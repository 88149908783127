define("ember-svg-jar/inlined/arrow-dot-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-dot-right</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM22 12a1.5 1.5 0 11-1.5-1.5A1.5 1.5 0 0122 12zm-5.5-.5A1.5 1.5 0 1118 10a1.5 1.5 0 01-1.5 1.5zM18 14a1.5 1.5 0 11-1.5-1.5A1.5 1.5 0 0118 14zm-5.5 1.5A1.5 1.5 0 1114 14a1.5 1.5 0 01-1.5 1.5zM14 18a1.5 1.5 0 11-1.5-1.5A1.5 1.5 0 0114 18zm-1.5-6.5A1.5 1.5 0 1114 10a1.5 1.5 0 01-1.5 1.5zm0-4A1.5 1.5 0 1114 6a1.5 1.5 0 01-1.5 1.5zm-4 4A1.5 1.5 0 1110 10a1.5 1.5 0 01-1.5 1.5zM10 14a1.5 1.5 0 11-1.5-1.5A1.5 1.5 0 0110 14zm-5.5-2.5A1.5 1.5 0 116 10a1.5 1.5 0 01-1.5 1.5zM6 14a1.5 1.5 0 11-1.5-1.5A1.5 1.5 0 016 14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});