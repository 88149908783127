define("ember-svg-jar/inlined/insect-catch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>insect-catch</title><path d=\"M23.837 19.325l-.44-.888A3.488 3.488 0 0020.26 16.5h-2.807a2.007 2.007 0 01-.742-.143l-1.974-.789c-2.863-.955-4.462.484-4.908 1.377a.75.75 0 101.342.67c.007-.013.734-1.407 3.051-.638l1.334.533a.5.5 0 01.31.527l-.19 1.525a.5.5 0 01-.5.438H12.5a.75.75 0 000 1.5h3a.249.249 0 01.186.416l-.743.836a.75.75 0 101.121 1l1.85-2.08a.5.5 0 01.373-.168h.933a.5.5 0 01.374.168l1.849 2.08a.75.75 0 101.121-1l-.743-.836A.25.25 0 0122 21.5h.5a1.5 1.5 0 001.337-2.175z\"/><path d=\"M11.861 9.47c1.92.974 5.034 2.806 7.612 4.238a2.986 2.986 0 003.711-.422 2.755 2.755 0 00.391-3.428c-4.64-7.426-4.137-7.968-5.1-8.929-1.589-1.573-4.53-1.1-6.7 1.072C10.033 3.74 9.384 5.968 10 7.6a.5.5 0 01-.115.529L.293 17.722a1 1 0 101.414 1.414l9.574-9.574a.5.5 0 01.58-.092zm1.325-6.055a4.345 4.345 0 012.881-1.407 1.4 1.4 0 011.008.347c.668.668.4 2.43-1.061 3.889s-3.22 1.729-3.889 1.061-.398-2.431 1.061-3.89z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});