define("ember-svg-jar/inlined/plant-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plant-1</title><path d=\"M12 9a1 1 0 00-1 1v13a1 1 0 002 0V10a1 1 0 00-1-1zM12.782.28a1.231 1.231 0 00-1.563 0A4.815 4.815 0 009.25 3.86c0 2.318 2.247 3.8 2.343 3.857a.749.749 0 00.813 0c.1-.061 2.344-1.539 2.344-3.857A4.91 4.91 0 0012.782.28zM6.043 18.46a7.468 7.468 0 00-4.427.14A1.251 1.251 0 001 20.535a7.48 7.48 0 003.519 2.685 7.351 7.351 0 004.429-.141 1.251 1.251 0 00.618-1.934 7.468 7.468 0 00-3.523-2.685zM6.043 12.46a7.468 7.468 0 00-4.427.14A1.251 1.251 0 001 14.535a7.48 7.48 0 003.519 2.685 7.351 7.351 0 004.429-.141 1.251 1.251 0 00.618-1.934 7.468 7.468 0 00-3.523-2.685zM5.385 11.127a6.018 6.018 0 003.625-.113 1.148 1.148 0 00.571-1.777 6.122 6.122 0 00-2.887-2.2 6.126 6.126 0 00-3.627.112A1.149 1.149 0 002.5 8.928a6.113 6.113 0 002.885 2.199zM17.957 18.46a7.468 7.468 0 014.427.14A1.251 1.251 0 0123 20.535a7.48 7.48 0 01-3.523 2.685 7.351 7.351 0 01-4.429-.141 1.251 1.251 0 01-.618-1.934 7.468 7.468 0 013.527-2.685zM17.957 12.46a7.468 7.468 0 014.427.14A1.251 1.251 0 0123 14.535a7.48 7.48 0 01-3.523 2.685 7.351 7.351 0 01-4.429-.141 1.251 1.251 0 01-.618-1.934 7.468 7.468 0 013.527-2.685zM18.615 11.127a6.018 6.018 0 01-3.625-.113 1.148 1.148 0 01-.571-1.777 6.122 6.122 0 012.887-2.2 6.126 6.126 0 013.627.112 1.149 1.149 0 01.567 1.779 6.113 6.113 0 01-2.885 2.199z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});