define("ember-svg-jar/inlined/messaging-kik-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messaging-kik-1</title><path d=\"M24 11c0-5.45-5.38-9.88-12-9.88S0 5.59 0 11s5.38 9.88 12 9.88a14.34 14.34 0 003.81-.51.5.5 0 01.34 0l5 2.31a1 1 0 001.32-1.35l-1.6-3.19a.5.5 0 01.11-.59A8.89 8.89 0 0024 11zm-10.45 6a1 1 0 01-1.39-.28l-2.27-3.43a.25.25 0 00-.36-.06l-.43.34a.25.25 0 00-.1.2v2.39a1 1 0 01-2 0V5.65a1 1 0 112 0v4.95a.25.25 0 00.4.2l2.48-1.93a1 1 0 011.23 1.58L11.5 11.7a.25.25 0 00-.05.34l2.38 3.57a1 1 0 01-.28 1.39zm2.95-2.83a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.48z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});