define("ember-svg-jar/inlined/gesture-two-fingers-expand-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-two-fingers-expand-all</title><path d=\"M9.5 3.749h5a.751.751 0 00.469-1.336l-2.5-2a.752.752 0 00-.938 0l-2.5 2A.751.751 0 009.5 3.749zM14.5 20.249h-5a.75.75 0 00-.469 1.335l2.5 2a.75.75 0 00.938 0l2.5-2a.75.75 0 00-.469-1.335zM2.414 8.529l-2 2.5a.752.752 0 000 .938l2 2.5A.751.751 0 003.75 14V9a.751.751 0 00-1.336-.471zM23.586 11.967a.752.752 0 000-.938l-2-2.5A.751.751 0 0020.25 9v5a.751.751 0 001.336.469zM14.573 11.525a.251.251 0 01-.139-.274l.537-2.751a.93.93 0 00-1.8-.454l-.476 2.747a.251.251 0 01-.306.2l-.553-.136a.249.249 0 01-.19-.243V7.99a1 1 0 00-2 0v4.273a.25.25 0 01-.435.168C7.72 10.791 6.392 12.305 7.5 14l1.839 2.776a.5.5 0 00.417.224h4.95a.5.5 0 00.486-.383c.211-.881.7-2.641.808-3.117.254-1.1-.488-1.535-1.427-1.975z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});