define("ember-svg-jar/inlined/touch-id-smartphone-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>touch-id-smartphone-1</title><path d=\"M15.962 1.987a4.8 4.8 0 016.288 4.555v4.722a.875.875 0 001.75 0V6.542A6.546 6.546 0 0015.417.325a.875.875 0 10.545 1.662zM22.333 15.626a.875.875 0 10-1.3-1.168 4.791 4.791 0 01-8.363-3.194V6.542a4.777 4.777 0 011.22-3.194.875.875 0 10-1.3-1.168 6.527 6.527 0 00-1.666 4.362v4.722a6.541 6.541 0 0011.416 4.362z\"/><path d=\"M15.5 11.264a.875.875 0 00-1.75 0 3.709 3.709 0 107.417 0V6.542a3.709 3.709 0 10-7.417 0v1.416a.875.875 0 001.75 0V6.542a1.959 1.959 0 113.917 0v4.722a1.959 1.959 0 11-3.917 0z\"/><path d=\"M18.333 10.792V7.958a.875.875 0 10-1.75 0v2.834a.875.875 0 101.75 0zM15.962 1.987a4.8 4.8 0 016.288 4.555v4.722a.875.875 0 001.75 0V6.542A6.546 6.546 0 0015.417.325a.875.875 0 10.545 1.662z\"/><path d=\"M22.333 15.626a.875.875 0 10-1.3-1.168 4.791 4.791 0 01-8.363-3.194V6.542a4.777 4.777 0 011.22-3.194.875.875 0 10-1.3-1.168 6.527 6.527 0 00-1.666 4.362v4.722a6.541 6.541 0 0011.416 4.362z\"/><path d=\"M15.5 11.264a.875.875 0 00-1.75 0 3.709 3.709 0 107.417 0V6.542a3.709 3.709 0 10-7.417 0v1.416a.875.875 0 001.75 0V6.542a1.959 1.959 0 113.917 0v4.722a1.959 1.959 0 11-3.917 0z\"/><path d=\"M14.2 19a.25.25 0 00-.238.172.439.439 0 01-.043.1.5.5 0 01-.422.232H2.5A.5.5 0 012 19V3.5a.5.5 0 01.5-.5h7.026a.249.249 0 00.225-.142A8.554 8.554 0 0111.093.846a2.952 2.952 0 01.435-.395A.25.25 0 0011.38 0H3a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3v-1.75a.25.25 0 00-.25-.25zM18.333 10.792V7.958a.875.875 0 10-1.75 0v2.834a.875.875 0 101.75 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});