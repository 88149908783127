define("ember-svg-jar/inlined/ui-columns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ui-columns</title><path d=\"M22 0H2a2 2 0 00-2 2v7a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zm-7.25 8.5a.5.5 0 01-.5.5H2.5a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5h11.75a.5.5 0 01.5.5zM21 6.856a.728.728 0 01-1.182.568l-1.745-1.4a.727.727 0 010-1.136l1.745-1.4A.727.727 0 0121 4.064z\"/><path d=\"M4 4.75h7.5a.75.75 0 000-1.5H4a.75.75 0 000 1.5zM4 7.75h4.5a.75.75 0 000-1.5H4a.75.75 0 000 1.5zM22 13H2a2 2 0 00-2 2v7a2 2 0 002 2h20a2 2 0 002-2v-7a2 2 0 00-2-2zm-7.25 8.5a.5.5 0 01-.5.5H2.5a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5h11.75a.5.5 0 01.5.5zM21 19.856a.728.728 0 01-1.182.568l-1.745-1.4a.727.727 0 010-1.136l1.745-1.4a.727.727 0 011.182.576z\"/><path d=\"M4 17.75h7.5a.75.75 0 000-1.5H4a.75.75 0 000 1.5zM4 20.75h4.5a.75.75 0 000-1.5H4a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});