define("ember-svg-jar/inlined/hammer-wench", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hammer-wench</title><path d=\"M17.511 13.985l-.846-.846a.5.5 0 00-.707 0l-2.824 2.824a.5.5 0 000 .707l.849.849a.5.5 0 01.128.457A4.994 4.994 0 0018.984 24a5.046 5.046 0 001.591-.258.5.5 0 00.2-.828l-2.449-2.449a.5.5 0 01-.146-.354V18.7a.5.5 0 01.5-.5h1.413a.5.5 0 01.354.147L22.9 20.8a.5.5 0 00.827-.193 4.992 4.992 0 00-5.76-6.5.5.5 0 01-.456-.122zM10.866 7.34l-.85-.85a.5.5 0 01-.129-.458A4.992 4.992 0 003.39.272a.5.5 0 00-.19.828l2.456 2.458a.5.5 0 01.146.353v1.414a.5.5 0 01-.5.5H3.888a.5.5 0 01-.353-.147L1.086 3.23a.5.5 0 00-.828.2 4.993 4.993 0 005.768 6.46.5.5 0 01.457.129l.852.852a.5.5 0 00.707 0l2.824-2.824a.5.5 0 000-.707zM16.647 9.622l-2.264-2.264a.5.5 0 00-.707 0L1.088 19.946a2 2 0 000 2.828l.143.143a2 2 0 002.829 0l12.587-12.588a.5.5 0 000-.707zM19.013 9.605a1.366 1.366 0 001.928 0l2.66-2.66a1.364 1.364 0 000-1.928l-3.962-3.962a.5.5 0 00-.619-.07 3.832 3.832 0 01-4.658-.772.514.514 0 00-.707 0L12.6 1.266a1.364 1.364 0 000 1.928z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});