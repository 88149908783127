define("ember-svg-jar/inlined/arrow-thick-circle-bottom-right-corner-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-circle-bottom-right-corner-2</title><path d=\"M20.489 3.511a12.006 12.006 0 100 16.979 12.017 12.017 0 000-16.979zM6.858 16.144v-.419a1.058 1.058 0 011-1.044l3.51-.151c.549-.024.681-.362.292-.751L7.07 9.193a1 1 0 010-1.415l.705-.7a1 1 0 011.415 0l4.6 4.6c.389.389.733.258.763-.292l.194-3.448a1.072 1.072 0 011.056-1h.418a.972.972 0 01.974 1l-.217 8.02a1.06 1.06 0 01-1.027 1.019l-8.09.152a.983.983 0 01-1.003-.985z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});