define("ember-svg-jar/inlined/music-genre-idea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-genre-idea</title><path d=\"M11.75 4.252a1 1 0 001-1v-1.5a1 1 0 00-2 0v1.5a1 1 0 001 1zM4.429 6.6a1 1 0 001.414-1.419L4.782 4.12a1 1 0 00-1.414 1.414zM2.5 11H1a1 1 0 000 2h1.5a1 1 0 000-2zM22.5 11H21a1 1 0 000 2h1.5a1 1 0 000-2z\"/><path d=\"M17.765 10.873a.251.251 0 00-.165.293 6 6 0 01-3.4 6.814.236.236 0 01-.1.022H9.4a.255.255 0 01-.1-.022 6 6 0 014.126-11.24.25.25 0 00.3-.148 3.256 3.256 0 01.824-1.2.251.251 0 00-.085-.419A8 8 0 008.6 19.856a.251.251 0 01.152.23V21a3 3 0 006 0v-.937a.218.218 0 01.133-.2 8 8 0 004.547-9.6.247.247 0 00-.132-.163.25.25 0 00-.2 0l-.118.049a3.264 3.264 0 01-1.217.724z\"/><path d=\"M21.837.045l-3.409 1.1A1.753 1.753 0 0017 2.867v2.92a.25.25 0 01-.25.25 1.75 1.75 0 101.75 1.75v-4.92c0-.121.086-.224.3-.269l3.41-1.1a.251.251 0 01.294.247v2.073a.219.219 0 01-.222.219h-.032A1.75 1.75 0 1024 5.787V1.742A1.75 1.75 0 0021.837.045z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});