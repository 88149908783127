define("ember-svg-jar/inlined/taxi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>taxi</title><path d=\"M17.251 20.15a.249.249 0 01.25.25v2.5a1 1 0 001 1h2a1 1 0 001-1v-2.522a.25.25 0 01.209-.246 1.251 1.251 0 001.04-1.232v-5.5a2.743 2.743 0 00-1.664-2.516.252.252 0 01-.15-.205l-.317-3.265c0-1.815-1.754-2.909-4.028-3.484a.249.249 0 01-.178-.168L15.588 1.1a1.5 1.5 0 00-1.414-1H9.83a1.5 1.5 0 00-1.414 1l-.823 2.655a.25.25 0 01-.179.169C5.14 4.49 3.386 5.562 3.389 7.318l-.323 3.359a.25.25 0 01-.15.206A2.745 2.745 0 001.25 13.4v5.5a1.251 1.251 0 001.25 1.25v2.75a1 1 0 001 1h2a1 1 0 001-1v-2.5a.25.25 0 01.25-.25zm-7.1-17.874a.249.249 0 01.238-.176h3.234a.249.249 0 01.238.176l.278.894a.25.25 0 01-.26.323 22.3 22.3 0 00-3.746-.001.249.249 0 01-.22-.092.252.252 0 01-.044-.231zM5 16.4a1.5 1.5 0 111.5-1.5A1.5 1.5 0 015 16.4zm10 1.5a.25.25 0 01-.25.25h-5.5A.25.25 0 019 17.9v-.76c0-.408 1.168-1 3-1a6.064 6.064 0 012.343.414c.155.069.657.313.657.588zm-3-8.5a16.836 16.836 0 00-6.554 1.27.25.25 0 01-.347-.253l.285-3c0-.946 2.718-2 6.617-2a15.725 15.725 0 014.907.694c1.071.375 1.711.863 1.715 1.4l.279 2.908a.246.246 0 01-.1.225.251.251 0 01-.245.029A16.811 16.811 0 0012 9.4zm7 7a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});