define("ember-svg-jar/inlined/single-man-actions-cart.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-cart</title><path d=\"M.661 18.062h1.791a.247.247 0 01.247.227l.438 5.257A.5.5 0 003.63 24h3.959a.5.5 0 00.493-.454l.438-5.257a.247.247 0 01.247-.227h1.791a.5.5 0 00.5-.5V14.1a5.444 5.444 0 00-10.887 0v3.464a.5.5 0 00.49.498z\"/><path d=\"M5.609 0a4.206 4.206 0 104.207 4.206A4.211 4.211 0 005.609 0zm0 6.433a2.229 2.229 0 01-2.226-2.227 2.135 2.135 0 01.156-.8.248.248 0 01.361-.116 5.314 5.314 0 002.8.793 5.344 5.344 0 00.9-.077.2.2 0 01.165.045.2.2 0 01.072.155 2.229 2.229 0 01-2.228 2.227zM15.615 21.155a1.423 1.423 0 101.423 1.423 1.435 1.435 0 00-1.423-1.423zM19.308 21.155a1.423 1.423 0 101.423 1.423 1.429 1.429 0 00-1.423-1.423zM12.846 14a.5.5 0 00-.474.659l1.846 5.5a.5.5 0 00.473.341h6.232a.5.5 0 00.481-.365c1.69-6.042.852-3.242 2.544-8.318a1 1 0 10-1.9-.633l-.878 2.646a.25.25 0 01-.237.171z\"/><path d=\"M.661 18.062h1.791a.247.247 0 01.247.227l.438 5.257A.5.5 0 003.63 24h3.959a.5.5 0 00.493-.454l.438-5.257a.247.247 0 01.247-.227h1.791a.5.5 0 00.5-.5V14.1a5.444 5.444 0 00-10.887 0v3.464a.5.5 0 00.49.498z\"/><path d=\"M5.609 0a4.206 4.206 0 104.207 4.206A4.211 4.211 0 005.609 0zm0 6.433a2.229 2.229 0 01-2.226-2.227 2.135 2.135 0 01.156-.8.248.248 0 01.361-.116 5.314 5.314 0 002.8.793 5.344 5.344 0 00.9-.077.2.2 0 01.165.045.2.2 0 01.072.155 2.229 2.229 0 01-2.228 2.227zM15.615 21.155a1.423 1.423 0 101.423 1.423 1.435 1.435 0 00-1.423-1.423zM19.308 21.155a1.423 1.423 0 101.423 1.423 1.429 1.429 0 00-1.423-1.423zM12.846 14a.5.5 0 00-.474.659l1.846 5.5a.5.5 0 00.473.341h6.232a.5.5 0 00.481-.365c1.69-6.042.852-3.242 2.544-8.318a1 1 0 10-1.9-.633l-.878 2.646a.25.25 0 01-.237.171z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});