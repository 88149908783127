define("ember-svg-jar/inlined/earpods-tap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earpods-tap</title><path d=\"M14.3 5.719a1.751 1.751 0 01-1.05-1.6 1.731 1.731 0 01.35-1.05.75.75 0 00-1.2-.9 3.221 3.221 0 00-.65 1.951A3.087 3.087 0 0014 7.157a.75.75 0 00.3-1.438zM4.1 2.636a5.063 5.063 0 00-3.765 1.63A1.31 1.31 0 000 5.141v4.033a1.308 1.308 0 00.336.875 5.059 5.059 0 003.764 1.63 5.118 5.118 0 00.82-.065.5.5 0 01.58.495v8.722a1.467 1.467 0 001.4 1.3h.8a1.3 1.3 0 001.3-1.3V7.157a4.727 4.727 0 00-4.9-4.521zM2.511 7.962a.8.8 0 11.8-.8.8.8 0 01-.8.8z\"/><path d=\"M23.908 8.647A10.481 10.481 0 0021.623 6.3l-4.366-3.265a1.431 1.431 0 00-1.069-.42A1.5 1.5 0 0015.176 5.2l4.543 4.359a.992.992 0 01-1.056 1.636c-1.179-.469-1.875-.915-2.752-.219a1.644 1.644 0 00.207 2.714l3.437 1.59a10.032 10.032 0 013.324 2.406c.232.268.34.454.621.454a.5.5 0 00.5-.5v-8.7a.5.5 0 00-.092-.293z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});