define("ember-svg-jar/inlined/road-sign-slippery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-slippery</title><path d=\"M23.53 11.47l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0l11-11a.749.749 0 000-1.06zm-13.113 6.156a.749.749 0 01-1.029-.254.251.251 0 00-.215-.122 1.75 1.75 0 010-3.5.75.75 0 110 1.5.25.25 0 000 .5 1.761 1.761 0 011.5.847.749.749 0 01-.256 1.029zM10 12.25a.25.25 0 00-.25.25v.5a.75.75 0 01-1.5 0v-.775a.25.25 0 00-.125-.216A1.746 1.746 0 017.25 10.5a1.792 1.792 0 01.513-1.322l.5-.5a.248.248 0 00.067-.124l.3-1.377a1.733 1.733 0 011.717-1.427h3.306a1.744 1.744 0 011.726 1.463l.291 1.341a.259.259 0 00.068.123l.5.5a1.741 1.741 0 01.512 1.237 1.817 1.817 0 01-.877 1.591.251.251 0 00-.123.216V13a.75.75 0 01-1.5 0v-.5a.25.25 0 00-.25-.25zm6.422 5.309a.752.752 0 01-1.042-.2.25.25 0 00-.207-.11 1.75 1.75 0 010-3.5.75.75 0 110 1.5.25.25 0 000 .5 1.748 1.748 0 011.447.767.75.75 0 01-.198 1.043z\"/><path d=\"M14 7.947l-.1-.447v-.009a.263.263 0 00-.253-.241h-3.3a.25.25 0 00-.246.205v.009l-.105.483a.252.252 0 00.05.21.249.249 0 00.2.093h3.52a.249.249 0 00.244-.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});