define("ember-svg-jar/inlined/board-game-tic-tac-toe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>board-game-tic-tac-toe</title><path d=\"M23 15.5h-5a.5.5 0 01-.5-.5V9a.5.5 0 01.5-.5h5a1 1 0 000-2h-5a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v5a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v5a.5.5 0 01-.5.5H1a1 1 0 000 2h5a.5.5 0 01.5.5v6a.5.5 0 01-.5.5H1a1 1 0 000 2h5a.5.5 0 01.5.5v5a1 1 0 002 0v-5a.5.5 0 01.5-.5h6a.5.5 0 01.5.5v5a1 1 0 002 0v-5a.5.5 0 01.5-.5h5a1 1 0 000-2zm-7.5-.5a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5V9a.5.5 0 01.5-.5h6a.5.5 0 01.5.5z\"/><path d=\"M9.793 3.887A1 1 0 1011.207 5.3l.439-.44a.5.5 0 01.708 0l.439.44a1 1 0 101.414-1.414l-.439-.44a.5.5 0 010-.707l.439-.439A1 1 0 0012.793.887l-.439.439a.5.5 0 01-.708 0l-.439-.439A1 1 0 009.793 2.3l.439.439a.5.5 0 010 .707zM.79 12.793a1 1 0 101.41 1.414l.44-.439a.5.5 0 01.707 0l.439.439A1 1 0 105.2 12.793l-.439-.439a.5.5 0 010-.708l.439-.439a1 1 0 00-1.41-1.414l-.439.439a.5.5 0 01-.707 0L2.2 9.793a1 1 0 10-1.41 1.414l.439.439a.5.5 0 010 .708zM23.2 20.3a1 1 0 00-1.414-1.414l-.439.439a.5.5 0 01-.707 0l-.44-.439a1 1 0 00-1.41 1.414l.439.439a.5.5 0 010 .707l-.439.44A1 1 0 1020.2 23.3l.44-.44a.5.5 0 01.707 0l.439.44a1 1 0 101.414-1.413l-.439-.44a.5.5 0 010-.707z\"/><circle cx=\"3\" cy=\"3\" r=\"2.5\"/><circle cx=\"12\" cy=\"12\" r=\"2.5\"/><circle cx=\"21\" cy=\"3\" r=\"2.5\"/><circle cx=\"3\" cy=\"21\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});