define("ember-svg-jar/inlined/rating-star-top", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rating-star-top</title><path d=\"M17.585 6.825a.251.251 0 00-.328.029l-.148.155a.249.249 0 00-.049.272l.6 1.389a2.622 2.622 0 01.028 2.02.254.254 0 00.021.226 6.75 6.75 0 11-11.428 0 .248.248 0 00.021-.226 2.629 2.629 0 01.028-2.023l.6-1.388a.251.251 0 00-.048-.272l-.147-.154a.25.25 0 00-.328-.029 9.5 9.5 0 1011.171 0z\"/><path d=\"M8.662 6.692a.251.251 0 01.049.273l-1 2.3a1.129 1.129 0 00.284 1.3 1.155 1.155 0 001.351.15l2.53-1.415a.251.251 0 01.246 0l2.532 1.427a1.15 1.15 0 001.351-.151 1.128 1.128 0 00.284-1.3l-1-2.3a.251.251 0 01.049-.273L17.147 4.8a1.136 1.136 0 00.27-1.239 1.111 1.111 0 00-1.052-.686h-2.036a.248.248 0 01-.222-.137L13.033.631A1.154 1.154 0 0012 0a1.151 1.151 0 00-1.033.631L9.889 2.739a.249.249 0 01-.222.136H7.631a1.106 1.106 0 00-1.049.684 1.13 1.13 0 00.258 1.23z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});