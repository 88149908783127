define("ember-svg-jar/inlined/sport-curling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sport-curling</title><circle cx=\"15\" cy=\"3.25\" r=\"2.5\"/><path d=\"M10.471 13.5a.249.249 0 01-.053-.308l1.56-2.808a.251.251 0 01.416-.033l.083.11a9.24 9.24 0 007.451 3.794h.572a1.5 1.5 0 000-3h-.572A6.236 6.236 0 0114.9 8.691a7.142 7.142 0 00-11.83.415l-.217.349a1.5 1.5 0 102.547 1.59l.217-.349a4.121 4.121 0 013.402-1.945.25.25 0 01.225.371l-2.7 4.857a1.5 1.5 0 01-1.312.771H1.5a1.5 1.5 0 000 3h3.734a4.508 4.508 0 003.393-1.543.249.249 0 01.172-.086.252.252 0 01.182.063L11 17.976a1.5 1.5 0 01.5 1.122v2.652a1.5 1.5 0 003 0V19.1a4.506 4.506 0 00-1.511-3.365z\"/><path d=\"M23 19.75h-.75a.25.25 0 01-.25-.25v-1a2 2 0 00-2-2h-1.5a1 1 0 000 2H20v1a.25.25 0 01-.25.25H18a1 1 0 00-1 1v1.5a1 1 0 001 1h5a1 1 0 001-1v-1.5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});