define("ember-svg-jar/inlined/safety-float", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-float</title><path d=\"M22.955 10.072a.252.252 0 01-.17-.191 11.017 11.017 0 00-8.666-8.666.252.252 0 01-.191-.17A1.5 1.5 0 0012.5 0h-1a1.5 1.5 0 00-1.428 1.045.252.252 0 01-.191.17 11.017 11.017 0 00-8.666 8.666.252.252 0 01-.17.191A1.5 1.5 0 000 11.5v1a1.5 1.5 0 001.045 1.428.252.252 0 01.17.191 11.017 11.017 0 008.666 8.666.252.252 0 01.191.17A1.5 1.5 0 0011.5 24h1a1.5 1.5 0 001.428-1.045.252.252 0 01.191-.17 11.017 11.017 0 008.666-8.666.252.252 0 01.17-.191A1.5 1.5 0 0024 12.5v-1a1.5 1.5 0 00-1.045-1.428zm-2.265-.387a.25.25 0 01-.241.315h-3.163a.25.25 0 01-.23-.152 5.527 5.527 0 00-2.9-2.9.25.25 0 01-.156-.234V3.551a.248.248 0 01.1-.2.245.245 0 01.217-.043 9.021 9.021 0 016.373 6.377zM12 15.5a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5zM9.726 3.3a.218.218 0 01.274.21v3.2a.25.25 0 01-.152.23 5.527 5.527 0 00-2.9 2.9.25.25 0 01-.23.152H3.551a.248.248 0 01-.2-.1.245.245 0 01-.043-.217A9.023 9.023 0 019.726 3.3zM3.31 14.315A.25.25 0 013.551 14h3.163a.25.25 0 01.23.152 5.527 5.527 0 002.9 2.9.25.25 0 01.152.23v3.163a.248.248 0 01-.1.2.245.245 0 01-.217.043 9.021 9.021 0 01-6.369-6.373zm11.005 6.375a.25.25 0 01-.315-.241v-3.163a.25.25 0 01.152-.23 5.527 5.527 0 002.9-2.9.25.25 0 01.23-.152h3.163a.248.248 0 01.2.1.245.245 0 01.043.217 9.021 9.021 0 01-6.373 6.369z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});