define("ember-svg-jar/inlined/army-badge-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-badge-1</title><path d=\"M19.207 7.677a1.5 1.5 0 00-1.539.074l-5.39 3.6a.5.5 0 01-.555 0l-5.391-3.6A1.5 1.5 0 004 9v2.351a.5.5 0 00.24.427l7.466 4.542a.5.5 0 00.518 0l7.534-4.543A.5.5 0 0020 11.35V9a1.5 1.5 0 00-.793-1.323z\"/><path d=\"M19.745 14.426a.5.5 0 00-.505.009l-6.166 3.751a1.909 1.909 0 01-1.064.317 2.022 2.022 0 01-1.119-.339L4.76 14.435a.5.5 0 00-.76.427v3.3a1.5 1.5 0 00.668 1.248l6.5 4.334a1.5 1.5 0 001.664 0l6.5-4.334A1.5 1.5 0 0020 18.166v-3.3a.5.5 0 00-.255-.44zM9.647 4.825a.5.5 0 01.107.556L8.93 7.274a.524.524 0 00.746.655l2.079-1.169a.5.5 0 01.49 0l2.079 1.169a.524.524 0 00.746-.655l-.824-1.893a.5.5 0 01.107-.556l1.491-1.468A.49.49 0 0015.5 2.5h-1.682a.5.5 0 01-.453-.29L12.471.287a.53.53 0 00-.942 0l-.9 1.926a.5.5 0 01-.453.289H8.5a.489.489 0 00-.343.855z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});