define("ember-svg-jar/inlined/igloo-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>igloo-1</title><path d=\"M13.225 7.572A.25.25 0 0113 7.323V5.136a.248.248 0 01.161-.233l2.675-1.019a1 1 0 00.074-1.838l-4.195-2A.5.5 0 0011 .5v6.823a.25.25 0 01-.225.249A12.01 12.01 0 000 19.5v3A1.5 1.5 0 001.5 24h6a.5.5 0 00.5-.5v-3.375a4 4 0 018 0V23.5a.5.5 0 00.5.5h6a1.5 1.5 0 001.5-1.5v-3A12.01 12.01 0 0013.225 7.572z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});