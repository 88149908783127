define("ember-svg-jar/inlined/single-neutral-id-card-double", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-id-card-double</title><path d=\"M24 7.5a2 2 0 00-2-2h-1.75a.25.25 0 01-.25-.25V3.5a1 1 0 00-1-1H2a2 2 0 00-2 2v12a1 1 0 001 1h1.75a.25.25 0 01.25.25v1.75a2 2 0 002 2h3a1 1 0 001-1 .75.75 0 011.5 0 1 1 0 001 1h4a1 1 0 001-1 .75.75 0 011.5 0 1 1 0 001 1h3a2 2 0 002-2zM22 19a.5.5 0 01-.5.5h-1.523a.248.248 0 01-.227-.146 2.75 2.75 0 00-5 0 .248.248 0 01-.227.146h-2.046a.248.248 0 01-.227-.146 2.75 2.75 0 00-5 0 .248.248 0 01-.227.146H5.5A.5.5 0 015 19V8a.5.5 0 01.5-.5h16a.5.5 0 01.5.5z\"/><path d=\"M14.75 10.75a.75.75 0 00.75.75H20a.75.75 0 000-1.5h-4.5a.75.75 0 00-.75.75zM18 13h-2.5a.75.75 0 000 1.5H18a.75.75 0 000-1.5zM11.316 13.592c-.014-.076-.013-.2-.019-.212a3.513 3.513 0 00.774-2.435 2.074 2.074 0 10-4.142 0 3.449 3.449 0 00.736 2.381.609.609 0 01.019.266c-1.314.49-2.04.859-2.348 1.474a4.21 4.21 0 00-.328 1.113.5.5 0 00.108.409.5.5 0 00.384.18h7a.5.5 0 00.384-.18.5.5 0 00.108-.409 4.21 4.21 0 00-.328-1.113c-.309-.615-1.035-.984-2.348-1.474z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});