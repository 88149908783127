define("ember-svg-jar/inlined/content-pen-write", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-pen-write</title><path d=\"M8.816 19.792a1 1 0 00-1.414 0l-1.293 1.293-1.293-1.293a1 1 0 00-1.347-.062l-3 2.5a1 1 0 00-.128 1.41 1.013 1.013 0 001.408.127l2.3-1.916L5.4 23.206a1 1 0 001.414 0l1.293-1.293.793.793a1 1 0 101.414-1.414zM23.776 3.361A2.867 2.867 0 0022.4 1.636L19.5.067a.5.5 0 00-.679.2l-7.974 14.456a.5.5 0 00.2.678l2.479 1.365a.493.493 0 00.241.063.5.5 0 00.439-.259l7.25-13.17a.884.884 0 01.324 1.188L18.14 11.2a1 1 0 00.394 1.357 1.009 1.009 0 001.358-.392l3.64-6.611a2.868 2.868 0 00.244-2.193zM13.1 17.544l-2.479-1.365a.5.5 0 00-.741.456l.1 2.748a.5.5 0 00.751.414l2.378-1.383a.5.5 0 00-.01-.87z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});