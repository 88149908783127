define("ember-svg-jar/inlined/task-list-text-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-list-text-alternate</title><path d=\"M24 2.013a2 2 0 00-2-2H2a2 2 0 00-2 2v19.974a2 2 0 002 2h15.025a7.02 7.02 0 004.932-2.043A6.93 6.93 0 0024 17.013zm-22 .5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v13.974a.25.25 0 01-.25.25H18.5a1.752 1.752 0 00-1.75 1.75v3.25a.25.25 0 01-.25.25h-14a.5.5 0 01-.5-.5zm16.578 19.223a.25.25 0 01-.328-.237v-3.012a.253.253 0 01.25-.25h3.016a.252.252 0 01.2.1.248.248 0 01.035.224 4.974 4.974 0 01-3.176 3.171z\"/><path d=\"M14.25 15.513h-6.5a.25.25 0 01-.25-.25v-2.25a.25.25 0 01.25-.25h12a.75.75 0 000-1.5h-12a.25.25 0 01-.25-.25v-2.25a.25.25 0 01.25-.25h12a.75.75 0 000-1.5h-12a.25.25 0 01-.25-.25v-2.5a.75.75 0 00-1.5 0v2.5a.25.25 0 01-.25.25h-1.5a.75.75 0 000 1.5h1.5a.25.25 0 01.25.25v2.25a.25.25 0 01-.25.25h-1.5a.75.75 0 100 1.5h1.5a.25.25 0 01.25.25v2.25a.25.25 0 01-.25.25h-1.5a.75.75 0 100 1.5h1.5a.25.25 0 01.25.25v2.5a.75.75 0 001.5 0v-2.5a.25.25 0 01.25-.25h6.5a.75.75 0 100-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});