define("ember-svg-jar/inlined/duck-bill-platypus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>duck-bill-platypus</title><path d=\"M18.707 8.213a1 1 0 000-1.414c-1.646-1.646-2.991-1.421-3.474-1.259l-.533-.753a1.993 1.993 0 011.884-.758 2 2 0 002.106-.967 1.94 1.94 0 00.081-1.7C18.423.535 17.609.006 15.79.006a6.5 6.5 0 00-6.262 4.773 4.823 4.823 0 00-.765.76c-.487-.162-1.83-.382-3.47 1.26a1 1 0 101.414 1.414 2.834 2.834 0 011.046-.729 5.615 5.615 0 00-.245 1.829 2.6 2.6 0 00-1.494.878 3.192 3.192 0 00-.494 2.761 1 1 0 101.96-.392 1.473 1.473 0 01.075-1.093.56.56 0 01.311-.182 5.46 5.46 0 00.8 1.482A4.462 4.462 0 007.5 15.756a4.508 4.508 0 001.52 3.358.952.952 0 00-.02.142v2.738a2 2 0 002 2h2a2 2 0 002-2v-2.738a.957.957 0 00-.02-.141 4.508 4.508 0 001.52-3.359 4.459 4.459 0 00-1.161-2.988 5.5 5.5 0 00.8-1.484.572.572 0 01.31.183 1.473 1.473 0 01.075 1.093 1 1 0 101.96.392 3.192 3.192 0 00-.494-2.761 2.6 2.6 0 00-1.494-.878 5.635 5.635 0 00-.245-1.831 2.866 2.866 0 011.046.731 1 1 0 001.41 0zm-5.845 11.592a.249.249 0 01.138.223v1.716a.255.255 0 01-.073.177.251.251 0 01-.177.073h-1.5a.25.25 0 01-.25-.25v-1.715a.251.251 0 01.138-.224l.75-.375a.252.252 0 01.224 0zm-2.612-4.3a1 1 0 11-1 1 1 1 0 011-.999zm3.5 2a1 1 0 111-1 1 1 0 01-1 1.001z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});