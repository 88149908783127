define("ember-svg-jar/inlined/toys-furby", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toys-furby</title><path d=\"M5.958 19.5a3.985 3.985 0 00-3.944 3.334A1 1 0 003 24h5.917a1 1 0 00.983-1.166A3.985 3.985 0 005.958 19.5zM18.042 19.5a3.985 3.985 0 00-3.942 3.334A1 1 0 0015.083 24H21a1 1 0 00.986-1.166 3.985 3.985 0 00-3.944-3.334z\"/><path d=\"M18.042 18a5.552 5.552 0 011.654.251.5.5 0 00.581-.226 9.288 9.288 0 001.223-4.739 14.073 14.073 0 00-.918-5.113.5.5 0 01.146-.565C22.3 6.3 23.309 3.458 23.5 1.154A1.068 1.068 0 0022.324.006c-3.177.322-5.041 1.337-5.911 2.386a.5.5 0 01-.624.121A7.942 7.942 0 0012 1.5a7.942 7.942 0 00-3.789 1.013.5.5 0 01-.624-.12C6.717 1.343 4.853.328 1.675.005A1.069 1.069 0 00.5 1.153C.691 3.458 1.7 6.3 3.272 7.608a.5.5 0 01.146.565 14.073 14.073 0 00-.918 5.113 9.288 9.288 0 001.223 4.739.5.5 0 00.581.226A5.552 5.552 0 015.958 18a5.481 5.481 0 015.188 3.664.5.5 0 00.471.335h.765a.5.5 0 00.472-.335A5.481 5.481 0 0118.042 18zM15.5 6A1.5 1.5 0 1114 7.5 1.5 1.5 0 0115.5 6zM7 7.5A1.5 1.5 0 118.5 9 1.5 1.5 0 017 7.5zm1.7 5.789a.5.5 0 01-.064-.455C9.057 11.631 10.439 10 12 10s2.943 1.631 3.367 2.834a.5.5 0 01-.472.666h-5.79a.5.5 0 01-.405-.211zM12 18a3.513 3.513 0 01-2.868-2.33A.5.5 0 019.6 15h4.8a.5.5 0 01.471.67A3.512 3.512 0 0112 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});