define("ember-svg-jar/inlined/3d-pen-draw-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>3d-pen-draw-box</title><path d=\"M5.378 9.877a.75.75 0 10-.6 1.374l.757.332a.75.75 0 00.6-1.374z\"/><path d=\"M17.5 10.542a1 1 0 00-1 1v6.64a.25.25 0 01-.15.229l-6.1 2.669V20a.75.75 0 00-1.5 0v1.08l-6.1-2.669a.25.25 0 01-.15-.229V9.817a.251.251 0 01.15-.229L9.4 6.635a.249.249 0 01.2 0L12 7.684a1 1 0 00.8-1.832L9.9 4.584a.989.989 0 00-.8 0L1.114 8.078h-.005a1.048 1.048 0 00-.609.933V19a1 1 0 00.6.916l8 3.5a1 1 0 00.8 0l8-3.5a1 1 0 00.6-.916v-7.458a1 1 0 00-1-1z\"/><path d=\"M8.751 16.5v1a.75.75 0 001.5 0v-1a.75.75 0 00-1.5 0z\"/><circle cx=\"9.501\" cy=\"12.5\" r=\"2\"/><path d=\"M22.935 1.059a1.911 1.911 0 00-2.7 0l-5.088 5.087a.5.5 0 00-.136.255l-.5 2.5a.5.5 0 00.136.452.5.5 0 00.451.137l2.5-.5a.5.5 0 00.256-.137l5.087-5.087a1.913 1.913 0 00-.006-2.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});