define("ember-svg-jar/inlined/lantern-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lantern-1</title><path d=\"M12.99 20.024a.249.249 0 01-.24-.246v-.528A.25.25 0 0113 19h3a.976.976 0 00.888-.579.231.231 0 01.259-.171h.35c.221 0 .253.25.253.25a.75.75 0 001.5 0v-2a.75.75 0 00-1.5 0 .227.227 0 01-.252.25h-.277C17 16.75 17 16.5 17 16.5a1.5 1.5 0 00-1.5-1.5.67.67 0 00-.328.089 5.978 5.978 0 01-6.336 0A.709.709 0 008.5 15 1.5 1.5 0 007 16.5V18a1 1 0 001 1h3a.25.25 0 01.25.25v.531a.246.246 0 01-.235.246C7.415 20.225 4 21.494 4 23.5a.5.5 0 00.5.5h15a.5.5 0 00.5-.5c0-1.915-2.839-3.288-7.01-3.476z\"/><path d=\"M10.919 14.887a5 5 0 004.718-8.312.492.492 0 01-.137-.336V.25a.25.25 0 00-.25-.25h-6.5a.25.25 0 00-.25.25v5.99a.487.487 0 01-.137.334 5 5 0 002.556 8.313zm-.063-6.825a.179.179 0 01.248.106 1.374 1.374 0 00.4.581c.059.055.236.078.259.032a2.6 2.6 0 00-.212-2.5.178.178 0 01.217-.266 4.6 4.6 0 012.732 4.144 2.5 2.5 0 01-5 0 2.249 2.249 0 011.356-2.097z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});