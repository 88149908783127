define("ember-svg-jar/inlined/laundry-hand-wash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laundry-hand-wash</title><path d=\"M23.909 4.747a.251.251 0 00.061-.163l.006-4.335A.249.249 0 0023.9.073.247.247 0 0023.726 0h-5.489a.252.252 0 00-.137.042l-.293.2a4.51 4.51 0 01-2.5.76A21.655 21.655 0 005.928 2.6l-4.4 1.757A1.808 1.808 0 00.276 6.305 1.745 1.745 0 002 7.787a1.717 1.717 0 00.552-.09l2.364-.788a.249.249 0 01.272.394 9.106 9.106 0 00-1.905 4.907 1.65 1.65 0 00.692 1.558 1.891 1.891 0 001.85.113 1.663 1.663 0 00.941-1.422A3.78 3.78 0 018.615 9.28a5.48 5.48 0 011.476-.555.249.249 0 01.245.407A9.857 9.857 0 008.407 12.5l-.731 2.192A1.749 1.749 0 008.78 16.9a1.718 1.718 0 00.553.09 1.742 1.742 0 001.656-1.19l.732-2.2a6.442 6.442 0 012.092-3L15.807 9a1.516 1.516 0 012.407.768l.111.384a1.518 1.518 0 01-.051.98l-.583 1.468a1.747 1.747 0 001.252 2.357 1.8 1.8 0 002.021-1.135l.658-1.644a4.517 4.517 0 00.127-2.714 4 4 0 01.735-3.054zM22.908 21.958c-1.571.128-2.662-1.552-2.67-1.566a1.045 1.045 0 00-1.712.023 3.368 3.368 0 01-2.748 1.554 2.8 2.8 0 01-2.4-1.478 1.04 1.04 0 00-1.713-.156 4.3 4.3 0 01-3.1 1.634 3.548 3.548 0 01-2.755-1.577 1.039 1.039 0 00-1.632-.092c-.014.019-1.47 1.832-3.1 1.7a.983.983 0 00-1.05.945 1 1 0 00.942 1.049c.075 0 .148.006.221.006a5.7 5.7 0 003.552-1.46.249.249 0 01.328.007 5.171 5.171 0 003.5 1.418 5.772 5.772 0 003.629-1.356.252.252 0 01.332.016 4.535 4.535 0 003.251 1.34 5.108 5.108 0 003.448-1.339.251.251 0 01.334 0 4.7 4.7 0 003.487 1.323 1 1 0 00.927-1.065 1.01 1.01 0 00-1.071-.926z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});