define("ember-svg-jar/inlined/office-desk-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-desk-1</title><path d=\"M23.521 15.486a.5.5 0 00-.426-.237H1.713a.5.5 0 00-.447.724l.862 1.727a1 1 0 00.894.553h.353a.25.25 0 01.239.322l-1.167 3.887a1 1 0 00.671 1.245.984.984 0 00.287.043 1 1 0 00.958-.714l1.382-4.609a.251.251 0 01.24-.178h12.84a.251.251 0 01.239.178l1.383 4.609a1 1 0 101.916-.574L21.2 18.571a.25.25 0 01.24-.322h.351a1 1 0 00.895-.553l.861-1.723a.5.5 0 00-.026-.487zM3.405 12.249h1.786a.25.25 0 01.224.362l-.458.915a.5.5 0 00.448.723h5a.5.5 0 00.447-.723l-.457-.915a.25.25 0 01.223-.362h1.787a3 3 0 003-3v-6a3 3 0 00-3-3h-9.5a2.5 2.5 0 00-2.5 2.5v6.5a3 3 0 003 3zm-1-9.5a.5.5 0 01.5-.5h9.5a1 1 0 011 1v4.5a.5.5 0 01-.5.5h-10a.5.5 0 01-.5-.5zM17.905 14.249h4a.5.5 0 00.5-.5v-3.5a.978.978 0 00-.6-.9.25.25 0 01-.15-.229V7.749a.75.75 0 10-1.5 0v1.4a.1.1 0 01-.189.045l-.89-1.78a.75.75 0 10-1.342.671l.449.9a.252.252 0 01.009.2.249.249 0 01-.145.143.985.985 0 00-.642.921v3.5a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});