define("ember-svg-jar/inlined/music-note-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-note-2</title><path d=\"M21.5 2.25A2.25 2.25 0 0018.4.168l-9 3.682A2.241 2.241 0 008 5.932v9.818a.25.25 0 01-.25.25H6.5a4 4 0 000 8 4.1 4.1 0 004-4v-9.242a.248.248 0 01.155-.231l8-3.273a.25.25 0 01.345.231v4.27a.25.25 0 01-.25.25H17.5a4 4 0 100 8 4.1 4.1 0 004-4zm-10.655 5.5a.25.25 0 01-.234-.024.247.247 0 01-.111-.207V6.436a.5.5 0 01.311-.463l7.5-3.069a.5.5 0 01.689.463v.876a.249.249 0 01-.155.231z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});