define("ember-svg-jar/inlined/crafts-bottle-art-ship", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crafts-bottle-art-ship</title><path d=\"M22.5 8.25H20v-1a3 3 0 00-3-3H3a3 3 0 00-3 3v9.5a3 3 0 003 3h14a3 3 0 003-3v-1h2.5a1.5 1.5 0 001.5-1.5v-4.5a1.5 1.5 0 00-1.5-1.5zm-.5 5a.5.5 0 01-.5.5H20a2 2 0 00-2 2v1a1 1 0 01-1 1H3a1 1 0 01-1-1v-9.5a1 1 0 011-1h14a1 1 0 011 1v1a2 2 0 002 2h1.5a.5.5 0 01.5.5z\"/><path d=\"M14.5 14.25H10a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h2.5a1 1 0 00.707-1.707l-3.5-3.5A1 1 0 008 8.25v5.5a.5.5 0 01-.5.5H5a.5.5 0 00-.5.5v.5a1 1 0 001 1h7a3 3 0 002.4-1.2.5.5 0 00-.4-.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});