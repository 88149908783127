define("ember-svg-jar/inlined/trip-road-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trip-road-circle</title><path d=\"M18.887 13a.5.5 0 00.353-.146c.185-.185 4.51-4.55 4.51-7.991a4.864 4.864 0 00-9.728 0c0 3.441 4.327 7.806 4.511 7.991a.5.5 0 00.354.146zM17.25 4.864A1.637 1.637 0 1118.886 6.5a1.637 1.637 0 01-1.636-1.636z\"/><path d=\"M19.149 14.481a1.767 1.767 0 01-.262.019 2 2 0 01-1.414-.586c-2.108-2.105-4.739-5.673-4.937-8.709a.5.5 0 00-.387-.455A9.573 9.573 0 0010 4.5 9.739 9.739 0 00.8 17.477a.5.5 0 00.8.211c.4-.351.8-.676 1.187-.959a.75.75 0 01.889 1.209 19.8 19.8 0 00-1.552 1.285.5.5 0 00-.071.66 9.739 9.739 0 0017.662-4.867.5.5 0 00-.569-.535zM9.6 16.134a5.769 5.769 0 00-1.084-.1 5.875 5.875 0 00-1.548.211.739.739 0 01-.2.026.75.75 0 01-.2-1.474 7.443 7.443 0 011.939-.263 7.365 7.365 0 011.361.129.75.75 0 11-.268 1.471zm7.283 2.2a.752.752 0 01-.715.522.762.762 0 01-.228-.035 18.193 18.193 0 01-2.827-1.269.762.762 0 01-.331-1.018.739.739 0 011-.325l.023.012A16.812 16.812 0 0016.4 17.4a.75.75 0 01.485.937z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});