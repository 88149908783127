define("ember-svg-jar/inlined/paginate-filter-7", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paginate-filter-7</title><path d=\"M22 0H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2V2a2 2 0 00-2-2zm-5.385 7.56L13.7 14.778a.75.75 0 11-1.39-.562l2.677-6.625a.25.25 0 00-.232-.344H12a.25.25 0 00-.25.25V8a.75.75 0 01-1.5 0V7a1.251 1.251 0 011.25-1.25h3.962a1.3 1.3 0 011.1.6 1.207 1.207 0 01.053 1.207z\"/><path d=\"M19.5 22h-17a.5.5 0 01-.5-.5v-17a1 1 0 00-2 0V22a2 2 0 002 2h17.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});