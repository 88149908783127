define("ember-svg-jar/inlined/real-estate-action-building-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-building-warning</title><path d=\"M6.723 6.052l-3-1.5a.5.5 0 00-.447 0l-3 1.5A.5.5 0 000 6.5V14a.5.5 0 00.5.5H2a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5A.5.5 0 007 14V6.5a.5.5 0 00-.277-.448zM5 10.5H2a.5.5 0 010-1h3a.5.5 0 010 1zM5 8H2a.5.5 0 110-1h3a.5.5 0 010 1zM13.5 14.23c.37-.709 1.114-2.137 1.434-2.692a.5.5 0 00.066-.249V3a.5.5 0 00-.308-.461l-6-2.5A.5.5 0 008 .5V14a.5.5 0 00.5.5h4.555a.5.5 0 00.445-.27zM10 4.5h3a.5.5 0 010 1h-3a.5.5 0 010-1zM10 7h3a.5.5 0 010 1h-3a.5.5 0 010-1zm0 3.5a.5.5 0 010-1h3a.5.5 0 010 1zM23.838 21.906l-5.056-9.634a1.449 1.449 0 00-2.562 0l-5.056 9.634a1.431 1.431 0 00.049 1.408 1.457 1.457 0 001.232.686h10.112a1.454 1.454 0 001.233-.687 1.427 1.427 0 00.048-1.407zM16.752 15.5a.75.75 0 111.5 0v3a.75.75 0 01-1.5 0zm.749 6.751a1 1 0 111-1 1 1 0 01-1.001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});