define("ember-svg-jar/inlined/house-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>house-heart</title><path d=\"M20.07 6.536a.249.249 0 00.43-.172V1.493a.5.5 0 00-.5-.5h-3.5a.5.5 0 00-.5.5v.589a.5.5 0 00.139.345zM23.722 11.8l-11-11.5a1.034 1.034 0 00-1.445 0l-11 11.5A1 1 0 001 13.493h1.5a.5.5 0 01.5.5v8.5a1.5 1.5 0 001.5 1.5h15a1.5 1.5 0 001.5-1.5v-8.5a.5.5 0 01.5-.5H23a1 1 0 00.722-1.692zm-7.229 4.45l-3.771 3.933a1 1 0 01-1.444 0L7.5 16.243a2.948 2.948 0 011.62-4.988 2.91 2.91 0 012.557.825l.146.146a.25.25 0 00.353 0l.146-.146a2.949 2.949 0 014.17 4.171z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});