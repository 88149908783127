define("ember-svg-jar/inlined/messages-bubble-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-heart</title><path d=\"M12 1.337C5.659 1.337.5 5.587.5 10.811a8.6 8.6 0 003.175 6.54l-2.3 4.587a.5.5 0 00.659.677l6.149-2.862a13.733 13.733 0 003.817.532c6.341 0 11.5-4.25 11.5-9.474S18.341 1.337 12 1.337zm4.633 10.072L12.5 15.72a.5.5 0 01-.721 0L7.64 11.4a2.949 2.949 0 014.178-4.163l.323.322.322-.322a2.949 2.949 0 014.723.766 2.946 2.946 0 01-.553 3.406z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});