define("ember-svg-jar/inlined/products-purse-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>products-purse-1</title><path d=\"M15.354 15a.25.25 0 00-.239.179 3.251 3.251 0 01-6.23 0A.25.25 0 008.646 15H6.167A5.529 5.529 0 012.6 13.714a.25.25 0 00-.4.134l-.74 3.142a5.5 5.5 0 005.353 6.76h10.374a5.5 5.5 0 005.353-6.76l-.74-3.142a.25.25 0 00-.4-.134A5.529 5.529 0 0117.833 15zM2.667 5a.392.392 0 00-.417.361v4.445A3.815 3.815 0 006.167 13.5h2.479a.25.25 0 00.239-.179 3.251 3.251 0 016.23 0 .25.25 0 00.239.179h2.479a3.815 3.815 0 003.917-3.694V5.361A.393.393 0 0021.333 5H16.75a.25.25 0 01-.25-.25v-1.5a3 3 0 00-3-3h-3a3 3 0 00-3 3v1.5a.25.25 0 01-.25.25zM9.5 3.25a1 1 0 011-1h3a1 1 0 011 1v1.5a.25.25 0 01-.25.25h-4.5a.25.25 0 01-.25-.25z\"/><circle cx=\"12\" cy=\"14.25\" r=\"1.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});