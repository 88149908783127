define("ember-svg-jar/inlined/shield-check-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shield-check-1</title><path d=\"M24 1.953A1.96 1.96 0 0022.045.006H1.96A1.961 1.961 0 00.012 1.964L0 9.306a15.145 15.145 0 0011.861 14.668.976.976 0 00.4 0A15.144 15.144 0 0023.988 9.2zM12 17.994a7.25 7.25 0 010-14.5 1 1 0 110 2 5.25 5.25 0 105.167 6.19 1 1 0 111.967.356A7.241 7.241 0 0112 17.994zm6.957-12.645a1 1 0 010 1.414l-5.7 5.7a1.787 1.787 0 01-2.519 0l-1.789-1.794a1 1 0 011.415-1.415l1.282 1.284a.5.5 0 00.708 0l5.189-5.189a1 1 0 011.414 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});