define("ember-svg-jar/inlined/single-neutral-actions-edit-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-edit-1</title><path d=\"M.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M15.084 22.49a.5.5 0 00-.13-.479L12.739 19.8a.5.5 0 00-.834.216l-.886 3.1a.5.5 0 00.481.637.492.492 0 00.137-.019l3.1-.886a.5.5 0 00.347-.358zM13.054 17.989a.5.5 0 000 .707l3 3a.5.5 0 00.707 0l5.01-5.01a.5.5 0 000-.707l-3-3a.5.5 0 00-.707 0zM19.479 12.271l3 3a.453.453 0 00.327.125.616.616 0 00.426-.171 2.621 2.621 0 000-3.707 2.641 2.641 0 00-3.755.048.5.5 0 00.002.705zM.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M15.084 22.49a.5.5 0 00-.13-.479L12.739 19.8a.5.5 0 00-.834.216l-.886 3.1a.5.5 0 00.481.637.492.492 0 00.137-.019l3.1-.886a.5.5 0 00.347-.358zM13.054 17.989a.5.5 0 000 .707l3 3a.5.5 0 00.707 0l5.01-5.01a.5.5 0 000-.707l-3-3a.5.5 0 00-.707 0zM19.479 12.271l3 3a.453.453 0 00.327.125.616.616 0 00.426-.171 2.621 2.621 0 000-3.707 2.641 2.641 0 00-3.755.048.5.5 0 00.002.705z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});