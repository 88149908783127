define("ember-svg-jar/inlined/gift-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gift-heart</title><path d=\"M16.08 8.49a1.251 1.251 0 00-2.135-.89 7.311 7.311 0 00-.875 2.341.25.25 0 00.3.3c2.2-.541 2.71-.92 2.71-1.751zM10.626 10.248a.25.25 0 00.3-.3 7.3 7.3 0 00-.87-2.348 1.251 1.251 0 00-1.769 1.772 7.309 7.309 0 002.339.876z\"/><path d=\"M12.75 6.1a.25.25 0 00.41.192 2.79 2.79 0 013.614.248 2.732 2.732 0 01.806 1.95 2.637 2.637 0 01-.569 1.67.25.25 0 00.2.406h5.852a.251.251 0 00.237-.166 7.32 7.32 0 00.455-2.6A6.18 6.18 0 0019.7 2.508c-.485-.194-3.433-1.6-6.948 1.61zM14.661 12.066a.25.25 0 00-.164.438l2 1.747a.75.75 0 01-.988 1.129l-2.341-2.049a.25.25 0 00-.415.188v8.2a.25.25 0 00.4.2c3.035-2.188 7.105-5.786 9.212-9.483a.249.249 0 00-.218-.373zM6.8 10.566a.25.25 0 00.2-.406 2.637 2.637 0 01-.569-1.67 2.732 2.732 0 01.806-1.946 2.8 2.8 0 013.604-.244.25.25 0 00.409-.2V4.118C7.736.915 4.838 2.294 4.3 2.508A6.181 6.181 0 00.25 7.792 7.3 7.3 0 00.706 10.4a.25.25 0 00.236.168zM11.25 13.519a.25.25 0 00-.415-.188L8.494 15.38a.75.75 0 01-.988-1.129l2-1.747a.25.25 0 00-.164-.438H1.86a.249.249 0 00-.217.373c2.106 3.7 6.176 7.3 9.211 9.483a.25.25 0 00.4-.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});