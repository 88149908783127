define("ember-svg-jar/inlined/app-window-four", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-four</title><path d=\"M3 11h5.5a2.753 2.753 0 002.75-2.75v-4.5A2.752 2.752 0 008.5 1H3A2.752 2.752 0 00.25 3.751v4.5A2.753 2.753 0 003 11zM1.75 4.751A.25.25 0 012 4.5h7.5a.25.25 0 01.25.25v3.5A1.252 1.252 0 018.5 9.5H3a1.252 1.252 0 01-1.25-1.25zM12.75 8.251A2.753 2.753 0 0015.5 11H21a2.753 2.753 0 002.75-2.75v-4.5A2.752 2.752 0 0021 1h-5.5a2.752 2.752 0 00-2.75 2.75zm1.5-3.5a.25.25 0 01.25-.25H22a.25.25 0 01.25.25v3.5A1.252 1.252 0 0121 9.5h-5.5a1.252 1.252 0 01-1.25-1.25zM3 23h5.5a2.753 2.753 0 002.75-2.75v-4.5A2.752 2.752 0 008.5 13H3a2.752 2.752 0 00-2.75 2.75v4.5A2.753 2.753 0 003 23zm-1.25-6.25A.25.25 0 012 16.5h7.5a.25.25 0 01.25.25v3.5A1.252 1.252 0 018.5 21.5H3a1.252 1.252 0 01-1.25-1.25zM12.75 20.251A2.753 2.753 0 0015.5 23H21a2.753 2.753 0 002.75-2.75v-4.5A2.752 2.752 0 0021 13h-5.5a2.752 2.752 0 00-2.75 2.75zm1.5-3.5a.25.25 0 01.25-.25H22a.25.25 0 01.25.25v3.5A1.252 1.252 0 0121 21.5h-5.5a1.252 1.252 0 01-1.25-1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});