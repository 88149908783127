define("ember-svg-jar/inlined/button-refresh-one", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-refresh-one</title><path d=\"M3.36 8.017A1 1 0 005 7.249v-1A.25.25 0 015.25 6H18.5a.25.25 0 01.25.25v5a1.25 1.25 0 102.5 0v-5.5A2.252 2.252 0 0019 3.5H5.25A.25.25 0 015 3.249v-1a1 1 0 00-1.64-.769l-3 2.5a1 1 0 000 1.537zM23.651 17.99l-3.5-3a1 1 0 00-1.651.759v1.5a.25.25 0 01-.25.25H5.5a.25.25 0 01-.25-.25v-4.981a1.25 1.25 0 00-2.5 0v5.481A2.253 2.253 0 005 20h13.25a.25.25 0 01.25.25v1.5a1 1 0 001.651.758l3.5-3a1 1 0 000-1.517z\"/><path d=\"M12.5 16.749a1 1 0 001-1V7.831a1 1 0 00-1.693-.721l-2.97 2.855a1 1 0 101.386 1.442l.854-.821a.25.25 0 01.423.18v4.983a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});