define("ember-svg-jar/inlined/aircraft-hotair-balloon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>aircraft-hotair-balloon</title><path d=\"M18.729 1.194H7.521a5.239 5.239 0 00-4.2 2.112.248.248 0 01-.269.091l-1.32-.378A1.246 1.246 0 00.021 4.174v4.531a1.23 1.23 0 00.541 1.023 1.211 1.211 0 001.094.158l1.4-.4a.25.25 0 01.269.092 5.223 5.223 0 004.2 2.113h1.259a.25.25 0 01.237.171l.684 2.052a1.5 1.5 0 001.422 1.024h3.78a1.5 1.5 0 001.423-1.025l.683-2.051a.25.25 0 01.237-.171h1.479a5.25 5.25 0 000-10.5zm-3.877 10.829l-.249.747a.25.25 0 01-.237.171h-2.7a.25.25 0 01-.237-.171l-.249-.747a.25.25 0 01.237-.329h3.2a.25.25 0 01.237.329zM10.13 17.059a4.213 4.213 0 00-3.6-2 4.264 4.264 0 00-4.077 3.106 3.25 3.25 0 00-2.424 3.141 1.5 1.5 0 001.5 1.5h11.5a1.5 1.5 0 001.5-1.5 4.263 4.263 0 00-4.399-4.247zm2.21 3.641a.249.249 0 01-.207.11H2.556a.226.226 0 01-.16-.386 1.249 1.249 0 01.888-.364c.034-.006.1 0 .14-.009a1 1 0 00.877-.915 2.236 2.236 0 014.32-.634 1.009 1.009 0 001.057.63.7.7 0 00.1-.017 2.312 2.312 0 01.5-.055 2.252 2.252 0 012.085 1.406.247.247 0 01-.023.234z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});