define("ember-svg-jar/inlined/time-clock-file-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-clock-file-sync</title><path d=\"M23.487 2.928L21.073.513A1.749 1.749 0 0019.836 0H8.25A1.749 1.749 0 006.5 1.751v8.035a.247.247 0 00.241.246 7.7 7.7 0 01.968.1.248.248 0 00.291-.24V1.751a.251.251 0 01.25-.25h11.586l.177.073 2.414 2.414a.249.249 0 01.073.177v14.086a.251.251 0 01-.25.25h-7.893a.247.247 0 00-.242.208 7.167 7.167 0 01-.3 1.178c-.014.038.022.114.175.114h8.26a1.75 1.75 0 001.75-1.75V4.165a1.746 1.746 0 00-.513-1.237z\"/><path d=\"M19 8.251a3.254 3.254 0 01-3.25 3.25 3.164 3.164 0 01-.87-.124.212.212 0 00-.254.221c.042.327.074.8.09 1.067a.314.314 0 00.194.256 4.654 4.654 0 00.84.08A4.75 4.75 0 1011 8.251a4.635 4.635 0 00.456 1.981.277.277 0 00.276.08 1.959 1.959 0 01.984-.274.2.2 0 00.164-.263A3.25 3.25 0 1119 8.251zM12.92 11.56a.5.5 0 00-.545.109l-1.3 1.3a.245.245 0 01-.343 0 6.312 6.312 0 00-4.078-1.716A6.465 6.465 0 000 17.547 6.375 6.375 0 0011.357 21.6 1 1 0 109.8 20.354a4.374 4.374 0 11-.5-5.982.258.258 0 010 .368l-1.382 1.386a.5.5 0 00.353.853h4.458a.5.5 0 00.5-.5v-4.457a.5.5 0 00-.309-.462z\"/><path d=\"M15.75 5.733a.75.75 0 00-.75.75v1.768a.75.75 0 00.75.75h1.768a.75.75 0 100-1.5h-.767a.252.252 0 01-.251-.252v-.766a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});