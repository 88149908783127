define("ember-svg-jar/inlined/business-team-goal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-team-goal</title><path d=\"M23.012.033h-5.905a1.725 1.725 0 00-1.723 1.722V5.2a.492.492 0 11-.984 0V2.251A.25.25 0 0014.15 2H9.725A.738.738 0 009.2 3.262l1.762 1.761a.252.252 0 010 .354L9.2 7.139A.737.737 0 009.725 8.4h7.382a1.724 1.724 0 001.722-1.721.245.245 0 01.246-.246h2.456a.249.249 0 01.25.25v16.233a.985.985 0 001.969 0V1.018a.233.233 0 00-.011-.065.224.224 0 010-.115.247.247 0 00.011-.067.738.738 0 00-.738-.738zM2.471 20.839A4.669 4.669 0 00.282 23.3a.491.491 0 00.46.666h6.566a.25.25 0 00.25-.264c0-.076-.007-.151-.007-.228a6.869 6.869 0 01.235-1.774.249.249 0 00-.077-.253 4.552 4.552 0 00-.964-.643.253.253 0 01-.136-.2.248.248 0 01.095-.221 3.448 3.448 0 10-5.577-2.707 3.426 3.426 0 001.378 2.74.25.25 0 01-.034.42zM2.7 17.1a.25.25 0 01.373-.133 4.452 4.452 0 002.417.716 4.25 4.25 0 00.936-.111.114.114 0 01.094.023.111.111 0 01.042.088 1.978 1.978 0 01-3.955 0A1.9 1.9 0 012.7 17.1z\"/><path d=\"M12.3 18.289a5.7 5.7 0 00-3.354 5.187.492.492 0 00.492.492h10.416a.492.492 0 00.492-.492 5.705 5.705 0 00-3.431-5.224.248.248 0 01-.15-.208.253.253 0 01.113-.231 3.859 3.859 0 00.623-.5 4.13 4.13 0 10-5.928-.094 4.062 4.062 0 00.76.635.246.246 0 01.113.228.251.251 0 01-.146.207zm3.084 3.264a.738.738 0 11-1.476 0v-1.211a.738.738 0 011.476 0zm-3.455-7.143a2.531 2.531 0 01.171-.91.25.25 0 01.16-.149.247.247 0 01.217.035A5.587 5.587 0 0015.7 14.41a5.53 5.53 0 001.314-.161.166.166 0 01.141.03.17.17 0 01.064.131 2.639 2.639 0 01-1.393 2.317 2.646 2.646 0 01-3.9-2.317z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});