define("ember-svg-jar/inlined/paragraph-left-align", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paragraph-left-align</title><path d=\"M22.5 23.271h-21a1.25 1.25 0 010-2.5h21a1.25 1.25 0 010 2.5zM19.637 3.225H1.5a1.25 1.25 0 010-2.5h18.137a1.25 1.25 0 010 2.5zM15.818 9.907H1.5a1.25 1.25 0 010-2.5h14.318a1.25 1.25 0 110 2.5zM18.682 16.589H1.5a1.25 1.25 0 110-2.5h17.182a1.25 1.25 0 010 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});