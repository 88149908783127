define("ember-svg-jar/inlined/card-game-card-spade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>card-game-card-spade</title><path d=\"M21.5 21a3 3 0 01-3 3h-13a3 3 0 01-3-3V3a3 3 0 013-3h13a3 3 0 013 3zM5.5 2a1 1 0 00-1 1v18a1 1 0 001 1h13a1 1 0 001-1V3a1 1 0 00-1-1z\"/><path d=\"M15.5 12.5c0-1.8-2.808-4.977-3.128-5.334a.516.516 0 00-.744 0C11.308 7.523 8.5 10.7 8.5 12.5c0 1.842 1.43 2.327 2.529 2.454a.251.251 0 01.221.248V17a.75.75 0 001.5 0v-1.8a.25.25 0 01.221-.248c1.099-.125 2.529-.61 2.529-2.452z\"/><circle cx=\"7.25\" cy=\"4.75\" r=\"1.25\"/><circle cx=\"16.75\" cy=\"19.25\" r=\"1.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});