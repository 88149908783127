define("ember-svg-jar/inlined/keyboard-delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-delete</title><path d=\"M19.5.5h-15a4.012 4.012 0 00-4 4v15a4.011 4.011 0 004 4h15a4.011 4.011 0 004-4v-15a4.012 4.012 0 00-4-4zm0 14.5a1.5 1.5 0 01-1.5 1.5H8.207a1.494 1.494 0 01-1.061-.439l-3-3a1.5 1.5 0 010-2.121l3-3a1.5 1.5 0 011.061-.44H18A1.5 1.5 0 0119.5 9z\"/><path d=\"M10.97 14.028a.751.751 0 001.06 0l.793-.793a.25.25 0 01.354 0l.793.793a.75.75 0 001.06-1.061l-.793-.793a.25.25 0 010-.353l.793-.793a.75.75 0 00-1.06-1.061l-.793.793a.25.25 0 01-.354 0l-.793-.793a.75.75 0 10-1.06 1.061l.793.793a.25.25 0 010 .353l-.793.793a.75.75 0 000 1.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});