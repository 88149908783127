define("ember-svg-jar/inlined/data-file-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-search</title><path d=\"M8.754 21.2l2.506 2.506a1 1 0 001.414-1.414l-2.506-2.506a.241.241 0 01-.03-.3 5.515 5.515 0 10-1.688 1.685.241.241 0 01.304.029zM5.5 20.033a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5z\"/><path d=\"M24 18.5V4.414A2 2 0 0023.414 3L21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.3a.242.242 0 00.222.241 6.927 6.927 0 011.453.309A.243.243 0 008 9.624V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-9.118a.241.241 0 00-.23.169 6.889 6.889 0 01-.42 1.014.261.261 0 00.012.232l.459.458a.472.472 0 00.285.126H22a2 2 0 002-1.999z\"/><path d=\"M11.274 12.479a.981.981 0 001.018-.391l1.644-2.32a.25.25 0 01.332-.071l1.507.905A1 1 0 0017 10.451a.9.9 0 00.105-.123l2.688-3.734a1 1 0 10-1.624-1.168l-2.015 2.8a.252.252 0 01-.332.068L14.3 7.387a1 1 0 00-1.329.279l-2.312 3.266a.961.961 0 00-.173.584.365.365 0 00.053.136 7.237 7.237 0 01.634.764.289.289 0 00.101.063z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});