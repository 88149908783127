define("ember-svg-jar/inlined/locker-room-wash-hands-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>locker-room-wash-hands-1</title><path d=\"M14.954 3.5a1 1 0 001-1V1a1 1 0 10-2 0v1.5a1 1 0 001 1zM14.954 8a1 1 0 001-1V5.5a1 1 0 10-2 0V7a1 1 0 001 1zM14.954 12.5a1 1 0 001-1V10a1 1 0 10-2 0v1.5a1 1 0 001 1zM18.064 2.786a1.013 1.013 0 001.291.578 1 1 0 00.578-1.29L19.388.645a1 1 0 10-1.869.713zM19.181 5.857l.578 1.518a1.013 1.013 0 001.291.578 1 1 0 00.578-1.291l-.578-1.517a1 1 0 00-1.869.712zM23.229 11.131l-.567-1.487a1 1 0 10-1.869.713l.567 1.486a1.015 1.015 0 001.291.579 1 1 0 00.578-1.291z\"/><path d=\"M19.7 18h-6.75a.5.5 0 010-1h5.75a1.25 1.25 0 100-2.5H9.558a.25.25 0 01-.177-.427l2.519-2.52a1.665 1.665 0 00-2.252-2.447L5.9 12.639A5 5 0 012.469 14H1.205a.5.5 0 00-.5.5V22a.5.5 0 00.5.5h1.75l1.627.814A6.506 6.506 0 007.489 24H18.2a1.25 1.25 0 000-2.5h-5.25a.5.5 0 010-1h6.75a1.25 1.25 0 000-2.5zM10.554 3.364a1.006 1.006 0 001.29-.579l.544-1.428a1 1 0 10-1.869-.711l-.544 1.429a1 1 0 00.579 1.289zM8.858 7.953a1.007 1.007 0 001.291-.578l.578-1.518a1 1 0 00-1.869-.712L8.28 6.662a1 1 0 00.578 1.291z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});