define("ember-svg-jar/inlined/style-three-pin-fishing-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-fishing-1</title><circle cx=\"11.993\" cy=\"10.741\" r=\".75\"/><path d=\"M22.075 6.846A.5.5 0 0021.6 6.5H8.75a.75.75 0 010-1.5h12.041A.25.25 0 0021 4.611 10.768 10.768 0 0012 0a11.312 11.312 0 00-4.993 1.145.012.012 0 00-.007.01v9.119a.5.5 0 00.25.434 1.488 1.488 0 010 2.584.5.5 0 00-.25.433v1.525a1.5 1.5 0 003 0 .5.5 0 011 0 2.5 2.5 0 01-5 0v-1.525a.5.5 0 00-.25-.433 1.488 1.488 0 010-2.584.5.5 0 00.25-.434V2.181a.25.25 0 00-.393-.205A9.947 9.947 0 003 4.611.25.25 0 003.209 5H4.25a.75.75 0 010 1.5H2.4a.5.5 0 00-.476.346A8.59 8.59 0 001.5 9.5c0 5.746 7.3 12.284 9.539 14.151a1.5 1.5 0 001.921 0C15.2 21.785 22.5 15.247 22.5 9.5a8.59 8.59 0 00-.425-2.654zM20 12.076a1 1 0 01-.67.943.985.985 0 01-.329.056 1 1 0 01-.781-.375l-.352-.44a.5.5 0 00-.748-.037A5.31 5.31 0 0113.5 14c-2.538 0-4.338-2.608-4.414-2.719a.5.5 0 010-.562C9.162 10.608 10.961 8 13.5 8a5.31 5.31 0 013.62 1.777.5.5 0 00.748-.038l.352-.439a1 1 0 011.78.625z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});