define("ember-svg-jar/inlined/protection-helmet-viking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>protection-helmet-viking</title><path d=\"M4.311 8.991a.5.5 0 01.034.635 9.431 9.431 0 00-1.7 3.94.5.5 0 01-.87.24 7.17 7.17 0 01-.022-9.367.543.543 0 01.59-.159.532.532 0 01.34.509 6.139 6.139 0 001.628 4.202zM23.975 9.1a7.138 7.138 0 01-1.763 4.713.5.5 0 01-.868-.242 9.434 9.434 0 00-1.7-3.942.5.5 0 01.037-.637 6.053 6.053 0 001.622-4.2.532.532 0 01.94-.35A7.1 7.1 0 0123.975 9.1zM19.5 15.749a.5.5 0 00.5-.5 8 8 0 00-16 0 .5.5 0 00.5.5zM4.5 17.249a.5.5 0 00-.5.5v1a1 1 0 001 1h14a1 1 0 001-1v-1a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});