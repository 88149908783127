define("ember-svg-jar/inlined/award-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>award-badge</title><path d=\"M12.17 9.569a.251.251 0 00-.3 0l-7.17 5.5a.5.5 0 00-.2.4v1.849a.5.5 0 00.8.4l5.649-4.332a1.75 1.75 0 012.133 0l5.608 4.326a.5.5 0 00.8-.395V15.47a.5.5 0 00-.194-.4z\"/><path d=\"M12.17 14.569a.251.251 0 00-.3 0l-7.17 5.5a.5.5 0 00-.2.4v2.027a1.511 1.511 0 00.839 1.347 1.489 1.489 0 001.574-.158l5.1-3.914 5.068 3.911a1.5 1.5 0 002.414-1.19V20.47a.5.5 0 00-.194-.4zM10.953 3.378a1.75 1.75 0 012.133 0l5.608 4.327a.5.5 0 00.806-.395V2.006a2 2 0 00-2-2h-11a2 2 0 00-2 2v5.307a.5.5 0 00.8.4z\"/><path d=\"M10.953 8.378a1.75 1.75 0 012.133 0l5.608 4.327a.5.5 0 00.806-.395v-1.839a.5.5 0 00-.2-.4l-7.13-5.502a.249.249 0 00-.3 0l-7.17 5.5a.5.5 0 00-.2.4v1.849a.5.5 0 00.8.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});