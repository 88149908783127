define("ember-svg-jar/inlined/single-man-actions-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-location</title><path d=\"M12 16a6.465 6.465 0 011.8-4.477.25.25 0 00-.082-.4l-2.066-.886a.249.249 0 01-.152-.229V8.87a.252.252 0 01.074-.177 3.311 3.311 0 00.779-2.231.251.251 0 01.088-.176 1.873 1.873 0 00.538-1.4 1.58 1.58 0 00-.317-1.015.251.251 0 01-.034-.264 2.531 2.531 0 00.157-1.727C12.444.493 10.742 0 9.388 0a3.752 3.752 0 00-3.163 1.324.251.251 0 01-.212.118 1.072 1.072 0 00-.9.375c-.372.493-.106 1.17.176 1.732a.251.251 0 01-.046.288 1.3 1.3 0 00-.162.2A1.763 1.763 0 004.82 5a1.521 1.521 0 00.638 1.331.249.249 0 01.117.194A3.167 3.167 0 006.42 8.7a.25.25 0 01.08.183v1.123a.248.248 0 01-.151.229l-3.937 1.687C1.14 12.468.957 13.626.705 15.23c-.055.359-.117.751-.2 1.179A.5.5 0 001 17h10.811a.25.25 0 00.189-.087.247.247 0 00.058-.2A4.828 4.828 0 0112 16zM18.5 11a5.006 5.006 0 00-5 5c0 3.171 4.457 7.664 4.647 7.854a.5.5 0 00.707 0c.189-.19 4.646-4.683 4.646-7.854a5.006 5.006 0 00-5-5zm-1.75 5a1.75 1.75 0 111.75 1.75A1.753 1.753 0 0116.749 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});