define("ember-svg-jar/inlined/rabbit-side", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rabbit-side</title><path d=\"M21.6 7.655L10.492.9a2.249 2.249 0 10-2.338 3.842l6.857 4.172a4.955 4.955 0 00-.782 1.514H6.5a6.5 6.5 0 000 13h4.83a.5.5 0 00.5-.5v-1.169a.416.416 0 00-.416-.416h-3.5a.751.751 0 01-.744-.842 4.792 4.792 0 00-.7-3.236A1.85 1.85 0 005 16.678a.75.75 0 010-1.5 3.31 3.31 0 012.6 1.1 5.564 5.564 0 011.1 3.568h2.713a1.918 1.918 0 011.916 1.916v1.169a.5.5 0 00.5.5H17.5a1.5 1.5 0 00.019-3 5.435 5.435 0 001.462-3.5H19a5 5 0 002.6-9.273zm-2.1 4.273a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});