define("ember-svg-jar/inlined/outdoors-fire-camp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-fire-camp</title><path d=\"M9.489 5.01a.494.494 0 00-.827.041A5.4 5.4 0 007.8 7.372a3.781 3.781 0 107.562 0A7.923 7.923 0 0011.331.644a.5.5 0 00-.71.574 6.512 6.512 0 01-.111 4.557.251.251 0 01-.422.041 35.678 35.678 0 01-.599-.806zM2 12.415a2 2 0 00-2 2v7a2 2 0 002 2h20a2 2 0 002-2v-7a2 2 0 00-2-2zm3.5 8.75a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25v-2a.25.25 0 01.25-.25h3a.25.25 0 01.25.25zm13-6.5a.25.25 0 01.25-.25h3a.25.25 0 01.25.25v2a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25zm0 4.5a.25.25 0 01.25-.25h3a.25.25 0 01.25.25v2a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25zm-5.5-4.5a.25.25 0 01.25-.25h3a.25.25 0 01.25.25v2a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25zm0 4.5a.25.25 0 01.25-.25h3a.25.25 0 01.25.25v2a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25zm-5.5-4.5a.25.25 0 01.25-.25h3a.25.25 0 01.25.25v2a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25zm0 4.5a.25.25 0 01.25-.25h3a.25.25 0 01.25.25v2a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25zm-2-2.5a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25v-2a.25.25 0 01.25-.25h3a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});