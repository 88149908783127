define("ember-svg-jar/inlined/project-notes-buildings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>project-notes-buildings</title><path d=\"M19.75 1.76h-1a1 1 0 000 2h.5a.5.5 0 01.5.5v17.23a.5.5 0 01-.5.5H4.75a.5.5 0 01-.5-.5V4.26a.5.5 0 01.5-.5h.5a1 1 0 100-2h-1a2 2 0 00-2 2v18.23a2 2 0 002 2h15.5a2 2 0 002-2V3.76a2 2 0 00-2-2z\"/><path d=\"M8 6.01h8a1 1 0 001-1v-2a1 1 0 00-1-1h-1.171a.5.5 0 01-.471-.333 2.5 2.5 0 00-4.716 0 .5.5 0 01-.471.333H8a1 1 0 00-1 1v2a1 1 0 001 1zM14.5 20.24H16a.5.5 0 00.5-.5v-9a.5.5 0 00-.235-.424l-4-2.5a.5.5 0 00-.53 0l-4 2.5a.5.5 0 00-.235.424v9a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-2a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v2a.5.5 0 00.5.5zm-4-9h3a.75.75 0 010 1.5h-3a.75.75 0 010-1.5zm3 4.5h-3a.75.75 0 010-1.5h3a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});