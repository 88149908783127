define("ember-svg-jar/inlined/qr-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>qr-code</title><path d=\"M1 8h6a1 1 0 001-1V1a1 1 0 00-1-1H1a1 1 0 00-1 1v6a1 1 0 001 1zm1-5.75A.25.25 0 012.25 2h3.5a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25h-3.5A.25.25 0 012 5.75zM7 16H1a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1v-6a1 1 0 00-1-1zm-1 5.75a.25.25 0 01-.25.25h-3.5a.25.25 0 01-.25-.25v-3.5a.25.25 0 01.25-.25h3.5a.25.25 0 01.25.25zM23 0h-6a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1V1a1 1 0 00-1-1zm-1 5.75a.25.25 0 01-.25.25h-3.5a.25.25 0 01-.25-.25v-3.5a.25.25 0 01.25-.25h3.5a.25.25 0 01.25.25zM10 4.25h1a.75.75 0 000-1.5.25.25 0 01-.25-.25V1a.75.75 0 00-1.5 0v2.5a.75.75 0 00.75.75zM13 1.75a.25.25 0 01.25.25v5.5a.75.75 0 001.5 0V1A.75.75 0 0014 .25h-1a.75.75 0 000 1.5zM5.75 10.5a.75.75 0 00.75.75H10a.75.75 0 00.75-.75v-4a.75.75 0 00-1.5 0v3a.25.25 0 01-.25.25H6.5a.75.75 0 00-.75.75z\"/><path d=\"M3 9.75a.75.75 0 00-.75.75V13a.25.25 0 01-.25.25H1a.75.75 0 000 1.5h13a.75.75 0 00.75-.75v-3a.75.75 0 00-1.5 0v2a.25.25 0 01-.25.25H4a.25.25 0 01-.25-.25v-2.5A.75.75 0 003 9.75zM13.75 17a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75v4a.75.75 0 001.5 0v-3a.25.25 0 01.25-.25h2a.75.75 0 00.75-.75zM23 22.25h-8.5a.25.25 0 01-.25-.25v-2a.75.75 0 00-1.5 0v3a.75.75 0 00.75.75H23a.75.75 0 000-1.5z\"/><path d=\"M16.5 20.25h3a.75.75 0 00.75-.75v-3a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75v3a.75.75 0 00.75.75zm.75-2.75a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25zM22.5 12.75a.75.75 0 00-.75.75V20a.75.75 0 001.5 0v-6.5a.75.75 0 00-.75-.75zM23.25 10a.75.75 0 00-.75-.75H17a.75.75 0 00-.75.75v3a.75.75 0 001.5 0v-2a.25.25 0 01.25-.25h4.5a.75.75 0 00.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});