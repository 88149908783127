define("ember-svg-jar/inlined/tools-shovel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-shovel</title><path d=\"M15.817 13.694a.5.5 0 01-.708 0l-7.8-7.8V1.811A1.805 1.805 0 004.227.534L.535 4.227a1.805 1.805 0 001.276 3.082H5.9l7.8 7.8a.5.5 0 010 .707l-2.122 2.122a.5.5 0 000 .707l3.889 3.89a5 5 0 007.072-7.072l-3.89-3.889a.5.5 0 00-.707 0zM4.882 2.708a.25.25 0 01.427.176v2.175a.25.25 0 01-.25.25H2.885a.25.25 0 01-.177-.427z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});