define("ember-svg-jar/inlined/single-woman-actions-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-sync</title><path d=\"M.152 17.931a.5.5 0 00.477.626h2.3a.248.248 0 01.243.2l.921 4.847v.007a.5.5 0 00.484.389h2.969a.5.5 0 00.484-.389v-.006l.921-4.847a.247.247 0 01.243-.2h.116a.247.247 0 00.246-.275 6.49 6.49 0 01-.042-.715 7.156 7.156 0 011.2-3.967.249.249 0 00.032-.2c-.564-2.076-.686-3.059-1.763-4.068a.5.5 0 00-.757.1L6.493 12.16a.5.5 0 01-.837 0L3.933 9.411a.5.5 0 00-.755-.1c-1.153 1.062-1.066 1.469-3.026 8.62zM6.051 8.412a4.206 4.206 0 10-4.206-4.206 4.211 4.211 0 004.206 4.206zm0-1.979A2.224 2.224 0 013.914 4.81a.248.248 0 01.158-.3 4.627 4.627 0 001.8-1.128.247.247 0 01.176-.074.252.252 0 01.177.074 4.6 4.6 0 001.8 1.128.247.247 0 01.158.3 2.224 2.224 0 01-2.132 1.623zM17.083 24a6.282 6.282 0 004.931-2.373.99.99 0 10-1.546-1.236 4.33 4.33 0 11-.491-5.92.247.247 0 01.009.359l-1.376 1.376a.5.5 0 00.35.845h4.411a.5.5 0 00.5-.495v-4.411a.5.5 0 00-.845-.35l-1.284 1.284a.248.248 0 01-.345.006 6.253 6.253 0 00-4.309-1.7 6.31 6.31 0 000 12.619z\"/><path d=\"M.152 17.931a.5.5 0 00.477.626h2.3a.248.248 0 01.243.2l.921 4.847v.007a.5.5 0 00.484.389h2.969a.5.5 0 00.484-.389v-.006l.921-4.847a.247.247 0 01.243-.2h.116a.247.247 0 00.246-.275 6.49 6.49 0 01-.042-.715 7.156 7.156 0 011.2-3.967.249.249 0 00.032-.2c-.564-2.076-.686-3.059-1.763-4.068a.5.5 0 00-.757.1L6.493 12.16a.5.5 0 01-.837 0L3.933 9.411a.5.5 0 00-.755-.1c-1.153 1.062-1.066 1.469-3.026 8.62zM6.051 8.412a4.206 4.206 0 10-4.206-4.206 4.211 4.211 0 004.206 4.206zm0-1.979A2.224 2.224 0 013.914 4.81a.248.248 0 01.158-.3 4.627 4.627 0 001.8-1.128.247.247 0 01.176-.074.252.252 0 01.177.074 4.6 4.6 0 001.8 1.128.247.247 0 01.158.3 2.224 2.224 0 01-2.132 1.623zM17.083 24a6.282 6.282 0 004.931-2.373.99.99 0 10-1.546-1.236 4.33 4.33 0 11-.491-5.92.247.247 0 01.009.359l-1.376 1.376a.5.5 0 00.35.845h4.411a.5.5 0 00.5-.495v-4.411a.5.5 0 00-.845-.35l-1.284 1.284a.248.248 0 01-.345.006 6.253 6.253 0 00-4.309-1.7 6.31 6.31 0 000 12.619z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});