define("ember-svg-jar/inlined/style-three-pin-skiing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-skiing</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM9.364 3.145a.5.5 0 01.617.345l1.05 3.724a.5.5 0 00.258.312l2.158 1.079a1 1 0 11-.895 1.79l-.222-.112a.25.25 0 00-.352.292l.5 1.789a.5.5 0 01-.963.272l-2.5-8.874a.5.5 0 01.349-.617zm-1.341 9.139l.723-3.25a1.925 1.925 0 01.147-.421.25.25 0 01.465.042l.816 2.9a.492.492 0 01.007.244l-.2.922A1.008 1.008 0 019 13.5a1.036 1.036 0 01-.213-.023 1 1 0 01-.764-1.193zm8.312 5.139a5.331 5.331 0 01-4.678.056l-6.476-3.048a.75.75 0 11.638-1.358l6.481 3.048a3.84 3.84 0 003.369-.04.75.75 0 11.67 1.342zM16.5 11.5A1.5 1.5 0 1118 10a1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});