define("ember-svg-jar/inlined/shopping-cart-man-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-cart-man-message</title><path d=\"M17.208 17.351a.5.5 0 00.478.649h2.063a.25.25 0 01.25.25v1.25a.5.5 0 00.5.5h1.4a.25.25 0 01.177.427l-1.479 1.479a.5.5 0 00-.093.581A2.4 2.4 0 0022.5 24h1.25a.25.25 0 00.25-.25V5.867a.249.249 0 00-.348-.23c-4.236 1.797-4.959 6.975-6.444 11.714zM22 14.5a1 1 0 111-1 1 1 0 01-1 1zM17 11.75a1 1 0 001-1V2a2 2 0 00-2-2H2a2 2 0 00-2 2v11.5a2 2 0 002 2h6.512a.246.246 0 01.169.066l2.642 2.421a1 1 0 00.677.263 1.015 1.015 0 00.4-.084 1 1 0 00.6-.916v-1.5a.25.25 0 01.25-.25H15a1 1 0 000-2h-3a1 1 0 00-1 1 .21.21 0 01-.351.155l-.973-.892A1 1 0 009 13.5H3a1 1 0 01-1-1V3a1 1 0 011-1h12a1 1 0 011 1v7.75a1 1 0 001 1z\"/><path d=\"M10.649 9.75a1.255 1.255 0 001.174-.822l1.879-5.171a.75.75 0 10-1.41-.512l-.3.841a.25.25 0 01-.235.165H6a1.248 1.248 0 00-1.043.562 1.223 1.223 0 00-.127 1.13C4.839 5.962 5.916 8.981 5.925 9a1.252 1.252 0 001.146.749z\"/><circle cx=\"7.499\" cy=\"11.25\" r=\"1\"/><circle cx=\"10.249\" cy=\"11.25\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});