define("ember-svg-jar/inlined/retouch-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>retouch-select</title><path d=\"M1 5a1 1 0 001-1V2.5a.5.5 0 01.5-.5H4a1 1 0 000-2H2a2 2 0 00-2 2v2a1 1 0 001 1zM19 1a1 1 0 001 1h1.5a.5.5 0 01.5.5V4a1 1 0 002 0V2a2 2 0 00-2-2h-2a1 1 0 00-1 1zM4 22H2.5a.5.5 0 01-.5-.5V20a1 1 0 00-2 0v2a2 2 0 002 2h2a1 1 0 000-2zM23 19a1 1 0 00-1 1v1.5a.5.5 0 01-.5.5H20a1 1 0 000 2h2a2 2 0 002-2v-2a1 1 0 00-1-1zM16.141 10.329a.074.074 0 00-.141.021 2 2 0 01-2 1.9h-.042A2 2 0 0112 10.268c-.017-1.929-.6-2.518-2.488-2.518H9.5a1.993 1.993 0 01-1.444-.617.249.249 0 00-.315-.033A7 7 0 1018.5 13a7.193 7.193 0 00-.045-.795.25.25 0 00-.222-.22 2.5 2.5 0 01-2.092-1.656zM23.266 8.75c-1.273-.027-1.766-.515-1.766-1.74V7a.749.749 0 00-.747-.752A.75.75 0 0020 7c0 1.243-.5 1.734-1.761 1.752a.75.75 0 00.011 1.5h.006c1.244 0 1.733.493 1.744 1.757a.75.75 0 00.734.743h.016a.75.75 0 00.749-.712c.064-1.274.594-1.8 1.748-1.788A.758.758 0 0024 9.509a.75.75 0 00-.734-.759z\"/><path d=\"M18.469 6.5h.031a.75.75 0 00.016-1.5c-2.611-.056-3.773-1.208-3.766-3.737v-.011A.749.749 0 0014 .5a.75.75 0 00-.75.747C13.241 3.806 12.081 4.964 9.489 5A.75.75 0 009.5 6.5h.01c2.563 0 3.717 1.159 3.74 3.756a.75.75 0 00.734.744H14a.75.75 0 00.749-.712c.131-2.619 1.278-3.788 3.72-3.788zm-5.539-.561a.25.25 0 010-.4 3.934 3.934 0 00.866-.862.251.251 0 01.4 0 3.956 3.956 0 00.917.89.25.25 0 01.007.4 4.085 4.085 0 00-.873.906.246.246 0 01-.205.106.25.25 0 01-.205-.107 3.929 3.929 0 00-.907-.933z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});