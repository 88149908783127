define("ember-svg-jar/inlined/cursor-select-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-select-circle</title><circle cx=\"12\" cy=\"11.998\" r=\"4\"/><path d=\"M21.345 8.438a1.012 1.012 0 001.29.578 1 1 0 00.579-1.29 12.041 12.041 0 00-.888-1.841A1 1 0 0020.605 6.9a9.935 9.935 0 01.74 1.538zM19.575 2.691a12.075 12.075 0 00-1.688-1.15 1 1 0 10-.981 1.743 10.09 10.09 0 011.407.959 1 1 0 001.262-1.552zM11.88 2h.011a9.825 9.825 0 011.7.126A1 1 0 0013.908.15 12.129 12.129 0 0011.87 0a1 1 0 00.01 2zM6.907 3.392a9.97 9.97 0 011.533-.739A1 1 0 107.728.784a12 12 0 00-1.841.888 1 1 0 101.02 1.72zM1.924 7.472a1 1 0 001.362-.379 9.983 9.983 0 01.959-1.408 1 1 0 10-1.551-1.262 11.965 11.965 0 00-1.151 1.688 1 1 0 00.381 1.361zM1.011 13.117A1 1 0 002 12.106a10.074 10.074 0 01.127-1.7 1 1 0 00-1.975-.317A12.1 12.1 0 000 12.128a1 1 0 001 .989zM2.655 15.557a1 1 0 00-1.869.712 12 12 0 00.888 1.841A1 1 0 103.4 17.092a9.945 9.945 0 01-.745-1.535zM7.094 20.712a10.177 10.177 0 01-1.407-.959A1 1 0 104.425 21.3a12.082 12.082 0 001.688 1.151 1 1 0 10.981-1.743zM12.12 22h-.011a9.748 9.748 0 01-1.7-.126 1 1 0 10-.319 1.974A11.984 11.984 0 0012 24a1 1 0 10.117-2zM17.093 20.6a10.068 10.068 0 01-1.533.74 1 1 0 00-.579 1.291 1.014 1.014 0 001.291.578 12 12 0 001.841-.888 1 1 0 00-1.02-1.721zM22.076 16.523a1 1 0 00-1.362.38 9.973 9.973 0 01-.959 1.407 1 1 0 101.551 1.263 12.023 12.023 0 001.151-1.688 1 1 0 00-.381-1.362zM22.989 10.879a1 1 0 00-.989 1.01 10.078 10.078 0 01-.127 1.7 1 1 0 001.975.317A12.1 12.1 0 0024 11.868a1.01 1.01 0 00-1.011-.989z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});