define("ember-svg-jar/inlined/modern-tv-3d", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-tv-3d</title><path d=\"M24 1.5A1.5 1.5 0 0022.5 0h-21A1.5 1.5 0 000 1.5v16A1.5 1.5 0 001.5 19h21a1.5 1.5 0 001.5-1.5zm-2 13a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5zM14.35 20.681a.5.5 0 00-.385-.181h-3.93a.5.5 0 00-.385.181l-2.1 2.532a.5.5 0 00.41.787h8.079a.5.5 0 00.41-.787z\"/><path d=\"M18.75 8.5a4 4 0 00-4-4h-1a1 1 0 00-1 1v6a1 1 0 001 1h1a4 4 0 004-4zm-3.714 1.98a.252.252 0 01-.286-.248V6.768a.25.25 0 01.286-.248 2 2 0 010 3.96zM8.75 4.5h-2.5a1 1 0 000 2h2.5a.5.5 0 010 1h-1.5a1 1 0 000 2h1.5a.5.5 0 010 1h-2.5a1 1 0 000 2h2.5a2.5 2.5 0 002.1-3.863.252.252 0 010-.274A2.5 2.5 0 008.75 4.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});