define("ember-svg-jar/inlined/book-library-shelf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-library-shelf</title><path d=\"M23 0a1 1 0 00-1 1v5a1.5 1.5 0 01-3 0V1a1 1 0 00-2 0v14.81a.255.255 0 00.05.15 4.464 4.464 0 003.579 1.67 4.559 4.559 0 003.3-1.342.247.247 0 00.07-.173V1A1 1 0 0023 0zM23.63 18.379a6.372 6.372 0 01-6.251-.14.25.25 0 00-.379.214V20.5a3.5 3.5 0 007 0v-1.9a.25.25 0 00-.37-.22zM14.5 0a1 1 0 00-1 1v5a1.5 1.5 0 01-3 0V1a1 1 0 00-2 0v14.81a.255.255 0 00.05.15 4.464 4.464 0 003.579 1.67 4.559 4.559 0 003.3-1.342.247.247 0 00.07-.173V1a1 1 0 00-.999-1zM15.13 18.379a6.372 6.372 0 01-6.251-.14.25.25 0 00-.379.214V20.5a3.5 3.5 0 007 0v-1.9a.25.25 0 00-.37-.22zM6 0a1 1 0 00-1 1v5a1.5 1.5 0 01-3 0V1a1 1 0 00-2 0v14.81a.249.249 0 00.051.15 4.462 4.462 0 003.579 1.67 4.562 4.562 0 003.3-1.342.25.25 0 00.07-.173V1a1 1 0 00-1-1zM6.631 18.379a6.3 6.3 0 01-3 .751 6.264 6.264 0 01-3.25-.891.251.251 0 00-.38.214V20.5a3.5 3.5 0 007 0v-1.9a.25.25 0 00-.369-.22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});