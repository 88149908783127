define("ember-svg-jar/inlined/study-book", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>study-book</title><path d=\"M9.076 4.392A21.161 21.161 0 004 3.381a.759.759 0 00-.819.674.748.748 0 00.672.819 19.6 19.6 0 014.74.938.75.75 0 10.482-1.42zM9.076 8.023A21.156 21.156 0 004 7.013a.75.75 0 00-.147 1.493 19.592 19.592 0 014.74.937.75.75 0 10.482-1.42zM9.076 11.655A21.21 21.21 0 004 10.644a.757.757 0 00-.819.674.75.75 0 00.672.82 19.552 19.552 0 014.74.937.75.75 0 10.482-1.42zM19.5 3.214a22.56 22.56 0 00-5.075 1.178.75.75 0 10.482 1.42 21.169 21.169 0 014.74-1.105.75.75 0 00-.147-1.493zM19.5 6.845a22.63 22.63 0 00-5.075 1.178.75.75 0 10.482 1.42 21.239 21.239 0 014.74-1.105.75.75 0 00-.147-1.493zM19.5 10.477a22.522 22.522 0 00-5.075 1.178.75.75 0 10.482 1.42 21.157 21.157 0 014.74-1.1.75.75 0 00-.147-1.493z\"/><path d=\"M15 15.621a.611.611 0 00-.811-.577c-.349.121-.677.251-.98.391a.5.5 0 01-.709-.455V4.008c0-.761 3.274-2.336 9.277-2.506a.288.288 0 01.167.063.17.17 0 01.056.123v11.988a.218.218 0 01-.233.183l-.521.018a.78.78 0 00-.746.78.722.722 0 00.753.721c.182-.008.367-.015.555-.02a1.7 1.7 0 001.692-1.682V1.688a1.66 1.66 0 00-.511-1.2A1.707 1.707 0 0021.734 0c-3.807.107-7.828.85-9.683 2.249a.5.5 0 01-.6 0C9.593.853 5.573.11 1.766 0A1.709 1.709 0 00.511.49 1.66 1.66 0 000 1.688v11.988a1.7 1.7 0 001.692 1.682C7.715 15.524 11 17.1 11 17.865a.75.75 0 001.5 0c0-.334.627-.823 1.835-1.281a1.032 1.032 0 00.665-.963zm-4-.641a.5.5 0 01-.709.455 23.919 23.919 0 00-8.558-1.576.218.218 0 01-.233-.183V1.688a.17.17 0 01.056-.123.226.226 0 01.16-.063h.007C7.726 1.672 11 3.247 11 4.008z\"/><path d=\"M21.253 20H19.5a.5.5 0 01-.5-.5v-4.25a1.25 1.25 0 00-2.5 0v6.466a.25.25 0 01-.454.145l-1.067-1.5a1.05 1.05 0 10-1.858.978l1.26 2.395a.5.5 0 00.443.267H23.5a.5.5 0 00.5-.5v-.6A2.887 2.887 0 0021.253 20z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});