define("ember-svg-jar/inlined/multiple-actions-setting.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-setting</title><path d=\"M14.02 11.24a.5.5 0 00.591-.341v-.011A3.259 3.259 0 0116 9.067a.5.5 0 00-.152-.911 5.983 5.983 0 00-3.828.378.5.5 0 00-.182.771 7.482 7.482 0 01.974 1.56.5.5 0 00.455.29 3.277 3.277 0 01.753.085z\"/><ellipse cx=\"14.012\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M10.448 12.789a3.29 3.29 0 01.769-.9.5.5 0 00.128-.616A6.024 6.024 0 000 13.333.6.6 0 00.6 14h9.028a.5.5 0 00.485-.379 3.156 3.156 0 01.335-.832z\"/><ellipse cx=\"5.513\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><circle cx=\"17.748\" cy=\"17.031\" r=\"1.5\"/><path d=\"M23.516 15.654a1.783 1.783 0 00-1.7-2.953l-1.411.327a.452.452 0 01-.533-.311l-.424-1.392a1.775 1.775 0 00-3.4 0l-.424 1.392a.455.455 0 01-.533.311l-1.41-.328a1.783 1.783 0 00-1.7 2.952l.988 1.066a.463.463 0 010 .625l-.988 1.065a1.783 1.783 0 001.7 2.952l1.411-.326a.453.453 0 01.533.311l.424 1.392a1.775 1.775 0 003.4 0l.424-1.392a.45.45 0 01.533-.311l1.411.326a1.782 1.782 0 001.7-2.952l-.987-1.065a.463.463 0 010-.625zm-5.768 4.377a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});