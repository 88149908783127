define("ember-svg-jar/inlined/dresser-drawers-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dresser-drawers-3</title><path d=\"M17.5 0h-11A1.5 1.5 0 005 1.5V4a.5.5 0 00.5.5h13A.5.5 0 0019 4V1.5A1.5 1.5 0 0017.5 0z\"/><rect x=\"5\" y=\"6\" width=\"14\" height=\"4\" rx=\".5\" ry=\".5\"/><rect x=\"5\" y=\"11.5\" width=\"14\" height=\"4\" rx=\".5\" ry=\".5\"/><path d=\"M18.5 17h-13a.5.5 0 00-.5.5V23a1 1 0 002 0v-.5a.5.5 0 01.5-.5h9a.5.5 0 01.5.5v.5a1 1 0 002 0v-5.5a.5.5 0 00-.5-.5zm-5 2.75h-3a.75.75 0 010-1.5h3a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});