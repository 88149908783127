define("ember-svg-jar/inlined/people-man-3.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-3</title><path d=\"M3.5 9.5a3.488 3.488 0 002.476 3.331.252.252 0 01.155.136 9.466 9.466 0 001.193 1.977 6.485 6.485 0 004.655 2.744 6.528 6.528 0 004.672-2.764 9.431 9.431 0 001.182-1.944.251.251 0 01.156-.136A3.489 3.489 0 0020.163 8a.25.25 0 01.033-.267 3.5 3.5 0 00-2.133-5.687.251.251 0 01-.189-.146 1.5 1.5 0 00-2.156-.679.25.25 0 01-.287-.018 5.5 5.5 0 00-6.862 0 .25.25 0 01-.287.018 1.5 1.5 0 00-2.156.679.251.251 0 01-.189.146A3.5 3.5 0 003.8 7.732.25.25 0 013.837 8 3.491 3.491 0 003.5 9.5zm5 4.514A8.332 8.332 0 016.8 7.94a.25.25 0 01.285-.222A2.83 2.83 0 008.853 7.4a.249.249 0 01.311.054 3.743 3.743 0 005.672 0 .25.25 0 01.311-.054 2.828 2.828 0 001.754.325.248.248 0 01.188.05.253.253 0 01.1.171 8.212 8.212 0 01-1.709 6.051 5.141 5.141 0 01-3.5 2.195 5.161 5.161 0 01-3.48-2.178z\"/><path d=\"M21.08 18.287l-.017-.006-4.476-1.5a.5.5 0 00-.562.178 4.985 4.985 0 01-8.05 0 .5.5 0 00-.562-.178L2.92 18.287A4.519 4.519 0 000 22.5v1a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-1a4.519 4.519 0 00-2.92-4.213zM13.954 12.818a.748.748 0 00-1.016-.3 1.286 1.286 0 01-.235.1 1.98 1.98 0 01-.576.082 2.051 2.051 0 01-.582-.083 1.248 1.248 0 01-.234-.1.75.75 0 10-.715 1.318 2.765 2.765 0 00.512.213 3.572 3.572 0 001.017.148h.006a3.521 3.521 0 001.014-.147 2.773 2.773 0 00.511-.213.75.75 0 00.298-1.018zM9 10.463a.75.75 0 00.5-.193.633.633 0 01.625 0 .75.75 0 101.006-1.113 2.094 2.094 0 00-2.637 0A.75.75 0 009 10.463zM13.565 10.463a.747.747 0 00.5-.192.63.63 0 01.626 0A.75.75 0 1015.7 9.157a2.1 2.1 0 00-2.636 0 .75.75 0 00.5 1.307z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});