define("ember-svg-jar/inlined/move-expand-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>move-expand-vertical</title><path d=\"M3.616 9.609a1.252 1.252 0 001.768 0l6.439-6.423a.252.252 0 01.354 0l6.439 6.423a1.248 1.248 0 001.768-1.763l-7.5-7.481a1.252 1.252 0 00-1.768 0l-7.5 7.481a1.245 1.245 0 000 1.763zM20.384 14.391a1.252 1.252 0 00-1.768 0l-6.439 6.423a.252.252 0 01-.354 0l-6.439-6.423a1.248 1.248 0 00-1.768 1.763l7.5 7.481a1.253 1.253 0 001.768 0l7.5-7.481a1.245 1.245 0 000-1.763z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});