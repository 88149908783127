define("ember-svg-jar/inlined/single-woman-actions-up-down.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-up-down</title><path d=\"M12.515 12.314a.25.25 0 00-.086-.416l-.955-.35a.779.779 0 01-.513-.73v-.467a.25.25 0 01.25-.25A6.378 6.378 0 0015 9.206a.389.389 0 00.083-.593c-.494-.537-.835-1.185-.981-3.317C13.839 1.471 11.236.125 9 .125S4.162 1.471 3.9 5.3c-.145 2.132-.487 2.78-.981 3.317A.388.388 0 003 9.206a6.348 6.348 0 003.782.9.251.251 0 01.254.25v.462a.779.779 0 01-.513.73c-2.9 1.065-5.212 1.825-5.764 2.922A7.822 7.822 0 000 17.2a.386.386 0 00.1.3.4.4 0 00.29.126h9.4a.251.251 0 00.249-.227 7.972 7.972 0 012.476-5.085zM17.907 19.916a.5.5 0 00-.407-.791h-1a.25.25 0 01-.25-.25v-5.25a1.25 1.25 0 00-2.5 0v5.25a.25.25 0 01-.25.25h-1a.5.5 0 00-.407.791l2.5 3.5a.5.5 0 00.814 0zM18.093 16.334a.5.5 0 00.407.791h1a.25.25 0 01.25.25v5.25a1.25 1.25 0 002.5 0v-5.25a.25.25 0 01.25-.25h1a.5.5 0 00.407-.791l-2.5-3.5a.519.519 0 00-.814 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});