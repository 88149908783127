define("ember-svg-jar/inlined/crypto-currency-namecoin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-namecoin</title><path d=\"M15.185 13.439a.252.252 0 00-.046-.267L10.5 8.15a.254.254 0 00-.227-.077.249.249 0 00-.187.15l-.774 1.838a.252.252 0 00.046.267L14 15.35a.254.254 0 00.227.077.249.249 0 00.187-.15z\"/><path d=\"M12 1a11 11 0 1011 11A11 11 0 0012 1zm7.191 6.791l-4 9.5-.005.009a.775.775 0 01-.686.45.744.744 0 01-.543-.236h-.008l-5.073-5.501a.25.25 0 00-.414.072l-1.771 4.206a.75.75 0 11-1.382-.582l3.985-9.465a.212.212 0 01.033-.056.683.683 0 01.565-.416.72.72 0 01.651.214h.008l5.073 5.5a.25.25 0 00.414-.072l1.771-4.206a.75.75 0 111.382.582z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});