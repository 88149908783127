define("ember-svg-jar/inlined/single-man-circle.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-circle</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a9.958 9.958 0 01-6.554-2.456.5.5 0 01.126-.834c.9-.4 1.947-.779 2.979-1.152.376-.137.751-.273 1.118-.409a.251.251 0 00.163-.235v-1.739a.249.249 0 00-.119-.212 3.074 3.074 0 01-1.3-2.613.25.25 0 00-.12-.2 1.253 1.253 0 01-.483-1.108 1.316 1.316 0 01.41-1.056.249.249 0 00.08-.263c-.253-.909-.6-2.274.011-2.937a1.178 1.178 0 01.965-.34.249.249 0 00.228-.125c.5-.872 1.908-1.266 3.207-1.266 1.467 0 3.071.5 3.351 1.624a5.853 5.853 0 01-.36 3.045.25.25 0 00.081.27 1.328 1.328 0 01.4 1.049 1.252 1.252 0 01-.483 1.107.249.249 0 00-.121.2 3.072 3.072 0 01-1.305 2.614.249.249 0 00-.119.212v1.739a.248.248 0 00.164.234c.407.151.812.3 1.209.441 1.078.39 2.071.749 2.9 1.113a.5.5 0 01.128.835A9.958 9.958 0 0112 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});