define("ember-svg-jar/inlined/business-contract-give", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-contract-give</title><path d=\"M17.75 2.474h-10a1 1 0 000 2h9.5a.5.5 0 01.5.5v7.9a.1.1 0 00.03.073l2.75 2.75a.75.75 0 11-1.06 1.061l-2.07-2.077a1.245 1.245 0 00-1.462 1.975l2.744 2.8a.247.247 0 01.072.175v1.4a5.989 5.989 0 00.94 2.405.249.249 0 00.175.116 4.07 4.07 0 003.786-1.391.248.248 0 00.026-.27l-.9-1.74a.257.257 0 01-.028-.115v-4.5c.034-1.443-1.359-2.759-2.912-4.081a.248.248 0 01-.088-.19V4.474a2 2 0 00-2.003-2z\"/><path d=\"M6.25 20.974h10a1 1 0 000-2h-9a.5.5 0 01-.5-.5V11.59a.251.251 0 00-.125-.217.7.7 0 01-.155-.119L3.72 8.5a.75.75 0 111.06-1.057l1.97 1.97v-.029a1.245 1.245 0 001.316-2.04l-2.744-2.8a.249.249 0 01-.072-.175v-1.4a6 6 0 00-.94-2.4.252.252 0 00-.175-.116A4.073 4.073 0 00.349 1.844a.248.248 0 00-.026.27l.9 1.74a.261.261 0 01.028.116v4.5c-.037 1.6 1.672 3.039 3.411 4.5a.248.248 0 01.089.191v6.306a1.5 1.5 0 001.499 1.507z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});