define("ember-svg-jar/inlined/video-game-xbox-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-xbox-controller</title><path d=\"M5 2h4.5A1.5 1.5 0 0111 3.5V5a1 1 0 102 0V3.5A3.5 3.5 0 009.5 0H5a1 1 0 000 2zM19.5 7.5h-15A4.5 4.5 0 000 12v9.92a2.056 2.056 0 00.615 1.474A2.1 2.1 0 002.1 24h.029c1.949-.026 2.248-.6 4.192-4.361l.723-1.394A2.519 2.519 0 019.21 17h5.579a2.5 2.5 0 012.145 1.214c.321.614.6 1.161.853 1.647C19.593 23.364 19.919 24 21.859 24a2.131 2.131 0 001.515-.625A2.1 2.1 0 0024 21.88V12a4.5 4.5 0 00-4.5-4.5zm-12.25 6H6.5a.5.5 0 00-.5.5v.75a.75.75 0 01-1.5 0V14a.5.5 0 00-.5-.5h-.75a.75.75 0 010-1.5H4a.5.5 0 00.5-.5v-.75a.75.75 0 111.5 0v.75a.5.5 0 00.5.5h.75a.75.75 0 010 1.5zm8.75 0a1 1 0 111-1 1 1 0 01-1 1zm2.5 2a1 1 0 111-1 1 1 0 01-1 1zm0-4a1 1 0 111-1 1 1 0 01-1 1zm2.5 2a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});