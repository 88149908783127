define("ember-svg-jar/inlined/style-three-pin-signal-antenna", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-signal-antenna</title><path d=\"M11.169 13.5a.25.25 0 00-.239.178l-.3 1a.25.25 0 00.239.322h2.262a.25.25 0 00.239-.322l-.3-1a.25.25 0 00-.239-.178z\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM7.142 11.857a4.748 4.748 0 010-6.717A.75.75 0 118.2 6.2a3.25 3.25 0 000 4.6.75.75 0 11-1.06 1.061zm8.074 6.359a.75.75 0 01-.935-.5l-.311-1.037a.25.25 0 00-.239-.178h-3.462a.25.25 0 00-.239.178l-.311 1.037a.75.75 0 01-1.438-.43L10.8 8.878a1.25 1.25 0 012.394 0l2.522 8.405a.751.751 0 01-.5.933zM16.858 5.14a4.748 4.748 0 010 6.717A.75.75 0 1115.8 10.8a3.25 3.25 0 000-4.6.75.75 0 111.06-1.06z\"/><path d=\"M12.231 12a.25.25 0 00.239-.322l-.231-.769a.249.249 0 00-.478 0l-.231.769a.25.25 0 00.239.322z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});