define("ember-svg-jar/inlined/time-clock-file-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-clock-file-setting</title><path d=\"M23.415 3L21 .587A2 2 0 0019.586 0H8a2 2 0 00-2 2v6.357a.245.245 0 00.214.247 7.875 7.875 0 011.45.318A.247.247 0 008 8.69V2.5a.5.5 0 01.5-.5h10.88a.5.5 0 01.353.146l2.122 2.121a.5.5 0 01.145.355V18a.5.5 0 01-.5.5h-7.374a.249.249 0 00-.236.32 3.229 3.229 0 01.047 1.377.249.249 0 00.24.3H22a2 2 0 002-2V4.415A2 2 0 0023.415 3z\"/><path d=\"M17.136 12.961a4.712 4.712 0 002.977-3.3 4.753 4.753 0 10-9.25-2.188 4.684 4.684 0 00.739 3.713.519.519 0 00.225.175 6.4 6.4 0 011.673 1.384.516.516 0 00.188.136 4.692 4.692 0 003.448.08zM12.251 8.5a3.25 3.25 0 113.25 3.25 3.254 3.254 0 01-3.25-3.25z\"/><circle cx=\"6.251\" cy=\"17.032\" r=\"1.5\"/><path d=\"M12.019 15.655a1.782 1.782 0 00-1.7-2.953l-1.41.327a.452.452 0 01-.534-.311l-.425-1.392a1.774 1.774 0 00-3.4 0l-.424 1.391a.456.456 0 01-.533.312L2.184 12.7a1.782 1.782 0 00-1.7 2.953l.988 1.064a.464.464 0 010 .626L.483 18.41a1.783 1.783 0 001.7 2.953l1.41-.327a.452.452 0 01.534.311l.424 1.392a1.775 1.775 0 003.4 0l.424-1.392a.453.453 0 01.533-.311l1.411.327a1.783 1.783 0 001.7-2.953l-.988-1.065a.464.464 0 010-.626zm-5.768 4.377a3 3 0 113-3 3 3 0 01-3 3zM17.269 9.251a.75.75 0 100-1.5H16.5a.252.252 0 01-.251-.252v-.766a.75.75 0 10-1.5 0V8.5a.75.75 0 00.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});