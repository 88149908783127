define("ember-svg-jar/inlined/controls-forward", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>controls-forward</title><path d=\"M23.5 10.865L13 1.384a1.5 1.5 0 00-2.5 1.138v5.525a.25.25 0 01-.418.186L2.5 1.384A1.5 1.5 0 000 2.522v18.957a1.5 1.5 0 002.5 1.136l7.583-6.847a.25.25 0 01.418.186v5.525A1.5 1.5 0 0013 22.615l10.5-9.479a1.539 1.539 0 000-2.271z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});