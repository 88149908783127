define("ember-svg-jar/inlined/target-center", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>target-center</title><path d=\"M23.9 2.7a.5.5 0 00-.4-.2h-1.75a.25.25 0 01-.25-.25V.5a.5.5 0 00-.648-.477A14.867 14.867 0 0019 .769v3.066a.251.251 0 01-.073.178l-.5.5A.25.25 0 0118 4.335V1.3a5.388 5.388 0 00-2.7 2.95 2.983 2.983 0 00.586 2.511.252.252 0 01-.027.322l-.89.89a.247.247 0 01-.333.017A8.993 8.993 0 001.92 20.549a.249.249 0 010 .309L.716 22.38a1 1 0 00.164 1.4 1.01 1.01 0 001.4-.164l1.052-1.33a.25.25 0 01.344-.046 8.961 8.961 0 0010.857-.153.25.25 0 01.34.03l1.376 1.544a1.015 1.015 0 001.412.081 1 1 0 00.082-1.411l-1.5-1.684a.252.252 0 01-.015-.316 8.947 8.947 0 00.449-10.015.255.255 0 00-.4-.043L15 11.558a.242.242 0 00-.041.288 6.764 6.764 0 11-1.984-2.292.256.256 0 01.1.183.251.251 0 01-.072.2l-.727.727a.252.252 0 01-.319.029 5.161 5.161 0 00-4.152-.8 5.253 5.253 0 106.1 6.947 5.1 5.1 0 00-.013-3.681.252.252 0 00-.411-.081l-1.213 1.213a.244.244 0 00-.067.213 2.975 2.975 0 01-.192 1.649A3.331 3.331 0 019.4 18.226a3.25 3.25 0 01-1.093-6.4 3.174 3.174 0 012.094.249.255.255 0 01.137.184.252.252 0 01-.07.218l-.88.881A1.718 1.718 0 009 13.25 1.75 1.75 0 1010.75 15a1.731 1.731 0 00-.107-.582l6.274-6.274a.25.25 0 01.321-.028 3.2 3.2 0 001.863.667 2.683 2.683 0 00.65-.08c2.68-.662 4.165-5.355 4.227-5.554A.5.5 0 0023.9 2.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});