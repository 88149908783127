define("ember-svg-jar/inlined/army-dog-tag-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-dog-tag-1</title><path d=\"M20.636.6A.749.749 0 0019.6.364l-3.848 2.767a.251.251 0 01-.211.039A13.608 13.608 0 0012 2.75a13.688 13.688 0 00-3.542.419.248.248 0 01-.211-.038L4.4.364a.75.75 0 10-.8 1.272l2.782 2a.249.249 0 010 .405A2.065 2.065 0 005.5 5.75v15a2.113 2.113 0 001.065 1.834A10.847 10.847 0 0012 23.75a10.847 10.847 0 005.435-1.166A2.111 2.111 0 0018.5 20.75v-15a1.923 1.923 0 00-.086-.557.255.255 0 00-.157-.163.251.251 0 00-.224.03c-1.053.718-4.919 3.355-5 3.393A1.979 1.979 0 0112 8.75a2 2 0 010-4 1.914 1.914 0 01.667.119.249.249 0 01.059.437l-1.126.808a.75.75 0 10.8 1.272l8-5.75A.75.75 0 0020.636.6zM14.75 14.75a.75.75 0 011.5 0v2a.75.75 0 01-1.5 0zm-3.5-3a.75.75 0 011.5 0v8a.75.75 0 01-1.5 0zm-2 6a.75.75 0 01-1.5 0v-4a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});