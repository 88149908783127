define("ember-svg-jar/inlined/space-rocket-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>space-rocket-2</title><path d=\"M3.3 15.854l3.119-1.7a.25.25 0 00.131-.22V8.25a2 2 0 00-4 0v7.614a.249.249 0 00.384.211c.117-.075.24-.151.366-.221zM9.546 7.75c0-1.35 1.673-3 2.5-3s2.5 1.65 2.5 3v4.55a.251.251 0 00.13.22l1 .544a.251.251 0 00.37-.22V4.75c0-2.209-2.5-4.5-4-4.5s-4 2.291-4 4.5v8.1a.25.25 0 00.369.22l1-.544a.25.25 0 00.131-.22zM20.786 15.847c.125.072.251.148.374.227a.25.25 0 00.386-.21V8.25a2 2 0 00-4 0v5.686a.25.25 0 00.13.219zM5.969 19.921a.25.25 0 00-.237-.171h-2.64a.25.25 0 00-.237.171l-.785 2.355a.339.339 0 00.342.474h4a.339.339 0 00.342-.474zM20.969 19.921a.25.25 0 00-.237-.171h-2.64a.25.25 0 00-.237.171l-.785 2.355a.339.339 0 00.342.474h4a.339.339 0 00.342-.474zM10.163 20.25a.25.25 0 00-.239.177l-.866 2.845a.349.349 0 00.354.478h5a.352.352 0 00.356-.479l-.855-2.843a.25.25 0 00-.239-.178zM9.3 18.748a.25.25 0 00.25-.25v-4.486a.251.251 0 00-.37-.22l-5.396 2.934a3.107 3.107 0 00-1.626 1.7.251.251 0 00.239.324zM20.3 16.719l-5.382-2.927a.25.25 0 00-.369.22V18.5a.249.249 0 00.25.25h6.939a.219.219 0 00.211-.279 3.088 3.088 0 00-1.649-1.752z\"/><path d=\"M13.546 7.75a3.069 3.069 0 00-1.529-2c-.265.07-1.471 1.183-1.471 2V19a.25.25 0 00.25.25h2.5a.25.25 0 00.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});