define("ember-svg-jar/inlined/single-man-actions-warning.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-warning</title><path d=\"M.619 18.062H2.41a.247.247 0 01.247.227l.443 5.257a.5.5 0 00.488.454h3.959a.5.5 0 00.493-.454l.438-5.257a.247.247 0 01.247-.227h1.791a.494.494 0 00.495-.5V14.1a5.444 5.444 0 00-10.887 0v3.464a.5.5 0 00.495.498z\"/><path d=\"M5.568 0a4.206 4.206 0 104.206 4.206A4.211 4.211 0 005.568 0zm0 6.433a2.229 2.229 0 01-2.227-2.227 2.135 2.135 0 01.156-.8.248.248 0 01.36-.119 5.314 5.314 0 002.8.793 5.344 5.344 0 00.9-.077.2.2 0 01.165.045.2.2 0 01.072.155 2.229 2.229 0 01-2.226 2.23zM12.44 24h10.007a1.442 1.442 0 001.221-.679 1.417 1.417 0 00.047-1.394l-5-9.535a1.435 1.435 0 00-2.535 0l-5 9.535a1.42 1.42 0 00.048 1.394A1.44 1.44 0 0012.44 24zm5-8.66a.742.742 0 01.742.742v2.475a.742.742 0 11-1.484 0v-2.475a.742.742 0 01.746-.742zm-.99 5.938a.99.99 0 11.99.99.989.989 0 01-.986-.99z\"/><path d=\"M.619 18.062H2.41a.247.247 0 01.247.227l.443 5.257a.5.5 0 00.488.454h3.959a.5.5 0 00.493-.454l.438-5.257a.247.247 0 01.247-.227h1.791a.494.494 0 00.495-.5V14.1a5.444 5.444 0 00-10.887 0v3.464a.5.5 0 00.495.498z\"/><path d=\"M5.568 0a4.206 4.206 0 104.206 4.206A4.211 4.211 0 005.568 0zm0 6.433a2.229 2.229 0 01-2.227-2.227 2.135 2.135 0 01.156-.8.248.248 0 01.36-.119 5.314 5.314 0 002.8.793 5.344 5.344 0 00.9-.077.2.2 0 01.165.045.2.2 0 01.072.155 2.229 2.229 0 01-2.226 2.23zM12.44 24h10.007a1.442 1.442 0 001.221-.679 1.417 1.417 0 00.047-1.394l-5-9.535a1.435 1.435 0 00-2.535 0l-5 9.535a1.42 1.42 0 00.048 1.394A1.44 1.44 0 0012.44 24zm5-8.66a.742.742 0 01.742.742v2.475a.742.742 0 11-1.484 0v-2.475a.742.742 0 01.746-.742zm-.99 5.938a.99.99 0 11.99.99.989.989 0 01-.986-.99z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});