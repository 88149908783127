define("ember-svg-jar/inlined/common-file-settings-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-settings-1</title><path d=\"M21.707 5.707L16.293.293A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.465a.5.5 0 01.353.146l4.536 4.536a.5.5 0 01.146.353z\"/><path d=\"M17.063 10.847a1.616 1.616 0 00-1.541-2.676l-1.222.284a.332.332 0 01-.386-.226l-.369-1.21a1.609 1.609 0 00-3.08 0l-.369 1.21a.331.331 0 01-.386.226l-1.232-.284a1.616 1.616 0 00-1.541 2.676l.858.925a.338.338 0 010 .456l-.858.925a1.616 1.616 0 001.541 2.676l1.227-.284a.332.332 0 01.386.226l.369 1.209a1.608 1.608 0 003.08 0l.369-1.21a.333.333 0 01.386-.226l1.227.284a1.616 1.616 0 001.541-2.676l-.858-.925a.338.338 0 010-.456zM12 13.75A1.75 1.75 0 1113.75 12 1.752 1.752 0 0112 13.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});