define("ember-svg-jar/inlined/diagram-wave-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-wave-down</title><path d=\"M23.577 13.5a1.443 1.443 0 00-1.021-2.464h-1.193a.25.25 0 01-.25-.25V4.782A4.336 4.336 0 0016.782.451h-.962a4.336 4.336 0 00-4.331 4.331v14.436a1.445 1.445 0 01-1.444 1.443h-.962a1.445 1.445 0 01-1.444-1.443V4.782A4.335 4.335 0 003.309.451H1.444a1.444 1.444 0 000 2.888h1.865a1.444 1.444 0 011.443 1.443v14.436a4.336 4.336 0 004.331 4.331h.962a4.336 4.336 0 004.331-4.331V4.782a1.445 1.445 0 011.444-1.443h.962a1.445 1.445 0 011.444 1.443v6.006a.25.25 0 01-.25.25h-1.194a1.443 1.443 0 00-1.021 2.462l2.887 2.886a1.442 1.442 0 002.043 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});