define("ember-svg-jar/inlined/module-phone-puzzle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>module-phone-puzzle-1</title><path d=\"M.74 21a3 3 0 003 3h8a2.95 2.95 0 00.926-.149.5.5 0 00.2-.829l-1.039-1.039L10.84 21l-1.854-1.853A.5.5 0 008.633 19H3.241a.5.5 0 01-.5-.5L2.758 3a1 1 0 011-1h8a1 1 0 011 1l-.018 5a1 1 0 001 1 1 1 0 001-1l.014-4.994a3 3 0 00-3-3.009h-8a3 3 0 00-3 3z\"/><path d=\"M22.967 16.864a1 1 0 000-1.414l-1.327-1.328a.247.247 0 01-.07-.219.25.25 0 01.14-.184 2 2 0 10-2.669-2.668.251.251 0 01-.4.07L17.31 9.793a1 1 0 00-1.414 0l-1.449 1.449a.5.5 0 00.077.771 2 2 0 11-2.771 2.772.508.508 0 00-.769-.079l-1.45 1.449a1 1 0 000 1.414l5.657 5.657a1 1 0 001.414 0l1.562-1.562a.5.5 0 00-.171-.819 2 2 0 112.593-2.593.5.5 0 00.819.171z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});