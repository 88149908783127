define("ember-svg-jar/inlined/smart-watch-square-play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-play</title><path d=\"M16.43 2.75a.251.251 0 00.238-.329L15.975.342A.5.5 0 0015.5 0h-7a.5.5 0 00-.475.342l-.693 2.079a.251.251 0 00.238.329zM7.57 21.25a.248.248 0 00-.2.1.251.251 0 00-.035.225l.693 2.079A.5.5 0 008.5 24h7a.5.5 0 00.475-.342l.693-2.079a.251.251 0 00-.035-.225.248.248 0 00-.2-.1zM17.5 4h-11A2.5 2.5 0 004 6.5v11A2.508 2.508 0 006.5 20h11a2.5 2.5 0 002.5-2.5v-11A2.5 2.5 0 0017.5 4zm-2.28 7.285a1.113 1.113 0 010 1.929l-4.5 2.629a1.157 1.157 0 01-1.136.014A1.122 1.122 0 019 14.879V9.621a1.122 1.122 0 01.581-.978 1.154 1.154 0 011.138.014z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});