define("ember-svg-jar/inlined/professions-man-nurse-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-nurse-1</title><circle cx=\"13.98\" cy=\"9.155\" r=\".99\"/><circle cx=\"10.02\" cy=\"9.155\" r=\".99\"/><path d=\"M12.081 12.737a2.96 2.96 0 00.855-.123 2.368 2.368 0 00.431-.18.633.633 0 00-.3-1.189h-1.98a.633.633 0 00-.3 1.19 2.436 2.436 0 00.431.178 3 3 0 00.858.124z\"/><path d=\"M.123 20.431v2.579a.99.99 0 101.979 0v-2.579a.961.961 0 01.374-.776c1.182-.9 3.816-2.374 8.524-2.56a.249.249 0 01.182.068.252.252 0 01.076.179v5.668a.742.742 0 101.484 0v-5.668A.246.246 0 0113 17.1c4.708.186 7.342 1.657 8.525 2.56a.963.963 0 01.373.776v2.574a.99.99 0 101.979 0v-2.579a2.928 2.928 0 00-1.151-2.35 15.335 15.335 0 00-7.148-2.736.247.247 0 01-.09-.458 6.925 6.925 0 003.44-5.979v-1.98a6.928 6.928 0 00-13.856 0v1.98a6.925 6.925 0 003.44 5.979.247.247 0 01-.09.458 15.325 15.325 0 00-7.147 2.736 2.926 2.926 0 00-1.152 2.35zM7.051 8.908v-.94a.249.249 0 01.241-.248 6.926 6.926 0 004.646-1.675.091.091 0 01.124 0 6.924 6.924 0 004.646 1.676.249.249 0 01.241.248v.939a4.949 4.949 0 01-9.9 0z\"/><path d=\"M7.794 22.515a.247.247 0 01.247-.247h.495a.743.743 0 000-1.485h-.495a.246.246 0 01-.247-.247v-.5a.743.743 0 00-1.485 0v.5a.247.247 0 01-.248.247h-.494a.743.743 0 100 1.485h.494a.248.248 0 01.248.247v.5a.743.743 0 101.485 0z\"/><circle cx=\"14.969\" cy=\"19.3\" r=\".99\"/><path d=\"M15.959 22.269a.99.99 0 00-1.979 0 .99.99 0 001.979 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});