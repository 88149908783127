define("ember-svg-jar/inlined/agriculture-machine-plow-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>agriculture-machine-plow-1</title><path d=\"M10 2h4a1 1 0 000-2h-4a1 1 0 000 2zM23 11h-.828a1 1 0 01-.6-.2L14.4 5.363a1 1 0 01-.4-.8V4.25a.75.75 0 00-.75-.75h-2.5a.75.75 0 00-.75.75v.316a1 1 0 01-.4.8L2.432 10.8a1 1 0 01-.6.2H1a1 1 0 00-1 1v.5a1 1 0 001 1h2.858a.5.5 0 01.423.234l2.008 3.195a.5.5 0 01-.1.645 6.087 6.087 0 00-1.462 1.886 1 1 0 00.342 1.275l1.716 1.144a2.78 2.78 0 011.1 1.436 1 1 0 00.943.685.983.983 0 00.227-.026l4.694-1.083a1 1 0 00.292-1.83 8.931 8.931 0 01-1.687-1.325 4.95 4.95 0 01-1.115-1.807A3.991 3.991 0 0111 16.563V14a.5.5 0 01.5-.5h.858a.5.5 0 01.423.234l2.009 3.2a.5.5 0 01-.1.645 5.955 5.955 0 00-.836.883.5.5 0 00.076.688 7.243 7.243 0 00.886.631 2.489 2.489 0 011.173 2.536.5.5 0 00.076.354 2.784 2.784 0 01.315.649 1 1 0 00.946.684.983.983 0 00.227-.026l4.694-1.083a1 1 0 00.292-1.83 8.931 8.931 0 01-1.687-1.325 4.95 4.95 0 01-1.115-1.807 4 4 0 01-.239-1.365V14a.5.5 0 01.5-.5h3a1 1 0 001-1V12A1 1 0 0023 11zM9 16.135a.366.366 0 01-.324.363.445.445 0 01-.424-.205l-1.515-2.41a.249.249 0 01.211-.383H8.5a.5.5 0 01.5.5zm1-5.635a.5.5 0 01-.5.5H6.22a.251.251 0 01-.151-.45L9.6 7.876a.251.251 0 01.4.2zm7.5 5.636a.366.366 0 01-.324.363.445.445 0 01-.424-.2l-1.516-2.411a.25.25 0 01.212-.383H17a.5.5 0 01.5.5zm.517-5.307a.25.25 0 01-.237.171H14.5a.5.5 0 01-.5-.5V8.076a.251.251 0 01.4-.2l3.53 2.674a.25.25 0 01.087.279z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});