define("ember-svg-jar/inlined/chef-gear-pizza", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chef-gear-pizza</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10 10 0 01-10 10z\"/><path d=\"M8.5 6a3 3 0 00-3 3c0 .873 1.455 2 3 2s3-1.127 3-2a3 3 0 00-3-3zM13.5 13a3 3 0 00-3 3c0 .873 1.455 2 3 2s3-1.127 3-2a3 3 0 00-3-3zM7.5 13.75H6a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zM16.25 10.25h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zM7 19.25a.75.75 0 01-.53-1.28l1-1a.75.75 0 111.06 1.06l-1 1a.744.744 0 01-.53.22zM13.5 7.25a.75.75 0 01-.53-1.28l1-1a.75.75 0 011.06 1.06l-1 1a.744.744 0 01-.53.22zM18.5 14.25a.744.744 0 01-.53-.22l-1-1a.75.75 0 011.06-1.06l1 1a.75.75 0 01-.53 1.28z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});