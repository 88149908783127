define("ember-svg-jar/inlined/protection-safe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>protection-safe</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM2.5 13a1 1 0 111-1 1 1 0 01-1 1zm15.51-8.425a1 1 0 110 1.414 1 1 0 010-1.414zM12 1.5a1 1 0 11-1 1 1 1 0 011-1zM4.575 4.575a1 1 0 110 1.414 1 1 0 010-1.414zm1.415 14.85a1 1 0 110-1.414 1 1 0 010 1.414zM8.5 16.99a.25.25 0 01-.4.2 6.48 6.48 0 010-10.38.25.25 0 01.4.2zM12 22.5a1 1 0 111-1 1 1 0 01-1 1zm1.5-4.382a.25.25 0 01-.2.245 6.246 6.246 0 01-2.6 0 .25.25 0 01-.2-.245V5.882a.25.25 0 01.2-.245 6.246 6.246 0 012.6 0 .25.25 0 01.2.245zm2.4-.928a.25.25 0 01-.4-.2V7.01a.25.25 0 01.4-.2 6.48 6.48 0 010 10.38zm3.525 2.235a1 1 0 110-1.414 1 1 0 010 1.414zM21.5 13a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});