define("ember-svg-jar/inlined/athletics-javelin-throwing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>athletics-javelin-throwing</title><circle cx=\"13.592\" cy=\"6.979\" r=\"2.5\"/><path d=\"M21.781 18.805a4.55 4.55 0 00-4.766-3.05l-.529.059a.249.249 0 01-.271-.194l-.31-1.395a.249.249 0 01.2-.3l5.359-.884a1.5 1.5 0 00-.5-2.959l-9.211 1.532a1.5 1.5 0 01-1.638-.943l-1.16-3.143A1.5 1.5 0 106.154 8.6l1.164 3.143a4.545 4.545 0 004.924 2.827l.41-.069a.251.251 0 01.286.193l.522 2.354a.253.253 0 01-.039.2l-3.058 4.368a1.5 1.5 0 102.457 1.721l3.111-4.437 1.416-.157a1.489 1.489 0 011.588 1.016l.734 2.2A1.5 1.5 0 1022.514 21zM19.227.684a1 1 0 00-1.27-.62l-16 5.5a1 1 0 10.65 1.89l16-5.5a1 1 0 00.62-1.27z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});