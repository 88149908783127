define("ember-svg-jar/inlined/microphone-podcast-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>microphone-podcast-man</title><path d=\"M17.791 10.907a1.863 1.863 0 01-2.082 0 .75.75 0 10-.918 1.186 3.249 3.249 0 003.918 0 .75.75 0 10-.918-1.186z\"/><circle cx=\"18.75\" cy=\"9\" r=\".75\"/><circle cx=\"14.75\" cy=\"9\" r=\".75\"/><path d=\"M16.75 0a1 1 0 00-.976.78.252.252 0 01-.191.19 1.579 1.579 0 01-.333.03c-3.084 0-5.5 3.075-5.5 7v1a7 7 0 0014 0V8c0-3.933-2.618-8-7-8zm0 14a5.006 5.006 0 01-5-5v-.561a.248.248 0 01.146-.227 5.3 5.3 0 001.713-1.543.249.249 0 01.323-.051 4.943 4.943 0 005.636 0 .249.249 0 01.323.051A5.3 5.3 0 0021.6 8.212a.248.248 0 01.146.227V9a5.006 5.006 0 01-4.996 5zM6.25 17a.25.25 0 00-.25-.25H4.25a.5.5 0 010-1H6a.25.25 0 00.25-.25V14a.25.25 0 00-.25-.25H4.25a.5.5 0 010-1H6a.25.25 0 00.25-.25 3 3 0 00-6 0 .25.25 0 00.25.25h1.75a.5.5 0 010 1H.5a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25h1.75a.5.5 0 010 1H.5a.25.25 0 00-.25.25 3 3 0 001.85 2.769.251.251 0 01.15.231v1.75A.25.25 0 012 22h-.75a1 1 0 000 2h4a1 1 0 000-2H4.5a.25.25 0 01-.25-.25V20a.251.251 0 01.154-.231A3 3 0 006.25 17z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});