define("ember-svg-jar/inlined/skiing-cross-country", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skiing-cross-country</title><circle cx=\"17.752\" cy=\"2.491\" r=\"2.25\"/><path d=\"M22.209 10.126a6.791 6.791 0 01-1.281.556.253.253 0 00-.172.212l-.345 3.217-.768-.111a1 1 0 10-.283 1.98l.838.12-.114 1.063c-.091.855.442 1.095 1 1.1a.939.939 0 00.983-.887l.106-.993s.776.107.823.107a1 1 0 00.141-1.99l-.751-.107.447-4.171a.25.25 0 00-.111-.236.252.252 0 00-.261-.008zM20.751 19.259a1 1 0 00-1 1 1.5 1.5 0 01-1.5 1.5H4.257a1 1 0 000 2h14a3.5 3.5 0 003.5-3.5 1 1 0 00-1.006-1zM6.266 20.575a3.479 3.479 0 002.125-.721 1 1 0 10-1.216-1.587 1.5 1.5 0 01-2.1-.277l-3.283-4.276a1 1 0 10-1.585 1.217l3.279 4.276a3.493 3.493 0 002.78 1.368z\"/><path d=\"M4.456 10.741a1.423 1.423 0 001.861-.172l1.828-1.828a2.484 2.484 0 011.768-.732h2.117a.25.25 0 01.177.427l-3.84 3.84a2.481 2.481 0 01-1.768.733H3.334a.25.25 0 00-.2.4l1.917 2.5a.251.251 0 00.2.1H6.6a5.414 5.414 0 003.188-1.049.25.25 0 01.323.026l2.411 2.414a2.488 2.488 0 01.732 1.769v1.093a.25.25 0 00.25.25H16a.25.25 0 00.25-.25v-1.096a5.464 5.464 0 00-1.611-3.89l-2.34-2.34a.252.252 0 010-.354l3.263-3.264a.25.25 0 01.3-.041 5.971 5.971 0 002.914.759A6.75 6.75 0 0022.5 8.811a1.5 1.5 0 00.683-1.759l.15-1.452.279-2.606a1 1 0 00-.887-1.1.989.989 0 00-1.1.887l-.244 2.277-.112 1.042c-.671.229-1.284.94-2.5.94a2.942 2.942 0 01-1.916-.707 5.5 5.5 0 00-3.577-1.324H9.913A5.462 5.462 0 006.024 6.62C3.838 8.808 4.207 8.4 4 8.741H1a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});