define("ember-svg-jar/inlined/like-ribbon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>like-ribbon</title><path d=\"M23.366 19.266l-2.4-4.138a.25.25 0 00-.424-.013 10.283 10.283 0 01-5.075 3.985.249.249 0 00-.151.15.253.253 0 00.02.213l1.975 3.343a1 1 0 001.8-.172l.832-2.287 2.392.405a1 1 0 001.031-1.488zM3.471 15.09a.247.247 0 00-.216-.112.25.25 0 00-.209.124L.634 19.267a1 1 0 001.032 1.487l2.391-.405.833 2.288a1 1 0 001.8.168l1.98-3.351a.251.251 0 00.02-.212.248.248 0 00-.15-.15 10.286 10.286 0 01-5.069-4.002zM20.766 9.456a8.75 8.75 0 10-8.75 8.75 8.76 8.76 0 008.75-8.75zm-6.074-1.712a1.5 1.5 0 011.376 2.1l-.871 2a1.9 1.9 0 01-1.8 1.115h-1.406a2.835 2.835 0 01-1.323-.264 4.874 4.874 0 00-2.274-.323.5.5 0 01-.5-.5V8.946a.5.5 0 01.5-.5h.683c.965 0 1.591-1.361 2.254-2.8a1.064 1.064 0 011.107-.737c.3.067.667.335.667 1.233v1.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});