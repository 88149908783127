define("ember-svg-jar/inlined/heavy-equipment-cleaner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>heavy-equipment-cleaner</title><path d=\"M21.628 20.5a1 1 0 00.965-1.264 20.36 20.36 0 01-.667-5.7 1 1 0 00-1-1.031H3.074a1 1 0 00-1 1.031 20.349 20.349 0 01-.667 5.705 1 1 0 00.965 1.259zM18.5 18.25h-13a.75.75 0 010-1.5h13a.75.75 0 010 1.5zM6.5 22h-3a.5.5 0 00-.5.5v.5a1 1 0 001 1h2a1 1 0 001-1v-.5a.5.5 0 00-.5-.5zM20.5 22h-3a.5.5 0 00-.5.5v.5a1 1 0 001 1h2a1 1 0 001-1v-.5a.5.5 0 00-.5-.5zM5.263 11H8.75a.25.25 0 00.25-.25V9.016a.75.75 0 111.5 0v1.734a.25.25 0 00.25.25h2.5a.25.25 0 00.25-.25V9.016a.75.75 0 111.5 0v1.734a.25.25 0 00.25.25h3.487a.5.5 0 00.389-.187.5.5 0 00.1-.419l-.651-2.983A1.113 1.113 0 0017.5 6.5h-1v-5A1.472 1.472 0 0015.062 0H8.937A1.471 1.471 0 007.5 1.5v5h-1a1.108 1.108 0 00-1.071.887l-.655 3.007a.5.5 0 00.1.419.5.5 0 00.389.187zM9.5 6V2.5A.5.5 0 0110 2h4a.5.5 0 01.5.5V6a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});