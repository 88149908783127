define("ember-svg-jar/inlined/smart-watch-square-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-time</title><path d=\"M7.477 7.541c-.143 0-.521 0-.521.658s.378.656.521.656S8 8.855 8 8.2s-.38-.659-.523-.659zM16.565 7.5c-.2 0-.522 0-.522 1.5s.327 1.5.522 1.5.521 0 .521-1.5-.339-1.5-.521-1.5zM12.5 8v.74a.249.249 0 00.25.25h.229a.249.249 0 00.25-.25V7.729A.229.229 0 0013 7.5a.5.5 0 00-.5.5z\"/><path d=\"M21 5.5A2.5 2.5 0 0018.5 3h-13A2.5 2.5 0 003 5.5v13A2.5 2.5 0 005.5 21h13a2.5 2.5 0 002.5-2.5zM7.477 9.855A1.492 1.492 0 015.956 8.2 1.528 1.528 0 119 8.2v1.091A2 2 0 016.989 11.5a.5.5 0 010-1 .933.933 0 00.729-.28.219.219 0 00-.17-.366zM7.25 14.75h9.5a.75.75 0 010 1.5h-9.5a.75.75 0 010-1.5zM10.3 9.5a.5.5 0 11-.5.5.5.5 0 01.5-.5zM9.8 8a.5.5 0 11.5.5.5.5 0 01-.5-.5zm4.748 1.49a.5.5 0 01-.209.407.253.253 0 00-.105.2v.816a.5.5 0 01-1 0v-.673a.249.249 0 00-.25-.25H12a.5.5 0 01-.5-.5V8A1.5 1.5 0 0113 6.5h.729a.5.5 0 01.5.5v1.881a.25.25 0 00.105.2.5.5 0 01.209.409zm2.022 2.01c-1.522 0-1.522-1.818-1.522-2.5s0-2.5 1.522-2.5 1.516 1.815 1.516 2.5 0 2.5-1.521 2.5zM16.18 22H7.82a.248.248 0 00-.2.1.251.251 0 00-.035.225l.443 1.329A.5.5 0 008.5 24h7a.5.5 0 00.475-.342l.443-1.329a.251.251 0 00-.035-.225.248.248 0 00-.203-.104zM7.82 2h8.36a.251.251 0 00.238-.329L15.975.342A.5.5 0 0015.5 0h-7a.5.5 0 00-.475.342l-.443 1.329A.251.251 0 007.82 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});