define("ember-svg-jar/inlined/feed-tumblr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>feed-tumblr</title><path d=\"M13.25 0a.5.5 0 01.5.5V5a.5.5 0 00.5.5h4a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-4a.5.5 0 00-.5.5v5.49c0 4.55 2.88 4.49 5.27 3.23a.5.5 0 01.73.44v3.24a.5.5 0 01-.3.46c-6.59 2.94-11.7-.26-11.7-4.39V10a.5.5 0 00-.5-.5h-2.5a.5.5 0 01-.5-.5V7a.5.5 0 01.38-.48c1.6-.41 4.52-.86 5.31-6.07a.5.5 0 01.49-.45z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});