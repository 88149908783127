define("ember-svg-jar/inlined/astrology-taurus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astrology-taurus</title><path d=\"M16.751 6.285a.252.252 0 01-.125-.223.25.25 0 01.137-.216 10.49 10.49 0 004.485-4.371 1 1 0 00-1.761-.95A8.5 8.5 0 0112 5 8.5 8.5 0 014.513.525a1 1 0 00-1.761.95 10.49 10.49 0 004.485 4.371.25.25 0 01.137.216.252.252 0 01-.125.223 9.5 9.5 0 109.5 0zM12 22a7.5 7.5 0 117.5-7.5A7.508 7.508 0 0112 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});