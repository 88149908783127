define("ember-svg-jar/inlined/accounting-abacus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accounting-abacus</title><path d=\"M23 2a2 2 0 00-2-2H3a2 2 0 00-2 2v20a2 2 0 002 2h18a2 2 0 002-2zm-2 19.5a.5.5 0 01-.5.5h-3.75a.25.25 0 01-.25-.25v-1.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25v1.5a.25.25 0 01-.25.25h-4.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25v2.5a.25.25 0 01-.25.25H3.5a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h3.75a.25.25 0 01.25.25v1a.25.25 0 00.25.25h1.5a.25.25 0 00.25-.25v-1A.25.25 0 019.75 2h4.5a.25.25 0 01.25.25v6a.25.25 0 00.25.25h1.5a.25.25 0 00.25-.25v-6a.25.25 0 01.25-.25h3.75a.5.5 0 01.5.5z\"/><path d=\"M6 6a1.5 1.5 0 001.5 1.5h2a1.5 1.5 0 000-3h-2A1.5 1.5 0 006 6zM13 11a1.5 1.5 0 001.5 1.5h2a1.5 1.5 0 000-3h-2A1.5 1.5 0 0013 11zM13 17.5a1.5 1.5 0 001.5 1.5h2a1.5 1.5 0 000-3h-2a1.5 1.5 0 00-1.5 1.5zM6 16.5A1.5 1.5 0 007.5 18h2a1.5 1.5 0 000-3h-2A1.5 1.5 0 006 16.5z\"/><rect x=\"7.5\" y=\"8.5\" width=\"2\" height=\"5.5\" rx=\".25\" ry=\".25\"/><rect x=\"14.5\" y=\"13.5\" width=\"2\" height=\"1.5\" rx=\".25\" ry=\".25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});