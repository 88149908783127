define("ember-svg-jar/inlined/shipment-crane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-crane</title><path d=\"M16.308 9.75a.25.25 0 00-.249.233l-.851 12.736a.965.965 0 00.968 1.031h3a.966.966 0 00.968-1.032l-.851-12.735a.25.25 0 00-.249-.233zM13 4a.25.25 0 00-.25-.25H.676a.5.5 0 00-.447.724L1 6.026a1.4 1.4 0 001.171.724h.25a.25.25 0 01.25.25v2.783a.25.25 0 01-.143.226A3.018 3.018 0 00.864 12.75a3.179 3.179 0 003.187 3 2.908 2.908 0 002.875-3 1 1 0 00-2 0 .909.909 0 01-.875 1 1.181 1.181 0 01-1.187-1 .934.934 0 01.812-1 1 1 0 001-1V7a.25.25 0 01.25-.25h7.824A.25.25 0 0013 6.5zM12 17.75h-2a.25.25 0 00-.25.25v5.5a.25.25 0 00.25.25h2a1 1 0 001-1v-4a1 1 0 00-1-1z\"/><rect x=\"4.75\" y=\"17.75\" width=\"3.5\" height=\"6\" rx=\".25\" ry=\".25\"/><path d=\"M3.25 18a.25.25 0 00-.25-.25H1a1 1 0 00-1 1v4a1 1 0 001 1h2a.25.25 0 00.25-.25zM23.889 4.118a.814.814 0 00-.713-.368H20.75a.25.25 0 01-.25-.25V.622a.34.34 0 00-.475-.347l-5.051 1.712a.755.755 0 00-.474.67v5.084a.506.506 0 00.5.509h5a.506.506 0 00.5-.509V7a.25.25 0 01.25-.25h1.426a1.4 1.4 0 001.171-.724l.553-1.1a.816.816 0 00-.011-.808z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});