define("ember-svg-jar/inlined/single-woman-circle-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-circle-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 21.5a9.5 9.5 0 119.5-9.5 9.511 9.511 0 01-9.5 9.5z\"/><path d=\"M11.987 16a7.569 7.569 0 00-5.353 2.144.5.5 0 00.35.856h10a.5.5 0 00.351-.856A7.552 7.552 0 0011.987 16zM17.241 10.794V9.545a5.3 5.3 0 10-10.594 0v1.249a4.57 4.57 0 01-.642 2.512 1 1 0 001.681 1.084 5.149 5.149 0 00.453-.9.253.253 0 01.184-.153.249.249 0 01.228.071 4.727 4.727 0 006.786 0 .25.25 0 01.412.082 5.059 5.059 0 00.454.9 1 1 0 001.68-1.085 4.568 4.568 0 01-.642-2.511zm-5.3 2.051a2.778 2.778 0 01-2.785-2.587.249.249 0 01.174-.277 5.72 5.72 0 002.44-1.5.249.249 0 01.36 0 5.709 5.709 0 002.424 1.5.251.251 0 01.173.277 2.78 2.78 0 01-2.783 2.587z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});