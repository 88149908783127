define("ember-svg-jar/inlined/messaging-whatsapp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messaging-whatsapp</title><path d=\"M12 .09A12 12 0 000 12a11.74 11.74 0 002.14 6.77l-1.35 4a.5.5 0 00.12.51.51.51 0 00.51.13l4.2-1.33A12 12 0 0024 12 12 12 0 0012 .09zm7.2 16.72a3.91 3.91 0 01-3.5 2.09 10.71 10.71 0 01-3.45-1 14 14 0 01-5.95-5.16c-1.91-2.53-2-4.9-.16-6.86A2.17 2.17 0 018 5.32a1.54 1.54 0 011.21.87l.38.92.63 1.51a1.1 1.1 0 010 1 5.14 5.14 0 01-1 1.37 12.41 12.41 0 001.79 2.15 9.06 9.06 0 002.73 1.63c.28-.36.83-1 1-1.27a1 1 0 011.34-.36c.38.14 2.51 1.18 2.51 1.18a1.43 1.43 0 01.68.49 2 2 0 01-.07 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});