define("ember-svg-jar/inlined/connect-device-cancel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>connect-device-cancel</title><path d=\"M9.5 16.25v.25a1 1 0 01-1 1h-6a1 1 0 01-1-1V5a1 1 0 011-1h6a1 1 0 011 1v.75a.25.25 0 00.25.25h1a.25.25 0 00.25-.25V4.5A2.5 2.5 0 008.5 2h-6A2.5 2.5 0 000 4.5v15A2.5 2.5 0 002.5 22h6a2.5 2.5 0 002.5-2.5v-3.25a.25.25 0 00-.25-.25h-1a.25.25 0 00-.25.25zM21.5 2h-6A2.5 2.5 0 0013 4.5v1.25a.25.25 0 00.25.25h1a.25.25 0 00.25-.25V5a1 1 0 011-1h6a1 1 0 011 1v11.5a1 1 0 01-1 1h-6a1 1 0 01-1-1v-.25a.25.25 0 00-.25-.25h-1a.25.25 0 00-.25.25v3.25a2.5 2.5 0 002.5 2.5h6a2.5 2.5 0 002.5-2.5v-15A2.5 2.5 0 0021.5 2z\"/><path d=\"M14.27 14.707a1.013 1.013 0 001.414 0 1 1 0 000-1.414l-2.116-2.116a.25.25 0 010-.354l2.116-2.116a1 1 0 10-1.414-1.414l-2.117 2.116a.249.249 0 01-.353 0L9.684 7.293A1 1 0 008.27 8.707l2.116 2.116a.252.252 0 010 .354L8.27 13.293a1 1 0 000 1.414 1.011 1.011 0 001.414 0l2.116-2.116a.249.249 0 01.353 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});