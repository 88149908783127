define("ember-svg-jar/inlined/skiing-skiis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skiing-skiis</title><path d=\"M3.333 20.057A.5.5 0 013 19.585V7.117a.5.5 0 01.167-.373A1 1 0 003.5 6V1.5a1.5 1.5 0 00-3 0V6a1 1 0 00.333.744.5.5 0 01.167.373v12.468a.5.5 0 01-.333.472 1 1 0 000 1.886.5.5 0 01.333.471V23a1 1 0 002 0v-.586a.5.5 0 01.333-.471 1 1 0 000-1.886zM23.333 20.057a.5.5 0 01-.333-.472V7.117a.5.5 0 01.167-.373A1 1 0 0023.5 6V1.5a1.5 1.5 0 00-3 0V6a1 1 0 00.333.744.5.5 0 01.167.373v12.468a.5.5 0 01-.333.472 1 1 0 000 1.886.5.5 0 01.333.471V23a1 1 0 002 0v-.586a.5.5 0 01.333-.471 1 1 0 000-1.886zM10.808 10.021a.5.5 0 00-.429-.091l-5 1.248a.5.5 0 00-.379.485v2.424a.5.5 0 00.621.485l5-1.248a.5.5 0 00.379-.485v-2.424a.5.5 0 00-.192-.394zM8 0a3 3 0 00-3 3v6.087a.5.5 0 00.621.485l5-1.248A.5.5 0 0011 7.839V3a3 3 0 00-3-3zM10.808 15.021a.5.5 0 00-.429-.091l-5 1.248a.5.5 0 00-.379.485V23a1 1 0 001 1h4a1 1 0 001-1v-7.585a.5.5 0 00-.192-.394zM18.621 11.178l-5-1.248a.5.5 0 00-.621.485v2.424a.5.5 0 00.379.485l5 1.248a.5.5 0 00.621-.485v-2.424a.5.5 0 00-.379-.485zM16 0a3 3 0 00-3 3v4.839a.5.5 0 00.379.485l5 1.248A.5.5 0 0019 9.087V3a3 3 0 00-3-3zM18.621 16.178l-5-1.248a.5.5 0 00-.621.485V23a1 1 0 001 1h4a1 1 0 001-1v-6.337a.5.5 0 00-.379-.485z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});