define("ember-svg-jar/inlined/reptile-hippo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>reptile-hippo</title><path d=\"M1.175 15.042a3.174 3.174 0 001.812-1.135 2.5 2.5 0 014.089.226 2.115 2.115 0 001.488.874 2.8 2.8 0 001.912-1.046 2.579 2.579 0 012-1.006 2.507 2.507 0 012.26 1.425 1.281 1.281 0 001.051.627 1.867 1.867 0 001.476-.846 2.415 2.415 0 012.137-1.206 2.5 2.5 0 012.127 1.18c.114.17.738.91 1.3.865a2.526 2.526 0 011.17.209V9.781a.532.532 0 00-.025-.158c-.537-1.609-2.644-3.342-6.005-3.342a13.344 13.344 0 00-4.645.811c0-1.245-1.619-2.7-3.456-.49a3.013 3.013 0 00-3.325.471A4.222 4.222 0 003.5 5.781a3.372 3.372 0 00-3.5 3.5v5.959a2.521 2.521 0 011.175-.198zM3.41 8.827l-1.5-.045a1.5 1.5 0 01.623-1.172.75.75 0 01.877 1.217zm5.3 1.694a1 1 0 111-1 1 1 0 01-.996 1z\"/><path d=\"M22.93 16.5c-1.567.134-2.666-1.556-2.674-1.569a1.049 1.049 0 00-1.716.022 3.372 3.372 0 01-2.752 1.558 2.8 2.8 0 01-2.405-1.482 1.045 1.045 0 00-1.713-.161 4.306 4.306 0 01-3.108 1.643A3.563 3.563 0 015.8 14.927a1.04 1.04 0 00-1.638-.091c-.013.018-1.476 1.838-3.11 1.7A.989.989 0 000 17.484a1 1 0 00.947 1.052c.075 0 .148.006.221.006A5.844 5.844 0 004.9 16.935a5.242 5.242 0 003.664 1.572 5.86 5.86 0 003.81-1.5 4.578 4.578 0 003.416 1.5 5.146 5.146 0 003.621-1.5 4.82 4.82 0 003.66 1.482A1 1 0 0024 17.425a1.023 1.023 0 00-1.07-.925z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});