define("ember-svg-jar/inlined/tags-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-block</title><path d=\"M9.254 17.031a7.322 7.322 0 012.064-4.731c2.687-2.882 4.995-3.278 7.078-2.966a.491.491 0 00.429-.141l.525-.535a3.978 3.978 0 00.985-2.413v-4a2 2 0 00-2-2l-4.258.01a3.945 3.945 0 00-2.415 1L.835 12.084a2 2 0 000 2.828l4.837 4.837a1.977 1.977 0 001.405.581h.013a1.971 1.971 0 001.409-.6l.73-.744a.49.49 0 00.131-.428 7.967 7.967 0 01-.106-1.527zm3.581-11.283a2 2 0 112 2 2 2 0 01-2-2z\"/><path d=\"M17.251 10.749a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm0 11a4.435 4.435 0 01-2.1-.532.251.251 0 01-.059-.4l5.728-5.727a.249.249 0 01.4.059 4.434 4.434 0 01.532 2.1 4.505 4.505 0 01-4.501 4.5zm2.1-8.468a.251.251 0 01.059.4l-5.73 5.725a.251.251 0 01-.4-.059 4.426 4.426 0 01-.532-2.1 4.5 4.5 0 014.5-4.5 4.438 4.438 0 012.101.534z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});