define("ember-svg-jar/inlined/safety-electricity-danger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-electricity-danger</title><circle cx=\"19.649\" cy=\"6.817\" r=\"2.49\"/><path d=\"M3.812 12.552a1.494 1.494 0 00.159 2.98q.069 0 .138-.006l4.041-.4a.544.544 0 01.057 0 .487.487 0 01.345.147l1.2 1.2a.249.249 0 01-.155.425l-1.772.147a1.5 1.5 0 00-1.144.7l-2.435 3.9a1.569 1.569 0 00.273 2.042 1.5 1.5 0 002.207-.37l1.824-2.92a1 1 0 01.775-.465l3.647-.258A3.983 3.983 0 0016 17.891l2.045-3.068a.25.25 0 01.392-.029 8.68 8.68 0 011.833 3.1l.2.6a1.494 1.494 0 002.835-.945l-.3-.9a11.6 11.6 0 00-5.571-6.4A8.771 8.771 0 0113.8 6.466l-1-1.989a1.6 1.6 0 00-1.4-.9 1.389 1.389 0 00-.41.063 1.5 1.5 0 00-.9 2.1l1.035 2.06a11.717 11.717 0 002.592 3.419.248.248 0 01.041.323l-1.711 2.567a.254.254 0 01-.183.11.251.251 0 01-.2-.072l-1-1A3.487 3.487 0 008.2 12.13a3.375 3.375 0 00-.347.018z\"/><path d=\"M6.905 4.9L4.529.271A.5.5 0 004.086 0H1.534a.5.5 0 00-.4.8l1.82 2.437a.25.25 0 01-.2.4H1.113a.5.5 0 00-.366.836l5.2 5.636a.249.249 0 00.415-.262L4.8 5.969a.249.249 0 01.231-.342h1.431a.5.5 0 00.443-.727z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});