define("ember-svg-jar/inlined/shopping-cart-subtract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-cart-subtract</title><path d=\"M18.251 12.5A5.75 5.75 0 1024 18.25a5.756 5.756 0 00-5.749-5.75zm2.187 6.5h-4.375a.75.75 0 110-1.5h4.375a.75.75 0 110 1.5z\"/><path d=\"M12.656 14a1 1 0 00-1-1H8.238A.25.25 0 018 12.808l-1.15-5a.25.25 0 01.238-.308h13.19a.5.5 0 01.474.658L20 10.406a1 1 0 101.9.633l1-3A1.929 1.929 0 0021.07 5.5H6.514a.249.249 0 01-.244-.194l-.878-3.819A1.921 1.921 0 003.514 0H1a1 1 0 000 2l2.24-.055a.252.252 0 01.25.194l3.357 14.6a.25.25 0 01-.108.265 2.251 2.251 0 102.286-.1.255.255 0 01-.125-.165l-.331-1.437A.25.25 0 018.813 15h2.843a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});