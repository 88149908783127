define("ember-svg-jar/inlined/equipment-machine-track", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>equipment-machine-track</title><path d=\"M17 3.514A3.486 3.486 0 1020.488 7 3.492 3.492 0 0017 3.514zM17 8.5A1.5 1.5 0 1118.5 7 1.5 1.5 0 0117 8.5zm0-4.99A3.486 3.486 0 1020.488 7 3.492 3.492 0 0017 3.514zm0 4.99A1.5 1.5 0 1118.5 7 1.5 1.5 0 0117 8.5zm0 2.981A4.486 4.486 0 1121.488 7 4.5 4.5 0 0117 11.485zm0-7.971A3.486 3.486 0 1020.488 7 3.492 3.492 0 0017 3.514zm0 4.99A1.5 1.5 0 1118.5 7 1.5 1.5 0 0117 8.5zM5 16.485A2.515 2.515 0 107.508 19 2.519 2.519 0 005 16.485zm0 3.269a.75.75 0 010-1.5.75.75 0 110 1.5zm0-3.269A2.515 2.515 0 107.508 19 2.519 2.519 0 005 16.485zm0 3.269a.75.75 0 010-1.5.75.75 0 110 1.5zm0-3.269A2.515 2.515 0 107.508 19 2.519 2.519 0 005 16.485zm0 3.269a.75.75 0 010-1.5.75.75 0 110 1.5zm0-3.269A2.515 2.515 0 107.508 19 2.519 2.519 0 005 16.485zm0 3.269a.75.75 0 010-1.5.75.75 0 110 1.5zm16.869-17.62a7.309 7.309 0 00-10.821.566L.889 16.685a4.6 4.6 0 006.419 6.43l14.02-10.191a7.294 7.294 0 00.541-10.79zM5 22.514A3.515 3.515 0 118.508 19 3.522 3.522 0 015 22.514zm0-6.029A2.515 2.515 0 107.508 19 2.519 2.519 0 005 16.485zm0 3.269a.75.75 0 010-1.5.75.75 0 110 1.5zm0-3.269A2.515 2.515 0 107.508 19 2.519 2.519 0 005 16.485zm0 3.269a.75.75 0 010-1.5.75.75 0 110 1.5zm0-3.269A2.515 2.515 0 107.508 19 2.519 2.519 0 005 16.485zm0 3.269a.75.75 0 010-1.5.75.75 0 110 1.5zm0-3.269A2.515 2.515 0 107.508 19 2.519 2.519 0 005 16.485zm0 3.269a.75.75 0 010-1.5.75.75 0 110 1.5zm0-3.269A2.515 2.515 0 107.508 19 2.519 2.519 0 005 16.485zm0 3.269a.75.75 0 010-1.5.75.75 0 110 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});