define("ember-svg-jar/inlined/educative-toys-music", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>educative-toys-music</title><path d=\"M21.868 5.464A.5.5 0 0122.077 5a2.749 2.749 0 10-2.516.333.5.5 0 01.326.417l1.869 17.356a1 1 0 001.988-.214zM6.25 5h6a1.5 1.5 0 001.5-1.5v-2a1.5 1.5 0 00-1.5-1.5h-6a1.5 1.5 0 00-1.5 1.5v2A1.5 1.5 0 006.25 5zm4.8-3.207a.8.8 0 11-.8.8.8.8 0 01.801-.8zm-3.6-.1a.8.8 0 11-.8.8.8.8 0 01.798-.803zM4.75 11.333h9a1.5 1.5 0 001.5-1.5v-2a1.5 1.5 0 00-1.5-1.5h-9a1.5 1.5 0 00-1.5 1.5v2a1.5 1.5 0 001.5 1.5zm7.8-3.25a.8.8 0 11-.8.8.8.8 0 01.801-.8zm-6.6-.1a.8.8 0 11-.8.8.8.8 0 01.798-.803zM3.25 17.667h12a1.5 1.5 0 001.5-1.5v-2a1.5 1.5 0 00-1.5-1.5h-12a1.5 1.5 0 00-1.5 1.5v2a1.5 1.5 0 001.5 1.5zm10.8-3.25a.8.8 0 11-.8.8.8.8 0 01.801-.8zm-9.6-.1a.8.8 0 11-.8.8.8.8 0 01.798-.803zM16.75 19h-15a1.5 1.5 0 00-1.5 1.5v2a1.5 1.5 0 001.5 1.5h15a1.5 1.5 0 001.5-1.5v-2a1.5 1.5 0 00-1.5-1.5zm-13.8 3.25a.8.8 0 11.8-.8.8.8 0 01-.802.8zm12.6.1a.8.8 0 11.8-.8.8.8 0 01-.799.803z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});