define("ember-svg-jar/inlined/file-code-home-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-home-1</title><path d=\"M14.715 11.14l-2.029-2.174a.249.249 0 010-.341l2.028-2.175a.75.75 0 00-1.078-1.042l-.017.018-2.667 2.858a.749.749 0 000 1.023l2.666 2.857a.751.751 0 001.061.036l.018-.018a.749.749 0 00.018-1.042zM16.286 11.14a.75.75 0 001.078 1.042l.018-.018 2.666-2.857a.751.751 0 000-1.024l-2.666-2.857a.752.752 0 00-1.061-.036.731.731 0 00-.036 1.06l2.029 2.175a.25.25 0 010 .341z\"/><path d=\"M23.415 3L21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.869a.25.25 0 00.331.232 2.485 2.485 0 011.339 0A.249.249 0 008 9.868V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.354.146l2.121 2.121a.5.5 0 01.146.355V18a.5.5 0 01-.5.5h-5.362a.249.249 0 00-.235.189 2.462 2.462 0 01-.948 1.363.247.247 0 00.148.448H22a2 2 0 002-2V4.415A2 2 0 0023.415 3z\"/><path d=\"M7.323 12.119a.5.5 0 00-.646 0l-6.5 5.5A.5.5 0 00.5 18.5h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5H5a.5.5 0 00.5-.5v-2.25a1.5 1.5 0 013 0v2.25a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-4.75a.25.25 0 01.25-.25h1.25a.5.5 0 00.323-.882z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});