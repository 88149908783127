define("ember-svg-jar/inlined/smiley-disapointed-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-disapointed-alternate</title><path d=\"M5.947 9.4l4-2a1 1 0 10-.894-1.79l-4 2A1 1 0 005.5 9.5a.989.989 0 00.447-.1zM18.947 7.605l-4-2a1 1 0 10-.894 1.79l4 2a.989.989 0 00.447.105 1 1 0 00.448-1.895z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M10.416 11.756a1 1 0 00-1.832-.8.909.909 0 01-1.668 0 1 1 0 00-1.832.8 2.91 2.91 0 005.332 0zM17.9 10.438a1 1 0 00-1.316.515.909.909 0 01-1.668 0 1 1 0 00-1.832.8 2.91 2.91 0 005.332 0 1 1 0 00-.516-1.315zM12 15a6.053 6.053 0 00-4.789 2.386 1 1 0 001.578 1.228 4 4 0 016.422 0 1 1 0 001.578-1.228A6.053 6.053 0 0012 15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});