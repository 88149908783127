define("ember-svg-jar/inlined/table-lamp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>table-lamp</title><path d=\"M15.5 22h-2a.5.5 0 01-.5-.5V17a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v4.5a.5.5 0 01-.5.5h-2a1 1 0 000 2h7a1 1 0 000-2z\"/><path d=\"M18.565 2.424A.5.5 0 0018.071 2H13.5a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v.5a.5.5 0 01-.5.5H5.929a.5.5 0 00-.494.424l-1.846 12a.5.5 0 00.494.576H14.5a.5.5 0 01.5.5V17a1 1 0 002 0v-1.5a.5.5 0 01.5-.5h2.417a.5.5 0 00.494-.576z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});