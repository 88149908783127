define("ember-svg-jar/inlined/single-woman-actions-star.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-star</title><path d=\"M8.927 17.5a.25.25 0 00.238-.326A3.15 3.15 0 019.243 15a3.237 3.237 0 013.016-2h1.187a.248.248 0 00.229-.151l.124-.286a.254.254 0 000-.2.251.251 0 00-.141-.136c-.672-.256-1.4-.52-2.185-.807a.778.778 0 01-.512-.729v-.468a.25.25 0 01.249-.25A6.37 6.37 0 0015 9.08a.387.387 0 00.084-.592c-.494-.538-.836-1.186-.981-3.317C13.838 1.346 11.236 0 9 0S4.162 1.346 3.9 5.171C3.755 7.3 3.414 7.95 2.92 8.488A.386.386 0 003 9.08a6.35 6.35 0 003.782.9.251.251 0 01.254.25v.463a.779.779 0 01-.513.729c-2.9 1.065-5.211 1.825-5.764 2.922A7.867 7.867 0 000 17.075a.389.389 0 00.1.3.4.4 0 00.29.126zM20.736 23.828a1.277 1.277 0 001.506-.166 1.254 1.254 0 00.315-1.45L21.333 19.4a.251.251 0 01.063-.286l2.213-1.971a1.264 1.264 0 00.3-1.378A1.234 1.234 0 0022.74 15h-2.5a.249.249 0 01-.229-.151l-1.344-3.116-.013-.028a1.293 1.293 0 00-2.317.028l-1.347 3.115a.25.25 0 01-.23.151h-2.5a1.229 1.229 0 00-1.166.76 1.271 1.271 0 00.319 1.4l2.194 1.955a.249.249 0 01.063.286l-1.223 2.815a1.255 1.255 0 00.315 1.451 1.278 1.278 0 001.506.166l3.111-1.752a.249.249 0 01.245 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});