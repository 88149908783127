define("ember-svg-jar/inlined/server-lock-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-lock-1</title><path d=\"M10 20a.25.25 0 01-.25-.25v-2.5A.25.25 0 0110 17h2a.5.5 0 00.5-.5V16a1.994 1.994 0 011.207-1.83.5.5 0 00.3-.432 4.9 4.9 0 01.182-1.1.5.5 0 00-.48-.638H2.5a2.5 2.5 0 000 5H8a.25.25 0 01.25.25v2.5A.25.25 0 018 20H5a.75.75 0 000 1.5h7a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5zm-7-5.5a1.25 1.25 0 111.25 1.25A1.249 1.249 0 013 14.5zm5 1.25a1.25 1.25 0 111.25-1.25A1.25 1.25 0 018 15.75zM1 20a.75.75 0 000 1.5h1A.75.75 0 002 20zM2.5 5h13a2.5 2.5 0 000-5h-13a2.5 2.5 0 000 5zM3 2.5a1.25 1.25 0 111.25 1.25A1.249 1.249 0 013 2.5zm3.75 0A1.25 1.25 0 118 3.75 1.249 1.249 0 016.75 2.5zM17.622 9.2a.5.5 0 00.358-.412A2.1 2.1 0 0018 8.5 2.5 2.5 0 0015.5 6h-13a2.5 2.5 0 000 5h12.282a.5.5 0 00.385-.181A4.989 4.989 0 0117.622 9.2zM3 8.5a1.25 1.25 0 111.25 1.25A1.249 1.249 0 013 8.5zm5 1.25A1.25 1.25 0 119.25 8.5 1.25 1.25 0 018 9.75zM19 10.75a3.5 3.5 0 00-3.5 3.5v1.018a.25.25 0 01-.214.247A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.286-1.485.25.25 0 01-.214-.247V14.25a3.5 3.5 0 00-3.5-3.5zm1 9.5a1 1 0 11-1-1 1 1 0 011 1zm-1-7.5a1.5 1.5 0 011.5 1.5v1a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-1a1.5 1.5 0 011.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});