define("ember-svg-jar/inlined/video-edit-cc-titles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-edit-cc-titles</title><path d=\"M21.5 1h-19A2.5 2.5 0 000 3.5v17A2.5 2.5 0 002.5 23h19a2.5 2.5 0 002.5-2.5v-17A2.5 2.5 0 0021.5 1zm-4 8.25h-2a1.252 1.252 0 00-1.25 1.25v3a1.252 1.252 0 001.25 1.25h2a1.252 1.252 0 001.25-1.25.76.76 0 01.75-.761.741.741 0 01.75.74 2.757 2.757 0 01-2.75 2.771h-2a2.752 2.752 0 01-2.75-2.75v-3a2.752 2.752 0 012.75-2.75h2a2.752 2.752 0 012.75 2.75.764.764 0 01-.75.766.738.738 0 01-.75-.735V10.5a1.252 1.252 0 00-1.25-1.25zm-9 0h-2a1.252 1.252 0 00-1.25 1.25v3a1.252 1.252 0 001.25 1.25h2a1.252 1.252 0 001.25-1.25.76.76 0 01.75-.761.741.741 0 01.75.74A2.757 2.757 0 018.5 16.25h-2a2.752 2.752 0 01-2.75-2.75v-3A2.752 2.752 0 016.5 7.75h2a2.752 2.752 0 012.75 2.75.764.764 0 01-.75.766.738.738 0 01-.75-.735V10.5A1.252 1.252 0 008.5 9.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});