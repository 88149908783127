define("ember-svg-jar/inlined/segway-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>segway-1</title><path d=\"M22 13.625a2 2 0 00-2 2v6a2 2 0 004 0v-6a2 2 0 00-2-2zM2 13.625a2 2 0 00-2 2v6a2 2 0 004 0v-6a2 2 0 00-2-2zM22 2.375a1 1 0 00-1-1h-2.75a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25H21a1 1 0 001-1zM5.75 1.375H3a1 1 0 000 2h2.75a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25zM16.728 13.073a1.8 1.8 0 00-1.765-1.448h-.213a.25.25 0 00-.25.25v4.25a.75.75 0 01-1.5 0v-11a.25.25 0 01.25-.25H16a1 1 0 001-1v-2.5a1 1 0 00-1-1H8a1 1 0 00-1 1v2.5a1 1 0 001 1h2.75a.25.25 0 01.25.25v11a.75.75 0 01-1.5 0v-4.25a.25.25 0 00-.25-.25h-.335a1.8 1.8 0 00-1.765 1.448l-1.08 5.4a1.8 1.8 0 001.765 2.152h8.209a1.8 1.8 0 001.765-2.152z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});