define("ember-svg-jar/inlined/gymnastics-trampoline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gymnastics-trampoline</title><path d=\"M12 4.25c-6.729 0-12 2.2-12 5v6.5a1 1 0 002 0v-3.262a.25.25 0 01.372-.219 12.457 12.457 0 002.28.961.5.5 0 01.348.476v5.044a1 1 0 002 0v-4.344a.5.5 0 01.579-.494A28 28 0 0012 14.25a28 28 0 004.421-.338.5.5 0 01.579.494v4.344a1 1 0 002 0v-5.044a.5.5 0 01.348-.476 12.457 12.457 0 002.28-.961.25.25 0 01.372.219v3.262a1 1 0 002 0v-6.5c0-2.804-5.271-5-12-5zm0 7c-4.418 0-8-1.007-8-2.25s3.582-2.25 8-2.25S20 7.757 20 9s-3.582 2.25-8 2.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});