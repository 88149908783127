define("ember-svg-jar/inlined/microphone-podcast-international-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>microphone-podcast-international-1</title><path d=\"M18.747 6.967a6.75 6.75 0 10-13.5-.219 6.7 6.7 0 001.977 4.773.24.24 0 00.4-.112 4.573 4.573 0 012.65-3.046.25.25 0 00.118-.331L10 7.177c-.062-.13 0-.275 0-.429a2 2 0 00-2-2h-.469a.249.249 0 01-.212-.119.245.245 0 01-.01-.243 5.26 5.26 0 017.045-2.331.234.234 0 01-.1.443 2 2 0 000 4h.25a.752.752 0 01.75.75 2.251 2.251 0 001.11 1.942.249.249 0 01.117.156.252.252 0 01-.032.193 5.2 5.2 0 01-.436.594.252.252 0 00-.03.28 4.361 4.361 0 01.382.982.25.25 0 00.176.18.254.254 0 00.243-.065 6.686 6.686 0 001.963-4.543z\"/><path d=\"M12 9.54a2.97 2.97 0 00-3 2.934v2.84a3 3 0 006 0v-2.84a2.971 2.971 0 00-3-2.934z\"/><path d=\"M16.25 13.748v1.5a4.25 4.25 0 11-8.5 0v-1.5a.75.75 0 10-1.5 0v1.5a5.758 5.758 0 004.791 5.67.252.252 0 01.209.247v1.083a.25.25 0 01-.25.25H8A.75.75 0 008 24h8a.75.75 0 000-1.5h-3a.25.25 0 01-.25-.25v-1.085a.25.25 0 01.208-.247 5.759 5.759 0 004.792-5.67v-1.5a.75.75 0 10-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});