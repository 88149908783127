define("ember-svg-jar/inlined/focus-auto-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>focus-auto-alternate</title><path d=\"M12 6.49a3.5 3.5 0 00-3.5 3.5v6.5a1 1 0 002 0v-3a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25v3a1 1 0 002 0v-6.5a3.5 3.5 0 00-3.5-3.5zm-1.25 4.75a.25.25 0 01-.25-.25v-1a1.5 1.5 0 013 0v1a.25.25 0 01-.25.25z\"/><path d=\"M23.354 13.561a2.209 2.209 0 000-3.122l-2.222-2.221V5.075a2.209 2.209 0 00-2.207-2.207h-3.143L13.561.646a2.209 2.209 0 00-3.122 0L8.218 2.868H5.075a2.209 2.209 0 00-2.207 2.207v3.143L.646 10.439a2.209 2.209 0 000 3.122l2.222 2.221v3.143a2.209 2.209 0 002.207 2.207h3.143l2.221 2.222a2.209 2.209 0 003.122 0l2.221-2.222h3.143a2.209 2.209 0 002.207-2.207v-3.143zm-1.445-1.738a.25.25 0 010 .354l-2.484 2.484a1 1 0 00-.293.707v3.514a.25.25 0 01-.25.25h-3.514a1 1 0 00-.707.293l-2.484 2.484a.25.25 0 01-.354 0l-2.484-2.484a1 1 0 00-.707-.293H5.118a.25.25 0 01-.25-.25v-3.514a1 1 0 00-.293-.707l-2.484-2.484a.25.25 0 010-.354l2.484-2.484a1 1 0 00.293-.707V5.118a.25.25 0 01.25-.25h3.514a1 1 0 00.707-.293l2.484-2.484a.25.25 0 01.354 0l2.484 2.484a1 1 0 00.707.293h3.514a.25.25 0 01.25.25v3.514a1 1 0 00.293.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});