define("ember-svg-jar/inlined/computer-chip-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>computer-chip-shield</title><path d=\"M2.435 11H1a1 1 0 000 2h1.435a1 1 0 100-2zM2.435 16H1a1 1 0 000 2h1.435a1 1 0 000-2zM2.435 6.5H1a1 1 0 100 2h1.435a1 1 0 100-2zM23 11h-1.435a1 1 0 000 2H23a1 1 0 000-2zM21.565 8.5H23a1 1 0 000-2h-1.435a1 1 0 000 2zM24 17a1 1 0 00-1-1h-1.435a1 1 0 000 2H23a1 1 0 001-1zM12 3.435a1 1 0 001-1V1a1 1 0 00-2 0v1.435a1 1 0 001 1zM7.5 3.435a1 1 0 001-1V1a1 1 0 10-2 0v1.435a1 1 0 001 1zM16.5 3.435a1 1 0 001-1V1a1 1 0 00-2 0v1.435a1 1 0 001 1zM12 20.566a1 1 0 00-1 1V23a1 1 0 102 0v-1.434a1 1 0 00-1-1zM16.5 20.566a1 1 0 00-1 1V23a1 1 0 102 0v-1.434a1 1 0 00-1-1zM7.5 20.566a1 1 0 00-1 1V23a1 1 0 002 0v-1.434a1 1 0 00-1-1zM18.067 4.5H5.933A1.435 1.435 0 004.5 5.934v12.133A1.436 1.436 0 005.933 19.5h12.134a1.436 1.436 0 001.433-1.433V5.934A1.435 1.435 0 0018.067 4.5zm-1.491 4c0 3.2-.49 7.063-4.311 8.506a.756.756 0 01-.53 0C7.17 15.283 7.424 9.907 7.424 8.5a.75.75 0 01.75-.75h7.652a.75.75 0 01.75.75z\"/><path d=\"M13.338 9.7a.249.249 0 00-.157-.444H9.193a.25.25 0 00-.18.077.245.245 0 00-.07.183 11.29 11.29 0 00.458 2.92.252.252 0 00.167.163.249.249 0 00.229-.045zM10.45 13.949a.25.25 0 00-.034.356 4.332 4.332 0 001.477 1.142.251.251 0 00.214 0c1.857-.874 2.573-2.627 2.832-4.562a.25.25 0 00-.4-.228z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});