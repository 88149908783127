define("ember-svg-jar/inlined/archery-bow-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>archery-bow-1</title><path d=\"M22.928 19.452a1 1 0 01-.428-.82V11.9a2.4 2.4 0 00-2.4-2.4.4.4 0 01-.29-.124L18.3 7.805a.5.5 0 01.007-.7l1.89-1.89 1.474-.368a2.292 2.292 0 001.674-1.674l.64-2.552a.5.5 0 00-.606-.606l-2.552.638a2.293 2.293 0 00-1.674 1.674L18.785 3.8 16.94 5.646a.5.5 0 01-.714-.008l-1.357-1.416a.42.42 0 01-.119-.3A2.43 2.43 0 0012.323 1.5H5.368a1 1 0 01-.82-.428A2.5 2.5 0 000 2.5V16a1 1 0 002 0V5.5a.5.5 0 01.5-.5 2.481 2.481 0 001.712-.686.5.5 0 01.7.009l4.881 4.884a1 1 0 101.414-1.415L7.341 3.927a.25.25 0 01.177-.427h4.805a.427.427 0 01.427.427 2.415 2.415 0 00.674 1.679l1.4 1.462a.5.5 0 01-.008.7l-9.61 9.61a.845.845 0 01-.6.247 2.5 2.5 0 00-1.621.6l-2.814 2.4a.5.5 0 00.2.866l1.335.334a.5.5 0 01.375.423l.165 1.32a.5.5 0 00.863.278l2.593-2.8a2.493 2.493 0 00.665-1.7.731.731 0 01.214-.516l9.6-9.6a.5.5 0 01.359-.146.5.5 0 01.356.154l1.462 1.525a2.411 2.411 0 001.742.737.4.4 0 01.4.4v4.58a.25.25 0 01-.427.177l-3.866-3.866a1 1 0 00-1.414 1.414l4.884 4.884a.5.5 0 01.009.7A2.479 2.479 0 0019 21.5a.5.5 0 01-.5.5H8a1 1 0 000 2h13.5a2.5 2.5 0 001.428-4.548z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});