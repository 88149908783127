define("ember-svg-jar/inlined/building-modern-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>building-modern-2</title><path d=\"M7.125 6h8a.5.5 0 00.3-.9l-3.1-2.325a.5.5 0 01-.2-.4V1a1 1 0 00-2 0v1.375a.5.5 0 01-.2.4L6.825 5.1a.5.5 0 00.3.9zM15.125 7.5h-8a.5.5 0 00-.5.5v15.5a.5.5 0 00.5.5h8a.5.5 0 00.5-.5V8a.5.5 0 00-.5-.5zm-5 13a.75.75 0 01-1.5 0v-10a.75.75 0 011.5 0zm3.5 0a.75.75 0 01-1.5 0v-10a.75.75 0 011.5 0zM4.625 13h-4a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-10a.5.5 0 00-.5-.5zm-1.25 8a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zm0-4a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zM23.42 12.811l-5.25-2.25a.75.75 0 00-1.045.689v12a.75.75 0 00.75.75h5.25a.75.75 0 00.75-.75V13.5a.749.749 0 00-.455-.689zM19.875 20.5a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zm0-4.5a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zm2.5 4.5a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zm0-4.5a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});