define("ember-svg-jar/inlined/locker-room-wash-hands", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>locker-room-wash-hands</title><path d=\"M20.75 17.876H14.5a.5.5 0 010-1h5.25a1.25 1.25 0 000-2.5H12a.5.5 0 010-1h3.5a.5.5 0 00.5-.5v-.5a1.5 1.5 0 00-1.5-1.5H9a4.984 4.984 0 00-4.95 4.5H2.5a.5.5 0 00-.5.5v5.5a.5.5 0 00.5.5h2.055a3.984 3.984 0 003.445 2h10.75a1.25 1.25 0 000-2.5H14.5a.5.5 0 010-1h6.25a1.25 1.25 0 000-2.5z\"/><circle cx=\"13.5\" cy=\"1.376\" r=\"1.25\"/><circle cx=\"13.5\" cy=\"4.876\" r=\"1.25\"/><circle cx=\"13.5\" cy=\"8.376\" r=\"1.25\"/><path d=\"M10.069 2.488A1.25 1.25 0 108.311.991a1.25 1.25 0 001.758 1.5zM8 6.126a1.251 1.251 0 10-1.114-.681A1.251 1.251 0 008 6.126zM6.5 9.626a1.25 1.25 0 10-1.112-.681 1.251 1.251 0 001.112.681zM17.5 2.626A1.252 1.252 0 0018.69.992a1.25 1.25 0 10-1.19 1.634zM17.811 5.259a1.249 1.249 0 10.8-1.574 1.252 1.252 0 00-.8 1.574zM19.388 7.806a1.249 1.249 0 10.727-.62 1.251 1.251 0 00-.727.62z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});