define("ember-svg-jar/inlined/statistics-daytum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>statistics-daytum</title><rect x=\".5\" y=\"17\" width=\"3\" height=\"7\" rx=\".5\" ry=\".5\"/><rect x=\"4.5\" y=\"12\" width=\"3\" height=\"12\" rx=\".5\" ry=\".5\"/><rect x=\"8.5\" y=\"15\" width=\"3\" height=\"9\" rx=\".5\" ry=\".5\"/><rect x=\"12.5\" y=\"8\" width=\"3\" height=\"16\" rx=\".5\" ry=\".5\"/><rect x=\"16.5\" y=\"12\" width=\"3\" height=\"12\" rx=\".5\" ry=\".5\"/><rect x=\"20.5\" y=\"4\" width=\"3\" height=\"20\" rx=\".5\" ry=\".5\"/><rect x=\".5\" width=\"23\" height=\"3\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});