define("ember-svg-jar/inlined/bathroom-laundry-bin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bathroom-laundry-bin</title><path d=\"M22 11.25a.5.5 0 00-.5-.5h-19a.5.5 0 00-.5.5v9.25A3.5 3.5 0 005.5 24h13a3.5 3.5 0 003.5-3.5zM23 7.75a1.5 1.5 0 00-1.5-1.5h-3.75a.5.5 0 01-.5-.5v-.5a5.25 5.25 0 00-10.5 0v.5a.5.5 0 01-.5.5H2.5a1.5 1.5 0 000 3h19a1.5 1.5 0 001.5-1.5zM9.25 5.25a2.75 2.75 0 015.5 0v.5a.5.5 0 01-.5.5h-4.5a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});