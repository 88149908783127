define("ember-svg-jar/inlined/measure-ruler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>measure-ruler</title><path d=\"M23.394 6.424a1 1 0 000-1.414l-3.182-3.183a1 1 0 00-1.414 0L17.729 2.9a.5.5 0 01-.69.016A10 10 0 002.913 17.039a.5.5 0 01-.017.69L1.827 18.8a1 1 0 000 1.414l3.183 3.18a1 1 0 001.414 0l2.3-2.3a.5.5 0 000-.707l-1.77-1.766a.75.75 0 011.06-1.06l1.769 1.767a.5.5 0 00.707 0l1.768-1.768a.5.5 0 000-.707l-1.768-1.767a.75.75 0 111.06-1.061l1.768 1.768a.5.5 0 00.707 0l1.768-1.768a.5.5 0 000-.707l-1.768-1.768a.75.75 0 111.061-1.06l1.768 1.768a.5.5 0 00.707 0l1.767-1.768a.5.5 0 000-.707l-1.767-1.768a.75.75 0 011.06-1.061l1.768 1.768a.5.5 0 00.707 0zm-9.21.018l-7.742 7.741a.5.5 0 01-.728-.016 6 6 0 018.453-8.453.5.5 0 01.017.728z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});