define("ember-svg-jar/inlined/touch-finger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>touch-finger</title><path d=\"M19.073 14.779l-5.079-1.823V7.5a2 2 0 00-4 0v8.461l-.826-1.138a1.545 1.545 0 00-1.2-.558l-.99.019a1.476 1.476 0 00-1.3 2.179l3.735 6.773A1.464 1.464 0 0010.7 24h6.388a1.478 1.478 0 001.378-.989l1.519-6.68a.461.461 0 00.013-.111 1.434 1.434 0 00-.925-1.441z\"/><path d=\"M6.1 11.9a1 1 0 00.473-1.333A5.93 5.93 0 016 8a6 6 0 0112 0 5.93 5.93 0 01-.577 2.57 1 1 0 00.477 1.33 1.023 1.023 0 001.333-.473A7.928 7.928 0 0020 8 8 8 0 004 8a7.928 7.928 0 00.771 3.43 1 1 0 001.329.47z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});