define("ember-svg-jar/inlined/crypto-currency-bitcoin-cloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-cloud</title><path d=\"M21.87 12.233a.253.253 0 01-.1-.28 3.65 3.65 0 00-3.682-4.659.25.25 0 01-.246-.161A6.257 6.257 0 005.743 9.14a.25.25 0 01-.239.241 5.755 5.755 0 00.235 11.5H19.3a4.711 4.711 0 002.566-8.652zm-6.12 2.252a2.242 2.242 0 01-1.35 2.052.251.251 0 00-.15.229v1.219a.75.75 0 01-1.5 0v-1a.25.25 0 00-.25-.25H12a.25.25 0 00-.25.25v1a.75.75 0 01-1.5 0v-1a.25.25 0 00-.25-.25.75.75 0 01-.75-.75v-6a.75.75 0 01.75-.75.25.25 0 00.25-.25v-1a.75.75 0 111.5 0v1a.25.25 0 00.25.25h.5a.25.25 0 00.25-.25v-1a.75.75 0 111.5 0V9.2a.251.251 0 00.15.229 2.244 2.244 0 011.35 2.052 2.219 2.219 0 01-.46 1.349.251.251 0 000 .3 2.219 2.219 0 01.46 1.355z\"/><path d=\"M13.5 13.735H11a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h2.5a.75.75 0 000-1.5zM14.25 11.485a.752.752 0 00-.75-.75H11a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h2.5a.752.752 0 00.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});