define("ember-svg-jar/inlined/hierarchy-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hierarchy-1</title><path d=\"M21 0a3 3 0 00-3 3 2.971 2.971 0 00.876 2.1.25.25 0 01.05.279l-2.7 5.979a.25.25 0 01-.233.147 2.9 2.9 0 00-1.945.726.251.251 0 01-.276.035l-2.651-1.327a.249.249 0 01-.138-.244A2.993 2.993 0 105.8 12.531a.251.251 0 01.04.281l-2.512 5.063a.25.25 0 01-.235.139A2.993 2.993 0 106 21a2.978 2.978 0 00-.8-2.032.251.251 0 01-.04-.281l2.513-5.064a.25.25 0 01.234-.139 2.9 2.9 0 002.049-.716.25.25 0 01.275-.035l2.652 1.326a.251.251 0 01.138.244 2.993 2.993 0 105.98.2 2.969 2.969 0 00-.876-2.1.251.251 0 01-.05-.28l2.7-5.983a.252.252 0 01.23-.147A2.995 2.995 0 1021 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});