define("ember-svg-jar/inlined/road-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign</title><path d=\"M18.574 14.566a.75.75 0 00-.727-.566H13a.249.249 0 00-.25.25v2a.75.75 0 01-1.5 0v-2A.25.25 0 0011 14H6.151a.751.751 0 00-.727.566l-2.152 8.5A.752.752 0 004 24h7a.25.25 0 00.25-.25v-3.5a.75.75 0 011.5 0v3.5A.249.249 0 0013 24h7a.752.752 0 00.727-.934zM8 5.25h8a.75.75 0 00.724-.943l-1-3.75A.75.75 0 0015 0H9a.75.75 0 00-.725.557l-1 3.75A.75.75 0 008 5.25z\"/><path d=\"M23.983 14.593L22.5 7.653A1.252 1.252 0 0021.25 6.5H2.75A1.251 1.251 0 001.5 7.654L.017 14.593A.693.693 0 000 14.75 1.252 1.252 0 001.25 16h1.257a1.246 1.246 0 001.216-.962l1.324-3.624a.251.251 0 01.235-.164h13.435a.251.251 0 01.235.164l1.325 3.624a1.244 1.244 0 001.216.962h1.257A1.25 1.25 0 0024 14.75a.693.693 0 00-.017-.157z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});