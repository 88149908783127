define("ember-svg-jar/inlined/science-fiction-alien-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-fiction-alien-1</title><path d=\"M12 .25c-6.991 0-11 3.65-11 10 0 6.582 7.633 13.5 11 13.5s11-6.918 11-13.5c0-6.35-4.009-10-11-10zm1.583 14.429a5.847 5.847 0 011.059-4.494c1.1-1.338 2.894-2.016 5.344-2.016a.5.5 0 01.5.45 6.472 6.472 0 01-1.461 4.6 6.36 6.36 0 01-4.948 1.86.5.5 0 01-.494-.4zm-3.166 0a.5.5 0 01-.491.4 6.36 6.36 0 01-4.948-1.86 6.472 6.472 0 01-1.461-4.6.5.5 0 01.5-.45c2.45 0 4.248.678 5.344 2.016a5.847 5.847 0 011.056 4.494z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});