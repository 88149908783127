define("ember-svg-jar/inlined/real-estate-search-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-search-house</title><path d=\"M23.561 21.439L19.2 17.077a.5.5 0 01-.058-.637 10.544 10.544 0 10-2.7 2.7.5.5 0 01.637.058l4.363 4.363a1.5 1.5 0 002.122-2.122zM2 10.5a8.5 8.5 0 118.5 8.5A8.51 8.51 0 012 10.5z\"/><path d=\"M11.185 5.271a1 1 0 00-1.37 0L5.158 9.635a.5.5 0 00.342.865H6a.5.5 0 01.5.5v3.5a1 1 0 001 1h6a1 1 0 001-1V11a.5.5 0 01.5-.5h.5a.5.5 0 00.342-.865z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});