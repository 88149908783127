define("ember-svg-jar/inlined/android-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>android-download</title><path d=\"M12.75 19.5a6.029 6.029 0 014.56-5.823.25.25 0 00.19-.243V10.5a.75.75 0 00-.75-.75h-12a.75.75 0 00-.75.75v10.024a1.221 1.221 0 001.212 1.227h7.62a.25.25 0 00.237-.33 6.257 6.257 0 01-.319-1.921zM3.97 7.172A1 1 0 004.914 8.5h11.669a1 1 0 00.943-1.331A6.865 6.865 0 0014.142 3.3a.25.25 0 01-.119-.314l.633-1.625a1 1 0 00-1.863-.723l-.7 1.788a.251.251 0 01-.269.157 7.406 7.406 0 00-1.068-.083 7.531 7.531 0 00-1.08.082.25.25 0 01-.268-.158L8.721.642a1 1 0 00-1.867.719l.626 1.627a.249.249 0 01-.12.312 6.806 6.806 0 00-3.39 3.872zM13 5a.75.75 0 11-.75.75A.75.75 0 0113 5zM8.5 5a.75.75 0 11-.75.75A.75.75 0 018.5 5zM20.75 11a1 1 0 10-2 0v2.256a.25.25 0 00.239.249 5.95 5.95 0 011.44.241.249.249 0 00.321-.24zM1.75 19a1 1 0 001-1v-7a1 1 0 00-2 0v7a1 1 0 001 1z\"/><path d=\"M14.25 19.5a4.5 4.5 0 104.5-4.5 4.5 4.5 0 00-4.5 4.5zm2.538.309a.5.5 0 01.462-.309h.5a.25.25 0 00.25-.25V17.5a.75.75 0 011.5 0v1.75a.25.25 0 00.25.25h.5a.5.5 0 01.354.854l-1.5 1.5a.5.5 0 01-.708 0l-1.5-1.5a.5.5 0 01-.108-.544z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});