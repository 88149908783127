define("ember-svg-jar/inlined/bird-cage-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bird-cage-2</title><path d=\"M12.364 10.006a4.445 4.445 0 012.516.63.25.25 0 00.372-.162l.235-1.091a.537.537 0 00.011-.106 1.5 1.5 0 00-1.5-1.5 1.474 1.474 0 00-.75.21 1.485 1.485 0 00-1.5 0 1.468 1.468 0 00-.75-.21 1.5 1.5 0 00-1.5 1.5.489.489 0 00.016.126l.306 1.179a.251.251 0 00.381.146 4.3 4.3 0 012.163-.722z\"/><path d=\"M22.209 19.835a.89.89 0 00-.709-.335h-.59L19.7 6.677v-.022a2.992 2.992 0 00-2.8-2.638 5 5 0 00-9.8 0 3 3 0 00-2.8 2.66L3.088 19.5H2.5a.89.89 0 00-.711.335.909.909 0 00-.142.809l.49 1.462A2.5 2.5 0 004.559 24h14.877a2.5 2.5 0 002.421-1.874l.5-1.52a.892.892 0 00-.148-.771zM12 2a3 3 0 012.816 2H9.182A3 3 0 0112 2zM6.288 6.885A1 1 0 017.281 6h9.435a1 1 0 01.992.877l1.14 12.076a.506.506 0 01-.128.384.5.5 0 01-.37.163H5.646a.5.5 0 01-.5-.547z\"/><path d=\"M9.105 17a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-2.5a3.454 3.454 0 00-6.61-1.451l-1.364 1.364-.556.557a.75.75 0 00.53 1.28h1zm3.643-3a.75.75 0 11-.75-.75.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});