define("ember-svg-jar/inlined/app-window-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-share</title><path d=\"M21.275 11a2.752 2.752 0 00-2.75 2.75 2.452 2.452 0 00.063.538.25.25 0 01-.116.268l-2.665 1.6a.25.25 0 01-.288-.021 2.722 2.722 0 00-1.744-.634 2.75 2.75 0 102.055 4.559.248.248 0 01.281-.067l2.263.905a.25.25 0 01.156.257c0 .032-.005.064-.005.1a2.78 2.78 0 10.7-1.809.252.252 0 01-.281.067l-2.268-.913a.248.248 0 01-.156-.257v-.095a2.515 2.515 0 00-.063-.539.25.25 0 01.116-.268l2.665-1.6a.249.249 0 01.288.022 2.726 2.726 0 001.744.634 2.75 2.75 0 100-5.5z\"/><path d=\"M9.222 19.222a.97.97 0 00-.951-.721h-5.3a1 1 0 01-1-1V5.251A.25.25 0 012.225 5h19.5a.25.25 0 01.25.25V8a1 1 0 00.388.777l.022.018a.983.983 0 001.59-.778V3a3 3 0 00-3-3h-18a3 3 0 00-3 3v14.5a3 3 0 003 3h5.3a1 1 0 00.95-1.256zM9.629 2a.967.967 0 011.693 0 .977.977 0 01.153.5.98.98 0 01-.153.5.967.967 0 01-1.693 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.967.967 0 011.693 0 .977.977 0 01.153.5.98.98 0 01-.153.5.967.967 0 01-1.693 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.562.091a1 1 0 01.908-.591.986.986 0 01.847.5.977.977 0 01.153.5.98.98 0 01-.153.5.967.967 0 01-1.693 0 .972.972 0 01-.154-.5.987.987 0 01.092-.408z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});