define("ember-svg-jar/inlined/crypto-currency-bitcoin-monitor-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-monitor-shield</title><path d=\"M13 7.706h-2.25v1.5H13a.75.75 0 100-1.5zM10.75 4.706v1.5H13a.75.75 0 000-1.5z\"/><path d=\"M10.69 14.261a3.638 3.638 0 001.288.346 3.664 3.664 0 001.289-.346c3.081-1.174 5.233-3.972 5.233-6.8v-5a1.545 1.545 0 00-.928-1.416A16.419 16.419 0 0011.978 0a16.018 16.018 0 00-5.55 1.04A1.545 1.545 0 005.5 2.456v5a7.715 7.715 0 005.19 6.805zM9.25 3.956a.75.75 0 01.75-.75h1a.25.25 0 00.25-.25v-.5a.75.75 0 111.5 0v.5a.25.25 0 00.25.25 2.24 2.24 0 011.665 3.75A2.24 2.24 0 0113 10.706a.25.25 0 00-.25.25v.5a.75.75 0 01-1.5 0v-.5a.25.25 0 00-.25-.25h-1a.75.75 0 01-.75-.75z\"/><path d=\"M22 3h-1a1 1 0 000 2h.5a.5.5 0 01.5.5v12a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5H3a1 1 0 000-2H2a2 2 0 00-2 2v13a2 2 0 002 2h8.75a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H8a1 1 0 000 2h8a1 1 0 000-2h-2.75a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25H22a2 2 0 002-2V5a2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});