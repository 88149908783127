define("ember-svg-jar/inlined/certified-diploma-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>certified-diploma-1</title><path d=\"M23.047 11.375a2.724 2.724 0 00-2.241-1.369 2.743 2.743 0 00-4.61 0A2.745 2.745 0 0013.891 14 2.726 2.726 0 0015 17.644V23.5a.5.5 0 00.82.385l2.68-2.235 2.68 2.235A.5.5 0 0022 23.5v-5.856A2.725 2.725 0 0023.112 14a2.723 2.723 0 00-.065-2.625zm-7.681 2.155a1.243 1.243 0 011.161-2.01.751.751 0 00.813-.471 1.243 1.243 0 012.322 0 .753.753 0 00.813.469 1.243 1.243 0 011.161 2.012.752.752 0 000 .941 1.242 1.242 0 01-1.161 2.009.759.759 0 00-.812.469 1.243 1.243 0 01-2.323 0 .75.75 0 00-.7-.478.891.891 0 00-.115.009 1.244 1.244 0 01-1.162-2.013.75.75 0 00.003-.937z\"/><circle cx=\"18.5\" cy=\"14\" r=\"2\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v14A1.5 1.5 0 001.5 17H12a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5H6.175a.25.25 0 01-.245-.2 3.511 3.511 0 00-2.73-2.73.249.249 0 01-.2-.245v-4.78a.093.093 0 01.078-.092A3.511 3.511 0 005.93 3.2a.249.249 0 01.245-.2h11.65a.249.249 0 01.245.2 3.512 3.512 0 002.73 2.73.249.249 0 01.2.245V8a.5.5 0 00.276.447l2 1A.5.5 0 0024 9V1.5A1.5 1.5 0 0022.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});