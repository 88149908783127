define("ember-svg-jar/inlined/network-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>network-refresh</title><path d=\"M3.927 17.9a.25.25 0 01.2-.4h2.112a.25.25 0 01.234.163 13.135 13.135 0 00.548 1.282 1 1 0 001.791-.891 2.848 2.848 0 01-.094-.21.25.25 0 01.232-.344H13a1 1 0 100-2H8.105a.249.249 0 01-.243-.2A14.957 14.957 0 017.5 12a15.213 15.213 0 01.254-2.8A.25.25 0 018 9h8a.25.25 0 01.245.2c.075.4.138.806.18 1.242a1 1 0 101.99-.189 16.373 16.373 0 00-.123-.968.25.25 0 01.251-.285h2.805a.251.251 0 01.239.178 9.552 9.552 0 01.3 1.313 1 1 0 001.976-.3C22.391.439 12.589-.041 11.932 0a12 12 0 00-9.188 19.636 1 1 0 101.541-1.274 10.813 10.813 0 01-.358-.462zM5.8 15.2a.252.252 0 01-.246.3H2.813a.252.252 0 01-.236-.166 9.944 9.944 0 01-.17-6.154.249.249 0 01.239-.18h2.807a.249.249 0 01.247.288A17.891 17.891 0 005.5 12a17.279 17.279 0 00.3 3.2zM8.723 7a.25.25 0 01-.231-.345 10.053 10.053 0 013.364-4.386.249.249 0 01.29 0 10.1 10.1 0 013.36 4.385.25.25 0 01-.231.346zm11.687-.387a.252.252 0 01.009.256A.248.248 0 0120.2 7h-2.263a.25.25 0 01-.237-.171 13.157 13.157 0 00-1.743-3.483.25.25 0 01.312-.37 10.151 10.151 0 014.141 3.637zM7.733 2.969a.25.25 0 01.311.371A13.15 13.15 0 006.3 6.828.25.25 0 016.061 7H3.8a.249.249 0 01-.21-.385 10.049 10.049 0 014.143-3.646z\"/><path d=\"M23.937 16.648l-1.5-4a1 1 0 00-.645-.605.991.991 0 00-.873.143l-3.5 2.5a1 1 0 00.294 1.771l1.421.426a.252.252 0 01.158.338A7.685 7.685 0 0112 22a10 10 0 01-5-1.34 1 1 0 00-1 1.731A11.991 11.991 0 0012 24a9.743 9.743 0 009.234-6.248.25.25 0 01.3-.146A5.876 5.876 0 0023 18a1 1 0 00.937-1.351z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});