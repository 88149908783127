define("ember-svg-jar/inlined/body-care-mask", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>body-care-mask</title><path d=\"M16.985 20.681a.751.751 0 011.03-1.092l1 .947a.5.5 0 00.71-.025 12.366 12.366 0 003.143-6.691.5.5 0 00-.5-.57H20.5a.75.75 0 010-1.5h1.969a.5.5 0 00.5-.53 12.531 12.531 0 00-2.685-7.1.5.5 0 00-.757-.028l-.977 1.05a.75.75 0 01-1.1-1.022l1.035-1.112a.5.5 0 00-.056-.734 10.214 10.214 0 00-12.86 0 .5.5 0 00-.056.734l1.036 1.116a.75.75 0 01-1.1 1.022L4.474 4.1a.5.5 0 00-.757.028 12.531 12.531 0 00-2.685 7.1.5.5 0 00.5.53H3.5a.75.75 0 010 1.5H1.623a.5.5 0 00-.5.57 12.366 12.366 0 003.143 6.691.5.5 0 00.71.025l1-.947a.751.751 0 011.03 1.092l-.806.761a.5.5 0 00.064.779 10.178 10.178 0 0011.474 0 .5.5 0 00.064-.779zM5.5 9.135c0-.856 1.074-1.5 2.5-1.5s2.5.644 2.5 1.5-1.075 1.5-2.5 1.5-2.5-.645-2.5-1.5zM12 19c-2 0-3.5-.859-3.5-2s1.5-2 3.5-2 3.5.859 3.5 2-1.508 2-3.5 2zm1.5-9.865c0-.856 1.074-1.5 2.5-1.5s2.5.644 2.5 1.5-1.075 1.5-2.5 1.5-2.5-.645-2.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});