define("ember-svg-jar/inlined/bin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bin</title><path d=\"M7 12.626h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H7a.875.875 0 000-1.75zM7 9.626h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H7a.875.875 0 000-1.75z\"/><path d=\"M22.478 4.5H1.522A1.524 1.524 0 000 6.023v11.955A1.524 1.524 0 001.522 19.5h20.956A1.524 1.524 0 0024 17.978V6.023A1.524 1.524 0 0022.478 4.5zM8.649 12.162a2.13 2.13 0 01.412 1.867 2.174 2.174 0 01-2.137 1.6H5.5A.625.625 0 014.875 15V9a.625.625 0 01.625-.624h1.424a2.174 2.174 0 012.137 1.6 2.128 2.128 0 01-.412 1.866.25.25 0 000 .32zm3.726 2.214h.125a.625.625 0 010 1.25h-2a.625.625 0 010-1.25h.125a.25.25 0 00.25-.25v-4.25a.25.25 0 00-.25-.25H10.5a.625.625 0 010-1.25h2a.625.625 0 010 1.25h-.125a.25.25 0 00-.25.25v4.25a.25.25 0 00.25.25zm5.75.625a.625.625 0 01-.481.608.644.644 0 01-.144.017.627.627 0 01-.559-.346s-1.631-3.245-1.667-3.334-.149-.067-.149 0V15a.625.625 0 01-1.25 0V9a.625.625 0 011.184-.279s1.583 3.187 1.645 3.29.171.094.171-.007V9a.625.625 0 111.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});