define("ember-svg-jar/inlined/rhino", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rhino</title><path d=\"M20.8 13.938c2.179-2.393 3.2-5.583 3.2-9.985a1.452 1.452 0 00-2.719-.708c-1.749 3.125-3.623 4.814-6.422 5.765l1-4.395a1.439 1.439 0 00-2.268-1.409L9.883 5.993c-.289-.19.091.034-2.5-1.451a5.962 5.962 0 00.375-2.481 1.134 1.134 0 00-1.787-.876l-3.122 2.22C2 3.343 1.375 3.343 1.375 3.343H.5a.5.5 0 00-.5.5v17.5a.5.5 0 00.459.5.492.492 0 00.534-.416c.222-1.334 1.428-2.388 3.564-3.075a34.969 34.969 0 012.972 2.555 4.493 4.493 0 003.071 1.205h1.67a.5.5 0 00.317-.887l-.671-.549a.75.75 0 11.95-1.16l4.147 3.394a.5.5 0 00.51.074 6.746 6.746 0 003.084-2.546 6.139 6.139 0 00.89-4.649 6.241 6.241 0 00-.697-1.851zm-9.938-1.676a1.249 1.249 0 11-.358-1.731 1.25 1.25 0 01.353 1.731zm.8-5.1l1.892-1.423-.528 2.318zm5.008 3.286a12.425 12.425 0 005.239-4.418 11 11 0 01-2.4 6.348 26.526 26.526 0 00-2.84-1.932z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});