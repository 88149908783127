define("ember-svg-jar/inlined/official-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>official-building</title><path d=\"M13.4 1.9a.5.5 0 01-.4-.49V1a1 1 0 00-2 0v.41a.5.5 0 01-.4.489 7.024 7.024 0 00-4.728 3.48.25.25 0 00.219.371h11.818a.25.25 0 00.219-.371A7.024 7.024 0 0013.4 1.9zM5.679 6.75a.5.5 0 00-.487.383A6.949 6.949 0 005 8.75v3.312c0 .138.1.2.218.128l5.563-3.121a2.508 2.508 0 012.439 0l5.562 3.121c.12.067.218.01.218-.128V8.75a6.949 6.949 0 00-.192-1.617.5.5 0 00-.487-.383z\"/><path d=\"M18.744 13.315L12.73 9.941a1.477 1.477 0 00-1.459 0l-6.015 3.374a.5.5 0 00.244.936.5.5 0 01.5.5V21.9a.5.5 0 01-.168.374A.963.963 0 005.5 23a1 1 0 001 1h11a1 1 0 001-1 .963.963 0 00-.332-.724A.5.5 0 0118 21.9v-7.149a.5.5 0 01.5-.5.5.5 0 00.244-.936zm-8.244.936a.5.5 0 01.5.5V21.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-6.749a.5.5 0 01.5-.5zM16 21.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-6.749a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zM3.5 13.25h-2a1.5 1.5 0 00-1.5 1.5V21a1 1 0 001 1h2.5a.5.5 0 00.5-.5v-7.75a.5.5 0 00-.5-.5zM22.5 13.25h-2a.5.5 0 00-.5.5v7.75a.5.5 0 00.5.5H23a1 1 0 001-1v-6.25a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});