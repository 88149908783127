define("ember-svg-jar/inlined/weather-cloud-wind-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-cloud-wind-3</title><path d=\"M1.809 13.5A.248.248 0 002 13.412a.245.245 0 00.057-.2A4.5 4.5 0 016.581 8a1 1 0 00.986-.834 5 5 0 019.647-.831 1 1 0 00.9.667 2.5 2.5 0 012.232 3.365 1 1 0 00.366 1.169 3 3 0 01.816 4.077.253.253 0 00-.039.158c.007.075.011.151.011.228s0 .153-.01.227a.249.249 0 00.249.273H23.162a.248.248 0 00.236-.13 4.993 4.993 0 00-.885-5.932.252.252 0 01-.071-.218A4.6 4.6 0 0022.5 9.5a4.491 4.491 0 00-3.517-4.393.248.248 0 01-.171-.136 7 7 0 00-12.988.916.251.251 0 01-.205.173A6.509 6.509 0 000 12.5a6.594 6.594 0 00.058.872.253.253 0 00.109.174.249.249 0 00.2.035A2.481 2.481 0 011 13.5z\"/><path d=\"M19 17a1 1 0 000-2H1a1 1 0 000 2zM23 18H4a1 1 0 000 2h19a1 1 0 000-2zM19 21H1a1 1 0 000 2h18a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});