define("ember-svg-jar/inlined/crafts-origami-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crafts-origami-1</title><path d=\"M23.753 13.658A1 1 0 0023 12h-2.53l.76-3.8a1 1 0 00-1.32-1.14L9 11.188l4.658-9.775A1 1 0 0012.75 0h-8.5a1 1 0 00-.691 1.723L5.865 3.93l-5.652 6.7a1 1 0 00-.14.993l3.25 8a1.047 1.047 0 00.286.393l4.5 3.75A1.006 1.006 0 008.75 24h5.75a1 1 0 00.752-.342zM18.47 11.8a.25.25 0 01-.245.2h-5.59L18.5 9.71a.25.25 0 01.336.282zM10.81 2a.25.25 0 01.228.353l-1.043 2.3a.25.25 0 01-.4.078l-2.4-2.3A.25.25 0 017.364 2zM7.174 5.489a.25.25 0 01.326-.048l1.3.849a.249.249 0 01.09.312l-4.345 9.585a.25.25 0 01-.46-.009L2.2 11.552a.248.248 0 01.041-.256zm-1.552 13.6a.251.251 0 01-.068-.3l1.469-3.241a.25.25 0 01.426-.049L12.11 21.6a.25.25 0 01-.2.4H9.2a.251.251 0 01-.16-.058zm9.111 2.113a.247.247 0 01-.2.089.25.25 0 01-.193-.1L9.126 14.4a.25.25 0 01.2-.4h10.933a.25.25 0 01.191.411z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});