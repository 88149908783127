define("ember-svg-jar/inlined/party-confetti-whistle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>party-confetti-whistle</title><path d=\"M9.542 17.051a.5.5 0 00-.8-.13l-.965.965a.5.5 0 00-.094.577L9.1 21.291a.5.5 0 00.8.13l.965-.965a.5.5 0 00.094-.577zM22.41 7.72A6.831 6.831 0 0016.557.05 5.66 5.66 0 0010.2 4.905a4.732 4.732 0 004.055 5.313A3.994 3.994 0 0018.726 6.8a3.383 3.383 0 00-2.9-3.8 1 1 0 00-.266 1.982 1.384 1.384 0 011.188 1.557 1.985 1.985 0 01-2.227 1.7 2.73 2.73 0 01-2.343-3.069 3.657 3.657 0 014.112-3.137 4.828 4.828 0 014.137 5.422c-.147 1.1-1.282 3.4-7.1 6.721a.5.5 0 01-.6-.08l-.228-.228a.5.5 0 00-.707 0l-1.19 1.19a.5.5 0 00-.094.577l1.415 2.828a.5.5 0 00.8.13l1.9-1.9a.5.5 0 00.146-.354.483.483 0 00-.033-.178.5.5 0 01.212-.613c4.68-2.761 7.126-5.331 7.462-7.828zM6.714 19.879a.5.5 0 00-.8-.13l-.836.837a2 2 0 102.828 2.828l.129-.129a.5.5 0 00.093-.577zM5.5 5.5A.5.5 0 016 6v1.5a1 1 0 002 0V5a1.5 1.5 0 00-1.5-1.5h-1A.5.5 0 015 3V2A1.5 1.5 0 003.5.5H1a1 1 0 000 2h1.5A.5.5 0 013 3v1a1.5 1.5 0 001.5 1.5z\"/><circle cx=\"5\" cy=\"12\" r=\"1.5\"/><path d=\"M23.57 17.5l-.331-.046a2.012 2.012 0 01-1.7-1.7l-.047-.331a.5.5 0 00-.99 0l-.047.332a2.012 2.012 0 01-1.7 1.7l-.332.046a.5.5 0 000 .991l.332.048a2.009 2.009 0 011.7 1.7l.047.331a.5.5 0 00.99 0l.047-.332a2.009 2.009 0 011.7-1.7l.331-.048a.5.5 0 000-.991z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});