define("ember-svg-jar/inlined/drone-height", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drone-height</title><path d=\"M3.25 12.75a.75.75 0 000-1.5h-2a.75.75 0 000 1.5h.07a.181.181 0 01.18.18v9.14a.181.181 0 01-.18.18h-.07a.75.75 0 000 1.5h2a.75.75 0 000-1.5h-.07a.181.181 0 01-.18-.18v-9.14a.181.181 0 01.18-.18zM7.25 16.5a.5.5 0 000-1h-2a.5.5 0 00-.5.5v1.5a1 1 0 001 1h.5a.5.5 0 010 1h-1a.5.5 0 000 1h1a1.5 1.5 0 000-3H6a.25.25 0 01-.25-.25v-.5A.25.25 0 016 16.5zM10.25 15.5a1.5 1.5 0 00-1.5 1.5v2a1.5 1.5 0 003 0v-2a1.5 1.5 0 00-1.5-1.5zm.5 3.5a.5.5 0 01-1 0v-2a.5.5 0 011 0zM22.75.25h-5a.75.75 0 000 1.5h5a.75.75 0 000-1.5zM9.75 1.75a.75.75 0 000-1.5h-5a.75.75 0 000 1.5zM21.75 4.5h-.5a.25.25 0 01-.25-.25V3a.75.75 0 00-1.5 0v1.25a.25.25 0 01-.25.25h-1a2.561 2.561 0 01-1.9-.845 3.5 3.5 0 00-5.2 0 2.565 2.565 0 01-1.9.845h-1A.25.25 0 018 4.25V3a.75.75 0 00-1.5 0v1.25a.25.25 0 01-.25.25h-.5a1.5 1.5 0 000 3h3.5a2.581 2.581 0 01.784.123.181.181 0 01.05.318A6.245 6.245 0 007.5 13 .75.75 0 009 13a4.754 4.754 0 012.5-4.182.18.18 0 01.192.013c.055.04.113.079.171.117a.178.178 0 01.079.117.176.176 0 01-.03.139 2.25 2.25 0 103.676 0 .176.176 0 01-.03-.139.178.178 0 01.079-.117c.058-.038.116-.077.171-.117A.18.18 0 0116 8.818 4.754 4.754 0 0118.5 13a.75.75 0 001.5 0 6.245 6.245 0 00-2.584-5.059.181.181 0 01.05-.318 2.581 2.581 0 01.784-.123h3.5a1.5 1.5 0 000-3zm-8 6.75a.75.75 0 11.75-.75.751.751 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});