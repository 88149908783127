define("ember-svg-jar/inlined/toilet-need", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toilet-need</title><circle cx=\"12.25\" cy=\"2.747\" r=\"2.5\"/><path d=\"M13.751 16.747a2.11 2.11 0 01-.771-.152.249.249 0 00-.336.18l-.367 1.711a1.257 1.257 0 00.105.82l1.75 3.75a1.25 1.25 0 002.236-1.117l-1.554-3.359.394-1.837a.25.25 0 00-.349-.279l-.166.076a2.222 2.222 0 01-.942.207zM7.9 14.657a9.949 9.949 0 001.826.973 1.294 1.294 0 001.658-.61 1.253 1.253 0 00-.611-1.659l-.926-.428a2.757 2.757 0 01-1.6-2.5V10a.5.5 0 011 0v.441a1.755 1.755 0 001.017 1.589l.927.427a2.158 2.158 0 01.628.445.251.251 0 00.356 0 2.176 2.176 0 01.628-.445l.926-.427a1.754 1.754 0 001.018-1.589V10a.5.5 0 011 0v.441a2.756 2.756 0 01-1.6 2.5l-.925.428a1.253 1.253 0 00-.611 1.659 1.292 1.292 0 001.658.61 7.129 7.129 0 002.375-1.438 5.316 5.316 0 001.606-3.759V10A3.254 3.254 0 0015 6.747H9A3.254 3.254 0 005.75 10v.441a5.33 5.33 0 002.15 4.216zM9.307 16.54l-.166-.076a.25.25 0 00-.349.279l.394 1.837-1.554 3.359a1.25 1.25 0 002.236 1.117l1.75-3.75a1.257 1.257 0 00.105-.82l-.367-1.711a.248.248 0 00-.126-.168.253.253 0 00-.21-.013 2.09 2.09 0 01-.771.153 2.222 2.222 0 01-.942-.207z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});