define("ember-svg-jar/inlined/night-pass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>night-pass</title><path d=\"M4.923 16.937a1 1 0 001.414-1.414A8 8 0 018.9 2.484a.251.251 0 01.236.024.247.247 0 01.11.211v.147a9.761 9.761 0 009.75 9.75h.143a.249.249 0 01.211.11.253.253 0 01.024.237 8.044 8.044 0 01-2.2 3 1 1 0 101.295 1.524 10.022 10.022 0 003.382-5.9 1 1 0 00-1.2-1.149 7.752 7.752 0 01-9.226-9.226 1 1 0 00-1.149-1.2 10 10 0 00-5.35 16.922z\"/><path d=\"M15.428 15.916a.746.746 0 00-.672-.416h-1.5a.25.25 0 01-.25-.25V13a1 1 0 00-2 0v2.25a.25.25 0 01-.25.25h-1.5a.75.75 0 00-.744.848.76.76 0 00.143.351l2.753 3.67a.75.75 0 001.2 0l2.75-3.667a.649.649 0 00.046-.07.748.748 0 00.024-.716zM22.994 22H1.006a1 1 0 000 2h21.988a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});