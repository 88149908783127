define("ember-svg-jar/inlined/table-restaurant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>table-restaurant</title><path d=\"M8.5 13.75a2 2 0 00-2-2H3.2a.249.249 0 01-.249-.237 21.244 21.244 0 00-.175-1.889L1.99 4.108a1 1 0 00-1.98.283L.8 9.906a21.268 21.268 0 01-.317 7.568l-.459 2.059a1 1 0 00.759 1.193A1.018 1.018 0 001 20.75a1 1 0 00.975-.783l.458-2.06a23.7 23.7 0 00.527-3.92.25.25 0 01.25-.237h3.04a.25.25 0 01.25.25v5.75a1 1 0 002 0z\"/><path d=\"M14.5 19.75a1 1 0 00-1-1 .5.5 0 01-.5-.5v-7.5a.5.5 0 01.5-.5H18a1 1 0 000-2H6a1 1 0 000 2h4.5a.5.5 0 01.5.5v7.5a.5.5 0 01-.5.5 1 1 0 000 2h3a1 1 0 001-1z\"/><path d=\"M23.519 17.474a21.254 21.254 0 01-.319-7.567l.788-5.515a1 1 0 10-1.98-.283l-.788 5.515a21.266 21.266 0 00-.175 1.89.248.248 0 01-.249.236H17.5a2 2 0 00-2 2v6a1 1 0 002 0V14a.25.25 0 01.25-.25h3.039a.249.249 0 01.249.237 23.83 23.83 0 00.528 3.921l.458 2.059a1 1 0 00.976.783 1.084 1.084 0 00.218-.023 1 1 0 00.759-1.193z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});