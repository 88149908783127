define("ember-svg-jar/inlined/reptile-rattlesnake", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>reptile-rattlesnake</title><path d=\"M12 7.11a2.487 2.487 0 002.24 1.39h1.05a1.356 1.356 0 011.042.338 1.882 1.882 0 01.3 1.42 4.353 4.353 0 01-.741 1.959.5.5 0 00.419.773h4.5a.5.5 0 00.481-.363C21.508 11.858 23 10.647 23 9a6 6 0 00-3.521-5.851A3 3 0 0016.5.5a3.2 3.2 0 00-2.967 3h-.913a1.5 1.5 0 00-1.346 2.177zm4.5-4.61a1 1 0 11-1 1 1 1 0 011-1zM7.5 9.5h-4a.5.5 0 00-.5.5 9.049 9.049 0 00.392 2.636.5.5 0 00.478.354h4.3a.5.5 0 00.434-.749A4.514 4.514 0 018 10a.5.5 0 00-.5-.5zM2.5 17.5h19a.5.5 0 00.5-.5 2.5 2.5 0 00-2.5-2.5h-15A2.5 2.5 0 002 17a.5.5 0 00.5.5zM21.5 18.994h-19A2.5 2.5 0 002.5 24h19a2.5 2.5 0 000-5.006zM3.75 8.507h3.5a1 1 0 000-2h-3.5a1 1 0 000 2zM4.75 5.5h1.5a1 1 0 000-2h-1.5a1 1 0 000 2zM5.5 2.75a1 1 0 001-1V1a1 1 0 00-2 0v.75a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});