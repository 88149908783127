define("ember-svg-jar/inlined/video-game-mario", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-mario</title><path d=\"M3 5.5a.5.5 0 01.5-.5h.25a.75.75 0 000-1.5H3.5A.5.5 0 013 3v-.25a.75.75 0 00-1.5 0V3a.5.5 0 01-.5.5H.75a.75.75 0 000 1.5H1a.5.5 0 01.5.5v.25a.75.75 0 001.5 0zM23.25 1.5H23a.5.5 0 01-.5-.5V.752a.75.75 0 10-1.5 0V1a.5.5 0 01-.5.5h-.25a.75.75 0 000 1.5h.25a.5.5 0 01.5.5v.25a.75.75 0 001.5 0V3.5A.5.5 0 0123 3h.25a.75.75 0 000-1.5zM20 22h-.5a.5.5 0 01-.5-.5v-2a.5.5 0 00-.5-.5h-13a.5.5 0 00-.5.5v2a.5.5 0 01-.5.5H4a1 1 0 000 2h16a1 1 0 000-2z\"/><rect x=\"5\" y=\"13.5\" width=\"14\" height=\"4.5\" rx=\".5\" ry=\".5\"/><path d=\"M20 8h-.9a.5.5 0 01-.5-.495 6.472 6.472 0 00-.331-1.979 6.4 6.4 0 00-.841-1.669 1 1 0 00-1.619-.016.25.25 0 01-.408-.017C14.039 1.73 11.228.005 7.364 0a1 1 0 00-.658 1.753A4.747 4.747 0 017.864 3.36a.251.251 0 01-.254.351c-.038 0-.078-.006-.118-.007a1 1 0 00-.824.475 6.4 6.4 0 00-.96 3.329.5.5 0 01-.5.5H4a1 1 0 000 2h.5a.5.5 0 01.5.5V12a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-1.5a.5.5 0 01.5-.5h.5a1 1 0 000-2zm-3.549-.149a.5.5 0 01-.357.149H8.207a.5.5 0 01-.5-.507 4.318 4.318 0 01.044-.568.25.25 0 01.4-.165c.161.118.329.23.5.334a1 1 0 001.5-.665 6.472 6.472 0 00-.391-3.724.25.25 0 01.3-.337c2.372.689 3.857 2.244 4.189 3.783a1 1 0 001.569.6c.1-.07.19-.143.281-.218a.25.25 0 01.4.143 4.509 4.509 0 01.101.824.5.5 0 01-.149.355z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});