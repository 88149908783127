define("ember-svg-jar/inlined/phone-action-add-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-add-user</title><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/><path d=\"M11.5 14.5A1.5 1.5 0 0113 13a1.5 1.5 0 011.5-1.5c.036 0 .07.008.106.011a3.982 3.982 0 00-1.974-1.328 2.25 2.25 0 10-2.264 0A3.993 3.993 0 007.5 14v1a.5.5 0 00.5.5h3.889a1.491 1.491 0 01-.389-1z\"/><path d=\"M16 13.75h-.75V13a.75.75 0 00-1.5 0v.75H13a.75.75 0 000 1.5h.75V16a.75.75 0 001.5 0v-.75H16a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});