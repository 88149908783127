define("ember-svg-jar/inlined/recycling-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>recycling-refresh</title><path d=\"M5.407 11.887a.75.75 0 00-.656-.387h-.436a.5.5 0 01-.5-.565 8.25 8.25 0 0114.656-4.053 1 1 0 001.568-1.241 10.25 10.25 0 00-18.245 5.4.5.5 0 01-.5.454H.75a.75.75 0 00-.636 1.147l2 3.2a.751.751 0 001.273 0l2-3.2a.75.75 0 00.02-.755zM23.886 11.353l-2-3.2a.781.781 0 00-1.273 0l-2 3.2a.75.75 0 00.636 1.147h.436a.5.5 0 01.5.565 8.25 8.25 0 01-14.656 4.053 1 1 0 00-1.568 1.241 10.25 10.25 0 0018.245-5.405.5.5 0 01.5-.454h.547a.75.75 0 00.636-1.147z\"/><path d=\"M13.374 11.117a6.905 6.905 0 00-2.062 3.153.5.5 0 00.272.636 2.116 2.116 0 00.756.17 3.237 3.237 0 002.575-1.576 7.3 7.3 0 00-.1-6.786.5.5 0 00-.776-.071A6.537 6.537 0 0111.7 8.117a6.944 6.944 0 00-2.1 1.23 2.739 2.739 0 00-1.01 2.373A2.176 2.176 0 009.309 13a.5.5 0 01.129.545 17.245 17.245 0 00-.879 3.328.5.5 0 00.991.139c.334-2.2 1.421-5.418 3.243-6.708a.5.5 0 01.581.814z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});