define("ember-svg-jar/inlined/road-sign-warning-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-warning-alternate</title><path d=\"M23.881 21.773l-11-20.533a1.042 1.042 0 00-1.762 0l-11 20.533A1 1 0 001 23.246h22a1 1 0 00.881-1.473zm-2.755-.648a.248.248 0 01-.214.121H3.088a.25.25 0 01-.22-.368L11.78 4.241a.249.249 0 01.44 0l8.912 16.637a.248.248 0 01-.006.247z\"/><path d=\"M12.25 15.713a1 1 0 001-1V9.454a1 1 0 00-2 0v5.259a1 1 0 001 1z\"/><circle cx=\"12.25\" cy=\"17.963\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});