define("ember-svg-jar/inlined/road-sign-split-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-split-1</title><path d=\"M23.78 11.47L12.53.22a.749.749 0 00-1.06 0L.22 11.47a.749.749 0 000 1.06l11.25 11.25a.748.748 0 001.06 0l11.25-11.25a.749.749 0 000-1.06zm-8.25-1.94l-2.707 2.707a.251.251 0 00-.073.177V17a.75.75 0 01-1.5 0v-4.586a.251.251 0 00-.073-.177L8.47 9.53a.75.75 0 011.06-1.06l2.293 2.293a.252.252 0 00.354 0L14.47 8.47a.75.75 0 011.06 1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});