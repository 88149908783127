define("ember-svg-jar/inlined/book-book-pages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-book-pages</title><path d=\"M18.875 0H4.4a1.532 1.532 0 00-1.275 1.572v17.476a1.743 1.743 0 001.1 1.625l8 3.2a1.75 1.75 0 002.4-1.625v-17a1.743 1.743 0 00-1.1-1.625l-2.86-1.143a.25.25 0 01.092-.48h7.868a.25.25 0 01.25.25v16.475a.25.25 0 01-.25.25h-1.75a1 1 0 000 2h2a2 2 0 002-2V2a2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});