define("ember-svg-jar/inlined/single-woman-id-card.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-id-card</title><path d=\"M10 6.5h4a1 1 0 001-1V3A3.01 3.01 0 0011.191.107 3.047 3.047 0 009 3.088V5.5a1 1 0 001 1zm1.25-3.75a.75.75 0 11.75.75.75.75 0 01-.75-.75z\"/><path d=\"M21.5 5a2 2 0 00-2-2h-2.75a.25.25 0 00-.25.25v1.5a.251.251 0 00.25.25H19a.5.5 0 01.5.5v16a.5.5 0 01-.5.5H5a.5.5 0 01-.5-.5v-16A.5.5 0 015 5h2.25a.251.251 0 00.25-.25v-1.5A.25.25 0 007.25 3H4.5a2 2 0 00-2 2v17a2 2 0 002 2h15a2 2 0 002-2z\"/><path d=\"M10.68 14.668a.249.249 0 01.252.25v.228a.521.521 0 01-.341.49c-1.934.717-3.475 1.228-3.843 1.966a5.29 5.29 0 00-.507 1.836.261.261 0 00.261.285h11.476a.257.257 0 00.193-.085.261.261 0 00.068-.2 5.335 5.335 0 00-.507-1.836c-.369-.738-1.909-1.249-3.843-1.966a.522.522 0 01-.342-.49V14.9a.251.251 0 01.246-.25 4.12 4.12 0 002.445-.593.262.262 0 00.056-.4c-.33-.362-.557-.8-.654-2.231a3.4 3.4 0 10-6.8 0c-.1 1.434-.325 1.869-.654 2.231a.267.267 0 00-.066.213.258.258 0 00.122.185 4.142 4.142 0 002.438.613z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});