define("ember-svg-jar/inlined/credit-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card</title><path d=\"M21 0H6a3 3 0 00-3 3v4.5a1 1 0 001 1 1 1 0 001-1v-.25A.25.25 0 015.25 7h16.5a.25.25 0 01.25.25V12a1 1 0 01-.535.885.939.939 0 00-.465.842 1 1 0 001.451.9A3 3 0 0024 12V3a3 3 0 00-3-3zM5.25 4A.25.25 0 015 3.75V3a1 1 0 011-1h15a1 1 0 011 1v.75a.25.25 0 01-.25.25z\"/><path d=\"M17 10H2a2 2 0 00-2 2v10a2 2 0 002 2h15a2 2 0 002-2V12a2 2 0 00-2-2zM6 15.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm8.5 5.5a1.992 1.992 0 01-1.333-.51.251.251 0 00-.334 0 2 2 0 110-2.98.251.251 0 00.334 0A2 2 0 1114.5 21z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});