define("ember-svg-jar/inlined/diagram-arrow-dash-corner-left-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-arrow-dash-corner-left-up</title><path d=\"M8.83 13.4a1.076 1.076 0 001.523 0l3.047-3.045a1.077 1.077 0 000-1.524L9.628 5.059a.269.269 0 010-.381L12.1 2.2a1.077 1.077 0 00-.718-1.835L1.636 0A1.51 1.51 0 00.46.464 1.585 1.585 0 000 1.639l.361 9.744a1.078 1.078 0 001.839.722L4.677 9.63a.27.27 0 01.381 0zM16.066 11.5a1.077 1.077 0 00-1.524 0L11.5 14.544a1.076 1.076 0 000 1.523l.761.762a1.079 1.079 0 001.524 0l3.047-3.047a1.078 1.078 0 000-1.523zM20.255 15.686l-.761-.762a1.112 1.112 0 00-1.524 0l-3.046 3.048a1.076 1.076 0 000 1.523l.761.762a1.077 1.077 0 001.524 0l3.046-3.048a1.076 1.076 0 000-1.523zM24 19.876a1.076 1.076 0 00-.316-.761l-.761-.762a1.077 1.077 0 00-1.524 0L18.351 21.4a1.079 1.079 0 000 1.524l.762.761a1.076 1.076 0 001.523 0l3.047-3.046a1.078 1.078 0 00.317-.763z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});