define("ember-svg-jar/inlined/army-symbol-brigade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-symbol-brigade</title><path d=\"M22 7.5H2a2 2 0 00-2 2V22a2 2 0 002 2h20a2 2 0 002-2V9.5a2 2 0 00-2-2zm0 14a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5V10a.5.5 0 01.5-.5h19a.5.5 0 01.5.5zM8.793 4.293a1 1 0 101.414 1.414l1.116-1.116a.25.25 0 01.354 0l1.116 1.116a1 1 0 001.414-1.414l-1.116-1.116a.25.25 0 010-.354l1.116-1.116A1 1 0 0012.793.293l-1.116 1.116a.25.25 0 01-.354 0L10.207.293a1 1 0 00-1.414 1.414l1.116 1.116a.25.25 0 010 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});