define("ember-svg-jar/inlined/road-sign-split-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-split-1-alternate</title><path d=\"M14.293 8.446l-2.116 2.116a.25.25 0 01-.354 0L9.707 8.446A1 1 0 108.293 9.86l2.634 2.634a.251.251 0 01.073.177v4.482a1 1 0 002 0v-4.482a.251.251 0 01.073-.177l2.634-2.634a1 1 0 10-1.414-1.414z\"/><path d=\"M23.707 12.707a1 1 0 000-1.414l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0zm-2.3-.884a.25.25 0 010 .354l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});