define("ember-svg-jar/inlined/ticket-two", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ticket-two</title><path d=\"M14.5 7v10.75a.25.25 0 00.25.25h6.75a1 1 0 001-1V1a1 1 0 00-1-1h-10a1 1 0 00-1 1v3.75a.25.25 0 00.25.25h1.75a2 2 0 012 2zm3.75 7a.75.75 0 11.75-.75.75.75 0 01-.75.75zm2.5-1.5a.75.75 0 11-.75.75.75.75 0 01.75-.75zm-3.3-7.9a.75.75 0 01-.9-1.2l1.2-.9a1.25 1.25 0 012 1 .75.75 0 01-.012.134l-.821 4.517a.087.087 0 00.018.069.085.085 0 00.065.03.75.75 0 010 1.5h-2a.75.75 0 010-1.5h.165a.25.25 0 00.246-.205l.72-3.955zm-1.7 7.9a.75.75 0 11-.75.75.75.75 0 01.75-.75z\"/><path d=\"M2.5 24h10a1 1 0 001-1V7a1 1 0 00-1-1h-10a1 1 0 00-1 1v16a1 1 0 001 1zm6.25-4a.75.75 0 11.75-.75.75.75 0 01-.75.75zm3.25-.75a.75.75 0 11-.75-.75.75.75 0 01.75.75zm-6.03-4.78l3.613-3.614a.572.572 0 00.167-.4.7.7 0 00-1.369-.224.75.75 0 11-1.424-.473 2.2 2.2 0 014.293.7 2.087 2.087 0 01-.606 1.464l-1.907 1.9a.25.25 0 00.177.427H9.5a.75.75 0 010 1.5h-3a.75.75 0 01-.53-1.28zm.28 4.03a.75.75 0 11-.75.75.75.75 0 01.75-.75zm-2.5 0a.75.75 0 11-.75.75.75.75 0 01.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});