define("ember-svg-jar/inlined/align-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>align-right</title><path d=\"M21.25 1a1 1 0 00-1 1v20a1 1 0 002 0V2a1 1 0 00-1-1z\"/><rect x=\"7.75\" y=\"5\" width=\"11\" height=\"6\" rx=\"1.5\" ry=\"1.5\"/><rect x=\"1.75\" y=\"13\" width=\"17\" height=\"6\" rx=\"1.5\" ry=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});