define("ember-svg-jar/inlined/pencil-write-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pencil-write-2-alternate</title><path d=\"M20 11.5a1 1 0 00-1 1V21a1 1 0 01-1 1H3a1 1 0 01-1-1V4a1 1 0 011-1h10a1 1 0 000-2H3a3 3 0 00-3 3v17a3 3 0 003 3h15a3 3 0 003-3v-8.5a1 1 0 00-1-1z\"/><path d=\"M23.415.582a2.018 2.018 0 00-2.828 0L10.3 10.871a.5.5 0 00-.111.168l-1.417 3.535a.5.5 0 00.111.539.509.509 0 00.539.112l3.536-1.415a.513.513 0 00.168-.111L23.415 3.41a2 2 0 000-2.828z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});