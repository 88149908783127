define("ember-svg-jar/inlined/real-estate-search-house-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-search-house-2</title><path d=\"M14.608 16.3a8.966 8.966 0 01-2.843-1.157.5.5 0 00-.765.425V23.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-6.709a.5.5 0 00-.392-.491zM7.5 7.5A.5.5 0 007 7a.5.5 0 01-.5-.5V4.75a.75.75 0 00-1.5 0v1.813a.5.5 0 01-.375.484A1.5 1.5 0 003.5 8.5v5.123a.5.5 0 01-.123.328l-.187.214A.251.251 0 012.75 14a.75.75 0 00-1.5 0v2.192a.5.5 0 01-.124.329L.12 17.67A.5.5 0 000 18v5.5a.5.5 0 00.5.5H9a.5.5 0 00.5-.5V13.314a.5.5 0 00-.106-.307A8.937 8.937 0 017.5 7.5zm-1 14h-1a.75.75 0 010-1.5h1a.75.75 0 010 1.5zm0-3.25h-1a.75.75 0 010-1.5h1a.75.75 0 010 1.5zm0-3.5h-1a.75.75 0 010-1.5h1a.75.75 0 010 1.5zM16.5 0A7.5 7.5 0 1024 7.5 7.508 7.508 0 0016.5 0zM21 7.5h-1a.5.5 0 00-.5.5v3a.5.5 0 01-.5.5h-1.25a.5.5 0 01-.5-.5V9a.75.75 0 00-1.5 0v2a.5.5 0 01-.5.5H14a.5.5 0 01-.5-.5V8a.5.5 0 00-.5-.5h-1a.5.5 0 01-.314-.889l4.157-3.365a1 1 0 011.34.023l4.131 3.342A.5.5 0 0121 7.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});