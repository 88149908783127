define("ember-svg-jar/inlined/hat-feather", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hat-feather</title><path d=\"M5.708 15.751h8.064a.5.5 0 00.5-.466 8.935 8.935 0 013.753-6.813.5.5 0 00.2-.441l-.15-1.906A1.507 1.507 0 0016.58 4.75H7.42a1.509 1.509 0 00-1.495 1.378l-.716 9.084a.5.5 0 00.5.539zM15.77 15.2a.5.5 0 00.531.536 6.872 6.872 0 004.866-2.378 7.31 7.31 0 00.653-.746.5.5 0 00-.045-.655l-.688-.688a.25.25 0 01.177-.427h1.191a.5.5 0 00.474-.342 7.331 7.331 0 00.35-1.7.535.535 0 00-.577-.578 7.309 7.309 0 00-4.557 2.113A7.437 7.437 0 0015.77 15.2zM23 17.25H1a1 1 0 000 2h22a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});