define("ember-svg-jar/inlined/astrology-stars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astrology-stars</title><path d=\"M18.709 18.027a.5.5 0 00.411-.2A7.993 7.993 0 0012.75 5c-.2 0-.4.01-.6.027a.5.5 0 00-.446.394A2 2 0 019.75 7c-1.914-.142-2.421.964-2.5 2.6a2 2 0 01-1.937 1.9.5.5 0 00-.487.431 7.946 7.946 0 001.555 5.9.5.5 0 00.412.2c1.624-.05 10.3-.046 11.916-.004zm-2.493-7.9A.5.5 0 1117 9.5a8.006 8.006 0 011.6 3.429.5.5 0 11-.98.2 7.014 7.014 0 00-1.404-3.002zM23.261 2.5c-1.4-.029-2.016-.646-2.011-2a.5.5 0 00-.5-.5.5.5 0 00-.5.5c0 1.364-.624 1.981-2.007 2a.5.5 0 00.007 1c1.43-.028 1.988.6 2 2.005a.5.5 0 00.49.5h.01a.5.5 0 00.5-.475c.07-1.4.682-2.025 1.984-2.025h.017a.5.5 0 00.011-1z\"/><path d=\"M5.749 9.525C5.887 6.779 7.148 5.5 9.719 5.5h.031a.5.5 0 00.011-1C7.014 4.441 5.74 3.172 5.75.5a.5.5 0 00-.5-.5.5.5 0 00-.5.5c-.01 2.691-1.283 3.962-4.007 4a.5.5 0 00.007 1h.011c2.7 0 3.965 1.272 3.989 4a.5.5 0 00.49.5h.01a.5.5 0 00.499-.475zM21.75 21h-1.669a.081.081 0 01-.081-.081 1.42 1.42 0 00-1.418-1.419H6.918A1.42 1.42 0 005.5 20.919a.081.081 0 01-.081.081H3.75a1.5 1.5 0 000 3h18a1.5 1.5 0 000-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});