define("ember-svg-jar/inlined/ab-testing-chemistry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ab-testing-chemistry</title><path d=\"M19.473 12.791V21.2a3.162 3.162 0 00.982 2.266 1.8 1.8 0 001.263.534h.009a1.7 1.7 0 001.211-.5 3.242 3.242 0 001.019-2.324v-8.385a.747.747 0 00-.747-.748h-2.989a.748.748 0 00-.748.748zm1.5 1a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v3.236a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25zM8.914 22.16A1.245 1.245 0 0010.007 24h6.975a1.246 1.246 0 001.1-1.829l-2.318-4.54a.244.244 0 01-.027-.113v-3.233a1.247 1.247 0 00-1.247-1.245H12.5a1.246 1.246 0 00-1.245 1.245v3.223a.253.253 0 01-.028.114l-2.31 4.531zm3.332-3.26l.309-.608.01-.017a1.24 1.24 0 00.181-.648v-2.843a.25.25 0 01.25-.25h.995a.25.25 0 01.25.25v2.847a1.186 1.186 0 00.15.593l.011.019.337.661a.247.247 0 01-.01.244.25.25 0 01-.213.12h-2.047a.253.253 0 01-.214-.12.25.25 0 01-.009-.248z\"/><path d=\"M.043 14.534a2.993 2.993 0 002.989 2.99h5.979a1 1 0 000-1.993H3.032a1 1 0 01-1-1V5.318a.25.25 0 01.25-.25h17.439a.249.249 0 01.25.25v4.234a1 1 0 101.993 0V2.99A2.992 2.992 0 0018.975 0H3.032A2.993 2.993 0 00.043 2.99zM8.762 1.993a.748.748 0 11-.748.748.747.747 0 01.748-.748zm-2.491 0a.748.748 0 11-.748.748.747.747 0 01.748-.748zm-2.491 0a.748.748 0 11-.748.748.747.747 0 01.748-.748z\"/><path d=\"M11 11.212a.5.5 0 00-.429-.493l-.263-.037a1.5 1.5 0 01-1.264-1.269l-.038-.264a.5.5 0 00-.987 0l-.038.265a1.5 1.5 0 01-1.267 1.268l-.263.037a.5.5 0 000 .987l.264.038a1.5 1.5 0 011.268 1.269l.038.264a.5.5 0 00.987 0l.038-.265a1.5 1.5 0 011.268-1.268l.263-.038a.5.5 0 00.423-.494zM18.643 8.889a.5.5 0 00-.427-.494l-.266-.038a1.5 1.5 0 01-1.267-1.266l-.036-.264a.5.5 0 00-.493-.429.5.5 0 00-.493.427l-.038.265a1.5 1.5 0 01-1.269 1.268l-.264.038a.5.5 0 000 .986l.263.038a1.5 1.5 0 011.269 1.269l.038.264a.5.5 0 00.493.427.5.5 0 00.493-.429l.036-.262a1.5 1.5 0 011.268-1.27l.265-.038a.5.5 0 00.428-.492z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});