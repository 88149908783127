define("ember-svg-jar/inlined/multiple-actions-heart.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-heart</title><path d=\"M13.387 11.134c.11-.027.22-.05.333-.069a5.04 5.04 0 013.511.727.5.5 0 00.536 0 4.925 4.925 0 011.3-.57.5.5 0 00.267-.779 6 6 0 00-7.311-1.911.5.5 0 00-.182.771 7.511 7.511 0 01.973 1.555.5.5 0 00.573.276z\"/><ellipse cx=\"14.013\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M11.347 12.141a.5.5 0 00.14-.593A6.029 6.029 0 000 13.333.6.6 0 00.6 14h9.017a.5.5 0 00.448-.279 4.811 4.811 0 011.282-1.58zM7.123 6.594A3.448 3.448 0 105.513 7a3.485 3.485 0 001.61-.406zM23.627 14.449a3.526 3.526 0 00-5.646-.916l-.305.3a.249.249 0 01-.353 0l-.3-.3a3.525 3.525 0 00-4.993 4.978l5.114 5.335a.5.5 0 00.721 0l5.107-5.327a3.524 3.524 0 00.655-4.07z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});