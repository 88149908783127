define("ember-svg-jar/inlined/airport", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>airport</title><path d=\"M15.992 13.912a.5.5 0 00-.492-.412h-7a.5.5 0 00-.492.412l-1.463 8.224A1.5 1.5 0 008 24h8a1.49 1.49 0 001.182-.577 1.466 1.466 0 00.28-1.253zM20.371 4.6a1.51 1.51 0 00-1.2-.6h-.421a.5.5 0 01-.5-.5V2a1 1 0 00-2 0v1.5a.5.5 0 01-.5.5H13.5a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v2.5a.5.5 0 01-.5.5H8.25a.5.5 0 01-.5-.5V2a1 1 0 00-2 0v1.5a.5.5 0 01-.5.5h-.424a1.5 1.5 0 00-1.442 1.912l1.428 5A1.5 1.5 0 006.254 12h11.492a1.505 1.505 0 001.442-1.088l1.428-5a1.51 1.51 0 00-.245-1.312zM8.5 9a.5.5 0 01-.5.5H6.5a.5.5 0 01-.485-.379l-.5-2A.5.5 0 016 6.5h2a.5.5 0 01.5.5zM14 9a.5.5 0 01-.5.5h-3A.5.5 0 0110 9V7a.5.5 0 01.5-.5h3a.5.5 0 01.5.5zm4.485-1.879l-.5 2a.5.5 0 01-.485.379H16a.5.5 0 01-.5-.5V7a.5.5 0 01.5-.5h2a.5.5 0 01.485.621z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});