define("ember-svg-jar/inlined/landmark-netherlands-windmill-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-netherlands-windmill-1</title><path d=\"M16.388 20.041a.256.256 0 00-.094-.144l-5.742-4.3a.25.25 0 00-.374.031L7.887 18.7a.252.252 0 00-.042.091l-.93 3.855C6.8 23.3 7.188 24 7.773 24h8.355c.586 0 .973-.7.858-1.352z\"/><path d=\"M15 10a.211.211 0 01.337-.169l.663.5a.5.5 0 00.7-.1L21.64 3.6a1 1 0 00-.2-1.4l-2-1.494a1 1 0 00-1.4.2L13.4 7.132a.251.251 0 01-.294.083A2.917 2.917 0 0012 7a.191.191 0 01-.153-.3l.64-.859a.5.5 0 00-.1-.7L5.762.2a1 1 0 00-1.4.2l-1.493 2a1 1 0 00.2 1.4l6.164 4.6a.251.251 0 01.075.311A2.922 2.922 0 009 10a.049.049 0 01-.078.039L8 9.349a.5.5 0 00-.7.1l-4.939 6.628a1 1 0 00.2 1.4l2.005 1.5a1 1 0 001.4-.205l4.461-5.984a.249.249 0 01.308-.076A2.932 2.932 0 0012 13a.068.068 0 01.054.109l-.544.73a.5.5 0 00.1.7l6.625 4.938a1 1 0 001.4-.2l1.5-2.005a1 1 0 00-.2-1.4l-6.05-4.509a.251.251 0 01-.083-.292A2.922 2.922 0 0015 10zm-3 1a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});