define("ember-svg-jar/inlined/skiing-cable-car-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skiing-cable-car-1</title><path d=\"M7.1 5.428a2.5 2.5 0 001.835-3.022A2.5 2.5 0 107.1 5.428zM21.72 16.586a1 1 0 00-1.944.475 1.5 1.5 0 01-1.1 1.813L5.789 22.028a1 1 0 00.474 1.944l12.888-3.155a3.5 3.5 0 002.569-4.231zM13.258 9.75a1 1 0 001-1V1a1 1 0 00-2 0v7.75a1 1 0 001 1z\"/><path d=\"M6.373 18.528a7.108 7.108 0 004.294 1.387c.156 0 1.419.082 1.36-1.062a1.007 1.007 0 00-1.052-.947 5.169 5.169 0 01-3.4-.973A5.451 5.451 0 015.6 14.061l-1.457-5.1a1 1 0 00-1.923.549l1.457 5.1a7.524 7.524 0 002.696 3.918z\"/><path d=\"M16.668 15.839a3.5 3.5 0 00-4.414-3.139l-1.235.337a.5.5 0 01-.612-.345L8.95 7.588a1.5 1.5 0 00-2.885.824l1.457 5.1a3.515 3.515 0 004.286 2.416l1.236-.337a.505.505 0 01.422.076.5.5 0 01.208.372l.178 2.684a.251.251 0 00.1.185.254.254 0 00.207.041l2.466-.6a.25.25 0 00.19-.259z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});