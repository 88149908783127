define("ember-svg-jar/inlined/keyboard-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-option</title><path d=\"M23.414 4.553a3.982 3.982 0 00-3.97-3.97H4.556a3.982 3.982 0 00-3.97 3.97v14.888a3.982 3.982 0 003.97 3.971h14.888a3.982 3.982 0 003.97-3.971zm-4 12.945h-3.5a2 2 0 01-1.415-.586l-6.12-6.121a1 1 0 00-.707-.293H4.414a1 1 0 110-2h3.672a2 2 0 011.414.585l6.121 6.122a1 1 0 00.707.293h3.086a1 1 0 010 2zm0-7H14.5a1 1 0 010-2h4.914a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});