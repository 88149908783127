define("ember-svg-jar/inlined/single-man-actions-mobilephone.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-mobilephone</title><path d=\"M7.087 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.743a.5.5 0 01.742-.3A6.956 6.956 0 008.547 5a6.35 6.35 0 00.929-.07.5.5 0 01.569.564A3 3 0 014.087 5a2.841 2.841 0 01.1-.743z\"/><path d=\"M13.413 13.84a.493.493 0 00-.1-.3 7.489 7.489 0 00-13.2 2.315.5.5 0 00.479.643h12.321a.5.5 0 00.5-.5zM22.439 11h-6.053a1.493 1.493 0 00-1.473 1.509v9.982A1.493 1.493 0 0016.386 24h6.053a1.494 1.494 0 001.474-1.509v-9.982A1.494 1.494 0 0022.439 11zm-5.526 10.5v-8a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v8a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});