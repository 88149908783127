define("ember-svg-jar/inlined/gesture-swipe-vertical-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-vertical-1</title><path d=\"M11 1.5a1.5 1.5 0 01.937.329L13.73 3.26a.25.25 0 00.344-.03.7.7 0 00.136-.23.747.747 0 00-.239-.833l-2.5-2a.748.748 0 00-.937 0l-2.5 2A.749.749 0 007.8 3a.7.7 0 00.136.232.249.249 0 00.344.031l1.79-1.432A1.5 1.5 0 0111 1.5zM11 22.5a1.5 1.5 0 01-.937-.328l-1.79-1.432a.25.25 0 00-.344.03.71.71 0 00-.129.23.75.75 0 00.239.834l2.5 2a.751.751 0 00.937 0l2.5-2A.749.749 0 0014.21 21a.71.71 0 00-.136-.232.25.25 0 00-.344-.031l-1.791 1.433a1.5 1.5 0 01-.939.33z\"/><path d=\"M13.5 18.248h-5a.75.75 0 00-.469 1.336l2.5 2a.751.751 0 00.937 0l2.5-2a.75.75 0 00-.468-1.336zM8.5 5.749h5a.75.75 0 00.468-1.335l-2.5-2a.748.748 0 00-.937 0l-2.5 2A.75.75 0 008.5 5.749zM14 12a3.5 3.5 0 00-3.5-3.5H.5A.5.5 0 000 9v6a.5.5 0 00.5.5h10A3.5 3.5 0 0014 12zm-5 1.123v-2.25a.375.375 0 01.375-.375H10.5a1.5 1.5 0 010 3H9.375A.374.374 0 019 13.123z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});