define("ember-svg-jar/inlined/single-man-actions-star.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-star</title><path d=\"M7 10a5 5 0 10-5-5 5.006 5.006 0 005 5zM4.1 4.257a.5.5 0 01.741-.3A6.959 6.959 0 008.46 5a6.224 6.224 0 00.929-.071.5.5 0 01.568.565A3 3 0 014 5a2.841 2.841 0 01.1-.743z\"/><path d=\"M9.705 15.19a2.741 2.741 0 012.554-1.69.421.421 0 00.3-.715A7.469 7.469 0 00.021 15.857.5.5 0 00.5 16.5h8.514a.5.5 0 00.5-.468 2.648 2.648 0 01.191-.842zM20.737 23.827a1.273 1.273 0 001.505-.166 1.256 1.256 0 00.316-1.45L21.334 19.4a.249.249 0 01.063-.286l2.213-1.972a1.261 1.261 0 00.3-1.377A1.232 1.232 0 0022.74 15h-2.5a.25.25 0 01-.23-.151l-1.344-3.116-.013-.028a1.293 1.293 0 00-2.317.028l-1.346 3.115a.249.249 0 01-.229.151h-2.5a1.229 1.229 0 00-1.166.76 1.272 1.272 0 00.319 1.4l2.194 1.955a.249.249 0 01.063.286l-1.225 2.81a1.255 1.255 0 00.315 1.451 1.286 1.286 0 001.506.166l3.112-1.752a.249.249 0 01.245 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});