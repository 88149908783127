define("@gavant/ember-shopify-draggable/templates/components/sortable-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "7xhAhZpK",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "@gavant/ember-shopify-draggable/templates/components/sortable-item.hbs",
    "isStrictMode": false
  });
});