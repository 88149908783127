define("ember-svg-jar/inlined/button-record-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-record-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm0 21.421A9.421 9.421 0 1121.421 12 9.432 9.432 0 0112 21.417z\"/><circle cx=\"12\" cy=\"11.996\" r=\"4.381\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});