define("ember-svg-jar/inlined/cross-over", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cross-over</title><path d=\"M2.875 22.255a1.246 1.246 0 00.884-.366L19.235 6.413a.252.252 0 01.178-.074.247.247 0 01.176.074l1.931 1.944a.5.5 0 00.855-.352v-6a.5.5 0 00-.5-.5h-5.958a.5.5 0 00-.355.852l1.914 1.928a.248.248 0 010 .352L1.991 20.121a1.25 1.25 0 00.884 2.134z\"/><path d=\"M22.375 21.993v-5.946a.5.5 0 00-.852-.356l-1.938 1.921a.249.249 0 01-.352 0l-3.512-3.466a1.25 1.25 0 00-1.756 1.78l3.489 3.442a.251.251 0 010 .356l-1.931 1.914a.5.5 0 00.352.855h6a.5.5 0 00.5-.5zM2 2.116a1.251 1.251 0 00-.01 1.768l5.965 6.031a1.25 1.25 0 101.777-1.758L3.764 2.126A1.25 1.25 0 002 2.116z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});