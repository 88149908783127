define("ember-svg-jar/inlined/gesture-tap-swipe-down-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-tap-swipe-down-1</title><path d=\"M17.32 20.5h-1.25a.25.25 0 01-.25-.25V15a1 1 0 00-2 0v5.25a.249.249 0 01-.25.25h-1.25a.75.75 0 00-.468 1.335l2.5 2a.75.75 0 00.937 0l2.5-2a.75.75 0 00-.469-1.335zM17.7.858a6.652 6.652 0 00-6.847.186.75.75 0 00.8 1.27A5.246 5.246 0 0119.68 6.75a5.246 5.246 0 01-8.018 4.443.75.75 0 00-.795 1.271 6.69 6.69 0 003.558 1.036 6.759 6.759 0 003.282-.862A6.65 6.65 0 0021.18 6.75 6.644 6.644 0 0017.7.858z\"/><path d=\"M15.84 6.509a1 1 0 00-1-1H7.776a.25.25 0 01-.142-.455C9.772 3.565 9.083 1.2 7.41 2.293L3.043 5.205a.5.5 0 00-.223.415v5.434a.5.5 0 00.411.492c1.1.2 3.683.664 4.165.775a1.282 1.282 0 001.52-.893l1.117-3.741a.249.249 0 01.239-.178h4.568a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});