define("ember-svg-jar/inlined/single-woman-increase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-increase</title><path d=\"M24 .5a.5.5 0 00-.5-.5H19a.5.5 0 00-.372.834l1.224 1.36a.249.249 0 010 .333L18.011 4.6a.25.25 0 01-.343.03l-1.387-1.11a1.25 1.25 0 00-1.722.153l-3.5 4a1.25 1.25 0 001.882 1.646L15.5 6.4a.25.25 0 01.344-.03l1.379 1.1a1.249 1.249 0 001.716-.146L21.5 4.44a.246.246 0 01.186-.084.251.251 0 01.187.082l1.256 1.4A.5.5 0 0024 5.5zM11.983 17.868l-1.8-6.285a4.248 4.248 0 00-1.239-2.125.5.5 0 00-.764.1l-1.755 2.76a.5.5 0 01-.846 0L3.839 9.54a.5.5 0 00-.357-.23.5.5 0 00-.406.128 4.219 4.219 0 00-1.254 2.129l-1.8 6.3A.5.5 0 00.5 18.5h2.323a.25.25 0 01.246.2L4 23.6a.5.5 0 00.489.4h3a.5.5 0 00.49-.4l.931-4.9a.249.249 0 01.245-.2H11.5a.5.5 0 00.483-.632zM5.979 8.531a4.25 4.25 0 10-4.25-4.25 4.255 4.255 0 004.25 4.25zm0-2a2.247 2.247 0 01-2.159-1.64.25.25 0 01.159-.3A4.67 4.67 0 005.8 3.447a.25.25 0 01.357 0 4.67 4.67 0 001.821 1.14.25.25 0 01.16.3 2.249 2.249 0 01-2.159 1.644z\"/><path d=\"M24 .5a.5.5 0 00-.5-.5H19a.5.5 0 00-.372.834l1.224 1.36a.249.249 0 010 .333L18.011 4.6a.25.25 0 01-.343.03l-1.387-1.11a1.25 1.25 0 00-1.722.153l-3.5 4a1.25 1.25 0 001.882 1.646L15.5 6.4a.25.25 0 01.344-.03l1.379 1.1a1.249 1.249 0 001.716-.146L21.5 4.44a.246.246 0 01.186-.084.251.251 0 01.187.082l1.256 1.4A.5.5 0 0024 5.5zM11.983 17.868l-1.8-6.285a4.248 4.248 0 00-1.239-2.125.5.5 0 00-.764.1l-1.755 2.76a.5.5 0 01-.846 0L3.839 9.54a.5.5 0 00-.357-.23.5.5 0 00-.406.128 4.219 4.219 0 00-1.254 2.129l-1.8 6.3A.5.5 0 00.5 18.5h2.323a.25.25 0 01.246.2L4 23.6a.5.5 0 00.489.4h3a.5.5 0 00.49-.4l.931-4.9a.249.249 0 01.245-.2H11.5a.5.5 0 00.483-.632zM5.979 8.531a4.25 4.25 0 10-4.25-4.25 4.255 4.255 0 004.25 4.25zm0-2a2.247 2.247 0 01-2.159-1.64.25.25 0 01.159-.3A4.67 4.67 0 005.8 3.447a.25.25 0 01.357 0 4.67 4.67 0 001.821 1.14.25.25 0 01.16.3 2.249 2.249 0 01-2.159 1.644z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});