define("ember-svg-jar/inlined/single-woman-actions-lock.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-lock</title><path d=\"M12.5 17a2.969 2.969 0 01.67-1.88.5.5 0 00.01-.617A7.478 7.478 0 00.021 16.86a.5.5 0 00.479.64H12a.5.5 0 00.5-.5zM22.5 14.25a3.5 3.5 0 00-7 0v1.018a.249.249 0 01-.214.247A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.286-1.485.249.249 0 01-.214-.247zm-2.5 6a1 1 0 11-1-1 1 1 0 011 1zm-2.25-4.75a.25.25 0 01-.25-.25v-1a1.5 1.5 0 013 0v1a.25.25 0 01-.25.25z\"/><path d=\"M1.567 10.442a1 1 0 001.383-.3 5.233 5.233 0 00.454-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .249.249 0 01.411.082 5.233 5.233 0 00.454.9 1 1 0 001.681-1.082 4.569 4.569 0 01-.642-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248a4.573 4.573 0 01-.643 2.512 1 1 0 00.298 1.382zM7.209 8.6a2.7 2.7 0 01-2.741-2.352.5.5 0 01.324-.577 5.711 5.711 0 002.245-1.439.25.25 0 01.361 0 5.7 5.7 0 002.23 1.437.5.5 0 01.323.576A2.708 2.708 0 017.209 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});