define("ember-svg-jar/inlined/medical-app-tablet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-app-tablet</title><path d=\"M7 12.732a.751.751 0 00.75.75h1.5a.752.752 0 00.75-.75v-1a.249.249 0 01.25-.25h1a.752.752 0 00.75-.75v-1.5a.752.752 0 00-.75-.75h-1a.249.249 0 01-.25-.25v-1a.752.752 0 00-.75-.75h-1.5a.751.751 0 00-.75.75v1a.25.25 0 01-.25.25h-1a.751.751 0 00-.75.75v1.5a.751.751 0 00.75.75h1a.25.25 0 01.25.25zM16.179 14.366a2.5 2.5 0 00-3.548 3.525l1.724 1.886v1.172c0 1.357 1.552 2.466 2.028 2.838a1 1 0 001.234-1.573c-.608-.48-1.234-1-1.262-1.265v-1.561a1 1 0 00-.262-.675l-1.985-2.172a.5.5 0 01.712-.708l2.015 1.867a1 1 0 001.359-1.469z\"/><path d=\"M17 3a3 3 0 00-3-3H3a3 3 0 00-3 3v18a3 3 0 003 3h10.344a.25.25 0 00.2-.395 4.542 4.542 0 01-.941-2.62v-.4a.249.249 0 00-.065-.168l-.767-.84a.255.255 0 00-.185-.081H2.5A.5.5 0 012 19V3.5a.5.5 0 01.5-.5h12a.5.5 0 01.5.5v8.277a.248.248 0 00.207.246 4.232 4.232 0 011.461.551.216.216 0 00.221 0 .443.443 0 01.486.033C18.4 13.385 20 14.6 20 15.387v4.559a1 1 0 00.147.522l2 3.054a1 1 0 001.706-1.044l-1.813-2.752a.25.25 0 01-.04-.137v-4.2c0-1.773-1.709-3.07-3.518-4.442-.375-.285-.752-.571-1.11-.86a1 1 0 01-.372-.78V3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});