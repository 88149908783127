define("ember-svg-jar/inlined/smiley-angel-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-angel-alternate</title><path d=\"M20.719 8a1 1 0 00-.5 1.321 9 9 0 11-16.432 0A1 1 0 101.96 8.5 11 11 0 1023 13a10.912 10.912 0 00-.96-4.5 1 1 0 00-1.321-.5z\"/><path d=\"M7.916 12.3a.663.663 0 011.168 0 1 1 0 001.832-.8 2.637 2.637 0 00-4.832 0 1 1 0 101.832.8zM17.4 12.816a1 1 0 00.515-1.317 2.637 2.637 0 00-4.832 0 1 1 0 001.832.8.663.663 0 011.168 0 1 1 0 001.317.515zM12 19.5a5.354 5.354 0 004.614-2.769 1 1 0 10-1.728-1.007A3.378 3.378 0 0112 17.5a3.378 3.378 0 01-2.886-1.776 1 1 0 10-1.728 1.007A5.354 5.354 0 0012 19.5zM12 7c1.126 0 11-.1 11-3.5S13.126 0 12 0 1 .1 1 3.5 10.874 7 12 7zm0-5c4.333 0 7.352.7 8.525 1.276a.253.253 0 01.14.224.249.249 0 01-.14.224C19.353 4.3 16.334 5 12 5s-7.353-.7-8.525-1.276a.249.249 0 010-.448C4.648 2.7 7.667 2 12 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});