define("ember-svg-jar/inlined/aircraft-hot-air-balloon-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>aircraft-hot-air-balloon-1</title><path d=\"M22.108 14.63a.254.254 0 01.012-.178 5.693 5.693 0 00.006-4.895.243.243 0 01-.012-.176l1.622-5.3A2 2 0 0021.824 1.5H19.9a4.959 4.959 0 00-4.489 2.821.251.251 0 01-.286.133A15.3 15.3 0 0011.426 4C5.223 4 .176 7.589.176 12a7.124 7.124 0 003.46 5.756.1.1 0 01.04.082V20.5a2 2 0 002 2h4a2 2 0 002-2v-.265a.251.251 0 01.242-.25 15.227 15.227 0 003.2-.437.249.249 0 01.285.134 4.966 4.966 0 004.486 2.818h1.933a2 2 0 001.912-2.588zM9.676 20.25a.249.249 0 01-.25.25h-3.5a.25.25 0 01-.25-.25v-1.015A.251.251 0 016.018 19a14.367 14.367 0 003.44.864.249.249 0 01.218.248zM19.9 3.5h1.585a.25.25 0 01.239.323l-.979 3.2a.25.25 0 01-.409.11A10.952 10.952 0 0017.319 5.2a.093.093 0 01-.045-.13A2.973 2.973 0 0119.9 3.5zm-.012 17a2.97 2.97 0 01-2.531-1.4.255.255 0 01-.028-.205.25.25 0 01.136-.155 10.831 10.831 0 002.866-1.863.251.251 0 01.409.11l.982 3.193a.25.25 0 01-.239.324z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});