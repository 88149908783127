define("ember-svg-jar/inlined/read-email-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-email-monitor</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v16A1.5 1.5 0 001.5 19h21a1.5 1.5 0 001.5-1.5v-16A1.5 1.5 0 0022.5 0zM22 14.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5zM14.35 20.681a.5.5 0 00-.385-.181h-3.93a.5.5 0 00-.385.181l-2.1 2.532a.5.5 0 00.41.787h8.079a.5.5 0 00.41-.787z\"/><path d=\"M17.9 7.333a.2.2 0 00-.205.006L13.213 9.8a1.847 1.847 0 01-1.926 0L6.809 7.339a.2.2 0 00-.209-.005.2.2 0 00-.1.175V11.3a1.207 1.207 0 001.212 1.2h9.076A1.207 1.207 0 0018 11.3V7.508a.2.2 0 00-.1-.175z\"/><path d=\"M16.788 4.5H7.712A1.207 1.207 0 006.5 5.7a.061.061 0 00.028.051l5.4 3.033a.613.613 0 00.642 0l5.4-3.034A.061.061 0 0018 5.7a1.207 1.207 0 00-1.212-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});