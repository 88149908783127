define("ember-svg-jar/inlined/vintage-camera-flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-camera-flash</title><path d=\"M3 19.5a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-5.676a.25.25 0 00-.068-.171 9.037 9.037 0 01-1.254-1.7A.1.1 0 008.5 12a2 2 0 11-1.019-1.744.25.25 0 00.363-.286 8.93 8.93 0 01-.314-1.74.251.251 0 00-.25-.23H3.25a.25.25 0 00-.25.25zm3.5-4A1.5 1.5 0 115 17a1.5 1.5 0 011.5-1.5z\"/><path d=\"M24 7.5a7.5 7.5 0 10-9.194 7.307.251.251 0 01.194.244v2.195a.249.249 0 01-.251.25h-1.5a.251.251 0 01-.249-.25v-1.288a.248.248 0 00-.149-.228 8.984 8.984 0 01-1.459-.82.252.252 0 00-.258-.015.248.248 0 00-.134.221V19.5A1.5 1.5 0 019.5 21h-6A1.5 1.5 0 012 19.5V8.25A.25.25 0 001.75 8H1.5A1.5 1.5 0 000 9.5v12A2.5 2.5 0 002.5 24h8a2.5 2.5 0 002.5-2.5v-1.757a.249.249 0 01.251-.25h1.5a.251.251 0 01.249.25V21.5a1.5 1.5 0 003 0v-6.449a.25.25 0 01.194-.244A7.514 7.514 0 0024 7.5zm-13 0a5.5 5.5 0 115.5 5.5A5.506 5.506 0 0111 7.5z\"/><circle cx=\"16.5\" cy=\"7.5\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});