define("ember-svg-jar/inlined/keyboard-wireless", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-wireless</title><path d=\"M6.821 4.819a9.484 9.484 0 0110.358 0 1 1 0 101.112-1.662 11.335 11.335 0 00-12.582 0 1 1 0 001.112 1.662z\"/><path d=\"M7.474 6.621a1 1 0 001.11 1.664 6.255 6.255 0 016.833 0 1 1 0 101.108-1.664 8.287 8.287 0 00-9.051 0zM21.5 11.751h-19a2.5 2.5 0 00-2.5 2.5v6a2.5 2.5 0 002.5 2.5h19a2.5 2.5 0 002.5-2.5v-6a2.5 2.5 0 00-2.5-2.5zm-.252 7.5h-.5a.5.5 0 010-1h.5a.5.5 0 010 1zm0-3h-.5a.5.5 0 110-1h.5a.5.5 0 010 1zm-14.749 2h11a.5.5 0 010 1H6.5a.5.5 0 010-1zm-.747-2a.5.5 0 010-1h.5a.5.5 0 010 1zm2.5-.5a.5.5 0 01.5-.5h.5a.5.5 0 010 1h-.5a.5.5 0 01-.499-.5zm3 0a.5.5 0 01.5-.5h.5a.5.5 0 010 1h-.5a.5.5 0 01-.499-.5zm3 0a.5.5 0 01.5-.5h.5a.5.5 0 010 1h-.5a.5.5 0 01-.499-.5zm3.5.5a.5.5 0 110-1h.5a.5.5 0 010 1zm-15-1h.5a.5.5 0 010 1h-.5a.5.5 0 010-1zm0 3h.5a.5.5 0 010 1h-.5a.5.5 0 010-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});