define("ember-svg-jar/inlined/road-sign-no-horn-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-no-horn-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a9.95 9.95 0 01-6.155-2.125.254.254 0 01-.1-.182.249.249 0 01.073-.192l2.325-2.326a1 1 0 00-1.414-1.414l-2.307 2.312a.251.251 0 01-.376-.026 9.99 9.99 0 0114-14 .251.251 0 01.026.376l-1.485 1.484A1 1 0 1018 7.321l1.5-1.5a.25.25 0 01.374.023A9.994 9.994 0 0112 22z\"/><path d=\"M19.943 10.057a.751.751 0 00-.818.163l-.707.707a.247.247 0 01-.176.073h-6.87a.261.261 0 01-.1-.022l-4.6-2.089a1.25 1.25 0 00-1.767 1.138v3.447a1.246 1.246 0 001.767 1.137l.913-.414a.25.25 0 01.289.06 3.758 3.758 0 002.782 1.243h4.5a2.253 2.253 0 002.25-2.25 2.116 2.116 0 00-.05-.446.249.249 0 01.244-.3h.642a.247.247 0 01.176.073l.707.707a.75.75 0 00.531.22.761.761 0 00.287-.057.75.75 0 00.463-.693v-2a.75.75 0 00-.463-.697zM15.156 14h-4.5a2.178 2.178 0 01-1.056-.28.25.25 0 01.018-.446l1.654-.752a.261.261 0 01.1-.022h3.784a.75.75 0 010 1.5z\"/><path d=\"M11.656 9.75h1a.75.75 0 000-1.5h-1a.75.75 0 100 1.5zM14.651 9.75h1a.75.75 0 000-1.5h-1a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});