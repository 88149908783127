define("ember-svg-jar/inlined/warehouse-cart-packages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>warehouse-cart-packages</title><circle cx=\"6.872\" cy=\"20.547\" r=\"1.953\"/><circle cx=\"20.547\" cy=\"20.547\" r=\"1.953\"/><rect x=\"6.872\" y=\"7.849\" width=\"6.837\" height=\"6.837\" rx=\".977\" ry=\".977\"/><rect x=\"15.174\" y=\"7.849\" width=\"6.837\" height=\"6.837\" rx=\".977\" ry=\".977\"/><rect x=\"10.779\" y=\"1.5\" width=\"7.814\" height=\"4.884\" rx=\".977\" ry=\".977\"/><path d=\"M22.988 16.151H5.9a.488.488 0 01-.488-.488V4.413a.273.273 0 010-.057v-.007a1.386 1.386 0 00-1.389-1.384H1.012a.977.977 0 000 1.954h1.953a.487.487 0 01.488.488v10.744a1.954 1.954 0 001.954 1.954h17.581a.977.977 0 000-1.954z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});