define("ember-svg-jar/inlined/agriculture-machine-seeder-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>agriculture-machine-seeder-3</title><rect x=\"9.25\" y=\"7.5\" width=\"6\" height=\"1.5\" rx=\".5\" ry=\".5\"/><path d=\"M22.75 10.5h-5a.5.5 0 00-.493.582l.222 1.336a.5.5 0 01-.111.405.5.5 0 01-.382.177h-1.472a.5.5 0 01-.382-.177.5.5 0 01-.111-.405l.222-1.336a.5.5 0 00-.493-.582h-5a.5.5 0 00-.493.582l.222 1.336a.5.5 0 01-.111.405.5.5 0 01-.382.177H7.514a.5.5 0 01-.382-.177.5.5 0 01-.111-.405l.222-1.336a.5.5 0 00-.493-.582h-5a.5.5 0 00-.493.582l.5 3a.5.5 0 00.493.418h2a.5.5 0 01.5.5v6a1 1 0 002 0v-6a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v6a1 1 0 002 0v-6a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v6a1 1 0 002 0v-6.628a4.038 4.038 0 01.054-.658l.439-2.632a.5.5 0 00-.493-.582z\"/><path d=\"M18.25 17a1.5 1.5 0 00-1.5 1.5v4a1.5 1.5 0 003 0v-4a1.5 1.5 0 00-1.5-1.5zM10.25 17a1.5 1.5 0 00-1.5 1.5v4a1.5 1.5 0 003 0v-4a1.5 1.5 0 00-1.5-1.5zM2.25 17a1.5 1.5 0 00-1.5 1.5v4a1.5 1.5 0 003 0v-4a1.5 1.5 0 00-1.5-1.5zM22.75 7.5h-1a.5.5 0 01-.5-.5V5.5a2 2 0 00-2-2h-1a.5.5 0 01-.5-.5V1.5a1.5 1.5 0 00-1.5-1.5h-8a1.5 1.5 0 00-1.5 1.5V3a.5.5 0 01-.5.5h-1a2 2 0 00-2 2V7a.5.5 0 01-.5.5h-1a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V8a.5.5 0 00-.5-.5h-1a.5.5 0 01-.5-.5V6a.5.5 0 01.5-.5h1a1 1 0 01.6.2 1.489 1.489 0 00.9.3h8a1.489 1.489 0 00.9-.3 1.006 1.006 0 01.6-.2h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V8a.5.5 0 00-.5-.5zm-9-3.5a.5.5 0 01-.5.5h-4.5a.5.5 0 01-.5-.5V2.5a.5.5 0 01.5-.5h4.5a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});