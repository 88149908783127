define("ember-svg-jar/inlined/file-code-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-home</title><path d=\"M17.75 11.5V15a.75.75 0 001.5 0v-3.5a.75.75 0 10-1.5 0zM13.75 8.5V5a.75.75 0 00-1.5 0v3.5a.75.75 0 001.5 0zM20.25 8V5.5A1.251 1.251 0 0019 4.251h-1.5A1.251 1.251 0 0016.25 5.5V8a1.252 1.252 0 001.25 1.25H19A1.252 1.252 0 0020.25 8zm-1.5-.5a.25.25 0 01-.25.25H18a.25.25 0 01-.25-.25V6a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25z\"/><path d=\"M23.414 3L21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.869a.25.25 0 00.33.232 2.479 2.479 0 011.34 0A.25.25 0 008 9.869V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.121a.5.5 0 01.146.355V18a.5.5 0 01-.5.5h-5.338a.25.25 0 00-.241.216 2.026 2.026 0 01-.866 1.33.248.248 0 00.14.454H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M15.25 12A1.25 1.25 0 0014 10.751h-1.5A1.25 1.25 0 0011.25 12v.7a.25.25 0 00.088.191l1.256 1.062c.056.048.156-.051.156-.134V12.5a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25V14a.25.25 0 01-.25.25h-.318c-.088 0-.076.141 0 .2l1.218 1.038a.273.273 0 00.294.037 1.234 1.234 0 00.556-1.025zM7.324 12.119a.5.5 0 00-.647 0l-6.5 5.5A.5.5 0 00.5 18.5h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5H5a.5.5 0 00.5-.5v-2.25a1.5 1.5 0 013 0v2.25a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-4.75a.25.25 0 01.25-.25h1.25a.5.5 0 00.324-.882z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});