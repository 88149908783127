define("ember-svg-jar/inlined/messages-bubble-star-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-star-1</title><path d=\"M9.242 15a3.24 3.24 0 013.016-2h1.187a.25.25 0 00.23-.151l.824-1.909a3.292 3.292 0 015.981-.047l.845 1.956a.249.249 0 00.229.151h.062a.25.25 0 00.2-.1A7.991 7.991 0 0015.5 0H8a7.993 7.993 0 00-3 15.4v4.1a.5.5 0 00.854.353l3.106-3.105a.252.252 0 00.072-.205A3.128 3.128 0 019.242 15z\"/><path d=\"M17.5 22.006l3.235 1.821a1.275 1.275 0 001.506-.166 1.256 1.256 0 00.316-1.45l-1.3-2.981 2.347-2.092a1.262 1.262 0 00.3-1.378 1.233 1.233 0 00-1.165-.76h-2.665l-1.409-3.267-.013-.028a1.293 1.293 0 00-2.317.028L14.924 15h-2.666a1.228 1.228 0 00-1.166.76 1.269 1.269 0 00.319 1.4l2.329 2.07-1.295 2.98a1.255 1.255 0 00.315 1.451 1.286 1.286 0 001.506.166z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});