define("ember-svg-jar/inlined/social-media-identica", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-identica</title><path d=\"M20.76 13.41A4.66 4.66 0 0017.68 12a8 8 0 10-14.41-.9L2 17a1 1 0 001.38 1.13l5.22-2.32a8 8 0 004.21 0 4.64 4.64 0 002.54 5.11l1.05 2.48a1 1 0 00.9.61 1 1 0 00.91-.58L19.34 21a4.7 4.7 0 001.42-7.55zm-11.95.36a1 1 0 00-.72 0l-3.74 1.7.95-4.25a1 1 0 00-.07-.64 6 6 0 015-8.56h.51a6 6 0 11-1.9 11.77zm9.46 5.47a1 1 0 00-.58.52l-.36.77-.33-.77a1 1 0 00-.57-.55 2.7 2.7 0 01.95-5.21 2.7 2.7 0 01.85 5.26z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});