define("ember-svg-jar/inlined/laundry-machine-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laundry-machine-1</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v3.75a.5.5 0 00.5.5h4.884a.225.225 0 00.125-.042 11.74 11.74 0 0112.983 0 .222.222 0 00.124.042H23.5a.5.5 0 00.5-.5V1.5A1.5 1.5 0 0022.5 0zm-4.75 3.75A.75.75 0 1118.5 3a.75.75 0 01-.75.75zm3 0A.75.75 0 1121.5 3a.75.75 0 01-.75.75z\"/><path d=\"M23.5 7.25h-4.884a1.716 1.716 0 01-.957-.295 10.408 10.408 0 00-11.317 0 1.721 1.721 0 01-.958.295H.5a.5.5 0 00-.5.5V22.5A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5V7.75a.5.5 0 00-.5-.5zM12 21a6.5 6.5 0 116.5-6.5A6.5 6.5 0 0112 21z\"/><path d=\"M12 10a4.5 4.5 0 104.5 4.5A4.505 4.505 0 0012 10zm0 7a2.5 2.5 0 112.5-2.5A2.5 2.5 0 0112 17z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});