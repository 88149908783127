define("ember-svg-jar/inlined/crypto-currency-bitcoin-smartphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-smartphone</title><path d=\"M19.5 3a3 3 0 00-3-3h-9a3 3 0 00-3 3v18a3 3 0 003 3h9a3 3 0 003-3zM11 21a1 1 0 111 1 1 1 0 01-1-1zM6.5 3a1 1 0 011-1h9a1 1 0 011 1v14.5a1 1 0 01-1 1h-9a1 1 0 01-1-1z\"/><path d=\"M9 14a1 1 0 001 1h.25a.25.25 0 01.25.25 1 1 0 002 0v-.01a.25.25 0 01.231-.249A3 3 0 0015.5 12a2.961 2.961 0 00-.649-1.845.249.249 0 010-.31A2.961 2.961 0 0015.5 8a3 3 0 00-2.769-2.991.251.251 0 01-.231-.249v-.01a1 1 0 00-2 0 .25.25 0 01-.25.25H10a1 1 0 00-1 1zm3.5-1h-1.25a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h1.25a1 1 0 010 2zm1-5a1 1 0 01-1 1h-1.25a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h1.25a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});