define("ember-svg-jar/inlined/single-neutral-phone-book.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-phone-book</title><path d=\"M24 4a3 3 0 00-3-3h-.774a.5.5 0 01-.433-.25A1.491 1.491 0 0018.5 0H3.75a.25.25 0 00-.25.25v23.5a.25.25 0 00.25.25H18.5a1.491 1.491 0 001.293-.75.5.5 0 01.433-.25H21a3 3 0 003-3zM11.492 6.5a3 3 0 11-3.012 3 3.007 3.007 0 013.012-3zm4.923 11.9a.313.313 0 01-.229.1H7a.5.5 0 01-.5-.556 5.021 5.021 0 0110 .223.308.308 0 01-.085.233zM22 10.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25zm-2 2.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25zM21 3a1 1 0 011 1v1.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25zm1 17a1 1 0 01-1 1h-.75a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25zM2.25 0H1.5A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h.75a.25.25 0 00.25-.25V.25A.25.25 0 002.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});