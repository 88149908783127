define("ember-svg-jar/inlined/gesture-zoom-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-zoom-in</title><path d=\"M21.9 5.905c-1.233-1.621-3.778-2.946-6.774-3.58a.25.25 0 01-.2-.279l.068-.476a.5.5 0 00-.848-.424l-1.5 1.5a.5.5 0 00-.094.577l1 2a.5.5 0 00.942-.153l.078-.546a.251.251 0 01.3-.208c2.415.543 4.524 1.611 5.429 2.8A1 1 0 0021.9 5.905zM9.853 1.146A.5.5 0 009 1.57l.066.466a.249.249 0 01-.2.28C5.9 2.94 3.343 4.278 2.105 5.9A1 1 0 103.7 7.113c.919-1.213 3-2.273 5.423-2.806a.25.25 0 01.3.209l.077.554a.5.5 0 00.942.153l1-2a.5.5 0 00-.093-.577zM5.432 9.52a3.077 3.077 0 00-2.06 3.3l1.052 9.734a.5.5 0 00.5.447h5.021a.5.5 0 00.5-.554L9.322 12.06a3 3 0 00-3.89-2.54zM7.5 13.769l-1.988.214a.5.5 0 01-.551-.444l-.107-.994a1.5 1.5 0 012.983-.321l.107.994a.5.5 0 01-.444.551zM17.422 9.41a3.05 3.05 0 00-2.72 2.77l-1.138 10.264a.5.5 0 00.5.556h5.024a.5.5 0 00.5-.445l1.09-9.836a3 3 0 00-3.256-3.309zm1.761 3.15l-.11.994a.5.5 0 01-.552.442l-1.988-.22a.5.5 0 01-.442-.551l.11-.994a1.5 1.5 0 112.982.329z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});