define("ember-svg-jar/inlined/people-woman-6.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-6</title><path d=\"M22.25 8a3.983 3.983 0 00-1.265-2.9.25.25 0 00-.382.048 4.006 4.006 0 01-1.018 1.094.249.249 0 00-.1.265A7.9 7.9 0 0119.75 8.5v2a7.952 7.952 0 01-1.735 4.963.256.256 0 00-.053.185.251.251 0 00.094.167 6.846 6.846 0 004.113 1.43h.1c1.174-.027 1.364-.393 1.426-.515a.5.5 0 00.016-.425A23.336 23.336 0 0122.25 8zM22.284 20.491a14.249 14.249 0 00-5.908-2.481.5.5 0 00-.552.279 4.557 4.557 0 01-4.073 2.785 4.557 4.557 0 01-4.073-2.785.5.5 0 00-.551-.279 14.267 14.267 0 00-5.91 2.481 2.467 2.467 0 00-.967 1.977V23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-1.032a2.467 2.467 0 00-.966-1.977zM18.961 5.042a.25.25 0 00.4.069A2.965 2.965 0 0020.25 3a3 3 0 00-3-3 2.965 2.965 0 00-2.111.888.25.25 0 00.069.4 8.046 8.046 0 013.753 3.754z\"/><path d=\"M18.75 10.5v-2a7 7 0 00-14 0v2a7 7 0 0014 0zm-7.5 4.976a5.153 5.153 0 01-4.5-5.2v-.727a.251.251 0 01.243-.25c.24-.006.492-.021.757-.047a6.675 6.675 0 003.843-1.561.238.238 0 01.314 0 6.7 6.7 0 003.843 1.566c.265.026.517.039.756.045a.25.25 0 01.244.25v.948a5.006 5.006 0 01-5.5 4.976z\"/><circle cx=\"13.75\" cy=\"10.75\" r=\"1\"/><circle cx=\"9.75\" cy=\"10.75\" r=\"1\"/><path d=\"M10.208 13.342a.639.639 0 00.312.719 2.355 2.355 0 00.438.182 3.017 3.017 0 00.869.127 2.977 2.977 0 00.866-.125 2.375 2.375 0 00.441-.183.64.64 0 00-.307-1.2h-2a.64.64 0 00-.619.48z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});