define("ember-svg-jar/inlined/monitor-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-settings</title><path d=\"M10.757 11.532H2.509a.5.5 0 01-.5-.5v-8.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5V8.4a.25.25 0 00.293.246 8.426 8.426 0 011.457-.127.25.25 0 00.25-.25V2.032a2 2 0 00-2-2h-14a2 2 0 00-2 2v10.5a2 2 0 002 2h5.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-2.5a1 1 0 000 2h4.037a.252.252 0 00.25-.274 7.872 7.872 0 01-.037-.741 8.45 8.45 0 011.7-5.085.25.25 0 00-.2-.4z\"/><circle cx=\"17.739\" cy=\"17.001\" r=\"1.5\"/><path d=\"M22.52 17.314a.464.464 0 010-.626l.988-1.064a1.783 1.783 0 00-1.7-2.953L20.4 13a.455.455 0 01-.533-.312l-.428-1.388a1.776 1.776 0 00-3.4 0l-.423 1.391a.456.456 0 01-.534.312l-1.41-.327a1.783 1.783 0 00-1.7 2.953l.987 1.064a.462.462 0 010 .626l-.987 1.065a1.783 1.783 0 001.7 2.953l1.412-.327a.454.454 0 01.533.31l.423 1.393a1.776 1.776 0 003.4 0l.424-1.393a.449.449 0 01.533-.31l1.411.327a1.783 1.783 0 001.7-2.953zM17.739 20a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});