define("ember-svg-jar/inlined/conversation-chat-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>conversation-chat-1-alternate</title><path d=\"M8 12.805h-.5a1 1 0 00-.707.293L4.5 15.391v-1.586a1 1 0 00-1-1H2.25a.25.25 0 01-.25-.25v-9.5a.25.25 0 01.25-.25h16a.25.25 0 01.25.25v4.25a1 1 0 002 0v-4.5a2 2 0 00-2-2H2a2 2 0 00-2 2v10a2 2 0 002 2h.5v3a1 1 0 001.707.707l3.707-3.707H8a1 1 0 000-2z\"/><path d=\"M22 9.805H12a2 2 0 00-2 2v6a2 2 0 002 2h4.12l3.38 3.007a1.5 1.5 0 002.5-1.12v-1.887a2 2 0 002-2v-6a2 2 0 00-2-2zm-2 10.773l-2.835-2.52a1 1 0 00-.665-.253h-4.25a.25.25 0 01-.25-.25v-5.5a.25.25 0 01.25-.25h9.5a.25.25 0 01.25.25v5.5a.25.25 0 01-.25.25H21a1 1 0 00-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});