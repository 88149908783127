define("ember-svg-jar/inlined/app-window-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-code</title><path d=\"M0 19.251a3 3 0 003 3h18a3 3 0 003-3v-14.5a3 3 0 00-3-3H3a3 3 0 00-3 3zm14.5-15a.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5.966.966 0 011.692 0 .969.969 0 01.154.5zm3.5 0a.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5.966.966 0 011.692 0 .969.969 0 01.154.5zm3.5 0a.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5.983.983 0 01.846-.5 1 1 0 01.908.591.987.987 0 01.092.409zM2 7a.25.25 0 01.25-.25h19.5A.25.25 0 0122 7v12.25a1 1 0 01-1 1H3a1 1 0 01-1-1z\"/><path d=\"M6.793 16.458a1 1 0 001.414 0l2.5-2.5a1 1 0 000-1.414l-2.5-2.5a1 1 0 00-1.414 1.414l1.616 1.616a.25.25 0 010 .354l-1.616 1.616a1 1 0 000 1.414zM13 16.751h4a1 1 0 000-2h-4a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});