define("ember-svg-jar/inlined/real-estate-deal-pay-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-deal-pay-house</title><path d=\"M11.836 5.042L6.672.348a1 1 0 00-1.344 0L.164 5.042a.5.5 0 00.336.871h1a.5.5 0 01.5.5v2.5a1 1 0 001 1h6a1 1 0 001-1v-2.5a.5.5 0 01.5-.5h1a.5.5 0 00.336-.871zM9.5 18.663H8.248a2 2 0 01-2-2v-.75a.5.5 0 01.5-.5h1a.751.751 0 00.531-1.281l-2.5-2.5a.75.75 0 00-1.061 0l-2.5 2.5a.751.751 0 00.53 1.281h1a.5.5 0 01.5.5v.75a4 4 0 004 4H9.5a1 1 0 000-2zM12.752 3.663h3a2 2 0 012 2 .25.25 0 01-.25.25h-1.25a.75.75 0 00-.53 1.28l2.5 2.5a.749.749 0 001.06 0l2.5-2.5a.75.75 0 00-.53-1.28H20a.25.25 0 01-.25-.25 4 4 0 00-4-4h-3a1 1 0 000 2zM17.5 10.913a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm1.112 10.157a.5.5 0 00-.362.481v.112a.75.75 0 01-1.5 0 .5.5 0 00-.5-.5H16a.75.75 0 110-1.5h2.033a.592.592 0 00.221-1.141L16.19 17.7a2.084 2.084 0 01.2-3.941.5.5 0 00.361-.481v-.111a.75.75 0 011.5 0 .5.5 0 00.5.5H19a.75.75 0 010 1.5h-2.033a.591.591 0 00-.221 1.14l2.065.826a2.084 2.084 0 01-.2 3.941z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});