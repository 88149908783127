define("ember-svg-jar/inlined/file-rar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-rar</title><path d=\"M17.875 10.5A.877.877 0 0017 9.623h-.626a.249.249 0 00-.249.249v1.252a.249.249 0 00.249.249H17a.876.876 0 00.875-.873zM7.875 10.5A.877.877 0 007 9.623h-.626a.249.249 0 00-.249.249v1.252a.249.249 0 00.249.249H7a.876.876 0 00.875-.873zM12 9.623a.877.877 0 00-.875.875v.623a.252.252 0 00.252.252h1.249a.249.249 0 00.249-.249V10.5A.877.877 0 0012 9.623z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM7.118 12.732l1.824 1.825a.625.625 0 01-.884.883s-1.816-1.809-1.845-1.84-.088-.037-.088.02V15a.625.625 0 01-1.25 0V9a.625.625 0 01.625-.627H7a2.124 2.124 0 01.205 4.239c-.096 0-.129.079-.087.12zM14.125 15a.625.625 0 01-1.25 0v-2.128a.249.249 0 00-.249-.249h-1.25a.251.251 0 00-.251.251V15a.625.625 0 01-1.25 0v-4.5a2.125 2.125 0 114.25 0zm2.993-2.266l1.824 1.825a.625.625 0 01-.884.883s-1.816-1.809-1.845-1.844-.088-.037-.088.02V15a.625.625 0 01-1.25 0V9a.625.625 0 01.625-.625H17a2.124 2.124 0 01.2 4.239c-.091-.002-.124.077-.082.118z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});