define("ember-svg-jar/inlined/design-tool-pencil-ruler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-tool-pencil-ruler</title><rect x=\"5.75\" y=\"7\" width=\"1.5\" height=\"12.25\" rx=\".25\" ry=\".25\"/><rect x=\"8.25\" y=\"7\" width=\"1.5\" height=\"12.25\" rx=\".25\" ry=\".25\"/><rect x=\"3.25\" y=\"7\" width=\"1.5\" height=\"12.25\" rx=\".25\" ry=\".25\"/><path d=\"M9.3 6a.25.25 0 00.226-.358L6.951.283a.522.522 0 00-.9 0L3.478 5.642A.25.25 0 003.7 6zM3.5 20.25a.25.25 0 00-.25.25v.25a3.25 3.25 0 006.5 0v-.25a.25.25 0 00-.25-.25zM19.25 0h-5a1.5 1.5 0 00-1.5 1.5v1.25A.25.25 0 0013 3h1.75a.5.5 0 010 1H13a.25.25 0 00-.25.25v2.5A.25.25 0 0013 7h1.75a.5.5 0 010 1H13a.25.25 0 00-.25.25v2.5A.25.25 0 0013 11h1.75a.5.5 0 010 1H13a.25.25 0 00-.25.25v2.5A.25.25 0 0013 15h1.75a.5.5 0 010 1H13a.25.25 0 00-.25.25v6.25a1.5 1.5 0 001.5 1.5h5a1.5 1.5 0 001.5-1.5v-21a1.5 1.5 0 00-1.5-1.5zm-2.5 19.5a1.25 1.25 0 11-1.25 1.25 1.252 1.252 0 011.25-1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});