define("ember-svg-jar/inlined/multiple-actions-sync.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-sync</title><path d=\"M23.687 11.793a.494.494 0 00-.534.106L21.9 13.153a.25.25 0 01-.347.006 6.263 6.263 0 10.62 8.485A1 1 0 0020.61 20.4a4.257 4.257 0 11-.489-5.836.25.25 0 01.01.363l-1.344 1.344a.49.49 0 00.347.836H23.5a.49.49 0 00.49-.49v-4.371a.49.49 0 00-.303-.453zM12.034 8.534a.5.5 0 00-.182.771 7.473 7.473 0 01.787 1.187.5.5 0 00.7.19A7.933 7.933 0 0117.511 9.5a.321.321 0 00.169-.593 6 6 0 00-5.646-.373z\"/><ellipse cx=\"14.024\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M11.574 11.733a6.026 6.026 0 00-11.559 1.6.6.6 0 00.6.667h9.41a.5.5 0 00.441-.264 8.015 8.015 0 011.025-1.484.5.5 0 00.083-.519z\"/><ellipse cx=\"5.524\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});