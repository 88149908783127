define("ember-svg-jar/inlined/charging-cable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>charging-cable</title><path d=\"M23.75 6.067V3.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75v2.567A.5.5 0 0019 6.5v4a1.5 1.5 0 001.5 1.5v2a7.969 7.969 0 01-2.188 5.491 6 6 0 10-11.624 0A7.969 7.969 0 014.5 14v-2A2.5 2.5 0 007 9.5V5a.5.5 0 00-.5-.5h-.25V1A.75.75 0 005.5.25h-4A.75.75 0 00.75 1v3.5H.5A.5.5 0 000 5v4.5A2.5 2.5 0 002.5 12v2a10 10 0 0020 0v-2a1.5 1.5 0 001.5-1.5v-4a.5.5 0 00-.25-.433zM2.25 2a.25.25 0 01.25-.25h2a.25.25 0 01.25.25v2.25a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25zM12.5 14a4 4 0 11-4 4 4 4 0 014-4zM22 4.25a.25.25 0 01.25.25v1.25A.25.25 0 0122 6h-1a.25.25 0 01-.25-.25V4.5a.25.25 0 01.25-.25z\"/><path d=\"M9.75 6h1a.25.25 0 01.25.25V9.5a.5.5 0 00.326.469.506.506 0 00.553-.143l3.941-4.589A.75.75 0 0015.25 4h-1a.25.25 0 01-.25-.25V.5a.5.5 0 00-.879-.326L9.18 4.763A.75.75 0 009.75 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});