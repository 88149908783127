define("ember-svg-jar/inlined/time-clock-hand-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-clock-hand-1-alternate</title><path d=\"M21.071 2.931a10 10 0 00-14.142 0A10.06 10.06 0 004.3 12.425a1 1 0 001.94-.482 8 8 0 1113.42 3.715 1 1 0 001.414 1.414 10 10 0 000-14.141zM15.5 19.5H6.375a.625.625 0 010-1.25H8.9a1 1 0 00.919-1.393A2.235 2.235 0 007.764 15.5H3.5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.764a2.238 2.238 0 002.236-2.235V20.5a1 1 0 00-1-1zM1 14.751a1 1 0 00-1 1V23a1 1 0 002 0v-7.25a1 1 0 00-1-.999z\"/><path d=\"M18 9.5h-3v-5a1 1 0 10-2 0v6a1 1 0 001 1h4a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});