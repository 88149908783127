define("ember-svg-jar/inlined/design-tool-pens", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-tool-pens</title><path d=\"M20.375 8.772a3.973 3.973 0 01-1.895-.478.252.252 0 00-.23 0 .248.248 0 00-.136.185 19.387 19.387 0 00-.239 3.025 57.262 57.262 0 001.4 11.619 1.132 1.132 0 002.207 0 57.358 57.358 0 001.4-11.619 19.387 19.387 0 00-.239-3.025.248.248 0 00-.143-.189.252.252 0 00-.23 0 3.973 3.973 0 01-1.895.482zM20.71.1a.75.75 0 00-1.076.786c.114.731-.171 1.032-.773 1.591a3 3 0 00-1.236 2.293 2.75 2.75 0 005.5 0c0-1.676-.846-3.884-2.415-4.67zM11.375 6a.5.5 0 00-.5.5v13a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-13a.5.5 0 00-.5-.5zM5.875 0a2 2 0 00-2 2v1.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V2a2 2 0 00-2-2zM7.375 5h-3a3.5 3.5 0 00-3.5 3.5v6.25a.75.75 0 001.5 0V8.5a2 2 0 011.143-1.807.25.25 0 01.357.226V18a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V5.5a.5.5 0 00-.5-.5zM13.343.324a.521.521 0 00-.936 0l-1.5 4a.5.5 0 00.468.676h3a.5.5 0 00.468-.676zM14.375 21h-3a.5.5 0 00-.5.5v.5a2 2 0 004 0v-.5a.5.5 0 00-.5-.5zM7.375 19.5h-3a.5.5 0 00-.459.7l1.5 3.5a.5.5 0 00.918 0l1.5-3.5a.5.5 0 00-.459-.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});