define("ember-svg-jar/inlined/data-file-bars-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-bars-upload</title><path d=\"M13 17.5A6.5 6.5 0 106.5 24a6.508 6.508 0 006.5-6.5zm-7.5 3v-2.875a.127.127 0 00-.124-.125H4.5a.5.5 0 01-.391-.812l2-2.5a.517.517 0 01.782 0l2 2.5a.5.5 0 01-.391.812h-.876a.125.125 0 00-.124.121V20.5a1 1 0 01-2 0z\"/><rect x=\"14.5\" y=\"5.5\" width=\"2\" height=\"8\" rx=\".5\" ry=\".5\"/><rect x=\"18\" y=\"7.5\" width=\"2\" height=\"6\" rx=\".5\" ry=\".5\"/><path d=\"M21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.275a.247.247 0 00.242.25c.366.007 1.038.034 1.485.082A.245.245 0 008 9.359V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.859a.244.244 0 00-.24.21 7.956 7.956 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.414A2 2 0 0023.414 3z\"/><path d=\"M12.565 12.3a.251.251 0 00.435-.172V10.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v.229a.325.325 0 00.156.271 7.971 7.971 0 011.409 1.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});