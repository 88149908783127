define("ember-svg-jar/inlined/single-woman-actions-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-warning</title><path d=\"M.142 17.931a.5.5 0 00.477.626h2.3a.248.248 0 01.244.2l.92 4.847v.007a.5.5 0 00.484.389h2.969a.5.5 0 00.484-.388v-.007l.921-4.847a.247.247 0 01.243-.2h1.93a.247.247 0 00.219-.133l.47-.895a.245.245 0 00.019-.18c-1.807-6.6-1.729-6.973-2.847-8.019a.5.5 0 00-.756.1L6.483 12.16a.5.5 0 01-.837 0L3.923 9.411a.5.5 0 00-.755-.1C2.015 10.373 2.1 10.78.142 17.931zM6.041 0a4.206 4.206 0 104.206 4.206A4.211 4.211 0 006.041 0zm0 6.433A2.224 2.224 0 013.9 4.81a.248.248 0 01.158-.3 4.616 4.616 0 001.8-1.128.252.252 0 01.177-.074.247.247 0 01.176.074 4.627 4.627 0 001.8 1.128.248.248 0 01.158.3 2.224 2.224 0 01-2.128 1.623zM11.172 21.927a1.42 1.42 0 00.048 1.394 1.44 1.44 0 001.22.679h10.007a1.442 1.442 0 001.221-.679 1.417 1.417 0 00.047-1.394l-5-9.535a1.435 1.435 0 00-2.535 0zm6.272-6.587a.742.742 0 01.742.742v1.98a.742.742 0 01-1.484 0v-1.98a.742.742 0 01.742-.742zm-.99 5.938a.99.99 0 11.99.99.989.989 0 01-.99-.99z\"/><path d=\"M.142 17.931a.5.5 0 00.477.626h2.3a.248.248 0 01.244.2l.92 4.847v.007a.5.5 0 00.484.389h2.969a.5.5 0 00.484-.388v-.007l.921-4.847a.247.247 0 01.243-.2h1.93a.247.247 0 00.219-.133l.47-.895a.245.245 0 00.019-.18c-1.807-6.6-1.729-6.973-2.847-8.019a.5.5 0 00-.756.1L6.483 12.16a.5.5 0 01-.837 0L3.923 9.411a.5.5 0 00-.755-.1C2.015 10.373 2.1 10.78.142 17.931zM6.041 0a4.206 4.206 0 104.206 4.206A4.211 4.211 0 006.041 0zm0 6.433A2.224 2.224 0 013.9 4.81a.248.248 0 01.158-.3 4.616 4.616 0 001.8-1.128.252.252 0 01.177-.074.247.247 0 01.176.074 4.627 4.627 0 001.8 1.128.248.248 0 01.158.3 2.224 2.224 0 01-2.128 1.623zM11.172 21.927a1.42 1.42 0 00.048 1.394 1.44 1.44 0 001.22.679h10.007a1.442 1.442 0 001.221-.679 1.417 1.417 0 00.047-1.394l-5-9.535a1.435 1.435 0 00-2.535 0zm6.272-6.587a.742.742 0 01.742.742v1.98a.742.742 0 01-1.484 0v-1.98a.742.742 0 01.742-.742zm-.99 5.938a.99.99 0 11.99.99.989.989 0 01-.99-.99z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});