define("ember-svg-jar/inlined/plane-check-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-check-in</title><circle cx=\"12\" cy=\"3.25\" r=\"3\"/><path d=\"M16 9.75a2 2 0 00-2-2h-.4a.5.5 0 00-.416.223l-.769 1.153a.5.5 0 01-.832 0l-.769-1.153a.5.5 0 00-.414-.223H10a2 2 0 00-2 2v1a.5.5 0 00.5.5h7a.5.5 0 00.5-.5zM8 12.25H3a2.5 2.5 0 00-2.5 2.5v8.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-8.5a2.5 2.5 0 00-2.5-2.5H8zm8.46 4.332a.931.931 0 01-.623 1.159l-1.739.521a.413.413 0 00-.235.2L12.829 20.8a.324.324 0 01-.206.175l-.879.264a.323.323 0 01-.285-.044.24.24 0 01-.1-.24l.412-1.593a.245.245 0 00-.1-.241.324.324 0 00-.284-.043l-1.142.343a.316.316 0 00-.222.223l-.195.841a.325.325 0 01-.283.236l-.945.113a.313.313 0 01-.247-.074.243.243 0 01-.075-.223l.156-1.544-.98-1.2a.237.237 0 01-.06-.227.308.308 0 01.165-.2l.851-.427a.326.326 0 01.366.042l.626.594a.313.313 0 00.308.064l1.142-.342a.326.326 0 00.214-.193.244.244 0 00-.052-.254L9.8 15.742a.242.242 0 01-.051-.254.322.322 0 01.213-.193l.88-.264a.324.324 0 01.268.033l2.147 1.378a.411.411 0 00.308.038l1.738-.522a.93.93 0 011.157.624z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});