define("ember-svg-jar/inlined/gesture-swipe-vertical-up-two-fingers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-vertical-up-two-fingers</title><path d=\"M19.224 2.841a.25.25 0 01.118-.322l.974-.487A.75.75 0 0020.1.622l-2.931-.49a.753.753 0 00-.82.461l-.978 2.446a.75.75 0 001.031.949l.7-.348a.246.246 0 01.2-.01.251.251 0 01.144.138 22.306 22.306 0 011.538 8.353 18.182 18.182 0 01-2.7 10.19 1 1 0 101.658 1.118 20.146 20.146 0 003.038-11.308 23.879 23.879 0 00-1.756-9.28z\"/><path d=\"M13.061 5.623H3.448a.428.428 0 00-.428.428v5.142a.429.429 0 00.428.429h9.613a3 3 0 100-6zm0 4.5h-1a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h1a1.5 1.5 0 010 3zM13.02 12.623H3.448a.428.428 0 00-.428.428v5.142a.429.429 0 00.428.429h9.572a3 3 0 100-6zm0 4.5h-1a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h1a1.5 1.5 0 010 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});