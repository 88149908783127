define("ember-svg-jar/inlined/folder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder</title><path d=\"M20 6.5h-9.5a.5.5 0 01-.359-.153L8.8 4.959A1.506 1.506 0 007.718 4.5H4.286A1.788 1.788 0 002.5 6.285v11.43A1.788 1.788 0 004.286 19.5h15.428a1.788 1.788 0 001.786-1.785V8A1.5 1.5 0 0020 6.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});