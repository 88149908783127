define("ember-svg-jar/inlined/file-ppt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-ppt</title><path d=\"M7 9.625h-.623a.252.252 0 00-.252.252v1.246a.252.252 0 00.252.252H7a.875.875 0 000-1.75zM12 9.625h-.623a.252.252 0 00-.252.252v1.246a.252.252 0 00.252.252H12a.875.875 0 000-1.75z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM7 12.621h-.623a.252.252 0 00-.252.252V15a.625.625 0 11-1.25 0V9a.625.625 0 01.625-.629H7a2.125 2.125 0 110 4.25zm5 0h-.623a.252.252 0 00-.252.252V15a.625.625 0 11-1.25 0V9a.625.625 0 01.625-.625H12a2.125 2.125 0 010 4.25zm7.5-3h-1.126a.249.249 0 00-.249.25V15a.625.625 0 01-1.25 0V9.875a.249.249 0 00-.249-.25H15.5a.625.625 0 010-1.25h4a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});