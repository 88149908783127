define("ember-svg-jar/inlined/security-officer-luggage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>security-officer-luggage</title><path d=\"M15.183 5.7a.25.25 0 00-.25.25v.75a2.5 2.5 0 005 .012v-.764a.25.25 0 00-.25-.25zM13.478 4.7h6.368a.5.5 0 00.475-.342l.33-.988a1 1 0 00-.707-1.286L14.91.823a.755.755 0 00-.73.216.746.746 0 00-.164.749l.3.91h-.841a1 1 0 100 2zM19.98 11.2h-1.16a.5.5 0 00-.47.31l-.64 1.61a.262.262 0 01-.23.16.251.251 0 01-.23-.16l-.65-1.61a.489.489 0 00-.46-.31H14.4a2 2 0 00-1.415.587l-4.594 4.6a1.245 1.245 0 101.76 1.76l3.9-3.892a.25.25 0 01.427.177V22.7a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-7.75a.5.5 0 111 0v5.75a1.25 1.25 0 002.5 0v-5.5a4.005 4.005 0 00-3.998-4z\"/><path d=\"M12.02 19.7h-8a.5.5 0 01-.5-.5v-9a1 1 0 00-1-1 2.5 2.5 0 00-2.5 2.5v6a2.5 2.5 0 002 2.45v.55a2.5 2.5 0 002.5 2.5h6a2.5 2.5 0 002.5-2.5 1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});