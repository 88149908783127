define("ember-svg-jar/inlined/touch-finger-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>touch-finger-1</title><path d=\"M4.712 12.944a1 1 0 001.571-1.237 6 6 0 119.4.045 1 1 0 00.154 1.406 1.013 1.013 0 001.41-.158A8 8 0 103 8a8.248 8.248 0 001.712 4.944z\"/><path d=\"M16.385 17H13.5v-6.5a2.5 2.5 0 00-5 0v8.835l-1.091-1.527a2.075 2.075 0 10-3.672 1.932l2.1 3.993a.5.5 0 00.444.267H20.5a.5.5 0 00.5-.5v-1.652A4.8 4.8 0 0016.385 17z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});