define("ember-svg-jar/inlined/drawer-cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drawer-cash</title><path d=\"M4.51 12.5a1 1 0 001-1L5.5 2.25a.251.251 0 01.073-.177.255.255 0 01.18-.073h12.494a.25.25 0 01.25.25l.003 9.25a1 1 0 001 1 1 1 0 001-1V2a2 2 0 00-2-2h-13a2 2 0 00-2 2l.006 9.5a1 1 0 001.004 1z\"/><path d=\"M12.125 13.75a.75.75 0 00.75-.75v-.522a2.084 2.084 0 00.56-4.011l-2.063-.826a.592.592 0 01.22-1.141h2.033a.75.75 0 000-1.5h-.75v-.5a.75.75 0 00-1.5 0v.522a2.085 2.085 0 00-.56 4.012l2.063.825a.592.592 0 01-.22 1.141h-2.033a.75.75 0 000 1.5h.75v.5a.75.75 0 00.75.75z\"/><path d=\"M23.868 16l-1.75-3a1 1 0 10-1.728 1l1.164 2H17.5a1.5 1.5 0 00-1.5 1.5 1.5 1.5 0 01-1.5 1.5h-5A1.5 1.5 0 018 17.5 1.5 1.5 0 006.5 16H2.454l1.164-2a1 1 0 10-1.728-1L.14 16a1.414 1.414 0 00-.14.5v6A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-6a1.255 1.255 0 00-.132-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});