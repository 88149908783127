define("ember-svg-jar/inlined/radiology-scan-hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>radiology-scan-hand</title><path d=\"M23.853 22.478l-1.769-2.651a.5.5 0 01-.084-.278v-4.125c0-1.773-1.709-3.07-3.519-4.442-.5-.381-1.006-.764-1.465-1.154A.052.052 0 0117 9.791V1.5A1.5 1.5 0 0015.5 0h-14A1.5 1.5 0 000 1.5v19A1.5 1.5 0 001.5 22h10.9a.5.5 0 00.5-.575 2.889 2.889 0 01-.034-.439v-.4a.5.5 0 00-.131-.337l-1.2-1.313a4 4 0 014.856-6.209.416.416 0 00.414-.007.411.411 0 00.12-.107.249.249 0 01.35-.048l.008.006C18.488 13.5 20 14.644 20 15.424v4.559a.992.992 0 00.147.522l2 3.017a1 1 0 101.705-1.044zM13.95 8.921a.75.75 0 01-.9 1.2A8.229 8.229 0 009.831 8.89a.5.5 0 00-.581.493v.986a.5.5 0 00.445.5 9.133 9.133 0 011.51.286.75.75 0 11-.411 1.443 7.635 7.635 0 00-.972-.2.5.5 0 00-.572.495v2.623a.5.5 0 00.2.4.733.733 0 01.3.591.75.75 0 01-.75.75H5a.75.75 0 010-1.5h2.25a.5.5 0 00.5-.5v-2.383a.5.5 0 00-.581-.494 8.48 8.48 0 00-2.8.976.75.75 0 01-.743-1.3 10.025 10.025 0 013.684-1.209.5.5 0 00.44-.5v-.964a.5.5 0 00-.582-.493 8.238 8.238 0 00-3.22 1.232.75.75 0 01-.9-1.2 9.673 9.673 0 014.263-1.563.5.5 0 00.439-.5V5.853a.5.5 0 00-.581-.494 8.447 8.447 0 00-2.8.978.75.75 0 11-.744-1.3 9.888 9.888 0 019.742 0 .749.749 0 01-.742 1.3A8.485 8.485 0 009.83 5.36a.5.5 0 00-.58.493v1.01a.5.5 0 00.438.5 9.673 9.673 0 014.262 1.558z\"/><path d=\"M16.18 14.366a2.5 2.5 0 00-3.548 3.525l1.724 1.886v1.172c0 1.357 1.552 2.466 2.028 2.838a1 1 0 001.234-1.573c-.608-.48-1.234-1-1.262-1.265v-1.561a1 1 0 00-.262-.675l-1.985-2.172a.5.5 0 01.712-.708l2.015 1.867a1 1 0 001.364-1.471z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});