define("ember-svg-jar/inlined/office-chair-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-chair-table</title><path d=\"M3.5 8.249V6a5.414 5.414 0 01.131-1.194.25.25 0 00-.244-.306H2a2.007 2.007 0 00-2 2v15a1 1 0 002 0V9a.5.5 0 01.5-.5h.75a.25.25 0 00.25-.251zM22.5 4.5h-7.887a.25.25 0 00-.244.306A5.414 5.414 0 0114.5 6v2.25a.25.25 0 00.25.25h.75a.5.5 0 01.5.5v1a.5.5 0 00.5.5h7a.5.5 0 00.5-.5V6a1.5 1.5 0 00-1.5-1.5zm-2.5 4a1 1 0 111-1 1 1 0 01-1 1zM23.5 12h-7a.5.5 0 00-.5.5V18a1.5 1.5 0 001.5 1.5h4a.5.5 0 01.5.5v1.5a1 1 0 002 0v-9a.5.5 0 00-.5-.5zM20 16a1 1 0 111-1 1 1 0 01-1 1zM14 14.5a.5.5 0 01-.5-.5V6a4.5 4.5 0 00-9 0v8a.5.5 0 01-.5.5 1 1 0 100 2 .5.5 0 01.5.5v4.5a1 1 0 002 0V17a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v4.5a1 1 0 002 0V17a.5.5 0 01.5-.5 1 1 0 100-2zm-2.5-.5a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h4a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});