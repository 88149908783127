define("ember-svg-jar/inlined/real-estate-dimensions-building-ruler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-dimensions-building-ruler</title><path d=\"M15.724 15.553l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 009 16v7.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V16a.5.5 0 00-.276-.447zM13.5 18.75h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zM23.692 12.038l-6-2.5A.5.5 0 0017 10v13.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-11a.5.5 0 00-.308-.462zM21.5 18.75h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm0-3.5h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5z\"/><path d=\"M23 0H1a1 1 0 00-1 1v22a1 1 0 001 1h3.5a.5.5 0 00.5-.5v-2.75a.5.5 0 00-.5-.5h-2a.75.75 0 010-1.5h2a.5.5 0 00.5-.5v-2.5a.5.5 0 00-.5-.5h-2a.75.75 0 010-1.5h2a.5.5 0 00.5-.5v-2.5a.5.5 0 00-.5-.5h-2a.75.75 0 010-1.5h2a.5.5 0 00.5-.5V5.5a.5.5 0 01.5-.5h2.75a.5.5 0 00.5-.5v-2a.75.75 0 011.5 0v2a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-2a.75.75 0 011.5 0v2a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-2a.75.75 0 011.5 0v2a.5.5 0 00.5.5h2.75a.5.5 0 00.5-.5V1a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});