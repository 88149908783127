define("ember-svg-jar/inlined/accounting-calculator-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accounting-calculator-2</title><path d=\"M11.875 11.375a3.23 3.23 0 012.863-3h1.137a.25.25 0 00.25-.25 8.012 8.012 0 10-4.387 7.133.25.25 0 00.137-.223zm-5.375.5a.75.75 0 010-1.5h2.033a.592.592 0 00.22-1.141L6.69 8.409a2.084 2.084 0 01.36-3.981.249.249 0 00.2-.245v-.308a.75.75 0 011.5 0v.25a.25.25 0 00.25.25h.5a.75.75 0 010 1.5H7.467a.592.592 0 00-.22 1.141l2.063.825a2.084 2.084 0 01-.36 3.981.249.249 0 00-.2.245v.308a.75.75 0 01-1.5 0v-.25a.25.25 0 00-.25-.25z\"/><path d=\"M22.512 9.875h-7.774c-.662 0-1.363.976-1.363 1.5v11c0 .524.7 1.5 1.363 1.5h7.774c.662 0 1.363-.976 1.363-1.5v-11c0-.524-.701-1.5-1.363-1.5zm-1.387 11.51a.75.75 0 11.75-.75.75.75 0 01-.75.75zm-5.75-.75a.75.75 0 11.75.75.75.75 0 01-.75-.75zm0-2.51a.75.75 0 11.75.75.75.75 0 01-.75-.75zm2.5 2.51a.75.75 0 11.75.75.75.75 0 01-.75-.75zm0-2.51a.75.75 0 11.75.75.75.75 0 01-.75-.75zm3.25.75a.75.75 0 11.75-.75.75.75 0 01-.75.75zm.25-3.5h-5.5c-.268 0-.534-.219-.534-.69v-2.31c0-.472.268-.667.536-.667h5.5c.268 0 .536.2.536.667v2.333c-.002.472-.27.667-.538.667z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});