define("ember-svg-jar/inlined/weather-app-sunny", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-app-sunny</title><path d=\"M15 17.5V21a1 1 0 01-1 1H4a1 1 0 01-1-1V3a1 1 0 011-1h3.5a1 1 0 000-2H4a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3v-3.5a1 1 0 10-2 0z\"/><path d=\"M11 19H7a.75.75 0 000 1.5h4a.75.75 0 000-1.5zM16 3a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 112-2 2 2 0 01-2 2z\"/><circle cx=\"16\" cy=\"1\" r=\"1\"/><path d=\"M12.464 3.464a1 1 0 10-1.414 0 1 1 0 001.414 0z\"/><circle cx=\"10\" cy=\"7\" r=\"1\"/><path d=\"M11.05 10.536a1 1 0 101.414 0 1 1 0 00-1.414 0zM20.95 3.464a1 1 0 10-1.414 0 1 1 0 001.414 0z\"/><circle cx=\"22\" cy=\"7\" r=\"1\"/><path d=\"M19.536 10.536a1 1 0 101.414 0 1 1 0 00-1.414 0z\"/><circle cx=\"16\" cy=\"13\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});