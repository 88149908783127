define("ember-svg-jar/inlined/shopping-bag-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-bag-check</title><path d=\"M20.1 23.875a3.75 3.75 0 003.721-4.215L22.245 7.032v-.007a.766.766 0 00-.028-.124v-.01l-.005-.017-1.045-2.934a.744.744 0 00-.041-.091 1.729 1.729 0 00-1.526-.974h-1a.75.75 0 000 1.5h1a.234.234 0 01.172.126l.547 1.54a.251.251 0 01-.236.334h-2.731a.25.25 0 01-.25-.25v-1a5 5 0 00-10 0v1a.25.25 0 01-.25.25H4.01a.25.25 0 01-.231-.347l.633-1.494a.255.255 0 01.19-.159h1a.75.75 0 000-1.5h-1A1.752 1.752 0 003.054 3.9L1.811 6.83v.008a.604.604 0 00-.019.051v.014a.7.7 0 00-.027.127L.177 19.66A3.75 3.75 0 003.9 23.875zM8.728 14.762a.342.342 0 01.484 0l1.509 1.444a.251.251 0 00.35 0l4.477-4.476a.342.342 0 01.484 0l1.23 1.229a.343.343 0 010 .484l-6.122 6.118a.343.343 0 01-.484 0L7.5 16.476a.346.346 0 010-.486zM9.1 5.125a3 3 0 016 0v1a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});