define("ember-svg-jar/inlined/color-palette-sample-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-palette-sample-2</title><path d=\"M22.173 19.367a2.5 2.5 0 001.733-3.081l-1.347-4.815a2.5 2.5 0 00-3.081-1.734l-.753.211.526-.653a2.5 2.5 0 00-.377-3.515l-3.891-3.138a2.5 2.5 0 00-3.516.376l-1.022 1.267A.25.25 0 0110 4.128V2.5A2.5 2.5 0 007.5 0h-5A2.5 2.5 0 000 2.5v16.307a5.145 5.145 0 004.51 5.17c.166.015.332.023.5.023.99 0 .49 0 17.163-4.633zM13.024 4.273a.5.5 0 01.7-.075l3.892 3.14a.5.5 0 01.075.7l-7.249 8.988a.249.249 0 01-.442-.155V8.11a.248.248 0 01.055-.157zM7 5a.5.5 0 01-.5.5h-3A.5.5 0 013 5V3a.5.5 0 01.5-.5h3A.5.5 0 017 3zM3.5 8h3a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5zm0 5.5h3a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-3A.5.5 0 013 16v-2a.5.5 0 01.5-.5zm1.5 5A1.5 1.5 0 113.5 20 1.5 1.5 0 015 18.5zm16.981-1.675a.5.5 0 01-.347.616l-10.811 3.026a.25.25 0 01-.262-.4l5.95-7.376a.25.25 0 01.127-.084l3.378-.946a.5.5 0 01.617.347z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});