define("ember-svg-jar/inlined/image-file-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-lock</title><path d=\"M14.87 5.425a1.462 1.462 0 10-1.462 1.462 1.462 1.462 0 001.462-1.462zM11.385 12.64a.183.183 0 00.16.095h8.843a.181.181 0 00.16-.095.178.178 0 000-.185l-3.17-5.073a.366.366 0 00-.62 0L14.98 10.22a.183.183 0 01-.31 0l-.632-1.01a.365.365 0 00-.62 0l-2.028 3.245a.181.181 0 00-.005.185z\"/><path d=\"M23.426 2.939L21.061.574A1.956 1.956 0 0019.676 0H8.327a1.96 1.96 0 00-1.96 1.959v7.7a.237.237 0 00.153.22 4.388 4.388 0 011.4.9.241.241 0 00.406-.178V2.449a.489.489 0 01.489-.49h10.658a.491.491 0 01.347.144L21.9 4.18a.491.491 0 01.144.347v13.106a.489.489 0 01-.49.489H11.51a.245.245 0 00-.245.245v1.469a.245.245 0 00.245.245h10.531A1.96 1.96 0 0024 18.122V4.324a1.956 1.956 0 00-.574-1.385z\"/><path d=\"M8.435 15.685a.12.12 0 01-.108-.117v-1.119a3.429 3.429 0 00-6.858 0v1.111a.112.112 0 01-.111.125A1.466 1.466 0 000 17.143v5.388A1.471 1.471 0 001.469 24h6.858A1.471 1.471 0 009.8 22.531v-5.388a1.466 1.466 0 00-1.365-1.458zM4.9 21.306a.98.98 0 11.98-.979.979.979 0 01-.98.979zm1.467-5.746a.116.116 0 01-.1.113H3.539a.119.119 0 01-.11-.114v-1.11a1.469 1.469 0 012.938 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});