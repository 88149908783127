define("ember-svg-jar/inlined/face-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>face-id</title><path d=\"M6.5 22H2.251A.249.249 0 012 21.75V17.5a1 1 0 00-2 0V22a2 2 0 002 2h4.5a1 1 0 100-2zM1 7.5a1 1 0 001-1V2.25A.249.249 0 012.251 2H6.5a1 1 0 000-2H2a2 2 0 00-2 2v4.5a1 1 0 001 1zM23 16.5a1 1 0 00-1 1v4.25a.25.25 0 01-.25.25H17.5a1 1 0 000 2H22a2 2 0 002-2v-4.5a1 1 0 00-1-1zM22 0h-4.5a1 1 0 000 2h4.25a.25.25 0 01.25.25V6.5a1 1 0 002 0V2a2 2 0 00-2-2zM15.772 14.453a1 1 0 011.676 1.094 6.5 6.5 0 01-10.9 0 1 1 0 011.676-1.094 4.5 4.5 0 007.544 0zM8.5 11.5a1 1 0 01-1-1v-3a1 1 0 012 0v3a1 1 0 01-1 1zM15.5 11.5a1 1 0 01-1-1v-3a1 1 0 012 0v3a1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});