define("ember-svg-jar/inlined/synchronize-arrows-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>synchronize-arrows-lock</title><path d=\"M11.988 7.248A2.752 2.752 0 009.238 10v.873a.251.251 0 01-.15.23.977.977 0 00-.6.9v3a1 1 0 001 1h5a1 1 0 001-1V12a.977.977 0 00-.6-.9.251.251 0 01-.15-.23V10a2.752 2.752 0 00-2.75-2.752zm0 1.5A1.25 1.25 0 0113.238 10v.75a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25V10a1.251 1.251 0 011.25-1.252zm0 5.75a1 1 0 111-1 1 1 0 01-1 1.002z\"/><path d=\"M.077 10.319l2.589 4.54a.5.5 0 00.372.248.444.444 0 00.063 0 .5.5 0 00.359-.152L7.1 11.2a.5.5 0 00-.3-.844L4.758 10.1a.251.251 0 01-.2-.332 8.1 8.1 0 015.672-5.209 7.616 7.616 0 017.479 2.373 1.25 1.25 0 101.872-1.657 10.122 10.122 0 00-9.926-3.149 10.673 10.673 0 00-7.673 7.439.218.218 0 01-.237.157l-1.17-.147a.5.5 0 00-.5.744zM23.488 14.41a.5.5 0 00.433-.75l-2.616-4.526a.5.5 0 00-.373-.246.487.487 0 00-.421.15L16.9 12.816a.5.5 0 00.3.842l2.01.241a.25.25 0 01.206.332 8.1 8.1 0 01-5.672 5.206 7.625 7.625 0 01-7.481-2.374 1.251 1.251 0 00-1.872 1.659 10.143 10.143 0 009.929 3.149 10.673 10.673 0 007.669-7.426.249.249 0 01.27-.179l1.17.14a.4.4 0 00.059.004z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});