define("ember-svg-jar/inlined/single-woman-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-circle</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm5.451 19.037a.5.5 0 01-.451.285H7a.5.5 0 01-.388-.815A6.989 6.989 0 0112 15.822a6.975 6.975 0 015.385 2.685.5.5 0 01.066.53zm-.158-5.371a.75.75 0 01-1.26.813 4.915 4.915 0 01-.63-1.5.25.25 0 00-.42-.116 4.256 4.256 0 01-6.048 0 .25.25 0 00-.421.116 4.9 4.9 0 01-.631 1.5.75.75 0 01-1.26-.813 4.159 4.159 0 00.587-2.289v-2.01a4.75 4.75 0 119.5 0v2.01a4.168 4.168 0 00.583 2.289z\"/><path d=\"M15.654 9.809a.5.5 0 00-.4-.585 5.229 5.229 0 01-2.895-1.659.5.5 0 00-.809 0 5.233 5.233 0 01-2.891 1.659.5.5 0 10.189.981 6.171 6.171 0 002.943-1.488.248.248 0 01.334 0 6.175 6.175 0 002.943 1.488.5.5 0 00.586-.396z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});