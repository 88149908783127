define("ember-svg-jar/inlined/card-game-card-club", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>card-game-card-club</title><path d=\"M21.5 21a3 3 0 01-3 3h-13a3 3 0 01-3-3V3a3 3 0 013-3h13a3 3 0 013 3zM5.5 2a1 1 0 00-1 1v18a1 1 0 001 1h13a1 1 0 001-1V3a1 1 0 00-1-1z\"/><circle cx=\"7.25\" cy=\"4.75\" r=\"1.25\"/><circle cx=\"16.75\" cy=\"19.25\" r=\"1.25\"/><path d=\"M16 12.75a2.006 2.006 0 01-2 2h-1a.248.248 0 00-.25.25v1.25a.75.75 0 01-1.5 0V15a.248.248 0 00-.25-.25h-1a2 2 0 010-4 .66.66 0 01.14.01c.04 0 .09-.08.07-.13a1.845 1.845 0 01-.21-.88 2 2 0 014 0 1.914 1.914 0 01-.21.89.1.1 0 00.08.12.528.528 0 01.13-.01 2.006 2.006 0 012 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});