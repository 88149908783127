define("ember-svg-jar/inlined/car-retro-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-retro-1</title><path d=\"M23.25 15.875a.079.079 0 01-.079-.071 62.078 62.078 0 00-.458-3.225 9.479 9.479 0 00-9.375-7.954c-6.763 0-9.116 5.8-9.533 6.872a.249.249 0 01-.209.158C0 12 0 15.21 0 16.625a.75.75 0 00.75.75h.76a.25.25 0 00.249-.231 3 3 0 015.982 0 .251.251 0 00.249.231h7.52a.25.25 0 00.249-.231 3 3 0 015.982 0 .251.251 0 00.249.231h1.26a.75.75 0 000-1.5zm-10.5-5.25a.249.249 0 01-.25.25H7.581a.5.5 0 01-.431-.754 6.11 6.11 0 015.34-3.249.256.256 0 01.184.07.249.249 0 01.076.18zm6.091-.008a.5.5 0 01-.437.258H15a.25.25 0 01-.25-.25v-3.3a.252.252 0 01.1-.2.249.249 0 01.221-.039 7.044 7.044 0 013.754 3.023.5.5 0 01.016.508z\"/><circle cx=\"4.75\" cy=\"17.375\" r=\"2\"/><circle cx=\"18.75\" cy=\"17.375\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});