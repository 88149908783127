define("ember-svg-jar/inlined/wild-bird-fly-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wild-bird-fly-1</title><path d=\"M8.888 7.972q.121-.249.248-.489C9.611 1.738 5.359.065 5.31.047a.5.5 0 00-.6.211 8.573 8.573 0 00-.364 7 6.735 6.735 0 014.542.714z\"/><path d=\"M18.431 21.065a17.979 17.979 0 00-3.331-6.472c-.133-.165-1.354-1.7-1.491-1.634 1.226-.627 4.293-2.288 5.65-3.869a30.825 30.825 0 004.58-7.128.5.5 0 00-.46-.7c-7.1 0-11.368 2.7-13.752 8.742a19.583 19.583 0 00-2.27-1.044 4.433 4.433 0 00-4.535.648 3 3 0 00-2.564 1.215.761.761 0 00.15 1.04.767.767 0 001.052-.144A1.6 1.6 0 012.5 11.1c3.581 6.2 9.167 10.26 14.562 10.636.126.492.322 1.6.328 1.637a.75.75 0 001.484-.216c-.005-.035-.262-1.437-.443-2.092z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});