define("ember-svg-jar/inlined/spa-stone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>spa-stone</title><path d=\"M14 17.5c-3.853 0-7.122-1.352-8.8-3.377A.5.5 0 004.587 14C2.678 15 1.5 16.409 1.5 18c0 3.084 4.393 5.5 10 5.5s10-2.416 10-5.5a3.416 3.416 0 00-.5-1.757.5.5 0 00-.663-.187A13.682 13.682 0 0114 17.5z\"/><path d=\"M14 16c4.767 0 8.5-2.2 8.5-5a3.633 3.633 0 00-1.192-2.581.5.5 0 00-.721.017A8.92 8.92 0 0114 11a8.92 8.92 0 01-6.587-2.564.5.5 0 00-.721-.017A3.633 3.633 0 005.5 11c0 2.8 3.733 5 8.5 5z\"/><ellipse cx=\"14\" cy=\"5\" rx=\"6.5\" ry=\"4.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});