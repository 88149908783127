define("ember-svg-jar/inlined/halloween-calendar-ghost", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>halloween-calendar-ghost</title><path d=\"M16.341 15.773L16.5 14.5a4.5 4.5 0 00-9 0l.159 1.273A7.785 7.785 0 017 20.08a.5.5 0 00.645.663l.864-.346a1 1 0 01.926.1l.507.338a1 1 0 001.11 0l.39-.26a1 1 0 011.11 0l.39.26a1 1 0 001.11 0l.507-.338a1 1 0 01.926-.1l.864.346A.5.5 0 0017 20.08a7.785 7.785 0 01-.659-4.307zm-6.091-.523a1 1 0 111-1 1 1 0 01-1 1zm3.5 0a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M22 3h-3a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v4.75a.75.75 0 01-1.5 0V3.5a.5.5 0 00-.5-.5h-6a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v4.75a.75.75 0 01-1.5 0V3.5A.5.5 0 004 3H2a2 2 0 00-2 2v17a2 2 0 002 2h20a2 2 0 002-2V5a2 2 0 00-2-2zm0 18.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});