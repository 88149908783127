define("ember-svg-jar/inlined/messages-people-man-bubble-square-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-man-bubble-square-alternate</title><path d=\"M10.5 17.663V16.28a3.072 3.072 0 00.991-2.43 1.826 1.826 0 00.655-1.5 1.612 1.612 0 00-.22-.859 1.092 1.092 0 00-.245-.276 3.7 3.7 0 00.3-2.338C11.638 7.492 9.891 7 8.5 7c-1.239 0-2.761.39-3.323 1.456a1.152 1.152 0 00-1.02.382c-.511.626-.1 1.654.2 2.367a1.093 1.093 0 00-.268.289 1.611 1.611 0 00-.229.875 1.807 1.807 0 00.65 1.481 3.072 3.072 0 00.99 2.43v1.383C3.624 18.427 0 20.03 0 21v2.5a.5.5 0 00.5.5h6.231l1.025-4.444a.25.25 0 01.488 0L9.269 24H15.5a.5.5 0 00.5-.5V21c0-.97-3.624-2.573-5.5-3.337z\"/><path d=\"M22 0H9a2 2 0 00-2 2v3a1 1 0 002 0V2.25A.25.25 0 019.25 2h12.5a.25.25 0 01.25.25v6.5a.25.25 0 01-.25.25H19.5a1.006 1.006 0 00-.665.252L16.416 11.4a.25.25 0 01-.416-.184V10a1 1 0 00-1-1h-1a1 1 0 000 2v3a1 1 0 001.665.748L19.88 11H22a2 2 0 002-2V2a2 2 0 00-2-2z\"/><circle cx=\"12.502\" cy=\"5.75\" r=\"1\"/><circle cx=\"15.752\" cy=\"5.75\" r=\"1\"/><circle cx=\"19.003\" cy=\"5.75\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});