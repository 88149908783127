define("ember-svg-jar/inlined/money-bags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>money-bags</title><path d=\"M6.2 10.209a.22.22 0 00-.042-.3.06.06 0 00-.034-.011H3.133a.251.251 0 00-.167.064A6.934 6.934 0 00.5 15.144a4 4 0 004 4c.311 0 .6-.013.869-.037a.246.246 0 00.2-.139.249.249 0 00-.014-.245A6.609 6.609 0 014.5 15.144a8.185 8.185 0 011.7-4.935zM6.237 6.978a.7.7 0 00-.744-1.033 5.067 5.067 0 01-1.986 0 .7.7 0 00-.744 1.033l.737 1.29a.251.251 0 00.217.126h1.567a.251.251 0 00.216-.126zM21.034 9.958a.251.251 0 00-.167-.064h-2.924a.246.246 0 00-.154.053l-.021.016a.125.125 0 00-.022.173 8.21 8.21 0 011.754 5.008 6.609 6.609 0 01-1.057 3.579.25.25 0 00.188.384c.269.024.558.037.869.037a4 4 0 004-4 6.934 6.934 0 00-2.466-5.186zM21.237 6.978a.7.7 0 00-.744-1.033 5.067 5.067 0 01-1.986 0 .7.7 0 00-.744 1.033l.736 1.29a.251.251 0 00.217.126h1.568a.251.251 0 00.217-.126zM9.306 7.256a.251.251 0 00.224.138h4.94a.251.251 0 00.224-.138l.829-1.656a1 1 0 00-1.391-1.316.814.814 0 01-.408.109.823.823 0 01-.661-.33l-.208-.277a1.068 1.068 0 00-1.71 0l-.208.277a.823.823 0 01-.661.33.814.814 0 01-.408-.109A1 1 0 008.477 5.6z\"/><path d=\"M18 15.144c0-2.8-1.74-4.817-3.6-6.2a.254.254 0 00-.15-.049H9.746a.254.254 0 00-.15.049c-1.856 1.384-3.6 3.4-3.6 6.2a5.767 5.767 0 006 5.5 5.767 5.767 0 006.004-5.5zm-5.062 3.382a.25.25 0 00-.188.243v.158a.75.75 0 01-1.5 0v-.083a.25.25 0 00-.25-.25h-.3a.75.75 0 010-1.5h1.762a.414.414 0 00.413-.413.41.41 0 00-.26-.383l-1.788-.716a1.908 1.908 0 01.235-3.621.249.249 0 00.188-.242v-.159a.75.75 0 011.5 0v.084a.25.25 0 00.25.25h.3a.75.75 0 010 1.5h-1.762a.413.413 0 00-.154.8l1.789.715a1.908 1.908 0 01-.235 3.621z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});