define("ember-svg-jar/inlined/underwear-corset-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>underwear-corset-1</title><path d=\"M15.746 24a.5.5 0 00.467-.678c-2.383-6.246-1.621-9.3-.817-12.526a24.256 24.256 0 00.819-4.646.5.5 0 00-.6-.521 5.966 5.966 0 00-3.25 1.858.5.5 0 01-.74 0 5.965 5.965 0 00-3.25-1.858.5.5 0 00-.6.521A24.252 24.252 0 008.6 10.8c.8 3.223 1.565 6.28-.818 12.526A.5.5 0 008.25 24z\"/><path d=\"M22.557 6.35A6.012 6.012 0 0018.73.389 5.806 5.806 0 0012 2.273 5.8 5.8 0 005.266.39a6.043 6.043 0 00-3.822 6.054 6.432 6.432 0 001.045 3.191c2 3.17 3.778 7.387-.258 11.9a1.5 1.5 0 00-.324 1.415A1.428 1.428 0 003.188 24h2.374a.5.5 0 00.462-.31c2.643-6.427 1.937-9.262 1.121-12.531a25.313 25.313 0 01-.866-4.983.5.5 0 00-.623-.457 6.312 6.312 0 00-1.841.781.25.25 0 01-.385-.2 4.03 4.03 0 012.519-4.031A3.874 3.874 0 019.1 2.4a4.074 4.074 0 011.962 2.274 1 1 0 001.88 0A4.074 4.074 0 0114.9 2.4a3.876 3.876 0 013.146-.128 4.026 4.026 0 012.52 4.037.249.249 0 01-.384.2 6.3 6.3 0 00-1.842-.786.5.5 0 00-.623.457 25.167 25.167 0 01-.866 4.983c-.815 3.269-1.521 6.1 1.121 12.531a.5.5 0 00.463.31h2.373a1.426 1.426 0 001.281-1.049 1.5 1.5 0 00-.325-1.417c-4.035-4.512-2.256-8.728-.256-11.9a6.143 6.143 0 001.049-3.288z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});