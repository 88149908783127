define("ember-svg-jar/inlined/single-neutral-actions-share-1.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-share-1</title><path d=\"M11 15.193h2.344a.5.5 0 00.44-.738A7.525 7.525 0 007.206 10.5a7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h8.31a.5.5 0 00.44-.261A1.989 1.989 0 0111 15.193z\"/><circle cx=\"7\" cy=\"4.75\" r=\"4.75\"/><path d=\"M11.5 16.387a1 1 0 00-1 1V23a1 1 0 002 0v-5.613a1 1 0 00-1-1zM23 20.193h-7a.354.354 0 01-.323-.386.372.372 0 01.371-.371H18a.888.888 0 00.815-1.237 1.906 1.906 0 00-1.814-1.006l-3-.032a.544.544 0 00-.357.145.5.5 0 00-.148.355v5.032a.5.5 0 00.5.5h8a2.04 2.04 0 002-1.924v-.2a.971.971 0 00-.996-.876z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});