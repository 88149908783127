define("ember-svg-jar/inlined/decoration-flowers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>decoration-flowers</title><path d=\"M14.809 14.662A1 1 0 0014 14.25h-4a1 1 0 00-.809.412l-3.556 3.715a3.322 3.322 0 00-.162 3.662A3.525 3.525 0 008.5 23.75h7a3.525 3.525 0 003.023-1.711 3.322 3.322 0 00-.162-3.662zM18.128 3.826C12.564 6.051 12.5 12 12.5 12.254a1 1 0 001 1 1 1 0 001-1c0-.2.081-4.859 4.372-6.574a1 1 0 10-.744-1.857zM5.128 5.683C9.419 7.4 9.5 12.06 9.5 12.254a1 1 0 002 0c0-.252-.064-6.2-5.628-8.428a1 1 0 10-.744 1.857zM16.228 3.231c.5.069 1.189-1.3 1.265-1.844a1 1 0 00-1.981-.274c-.076.547.221 2.05.716 2.118z\"/><path d=\"M18.707 8.292c-.531-.151-2.061-.065-2.2.416s1.12 1.357 1.652 1.508a1 1 0 00.545-1.924zM21.939 5.619c-.551-.043-2.033.344-2.071.843s1.366 1.108 1.916 1.151a1 1 0 10.155-1.994zM23.289 1.042a1.576 1.576 0 00-2.153-.577C20.382.9 18.8 2.724 19.194 3.406s2.764.224 3.518-.211a1.576 1.576 0 00.577-2.153zM7.771 3.231c.5-.068.793-1.571.717-2.118a1 1 0 00-1.981.274c.076.547.769 1.913 1.264 1.844zM12 5.757c.5 0 1-1.447 1-2a1 1 0 10-2 0c0 .551.5 1.999 1 2zM5.838 10.216c.532-.151 1.788-1.027 1.652-1.508s-1.666-.567-2.2-.416a1 1 0 10.545 1.924zM2.061 5.619a1 1 0 10.155 1.994c.55-.043 1.955-.653 1.916-1.151s-1.52-.886-2.071-.843zM4.806 3.406C5.2 2.724 3.618.9 2.864.465A1.576 1.576 0 001.288 3.2c.754.43 3.124.888 3.518.206z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});