define("ember-svg-jar/inlined/information-desk-hi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>information-desk-hi</title><circle cx=\"6.544\" cy=\"8\" r=\"2.5\"/><path d=\"M23 0H13a1 1 0 00-1 1v7a1 1 0 001 1h2v1.913a.5.5 0 00.825.379L18.5 9H23a1 1 0 001-1V1a1 1 0 00-1-1zm-4.5 6.75a.5.5 0 01-1 0v-1.5H16v1.5a.5.5 0 01-1 0v-4.5a.5.5 0 011 0v2h1.5v-2a.5.5 0 011 0zm2.5 0a.5.5 0 01-1 0v-4.5a.5.5 0 011 0zM18.672 13.19a1.5 1.5 0 00-2.121 0l-.983.982a1.509 1.509 0 01-2.189-.072A4.881 4.881 0 009.5 12H4a4.005 4.005 0 00-4 4v5.5a1.25 1.25 0 002.5 0v-5.75a.5.5 0 011 0v7.75a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V15c.8 0 1.133.517 1.621 1.074a4.5 4.5 0 006.569.219l.982-.982a1.5 1.5 0 000-2.121z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});