define("ember-svg-jar/inlined/cupcake", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cupcake</title><path d=\"M8.446 18.517a.249.249 0 00-.227-.008 5.263 5.263 0 01-2.229.5 5.7 5.7 0 01-.944-.078.247.247 0 00-.228.079.253.253 0 00-.054.236l1.048 3.667A1.506 1.506 0 007.254 24h1.38a.5.5 0 00.5-.558l-.557-4.736a.246.246 0 00-.131-.189zM13.572 18.789a5.224 5.224 0 01-3.124.006.25.25 0 00-.323.268l.529 4.5a.5.5 0 00.5.442h1.718a.5.5 0 00.5-.442l.529-4.5a.25.25 0 00-.323-.268zM18.974 18.926a5.614 5.614 0 01-.964.082 5.257 5.257 0 01-2.211-.49.249.249 0 00-.227.008.253.253 0 00-.127.189l-.556 4.727a.5.5 0 00.5.558h1.379a1.507 1.507 0 001.443-1.088l1.049-3.671a.251.251 0 00-.283-.315zM16.905 8.478a14.907 14.907 0 00-.427-.163.5.5 0 00-.6.216 4.5 4.5 0 01-7.755 0 .5.5 0 00-.6-.216c-.142.052-.285.106-.427.163C4.44 9.6 2.49 11.594 2.49 14a3.341 3.341 0 003.5 3.5 3.747 3.747 0 002.822-1.275.25.25 0 01.376 0 3.695 3.695 0 005.624 0 .25.25 0 01.188-.077.246.246 0 01.188.085 3.746 3.746 0 002.822 1.275 3.341 3.341 0 003.5-3.5c0-2.414-1.95-4.408-4.605-5.53z\"/><path d=\"M12 9.25a3 3 0 001.039-5.814.3.3 0 01-.181-.373A2.253 2.253 0 0115 1.5.75.75 0 0015 0a3.755 3.755 0 00-3.683 3.046.5.5 0 01-.324.378A3 3 0 0012 9.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});