define("ember-svg-jar/inlined/laboratory-sample", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laboratory-sample</title><path d=\"M23.252 9.685a1 1 0 00-.4-1.356l-8.793-4.765a1 1 0 10-.954 1.758l.881.477a.5.5 0 01.2.678l-2.145 3.957a4 4 0 00.179 4.3.5.5 0 01.041.54l-1.6 2.945a1 1 0 101.759.953l1.6-2.945a.5.5 0 01.475-.261 4 4 0 003.7-2.2l2.145-3.957a.5.5 0 01.678-.2l.878.476a1 1 0 001.355-.4zM16.844.524a1 1 0 00.4 1.356l.881.477a.5.5 0 01.241.3.5.5 0 01-.039.381l-.239.44a.5.5 0 00.2.677l1.757.953a.5.5 0 00.678-.2l.238-.44a.5.5 0 01.678-.2l.878.476a1 1 0 00.953-1.758L18.2.122a1 1 0 00-1.356.402z\"/><path d=\"M20.982 13.951a1 1 0 10-.967 1.75C21.276 16.4 22 17.236 22 18c0 1.889-4.276 4-10 4S2 19.889 2 18c0-1.407 2.5-3.224 7.127-3.819a1 1 0 10-.254-1.981C3.482 12.89 0 15.168 0 18c0 3.421 5.159 6 12 6s12-2.579 12-6c0-1.537-1.072-2.975-3.018-4.049z\"/><circle cx=\"6.5\" cy=\"17.969\" r=\"1.5\"/><circle cx=\"15.5\" cy=\"19.469\" r=\"1.25\"/><circle cx=\"19\" cy=\"17.969\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});