define("ember-svg-jar/inlined/app-window-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-warning</title><path d=\"M18.806 12.522a1.449 1.449 0 00-2.561 0l-5.056 9.633a1.43 1.43 0 00.048 1.409 1.454 1.454 0 001.233.687h10.111a1.456 1.456 0 001.233-.686 1.428 1.428 0 00.047-1.409zm-2.031 3.729a.75.75 0 111.5 0v3a.75.75 0 01-1.5 0zm.75 6.25a1 1 0 111-1 1 1 0 01-1 .999z\"/><path d=\"M20.976-.249h-18a3 3 0 00-3 3v14.5a3 3 0 003 3h6a1 1 0 001-1 1 1 0 00-1-1h-6a1 1 0 01-1-1V5a.25.25 0 01.25-.25h19.5a.25.25 0 01.25.25v6.25a1 1 0 001 1 1 1 0 001-1v-8.5a3 3 0 00-3-2.999zm-16.654 3a.967.967 0 01-1.693 0 .971.971 0 01-.153-.5 1.01 1.01 0 011-1 .985.985 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5zm3.5 0a.967.967 0 01-1.693 0 .971.971 0 01-.153-.5.968.968 0 01.153-.5.967.967 0 011.693 0 .969.969 0 01.154.5.972.972 0 01-.154.5zm3.5 0a.967.967 0 01-1.693 0 .971.971 0 01-.153-.5.968.968 0 01.153-.5.967.967 0 011.693 0 .969.969 0 01.154.5.972.972 0 01-.154.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});