define("ember-svg-jar/inlined/style-three-pin-tent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-tent</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm5.5 15.25h-2.507a.5.5 0 01-.447-.276l-2.1-4.2a.521.521 0 00-.894 0l-2.1 4.2a.5.5 0 01-.447.276H6.5a.757.757 0 01-.633-1.15l5.073-7.975a.5.5 0 000-.536L9.867 3.9a.75.75 0 111.266-.8l.445.7a.5.5 0 00.844 0l.445-.7a.75.75 0 111.266.8L13.06 5.589a.5.5 0 000 .536l5.073 7.975a.757.757 0 01-.633 1.15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});