define("ember-svg-jar/inlined/computer-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>computer-shield</title><path d=\"M3.483 15.976a.248.248 0 00-.176-.146.245.245 0 00-.221.059A8.592 8.592 0 01.181 17.4a.288.288 0 00-.18.23.282.282 0 00.13.26l1.96 1.17a.25.25 0 01.095.328l-.745 1.472a.282.282 0 00.03.28.288.288 0 00.26.11 8.959 8.959 0 003.8-1.365.251.251 0 00.061-.371 14.628 14.628 0 01-2.109-3.538zM3.869 8.733c.414-.21.9-.434 1.456-.656a.252.252 0 00.157-.233l-.017-4.092H18.5v4.083a.25.25 0 00.157.232c.563.225 1.06.453 1.48.665a.245.245 0 00.243-.01.252.252 0 00.12-.212V3.7a1.959 1.959 0 00-1.956-1.947H5.461a1.96 1.96 0 00-1.948 1.958l-.006 4.8a.25.25 0 00.119.213.248.248 0 00.243.009zM17.8 18.127a2.475 2.475 0 01-1.314-.764.131.131 0 00-.2.008 7.423 7.423 0 01-4.229 2.854 7.5 7.5 0 01-4.3-2.853.147.147 0 00-.114-.058.146.146 0 00-.117.05 2.5 2.5 0 01-1.291.767.25.25 0 00-.141.394 9.428 9.428 0 005.777 3.7.975.975 0 00.194.02 1.009 1.009 0 00.2-.021 9.344 9.344 0 005.683-3.707.25.25 0 00-.146-.394z\"/><path d=\"M19.527 16.128l1.543-4.244a1.253 1.253 0 00-.519-1.491A16.752 16.752 0 0012 8a16.763 16.763 0 00-8.549 2.388 1.254 1.254 0 00-.52 1.491l1.543 4.244a1.251 1.251 0 002.109.406C7.258 15.775 8.858 14.5 12 14.5s4.743 1.271 5.417 2.028a1.262 1.262 0 00.937.42 1.227 1.227 0 00.233-.023 1.253 1.253 0 00.94-.797zM23.869 17.892a.282.282 0 00.13-.26.288.288 0 00-.18-.23 8.583 8.583 0 01-2.9-1.513.245.245 0 00-.221-.059.248.248 0 00-.176.146 14.661 14.661 0 01-2.108 3.54.251.251 0 00.061.371 8.959 8.959 0 003.8 1.365.288.288 0 00.26-.11.282.282 0 00.03-.28l-.745-1.472a.25.25 0 01.095-.328z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});