define("ember-svg-jar/inlined/monitor-heart-rate-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-heart-rate-1</title><path d=\"M2 19c.628 0 .985-.466 1.649-1.529C4.3 16.428 5.19 15 6.994 15s2.7 1.428 3.346 2.471C11 18.534 11.361 19 11.99 19s.987-.466 1.651-1.53c.651-1.042 1.544-2.47 3.347-2.47s2.7 1.428 3.351 2.47c.666 1.063 1.024 1.53 1.655 1.53s.989-.467 1.655-1.53c.1-.154.2-.316.309-.48a.244.244 0 00.042-.138v-6.625a.249.249 0 00-.25-.25h-5.269a1.507 1.507 0 01-1.441-1.085v-.008l-.73-2.228a.25.25 0 00-.48.014l-1.47 5.677v.011a1.485 1.485 0 01-1.384 1.14h-.077a1.5 1.5 0 01-1.424-1.043v-.012L9.592 7.481a.251.251 0 00-.446-.045l-1.3 2.072A1 1 0 017 9.977H.25a.249.249 0 00-.25.25v6.63A.251.251 0 00.041 17c.108.163.211.324.306.476C1.012 18.534 1.369 19 2 19z\"/><path d=\"M21.994 21c-1.8 0-2.7-1.428-3.35-2.47-.666-1.063-1.024-1.53-1.656-1.53s-.986.466-1.651 1.53C14.686 19.572 13.794 21 11.99 21s-2.69-1.428-3.346-2.47C7.979 17.466 7.623 17 6.994 17s-.986.466-1.65 1.529C4.693 19.572 3.8 21 2 21a2.908 2.908 0 01-1.613-.47.249.249 0 00-.387.21v.76A2.5 2.5 0 002.5 24h19a2.5 2.5 0 002.5-2.5v-.764a.25.25 0 00-.386-.21 2.912 2.912 0 01-1.62.474zM6.308 7.977a.249.249 0 00.212-.118L8.187 5.2l.013-.014a1.487 1.487 0 011.411-.8 1.507 1.507 0 011.345 1.04v.012l1.578 4.174a.249.249 0 00.475-.026l1.521-5.871v-.011a1.479 1.479 0 011.4-1.14h.01a1.473 1.473 0 011.489 1.084v.009L18.788 7.8a.25.25 0 00.238.173h4.724a.25.25 0 00.25-.25V2.5A2.5 2.5 0 0021.5 0h-19A2.5 2.5 0 000 2.5v5.227a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});