define("ember-svg-jar/inlined/army-symbol-aviation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-symbol-aviation</title><path d=\"M24 5.5a2 2 0 00-2-2H2a2 2 0 00-2 2v13a2 2 0 002 2h20a2 2 0 002-2zM22 18a.5.5 0 01-.5.5h-19A.5.5 0 012 18V6a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M4 11.5a3 3 0 003 3 9.565 9.565 0 003.622-1.524.25.25 0 01.378.215V16a1 1 0 002 0v-2.809a.25.25 0 01.378-.215A9.565 9.565 0 0017 14.5a3 3 0 000-6c-1.245 0-3.817 1.613-4.862 2.3a.251.251 0 01-.276 0C10.817 10.113 8.245 8.5 7 8.5a3 3 0 00-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});