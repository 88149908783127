define("ember-svg-jar/inlined/cleaning-vacuum-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cleaning-vacuum-2</title><path d=\"M20.412 20.041h-1.237a.5.5 0 01-.5-.5V4.948a4.948 4.948 0 00-9.9 0v6.433a4.376 4.376 0 01-.8 2.509.494.494 0 01-.406.213H2.6a2.476 2.476 0 00-2.476 2.474v.975a.494.494 0 00.72.44 3.844 3.844 0 011.756-.425 3.963 3.963 0 013.959 3.959 3.844 3.844 0 01-.425 1.754.494.494 0 00.441.72H12.5a2.476 2.476 0 002.474-2.474 7.429 7.429 0 00-4.759-6.921.245.245 0 01-.142-.142.249.249 0 01.009-.2 6.377 6.377 0 00.686-2.882V4.948a2.969 2.969 0 015.938 0v17.567A1.486 1.486 0 0018.186 24h5.2a.494.494 0 00.5-.5 3.468 3.468 0 00-3.474-3.459z\"/><circle cx=\"2.598\" cy=\"21.526\" r=\"2.474\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});