define("ember-svg-jar/inlined/flower-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flower-3</title><path d=\"M12 10.693a2.066 2.066 0 102.066 2.066A2.067 2.067 0 0012 10.693zM7.782 4.339c.133.036.266.077.4.12a8.141 8.141 0 015.186 4.785.519.519 0 00.253.284 3.643 3.643 0 011.361 1.187.516.516 0 00.837.019 6.953 6.953 0 001.346-4.172c0-3.417-2.316-6.2-5.164-6.2a5.113 5.113 0 00-4.553 3.269.517.517 0 00.334.708zM9.08 10.372a.513.513 0 00.348-.152 3.637 3.637 0 011.55-.927.517.517 0 00.277-.791A6.956 6.956 0 007.7 5.932c-3.25-1.056-6.61.289-7.489 3a5.11 5.11 0 001.7 5.338.51.51 0 00.411.129.517.517 0 00.366-.228c.075-.115.155-.229.237-.342a8.144 8.144 0 016.155-3.457zM10.212 21.718A8.142 8.142 0 018.829 14.8a.517.517 0 00-.038-.378 3.648 3.648 0 01-.4-1.76.516.516 0 00-.666-.508 6.958 6.958 0 00-3.545 2.582c-2.008 2.765-1.768 6.375.536 8.049a5.112 5.112 0 005.6.033.515.515 0 00.146-.769 11.004 11.004 0 01-.25-.331zM20.791 17.121a.512.512 0 00-.429-.03 9.61 9.61 0 01-.394.137 8.14 8.14 0 01-7.008-.823.517.517 0 00-.371-.081 3.639 3.639 0 01-1.8-.161.516.516 0 00-.688.477 6.952 6.952 0 001.363 4.167c2.008 2.764 5.516 3.651 7.82 1.978a5.112 5.112 0 001.763-5.318.513.513 0 00-.256-.346zM23.787 8.929a5.113 5.113 0 00-4.513-3.321.517.517 0 00-.57.537c.007.137.009.277.009.417a8.137 8.137 0 01-2.948 6.41.52.52 0 00-.192.329 3.624 3.624 0 01-.709 1.66.518.518 0 00.241.8 6.954 6.954 0 004.384-.009c3.25-1.052 5.178-4.115 4.298-6.823z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});