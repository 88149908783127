define("ember-svg-jar/inlined/love-it-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it-edit</title><path d=\"M5.812 2.813A4.575 4.575 0 019.806 4.1l1.531 1.4a1 1 0 001.348 0l1.561-1.43a4.545 4.545 0 013.966-1.257 4.5 4.5 0 013.329 2.42 4.12 4.12 0 01.231 3.249.25.25 0 00.2.329 4.022 4.022 0 011.333.429.253.253 0 00.21.011.248.248 0 00.145-.151 6.109 6.109 0 00-.335-4.768A6.587 6.587 0 0012.87 2.619l-.859.782-.828-.755A6.531 6.531 0 005.49.839 6.478 6.478 0 00.7 4.329a6.4 6.4 0 001.2 7.454L9 19.47a.25.25 0 00.433-.161l.067-2.128A.257.257 0 009.435 17l-6.1-6.609a4.42 4.42 0 01-.85-5.16 4.5 4.5 0 013.327-2.418zM12.738 19.3a.5.5 0 00-.834.216l-.886 3.1a.5.5 0 00.618.618l3.1-.886a.5.5 0 00.217-.834z\"/><path d=\"M18.771 12.479a.5.5 0 00-.707 0l-5.011 5.01a.5.5 0 000 .707l3 3a.5.5 0 00.707 0l5.011-5.01a.5.5 0 000-.707zM23.231 11.018a2.641 2.641 0 00-3.755.048.5.5 0 000 .705l3 3a.454.454 0 00.327.125.616.616 0 00.426-.171 2.621 2.621 0 000-3.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});