define("ember-svg-jar/inlined/outdoors-flame-lantern", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-flame-lantern</title><path d=\"M18.251 20.228a.253.253 0 01-.15-.152.257.257 0 01.022-.213 8.948 8.948 0 001.045-5.3 22.891 22.891 0 00-3.525-10.24.5.5 0 01-.094-.292V.5a.5.5 0 00-.5-.5h-6.3a.5.5 0 00-.5.5v3.481a.5.5 0 01-.1.3 21.8 21.8 0 00-3.574 10.151 9.072 9.072 0 001.087 5.434.251.251 0 01.024.212.257.257 0 01-.15.154A3.491 3.491 0 003.318 22.8 1 1 0 004.3 24h15.2a1 1 0 00.981-1.2 3.492 3.492 0 00-2.23-2.572zm-2.782-.328a.5.5 0 01-.3.1H8.627a.5.5 0 01-.3-.1c-1.2-.9-1.8-2.437-1.785-4.588a19.517 19.517 0 013.4-10.145.5.5 0 01.375-.167h3.106a.5.5 0 01.392.189 20.54 20.54 0 013.381 10.6 5.032 5.032 0 01-1.727 4.111zM23.929 18.712L21.192 4.59a2.982 2.982 0 00-2.926-2.34h-.518A.751.751 0 0017 3v.5a.751.751 0 00.75.75h.518a.989.989 0 01.968.75l2.739 14.126a.973.973 0 01-.4 1.029.753.753 0 00-.221.991c.072.124.139.25.2.382a.751.751 0 00.475.406.763.763 0 00.206.028.755.755 0 00.412-.123 2.984 2.984 0 001.282-3.127z\"/><path d=\"M2.03 19.1L4.756 5.029a.992.992 0 01.974-.779h.518A.75.75 0 007 3.5V3a.75.75 0 00-.75-.75h-.52A3 3 0 002.8 4.62L.073 18.686a3.005 3.005 0 001.278 3.153.758.758 0 00.618.1.746.746 0 00.476-.407c.06-.13.127-.257.2-.38a.746.746 0 00-.219-.991.994.994 0 01-.396-1.061zM15.5 16.5a6.434 6.434 0 00-3.827-5.8.25.25 0 00-.3.373 3.58 3.58 0 01.193 3.679 2.3 2.3 0 01-.817-1.037.25.25 0 00-.347-.15A3.15 3.15 0 008.5 16.5a3.5 3.5 0 007 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});