define("ember-svg-jar/inlined/beanie-winter-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beanie-winter-1</title><circle cx=\"12\" cy=\"2\" r=\"2\"/><rect x=\"6.5\" y=\"18\" width=\"4.5\" height=\"6\" rx=\".25\" ry=\".25\"/><rect x=\"12.5\" y=\"18\" width=\"5\" height=\"6\" rx=\".25\" ry=\".25\"/><path d=\"M5 18.25a.25.25 0 00-.25-.25H2.5A2.5 2.5 0 000 20.5v1A2.5 2.5 0 002.5 24h2.25a.25.25 0 00.25-.25zM21.5 18h-2.25a.25.25 0 00-.25.25v5.5a.25.25 0 00.25.25h2.25a2.5 2.5 0 002.5-2.5v-1a2.5 2.5 0 00-2.5-2.5zM12 5a.264.264 0 00-.173.065C9.251 7.284 9.25 11.57 9.25 13.043v3.207a.25.25 0 00.25.25h5a.249.249 0 00.25-.25v-3.207c0-1.473 0-5.758-2.576-7.978A.27.27 0 0012 5zM7.75 13.043a14.749 14.749 0 011.495-7.149.25.25 0 00-.3-.359A9.018 9.018 0 003 14v2a.5.5 0 00.5.5h4a.249.249 0 00.25-.25zM15.059 5.536a.25.25 0 00-.3.359 14.74 14.74 0 011.494 7.148v3.207a.25.25 0 00.25.25h4A.5.5 0 0021 16v-2a9.015 9.015 0 00-5.941-8.464z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});