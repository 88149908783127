define("ember-svg-jar/inlined/shapes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shapes</title><rect x=\".5\" y=\".5\" width=\"10\" height=\"10\" rx=\"1\" ry=\"1\"/><path d=\"M6.427 12.972a1.146 1.146 0 00-1.854 0l-3.166 4.354a1.143 1.143 0 000 1.348l3.166 4.354a1.146 1.146 0 001.854 0l3.166-4.354a1.143 1.143 0 000-1.348z\"/><circle cx=\"18.5\" cy=\"5.5\" r=\"5\"/><path d=\"M17.861 13.957a1 1 0 00-1.722 0l-4.747 8.034a1 1 0 00.86 1.509h9.5a1 1 0 00.861-1.509z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});