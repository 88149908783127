define("ember-svg-jar/inlined/road-sign-width-2-5m", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-width-2.5m</title><path d=\"M23.53 11.47l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0l11-11a.749.749 0 000-1.06zM12 11.25a.75.75 0 11-.75.75.75.75 0 01.75-.75zm-5.97 1.28l-1 1a.746.746 0 01-.53.22.75.75 0 01-.75-.75v-2a.75.75 0 011.28-.53l1 1a.749.749 0 010 1.06zm1.294-.205a.749.749 0 01.091-.794L9.1 9.422A.682.682 0 009.25 9c0-.275-.5-.275-.5 0a.75.75 0 01-1.5 0 1.75 1.75 0 013.5 0 2.175 2.175 0 01-.477 1.358l-.438.549a.208.208 0 00-.025.223.211.211 0 00.19.12.75.75 0 010 1.5H8a.75.75 0 01-.676-.425zM14.25 18.5a.75.75 0 01-1.5 0v-1.085a.058.058 0 00-.107-.029.78.78 0 01-1.286 0 .058.058 0 00-.107.029V18.5a.75.75 0 01-1.5 0v-4a.75.75 0 011.393-.386l.643 1.071a.25.25 0 00.428 0l.643-1.071a.75.75 0 011.393.386zM15 9.25a1.75 1.75 0 010 3.5h-1a.75.75 0 010-1.5h1a.25.25 0 000-.5h-.5a1.252 1.252 0 01-1.25-1.25V8a.75.75 0 01.75-.75h2a.75.75 0 010 1.5h-1a.25.25 0 000 .5zM20.25 13a.75.75 0 01-1.28.53l-1-1a.749.749 0 010-1.06l1-1a.75.75 0 011.28.53z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});