define("ember-svg-jar/inlined/mood-moody", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mood-moody</title><path d=\"M17.832 15.757a7.721 7.721 0 011.761-4.078 1.929 1.929 0 012.426-.609.249.249 0 00.255-.019.253.253 0 00.1-.233 10.761 10.761 0 00-9.547-9.459V1a1 1 0 00-2 0v.337a10.74 10.74 0 000 21.409V23a1 1 0 002 0v-.276a10.731 10.731 0 006.959-3.616.252.252 0 00-.072-.387 3.464 3.464 0 01-1.882-2.964zm.93-5.335a2.555 2.555 0 01-2.06 1.03 2.59 2.59 0 01-2.061-1.03.75.75 0 111.2-.9 1.109 1.109 0 001.723 0 .75.75 0 111.2.9zm-15.795 1.62a8.754 8.754 0 017.581-8.665.25.25 0 01.283.248v10.056a.252.252 0 00.269.25 7.007 7.007 0 015.5 2.057.75.75 0 01-1.06 1.061 5.538 5.538 0 00-4.478-1.61.25.25 0 00-.226.249v.661a5.324 5.324 0 01-3.013-1.512A1 1 0 006.4 16.251a7.359 7.359 0 004.211 2.082.249.249 0 01.216.248v1.877a.25.25 0 01-.283.248 8.754 8.754 0 01-7.577-8.664z\"/><path d=\"M9.222 10.573a1 1 0 10-1.6-1.2.851.851 0 01-1.323 0 1 1 0 00-1.6 1.2 2.828 2.828 0 004.522 0zM21.163 17.627a1.992 1.992 0 001.87-1.87 7.226 7.226 0 00-1.489-3.23.468.468 0 00-.762 0 7.226 7.226 0 00-1.489 3.23 1.992 1.992 0 001.87 1.87z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});