define("ember-svg-jar/inlined/add-circle-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>add-circle-1-alternate</title><path d=\"M6.223 18.115a.948.948 0 00-.346-.292 6.489 6.489 0 010-11.647.946.946 0 00.346-.291l.021-.03a.992.992 0 00-1.229-1.484 8.49 8.49 0 000 15.258.993.993 0 001.229-1.485z\"/><path d=\"M14.75 3a9 9 0 109 9 9.01 9.01 0 00-9-9zm0 16a7 7 0 117-7 7.008 7.008 0 01-7 7z\"/><path d=\"M18.515 10.75H16.25a.25.25 0 01-.25-.25V8.235a1.25 1.25 0 00-2.5 0V10.5a.25.25 0 01-.25.25h-2.265a1.25 1.25 0 000 2.5h2.265a.25.25 0 01.25.25v2.264a1.25 1.25 0 002.5 0V13.5a.25.25 0 01.25-.25h2.265a1.25 1.25 0 000-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});