define("ember-svg-jar/inlined/e-commerce-apparel-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-apparel-laptop</title><path d=\"M22.018 17.449a.243.243 0 01-.018-.092V14a1 1 0 00-2 0v2.75a.25.25 0 01-.25.25H4.25a.25.25 0 01-.25-.25v-10a.25.25 0 01.25-.25h.587a.251.251 0 00.245-.2 3.822 3.822 0 01.611-1.409.25.25 0 00-.2-.393H4a2 2 0 00-2 2v10.859a.243.243 0 01-.018.092L.1 22.167A1.338 1.338 0 001.339 24h21.322a1.337 1.337 0 001.239-1.835zM10 21.5a.5.5 0 01.5-.5h3a.5.5 0 010 1h-3a.5.5 0 01-.5-.5z\"/><path d=\"M23 0h-2.551a.5.5 0 00-.489.4A2 2 0 0118 2 2 2 0 0116.04.4a.5.5 0 00-.489-.4H13a1 1 0 00-1 1v1a1 1 0 001 1h.75a.25.25 0 01.25.25V10a1 1 0 001 1h6a1 1 0 001-1V3.25a.25.25 0 01.25-.25H23a1 1 0 001-1V1a1 1 0 00-1-1zM10.777 8.234l-2.062-.825a.342.342 0 01.127-.659h2.033a1 1 0 000-2h-.25a.25.25 0 01-.25-.25 1 1 0 00-2 0v.1a.251.251 0 01-.181.24 2.342 2.342 0 00-.221 4.425l2.062.825a.342.342 0 01-.127.659H7.875a1 1 0 000 2h.25a.25.25 0 01.25.25 1 1 0 002 0v-.1a.251.251 0 01.181-.24 2.342 2.342 0 00.221-4.425z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});