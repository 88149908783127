define("ember-svg-jar/inlined/road-sign-lane-crossing-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-lane-crossing-left</title><path d=\"M23.53 12.53a.749.749 0 000-1.06l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0zm-13.914-.853a1.244 1.244 0 01-.366-.884V9.5A.25.25 0 009 9.25h-.5a.75.75 0 01-.53-1.28l1.5-1.5a.749.749 0 011.06 0l1.5 1.5a.75.75 0 01-.53 1.28H11a.25.25 0 00-.25.25v1.086a.251.251 0 00.073.177l3.561 3.56a1.258 1.258 0 01.366.884v2.043a.75.75 0 01-1.5 0v-1.836a.251.251 0 00-.073-.177zM10.75 15v2.5a.75.75 0 01-1.5 0V15a.75.75 0 011.5 0zm4-4.5a.75.75 0 01-1.5 0V7a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});