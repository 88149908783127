define("ember-svg-jar/inlined/arrow-thick-left-bottom-corner-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-left bottom-corner-1</title><path d=\"M23.476 4.056L19.94.52a1.023 1.023 0 00-1.414 0L7.659 11.392a.25.25 0 01-.426-.177V4.776a1 1 0 00-1-1h-5a1 1 0 00-1 1v17.987a1 1 0 001 1h18a1 1 0 001-1v-5a1 1 0 00-1-1H12.79a.25.25 0 01-.177-.427L23.477 5.469a1 1 0 00-.001-1.413z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});