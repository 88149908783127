define("ember-svg-jar/inlined/circus-tents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>circus-tents</title><path d=\"M7.391 10.654l5.762-3.365a.5.5 0 00.186-.673 1.288 1.288 0 00-.476-.488L7.62 3.065a.247.247 0 01-.12-.241.25.25 0 01.169-.212l2.1-.7a.557.557 0 000-1.009L7.051.019c-.278-.091-.551.16-.551.5v1.9a.5.5 0 01-.248.431L.637 6.128A1.286 1.286 0 000 7.238v.226c0 .7.629 1.292 1.5 1.511a.5.5 0 01.377.509 25.3 25.3 0 01-.788 5.606.322.322 0 00.31.41h4.666a.25.25 0 00.216-.376 3.189 3.189 0 01-.418-1.794v-.035a3.094 3.094 0 011.528-2.641zM22.272 17.513a.5.5 0 00-.544-.459 5.492 5.492 0 01-.5.023 4.978 4.978 0 01-2.52-.652.5.5 0 00-.506 0 5.2 5.2 0 01-5.04 0 .5.5 0 00-.506 0 4.978 4.978 0 01-2.52.652c-.169 0-.336-.008-.5-.023a.5.5 0 00-.544.459 28.019 28.019 0 01-.862 5.344A.9.9 0 009.091 24h4.146a.5.5 0 00.5-.5v-1.445a2.02 2.02 0 011.744-2.045 1.945 1.945 0 012.145 1.934V23.5a.5.5 0 00.5.5h4.146a.9.9 0 00.862-1.142 27.967 27.967 0 01-.862-5.345z\"/><path d=\"M23.215 11.95l-6.472-3.781a.247.247 0 01-.123-.235.25.25 0 01.158-.213l2.365-.928a.8.8 0 000-1.331l-3.029-1.171c-.309-.12-.614.211-.614.666v2.411a.5.5 0 01-.248.432l-7.1 4.15a1.581 1.581 0 00-.786 1.368v.282c0 1.093 1.242 1.98 2.773 1.98a2.949 2.949 0 002.555-1.209.247.247 0 01.436 0 2.949 2.949 0 002.555 1.209 2.949 2.949 0 002.555-1.209.247.247 0 01.436 0 2.949 2.949 0 002.555 1.209c1.532 0 2.773-.887 2.773-1.98v-.279a1.582 1.582 0 00-.789-1.371z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});