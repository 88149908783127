define("ember-svg-jar/inlined/integrations-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>integrations-icon</title><g fill-rule=\"nonzero\" fill=\"none\"><path d=\"M0 25C0 0 0 0 25 0s25 0 25 25 0 25-25 25S0 50 0 25\" fill=\"#4137AC\"/><path d=\"M28.3 21.6H12.78l3.21-3.21a1.398 1.398 0 000-1.98 1.398 1.398 0 00-1.98 0l-5.6 5.599a1.405 1.405 0 000 1.98l5.6 5.6a1.398 1.398 0 001.98 0 1.398 1.398 0 000-1.979l-3.21-3.21H28.3a1.4 1.4 0 000-2.8z\" fill=\"#877EE6\"/><path d=\"M21.303 27.2h15.52l-3.21-3.21a1.398 1.398 0 010-1.98 1.398 1.398 0 011.98 0l5.6 5.599a1.405 1.405 0 010 1.98l-5.6 5.6a1.398 1.398 0 01-1.98 0 1.398 1.398 0 010-1.979l3.21-3.21h-15.52a1.4 1.4 0 010-2.8z\" fill=\"#C2BCFF\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});