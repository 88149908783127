define("ember-svg-jar/inlined/camera-professional", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camera-professional</title><path d=\"M24 11.308a1.037 1.037 0 00-1.438-.958l-2.908 1.211a.251.251 0 00-.154.231v5.416a.251.251 0 00.154.231l2.908 1.211a1.011 1.011 0 00.4.081A1.04 1.04 0 0024 17.692z\"/><circle cx=\"6\" cy=\"15\" r=\"1\"/><circle cx=\"12.5\" cy=\"15\" r=\"1\"/><path d=\"M9.5 8.75a.25.25 0 01-.25.25H2.5A1.5 1.5 0 001 10.5v8A1.5 1.5 0 002.5 20h14a1.5 1.5 0 001.5-1.5v-8a1.5 1.5 0 00-1.286-1.485.249.249 0 01-.214-.247V7a.5.5 0 01.5-.5h2.277a.25.25 0 01.174.071A1.5 1.5 0 0020.5 7h2a1.5 1.5 0 000-3h-2a1.5 1.5 0 00-1.049.429.25.25 0 01-.174.071H16.5a2 2 0 00-2 2v2.25a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25V7.5a2 2 0 00-2-2H1a1 1 0 000 2h8a.5.5 0 01.5.5zM10 15a2.468 2.468 0 01.1-.681.254.254 0 00-.041-.22.251.251 0 00-.2-.1H8.646a.251.251 0 00-.2.1.254.254 0 00-.041.22A2.468 2.468 0 018.5 15 2.5 2.5 0 116 12.5h6.5A2.5 2.5 0 1110 15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});