define("ember-svg-jar/inlined/protection-sand-bag-soldier-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>protection-sand-bag-soldier-1</title><path d=\"M15.44 6.1a.5.5 0 00-.491-.6h-4.9a.5.5 0 00-.49.6 3 3 0 005.88 0zM9.036 4h6.927a.5.5 0 00.5-.571 4 4 0 00-7.919 0 .5.5 0 00.5.571zM23.911 22.59l-1.867-4.1a.511.511 0 01-.044-.207v-5.27a2.844 2.844 0 00-.453-1.758.251.251 0 01-.046-.146V7.5a.75.75 0 00-.75-.75h-1.5a.75.75 0 000 1.5h.5A.25.25 0 0120 8.5v1.793a.249.249 0 01-.167.235.5.5 0 00-.333.472v12a1 1 0 001 1H23a1 1 0 00.912-1.41zM5.25 20.5h-3.5a1.75 1.75 0 000 3.5h3.5a1.75 1.75 0 000-3.5zM13.75 20.5h-3.5a1.75 1.75 0 000 3.5h3.5a1.75 1.75 0 000-3.5z\"/><path d=\"M12.5 8.5A4.5 4.5 0 008 13v.75a.25.25 0 00.25.25h1.5A3.254 3.254 0 0113 17.25a3.168 3.168 0 01-.189 1.08.5.5 0 00.47.67h.469a2.962 2.962 0 012.772 1.56.25.25 0 00.469-.121L17 13a4.5 4.5 0 00-4.5-4.5z\"/><path d=\"M6.25 19h3.5a1.75 1.75 0 000-3.5h-3.5a1.75 1.75 0 000 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});