define("ember-svg-jar/inlined/arrow-double-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-double-left</title><path d=\"M7.715 12.141a.25.25 0 010-.343l4.846-5.11A1 1 0 0011.836 5H7.081a1 1 0 00-.725.312L.7 11.281a1 1 0 000 1.371l5.66 6.031a1 1 0 00.73.317h4.762a1 1 0 00.729-1.684z\"/><path d=\"M23.306 17.316l-4.857-5.175a.25.25 0 010-.343l4.844-5.11A1 1 0 0022.57 5h-4.755a1 1 0 00-.725.312l-5.662 5.969a1 1 0 000 1.371l5.662 6.034a1.006 1.006 0 00.73.314h4.761a1 1 0 00.728-1.684z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});