define("ember-svg-jar/inlined/garbage-bin-throw-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>garbage-bin-throw-1</title><path d=\"M7.8 14.51a2.145 2.145 0 01-.086-.746.25.25 0 00-.25-.264H2.406a.25.25 0 01-.246-.295l1.159-6.374a1 1 0 10-1.967-.357L.016 13.821A.99.99 0 00.5 14.855v4.5a.249.249 0 00.1.2.252.252 0 00.219.041 2.437 2.437 0 01.681-.1 2.5 2.5 0 012.5 2.5 2.449 2.449 0 01-.284 1.14.246.246 0 00.007.245.249.249 0 00.214.121h1.342A1.5 1.5 0 006.7 22.475l2.144-6.433a.249.249 0 00-.1-.289A2.29 2.29 0 017.8 14.51z\"/><path d=\"M3 21.994a1.5 1.5 0 10-1.5 1.5 1.5 1.5 0 001.5-1.5z\"/><circle cx=\"6\" cy=\"10.25\" r=\"1.75\"/><circle cx=\"17.5\" cy=\"3\" r=\"2.5\"/><path d=\"M20 6.89h-5a4 4 0 00-3.99 3.76 2.06 2.06 0 01-1.42 2.01 1.249 1.249 0 00.73 2.39 4.507 4.507 0 003.18-4.41.5.5 0 011 0v11.61a1.25 1.25 0 002.5 0V16a.5.5 0 011 0v6.25a1.25 1.25 0 002.5 0V10.64a.5.5 0 011 0v3.86a1.25 1.25 0 002.5 0v-3.61a4.005 4.005 0 00-4-4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});