define("ember-svg-jar/inlined/single-neutral-actions-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-heart</title><path d=\"M0 17.25a.5.5 0 00.5.5h1.81a.249.249 0 01.249.229L3 23.291a.5.5 0 00.5.459h4a.5.5 0 00.5-.459l.443-5.312a.249.249 0 01.249-.229h.858a.249.249 0 00.236-.332 5.017 5.017 0 01.246-3.889 4.954 4.954 0 01.7-1.04.248.248 0 00.053-.232A5.5 5.5 0 000 13.75z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M12.023 18.26l5.116 5.34a.5.5 0 00.721 0l5.105-5.325a3.522 3.522 0 00.659-4.068 3.526 3.526 0 00-5.646-.916l-.305.3a.25.25 0 01-.353 0l-.3-.3a3.525 3.525 0 00-5.646.916 3.519 3.519 0 00.649 4.053zM0 17.25a.5.5 0 00.5.5h1.81a.249.249 0 01.249.229L3 23.291a.5.5 0 00.5.459h4a.5.5 0 00.5-.459l.443-5.312a.249.249 0 01.249-.229h.858a.249.249 0 00.236-.332 5.017 5.017 0 01.246-3.889 4.954 4.954 0 01.7-1.04.248.248 0 00.053-.232A5.5 5.5 0 000 13.75z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M12.023 18.26l5.116 5.34a.5.5 0 00.721 0l5.105-5.325a3.522 3.522 0 00.659-4.068 3.526 3.526 0 00-5.646-.916l-.305.3a.25.25 0 01-.353 0l-.3-.3a3.525 3.525 0 00-5.646.916 3.519 3.519 0 00.649 4.053z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});