define("ember-svg-jar/inlined/data-file-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-refresh</title><path d=\"M21.125.586A2 2 0 0019.71 0H8.125a2 2 0 00-2 2v7.8a.245.245 0 00.23.249c.348.02 1.014.071 1.486.144a.246.246 0 00.284-.247V2.5a.5.5 0 01.5-.5H19.5a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-7.4a.242.242 0 00-.238.2 7.185 7.185 0 01-.409 1.454.244.244 0 00.225.34h8.318a2 2 0 002-2V4.414A2 2 0 0023.539 3z\"/><path d=\"M11.287 10.5a1.992 1.992 0 012.081-.344 1.11 1.11 0 01.152.083.158.158 0 00.228-.027l.325-.458A.252.252 0 0114.4 9.7l1.5.9a1 1 0 001.221-.151.9.9 0 00.105-.123l2.688-3.734a1 1 0 10-1.624-1.166l-2.015 2.8a.252.252 0 01-.332.068l-1.516-.908a1 1 0 00-1.329.279l-1.849 2.611c-.21.261.004.248.038.224zM6.25 24a6.344 6.344 0 004.982-2.4 1 1 0 10-1.562-1.246 4.376 4.376 0 11-3.42-7.1 4.336 4.336 0 012.924 1.122.256.256 0 01.005.367l-1.386 1.382a.5.5 0 00.354.854H12.6a.5.5 0 00.5-.5v-4.458a.5.5 0 00-.854-.353l-1.3 1.3a.247.247 0 01-.343 0 6.312 6.312 0 00-4.078-1.716 6.465 6.465 0 00-6.652 6.29A6.382 6.382 0 006.25 24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});