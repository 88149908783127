define("ember-svg-jar/inlined/smart-house-open-door", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-house-open-door</title><path d=\"M12.56 12.4a1 1 0 00-1 1v5.75a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25V5.712a1.493 1.493 0 00-.829-1.341l-.986-.494a.249.249 0 01.111-.477h4.7a1 1 0 000-2h-10a1.012 1.012 0 00-1 1v17.7a1.5 1.5 0 00.829 1.342l5 2.5a1.514 1.514 0 00.669.158 1.5 1.5 0 001.5-1.5v-.941a.25.25 0 01.25-.25h3.25a1.5 1.5 0 001.5-1.5V13.4a1 1 0 00-.994-1zM14.56 1.4a1.5 1.5 0 001.5 1.5h3a1.5 1.5 0 001.5-1.5v-1a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5zM14.157 6.348a4.859 4.859 0 006.873 0 1 1 0 10-1.414-1.414 2.928 2.928 0 01-4.045 0 1 1 0 00-1.414 1.414z\"/><path d=\"M21.733 7.817a5.906 5.906 0 01-8.341 0 1 1 0 00-1.414 1.414 7.908 7.908 0 0011.169 0 1 1 0 00-1.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});