define("ember-svg-jar/inlined/road-sign-lane-crossing-left-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-lane-crossing-left_1</title><path d=\"M23.78 12.53a.749.749 0 000-1.06L12.53.22a.749.749 0 00-1.06 0L.22 11.47a.749.749 0 000 1.06l11.25 11.25a.748.748 0 001.06 0zM9.708 11.15a.5.5 0 01-.2-.4v-3a.25.25 0 00-.25-.25h-.147a.25.25 0 01-.176-.427l1.219-1.219a.5.5 0 01.707 0l1.22 1.219a.25.25 0 01-.181.427h-.146a.25.25 0 00-.25.25v2a.5.5 0 00.2.4l3.6 2.7a.5.5 0 01.2.4V17a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-2.75a.5.5 0 00-.2-.4zm.8 4.1V17a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-2.5a.5.5 0 01.8-.4l1 .75a.5.5 0 01.2.4zm6-5.75a.5.5 0 01-.8.4l-1-.75a.5.5 0 01-.2-.4V8a.5.5 0 01.5-.5h1a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});