define("ember-svg-jar/inlined/lucide-recycle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7 19H4.815a1.83 1.83 0 01-1.57-.881 1.785 1.785 0 01-.004-1.784L7.196 9.5M11 19h8.203a1.83 1.83 0 001.556-.89 1.784 1.784 0 000-1.775l-1.226-2.12\"/><path d=\"M14 16l-3 3 3 3M8.293 13.596L7.196 9.5 3.1 10.598M9.344 5.811l1.093-1.892A1.83 1.83 0 0111.985 3a1.784 1.784 0 011.546.888l3.943 6.843\"/><path d=\"M13.378 9.633l4.096 1.098 1.097-4.096\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});