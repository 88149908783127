define("ember-svg-jar/inlined/light-mode-bright-dark-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-mode-bright-dark-2</title><path d=\"M21.25 2H2.75A2.752 2.752 0 000 4.75v14.5A2.752 2.752 0 002.75 22h18.5A2.752 2.752 0 0024 19.25V4.75A2.752 2.752 0 0021.25 2zM5.623 12.723a1 1 0 010-1.414l1.3-1.3A.251.251 0 007 9.828V8a1 1 0 011-1h1.812a.249.249 0 00.177-.073l1.3-1.3a1 1 0 011.414 0l1.3 1.3a.249.249 0 00.185.073H16a1 1 0 011 1v1.828a.251.251 0 00.073.177l1.3 1.3a1 1 0 010 1.414l-1.3 1.3A.251.251 0 0017 14.2V16a1 1 0 01-1 1h-1.813a.249.249 0 00-.177.073l-1.3 1.3a1 1 0 01-1.414 0l-1.3-1.3A.249.249 0 009.812 17H8a1 1 0 01-1-1v-1.8a.251.251 0 00-.073-.177z\"/><path d=\"M15.5 12a3.5 3.5 0 00-3.231-3.49.25.25 0 00-.269.25v6.481a.25.25 0 00.08.183.253.253 0 00.189.066A3.5 3.5 0 0015.5 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});