define("ember-svg-jar/inlined/server-warning-500", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-warning-500</title><path d=\"M13.033.771a1.45 1.45 0 00-2.562 0l-5.056 9.634a1.431 1.431 0 00.048 1.408A1.456 1.456 0 006.7 12.5h10.108a1.456 1.456 0 001.233-.687 1.431 1.431 0 00.048-1.408zM11.752 3.25A.75.75 0 0112.5 4v3A.75.75 0 1111 7V4a.75.75 0 01.752-.75zm0 5.5a1 1 0 11-1 1 1 1 0 011-1zM5.5 21a1 1 0 01-1 1h-3a1 1 0 000 2h3a3 3 0 000-6H2.75a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25H6.5a1 1 0 000-2h-5a1 1 0 00-1 1v3a2 2 0 002 2h2a1 1 0 011 1zM8.5 17.5v3a3.5 3.5 0 007 0v-3a3.5 3.5 0 00-7 0zm2 0a1.5 1.5 0 013 0v3a1.5 1.5 0 01-3 0zM16.5 17.5v3a3.5 3.5 0 007 0v-3a3.5 3.5 0 00-7 0zm2 0a1.5 1.5 0 013 0v3a1.5 1.5 0 01-3 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});