define("ember-svg-jar/inlined/passport-ticket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>passport-ticket</title><path d=\"M23.426 2.4l-2.9-.731a.415.415 0 00-.472.215 1.493 1.493 0 01-2.825-.713.412.412 0 00-.313-.413l-2.9-.732a.746.746 0 00-.906.54l-1.361 5.409a.25.25 0 00.154.3A3.986 3.986 0 0114.511 10v7.642a.249.249 0 00.188.242l4.525 1.152a.746.746 0 00.906-.541L23.966 3.3a.747.747 0 00-.54-.9zm-8.085 2.375a.675.675 0 11-.489-.82.675.675 0 01.489.82zm3.008.936a.676.676 0 11-.489-.82.676.676 0 01.489.82zm2.858.779a.675.675 0 11-.489-.82.675.675 0 01.489.82zM6.511 12.289a.257.257 0 00-.211.111 5.428 5.428 0 00-.793 2.447.251.251 0 00.248.277h1.509a.251.251 0 00.249-.277 5.427 5.427 0 00-.794-2.447.248.248 0 00-.208-.111zM7.264 16.375H5.758a.251.251 0 00-.249.277A5.415 5.415 0 006.3 19.1a.251.251 0 00.417 0 5.427 5.427 0 00.793-2.447.254.254 0 00-.063-.194.249.249 0 00-.183-.084zM8.7 12.556a.25.25 0 00-.376.294 7.763 7.763 0 01.442 2.05.249.249 0 00.249.228h1a.248.248 0 00.2-.1.253.253 0 00.046-.214A3.891 3.891 0 008.7 12.556zM4.321 12.556a3.89 3.89 0 00-1.558 2.259.247.247 0 00.046.214.251.251 0 00.2.1h1a.251.251 0 00.249-.228A7.723 7.723 0 014.7 12.85a.251.251 0 00-.377-.294z\"/><path d=\"M10.511 7.5h-8a2.5 2.5 0 00-2.5 2.5v11.5a2.5 2.5 0 002.5 2.5h8a2.5 2.5 0 002.5-2.5V10a2.5 2.5 0 00-2.5-2.5zm-4 13.375a5.125 5.125 0 115.125-5.125 5.131 5.131 0 01-5.125 5.125z\"/><path d=\"M4.256 16.6a.251.251 0 00-.249-.228h-1a.25.25 0 00-.243.31 3.89 3.89 0 001.558 2.259.251.251 0 00.378-.291 7.723 7.723 0 01-.444-2.05zM10.016 16.375h-1a.249.249 0 00-.249.228 7.763 7.763 0 01-.442 2.047.25.25 0 00.376.294 3.891 3.891 0 001.559-2.259.25.25 0 00-.243-.31z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});