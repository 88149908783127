define("ember-svg-jar/inlined/messages-bubble-search-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-search-alternate</title><path d=\"M8.6 17.328a1 1 0 00-.661.038l-3.648 1.52a.25.25 0 01-.331-.316l.851-2.339a1 1 0 00-.276-1.089A7.043 7.043 0 012 9.9C2 5.546 6.486 2 12 2s10 3.546 10 7.9a6.243 6.243 0 01-.259 1.75.25.25 0 00.052.233 7.042 7.042 0 01.99 1.493.25.25 0 00.452-.007A8.33 8.33 0 0024 9.9C24 4.443 18.617 0 12 0S0 4.443 0 9.9a8.877 8.877 0 002.708 6.26l-1.6 4.4a1 1 0 001.325 1.265l5.959-2.483a14.152 14.152 0 001.449.3.249.249 0 00.266-.348 6.992 6.992 0 01-.481-1.562.251.251 0 00-.2-.2c-.273-.059-.55-.125-.826-.204z\"/><path d=\"M21.173 19.759a.25.25 0 01-.034-.311 5.509 5.509 0 10-1.691 1.691.25.25 0 01.311.034l2.534 2.534a1 1 0 001.414-1.414zM13 16.5a3.5 3.5 0 113.5 3.5 3.5 3.5 0 01-3.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});