define("ember-svg-jar/inlined/bag-purse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bag-purse</title><path d=\"M16.5 9.5h-9a7 7 0 000 14h9a7 7 0 000-14z\"/><path d=\"M23.707 8.793a9.861 9.861 0 00-6.395-2.776.25.25 0 01-.165-.424 3 3 0 10-4.31-.016.25.25 0 01-.18.423h-1.314a.25.25 0 01-.181-.423 3 3 0 10-4.317.007.251.251 0 01-.17.424A9.562 9.562 0 00.293 8.793a1 1 0 101.414 1.414A7.525 7.525 0 017.072 8h9.856a7.524 7.524 0 015.364 2.206 1 1 0 101.415-1.413zM15 2.5a1 1 0 11-1 1 1 1 0 011-1zm-6 0a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});