define("ember-svg-jar/inlined/gymnastics-weightlifting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gymnastics-weightlifting</title><path d=\"M23 9a1 1 0 00-1 1v2.25h-2.75a.25.25 0 00-.25.25v1.105a1.923 1.923 0 01-.033.35.25.25 0 00.246.295H22V23a1 1 0 002 0V10a1 1 0 00-1-1z\"/><rect x=\"8.5\" y=\"12.5\" width=\"6\" height=\"1.5\" rx=\".25\" ry=\".25\"/><path d=\"M4 12.5a.25.25 0 00-.25-.25H2V10a1 1 0 00-2 0v13a1 1 0 002 0v-8.75h1.787a.25.25 0 00.246-.295 1.923 1.923 0 01-.033-.35z\"/><circle cx=\"11.25\" cy=\"2.5\" r=\"2.5\"/><path d=\"M18 10a4 4 0 00-4-4H9a4 4 0 00-4 4v3.605a1.25 1.25 0 002.5 0V9.75a.5.5 0 011 0v1.5a.25.25 0 00.25.25h5.5a.25.25 0 00.25-.25v-1.5a.5.5 0 011 0v3.855a1.25 1.25 0 002.5 0zM14.5 15.25a.25.25 0 00-.25-.25h-5.5a.25.25 0 00-.25.25v5.5a1.25 1.25 0 002.5 0V16.5a.5.5 0 011 0v4.25a1.25 1.25 0 002.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});