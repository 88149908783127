define("ember-svg-jar/inlined/image-file-shutter-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-shutter-alternate</title><path d=\"M21.707 5.707L16.293.293A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.465a.5.5 0 01.353.146l4.536 4.536a.5.5 0 01.146.353z\"/><path d=\"M9.737 12.557a.25.25 0 00.433 0l2.4-4.157c.2-.341-.064-.442-.215-.452A4.64 4.64 0 0012 7.935 5.962 5.962 0 008.132 9.36a.256.256 0 00-.053.322zM14.1 15.309a.25.25 0 00-.433 0l-2.378 4.118a.313.313 0 00.23.485c.16.014.321.023.485.023a5.959 5.959 0 003.753-1.329.256.256 0 00.058-.327zM12.225 11.746h4.968a.255.255 0 00.234-.356 6.025 6.025 0 00-3.4-3.1.26.26 0 00-.308.115l-1.712 2.966a.25.25 0 00.218.375zM9.938 14.91l-2.481-4.3a.253.253 0 00-.425-.022A5.955 5.955 0 006 13.935a5.671 5.671 0 00.058.808.563.563 0 00.566.542h3.1a.25.25 0 00.214-.375zM17.45 12.746H14.2a.25.25 0 00-.216.375l2.471 4.279a.253.253 0 00.422.027 5.975 5.975 0 001.077-4.227.485.485 0 00-.504-.454zM11.512 16.285H6.879a.254.254 0 00-.23.362 6.032 6.032 0 003.18 2.874.257.257 0 00.313-.112l1.587-2.749a.25.25 0 00-.217-.375z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});