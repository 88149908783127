define("ember-svg-jar/inlined/temperature-celsius", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>temperature-celsius</title><path d=\"M12 0a12 12 0 1012 12A12 12 0 0012 0zm-1 16.5a4.458 4.458 0 002.045-.49 1 1 0 11.91 1.781 6.5 6.5 0 110-11.582 1 1 0 11-.91 1.781A4.458 4.458 0 0011 7.5a4.5 4.5 0 000 9zm7-5.5a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});