define("ember-svg-jar/inlined/cog-double-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cog-double-1</title><path d=\"M12.688 15.314a1.563 1.563 0 00-1.126-2.721l-.76.039a.558.558 0 01-.427-.164.564.564 0 01-.164-.427l.039-.76a1.563 1.563 0 00-2.721-1.126l-.509.565a.583.583 0 01-.835 0l-.51-.566a1.563 1.563 0 00-2.721 1.128l.039.76a.563.563 0 01-.164.426.53.53 0 01-.427.164l-.759-.039a1.563 1.563 0 00-1.127 2.721l.564.51a.562.562 0 010 .835l-.564.509a1.563 1.563 0 001.127 2.721l.757-.039a.564.564 0 01.591.591l-.039.761a1.563 1.563 0 002.721 1.126l.51-.565a.582.582 0 01.835 0l.509.565a1.571 1.571 0 001.162.515 1.55 1.55 0 00.6-.119 1.566 1.566 0 00.961-1.524l-.039-.76a.562.562 0 01.164-.426.552.552 0 01.427-.165l.76.039a1.562 1.562 0 001.126-2.72l-.564-.51a.562.562 0 010-.835zM6.6 19.182a2.941 2.941 0 112.941-2.941A2.944 2.944 0 016.6 19.182zM23.484 8.688l-.564-.51a.562.562 0 010-.835l.564-.51a1.562 1.562 0 00-1.127-2.72l-.759.039a.569.569 0 01-.427-.165.563.563 0 01-.164-.426l.039-.761a1.563 1.563 0 00-2.721-1.126l-.51.566a.583.583 0 01-.835 0l-.509-.565A1.563 1.563 0 0013.75 2.8l.039.76a.563.563 0 01-.164.426.522.522 0 01-.427.165l-.76-.039a1.562 1.562 0 00-1.126 2.72l.564.51a.562.562 0 010 .835l-.564.51a1.563 1.563 0 001.126 2.721l.76-.039a.571.571 0 01.427.165.559.559 0 01.164.425l-.039.761a1.563 1.563 0 002.721 1.126l.509-.565a.581.581 0 01.835 0l.51.566a1.57 1.57 0 001.162.514 1.544 1.544 0 00.6-.118 1.566 1.566 0 00.964-1.524l-.039-.76a.563.563 0 01.164-.426.551.551 0 01.427-.164l.759.039a1.563 1.563 0 001.127-2.721zM17.4 10.7a2.941 2.941 0 112.941-2.941A2.945 2.945 0 0117.4 10.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});