define("ember-svg-jar/inlined/monitor-play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-play</title><path d=\"M24 2.84A2.548 2.548 0 0021.5.251h-19A2.548 2.548 0 000 2.84v13.911a3 3 0 003 3h7.25a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H5a1 1 0 000 2h14a1 1 0 000-2h-6.25a.25.25 0 01-.25-.25V20a.25.25 0 01.25-.25H21a3 3 0 003-3zm-22 0a.551.551 0 01.5-.589h19a.551.551 0 01.5.589v11.911a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5z\"/><path d=\"M9.3 11.356a.645.645 0 00.977.553l5.91-2.73a.645.645 0 000-1.106l-5.91-2.73A.644.644 0 009.3 5.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});