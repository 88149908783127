define("ember-svg-jar/inlined/road-sign-no-right-turn-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-no-right-turn-alternate</title><path d=\"M13.5 7.505a.75.75 0 00-1.281.53v.75a.25.25 0 01-.25.25h-1a3 3 0 00-3 3v4a1 1 0 102 0v-4a1 1 0 011-1h1a.25.25 0 01.25.25v.75a.75.75 0 001.281.53l2-2a.749.749 0 000-1.06z\"/><path d=\"M12 .026A11.974 11.974 0 1023.973 12 11.987 11.987 0 0012 .026zM21.973 12a9.967 9.967 0 01-16.087 7.874.25.25 0 01-.023-.374l1.058-1.058a1 1 0 10-1.414-1.414l-1.05 1.049a.249.249 0 01-.375-.025 9.964 9.964 0 0113.97-13.97.251.251 0 01.1.182.248.248 0 01-.072.193L16.236 6.3a1 1 0 101.414 1.412l1.85-1.849a.25.25 0 01.374.023A9.924 9.924 0 0121.973 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});