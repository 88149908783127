define("ember-svg-jar/inlined/folder-connect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-connect</title><path d=\"M22.5 3.375h-4.882a.5.5 0 01-.447-.276l-.447-.894a1.491 1.491 0 00-1.342-.83H13.5a1.5 1.5 0 00-1.5 1.5v7a1.5 1.5 0 001.5 1.5h9a1.5 1.5 0 001.5-1.5v-5a1.5 1.5 0 00-1.5-1.5zM22.5 15.375h-4.882a.5.5 0 01-.447-.276l-.447-.894a1.491 1.491 0 00-1.342-.83H13.5a1.5 1.5 0 00-1.5 1.5v7a1.5 1.5 0 001.5 1.5h9a1.5 1.5 0 001.5-1.5v-5a1.5 1.5 0 00-1.5-1.5zM.75 15.625a.75.75 0 00.75-.75v-1a.75.75 0 00-1.5 0v1a.75.75 0 00.75.75zM.75 3.125a.75.75 0 00.75-.75v-1a.75.75 0 00-1.5 0v1a.75.75 0 00.75.75zM.75 11.625a.75.75 0 00.75-.75v-1a.75.75 0 00-1.5 0v1a.75.75 0 00.75.75zM2.25 6.125H1.5v-.75a.75.75 0 00-1.5 0v1a1.252 1.252 0 001.25 1.25h1a.75.75 0 000-1.5zM6.25 6.125h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM10.25 6.125h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM6.25 18.625h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM10.25 18.625h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM2.25 18.625H1.5v-.75a.75.75 0 00-1.5 0v1a1.252 1.252 0 001.25 1.25h1a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});