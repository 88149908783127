define("ember-svg-jar/inlined/virtual-boxes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>virtual-boxes</title><path d=\"M17.015 8.48V4.395a.25.25 0 00-.38-.213L12.87 6.476a.248.248 0 00-.12.213v4.779a.25.25 0 00.38.214l3.162-1.92a1.509 1.509 0 00.723-1.282zM11.871 5.169a.25.25 0 00.26 0l3.838-2.34a.25.25 0 00.011-.419L12.852.263a1.511 1.511 0 00-1.7 0l-3.11 2.15a.25.25 0 00.012.419zM11.25 6.689a.25.25 0 00-.12-.214L7.4 4.192a.249.249 0 00-.38.213v4.076a1.511 1.511 0 00.717 1.28l3.138 1.917a.249.249 0 00.38-.213zM17.5 16.5h-1.919a.251.251 0 01-.24-.181 4.5 4.5 0 00-8.706.086.125.125 0 01-.121.095H6.5A2.5 2.5 0 004 19v2a1.5 1.5 0 00.857 1.355.25.25 0 01.143.225V23a1 1 0 002 0v-.25a.25.25 0 01.25-.25h9.5a.25.25 0 01.25.25V23a1 1 0 002 0v-.42a.25.25 0 01.143-.226A1.5 1.5 0 0020 21v-2a2.5 2.5 0 00-2.5-2.5zM11 15a2.5 2.5 0 11-2.5 2.5A2.5 2.5 0 0111 15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});