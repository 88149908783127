define("ember-svg-jar/inlined/app-window-cash-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-cash-1</title><path d=\"M17.525 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm.533 8.75a.592.592 0 00.221-1.141l-2.065-.826a2.084 2.084 0 01.361-3.98.249.249 0 00.2-.245v-.308a.75.75 0 111.5 0v.25a.25.25 0 00.25.25h.5a.75.75 0 010 1.5h-2.033a.591.591 0 00-.221 1.14l2.064.827a2.084 2.084 0 01-.36 3.98.25.25 0 00-.2.245v.308a.75.75 0 01-1.5 0v-.25a.25.25 0 00-.25-.25h-.5a.75.75 0 110-1.5z\"/><path d=\"M9.222 19.222a.97.97 0 00-.951-.721h-5.3a1 1 0 01-1-1V5.251A.25.25 0 012.225 5h19.5a.25.25 0 01.25.25v4.781a1 1 0 00.388.777l.022.018a.983.983 0 001.59-.778V3a3 3 0 00-3-3h-18a3 3 0 00-3 3v14.5a3 3 0 003 3h5.3a1 1 0 00.95-1.256zM9.629 2a.967.967 0 011.693 0 .977.977 0 01.153.5.98.98 0 01-.153.5.967.967 0 01-1.693 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.967.967 0 011.693 0 .977.977 0 01.153.5.98.98 0 01-.153.5.967.967 0 01-1.693 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.562.091a1 1 0 01.908-.591.986.986 0 01.847.5.977.977 0 01.153.5.98.98 0 01-.153.5.967.967 0 01-1.693 0 .972.972 0 01-.154-.5.987.987 0 01.092-.408z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});