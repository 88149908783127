define("ember-svg-jar/inlined/educative-toys-maths", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>educative-toys-maths</title><path d=\"M22.75.772H1.25A1.252 1.252 0 000 2.022v9a1.252 1.252 0 001.25 1.25h21.5a1.252 1.252 0 001.25-1.25v-9a1.252 1.252 0 00-1.25-1.25zM5 9.522a3 3 0 113-3 3 3 0 01-3 3zm9.5-.5a.5.5 0 01-.5.5h-3.5a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5H14a.5.5 0 01.5.5zm6.691.5h-4.382a.5.5 0 01-.447-.724l2.191-4.381a.5.5 0 01.894 0L21.638 8.8a.5.5 0 01-.447.722z\"/><circle cx=\"20.5\" cy=\"17.522\" r=\"3\"/><path d=\"M14.954 19.271l-4.335-2.322a1 1 0 00-1.473.853l-.124 4.4a1 1 0 001.422.934L14.9 21.06a1 1 0 00.051-1.789zM6.991 15.411L4.5 13.906a1 1 0 00-1.365.366L.635 18.6a1 1 0 00.356 1.362l2.491 1.5a1 1 0 00.5.135 1.012 1.012 0 00.261-.035.994.994 0 00.608-.466l2.5-4.329a.992.992 0 00.1-.759.982.982 0 00-.46-.597z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});