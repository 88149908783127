define("ember-svg-jar/inlined/keyboard-arrow-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-arrow-up</title><path d=\"M17.485 5.062L12.707.284a1.031 1.031 0 00-1.415 0L6.515 5.062a1 1 0 00.707 1.707h3.028a.25.25 0 01.25.25v15.473a1.5 1.5 0 103 0V7.019a.249.249 0 01.25-.25h3.028a1 1 0 00.707-1.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});