define("ember-svg-jar/inlined/optimization-plane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>optimization-plane</title><path d=\"M23.873 19.339l-1.705-4.267a.25.25 0 00-.232-.157H2.064a.25.25 0 00-.232.157l-1.7 4.266a1.777 1.777 0 00.111 1.548 1.516 1.516 0 001.311.779h20.9a1.517 1.517 0 001.311-.778 1.777 1.777 0 00.108-1.548zM6.5 18.415H5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zM2.631 13.072a.254.254 0 00.025.233.251.251 0 00.207.11h18.274a.25.25 0 00.232-.343l-.759-1.9a1.547 1.547 0 00-1.423-1.008h-8.151a.247.247 0 00-.132.038l-2.779 1.726A1.559 1.559 0 016.4 11.88l-2.064-1.449a.252.252 0 00-.246-.023 2.16 2.16 0 00-.21.105.5.5 0 00-.094.071 1.666 1.666 0 00-.395.588z\"/><path d=\"M6.853 8.253a.25.25 0 01-.277.014l-1.594-.95a.254.254 0 00-.107-.034h-.026a.31.31 0 00-.2.07l-1.031.864a.29.29 0 00-.053.052.31.31 0 00.047.437l3.358 2.357a.567.567 0 00.325.1.558.558 0 00.3-.085l9.162-5.688a1.594 1.594 0 00.743-1.377 1.522 1.522 0 00-.09-.513 1.61 1.61 0 00-1.155-1.037 1.511 1.511 0 00-1.187.228l-2.6 1.774a.252.252 0 01-.229.028L6.512 2.36a.391.391 0 00-.134-.024.385.385 0 00-.23.076l-1.038.769a.378.378 0 00-.062.058.385.385 0 00.044.544l3.932 2.434a.252.252 0 01.118.2.249.249 0 01-.1.211z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});