define("ember-svg-jar/inlined/single-man-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions</title><path d=\"M20.3 18.732c-.253-1.605-.436-2.765-1.707-3.31l-3.936-1.687a.251.251 0 01-.152-.23V12.37a.247.247 0 01.074-.177 3.305 3.305 0 00.779-2.23.251.251 0 01.088-.177 1.873 1.873 0 00.538-1.4 1.58 1.58 0 00-.317-1.015.25.25 0 01-.034-.265 2.528 2.528 0 00.156-1.726c-.341-1.389-2.043-1.881-3.4-1.881a3.751 3.751 0 00-3.164 1.325.252.252 0 01-.213.118 1.073 1.073 0 00-.9.375c-.371.493-.105 1.171.176 1.732a.251.251 0 01-.045.288 1.247 1.247 0 00-.162.2 1.766 1.766 0 00-.266.961 1.524 1.524 0 00.639 1.331.249.249 0 01.117.194A3.167 3.167 0 009.42 12.2a.25.25 0 01.08.183v1.122a.251.251 0 01-.152.23l-3.936 1.687c-1.272.546-1.455 1.7-1.707 3.308-.056.359-.117.751-.2 1.179A.5.5 0 004 20.5h16a.5.5 0 00.491-.591c-.078-.427-.14-.819-.191-1.177z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});