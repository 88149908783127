define("ember-svg-jar/inlined/lucide-mail-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M22 10.5V6a2 2 0 00-2-2H4a2 2 0 00-2 2v12c0 1.1.9 2 2 2h12.5\"/><path d=\"M22 7l-8.97 5.7a1.94 1.94 0 01-2.06 0L2 7M18 15.28c.2-.4.5-.8.9-1a2.1 2.1 0 012.6.4c.3.4.5.8.5 1.3 0 1.3-2 2-2 2M20 22v.01\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});