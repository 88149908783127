define("ember-svg-jar/inlined/anchor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>anchor</title><path d=\"M21.473 15.221a1.25 1.25 0 00-2.13-.978l-1.55 1.55a1 1 0 00.689 1.706.253.253 0 01.21.121.248.248 0 01.009.242 7.561 7.561 0 01-5.093 3.953.5.5 0 01-.608-.488v-9.45a.5.5 0 01.5-.5h3a1 1 0 000-2h-3a.5.5 0 01-.5-.5V7.19a.5.5 0 01.312-.463 3.5 3.5 0 10-2.624 0A.5.5 0 0111 7.19v1.687a.5.5 0 01-.5.5h-3a1 1 0 000 2h3a.5.5 0 01.5.5v9.45a.5.5 0 01-.608.488A7.561 7.561 0 015.3 17.862a.25.25 0 01.218-.363 1 1 0 00.69-1.706l-1.553-1.552a1.25 1.25 0 00-2.128.98 9.5 9.5 0 0018.946 0zM10.5 3.5A1.5 1.5 0 1112 5a1.5 1.5 0 01-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});