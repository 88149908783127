define("ember-svg-jar/inlined/cloud-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-settings</title><path d=\"M2.184 15.683a.947.947 0 001.392-.709l.012-.084a.921.921 0 00-.435-.959 2.18 2.18 0 01-.514-.373A2.373 2.373 0 012 11.773a2.588 2.588 0 012.586-2.587 2.643 2.643 0 01.535.056 1.009 1.009 0 00.811-.186 1 1 0 00.392-.779C6.4 2.54 14.617.906 17.141 6.232a1.025 1.025 0 00.978.569 3.686 3.686 0 012.937 1.275 3.8 3.8 0 01.935 2.8 3.429 3.429 0 01-1.325 2.607.952.952 0 00-.336.949l.01.047a.95.95 0 001.516.6A5.4 5.4 0 0024 10.729a5.763 5.763 0 00-5.218-5.9.25.25 0 01-.19-.12A7.684 7.684 0 004.493 7a.249.249 0 01-.227.195A4.646 4.646 0 00.62 9.446 4.479 4.479 0 000 11.893a4.287 4.287 0 001.235 3.09 4.277 4.277 0 00.949.7z\"/><circle cx=\"11.981\" cy=\"16.061\" r=\"1.5\"/><path d=\"M17.984 19.551a1.757 1.757 0 00-.235-2.112l-.987-1.064a.464.464 0 010-.626l.987-1.065a1.783 1.783 0 00-1.7-2.953l-1.41.327a.456.456 0 01-.534-.311l-.424-1.392a1.775 1.775 0 00-3.4 0l-.424 1.392a.45.45 0 01-.533.311l-1.411-.327a1.783 1.783 0 00-1.7 2.953l.987 1.065a.464.464 0 010 .626l-.988 1.064a1.783 1.783 0 001.7 2.953l1.411-.327a.453.453 0 01.533.311l.424 1.392a1.775 1.775 0 003.4 0l.424-1.392a.447.447 0 01.533-.311l1.411.327a1.742 1.742 0 001.936-.841zm-6-.49a3 3 0 113-3 3 3 0 01-3.003 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});