define("ember-svg-jar/inlined/single-neutral-folder-box.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-folder-box</title><path d=\"M6 2h3.4a.741.741 0 01.669.425A2.751 2.751 0 0012.544 4H19a1 1 0 000-2h-6.456a.742.742 0 01-.669-.425A2.75 2.75 0 009.4 0H6a1 1 0 000 2zM18.5 15.25a2.5 2.5 0 00-2.5 2.5.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5 2.5 2.5 0 00-2.5-2.5h-5a.5.5 0 00-.5.5v7.75a.5.5 0 00.5.5h23a.5.5 0 00.5-.5l-.009-7.751a.5.5 0 00-.5-.5z\"/><path d=\"M4 14.25a.5.5 0 00.5-.5v-8a.5.5 0 01.5-.5h2.9a.741.741 0 01.669.425 2.751 2.751 0 002.48 1.575H19a.5.5 0 01.5.5v6a.5.5 0 00.5.5h2.95a.5.5 0 00.49-.6l-.45-2.25a.5.5 0 00-.49-.4h-.75a.25.25 0 01-.25-.25v-4.5a1 1 0 00-1-1h-9.456a.742.742 0 01-.669-.425A2.75 2.75 0 007.9 3.25H3.5a1 1 0 00-1 1v6.5a.25.25 0 01-.25.25H1.5a.5.5 0 00-.49.4l-.446 2.253a.5.5 0 00.491.6z\"/><circle cx=\"12.08\" cy=\"10.5\" r=\"2\"/><path d=\"M12.08 13.5a3.6 3.6 0 00-3.294 2.316.5.5 0 00.471.684H14.9a.5.5 0 00.471-.684A3.6 3.6 0 0012.08 13.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});