define("ember-svg-jar/inlined/real-estate-app-building-smartphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-app-building-smartphone</title><path d=\"M17.75 0H6.25a3 3 0 00-3 3v18.25A2.752 2.752 0 006 24h12a2.752 2.752 0 002.75-2.75V3a3 3 0 00-3-3zm1 18a.5.5 0 01-.5.5H5.75a.5.5 0 01-.5-.5V3a1 1 0 011-1h11.5a1 1 0 011 1z\"/><path d=\"M12.265 3.826a.5.5 0 00-.53 0l-4 2.5a.5.5 0 00-.235.424v9a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-2a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v2a.5.5 0 00.5.5H16a.5.5 0 00.5-.5v-9a.5.5 0 00-.235-.424zM13.5 11.75h-3a.75.75 0 010-1.5h3a.75.75 0 010 1.5zm0-3h-3a.75.75 0 010-1.5h3a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});