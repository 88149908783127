define("ember-svg-jar/inlined/road-sign-u-turn-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-u-turn-left</title><path d=\"M0 12A12 12 0 1012 0 12.013 12.013 0 000 12zm7.5-.75a.25.25 0 00.25-.25 4.25 4.25 0 018.5 0v6a.75.75 0 01-1.5 0v-6a2.75 2.75 0 00-5.5 0 .25.25 0 00.25.25h1.125a.75.75 0 01.577 1.229l-2.125 2.562c-.007.008-.018.011-.025.019a.722.722 0 01-.175.136.8.8 0 01-.071.048.745.745 0 01-.306.068.674.674 0 01-.507-.2l-.037-.036c-.012-.013-.026-.023-.037-.036l-2.093-2.565a.75.75 0 01.58-1.225z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});