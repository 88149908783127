define("ember-svg-jar/inlined/phone-lock-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-lock-left</title><path d=\"M11.464 20.982l-2.6-2a.5.5 0 00-.8.4v.909a7.822 7.822 0 01-4.747-3.439 1 1 0 10-1.697 1.057 9.813 9.813 0 006.44 4.418v1.048a.5.5 0 00.8.4l2.6-2a.5.5 0 000-.793zM21.8 12s-.455-.609-.636-.628h-2.292a.426.426 0 00-.164.032c-.045.031-.092.1-.049.152a1.066 1.066 0 01.229.527 1 1 0 01-.251.732c-.013.014-.026.029-.04.042l-4.408 4.41a1 1 0 01-1.414 0l-7-7a1 1 0 010-1.415l4.412-4.402a1 1 0 011.414 0l1.128 1.129a.25.25 0 00.426-.178 5.068 5.068 0 01.194-1.654.243.243 0 00-.055-.252l-.956-.956a2.5 2.5 0 00-3.534 0L3.868 7.475a2.5 2.5 0 000 3.535l9.464 9.464a2.5 2.5 0 003.535 0l4.933-4.935a2.5 2.5 0 000-3.539z\"/><path d=\"M21.41 3.577v-.7a2.75 2.75 0 00-5.5 0v.7a1.5 1.5 0 00-.75 1.3v3a1.5 1.5 0 001.5 1.5h4a1.5 1.5 0 001.5-1.5v-3a1.5 1.5 0 00-.75-1.3zM17.66 6.4a1 1 0 111 1 1 1 0 01-1-1zm1-4.77a1.252 1.252 0 011.25 1.25v.25h-2.5v-.25a1.251 1.251 0 011.25-1.255z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});