define("ember-svg-jar/inlined/conversation-chat-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>conversation-chat-2</title><path d=\"M14.5 10.5h4.25a.25.25 0 00.25-.25V5.5A4.505 4.505 0 0014.5 1h-10A4.505 4.505 0 000 5.5v4A4.5 4.5 0 004.5 14H5v3.5a.5.5 0 00.876.329l3.276-3.743A.247.247 0 019.34 14h1a.249.249 0 00.246-.206A3.991 3.991 0 0114.5 10.5z\"/><path d=\"M24 14.5a2.5 2.5 0 00-2.5-2.5h-7a2.5 2.5 0 00-2.5 2.5v3a2.5 2.5 0 002.5 2.5h1.793l2.853 2.854A.5.5 0 0020 22.5V20h1.5a2.5 2.5 0 002.5-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});