define("ember-svg-jar/inlined/diagram-arrow-corner-point-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-arrow-corner-point-left</title><path d=\"M0 8.18a1.6 1.6 0 00.512 1.172l7.238 6.72a1.09 1.09 0 001.833-.8v-3.545a.272.272 0 01.273-.272h7.871a.272.272 0 01.273.272v10.637A1.638 1.638 0 0019.636 24h2.728A1.638 1.638 0 0024 22.364V6.546a1.638 1.638 0 00-1.636-1.637H9.856a.272.272 0 01-.273-.272V1.091A1.09 1.09 0 007.75.292L.514 7.011A1.608 1.608 0 000 8.18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});