define("ember-svg-jar/inlined/weather-cloud-rain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-cloud-rain</title><path d=\"M20.844 7.074a.252.252 0 01-.1-.193A7 7 0 007.088 4.84a.249.249 0 01-.22.173A5.5 5.5 0 007.25 16h10.5a5 5 0 003.094-8.926zM20.75 11a3 3 0 01-3 3H7.25a3.5 3.5 0 11.45-6.971A1 1 0 008.814 6.2a5 5 0 019.936.8c0 .146-.007.289-.02.433a1 1 0 00.5.959A3.007 3.007 0 0120.75 11zM7.78 17.47a.749.749 0 00-1.06 0l-5.25 5.25a.75.75 0 101.06 1.06l5.25-5.25a.749.749 0 000-1.06zM12.28 17.47a.749.749 0 00-1.06 0l-5.25 5.25a.75.75 0 101.06 1.06l5.25-5.25a.749.749 0 000-1.06zM16.78 17.47a.749.749 0 00-1.06 0l-5.25 5.25a.75.75 0 101.06 1.06l5.25-5.25a.749.749 0 000-1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});