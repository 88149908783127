define("ember-svg-jar/inlined/shopping-basket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-basket</title><path d=\"M21.5 14.625h-19a.5.5 0 00-.49.6l1.281 6.4a2.355 2.355 0 002.475 1.5h12.468a2.355 2.355 0 002.475-1.5l1.281-6.4a.5.5 0 00-.49-.6zM22 9.125h-.948a.249.249 0 01-.223-.138l-3.711-7.42a1.25 1.25 0 10-2.236 1.117l3.039 6.08a.249.249 0 01-.223.361H6.3a.249.249 0 01-.223-.361l3.039-6.08a1.25 1.25 0 10-2.234-1.117l-3.711 7.42a.249.249 0 01-.223.138H2a2 2 0 000 4h20a2 2 0 000-4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});