define("ember-svg-jar/inlined/promo-mail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>promo-mail</title><path d=\"M14 6.5a1 1 0 001-1V2a2 2 0 00-2-2H2a2 2 0 00-2 2v15.5a2 2 0 002 2h.5a1 1 0 000-2A.5.5 0 012 17V2.5a.5.5 0 01.5-.5h10a.5.5 0 01.5.5v3a1 1 0 001 1z\"/><path d=\"M5 4.25a.75.75 0 00-1.5 0v9a.75.75 0 001.5 0z\"/><circle cx=\"7.5\" cy=\"5\" r=\"1.5\"/><circle cx=\"16\" cy=\"12.5\" r=\"1.5\"/><path d=\"M22 8H10a2 2 0 00-2 2v2.5a1 1 0 002 0v-2a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v6a.5.5 0 01-.5.5h-2a1 1 0 000 2H22a2 2 0 002-2v-7a2 2 0 00-2-2z\"/><path d=\"M5 17v5a2 2 0 002 2h8a2 2 0 002-2v-5a2 2 0 00-2-2H7a2 2 0 00-2 2zm10 4.5a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-1.923a.25.25 0 01.343-.232l3.379 1.355a.756.756 0 00.556 0l3.379-1.352a.25.25 0 01.343.232zM7.5 17h7a.5.5 0 01.5.5.137.137 0 01-.086.127l-3.821 1.529a.259.259 0 01-.186 0l-3.821-1.529A.137.137 0 017 17.5a.5.5 0 01.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});