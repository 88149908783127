define("ember-svg-jar/inlined/camera-settings-frame-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camera-settings-frame-1</title><path d=\"M17 7.806h-1a.913.913 0 01-.5-.207l-1.293-1.293a1.877 1.877 0 00-1.207-.5h-2a1.877 1.877 0 00-1.207.5L8.5 7.6a.913.913 0 01-.5.207H7a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2.001zm-7.5 4.5a2.5 2.5 0 112.5 2.5 2.5 2.5 0 01-2.5-2.5zM3.749 11a.5.5 0 00.228-.946A1.3 1.3 0 013.25 9c0-.8.817-.974.959-1.3a.5.5 0 00-.683-.646A2.348 2.348 0 002.25 9a2.331 2.331 0 001.25 1.932.507.507 0 00.249.068zM4.194 16.724a.5.5 0 00-.217-.672A1.3 1.3 0 013.25 15a1.337 1.337 0 01.727-1.054.5.5 0 00-.451-.893l-.026.014A2.327 2.327 0 002.25 15a2.35 2.35 0 001.276 1.948.5.5 0 00.668-.224z\"/><path d=\"M2.874 18.414a.75.75 0 00-.208-1.04 3 3 0 010-4.752.75.75 0 00-.006-1.251 3 3 0 01.01-4.752.75.75 0 00-.836-1.245A4.5 4.5 0 000 9a4.445 4.445 0 001.013 2.843.25.25 0 010 .315A4.445 4.445 0 000 15a4.5 4.5 0 001.834 3.624.75.75 0 001.04-.21zM20.251 11a.5.5 0 01-.228-.946A1.3 1.3 0 0020.75 9c0-.8-.817-.974-.959-1.3a.5.5 0 01.683-.646A2.348 2.348 0 0121.75 9a2.331 2.331 0 01-1.25 1.934.507.507 0 01-.249.066zM20.023 16.052A1.3 1.3 0 0020.75 15a1.337 1.337 0 00-.727-1.054.5.5 0 01.451-.893l.026.014A2.327 2.327 0 0121.75 15a2.35 2.35 0 01-1.276 1.948.5.5 0 01-.451-.894z\"/><path d=\"M21.334 17.374a3 3 0 000-4.752.75.75 0 01.006-1.251 3 3 0 00-.009-4.752.75.75 0 01.836-1.245A4.5 4.5 0 0124 9a4.445 4.445 0 01-1.013 2.843.25.25 0 000 .315A4.445 4.445 0 0124 15a4.5 4.5 0 01-1.834 3.624.75.75 0 01-.832-1.248z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});