define("ember-svg-jar/inlined/wind-south", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wind-south</title><path d=\"M14 15.5a.5.5 0 00.472-.333l3-8.5a.5.5 0 00-.141-.542.5.5 0 00-.555-.072l-2.664 1.332a.252.252 0 01-.224 0l-2.664-1.332a.5.5 0 00-.555.072.5.5 0 00-.141.542l2.37 6.715a.292.292 0 00.023.047 3.976 3.976 0 01.544 1.542.291.291 0 00.012.051l.051.145A.5.5 0 0014 15.5z\"/><path d=\"M24 10a10 10 0 10-19 4.359.25.25 0 00.225.141h1.536a.255.255 0 00.185-.081.251.251 0 00.064-.192C7 14.153 7 14.077 7 14v-.06a.247.247 0 00-.031-.125A8 8 0 1114 18a.167.167 0 00-.1.3 4.036 4.036 0 011.206 1.467.252.252 0 00.259.139A10.016 10.016 0 0024 10zM11.5 19H1a1 1 0 000 2h10.5a.5.5 0 010 1 1 1 0 000 2 2.5 2.5 0 000-5z\"/><path d=\"M1 18h8.5a2.5 2.5 0 000-5 1 1 0 000 2 .5.5 0 010 1H1a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});