define("ember-svg-jar/inlined/car-actions-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-actions-search</title><path d=\"M5.2 12.621H1.237a.247.247 0 00-.247.247v.495A1.239 1.239 0 002.227 14.6h1.98a1.239 1.239 0 001.237-1.237v-.495a.247.247 0 00-.244-.247zM11.36 11.63a6.726 6.726 0 014.91-2.13 7.219 7.219 0 014 .93.052.052 0 00.02-.04V8.12a1.243 1.243 0 00-.36-.87l-1.07-1.08a.355.355 0 01-.11-.16L16.72.93A1.491 1.491 0 0015.34 0H5.12a1.491 1.491 0 00-1.38.93L1.82 5.74a.572.572 0 01-.11.16L.22 7.39a.749.749 0 00-.22.53v2.47a1.233 1.233 0 001.24 1.24zM4.4 4.61l.99-2.47a.247.247 0 01.23-.16h9.21a.229.229 0 01.23.16l.99 2.47a.236.236 0 01-.02.23.248.248 0 01-.21.11H4.63a.231.231 0 01-.2-.11.238.238 0 01-.03-.23z\"/><path d=\"M23.707 22.293l-2.534-2.534a.25.25 0 01-.034-.311 5.509 5.509 0 10-1.691 1.691.25.25 0 01.311.034l2.534 2.534a1 1 0 001.414-1.414zM16.5 20a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});