define("ember-svg-jar/inlined/weather-wind-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-wind-1</title><path d=\"M8.25 3a1 1 0 001-1V1a1 1 0 00-2 0v1a1 1 0 001 1zM3.124 4.538a1 1 0 101.414-1.415l-.708-.707a1 1 0 10-1.414 1.415zM1 9.25h1a1 1 0 000-2H1a1 1 0 000 2zM14.085 2.417a1 1 0 00-1.415 0l-.708.707a1 1 0 00-.292.67.249.249 0 00.337.243 8.545 8.545 0 012.125-.492.252.252 0 00.219-.192 1 1 0 00-.266-.936z\"/><path d=\"M5.229 9.313a.249.249 0 00.124-.285 3 3 0 014-3.569.248.248 0 00.247-.039 8.443 8.443 0 011.375-.92.25.25 0 00.025-.427 5 5 0 00-7.407 5.984.25.25 0 00.4.1 7.069 7.069 0 011.236-.844zM22.094 12.072a.248.248 0 01-.1-.192A7 7 0 008.338 9.84a.249.249 0 01-.22.173 5.514 5.514 0 00-4.96 4.177.252.252 0 00.042.21.248.248 0 00.2.1h1.565a.249.249 0 00.235-.167 3.5 3.5 0 013.748-2.3.994.994 0 001.116-.833A5 5 0 0120 12c0 .147-.007.293-.019.438a1 1 0 00.5.953A3 3 0 0119 19h-5.5a.25.25 0 00-.117.029c-.094.05-.19.1-.288.14a.248.248 0 00-.117.353 3.981 3.981 0 01.462 1.272.248.248 0 00.246.206H19a5 5 0 003.094-8.928z\"/><path d=\"M1 18h10.5a2.5 2.5 0 000-5 1 1 0 000 2 .5.5 0 010 1H1a1 1 0 000 2zM9.5 19H1a1 1 0 000 2h8.5a.5.5 0 010 1 1 1 0 000 2 2.5 2.5 0 000-5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});