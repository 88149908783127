define("ember-svg-jar/inlined/outdoors-flashlight-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-flashlight-2</title><path d=\"M13.5 8H13a.5.5 0 01-.5-.5V7a1 1 0 00-1-1H10a1 1 0 00-1 1v.5a.5.5 0 01-.5.5H2a2 2 0 00-2 2v4a2 2 0 002 2h11.5a.5.5 0 00.5-.5v-7a.5.5 0 00-.5-.5zM19.5 6H18a2.5 2.5 0 00-2.5 2.5v7A2.5 2.5 0 0018 18h1.5a.5.5 0 00.5-.5v-11a.5.5 0 00-.5-.5zM23 6h-1a.5.5 0 00-.5.5v11a.5.5 0 00.5.5h1a1 1 0 001-1V7a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});