define("ember-svg-jar/inlined/single-woman-actions-music.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-music</title><path d=\"M12.25 17.5a.25.25 0 00.25-.25v-3.42a3.451 3.451 0 01.39-1.589.252.252 0 00.01-.205.247.247 0 00-.146-.144l-1.28-.469a.779.779 0 01-.513-.729v-.468a.25.25 0 01.25-.25A6.374 6.374 0 0015 9.081a.387.387 0 00.084-.592c-.494-.538-.835-1.186-.981-3.318C13.838 1.346 11.236 0 9 0S4.161 1.346 3.9 5.171c-.146 2.132-.487 2.78-.981 3.318A.387.387 0 003 9.081a6.348 6.348 0 003.782.9.251.251 0 01.254.25v.463a.779.779 0 01-.513.729c-2.9 1.065-5.212 1.825-5.764 2.922A7.822 7.822 0 000 17.076a.386.386 0 00.1.3.4.4 0 00.29.126z\"/><path d=\"M13.5 24a2.5 2.5 0 002.5-2.5v-7.463a.251.251 0 01.2-.246l5.5-1.031a.252.252 0 01.206.054.249.249 0 01.09.192v3.755a.255.255 0 01-.081.185.251.251 0 01-.192.064c-.071-.01-.146-.01-.223-.01a2.5 2.5 0 102.5 2.5v-6.795a2 2 0 00-2.368-1.966l-6 1.125A2 2 0 0014 13.83v4.931a.255.255 0 01-.081.185.251.251 0 01-.192.064c-.075-.01-.15-.01-.227-.01a2.5 2.5 0 000 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});