define("ember-svg-jar/inlined/single-man-actions-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-text</title><path d=\"M9.5 12.5a2.988 2.988 0 011.385-2.513.251.251 0 00.115-.21V8.87a.251.251 0 01.073-.177 3.3 3.3 0 00.779-2.231.248.248 0 01.089-.176 1.876 1.876 0 00.537-1.4 1.58 1.58 0 00-.316-1.015.247.247 0 01-.034-.264 2.531 2.531 0 00.156-1.727C11.943.493 10.241 0 8.888 0a3.751 3.751 0 00-3.163 1.324.253.253 0 01-.213.118 1.072 1.072 0 00-.9.375c-.371.493-.1 1.17.176 1.732a.251.251 0 01-.045.288 1.247 1.247 0 00-.162.2A1.763 1.763 0 004.319 5a1.522 1.522 0 00.639 1.331.249.249 0 01.117.194A3.167 3.167 0 005.92 8.7a.25.25 0 01.08.183v1.123a.249.249 0 01-.152.229l-3.936 1.687C.64 12.468.457 13.626.205 15.23c-.056.359-.117.751-.2 1.179a.5.5 0 00.106.411A.5.5 0 00.5 17h8.75a.25.25 0 00.25-.25zM22 11h-9a2 2 0 00-2 2v9a2 2 0 002 2h9a2 2 0 002-2v-9a2 2 0 00-2-2zm-9 2.25a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v8.5a.255.255 0 01-.073.177.251.251 0 01-.177.073h-8.5a.25.25 0 01-.25-.25z\"/><path d=\"M20 16.5a.75.75 0 000-1.5h-4.75a.75.75 0 000 1.5zM15.25 19.5h2.25a.75.75 0 000-1.5h-2.25a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});