define("ember-svg-jar/inlined/farming-barn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>farming-barn</title><path d=\"M23.243 8.361L12.317.106a.528.528 0 00-.634 0L.757 8.361a1.468 1.468 0 001.77 2.339l9.156-6.918a.528.528 0 01.634 0l9.156 6.918a1.468 1.468 0 001.77-2.342z\"/><path d=\"M21.7 12.852l-9.25-7a.75.75 0 00-.906 0l-9.25 7a.751.751 0 00-.3.6v9.8a.75.75 0 00.75.75h18.5a.75.75 0 00.75-.75v-9.8a.751.751 0 00-.294-.6zM8.277 14.174A.25.25 0 018.515 14h6.97a.25.25 0 01.144.454l-3.34 2.365a.5.5 0 01-.578 0l-3.34-2.365a.25.25 0 01-.094-.28zm-1.642 6.484a.249.249 0 01-.135-.221v-4.374a.249.249 0 01.135-.221.253.253 0 01.26.017l3.087 2.187a.251.251 0 010 .408L6.9 20.641a.253.253 0 01-.265.017zm9.088 1.668a.251.251 0 01-.239.174H8.516a.25.25 0 01-.145-.454l3.34-2.365a.5.5 0 01.578 0l3.34 2.365a.25.25 0 01.094.28zm1.777-1.889a.249.249 0 01-.135.221.253.253 0 01-.26-.017l-3.087-2.187a.251.251 0 010-.408l3.087-2.187a.253.253 0 01.26-.017.249.249 0 01.135.221z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});