define("ember-svg-jar/inlined/trekking-top", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trekking-top</title><path d=\"M2.5 22.39a.5.5 0 00.121.986h7.063a.249.249 0 00.213-.121.246.246 0 00.003-.245 2.434 2.434 0 01-.281-1.134v-2.331A.249.249 0 009.4 19.3a9.083 9.083 0 01-3.767-.959.249.249 0 01-.108-.207.247.247 0 01.11-.206 6.759 6.759 0 012.316-.786.98.98 0 00.809-1.09v-.019a.993.993 0 00-1.16-.857 7.451 7.451 0 00-3.457 1.39 2.064 2.064 0 00-.772 1.56 1.9 1.9 0 00.351 1.086l.147 1.179A1.829 1.829 0 012.5 22.39zM12.174 4.912a2.478 2.478 0 001.444.464 2.609 2.609 0 00.421-.035A2.5 2.5 0 0015.075.84a2.5 2.5 0 00-3.486.586 2.5 2.5 0 00.585 3.486zM20.782 7.462a.877.877 0 001.093-.853V4.12a1 1 0 00-2 0v2.436a.9.9 0 00.758.873c.048.009.098.02.149.033zM20.624 12.33a.984.984 0 00-.749.957v9.339a1 1 0 102 0v-9.344a.987.987 0 00-1.231-.957z\"/><path d=\"M10.361 18.163a6.848 6.848 0 01.263 1.882v1.831a1.5 1.5 0 003 0v-2.861a2.524 2.524 0 00-.1-.686l-.188-.659a.25.25 0 01.285-.315l1.294.235a.25.25 0 01.205.246v2.54a1.5 1.5 0 003 0v-3.165a2.5 2.5 0 00-2.053-2.46l-2.778-.5a.252.252 0 01-.2-.315L13.934 11a.248.248 0 01.136-.159.254.254 0 01.209 0 8.953 8.953 0 003.756.825 9.1 9.1 0 002.335-.306 1.589 1.589 0 001.24-1.7 1.5 1.5 0 00-1.9-1.229 6.065 6.065 0 01-1.671.236 6.017 6.017 0 01-3.507-1.131L14 7.154a1.5 1.5 0 00-2.314.81l-1.847 6.451a3.51 3.51 0 000 1.923zM6.783 12.557a2 2 0 002.554-1.214l1.172-3.3A2 2 0 009.3 5.491a2 2 0 00-2.559 1.215L5.569 10a2 2 0 001.214 2.553z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});