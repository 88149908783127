define("ember-svg-jar/inlined/folder-sync-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-sync-alternate</title><path d=\"M22.77 18.6a1 1 0 00-1.255.578 4.074 4.074 0 01-1.277 1.932 4.163 4.163 0 01-5.658-.469.256.256 0 01.014-.349l1.717-1.717a.5.5 0 00-.354-.853H11.5a.5.5 0 00-.5.5v4.458a.5.5 0 00.854.353l.974-.974a.244.244 0 01.353.009 6.086 6.086 0 0010.211-2.2 1 1 0 00-.622-1.268zM24 12.234a.5.5 0 00-.854-.353l-1.03 1.031a.244.244 0 01-.352-.008 6.1 6.1 0 00-10.2 2.225 1 1 0 001.879.686 4.054 4.054 0 011.276-1.915 4.153 4.153 0 015.637.435.255.255 0 01-.012.35l-1.655 1.655a.5.5 0 00.354.853H23.5a.5.5 0 00.5-.5z\"/><path d=\"M2 2.5a.5.5 0 01.5-.5h4.573a.5.5 0 01.447.276L8.33 3.9A2 2 0 0010.118 5H19a.5.5 0 01.5.5v4.08a.242.242 0 00.179.233 7.951 7.951 0 011.46.573.244.244 0 00.361-.215V5a2 2 0 00-2-2h-9.073a.5.5 0 01-.447-.276l-.81-1.619A2 2 0 007.382 0H2a2 2 0 00-2 2v14.5a2 2 0 002 2h7.255a.248.248 0 00.25-.242c.007-.372.023-1.083.045-1.5a.25.25 0 00-.25-.258H2.5A.5.5 0 012 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});