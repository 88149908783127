define("ember-svg-jar/inlined/composition-window-man-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>composition-window-man-1-alternate</title><circle cx=\"12\" cy=\"6\" r=\"2.5\"/><path d=\"M12 9.5a4 4 0 00-4 4V15a1 1 0 001 1h.776a.25.25 0 01.249.222l.375 3.388a1 1 0 00.994.89h1.21a1 1 0 00.994-.89l.376-3.388a.25.25 0 01.25-.222H15a1 1 0 001-1v-1.5a4 4 0 00-4-4z\"/><path d=\"M21.5 3a3 3 0 00-3-3h-13a3 3 0 00-3 3v18a3 3 0 003 3h13a3 3 0 003-3zm-2 18a1 1 0 01-1 1h-13a1 1 0 01-1-1V3a1 1 0 011-1h13a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});