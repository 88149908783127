define("ember-svg-jar/inlined/trends-hot-flame", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trends-hot-flame</title><path d=\"M10.8.222A1.251 1.251 0 009.1 1.9a10.2 10.2 0 01.038 9.2.249.249 0 01-.437-.005 5.584 5.584 0 01-.56-1.568.754.754 0 00-.48-.556.746.746 0 00-.724.119 8.653 8.653 0 00-2.781 9.6 7.807 7.807 0 007.749 5.234c4.229 0 7.186-2.145 8.116-5.882C21.4 12.525 18.016 4.046 10.8.222zM15.537 18.1a3.314 3.314 0 01-3.508 2.829 3.406 3.406 0 01-3.355-2.811.5.5 0 01.607-.575 3.006 3.006 0 002.5-1.41 2.3 2.3 0 00.453-2.46.5.5 0 01.611-.713 4.724 4.724 0 012.692 5.14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});