define("ember-svg-jar/inlined/single-man-actions-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-chat</title><path d=\"M9.5 12a3.491 3.491 0 011.4-2.792.252.252 0 00.1-.2V8.87a.251.251 0 01.073-.177 3.3 3.3 0 00.779-2.231.248.248 0 01.089-.176 1.876 1.876 0 00.537-1.4 1.575 1.575 0 00-.316-1.014.251.251 0 01-.034-.265 2.531 2.531 0 00.156-1.727C11.943.493 10.241 0 8.888 0a3.751 3.751 0 00-3.163 1.324.253.253 0 01-.213.118 1.072 1.072 0 00-.9.375c-.371.493-.1 1.17.176 1.732a.251.251 0 01-.045.288 1.247 1.247 0 00-.162.2A1.763 1.763 0 004.319 5a1.522 1.522 0 00.639 1.331.249.249 0 01.117.194A3.167 3.167 0 005.92 8.7a.25.25 0 01.08.183v1.123a.249.249 0 01-.152.229l-3.936 1.687C.64 12.468.457 13.626.205 15.23c-.056.359-.117.751-.2 1.179a.5.5 0 00.106.411A.5.5 0 00.5 17h8.75a.25.25 0 00.25-.25zM22 10h-9a2 2 0 00-2 2v7a2 2 0 002 2h.75a.25.25 0 01.25.25V23a1 1 0 001.625.781l3.407-2.726a.245.245 0 01.156-.055H22a2 2 0 002-2v-7a2 2 0 00-2-2zm-3.875 9.219l-1.719 1.375A.249.249 0 0116 20.4V20a1 1 0 00-1-1h-1.75a.25.25 0 01-.25-.25v-6.5a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v6.5a.25.25 0 01-.25.25h-3a1 1 0 00-.625.219z\"/><path d=\"M15 13.5a.75.75 0 000 1.5h5a.75.75 0 000-1.5zM18.75 16.75A.75.75 0 0018 16h-3a.75.75 0 000 1.5h3a.75.75 0 00.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});