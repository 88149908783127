define("ember-svg-jar/inlined/arrow-double-right-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-double-right-1</title><path d=\"M23.613 11.3l-4.919-5a1.007 1.007 0 00-.714-.3h-4.009a1 1 0 00-.712 1.7l4.057 4.123a.252.252 0 010 .351L13.259 16.3a1 1 0 00.712 1.7h4.009a1 1 0 00.713-.3l4.92-5a1 1 0 000-1.4z\"/><path d=\"M14.142 11.3l-4.919-5A1.006 1.006 0 008.51 6H6.984a1 1 0 00-.713 1.7l4.058 4.123a.25.25 0 010 .351L6.271 16.3a1 1 0 00.713 1.7H8.51a1 1 0 00.712-.3l4.92-5a1 1 0 000-1.4z\"/><path d=\"M6.384 10.947L1.814 6.3A1 1 0 00.388 7.7l4.052 4.122a.251.251 0 010 .351L.388 16.3a1 1 0 001.426 1.4l4.569-4.65a1.508 1.508 0 00.001-2.103z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});