define("ember-svg-jar/inlined/wine-opener", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wine-opener</title><path d=\"M11.793 10.793l-1.7 1.7-1.3-.683a1.614 1.614 0 00-1.9.306 1.631 1.631 0 00-.308 1.9l1.124 2.135a.25.25 0 01-.338.337l-1.511-.8a1.64 1.64 0 00-2.087 2.4l1.253 1.707a.25.25 0 01-.26.391l-1.028-.249a1.642 1.642 0 00-1.538.442L.293 22.293a1 1 0 101.414 1.414l1.778-1.778 2.45.6a1.653 1.653 0 001.722-.662 1.6 1.6 0 000-1.872l-.671-.913a.25.25 0 01.318-.369l.9.475a1.643 1.643 0 002.2-2.2l-1.5-2.848.507.267a1.613 1.613 0 001.9-.306l1.885-1.885a1 1 0 00-1.414-1.414zM23.268 10.631l-9.9-9.9a2.5 2.5 0 00-3.535 0l-.707.706a2.5 2.5 0 000 3.536l9.9 9.9a2.5 2.5 0 003.536 0l.706-.707a2.5 2.5 0 000-3.535z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});