define("ember-svg-jar/inlined/bluetooth-transfer-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bluetooth-transfer-1</title><path d=\"M13.8 12.182a.249.249 0 010-.373l4.212-3.771a1.907 1.907 0 00.055-2.81L13.43.746a1.415 1.415 0 00-1.591-.4 1.445 1.445 0 00-.814 1.406v7.385a.25.25 0 01-.417.186L5.692 4.914A1 1 0 104.357 6.4l6.026 5.405a.25.25 0 010 .372L4.333 17.6a1 1 0 001.334 1.49l4.941-4.424a.25.25 0 01.417.187v7.4a1.445 1.445 0 00.815 1.407 1.276 1.276 0 00.494.1 1.56 1.56 0 001.1-.5l4.631-4.479a1.909 1.909 0 00-.053-2.811zm-.771-8.454a.25.25 0 01.424-.18l2.968 2.871a.25.25 0 01-.007.366l-2.972 2.657a.25.25 0 01-.417-.187zm.424 16.724a.25.25 0 01-.424-.18v-5.534a.25.25 0 01.417-.186l2.969 2.663a.247.247 0 01.083.181.25.25 0 01-.076.184z\"/><circle cx=\"3.5\" cy=\"12.018\" r=\"2\"/><circle cx=\"20.5\" cy=\"12.018\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});