define("ember-svg-jar/inlined/controls-movie-backward", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>controls-movie-backward</title><path d=\"M9.525 17.82q-.032-.329-.032-.664a7.783 7.783 0 018.021-7.5 8.407 8.407 0 014.09 1.05.25.25 0 00.371-.218V6.375a.486.486 0 00-.5-.469H.47a.486.486 0 00-.5.469v9.375a2.431 2.431 0 002.506 2.344h6.8a.25.25 0 00.249-.274z\"/><path d=\"M17.514 11.063A6.323 6.323 0 0011 17.156a6.324 6.324 0 006.517 6.094 6.323 6.323 0 006.517-6.094 6.322 6.322 0 00-6.52-6.093zm-3.985 5.549l2.758-2.112a.786.786 0 011.057.1.672.672 0 01-.106.989l-1.808 1.386a.225.225 0 000 .363l1.808 1.384a.671.671 0 01.106.989.791.791 0 01-1.057.1L13.529 17.7a.675.675 0 010-1.088zm6.923 3.248l-3.008-2.344a.449.449 0 010-.72l3.008-2.343a.505.505 0 01.822.36V19.5a.506.506 0 01-.822.36zM4.475 4.1a.222.222 0 00-.055.255.251.251 0 00.232.145h3.183a.26.26 0 00.177-.069l3.507-3.281a.224.224 0 00.054-.25.251.251 0 00-.232-.15H8.158a.26.26 0 00-.177.069zM16.506 4.1a.222.222 0 00-.054.255.25.25 0 00.231.145h4.8a.486.486 0 00.5-.469v-.937A2.375 2.375 0 0020.171.843a.264.264 0 00-.247.057zM.477 4.5h1.342A.261.261 0 002 4.431L5.5 1.15A.222.222 0 005.557.9a.25.25 0 00-.231-.15H2.482A2.431 2.431 0 00-.024 3.094v.937a.486.486 0 00.501.469zM10.49 4.1a.222.222 0 00-.054.255.251.251 0 00.231.145h3.183a.261.261 0 00.178-.069l3.506-3.281a.224.224 0 00.055-.25.252.252 0 00-.232-.15h-3.183A.26.26 0 0014 .819z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});