define("ember-svg-jar/inlined/credit-card-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-disable</title><path d=\"M17.027 4.25a.028.028 0 00.028-.027v-.306A2.406 2.406 0 0014.666 1.5H2.389A2.4 2.4 0 000 3.917v.306a.027.027 0 00.027.027zM16.817 6.512a.251.251 0 00.238-.25v-.485a.028.028 0 00-.028-.027h-17A.027.027 0 000 5.777v5.806A2.4 2.4 0 002.389 14h5.6a.25.25 0 00.245-.2 9.251 9.251 0 018.583-7.288zM3.333 8.25h2.833a.75.75 0 010 1.5H3.333a.75.75 0 010-1.5z\"/><path d=\"M17.25 9A6.75 6.75 0 1024 15.75 6.758 6.758 0 0017.25 9zm0 2a4.722 4.722 0 012.294.592.251.251 0 01.126.183.248.248 0 01-.071.212L13.487 18.1a.25.25 0 01-.4-.056A4.749 4.749 0 0117.25 11zm0 9.5a4.716 4.716 0 01-2.293-.592.249.249 0 01-.056-.395l6.112-6.113a.256.256 0 01.212-.071.252.252 0 01.184.127A4.749 4.749 0 0117.25 20.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});