define("ember-svg-jar/inlined/keyboard-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-2</title><path d=\"M5.75 10.251h13.5c2.649 0 4.5-1.645 4.5-4s-1.851-4-4.5-4h-6a1 1 0 01-1-1 1 1 0 10-2 0 3 3 0 003 3h6c1.208 0 2.5.525 2.5 2s-1.292 2-2.5 2H5.75c-2.29 0-4 2.376-4 4.5v.75a.25.25 0 01-.25.25h-.25a1 1 0 00-1 1v8a1 1 0 001 1h20a1 1 0 001-1v-8a1 1 0 00-1-1H4a.25.25 0 01-.25-.25v-.75c0-1.125.923-2.5 2-2.5zm-1.5 9.5h-.5a.5.5 0 010-1h.5a.5.5 0 010 1zm14.5-3.5h.5a.5.5 0 010 1h-.5a.5.5 0 010-1zm0 2.5h.5a.5.5 0 010 1h-.5a.5.5 0 010-1zm-3-2.5h.5a.5.5 0 010 1h-.5a.5.5 0 010-1zm0 2.5h.5a.5.5 0 010 1h-.5a.5.5 0 010-1zm-3-2.5h.5a.5.5 0 010 1h-.5a.5.5 0 010-1zm0 2.5h.5a.5.5 0 110 1h-.5a.5.5 0 010-1zm-3-2.5h.5a.5.5 0 010 1h-.5a.5.5 0 010-1zm0 2.5h.5a.5.5 0 010 1h-.5a.5.5 0 010-1zm-3-2.5h.5a.5.5 0 010 1h-.5a.5.5 0 010-1zm0 2.5h.5a.5.5 0 010 1h-.5a.5.5 0 010-1zm-1 2.5h11a.5.5 0 010 1h-11a.5.5 0 010-1zm-1.5-5a.5.5 0 010 1h-.5a.5.5 0 010-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});