define("ember-svg-jar/inlined/keyhole-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyhole-circle</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm2.5 18.131a.5.5 0 01-.493.572H9.712a.5.5 0 01-.49-.594l1.255-5.86A3.429 3.429 0 0111.17 5.8a3.493 3.493 0 014.33 3.35 3.439 3.439 0 01-1.978 3.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});