define("ember-svg-jar/inlined/alarm-bell-off-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>alarm-bell-off-1</title><path d=\"M21 19.5a1 1 0 000-2 1.5 1.5 0 01-1.5-1.5v-4.863a8.725 8.725 0 00-.4-2.617L8.121 19.5zM14.236 21H9.764a.251.251 0 00-.249.222 2.548 2.548 0 00-.015.278 2.5 2.5 0 005 0 2.547 2.547 0 00-.016-.278.249.249 0 00-.248-.222zM1 23.993a1 1 0 00.707-.286l22-22a1.014 1.014 0 000-1.414 1 1 0 00-1.414 0l-5.041 5.041A7.285 7.285 0 0013 3.072V1a1 1 0 00-2 0v2.072a7.957 7.957 0 00-6.5 8.065V16A1.5 1.5 0 013 17.5a1 1 0 000 2h.086L.293 22.293a1.015 1.015 0 000 1.414 1 1 0 00.707.286z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});