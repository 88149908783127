define("ember-svg-jar/inlined/army-badge-skull", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-badge-skull</title><path d=\"M22 1.5A1.5 1.5 0 0020.5 0h-17A1.5 1.5 0 002 1.5v14.289a1.493 1.493 0 00.57 1.177l8.5 6.709a1.495 1.495 0 001.86 0l8.5-6.71a1.493 1.493 0 00.57-1.176zm-4 9.083a2 2 0 01-1.6 1.959.5.5 0 00-.4.49v1.661a1.217 1.217 0 01-1.212 1.223h-.205a.5.5 0 01-.5-.5v-1.5a.75.75 0 00-1.5 0v1.5a.5.5 0 01-.5.5h-.166a.5.5 0 01-.5-.5v-1.5a.75.75 0 00-1.5 0v1.5a.5.5 0 01-.5.5h-.205A1.217 1.217 0 018 14.693v-1.661a.5.5 0 00-.4-.49A2 2 0 016 10.583V9.249a6 6 0 0112 0z\"/><path d=\"M9.334 7.25a1.333 1.333 0 101.333 1.333A1.333 1.333 0 009.334 7.25zM14.667 7.25A1.333 1.333 0 1016 8.583a1.333 1.333 0 00-1.333-1.333z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});