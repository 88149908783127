define("ember-svg-jar/inlined/casino-player-luck", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>casino-player-luck</title><path d=\"M21.478 9.858a7.246 7.246 0 01-.72-1.452C20.758 3.145 15.9 0 11.2 0A9.972 9.972 0 001.1 10.063c0 3.633 1.241 6.217 3.79 7.885a.25.25 0 01.11.209V23.5a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-2.731a.25.25 0 01.244-.25c1.628-.035 2.692-.245 3.425-.978.781-.781.96-3.026 1-4.282a.25.25 0 01.252-.243c.259 0 .547 0 .8-.01 1.078-.048 1.139-.9 1.174-1.4.081-1.117-.715-2.514-1.417-3.748zm-4.1.673a1.67 1.67 0 01.407 2.35c-.98 1.39-3.135.562-5.272-1.262a.251.251 0 00-.413.2 4.1 4.1 0 001.594 3.176.751.751 0 01-.938 1.172A5.719 5.719 0 0110.6 11.8a.25.25 0 00-.412-.182c-2.139 1.827-4.3 2.655-5.276 1.265a1.671 1.671 0 01.407-2.35l.206-.147a.249.249 0 000-.407l-.206-.149a1.671 1.671 0 01-.407-2.35c.66-.935 1.85-.867 3.209-.167a.25.25 0 00.338-.335c-.679-1.341-.735-2.512.189-3.164A1.67 1.67 0 0111 4.22l.147.207a.251.251 0 00.408 0l.145-.207a1.671 1.671 0 012.351-.406c.924.652.867 1.823.189 3.164a.25.25 0 00.337.335c1.359-.7 2.55-.768 3.209.167a1.67 1.67 0 01-.407 2.35l-.206.147a.245.245 0 00-.105.2.25.25 0 00.105.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});