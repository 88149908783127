define("ember-svg-jar/inlined/army-arrow-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-arrow-shield</title><path d=\"M24 1.953A1.959 1.959 0 0022.043.006H1.959A1.958 1.958 0 00.012 1.965L0 9.306a15.146 15.146 0 0011.861 14.669 1 1 0 00.4 0A15.145 15.145 0 0023.988 9.2zM9 14.744a.25.25 0 01-.427.177L6.8 13.577a.5.5 0 00-.6 0l-1.773 1.344A.25.25 0 014 14.744v-2.336a1 1 0 01.293-.707l1.013-.784a.5.5 0 00.194-.395V6.494a.5.5 0 00-.5-.5h-.5a.5.5 0 01-.354-.854l2-2a.5.5 0 01.708 0l2 2a.5.5 0 01-.354.854H8a.5.5 0 00-.5.5v4.028a.5.5 0 00.194.395l1.013.784a1 1 0 01.293.707zm5.5 4.5a.25.25 0 01-.427.177L12.3 18.077a.5.5 0 00-.6 0l-1.773 1.344a.25.25 0 01-.427-.177v-2.336a1 1 0 01.293-.707l1.013-.784a.5.5 0 00.194-.395V8.494a.5.5 0 00-.5-.5H10a.5.5 0 01-.354-.854l2-2a.5.5 0 01.708 0l2 2a.5.5 0 01-.354.854h-.5a.5.5 0 00-.5.5v6.528a.5.5 0 00.194.395l1.013.784a1 1 0 01.293.707zm5.5-4.5a.25.25 0 01-.427.177L17.8 13.577a.5.5 0 00-.6 0l-1.771 1.344a.25.25 0 01-.429-.177v-2.336a1 1 0 01.293-.707l1.013-.784a.5.5 0 00.194-.395V6.494a.5.5 0 00-.5-.5h-.5a.5.5 0 01-.354-.854l2-2a.5.5 0 01.708 0l2 2a.5.5 0 01-.354.854H19a.5.5 0 00-.5.5v4.028a.5.5 0 00.194.395l1.013.784a1 1 0 01.293.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});