define("ember-svg-jar/inlined/like-plus-one", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>like-plus-one</title><path d=\"M21 .5H3a3 3 0 00-3 3v17a3 3 0 003 3h18a3 3 0 003-3v-17a3 3 0 00-3-3zm-19 3a1 1 0 011-1h4.25a.5.5 0 01.5.5v2a.5.5 0 01-.5.5H2.5A.5.5 0 012 5zm20 0V5a.5.5 0 01-.5.5h-4.75a.5.5 0 01-.5-.5V3a.5.5 0 01.5-.5H21a1 1 0 011 1zM14.75 5a.5.5 0 01-.5.5h-4.5a.5.5 0 01-.5-.5V3a.5.5 0 01.5-.5h4.5a.5.5 0 01.5.5zm-.3 7.6a.75.75 0 11-.9-1.2l2-1.5a.751.751 0 011.194.7l-1 7.5a.751.751 0 01-1.488-.2l.765-5.728zM7 15.75a.75.75 0 010-1.5h1.25V13a.75.75 0 011.5 0v1.25H11a.75.75 0 010 1.5H9.75V17a.75.75 0 01-1.5 0v-1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});