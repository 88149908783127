define("ember-svg-jar/inlined/phone-action-power-switch-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-power-switch-1</title><path d=\"M13.25 12.161v5.589a1 1 0 01-1 1h-7a1 1 0 01-1-1v-11a1 1 0 011-1h4.8a.243.243 0 00.241-.22 6.445 6.445 0 01.524-1.93.244.244 0 00-.22-.351H4.75a2.5 2.5 0 00-2.5 2.5v15a2.5 2.5 0 002.5 2.5h8a2.5 2.5 0 002.5-2.5v-7.985a.244.244 0 00-.184-.235 6.507 6.507 0 01-1.442-.581.248.248 0 00-.374.213zM15.75 1.75v3.429a1 1 0 102 0V1.75a1 1 0 00-2 0z\"/><path d=\"M21.75 6.25a4.969 4.969 0 00-1.465-3.536 1 1 0 00-1.414 1.415 3 3 0 11-4.242 0 1 1 0 00-1.414-1.415A5 5 0 1021.75 6.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});