define("ember-svg-jar/inlined/koala", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>koala</title><path d=\"M20.5 3.75a6.589 6.589 0 00-3.225.834.5.5 0 00-.048.844 9.019 9.019 0 013.628 5.711.4.4 0 00.76.088 1.957 1.957 0 00.114-.362 1.762 1.762 0 001.358-.13A2.112 2.112 0 0024 8.75c0-1.729-.731-5-3.5-5zM12 5.25a7.5 7.5 0 107.5 7.5 7.5 7.5 0 00-7.5-7.5zm-3.5 7A1.25 1.25 0 119.75 11a1.249 1.249 0 01-1.25 1.25zm3.5 5c-3.715 0-1.237-6 0-6 1.12 0 3.8 6 0 6zm3.5-5A1.25 1.25 0 1116.75 11a1.249 1.249 0 01-1.25 1.25z\"/><path d=\"M6.771 5.429a.5.5 0 00-.048-.844A6.579 6.579 0 003.5 3.75c-2.769 0-3.5 3.271-3.5 5a2.112 2.112 0 00.913 1.985 1.773 1.773 0 001.358.13 1.957 1.957 0 00.114.362.4.4 0 00.76-.088 9.014 9.014 0 013.626-5.71z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});