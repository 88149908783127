define("ember-svg-jar/inlined/smiley-concerned", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-concerned</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM8.7 8.1a1.544 1.544 0 00-2.4 0 1 1 0 01-1.6-1.2 3.5 3.5 0 015.6 0 1 1 0 01-1.6 1.2zM9 11a1.5 1.5 0 11-1.5-1.5A1.5 1.5 0 019 11zm1.711 6.729a7.932 7.932 0 017.006-2.092 1 1 0 11-.434 1.952 5.9 5.9 0 00-5.142 1.538 1 1 0 11-1.43-1.4zM15 11a1.5 1.5 0 111.5 1.5A1.5 1.5 0 0115 11zm3.485-4.626l-4.5 2.5a1 1 0 11-.97-1.748l4.5-2.5a1 1 0 11.97 1.748z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});