define("ember-svg-jar/inlined/clamp-machine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>clamp-machine</title><path d=\"M24 11.5a1 1 0 00-1-1h-1.776a1.825 1.825 0 00-.461-.551l-2.847-2.468a.5.5 0 00-.747.106 2.006 2.006 0 01-.576.581.5.5 0 00-.054.793l1.256 1.1a.249.249 0 01-.165.437H8.824a.249.249 0 01-.164-.438l1.081-.938a.5.5 0 00-.108-.822 1.978 1.978 0 01-.644-.5.5.5 0 00-.708-.053l-2.5 2.167a1.987 1.987 0 00-.5.589h-.706A2.659 2.659 0 002.5 9.5a2.5 2.5 0 000 5 1 1 0 000-2 .5.5 0 010-1 .625.625 0 01.557.334A1 1 0 004 12.5h1.28a1.86 1.86 0 00.471.561l4.283 3.582a.5.5 0 00.421.107 12.027 12.027 0 011.293-.192.5.5 0 00.276-.879L8.8 12.942a.25.25 0 01.162-.441h8.662a.25.25 0 01.161.442l-3.324 2.791a.5.5 0 00.257.879 11.382 11.382 0 011.223.225.5.5 0 00.439-.1l4.341-3.646a2 2 0 00.5-.587H23a1 1 0 001-1.005zM13 17.5c-3.645 0-6.5 1.537-6.5 3.5a.5.5 0 00.5.5h12a.5.5 0 00.5-.5c0-1.963-2.855-3.5-6.5-3.5z\"/><path d=\"M16.5 3.5a1 1 0 00-1-1h-5a1 1 0 00-1 1v3a1 1 0 001 1h5a1 1 0 001-1zM13 5.75a.75.75 0 11.75-.75.755.755 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});