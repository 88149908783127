define("ember-svg-jar/inlined/earpod-listen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earpod-listen</title><path d=\"M11.5 6a5.744 5.744 0 00-4.063 1.652A1.489 1.489 0 007 8.709v4.582a1.489 1.489 0 00.437 1.057 5.96 5.96 0 005.909 1.352.5.5 0 01.654.476V22.5a1.5 1.5 0 003 0V11a5.274 5.274 0 00-5.5-5zM10 12a1 1 0 111-1 1 1 0 01-1 1zM16.5 0a1 1 0 000 2A5.506 5.506 0 0122 7.5a1 1 0 002 0A7.508 7.508 0 0016.5 0z\"/><path d=\"M15.249 3.25a1 1 0 000 2 3.5 3.5 0 013.5 3.5 1 1 0 002 0 5.506 5.506 0 00-5.5-5.5zM7.5 2a1 1 0 000-2A7.508 7.508 0 000 7.5a1 1 0 002 0A5.506 5.506 0 017.5 2z\"/><path d=\"M9.75 4.25a1 1 0 00-1-1 5.506 5.506 0 00-5.5 5.5 1 1 0 002 0 3.5 3.5 0 013.5-3.5 1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});