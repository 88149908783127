define("ember-svg-jar/inlined/single-woman-actions-edit-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-edit-1</title><path d=\"M11.6 17.5a.252.252 0 00.2-.1 1.872 1.872 0 01.19-.22l3.713-3.713a.255.255 0 00.07-.217.25.25 0 00-.135-.183c-1.054-.528-2.514-1.036-4.167-1.643a.779.779 0 01-.513-.73v-.467a.25.25 0 01.249-.25A6.377 6.377 0 0015 9.081a.388.388 0 00.084-.593c-.494-.537-.836-1.185-.981-3.317C13.839 1.346 11.237 0 9 0S4.162 1.346 3.9 5.171c-.145 2.129-.486 2.78-.98 3.317a.387.387 0 00-.1.316.393.393 0 00.18.277 6.352 6.352 0 003.782.9.249.249 0 01.254.25v.462a.778.778 0 01-.512.73c-2.9 1.065-5.212 1.825-5.765 2.922A7.867 7.867 0 000 17.076a.389.389 0 00.1.3.4.4 0 00.29.126zM15.086 22.74a.5.5 0 00-.131-.479l-2.215-2.215a.5.5 0 00-.834.216l-.886 3.1a.5.5 0 00.48.638.459.459 0 00.137-.02l3.1-.885a.5.5 0 00.349-.355zM21.772 16.229l-3-3a.5.5 0 00-.707 0l-5.011 5.01a.5.5 0 000 .707l3 3a.5.5 0 00.707 0l5.011-5.01a.5.5 0 000-.707zM19.48 11.813a.5.5 0 000 .708l3 3a.536.536 0 00.377.124.584.584 0 00.377-.17 2.624 2.624 0 000-3.707 2.655 2.655 0 00-3.754.045z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});