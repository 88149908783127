define("ember-svg-jar/inlined/squid-shell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>squid-shell</title><path d=\"M6.413 14.179a8.635 8.635 0 01.792-3.865.251.251 0 01.221-.134h2.343a.249.249 0 00.25-.252A4.5 4.5 0 0114.51 5.42a3.744 3.744 0 013.144 1.7.75.75 0 11-1.256.82 2.25 2.25 0 00-1.888-1.02 3 3 0 00-2.991 2.991 4.969 4.969 0 005 4.289A6.318 6.318 0 0018.1 14l-.5-1a.75.75 0 011.342-.67 10.111 10.111 0 01.549 1.126 6.355 6.355 0 003.38-5.611 7.813 7.813 0 00-.242-1.935l-1.65.476a.75.75 0 01-.416-1.441l1.551-.445a7.841 7.841 0 00-5.2-4.237l-.41 2.054a.758.758 0 01-.883.588.75.75 0 01-.588-.883l.396-1.973a9.095 9.095 0 00-.37-.008 9.632 9.632 0 00-7.308 3.352l1.9 1.143a.75.75 0 01-.771 1.287L6.858 4.607a9.57 9.57 0 00-1.445 4.805 10.249 10.249 0 00-1 4.767 12.331 12.331 0 00.572 3.731 8.5 8.5 0 01.428 2.569c0 .8-.314 1.276-.933 1.431a1.691 1.691 0 01-1.775-.6c-.232-.439.117-1.14.935-1.874a1 1 0 10-1.34-1.485c-2.228 2-1.7 3.674-1.364 4.3 1.378 2.6 6.474 2.408 6.474-1.772a10.4 10.4 0 00-.5-3.113 10.348 10.348 0 01-.497-3.187z\"/><path d=\"M23.225 17.746a3.427 3.427 0 00-3.312-2.567 6.34 6.34 0 00-2.5.514c-1.683.649-4.173.158-5.14-1.348a1 1 0 10-1.682 1.082 5.11 5.11 0 001.248 1.337 3.538 3.538 0 00-.947 1.334 2.521 2.521 0 01-1.452-2.471 1 1 0 00-2 .127c.326 5.1 5.345 4.429 5.656 5.315a1.236 1.236 0 01-.792.68c-.23.086-.8.249-1.114-.143a1 1 0 00-1.561 1.25c1.6 1.993 5.042.717 5.433-1.415a2.171 2.171 0 00-1.014-2.2 4.161 4.161 0 00-1.227-.543 1.787 1.787 0 011.5-.879 5.633 5.633 0 003.806-.256 4.383 4.383 0 011.782-.381 1.419 1.419 0 011.385 1.1 1.78 1.78 0 01-.9 2.042 1.648 1.648 0 01-1.494-.014 1 1 0 00-.983 1.742 3.72 3.72 0 005.308-4.306z\"/><circle cx=\"8.664\" cy=\"12.429\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});