define("ember-svg-jar/inlined/image-file-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-share</title><path d=\"M13.187 7.03A1.493 1.493 0 1011.7 5.538a1.493 1.493 0 001.487 1.492z\"/><path d=\"M23.414 3L21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v10.015a.25.25 0 00.469.121A6.218 6.218 0 017.9 10.222a.233.233 0 00.1-.192V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.121 2.122a.5.5 0 01.147.353V18a.5.5 0 01-.5.5h-7.519a.247.247 0 00-.212.379 4.227 4.227 0 01.617 1.425.245.245 0 00.237.2H22a2 2 0 002-2V4.414A2 2 0 0023.414 3z\"/><path d=\"M14.622 13h5.691a.186.186 0 00.158-.286l-3.236-5.178a.373.373 0 00-.633 0l-1.81 2.9a.187.187 0 01-.317 0L13.831 9.4a.375.375 0 00-.633 0l-.421.674a.26.26 0 00.082.328 4.252 4.252 0 011.526 2.4.244.244 0 00.237.198zM8.442 16a.245.245 0 01.266.012 2.74 2.74 0 004.167-1.425A2.758 2.758 0 0010.25 11a2.808 2.808 0 00-2.713 3.059.245.245 0 01-.115.221L4.558 16a.245.245 0 01-.266-.012 2.71 2.71 0 00-2.406-.349 2.706 2.706 0 00-1.762 1.774A2.759 2.759 0 002.75 21a2.729 2.729 0 001.887-.761.244.244 0 01.259-.053l2.464 1a.239.239 0 01.14.214 2.753 2.753 0 105.361-1.025 2.681 2.681 0 00-1.736-1.74 2.74 2.74 0 00-2.767.623.24.24 0 01-.258.053L5.553 18.3a.114.114 0 01-.06-.084l-.03-.353a.118.118 0 01.056-.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});