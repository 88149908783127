define("ember-svg-jar/inlined/multiple-actions-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-home</title><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.353 12.609a.25.25 0 00.406.142l2.626-2.222a2.31 2.31 0 012.765-.283.25.25 0 00.341-.335A4.529 4.529 0 0010.9 9.26a.25.25 0 00.005.311 6.973 6.973 0 011.448 3.038zM0 17.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.356-4.268a.249.249 0 00-.035-.148 1.988 1.988 0 01-.166-.329 2.508 2.508 0 01.731-2.767l2.027-1.715a.253.253 0 00.087-.191V14a5.5 5.5 0 00-11 0z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M10.177 17.618a.5.5 0 00.323.882h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5H15a.5.5 0 00.5-.5v-2.25a1.5 1.5 0 013 0v2.25a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-4.75a.25.25 0 01.25-.25h1.25a.5.5 0 00.323-.882l-6.5-5.5a.5.5 0 00-.646 0z\"/><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.353 12.609a.25.25 0 00.406.142l2.626-2.222a2.31 2.31 0 012.765-.283.25.25 0 00.341-.335A4.529 4.529 0 0010.9 9.26a.25.25 0 00.005.311 6.973 6.973 0 011.448 3.038zM0 17.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.356-4.268a.249.249 0 00-.035-.148 1.988 1.988 0 01-.166-.329 2.508 2.508 0 01.731-2.767l2.027-1.715a.253.253 0 00.087-.191V14a5.5 5.5 0 00-11 0z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M10.177 17.618a.5.5 0 00.323.882h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5H15a.5.5 0 00.5-.5v-2.25a1.5 1.5 0 013 0v2.25a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-4.75a.25.25 0 01.25-.25h1.25a.5.5 0 00.323-.882l-6.5-5.5a.5.5 0 00-.646 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});