define("ember-svg-jar/inlined/antenna-tower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>antenna-tower</title><path d=\"M23 21.755h-.706a.25.25 0 01-.25-.25v-7.832a.478.478 0 00-.478-.478h-2.874a.478.478 0 00-.478.478v7.832a.249.249 0 01-.25.25h-1.412a.25.25 0 01-.25-.25v-7.832a.478.478 0 00-.478-.478h-2.868a.478.478 0 00-.478.478v7.832a.25.25 0 01-.25.25h-.912a.25.25 0 01-.25-.25V7.937a.98.98 0 00-.978-.979H2.44a.98.98 0 00-.979.979v13.568a.25.25 0 01-.25.25H1a1 1 0 100 2h22a1 1 0 000-2zM8.654 11.076h-4.78a.75.75 0 010-1.5h4.78a.75.75 0 010 1.5zm.75 3.553a.75.75 0 01-.75.75h-4.78a.75.75 0 010-1.5h4.78a.75.75 0 01.746.75zm-5.53 3.552h4.78a.75.75 0 010 1.5h-4.78a.75.75 0 110-1.5zM8.654 4.59c0-.264-.357-.478-.8-.478h-.34a.249.249 0 01-.25-.25v-2.14a1 1 0 00-2 0v2.14a.249.249 0 01-.25.25h-.343c-.44 0-.8.214-.8.478v1.185a.25.25 0 00.25.25H8.4a.25.25 0 00.25-.25zM14.869 6.416a2.48 2.48 0 012.476 2.477 1 1 0 002 0 4.481 4.481 0 00-4.476-4.477 1 1 0 000 2z\"/><path d=\"M14.869 2.244a6.656 6.656 0 016.648 6.649 1 1 0 002 0A8.659 8.659 0 0014.869.244a1 1 0 100 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});