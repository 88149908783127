define("ember-svg-jar/inlined/landmark-easter-island-moi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-easter-island-moi</title><path d=\"M20.559 3.36A3.153 3.153 0 0017.415 0h-4.35a3.155 3.155 0 00-3.15 3.152v.983a.5.5 0 01-.1.306l-6.172 7.947A1 1 0 004.435 14h.894a.248.248 0 01.2.1.251.251 0 01.045.215l-.986 3.947a1 1 0 00.181.857 1.007 1.007 0 00.788.385h1.078a.251.251 0 01.229.352l-.906 2.039A1.5 1.5 0 007.328 24H18a1.5 1.5 0 001.5-1.5v-3.279L20.559 3.36zM12.414 9.5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});