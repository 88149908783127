define("ember-svg-jar/inlined/weather-night-clear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-night-clear</title><path d=\"M19.687 14.934A8.5 8.5 0 0110.125 6.5a8.573 8.573 0 01.28-2.171 1 1 0 00-1.091-1.247 10.5 10.5 0 1011.464 13.1 1 1 0 00-1.091-1.247zM10.625 22a8.5 8.5 0 01-2.8-16.526.249.249 0 01.332.254q-.028.386-.028.772A10.514 10.514 0 0018 16.982a.249.249 0 01.208.362A8.508 8.508 0 0110.625 22zM23.445 10.505l-.264-.038a1.508 1.508 0 01-1.273-1.273l-.038-.265a.5.5 0 00-.99 0l-.038.265a1.508 1.508 0 01-1.273 1.273l-.264.038a.5.5 0 000 .99l.264.038a1.508 1.508 0 011.273 1.273l.038.265a.5.5 0 00.99 0l.038-.265a1.508 1.508 0 011.273-1.273l.264-.038a.5.5 0 000-.99zM13.305 4.5l.464.066a3.013 3.013 0 012.544 2.546l.067.463a.5.5 0 00.99 0l.067-.464a3.013 3.013 0 012.544-2.545l.464-.066a.5.5 0 000-.99l-.464-.066A3.013 3.013 0 0117.437.893L17.37.43a.5.5 0 00-.99 0l-.067.464a3.013 3.013 0 01-2.544 2.545l-.464.066a.5.5 0 000 .99z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});