define("ember-svg-jar/inlined/presentation-projector-screen-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>presentation-projector-screen-settings</title><path d=\"M24 1.75a1.5 1.5 0 00-1.5-1.5h-21a1.5 1.5 0 00-.214 2.984.25.25 0 01.214.248v14.543a.251.251 0 01-.2.245 1 1 0 00.2 1.98H11a.249.249 0 01.25.25v.314a.249.249 0 01-.107.2 1.5 1.5 0 101.714 0 .249.249 0 01-.107-.2V20.5a.249.249 0 01.25-.25h9.5a1 1 0 00.2-1.98.249.249 0 01-.2-.245V3.482a.251.251 0 01.214-.248A1.5 1.5 0 0024 1.75zM20.5 18a.25.25 0 01-.25.25H3.75A.25.25 0 013.5 18V3.5a.249.249 0 01.25-.25h16.5a.249.249 0 01.25.25z\"/><path d=\"M7.3 12.118a1.115 1.115 0 001.062 1.848l1.227-.284a.829.829 0 01.978.568l.368 1.21a1.109 1.109 0 002.124 0l.368-1.21a.829.829 0 01.978-.568l1.227.284a1.115 1.115 0 001.068-1.848l-.858-.926a.834.834 0 010-1.135l.858-.926a1.115 1.115 0 00-1.062-1.848l-1.227.284A.829.829 0 0113.43 7l-.368-1.211a1.109 1.109 0 00-2.124 0L10.57 7a.829.829 0 01-.978.567l-1.227-.284A1.115 1.115 0 007.3 9.131l.858.926a.834.834 0 010 1.135zM12 9.375a1.25 1.25 0 11-1.25 1.25A1.25 1.25 0 0112 9.375z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});