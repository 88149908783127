define("ember-svg-jar/inlined/laboratory-test-blood-sugar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laboratory-test-blood-sugar</title><path d=\"M15.765 17.783A4.5 4.5 0 009.788 15.6l-1.814.843a.5.5 0 01-.664-.243l-2.319-4.987A2 2 0 001.364 12.9l3.268 7.028a.251.251 0 01-.334.332l-1.442-.683a2 2 0 00-1.711 3.617l1.6.757A.5.5 0 002.96 24h14.911a.5.5 0 00.454-.711z\"/><path d=\"M23.54 1.573A3.242 3.242 0 0019.1.46l-8.61 5.159A1.413 1.413 0 0010 7.557l.05.083a.5.5 0 01-.161.68L7.331 9.944a1.25 1.25 0 001.339 2.111l2.486-1.577a.5.5 0 01.7.165l.026.045a1.416 1.416 0 001.938.485l8.609-5.159a3.241 3.241 0 001.111-4.441zM17.056 6.9L14 8.732a.5.5 0 01-.687-.172l-.771-1.287a.5.5 0 01.173-.686l3.055-1.831a.5.5 0 01.686.172l.771 1.287a.5.5 0 01-.171.685z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});