define("ember-svg-jar/inlined/earth-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earth-2</title><path d=\"M20.953 4.026a.5.5 0 00-.844.162L19 7.258A2 2 0 0117.391 8.5h-2.838a.5.5 0 00-.385.72l.885 2.28a.5.5 0 00.447.277h.829a.5.5 0 01.447.723L14 18.192a2.027 2.027 0 01-3.867-.249l-.991-4.167a.5.5 0 00-.447-.276H8.25a3.25 3.25 0 010-6.5h4.1a.5.5 0 00.5-.5V5.354A2 2 0 0011.6 3.5l-.284-.114a.5.5 0 01-.063-.9l2.721-1.4a.5.5 0 00-.15-.939A11.645 11.645 0 0012 0a12.026 12.026 0 108.953 4.026z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});