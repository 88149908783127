define("ember-svg-jar/inlined/party-mask", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>party-mask</title><path d=\"M24 1.244v-.047a1 1 0 00-.559-.8 1 1 0 00-1.067.125C19.5 2.85 18.5 2.3 16.85 1.4a9.123 9.123 0 00-9.7 0C5.494 2.3 4.5 2.85 1.632.515A1 1 0 000 1.3c.035 2.481.694 10.563 7.694 10.563a10.97 10.97 0 004.3-.832 11.069 11.069 0 004.312.832A6.46 6.46 0 0021.557 9.5a.25.25 0 01.443.155V23a1 1 0 002 0V1.3v-.056zM10.558 7.466c-.4.97-1.781 1.319-3.084.781a14.258 14.258 0 01-3.3-2.922.5.5 0 01.349-.846 14.262 14.262 0 014.4.258c1.302.538 2.035 1.763 1.635 2.729zm9.32-2.141a14.258 14.258 0 01-3.3 2.922c-1.3.538-2.684.189-3.084-.781s.332-2.191 1.635-2.729a14.266 14.266 0 014.4-.258.5.5 0 01.349.846z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});