define("ember-svg-jar/inlined/desktop-computer-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>desktop-computer-2</title><path d=\"M21.351 21.357l-1.785-3.733a1.706 1.706 0 00-1.064-1.05V19.5a2 2 0 01-2 2h-9a2 2 0 01-2-2v-2.927a1.664 1.664 0 00-1.048 1.012L2.635 21.4a2.106 2.106 0 00.106 1.715A1.587 1.587 0 004.134 24h15.735a1.586 1.586 0 001.392-.889 2.119 2.119 0 00.09-1.754z\"/><path d=\"M7 19.5a.5.5 0 00.5.5h1.75a.5.5 0 00.5-.5L9.7.5a.5.5 0 00-.5-.5h-.7A1.5 1.5 0 007 1.5zM16.5 7.251h-4.78a.5.5 0 00-.5.5L11.252 20H16.5a.5.5 0 00.5-.5V7.751a.5.5 0 00-.5-.5zM14 12.5a1 1 0 11-1 1 1 1 0 011-1zM11.715 5.751H16.5a.5.5 0 00.5-.5V1.5A1.5 1.5 0 0015.5 0h-3.8a.5.5 0 00-.5.5l.011 4.75a.5.5 0 00.504.501z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});