define("ember-svg-jar/inlined/beanie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beanie</title><rect x=\"6.5\" y=\"15\" width=\"4.5\" height=\"7\" rx=\".5\" ry=\".5\"/><rect x=\"12.5\" y=\"15\" width=\"5\" height=\"7\" rx=\".5\" ry=\".5\"/><path d=\"M4.5 15h-2A2.5 2.5 0 000 17.5v2A2.5 2.5 0 002.5 22h2a.5.5 0 00.5-.5v-6a.5.5 0 00-.5-.5zM21.5 15h-2a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h2a2.5 2.5 0 002.5-2.5v-2a2.5 2.5 0 00-2.5-2.5zM9.25 13a.5.5 0 00.5.5h4.5a.5.5 0 00.5-.5v-2.957c0-1.473 0-5.758-2.576-7.978A.272.272 0 0012 2a.264.264 0 00-.173.065C9.251 4.284 9.25 8.57 9.25 10.043zM3.5 13.5h3.75a.5.5 0 00.5-.5v-2.957a14.749 14.749 0 011.495-7.149.249.249 0 00-.026-.285.252.252 0 00-.277-.074A9.016 9.016 0 003 11v2a.5.5 0 00.5.5zM16.25 10.043V13a.5.5 0 00.5.5h3.75a.5.5 0 00.5-.5v-2a9.015 9.015 0 00-5.941-8.464.25.25 0 00-.3.359 14.74 14.74 0 011.491 7.148z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});