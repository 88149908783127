define("ember-svg-jar/inlined/single-neutral-actions-information.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-information</title><path d=\"M11.335 12.419a.5.5 0 00-.117-.741 7.475 7.475 0 00-11.2 4.179.5.5 0 00.482.643h8.64a.5.5 0 00.492-.411 7.96 7.96 0 011.703-3.67z\"/><circle cx=\"7\" cy=\"4.75\" r=\"4.75\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm.5 3.249a1 1 0 11-1-1 1 1 0 011 1zM15.25 21a.75.75 0 01.75-.75h.25a.5.5 0 00.5-.5V18a.25.25 0 00-.25-.25H16a.75.75 0 110-1.5h1a1.252 1.252 0 011.25 1.25v2.25a.5.5 0 00.5.5H19a.75.75 0 010 1.5h-3a.75.75 0 01-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});