define("ember-svg-jar/inlined/messages-bubble-square-quotation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-quotation</title><path d=\"M22 1.5H2A1.5 1.5 0 00.5 3v13.553A1.459 1.459 0 002 18h3v4a.5.5 0 00.784.411L12.156 18H22a1.5 1.5 0 001.5-1.5V3A1.5 1.5 0 0022 1.5zm-7 8.75A2.25 2.25 0 1117.25 8v2.517a2.752 2.752 0 01-2.75 2.75.75.75 0 010-1.5 1.251 1.251 0 001.25-1.25v-.4a2.227 2.227 0 01-.75.133zm-3.75.267a2.752 2.752 0 01-2.75 2.75.75.75 0 010-1.5 1.251 1.251 0 001.25-1.25v-.4a2.227 2.227 0 01-.75.133A2.25 2.25 0 1111.25 8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});