define("ember-svg-jar/inlined/file-code-remove-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-remove-1</title><path d=\"M14.679 12.232l.018-.018a.749.749 0 00.018-1.042L12.686 9a.249.249 0 010-.341l2.028-2.174a.75.75 0 00-1.078-1.045l-.018.018-2.666 2.857a.751.751 0 000 1.024l2.666 2.861a.752.752 0 001.061.032zM16.286 11.172a.75.75 0 001.078 1.042l.018-.018 2.666-2.857a.752.752 0 000-1.025l-2.666-2.856a.751.751 0 00-1.061-.036.731.731 0 00-.036 1.06l2.029 2.174a.25.25 0 010 .342z\"/><path d=\"M23.414 3.032L21 .618a2 2 0 00-1.414-.586H8a2 2 0 00-2 2v7.213a.247.247 0 00.242.25 19.65 19.65 0 011.485.082A.246.246 0 008 9.329v-6.8a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.147L21.854 4.3a.5.5 0 01.146.353v13.379a.5.5 0 01-.5.5h-6.869a.244.244 0 00-.241.209 7.973 7.973 0 01-.373 1.458.244.244 0 00.228.332H22a2 2 0 002-2V4.447a2 2 0 00-.586-1.415z\"/><path d=\"M6.5 10.97a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.652 9.151a.75.75 0 01-1.061 0l-1.414-1.414a.25.25 0 00-.354 0l-1.414 1.414a.75.75 0 11-1.06-1.061l1.413-1.414a.249.249 0 000-.353l-1.414-1.414a.75.75 0 011.061-1.061l1.414 1.414a.25.25 0 00.354 0l1.414-1.414a.75.75 0 011.06 1.061l-1.414 1.414a.25.25 0 000 .353l1.415 1.414a.752.752 0 010 1.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});