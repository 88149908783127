define("ember-svg-jar/inlined/cap-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cap-3</title><path d=\"M6.471 5.271a1 1 0 00-.877-.047A9.178 9.178 0 00.114 15.1a1.459 1.459 0 00.66 1 1.48 1.48 0 00.792.23 1.458 1.458 0 00.408-.058l9.431-2.728a.5.5 0 00.344-.613c-.874-3.182-2.782-6.27-5.278-7.66zM17.981 11.118A9.187 9.187 0 008.792 4.5a.251.251 0 00-.15.442 15.258 15.258 0 014.545 7.558.5.5 0 00.238.3.5.5 0 00.383.044l3.832-1.108a.5.5 0 00.341-.618zM23.972 15.9A1.516 1.516 0 0023 14.772L18.274 13.3a.508.508 0 00-.289 0l-9.194 2.66a.5.5 0 00-.046.945l4.839 1.935a9.827 9.827 0 003.636.671 9.456 9.456 0 006.3-2.211 1.509 1.509 0 00.452-1.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});