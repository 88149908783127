define("ember-svg-jar/inlined/ecology-leaf-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ecology-leaf-shield</title><path d=\"M17.3 9.5a.5.5 0 00.428-.248A13.167 13.167 0 0019.084.727a.871.871 0 00-1.35-.575 9.8 9.8 0 01-5.581 1.415C7.54 1.567 5.6 3.463 5.577 3.5A6.081 6.081 0 003.16 9.189a.5.5 0 01-.166.442c-1.158 1.015-1.975 1.96-2.724 2.585a.75.75 0 10.96 1.152c1.921-1.6 3.888-4.667 9.777-6.7a.75.75 0 01.49 1.417 18.288 18.288 0 00-6.612 3.857.5.5 0 00-.156.355.5.5 0 00.145.361 6.055 6.055 0 004.306 1.8.32.32 0 00.32-.32v-.68a3.044 3.044 0 011.829-2.791A17.554 17.554 0 0117.3 9.5z\"/><path d=\"M23.072 12.041A16.393 16.393 0 0017.478 11a15.968 15.968 0 00-5.55 1.04A1.544 1.544 0 0011 13.457v3.392a7.716 7.716 0 005.19 6.806l.553.21a2.057 2.057 0 001.471 0l.553-.21C21.848 22.481 24 19.682 24 16.849v-3.392a1.544 1.544 0 00-.928-1.416zM20.5 18H19a.5.5 0 00-.5.5V20a1 1 0 11-2 0v-1.5a.5.5 0 00-.5-.5h-1.5a1 1 0 010-2H16a.5.5 0 00.5-.5V14a1 1 0 112 0v1.5a.5.5 0 00.5.5h1.5a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});