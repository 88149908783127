define("ember-svg-jar/inlined/style-one-pin-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-user</title><path d=\"M12 0a9.5 9.5 0 00-4 18.119 2.509 2.509 0 011.218 1.222l1.872 4.076a1 1 0 001.817 0l1.876-4.079A2.5 2.5 0 0116 18.118 9.5 9.5 0 0012 0zm0 2.5a3 3 0 11-3 3 3 3 0 013-3zm4 12H8a.5.5 0 01-.5-.5 4.5 4.5 0 019 0 .5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});