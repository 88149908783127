define("ember-svg-jar/inlined/iris-scan-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>iris-scan-search</title><circle cx=\"11.998\" cy=\"7\" r=\"1.5\"/><path d=\"M12.038 11.113a.064.064 0 00.02-.071A.066.066 0 0012 11a4 4 0 114-4 3.924 3.924 0 01-.659 2.177.25.25 0 00.243.386A6.742 6.742 0 0116.5 9.5a6.957 6.957 0 013.97 1.243.251.251 0 00.291 0 21.034 21.034 0 002.662-2.322 2.034 2.034 0 000-2.837C20.8 2.869 16.485 0 12.145 0h-.293C7.511 0 3.191 2.87.576 5.58a2.037 2.037 0 000 2.836A18.638 18.638 0 009.828 13.8a.249.249 0 00.275-.143 7.025 7.025 0 011.935-2.544z\"/><path d=\"M23.632 21.87l-2.276-2.281a.251.251 0 01-.039-.3 5.451 5.451 0 00.741-2.756 5.53 5.53 0 10-5.53 5.53 5.447 5.447 0 002.756-.741.25.25 0 01.3.039l2.287 2.272a1.23 1.23 0 001.759 0 1.236 1.236 0 00.002-1.763zm-7.1-1.81a3.53 3.53 0 113.53-3.53 3.536 3.536 0 01-3.534 3.53z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});