define("ember-svg-jar/inlined/road-sign-traffic-left-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-traffic-left-alternate</title><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-11.53 10.116a.25.25 0 01-.354 0l-9.232-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/><path d=\"M9.47 7.47l-2 2A.75.75 0 008 10.75h.75A.25.25 0 019 11v5a1 1 0 002 0v-5a.25.25 0 01.25-.25H12a.75.75 0 00.53-1.28l-2-2a.749.749 0 00-1.06 0zM13.813 13.726a.25.25 0 01.187.242V16a1 1 0 002 0v-2.032a.25.25 0 01.187-.242A.75.75 0 0016 12.25h-2a.75.75 0 00-.187 1.476z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});