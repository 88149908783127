define("ember-svg-jar/inlined/outdoors-tree-gate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-tree-gate</title><path d=\"M23.819 16.466l-2.038-2.912a.327.327 0 01.268-.515 1 1 0 00.843-1.537L20.5 7.748a.248.248 0 01.083-.349 1.014 1.014 0 00.36-.367 1 1 0 00-.013-1.008l-2.542-4.242a.5.5 0 00-.857 0l-2.546 4.242a1 1 0 00-.013 1.008 1.014 1.014 0 00.36.367.25.25 0 01.084.349l-2.057 3.232a.5.5 0 00.208.721A2.5 2.5 0 0115 13.961v3.578a.5.5 0 00.5.5h.711a.5.5 0 01.5.5v2.672a1.25 1.25 0 002.5 0v-2.672a.5.5 0 01.5-.5H23a1 1 0 00.82-1.573z\"/><path d=\"M12.5 12.461a1.5 1.5 0 00-1.5 1.5v.75a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5v-.75a1.5 1.5 0 10-3 0v.75a.5.5 0 01-.5.5H3.5a.5.5 0 01-.5-.5v-.75a1.5 1.5 0 00-3 0v7.5a1 1 0 001 1h1a1 1 0 001-1v-.25a.5.5 0 01.5-.5H5a.5.5 0 01.5.5v.25a1 1 0 001 1h1a1 1 0 001-1v-.25a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5v.25a1 1 0 001 1h1a1 1 0 001-1v-7.5a1.5 1.5 0 00-1.5-1.5zm-7 5.75a.5.5 0 01-.5.5H3.5a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5H5a.5.5 0 01.5.5zm5.5 0a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});