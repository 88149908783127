define("ember-svg-jar/inlined/tower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tower</title><path d=\"M12 14.749a5.5 5.5 0 005.08-3.4.251.251 0 00-.231-.349h-9.7a.251.251 0 00-.231.346A5.5 5.5 0 0012 14.749z\"/><path d=\"M19.49 8.363a1 1 0 00-.99-.863H18a.5.5 0 01-.5-.5V5.753a.254.254 0 01.254-.254 1 1 0 00.529-1.848L12.8.228a1.5 1.5 0 00-1.588 0L5.717 3.651A1 1 0 006.246 5.5a.254.254 0 01.254.254V7a.5.5 0 01-.5.5h-.5a1 1 0 00-.99.864l-2 14.5a1 1 0 001.79.736.5.5 0 01.182-.15l7.3-3.531a.5.5 0 01.436 0l7.316 3.539a.493.493 0 01.179.145.986.986 0 00.924.385 1 1 0 00.853-1.127zM17.193 9.5a.5.5 0 01.495.432l.586 4.244a.5.5 0 01-.278.518l-5.778 2.8a.5.5 0 01-.436 0L6 14.693a.5.5 0 01-.278-.518l.586-4.244a.5.5 0 01.5-.432zM5.613 20.683a.5.5 0 01-.713-.519l.4-2.864a.5.5 0 01.713-.382l2.764 1.337a.5.5 0 010 .9zm12.379-3.766a.5.5 0 01.713.382l.4 2.865a.5.5 0 01-.713.519l-3.159-1.529a.5.5 0 010-.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});