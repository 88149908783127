define("ember-svg-jar/inlined/tags-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-upload</title><path d=\"M17.558 9.76a.5.5 0 00.367-.15l1.174-1.2A3.791 3.791 0 0020.085 6V2a2 2 0 00-2-2l-4.258.005a3.747 3.747 0 00-2.414 1L.585 11.834a2 2 0 000 2.828L5.421 19.5a1.972 1.972 0 001.406.582h.012a1.975 1.975 0 001.41-.6l1.036-1.057a.5.5 0 00.142-.369 7.976 7.976 0 018.131-8.296zM12.585 5.5a2 2 0 112 2 2 2 0 01-2-2z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-1 9.5v-2.75a.25.25 0 00-.25-.25h-.75a.5.5 0 01-.391-.813l2-2.5a.52.52 0 01.782 0l2 2.5a.5.5 0 01-.391.813h-.75a.25.25 0 00-.25.25v2.75a1 1 0 01-2 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});