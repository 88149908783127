define("ember-svg-jar/inlined/share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>share</title><path d=\"M19 14a4.976 4.976 0 00-3.586 1.53l-5.493-2.747A4.948 4.948 0 0010 12a4.935 4.935 0 00-.079-.783l5.493-2.747A4.991 4.991 0 1014 5a4.948 4.948 0 00.079.784L8.586 8.529a5 5 0 100 6.941l5.493 2.746A4.935 4.935 0 0014 19a5 5 0 105-5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});