define("ember-svg-jar/inlined/information-desk-paper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>information-desk-paper</title><path d=\"M14.809 15.231a2.512 2.512 0 01-2.79-2.17c0-.023-.005-.05-.007-.08a.251.251 0 00-.249-.231H10a1 1 0 000 2h1.6l.9 8.553a.5.5 0 00.5.447h4a.5.5 0 00.5-.5v-8.339a.25.25 0 00-.331-.237 11.762 11.762 0 01-2.36.557z\"/><circle cx=\"2.5\" cy=\"3.25\" r=\"2.5\"/><path d=\"M8.12 5.1a2.5 2.5 0 014.014 2.94.254.254 0 00-.014.232.25.25 0 00.183.143.976.976 0 00.2.021 1 1 0 00.982-.8l1.075-5.384a1 1 0 00-.786-1.176L9.755.27a1 1 0 00-1.177.784L7.8 4.945a.186.186 0 00.1.2.184.184 0 00.22-.045z\"/><path d=\"M11.127 7.739a1.5 1.5 0 10-2.254-1.978C7.216 7.651 5.989 7.6 5.44 7.6a4.038 4.038 0 01-2.14-.622A1.5 1.5 0 001 8.25v14a1.5 1.5 0 003 0v-11.8a7.011 7.011 0 001.439.149 7.16 7.16 0 005.688-2.86zM20.3 7.35l-.583.775a8.04 8.04 0 01-5.4 3.137 1.5 1.5 0 00.374 2.976A11.042 11.042 0 0020 12.058V22.25a1.5 1.5 0 003 0v-14a1.5 1.5 0 00-2.7-.9z\"/><circle cx=\"21.5\" cy=\"3.25\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});