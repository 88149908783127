define("ember-svg-jar/inlined/real-estate-sign-sale-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-sign-sale-1</title><path d=\"M10.878 13.409a.5.5 0 00-.529.468L10.034 19a.5.5 0 00.521.531c.77-.034 1.346-.035 1.968-.026a.5.5 0 00.506-.469l.31-4.976a.5.5 0 00-.469-.53zM23.734 23.058A25.57 25.57 0 0012 20.5 25.57 25.57 0 00.266 23.058.5.5 0 00.5 24h23a.5.5 0 00.234-.942zM10.189 6.227l.249.015a.251.251 0 00.265-.234l.068-1.122a.375.375 0 10-.748-.047l-.068 1.123a.25.25 0 00.234.265z\"/><path d=\"M3.949 11.98l15.97.98a2.5 2.5 0 002.648-2.343L23 3.631A2.505 2.505 0 0020.652.982L4.684 0a2.5 2.5 0 00-2.649 2.346l-.429 6.987a2.5 2.5 0 002.343 2.647zM16.76 5.253a1.629 1.629 0 011.721-1.524l1 .062a.625.625 0 01.586.662.639.639 0 01-.662.586l-1-.061a.37.37 0 00-.4.352l-.069 1.122a.251.251 0 00.064.181.253.253 0 00.172.084l1.123.068a.625.625 0 01-.076 1.248L18.1 7.965a.249.249 0 00-.265.234l-.008.124a.378.378 0 00.353.4l1 .06a.625.625 0 11-.076 1.248l-1-.06a1.629 1.629 0 01-1.524-1.722zM12.829 4.01a.625.625 0 111.248.076l-.245 3.992a.376.376 0 00.352.4l1 .061a.625.625 0 11-.084 1.246l-1-.061A1.628 1.628 0 0112.584 8zM8.652 6.759l.122-1.993a1.626 1.626 0 013.245.2l-.246 3.993a.625.625 0 11-1.248-.076l.075-1.128a.25.25 0 00-.234-.265l-.249-.015a.251.251 0 00-.181.062.253.253 0 00-.084.172l-.074 1.123a.636.636 0 01-.662.586.626.626 0 01-.586-.662l.123-1.995zM5.2 7.925l1 .061a.375.375 0 00.4-.35c.025-.418-.218-.646-.688-1.045a2.451 2.451 0 01-1.13-2.072A1.63 1.63 0 016.5 3l1 .062a.625.625 0 01.586.662.638.638 0 01-.662.586l-1-.062a.385.385 0 00-.4.353c-.025.416.218.643.688 1.043a2.448 2.448 0 011.131 2.067 1.625 1.625 0 01-1.721 1.523l-1-.061A.625.625 0 115.2 7.925z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});