define("ember-svg-jar/inlined/wedding-couple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wedding-couple</title><path d=\"M12.715 6.485l2.618-2.674a2.2 2.2 0 00.426-2.582 2.285 2.285 0 00-3.526-.667.353.353 0 01-.465 0 2.285 2.285 0 00-3.527.666A2.2 2.2 0 008.66 3.8l2.626 2.682a1 1 0 001.428 0zM6.573 4.582a1 1 0 00-1.131-1.65A12.506 12.506 0 000 13.25V23a1 1 0 002 0v-9.75a10.5 10.5 0 014.573-8.668zM18.558 2.932a1 1 0 00-1.131 1.65A10.5 10.5 0 0122 13.25V23a1 1 0 002 0v-9.75a12.506 12.506 0 00-5.442-10.318z\"/><path d=\"M9.6 13.47a.247.247 0 01-.119-.2.251.251 0 01.094-.211 2.918 2.918 0 10-3.616 0 .251.251 0 01-.024.409 3.514 3.514 0 00-1.682 3v2.011a.5.5 0 00.5.5h.6a.5.5 0 01.5.45l.413 4.123a.5.5 0 00.5.45h2.01a.5.5 0 00.5-.45l.412-4.123a.5.5 0 01.5-.45h.6a.5.5 0 00.5-.5v-2.012A3.513 3.513 0 009.6 13.47zm-1.836-1.28a1.419 1.419 0 01-1.418-1.418 1.478 1.478 0 01.028-.284.25.25 0 01.367-.168 3.576 3.576 0 001.746.452 3.453 3.453 0 00.4-.022.252.252 0 01.212.079.255.255 0 01.061.218 1.42 1.42 0 01-1.396 1.143zM19.585 15.933a2.944 2.944 0 00-1.333-1.7.25.25 0 010-.433 3.042 3.042 0 10-3.006 0 .25.25 0 010 .433 2.95 2.95 0 00-1.334 1.7l-1.192 3.921a.5.5 0 00.079.447.594.594 0 00.407.2l.867-.012a.5.5 0 01.505.45l.261 2.612a.5.5 0 00.5.45h2.821a.5.5 0 00.5-.45l.261-2.612a.5.5 0 01.5-.45l.868.012a.605.605 0 00.406-.2.5.5 0 00.079-.447zM16.751 12.7a1.543 1.543 0 01-1.451-1.01.248.248 0 01.152-.322 3.056 3.056 0 001.12-.7.251.251 0 01.349 0 3.06 3.06 0 001.121.7.251.251 0 01.152.322 1.543 1.543 0 01-1.443 1.01z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});