define("ember-svg-jar/inlined/professions-man-doctor-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-doctor-1</title><path d=\"M0 20.355v2.606a1 1 0 002 0v-2.606a.971.971 0 01.377-.784c1.194-.912 3.856-2.4 8.613-2.587a.245.245 0 01.183.07.25.25 0 01.077.18v5.727a.75.75 0 001.5 0v-5.727a.25.25 0 01.077-.18.245.245 0 01.183-.07c4.757.189 7.419 1.675 8.614 2.587a.973.973 0 01.376.784v2.606a1 1 0 002 0v-2.606a2.957 2.957 0 00-1.163-2.374 15.686 15.686 0 00-7.587-2.815.251.251 0 01-.214-.206.249.249 0 01.133-.265A7 7 0 0019 8.462v-6.5a1 1 0 00-.4-.8A8.07 8.07 0 0016.3.087a1 1 0 10-.6 1.906 8.252 8.252 0 011.163.463.25.25 0 01.137.224v2.782a.25.25 0 01-.25.25H16a.75.75 0 000 1.5h.75a.25.25 0 01.25.25v1a4.92 4.92 0 01-.624 2.387.25.25 0 01-.358.087 7.209 7.209 0 00-8.036 0 .248.248 0 01-.2.036.251.251 0 01-.16-.122A4.92 4.92 0 017 8.462v-1a.25.25 0 01.25-.25H8a.75.75 0 000-1.5h-.75a.25.25 0 01-.25-.25V2.68a.25.25 0 01.139-.224A8.3 8.3 0 018.3 1.993 1 1 0 107.7.087a8.07 8.07 0 00-2.3 1.076 1 1 0 00-.4.8v6.5A7 7 0 008.831 14.7a.249.249 0 01.133.265.251.251 0 01-.214.206 15.688 15.688 0 00-7.586 2.809A2.958 2.958 0 000 20.355zM15.216 12.2a.25.25 0 01.015.4 5.25 5.25 0 01-6.462 0 .25.25 0 01.015-.4 5.913 5.913 0 016.432 0z\"/><path d=\"M7.75 22.461a.25.25 0 01.25-.25h.5a.75.75 0 000-1.5H8a.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25h-.5a.75.75 0 000 1.5H6a.25.25 0 01.25.25v.5a.75.75 0 001.5 0z\"/><circle cx=\"14.75\" cy=\"19.212\" r=\"1\"/><path d=\"M15.75 22.212a1 1 0 00-2 0 1 1 0 002 0zM12 .212a3.25 3.25 0 103.25 3.25A3.254 3.254 0 0012 .212zm0 2.25a1 1 0 11-1 1 1 1 0 011-1z\"/><circle cx=\"14\" cy=\"8.462\" r=\".75\"/><circle cx=\"10\" cy=\"8.462\" r=\".75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});