define("ember-svg-jar/inlined/hat-winter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hat-winter</title><path d=\"M19 15.5h-1a.5.5 0 00-.5.5v2a2.5 2.5 0 01-2.569 2.5.5.5 0 00-.507.416 5.561 5.561 0 01-.233.905.494.494 0 00.052.437.5.5 0 00.377.227c.126.011.252.016.38.016A4.505 4.505 0 0019.5 18v-2a.5.5 0 00-.5-.5zM2.5 11h7.75a.25.25 0 00.25-.25V10a6.5 6.5 0 018.058-6.311.251.251 0 00.3-.305A4.509 4.509 0 0014.5 0h-4A8.51 8.51 0 002 8.5v2a.5.5 0 00.5.5z\"/><path d=\"M17 5a5.006 5.006 0 00-5 5v2a.5.5 0 01-.5.5h-1a.5.5 0 00-.5.5v1a2 2 0 01-4 0v-1a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v2a2 2 0 002 2 1 1 0 011 1v2a4 4 0 008 0v-5.5a.5.5 0 01.5-.5h6a2.5 2.5 0 002.5-2.5V10a5.006 5.006 0 00-5-5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});