define("ember-svg-jar/inlined/wine-barrel-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wine-barrel-1</title><path d=\"M20.7 12.986a.5.5 0 00-.5-.017A18.592 18.592 0 0112 14.75a18.615 18.615 0 01-8.2-1.781.5.5 0 00-.734.458c.034.984.1 1.884.2 2.679a.49.49 0 00.153.3C4.536 17.481 7.8 18.75 12 18.75s7.462-1.268 8.589-2.338a.5.5 0 00.153-.3c.091-.8.162-1.7.2-2.68a.5.5 0 00-.242-.446z\"/><path d=\"M12 13.25c4.64 0 8.135-1.547 8.874-2.664a.266.266 0 00.042-.16 35.015 35.015 0 00-.263-2.884.5.5 0 00-.818-.316C18.17 8.63 15.371 9.5 12 9.5s-6.169-.87-7.836-2.274a.5.5 0 00-.818.316 35.36 35.36 0 00-.263 2.885.264.264 0 00.043.159C3.865 11.7 7.36 13.25 12 13.25zM19.491 18.812A19.484 19.484 0 0112 20.25a19.49 19.49 0 01-7.492-1.438.5.5 0 00-.687.573c.1.442.182.7.2.753C4.065 20.3 5.2 24 12 24s7.936-3.7 7.978-3.853c.016-.051.094-.316.2-.762a.5.5 0 00-.687-.573z\"/><path d=\"M12 8c4.486 0 8-1.757 8-4s-3.514-4-8-4-8 1.757-8 4 3.514 4 8 4zM9 3c.828 0 1.5.447 1.5 1S9.828 5 9 5s-1.5-.447-1.5-1S8.172 3 9 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});