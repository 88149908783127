define("ember-svg-jar/inlined/religion-man-muslim-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-man-muslim-1</title><path d=\"M12 19.88a.75.75 0 00-.75.75v2.5a.75.75 0 001.5 0v-2.5a.75.75 0 00-.75-.75zM14.5 19.38a.75.75 0 10.75.75.75.75 0 00-.75-.75zM14.5 22.38a.75.75 0 10.75.75.75.75 0 00-.75-.75zM18.075 3.219a3.728 3.728 0 00-3.7-3.1H9.624a3.729 3.729 0 00-3.7 3.1c-.064.382-.975 8.5-.975 8.5A4.772 4.772 0 007.7 16.375l2.313 1.068a4.754 4.754 0 003.982 0l2.305-1.068a4.778 4.778 0 002.746-4.66s-.824-7.615-.971-8.496zM9.624 1.62h4.752A2.232 2.232 0 0116.6 3.469l.477 2.825a.25.25 0 01-.319.281A18.273 18.273 0 0012 6.037a18.311 18.311 0 00-4.752.538.25.25 0 01-.318-.281l.47-2.825A2.231 2.231 0 019.624 1.62zm.783 12.365a.75.75 0 011.052-.134.981.981 0 001.082 0 .75.75 0 11.918 1.186 2.417 2.417 0 01-2.918 0 .749.749 0 01-.134-1.052zm3.463-2.375a5.95 5.95 0 00-3.74 0 1.24 1.24 0 01-1.355-.379l-.014-.019-1.7-2.669a.25.25 0 01.115-.366A14.015 14.015 0 0112 7.537a14.711 14.711 0 014.832.621.25.25 0 01.121.368l-1.683 2.64-.012.017a1.249 1.249 0 01-1.388.427zM22.685 19.348a13.318 13.318 0 00-4.054-2.026.75.75 0 10-.454 1.429 11.79 11.79 0 013.6 1.79 1.219 1.219 0 01.476.982v1.607a.75.75 0 001.5 0v-1.607a2.711 2.711 0 00-1.068-2.175zM5.608 17.248a13.442 13.442 0 00-4.294 2.1A2.713 2.713 0 00.25 21.523v1.607a.75.75 0 001.5 0v-1.607a1.22 1.22 0 01.475-.982 11.962 11.962 0 013.817-1.858.75.75 0 10-.434-1.435z\"/><path d=\"M9.875 8.027a1.822 1.822 0 00-1.646.873.751.751 0 001.292.765c.021-.036.078-.133.354-.133s.333.1.355.133a.75.75 0 00.645.367.74.74 0 00.383-.1.75.75 0 00.262-1.032 1.82 1.82 0 00-1.645-.873zM15.52 8.9a1.82 1.82 0 00-1.645-.868 1.822 1.822 0 00-1.646.868.751.751 0 001.292.765c.021-.036.078-.133.354-.133s.333.1.355.133a.75.75 0 00.645.367.74.74 0 00.383-.1.75.75 0 00.262-1.032z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});