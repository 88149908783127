define("ember-svg-jar/inlined/artboard-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>artboard-image</title><path d=\"M24 17.5a1.5 1.5 0 00-1.5-1.5H21V5a2 2 0 00-2-2H8.5V1.5A1.5 1.5 0 007 0H4.5A1.5 1.5 0 003 1.5V3H1.5A1.5 1.5 0 000 4.5V7a1.5 1.5 0 001.5 1.5H3V19a2 2 0 002 2h10.5v1.5A1.5 1.5 0 0017 24h2.5a1.5 1.5 0 001.5-1.5V21h1.5a1.5 1.5 0 001.5-1.5zm-2 1.25a.25.25 0 01-.25.25H20a1 1 0 00-1 1v1.75a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25V20a1 1 0 00-1-1h-11a.5.5 0 01-.5-.5v-11a1 1 0 00-1-1H2.25A.25.25 0 012 6.25v-1A.25.25 0 012.25 5H4a1 1 0 001-1V2.25A.25.25 0 015.25 2h1a.25.25 0 01.25.25V4a1 1 0 001 1h11a.5.5 0 01.5.5V17a1 1 0 001 1h1.75a.25.25 0 01.25.25z\"/><path d=\"M13.367 10.848a.751.751 0 01.618-.348.735.735 0 01.632.323l1.928 2.782a.25.25 0 00.455-.142V8.25A1.252 1.252 0 0015.75 7h-7.5A1.252 1.252 0 007 8.25v7.5A1.252 1.252 0 008.25 17h1.065a.251.251 0 00.211-.116zM10.5 12a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/><path d=\"M14.241 12.915a.25.25 0 00-.417.008l-2.35 3.693a.25.25 0 00.211.384h4.065a1.2 1.2 0 00.829-.339.251.251 0 00.033-.324z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});