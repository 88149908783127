define("ember-svg-jar/inlined/road-sign-no-u-turn-left-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-no-u-turn-left-alternate</title><path d=\"M11.979 6.613a4.413 4.413 0 00-4.408 4.408v.443a.245.245 0 01-.435.155l-.068-.083a.734.734 0 10-1.137.93l2.051 2.51a.734.734 0 00.527.269h.041a.737.737 0 00.514-.209l2.571-2.51a.735.735 0 00-1.026-1.052l-.663.647a.245.245 0 01-.416-.176v-.925a2.449 2.449 0 014.9 0v5.88a.98.98 0 101.959 0v-5.879a4.413 4.413 0 00-4.41-4.408z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22.041a9.988 9.988 0 01-6.165-2.121.245.245 0 01-.023-.366l2.4-2.4a.979.979 0 10-1.385-1.385L4.43 18.165a.242.242 0 01-.189.071.245.245 0 01-.178-.094A10.035 10.035 0 0118.142 4.063a.246.246 0 01.023.367l-2.437 2.437a.979.979 0 101.385 1.385l2.44-2.44a.244.244 0 01.367.023A10.036 10.036 0 0112 22.041z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});