define("ember-svg-jar/inlined/accounting-coins-stack-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accounting-coins-stack-1</title><rect x=\"1.75\" y=\"1.5\" width=\"9\" height=\"3\" rx=\"1\" ry=\"1\"/><rect x=\".75\" y=\"6\" width=\"9\" height=\"3\" rx=\"1\" ry=\"1\"/><rect x=\"1.75\" y=\"10.5\" width=\"9\" height=\"3\" rx=\"1\" ry=\"1\"/><rect x=\".75\" y=\"15\" width=\"9\" height=\"3\" rx=\"1\" ry=\"1\"/><rect x=\"1.75\" y=\"19.5\" width=\"9\" height=\"3\" rx=\"1\" ry=\"1\"/><rect x=\"13.25\" y=\"10.5\" width=\"9\" height=\"3\" rx=\"1\" ry=\"1\"/><rect x=\"14.25\" y=\"15\" width=\"9\" height=\"3\" rx=\"1\" ry=\"1\"/><rect x=\"13.25\" y=\"19.5\" width=\"9\" height=\"3\" rx=\"1\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});