define("ember-svg-jar/inlined/gesture-swipe-horizontal-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-horizontal-3</title><path d=\"M16.419 12.527l-3.741-1.115a.251.251 0 01-.179-.24V6.6a1 1 0 00-2 0v7.065a.25.25 0 01-.455.143c-1.49-2.138-3.856-1.45-2.762.221l2.912 4.37a.5.5 0 00.416.222h5.435a.5.5 0 00.492-.41c.2-1.1.662-3.675.775-4.166a1.282 1.282 0 00-.893-1.518zM6.25 7.624h-2.5a.25.25 0 01-.25-.25v-1.25a.751.751 0 00-1.336-.468l-2 2.5a.75.75 0 000 .937l2 2.5a.751.751 0 001.336-.469v-1.25a.25.25 0 01.25-.25h2.5a1 1 0 000-2zM23.836 9.093a.75.75 0 000-.937l-2-2.5a.751.751 0 00-1.336.468v1.25a.25.25 0 01-.25.25h-2.5a1 1 0 000 2h2.5a.25.25 0 01.25.25v1.25a.751.751 0 001.336.469z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});