define("ember-svg-jar/inlined/protection-helmet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>protection-helmet</title><path d=\"M12 0a9.01 9.01 0 00-9 9v8.871a.97.97 0 00.088.409c1.077 2.4 3.64 4.234 5.752 5.566a1.007 1.007 0 001.069 0 1.008 1.008 0 00.453-.988L9.129 15.3a1 1 0 00-.847-.864 26.65 26.65 0 01-1.891-.347A.5.5 0 016 13.6V9.868a.5.5 0 01.612-.487 23.976 23.976 0 002.667.451.5.5 0 01.425.362l1.815 6.444a.519.519 0 00.962 0l1.819-6.444a.5.5 0 01.425-.362 23.976 23.976 0 002.667-.451.5.5 0 01.608.487V13.6a.5.5 0 01-.391.488c-.743.165-1.5.3-2.259.4a.5.5 0 00-.428.416l-1.287 7.969a1 1 0 001.525.972c2.112-1.332 4.675-3.169 5.753-5.569a1.008 1.008 0 00.087-.405V9a9.01 9.01 0 00-9-9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});