define("ember-svg-jar/inlined/aircraft-chopper-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>aircraft-chopper-1</title><path d=\"M11.02 5.633h7.592a.98.98 0 100-1.96H11.02a.244.244 0 01-.244-.244v-.735a.98.98 0 00-1.96 0v.735a.244.244 0 01-.245.244H.98a.98.98 0 100 1.96h7.591a.244.244 0 01.245.245v1.469a.244.244 0 01-.245.245h-.244C4.226 7.592.98 10.964.98 13.96a4.769 4.769 0 001.185 3.055 4.265 4.265 0 00.63.593.246.246 0 01.092.225l-.3 2.274a.246.246 0 01-.243.214h-.385a.248.248 0 01-.2-.1.979.979 0 00-1.759.595 1.471 1.471 0 001.469 1.47h11.266a.98.98 0 000-1.959h-.877a.247.247 0 01-.243-.215l-.242-1.968a.242.242 0 01.121-.242 7.107 7.107 0 003.025-4.449.242.242 0 01.183-.179l3.292-.746a.245.245 0 00.13-.4 3.874 3.874 0 01-.981-2.576A3.825 3.825 0 0117.5 7.94a.243.243 0 00-.015-.235.248.248 0 00-.207-.113H11.02a.244.244 0 01-.244-.245V5.878a.244.244 0 01.244-.245zm-7.75 9.552a2.642 2.642 0 01-.331-1.225c0-1.938 2.454-4.409 5.388-4.409H9.1a.246.246 0 01.238.3 7.4 7.4 0 01-5.808 5.458.244.244 0 01-.26-.124zm6.3 5.059a.248.248 0 01-.184.083H4.823a.245.245 0 01-.242-.277l.191-1.469a.245.245 0 01.243-.213h4.194a.244.244 0 01.243.215l.182 1.469a.25.25 0 01-.06.192zm1.254-8.689c.072-.176.139-.353.2-.533a.249.249 0 01.1-.126.694.694 0 01.385-.12h.98a.735.735 0 010 1.469h-.98a.722.722 0 01-.691-.529.247.247 0 01.01-.161z\"/><path d=\"M23.02 8.571h-1.252a.245.245 0 01-.212-.122l-.871-1.51a.98.98 0 00-1.7.98l.871 1.51a.242.242 0 010 .244l-.871 1.509a.98.98 0 001.7.98l.871-1.509a.245.245 0 01.212-.122h1.252a.98.98 0 000-1.96z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});