define("ember-svg-jar/inlined/american-football-score", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>american-football-score</title><path d=\"M7.5 1a1 1 0 00-2 0v5.5a1 1 0 002 0zM22.5 0a1 1 0 00-1 1v11.5a.5.5 0 01-.5.5H8.5a1 1 0 000 2H13a.5.5 0 01.5.5v6a.5.5 0 01-.5.5h-2.5a1 1 0 000 2h8a1 1 0 000-2H16a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5h5.5a2 2 0 002-2V1a1 1 0 00-1-1z\"/><path d=\"M15.552 10.75c1.544-.036 3.23-.864 3.2-2.324-.035-1.471-1.767-2.207-3.3-2.175-1.821.044-3.225 1.065-3.2 2.326.033 1.465 1.767 2.193 3.3 2.173zM7.613 10.037a1 1 0 001.349.424 7.63 7.63 0 011.324-.548A1 1 0 109.713 8a9.566 9.566 0 00-1.675.691 1 1 0 00-.425 1.346zM5.324 10.613a14.543 14.543 0 00-1.632 1.773 1 1 0 101.579 1.228 12.362 12.362 0 011.405-1.527 1 1 0 10-1.352-1.474zM3.368 14.6a1 1 0 00-1.335.467 15.242 15.242 0 00-.833 2.141 1 1 0 101.913.584 13.156 13.156 0 01.725-1.858 1 1 0 00-.47-1.334zM1.747 19.008a1 1 0 00-1.117.868A17.292 17.292 0 00.5 22a1 1 0 002 0 15.2 15.2 0 01.115-1.875 1 1 0 00-.868-1.117z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});