define("ember-svg-jar/inlined/card-game-diamond", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>card-game-diamond</title><path d=\"M15.175 11.4L12.8 8.233a1.03 1.03 0 00-1.6 0L8.825 11.4a1 1 0 000 1.2l2.375 3.167a1 1 0 001.6 0l2.375-3.167a1 1 0 000-1.2zM11.6 15.467z\"/><path d=\"M18.5 24h-13a3 3 0 01-3-3V3a3 3 0 013-3h13a3 3 0 013 3v18a3 3 0 01-3 3zM5.5 2a1 1 0 00-1 1v18a1 1 0 001 1h13a1 1 0 001-1V3a1 1 0 00-1-1z\"/><circle cx=\"7.25\" cy=\"4.75\" r=\"1.25\"/><circle cx=\"16.75\" cy=\"19.25\" r=\"1.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});