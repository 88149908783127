define("ember-svg-jar/inlined/e-commerce-buy-apparel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-buy-apparel</title><path d=\"M8.5 15.5h5.25a.5.5 0 00.5-.5v-2.25a.25.25 0 01.25-.25h.75a.5.5 0 00.485-.621l-.608-2.432a1.249 1.249 0 00-1.213-.947H12.25a.5.5 0 00-.5.5.625.625 0 01-1.25 0 .5.5 0 00-.5-.5H8.336a1.249 1.249 0 00-1.213.947l-.608 2.432A.5.5 0 007 12.5h.75a.25.25 0 01.25.25V15a.5.5 0 00.5.5zM21.251 19.837a.25.25 0 01.078-.407l1.077-.461a.5.5 0 00-.006-.922l-6.29-2.59a.5.5 0 00-.653.652l2.59 6.29a.5.5 0 00.922.007l.461-1.077a.252.252 0 01.183-.147.249.249 0 01.224.069l2.456 2.456a1 1 0 001.414-1.414z\"/><path d=\"M22 0H2a2 2 0 00-2 2v16.694A1.912 1.912 0 002 20.5h12a1 1 0 000-2l-11.5-.016a.5.5 0 01-.5-.5V6a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v9.5a1 1 0 002 0V2a2 2 0 00-2-2zM8 2.5a1 1 0 11-1-1 1 1 0 011 1zm-4.5-1a1 1 0 11-1 1 1 1 0 011-1zm6 1a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});