define("ember-svg-jar/inlined/christmas-postcard-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-postcard-2</title><path d=\"M24 5a1.5 1.5 0 00-1.5-1.5h-21A1.5 1.5 0 000 5v14a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 19zm-4 1.5A1.5 1.5 0 1118.5 8 1.5 1.5 0 0120 6.5zm-8.825 6.467a.5.5 0 00.379.05l1.207-.324a.75.75 0 11.388 1.449l-.742.2a.249.249 0 00-.176.306l.2.742a.75.75 0 01-.531.918.767.767 0 01-.194.026.752.752 0 01-.725-.557l-.323-1.207a.5.5 0 00-.233-.3l-1.3-.751a.25.25 0 00-.375.217v1.5a.5.5 0 00.146.354l.884.884a.75.75 0 11-1.06 1.06l-.543-.543a.252.252 0 00-.354 0l-.543.543a.75.75 0 01-1.06-1.06l.884-.884a.5.5 0 00.146-.354v-1.5a.25.25 0 00-.375-.217l-1.3.751a.5.5 0 00-.233.3l-.322 1.206a.752.752 0 01-.725.557.767.767 0 01-.194-.026.75.75 0 01-.531-.918l.2-.742a.249.249 0 00-.176-.306l-.742-.2a.75.75 0 11.388-1.449l1.207.324a.5.5 0 00.379-.05l1.3-.75a.251.251 0 000-.434l-1.3-.75a.5.5 0 00-.379-.05l-1.207.324a.773.773 0 01-.194.026.75.75 0 01-.194-1.475l.742-.2a.249.249 0 00.176-.306l-.2-.742a.75.75 0 011.45-.387l.323 1.207a.5.5 0 00.233.3l1.3.751a.25.25 0 00.375-.217v-1.5a.5.5 0 00-.151-.349l-.88-.884a.75.75 0 011.06-1.06l.543.543a.252.252 0 00.354 0l.543-.543a.75.75 0 011.06 1.06l-.88.884a.5.5 0 00-.146.354v1.5a.25.25 0 00.375.217l1.3-.751a.5.5 0 00.233-.3l.323-1.207a.75.75 0 011.45.387l-.2.742a.249.249 0 00.176.306l.742.2a.75.75 0 01-.194 1.475.773.773 0 01-.194-.026l-1.207-.324a.5.5 0 00-.379.05l-1.3.75a.251.251 0 000 .434zM21 16.25h-4a.75.75 0 010-1.5h4a.75.75 0 010 1.5zm0-3h-4a.75.75 0 010-1.5h4a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});