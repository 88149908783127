define("ember-svg-jar/inlined/expand-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>expand-horizontal</title><path d=\"M3.293 15.707A1 1 0 005 15v-1.5a.25.25 0 01.25-.25h13.5a.25.25 0 01.25.25V15a1 1 0 001.707.707l3-3a1 1 0 000-1.414l-3-3A1 1 0 0019 9v1.5a.25.25 0 01-.25.25H5V9a1 1 0 00-1.707-.707l-3 3a1 1 0 000 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});