define("ember-svg-jar/inlined/flying-insect-dragonfly-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flying-insect-dragonfly-2</title><path d=\"M12.437 14.009a3.55 3.55 0 01-.874 0 .5.5 0 00-.394.121.5.5 0 00-.169.375V23a1 1 0 002 0v-8.495a.5.5 0 00-.563-.5zM13.556 6.69a3.967 3.967 0 01-1.156-.309 1 1 0 00-.8 0 3.967 3.967 0 01-1.155.309.5.5 0 00-.444.5v3.277a2 2 0 104 0v-3.28a.5.5 0 00-.445-.497zM8 9.5a.5.5 0 00.5-.5V7.08a1 1 0 00-.5-.866 4.041 4.041 0 01-1.039-.865A1 1 0 006.2 5H1a1 1 0 00-.731 1.682A8.886 8.886 0 006.748 9.5zM23.917 5.6A1 1 0 0023 5h-5.2a1 1 0 00-.759.349A4.028 4.028 0 0116 6.214a1 1 0 00-.5.866V9a.5.5 0 00.5.5h1.252a8.886 8.886 0 006.479-2.818 1 1 0 00.186-1.082zM16.75 2.75A2.748 2.748 0 0012.308.584a.5.5 0 01-.616 0A2.749 2.749 0 1010 5.5a2.739 2.739 0 001.692-.584.5.5 0 01.616 0A2.748 2.748 0 0016.75 2.75zM10 4a1.25 1.25 0 111.25-1.25A1.252 1.252 0 0110 4zm4 0a1.25 1.25 0 111.25-1.25A1.252 1.252 0 0114 4zM8.527 10.939a.5.5 0 00-.5-.439H6.854a2 2 0 00-.894.211L1.79 12.8a1.427 1.427 0 00.637 2.7H4.5a6.483 6.483 0 004.779-2.1.5.5 0 00.02-.655 3.537 3.537 0 01-.772-1.806zM22.21 12.8l-4.17-2.085a2 2 0 00-.894-.211h-1.177a.5.5 0 00-.5.439 3.537 3.537 0 01-.772 1.807.5.5 0 00.02.655A6.483 6.483 0 0019.5 15.5h2.073a1.427 1.427 0 00.637-2.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});