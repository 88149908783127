define("ember-svg-jar/inlined/messages-people-man-bubble-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-man-bubble-alternate</title><circle cx=\"12.87\" cy=\"6.25\" r=\"1\"/><circle cx=\"16.12\" cy=\"6.25\" r=\"1\"/><circle cx=\"19.37\" cy=\"6.25\" r=\"1\"/><path d=\"M17.894 0a5.3 5.3 0 00-3.611 1.327 5.366 5.366 0 00-3.79.092A5.564 5.564 0 007.314 4.67a1 1 0 101.88.684 3.562 3.562 0 012.04-2.078 3.4 3.4 0 012.813.119 1 1 0 001.165-.2A3.469 3.469 0 0117.894 2 4.026 4.026 0 0122 6.2a3.845 3.845 0 01-.961 2.632A4 4 0 0117.994 10a1 1 0 00-.825.436 2.273 2.273 0 01-1.675 1.064 1.889 1.889 0 01-1.05-.312 1 1 0 00-1.12 1.658 3.857 3.857 0 002.17.654 4.017 4.017 0 003.006-1.516 5.791 5.791 0 004.009-1.794A5.837 5.837 0 0024 6.2 6.03 6.03 0 0017.894 0z\"/><path d=\"M10.5 17.663V16.28a3.072 3.072 0 00.991-2.43 1.826 1.826 0 00.655-1.5 1.612 1.612 0 00-.22-.859 1.092 1.092 0 00-.245-.276 3.7 3.7 0 00.3-2.338C11.638 7.492 9.891 7 8.5 7c-1.239 0-2.761.39-3.323 1.456a1.153 1.153 0 00-1.02.382c-.511.626-.1 1.654.2 2.367a1.093 1.093 0 00-.268.289 1.611 1.611 0 00-.229.875 1.807 1.807 0 00.65 1.481 3.072 3.072 0 00.99 2.43v1.383C3.624 18.427 0 20.03 0 21v2.5a.5.5 0 00.5.5h6.231l1.025-4.444a.25.25 0 01.488 0L9.269 24H15.5a.5.5 0 00.5-.5V21c0-.97-3.624-2.573-5.5-3.337z\"/><circle cx=\"14.618\" cy=\"16.25\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});