define("ember-svg-jar/inlined/coffee-cold-press", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>coffee-cold-press</title><path d=\"M8.288 6h10.948a.5.5 0 00.5-.526 6 6 0 00-11.955 0A.5.5 0 008.288 6zM23.138 8.061a1.006 1.006 0 00-.9-.561h-7.226a.5.5 0 00-.5.5v9.75a.5.5 0 00.5.5h1.25a.75.75 0 010 1.5h-4.5a.75.75 0 010-1.5h.75a.5.5 0 00.5-.5V8a.5.5 0 00-.5-.5h-9.75a2 2 0 00-2 2v2a7 7 0 006.067 6.93.5.5 0 01.433.5v3.57a1.5 1.5 0 001.5 1.5h10a1.5 1.5 0 001.5-1.5v-9.657a.5.5 0 01.105-.307l2.662-3.422a1.007 1.007 0 00.109-1.053zm-15.876 7.8a.5.5 0 01-.612.487A4.984 4.984 0 012.762 11.5V10a.5.5 0 01.5-.5h3.5a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});