define("ember-svg-jar/inlined/money-wallet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>money-wallet</title><path d=\"M19.625 18.5a3.75 3.75 0 010-7.5h2.25a.25.25 0 00.25-.25v-2.5a2.5 2.5 0 00-2.5-2.5h-17a2.5 2.5 0 00-2.5 2.5v13a2.5 2.5 0 002.5 2.5h17a2.5 2.5 0 002.5-2.5v-2.5a.25.25 0 00-.25-.25z\"/><path d=\"M22.625 12.5h-3a2.25 2.25 0 000 4.5h3a1.506 1.506 0 001.25-1.5V14a1.506 1.506 0 00-1.25-1.5zM19.812 1.176a1.22 1.22 0 00-1.5-.884L5.44 3.757a.25.25 0 00.065.491l14.76.022a.249.249 0 00.243-.311z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});