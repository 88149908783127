define("ember-svg-jar/inlined/modern-music-mix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-music-mix</title><path d=\"M19.5 15.25a.25.25 0 01-.25.25h-4a.25.25 0 01-.25-.25.154.154 0 00-.2-.147 4.477 4.477 0 01-1.3.192c-.105 0-.209 0-.313-.011a.253.253 0 00-.226.112.229.229 0 01-.193.1H8.75a.25.25 0 01-.25-.25v-4A.25.25 0 018.75 11h.05a.2.2 0 00.2-.2 4.46 4.46 0 01.245-1.463A.251.251 0 009.008 9H8.75a.25.25 0 01-.25-.25v-4a.25.25 0 01.25-.25h3.5a.25.25 0 00.25-.25V3.125a3.389 3.389 0 01.018-.35.251.251 0 00-.249-.275H2a2 2 0 00-2 2V22a2 2 0 002 2h17.5a2 2 0 002-2v-8.461a.25.25 0 00-.237-.25 4.484 4.484 0 01-1.421-.31.25.25 0 00-.342.232zm-6.75 2.25a.25.25 0 01.25.25v4a.25.25 0 01-.25.25h-4a.25.25 0 01-.25-.25v-4a.25.25 0 01.25-.25zM6.5 15.25a.25.25 0 01-.25.25h-4a.25.25 0 01-.25-.25v-4a.25.25 0 01.25-.25h4a.25.25 0 01.25.25zM6.25 4.5a.25.25 0 01.25.25v4a.25.25 0 01-.25.25h-4A.25.25 0 012 8.75V5a.5.5 0 01.5-.5zM2 17.75a.25.25 0 01.25-.25h4a.25.25 0 01.25.25v4a.25.25 0 01-.25.25H2.5a.5.5 0 01-.5-.5zM15.25 22a.25.25 0 01-.25-.25v-4a.25.25 0 01.25-.25h4a.25.25 0 01.25.25v3.75a.5.5 0 01-.5.5z\"/><path d=\"M24 2A2 2 0 0021.632.034l-6 1.126A2 2 0 0014 3.125v4.931a.25.25 0 01-.273.25 2.43 2.43 0 00-.227-.011 2.5 2.5 0 102.5 2.552V3.54a.5.5 0 01.408-.491l5-.938A.5.5 0 0122 2.6v3.456a.25.25 0 01-.273.25 2.43 2.43 0 00-.227-.011A2.5 2.5 0 1024 8.847v-.052z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});