define("ember-svg-jar/inlined/e-commerce-shopping-bag-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-shopping-bag_1</title><path d=\"M23.847 21.583l-.831-4.73a1.869 1.869 0 00-1.891-1.486.25.25 0 01-.25-.25v-1a2.75 2.75 0 00-5.5 0v1a.25.25 0 01-.25.25 1.868 1.868 0 00-1.891 1.486l-.832 4.73a1.852 1.852 0 00-.027.315 2.063 2.063 0 002.143 1.969h7.213a2.064 2.064 0 002.144-1.967 1.863 1.863 0 00-.028-.317zm-6.972-7.466a1.25 1.25 0 012.5 0v1a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25zM2.125 1.117a1.982 1.982 0 00-2 1.958v9.584a1.981 1.981 0 002 1.958h6.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H6.541a1 1 0 000 2h4.584a1 1 0 00.2-1.98.25.25 0 01-.2-.245v-1.05a.25.25 0 01.2-.245 1 1 0 00-.2-1.98l-8.5.04a.5.5 0 01-.5-.5V3.616a.5.5 0 01.5-.5L5.8 3.108a.25.25 0 00.246-.21 3.548 3.548 0 01.544-1.393.25.25 0 00-.209-.388zM13.784 2.765a.25.25 0 00.241.321l3.6-.009a.5.5 0 01.5.5v5.54a1 1 0 002 0V3.075a1.982 1.982 0 00-2-1.958h-4.12a.249.249 0 00-.2.1.252.252 0 00-.036.224 2.246 2.246 0 01.017 1.321z\"/><path d=\"M7.5 3.475a2.084 2.084 0 001.315 1.942l2.063.825a.592.592 0 01-.1 1.129.579.579 0 01-.119.012H8.625a.75.75 0 00-.151 1.485.831.831 0 00.151.015h.5a.25.25 0 01.25.25v.25a.751.751 0 00.6.735.768.768 0 00.3 0 .751.751 0 00.6-.735v-.3a.25.25 0 01.2-.245 2.092 2.092 0 00.36-3.992l-2.063-.822A.588.588 0 019 3.475a.594.594 0 01.31-.521l.052-.025a.651.651 0 01.111-.029.579.579 0 01.119-.012h2.033a.831.831 0 00.151-.015.75.75 0 00-.151-1.485h-.5a.25.25 0 01-.25-.25V.883a.75.75 0 10-1.5 0v.3a.25.25 0 01-.2.245A2.1 2.1 0 007.5 3.475z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});