define("ember-svg-jar/inlined/multiple-actions-share-1.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-share-1</title><path d=\"M23.382 10.577c-.454-.907-1.9-1.44-3.911-2.179l-.486-.172a1.8 1.8 0 01-.055-1.172 4.647 4.647 0 001.216-3.569A3.22 3.22 0 0017 0a2.994 2.994 0 00-1.342.314.25.25 0 00-.1.363 5 5 0 01.842 2.808 6.266 6.266 0 01-.922 3.624.25.25 0 00.121.372c1.918.716 3.3 1.328 3.9 2.537a8.734 8.734 0 01.75 3.482 1.507 1.507 0 01-.016.214.254.254 0 00.058.2.251.251 0 00.19.086H23.5a.5.5 0 00.5-.5 7.542 7.542 0 00-.618-2.923z\"/><path d=\"M14.471 8.4l-.486-.172a1.8 1.8 0 01-.055-1.172 4.647 4.647 0 001.216-3.569A3.22 3.22 0 0012 0a3.22 3.22 0 00-3.146 3.485 4.605 4.605 0 001.193 3.538 1.877 1.877 0 010 1.185l-.515.19c-2.007.739-3.457 1.272-3.911 2.179A7.542 7.542 0 005 13.5a.5.5 0 00.5.5h13a.5.5 0 00.5-.5 7.542 7.542 0 00-.618-2.923c-.454-.907-1.904-1.44-3.911-2.177zM1 16.387a1 1 0 00-1 1V23a1 1 0 002 0v-5.613a1 1 0 00-1-1zM12.5 20.193h-7a.348.348 0 01-.322-.386.371.371 0 01.371-.371H7.5a.887.887 0 00.819-1.236 1.9 1.9 0 00-1.814-1.006l-3-.032a.5.5 0 00-.5.5v5.032a.5.5 0 00.5.5h8a2.04 2.04 0 002-1.924v-.2a.971.971 0 00-1.005-.877z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});