define("ember-svg-jar/inlined/style-three-pin-polaroid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-polaroid</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm5.5 14a2.5 2.5 0 01-2.5 2.5H9A2.5 2.5 0 016.5 14V8.495A.5.5 0 017 8h10a.5.5 0 01.5.5zm0-8a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5v-.5a2 2 0 012-2h7a2 2 0 012 2z\"/><path d=\"M8.5 4.5H8a.5.5 0 000 1h.5a.5.5 0 000-1zM11 4.5h-.5a.5.5 0 000 1h.5a.5.5 0 000-1zM12 9a3 3 0 103 3 3 3 0 00-3-3zm2 3.5a.5.5 0 01-1 0v-1a.5.5 0 011 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});