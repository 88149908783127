define("ember-svg-jar/inlined/shipment-plane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-plane</title><path d=\"M6.8 5.41a.25.25 0 00.25-.25V1.91a.25.25 0 00-.25-.25H5.211a1.511 1.511 0 00-1.06.439L1.267 4.983a.25.25 0 00.177.427zM10.9 17.041a.249.249 0 00.039-.347 2.338 2.338 0 01.062-2.942 7.2 7.2 0 011.276-1.022 2.331 2.331 0 012.2-.311l.477.178a.25.25 0 00.337-.234V7.16a.25.25 0 00-.25-.25H.547a.25.25 0 00-.25.25v9a1.5 1.5 0 001.5 1.5h8.278a.249.249 0 00.16-.059c.381-.319.565-.472.665-.56zM8.547 5.16a.25.25 0 00.25.25h5.354a.25.25 0 00.176-.427L11.444 2.1a1.513 1.513 0 00-1.061-.439H8.8a.25.25 0 00-.25.25z\"/><path d=\"M22.248 13.894a1.793 1.793 0 00-1.41.269l-2 1.365a.25.25 0 01-.228.027l-4.651-1.73a.83.83 0 00-.784.11 8.3 8.3 0 00-1.025.788.842.842 0 00.138 1.2.343.343 0 00.033.024L15 17.608a.251.251 0 01.017.414l-.957.709a.25.25 0 01-.276.015l-1.15-.677a.194.194 0 00-.053-.023.772.772 0 00-.712.14c-.839.7-.932.773-1 .854a.775.775 0 00.137 1.091l.015.01 2.871 2.016a.983.983 0 001.084.031l7.853-4.877a1.868 1.868 0 00.871-1.6 1.8 1.8 0 00-.1-.6 1.87 1.87 0 00-1.352-1.217z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});