define("ember-svg-jar/inlined/golf-equipment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>golf-equipment</title><path d=\"M17.207 11.75h-11a.5.5 0 00-.5.5v8.25a3.5 3.5 0 003.5 3.5h5a3.5 3.5 0 003.5-3.5v-8.25a.5.5 0 00-.5-.5zm-3 5.75a2.5 2.5 0 01-5 0v-2a.5.5 0 01.5-.5h4a.5.5 0 01.5.5zM19.207.006a.993.993 0 00-.372.072l-2.362.945a1 1 0 00-.611.744l-1.029 5.476a.316.316 0 01-.626-.059V2.469A1.864 1.864 0 0013.028.728L11.389.072A.984.984 0 0011.018 0a2.314 2.314 0 100 4.627h.689a.5.5 0 01.5.5V7a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.457L7.78 2.026a1 1 0 00-.625-.843L4.994.318a1.009 1.009 0 00-.372-.072 2.33 2.33 0 000 4.659h.939a.5.5 0 01.5.457l.167 1.958a.5.5 0 01-.172.422 1 1 0 00-.349.758v1.25a.5.5 0 00.5.5h11a.5.5 0 00.5-.5V8.5a1 1 0 00-.545-.89.5.5 0 01-.262-.537l.311-1.659a.5.5 0 01.492-.408h1.5a2.5 2.5 0 000-5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});