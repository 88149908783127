define("ember-svg-jar/inlined/multiple-actions-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-heart</title><circle cx=\"14.499\" cy=\"3.501\" r=\"3\"/><path d=\"M11.961 11.33a.25.25 0 00.342.128A4.984 4.984 0 0113.721 11a5.037 5.037 0 013.638.811.251.251 0 00.28 0 5 5 0 011.087-.537.251.251 0 00.16-.288A4.471 4.471 0 0014.5 7.5a4.527 4.527 0 00-3.6 1.76.251.251 0 000 .311 6.984 6.984 0 011.061 1.759zM.5 18h1.81a.25.25 0 01.249.23L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.44-5.312a.25.25 0 01.249-.23h.879a.25.25 0 00.236-.334 5.018 5.018 0 01.226-3.949 4.934 4.934 0 01.691-1.024.251.251 0 00.051-.235A5.5 5.5 0 000 14v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M11.372 14.45a3.517 3.517 0 00.651 4.06l5.112 5.332a.5.5 0 00.721 0l5.1-5.325a3.523 3.523 0 00.659-4.068 3.526 3.526 0 00-5.646-.916l-.305.3a.25.25 0 01-.353 0l-.3-.3a3.526 3.526 0 00-5.646.916z\"/><circle cx=\"14.499\" cy=\"3.501\" r=\"3\"/><path d=\"M11.961 11.33a.25.25 0 00.342.128A4.984 4.984 0 0113.721 11a5.037 5.037 0 013.638.811.251.251 0 00.28 0 5 5 0 011.087-.537.251.251 0 00.16-.288A4.471 4.471 0 0014.5 7.5a4.527 4.527 0 00-3.6 1.76.251.251 0 000 .311 6.984 6.984 0 011.061 1.759zM.5 18h1.81a.25.25 0 01.249.23L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.44-5.312a.25.25 0 01.249-.23h.879a.25.25 0 00.236-.334 5.018 5.018 0 01.226-3.949 4.934 4.934 0 01.691-1.024.251.251 0 00.051-.235A5.5 5.5 0 000 14v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M11.372 14.45a3.517 3.517 0 00.651 4.06l5.112 5.332a.5.5 0 00.721 0l5.1-5.325a3.523 3.523 0 00.659-4.068 3.526 3.526 0 00-5.646-.916l-.305.3a.25.25 0 01-.353 0l-.3-.3a3.526 3.526 0 00-5.646.916z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});