define("ember-svg-jar/inlined/real-estate-action-house-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-house-location</title><path d=\"M11.89 15.157a2.5 2.5 0 013.212-3.23l5.222 1.958a.5.5 0 00.676-.468V12a.5.5 0 01.5-.5h2a.5.5 0 00.344-.863L13.029.408a1.5 1.5 0 00-2.059 0L.156 10.637A.5.5 0 00.5 11.5h2a.5.5 0 01.5.5v10a1 1 0 001 1h10.148a.5.5 0 00.467-.678z\"/><path d=\"M23.352 16.622l-8.774-3.29a1 1 0 00-1.287 1.292l3.322 8.73a.993.993 0 00.933.646h.064a1 1 0 00.909-.761l.845-3.462a.5.5 0 01.365-.367l3.513-.879a1 1 0 00.11-1.906z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});