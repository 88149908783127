define("ember-svg-jar/inlined/messages-people-man-bubble-oval", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-man-bubble-oval</title><circle cx=\"13.116\" cy=\"5.5\" r=\"1\"/><circle cx=\"16.366\" cy=\"5.5\" r=\"1\"/><circle cx=\"19.616\" cy=\"5.5\" r=\"1\"/><path d=\"M10.5 17.663V16.28a3.072 3.072 0 00.991-2.43 1.826 1.826 0 00.655-1.5 1.612 1.612 0 00-.22-.859 1.092 1.092 0 00-.245-.276 3.7 3.7 0 00.3-2.338C11.638 7.492 9.891 7 8.5 7c-1.239 0-2.761.39-3.323 1.456a1.151 1.151 0 00-1.02.382c-.511.626-.1 1.654.2 2.367a1.093 1.093 0 00-.268.289 1.611 1.611 0 00-.229.875 1.807 1.807 0 00.65 1.481 3.072 3.072 0 00.99 2.43v1.383C3.624 18.427 0 20.03 0 21v2.5a.5.5 0 00.5.5h15a.5.5 0 00.5-.5V21c0-.97-3.624-2.573-5.5-3.337z\"/><path d=\"M18.5 0h-6a5.525 5.525 0 00-5.363 4.31 1 1 0 001.953.43A3.512 3.512 0 0112.5 2h6a3.5 3.5 0 010 7h-1a1 1 0 00-.625.219l-.878.7A1 1 0 0015 9h-1a1 1 0 000 2v1a1 1 0 001.625.781L17.851 11h.649a5.5 5.5 0 000-11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});