define("ember-svg-jar/inlined/human-resources-offer-employee-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-offer-employee-1</title><circle cx=\"18.036\" cy=\"3.691\" r=\"3.692\"/><path d=\"M18.036 8.726a6.033 6.033 0 00-5.8 4.38.253.253 0 00.041.219.248.248 0 00.2.1h11.117a.25.25 0 00.24-.319 6.033 6.033 0 00-5.798-4.38zM1.156 14.75a1 1 0 00-1 1V23a1 1 0 002 0v-7.25a1 1 0 00-1-1zM15.656 19.5H6.531a.625.625 0 110-1.25h2.526a1 1 0 00.92-1.394A2.234 2.234 0 007.92 15.5H3.656a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5H14.42a2.239 2.239 0 002.236-2.237V20.5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});