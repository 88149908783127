define("ember-svg-jar/inlined/walkman-cassette-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>walkman-cassette-1</title><path d=\"M24 6a2.252 2.252 0 00-2.25-2.25H2.25A2.252 2.252 0 000 6v12a2.252 2.252 0 002.25 2.25h.982a.5.5 0 00.354-.146l2.548-2.548a2.73 2.73 0 011.945-.806h7.842a2.73 2.73 0 011.945.806l2.548 2.544a.5.5 0 00.354.146h.982A2.252 2.252 0 0024 18zM4.75 7.25a.75.75 0 010-1.5h14.5a.75.75 0 010 1.5zm8.024 2.76a.251.251 0 01.236.333 4.985 4.985 0 00.008 3.334.25.25 0 01-.236.333h-1.537a.25.25 0 01-.236-.333 4.977 4.977 0 00.007-3.334.249.249 0 01.032-.227.251.251 0 01.2-.106zm-7.024 4a2 2 0 110-4h3.008a.5.5 0 01.405.207 3 3 0 01-.017 3.589.5.5 0 01-.4.2zm9.507 0a.5.5 0 01-.4-.205 2.961 2.961 0 01-.6-1.8 3.075 3.075 0 01.581-1.782.5.5 0 01.406-.208h3.006a2 2 0 010 4z\"/><path d=\"M16.806 18.616a1.246 1.246 0 00-.885-.366H8.079a1.246 1.246 0 00-.885.366l-1.207 1.207a.25.25 0 00.177.427h11.672a.25.25 0 00.177-.427z\"/><circle cx=\"6.75\" cy=\"12\" r=\"1\"/><circle cx=\"17.25\" cy=\"12\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});