define("ember-svg-jar/inlined/cloud-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-clock</title><path d=\"M2.089 15.98a.973.973 0 001.445-.759v-.037a.958.958 0 00-.437-.927 2.308 2.308 0 01-.461-.348A2.371 2.371 0 012 12.124a2.588 2.588 0 012.586-2.587 2.643 2.643 0 01.535.056 1 1 0 001.2-.965c.081-5.754 8.3-7.363 10.818-2.045a.977.977 0 00.941.571 3.69 3.69 0 012.981 1.282 3.843 3.843 0 01.932 2.7 3.448 3.448 0 01-1.254 2.633.977.977 0 00-.34.9v.033a.98.98 0 001.582.618A5.4 5.4 0 0024 11.081a5.763 5.763 0 00-5.218-5.9.25.25 0 01-.19-.12 7.685 7.685 0 00-14.1 2.3.251.251 0 01-.227.2A4.642 4.642 0 00.62 9.8 4.479 4.479 0 000 12.244a4.287 4.287 0 001.235 3.09 4.161 4.161 0 00.854.646z\"/><path d=\"M18.75 15.929a6.75 6.75 0 10-6.75 6.75 6.758 6.758 0 006.75-6.75zM12 20.679a4.75 4.75 0 114.75-4.75 4.756 4.756 0 01-4.75 4.75z\"/><path d=\"M15 15.929a1 1 0 00-1-1h-.75a.25.25 0 01-.25-.25v-1.25a1 1 0 00-2 0v2.5a1 1 0 001 1h2a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});