define("ember-svg-jar/inlined/music-genre-70s", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-genre-70s</title><path d=\"M19 14.75h1a.75.75 0 000-1.5h-1A1.752 1.752 0 0017.25 15a2.553 2.553 0 001.3 2.1c.508.381.7.557.7.9a.25.25 0 01-.25.25h-1a.75.75 0 000 1.5h1A1.752 1.752 0 0020.75 18a2.553 2.553 0 00-1.3-2.1c-.508-.381-.7-.557-.7-.9a.25.25 0 01.25-.25zM13.5 10.25a3.254 3.254 0 00-3.25 3.25v3a3.25 3.25 0 006.5 0v-3a3.254 3.254 0 00-3.25-3.25zm1.75 6.25a1.75 1.75 0 01-3.5 0v-3a1.75 1.75 0 013.5 0zM8.462 10.25H4.5a1.252 1.252 0 00-1.25 1.25v1a.75.75 0 001.5 0V12a.25.25 0 01.25-.25h2.749a.249.249 0 01.232.344l-2.676 6.625a.75.75 0 101.39.562l2.913-7.212a1.218 1.218 0 00-.046-1.221 1.308 1.308 0 00-1.1-.598z\"/><path d=\"M24 5a2 2 0 00-2-2h-2.75a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v3.75a.75.75 0 01-1.5 0V3.5A.5.5 0 0015 3H7.75a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v3.75a.75.75 0 01-1.5 0V3.5a.5.5 0 00-.5-.5H2a2 2 0 00-2 2v17a2 2 0 002 2h20a2 2 0 002-2zm-2 16.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});