define("ember-svg-jar/inlined/resize-expand-corner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>resize-expand-corner</title><path d=\"M24 2a2 2 0 00-2-2H6a2 2 0 00-2 2v8.5a1 1 0 002 0v-8a.5.5 0 01.5-.5h15a.5.5 0 01.5.5v15a.5.5 0 01-.5.5h-8a1 1 0 000 2H22a2 2 0 002-2zM2.25 22.5h-.5a.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0v1.5a.75.75 0 00.75.75h1.5a.75.75 0 000-1.5zM10.75 21a.75.75 0 00-.75.75v.5a.25.25 0 01-.25.25h-.5a.75.75 0 000 1.5h1.5a.75.75 0 00.75-.75v-1.5a.75.75 0 00-.75-.75zM2.25 12.5H.75a.75.75 0 00-.75.75v1.5a.75.75 0 001.5 0v-.5a.25.25 0 01.25-.25h.5a.75.75 0 000-1.5z\"/><path d=\"M11.5 13.25a.75.75 0 00-.75-.75h-1.5a.75.75 0 000 1.5h.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0zM.75 19.5a.75.75 0 00.75-.75v-1a.75.75 0 00-1.5 0v1a.75.75 0 00.75.75zM10.75 17a.75.75 0 00-.75.75v1a.75.75 0 001.5 0v-1a.75.75 0 00-.75-.75zM6.25 22.5h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM6.25 12.5h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM13.293 10.707a1 1 0 001.414 0L17 8.414a.25.25 0 01.354 0l1.866 1.866a.75.75 0 001.28-.53v-5.5a.75.75 0 00-.75-.75h-5.5a.75.75 0 00-.53 1.28l1.866 1.866a.25.25 0 010 .354l-2.293 2.293a1 1 0 000 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});