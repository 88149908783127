define("ember-svg-jar/inlined/whale-water", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>whale-water</title><path d=\"M1.172 19a3.178 3.178 0 001.811-1.135 2.5 2.5 0 014.088.226 2.115 2.115 0 001.488.874 2.789 2.789 0 001.888-1.017 2.5 2.5 0 014.287.39 1.281 1.281 0 001.05.627 1.868 1.868 0 001.477-.846 2.415 2.415 0 012.139-1.206 2.522 2.522 0 01.8.129 5.743 5.743 0 001.4-3.235c.106-1.057.138-2.242.147-2.867a2.66 2.66 0 001.973-1.782 3.3 3.3 0 00-.069-2.951.5.5 0 00-.629-.154L20.309 7.41l-2.25-1.8a.5.5 0 00-.666.037 3.011 3.011 0 00-.621 3.012 3.816 3.816 0 001.934 2.151c-.279 1.515-1.22 4.19-3.585 4.19a2.908 2.908 0 01-2.162-.955c-1.415-1.574-4.259-4.193-7.748-4.038A5.517 5.517 0 000 15.6v2.9a.5.5 0 00.5.5zM6 15a1 1 0 111-1 1 1 0 01-1 1zM1 6a1 1 0 001-1 1.5 1.5 0 113 0v3a1 1 0 002 0V5a1.5 1.5 0 113 0 1 1 0 002 0 3.5 3.5 0 00-6-2.447A3.5 3.5 0 000 5a1 1 0 001 1z\"/><path d=\"M22.927 20.454c-1.582.139-2.668-1.556-2.677-1.569a1.046 1.046 0 00-1.714.022 3.372 3.372 0 01-2.753 1.558 2.8 2.8 0 01-2.4-1.482 1.044 1.044 0 00-1.717-.156 4.3 4.3 0 01-3.1 1.638 3.56 3.56 0 01-2.76-1.58 1.04 1.04 0 00-1.637-.091c-.014.018-1.487 1.8-3.108 1.7A.99.99 0 000 21.442c-.01.188.042 1.058 1.168 1.058a5.84 5.84 0 003.726-1.607 5.24 5.24 0 003.663 1.572 5.86 5.86 0 003.81-1.5 4.575 4.575 0 003.415 1.5 5.153 5.153 0 003.622-1.5 4.822 4.822 0 003.66 1.482 1 1 0 00.929-1.067 1.011 1.011 0 00-1.066-.926z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});