define("ember-svg-jar/inlined/controls-movie-stop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>controls-movie-stop</title><path d=\"M17.514 9.656a8.407 8.407 0 014.09 1.05.25.25 0 00.371-.218V6.375a.486.486 0 00-.5-.469H.47a.486.486 0 00-.5.469v9.375a2.431 2.431 0 002.506 2.344h6.8a.25.25 0 00.249-.274q-.032-.329-.032-.664a7.783 7.783 0 018.021-7.5z\"/><path d=\"M17.514 11.063A6.323 6.323 0 0011 17.156a6.324 6.324 0 006.517 6.094 6.323 6.323 0 006.517-6.094 6.322 6.322 0 00-6.52-6.093zm2.507 7.968a.486.486 0 01-.5.469h-4.012a.486.486 0 01-.5-.469v-3.75a.485.485 0 01.5-.468h4.011a.485.485 0 01.5.468zM4.471 4.1a.222.222 0 00-.054.255.251.251 0 00.231.145h3.183a.261.261 0 00.178-.069l3.506-3.281a.222.222 0 00.054-.25.25.25 0 00-.231-.15H8.155a.258.258 0 00-.177.069zM16.5 4.1a.224.224 0 00-.054.255.251.251 0 00.232.145h4.8a.487.487 0 00.5-.469v-.937a2.375 2.375 0 00-1.81-2.251.263.263 0 00-.247.057zM.474 4.5h1.342a.26.26 0 00.177-.069L5.5 1.15A.224.224 0 005.554.9a.251.251 0 00-.232-.15H2.479A2.432 2.432 0 00-.028 3.094v.937a.486.486 0 00.502.469zM10.487 4.1a.224.224 0 00-.055.255.252.252 0 00.232.145h3.183a.26.26 0 00.177-.069l3.507-3.281a.222.222 0 00.054-.25.251.251 0 00-.231-.15h-3.183a.261.261 0 00-.178.069z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});