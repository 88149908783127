define("ember-svg-jar/inlined/beacon-remote-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beacon-remote-laptop</title><path d=\"M23.924 10.041l-1.4-3.24c0-.01-.016-.014-.021-.024V2.5a2.144 2.144 0 00-2.16-2H14.5a2 2 0 00-2 2v4.277c0 .01-.016.014-.02.024l-1.41 3.264a1.061 1.061 0 00.995 1.435h10.873a1.062 1.062 0 00.986-1.459zM20.5 6.25a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25v-3.5a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25zM5.5 7.5a4.966 4.966 0 00-3.534 1.464 1 1 0 001.414 1.414 3.074 3.074 0 014.242 0 1 1 0 001.414-1.414A4.966 4.966 0 005.5 7.5z\"/><path d=\"M1.966 7.207a5 5 0 017.07 0 1 1 0 001.414-1.414 7 7 0 00-9.9 0 1 1 0 001.416 1.414zM11 14a1.5 1.5 0 00-1.5-1.5h-8A1.5 1.5 0 000 14v8a1.5 1.5 0 001.5 1.5h8A1.5 1.5 0 0011 22v-8zm-4.658 5.772a.252.252 0 00-.091.193v1.285a.75.75 0 01-1.5 0v-1.277a.251.251 0 00-.091-.193 1.277 1.277 0 01-.492-.989 1.333 1.333 0 012.666 0 1.266 1.266 0 01-.492.981zM8.751 19A.75.75 0 018 18.25a2.5 2.5 0 00-5 0 .75.75 0 01-1.5 0 4 4 0 018 0 .75.75 0 01-.749.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});