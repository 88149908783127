define("ember-svg-jar/inlined/touch-id-smartphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>touch-id-smartphone</title><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/><path d=\"M12 6.25a4.243 4.243 0 00-2.191.608.75.75 0 00.774 1.285A2.75 2.75 0 0114.75 10.5v2a2.727 2.727 0 01-.264 1.178.75.75 0 101.354.644 4.21 4.21 0 00.41-1.822v-2A4.255 4.255 0 0012 6.25zM12.787 15.137a2.789 2.789 0 01-.787.113 2.752 2.752 0 01-2.75-2.75v-2a2.742 2.742 0 01.114-.786.75.75 0 00-1.438-.428A4.249 4.249 0 007.75 10.5v2a4.252 4.252 0 005.463 4.074.749.749 0 10-.426-1.437z\"/><path d=\"M13 12.625a.625.625 0 00.625-.625v-1.5A1.627 1.627 0 0012 8.875a.625.625 0 000 1.25.375.375 0 01.375.375V12a.625.625 0 00.625.625zM10.375 11v1.5A1.626 1.626 0 0012 14.125a.625.625 0 000-1.25.375.375 0 01-.375-.375V11a.625.625 0 00-1.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});