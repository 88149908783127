define("ember-svg-jar/inlined/casino-player-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>casino-player-man</title><path d=\"M10.407 11.373a.75.75 0 00.134 1.053 2.413 2.413 0 002.918 0 .75.75 0 10-.918-1.186.973.973 0 01-1.082 0 .753.753 0 00-1.052.133z\"/><circle cx=\"14\" cy=\"9.25\" r=\"1\"/><circle cx=\"10\" cy=\"9.25\" r=\"1\"/><path d=\"M14.538 16.414a.375.375 0 00-.354.042l-1.966.951a.5.5 0 01-.436 0l-1.966-.951a.375.375 0 00-.354-.042.466.466 0 00-.262.286 3.912 3.912 0 000 2.489.46.46 0 00.259.285.375.375 0 00.354-.041l1.966-.951a.5.5 0 01.436 0l1.966.951a.375.375 0 00.354.041.46.46 0 00.259-.285 3.912 3.912 0 000-2.489.466.466 0 00-.256-.286zM12 16a7.008 7.008 0 007-7V7A7 7 0 005 7v2a7.008 7.008 0 007 7zM7 8.015a.25.25 0 01.247-.25 6.986 6.986 0 004.439-1.4.5.5 0 01.627 0 6.99 6.99 0 004.44 1.4.25.25 0 01.247.25V9A5 5 0 017 9z\"/><path d=\"M22.887 18.282a13.656 13.656 0 00-5.41-2.483.993.993 0 00-.843.181 1 1 0 00-.388.771 6.232 6.232 0 01-4.046 5.612.5.5 0 01-.389 0 6.277 6.277 0 01-4.054-5.612 1 1 0 00-.388-.771.991.991 0 00-.843-.181 13.641 13.641 0 00-5.41 2.484A3 3 0 000 20.644V23a1 1 0 002 0v-2.356a1.017 1.017 0 01.36-.8 9.3 9.3 0 011.369-.9.25.25 0 01.358.148 17.017 17.017 0 01.736 4.417.5.5 0 00.5.484h13.355a.5.5 0 00.5-.485 17.075 17.075 0 01.736-4.417.254.254 0 01.144-.158.247.247 0 01.214.011 9.15 9.15 0 011.368.895 1.019 1.019 0 01.361.8V23a1 1 0 002 0v-2.356a3 3 0 00-1.114-2.362z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});