define("ember-svg-jar/inlined/laundry-iron", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laundry-iron</title><path d=\"M2.357 4.577a1.4 1.4 0 01-.232-.308.5.5 0 00-.654-.206l-.866.418a1 1 0 00-.272 1.607l12.23 12.23a1.873 1.873 0 001.369.489 3.849 3.849 0 00.914-.12c.158-.039.315-.086.469-.14a.5.5 0 00.187-.825zM23.667 20.938L21.1 18.375a.25.25 0 01-.018-.333 2.142 2.142 0 00.13-.182 11 11 0 00-1.531-13.637c-5.1-5.1-13.256-2.054-15.609-1.014a.5.5 0 00-.151.811l18.332 18.332a1 1 0 001.414-1.414zm-5.577-6.285a.75.75 0 110-1.061.751.751 0 010 1.061zm1.768 1.768a.75.75 0 110-1.061.752.752 0 010 1.061zm.337-4.358a.5.5 0 01-.851.308l-7.1-7.1a.5.5 0 01.308-.852 7.072 7.072 0 015.718 1.929 7.072 7.072 0 011.93 5.715z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});