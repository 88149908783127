define("ember-svg-jar/inlined/truck-empty-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>truck-empty-1</title><path d=\"M5.839 6.625h6.411a.25.25 0 00.25-.25v-1.75a.75.75 0 00-.75-.75h-1a6.741 6.741 0 00-5.1 2.337.25.25 0 00.19.413zM23.25 19.375H23v-.25a1.252 1.252 0 00-1.25-1.25h-1.2a.25.25 0 00-.2.406 2.948 2.948 0 01.65 1.844 2.651 2.651 0 01-.042.457.248.248 0 00.055.2.253.253 0 00.192.089h2.045a.75.75 0 000-1.5z\"/><path d=\"M16.154 17.771a.248.248 0 00.1-.2V4.875a1 1 0 011-1h.5a1 1 0 000-2h-.5a3 3 0 00-3 3v12.75a.25.25 0 01-.25.25H12.75a.25.25 0 01-.25-.25v-9.75a.25.25 0 00-.25-.25H4.878a.25.25 0 00-.225.141 5.736 5.736 0 00-.258.6A6.57 6.57 0 004 10.625v2.5a.25.25 0 01-.25.25h-.31a2.74 2.74 0 00-2.71 2.3l-.72 4.3a.75.75 0 00.738.874H1.79a.251.251 0 00.247-.291A2.669 2.669 0 012 20.125a3 3 0 016 0 2.651 2.651 0 01-.042.457.248.248 0 00.055.2.253.253 0 00.192.089H14.8a.25.25 0 00.247-.293 2.651 2.651 0 01-.047-.453 2.989 2.989 0 011.154-2.354zm-6.4-4.766a.5.5 0 01-.48.37h-2.49a.5.5 0 01-.5-.5v-2.25a5.425 5.425 0 01.121-1.1.5.5 0 01.49-.4H10a.5.5 0 01.5.5z\"/><circle cx=\"5\" cy=\"20.125\" r=\"2\"/><circle cx=\"18\" cy=\"20.125\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});