define("ember-svg-jar/inlined/multiple-actions-subtract.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-subtract</title><path d=\"M14.472 8.4l-.484-.172a1.8 1.8 0 01-.055-1.172 4.647 4.647 0 001.216-3.569A3.22 3.22 0 0012 0a2.994 2.994 0 00-1.342.314.25.25 0 00-.1.363 5 5 0 01.842 2.808 6.266 6.266 0 01-.922 3.624.25.25 0 00.121.372c3 1.121 3.653 2 3.84 2.387a.25.25 0 00.311.127 7.847 7.847 0 011.537-.395.25.25 0 00.061-.477 41.906 41.906 0 00-1.876-.723z\"/><path d=\"M13.207 10.763a.251.251 0 00.11-.159.255.255 0 00-.037-.19c-.5-.759-1.622-1.211-3.809-2.016l-.486-.172a1.8 1.8 0 01-.055-1.172 4.647 4.647 0 001.216-3.569A3.22 3.22 0 007 0a3.22 3.22 0 00-3.146 3.485 4.605 4.605 0 001.193 3.538 1.877 1.877 0 010 1.185l-.515.19c-2.01.739-3.46 1.272-3.914 2.179A7.542 7.542 0 000 13.5a.5.5 0 00.5.5h9.663a.252.252 0 00.223-.136 8.031 8.031 0 012.821-3.101zM17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm3.5 7.5h-7a1 1 0 010-2h7a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});