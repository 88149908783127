define("ember-svg-jar/inlined/single-man-flag-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-flag-1</title><path d=\"M11.134 4.064a.5.5 0 00-.384-.82h-8a.25.25 0 01-.25-.25v-1.25a1.25 1.25 0 00-2.5 0v21a1.25 1.25 0 002.5 0v-12.25a.25.25 0 01.25-.25h8a.5.5 0 00.384-.821L9.034 6.9a.251.251 0 010-.32zM18.5 8.994a5.506 5.506 0 00-5.5 5.5v3a.5.5 0 00.5.5h1.81a.249.249 0 01.249.229L16 23.535a.5.5 0 00.5.459h4a.5.5 0 00.5-.459l.443-5.312a.249.249 0 01.249-.229H23.5a.5.5 0 00.5-.5v-3a5.506 5.506 0 00-5.5-5.5zm.75 5.948a.75.75 0 01-1.5 0v-3.948a.75.75 0 011.5 0zM18.5 8.144a4.069 4.069 0 10-4.068-4.069A4.074 4.074 0 0018.5 8.144zm-1.929-4.788a.25.25 0 01.362-.124 5.053 5.053 0 002.609.726 4.961 4.961 0 00.736-.056.254.254 0 01.2.058.249.249 0 01.086.189 2.028 2.028 0 01-2.062 2 2.07 2.07 0 01-2.068-2.069 2 2 0 01.137-.724z\"/><path d=\"M11.134 4.064a.5.5 0 00-.384-.82h-8a.25.25 0 01-.25-.25v-1.25a1.25 1.25 0 00-2.5 0v21a1.25 1.25 0 002.5 0v-12.25a.25.25 0 01.25-.25h8a.5.5 0 00.384-.821L9.034 6.9a.251.251 0 010-.32zM18.5 8.994a5.506 5.506 0 00-5.5 5.5v3a.5.5 0 00.5.5h1.81a.249.249 0 01.249.229L16 23.535a.5.5 0 00.5.459h4a.5.5 0 00.5-.459l.443-5.312a.249.249 0 01.249-.229H23.5a.5.5 0 00.5-.5v-3a5.506 5.506 0 00-5.5-5.5zm.75 5.948a.75.75 0 01-1.5 0v-3.948a.75.75 0 011.5 0zM18.5 8.144a4.069 4.069 0 10-4.068-4.069A4.074 4.074 0 0018.5 8.144zm-1.929-4.788a.25.25 0 01.362-.124 5.053 5.053 0 002.609.726 4.961 4.961 0 00.736-.056.254.254 0 01.2.058.249.249 0 01.086.189 2.028 2.028 0 01-2.062 2 2.07 2.07 0 01-2.068-2.069 2 2 0 01.137-.724z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});