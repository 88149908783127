define("ember-svg-jar/inlined/road-sign-right-curve-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-right-curve-1-alternate</title><path d=\"M14.207 6.5a1 1 0 00-1.414 0l-2.147 2.146A.5.5 0 0011 9.5h.75a.25.25 0 01.25.25v1.439a.249.249 0 01-.073.177L9.293 14a.991.991 0 00-.293.707V17.5a1 1 0 001 1h1a1 1 0 001-1v-1.689a.249.249 0 01.073-.177L14.707 13a.991.991 0 00.293-.707V9.75a.25.25 0 01.25-.25H16a.5.5 0 00.354-.854z\"/><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});