define("ember-svg-jar/inlined/famous-people-man-merlin-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-people-man-merlin-1</title><path d=\"M8.975 15.675a.25.25 0 01.268.176 52.355 52.355 0 002.367 5.721.436.436 0 00.78 0 52.355 52.355 0 002.367-5.721.25.25 0 01.268-.176 21.96 21.96 0 012.171.362.25.25 0 00.3-.244v-.2a.25.25 0 01.291-.246l2.125.354a.5.5 0 00.434-.847c-2-2-1.6-5.546-1.6-5.646V7.466a.249.249 0 01.216-.247 49.446 49.446 0 002.861-.476 1 1 0 00-.393-1.961 46.4 46.4 0 01-3.165.51.249.249 0 01-.276-.195l-1-4.7A.5.5 0 0016.5 0h-9a.5.5 0 00-.489.4l-1 4.684a.251.251 0 01-.278.2A53.091 53.091 0 012.7 4.782a1 1 0 00-.4 1.961c.908.182 1.819.329 2.731.459a.25.25 0 01.215.247V9.21c0 .1.4 3.64-1.6 5.646a.5.5 0 00.436.847l2.127-.354a.25.25 0 01.291.251v.2a.25.25 0 00.3.244 21.96 21.96 0 012.175-.369zM6.75 7.752A.251.251 0 017 7.5c1.437.021 2.4.213 5.061.213S15.724 7.526 17 7.5a.252.252 0 01.18.072.249.249 0 01.075.178v1.46a5.229 5.229 0 01-2.212 4.264.252.252 0 01-.2.039.249.249 0 01-.163-.125 3.064 3.064 0 00-5.344 0 .253.253 0 01-.163.126.257.257 0 01-.2-.04A5.229 5.229 0 016.75 9.21z\"/><path d=\"M22.533 19.417a14.813 14.813 0 00-6.689-2.617.25.25 0 00-.276.165c-.612 1.775-1.514 3.9-2.032 5.092a1.673 1.673 0 01-3.072 0c-.519-1.188-1.419-3.314-2.032-5.091a.25.25 0 00-.276-.165 14.813 14.813 0 00-6.689 2.621A2.462 2.462 0 00.5 21.394V23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-2.106a2.462 2.462 0 00-.967-1.977z\"/><circle cx=\"14\" cy=\"9.21\" r=\"1\"/><circle cx=\"10\" cy=\"9.21\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});