define("ember-svg-jar/inlined/real-estate-action-building-dollar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-building-dollar</title><path d=\"M6.724 6.053l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 000 6.5V14a.5.5 0 00.5.5H2a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5A.5.5 0 007 14V6.5a.5.5 0 00-.276-.447zM5 10.5H2a.5.5 0 010-1h3a.5.5 0 010 1zM5 8H2a.5.5 0 010-1h3a.5.5 0 010 1zM14.677 10.02A.5.5 0 0015 9.553V3a.5.5 0 00-.308-.462l-6-2.5A.5.5 0 008 .5V14a.5.5 0 00.5.5h1.26a.5.5 0 00.455-.293 8.038 8.038 0 014.462-4.187zM10 4.5h3a.5.5 0 010 1h-3a.5.5 0 010-1zM10 7h3a.5.5 0 010 1h-3a.5.5 0 010-1zm0 3.5a.5.5 0 010-1h3a.5.5 0 010 1zM17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm1.112 10.158a.5.5 0 00-.362.481v.111a.75.75 0 01-1.5 0 .5.5 0 00-.5-.5H16a.75.75 0 010-1.5h2.033a.593.593 0 00.592-.592.587.587 0 00-.371-.548l-2.065-.827a2.084 2.084 0 01.2-3.941.5.5 0 00.362-.48v-.112a.75.75 0 011.5 0 .5.5 0 00.5.5H19a.75.75 0 010 1.5h-2.033a.592.592 0 00-.221 1.141l2.065.826a2.084 2.084 0 01-.2 3.941z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});