define("ember-svg-jar/inlined/famous-character-star-wars-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character-star-wars-1</title><path d=\"M1.75 7a1 1 0 001-1V4.5a1 1 0 00-2 0V6a1 1 0 001 1zM22.25 3.5a1 1 0 00-1 1V6a1 1 0 002 0V4.5a1 1 0 00-1-1zM7.75 12.5H6.5A1.5 1.5 0 005 14v1a.25.25 0 01-.25.25H3a.25.25 0 01-.25-.25v-4.5a1 1 0 00-2 0v6.559a2.75 2.75 0 001.881 2.609l2.369.79V22a1.5 1.5 0 003 0v-9.25a.25.25 0 00-.25-.25zM22.25 9.5a1 1 0 00-1 1V15a.25.25 0 01-.25.25h-1.75A.25.25 0 0119 15v-1a1.5 1.5 0 00-1.5-1.5h-1.25a.25.25 0 00-.25.25V22a1.5 1.5 0 003 0v-1.542l2.37-.791a2.75 2.75 0 001.88-2.609V10.5a1 1 0 00-1-1zM13.764 1.549C13.7.971 12.91.5 12 .5s-1.7.471-1.765 1.049L9 13.04v7.21a.25.25 0 00.25.25h5.5a.25.25 0 00.25-.25v-7.263zm-.331 12.837l-1.182-.525a.618.618 0 00-.5 0s-1.067.47-1.191.529-.177-.045-.115-.185l1.068-2.37a.524.524 0 01.978 0s1.007 2.266 1.068 2.37-.009.233-.126.181z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});