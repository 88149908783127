define("ember-svg-jar/inlined/color-drop-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-drop-off</title><path d=\"M19 15.361a.249.249 0 00.073.177l1.333 1.332a.251.251 0 00.422-.128A9.027 9.027 0 0021 15a12.277 12.277 0 00-1.527-5.31.25.25 0 00-.473.11zM14 17.639a.249.249 0 00-.073-.177l-1.5-1.5a.25.25 0 00-.427.177v7.574a.25.25 0 00.079.182.248.248 0 00.188.067 10.477 10.477 0 001.537-.218A.248.248 0 0014 23.5zM17.5 21.139a.249.249 0 00-.073-.177l-1.5-1.5a.25.25 0 00-.427.177v3.191a.25.25 0 00.355.226 9.171 9.171 0 001.555-.914.218.218 0 00.09-.177zM10.5 14.139a.249.249 0 00-.073-.177L4.693 8.228a.25.25 0 00-.392.05A14.168 14.168 0 002 15a8.945 8.945 0 008.221 8.926.249.249 0 00.279-.248zM10.073 6.538a.25.25 0 00.427-.177V.609a.126.126 0 00-.217-.088c-.6.633-1.629 1.754-2.757 3.153a.251.251 0 00.017.334zM15.5 11.861a.249.249 0 00.073.177l1.5 1.5a.25.25 0 00.427-.177V6.466a.243.243 0 00-.044-.141 44.524 44.524 0 00-1.509-2.057.25.25 0 00-.447.154zM12 8.361a.249.249 0 00.073.177l1.5 1.5A.25.25 0 0014 9.861V2.02a.245.245 0 00-.063-.165c-.486-.55-.912-1.01-1.22-1.334a1.443 1.443 0 00-.345-.265.25.25 0 00-.372.218zM1.707.293A1 1 0 00.293 1.707l22 22a1 1 0 001.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});