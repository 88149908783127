define("ember-svg-jar/inlined/monitor-users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-users</title><path d=\"M7.962 9.058A.5.5 0 007.5 8.75h-2a.5.5 0 00-.5.5v2a.5.5 0 00.309.462.512.512 0 00.191.038.5.5 0 00.354-.147l.292-.293a.252.252 0 01.354 0l2.72 2.72a.75.75 0 001.06-1.061L7.561 10.25a.25.25 0 010-.354l.293-.296a.5.5 0 00.108-.542zM8 6.5a3.647 3.647 0 00-1.375-2.922.251.251 0 01-.047-.353 2 2 0 10-3.156 0 .251.251 0 01-.047.353A3.647 3.647 0 002 6.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5zM20.625 3.578a.251.251 0 01-.047-.353 2 2 0 10-3.156 0 .251.251 0 01-.047.353A3.647 3.647 0 0016 6.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5 3.647 3.647 0 00-1.375-2.922zM13.72 14.03a.748.748 0 001.06 0l2.72-2.72a.252.252 0 01.354 0l.292.293a.5.5 0 00.354.147.512.512 0 00.191-.038.5.5 0 00.309-.462v-2a.5.5 0 00-.5-.5h-2a.5.5 0 00-.354.853l.293.293a.25.25 0 010 .354l-2.719 2.719a.75.75 0 000 1.061z\"/><path d=\"M19.5 12.5a1 1 0 00-1 1v4.25a.25.25 0 01-.25.25h-13a.25.25 0 01-.25-.25V13.5a1 1 0 00-2 0V19a2 2 0 002 2h5.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H8.75a1 1 0 000 2h6a1 1 0 000-2H13a.25.25 0 01-.25-.25v-.5A.25.25 0 0113 21h5.5a2 2 0 002-2v-5.5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});