define("ember-svg-jar/inlined/light-mode-night", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-mode-night</title><path d=\"M11.189 19.354c-.828-.306-.692-2.464-.326-2.869.86-.946 1.589-2.054 1.589-4.731A3.735 3.735 0 008.58 7.677a3.735 3.735 0 00-3.872 4.077c0 2.677.729 3.785 1.589 4.731.366.405.5 2.563-.326 2.869C2.912 20.488.976 21.3.393 22.463a7.221 7.221 0 00-.365.874A.5.5 0 00.5 24h16.16a.5.5 0 00.472-.663 7.221 7.221 0 00-.365-.874c-.583-1.163-2.519-1.975-5.578-3.109zM19.382.046a1.251 1.251 0 00-1.4 1.853 4.7 4.7 0 01.218 4.379 4.712 4.712 0 01-3.461 2.715 1.251 1.251 0 00-.484 2.27A6.249 6.249 0 1019.382.046z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});