define("ember-svg-jar/inlined/professions-woman-clerk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-woman-clerk</title><path d=\"M2.847 13.524a.5.5 0 00-.231.8 3.8 3.8 0 002.921 1.12 4.156 4.156 0 002.539-.74.251.251 0 01.291-.014 6.734 6.734 0 007.228.024.251.251 0 01.294.019 4.27 4.27 0 002.574.771 3.8 3.8 0 002.921-1.12.5.5 0 00-.231-.8c-2.529-.812-2.436-4.862-2.407-6.09A.25.25 0 0119 7.25h1a1 1 0 001-1 1.029 1.029 0 00-1-1h-.64a.251.251 0 01-.248-.221 9.535 9.535 0 00-.165-1.279 1.782 1.782 0 00-1.827-1.5.251.251 0 01-.174-.08 6.717 6.717 0 00-9.892 0 .251.251 0 01-.174.08 1.781 1.781 0 00-1.829 1.5 9.7 9.7 0 00-.163 1.279.251.251 0 01-.249.221H4a1.029 1.029 0 00-1 1 1 1 0 001 1h1a.251.251 0 01.25.246c.027 1.397.143 5.211-2.403 6.028zM7.33 3.75h9.92c.085 0 .22.126.244.366l.1.852a.251.251 0 01-.249.279H6.653a.249.249 0 01-.248-.279l.1-.863c.024-.229.159-.355.244-.355zM6.75 9V7.5A.25.25 0 017 7.25h10a.25.25 0 01.25.25V9a5.25 5.25 0 01-10.5 0z\"/><path d=\"M23.5 23.5v-3.106a2.466 2.466 0 00-.966-1.977 11.966 11.966 0 00-2.371-1.369.246.246 0 01-.1-.076.7.7 0 00-.331-.248 32.233 32.233 0 00-3.679-.894.5.5 0 00-.4.1l-3.5 2.8a.25.25 0 01-.312 0l-3.5-2.8a.5.5 0 00-.4-.1 32.41 32.41 0 00-3.679.894.7.7 0 00-.331.248.246.246 0 01-.1.076 12.011 12.011 0 00-2.372 1.369A2.466 2.466 0 00.5 20.394V23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5zM7.154 17.516a.249.249 0 01.2.05l3.2 2.551a.25.25 0 010 .391l-1.137.908a.25.25 0 01-.341-.028l-2.947-3.266a.252.252 0 01-.053-.241.248.248 0 01.18-.169q.444-.112.898-.196zm10.59.2a.25.25 0 01.127.41l-2.946 3.266a.25.25 0 01-.341.028l-1.137-.908a.25.25 0 010-.391l3.2-2.551a.249.249 0 01.2-.05q.453.08.897.192z\"/><circle cx=\"14\" cy=\"9.25\" r=\"1\"/><circle cx=\"10\" cy=\"9.25\" r=\"1\"/><path d=\"M13.077 11.361h-2a.64.64 0 00-.307 1.2 2.355 2.355 0 00.438.182 3.017 3.017 0 00.869.127 2.977 2.977 0 00.866-.125 2.375 2.375 0 00.441-.183.64.64 0 00-.307-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});