define("ember-svg-jar/inlined/ab-testing-browsers-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ab-testing-browsers-1</title><path d=\"M18 4.5V3a3 3 0 00-3-3H3a3 3 0 00-3 3v9.5a3 3 0 003 3h12a3 3 0 003-3zm-8.5-3a1 1 0 11-1 1 1 1 0 011-1zm-3 0a1 1 0 11-1 1 1 1 0 011-1zm-3 0a1 1 0 11-1 1 1 1 0 011-1zm12.5 11a1 1 0 01-1 1H3a1 1 0 01-1-1V6a1 1 0 011-1h12a1 1 0 011 1z\"/><path d=\"M9 5.75A2.253 2.253 0 006.75 8v3.5a.75.75 0 001.5 0v-1a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v1a.75.75 0 001.5 0V8A2.253 2.253 0 009 5.75zm.75 2.75a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25V8a.75.75 0 011.5 0zM21 8.5h-.5a1 1 0 00-1 1v3a1 1 0 001 1h.5a1 1 0 011 1V21a1 1 0 01-1 1h-2a1 1 0 000 2h2a3 3 0 003-3v-9.5a3 3 0 00-3-3zM11 22H9a1 1 0 01-1-1v-3.5a1 1 0 00-2 0V21a3 3 0 003 3h2a1 1 0 000-2zM17.25 18.75a2 2 0 00-2-2H14a.75.75 0 00-.75.75v5a.75.75 0 00.75.75h1.25a2 2 0 002-2 1.96 1.96 0 00-.347-1.109.25.25 0 010-.282 1.96 1.96 0 00.347-1.109zm-2 3H15a.25.25 0 01-.25-.25V21a.25.25 0 01.25-.25h.25a.5.5 0 010 1zm0-2.5H15a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h.25a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});