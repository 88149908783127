define("ember-svg-jar/inlined/smartphone-pay-dollar-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smartphone-pay-dollar-1</title><path d=\"M15.362 2.5a2.5 2.5 0 00-2.5-2.5h-9a2.5 2.5 0 00-2.5 2.5v19a2.5 2.5 0 002.5 2.5h8.047a1.454 1.454 0 001.453-1.453v-2.094A1.454 1.454 0 0013 19.49a1.452 1.452 0 00-1.091-.49h-8.3a.25.25 0 01-.25-.25V4.25A.25.25 0 013.612 4h9.5a.25.25 0 01.25.25V11c0 .025.012.046.014.071a.992.992 0 00.986.929 1.045 1.045 0 001-1zm-8 19a1 1 0 111 1 1 1 0 01-1-1z\"/><path d=\"M18.74 15.662a.75.75 0 11-1.006 1.113c-3.966-3.584-3.794-3.435-3.838-3.462a1.353 1.353 0 00-1.676.177 1.394 1.394 0 000 1.94l3.67 4.147a.249.249 0 01.063.166v2.544a2.63 2.63 0 00.306 1.071.251.251 0 00.226.142h5.907a.25.25 0 00.207-.391L21.157 21a.255.255 0 01-.044-.141V15.03c0-1.818-2.193-3.578-3.851-4.822a.25.25 0 00-.4.2v3.446a.249.249 0 00.082.185zM9.889 10.234l-2.062-.825a.342.342 0 01.127-.659h2.033a1 1 0 100-2h-.25a.25.25 0 01-.25-.25 1 1 0 00-2 0v.1a.251.251 0 01-.181.241 2.341 2.341 0 00-.222 4.424l2.063.825a.342.342 0 01-.127.659H6.987a1 1 0 000 2h.25a.25.25 0 01.25.25 1 1 0 002 0v-.1a.25.25 0 01.18-.24 2.267 2.267 0 00.255-.09 2.343 2.343 0 00.965-.762 2.325 2.325 0 00-1-3.572z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});