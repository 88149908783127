define("ember-svg-jar/inlined/rooster", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rooster</title><path d=\"M9.2 5.25a7.473 7.473 0 014.9 1.833.248.248 0 00.228.053.251.251 0 00.172-.16l.948-2.767a2.5 2.5 0 00-4.059-2.65 2.5 2.5 0 00-4.389 0 2.5 2.5 0 00-.6-.406 2.5 2.5 0 00-3.46 3.056l.948 2.767a.252.252 0 00.171.16.248.248 0 00.229-.053A7.473 7.473 0 019.2 5.25z\"/><path d=\"M21.168 15.515a7.127 7.127 0 00-6.206-5.264l-.322-.026A6 6 0 003.2 12.75v9.5a1.5 1.5 0 003 0 1.5 1.5 0 003 0 1.5 1.5 0 003 0 1.5 1.5 0 003 0V17a.25.25 0 01.25-.25h4.75a1 1 0 00.972-1.235zM10.946 12.75A1.25 1.25 0 1112.2 11.5a1.249 1.249 0 01-1.254 1.25zm4.5 2a.25.25 0 01-.25-.249c0-.446 0-1.377-.009-1.874a.249.249 0 01.314-.244 5.33 5.33 0 012.994 1.972.25.25 0 01-.2.395z\"/><path d=\"M18.7 17.75h-1.5a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h1a1.624 1.624 0 001.588-.758c.568-1.062-.46-2.7-.672-3.019a.5.5 0 00-.416-.223z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});