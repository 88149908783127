define("ember-svg-jar/inlined/gender-hetero", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gender-hetero</title><path d=\"M11.675 8.262a1.238 1.238 0 00-.522 1.67 4.152 4.152 0 01.476 1.944 4.207 4.207 0 11-6.3-3.649A1.237 1.237 0 104.1 6.082 6.675 6.675 0 005.512 18.27a.251.251 0 01.179.24v.79a.25.25 0 01-.25.25h-.493a1.238 1.238 0 000 2.475h.493a.25.25 0 01.25.25v.492a1.237 1.237 0 102.474 0v-.492a.25.25 0 01.25-.25h.492a1.238 1.238 0 000-2.475h-.492a.25.25 0 01-.25-.25v-.563a.25.25 0 01.214-.247 6.68 6.68 0 004.966-9.7 1.237 1.237 0 00-1.67-.528z\"/><path d=\"M23.258.5a.5.5 0 00-.495-.5H18.8a.5.5 0 00-.35.845l.928.928a.249.249 0 010 .353L17.27 4.239a.25.25 0 01-.312.033A6.678 6.678 0 007.8 13.6a1.237 1.237 0 102.06-1.371 4.206 4.206 0 115.394 1.425 1.237 1.237 0 101.116 2.208A6.675 6.675 0 0018.986 6.3a.25.25 0 01.033-.312l2.113-2.112a.247.247 0 01.353 0l.928.927a.495.495 0 00.845-.349z\"/><path d=\"M11.675 8.262a1.238 1.238 0 00-.522 1.67 4.152 4.152 0 01.476 1.944 4.207 4.207 0 11-6.3-3.649A1.237 1.237 0 104.1 6.082 6.675 6.675 0 005.512 18.27a.251.251 0 01.179.24v.79a.25.25 0 01-.25.25h-.493a1.238 1.238 0 000 2.475h.493a.25.25 0 01.25.25v.492a1.237 1.237 0 102.474 0v-.492a.25.25 0 01.25-.25h.492a1.238 1.238 0 000-2.475h-.492a.25.25 0 01-.25-.25v-.563a.25.25 0 01.214-.247 6.68 6.68 0 004.966-9.7 1.237 1.237 0 00-1.67-.528z\"/><path d=\"M23.258.5a.5.5 0 00-.495-.5H18.8a.5.5 0 00-.35.845l.928.928a.249.249 0 010 .353L17.27 4.239a.25.25 0 01-.312.033A6.678 6.678 0 007.8 13.6a1.237 1.237 0 102.06-1.371 4.206 4.206 0 115.394 1.425 1.237 1.237 0 101.116 2.208A6.675 6.675 0 0018.986 6.3a.25.25 0 01.033-.312l2.113-2.112a.247.247 0 01.353 0l.928.927a.495.495 0 00.845-.349z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});