define("ember-svg-jar/inlined/feed-digg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>feed-digg</title><path d=\"M5.5 4.25a1 1 0 00-1 1v2.5H1a1 1 0 00-1 1v7a1 1 0 001 1h4.5a1 1 0 001-1V5.25a1 1 0 00-1-1zm-1 10.5H2v-5h2.5zM8.5 8.25a1 1 0 00-1 1v7a1 1 0 002 0v-7a1 1 0 00-1-1zM8.5 4.25a1 1 0 00-1 1v1a1 1 0 002 0v-1a1 1 0 00-1-1zM16 7.75h-4.5a1 1 0 00-1 1v7a1 1 0 001 1H15v1h-3.5a1 1 0 000 2H16a1 1 0 001-1v-10a1 1 0 00-1-1zm-1 7h-2.5v-5H15zM23 7.75h-4.5a1 1 0 00-1 1v7a1 1 0 001 1H22v1h-3.5a1 1 0 000 2H23a1 1 0 001-1v-10a1 1 0 00-1-1zm-1 7h-2.5v-5H22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});