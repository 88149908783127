define("ember-svg-jar/inlined/weather-clouds-rainbow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-clouds-rainbow</title><path d=\"M12 3.5a10.005 10.005 0 017.666 3.577.248.248 0 00.293.068 7.5 7.5 0 011.474-.48.25.25 0 00.147-.4 12.005 12.005 0 00-19.16 0 .251.251 0 00.147.4 7.5 7.5 0 011.474.48.248.248 0 00.293-.068A10.005 10.005 0 0112 3.5z\"/><path d=\"M12 5.5a7.965 7.965 0 00-5.942 2.631.249.249 0 00.016.351 7.549 7.549 0 01.976 1.091.25.25 0 00.392.014 6.021 6.021 0 019.116 0 .25.25 0 00.392-.014 7.549 7.549 0 01.976-1.091.249.249 0 00.016-.351A7.965 7.965 0 0012 5.5z\"/><path d=\"M12 9.5a4.006 4.006 0 00-3.566 2.188.25.25 0 00.131.346 6.186 6.186 0 011.271.682.25.25 0 00.368-.095 2 2 0 013.592 0 .25.25 0 00.368.095 6.186 6.186 0 011.271-.682.25.25 0 00.131-.346A4.006 4.006 0 0012 9.5zM7.116 13.178a.251.251 0 01-.2-.2A6.011 6.011 0 001 8a1 1 0 000 2 4.019 4.019 0 014 4.071 1 1 0 001.076 1.046 2.88 2.88 0 01.229-.01 2.7 2.7 0 010 5.393H1a1 1 0 000 2h5.3a4.7 4.7 0 00.812-9.322zM24 21.5a1 1 0 00-1-1h-5.3a2.7 2.7 0 010-5.393c.077 0 .154 0 .229.01a1.031 1.031 0 00.764-.319A1.1 1.1 0 0019 14a4 4 0 014-4 1 1 0 000-2 6.011 6.011 0 00-5.913 4.974.251.251 0 01-.2.2A4.7 4.7 0 0017.7 22.5H23a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});