define("ember-svg-jar/inlined/design-file-select-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-select-alternate</title><path d=\"M17.083 7h-1.666a.918.918 0 00-.917.917.085.085 0 01-.094.083H9.588c-.1 0-.088-.083-.088-.083A.918.918 0 008.583 7H6.917A.918.918 0 006 7.917v1.666a.918.918 0 00.917.917s.083 0 .083.135v4.218c0 .135-.083.147-.083.147a.918.918 0 00-.917.917v1.666a.918.918 0 00.917.917h1.666a.918.918 0 00.917-.917s-.016-.083.106-.083h4.782c.118 0 .112.083.112.083a.918.918 0 00.917.917h1.666a.918.918 0 00.917-.917v-1.666a.918.918 0 00-.917-.917S17 15 17 14.876v-4.258a.107.107 0 01.083-.118.918.918 0 00.917-.917V7.917A.918.918 0 0017.083 7zM15 15.056a.086.086 0 01-.056.089.9.9 0 00-.3.294c-.013.019-.028.061-.083.061H9.439c-.063 0-.079-.05-.1-.074a.9.9 0 00-.291-.283.109.109 0 01-.048-.084v-4.62a.11.11 0 01.07-.092.918.918 0 00.289-.3.12.12 0 01.1-.051h5.094a.094.094 0 01.094.061.907.907 0 00.287.288.121.121 0 01.066.1z\"/><path d=\"M21.707 5.707L16.293.293A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.465a.5.5 0 01.353.146l4.536 4.536a.5.5 0 01.146.353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});