define("ember-svg-jar/inlined/single-neutral-flag-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-flag-2</title><path d=\"M21.784 6.91a.251.251 0 010-.32l2.1-2.52a.5.5 0 00-.384-.82h-8a.25.25 0 01-.25-.25V1.75a1.25 1.25 0 00-2.5 0v21a1.25 1.25 0 002.5 0V10.5a.25.25 0 01.25-.25h8a.5.5 0 00.384-.82zM5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M21.784 6.91a.251.251 0 010-.32l2.1-2.52a.5.5 0 00-.384-.82h-8a.25.25 0 01-.25-.25V1.75a1.25 1.25 0 00-2.5 0v21a1.25 1.25 0 002.5 0V10.5a.25.25 0 01.25-.25h8a.5.5 0 00.384-.82zM5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});