define("ember-svg-jar/inlined/scissors-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>scissors-1</title><path d=\"M18.066 16.393a3.866 3.866 0 00-3.911 1.307l-.9-2.263a.245.245 0 01.093-.295A14.146 14.146 0 0020 3.465a12.83 12.83 0 00-.376-3.086.5.5 0 00-.935-.1l-6.464 13.25a.251.251 0 01-.45 0L5.31.281a.5.5 0 00-.935.1A12.891 12.891 0 004 3.465a14.146 14.146 0 006.654 11.682.246.246 0 01.094.295l-.9 2.264A3.858 3.858 0 106.857 24a3.913 3.913 0 003.52-2.225L12 17.7l1.652 4.141a3.874 3.874 0 007.248-2.612 3.837 3.837 0 00-2.834-2.836zM8.714 20.55l-.165.415A1.882 1.882 0 016.857 22a1.857 1.857 0 111.857-1.857zM17.143 22a1.871 1.871 0 01-1.662-.965l-.2-.491v-.4A1.857 1.857 0 1117.143 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});