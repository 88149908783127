define("ember-svg-jar/inlined/zip-file-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>zip-file-download</title><path d=\"M9.5 17.5c0-4.346 2.791-7.87 8.254-7.972A.248.248 0 0018 9.283V5.207a1.5 1.5 0 00-.439-1.06L13.853.439A1.5 1.5 0 0012.793 0H1.5A1.5 1.5 0 000 1.5v19A1.5 1.5 0 001.5 22h8.94a.246.246 0 00.207-.379A7.948 7.948 0 019.5 17.5zm-3.5 1a1 1 0 111-1 1 1 0 01-1 1zM6 15a1 1 0 111-1 1 1 0 01-.995 1zm0-3.5a1 1 0 111-1 1 1 0 01-.995 1zm1.5-4.9a1.968 1.968 0 01-1.479.67 1.975 1.975 0 01-2.005-2.221l.49-3.127A.5.5 0 015 1.5h2a.5.5 0 01.494.423l.485 3.111v.015A1.985 1.985 0 017.508 6.6z\"/><path d=\"M17.5 24a6.5 6.5 0 10-6.5-6.5 6.508 6.508 0 006.5 6.5zm-2.391-5.688a.5.5 0 01.391-.812h.75a.25.25 0 00.25-.25V14.5a1 1 0 012 0v2.75a.25.25 0 00.25.25h.75a.5.5 0 01.391.813l-2 2.5a.518.518 0 01-.782 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});