define("ember-svg-jar/inlined/diving-mask-fish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diving-mask-fish</title><path d=\"M20.25 1.75a1 1 0 00-1 1V7.5a2 2 0 01-2 2H13a.5.5 0 01-.4-.2 2 2 0 00-3.2 0 .5.5 0 01-.4.2H7.5a1 1 0 000 2H9a.5.5 0 01.4.2 2 2 0 003.2 0 .5.5 0 01.4-.2h4.25a4 4 0 004-4V2.75a1 1 0 00-1-1z\"/><path d=\"M7.157 7.987A4.575 4.575 0 0010.4 6.646a.5.5 0 01.706 0 4.575 4.575 0 003.24 1.341 3.4 3.4 0 003.395-3.394A4.6 4.6 0 0013.145 0h-4.79a4.6 4.6 0 00-4.593 4.593 3.4 3.4 0 003.395 3.394zM11.75 2.5a.5.5 0 01.5-.5h.895a2.6 2.6 0 012.593 2.593 1.4 1.4 0 01-1.395 1.394A2.6 2.6 0 0111.75 3.4zM8.355 2h.895a.5.5 0 01.5.5v.9a2.6 2.6 0 01-2.593 2.587 1.4 1.4 0 01-1.4-1.394A2.6 2.6 0 018.355 2zM13.273 15.032a1 1 0 01-.553-.224 6.344 6.344 0 00-3.981-1.327 1.007 1.007 0 00-.989 1v1.683a.5.5 0 01-.25.436c-.435.246-.795.478-1.059.658a.5.5 0 01-.506.034L4.2 16.426a1 1 0 00-1.447.894v3.38a1 1 0 001.447.9l1.73-.87a.5.5 0 01.507.035c.264.182.625.416 1.06.663a.5.5 0 01.253.435V23a1 1 0 001 1h.046a11.241 11.241 0 003.9-.911.99.99 0 01.352-.089c3.412-.16 5.7-3.023 5.7-3.993.002-.944-2.187-3.707-5.475-3.975zm.977 4.217A.75.75 0 1115 18.5a.75.75 0 01-.75.749z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});