define("ember-svg-jar/inlined/diagram-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-down</title><path d=\"M18.023 20.834a.249.249 0 01.213.247v1.445a1.441 1.441 0 002.46 1.019l2.882-2.882a1.441 1.441 0 000-2.038L20.7 15.744a1.441 1.441 0 00-2.46 1.018v.9a.249.249 0 01-.293.246A18.3 18.3 0 012.878 1.365 1.441 1.441 0 000 1.582a21.2 21.2 0 0018.023 19.252z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});