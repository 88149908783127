define("ember-svg-jar/inlined/picture-polaroid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>picture-polaroid</title><path d=\"M23.968 9.452a1.882 1.882 0 00-1.5-2.194l-4-.748a.249.249 0 00-.205.053.252.252 0 00-.091.192v1.526a.25.25 0 00.205.246l3.111.582a.5.5 0 01.4.583l-2.207 11.8a.5.5 0 01-.583.4L7.378 19.7a.5.5 0 01-.4-.583l.106-.566a.249.249 0 00-.246-.3H5.312a.25.25 0 00-.246.2l-.168.9a1.884 1.884 0 001.5 2.2l12.945 2.421a1.836 1.836 0 00.344.032 1.885 1.885 0 001.847-1.538z\"/><path d=\"M15.046 17a1.882 1.882 0 001.877-1.883V1.882A1.881 1.881 0 0015.046 0H1.878A1.882 1.882 0 000 1.882v13.235A1.882 1.882 0 001.878 17zm-.623-15a.5.5 0 01.5.5v9.941a.249.249 0 01-.25.25H2.25a.25.25 0 01-.25-.25V2.5a.5.5 0 01.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});