define("ember-svg-jar/inlined/job-seach-team-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>job-seach-team-man</title><path d=\"M17.75 18.75a4.738 4.738 0 00-3.324 1.359.251.251 0 00-.055.276A7.979 7.979 0 0115 23.5v.242a.249.249 0 00.25.258H22a.5.5 0 00.5-.5 4.755 4.755 0 00-4.75-4.75zM12 11.437a5.063 5.063 0 00-10.125 0c0 1.421 1.13 5.063 5.063 5.063A5.093 5.093 0 0012 11.437zm-7.925-1.086a.25.25 0 01.159-.15.253.253 0 01.217.034 6.744 6.744 0 005.339 1.034.172.172 0 01.21.168 3.063 3.063 0 11-5.925-1.086z\"/><path d=\"M13.5 23.5a6.75 6.75 0 00-13.5 0 .5.5 0 00.5.5H13a.5.5 0 00.5-.5zM6 19.487a.75.75 0 011.5 0v1.455a.75.75 0 01-1.5 0zM21.013 15.078a3.263 3.263 0 10-3.263 3.262 3.267 3.267 0 003.263-3.262zM23.707 10.292L21.311 7.9a.249.249 0 01-.036-.306 5.009 5.009 0 10-1.684 1.683.25.25 0 01.306.037l2.4 2.4a1 1 0 101.414-1.415zM14 5a3 3 0 113 3 3 3 0 01-3-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});