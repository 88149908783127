define("ember-svg-jar/inlined/road-woods", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-woods</title><path d=\"M2.188 8.739a.5.5 0 01.5.5V10.5a1 1 0 002 0V9.238a.5.5 0 01.5-.5h1.478A.622.622 0 007.1 7.676L5.981 6.219a.051.051 0 010-.06.048.048 0 01.057-.017.628.628 0 00.638-1.042L5.15 3.213a.044.044 0 01.05-.069.629.629 0 00.6-1.082L4.053.162a.5.5 0 00-.734 0L1.557 2.065a.629.629 0 00.6 1.077.045.045 0 01.055.068l-1.5 1.9a.627.627 0 00.639 1.035.049.049 0 01.057.018.05.05 0 010 .06L.269 7.678a.621.621 0 00.439 1.061zM22.2 18.433a.05.05 0 01.059-.077.7.7 0 00.726-1.15l-1.7-2.1a.048.048 0 010-.06.049.049 0 01.058-.018.7.7 0 00.662-1.2l-1.981-2.16a.5.5 0 00-.735 0l-2 2.165a.7.7 0 00.671 1.2.049.049 0 01.059.016.051.051 0 010 .061l-1.67 2.111a.7.7 0 00.729 1.146.05.05 0 01.059.016.051.051 0 010 .061l-1.281 1.637a.692.692 0 00.489 1.181h1.828a.5.5 0 01.5.5V23a1 1 0 002 0v-1.248a.5.5 0 01.5-.5h1.806a.692.692 0 00.486-1.183z\"/><path d=\"M23.735 2.376a.505.505 0 00-.41-.107A29.4 29.4 0 00.458 23.1a.5.5 0 00.481.634h7.992a.5.5 0 00.469-.314 20.754 20.754 0 016.447-8.674.5.5 0 00.19-.4 1.639 1.639 0 01.561-1.244l1.962-2.122a1.445 1.445 0 011.105-.481 1.5 1.5 0 011.1.482l.361.393a.5.5 0 00.538.132 20.297 20.297 0 011.882-.581.5.5 0 00.376-.484V2.76a.5.5 0 00-.187-.384zM6.258 20.572q-.309.666-.58 1.35a.5.5 0 01-.465.316.491.491 0 01-.183-.038.5.5 0 01-.282-.648q.282-.713.6-1.4a.5.5 0 01.906.421zm2.973-4.841a24.88 24.88 0 00-1.624 2.331.5.5 0 01-.426.238.491.491 0 01-.261-.074.5.5 0 01-.164-.687 26.178 26.178 0 011.688-2.425.5.5 0 11.787.617zm3.987-4.031a24.879 24.879 0 00-2.109 1.9.5.5 0 01-.71-.7 25.913 25.913 0 012.194-1.979.5.5 0 11.625.779zm4.8-3.02a24.568 24.568 0 00-2.486 1.371.486.486 0 01-.266.077A.5.5 0 0115 9.2a25.27 25.27 0 012.587-1.428.5.5 0 01.431.9zm4.034-1.512q-.711.2-1.4.436a.516.516 0 01-.163.027.5.5 0 01-.162-.972q.72-.247 1.46-.452a.5.5 0 11.268.961z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});