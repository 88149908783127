define("ember-svg-jar/inlined/style-one-pin-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-home</title><path d=\"M12 0a9.5 9.5 0 00-4 18.119 2.509 2.509 0 011.218 1.222l1.872 4.076a1 1 0 001.817 0l1.876-4.079A2.5 2.5 0 0116 18.118 9.5 9.5 0 0012 0zm6.823 8.618a.5.5 0 01-.323.882h-1.25a.25.25 0 00-.25.25v4.75a.5.5 0 01-.5.5H14a.5.5 0 01-.5-.5v-2.25a1.5 1.5 0 00-3 0v2.25a.5.5 0 01-.5.5H7.5a.5.5 0 01-.5-.5V9.75a.25.25 0 00-.25-.25H5.5a.5.5 0 01-.324-.882l6.5-5.5a.5.5 0 01.647 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});