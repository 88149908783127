define("ember-svg-jar/inlined/monitor-upload.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-upload</title><path d=\"M12.18 11.657a.25.25 0 00-.232-.156H2.5A.5.5 0 012 11V2.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v7.1a.25.25 0 00.088.19.245.245 0 00.2.057 7.68 7.68 0 011.211-.1c.079 0 .157 0 .236.007A.248.248 0 0018 9.509V2a2 2 0 00-2-2H2a2 2 0 00-2 2v10.5a2 2 0 002 2h5.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5H5a1 1 0 000 2h4.508a.251.251 0 00.25-.265c0-.078-.008-.156-.008-.235a7.657 7.657 0 01.245-1.9.254.254 0 00.005-.091V15a.492.492 0 01.269-.437.25.25 0 00.114-.122 7.781 7.781 0 011.739-2.511.252.252 0 00.058-.273z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.513 6.513 0 00-6.5-6.5zm2.451 6.216a.5.5 0 01-.451.284h-.75a.25.25 0 00-.25.25v2.75a1 1 0 11-2 0v-2.75a.249.249 0 00-.25-.25h-.75a.5.5 0 01-.39-.813l2-2.5a.519.519 0 01.781 0l2 2.5a.5.5 0 01.06.53z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});