define("ember-svg-jar/inlined/shipment-tracking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-tracking</title><path d=\"M22.5.5H16a.5.5 0 00-.5.5v6.512a.5.5 0 01-.753.432l-.01-.006-2.5-1.313a.5.5 0 00-.472 0L9.233 8.014h-.006a.5.5 0 01-.727-.442V1A.5.5 0 008 .5H1.5a1 1 0 00-1 1v21a1 1 0 001 1h21a1 1 0 001-1v-21a1 1 0 00-1-1zm-1.5 20a.5.5 0 01-.5.5H15a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h5.5a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});