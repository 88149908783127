define("ember-svg-jar/inlined/delivery-person-motorcycle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>delivery-person-motorcycle-1</title><rect x=\".25\" y=\"7.5\" width=\"7\" height=\"7\" rx=\"1\" ry=\"1\"/><path d=\"M4.5 15.746A3.755 3.755 0 00.75 19.5v1.25a1 1 0 002 0V19.5a1.752 1.752 0 011.75-1.75h4.042A1.5 1.5 0 0110 19.246a2.5 2.5 0 002.5 2.5h4.85a.25.25 0 01.242.188 2.738 2.738 0 005.36-.25.249.249 0 01.2-.205.745.745 0 00.6-.733 3.742 3.742 0 00-2.581-3.546.25.25 0 01-.171-.236v-.545a.251.251 0 01.09-.192.255.255 0 01.206-.054 1.068 1.068 0 00.2.019 1.252 1.252 0 001.25-1.251v-1.882a1.251 1.251 0 00-1.809-1.119l-.124.06a.248.248 0 01-.3-.06 1.991 1.991 0 00-1.512-.7h-.5a1 1 0 000 2h.25a.25.25 0 01.25.25v6a.25.25 0 01-.25.25H12.5a.5.5 0 01-.5-.5 3.432 3.432 0 00-.308-1.407.061.061 0 01.006-.06.058.058 0 01.052-.029 1 1 0 000-2z\"/><circle cx=\"6.5\" cy=\"21.25\" r=\"2.75\"/><path d=\"M12.689 15.25h1.051a.252.252 0 01.178.074.246.246 0 01.072.178l-.017 2.238A1.25 1.25 0 0015.212 19h.011a1.25 1.25 0 001.25-1.24l.021-2.747a2.252 2.252 0 00-2.25-2.263h-1.555a.75.75 0 01-.735-.9l.137-.682a.248.248 0 01.135-.168.251.251 0 01.222 0l.609.3a4.265 4.265 0 001.9.449H16a1.25 1.25 0 000-2.5h-1.042a1.753 1.753 0 01-.782-.185l-2.367-1.182a1.25 1.25 0 00-1.785.873L9.5 11.362a3.249 3.249 0 003.186 3.888zM13.5 7.5a3.755 3.755 0 003.75-3.75.249.249 0 01.25-.25h1.25a1 1 0 000-2H16.6a.249.249 0 01-.2-.093A3.744 3.744 0 1013.5 7.5zm-1.7-3.7a.16.16 0 01.116-.051h3.065a.251.251 0 01.247.292 1.751 1.751 0 01-3.468-.121.161.161 0 01.04-.12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});