define("ember-svg-jar/inlined/earth-locate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earth-locate</title><path d=\"M15.608 21.684a.5.5 0 00-.545-.165 9.936 9.936 0 01-7.423-.531.25.25 0 01-.14-.225v-1.15a2.5 2.5 0 01.892-1.913A4.5 4.5 0 005.5 9.75H2.584a.25.25 0 01-.241-.314 9.982 9.982 0 0114.546-6.154.25.25 0 01-.122.468H14.25a2.75 2.75 0 000 5.5 2.524 2.524 0 01.978.2.5.5 0 00.45-.033 6.468 6.468 0 017.222.393.5.5 0 00.789-.511 12 12 0 10-16.348 13.76l.159.078v-.02a11.928 11.928 0 008.566.17.5.5 0 00.209-.795 28.735 28.735 0 01-.667-.808z\"/><path d=\"M19 10a5.006 5.006 0 00-5 5c0 3.171 4.457 7.664 4.647 7.854a.5.5 0 00.707 0C19.544 22.664 24 18.171 24 15a5.006 5.006 0 00-5-5zm0 6.75A1.75 1.75 0 1120.75 15 1.752 1.752 0 0119 16.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});