define("ember-svg-jar/inlined/insect-centipede-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>insect-centipede-1</title><path d=\"M23.573 18.644a1 1 0 00-1.415 0 4.788 4.788 0 01-1.122.846.25.25 0 01-.364-.264 5.077 5.077 0 00.078-.886v-1.026a.5.5 0 01.246-.43 6.651 6.651 0 002.818-3.784 1 1 0 10-1.9-.633 5.8 5.8 0 01-.98 1.853.25.25 0 01-.439-.111 13.546 13.546 0 00-1.381-3.85.489.489 0 01.044-.536 6.035 6.035 0 001.3-3.687 1 1 0 00-2 0 3.868 3.868 0 01-.5 1.88.248.248 0 01-.194.128.251.251 0 01-.216-.088 13.541 13.541 0 00-1.457-1.486.5.5 0 01-.149-.521 5.681 5.681 0 00-.267-4.249 1 1 0 10-1.79.894 3.424 3.424 0 01.3 2.072.251.251 0 01-.371.181 13.54 13.54 0 00-2.525-1.11.493.493 0 01-.334-.38A7.559 7.559 0 009.675.28a.973.973 0 00-1.364 0 1.029 1.029 0 00-.058 1.409 4.782 4.782 0 01.512 1.246.249.249 0 01-.264.322 13.88 13.88 0 00-1.39-.07 4.519 4.519 0 00-1.99.46.5.5 0 01-.451 0A4.6 4.6 0 001.4 3.22a1 1 0 10.485 1.941 2.467 2.467 0 011.1-.022.251.251 0 01.169.368 4.532 4.532 0 00.1 4.619.251.251 0 01-.17.379 2.88 2.88 0 01-1.578-.169A1 1 0 00.764 12.2a4.87 4.87 0 001.82.358 5.284 5.284 0 002.4-.6.489.489 0 01.416-.015 4.516 4.516 0 001.715.337 3.5 3.5 0 01.6.051.251.251 0 01.129.429 2.783 2.783 0 01-2.523.669 1 1 0 00-.35 1.97 5.864 5.864 0 001.023.093A4.583 4.583 0 009.412 14a.5.5 0 01.8.1 3.516 3.516 0 01.441 1.709V17.7a.247.247 0 01-.235.25 3.158 3.158 0 01-2.331-.878A1 1 0 006.7 18.518a5.107 5.107 0 003.848 1.426.5.5 0 01.482.309 4.994 4.994 0 00.615 1.088.252.252 0 01-.108.382 4.52 4.52 0 01-3.028.035 1 1 0 00-.57 1.918 7.392 7.392 0 002.118.324 5.737 5.737 0 003.243-.96.47.47 0 01.45-.04 5.045 5.045 0 005.371-.945.471.471 0 01.354-.12 5.993 5.993 0 004.1-1.873 1 1 0 00-.002-1.418zM15.7 20.885a2.022 2.022 0 112.022-2.021 2.021 2.021 0 01-2.022 2.021z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});