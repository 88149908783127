define("ember-svg-jar/inlined/love-cloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-cloud</title><path d=\"M22.308 8.946a5.441 5.441 0 00-1.658-3.9 5.349 5.349 0 00-3.071-1.469.5.5 0 01-.362-.229A7.168 7.168 0 004.2 5.4a.5.5 0 01-.424.372A4.312 4.312 0 000 10.05 3.955 3.955 0 003.95 14a1 1 0 000-2A1.953 1.953 0 012 10.05a2.311 2.311 0 012.787-2.262 1 1 0 001.2-.919 5.171 5.171 0 019.84-1.9.976.976 0 00.94.571 3.429 3.429 0 012.492.949 3.384 3.384 0 011.046 2.458 2.666 2.666 0 01-1.89 2.8 1 1 0 00.854 1.809 4.621 4.621 0 003.039-4.61z\"/><path d=\"M16.308 10.835c0-2.727-3.139-3.827-4.58-1.578a.5.5 0 01-.841 0c-1.442-2.249-4.579-1.149-4.579 1.578 0 2.207 3.337 5.031 4.614 6.031a.621.621 0 00.771 0c1.277-1 4.615-3.824 4.615-6.031zM16.225 17.988a.59.59 0 01-.835 0 1.513 1.513 0 10-2.139 2.14l2.078 2.172a.663.663 0 00.957 0l2.078-2.168a1.513 1.513 0 10-2.139-2.14zM23.889 21.128a1.055 1.055 0 00-1.69-.274.411.411 0 01-.582 0 1.055 1.055 0 00-1.492 1.492l1.449 1.511a.463.463 0 00.668 0l1.449-1.511a1.056 1.056 0 00.198-1.218z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});