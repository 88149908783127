define("ember-svg-jar/inlined/truck-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>truck-1</title><path d=\"M23.99 12.483l-.718-5.019A3.321 3.321 0 0020 4.625H9.163a4.476 4.476 0 00-4.025 2.487L3.6 10.2a.252.252 0 01-.15.127l-1.681.518A2.487 2.487 0 000 13.232a.161.161 0 00.16.161h2.09a.5.5 0 010 1H.232a.232.232 0 00-.232.232 2.992 2.992 0 002.519 2.947.2.2 0 00.231-.2 3 3 0 016 0 .25.25 0 00.25.25h7a.25.25 0 00.25-.25 3 3 0 016 0 .187.187 0 00.231.181A1.994 1.994 0 0024 15.625v-3a.969.969 0 00-.01-.142zM10.5 9.125a1 1 0 01-1 1H6.273a.25.25 0 01-.224-.362l.878-1.756a2.486 2.486 0 012.236-1.382h1.087a.25.25 0 01.25.25zm10.464.72a1.5 1.5 0 01-1.145.53h-5.472a1.493 1.493 0 01-1.479-1.254l-.167-1a1.5 1.5 0 011.48-1.746h5.472a1.493 1.493 0 011.479 1.254l.167 1a1.5 1.5 0 01-.335 1.216z\"/><circle cx=\"5.75\" cy=\"17.375\" r=\"2\"/><circle cx=\"19.25\" cy=\"17.375\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});