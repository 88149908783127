define("ember-svg-jar/inlined/wifi-heart-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-heart_1</title><path d=\"M21.039 12.552a3.5 3.5 0 00-3.058.987l-.305.3a.25.25 0 01-.353 0l-.3-.3a3.525 3.525 0 00-4.993 4.977l5.113 5.335a.5.5 0 00.723 0l5.106-5.327a3.524 3.524 0 00-1.928-5.972zM15.478 8.562a1 1 0 001.414-1.414 9.878 9.878 0 00-7.033-2.914 9.88 9.88 0 00-7.034 2.914 1 1 0 001.414 1.414 7.96 7.96 0 0111.239 0z\"/><path d=\"M18.008 5.362a1 1 0 001.415-1.414 13.546 13.546 0 00-19.13 0 1 1 0 101.414 1.414 11.542 11.542 0 0116.301 0zM13.549 11.11a.887.887 0 00.134-.133 1 1 0 00-.27-1.387 6.377 6.377 0 00-8.059.781 1 1 0 101.414 1.414 4.376 4.376 0 015.528-.537 1 1 0 001.253-.138z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});