define("ember-svg-jar/inlined/famous-character-darth-vader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character-darth-vader</title><path d=\"M22.533 18.4c-1.427-1.093-4.692-2.92-10.533-2.92S2.894 17.307 1.467 18.4A2.466 2.466 0 00.5 20.373v3.107a.5.5 0 00.5.5h5.25a.25.25 0 00.25-.25v-1.75a1.5 1.5 0 011.5-1.5h8a1.5 1.5 0 011.5 1.5v1.75a.25.25 0 00.25.25H23a.5.5 0 00.5-.5v-3.107a2.466 2.466 0 00-.967-1.973z\"/><circle cx=\"12\" cy=\"22.48\" r=\"1\"/><circle cx=\"15\" cy=\"22.48\" r=\"1\"/><path d=\"M9.342 14.1a.767.767 0 00.645.376h4.026a.766.766 0 00.645-.376c.442-.7-.858-3.62-2.658-3.62S8.9 13.4 9.342 14.1zM14 8.98c.828 0 1.5-.129 1.5-.75A1.4 1.4 0 0014 7.042a1.4 1.4 0 00-1.5 1.188c0 .621.672.75 1.5.75zM11.5 8.23A1.4 1.4 0 0010 7.042 1.4 1.4 0 008.5 8.23c0 .621.672.75 1.5.75s1.5-.129 1.5-.75z\"/><path d=\"M6.017 13.98a.5.5 0 00.492-.414c.132-.76.31-1.59.523-2.419a.134.134 0 01.234-.063l.634.846a.816.816 0 001.05.15.75.75 0 00.15-1.05L7.722 9.193a.259.259 0 01-.037-.236C8.355 7.016 9.183 5.48 10 5.48h1.25a.25.25 0 00.25-.25V.277a.259.259 0 00-.278-.256A5.84 5.84 0 009.057.6 6.515 6.515 0 005.5 6.435v2.492a4.557 4.557 0 01-.364 1.784l-.5 1.169a1.506 1.506 0 001.379 2.1zM12.75 5.48H14c.817 0 1.644 1.537 2.314 3.477a.256.256 0 01-.036.236L14.9 11.03a.75.75 0 00.15 1.05.82.82 0 001.05-.15s.55-.742.63-.84a.142.142 0 01.241.071c.211.825.388 1.65.52 2.405a.5.5 0 00.492.414 1.506 1.506 0 001.379-2.1l-.5-1.17a4.562 4.562 0 01-.362-1.785v-2.49A6.515 6.515 0 0014.944.6a5.85 5.85 0 00-2.166-.579.259.259 0 00-.278.256V5.23a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});