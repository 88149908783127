define("ember-svg-jar/inlined/team-meeting-message-men-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>team-meeting-message-men-question</title><path d=\"M20.5 9.748v-8A1.752 1.752 0 0018.75 0H5.25A1.752 1.752 0 003.5 1.748v8A1.752 1.752 0 005.25 11.5h5.5a.25.25 0 01.25.25v2a.75.75 0 001.28.53l2.707-2.71a.251.251 0 01.177-.073h3.586a1.752 1.752 0 001.75-1.749zM14.75 10a.747.747 0 00-.53.22l-1.293 1.294a.25.25 0 01-.427-.177v-.587a.75.75 0 00-.75-.75h-6.5A.25.25 0 015 9.748v-8a.25.25 0 01.25-.25h13.5a.25.25 0 01.25.25v8a.25.25 0 01-.25.25z\"/><path d=\"M12.25 2.5A2.253 2.253 0 0010 4.751a.75.75 0 101.5 0 .75.75 0 11.75.75.75.75 0 000 1.5 2.25 2.25 0 000-4.5z\"/><circle cx=\"12.25\" cy=\"8.251\" r=\".75\"/><path d=\"M4.75 12.751a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 01-2-2 1.97 1.97 0 01.081-.563.25.25 0 01.369-.144 4.91 4.91 0 003.448.622.085.085 0 01.1.085 2 2 0 01-1.998 2z\"/><path d=\"M4.75 21a4.135 4.135 0 00-3.692 2.266A.5.5 0 001.5 24H8a.5.5 0 00.442-.734A4.135 4.135 0 004.75 21zM19.25 20.751a4 4 0 10-4-4 4 4 0 004 4zm0-2a2 2 0 01-2-2 1.97 1.97 0 01.081-.563.25.25 0 01.369-.144 4.91 4.91 0 003.448.622.085.085 0 01.1.085 2 2 0 01-1.998 2zM19.25 21a4.135 4.135 0 00-3.692 2.266A.5.5 0 0016 24h6.5a.5.5 0 00.442-.734A4.135 4.135 0 0019.25 21z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});