define("ember-svg-jar/inlined/single-neutral-actions-coding.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-coding</title><path d=\"M12.632 11.158a.25.25 0 00-.064-.432c-.339-.128-.694-.259-1.069-.4l-.629-.226a.6.6 0 01-.138-.31 2.632 2.632 0 01.058-1.3 5.618 5.618 0 001.466-4.314C12.256 1.756 10.677 0 8.5 0S4.744 1.756 4.744 4.174A5.605 5.605 0 006.2 8.471a2.505 2.505 0 01.073 1.317.614.614 0 01-.135.308l-.632.233c-2.446.9-4.214 1.549-4.753 2.626A9.167 9.167 0 000 16.5a.5.5 0 00.5.5h8.794a.25.25 0 00.249-.227 7.99 7.99 0 013.089-5.615z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-4.28 5.97l2-2a.75.75 0 011.06 1.06l-1.293 1.293a.252.252 0 000 .354l1.293 1.293a.75.75 0 11-1.06 1.06l-2-2a.749.749 0 010-1.06zm6.56 3.06a.75.75 0 01-1.06-1.06l1.293-1.293a.252.252 0 000-.354L18.72 16.03a.75.75 0 111.06-1.06l2 2a.749.749 0 010 1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});