define("ember-svg-jar/inlined/data-file-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-heart</title><path d=\"M21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v9.572a.158.158 0 00.067.147c.1.053.2.105.293.164a.43.43 0 00.27 0 5.007 5.007 0 011.2-.584.242.242 0 00.17-.229V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.354.146l2.121 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-7.53a.253.253 0 00-.207.111c-.238.334-.845 1.028-1.244 1.474a.249.249 0 00.189.414H22a2 2 0 002-2V4.414A2 2 0 0023.415 3z\"/><path d=\"M13.936 9.768a.252.252 0 01.333-.071l1.507.905A1 1 0 0017 10.451a1.06 1.06 0 00.105-.123l2.687-3.734a1 1 0 10-1.623-1.168l-2.016 2.8a.251.251 0 01-.331.068L14.3 7.387a1 1 0 00-1.331.28l-2.312 3.265c.04.1.052.137.092.242a5.023 5.023 0 011.644.768zM.373 14.449a3.521 3.521 0 00.653 4.063l5.113 5.334a.5.5 0 00.723 0l5.106-5.326a3.528 3.528 0 00-2.384-6.02 3.665 3.665 0 00-2.695 1.126l-.212.212a.249.249 0 01-.353 0l-.212-.212A3.665 3.665 0 003.417 12.5a3.505 3.505 0 00-3.044 1.949z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});