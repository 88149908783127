define("ember-svg-jar/inlined/calendar-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>calendar-warning</title><path d=\"M2.5 16.5A.5.5 0 012 16V7.5a.5.5 0 01.5-.5H16a.5.5 0 01.5.5v2.7a2.649 2.649 0 012 0V3a1 1 0 00-1-1H15a.249.249 0 01-.25-.25v-1a.75.75 0 10-1.5 0V4.5a.75.75 0 01-1.5 0v-2a.5.5 0 00-.5-.5H7a.249.249 0 01-.25-.25v-1a.75.75 0 00-1.5 0V4.5a.75.75 0 11-1.5 0v-2a.5.5 0 00-.5-.5H1a1 1 0 00-1 1v13.5a2 2 0 002 2h9.256l1.05-2z\"/><path d=\"M18.781 12.272a1.449 1.449 0 00-2.561 0l-5.055 9.634a1.426 1.426 0 00.047 1.408 1.454 1.454 0 001.233.686h10.111a1.456 1.456 0 001.233-.686 1.428 1.428 0 00.047-1.409zM17.5 15.251a.75.75 0 01.75.75v3a.75.75 0 01-1.5 0V16a.75.75 0 01.75-.749zm0 7a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});