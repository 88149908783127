define("ember-svg-jar/inlined/single-man-actions-home.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-home</title><path d=\"M7.087 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.743a.5.5 0 01.742-.3A6.956 6.956 0 008.547 5a6.36 6.36 0 00.929-.07.5.5 0 01.569.564A3 3 0 014.087 5a2.841 2.841 0 01.1-.743zM12.609 13.522a.5.5 0 00.034-.731 7.493 7.493 0 00-5.35-2.291 7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h8.326a.5.5 0 00.324-.119zM21.412 24a.5.5 0 00.5-.5v-4.75a.25.25 0 01.25-.25h1.25a.5.5 0 00.324-.881l-6.5-5.5a.5.5 0 00-.647 0l-6.5 5.5a.5.5 0 00.323.881h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});