define("ember-svg-jar/inlined/seafood-fish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seafood-fish</title><path d=\"M3.518 11.337a.5.5 0 00.835-.219A19.186 19.186 0 017.789 4.29a.25.25 0 00-.249-.4 7.534 7.534 0 00-5.52 4.5 1.669 1.669 0 00.364 1.81zM21.352 14.7c-.085.09-.172.179-.26.267A18.286 18.286 0 0114 19.3a.5.5 0 00-.2.825l1.309 1.32a1.677 1.677 0 001.187.494h.028a1.68 1.68 0 001.2-.535 19.876 19.876 0 004.247-6.45.25.25 0 00-.416-.259zM23.328.655C22.371-.3 14.951-.9 10.09 3.965a18.285 18.285 0 00-4.931 11.029 1 1 0 01-.55.812L.647 17.787a1.17 1.17 0 00.018 2.1l2.207 1.057a1 1 0 01.492.518l.761 1.838a1.169 1.169 0 002.118.055l1.935-3.967A1 1 0 019 18.827a18.057 18.057 0 0011.031-4.921C24.943 8.994 24.284 1.612 23.328.655zM17.5 5.585a1.17 1.17 0 110-1.655 1.169 1.169 0 010 1.655z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});