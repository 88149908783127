define("ember-svg-jar/inlined/allowances-no-umbrellas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>allowances-no-umbrellas</title><path d=\"M10.5 18.75a.752.752 0 01-.75-.75v-.5a.75.75 0 00-1.5 0v.5a2.25 2.25 0 004.5 0v-1.086a.251.251 0 00-.073-.177l-1-1a.25.25 0 00-.427.177V18a.752.752 0 01-.75.75zM4.5 13.5A.5.5 0 005 13a1.147 1.147 0 011.25-1 1.147 1.147 0 011.25 1 .5.5 0 001 0 .617.617 0 01.036-.2.249.249 0 00-.059-.259L5.266 9.327a.25.25 0 00-.4.063A7.93 7.93 0 004 13a.5.5 0 00.5.5z\"/><path d=\"M24 12a12 12 0 10-12 12 12.013 12.013 0 0012-12zm-2 0a9.95 9.95 0 01-2.5 6.589L13.116 12.2A1.988 1.988 0 0114 12c.813 0 1.5.458 1.5 1a.5.5 0 001 0 1.147 1.147 0 011.25-1A1.147 1.147 0 0119 13a.5.5 0 001 0 8.009 8.009 0 00-7.25-7.962V4a.75.75 0 00-1.5 0v1.038a7.956 7.956 0 00-3.862 1.436L5.411 4.5A9.984 9.984 0 0122 12zM12 22A10.011 10.011 0 012 12a9.845 9.845 0 012.048-6.038l13.99 13.99A9.824 9.824 0 0112 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});