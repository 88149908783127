define("ember-svg-jar/inlined/browser-hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>browser-hand</title><path d=\"M1.025 24a1 1 0 001-1v-7.25a1 1 0 00-2 0V23a1 1 0 001 1zM15.525 19.5H6.4a.625.625 0 110-1.25h2.525a1 1 0 00.92-1.392A2.233 2.233 0 007.789 15.5H3.525a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.764a2.238 2.238 0 002.236-2.236V20.5a1 1 0 00-1-1zM20.975 0h-13a3 3 0 00-3 3v8.5a1 1 0 001 1 1 1 0 001-1V5.25a.25.25 0 01.25-.25h14.5a.25.25 0 01.25.25V14a1 1 0 01-1 1h-7.5a1 1 0 00-1 1 1 1 0 001 1h7.5a3 3 0 003-3V3a3 3 0 00-3-3zM9.322 3a.967.967 0 01-1.693 0 .969.969 0 01-.154-.5.979.979 0 01.092-.408A.981.981 0 019.322 2a.977.977 0 01.153.5.977.977 0 01-.153.5zm3.5 0a.967.967 0 01-1.693 0 .969.969 0 01-.154-.5.969.969 0 01.154-.5.967.967 0 011.693 0 .977.977 0 01.153.5.977.977 0 01-.153.5zm3.5 0a.967.967 0 01-1.693 0 .969.969 0 01-.154-.5.969.969 0 01.154-.5.967.967 0 011.693 0 .977.977 0 01.153.5.977.977 0 01-.153.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});