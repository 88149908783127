define("ember-svg-jar/inlined/tools-kitchen-basket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-kitchen-basket</title><path d=\"M12.345 14.487a.5.5 0 00-.69 0l-3.438 3.274a.5.5 0 000 .724l3.438 3.275a.5.5 0 00.69 0l3.438-3.275a.5.5 0 000-.724zM19.239 4.137a.5.5 0 00-.792.132 3.218 3.218 0 01-.5.717.5.5 0 00.014.7 8.6 8.6 0 012.539 6.073v.364a.5.5 0 01-.5.5h-6.813a.25.25 0 00-.172.431l3.89 3.706a.5.5 0 00.69 0l4.6-4.377a1 1 0 00.31-.73 10.461 10.461 0 00-3.266-7.516zM17.6 19.487a.5.5 0 00-.69 0l-3.89 3.705a.25.25 0 00.172.431H15.5a8.454 8.454 0 004.29-1.164.5.5 0 00.092-.794zM18.717 17.761a.5.5 0 000 .724l2.421 2.306a.5.5 0 00.719-.031A8.463 8.463 0 0024 15.123v-1a1.5 1.5 0 00-.032-.309.5.5 0 00-.834-.259zM2.143 20.76a.5.5 0 00.719.031l2.421-2.306a.5.5 0 000-.724L.866 13.555a.5.5 0 00-.834.259 1.5 1.5 0 00-.032.309v1a8.463 8.463 0 002.143 5.637zM7.1 19.487a.5.5 0 00-.69 0l-2.292 2.178a.5.5 0 00.092.794 8.454 8.454 0 004.29 1.164h2.313a.25.25 0 00.172-.431zM3.5 11.759A8.6 8.6 0 016.042 5.68a.5.5 0 00.013-.694 3.242 3.242 0 01-.5-.718.5.5 0 00-.791-.131A10.467 10.467 0 001.5 11.653a1 1 0 00.31.73l4.6 4.377a.5.5 0 00.69 0l3.89-3.706a.25.25 0 00-.172-.431H4a.5.5 0 01-.5-.5zM16.118 1.079a12.426 12.426 0 00-8.235 0 1.791 1.791 0 00-1.022 2.452 1.7 1.7 0 002.1.878 8.839 8.839 0 016.068 0 1.7 1.7 0 002.1-.878 1.792 1.792 0 00-1.011-2.452z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});