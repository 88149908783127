define("ember-svg-jar/inlined/real-estate-action-building-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-building-location</title><path d=\"M6.724 6.053l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 000 6.5V14a.5.5 0 00.5.5H2a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5A.5.5 0 007 14V6.5a.5.5 0 00-.276-.447zM5 10.5H2a.5.5 0 010-1h3a.5.5 0 010 1zM5 8H2a.5.5 0 010-1h3a.5.5 0 010 1zM15 11.292V3a.5.5 0 00-.308-.462l-6-2.5A.5.5 0 008 .5V14a.5.5 0 00.5.5h2.745a.5.5 0 00.5-.456 2.471 2.471 0 012.71-2.254.5.5 0 00.545-.498zm-2-.792h-3a.5.5 0 010-1h3a.5.5 0 010 1zM13 8h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zM23.352 16.622l-8.774-3.29a1 1 0 00-1.286 1.291l3.321 8.733a1 1 0 00.933.644h.064a1 1 0 00.909-.761l.845-3.463a.5.5 0 01.364-.367l3.513-.878a1 1 0 00.11-1.906z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});