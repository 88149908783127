define("ember-svg-jar/inlined/router-signal-tech", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>router-signal-tech</title><path d=\"M18.714 19.015a.25.25 0 01-.214-.247V16a1 1 0 00-2 0v2.75a.25.25 0 01-.25.25h-8.5a.25.25 0 01-.25-.25V16a1 1 0 00-2 0v2.768a.25.25 0 01-.214.247A1.5 1.5 0 004 20.5v2A1.5 1.5 0 005.5 24h13a1.5 1.5 0 001.5-1.5v-2a1.5 1.5 0 00-1.286-1.485zM12 21.5a1 1 0 11-1-1 1 1 0 011 1zm-4.5-1a1 1 0 11-1 1 1 1 0 011-1zM7 9a.5.5 0 00.447-.724l-.191-.382A.272.272 0 017.5 7.5a2 2 0 002-2v-3a2 2 0 00-2-2h-5a2 2 0 00-2 2v3a2 2 0 002 2 .272.272 0 01.244.394l-.191.382A.5.5 0 003 9zM2.5 2.75a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25h-4.5a.25.25 0 01-.25-.25zM7.562 12.55a1 1 0 001.388.276 5.583 5.583 0 016.1 0 1 1 0 101.111-1.664 7.618 7.618 0 00-8.322 0 1 1 0 00-.277 1.388z\"/><path d=\"M8.948 14.168a1 1 0 001.112 1.664 3.549 3.549 0 013.881 0 1 1 0 001.11-1.664 5.588 5.588 0 00-6.103 0zM23.5 2a2 2 0 00-2-2h-3a2 2 0 00-2 2v6.5a2 2 0 002 2h3a2 2 0 002-2V2zm-2.25 0a.25.25 0 01.25.25v4.5a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-4.5a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});