define("ember-svg-jar/inlined/vr-user-play-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vr-user-play-1</title><path d=\"M22 0h-9a2 2 0 00-2 2v6.286a.25.25 0 00.135.222 9.054 9.054 0 014.092 4.344.25.25 0 00.229.148H22a2 2 0 002-2V2a2 2 0 00-2-2zm-2.106 6.5a.935.935 0 01-.453.8l-3.033 1.82A.931.931 0 0115 8.32V4.68a.931.931 0 011.408-.8l3.033 1.82a.933.933 0 01.453.8zM7 9a6.994 6.994 0 00-6.689 4.93A1.983 1.983 0 000 15v3a2 2 0 002 2h2.5a1 1 0 00.8-.4l.9-1.2a1 1 0 011.6 0l.9 1.2a1 1 0 00.8.4H12a2 2 0 002-2v-3a1.989 1.989 0 00-.306-1.063A6.958 6.958 0 007 9zm-2.5 7h-2a.5.5 0 010-1h2a.5.5 0 010 1zm3 0h-1a.5.5 0 010-1h1a.5.5 0 010 1zm-3.969-3a.25.25 0 01-.182-.421 5.022 5.022 0 017.312 0 .25.25 0 01-.183.421zM11.147 21.447a1 1 0 00-1.385-.28 5.078 5.078 0 01-5.524 0 1 1 0 00-1.105 1.666 6.99 6.99 0 007.734 0 1 1 0 00.28-1.386z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});