define("ember-svg-jar/inlined/composition-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>composition-man</title><path d=\"M16.237 15.554c-.35-.13-.709-.261-1.074-.4a.25.25 0 01-.163-.231V12.8a.249.249 0 01.083-.186 4.247 4.247 0 001.406-2.861A1.779 1.779 0 0017 8.792a2.208 2.208 0 00-.415-2.038c.026-.068.059-.147.093-.233a8.291 8.291 0 00.814-4.175C17.159.689 14.822.049 12.922 0c-1.64-.033-3.651.329-4.267 1.458a1.883 1.883 0 00-1.371.742c-.869 1.171-.238 3.252.1 4.369l.051.167A2.194 2.194 0 007 8.792a1.779 1.779 0 00.509.962 4.247 4.247 0 001.406 2.861A.249.249 0 019 12.8v2.122a.25.25 0 01-.163.234l-1.076.4c-3.469 1.275-5.975 2.2-6.708 3.666A10.567 10.567 0 000 23.5a.5.5 0 00.5.5h23a.5.5 0 00.5-.5 10.567 10.567 0 00-1.053-4.28c-.734-1.47-3.241-2.391-6.71-3.666zM1 5a1 1 0 001-1V2.5a.5.5 0 01.5-.5H4a1 1 0 000-2H2a2 2 0 00-2 2v2a1 1 0 001 1z\"/><path d=\"M2 15h2a1 1 0 000-2H2.5a.5.5 0 01-.5-.5V11a1 1 0 00-2 0v2a2 2 0 002 2zM22 0h-2a1 1 0 000 2h1.5a.5.5 0 01.5.5V4a1 1 0 002 0V2a2 2 0 00-2-2zM23 10a1 1 0 00-1 1v1.5a.5.5 0 01-.5.5H20a1 1 0 000 2h2a2 2 0 002-2v-2a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});