define("ember-svg-jar/inlined/marine-mammal-penguin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>marine-mammal-penguin</title><path d=\"M21 23a4 4 0 01-4-4V6.237A6.176 6.176 0 0011.588.028 5.944 5.944 0 006.965 1.56a6.014 6.014 0 00-1.956 4.11C4.26 6.023 2.5 7.009 2.5 8.5A.5.5 0 003 9c.607 0 1.233.019 1.872.056C2.952 15.394 3.9 19.824 4.7 22h-.45a1 1 0 000 2H21a.5.5 0 000-1zM8.69 4.9a1 1 0 111 1 1 1 0 01-1-1zM6.913 9.229c.348.039.7.083 1.054.132A3.522 3.522 0 0111 12.831v.194a4.607 4.607 0 00-2.512 1.463A8.061 8.061 0 006.6 17.342a.5.5 0 00.556.651 43.67 43.67 0 013.08-.383c.353-.031.768.041.768.487v3.4a.5.5 0 01-.5.5H7.192a.5.5 0 01-.463-.312c-.68-1.676-1.877-5.994.184-12.456z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});