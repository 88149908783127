define("ember-svg-jar/inlined/ranking-ribbon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ranking-ribbon</title><path d=\"M3.726 16.431a.249.249 0 00-.028.409c.875.705 2.069 1.309 2.888 2.033A.249.249 0 007 18.686v-3.365a.25.25 0 00-.333-.236 16.3 16.3 0 00-2.941 1.346zM4.476 18.6a13.751 13.751 0 01-1.541-1.1.249.249 0 00-.32 0L.184 19.477a.5.5 0 00.218.877l2.01.4-.4 2.01a.5.5 0 00.69.556 10.165 10.165 0 003.8-2.787c-.002-.6-.841-1.182-2.026-1.933zM23.6 20.354a.5.5 0 00.218-.877L21.385 17.5a.251.251 0 00-.32 0 13.761 13.761 0 01-1.542 1.1c-1.184.756-2.023 1.338-2.023 1.939a10.165 10.165 0 003.8 2.787.5.5 0 00.69-.556l-.4-2.01zM20.3 16.839a.248.248 0 00.093-.212.25.25 0 00-.121-.2 16.391 16.391 0 00-2.941-1.343.25.25 0 00-.333.236v3.364a.249.249 0 00.415.187c.816-.715 2.024-1.336 2.887-2.032z\"/><path d=\"M2.658 15.578a.251.251 0 00.154.14.247.247 0 00.207-.024A18.3 18.3 0 0112 13.115a18.214 18.214 0 018.983 2.58.251.251 0 00.359-.117 1.918 1.918 0 00.158-.759v-3.845a1.5 1.5 0 00-.8-1.325 20.392 20.392 0 00-3.491-1.427.25.25 0 00-.318.29 2.464 2.464 0 01-.921 2.5 2.487 2.487 0 01-2.97.053l-.857-.613a.249.249 0 00-.29 0l-.853.612a2.489 2.489 0 01-2.977-.052 2.46 2.46 0 01-.917-2.5.251.251 0 00-.319-.29A20.409 20.409 0 003.3 9.649a1.5 1.5 0 00-.8 1.325v3.845a1.922 1.922 0 00.158.759z\"/><path d=\"M8.608 8.679a1 1 0 001.517 1.165l1.73-1.235a.249.249 0 01.29 0l1.729 1.235a1 1 0 001.518-1.164l-.737-1.966a.25.25 0 01.074-.28l1.53-1.275a1 1 0 00-.64-1.768H13.8a.25.25 0 01-.238-.174l-.606-1.889a1 1 0 00-1.9 0l-.607 1.889a.25.25 0 01-.238.174h-1.83a1 1 0 00-.64 1.768l1.53 1.275a.25.25 0 01.074.28z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});