define("ember-svg-jar/inlined/programming-laptop-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-laptop-type</title><path d=\"M13.966 18.831a.251.251 0 00.115-.328l-.62-1.345a2.233 2.233 0 011.094-2.958c.762-.35 4.755.225 6 .416a.25.25 0 00.222-.078.247.247 0 00.058-.229L20 10.951V1.515a1.5 1.5 0 00-1.5-1.5h-13a1.5 1.5 0 00-1.5 1.5v9.444l-.84 3.358a.252.252 0 00.059.23.248.248 0 00.223.077c1.245-.2 5.217-.79 6-.428a2.231 2.231 0 011.1 2.963L9.919 18.5a.251.251 0 00.115.328 2.106 2.106 0 01.716.588.252.252 0 00.2.1h2.1a.248.248 0 00.2-.1 2.119 2.119 0 01.716-.585zM6 9.015v-6a1 1 0 011-1h10a1 1 0 011 1v6a1 1 0 01-1 1H7a1 1 0 01-1-1z\"/><path d=\"M9.176 16.531a.733.733 0 00-.358-.972 43.837 43.837 0 00-4.6.459c-.312-.01-.8-.009-.972.359l-2.04 3.467A2.439 2.439 0 002.4 23.08l1.479.681a2.44 2.44 0 002.345-.167C7.54 22.744 9.4 21.2 9.4 21.2a.586.586 0 00-.453-1.071l-1.2.551a.249.249 0 01-.281-.05.253.253 0 01-.051-.282zM20.755 16.377c-.169-.368-.66-.369-.972-.359a43.837 43.837 0 00-4.6-.459.733.733 0 00-.358.972l1.758 3.817a.253.253 0 01-.051.282.249.249 0 01-.281.05l-1.2-.551A.586.586 0 0014.6 21.2s1.86 1.544 3.176 2.394a2.44 2.44 0 002.345.167l1.479-.681a2.439 2.439 0 001.2-3.236z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});