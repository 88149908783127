define("ember-svg-jar/inlined/religion-hands", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-hands</title><path d=\"M17 5.75v-2a.5.5 0 00-.5-.5h-2.25A.25.25 0 0114 3V.75a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5V3a.25.25 0 01-.25.25H8.5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h2.25a.25.25 0 01.25.25v6.25a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V6.5a.25.25 0 01.25-.25h2.25a.5.5 0 00.5-.5zM6.867 16.284a1.376 1.376 0 10-2.251 1.583L5.4 18.95a.5.5 0 01-.8.6l-1.1-1.467a2.52 2.52 0 01-.5-1.5V12.25a1.5 1.5 0 00-3 0v5.672a5.014 5.014 0 001.1 3.123l2.013 2.517a.5.5 0 00.391.188H8a.5.5 0 00.5-.5v-3.137a5.061 5.061 0 00-.93-2.774zM22.5 10.75a1.5 1.5 0 00-1.5 1.5v4.333a2.52 2.52 0 01-.5 1.5l-1.1 1.467a.5.5 0 11-.8-.6c.2-.267.585-.814.784-1.083a1.376 1.376 0 10-2.251-1.583l-.7 1.055a5.061 5.061 0 00-.93 2.774v3.137a.5.5 0 00.5.5h4.5a.5.5 0 00.391-.188l2.006-2.517a5.014 5.014 0 001.1-3.123V12.25a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});