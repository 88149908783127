define("ember-svg-jar/inlined/dog-house-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dog-house-2</title><path d=\"M12.354 7.64a.5.5 0 00-.708 0l-8.5 8.5a.5.5 0 00-.146.353v6.223a1 1 0 001 1h3a1 1 0 001-1v-2.945a4 4 0 018 0v2.945a1 1 0 001 1h3a1 1 0 001-1v-6.223a.5.5 0 00-.146-.353z\"/><path d=\"M13.061.709a1.538 1.538 0 00-2.122 0L.5 11.148a1.872 1.872 0 00-.5 1.207v3.1a.706.706 0 00.413.711.7.7 0 00.794-.211L11.646 5.519a.5.5 0 01.708 0l10.439 10.439a.677.677 0 001.207-.5v-3.1a1.872 1.872 0 00-.5-1.207z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});