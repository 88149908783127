define("ember-svg-jar/inlined/arduino-plus-minus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arduino-plus-minus</title><path d=\"M18.5 6.283c-3.3 0-5.582 3.149-6.5 4.683-.918-1.534-3.2-4.683-6.5-4.683A5.428 5.428 0 000 12a5.427 5.427 0 005.5 5.718c3.3 0 5.582-3.149 6.5-4.684.918 1.535 3.2 4.684 6.5 4.684A5.427 5.427 0 0024 12a5.428 5.428 0 00-5.5-5.717zm1 6.185H19a.25.25 0 00-.25.25v.5a.75.75 0 01-1.5 0v-.5a.25.25 0 00-.25-.25h-.5a.75.75 0 010-1.5h.5a.25.25 0 00.25-.25v-.5a.75.75 0 011.5 0v.5a.25.25 0 00.25.25h.5a.75.75 0 010 1.5zm-12.5 0H4.5a.75.75 0 010-1.5H7a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});