define("ember-svg-jar/inlined/graph-bar-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" stroke=\"#000\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"><path d=\"M.5 23.5h23M4.5 23.5v-5h-3v5M10.5 23.5v-10h-3v10M16.5 23.5v-8h-3v8M22.5 23.5v-15h-3v15\"/></g><path fill=\"none\" d=\"M0 0h24v24H0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});