define("ember-svg-jar/inlined/shopping-cart-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.5 3H4a.5.5 0 01.487.386L5.809 9H23.5a.5.5 0 01.475.659l-3 9A.502.502 0 0120.5 19H8.161l.235 1H19a2 2 0 010 4c-1.498 0-2.521-1.625-1.722-3H9.723c.791 1.362-.211 3-1.723 3-1.102 0-2-.896-2-2 0-.89.588-1.637 1.394-1.896L3.605 4H.5a.5.5 0 010-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});