define("ember-svg-jar/inlined/organic-plant-grow-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>organic-plant-grow-1</title><path d=\"M9.339 16.1a.5.5 0 00.648-.466 11.036 11.036 0 011.027-4.134.5.5 0 00-.037-.5A9 9 0 003.5 7a.5.5 0 00-.5.5 9.016 9.016 0 006.339 8.6zM9 7.057a10.554 10.554 0 012.8 2.517.25.25 0 00.2.1.253.253 0 00.2-.1 10.581 10.581 0 012.8-2.52.5.5 0 00.237-.452A9.815 9.815 0 0012.35.141a.514.514 0 00-.707 0 9.85 9.85 0 00-2.877 6.464.5.5 0 00.234.452zM11.5 16v2.25a.75.75 0 001.5 0v-1.369a.5.5 0 01.421-.494A9.013 9.013 0 0021 7.5a.5.5 0 00-.5-.5 9.01 9.01 0 00-9 9zM23 22a3.745 3.745 0 01-2.9-1.646 1.056 1.056 0 00-1.546.023A3.72 3.72 0 0115.667 22a3.743 3.743 0 01-2.9-1.646 1.055 1.055 0 00-1.547.024A3.72 3.72 0 018.333 22a3.745 3.745 0 01-2.9-1.646 1.055 1.055 0 00-1.547.024A3.718 3.718 0 011 22a1 1 0 000 2 5.126 5.126 0 003.346-1.243.5.5 0 01.639 0 5.128 5.128 0 006.694 0 .5.5 0 01.639 0 5.129 5.129 0 006.7 0 .5.5 0 01.639 0A5.131 5.131 0 0023 24a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});