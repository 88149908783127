define("ember-svg-jar/inlined/road-sign-water-ahead", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-water-ahead</title><path d=\"M23.53 11.47l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0l11-11a.749.749 0 000-1.06zM3.75 12a.75.75 0 01.75-.75h3.086a.251.251 0 00.177-.073L9.97 8.97a.75.75 0 011.06 1.06l-2.207 2.207a.251.251 0 00-.073.177V13.5a.75.75 0 01-1.5 0V13a.25.25 0 00-.25-.25H4.5a.75.75 0 01-.75-.75zm12.75 4.939a2.718 2.718 0 01-1.374-.374.25.25 0 00-.252 0 2.794 2.794 0 01-2.749 0 .248.248 0 00-.25 0 2.794 2.794 0 01-2.749 0 .25.25 0 00-.252 0 2.718 2.718 0 01-1.374.374.75.75 0 010-1.5 1.234 1.234 0 00.936-.433.776.776 0 011.128 0 1.256 1.256 0 001.872 0 .776.776 0 011.128 0 1.256 1.256 0 001.872 0 .776.776 0 011.128 0 1.234 1.234 0 00.936.433.75.75 0 010 1.5zm3-4.189H17a.25.25 0 00-.25.25v.5a.75.75 0 01-1.5 0v-1.086a.251.251 0 00-.073-.177L12.97 10.03a.75.75 0 011.06-1.06l2.207 2.207a.251.251 0 00.177.073H19.5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});