define("ember-svg-jar/inlined/meeting-headphones-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-headphones-2</title><path d=\"M24 14.249a4.5 4.5 0 00-2.842-4.18.25.25 0 01-.158-.232v-.588a9 9 0 00-18 0v.588a.251.251 0 01-.158.233A4.5 4.5 0 003 18.494a1.5 1.5 0 002-1.415v-7.83a7 7 0 0114 0v7.83a1.491 1.491 0 00.429 1.035.246.246 0 01.071.174v.461c0 1.475-1.291 2-2.5 2h-1.64a.252.252 0 01-.208-.11 2 2 0 100 2.221.253.253 0 01.208-.111H17c2.649 0 4.5-1.645 4.5-4v-.326a.252.252 0 01.131-.22A4.5 4.5 0 0024 14.249z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});