define("ember-svg-jar/inlined/rotate-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rotate-alternate</title><path d=\"M9.811 3.688a1.5 1.5 0 00-2.121 0L.438 10.94a1.5 1.5 0 000 2.121l7.252 7.251a1.5 1.5 0 002.121 0l7.251-7.251a1.5 1.5 0 000-2.121zM23.121 14.122a3 3 0 000-4.243l-1.237-1.238a.25.25 0 010-.353l.177-.177a.5.5 0 00-.283-.849L19.3 6.909a.5.5 0 00-.566.566l.353 2.475a.5.5 0 00.849.283l.177-.177a.25.25 0 01.353 0l1.237 1.238a1 1 0 010 1.413l-1.233 1.237a.25.25 0 01-.353 0l-.177-.177a.5.5 0 00-.849.282l-.353 2.476a.5.5 0 00.566.565l2.474-.353a.5.5 0 00.283-.849l-.177-.176a.252.252 0 010-.354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});