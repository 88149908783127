define("ember-svg-jar/inlined/wireless-payment-smartphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wireless-payment-smartphone</title><path d=\"M14.753 3.2a5.33 5.33 0 016.744 0 1 1 0 001.256-1.557 7.318 7.318 0 00-9.256 0A1 1 0 0014.753 3.2z\"/><path d=\"M15.164 4.3a1 1 0 001.256 1.56 2.694 2.694 0 013.41 0 1 1 0 001.256-1.56 4.684 4.684 0 00-5.922 0zM.715 12.506h6.82a.5.5 0 00.493-.582l-.764-4.582a1 1 0 00-.986-.836h-.9a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h.75a.5.5 0 00.5-.5v-1.5a.5.5 0 00-.5-.5h-4a.5.5 0 00-.5.5v1.5a.5.5 0 00.5.5h.75a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25h-.9a1 1 0 00-.986.836l-.77 4.582a.5.5 0 00.493.582zm2.41-4.5h2a.5.5 0 010 1h-2a.5.5 0 010-1zM9.625 14.006h-9a.5.5 0 00-.5.5v9a.5.5 0 00.5.5h8.5a1 1 0 001-1v-8.5a.5.5 0 00-.5-.5zM16.681 15.423a.75.75 0 100 1.5h.444a.25.25 0 01.25.25v.083a.75.75 0 001.5 0V17.1a.188.188 0 01.169-.187 1.345 1.345 0 001.275-1.458c0-.872-.862-1.452-1.775-2.067-.144-.1-.325-.218-.5-.339a.25.25 0 01.142-.455h1.381a.75.75 0 000-1.5h-.444a.25.25 0 01-.25-.25v-.084a.75.75 0 00-1.5 0v.157a.187.187 0 01-.172.187 1.318 1.318 0 00-1.272 1.462c0 .872.862 1.452 1.775 2.066.143.1.323.218.5.342a.25.25 0 01-.146.453z\"/><path d=\"M21.125 7.256h-6a2.753 2.753 0 00-2.75 2.75v11.25a2.752 2.752 0 002.75 2.75h6a2.752 2.752 0 002.75-2.75v-11.25a2.753 2.753 0 00-2.75-2.75zm-4 14.5a1 1 0 111 1 1 1 0 01-1-1zm-2-13h6a1.252 1.252 0 011.25 1.25v9.25a.25.25 0 01-.25.25h-8a.25.25 0 01-.25-.25v-9.25a1.252 1.252 0 011.25-1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});