define("ember-svg-jar/inlined/tools-wench-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-wench-2</title><path d=\"M23.46 7.855a.5.5 0 00-.415-.779h-1.694a.5.5 0 01-.343-.136l-1.591-1.5a.5.5 0 010-.728l1.591-1.5a.5.5 0 01.343-.136h1.694a.5.5 0 00.415-.776 5.053 5.053 0 00-.622-.757 5 5 0 00-8.427 4.578.5.5 0 01-.135.459l-9.21 9.21a.5.5 0 01-.382.145A4 4 0 108.446 19.7a.5.5 0 01.146-.381l9.219-9.218a.5.5 0 01.458-.136 4.972 4.972 0 004.569-1.358 5.045 5.045 0 00.622-.752zM5.514 20.985a1.5 1.5 0 110-2.121 1.5 1.5 0 010 2.121z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});