define("ember-svg-jar/inlined/crypto-currency-bitcoin-cloud-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-cloud-sync</title><path d=\"M14.458 6.476A3.041 3.041 0 0011.5 2.927a.25.25 0 01-.219-.135 4.254 4.254 0 00-8 1.444.249.249 0 01-.281.219A2.283 2.283 0 00.5 6.728a2.308 2.308 0 002.434 2.283h7.334a.248.248 0 00.155-.054 8.96 8.96 0 013.744-1.752.252.252 0 00.2-.2zM15.25 19.011a.25.25 0 00.25.25H17a1.25 1.25 0 000-2.5h-1.5a.25.25 0 00-.25.25z\"/><path d=\"M16 8.511a7.5 7.5 0 107.5 7.5 7.5 7.5 0 00-7.5-7.5zm3.75 9.5a2.752 2.752 0 01-2.75 2.75.25.25 0 00-.25.25.75.75 0 01-1.5 0 .25.25 0 00-.25-.25h-.5a.75.75 0 01-.75-.75v-8a.75.75 0 01.75-.75h.5a.25.25 0 00.25-.25.75.75 0 011.5 0 .25.25 0 00.25.25 2.742 2.742 0 012.04 4.583.25.25 0 000 .335 2.723 2.723 0 01.71 1.832z\"/><path d=\"M18.25 14.011a1.252 1.252 0 00-1.25-1.25h-1.5a.25.25 0 00-.25.25v2a.25.25 0 00.25.25H17a1.251 1.251 0 001.25-1.25zM7.462 13.953a.494.494 0 00-.086-.5c-.01-.012-.011-.03-.022-.042l-3-3a.5.5 0 00-.708 0l-3 3a.5.5 0 00.354.85h1.5a.25.25 0 01.25.25v4.5A3.254 3.254 0 006 22.261h.976a1.25 1.25 0 100-2.5H6a.752.752 0 01-.75-.75v-4.5a.25.25 0 01.25-.25H7a.65.65 0 00.462-.308z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});