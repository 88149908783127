define("ember-svg-jar/inlined/pin-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin-star</title><path d=\"M12 0a8.51 8.51 0 00-8.5 8.5c0 4.623 6.415 13.391 7.7 15.1a1 1 0 001.6 0c1.286-1.71 7.7-10.477 7.7-15.1A8.51 8.51 0 0012 0zm5.1 7.5l-1.8 1.772a.5.5 0 00-.108.555l1 2.289a.88.88 0 01-.222 1.019.907.907 0 01-.609.238.921.921 0 01-.452-.121l-2.532-1.427a.5.5 0 00-.491 0l-2.534 1.427a.9.9 0 01-1.061-.117.882.882 0 01-.222-1.019l.995-2.289a.5.5 0 00-.107-.555L7.157 7.5a.876.876 0 01-.214-.967A.862.862 0 017.761 6h2.017a.5.5 0 00.454-.29l1.083-2.334a.912.912 0 011.626-.008v.008l1.08 2.334a.5.5 0 00.454.29H16.5a.863.863 0 01.819.531.873.873 0 01-.219.969z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});