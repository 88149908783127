define("ember-svg-jar/inlined/human-resources-businessman-mail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-businessman-mail</title><path d=\"M14.068 2.461a2 2 0 00-2-2h-10a2 2 0 00-2 2v6.957a2 2 0 002 2h10a2 2 0 002-2zM7.4 5.81a.249.249 0 01-.282.005A418.871 418.871 0 002.8 3a.249.249 0 01.134-.46h8.345a.25.25 0 01.144.454zm-5.332-.462a.25.25 0 01.388-.208c4.266 2.808 3.923 2.582 3.973 2.609a1.818 1.818 0 001.722-.026c.046-.025-.257.19 3.523-2.489a.25.25 0 01.394.2v3.73a.249.249 0 01-.25.25h-9.5a.249.249 0 01-.25-.25zM21.45 8.879a.251.251 0 01-.011-.432 4.081 4.081 0 10-4.373-.077.249.249 0 01-.027.432 4.762 4.762 0 00-2.656 4.262v2.851a.5.5 0 00.5.5h1.458a.25.25 0 01.249.225l.645 6.448a.5.5 0 00.5.451h2.85a.5.5 0 00.5-.451l.645-6.448a.249.249 0 01.248-.225h1.46a.5.5 0 00.5-.5v-2.851a4.775 4.775 0 00-2.488-4.185zm-1.543 3.376a.75.75 0 01-1.5 0v-1.692a.75.75 0 111.5 0zM17.326 4.39a.25.25 0 01.368-.139 5.071 5.071 0 002.639.738 5.147 5.147 0 00.944-.088.089.089 0 01.106.088 2.076 2.076 0 01-4.151 0 1.976 1.976 0 01.094-.599z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});