define("ember-svg-jar/inlined/team-meeting-message-lock-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>team-meeting-message-lock-1</title><path d=\"M19.25 12.016a1.752 1.752 0 001.75-1.75V1.749A1.753 1.753 0 0019.25 0h-3a.75.75 0 000 1.5h3a.251.251 0 01.25.25v8.517a.25.25 0 01-.25.25h-4a.747.747 0 00-.53.22l-1.293 1.293a.25.25 0 01-.427-.177v-.587a.75.75 0 00-.75-.75h-7a.25.25 0 01-.25-.25V1.749a.251.251 0 01.25-.25h3a.75.75 0 000-1.5h-3a1.753 1.753 0 00-1.75 1.75v8.517a1.752 1.752 0 001.75 1.75h6a.25.25 0 01.25.25v2a.75.75 0 001.28.53l2.707-2.71a.251.251 0 01.177-.073z\"/><path d=\"M8.5 4.769v3a1.752 1.752 0 001.75 1.75h4A1.752 1.752 0 0016 7.769v-3a1.749 1.749 0 00-.875-1.515.251.251 0 01-.125-.216v-.269a2.75 2.75 0 00-5.5 0v.269a.251.251 0 01-.125.216A1.749 1.749 0 008.5 4.769zm6 3a.25.25 0 01-.25.25h-4a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25h4a.25.25 0 01.25.25zm-3.5-5a1.25 1.25 0 112.5 0 .25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25z\"/><circle cx=\"12.25\" cy=\"6.288\" r=\".75\"/><circle cx=\"5\" cy=\"16.499\" r=\"3\"/><path d=\"M5 20.5a4.674 4.674 0 00-4.278 2.807.5.5 0 00.462.693h7.632a.5.5 0 00.462-.692A4.674 4.674 0 005 20.5z\"/><circle cx=\"19\" cy=\"16.499\" r=\"3\"/><path d=\"M23.278 23.307a4.663 4.663 0 00-8.556 0 .5.5 0 00.462.692h7.633a.5.5 0 00.461-.692z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});