define("ember-svg-jar/inlined/travel-insurance-plane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>travel-insurance-plane</title><path d=\"M8.891 2.816l1.733.717a.249.249 0 00.2 0L14.4 1.972a.25.25 0 00-.005-.461L11.366.259a1 1 0 00-.764 0L8.891.968a1 1 0 000 1.848z\"/><path d=\"M6.71 9.936a1 1 0 001.08.222l4.239-1.7a.25.25 0 01.327.32l-1.4 3.747a.992.992 0 00.266 1.094 1.112 1.112 0 001.117.152l1.977-.988a1 1 0 00.448-.448l3.058-6.118a.252.252 0 01.137-.122l3.574-1.332a2.145 2.145 0 00.707-3.479 2.153 2.153 0 00-2.334-.46l-.017.007-10.944 4.76-2.033-1.017a1 1 0 00-.84-.025l-1.967.844a1 1 0 00-.312 1.626z\"/><rect y=\"13.883\" width=\"5\" height=\"9\" rx=\"1\" ry=\"1\"/><path d=\"M23.854 17.989a3.241 3.241 0 00-3.516-1.119l-2.5.856a.291.291 0 00-.174.312 2.012 2.012 0 01.031.345 2.461 2.461 0 01-.77 1.781 2.75 2.75 0 01-1.925.719h-4a1.484 1.484 0 01-1.126-.516c-.122-.14-.3-.484.146-.484H15a1.511 1.511 0 100-3h-2.7a.227.227 0 01-.161-.061A7.024 7.024 0 007.5 14.883H6.25a.25.25 0 00-.25.25v6.611a.253.253 0 00.171.237c.5.171.963.334 1.383.481 2.5.876 3.89 1.355 5.146 1.355 1.519 0 2.841-.7 5.7-2.241a174.871 174.871 0 015.329-2.786.5.5 0 00.269-.368.5.5 0 00-.144-.433z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});