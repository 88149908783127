define("ember-svg-jar/inlined/common-file-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-add</title><path d=\"M23.414 3L21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.273a.249.249 0 00.246.25 14.34 14.34 0 011.465.077A.247.247 0 008 9.353V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.244.244 0 00-.241.21 7.935 7.935 0 01-.364 1.458.242.242 0 00.225.331h7.745A1.994 1.994 0 0024 18.5V4.415A2 2 0 0023.414 3z\"/><path d=\"M6.5 11a6.5 6.5 0 106.5 6.5A6.508 6.508 0 006.5 11zM9 18.25H7.5a.25.25 0 00-.25.25V20a.75.75 0 01-1.5 0v-1.5a.25.25 0 00-.25-.25H4a.75.75 0 010-1.5h1.5a.25.25 0 00.25-.25V15a.75.75 0 011.5 0v1.5a.25.25 0 00.25.25H9a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});