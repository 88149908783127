define("ember-svg-jar/inlined/hospital-building-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hospital-building-1</title><path d=\"M6.348 12.146A.5.5 0 006 12H1.5A1.5 1.5 0 000 13.5V23a1 1 0 001 1h5a.5.5 0 00.5-.5V12.505a.5.5 0 00-.147-.354zM4 21.75H2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm0-3H2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm0-3H2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zM15.293 13.783a7.92 7.92 0 01-6.586 0 .5.5 0 00-.707.455V23.5a.5.5 0 00.5.5H10a.5.5 0 00.5-.5V22a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v1.5a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-9.262a.5.5 0 00-.707-.455zM13 18.75h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zM22.5 12H18a.5.5 0 00-.353.146h-.005a.5.5 0 00-.147.354v11a.5.5 0 00.5.5h5a1 1 0 001-1v-9.5A1.5 1.5 0 0022.5 12zm-.5 9.75h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm0-3h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm0-3h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5z\"/><path d=\"M12 0a6.5 6.5 0 106.5 6.5A6.508 6.508 0 0012 0zm3.5 7.5a.5.5 0 01-.5.5h-1a.5.5 0 00-.5.5v1a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1A.5.5 0 0010 8H9a.5.5 0 01-.5-.5v-2A.5.5 0 019 5h1a.5.5 0 00.5-.5v-1A.5.5 0 0111 3h2a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});