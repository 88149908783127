define("ember-svg-jar/inlined/presentation-amphitheater", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>presentation-amphitheater</title><circle cx=\"12\" cy=\"2.5\" r=\"2.5\"/><path d=\"M14.181 16.061a.993.993 0 00.819-.989v-5.43a.5.5 0 011 0V13.5a1.25 1.25 0 002.5 0V9.892a4 4 0 00-4-4h-5a4 4 0 00-4 4V13.5a1.25 1.25 0 002.5 0V9.642a.5.5 0 011 0v5.43a.993.993 0 00.819.989 13.994 13.994 0 004.362 0zM2.139 21.662A21.672 21.672 0 0012 24a21.672 21.672 0 009.861-2.338 1 1 0 00-.9-1.786A19.658 19.658 0 0112 22a19.658 19.658 0 01-8.959-2.124 1 1 0 00-.9 1.786z\"/><path d=\"M4.231 17.888a16.984 16.984 0 0015.538 0 1 1 0 00-.916-1.777 15.242 15.242 0 01-13.706 0 1 1 0 00-.916 1.777z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});