define("ember-svg-jar/inlined/vip-crown-king", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vip-crown-king</title><path d=\"M11.75 8.125a3.866 3.866 0 011.174.184.25.25 0 00.326-.239V5.375h1.5a1 1 0 001-1v-1a1 1 0 00-1-1h-1.5v-1.25a1 1 0 00-1-1h-1a1 1 0 00-1 1v1.25h-1.5a1 1 0 00-1 1v1a1 1 0 001 1h1.5v2.7a.25.25 0 00.326.239 3.866 3.866 0 011.174-.189zM2.887 20.125a.5.5 0 00-.276.084 1.989 1.989 0 00-.861 1.666 1.937 1.937 0 002 2h16a1.931 1.931 0 001.989-2 1.852 1.852 0 00-.8-1.675.5.5 0 00-.264-.075z\"/><path d=\"M21.773 19.125a.5.5 0 00.373-.166A7.216 7.216 0 0024 14.125a6.257 6.257 0 00-6.25-6.25 6.184 6.184 0 00-3.263.945.25.25 0 00-.117.188.255.255 0 00.078.207 4.059 4.059 0 011.3 2.976v1.53a.5.5 0 01-1 0v-1.53a3 3 0 10-6 0v1.53a.5.5 0 01-1 0v-1.53a4.084 4.084 0 011.481-3.153.251.251 0 00-.041-.413 6.183 6.183 0 00-2.938-.75A6.257 6.257 0 000 14.125a7.216 7.216 0 001.854 4.834.5.5 0 00.373.166z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});