define("ember-svg-jar/inlined/movie-celebrity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>movie-celebrity</title><path d=\"M19.868 15.5a1 1 0 00-1.736.992l4 7A1 1 0 0023 24a.988.988 0 00.495-.132 1 1 0 00.372-1.364zM5.5 15.132a1 1 0 00-1.364.372l-4 7A1 1 0 00.5 23.868.988.988 0 001 24a1 1 0 00.869-.5l4-7a1 1 0 00-.369-1.368zM3.75 4.5a.75.75 0 00-.75.75v1.5a.75.75 0 001.5 0v-1.5a.75.75 0 00-.75-.75zM7.5 3.75A.75.75 0 006.75 3h-1.5a.75.75 0 000 1.5h1.5a.75.75 0 00.75-.75zM3.75 3a.75.75 0 00.75-.75V.75a.75.75 0 00-1.5 0v1.5a.75.75 0 00.75.75zM.75 4.5h1.5a.75.75 0 000-1.5H.75a.75.75 0 000 1.5zM20.25 9.5a.75.75 0 00-.75.75v1.5a.75.75 0 001.5 0v-1.5a.75.75 0 00-.75-.75zM21.75 9.5h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5zM20.25 8a.75.75 0 00.75-.75v-1.5a.75.75 0 00-1.5 0v1.5a.75.75 0 00.75.75zM19.5 8.75a.75.75 0 00-.75-.75h-1.5a.75.75 0 000 1.5h1.5a.75.75 0 00.75-.75z\"/><path d=\"M14.305 9.347a.25.25 0 01-.012-.431 4.075 4.075 0 10-4.413-.083.25.25 0 01-.027.431 4.778 4.778 0 00-2.627 4.263v2.851a.5.5 0 00.146.353.5.5 0 00.354.147h1.231a.5.5 0 01.5.45l.623 6.223a.5.5 0 00.5.45h2.85a.5.5 0 00.5-.45l.623-6.223a.5.5 0 01.5-.45h1.231a.5.5 0 00.354-.147.5.5 0 00.146-.353v-2.851a4.778 4.778 0 00-2.479-4.18zm-1.555 3.371a.75.75 0 01-1.5 0v-1.692a.75.75 0 011.5 0zm-.6-5.192a2.077 2.077 0 01-1.988-2.672.25.25 0 01.369-.141 5.079 5.079 0 002.644.738 5.194 5.194 0 00.753-.055.25.25 0 01.21.066.253.253 0 01.075.207 2.076 2.076 0 01-2.062 1.857z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});