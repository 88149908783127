define("ember-svg-jar/inlined/video-player-adjust", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-player-adjust</title><path d=\"M21 0H3a3 3 0 00-3 3v18a3 3 0 003 3h18a3 3 0 003-3V3a3 3 0 00-3-3zm-.5 20.75h-1.75a.75.75 0 010-1.5h1.75a.75.75 0 010 1.5zm-10.25 0H3.5a.75.75 0 010-1.5h6.75a.75.75 0 010 1.5zM12.5 20a2 2 0 112 2 2 2 0 01-2-2zm-10-4.5A.5.5 0 012 15V3a1 1 0 011-1h18a1 1 0 011 1v12a.5.5 0 01-.5.5z\"/><path d=\"M15.48 7.591l-5.921-3.45A1.04 1.04 0 008 5.05v6.9a1.04 1.04 0 001.559.909l5.921-3.45a1.054 1.054 0 000-1.818z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});