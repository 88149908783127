define("ember-svg-jar/inlined/single-woman-actions-flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-flash</title><path d=\"M.226 18.362a.494.494 0 00.393.2h2.3a.247.247 0 01.243.2l.921 4.847v.007a.5.5 0 00.484.384h2.969a.5.5 0 00.483-.388v-.007l.921-4.847a.247.247 0 01.243-.2h2.32a.5.5 0 00.394-.2.493.493 0 00.084-.431c-1.964-7.162-1.879-7.546-3.007-8.6a.5.5 0 00-.756.1L6.483 12.16a.5.5 0 01-.837 0L3.923 9.411a.5.5 0 00-.755-.1C2.015 10.373 2.1 10.78.141 17.931a.5.5 0 00.085.431zM1.835 4.206A4.206 4.206 0 106.041 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227A2.224 2.224 0 013.9 4.81a.248.248 0 01.158-.3 4.623 4.623 0 001.8-1.128.247.247 0 01.353 0 4.615 4.615 0 001.8 1.128.249.249 0 01.158.3 2.224 2.224 0 01-2.128 1.623zM21.791 16.082a.247.247 0 01-.247-.247v-4.206a.495.495 0 00-.878-.313l-5.219 6.395a.814.814 0 00.621 1.338h1.273a.247.247 0 01.247.247v4.206a.5.5 0 00.329.466.48.48 0 00.163.032.494.494 0 00.383-.182l5.219-6.4a.814.814 0 00-.621-1.338z\"/><path d=\"M.226 18.362a.494.494 0 00.393.2h2.3a.247.247 0 01.243.2l.921 4.847v.007a.5.5 0 00.484.384h2.969a.5.5 0 00.483-.388v-.007l.921-4.847a.247.247 0 01.243-.2h2.32a.5.5 0 00.394-.2.493.493 0 00.084-.431c-1.964-7.162-1.879-7.546-3.007-8.6a.5.5 0 00-.756.1L6.483 12.16a.5.5 0 01-.837 0L3.923 9.411a.5.5 0 00-.755-.1C2.015 10.373 2.1 10.78.141 17.931a.5.5 0 00.085.431zM1.835 4.206A4.206 4.206 0 106.041 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227A2.224 2.224 0 013.9 4.81a.248.248 0 01.158-.3 4.623 4.623 0 001.8-1.128.247.247 0 01.353 0 4.615 4.615 0 001.8 1.128.249.249 0 01.158.3 2.224 2.224 0 01-2.128 1.623zM21.791 16.082a.247.247 0 01-.247-.247v-4.206a.495.495 0 00-.878-.313l-5.219 6.395a.814.814 0 00.621 1.338h1.273a.247.247 0 01.247.247v4.206a.5.5 0 00.329.466.48.48 0 00.163.032.494.494 0 00.383-.182l5.219-6.4a.814.814 0 00-.621-1.338z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});