define("ember-svg-jar/inlined/playlist-edit-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>playlist-edit-alternate</title><path d=\"M13.5 9.25V5.2a1.744 1.744 0 00-.631-1.347 1.774 1.774 0 00-1.533-.349l-3.409 1.1A1.752 1.752 0 006.5 6.329V9.25a.25.25 0 01-.25.25A1.75 1.75 0 108 11.25V6.329c0-.12.086-.223.295-.268l3.412-1.1A.25.25 0 0112 5.2v2.05a.25.25 0 01-.25.25 1.75 1.75 0 101.75 1.75zM4.5 14.5a.75.75 0 000 1.5h6a.75.75 0 000-1.5z\"/><path d=\"M11.248 17.928a.25.25 0 00-.176-.428H2.5A.5.5 0 012 17V2.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v10.067a.25.25 0 00.427.177l1.28-1.28a1 1 0 00.293-.707V2a2 2 0 00-2-2H2a2 2 0 00-2 2v15.5a2 2 0 002 2h7.877a.485.485 0 00.464-.344 3.129 3.129 0 01.907-1.228zM12.062 20.132a.248.248 0 00-.249-.063.253.253 0 00-.173.191l-.628 3.14a.5.5 0 00.49.6.466.466 0 00.1-.01l3.143-.628a.251.251 0 00.19-.173.248.248 0 00-.063-.249zM23.228 11.766a2.618 2.618 0 00-3.7 0l-6.5 6.5a.5.5 0 000 .707l3 3a.5.5 0 00.707 0l6.5-6.5a2.621 2.621 0 00-.007-3.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});