define("ember-svg-jar/inlined/common-file-text-cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-text-cash</title><path d=\"M12 8.5h7a1 1 0 000-2h-7a1 1 0 000 2z\"/><path d=\"M23.414 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.275a.248.248 0 00.242.25 19.17 19.17 0 011.485.083A.246.246 0 008 9.36V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.245.245 0 00-.241.21 7.956 7.956 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M17.5 12a1 1 0 00-1-1h-4a.959.959 0 00-.684.292.193.193 0 00.023.263 8.01 8.01 0 011.2 1.342.24.24 0 00.2.1H16.5a1 1 0 001-.997zM6.5 11a6.5 6.5 0 106.5 6.5A6.508 6.508 0 006.5 11zm.887 10.2a.184.184 0 00-.137.2v.354a.75.75 0 01-1.5 0v-.334a.17.17 0 00-.164-.166H5a.75.75 0 010-1.5h2.033a.592.592 0 00.22-1.141l-2.063-.83a2.079 2.079 0 01.4-3.98v-.008a.191.191 0 00.164-.2v-.35a.75.75 0 011.5 0v.335a.171.171 0 00.169.165H8a.75.75 0 010 1.5H5.967a.592.592 0 00-.22 1.141l2.063.826a2.079 2.079 0 01-.423 3.988z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});