define("ember-svg-jar/inlined/microphone-podcast-international", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>microphone-podcast-international</title><path d=\"M7.805 9.91a5.216 5.216 0 01-1.057-3.041.247.247 0 01.071-.18A.25.25 0 017 6.613h1.492a.25.25 0 01.25.248c0 .224.012.581.047.983a.75.75 0 101.494-.128 10.607 10.607 0 01-.04-.85.249.249 0 01.25-.253h3a.249.249 0 01.25.253c0 .361-.023.646-.037.821a.75.75 0 001.494.123c.032-.387.041-.731.043-.949a.251.251 0 01.25-.248H17a.251.251 0 01.25.257 5.224 5.224 0 01-.993 2.93.75.75 0 101.217.876 6.75 6.75 0 10-10.864.137.75.75 0 001.195-.903zm9.058-5.141a.249.249 0 01-.232.344h-1.274a.249.249 0 01-.247-.213 12.694 12.694 0 00-.522-2.2.25.25 0 01.379-.285 5.326 5.326 0 011.9 2.355zM12.306 1.5a.247.247 0 01.207.135 10.91 10.91 0 011.066 3.186.25.25 0 01-.247.292h-2.681a.25.25 0 01-.246-.292 10.937 10.937 0 011.065-3.186.254.254 0 01.207-.135 5.494 5.494 0 01.629 0zM8.28 3.029a5.365 5.365 0 01.737-.615.25.25 0 01.383.286 12.513 12.513 0 00-.522 2.2.25.25 0 01-.247.214h-1.27a.25.25 0 01-.232-.343A5.208 5.208 0 018.28 3.029z\"/><path d=\"M11.992 18.259a2.971 2.971 0 003-2.934v-2.84a3 3 0 00-6 0v2.84a2.97 2.97 0 003 2.934z\"/><path d=\"M17.742 13.759a.75.75 0 00-1.5 0v1.5a4.25 4.25 0 11-8.5 0v-1.5a.75.75 0 00-1.5 0v1.5a5.758 5.758 0 004.791 5.67.251.251 0 01.209.246v1.084a.25.25 0 01-.25.25h-3a.75.75 0 000 1.5h8a.75.75 0 100-1.5h-3a.25.25 0 01-.25-.25v-1.084a.25.25 0 01.208-.246 5.759 5.759 0 004.792-5.67z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});