define("ember-svg-jar/inlined/style-three-pin-car-repair", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-car-repair</title><path d=\"M13 9.5h-2a1.74 1.74 0 00-1.946 1.712.249.249 0 00.246.288h5.4a.249.249 0 00.247-.288A1.74 1.74 0 0013 9.5z\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM9.561 4a1.246 1.246 0 00-.976.469.75.75 0 01-1.17-.938 2.738 2.738 0 014.725.8.252.252 0 00.236.166h2.185a.75.75 0 010 1.5h-2.185a.252.252 0 00-.236.166 2.738 2.738 0 01-4.725.8.75.75 0 011.17-.938A1.25 1.25 0 109.561 4zM17.3 14.359a2.014 2.014 0 01-.688.793.253.253 0 00-.111.208v.89a.75.75 0 01-1.5 0v-.5a.25.25 0 00-.25-.25H9.25a.25.25 0 00-.25.25v.5a.75.75 0 01-1.5 0v-.89a.253.253 0 00-.111-.208 2.014 2.014 0 01-.688-.793.25.25 0 01.225-.359H8.5a.5.5 0 000-1H6.928a.25.25 0 01-.226-.358 2.005 2.005 0 011.168-1.034.251.251 0 00.17-.214 3.136 3.136 0 011.138-2.369.25.25 0 01.185-.046 1.29 1.29 0 00.2.021 3.686 3.686 0 001.779-.469.252.252 0 01.12-.031H13c1.775 0 2.785 1.005 2.96 2.894a.251.251 0 00.17.214 2.005 2.005 0 011.17 1.034.25.25 0 01-.226.358H15.5a.5.5 0 000 1h1.574a.25.25 0 01.225.359z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});