define("ember-svg-jar/inlined/volume-control-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>volume-control-off</title><path d=\"M14.677 6.058a.251.251 0 00.073-.177V3.752a1 1 0 00-1.707-.707L7.116 8.972a.249.249 0 01-.177.073H4.75a2 2 0 00-2 2v3.414a2 2 0 001.424 1.915.25.25 0 00.249-.063zM23.707 1.709a1 1 0 000-1.414 1.01 1.01 0 00-1.414 0l-22 22a1 1 0 000 1.414 1.03 1.03 0 00.146.121 1 1 0 001.268-.121l6.116-6.116a.251.251 0 01.354 0l4.865 4.866a1 1 0 001.708-.707V10.77a.251.251 0 01.073-.177z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});