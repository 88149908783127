define("ember-svg-jar/inlined/rain-umbrella", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rain-umbrella</title><path d=\"M11.321 16.9a.249.249 0 00-.321.24v4.11a2.75 2.75 0 005.5 0 1 1 0 00-2 0 .75.75 0 01-1.5 0v-4.11a.249.249 0 00-.321-.239 2.378 2.378 0 01-1.358 0zM12 0a1 1 0 00-1 1v.323a.249.249 0 01-.225.248A12.166 12.166 0 000 14v.5a1 1 0 002 0c0-.533.7-1 1.5-1s1.5.467 1.5 1a1 1 0 002 0c0-.408.779-1 2-1s2 .592 2 1a1 1 0 002 0c0-.408.779-1 2-1s2 .592 2 1a1 1 0 002 0c0-.533.7-1 1.5-1s1.5.467 1.5 1a1 1 0 002 0V14A12.166 12.166 0 0013.225 1.571.249.249 0 0113 1.323V1a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});