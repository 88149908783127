define("ember-svg-jar/inlined/agriculture-machine-plow-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>agriculture-machine-plow-2</title><path d=\"M23 15.5h-4.5a.5.5 0 01-.5-.5v-1.132a.5.5 0 00-.276-.447L13.276 11.2a.5.5 0 01-.276-.45v-3a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v3a.5.5 0 01-.276.447l-4.448 2.224a.5.5 0 00-.276.447V15a.5.5 0 01-.5.5H1a1 1 0 000 2 .5.5 0 01.5.5v2.649a1.5 1.5 0 00.329.937l1.78 2.227A.5.5 0 004.5 23.5V18a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2.649a1.5 1.5 0 00.329.937l1.78 2.227a.5.5 0 00.891-.313V18a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2.649a1.5 1.5 0 00.329.937l1.78 2.227a.5.5 0 00.891-.313V18a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2.649a1.5 1.5 0 00.329.937l1.78 2.227a.5.5 0 00.891-.313V18a.5.5 0 01.5-.5 1 1 0 000-2zm-6.486-.188a.249.249 0 01-.242.188H7.728a.25.25 0 01-.12-.469l3.153-1.72A.5.5 0 0111 13.25h2a.5.5 0 01.239.061l3.153 1.72a.25.25 0 01.122.281z\"/><path d=\"M2.8 9.975A1.5 1.5 0 004.221 11h4.425a1 1 0 00.447-.105l.631-.316a.5.5 0 00.276-.447V7.75a1.5 1.5 0 011.5-1.5h1a1.5 1.5 0 011.5 1.5v2.382a.5.5 0 00.276.447l.631.316a1 1 0 00.447.105h4.425A1.5 1.5 0 0021.2 9.975l.721-2.162A1.5 1.5 0 0022 7.338V5.5A1.5 1.5 0 0020.5 4h-3.208a.5.5 0 01-.481-.364 5 5 0 00-9.622 0A.5.5 0 016.708 4H3.5A1.5 1.5 0 002 5.5v1.838a1.49 1.49 0 00.077.474zM17 6.5h2.5a.75.75 0 010 1.5H17a.75.75 0 010-1.5zM9.329 3.636a3 3 0 015.342 0 .249.249 0 01-.223.364h-4.9a.25.25 0 01-.222-.364zM4.5 6.5H7A.75.75 0 017 8H4.5a.75.75 0 010-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});