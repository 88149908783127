define("ember-svg-jar/inlined/phone-broken", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-broken</title><path d=\"M18.336 12.987l-2.5-.892a1 1 0 00-.988.183L12.9 13.953l-2.878-1.768a1 1 0 00-1.043 0L5.48 14.31a1 1 0 00-.48.854V21a3 3 0 003 3h8a3 3 0 003-3v-7.071a1 1 0 00-.664-.942zm-2.668 1.391l1 .358a.5.5 0 01.331.47V18.5A1.5 1.5 0 0115.5 20h-7A1.5 1.5 0 017 18.5v-2.436a.5.5 0 01.24-.427l2-1.215a.5.5 0 01.521 0l3.039 1.865a.25.25 0 00.294-.023l2.084-1.794a.5.5 0 01.49-.092z\"/><path d=\"M16 0H8a3 3 0 00-3 3v8.01a1 1 0 001.512.859l2.657-1.584a.248.248 0 01.247-.006l3.084 1.663a1 1 0 001.176-.167l2.061-2.025a.25.25 0 01.255-.059l1.691.566A1 1 0 0019 9.309V3a3 3 0 00-3-3zm-3.023 9.562a.505.505 0 01-.238-.059L9.4 7.7a.25.25 0 00-.247 0l-1.4.836A.5.5 0 017 8.115V3a.964.964 0 01.266-.713A1.454 1.454 0 018.371 2h7.258a1.447 1.447 0 011.105.288A.964.964 0 0117 3v3.807a.5.5 0 01-.658.474.787.787 0 00-.529-.145 1.007 1.007 0 00-.426.257l-2.06 2.026a.5.5 0 01-.35.143zM3 10.5H1a1 1 0 000 2h2a1 1 0 000-2zM1.707 5.793A1 1 0 00.293 7.207l2 2a1 1 0 001.414-1.414zM2.293 13.793l-2 2a1 1 0 000 1.414 1.012 1.012 0 001.414 0l2-2a1 1 0 00-1.414-1.414zM23 10.5h-2a1 1 0 000 2h2a1 1 0 000-2zM21 9.5a1 1 0 00.707-.293l2-2a1 1 0 10-1.414-1.414l-2 2A1 1 0 0021 9.5zM21.707 13.793a1 1 0 00-1.414 1.414l1.205 1.2c.553.553 1.387 1.617 2.209.8a1 1 0 000-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});