define("ember-svg-jar/inlined/space-earth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>space-earth</title><circle cx=\"2.319\" cy=\"22\" r=\"2\"/><path d=\"M1.319 14.112v4.423a.5.5 0 00.572.5 2.977 2.977 0 01.857 0 .5.5 0 00.571-.5v-3.374a.248.248 0 01.083-.185.245.245 0 01.193-.063 4 4 0 003.915-2.058.5.5 0 00-.2-.678l-1.971-1.083a.5.5 0 01-.2-.678.378.378 0 01.276-.192 1.5 1.5 0 10-1.575-.865.373.373 0 01-.013.335.5.5 0 01-.679.2L1.175 8.81a.5.5 0 00-.679.2 4.009 4.009 0 00.677 4.752.5.5 0 01.146.35z\"/><circle cx=\"15.319\" cy=\"22\" r=\"2\"/><path d=\"M18 0a6 6 0 106 6 6 6 0 00-6-6zm2.62 8.778a.5.5 0 01-.2.4 3.993 3.993 0 01-4.5.238.5.5 0 01-.239-.428V7.246a1.754 1.754 0 00-1.219-1.668.5.5 0 01-.331-.6A4 4 0 0120 2.536a.249.249 0 01-.11.464 1.676 1.676 0 00-1.771 1.746V6a1.751 1.751 0 001.749 1.75.741.741 0 01.75.749zM14.886 19.032a.5.5 0 00.425-.432l.325-2.6a1.781 1.781 0 00-3.513-.569L11.49 18.6a.5.5 0 01-.49.4H9.319a1.5 1.5 0 00-1.5 1.5.5.5 0 01-.5.5H5.784a.5.5 0 00-.5.572 3.068 3.068 0 010 .856.5.5 0 00.5.572h6.071a.5.5 0 00.495-.572 3 3 0 012.536-3.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});