define("ember-svg-jar/inlined/medical-personnel-nurse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-personnel-nurse</title><path d=\"M.5 6.75H3a.25.25 0 01.25.25v2.5a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5V7A.25.25 0 017 6.75h2.5a.5.5 0 00.5-.5v-2.5a.5.5 0 00-.5-.5H7A.25.25 0 016.75 3V.5a.5.5 0 00-.5-.5h-2.5a.5.5 0 00-.5.5V3a.25.25 0 01-.25.25H.5a.5.5 0 00-.5.5v2.5a.5.5 0 00.5.5zM24 23a1 1 0 00-1-1h-1.5v-3.254a3.5 3.5 0 00-3.5-3.5h-.5a.5.5 0 00-.46.3l-.81 1.893a.251.251 0 01-.46 0l-.81-1.893a.5.5 0 00-.46-.3H14a3.5 3.5 0 00-3.5 3.5V22H1a1 1 0 000 2h22a1 1 0 001-1zM13.115 11.718a.991.991 0 00.262.31 2.733 2.733 0 005.25-.011 1 1 0 00.258-.3l1.829-3.432a1.5 1.5 0 00-.293-1.8 6.5 6.5 0 00-8.842 0 1.5 1.5 0 00-.292 1.8zm5.467-4.153a.25.25 0 01.078.323L18.065 9a.249.249 0 01-.169.127.254.254 0 01-.206-.048 2.73 2.73 0 00-3.38 0A.25.25 0 0113.935 9l-.6-1.115a.25.25 0 01.078-.323 4.627 4.627 0 015.169.003z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});