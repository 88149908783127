define("ember-svg-jar/inlined/optimization-rocket-growth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>optimization-rocket-growth</title><path d=\"M22.266 2.9a.251.251 0 01.354 0 .75.75 0 001.06-1.061L22.266.428a.75.75 0 10-1.06 1.061.25.25 0 010 .353L20.1 2.949a.251.251 0 01-.221.07 1.5 1.5 0 00-1.324.415l-1.71 1.709a.252.252 0 01-.261.059 1.5 1.5 0 00-.5-.087 1.486 1.486 0 00-1.06.44L10.776 9.8a1.5 1.5 0 000 2.121l1.414 1.415a1.5 1.5 0 002.121 0l.637-.637a.25.25 0 01.395.054 4.526 4.526 0 01-.739 5.391 4.5 4.5 0 01-6.363 0 .09.09 0 01.064-.154h.4a.75.75 0 000-1.5H3.6a.75.75 0 000 1.5h.8a.25.25 0 01.227.144 7.527 7.527 0 003.112 3.36.25.25 0 01-.123.467H6.15a1 1 0 100 2h11a1 1 0 000-2h-1.918a.25.25 0 01-.123-.467 7.5 7.5 0 002.342-11 .25.25 0 01.024-.326l1.079-1.078a1.5 1.5 0 00.352-1.564.25.25 0 01.059-.26l1.71-1.711a1.493 1.493 0 00.44-1.061 1.459 1.459 0 00-.024-.265.249.249 0 01.07-.22zM.393 8.249a1 1 0 001.414 0L4.966 5.09a.251.251 0 01.354 0l1.159 1.16a2 2 0 002.828 0l2.47-2.47a.25.25 0 01.353 0l1.117 1.12a.5.5 0 00.353.146.494.494 0 00.192-.046.5.5 0 00.308-.462v-4a.5.5 0 00-.5-.5h-4A.5.5 0 009.247.9l1.116 1.116a.25.25 0 010 .353L8.07 4.658a.25.25 0 01-.354 0L6.557 3.5a2 2 0 00-2.828 0L.393 6.835a1 1 0 000 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});