define("ember-svg-jar/inlined/delete-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>delete-1</title><path d=\"M20.485 3.511A12.01 12.01 0 1024 12a12.009 12.009 0 00-3.515-8.489zm-6.536 8.306l5.195-5.2a.256.256 0 01.387.029 9.209 9.209 0 01.006 10.7.257.257 0 01-.388.029l-5.2-5.2a.249.249 0 010-.358zm3.426-6.964l-5.193 5.2a.249.249 0 01-.353 0l-5.2-5.2a.258.258 0 01.03-.389 9.212 9.212 0 0110.684 0 .258.258 0 01.032.389zM4.86 6.613l5.2 5.2a.25.25 0 010 .353L4.855 17.38a.258.258 0 01-.388-.03 9.21 9.21 0 01.006-10.708.256.256 0 01.387-.029zm1.763 12.533l5.206-5.208a.249.249 0 01.353 0l5.2 5.2a.257.257 0 01-.03.388 9.249 9.249 0 01-10.7 0 .258.258 0 01-.029-.38z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});