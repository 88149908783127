define("ember-svg-jar/inlined/light-bulb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-bulb</title><path d=\"M12 0a8.994 8.994 0 00-4.338 16.876 1 1 0 00.483.124h7.71a1 1 0 00.483-.124A8.994 8.994 0 0012 0zm3.713 14.924a.5.5 0 01-.265.076H13.5a.5.5 0 01-.5-.5v-2.479a.251.251 0 01.292-.247 1.209 1.209 0 00.208.018 1.5 1.5 0 001.061-.438l1.146-1.147a1 1 0 00-1.414-1.414l-.616.616a.25.25 0 01-.354 0l-.616-.616a1 1 0 00-1.414 0l-.616.616a.25.25 0 01-.354 0l-.616-.616a1 1 0 00-1.414 1.414l1.146 1.147a1.483 1.483 0 001.274.413.25.25 0 01.287.247V14.5a.5.5 0 01-.5.5H8.552a.5.5 0 01-.265-.076 7 7 0 117.426 0zM15 19a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5v1.5a1.5 1.5 0 001.5 1.5.5.5 0 01.5.5v.5a1 1 0 002 0v-.5a.5.5 0 01.5-.5 1.5 1.5 0 001.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});