define("ember-svg-jar/inlined/real-estate-action-house-settings-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-house-settings-1</title><path d=\"M23.721 11.8L12.724.3a1.038 1.038 0 00-1.446 0L.28 11.8A1 1 0 001 13.492h1.5a.5.5 0 01.5.5v8.5a1.5 1.5 0 001.5 1.5h15a1.5 1.5 0 001.5-1.5v-8.5a.5.5 0 01.5-.5H23a1 1 0 00.721-1.692zM18 18.012a1.749 1.749 0 01-1.935.842l-1.412-.327a.452.452 0 00-.533.31l-.42 1.392a1.775 1.775 0 01-3.4 0l-.424-1.392a.454.454 0 00-.533-.31l-1.411.327a1.783 1.783 0 01-1.7-2.953l.986-1.065a.462.462 0 000-.625l-.988-1.065a1.783 1.783 0 011.7-2.953l1.412.327a.455.455 0 00.533-.311l.425-1.391a1.774 1.774 0 013.4 0l.424 1.391a.454.454 0 00.533.311l1.411-.327a1.783 1.783 0 011.7 2.953l-.986 1.065a.462.462 0 000 .625l.988 1.065a1.755 1.755 0 01.23 2.111z\"/><path d=\"M12 11.524a3 3 0 103 3 3 3 0 00-3-3zm0 4.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});