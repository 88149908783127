define("ember-svg-jar/inlined/vectors-pen-subtract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vectors-pen-subtract</title><path d=\"M10.743 15.507a7.159 7.159 0 01-1.809-3.043.251.251 0 00-.39-.129L4.4 15.443a1 1 0 00-.107 1.507L9.3 21.957a1 1 0 001.507-.107l3.111-4.15a.249.249 0 00-.129-.389 7.147 7.147 0 01-3.046-1.804zM23.945 4.255A1.589 1.589 0 0022 2.305c-3.142.845-8.641 2.495-10.2 4.051a5.721 5.721 0 008.09 8.091c1.56-1.556 3.21-7.054 4.055-10.192zM15.622 12.4a1.771 1.771 0 111.77-1.771 1.771 1.771 0 01-1.77 1.771zM9.5 3a1.25 1.25 0 00-1.25-1.25h-7a1.25 1.25 0 000 2.5h7A1.25 1.25 0 009.5 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});