define("ember-svg-jar/inlined/transform-inside", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transform-inside</title><path d=\"M14.5 2H16a1 1 0 000-2h-1.5a1 1 0 000 2zM4 1H1a1 1 0 000 2h3a17.019 17.019 0 0117 17v3a1 1 0 002 0v-3A19.021 19.021 0 004 1z\"/><path d=\"M12.116 10.116L5.9 16.336a.25.25 0 01-.354 0l-1.689-1.69A.5.5 0 003 15v5.5a.5.5 0 00.5.5H9a.5.5 0 00.354-.854l-1.69-1.689a.25.25 0 010-.354l6.22-6.219a1.25 1.25 0 00-1.768-1.768zM23 7a1 1 0 00-1 1v1.5a1 1 0 002 0V8a1 1 0 00-1-1zM22 0h-1.5a1 1 0 000 2h1.25a.25.25 0 01.25.25V3.5a1 1 0 002 0V2a2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});