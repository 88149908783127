define("ember-svg-jar/inlined/arrow-thick-up-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-up-4</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-4.662 12.952a.5.5 0 01-.853.354l-2.724-2.721a.25.25 0 00-.427.176v7.9a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-7.9a.25.25 0 00-.426-.177L7.692 13.3a.5.5 0 01-.854-.354V9.879A.5.5 0 017 9.509l5-4.545a.5.5 0 01.673 0l5 4.545a.5.5 0 01.164.37z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});