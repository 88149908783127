define("ember-svg-jar/inlined/easter-egg-bunny", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>easter-egg-bunny</title><path d=\"M18.515 12.2a.5.5 0 00-.395 0 52.006 52.006 0 01-11.212 3.234.5.5 0 00-.408.466 10.5 10.5 0 00.431 3.757.5.5 0 00.559.338 50.975 50.975 0 0011.684-3.4.5.5 0 00.3-.476 11.9 11.9 0 00-.685-3.624.5.5 0 00-.274-.295zm-9.287 6.051a1 1 0 111-1 1 1 0 01-1 1zm4-1a1 1 0 111-1 1 1 0 01-1 1zM16.978 16a1 1 0 111-1 1 1 0 01-1 1zM19.048 17.731a52.131 52.131 0 01-11.177 3.2.249.249 0 00-.16.382A6.057 6.057 0 0012.978 24c3.231 0 5.911-1.964 6.415-6.007a.249.249 0 00-.1-.23.252.252 0 00-.245-.032zM18.7.067c-.678-.195-1.741-.058-2.943 1.7a14.963 14.963 0 00-1.868 4.203c-.112.388-.211.784-.3 1.179a.451.451 0 01-.455.355.519.519 0 01-.484-.386C11.284 1.865 9.224.7 7.722.665 5.866.616 4.449 2.308 3.964 3.9c-.706 2.323 0 4.526 1.61 5.016a2.547 2.547 0 001.326.04 15.609 15.609 0 001.023 1.911 10.679 10.679 0 00-1.2 3.236.251.251 0 00.067.224.247.247 0 00.223.071 50.466 50.466 0 0010.935-3.211 1 1 0 00.491-.456 16.98 16.98 0 001.219-3.109C20.615 4.272 20.69.636 18.7.067zm-8.213 8.125a5.982 5.982 0 00-.79.549.5.5 0 01-.765-.167c-.06-.121-.118-.243-.175-.368a.491.491 0 01.076-.528 1 1 0 00-1.506-1.316c-.279.322-.763.765-1.171.642-.337-.1-.7-1.137-.278-2.52.312-1.027 1.108-1.82 1.791-1.82.865.022 2.11 1.346 3.052 4.97a.5.5 0 01-.234.558zm7.247-1.121a16.363 16.363 0 01-.545 1.594.5.5 0 01-.791.191 6.17 6.17 0 00-.686-.515.5.5 0 01-.218-.519c.06-.3.161-.758.316-1.3a11.137 11.137 0 011.99-4.14.251.251 0 01.434.124 11.136 11.136 0 01-.5 4.565z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});