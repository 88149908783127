define("ember-svg-jar/inlined/legal-scale-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>legal-scale-1</title><path d=\"M24 13.086a.991.991 0 00-.086-.387l-2.747-6.181a.251.251 0 01.149-.339A1 1 0 0021 4.23h-7.75a.25.25 0 01-.25-.25V1.75a1 1 0 00-2 0v2.23a.25.25 0 01-.25.25H3a1 1 0 00-.316 1.949.249.249 0 01.149.339L.087 12.7a.991.991 0 00-.087.386v.019a4.5 4.5 0 009 0 1.144 1.144 0 00-.1-.405L6.192 6.581a.25.25 0 01.228-.351h4.33a.25.25 0 01.25.25V21a.25.25 0 01-.25.25H9.081a1 1 0 000 2h5.811a1 1 0 000-2H13.25A.25.25 0 0113 21V6.48a.25.25 0 01.25-.25h4.33a.25.25 0 01.228.351L15.1 12.7a1.144 1.144 0 00-.1.405 4.5 4.5 0 109 0zm-21.187-.731A.25.25 0 012.585 12l1.684-3.789a.25.25 0 01.457 0L6.406 12a.25.25 0 01-.228.351zm15.009 0a.25.25 0 01-.228-.355l1.68-3.792a.25.25 0 01.457 0L21.415 12a.25.25 0 01-.228.351z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});