define("ember-svg-jar/inlined/team-meeting-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>team-meeting-chat</title><path d=\"M10.652 13.746a.984.984 0 00.613.214.994.994 0 00.425-.1.981.981 0 00.555-.884v-.773a.218.218 0 01.2-.217 1.964 1.964 0 001.764-1.952V2.2A1.963 1.963 0 0012.245.235H1.951A1.963 1.963 0 00-.01 2.2v7.842A1.963 1.963 0 001.951 12h6.431a.256.256 0 01.157.055zm-8.7-11.3A.25.25 0 012.2 2.2H12a.25.25 0 01.25.25v7.338a.249.249 0 01-.25.25h-.73a.982.982 0 00-.893.574.251.251 0 01-.383.092l-.563-.45a.974.974 0 00-.612-.216H2.2a.249.249 0 01-.25-.25z\"/><path d=\"M3.912 4.892h6.372a.736.736 0 100-1.471H3.912a.736.736 0 100 1.471zM7.343 6.362H3.912a.736.736 0 100 1.471h3.431a.736.736 0 000-1.471zM11.5 21.509a.248.248 0 00-.076-.252 4.693 4.693 0 00-.723-.522.249.249 0 01-.034-.407A3.69 3.69 0 106 20.362a.25.25 0 01-.028.408 4.668 4.668 0 00-1.948 2.33.49.49 0 00.458.664h6.54a.247.247 0 00.181-.078.251.251 0 00.069-.186c0-.075-.007-.15-.007-.226a6.845 6.845 0 01.235-1.765zm-4.858-4.448a.248.248 0 01.145-.164.25.25 0 01.22.013 4.287 4.287 0 002.941.519.068.068 0 01.058.016.07.07 0 01.026.054 1.725 1.725 0 01-3.45 0 1.625 1.625 0 01.06-.438zM15.779 17.793a.251.251 0 01-.029.428 5.679 5.679 0 00-3.1 5.053.49.49 0 00.49.49h10.38a.49.49 0 00.49-.49 5.679 5.679 0 00-3.174-5.09.249.249 0 01-.034-.428 4.144 4.144 0 00.6-.514 4.358 4.358 0 10-6.357-.094 4.254 4.254 0 00.734.645zm3.289 3.566a.736.736 0 01-1.471 0v-1.208a.736.736 0 011.471 0zm-3.2-7.115a2.294 2.294 0 01.13-.758.251.251 0 01.372-.128 5.523 5.523 0 004.136.768.12.12 0 01.144.118 2.424 2.424 0 01-2.392 2.391 2.358 2.358 0 01-.9-.179 2.391 2.391 0 01-1.487-2.212z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});