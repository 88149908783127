define("ember-svg-jar/inlined/arrow-double-left-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-double-left-1</title><path d=\"M6.685 12.174a.251.251 0 010-.35L10.741 7.7a1 1 0 00-.712-1.7H6.02a1 1 0 00-.712.3l-4.921 5a1 1 0 000 1.4l4.92 5a1 1 0 00.713.3h4.009a1 1 0 00.712-1.7z\"/><path d=\"M13.672 12.174a.249.249 0 010-.35L17.73 7.7a1 1 0 00-.713-1.7H15.49a1 1 0 00-.711.3l-4.921 5a1 1 0 000 1.4l4.92 5a1 1 0 00.712.3h1.527a1 1 0 00.713-1.7z\"/><path d=\"M19.56 12.175a.25.25 0 010-.35L23.612 7.7a1 1 0 10-1.426-1.4l-4.57 4.651a1.509 1.509 0 000 2.1l4.57 4.649a1 1 0 101.426-1.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});