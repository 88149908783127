define("ember-svg-jar/inlined/rollerblades-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rollerblades-person</title><circle cx=\"18.875\" cy=\"2.875\" r=\"2.5\"/><path d=\"M23.375 10.375a1.5 1.5 0 00-1.5-1.5h-2.646a1.5 1.5 0 01-1.341-.829l-.4-.8a3.923 3.923 0 00-3.506-2.168 3.971 3.971 0 00-.52.034l-5.7.761A1.557 1.557 0 006.388 7.16a1.5 1.5 0 001.483 1.715 1.533 1.533 0 00.2-.013l3.545-.473a.25.25 0 01.242.384L9 13.19a1.5 1.5 0 01-1.259.685h-.616a1.5 1.5 0 00-1.5 1.5 1.5 1.5 0 001.5 1.5h.619a4.5 4.5 0 003.777-2.055l.259-.4a.25.25 0 01.378-.049c.4.361.883.819 1.483 1.4a.49.49 0 01.146.4l-.1.809a1.5 1.5 0 001.487 1.683 1.5 1.5 0 001.486-1.31l.112-.884a3.473 3.473 0 00-1.039-2.853 55.68 55.68 0 00-1.984-1.857.251.251 0 01-.044-.323l1.337-2.065a.092.092 0 01.084-.043.1.1 0 01.079.052 4.5 4.5 0 004.024 2.487h2.646a1.5 1.5 0 001.5-1.492zM4.875 12.375a1 1 0 00-2 0v6a1 1 0 002 0v-6zM11.875 19.375a1 1 0 000 2h6a1 1 0 000-2h-6z\"/><circle cx=\"1.625\" cy=\"12.625\" r=\"1\"/><circle cx=\"1.625\" cy=\"15.375\" r=\"1\"/><circle cx=\"1.625\" cy=\"18.125\" r=\"1\"/><circle cx=\"17.625\" cy=\"22.625\" r=\"1\"/><circle cx=\"14.875\" cy=\"22.625\" r=\"1\"/><circle cx=\"12.125\" cy=\"22.625\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});