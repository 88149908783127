define("ember-svg-jar/inlined/arrow-button-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-right</title><path d=\"M7.207 23.751l12.471-10.984a1.066 1.066 0 000-1.539L7.207.249a.987.987 0 00-1.414.107l-1.557 1.85a1.028 1.028 0 00.111 1.438l9.453 8.165a.25.25 0 010 .379l-9.45 8.168a1.021 1.021 0 00-.349.7 1 1 0 00.238.741l1.558 1.851a.989.989 0 001.414.107z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});