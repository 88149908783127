define("ember-svg-jar/inlined/gesture-swipe-horizontal-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-horizontal-right</title><path d=\"M23.378 7.452a.75.75 0 00-1.35-.313l-.5.695a.249.249 0 01-.312.08 16.867 16.867 0 00-3.6-1.229 1 1 0 10-.443 1.951 15.13 15.13 0 012.767.9.251.251 0 01.1.374l-.413.577a.751.751 0 00.734 1.176l2.869-.478a.75.75 0 00.617-.863zM6.616 6.684A12.341 12.341 0 00.4 9.742a1 1 0 001.461 1.366 10.577 10.577 0 015.197-2.473 1 1 0 00-.442-1.951zM12.261 4a3.5 3.5 0 00-3.5 3.5v12a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-12a3.5 3.5 0 00-3.5-3.5zm1.5 4.625a.375.375 0 01-.375.375h-2.25a.375.375 0 01-.375-.375V7.5a1.5 1.5 0 113 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});