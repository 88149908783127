define("ember-svg-jar/inlined/marine-mammal-dolphin-jump", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>marine-mammal-dolphin-jump</title><path d=\"M12.489 10.313c0-1.112-.211-3.355-.194-3.436a56.855 56.855 0 00-.613-.155c.045-.138.1-.29.151-.44.382-1.088.591-1.782.275-2.225a.718.718 0 00-.605-.3c-1 0-2.062 1.506-3.2 2.972a12.184 12.184 0 00-4.555 1.592 3.959 3.959 0 00-2.272 3.74c-1.6 1.559-1.5 2.1-1.46 2.279a1.507 1.507 0 00.734 1.1c1.359.729 3.327-.919 6.976-1.738.831.943 2.221 2.316 3.267 2.316.5 0 .18-1.581-.129-2.764.23-.015 1.2-.045 1.456-.043a24.456 24.456 0 00.169-2.898zM3.505 11.5a1 1 0 111 1 1 1 0 01-1-1z\"/><path d=\"M11.514 20.585a4.063 4.063 0 01-2.853 1.381 3.469 3.469 0 01-2.686-1.522 1.04 1.04 0 00-1.634-.089c-.015.02-1.507 1.874-3.193 1.627a1 1 0 00-1.126.855C.017 22.873-.174 24 1.44 24a5.713 5.713 0 003.636-1.555 5.16 5.16 0 003.585 1.519 5.772 5.772 0 003.728-1.453 4.51 4.51 0 003.344 1.453 5.074 5.074 0 003.543-1.446 4.627 4.627 0 003.86 1.4 1 1 0 10-.272-1.98c-1.618.224-2.732-1.483-2.741-1.495a1.047 1.047 0 00-1.713.02 3.279 3.279 0 01-2.677 1.5 2.72 2.72 0 01-2.333-1.421.957.957 0 00-.652-.5 4.341 4.341 0 00.791-.77 12.4 12.4 0 002.127-5.833c1.949.19 3.427.408 4 .5l1.888 4.017a.5.5 0 00.833.111 3.484 3.484 0 00.406-3.759A2.927 2.927 0 0024 11.745a.5.5 0 00-.62-.484l-2.723.67a22.277 22.277 0 00-4.868-3.674C15.392 3.959 13.758 0 11.045 0c-1.4 0-2.656 1.105-3.54 3.112a1 1 0 001.831.8C9.857 2.733 10.511 2 11.045 2c.83 0 1.928 1.663 2.484 4.474a19.883 19.883 0 01.355 3.841 17.925 17.925 0 01-.777 5.478c-.59 1.808-1.409 2.838-2.062 2.838-.528 0-1.179-.726-1.7-1.894a1 1 0 00-1.827.81 5.075 5.075 0 002.617 2.919 2.619 2.619 0 00.908.162 2.682 2.682 0 00.471-.043z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});