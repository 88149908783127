define("ember-svg-jar/inlined/responsive-design-laptop-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>responsive-design-laptop-1</title><path d=\"M19.5 20h-.75a.25.25 0 01-.25-.25V17.5a1 1 0 00-2 0v2.25a.25.25 0 01-.25.25H11.5a.489.489 0 00-.5.479 1 1 0 11-2 0A.489.489 0 008.5 20H3.75a.25.25 0 01-.25-.25V16a1 1 0 00-2 0v3.75a.25.25 0 01-.25.25H.5a.5.5 0 00-.5.5v1A2.5 2.5 0 002.5 24h15a2.5 2.5 0 002.5-2.5v-1a.5.5 0 00-.5-.5z\"/><path d=\"M5.5 15.5H11a2 2 0 002-2V2a2 2 0 00-2-2H5.5a2 2 0 00-2 2v11.5a2 2 0 002 2zM5.75 2h5a.25.25 0 01.25.25v11a.25.25 0 01-.25.25h-5a.25.25 0 01-.25-.25v-11A.25.25 0 015.75 2zM22 0h-6a2 2 0 00-2 2v11.5a2 2 0 002 2h6a2 2 0 002-2V2a2 2 0 00-2-2zm-.5 2a.5.5 0 01.5.5v8a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5zM18 13.5a.5.5 0 010-1h2a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});