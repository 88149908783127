define("ember-svg-jar/inlined/credit-card-flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-flag</title><path d=\"M20 2a2 2 0 00-2-2H2a2 2 0 00-2 2v1a.25.25 0 00.25.25h19.5A.25.25 0 0020 3zM13 13.619a2 2 0 011.358-1.894l1.269-.43a5.55 5.55 0 014.022.171.249.249 0 00.351-.229V5a.25.25 0 00-.25-.25H.25A.25.25 0 000 5v8a2 2 0 002 2h10.75a.249.249 0 00.25-.25zM6 12.25H3a.75.75 0 010-1.5h3a.75.75 0 010 1.5zm3.5-3.5H3a.75.75 0 010-1.5h6.5a.75.75 0 010 1.5z\"/><path d=\"M14.508 23.25a.75.75 0 001.5 0v-3.765a.25.25 0 01.17-.237l.251-.085a3.059 3.059 0 012.717.37 4.065 4.065 0 002.285.7 4.234 4.234 0 001.349-.223l.025-.01.92-.425a.5.5 0 00.291-.454v-5.5a.5.5 0 00-.709-.454l-.871.405-.026.01a3.049 3.049 0 01-2.7-.375 4.05 4.05 0 00-3.6-.489l-1.268.43a.5.5 0 00-.34.473z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});