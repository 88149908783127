define("ember-svg-jar/inlined/temperature-celsius-low", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>temperature-celsius-low</title><path d=\"M6.25 18.417H5a.25.25 0 01-.25-.25v-3.25a1.25 1.25 0 00-2.5 0v3.25a.25.25 0 01-.25.25H.75a.749.749 0 00-.6 1.2l2.75 3.669a.751.751 0 001.2 0l2.75-3.667a.75.75 0 00-.6-1.2zM15 .417a9 9 0 109 9 9 9 0 00-9-9zm-4 7a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm6 6h1.5a.75.75 0 010 1.5H17a4.75 4.75 0 010-9.5h1.5a.75.75 0 010 1.5H17a3.25 3.25 0 000 6.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});