define("ember-svg-jar/inlined/accessories-hand-fan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accessories-hand-fan</title><path d=\"M23.769 5.135a1 1 0 00-.235-1.482C15.859-1.218 8.1-1.217.463 3.654a1 1 0 00-.232 1.481l10.206 12.317a.5.5 0 010 .638L7.73 21.357A1 1 0 008 22.863 7.993 7.993 0 0012 24a7.993 7.993 0 004-1.137 1 1 0 00.273-1.506l-2.71-3.267a.5.5 0 010-.638zM11.25 1.974a.5.5 0 01.494-.5h.518a.5.5 0 01.488.5v7.3a.5.5 0 01-.513.5q-.237-.006-.474 0a.5.5 0 01-.513-.5zm-6.41 1.64a.5.5 0 01.247-.687l.479-.2a.5.5 0 01.629.238L9.5 9.448a.5.5 0 01-.315.711c-.152.041-.3.086-.454.135a.5.5 0 01-.6-.247zm8.585 17.445a.5.5 0 01-.277.807 5.237 5.237 0 01-2.3 0 .5.5 0 01-.276-.807l1.04-1.256a.5.5 0 01.77 0zm-1.04-5.32a.5.5 0 01-.77 0l-2.5-3.014a.5.5 0 01.233-.795 8.773 8.773 0 015.3 0 .5.5 0 01.328.336.5.5 0 01-.1.459zm3.484-5.692a.5.5 0 01-.6.247c-.15-.049-.3-.094-.455-.136a.5.5 0 01-.315-.71l3.3-6.472a.5.5 0 01.627-.239c.161.063.321.128.482.2a.5.5 0 01.251.688z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});