define("ember-svg-jar/inlined/shape-peg-top", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shape-peg-top</title><path d=\"M22.225 10.011L13.37.62A1.911 1.911 0 0010.6.608l-8.861 9.438a2.338 2.338 0 00-.065 2.874l8.907 10.31a1.669 1.669 0 002.736.07l9.058-10.448a2.333 2.333 0 00-.15-2.841zm-5.59 1.783a.25.25 0 01-.3-.164l-2.189-6.577a.25.25 0 01.419-.25l5.451 5.78a.25.25 0 01-.122.414zm-2.214 2.185a.25.25 0 01.232.343L12.23 20.38a.251.251 0 01-.465 0l-2.423-6.058a.251.251 0 01.233-.343zm-4.953-2a.249.249 0 01-.237-.329l2.529-7.587a.25.25 0 01.475 0l2.529 7.587a.249.249 0 01-.237.329zm-1.811-.349a.25.25 0 01-.3.164L4.1 11a.25.25 0 01-.18-.172.248.248 0 01.057-.242l5.47-5.828a.25.25 0 01.419.25zm-.223 2.241a.251.251 0 01.173.15l1.466 3.666a.25.25 0 01-.421.256L5.029 13.75a.25.25 0 01.249-.406zm8.955.15a.249.249 0 01.172-.15l2.142-.523a.249.249 0 01.248.406l-3.587 4.138a.25.25 0 01-.421-.256z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});