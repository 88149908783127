define("ember-svg-jar/inlined/server-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-heart</title><path d=\"M0 3.484a2.5 2.5 0 002.5 2.5h17a2.5 2.5 0 002.5-2.5v-1a2.5 2.5 0 00-2.5-2.5h-17a2.5 2.5 0 00-2.5 2.5zm3.635-.5A1.115 1.115 0 114.75 4.1a1.114 1.114 0 01-1.115-1.116zm3.865 0A1.115 1.115 0 118.614 4.1 1.114 1.114 0 017.5 2.984zM13.72 11.08a5.031 5.031 0 013.639.811.25.25 0 00.282 0 5.031 5.031 0 013.637-.811l.123.021a.5.5 0 00.592-.45c0-.055.007-.111.007-.167v-1a2.5 2.5 0 00-2.5-2.5h-17a2.5 2.5 0 00-2.5 2.5v1a2.5 2.5 0 002.5 2.5h7.818a.491.491 0 00.374-.179 5.023 5.023 0 013.028-1.725zm-3.991-1.1a1.115 1.115 0 11-1.115-1.111 1.115 1.115 0 011.115 1.115zm-3.865 0A1.115 1.115 0 114.75 8.869a1.114 1.114 0 011.114 1.115zM9.234 13.984H2.5a2.5 2.5 0 00-2.5 2.5v1a2.5 2.5 0 002.5 2.5h7.767a.5.5 0 00.389-.8 4.823 4.823 0 01-.947-4.556.494.494 0 00-.475-.644zm-5.6 3A1.115 1.115 0 114.75 18.1a1.114 1.114 0 01-1.115-1.116z\"/><path d=\"M21.038 12.561a3.475 3.475 0 00-3.056.988l-.306.3a.249.249 0 01-.353 0l-.3-.3a3.524 3.524 0 00-5.646.916 3.517 3.517 0 00.653 4.061l5.114 5.335a.5.5 0 00.721 0l5.107-5.327a3.522 3.522 0 00.661-4.069 3.487 3.487 0 00-2.595-1.904z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});