define("ember-svg-jar/inlined/real-estate-action-house-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-house-shield</title><path d=\"M9.5 16.848v-3.392a3.042 3.042 0 011.829-2.791A17.386 17.386 0 0117.478 9.5a17.438 17.438 0 016.306 1.219.052.052 0 00.06-.083L13.029.408a1.5 1.5 0 00-2.059 0L.156 10.636A.5.5 0 00.5 11.5H3V22a1 1 0 001 1h7.2a.5.5 0 00.381-.824A8.321 8.321 0 019.5 16.848z\"/><path d=\"M23.072 12.04A16.383 16.383 0 0017.478 11a15.963 15.963 0 00-5.55 1.04A1.544 1.544 0 0011 13.456v3.391c0 2.6 1.679 5.466 5.743 7.017a2.06 2.06 0 001.471 0C22.289 22.309 24 19.439 24 16.847v-3.391a1.544 1.544 0 00-.928-1.416zM20.5 17.25h-1.75a.5.5 0 00-.5.5v1.75a.75.75 0 01-1.5 0v-1.75a.5.5 0 00-.5-.5H14.5a.75.75 0 010-1.5h1.75a.5.5 0 00.5-.5V13.5a.75.75 0 011.5 0v1.75a.5.5 0 00.5.5h1.75a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});