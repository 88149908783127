define("ember-svg-jar/inlined/phone-action-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-share</title><path d=\"M11.476 11.189L13.3 10.1a1.993 1.993 0 10-.773-1.286L10.7 9.9a2 2 0 10.238 2.982l1.559.623a2 2 0 10.559-1.393l-1.556-.62a2.172 2.172 0 00-.024-.303z\"/><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});