define("ember-svg-jar/inlined/button-zigzag-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-zigzag-1</title><path d=\"M8.532 2a4.755 4.755 0 00-4.75 4.75V16a.25.25 0 01-.25.25H1.511a1 1 0 00-.706 1.709l3.521 3.5a1 1 0 001.411 0l3.52-3.5a1 1 0 00-.7-1.709H6.532a.249.249 0 01-.25-.25V6.749a2.25 2.25 0 114.5 0v10.5a4.75 4.75 0 109.5 0L20.246 8a.249.249 0 01.25-.251h1.994a1 1 0 00.711-1.7l-3.458-3.5a1 1 0 00-1.41-.013l-3.583 3.5a1 1 0 00.7 1.716h2.05a.249.249 0 01.25.249l.036 9.251a2.25 2.25 0 01-4.5 0V6.749A4.756 4.756 0 008.532 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});