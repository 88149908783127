define("ember-svg-jar/inlined/chef-gear-butter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chef-gear-butter</title><path d=\"M7 10.25a3 3 0 013-3h2.5a.5.5 0 00.5-.5v-1a1.5 1.5 0 00-1.5-1.5h-.75a.5.5 0 00-.5.5v.5a.75.75 0 01-1.5 0v-.5a.5.5 0 00-.5-.5h-1.5a.5.5 0 00-.5.5v1.5a.75.75 0 01-1.5 0v-1.5a.5.5 0 00-.5-.5H3.5A1.5 1.5 0 002 5.75v4a1.5 1.5 0 001.5 1.5h3a.5.5 0 00.5-.5z\"/><path d=\"M18 8.75h-.75a.5.5 0 00-.5.5v2.5a.75.75 0 01-1.5 0v-2.5a.5.5 0 00-.5-.5h-1.5a.5.5 0 00-.5.5v2.5a.75.75 0 01-1.5 0v-2.5a.5.5 0 00-.5-.5H10a1.5 1.5 0 00-1.5 1.5v4a1.5 1.5 0 001.5 1.5h8a1.5 1.5 0 001.5-1.5v-4a1.5 1.5 0 00-1.5-1.5z\"/><path d=\"M12 19.75c-5.817 0-12-1.928-12-5.5a3.05 3.05 0 01.4-1.485 1 1 0 011.75.969 1.055 1.055 0 00-.15.516c0 1.461 3.8 3.5 10 3.5s10-2.039 10-3.5c0-.609-.672-1.17-1.072-1.45a1 1 0 111.145-1.639A3.783 3.783 0 0124 14.25c0 3.572-6.183 5.5-12 5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});