define("ember-svg-jar/inlined/color-painting-palette", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-painting-palette</title><path d=\"M23.92 11.237A12.337 12.337 0 0014.935.868 9.789 9.789 0 009.159.84a12.483 12.483 0 00-8.8 8.906 11.918 11.918 0 003.159 11.385 2.487 2.487 0 003.081.358 1.5 1.5 0 00.256-2.334l-.809-.81a1 1 0 010-1.414 1.1 1.1 0 011.6-.189c1.41 1.409 5.62 5.656 5.622 5.657a3.985 3.985 0 002.815 1.183 3.751 3.751 0 001.928-.53 12.018 12.018 0 005.909-11.815zm-9.443 7.626a2.506 2.506 0 010-3.537 2.562 2.562 0 013.535 0 2.5 2.5 0 11-3.535 3.537zM14.245 7.2a2 2 0 012-2 2 2 0 11-2 2zm3.7 6.01a1.5 1.5 0 112.121 0 1.5 1.5 0 01-2.124-.005zm-6.296-8.49a2 2 0 11-2.827 0 2.048 2.048 0 012.827 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});