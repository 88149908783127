define("ember-svg-jar/inlined/composition-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>composition-layout</title><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2zm-2.5 0a.5.5 0 01.5.5v4.233a.25.25 0 01-.25.25H2.25a.25.25 0 01-.25-.25V2.5a.5.5 0 01.5-.5zM2.25 14.983a.25.25 0 01-.25-.25v-5.5a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25v5.5a.25.25 0 01-.25.25zM9 9.233a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25v5.5a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25zm8 0a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25v5.5a.25.25 0 01-.25.25h-4.5a.25.25 0 01-.25-.25zM2.5 22a.5.5 0 01-.5-.5v-4.267a.25.25 0 01.25-.25h19.5a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});