define("ember-svg-jar/inlined/button-loop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-loop</title><path d=\"M22 7.734a6.042 6.042 0 00-6.849-1.186 5.938 5.938 0 00-2.292 2.029l-3.771 5.414a3.482 3.482 0 01-1.313 1.2 3.533 3.533 0 01-4.01-.7 3.6 3.6 0 010-4.994 3.535 3.535 0 014.994 0 1.25 1.25 0 101.768-1.768A6.039 6.039 0 002 7.734a6.1 6.1 0 000 8.53 6.043 6.043 0 006.847 1.186 5.938 5.938 0 002.292-2.029l3.773-5.414a3.482 3.482 0 011.313-1.2 3.537 3.537 0 014.01.7 3.6 3.6 0 010 4.994 3.535 3.535 0 01-4.994 0 1.25 1.25 0 10-1.768 1.768 6.039 6.039 0 008.529 0 6.1 6.1 0 000-8.53z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});