define("ember-svg-jar/inlined/wolf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wolf</title><path d=\"M22.692 10.907A3.041 3.041 0 0120.119 8.9a.5.5 0 01.289-.6c1.569-.653 3.354-2.874 3.571-6.147a1.5 1.5 0 00-.768-1.41A5.324 5.324 0 0020.5 0a6.514 6.514 0 00-5.874 3.714.5.5 0 01-.452.286H9.826a.5.5 0 01-.452-.286A6.514 6.514 0 003.5 0 5.327 5.327 0 00.79.743a1.506 1.506 0 00-.769 1.411c.217 3.273 2 5.494 3.571 6.147a.5.5 0 01.289.6 3.044 3.044 0 01-2.573 2.007A1.5 1.5 0 00.13 12.7c.605 2.79 3.224 5.254 6.87 6.489a5 5 0 009.994 0c3.649-1.235 6.268-3.7 6.873-6.491a1.5 1.5 0 00-1.178-1.791zM15.5 13.25A1.25 1.25 0 1116.75 12a1.25 1.25 0 01-1.25 1.25zM7.25 12a1.25 1.25 0 111.25 1.25A1.25 1.25 0 017.25 12zm3.629 7h2.242a.5.5 0 01.5.5c0 .69-.339 2-1.621 2s-1.621-1.3-1.621-2a.5.5 0 01.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});