define("ember-svg-jar/inlined/gorilla", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gorilla</title><path d=\"M12 0C6.441 0 1.75 8.56 1.75 14.25 1.75 19.9 6.061 24 12 24s10.25-4.1 10.25-9.75C22.25 8.56 17.559 0 12 0zM9.5 6.25a2.852 2.852 0 011.95.941c.187.17.442.4.55.4s.363-.231.55-.4a2.852 2.852 0 011.95-.941 4 4 0 012.474 7.15 5.467 5.467 0 01.526 2.35c0 .122 0 .242-.012.361a30.286 30.286 0 01-10.976 0 5.373 5.373 0 01-.012-.361 5.467 5.467 0 01.526-2.35A4 4 0 019.5 6.25zm2.5 15a5.506 5.506 0 01-4.9-3.011 32.3 32.3 0 004.9.374 32.305 32.305 0 004.9-.374A5.506 5.506 0 0112 21.25z\"/><circle cx=\"9.5\" cy=\"10.25\" r=\"1.25\"/><circle cx=\"14.5\" cy=\"10.25\" r=\"1.25\"/><path d=\"M9.668 13.2a1 1 0 00.277 1.387 3.7 3.7 0 004.11 0 1 1 0 10-1.11-1.664 1.7 1.7 0 01-1.89 0 1 1 0 00-1.387.277z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});