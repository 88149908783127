define("ember-svg-jar/inlined/family-outdoors-tree-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-outdoors-tree-house</title><path d=\"M13.459 15.487a4.349 4.349 0 01-1.129-.241.5.5 0 01-.33-.471V8.75a1 1 0 00-2 0v1.723a.251.251 0 01-.429.175A1.993 1.993 0 019 9.25a1 1 0 00-2 0 4.008 4.008 0 002.667 3.772.5.5 0 01.333.471v1.282a.5.5 0 01-.33.47 4.38 4.38 0 01-1.47.255h-.5a3.241 3.241 0 01-3.077-1.664 1.708 1.708 0 01-.188-.662 1 1 0 00-.674-.869A2.327 2.327 0 012 10.25a2.319 2.319 0 011.715-2.039 1 1 0 00.664-.93C4.4 5.55 6.271 5.5 6.484 5.5a1 1 0 00.635-.227l.305-.25a1 1 0 00.365-.773C7.789 3.009 9.117 2 10.75 2a2.8 2.8 0 012.95 2.031 1 1 0 001.985-.256A4.743 4.743 0 0010.75 0 4.937 4.937 0 006 3.013a1 1 0 01-.694.635A3.75 3.75 0 002.529 6.2a1 1 0 01-.443.579A4.09 4.09 0 000 10.25a4.1 4.1 0 002.205 3.54.994.994 0 01.443.519 4.693 4.693 0 00.991 1.564A5.389 5.389 0 007.7 17.5h.5a6.453 6.453 0 001.2-.115.5.5 0 01.594.491V23a1 1 0 002 0v-5.123a.5.5 0 01.594-.491 6.677 6.677 0 00.711.1 1 1 0 10.154-1.994z\"/><path d=\"M23.412 7.238l-3.5-3.11a1.751 1.751 0 00-2.324 0l-3.5 3.11a1.755 1.755 0 00-.588 1.309V13.1a1.752 1.752 0 001.578 1.741.468.468 0 01.422.467V23a1 1 0 002 0v-.5a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5v.5a1 1 0 002 0v-7.694a.468.468 0 01.422-.467A1.752 1.752 0 0024 13.1V8.547a1.755 1.755 0 00-.588-1.309zM20 19.75a.5.5 0 01-.5.5H18a.5.5 0 01-.5-.5V19a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5zM20 16a.5.5 0 01-.5.5H18a.5.5 0 01-.5-.5v-.652a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5zm.424-3.25a.5.5 0 01-.5.5h-2.348a.5.5 0 01-.5-.5v-2.174a1.674 1.674 0 113.348 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});