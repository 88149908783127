define("ember-svg-jar/inlined/gesture-swipe-vertical-up-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-vertical-up-3</title><path d=\"M18.343 12.64a1 1 0 00-1-1h-7.064a.25.25 0 01-.142-.455c2.138-1.49 1.449-3.856-.224-2.762l-4.367 2.912a.5.5 0 00-.223.416v5.435a.5.5 0 00.411.492c1.1.2 3.683.665 4.165.776a1.283 1.283 0 001.52-.894l1.117-3.741a.25.25 0 01.239-.179h4.568a1 1 0 001-1zM13.433 2.993a1.751 1.751 0 012.214-1.107l1.192.4a.25.25 0 00.3-.116.747.747 0 00-.42-1.07L13.883.152a.747.747 0 00-.948.474L11.99 3.46a.749.749 0 00.22.8.694.694 0 00.612.167.247.247 0 00.187-.166zM16.876 15.12a1 1 0 00-1.135.845 10.286 10.286 0 01-2.728 6.19 1 1 0 101.366 1.461c1.742-1.63 2.866-4.107 3.341-7.362a1 1 0 00-.844-1.134z\"/><path d=\"M17.108 5.968a.247.247 0 01.128-.292l1.027-.514a.75.75 0 00-.1-1.382l-2.834-.945a.749.749 0 00-.949.474l-.944 2.834a.751.751 0 001.046.909l.469-.235a.25.25 0 01.354.161q.088.342.167.7c.112.508.214 1.029.293 1.57a1.011 1.011 0 001.135.844 1 1 0 00.844-1.135 23.869 23.869 0 00-.636-2.989z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});