define("ember-svg-jar/inlined/hat-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hat-1</title><path d=\"M5.406 14.663l6.061 1.675a1.985 1.985 0 001.065 0l6.062-1.675a.5.5 0 00.365-.527l-.107-1.181a.5.5 0 00-.5-.455H5.646a.5.5 0 00-.5.455l-.107 1.181a.5.5 0 00.367.527zM5.918 10.5h12.164a.5.5 0 00.5-.545l-.506-5.58A1.508 1.508 0 0016.58 3H7.42a1.507 1.507 0 00-1.494 1.372L5.42 9.955a.5.5 0 00.5.545z\"/><path d=\"M23.3 15.8a1.958 1.958 0 00-1.785-.389l-8.578 2.37a3.49 3.49 0 01-1.864 0L2.49 15.414a1.975 1.975 0 10-.851 3.852l9.59 1.668a4.536 4.536 0 001.542 0l9.59-1.668A1.978 1.978 0 0023.3 15.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});