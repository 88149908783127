define("ember-svg-jar/inlined/shipment-sign-smartphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-sign-smartphone</title><path d=\"M13.952 7.6H16.2a.25.25 0 00.25-.25v-3.1a.251.251 0 00-.349-.229l-7 3.014a.252.252 0 00-.151.23v8.128a.249.249 0 00.367.221l1.5-.795a.251.251 0 00.133-.221V10.6a3 3 0 013.002-3zM.794 4.276a.25.25 0 00-.341.232v7.441a1 1 0 00.6.916L7.6 15.731a.25.25 0 00.35-.229V7.279a.25.25 0 00-.158-.233zM15.179 3.328a.25.25 0 00-.015-.465L8.339.454a1 1 0 00-.755.037h-.007L1.628 3.072a.251.251 0 00.007.462l6.616 2.618a.5.5 0 00.382 0z\"/><path d=\"M17.26 10.6a1 1 0 000-2H14.2a2.153 2.153 0 00-1.529.646 2.537 2.537 0 00-.721 1.789l.007 10.418a2.2 2.2 0 002.25 2.147H20.7a2.2 2.2 0 002.25-2.147c0-1.922-.008-.008 0-3.851a1 1 0 00-1-1 1 1 0 00-1 1v1.21a.5.5 0 01-.5.5h-5.992a.5.5 0 01-.5-.5l-.005-7.712a.5.5 0 01.5-.5z\"/><path d=\"M15.458 16.828a.312.312 0 00.306.372c.047 0-.075.021 1.512-.3a.249.249 0 00.128-.421l-1.233-1.234a.249.249 0 00-.249-.062.247.247 0 00-.173.19zM20.764 9.579l-3.894 3.894a.251.251 0 000 .354l1.956 1.955a.25.25 0 00.353 0l3.894-3.894a1.633 1.633 0 00-2.309-2.309z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});