define("ember-svg-jar/inlined/stairs-ascend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>stairs-ascend</title><path d=\"M24 7a1 1 0 00-1-1h-6.5A1.5 1.5 0 0015 7.5v2a.5.5 0 01-.5.5h-2a1.5 1.5 0 00-1.5 1.5v2a.5.5 0 01-.5.5h-2A1.5 1.5 0 007 15.5v2a.5.5 0 01-.5.5h-2A1.5 1.5 0 003 19.5V23a1 1 0 001 1h19a1 1 0 001-1zM11.9 5.854a.5.5 0 00.85-.354v-5a.5.5 0 00-.5-.5h-5a.5.5 0 00-.35.854l1.082 1.085a.5.5 0 010 .708L.439 10.189a1.5 1.5 0 002.122 2.122L10.1 4.768a.5.5 0 01.708 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});