define("ember-svg-jar/inlined/casino-chips-double-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>casino-chips-double-1</title><path d=\"M15.435 20.677a.25.25 0 010-.354l.616-.616a1 1 0 00-1.414-1.414l-.616.616a.25.25 0 01-.354 0l-.616-.616a1 1 0 00-1.414 1.414l.616.616a.25.25 0 010 .354l-.616.616a1 1 0 101.414 1.414l.616-.616a.25.25 0 01.354 0l.616.616a1 1 0 001.414-1.414zM24 23a1 1 0 01-1 1h-4.813a1 1 0 01-.661-1.75l3.143-2.772c.85-.749.991-.93.87-1.529a1.188 1.188 0 00-2.352.239 1 1 0 01-2 0 3.188 3.188 0 016.312-.637c.361 1.781-.632 2.656-1.508 3.428l-.662.583a.25.25 0 00.165.438H23a1 1 0 011 1zM.4 5.724a.25.25 0 00-.395.2v2.129a.5.5 0 00.115.32C.948 9.374 3.136 10.25 6 10.25s5.052-.876 5.885-1.877a.5.5 0 00.115-.32V5.928a.25.25 0 00-.395-.2A9.858 9.858 0 016 7.25 9.858 9.858 0 01.4 5.724zM6 14.75c2.864 0 5.052-.876 5.885-1.877a.5.5 0 00.115-.32v-1.886a.251.251 0 00-.125-.216.247.247 0 00-.249 0A11.544 11.544 0 016 11.75a11.544 11.544 0 01-5.626-1.3.25.25 0 00-.374.217v1.886a.5.5 0 00.115.32C.948 13.874 3.136 14.75 6 14.75zM6 16.25a11.544 11.544 0 01-5.626-1.3.25.25 0 00-.374.217V16c0 1.71 2.58 3 6 3s6-1.29 6-3v-.833a.251.251 0 00-.125-.216.247.247 0 00-.249 0A11.544 11.544 0 016 16.25z\"/><ellipse cx=\"6\" cy=\"3\" rx=\"6\" ry=\"3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});