define("ember-svg-jar/inlined/crime-wanted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-wanted</title><path d=\"M22 0H2a2 2 0 00-2 2v20a2 2 0 002 2h6a1 1 0 000-2H2.25a.25.25 0 01-.25-.25V2.25A.25.25 0 012.25 2h19.5a.25.25 0 01.25.25v19.5a.25.25 0 01-.25.25H16a1 1 0 000 2h6a2 2 0 002-2V2a2 2 0 00-2-2z\"/><path d=\"M11.75 17.5h2a.75.75 0 000-1.5h-.5a.25.25 0 01-.25-.25.75.75 0 00-1.5 0v.071a.249.249 0 01-.187.242A1.745 1.745 0 0010 17.75c0 1.213 1.106 1.767 1.915 2.171.635.318 1.085.563 1.085.829a.25.25 0 01-.25.25h-2a.75.75 0 000 1.5h.5a.25.25 0 01.25.25.75.75 0 001.5 0v-.071a.249.249 0 01.187-.242A1.746 1.746 0 0014.5 20.75c0-1.213-1.106-1.767-1.915-2.171-.635-.318-1.085-.563-1.085-.829a.25.25 0 01.25-.25z\"/><path d=\"M16.165 15.092a.249.249 0 00.232.158H19a1 1 0 001-1v-10a1 1 0 00-1-1H5a1 1 0 00-1 1v10a1 1 0 001 1h2.6a.249.249 0 00.232-.158 4.47 4.47 0 018.33 0zm-7.16-6.844a3 3 0 113 3 3 3 0 01-3-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});