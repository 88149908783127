define("ember-svg-jar/inlined/road-sign-turn-right-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-turn-right-2</title><path d=\"M.22 12.53l11.25 11.25a.748.748 0 001.06 0l11.25-11.25a.749.749 0 000-1.06L12.53.22a.749.749 0 00-1.06 0L.22 11.47a.749.749 0 000 1.06zM9.25 12a2 2 0 012-2H14a.25.25 0 00.25-.25V9.1a.25.25 0 01.427-.177l1.719 1.72a.5.5 0 010 .707l-1.719 1.72a.25.25 0 01-.427-.177v-.643A.249.249 0 0014 12h-2.25a.5.5 0 00-.5.5v4a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});