define("ember-svg-jar/inlined/army-fortress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-fortress</title><path d=\"M24 1.953A1.958 1.958 0 0022.044.006H1.959A1.958 1.958 0 00.012 1.965L0 9.305a15.148 15.148 0 0011.861 14.67 1 1 0 00.4 0A15.145 15.145 0 0023.988 9.2zm-6.5 6.553a1 1 0 01-1 1 .5.5 0 00-.5.5v6.5a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-6.5a.5.5 0 00-.5-.5 1 1 0 01-1-1v-3.5a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5H17a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});