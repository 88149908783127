define("ember-svg-jar/inlined/landmark-forbidden-city", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-forbidden-city</title><path d=\"M.5 13.25h23a.5.5 0 00.257-.929 13.01 13.01 0 01-3.341-2.848A.5.5 0 0020 9.25H4a.5.5 0 00-.416.223 13.042 13.042 0 01-3.341 2.848.5.5 0 00.257.929zM.5 7.75h23a.5.5 0 00.31-.893c-.018-.013-.11-.07-.129-.082a15.246 15.246 0 01-3.713-2.92.5.5 0 01.038-.693 2.508 2.508 0 00.744-1.912 1 1 0 00-2 0c0 .5-.163.5-.25.5h-13c-.087 0-.25 0-.25-.5a1 1 0 00-2 0A2.509 2.509 0 004 3.163a.5.5 0 01.038.693 15.234 15.234 0 01-3.719 2.92 3.338 3.338 0 00-.126.08.5.5 0 00.307.894zM22.981 19.613a.5.5 0 00-.481-.363H22a.5.5 0 01-.5-.5v-3.5a.5.5 0 00-.5-.5H3a.5.5 0 00-.5.5v3.5a.5.5 0 01-.5.5h-.5a.5.5 0 00-.481.363l-1 3.5a.5.5 0 00.481.637h23a.5.5 0 00.481-.637zM7 18.75a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a1 1 0 012 0zm4 0a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a1 1 0 012 0zm4 0a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a1 1 0 012 0zm4 0a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a1 1 0 012 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});