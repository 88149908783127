define("ember-svg-jar/inlined/email-action-at", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-at</title><path d=\"M18.409 15.131a.758.758 0 01-.409.119H6a.757.757 0 01-.405-.119l-5.21-3.35a.25.25 0 00-.385.21V22a2 2 0 002 2h20a2 2 0 002-2V12a.25.25 0 00-.385-.211z\"/><path d=\"M5.12 13.041a.25.25 0 00.385-.21L5.5 2.25A.249.249 0 015.75 2h12.5a.25.25 0 01.25.25l.01 10.575a.25.25 0 00.385.21l4.053-2.607a.248.248 0 00.115-.206.251.251 0 00-.107-.21L20.5 8.3V2.207a2.114 2.114 0 00-2-2.208h-13a2.115 2.115 0 00-2 2.209V8.3l-2.458 1.71a.249.249 0 00.007.415z\"/><path d=\"M7.375 9.6a4.7 4.7 0 003.51 3.52 4.973 4.973 0 001.115.131 4.716 4.716 0 001.221-.16.75.75 0 10-.386-1.449 3.343 3.343 0 01-1.616.018 3.231 3.231 0 01-2.382-2.39 3.286 3.286 0 01.648-2.829 3.207 3.207 0 012.6-1.19 3.37 3.37 0 013.165 3.435v.564a.5.5 0 01-1 0V8.5A2.25 2.25 0 1012 10.75a2.225 2.225 0 001.142-.322A1.995 1.995 0 0016.75 9.25v-.564a4.879 4.879 0 00-4.627-4.935 4.723 4.723 0 00-3.8 1.739 4.774 4.774 0 00-.948 4.11zM12 9.25a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});