define("ember-svg-jar/inlined/elephant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>elephant</title><path d=\"M21.447 8.858l-2.011-1.006A4.493 4.493 0 0016 6.255h-1.034c-.45 1.886-1.44 4.667-5.26 3.871a.75.75 0 01.3-1.469 2.821 2.821 0 002.183-.218c1.12-.747 1.421-2.989 1.64-4.117a2.483 2.483 0 00-.469-1.7 3.2 3.2 0 00-2.658-.874l-.22.006c-1.6.042-2.791.072-3.8 1.3a5.151 5.151 0 00-2.4-.254A5.094 5.094 0 000 7.905v7.35a5.994 5.994 0 001.646 3.853.5.5 0 00.708 0l1.5-1.5a.5.5 0 000-.7A3.9 3.9 0 013 14.255v-.323a.5.5 0 01.618-.486 12.008 12.008 0 001.6.264.5.5 0 01.441.4l.078.385a21.633 21.633 0 01.265 5.01v2.251a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-4a.5.5 0 01.5-.5H16a.5.5 0 01.5.5v4a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V10.621a2.835 2.835 0 011.413 1.563 1.9 1.9 0 01.089.57v4a1 1 0 002 0v-4a4.7 4.7 0 00-2.555-3.896zM2.25 8a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});