define("ember-svg-jar/inlined/book-close-bookmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-close-bookmark</title><path d=\"M20.25 1.75H18.5a.5.5 0 01-.5-.5V1a1 1 0 00-1-1h-3a1 1 0 00-1 1v9.5a.5.5 0 00.854.354l1.469-1.47a.25.25 0 01.354 0l1.469 1.47A.5.5 0 0018 10.5V4.25a.5.5 0 01.5-.5h1.25a.5.5 0 01.5.5V21.5a.5.5 0 01-.5.5H7.25a.5.5 0 01-.5-.5V4.25a.5.5 0 01.5-.5h4a.25.25 0 00.25-.25V2a.25.25 0 00-.25-.25h-7.5a2 2 0 00-2 2V22a2 2 0 002 2h16.5a2 2 0 002-2V3.75a2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});