define("ember-svg-jar/inlined/history-fan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-fan</title><path d=\"M23.842 11.89a13.211 13.211 0 00-2.019-3.8.25.25 0 00-.38-.02l-4.168 4.43a.25.25 0 01-.369 0 5.537 5.537 0 00-.7-.656.25.25 0 01-.026-.367l4.28-4.544a.251.251 0 00-.013-.356A11.25 11.25 0 0016.5 4.215a.251.251 0 00-.324.172L14.5 10.626a.25.25 0 01-.314.175 6.324 6.324 0 00-.942-.208.251.251 0 01-.207-.313l1.684-6.256a.25.25 0 00-.2-.312 15.077 15.077 0 00-5.039 0 .249.249 0 00-.2.311l1.684 6.257a.251.251 0 01-.207.313 6.366 6.366 0 00-.943.208.249.249 0 01-.313-.175L7.831 4.387a.251.251 0 00-.324-.172 11.182 11.182 0 00-3.951 2.362.249.249 0 00-.012.355l4.276 4.535a.25.25 0 01-.026.368 5.4 5.4 0 00-.7.655.25.25 0 01-.369.006L2.551 8.071a.249.249 0 00-.38.02 13.009 13.009 0 00-2.009 3.8.993.993 0 00.58 1.24l9.842 3.881a.25.25 0 01.064.427L9.192 18.61a.5.5 0 00-.14.61c.07.13.7 1.28 2.95 1.28s2.88-1.15 2.95-1.28a.51.51 0 00-.14-.61l-1.457-1.172a.25.25 0 01.064-.427l9.833-3.881a1 1 0 00.59-1.24zM12 12.5a4.666 4.666 0 013.316 1.224.25.25 0 01-.085.407L12.1 15.383a.249.249 0 01-.186 0l-3.143-1.252a.25.25 0 01-.086-.407A4.647 4.647 0 0112 12.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});