define("ember-svg-jar/inlined/arrow-button-up-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-up-2</title><path d=\"M23.437 19L13.711 2.93a2 2 0 00-3.422 0L.563 19a2 2 0 001.711 3.035h19.452A2 2 0 0023.437 19z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});