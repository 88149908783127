define("ember-svg-jar/inlined/image-document-eps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-document-eps</title><path d=\"M12 9.625h-.625a.249.249 0 00-.25.25v1.25a.251.251 0 00.25.25H12a.875.875 0 000-1.75z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM6.375 11.375H7.5a.625.625 0 010 1.25H6.375a.249.249 0 00-.25.25V14a.375.375 0 00.375.375h2a.625.625 0 010 1.25h-2A1.626 1.626 0 014.875 14v-4A1.625 1.625 0 016.5 8.375h2a.625.625 0 010 1.25h-2a.375.375 0 00-.375.375v1.125a.251.251 0 00.25.25zm5.5 1.25h-.5a.249.249 0 00-.25.25V15a.625.625 0 01-1.25 0V9a.625.625 0 01.625-.625H12a2.128 2.128 0 012.114 2.346 2.214 2.214 0 01-2.239 1.904zm4.552-1.758l1.84 1.227a1.927 1.927 0 01-1.07 3.531H15.5a.625.625 0 010-1.25h1.7a.678.678 0 00.376-1.242l-1.84-1.227A1.927 1.927 0 0116.8 8.375h1.7a.625.625 0 010 1.25h-1.7a.678.678 0 00-.376 1.242z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});