define("ember-svg-jar/inlined/official-building-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>official-building-3</title><path d=\"M23.884 23.18l-2.5-3A.5.5 0 0021 20H3a.5.5 0 00-.384.18l-2.5 3A.5.5 0 00.5 24h23a.5.5 0 00.384-.82z\"/><rect x=\"3\" y=\"10.5\" width=\"4\" height=\"8.5\" rx=\".5\" ry=\".5\"/><rect x=\"10\" y=\"10.5\" width=\"4\" height=\"8.5\" rx=\".5\" ry=\".5\"/><rect x=\"17\" y=\"10.5\" width=\"4\" height=\"8.5\" rx=\".5\" ry=\".5\"/><path d=\"M2.084 9.277A.5.5 0 002.5 9.5h19a.5.5 0 00.416-.223l1-1.5A.5.5 0 0022.5 7h-21a.5.5 0 00-.416.777zM1.5 6h21a.5.5 0 00.215-.952l-10.5-5a.506.506 0 00-.43 0l-10.5 5A.5.5 0 001.5 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});