define("ember-svg-jar/inlined/wild-pig", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wild-pig</title><path d=\"M21.439 8.084c.588-.978 1.992-3.534 1.518-4.871a1.257 1.257 0 00-.81-.8c-1.036-.349-3.217.229-4.092.489L16.5.8a.5.5 0 00-.76-.053l-.9.913L12.232.075a.5.5 0 00-.52 0L9.1 1.66 8.2.747A.489.489 0 007.812.6a.5.5 0 00-.366.2L5.889 2.9c-.875-.262-3.056-.839-4.093-.489a1.244 1.244 0 00-.8.8c-.468 1.337.967 3.9 1.568 4.875a12.193 12.193 0 00-2.253 6.59C.306 22.023 7.591 24 12 24s11.694-1.976 11.694-9.322a12.2 12.2 0 00-2.255-6.594zm-11.571.467a1.333 1.333 0 11-1.333-1.333 1.332 1.332 0 011.333 1.333zm-5.74 4.532a.519.519 0 01.473-.3h.015a.517.517 0 01.468.331 11.365 11.365 0 001.973 3.51 1.749 1.749 0 01-1.287 2.931 2.619 2.619 0 01-2.616-2.615 9.4 9.4 0 01.974-3.857zm4.884 4.522a3.239 3.239 0 00-.85-2 4.271 4.271 0 01-.312-.382c.378-1.616 2.043-3.975 4.15-3.975s3.772 2.359 4.15 3.975a4.271 4.271 0 01-.312.382 3.245 3.245 0 00-.85 2 7.046 7.046 0 01-2.988.508 7.052 7.052 0 01-2.988-.508zm7.617.9a1.747 1.747 0 01.313-1.885 11.36 11.36 0 001.973-3.511.518.518 0 01.468-.33h.017a.52.52 0 01.473.3 9.4 9.4 0 01.973 3.855 2.619 2.619 0 01-2.616 2.615 1.747 1.747 0 01-1.601-1.042zm-1.164-8.622A1.333 1.333 0 1116.8 8.551a1.332 1.332 0 01-1.335 1.332z\"/><path d=\"M9.335 15.214a1.066 1.066 0 101.065-1.067 1.066 1.066 0 00-1.065 1.067zM14.665 15.214A1.066 1.066 0 1013.6 16.28a1.067 1.067 0 001.065-1.066z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});