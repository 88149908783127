define("ember-svg-jar/inlined/real-estate-insurance-dollar-hand-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-insurance-dollar-hand-1</title><path d=\"M13.5 19.5H6a.5.5 0 01-.352-.854A.5.5 0 016 18.5h3a.5.5 0 00.5-.5 2 2 0 00-2-2H2.25a.25.25 0 01-.25-.25.75.75 0 00-1.5 0v7.5a.75.75 0 001.5 0 .25.25 0 01.25-.25H16a.5.5 0 00.5-.5 3 3 0 00-3-3zM23.331 6.626L16.163.252a1 1 0 00-1.328 0L7.667 6.626A.5.5 0 008 7.5h2a.5.5 0 01.5.5v5a1 1 0 001 1h8a1 1 0 001-1V8a.5.5 0 01.5-.5h2a.5.5 0 00.331-.874zm-6.72 4.532a.5.5 0 00-.362.48v.112a.75.75 0 11-1.5 0 .5.5 0 00-.5-.5H14a.75.75 0 010-1.5h2.033a.592.592 0 00.22-1.141l-2.063-.825a2.084 2.084 0 01.2-3.942.5.5 0 00.361-.48V3.25a.75.75 0 011.5 0 .5.5 0 00.5.5H17a.75.75 0 010 1.5h-2.034a.592.592 0 00-.22 1.142l2.063.825a2.084 2.084 0 01-.2 3.941z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});