define("ember-svg-jar/inlined/deer-sleep", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>deer-sleep</title><path d=\"M7.5 5.7a6.008 6.008 0 003 .8 5.977 5.977 0 005.97-5.4 1 1 0 10-1.99-.2 3.981 3.981 0 01-1 2.273A3 3 0 0010.5.5a1 1 0 000 2 1 1 0 010 2 4 4 0 01-2-.536 1 1 0 10-1 1.736zM19.719 7.875A5.245 5.245 0 0020.5 5a.5.5 0 00-.5-.5 4.122 4.122 0 00-3.337 1.735A6.276 6.276 0 0015 6c-2.65 0-5.692 2.508-5.82 2.616a.5.5 0 00-.154.542A2.714 2.714 0 0011.5 11h2c1.208 0 2 .1 2 1.5a2.1 2.1 0 01-.588 1.591c-1.026 1-3.247.956-4.717.919C9.942 15 5.5 15 5.5 15a4.5 4.5 0 000 9h4c5.014 0 13.5-1.9 13.5-9a9.343 9.343 0 00-3.281-7.125zM15 9.5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});