define("ember-svg-jar/inlined/road-sign-hospital", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-hospital</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm2.971 16.5a.75.75 0 01-1.5 0v-3a.25.25 0 00-.25-.25h-2a.25.25 0 00-.25.25v3a.75.75 0 01-1.5 0v-8a.75.75 0 011.5 0v3a.25.25 0 00.25.25h2a.25.25 0 00.25-.25v-3a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});