define("ember-svg-jar/inlined/headphones-human", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>headphones-human</title><path d=\"M12.643 11.341a1.689 1.689 0 101.7 1.688 1.695 1.695 0 00-1.7-1.688z\"/><path d=\"M16.53 2.177a.5.5 0 01-.289-.293A2.892 2.892 0 0013.532 0h-1.778A2.892 2.892 0 009 2.012a.5.5 0 01-.286.311A8.212 8.212 0 003.3 9.6a2.009 2.009 0 01-.215.832c-.147.289-.347.638-.512.928-.7 1.235-1.5 2.633-1.423 3.75v.137a1.223 1.223 0 00.189.742 1.247 1.247 0 00.965.521c.173.007.364.01.551.01a.5.5 0 01.5.5V19a2.5 2.5 0 002.5 2.5h1.5a.5.5 0 01.5.5v1.5a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-3.709a.5.5 0 01.221-.415c2.508-1.689 3.779-4.314 3.779-7.814a9.94 9.94 0 00-6.325-9.385zM12.643 17a3.986 3.986 0 01-4-3.971 3.943 3.943 0 011.975-3.4.5.5 0 00.248-.432V2.882A.885.885 0 0111.754 2h1.778a.886.886 0 01.889.882v6.31a.5.5 0 00.248.432 3.942 3.942 0 011.974 3.4 3.986 3.986 0 01-4 3.976z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});