define("ember-svg-jar/inlined/move-left-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>move-left-1</title><path d=\"M23.75 11.521a1.25 1.25 0 00-1.25-1.25H11.318a.25.25 0 01-.25-.25V7.7a.5.5 0 00-.853-.353L6.4 11.168a.5.5 0 000 .707l3.819 3.818a.5.5 0 00.353.147.513.513 0 00.192-.038.5.5 0 00.308-.462v-2.319a.25.25 0 01.25-.25H22.5a1.25 1.25 0 001.25-1.25zM1.5 23.749A1.25 1.25 0 002.75 22.5v-21a1.25 1.25 0 00-2.5 0v21a1.25 1.25 0 001.25 1.249z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});