define("ember-svg-jar/inlined/hardware-nuts-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hardware-nuts-1</title><path d=\"M17.686 8.189a.5.5 0 00-.549.258 2.37 2.37 0 01-.112.194L15.2 11.765a.5.5 0 00.478.75q.162-.015.322-.015a3.5 3.5 0 11-3.5 3.5v-.065a.5.5 0 00-.607-.5 2.747 2.747 0 01-.589.065H8.5a.5.5 0 00-.5.5 8 8 0 109.686-7.811z\"/><path d=\"M12.578 13.292l3.185-5.462a1.5 1.5 0 00.013-1.6L12.568.7A1.493 1.493 0 0011.3 0H4.7a1.5 1.5 0 00-1.278.712L.235 6.215a1.5 1.5 0 00-.008 1.6l3.207 5.5A1.5 1.5 0 004.7 14h6.609a1.5 1.5 0 001.269-.708zM8 10a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});