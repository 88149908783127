define("ember-svg-jar/inlined/vintage-camera", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-camera</title><path d=\"M14.905 3a.25.25 0 00.223-.362L14.224.83A1.491 1.491 0 0012.882 0H9.118a1.492 1.492 0 00-1.342.829l-.9 1.809A.25.25 0 007.1 3z\"/><circle cx=\"11\" cy=\"17\" r=\"3.5\"/><path d=\"M19 6.5A2.5 2.5 0 0016.5 4h-11A2.5 2.5 0 003 6.5v15A2.5 2.5 0 005.5 24h11a2.5 2.5 0 002.5-2.5zM13 8a2 2 0 11-2-2 2 2 0 012 2zm-7.5 9a5.5 5.5 0 115.5 5.5A5.506 5.506 0 015.5 17zM.75 6a.75.75 0 00-.75.75v2a.75.75 0 001.5 0v-2A.75.75 0 00.75 6zM24 12.5a1.5 1.5 0 00-1.5-1.5h-1a.5.5 0 00-.5.5v7a.25.25 0 01-.25.25h-.5A.25.25 0 0020 19v1.5a.25.25 0 00.25.25H21a2 2 0 002-2v-4.669a.25.25 0 01.143-.226A1.5 1.5 0 0024 12.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});