define("ember-svg-jar/inlined/single-neutral-actions-edit-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-edit-2</title><path d=\"M16.1 13.072a.249.249 0 00.031-.316c-.649-.96-2.342-1.584-4.634-2.427l-.627-.229a.6.6 0 01-.138-.31 2.57 2.57 0 01.058-1.3 5.621 5.621 0 001.466-4.315C12.256 1.756 10.677 0 8.5 0S4.744 1.756 4.744 4.174A5.6 5.6 0 006.2 8.471a2.456 2.456 0 01.073 1.317.614.614 0 01-.135.308l-.631.233c-2.447.9-4.215 1.549-4.754 2.626A9.17 9.17 0 000 16.5a.5.5 0 00.5.5h11.569a.249.249 0 00.177-.073zM23.228 11.765a2.62 2.62 0 00-3.7 0l-6.678 6.679a.25.25 0 000 .353l3.35 3.354a.25.25 0 00.353 0l6.679-6.679a2.621 2.621 0 00-.004-3.707zM12.062 20.132a.248.248 0 00-.249-.063.253.253 0 00-.173.19l-.628 3.141a.5.5 0 00.49.6.466.466 0 00.1-.01l3.143-.628a.251.251 0 00.19-.173.248.248 0 00-.063-.249z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});