define("ember-svg-jar/inlined/wedding-certificate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wedding-certificate</title><path d=\"M9.25 19.75H2.5a.5.5 0 01-.5-.5V2.5a.5.5 0 01.5-.5h8.879a.5.5 0 01.353.146l4.122 4.122a.5.5 0 01.146.353V9a1 1 0 002 0V6.414A2.011 2.011 0 0017.414 5L13 .586A1.986 1.986 0 0011.586 0H2a2 2 0 00-2 2v17.75a2 2 0 002 2h7.25a1 1 0 000-2z\"/><path d=\"M12.051 9.75A.75.75 0 0011.3 9H4.25a.75.75 0 000 1.5h7.05a.75.75 0 00.751-.75zM9.8 13.5H4.25a.75.75 0 000 1.5H9.8a.75.75 0 100-1.5zM17.75 11.5A6.25 6.25 0 1024 17.75a6.25 6.25 0 00-6.25-6.25zm3.409 6.943l-3.048 3.181a.5.5 0 01-.722 0l-3.048-3.181a2.017 2.017 0 112.852-2.852l.2.2a.5.5 0 00.708 0l.2-.2a2.017 2.017 0 112.852 2.852z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});