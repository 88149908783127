define("ember-svg-jar/inlined/reception-desk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>reception-desk</title><path d=\"M12 0a4.5 4.5 0 104.5 4.5A4.505 4.505 0 0012 0zm0 7a2.5 2.5 0 112.5-2.5A2.5 2.5 0 0112 7z\"/><rect x=\"8\" y=\"11\" width=\"8\" height=\"6\" rx=\".5\" ry=\".5\"/><path d=\"M15.5 18h-7a.5.5 0 00-.5.5v.5a1 1 0 001 1h6a1 1 0 001-1v-.5a.5.5 0 00-.5-.5zM22.5 12h-5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h.5a.5.5 0 01.5.5V23a1 1 0 002 0v-7.5a.5.5 0 01.5-.5h1.5a1.5 1.5 0 000-3zM6.5 12h-5a1.5 1.5 0 000 3H3a.5.5 0 01.5.5V23a1 1 0 002 0v-7.5A.5.5 0 016 15h.5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});