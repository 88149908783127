define("ember-svg-jar/inlined/social-media-tuenti", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-tuenti</title><path d=\"M21.49 3a.51.51 0 00-.17-.18A9.29 9.29 0 0014.61 0H9.39A9.4 9.4 0 000 9.39v5.22a9.38 9.38 0 003.16 7A9.26 9.26 0 009.39 24h12.13A2.48 2.48 0 0024 21.52V9.39A9.37 9.37 0 0021.49 3zM10.4 14.18l-2.55 4.19a.75.75 0 11-1.28-.78l2.54-4.19a.75.75 0 111.28.78zm-1.16-4.11a1.5 1.5 0 111.5-1.51 1.5 1.5 0 01-1.5 1.51zm6.34 8.33a.75.75 0 01-1.23-.86 8.82 8.82 0 00-.43-10.66.75.75 0 111.16-1 10.32 10.32 0 01.5 12.48z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});