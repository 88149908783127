define("ember-svg-jar/inlined/smart-watch-square-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-house</title><path d=\"M16.43 2.75a.251.251 0 00.238-.329L15.975.342A.5.5 0 0015.5 0h-7a.5.5 0 00-.475.342l-.693 2.079a.251.251 0 00.238.329zM7.57 21.25a.248.248 0 00-.2.1.251.251 0 00-.035.225l.693 2.079A.5.5 0 008.5 24h7a.5.5 0 00.475-.342l.693-2.079a.251.251 0 00-.035-.225.248.248 0 00-.2-.1zM17.5 4h-11A2.5 2.5 0 004 6.5v11A2.508 2.508 0 006.5 20h11a2.5 2.5 0 002.5-2.5v-11A2.5 2.5 0 0017.5 4zM12 13.5a1.25 1.25 0 00-1.25 1.25V16a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5v-3.25A.25.25 0 008 12.5h-.911a.339.339 0 01-.318-.219.329.329 0 01.1-.369l4.911-3.833a.341.341 0 01.438 0l4.911 3.833a.329.329 0 01.1.369.339.339 0 01-.318.219H16a.25.25 0 00-.25.25V16a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5v-1.25A1.25 1.25 0 0012 13.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});