define("ember-svg-jar/inlined/like-click", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>like-click</title><path d=\"M14.134 12.645a3.04 3.04 0 01.282-.645 2.969 2.969 0 014.936-.341 2.921 2.921 0 01.3.463.5.5 0 00.661.22A6.489 6.489 0 0017.5 0h-11a6.5 6.5 0 000 13h7.155a.5.5 0 00.479-.355zM16.25 6V4.5A1.752 1.752 0 0118 2.746h1a.75.75 0 010 1.5h-1a.251.251 0 00-.25.25V5a.25.25 0 00.25.25h1a.75.75 0 010 1.5h-1a.25.25 0 00-.25.25v.5a.25.25 0 00.25.25h1a.75.75 0 010 1.5h-1a1.752 1.752 0 01-1.75-1.75zM7.5 9.248h-1A1.752 1.752 0 014.75 7.5v-4a.75.75 0 011.5 0v4a.25.25 0 00.25.25h1a.75.75 0 010 1.5zm3.25-.75a.75.75 0 01-1.5 0v-5a.75.75 0 011.5 0zm2.585-.121a.127.127 0 00-.085.119.75.75 0 11-1.5 0v-5a.75.75 0 011.5 0 .127.127 0 00.226.08l.438-.548a.75.75 0 111.172.937l-1.5 1.875a.251.251 0 000 .313l1.5 1.875a.75.75 0 01-1.172.937l-.438-.548a.127.127 0 00-.141-.04z\"/><path d=\"M22.03 18.568A3.312 3.312 0 0020.167 18H19a.5.5 0 01-.5-.5v-4a1.5 1.5 0 10-3 0v6.465a.25.25 0 01-.41.192l-.879-.736a1.266 1.266 0 00-1.76-.162 1.25 1.25 0 00-.162 1.76l2.329 2.8A.5.5 0 0015 24h8a.5.5 0 00.5-.5v-2.168a3.329 3.329 0 00-1.47-2.764z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});