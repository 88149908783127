define("ember-svg-jar/inlined/dna", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dna</title><path d=\"M3.342 7.133a.249.249 0 00.212.117h3.392a.249.249 0 00.212-.117l1.875-3a.253.253 0 000-.265l-1.875-3A.249.249 0 006.946.75H3.554a.249.249 0 00-.212.117l-1.875 3a.253.253 0 000 .265zM8.467 7.867a.253.253 0 000 .265l1.875 3a.249.249 0 00.212.117h2.892a.249.249 0 00.212-.117l1.875-3a.253.253 0 000-.265l-1.875-3a.249.249 0 00-.212-.117h-2.892a.249.249 0 00-.212.117zM3.342 15.133a.249.249 0 00.212.117h3.392a.249.249 0 00.212-.117l1.875-3a.253.253 0 000-.265l-1.875-3a.249.249 0 00-.212-.117H3.554a.249.249 0 00-.212.117l-1.875 3a.253.253 0 000 .265zM9.033 19.868l-1.875-3a.249.249 0 00-.212-.117H3.554a.249.249 0 00-.212.117l-1.875 3a.253.253 0 000 .265l1.875 3a.249.249 0 00.212.117h3.392a.249.249 0 00.212-.117l1.875-3a.253.253 0 000-.265zM15.533 16.133a.253.253 0 000-.265l-1.875-3a.249.249 0 00-.212-.117h-2.892a.249.249 0 00-.212.117l-1.875 3a.253.253 0 000 .265l1.875 3a.249.249 0 00.212.117h2.892a.249.249 0 00.212-.117zM14.967 4.132l1.875 3a.249.249 0 00.212.117h3.392a.249.249 0 00.212-.117l1.875-3a.253.253 0 000-.265l-1.875-3a.249.249 0 00-.212-.117h-3.392a.249.249 0 00-.212.117l-1.875 3a.253.253 0 000 .265zM22.533 11.868l-1.875-3a.249.249 0 00-.212-.117h-3.392a.249.249 0 00-.212.117l-1.875 3a.253.253 0 000 .265l1.875 3a.249.249 0 00.212.117h3.392a.249.249 0 00.212-.117l1.875-3a.253.253 0 000-.265zM20.658 16.867a.249.249 0 00-.212-.117h-3.392a.249.249 0 00-.212.117l-1.875 3a.253.253 0 000 .265l1.875 3a.249.249 0 00.212.117h3.392a.249.249 0 00.212-.117l1.875-3a.253.253 0 000-.265z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});