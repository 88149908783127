define("ember-svg-jar/inlined/messages-bubble-lock-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-lock-1-alternate</title><path d=\"M10 15a1 1 0 00-.707.293L7 17.586V15.56a1 1 0 00-.664-.942A6.493 6.493 0 018.5 2h7A6.508 6.508 0 0122 8.5a6.421 6.421 0 01-.168 1.437.251.251 0 00.1.263 5.162 5.162 0 011.179 1.094.25.25 0 00.434-.073A8.488 8.488 0 0015.5 0h-7A8.492 8.492 0 005 16.232V20a1 1 0 001.707.707L10.414 17h1.846a.249.249 0 00.249-.23 2.941 2.941 0 01.479-1.383.25.25 0 00-.209-.387z\"/><path d=\"M22.5 15.5v-1.25a3.5 3.5 0 00-7 0v1.25A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.5-1.5zM19 12.75a1.5 1.5 0 011.5 1.5v1.25h-3v-1.25a1.5 1.5 0 011.5-1.5zm0 6.5a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});