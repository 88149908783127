define("ember-svg-jar/inlined/check-square-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>check-square-alternate</title><path d=\"M23.409.4a1.249 1.249 0 00-1.758.19L10.025 15.007 6.008 10.99a1.25 1.25 0 00-1.768 1.768l5 5a1.263 1.263 0 001.857-.1l12.5-15.5A1.249 1.249 0 0023.409.4z\"/><path d=\"M19.124 11.374a1 1 0 00-1 1v9.25a.25.25 0 01-.25.25h-15.5a.25.25 0 01-.25-.25v-15.5a.25.25 0 01.25-.25h11.25a1 1 0 100-2h-11.5a2 2 0 00-2 2v16a2 2 0 002 2h16a2 2 0 002-2v-9.5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});