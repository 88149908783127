define("ember-svg-jar/inlined/real-estate-dimensions-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-dimensions-map</title><path d=\"M23.25 3.016L17.191.271a1.912 1.912 0 00-1.3-.056L7.884 3.02A1 1 0 017.323 3L1.159.266a.822.822 0 00-.8.042.829.829 0 00-.359.714l.02 18.822a1.342 1.342 0 00.753 1.161L6.9 23.733a1.855 1.855 0 00.732.14 1.748 1.748 0 00.565-.087l8.024-2.8a.968.968 0 01.556.025l6.059 2.744A.8.8 0 0024 23V4.177a1.347 1.347 0 00-.75-1.161zM2.5 15.506a.249.249 0 01.335-.235l4.565 1.67a.743.743 0 00.5.005l2.519-.866a.251.251 0 01.332.237v3.433a.252.252 0 01-.17.237l-2.544.865a1.6 1.6 0 01-.939-.026L2.964 19.19a.8.8 0 01-.464-.685zm9.3-1a1.1 1.1 0 00-1.014-.132l-3.04 1.039a.252.252 0 01-.165 0l-4.915-1.755a.25.25 0 01-.166-.236v-2.873a.249.249 0 01.342-.232l4.379 1.752a.745.745 0 00.279.054.758.758 0 00.183-.023l3.968-1a.75.75 0 00-.366-1.455l-1.535.387V8.979a.75.75 0 00-1.5 0v1.431l-.7.176-1.8-.722V8.373a.75.75 0 10-1.5 0v.891l-1.587-.581a.25.25 0 01-.163-.235v-4a.314.314 0 01.463-.313l4.151 1.678a1.58 1.58 0 00.938.029l5.869-1.967a.248.248 0 01.225.034.252.252 0 01.1.2v4.87a.75.75 0 001.5 0V3.3l.306-.139a1.414 1.414 0 01.923.06L21.05 5.2a.877.877 0 01.45.719v7.936a.25.25 0 01-.109.206.247.247 0 01-.231.027l-4.008-1.543a2.062 2.062 0 00-1.51.059l-1.016.486a1.614 1.614 0 00-.876 1.391s-.02 3.431-.007 4.265a.251.251 0 01-.169.24l-.993.342a.249.249 0 01-.331-.237v-3.674a1.105 1.105 0 00-.45-.917zm3.476.095a.251.251 0 01.142-.224l.874-.417a.64.64 0 01.325-.013l4.723 1.766a.251.251 0 01.163.234v3.52a.318.318 0 01-.466.317l-4.5-1.775-.948.318a.248.248 0 01-.226-.035.251.251 0 01-.1-.2zm7.772 8.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});