define("ember-svg-jar/inlined/vr-user-globe-human", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vr-user-globe-human</title><path d=\"M24 5.51a.5.5 0 00-.478-.5 7.49 7.49 0 00-4.341.973.5.5 0 00.055.88l4.057 1.842A.5.5 0 0024 8.25zM23.437 14.5l-8 1a.5.5 0 00-.409.329s-.544 1.538-.7 2.009a.5.5 0 00.475.658h1.613a.25.25 0 01.25.25v.564a2.6 2.6 0 002.593 2.593h.026a.25.25 0 01.25.25V23.5a.5.5 0 00.5.5H23.5a.5.5 0 00.5-.5V15a.5.5 0 00-.169-.375.491.491 0 00-.394-.125zM15.252 14l7.338-.443A1.5 1.5 0 0024 12.059V11.2a1.506 1.506 0 00-.978-1.406L17.414 7.7a1.481 1.481 0 00-1.6.423 5.387 5.387 0 00-1.083 2.252 5.061 5.061 0 00.038 3.345.5.5 0 00.483.28zm2.023-4.747l1.627.522a.5.5 0 01.147.877l-2.242 1.668a.5.5 0 01-.8-.429 5.728 5.728 0 01.693-2.422.5.5 0 01.575-.217zM7 0a7 7 0 107 7 7.008 7.008 0 00-7-7zm0 12a4.68 4.68 0 01-1.092-.14.248.248 0 01-.192-.24l-.028-2.37A3.754 3.754 0 002.76 5.964l-.366-.141a.25.25 0 01-.149-.308A4.994 4.994 0 017 2a4.634 4.634 0 01.787.07.255.255 0 01.183.137.251.251 0 010 .228l-.527.976a1.95 1.95 0 00.294 2.382L9.07 7.13a.252.252 0 01.066.238l-.172.686a1.934 1.934 0 00.219 1.476 1.814 1.814 0 001.005.822.251.251 0 01.077.424A4.969 4.969 0 017 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});