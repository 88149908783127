define("ember-svg-jar/inlined/water-fountain-drink", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-fountain-drink</title><circle cx=\"12.25\" cy=\"3.25\" r=\"2.5\"/><path d=\"M11.9 10.758l-.917-.093a1.493 1.493 0 01-1.122-1.188l-.635-3.5a1.5 1.5 0 00-2.586-.74l-5 5.5a1.5 1.5 0 00-.39 1.009v10a1.5 1.5 0 003 0V12.33l2.567-2.824.093.507c.447 2.461 2.229 3.482 4.69 3.729a1.483 1.483 0 001.642-1.342 1.5 1.5 0 00-1.342-1.642zM22.25 11.25h-1v-.5a1 1 0 00-2 0v.5h-3.488a1 1 0 00-1 1.047c.225 4.757 3.238 7.953 7.488 7.953a.5.5 0 00.5-.5v-8a.5.5 0 00-.5-.5zM18.6 7.929a1.308 1.308 0 01.465.461 1 1 0 00.861.489.984.984 0 00.509-.141 1 1 0 00.349-1.37A3.307 3.307 0 0019.606 6.2 1 1 0 0018.6 7.929zM15.249 9.132a1 1 0 00.8-.4s.448-.584.5-.637a1 1 0 10-1.343-1.475c-.15.136-.756.911-.756.911a1 1 0 00.8 1.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});