define("ember-svg-jar/inlined/single-neutral-actions-star.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-star</title><path d=\"M9.2 15a3.238 3.238 0 013.016-2 .247.247 0 00.17-.427A7.462 7.462 0 00.065 15.856a.5.5 0 00.479.643H8.47a.5.5 0 00.5-.474A3.113 3.113 0 019.2 15z\"/><circle cx=\"7.044\" cy=\"4.75\" r=\"4.75\"/><path d=\"M20.693 23.827a1.275 1.275 0 001.506-.166 1.254 1.254 0 00.315-1.45L21.29 19.4a.25.25 0 01.063-.286l2.213-1.972a1.263 1.263 0 00.3-1.377A1.234 1.234 0 0022.7 15h-2.5a.248.248 0 01-.229-.151l-1.344-3.116-.017-.033a1.294 1.294 0 00-2.318.028l-1.346 3.116a.248.248 0 01-.229.151h-2.5a1.23 1.23 0 00-1.166.76 1.27 1.27 0 00.32 1.4l2.194 1.955a.25.25 0 01.063.286L12.4 22.21a1.253 1.253 0 00.314 1.451 1.286 1.286 0 001.506.166l3.112-1.752a.249.249 0 01.245 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});