define("ember-svg-jar/inlined/single-woman-actions-image.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-image</title><path d=\"M9 12.144a.5.5 0 00-.4-.489 6.816 6.816 0 00-1.4-.155 7.543 7.543 0 00-7.179 5.357.5.5 0 00.479.643h8A.5.5 0 009 17zM12.5 10.5a2 2 0 00-2 2V22a2 2 0 002 2H22a2 2 0 002-2v-9.5a2 2 0 00-2-2zm8.775 6.387a1.25 1.25 0 00-2.064-.024l-1.537 2.2a.254.254 0 01-.169.1.25.25 0 01-.192-.053l-.612-.489a1.249 1.249 0 00-1.821.282l-2 3.005a.2.2 0 01-.171.092.206.206 0 01-.206-.206V12.75a.25.25 0 01.25-.25h9a.25.25 0 01.25.25v4.4a.25.25 0 01-.458.139z\"/><circle cx=\"15.5\" cy=\"15\" r=\"1.75\"/><path d=\"M10.6 9.163a.248.248 0 01.345-.012.248.248 0 00.223.056A7.356 7.356 0 0112.5 9h.194a.25.25 0 00.227-.355 4.916 4.916 0 01-.415-2.1V5.3a5.3 5.3 0 10-10.594 0v1.248A4.573 4.573 0 011.27 9.06a1 1 0 001.68 1.085 5.131 5.131 0 00.455-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0zm-.649-2.917A2.707 2.707 0 017.209 8.6a2.7 2.7 0 01-2.74-2.35.5.5 0 01.323-.578 5.719 5.719 0 002.246-1.44.249.249 0 01.36 0 5.719 5.719 0 002.23 1.437.5.5 0 01.323.577z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});