define("ember-svg-jar/inlined/button-refresh-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-refresh-arrow</title><path d=\"M10.664 20.073a1.249 1.249 0 10-.507 2.447A10.739 10.739 0 102.4 16.1a.25.25 0 01-.084.3l-1 .726a1 1 0 00.377 1.787l3.946.849a1.062 1.062 0 00.21.022 1 1 0 00.978-.79l.945-4.4a1 1 0 00-1.565-1.02l-1.361.989a.25.25 0 01-.386-.128 8.255 8.255 0 116.205 5.643z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});