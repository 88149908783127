define("ember-svg-jar/inlined/social-media-orkut", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-orkut</title><path d=\"M12 0a12 12 0 1012 12A12 12 0 0012 0zm0 18.5a6.5 6.5 0 116.5-6.5 6.5 6.5 0 01-6.5 6.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});