define("ember-svg-jar/inlined/folder-music-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-music-1</title><path d=\"M22.5 4.5H11.736a.5.5 0 01-.447-.276l-.947-1.9A1.494 1.494 0 009 1.5H1.5A1.5 1.5 0 000 3v18a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 21V6a1.5 1.5 0 00-1.5-1.5zm-5.339 9.794a1.993 1.993 0 11-1.993-1.994 1.778 1.778 0 01.3.028c.064.011.19.006.19-.229V9.266a.485.485 0 00-.178-.379.494.494 0 00-.405-.106l-4.26 1.375a.539.539 0 00-.493.508v6.116a1.993 1.993 0 11-1.993-1.993 1.681 1.681 0 01.286.025c.07.012.207-.006.207-.206v-3.942a2 2 0 011.628-1.959l4.26-1.375a2.02 2.02 0 011.731.4 1.985 1.985 0 01.72 1.534z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});