define("ember-svg-jar/inlined/arrow-button-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-up</title><path d=\"M23.751 16.793L12.767 4.322a1.066 1.066 0 00-1.539 0L.249 16.793a.987.987 0 00.107 1.414l1.85 1.557a1.028 1.028 0 001.438-.111l8.165-9.453a.25.25 0 01.379 0l8.168 9.45a1.021 1.021 0 00.7.349 1 1 0 00.741-.238l1.851-1.558a.989.989 0 00.107-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});