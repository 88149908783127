define("ember-svg-jar/inlined/crafts-origami-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crafts-origami-alternate</title><path d=\"M18.633 15.664a.25.25 0 00.484.089l4.646-12.13c.236-.623.008-.939-.149-1.072s-.5-.306-1.081.028l-3.775 2.184a.251.251 0 00-.125.216zM2.768 8.638a.249.249 0 00-.4-.061L.5 10.446a.935.935 0 00-.311 1.044.935.935 0 00.961.517h2.966a.252.252 0 00.214-.121.249.249 0 00.007-.245zM11.034 5.356a.25.25 0 00-.028.32l5.672 8.124a.25.25 0 00.455-.143V1.466a.933.933 0 00-.517-.961.933.933 0 00-1.044.314zM11.605 8.717a.25.25 0 00-.455.144v10.885a.25.25 0 00.158.233l8.78 3.46c.074.03.72.3 1.065-.138.125-.158.277-.507-.113-1.067zM9.65 7.256a.25.25 0 00-.25-.25L4.15 7a.5.5 0 00-.444.732l5.472 10.474a.25.25 0 00.472-.116z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});