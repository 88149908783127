define("ember-svg-jar/inlined/style-three-pin-drawer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-drawer</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm5.5 14.25a.75.75 0 01-1.5 0V14a.5.5 0 00-.5-.5h-7a.5.5 0 00-.5.5v.25a.75.75 0 01-1.5 0V11a.5.5 0 01.5-.5h10a.5.5 0 01.5.5zM6.5 9V8a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5zm11 0a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5V8a.5.5 0 01.5-.5h4a.5.5 0 01.5.5zm1.25-3.25a.75.75 0 01-.75.75H6A.75.75 0 016 5h12a.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});