define("ember-svg-jar/inlined/standing-lamp-double", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>standing-lamp-double</title><path d=\"M16.365 8.048l1.564 3.652a.5.5 0 00.813.157l4.612-4.613a.5.5 0 00-.157-.813l-3.648-1.567a1 1 0 00-1.1.212l-1.872 1.872a1 1 0 00-.212 1.1z\"/><path d=\"M23 22h-9.5a.5.5 0 01-.5-.5v-18h.646a.5.5 0 01.363.154l1.824 1.907a.5.5 0 00.715.008l.707-.707a.5.5 0 00.008-.7L15.3 2.1a2.009 2.009 0 00-1.43-.6h-.62a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v3.25a.25.25 0 01-.25.25h-.909a2.019 2.019 0 00-1.621.83L6.756 7.518a.5.5 0 00.107.671l.788.619a.5.5 0 00.724-.115l1.318-1.971a.5.5 0 01.416-.222H11v15a.5.5 0 01-.5.5H1a1 1 0 000 2h22a1 1 0 000-2z\"/><path d=\"M6.321 15.393a.5.5 0 00.788-.252l1.118-3.809a1 1 0 00-.342-1.068L5.8 8.629a1 1 0 00-1.114-.079L1.25 10.538a.5.5 0 00-.059.826z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});