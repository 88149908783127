define("ember-svg-jar/inlined/canoe-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>canoe-1</title><path d=\"M23.726 2.125L21.874.274a.937.937 0 00-1.324 0l-2.16 2.16a2.245 2.245 0 00-.609 2.049.5.5 0 01-.136.456l-.917.916a.5.5 0 00-.13.48c.133.511.254 1.035.361 1.568a.249.249 0 00.421.127l1.677-1.676a.5.5 0 01.457-.135 2.238 2.238 0 002.051-.61l2.161-2.159a.938.938 0 000-1.325zM6.79 16.356a.25.25 0 00-.423-.134l-1.44 1.439a.5.5 0 01-.45.137 2.281 2.281 0 00-2.042.593L.274 20.55a.938.938 0 000 1.325l1.852 1.851a.937.937 0 001.324 0l2.16-2.16a2.245 2.245 0 00.609-2.049.5.5 0 01.136-.456L7 18.416a.5.5 0 00.131-.481q-.199-.759-.341-1.579zM12 0c-1.5 0-4.5 6.416-4.5 13.043S11 24 12 24s4.5-4.329 4.5-10.957S13.5 0 12 0zm2 15c0 1.656-1 3-2 3s-2-1.344-2-3 1-3 2-3 2 1.343 2 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});