define("ember-svg-jar/inlined/common-file-text-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-text-refresh</title><path d=\"M20 7.5a1 1 0 00-1-1h-7a1 1 0 000 2h7a1 1 0 001-1z\"/><path d=\"M23.414 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.8a.245.245 0 00.23.249c.35.02 1.016.069 1.487.14A.247.247 0 008 9.939V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-7.374a.243.243 0 00-.239.206 7.192 7.192 0 01-.408 1.453.244.244 0 00.225.34H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M14.906 13H16.5a1 1 0 000-2h-1.8a.293.293 0 00-.31.4 1.917 1.917 0 01.107.625v.525a.422.422 0 00.409.45zM12.92 11.56a.5.5 0 00-.545.108l-1.3 1.3a.246.246 0 01-.343 0 6.31 6.31 0 00-4.075-1.716A6.464 6.464 0 000 17.543 6.374 6.374 0 0011.357 21.6 1 1 0 109.8 20.354a4.376 4.376 0 11-3.42-7.1 4.34 4.34 0 012.92 1.118.256.256 0 010 .367l-1.382 1.386a.5.5 0 00.354.854h4.457a.5.5 0 00.5-.5v-4.458a.5.5 0 00-.309-.461z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});