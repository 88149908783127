define("ember-svg-jar/inlined/analytics-bars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>analytics-bars</title><path d=\"M1.25 17h3.5a.249.249 0 00.25-.25v-2a1 1 0 00-1-1H2a1 1 0 00-1 1v2a.249.249 0 00.25.25zM8 8.75a1 1 0 00-1 1v7a.249.249 0 00.25.25h3.5a.249.249 0 00.25-.25v-7a1 1 0 00-1-1zM14 10.75a1 1 0 00-1 1v5a.249.249 0 00.25.25h3.5a.249.249 0 00.25-.25v-5a1 1 0 00-1-1zM19.25 17h3.5a.249.249 0 00.25-.25v-12a1 1 0 00-1-1h-2a1 1 0 00-1 1v12a.249.249 0 00.25.25zM1 20.25h22a1 1 0 000-2H1a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});