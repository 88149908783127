define("ember-svg-jar/inlined/arrow-thick-up-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-up-3</title><path d=\"M21.666 8.393L12.655.339a1 1 0 00-1.333 0L2.33 8.4a1.006 1.006 0 00-.33.739v5.684a1 1 0 001.65.76l4.926-4.226a.25.25 0 01.413.19v11.368a1 1 0 001 1h4a1 1 0 001-1V11.544a.251.251 0 01.413-.19l4.948 4.235a1 1 0 001.65-.76v-5.69a1 1 0 00-.334-.746z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});