define("ember-svg-jar/inlined/gesture-swipe-vertical-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-vertical-2</title><path d=\"M12.591 12.686a.251.251 0 01.24-.179H17.4a1 1 0 000-2h-7.065a.25.25 0 01-.143-.455c2.138-1.49 1.449-3.852-.224-2.762L5.6 10.2a.5.5 0 00-.222.416v5.435a.5.5 0 00.41.492c1.1.2 3.684.665 4.166.776a1.281 1.281 0 001.519-.894zM16.308 22.17a1.5 1.5 0 01-1.874 0l-1.79-1.433a.25.25 0 00-.344.031.693.693 0 00-.136.232.75.75 0 00.238.834l2.5 2a.753.753 0 00.938 0l2.5-2a.752.752 0 00.239-.834.728.728 0 00-.136-.232.249.249 0 00-.344-.03zM14.434 1.828a1.5 1.5 0 011.874 0L18.1 3.26a.249.249 0 00.344-.031.717.717 0 00.135-.229.751.751 0 00-.239-.833l-2.5-2a.749.749 0 00-.938 0l-2.5 2a.749.749 0 00-.238.833.688.688 0 00.136.233.249.249 0 00.344.03z\"/><path d=\"M17.871 5.749a.751.751 0 00.469-1.335l-2.5-2a.749.749 0 00-.938 0l-2.5 2a.751.751 0 00.469 1.335h1.25a.25.25 0 01.25.25V7.5a1 1 0 002 0V6a.25.25 0 01.25-.25zM12.871 18.248a.751.751 0 00-.469 1.336l2.5 2a.753.753 0 00.938 0l2.5-2a.751.751 0 00-.469-1.336h-1.25a.25.25 0 01-.25-.25V16.5a1 1 0 10-2 0V18a.25.25 0 01-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});