define("ember-svg-jar/inlined/face-id-scan-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>face-id-scan-1</title><path d=\"M6.5 0A6.5 6.5 0 000 6.5a5.867 5.867 0 002.514 5.206.34.34 0 01.152.28v3.681A.333.333 0 003 16h6.666a.334.334 0 00.334-.333v-2.209a.333.333 0 01.333-.333h1A1.667 1.667 0 0013 11.458v-1.322a.332.332 0 01.332-.333c.125 0 .252 0 .367-.007a.835.835 0 00.644-.347.824.824 0 00.126-.495v-.091a5.225 5.225 0 00-.948-2.5c-.111-.194-.244-.426-.342-.619a1.343 1.343 0 01-.143-.555C13.038 2.323 10.805 0 6.5 0zM19.5 24a1 1 0 01-.863-1.5l3.5-6a1 1 0 111.727 1l-3.5 6a1 1 0 01-.864.5zM18.792 15.478a1 1 0 001.795-.882l-.662-1.346a1 1 0 10-1.794.882zM16.441 8.428a1 1 0 00-.456 1.339l.661 1.346a1 1 0 101.795-.883l-.662-1.346a1 1 0 00-1.338-.456zM17.469 20.008a1 1 0 00-.871-1.114l-1.489-.182a1 1 0 00-.243 1.985l1.489.182a1 1 0 001.114-.871zM10.281 18.121a1 1 0 00-.243 1.985l1.489.183a1 1 0 00.243-1.989z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});