define("ember-svg-jar/inlined/locker-room-hanger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>locker-room-hanger</title><path d=\"M24 17.573a1.917 1.917 0 00-1.065-1.724l-8.985-4.492a.25.25 0 01-.026-.432A3.5 3.5 0 108.5 8a1 1 0 002 0 1.5 1.5 0 111.986 1.42A2.2 2.2 0 0011 11.5a1 1 0 00.553.895l9.347 4.631a.25.25 0 01-.111.474H3.261a.25.25 0 01-.114-.473l5.808-2.963a1 1 0 10-.91-1.781l-6.992 3.568A1.93 1.93 0 001.93 19.5h20.143A1.93 1.93 0 0024 17.573z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});