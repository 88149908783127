define("ember-svg-jar/inlined/social-video-crunchyroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-video-crunchyroll</title><path d=\"M12 0a12 12 0 000 24h.75a.5.5 0 000-1A9.59 9.59 0 1123 12.76a.5.5 0 00.5.47.52.52 0 00.5-.47v-.75A12 12 0 0012 0z\"/><path d=\"M21.22 13.15a.5.5 0 00-.51 0 3.4 3.4 0 11-4-5.51.5.5 0 00-.12-.87 7.5 7.5 0 104.92 7v-.28a.5.5 0 00-.29-.34z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});