define("ember-svg-jar/inlined/earth-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earth-question</title><path d=\"M10.967 22.108a.5.5 0 00-.339-.206 9.975 9.975 0 01-2.987-.914.25.25 0 01-.141-.225v-1.15a2.492 2.492 0 01.893-1.913 4.482 4.482 0 001.264-1.775 7.868 7.868 0 01.266-.972 4.423 4.423 0 00.077-.7 4.505 4.505 0 00-4.5-4.5H2.584a.25.25 0 01-.241-.314 9.982 9.982 0 0114.546-6.157.25.25 0 01-.122.468H14.25a2.75 2.75 0 000 5.5 2.527 2.527 0 011.282.362.5.5 0 00.354.058A7.874 7.874 0 0117.5 9.5a7.97 7.97 0 015.977 2.7A.3.3 0 0024 12 12 12 0 107.341 23.059l.159.078v-.02A11.927 11.927 0 0012 24a.3.3 0 00.2-.524 7.968 7.968 0 01-1.233-1.368z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm0 11.5a1 1 0 111-1 1 1 0 01-1 1zm1.049-4.219a.5.5 0 00-.3.458v.011a.75.75 0 01-1.5 0v-.55a1.256 1.256 0 01.988-1.221 1.125 1.125 0 10-1.363-1.1.75.75 0 01-1.5 0 2.625 2.625 0 113.674 2.406z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});