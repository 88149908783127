define("ember-svg-jar/inlined/messages-bubble-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-empty</title><path d=\"M15.826 2.25H8.174a7.674 7.674 0 000 15.348H9v3.652a.5.5 0 00.863.344l3.785-4h2.178a7.674 7.674 0 000-15.348z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});