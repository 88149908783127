define("ember-svg-jar/inlined/amusement-park-entrance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>amusement-park-entrance</title><path d=\"M22.542 9.854a.253.253 0 01.025-.245 3.194 3.194 0 00.609-1.865 3.3 3.3 0 00-2.561-3.183.252.252 0 01-.159-.119.255.255 0 01-.023-.2 3.09 3.09 0 00.136-.9A3.355 3.355 0 0014.787 1.1a.25.25 0 01-.361 0 3.352 3.352 0 00-4.852 0 .25.25 0 01-.361 0 3.355 3.355 0 00-5.782 2.249 3.09 3.09 0 00.136.9.255.255 0 01-.023.2.252.252 0 01-.159.119A3.3 3.3 0 00.824 7.744a3.194 3.194 0 00.609 1.865.25.25 0 01.025.245A11.446 11.446 0 00.5 14.437v9a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-9a11.472 11.472 0 01.47-3.25.22.22 0 01.211-.158 3.353 3.353 0 002.424-1.034.256.256 0 01.182-.077.252.252 0 01.181.082 3.168 3.168 0 00.431.387.252.252 0 01.1.2v1.444a.25.25 0 01-.2.245 1 1 0 00-.8.98v3a1 1 0 001 1h9a1 1 0 001-1v-3a1 1 0 00-.8-.98.25.25 0 01-.2-.245v-1.446a.252.252 0 01.1-.2 3.168 3.168 0 00.433-.385.252.252 0 01.181-.079.256.256 0 01.182.077 3.353 3.353 0 002.424 1.037.22.22 0 01.211.158 11.472 11.472 0 01.47 3.25v9a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-9a11.446 11.446 0 00-.958-4.589zM4.181 9.532a1.79 1.79 0 110-3.577 1.79 1.79 0 110 3.577zm2.606-7.971a1.825 1.825 0 011.857 1.788 1.858 1.858 0 01-3.713 0 1.825 1.825 0 011.856-1.788zm5.213 0a1.825 1.825 0 011.856 1.788 1.857 1.857 0 01-3.712 0A1.825 1.825 0 0112 1.561zm7.069 1.788a1.858 1.858 0 01-3.713 0 1.858 1.858 0 013.713 0zM9.394 5.955a1.79 1.79 0 11-1.857 1.789 1.826 1.826 0 011.857-1.789zm5.212 3.577a1.79 1.79 0 111.857-1.788 1.825 1.825 0 01-1.857 1.788zm5.213-3.577a1.79 1.79 0 11-1.856 1.789 1.826 1.826 0 011.856-1.789zM9.75 12.254A.25.25 0 019.5 12v-.748a.251.251 0 01.226-.249 3.355 3.355 0 002.094-1.01.249.249 0 01.36 0 3.355 3.355 0 002.094 1.014.251.251 0 01.226.249V12a.25.25 0 01-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});