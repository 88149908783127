define("ember-svg-jar/inlined/business-climb-top", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-climb-top</title><path d=\"M22.256 2.312a.5.5 0 00-.391-.812h-5.734a.249.249 0 01-.245-.2 1 1 0 00-1.98.2v8.428a.255.255 0 01-.039.134l-2.681 4.257a.252.252 0 00.05.325l1.452 1.227a.251.251 0 00.338-.014l.642-.642a1.752 1.752 0 012.475 0l.643.641a.248.248 0 00.337.015l1.452-1.227a.252.252 0 00.05-.325l-2.681-4.257a.254.254 0 01-.038-.134V6.75a.25.25 0 01.25-.25h5.709a.5.5 0 00.391-.812l-1.1-1.376a.5.5 0 010-.624z\"/><path d=\"M.478 23.2a.575.575 0 00.506.3h21.61a1 1 0 00.847-1.532L19.7 16.029a.248.248 0 00-.173-.114.251.251 0 00-.2.057l-1.236 1.045a1.758 1.758 0 01-2.367-.1l-.643-.641a.25.25 0 00-.353 0l-.643.643a1.76 1.76 0 01-2.367.1l-1.236-1.045a.249.249 0 00-.373.057l-1.735 2.754a.25.25 0 01-.423 0L6.006 15.7a.729.729 0 00-1.156 0L.5 22.614a.575.575 0 00-.022.586z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});