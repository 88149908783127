define("ember-svg-jar/inlined/plane-boarding-pass-hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-boarding-pass-hand</title><path d=\"M7.815 17.01a.7.7 0 00-.18.48v1.82a3.663 3.663 0 01-.85 1.6 9.387 9.387 0 01-.71.809.753.753 0 001.07 1.061c.112-.116.375-.4.676-.766a.248.248 0 01.23-.09.251.251 0 01.2.152A1.488 1.488 0 009.625 23h11a1.5 1.5 0 001.5-1.5v-19a1.5 1.5 0 00-1.5-1.5h-3a1 1 0 00-.98.8 1.5 1.5 0 01-2.94 0 1 1 0 00-.98-.8h-3.1a1.5 1.5 0 00-1.5 1.5v5.3a.251.251 0 01-.1.2l-1.45 1.11c-1.4 1.007-2.717 1.958-2.717 3.564v4.646a.5.5 0 01-.093.291l-1.75 2.451a.75.75 0 101.22.871l1.984-2.777a.747.747 0 00.139-.436v-5.046c0-.8.8-1.412 2.094-2.347l.276-.2a.247.247 0 01.26-.021.251.251 0 01.137.223v2.013a.5.5 0 01-.146.354l-.834.834a.737.737 0 000 1.06.757.757 0 001.06 0L10.4 12.4a.516.516 0 01.72 0 .54.54 0 01-.04.76zM18.625 5a.75.75 0 11-.75.75.749.749 0 01.75-.75zm-3.5 0a.75.75 0 11-.75.75.749.749 0 01.75-.75zm-3.5 0a.75.75 0 11-.75.75.749.749 0 01.75-.75zm8.15 11.73L13.8 20.44a.346.346 0 01-.4-.01l-2.19-1.54a.2.2 0 01-.04-.28c.078-.077.878-.778.96-.85a.211.211 0 01.19-.03l.883.525a.5.5 0 00.542-.02l.884-.618a.25.25 0 00-.012-.417l-2.907-1.8a.384.384 0 01-.087-.571l.283-.335a.255.255 0 01.284-.074l4.477 1.669a.5.5 0 00.457-.055l1.556-1.065a.955.955 0 01.77-.149 1.049 1.049 0 01.75.68.956.956 0 01.06.33 1.035 1.035 0 01-.485.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});