define("ember-svg-jar/inlined/e-commerce-gift", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-gift</title><path d=\"M22 19h-.25a.25.25 0 01-.25-.25V15.5a1 1 0 00-2 0v2.75a.25.25 0 01-.25.25H15.5a1 1 0 00-1 1c0 .29-.876 1-2.5 1s-2.5-.71-2.5-1a1 1 0 00-1-1H4.75a.25.25 0 01-.25-.25v-7.583A.667.667 0 015.166 10H9.5a1 1 0 000-2H5.166A2.669 2.669 0 002.5 10.667v8.083a.25.25 0 01-.25.25H2a1.5 1.5 0 00-1.27 2.3A5.752 5.752 0 005.623 24h12.754a5.756 5.756 0 004.894-2.7A1.5 1.5 0 0022 19zM16.5 8a.251.251 0 00.177-.073.255.255 0 00.073-.177l-.008-3.25a.25.25 0 00-.25-.25H13.5a1.5 1.5 0 00-1.5 1.5v2a.25.25 0 00.25.25z\"/><path d=\"M18.5 9.5a.252.252 0 00-.177.074.249.249 0 00-.073.177L18.257 13a.249.249 0 00.25.249H21.5a1.5 1.5 0 001.5-1.5v-2a.25.25 0 00-.25-.25zM16.751 9.75a.25.25 0 00-.25-.25H12.25a.25.25 0 00-.25.25v2a1.5 1.5 0 001.5 1.5h3.007a.249.249 0 00.25-.25zM18.248 7.751A.249.249 0 0018.5 8h4.25a.25.25 0 00.25-.25v-2a1.5 1.5 0 00-1.5-1.5h-3.008a.251.251 0 00-.25.251zM17.5 4c.471 0 .419-.054 3.125-2.219a1 1 0 10-1.25-1.562L17.656 1.6a.25.25 0 01-.312 0L15.625.219a1 1 0 00-1.25 1.562C17.109 3.968 17.037 4 17.5 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});