define("ember-svg-jar/inlined/love-it-subtract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it-subtract</title><path d=\"M17.5 10.286a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.5 7.25h-5a.75.75 0 010-1.5h5a.75.75 0 010 1.5z\"/><path d=\"M5.811 2.777A4.575 4.575 0 019.805 4.06l1.532 1.4a1 1 0 001.347 0l1.561-1.424a4.548 4.548 0 013.966-1.256A4.5 4.5 0 0121.54 5.2a4.175 4.175 0 01-.135 4.056.249.249 0 00.107.348 7.972 7.972 0 011.241.754.254.254 0 00.2.044.25.25 0 00.165-.12 6.194 6.194 0 00.206-5.986 6.589 6.589 0 00-10.455-1.714l-.859.783-.828-.755A6.527 6.527 0 005.489.8 6.476 6.476 0 00.7 4.293a6.4 6.4 0 001.2 7.454L9 19.433a.25.25 0 00.434-.16l.066-2.129a.248.248 0 00-.066-.178l-6.1-6.609a4.42 4.42 0 01-.85-5.16 4.5 4.5 0 013.327-2.42z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});