define("ember-svg-jar/inlined/image-file-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-heart</title><path d=\"M13.187 4.045a1.493 1.493 0 101.493 1.493 1.492 1.492 0 00-1.493-1.493z\"/><path d=\"M23.415 3L21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v9.5a.27.27 0 00.082.224c.081.045.163.088.243.135a.527.527 0 00.333 0 5.051 5.051 0 011.175-.559.242.242 0 00.167-.231V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-7.531a.252.252 0 00-.207.111c-.237.334-.845 1.027-1.243 1.474a.249.249 0 00.188.414H22a2 2 0 002-2V4.414A2 2 0 0023.415 3z\"/><path d=\"M13.831 9.4a.375.375 0 00-.633 0l-1.236 1.977a.244.244 0 00.087.341 5.03 5.03 0 011.351 1.19.238.238 0 00.187.092h6.722a.186.186 0 00.158-.286l-3.232-5.178a.373.373 0 00-.633 0l-1.81 2.9a.187.187 0 01-.317 0zM12.628 14.449A3.506 3.506 0 009.581 12.5a3.665 3.665 0 00-2.693 1.127l-.211.211a.249.249 0 01-.353 0l-.211-.211A3.667 3.667 0 003.419 12.5a3.527 3.527 0 00-2.393 6.012l5.113 5.334a.5.5 0 00.723 0l5.106-5.327a3.524 3.524 0 00.66-4.07z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});