define("ember-svg-jar/inlined/history-woman-queen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-woman-queen</title><path d=\"M6.976 18.423a2.067 2.067 0 001.277-.923A6.521 6.521 0 0012 19.246a6.673 6.673 0 003.988-1.976 2.1 2.1 0 001.429 1.153c1.688.405 3.566-.887 4.169-1.634a.5.5 0 00-.232-.789c-1.326-.441-1.967-2.068-2.277-3.428a3.142 3.142 0 00.172-.938 2.016 2.016 0 00-.631-1.611l-.065-.2a.5.5 0 00.074-.207c.256-2.3.87-8.065.87-8.065a.5.5 0 00-.762-.477L15.282 3.23a.251.251 0 01-.322-.049L12.38.171a.517.517 0 00-.76 0l-2.58 3.01a.251.251 0 01-.322.049L5.265 1.072a.5.5 0 00-.762.48L5.416 9.6a.5.5 0 00.053.148l-.087.272a2.016 2.016 0 00-.631 1.611 3.109 3.109 0 00.423 1.485c-.351 1.22-.985 2.5-2.136 2.881a.5.5 0 00-.231.788c.585.729 2.479 2.044 4.169 1.638zM12 17.746c-1.029 0-3.816-1.49-4.191-3.738a1.75 1.75 0 00-1-1.306 1.281 1.281 0 01-.557-1.1.634.634 0 01.1-.44.744.744 0 00.378-.441l.306-.953a.249.249 0 01.158-.16A14.9 14.9 0 0116.8 9.6a.249.249 0 01.158.161l.309.961a.744.744 0 00.378.441.634.634 0 01.1.44 1.281 1.281 0 01-.557 1.1 1.75 1.75 0 00-1 1.3c-.372 2.253-3.159 3.743-4.188 3.743z\"/><path d=\"M12.741 14.324a1.966 1.966 0 01-1.634 0 .75.75 0 00-.711 1.321 3.436 3.436 0 003.06 0 .75.75 0 10-.715-1.318zM10.992 12.022a.751.751 0 00-.055-1.059 2.1 2.1 0 00-2.635 0 .75.75 0 101 1.114.637.637 0 01.627 0 .749.749 0 001.063-.055zM12.867 10.963a.75.75 0 001 1.114.637.637 0 01.627 0 .75.75 0 101-1.114 2.1 2.1 0 00-2.627 0zM23.2 24a.5.5 0 00.463-.688 4.5 4.5 0 00-2.579-2.526l-.054-.016-5-1.262a.5.5 0 00-.427.088l-3.452 2.656a.249.249 0 01-.3 0L8.39 19.588a.5.5 0 00-.428-.089L2.92 20.782a4.5 4.5 0 00-2.579 2.526A.5.5 0 00.8 24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});