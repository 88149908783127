define("ember-svg-jar/inlined/people-woman-7.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-7</title><path d=\"M21.8 7.29a.251.251 0 01-.2-.175 10.047 10.047 0 00-19.21 0 .251.251 0 01-.2.175A2.655 2.655 0 000 9.9v2.667a2.667 2.667 0 002.667 2.666A1.333 1.333 0 004 13.9V9.253C4 5.1 7.839 2.015 12 2.015s8 3.085 8 7.238v4.649a1.333 1.333 0 001.333 1.333A2.667 2.667 0 0024 12.569V9.9a2.655 2.655 0 00-2.2-2.61zM7.36 19.53C4.963 20.012.5 20.908.5 23.485a.5.5 0 00.5.5h22a.5.5 0 00.5-.5c0-2.577-4.466-3.473-6.875-3.958a.5.5 0 00-.48.175c-.112.136-.756 1.729-4.142 1.729-3.187 0-3.855-1.371-4.145-1.722a.519.519 0 00-.498-.179z\"/><path d=\"M14.858 18.592a7.052 7.052 0 00.737-.4.25.25 0 01.294.022 2.526 2.526 0 00.6.375 4.153 4.153 0 00.658.232 5.351 5.351 0 001.317.161 3.8 3.8 0 002.921-1.12.5.5 0 00-.231-.8c-2.71-.87-2.4-5.231-2.4-6.584a6.75 6.75 0 00-13.5 0c0 .659.431 5.615-2.4 6.525a.5.5 0 00-.231.8 3.8 3.8 0 002.921 1.12 5.4 5.4 0 001.317-.161 3.238 3.238 0 001.223-.579.248.248 0 01.289-.016 7.077 7.077 0 00.776.422 6.673 6.673 0 005.716 0zM6.75 12.485v-.943a.249.249 0 01.25-.25 7.177 7.177 0 004.94-1.7.1.1 0 01.126 0 7.248 7.248 0 005.085 1.7.1.1 0 01.1.1v1.089a5.25 5.25 0 01-10.5 0z\"/><circle cx=\"14\" cy=\"12.735\" r=\"1\"/><circle cx=\"10\" cy=\"12.735\" r=\"1\"/><path d=\"M12.943 16.23a2.309 2.309 0 00.441-.183.639.639 0 00-.307-1.2h-2a.639.639 0 00-.307 1.2 2.357 2.357 0 00.438.183 3.061 3.061 0 001.735 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});