define("ember-svg-jar/inlined/non-gmo-injection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>non-gmo-injection</title><path d=\"M23.707.293a1 1 0 00-1.414 0L16.086 6.5a.5.5 0 01-.758-.058 3.532 3.532 0 00-1.516-1.187.5.5 0 01-.312-.463V1a1 1 0 00-2 0v3.792a.5.5 0 01-.312.463A3.507 3.507 0 009 8.5v4.672a1 1 0 01-.293.707L.293 22.293a1 1 0 001.414 1.414l22-22a1 1 0 000-1.414zM17.5 18h-1a.5.5 0 01-.5-.5V17a.5.5 0 00-.5-.5H14a.5.5 0 010-1h1.5a.5.5 0 00.5-.5v-2.976a.25.25 0 00-.427-.176l-7.725 7.725a.25.25 0 00.176.427H10a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H8.5a1 1 0 000 2h8a1 1 0 000-2H15a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h2.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});