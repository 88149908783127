define("ember-svg-jar/inlined/security-officer-gate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>security-officer-gate</title><path d=\"M10.479 10.921a2.456 2.456 0 004.911.007l.022-.727a.251.251 0 00-.25-.258h-4.433a.249.249 0 00-.25.25zM8.566 8.476a.5.5 0 00.5.5c1.566 0 4.7-.011 6.262-.011a.5.5 0 00.441-.33l.322-.966a.991.991 0 00-.7-1.273l-4.921-1.23a.745.745 0 00-.887.958l.508 1.523a.25.25 0 01-.035.225.247.247 0 01-.2.1h-.79a.481.481 0 00-.161.033.5.5 0 00-.339.471z\"/><path d=\"M23.439 5.478L12.439.1a1.009 1.009 0 00-.878 0l-11 5.377a1 1 0 00-.561.9V23a1 1 0 001 1h22a1 1 0 001-1V6.377a1 1 0 00-.561-.899zm-6.55 16.272a.25.25 0 01-.25.25h-.478a.25.25 0 01-.25-.25v-3.395a.489.489 0 01.978 0zM22 21.5a.5.5 0 01-.5.5h-1.917a.249.249 0 01-.25-.25V18.6a3.911 3.911 0 00-3.911-3.911h-1.134a.5.5 0 00-.464.315l-.624 1.562a.257.257 0 01-.225.156.246.246 0 01-.225-.156l-.636-1.575a.476.476 0 00-.449-.3H9.844l-2.692-1.348a.735.735 0 01-.192-1.176l1.5-1.5a1.222 1.222 0 10-1.724-1.732l-1.5 1.5a3.178 3.178 0 00.826 5.09l3.986 1.992v4.233a.249.249 0 01-.25.25H2.5a.5.5 0 01-.5-.5V7.313a.5.5 0 01.28-.449l9.5-4.644a.507.507 0 01.44 0l9.5 4.644a.5.5 0 01.28.449z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});