define("ember-svg-jar/inlined/gymnastics-ribbon-person-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gymnastics-ribbon-person-1</title><path d=\"M8.194 6.775a.25.25 0 00-.411.184v.059a2.493 2.493 0 004.87.759.25.25 0 00-.325-.31 4.409 4.409 0 01-1.522.275 4.029 4.029 0 01-2.612-.967zM4.41 5.2a.251.251 0 00.322-.133l.9-2.1A1.732 1.732 0 017.2 2a.847.847 0 01.829 1.031 2.847 2.847 0 002.781 3.464 3.261 3.261 0 003.123-2.355l.132-.46a.943.943 0 01.9-.681.3.3 0 01.3.351 2.274 2.274 0 004.45.925.366.366 0 01.351-.275h.434a1 1 0 100-2h-.434a2.363 2.363 0 00-2.295 1.79.276.276 0 01-.538-.11A2.3 2.3 0 0014.963 1a2.951 2.951 0 00-2.827 2.13c-.163.57-.376 1.37-1.331 1.37a.848.848 0 01-.829-1.031A2.846 2.846 0 007.2 0a3.742 3.742 0 00-3.377 2.118L2.7 4.737a.249.249 0 00.284.342A2.4 2.4 0 014.41 5.2z\"/><path d=\"M21.477 16.647a1.5 1.5 0 10-1.954-2.275l-1.813 1.553a4.505 4.505 0 01-2.93 1.083H12.6a.25.25 0 01-.245-.2l-.521-2.5a.25.25 0 01.245-.3h1.336a4.5 4.5 0 002.88-1.042l2.166-1.8a1.5 1.5 0 00.192-2.11 1.515 1.515 0 00-2.112-.192l-2.166 1.8a1.5 1.5 0 01-.96.347H7a1.5 1.5 0 01-1.477-1.23l-.547-2.53a1.5 1.5 0 00-2.952.537l.552 2.533A4.5 4.5 0 007 14.011h1.518a.25.25 0 01.246.209l.433 2.6A.248.248 0 019 17.1a5.455 5.455 0 00-3.659 2.489l-1.329 2.12A1.5 1.5 0 006.555 23.3l1.326-2.118A2.485 2.485 0 0110 20.005h4.78a7.513 7.513 0 004.882-1.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});