define("ember-svg-jar/inlined/graph-stats-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>graph-stats-circle</title><path d=\"M19.354 14a3.08 3.08 0 01-2.854-1.567.248.248 0 00-.444.062l-1.576 5.014a1.538 1.538 0 01-2.9-.055L9.256 8.8a.25.25 0 00-.485.008 14.607 14.607 0 01-1.2 3.754A2.809 2.809 0 014.874 14H.168a12 12 0 0023.664 0z\"/><path d=\"M4.874 12c.935 0 1.012 0 1.913-3.565.206-.813.448-1.771.755-2.895A1.479 1.479 0 019 4.5a1.476 1.476 0 011.454 1.047l2.4 8.928a.249.249 0 00.479.01l1.346-4.285a1.479 1.479 0 011.348-.989 1.521 1.521 0 011.443.773c.1.194.2.383.285.565.581 1.171.75 1.448 1.6 1.448H24A12 12 0 000 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});