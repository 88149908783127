define("ember-svg-jar/inlined/medical-condition-head-pain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-condition-head-pain</title><path d=\"M18.022 12.425c0-3.945-3.635-6.3-7.151-6.3A7.414 7.414 0 003.4 13.64a6.6 6.6 0 002.721 5.768.5.5 0 01.214.41V23.5a.5.5 0 00.5.5h7.338a.5.5 0 00.5-.5v-1.936a.5.5 0 01.5-.5h.834a1.968 1.968 0 001.968-1.964v-1.194a.5.5 0 01.487-.5c.054 0 .106 0 .156-.006a1.042 1.042 0 00.811-.438 1.169 1.169 0 00.166-.713 5.816 5.816 0 00-1.062-2.827 6.376 6.376 0 01-.511-.997zM13.467 3.917a.282.282 0 01.032.455 1 1 0 001.279 1.537 2.279 2.279 0 00-.251-3.687A.278.278 0 0114.4 2a.274.274 0 01.1-.234A1 1 0 1013.216.232a2.28 2.28 0 00.251 3.685zM19.528 5.723A.277.277 0 0119.4 5.5a.273.273 0 01.1-.234 1 1 0 10-1.28-1.537 2.282 2.282 0 00.252 3.687.28.28 0 01.031.452 1 1 0 101.279 1.537 2.279 2.279 0 00-.25-3.685zM7.521 3.734A.167.167 0 017.54 4a1 1 0 001.279 1.541 2.165 2.165 0 00-.238-3.5.162.162 0 01-.078-.13.157.157 0 01.06-.14A1 1 0 007.283.232a2.167 2.167 0 00.238 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});