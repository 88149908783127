define("ember-svg-jar/inlined/3d-print-triangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>3d-print-triangle</title><path d=\"M8.47 8.28a.75.75 0 101.06-1.06L8.28 5.97a.75.75 0 00-1.06 1.06zM15 8.5a.744.744 0 00.53-.22l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25A.75.75 0 0015 8.5z\"/><path d=\"M22 0H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zM10 2a.5.5 0 01.5.5v2.367a.5.5 0 00.1.3l1 1.334a.5.5 0 00.8 0l1-1.334a.5.5 0 00.1-.3V2.5A.5.5 0 0114 2h7.5a.5.5 0 01.5.5v17a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-17a.5.5 0 01.5-.5zm3.25 20.75A.75.75 0 1114 22a.75.75 0 01-.75.75zm3.5 0a.75.75 0 11.75-.75.75.75 0 01-.75.75zm3.5 0A.75.75 0 1121 22a.75.75 0 01-.75.75z\"/><path d=\"M7 18h10a.5.5 0 00.424-.765l-5-8a.52.52 0 00-.848 0l-5 8A.5.5 0 007 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});