define("ember-svg-jar/inlined/disability-walking-help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-walking-help</title><circle cx=\"7.875\" cy=\"2.749\" r=\"2.5\"/><path d=\"M13.861 12.961a1.5 1.5 0 00-1.274-1.7C9.458 10.817 8.792 9.575 7.949 8c-.316-.581-.685-1.378-1.77-1.24A1.5 1.5 0 004.92 7.885l-3.5 14a1.5 1.5 0 002.91.728l1.248-4.99c.913 1.353 1.174 2.228 1.839 4.978a1.5 1.5 0 102.916-.7c-1.024-4.234-1.381-4.85-3.738-7.954a.251.251 0 01-.044-.212l.351-1.4a.25.25 0 01.41-.125 8.826 8.826 0 004.851 2.032 1.507 1.507 0 001.698-1.281zM21.729 20.481L20.2 13.6a2.982 2.982 0 00-2.929-2.35h-1.146a1 1 0 000 2h1.145a.993.993 0 01.976.784l.493 2.216h-1.3a3 3 0 00-2.911 2.273l-.487 1.949a1.75 1.75 0 102 .233l.425-1.7a1 1 0 01.971-.758h1.746l.543 2.441a1.749 1.749 0 102-.209z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});