define("ember-svg-jar/inlined/road-sign-divided-highway-ahead-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-divided-highway-ahead-1</title><path d=\"M11.481 23.785a.734.734 0 001.038 0l11.266-11.266a.733.733 0 000-1.038L12.519.215a.733.733 0 00-1.038 0L.215 11.481a.733.733 0 000 1.038zM7.118 12.953a.248.248 0 00-.073.176v.09a.252.252 0 00.074.177.245.245 0 00.177.072.732.732 0 01.527.215.747.747 0 010 1.058l-1 1a.755.755 0 01-.243.162.746.746 0 01-.572 0 .755.755 0 01-.243-.162l-1-1a.748.748 0 010-1.058.734.734 0 01.527-.215.249.249 0 00.251-.249v-.3a1.241 1.241 0 01.365-.882l1.557-1.556a.249.249 0 00.073-.177V8.228a.748.748 0 011.5 0v2.287a1.253 1.253 0 01-.365.881zm5.163 6.994a.748.748 0 01-.748-.748v-2.786a1.24 1.24 0 01.365-.881l3.552-3.551a.253.253 0 00.073-.177v-1.581a.249.249 0 00-.25-.25.748.748 0 01-.529-1.273l1-1a.751.751 0 01.814-.161.732.732 0 01.242.161l1 1a.747.747 0 010 1.057.73.73 0 01-.527.215.251.251 0 00-.178.073.247.247 0 00-.073.177v2a.745.745 0 01-.22.529l-3.7 3.7a.255.255 0 00-.073.177V19.2a.748.748 0 01-.748.747zM10.287 9.225V6.482a.249.249 0 01.249-.249h2.493a.25.25 0 01.25.249v2.743a1.5 1.5 0 01-2.992 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});