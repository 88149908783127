define("ember-svg-jar/inlined/minus-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.5 2H.5a.5.5 0 01-.5-.5v-1A.5.5 0 01.5 0h11a.5.5 0 01.5.5v1a.5.5 0 01-.5.5z\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "12",
      "height": "2",
      "viewBox": "0 0 12 2"
    }
  };
});