define("ember-svg-jar/inlined/asian-food-pho", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>asian-food-pho</title><path d=\"M6.857 11a1 1 0 01-.707-1.707 2.184 2.184 0 000-3.086 4.186 4.186 0 010-5.914 1 1 0 011.414 1.414 2.186 2.186 0 000 3.086 4.186 4.186 0 010 5.914 1 1 0 01-.707.293zM17.143 11a1 1 0 01-.707-1.707 2.186 2.186 0 000-3.086 4.186 4.186 0 010-5.914 1 1 0 011.414 1.414 2.184 2.184 0 000 3.086 4.187 4.187 0 010 5.914 1 1 0 01-.707.293zM12 11a1 1 0 01-.707-1.707 2.184 2.184 0 000-3.086 4.186 4.186 0 010-5.914 1 1 0 011.414 1.414 2.184 2.184 0 000 3.086 4.186 4.186 0 010 5.914A1 1 0 0112 11zM16.255 20.816a1 1 0 00-.794-.176 16.817 16.817 0 01-6.922 0 1 1 0 00-.794.176 6.883 6.883 0 00-1.567 1.614A1 1 0 007 24h10a1 1 0 00.823-1.569 6.883 6.883 0 00-1.568-1.615zM23.921 13.611A1 1 0 0023 13H1a1 1 0 00-.715 1.7A17.1 17.1 0 0012 19.5a17.1 17.1 0 0011.716-4.8 1 1 0 00.205-1.089z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});