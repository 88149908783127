define("ember-svg-jar/inlined/navigation-left-circle-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-left-circle-2-alternate</title><path d=\"M0 12A12 12 0 1012 0 12.014 12.014 0 000 12zm2 0a10 10 0 1110 10A10.011 10.011 0 012 12z\"/><path d=\"M4.393 10.9l4-4a.5.5 0 01.853.354v1.5A.25.25 0 009.5 9h5.75a3.99 3.99 0 01.407 7.961.5.5 0 01-.151-.987 1.25 1.25 0 00-.256-2.474H9.5a.25.25 0 00-.25.25v1.5a.5.5 0 01-.853.353l-4-4a.5.5 0 01-.004-.703z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});