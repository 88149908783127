define("ember-svg-jar/inlined/coffee-machine-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>coffee-machine-1</title><path d=\"M14.5 8.5a1 1 0 000 2h3a1 1 0 000-2z\"/><path d=\"M20.241 6.5l.346-.866A1 1 0 0121.516 5h.984A1.5 1.5 0 0024 3.5v-2A1.5 1.5 0 0022.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 000-3h-3.093a.246.246 0 01-.16-.436A2 2 0 0020 19v-4a.5.5 0 01.5-.5h.5a1 1 0 011 1v3.25a1 1 0 002 0V15.5a3 3 0 00-3-3h-9.691a.492.492 0 00-.425.257.563.563 0 00.013.582l.948 1.5a.992.992 0 01.155.534V19a2 2 0 00.753 1.564.246.246 0 01-.16.436H8.5a.5.5 0 01-.5-.5v-15a.5.5 0 01.5-.5h1.984a1 1 0 01.929.629l.346.866a1.507 1.507 0 001.359 1h5.764a1.507 1.507 0 001.359-.995zM6 15a.5.5 0 01-.5.5h-3A.5.5 0 012 15v-.75a.5.5 0 01.5-.5h1a.75.75 0 000-1.5h-1a.5.5 0 01-.5-.5v-1.5a.5.5 0 01.5-.5h1a.75.75 0 000-1.5h-1a.5.5 0 01-.5-.5v-1.5a.5.5 0 01.5-.5h1a.75.75 0 000-1.5h-1a.5.5 0 01-.5-.5V2.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});