define("ember-svg-jar/inlined/historical-building-castle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>historical-building-castle</title><path d=\"M5 11h14a2 2 0 002-2V7a1 1 0 00-1-1h-1a.5.5 0 00-.5.5V7a1 1 0 01-2 0v-.5A.5.5 0 0016 6h-2a.5.5 0 00-.5.5 1.467 1.467 0 01-.3.893.25.25 0 01-.449-.151V4.5a.5.5 0 01.5-.5h3.5a.5.5 0 00.354-.854l-.793-.792a.5.5 0 010-.708L17.1.854A.5.5 0 0016.75 0h-4.5a1 1 0 00-1 1v6.242a.25.25 0 01-.449.151 1.467 1.467 0 01-.3-.893A.5.5 0 0010 6H8a.5.5 0 00-.5.5V7a1 1 0 01-2 0v-.5A.5.5 0 005 6H4a1 1 0 00-1 1v2a2 2 0 002 2zM23 22h-2.735a.632.632 0 01-.595-.491l-1.679-8.592a.5.5 0 00-.491-.407h-11a.5.5 0 00-.491.407L4.33 21.509a.632.632 0 01-.6.491H1a1 1 0 100 2h8.5a.5.5 0 00.5-.5v-3a2 2 0 014 0v3a.5.5 0 00.5.5H23a1 1 0 100-2zm-10-6a1 1 0 01-2 0v-1a1 1 0 012 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});