define("ember-svg-jar/inlined/social-media-twitter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-twitter</title><path d=\"M23.32 6.44a.5.5 0 00-.2-.87l-.79-.2a.5.5 0 01-.33-.7l.44-.89a.5.5 0 00-.58-.7l-2 .56a.5.5 0 01-.44-.08 5 5 0 00-3-1 5 5 0 00-5 5v.36a.25.25 0 01-.22.25c-2.81.33-5.5-1.1-8.4-4.44a.51.51 0 00-.51-.15A.5.5 0 002 4a7.58 7.58 0 00.46 4.92.25.25 0 01-.26.36l-1.12-.22a.5.5 0 00-.57.59 5.15 5.15 0 002.37 3.78.25.25 0 010 .45l-.53.21a.5.5 0 00-.26.69 4.36 4.36 0 003.2 2.48.25.25 0 010 .47 10.94 10.94 0 01-4.29.83.5.5 0 00-.2 1 20.06 20.06 0 008.14 1.93 12.58 12.58 0 007-2A12.5 12.5 0 0021.5 9.06v-.87a.5.5 0 01.18-.38z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});