define("ember-svg-jar/inlined/livestock-lamb-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>livestock-lamb-alternate</title><path d=\"M15.507 15.072a2.872 2.872 0 01-.2.256 2.763 2.763 0 01-2.592.87 1.252 1.252 0 00-1.087.313 2.819 2.819 0 01-3.752 0 1.252 1.252 0 00-.852-.335 1.335 1.335 0 00-.235.022A2.769 2.769 0 013.5 13.5c0-.079 0-.158.01-.236a1.25 1.25 0 00-.314-.936 2.749 2.749 0 010-3.656 1.25 1.25 0 00.314-.936c-.01-.078-.01-.157-.01-.236a2.77 2.77 0 013.287-2.7 1.259 1.259 0 001.087-.313 2.757 2.757 0 014.062.335 5.8 5.8 0 011.98-1.531 5.263 5.263 0 00-7.27-1.028A5.267 5.267 0 001 7.417a5.252 5.252 0 000 6.166 5.248 5.248 0 002 4.033V21.5a1.25 1.25 0 002.5 0v-2.8a5.321 5.321 0 001.146.039 5.254 5.254 0 006.208 0c.048 0 .1.006.146.009V21.5a1.25 1.25 0 002.5 0v-3.259a5.23 5.23 0 002.919-3.836 3.964 3.964 0 01-2.065.845 2.451 2.451 0 01-.847-.178z\"/><path d=\"M23.808 9.455l-3.936-2.921a4.248 4.248 0 00-7.534 0L8.4 9.455a.376.376 0 00-.083.59 5.162 5.162 0 004.849 1.473c1.019 1.273 2.355 2.232 2.939 2.232s1.919-.959 2.938-2.232a5.159 5.159 0 004.848-1.473.375.375 0 00-.083-.59zM14.5 10a1 1 0 111-1 1 1 0 01-1 1zm3 0a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});