define("ember-svg-jar/inlined/rating-star-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rating-star-square</title><path d=\"M24 5a5.006 5.006 0 00-5-5H5a5.006 5.006 0 00-5 5v14a5.006 5.006 0 005 5h14a5.006 5.006 0 005-5zm-7.838 11.232a.422.422 0 01-.594.536l-3.446-1.941a.249.249 0 00-.245 0l-3.445 1.941a.423.423 0 01-.594-.537l1.425-3.277a.251.251 0 00-.063-.286l-2.447-2.181a.422.422 0 01.281-.737h2.907a.25.25 0 00.23-.151l1.441-3.344a.422.422 0 01.775 0L13.829 9.6a.25.25 0 00.23.151h2.907a.422.422 0 01.281.737l-2.447 2.18a.25.25 0 00-.063.286z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});