define("ember-svg-jar/inlined/weather-app-sun-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-app-sun-location</title><path d=\"M12.856 17.984a.25.25 0 00-.262-.17 5.845 5.845 0 114.7-8.294.25.25 0 00.281.138A6.454 6.454 0 0119 9.5h.076a.249.249 0 00.236-.34 7.844 7.844 0 10-6.051 10.583.25.25 0 00.182-.133.247.247 0 000-.226 10.929 10.929 0 01-.587-1.4z\"/><path d=\"M12 3.467a1 1 0 001-1V1a1 1 0 00-2 0v1.467a1 1 0 001 1zM4.552 5.966a1 1 0 101.414-1.414L4.929 3.515a1 1 0 00-1.414 1.414zM2.467 11H1a1 1 0 000 2h1.467a1 1 0 100-2zM4.552 18.034l-1.037 1.037a1 1 0 101.414 1.414l1.037-1.037a1 1 0 10-1.414-1.414zM12 20.533a1 1 0 00-1 1V23a1 1 0 002 0v-1.467a1 1 0 00-1-1zM18.741 6.259a1 1 0 00.707-.293l1.037-1.037a1 1 0 00-1.414-1.414l-1.037 1.037a1 1 0 00.707 1.707zM19 11a5.006 5.006 0 00-5 5c0 3.171 4.457 7.664 4.646 7.854a.5.5 0 00.708 0C19.543 23.664 24 19.171 24 16a5.006 5.006 0 00-5-5zm-1.75 5A1.75 1.75 0 1119 17.75 1.752 1.752 0 0117.25 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});