define("ember-svg-jar/inlined/multiple-users-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-users-3</title><path d=\"M14.957 16.207a1 1 0 000-1.414l-1.811-1.811a.5.5 0 01-.146-.353V10.75a1 1 0 00-2 0v1.879a.5.5 0 01-.146.353l-1.811 1.811a1 1 0 001.414 1.414l1.366-1.366a.25.25 0 01.354 0l1.366 1.366a1 1 0 001.414 0zM5.5 19.652a2.2 2.2 0 10-2.2-2.2 2.2 2.2 0 002.2 2.2zM5.5 20.452a3.592 3.592 0 00-3.41 2.47.25.25 0 00.237.328h6.344a.252.252 0 00.2-.1.249.249 0 00.035-.224A3.594 3.594 0 005.5 20.452z\"/><circle cx=\"12.002\" cy=\"2.949\" r=\"2.199\"/><path d=\"M12 5.947a3.594 3.594 0 00-3.411 2.47.25.25 0 00.238.328h6.346a.25.25 0 00.237-.328A3.592 3.592 0 0012 5.947zM18.5 19.652a2.2 2.2 0 10-2.2-2.2 2.2 2.2 0 002.2 2.2zM18.5 20.452a3.594 3.594 0 00-3.411 2.47.249.249 0 00.035.224.252.252 0 00.2.1h6.346a.25.25 0 00.237-.328 3.592 3.592 0 00-3.407-2.466z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});