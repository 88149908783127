define("ember-svg-jar/inlined/folder-file-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-file-1</title><path d=\"M23.273 12.116a1 1 0 00-.774-.366H10.2a1 1 0 01-.981-.8l-.278-1.397a1 1 0 00-.981-.8H1.5a1 1 0 00-.98 1.2l2.6 13a1 1 0 00.981.8H20.5a1 1 0 00.98-.8l2-10a1 1 0 00-.207-.837zM5.5 7.75a1 1 0 001-1v-4a.5.5 0 01.5-.5h12a.5.5 0 01.5.5v7a1 1 0 002 0v-7.5a2 2 0 00-2-2h-13a2 2 0 00-2 2v4.5a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});