define("ember-svg-jar/inlined/smart-watch-square-wifi-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-wifi-alternate</title><path d=\"M21 6a3 3 0 00-3-3h-1.078a.249.249 0 01-.24-.182L15.98.365A.5.5 0 0015.5 0h-7a.5.5 0 00-.481.363l-.7 2.455A.248.248 0 017.077 3H6a3 3 0 00-3 3v12a3 3 0 003 3h1.077a.249.249 0 01.24.181l.7 2.456A.5.5 0 008.5 24h7a.5.5 0 00.48-.363l.7-2.456a.25.25 0 01.24-.181H18a3 3 0 003-3zm-2 12a1 1 0 01-1 1H6a1 1 0 01-1-1V6a1 1 0 011-1h12a1 1 0 011 1z\"/><circle cx=\"12\" cy=\"15.002\" r=\"1\"/><path d=\"M16.47 9.634a7.067 7.067 0 00-8.941 0A.75.75 0 108.47 10.8a5.581 5.581 0 017.059 0 .75.75 0 10.941-1.167z\"/><path d=\"M14.8 12.044a4.431 4.431 0 00-5.607 0 .75.75 0 10.941 1.167 2.945 2.945 0 013.725 0 .75.75 0 10.941-1.167z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});