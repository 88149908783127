define("ember-svg-jar/inlined/shop-dislike-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shop-dislike-circle</title><path d=\"M21.952 8a3.69 3.69 0 01-2.7-1.162.251.251 0 00-.183-.079.248.248 0 00-.182.08 3.729 3.729 0 01-5.408 0 .251.251 0 00-.183-.08.254.254 0 00-.183.079 3.726 3.726 0 01-5.407 0 .247.247 0 00-.183-.079.25.25 0 00-.182.079A3.692 3.692 0 014.634 8a3.737 3.737 0 01-.788-.084.25.25 0 00-.3.245v1.067a.249.249 0 01-.107.205 8 8 0 1010.92 11.415.253.253 0 01.2-.1h6.7A1.753 1.753 0 0023 19.006l.035-10.843a.252.252 0 00-.3-.246 3.765 3.765 0 01-.783.083zM8 22a6 6 0 116-6 6.006 6.006 0 01-6 6z\"/><path d=\"M9.418 19.366l-.313-1.178a.2.2 0 01.037-.176.21.21 0 01.165-.08h1.735a1.205 1.205 0 001.177-1.476l-.7-2.344a2.206 2.206 0 00-2.153-1.842 35.9 35.9 0 01-.89-.012A5.939 5.939 0 004.8 13.2a.994.994 0 00-.556.9v2.656a1 1 0 001 1h.3a.208.208 0 01.17.088l1.73 2.439a1.106 1.106 0 001.974-.917zm-.6.66zM23.916 4.321L22.257.172A.3.3 0 0021.973 0H4.527a.3.3 0 00-.283.171L2.584 4.32a1.157 1.157 0 00-.009.843A2.135 2.135 0 004.59 6.5a2.074 2.074 0 002.137-2 .75.75 0 011.5 0 2.074 2.074 0 002.137 2 2.073 2.073 0 002.136-2 .75.75 0 011.5 0 2.141 2.141 0 004.273 0 .75.75 0 011.5 0 2.073 2.073 0 002.136 2 2.136 2.136 0 002.016-1.337 1.16 1.16 0 00-.009-.842z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});