define("ember-svg-jar/inlined/arrow-right-x", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-right-x</title><path d=\"M23.459 11.008l-6.618-7.5A1.5 1.5 0 0015.716 3H1.668a1.5 1.5 0 00-1.5 1.5v15a1.5 1.5 0 001.5 1.5h14.048a1.5 1.5 0 001.125-.507l6.619-7.5a1.505 1.505 0 00-.001-1.985zm-9.584 4.285a1 1 0 01-1.414 1.414l-3.117-3.116a.249.249 0 00-.353 0l-3.116 3.116a1 1 0 01-1.414-1.414l3.116-3.116a.252.252 0 000-.354L4.461 8.707a1 1 0 111.414-1.414l3.116 3.116a.249.249 0 00.353 0l3.117-3.116a1 1 0 011.414 1.414l-3.116 3.116a.25.25 0 000 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});