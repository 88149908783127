define("ember-svg-jar/inlined/religion-man-japan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-man-japan</title><path d=\"M12 13.5a4.988 4.988 0 01-4.689-3.334.249.249 0 00-.235-.166H5.484a.249.249 0 00-.2.1.252.252 0 00-.043.217 7 7 0 0013.514 0 .252.252 0 00-.043-.217.249.249 0 00-.2-.1h-1.588a.249.249 0 00-.235.166A4.988 4.988 0 0112 13.5z\"/><path d=\"M10.7 11.832a2.36 2.36 0 00.437.181 3 3 0 00.867.126h.006a3.02 3.02 0 00.865-.125 2.441 2.441 0 00.435-.181.639.639 0 00-.3-1.2H11a.639.639 0 00-.3 1.2zM.635 8.489A.984.984 0 001.506 9h20.988a.984.984 0 00.871-.511 1.017 1.017 0 00-.026-1.029C21.3 4.151 17.668 1.661 16.276.732A4.417 4.417 0 0013.812 0h-3.624a4.417 4.417 0 00-2.464.732C6.343 1.654 2.7 4.154.661 7.459a1.019 1.019 0 00-.026 1.03zM16.219 23.878a.251.251 0 00.216.122H23a.5.5 0 00.5-.5v-3.1a2.463 2.463 0 00-.967-1.978 11.994 11.994 0 00-2.45-1.4.25.25 0 00-.32.109l-3.548 6.5a.252.252 0 00.004.247zM8.052 16.057a.5.5 0 00-.467-.16 14.53 14.53 0 00-6.118 2.52A2.461 2.461 0 00.5 20.4v3.1a.5.5 0 00.5.5h7.326a.252.252 0 00.19-.087l3.066-3.56a.251.251 0 000-.33zM17.389 16.116a.252.252 0 00-.249.08l-6.364 7.391a.25.25 0 00.189.413h3.191a.251.251 0 00.22-.13l3.924-7.192a.252.252 0 00-.15-.361c-.245-.07-.499-.136-.761-.201z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});