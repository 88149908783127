define("ember-svg-jar/inlined/multiple-actions-player.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-player</title><path d=\"M13.884 10.5h4.393a.5.5 0 00.342-.864 6 6 0 00-6.6-1.1.5.5 0 00-.181.771 7.424 7.424 0 01.718 1.052.5.5 0 00.557.24 3.171 3.171 0 01.771-.099z\"/><ellipse cx=\"14.013\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M11.259 11.755a.5.5 0 00.055-.552A6.026 6.026 0 000 13.333.6.6 0 00.6 14h9.424a.5.5 0 00.5-.448 3.293 3.293 0 01.735-1.797z\"/><ellipse cx=\"5.513\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M22.115 12h-8.23A1.888 1.888 0 0012 13.885v8.23A1.888 1.888 0 0013.885 24h8.23A1.888 1.888 0 0024 22.115v-8.23A1.888 1.888 0 0022.115 12zM15.5 16.18a.931.931 0 011.408-.8l3.034 1.82a.929.929 0 010 1.594l-3.033 1.82a.924.924 0 01-.479.133.931.931 0 01-.93-.93z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});