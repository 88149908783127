define("ember-svg-jar/inlined/house-fire-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>house-fire-1</title><path d=\"M21.42 8.468a2.114 2.114 0 01-.5.08.25.25 0 01-.208-.406 9.958 9.958 0 001.814-7.5A.75.75 0 0021.123.4a1.625 1.625 0 01-1.213.933 10.274 10.274 0 00-5.86.879.252.252 0 01-.233 0 .249.249 0 01-.133-.191 3.757 3.757 0 00-.571-1.538.74.74 0 00-.675-.343.75.75 0 00-.629.419 2.259 2.259 0 01-1.151.815 6.134 6.134 0 00-3.68 3.6 4.745 4.745 0 00.463 3.623.324.324 0 00.51.05L8.9 7.631A2 2 0 0110.362 7a2 2 0 011.46.636c.887.948 4.069 4.327 6.141 6.526a.5.5 0 00.422.154 4.489 4.489 0 002.164-.79 5.281 5.281 0 001.832-4.353.75.75 0 00-.964-.7z\"/><path d=\"M11.092 8.316a1.035 1.035 0 00-1.459 0l-8 8.5a1 1 0 00.73 1.684h1.5a.5.5 0 01.5.5v4a1 1 0 001 1h10a1 1 0 001-1v-4a.5.5 0 01.5-.5h1.5a1 1 0 00.726-1.687s-6.647-7.054-7.997-8.497zM11.863 16a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});