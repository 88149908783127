define("ember-svg-jar/inlined/educative-toys-counting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>educative-toys-counting</title><path d=\"M22.25 20.5H1.75a1.75 1.75 0 000 3.5h20.5a1.75 1.75 0 000-3.5z\"/><rect x=\".75\" y=\"11.5\" width=\"6\" height=\"3.5\" rx=\".5\" ry=\".5\"/><rect x=\".75\" y=\"7\" width=\"6\" height=\"3.5\" rx=\".5\" ry=\".5\"/><rect x=\".75\" y=\"16\" width=\"6\" height=\"3.5\" rx=\".5\" ry=\".5\"/><rect x=\"17.75\" y=\"15.5\" width=\"5.75\" height=\"4\" rx=\".5\" ry=\".5\"/><path d=\"M18.75 2a1 1 0 011 1v11a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V3a3 3 0 00-6 0v7.75a2 2 0 01-4 0v-4a4.5 4.5 0 00-8.775-1.407A.5.5 0 003.45 6h1.073a.5.5 0 00.454-.291A2.5 2.5 0 019.75 6.75v4a4 4 0 008 0V3a1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});