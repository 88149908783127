define("ember-svg-jar/inlined/messages-bubble-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-image</title><path d=\"M12 1.337C5.659 1.337.5 5.587.5 10.811a8.6 8.6 0 003.175 6.54l-2.3 4.587a.5.5 0 00.659.677l6.149-2.862a13.733 13.733 0 003.817.532c6.341 0 11.5-4.25 11.5-9.474S18.341 1.337 12 1.337zm7.4 13.235a.5.5 0 01-.441.265h-11.5a.5.5 0 01-.395-.807l2.451-3.151a1.5 1.5 0 011.925-.328l1.428 1.088 1.359-1.749a1.5 1.5 0 012.392.087l2.755 4.08a.5.5 0 01.026.515zm-8.441-7.735a2 2 0 11-2-2 2 2 0 012 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});