define("ember-svg-jar/inlined/folder-share-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-share-alternate</title><path d=\"M2.5 2h4.573a.5.5 0 01.447.276L8.33 3.9A2 2 0 0010.118 5H19a.5.5 0 01.5.5v4.045a.247.247 0 00.314.24 9.865 9.865 0 011.465-.243.241.241 0 00.221-.242V5a2 2 0 00-2-2h-9.073a.5.5 0 01-.447-.276l-.81-1.619A2 2 0 007.382 0H2a2 2 0 00-2 2v14.5a2 2 0 002 2h7.292a.246.246 0 00.249-.222 9.893 9.893 0 01.238-1.464.247.247 0 00-.239-.314H2.5A.5.5 0 012 16V2.5a.5.5 0 01.5-.5z\"/><path d=\"M22.129 18.639a2.74 2.74 0 00-2.767.623.24.24 0 01-.258.053L16.553 18.3a.114.114 0 01-.06-.084l-.03-.353a.118.118 0 01.056-.1L19.442 16a.245.245 0 01.266.012 2.741 2.741 0 004.168-1.425A2.759 2.759 0 0021.25 11a2.808 2.808 0 00-2.713 3.059.243.243 0 01-.115.221L15.558 16a.245.245 0 01-.266-.012 2.741 2.741 0 00-4.168 1.426A2.759 2.759 0 0013.75 21a2.729 2.729 0 001.887-.761.245.245 0 01.26-.053l2.463 1a.239.239 0 01.144.214 2.753 2.753 0 105.361-1.025 2.681 2.681 0 00-1.736-1.736z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});