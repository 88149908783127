define("ember-svg-jar/inlined/server-choose", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-choose</title><path d=\"M4.938 8.941a.246.246 0 00.089-.191.25.25 0 00-.089-.192 3.992 3.992 0 01-1.077-1.412A.251.251 0 003.633 7H2a2 2 0 000 4h1.453a.25.25 0 00.239-.177 4.009 4.009 0 011.246-1.882zM4.941 15.441a.249.249 0 000-.382 3.986 3.986 0 01-1.432-2.824.25.25 0 00-.251-.235H2a2 2 0 000 4h2.285a.25.25 0 00.191-.089 4.3 4.3 0 01.465-.47zM3.5 18.5a3.941 3.941 0 01.181-1.176.248.248 0 00-.037-.222.251.251 0 00-.2-.1H2a2 2 0 000 4h1.915a.251.251 0 00.211-.385A3.91 3.91 0 013.5 18.5zM18.562 8.558a.25.25 0 00-.089.192.246.246 0 00.089.191 4.009 4.009 0 011.246 1.882.25.25 0 00.239.177H22a2 2 0 000-4h-2.133a.251.251 0 00-.228.146 3.992 3.992 0 01-1.077 1.412zM18.561 15.059a.251.251 0 000 .382 4.3 4.3 0 01.465.47.25.25 0 00.191.089H22a2 2 0 000-4h-1.758a.25.25 0 00-.249.235 3.986 3.986 0 01-1.432 2.824zM20 18.5a3.91 3.91 0 01-.626 2.115.251.251 0 00.211.385H22a2 2 0 000-4h-1.942a.251.251 0 00-.2.1.248.248 0 00-.037.222A3.941 3.941 0 0120 18.5zM7.5 8H16a2.5 2.5 0 000-5H7.5a2.5 2.5 0 000 5zM11 4.5a1 1 0 11-1 1 1 1 0 011-1zm-3.25 0a1 1 0 11-1 1 1 1 0 011-1zM7.5 14.5H16a2.5 2.5 0 000-5H7.5a2.5 2.5 0 000 5zM11 11a1 1 0 11-1 1 1 1 0 011-1zm-3.25 0a1 1 0 11-1 1 1 1 0 011-1zM16 21a2.5 2.5 0 000-5H7.5a2.5 2.5 0 000 5zm-5-3.5a1 1 0 11-1 1 1 1 0 011-1zm-3.25 0a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});