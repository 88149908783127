define("ember-svg-jar/inlined/module-three-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>module-three-1</title><path d=\"M11.871 6.936a.254.254 0 00.258 0l4.514-2.709a.249.249 0 00.121-.214.252.252 0 00-.121-.215l-4.128-2.477a1 1 0 00-1.029 0L7.357 3.8a.252.252 0 00-.121.215.249.249 0 00.121.214zM12.5 8.154v4.417a.249.249 0 00.127.218.252.252 0 00.252 0l4.5-2.7a.252.252 0 00.121-.215v-4.42a.248.248 0 00-.127-.217.252.252 0 00-.252 0l-4.5 2.7a.25.25 0 00-.121.217zM11.121 12.786a.252.252 0 00.252 0 .249.249 0 00.127-.218V8.154a.25.25 0 00-.121-.214l-4.5-2.7a.252.252 0 00-.252 0 .248.248 0 00-.127.217v4.414a.252.252 0 00.121.215zM13.236 14.013a.249.249 0 00.121.214l4.514 2.709a.254.254 0 00.258 0l4.514-2.709a.249.249 0 00.121-.214.252.252 0 00-.121-.215l-4.514-2.708a.254.254 0 00-.258 0l-4.514 2.71a.252.252 0 00-.121.213zM18.5 18.154v4.417a.249.249 0 00.127.218.252.252 0 00.252 0l4.136-2.489a1 1 0 00.485-.857v-3.989a.248.248 0 00-.127-.217.252.252 0 00-.252 0l-4.5 2.7a.25.25 0 00-.121.217zM12.627 15.237a.248.248 0 00-.127.217v4.417a.252.252 0 00.121.215l4.5 2.7a.252.252 0 00.252 0 .249.249 0 00.127-.218v-4.414a.25.25 0 00-.121-.214l-4.5-2.7a.252.252 0 00-.252-.003zM5.871 16.936a.254.254 0 00.258 0l4.514-2.709a.249.249 0 00.121-.214.252.252 0 00-.121-.215L6.129 11.09a.254.254 0 00-.258 0L1.357 13.8a.252.252 0 00-.121.215.249.249 0 00.121.214zM11.373 15.237a.252.252 0 00-.252 0l-4.5 2.7a.25.25 0 00-.121.214v4.417a.249.249 0 00.127.218.252.252 0 00.252 0l4.5-2.7a.252.252 0 00.121-.215v-4.417a.248.248 0 00-.127-.217zM.986 20.3l4.135 2.482a.252.252 0 00.252 0 .249.249 0 00.127-.218v-4.41a.25.25 0 00-.121-.214l-4.5-2.7a.252.252 0 00-.252 0 .248.248 0 00-.127.217v3.993a1 1 0 00.486.85z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});