define("ember-svg-jar/inlined/archive-folder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>archive-folder</title><path d=\"M22.5 14H19a1 1 0 00-1 1 1 1 0 01-1 1H7a1 1 0 01-1-1 1 1 0 00-1-1H1.5A1.5 1.5 0 000 15.5V22a2 2 0 002 2h20a2 2 0 002-2v-6.5a1.5 1.5 0 00-1.5-1.5zM9.5 12a1 1 0 000 2h5a1 1 0 000-2zM2.5 11a1 1 0 001-1h17a1 1 0 002 0 2 2 0 00-2-2h-17a2 2 0 00-2 2 1 1 0 001 1zM2.5 7a1 1 0 001-1h17a1 1 0 002 0 2 2 0 00-2-2h-17a2 2 0 00-2 2 1 1 0 001 1zM2.5 3a1 1 0 001-1h17a1 1 0 002 0 2 2 0 00-2-2h-17a2 2 0 00-2 2 1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});