define("ember-svg-jar/inlined/timer-10", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>timer-10</title><path d=\"M13.083 1.25A10.761 10.761 0 002.6 14.411a.254.254 0 01-.035.19.249.249 0 01-.16.109l-1.835.369a.5.5 0 00-.178.906L4.1 18.454a.5.5 0 00.694-.14L7.263 14.6a.5.5 0 00-.515-.767l-1.894.381a.25.25 0 01-.294-.191 8.756 8.756 0 118.523 6.724 1 1 0 100 2 10.75 10.75 0 000-21.5z\"/><path d=\"M10.482 7.116A1.507 1.507 0 008.9 7.3L7.208 8.647a1 1 0 101.25 1.561l.468-.375a.251.251 0 01.407.2v5.939a1 1 0 002 0v-7.5a1.508 1.508 0 00-.851-1.356zM15.833 16.968a3.505 3.505 0 003.5-3.5v-3a3.5 3.5 0 10-7 0v3a3.5 3.5 0 003.5 3.5zm-1.5-6.5a1.5 1.5 0 113 0v3a1.5 1.5 0 11-3 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});