define("ember-svg-jar/inlined/barbecue-steak", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>barbecue-steak</title><path d=\"M16.5 0h-6A10.512 10.512 0 000 10.5V19a5 5 0 0010 0 4 4 0 014-4h2.5a7.5 7.5 0 000-15zM5 20.5A1.5 1.5 0 116.5 19 1.5 1.5 0 015 20.5zm11.5-9H13c-6 0-5 3.5-7 3.5-1 0-2.5 0-2.5-2v-1.5a8 8 0 018-8h5a4 4 0 010 8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});