define("ember-svg-jar/inlined/underwear-female-set", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>underwear-female-set</title><path d=\"M5.055 9.376a1 1 0 00.6.316 16.293 16.293 0 002.23.2 6.092 6.092 0 003.815-1.21.5.5 0 01.6 0c2.069 1.553 4.253 1.255 6.044 1.01a1.007 1.007 0 00.6-.317c.9-.98 1.222-2.495.971-4.5a1.006 1.006 0 00-.583-.787 8.616 8.616 0 00-3.827-.812.25.25 0 01-.215-.389 11.077 11.077 0 00.831-1.46 1 1 0 10-1.807-.853 10.525 10.525 0 01-2.141 2.941.251.251 0 01-.35 0A10.462 10.462 0 019.686.574a1 1 0 10-1.809.852 11.077 11.077 0 00.831 1.46.25.25 0 01-.215.389 8.639 8.639 0 00-3.828.812 1.007 1.007 0 00-.582.788c-.251 2.006.076 3.525.972 4.501zM18.14 13H5.86a1 1 0 00-.925.62 13.168 13.168 0 00-.816 6.565 1 1 0 001.23.838 3.928 3.928 0 014.61 2.325A1 1 0 0010.9 24h2.2a1 1 0 00.937-.652 3.934 3.934 0 014.61-2.325 1 1 0 001.231-.838 13.167 13.167 0 00-.817-6.565 1 1 0 00-.921-.62z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});