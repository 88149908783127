define("ember-svg-jar/inlined/video-player-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-player-monitor</title><path d=\"M18.72 18.729A1.507 1.507 0 0017.434 18H6.566a1.507 1.507 0 00-1.286.729l-1.8 3A1.5 1.5 0 004.767 24h14.466a1.5 1.5 0 001.287-2.272zM17.25 21a.75.75 0 01-.75.75h-1a.75.75 0 010-1.5h1a.75.75 0 01.75.75zm-4 0a.75.75 0 01-.75.75h-1a.75.75 0 010-1.5h1a.75.75 0 01.75.75zm-5.75-.75h1a.75.75 0 010 1.5h-1a.75.75 0 010-1.5zM10.538 9.442l3.316-1.416a1.15 1.15 0 000-2.066l-3.315-1.415a.76.76 0 00-1.039.741V8.7a.76.76 0 001.038.742z\"/><path d=\"M23 2a2 2 0 00-2-2H3a2 2 0 00-2 2v12a2 2 0 002 2h18a2 2 0 002-2zm-2.5 0a.5.5 0 01.5.5V11a.5.5 0 01-.5.5h-17A.5.5 0 013 11V2.5a.5.5 0 01.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});