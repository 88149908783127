define("ember-svg-jar/inlined/single-neutral-actions-up-down.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-up-down</title><path d=\"M13.4 11.592a.25.25 0 00-.05-.436 60.382 60.382 0 00-1.855-.7l-.628-.23a.6.6 0 01-.138-.311 2.573 2.573 0 01.058-1.3A5.618 5.618 0 0012.256 4.3c0-2.419-1.579-4.175-3.756-4.175S4.744 1.881 4.744 4.3A5.605 5.605 0 006.2 8.6a2.453 2.453 0 01.073 1.316.615.615 0 01-.135.309l-.631.232c-2.447.9-4.215 1.55-4.754 2.626A9.173 9.173 0 000 16.625a.5.5 0 00.5.5h9.352a.25.25 0 00.247-.212 7.994 7.994 0 013.301-5.321zM17.944 19.4a.5.5 0 00-.444-.271h-1a.25.25 0 01-.25-.25v-5.25a1.25 1.25 0 00-2.5 0v5.25a.25.25 0 01-.25.25h-1a.5.5 0 00-.407.791l2.5 3.5a.5.5 0 00.814 0l1.127-1.578 1.373-1.922a.5.5 0 00.037-.52zM21.407 12.834a.519.519 0 00-.814 0l-1.127 1.578-1.373 1.922a.5.5 0 00.407.791h1a.25.25 0 01.25.25v5.25a1.25 1.25 0 002.5 0v-5.25a.25.25 0 01.25-.25h1a.5.5 0 00.407-.791z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});