define("ember-svg-jar/inlined/conversation-question-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>conversation-question-warning</title><path d=\"M9.5 12.5a3 3 0 013-3h7.75a.25.25 0 00.25-.25V1.5A1.5 1.5 0 0019 0H1.5A1.5 1.5 0 000 1.5V16a1.5 1.5 0 001.5 1.5h3V21a.5.5 0 00.854.353L9.207 17.5h.043a.25.25 0 00.25-.25zm.917-5.907a.25.25 0 00-.167.236V8a.75.75 0 01-1.5 0V6.829a1.757 1.757 0 011.166-1.651A1.25 1.25 0 108.25 4a.75.75 0 01-1.5 0 2.75 2.75 0 113.667 2.593z\"/><path d=\"M22.5 11h-10a1.5 1.5 0 00-1.5 1.5v7a1.5 1.5 0 001.5 1.5h3.833l3.867 2.9a.5.5 0 00.8-.4V21h1.5a1.5 1.5 0 001.5-1.5v-7a1.5 1.5 0 00-1.5-1.5zM18 16.5a.75.75 0 01-1.5 0V13a.75.75 0 011.5 0zm-.75 1.5a.75.75 0 11-.75.75.75.75 0 01.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});