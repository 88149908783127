define("ember-svg-jar/inlined/professions-man-telecommunicator.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-telecommunicator</title><path d=\"M.5 20.769v2.606a.5.5 0 00.5.5h8.648a.251.251 0 00.241-.181l1.63-5.706a.52.52 0 01.962 0l1.63 5.706a.251.251 0 00.241.181H23a.5.5 0 00.5-.5v-2.606a2.462 2.462 0 00-.967-1.977c-1.333-1.017-4.277-2.674-9.417-2.889a.249.249 0 01-.214-.36.942.942 0 00.1-.418 1 1 0 00-1-1 5.006 5.006 0 01-5-5v-.95a.251.251 0 01.243-.25 6.992 6.992 0 004.694-1.693.1.1 0 01.126 0 7 7 0 004.694 1.694.251.251 0 01.243.25v2.449a1 1 0 01-1 1H13a1 1 0 000 2h3a3 3 0 002.945-2.474.032.032 0 01.031-.026H19a2.5 2.5 0 000-5h-.026a.063.063 0 01-.062-.053A7 7 0 005 7.125v2a7 7 0 004.251 6.43.25.25 0 01-.071.478 15.417 15.417 0 00-7.713 2.759A2.462 2.462 0 00.5 20.769z\"/><circle cx=\"14\" cy=\"9.375\" r=\"1\"/><circle cx=\"10\" cy=\"9.375\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});