define("ember-svg-jar/inlined/arrow-thick-left-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-left-1</title><path d=\"M22.9 9.015H11.648a.25.25 0 01-.178-.426l3.337-3.37a1 1 0 00-.023-1.39l-2.756-2.754a1 1 0 00-1.414 0L.4 11.292a1 1 0 000 1.414l10.214 10.219a1 1 0 001.414 0l2.757-2.757a1 1 0 00.016-1.4l-3.293-3.347a.25.25 0 01.178-.421H22.9a1 1 0 001-1v-3.985a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});