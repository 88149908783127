define("ember-svg-jar/inlined/bread-slice-spread", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bread-slice-spread</title><path d=\"M18.5 22.976a1 1 0 01-1 1H5.252a1.5 1.5 0 01-1.1-.486 1.477 1.477 0 01-.374-1.166L4.9 13.067a.5.5 0 00-.372-.545A6.133 6.133 0 010 6.368 6.379 6.379 0 016.4.024h9.666a6.379 6.379 0 016.4 6.344 6.519 6.519 0 01-1.712 4.5A1 1 0 1119.3 9.5a4.53 4.53 0 001.169-3.132 4.378 4.378 0 00-4.4-4.344H6.4A4.378 4.378 0 002 6.368a4.075 4.075 0 004.068 4.345 1 1 0 01.993 1.12L5.9 21.416a.5.5 0 00.5.56h11.1a1 1 0 011 1z\"/><path d=\"M14.846 15.982a1 1 0 00-.638-.4 7.229 7.229 0 01-4.279-2.855.25.25 0 00-.383-.017 2.33 2.33 0 00.951 3.68 2.526 2.526 0 104.349-.406zM23.535 23.385a1 1 0 00.31-1.38L12.715 4.441a1 1 0 00-1.324-.341A3.635 3.635 0 009.5 7.445a7.665 7.665 0 003.667 6.184 4 4 0 001.934.536 3.189 3.189 0 00.9-.128.5.5 0 01.563.212l5.591 8.827a1 1 0 001.38.309z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});