define("ember-svg-jar/inlined/zip-file-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>zip-file-star</title><path d=\"M10.083 18.648A3.3 3.3 0 019.244 15a3.236 3.236 0 013.015-2h1.188a.251.251 0 00.229-.151l.825-1.91c.015-.036.032-.072.049-.107a3.365 3.365 0 013.15-1.856.251.251 0 00.3-.242V5.207a1.494 1.494 0 00-.439-1.06L13.856.44A1.5 1.5 0 0012.8 0H1.5A1.5 1.5 0 000 1.5v19A1.5 1.5 0 001.5 22h8.746a.246.246 0 00.233-.161c.159-.427.611-1.51.783-1.919a.243.243 0 00-.067-.278zM6 18.5a1 1 0 111-1 1 1 0 01-1 1zM6 15a1 1 0 111-1 1 1 0 01-.993 1zm0-3.5a1 1 0 111-1 1 1 0 01-.993 1zm1.5-4.9a1.968 1.968 0 01-1.478.67 1.977 1.977 0 01-2.004-2.221l.49-3.126A.5.5 0 015 1.5h2a.5.5 0 01.5.423l.485 3.111v.015A1.99 1.99 0 017.51 6.6z\"/><path d=\"M23.61 17.138a1.262 1.262 0 00.3-1.378 1.232 1.232 0 00-1.17-.76h-2.5a.25.25 0 01-.23-.151l-1.344-3.116-.013-.027a1.293 1.293 0 00-2.3 0l-.013.027-1.344 3.116a.249.249 0 01-.229.151h-2.5a1.234 1.234 0 00-1.169.762 1.264 1.264 0 00.3 1.378l2.213 1.972a.248.248 0 01.062.286l-1.223 2.815a1.256 1.256 0 00.315 1.451 1.278 1.278 0 001.506.166l3.113-1.753a.249.249 0 01.245 0l3.113 1.753a1.277 1.277 0 001.505-.166 1.257 1.257 0 00.316-1.451L21.334 19.4a.249.249 0 01.063-.286z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});