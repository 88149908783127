define("ember-svg-jar/inlined/dog-poodle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dog-poodle</title><path d=\"M22.883 18.789a4.561 4.561 0 01-.133-1.529v-2.5a5.218 5.218 0 00-.685-2.748 10.9 10.9 0 00.573-3.287 2.5 2.5 0 10-2 .123 8.381 8.381 0 01-.3 1.965 3.125 3.125 0 00-.587-.053H14.78a6.512 6.512 0 00-3.595-2.952A10.52 10.52 0 009.64 4.432a3.587 3.587 0 00.114-.9 3.5 3.5 0 10-7 0 3.6 3.6 0 00.068.693A5.682 5.682 0 01.5 5.1a.5.5 0 00-.5.5 3.334 3.334 0 00.905 2.5c.745.738 1.894 1.11 3.747 1.192A6.478 6.478 0 007 20.075v2.674a1.25 1.25 0 002.5 0v-2.321a6.478 6.478 0 004.89-2.668h5.866c.042 1.606.31 2.326 1.059 2.94a5.633 5.633 0 01.185 2.05 1.25 1.25 0 002.5 0c0-2.603-.357-3.35-1.117-3.961zM4.666 13.958a4.456 4.456 0 01.506-2.068A2.7 2.7 0 008 14.257c.916 0 3.558-.63 3.5-4.109a4.479 4.479 0 11-6.832 3.81zm-.412-8.895a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});