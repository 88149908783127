define("ember-svg-jar/inlined/arrow-button-circle-down-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-circle-down-1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm6.817 9.6l-6.433 7.719a.5.5 0 01-.768 0L5.183 9.6a.5.5 0 01.385-.82h2.687a.5.5 0 01.395.193l3.15 4.05a.249.249 0 00.394 0L15.35 8.97a.5.5 0 01.395-.193h2.687a.5.5 0 01.385.823z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});