define("ember-svg-jar/inlined/halloween-voodoo-doll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>halloween-voodoo-doll</title><path d=\"M18.57 12.443a.249.249 0 01.182-.426 5.161 5.161 0 10-5.058-5.058.25.25 0 01-.426.182L9.715 3.587a1.549 1.549 0 00-2.19 2.19l2.19 2.19a1.536 1.536 0 01.416.761.5.5 0 01-.746.535L5.911 7.177a.5.5 0 01-.241-.47 2.838 2.838 0 10-2.831 2.668 2.781 2.781 0 001.479-.429.5.5 0 01.522 0l7.545 4.531a.5.5 0 01-.516.857L8.25 12.157a.5.5 0 00-.611.075L3.875 16a1.549 1.549 0 002.19 2.19l2.555-2.559a1.032 1.032 0 011.46 1.46l-2.555 2.554a1.549 1.549 0 102.19 2.19L15.554 16a1.55 1.55 0 012.19 0l2.19 2.19A1.548 1.548 0 1022.123 16zm1.121-4.734a.5.5 0 01.5-.5h.1a.5.5 0 00.5-.5v-.095a.5.5 0 011 0v.095a.5.5 0 00.5.5h.1a.5.5 0 010 1h-.1a.5.5 0 00-.5.5v.1a.5.5 0 11-1 0v-.1a.5.5 0 00-.5-.5h-.1a.5.5 0 01-.5-.5zm-3.284-3.285a.5.5 0 01.5-.5H17a.5.5 0 00.5-.5V3.33a.5.5 0 011 0v.094a.5.5 0 00.5.5h.1a.5.5 0 010 1H19a.5.5 0 00-.5.5v.095a.5.5 0 11-1 0v-.095a.5.5 0 00-.5-.5h-.095a.5.5 0 01-.498-.5zM17.858 9.9a.5.5 0 01-.5-.5A1.155 1.155 0 0016.2 8.245a.5.5 0 010-1A2.156 2.156 0 0118.358 9.4a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});