define("ember-svg-jar/inlined/gymnastics-jump", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gymnastics-jump</title><path d=\"M4.5 2.75h14.966a.25.25 0 00.2-.393C18.787 1.091 16.05 0 11.981 0S5.174 1.091 4.29 2.357a.25.25 0 00.21.393zM4.124 8.75h15.713a.5.5 0 00.5-.537l-.262-3.5a.5.5 0 00-.5-.463H4.387a.5.5 0 00-.5.463l-.262 3.5a.5.5 0 00.5.537zm6.357-3h2.5a.75.75 0 110 1.5h-2.5a.75.75 0 010-1.5zM21.479 23.462l-.431-5.749a.5.5 0 00-.5-.463H3.412a.5.5 0 00-.5.463l-.39 5.211A1 1 0 003.52 24h17.461a.5.5 0 00.366-.16.5.5 0 00.132-.378zm-8.5-2.712h-2.5a.75.75 0 010-1.5h2.5a.75.75 0 110 1.5zM3.6 15.75h16.762a.5.5 0 00.5-.537l-.337-4.5a.5.5 0 00-.5-.463H3.937a.5.5 0 00-.5.463l-.337 4.5a.5.5 0 00.5.537zm6.882-3.5h2.5a.75.75 0 110 1.5h-2.5a.75.75 0 010-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});