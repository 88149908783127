define("ember-svg-jar/inlined/3d-pen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>3d-pen</title><path d=\"M22.608 1.65a4.352 4.352 0 00-5.645-.369A62.155 62.155 0 001.421 17.5a1.454 1.454 0 00.179 1.837l.605.605a.25.25 0 010 .354L.62 21.876a1 1 0 001.414 1.414l1.579-1.579a.25.25 0 01.355 0l.718.729a1.544 1.544 0 001.8 0A57.967 57.967 0 0022.955 6.49a3.763 3.763 0 00-.347-4.84zM5.218 15.179a.254.254 0 01.183-.107.248.248 0 01.2.072l3.241 3.24a.254.254 0 01.072.2.252.252 0 01-.108.183c-1.356.923-2.892 1.055-3.767.179-.567-.565-1.046-1.991.179-3.767zm5.067 2.11a.25.25 0 01-.382.035l-3.241-3.241A.25.25 0 016.7 13.7c1.366-.935 2.9-1.047 3.768-.179.565.567 1.043 1.992-.183 3.768zm9.194-8.212a3.356 3.356 0 01-4.744.1 3.357 3.357 0 01.1-4.744 3.356 3.356 0 014.744-.1 3.355 3.355 0 01-.1 4.744z\"/><path d=\"M15.963 5.561a1.689 1.689 0 102.386 2.387 1.689 1.689 0 10-2.386-2.387z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});