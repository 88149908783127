define("ember-svg-jar/inlined/shape-rhomboid-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shape-rhomboid-alternate</title><path d=\"M17.352 7.973a.247.247 0 00-.086.188V23a.25.25 0 00.41.192l.034-.029 2.526-2.19a3.247 3.247 0 001.014-2.223V5.25v-.121a.25.25 0 00-.413-.175zM7.726 6.938A.248.248 0 007.89 7h8.2a.252.252 0 00.164-.061l4.054-3.514a.221.221 0 00.075-.16.217.217 0 00-.067-.163 1.844 1.844 0 00-.08-.074L17.71.839A3.846 3.846 0 0015.463 0H8.516a3.838 3.838 0 00-2.247.842L3.761 3.028q-.041.034-.078.072a.218.218 0 00.008.322z\"/><rect x=\"8.266\" y=\"8.5\" width=\"7.5\" height=\"15.5\" rx=\".25\" ry=\".25\"/><path d=\"M6.766 8.205a.251.251 0 00-.086-.188L3.167 4.955a.249.249 0 00-.413.174V18.75a3.26 3.26 0 001.011 2.223l2.508 2.185c.029.026.06.052.092.077a.251.251 0 00.405-.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});