define("ember-svg-jar/inlined/parking-ramp-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>parking-ramp-1</title><path d=\"M23.846 18.426a.247.247 0 00-.272.054l-4.28 4.279a.25.25 0 00.091.411 1.046 1.046 0 00.369.067h3A1.249 1.249 0 0024 21.989v-3.333a.248.248 0 00-.154-.23zM23.846 13.435a.25.25 0 00-.272.054l-5 4.99a.249.249 0 00-.073.177v2.169a.25.25 0 00.426.177l5-4.994a.251.251 0 00.073-.177v-2.165a.251.251 0 00-.154-.231zM18.506 15.831a.25.25 0 00.426.176l4.606-4.6a.25.25 0 00.073-.188.247.247 0 00-.089-.18 1.186 1.186 0 00-.771-.287h-2.742a.254.254 0 01-.163-.06L8.542 1a1 1 0 00-1.3 1.517L18.419 12.1a.249.249 0 01.087.189zM5.244 21.24H1.249a.25.25 0 00-.25.25v.5a1.25 1.25 0 001.249 1.249h2a1.25 1.25 0 001.248-1.249v-.5a.25.25 0 00-.252-.25zM15.247 21.24h-3.995a.25.25 0 00-.25.25v.5a1.25 1.25 0 001.248 1.249h2a1.25 1.25 0 001.25-1.25v-.5a.25.25 0 00-.253-.249zM15.046 14.735a.5.5 0 01-.11-.167l-2.049-5.121A1.489 1.489 0 0011.5 8.5H5.166a1.493 1.493 0 00-1.392.942l-1.938 4.85a.5.5 0 01-.111.168L.219 15.966A.749.749 0 000 16.5V19a1.25 1.25 0 001.249 1.248h14a1.25 1.25 0 001.251-1.255V16.7a1.254 1.254 0 00-.366-.883zm-2.852-1.346a.25.25 0 01-.206.109H4.674a.248.248 0 01-.231-.342l1-2.5a.25.25 0 01.232-.157h5.315a.249.249 0 01.232.157l1 2.5a.248.248 0 01-.028.233z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});