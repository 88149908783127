define("ember-svg-jar/inlined/medical-instrument-tweezers-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-instrument-tweezers-2</title><path d=\"M6.405 7.177a.477.477 0 00-.139-.077L1.594 5.375a.813.813 0 00-.606 1.509l3.979 1.733a.512.512 0 01.148.1l9.771 9.447a.25.25 0 00.391-.3l-3.716-6.523a.5.5 0 00-.123-.144zM23.17 20.628L11.135 3.51a.481.481 0 00-.111-.114L7.023.43a.813.813 0 00-1 1.278L9.35 4.489a.5.5 0 01.115.136l10.368 18.208a2 2 0 103.337-2.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});