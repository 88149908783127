define("ember-svg-jar/inlined/startup-launch-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>startup-launch-1</title><path d=\"M21.013 15.854h1.7a1 1 0 00.184-1.983.25.25 0 01-.2-.2 1 1 0 00-1.983.183v.018a.015.015 0 01-.013.015 2.3 2.3 0 00-.962 4.186l1.84 1.227a.3.3 0 01-.168.554h-1.7a1 1 0 00-.184 1.984.25.25 0 01.2.2A1 1 0 0021.7 22a.25.25 0 01.2-.207 2.295 2.295 0 00.793-4.156l-1.84-1.227a.3.3 0 01.168-.555zM2.987 8.146H1.29a1 1 0 00-.183 1.983.252.252 0 01.2.2 1 1 0 001.97-.039.249.249 0 01.195-.207 2.295 2.295 0 00.793-4.156L2.425 4.7a.3.3 0 01.168-.555h1.7a1 1 0 00.184-1.983.252.252 0 01-.2-.2 1 1 0 00-1.983.183v.017a.016.016 0 01-.013.016 2.3 2.3 0 00-.962 4.186l1.84 1.227a.3.3 0 01-.168.554zM3.786 18.038c-1.052 1.052-1.29 3.5-1.341 4.329a.251.251 0 00.265.266c.827-.051 3.278-.289 4.33-1.341a2.233 2.233 0 00.011-3.265 2.472 2.472 0 00-1.671-.711 2.22 2.22 0 00-1.594.722zM8.725 12.034l-.217-.1a2.679 2.679 0 00-3.036.608l-1.29 1.367a.669.669 0 00.341 1.112l2.227.5v.006l2.8 2.8.5 2.228a.669.669 0 001.112.341l1.367-1.291a2.676 2.676 0 00.609-3.035l-.1-.216a.251.251 0 01.094-.313l5.272-3.365.009-.006a4.142 4.142 0 001.5-3.5c-.051-.447-.2-1.349-.323-2.118a.251.251 0 01.07-.219l.624-.624A1 1 0 0018.873 4.8l-.624.624a.249.249 0 01-.218.069 52.848 52.848 0 00-2.119-.322 4.144 4.144 0 00-3.5 1.5l-.006.01-3.369 5.259a.248.248 0 01-.312.094zm5.686-3.515a1.522 1.522 0 110 2.152 1.522 1.522 0 010-2.152z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});