define("ember-svg-jar/inlined/file-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-key</title><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM6.561 12.175l2.381 2.382a.625.625 0 01-.884.883l-1.806-1.8c-.04-.04-.127-.028-.127.075V15a.625.625 0 01-1.25 0V9a.625.625 0 011.25 0v1.241c0 .114.12.129.184.066l1.749-1.75a.625.625 0 01.884.883l-2.381 2.381a.25.25 0 000 .354zm4.815-.8H12.5a.625.625 0 010 1.25h-1.127a.248.248 0 00-.248.248V14a.375.375 0 00.375.375h2a.625.625 0 010 1.25h-2A1.626 1.626 0 019.875 14v-4A1.627 1.627 0 0111.5 8.373h2a.625.625 0 010 1.25h-2a.375.375 0 00-.375.375v1.124a.251.251 0 00.251.251zM20.125 10a2.622 2.622 0 01-1.825 2.489.247.247 0 00-.177.233V15a.625.625 0 01-1.25 0v-2.278a.247.247 0 00-.176-.233A2.621 2.621 0 0114.875 10V9a.625.625 0 111.25 0v1a1.375 1.375 0 002.75 0V9a.625.625 0 111.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});