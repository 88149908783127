define("ember-svg-jar/inlined/ecology-leaf-bulb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ecology-leaf-bulb</title><path d=\"M13.937 21.5h-3.874a.5.5 0 00-.484.625 2.5 2.5 0 004.842 0 .5.5 0 00-.484-.625zM12 0a8.75 8.75 0 108.75 8.75A8.76 8.76 0 0012 0zm4.313 10.8a3.092 3.092 0 01-1.774 2.493 2.057 2.057 0 01-.641.1 2.8 2.8 0 01-1.1-.248.5.5 0 01-.3-.481 12.075 12.075 0 011.43-5.407.5.5 0 10-.846-.533 13.061 13.061 0 00-1.385 8.348.5.5 0 01-.357.611.508.508 0 01-.127.016.5.5 0 01-.483-.374 9.014 9.014 0 01-.237-2.433.5.5 0 00-.495-.512 2.648 2.648 0 01-1.928-.692 3.068 3.068 0 01-.5-2.986 6.668 6.668 0 012.259-3.234 10.05 10.05 0 002.061-2.445 1 1 0 01.72-.51 1.007 1.007 0 01.844.258c2.006 1.886 3.396 5.788 2.859 8.029z\"/><path d=\"M14.5 18.5h-5a1 1 0 000 2h5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});