define("ember-svg-jar/inlined/wild-bird-eagle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wild-bird-eagle</title><path d=\"M20 8a4.008 4.008 0 00-3.08-3.894A5 5 0 007 5v12.745a2.5 2.5 0 001.5 2.288V22a1 1 0 000 2h2a1 1 0 000-2v-1.969a2.509 2.509 0 001.291-1.286h.418a2.509 2.509 0 001.291 1.286V22a1 1 0 000 2h2a1 1 0 000-2v-1.967a2.5 2.5 0 001.5-2.288V9h2a1 1 0 001-1zm-8-6a3 3 0 013 3 1 1 0 001 1 2 2 0 011.731 1H16a1 1 0 00-1 1v2.336l-.258.258a.5.5 0 01-.666.037l-1.451-1.162a1 1 0 00-1.25 0l-1.451 1.162a.5.5 0 01-.666-.037L9 10.336V5a3 3 0 013-3zM5.192 10.609C-.242 8.375.3 17.873 4.895 18.693a.51.51 0 00.605-.5v-7.117a.51.51 0 00-.308-.467z\"/><path d=\"M18.808 10.609a.51.51 0 00-.308.467V18.2a.51.51 0 00.605.5c4.595-.827 5.137-10.325-.297-8.091z\"/><circle cx=\"12.25\" cy=\"5\" r=\"1.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});