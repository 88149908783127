define("ember-svg-jar/inlined/style-one-pin-skull", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-skull</title><path d=\"M12 0a9.5 9.5 0 00-4 18.119 2.509 2.509 0 011.218 1.222l1.872 4.076a1 1 0 001.817 0l1.876-4.079A2.5 2.5 0 0116 18.118 9.5 9.5 0 0012 0zm6 10.5a3 3 0 01-2.143 2.875.5.5 0 00-.357.479v.646a1.5 1.5 0 01-.749 1.3.5.5 0 01-.751-.434V14a.5.5 0 00-1 0v1.5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V14a.5.5 0 00-1 0v1.366a.5.5 0 01-.751.432A1.5 1.5 0 018.5 14.5v-.646a.5.5 0 00-.357-.479A3 3 0 016 10.5V9a6 6 0 0112 0z\"/><circle cx=\"9\" cy=\"9.5\" r=\"1.5\"/><circle cx=\"15\" cy=\"9.5\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});