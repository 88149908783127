define("ember-svg-jar/inlined/religion-hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-hand</title><rect y=\"13.408\" width=\"4\" height=\"9\" rx=\"1\" ry=\"1\"/><path d=\"M23.853 17.617a3.237 3.237 0 00-3.49-1.127h-.015l-2 .639a.5.5 0 00-.348.485 2.25 2.25 0 01-2.249 2.29H10.5a.5.5 0 010-1h5.25a1.25 1.25 0 000-2.5l-3.685.008a4.339 4.339 0 01-.572-.383A6.836 6.836 0 007.5 14.408h-2a.5.5 0 00-.5.5v5.9a.5.5 0 00.339.474c.706.239 1.341.46 1.917.66 2.624.911 4.088 1.409 5.418 1.409 1.624 0 3.049-.742 6.139-2.386 1.281-.681 2.876-1.529 4.911-2.547a.5.5 0 00.129-.8zM13 6.65h2a.5.5 0 01.5.5v6a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-6a.5.5 0 01.5-.5h2a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-2a.5.5 0 01-.5-.5v-2a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v2a.5.5 0 01-.5.5h-2a.5.5 0 00-.5.5v2a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});