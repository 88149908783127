define("ember-svg-jar/inlined/vest-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vest-1</title><path d=\"M20.613 9.808a2.018 2.018 0 01-1.113-1.79V5.4a1 1 0 00-.758-.97l-1.363-.341A.5.5 0 0117 3.6v-.958a1.6 1.6 0 00-.021-.284v-.014A1.95 1.95 0 0015.781.8 9.407 9.407 0 008.242.792 1.959 1.959 0 007.024 2.34v.015A1.565 1.565 0 007 2.642V3.6a.5.5 0 01-.379.485l-1.363.341A1 1 0 004.5 5.4v2.618a2.018 2.018 0 01-1.113 1.79 1.493 1.493 0 00-.824 1.465c.168 2.046.635 7.642.825 9.927l.083.992a1 1 0 00.786.894 37.551 37.551 0 006.472.905.5.5 0 00.521-.5V4.368a.5.5 0 00-.391-.488c-.8-.179-2.1-.611-2.013-1.192a1.016 1.016 0 01.753-.8 7.82 7.82 0 014.813 0 1.011 1.011 0 01.742.8c.09.583-1.21 1.014-2.013 1.192a.5.5 0 00-.391.488v19.123a.5.5 0 00.521.5 37.574 37.574 0 006.473-.905 1 1 0 00.785-.9l.083-.99c.19-2.285.657-7.881.825-9.927a1.493 1.493 0 00-.824-1.461zM7.75 20.518a.75.75 0 01-1.5 0v-3a.75.75 0 011.5 0zm10 0a.75.75 0 01-1.5 0v-3a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});