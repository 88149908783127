define("ember-svg-jar/inlined/sky", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sky</title><path d=\"M23.823 17.593l-6.5-5.5a.5.5 0 00-.647 0l-6.5 5.5a.5.5 0 00.324.882h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5H15a.5.5 0 00.5-.5v-2.25a1.5 1.5 0 013 0v2.25a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-4.75a.25.25 0 01.25-.25h1.25a.5.5 0 00.323-.882zM10.5 11a3.5 3.5 0 001.138-6.8.252.252 0 01-.167-.209A4.5 4.5 0 002.722 3.1a.254.254 0 01-.15.157A4 4 0 004 11zM3.686 5.024a1 1 0 00.835-.856 2.5 2.5 0 114.953.691 1.01 1.01 0 00.253.8A1.035 1.035 0 0010.5 6a1.5 1.5 0 010 3H4a2 2 0 01-.314-3.976zM6.134 12.105a.517.517 0 00-.768 0l-1.75 2.1a.5.5 0 00.384.82h.5a.25.25 0 01.25.25v1.2a1 1 0 002 0v-1.2a.25.25 0 01.25-.25h.5a.5.5 0 00.384-.82zM7.5 20.9H7a.25.25 0 01-.25-.25v-1.2a1 1 0 00-2 0v1.2a.25.25 0 01-.25.25H4a.5.5 0 00-.384.821l1.75 2.1a.5.5 0 00.768 0l1.75-2.1A.5.5 0 007.5 20.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});