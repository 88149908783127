define("ember-svg-jar/inlined/cat-litter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cat-litter</title><path d=\"M11.849 9.378a.75.75 0 111.3.744l-1.86 3.255a.25.25 0 00.217.374h1.179a.251.251 0 00.215-.121l1.955-3.266a.75.75 0 111.286.772L14.8 13.373a.25.25 0 00.215.378h1.351a.5.5 0 00.441-.264l.721-1.354a3.962 3.962 0 00-.467-4.433l2.262-4.242a1.5 1.5 0 10-2.647-1.412l-2.262 4.241a3.957 3.957 0 00-3.946 2.086l-2.412 4.648a.5.5 0 00.444.73h.706a.251.251 0 00.217-.126z\"/><path d=\"M24 14.25a1.5 1.5 0 00-1.5-1.5H21a1.614 1.614 0 00-1.2.6l-.9 1.2a.649.649 0 01-.4.2h-13a.654.654 0 01-.4-.2l-.9-1.2a1.614 1.614 0 00-1.2-.6H1.5a1.5 1.5 0 000 3h.147l1.908 5.34a2.5 2.5 0 002.354 1.66h12.182a2.5 2.5 0 002.354-1.66l1.908-5.34h.147a1.5 1.5 0 001.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});