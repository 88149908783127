define("ember-svg-jar/inlined/volume-control-low-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>volume-control-low-1</title><path d=\"M14.941 3.017a9.73 9.73 0 00-7.924 4.849A.249.249 0 016.8 8h-.3a3 3 0 00-3 3v2a3 3 0 003 3h.3a.249.249 0 01.221.133 9.729 9.729 0 007.924 4.851h.06a1 1 0 001-1V4.015a1 1 0 00-1.064-.998zM18.639 7.982A1 1 0 0017.223 9.4a4.379 4.379 0 010 6.179 1 1 0 101.414 1.414 6.382 6.382 0 00.002-9.011z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});