define("ember-svg-jar/inlined/iris-scan-approved-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>iris-scan-approved-1</title><circle cx=\"11.999\" cy=\"7\" r=\"1.5\"/><path d=\"M12.291 11.437a.25.25 0 00-.173-.437h-.119a4 4 0 114-4 3.928 3.928 0 01-.8 2.375.25.25 0 00.264.392 7.926 7.926 0 015.591.573.25.25 0 00.266-.027 20.949 20.949 0 002.1-1.9 2.034 2.034 0 000-2.837C20.806 2.869 16.486 0 12.146 0h-.293C7.512 0 3.192 2.87.578 5.58a2.035 2.035 0 000 2.836c2.283 2.368 5.865 4.852 9.617 5.446a.248.248 0 00.259-.128 8.06 8.06 0 011.837-2.297z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm3.474 5.355l-2.9 3.874a1.751 1.751 0 01-2.64.185l-1.5-1.5a1 1 0 111.409-1.414l1.094 1.094a.25.25 0 00.377-.026l2.558-3.413a1 1 0 011.6 1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});