define("ember-svg-jar/inlined/swan-water", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>swan-water</title><path d=\"M1.23 10.658l1.683-1.4a3.962 3.962 0 01-1.5-.7L.27 9.507a.749.749 0 10.96 1.151z\"/><path d=\"M3.994 7.891a2.087 2.087 0 001.5-.556c1.584-1.583 2.889-2.191 3.768-2.191a1.125 1.125 0 011.229 1.192c0 1.5-1 3-4 5.495a7.8 7.8 0 00-3 4.493 5.052 5.052 0 00.13 1.141 2.5 2.5 0 013.461.79 2.111 2.111 0 001.485.873 2.8 2.8 0 001.887-1.016 2.5 2.5 0 014.283.389 1.278 1.278 0 001.05.627 1.886 1.886 0 001.448-.8 2.5 2.5 0 014.289-.07 2.643 2.643 0 00.344.379 11.835 11.835 0 002.105-5.806c0-.97.142-1.643-.512-2.008a1 1 0 00-1.01.022 28.437 28.437 0 01-3.973 2.035 17.417 17.417 0 01-6.494 1.449c-2 0-2.5-1-2-2s3.5-3 3.5-5.994c0-3.56-2-5-4-5a6.482 6.482 0 00-4.005 1.451c-.737.569-2.022.184-3.1 1.2a2.562 2.562 0 00-.346 3.052 2.381 2.381 0 001.961.853zm0-2.8a.749.749 0 11-.749.749A.749.749 0 014 5.087z\"/><path d=\"M24 21.543a1.016 1.016 0 00-1.067-.928c-1.576.122-2.665-1.555-2.673-1.568a1.043 1.043 0 00-1.718.029 3.358 3.358 0 01-2.747 1.551 2.8 2.8 0 01-2.4-1.482 1.043 1.043 0 00-1.716-.156 4.3 4.3 0 01-3.1 1.638 3.556 3.556 0 01-2.757-1.58 1.041 1.041 0 00-1.637-.091c-.014.018-1.469 1.8-3.106 1.7a.985.985 0 00-1.057.944c-.01.188.042 1.057 1.167 1.057a5.841 5.841 0 003.723-1.606 5.238 5.238 0 003.661 1.571 5.849 5.849 0 003.807-1.5 4.567 4.567 0 003.413 1.5 5.143 5.143 0 003.619-1.5 4.79 4.79 0 003.658 1.481 1 1 0 00.93-1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});