define("ember-svg-jar/inlined/vintage-tv-watch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-tv-watch</title><path d=\"M23.25 8a2.752 2.752 0 00-2.75-2.75h-5.581a.25.25 0 01-.161-.442l3.683-3.085A1 1 0 1017.059.278l-4.9 4.2a.251.251 0 01-.326 0l-4.9-4.2a1 1 0 10-1.374 1.445l3.683 3.085a.25.25 0 01-.161.442H3.5A2.752 2.752 0 00.75 8v11a2.754 2.754 0 001.481 2.439.5.5 0 01.269.443V23a1 1 0 002 0v-.75a.5.5 0 01.5-.5h14a.5.5 0 01.5.5V23a1 1 0 002 0v-1.118a.5.5 0 01.269-.443A2.754 2.754 0 0023.25 19zM16.5 8a3.009 3.009 0 013 3v5a3.009 3.009 0 01-3 3h-9a3.009 3.009 0 01-3-3v-5a3.009 3.009 0 013-3z\"/><path d=\"M14.786 12.124l-4.56-1.658A1.1 1.1 0 008.75 11.5v4a1.1 1.1 0 001.476 1.034l4.56-1.658a1.464 1.464 0 000-2.752z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});