define("ember-svg-jar/inlined/lucide-pizza", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15 11h.01M11 15h.01M16 16h.01M2 16l20 6-6-20c-3.36.9-6.42 2.67-8.88 5.12A19.876 19.876 0 002 16zM17 6c-6.29 1.47-9.43 5.13-11 11\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});