define("ember-svg-jar/inlined/diagram-arrow-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-arrow-right</title><path d=\"M15.815 19.417a1 1 0 001.081-.184l6.634-6.16a1.458 1.458 0 000-2.145l-6.63-6.16a1 1 0 00-1.681.732v2.986a.249.249 0 01-.25.25H1.5a1.5 1.5 0 00-1.5 1.5v3.5a1.5 1.5 0 001.5 1.5h13.465a.25.25 0 01.25.25V18.5a1 1 0 00.6.917z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});