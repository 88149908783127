define("ember-svg-jar/inlined/phone-action-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-cart</title><path d=\"M16.037 7.045a.751.751 0 00-.961.449L14.711 8.5h-5.93a1.247 1.247 0 00-1.043.562 1.223 1.223 0 00-.122 1.138L8.7 13.243A1.251 1.251 0 009.854 14h3.578a1.254 1.254 0 001.174-.823l1.88-5.171a.749.749 0 00-.449-.961z\"/><circle cx=\"10.532\" cy=\"15.75\" r=\"1\"/><circle cx=\"13.032\" cy=\"15.75\" r=\"1\"/><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});