define("ember-svg-jar/inlined/road-sign-side-road-right-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-side-road-right-1</title><path d=\"M.47 12.53l11 11a.748.748 0 001.06 0l11-11a.749.749 0 000-1.06l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06zm9-3.06l2-2a.751.751 0 011.06 0l2 2a.75.75 0 01-.53 1.28h-1a.25.25 0 00-.25.25v.586a.251.251 0 00.073.177l2.707 2.707a.75.75 0 11-1.06 1.06l-1.293-1.293a.25.25 0 00-.427.177V17a.75.75 0 01-1.5 0v-6a.25.25 0 00-.25-.25h-1a.75.75 0 01-.53-1.28z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});