define("ember-svg-jar/inlined/lucide-refresh-ccw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3 2v6h6\"/><path d=\"M21 12A9 9 0 006 5.3L3 8M21 22v-6h-6\"/><path d=\"M3 12a9 9 0 0015 6.7l3-2.7\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});