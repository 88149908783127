define("ember-svg-jar/inlined/nautic-sports-scooter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>nautic-sports-scooter</title><path d=\"M1.83 11.612h20.832a.5.5 0 00.492-.59 3.561 3.561 0 00-3.888-3.16h-8.025a.5.5 0 01-.388-.185l-.566-.7a.5.5 0 01.037-.671l.815-.8a.5.5 0 01.351-.144h2.01a1 1 0 000-2h-2.215a1.987 1.987 0 00-1.4.575l-.758.747a.5.5 0 01-.738-.041L7.153 3.13a.5.5 0 00-.387-.184c-1.247 0-3.255 1.71-4.469 4.464a.5.5 0 00.457.7H4a.75.75 0 010 1.5H1.977a.5.5 0 00-.491.4 12.28 12.28 0 00-.154 1.043.5.5 0 00.5.552zM21.846 13.112H2.681a.5.5 0 00-.4.794l1.154 1.586a.5.5 0 00.539.188 2.627 2.627 0 01.706-.1 2.47 2.47 0 011.9.857c.551.653 1 1.114 1.757 1.114s1.216-.472 1.738-1.09a2.528 2.528 0 011.938-.881 2.469 2.469 0 011.9.857c.551.653 1 1.114 1.758 1.114s1.216-.472 1.738-1.09a2.667 2.667 0 012.971-.642.5.5 0 00.593-.167l1.28-1.748a.5.5 0 00-.4-.795z\"/><path d=\"M23 19.054a3.744 3.744 0 01-2.9-1.645 1.056 1.056 0 00-1.546.022 3.717 3.717 0 01-2.884 1.623 3.742 3.742 0 01-2.9-1.645 1.055 1.055 0 00-1.547.023 3.717 3.717 0 01-2.884 1.622 3.744 3.744 0 01-2.9-1.645 1.055 1.055 0 00-1.547.023A3.715 3.715 0 011 19.054a1 1 0 000 2 5.121 5.121 0 003.346-1.243.5.5 0 01.639 0 5.129 5.129 0 006.694 0 .5.5 0 01.639 0 5.129 5.129 0 006.7 0 .5.5 0 01.639 0A5.125 5.125 0 0023 21.054a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});