define("@gavant/ember-shopify-draggable/components/draggable-item", ["exports", "@ember/component", "@gavant/ember-shopify-draggable/templates/components/draggable-item"], function (_exports, _component, _draggableItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _draggableItem.default,
    classNames: ['draggable-item']
  });
});