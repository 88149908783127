define("ember-svg-jar/inlined/discount-70", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>discount-70</title><path d=\"M11.97 10.285a.749.749 0 00-.748.748v2a.748.748 0 001.5 0v-2a.749.749 0 00-.752-.748z\"/><path d=\"M24 12a4.357 4.357 0 00-2.272-3.86.251.251 0 01-.12-.29 4.416 4.416 0 00-5.459-5.459.25.25 0 01-.289-.12 4.416 4.416 0 00-7.72 0 .249.249 0 01-.289.12 4.416 4.416 0 00-5.459 5.46.25.25 0 01-.12.289 4.415 4.415 0 000 7.72.25.25 0 01.12.289 4.416 4.416 0 005.459 5.46.25.25 0 01.289.12 4.415 4.415 0 007.72 0 .248.248 0 01.289-.119 4.416 4.416 0 005.459-5.459.251.251 0 01.12-.29A4.357 4.357 0 0024 12zm-8.041 3.272a.748.748 0 01-.59-1.207l3.492-4.489a.748.748 0 111.18.919l-3.491 4.488a.745.745 0 01-.591.289zm0-5.611a.873.873 0 11-.873.873.873.873 0 01.874-.873zm3.491 3.491a.873.873 0 11-.873.873.873.873 0 01.874-.873zm-7.481 2.12a2.248 2.248 0 01-2.245-2.244v-2a2.245 2.245 0 114.489 0v2a2.247 2.247 0 01-2.243 2.244zM6.962 14.8a.748.748 0 11-1.338-.67L7.4 10.586a.253.253 0 00-.011-.243.25.25 0 00-.213-.119H5.794a.748.748 0 010-1.5H7.98a1.246 1.246 0 011.116 1.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});