define("ember-svg-jar/inlined/style-two-pin-photography", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-two-pin-photography</title><path d=\"M12 0a9.511 9.511 0 00-9.5 9.5c0 6.547 6.538 12.488 8.541 14.153a1.5 1.5 0 001.917 0c2-1.665 8.542-7.608 8.542-14.154A9.511 9.511 0 0012 0zm5.5 12.5A1.5 1.5 0 0116 14H8a1.5 1.5 0 01-1.5-1.5v-4A1.5 1.5 0 018 7h.932a.253.253 0 00.208-.111l.3-.444A1 1 0 0110.268 6h3.464a1 1 0 01.832.445l.3.444a.25.25 0 00.204.111H16a1.5 1.5 0 011.5 1.5z\"/><circle cx=\"12\" cy=\"10.5\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});