define("ember-svg-jar/inlined/amusement-park-rollercoaster", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>amusement-park-rollercoaster</title><path d=\"M23 18.25c-.52 0-1.416-1.631-2.07-2.821C19.266 12.4 16.986 8.25 12 8.25s-7.266 4.15-8.93 7.179C2.416 16.619 1.52 18.25 1 18.25a1 1 0 000 2c1.626 0 2.659-1.716 3.563-3.375a.25.25 0 01.47.119v2.256a1 1 0 002 0v-6.2a.5.5 0 01.115-.32A8.044 8.044 0 018.6 11.339a.25.25 0 01.4.2v7.711a1 1 0 002 0v-8.7a.251.251 0 01.218-.248 6.394 6.394 0 011.564 0 .249.249 0 01.218.248v8.7a1 1 0 002 0v-7.708a.25.25 0 01.4-.2 8.044 8.044 0 011.456 1.386.5.5 0 01.115.32v6.202a1 1 0 002 0V17a.25.25 0 01.47-.119c.878 1.611 1.941 3.374 3.563 3.374a1 1 0 000-2zM11.313 6.75h4.5a.5.5 0 00.5-.5 2.5 2.5 0 00-2.5-2.5h-2.5a1 1 0 00-1 1v1a1 1 0 001 1zM4.639 8.374a.994.994 0 00.585.493 1.013 1.013 0 00.306.048.99.99 0 00.456-.115L9.1 7.2a1 1 0 00.43-1.347l-.46-.888a1 1 0 00-1.347-.43l-3.112 1.6a1 1 0 00-.43 1.347zM.48 13.4l.855.52a1 1 0 001.374-.335l1.818-2.99a1 1 0 00-.334-1.374L3.338 8.7a1 1 0 00-1.374.335L.145 12.024A1 1 0 00.48 13.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});