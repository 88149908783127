define("ember-svg-jar/inlined/road-sign-side-road-angle-right-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-side-road-angle-right-2</title><path d=\"M23.78 12.53a.749.749 0 000-1.06L12.53.22a.749.749 0 00-1.06 0L.22 11.47a.749.749 0 000 1.06l11.25 11.25a.748.748 0 001.06 0zm-7.08-1.064a.5.5 0 01-.271.731l-3.738 1.246a.25.25 0 00-.171.237V16a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5V8a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2.153a.25.25 0 00.1.2.248.248 0 00.225.034l2.291-.763a.5.5 0 01.587.217z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});