define("@gavant/ember-shopify-draggable/components/draggable-container", ["exports", "@ember/component", "@gavant/ember-shopify-draggable/templates/components/draggable-container", "@gavant/ember-shopify-draggable/mixins/base-container"], function (_exports, _component, _draggableContainer, _baseContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_baseContainer.default, {
    layout: _draggableContainer.default,
    classNames: ['draggable-container']
  });
});