define("ember-svg-jar/inlined/equalizer-stereo-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>equalizer-stereo-1</title><path d=\"M22.5 14.5h-21A1.5 1.5 0 000 16v6a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 22v-6a1.5 1.5 0 00-1.5-1.5zm-5 4v1a1 1 0 01-1 1h-10a1 1 0 01-1-1v-1a1 1 0 011-1h10a1 1 0 011 1zM14 13.5h7.5a.5.5 0 00.5-.5V2A1.5 1.5 0 0020.5.5H15A1.5 1.5 0 0013.5 2v11a.5.5 0 00.5.5zm3.75-5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zM16.25 5a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5zM2.5 13.5H10a.5.5 0 00.5-.5V2A1.5 1.5 0 009 .5H3.5A1.5 1.5 0 002 2v11a.5.5 0 00.5.5zm3.75-5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zM4.75 5a1.5 1.5 0 111.5 1.5A1.5 1.5 0 014.75 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});