define("ember-svg-jar/inlined/zoom-out-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>zoom-out-1</title><path d=\"M23.414 20.59L19.2 16.376a10.5 10.5 0 10-2.828 2.824l4.214 4.213a2.023 2.023 0 002.828 0 2 2 0 000-2.823zM10.5 2A8.5 8.5 0 112 10.5 8.51 8.51 0 0110.5 2z\"/><path d=\"M7 11.5h7a1 1 0 000-2H7a1 1 0 100 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});