define("ember-svg-jar/inlined/cocktail-cherry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cocktail-cherry</title><path d=\"M23.313.684a2.344 2.344 0 00-3.31 0 2.333 2.333 0 00-.685 1.656 2.222 2.222 0 00.064.523.5.5 0 01-.132.472l-2.816 2.814a.5.5 0 01-.609.076 4.587 4.587 0 00-5.625.457 4.777 4.777 0 00.4 6.72 4.778 4.778 0 006.721.4 4.588 4.588 0 00.456-5.626.5.5 0 01.075-.61l2.815-2.814a.5.5 0 01.472-.132 2.216 2.216 0 00.523.064 2.342 2.342 0 001.655-4zm-9.949 8.1a2.382 2.382 0 00-.617.444 2.307 2.307 0 00-.461.654.75.75 0 01-1.356-.642 3.851 3.851 0 011.766-1.8.75.75 0 11.668 1.342z\"/><path d=\"M3.836 13.045a4.587 4.587 0 00-.456 5.627.5.5 0 01-.075.61L.293 22.293a1 1 0 001.414 1.414L4.719 20.7a.5.5 0 01.61-.075 4.587 4.587 0 005.624-.458 4.777 4.777 0 00-.4-6.721 4.779 4.779 0 00-6.717-.401zM7 15.149a2.358 2.358 0 00-1.079 1.1.75.75 0 11-1.354-.649 3.859 3.859 0 011.764-1.795A.75.75 0 117 15.149z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});