define("ember-svg-jar/inlined/style-three-pin-diving", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-diving</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm3 3.5a1.25 1.25 0 11-1.25 1.25A1.25 1.25 0 0115 3.5zm-2.5 6a2.5 2.5 0 015 0v3a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5zM9 3.5a1.25 1.25 0 11-1.25 1.25A1.25 1.25 0 019 3.5zM9 7a2.5 2.5 0 012.5 2.5v3a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5v-3A2.5 2.5 0 019 7zm2.5 8a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5A.5.5 0 017 14h4a.5.5 0 01.5.5zm5 1h-3a1 1 0 01-1-1v-.5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v.5a1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});