define("ember-svg-jar/inlined/champagne-bottle-glass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>champagne-bottle-glass</title><path d=\"M4.973 10.5a3.325 3.325 0 002.661 1.25h.014a3.328 3.328 0 002.66-1.237c.1-.131.365-.472.565-.721a.5.5 0 00.057-.537 11.774 11.774 0 01-1.3-5.387V1.5A1.5 1.5 0 008.125 0h-1a1.5 1.5 0 00-1.5 1.5v2.368a11.833 11.833 0 01-1.281 5.344.5.5 0 00.056.538c.205.262.486.633.573.75zm2.652-3a1 1 0 11-1 1 1 1 0 011-1z\"/><path d=\"M11.743 11.338a.265.265 0 00-.253.1 4.821 4.821 0 01-3.842 1.812h-.02a4.818 4.818 0 01-3.844-1.839.276.276 0 00-.479.076 11.188 11.188 0 00-.68 3.771V21.5a2.5 2.5 0 002.5 2.5h5a2.5 2.5 0 002.5-2.5v-6.242a11.183 11.183 0 00-.674-3.746.264.264 0 00-.208-.174zM20.125 22.5h-1.25a.5.5 0 01-.5-.5v-1.688a.5.5 0 01.353-.478 3.756 3.756 0 002.647-3.584c0-.936-.81-6.728-.945-7.658A1.242 1.242 0 0019.2 7.5h-3.14a1.236 1.236 0 00-1.232 1.072c-.135.932-.948 6.739-.948 7.678a3.756 3.756 0 002.647 3.584.5.5 0 01.353.478V22a.5.5 0 01-.5.5h-1.25a.75.75 0 000 1.5h5a.75.75 0 000-1.5zM16.216 9.43a.5.5 0 01.495-.43h1.828a.5.5 0 01.495.43c.06.421.131.94.208 1.5a.5.5 0 01-.5.567H16.5a.5.5 0 01-.5-.567c.084-.557.156-1.075.216-1.5zm.659 8.07a.75.75 0 11.75-.75.749.749 0 01-.75.75zm.75-3.75a.75.75 0 11.75.75.75.75 0 01-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});