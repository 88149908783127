define("ember-svg-jar/inlined/gymnastics-rhythmic-ribbon-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gymnastics-rhythmic-ribbon-1</title><path d=\"M18.743 3.737C15.6 1.069 11.274.362 9.664 2.258a1 1 0 101.523 1.3c.627-.736 3.63-.528 6.261 1.709a24.086 24.086 0 011.861 1.719.25.25 0 01-.3.394 11.474 11.474 0 01-1.579-1.012 1 1 0 00-1.2 1.6c.711.534 2.684 1.9 4.2 1.9a1.535 1.535 0 001.446-.768c.39-.6.5-1.581-.732-3.06a20.534 20.534 0 00-2.401-2.303zM23.4 2.694A11.285 11.285 0 0018.5.012a1 1 0 00-.3 1.976 9.4 9.4 0 013.9 2.231 1 1 0 001.3-1.525zM9.24 5.247C7.158 4.8 5.458 5.21 4.451 6.394 2.7 8.451 3.744 12.191 6.78 15.33a.5.5 0 010 .7L.541 22.294a1 1 0 101.418 1.411l7.323-7.359a1 1 0 00-.061-1.467c-3.2-2.721-4.281-5.973-3.246-7.19.5-.588 1.536-.766 2.847-.486 3.121.666 7.176 4.356 8.132 5.7a.25.25 0 01-.251.39 9.965 9.965 0 01-5.283-3.577 1 1 0 00-1.715 1.029 11.254 11.254 0 007.083 4.561 2.116 2.116 0 001.977-.742 2.011 2.011 0 00.4-1.413c-.219-2.497-6.772-7.231-9.925-7.904z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});