define("ember-svg-jar/inlined/road-sign-lane-narrowing-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-lane-narrowing-left</title><path d=\"M23.53 12.53a.749.749 0 000-1.06l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0zM8.25 12.5a.758.758 0 01.171-.478l2.272-2.754a.248.248 0 00.057-.159V6.5a.75.75 0 011.5 0v2.793a1.244 1.244 0 01-.366.884L9.807 12.7a.25.25 0 00-.057.159V16a.75.75 0 01-1.5 0zm6.5 3.5a.75.75 0 01-1.5 0V6.5a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});