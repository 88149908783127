define("ember-svg-jar/inlined/real-estate-action-building-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-building-star</title><path d=\"M6.723 6.052l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 000 6.5V14a.5.5 0 00.5.5H2a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5A.5.5 0 007 14V6.5a.5.5 0 00-.277-.448zM5 10.5H2a.5.5 0 010-1h3a.5.5 0 010 1zM5 8H2a.5.5 0 010-1h3a.5.5 0 010 1zM13.61 13.5a.5.5 0 00.46-.3c.239-.555.716-1.663.883-2.022a.5.5 0 00.047-.214V3a.5.5 0 00-.308-.462l-6-2.5A.5.5 0 008 .5V14a.5.5 0 00.5.5h1.4a.5.5 0 00.361-.155 2.766 2.766 0 012-.845zM10 4.5h3a.5.5 0 010 1h-3a.5.5 0 010-1zM10 7h3a.5.5 0 010 1h-3a.5.5 0 010-1zm0 3.5a.5.5 0 010-1h3a.5.5 0 010 1z\"/><path d=\"M23.61 17.138a1.262 1.262 0 00.3-1.378 1.232 1.232 0 00-1.17-.76H20.4a.5.5 0 01-.459-.3l-1.279-2.966-.012-.027a1.294 1.294 0 00-2.318.027L15.055 14.7a.5.5 0 01-.459.3h-2.337a1.23 1.23 0 00-1.166.76 1.27 1.27 0 00.32 1.4l2.058 1.834a.5.5 0 01.126.573l-1.15 2.649a1.253 1.253 0 00.314 1.451 1.28 1.28 0 001.506.166l2.989-1.683a.5.5 0 01.491 0l2.99 1.684a1.275 1.275 0 001.505-.166 1.254 1.254 0 00.316-1.45l-1.152-2.65a.5.5 0 01.126-.573z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});