define("ember-svg-jar/inlined/photography-equipment-battery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>photography-equipment-battery</title><path d=\"M21 6.5A2.5 2.5 0 0018.5 4h-.25a.25.25 0 01-.25-.25V1.5A1.5 1.5 0 0016.5 0H12a1.5 1.5 0 00-1.5 1.5v2.25a.25.25 0 01-.25.25H5.5A2.5 2.5 0 003 6.5v15A2.5 2.5 0 005.5 24h13a2.5 2.5 0 002.5-2.5zM8.044 16a.751.751 0 01-.57-1.238l5.646-6.587A.5.5 0 0114 8.5v3.25a.25.25 0 00.25.25h1.706a.751.751 0 01.57 1.238l-5.646 6.587A.5.5 0 0110 19.5v-3.25a.25.25 0 00-.25-.25zM12 2.25a.75.75 0 011.5 0v1.5a.75.75 0 01-1.5 0zm3.75 2.25a.75.75 0 01-.75-.75v-1.5a.75.75 0 011.5 0v1.5a.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});