define("ember-svg-jar/inlined/bicycle-sports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bicycle-sports</title><path d=\"M24 15a5.006 5.006 0 00-5-5 4.787 4.787 0 00-.886.087.249.249 0 01-.288-.182l-.875-3.313a.251.251 0 01.267-.313l1.586.156a.25.25 0 01.16.418.985.985 0 00-.253.533 1 1 0 00.848 1.131 2 2 0 10.525-3.965l-3.719-.366a1.509 1.509 0 00-1.309.511 1.494 1.494 0 00-.313 1.364l.469 1.775a.255.255 0 01-.036.206.25.25 0 01-.176.108l-5.127.53a.248.248 0 01-.232-.107L8.7 7.2a.25.25 0 01.259-.386L9.807 7a.949.949 0 00.21.022 1 1 0 00.209-1.978L5.459 4.022a1 1 0 00-.419 1.956l.36.077a.25.25 0 01.154.1l2.288 3.337a.251.251 0 01-.025.314l-.38.4a.25.25 0 01-.291.053A4.886 4.886 0 005 9.75a5 5 0 104.9 6H11a1 1 0 00.722-.309l2.1-2.194a.25.25 0 01.418.25A4.84 4.84 0 0014 15a5 5 0 0010 0zM5 17.75a3 3 0 112.659-4.364.251.251 0 01-.223.364H5.5a1 1 0 000 2h1.936a.25.25 0 01.223.364A2.988 2.988 0 015 17.75zm5.573-4H10.1a.25.25 0 01-.243-.19 4.956 4.956 0 00-.865-1.783.249.249 0 01.018-.322l.647-.682a.252.252 0 01.156-.076l3.409-.353a.25.25 0 01.206.422zM19 18a3 3 0 01-3-3 2.928 2.928 0 01.306-1.3.25.25 0 01.466.048l.314 1.188a1 1 0 00.646.692l.947.32a1 1 0 10.641-1.894l-.311-.105a.25.25 0 01-.161-.173l-.382-1.446a.253.253 0 01.036-.205.248.248 0 01.18-.107A3 3 0 1119 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});