define("ember-svg-jar/inlined/tools-axe-hold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-axe-hold</title><path d=\"M22.812 7.884L19.78 4.851a.48.48 0 01-.113-.174l-1.54-4a1.006 1.006 0 00-1.163-.618 8.651 8.651 0 00-4.052 2.168 6.589 6.589 0 00-1.878 3.783 1 1 0 00.633 1.06l4.049 1.558a.493.493 0 01.175.113l3.031 3.032a1.5 1.5 0 002.122 0l1.768-1.768a1.5 1.5 0 000-2.121zM5.654 17.937a1.123 1.123 0 01-1.59 0l-.94-.93a1.123 1.123 0 010-1.59 1.1 1.1 0 01.8-.33 1.073 1.073 0 01.79.33l.94.93a1.123 1.123 0 010 1.59zM4.544 14.177a1.124 1.124 0 011.59-1.59l1.64 1.64a1.112 1.112 0 01.33.79 1.136 1.136 0 01-.33.8 1.161 1.161 0 01-1.59 0zM9.894 13.7a1.123 1.123 0 01-1.59 0l-1.64-1.64a1.123 1.123 0 010-1.59 1.105 1.105 0 01.79-.33 1.128 1.128 0 01.8.33l1.64 1.64a1.123 1.123 0 010 1.59zM11.984 11.2v.118a.5.5 0 01-.3.484 1.168 1.168 0 01-1.259-.222l-.93-.94a1.073 1.073 0 01-.33-.79 1.1 1.1 0 01.33-.8 1.161 1.161 0 011.59 0l.891.9a.5.5 0 01.121.505 2.438 2.438 0 00-.113.745zM13.769 14.75a.5.5 0 00.147-.353.5.5 0 00-.146-.354l-1.056-1.056a2.281 2.281 0 01-.238-.281.5.5 0 00-.585-.174 1.889 1.889 0 01-.484.115.5.5 0 00-.306.145l-.008.008a.5.5 0 00-.142.3 1.852 1.852 0 01-.527 1.127 1.919 1.919 0 01-1.118.534.5.5 0 00-.3.144l-.029.03a.5.5 0 00-.142.295 1.876 1.876 0 01-.535 1.117 1.908 1.908 0 01-1.1.53.5.5 0 00-.294.144l-.062.062a.5.5 0 00-.14.291 1.841 1.841 0 01-1.6 1.62.5.5 0 00-.291.144L.894 23.1a.514.514 0 00-.11.54.5.5 0 00.46.31l3.06.02a.5.5 0 00.35-.14z\"/><path d=\"M17.313 22.526l1.29 1.44H9.76a.5.5 0 01-.354-.146l-1.29-1.29a.5.5 0 010-.708l6.72-6.719a1 1 0 000-1.414l-1.413-1.413a1.5 1.5 0 012.12-2.12l2.12 2.12a4 4 0 010 5.661l-.348.348a3 3 0 00-.002 4.241z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});