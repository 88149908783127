define("ember-svg-jar/inlined/vectors-pen-subtract-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vectors-pen-subtract-1</title><path d=\"M9.5 2.5a1.25 1.25 0 00-1.25-1.25h-7a1.25 1.25 0 000 2.5h7A1.25 1.25 0 009.5 2.5zM23.707 7.3l-5.755-5.757a.993.993 0 00-.794-.289c-.638.055-.348-.125-5.232 6.841a1 1 0 00.112 1.281l3.836 3.836a1 1 0 001.281.112l6.419-4.5a1 1 0 00.133-1.524zM10.547 9.907a.5.5 0 00-.56-.1L4.712 12.2a.5.5 0 00-.293.455c0 .066-.023 6.6-1.835 9.315a.5.5 0 00.693.693c2.718-1.812 9.249-1.834 9.315-1.834a.5.5 0 00.455-.293l2.4-5.276a.5.5 0 00-.1-.56zm.582 7.043a2 2 0 110-2.828 2 2 0 010 2.828z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});