define("ember-svg-jar/inlined/cursor-choose-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-choose-1</title><path d=\"M4.8 7l3.907-5.21a1 1 0 00-1.6-1.2L3.892 4.878 1.707 2.694A1 1 0 00.293 4.108l3 3A1.013 1.013 0 004.8 7zM21.762 3.749l1.945-1.944A1 1 0 0022.293.39l-1.945 1.945L18.4.39a1 1 0 00-1.414 1.415l1.944 1.944-1.941 1.945a1 1 0 000 1.414 1.015 1.015 0 001.414 0l1.945-1.945 1.945 1.945a1.017 1.017 0 001.414 0 1 1 0 000-1.414zM18.074 14.68L13 12.857V7.4a2 2 0 00-4 0v8.461l-.827-1.139a1.546 1.546 0 00-1.2-.558l-.99.02a1.476 1.476 0 00-1.3 2.178l3.735 6.773A1.462 1.462 0 009.7 23.9h6.386a1.479 1.479 0 001.379-.99l.594-2.609.837-3.679a1.523 1.523 0 00-.822-1.942z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});