define("ember-svg-jar/inlined/navigation-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-up</title><path d=\"M12.777.4l9.988 7.99A1.25 1.25 0 0122 10.624h-1.5a.25.25 0 00-.25.25v11.75a1.251 1.251 0 01-1.25 1.25h-8.753a.249.249 0 01-.25-.25v-4a2.5 2.5 0 00-2.5-2.5H4a.25.25 0 01-.25-.25v-6a.25.25 0 00-.25-.25H2A1.255 1.255 0 01.751 9.368a1.232 1.232 0 01.474-.968L11.208.409A1.227 1.227 0 0112.777.4z\"/><path d=\"M7.5 18.122a1.5 1.5 0 011.5 1.5v3.4a.251.251 0 01-.435.169l-4.24-4.647a.25.25 0 01.184-.418z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});