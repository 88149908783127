define("ember-svg-jar/inlined/brain-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>brain-1</title><path d=\"M24 12a3.5 3.5 0 00-2.216-3.242.25.25 0 01-.137-.333A4.15 4.15 0 0022 6.75a4.257 4.257 0 00-3.785-4.225.25.25 0 01-.222-.231A2.529 2.529 0 0015.5 0a2.5 2.5 0 00-2.286 1.483.249.249 0 01-.293.14 3.573 3.573 0 00-1.842 0 .249.249 0 01-.293-.14A2.5 2.5 0 008.5 0a2.53 2.53 0 00-2.493 2.294.249.249 0 01-.222.231A4.257 4.257 0 002 6.75a4.15 4.15 0 00.353 1.675.252.252 0 010 .2.246.246 0 01-.139.137 3.479 3.479 0 000 6.483.25.25 0 01.137.333A4.151 4.151 0 002 17.25a4.255 4.255 0 003.785 4.224.251.251 0 01.222.232 2.5 2.5 0 004.562 1.2.248.248 0 01.287-.095 3.57 3.57 0 002.288 0 .247.247 0 01.287.1 2.5 2.5 0 004.562-1.2.251.251 0 01.222-.232A4.255 4.255 0 0022 17.25a4.151 4.151 0 00-.353-1.676.25.25 0 01.137-.333A3.5 3.5 0 0024 12zm-6 5H14.986a1.667 1.667 0 110-1.508H18A.75.75 0 0118 17zm-7.75-7.959a1.665 1.665 0 01-1.421-.8.252.252 0 00-.213-.119H5.75a.75.75 0 010-1.5h2.866a.252.252 0 00.213-.119 1.666 1.666 0 111.421 2.538zM9 17.5a.75.75 0 01-1.5 0v-2.616a.252.252 0 00-.119-.214 1.667 1.667 0 111.738 0 .252.252 0 00-.119.214zm8.417-10.25a1.667 1.667 0 01-.8 1.42.251.251 0 00-.12.214V12A.75.75 0 0115 12V8.884a.251.251 0 00-.12-.214 1.666 1.666 0 112.537-1.42z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});