define("ember-svg-jar/inlined/messages-bubble-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-search</title><path d=\"M9.5 16.5a6.992 6.992 0 0111.741-5.14.25.25 0 00.408-.108A7.437 7.437 0 0022 9c0-4.962-4.935-9-11-9S0 4.038 0 9a8.08 8.08 0 002.657 5.854L1.03 19.329a.5.5 0 00.662.632l5.832-2.429a12.961 12.961 0 001.784.351.249.249 0 00.278-.287A7.068 7.068 0 019.5 16.5z\"/><path d=\"M23.707 22.293l-2.534-2.534a.25.25 0 01-.034-.311 5.509 5.509 0 10-1.691 1.691.25.25 0 01.311.034l2.534 2.534a1 1 0 001.414-1.414zM13 16.5a3.5 3.5 0 113.5 3.5 3.5 3.5 0 01-3.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});