define("ember-svg-jar/inlined/chick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chick</title><path d=\"M21.479 4.62a5 5 0 00-9.986.38c0 .136.006.271.017.4a8 8 0 00-7.863 13.539l-.561.561H1a1 1 0 000 2h.086l-.793.793a1 1 0 001.414 1.414l.793-.793V23a1 1 0 002 0v-2.086l.815-.815a7.989 7.989 0 007.035.165l.65.65V23a1 1 0 002 0v-.086l.793.793a1 1 0 001.414-1.414l-.793-.793h.086a1 1 0 000-2h-2.086l-.329-.329A7.99 7.99 0 0016.415 10a5.009 5.009 0 004.076-2H23.5a.5.5 0 00.5-.5 3.11 3.11 0 00-2.521-2.88zM9.009 16.25a4.171 4.171 0 01-4.153-3.715.75.75 0 011.491-.164 2.679 2.679 0 005.338-.177.75.75 0 111.5.069 4.17 4.17 0 01-4.176 3.987zM17.993 5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});