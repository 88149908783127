define("ember-svg-jar/inlined/paginate-filter-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paginate-filter-heart</title><path d=\"M22 0H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2V2a2 2 0 00-2-2zm-3.848 10.44l-4.041 4.216a.5.5 0 01-.721 0l-4.049-4.224A2.888 2.888 0 018.806 7.1a2.9 2.9 0 014.637-.753l.307.308.308-.306a2.894 2.894 0 014.636.751 2.892 2.892 0 01-.542 3.34z\"/><path d=\"M19.5 22h-17a.5.5 0 01-.5-.5v-17a1 1 0 00-2 0V22a2 2 0 002 2h17.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});