define("ember-svg-jar/inlined/disability-partially-blind", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-partially-blind</title><path d=\"M23.614 13.183a2 2 0 000-2.366C22.229 8.918 18.326 4.5 12 4.5a12.855 12.855 0 00-2.329.216.5.5 0 01-.532-.257l-.755-1.426a1 1 0 00-1.768.935l.478.9a.5.5 0 01-.246.694 16.158 16.158 0 00-6.461 5.255 2 2 0 000 2.366C1.771 15.083 5.674 19.5 12 19.5a12.855 12.855 0 002.329-.216.5.5 0 01.532.258l.755 1.426a1 1 0 001.768-.935l-.478-.9a.5.5 0 01.246-.693 16.163 16.163 0 006.462-5.257zm-15.5 3.609a.5.5 0 01-.266-.234L4.363 9.971a.5.5 0 01.121-.618c.168-.139.342-.278.524-.415a.5.5 0 01.744.169L9.8 16.856a.25.25 0 01-.278.359 11 11 0 01-1.406-.423zM2.231 12.31a.5.5 0 010-.61c.166-.216.364-.458.588-.714a.251.251 0 01.409.048L5.063 14.5a.25.25 0 01-.377.312 15.039 15.039 0 01-2.455-2.502zm9.9 5.185a.5.5 0 01-.446-.269L7.006 8.265a.5.5 0 01.229-.683c.208-.1.422-.192.641-.28a.5.5 0 01.627.231l4.867 9.192a.5.5 0 01-.4.732c-.296.027-.57.037-.843.043zm9.643-5.8a.5.5 0 010 .61 13.912 13.912 0 01-5.65 4.395.5.5 0 01-.627-.23l-.372-.7a.5.5 0 01.091-.59A4.394 4.394 0 0016.5 12 4.5 4.5 0 0012 7.5a4.325 4.325 0 00-.77.074.561.561 0 01-.6-.29.5.5 0 01.4-.74c.313-.028.634-.044.963-.044 5.107 0 8.407 3.416 9.777 5.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});