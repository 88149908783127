define("ember-svg-jar/inlined/common-file-double-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-double-alternate</title><path d=\"M21.414 4L18 .589A2.009 2.009 0 0016.586 0H7a2 2 0 00-2 2v1H4a2 2 0 00-2 2v17a2 2 0 002 2h13a2 2 0 002-2v-1h1a2 2 0 002-2V5.414A1.99 1.99 0 0021.414 4zM17 21.5a.5.5 0 01-.5.5h-12a.5.5 0 01-.5-.5v-16a.5.5 0 011 0V19a2 2 0 002 2h9.5a.5.5 0 01.5.5zm3-3a.5.5 0 01-.5.5h-12a.5.5 0 01-.5-.5v-16a.5.5 0 01.5-.5h8.879a.5.5 0 01.353.146l3.122 3.122a.5.5 0 01.146.353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});