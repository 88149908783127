define("ember-svg-jar/inlined/drone-restaurant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drone-restaurant</title><path d=\"M14.5 19.375a3.19 3.19 0 00-.553-1.979.25.25 0 00-.447.154v2.075a.25.25 0 00.25.25h.5a.25.25 0 00.25-.25z\"/><path d=\"M16 13.875H8a1.5 1.5 0 00-1.5 1.5v7a1.5 1.5 0 001.5 1.5h8a1.5 1.5 0 001.5-1.5v-7a1.5 1.5 0 00-1.5-1.5zm-.5 5.5v1a.5.5 0 01-.5.5h-1.5v.5a.5.5 0 01-1 0v-5a.5.5 0 01.5-.5c1.2 0 2.5 1.095 2.5 3.5zm-7-2v-1a.5.5 0 011 0v1a.5.5 0 001 0v-1a.5.5 0 011 0v1a1.5 1.5 0 01-1 1.414v2.586a.5.5 0 01-1 0v-2.586a1.5 1.5 0 01-1-1.414zM21.714 5.39a.249.249 0 01-.214-.247v-.268a1.5 1.5 0 00-.643-1.23.251.251 0 01-.107-.205v-.565a.75.75 0 00-1.5 0v.565a.25.25 0 01-.107.205 1.5 1.5 0 00-.643 1.23v.244a.25.25 0 01-.263.249c-2.428-.127-3.4-1.993-6.237-1.993S8.191 5.241 5.763 5.368a.25.25 0 01-.263-.249v-.244a1.5 1.5 0 00-.643-1.23.251.251 0 01-.107-.205v-.565a.75.75 0 00-1.5 0v.565a.25.25 0 01-.107.205 1.5 1.5 0 00-.643 1.23v.268a.249.249 0 01-.214.247A1.5 1.5 0 002.5 8.375h.532a.25.25 0 01.24.181l1.264 4.425a.75.75 0 101.442-.412L4.871 8.694a.251.251 0 01.04-.22.254.254 0 01.2-.1H5.5a4.771 4.771 0 011.582.266.251.251 0 01.168.237v2.247A1.752 1.752 0 009 12.875h6a1.752 1.752 0 001.75-1.75V8.878a.251.251 0 01.168-.237 4.771 4.771 0 011.582-.266h.389a.254.254 0 01.2.1.251.251 0 01.04.22l-1.107 3.875a.75.75 0 001.442.412l1.264-4.425a.25.25 0 01.24-.181h.532a1.5 1.5 0 00.214-2.985zm-6.464 5.735a.25.25 0 01-.25.25H9a.25.25 0 01-.25-.25V9.851a.25.25 0 01.363-.223 5.952 5.952 0 005.774 0 .25.25 0 01.363.223zM7 1.625a.75.75 0 000-1.5H1a.75.75 0 000 1.5zM23 .125h-6a.75.75 0 000 1.5h6a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});