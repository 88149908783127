define("ember-svg-jar/inlined/navigation-right-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-right-2</title><path d=\"M12.249 23.739a1.44 1.44 0 01-.36-2l6.583-9.463a.48.48 0 000-.549L11.889 2.26A1.44 1.44 0 0114.254.616l6.582 9.463a3.372 3.372 0 010 3.837l-6.582 9.463a1.44 1.44 0 01-2.005.36z\"/><path d=\"M7.527.89l6.584 9.463a2.892 2.892 0 010 3.289l-6.584 9.464a.961.961 0 01-.788.411H3.526a.96.96 0 01-.788-1.508l6.583-9.463a.965.965 0 000-1.1l-6.583-9.46A.96.96 0 013.526.478h3.213a.96.96 0 01.788.412z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});