define("ember-svg-jar/inlined/road-sign-bicycles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-bicycles</title><path d=\"M12.451 11.835l-1.414.314a.255.255 0 00-.149.1L9.74 13.855a.25.25 0 00.2.395h1.068a.251.251 0 00.2-.1l1.492-1.917a.25.25 0 00-.252-.4zM9.11 15.75H8a.75.75 0 01-.61-1.186l.641-.9a.251.251 0 00-.225-.395 1.636 1.636 0 00-.669.206A1.75 1.75 0 008 16.75a1.734 1.734 0 001.3-.583.25.25 0 00-.187-.417zM17.539 13.339a.25.25 0 00-.306.339l.453 1.017a.752.752 0 01-.381.991.766.766 0 01-.3.064.752.752 0 01-.686-.445l-.451-1.016a.25.25 0 00-.455 0 1.688 1.688 0 00-.163.711 1.75 1.75 0 102.289-1.661z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm5 18.25a3.238 3.238 0 01-2-5.792.062.062 0 00.018-.074l-.024-.053a.249.249 0 00-.425-.052l-2.475 3.182a.749.749 0 01-.592.289h-.154a.251.251 0 00-.24.178 3.243 3.243 0 11-2.047-4 .25.25 0 00.28-.092l.27-.378a.248.248 0 00.037-.214l-.377-1.313a.249.249 0 00-.24-.181H8.5a.75.75 0 010-1.5H11a.75.75 0 010 1.5.167.167 0 00-.133.066.169.169 0 00-.027.146l.122.426a.25.25 0 00.295.175l2.386-.53a.251.251 0 00.169-.132.247.247 0 00.005-.214l-.6-1.348a1.25 1.25 0 01.814-1.714l1.269-.348h.006a.75.75 0 01.394 1.447l-.711.194a.255.255 0 00-.16.134.25.25 0 000 .209l1.5 3.373a.251.251 0 00.263.146 2.963 2.963 0 01.408-.03 3.25 3.25 0 010 6.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});