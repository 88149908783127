define("ember-svg-jar/inlined/peacock-feather", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>peacock-feather</title><path d=\"M21.631 19.268a5.467 5.467 0 001.517-5.575 1 1 0 00-1.918.568 3.468 3.468 0 01-.39 2.849 25.471 25.471 0 00-1.283-2.69 5.465 5.465 0 00.472-5.567 1 1 0 10-1.8.867 3.48 3.48 0 01.12 2.758 19.253 19.253 0 00-2.62-3.023 5 5 0 00-3.69-6.872L1.239.014a.5.5 0 00-.533.762l6.185 9.387a5 5 0 007.684.953 16.9 16.9 0 012.477 3.01 3.511 3.511 0 01-2.685.544 1 1 0 10-.4 1.958 5.458 5.458 0 004.092-.767 25.181 25.181 0 011.169 2.623 3.484 3.484 0 01-2.6.043 1 1 0 00-.708 1.871 5.476 5.476 0 003.963-.019 38.8 38.8 0 01.767 2.846 1.032 1.032 0 001.2.75 1 1 0 00.75-1.2q-.432-1.863-.969-3.507zM11.124 5a2.5 2.5 0 11-2.5 2.5 2.5 2.5 0 012.5-2.5z\"/><circle cx=\"11.123\" cy=\"7.501\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});