define("ember-svg-jar/inlined/movies-audience", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>movies-audience</title><circle cx=\"8\" cy=\"9\" r=\"2.5\"/><path d=\"M9.393 12.75a4 4 0 00-4.672 1.458.253.253 0 00-.028.234.249.249 0 00.179.154 4.009 4.009 0 012.52 1.786.251.251 0 00.208.118h.8a.249.249 0 00.212-.118 4.009 4.009 0 012.52-1.786.249.249 0 00.179-.154.253.253 0 00-.028-.234 4.012 4.012 0 00-1.89-1.458z\"/><circle cx=\"16\" cy=\"9\" r=\"2.5\"/><path d=\"M17.393 12.75a4 4 0 00-4.672 1.458.253.253 0 00-.028.234.249.249 0 00.179.154 4.009 4.009 0 012.52 1.786.251.251 0 00.212.118h.796a.249.249 0 00.212-.118 4.009 4.009 0 012.52-1.786.249.249 0 00.179-.154.253.253 0 00-.028-.234 4.012 4.012 0 00-1.89-1.458z\"/><circle cx=\"4\" cy=\"18.5\" r=\"2.5\"/><path d=\"M2.607 22.25A4.014 4.014 0 00.8 23.6a.25.25 0 00.2.4h6a.25.25 0 00.2-.4 4 4 0 00-4.593-1.35z\"/><circle cx=\"12\" cy=\"18.5\" r=\"2.5\"/><path d=\"M10.607 22.25A4.014 4.014 0 008.8 23.6a.25.25 0 00.2.4h6a.25.25 0 00.2-.4 4 4 0 00-4.593-1.35z\"/><circle cx=\"20\" cy=\"18.5\" r=\"2.5\"/><path d=\"M18.607 22.25A4.014 4.014 0 0016.8 23.6a.25.25 0 00.2.4h6a.25.25 0 00.2-.4 4 4 0 00-4.593-1.35zM22 15a2 2 0 002-2V2a2 2 0 00-2-2H2a2 2 0 00-2 2v11a2 2 0 002 2h.07a1 1 0 00.13-1.98.249.249 0 01-.2-.245V2.5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v10.275a.25.25 0 01-.2.245 1 1 0 00.13 1.98H22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});