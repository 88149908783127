define("ember-svg-jar/inlined/religion-angel-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-angel-1</title><path d=\"M7.94 12.775a.5.5 0 00-.225-.847c-2.05-.508-4.615-1.473-5.722-2.687a.768.768 0 00-.793-.209.731.731 0 00-.517.668c-.088 2.263.7 5.337 2.181 6.564a2.36 2.36 0 001.278.562.252.252 0 01.212.167 1.836 1.836 0 00.583.847 1.582 1.582 0 00.44.249.5.5 0 00.676-.466v-.439a6.09 6.09 0 011.887-4.409zM22.8 9.032a.759.759 0 00-.79.21c-1.1 1.2-3.683 2.2-5.824 2.718a.5.5 0 00-.231.846 6.09 6.09 0 011.852 4.4v.452a.5.5 0 00.622.486 1.645 1.645 0 00.639-.312 1.836 1.836 0 00.583-.847.252.252 0 01.212-.167 2.36 2.36 0 001.278-.562c1.477-1.227 2.269-4.3 2.181-6.563a.731.731 0 00-.522-.661zM16.292 21.532l.011-4.325a4.456 4.456 0 00-4.363-4.544 4.455 4.455 0 00-4.386 4.522l-.011 4.325A3.743 3.743 0 017 23.275a.5.5 0 00.451.715h8.914a.5.5 0 00.451-.716 3.626 3.626 0 01-.524-1.742zM7.5 7.5a4.49 4.49 0 002.5 4.005 4.337 4.337 0 003.95.032A4.493 4.493 0 107.5 7.5zm6.6.431a.25.25 0 01.275.323A2.494 2.494 0 019.5 7.5a2.185 2.185 0 01.013-.23.251.251 0 01.379-.186A6.258 6.258 0 0013.167 8a6.284 6.284 0 00.933-.072zM7.5 2.01h9a1 1 0 100-2h-9a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});