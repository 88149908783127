define("ember-svg-jar/inlined/video-game-bowl-ghost", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-bowl-ghost</title><circle cx=\"13.534\" cy=\"2.021\" r=\".75\"/><circle cx=\"16.75\" cy=\"1.25\" r=\"1.25\"/><circle cx=\"13.5\" cy=\"14.5\" r=\".75\"/><path d=\"M14.833 7.483a.5.5 0 01-.333-.47V6.5A.5.5 0 0115 6h1a1 1 0 000-2H8a1 1 0 000 2h1a.5.5 0 01.5.5v.513a.5.5 0 01-.333.47 8.5 8.5 0 105.666 0zM16 17.5c0 1.1-.672 2-1.5 2a1.334 1.334 0 01-1.051-.575.25.25 0 00-.4 0 1.248 1.248 0 01-2.1 0 .25.25 0 00-.4 0A1.334 1.334 0 019.5 19.5c-.828 0-1.5-.9-1.5-2v-3a4 4 0 018 0z\"/><circle cx=\"10.5\" cy=\"14.5\" r=\".75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});