define("ember-svg-jar/inlined/currency-dollar-bill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-dollar-bill</title><path d=\"M21.143 7.487A.25.25 0 0121 7.261V5.5a1 1 0 00-1-1H4a1 1 0 00-1 1v1.761a.25.25 0 01-.143.226 4.994 4.994 0 000 9.027.25.25 0 01.143.226v1.76a1 1 0 001 1h16a1 1 0 001-1v-1.76a.25.25 0 01.143-.226 4.994 4.994 0 000-9.027zm-10.82 8.784a1 1 0 010-2H12.6a.5.5 0 00.185-.965l-2.3-.921a2.494 2.494 0 01.334-4.741A.249.249 0 0011 7.4v-.233a1 1 0 012 0v.147a.249.249 0 00.25.25h.427a1 1 0 010 2H11.4a.5.5 0 00-.5.5.5.5 0 00.314.464l2.306.922a2.495 2.495 0 01-.334 4.742.249.249 0 00-.19.243v.233a1 1 0 01-2 0v-.146a.249.249 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});