define("ember-svg-jar/inlined/diamond-ring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diamond-ring</title><path d=\"M2.846 9.346A.621.621 0 003.405 9l.609-1.219a.243.243 0 01.112-.112l1.218-.609a.625.625 0 000-1.119l-1.22-.61a.253.253 0 01-.112-.112L3.406 4a.649.649 0 00-1.119 0l-.61 1.219a.248.248 0 01-.112.112L.348 5.94a.625.625 0 000 1.119l1.219.609a.258.258 0 01.112.112L2.286 9a.623.623 0 00.56.346zM23.655 2.787L22.1 2.01a.246.246 0 01-.111-.112L21.213.344a.649.649 0 00-1.117 0L19.319 1.9a.253.253 0 01-.112.112l-1.55.776a.625.625 0 000 1.119l1.552.776a.246.246 0 01.112.111l.779 1.552a.625.625 0 001.118 0l.777-1.554a.256.256 0 01.105-.112l1.557-.78a.625.625 0 000-1.117zM16.419 7.468a.249.249 0 00-.344.071 5.544 5.544 0 01-1.808 1.706.248.248 0 00-.124.221.251.251 0 00.134.216 6 6 0 11-5.554 0 .251.251 0 00.134-.216.248.248 0 00-.124-.221 5.544 5.544 0 01-1.808-1.706.249.249 0 00-.344-.071 9 9 0 109.838 0z\"/><path d=\"M11.5 9A4.5 4.5 0 107 4.5 4.505 4.505 0 0011.5 9zm0-7.286a.5.5 0 010 1A1.789 1.789 0 009.714 4.5a.5.5 0 01-1 0A2.789 2.789 0 0111.5 1.714z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});