define("ember-svg-jar/inlined/movies-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>movies-home</title><path d=\"M23 15.291A1.292 1.292 0 0021.709 14H13.25a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h6.25a2 2 0 002-2V2a2 2 0 00-2-2h-15a2 2 0 00-2 2v8a2 2 0 002 2h6.25a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H2.291A1.292 1.292 0 001 15.291v.918A1.292 1.292 0 002.291 17.5h19.418A1.292 1.292 0 0023 16.209zM4.5 2.5A.5.5 0 015 2h14a.5.5 0 01.5.5v7a.5.5 0 01-.5.5H5a.5.5 0 01-.5-.5zM7 18.5H4a.5.5 0 00-.475.342l-1.5 4.5A.5.5 0 002.5 24H3a.5.5 0 00.374-.168l4-4.5A.5.5 0 007 18.5zM21.474 18.842A.5.5 0 0021 18.5h-3a.5.5 0 00-.374.832l4 4.5A.5.5 0 0022 24h.5a.5.5 0 00.474-.658z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});