define("ember-svg-jar/inlined/style-one-pin-cross", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-cross</title><path d=\"M12 0a9.5 9.5 0 00-4 18.119 2.509 2.509 0 011.218 1.222l1.872 4.076a1 1 0 001.817 0l1.876-4.079A2.5 2.5 0 0116 18.118 9.5 9.5 0 0012 0zm5.5 10.5a1 1 0 01-1 1h-2a.5.5 0 00-.5.5v2a1 1 0 01-1 1h-2a1 1 0 01-1-1v-2a.5.5 0 00-.5-.5h-2a1 1 0 01-1-1v-2a1 1 0 011-1h2A.5.5 0 0010 7V5a1 1 0 011-1h2a1 1 0 011 1v2a.5.5 0 00.5.5h2a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});