define("ember-svg-jar/inlined/road-sign-turn-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-turn-right</title><path d=\"M23.25 17.25a.75.75 0 00.75-.75v-9a.75.75 0 00-.75-.75H.75A.75.75 0 000 7.5v9a.75.75 0 00.75.75zM1.5 8.5a.25.25 0 01.25-.25h.085a.249.249 0 01.177.073l3.492 3.5a.252.252 0 010 .354l-3.492 3.5a.249.249 0 01-.177.073H1.75a.25.25 0 01-.25-.25zM9 15.75a.182.182 0 01-.169-.113.184.184 0 01.039-.2l2.548-2.552a1.249 1.249 0 000-1.765L8.983 8.677a.25.25 0 01.177-.427h3.175a.249.249 0 01.177.073l3.488 3.5a.252.252 0 010 .354l-3.492 3.5a.249.249 0 01-.177.073zm13.5-.25a.25.25 0 01-.25.25H19.5a.182.182 0 01-.169-.113.184.184 0 01.039-.2l2.548-2.552a1.249 1.249 0 000-1.765l-2.435-2.443a.25.25 0 01.177-.427h2.59a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});