define("ember-svg-jar/inlined/microphone-podcast-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>microphone-podcast-1</title><path d=\"M5 22H4a1 1 0 000 2h1a1 1 0 000-2zM10 22H9a1 1 0 000 2h1a1 1 0 000-2zM15 22h-1a1 1 0 000 2h1a1 1 0 000-2zM20 22h-1a1 1 0 000 2h1a1 1 0 000-2zM9.5 8a.75.75 0 010 1.5H6.75a.25.25 0 00-.25.25v.75a5.5 5.5 0 0011 0v-.75a.25.25 0 00-.25-.25H14.5a.75.75 0 010-1.5h2.75a.25.25 0 00.25-.25v-1a.25.25 0 00-.25-.25H14.5a.75.75 0 010-1.5h2.7a.25.25 0 00.248-.286A5.514 5.514 0 0013.048.1a.251.251 0 00-.3.245V3a.75.75 0 01-1.5 0V.346a.251.251 0 00-.091-.193.254.254 0 00-.205-.053 5.514 5.514 0 00-4.4 4.613A.252.252 0 006.8 5h2.7a.75.75 0 010 1.5H6.75a.25.25 0 00-.25.25v1a.25.25 0 00.25.25z\"/><path d=\"M20 7.5a1 1 0 00-1 1v2a7 7 0 01-14 0v-2a1 1 0 00-2 0v2a9.014 9.014 0 007.784 8.918.249.249 0 01.216.247V21a1 1 0 002 0v-1.335a.248.248 0 01.216-.247A9.014 9.014 0 0021 10.5v-2a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});