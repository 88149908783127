define("ember-svg-jar/inlined/weather-cloud-thunder-heavy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-cloud-thunder-heavy</title><path d=\"M20.594 7.074a.252.252 0 01-.1-.193A7 7 0 006.838 4.84a.249.249 0 01-.22.173A5.5 5.5 0 007 16h10.5a5 5 0 003.094-8.926zM17.5 14H7a3.5 3.5 0 11.448-6.971A1 1 0 008.564 6.2 5 5 0 0118.5 7c0 .146-.007.289-.02.433a1 1 0 00.5.959A3 3 0 0117.5 14zM8.45 20.9l-.95-.714a.25.25 0 010-.4l1.217-.946a.75.75 0 00-.922-1.184l-2.25 1.75A.75.75 0 005.55 20.6l.953.714a.25.25 0 010 .4l-1.217.946a.75.75 0 00.922 1.184l2.25-1.75A.75.75 0 008.45 20.9zM13.45 20.9l-.953-.714a.25.25 0 010-.4l1.217-.946a.75.75 0 00-.922-1.184l-2.25 1.75a.75.75 0 00.008 1.194l.953.714a.25.25 0 010 .4l-1.217.946a.75.75 0 00.922 1.184l2.25-1.75a.75.75 0 00-.008-1.194zM18.842 17.789a.75.75 0 00-1.053-.131l-2.25 1.75a.75.75 0 00.011 1.192l.953.714a.25.25 0 010 .4l-1.217.946a.75.75 0 00.922 1.184l2.25-1.75a.75.75 0 00-.008-1.194l-.953-.714a.25.25 0 010-.4l1.217-.946a.751.751 0 00.128-1.051z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});