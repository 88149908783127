define("ember-svg-jar/inlined/currency-yuan-decrease", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-yuan-decrease</title><path d=\"M16.987 0a7 7 0 107 7 7.009 7.009 0 00-7-7zm-.716 10.847V9.123a.249.249 0 00-.25-.25h-.633a.75.75 0 010-1.5h.633a.249.249 0 00.25-.25v-.281a.255.255 0 00-.054-.157l-1.959-2.449A.75.75 0 0115.43 3.3l1.4 1.747a.25.25 0 00.391 0l1.4-1.747a.75.75 0 011.171.938l-1.96 2.449a.256.256 0 00-.055.157v.281a.25.25 0 00.25.25h.634a.75.75 0 110 1.5h-.634a.25.25 0 00-.25.25v1.724a.75.75 0 11-1.5 0zM22.987 23.5a.5.5 0 01-.5.5h-5a.5.5 0 01-.353-.854l1.415-1.414a.25.25 0 00-.008-.361l-4.582-4.225a.25.25 0 00-.326-.012l-2.9 2.317a2.154 2.154 0 01-2.771-.071l-.219-.194-7.313-6.5a1.25 1.25 0 011.66-1.87l7.155 6.36a.25.25 0 00.322.009l2.9-2.324a2.163 2.163 0 012.8.1l5.047 4.653a.249.249 0 00.346-.007l1.462-1.462a.5.5 0 01.365-.145.513.513 0 01.192.038.5.5 0 01.308.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});