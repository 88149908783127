define("ember-svg-jar/inlined/button-arrow-curve", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-arrow-curve</title><path d=\"M8.227 14.365a1 1 0 00.713-1.7L5.6 9.263a1 1 0 00-.7-.3h-.014a1 1 0 00-.7.287l-3.46 3.4a1 1 0 00.7 1.713h1.96a.25.25 0 01.25.25v.6a8.409 8.409 0 0016.818 0V6.024a.25.25 0 01.25-.25h1.871a1 1 0 00.71-1.7L19.914.67A1 1 0 0018.5.661l-3.46 3.4a1 1 0 00.7 1.713h1.96a.25.25 0 01.25.25v9.191a5.909 5.909 0 01-11.818 0v-.6a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});