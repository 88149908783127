define("ember-svg-jar/inlined/mobile-phone-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mobile-phone-horizontal</title><path d=\"M.034 7v10a3 3 0 003 3h17.932a3 3 0 003-3V7a3 3 0 00-3-3H3.034a3 3 0 00-3 3zm22.432 5a1 1 0 11-1-1 1 1 0 011 1zm-4.5-6a1 1 0 011 1v10a1 1 0 01-1 1H3.534a1 1 0 01-1-1V7a1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});