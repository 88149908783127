define("ember-svg-jar/inlined/social-instagram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-instagram</title><path d=\"M17.5 0h-11A6.51 6.51 0 000 6.5v11A6.51 6.51 0 006.5 24h11a6.51 6.51 0 006.5-6.5v-11A6.51 6.51 0 0017.5 0zM12 17.5a5.5 5.5 0 115.5-5.5 5.5 5.5 0 01-5.5 5.5zm6.5-11A1.5 1.5 0 1120 5a1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});