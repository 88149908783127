define("ember-svg-jar/inlined/graph-statistics-coffee", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>graph-statistics-coffee</title><path d=\"M18.252 15.707a1 1 0 001.414 0 2.378 2.378 0 00.07-3.534c-.457-.606-.457-.606-.1-.966a1 1 0 10-1.414-1.414 2.409 2.409 0 00-.085 3.585 2.2 2.2 0 01.36.574s-.02.112-.248.341a1 1 0 00.003 1.414zM13.5 17.548v2.976c0 2.047 1.674 3.476 4.071 3.476a3.944 3.944 0 003.342-1.444.251.251 0 01.185-.1A2.8 2.8 0 0024 19.929a2.637 2.637 0 00-2.225-2.429.248.248 0 01-.173-.145.5.5 0 00-.459-.3H14a.5.5 0 00-.5.493zm8.143 1.878a.25.25 0 01.131-.22.246.246 0 01.255.011.9.9 0 01.471.712.949.949 0 01-.562.767.2.2 0 01-.295-.172zM2.5 12h4.25a.25.25 0 01.25.25v1a.249.249 0 01-.25.25H3.625a1 1 0 000 2h9a1 1 0 000-2H9.25a.249.249 0 01-.25-.25v-1a.25.25 0 01.25-.25H14a2.5 2.5 0 002.5-2.448V2.5A2.5 2.5 0 0014 0H2.5A2.5 2.5 0 000 2.5v7.052A2.5 2.5 0 002.5 12zM2 2.5a.5.5 0 01.5-.5H14a.5.5 0 01.5.5V8a.5.5 0 01-.5.5H2.5A.5.5 0 012 8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});