define("ember-svg-jar/inlined/cap-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cap-2</title><path d=\"M22.416 18.791a1 1 0 00-.489-.41 27.142 27.142 0 00-19.854 0 1.007 1.007 0 00-.489.41C.045 21.317.1 21.2.071 21.3A1.5 1.5 0 002.1 23.124 24.547 24.547 0 0112 21.25a24.554 24.554 0 019.9 1.874 1.5 1.5 0 002.029-1.824c-.029-.109.027.018-1.513-2.509z\"/><path d=\"M12 15a28.519 28.519 0 019.337 1.566.5.5 0 00.663-.473V12.75a10.016 10.016 0 00-8.571-9.9.5.5 0 01-.429-.492V1.75a1 1 0 00-2 0v.608a.5.5 0 01-.429.494A10.015 10.015 0 002 12.75v3.342a.5.5 0 00.663.473A28.509 28.509 0 0112 15zM6.5 9.465a1 1 0 01.7-.954 16.151 16.151 0 019.6 0 1 1 0 01.7.954v1.915a1 1 0 01-1.265.965 16.186 16.186 0 00-8.471 0A1 1 0 016.5 11.38z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});