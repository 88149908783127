define("ember-svg-jar/inlined/server-settings-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-settings-1</title><path d=\"M9.134 17.047a8.419 8.419 0 011.18-4.3.494.494 0 00-.428-.747h-7.1a2.6 2.6 0 00-2.639 2.239A2.5 2.5 0 002.634 17h5.5a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25h-3a.75.75 0 000 1.5h4.43a.5.5 0 00.448-.711 8.412 8.412 0 01-.878-3.742zm-6-2.547a1.25 1.25 0 111.25 1.25 1.249 1.249 0 01-1.25-1.25zm5 1.25a1.25 1.25 0 111.25-1.25 1.25 1.25 0 01-1.25 1.25zM1.134 20a.75.75 0 000 1.5h1a.75.75 0 100-1.5zM2.634 5h13a2.5 2.5 0 000-5h-13a2.5 2.5 0 000 5zm.5-2.5a1.25 1.25 0 111.25 1.25 1.249 1.249 0 01-1.25-1.25zm3.75 0a1.25 1.25 0 111.25 1.25 1.249 1.249 0 01-1.25-1.25zM17.568 8.557a.5.5 0 00.506-.584A2.5 2.5 0 0015.634 6H2.781A2.6 2.6 0 00.147 8.239 2.5 2.5 0 002.634 11h9.036a8.4 8.4 0 015.898-2.443zM9.384 8.5a1.25 1.25 0 11-1.25-1.25 1.25 1.25 0 011.25 1.25zm-3.75 0a1.25 1.25 0 11-1.25-1.25 1.25 1.25 0 011.25 1.25z\"/><circle cx=\"17.614\" cy=\"17.031\" r=\"1.5\"/><path d=\"M15.491 12.717a.455.455 0 01-.534.31l-1.411-.327a1.783 1.783 0 00-1.7 2.953l.987 1.065a.461.461 0 010 .625l-.987 1.066a1.783 1.783 0 001.7 2.953l1.412-.327a.455.455 0 01.533.31l.423 1.392a1.776 1.776 0 003.4 0l.423-1.392a.448.448 0 01.534-.31l1.411.327a1.783 1.783 0 001.7-2.953l-.982-1.066a.463.463 0 010-.625l.988-1.065a1.783 1.783 0 00-1.7-2.953l-1.412.327a.45.45 0 01-.532-.31l-.424-1.392a1.776 1.776 0 00-3.4 0zm2.123 7.314a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});