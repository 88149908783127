define("ember-svg-jar/inlined/user-3d-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>user-3d-box</title><path d=\"M21.428 9.856a7.188 7.188 0 01-.721-1.456c0-5.262-4.863-8.4-9.564-8.4a9.925 9.925 0 00-10 10.062c0 3.549 1.308 6.2 3.887 7.885a.251.251 0 01.113.209V23.5a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-2.732a.249.249 0 01.245-.249c1.594-.035 2.593-.243 3.327-.978.782-.781.937-3.021.96-4.28a.25.25 0 01.252-.245c.247 0 .521 0 .767-.011a1.162 1.162 0 001.153-1.262v-.138c.084-1.116-.715-2.515-1.419-3.749zm-9.474 5.419a.743.743 0 01-.621 0l-4.871-2.214a.751.751 0 01-.44-.683V6.622a.792.792 0 01.44-.683l4.871-2.214a.748.748 0 01.621 0l4.871 2.214a.791.791 0 01.439.683v5.756a.751.751 0 01-.439.683z\"/><path d=\"M9.085 6.394a.25.25 0 000 .455l2.455 1.116a.249.249 0 00.207 0L14.2 6.849a.25.25 0 000-.455l-2.453-1.116a.249.249 0 00-.207 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});