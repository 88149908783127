define("ember-svg-jar/inlined/social-media-gowalla", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-gowalla</title><path d=\"M12 3.19A5.81 5.81 0 1017.81 9 5.82 5.82 0 0012 3.19zm0 9.56A3.75 3.75 0 1115.75 9 3.75 3.75 0 0112 12.75z\"/><path d=\"M12 0a9 9 0 00-2.17 17.74l1.69 5.9a.5.5 0 001 0l1.69-5.9A9 9 0 0012 0zm0 16.31A7.31 7.31 0 1119.31 9 7.32 7.32 0 0112 16.31z\"/><circle cx=\"12\" cy=\"9\" r=\"2.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});