define("ember-svg-jar/inlined/sea-transport-bark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sea-transport-bark</title><path d=\"M13.739 13.425a40.972 40.972 0 007.935-.883 1.252 1.252 0 00.946-1.49L21.208 4.7a.25.25 0 00-.244-.2H4.026a.25.25 0 00-.244.2l-1.411 6.345a1.25 1.25 0 00.963 1.494 45.033 45.033 0 007.918.877.251.251 0 01.243.25v.369a.25.25 0 01-.177.239L6.4 15.788a1.25 1.25 0 00-.694 1.859l.756 1.205a.25.25 0 00.071.074 2.431 2.431 0 01.705.716 2.016 2.016 0 001.413.832 2.681 2.681 0 001.808-.975 2.5 2.5 0 014.287.39 1.179 1.179 0 00.982.585 1.792 1.792 0 001.375-.759 2.486 2.486 0 011.542-1.158.255.255 0 00.15-.111l.5-.8a1.249 1.249 0 00-.7-1.852l-4.925-1.516a.25.25 0 01-.177-.239v-.36a.25.25 0 01.246-.254zM3 3.5h19a1 1 0 000-2h-8.25a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v.25a.25.25 0 01-.25.25H3a1 1 0 000 2z\"/><path d=\"M23.991 22.8a1 1 0 00-1.123-.858c-1.631.211-2.739-1.5-2.747-1.514a1.043 1.043 0 00-1.719.029 3.268 3.268 0 01-2.675 1.509 2.723 2.723 0 01-2.336-1.44 1.043 1.043 0 00-1.717-.155 4.2 4.2 0 01-3.024 1.591 3.462 3.462 0 01-2.686-1.538 1.043 1.043 0 00-1.636-.093c-.015.019-1.518 1.858-3.2 1.648a1 1 0 10-.26 1.982 4.169 4.169 0 00.544.039 5.6 5.6 0 003.481-1.424.252.252 0 01.328.007 5.09 5.09 0 003.429 1.379 5.684 5.684 0 003.557-1.321.25.25 0 01.331.016 4.469 4.469 0 003.189 1.3 5.03 5.03 0 003.381-1.3.25.25 0 01.333 0 4.55 4.55 0 003.691 1.261 1 1 0 00.859-1.118z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});