define("ember-svg-jar/inlined/professions-man-nurse-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-nurse-2</title><path d=\"M22.616 18.141a15.358 15.358 0 00-7.432-2.758.245.245 0 01-.08-.461 6.856 6.856 0 003.753-6.106V2.449a.963.963 0 00-.1-.411 2.039 2.039 0 00-.3-.373A11.233 11.233 0 0012 0h-.006a11.234 11.234 0 00-6.459 1.665 2.039 2.039 0 00-.295.373.963.963 0 00-.1.411v6.367a6.856 6.856 0 003.76 6.106.245.245 0 01-.08.461 15.36 15.36 0 00-7.435 2.757 2.9 2.9 0 00-1.14 2.326v2.553a.98.98 0 101.959 0v-2.553a.953.953 0 01.369-.768c1.17-.893 3.778-2.349 8.438-2.534a.244.244 0 01.254.245v5.61a.735.735 0 101.47 0v-5.61a.244.244 0 01.254-.245c4.66.185 7.268 1.641 8.439 2.535a.951.951 0 01.368.767v2.553a.98.98 0 101.959 0v-2.553a2.9 2.9 0 00-1.139-2.325zM16.761 2.933a.246.246 0 01.137.22V5.44a.245.245 0 01-.276.243 36.6 36.6 0 00-9.244 0A.246.246 0 017.1 5.44V3.154a.244.244 0 01.137-.22 12.116 12.116 0 019.524-.001zM7.1 8.816v-.9a.243.243 0 01.212-.242 34.6 34.6 0 019.372 0 .245.245 0 01.212.242v.9a4.9 4.9 0 11-9.8 0z\"/><circle cx=\"14.694\" cy=\"19.347\" r=\".98\"/><path d=\"M15.673 22.286a.98.98 0 10-1.959 0 .98.98 0 001.959 0z\"/><circle cx=\"13.959\" cy=\"8.836\" r=\".98\"/><circle cx=\"10.041\" cy=\"8.836\" r=\".98\"/><path d=\"M12.08 12.381a2.953 2.953 0 00.846-.122 2.3 2.3 0 00.427-.178.626.626 0 00-.3-1.176H11.1a.626.626 0 00-.3 1.176 2.333 2.333 0 00.428.178 2.919 2.919 0 00.849.123zM8.327 22.531a.735.735 0 000-1.47h-.49a.244.244 0 01-.245-.245v-.489a.735.735 0 10-1.47 0v.489a.244.244 0 01-.244.245h-.49a.735.735 0 000 1.47h.49a.244.244 0 01.244.245v.489a.735.735 0 101.47 0v-.489a.244.244 0 01.245-.245z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});