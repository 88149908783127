define("ember-svg-jar/inlined/voice-id-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>voice-id-lock</title><path d=\"M1 2.5a1 1 0 00-1 1v14a1 1 0 002 0v-14a1 1 0 00-1-1zM4.5 6.5a1 1 0 00-1 1v6a1 1 0 002 0v-6a1 1 0 00-1-1zM8 5a1 1 0 00-1 1v9a1 1 0 002 0V6a1 1 0 00-1-1zM11.5 0a1 1 0 00-1 1v19a1 1 0 002 0V1a1 1 0 00-1-1zM21.162 9.816a4.381 4.381 0 011 .563.25.25 0 00.254.026A.994.994 0 0023 9.5v-6a1 1 0 00-2 0v6.108a.25.25 0 00.162.208zM17.85 9.551a4.373 4.373 0 01.65-.051h.7a.249.249 0 00.234-.161A.944.944 0 0019.5 9V7.5a1 1 0 00-2 0V9a1.025 1.025 0 00.082.4.251.251 0 00.268.151zM14.577 11.4a.251.251 0 00.307-.079 4.41 4.41 0 011.01-.989.249.249 0 00.106-.2V6a1 1 0 00-2 0v4.5a.994.994 0 00.577.9zM22.714 15.515a.249.249 0 01-.214-.247V14.25a3.5 3.5 0 00-7 0v1.018a.249.249 0 01-.214.247A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.286-1.485zM19 21.25a1 1 0 111-1 1 1 0 01-1 1zm1.5-6a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-1a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});