define("ember-svg-jar/inlined/bin-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bin-2-alternate</title><path d=\"M20.318 9h-15a.5.5 0 00-.5.5V22a2 2 0 002 2h12a2 2 0 002-2V9.5a.5.5 0 00-.5-.5zm-9.25 11.5a.75.75 0 01-1.5 0V13a.75.75 0 011.5 0zm5 0a.75.75 0 01-1.5 0V13a.75.75 0 111.5 0zM21.628 2.552l-4.663.9a.25.25 0 01-.293-.2l-.238-1.227A2.5 2.5 0 0013.505.049L8.6 1a2.5 2.5 0 00-1.98 2.93l.237 1.227a.249.249 0 01-.2.293l-4.663.9A1 1 0 001.2 7.521a1.032 1.032 0 001.172.793l19.636-3.8a1 1 0 10-.38-1.964zm-13.048 1a.5.5 0 01.4-.586l4.909-.949a.5.5 0 01.585.4l.238 1.228a.251.251 0 01-.2.293L9.11 4.973a.25.25 0 01-.293-.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});