define("ember-svg-jar/inlined/cloud-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-refresh</title><path d=\"M24 10.935a5.766 5.766 0 00-5.218-5.9.249.249 0 01-.191-.12A7.684 7.684 0 004.493 7.21a.25.25 0 01-.227.2A4.643 4.643 0 00.624 9.649 4.47 4.47 0 000 12.1a4.289 4.289 0 001.235 3.1 4.291 4.291 0 001.009.732.932.932 0 001.365-.752 1.208 1.208 0 00-.591-1.132 2.033 2.033 0 01-.38-.273A2.374 2.374 0 012 11.984 2.588 2.588 0 014.586 9.4a2.7 2.7 0 01.535.054.989.989 0 00.811-.184 1 1 0 00.392-.779c.085-5.736 8.293-7.372 10.818-2.044a.981.981 0 00.941.57 3.738 3.738 0 013.009 1.315 3.872 3.872 0 01.9 2.747 3.436 3.436 0 01-1.3 2.593.971.971 0 00-.352.907v.027a.976.976 0 001.571.633A5.4 5.4 0 0024 10.935z\"/><path d=\"M16.327 11.786a.249.249 0 01-.348.005 6.375 6.375 0 10.628 8.631 1 1 0 10-1.562-1.248 4.374 4.374 0 11-.495-5.982.251.251 0 01.082.179.245.245 0 01-.073.183l-1.391 1.392a.5.5 0 00.354.853h4.457a.5.5 0 00.5-.5v-4.457a.5.5 0 00-.854-.353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});