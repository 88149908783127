define("ember-svg-jar/inlined/layout-array-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layout-array-1</title><rect x=\"6.5\" y=\".997\" width=\"11\" height=\"22\" rx=\"1.5\" ry=\"1.5\"/><rect y=\"2.497\" width=\"4.5\" height=\"19\" rx=\"1.5\" ry=\"1.5\"/><rect x=\"19.5\" y=\"2.497\" width=\"4.5\" height=\"19\" rx=\"1.5\" ry=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});