define("ember-svg-jar/inlined/bluetooth-on", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bluetooth-on</title><path d=\"M9.99 12.179a.249.249 0 010-.368l4.6-4.221a1.5 1.5 0 00.04-2.169L9.806.638A1.5 1.5 0 007.248 1.7v7.39a.25.25 0 01-.419.184l-4.9-4.509A1 1 0 00.571 6.236l6.062 5.574a.249.249 0 010 .368L.546 17.763A1 1 0 101.9 19.237l4.932-4.524a.25.25 0 01.419.184v7.4A1.5 1.5 0 009.8 23.365l4.824-4.783a1.5 1.5 0 00-.037-2.173zM9.248 3.5a.25.25 0 01.426-.177l2.991 2.965a.25.25 0 01.074.183.246.246 0 01-.081.179L9.667 9.394a.25.25 0 01-.419-.184zm.426 17.177a.25.25 0 01-.426-.177v-5.717a.25.25 0 01.419-.184l2.992 2.751a.249.249 0 01.08.179.246.246 0 01-.074.183zM21.293 6.107a1 1 0 10-1.414 1.415 6.5 6.5 0 010 9.192 1 1 0 101.414 1.414 8.51 8.51 0 000-12.021z\"/><path d=\"M16.549 8.937a1 1 0 000 1.415 2.5 2.5 0 010 3.534 1 1 0 101.415 1.414 4.5 4.5 0 000-6.364 1 1 0 00-1.415.001z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});