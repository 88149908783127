define("ember-svg-jar/inlined/fast-food-french-fries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fast-food-french-fries</title><path d=\"M8.674 10.636a1 1 0 00.163.472 2.47 2.47 0 01.374.933c.041.226.789.71 2.1.84a.5.5 0 00.548-.539L10.864.458a.5.5 0 00-.175-.34.5.5 0 00-.365-.118L8.331.17a.5.5 0 00-.457.538zM14.769 12.042a2.475 2.475 0 01.39-.958.993.993 0 00.17-.482l.8-9.894a.5.5 0 00-.46-.538L13.676 0a.5.5 0 00-.365.116.5.5 0 00-.175.34l-.994 11.884a.5.5 0 00.55.539c1.297-.134 2.036-.614 2.077-.837zM17.208 10h1.463a.5.5 0 00.477-.351L20.977 3.8a.5.5 0 00-.326-.626l-1.907-.6a.5.5 0 00-.628.326l-2.211 6.99a.25.25 0 00.336.306 2.468 2.468 0 01.967-.196zM4.852 9.649a.5.5 0 00.477.351h1.442a2.479 2.479 0 01.989.205.25.25 0 00.34-.305l-2.214-7a.5.5 0 00-.628-.326l-1.907.605a.5.5 0 00-.326.626z\"/><path d=\"M20.616 12.718a.979.979 0 00-.95-1.218h-2.458a.978.978 0 00-.963.807c-.259 1.448-2.388 2.108-4.255 2.108s-4-.66-4.255-2.108a.978.978 0 00-.964-.807H4.333a.978.978 0 00-.949 1.218L5.928 22.9A1.455 1.455 0 007.343 24h9.314a1.455 1.455 0 001.415-1.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});