define("ember-svg-jar/inlined/seasoning-chilli", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seasoning-chilli</title><path d=\"M18.922 9.614a.5.5 0 00.654 0 5.205 5.205 0 012.734-1.337.5.5 0 00.44-.5V7.25a3.5 3.5 0 00-2.013-3.168.5.5 0 01-.258-.286.5.5 0 01.022-.384A2.5 2.5 0 0122.75 2a1 1 0 000-2 4.508 4.508 0 00-4.36 3.386 1 1 0 01-.561.666 3.5 3.5 0 00-2.079 3.2v.531a.5.5 0 00.439.5 5.323 5.323 0 012.733 1.331zM19.817 11.492a.75.75 0 01-1.132 0 5.3 5.3 0 00-2.3-1.649.5.5 0 00-.637.481v1.13a6.912 6.912 0 01-4.407 6.47c-3.091 1.216-6.5.157-9.343-2.9A1.01 1.01 0 00.292 16a11.274 11.274 0 0010.741 8c6.351 0 11.717-5.133 11.717-11.208v-2.468a.5.5 0 00-.636-.482 5.306 5.306 0 00-2.297 1.65z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});