define("ember-svg-jar/inlined/road-sign-side-road-angle-right-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-side-road-angle-right-2-alternate</title><path d=\"M15.885 9.779a1 1 0 00-1.174-.435l-1.632.544a.248.248 0 01-.225-.034.252.252 0 01-.1-.2V8.192a1 1 0 00-1-1h-2a1 1 0 00-1 1v8a1 1 0 001 1h2a1 1 0 001-1v-1.959a.25.25 0 01.167-.233l3.395-1.133a1 1 0 00.542-1.463z\"/><path d=\"M23.707 12.707a1 1 0 000-1.414l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0zm-2.3-.884a.25.25 0 010 .354l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});