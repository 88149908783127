define("ember-svg-jar/inlined/single-neutral-podium", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-podium</title><path d=\"M23 16.241H1a.75.75 0 000 1.5h1.219a.25.25 0 01.243.19l1.31 5.242a.75.75 0 00.728.568h15a.75.75 0 00.728-.568l1.31-5.242a.25.25 0 01.243-.19H23a.75.75 0 000-1.5zM5 14.759h14a.5.5 0 00.5-.5 6.76 6.76 0 00-.66-2.712C18.357 10.576 16.8 10 14.646 9.2l-.552-.2c-.007 0-.043-.035-.075-.136A1.441 1.441 0 0114.051 8a6.053 6.053 0 001.3-4.17 3.356 3.356 0 10-6.7 0 6.047 6.047 0 001.287 4.154 1.392 1.392 0 01.043.876c-.032.1-.068.133-.075.136l-.552.204C7.2 10 5.643 10.576 5.16 11.548a6.753 6.753 0 00-.66 2.711.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});