define("ember-svg-jar/inlined/optimization-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>optimization-clock</title><path d=\"M15.25 24a6.5 6.5 0 10-6.5-6.5 6.508 6.508 0 006.5 6.5zm-.75-10.5a.75.75 0 011.5 0v3a.25.25 0 00.25.25h2a.75.75 0 010 1.5h-3a.75.75 0 01-.75-.75zM11.457 9.293a1 1 0 00-1.414 0l-2.5 2.5a1 1 0 001.414 1.415l2.5-2.5a1 1 0 000-1.415zM20.457 9.293a1 1 0 10-1.414 1.415l2.5 2.5a1 1 0 001.414-1.415z\"/><path d=\"M19.75 0h-16a3 3 0 00-3 3v11.5a3 3 0 003 3h2.5a1 1 0 000-2h-2.5a1 1 0 01-1-1V5.25A.25.25 0 013 5h17.5a.25.25 0 01.25.25V8a1 1 0 002 0V3a3 3 0 00-3-3zM4.5 3.5a.75.75 0 11.75-.75.749.749 0 01-.75.75zm2.5 0a.75.75 0 11.75-.75.749.749 0 01-.75.75zm2.5 0a.75.75 0 11.75-.75.749.749 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});