define("ember-svg-jar/inlined/people-man-11", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-11</title><path d=\"M1 24h22a.5.5 0 00.5-.5 2.467 2.467 0 00-.967-1.977 14.264 14.264 0 00-5.908-2.481.5.5 0 00-.48.175c-.084.1-1.252 2.876-4.132 2.888h-.026c-2.892-.012-4.049-2.79-4.133-2.891a.5.5 0 00-.479-.172 14.264 14.264 0 00-5.908 2.481A2.467 2.467 0 00.5 23.5a.5.5 0 00.5.5zM14.293 18.106A7 7 0 0019 11.5v-2a7 7 0 00-3.4-6 .249.249 0 00-.376.178l-.241 1.668a.252.252 0 00.093.233A4.976 4.976 0 0117 9.5v2a5 5 0 01-10 0v-2a4.976 4.976 0 011.926-3.92.251.251 0 00.093-.233l-.241-1.668A.249.249 0 008.4 3.5a7 7 0 00-3.4 6v2a6.981 6.981 0 009.293 6.606z\"/><circle cx=\"14\" cy=\"11.75\" r=\"1\"/><circle cx=\"10\" cy=\"11.75\" r=\"1\"/><path d=\"M13.077 13.862h-2a.639.639 0 00-.3 1.2 2.31 2.31 0 00.435.18 2.99 2.99 0 00.867.126h.006a2.976 2.976 0 00.863-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2zM10.356 7.611a1.045 1.045 0 001.031.889h1.226a1.045 1.045 0 001.031-.889l.839-5.817a1.562 1.562 0 00-.366-1.247 1.584 1.584 0 00-1.2-.547h-1.834a1.584 1.584 0 00-1.2.547 1.562 1.562 0 00-.366 1.247z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});