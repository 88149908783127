define("ember-svg-jar/inlined/legal-scale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>legal-scale</title><path d=\"M16.138 17.309a.248.248 0 00-.15-.05H1a1 1 0 000 2h6.115a.248.248 0 01.185.083 1.738 1.738 0 010 2.334.248.248 0 01-.185.083H1a1 1 0 000 2h15a.254.254 0 00.15-.049 3.991 3.991 0 00-.011-6.4z\"/><path d=\"M20.5 17.259h-2.448a.25.25 0 00-.2.394 4.974 4.974 0 010 5.713.25.25 0 00.2.393H20.5a3.25 3.25 0 000-6.5zM4 12.241a3.913 3.913 0 004-4 1.058 1.058 0 00-.082-.431L5.67 2.59a.25.25 0 01.23-.349h4.85a.25.25 0 01.25.25v8.7a.249.249 0 01-.176.239A3.993 3.993 0 008 15.241a.5.5 0 00.5.5h7a.5.5 0 00.5-.5 3.993 3.993 0 00-2.824-3.808.25.25 0 01-.176-.239v-8.7a.25.25 0 01.25-.25h4.858a.25.25 0 01.23.348L16.08 7.881s-.08.319-.08.36a4 4 0 008 0 4.33 4.33 0 00-.08-.36l-3-7.029A1.055 1.055 0 0020 .241H4a1.057 1.057 0 00-.918.6C2.927 1.213 0 7.842 0 8.241a3.913 3.913 0 004 4zm14.463-4.848l1.307-3.064a.25.25 0 01.46 0l1.307 3.064a.25.25 0 01-.23.348h-2.614a.25.25 0 01-.23-.348zm-16.024 0L3.77 4.3a.251.251 0 01.46 0l1.331 3.092a.251.251 0 01-.23.349H2.669a.251.251 0 01-.23-.349z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});