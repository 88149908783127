define("ember-svg-jar/inlined/deer-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>deer-body</title><path d=\"M24 9.75a1 1 0 00-2 0 1.213 1.213 0 01-.826 1.055l-.014.005a3.976 3.976 0 00-2.41-.81H11.5c-1.321 0-1.771-.642-1.922-1.089l1.663-.277A1.5 1.5 0 0012.5 7.15V6a.5.5 0 00-.276-.447l-.449-.225A4.509 4.509 0 0014.75 1a1 1 0 00-2 0 2.437 2.437 0 01-.8 1.878 5.263 5.263 0 00-.25-1.194 1 1 0 10-1.9.632 2.858 2.858 0 01.12 1.506A8.282 8.282 0 018.25 4H6.5a8.282 8.282 0 01-1.672-.178 2.868 2.868 0 01.12-1.506 1 1 0 00-1.9-.632A5.263 5.263 0 002.8 2.878 2.437 2.437 0 012 1a1 1 0 00-2 0c0 3.084 2.446 4.505 5 4.889V8.5c0 2.969 1.839 6.507 4 7.8v7.2a.5.5 0 00.5.5h2a.5.5 0 00.493-.418l.861-5.164a.5.5 0 01.493-.418h4.6a3.582 3.582 0 001.343.469l.461 5.076a.5.5 0 00.5.455h2a.5.5 0 00.5-.5V14a3.977 3.977 0 00-.327-1.583A3.061 3.061 0 0024 9.75zM8 5.5a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});