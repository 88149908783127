define("ember-svg-jar/inlined/file-pps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-pps</title><path d=\"M6.5 9.627h-.623a.252.252 0 00-.252.252v1.246a.252.252 0 00.252.252H6.5a.875.875 0 100-1.75zM11.5 9.627h-.623a.252.252 0 00-.252.252v1.246a.252.252 0 00.252.252h.623a.875.875 0 000-1.75z\"/><path d=\"M23.5 6A1.5 1.5 0 0022 4.5H2A1.5 1.5 0 00.5 6v12A1.5 1.5 0 002 19.5h20a1.5 1.5 0 001.5-1.5zM4.375 9A.625.625 0 015 8.373h1.5a2.125 2.125 0 010 4.25h-.623a.252.252 0 00-.252.252V15a.625.625 0 01-1.25 0zm5 0A.625.625 0 0110 8.373h1.5a2.125 2.125 0 010 4.25h-.623a.252.252 0 00-.252.252V15a.625.625 0 01-1.25 0zm6.552 1.871l1.84 1.227a1.927 1.927 0 01-1.07 3.531H15a.625.625 0 010-1.25h1.7a.678.678 0 00.376-1.242l-1.84-1.227A1.927 1.927 0 0116.3 8.377H18a.625.625 0 010 1.25h-1.7a.678.678 0 00-.376 1.242z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});