define("ember-svg-jar/inlined/video-game-tetris.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-tetris</title><path d=\"M22 2.5A2.5 2.5 0 0019.5 0h-15A2.5 2.5 0 002 2.5v19A2.5 2.5 0 004.5 24h15a2.5 2.5 0 002.5-2.5zM10.5 19.25a.75.75 0 01-.75.75h-1a.25.25 0 00-.25.25v.984a.75.75 0 01-1.5 0v-.984a.25.25 0 00-.25-.25h-1a.75.75 0 010-1.5h1a.25.25 0 00.25-.25v-.984a.75.75 0 011.5 0v.984a.25.25 0 00.25.25h1a.75.75 0 01.75.75zM15 22a1 1 0 111-1 1 1 0 01-1 1zm2.5-2.5a1 1 0 111-1 1 1 0 01-1 1zm1-5.5a.5.5 0 01-.5.5h-6.5a.5.5 0 01-.5-.5v-2h4.5a1.5 1.5 0 001.5-1.5v-4A1.5 1.5 0 0015.5 5h-3a1 1 0 000 2h2a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-4A1.5 1.5 0 009 11.5V14a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5V3.5A.5.5 0 016 3h12a.5.5 0 01.5.5z\"/><circle cx=\"9\" cy=\"6.25\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});