define("ember-svg-jar/inlined/history-seat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-seat</title><path d=\"M8.5 17.75H7.354a.5.5 0 00-.431.25l-1.167 2a.5.5 0 00.432.752h.645a.5.5 0 00.384-.18l1.667-2a.5.5 0 00-.384-.822zM17.077 18a.5.5 0 00-.432-.248H15.5a.5.5 0 00-.384.82l1.666 2a.5.5 0 00.385.18h.645a.5.5 0 00.432-.752zM6.443 9.25h11.115a.5.5 0 00.491-.406l.192-1a.5.5 0 00-.491-.595H6.25a.5.5 0 00-.491.595l.194 1a.5.5 0 00.49.406zM17.664 10.844a.5.5 0 00-.491-.594H6.831a.5.5 0 00-.491.6l.651 3.35a.5.5 0 01.009.1v1.45a1 1 0 001 1h8a1 1 0 001-1v-.005l-.009-1.354A.541.541 0 0117 14.3zM2 3.25a2 2 0 00-2 2 1.5 1.5 0 001.5 1.5.5.5 0 01.5.5v4a3 3 0 003 3 .53.53 0 00.522-.626L4 5.25a2 2 0 00-2-2zM22 3.25a2 2 0 00-2 2l-1.523 8.374a.531.531 0 00.523.626 3 3 0 003-3v-4a.5.5 0 01.5-.5 1.5 1.5 0 001.5-1.5 2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});