define("ember-svg-jar/inlined/landmarks-statue-flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmarks-statue-flag</title><path d=\"M13.452 9.738a.25.25 0 00.44-.069l.95-2.9a.248.248 0 01.3-.165l5.222 1.286a1.249 1.249 0 001.577-.8l1.245-3.8a1.255 1.255 0 00-.82-1.583L16.933.1a1.412 1.412 0 00-1.25.1 1.383 1.383 0 00-.6 1.016l-2.359 7.2a.254.254 0 00.025.208 12.7 12.7 0 00.703 1.114zM12.258 11.185a1.008 1.008 0 00.142-1.229l-1.742-2.838a3 3 0 00-2.653-1.6h-2a3 3 0 00-3 3V11.5a1 1 0 001 1h1v3a1 1 0 001 1h2a1 1 0 001-1V9.327a.25.25 0 01.458-.139l1.237 1.853a1.007 1.007 0 001.56.144z\"/><circle cx=\"7.005\" cy=\"2\" r=\"2\"/><path d=\"M13.505 24a.75.75 0 100-1.5h-1.889a.249.249 0 01-.186-.083.252.252 0 01-.063-.193l.384-3.646a.752.752 0 00-.189-.58.6.6 0 00-.151-.121.251.251 0 01-.111-.294l1.835-5.6a.1.1 0 00-.16-.1 1.993 1.993 0 01-1.438.607.2.2 0 00-.193.14l-1.621 4.948a.248.248 0 01-.237.172H4.005a.749.749 0 00-.746.828l.383 3.646a.248.248 0 01-.062.193.25.25 0 01-.186.083H1.505a.75.75 0 100 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});