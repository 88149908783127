define("ember-svg-jar/inlined/smiley-cheerful-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-cheerful-alternate</title><path d=\"M16 14.5a1 1 0 00-1 1 1 1 0 01-2 0 1 1 0 00-2 0 1 1 0 01-2 0 1 1 0 00-2 0 3 3 0 005 2.234 3 3 0 005-2.234 1 1 0 00-1-1zM9.084 9.9a1 1 0 00.917.6.973.973 0 00.4-.084 1 1 0 00.515-1.316 3.5 3.5 0 00-6.416 0 1 1 0 101.832.8 1.5 1.5 0 012.751 0zM19.5 9.1a3.5 3.5 0 00-6.415 0 1 1 0 101.832.8 1.5 1.5 0 012.751 0 1 1 0 00.917.6.973.973 0 00.4-.084A1 1 0 0019.5 9.1z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});