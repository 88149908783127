define("ember-svg-jar/inlined/ice-cream-cone-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ice-cream-cone-1</title><path d=\"M18.625 8.25v-.5a.92.92 0 10-1.833 0v.5a.994.994 0 01-.629.962l-8.1 2.661a2.64 2.64 0 00-1.164.784A1 1 0 016.14 13h-.265a.5.5 0 00-.441.735l5 9.359a1.6 1.6 0 002.876.019l5.008-9.378a.5.5 0 00-.443-.735H12.55a.25.25 0 01-.078-.488l4.22-1.385a2.991 2.991 0 001.933-2.877zM7.875 10.083a1 1 0 01-1-1 2.345 2.345 0 011.68-2.24l5.574-1.771a.338.338 0 00.246-.322v-.333a1 1 0 012 0v.333A2.345 2.345 0 0114.7 6.99L9.121 8.761a.338.338 0 00-.246.322 1 1 0 01-1 1zM12.616 1.071a.5.5 0 00.331-.348A1 1 0 0114.913 1v.185a1.76 1.76 0 01-1.349 1.679L10.08 3.986a.3.3 0 00-.206.254 1 1 0 01-2-.027v-.37a1.76 1.76 0 011.35-1.679z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});