define("ember-svg-jar/inlined/face-id-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>face-id-5</title><path d=\"M7.7 22l-5.2.039a.5.5 0 01-.5-.5v-5.234a1 1 0 00-2 0v5.738A1.959 1.959 0 001.956 24H7.7a1 1 0 000-2zM1 8.7a1 1 0 001-1l-.04-5.2a.5.5 0 01.5-.5H7.7a1 1 0 000-2H1.956A1.959 1.959 0 000 1.957V7.7a1 1 0 001 1zM23 15.305a1 1 0 00-1 1l.04 5.191a.5.5 0 01-.5.5h-5.235a1 1 0 000 2h5.739A1.959 1.959 0 0024 22.043v-5.738a1 1 0 00-1-1zM22.044 0h-5.739a1 1 0 000 2l5.195-.039a.5.5 0 01.5.5V7.7a1 1 0 002 0V1.957A1.959 1.959 0 0022.044 0z\"/><path d=\"M8.963 10.5a1.3 1.3 0 00.29.594 2.631 2.631 0 00.834 1.654v1.113l-.558.206c-2.008.739-3.458 1.272-3.911 2.179A6.223 6.223 0 005 18.767a.5.5 0 00.5.5h13a.5.5 0 00.5-.5 6.223 6.223 0 00-.618-2.517c-.454-.907-1.905-1.44-3.913-2.179l-.556-.206v-1.113a2.631 2.631 0 00.834-1.654 1.3 1.3 0 00.29-.594 1.534 1.534 0 00-.2-1.244v-.01a4.857 4.857 0 00.471-2.482c-.212-1.061-1.58-1.47-2.79-1.5a3.143 3.143 0 00-2.547.84 1.266 1.266 0 00-.811.492c-.55.742-.187 1.966.017 2.639a1.519 1.519 0 00-.214 1.261z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});