define("ember-svg-jar/inlined/outdoors-pig-apple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-pig-apple</title><path d=\"M23.933 1.067a1 1 0 00-1-1H12.61a1.492 1.492 0 00-1.491 1.492v19.17a1.492 1.492 0 001.491 1.492h10.323a1 1 0 000-2h-9.314a.5.5 0 01-.5-.5V2.567a.5.5 0 01.5-.5h9.314a1 1 0 001-1zM.5 12.615a.5.5 0 00-.43.495v10.323a.5.5 0 00.908.291l1.928-2.7a1.465 1.465 0 011.072-.608l3.889-.324a2.48 2.48 0 002-1.355l.541-1.082a1.486 1.486 0 00-.663-1.99l-1.81-.906a.246.246 0 01-.118-.124 3.628 3.628 0 00-1.741-1.821.25.25 0 01-.135-.277 1.925 1.925 0 00.043-.409 1.975 1.975 0 10-3.949 0v.047a.25.25 0 01-.215.253z\"/><path d=\"M16.281 9.439a2.66 2.66 0 00-.947 2.44 5.087 5.087 0 00.9 2.316 2.226 2.226 0 001.8.892 2.407 2.407 0 00.828-.149l.238-.086a.251.251 0 01.171 0l.237.086a2.269 2.269 0 002.626-.743 5.076 5.076 0 00.9-2.317 2.657 2.657 0 00-.947-2.439 2.31 2.31 0 00-2.766-.155.25.25 0 01-.267 0 2.306 2.306 0 00-2.773.155zM20.379 8.14l.983-.984a1 1 0 10-1.414-1.414l-.983.984a1 1 0 101.414 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});