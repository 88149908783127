define("ember-svg-jar/inlined/construction-shovel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>construction-shovel</title><path d=\"M8.474 18.025a.248.248 0 00.052-.2.252.252 0 00-.12-.172 5.107 5.107 0 00-2.566-.666c-2.886 0-4.591 5.312-4.983 6.681a.248.248 0 00.041.22.251.251 0 00.2.1h3.435a.249.249 0 00.249-.222 2 2 0 01.251-.77 38.61 38.61 0 013.441-4.971zM16.408 12.135a.25.25 0 00.184-.325 2.484 2.484 0 00-.569-.968l-2.4-2.4a1 1 0 00-1.414 0L11.145 9.5l-6.01-6.01L6.9 1.723A1 1 0 005.488.309L.539 5.258a1 1 0 001.414 1.414L3.721 4.9l6.011 6.011-1.061 1.065a1 1 0 000 1.414l1.948 1.948a.251.251 0 00.347.006 12.225 12.225 0 015.442-3.209zM17.77 13.984c-4.67 0-9.668 7.8-10.775 9.621a.25.25 0 00.214.379H23.5a.254.254 0 00.183-.079.252.252 0 00.066-.189c-.11-1.591-.942-9.732-5.979-9.732z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});