define("ember-svg-jar/inlined/swimming-jump", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>swimming-jump</title><circle cx=\"18\" cy=\"5.901\" r=\"2.5\"/><path d=\"M7.614 13.574l-1.868 2.9a.5.5 0 00.421.771h2.378a.5.5 0 00.421-.23l1.9-2.959a1.5 1.5 0 00-.323-1.981l-2.28-1.83H12.5a1.5 1.5 0 001.262-2.312l-4.5-7a1.5 1.5 0 10-2.524 1.628L9.752 7.25H4a1.5 1.5 0 00-.938 2.669zM24 22.7a.5.5 0 00-.166-.367.471.471 0 00-.384-.125 2.828 2.828 0 01-2.818-1.529.523.523 0 00-.858-.079 4.232 4.232 0 01-3.085 1.623 3.626 3.626 0 01-2.793-1.594.523.523 0 00-.819-.045A4.528 4.528 0 0110 22.224v-2.473a1 1 0 00-1-1H.5a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h23a.5.5 0 00.5-.505z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});