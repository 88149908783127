define("ember-svg-jar/inlined/kindle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kindle-1</title><path d=\"M19.5 0h-15A1.5 1.5 0 003 1.5v21A1.5 1.5 0 004.5 24h15a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0019.5 0zM6 20a1 1 0 01-1-1V3a1 1 0 011-1h12a1 1 0 011 1v16a1 1 0 01-1 1z\"/><path d=\"M8.5 6.251h7a.75.75 0 000-1.5h-7a.75.75 0 000 1.5zM8.5 9.751h7a.75.75 0 000-1.5h-7a.75.75 0 000 1.5zM8.5 13.251h7a.75.75 0 000-1.5h-7a.75.75 0 000 1.5zM8.5 16.751H14a.75.75 0 100-1.5H8.5a.75.75 0 100 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});