define("ember-svg-jar/inlined/pregnancy-eggs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pregnancy-eggs</title><path d=\"M16.5 13C12.36 13 9 15.47 9 18.5s3.36 5.5 7.5 5.5 7.5-2.47 7.5-5.5-3.364-5.5-7.5-5.5zm0 8.75c-2.379 0-4.25-1.43-4.25-3.25a.75.75 0 011.5 0c0 .95 1.26 1.75 2.75 1.75s2.75-.8 2.75-1.75a.75.75 0 011.5 0c-.005 1.82-1.875 3.25-4.25 3.25zM11.882 1.28A4.888 4.888 0 007.5.09a9.061 9.061 0 00-4.806 2.6C-.235 5.618-.869 9.742 1.275 11.887a4.592 4.592 0 003.32 1.27 8.609 8.609 0 005.873-2.684 9.066 9.066 0 002.6-4.808 4.874 4.874 0 00-1.186-4.385zm-3 7.6c-1.706 1.705-4.049 1.961-5.3.707a.75.75 0 111.06-1.061c.6.6 2.032.444 3.182-.707 1.054-1.053 1.378-2.511.707-3.181a.75.75 0 011.053-1.06c1.288 1.289.978 3.622-.707 5.303z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});