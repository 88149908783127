define("ember-svg-jar/inlined/love-gift-couple-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-gift-couple-box</title><path d=\"M22.5 5.624h-3.768a.25.25 0 01-.2-.4A3.377 3.377 0 0013.4.864a6.919 6.919 0 00-1.171 2.1.25.25 0 01-.464 0A6.919 6.919 0 0010.6.864a3.377 3.377 0 00-5.13 4.364.25.25 0 01-.2.4H1.5A1.5 1.5 0 000 7.124v2.5a1 1 0 001 1h.5a.5.5 0 01.5.5v11.5a1.5 1.5 0 001.5 1.5h17a1.5 1.5 0 001.5-1.5v-11.5a.5.5 0 01.5-.5h.5a1 1 0 001-1v-2.5a1.5 1.5 0 00-1.5-1.5zM7.234 2.278a1.38 1.38 0 011.949 0 10.516 10.516 0 011.211 2.753.25.25 0 01-.307.32 10.021 10.021 0 01-2.853-1.126 1.38 1.38 0 010-1.947zm.516 6.346a2 2 0 11-2 2 2 2 0 012-2zm2.75 9.5a.25.25 0 01-.25.25h-.588a.5.5 0 00-.5.438l-.418 3.344a.249.249 0 01-.248.218H7a.249.249 0 01-.248-.218l-.418-3.344a.5.5 0 00-.5-.438H5.25a.25.25 0 01-.25-.25v-2a2.75 2.75 0 015.5 0zm7.75-7.5a2 2 0 11-2-2 2 2 0 012 2zM13.607 5.03a10.5 10.5 0 011.21-2.752 1.377 1.377 0 011.949 1.947 10.029 10.029 0 01-2.853 1.125.25.25 0 01-.306-.32zM19 18.124a.25.25 0 01-.25.25h-.588a.5.5 0 00-.5.438l-.418 3.344a.249.249 0 01-.248.218H15.5a.249.249 0 01-.248-.218l-.418-3.344a.5.5 0 00-.5-.438h-.584a.25.25 0 01-.25-.25v-2a2.75 2.75 0 015.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});