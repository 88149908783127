define("ember-svg-jar/inlined/fruit-scale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fruit-scale</title><path d=\"M2.122 16.25a.249.249 0 00-.247.209l-.48 2.88A2.5 2.5 0 003.86 22.25h16.279a2.5 2.5 0 002.466-2.91l-.48-2.881a.249.249 0 00-.247-.209zm8.045 3.833H4.833a.833.833 0 110-1.666h5.334a.833.833 0 010 1.666zM18.5 18.25a1 1 0 11-1 1 1 1 0 011-1zm-3.042 0a1 1 0 11-1 1 1 1 0 011-1zM22.811 11.922l.03-.1a.25.25 0 00-.241-.322H1.4a.25.25 0 00-.239.325l.03.1c.392 1.261.88 2.83 2.752 2.83h16.116c1.87-.005 2.341-1.508 2.752-2.833zM22.107 10a.251.251 0 00.229-.352l-3.115-7.007a1.5 1.5 0 00-1.37-.891H6.149a1.5 1.5 0 00-1.37.891L1.665 9.648a.249.249 0 00.018.238.251.251 0 00.21.114z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});