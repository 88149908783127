define("ember-svg-jar/inlined/love-heart-ranking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-heart-ranking</title><path d=\"M14.854.018a2.822 2.822 0 00-2.495 1.02.468.468 0 01-.717 0 2.821 2.821 0 00-2.5-1.02A3.1 3.1 0 006.5 3.231c0 2.427 3.423 5.312 4.9 6.44a.981.981 0 001.208 0c1.472-1.127 4.9-4.013 4.9-6.44A3.1 3.1 0 0014.854.018z\"/><rect x=\"2\" y=\"10\" width=\"3.5\" height=\"14\" rx=\"1\" ry=\"1\"/><rect x=\"13\" y=\"16\" width=\"3.5\" height=\"8\" rx=\"1\" ry=\"1\"/><rect x=\"18.5\" y=\"10\" width=\"3.5\" height=\"14\" rx=\"1\" ry=\"1\"/><rect x=\"7.5\" y=\"13.5\" width=\"3.5\" height=\"10.5\" rx=\"1\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});