define("ember-svg-jar/inlined/protection-castle-gate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>protection-castle-gate</title><path d=\"M23.5 0h-1a.5.5 0 00-.5.5V1a1 1 0 01-2 0V.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5V1a1 1 0 01-2 0V.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5V3a2 2 0 002 2h6a2 2 0 002-2V.5a.5.5 0 00-.5-.5zM9.785 21.3a2.693 2.693 0 01-3.571 0 1.029 1.029 0 00-1.428 0 2.6 2.6 0 01-3.214.252 1 1 0 00-1.144 1.641 4.7 4.7 0 004.944.171.25.25 0 01.256 0A4.59 4.59 0 008 24a4.433 4.433 0 003.214-1.3 1 1 0 10-1.429-1.4zM22.5 6.5h-7a1.34 1.34 0 00-.5.133L3.642 13.175a.253.253 0 01-.255 0l-.868-.527a1 1 0 00-1.038 1.71l13.4 8.135A.25.25 0 0115 22.7v.8a.5.5 0 00.5.5h7a.5.5 0 00.5-.5V7a.5.5 0 00-.5-.5zM15 19.778a.25.25 0 01-.38.214l-8.794-5.339a.252.252 0 01-.12-.217.25.25 0 01.125-.214l8.794-5.065a.251.251 0 01.375.217zM21 13a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-1.5a2 2 0 014 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});