define("ember-svg-jar/inlined/style-three-pin-power-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-power-button</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm-.75 4.5a.75.75 0 011.5 0v4a.75.75 0 01-1.5 0zM12 14.75A5.249 5.249 0 018.088 6a.75.75 0 111.117 1A3.75 3.75 0 1014.8 7a.75.75 0 111.117-1A5.249 5.249 0 0112 14.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});