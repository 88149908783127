define("ember-svg-jar/inlined/content-typing-machine-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-typing-machine-3</title><path d=\"M20.972 15.335A.5.5 0 0020.5 15h-17a.5.5 0 00-.471.335l-1.98 5.674A2.5 2.5 0 003.5 24h17a2.494 2.494 0 002.433-3.058zM17.25 19.5a1 1 0 11-1-1 1 1 0 011 1zm-4.25 0a1 1 0 11-1-1 1 1 0 011 1zm-4.25 0a1 1 0 11-1-1 1 1 0 011 1zM6.25 7.5a1 1 0 001-1V2.25A.25.25 0 017.5 2h7.733a.251.251 0 01.177.073l1.267 1.268a.249.249 0 01.073.177V6.5a1 1 0 002 0V3.414A2 2 0 0018.165 2L16.75.586A2 2 0 0015.336 0H7.25a2 2 0 00-2 2v4.5a1 1 0 001 1zM2 12.5h1v.5a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-.5h1a1 1 0 000-2h-1.05a2.5 2.5 0 00-2.45-2h-13a2.5 2.5 0 00-2.449 2H2a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});