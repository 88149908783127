define("ember-svg-jar/inlined/day-sunset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>day-sunset</title><path d=\"M6.843 15.984a.249.249 0 00.2.081.254.254 0 00.188-.1 2.012 2.012 0 011.093-.731.25.25 0 00.187-.242v-.061a.247.247 0 00-.072-.175 5 5 0 117.143-.023.25.25 0 00-.071.175V15a.251.251 0 00.185.242 2.026 2.026 0 011.081.721.249.249 0 00.382.017 7 7 0 10-10.311.009zM1.744 12.25h1.5a1 1 0 000-2h-1.5a1 1 0 000 2z\"/><path d=\"M5.1 5.77a1 1 0 101.414-1.415L5.453 3.3a1 1 0 00-1.414 1.409zM11.994 3.5a1 1 0 001-1V1a1 1 0 00-2 0v1.5a1 1 0 001 1zM5.1 16.73l-1.061 1.061A1 1 0 105.453 19.2l1.061-1.06A1 1 0 105.1 16.73zM19.744 11.25a1 1 0 001 1h1.5a1 1 0 000-2h-1.5a1 1 0 00-1 1zM18.182 6.063a1 1 0 00.707-.293l1.06-1.061A1 1 0 1018.535 3.3l-1.06 1.06a1 1 0 00.707 1.708zM18.889 16.73a1 1 0 00-1.414 1.415l1.06 1.06a1 1 0 001.414-1.414zM15.514 17.543a.5.5 0 00-.332-.874h-.926a.25.25 0 01-.25-.25v-3.75a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v3.75a.25.25 0 01-.25.25h-.935a.5.5 0 00-.332.874l3.184 2.831a.5.5 0 00.665 0zM22.994 22H1.006a1 1 0 000 2h21.988a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});