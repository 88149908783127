define("ember-svg-jar/inlined/crypto-currency-bitcoin-wallet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-wallet</title><path d=\"M19 6.75h-1.5a.25.25 0 00-.25.25v1a.25.25 0 00.25.25H19a.75.75 0 000-1.5z\"/><path d=\"M24 6a6 6 0 00-12 0 5.905 5.905 0 00.15 1.3.248.248 0 00.243.2H14.5a4.559 4.559 0 01.948.106.25.25 0 00.3-.245V3a.75.75 0 01.75-.75.25.25 0 00.252-.25.75.75 0 011.5 0 .25.25 0 00.25.25h.5a2.253 2.253 0 012.25 2.25 2.219 2.219 0 01-.46 1.349.249.249 0 000 .3 2.219 2.219 0 01.46 1.351A2.253 2.253 0 0119 9.75a.186.186 0 00-.162.277 4.969 4.969 0 01.55 1.527.249.249 0 00.317.193A6 6 0 0024 6z\"/><path d=\"M17.5 3.75a.25.25 0 00-.25.25v1a.25.25 0 00.25.25H19a.75.75 0 000-1.5zM18 20.333a.25.25 0 00-.25-.25h-3.788a2.8 2.8 0 01-2.712-2.875v-1.416a2.8 2.8 0 012.712-2.875h3.788a.25.25 0 00.25-.25V12.5A3.5 3.5 0 0014.5 9H2a2 2 0 00-2 2v11a2 2 0 002 2h12.5a3.5 3.5 0 003.5-3.5z\"/><path d=\"M18.539 14.417h-4.577a1.3 1.3 0 00-1.212 1.375v1.416a1.3 1.3 0 001.212 1.375h4.577a1.3 1.3 0 001.211-1.375v-1.416a1.3 1.3 0 00-1.211-1.375z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});