define("ember-svg-jar/inlined/gaming-ribbon-100", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gaming-ribbon-100</title><path d=\"M23.366 19.266l-2.4-4.138A.254.254 0 0020.76 15a.25.25 0 00-.216.111 10.283 10.283 0 01-5.077 3.989.25.25 0 00-.131.363l1.975 3.343a1 1 0 001.8-.172l.832-2.287 2.392.405a1.019 1.019 0 00.988-.418 1.008 1.008 0 00.043-1.068zM3.471 15.09a.25.25 0 00-.425.012L.634 19.267a1 1 0 001.032 1.487l2.391-.405.833 2.288a1 1 0 001.8.168l1.98-3.351a.251.251 0 00.02-.212.248.248 0 00-.15-.15 10.286 10.286 0 01-5.069-4.002zM12 8.206a1 1 0 00-1 1v1a1 1 0 002 0v-1a1 1 0 00-1-1zM18 10.206v-1a1 1 0 00-2 0v1a1 1 0 002 0z\"/><path d=\"M20.766 9.456a8.75 8.75 0 10-8.75 8.75 8.76 8.76 0 008.75-8.75zM6 11.706a.5.5 0 01.5-.5H7v-3l-.64.48a.5.5 0 11-.6-.8l.64-.48a.994.994 0 011.049-.094A1 1 0 018 8.206v3h.5a.5.5 0 010 1h-2a.5.5 0 01-.5-.5zm13-1.5a2 2 0 01-4 0v-1a2 2 0 014 0zm-7 2a2 2 0 01-2-2v-1a2 2 0 014 0v1a2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});