define("ember-svg-jar/inlined/casino-player-dice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>casino-player-dice</title><path d=\"M16.4 13.5a1 1 0 01-1 1h-8a1 1 0 01-1-1v-8a1 1 0 011-1h8a1 1 0 011 1zm5.076-3.642a7.246 7.246 0 01-.72-1.452C20.758 3.145 15.9 0 11.2 0A9.972 9.972 0 001.1 10.063c0 3.633 1.241 6.217 3.79 7.885a.25.25 0 01.11.209V23.5a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-2.731a.25.25 0 01.244-.25c1.628-.035 2.692-.245 3.425-.978.781-.781.96-3.026 1-4.282a.25.25 0 01.252-.243c.259 0 .547 0 .8-.01 1.078-.048 1.139-.9 1.174-1.4.081-1.117-.715-2.514-1.417-3.748z\"/><circle cx=\"11.402\" cy=\"9.5\" r=\"1\"/><circle cx=\"8.902\" cy=\"7\" r=\"1\"/><circle cx=\"13.902\" cy=\"7\" r=\"1\"/><circle cx=\"8.902\" cy=\"12\" r=\"1\"/><circle cx=\"13.902\" cy=\"12\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});