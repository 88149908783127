define("ember-svg-jar/inlined/business-trophy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-trophy</title><path d=\"M8.111 23.75h7.778a1.006 1.006 0 100-2 .467.467 0 01-.34-.157l-.523-.523A3.522 3.522 0 0114 18.594v-.124c0-.1.053-.22.159-.22H15a.75.75 0 000-1.5H9a.75.75 0 000 1.5h.782a.222.222 0 01.218.188v.156a3.522 3.522 0 01-1.026 2.476l-.547.546a.568.568 0 01-.316.134 1.006 1.006 0 100 2zM18.5 7.75a.5.5 0 00.5-.5v-3.5a.5.5 0 00-.5-.5h-13a.5.5 0 00-.5.5v3.5a.5.5 0 00.5.5zm-2.5-3a.75.75 0 11-.75.75.75.75 0 01.75-.75zm-4 0a.75.75 0 11-.75.75.75.75 0 01.75-.75zm-4 0a.75.75 0 11-.75.75.75.75 0 01.75-.75zM3 1.25a1.035 1.035 0 001.067 1h15.866a1.035 1.035 0 001.067-1 1.035 1.035 0 00-1.067-1H4.067A1.035 1.035 0 003 1.25zM4.5 10.25a1 1 0 001 1 .385.385 0 01.307.237 6.978 6.978 0 0012.405-.037.337.337 0 01.288-.2 1 1 0 000-2h-13a1 1 0 00-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});