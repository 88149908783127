define("ember-svg-jar/inlined/smart-light-connect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-light-connect</title><path d=\"M6.036 10.4a.992.992 0 101.4 1.4 6.458 6.458 0 019.122 0 .992.992 0 101.4-1.4 8.446 8.446 0 00-11.922 0z\"/><path d=\"M3.23 7.593A.992.992 0 004.633 9a10.432 10.432 0 0114.734 0 .992.992 0 001.4-1.4A12.417 12.417 0 003.23 7.593z\"/><path d=\"M23.576 4.787a16.39 16.39 0 00-23.152 0 .992.992 0 001.4 1.4 14.4 14.4 0 0120.346 0 .992.992 0 001.4-1.4zM13.922 20.527h-3.844a.5.5 0 00-.481.62 2.474 2.474 0 001.726 1.762.247.247 0 01.181.238v.353a.5.5 0 10.992 0v-.356a.248.248 0 01.18-.239 2.472 2.472 0 001.724-1.758.5.5 0 00-.48-.62zM12 12.094a4.286 4.286 0 00-2.977 7.3.493.493 0 00.345.14h1.392a.5.5 0 00.5-.5v-1.448a.25.25 0 00-.111-.207l-1.046-.7a.744.744 0 01.825-1.239l.938.626a.25.25 0 00.275 0l.938-.626a.745.745 0 01.826 1.239l-1.047.7a.249.249 0 00-.11.207v1.453a.5.5 0 00.5.5h1.391a.5.5 0 00.346-.14A4.286 4.286 0 0012 12.094z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});