define("ember-svg-jar/inlined/equipment-safety-mask", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>equipment-safety-mask</title><path d=\"M17.5 0h-11A4.505 4.505 0 002 4.5V14a10 10 0 0020 0V4.5A4.505 4.505 0 0017.5 0zM19 10a3 3 0 01-3 3H8a3 3 0 01-3-3V6.5a3 3 0 013-3h8a3 3 0 013 3z\"/><path d=\"M16 11.75H8A1.752 1.752 0 016.25 10V6.5A1.752 1.752 0 018 4.75h8a1.752 1.752 0 011.75 1.75V10A1.752 1.752 0 0116 11.75zm-8-5.5a.25.25 0 00-.25.25V10a.25.25 0 00.25.25h8a.25.25 0 00.25-.25V6.5a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});