define("ember-svg-jar/inlined/vr-headphones", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vr-headphones</title><path d=\"M2 16h10a2.98 2.98 0 011.416.356.25.25 0 00.362-.279c-.268-1.088-.793-3.2-.793-3.2a.5.5 0 00-.485-.377H11a.5.5 0 00-.354.146 2.892 2.892 0 01-2.06.854H5.414a2.892 2.892 0 01-2.06-.854A.5.5 0 003 12.5H1.5a.5.5 0 00-.485.379s-.525 2.11-.793 3.2a.25.25 0 00.362.28A2.98 2.98 0 012 16zM12 17H2a2 2 0 00-2 2v3a2 2 0 002 2h10a2 2 0 002-2v-3a2 2 0 00-2-2zm-9.5 2h2a.5.5 0 010 1h-2a.5.5 0 010-1zm5.5.5a.5.5 0 01-.5.5h-1a.5.5 0 010-1h1a.5.5 0 01.5.5z\"/><path d=\"M24 7.975A1.977 1.977 0 0022.025 6 .025.025 0 0122 5.975V5.95a6 6 0 00-12 0v.025A.025.025 0 019.975 6 1.977 1.977 0 008 7.975v1.05A1.977 1.977 0 009.975 11H11.5a.5.5 0 00.5-.5V5.95a4 4 0 018 0v4.55a.5.5 0 00.333.472.249.249 0 01.167.235V17.5a.5.5 0 01-1 0v-2A2.5 2.5 0 0017 13h-1a1 1 0 000 2h1a.5.5 0 01.5.5v2a2.5 2.5 0 005 0v-6.372a.251.251 0 01.168-.236A1.977 1.977 0 0024 9.025z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});