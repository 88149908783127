define("ember-svg-jar/inlined/messages-bubble-square-heart-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-heart-1-alternate</title><path d=\"M8.5 14a1 1 0 00-.664.252L5 16.773V15a1 1 0 00-1-1H2.249A.251.251 0 012 13.747V2.25A.25.25 0 012.25 2h18a.249.249 0 01.25.25v8.521a.252.252 0 00.244.25 4.261 4.261 0 011.42.275.254.254 0 00.23-.029.251.251 0 00.106-.206V2a2 2 0 00-2-2H2a2 2 0 00-2 2v12.053A1.953 1.953 0 002 16h1v3a1 1 0 001.664.748L8.88 16h.38a.249.249 0 00.249-.236 5 5 0 01.3-1.429.25.25 0 00-.238-.335z\"/><path d=\"M21.039 12.546a3.483 3.483 0 00-3.058.987l-.481.482-.482-.482a3.526 3.526 0 00-5.646.916 3.521 3.521 0 00.653 4.063l5.113 5.334a.5.5 0 00.723 0l5.106-5.327a3.524 3.524 0 00.66-4.07 3.487 3.487 0 00-2.588-1.903z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});