define("ember-svg-jar/inlined/aircraft-chopper-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>aircraft-chopper-2</title><path d=\"M16.432 6.5h-3.684a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h5.729a1 1 0 000-2h-5.729a.25.25 0 01-.25-.25V2.5a1 1 0 00-2 0v.25a.25.25 0 01-.25.25H4.5a1 1 0 000 2h5.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-.761a9.133 9.133 0 00-8.989 9 3 3 0 003 3h.268a.25.25 0 01.232.344l-.61 1.5a.25.25 0 01-.231.156h-.436a.25.25 0 01-.245-.2 1 1 0 00-1.98.2 2 2 0 002 2h11a2 2 0 002-2 1 1 0 00-1.98-.2.249.249 0 01-.245.2h-.434a.251.251 0 01-.232-.156l-.744-1.834a.249.249 0 01.128-.321c1.423-.643 1.765-2.211 2.014-3.442.323-1.592.6-2.927 2.359-3.477a.246.246 0 00.157-.145.251.251 0 00-.012-.214A3.96 3.96 0 0116.018 9a3.918 3.918 0 01.625-2.116.25.25 0 00-.211-.384zM3.008 14a.251.251 0 01-.2-.1.254.254 0 01-.041-.22A7.073 7.073 0 019.489 8.5H9.6a.249.249 0 01.25.25V13a1 1 0 01-1 1zm7.34 6.39a.249.249 0 01-.207.11H5.855a.249.249 0 01-.232-.344l.609-1.5a.251.251 0 01.232-.156h3.068a.25.25 0 01.231.156l.61 1.5a.25.25 0 01-.025.234z\"/><path d=\"M23.489 10.956a.978.978 0 00-.264-.554L21.973 9.15a.25.25 0 010-.353l1.238-1.238a1.05 1.05 0 000-1.414 1.015 1.015 0 00-1.414 0l-1.238 1.238a.25.25 0 01-.353 0L19 6.175a1 1 0 10-1.414 1.414L18.792 8.8a.25.25 0 010 .353l-1.216 1.215a1 1 0 001.415 1.414l1.215-1.215a.25.25 0 01.353 0l1.252 1.252a1 1 0 00.7.292h.008a.978.978 0 00.971-1.153z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});