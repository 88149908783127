define("ember-svg-jar/inlined/single-man-actions-share-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-share-2</title><path d=\"M13.749 14a4.146 4.146 0 011.251.194.256.256 0 00.2-.024l1.033-.62a.251.251 0 00.1-.307 2.234 2.234 0 00-1.255-1.321l-3.936-1.687a.251.251 0 01-.152-.23V8.87a.252.252 0 01.074-.177 3.305 3.305 0 00.779-2.23.251.251 0 01.088-.177 1.873 1.873 0 00.538-1.4 1.58 1.58 0 00-.317-1.015.252.252 0 01-.034-.265 2.527 2.527 0 00.157-1.726C11.944.492 10.242 0 8.888 0a3.752 3.752 0 00-3.163 1.324.251.251 0 01-.212.118 1.072 1.072 0 00-.9.375c-.372.493-.106 1.171.176 1.732a.251.251 0 01-.046.288 1.3 1.3 0 00-.162.2A1.765 1.765 0 004.32 5a1.524 1.524 0 00.638 1.331.249.249 0 01.117.194A3.167 3.167 0 005.92 8.7a.25.25 0 01.08.183v1.122a.25.25 0 01-.151.23l-3.937 1.687C.64 12.468.457 13.626.205 15.23c-.055.359-.117.751-.2 1.179A.5.5 0 00.5 17h9.028a.251.251 0 00.236-.166A4.238 4.238 0 0113.749 14z\"/><path d=\"M24 21.25a2.741 2.741 0 00-4.637-1.989.251.251 0 01-.265.051l-2.446-.979a.25.25 0 01-.157-.217c0-.044-.007-.1-.016-.161a.25.25 0 01.119-.247L19.436 16a.251.251 0 01.271.009 2.708 2.708 0 001.542.487 2.75 2.75 0 10-2.75-2.75 2.214 2.214 0 00.022.295.25.25 0 01-.119.247L15.563 16a.251.251 0 01-.271-.009 2.709 2.709 0 00-1.543-.487 2.75 2.75 0 101.888 4.739.25.25 0 01.265-.052l2.445.979a.249.249 0 01.157.223A2.749 2.749 0 0024 21.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});