define("ember-svg-jar/inlined/helmet-sports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>helmet-sports</title><path d=\"M18.5 13.5h-1a.5.5 0 00-.5.5 9.393 9.393 0 01-.392 3.531c-.336.629-1.211.866-2.659.941a.5.5 0 00-.472.467 4.312 4.312 0 01-.154.912.5.5 0 00.5.636c1.807-.065 3.681-.389 4.55-2.013.518-.974.617-2.374.627-4.474a.5.5 0 00-.5-.5z\"/><path d=\"M23 9.995h-2.8a.5.5 0 01-.485-.382A10 10 0 000 12c0 5.524 2.974 10 8.5 10a3.5 3.5 0 003.5-3.5V13a1 1 0 011-1h10a1 1 0 000-2zm-15.751 9.75a3 3 0 113-3 3 3 0 01-3 3z\"/><circle cx=\"7.249\" cy=\"16.745\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});