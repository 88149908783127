define("ember-svg-jar/inlined/religion-bible", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-bible</title><path d=\"M19.75 4V2a2 2 0 00-2-2H5.25a3 3 0 00-3 3v18a3 3 0 003 3h14.5a2 2 0 002-2V6a2 2 0 00-2-2zm-11 9a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h2a.5.5 0 00.5-.5v-2a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v2a.5.5 0 00.5.5h2a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-2a.5.5 0 00-.5.5v5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-5a.5.5 0 00-.5-.5zM5.25 2h12a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-12a1 1 0 010-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});