define("ember-svg-jar/inlined/history-geisha-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-geisha-1</title><circle cx=\"14.75\" cy=\"15.75\" r=\"1.25\"/><circle cx=\"9.25\" cy=\"15.75\" r=\"1.25\"/><path d=\"M12.954 18.5a.521.521 0 00-.229.054l-.5.251a.5.5 0 01-.447 0l-.5-.251a.522.522 0 00-.23-.054.514.514 0 00-.487.676l.339 1.034a1.157 1.157 0 002.194 0l.344-1.034a.513.513 0 00-.487-.676z\"/><path d=\"M18.419 5.8a.25.25 0 00-.2-.023.254.254 0 00-.153.132A4.5 4.5 0 0114 8.5h-4a4.5 4.5 0 01-4.067-2.593.251.251 0 00-.353-.107 7.766 7.766 0 00-4.08 6.7 7.311 7.311 0 003.49 6.05 7.233 7.233 0 0014.02 0 7.311 7.311 0 003.49-6.05 7.765 7.765 0 00-4.081-6.7zm-.669 10.95a5.75 5.75 0 01-11.5 0v-.98A5.557 5.557 0 018.4 11.4a.251.251 0 01.336.025 4.487 4.487 0 006.522 0 .251.251 0 01.342-.025 5.557 5.557 0 012.153 4.37z\"/><path d=\"M3 5h3.482a.252.252 0 01.236.166A3.489 3.489 0 0010 7.5h4a3.489 3.489 0 003.282-2.334.251.251 0 01.236-.166H21c.553 0 1-.947 1-1.5s-.447-.5-1-.5h-1.331a.25.25 0 01-.192-.41l.792-.949A1 1 0 0018.731.359l-1.437 1.725a.249.249 0 01-.205.09.255.255 0 01-.2-.111A3.476 3.476 0 0014 .5h-4a3.479 3.479 0 00-2.895 1.563.251.251 0 01-.194.111.248.248 0 01-.2-.09L5.269.359a1 1 0 00-1.538 1.282l.791.949A.25.25 0 014.33 3H3a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});