define("ember-svg-jar/inlined/mobile-phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mobile-phone</title><path d=\"M17.25 0H6.75a2.5 2.5 0 00-2.5 2.5v19a2.5 2.5 0 002.5 2.5h10.5a2.5 2.5 0 002.5-2.5v-19a2.5 2.5 0 00-2.5-2.5zm.5 17.5a1.5 1.5 0 01-1.5 1.5h-8.5a1.5 1.5 0 01-1.5-1.5v-14A1.5 1.5 0 017.75 2h8.5a1.5 1.5 0 011.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});