define("ember-svg-jar/inlined/switch-off-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>switch-off-1</title><path d=\"M16.5 5h-9a.5.5 0 00-.5.5v13a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-13a.5.5 0 00-.5-.5zM15 12.5a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5z\"/><path d=\"M22.4 0H1.6A1.6 1.6 0 000 1.6v20.8A1.6 1.6 0 001.6 24h20.8a1.6 1.6 0 001.6-1.6V1.6A1.6 1.6 0 0022.4 0zM19 19a2 2 0 01-2 2H7a2 2 0 01-2-2V5a2 2 0 012-2h10a2 2 0 012 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});