define("ember-svg-jar/inlined/video-projector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-projector</title><path d=\"M9.343 9.937l7.2-4a.5.5 0 000-.874l-7.2-4A.5.5 0 008.6 1.5v8a.5.5 0 00.246.431.566.566 0 00.497.006zM5.5 10.5a1 1 0 00-1-1h-1a1 1 0 000 2h1a1 1 0 001-1zM4 6a1 1 0 00-1-1H2a1 1 0 000 2h1a1 1 0 001-1zM2 2a1 1 0 000-2H1a1 1 0 000 2zM20.5 9.5h-1a1 1 0 000 2h1a1 1 0 000-2zM7 15a1 1 0 00-1-1H5a1 1 0 000 2h1a1 1 0 001-1zM19 14h-1a1 1 0 000 2h1a1 1 0 000-2zM22 5h-1a1 1 0 000 2h1a1 1 0 000-2zM23 0h-1a1 1 0 000 2h1a1 1 0 000-2zM17.5 17h-2.26a.249.249 0 00-.249.231 3 3 0 01-5.982 0A.251.251 0 008.76 17H8.5A2.5 2.5 0 006 19.5v1a1.491 1.491 0 00.5 1.11V23a1 1 0 002 0v-.75a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25V23a1 1 0 002 0v-1.39a1.491 1.491 0 00.5-1.11v-1a2.5 2.5 0 00-2.5-2.5z\"/><path d=\"M12 19a2 2 0 10-2-2 2 2 0 002 2zm-.75-2a.75.75 0 11.75.75.75.75 0 01-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});