define("ember-svg-jar/inlined/party-throw-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>party-throw-up</title><path d=\"M22.5 15h-2.645l.593-2.766A3.5 3.5 0 0017.026 8H13a1.5 1.5 0 00-1.485 1.288l-.449 3.138-2.287 3.2a1.5 1.5 0 002.442 1.744l2.5-3.5a1.506 1.506 0 00.264-.66l.255-1.783a.5.5 0 01.495-.427h2.291a.5.5 0 01.489.6l-.982 4.582A1.5 1.5 0 0018 18h4.5a1.5 1.5 0 000-3zM3.75 13.5C1.612 13.5 0 14.575 0 16a2.308 2.308 0 001.5 2.029 2.761 2.761 0 003 2.471 2.754 2.754 0 003-2.5 2.172 2.172 0 00-.27-1.042A1.861 1.861 0 007.5 16c0-1.425-1.612-2.5-3.75-2.5zm.75 5c-.62 0-1-.323-1-.5a1.176 1.176 0 00.1-1.007 1 1 0 00-.7-.585A1.829 1.829 0 012.029 16a2.6 2.6 0 011.721-.5 2.622 2.622 0 011.715.493.925.925 0 01-.135.111.992.992 0 00-.434.808c-.012.733.6.83.6 1.088.004.177-.376.5-.996.5zM3.014 11.7a1 1 0 001.386-.3 2.515 2.515 0 011.03-.91 1 1 0 00-.86-1.806 4.505 4.505 0 00-1.853 1.629 1 1 0 00.297 1.387zM7.962 10.43a1 1 0 00-1.357.4 4.485 4.485 0 00-.421 1.089 1 1 0 101.941.477 2.606 2.606 0 01.236-.609 1 1 0 00-.399-1.357z\"/><circle cx=\"9\" cy=\"6\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});