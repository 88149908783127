define("ember-svg-jar/inlined/dating-smartphone-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dating-smartphone-man</title><path d=\"M7.5 8.25a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 01-1.92-2.56.254.254 0 01.149-.163.251.251 0 01.22.019A4.975 4.975 0 009.2 12.2a.248.248 0 01.209.068.252.252 0 01.075.208A2 2 0 017.5 14.25zM23.627 1.949a3.526 3.526 0 00-5.646-.916.681.681 0 01-.963 0 3.525 3.525 0 00-4.993 4.978l5.113 5.335a.5.5 0 00.722 0l5.107-5.327a3.524 3.524 0 00.66-4.07z\"/><path d=\"M4.088 18.168a.5.5 0 00.373.832h6.077a.5.5 0 00.374-.832 4.564 4.564 0 00-6.824 0z\"/><path d=\"M14 11a1 1 0 00-1 1v7.5a.5.5 0 01-.5.5h-10a.5.5 0 01-.5-.5V5a2 2 0 012-2h4.5a1 1 0 000-2H3a3 3 0 00-3 3v17a3 3 0 003 3h9a3 3 0 003-3v-9a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});