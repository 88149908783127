define("ember-svg-jar/inlined/real-estate-sign-sale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-sign-sale</title><path d=\"M8.25 9.375a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25h.25a.25.25 0 00.25-.25V9.75a.375.375 0 00-.375-.375z\"/><path d=\"M24 2a2 2 0 00-2-2H1.5a1 1 0 000 2H3a.5.5 0 01.5.5v1.75a.5.5 0 01-.5.5H1.5A1.5 1.5 0 000 6.25v10a1.5 1.5 0 001.5 1.5H19a1.5 1.5 0 001.5-1.5v-10a1.5 1.5 0 00-1.5-1.5h-1.5a.5.5 0 01-.5-.5V2.5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5V23a1 1 0 002 0zm-18.5.5A.5.5 0 016 2h8.5a.5.5 0 01.5.5v1.75a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5zm-.875 8.25a2.451 2.451 0 011.25 2 1.627 1.627 0 01-1.625 1.625h-1a.625.625 0 010-1.25h1a.375.375 0 00.375-.375c0-.418-.257-.631-.75-1a2.451 2.451 0 01-1.25-2A1.627 1.627 0 014.25 8.125h1a.625.625 0 010 1.25h-1a.375.375 0 00-.375.375c0 .418.257.631.75 1zm5.25 3a.625.625 0 01-1.25 0v-1.125a.25.25 0 00-.25-.25h-.25a.25.25 0 00-.25.25v1.125a.625.625 0 01-1.25 0v-4a1.625 1.625 0 013.25 0zm3.375.625h-1a1.627 1.627 0 01-1.625-1.625v-4a.625.625 0 011.25 0v4a.375.375 0 00.375.375h1a.625.625 0 010 1.25zm4-6.25a.625.625 0 010 1.25h-1a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25h1.125a.625.625 0 010 1.25h-1.125a.25.25 0 00-.25.25v.125a.375.375 0 00.375.375h1a.625.625 0 010 1.25h-1a1.627 1.627 0 01-1.625-1.625v-3a1.627 1.627 0 011.625-1.625z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});