define("ember-svg-jar/inlined/audio-file-volume", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file-volume</title><path d=\"M21.414 4.914L17.086.586A2 2 0 0015.672 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.328a2 2 0 00-.586-1.414zM12.5 19a1 1 0 01-1.486.874l-2.878-2.267a.5.5 0 00-.309-.107H6.5a1 1 0 01-1-1v-2a1 1 0 011-1h1.327a.5.5 0 00.309-.107l2.878-2.267A1 1 0 0112.5 12zm2.591-2.1a.75.75 0 01-1.061-1.061.75.75 0 000-1.061.75.75 0 011.061-1.061 2.251 2.251 0 010 3.183zm2.121 2.121a.75.75 0 01-1.061-1.061 3.748 3.748 0 000-5.3.75.75 0 011.061-1.061 5.25 5.25 0 010 7.425z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});