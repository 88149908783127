define("ember-svg-jar/inlined/email-action-read", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-read</title><path d=\"M8.687 14.84a.246.246 0 00.15.05h6.333a.246.246 0 00.15-.05L23 9.082a.25.25 0 00-.007-.405l-10.137-7.05a1.506 1.506 0 00-1.713 0L1.013 8.674a.25.25 0 00-.007.405z\"/><path d=\"M17.083 15.393a.25.25 0 00.011.408l3.325 2.215a.75.75 0 11-.832 1.248l-4.251-2.832a.252.252 0 00-.139-.042H8.8a.252.252 0 00-.139.042l-4.242 2.832a.75.75 0 01-.832-1.248L6.91 15.8a.248.248 0 00.111-.2.251.251 0 00-.1-.207L.4 10.5a.25.25 0 00-.4.2v9.94a2 2 0 002 2h20a2 2 0 002-2v-9.934a.25.25 0 00-.4-.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});