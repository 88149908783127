define("ember-svg-jar/inlined/music-player-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-player-2</title><path d=\"M16.5 0h-9A3.5 3.5 0 004 3.5v17A3.5 3.5 0 007.5 24h9a3.5 3.5 0 003.5-3.5v-17A3.5 3.5 0 0016.5 0zM18 7a1 1 0 01-1 1H7a1 1 0 01-1-1V4a1 1 0 011-1h10a1 1 0 011 1zm-1 9a5 5 0 11-5-5 5 5 0 015 5z\"/><circle cx=\"12\" cy=\"15.995\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});