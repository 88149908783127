define("ember-svg-jar/inlined/desktop-monitor-lock-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>desktop-monitor-lock-1</title><path d=\"M16 16.751a.25.25 0 01-.25.25H2.25a.25.25 0 01-.25-.25v-8.5A.25.25 0 012.25 8h10a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25H2a2 2 0 00-2 2v10.5a2 2 0 002 2h5.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H5a1 1 0 000 2h8a1 1 0 100-2h-2.75a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H16a2 2 0 002-2V15a.25.25 0 00-.25-.25h-1.5A.25.25 0 0016 15z\"/><path d=\"M22.714 4.766a.25.25 0 01-.214-.248V3.5a3.5 3.5 0 00-7 0v1.018a.25.25 0 01-.214.248A1.5 1.5 0 0014 6.251v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5v-5.5a1.5 1.5 0 00-1.286-1.485zM17.5 3.5a1.5 1.5 0 013 0v1a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25zm1.5 5a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});