define("ember-svg-jar/inlined/cable-car-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cable-car-1</title><path d=\"M22.08 14.478a4.976 4.976 0 00-4.98-4.547H7.406a4.962 4.962 0 00-4.974 4.5l-.67 4.89-.012.136v1.75a2.754 2.754 0 002.75 2.75H20a2.754 2.754 0 002.75-2.75v-1.75l-.009-.136zM7.753 18.209a.25.25 0 01-.25.25H4.186A.254.254 0 014 18.373a.25.25 0 01-.059-.2l.481-3.516a2.984 2.984 0 012.987-2.728h.1a.25.25 0 01.25.25zm7 0a.25.25 0 01-.25.25H10a.25.25 0 01-.25-.25v-6.028a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25zm5.756.164a.251.251 0 01-.189.086H17a.25.25 0 01-.25-.25v-6.028a.25.25 0 01.25-.25h.1a3 3 0 012.993 2.769l.475 3.471a.25.25 0 01-.059.202zM23.963.773a1 1 0 00-1.231-.7L13.6 2.618a.08.08 0 01-.07-.013.082.082 0 01-.032-.064 1.5 1.5 0 00-3 0v.772a.249.249 0 01-.186.242L.746 6.073A1 1 0 001 8.041a.976.976 0 00.255-.033l8.931-2.351a.256.256 0 01.214.043.25.25 0 01.1.2v.532a.25.25 0 01-.25.25H9.5A1.5 1.5 0 008 8.181v.5a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-.5a1.5 1.5 0 00-1.5-1.5h-.75a.25.25 0 01-.25-.25V4.912a.251.251 0 01.183-.241L23.268 2a1 1 0 00.695-1.227z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});