define("ember-svg-jar/inlined/multiple-actions-video.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-video</title><path d=\"M18.75 12.5a.149.149 0 00.114-.053.151.151 0 00.035-.121 6.07 6.07 0 00-.517-1.749c-.454-.907-1.9-1.44-3.911-2.179l-.485-.172a1.8 1.8 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 0012 0a2.986 2.986 0 00-1.341.314.25.25 0 00-.1.363 5 5 0 01.841 2.808 6.259 6.259 0 01-.922 3.624.254.254 0 00-.029.216.249.249 0 00.151.156c1.917.716 3.3 1.328 3.9 2.537a7.459 7.459 0 01.654 2.267.25.25 0 00.248.215z\"/><path d=\"M12.5 12.5h1.133a.251.251 0 00.247-.292 5.9 5.9 0 00-.5-1.631c-.454-.907-1.9-1.44-3.911-2.179l-.485-.172a1.8 1.8 0 01-.054-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 007 0a3.219 3.219 0 00-3.145 3.485 4.609 4.609 0 001.192 3.538 1.877 1.877 0 010 1.185L4.53 8.4c-2.007.737-3.457 1.27-3.911 2.177A7.531 7.531 0 000 13.5a.5.5 0 00.5.5h9.277a.249.249 0 00.21-.115A2.986 2.986 0 0112.5 12.5zM23.371 15.517l-2.807.748a.249.249 0 01-.314-.241V15.5a1.5 1.5 0 00-1.5-1.5H12.5a1.5 1.5 0 00-1.5 1.5v7a1.5 1.5 0 001.5 1.5h6.25a1.5 1.5 0 001.5-1.5v-.524a.249.249 0 01.314-.241l2.807.748A.5.5 0 0024 22v-6a.5.5 0 00-.629-.483z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});