define("ember-svg-jar/inlined/real-estate-truck-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-truck-house</title><path d=\"M23.832 7.126L16.664.752a1 1 0 00-1.327 0L8.168 7.126A.5.5 0 008.5 8h2a.5.5 0 01.5.5v5a1 1 0 001 1h2a.5.5 0 00.5-.5v-2.5a1.5 1.5 0 013 0V14a.5.5 0 00.5.5h2a1 1 0 001-1v-5a.5.5 0 01.5-.5h2a.5.5 0 00.332-.874zM23 16a.5.5 0 00-.5-.5h-12a.5.5 0 00-.5.5v2a.5.5 0 00.5.5H21a.5.5 0 01.5.5v.75a.75.75 0 001.5 0V16zM8 11.5H4.877a1 1 0 00-.962.726L3.14 14.94a.5.5 0 01-.257.31L.552 16.414a1 1 0 00-.552.895V21.5a1 1 0 001 1h1.535a.5.5 0 00.5-.57 3 3 0 015.108-2.53.5.5 0 00.857-.347V12.5a1 1 0 00-1-1z\"/><circle cx=\"6\" cy=\"21.5\" r=\"2\"/><path d=\"M10.5 19.5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h4.539a.5.5 0 00.5-.579 2.884 2.884 0 01.261-1.7.5.5 0 00-.45-.718z\"/><circle cx=\"18.5\" cy=\"21.5\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});