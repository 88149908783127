define("ember-svg-jar/inlined/water-protection-fish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-protection-fish</title><circle cx=\"14.75\" cy=\"15.624\" r=\"1.25\"/><path d=\"M23.5 3a3.715 3.715 0 01-3.053-1.724.52.52 0 00-.894 0A4.006 4.006 0 0116 3a4.006 4.006 0 01-3.553-1.724.52.52 0 00-.894 0A4.006 4.006 0 018 3a4.006 4.006 0 01-3.553-1.724.52.52 0 00-.894 0A3.715 3.715 0 01.5 3a.5.5 0 00-.5.5v20a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-20a.5.5 0 00-.5-.5zm-4.484 13.252c0 .988-2.688 4.089-6.469 4.089a8.446 8.446 0 01-5.84-2.885.5.5 0 00-.659-.062l-1.457 1.067A1 1 0 013 17.653v-2.8a1 1 0 011.591-.808l1.462 1.07a.5.5 0 00.657-.06 8.52 8.52 0 015.836-2.881c3.781 0 6.467 3.094 6.469 4.079zM18.75 12.5a.75.75 0 11.75-.75.75.75 0 01-.75.75zM20 10a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});