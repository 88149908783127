define("ember-svg-jar/inlined/text-style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>text-style</title><path d=\"M19.556.359H4.444A2.2 2.2 0 002.25 2.554v1.889a1.25 1.25 0 002.5 0V3.109a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25v17.778a.25.25 0 01-.25.25H8.694a1.25 1.25 0 000 2.5h6.612a1.25 1.25 0 100-2.5H13.5a.25.25 0 01-.25-.25V3.109a.25.25 0 01.25-.25H19a.25.25 0 01.25.25v1.334a1.25 1.25 0 002.5 0V2.554A2.2 2.2 0 0019.556.359z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});