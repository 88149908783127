define("ember-svg-jar/inlined/badminton-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>badminton-player</title><circle cx=\"11.146\" cy=\"6.248\" r=\"2.5\"/><path d=\"M16.88 8.089L8.185 11.27A1.347 1.347 0 016.4 10a1.5 1.5 0 00-.874-1.359l.1-1.345a3.166 3.166 0 001.663-1.062 3.963 3.963 0 00.891-2.541A3.492 3.492 0 004.943 0a3.082 3.082 0 00-2.356 1.144A3.963 3.963 0 001.7 3.685a3.965 3.965 0 00.885 2.543A3.174 3.174 0 004.12 7.244L4.006 8.8A1.494 1.494 0 003.4 10a4.339 4.339 0 004.345 4.343 5.914 5.914 0 002.317-.56.251.251 0 01.338.234v2.751a3 3 0 01-.154.948l-1.523 4.301a1.5 1.5 0 002.845.949l1.75-5a1.484 1.484 0 00.075-.394l.935.311a1.5 1.5 0 01.905 2.013l-.216.5a1.5 1.5 0 002.758 1.183l.215-.5a4.5 4.5 0 00-2.713-6.039l-1.877-.626v-1.683a.249.249 0 01.164-.235l4.347-1.596a1.5 1.5 0 10-1.031-2.811zM3.7 3.687C3.7 2.563 4.423 2 4.938 2c.674 0 1.242.776 1.241 1.692 0 1.117-.721 1.688-1.243 1.688-.512-.001-1.243-.571-1.236-1.693zM22.187 3.231l-.82-2.085A1.7 1.7 0 0018.7.467l-2.1 1.755a1.964 1.964 0 001.364 3.467l2.736-.145a1.7 1.7 0 001.487-2.313zm-4.3.524l1.775-1.483.513 1.307z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});