define("ember-svg-jar/inlined/photography-equipment-light-umbrella", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>photography-equipment-light-umbrella</title><path d=\"M19.925 23.828a1 1 0 00.277-1.387l-1.445-2.167a3.044 3.044 0 00-1.7-1.164.249.249 0 01-.184-.241v-3.251a.172.172 0 01.294-.122 1 1 0 001.414 0l1.414-1.414a1 1 0 000-1.414l-3.54-3.535a1.024 1.024 0 00-1.414 0L13.273 10.9a.5.5 0 000 .707l1.527 1.522a.251.251 0 01.073.177v5.563a.25.25 0 01-.184.241 3.044 3.044 0 00-1.7 1.164l-1.445 2.167a1 1 0 101.664 1.11l1.209-1.814a.25.25 0 01.458.139V23a1 1 0 002 0v-1.127a.25.25 0 01.458-.139l1.211 1.817a1 1 0 001.381.277zM8.241 12.953a2.5 2.5 0 00-.651 2.2.75.75 0 01-1.27.653A9.23 9.23 0 015.135 4.191a.25.25 0 01.452.082c.488 2.134 2.637 5.2 4.755 7.42a.25.25 0 01-.184.422 2.56 2.56 0 00-1.917.838zM12.4 8.832a3.108 3.108 0 012-.925.25.25 0 00.153-.432C11 4.153 7.222 2.355 6.585 2.992s1.161 4.414 4.483 7.97a.249.249 0 00.432-.152 3.108 3.108 0 01.925-2.005M16.557 4.631a2.507 2.507 0 012.2-.652.75.75 0 00.653-1.27A9.231 9.231 0 007.8 1.525a.25.25 0 00.082.452c2.134.487 5.2 2.637 7.42 4.754a.249.249 0 00.422-.183 2.562 2.562 0 01.833-1.917z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});