define("ember-svg-jar/inlined/human-resources-hierarchy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-hierarchy</title><path d=\"M4 18a1 1 0 001-1v-1.25a.249.249 0 01.25-.25h5.5a.249.249 0 01.25.25V17a1 1 0 002 0v-1.25a.249.249 0 01.25-.25h5.5a.249.249 0 01.25.25V17a1 1 0 002 0v-2a1.5 1.5 0 00-1.5-1.5h-15A1.5 1.5 0 003 15v2a1 1 0 001 1z\"/><circle cx=\"4\" cy=\"21.5\" r=\"2.5\"/><circle cx=\"12\" cy=\"21.5\" r=\"2.5\"/><circle cx=\"20\" cy=\"21.5\" r=\"2.5\"/><path d=\"M12 5.714a2.857 2.857 0 10-2.857-2.858A2.857 2.857 0 0012 5.714zM12 6.856a5.143 5.143 0 00-5.139 4.949.188.188 0 00.187.2h9.839a.253.253 0 00.181-.078.25.25 0 00.068-.185A5.144 5.144 0 0012 6.856z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});