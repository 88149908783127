define("ember-svg-jar/inlined/organic-watering-plant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>organic-watering-plant</title><path d=\"M20.308 11.445a.99.99 0 00-1.616 0C17.826 12.816 15 17.447 15 19.5a4.5 4.5 0 009 0c0-2.053-2.826-6.684-3.692-8.055zM20 21.794a2.753 2.753 0 01-2.75-2.75.5.5 0 111 0 1.752 1.752 0 001.75 1.75.5.5 0 010 1zM19.084.728a.87.87 0 00-1.35-.575 9.8 9.8 0 01-5.581 1.415C7.54 1.568 5.6 3.464 5.577 3.5A6.081 6.081 0 003.16 9.189a.5.5 0 01-.166.443c-1.158 1.014-1.976 1.96-2.724 2.585a.75.75 0 10.96 1.152c1.921-1.6 3.888-4.668 9.777-6.7A.75.75 0 0111.5 8.09a18.276 18.276 0 00-6.612 3.856.5.5 0 00-.011.716 6.055 6.055 0 004.306 1.8 9.451 9.451 0 005.259-1.742C20.009 8.986 19.4 2.6 19.084.728z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});