define("ember-svg-jar/inlined/exotic-food-samosa-dip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>exotic-food-samosa-dip</title><path d=\"M7.593 3.873a1.065 1.065 0 00-1.8.074 32.331 32.331 0 01-5.615 8.408A1.058 1.058 0 001.058 14h11.31a1.057 1.057 0 00.933-.559 1.075 1.075 0 00-.1-1.146 34.8 34.8 0 01-5.608-8.422zM12.63 8a25.521 25.521 0 012.8.532.5.5 0 00.581-.291 3.186 3.186 0 01.49-.809A3.24 3.24 0 0119 6.25a.75.75 0 010 1.5 1.748 1.748 0 00-1.349.635 1.483 1.483 0 00.145 2.042 2.22 2.22 0 00.6.39.621.621 0 01.368.567 1.616 1.616 0 003.232 0V9.721a3.011 3.011 0 00-.1-.785 26.81 26.81 0 01-.824-8.072.969.969 0 00-1.584-.639l-7.28 6.084a.965.965 0 00-.327.94.988.988 0 00.749.751zM18.75 15.5c-2.528 0-5.25.782-5.25 2.5v.45a8.064 8.064 0 00.576 2.99A4.074 4.074 0 0017.858 24h1.784a4.076 4.076 0 003.783-2.56A8.063 8.063 0 0024 18.45V18c0-1.718-2.721-2.5-5.25-2.5zm0 3.5c-2.4 0-3.674-.732-3.751-.989.077-.279 1.348-1.011 3.751-1.011 2.373 0 3.642.714 3.748 1-.106.286-1.375 1-3.748 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});