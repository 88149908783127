define("ember-svg-jar/inlined/road-sign-bike-lane-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-bike-lane-alternate</title><path d=\"M23 0H1a1 1 0 00-1 1v22a1 1 0 001 1h22a1 1 0 001-1V1a1 1 0 00-1-1zm-1 21.75a.25.25 0 01-.25.25H2.25a.25.25 0 01-.25-.25V2.25A.25.25 0 012.25 2h19.5a.25.25 0 01.25.25z\"/><path d=\"M9.75 11.5a.75.75 0 00.75-.75v-5a.75.75 0 00-1.5 0v5a.75.75 0 00.75.75zM13 10.75a.127.127 0 01.226-.079l.439.548a.75.75 0 101.17-.938l-1.5-1.875a.25.25 0 010-.312l1.5-1.875a.75.75 0 10-1.17-.938l-.439.548A.127.127 0 0113 5.75a.75.75 0 00-1.5 0v5a.75.75 0 001.5 0zM8 9.25a1.728 1.728 0 00-.237-.874.252.252 0 010-.252A1.728 1.728 0 008 7.25v-.5A1.752 1.752 0 006.25 5h-1a.75.75 0 00-.75.75v5a.75.75 0 00.75.75h1A1.752 1.752 0 008 9.75zm-1.5.5a.25.25 0 01-.5 0v-.5a.25.25 0 01.5 0zm0-2.5a.25.25 0 01-.5 0v-.5a.25.25 0 01.5 0zM15.25 12.5a.75.75 0 00-.75.75.213.213 0 01-.411.079l-.143-.357a.75.75 0 00-1.446.278v5a.75.75 0 001.5 0 .213.213 0 01.411-.079l.143.357a.749.749 0 00.7.472.691.691 0 00.142-.014.749.749 0 00.604-.736v-5a.75.75 0 00-.75-.75zM6.25 17.5h-1a.25.25 0 01-.25-.25v-4a.75.75 0 00-1.5 0v4A1.752 1.752 0 005.25 19h1a.75.75 0 000-1.5zM9.75 12.5A1.752 1.752 0 008 14.25v4a.75.75 0 001.5 0v-1a.25.25 0 01.5 0v1a.75.75 0 001.5 0v-4a1.752 1.752 0 00-1.75-1.75zm.25 2.75a.25.25 0 01-.5 0v-1c0-.275.5-.275.5 0zM18.75 11.5a.75.75 0 000-1.5h-1a.25.25 0 010-.5h1a.75.75 0 000-1.5h-1a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h1a.75.75 0 000-1.5h-1A1.752 1.752 0 0016 6.75v3a1.752 1.752 0 001.75 1.75zM18.75 14h1a.75.75 0 000-1.5h-1A1.752 1.752 0 0017 14.25v3A1.752 1.752 0 0018.75 19h1a.75.75 0 000-1.5h-1a.25.25 0 010-.5h1a.75.75 0 000-1.5h-1a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});