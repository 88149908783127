define("ember-svg-jar/inlined/astronomy-constellation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astronomy-constellation</title><path d=\"M20.75 12.486a.1.1 0 01-.1-.068l-2.657-6.977a.252.252 0 01.069-.277l3.872-3.4a1 1 0 00-1.32-1.5l-4.169 3.66a.252.252 0 01-.165.062H9.749a.25.25 0 01-.23-.154 3 3 0 10-4.772 3.385.25.25 0 01.069.268L3.3 11.824a.249.249 0 01-.221.167 3 3 0 101.421 5.72.251.251 0 01.275.044l2.091 1.931a.249.249 0 01.065.269 3 3 0 104.351-1.546.249.249 0 01-.12-.25l.652-4.562A.25.25 0 0112 13.39a2.982 2.982 0 001.161-.594.249.249 0 01.276-.029l4.192 2.206a.25.25 0 01.132.244 2.992 2.992 0 00-.012.269 3 3 0 103-3zm-1.987.593a.117.117 0 01-.163.145L14.37 11a.25.25 0 01-.132-.244 2.97 2.97 0 00.012-.268 3 3 0 10-4.533 2.577.251.251 0 01.12.25l-.652 4.561a.249.249 0 01-.185.205 2.952 2.952 0 00-.5.179.253.253 0 01-.275-.043l-2.092-1.931a.25.25 0 01-.065-.27 3 3 0 00-.829-3.274.219.219 0 01-.062-.236L6.7 8.148a.246.246 0 01.221-.167 3.007 3.007 0 002.6-1.841.25.25 0 01.23-.154h6.139a.25.25 0 01.234.161zm-7.513-1.593a1 1 0 111-1 1.007 1.007 0 01-1 1zm-4.5-7.5a1 1 0 011 .99V5a1 1 0 11-1-1.01zm-4.5 11a1 1 0 111 1 1 1 0 01-1-1zm7.5 7a1 1 0 01-1-1 1.017 1.017 0 011-1 1 1 0 010 2zm11-5.5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});