define("ember-svg-jar/inlined/data-transfer-diagonal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-transfer-diagonal</title><path d=\"M10.373 19.31a1.5 1.5 0 002.121 0l7.147-7.147a.25.25 0 01.354 0l.884.883a1 1 0 00.707.293.976.976 0 00.267-.036 1 1 0 00.7-.722l1.417-5.656a1 1 0 00-1.213-1.212L17.1 7.127a1 1 0 00-.464 1.673l.884.884a.25.25 0 010 .353l-7.147 7.148a1.5 1.5 0 000 2.125zM11.538 4.659l-7.392 6.975a.249.249 0 01-.348-.005l-.677-.676a1 1 0 00-1.677.464L.03 17.074A1 1 0 001 18.316a1.01 1.01 0 00.243-.03L6.9 16.872a1 1 0 00.464-1.672l-1.091-1.09a.254.254 0 01-.073-.181.247.247 0 01.079-.178L13.6 6.84a1.5 1.5 0 10-2.058-2.181z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});