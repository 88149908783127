define("ember-svg-jar/inlined/pool-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pool-player</title><circle cx=\"13.498\" cy=\"2.759\" r=\"2.5\"/><path d=\"M9.5 15.739l-.98 6.24a1.5 1.5 0 102.96.51l1.02-5.961 1.02 5.961a1.509 1.509 0 001.48 1.25 2.105 2.105 0 00.25-.02 1.512 1.512 0 001.23-1.74l-.98-6.24v-.96a.25.25 0 00-.21-.247l-5.5-.893a.249.249 0 00-.29.247zM23.16 12.772l-16.006-2.6a.248.248 0 00-.232.086l-.506.6a2.426 2.426 0 01-.787.629.25.25 0 00.072.471l17.139 2.788a1 1 0 10.32-1.974zM2.9 11.179a2.479 2.479 0 01-.884-1.655.25.25 0 00-.206-.224l-.65-.1a1 1 0 00-.32 1.974l1.923.313a.176.176 0 00.187-.1.174.174 0 00-.05-.208z\"/><path d=\"M4.95 6.379L3.349 8.3a1.5 1.5 0 00.192 2.109 1.542 1.542 0 002.107-.189L7.26 8.3a.48.48 0 01.528-.157l1.71.64v.549a.249.249 0 00.21.246l12 1.95a.251.251 0 00.29-.245 1.509 1.509 0 00-.98-1.43L8.83 5.319a3.527 3.527 0 00-3.878 1.058z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});