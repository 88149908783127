define("ember-svg-jar/inlined/shark-hammer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shark-hammer</title><path d=\"M21.115 19.292a8.638 8.638 0 00.375-4.452.5.5 0 00-.648-.377 5.863 5.863 0 00-2.758 2.2 8.91 8.91 0 00-.981 1.886c-.583.179-2.225.561-3.249-.462-.841-.841-.74-1.876-.555-3.755.042-.431.086-.887.123-1.38a34.425 34.425 0 013.578.486.5.5 0 00.447-.724 9.759 9.759 0 00-4.1-4.174 13.5 13.5 0 00-.775-3.125.455.455 0 01.077-.459.53.53 0 01.5-.185 20.947 20.947 0 012.7.641.51.51 0 00.45-.069.5.5 0 00.207-.405v-3.75A.5.5 0 0016.089.7l-2-.36h-.021A34.056 34.056 0 009.5.063a34.074 34.074 0 00-4.589.272l-2 .36a.5.5 0 00-.411.493v3.75a.5.5 0 00.207.405.509.509 0 00.45.069 20.993 20.993 0 012.7-.641.514.514 0 01.5.185.455.455 0 01.077.459 13.47 13.47 0 00-.777 3.125 9.753 9.753 0 00-4.1 4.174.5.5 0 00.443.724 35.807 35.807 0 013.586-.489c.4 4.913 2.323 8.091 5.728 9.452 2.269.91 5.556-.055 6.563-.391a9.152 9.152 0 001.9 1.375 5.474 5.474 0 002.223.553.5.5 0 00.5-.5c0-.125-.017-2.85-1.385-4.146zM8.75 8.938a.75.75 0 011.5 0v3.5a.75.75 0 11-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});