define("ember-svg-jar/inlined/kitchenware-draining-net", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchenware-draining-net</title><path d=\"M20.5 2a2 2 0 00-2-2h-13a2 2 0 00-2 2v13a2 2 0 002 2h4a.5.5 0 01.5.5V22a2 2 0 004 0v-4.5a.5.5 0 01.5-.5h4a2 2 0 002-2zm-12 12.5a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5v-2A.5.5 0 016 12h2a.5.5 0 01.5.5zm0-5a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5v-2A.5.5 0 016 7h2a.5.5 0 01.5.5zm0-5A.5.5 0 018 5H6a.5.5 0 01-.5-.5v-2A.5.5 0 016 2h2a.5.5 0 01.5.5zm5 10a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm0-5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2A.5.5 0 0111 7h2a.5.5 0 01.5.5zm0-5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2A.5.5 0 0111 2h2a.5.5 0 01.5.5zm5 10a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm0-5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2A.5.5 0 0116 7h2a.5.5 0 01.5.5zm0-5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2A.5.5 0 0116 2h2a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});