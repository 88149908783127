define("ember-svg-jar/inlined/phone-chatting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-chatting</title><path d=\"M18.5 19a1 1 0 00-2 0 .5.5 0 01-.5.5H6.5A.5.5 0 016 19v-1.5a1 1 0 00-2 0V21a3 3 0 003 3h8.5a3 3 0 003-3z\"/><circle cx=\"4.75\" cy=\"9.75\" r=\"1.25\"/><circle cx=\"8.75\" cy=\"9.75\" r=\"1.25\"/><path d=\"M3.5 5.25A3.254 3.254 0 00.25 8.5v2a3.254 3.254 0 003.25 3.25h2.189l2.781 2.78a.746.746 0 00.53.22.75.75 0 00.75-.75v-2.25H10a3.254 3.254 0 003.25-3.25v-2A3.254 3.254 0 0010 5.25zm6.5 1.5a1.752 1.752 0 011.75 1.75v2A1.752 1.752 0 0110 12.25H9a.75.75 0 00-.75.75v.586a.25.25 0 01-.427.177L6.53 12.47a.747.747 0 00-.53-.22H3.5a1.752 1.752 0 01-1.75-1.75v-2A1.752 1.752 0 013.5 6.75z\"/><circle cx=\"16.75\" cy=\"12.25\" r=\"1\"/><circle cx=\"19.75\" cy=\"12.25\" r=\"1\"/><path d=\"M20.5 15.75a3.254 3.254 0 003.25-3.25v-1a3.254 3.254 0 00-3.25-3.25h-5a.75.75 0 000 1.5h5a1.752 1.752 0 011.75 1.75v1a1.752 1.752 0 01-1.75 1.75h-2a.751.751 0 00-.416.126L16.25 15.6V15a.75.75 0 00-.75-.75H15a1.731 1.731 0 01-1.051-.351.751.751 0 10-.9 1.2 3.212 3.212 0 001.7.641V17a.75.75 0 001.166.624l2.812-1.874zM15.5 0h-8a3 3 0 00-3 3 1 1 0 002 0 1 1 0 011-1h8a1 1 0 011 1v2a1 1 0 002 0V3a3 3 0 00-3-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});