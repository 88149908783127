define("ember-svg-jar/inlined/expand-vertical-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>expand-vertical-3</title><path d=\"M17.75 12a1.25 1.25 0 00-1.25-1.25h-9a1.25 1.25 0 000 2.5h9A1.25 1.25 0 0017.75 12zM12 15.249a1.25 1.25 0 00-1.25 1.25v2.25a.25.25 0 01-.25.25H9a1 1 0 00-.707 1.707l3 3a1 1 0 001.414 0l3-3A1 1 0 0015 19h-1.5a.25.25 0 01-.25-.25V16.5A1.25 1.25 0 0012 15.249zM12.707.292a1 1 0 00-1.414 0l-3 3A1 1 0 009 5h1.5a.25.25 0 01.25.25V7.5a1.25 1.25 0 002.5 0V5.249A.25.25 0 0113.5 5H15a1 1 0 00.707-1.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});