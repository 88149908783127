define("ember-svg-jar/inlined/athletics-team-running", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>athletics-team-running</title><circle cx=\"13.75\" cy=\"2.5\" r=\"2.5\"/><path d=\"M22.175 11.119L21 8.272a1.5 1.5 0 00-.316-1.982 1.473 1.473 0 00-.617-.29l-.936-2.275a1 1 0 00-1.85.761l1 2.415c-.415.518-.964 1.308-2.037.912l-7-2.543a4.514 4.514 0 00-4.996 1.35L3.1 8a1.5 1.5 0 002.3 1.92l1.153-1.38a1.506 1.506 0 011.665-.449l1.441.524a.25.25 0 01.15.319l-1.8 5.04A1.5 1.5 0 016.589 15H3.25a1.5 1.5 0 000 3h3.339a4.5 4.5 0 003.947-2.333.249.249 0 01.312-.112l.913.365a1.506 1.506 0 01.9 1.756l-1.82 4.458a1.5 1.5 0 102.91.729l1.82-4.46a4.516 4.516 0 00-2.695-5.268l-.983-.393a.25.25 0 01-.143-.316l.879-2.465a.257.257 0 01.129-.142.251.251 0 01.192-.008l2.265.823a4.492 4.492 0 004.315-.688l.8 1.935a1 1 0 101.85-.762z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});