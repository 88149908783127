define("ember-svg-jar/inlined/electronics-motor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>electronics-motor</title><path d=\"M6.25 8.751A.25.25 0 006 9v7.5A2.5 2.5 0 008.5 19h.75a.25.25 0 01.25.25v1.25a.5.5 0 00.5.5h.75a.25.25 0 01.25.25V23a1 1 0 102 0v-1.75a.25.25 0 01.25-.25H14a.5.5 0 00.5-.5v-1.25a.25.25 0 01.25-.25h.75a2.5 2.5 0 002.5-2.5V9a.25.25 0 00-.25-.25zm7.126 2.666a.75.75 0 011.248-.832l1 1.5a.748.748 0 01.126.416v3a.75.75 0 01-.75.75h-1a.75.75 0 010-1.5.25.25 0 00.25-.25V12.8a.255.255 0 00-.042-.139zM8.25 12.5a.748.748 0 01.126-.416l1-1.5a.75.75 0 111.248.832l-.832 1.248a.255.255 0 00-.042.139v1.7a.25.25 0 00.25.25.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zM17.75 7.251A.25.25 0 0018 7a2.5 2.5 0 00-2.5-2.5h-7A2.5 2.5 0 006 7a.25.25 0 00.25.25zM10.5 3h3a.5.5 0 00.5-.5v-1A1.5 1.5 0 0012.5 0h-1A1.5 1.5 0 0010 1.5v1a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});