define("ember-svg-jar/inlined/diagram-fall-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-fall-down</title><path d=\"M22.56 0a1.44 1.44 0 00-1.44 1.44v6.95a.25.25 0 01-.25.25H3.13a.25.25 0 01-.25-.25V1.44a1.44 1.44 0 00-2.88 0v7.68a2.4 2.4 0 002.4 2.4h8.15a.25.25 0 01.25.25v6.22a.25.25 0 01-.25.25H9.36a1.44 1.44 0 00-1.018 2.46l2.88 2.88a1.439 1.439 0 002.036 0l2.88-2.88a1.44 1.44 0 00-1.018-2.46h-1.19a.25.25 0 01-.25-.25v-6.22a.25.25 0 01.25-.25h7.67a2.4 2.4 0 002.4-2.4V1.44A1.44 1.44 0 0022.56 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});