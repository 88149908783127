define("ember-svg-jar/inlined/love-fire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-fire</title><path d=\"M21.989 11.762a1.024 1.024 0 00-.634-.743.983.983 0 00-.923.115 8.911 8.911 0 01-1.073.661.5.5 0 01-.473-.006.5.5 0 01-.256-.4A13.294 13.294 0 008.772.03a1.014 1.014 0 00-1.028.335.989.989 0 00-.138 1.051 9.155 9.155 0 01.358 6.711.5.5 0 01-.908.089 8.938 8.938 0 01-.576-1.21 1.024 1.024 0 00-.736-.634 1.014 1.014 0 00-.919.267 10.026 10.026 0 00-3.033 7.215 10.208 10.208 0 0020.416 0 10.012 10.012 0 00-.219-2.092zm-9.683 9.416a.5.5 0 01-.613 0C10.482 20.238 7 17.34 7 15.085c0-2.728 3.138-3.827 4.579-1.578a.5.5 0 00.842 0C13.862 11.258 17 12.357 17 15.085c0 2.255-3.483 5.153-4.694 6.093z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});