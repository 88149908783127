define("ember-svg-jar/inlined/exit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>exit</title><path d=\"M21.5 2.5h-19A2.5 2.5 0 000 5v14a2.5 2.5 0 002.5 2.5h19A2.5 2.5 0 0024 19V5a2.5 2.5 0 00-2.5-2.5zm0 7.25h-.75V15a.75.75 0 01-1.5 0V9.75h-.75a.75.75 0 010-1.5h3a.75.75 0 010 1.5zm-5.5 4.5a.75.75 0 110 1.5h-2a.75.75 0 010-1.5h.25v-4.5H14a.75.75 0 010-1.5h2a.75.75 0 110 1.5h-.25v4.5zm-3.828.415a.75.75 0 01-.336 1.006.742.742 0 01-.335.079.751.751 0 01-.671-.414L10 13.677l-.829 1.659a.751.751 0 01-.671.414.742.742 0 01-.335-.079.75.75 0 01-.336-1.006L9.161 12 7.829 9.336a.75.75 0 111.342-.671L10 10.323l.829-1.658a.75.75 0 111.342.671L10.839 12zM5 11.25a.75.75 0 010 1.5H3.75V14a.25.25 0 00.25.25h2a.75.75 0 010 1.5H4A1.752 1.752 0 012.25 14v-4A1.752 1.752 0 014 8.25h2a.75.75 0 010 1.5H4a.25.25 0 00-.25.25v1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});