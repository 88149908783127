define("ember-svg-jar/inlined/single-man-circle.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-circle</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 21.5a9.5 9.5 0 119.5-9.5 9.511 9.511 0 01-9.5 9.5z\"/><path d=\"M12 12a5.006 5.006 0 00-5 5v.5a.5.5 0 00.5.5h1.623a.25.25 0 01.246.206l.339 1.882a.5.5 0 00.492.412h3.6a.5.5 0 00.492-.412l.339-1.882a.25.25 0 01.246-.206H16.5a.5.5 0 00.5-.5V17a5.006 5.006 0 00-5-5zm0 1.75a.75.75 0 01.75.75v2.392a.75.75 0 01-1.5 0V14.5a.75.75 0 01.75-.75zM12 11.463a3.925 3.925 0 10-3.925-3.925A3.93 3.93 0 0012 11.463zm0-2a1.926 1.926 0 01-1.925-1.925 1.842 1.842 0 01.12-.649.249.249 0 01.36-.128 4.8 4.8 0 002.435.665 4.536 4.536 0 00.643-.048.253.253 0 01.2.06.249.249 0 01.085.191A1.943 1.943 0 0112 9.463z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 21.5a9.5 9.5 0 119.5-9.5 9.511 9.511 0 01-9.5 9.5z\"/><path d=\"M12 12a5.006 5.006 0 00-5 5v.5a.5.5 0 00.5.5h1.623a.25.25 0 01.246.206l.339 1.882a.5.5 0 00.492.412h3.6a.5.5 0 00.492-.412l.339-1.882a.25.25 0 01.246-.206H16.5a.5.5 0 00.5-.5V17a5.006 5.006 0 00-5-5zm0 1.75a.75.75 0 01.75.75v2.392a.75.75 0 01-1.5 0V14.5a.75.75 0 01.75-.75zM12 11.463a3.925 3.925 0 10-3.925-3.925A3.93 3.93 0 0012 11.463zm0-2a1.926 1.926 0 01-1.925-1.925 1.842 1.842 0 01.12-.649.249.249 0 01.36-.128 4.8 4.8 0 002.435.665 4.536 4.536 0 00.643-.048.253.253 0 01.2.06.249.249 0 01.085.191A1.943 1.943 0 0112 9.463z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});