define("ember-svg-jar/inlined/office-file-xls-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-file-xls-1</title><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zM6.059 17.219a.624.624 0 01-.28.838.618.618 0 01-.279.066.625.625 0 01-.559-.346s-.861-1.71-.881-1.762a.065.065 0 00-.121 0l-.88 1.759a.625.625 0 01-.559.346.618.618 0 01-.279-.066.624.624 0 01-.28-.838l1.305-2.606a.252.252 0 000-.224l-1.3-2.609a.625.625 0 011.118-.558l.875 1.75a.072.072 0 00.132 0l.875-1.75a.625.625 0 011.118.558l-1.31 2.609a.252.252 0 000 .224zm4.441.9h-2A1.626 1.626 0 016.875 16.5v-5a.625.625 0 111.25 0v5a.375.375 0 00.375.375h2a.625.625 0 010 1.25zm2.927-4.758l1.84 1.227a1.927 1.927 0 01-1.07 3.531H12.5a.625.625 0 010-1.25h1.7a.678.678 0 00.376-1.242L12.733 14.4a1.927 1.927 0 011.07-3.531h1.7a.625.625 0 010 1.25H13.8a.678.678 0 00-.376 1.242z\"/><path d=\"M23.707 5.705L18.293.291A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.748V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.249A.249.249 0 016 21.749v-.5A.251.251 0 005.749 21H4.25a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.412a1 1 0 00-.293-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});