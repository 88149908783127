define("ember-svg-jar/inlined/shop-like", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shop-like</title><path d=\"M10.26 15.946H7.83a.245.245 0 01-.192-.093.231.231 0 01-.041-.2L8.033 14a1.59 1.59 0 00-.3-1.413 1.656 1.656 0 00-2.545.085l-2.429 3.419a.241.241 0 01-.2.1H.751a.75.75 0 00-.75.75v4.5a.748.748 0 00.408.667l.594.3C3.735 23.794 4.451 24 5.957 24c.218 0 .452 0 .711-.01.351-.007.758-.017 1.246-.017 1.494 0 2.524-.878 3.066-2.628l.942-3.143v-.013a1.739 1.739 0 00-1.666-2.242z\"/><path d=\"M23.836 5.115L22.257.967A1.51 1.51 0 0020.854 0H4.233a1.506 1.506 0 00-1.4.966L1.252 5.114a2.493 2.493 0 00-.019 1.732 3.245 3.245 0 001.2 1.569.253.253 0 01.108.206V11a1 1 0 002 0V9.215a.251.251 0 01.214-.248 3.252 3.252 0 002.092-1.208.251.251 0 01.393 0 3.246 3.246 0 005.107 0 .251.251 0 01.393 0 3.246 3.246 0 005.107 0 .251.251 0 01.393 0A3.246 3.246 0 0020.794 9a.232.232 0 01.233.234L21 19.252a.251.251 0 01-.25.249h-1.259a.25.25 0 01-.25-.25V13.5a1 1 0 00-1-1h-3a1 1 0 00-1 1v5.75a.25.25 0 01-.25.25 1 1 0 000 2h8a1 1 0 001-1l.04-12.293a.247.247 0 01.072-.175 3.23 3.23 0 00.751-1.19 2.5 2.5 0 00-.018-1.727z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});