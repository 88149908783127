define("ember-svg-jar/inlined/hat-lady-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hat-lady-1</title><path d=\"M4 15.5h15a.5.5 0 00.5-.5v-2.756a.5.5 0 00-.48-.5 6 6 0 01-5.77-5.994 6.072 6.072 0 01.09-1.042.5.5 0 00-.4-.578A8 8 0 003.5 12v3a.5.5 0 00.5.5zM22.6 19.54l-3.3-2.44a.5.5 0 00-.3-.1H4a.5.5 0 00-.3.1L.4 19.54a1 1 0 00.277 1.75A35.318 35.318 0 0011.5 23a35.318 35.318 0 0010.819-1.71 1 1 0 00.277-1.75z\"/><path d=\"M23.25 5h-1.586a.25.25 0 01-.177-.427l1.122-1.121a.75.75 0 00-1.061-1.061l-1.121 1.122A.25.25 0 0120 3.336V1.75a.75.75 0 00-1.5 0v1.586a.25.25 0 01-.427.177l-1.121-1.122a.75.75 0 00-1.061 1.061l1.122 1.121a.25.25 0 01-.177.427H15.25a.75.75 0 000 1.5h1.586a.25.25 0 01.177.427l-1.122 1.121a.75.75 0 101.061 1.061l1.121-1.122a.25.25 0 01.427.177V9.75a.75.75 0 001.5 0V8.164a.25.25 0 01.427-.177l1.121 1.122a.75.75 0 001.061-1.061l-1.122-1.121a.25.25 0 01.177-.427h1.586a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});