define("ember-svg-jar/inlined/single-neutral-rotate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-rotate</title><path d=\"M18.206 13.186a1 1 0 10-.462 1.945c3.123.741 4.256 1.8 4.256 2.333 0 .869-2.864 2.6-8.569 2.941a1 1 0 00.059 2h.062C18.372 22.11 24 20.642 24 17.464c0-1.872-2.058-3.392-5.794-4.278zM7.916 17.842a.5.5 0 00-.892.206l-.3 1.576a.25.25 0 01-.3.2C3.538 19.154 2 18.122 2 17.464c0-.532 1.133-1.593 4.256-2.334a1 1 0 00-.462-1.946C2.058 14.071 0 15.591 0 17.464c0 2.191 2.809 3.6 6.06 4.335a.251.251 0 01.191.291l-.242 1.264a.5.5 0 00.746.524l3.455-2.046a.5.5 0 00.145-.73z\"/><circle cx=\"12\" cy=\"2.552\" r=\"2.5\"/><path d=\"M13.167 18.052a.5.5 0 00.5-.443l.506-4.336a.251.251 0 01.249-.221H15.5a.5.5 0 00.5-.5v-2.4a4 4 0 10-8 0v2.4a.5.5 0 00.5.5h1.082a.251.251 0 01.249.221l.506 4.336a.5.5 0 00.5.443zM18.206 13.186a1 1 0 10-.462 1.945c3.123.741 4.256 1.8 4.256 2.333 0 .869-2.864 2.6-8.569 2.941a1 1 0 00.059 2h.062C18.372 22.11 24 20.642 24 17.464c0-1.872-2.058-3.392-5.794-4.278zM7.916 17.842a.5.5 0 00-.892.206l-.3 1.576a.25.25 0 01-.3.2C3.538 19.154 2 18.122 2 17.464c0-.532 1.133-1.593 4.256-2.334a1 1 0 00-.462-1.946C2.058 14.071 0 15.591 0 17.464c0 2.191 2.809 3.6 6.06 4.335a.251.251 0 01.191.291l-.242 1.264a.5.5 0 00.746.524l3.455-2.046a.5.5 0 00.145-.73z\"/><circle cx=\"12\" cy=\"2.552\" r=\"2.5\"/><path d=\"M13.167 18.052a.5.5 0 00.5-.443l.506-4.336a.251.251 0 01.249-.221H15.5a.5.5 0 00.5-.5v-2.4a4 4 0 10-8 0v2.4a.5.5 0 00.5.5h1.082a.251.251 0 01.249.221l.506 4.336a.5.5 0 00.5.443z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});