define("ember-svg-jar/inlined/legal-scale-unequal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>legal-scale-unequal</title><path d=\"M24 10.356a1 1 0 00-.086-.388l-3.087-6.946a.25.25 0 01.136-.334l.654-.258a1 1 0 00-.734-1.861l-7.541 2.977A.25.25 0 0113 3.314V2a1 1 0 00-2 0v2.3a.251.251 0 01-.158.233L1.883 8.069A1 1 0 002.25 10h.04a.25.25 0 01.237.352l-2.44 5.491a1 1 0 00-.087.388v.019a4.5 4.5 0 009 0 .745.745 0 00-.047-.26 1.007 1.007 0 00-.052-.145L5.842 8.936a.256.256 0 010-.2.251.251 0 01.14-.137l4.679-1.847a.249.249 0 01.339.236V21.25a.25.25 0 01-.25.25H7.581a1 1 0 100 2h8.811a1 1 0 000-2H13.25a.25.25 0 01-.25-.25V6a.25.25 0 01.158-.232L17.2 4.175a.249.249 0 01.32.333L15.1 9.97a1.007 1.007 0 00-.052.145.745.745 0 00-.047.26 4.5 4.5 0 009 0zM2.813 15.5a.251.251 0 01-.228-.352l1.684-3.792a.25.25 0 01.457 0l1.68 3.792a.251.251 0 01-.228.352zm15.009-5.875a.251.251 0 01-.228-.352l1.68-3.792a.25.25 0 01.457 0l1.684 3.792a.251.251 0 01-.228.352z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});