define("ember-svg-jar/inlined/virtual-touch-board", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>virtual-touch-board</title><path d=\"M14.666 4.5a1 1 0 00-1 1v4.13a.25.25 0 01-.41.192l-.449-.376a.833.833 0 00-1.282 1.065l1.578 1.9a.249.249 0 00.193.091h5.454a.25.25 0 00.25-.25v-1.529A2.222 2.222 0 0016.778 8.5h-.861a.249.249 0 01-.25-.25V5.5a1 1 0 00-1.001-1z\"/><path d=\"M21 2a2 2 0 00-2-2H5a2 2 0 00-2 2v4.5a1 1 0 002 0v-4a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v4a1 1 0 002 0zM17.5 16.5h-1.917a.251.251 0 01-.241-.184 4.5 4.5 0 00-8.707.089.125.125 0 01-.121.095H6.5A2.5 2.5 0 004 19v2a1.5 1.5 0 00.857 1.355.249.249 0 01.143.227V23a1 1 0 002 0v-.25a.25.25 0 01.25-.25h9.5a.25.25 0 01.25.25V23a1 1 0 002 0v-.42a.249.249 0 01.143-.225A1.5 1.5 0 0020 21v-2a2.5 2.5 0 00-2.5-2.5zM11 15a2.5 2.5 0 11-2.5 2.5A2.5 2.5 0 0111 15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});