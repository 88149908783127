define("ember-svg-jar/inlined/vide-document-m4v-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vide-document-m4v-1</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM8.125 19.5a.625.625 0 01-1.25 0v-4.892c0-.141-.178-.1-.234.008l-.582 1.163a.625.625 0 01-1.118 0s-.552-1.078-.6-1.2-.216-.114-.216 0V19.5a.625.625 0 01-1.25 0v-8a.625.625 0 011.184-.279l1.217 2.435a.251.251 0 00.448 0l1.217-2.435a.625.625 0 011.184.279zm7 0a.625.625 0 01-1.25 0v-1.125a.25.25 0 00-.25-.25H9.5a.625.625 0 01-.625-.625v-1a5.631 5.631 0 015.605-5.625.633.633 0 01.645.625zm6-5.7a9.788 9.788 0 01-2.137 6.092.626.626 0 01-.976 0 9.788 9.788 0 01-2.137-6.092v-2.3a.625.625 0 011.25 0v2.3a8.535 8.535 0 001.154 4.275.26.26 0 00.442 0 8.535 8.535 0 001.154-4.275v-2.3a.625.625 0 011.25 0z\"/><path d=\"M13.572 12.225a4.38 4.38 0 00-3.447 4.275v.126a.249.249 0 00.249.249h3.251a.25.25 0 00.25-.25v-4.148a.256.256 0 00-.303-.252z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});