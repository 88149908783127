define("ember-svg-jar/inlined/cake-flour-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cake-flour-1</title><path d=\"M18 10.5a4.474 4.474 0 01-2.7-.9.5.5 0 00-.6 0 4.492 4.492 0 01-5.4 0 .5.5 0 00-.6 0 4.494 4.494 0 01-4.049.695.5.5 0 00-.651.475V21.5A2.5 2.5 0 006.5 24h11a2.5 2.5 0 002.5-2.5V10.77a.5.5 0 00-.65-.477A4.468 4.468 0 0118 10.5zm-1.5 8a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5v-4A.5.5 0 018 14h8a.5.5 0 01.5.5z\"/><path d=\"M20.822 4.861a.5.5 0 00-.48-.361H3.658a.5.5 0 00-.48.361A4.187 4.187 0 003 6a3 3 0 005.765 1.169.255.255 0 01.47 0 3 3 0 005.53 0 .255.255 0 01.47 0A3 3 0 0021 6a4.187 4.187 0 00-.178-1.139z\"/><rect x=\"2.5\" width=\"19\" height=\"3\" rx=\"1\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});