define("ember-svg-jar/inlined/share-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>share-2</title><path d=\"M11.5 9.5v2a3 3 0 01-6 0v-2a8.475 8.475 0 01.286-2.186A.25.25 0 005.545 7H2a2 2 0 00-2 2v13a2 2 0 002 2h13a2 2 0 002-2V9a2 2 0 00-2-2h-1a2.5 2.5 0 00-2.5 2.5z\"/><path d=\"M23.708 3.292l-3-3A1 1 0 0019 1v1.75a.25.25 0 01-.25.25H14a6.508 6.508 0 00-6.5 6.5v2a1 1 0 002 0v-2A4.5 4.5 0 0114 5h4.75a.249.249 0 01.25.25V7a1 1 0 001.707.707l3-3a1.006 1.006 0 00.001-1.415z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});