define("ember-svg-jar/inlined/light-bulb-eco", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-bulb-eco</title><path d=\"M17.24 8.88A1 1 0 0016 8.2l-8.55 2.47a1 1 0 00.27 1.96c.27 0 1.14.06 1.14.87v.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-.5a2.8 2.8 0 00-.48-1.6l6.17-1.78a1.012 1.012 0 00.69-1.24z\"/><path d=\"M16.28 11.5a2.916 2.916 0 00-3.14 2.87.13.13 0 00.13.13h1.74a.13.13 0 00.13-.13c0-.81.87-.87 1.14-.87a1 1 0 000-2zM17.772 5.226a1 1 0 00-1.236-.688l-9.62 2.739A1 1 0 107.464 9.2l9.62-2.739a1 1 0 00.688-1.235zM7.948 5.214l3.888-.9A1.492 1.492 0 0013 2.856V1a1 1 0 00-2 0v1.26a.25.25 0 01-.194.243L7.5 3.265a1 1 0 10.449 1.949zM15.989 16H8.011a1.04 1.04 0 00-.756.32.927.927 0 00-.237.816l.273 1.334a4.525 4.525 0 003.515 3.384.25.25 0 01.194.246v.9a1 1 0 002 0v-.9a.249.249 0 01.193-.243 4.523 4.523 0 003.516-3.384l.272-1.333a.924.924 0 00-.235-.816 1.041 1.041 0 00-.757-.324z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});