define("ember-svg-jar/inlined/casino-lucky-7", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>casino-lucky-7</title><path d=\"M22.376 1.919l-.89-1.067a1.494 1.494 0 00-1.82-.382 9.528 9.528 0 01-4.118 1A7.348 7.348 0 0112.5.79 8.271 8.271 0 009.087.036 7.584 7.584 0 005.443.9.251.251 0 015.1.8 1.5 1.5 0 003.776 0h-1a1.5 1.5 0 00-1.5 1.5v7a1.5 1.5 0 001.5 1.5h1.073a1.5 1.5 0 001.472-1.217 2.635 2.635 0 012.694-2.006 7.361 7.361 0 012.222.625 9.282 9.282 0 003.161.811.38.38 0 01.29.628 23.486 23.486 0 00-5.783 13.564A1.5 1.5 0 009.4 24h5.928a1.5 1.5 0 001.49-1.342c.54-5.067 4.58-16.012 5.806-19.246a1.506 1.506 0 00-.248-1.493z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});