define("ember-svg-jar/inlined/multiple-neutral-1.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-neutral-1</title><path d=\"M13.75 7.75a6.194 6.194 0 01-.663 2.789.5.5 0 00.1.579A4.7 4.7 0 0016.5 12.5a4.75 4.75 0 000-9.5 4.7 4.7 0 00-3.318 1.382.5.5 0 00-.1.579 6.194 6.194 0 01.668 2.789zM16.5 13a7.4 7.4 0 00-2.377.393.5.5 0 00-.2.823A8.957 8.957 0 0116.5 20.5a.5.5 0 00.5.5h6.5a.5.5 0 00.5-.5 7.508 7.508 0 00-7.5-7.5z\"/><circle cx=\"7.5\" cy=\"7.75\" r=\"4.75\"/><path d=\"M15 20.5a7.5 7.5 0 00-15 0 .5.5 0 00.5.5h14a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});