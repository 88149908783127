define("ember-svg-jar/inlined/desktop-monitor-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>desktop-monitor-download</title><path d=\"M16.289 14.405a.247.247 0 00-.289.243v2.1a.25.25 0 01-.25.25H2.25a.25.25 0 01-.25-.25v-8.5A.25.25 0 012.25 8h7.1a.248.248 0 00.25-.29 7.4 7.4 0 01-.085-1.451A.244.244 0 009.268 6H2a2 2 0 00-2 2v10.5a2 2 0 002 2h5.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H5a1 1 0 000 2h8a1 1 0 100-2h-2.75a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H16a2 2 0 002-2v-3.757a.25.25 0 00-.078-.182.254.254 0 00-.187-.068c-.078 0-.156.008-.235.008a7.926 7.926 0 01-1.211-.096z\"/><path d=\"M17.5 0A6.5 6.5 0 1024 6.5 6.508 6.508 0 0017.5 0zm2 6.5a.5.5 0 01.391.812l-2 2.5a.5.5 0 01-.782 0l-2-2.5A.5.5 0 0115.5 6.5h1a.25.25 0 00.25-.25V3.5a.75.75 0 011.5 0v2.75a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});