define("ember-svg-jar/inlined/single-neutral-actions-check-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-check-2</title><path d=\"M.25 18.094h1.81a.249.249 0 01.249.229l.443 5.312a.5.5 0 00.5.459h4a.5.5 0 00.5-.459l.443-5.312a.249.249 0 01.249-.229h.579a.252.252 0 00.179-.076.25.25 0 00.071-.181 7.993 7.993 0 011.327-4.659.252.252 0 00.037-.189A5.5 5.5 0 00-.25 14.094v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.25\" cy=\"4.094\" r=\"3.5\"/><path d=\"M17.25 24.094a6.473 6.473 0 006.2-4.557 1 1 0 00-1.91-.6 4.5 4.5 0 11-4.3-5.845 1 1 0 000-2 6.5 6.5 0 000 13z\"/><path d=\"M22.043 12.761l-4.616 4.617a.251.251 0 01-.354 0L15.9 16.2a1 1 0 10-1.415 1.414l1.577 1.577a1.689 1.689 0 002.386 0l5.014-5.014a1 1 0 00-1.414-1.414zM.25 18.094h1.81a.249.249 0 01.249.229l.443 5.312a.5.5 0 00.5.459h4a.5.5 0 00.5-.459l.443-5.312a.249.249 0 01.249-.229h.579a.252.252 0 00.179-.076.25.25 0 00.071-.181 7.993 7.993 0 011.327-4.659.252.252 0 00.037-.189A5.5 5.5 0 00-.25 14.094v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.25\" cy=\"4.094\" r=\"3.5\"/><path d=\"M17.25 24.094a6.473 6.473 0 006.2-4.557 1 1 0 00-1.91-.6 4.5 4.5 0 11-4.3-5.845 1 1 0 000-2 6.5 6.5 0 000 13z\"/><path d=\"M22.043 12.761l-4.616 4.617a.251.251 0 01-.354 0L15.9 16.2a1 1 0 10-1.415 1.414l1.577 1.577a1.689 1.689 0 002.386 0l5.014-5.014a1 1 0 00-1.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});