define("ember-svg-jar/inlined/task-list-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-list-pin</title><path d=\"M15.285 16.5h5.451a.25.25 0 00.249-.223A2.613 2.613 0 0021 16V9a2 2 0 00-2-2h-4.715a.25.25 0 00-.25.25v.25a2.5 2.5 0 01-5 0v-.25a.25.25 0 00-.25-.25H5a2 2 0 00-2 2v13a2 2 0 002 2h8a2.633 2.633 0 00.315-.019.248.248 0 00.22-.248v-5.484a1.753 1.753 0 011.75-1.749zm-5 2.5h-3a.75.75 0 010-1.5h3a.75.75 0 010 1.5zm-3-4.5a.75.75 0 110-1.5h9.5a.75.75 0 010 1.5z\"/><path d=\"M19.913 18.426a.25.25 0 00-.177-.426h-4.451a.253.253 0 00-.25.25v4.46a.25.25 0 00.427.177zM10.381 5.768a.251.251 0 01.154.232v1.5a1 1 0 002 0V6a.251.251 0 01.154-.231 3 3 0 10-2.308 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});