define("ember-svg-jar/inlined/presentation-projector-screen-hierarchy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>presentation-projector-screen-hierarchy</title><path d=\"M24 1.75a1.5 1.5 0 00-1.5-1.5h-21a1.5 1.5 0 00-.214 2.984.25.25 0 01.214.248v14.543a.251.251 0 01-.2.245 1 1 0 00.2 1.98H11a.249.249 0 01.25.25v.314a.249.249 0 01-.107.2 1.5 1.5 0 101.714 0 .249.249 0 01-.107-.2V20.5a.249.249 0 01.25-.25h9.5a1 1 0 00.2-1.98.249.249 0 01-.2-.245V3.482a.251.251 0 01.214-.248A1.5 1.5 0 0024 1.75zM20.5 18a.25.25 0 01-.25.25H3.75A.25.25 0 013.5 18V3.5a.249.249 0 01.25-.25h16.5a.249.249 0 01.25.25z\"/><path d=\"M6.5 16.75a2 2 0 00.889-3.791.252.252 0 01-.139-.224V8.75a.25.25 0 01.25-.25h1.75a.249.249 0 01.25.25v.5a1 1 0 001 1h.5a.249.249 0 01.25.25v2.235a.251.251 0 01-.139.224 2 2 0 101.778 0 .251.251 0 01-.139-.224V10.5a.249.249 0 01.25-.25h.5a1 1 0 001-1v-.5a.249.249 0 01.25-.25h1.75a.25.25 0 01.25.25v3.985a.251.251 0 01-.139.224 2 2 0 101.778 0 .252.252 0 01-.139-.224V8.75A1.752 1.752 0 0016.5 7h-1.75a.25.25 0 01-.25-.25v-.5a1 1 0 00-1-1h-3a1 1 0 00-1 1v.5a.25.25 0 01-.25.25H7.5a1.752 1.752 0 00-1.75 1.75v3.985a.251.251 0 01-.139.224A2 2 0 006.5 16.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});