define("ember-svg-jar/inlined/volleyball-smash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>volleyball-smash</title><circle cx=\"14.27\" cy=\"5.092\" r=\"2.5\"/><circle cx=\"3.366\" cy=\"3\" r=\"3\"/><path d=\"M23.568 20.571a1.251 1.251 0 00-.784-1.585l-2.3-.776a2.75 2.75 0 01-1.558-1.334l-.724-1.388a3.01 3.01 0 01-.34-1.388v-3.129a.249.249 0 01.156-.232 12.38 12.38 0 003.264-1.951 3.26 3.26 0 00.883-3.844l-1.44-3.062a1.25 1.25 0 10-2.276 1.035l1.443 3.062a.75.75 0 01-.2.887 10.978 10.978 0 01-7.141 2.376A15.335 15.335 0 018.03 8.57a1.25 1.25 0 00-.83 2.359 17.33 17.33 0 005.415.823.249.249 0 01.177.073.252.252 0 01.074.177v2.644a3.658 3.658 0 01-.109.884l-.463 1.854a5.256 5.256 0 00.535 3.882l1.2 2.1a1.25 1.25 0 102.17-1.24L15 20.025a2.751 2.751 0 01-.28-2.032l.439-1.755a.315.315 0 01.584-.069l.972 1.863a5.253 5.253 0 002.975 2.548l2.294.775a1.25 1.25 0 001.585-.784z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});