define("ember-svg-jar/inlined/playlist-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>playlist-search</title><path d=\"M16 9.5h.241a.249.249 0 00.183-.07.253.253 0 00.076-.18V2.5A2.5 2.5 0 0014 0H2.5A2.5 2.5 0 000 2.5v12A2.5 2.5 0 002.5 17h6.754a.253.253 0 00.18-.076.249.249 0 00.07-.183V16.5C9.5 12.64 13 9.5 16 9.5zM11.5 8a1 1 0 01-1-1 1.77 1.77 0 00-1.151-1.729A.25.25 0 009 5.5v5.552A2.5 2.5 0 116.5 8.5c.077 0 .152 0 .227.01A.249.249 0 007 8.261V4a1 1 0 011-1c1.557 0 4.5.836 4.5 4a1 1 0 01-1 1z\"/><path d=\"M21.174 19.76a.25.25 0 01-.034-.311 5.506 5.506 0 10-1.691 1.691.25.25 0 01.311.034l2.533 2.533a1 1 0 001.414-1.414zM13 16.5a3.5 3.5 0 113.5 3.5 3.5 3.5 0 01-3.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});