define("ember-svg-jar/inlined/road-sign-side-road-angle-left-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-side-road-angle-left-3</title><path d=\"M23.53 12.53a.749.749 0 000-1.06l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0zm-9.78 2.97a.75.75 0 01-1.5 0v-2.086a.25.25 0 00-.427-.177L10.03 15.03a.75.75 0 01-1.06-1.06l3.207-3.207a.251.251 0 00.073-.177V7.5a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});