define("ember-svg-jar/inlined/remove-circle-bold-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>remove-circle-bold-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 21a9 9 0 119-9 9.01 9.01 0 01-9 9z\"/><path d=\"M16.95 7.757l-.707-.707a1 1 0 00-1.415 0L12.177 9.7a.252.252 0 01-.354 0L9.172 7.05a1 1 0 00-1.415 0l-.707.707a1 1 0 000 1.414l2.65 2.652a.25.25 0 010 .354l-2.65 2.651a1 1 0 000 1.414l.707.708a1.006 1.006 0 001.415 0l2.651-2.65a.25.25 0 01.354 0l2.651 2.652a1.006 1.006 0 001.415 0l.707-.708a1 1 0 000-1.414l-2.65-2.653a.25.25 0 010-.354l2.65-2.652a1 1 0 000-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});