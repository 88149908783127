define("ember-svg-jar/inlined/multiple-man-woman-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-man-woman-2</title><path d=\"M24 18.905a3.487 3.487 0 00-1.9-3.112l-2.967-1.528a.249.249 0 01-.133-.222v-1.6a.247.247 0 01.108-.205 3.468 3.468 0 001.373-2.748.251.251 0 01.1-.181A1.593 1.593 0 0021.12 8a1.7 1.7 0 00-.412-1.2.25.25 0 01-.059-.25 6.205 6.205 0 00.336-3.174C20.639 1.992 18.756 1.5 17.25 1.5a5.08 5.08 0 00-3.067.874.248.248 0 00-.09.171.25.25 0 00.058.184 8.38 8.38 0 011.882 5c.136 2.016.329 2.209.8 2.676a2.043 2.043 0 01.6 1.6 2 2 0 01-.826 1.483 8.9 8.9 0 01-1.936 1.055.25.25 0 00-.025.456q.7.36 1.4.778a5.22 5.22 0 012.46 4.705v1.768a.25.25 0 00.25.25H23.5a.5.5 0 00.5-.5z\"/><path d=\"M16.75 22.5a.25.25 0 00.25-.25v-1.768a3.761 3.761 0 00-1.731-3.418 21.636 21.636 0 00-3.915-1.857c-.355-.124-.729-.443-.729-.8v-.53a.249.249 0 01.253-.25 7.456 7.456 0 004.86-1.368.519.519 0 00.194-.384.556.556 0 00-.162-.414 3.027 3.027 0 01-.8-1.1 8.561 8.561 0 01-.435-2.537 6.213 6.213 0 00-3.067-5.384 6.281 6.281 0 00-5.938 0 6.213 6.213 0 00-3.066 5.385 8.471 8.471 0 01-.439 2.542 2.594 2.594 0 01-.169.342 3.871 3.871 0 01-.656.791.5.5 0 00.067.762 7.351 7.351 0 004.671 1.37h.188a.251.251 0 01.254.25v.53c0 .357-.374.676-.729.8a21.636 21.636 0 00-3.915 1.857A3.764 3.764 0 000 20.482v1.768a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});