define("ember-svg-jar/inlined/human-resources-businessman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-businessman</title><path d=\"M19.274 19.188a.25.25 0 00-.25.25v.75a.75.75 0 11-1.5 0v-.75a.25.25 0 00-.25-.25h-4.25a.25.25 0 00-.25.25v2.25a1.5 1.5 0 001.5 1.5h8a1.5 1.5 0 001.5-1.5v-2.25a.25.25 0 00-.25-.25zM23.524 18.188a.25.25 0 00.25-.25v-2.25a1.5 1.5 0 00-1.5-1.5h-.28a.25.25 0 01-.246-.206.914.914 0 00-.079-.242l-.5-1a2.5 2.5 0 00-2.395-1.552h-1a2.5 2.5 0 00-2.394 1.552l-.5 1a.914.914 0 00-.079.242.25.25 0 01-.246.206h-.281a1.5 1.5 0 00-1.5 1.5v2.25a.25.25 0 00.25.25zm-6.355-4.553c.224-.447.335-.447.605-.447h1c.271 0 .382 0 .606.447l.1.191a.25.25 0 01-.223.362H17.3a.25.25 0 01-.213-.119.253.253 0 01-.011-.243zM7.293 8.653a.251.251 0 01-.011-.432 4.08 4.08 0 10-4.373-.077.25.25 0 01-.026.432 4.761 4.761 0 00-2.657 4.262v2.851a.5.5 0 00.5.5h1.459a.251.251 0 01.249.226l.644 6.447a.5.5 0 00.5.45h2.85a.5.5 0 00.5-.45l.645-6.448a.251.251 0 01.249-.225h1.452a.5.5 0 00.5-.5v-2.851a4.774 4.774 0 00-2.481-4.185zM5.75 12.029a.75.75 0 01-1.5 0v-1.691a.75.75 0 011.5 0zM3.17 4.164a.25.25 0 01.15-.159.253.253 0 01.218.02 5.067 5.067 0 002.638.737 5.147 5.147 0 00.944-.087.09.09 0 01.106.087 2.075 2.075 0 11-4.15 0 1.974 1.974 0 01.094-.598z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});