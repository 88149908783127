define("ember-svg-jar/inlined/database-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-sync</title><path d=\"M7.81 21.068a.249.249 0 00.275-.317 8.332 8.332 0 01-.308-2.914.25.25 0 00-.212-.265 12.369 12.369 0 01-5.783-2.145.25.25 0 00-.408.194v1.693c0 1.75 2.699 3.231 6.436 3.754zM1.374 12.861c0 1.737 2.656 3.211 6.35 3.745A.25.25 0 008 16.418a8.4 8.4 0 011.236-2.743.25.25 0 00-.186-.391c-2.891-.225-5.67-1.01-7.27-2.311a.251.251 0 00-.408.194zM20.178 8.407V6.715a.249.249 0 00-.407-.194C17.852 8.079 14.242 8.9 10.777 8.9S3.7 8.079 1.782 6.519a.25.25 0 00-.408.194v1.694c0 2.112 3.93 3.832 8.875 3.948a.252.252 0 00.177-.068 8.378 8.378 0 018.3-1.9.251.251 0 00.225-.038 2.542 2.542 0 001.227-1.942z\"/><ellipse cx=\"10.777\" cy=\"3.953\" rx=\"9.402\" ry=\"3.959\"/><path d=\"M11.555 22.088a.25.25 0 01.18-.074.253.253 0 01.179.079 6.033 6.033 0 0010.119-2.182 1 1 0 10-1.892-.647 4.038 4.038 0 01-6.828 1.4.249.249 0 01.008-.344l1.7-1.7a.5.5 0 00-.35-.845h-4.416a.5.5 0 00-.5.5v4.411a.5.5 0 00.845.35zM20.775 13.007a.25.25 0 01-.357 0 6.045 6.045 0 00-10.106 2.209 1 1 0 001.893.648 4.039 4.039 0 016.805-1.428.249.249 0 01-.007.346l-1.633 1.629a.5.5 0 00.35.845h4.411a.5.5 0 00.5-.5V12.35a.495.495 0 00-.85-.35l-.066.066z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});