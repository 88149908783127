define("ember-svg-jar/inlined/folder-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-media</title><path d=\"M21.5 5.25a.25.25 0 00.25-.25v-.75A.75.75 0 0021 3.5H8l-.18-.076-1.342-1.389a1.751 1.751 0 00-1.26-.535H2.286A2.037 2.037 0 00.25 3.536V16.25A1.749 1.749 0 002 18h.25a.25.25 0 00.25-.25v-9.5a3 3 0 013-3z\"/><path d=\"M22 7H6a1.752 1.752 0 00-1.75 1.75v12A1.752 1.752 0 006 22.5h16a1.752 1.752 0 001.75-1.75v-12A1.752 1.752 0 0022 7zm-4.78 8.965l-4.5 2.629a1.154 1.154 0 01-1.136.013 1.122 1.122 0 01-.584-.978v-5.258a1.123 1.123 0 01.581-.978 1.157 1.157 0 011.138.014l4.5 2.629a1.114 1.114 0 010 1.929z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});