define("ember-svg-jar/inlined/task-list-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-list-heart</title><path d=\"M12.75 8.5a.75.75 0 00-.75-.75H6a.75.75 0 000 1.5h6a.75.75 0 00.75-.75z\"/><path d=\"M10.542 19.078A.236.236 0 0010.36 19H3.5a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v5.338a.253.253 0 00.2.243c.881.218 2.218 1.224 2.736.679a.257.257 0 00.064-.178V3.5A1.5 1.5 0 0016.5 2h-4a.239.239 0 01-.207-.121 3.828 3.828 0 00-6.589 0A.239.239 0 015.5 2h-4A1.5 1.5 0 000 3.5v17A1.5 1.5 0 001.5 22h11.209a.249.249 0 00.185-.419 205.16 205.16 0 01-2.352-2.503z\"/><path d=\"M6 14.749a.75.75 0 100 1.5h3.265A.244.244 0 009.508 16a5.089 5.089 0 01.1-.95.247.247 0 00-.239-.3zM10.789 12.669a5.038 5.038 0 011.186-.967c.165-.1.212-.453.025-.453H6a.75.75 0 000 1.5h4.612a.235.235 0 00.177-.08zM23.628 14.449a3.509 3.509 0 00-3.045-1.95 3.669 3.669 0 00-2.695 1.126l-.212.212a.249.249 0 01-.353 0l-.212-.212a3.669 3.669 0 00-2.695-1.125 3.528 3.528 0 00-2.391 6.012l5.113 5.335a.5.5 0 00.723 0l5.106-5.328a3.523 3.523 0 00.661-4.07z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});