define("ember-svg-jar/inlined/diagram-curve-rise-dash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-curve-rise-dash</title><path d=\"M0 12a1.44 1.44 0 002.88 0v-1.92a1.44 1.44 0 00-2.88 0zM12 12.887a1.44 1.44 0 10-2.88 0v1.92a1.44 1.44 0 102.88 0zM19.727 13.574a1.447 1.447 0 00-1.489 1.39 2.392 2.392 0 01-.333 1.136 1.44 1.44 0 002.476 1.472 5.288 5.288 0 00.735-2.509 1.439 1.439 0 00-1.389-1.489zM8.446 7.449a2.4 2.4 0 01.59 1.033 1.44 1.44 0 002.777-.763A5.3 5.3 0 0010.52 5.45a1.44 1.44 0 10-2.074 2zM12.644 19.855a5.3 5.3 0 001.756.3h.617a1.44 1.44 0 000-2.88H14.4a2.449 2.449 0 01-.8-.136 1.44 1.44 0 10-.956 2.716zM5.106 6.722a1.439 1.439 0 10-.2-2.872 5.245 5.245 0 00-2.483.825A1.44 1.44 0 103.984 7.1a2.391 2.391 0 011.122-.378zM23.578 10.977L20.7 8.1a1.439 1.439 0 00-2.038 0l-2.879 2.879a1.44 1.44 0 001.017 2.456h5.76a1.44 1.44 0 001.018-2.458z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});