define("ember-svg-jar/inlined/add-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>add-tab</title><path d=\"M16 18.468a3.382 3.382 0 002.067-.832l4.775-4.584a1.455 1.455 0 000-2.107l-4.78-4.581A3.385 3.385 0 0016 5.532H2.212a1.5 1.5 0 00-1.5 1.5v9.936a1.5 1.5 0 001.5 1.5zm-4.283-7.5a1 1 0 010 2H9.462a.25.25 0 00-.25.25v2.25a1 1 0 01-2 0v-2.25a.25.25 0 00-.25-.25h-2.25a1 1 0 110-2h2.25a.25.25 0 00.25-.25v-2.25a1 1 0 012 0v2.25a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});