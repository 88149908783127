define("ember-svg-jar/inlined/notes-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-star</title><path d=\"M9.362 17.626a.263.263 0 00-.217-.126H2.251A.25.25 0 012 17.25V4.75a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v4.469a.247.247 0 00.361.223 3.271 3.271 0 011.4-.435.245.245 0 00.235-.242V4.5a2 2 0 00-2-2h-1.25A.25.25 0 0114 2.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-1.5A.25.25 0 016 2.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25H2a2 2 0 00-2 2v13a2 2 0 002 2h8.448a.249.249 0 00.183-.42 15.338 15.338 0 01-1.269-1.454z\"/><path d=\"M23.609 17.138a1.264 1.264 0 00.3-1.378A1.234 1.234 0 0022.74 15h-2.5a.249.249 0 01-.229-.151l-1.344-3.117-.013-.027a1.295 1.295 0 00-2.3 0l-.012.027-1.344 3.117a.252.252 0 01-.23.151h-2.5a1.234 1.234 0 00-1.169.761 1.264 1.264 0 00.3 1.378l2.213 1.972a.25.25 0 01.063.286l-1.224 2.815a1.255 1.255 0 00.315 1.451 1.277 1.277 0 001.506.166l3.113-1.753a.249.249 0 01.245 0l3.113 1.753a1.277 1.277 0 001.506-.166 1.255 1.255 0 00.315-1.451L21.333 19.4a.25.25 0 01.063-.286z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});