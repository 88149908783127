define("ember-svg-jar/inlined/american-football-goal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>american-football-goal</title><path d=\"M9.511 9.878c1.556 1.072 4.058-.273 5.223-1.962 1.36-1.974 1.348-4.634-.026-5.581s-3.862-.01-5.223 1.965-1.348 4.631.026 5.578z\"/><path d=\"M19.5 0a1 1 0 00-1 1v13a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v14.5a1 1 0 001 1h6a.5.5 0 01.5.5v6.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V17a.5.5 0 01.5-.5h6a1 1 0 001-1V1a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});