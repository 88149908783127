define("ember-svg-jar/inlined/business-money-basket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-money-basket</title><path d=\"M21.306 23.88a.835.835 0 00.156.013.964.964 0 00.95-.809l.707-4.338v-.015a1.6 1.6 0 00-.469-1.511 2.049 2.049 0 00-1.42-.553H3.385a2.05 2.05 0 00-1.422.555 1.6 1.6 0 00-.463 1.509v.015l.706 4.338a.964.964 0 001.9-.31l-.634-3.889a.249.249 0 01.247-.29H5.9a.25.25 0 01.249.227l.381 4.194a.963.963 0 00.959.876h.088a.965.965 0 00.872-1.047l-.359-3.976a.25.25 0 01.249-.272H11.1a.249.249 0 01.249.25v4.083a.964.964 0 001.927 0v-4.081a.249.249 0 01.25-.25h3.234a.25.25 0 01.249.272l-.361 3.971a.965.965 0 00.872 1.047h.089a.963.963 0 00.958-.876l.381-4.189a.25.25 0 01.252-.224h1.7a.248.248 0 01.247.29l-.633 3.882a.963.963 0 00.792 1.108zM22.9 10.366l-3.963-9.027A2.07 2.07 0 0016.21.283L2.072 6.527a2.071 2.071 0 00-1.056 2.727l2.409 5.453a.962.962 0 00.882.575.979.979 0 00.393-.082.964.964 0 00.492-1.27L2.779 8.476a.14.14 0 01.072-.186l14.138-6.244a.14.14 0 01.185.069l3.967 9.029a.139.139 0 01-.071.185l-3.837 1.693a.964.964 0 00.779 1.764l3.836-1.694a2.069 2.069 0 001.056-2.725z\"/><path d=\"M8.849 10.227a2.072 2.072 0 001.928 1.3h.013l1.852-.011a.156.156 0 01.144.1.174.174 0 01-.085.2l-.409.167-1.151.469a.964.964 0 00.363 1.856.947.947 0 00.363-.072l.193-.078a.078.078 0 01.06 0 .086.086 0 01.043.043.963.963 0 00.891.6.98.98 0 00.365-.072.963.963 0 00.527-1.256.2.2 0 01.056-.229 2.076 2.076 0 00-1.35-3.657h-.013l-1.86.013a.155.155 0 01-.058-.3l1.569-.639a.963.963 0 00-.726-1.785l-.194.079a.071.071 0 01-.059 0 .075.075 0 01-.042-.042.963.963 0 00-1.785.726l.016.031a.158.158 0 01-.045.179 2.079 2.079 0 00-.606 2.378z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});