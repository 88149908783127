define("ember-svg-jar/inlined/road-sign-no-parking-allowed-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-no-parking-allowed-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a9.948 9.948 0 01-6.156-2.126.251.251 0 01-.026-.371l2.257-2.332a1 1 0 00-1.438-1.391l-2.213 2.288a.25.25 0 01-.2.076.248.248 0 01-.184-.1 9.99 9.99 0 0114-14 .25.25 0 01.022.379L15.95 6.468a1 1 0 101.389 1.439L19.5 5.818a.248.248 0 01.191-.07.254.254 0 01.18.095A9.994 9.994 0 0112 22z\"/><path d=\"M12.687 7.75h-2a1 1 0 00-1 1v8a1 1 0 002 0V14a.249.249 0 01.25-.25h.75a3 3 0 000-6zm0 4h-.75a.249.249 0 01-.25-.25V10a.249.249 0 01.25-.25h.75a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});