define("ember-svg-jar/inlined/diagram-dash-up-then-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-dash-up-then-down</title><path d=\"M.02 12.152a9.216 9.216 0 00.65 2.188 1.446 1.446 0 102.649-1.162 6.351 6.351 0 01-.446-1.5 1.446 1.446 0 00-2.853.474zM9.031 1.331a9.306 9.306 0 00-2.256.313 1.446 1.446 0 00.373 2.844 1.474 1.474 0 00.375-.049 6.427 6.427 0 011.553-.215A1.448 1.448 0 0010.5 2.755a1.473 1.473 0 00-1.469-1.424zM15.8 4.223a1.447 1.447 0 000-2.893h-1.925a1.447 1.447 0 000 2.893zM10.218 18.457a1.447 1.447 0 001.447 1.443h1.928a1.446 1.446 0 100-2.892h-1.928a1.446 1.446 0 00-1.447 1.449zM3.886 16.577a1.446 1.446 0 00.487 1.986 9.234 9.234 0 002.081.934A1.447 1.447 0 007.3 16.73a6.378 6.378 0 01-1.429-.639 1.446 1.446 0 00-1.985.486zM2.043 4.676A9.228 9.228 0 00.81 6.589a1.446 1.446 0 102.606 1.256 6.387 6.387 0 01.849-1.318 1.446 1.446 0 10-2.222-1.851zM22.554 1.33h-1.929a1.447 1.447 0 000 2.893h1.929a1.447 1.447 0 000-2.893zM23.576 19.48a1.446 1.446 0 000-2.046l-2.767-2.767a1.446 1.446 0 00-2.469 1.022V16.8a.25.25 0 01-.2.245 1.44 1.44 0 000 2.828.25.25 0 01.2.246v1.106a1.446 1.446 0 002.469 1.023z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});