define("ember-svg-jar/inlined/check-double", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>check-double</title><path d=\"M5.532 15.06a1.5 1.5 0 010-2.119L4 11.409a1 1 0 00-1.414 0L.543 13.452a1 1 0 000 1.414l4.793 4.793a1 1 0 001.414 0l1.514-1.513a.25.25 0 000-.354zM9.7 10.9l1.512 1.511a.25.25 0 00.354 0l6.362-6.362a.25.25 0 000-.354l-1.357-1.357a1 1 0 00-1.414 0L8.985 10.51a1.445 1.445 0 01.715.39z\"/><path d=\"M21.561 4.531a.5.5 0 00-.707 0l-9.115 9.115a.5.5 0 01-.707 0L8.989 11.6a.5.5 0 00-.707 0l-2.043 2.046a.5.5 0 000 .707l4.793 4.793a.5.5 0 00.707 0L23.6 7.282a.5.5 0 000-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});