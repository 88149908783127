define("ember-svg-jar/inlined/party-hats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>party-hats</title><path d=\"M19.5 19.5a4.483 4.483 0 00-1.481.25.5.5 0 00-.277.707A6.45 6.45 0 0118.5 23.5a.5.5 0 00.5.5h4.469a.5.5 0 00.5-.562A4.5 4.5 0 0019.5 19.5zM17.728 14.5h3.544a.5.5 0 00.467-.679l-1.772-4.608a.5.5 0 00-.934 0l-1.772 4.608a.5.5 0 00.467.679zM19.5 18.5A2.5 2.5 0 0022 16a.5.5 0 00-.5-.5h-4a.5.5 0 00-.5.5 2.5 2.5 0 002.5 2.5zM5.981 19.75a4.5 4.5 0 00-5.946 3.688.5.5 0 00.5.562H5a.5.5 0 00.5-.5 6.461 6.461 0 01.757-3.043.5.5 0 00-.276-.707zM2.728 14.5h3.544a.5.5 0 00.467-.679L4.967 9.213a.5.5 0 00-.934 0l-1.772 4.608a.5.5 0 00.467.679zM4.5 18.5A2.5 2.5 0 007 16a.5.5 0 00-.5-.5h-4a.5.5 0 00-.5.5 2.5 2.5 0 002.5 2.5zM8.677 11.5h6.646a.5.5 0 00.478-.647L12.478.353a.5.5 0 00-.956 0L8.2 10.853a.5.5 0 00.478.647zM13.718 18.8a.249.249 0 01-.027-.457A3.758 3.758 0 0015.75 15a3.69 3.69 0 00-.518-1.877.749.749 0 00-.648-.373H9.416a.749.749 0 00-.648.373A3.69 3.69 0 008.25 15a3.758 3.758 0 002.059 3.347.249.249 0 01-.027.457A5.009 5.009 0 007 23.5a.5.5 0 00.5.5h9a.5.5 0 00.5-.5 5.009 5.009 0 00-3.282-4.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});