define("ember-svg-jar/inlined/pencil-write-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pencil-write-3</title><path d=\"M22 11.49h-3.446a.251.251 0 00-.177.073l-5.191 5.19a1.991 1.991 0 01-.67.442L8.979 18.61a2 2 0 01-2.6-2.6l1.414-3.535a2 2 0 01.443-.671l.314-.314H2a2 2 0 00-2 2v6.5a2 2 0 002 2h20a2 2 0 002-2v-6.5a2 2 0 00-2-2z\"/><path d=\"M9.3 12.865a.5.5 0 00-.11.168l-1.418 3.535a.5.5 0 00.111.539.509.509 0 00.539.11l3.536-1.417a.468.468 0 00.167-.11l7.814-7.814a.5.5 0 00.147-.353.5.5 0 00-.147-.354L17.817 5.05a.516.516 0 00-.707 0zM18.525 4.343l2.121 2.121a.5.5 0 00.354.147.494.494 0 00.3-.108.421.421 0 00.058-.039L22.415 5.4a2 2 0 000-2.828 2.049 2.049 0 00-2.829 0l-1.061 1.06a.616.616 0 00-.041.063.493.493 0 00-.105.291.5.5 0 00.146.357z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});