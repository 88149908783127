define("ember-svg-jar/inlined/sim-card-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sim-card-2</title><path d=\"M20.654 5.82L16.181.531A1.506 1.506 0 0015.027 0H4.5A1.488 1.488 0 003 1.472v21.056A1.488 1.488 0 004.5 24h15a1.488 1.488 0 001.5-1.472V6.76a1.456 1.456 0 00-.346-.94zM9 13.75a.25.25 0 01-.25.25H6.5v-.223A2.3 2.3 0 018.818 11.5H9zm-2.5 1.5a.25.25 0 01.25-.25h2a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25zm0 2.75h2.25a.25.25 0 01.25.25v2.25h-.182A2.3 2.3 0 016.5 18.223zm3.5-6.25a.25.25 0 01.25-.25h3.5a.25.25 0 01.25.25v8.5a.25.25 0 01-.25.25h-3.5a.25.25 0 01-.25-.25zM17.5 14h-2.25a.25.25 0 01-.25-.25V11.5h.182a2.3 2.3 0 012.318 2.277zM15 18.25a.25.25 0 01.25-.25h2.25v.223a2.3 2.3 0 01-2.318 2.277H15zm2.5-1.5a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h2a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});