define("ember-svg-jar/inlined/image-file-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-edit</title><path d=\"M14.68 5.538a1.493 1.493 0 10-1.493 1.492 1.493 1.493 0 001.493-1.492zM11.122 12.9a.187.187 0 00.163.1h9.028a.186.186 0 00.158-.286l-3.236-5.178a.374.374 0 00-.633 0l-1.81 2.9a.187.187 0 01-.317 0L13.831 9.4a.374.374 0 00-.633 0l-2.071 3.312a.187.187 0 00-.005.188z\"/><path d=\"M23.414 3L21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v9.067a.249.249 0 00.073.177l1.5 1.5A.25.25 0 008 12.567V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-7.821a.247.247 0 00-.234.333c.162.437.359 1.153.447 1.486a.244.244 0 00.24.18H22a2 2 0 002-2V4.415A2 2 0 0023.414 3zM11.15 18.449l-6.58-6.581A2.71 2.71 0 00.964 11.6a2.622 2.622 0 00-.2 3.881l6.679 6.679a.25.25 0 00.353 0l3.354-3.36a.249.249 0 000-.351zM11.938 20.135l-2.809 2.808a.251.251 0 00.128.423l3.143.628a.566.566 0 00.452-.137.5.5 0 00.136-.451l-.628-3.143a.25.25 0 00-.422-.128z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});