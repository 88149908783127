define("ember-svg-jar/inlined/layout-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layout-right</title><path d=\"M22 0H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zM2.5 2h12a.5.5 0 01.5.5v19a.5.5 0 01-.5.5h-12a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5zm19 20h-4a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v19a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});