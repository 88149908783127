define("ember-svg-jar/inlined/audio-file-equalizer-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file-equalizer-alternate</title><path d=\"M21.707 5.707L16.293.293A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.465a.5.5 0 01.353.146l4.536 4.536a.5.5 0 01.146.353z\"/><path d=\"M8.5 18.25h-2a.75.75 0 000 1.5h2a.75.75 0 000-1.5zM8.5 15.25h-2a.75.75 0 000 1.5h2a.75.75 0 000-1.5zM8.5 12.25h-2a.75.75 0 000 1.5h2a.75.75 0 000-1.5zM8.5 9.25h-2a.75.75 0 000 1.5h2a.75.75 0 000-1.5zM17.5 18.25h-2a.75.75 0 000 1.5h2a.75.75 0 000-1.5zM17.5 15.25h-2a.75.75 0 000 1.5h2a.75.75 0 000-1.5zM17.5 12.25h-2a.75.75 0 000 1.5h2a.75.75 0 000-1.5zM13 18.25h-2a.75.75 0 000 1.5h2a.75.75 0 000-1.5zM13 15.25h-2a.75.75 0 000 1.5h2a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});