define("ember-svg-jar/inlined/warehouse-cart-package-ribbon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>warehouse-cart-package-ribbon</title><path d=\"M3.5 15.5h10a1 1 0 001-1V8a1 1 0 00-1-1h-2.75a.25.25 0 00-.25.25V10a.5.5 0 01-.8.4l-.9-.675a.5.5 0 00-.6 0l-.9.675a.5.5 0 01-.8-.4V7.25A.25.25 0 006.25 7H3.5a1 1 0 00-1 1v6.5a1 1 0 001 1z\"/><circle cx=\"4.5\" cy=\"22\" r=\"2\"/><circle cx=\"14\" cy=\"22\" r=\"2\"/><path d=\"M24 1a1 1 0 00-1-1h-1.37a3.013 3.013 0 00-2.939 2.4L15.85 16.2a1 1 0 01-.98.8H1a1 1 0 000 2h13.87a3.013 3.013 0 002.939-2.395L20.65 2.8a1 1 0 01.98-.8H23a1 1 0 001-1z\"/><rect x=\"5\" width=\"7\" height=\"6\" rx=\"1\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});