define("ember-svg-jar/inlined/medical-app-laptop-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-app-laptop-1</title><path d=\"M10.468 14.25a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1.5a.5.5 0 01.5-.5h1.5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-1.5a.5.5 0 01-.5-.5v-1.5a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v1.5a.5.5 0 01-.5.5h-1.5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h1.5a.5.5 0 01.5.5z\"/><path d=\"M3.468 14.733a1 1 0 001-1V5.417a.667.667 0 01.667-.667H18.8a.667.667 0 01.666.667v8.316a1 1 0 002 0V5.417A2.669 2.669 0 0018.8 2.75H5.135a2.67 2.67 0 00-2.667 2.667v8.316a1 1 0 001 1zM23.894 16.442a.5.5 0 00-.394-.192h-8a.5.5 0 00-.5.5c0 .71-1.232 1.5-3 1.5s-3-.79-3-1.5a.5.5 0 00-.5-.5h-8a.5.5 0 00-.485.621 5.772 5.772 0 005.608 4.379h12.754a5.773 5.773 0 005.608-4.379.5.5 0 00-.091-.429z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});