define("ember-svg-jar/inlined/dresser-drawers-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dresser-drawers-2</title><path d=\"M10.75 14h-10a.5.5 0 00-.5.5v8a1.5 1.5 0 001.5 1.5h9a.5.5 0 00.5-.5v-9a.5.5 0 00-.5-.5zM9.5 19.5a.75.75 0 01-1.5 0v-2a.75.75 0 011.5 0zM23.25 14h-10a.5.5 0 00-.5.5v9a.5.5 0 00.5.5h9a1.5 1.5 0 001.5-1.5v-8a.5.5 0 00-.5-.5zM16 19.5a.75.75 0 01-1.5 0v-2a.75.75 0 011.5 0z\"/><rect x=\".25\" y=\"7\" width=\"23.5\" height=\"5.5\" rx=\".5\" ry=\".5\"/><path d=\"M22.25 0H1.75a1.5 1.5 0 00-1.5 1.5V5a.5.5 0 00.5.5h22.5a.5.5 0 00.5-.5V1.5a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});