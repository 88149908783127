define("ember-svg-jar/inlined/travel-man-luggage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>travel-man-luggage</title><path d=\"M21.5 13h-2a.5.5 0 01-.5-.5V8.75a1 1 0 00-2 0v10.781a.468.468 0 00.474.469A2.5 2.5 0 0120 22.5a.5.5 0 00.5.5h1a1.5 1.5 0 001.5-1.5v-7a1.5 1.5 0 00-1.5-1.5z\"/><circle cx=\"17.5\" cy=\"22.5\" r=\"1.5\"/><path d=\"M8.86 9.206a.25.25 0 01-.14-.214.248.248 0 01.124-.223 4.7 4.7 0 10-4.688 0 .247.247 0 01-.016.437 5.441 5.441 0 00-3.083 4.9v2.969a.991.991 0 00.989.99h1.069a.494.494 0 01.493.454l.382 4.576a.994.994 0 00.986.905h3.048a.994.994 0 00.986-.907l.382-4.577a.494.494 0 01.493-.454h1.069a.991.991 0 00.989-.99V14.1A5.441 5.441 0 008.86 9.206zM6.5 7.423A2.725 2.725 0 013.778 4.7a2.625 2.625 0 01.139-.841.494.494 0 01.729-.259 5.7 5.7 0 003.009.857 5.529 5.529 0 001.121-.117.372.372 0 01.446.364A2.725 2.725 0 016.5 7.423z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});