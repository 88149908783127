define("ember-svg-jar/inlined/earth-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earth-remove</title><path d=\"M10.907 22.022a.254.254 0 00-.174-.106 9.974 9.974 0 01-3.092-.928.25.25 0 01-.141-.225v-1.15a2.492 2.492 0 01.893-1.913 4.492 4.492 0 001.266-1.779 7.851 7.851 0 01.264-.965 4.448 4.448 0 00.077-.7 4.505 4.505 0 00-4.5-4.5H2.584a.25.25 0 01-.241-.314 9.982 9.982 0 0114.546-6.16.25.25 0 01-.122.468H14.25a2.75 2.75 0 000 5.5 2.554 2.554 0 011.358.408.246.246 0 00.187.032 7.924 7.924 0 017.764 2.6.25.25 0 00.441-.152V12A12 12 0 107.341 23.059l.159.078v-.02A11.927 11.927 0 0012 24h.137a.25.25 0 00.156-.44 7.969 7.969 0 01-1.386-1.538z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm1.414 6.854l1.237 1.237a.75.75 0 11-1.06 1.06l-1.237-1.237a.5.5 0 00-.708 0l-1.237 1.237a.75.75 0 11-1.06-1.06l1.237-1.237a.5.5 0 000-.708l-1.237-1.237a.75.75 0 011.06-1.06l1.237 1.237a.5.5 0 00.708 0l1.237-1.237a.75.75 0 111.06 1.06l-1.237 1.237a.5.5 0 000 .708z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});