define("ember-svg-jar/inlined/vegetables-beet-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vegetables-beet-1</title><path d=\"M23.6 5.86a8.4 8.4 0 00-2.034-.3.5.5 0 00-.475.313 9.732 9.732 0 01-1.111 2.014.251.251 0 00.254.391 5.72 5.72 0 012.71.1.52.52 0 00.65-.378l.376-1.516a.52.52 0 00-.37-.624z\"/><path d=\"M18.211 8.281a.5.5 0 01.034-.615 8.289 8.289 0 002.062-5.489.52.52 0 00-.506-.52l-1.562-.039a.519.519 0 00-.533.524 5.714 5.714 0 01-1.254 3.615.5.5 0 01-.675.1 7.94 7.94 0 00-10.35.777c-2.951 2.95-3.746 7.89-1.81 11.246a1.121 1.121 0 01.134.513c.03.73-.569 1.074-.755 1.309-.332.324-1.112.843-1.508.631A1 1 0 00.541 22.1a2.4 2.4 0 001.151.287 4.18 4.18 0 002.715-1.263c.263-.207.6-.828 1.37-.768a1.01 1.01 0 01.434.123 8.359 8.359 0 004.18 1.057 10.141 10.141 0 007.067-2.867 7.745 7.745 0 002.321-5.878 8.569 8.569 0 00-1.568-4.51zm-5-.642a.751.751 0 01-.913.54 4.259 4.259 0 00-3.839.833.744.744 0 01-.474.17.75.75 0 01-.475-1.331 5.8 5.8 0 015.16-1.125.75.75 0 01.542.913z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});