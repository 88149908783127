define("ember-svg-jar/inlined/pathfinder-merge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pathfinder-merge</title><path d=\"M24 8a2 2 0 00-2-2h-3.75a.25.25 0 01-.25-.25V2a2 2 0 00-2-2H2a2 2 0 00-2 2v14a2 2 0 002 2h3.75a.25.25 0 01.25.25V22a2 2 0 002 2h14a2 2 0 002-2zm-2 13.5a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-3.25a.25.25 0 01.25-.25H16a2 2 0 002-2V8.25a.25.25 0 01.25-.25h3.25a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});