define("ember-svg-jar/inlined/synchronize-arrows-square-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>synchronize-arrows-square-warning</title><path d=\"M7 21.748h10a4.255 4.255 0 004.25-4.25 1.25 1.25 0 00-2.5 0 1.752 1.752 0 01-1.75 1.75H7A1.753 1.753 0 015.25 17.5v-4.25A.25.25 0 015.5 13h1a.5.5 0 00.384-.82l-2.5-3a.5.5 0 00-.769 0l-2.5 3A.5.5 0 001.5 13h1a.249.249 0 01.25.25v4.25A4.255 4.255 0 007 21.748zM22.884 11.818A.5.5 0 0022.5 11h-1a.25.25 0 01-.25-.25V6.5A4.255 4.255 0 0017 2.248H7A4.255 4.255 0 002.75 6.5a1.25 1.25 0 102.5 0A1.753 1.753 0 017 4.748h10A1.752 1.752 0 0118.75 6.5v4.25a.25.25 0 01-.25.25h-1a.5.5 0 00-.384.82l2.5 3a.5.5 0 00.768 0z\"/><path d=\"M12 12.978a1 1 0 001-1V8a1 1 0 00-2 0v3.98a1 1 0 001 .998z\"/><circle cx=\"12\" cy=\"15.728\" r=\"1.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});