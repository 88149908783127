define("ember-svg-jar/inlined/allowances-no-food-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>allowances-no-food-1</title><path d=\"M11.3 5.045a.75.75 0 00-.75.75v4.5a.5.5 0 01-1 0V5.8a.75.75 0 00-1.5 0v4.5a.5.5 0 01-1 0V5.8a.75.75 0 00-1.5 0v4.5a3.248 3.248 0 002.167 3.056.5.5 0 01.333.471V18.8a.75.75 0 001.5 0v-4.978a.5.5 0 01.333-.471 3.248 3.248 0 002.162-3.051V5.8a.75.75 0 00-.745-.755zM14.3 5.05a.75.75 0 00-.75.75v13a.75.75 0 001.5 0v-3.75a.5.5 0 01.5-.5h1.211A1.251 1.251 0 0018 13.145c-.014-.082-1.3-8.095-3.7-8.095z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a9.94 9.94 0 01-5.806-1.867.5.5 0 01-.062-.761l.807-.808a1 1 0 00-1.414-1.414l-.824.825a.5.5 0 01-.757-.058A9.994 9.994 0 0117.917 3.945a.5.5 0 01.058.756l-.746.746a1 1 0 001.414 1.414l.729-.729a.5.5 0 01.761.062A9.991 9.991 0 0112 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});