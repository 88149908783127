define("ember-svg-jar/inlined/trends-hot-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trends-hot-1</title><path d=\"M12 10.875v2.5a.75.75 0 001.5 0v-2.5a.75.75 0 00-1.5 0z\"/><path d=\"M21 4.125H3a3 3 0 00-3 3v9.75a3 3 0 003 3h18a3 3 0 003-3v-9.75a3 3 0 00-3-3zm-3.75 6a.75.75 0 010-1.5h3a.75.75 0 010 1.5h-.5a.25.25 0 00-.25.25v4.5a.75.75 0 01-1.5 0v-4.5a.25.25 0 00-.25-.25zm-2.25.75v2.5a2.25 2.25 0 01-4.5 0v-2.5a2.25 2.25 0 014.5 0zm-9.247 2a.25.25 0 00-.25.25v1.75a.75.75 0 01-1.5 0v-5.5a.75.75 0 011.5 0v1.75a.25.25 0 00.25.25h1a.25.25 0 00.25-.25v-1.75a.75.75 0 011.5 0v5.5a.75.75 0 01-1.5 0v-1.75a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});