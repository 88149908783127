define("ember-svg-jar/inlined/road-sign-2-5m-wide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-2.5m-wide</title><path d=\"M12 .25A11.75 11.75 0 1023.75 12 11.763 11.763 0 0012 .25zM8.25 9a.75.75 0 01-1.5 0 1.75 1.75 0 013.5 0 2.175 2.175 0 01-.477 1.358l-.438.549a.208.208 0 00-.025.223.211.211 0 00.19.12.75.75 0 010 1.5h-2a.75.75 0 01-.585-1.219L8.6 9.422A.682.682 0 008.75 9c0-.275-.5-.275-.5 0zm-2.72 3.53l-1 1a.746.746 0 01-.53.22.75.75 0 01-.75-.75v-2a.75.75 0 011.28-.53l1 1a.749.749 0 010 1.06zM12 10.5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm-2.25 4a.75.75 0 011.393-.386l.643 1.071a.25.25 0 00.428 0l.643-1.071a.75.75 0 011.393.386v4a.75.75 0 01-1.5 0v-1.085a.058.058 0 00-.107-.029.78.78 0 01-1.286 0 .058.058 0 00-.107.029V18.5a.75.75 0 01-1.5 0zm5.75-3.75H15a1.252 1.252 0 01-1.25-1.25V8a.75.75 0 01.75-.75h2a.75.75 0 010 1.5h-1a.25.25 0 000 .5 1.75 1.75 0 010 3.5h-1a.75.75 0 010-1.5h1a.25.25 0 000-.5zm2.97.72l1-1a.75.75 0 011.28.53v2a.75.75 0 01-1.28.53l-1-1a.749.749 0 010-1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});