define("ember-svg-jar/inlined/body-care-perfume-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>body-care-perfume-1</title><path d=\"M5.25 6.25a1 1 0 00-1-1h-2.5a1 1 0 000 2h2.5a1 1 0 001-1zM3.75 10.616l.866-.5a1 1 0 10-1-1.732l-.866.5a1 1 0 101 1.732zM2.75 3.616l.866.5a1 1 0 101-1.732l-.866-.5a1 1 0 00-1 1.732zM14.078 20H5.422a.25.25 0 00-.182.423 6.481 6.481 0 009.021 0 .25.25 0 00-.183-.423z\"/><path d=\"M22.636 10.389c-.84-1.7-2.286-2.759-3.462-2.632a.2.2 0 01-.168-.052 10.209 10.209 0 00-6.324-2.455s-.685.018-1.014.036a.329.329 0 01-.218-.072 1.99 1.99 0 00-2.2-.9 2.048 2.048 0 00-1.5 2.012V7.75a.5.5 0 00.5.5h.61A.145.145 0 019 8.4c0 .183.005.56.007.776a.162.162 0 01-.122.137A6.486 6.486 0 003.8 18.349a.257.257 0 00.235.151h11.432a.257.257 0 00.235-.151 6.485 6.485 0 00-5.041-9.032c-.057-.008-.169-.081-.169-.165 0-.2 0-.55.007-.751a.21.21 0 01.095-.151h.656a.5.5 0 00.5-.5v-.308a.18.18 0 01.171-.192h.761a7.887 7.887 0 014.9 1.75.223.223 0 01.071.236 5.02 5.02 0 00.5 3.368c.977 1.982 2.772 3.093 4.011 2.482s1.449-2.715.472-4.697z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});