define("ember-svg-jar/inlined/tags-block-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-block-alternate</title><circle cx=\"17.171\" cy=\"5.999\" r=\"1.5\"/><path d=\"M9.9 21.191a.249.249 0 00-.32-.114 1.027 1.027 0 01-1.117-.2l-6.171-6.171a1 1 0 010-1.414l11-11A.993.993 0 0114 2h6.672a.5.5 0 01.5.5v6.67a1 1 0 01-.293.708l-.166.166a.25.25 0 00.058.4 8.038 8.038 0 011.229.813.249.249 0 00.341-.027 2.928 2.928 0 00.826-2.06V2a2 2 0 00-2-2H14a2.978 2.978 0 00-2.121.879l-11 11a3 3 0 000 4.242l6.171 6.171a3.005 3.005 0 003.57.5.25.25 0 00.076-.373 8.079 8.079 0 01-.796-1.228z\"/><path d=\"M17.25 10.749a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm0 11a4.419 4.419 0 01-2.1-.532.249.249 0 01-.059-.4l5.727-5.727a.249.249 0 01.214-.07.246.246 0 01.183.129 4.422 4.422 0 01.532 2.1 4.5 4.5 0 01-4.497 4.5zm2.1-8.468a.255.255 0 01.129.184.25.25 0 01-.071.214l-5.728 5.727a.251.251 0 01-.4-.059 4.435 4.435 0 01-.532-2.1 4.505 4.505 0 014.5-4.5 4.439 4.439 0 012.101.534z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});