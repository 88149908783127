define("ember-svg-jar/inlined/smiley-one-eye-smile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-one-eye-smile</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm9.25 8.167a4.75 4.75 0 11-4.75-4.75 4.756 4.756 0 014.75 4.75zM16.942 16.4a5.706 5.706 0 01-9.882 0 .438.438 0 01.379-.658h9.123a.439.439 0 01.38.658zM7.5 8a2 2 0 11-2 2 2 2 0 012-2z\"/><circle cx=\"16.5\" cy=\"8.167\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});