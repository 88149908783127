define("ember-svg-jar/inlined/audio-book-art", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-book-art</title><path d=\"M9.166 11.356a2.738 2.738 0 00.9.162 2.321 2.321 0 002.146-1.654 1.018 1.018 0 011.249-.653.749.749 0 10.57-1.386 2.493 2.493 0 00-3.19 1.432c-.39.878-.766.83-1.176.684A4.482 4.482 0 016.386 5.9c0-2.427 2.437-4.4 5.432-4.4a6.212 6.212 0 013.566 1.085.75.75 0 10.867-1.224A7.717 7.717 0 0011.818 0C8 0 4.886 2.649 4.886 5.9a5.953 5.953 0 004.28 5.456z\"/><path d=\"M7.921 7a.9.9 0 10.9-.9.9.9 0 00-.9.9zM10.621 4a.9.9 0 10-.9.9.9.9 0 00.9-.9zM11.821 3.4a.9.9 0 10.9-.9.9.9 0 00-.9.9zM16.6 6.614a.5.5 0 01.039.514l-2.919 5.839a.75.75 0 101.341.671L18 7.769a.5.5 0 01.4-.275 2.453 2.453 0 001.686-1.027 3.309 3.309 0 00.534-2.494 4 4 0 00-1.32-2.592.5.5 0 00-.8.359c-.148 1.738-1.187 1.592-1.988 2.754a1.838 1.838 0 00.088 2.12z\"/><path d=\"M23 6a1 1 0 00-1 1v11.038a.5.5 0 01-.475.5 18.324 18.324 0 00-7.79 1.895.5.5 0 01-.735-.442V15a1 1 0 10-2 0v4.99a.5.5 0 01-.735.441 18.32 18.32 0 00-7.79-1.895.5.5 0 01-.475-.5v-9.5a.5.5 0 01.528-.5c.135.008.269.016.4.026a1 1 0 00.142-1.995Q2.336 6.014 1.533 6h-.024a1.518 1.518 0 00-1.058.433A1.491 1.491 0 000 7.51v11.5a1.508 1.508 0 001.482 1.495c4.681.078 7.32 1.284 8.629 2.163a5.98 5.98 0 011.143 1 1 1 0 001.493 0 5.974 5.974 0 011.142-1c1.309-.879 3.948-2.085 8.629-2.163A1.508 1.508 0 0024 19.01V7a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});