define("ember-svg-jar/inlined/smiley-grumpy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-grumpy</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM9 7h.052a2 2 0 11-2.04 2.221A.249.249 0 006.764 9H5.5a1 1 0 010-2zm-2.448 9.954a6.5 6.5 0 0110.9 0 1 1 0 01-1.676 1.092 4.5 4.5 0 00-7.544 0 1 1 0 11-1.676-1.092zM17.5 11a2 2 0 01-1.988-1.778.249.249 0 00-.248-.222H14a1 1 0 010-2h3.552a2 2 0 01-.052 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});