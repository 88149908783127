define("ember-svg-jar/inlined/arrow-right-12", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5.501 24a1 1 0 01-.647-1.763L16.952 12 4.854 1.764A1.001 1.001 0 016.146.236l13 11a1 1 0 010 1.528l-13 11a1.002 1.002 0 01-.645.236z\"/><path fill=\"none\" d=\"M0 0h24v24H0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});