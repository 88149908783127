define("ember-svg-jar/inlined/video-game-ping-pong", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-ping-pong</title><path d=\"M3.875 2.75a.75.75 0 00.75-.75.5.5 0 011 0 1.042 1.042 0 01-.226.645L3.29 5.281A.75.75 0 003.875 6.5h2.5a.75.75 0 000-1.5h-.419a.25.25 0 01-.2-.406l.81-1.013A2.544 2.544 0 007.125 2a2 2 0 00-4 0 .75.75 0 00.75.75zM18.875 6.5a2 2 0 002-2V2a2 2 0 00-4 0v2.5a2 2 0 002 2zm-.5-4.5a.5.5 0 011 0v2.5a.5.5 0 01-1 0zM22.5 8h-21A1.5 1.5 0 000 9.5v13A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-13A1.5 1.5 0 0022.5 8zM4.25 21.5a.75.75 0 01-1.5 0v-5a.75.75 0 011.5 0zm12-5.25A1.25 1.25 0 1117.5 15a1.25 1.25 0 01-1.25 1.25zm5-.75a.75.75 0 01-1.5 0v-5a.75.75 0 011.5 0zM10 4.25h3.5a.75.75 0 000-1.5H10a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});