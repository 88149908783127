define("ember-svg-jar/inlined/multiple-actions-heart.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-heart</title><path d=\"M18.266 11.531a.5.5 0 00.256-.636q-.063-.162-.141-.318c-.454-.907-1.9-1.44-3.911-2.179l-.485-.172a1.8 1.8 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 0012 0a2.989 2.989 0 00-1.035.182.5.5 0 00-.3.3.507.507 0 00.041.423 5.02 5.02 0 01.694 2.58 6.486 6.486 0 01-.773 3.384.5.5 0 00.256.719c1.77.672 3.047 1.281 3.622 2.43a5.615 5.615 0 01.29.707.5.5 0 00.408.336 5.043 5.043 0 012.036.733.5.5 0 00.53 0 4.642 4.642 0 01.497-.263z\"/><path d=\"M13.106 11.211a.45.45 0 00.275-.634c-.454-.907-1.9-1.44-3.911-2.179l-.485-.172a1.8 1.8 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 007 0a3.22 3.22 0 00-3.146 3.485 4.605 4.605 0 001.193 3.538 1.877 1.877 0 010 1.185l-.515.19c-2.01.739-3.46 1.272-3.914 2.179A7.542 7.542 0 000 13.5a.5.5 0 00.5.5h9.125a.5.5 0 00.445-.272 4.963 4.963 0 013.036-2.517zM23.627 14.449a3.525 3.525 0 00-5.646-.916l-.305.3a.25.25 0 01-.353 0l-.3-.3a3.525 3.525 0 00-4.993 4.978l5.113 5.335a.5.5 0 00.723 0l5.106-5.327a3.524 3.524 0 00.655-4.07z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});