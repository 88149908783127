define("ember-svg-jar/inlined/make-up-table-mirror", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>make-up-table-mirror</title><path d=\"M0 9.575a12.064 12.064 0 0010.56 11.077.5.5 0 01.44.5v.348a.5.5 0 01-.5.5H4.25a1 1 0 000 2h15.5a1 1 0 000-2H13.5a.5.5 0 01-.5-.5v-.352a.5.5 0 01.44-.5A12.064 12.064 0 0024 9.575 1 1 0 0023 8.5h-1.792a.5.5 0 01-.5-.46 8.74 8.74 0 00-17.424 0 .5.5 0 01-.5.459H1a1 1 0 00-1 1.076zM12 2a6.75 6.75 0 11-6.75 6.75A6.758 6.758 0 0112 2zm-9.733 9.12a.5.5 0 01.486-.62h.3a.469.469 0 01.455.356 8.751 8.751 0 0016.981.022.5.5 0 01.485-.378h.276a.5.5 0 01.486.62 10.024 10.024 0 01-19.466 0z\"/><path d=\"M8.75 9.25A3.755 3.755 0 0112.5 5.5a1 1 0 000-2 5.756 5.756 0 00-5.75 5.75 1 1 0 002 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});