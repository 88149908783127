define("ember-svg-jar/inlined/cash-eye", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-eye</title><path d=\"M12 20.025c4.988 0 8.3-3.125 11.453-6.622a2.071 2.071 0 000-2.8C20.278 7.071 16.992 3.975 12 3.975S3.7 7.093.548 10.6a2.068 2.068 0 000 2.806C3.73 16.938 7.018 20.025 12 20.025zm5.272-12.177a.252.252 0 01.321-.034 24.078 24.078 0 014.383 4.132.08.08 0 010 .109 23.954 23.954 0 01-4.382 4.132.251.251 0 01-.32-.035.247.247 0 01-.022-.32 6.5 6.5 0 000-7.663.251.251 0 01.02-.321zM13.2 13.253l-2.938-1.1a2.092 2.092 0 01-1.177-2.027 2.086 2.086 0 011.946-2.087.248.248 0 00.216-.247v-.655a.75.75 0 011.5 0v.621a.25.25 0 00.25.25h1.162a.75.75 0 110 1.5h-2.7c-.4 0-.872.106-.872.615a.825.825 0 00.209.625l2.938 1.1a2.09 2.09 0 011.177 2.026 2.086 2.086 0 01-1.946 2.087.25.25 0 00-.216.248v.652a.75.75 0 01-1.5 0v-.621a.249.249 0 00-.25-.25H9.838a.75.75 0 010-1.5h2.7c.4 0 .872-.107.872-.616a.826.826 0 00-.21-.621zM6.408 7.814a.25.25 0 01.342.355 6.5 6.5 0 000 7.662.25.25 0 01-.342.355 24.035 24.035 0 01-4.382-4.13.082.082 0 010-.109 23.947 23.947 0 014.382-4.133z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});