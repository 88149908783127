define("ember-svg-jar/inlined/crypto-currency-bitcoin-browser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-browser</title><path d=\"M9.5 17.25a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.5a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.719a.251.251 0 01.15-.229A2.244 2.244 0 0015.25 15a2.219 2.219 0 00-.46-1.349.249.249 0 010-.3A2.219 2.219 0 0015.25 12a2.248 2.248 0 00-1.5-2.112V9a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25V9a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25.75.75 0 00-.75.75v6a.75.75 0 00.75.75zm3.5-1.5h-2.5a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H13a.75.75 0 010 1.5zm-2.75-4.25a.25.25 0 01.25-.25H13a.75.75 0 010 1.5h-2.5a.25.25 0 01-.25-.25z\"/><path d=\"M24 5a3 3 0 00-3-3H3a3 3 0 00-3 3v14a3 3 0 003 3h18a3 3 0 003-3zM8 4.5a1 1 0 11-1-1 1 1 0 011 1zm-3.5 0a1 1 0 11-1-1 1 1 0 011 1zm6 1a1 1 0 111-1 1 1 0 01-1 1zM22 19a1 1 0 01-1 1H3a1 1 0 01-1-1V8a1 1 0 011-1h18a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});