define("ember-svg-jar/inlined/bandage-leg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bandage-leg</title><path d=\"M20.024 5.619a.5.5 0 00-.37-.483l-9-2.421a.5.5 0 00-.63.483v2.3a.5.5 0 00.362.481l9 2.574a.5.5 0 00.638-.48zM16.582 18.563a.5.5 0 00-.113-.908l-5.8-1.735a.5.5 0 00-.643.479V18h-1.5a.5.5 0 00-.5.5v5a.5.5 0 00.5.5h4.75a.5.5 0 00.5-.5 5.748 5.748 0 012.806-4.937zM15.272 23.5a.5.5 0 00.5.5h2.752a1.5 1.5 0 001.5-1.5v-2.75a.5.5 0 00-.5-.5 4.255 4.255 0 00-4.252 4.25zM1.2 22.126A1.5 1.5 0 002.65 24h3.372a.5.5 0 00.5-.5v-4.975a.5.5 0 00-.55-.5A5.5 5.5 0 001.2 22.126zM20.024 1a1 1 0 00-1-1h-8a1 1 0 00-.712.3.5.5 0 00.228.833l8.854 2.381a.5.5 0 00.63-.483z\"/><path d=\"M.082 12.287c-.394 1.26.66 2.674 2.4 3.218a4.4 4.4 0 001.314.207 2.437 2.437 0 002.491-1.483 1.752 1.752 0 00.053-.8.25.25 0 01.319-.278l16.056 4.814a1 1 0 001.285-.96v-4.817a1 1 0 00-.725-.96L5.51 6.132a3.206 3.206 0 00-2.275.175 2.453 2.453 0 00-1.376 1.331zm2.848 1.786c-1.02-.319-1.52-1.006-1.416-1.339.072-.228.476-.432 1.054-.432a2.954 2.954 0 01.871.139c1.019.319 1.519 1 1.416 1.338s-.906.615-1.925.294z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});