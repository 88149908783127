define("ember-svg-jar/inlined/file-code-refresh-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-refresh-1</title><path d=\"M16.411 11.14a.75.75 0 001.078 1.043l.017-.019 2.667-2.856a.752.752 0 000-1.025l-2.666-2.856a.751.751 0 00-1.061-.036.731.731 0 00-.036 1.06l2.029 2.174a.249.249 0 010 .341z\"/><path d=\"M23.539 3L21.125.587A2 2 0 0019.71 0H8.125a2 2 0 00-2 2v7.8a.245.245 0 00.23.249c.348.021 1.014.071 1.486.144a.247.247 0 00.284-.247V2.5a.5.5 0 01.5-.5H19.5a.5.5 0 01.353.146l2.122 2.121a.5.5 0 01.146.354V18a.5.5 0 01-.5.5h-7.4a.242.242 0 00-.238.205 7.185 7.185 0 01-.409 1.454.244.244 0 00.225.34h8.318a2 2 0 002-2V4.415A2 2 0 0023.539 3z\"/><path d=\"M14.6 12.046s-.036.3.1.219a1.122 1.122 0 00.119-.083.749.749 0 00.018-1.042l-2.026-2.174a.249.249 0 010-.341l2.028-2.174a.75.75 0 00-1.078-1.043l-.018.019-2.666 2.857a.749.749 0 000 1.023l.72.772a.245.245 0 00.254.064 1.959 1.959 0 011.317.055 2 2 0 011.232 1.848zM12.8 11.56a.5.5 0 00-.545.109l-1.3 1.3a.245.245 0 01-.343 0 6.312 6.312 0 00-4.078-1.716 6.465 6.465 0 00-6.652 6.29 6.375 6.375 0 0011.35 4.057 1 1 0 10-1.562-1.246 4.376 4.376 0 11-3.42-7.1 4.34 4.34 0 012.925 1.122.257.257 0 010 .367l-1.382 1.383a.5.5 0 00.354.853H12.6a.5.5 0 00.5-.5v-4.457a.5.5 0 00-.3-.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});