define("ember-svg-jar/inlined/appliances-vitamix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>appliances-vitamix</title><path d=\"M16.732 22.725l-1.429-5a1 1 0 00-.961-.725H5.85a1 1 0 00-.961.725l-1.429 5A1 1 0 004.422 24H15.77a1 1 0 00.962-1.275zM10.1 22.5a1.75 1.75 0 111.75-1.75 1.749 1.749 0 01-1.75 1.75zM16.288 3a.5.5 0 00.446-.727c-.167-.326-.432-.844-.862-1.72A1 1 0 0014.978 0H5.214a1 1 0 00-.894.553c-.43.876-.7 1.394-.862 1.72A.5.5 0 003.9 3zM20.6 7.5a3 3 0 00-3-3H4.09a.5.5 0 00-.486.617l2.308 9.616a1 1 0 00.973.767h6.423a1 1 0 00.972-.767l1.884-7.85a.5.5 0 01.486-.383h.95a1 1 0 011 1V12a1 1 0 002 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});