define("ember-svg-jar/inlined/lucide-calendar-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21 12V6a2 2 0 00-2-2H5a2 2 0 00-2 2v14c0 1.1.9 2 2 2h7.5M16 2v4M8 2v4M3 10h18\"/><path d=\"M18 21a3 3 0 100-6 3 3 0 000 6v0zM22 22l-1.5-1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});