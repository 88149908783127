define("ember-svg-jar/inlined/arrow-thick-circle-bottom-right-corner-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-circle-bottom-right-corner-1</title><path d=\"M20.481 3.519a12 12 0 100 16.971 11.994 11.994 0 000-16.971zM6.505 16.6v-1.822a.5.5 0 01.5-.5h4.544a.25.25 0 00.177-.427L6.5 8.623a.5.5 0 010-.707l1.41-1.411a.5.5 0 01.708 0l5.227 5.228a.25.25 0 00.427-.177V7.014a.5.5 0 01.5-.5h1.824a.5.5 0 01.5.5V16.6a.5.5 0 01-.5.5H7.005a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});