define("ember-svg-jar/inlined/love-whale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-whale</title><path d=\"M13.754 8.638c.932-.19 3.561-.824 4.161-2.032a1.5 1.5 0 00-1.93-2.164.5.5 0 01-.716-.355 1.5 1.5 0 00-2.89-.23c-.6 1.208.482 3.682.895 4.541a.431.431 0 00.48.24zM5.461 7.559a.433.433 0 00.521-.086c.766-.81 3.179-3.522 2.892-5.262a1.935 1.935 0 00-3.708-.64.5.5 0 01-.376.289.5.5 0 01-.448-.154A1.935 1.935 0 001.034 3.5c.287 1.742 3.442 3.537 4.427 4.059zM17.741 9.681c-4.426 0-6.083 3.308-7.414 5.966C9.285 17.727 8.58 19 7.275 19a2.079 2.079 0 01-1.587-.6 2.787 2.787 0 01-.644-1.248.5.5 0 01.23-.555A3.49 3.49 0 007 13.589a3.35 3.35 0 00-.055-.527.5.5 0 00-.658-.395 9.146 9.146 0 01-5.574 0 .505.505 0 00-.425.065.549.549 0 00-.24.375 3.063 3.063 0 00-.048.482 3.5 3.5 0 001.814 3.059.5.5 0 01.259.428A8.109 8.109 0 003.708 22.2 4.372 4.372 0 007.1 23.61h10.618A6.278 6.278 0 0024 17.35v-1.433a6.254 6.254 0 00-6.259-6.236zM19.5 15.61a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});