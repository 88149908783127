define("ember-svg-jar/inlined/charging-battery-cable-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>charging-battery-cable-1</title><path d=\"M24 7a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H2a2 2 0 00-2 2v9a2 2 0 002 2h2.25a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25H2.5a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5h16a.5.5 0 01.5.5V6a1 1 0 001 1h1.75a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25H20a1 1 0 00-1 1v1.5a.5.5 0 01-.5.5h-1.75a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25H19a2 2 0 002-2v-1h1a2 2 0 002-2z\"/><path d=\"M15 11.5a1.5 1.5 0 00-1.5-1.5V7a1 1 0 00-2 0v2.746a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V7a1 1 0 00-2 0v3A1.5 1.5 0 006 11.5v3A3.5 3.5 0 009.5 18v3a1 1 0 002 0v-3a3.5 3.5 0 003.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});