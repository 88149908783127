define("ember-svg-jar/inlined/app-window-multiple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-multiple</title><path d=\"M19.5 5.5a3 3 0 00-3-3H4a3 3 0 00-3 3V15a3 3 0 003 3h12.5a3 3 0 003-3zm-8.846-1a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0A.972.972 0 0110.5 5a.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0A.969.969 0 019 5a.972.972 0 01-.154.5.966.966 0 01-1.692 0A.972.972 0 017 5a.969.969 0 01.154-.5zm-3.562.091A1 1 0 014.5 4a.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0A.972.972 0 013.5 5a.987.987 0 01.092-.408zM17.5 15a1 1 0 01-1 1H4a1 1 0 01-1-1V7.751a.25.25 0 01.25-.25h14a.25.25 0 01.25.25z\"/><path d=\"M7.5 21.5H20a3 3 0 003-3V7a1 1 0 00-1-1 1 1 0 00-1 1v11.5a1 1 0 01-1 1H7.5a1 1 0 00-1 1 1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});