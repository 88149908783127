define("ember-svg-jar/inlined/cat-bell-collar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cat-bell-collar</title><path d=\"M24 8.155c0-3.335-6.085-5.327-11.968-5.327C6.118 2.828 0 4.82 0 8.155v4.673c0 2.033 3.018 3.64 6.759 4.442a.252.252 0 00.194-.039.245.245 0 00.1-.167 5 5 0 019.887.025.252.252 0 00.3.208c3.769-.785 6.76-2.39 6.76-4.469zm-2 0a1.224 1.224 0 01-.453.827.251.251 0 01-.278.047 23.5 23.5 0 00-9.236-1.7 21 21 0 00-9.007 1.906.251.251 0 01-.265-.021C2.265 8.847 2 8.48 2 8.155c0-1.324 4-3.327 10.032-3.327C18.024 4.828 22 6.831 22 8.155zM6.171 10.647a.25.25 0 01-.021-.478 16.523 16.523 0 015.883-.841 20.523 20.523 0 016.173.766.25.25 0 010 .481 23.552 23.552 0 01-6.169.753 23.891 23.891 0 01-5.866-.681z\"/><path d=\"M12 14.328a3.5 3.5 0 00-1.073 6.832.249.249 0 00.223-.035.253.253 0 00.1-.2l.029-3.1a.75.75 0 111.5.014l-.029 3.082a.25.25 0 00.327.24A3.5 3.5 0 0012 14.328z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});