define("ember-svg-jar/inlined/style-one-pin-train-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-train_1</title><path d=\"M16.614 13.857a.751.751 0 011.03.257l.827 1.379a.5.5 0 00.813.063 9.5 9.5 0 10-14.568 0 .5.5 0 00.813-.063l.827-1.379a.751.751 0 011.288.772l-1.085 1.807a.5.5 0 00.143.668 8.716 8.716 0 001.3.758 2.509 2.509 0 011.218 1.222l1.872 4.076a1 1 0 001.817 0l1.876-4.079A2.5 2.5 0 0116 18.118a8.69 8.69 0 001.3-.757.5.5 0 00.143-.668l-1.085-1.807a.751.751 0 01.256-1.029zM15 13H9a1.5 1.5 0 01-1.5-1.5v-4a4.5 4.5 0 019 0v4A1.5 1.5 0 0115 13z\"/><circle cx=\"9.5\" cy=\"11\" r=\"1\"/><circle cx=\"14.5\" cy=\"11\" r=\"1\"/><ellipse cx=\"12\" cy=\"7\" rx=\"2.5\" ry=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});