define("ember-svg-jar/inlined/single-woman-actions-video.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-video</title><path d=\"M9.456 15.5a3 3 0 011.162-2.368.5.5 0 00-.082-.842 7.337 7.337 0 00-3.286-.79 7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h8.412a.5.5 0 00.5-.5zM23.327 15.517l-2.807.749a.25.25 0 01-.314-.242V15.5a1.5 1.5 0 00-1.5-1.5h-6.25a1.5 1.5 0 00-1.5 1.5v7a1.5 1.5 0 001.5 1.5h6.25a1.5 1.5 0 001.5-1.5v-.524a.249.249 0 01.314-.241l2.807.748a.5.5 0 00.629-.483v-6a.5.5 0 00-.629-.483zM1.569 10.442a1 1 0 001.383-.3 5.233 5.233 0 00.454-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .249.249 0 01.411.082 5.233 5.233 0 00.454.9A1 1 0 0013.15 9.06a4.578 4.578 0 01-.643-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248a4.573 4.573 0 01-.642 2.512 1 1 0 00.298 1.382zM7.211 8.6A2.7 2.7 0 014.47 6.248a.5.5 0 01.324-.577 5.711 5.711 0 002.245-1.439.25.25 0 01.361 0 5.7 5.7 0 002.23 1.437.5.5 0 01.323.576A2.708 2.708 0 017.211 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});