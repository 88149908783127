define("ember-svg-jar/inlined/settings-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>settings-horizontal</title><path d=\"M21.75.251H2.25a2 2 0 00-2 2v19.5a2 2 0 002 2h19.5a2 2 0 002-2v-19.5a2 2 0 00-2-2zM19.25 13h-9.5a.75.75 0 010-1.5h9.5a.75.75 0 010 1.5zm.75 5.25a.75.75 0 01-.75.75h-4.5a.75.75 0 010-1.5h4.5a.75.75 0 01.75.751zm-8.176 1.22a1.5 1.5 0 111.424-1.57 1.5 1.5 0 01-1.424 1.57zM5.75 17.5h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 010-1.5zM4.742 12a1.5 1.5 0 111.423 1.573A1.5 1.5 0 014.742 12zM8.25 6.751H5.5a.75.75 0 010-1.5h2.75a.75.75 0 010 1.5zm3.411-1.983a1.5 1.5 0 11-1.424 1.573 1.5 1.5 0 011.424-1.573zM19.25 7h-4a.75.75 0 010-1.5h4a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});