define("ember-svg-jar/inlined/arrow-thick-right-bottom-corner-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-right bottom-corner-1</title><path d=\"M22.767 3.776h-5a1 1 0 00-1 1v6.439a.25.25 0 01-.426.177L5.474.52a1.023 1.023 0 00-1.414 0L.523 4.057a1 1 0 000 1.413l10.864 10.868a.25.25 0 01-.177.427H4.767a1 1 0 00-1 1v5a1 1 0 001 1h18a1 1 0 001-1V4.776a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});