define("ember-svg-jar/inlined/animal-products-fried-egg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>animal-products-fried-egg</title><path d=\"M10.065 3.5a4.562 4.562 0 104.562 4.562A4.567 4.567 0 0010.065 3.5zm-.479 3.25a.834.834 0 00-.833.833.75.75 0 01-1.5 0 2.335 2.335 0 012.333-2.329.75.75 0 010 1.5z\"/><path d=\"M10.858 24c-3.007 0-5.242-1.482-5.978-4.056a32.571 32.571 0 00-3.1-7.218C.253 9.944-.85 7.933.877 4.661 2.819.981 9.673-.971 15.545.483 20.918 1.815 24 5.627 24 10.944c0 6.985-6.1 12.163-11.744 12.955a10.015 10.015 0 01-1.398.101zM2.645 5.594c-1.224 2.321-.563 3.527.887 6.17a34.3 34.3 0 013.27 7.63c.808 2.822 3.889 2.707 5.176 2.525C16.8 21.243 22 16.855 22 10.944s-4.345-7.878-6.936-8.52c-4.885-1.21-10.92.331-12.419 3.17z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});