define("ember-svg-jar/inlined/car-repair-wash-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-repair-wash-3</title><path d=\"M11.989 2.417a1.209 1.209 0 10-1.209-1.209 1.21 1.21 0 001.209 1.209zM8.544 5.042a1.209 1.209 0 101.208-1.209 1.209 1.209 0 00-1.208 1.209zM8.155 2.417a1.209 1.209 0 10-1.208-1.209 1.21 1.21 0 001.208 1.209zM5.6 6.25a1.209 1.209 0 10-1.209-1.208A1.209 1.209 0 005.6 6.25zM15.822 2.417a1.209 1.209 0 10-1.208-1.209 1.21 1.21 0 001.208 1.209zM14.225 6.25a1.209 1.209 0 10-1.209-1.208 1.209 1.209 0 001.209 1.208zM18.377 6.25a1.209 1.209 0 10-1.208-1.208 1.21 1.21 0 001.208 1.208zM21.215 14.135a.251.251 0 010-.388l.895-.716a1 1 0 00-1.249-1.562l-.725.58a.249.249 0 01-.4-.168l-.1-.88a3.017 3.017 0 00-2.99-2.751H7.329a3.011 3.011 0 00-2.987 2.725l-.1.889a.249.249 0 01-.4.168l-.7-.563a1 1 0 00-1.249 1.562l.884.707a.249.249 0 01.094.194.253.253 0 01-.092.2 2.738 2.738 0 00-1.018 2.118v2.25a1.753 1.753 0 001.75 1.75h16.955a1.753 1.753 0 001.75-1.75v-2.25a2.739 2.739 0 00-1.001-2.115zm-15.08-1.162l.2-1.806a1.007 1.007 0 011-.917h9.319a1.013 1.013 0 011 .943l.2 1.78a.256.256 0 01-.063.194.249.249 0 01-.186.083H6.383a.249.249 0 01-.248-.277zM5.511 18.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm13 0a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM8.011 21.25h-4a.25.25 0 00-.25.25v.25a2.25 2.25 0 004.5 0v-.25a.249.249 0 00-.25-.25zM20.011 21.25h-4a.25.25 0 00-.25.25v.25a2.25 2.25 0 004.5 0v-.25a.249.249 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});