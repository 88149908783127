define("ember-svg-jar/inlined/real-estate-market-building-increase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-market-building-increase</title><path d=\"M14 20.25a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V6.75a1 1 0 00-1-1h-2a1 1 0 00-1 1zM23 .75h-2a1 1 0 00-1 1v18.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V1.75a1 1 0 00-1-1zM23 22H1a1 1 0 000 2h22a1 1 0 000-2zM6.75 8.5a1.246 1.246 0 00.884-.366l4.116-4.116a.5.5 0 01.707 0l.263.262A.75.75 0 0014 3.75v-3a.75.75 0 00-.75-.75h-3a.75.75 0 00-.53 1.28l.262.263a.5.5 0 010 .707L5.866 6.366A1.25 1.25 0 006.75 8.5zM8.765 11.326l-4-2.5a.5.5 0 00-.53 0l-4 2.5A.5.5 0 000 11.75v8.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v1.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-8.5a.5.5 0 00-.235-.424zM6 16.75H3a.75.75 0 010-1.5h3a.75.75 0 010 1.5zm0-3H3a.75.75 0 010-1.5h3a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});