define("ember-svg-jar/inlined/gender-male", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gender-male</title><path d=\"M24 .5a.5.5 0 00-.5-.5h-7a.5.5 0 00-.354.854l2.44 2.439a.25.25 0 010 .354L14.132 8.1a.25.25 0 01-.322.026 8.782 8.782 0 102.063 2.063.25.25 0 01.026-.322l4.455-4.454a.25.25 0 01.353 0l2.439 2.44A.5.5 0 0024 7.5zm-15.25 21A6.25 6.25 0 1115 15.25a6.257 6.257 0 01-6.25 6.25z\"/><path d=\"M24 .5a.5.5 0 00-.5-.5h-7a.5.5 0 00-.354.854l2.44 2.439a.25.25 0 010 .354L14.132 8.1a.25.25 0 01-.322.026 8.782 8.782 0 102.063 2.063.25.25 0 01.026-.322l4.455-4.454a.25.25 0 01.353 0l2.439 2.44A.5.5 0 0024 7.5zm-15.25 21A6.25 6.25 0 1115 15.25a6.257 6.257 0 01-6.25 6.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});