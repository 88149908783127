define("ember-svg-jar/inlined/tea-kettle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tea-kettle-1</title><path d=\"M18.5 22h-16a.5.5 0 00-.5.5A1.5 1.5 0 003.5 24h14a1.5 1.5 0 001.5-1.5.5.5 0 00-.5-.5zM18.5 7.5a5.519 5.519 0 00-1.977.367 1.979 1.979 0 01-.718.133H1a1 1 0 00-.6 1.8l1.82 1.373a1.511 1.511 0 01.568 1.5 42.789 42.789 0 00-.767 7.319.5.5 0 00.5.513h15.954a.5.5 0 00.5-.517c-.016-.5-.042-1-.075-1.5A5.5 5.5 0 0018.5 7.5zm-6.5 9a1.5 1.5 0 01-3 0V12a1.5 1.5 0 013 0zM22 13a3.5 3.5 0 01-1.206 2.644 3.464 3.464 0 01-1.531.773.5.5 0 01-.6-.426 34.236 34.236 0 00-1.218-5.791.5.5 0 01.382-.639A3.5 3.5 0 0122 13zM16.091 5.818a5.962 5.962 0 00-3.743-3.531.5.5 0 01-.348-.476V1.5a1.5 1.5 0 00-3 0v.311a.5.5 0 01-.348.476 5.962 5.962 0 00-3.743 3.531.5.5 0 00.466.682h10.25a.5.5 0 00.466-.682z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});