define("ember-svg-jar/inlined/baby-newborn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baby-newborn</title><path d=\"M18.258 13.3l-6.394 2.75a.25.25 0 00.018.466l6.031 2.061a.5.5 0 00.659-.417c.059-.523.126-1.48.381-4.356a.5.5 0 00-.7-.5zM17.453 20L5.947 16.068a.5.5 0 00-.66.517l.119 1.37a6.627 6.627 0 0012.318 2.769.5.5 0 00-.271-.724zM12 0a7.481 7.481 0 00-7.449 8.124l.5 5.738a.5.5 0 00.336.43l3.4 1.162a1 1 0 00.718-.028l9.4-4.04a.5.5 0 00.3-.416l.248-2.846A7.482 7.482 0 0012 0zm0 12a4.25 4.25 0 114.25-4.25A4.254 4.254 0 0112 12z\"/><circle cx=\"10.325\" cy=\"7.25\" r=\"1\"/><circle cx=\"13.825\" cy=\"7.25\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});