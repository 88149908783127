define("ember-svg-jar/inlined/shipment-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-box</title><path d=\"M21.993 6.75a.5.5 0 00.407-.791L18.8.919A1 1 0 0017.985.5H12.75a.5.5 0 00-.5.5v5.25a.5.5 0 00.5.5zM1 8.25a.5.5 0 00-.5.5V22.5a1 1 0 001 1h21a1 1 0 001-1V8.75a.5.5 0 00-.5-.5zM12.5 18a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5zM10.75 1a.5.5 0 00-.5-.5H6.015A1 1 0 005.2.919l-3.6 5.04a.5.5 0 00.407.791h8.243a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});