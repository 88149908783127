define("ember-svg-jar/inlined/engagement-calendar-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>engagement-calendar-date</title><path d=\"M22 3h-3a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v4.75a.75.75 0 01-1.5 0V3.5a.5.5 0 00-.5-.5h-6a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v4.75a.75.75 0 01-1.5 0V3.5A.5.5 0 004 3H2a2 2 0 00-2 2v17a2 2 0 002 2h20a2 2 0 002-2V5a2 2 0 00-2-2zm0 18.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M16.434 11.849a1.76 1.76 0 00-1.5-.849h-4.868a1.761 1.761 0 00-1.5.85l-1.013 1.687a2.128 2.128 0 00.411 2.685L12 19.811a.751.751 0 001 0l4.038-3.589a2.128 2.128 0 00.411-2.685zM15.1 15.937l-2.266 2.014a.5.5 0 01-.664 0L9.9 15.937a.25.25 0 01.166-.437h4.864a.25.25 0 01.166.437zm.654-2.064a.25.25 0 01-.218.127h-6.07a.25.25 0 01-.214-.379l.6-1a.253.253 0 01.215-.122h4.868a.251.251 0 01.214.121l.6 1a.25.25 0 01.003.253z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});