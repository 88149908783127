define("ember-svg-jar/inlined/optimization-timer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>optimization-timer</title><path d=\"M15.3 11.12a6.5 6.5 0 102.408 0 .25.25 0 01-.2-.245v-.625a.25.25 0 01.25-.25H19a1 1 0 000-2h-5a1 1 0 000 2h1.25a.25.25 0 01.25.25v.625a.249.249 0 01-.2.245zm.45 2.88a.75.75 0 011.5 0v2.5a.25.25 0 00.25.25h1a.75.75 0 010 1.5h-2a.75.75 0 01-.75-.75z\"/><path d=\"M23 3a3 3 0 00-3-3H4a3 3 0 00-3 3v11.5a3 3 0 003 3h4a1 1 0 000-2H4a1 1 0 01-1-1V5.25A.25.25 0 013.25 5h17.5a.25.25 0 01.25.25V11a1 1 0 002 0zm-18.25.5a.75.75 0 11.75-.75.749.749 0 01-.75.75zm2.5 0A.75.75 0 118 2.75a.749.749 0 01-.75.75zm2.5 0a.75.75 0 11.75-.75.749.749 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});