define("ember-svg-jar/inlined/gesture-tap-swipe-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-tap-swipe-right</title><path d=\"M23.836 8.9l-2-2.5a.751.751 0 00-1.336.468v1.25a.249.249 0 01-.25.25H15.5a1 1 0 000 2h4.75a.25.25 0 01.25.25v1.25a.751.751 0 001.336.469l2-2.5a.75.75 0 000-.937z\"/><path d=\"M7.25 3.623A7.252 7.252 0 001.1 14.716a.75.75 0 101.272-.795 5.75 5.75 0 119.746.013.749.749 0 101.268.8A7.25 7.25 0 007.25 3.623z\"/><path d=\"M7.254 7.374a3.5 3.5 0 00-3.5 3.5v9a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-9a3.5 3.5 0 00-3.5-3.5zM8.754 12a.375.375 0 01-.375.375h-2.25A.375.375 0 015.754 12v-1.126a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});