define("ember-svg-jar/inlined/cat-grass-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cat-grass-1</title><path d=\"M18.647 16H4.752a.5.5 0 00-.492.592l.827 4.435a3.269 3.269 0 003.139 2.723h6.947a3.264 3.264 0 003.135-2.7l.83-4.457a.5.5 0 00-.491-.593zM21.318 1.453a1 1 0 00-1.207.736l-2.134 8.791a.249.249 0 01-.242.191H16.64a.248.248 0 01-.183-.08.245.245 0 01-.066-.189l.55-7.261a1 1 0 10-1.994-.151l-.565 7.45a.249.249 0 01-.249.231h-.992a.25.25 0 01-.25-.25V1.25a1 1 0 00-2 0v9.671a.25.25 0 01-.25.25H9.619a.25.25 0 01-.249-.23l-.589-7.455a1 1 0 10-1.994.159L7.36 10.9a.25.25 0 01-.249.27h-.829a.251.251 0 01-.243-.191l-2.15-8.791a1 1 0 10-1.942.474l2.081 8.509h-.434a1.658 1.658 0 000 3.316h16.211a1.658 1.658 0 00.181-3.306l2.068-8.521a1 1 0 00-.736-1.207z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});