define("ember-svg-jar/inlined/police-hat-8", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-hat-8</title><path d=\"M12 0A10.016 10.016 0 002 10v4a.5.5 0 00.5.5h19a.5.5 0 00.5-.5v-4A10.016 10.016 0 0012 0zm2.39 8.35l.53.58a.693.693 0 01-.66 1.15l-.76-.18a.519.519 0 00-.61.36l-.23.75a.691.691 0 01-1.32 0l-.23-.75a.519.519 0 00-.61-.36l-.76.18a.693.693 0 01-.66-1.15l.53-.58a.5.5 0 000-.7l-.53-.58a.693.693 0 01.66-1.15l.76.18a.519.519 0 00.61-.36l.23-.75a.691.691 0 011.32 0l.23.75a.519.519 0 00.61.36l.76-.18a.693.693 0 01.66 1.15l-.53.58a.5.5 0 000 .7zM23.791 18.562l-1.9-2.375A.5.5 0 0021.5 16h-19a.5.5 0 00-.39.187L.2 18.574a1 1 0 00.584 1.577A50.137 50.137 0 0111.565 23.9a1 1 0 00.871 0 50.183 50.183 0 0110.782-3.75 1 1 0 00.72-.628 1.006 1.006 0 00-.147-.96z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});