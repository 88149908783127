define("ember-svg-jar/inlined/check-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>check-1</title><path d=\"M23.374.287a1.5 1.5 0 00-2.093.345L7.246 20.2l-4.685-4.689a1.5 1.5 0 10-2.122 2.121l5.935 5.934a1.525 1.525 0 002.279-.186l15.066-21a1.5 1.5 0 00-.345-2.093z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});