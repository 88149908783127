define("ember-svg-jar/inlined/famous-character-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character-star</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M12 3.5a8.5 8.5 0 108.5 8.5A8.5 8.5 0 0012 3.5zm5.021 8.624l-1.78 1.276a.251.251 0 00-.092.282l.82 2.457a1 1 0 01-1.549 1.111l-2.27-1.7a.249.249 0 00-.3 0l-2.27 1.7a1 1 0 01-1.549-1.117l.82-2.456a.251.251 0 00-.092-.282l-1.78-1.271a1 1 0 01.582-1.813h2.381a.25.25 0 00.239-.179l.862-2.869a1 1 0 011.914 0l.862 2.869a.25.25 0 00.239.179h2.381a1 1 0 01.582 1.813z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});