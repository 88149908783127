define("ember-svg-jar/inlined/road-sign-turn-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-turn-1</title><path d=\"M23.53 11.47l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0l11-11a.749.749 0 000-1.06zM15 14.5a.5.5 0 01-.5.5h-1.25a.25.25 0 00-.25.25v1.25a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-6.25a.25.25 0 00-.25-.25H9.5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1.25a.25.25 0 00.25-.25V6.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v6.25a.25.25 0 00.25.25h1.25a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});