define("ember-svg-jar/inlined/road-sign-speed-limit-70", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-speed-limit-70</title><path d=\"M16.274 9.536a1.728 1.728 0 00-1.726 1.726v2.476a1.726 1.726 0 003.452 0v-2.476a1.728 1.728 0 00-1.726-1.726z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm-.634 9.883L8.93 16.474a.753.753 0 01-.7.49.751.751 0 01-.7-1.01l2.241-6.081a.251.251 0 00-.235-.337H6.75a.25.25 0 00-.25.25v.237a.75.75 0 01-1.5 0V9.4a1.37 1.37 0 011.369-1.364h3.714a1.369 1.369 0 011.283 1.847zm8.134 3.855a3.226 3.226 0 11-6.452 0v-2.476a3.226 3.226 0 116.452 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});