define("ember-svg-jar/inlined/moving-walkway-luggage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>moving-walkway-luggage</title><circle cx=\"16.909\" cy=\"2.708\" r=\"2.445\"/><path d=\"M23.022 12.489a.978.978 0 100-1.956h-2.2a.978.978 0 00-.978.978.978.978 0 00.978.978zM8.62 11.626v-.02a.961.961 0 00-.959-1.073H.978a.978.978 0 000 1.956h6.683a.968.968 0 00.959-.863zM9.048 17.38H8.8a.245.245 0 00-.245.244v5.869a.245.245 0 00.245.244h.245a.979.979 0 00.978-.978v-4.4a.979.979 0 00-.975-.979zM1.223 17.38a.978.978 0 00-.978.978v4.4a.978.978 0 00.978.978h.244a.245.245 0 00.245-.244v-5.868a.245.245 0 00-.245-.244zM7.58 16.972a2.445 2.445 0 10-4.89 0v6.521a.244.244 0 00.244.244h4.4a.244.244 0 00.244-.244zm-1.467.163a.245.245 0 01-.244.245H4.4a.245.245 0 01-.244-.245v-.163a.978.978 0 111.956 0z\"/><path d=\"M18.376 8.088a1.468 1.468 0 00-2.33-1.188l-5.38 3.913a1.467 1.467 0 101.726 2.372l2.661-1.935a.245.245 0 01.389.2v10.087a.245.245 0 01-.245.244h-3.215a.978.978 0 000 1.956h11.04a.978.978 0 000-1.956h-4.4a.244.244 0 01-.244-.244z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});