define("ember-svg-jar/inlined/style-three-pin-fish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-fish</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm2 3a1.5 1.5 0 11-1.5 1.5A1.5 1.5 0 0114 3zm-.908 11.689a.5.5 0 00-.315.171 2.994 2.994 0 01-2.336 1.134 3.249 3.249 0 01-1.3-.27A.252.252 0 019 15.5v-1.311a.5.5 0 00-.265-.442 8.064 8.064 0 01-.558-.325.505.505 0 00-.455-.042l-2.129.852a.249.249 0 01-.336-.292l.455-1.818a.5.5 0 000-.243l-.455-1.818a.25.25 0 01.336-.293l2.132.853a.5.5 0 00.452-.041c.144-.091.332-.2.558-.322A.5.5 0 009 9.816V8.5a.251.251 0 01.138-.223 3.076 3.076 0 013.639.864.506.506 0 00.315.17A3.563 3.563 0 0116.25 12a3.564 3.564 0 01-3.158 2.689zM16.5 9.5A1.5 1.5 0 1118 8a1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});