define("ember-svg-jar/inlined/arrow-rectangle-down-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-rectangle-down-1</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-5 8.361V10.7a.5.5 0 01-.129.335l-5 5.547a.5.5 0 01-.742 0l-5-5.547A.5.5 0 016.5 10.7V8.361a.5.5 0 01.882-.323l4.427 5.233a.25.25 0 00.382 0l4.427-5.233a.5.5 0 01.882.323z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});