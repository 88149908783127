define("ember-svg-jar/inlined/car-tool-keys.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-tool-keys</title><path d=\"M20.25 4.487a4.039 4.039 0 00-6.895-2.856L12.1 2.887a.25.25 0 01-.308.035 4 4 0 00-4.921.578l-4.95 4.95a4 4 0 000 5.657l1.415 1.413a4 4 0 005.656 0l4.951-4.949a4 4 0 00.662-4.777.251.251 0 00-.4-.055l-1 1a.75.75 0 01-1.06-1.061l2.618-2.627a2.039 2.039 0 013.487 1.436v6.1a.25.25 0 01-.25.25 2.752 2.752 0 00-2.75 2.75v2a1.746 1.746 0 001.312 1.687.25.25 0 01.188.242v3.512a2.536 2.536 0 00.3 1.179 2.479 2.479 0 002.209 1.342h.041a2.477 2.477 0 002.207-1.417 2.5 2.5 0 00.244-1.076v-3.54a.249.249 0 01.188-.242 1.746 1.746 0 001.312-1.687v-2a2.738 2.738 0 00-1.65-2.507.25.25 0 00-.35.229v1.278a.5.5 0 11-1 0zM6.92 12.639a1.5 1.5 0 11.44-1.061 1.5 1.5 0 01-.44 1.061zm3.58-3.578A1.5 1.5 0 1110.938 8a1.5 1.5 0 01-.438 1.061zm9.252 12a.492.492 0 01-.485.493.48.48 0 01-.458-.29.491.491 0 01-.057-.231v-3.2a.249.249 0 01.25-.25h.5a.249.249 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});