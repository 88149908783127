define("ember-svg-jar/inlined/famous-character", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character</title><path d=\"M19.5 7.5v-1a1.479 1.479 0 00-.919-1.368.478.478 0 01-.325-.381A6.493 6.493 0 005.705 4.9a.24.24 0 01-.163.168A1.5 1.5 0 004.5 6.5v1a1.5 1.5 0 00.88 1.366.236.236 0 01.144.2 6.5 6.5 0 0012.952 0 .236.236 0 01.144-.2A1.5 1.5 0 0019.5 7.5zM16 10.555a2.023 2.023 0 01-1.177 1.83 6.787 6.787 0 01-5.646 0A2.023 2.023 0 018 10.555v-5.73a.85.85 0 01.486-.743l1.345-.7a1.116 1.116 0 011.221.13l.594.515a.558.558 0 00.708 0l.594-.515a1.116 1.116 0 011.221-.13l1.345.7a.85.85 0 01.486.743z\"/><path d=\"M10.749 8.25a.75.75 0 00.336-1.421l-1-.5a.75.75 0 00-.67 1.342l1 .5a.741.741 0 00.334.079zM13.915 6.329l-1 .5a.75.75 0 00.336 1.421.741.741 0 00.334-.079l1-.5a.75.75 0 10-.67-1.342zM13.5 10h-3a.75.75 0 000 1.5h3a.75.75 0 000-1.5zM17.052 16.027A22.972 22.972 0 0012 15.5a22.972 22.972 0 00-5.052.527.25.25 0 00-.193.247v.874a.25.25 0 00.209.247l4.995.833a.282.282 0 00.082 0l5-.833a.25.25 0 00.209-.247v-.874a.25.25 0 00-.198-.247zM4.9 16.614a12.661 12.661 0 00-3.435 1.8 2.419 2.419 0 00-.9 1.475.257.257 0 00.2.292l4.177.835a.25.25 0 00.3-.245v-3.919a.259.259 0 00-.342-.238zM18.755 16.852v3.922a.25.25 0 00.3.245l4.177-.835a.257.257 0 00.2-.292 2.419 2.419 0 00-.9-1.475 12.661 12.661 0 00-3.435-1.8.259.259 0 00-.342.235zM5.044 22.579L.8 21.73a.25.25 0 00-.3.245V23.5a.5.5 0 00.5.5h4.245v-1.176a.25.25 0 00-.201-.245zM23.2 21.73l-4.245.849a.25.25 0 00-.2.245V24H23a.5.5 0 00.5-.5v-1.525a.25.25 0 00-.3-.245zM16.954 18.94l-4.5.75a5.482 5.482 0 01-.906 0l-4.5-.75a.25.25 0 00-.291.247v4.563a.25.25 0 00.25.25h2.262a.246.246 0 00.242-.232 2.5 2.5 0 014.978 0 .246.246 0 00.242.232H17a.25.25 0 00.25-.25v-4.563a.25.25 0 00-.296-.247z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});