define("ember-svg-jar/inlined/footwear-sneakers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>footwear-sneakers</title><path d=\"M23.7 6.675a.251.251 0 00.3-.246V4.5a1 1 0 00-1-1h-2.3a.25.25 0 00-.237.328A4.265 4.265 0 0023.7 6.675zM18.917 3.88a.5.5 0 00-.485-.38H.5A.5.5 0 000 4v1.294a1.5 1.5 0 001 1.413l5.4 1.93a.5.5 0 00.52-.117l1.55-1.55a.75.75 0 011.06 1.06l-.992.992a.251.251 0 00.093.413l1.082.387a.5.5 0 00.522-.118L11.97 7.97a.75.75 0 011.06 1.06l-1.176 1.177a.249.249 0 00.092.412l1.844.659a1.089 1.089 0 001.272-.466A3.5 3.5 0 0118 9.5a3.941 3.941 0 012.985 1.443.981.981 0 00.889.557h.626A1.5 1.5 0 0024 10V8.669a.5.5 0 00-.42-.493 5.767 5.767 0 01-4.663-4.296zM24 1.5A1.5 1.5 0 0022.5 0h-21A1.5 1.5 0 000 1.5a.5.5 0 00.5.5h23a.5.5 0 00.5-.5zM.3 17.325a.251.251 0 00-.3.246V19.5a1 1 0 001 1h2.3a.25.25 0 00.237-.328A4.265 4.265 0 00.3 17.325zM5.083 20.12a.5.5 0 00.485.38H23.5a.5.5 0 00.5-.5v-1.294a1.5 1.5 0 00-1-1.413l-5.4-1.93a.5.5 0 00-.522.117l-1.55 1.55a.75.75 0 01-1.06-1.06l.992-.992a.251.251 0 00-.093-.413l-1.082-.387a.5.5 0 00-.522.118L12.03 16.03a.75.75 0 01-1.06-1.06l1.176-1.177a.249.249 0 00-.092-.412l-1.844-.659a1.089 1.089 0 00-1.272.466A3.5 3.5 0 016 14.5a3.941 3.941 0 01-2.985-1.443.981.981 0 00-.889-.557H1.5A1.5 1.5 0 000 14v1.331a.5.5 0 00.42.493 5.767 5.767 0 014.663 4.296zM0 22.5A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5.5.5 0 00-.5-.5H.5a.5.5 0 00-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});