define("ember-svg-jar/inlined/smiley-nose-blow-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-nose-blow-1-alternate</title><path d=\"M9.478 13.4a2.13 2.13 0 00-3.1.08c-.4.474-1.824 2.027-3.29 2.074a3.109 3.109 0 00-3.07 2.735 3.031 3.031 0 00.77 2.311c.037.039.074.078.112.115a.253.253 0 01.058.27A2.206 2.206 0 003.07 24h.035c4.418-.067 6.47-4.913 6.885-9.042a2.031 2.031 0 00-.512-1.558zM3.07 22a.269.269 0 01-.258-.146.217.217 0 01.04-.192.206.206 0 01.163-.079 1 1 0 000-2 1.014 1.014 0 01-1.01-1.113 1.135 1.135 0 011.142-.918c2.148-.07 3.9-1.773 4.754-2.783a.1.1 0 01.028-.023.058.058 0 01.071.012c-.333 3.311-1.822 7.2-4.925 7.242z\"/><path d=\"M12 0A12.013 12.013 0 000 12a1 1 0 002 0 10 10 0 1110 10 10.13 10.13 0 01-1.819-.165 1 1 0 00-.362 1.965A12 12 0 1012 0z\"/><path d=\"M6.014 10.164A1 1 0 007 11a.927.927 0 00.165-.014l3-.5a1 1 0 00.543-1.693l-3-3a1 1 0 00-1.415 1.414L7.575 8.49a.249.249 0 01-.135.423l-.6.1a1 1 0 00-.826 1.151zM17.707 5.793a1 1 0 00-1.414 0l-3 3a1 1 0 00.565 1.7l3.5.5a1 1 0 10.284-1.98l-1.026-.146a.25.25 0 01-.141-.425l1.232-1.232a1 1 0 000-1.417zM12.068 16a.252.252 0 01-.183.116A1 1 0 0012 18.113a1.985 1.985 0 001.7-.94l.737-1.179a.25.25 0 01.4-.028l1.4 1.675a1 1 0 001.538-1.282l-1.617-1.938a2 2 0 00-3.232.22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});