define("ember-svg-jar/inlined/style-three-pin-hospital-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-hospital-1</title><path d=\"M12.5 0C6.71 0 2 4.262 2 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.7 21.784 23 15.246 23 9.5 23 4.262 18.29 0 12.5 0zM17 14.5a1 1 0 01-1 1h-1a1 1 0 01-1-1V12a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v2.5a1 1 0 01-1 1H9a1 1 0 01-1-1v-9a1 1 0 011-1h1a1 1 0 011 1V8a.5.5 0 00.5.5h2A.5.5 0 0014 8V5.5a1 1 0 011-1h1a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});