define("ember-svg-jar/inlined/clean-car-cable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>clean-car-cable</title><path d=\"M22.038 7.665a1 1 0 00-1.784.905 9.147 9.147 0 011 4.18 9.25 9.25 0 11-9.869-9.229.5.5 0 01.465.245A2.5 2.5 0 0014 5h1.5a1 1 0 00.745-.333.5.5 0 01.372-.167H18A.75.75 0 0018 3h-1a.5.5 0 010-1h1a.75.75 0 000-1.5h-1.383a.5.5 0 01-.372-.167A1 1 0 0015.5 0H14a2.5 2.5 0 00-2.172 1.264.5.5 0 01-.409.251A11.25 11.25 0 1023.25 12.75a11.138 11.138 0 00-1.212-5.085z\"/><path d=\"M15.125 18.25a1 1 0 001-1v-.586a.5.5 0 01.25-.433 2 2 0 00-.018-3.473.5.5 0 01-.252-.4c-.193-3.011-1.746-4.105-3.2-4.105H11c-1.458 0-3.144 1.09-3.353 4.107a.5.5 0 01-.253.4 2 2 0 00-.019 3.473.5.5 0 01.25.433v.586a1 1 0 002 0V17a.5.5 0 01.5-.5h3.5a.5.5 0 01.5.5v.25a1 1 0 001 1zM14.06 12.5H9.7a.5.5 0 01-.494-.578c.246-1.58 1.044-2.172 1.8-2.172h1.9c.875 0 1.464.8 1.656 2.181a.5.5 0 01-.5.569z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});