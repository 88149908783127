define("ember-svg-jar/inlined/single-woman-actions-flight.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-flight</title><path d=\"M10.762 15.407a7.408 7.408 0 011.273-1.016 2.158 2.158 0 01.43-.248.5.5 0 00.125-.84A7.464 7.464 0 00.565 16.857a.5.5 0 00.479.643h8.8a.367.367 0 00.269-.117.363.363 0 00.1-.277 2.324 2.324 0 01.549-1.699zM22 15.555a1.8 1.8 0 00-1.41.269l-2 1.365a.25.25 0 01-.228.028l-4.651-1.731a.826.826 0 00-.784.111l-.892.661a1.311 1.311 0 00-.133.125.824.824 0 00-.2.6.856.856 0 00.35.611l2.7 1.671a.251.251 0 01.017.414l-.957.709a.25.25 0 01-.276.014l-1.172-.69a.774.774 0 00-.745.132l-.87.73a1.271 1.271 0 00-.128.126.774.774 0 00.143 1.1l2.878 2.02a.983.983 0 001.084.031l7.855-4.877A1.882 1.882 0 0022 15.555z\"/><path d=\"M1.57 10.442a1 1 0 001.382-.3 5.166 5.166 0 00.455-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .249.249 0 01.411.082 5.233 5.233 0 00.454.9 1 1 0 001.681-1.082 4.561 4.561 0 01-.643-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248a4.573 4.573 0 01-.642 2.512 1 1 0 00.298 1.382zM7.211 8.6A2.706 2.706 0 014.47 6.248a.5.5 0 01.324-.577A5.708 5.708 0 007.04 4.232a.249.249 0 01.36 0 5.719 5.719 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.211 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});