define("ember-svg-jar/inlined/soft-drinks-bottle-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>soft-drinks-bottle-heart</title><path d=\"M8.5 3.5h7A.5.5 0 0016 3V1.5A1.5 1.5 0 0014.5 0h-5A1.5 1.5 0 008 1.5V3a.5.5 0 00.5.5zM14.819 8.615a.5.5 0 01-.319-.466V5.5A.5.5 0 0014 5h-4a.5.5 0 00-.5.5v2.649a.5.5 0 01-.319.466C6.429 9.682 5.5 12.109 5.5 15v7a2 2 0 002 2h9a2 2 0 002-2v-7c0-2.891-.929-5.318-3.681-6.385zm-2.511 10.8a.5.5 0 01-.615 0c-.854-.666-3.193-2.629-3.193-4.155a1.709 1.709 0 013.276-.848.249.249 0 00.448 0 1.709 1.709 0 013.276.848c0 1.529-2.338 3.492-3.192 4.157z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});