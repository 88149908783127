define("ember-svg-jar/inlined/smiley-yawn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-yawn</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM5.793 7.879a8.638 8.638 0 012.821-1.888 1 1 0 11.766 1.847 6.683 6.683 0 00-2.173 1.455 1 1 0 01-1.414-1.414zM9.5 16a2.5 2.5 0 015 0v1.5a2.5 2.5 0 01-5 0zm8.707-6.707a1 1 0 01-1.414 0 6.683 6.683 0 00-2.173-1.455 1 1 0 11.766-1.847 8.638 8.638 0 012.821 1.888 1 1 0 010 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});