define("ember-svg-jar/inlined/toilet-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toilet-sign</title><path d=\"M5.581 9.5a4.75 4.75 0 10-4.75-4.75 4.756 4.756 0 004.75 4.75zm2.068-4.569a.5.5 0 01.551.645 2.748 2.748 0 01-5.369-.826c0-.071 0-.14.009-.209a.5.5 0 01.75-.39A6.241 6.241 0 006.748 5a5.993 5.993 0 00.901-.069zM5.581 10.238a5 5 0 00-5 5v3a.5.5 0 00.5.5h1.546a.5.5 0 01.5.452l.418 4.352a.5.5 0 00.5.458h3.08a.5.5 0 00.5-.458l.419-4.352a.5.5 0 01.5-.452h1.546a.5.5 0 00.5-.5v-3a5 5 0 00-5.009-5zM18.081 9.5a4.75 4.75 0 10-4.75-4.75 4.756 4.756 0 004.75 4.75zm2.75-4.75a2.75 2.75 0 01-5.5 0c0-.021.006-.041.006-.062a4.606 4.606 0 002.41-1.1.5.5 0 01.669 0 4.6 4.6 0 002.409 1.1c0 .021.006.041.006.062zM23.4 18.6l-1.555-5.46a3.891 3.891 0 00-7.527 0l-1.555 5.46a.5.5 0 00.48.636h1.928a.5.5 0 01.491.4L16.5 23.6a.5.5 0 00.49.4h2.18a.5.5 0 00.49-.4l.84-3.958a.5.5 0 01.49-.4h1.928a.5.5 0 00.482-.642z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});