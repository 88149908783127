define("ember-svg-jar/inlined/wine-barrel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wine-barrel</title><path d=\"M7.043 18.629a27.549 27.549 0 01-2.471-.321.5.5 0 00-.579.394l-.363 1.834a1.021 1.021 0 00.21.84.992.992 0 00.77.359H6.5a1 1 0 001-1v-1.608a.5.5 0 00-.457-.498zM14.428 18.308c-.824.148-1.664.251-2.471.321a.5.5 0 00-.457.5v1.608a1 1 0 001 1h1.89a.968.968 0 00.77-.37.988.988 0 00.21-.829l-.363-1.838a.5.5 0 00-.579-.392zM18.6 8.047a49.437 49.437 0 00-7.841-.55.5.5 0 00-.506.5v3.477a.5.5 0 00.506.5 49.437 49.437 0 007.841-.55.5.5 0 00.4-.49v-2.4a.5.5 0 00-.4-.487zM10.771 2.265a.5.5 0 00-.521.5v2.727a.5.5 0 00.495.5 48.934 48.934 0 017.665.5A.5.5 0 0019 6v-.76c0-1.522-3.59-2.778-8.229-2.975zM8.244 7.5A49.437 49.437 0 00.4 8.047a.5.5 0 00-.4.49v2.4a.5.5 0 00.4.49 49.437 49.437 0 007.841.55.5.5 0 00.506-.5V8a.5.5 0 00-.506-.5zM18.41 12.984a48.934 48.934 0 01-7.665.495.5.5 0 00-.495.5v2.727a.5.5 0 00.521.5C15.41 17.009 19 15.753 19 14.235v-.759a.5.5 0 00-.59-.492zM8.228 2.265C3.59 2.462 0 3.718 0 5.235V6a.5.5 0 00.589.492 48.961 48.961 0 017.666-.5.5.5 0 00.495-.5V2.765a.5.5 0 00-.522-.5zM8.255 13.479a48.961 48.961 0 01-7.666-.495.5.5 0 00-.589.492v.759c0 1.518 3.59 2.774 8.228 2.971a.5.5 0 00.522-.5v-2.727a.5.5 0 00-.495-.5zM22.222 10.748A.249.249 0 0122 10.5v-.264a1 1 0 00-2 0v2a.5.5 0 00.5.5h1.25a.25.25 0 01.25.25v.75a1 1 0 002 0v-1a2 2 0 00-1.778-1.988z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});