define("ember-svg-jar/inlined/invitation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>invitation</title><path d=\"M20.25 4a.75.75 0 00-.75-.75h-6a.75.75 0 000 1.5h6a.749.749 0 00.75-.75zM19.5 6.25h-3a.75.75 0 000 1.5h3a.75.75 0 000-1.5zM7.75 3A3.25 3.25 0 1011 6.25 3.254 3.254 0 007.75 3zm0 5A1.75 1.75 0 119.5 6.25 1.752 1.752 0 017.75 8z\"/><path d=\"M13.25 24h6.5a.25.25 0 00.25-.25v-4.227a.228.228 0 010-.045l.782-4.3A.219.219 0 0121 15a2.02 2.02 0 002-2V2a2 2 0 00-2-2H3a2 2 0 00-2 2v11a2 2 0 002 2h3.793a.249.249 0 01.25.258A89.964 89.964 0 007 17.5c0 1.118 1.368 1.317 2 1a4.142 4.142 0 001.546-1.827.248.248 0 00.017-.048l.359-1.436a.25.25 0 01.243-.189h.515a.252.252 0 01.243.311L11.5 17a5.731 5.731 0 000 3 9.045 9.045 0 001.431 1.929.249.249 0 01.069.171v1.65a.25.25 0 00.25.25zM12 10.542v2.208a.249.249 0 01-.25.25H3.5a.5.5 0 01-.5-.5v-10a.5.5 0 01.5-.5h17a.5.5 0 01.5.5v10a.5.5 0 01-.5.5h-4.25a.249.249 0 01-.25-.25v-2.208C16 10.042 16 8 14 8s-2 2.042-2 2.542z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});