define("ember-svg-jar/inlined/shopping-cart-man-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-cart-man-1</title><path d=\"M13.6 8.5a1 1 0 00-1-1h-1a2.236 2.236 0 00-2.184 1.822l-.235 1.468a.25.25 0 01-.247.21H1.372a1.371 1.371 0 00-1.314 1.766l1.319 4.4A1.166 1.166 0 002.5 18h5.234a.251.251 0 01.247.29l-.164 1.024a.22.22 0 01-.217.186h-5a1 1 0 000 2h5a2.236 2.236 0 002.183-1.822l1.6-9.992A.22.22 0 0111.6 9.5h1a1 1 0 001-1z\"/><circle cx=\"2.6\" cy=\"23\" r=\"1\"/><circle cx=\"8.1\" cy=\"23\" r=\"1\"/><path d=\"M14.5 4a4 4 0 007.981.392.251.251 0 00-.318-.265 8.676 8.676 0 01-2.382.36 6.179 6.179 0 01-4.6-2.02.25.25 0 00-.413.083A3.972 3.972 0 0014.5 4z\"/><path d=\"M22.342 3.026a.041.041 0 00.026-.048 4 4 0 00-6.693-1.807.249.249 0 00-.01.342c1.74 1.967 3.871 2.447 6.677 1.513zM18.5 8.5A5.506 5.506 0 0013 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L16 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 0121.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});