define("ember-svg-jar/inlined/lucide-cloud-moon-rain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.083 9A6.002 6.002 0 0116 4a4.243 4.243 0 006 6c0 2.22-1.206 4.16-3 5.197M3 20a5 5 0 118.9-4H13a3 3 0 012 5.24M11 20v2M7 19v2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});