define("ember-svg-jar/inlined/transportation-ticket-plane-transfer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transportation-ticket-plane-transfer</title><path d=\"M3.784 8.552A.976.976 0 004.3 8.4l7.84-4.864a1.861 1.861 0 00.868-1.6 1.784 1.784 0 00-.1-.6A1.864 1.864 0 0011.557.123a1.786 1.786 0 00-1.407.268l-2 1.363a.25.25 0 01-.228.027L3.285.053A.821.821 0 002.5.164l-.89.66a1.548 1.548 0 00-.13.124.842.842 0 00.153 1.215L4.3 3.812a.249.249 0 01.011.416l-.589.411a.248.248 0 01-.269.01l-1.181-.7a.778.778 0 00-.755.139L.321 5.145a1.175 1.175 0 00-.121.117.761.761 0 00-.164.562.774.774 0 00.307.532l2.87 2.015a.975.975 0 00.571.181zM23.86 16.779a1.868 1.868 0 00-1.347-1.208 1.793 1.793 0 00-1.408.269l-1.996 1.36a.25.25 0 01-.228.027L14.237 15.5a.818.818 0 00-.779.113l-.89.659a1.561 1.561 0 00-.133.124.843.843 0 00.154 1.215l2.663 1.649a.248.248 0 01.012.416l-.589.411a.248.248 0 01-.269.01l-1.182-.7a.773.773 0 00-.754.14l-1.179 1.041a1.133 1.133 0 00-.131.128.771.771 0 00.14 1.094l2.871 2.016a.979.979 0 001.083.032l7.84-4.868a1.866 1.866 0 00.868-1.6 1.8 1.8 0 00-.102-.601zM8.827 7.735a1 1 0 001 1 4.967 4.967 0 014.3 2.457.249.249 0 01.022.2c-.022.068-.584.123-.649.152a.739.739 0 00-.388.395.747.747 0 00.162.815l2.009 1.5a.746.746 0 001.058 0l1.5-1.5a.748.748 0 00-.529-1.277h-.7a.251.251 0 01-.235-.164 6.949 6.949 0 00-6.553-4.576 1 1 0 00-.997.998zM9.824 18.713a4.971 4.971 0 01-4.606-3.071.128.128 0 01.012-.117.123.123 0 01.1-.055.749.749 0 00.529-1.278l-1.5-1.5a.75.75 0 00-1.059 0l-1.5 1.5a.748.748 0 00.54 1.278h.529a.249.249 0 01.24.181 6.96 6.96 0 006.715 5.058 1 1 0 100-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});