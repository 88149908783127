define("ember-svg-jar/inlined/bird-cage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bird-cage</title><path d=\"M19.32 8.3a.5.5 0 00-.458-.3H5.252a.5.5 0 00-.457.3C.721 17.415-.366 19.454 1.852 22.126a4.489 4.489 0 003.462 1.626h13.361a4.492 4.492 0 003.463-1.626c2.138-2.577 1.302-4.419-2.818-13.826zM7.616 18.4a3.816 3.816 0 01.9 2.851H7.245a.5.5 0 01-.5-.529 4.969 4.969 0 01.871-2.322zm8.2 0a4.961 4.961 0 01.871 2.323.5.5 0 01-.5.529H14.92a3.813 3.813 0 01.898-2.852zm-3.13-8.034a3.953 3.953 0 012.89 2.819 4.06 4.06 0 01-.109 2.451 3.09 3.09 0 00-.182 1.257 4.984 4.984 0 00-1.868 4.359h-3.406a5.1 5.1 0 00-1.858-4.38 3.337 3.337 0 00-.2-1.256 3.953 3.953 0 01-.241-1.364 4.007 4.007 0 014.976-3.886zM18.778 6.5a.5.5 0 00.469-.327L21.1 1.191a.652.652 0 00-.152-.821.638.638 0 00-.81.046l-4.375 2.823L12.77.6a.966.966 0 00-1.381-.037L8.645 3.236 3.994.418A.648.648 0 003.181.37a.646.646 0 00-.152.811l1.853 4.994a.5.5 0 00.469.327z\"/><path d=\"M10.207 16.683l1.156 1.156a.5.5 0 00.707 0l1.156-1.156a.5.5 0 00-.13-.8L11.94 15.3a.5.5 0 00-.447 0l-1.156.578a.5.5 0 00-.13.805z\"/><circle cx=\"9.716\" cy=\"13.752\" r=\".75\"/><circle cx=\"13.716\" cy=\"13.752\" r=\".75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});