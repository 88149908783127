define("ember-svg-jar/inlined/products-shopping-bags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>products-shopping-bags</title><path d=\"M20 16.75a.25.25 0 00-.25.25v3.75A.25.25 0 0020 21h2.5a1.5 1.5 0 001.5-1.5V17a.25.25 0 00-.25-.25zM18 15.25a.25.25 0 00.25-.25v-3.25a.25.25 0 00-.25-.25h-2.5A1.5 1.5 0 0014 13v2a.25.25 0 00.25.25zM19.75 15a.25.25 0 00.25.25h3.75A.25.25 0 0024 15v-2a1.5 1.5 0 00-1.5-1.5H20a.25.25 0 00-.25.25zM18.25 17a.25.25 0 00-.25-.25h-3.75A.25.25 0 0014 17v2.5a1.5 1.5 0 001.5 1.5H18a.25.25 0 00.25-.25zM12 9.964v-.028A1.445 1.445 0 0010.577 8.5h-.327a.25.25 0 01-.25-.25V6.5a3.5 3.5 0 00-7 0v1.75a.25.25 0 01-.25.25h-.327A1.43 1.43 0 001 9.912l-1 9.624A1.446 1.446 0 001.423 21h10.154A1.456 1.456 0 0013 19.483zM5 6.5a1.5 1.5 0 013 0v1.75a.25.25 0 01-.25.25h-2.5A.25.25 0 015 8.25zM19 11a1 1 0 00.707-.293l2-2a1 1 0 10-1.414-1.414l-1.116 1.116a.25.25 0 01-.354 0l-1.116-1.116a1 1 0 10-1.414 1.414l2 2A1 1 0 0019 11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});