define("ember-svg-jar/inlined/force-touch-press", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>force-touch-press</title><path d=\"M5.293 11.957a1 1 0 101.414-1.414l-1-1a1 1 0 00-1.414 1.414zM4 15h1.5a1 1 0 000-2H4a1 1 0 000 2zM22.5 16h-3a.25.25 0 00-.25.25v3.5a.25.25 0 00.25.25h2a2.5 2.5 0 002.5-2.5 1.5 1.5 0 00-1.5-1.5zM0 17.5A2.5 2.5 0 002.5 20H8v1.5H7a.5.5 0 00-.3.9l2 1.5a.5.5 0 00.6 0l2-1.5a.5.5 0 00-.3-.9h-1v-2.75a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v1a.25.25 0 00.25.25h6.25a.25.25 0 00.25-.25v-3.5a.25.25 0 00-.25-.25h-16A1.5 1.5 0 000 17.5zM15.5 15a1 1 0 000-2H14a1 1 0 000 2zM21.4 5.472a15.328 15.328 0 002.322-4.5A.75.75 0 0023 0h-7.771a.744.744 0 00-.437.141 11.465 11.465 0 00-3.382 4.253L8.254 10.84a1.743 1.743 0 00.378 2.324 1.765 1.765 0 002.493-.343L15 6.976a.671.671 0 011.2.558c-.416 1.438-.4 1.376-.412 1.449a1.894 1.894 0 003.612 1.031c1.189-3.421.967-3.06 2-4.542z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});