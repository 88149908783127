define("ember-svg-jar/inlined/school-locker-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>school-locker-2</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-2.34 10a1.2 1.2 0 11-1.2 1.2 1.2 1.2 0 011.2-1.2zM7.8 10a1.2 1.2 0 11-1.2 1.2A1.2 1.2 0 017.8 10zm.59 10.746H3.141a.75.75 0 010-1.5h5.25a.75.75 0 110 1.5zm0-3H3.141a.75.75 0 110-1.5h5.25a.75.75 0 010 1.5zM12.75 22a.75.75 0 01-1.5 0V2a.75.75 0 011.5 0zm8-1.254H15.5a.75.75 0 110-1.5h5.25a.75.75 0 010 1.5zm0-3H15.5a.75.75 0 010-1.5h5.25a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});