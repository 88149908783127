define("ember-svg-jar/inlined/color-rolling-brush", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-rolling-brush</title><path d=\"M23 2.5h-1.25a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25H23a1 1 0 000-2zM3 2.5a3 3 0 00-3 3v2a3 3 0 003 3h8a1 1 0 011 1v.325a.25.25 0 00.3.245 3.535 3.535 0 011.4 0 .25.25 0 00.3-.245V11.5a3 3 0 00-3-3H3a1 1 0 01-1-1v-2a1 1 0 011-1h.25a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25z\"/><rect x=\"4.5\" width=\"16\" height=\"7\" rx=\"1.5\" ry=\"1.5\"/><path d=\"M10.5 15.5v7A1.5 1.5 0 0012 24h2a1.5 1.5 0 001.5-1.5v-7a2.5 2.5 0 00-5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});