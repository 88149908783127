define("ember-svg-jar/inlined/face-id-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>face-id-4</title><path d=\"M6.5 22H2.249A.25.25 0 012 21.75V17.5a1 1 0 00-2 0V22a2 2 0 002 2h4.5a1 1 0 000-2zM1 7.5a1 1 0 001-1V2.25A.25.25 0 012.249 2H6.5a1 1 0 000-2H2a2 2 0 00-2 2v4.5a1 1 0 001 1zM23 16.5a1 1 0 00-1 1v4.25a.25.25 0 01-.25.25H17.5a1 1 0 100 2H22a2 2 0 002-2v-4.5a1 1 0 00-1-1zM22 0h-4.5a1 1 0 000 2h4.25a.25.25 0 01.25.25V6.5a1 1 0 002 0V2a2 2 0 00-2-2zM17.7 17.607a6.126 6.126 0 00-3.83-3.676.251.251 0 01-.028-.465 4.254 4.254 0 10-3.618-.025.251.251 0 01-.034.465 6.122 6.122 0 00-3.891 3.7A.75.75 0 007 18.625h10a.751.751 0 00.7-1.018zM9.806 9.625a2.14 2.14 0 01.126-.72.249.249 0 01.365-.131 5.652 5.652 0 002.945.851 5.368 5.368 0 00.764-.056.25.25 0 01.207.064.255.255 0 01.079.2 2.248 2.248 0 01-4.486-.21z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});