define("ember-svg-jar/inlined/peacock-feathers-up-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>peacock-feathers-up-1</title><path d=\"M24 12.06a12.16 12.16 0 00-12-12 12.16 12.16 0 00-12 12c0 1.808 3.035 3.895 6.013 5.509 0 .122-.013.239-.013.371a6 6 0 0012 0c0-.137-.008-.258-.014-.385 6-3.309 6.014-4.894 6.014-5.495zM20.584 7.1l-4.577 4c0-.057-.006-.106-.007-.166a3.973 3.973 0 00-1.363-2.983l2.094-4.62A10.64 10.64 0 0120.584 7.1zM13.75 9.94a1 1 0 11-1 1 1 1 0 011-1zm-3.5 0a1 1 0 11-1 1 1 1 0 011-1zM12 12.386l1.5 1.006-1.146 1.146a.5.5 0 01-.708 0L10.5 13.392zm3.372-9.68L13.34 7.188a3.744 3.744 0 00-2.68 0L8.628 2.706a9.124 9.124 0 016.744 0zm-8.1.632l2.09 4.618A3.968 3.968 0 008 10.934c0 .062-.006.112-.007.172l-4.575-4a10.629 10.629 0 013.851-3.768zM2 12.06a9.442 9.442 0 01.722-3.572l5.04 4.41a14.715 14.715 0 01-1.313 2.613C3.841 14.023 2.092 12.605 2 12.06zm15.55 3.45a18.541 18.541 0 01-1.312-2.61l5.042-4.414a9.373 9.373 0 01.72 3.557c-.049.302-.968 1.484-4.45 3.467z\"/><circle cx=\"12\" cy=\"3.94\" r=\"1\"/><circle cx=\"17.5\" cy=\"6.44\" r=\"1\"/><circle cx=\"20\" cy=\"11.94\" r=\"1\"/><circle cx=\"6.5\" cy=\"6.44\" r=\"1\"/><circle cx=\"4\" cy=\"11.94\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});