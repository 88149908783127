define("ember-svg-jar/inlined/paper-write", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paper-write</title><path d=\"M19 18.587a1 1 0 00-1 1v2.163a.25.25 0 01-.249.25l-15.5.024a.25.25 0 01-.251-.25V4.749a.25.25 0 01.25-.25H3.5a1 1 0 100-2H2a1.99 1.99 0 00-2 1.975v17.551A1.989 1.989 0 002 24h16a1.989 1.989 0 002-1.974v-2.439a1 1 0 00-1-1zM16.5 4.5h1.25a.25.25 0 01.25.25v.75a1 1 0 002 0v-1a2 2 0 00-2-2h-1.5a1 1 0 000 2z\"/><path d=\"M7 5.5h6A1.5 1.5 0 0014.5 4v-.5A1.5 1.5 0 0013 2h-1a2 2 0 00-4 0H7a1.5 1.5 0 00-1.5 1.5V4A1.5 1.5 0 007 5.5zM4 10a1 1 0 001 1h8a1 1 0 000-2H5a1 1 0 00-1 1zM5 14a1 1 0 000 2h4.5a1 1 0 000-2zM23.415 8.241a2.021 2.021 0 00-2.828 0l-7.869 7.87a.493.493 0 00-.111.168l-1.414 3.535a.5.5 0 00.65.649l3.535-1.414a.48.48 0 00.168-.11l7.869-7.869a2 2 0 000-2.829z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});