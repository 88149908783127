define("ember-svg-jar/inlined/athletics-pole-vault", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>athletics-pole-vault</title><circle cx=\"2.5\" cy=\"5.501\" r=\"2.5\"/><path d=\"M9.106 6.935a1.507 1.507 0 01-.672.672l-5.1 2.552a1.5 1.5 0 00-.563 2.2l4.065 5.871a.5.5 0 01.085.349l-.4 3.231a1.5 1.5 0 102.975.382l.405-3.24a3.516 3.516 0 00-.619-2.464l.483-.233a1.5 1.5 0 012.045.794l.8 2.014a1.5 1.5 0 002.785-1.115l-.8-2.013a4.5 4.5 0 00-6.144-2.385l-.713.344a.249.249 0 01-.314-.082l-1.042-1.5a.25.25 0 01.093-.366l3.3-1.652a4.5 4.5 0 002.011-2.011l.827-1.655a.25.25 0 00-.082-.318 22.417 22.417 0 00-2.17-1.314.25.25 0 00-.193-.018.255.255 0 00-.148.127z\"/><path d=\"M6.948.049a1 1 0 10-.609 1.9C13.483 4.237 22 9.741 22 23a1 1 0 102 0C24 7.772 13.316 2.083 6.948.049z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});