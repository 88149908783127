define("ember-svg-jar/inlined/performance-graph-calculator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>performance-graph-calculator</title><path d=\"M23.645 11.505a1.867 1.867 0 00-1.487-1.486h-8.919a1.867 1.867 0 00-1.487 1.486v10.9a1.869 1.869 0 001.487 1.487h8.919a1.869 1.869 0 001.487-1.487zm-9.415 1.486a.464.464 0 01.495-.495h5.946a.464.464 0 01.5.495v1.983a.465.465 0 01-.5.495h-5.946a.478.478 0 01-.494-.513zm.746 8.435a.744.744 0 11.743-.743.744.744 0 01-.743.743zm0-2.488a.744.744 0 11.743-.744.745.745 0 01-.743.744zm2.808 2.488a.744.744 0 11.743-.743.744.744 0 01-.743.743zm0-2.488a.744.744 0 11.743-.744.745.745 0 01-.743.744zm2.89 2.488a.744.744 0 11.743-.743.743.743 0 01-.743.743zm0-2.488a.744.744 0 11.743-.744.744.744 0 01-.743.744zM14.159 5.22a.251.251 0 01-.354 0l-.9-.9a1.984 1.984 0 00-2.8 0L.646 13.777a.991.991 0 001.4 1.4L11.328 5.9a.25.25 0 01.353 0l.9.9a1.985 1.985 0 002.8 0l3.232-3.233a.251.251 0 01.354 0l.857.858a.492.492 0 00.35.145.485.485 0 00.19-.038.5.5 0 00.3-.458V.6a.5.5 0 00-.5-.5h-3.457a.5.5 0 00-.35.846l.857.857a.25.25 0 010 .353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});