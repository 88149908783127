define("ember-svg-jar/inlined/insect-scorpion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>insect-scorpion</title><path d=\"M21.427.3a.5.5 0 00-.936.045L20.2 2.237a.5.5 0 01-.8.231l-1.253-1.44a.5.5 0 00-.816.462c.2 1.248.673 3.061 1.749 3.762a3.721 3.721 0 01-1.468 1.342.5.5 0 00-.274.446v1.1a.5.5 0 00.683.466 5.726 5.726 0 003.233-3.234.991.991 0 00.056-.2c1.482-.965.741-3.408.117-4.872zM7.419 5.252c1.076-.7 1.548-2.514 1.749-3.762a.5.5 0 00-.816-.462L7.1 2.468a.5.5 0 01-.8-.231L6.01.349A.5.5 0 005.073.3c-.624 1.46-1.364 3.9.117 4.869a1.051 1.051 0 00.055.2 5.721 5.721 0 003.413 3.3.5.5 0 00.667-.469V7.12a.5.5 0 00-.293-.456 3.71 3.71 0 01-1.613-1.412z\"/><path d=\"M15.839 9.52V6a1.5 1.5 0 00-1.5-1.5h-2.014a1.5 1.5 0 00-1.5 1.5v3.509a2.144 2.144 0 01-2.69.141l-.686-.55A1 1 0 106.2 10.665a4.439 4.439 0 004.624 1.155v1.468c0 .174-.42.3-.549.352-1.116.406-1.793-.415-2.3-.851A1 1 0 006.671 14.3a4.068 4.068 0 004.156 1.265 2.513 2.513 0 001.5 2.222 6.847 6.847 0 01-.163 1.538A3.272 3.272 0 018.575 22c-2.488 0-3.75-1.43-3.75-4.25a1.02 1.02 0 00-.015-.176 2 2 0 001.015-1.741A3.43 3.43 0 003.7 12.708a.5.5 0 00-.678.569c.288 1.319-1.192 1.113-1.192 2.556a2 2 0 001.016 1.741 1.019 1.019 0 00-.016.176c0 3.913 2.15 6.25 5.75 6.25a5.272 5.272 0 005.606-4.591 9.041 9.041 0 00.144-1.615 2.51 2.51 0 001.512-2.224c-.006.262 1.612.142 1.776.111A5.025 5.025 0 0019.98 14.3a1 1 0 00-1.308-1.513 3.144 3.144 0 01-1.333.907 1.722 1.722 0 01-1.5-.372c-.032-.064 0-1.066 0-1.494.072.091 1.889 1.013 4.612-1.164A1 1 0 1019.2 9.1c-.694.561-1.956 1.473-3.361.42z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});