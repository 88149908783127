define("ember-svg-jar/inlined/volume-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>volume-up</title><path d=\"M11.5 5.251a7.5 7.5 0 107.5 7.5 7.5 7.5 0 00-7.5-7.5zm1.25 5.25A1.5 1.5 0 1114.25 9a1.5 1.5 0 01-1.5 1.5z\"/><path d=\"M23 13.751a1 1 0 001-1 .984.984 0 00-.859-.986.25.25 0 01-.211-.221 11.5 11.5 0 10-19.682 9.193.249.249 0 01.02.323l-.068.091a1 1 0 101.6 1.2l1.5-2a1 1 0 00-1.6-1.2.129.129 0 01-.2.01 9.5 9.5 0 1116.419-7.64.251.251 0 01-.183.274A1 1 0 0021 13.751z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});