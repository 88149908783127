define("ember-svg-jar/inlined/app-window-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-disable</title><path d=\"M18.957 10.676a6.763 6.763 0 00-8.132 8.124 6.666 6.666 0 005.017 5.023 6.988 6.988 0 001.547.177 6.754 6.754 0 006.587-8.3 6.672 6.672 0 00-5.019-5.024zm-5.323 8.929a.255.255 0 01-.211.071.248.248 0 01-.184-.126 4.38 4.38 0 01-.463-1.184 4.753 4.753 0 014.64-5.861 4.988 4.988 0 011.1.123 4.365 4.365 0 011.182.462.248.248 0 01.126.184.255.255 0 01-.071.211l-.313.312-5.495 5.5zm7.125 1.006a4.792 4.792 0 01-4.477 1.264 4.415 4.415 0 01-1.18-.461.254.254 0 01-.125-.184.249.249 0 01.071-.211l.312-.313 5.5-5.495.313-.312a.247.247 0 01.211-.071.251.251 0 01.184.125 4.391 4.391 0 01.462 1.181 4.8 4.8 0 01-1.271 4.477z\"/><path d=\"M20.851 0h-18a3 3 0 00-3 3v14.5a3 3 0 003 3h5a1 1 0 001-1 1 1 0 00-1-1h-5a1 1 0 01-1-1V5.253A.25.25 0 012.1 5h19.5a.25.25 0 01.25.25V9a1 1 0 001 1 1 1 0 001-1V3a3 3 0 00-2.999-3zM4.2 3a.967.967 0 01-1.695 0 .969.969 0 01-.154-.5.979.979 0 01.092-.4A.981.981 0 014.2 2a.977.977 0 01.153.5.977.977 0 01-.153.5zm3.5 0a.967.967 0 01-1.695 0 .969.969 0 01-.154-.5.969.969 0 01.154-.5A.967.967 0 017.7 2a.977.977 0 01.153.5.977.977 0 01-.153.5zm3.5 0a.967.967 0 01-1.695 0 .969.969 0 01-.154-.5.969.969 0 01.154-.5.967.967 0 011.695 0 .977.977 0 01.153.5.977.977 0 01-.153.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});