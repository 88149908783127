define("ember-svg-jar/inlined/love-gift-flowers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-gift-flowers</title><path d=\"M5 5.442A.5.5 0 005.442 5c.12-1.044.3-3.549-.618-4.465A1.5 1.5 0 002.2 1.9a.251.251 0 01-.3.3A1.5 1.5 0 00.538 4.824c.917.916 3.422.738 4.462.618zM23.712 19.645l-3.436-2.074a.5.5 0 00-.656.126 9.445 9.445 0 01-1.92 1.922.5.5 0 00-.127.657l2.074 3.436a.541.541 0 00.833.151l1.693-1.692 1.692-1.693a.541.541 0 00-.153-.833zM17.7 5.324l-.3-.3a1.353 1.353 0 00-.963-.4 1.364 1.364 0 00-.965.4L5.022 15.462a1.368 1.368 0 000 1.932l.306.305a8.65 8.65 0 006.141 2.536A8.756 8.756 0 0017.7 5.324zM7.121 10.568c-.364-.985-1.338-3.3-2.569-3.7a1.5 1.5 0 00-1.724 2.4.249.249 0 01-.133.409 1.5 1.5 0 00-.029 2.953c1.231.4 3.385-.887 4.261-1.465a.5.5 0 00.194-.597zM10.568 7.121a.5.5 0 00.591-.194c.578-.876 1.868-3.03 1.465-4.261a1.5 1.5 0 00-2.953.034.249.249 0 01-.409.133 1.5 1.5 0 00-2.4 1.724c.406 1.226 2.721 2.2 3.706 2.564z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});