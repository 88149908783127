define("ember-svg-jar/inlined/couple-man-woman-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>couple-man-woman-1</title><path d=\"M17.476 3.232A3.1 3.1 0 0014.83.019a2.82 2.82 0 00-2.495 1.02.468.468 0 01-.717 0 2.815 2.815 0 00-2.5-1.02 3.1 3.1 0 00-2.642 3.213c0 2.427 3.423 5.311 4.9 6.439a.981.981 0 001.208 0c1.468-1.127 4.892-4.012 4.892-6.439zM6.823 13.47a.251.251 0 01-.119-.2.247.247 0 01.094-.21 2.919 2.919 0 10-3.616 0 .25.25 0 01-.024.41 3.512 3.512 0 00-1.682 3v2.011a.5.5 0 00.5.5h.6a.5.5 0 01.5.451l.413 4.122a.5.5 0 00.5.45H6a.5.5 0 00.5-.45l.412-4.122a.5.5 0 01.5-.451H8a.5.5 0 00.354-.147.5.5 0 00.146-.353v-2.015a3.511 3.511 0 00-1.677-2.996zM4.99 12.19a1.419 1.419 0 01-1.418-1.418 1.489 1.489 0 01.028-.285.253.253 0 01.141-.177.25.25 0 01.226.009 3.576 3.576 0 001.746.452 3.7 3.7 0 00.4-.021.249.249 0 01.212.079.252.252 0 01.061.217A1.42 1.42 0 014.99 12.19zM22.5 19.854l-1.19-3.922a2.942 2.942 0 00-1.333-1.7.25.25 0 010-.433 3.042 3.042 0 10-3.006 0 .249.249 0 010 .432 2.953 2.953 0 00-1.334 1.7l-1.192 3.922a.5.5 0 00.079.446.594.594 0 00.407.2l.867-.012a.5.5 0 01.5.45l.261 2.612a.5.5 0 00.5.45h2.821a.5.5 0 00.5-.45l.261-2.612a.5.5 0 01.5-.45l.868.012a.6.6 0 00.406-.2.5.5 0 00.085-.445zM18.476 12.7a1.544 1.544 0 01-1.447-1.011.25.25 0 01.152-.323 3.054 3.054 0 001.12-.7.249.249 0 01.349 0 3.058 3.058 0 001.121.7.252.252 0 01.152.323 1.544 1.544 0 01-1.447 1.011z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});