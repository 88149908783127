define("ember-svg-jar/inlined/cursor-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-select</title><path d=\"M23.162 13.628l-3.087-2.264a.707.707 0 00-1.127.568v1.014a.25.25 0 01-.25.25H15.2a.25.25 0 01-.25-.25v-3.5a.25.25 0 01.25-.25h1.015a.7.7 0 00.566-1.127L14.514 4.98a.732.732 0 00-1.133 0l-2.262 3.086a.706.706 0 00.565 1.13H12.7a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25H9.2a.25.25 0 01-.25-.25v-1.014a.705.705 0 00-1.125-.568L4.733 13.63a.706.706 0 000 1.135l3.087 2.263a.705.705 0 001.126-.567v-1.015a.25.25 0 01.25-.25h3.5a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25h-1.012a.706.706 0 00-.568 1.125l2.266 3.091a.706.706 0 001.134 0l2.263-3.086a.705.705 0 00-.566-1.127H15.2a.25.25 0 01-.25-.25v-3.5a.25.25 0 01.25-.25h3.5a.25.25 0 01.25.25v1.015a.706.706 0 001.126.567l3.09-2.265a.708.708 0 000-1.135zM5.386 9.742a1 1 0 00.861-.752l.561-2.25a.251.251 0 01.181-.182L9.243 6a1 1 0 00.205-1.87L2 .405A1 1 0 00.657 1.747L4.383 9.2a1 1 0 001.003.542z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});