define("ember-svg-jar/inlined/book-address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-address</title><path d=\"M20.15 4.04a.5.5 0 01-.4-.489V2a2 2 0 00-2-2H5.25a3 3 0 00-3 3v18a3 3 0 003 3h14.5a2 2 0 002-2V6a2 2 0 00-1.6-1.96zM4.25 3a1 1 0 011-1H17.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H5.25a1 1 0 01-1-1zm13.574 16.984a.5.5 0 01-.384.18H6.56a.5.5 0 01-.493-.588 5.55 5.55 0 01.486-1.5c.447-.896 1.572-1.446 3.628-2.208a1.084 1.084 0 00-.008-.749 5.177 5.177 0 01-1.115-3.575 2.946 2.946 0 115.884 0 5.2 5.2 0 01-1.132 3.6 1.042 1.042 0 00.026.737c2.039.751 3.164 1.3 3.611 2.2a5.55 5.55 0 01.486 1.5.5.5 0 01-.109.403z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});