define("ember-svg-jar/inlined/shape-peg-top-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shape-peg-top-alternate</title><path d=\"M9.1 12.476a.251.251 0 00.039.22.253.253 0 00.2.1h5.321a.253.253 0 00.2-.1.251.251 0 00.039-.22L12.24 3.5a.25.25 0 00-.48 0zM14.782 14.636a.25.25 0 00-.233-.339H9.45a.247.247 0 00-.205.108.25.25 0 00-.028.231l2.549 6.691a.251.251 0 00.468 0zM7.452 14.215a.246.246 0 00-.138-.141L2.793 12.19a.25.25 0 00-.32.342 1.9 1.9 0 00.161.266l7.277 9.244a.25.25 0 00.43-.244zM7.261 12.426a.244.244 0 00.2-.005.247.247 0 00.132-.154l3.16-10.654a.25.25 0 00-.429-.233l-7.6 8.9a.181.181 0 00-.039.161.183.183 0 00.107.126zM16.548 14.215a.246.246 0 01.138-.141l4.521-1.884a.25.25 0 01.32.342 1.9 1.9 0 01-.161.266l-7.277 9.244a.25.25 0 01-.43-.244zM16.739 12.426a.244.244 0 01-.2-.005.247.247 0 01-.132-.154l-3.16-10.654a.25.25 0 01.429-.233l7.6 8.9a.181.181 0 01.039.161.183.183 0 01-.107.126z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});