define("ember-svg-jar/inlined/security-officer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>security-officer</title><path d=\"M11.7 5.573a.25.25 0 00-.25.25v.75a2.5 2.5 0 005 .008v-.758a.25.25 0 00-.25-.25zM9 3.573a1 1 0 001 1h6.368a.5.5 0 00.475-.342l.33-.988a1 1 0 00-.707-1.286L11.43.7a.756.756 0 00-.73.216.746.746 0 00-.164.749l.3.91H10a1 1 0 00-1 .998zM24 22.323a1 1 0 00-1-1h-5.5a.5.5 0 01-.5-.5v-6a.5.5 0 011 0v4.25a1.25 1.25 0 002.5 0v-4a4.005 4.005 0 00-4-4h-1.16a.5.5 0 00-.47.31l-.64 1.61a.262.262 0 01-.23.16.251.251 0 01-.23-.16l-.65-1.61a.489.489 0 00-.46-.31h-1.741a2 2 0 00-1.419.587l-5.594 5.6a1.245 1.245 0 001.76 1.76l4.9-4.892a.25.25 0 01.427.177v6.515a.5.5 0 01-.5.5H1a1 1 0 000 2h22a1 1 0 001-.997z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});