define("ember-svg-jar/inlined/organic-sun-growth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>organic-sun-growth</title><path d=\"M16 12a4 4 0 10-4-4 4 4 0 004 4zm0-6a2 2 0 11-2 2 2 2 0 012-2zM16 3a1 1 0 001-1V1a1 1 0 00-2 0v1a1 1 0 001 1zM20.95 4.464l.707-.707a1 1 0 10-1.414-1.414l-.707.707a1 1 0 001.414 1.414zM21 8a1 1 0 001 1h1a1 1 0 000-2h-1a1 1 0 00-1 1zM19.536 11.536a1 1 0 000 1.414l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 0zM15 14v1a1 1 0 002 0v-1a1 1 0 00-2 0zM11.05 11.536l-.707.707a1 1 0 001.414 1.414l.707-.707a1 1 0 10-1.414-1.414zM9 9h1a1 1 0 000-2H9a1 1 0 000 2zM11.05 4.464a1 1 0 101.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zM23 22h-.66a.25.25 0 01-.237-.33 5.277 5.277 0 011.1-1.713 1 1 0 10-1.414-1.414 6.854 6.854 0 00-.594.691.25.25 0 01-.4 0 6.868 6.868 0 00-.594-.691 1 1 0 00-1.416 1.412 5.3 5.3 0 011.1 1.715.249.249 0 01-.237.33h-3.306a.249.249 0 01-.237-.33 5.277 5.277 0 011.1-1.713 1 1 0 00-1.414-1.414 7 7 0 00-.594.691.25.25 0 01-.4 0 6.868 6.868 0 00-.594-.691 1 1 0 00-1.416 1.412 5.3 5.3 0 011.1 1.715.25.25 0 01-.237.33h-3.31a.25.25 0 01-.237-.33 5.277 5.277 0 011.1-1.713 1 1 0 00-1.414-1.414 6.854 6.854 0 00-.594.691.252.252 0 01-.2.1.249.249 0 01-.2-.1 7.012 7.012 0 00-.594-.691 1 1 0 00-1.411 1.412 5.3 5.3 0 011.1 1.715.25.25 0 01-.237.33H4.344a.249.249 0 01-.236-.33 5.26 5.26 0 011.1-1.713 1 1 0 00-1.414-1.414 7 7 0 00-.594.691.25.25 0 01-.4 0 6.868 6.868 0 00-.594-.691 1 1 0 00-1.415 1.412 5.283 5.283 0 011.1 1.715.249.249 0 01-.236.33H1a1 1 0 000 2h22a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});