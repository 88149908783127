define("ember-svg-jar/inlined/army-dog-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-dog-tag</title><path d=\"M18.433.262a.75.75 0 00-1.045.18l-3.761 5.327a.247.247 0 01-.2.106h-.177a.5.5 0 00-.409.788l.925 1.311A2.887 2.887 0 0114 9.375a2 2 0 01-4 0 1.935 1.935 0 01.123-.68.25.25 0 01.439-.057l.826 1.17a.75.75 0 101.224-.866l-6-8.5a.75.75 0 10-1.224.866L8.557 5.8a.249.249 0 01-.117.378 4.487 4.487 0 00-2.94 4.2v9a4.505 4.505 0 004.5 4.5h4a4.505 4.505 0 004.5-4.5v-9a4.487 4.487 0 00-2.94-4.2.249.249 0 01-.117-.378l3.169-4.49a.75.75 0 00-.179-1.048zM16 14.626a.75.75 0 010 1.5H8a.75.75 0 010-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});