define("ember-svg-jar/inlined/dating-couple-balloon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dating-couple-balloon</title><path d=\"M23.494 1.316A2.442 2.442 0 0019.618.7l-.668.657L18.282.7a2.442 2.442 0 00-3.876.618 2.352 2.352 0 00.453 2.749l3.658 3.753a.605.605 0 00.866 0l3.658-3.753a2.352 2.352 0 00.453-2.751zM19.739 10.566a1 1 0 10-1.774-.921c-1.027 1.977-.372 3.824.156 5.308.674 1.9 1.119 3.153-1.059 4.738a1 1 0 101.176 1.618c3.387-2.463 2.489-4.993 1.767-7.026-.479-1.347-.892-2.511-.266-3.717zM5.6 13.47a.247.247 0 01-.119-.2.251.251 0 01.094-.211 2.918 2.918 0 10-3.616 0 .251.251 0 01-.024.409 3.514 3.514 0 00-1.682 3v2.011a.5.5 0 00.5.5h.6a.5.5 0 01.5.45l.413 4.123a.5.5 0 00.5.45h2.01a.5.5 0 00.5-.45l.412-4.123a.5.5 0 01.5-.45h.6a.5.5 0 00.5-.5v-2.012A3.513 3.513 0 005.6 13.47zm-1.836-1.28a1.419 1.419 0 01-1.418-1.418 1.478 1.478 0 01.028-.284.25.25 0 01.367-.168 3.576 3.576 0 001.746.452 3.453 3.453 0 00.4-.022.252.252 0 01.212.079.255.255 0 01.061.218 1.42 1.42 0 01-1.396 1.143zM14.584 15.933a2.944 2.944 0 00-1.333-1.7.25.25 0 010-.433 3.042 3.042 0 10-3.006 0 .25.25 0 010 .433 2.95 2.95 0 00-1.334 1.7l-1.188 3.921a.5.5 0 00.077.446.594.594 0 00.407.2l.867-.012a.5.5 0 01.5.45l.261 2.612a.5.5 0 00.5.45h2.825a.5.5 0 00.5-.45l.261-2.612a.5.5 0 01.5-.45l.868.012a.605.605 0 00.406-.2.5.5 0 00.079-.447zM11.75 12.7a1.543 1.543 0 01-1.45-1.01.248.248 0 01.152-.322 3.056 3.056 0 001.12-.7.251.251 0 01.349 0 3.06 3.06 0 001.121.7.251.251 0 01.152.322 1.543 1.543 0 01-1.444 1.01z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});