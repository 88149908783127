define("ember-svg-jar/inlined/beanie-winter-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beanie-winter-3</title><path d=\"M19.414 9.073a18.056 18.056 0 00-4.087-2.839 28.921 28.921 0 01-2.748-1.682 1 1 0 00-1.158 0 29.157 29.157 0 01-2.749 1.683 18.094 18.094 0 00-4.086 2.837A8.549 8.549 0 002 15.179V21.5a2.5 2.5 0 005 0V19a.5.5 0 01.5-.5 2.735 2.735 0 001.575-.5.5.5 0 01.639.055 3.245 3.245 0 004.572 0 .5.5 0 01.639-.055 2.735 2.735 0 001.575.5.5.5 0 01.5.5v2.5a2.5 2.5 0 005 0v-6.321a8.545 8.545 0 00-2.586-6.106zM17.75 15.75a1.25 1.25 0 01-2.5 0 .75.75 0 00-1.5 0 1.75 1.75 0 01-3.5 0 .75.75 0 00-1.5 0 1.25 1.25 0 01-2.5 0v-2A1.252 1.252 0 017.5 12.5h9a1.252 1.252 0 011.25 1.25zM12 3.5a1 1 0 001-1V1a1 1 0 00-2 0v1.5a1 1 0 001 1zM14.75 4a.993.993 0 00.707-.293l1.061-1.061A1 1 0 1015.1 1.232l-1.057 1.061A1 1 0 0014.75 4zM15.25 5a1 1 0 001 1h1.5a1 1 0 000-2h-1.5a1 1 0 00-1 1zM8.543 3.707a1 1 0 101.414-1.414L8.9 1.232a1 1 0 10-1.418 1.414zM6.25 6h1.5a1 1 0 000-2h-1.5a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});