define("ember-svg-jar/inlined/compass-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>compass-arrow</title><path d=\"M23.633.368A1.242 1.242 0 0022.159.15L.66 11.649A1.25 1.25 0 001.25 14H10v8.75a1.249 1.249 0 00.946 1.212 1.28 1.28 0 00.3.038 1.248 1.248 0 001.1-.661l11.5-21.5a1.243 1.243 0 00-.213-1.471z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});