define("ember-svg-jar/inlined/module-blocks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>module-blocks</title><path d=\"M14.5 5a1 1 0 00-1-1h-12a1 1 0 00-1 1v3a.5.5 0 00.5.5h13a.5.5 0 00.5-.5zM8 17.5a.5.5 0 00-.5-.5H1a.5.5 0 00-.5.5V22a1 1 0 001 1h6.042a.5.5 0 00.493-.585A2.464 2.464 0 018 22zM7.5 16a.5.5 0 00.5-.5V14a2.5 2.5 0 012.5-2.5H14a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5H1a.5.5 0 00-.5.5v5.5a.5.5 0 00.5.5z\"/><rect x=\"2.5\" y=\"1\" width=\"3\" height=\"2\" rx=\".5\" ry=\".5\"/><rect x=\"9.5\" y=\"1\" width=\"3\" height=\"2\" rx=\".5\" ry=\".5\"/><path d=\"M9.793 13.293A1 1 0 009.5 14v8a1 1 0 001 1h12a1 1 0 001-1v-8a1 1 0 00-1-1h-12a1 1 0 00-.707.293zM20.5 15a1 1 0 11-1 1 1 1 0 011-1zm0 4a1 1 0 11-1 1 1 1 0 011-1zm-4-4a1 1 0 11-1 1 1 1 0 011-1zm0 4a1 1 0 11-1 1 1 1 0 011-1zm-4-4a1 1 0 11-1 1 1 1 0 011-1zm0 4a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});