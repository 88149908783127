define("ember-svg-jar/inlined/otter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>otter</title><path d=\"M17.534 18.5a1.5 1.5 0 001.324-2.2l-1.371-2.58a6.791 6.791 0 01-3.735.747.75.75 0 01-.682-.813.742.742 0 01.812-.681c2.819.242 4.151-1.4 4.864-3.294a8.438 8.438 0 00.54-4 1.991 1.991 0 001.767-1.882l1.037-.346a1 1 0 10-.632-1.9l-.41.137.121-.242a1 1 0 00-1.79-.894l-.4.8a2 2 0 00-2.157 1.309c-2.45.475-9.234 2.039-10.979 5.09-1.541 2.7-1.714 6.967-1.679 9.272a1.506 1.506 0 001.5 1.477zm-4.51-11.75a1 1 0 11-1-1 1 1 0 011 1zM18.726 20h-1a3.5 3.5 0 00-3.5 3.5.506.506 0 00.777.416l1.259-.839 1.74.87a.5.5 0 00.5-.031l1.259-.839 1.74.87a.5.5 0 00.724-.447 3.5 3.5 0 00-3.499-3.5zM5.726 20h-1a3.5 3.5 0 00-3.5 3.5.5.5 0 00.723.447l1.741-.87 1.258.839a.5.5 0 00.5.031l1.741-.87 1.258.839a.506.506 0 00.778-.416A3.5 3.5 0 005.726 20z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});