define("ember-svg-jar/inlined/monkey-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monkey-2</title><path d=\"M19.6 5.384a8 8 0 00-15.208 0A4.426 4.426 0 00.5 9.875a4.572 4.572 0 002.188 4.015 3.671 3.671 0 001.4.462A9.313 9.313 0 002.5 19.336a3.29 3.29 0 00.888 2.33C4.816 23.3 7.714 24.125 12 24.125s7.184-.827 8.612-2.459a3.311 3.311 0 00.89-2.291v-.039a9.336 9.336 0 00-1.588-4.987 3.652 3.652 0 001.506-.522 4.612 4.612 0 002.08-3.952 4.426 4.426 0 00-3.9-4.491zM9.332 12.541a1.389 1.389 0 011.773.781 1.042 1.042 0 001.79 0 1.386 1.386 0 011.766-.792c1.721.406 3.722 2.446 4.5 4.833A19.265 19.265 0 0112 18.375c-4.332 0-6.126-.712-7.124-1.127.806-2.331 2.767-4.308 4.456-4.707zm-.5-7.295c1.966 0 2.217 1.9 3.167 1.9.973 0 1.143-1.9 3.169-1.9 4.8 0 2.453 5.85 2.214 6.422a6.517 6.517 0 00-2.264-1.085 3.419 3.419 0 00-3.118.673 3.444 3.444 0 00-3.128-.656 6.515 6.515 0 00-2.247 1.073c-.181-.438-2.66-6.427 2.205-6.427zM2.5 9.875a2.537 2.537 0 011.639-2.422 10.817 10.817 0 00.869 4.847 1.636 1.636 0 01-1.328-.147A2.606 2.606 0 012.5 9.875zm16.607 10.474c-.58.663-2.276 1.776-7.107 1.776-4.716 0-6.438-1.069-7.044-1.706a1.358 1.358 0 01-.456-1.164 19.373 19.373 0 007.5 1.12 21.316 21.316 0 007.5-.986 1.236 1.236 0 01-.393.96zm1.265-8.227a1.657 1.657 0 01-1.359.182 10.543 10.543 0 00.827-4.857 2.532 2.532 0 011.66 2.428 2.607 2.607 0 01-1.128 2.247z\"/><circle cx=\"9.25\" cy=\"8.375\" r=\"1.25\"/><circle cx=\"14.75\" cy=\"8.375\" r=\"1.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});