define("ember-svg-jar/inlined/renewable-energy-eco-barrel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>renewable-energy-eco-barrel</title><path d=\"M12.013 18.9a.5.5 0 00.136-.5A3.923 3.923 0 0112 17.032a.5.5 0 00-.5-.532H.753a.75.75 0 000 1.5H1.5a.5.5 0 01.5.5V22a.5.5 0 01-.5.5H.686a.753.753 0 000 1.5h7.722a.5.5 0 00.5-.546 2.23 2.23 0 01.38-1.469 20.687 20.687 0 012.725-3.085zM.753 3.5H1.5A.5.5 0 012 4v3a.5.5 0 00.5.5h15A.5.5 0 0018 7V4a.5.5 0 01.5-.5h.75a.75.75 0 000-1.5H17.5a.5.5 0 01-.5-.5V1a1 1 0 00-1-1h-3a1 1 0 00-1 1v.5a.5.5 0 01-.5.5H.753a.75.75 0 000 1.5zM.753 10.5H1.5a.5.5 0 01.5.5v3.5a.5.5 0 00.5.5h9.931a.5.5 0 00.4-.2 6.878 6.878 0 012.03-1.757 6.188 6.188 0 012.682-.837.5.5 0 00.455-.5V11a.5.5 0 01.5-.5h.75a.75.75 0 000-1.5H.753a.75.75 0 000 1.5zM23.933 13.653a.5.5 0 00-.677-.385 8.859 8.859 0 01-3.721.417 6.664 6.664 0 00-3.9.642A3.8 3.8 0 0013.5 17.05a2.785 2.785 0 00.383 1.651.5.5 0 01-.108.618 20.461 20.461 0 00-3.246 3.509.75.75 0 001.239.845 24.3 24.3 0 016.843-6.033.75.75 0 11.767 1.289 23.379 23.379 0 00-3.534 2.583.5.5 0 00-.025.724 2.262 2.262 0 00.6.442 2.515 2.515 0 001.149.257 5.411 5.411 0 002.693-.886c1.998-1.198 4.139-5.485 3.672-8.396z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});