define("ember-svg-jar/inlined/clothes-design-thread", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>clothes-design-thread</title><path d=\"M21 7.75a3 3 0 00-3 3v3.75a.969.969 0 01-.569.877.753.753 0 00-.431.679v.551a.749.749 0 00.75.75.739.739 0 00.25-.043 2.991 2.991 0 002-2.814v-3.75a1 1 0 012 0v2.5a1 1 0 002 0v-2.5a3 3 0 00-3-3zM1.5 3.75a.5.5 0 01.5.5v2a.5.5 0 00.5.5h12a.5.5 0 00.5-.5v-2a.5.5 0 01.5-.5 1.5 1.5 0 000-3h-14a1.5 1.5 0 000 3z\"/><rect x=\"2\" y=\"12.75\" width=\"13\" height=\"3\" rx=\".5\" ry=\".5\"/><rect x=\"2\" y=\"8.25\" width=\"13\" height=\"3\" rx=\".5\" ry=\".5\"/><path d=\"M15.5 20.25a.5.5 0 01-.5-.5v-2a.5.5 0 00-.5-.5h-12a.5.5 0 00-.5.5v2a.5.5 0 01-.5.5 1.5 1.5 0 000 3h14a1.5 1.5 0 000-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});