define("ember-svg-jar/inlined/phone-actions-24-support-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-24-support-1</title><path d=\"M23 0a5.7 5.7 0 00-5.688 5.688v.937a1 1 0 001 1H22V8.5a1 1 0 002 0V1a1 1 0 00-1-1zm-1 5.629h-2.687A3.7 3.7 0 0122 2.142z\"/><path d=\"M11.344 4.348a1 1 0 001-1A1.344 1.344 0 0115 3.078c.138.675-.031.893-.953 1.7l-3.365 2.976a1 1 0 00.662 1.75H16.5a1 1 0 000-2h-2.512l1.384-1.221c.924-.813 1.972-1.735 1.593-3.6a3.344 3.344 0 00-6.621.667 1 1 0 001 .998zM15.5 15.788a3.119 3.119 0 00-4.4 0l-.826.827a4.252 4.252 0 00-1.347 4.5 11.354 11.354 0 01-6.03-6.031 4.242 4.242 0 004.5-1.345l.832-.83a3.119 3.119 0 000-4.4L4.634 4.928a2.232 2.232 0 00-3.648.74 13.322 13.322 0 008.978 17.914 13.6 13.6 0 008.378-.559 2.233 2.233 0 00.738-3.649z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});