define("ember-svg-jar/inlined/arrow-down-12", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 19.5c-.294 0-.573-.13-.763-.354l-11-13a1 1 0 111.526-1.292L12 16.952 22.237 4.854a1 1 0 111.526 1.291l-11 13A.997.997 0 0112 19.5z\"/><path fill=\"none\" d=\"M0 0h24v24H0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});