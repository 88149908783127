define("ember-svg-jar/inlined/sql-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sql-1</title><path d=\"M12.475 12.1a1.377 1.377 0 00-1.375 1.376v3.5a1.375 1.375 0 002.75 0v-3.5a1.377 1.377 0 00-1.375-1.376z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM5.8 14.442l1.95.975a2.479 2.479 0 01-1.108 4.7H4.5a.625.625 0 010-1.25h2.146a1.229 1.229 0 00.554-2.332l-1.95-.975a2.48 2.48 0 011.109-4.7H8.5a.625.625 0 010 1.25H6.354a1.23 1.23 0 00-.55 2.329zm9.3 2.534a2.607 2.607 0 01-1.176 2.173.167.167 0 00.011.283l.857.505a.625.625 0 11-.635 1.077S11.1 19.2 11.078 19.19a2.62 2.62 0 01-1.228-2.214v-3.5a2.625 2.625 0 015.25 0zm5.375 3.124h-2.5a2.127 2.127 0 01-2.125-2.125v-6.5a.625.625 0 111.25 0v6.5a.876.876 0 00.875.875h2.5a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});