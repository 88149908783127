define("ember-svg-jar/inlined/smiley-disapointed-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-disapointed-2-alternate</title><path d=\"M23.131 7.162a1 1 0 10-1.854.749 10 10 0 11-18.607.135 1 1 0 10-1.865-.721 12 12 0 1022.326-.163z\"/><circle cx=\"7.583\" cy=\"7.153\" r=\"1.75\"/><path d=\"M5.663 3.916a1 1 0 00.707-.293 4.332 4.332 0 013.08-1.276 1 1 0 000-2 6.345 6.345 0 00-4.494 1.861 1 1 0 00.707 1.708z\"/><circle cx=\"15.917\" cy=\"7.153\" r=\"1.75\"/><path d=\"M14.051 2.347a4.337 4.337 0 013.08 1.276 1 1 0 001.414-1.415A6.345 6.345 0 0014.051.347a1 1 0 000 2zM9.293 10.7a1 1 0 101.414 1.414l.5-.5A1 1 0 109.793 10.2zM13.293 12.11a1 1 0 001.414-1.41l-.5-.5a1 1 0 10-1.414 1.414zM6.892 17.494a.44.44 0 00.379.659 21.078 21.078 0 019.123 0 .439.439 0 00.38-.658 5.706 5.706 0 00-9.882 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});