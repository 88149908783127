define("ember-svg-jar/inlined/radiology-scan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>radiology-scan</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-2.224 9.7a1 1 0 01-1.406.147 10.457 10.457 0 00-5.313-2.006.5.5 0 00-.557.5v1.482a.5.5 0 00.455.5 14.773 14.773 0 012.771.509 12.576 12.576 0 012.026.775 1 1 0 01-.44 1.9.982.982 0 01-.438-.1 10.576 10.576 0 00-1.7-.65 12.6 12.6 0 00-2.115-.412.5.5 0 00-.555.5v1.237a.5.5 0 00.455.5 14.773 14.773 0 012.771.509 1 1 0 01-.278 1.961A1.03 1.03 0 0115.67 17a12.7 12.7 0 00-2.115-.413.5.5 0 00-.555.5v2.663a1 1 0 01-2 0v-2.663a.5.5 0 00-.555-.5A12.7 12.7 0 008.33 17a1 1 0 01-.556-1.922 14.773 14.773 0 012.771-.509.5.5 0 00.455-.5v-1.233a.5.5 0 00-.556-.5 12.611 12.611 0 00-2.114.412 10.576 10.576 0 00-1.7.65 1 1 0 01-.878-1.8 12.552 12.552 0 012.027-.775 14.748 14.748 0 012.77-.509.5.5 0 00.455-.5V8.335a.5.5 0 00-.557-.5A10.457 10.457 0 005.13 9.844a1 1 0 01-1.26-1.553 12.453 12.453 0 016.675-2.47.5.5 0 00.455-.5V3.75a1 1 0 012 0v1.574a.5.5 0 00.455.5 12.453 12.453 0 016.675 2.47 1 1 0 01.146 1.406z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});