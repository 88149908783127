define("ember-svg-jar/inlined/single-woman-phone-book.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-phone-book</title><path d=\"M24 4a3 3 0 00-3-3h-.774a.5.5 0 01-.434-.25A1.488 1.488 0 0018.5 0H3.755a.25.25 0 00-.25.25v23.5a.25.25 0 00.25.25H18.5a1.488 1.488 0 001.292-.75.5.5 0 01.434-.25H21a3 3 0 003-3zm-7.068 14.145a.24.24 0 01-.177.079H6.235a.24.24 0 01-.177-.079.249.249 0 01-.058-.188 5.036 5.036 0 01.464-1.716c.338-.689 1.75-1.168 3.523-1.837a.489.489 0 00.313-.459v-.2a.249.249 0 00-.073-.177.253.253 0 00-.178-.073 3.737 3.737 0 01-2.215-.567.247.247 0 01-.051-.372c.3-.338.51-.746.6-2.085a3.12 3.12 0 116.234 0c.089 1.339.3 1.747.6 2.085a.247.247 0 01-.051.372 3.7 3.7 0 01-2.216.556.25.25 0 00-.25.25v.207a.489.489 0 00.313.459c1.773.669 3.184 1.148 3.522 1.837A5.043 5.043 0 0117 17.953a.253.253 0 01-.068.192zM22 10.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25zm-2 2.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25zM22 4v1.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25H21a1 1 0 011 1zm0 16a1 1 0 01-1 1h-.75a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25zM2.505.25a.25.25 0 00-.25-.25h-.75a1.5 1.5 0 00-1.5 1.5v21a1.5 1.5 0 001.5 1.5h.75a.25.25 0 00.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});