define("ember-svg-jar/inlined/loading-circle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>loading-circle-1</title><path d=\"M12.046 24a11.916 11.916 0 004.91-1.048 1 1 0 10-.82-1.824A10 10 0 1112.046 2a1 1 0 000-2 12 12 0 000 24zM15.21 2.508c.29.1.573.206.85.327A1 1 0 1016.861 1a11.908 11.908 0 00-1.019-.392 1 1 0 10-.632 1.9zM20.044 5.994a1 1 0 001.6-1.2 12.09 12.09 0 00-1.368-1.528A1 1 0 0018.9 4.72a9.985 9.985 0 011.144 1.274zM21.876 17.038a1 1 0 001.3-.552 11.961 11.961 0 00.6-1.965 1 1 0 00-1.956-.419 9.829 9.829 0 01-.5 1.634 1 1 0 00.556 1.302zM21.6 9.026a9.953 9.953 0 01.365 1.669 1 1 0 00.99.871.972.972 0 00.131-.009 1 1 0 00.862-1.12 11.887 11.887 0 00-.438-2 1 1 0 00-1.91.594zM19.519 18.641q-.3.34-.634.652a1 1 0 001.368 1.459q.4-.373.76-.78a1 1 0 10-1.494-1.331z\"/><path d=\"M11.046 5a1 1 0 001 1 6 6 0 11-6 6 1 1 0 00-2 0 8 8 0 108-8 1 1 0 00-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});