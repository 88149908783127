define("ember-svg-jar/inlined/reception-hotel-24", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>reception-hotel-24</title><path d=\"M24 14.5a1.5 1.5 0 00-1.5-1.5H20a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h1.5a1 1 0 001-1v-1a1 1 0 00-1-1H16a1 1 0 00-1 1v1a1 1 0 001 1h1.5a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-16A1.5 1.5 0 000 14.5v9a.5.5 0 00.5.5h23a.5.5 0 00.5-.5zm-9.5 6.75a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25v-2a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25v2a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25v-6a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v2a.25.25 0 00.25.25h1.5a.25.25 0 00.25-.25v-2a.25.25 0 01.25-.25h1a.25.25 0 01.25.25z\"/><path d=\"M10 11a2 2 0 00-2-2H4a2 2 0 00-2 2v.75a.25.25 0 00.25.25h7.5a.25.25 0 00.25-.25zM14.75 1.75a1.75 1.75 0 00-3.5 0 .75.75 0 001.5 0c0-.275.5-.275.5 0a.682.682 0 01-.148.422l-1.688 2.109A.75.75 0 0012 5.5h2A.75.75 0 0014 4a.211.211 0 01-.19-.12.208.208 0 01.025-.223l.438-.549a2.175 2.175 0 00.477-1.358zM18.25 5.5a.75.75 0 00.75-.75v-4a.75.75 0 00-.75-.75 2.752 2.752 0 00-2.75 2.75v.5a.75.75 0 00.75.75h1a.25.25 0 01.25.25v.5a.75.75 0 00.75.75zm-1.225-3a1.248 1.248 0 01.475-.749V2.5zM22.25 2h-.5a.25.25 0 01-.25-.25v-1a.75.75 0 00-1.5 0v4a.75.75 0 001.5 0v-1a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v1a.75.75 0 001.5 0v-1A1.752 1.752 0 0022.25 2zM7.9 1.077a5.685 5.685 0 00-3.792 0 .9.9 0 00-.6.857v.816A.25.25 0 003.75 3h4.5a.25.25 0 00.25-.25v-.816a.9.9 0 00-.6-.857zM3.5 5a2.5 2.5 0 005 0v-.75A.25.25 0 008.25 4h-4.5a.25.25 0 00-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});