define("ember-svg-jar/inlined/tags-favorite-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-favorite-alternate</title><circle cx=\"17.17\" cy=\"5.999\" r=\"1.5\"/><path d=\"M13.96 12.545a3.525 3.525 0 00-1.936 5.965l5.114 5.335a.5.5 0 00.722 0l5.106-5.327a3.525 3.525 0 10-4.985-4.986l-.305.3a.25.25 0 01-.354 0l-.3-.3a3.5 3.5 0 00-3.062-.987z\"/><path d=\"M11.248 19.868a.247.247 0 00-.178-.077.251.251 0 00-.179.073l-1.014 1.014a1.025 1.025 0 01-1.414 0l-6.172-6.172a1 1 0 010-1.414l11-11A1 1 0 0114 2h6.67a.5.5 0 01.5.5v6.67a1 1 0 01-.293.708l-.7.7a.25.25 0 00.182.427h.1a5.071 5.071 0 011.752.309.071.071 0 00.076-.016 2.978 2.978 0 00.883-2.128V2a2 2 0 00-2-2H14a2.98 2.98 0 00-2.122.879l-11 11a3 3 0 000 4.242l6.172 6.172a3 3 0 004.242 0l.992-.991a.251.251 0 000-.35z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});