define("ember-svg-jar/inlined/single-neutral-actions-flight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-flight</title><path d=\"M10.5 17.75a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5h1.81a.249.249 0 01.249.229L3 23.291a.5.5 0 00.5.459h4a.5.5 0 00.5-.459l.443-5.312a.249.249 0 01.249-.229z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M11.169 20.454a.76.76 0 00-.164.563.77.77 0 00.308.533l2.877 2.02a.98.98 0 001.084.032l7.855-4.878A1.869 1.869 0 0024 17.119a1.793 1.793 0 00-.105-.6 1.869 1.869 0 00-1.35-1.211 1.8 1.8 0 00-1.41.269l-2 1.365a.25.25 0 01-.228.027l-4.653-1.732a.831.831 0 00-.782.112l-.886.651a1.138 1.138 0 00-.139.13.823.823 0 00-.2.6.856.856 0 00.35.611l2.7 1.67a.251.251 0 01.017.414l-.957.709a.25.25 0 01-.276.015l-1.17-.69a.782.782 0 00-.747.131l-.867.728a1.227 1.227 0 00-.128.136zM10.5 17.75a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5h1.81a.249.249 0 01.249.229L3 23.291a.5.5 0 00.5.459h4a.5.5 0 00.5-.459l.443-5.312a.249.249 0 01.249-.229z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M11.169 20.454a.76.76 0 00-.164.563.77.77 0 00.308.533l2.877 2.02a.98.98 0 001.084.032l7.855-4.878A1.869 1.869 0 0024 17.119a1.793 1.793 0 00-.105-.6 1.869 1.869 0 00-1.35-1.211 1.8 1.8 0 00-1.41.269l-2 1.365a.25.25 0 01-.228.027l-4.653-1.732a.831.831 0 00-.782.112l-.886.651a1.138 1.138 0 00-.139.13.823.823 0 00-.2.6.856.856 0 00.35.611l2.7 1.67a.251.251 0 01.017.414l-.957.709a.25.25 0 01-.276.015l-1.17-.69a.782.782 0 00-.747.131l-.867.728a1.227 1.227 0 00-.128.136z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});