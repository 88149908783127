define("ember-svg-jar/inlined/landmark-statue-of-liberty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-statue-of-liberty</title><path d=\"M17.8 22.429a1.513 1.513 0 00-1.063-.441H8.57a1.514 1.514 0 00-1.064.441l-.707.71a.5.5 0 00.355.853h11a.5.5 0 00.354-.852zM14.655 7.992v-.5a.5.5 0 01.5-.5.5.5 0 00.485-.379l.5-2a.5.5 0 00-.606-.606l-1.451.363a.5.5 0 01-.475-.132l-.6-.6a.5.5 0 00-.707 0l-.6.6a.5.5 0 01-.474.132l-1.451-.363a.5.5 0 00-.607.606l.5 2a.5.5 0 00.486.379.5.5 0 01.5.5v.5a2 2 0 004 0zM8.4 3.492C8.4 2.512 7.244.92 6.745.281a.775.775 0 00-1.181 0c-.5.639-1.659 2.231-1.659 3.211a2.25 2.25 0 104.5 0z\"/><path d=\"M18.842 10.742h-2.35a.5.5 0 01-.353-.145 2.1 2.1 0 00-1.477-.6h-3.51a1.564 1.564 0 01-1.22-.62c0-.01-.01-.02-.02-.03l-2.57-2.83a.506.506 0 00-.37-.16h-1.39a.5.5 0 00-.43.25.5.5 0 000 .5l4.24 7.1c.01.01.01.02.02.04a1.54 1.54 0 01.12.95.01.01 0 00-.01.01l-.86 5.2a.506.506 0 00.49.59h7a.5.5 0 00.49-.6l-.7-3.553a.5.5 0 01.491-.6h.911a1.231 1.231 0 001.12-.69l1.5-3a1.249 1.249 0 00-1.122-1.812zm-.581 1.858l-.931 1.862a.5.5 0 01-.447.276h-1.726a.25.25 0 01-.224-.362l.931-1.861a.5.5 0 01.447-.277h1.727a.25.25 0 01.223.362z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});