define("ember-svg-jar/inlined/browser-page-hierarchy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>browser-page-hierarchy</title><path d=\"M24 4.75a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h18a3 3 0 003-3zm-14.346-1a.966.966 0 011.692 0 .969.969 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0 .969.969 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0A.969.969 0 016 4.25a.969.969 0 01.154-.5zm-3.562.092A1 1 0 013.5 3.25a.985.985 0 01.846.5.969.969 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.979.979 0 01.092-.408zM22 19.25a1 1 0 01-1 1H3a1 1 0 01-1-1V7a.25.25 0 01.25-.25h19.5A.25.25 0 0122 7z\"/><path d=\"M4.05 17.25a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 00-.8-.98.249.249 0 01-.2-.245V11.75a.5.5 0 01.5-.5h2.64a.25.25 0 01.207.111 2.005 2.005 0 00.987.767.251.251 0 01.166.236V14a.25.25 0 01-.25.25h-.25a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 00-1-1h-.25a.25.25 0 01-.25-.25v-1.636a.249.249 0 01.166-.236 2 2 0 00.986-.767.25.25 0 01.208-.111h2.64a.5.5 0 01.5.5v2.275a.25.25 0 01-.2.245 1 1 0 00-.8.98v2a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 00-1-1h-.75a.25.25 0 01-.25-.25v-2.25a1.5 1.5 0 00-1.5-1.5h-2.264a.252.252 0 01-.249-.222 2 2 0 00-3.975 0 .249.249 0 01-.248.222H7.55a1.5 1.5 0 00-1.5 1.5V14a.25.25 0 01-.25.25h-.75a1 1 0 00-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});