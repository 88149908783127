define("ember-svg-jar/inlined/email-action-receive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-receive</title><path d=\"M21.971 8.919L17.769 7.3a.75.75 0 00-.539 1.4l2.169.835a.251.251 0 010 .466l-7.31 2.923a.247.247 0 01-.185 0L4.569 9.989a.25.25 0 010-.466L6.763 8.7a.75.75 0 10-.527-1.4L1.978 8.9a.766.766 0 00-.478.719v8.917a.5.5 0 00.276.448l10 4.968h.014A.506.506 0 0012 24a.514.514 0 00.21-.049h.013l10-4.968a.5.5 0 00.277-.448V9.5c0-.012.047-.359-.529-.581z\"/><path d=\"M11.2 10.1a1 1 0 001.6 0l3-4a1 1 0 00-.8-1.6h-1.5v-3a1.5 1.5 0 00-3 0v3H9a1 1 0 00-.8 1.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});