define("ember-svg-jar/inlined/tags-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-refresh</title><path d=\"M17 9.249a7.5 7.5 0 01.878.055.5.5 0 00.414-.147l.73-.745A3.79 3.79 0 0020.007 6V2a2 2 0 00-2-2L13.75.005a3.751 3.751 0 00-2.415 1L.507 11.834a2 2 0 000 2.828L5.344 19.5a1.977 1.977 0 001.406.581h.011a1.976 1.976 0 001.411-.6l.515-.525a.5.5 0 00.138-.417A7.779 7.779 0 018.75 17.5 8.259 8.259 0 0117 9.249zM12.507 5.5a2 2 0 112 2 2 2 0 01-2-2zM11.354 22.782l.969-.97a.25.25 0 01.359.006 6.086 6.086 0 0010.21-2.2A1 1 0 0021 18.972a4.091 4.091 0 01-6.918 1.419.249.249 0 01.008-.344l1.721-1.722a.5.5 0 00-.353-.853H11a.5.5 0 00-.5.5v4.457a.492.492 0 00.068.224.464.464 0 00.087.111.47.47 0 00.154.126.5.5 0 00.545-.108z\"/><path d=\"M21.621 12.655a.249.249 0 01-.358 0 6.1 6.1 0 00-10.2 2.227 1 1 0 001.893.648 4.091 4.091 0 016.9-1.445.248.248 0 01-.006.346l-1.659 1.66a.5.5 0 00.353.853H23a.5.5 0 00.5-.5v-4.461a.5.5 0 00-.309-.462.488.488 0 00-.455.061c-.029.019-.065.023-.09.048z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});