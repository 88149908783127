define("ember-svg-jar/inlined/live-notice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>live-notice</title><path d=\"M23.5 7A2.5 2.5 0 0021 4.5H3A2.5 2.5 0 00.5 7v10A2.5 2.5 0 003 19.5h18a2.5 2.5 0 002.5-2.5zM6 15.267H5a1.752 1.752 0 01-1.75-1.75v-4a.75.75 0 011.5 0v4a.251.251 0 00.25.25h1a.75.75 0 010 1.5zm3.75-.75a.75.75 0 01-1.5 0v-5a.75.75 0 011.5 0zm5.5-3.3A5.872 5.872 0 0113.826 15a.749.749 0 01-1.152 0 5.872 5.872 0 01-1.424-3.783v-1.7a.75.75 0 011.5 0v1.7a4.1 4.1 0 00.266 1.448.249.249 0 00.468 0 4.1 4.1 0 00.266-1.448v-1.7a.75.75 0 011.5 0zm4.25.553a.75.75 0 010 1.5h-1a.25.25 0 000 .5h1a.75.75 0 010 1.5h-1a1.752 1.752 0 01-1.75-1.75v-3a1.752 1.752 0 011.75-1.75h1a.75.75 0 010 1.5h-1a.25.25 0 00-.25.25v1a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});