define("ember-svg-jar/inlined/multiple-actions-flag.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-flag</title><path d=\"M17.648 10.3a.25.25 0 00.159-.464 20.093 20.093 0 00-2.84-1.193l-.486-.172a1.8 1.8 0 01-.055-1.171 4.647 4.647 0 001.216-3.569A3.22 3.22 0 0012.5.249a2.994 2.994 0 00-1.342.314.25.25 0 00-.1.363 5 5 0 01.834 2.808 6.266 6.266 0 01-.922 3.624.25.25 0 00.121.372c1.918.716 3.3 1.328 3.905 2.537.012.024.023.047.033.071a.251.251 0 00.3.138 5.54 5.54 0 012.319-.176z\"/><path d=\"M12.5 12.87a1.977 1.977 0 011.185-1.822.25.25 0 00.1-.363C13.272 9.9 12.2 9.469 9.967 8.647l-.486-.172A1.8 1.8 0 019.426 7.3a4.647 4.647 0 001.216-3.569A3.22 3.22 0 007.5.249a3.219 3.219 0 00-3.149 3.485 4.609 4.609 0 001.192 3.538 1.88 1.88 0 010 1.186l-.515.189c-2.006.739-3.457 1.272-3.911 2.179A7.542 7.542 0 00.5 13.749a.5.5 0 00.5.5h11.245A.25.25 0 0012.5 14zM23.273 12.449a.5.5 0 00-.478-.033l-.884.41a3.052 3.052 0 01-2.717-.37 4.048 4.048 0 00-3.6-.489l-1.269.429a.5.5 0 00-.34.474L14 22.751a1 1 0 002 0v-4.164a.249.249 0 01.191-.243 3.047 3.047 0 012.448.44 4.011 4.011 0 002.27.7 4.256 4.256 0 001.376-.232l.933-.43a.5.5 0 00.291-.454v-5.5a.5.5 0 00-.236-.419z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});