define("ember-svg-jar/inlined/single-man-actions-text.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-text</title><path d=\"M7 10a5 5 0 10-5-5 5.006 5.006 0 005 5zM4.1 4.257a.5.5 0 01.741-.3A6.959 6.959 0 008.46 5a6.36 6.36 0 00.929-.07.5.5 0 01.569.564A3 3 0 014 5a2.841 2.841 0 01.1-.743zM9.5 12.5a2.884 2.884 0 01.184-1.008.5.5 0 00-.319-.652 7.2 7.2 0 00-2.158-.34 7.545 7.545 0 00-7.186 5.357.5.5 0 00.479.643H9a.5.5 0 00.5-.5zM24 13a2 2 0 00-2-2h-9a2 2 0 00-2 2v9a2 2 0 002 2h9a2 2 0 002-2zm-11 .25a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v8.5a.249.249 0 01-.25.25h-8.5a.25.25 0 01-.25-.25z\"/><path d=\"M20 15h-4.75a.75.75 0 000 1.5H20a.75.75 0 000-1.5zM17.5 19.5a.75.75 0 000-1.5h-2.25a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});