define("ember-svg-jar/inlined/table-restaurant-beach", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>table-restaurant-beach</title><path d=\"M12.5 12a.5.5 0 01.5.5v10.475a1 1 0 01-2 0V12.5a.5.5 0 01.5-.5zM9 20a2 2 0 00-2-2H4a.249.249 0 01-.244-.2l-.783-3.521a1 1 0 10-1.952.434L1.978 19a.988.988 0 01.022.22V23a1 1 0 002 0v-2.749a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25V23a1 1 0 002 0zM15 20a2 2 0 012-2h3a.249.249 0 00.244-.2l.783-3.521a1 1 0 011.952.434L22.022 19a.988.988 0 00-.022.22V23a1 1 0 01-2 0v-2.749a.25.25 0 00-.25-.25h-2.5a.25.25 0 00-.25.25V23a1 1 0 01-2 0zM6.133 9.659a.252.252 0 01.446 0A2.869 2.869 0 009.2 11.185a2.87 2.87 0 002.617-1.526.252.252 0 01.446 0 2.869 2.869 0 002.617 1.526 2.869 2.869 0 002.617-1.526.252.252 0 01.446 0 2.87 2.87 0 002.617 1.526 2.317 2.317 0 002.377-1.546 1.843 1.843 0 00-.6-1.889L12.751.248a1.164 1.164 0 00-1.432 0L1.59 7.8a1.837 1.837 0 00-.544 1.734 2.378 2.378 0 002.47 1.654 2.87 2.87 0 002.617-1.529z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});