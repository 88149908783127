define("ember-svg-jar/inlined/cursor-select-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-select-2</title><path d=\"M4.5 0H1a1 1 0 00-1 1v3.5a1 1 0 002 0V2.248A.25.25 0 012.25 2H4.5a1 1 0 000-2zM1 12.5a1 1 0 001-1v-3a1 1 0 00-2 0v3a1 1 0 001 1zM4.5 18H2.25a.25.25 0 01-.25-.25V15.5a1 1 0 10-2 0V19a1 1 0 001 1h3.5a1 1 0 000-2zM11.5 0h-3a1 1 0 000 2h3a1 1 0 000-2zM15.5 2h2.25a.25.25 0 01.25.25V4.5a1 1 0 102 0V1a1 1 0 00-1-1h-3.5a1 1 0 000 2zM19 7.5a1 1 0 00-1 1v3a1 1 0 002 0v-3a1 1 0 00-1-1zM11.5 18h-3a1 1 0 000 2h3a1 1 0 000-2zM23.43 18.075l-7.93-3.968a1.033 1.033 0 00-1.386 1.385l3.969 7.937A1.033 1.033 0 0020 23.216l.605-2.425a.251.251 0 01.182-.182L23.218 20a1.033 1.033 0 00.212-1.926z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});