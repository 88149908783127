define("ember-svg-jar/inlined/yoga-stretch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>yoga-stretch</title><circle cx=\"6.75\" cy=\"8\" r=\"2.5\"/><path d=\"M13.5 14.039a.249.249 0 00.046.144 5.21 5.21 0 01.713 1.355 2.235 2.235 0 01-.122 1.718 2.211 2.211 0 01-.549.694.248.248 0 00-.088.19v3.61a1.25 1.25 0 002.5 0v-5.918a.5.5 0 011 0v5.918a1.25 1.25 0 002.5 0V9.206a.248.248 0 01.062-.164l4.128-4.719a1.25 1.25 0 10-1.881-1.646l-4.145 4.738a.251.251 0 01-.188.085h-1.952a.25.25 0 01-.188-.085L11.19 2.677a1.25 1.25 0 00-1.881 1.646l4.129 4.719a.248.248 0 01.062.164z\"/><path d=\"M.838 17.418a1.213 1.213 0 00.409.07 1.267 1.267 0 001.182-.823 3.1 3.1 0 011.182-1.342.25.25 0 01.389.208v1.824a.249.249 0 01-.183.24 2.23 2.23 0 00-1.233.865 2.287 2.287 0 00-.232 2.153l.762 1.658A1.249 1.249 0 004.251 23a1.234 1.234 0 00.52-.114 1.25 1.25 0 00.615-1.657l-.4-.874A.25.25 0 015.209 20h3.08a.25.25 0 01.227.354l-.4.875a1.25 1.25 0 102.272 1.042l.8-1.75a2.23 2.23 0 00-1.5-2.926.249.249 0 01-.182-.241v-1.741a.25.25 0 01.4-.2 3.032 3.032 0 011.048 1.251 1.249 1.249 0 001.18.838 1.265 1.265 0 00.395-.064 1.249 1.249 0 00.79-1.582C12.677 13.932 10.261 12 6.687 12 3.135 12 .7 13.915.064 15.854a1.24 1.24 0 00.774 1.564zM14.923 5.424a2.447 2.447 0 003.154 0 2.526 2.526 0 00.552-.631 2.5 2.5 0 10-3.706.631z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});