define("ember-svg-jar/inlined/social-media-formspring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-formspring</title><path d=\"M14.51 14.93a9.93 9.93 0 003.43-.6.75.75 0 10-.52-1.41 8.43 8.43 0 01-2.91.5c-2.33 0-4.46-.9-5.42-2.31a.75.75 0 00-1.24.89c1.26 1.79 3.81 2.93 6.66 2.93zM14.51 3.1A9 9 0 009.1 4.77.75.75 0 1010 6a7.53 7.53 0 014.5-1.36c3.21 0 5.92 1.76 5.92 3.84v.17a.75.75 0 00.7.79.75.75 0 00.75-.7v-.26c.06-2.99-3.27-5.38-7.36-5.38zM12.29 2.56a6.17 6.17 0 013.31-.89 5.86 5.86 0 013.8 1.26.75.75 0 001-1.12A7.29 7.29 0 0015.61.17a7.68 7.68 0 00-4.13 1.13.75.75 0 00.81 1.26z\"/><path d=\"M6.9 13.55a.75.75 0 00-1.43.45c.78 2.53 3.93 4.29 7.67 4.29 4.4 0 7.84-2.4 7.84-5.46 0-4-5.36-5.73-9.16-5.73-5.29 0-9.75 3.56-9.75 7.78a7.51 7.51 0 003.56 6.18 10.48 10.48 0 009.42 1.06l4.88 1.67a.75.75 0 00.9-1.07l-1.62-3a7.3 7.3 0 001.28-1.41.75.75 0 10-1.24-.85A6.12 6.12 0 0117.81 19a.75.75 0 00-.22 1l1 1.82-3.31-1.13a.74.74 0 00-.52 0 9 9 0 01-8.28-.81 6 6 0 01-2.9-4.94c0-3.35 3.85-6.28 8.25-6.28 3.09 0 7.66 1.34 7.66 4.23 0 2.15-2.91 4-6.34 4-3.05-.11-5.67-1.47-6.25-3.34z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});