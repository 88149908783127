define("ember-svg-jar/inlined/tools-palette-trowel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-palette-trowel</title><path d=\"M9.2 6.765c.011-.01.026-.013.036-.024l.9-.9a1 1 0 00-1.411-1.414l-.03.029a.5.5 0 01-.744-.042L5.163.918a3.026 3.026 0 00-4.247 0A3.031 3.031 0 00.96 5.2l3.451 2.753a.5.5 0 01.042.744l-.249.25a1 1 0 101.414 1.414l1.12-1.12c.011-.011.014-.025.024-.036zM23.509 14.366a1.5 1.5 0 00-.072-2.189C19.43 8.661 14.206 7.39 11.449 6.945a.5.5 0 00-.433.14l-3.933 3.933a.5.5 0 00-.14.433c.445 2.758 1.716 7.982 5.231 11.989a1.513 1.513 0 001.079.51h.047a1.505 1.505 0 001.061-.439z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});