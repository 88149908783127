define("ember-svg-jar/inlined/video-game-mario-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-mario-1</title><path d=\"M14.719 19.212a.557.557 0 00-.069-.745l-1.36-1.225a.751.751 0 011-1.116l4.131 3.723A1.984 1.984 0 0119 21.25a1.849 1.849 0 01-.078.536l-.009.031a.744.744 0 00.123.616l.016.02a.734.734 0 00.6.3h1.6A2.253 2.253 0 0023.5 20.5v-1.46a2.273 2.273 0 00-.314-1.135l-3.4-5.78A.247.247 0 0120 11.75a3.995 3.995 0 00.409-7.969 1 1 0 01-.714-.434 4.762 4.762 0 00-3.945-2.1 3.576 3.576 0 00-3.694 3.083.5.5 0 01-.492.417H11a5.006 5.006 0 00-5 5 4.828 4.828 0 00.031.55.468.468 0 01-.385.514 3.822 3.822 0 00-2.054 1.041A3.758 3.758 0 002.5 14.5a3.7 3.7 0 00.667 2.128.5.5 0 01-.042.624L.348 20.29a.584.584 0 00-.032.038A1.531 1.531 0 000 21.25a1.5 1.5 0 001.5 1.5H5a1 1 0 001-1 .921.921 0 00-.2-.587.357.357 0 00-.049-.054l-.151-.143a.5.5 0 01.068-.778l3.506-2.337a.5.5 0 01.358-.078A1.752 1.752 0 0111 19.5V22a.742.742 0 00.75.75h3.29a.957.957 0 00.677-1.637l-.934-.933a.735.735 0 01-.064-.968zM17 9.75a2.482 2.482 0 01-1.356-.4A1.741 1.741 0 0115 8a2 2 0 01.627-1.472A1 1 0 0016 5.75V3.878a.5.5 0 01.666-.472 2.744 2.744 0 011.681 1.676 1 1 0 00.943.668H20a2 2 0 010 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});