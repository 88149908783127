define("ember-svg-jar/inlined/professions-woman-chef-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-woman-chef-3</title><path d=\"M3.347 15.9a.5.5 0 00-.327.337.5.5 0 00.1.46 3.8 3.8 0 002.921 1.119 5.351 5.351 0 001.317-.161 3.149 3.149 0 001.272-.623.25.25 0 01.278-.032 6.442 6.442 0 006.145.022.248.248 0 01.284.034 3.092 3.092 0 001.309.656 5.351 5.351 0 001.317.161 3.8 3.8 0 002.921-1.12.5.5 0 00-.231-.8c-2.591-.833-2.153-4.358-2.153-6.5a6.412 6.412 0 00-.327-2.012.251.251 0 01.071-.265 2.909 2.909 0 001.006-2.17 2.993 2.993 0 00-1.45-2.537A3.472 3.472 0 0015.5 2a.252.252 0 01-.249-.117 3.889 3.889 0 00-6.5 0A.25.25 0 018.5 2a3.468 3.468 0 00-2.3.472 3 3 0 00-1.45 2.534 2.91 2.91 0 001.006 2.175.251.251 0 01.071.265A6.412 6.412 0 005.5 9.458c0 1.882.456 5.603-2.153 6.442zM7.5 11.292V10.2a.248.248 0 01.081-.184.253.253 0 01.189-.066c1.406.113 2.817.178 4.23.178s2.824-.065 4.23-.178a.253.253 0 01.189.066.248.248 0 01.081.184v1.1a4.5 4.5 0 11-9 0zM.5 23.375a.5.5 0 00.5.5h6a.25.25 0 00.25-.25v-4.481a.253.253 0 00-.094-.2.25.25 0 00-.211-.048 14.02 14.02 0 00-5.478 2.392A2.464 2.464 0 00.5 23.269zM8.75 23.625a.25.25 0 00.25.25h14a.5.5 0 00.5-.5v-.106a2.464 2.464 0 00-.967-1.977C21.106 20.2 17.84 18.375 12 18.375a24.67 24.67 0 00-3.031.181.249.249 0 00-.219.248zm3-2a1 1 0 11-1-1 1 1 0 011 1z\"/><circle cx=\"14\" cy=\"11.625\" r=\"1\"/><circle cx=\"10\" cy=\"11.625\" r=\"1\"/><path d=\"M13.077 13.737h-2a.639.639 0 00-.3 1.2 2.31 2.31 0 00.435.18 2.99 2.99 0 00.867.126h.006a2.976 2.976 0 00.863-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});