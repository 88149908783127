define("ember-svg-jar/inlined/religion-taoism", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-taoism</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 2a9.981 9.981 0 018.486 15.266.25.25 0 01-.089.085A4.852 4.852 0 0118 18C8 18 16 6 6 6a5.292 5.292 0 00-1.606.252.25.25 0 01-.272-.393A9.976 9.976 0 0112 2zm-7.5 9a2 2 0 112 2 2 2 0 01-2-2z\"/><circle cx=\"17.5\" cy=\"12.999\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});