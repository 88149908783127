define("ember-svg-jar/inlined/laboratory-test-blood-finger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laboratory-test-blood=finger</title><path d=\"M20.088 14.966C19.74 15.471 18 18.051 18 19.25a2.5 2.5 0 005 0c0-1.2-1.74-3.779-2.088-4.284a.522.522 0 00-.824 0zM16.5 12.974h-3a.5.5 0 010-1H21a2 2 0 000-4H9.922a.25.25 0 01-.145-.454l2.346-1.668a1.989 1.989 0 00-.806-3.568 1.958 1.958 0 00-1.5.334l-8.4 5.951A1 1 0 001 9.382V18.1a1 1 0 00.725.961l5.292 1.516a10.757 10.757 0 002.883.4h2.6a2.488 2.488 0 002.292-1.53.5.5 0 00-.461-.694H12.5a.5.5 0 010-1h2.756A1.484 1.484 0 0016 16.474v-.224a.5.5 0 00-.5-.5h-2a.5.5 0 010-1h2.756A1.484 1.484 0 0017 13.474a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});