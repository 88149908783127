define("ember-svg-jar/inlined/bandage-finger-bleed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bandage-finger-bleed</title><path d=\"M20 10.224a2 2 0 000-4H8.923a.25.25 0 01-.145-.453L11.12 4.1a1.985 1.985 0 00-.8-3.569 1.929 1.929 0 00-1.5.329L.42 6.814a1.014 1.014 0 00-.42.82v8.71a1 1 0 00.72.96l5.3 1.52a10.534 10.534 0 002.88.4h2.6a2.505 2.505 0 002.308-1.524.5.5 0 00-.461-.7H11.5a.5.5 0 01-.5-.5.5.5 0 01.5-.5h2.77a1.487 1.487 0 00.73-1.28v-.22a.5.5 0 00-.5-.5h-2a.5.5 0 01-.5-.5.5.5 0 01.5-.5h2.77a1.487 1.487 0 00.73-1.28.5.5 0 00-.5-.5h-3a.5.5 0 010-1zM24 20a3.5 3.5 0 01-7 0c0-1.666 2.6-4.545 3.318-5.309a.25.25 0 01.182-.079.247.247 0 01.182.079C21.4 15.455 24 18.334 24 20z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});