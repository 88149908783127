define("ember-svg-jar/inlined/sea-transport-small-boat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sea-transport-small-boat</title><path d=\"M3.314 8.742h2.7a.5.5 0 01.49.62l-.756 2.815a.25.25 0 00.242.315h14.221a.25.25 0 00.186-.417l-1.339-1.5a1 1 0 00-.746-.333h-6.293a1.586 1.586 0 01-1.129-.47l-.346-.53a.4.4 0 01.28-.681h6.07a.125.125 0 00.094-.208L15.86 7a1.979 1.979 0 00-1.571-.759h-.469a.247.247 0 01-.175-.072L8.969 1.532a1 1 0 10-1.408 1.42l2.878 2.853a.25.25 0 01-.176.428l-5.469-.011a.507.507 0 00-.38.18l-1.48 1.51a.5.5 0 00.38.83zM.839 18.939a2.316 2.316 0 01.491.014c.609.085 1.552-.745 1.821-1.086a2.5 2.5 0 014.09.226 2.019 2.019 0 001.412.832 2.688 2.688 0 001.831-1 2.58 2.58 0 012-1.006 2.507 2.507 0 012.261 1.425 1.175 1.175 0 00.982.585 1.788 1.788 0 001.374-.759 2.5 2.5 0 012.166-1.251 2.474 2.474 0 011.755.744.25.25 0 00.39-.045l1.776-2.858a.5.5 0 00-.425-.763H3.125a.987.987 0 00-.938.654L.588 18.6a.25.25 0 00.251.343z\"/><path d=\"M23.991 21.559a1 1 0 00-1.123-.859c-1.631.211-2.739-1.5-2.747-1.513a1.043 1.043 0 00-1.719.028 3.268 3.268 0 01-2.675 1.51 2.725 2.725 0 01-2.336-1.44 1.043 1.043 0 00-1.717-.156 4.2 4.2 0 01-3.024 1.6 3.462 3.462 0 01-2.686-1.539 1.043 1.043 0 00-1.636-.093c-.015.019-1.518 1.859-3.2 1.648a1 1 0 00-.26 1.982 4 4 0 00.542.036 5.6 5.6 0 003.481-1.423.251.251 0 01.328.006 5.09 5.09 0 003.431 1.379 5.684 5.684 0 003.557-1.325.249.249 0 01.33.016 4.47 4.47 0 003.19 1.306 5.034 5.034 0 003.381-1.3.248.248 0 01.333 0 4.553 4.553 0 003.691 1.26 1 1 0 00.859-1.123z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});