define("ember-svg-jar/inlined/primitive-symbols-woman-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>primitive-symbols-woman-1</title><path d=\"M15 6H9a3 3 0 00-3 3v5a1 1 0 002 0V9a.982.982 0 01.273-.681.28.28 0 01.479.1l.988 3.045a1.222 1.222 0 01-.039.511L8.376 15.29a2.158 2.158 0 00-.126.46v7a1 1 0 002 0v-6a.25.25 0 01.25-.25h3a.25.25 0 01.25.25v6a1 1 0 002 0v-7a2.126 2.126 0 00-.127-.461l-1.309-3.274a1.287 1.287 0 01-.054-.554s.908-2.831 1-3.068a.268.268 0 01.434-.111A.985.985 0 0116 9v5a1 1 0 002 0V9a3 3 0 00-3-3z\"/><circle cx=\"12\" cy=\"2.75\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});