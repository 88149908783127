define("ember-svg-jar/inlined/bathroom-robe-female", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bathroom-robe-female</title><path d=\"M6.272 10.642a.5.5 0 00.012-.4L4.257 5.166a4.443 4.443 0 01-.225-.735.25.25 0 00-.485-.016 7.938 7.938 0 00-.22 1L3.1 6.877c-.533 3.492-1.137 7.45-1.562 9.335a1.644 1.644 0 001.043 1.93l.909.315a.243.243 0 00.21-.022.252.252 0 00.119-.176 27.359 27.359 0 012.453-7.617zM22.458 16.212c-.425-1.885-1.029-5.843-1.562-9.335l-.223-1.46a8.173 8.173 0 00-.22-1 .25.25 0 00-.485.017 4.589 4.589 0 01-.226.739l-2.026 5.076a.5.5 0 00.012.4 27.393 27.393 0 012.45 7.617.25.25 0 00.329.2l.908-.315a1.644 1.644 0 001.043-1.939zM17.041 11.544a.5.5 0 00-.456-.294H15a.25.25 0 00-.25.25V16a.75.75 0 01-1.5 0v-4.5a.25.25 0 00-.25-.25h-1a.25.25 0 00-.25.25V13a.75.75 0 01-1.5 0v-1.5a.25.25 0 00-.25-.25H7.415a.5.5 0 00-.456.294A24.93 24.93 0 004.5 22.49a1.445 1.445 0 00.466 1.073A1.6 1.6 0 006.07 24h11.86a1.582 1.582 0 001.094-.428 1.454 1.454 0 00.476-1.086 24.922 24.922 0 00-2.459-10.942zM7.037 9.435a.5.5 0 00.463.315h4.72a.25.25 0 00.224-.362L8.862 2.224a.5.5 0 01.447-.724h5.2a.5.5 0 01.4.8L12.4 5.678a.5.5 0 00-.046.521l1.637 3.275a.5.5 0 00.447.276H16.5a.5.5 0 00.464-.315l1.85-4.635a3.47 3.47 0 00-.355-3.263A3.59 3.59 0 0017.906.9 3.623 3.623 0 0015.42 0H8.58a3.516 3.516 0 00-3.043 1.538A3.467 3.467 0 005.186 4.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});