define("ember-svg-jar/inlined/common-file-text-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-text-heart</title><path d=\"M20 7.5a1 1 0 00-1-1h-7a1 1 0 000 2h7a1 1 0 001-1z\"/><path d=\"M24 4.415A2 2 0 0023.414 3L21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v9.51a.292.292 0 00.115.234c.082.048.168.091.249.141a.37.37 0 00.3-.026 5.01 5.01 0 011.169-.559.242.242 0 00.167-.23V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.354.146l2.121 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-7.53a.254.254 0 00-.208.111c-.237.334-.844 1.027-1.243 1.474a.249.249 0 00.189.414H22a2 2 0 002-2z\"/><path d=\"M12.5 11a.969.969 0 00-.746.357.157.157 0 00.054.229 5.033 5.033 0 011.6 1.321.242.242 0 00.189.093H16.5a1 1 0 000-2zM12.628 14.449a3.526 3.526 0 00-5.646-.916.681.681 0 01-.963 0 3.486 3.486 0 00-3.058-.987 3.525 3.525 0 00-1.935 5.966l5.113 5.334a.5.5 0 00.723 0l5.106-5.327a3.524 3.524 0 00.66-4.07z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});