define("ember-svg-jar/inlined/headphones-customer-support-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>headphones-customer-support-question</title><path d=\"M24 14a4.493 4.493 0 00-3-4.239V9A9 9 0 003 9v.761a4.5 4.5 0 000 8.483 1.5 1.5 0 002-1.414V9a7 7 0 1114 0v7.83a1.494 1.494 0 00.5 1.1v.57c0 1.474-1.291 2-2.5 2h-1.778a2 2 0 100 2H17c2.649 0 4.5-1.645 4.5-4v-.478A4.5 4.5 0 0024 14z\"/><circle cx=\"12\" cy=\"16.751\" r=\"1.25\"/><path d=\"M9.5 10.5a1.25 1.25 0 001.25-1.25 1.25 1.25 0 111.75 1.15 2.918 2.918 0 00-1.749 2.673v.555a1.25 1.25 0 002.5 0v-.555a.417.417 0 01.25-.383A3.75 3.75 0 108.25 9.251 1.25 1.25 0 009.5 10.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});