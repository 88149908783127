define("ember-svg-jar/inlined/room-service-food", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>room-service-food</title><path d=\"M15.5 3.5V4a2.5 2.5 0 005 0v-.5zM20.655 2.292a.5.5 0 00.069-.45l-.5-1.5A.5.5 0 0019.75 0h-3.5a.5.5 0 00-.475.342l-.5 1.5a.5.5 0 00.475.658h4.5a.5.5 0 00.405-.208zM12.25 16.249a1 1 0 00-1 1V19H1a1 1 0 000 2h10.25a2 2 0 002-2v-1.75a1 1 0 00-1-1.001z\"/><circle cx=\"2.5\" cy=\"22.499\" r=\"1.5\"/><circle cx=\"9\" cy=\"22.499\" r=\"1.5\"/><path d=\"M1.5 17.749H9a1 1 0 001-1v-5a1 1 0 00-1-1H7.5v-.5a2.25 2.25 0 00-4.5 0v.5H1.5a1 1 0 00-1 1v5a1 1 0 001 1zm3-7.5a.75.75 0 011.5 0v.5H4.5zM23.962 22.162l-3-13a1.5 1.5 0 00-1.215-1.142c-1.1-.174-1.509.668-1.882 1.267a6.951 6.951 0 01-5.052 3.224 1.5 1.5 0 00.373 2.977 9.94 9.94 0 005.247-2.313.246.246 0 01.24-.047.249.249 0 01.166.181l.282 1.221a.252.252 0 01-.081.246l-1.84 1.579a5.493 5.493 0 00-1.876 3.492l-.308 2.466A1.515 1.515 0 0016.5 24a1.5 1.5 0 001.486-1.313l.309-2.467a2.506 2.506 0 01.852-1.588l.767-.657 1.122 4.862a1.5 1.5 0 002.924-.674z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});