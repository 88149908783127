define("ember-svg-jar/inlined/material-tile-roof-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>material-tile-roof-1</title><path d=\"M17.423 3.353a.251.251 0 00-.407 0 5.769 5.769 0 01-3.684 2.377.25.25 0 00-.18.362 4.5 4.5 0 003.93 2.527 4.5 4.5 0 003.957-2.578.25.25 0 00-.169-.357 5.764 5.764 0 01-3.447-2.331zM16.4 10.412a.251.251 0 00-.183-.362 5.989 5.989 0 01-3.877-2.426.25.25 0 00-.4 0 6.049 6.049 0 01-3.3 2.305.25.25 0 00-.161.35 4.514 4.514 0 003.995 2.656 4.5 4.5 0 003.926-2.523zM7.726 11.94a.251.251 0 00-.405 0 5.9 5.9 0 01-4.742 2.5.2.2 0 00-.175.3 5 5 0 008.757-.139.25.25 0 00-.155-.359 6.054 6.054 0 01-3.28-2.302zM11.192 5.956a.251.251 0 00-.158-.349A5.855 5.855 0 017.9 3.353a.25.25 0 00-.407 0 5.726 5.726 0 01-4.01 2.422.25.25 0 00-.194.366 4.488 4.488 0 003.905 2.478 4.517 4.517 0 003.998-2.663zM17.613 11.94a.25.25 0 00-.4 0 5.988 5.988 0 01-3.843 2.421.25.25 0 00-.176.371 5 5 0 008.665.034.252.252 0 00.008-.237.249.249 0 00-.193-.137 5.97 5.97 0 01-4.061-2.452zM18.522 9.929a.25.25 0 00-.16.351 4.584 4.584 0 003.455 2.62.25.25 0 00.281-.285L21.5 8.62a.251.251 0 00-.42-.144 5.921 5.921 0 01-2.558 1.453zM6.645 1.865a.25.25 0 00-.222-.365H4.861a1.509 1.509 0 00-1.483 1.277L3.15 4.3a4.319 4.319 0 003.495-2.435zM2.579 12.935a4.493 4.493 0 003.928-2.523.251.251 0 00-.183-.362 5.9 5.9 0 01-3.358-1.805.25.25 0 00-.429.134L1.9 12.622a.25.25 0 00.048.189.254.254 0 00.17.1 4.145 4.145 0 00.461.024z\"/><path d=\"M17.532 18.75a6.537 6.537 0 01-5.195-2.594.25.25 0 00-.4 0 6.5 6.5 0 01-10.187.256.249.249 0 00-.438.124l-.557 3.722a.5.5 0 00.089.366 4.5 4.5 0 007.406-.137 4.5 4.5 0 007.5 0 4.5 4.5 0 007.406.137.5.5 0 00.089-.366l-.519-3.458a.249.249 0 00-.173-.2.251.251 0 00-.257.068 6.527 6.527 0 01-4.764 2.082zM12.455 4.305a4.28 4.28 0 003.718-2.44.246.246 0 00-.008-.245.25.25 0 00-.213-.12H8.959a.25.25 0 00-.222.365 4.283 4.283 0 003.718 2.44zM20.824 4.119l-.2-1.342A1.509 1.509 0 0019.139 1.5h-.651a.25.25 0 00-.222.365 4.512 4.512 0 002.558 2.254z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});