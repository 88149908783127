define("ember-svg-jar/inlined/single-man-focus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-focus</title><path d=\"M7.7 22l-5.2.039a.5.5 0 01-.5-.5v-5.234a1 1 0 00-2 0v5.738A1.959 1.959 0 001.957 24H7.7a1 1 0 000-2zM1 8.7a1 1 0 001-1l-.04-5.2a.5.5 0 01.5-.5H7.7a1 1 0 000-2H1.957A1.959 1.959 0 000 1.957V7.7a1 1 0 001 1zM23 15.305a1 1 0 00-1 1l.04 5.191a.5.5 0 01-.5.5H16.3a1 1 0 100 2h5.739A1.959 1.959 0 0024 22.043v-5.738a1 1 0 00-1-1zM22.043 0H16.3a1 1 0 000 2l5.2-.039a.5.5 0 01.5.5V7.7a1 1 0 002 0V1.957A1.959 1.959 0 0022.043 0zM12 13.5A4.5 4.5 0 107.5 9a4.505 4.505 0 004.5 4.5zm0-2A2.5 2.5 0 019.5 9a2.371 2.371 0 01.1-.688.5.5 0 01.7-.3 6.929 6.929 0 003.111.741 7.03 7.03 0 00.758-.042.285.285 0 01.228.074A.289.289 0 0114.5 9a2.5 2.5 0 01-2.5 2.5zM6.055 17.772a.5.5 0 00.445.728h11a.5.5 0 00.445-.728A6.677 6.677 0 0012 14.259a6.677 6.677 0 00-5.945 3.513z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});