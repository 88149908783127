define("ember-svg-jar/inlined/cursor-hand-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-hand-1-alternate</title><path d=\"M21.862 11.5a3 3 0 00-3-3h-.268a2 2 0 00-1.732-1h-1.268a2 2 0 00-1.732-1h-1V3a2.5 2.5 0 00-5 0v10.3l-1.213-1.7A2.485 2.485 0 002.6 14.47l6.1 8.606a1 1 0 101.631-1.157l-6.095-8.6a.485.485 0 01.791-.563l2.12 2.976a1.5 1.5 0 002.721-.871V3a.5.5 0 011 0v7.5a1 1 0 002 0V8.747a.25.25 0 01.25-.25h.5a.249.249 0 01.25.25V10.5a1 1 0 002 0v-.75a.25.25 0 01.25-.25h.5a.249.249 0 01.25.25v1.75a1 1 0 002 0v-.71a.251.251 0 01.106-.2.248.248 0 01.228-.031 1 1 0 01.666.942v2.6a9.012 9.012 0 01-.346 2.474L17.9 22.223a1 1 0 001.923.549l1.616-5.654a11.042 11.042 0 00.422-3.022z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});