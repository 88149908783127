define("ember-svg-jar/inlined/messaging-kik-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messaging-kik-1-alternate</title><path d=\"M11.45 12a.25.25 0 01.05-.34l1.61-1.25a1 1 0 00-1.23-1.58L9.4 10.8a.25.25 0 01-.4-.2V5.65a1 1 0 10-2 0v10.5a1 1 0 102 0v-2.38a.25.25 0 01.1-.2l.43-.34a.25.25 0 01.36.06l2.28 3.42a1 1 0 101.66-1.11z\"/><circle cx=\"16.5\" cy=\"12.65\" r=\"1.5\"/><path d=\"M12 .9C5.25.9 0 5.45 0 11s5.25 10.17 12 10.17a12.84 12.84 0 003.72-.54L20.77 23a1.25 1.25 0 001.65-1.69l-1.68-3.36A9.18 9.18 0 0024 11C24 5.45 18.75.9 12 .9zm7.33 16.25a.75.75 0 00-.2.92l1.3 2.6a.25.25 0 01-.33.34l-4-1.87a.74.74 0 00-.32-.07.76.76 0 00-.22 0 11.41 11.41 0 01-3.56.56C6.07 19.67 1.5 15.8 1.5 11S6.07 2.4 12 2.4 22.5 6.28 22.5 11a7.83 7.83 0 01-3.17 6.15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});