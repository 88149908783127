define("ember-svg-jar/inlined/medical-specialty-optometrist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-specialty-optometrist</title><path d=\"M12 17.75a.584.584 0 00-.583.583v1.334a.583.583 0 001.166 0v-1.334A.584.584 0 0012 17.75z\"/><path d=\"M23 1.493A1.5 1.5 0 0021.5 0h-19A1.5 1.5 0 001 1.493v21.014A1.5 1.5 0 002.5 24h19a1.5 1.5 0 001.5-1.493zM9.25 4.5a2.253 2.253 0 012.25-2.25H14a.75.75 0 010 1.5h-2.5a.751.751 0 00-.75.75v.75a.5.5 0 00.5.5h1.25a.75.75 0 010 1.5h-1.25a.5.5 0 00-.5.5v.75a.751.751 0 00.75.75H14a.75.75 0 010 1.5h-2.5A2.253 2.253 0 019.25 8.5zM6 12.75h12a.75.75 0 010 1.5H6a.75.75 0 010-1.5zm.667 5h-.084a.5.5 0 00-.5.5V21a.75.75 0 11-1.5 0v-2.75a.5.5 0 00-.5-.5H4a.75.75 0 010-1.5h2.667a.75.75 0 110 1.5zm7.416 1.917a2.083 2.083 0 11-4.166 0v-1.334a2.083 2.083 0 014.166 0zM20 20.25a.75.75 0 010 1.5h-2a1.417 1.417 0 01-.85-2.551l1.333-1a.25.25 0 00-.15-.45h-1a.75.75 0 010-1.5h2a1.417 1.417 0 01.85 2.551l-1.333 1a.25.25 0 00.15.45z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});