define("ember-svg-jar/inlined/arrow-circle-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-circle-right</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm4.961 13.358l-7.346 5.634a1.25 1.25 0 11-1.521-1.984l6.271-4.81a.249.249 0 000-.4L8.094 6.992a1.25 1.25 0 111.521-1.984l7.346 5.634a1.711 1.711 0 010 2.716z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});