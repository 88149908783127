define("ember-svg-jar/inlined/fruit-banana", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fruit-banana</title><path d=\"M21.188 15.57a.5.5 0 01-.13-.6c2.414-5.086 3.705-11.88 1.829-13.935a3.181 3.181 0 00-5.307.979L14.8 9.06a13.876 13.876 0 01-.575 1.268.5.5 0 01-.317.254.5.5 0 01-.4-.065 5.622 5.622 0 00-8.595 3.636.51.51 0 00.829.491 5.209 5.209 0 014.367-1.135 5.1 5.1 0 01.8.224.5.5 0 01.16.841 15.42 15.42 0 01-6.994 3.581l-2.409.523a1.849 1.849 0 00-.192 3.561 16.449 16.449 0 005.2.849 16.3 16.3 0 008.94-2.679 16.392 16.392 0 002.236-1.759.5.5 0 01.629-.044 5.222 5.222 0 012.193 4.832.509.509 0 00.29.513.514.514 0 00.583-.1 5.615 5.615 0 000-7.944q-.168-.18-.357-.337zM19.48 2.763a1.178 1.178 0 011.9-.35c.861.943.268 6.632-2.154 11.681a.5.5 0 01-.547.275 5.665 5.665 0 00-2.179 0 .5.5 0 01-.584-.381 5.561 5.561 0 00-.444-1.252.5.5 0 01.021-.482A15.843 15.843 0 0016.7 9.808z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});