define("ember-svg-jar/inlined/library-sports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>library-sports</title><path d=\"M14.649 12.021a4.072 4.072 0 01-1.827-6.6.25.25 0 00-.187-.421H1.5A1.5 1.5 0 000 6.5v16A1.5 1.5 0 001.5 24h12a1.5 1.5 0 001.5-1.5v-10a.5.5 0 00-.351-.479zM5 22a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5zm4.828.276l-1.428.469a.5.5 0 01-.631-.319l-1.247-3.8a.5.5 0 01.319-.631l1.425-.468a.5.5 0 01.632.32l1.246 3.8a.5.5 0 01-.316.63zM13 15.251H2a.75.75 0 110-1.5h.5a.5.5 0 00.5-.5V8.5a.5.5 0 01.5-.5H5a.5.5 0 01.5.5v4.75a.5.5 0 00.5.5h.5a.5.5 0 00.5-.5V10.5a.5.5 0 01.5-.5H9a.5.5 0 01.5.5v2.75a.5.5 0 00.5.5h3a.75.75 0 010 1.5z\"/><path d=\"M23.941 6.313a1.973 1.973 0 00-.985-1.413.379.379 0 01-.2-.406 2.648 2.648 0 00-.824-2.43 2.649 2.649 0 00-2.43-.824.381.381 0 01-.406-.2 1.978 1.978 0 00-1.408-.98 1.979 1.979 0 00-1.6.265 2.485 2.485 0 00-.956 1.713l-.75 3.762a.5.5 0 01-.137.255l-.216.217a2.619 2.619 0 003.7 3.7l.216-.217a.505.505 0 01.256-.137l3.758-.751a2.485 2.485 0 001.713-.956 1.978 1.978 0 00.269-1.598zM16.5 3.891a.249.249 0 01-.152-.281l.263-1.31c.117-.79.408-.848.758-.771.563.119.512.576.328.865l-.892 1.4a.252.252 0 01-.305.097zm1.09 3.194a.487.487 0 01-.673-.673l1.951-3.066a1.3 1.3 0 111.788 1.788zm4.115.3l-1.313.263a.252.252 0 01-.281-.152.249.249 0 01.1-.3l1.4-.892c.29-.185.746-.236.866.328.076.35.017.641-.772.758z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});