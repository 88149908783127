define("ember-svg-jar/inlined/brightness", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>brightness</title><circle cx=\"12\" cy=\"12.001\" r=\"4.5\"/><path d=\"M12 5.827a1 1 0 001-1V1a1 1 0 00-2 0v3.827a1 1 0 001 1zM12 18.175a1 1 0 00-1 1V23a1 1 0 102 0v-3.825a1 1 0 00-1-1zM6.219 7.636a1.018 1.018 0 001.414 0 1 1 0 000-1.414l-2.7-2.707A1 1 0 003.515 4.93zM17.779 16.366a1 1 0 00-1.414 1.414l2.706 2.707a1.017 1.017 0 001.414 0 1 1 0 000-1.414zM5.826 12.5a1 1 0 00-1-1H1a1 1 0 000 2h3.826a1 1 0 001-1zM23 11.5h-3.826a1 1 0 000 2H23a1 1 0 000-2zM6.219 16.366l-2.7 2.707a1 1 0 000 1.414 1.017 1.017 0 001.414 0l2.7-2.706a1 1 0 00-1.414-1.414zM17.072 7.923a1 1 0 00.707-.287l2.706-2.706a1 1 0 00-1.414-1.415l-2.706 2.707a.994.994 0 00.707 1.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});