define("ember-svg-jar/inlined/diagram-arrow-down-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-arrow-down-1</title><path d=\"M24 1a1 1 0 00-1-1H1a1 1 0 00-1 1v3a1 1 0 001 1h7.75a.25.25 0 01.25.25v9.715a.25.25 0 01-.25.25H5.5a1 1 0 00-.733 1.685l6.159 6.631a1.457 1.457 0 002.147 0l6.16-6.633a1 1 0 00-.733-1.681h-3.25a.25.25 0 01-.25-.25V5.25a.25.25 0 01.25-.25H23a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});