define("ember-svg-jar/inlined/party-balloons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>party-balloons</title><path d=\"M7.7 11.439a4.211 4.211 0 001.822-.8.25.25 0 00.078-.3A6.909 6.909 0 019 7.5a6.809 6.809 0 011.324-4.068.251.251 0 00-.008-.308A4.3 4.3 0 007 1.5a4.775 4.775 0 00-4.5 5 4.9 4.9 0 003.679 4.916l-.726 1.362a.758.758 0 00-.024.64.5.5 0 00.441.33H8a.5.5 0 00.441-.33.765.765 0 00-.008-.608c-.007-.01.067.125-.733-1.371zM8.433 15.408a.986.986 0 00-1.094-.9 1 1 0 00-.9 1.093 1.472 1.472 0 01-.424 1.189 3.478 3.478 0 000 4.914 1 1 0 101.414-1.414 1.476 1.476 0 010-2.085 3.466 3.466 0 001.004-2.797z\"/><circle cx=\"21.5\" cy=\"18.5\" r=\"1\"/><circle cx=\"11.5\" cy=\"20.5\" r=\"1\"/><circle cx=\"22.5\" cy=\"7.5\" r=\"1\"/><circle cx=\"1.5\" cy=\"2\" r=\"1\"/><circle cx=\"3\" cy=\"16.5\" r=\"1\"/><path d=\"M19.5 7.5a4.775 4.775 0 00-4.5-5 4.775 4.775 0 00-4.5 5 4.876 4.876 0 003.8 4.939l-.847 1.589a.759.759 0 00-.025.642.5.5 0 00.441.33H16a.5.5 0 00.441-.33.762.762 0 00-.025-.642l-.837-1.569A4.859 4.859 0 0019.5 7.5zM16.008 16.6a1 1 0 10-1.99-.2 3.46 3.46 0 001 2.807 1.475 1.475 0 010 2.084 1 1 0 001.414 1.416 3.478 3.478 0 000-4.915 1.466 1.466 0 01-.424-1.192z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});