define("ember-svg-jar/inlined/love-it-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it-share</title><path d=\"M9.256 17.751a4.045 4.045 0 01.113-.912.25.25 0 00-.06-.226l-5.975-6.471a4.422 4.422 0 01-.849-5.16 4.5 4.5 0 013.329-2.42 4.578 4.578 0 013.994 1.284l1.531 1.4a1 1 0 001.347 0l1.562-1.423a4.549 4.549 0 013.965-1.257 4.5 4.5 0 013.329 2.42 4.152 4.152 0 01.032 3.752.25.25 0 000 .223.255.255 0 00.18.132 4.19 4.19 0 011.331.466.252.252 0 00.2.019.256.256 0 00.149-.136 6.158 6.158 0 00-.111-5.36 6.59 6.59 0 00-10.455-1.71l-.859.783-.825-.755A6.526 6.526 0 005.492.589 6.472 6.472 0 00.7 4.078a6.4 6.4 0 001.2 7.454l6.924 7.5a.25.25 0 00.433-.17z\"/><path d=\"M21.006 18a2.735 2.735 0 00-2 .874l-2.753-1.1v-.022a2.742 2.742 0 00-.046-.456l3.125-1.875a2.724 2.724 0 001.674.579 2.8 2.8 0 10-2.7-2.294l-3.125 1.875A2.719 2.719 0 0013.506 15a2.75 2.75 0 102 4.627l2.753 1.1v.022A2.75 2.75 0 1021.006 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});