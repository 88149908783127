define("ember-svg-jar/inlined/monetization-idea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monetization-idea</title><path d=\"M13.786 5.686A.5.5 0 0014.25 6h.034a.5.5 0 00.451-.378l.223-.891a.25.25 0 01.127-.161.247.247 0 01.2-.011A8.459 8.459 0 0120.75 12.5a1 1 0 002 0 10.45 10.45 0 00-7.022-9.911.25.25 0 01-.16-.3l.167-.671a.5.5 0 00-.785-.52l-2 1.5a.5.5 0 00-.164.585zM7.805 22.234a.251.251 0 01.151.286l-.194.872a.5.5 0 00.2.514.5.5 0 00.549.023l2.5-1.5a.5.5 0 00.19-.653l-1.5-3a.508.508 0 00-.509-.272.5.5 0 00-.426.388l-.254 1.142a.25.25 0 01-.35.172A8.522 8.522 0 013.25 12.5a1 1 0 00-2 0 10.526 10.526 0 006.555 9.734zM16.54 11.775a1 1 0 00-1 1v.317a.25.25 0 01-.2.244 2.61 2.61 0 00-.411 4.973l2.475.99a.61.61 0 01-.226 1.177H14.74a1 1 0 000 2h.55a.249.249 0 01.25.25v.25a1 1 0 002 0v-.317a.249.249 0 01.2-.244 2.61 2.61 0 00.412-4.974l-2.475-.989a.61.61 0 01.226-1.177h2.441a1 1 0 000-2h-.554a.25.25 0 01-.25-.25v-.25a1 1 0 00-1-1zM3.652 7.5a.248.248 0 01.1.2v.55A.75.75 0 004.5 9H8a.75.75 0 00.75-.75V7.7a.248.248 0 01.1-.2 4.129 4.129 0 001.65-3.25A4.361 4.361 0 006.25 0 4.361 4.361 0 002 4.25 4.129 4.129 0 003.652 7.5zM6.167 12.667a.75.75 0 00.75-.75v-.167a.25.25 0 01.25-.25H7.5a.75.75 0 000-1.5H5a.75.75 0 000 1.5h.167a.25.25 0 01.25.25v.167a.75.75 0 00.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});