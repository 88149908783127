define("ember-svg-jar/inlined/microphone-podcast-on-air", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>microphone-podcast-on-air</title><path d=\"M11.5 9a2 2 0 012 2 .25.25 0 00.346.231A3.506 3.506 0 0016 8V7a.25.25 0 00-.25-.25H13.5a.5.5 0 010-1h2.25A.25.25 0 0016 5.5V4a.25.25 0 00-.25-.25H13.5a.5.5 0 010-1h2.092a.249.249 0 00.238-.327A3.506 3.506 0 0012.5 0h-2a3.506 3.506 0 00-3.33 2.423.249.249 0 00.238.327H10.5a.5.5 0 010 1H7.25A.25.25 0 007 4v1.5a.25.25 0 00.25.25h3.25a.5.5 0 010 1H7.25A.25.25 0 007 7v1a3.506 3.506 0 002.154 3.231A.25.25 0 009.5 11a2 2 0 012-2zM4.5 16.375A1.627 1.627 0 002.875 18v1a1.625 1.625 0 003.25 0v-1A1.627 1.627 0 004.5 16.375zM4.875 19a.375.375 0 01-.75 0v-1a.375.375 0 01.75 0zM9.5 16.375a.625.625 0 00-.625.625v.11a.25.25 0 01-.458.138l-.4-.6A.626.626 0 006.875 17v3a.625.625 0 001.25 0v-.11a.25.25 0 01.458-.138l.4.6A.626.626 0 0010.125 20v-3a.625.625 0 00-.625-.625zM13.5 16.375A1.627 1.627 0 0011.875 18v2a.625.625 0 001.25 0v-.125a.25.25 0 01.25-.25h.25a.25.25 0 01.25.25V20a.625.625 0 001.25 0v-2a1.627 1.627 0 00-1.625-1.625zm-.125 2a.25.25 0 01-.25-.25V18a.375.375 0 01.75 0v.125a.25.25 0 01-.25.25zM16.5 16.375a.625.625 0 00-.625.625v3a.625.625 0 001.25 0v-3a.625.625 0 00-.625-.625zM21.125 18a1.627 1.627 0 00-1.625-1.625h-1a.625.625 0 00-.625.625v3a.625.625 0 001.25 0v-.293a.082.082 0 01.082-.082.084.084 0 01.058.024l.793.792a.624.624 0 00.884-.882l-.18-.181a.25.25 0 01-.015-.337A1.616 1.616 0 0021.125 18zm-2-.125a.25.25 0 01.25-.25h.125a.375.375 0 010 .75h-.125a.25.25 0 01-.25-.25z\"/><path d=\"M24 16a3 3 0 00-3-3h-8.25a.25.25 0 01-.25-.25V11a1 1 0 00-2 0v1.75a.25.25 0 01-.25.25H3a3 3 0 00-3 3v5a3 3 0 003 3h18a3 3 0 003-3zm-2 5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5a1 1 0 011-1h18a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});