define("ember-svg-jar/inlined/sofa-couch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sofa-couch</title><path d=\"M20.5 7.5h-5.867a1.508 1.508 0 01-1.287-.729l-.875-1.457A5.826 5.826 0 007.5 2.5a5.01 5.01 0 00-4.739 3.4.5.5 0 00.4.654A5.436 5.436 0 017.5 12v.563a.5.5 0 00.581.493 42.358 42.358 0 0115.3.353.5.5 0 00.619-.487V11a3.5 3.5 0 00-3.5-3.5z\"/><path d=\"M23.611 15.013A39.993 39.993 0 006.6 14.856a.5.5 0 01-.6-.489V12a3.875 3.875 0 00-3.5-4 2.5 2.5 0 00-.961 4.81.744.744 0 01.461.69v2a3.492 3.492 0 002.82 3.416.25.25 0 01.18.357l-.39.78a1 1 0 001.79.894l.835-1.671A.5.5 0 017.677 19h11.146a.5.5 0 01.447.276l.835 1.671a1 1 0 101.79-.894l-.346-.691a.25.25 0 01.224-.362h.727a1.5 1.5 0 001.5-1.5v-2a.5.5 0 00-.389-.487z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});