define("ember-svg-jar/inlined/phone-action-star-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-star-1</title><path d=\"M12 14.712V18.5a1 1 0 01-1 1H4a1 1 0 01-1-1v-11a1 1 0 011-1h4.448a.247.247 0 00.24-.322 2.764 2.764 0 01-.032-1.838.251.251 0 00-.23-.34H3.5A2.5 2.5 0 001 6.5v15A2.5 2.5 0 003.5 24h8a2.5 2.5 0 002.5-2.5v-6.972a.245.245 0 00-.334-.232 2.859 2.859 0 01-1.38.175.25.25 0 00-.286.241z\"/><path d=\"M21.74 4h-2.665L17.666.732l-.013-.027a1.293 1.293 0 00-2.317.027L13.925 4h-2.666a1.229 1.229 0 00-1.166.76 1.271 1.271 0 00.319 1.4l2.329 2.07-1.295 2.981a1.255 1.255 0 00.315 1.451 1.287 1.287 0 00.864.338 1.3 1.3 0 00.642-.172l3.233-1.821 3.236 1.821a1.275 1.275 0 001.505-.166 1.256 1.256 0 00.316-1.45l-1.3-2.982 2.353-2.091a1.262 1.262 0 00.3-1.378A1.232 1.232 0 0021.74 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});