define("ember-svg-jar/inlined/footwear-boots-female", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>footwear-boots-female</title><path d=\"M18.673 19.808c-.641-.318-1.508-.748-2.677-1.385-1.309-.715-1.717-1.59-1.746-3.743V.994a.5.5 0 00-.379-.484A17.5 17.5 0 0011.04.063a.5.5 0 00-.54.5v12.431a.75.75 0 01-1.5 0V.506a.5.5 0 00-.517-.5 18.738 18.738 0 00-3.854.5.5.5 0 00-.379.488v9.346a18.688 18.688 0 01-.409 3.96c-.083.469-.592 3.452-.591 3.7v5.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2.127a.5.5 0 01.791-.406l3.577 2.562a1.5 1.5 0 001.086.465h7.046a2 2 0 002-2c-.001-1.156-.482-1.394-2.077-2.186z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});