define("ember-svg-jar/inlined/coffee-straw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>coffee-straw</title><path d=\"M20 5H9.578a.5.5 0 01-.4-.2L6.024.534a1.25 1.25 0 10-2.048 1.433L5.97 4.6a.25.25 0 01-.2.4H4a1 1 0 00-1 1v1a1 1 0 001 1h16a1 1 0 001-1V6a1 1 0 00-1-1zM18.65 9.5H5.35a.5.5 0 00-.5.533l.87 13.033a1 1 0 001 .934h10.562a1 1 0 001-.934l.869-13.033a.5.5 0 00-.5-.533zM12 19.5a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});