define("ember-svg-jar/inlined/tag-free-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tag-free-circle</title><path d=\"M9.15 11.1a.15.15 0 00.15.149.75.75 0 000-1.5.15.15 0 00-.15.149z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm8.4 11.85a.75.75 0 010 1.5H19a.25.25 0 00-.25.25v.2a.451.451 0 00.45.45h1.2a.75.75 0 010 1.5h-1.2a1.953 1.953 0 01-1.95-1.95v-3.6a1.953 1.953 0 011.95-1.95h1.2a.75.75 0 010 1.5h-1.2a.451.451 0 00-.45.45v1.4a.25.25 0 00.25.25zm-4.8 0a.75.75 0 010 1.5h-1.4a.25.25 0 00-.25.25v.2a.451.451 0 00.45.45h1.2a.75.75 0 010 1.5h-1.2a1.953 1.953 0 01-1.95-1.95v-3.6a1.953 1.953 0 011.95-1.95h1.2a.75.75 0 010 1.5h-1.2a.451.451 0 00-.45.45v1.4a.25.25 0 00.25.25zm-4.157 2.764a.75.75 0 01-1.287.772l-.541-.9a.251.251 0 00-.465.129V15a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75h.9a2.249 2.249 0 011.134 4.192.252.252 0 00-.088.344zM5.4 11.85a.75.75 0 010 1.5h-.8a.25.25 0 00-.25.25V15a.75.75 0 11-1.5 0v-4.8A1.953 1.953 0 014.8 8.25H6a.75.75 0 010 1.5H4.8a.451.451 0 00-.45.45v1.4a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});