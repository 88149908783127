define("ember-svg-jar/inlined/alarm-bell-sleep", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>alarm-bell-sleep</title><path d=\"M23 15h-1.455l2.245-2.886A1 1 0 0023 10.5h-3.5a1 1 0 000 2h1.456l-2.245 2.886A1 1 0 0019.5 17H23a1 1 0 000-2zM15.5 2h2.39l-3.164 3.868A1 1 0 0015.5 7.5H20a1 1 0 000-2h-2.39l3.164-3.867A1 1 0 0020 0h-4.5a1 1 0 000 2zM9.5 21.5h-3a.5.5 0 00-.5.5 2 2 0 004 0 .5.5 0 00-.5-.5zM14.5 13.647V13c0-3.493-2.079-6.479-5-7.27V4.5a1.5 1.5 0 10-3 0v1.23c-2.921.791-5 3.777-5 7.27v.646a13.771 13.771 0 01-1.447 6.13.5.5 0 00.447.724h15a.5.5 0 00.447-.724 13.785 13.785 0 01-1.447-6.129z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});