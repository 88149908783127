define("ember-svg-jar/inlined/earth-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earth-3</title><path d=\"M15.575 5.5a.249.249 0 00.231-.344A9.777 9.777 0 0012.133.427a.25.25 0 00-.266 0 9.777 9.777 0 00-3.673 4.729.249.249 0 00.231.344zM18.465 11.009a.25.25 0 00.25.241h4.978a.248.248 0 00.184-.081.252.252 0 00.065-.19 11.888 11.888 0 00-.98-3.83.25.25 0 00-.229-.149h-4.46a.25.25 0 00-.244.3 21.812 21.812 0 01.436 3.709zM16.715 11.25a.254.254 0 00.181-.077.251.251 0 00.069-.184 19.436 19.436 0 00-.51-3.8.248.248 0 00-.242-.189H7.787a.249.249 0 00-.242.191 19.436 19.436 0 00-.51 3.8.251.251 0 00.25.261zM8.425 18.5a.249.249 0 00-.231.344 9.777 9.777 0 003.674 4.729.249.249 0 00.265 0 9.773 9.773 0 003.674-4.729.25.25 0 00-.232-.344zM7.285 12.75a.251.251 0 00-.25.26 19.437 19.437 0 00.51 3.8.249.249 0 00.242.19h8.426a.251.251 0 00.243-.191 19.518 19.518 0 00.509-3.8.251.251 0 00-.25-.261zM.058 10.979a.25.25 0 00.249.271h4.978a.249.249 0 00.25-.24 21.812 21.812 0 01.436-3.71.246.246 0 00-.05-.209A.248.248 0 005.727 7h-4.46a.25.25 0 00-.229.149 11.888 11.888 0 00-.98 3.83zM5.535 12.991a.25.25 0 00-.25-.241H.307a.248.248 0 00-.184.081.252.252 0 00-.065.19 11.9 11.9 0 00.98 3.83.25.25 0 00.229.149h4.46a.25.25 0 00.244-.3 21.812 21.812 0 01-.436-3.709zM6.52 18.668a.251.251 0 00-.236-.168H2.4a.25.25 0 00-.2.394 12.026 12.026 0 006.669 4.684.25.25 0 00.251-.409 12.887 12.887 0 01-2.6-4.501zM17.716 18.5a.251.251 0 00-.236.168 12.887 12.887 0 01-2.594 4.5.25.25 0 00.251.409 12.026 12.026 0 006.669-4.684.25.25 0 00-.2-.394zM6.284 5.5a.251.251 0 00.236-.168A12.887 12.887 0 019.114.831a.25.25 0 00-.251-.409 12.026 12.026 0 00-6.669 4.684.25.25 0 00.2.394zM23.942 13.021a.25.25 0 00-.249-.271h-4.978a.25.25 0 00-.25.241 21.812 21.812 0 01-.436 3.707.25.25 0 00.244.3h4.46a.25.25 0 00.229-.149 11.9 11.9 0 00.98-3.828zM17.48 5.332a.251.251 0 00.236.168H21.6a.25.25 0 00.2-.394A12.026 12.026 0 0015.137.422a.25.25 0 00-.251.409 12.887 12.887 0 012.594 4.501z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});