define("ember-svg-jar/inlined/lucide-text-cursor-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13 20h-1a3 3 0 01-3-3V7a3 3 0 013-3h1M5 4h1a3 3 0 013 3v10a3 3 0 01-3 3H5M13.1 7.9h6.8A2.18 2.18 0 0122 10v4a2.11 2.11 0 01-2.1 2.1h-6.8M4.8 16.1h-.7A2.18 2.18 0 012 14v-4a2.18 2.18 0 012.1-2.1h.7\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});