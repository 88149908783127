define("ember-svg-jar/inlined/settings-brightness", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>settings-brightness</title><path d=\"M18.5 3a3 3 0 00-3-3h-7a3 3 0 00-3 3v18a3 3 0 003 3h7a3 3 0 003-3zm-5.75 18.25a.75.75 0 11-1.5 0v-.608a.75.75 0 011.5 0zM12 18.822A1.822 1.822 0 1113.821 17 1.821 1.821 0 0112 18.822zm-.75-5.464v-.607a.75.75 0 111.5 0v.607a.75.75 0 01-1.5 0zm4.285 7.178a.749.749 0 01-1.06 0l-.429-.429a.75.75 0 111.06-1.06l.429.428a.75.75 0 010 1.061zm-1.489-6.642l.429-.428a.75.75 0 011.06 1.06l-.429.429a.75.75 0 01-1.06-1.061zM17 17a.762.762 0 01-.75.75h-.607a.75.75 0 010-1.5h.607A.761.761 0 0117 17zM7.5 3a1 1 0 011-1h7a1 1 0 011 1v3.5a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5zm2.454 11.955a.749.749 0 01-1.06 0l-.429-.429a.75.75 0 011.06-1.06l.429.428a.75.75 0 010 1.061zM7 17a.761.761 0 01.75-.75h.607a.75.75 0 010 1.5H7.75A.762.762 0 017 17zm1.465 2.474l.429-.428a.75.75 0 111.06 1.06l-.429.429a.75.75 0 01-1.06-1.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});