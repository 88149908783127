define("ember-svg-jar/inlined/smiley-very-happy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-very-happy</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM6.438 5.394l.067-.465a.5.5 0 01.99 0l.067.464a3.013 3.013 0 002.544 2.546l.464.066a.5.5 0 010 .99l-.464.066a3.013 3.013 0 00-2.544 2.545l-.067.465a.5.5 0 01-.99 0l-.067-.464a3.013 3.013 0 00-2.544-2.546l-.464-.066a.5.5 0 010-.99l.464-.066a3.013 3.013 0 002.544-2.545zM9.5 16.5a2.5 2.5 0 015 0v2a2.5 2.5 0 01-5 0zm8.062-4.894l-.067.465a.5.5 0 01-.99 0l-.067-.464a3.013 3.013 0 00-2.544-2.546l-.464-.066a.5.5 0 010-.99l.464-.066a3.013 3.013 0 002.544-2.545l.067-.465a.5.5 0 01.99 0l.067.464a3.013 3.013 0 002.544 2.546l.464.066a.5.5 0 010 .99l-.464.066a3.013 3.013 0 00-2.544 2.545z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});