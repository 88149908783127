define("ember-svg-jar/inlined/antique-battering-ram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>antique-battering-ram</title><circle cx=\"7\" cy=\"19.497\" r=\"2.25\"/><circle cx=\"17.5\" cy=\"19.497\" r=\"2.25\"/><path d=\"M7.25 14.247a.5.5 0 01-.5.5h-4a1 1 0 00-1 1v2a1 1 0 001 1h.725a.5.5 0 00.472-.335 3.235 3.235 0 016.106 0 .5.5 0 00.472.335h3.45a.5.5 0 00.472-.335 3.235 3.235 0 016.106 0 .5.5 0 00.472.335h.725a1 1 0 001-1v-2a1 1 0 00-1-1h-1a.5.5 0 01-.5-.5v-1.5a.5.5 0 01.5-.5h.5a2 2 0 000-4h-.5a.5.5 0 01-.5-.5v-4.5a1.008 1.008 0 00-1.77-.64l-4.55 5.46a.5.5 0 01-.384.18H9.75a.5.5 0 01-.5-.5v-4.5a1.008 1.008 0 00-1.77-.64l-4.55 5.46a.5.5 0 01-.384.18H1.25a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-3a.482.482 0 00-.083-.271.5.5 0 01.029-.6l1.112-1.338a.25.25 0 01.442.16zm11 0a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5v-1.5a.5.5 0 01.5-.5h6.5a.5.5 0 00.5-.5v-3a.482.482 0 00-.083-.271.5.5 0 01.029-.6l1.112-1.336a.25.25 0 01.442.16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});