define("ember-svg-jar/inlined/plane-boarding-pass-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-boarding-pass-check</title><path d=\"M7.954 10.069a.251.251 0 01-.271.01l-1.165-.694-.2.018-1.062.937a.2.2 0 00-.039.038.23.23 0 00.034.323l2.29 2.24a.415.415 0 00.459.013l6.951-4.7a1.282 1.282 0 00.483-1.4 1.2 1.2 0 00-1.729-.6l-1.891 1.29a.25.25 0 01-.228.028L7.39 6.018a.284.284 0 00-.269.038l-.766.568a.229.229 0 00-.045.043.284.284 0 00.032.4l2.81 1.74a.249.249 0 01.011.417z\"/><path d=\"M8.812 16.363H2.5a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5h15a.5.5 0 01.5.5v3.8a.245.245 0 00.214.247c.423.052 1.094.181 1.488.266a.246.246 0 00.3-.245V1.714A1.77 1.77 0 0018.182 0H1.818A1.77 1.77 0 000 1.714v14.832a1.817 1.817 0 001.817 1.817H8.78a.244.244 0 00.243-.264 8.583 8.583 0 01.032-1.462.245.245 0 00-.243-.274zM8.364 1.477a.819.819 0 11-.819.818.819.819 0 01.819-.818zm-2.864 0a.819.819 0 11-.818.818.818.818 0 01.818-.818zm-2.864 0a.819.819 0 11-.818.818.819.819 0 01.818-.818z\"/><path d=\"M23.062 17.8a1 1 0 00-1.228.7 4.751 4.751 0 11-4.584-6 1 1 0 000-2 6.75 6.75 0 106.514 8.526 1 1 0 00-.702-1.226z\"/><path d=\"M17.073 17.1L15.8 15.824a1 1 0 00-1.414 1.414l1.647 1.647a1.717 1.717 0 002.431 0l5.242-5.241a1 1 0 10-1.414-1.415L17.427 17.1a.252.252 0 01-.354 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});