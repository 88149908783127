define("ember-svg-jar/inlined/shopping-bag-smiley", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-bag-smiley</title><path d=\"M22 7.5A1.5 1.5 0 0020.5 6h-2.75a.25.25 0 01-.25-.25V5.5a5.5 5.5 0 00-11 0v.25a.25.25 0 01-.25.25H3.5A1.5 1.5 0 002 7.5v14A2.5 2.5 0 004.5 24h15a2.5 2.5 0 002.5-2.5zm-10 2a6 6 0 11-6 6 6.006 6.006 0 016-6zm-3.5-4a3.5 3.5 0 017 0v.25a.25.25 0 01-.25.25h-6.5a.25.25 0 01-.25-.25z\"/><circle cx=\"9.5\" cy=\"14\" r=\"1\"/><circle cx=\"14.5\" cy=\"14\" r=\"1\"/><path d=\"M14.348 17.347a.76.76 0 00-1.011.3 1.453 1.453 0 01-1.337.6 1.455 1.455 0 01-1.336-.6.75.75 0 00-1.335.684A2.933 2.933 0 0012 19.75a2.933 2.933 0 002.671-1.415.741.741 0 00-.323-.988z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});