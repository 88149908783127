define("ember-svg-jar/inlined/keyboard-wireless-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-wireless-2</title><path d=\"M6.82 5.069a9.483 9.483 0 0110.36 0 1 1 0 001.111-1.662 11.335 11.335 0 00-12.582 0A1 1 0 006.82 5.069z\"/><path d=\"M7.475 6.871a1 1 0 101.109 1.664 6.253 6.253 0 016.832 0 1 1 0 101.109-1.664 8.286 8.286 0 00-9.05 0zM22 12.5H2a1 1 0 00-1 1v8a1 1 0 001 1h20a1 1 0 001-1v-8a1 1 0 00-1-1zM16.5 15h.5a.5.5 0 010 1h-.5a.5.5 0 010-1zm0 2.5h.5a.5.5 0 010 1h-.5a.5.5 0 010-1zm-3-2.5h.5a.5.5 0 010 1h-.5a.5.5 0 010-1zm0 2.5h.5a.5.5 0 110 1h-.5a.5.5 0 010-1zm-3-2.5h.5a.5.5 0 010 1h-.5a.5.5 0 010-1zm0 2.5h.5a.5.5 0 010 1h-.5a.5.5 0 010-1zm-3-2.5H8a.5.5 0 010 1h-.5a.5.5 0 010-1zm0 2.5H8a.5.5 0 010 1h-.5a.5.5 0 010-1zm-2.5 1h-.5a.5.5 0 010-1H5a.5.5 0 010 1zM5 16h-.5a.5.5 0 010-1H5a.5.5 0 010 1zm12.5 5h-11a.5.5 0 010-1h11a.5.5 0 010 1zm2.5-2.5h-.5a.5.5 0 010-1h.5a.5.5 0 010 1zm0-2.5h-.5a.5.5 0 110-1h.5a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});