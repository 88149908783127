define("ember-svg-jar/inlined/american-football-run-ball-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>american-football-run-ball-1</title><circle cx=\"10.194\" cy=\"2.75\" r=\"2.5\"/><path d=\"M21.254 17.75a1.5 1.5 0 00-1.5-1.5h-4.106a.5.5 0 01-.47-.327L12.8 9.465a.249.249 0 01.161-.325l3.2-.986a.494.494 0 01.581.231l1.206 2.109a1.5 1.5 0 102.6-1.488L19.35 6.9a3.47 3.47 0 00-3.027-1.766 3.581 3.581 0 00-1.041.156L10.4 6.79a.25.25 0 00-.1.421 5.036 5.036 0 011.146 1.566 6.092 6.092 0 01.15 4.183 2.032 2.032 0 01-.423.766l.223.6L8.9 15.891a3.482 3.482 0 00-1.644 2.967v3.392a1.5 1.5 0 003 0v-3.392a.5.5 0 01.235-.423l1.983-1.24a3.507 3.507 0 003.176 2.055h4.106a1.5 1.5 0 001.498-1.5z\"/><path d=\"M7.754 10.75a2.5 2.5 0 012.392 1.813 4.413 4.413 0 00-.064-3.16A4.708 4.708 0 007.745 7.3a.554.554 0 00-.229-.05.544.544 0 00-.523.395 4.457 4.457 0 00.055 3.105z\"/><path d=\"M7.754 14.75a1.5 1.5 0 000-3H5.979a.5.5 0 01-.5-.5.489.489 0 01.069-.239 5.581 5.581 0 01-.264-2.363.2.2 0 00-.254-.207 3.208 3.208 0 00-2.273 3.046h-.011c0 .013.008.024.008.036a3.229 3.229 0 003.225 3.227z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});