define("ember-svg-jar/inlined/lock-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.5 9h-1v-.5C15.5 6.57 13.93 5 12 5S8.5 6.57 8.5 8.5V9h-1a.5.5 0 00-.5.5v7a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-7a.5.5 0 00-.5-.5zm-7-.5C9.5 7.122 10.621 6 12 6s2.5 1.122 2.5 2.5V9h-5v-.5zM16 16H8v-6h8v6z\"/><path d=\"M12 11c-.552 0-1 .449-1 1a.99.99 0 00.5.847V14.5a.5.5 0 001 0v-1.653A.99.99 0 0013 12c0-.551-.448-1-1-1z\"/><path d=\"M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm0 23C5.935 23 1 18.065 1 12S5.935 1 12 1s11 4.935 11 11-4.935 11-11 11z\"/><g><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});