define("ember-svg-jar/inlined/app-window-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-small</title><path d=\"M19.75 8.5a3 3 0 00-3-3h-9.5a3 3 0 00-3 3v7a3 3 0 003 3h9.5a3 3 0 003-3zm-5.85-1a.966.966 0 011.692 0 .889.889 0 010 1 .966.966 0 01-1.692 0 .889.889 0 010-1zm-3.5 0a.966.966 0 011.692 0 .889.889 0 010 1 .966.966 0 01-1.692 0 .889.889 0 010-1zm-3.562.091A1 1 0 017.75 7a.983.983 0 01.846.5.889.889 0 010 1 .966.966 0 01-1.696 0 .972.972 0 01-.15-.5.987.987 0 01.092-.408zM17.75 15.5a1 1 0 01-1 1h-9.5a1 1 0 01-1-1v-4.75a.25.25 0 01.25-.25h11a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});