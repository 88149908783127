define("ember-svg-jar/inlined/currency-pound-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-pound-bubble</title><path d=\"M21.526 8.25a.249.249 0 01-.134-.324 4.079 4.079 0 00-5.3-5.3.249.249 0 01-.324-.134 4.1 4.1 0 00-7.53 0 .249.249 0 01-.324.134 4.079 4.079 0 00-5.3 5.3.249.249 0 01-.134.324 4.078 4.078 0 000 7.5.25.25 0 01.134.325 4.078 4.078 0 005.3 5.3.249.249 0 01.324.135 4.1 4.1 0 007.53 0 .25.25 0 01.324-.135 4.078 4.078 0 005.3-5.3.25.25 0 01.134-.325 4.078 4.078 0 000-7.5zm-7.633 2.237a1 1 0 010 2h-2.35a.25.25 0 00-.25.25v.55a8.859 8.859 0 01-.4 2.869.25.25 0 00.236.331h3.965a1 1 0 010 2h-7.2a1 1 0 010-2c.929 0 1.4-1.077 1.4-3.2v-.55a.25.25 0 00-.25-.25H7.893a1 1 0 110-2h1.15a.25.25 0 00.25-.25v-2.35a3.4 3.4 0 116.8 0 1 1 0 01-2 0 1.4 1.4 0 10-2.8 0v2.35a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});