define("ember-svg-jar/inlined/shell-sea-grass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shell-sea-grass</title><path d=\"M17.484 12.5a3.946 3.946 0 00.553 1.724l.114.228a.249.249 0 00.2.139 8.985 8.985 0 013.41 1.042.151.151 0 00.225-.133 3.944 3.944 0 00-.552-1.724 3.188 3.188 0 01-.448-1.276 3.183 3.183 0 01.448-1.276 3.944 3.944 0 00.55-1.724 3.944 3.944 0 00-.552-1.724 3.188 3.188 0 01-.448-1.276A6.8 6.8 0 0019.9 3.223.5.5 0 0019.533 3a.518.518 0 00-.4.144A4.744 4.744 0 0017.484 6.5a3.946 3.946 0 00.553 1.724 3.179 3.179 0 01.447 1.276 3.185 3.185 0 01-.447 1.276 3.946 3.946 0 00-.553 1.724zM8.5 16.5c-4.514 0-8.207 2.6-8.483 6.968a.5.5 0 00.5.532h15.967a.5.5 0 00.5-.532c-.277-4.374-3.97-6.968-8.484-6.968zM9.6 9.5a3.946 3.946 0 00.553 1.724A3.179 3.179 0 0110.6 12.5a3.185 3.185 0 01-.447 1.276 6.9 6.9 0 00-.425.98.252.252 0 00.028.212.249.249 0 00.182.112 11.3 11.3 0 012.824.682.25.25 0 00.34-.242 2.986 2.986 0 01.446-1.3 3.94 3.94 0 00.552-1.72 3.944 3.944 0 00-.552-1.724A3.188 3.188 0 0113.1 9.5a3.183 3.183 0 01.448-1.276A3.944 3.944 0 0014.1 6.5a3.944 3.944 0 00-.552-1.724A3.188 3.188 0 0113.1 3.5 6.8 6.8 0 0012.02.223.5.5 0 0011.653 0a.514.514 0 00-.4.144A4.744 4.744 0 009.6 3.5a3.946 3.946 0 00.553 1.724A3.179 3.179 0 0110.6 6.5a3.185 3.185 0 01-.447 1.276A3.946 3.946 0 009.6 9.5zM2.6 12.5a3.946 3.946 0 00.553 1.724A3.179 3.179 0 013.6 15.5a1.01 1.01 0 01-.007.122.251.251 0 00.346.258 11.108 11.108 0 012.842-.766.251.251 0 00.206-.321 6.692 6.692 0 00-.439-1.017A3.188 3.188 0 016.1 12.5a3.183 3.183 0 01.448-1.276A3.944 3.944 0 007.1 9.5a3.944 3.944 0 00-.552-1.724A3.188 3.188 0 016.1 6.5a6.8 6.8 0 00-1.08-3.277A.5.5 0 004.653 3a.519.519 0 00-.4.144A4.744 4.744 0 002.6 6.5a3.946 3.946 0 00.553 1.724A3.179 3.179 0 013.6 9.5a3.185 3.185 0 01-.447 1.276A3.946 3.946 0 002.6 12.5zM23.984 21.577c-.221-3.48-3.158-5.544-6.75-5.544a8.469 8.469 0 00-2.4.334.25.25 0 00-.071.446 8.424 8.424 0 013.477 5 .25.25 0 00.243.191h5.1a.4.4 0 00.4-.423z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});