define("ember-svg-jar/inlined/asian-food-noodles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>asian-food-noodles</title><path d=\"M4 5a.5.5 0 00-.5-.5h-2a1 1 0 000 2h2A.5.5 0 004 6zM23.25.5H1.5a1 1 0 000 2h21.75a.25.25 0 00.25-.25V.75a.25.25 0 00-.25-.25zM23.25 4.5H17a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h6.25a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25zM7.143 13.074l-.792-1.423c-.789-1.417-.362-2.2.286-3.38a5.224 5.224 0 00.833-4.013 1 1 0 10-1.94.484 3.326 3.326 0 01-.647 2.569c-.715 1.3-1.606 2.931-.28 5.313l.797 1.423a3.1 3.1 0 01-.149 3.264 5.089 5.089 0 000 5.751A1 1 0 106.9 21.938a3.1 3.1 0 010-3.5 5.093 5.093 0 00.243-5.364z\"/><path d=\"M10.854 13.074l-.792-1.423c-.789-1.417-.361-2.2.287-3.38a5.224 5.224 0 00.833-4.013 1 1 0 10-1.94.484A3.326 3.326 0 018.6 7.311c-.715 1.3-1.606 2.931-.281 5.313l.792 1.423a3.1 3.1 0 01-.148 3.264 5.089 5.089 0 000 5.751 1 1 0 101.654-1.124 3.1 3.1 0 010-3.5 5.09 5.09 0 00.237-5.364z\"/><path d=\"M14.566 13.074l-.792-1.423c-.789-1.417-.361-2.2.287-3.38a5.224 5.224 0 00.833-4.013 1 1 0 10-1.94.484 3.33 3.33 0 01-.647 2.569c-.715 1.3-1.606 2.931-.281 5.313l.792 1.423a3.1 3.1 0 01-.148 3.264 5.086 5.086 0 000 5.751 1 1 0 101.654-1.124 3.1 3.1 0 010-3.5 5.09 5.09 0 00.242-5.364z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});