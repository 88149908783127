define("ember-svg-jar/inlined/paragraph-two-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paragraph-two-column</title><path d=\"M22.044 0H1.956A1.959 1.959 0 000 1.954v20.088A1.959 1.959 0 001.956 24h20.088A1.959 1.959 0 0024 22.042V1.954A1.959 1.959 0 0022.044 0zM21 22l-18 .04a1 1 0 01-1-1L1.958 3a1 1 0 011-1L21 1.956a1 1 0 011 1V21a1 1 0 01-1 1z\"/><path d=\"M7.5 6.248H10a.75.75 0 000-1.5H7.5a.75.75 0 000 1.5zM4.5 10.248H10a.75.75 0 000-1.5H4.5a.75.75 0 000 1.5zM4.5 14.248H10a.75.75 0 000-1.5H4.5a.75.75 0 100 1.5zM4.5 18.248H10a.75.75 0 000-1.5H4.5a.75.75 0 000 1.5zM14 6.248h5.5a.75.75 0 000-1.5H14a.75.75 0 000 1.5zM14 10.248h5.5a.75.75 0 000-1.5H14a.75.75 0 000 1.5zM14 14.248h5.5a.75.75 0 000-1.5H14a.75.75 0 000 1.5zM14 18.248h3.5a.75.75 0 000-1.5H14a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});