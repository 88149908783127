define("ember-svg-jar/inlined/ray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ray</title><path d=\"M21.42 2.58c-3.754-3.755-10.027-3.369-14.276.881a.75.75 0 00.114 1.154l1.91 1.273a.249.249 0 01.084.319l-.243.486a.248.248 0 01-.353.1l-.528-.321a1 1 0 00-1.038 1.71l.7.428a.25.25 0 01.094.325l-.326.652a.25.25 0 01-.335.111L6.61 9.39a1 1 0 10-.894 1.787l.551.276a.251.251 0 01.128.152.247.247 0 01-.024.2L.136 22.5A1 1 0 001.5 23.863l10.7-6.235a.254.254 0 01.2-.024.251.251 0 01.152.128l.275.551a1 1 0 101.788-.894l-.309-.617a.251.251 0 01.112-.335l.6-.3a.252.252 0 01.336.112l.428.856a1 1 0 101.787-.894l-.428-.856a.249.249 0 01.112-.335l.544-.272a.248.248 0 01.319.085l1.273 1.909a.752.752 0 00.55.33.729.729 0 00.6-.216c4.249-4.25 4.637-10.52.881-14.276zm-.908 5.82a1.068 1.068 0 110-1.51 1.066 1.066 0 010 1.51zm-5.286-5.29a1.068 1.068 0 110 1.51 1.062 1.062 0 010-1.51zM4.593 19.748a.25.25 0 01-.342-.342l3.659-6.274a.249.249 0 01.392-.051l2.616 2.619a.25.25 0 01.071.209.246.246 0 01-.121.184z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});