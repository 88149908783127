define("ember-svg-jar/inlined/arrow-right-9", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1 24a.997.997 0 00.691-.277l11.5-11a.998.998 0 000-1.446l-11.5-11A1 1 0 10.309 1.723L11.053 12 .309 22.277A1 1 0 001 24z\"/><path d=\"M11.5 24a.997.997 0 00.691-.277l11.5-11a.998.998 0 000-1.446l-11.5-11a1 1 0 00-1.383 1.445L21.553 12 10.809 22.277A1 1 0 0011.5 24z\"/><g><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});