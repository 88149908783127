define("ember-svg-jar/inlined/bookmarks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bookmarks</title><path d=\"M16.25 22.234a1 1 0 00.293.707l.543.543a1.549 1.549 0 001.107.516 1.274 1.274 0 00.482-.095 1.407 1.407 0 00.825-1.421V2a2 2 0 00-2-2h-.75a.5.5 0 00-.5.5zM10.1 20.3l1.542-1.541a.5.5 0 01.708 0L13.9 20.3a.5.5 0 00.854-.353V.5a.5.5 0 00-.5-.5H9.75a.5.5 0 00-.5.5v19.443a.5.5 0 00.85.357zM7.25 0H6.5a2 2 0 00-2 2v20.484a1.407 1.407 0 00.825 1.421 1.409 1.409 0 001.589-.421l.543-.543a1 1 0 00.293-.707V.5a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});