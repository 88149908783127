define("ember-svg-jar/inlined/watch-time-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>watch-time-1</title><path d=\"M12.266 12.175v-2.19a.75.75 0 00-1.5 0v2.5a.75.75 0 00.219.53l3 3a.769.769 0 001.061 0 .749.749 0 000-1.06zM23.838 4.163l-4-4a.5.5 0 00-.707 0l-1.418 1.418a.249.249 0 000 .353l4.353 4.354a.25.25 0 00.354 0l1.418-1.418a.5.5 0 000-.707z\"/><path d=\"M16.3 3l-1.994 1.988a7.981 7.981 0 00-3.413-.479 8.079 8.079 0 00-7.354 7.364 7.988 7.988 0 00.49 3.432L.164 19.13a.5.5 0 00-.148.355v4a.5.5 0 00.5.5h4a.5.5 0 00.352-.145l3.867-3.854a7.977 7.977 0 003.409.475 8.08 8.08 0 007.347-7.346 7.967 7.967 0 00-.478-3.42L21.006 7.7a.252.252 0 000-.354L16.652 3a.249.249 0 00-.352 0zm-4.783 15.49a6 6 0 116-6 6.006 6.006 0 01-6.001 5.995zm-8.5 1.5a1 1 0 111 1 1 1 0 01-1.001-1.005z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});