define("ember-svg-jar/inlined/design-document-dxf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-document-dxf</title><path d=\"M6.456 9.819a.249.249 0 00-.331.24v3.882a.249.249 0 00.331.24A2.238 2.238 0 007.875 12a2.238 2.238 0 00-1.419-2.181z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zm-17 11.125A.625.625 0 014.875 15V9a.625.625 0 01.625-.625 3.625 3.625 0 010 7.25zm8.559-.9a.625.625 0 11-1.118.558s-.859-1.7-.879-1.759-.1-.043-.127.006l-.876 1.753a.625.625 0 11-1.118-.558l1.3-2.609a.252.252 0 000-.224l-1.3-2.609a.625.625 0 111.118-.558s.826 1.644.854 1.709a.1.1 0 00.177-.006l.851-1.7a.625.625 0 111.118.558l-1.3 2.609a.252.252 0 000 .224zm4.441-5.1h-2a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25H17.5a.625.625 0 010 1.25h-1.125a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0v-5A1.626 1.626 0 0116.5 8.375h2a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});