define("ember-svg-jar/inlined/composition-frame-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>composition-frame-woman</title><path d=\"M12 15.026a6.593 6.593 0 00-4.876 2.164.5.5 0 00.37.836h9.012a.5.5 0 00.37-.836A6.593 6.593 0 0012 15.026zM7.452 14.541a1 1 0 001.383-.3 4.864 4.864 0 00.394-.746.251.251 0 01.364-.114 4.044 4.044 0 004.3 0 .249.249 0 01.363.114 4.937 4.937 0 00.4.748 1 1 0 101.68-1.085 3.856 3.856 0 01-.539-2.119v-1.09a4.052 4.052 0 00-8.1 0v1.089a3.856 3.856 0 01-.539 2.12 1 1 0 00.294 1.383zM11.744 12a2.055 2.055 0 01-2.023-1.711.251.251 0 01.152-.273 4.985 4.985 0 001.7-1.149.25.25 0 01.359 0 5 5 0 001.685 1.146.25.25 0 01.151.272A2.054 2.054 0 0111.744 12z\"/><path d=\"M21 3H3a3 3 0 00-3 3v12a3 3 0 003 3h18a3 3 0 003-3V6a3 3 0 00-3-3zm1 15a1 1 0 01-1 1H3a1 1 0 01-1-1V6a1 1 0 011-1h18a1 1 0 011 1zM2 1.5h20A.75.75 0 0022 0H2a.75.75 0 000 1.5zM22 22.5H2A.75.75 0 002 24h20a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});