define("ember-svg-jar/inlined/monetization-user-bag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monetization-user-bag</title><path d=\"M14.476 16.628h1.458a.251.251 0 01.249.226l.645 6.447a.5.5 0 00.5.45h2.85a.5.5 0 00.5-.45l.644-6.447a.251.251 0 01.249-.226h1.459a.5.5 0 00.354-.147.5.5 0 00.146-.353v-2.851a4.775 4.775 0 00-2.481-4.185.252.252 0 01-.13-.213.249.249 0 01.119-.219 4.081 4.081 0 10-4.373-.077.25.25 0 01-.026.432 4.761 4.761 0 00-2.657 4.262v2.851a.5.5 0 00.146.353.5.5 0 00.348.147zm5.024-4.16a.75.75 0 01-1.5 0v-1.692a.75.75 0 011.5 0zM16.919 4.6a.247.247 0 01.15-.159.253.253 0 01.218.02 5.1 5.1 0 003.583.649.092.092 0 01.073.02.086.086 0 01.033.068 2.076 2.076 0 01-4.151 0 1.97 1.97 0 01.094-.598zM6.893 16.25c3.629 0 6.583-2.691 6.583-6 0-3.567-2.784-5.231-4.121-6.031a.25.25 0 01-.089-.339l1.217-2.132A1 1 0 009.614.25H4.337a1 1 0 00-.868 1.5l1.2 2.1a.245.245 0 01.024.191.252.252 0 01-.121.152C3.178 4.945.476 6.607.476 10.25a6.153 6.153 0 006.417 6zM6.026 5.8a.25.25 0 00.2-.245V5.25a.75.75 0 011.5 0v.25a.249.249 0 00.25.25h.5a.75.75 0 010 1.5H6.442a.592.592 0 00-.219 1.142l2.062.825a2.083 2.083 0 01-.359 3.98.25.25 0 00-.2.245v.308a.75.75 0 01-1.5 0v-.25a.25.25 0 00-.25-.25h-.5a.75.75 0 110-1.5h2.033a.592.592 0 00.22-1.141l-2.063-.825a2.084 2.084 0 01.36-3.984z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});