define("ember-svg-jar/inlined/messages-people-woman-bubble-oval", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-woman-bubble-oval</title><path d=\"M11.173 17.386a.255.255 0 01-.173-.224v-.678a5.251 5.251 0 003.015-.768.5.5 0 00.132-.744c-.421-.5-.713-1.153-.842-3.059C13.059 8.278 10.607 7 8.5 7s-4.557 1.278-4.8 4.913c-.129 1.9-.421 2.563-.842 3.059a.5.5 0 00.131.744A5.267 5.267 0 006 16.484v.679a.257.257 0 01-.174.224c-2.868 1.062-4.434 1.82-5.034 3.021A7.587 7.587 0 000 23.5a.5.5 0 00.5.5h6.9a.25.25 0 00.231-.346L6.14 20.049a.248.248 0 01.06-.279.251.251 0 01.284-.039 4.679 4.679 0 004.21.037.25.25 0 01.338.327l-1.591 3.553a.251.251 0 00.228.352H16.5a.5.5 0 00.5-.5 7.581 7.581 0 00-.792-3.091c-.601-1.209-2.167-1.963-5.035-3.023z\"/><circle cx=\"13.116\" cy=\"5.5\" r=\"1\"/><circle cx=\"16.366\" cy=\"5.5\" r=\"1\"/><circle cx=\"19.616\" cy=\"5.5\" r=\"1\"/><path d=\"M18.5 0h-6a5.524 5.524 0 00-5.363 4.31 1 1 0 101.953.429A3.512 3.512 0 0112.5 2h6a3.5 3.5 0 110 7 1 1 0 00-.625.219l-.878.7A1 1 0 0016 9h-1a1 1 0 000 2v1a1 1 0 001.625.781l2.241-1.793A5.5 5.5 0 0018.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});