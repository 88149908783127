define("ember-svg-jar/inlined/night-moon-new-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>night-moon-new-1</title><path d=\"M15.122 2.184A11.572 11.572 0 004.434.668a1 1 0 000 1.9 9.943 9.943 0 010 18.878 1 1 0 000 1.9A12.489 12.489 0 008.387 24a11.438 11.438 0 006.749-2.21A12.311 12.311 0 0020.25 12a12.35 12.35 0 00-5.128-9.816zm-1.156 17.983a9.453 9.453 0 01-5.319 1.824.25.25 0 01-.157-.45 11.936 11.936 0 00-.01-19.083.25.25 0 01.156-.45 9.424 9.424 0 015.316 1.8 10.315 10.315 0 014.3 8.2 10.278 10.278 0 01-4.286 8.159z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});