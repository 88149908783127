define("ember-svg-jar/inlined/plant-flowers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plant-flowers</title><path d=\"M2.5 11.5h-1A1.5 1.5 0 000 13v9.5A1.5 1.5 0 001.5 24h1a.5.5 0 00.5-.5V12a.5.5 0 00-.5-.5zM22.5 11.5h-1a.5.5 0 00-.5.5v11.5a.5.5 0 00.5.5h1a1.5 1.5 0 001.5-1.5V13a1.5 1.5 0 00-1.5-1.5z\"/><rect x=\"4\" y=\"16\" width=\"16\" height=\"3.5\" rx=\".5\" ry=\".5\"/><rect x=\"4\" y=\"20.5\" width=\"16\" height=\"3.5\" rx=\".5\" ry=\".5\"/><path d=\"M19.764 11.575a.5.5 0 01-.23-.5A7.48 7.48 0 0121.07 8a.288.288 0 01.317-.1A1.972 1.972 0 0022 8a2 2 0 10-2-2 .233.233 0 01-.313.218.289.289 0 01-.187-.27V3.89a.289.289 0 01.125-.238 2 2 0 10-2.25 0 .289.289 0 01.125.238v2.061a.289.289 0 01-.188.27A.232.232 0 0117 6a2 2 0 10-2 2 1.972 1.972 0 00.613-.1.288.288 0 01.317.1 7.718 7.718 0 011.517 2.958.456.456 0 01-.447.542H7a.456.456 0 01-.447-.54A7.718 7.718 0 018.07 8a.288.288 0 01.317-.1A1.972 1.972 0 009 8a2 2 0 10-2-2 .233.233 0 01-.313.218.289.289 0 01-.187-.27V3.89a.289.289 0 01.125-.238 2 2 0 10-2.25 0 .289.289 0 01.125.238v2.061a.289.289 0 01-.188.27A.232.232 0 014 6a2 2 0 10-2 2 1.972 1.972 0 00.613-.1.288.288 0 01.317.1 7.48 7.48 0 011.536 3.073.5.5 0 01-.23.5A.5.5 0 004 12v2.5a.5.5 0 00.5.5h15a.5.5 0 00.5-.5V12a.5.5 0 00-.236-.425z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});