define("ember-svg-jar/inlined/coffee-cup-hot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>coffee-cup-hot</title><path d=\"M7.273 6.685a1 1 0 101.41-1.409.768.768 0 010-1.083 2.762 2.762 0 000-3.9A1 1 0 007.273 1.7a.768.768 0 010 1.083 2.762 2.762 0 000 3.902zM11.294 6.187a1 1 0 001.412-1.41.588.588 0 010-.833 2.588 2.588 0 000-3.652A1 1 0 0011.3 1.7a.591.591 0 010 .834 2.588 2.588 0 00-.006 3.653zM15.317 6.685a1 1 0 001.41-1.409.768.768 0 010-1.083 2.762 2.762 0 000-3.9 1 1 0 10-1.41 1.407.768.768 0 010 1.083 2.762 2.762 0 000 3.902zM19.321 15.02a.238.238 0 01-.1-.231 20.622 20.622 0 00.257-3.207C19.476 9.31 16.262 7.6 12 7.6s-7.476 1.71-7.476 3.982a20.622 20.622 0 00.257 3.207.238.238 0 01-.1.231 4.355 4.355 0 00-2.148 3.5C2.531 21.592 6.69 24 12 24s9.469-2.408 9.469-5.482a4.355 4.355 0 00-2.148-3.498zm-2.928 2.73a.981.981 0 01-.256.376A6.06 6.06 0 0112 19.556a6.068 6.068 0 01-4.137-1.429.96.96 0 01-.253-.371 15.436 15.436 0 01-.79-2.811.252.252 0 01.351-.274 12.181 12.181 0 004.829.9 12.182 12.182 0 004.819-.894.249.249 0 01.349.27 15.279 15.279 0 01-.775 2.803zm.07-5.116a.6.6 0 01-.4-.072A6.832 6.832 0 0012 11.541a6.861 6.861 0 00-4.049 1.012.656.656 0 01-.439.068c-.631-.344-.994-.722-.994-1.039 0-.793 2.186-1.993 5.482-1.993s5.482 1.2 5.482 1.993c0 .318-.373.705-1.019 1.052z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});