define("ember-svg-jar/inlined/feed-medium", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>feed-medium</title><path d=\"M24 6a1.5 1.5 0 00-.86-1.36l-6.81-3.16a1.5 1.5 0 00-1.87.52L10.6 8.31 8.81 5.15a1 1 0 00-.44-.33 1.49 1.49 0 00-.22-.15l-6-3a1.48 1.48 0 00-1.45.07A1.5 1.5 0 000 3v14.86a1.47 1.47 0 00.79 1.32l6 3.32a1.5 1.5 0 00.67.16 1.48 1.48 0 00.78-.22A1.5 1.5 0 009 21.16v-5.61l12.88 7a1.5 1.5 0 001.44-.08 1.49 1.49 0 00.7-1.27zM7 19.89a.25.25 0 01-.37.22l-4.36-2.4a.5.5 0 01-.27-.44v-13A.25.25 0 012.36 4l4.35 2.18a.5.5 0 01.29.44zm2.27-6.44A.5.5 0 019 13V9.62a.25.25 0 01.45-.14L12.88 15a.25.25 0 01-.32.37zM12 10.33a.5.5 0 010-.57l3.72-6.11a.25.25 0 01.31-.08L21.3 6a.25.25 0 01.11.36l-5.74 9.19a.25.25 0 01-.41 0zm10 9.62a.25.25 0 01-.36.22l-4.7-2.37a.25.25 0 01-.1-.35l4.7-7.53A.25.25 0 0122 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});