define("ember-svg-jar/inlined/spa-board", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>spa-board</title><path d=\"M22.5 11.292a2.5 2.5 0 00-2.5-2.5h-1.378a.5.5 0 01-.353-.146l-4.855-4.855a2 2 0 00-2.827 0L5.733 8.646a.5.5 0 01-.353.146H4a2.5 2.5 0 00-2.5 2.5v7a2.5 2.5 0 002.5 2.5h16a2.5 2.5 0 002.5-2.5zm-15.25 2.9a3.13 3.13 0 011.6 2.55 2.053 2.053 0 01-2.05 2.05H5.5a.75.75 0 010-1.5h1.3a.551.551 0 00.55-.55c0-.573-.343-.857-1-1.35a3.13 3.13 0 01-1.6-2.549 2.052 2.052 0 012.05-2.051h1.3a.75.75 0 010 1.5H6.8a.551.551 0 00-.55.551c.001.573.344.857 1 1.349zm4.2 1.99v1.86a.75.75 0 01-1.5 0v-6.5a.75.75 0 01.75-.75h.65a2.7 2.7 0 01.1 5.39zm3.532-7.39H9.019a.25.25 0 01-.177-.427l2.982-2.982a.25.25 0 01.354 0l2.982 2.982a.25.25 0 01-.177.427zm4.268 9.25a.75.75 0 01-1.5 0v-1.6a.249.249 0 00-.25-.25h-.6a.249.249 0 00-.25.25v1.6a.75.75 0 01-1.5 0v-5.2a2.05 2.05 0 114.1 0z\"/><path d=\"M11.792 12.392a.25.25 0 00-.341.233v1.735a.246.246 0 00.109.206.249.249 0 00.232.027 1.177 1.177 0 000-2.2zM17.2 12.292a.551.551 0 00-.55.551v1.6a.25.25 0 00.25.25h.6a.25.25 0 00.25-.25v-1.6a.551.551 0 00-.55-.551z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});