define("ember-svg-jar/inlined/rating-star-give-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rating-star-give-1</title><path d=\"M1.5 14.25a1 1 0 00-1 1v7.25a1 1 0 002 0v-7.25a1 1 0 00-1-1zM16 19H6.875a.625.625 0 010-1.25H9.4a1 1 0 00.919-1.393A2.233 2.233 0 008.264 15H4a.5.5 0 00-.5.5V22a.5.5 0 00.5.5h10.764A2.238 2.238 0 0017 20.264V20a1 1 0 00-1-1zM23.409 5.261A1.233 1.233 0 0022.24 4.5h-2.5a.25.25 0 01-.23-.151l-1.345-3.118-.013-.031a1.295 1.295 0 00-2.317.028l-1.345 3.12a.25.25 0 01-.23.151h-2.5a1.231 1.231 0 00-1.166.759 1.276 1.276 0 00.32 1.4l2.193 1.954a.249.249 0 01.063.286l-1.223 2.815a1.256 1.256 0 00.317 1.452 1.29 1.29 0 001.5.165l3.111-1.754a.251.251 0 01.246 0l3.112 1.754a1.283 1.283 0 001.5-.165 1.257 1.257 0 00.317-1.452L20.833 8.9a.249.249 0 01.067-.29l2.211-1.971a1.261 1.261 0 00.298-1.378z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});