define("ember-svg-jar/inlined/human-resources-team-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-team-settings</title><path d=\"M16.175 12.172a.991.991 0 00-.944-1.643l-1.09.252a.736.736 0 01-.87-.5L12.944 9.2a.986.986 0 00-1.888 0l-.327 1.076a.736.736 0 01-.87.5l-1.09-.252a.991.991 0 00-.944 1.643l.763.833a.744.744 0 010 1.009l-.763.823a.992.992 0 00.944 1.643l1.09-.253a.736.736 0 01.87.505l.327 1.075a.986.986 0 001.888 0l.327-1.075a.737.737 0 01.87-.505l1.09.253a.992.992 0 00.944-1.643L15.412 14a.744.744 0 010-1.009zM12 14.61a1.111 1.111 0 111.111-1.11A1.11 1.11 0 0112 14.61z\"/><circle cx=\"12\" cy=\"2.25\" r=\"1.75\"/><path d=\"M15.15 7.5a.251.251 0 00.233-.341 3.6 3.6 0 00-6.766 0 .251.251 0 00.233.341zM14.729 20.052a8.577 8.577 0 01-5.461 0 1 1 0 10-.643 1.893 10.527 10.527 0 006.746 0 1 1 0 00-.642-1.894zM15.8 4.394a8.518 8.518 0 014.6 8.948 1 1 0 00.829 1.145.956.956 0 00.159.013 1 1 0 00.986-.843A10.51 10.51 0 0016.7 2.605a1 1 0 00-.9 1.789zM2.616 14.5a.924.924 0 00.157-.013 1 1 0 00.827-1.145A8.694 8.694 0 013.5 12a8.462 8.462 0 014.7-7.607 1 1 0 10-.894-1.788A10.449 10.449 0 001.5 12a10.634 10.634 0 00.13 1.655 1 1 0 00.986.845z\"/><circle cx=\"4\" cy=\"18.25\" r=\"1.75\"/><path d=\"M7.15 23.5a.251.251 0 00.233-.341 3.6 3.6 0 00-6.766 0 .251.251 0 00.233.341z\"/><circle cx=\"20\" cy=\"18.25\" r=\"1.75\"/><path d=\"M23.15 23.5a.251.251 0 00.233-.341 3.6 3.6 0 00-6.766 0 .251.251 0 00.233.341z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});