define("ember-svg-jar/inlined/checklist-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>checklist-alternate</title><path d=\"M21 0H3a3 3 0 00-3 3v18a3 3 0 003 3h18a3 3 0 003-3V3a3 3 0 00-3-3zm1 21a1 1 0 01-1 1H3a1 1 0 01-1-1V3a1 1 0 011-1h18a1 1 0 011 1z\"/><path d=\"M11.249 4.5a1.251 1.251 0 00-1.75.25L7.365 7.6l-.482-.481a1.25 1.25 0 00-1.767 1.764l1.5 1.5a1.262 1.262 0 001.884-.134l3-4a1.25 1.25 0 00-.251-1.749zM11.249 13.5a1.251 1.251 0 00-1.75.25L7.365 16.6l-.482-.481a1.25 1.25 0 10-1.767 1.768l1.5 1.5a1.265 1.265 0 001.884-.138l3-4a1.25 1.25 0 00-.251-1.749zM18.5 7.749H14a1.25 1.25 0 000 2.5h4.5a1.25 1.25 0 000-2.5zM18.5 15.749H14a1.25 1.25 0 000 2.5h4.5a1.25 1.25 0 100-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});