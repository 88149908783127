define("ember-svg-jar/inlined/folder-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-settings</title><path d=\"M10.882 16.674a3.282 3.282 0 013.139-5.434s.12.029.249.058a.211.211 0 00.263-.138l.083-.272a3.274 3.274 0 016.143-.343A.125.125 0 0021 10.5v-6A1.5 1.5 0 0019.5 3H9.618a.5.5 0 01-.447-.276L8.224.83A1.491 1.491 0 006.882 0H1.5A1.5 1.5 0 000 1.5v15A1.5 1.5 0 001.5 18h8.682a.433.433 0 00.377-.189 2.959 2.959 0 01.324-.422l.216-.234a.193.193 0 00-.009-.257z\"/><circle cx=\"17.748\" cy=\"17.031\" r=\"1.5\"/><path d=\"M22.528 17.344a.463.463 0 010-.625l.988-1.065a1.782 1.782 0 00-1.7-2.953l-1.411.326a.452.452 0 01-.533-.31l-.424-1.393a1.775 1.775 0 00-3.4 0l-.424 1.393a.452.452 0 01-.533.31l-1.41-.327a1.782 1.782 0 00-1.7 2.952l.988 1.066a.463.463 0 010 .625l-.988 1.064a1.782 1.782 0 001.7 2.953l1.411-.326a.453.453 0 01.533.311l.424 1.391a1.774 1.774 0 003.4 0l.424-1.392a.451.451 0 01.533-.311l1.411.326a1.782 1.782 0 001.7-2.952zm-4.78 2.687a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});