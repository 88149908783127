define("ember-svg-jar/inlined/single-neutral", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral</title><path d=\"M22.947 19.22c-.734-1.47-3.241-2.391-6.71-3.667-.66-.241-1.076-.341-1.262-.878a1.711 1.711 0 01.155-1.589c1.375-1.486 2.05-3.712 2.05-6.586 0-3.8-2.6-5.5-5.18-5.5S6.82 2.7 6.82 6.5c0 2.874.675 5.1 2.05 6.586a1.711 1.711 0 01.155 1.589c-.186.537-.6.637-1.262.878-3.469 1.276-5.976 2.2-6.71 3.667A10.57 10.57 0 000 23.5a.5.5 0 00.5.5h23a.5.5 0 00.5-.5 10.57 10.57 0 00-1.053-4.28z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});