define("ember-svg-jar/inlined/single-neutral-actions-monitor.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-monitor</title><path d=\"M9.5 13a3.484 3.484 0 011.251-2.672.25.25 0 00.059-.31.931.931 0 01-.078-.23 2.632 2.632 0 01.058-1.3 5.618 5.618 0 001.466-4.314C12.256 1.756 10.677 0 8.5 0S4.744 1.756 4.744 4.174A5.605 5.605 0 006.2 8.471a2.505 2.505 0 01.073 1.317c-.086.368-.094.292-.767.541-2.446.9-4.214 1.549-4.753 2.626A9.167 9.167 0 000 16.5a.5.5 0 00.5.5h8.75a.25.25 0 00.25-.25zM20 22h-1.25a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25H22a2 2 0 002-2v-6a2 2 0 00-2-2h-9a2 2 0 00-2 2v6a2 2 0 002 2h3.25a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H15a.987.987 0 00-.847.5.974.974 0 00-.153.5 1 1 0 001 1h5a1 1 0 001-1 .974.974 0 00-.153-.5A.987.987 0 0020 22zm-7-8.75a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v5.5a.249.249 0 01-.25.25h-8.5a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});