define("ember-svg-jar/inlined/tank-top-female-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tank-top-female-2</title><path d=\"M18.733 17.26a7.246 7.246 0 01.958-3.16c.959-1.985 1.947-4.033-1.053-7.189a.5.5 0 01-.138-.344V1a1 1 0 00-2 0v6.422a.5.5 0 01-.177.382A7.173 7.173 0 0112 9a7.173 7.173 0 01-4.323-1.2.5.5 0 01-.177-.378V1a1 1 0 00-2 0v5.567a.5.5 0 01-.138.344c-3 3.156-2.012 5.2-1.053 7.189a7.246 7.246 0 01.958 3.16 10.16 10.16 0 01-1.121 4.784 1.352 1.352 0 00.038 1.3 1.307 1.307 0 001.1.66h13.433a1.3 1.3 0 001.1-.657 1.354 1.354 0 00.039-1.3 10.157 10.157 0 01-1.123-4.787z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});