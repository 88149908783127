define("ember-svg-jar/inlined/navigation-car-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-car-pin</title><path d=\"M18 0a6.006 6.006 0 00-6 6c0 1.647 1.118 4.579 2.372 7.353a.251.251 0 00.228.147h.9a5 5 0 014.385 2.6.249.249 0 00.443-.01C21.984 12.741 24 8.212 24 6a6.006 6.006 0 00-6-6zm0 9.5a3 3 0 113-3 3 3 0 01-3 3z\"/><path d=\"M19.5 20.5h-.25a.25.25 0 01-.25-.25V18.5a3.5 3.5 0 00-3.5-3.5h-1.246a.251.251 0 01-.19-.087L12.853 13.5l-2.745-3.2A2.994 2.994 0 007.83 9.25h-.095a.25.25 0 01-.208-.111l-.611-.916A.5.5 0 006.5 8H2.25a.5.5 0 00-.416.223l-.611.916a.25.25 0 01-.208.111H1a1 1 0 000 2h1.5a.25.25 0 01.25.25v3.25a.25.25 0 01-.25.25H.75a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h5.009a.251.251 0 00.25-.231 3 3 0 015.982 0 .25.25 0 00.25.231H19.5a.75.75 0 000-1.5zm-8.311-5.646a.251.251 0 01-.228.146H5a.25.25 0 01-.25-.25V11.5a.25.25 0 01.25-.25h2.83a1 1 0 01.759.349l1.63 1.9.932 1.087a.251.251 0 01.038.268z\"/><circle cx=\"9\" cy=\"22\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});