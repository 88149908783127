define("ember-svg-jar/inlined/medical-instrument-syringe-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-instrument-syringe-1</title><path d=\"M8.5 2h1a.5.5 0 01.5.5V3a.5.5 0 00.5.5h3A.5.5 0 0014 3v-.5a.5.5 0 01.5-.5h1a1 1 0 000-2h-7a1 1 0 000 2zM18.5 5h-13a1 1 0 000 2h2.251A.249.249 0 018 7.25v1a.5.5 0 00.5.5h3a.75.75 0 010 1.5h-3a.5.5 0 00-.5.5v1.5a.5.5 0 00.5.5h3a.75.75 0 010 1.5h-3a.5.5 0 00-.5.5v.75a4.5 4.5 0 002.6 4.192.25.25 0 01.152.23v2.828a1.25 1.25 0 002.5 0v-2.828a.251.251 0 01.153-.23A4.5 4.5 0 0016 15.5V7.25a.25.25 0 01.25-.25h2.25a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});