define("ember-svg-jar/inlined/instrument-contrabass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>instrument-contrabass</title><path d=\"M3.708 20.293a1 1 0 00-1.415 0l-2 2a1 1 0 001.415 1.414l2-2a1 1 0 000-1.414z\"/><path d=\"M23.091 2.677a.25.25 0 010-.354l.616-.616A1 1 0 0022.292.293l-.615.616a.251.251 0 01-.354 0l-.615-.616a1 1 0 00-1.415 1.414l.616.616a.25.25 0 010 .354l-.747.747a.252.252 0 01-.354 0l-.6-.6a1 1 0 00-1.415 1.414l.6.6a.249.249 0 010 .353l-1.25 1.252a.25.25 0 01-.314.031 5.928 5.928 0 00-7.895 1.264.5.5 0 00-.1.389A2.662 2.662 0 016.8 10.7a2.7 2.7 0 01-2.757.339 1.5 1.5 0 00-1.737.362 7.306 7.306 0 00-1.043 8.044.249.249 0 00.392.075 2 2 0 012.825 2.825.248.248 0 00-.06.215.252.252 0 00.136.178 7.455 7.455 0 003.268.751A7.117 7.117 0 0012.6 21.7a1.493 1.493 0 00.362-1.737A2.7 2.7 0 0113.3 17.2a2.654 2.654 0 012.577-1.034.507.507 0 00.388-.1 5.925 5.925 0 001.263-7.9.25.25 0 01.033-.314l1.25-1.25a.252.252 0 01.354 0l.6.6a1 1 0 101.414-1.414l-.6-.6a.25.25 0 010-.353l.748-.748a.25.25 0 01.354 0l.6.6a1 1 0 001.414-1.415z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});