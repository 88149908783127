define("ember-svg-jar/inlined/book-next-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-next-page</title><path d=\"M12.75 21.658a.25.25 0 00.38.214 13.383 13.383 0 014.417-1.545.251.251 0 00.2-.246v-4.376a1.363 1.363 0 011.361-1.362H22.5c.169 0 .491-.005.742 0a.249.249 0 00.258-.25V3.1a1 1 0 00-1.006-1h-.008c-5.213.069-8.139 1.171-9.608 2a.25.25 0 00-.128.218zM11.25 4.31a.251.251 0 00-.128-.219C9.653 3.267 6.726 2.165 1.511 2.1a.836.836 0 00-.711.285 1.008 1.008 0 00-.3.715v15.762a1 1 0 00.986 1c5.388.069 8.18 1.278 9.384 2.012a.249.249 0 00.38-.214z\"/><path d=\"M19.5 15.843a.25.25 0 00-.25.25v3.334a.25.25 0 00.423.18 185.61 185.61 0 003.355-3.345.251.251 0 00-.179-.425z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});