define("ember-svg-jar/inlined/gesture-tap-two-fingers-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-tap-two-fingers-clock</title><path d=\"M7 13a3 3 0 00-3 3v7.573a.429.429 0 00.429.427h5.142a.429.429 0 00.429-.429V16a3 3 0 00-3-3zm1.5 4a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5v-1a1.5 1.5 0 013 0zM14.5 10.5a3 3 0 00-3 3v10.071a.429.429 0 00.429.428h5.142a.429.429 0 00.429-.428V13.5a3 3 0 00-3-3zm1.5 4a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1a1.5 1.5 0 013 0zM18 10.749a.138.138 0 00-.11.219 4.186 4.186 0 01.86 2.531 4.245 4.245 0 01-.056.69.75.75 0 101.48.243 5.708 5.708 0 00-.642-3.7.25.25 0 00-.272-.123 5.876 5.876 0 01-1.26.14zM13.045 8.12a.25.25 0 00-.281-.1 5.682 5.682 0 00-1.423.672.75.75 0 10.824 1.253 4.146 4.146 0 011.422-.591.249.249 0 00.127-.419 6.035 6.035 0 01-.669-.815z\"/><path d=\"M7 11.749a4.247 4.247 0 011.006.12.75.75 0 10.353-1.458 5.755 5.755 0 00-6.928 7.025.75.75 0 101.453-.373A4.255 4.255 0 017 11.749zM18 0a4.75 4.75 0 104.75 4.75A4.756 4.756 0 0018 0zm0 8a3.25 3.25 0 113.25-3.25A3.254 3.254 0 0118 8z\"/><path d=\"M19.768 4H19a.25.25 0 01-.25-.25v-.769a.75.75 0 00-1.5 0v1.768A.75.75 0 0018 5.5h1.768a.75.75 0 100-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});