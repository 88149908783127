define("ember-svg-jar/inlined/team-meeting-message-men-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>team-meeting-message-men-lock</title><path d=\"M19.017 12.016a1.753 1.753 0 001.75-1.75V1.749A1.753 1.753 0 0019.017 0h-3a.75.75 0 000 1.5h3a.251.251 0 01.25.25v8.517a.251.251 0 01-.25.25h-4a.749.749 0 00-.531.22l-1.293 1.293a.25.25 0 01-.426-.177v-.587a.75.75 0 00-.75-.75h-7a.25.25 0 01-.25-.25V1.749a.251.251 0 01.25-.25h3a.75.75 0 000-1.5h-3a1.753 1.753 0 00-1.75 1.75v8.517a1.752 1.752 0 001.75 1.75h6a.25.25 0 01.25.25v2a.75.75 0 00.462.693.762.762 0 00.288.057.748.748 0 00.53-.22l2.707-2.71a.249.249 0 01.177-.073z\"/><path d=\"M8.267 4.769v3a1.752 1.752 0 001.75 1.75h4a1.753 1.753 0 001.75-1.75v-3a1.75 1.75 0 00-.876-1.515.25.25 0 01-.124-.216v-.269a2.75 2.75 0 00-5.5 0v.269a.251.251 0 01-.125.216 1.749 1.749 0 00-.875 1.515zm6 3a.251.251 0 01-.25.25h-4a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25h4a.251.251 0 01.25.25zm-3.5-5a1.25 1.25 0 112.5 0 .25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25z\"/><circle cx=\"12.017\" cy=\"6.288\" r=\".75\"/><path d=\"M5 21.224a3.961 3.961 0 10-3.961-3.961A3.966 3.966 0 005 21.224zm0-2a1.961 1.961 0 01-1.885-2.5.248.248 0 01.148-.163.251.251 0 01.22.017 4.918 4.918 0 003.385.608.08.08 0 01.094.079A1.964 1.964 0 015 19.224zM5 21.591a4.548 4.548 0 00-3.787 2.019.25.25 0 00.207.39h7.155a.25.25 0 00.208-.389A4.552 4.552 0 005 21.591zM15.069 17.3a3.948 3.948 0 103.948-3.947 3.952 3.952 0 00-3.948 3.947zm3.948 1.947a1.95 1.95 0 01-1.948-1.947 1.924 1.924 0 01.075-.534.249.249 0 01.368-.146 4.894 4.894 0 003.359.6.078.078 0 01.064.017.079.079 0 01.029.061 1.949 1.949 0 01-1.947 1.944zM19.017 21.6a4.532 4.532 0 00-3.832 2.109.187.187 0 00.158.288h7.232a.25.25 0 00.207-.389 4.53 4.53 0 00-3.765-2.008z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});