define("ember-svg-jar/inlined/safety-warning-electricity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-warning-electricity</title><path d=\"M23.9 22.163L12.81.974a.948.948 0 00-1.619 0L.105 22.159a.891.891 0 00-.1.421.9.9 0 00.9.9H23.1a.9.9 0 00.9-.9.885.885 0 00-.1-.417zm-9.673-6.952l-1.338 2.675a.251.251 0 00.125.342l.686.293a.5.5 0 01-.011.924l-2.5 1a.488.488 0 01-.186.036.5.5 0 01-.464-.314l-1-2.5a.5.5 0 01.115-.543.5.5 0 01.546-.1l.973.418a.25.25 0 00.322-.118L12.6 15.1a.249.249 0 00-.223-.361h-1.881a1.334 1.334 0 01-1.258-1.979l2.581-5.593a.75.75 0 011.362.629l-2.344 5.077a.253.253 0 00.017.24.247.247 0 00.21.115H13a1.312 1.312 0 011.224 1.98z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});