define("ember-svg-jar/inlined/server-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-3</title><path d=\"M22.5 1.5h-21A1.5 1.5 0 000 3v3a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 6V3a1.5 1.5 0 00-1.5-1.5zm-19.25 3A1.25 1.25 0 114.5 5.75 1.251 1.251 0 013.25 4.5zM8.5 5.75A1.25 1.25 0 119.75 4.5 1.252 1.252 0 018.5 5.75zM22.5 9h-21A1.5 1.5 0 000 10.5v3A1.5 1.5 0 001.5 15h21a1.5 1.5 0 001.5-1.5v-3A1.5 1.5 0 0022.5 9zM3.25 12a1.25 1.25 0 111.25 1.25A1.251 1.251 0 013.25 12zm5.25 1.25A1.25 1.25 0 119.75 12a1.252 1.252 0 01-1.25 1.25zM22.5 16.5h-21A1.5 1.5 0 000 18v3a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 21v-3a1.5 1.5 0 00-1.5-1.5zm-19.25 3a1.25 1.25 0 111.25 1.25 1.251 1.251 0 01-1.25-1.25zm5.25 1.25a1.25 1.25 0 111.25-1.25 1.252 1.252 0 01-1.25 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});