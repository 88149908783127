define("ember-svg-jar/inlined/taking-pictures-human", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>taking-pictures-human</title><path d=\"M22.947 18.72c-.734-1.47-3.241-2.391-6.71-3.667l-.91-.335c-.114-.042-.228-.228-.3-.5a3.7 3.7 0 01.108-2.166C16.167 10.914 17.18 9.506 17.18 6c0-3.8-2.6-5.5-5.18-5.5a4.942 4.942 0 00-4.781 3.161.25.25 0 00.028.231.247.247 0 00.206.108h.929a2.789 2.789 0 012.155.919.251.251 0 00.184.081H11a3 3 0 013 3v5.5a3 3 0 01-3 3H4.1a.24.24 0 00-.1.022 5.774 5.774 0 00-2.948 2.2A10.567 10.567 0 000 23a.5.5 0 00.5.5h23a.5.5 0 00.5-.5 10.567 10.567 0 00-1.053-4.28z\"/><path d=\"M11 15a1.5 1.5 0 001.5-1.5V8A1.5 1.5 0 0011 6.5H9.96a.249.249 0 01-.219-.129A1.45 1.45 0 008.382 5.5H6.118a1.45 1.45 0 00-1.359.871.249.249 0 01-.219.129H1.5A1.5 1.5 0 000 8v5.5A1.5 1.5 0 001.5 15zM7.25 8.25A2.25 2.25 0 115 10.5a2.25 2.25 0 012.25-2.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});