define("ember-svg-jar/inlined/programming-language-http", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-language-http</title><path d=\"M13.67 15.928h-2.13a.75.75 0 000 1.5h.06a.249.249 0 01.25.25V22a.75.75 0 001.5 0v-4.322a.25.25 0 01.25-.25h.066a.75.75 0 000-1.5zM18.46 15.928h-2.129a.75.75 0 000 1.5h.069a.25.25 0 01.25.25V22a.75.75 0 001.5 0v-4.322a.249.249 0 01.25-.25h.064a.75.75 0 000-1.5zM8.879 15.928a.75.75 0 00-.75.75v2.193a.25.25 0 01-.25.25H7.25a.25.25 0 01-.25-.25v-2.193a.75.75 0 00-1.5 0V22A.75.75 0 007 22v-1.129a.25.25 0 01.25-.25h.629a.25.25 0 01.25.25V22a.75.75 0 001.5 0v-5.322a.75.75 0 00-.75-.75zM21.653 15.928h-.532a.75.75 0 00-.75.75V22a.75.75 0 001.5 0v-1.184a.25.25 0 01.205-.245 2.34 2.34 0 00-.423-4.643zm.661 2.865a.25.25 0 01-.443-.16v-.717a.25.25 0 01.443-.16.816.816 0 010 1.037z\"/><path d=\"M24 4.25a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3 1 1 0 001-1 1 1 0 00-1-1 1 1 0 01-1-1V6.5a.25.25 0 01.25-.25h19.5a.25.25 0 01.25.25v6.25a1 1 0 001 1 1 1 0 001-1zm-19.654 0a.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.979.979 0 01.092-.408A1 1 0 013.5 2.75a.985.985 0 01.846.5.969.969 0 01.154.5.969.969 0 01-.154.5zm3.5 0a.966.966 0 01-1.692 0A.969.969 0 016 3.75a.969.969 0 01.154-.5.966.966 0 011.692 0 .969.969 0 01.154.5.969.969 0 01-.154.5zm3.5 0a.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.969.969 0 01.154-.5.966.966 0 011.692 0 .969.969 0 01.154.5.969.969 0 01-.154.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});