define("ember-svg-jar/inlined/read-light-idea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-light-idea</title><path d=\"M23.25 11.748a.75.75 0 00-.75.75v7.221a.256.256 0 01-.256.249 23.465 23.465 0 00-8.779 1.7.5.5 0 01-.715-.452V9.37c0-2.686-5.682-4.078-10.961-4.235a1.7 1.7 0 00-1.255.49A1.764 1.764 0 000 6.886v12.833a1.747 1.747 0 001.713 1.748c4.036.116 7.69.961 9.093 2.1a1.876 1.876 0 002.388 0c1.4-1.143 5.057-1.988 9.093-2.1A1.747 1.747 0 0024 19.719V12.5a.75.75 0 00-.75-.752zm-12 9.464a.5.5 0 01-.715.452 23.465 23.465 0 00-8.779-1.7.256.256 0 01-.256-.249V6.886a.256.256 0 01.079-.186.2.2 0 01.165-.067c6.241.186 9.506 1.88 9.506 2.736z\"/><path d=\"M9.258 10.149a21.869 21.869 0 00-5.186-1.02.742.742 0 00-.818.673.751.751 0 00.674.82 20.181 20.181 0 014.851.949.75.75 0 00.479-1.422zM9.258 13.822A21.854 21.854 0 004.072 12.8a.75.75 0 00-.144 1.493 20.224 20.224 0 014.851.95.75.75 0 00.479-1.422zM9.258 17.5a21.854 21.854 0 00-5.186-1.021.75.75 0 00-.144 1.493 20.224 20.224 0 014.851.95.75.75 0 00.479-1.422zM18.5 3a1 1 0 001-1V1a1 1 0 00-2 0v1a1 1 0 001 1zM22.354 4.122a1 1 0 00.764-.355L23.764 3a1 1 0 10-1.528-1.291l-.645.763a1 1 0 00.763 1.646zM13.882 3.767a1 1 0 101.527-1.291l-.645-.763A1 1 0 1013.236 3zM18.5 16.75a.75.75 0 00.75-.75v-.242a.469.469 0 01.237-.407 2 2 0 001.013-1.739v-.783a.5.5 0 01.25-.433 4.5 4.5 0 10-4.5 0 .5.5 0 01.25.433v.783a2 2 0 001.013 1.739.469.469 0 01.237.407V16a.75.75 0 00.75.75zM16 8.5a2.5 2.5 0 112.5 2.5A2.5 2.5 0 0116 8.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});