define("ember-svg-jar/inlined/milk-can", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>milk-can</title><path d=\"M21.4 7.111a1.251 1.251 0 00-1.767.009l-1.2 1.215a.5.5 0 01-.745-.039l-1.077-1.335a.5.5 0 01-.111-.313V5a.5.5 0 00-.5-.5H8a.5.5 0 00-.5.5v1.648a.5.5 0 01-.11.313L6.316 8.3a.5.5 0 01-.745.039L4.367 7.12a1.25 1.25 0 10-1.776 1.76l1.79 1.808a.5.5 0 01.141.419 2.23 2.23 0 00-.022.307V21.5A2.5 2.5 0 007 24h10a2.5 2.5 0 002.5-2.5V11.414a2.244 2.244 0 00-.021-.308.5.5 0 01.14-.418l1.79-1.808a1.251 1.251 0 00-.009-1.769zM6.551 3h10.9a.5.5 0 00.491-.6A3 3 0 0015 0H9a3 3 0 00-2.94 2.4.5.5 0 00.491.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});