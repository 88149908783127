define("ember-svg-jar/inlined/toilet-sign-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toilet-sign-2</title><path d=\"M8.074 11.26a3.188 3.188 0 00-.9-1.411.5.5 0 01.058-.785 4 4 0 10-4.466 0 .5.5 0 01.058.785 3.188 3.188 0 00-.9 1.411l-1.9 6.091A.5.5 0 00.5 18h1.367a.5.5 0 01.5.438L3 23.563a.5.5 0 00.5.437h3a.5.5 0 00.5-.437l.641-5.125a.5.5 0 01.5-.438H9.5a.5.5 0 00.477-.649zM4.663 4.845a.5.5 0 01.675 0 4.723 4.723 0 001.3.842.5.5 0 01.282.589 1.993 1.993 0 01-3.848 0 .5.5 0 01.282-.589 4.743 4.743 0 001.309-.842zM12 0a1 1 0 00-1 1v22a1 1 0 002 0V1a1 1 0 00-1-1zM21.524 9.7a.5.5 0 01-.056-.825 4 4 0 10-4.936 0 .5.5 0 01-.056.825A4.979 4.979 0 0014 14v2.5a.5.5 0 00.5.5h1.352a.5.5 0 01.5.447L17 23.554a.5.5 0 00.5.446h3a.5.5 0 00.5-.446l.654-6.107a.5.5 0 01.5-.447H23.5a.5.5 0 00.5-.5V14a4.979 4.979 0 00-2.476-4.3zm-4.472-4.392a.5.5 0 01.7-.338 5.764 5.764 0 002.419.53 5.034 5.034 0 00.524-.029.283.283 0 01.217.071.28.28 0 01.088.208 2 2 0 01-4 0 1.9 1.9 0 01.052-.442z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});