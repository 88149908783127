define("ember-svg-jar/inlined/love-lollipop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-lollipop</title><path d=\"M18.5 6a.5.5 0 01-.5-.5 5.5 5.5 0 00-11 0V16a1 1 0 001 1h10.5a5.5 5.5 0 000-11zm0 8h-8a.5.5 0 01-.5-.5v-8a2.5 2.5 0 015 0V8a1 1 0 001 1h2.5a2.5 2.5 0 010 5zM5.232 17.354a.5.5 0 00-.707 0L.439 21.439a1.5 1.5 0 002.122 2.122l4.085-4.086a.5.5 0 000-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});