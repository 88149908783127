define("ember-svg-jar/inlined/headphones-bluetooth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>headphones-bluetooth</title><path d=\"M12 3.994a9.885 9.885 0 00-7.032 2.915 1 1 0 001.414 1.414A7.9 7.9 0 0112 5.994a7.9 7.9 0 015.619 2.329 1 1 0 001.414-1.414A9.883 9.883 0 0012 3.994z\"/><path d=\"M3.85 5.372a11.54 11.54 0 0116.3 0 1 1 0 001.414-1.414 13.544 13.544 0 00-19.128 0A1 1 0 103.85 5.372zM19.849 15.492a.251.251 0 01-.183-.22 7.7 7.7 0 00-15.332 0 .251.251 0 01-.183.22 4.029 4.029 0 00-2.959 4.182 4.048 4.048 0 003.563 4.308 1.36 1.36 0 001.55-1.346v-6.695a5.7 5.7 0 1111.39 0v6.695a1.359 1.359 0 001.551 1.346 4.048 4.048 0 003.562-4.308 4.029 4.029 0 00-2.959-4.182z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});