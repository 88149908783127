define("ember-svg-jar/inlined/wifi-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-download</title><path d=\"M15.147 8.409A1 1 0 1016.562 7a9.768 9.768 0 00-13.8 0 1 1 0 001.416 1.409 7.764 7.764 0 0110.969 0z\"/><path d=\"M17.624 5.277a1 1 0 101.414-1.414 13.276 13.276 0 00-18.753 0A1 1 0 101.7 5.277a11.276 11.276 0 0115.924 0zM12.036 11.04a1 1 0 101.117-1.659 6.267 6.267 0 00-7.914.768 1 1 0 101.414 1.414 4.264 4.264 0 015.383-.523zM17.631 11.258a6.378 6.378 0 106.377 6.378 6.386 6.386 0 00-6.377-6.378zm1 3.443v2.674a.249.249 0 00.25.25h.707a.5.5 0 01.391.813l-1.958 2.447a.5.5 0 01-.781 0l-1.958-2.447a.5.5 0 01.391-.813h.708a.249.249 0 00.25-.25V14.7a1 1 0 012 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});