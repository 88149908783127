define("ember-svg-jar/inlined/award-oscar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>award-oscar</title><path d=\"M14.434 13.5a.5.5 0 00.5-.469l.116-1.861H16a.5.5 0 00.5-.453l.331-3.461a1.492 1.492 0 00-1.006-1.571 10.972 10.972 0 00-7.633 0 1.511 1.511 0 00-1.019 1.582c.119 1.159.329 3.448.329 3.449a.5.5 0 00.5.454h.954l.115 1.861a.5.5 0 00.5.469z\"/><path d=\"M12 5a2.5 2.5 0 002.5-2.5v-1A1.5 1.5 0 0013 0h-2a1.5 1.5 0 00-1.5 1.5v1A2.5 2.5 0 0012 5zM10.181 18.021a6.214 6.214 0 013.636 0l2.025.675a.5.5 0 00.658-.475V16.5A1.5 1.5 0 0015 15H9a1.5 1.5 0 00-1.5 1.5v1.721a.5.5 0 00.659.475zM17.21 20.71l-4.1-1.368a4.03 4.03 0 00-2.213 0l-4.1 1.367A2.087 2.087 0 005.5 22.5 1.5 1.5 0 007 24h10a1.5 1.5 0 001.5-1.5 2.085 2.085 0 00-1.29-1.79z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});