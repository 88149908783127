define("ember-svg-jar/inlined/color-tube-painting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-tube-painting</title><path d=\"M23.232 3.421l-3.285-1.71a1.424 1.424 0 00-1.923.606l-.936 1.8a.247.247 0 00-.017.19.249.249 0 00.123.147l5.371 2.8a.25.25 0 00.337-.107l.936-1.8a1.423 1.423 0 00-.606-1.926zM12.885 12.73a.253.253 0 00-.191-.016.247.247 0 00-.146.123l-.936 1.8a1.424 1.424 0 00.606 1.923l3.285 1.71a1.428 1.428 0 001.923-.606l.936-1.8a.25.25 0 00-.106-.337zM16.732 5.34a.25.25 0 00-.337.106l-3.154 6.06a.249.249 0 00-.017.191.252.252 0 00.123.147l5.371 2.8a.25.25 0 00.337-.106l3.154-6.061a.246.246 0 00.017-.19.252.252 0 00-.123-.147zM14.981 19.33L11.7 17.621a.5.5 0 00-.721.346l-.5 2.5a1.634 1.634 0 00.819 1.877 1.616 1.616 0 001.986-.385l1.816-1.832a.5.5 0 00-.119-.797zM3.01 3.736a2.006 2.006 0 012.009.014c1.8 1.06 3.706 2.186 4.822 2.85a1.827 1.827 0 01.979 1.3.779.779 0 01-.208.659 1 1 0 001.338 1.487 2.785 2.785 0 00.861-2.346 3.783 3.783 0 00-1.943-2.816c-1.118-.67-3.029-1.8-4.833-2.861A4 4 0 000 5.471a3.744 3.744 0 001.767 3.357 43.521 43.521 0 014.75 3.306.526.526 0 01.048.667c-.248.354-.849.1-1.1-.026a3.078 3.078 0 00-4.144 1.2c-.717 1.269-.545 3.11 1.456 4.361A19.874 19.874 0 007.192 20.4a1 1 0 00.616-1.9 17.923 17.923 0 01-3.965-1.86c-.8-.5-1.089-1.127-.776-1.68a1.1 1.1 0 011.5-.4 2.7 2.7 0 003.644-.61 2.519 2.519 0 00-.33-3.278 45.052 45.052 0 00-5.06-3.542A1.759 1.759 0 012 5.471a2 2 0 011.01-1.735z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});