define("ember-svg-jar/inlined/presentation-microphone-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>presentation-microphone-1</title><path d=\"M15.25 4.75a.25.25 0 00.25-.25v-1a3.5 3.5 0 00-7 0v1a.25.25 0 00.25.25z\"/><path d=\"M22.482 4.693a.252.252 0 01-.033-.223 1.5 1.5 0 00-.363-1.531l-.793-.793a1.852 1.852 0 00-2.707 0 1.852 1.852 0 000 2.707l.793.793a1.49 1.49 0 001.06.438 1.576 1.576 0 00.316-.032.25.25 0 01.286.157 2.14 2.14 0 01-.309 2.083L18.1 10.926a.252.252 0 01-.177.074H13a.25.25 0 01-.25-.25V9.114a.25.25 0 01.183-.241 3.509 3.509 0 002.525-2.835.249.249 0 00-.247-.288H8.789a.249.249 0 00-.247.288 3.509 3.509 0 002.525 2.835.251.251 0 01.183.241v1.636A.25.25 0 0111 11H6.078a.252.252 0 01-.177-.074L3.268 8.292a2.14 2.14 0 01-.309-2.083.25.25 0 01.286-.157 1.576 1.576 0 00.316.032 1.49 1.49 0 001.06-.438l.793-.793a1.852 1.852 0 000-2.707 1.852 1.852 0 00-2.707 0l-.793.793a1.5 1.5 0 00-.363 1.531.252.252 0 01-.033.223 4.134 4.134 0 00.336 5.014L3 10.851a.25.25 0 01.071.215.255.255 0 01-.132.184 1.922 1.922 0 00-.8.763 2.076 2.076 0 00.089 2.115L4.2 17.415A3.472 3.472 0 007 19h2.25a.249.249 0 01.25.25v4.25a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-4.25a.249.249 0 01.25-.25H17a3.47 3.47 0 002.8-1.586l1.97-3.285a2.078 2.078 0 00.089-2.116 1.922 1.922 0 00-.8-.763.255.255 0 01-.132-.184.251.251 0 01.073-.215l1.144-1.144a4.134 4.134 0 00.338-5.014z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});