define("ember-svg-jar/inlined/gauge-dashboard-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gauge-dashboard-1-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm7.327 18.8a15.838 15.838 0 00-14.655 0 10 10 0 1114.655 0z\"/><path d=\"M5.5 11.25H4a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5zM6.97 5.91a.75.75 0 10-1.06 1.06l1.06 1.06a.75.75 0 101.06-1.06zM12 6.25a.75.75 0 00.75-.75V4a.75.75 0 00-1.5 0v1.5a.75.75 0 00.75.75zM20 11.25h-1.5a.75.75 0 000 1.5H20a.75.75 0 000-1.5zM18.354 6.646a.5.5 0 00-.611-.075c-.689.414-6.753 4.066-7.511 4.824a2.389 2.389 0 000 3.373 2.423 2.423 0 003.373 0c.758-.758 4.41-6.822 4.824-7.51a.5.5 0 00-.075-.612z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});