define("ember-svg-jar/inlined/business-flame", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-flame</title><path d=\"M2.847 17.527h18.306A2.85 2.85 0 0024 14.681c0-.333.076.22-1.35-8.331a2.173 2.173 0 00-2.15-1.823H19a1 1 0 000 2h1.5a.18.18 0 01.178.152c1.4 8.413 1.322 7.9 1.322 8a.848.848 0 01-.847.846H2.847A.848.848 0 012 14.681c0-.1-.081.418 1.322-8a.18.18 0 01.178-.154H5a1 1 0 000-2H3.5A2.173 2.173 0 001.35 6.35C-.072 14.876 0 14.349 0 14.681a2.85 2.85 0 002.847 2.846zM22.5 19.777a1 1 0 00-1-1h-19a1 1 0 000 2h19a1 1 0 001-1zM3.5 21.777a1 1 0 000 2h17a1 1 0 000-2z\"/><path d=\"M12.412.389a.5.5 0 00-.578-.121A8.134 8.134 0 007 7.527a5 5 0 0010 0 5.213 5.213 0 00-2.248-4.3.5.5 0 00-.752.263 3.9 3.9 0 01-.745 1.354.252.252 0 01-.182.084.256.256 0 01-.186-.078 3.223 3.223 0 01-.416-3.873.5.5 0 00-.059-.588z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});