define("ember-svg-jar/inlined/design-document-indd-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-document-indd-1</title><path d=\"M18.456 13.819a.249.249 0 00-.331.24v3.882a.249.249 0 00.331.24A2.238 2.238 0 0019.875 16a2.238 2.238 0 00-1.419-2.181zM13.456 13.819a.249.249 0 00-.331.24v3.882a.249.249 0 00.331.24A2.238 2.238 0 0014.875 16a2.238 2.238 0 00-1.419-2.181z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM5.377 18.375H5.5a.625.625 0 010 1.25h-2a.625.625 0 010-1.25h.126a.249.249 0 00.249-.249v-4.249a.252.252 0 00-.252-.252H3.5a.625.625 0 010-1.25h2a.625.625 0 010 1.25h-.126a.249.249 0 00-.249.249v4.249a.252.252 0 00.252.252zm5.748.625a.624.624 0 01-.481.608.59.59 0 01-.144.017.625.625 0 01-.559-.346s-1.492-2.974-1.563-3.127-.253-.116-.253.048V19a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279s1.53 3.049 1.586 3.166a.122.122 0 00.23-.071V13a.625.625 0 011.25 0zm1.375.625a.625.625 0 01-.625-.625v-6a.625.625 0 01.625-.625 3.625 3.625 0 010 7.25zm5 0a.625.625 0 01-.625-.625v-6a.625.625 0 01.625-.625 3.625 3.625 0 010 7.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});