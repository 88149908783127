define("ember-svg-jar/inlined/crypto-currency-bitcoin-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-monitor</title><path d=\"M9.5 12.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.5a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.719a.251.251 0 01.15-.229 2.244 2.244 0 001.35-2.052 2.219 2.219 0 00-.46-1.35.249.249 0 010-.3 2.219 2.219 0 00.46-1.349A2.244 2.244 0 0013.9 5.2a.251.251 0 01-.15-.229V4.25a.75.75 0 00-1.5 0v.5A.25.25 0 0112 5h-.5a.25.25 0 01-.25-.25v-.484a.75.75 0 00-1.5 0v.484A.25.25 0 019.5 5a.75.75 0 00-.75.75v6a.75.75 0 00.75.75zM13 11h-2.5a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H13a.75.75 0 010 1.5zm-2.75-4.25a.25.25 0 01.25-.25H13A.75.75 0 0113 8h-2.5a.25.25 0 01-.25-.25z\"/><path d=\"M24 3.339A2.518 2.518 0 0021.565.75H2.435A2.518 2.518 0 000 3.339V16.25a2.961 2.961 0 002.913 3h5.949a.252.252 0 01.2.1.248.248 0 01.034.225l-.5 1.5a.25.25 0 01-.237.171H6.5a1 1 0 000 2h11a1 1 0 000-2h-1.862a.25.25 0 01-.237-.171l-.5-1.5a.248.248 0 01.034-.225.252.252 0 01.2-.1h5.949a2.961 2.961 0 002.913-3zM22 14.25a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});