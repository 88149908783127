define("ember-svg-jar/inlined/truck-cargo-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>truck-cargo-1</title><path d=\"M10.625 5.75H6.554A6.171 6.171 0 00.375 11.9v6.747a2.1 2.1 0 002.1 2.1h.456a.247.247 0 00.241-.215 2.983 2.983 0 015.9 0 .247.247 0 00.241.215h1.31a.75.75 0 00.75-.75V6.5a.75.75 0 00-.748-.75zm-.75 6.5a.25.25 0 01-.25.25H2.312c-.39 0-.437-.6-.437-.6a4.67 4.67 0 014.679-4.65h3.071a.25.25 0 01.25.25z\"/><circle cx=\"18.625\" cy=\"21\" r=\"2\"/><circle cx=\"6.125\" cy=\"21\" r=\"2\"/><path d=\"M4.494 4.5h6.381a.25.25 0 00.25-.25V2a1 1 0 00-1.336-.942l-3.78 1.35a2.747 2.747 0 00-1.761 1.78.25.25 0 00.246.312zM23.125 1.5h-8a1.5 1.5 0 00-1.5 1.5v17.5a.5.5 0 00.5.5h1.266a.246.246 0 00.243-.235 3 3 0 015.982 0 .246.246 0 00.243.235h1.266a.5.5 0 00.5-.5V2a.5.5 0 00-.5-.5zM17.875 14a.75.75 0 01-1.5 0V5.5a.75.75 0 011.5 0zm4 0a.75.75 0 01-1.5 0V5.5a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});