define("ember-svg-jar/inlined/sd-card-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sd-card-settings</title><path d=\"M10.881 17.358l.174-.187a.25.25 0 000-.34l-.175-.189a3.282 3.282 0 013.139-5.433l.229.053a.25.25 0 00.295-.171l.071-.234a3.256 3.256 0 011.276-1.739.251.251 0 00.11-.206V4.946a1.514 1.514 0 00-.44-1.061L12.145.471a1.49 1.49 0 00-1.06-.439H1.5a1.5 1.5 0 00-1.5 1.5v7.5a.5.5 0 00.5.5h.75a.25.25 0 01.25.25v2a.25.25 0 01-.25.25H.5a.5.5 0 00-.5.5v7.5a1.5 1.5 0 001.5 1.5h8.78a.194.194 0 00.167-.29 3.271 3.271 0 01.434-3.884zM8.249 3.532v4a.75.75 0 01-1.5 0v-4a.75.75 0 111.5 0zm-3.5 0v4a.75.75 0 01-1.5 0v-4a.75.75 0 011.5 0zm7 4a.75.75 0 01-1.5 0v-4a.75.75 0 011.5 0z\"/><circle cx=\"17.748\" cy=\"17.001\" r=\"1.5\"/><path d=\"M23.516 15.623a1.783 1.783 0 00-1.7-2.953L20.4 13a.448.448 0 01-.532-.311l-.42-1.389a1.775 1.775 0 00-3.4 0l-.424 1.391a.455.455 0 01-.533.311l-1.411-.327a1.783 1.783 0 00-1.7 2.953l.988 1.065a.463.463 0 010 .625l-.988 1.066a1.783 1.783 0 001.7 2.953l1.411-.327a.455.455 0 01.533.31l.424 1.392a1.775 1.775 0 003.4 0l.424-1.392a.447.447 0 01.533-.31l1.411.327a1.783 1.783 0 001.7-2.953l-.987-1.066a.463.463 0 010-.625zM17.748 20a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});