define("ember-svg-jar/inlined/email-action-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-share</title><path d=\"M16.959 13.118a.248.248 0 00.116-.167A4.249 4.249 0 0120.8 9.539a.218.218 0 00.2-.218V1.75a.158.158 0 00-.1-.147.16.16 0 00-.173.034L12.2 10.164a2.407 2.407 0 01-3.4 0L.271 1.637A.159.159 0 000 1.75v10.5A1.749 1.749 0 001.75 14h13.67a.243.243 0 00.128-.036z\"/><path d=\"M9.726 9.236a1.094 1.094 0 001.547 0L19.748.761A.437.437 0 0019.5.019 1.62 1.62 0 0019.249 0h-17.5A1.62 1.62 0 001.5.019a.434.434 0 00-.351.3.439.439 0 00.1.447zM21.25 18.5a2.733 2.733 0 00-2 .874l-2.753-1.1v-.022a2.742 2.742 0 00-.046-.456l3.125-1.875a2.724 2.724 0 001.671.581 2.8 2.8 0 10-2.7-2.294l-3.125 1.875a2.724 2.724 0 00-1.672-.583 2.75 2.75 0 102 4.626l2.753 1.1v.022a2.75 2.75 0 102.75-2.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});