define("ember-svg-jar/inlined/jackclamp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>jackclamp</title><path d=\"M12.5 19.5h-2a1 1 0 000 2h2a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zM21.75 18.25a2.236 2.236 0 00-1.875 1.023.5.5 0 01-.418.227H19a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h.457a.5.5 0 01.418.227 2.246 2.246 0 101.875-3.477zM2.5 5H11a1 1 0 001-1V2.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v2a.5.5 0 00.5.5H17a.5.5 0 00.5-.5V1a1 1 0 00-1-1h-14a2.5 2.5 0 000 5z\"/><path d=\"M17 12h-2.5a.5.5 0 00-.5.5v2a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V13a1 1 0 00-1-1H2.5a2.5 2.5 0 000 5h11a.5.5 0 01.5.5V23a1 1 0 001 1h1.5a1 1 0 001-1V12.5a.5.5 0 00-.5-.5z\"/><rect x=\"13\" y=\"9\" width=\"5.5\" height=\"2\" rx=\".5\" ry=\".5\"/><rect x=\"13\" y=\"6\" width=\"5.5\" height=\"2\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});