define("ember-svg-jar/inlined/award-badge-star-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>award-badge-star_1</title><path d=\"M17.759 19.587l-1.315-2.337 1.1-1.951a1.125 1.125 0 00-.982-1.676H14.4l-1.293-2.3L16 8.437a3.753 3.753 0 001-2.417v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a3.753 3.753 0 001 2.417l2.886 2.887L9.6 13.623H7a.828.828 0 00-.755 1.29l1.311 2.337-1.098 1.95a1.125 1.125 0 00.982 1.676H9.6l1.423 2.53a1.126 1.126 0 001.962 0l1.423-2.53H17a.828.828 0 00.755-1.29zM9 6.02V2.27a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25V9a.25.25 0 01-.427.177L9.417 7.023A1.838 1.838 0 019 6.02z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});