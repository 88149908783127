define("ember-svg-jar/inlined/fitness-dumbells-sizes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fitness-dumbells-sizes</title><path d=\"M5.875 1.5h-1.25A.625.625 0 004 2.125v.625a.5.5 0 01-.5.5.75.75 0 000 1.5.5.5 0 01.5.5v.625a.625.625 0 00.625.625h1.25a.625.625 0 00.625-.625v-3.75a.625.625 0 00-.625-.625zM5.5 16h-2a1 1 0 00-1 1v1.5a.5.5 0 01-.5.5h-.5a1 1 0 000 2H2a.5.5 0 01.5.5V23a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1zM6 8H4a.5.5 0 00-.5.5v1a.5.5 0 01-.5.5h-.5a1 1 0 000 2H3a.5.5 0 01.5.5v1a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-5A.5.5 0 006 8zM18.125 6.5h1.25A.625.625 0 0020 5.875V5.25a.5.5 0 01.5-.5.75.75 0 000-1.5.5.5 0 01-.5-.5v-.625a.625.625 0 00-.625-.625h-1.25a.625.625 0 00-.625.625v3.75a.625.625 0 00.625.625zM22.5 19H22a.5.5 0 01-.5-.5V17a1 1 0 00-1-1h-2a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-1.5a.5.5 0 01.5-.5h.5a1 1 0 000-2zM18 14h2a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h.5a1 1 0 000-2H21a.5.5 0 01-.5-.5v-1A.5.5 0 0020 8h-2a.5.5 0 00-.5.5v5a.5.5 0 00.5.5zM16 3h-2.5a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v1.5a.5.5 0 01-.5.5H8a.5.5 0 00-.5.5v1A.5.5 0 008 5h2.5a.5.5 0 01.5.5v4a.5.5 0 01-.5.5H8a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h2.5a.5.5 0 01.5.5v6a.5.5 0 01-.5.5H8a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h2.5a.5.5 0 01.5.5V23a1 1 0 002 0v-1.5a.5.5 0 01.5-.5H16a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-2.5a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5H16a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-2.5a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5H16a.5.5 0 00.5-.5v-1A.5.5 0 0016 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});