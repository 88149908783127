define("ember-svg-jar/inlined/pin-x-mark-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin-x-mark-4</title><path d=\"M18.794 22.044l-2.617-.8a.25.25 0 010-.478l2.617-.8a1 1 0 10-.588-1.912l-6.132 1.887a.249.249 0 01-.148 0l-6.132-1.897a1 1 0 10-.588 1.912l2.617.8a.25.25 0 010 .478l-2.617.8a1 1 0 00.588 1.912l6.132-1.887a.249.249 0 01.148 0l6.132 1.887a1 1 0 00.588-1.912z\"/><path d=\"M10.426 8.693a.5.5 0 01.324.468v8.089a1.25 1.25 0 002.5 0V9.161a.5.5 0 01.324-.468 4.5 4.5 0 10-3.148 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});