define("ember-svg-jar/inlined/style-three-pin-notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-notes</title><path d=\"M16.5 9h-4a.75.75 0 000 1.5h4a.75.75 0 000-1.5zM16.5 12h-4a.75.75 0 000 1.5h4a.75.75 0 000-1.5z\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM8.5 13.5a.5.5 0 01-.053.224l-1 2a.5.5 0 01-.894 0l-1-2A.5.5 0 015.5 13.5v-7a1.5 1.5 0 013 0zm10 1A1.5 1.5 0 0117 16h-5a1.5 1.5 0 01-1.5-1.5v-8A1.5 1.5 0 0112 5h5a1.5 1.5 0 011.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});