define("ember-svg-jar/inlined/people-man-cap-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-cap-2</title><path d=\"M4.71 10.014a2.729 2.729 0 001.439 2.433.25.25 0 01.144.187c.511 3.048 4.021 4.991 5.686 4.991s5.176-1.943 5.687-4.989a.252.252 0 01.145-.189 2.731 2.731 0 001.439-2.434 2.116 2.116 0 00-.465-1.443c.046-.324.078-.638.1-.945H22a.75.75 0 000-1.5h-3.1c-.054-.954-.278-6-6.919-6-3.618 0-7 1.579-6.935 6.726a13.861 13.861 0 00.13 1.719 2.112 2.112 0 00-.466 1.444zM11 3.625h2a.75.75 0 010 1.5h-2a.75.75 0 010-1.5zm6.191 7.456a1.746 1.746 0 00-1 1.3c-.378 2.249-3.176 3.739-4.209 3.739s-3.83-1.49-4.207-3.738a1.747 1.747 0 00-1-1.306 1.275 1.275 0 01-.559-1.1.626.626 0 01.1-.439.752.752 0 00.406-.794 11.37 11.37 0 01-.081-.567.5.5 0 01.5-.56h9.687a.5.5 0 01.5.56 11.17 11.17 0 01-.081.566.752.752 0 00.406.8.616.616 0 01.105.439 1.279 1.279 0 01-.567 1.1z\"/><path d=\"M12.751 12.453a1.626 1.626 0 01-.764.172 1.651 1.651 0 01-.771-.174.75.75 0 10-.731 1.31 3.259 3.259 0 001.506.364 3.144 3.144 0 001.5-.365.75.75 0 10-.734-1.307zM11.089 10.161A.751.751 0 0011.05 9.1a2.019 2.019 0 00-2.6 0 .75.75 0 001.022 1.1.557.557 0 01.557 0 .751.751 0 001.06-.039zM12.869 9.1a.75.75 0 001.022 1.1.558.558 0 01.559 0 .75.75 0 001.02-1.1 2.022 2.022 0 00-2.601 0zM21.08 18.162l-.017-.006-4.476-1.5a.5.5 0 00-.562.178 4.947 4.947 0 01-2.888 1.908.5.5 0 00-.387.488v4.148a.5.5 0 00.5.5H23.5a.5.5 0 00.5-.5v-1a4.519 4.519 0 00-2.92-4.216zm-5.33 3.713a.75.75 0 11-.75-.75.75.75 0 01.75.75zM11.25 19.227a.5.5 0 00-.387-.488 4.947 4.947 0 01-2.888-1.908.5.5 0 00-.562-.178L2.92 18.162A4.519 4.519 0 000 22.375v1a.5.5 0 00.5.5h10.25a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});