define("ember-svg-jar/inlined/real-estate-market-building-decrease", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-market-building-decrease</title><path d=\"M10 20.25a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V6.75a1 1 0 011-1h2a1 1 0 011 1zM1 .75h2a1 1 0 011 1v18.5a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V1.75a1 1 0 011-1zM1 22h22a1 1 0 010 2H1a1 1 0 010-2zM10.634.366l4.116 4.116a.5.5 0 00.707 0l.263-.262a.746.746 0 01.53-.22.75.75 0 01.75.75v3a.75.75 0 01-.75.75h-3a.75.75 0 01-.53-1.28l.262-.263a.5.5 0 000-.707L8.866 2.134A1.25 1.25 0 0110.634.366zM15 11.75v8.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v1.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-8.5a.5.5 0 00-.235-.424l-4-2.5a.5.5 0 00-.53 0l-4 2.5a.5.5 0 00-.235.424zM17.25 16a.75.75 0 01.75-.75h3a.75.75 0 010 1.5h-3a.75.75 0 01-.75-.75zm0-3a.75.75 0 01.75-.75h3a.75.75 0 010 1.5h-3a.75.75 0 01-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});