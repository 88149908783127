define("ember-svg-jar/inlined/educative-toys-counting-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>educative-toys-counting-2</title><path d=\"M10.5 7h3a.75.75 0 00.75-.75v-3.5a2.25 2.25 0 00-4.5 0v3.5a.75.75 0 00.75.75zM21.5 19h-19a2.25 2.25 0 000 4.5h19a2.25 2.25 0 000-4.5zM4.188 18h15.624a2.25 2.25 0 000-4.5H4.188a2.25 2.25 0 100 4.5zM5.875 12.5h12.25a2.25 2.25 0 000-4.5H5.875a2.25 2.25 0 000 4.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});