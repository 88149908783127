define("ember-svg-jar/inlined/spider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>spider</title><path d=\"M11.7 4.318a1 1 0 10-1.79-.894 3.21 3.21 0 00-.155 2.412 3.21 3.21 0 011.919-.567 1.281 1.281 0 01.026-.951zM14.09 3.424a1 1 0 10-1.788.894 1.276 1.276 0 01.024.951 3.21 3.21 0 011.919.567 3.21 3.21 0 00-.155-2.412zM23.281 14.219l-2.487-2.9a2.906 2.906 0 00-1.446-.915l-3.085-.842 3.062-1.02a2.9 2.9 0 001.962-2.352l.313-2.177a1 1 0 10-1.979-.284l-.311 2.177a.908.908 0 01-.616.738l-2.461.821.334-.279a2.911 2.911 0 001.033-2.47L17.3.921a1 1 0 10-1.993.16l.3 3.8a.909.909 0 01-.326.773l-.67.559a5.523 5.523 0 01.776 3.4 5.275 5.275 0 01-.588 2.468 5.63 5.63 0 011.439 1.95l1.044.928a.909.909 0 01.3.788l-.84 7.139a1 1 0 101.986.235l.84-7.14a2.914 2.914 0 00-.957-2.517l-1.923-1.709 2.131.581a.908.908 0 01.452.286l2.487 2.9a1 1 0 101.519-1.3zM9.194 12.082a5.274 5.274 0 01-.585-2.472 5.523 5.523 0 01.776-3.4l-.671-.559a.915.915 0 01-.326-.773l.3-3.8A1 1 0 106.7.921l-.3 3.8a2.917 2.917 0 001.034 2.465l.334.279-2.46-.821a.908.908 0 01-.616-.738l-.311-2.177a1 1 0 10-1.981.284l.311 2.176a2.9 2.9 0 001.964 2.352l3.062 1.02-3.085.839a2.906 2.906 0 00-1.446.915l-2.487 2.9a1 1 0 101.519 1.3l2.487-2.9a.907.907 0 01.453-.286l2.13-.581-1.922 1.712a2.914 2.914 0 00-.958 2.518l.84 7.14a1 1 0 101.986-.235l-.84-7.139a.91.91 0 01.3-.789l1.043-.928a5.625 5.625 0 011.437-1.945zM12 11a4.137 4.137 0 011.971.5 4.317 4.317 0 00.42-1.9c0-3.348-1.07-3.348-2.391-3.348s-2.391 0-2.391 3.348a4.321 4.321 0 00.42 1.9A4.126 4.126 0 0112 11z\"/><ellipse cx=\"12\" cy=\"16.783\" rx=\"3.826\" ry=\"4.783\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});