define("ember-svg-jar/inlined/protection-shield-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>protection-shield-2</title><path d=\"M10 .223H1.959A1.96 1.96 0 00.012 2.181L0 8.972a.5.5 0 00.146.354.5.5 0 00.354.147H10a.5.5 0 00.5-.5V.723a.5.5 0 00-.5-.5zM10 12.473H.635a.25.25 0 00-.243.306 15.215 15.215 0 009.778 10.957.25.25 0 00.33-.237V12.973a.5.5 0 00-.5-.5zM23.357 12.473H14a.5.5 0 00-.5.5v10.554a.25.25 0 00.33.237 15.211 15.211 0 009.77-10.985.25.25 0 00-.244-.306zM23.988 2.181A1.96 1.96 0 0022.041.223H14a.5.5 0 00-.5.5v8.25a.5.5 0 00.5.5h9.5a.5.5 0 00.354-.147.5.5 0 00.146-.354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});