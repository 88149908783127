define("ember-svg-jar/inlined/shirt-female", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shirt-female</title><path d=\"M4.5 16.164a.5.5 0 01-.59.492 21.827 21.827 0 01-3.62-.982.482.482 0 01-.29-.45v-1.47a.5.5 0 01.46-.5h.01a.372.372 0 01.18.02c.5.159 2.285.834 3.46 1.1a.5.5 0 01.39.487zM19.5 16.164a.5.5 0 00.59.492 21.827 21.827 0 003.62-.982.482.482 0 00.29-.45v-1.47a.5.5 0 00-.46-.5h-.01a.372.372 0 00-.18.02c-.5.159-2.285.834-3.46 1.1a.5.5 0 00-.39.487z\"/><path d=\"M22.662 11.937a.5.5 0 00.315-.657c-.936-2.42-2.79-7.211-2.827-7.28a.973.973 0 00-.67-.48l-1.231-.255a4.758 4.758 0 00-1.77-2.65l-.62-.465a.748.748 0 00-.728-.1A8.373 8.373 0 0112 .686 8.373 8.373 0 018.869.054a.748.748 0 00-.728.1l-.62.465a4.758 4.758 0 00-1.77 2.65L4.52 3.52a.973.973 0 00-.67.48c-.037.069-1.891 4.86-2.827 7.28a.5.5 0 00.315.657c.754.24 2.173.693 3.006.966A.5.5 0 005 12.428v-1.894a.5.5 0 111 0V23a1 1 0 001 1h3.749a.5.5 0 00.5-.5V5.991a.994.994 0 00-.12-.475A18.051 18.051 0 019.776 2.3a.25.25 0 01.289-.32 9.414 9.414 0 001.935.2 9.414 9.414 0 001.935-.2.251.251 0 01.236.077.248.248 0 01.053.243 18.051 18.051 0 01-1.354 3.216.994.994 0 00-.12.475V23.5a.5.5 0 00.5.5h3.749a1 1 0 001-1V10.534a.5.5 0 111 0v1.894a.5.5 0 00.656.475c.834-.273 2.253-.726 3.007-.966zM7.511 5.891a.249.249 0 01-.159-.166 3.346 3.346 0 01.475-3.337.25.25 0 01.434.086 20.164 20.164 0 00.858 2.345.249.249 0 010 .193.254.254 0 01-.135.138 6.235 6.235 0 00-1.249.706.251.251 0 01-.224.035zM9.25 19.75A.75.75 0 1110 19a.75.75 0 01-.75.75zm0-4.5a.75.75 0 11.75-.75.75.75 0 01-.75.75zM10 10a.75.75 0 11-.75-.75.75.75 0 01.75.75zm6.648-4.275a.25.25 0 01-.387.131 6.235 6.235 0 00-1.249-.706.254.254 0 01-.135-.138.249.249 0 010-.193 20.164 20.164 0 00.858-2.345.25.25 0 01.434-.086 3.346 3.346 0 01.479 3.337z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});