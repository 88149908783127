define("ember-svg-jar/inlined/single-man-vintage-tv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-vintage-tv</title><path d=\"M24 3.249a1.5 1.5 0 00-1.5-1.5h-21a1.5 1.5 0 00-1.5 1.5v14.5a1.5 1.5 0 001.5 1.5h.8a.25.25 0 01.2.406l-.775.969a1 1 0 101.562 1.25l1.95-2.437a.5.5 0 01.39-.188h12.752a.5.5 0 01.39.188l1.95 2.437a1 1 0 001.562-1.25l-.775-.969a.25.25 0 01.2-.406h.8a1.5 1.5 0 001.5-1.5zM6.14 5.094a.254.254 0 01-.1-.187.25.25 0 01.078-.2 3.5 3.5 0 015.524.938.25.25 0 01-.149.355 5.936 5.936 0 01-1.793.277 6.169 6.169 0 01-3.56-1.183zM5.094 6.5a.233.233 0 01.147-.165.236.236 0 01.22.027A7.362 7.362 0 009.7 7.7a7.38 7.38 0 002-.275.238.238 0 01.219.048.233.233 0 01.081.204 3.513 3.513 0 01-7-.428 3.213 3.213 0 01.094-.749zm9.095 9.455A.751.751 0 0113.5 17h-10a.751.751 0 01-.689-1.047A6.305 6.305 0 018.5 12a6.3 6.3 0 015.689 3.952zm3.811.8a.5.5 0 01-1 0V4a.5.5 0 111 0zm3.5-1.25h-1a.75.75 0 010-1.5h1a.75.75 0 010 1.5zm.75-3.75a.75.75 0 01-.75.75h-1a.75.75 0 010-1.5h1a.75.75 0 01.75.744zm-1.25-3a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0121 8.749z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});