define("ember-svg-jar/inlined/shopping-bag-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-bag-man</title><path d=\"M17.122 11.208a.25.25 0 00.067-.375 6.364 6.364 0 00-3.05-2.033.25.25 0 01-.029-.468 4.356 4.356 0 10-3.525 0 .25.25 0 01-.029.468 6.355 6.355 0 00-3.3 2.35.25.25 0 00.048.344 3.748 3.748 0 011.385 2.286.25.25 0 00.246.205h6.13a.251.251 0 00.246-.205 3.761 3.761 0 011.811-2.572zm-7.13-6.854a2.346 2.346 0 01.118-.734.246.246 0 01.153-.157.252.252 0 01.219.024 5.538 5.538 0 002.984.868 5.576 5.576 0 001.1-.11.109.109 0 01.092.023.113.113 0 01.041.086 2.355 2.355 0 11-4.71 0zM7.885 16a.134.134 0 01-.135-.135v-1.412a2.75 2.75 0 00-5.5 0v1.412a.134.134 0 01-.135.135 1.157 1.157 0 00-1.146 1.013L.259 22.7A1.156 1.156 0 001.405 24H8.6a1.156 1.156 0 001.146-1.3l-.71-5.687A1.157 1.157 0 007.885 16zM3.75 14.453a1.25 1.25 0 012.5 0v1.3A.25.25 0 016 16H4a.25.25 0 01-.25-.25zM22.6 24a1.156 1.156 0 001.146-1.3l-.71-5.687A1.157 1.157 0 0021.885 16a.134.134 0 01-.135-.135v-1.412a2.75 2.75 0 00-5.5 0v1.412a.134.134 0 01-.135.135 1.157 1.157 0 00-1.146 1.013l-.71 5.687a1.156 1.156 0 001.146 1.3zm-4.85-9.547a1.25 1.25 0 012.5 0v1.3A.25.25 0 0120 16h-2a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});