define("ember-svg-jar/inlined/coffee-espresso-machine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>coffee-espresso-machine</title><path d=\"M1 16h2.775a.25.25 0 01.245.2A1 1 0 005 17h.25a.25.25 0 01.25.25v.25a1 1 0 002 0v-.25a.25.25 0 01.25-.25H8a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-.98.8.249.249 0 01-.245.2H1a1 1 0 000 2z\"/><path d=\"M22 0H6.5A3.5 3.5 0 003 3.5V9a1 1 0 001 1h.75a.25.25 0 01.25.25v1.25a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1.25a.25.25 0 01.25-.25H10a.5.5 0 01.5.5v9a.5.5 0 01-.5.5H1a1 1 0 00-1 1v2a1 1 0 001 1h22a1 1 0 001-1V2a2 2 0 00-2-2zM7 5.5A1.5 1.5 0 118.5 4 1.5 1.5 0 017 5.5zm14.75 3.75h-4a.75.75 0 010-1.5h4a.75.75 0 010 1.5zm0-3h-4a.75.75 0 010-1.5h4a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});