define("ember-svg-jar/inlined/audio-document-aif-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-document-aif-1</title><path d=\"M6.5 12.125A1.377 1.377 0 005.125 13.5v1.125a.25.25 0 00.25.25h2.25a.25.25 0 00.25-.25V13.5A1.377 1.377 0 006.5 12.125z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM9.125 19.5a.625.625 0 01-1.25 0v-3.125a.25.25 0 00-.25-.25h-2.25a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0v-5.9a2.7 2.7 0 012.1-2.678 2.629 2.629 0 013.15 2.578zm5.375-.625a.625.625 0 010 1.25h-4a.625.625 0 010-1.25h1.125a.25.25 0 00.25-.25v-6.25a.25.25 0 00-.25-.25H10.5a.625.625 0 010-1.25h4a.625.625 0 010 1.25h-1.125a.25.25 0 00-.25.25v6.25a.25.25 0 00.25.25zm6-6.75H18a.875.875 0 00-.875.875v1.625a.25.25 0 00.25.25H19a.625.625 0 010 1.25h-1.625a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0V13A2.124 2.124 0 0118 10.875h2.5a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});