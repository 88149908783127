define("ember-svg-jar/inlined/single-woman-book", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-book</title><path d=\"M20.15 4.04a.5.5 0 01-.4-.489V2a2 2 0 00-2-2H5.25a3 3 0 00-3 3v18a3 3 0 003 3h14.5a2 2 0 002-2V6a2 2 0 00-1.6-1.96zM16.864 15.4a.633.633 0 01-1.064.686 4.087 4.087 0 01-.517-1.206.25.25 0 00-.416-.117 3.595 3.595 0 01-5.018 0 .249.249 0 00-.416.116 4.126 4.126 0 01-.517 1.208.633.633 0 01-1.065-.687 3.512 3.512 0 00.5-1.934v-1.7a4.013 4.013 0 118.025 0v1.7a3.521 3.521 0 00.488 1.934zm-.055 4.57a.5.5 0 01-.451.285h-8a.5.5 0 01-.388-.815 5.689 5.689 0 014.39-2.185 5.68 5.68 0 014.386 2.185.5.5 0 01.063.525zM5.25 2H17.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H5.25a1 1 0 010-2z\"/><path d=\"M15.556 12.151a.5.5 0 00-.4-.585 4.348 4.348 0 01-2.409-1.381s-.083-.18-.394-.193a.424.424 0 00-.393.193 4.345 4.345 0 01-2.408 1.381.5.5 0 00-.4.585.5.5 0 00.585.4 5.294 5.294 0 002.449-1.211.25.25 0 01.327 0 5.3 5.3 0 002.449 1.211.505.505 0 00.594-.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});