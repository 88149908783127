define("ember-svg-jar/inlined/meeting-team-laptop-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-team-laptop-1</title><path d=\"M24 12.067a.5.5 0 00-.5-.5h-.75a.25.25 0 01-.25-.25v-9.25a2 2 0 00-2-2h-13a2 2 0 00-2 2v4.511a.25.25 0 00.272.25c.158-.014.317-.021.478-.021a5.575 5.575 0 01.957.082.25.25 0 00.293-.246V2.567a.5.5 0 01.5-.5h12a.5.5 0 01.5.5v8.5a.5.5 0 01-.5.5h-7.966a.25.25 0 00-.249.274c.016.174.025.35.025.529a5.533 5.533 0 01-.321 1.863.248.248 0 00.031.228.251.251 0 00.2.106h9.78a2.5 2.5 0 002.5-2.5z\"/><circle cx=\"14.292\" cy=\"4.692\" r=\"1.625\"/><path d=\"M12 9.567h4.588a.408.408 0 00.382-.555 2.844 2.844 0 00-5.353 0 .409.409 0 00.383.555z\"/><circle cx=\"6.247\" cy=\"12.37\" r=\"4.063\"/><path d=\"M5.038 17.8A6.254 6.254 0 000 23.672a.249.249 0 00.25.26h11.992a.252.252 0 00.181-.077.248.248 0 00.069-.183A6.254 6.254 0 007.457 17.8a6.31 6.31 0 00-2.419 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});