define("ember-svg-jar/inlined/like-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>like-1</title><path d=\"M24 11.034a2.5 2.5 0 00-2.5-2.5h-6.311a.25.25 0 01-.237-.328 8.685 8.685 0 00.52-4.407c-.588-2.1-1.834-2.7-2.808-2.565A2 2 0 0011 3.284c0 2.746-2.129 5.746-4.034 7.061a.5.5 0 00-.216.412v10.116a.5.5 0 00.405.491c.357.068.681.135.987.2a17.361 17.361 0 004.108.471h6.5c1.958 0 2.25-1.1 2.25-1.75a2.247 2.247 0 00-.231-.995 2.251 2.251 0 001-3 2.25 2.25 0 00.959-3.078A2.5 2.5 0 0024 11.034zM5.25 10.784a1 1 0 00-1-1H1a1 1 0 00-1 1v11a1 1 0 001 1h3.25a1 1 0 001-1zm-4.25 0zm2.75 9.25a.75.75 0 11-.75-.75.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});