define("ember-svg-jar/inlined/camping-tent-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camping-tent-small</title><path d=\"M3.5 6a1 1 0 000 2h13a1 1 0 000-2zM21.5 4a1 1 0 00-1-1h-17a1 1 0 000 2h17a1 1 0 001-1zM20.5 0h-17a1 1 0 000 2h17a1 1 0 000-2zM0 23a1 1 0 001 1h22a1 1 0 000-2h-3.006a.25.25 0 01-.216-.124l-6.911-11.867a1.042 1.042 0 00-1.733 0L4.222 21.876a.25.25 0 01-.216.124H1a1 1 0 00-1 1zm11.571-5.036a.5.5 0 01.858 0l2.194 3.657a.252.252 0 010 .252.248.248 0 01-.218.127H9.592a.248.248 0 01-.218-.127.252.252 0 010-.252z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});