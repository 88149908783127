define("ember-svg-jar/inlined/skiing-board-slide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skiing-board-slide</title><circle cx=\"20.002\" cy=\"4.124\" r=\"2.5\"/><path d=\"M22.977 19.71a1 1 0 00-1.2.75 1.5 1.5 0 01-1.8 1.125L3.413 17.77a1.5 1.5 0 01-1.126-1.8 1 1 0 10-1.949-.449 3.5 3.5 0 002.626 4.2l16.566 3.813a3.505 3.505 0 004.2-2.625 1 1 0 00-.753-1.199z\"/><path d=\"M7.677 17.213l2.35-1.169a4.473 4.473 0 002.249-2.6.252.252 0 01.384-.125l1.219.878a1.5 1.5 0 01.623 1.217v2.463a1.5 1.5 0 003 0v-2.466a4.51 4.51 0 00-1.87-3.653l-1.507-1.085a.25.25 0 01.041-.43l1.5-.7a.251.251 0 01.266.035l4.1 3.44a1.5 1.5 0 001.928-2.3l-5.216-4.372c-1.1-.943-1.859-1.049-4.762-1.935a2.516 2.516 0 01-1.472-1.2L9.3 1.119a1.5 1.5 0 00-2.6 1.509l1.2 2.061a5.472 5.472 0 003.2 2.591l1.63.5-2.469 1.189a1.5 1.5 0 00-.759 1.3v1.76a1.492 1.492 0 01-.825 1.339l-2.349 1.166a1.5 1.5 0 101.349 2.679z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});