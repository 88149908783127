define("ember-svg-jar/inlined/smiley-tongue-sticking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-tongue-sticking</title><path d=\"M13.5 18.25a1 1 0 002 0v-1.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM8.5 16.5a1 1 0 010-2h9a1 1 0 01.2 1.98.25.25 0 00-.2.245v1.525a3 3 0 01-6 0v-1.5a.25.25 0 00-.25-.25zM5.664 6.014l3 .5A1 1 0 018.5 8.5a.927.927 0 01-.165-.014l-3-.5a1 1 0 11.328-1.972zM5.5 9.5h3a1 1 0 010 2h-3a1 1 0 010-2zm10 2a1 1 0 010-2h3a1 1 0 010 2zm3.164-3.514l-3 .5a.927.927 0 01-.164.014 1 1 0 01-.163-1.986l3-.5a1 1 0 01.328 1.972z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});