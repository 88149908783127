define("ember-svg-jar/inlined/presentation-projector-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>presentation-projector-1</title><path d=\"M4.5 18c-.077 0-.152 0-.227-.011a.251.251 0 00-.192.065.248.248 0 00-.081.184V22.5A1.5 1.5 0 005.5 24h13a1.5 1.5 0 001.5-1.5v-4.262a.25.25 0 00-.273-.249A2.43 2.43 0 0119.5 18zm10.75 3.5a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zm2.5 0a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zM4.5 13A1.5 1.5 0 003 14.5v1A1.5 1.5 0 004.5 17h15a1.5 1.5 0 001.5-1.5v-1a1.5 1.5 0 00-1.072-1.438.249.249 0 01-.178-.239V7.75a2 2 0 00-2-2h-2a.253.253 0 01-.15-.05.99.99 0 00-.6-.2H8a1 1 0 00-1 1v1a1 1 0 001 1h7a1 1 0 00.917-.6.248.248 0 01.229-.149h1.1a.5.5 0 01.5.5v4.5a.25.25 0 01-.25.25zM15 1.5A1.5 1.5 0 0013.5 0h-4A1.5 1.5 0 008 1.5v2.75a.25.25 0 00.25.25h6.5a.25.25 0 00.25-.25zm-3.459 1.25a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});