define("ember-svg-jar/inlined/history-urn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-urn</title><path d=\"M19.921 10.5a.5.5 0 00.426-.761 9.11 9.11 0 00-2.692-2.5c-1.1-.772-2.052-1.439-2.208-2.372a1.863 1.863 0 01.044-.745.5.5 0 00-.486-.618h-6.01a.5.5 0 00-.486.618 1.863 1.863 0 01.044.745C8.4 5.8 7.446 6.463 6.345 7.235a9.122 9.122 0 00-2.692 2.5.5.5 0 00.427.761zM16.749 2.5a1.25 1.25 0 000-2.5h-9.5a1.25 1.25 0 100 2.5zM18.053 22.173a.5.5 0 01-.138-.7 19.436 19.436 0 001.053-1.738.5.5 0 00-.441-.735H5.473a.5.5 0 00-.44.736 19.2 19.2 0 001.051 1.737.5.5 0 01-.138.7A1 1 0 006.5 24h11a1 1 0 001-1 .986.986 0 00-.447-.827zM3 11.993a14.185 14.185 0 00.313 3.107.5.5 0 00.489.4H5.5A.5.5 0 006 15v-1a1 1 0 011-1h2.75a1 1 0 011 1v1a.5.5 0 00.5.5H13a.5.5 0 00.5-.5v-1a1 1 0 011-1h2.751a1 1 0 011 1v1a.5.5 0 00.5.5H20.2a.5.5 0 00.488-.4A14.185 14.185 0 0021 11.993a.5.5 0 00-.5-.493h-17a.5.5 0 00-.5.493z\"/><path d=\"M20.11 17.163a.5.5 0 00-.473-.663h-1.391a1 1 0 01-1-1v-1a.5.5 0 00-.5-.5H15a.5.5 0 00-.5.5v1a1 1 0 01-1 1h-2.75a1 1 0 01-1-1v-1a.5.5 0 00-.5-.5H7.5a.5.5 0 00-.5.5v1a1 1 0 01-1 1H4.363a.5.5 0 00-.473.663c.061.175.123.347.188.516a.5.5 0 00.467.321h14.91a.5.5 0 00.467-.321c.065-.169.127-.341.188-.516z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});