define("ember-svg-jar/inlined/pill-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pill-clock</title><path d=\"M22.393 9.669a5.5 5.5 0 00-7.776 0l-2.468 2.479-2.48 2.47a5.5 5.5 0 007.772 7.782l2.48-2.471 2.47-2.481a5.5 5.5 0 00.002-7.779zm-6.365 11.31a3.5 3.5 0 11-4.947-4.944l1.42-1.413a.5.5 0 01.706 0l4.239 4.239a.5.5 0 010 .707zM10.75 6.5a.75.75 0 00-.75.75v2.419a.249.249 0 01-.126.217l-3 1.713a.75.75 0 10.744 1.3l3.5-2a.747.747 0 00.378-.651v-3a.75.75 0 00-.746-.748z\"/><path d=\"M7.583 20.029a1.02 1.02 0 00-.494-1.353A8.564 8.564 0 015.365 17.6a.25.25 0 01-.093-.181.247.247 0 01.072-.191L6.5 16.072a.769.769 0 00-1.088-1.087l-1.15 1.149a.25.25 0 01-.374-.023 8.746 8.746 0 01-1.767-4.323.251.251 0 01.249-.28h1.611a.769.769 0 100-1.538H2.356a.251.251 0 01-.248-.281 8.68 8.68 0 011.8-4.314.248.248 0 01.182-.094.25.25 0 01.192.072L5.428 6.5a.769.769 0 001.087-1.088L5.37 4.267a.252.252 0 01.023-.374 8.684 8.684 0 014.319-1.787.25.25 0 01.28.248v1.627a.77.77 0 001.539 0V2.372a.25.25 0 01.28-.248A8.728 8.728 0 0116.13 3.9a.246.246 0 01.1.181.251.251 0 01-.072.193L15 5.428a.769.769 0 101.087 1.087l1.154-1.153a.251.251 0 01.191-.073.249.249 0 01.181.1 8.484 8.484 0 011.062 1.705 1.019 1.019 0 101.847-.86 10.762 10.762 0 10-14.292 14.3 1.022 1.022 0 001.353-.505z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});