define("ember-svg-jar/inlined/tag-new-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tag-new-circle</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM8.582 9v7a.75.75 0 01-.582.73.776.776 0 01-.172.02.75.75 0 01-.671-.414l-1.6-3.212a.25.25 0 00-.474.112V16a.75.75 0 01-1.5 0V9A.75.75 0 015 8.664l1.6 3.212a.25.25 0 00.474-.112V9a.75.75 0 111.5 0zm.857 1.167a1.919 1.919 0 011.917-1.917h2.333a.75.75 0 010 1.5h-2.333a.419.419 0 00-.417.417V11.5a.25.25 0 00.25.25h1.334a.75.75 0 010 1.5h-1.334a.25.25 0 00-.25.25v1.333a.419.419 0 00.417.417h2.333a.75.75 0 010 1.5h-2.333a1.919 1.919 0 01-1.917-1.917zm9.651 6.1l-.525-1.365a.25.25 0 00-.466 0l-.525 1.365a.75.75 0 01-1.444-.177l-.875-7a.75.75 0 111.488-.186l.381 3.044a.25.25 0 00.481.058l.027-.07a.781.781 0 011.4 0l.027.07a.25.25 0 00.482-.058l.38-3.044a.75.75 0 111.488.186l-.875 7a.748.748 0 01-.653.651.7.7 0 01-.091.006.749.749 0 01-.7-.477z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});