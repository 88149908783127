define("ember-svg-jar/inlined/wall-socket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wall-socket</title><path d=\"M22.4 0H1.6A1.6 1.6 0 000 1.6v20.805A1.6 1.6 0 001.6 24h20.8a1.6 1.6 0 001.6-1.6V1.6A1.6 1.6 0 0022.4 0zM12 20.215A8.215 8.215 0 1120.214 12 8.213 8.213 0 0112 20.215z\"/><path d=\"M9.262 8.512a.75.75 0 00-.75.75v5.477a.75.75 0 001.5 0V9.262a.75.75 0 00-.75-.75zM14.738 8.512a.75.75 0 00-.75.75v5.477a.75.75 0 001.5 0V9.262a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});