define("ember-svg-jar/inlined/network-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>network-shield</title><path d=\"M23.072 11.552a16.412 16.412 0 00-5.593-1.04 15.99 15.99 0 00-5.551 1.04A1.544 1.544 0 0011 12.968v3.392a7.715 7.715 0 005.189 6.805l.554.212a2.071 2.071 0 00.736.135 2.04 2.04 0 00.734-.136l.554-.211C21.848 21.991 24 19.193 24 16.36v-3.392a1.544 1.544 0 00-.928-1.416zm-4.822 3.46a.25.25 0 00.25.25h2a.75.75 0 010 1.5h-2a.25.25 0 00-.25.25v2a.75.75 0 01-1.5 0v-2a.25.25 0 00-.25-.25h-2a.75.75 0 010-1.5h2a.25.25 0 00.25-.25v-2a.75.75 0 011.5 0z\"/><path d=\"M23.17 7.618A11.942 11.942 0 0011.94.015 12.224 12.224 0 00.053 10.874a12.01 12.01 0 0011.2 13.109 1.053 1.053 0 00.784-1.8 16.549 16.549 0 01-3.321-4.319.251.251 0 01.227-.359h.033a1 1 0 001-1 1 1 0 00-1-1H8.1a.251.251 0 01-.244-.2 15.606 15.606 0 01-.1-6.1A.248.248 0 018 9.012c10.844 0 13.544.007 14.28-.018a.942.942 0 00.89-1.376zM2 12.012a9.953 9.953 0 01.407-2.821.249.249 0 01.239-.179h2.807A.25.25 0 015.7 9.3a17.9 17.9 0 00-.2 2.713 17.278 17.278 0 00.295 3.2.248.248 0 01-.053.206.25.25 0 01-.192.091H2.819a.25.25 0 01-.235-.167A9.9 9.9 0 012 12.012zm6.041 8.657a.251.251 0 01-.311.372 10.048 10.048 0 01-3.8-3.131.25.25 0 01.2-.4h2.094a.25.25 0 01.234.162 12.84 12.84 0 001.583 2.997zM6.3 6.84a.25.25 0 01-.237.172H3.8a.25.25 0 01-.21-.386 10.04 10.04 0 014.143-3.644.25.25 0 01.311.37A13.15 13.15 0 006.3 6.84zm2.425.172a.25.25 0 01-.231-.346 10.064 10.064 0 013.36-4.383.249.249 0 01.291 0A10.15 10.15 0 0115.5 6.666a.25.25 0 01-.231.346zm9.216 0a.249.249 0 01-.241-.172 13.189 13.189 0 00-1.776-3.521.249.249 0 01.308-.372 9.892 9.892 0 014.2 3.681.25.25 0 01-.211.384z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});