define("ember-svg-jar/inlined/armchair-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>armchair-1</title><path d=\"M4.023 10.428a.5.5 0 00.326.394 5.52 5.52 0 013.412 3.583.5.5 0 00.554.35A24.141 24.141 0 0112 14.5a24.112 24.112 0 013.687.255.5.5 0 00.555-.348 5.482 5.482 0 013.406-3.577.5.5 0 00.329-.4l.453-2.94A6.5 6.5 0 0014 0H9.99a6.5 6.5 0 00-6.42 7.49z\"/><path d=\"M21.5 12a4.015 4.015 0 00-4 4.048.5.5 0 01-.632.483A18.756 18.756 0 0012 16a18.756 18.756 0 00-4.868.531.5.5 0 01-.436-.086A.529.529 0 016.5 16a4 4 0 00-4-4 2.5 2.5 0 00-.961 4.809A.745.745 0 012 17.5v1a3.491 3.491 0 002.844 3.422.5.5 0 01.406.491v.337a1.25 1.25 0 002.5 0v-.25a.5.5 0 01.5-.5h7.5a.5.5 0 01.5.5v.25a1.25 1.25 0 002.5 0v-.338a.5.5 0 01.406-.491A3.49 3.49 0 0022 18.5v-1a.743.743 0 01.46-.691A2.5 2.5 0 0021.5 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});