define("ember-svg-jar/inlined/real-estate-message-chat-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-message-chat-house</title><path d=\"M10.922 3.685a1 1 0 00-1.344 0L4.414 8.38a.5.5 0 00.336.87h1a.5.5 0 01.5.5v2.5a1 1 0 001 1h1a1 1 0 001-1v-2a1 1 0 012 0v2a1 1 0 001 1h1a1 1 0 001-1v-2.5a.5.5 0 01.5-.5h1a.5.5 0 00.336-.87z\"/><path d=\"M18.5 17a2 2 0 002-2V2a2 2 0 00-2-2H2a2 2 0 00-2 2v13a2 2 0 002 2h.5a.5.5 0 01.5.5v1.836a2 2 0 003.414 1.414l3.6-3.6a.5.5 0 01.357-.15zm-8.75-2a1 1 0 00-.707.293l-3.616 3.616A.25.25 0 015 18.732V16a1 1 0 00-1-1H2.5a.5.5 0 01-.5-.5V2h16a.5.5 0 01.5.5v12a.5.5 0 01-.5.5z\"/><path d=\"M23 11a1 1 0 00-1 1v6.5a.5.5 0 01-.5.5H21a1 1 0 00-1 1v.8a.25.25 0 01-.418.185l-1.9-1.729a1 1 0 00-.673-.26H12a1 1 0 000 2h4.427a.5.5 0 01.336.13l2.729 2.481A1.5 1.5 0 0022 22.5v-1a.5.5 0 01.5-.5 1.5 1.5 0 001.5-1.5V12a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});