define("ember-svg-jar/inlined/shape-rhomboid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shape-rhomboid</title><path d=\"M21.114 20.289a1 1 0 00.386-.789v-15a1 1 0 00-.386-.79l-4.5-3.5A1 1 0 0016 0H8a1 1 0 00-.614.21l-4.5 3.5a1 1 0 00-.386.79v15a1 1 0 00.386.789l4.5 3.5A1 1 0 008 24h8a1 1 0 00.614-.211zM8.276 2.053A.247.247 0 018.429 2h7.142a.247.247 0 01.153.053L18.618 4.3a.25.25 0 010 .394l-2.894 2.25a.247.247 0 01-.153.056H8.429a.247.247 0 01-.153-.053L5.382 4.7a.25.25 0 010-.394zm.474 7.2A.25.25 0 019 9h6a.25.25 0 01.25.25v12.5A.25.25 0 0115 22H9a.25.25 0 01-.25-.25zM4.6 19.086a.251.251 0 01-.1-.2V7.056a.25.25 0 01.4-.2l2.25 1.751a.249.249 0 01.1.2v11.832a.25.25 0 01-.4.2zm12.556 1.75a.25.25 0 01-.4-.2V8.806a.249.249 0 01.1-.2L19.1 6.858a.25.25 0 01.4.2v11.83a.251.251 0 01-.1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});