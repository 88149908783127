define("ember-svg-jar/inlined/restaurant-eating-set", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>restaurant-eating-set</title><path d=\"M19.25 0a.75.75 0 00-.75.75V23a1 1 0 002 0v-8a.5.5 0 01.5-.5h1.75A1.243 1.243 0 0024 13.25v-.007C23.936 6.055 21.582 0 19.25 0zM11.75 10a5.75 5.75 0 105.75 5.75A5.757 5.757 0 0011.75 10zm0 9.5a3.75 3.75 0 113.75-3.75 3.754 3.754 0 01-3.75 3.75z\"/><path d=\"M7 0a1 1 0 00-1 1v5a1.993 1.993 0 01-.571 1.4A.251.251 0 015 7.223V1a1 1 0 00-2 0v6.223a.251.251 0 01-.429.175A1.993 1.993 0 012 6V1a1 1 0 00-2 0v5a4.009 4.009 0 002.667 3.772.5.5 0 01.333.471V23a1 1 0 002 0V10.243a.5.5 0 01.333-.471A4.009 4.009 0 008 6V1a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});