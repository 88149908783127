define("ember-svg-jar/inlined/road-sign-no-u-turn-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-no-u-turn-alternate</title><path d=\"M18.318 11.669a.75.75 0 00-1.055.106l-.069.086a.25.25 0 01-.444-.159v-.452a4.5 4.5 0 00-9 0v6a1 1 0 002 0v-6a2.5 2.5 0 015 0v.944a.25.25 0 01-.425.179l-.676-.66a.75.75 0 00-1.049 1.074l2.625 2.563a.755.755 0 00.524.212h.042a.752.752 0 00.539-.275l2.093-2.562a.75.75 0 00-.105-1.056z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a9.946 9.946 0 01-6.1-2.085.25.25 0 01-.024-.375l1-1a1 1 0 00-1.414-1.414l-1 1a.25.25 0 01-.375-.024A9.993 9.993 0 0118.1 4.085a.252.252 0 01.024.375L16.188 6.4A1 1 0 1017.6 7.812l1.94-1.938a.25.25 0 01.375.024A9.993 9.993 0 0112 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});