define("ember-svg-jar/inlined/messages-bubble-remove-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-remove-1</title><path d=\"M17.5 9.5a7.955 7.955 0 014.963 1.737.246.246 0 00.216.047.249.249 0 00.169-.144A7.907 7.907 0 0023.5 8a8.009 8.009 0 00-8-8H8a7.993 7.993 0 00-3 15.4v4.1a.5.5 0 00.854.354l3.726-3.727A.247.247 0 009.649 16 8.008 8.008 0 0117.5 9.5z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-2.651 8.091l1.414-1.414a.252.252 0 000-.354l-1.414-1.414a.75.75 0 011.06-1.06l1.414 1.414a.252.252 0 00.354 0l1.414-1.414a.75.75 0 111.06 1.06l-1.414 1.414a.252.252 0 000 .354l1.414 1.414a.75.75 0 11-1.06 1.06l-1.414-1.414a.252.252 0 00-.354 0l-1.414 1.414a.75.75 0 11-1.06-1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});