define("ember-svg-jar/inlined/lucide-image-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2 2l20 20M10.41 10.41a2 2 0 11-2.83-2.83M13.5 13.5L6 21M18 12l3 3\"/><path d=\"M3.59 3.59A1.99 1.99 0 003 5v14a2 2 0 002 2h14c.55 0 1.052-.22 1.41-.59M21 15V5a2 2 0 00-2-2H9\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});