define("ember-svg-jar/inlined/ab-testing-chemistry-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ab-testing-chemistry-monitor</title><path d=\"M0 2.5v7.052A2.5 2.5 0 002.5 12h4.25a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H3.625a1 1 0 000 2h9a1 1 0 000-2H9.25a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H14a2.5 2.5 0 002.5-2.448V2.5A2.5 2.5 0 0014 0H2.5A2.5 2.5 0 000 2.5zm14.5 0V8a.5.5 0 01-.5.5H2.5A.5.5 0 012 8V2.5a.5.5 0 01.5-.5H14a.5.5 0 01.5.5zM23.25 18.116V11.75a.25.25 0 00-.25-.25h-2.5a.25.25 0 00-.25.25v6.386c0 2.679 3 2.721 3-.02zM23.25 9h-3a.75.75 0 000 1.5h3a.75.75 0 000-1.5zM17.5 15.5a.75.75 0 000-1.5h-2a.75.75 0 000 1.5zM17.5 18.358V16.75a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25v1.608a.507.507 0 01-.074.263l-2.861 4.633-.009.017A.5.5 0 0013 24h7a.5.5 0 00.444-.729l-.009-.017-2.86-4.633a.5.5 0 01-.075-.263z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});