define("ember-svg-jar/inlined/single-neutral-actions-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-star</title><path d=\"M0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.438a.25.25 0 00.224-.361 3.17 3.17 0 011.43-4.29.251.251 0 00.135-.265A5.5 5.5 0 000 13.75z\"/><circle cx=\"5.501\" cy=\"3.75\" r=\"3.5\"/><path d=\"M12.258 14.749a1.229 1.229 0 00-1.166.759 1.269 1.269 0 00.319 1.4l2.194 1.955a.249.249 0 01.063.286l-1.223 2.811a1.255 1.255 0 00.315 1.452 1.276 1.276 0 001.505.166l3.112-1.753a.254.254 0 01.245 0l3.113 1.753a1.277 1.277 0 001.506-.166 1.255 1.255 0 00.315-1.451l-1.223-2.815a.249.249 0 01.063-.286l2.212-1.972a1.264 1.264 0 00.3-1.378 1.233 1.233 0 00-1.169-.761h-2.5a.251.251 0 01-.23-.151l-1.344-3.117-.013-.027a1.293 1.293 0 00-2.317.027L14.989 14.6a.252.252 0 01-.23.151zM0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.438a.25.25 0 00.224-.361 3.17 3.17 0 011.43-4.29.251.251 0 00.135-.265A5.5 5.5 0 000 13.75z\"/><circle cx=\"5.501\" cy=\"3.75\" r=\"3.5\"/><path d=\"M12.258 14.749a1.229 1.229 0 00-1.166.759 1.269 1.269 0 00.319 1.4l2.194 1.955a.249.249 0 01.063.286l-1.223 2.811a1.255 1.255 0 00.315 1.452 1.276 1.276 0 001.505.166l3.112-1.753a.254.254 0 01.245 0l3.113 1.753a1.277 1.277 0 001.506-.166 1.255 1.255 0 00.315-1.451l-1.223-2.815a.249.249 0 01.063-.286l2.212-1.972a1.264 1.264 0 00.3-1.378 1.233 1.233 0 00-1.169-.761h-2.5a.251.251 0 01-.23-.151l-1.344-3.117-.013-.027a1.293 1.293 0 00-2.317.027L14.989 14.6a.252.252 0 01-.23.151z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});