define("ember-svg-jar/inlined/screen-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>screen-alternate</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v15A1.5 1.5 0 001.5 18h8.25a.25.25 0 01.25.25v2.684a.249.249 0 01-.22.248 14.386 14.386 0 00-3.66.891A1 1 0 006.5 24h11a1 1 0 00.377-1.926 14.4 14.4 0 00-3.661-.892.25.25 0 01-.22-.248V18.25a.25.25 0 01.25-.25H22.5a1.5 1.5 0 001.5-1.5v-15A1.5 1.5 0 0022.5 0zM22 13.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});