define("ember-svg-jar/inlined/religion-cao-dai-hand-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-cao-dai-hand-1</title><path d=\"M23.634 11.692L17.546.569a.776.776 0 00-.678-.44.775.775 0 00-.677.443l-6.025 11.121a.768.768 0 00.7 1.178h12.069a.767.767 0 00.7-1.179zm-6.767-1.321a3.869 3.869 0 01-3.334-2 3.779 3.779 0 016.668 0 3.869 3.869 0 01-3.334 2z\"/><circle cx=\"16.867\" cy=\"8.371\" r=\"1\"/><path d=\"M15.75 19.371H6.625a.625.625 0 010-1.25h2.526a1 1 0 00.919-1.393 2.235 2.235 0 00-2.057-1.357H3.75a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.763a2.239 2.239 0 002.237-2.236v-.264a1 1 0 00-1-1zM1.25 14.621a1 1 0 00-1 1v7.25a1 1 0 002 0v-7.25a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});