define("ember-svg-jar/inlined/file-xml", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-xml</title><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.428A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.251A.25.25 0 0014.75 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.251A.25.25 0 0021.75 7zM9.059 19.222a.625.625 0 11-1.118.558s-.812-1.623-.847-1.7a.112.112 0 00-.184-.008l-.851 1.7a.625.625 0 01-.559.346.625.625 0 01-.559-.9l1.305-2.609a.252.252 0 000-.224L4.941 13.78a.625.625 0 111.118-.558s.791 1.591.854 1.709.128.091.169.009l.859-1.718a.625.625 0 011.118.558l-1.3 2.609a.252.252 0 000 .224zm5.066.279a.625.625 0 11-1.25 0v-2.935c0-.142-.17-.079-.207 0l-.109.219a.65.65 0 01-1.118 0s-.08-.154-.117-.235-.2-.086-.2.014V19.5a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279s.76 1.533.818 1.635a.148.148 0 00.25-.007l.814-1.628a.625.625 0 011.184.279zm4.375.625h-2a1.627 1.627 0 01-1.625-1.626v-5a.625.625 0 111.25 0v5a.376.376 0 00.375.375h2a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});