define("ember-svg-jar/inlined/cog-square-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cog-square-1-alternate</title><path d=\"M19.174 0H4.826A4.832 4.832 0 000 4.826v14.348A4.832 4.832 0 004.826 24h14.348A4.832 4.832 0 0024 19.174V4.826A4.832 4.832 0 0019.174 0zM22 19.174A2.83 2.83 0 0119.174 22H4.826A2.83 2.83 0 012 19.174V4.826A2.83 2.83 0 014.826 2h14.348A2.83 2.83 0 0122 4.826z\"/><circle cx=\"11.997\" cy=\"12\" r=\"1.75\"/><path d=\"M18.933 10.881a1.719 1.719 0 00-1.24-2.992l-.872.045a.72.72 0 01-.755-.755l.045-.872a1.719 1.719 0 00-2.992-1.24l-.586.649a.742.742 0 01-1.066 0l-.586-.649a1.719 1.719 0 00-2.992 1.24l.045.872a.728.728 0 01-.21.545.749.749 0 01-.545.21l-.872-.045a1.719 1.719 0 00-1.24 2.992l.648.586a.719.719 0 010 1.067l-.648.585a1.719 1.719 0 001.24 2.992l.872-.045a.713.713 0 01.545.21.728.728 0 01.21.545l-.045.872a1.719 1.719 0 002.992 1.24l.586-.649a.74.74 0 011.066 0l.586.648a1.719 1.719 0 002.992-1.24l-.045-.872a.728.728 0 01.21-.545.739.739 0 01.545-.21l.872.045a1.719 1.719 0 001.24-2.992l-.648-.586a.719.719 0 010-1.067zM12 15.25A3.25 3.25 0 1115.247 12 3.254 3.254 0 0112 15.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});