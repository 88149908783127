define("ember-svg-jar/inlined/certified-diploma", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>certified-diploma</title><path d=\"M17.157.186a1 1 0 00-.82.428l-3.871 5.544a.249.249 0 00.175.391 5.418 5.418 0 012.5.962.248.248 0 00.32-.028L16.7 6.245a.75.75 0 011.06 1.06L16.512 8.55a.251.251 0 00-.028.32 5.473 5.473 0 01.95 2.509.25.25 0 00.145.2.254.254 0 00.247-.023l5.561-3.893a1 1 0 00.426-.821C23.812 4.541 19.458.186 17.157.186zM8.862 16.49a.251.251 0 00-.32.028l-1.536 1.537A.75.75 0 015.946 17l1.531-1.531a.25.25 0 00.027-.321 5.418 5.418 0 01-.964-2.5.249.249 0 00-.391-.175L.613 16.337a1 1 0 00-.426.82c0 2.3 4.355 6.657 6.657 6.657a1 1 0 00.819-.427l3.887-5.554a.254.254 0 00.023-.247.25.25 0 00-.2-.145 5.456 5.456 0 01-2.511-.951zM15.875 15.881a5.431 5.431 0 01-2.334 1.378.25.25 0 00-.108.417l4.225 4.224a1 1 0 001.707-.707v-1.828h1.827a1 1 0 00.707-1.707l-4.226-4.228a.251.251 0 00-.417.107 5.439 5.439 0 01-1.381 2.344z\"/><path d=\"M14.814 9.165a4.1 4.1 0 00-5.657 0 4 4 0 105.657 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});