define("ember-svg-jar/inlined/credit-card-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-remove</title><path d=\"M17.75 4a.25.25 0 00.25-.25 2.5 2.5 0 00-2.5-2.5h-13A2.5 2.5 0 000 3.75.25.25 0 00.25 4zM17.5 7.75h.243A.251.251 0 0018 7.5V5.75a.25.25 0 00-.25-.25H.25a.25.25 0 00-.25.25v6a2.5 2.5 0 002.5 2.5h6.544a.249.249 0 00.241-.186A8.515 8.515 0 0117.5 7.75zM3.5 10h3a.75.75 0 010 1.5h-3a.75.75 0 010-1.5z\"/><path d=\"M17.5 9.75a6.5 6.5 0 106.5 6.5 6.5 6.5 0 00-6.5-6.5zm3.03 8.47a.75.75 0 11-1.06 1.06l-1.793-1.793a.252.252 0 00-.354 0L15.53 19.28a.75.75 0 01-1.06-1.06l1.793-1.793a.252.252 0 000-.354L14.47 14.28a.75.75 0 011.06-1.06l1.793 1.793a.252.252 0 00.354 0l1.793-1.793a.75.75 0 011.06 1.06l-1.793 1.793a.252.252 0 000 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});