define("ember-svg-jar/inlined/office-file-hierarchy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-file-hierarchy</title><path d=\"M21.707 5.705L16.293.291A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.412a1 1 0 00-.293-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.465a.5.5 0 01.353.147l4.536 4.533a.5.5 0 01.146.353z\"/><path d=\"M17 15h-.127a.123.123 0 01-.123-.123V12.5a.75.75 0 00-.75-.75h-3a.25.25 0 01-.25-.25v-.877a.123.123 0 01.123-.123H13a1 1 0 001-1v-2a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1h.126a.125.125 0 01.124.124v.876a.251.251 0 01-.251.252H8a.75.75 0 00-.75.75v2.375a.125.125 0 01-.125.123H7a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 00-1-1h-.125a.125.125 0 01-.125-.125V13.5a.25.25 0 01.25-.25h6a.25.25 0 01.25.25v1.373a.127.127 0 01-.127.127H15a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});