define("ember-svg-jar/inlined/single-woman-actions-share-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-share-1</title><path d=\"M23.238 14.345c-.553-1.1-2.863-1.857-5.764-2.922a.779.779 0 01-.513-.73v-.467a.25.25 0 01.25-.25A6.374 6.374 0 0021 9.081a.388.388 0 00.084-.593c-.494-.537-.835-1.185-.981-3.317C19.838 1.346 17.236 0 15 0s-4.839 1.346-5.1 5.171c-.146 2.132-.487 2.78-.981 3.317A.388.388 0 009 9.081a6.348 6.348 0 003.782.9.251.251 0 01.254.25v.462a.779.779 0 01-.513.73c-2.9 1.065-5.212 1.825-5.764 2.922a5.75 5.75 0 00-.227.506.248.248 0 00.025.233.252.252 0 00.207.109h5.346a2 2 0 012 2v.057a.25.25 0 00.25.25h9.244a.392.392 0 00.29-.127.387.387 0 00.1-.3 7.822 7.822 0 00-.756-2.728zM1 16.387a1 1 0 00-1 1V23a1 1 0 002 0v-5.613a1 1 0 00-1-1zM12.5 20.193h-7a.348.348 0 01-.322-.386.371.371 0 01.371-.371H7.5a.887.887 0 00.819-1.236 1.908 1.908 0 00-1.813-1.006l-3-.032a.5.5 0 00-.5.5v5.032a.5.5 0 00.5.5h8a2.04 2.04 0 002-1.924v-.2a.971.971 0 00-1.006-.877z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});