define("ember-svg-jar/inlined/vest-female", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vest-female</title><path d=\"M19.176 19.917a9.29 9.29 0 01-.448-5.6l1.048-3.456a15.53 15.53 0 00.724-4.725l-.033-3.418a1.043 1.043 0 00-.561-.807L15.875.091a1.032 1.032 0 00-.675-.06h-.011a11.12 11.12 0 01-6.389 0 1.021 1.021 0 00-.675.06L4.063 1.926a1.049 1.049 0 00-.534.846L3.5 6.1a15.528 15.528 0 00.72 4.757l1.05 3.462a9.289 9.289 0 01-.45 5.6l-1.014 1.832a.961.961 0 00-.127.479 1.019 1.019 0 00.517.887 7.059 7.059 0 006.351.245.251.251 0 00.094-.381A11.623 11.623 0 019.9 21.9a2.746 2.746 0 01-.4-1.426v-7.9a2.748 2.748 0 01.418-1.457l1-1.6a.5.5 0 00.015-.5 17.978 17.978 0 01-1.689-6.3.5.5 0 01.6-.544A10.177 10.177 0 0012 2.409a10.177 10.177 0 002.154-.244.5.5 0 01.6.544c-.3 2.646-.951 5.562-2.381 7.366-.378.486-.318.483-1.185 1.836a1.251 1.251 0 00-.19.662v7.9a1.23 1.23 0 00.18.647 9.919 9.919 0 001.27 1.673 7.19 7.19 0 007.363.318 1.006 1.006 0 00.384-1.354zm-5.927-.6a.75.75 0 11.75-.75.75.75 0 01-.75.752zm0-4.819a.75.75 0 11.75-.75.75.75 0 01-.75.752z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});