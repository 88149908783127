define("ember-svg-jar/inlined/science-dna", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-dna</title><path d=\"M23.946 6.593a1 1 0 00-1.268-.624.563.563 0 01-.581-.136L18.166 1.9a.564.564 0 01-.135-.58 1 1 0 10-1.892-.643 8.937 8.937 0 00.245 6.413 6.937 6.937 0 01.3 4.525.25.25 0 01-.419.112l-3.989-3.986a.25.25 0 01.111-.418 7.107 7.107 0 011.713-.247 1 1 0 00-.021-2h-.02a9.255 9.255 0 00-6.3 2.682 9.114 9.114 0 00-1.98 9.94 6.975 6.975 0 01.406 1.27.251.251 0 01-.422.23l-1.639-1.639a.249.249 0 01-.028-.32 1 1 0 00-.818-1.574h-.037a8.926 8.926 0 00-2.563.472 1 1 0 00.644 1.893.563.563 0 01.581.136L5.834 22.1a.564.564 0 01.135.58 1 1 0 101.892.646 8.937 8.937 0 00-.245-6.413 6.937 6.937 0 01-.3-4.525.25.25 0 01.419-.112l3.986 3.986a.25.25 0 01-.111.418 7.107 7.107 0 01-1.712.247 1 1 0 00.021 2h.02a9.255 9.255 0 006.3-2.682 9.114 9.114 0 001.98-9.94 6.975 6.975 0 01-.406-1.27.251.251 0 01.422-.23l1.638 1.639a.249.249 0 01.028.32 1 1 0 00.818 1.574h.037a8.926 8.926 0 002.563-.472 1 1 0 00.627-1.273zm-8.609 7.668a6.966 6.966 0 01-.509.567 6.866 6.866 0 01-.569.509.5.5 0 01-.665-.036L8.7 10.406a.5.5 0 01-.036-.667 6.966 6.966 0 01.509-.567 6.866 6.866 0 01.569-.509.5.5 0 01.665.036l4.893 4.895a.5.5 0 01.037.667z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});