define("ember-svg-jar/inlined/astronomy-solar-system", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astronomy-solar-system</title><path d=\"M12 9.608A2.392 2.392 0 1014.391 12 2.391 2.391 0 0012 9.608z\"/><path d=\"M18.211 17.043a8.054 8.054 0 001.61-6.757 1 1 0 00-1.958.408 6.057 6.057 0 01-1.2 5.087 6 6 0 11-8.492-8.4 6.059 6.059 0 014.992-1.276 1 1 0 10.365-1.966 8 8 0 104.687 12.9z\"/><path d=\"M12 0A12.006 12.006 0 00.8 16.313a1 1 0 101.865-.713 9.993 9.993 0 115.145 5.483 1 1 0 10-.84 1.817A11.891 11.891 0 0012 24a12 12 0 000-24z\"/><path d=\"M18.217 7.217a1.435 1.435 0 10-1.434 1.435 1.435 1.435 0 001.434-1.435z\"/><circle cx=\"3.87\" cy=\"19.652\" r=\"1.435\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});