define("ember-svg-jar/inlined/arrow-up-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-up-2-alternate</title><path d=\"M19.153 24H4.847a2.386 2.386 0 01-2.384-2.384V8.694a2.4 2.4 0 01.806-1.789L10.422.594a2.393 2.393 0 013.156 0l7.156 6.314a2.391 2.391 0 01.8 1.786v12.922A2.386 2.386 0 0119.153 24zM12.165 3.164a.248.248 0 00-.33 0L5.408 8.833a.254.254 0 00-.084.188v11.868a.25.25 0 00.25.25h12.852a.25.25 0 00.25-.25V9.021a.254.254 0 00-.084-.188z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});