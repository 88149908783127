define("ember-svg-jar/inlined/safety-fire-extinguisher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-fire-extinguisher</title><path d=\"M21.954.875a.5.5 0 00-.395-.12l-6.066.809a1 1 0 00-.868.991v.391a1 1 0 00.868.992l6.066.808a.5.5 0 00.566-.5v-3a.5.5 0 00-.171-.371z\"/><rect x=\"11.375\" y=\"11.352\" width=\"3.25\" height=\"8.398\" rx=\".5\" ry=\".5\"/><path d=\"M11.926 5.141a.5.5 0 01-.3-.459V4.25a.5.5 0 01.5-.5h.5a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5H9.255a.49.49 0 01-.2-.043L4.281.086a1 1 0 00-.812 1.828l1.431.635a.249.249 0 01.148.211.253.253 0 01-.117.23A6.489 6.489 0 001.875 8.5v12.249a1 1 0 002 0V8.5a4.5 4.5 0 014.219-4.489.5.5 0 01.531.5v.172a.5.5 0 01-.3.459 4.486 4.486 0 00-2.7 4.109V22.5a1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5v-.75a.5.5 0 00-.5-.5h-3A1.252 1.252 0 019.875 20v-8.9a1.252 1.252 0 011.25-1.25h3a.5.5 0 00.5-.5v-.1a4.486 4.486 0 00-2.699-4.109z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});