define("ember-svg-jar/inlined/email-action-send", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-send</title><path d=\"M22.6 11.5l-6.784-6.777a1 1 0 00-1.707.707v3.043a.25.25 0 01-.25.25H2.109a1 1 0 00-1 1v4.5a1 1 0 001 1h11.75a.25.25 0 01.25.25v3.1a1 1 0 001.005 1 .99.99 0 00.679-.273l6.781-6.357A1 1 0 0022.6 11.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});