define("ember-svg-jar/inlined/stamps-famous", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>stamps-famous</title><path d=\"M23.5 3.256V3.2a2.7 2.7 0 00-4.923-1.529A2.683 2.683 0 0014.2 1.7a2.683 2.683 0 00-4.4 0 2.682 2.682 0 00-4.377-.033A2.684 2.684 0 003.2.5 2.715 2.715 0 00.5 3.256a2.664 2.664 0 001.117 2.172A2.682 2.682 0 001.639 9.8a2.694 2.694 0 000 4.4 2.683 2.683 0 00-.021 4.373 2.712 2.712 0 103.766 3.8h.033a2.677 2.677 0 004.367 0h.032a2.678 2.678 0 004.368 0h.032a2.677 2.677 0 004.367 0h.034a2.692 2.692 0 004.883-1.629 2.664 2.664 0 00-1.117-2.172 2.682 2.682 0 00-.022-4.372 2.694 2.694 0 000-4.4 2.682 2.682 0 00.022-4.372A2.664 2.664 0 0023.5 3.256zM18 4.5A1.5 1.5 0 0119.5 6v12a1.5 1.5 0 01-1.5 1.5H6A1.5 1.5 0 014.5 18V6A1.5 1.5 0 016 4.5z\"/><path d=\"M12 5.5l-.234.513a1.509 1.509 0 00.156 1.108v.01l.008.006 6.267 1.475a.251.251 0 00.307-.244V6a.5.5 0 00-.5-.5zM11.762 8.126a.25.25 0 00-.29.154l-1.939 5.04A.5.5 0 0010 14h1v1.5a1.5 1.5 0 001.5 1.5h2.25a.25.25 0 01.25.25v1a.25.25 0 00.25.25H18a.5.5 0 00.5-.5V9.909a.25.25 0 00-.193-.243zM14 9.75a.75.75 0 11-.75.75.75.75 0 01.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});