define("ember-svg-jar/inlined/wifi-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-settings</title><path d=\"M19.252 16.9a1.45 1.45 0 10-1.45 1.45 1.452 1.452 0 001.45-1.45z\"/><path d=\"M22.484 17.2a.438.438 0 010-.595l.964-1.041a1.754 1.754 0 00-1.672-2.906l-1.38.32a.434.434 0 01-.508-.3l-.414-1.361a1.747 1.747 0 00-3.345 0l-.415 1.361a.428.428 0 01-.509.3l-1.379-.32a1.708 1.708 0 00-1.9.828 1.728 1.728 0 00.23 2.078l.966 1.042a.438.438 0 010 .595l-.964 1.041a1.754 1.754 0 001.672 2.906l1.381-.32a.434.434 0 01.508.3l.415 1.362a1.747 1.747 0 003.345 0l.414-1.362a.434.434 0 01.508-.3l1.379.32a1.754 1.754 0 001.674-2.906zm-1.732-.3a2.95 2.95 0 11-2.95-2.95 2.953 2.953 0 012.95 2.95zM15.215 8.691a1 1 0 001.414-1.414 9.759 9.759 0 00-13.786 0 1 1 0 101.414 1.414 7.757 7.757 0 0110.958 0z\"/><path d=\"M17.689 5.562A1 1 0 1019.1 4.148a13.266 13.266 0 00-18.736 0 1 1 0 101.417 1.414 11.265 11.265 0 0115.908 0zM13.423 11.12c.022-.027.052-.042.071-.071a1 1 0 00-.27-1.388 6.261 6.261 0 00-7.908.767 1 1 0 001.415 1.414 4.253 4.253 0 015.375-.522c.028.018.061.021.09.037a.98.98 0 001.227-.237z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});