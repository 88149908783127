define("ember-svg-jar/inlined/download-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>download-arrow</title><path d=\"M11.332 14.084a.944.944 0 001.336 0l8.972-8.972a.944.944 0 00-.668-1.612h-1.416a.944.944 0 00-.668.276l-6.534 6.535a.5.5 0 01-.708 0L5.112 3.776a.944.944 0 00-.668-.276H3.028a.944.944 0 00-.668 1.612zM22.389 17.666H1.611a1.417 1.417 0 000 2.834h20.778a1.417 1.417 0 100-2.834z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});