define("ember-svg-jar/inlined/glasses-monocle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>glasses-monocle</title><path d=\"M7.25 13a6.5 6.5 0 116.5-6.5 6.508 6.508 0 01-6.5 6.5zm0-11a4.5 4.5 0 104.5 4.5A4.505 4.505 0 007.25 2z\"/><path d=\"M4.75 7.25A.75.75 0 014 6.5a3.254 3.254 0 013.25-3.25.75.75 0 010 1.5A1.752 1.752 0 005.5 6.5a.75.75 0 01-.75.75zM19.25 24a4 4 0 01-4-4V7.5a1 1 0 112 0V20a2 2 0 004 0 1 1 0 012 0 4 4 0 01-4 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});