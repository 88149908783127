define("ember-svg-jar/inlined/fast-food-hot-dog-sausage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fast-food-hot-dog-sausage</title><path d=\"M21.546 15.894l-2.326-2.327s-.252-.263-.252.057v1.663a.75.75 0 11-1.5 0v-3.368a.255.255 0 00-.073-.177l-2.22-2.22s-.207-.136-.207.158v2.107a.75.75 0 11-1.5 0V7.919a.255.255 0 00-.073-.177l-2-2a.25.25 0 00-.427.177V8.2a.75.75 0 01-1.5 0V3.919a.255.255 0 00-.068-.177l-1.2-1.2a4.072 4.072 0 00-5.083-.614 4.006 4.006 0 00-.655 6.189L15.89 21.551a4.053 4.053 0 005.656 0 4.006 4.006 0 000-5.657z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});