define("ember-svg-jar/inlined/human-resources-search-employees", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-search-employees</title><path d=\"M20.717 12.878a.251.251 0 00-.072-.2l-.668-.668a.25.25 0 00-.273-.054 7.024 7.024 0 01-4.886.2.248.248 0 00-.307.138 7.5 7.5 0 01-.968 1.64.254.254 0 00-.043.218 5.209 5.209 0 00.252.718.251.251 0 00.148.13c2.435.9 5.605 2.391 5.605 7v1.5a2.036 2.036 0 01-.012.222.251.251 0 00.249.278H23.5a.5.5 0 00.5-.5v-.853c0-3.1-2.161-3.871-4.068-4.55l-.49-.177a.249.249 0 01-.142-.13 1.012 1.012 0 01-.068-.2 2.756 2.756 0 01.033-1.238.253.253 0 01.052-.09 5.192 5.192 0 001.4-3.384z\"/><path d=\"M10 5.5a6.986 6.986 0 01.309-2.062.251.251 0 00-.185-.318A5.248 5.248 0 009 3a4.641 4.641 0 00-4.755 5.045c0 3.185.917 4.476 1.856 5.512a.252.252 0 01.039.059 3.489 3.489 0 01.06 1.914c-.076.256-.175.373-.242.4-.253.093-.508.183-.763.274C2.762 17.064 0 18.042 0 22v1.5a.5.5 0 00.5.5h17a.5.5 0 00.5-.5V22c0-3.948-2.773-4.935-5.22-5.806l-.739-.266c-.067-.025-.166-.142-.242-.4a3.531 3.531 0 01.056-1.906.251.251 0 01.041-.061 6.409 6.409 0 001.284-1.957.248.248 0 00-.092-.3A7 7 0 0110 5.5z\"/><path d=\"M19.949 10.14a.25.25 0 01.311.034l2.033 2.033a1 1 0 101.414-1.415L21.674 8.76a.25.25 0 01-.034-.311 5.506 5.506 0 10-1.691 1.691zM13.5 5.5A3.5 3.5 0 1117 9a3.5 3.5 0 01-3.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});