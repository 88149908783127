define("ember-svg-jar/inlined/blood-drop-type-a", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>blood-drop-type-a</title><path d=\"M15.28 14.176A7.97 7.97 0 0110.249 3.14a.5.5 0 00-.018-.457A23.163 23.163 0 008.819.393a1.093 1.093 0 00-1.638 0C5.705 2.328 0 13.3 0 16.5 0 20.425 3.813 24 8 24s8-3.575 8-7.5a7.727 7.727 0 00-.379-1.984.5.5 0 00-.341-.34zm-7.549 6.879a.749.749 0 01-.9.565A5.233 5.233 0 013.3 18.834a.75.75 0 111.343-.668 3.736 3.736 0 002.528 1.991.75.75 0 01.56.898zM17.5 4.25a1.045 1.045 0 00-1.05 1.038v.562a.5.5 0 00.5.5h1.1a.5.5 0 00.5-.5v-.562A1.045 1.045 0 0017.5 4.25z\"/><path d=\"M17.5 0A6.5 6.5 0 1024 6.5 6.5 6.5 0 0017.5 0zm2.55 9.5a.75.75 0 01-1.5 0V8.35a.5.5 0 00-.5-.5h-1.1a.5.5 0 00-.5.5V9.5a.75.75 0 01-1.5 0V5.288a2.55 2.55 0 015.1 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});