define("ember-svg-jar/inlined/pin-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin-2</title><circle cx=\"12\" cy=\"5\" r=\"5\"/><path d=\"M12 11.5a1.25 1.25 0 00-1.25 1.25v10a1.25 1.25 0 002.5 0v-10A1.25 1.25 0 0012 11.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});