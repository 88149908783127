define("ember-svg-jar/inlined/email-action-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-warning</title><path d=\"M17.5 10a2.94 2.94 0 012.608 1.572l.419.8a.25.25 0 00.473-.114V1.75a.158.158 0 00-.1-.147.162.162 0 00-.174.034L12.2 10.164a2.407 2.407 0 01-3.4 0L.272 1.637A.159.159 0 000 1.75v10.5A1.749 1.749 0 001.751 14h11.716a.25.25 0 00.221-.134l1.2-2.292A2.941 2.941 0 0117.5 10z\"/><path d=\"M9.726 9.236a1.094 1.094 0 001.547 0L19.748.761a.433.433 0 00.106-.447C19.759.027 19.49 0 19.25 0H1.75c-.241 0-.511.027-.6.314a.439.439 0 00.106.447zM18.782 12.271a1.45 1.45 0 00-2.562 0l-5.055 9.634a1.433 1.433 0 00.048 1.409 1.457 1.457 0 001.232.686h10.111a1.459 1.459 0 001.234-.687 1.434 1.434 0 00.047-1.408zM17.5 15.25a.75.75 0 01.75.75v3a.75.75 0 01-1.5 0v-3a.75.75 0 01.75-.75zm0 7a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});