define("ember-svg-jar/inlined/single-neutral-actions-check-2.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-check-2</title><path d=\"M11.335 12.419a.5.5 0 00-.117-.74A7.461 7.461 0 007.206 10.5a7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h8.647a.5.5 0 00.492-.412 7.936 7.936 0 011.696-3.669z\"/><circle cx=\"7\" cy=\"4.75\" r=\"4.75\"/><path d=\"M23.049 18.19a1 1 0 00-1.254.656A4.5 4.5 0 1117.5 13a1 1 0 000-2 6.5 6.5 0 106.2 8.443 1 1 0 00-.651-1.253z\"/><path d=\"M22.293 12.668l-4.439 4.439a.5.5 0 01-.708 0l-1-1a1 1 0 00-1.416 1.413l1.577 1.58a1.69 1.69 0 002.386 0l5.014-5.014a1 1 0 00-1.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});