define("ember-svg-jar/inlined/martial-arts-karate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>martial-arts-karate</title><path d=\"M21.846 4.011a1.5 1.5 0 00-2.084.392l-4.95 7.013a.252.252 0 01-.354.056L10.744 8.7a.251.251 0 01-.064-.329l.362-.6a1.057 1.057 0 01.9-.513A4.058 4.058 0 0016 3.2v-.95a1.5 1.5 0 00-3 0v.95a1.055 1.055 0 01-1.054 1.05A4.072 4.072 0 008.47 6.219L5.8 10.69a4.5 4.5 0 001.362 6.061l.62.413a1.5 1.5 0 101.664-2.5l-.62-.413a1.5 1.5 0 01-.454-2.021l.495-.847a.253.253 0 01.167-.119.249.249 0 01.2.045l4.166 3.117a.249.249 0 01.1.2v7.124a1.5 1.5 0 003 0v-7.445a.249.249 0 01.046-.144L22.238 6.1a1.5 1.5 0 00-.392-2.089z\"/><circle cx=\"4\" cy=\"5.75\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});