define("ember-svg-jar/inlined/playlist-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>playlist-alternate</title><path d=\"M20 6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2zm-2 11.5a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5h11a.5.5 0 01.5.5zM1 6a1 1 0 00-1 1v10.5a1 1 0 002 0V7a1 1 0 00-1-1zM23 6a1 1 0 00-1 1v10.5a1 1 0 002 0V7a1 1 0 00-1-1z\"/><path d=\"M9.25 17A1.752 1.752 0 0011 15.3V10.512a.249.249 0 01.174-.238l3-.957a.25.25 0 01.326.238v1.759a.249.249 0 01-.188.242A1.75 1.75 0 1016.5 13.3V9.2a2 2 0 00-2.368-1.966.978.978 0 00-.121.032l-3.45 1.109A2 2 0 009 10.329v2.985a.249.249 0 01-.188.242A1.75 1.75 0 009.25 17z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});