define("ember-svg-jar/inlined/video-game-mario-character", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-mario-character</title><path d=\"M24 3.187a.632.632 0 00-.7-.628 6.314 6.314 0 00-4.832 3.233.392.392 0 01-.342.2.44.44 0 01-.44-.44V.513a.5.5 0 00-.538-.5 7.076 7.076 0 00-5.7 3.716.5.5 0 01-.44.264.4.4 0 01-.4-.4V.506a.5.5 0 00-.524-.5A10.6 10.6 0 000 10.6v5.184a8.211 8.211 0 008.211 8.211h7.578A8.211 8.211 0 0024 15.783zM10.369 10.1a1 1 0 012 0v2.527a1 1 0 01-2 0zm-6.316 0a1 1 0 012 0v2.527a1 1 0 11-2 0zm4.789 11.368A5.1 5.1 0 013.8 17.137a.633.633 0 01.625-.722h8.842a.633.633 0 01.626.721 5.1 5.1 0 01-5.051 4.332z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});