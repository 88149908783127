define("ember-svg-jar/inlined/gardening-pots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gardening-pots</title><path d=\"M19.5 13h-15a1 1 0 00-1 1v1a1 1 0 001 1h.617a.5.5 0 01.5.438l.783 6.248A1.5 1.5 0 007.883 24h8.234a1.5 1.5 0 001.488-1.314l.782-6.248a.5.5 0 01.5-.438h.613a1 1 0 001-1v-1a1 1 0 00-1-1zM19.5 6.5h-15a1 1 0 00-1 1v1a1 1 0 001 1h.617a.5.5 0 01.5.438l.2 1.624a.5.5 0 00.5.438h11.37a.5.5 0 00.5-.438l.2-1.624a.5.5 0 01.5-.438h.613a1 1 0 001-1v-1a1 1 0 00-1-1zM19.5 0h-15a1 1 0 00-1 1v1a1 1 0 001 1h.617a.5.5 0 01.5.438l.2 1.624a.5.5 0 00.5.438h11.37a.5.5 0 00.5-.438l.2-1.624a.5.5 0 01.5-.438h.613a1 1 0 001-1V1a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});