define("ember-svg-jar/inlined/single-man-news", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-news</title><path d=\"M24 2.5A1.5 1.5 0 0022.5 1h-21A1.5 1.5 0 000 2.5V17a1.5 1.5 0 001.5 1.5h9a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5H4a1 1 0 000 2h16a1 1 0 000-2h-6.5a.5.5 0 01-.5-.5V19a.5.5 0 01.5-.5h9A1.5 1.5 0 0024 17zM13.141 4.345a.249.249 0 01-.025-.385 3.5 3.5 0 015.523.938.25.25 0 01-.147.354 6.082 6.082 0 01-1.79.276 6.163 6.163 0 01-3.561-1.183zm-1.047 1.4a.239.239 0 01.368-.138 7.356 7.356 0 004.238 1.34 7.38 7.38 0 002-.275.238.238 0 01.219.048.233.233 0 01.081.208 3.513 3.513 0 01-7-.428 3.213 3.213 0 01.094-.752zM9 9.5a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5zm12.126 6.412a.748.748 0 01-.626.338h-10a.751.751 0 01-.689-1.05 6.305 6.305 0 015.689-3.95 6.3 6.3 0 015.689 3.95.749.749 0 01-.063.712z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});