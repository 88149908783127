define("ember-svg-jar/inlined/color-brush-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-brush-1</title><path d=\"M3 14.5h18a1 1 0 001-1v-1a2.5 2.5 0 00-2.5-2.5h-4.671a.251.251 0 01-.249-.275l.619-6.19a3.215 3.215 0 10-6.4 0l.619 6.19a.253.253 0 01-.063.193.251.251 0 01-.183.082H4.5A2.5 2.5 0 002 12.5v1a1 1 0 001 1zM13 3.75a1 1 0 11-1-1 1 1 0 011 1zM22.368 22.459A8.6 8.6 0 0121 17.8v-1.3a.5.5 0 00-.5-.5h-17a.5.5 0 00-.5.5v1.3a8.607 8.607 0 01-1.368 4.66A1 1 0 002.474 24h1.494a3.494 3.494 0 002.912-1.558c.212-.317.439-.662.66-1a.25.25 0 01.46.136V23a1 1 0 001 1h12.526a1 1 0 00.842-1.541z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});