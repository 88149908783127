define("ember-svg-jar/inlined/currency-euro-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-euro-circle</title><path d=\"M12 0a12 12 0 1012 12A12 12 0 0012 0zm2 8.387a1.25 1.25 0 110 2.5H9.628a.25.25 0 00-.249.226 9.211 9.211 0 00-.035 1.241.25.25 0 00.25.241h3.176a1.25 1.25 0 010 2.5h-2.28a.25.25 0 00-.2.4c.967 1.223 2.717 1.75 5.475 1.75a1.25 1.25 0 010 2.5c-1.839 0-6.792-.019-8.4-4.49a.251.251 0 00-.236-.165H6.27a1.25 1.25 0 010-2.5h.33a.252.252 0 00.25-.269 4.224 4.224 0 01-.015-.326c0-.3.016-.573.038-.843a.25.25 0 00-.249-.27h-.311a1.25 1.25 0 110-2.5h1a.249.249 0 00.228-.148C9.332 4.252 14 4.25 15.769 4.25a1.25 1.25 0 010 2.5c-2.3 0-3.893.365-4.922 1.192a.25.25 0 00.153.445z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});