define("ember-svg-jar/inlined/design-document-max", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-document-max</title><path d=\"M12 9.625a.877.877 0 00-.875.875v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V10.5A.877.877 0 0012 9.625z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM9.125 15a.625.625 0 01-1.25 0v-3.175c0-.075-.08-.063-.109 0l-.207.457a.65.65 0 01-1.118 0s-.167-.327-.2-.4-.118-.062-.118.009V15a.625.625 0 01-1.25 0V9a.625.625 0 011.184-.279s.807 1.606.837 1.673a.124.124 0 00.208 0l.837-1.674A.625.625 0 019.125 9zm5 0a.625.625 0 01-1.25 0v-2.125a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0v-4.375a2.212 2.212 0 011.9-2.239 2.128 2.128 0 012.35 2.114zm4.934-.279a.625.625 0 11-1.118.558l-.876-1.753a.069.069 0 00-.129 0l-.877 1.754a.625.625 0 01-.559.346.615.615 0 01-.279-.066.624.624 0 01-.28-.838l1.3-2.609a.252.252 0 000-.224l-1.3-2.609a.625.625 0 111.118-.558s.851 1.7.876 1.753a.07.07 0 00.129 0l.877-1.754a.625.625 0 011.118.558l-1.3 2.609a.252.252 0 000 .224z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});