define("ember-svg-jar/inlined/cursor-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-1-alternate</title><path d=\"M8.654 22.676L.382 2.648A1.737 1.737 0 012.651.38l20.026 8.271a1.737 1.737 0 01-.19 3.276l-8.211 2.346-2.348 8.215a1.736 1.736 0 01-3.274.188zM3.95 3.621a.25.25 0 00-.327.326l6.229 15.082a.25.25 0 00.472-.029l1.665-5.824a1.745 1.745 0 011.2-1.194l5.82-1.662a.25.25 0 00.026-.472z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});