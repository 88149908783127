define("ember-svg-jar/inlined/style-three-pin-adventure-park", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-adventure-park</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm2.851 17.561a1 1 0 01-.828.439H9.977a1 1 0 01-.928-1.371l2.009-5.029a.5.5 0 00-.113-.541 1.5 1.5 0 112.11 0 .5.5 0 00-.113.541l2.009 5.025a1 1 0 01-.1.936zm2.82-6.638a.5.5 0 00.329.555 1.684 1.684 0 01.288.131 1.75 1.75 0 11-2.615 1.373.5.5 0 00-.033-.222.73.73 0 01.042-.635 4.251 4.251 0 10-7.364 0 .727.727 0 01.042.635.5.5 0 00-.033.222 1.747 1.747 0 11-2.615-1.373A1.684 1.684 0 016 11.478a.5.5 0 00.329-.555 5.459 5.459 0 010-1.848A.5.5 0 006 8.517a1.541 1.541 0 01-.283-.126 1.75 1.75 0 011.75-3.032 1.763 1.763 0 01.26.185.5.5 0 00.643.009 5.78 5.78 0 011.6-.927.5.5 0 00.314-.561 1.633 1.633 0 01-.03-.315 1.75 1.75 0 013.5 0 1.633 1.633 0 01-.03.315.5.5 0 00.314.561 5.78 5.78 0 011.6.927.5.5 0 00.643-.009A1.744 1.744 0 0118.928 6a1.751 1.751 0 01-.64 2.391 1.541 1.541 0 01-.283.126.5.5 0 00-.334.558 5.459 5.459 0 010 1.848z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});