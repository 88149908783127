define("ember-svg-jar/inlined/file-zip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-zip</title><path d=\"M16.5 9.627h-.623a.252.252 0 00-.252.252v1.246a.252.252 0 00.252.252h.623a.875.875 0 000-1.75z\"/><path d=\"M22 4.5H2A1.5 1.5 0 00.5 6v12A1.5 1.5 0 002 19.5h20a1.5 1.5 0 001.5-1.5V6A1.5 1.5 0 0022 4.5zM9 15.623H6.5a1.126 1.126 0 01-.936-1.75s2.515-3.94 2.558-4.014A.15.15 0 008 9.623H6a.625.625 0 010-1.25h2.5a1.127 1.127 0 01.936 1.75s-2.5 3.917-2.558 4.014 0 .236.175.236H9a.625.625 0 010 1.25zm3.876-1.25H13a.625.625 0 010 1.25h-2a.625.625 0 010-1.25h.126a.249.249 0 00.249-.249v-4.25a.251.251 0 00-.251-.251H11a.625.625 0 010-1.25h2a.625.625 0 010 1.25h-.124a.251.251 0 00-.251.251v4.248a.251.251 0 00.251.251zM14.375 9A.625.625 0 0115 8.373h1.5a2.125 2.125 0 010 4.25h-.623a.252.252 0 00-.252.252V15a.625.625 0 01-1.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});