define("ember-svg-jar/inlined/flower-roses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flower-roses</title><path d=\"M17.773 11.834a.483.483 0 01-.41.214H13.5a.5.5 0 01-.5-.5V8.312a.517.517 0 01.321-.468 3.238 3.238 0 001.929-2.953v-2.75a1.186 1.186 0 00-1.9-.949l-1.05.787a.5.5 0 01-.6 0l-1.05-.787a1.187 1.187 0 00-1.9.949v2.75a3.238 3.238 0 001.929 2.953.517.517 0 01.321.468v3.236a.5.5 0 01-.5.5H6.636a.5.5 0 01-.414-.219 8.281 8.281 0 01-1.14-3.24.51.51 0 01.307-.526A3.235 3.235 0 007.25 5.141v-2.75a1.186 1.186 0 00-1.9-.949l-1.05.787a.5.5 0 01-.6 0l-1.05-.787a1.187 1.187 0 00-1.9.949v2.75a3.239 3.239 0 002.01 2.988.489.489 0 01.306.4 10.354 10.354 0 00.828 3.171.251.251 0 01-.229.352H1.5a1.5 1.5 0 000 3h21a1.5 1.5 0 000-3h-2.165a.251.251 0 01-.229-.352 10.354 10.354 0 00.828-3.164.489.489 0 01.306-.4 3.239 3.239 0 002.01-2.988V2.391a1.186 1.186 0 00-1.9-.949l-1.05.787a.5.5 0 01-.6 0l-1.05-.787a1.187 1.187 0 00-1.9.949v2.75a3.236 3.236 0 001.884 2.933.479.479 0 01.29.484 8.267 8.267 0 01-1.151 3.276zM2.42 22.291a1 1 0 00.971.757H20.61a1 1 0 00.97-.758c0-.01 0-.02.006-.03l.945-5.122a.5.5 0 00-.492-.59H1.961a.5.5 0 00-.491.59z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});