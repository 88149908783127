define("ember-svg-jar/inlined/monitor-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-question</title><path d=\"M11.627 11.652a.25.25 0 00-.23-.151H2.5A.5.5 0 012 11V2.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v6.63a.25.25 0 00.293.246 8.477 8.477 0 011.452-.125A.251.251 0 0018 9V2a2 2 0 00-2-2H2a2 2 0 00-2 2v10.5a2 2 0 002 2h5.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5H5a1 1 0 000 2h4a.25.25 0 00.25-.25 8.451 8.451 0 012.33-5.828.251.251 0 00.047-.27z\"/><path d=\"M17.747 11.5A6.25 6.25 0 1024 17.751a6.257 6.257 0 00-6.253-6.251zm0 11.25a1 1 0 111-1 1 1 0 01-.999 1.001zm.912-4.164a.25.25 0 00-.163.234V19A.75.75 0 1117 19v-.554a1.258 1.258 0 01.988-1.222 1.125 1.125 0 10-1.363-1.1.75.75 0 01-1.5 0 2.625 2.625 0 113.538 2.461z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});