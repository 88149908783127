define("ember-svg-jar/inlined/gymnastics-acrobatic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gymnastics-acrobatic</title><path d=\"M20.915 17.5H23a1 1 0 001-1v-3a1 1 0 00-1-1H1a1 1 0 00-1 1v3a1 1 0 001 1h2.085a.5.5 0 01.475.658l-1.053 3.158a1 1 0 01-.949.684H1a1 1 0 000 2h4a1 1 0 000-2 .442.442 0 01-.419-.581l1.079-3.235a1 1 0 01.948-.684h10.784a1 1 0 01.949.684l1.078 3.235A.442.442 0 0119 22a1 1 0 000 2h4a1 1 0 000-2h-.558a1 1 0 01-.949-.684l-1.053-3.158a.5.5 0 01.475-.658zM6.5 10.5a3.5 3.5 0 001.312-6.744.5.5 0 01-.312-.464V1a1 1 0 00-2 0v2.292a.5.5 0 01-.312.464A3.5 3.5 0 006.5 10.5zm0-5A1.5 1.5 0 115 7a1.5 1.5 0 011.5-1.5zM17.5 10.5a3.5 3.5 0 001.312-6.744.5.5 0 01-.312-.464V1a1 1 0 00-2 0v2.292a.5.5 0 01-.312.464A3.5 3.5 0 0017.5 10.5zm0-5A1.5 1.5 0 1116 7a1.5 1.5 0 011.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});