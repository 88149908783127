define("ember-svg-jar/inlined/flag-plain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flag-plain</title><path d=\"M23.532 7.517L16.461.445a1.5 1.5 0 00-2.121 0l-4.067 4.068a.25.25 0 000 .354l5.144 5.144a.75.75 0 01-1.061 1.061l-6.39-6.391a1.536 1.536 0 00-2.111.007L1.948 8.6a.5.5 0 000 .707l8.485 8.485a.5.5 0 00.707 0l2.874-2.874a3.388 3.388 0 00.193 2.181 1 1 0 00.735.545 1.016 1.016 0 00.869-.28l7.722-7.721a1.5 1.5 0 00-.001-2.126zM2.163 11.543A1.25 1.25 0 00.4 13.311l10.312 10.316a1.25 1.25 0 101.768-1.768z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});