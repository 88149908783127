define("ember-svg-jar/inlined/family-swing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-swing</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 16.454a7.459 7.459 0 01-3.335-.783.75.75 0 11.67-1.342 5.99 5.99 0 005.33 0 .75.75 0 01.67 1.342 7.459 7.459 0 01-3.335.783zm7 2.3h-2a.75.75 0 010-1.5h.185l-.856-10.275a.252.252 0 00-.249-.229H15a.25.25 0 00-.25.25v5a.75.75 0 01-1.5 0V7a.25.25 0 00-.25-.25h-2a.25.25 0 00-.25.25v5a.75.75 0 01-1.5 0V7A.25.25 0 009 6.75H7.92a.252.252 0 00-.249.229L6.815 17.25H7a.75.75 0 010 1.5H5a.75.75 0 010-1.5h.31l.867-10.4a1.759 1.759 0 011.743-1.6h8.16a1.759 1.759 0 011.743 1.605l.867 10.4H19a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});