define("ember-svg-jar/inlined/content-pen-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-pen-4</title><path d=\"M23.436.566a1.5 1.5 0 00-2.122 0L19.9 1.98a.5.5 0 000 .707L21.314 4.1a.5.5 0 00.707 0l1.415-1.414a1.5 1.5 0 000-2.121zM3.637 17.537a.5.5 0 00-.457-.137.5.5 0 00-.364.309l-1.508 3.924-.963.967a.75.75 0 000 1.061.758.758 0 001.06 0l.963-.963 3.923-1.509a.5.5 0 00.174-.82zM19.193 3.394a1.5 1.5 0 00-2.122 0l-.707-.707a2 2 0 00-2.828 0L9.294 6.93a1 1 0 000 1.414 1.015 1.015 0 001.414 0L14.95 4.1l.707.707L4.344 16.122a.5.5 0 000 .708l2.828 2.828a.5.5 0 00.707 0L20.607 6.929a1.5 1.5 0 000-2.121z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});