define("ember-svg-jar/inlined/force-touch-double-tap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>force-touch-double-tap</title><path d=\"M6.251 19.5H2.489a.5.5 0 01-.5-.5V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v3.02a.253.253 0 00.113.209 7.576 7.576 0 011.452 1.245.25.25 0 00.435-.167V3a3 3 0 00-3-3h-10a3 3 0 00-3 3v18a3 3 0 003 3h6.75a.25.25 0 00.25-.25v-3a.25.25 0 00-.242-.25 7.451 7.451 0 01-3.496-1z\"/><path d=\"M8.788 18.881a1 1 0 10.4-1.958 4 4 0 114.469-5.523 1 1 0 101.832-.8 6 6 0 10-6.7 8.284z\"/><path d=\"M15.7 14.233l-.295.295a.249.249 0 01-.353 0l-2.3-2.3A2.5 2.5 0 008.489 14a2.477 2.477 0 00.733 1.767l3.428 3.429a2.25 2.25 0 00.233 4.447l3.862.357a1 1 0 10.184-1.991l-3.863-.356a.252.252 0 01-.225-.271.235.235 0 01.271-.227A16.59 16.59 0 0014.966 21a1 1 0 00.619-1.7l-4.949-4.95a.5.5 0 01.707-.707l3.181 3.182a1 1 0 001.414 0l1.179-1.178a2.389 2.389 0 013.3 0l1.883 1.886a1 1 0 001.414-1.415l-1.887-1.885a4.326 4.326 0 00-6.128 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});