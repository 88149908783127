define("ember-svg-jar/inlined/monitor-touch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-touch</title><path d=\"M23.664 18.164a1.284 1.284 0 00-.894-1.521l-3.786-1.1a.249.249 0 01-.181-.24V11.76a1 1 0 00-2 0v6.34a.25.25 0 01-.423.18c-1.451-1.392-3.181-.706-2.077.981l2.316 3.372a.25.25 0 00.206.108h5.78a.249.249 0 00.246-.2z\"/><path d=\"M11.8 22.26a1 1 0 000-2h-.25a.25.25 0 01-.25-.25v-1.525a.25.25 0 01.2-.245 1 1 0 00-.2-1.98H2.8a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h18a.5.5 0 01.5.5v10.5a1 1 0 102 0v-11a2 2 0 00-2-2h-19a2 2 0 00-2 2v13a2 2 0 002 2h6.752a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H6.8a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});