define("ember-svg-jar/inlined/lift-two-people", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lift-two-people</title><path d=\"M5 3h.5a.5.5 0 01.5.5V4a1 1 0 002 0v-.5a.5.5 0 01.5-.5H9a.5.5 0 00.354-.854l-2-2a.5.5 0 00-.708 0l-2 2A.5.5 0 005 3zM16.646 4.854a.5.5 0 00.708 0l2-2A.5.5 0 0019 2h-.5a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v.5a.5.5 0 01-.5.5H15a.5.5 0 00-.354.854zM20 6H4a1.5 1.5 0 00-1.5 1.5v15A1.5 1.5 0 004 24h16a1.5 1.5 0 001.5-1.5v-15A1.5 1.5 0 0020 6zm-3.5 1.5a2.25 2.25 0 11-2.25 2.25A2.253 2.253 0 0116.5 7.5zm-9 0a2.25 2.25 0 11-2.25 2.25A2.253 2.253 0 017.5 7.5zm3 10a.5.5 0 01-.5.5h-.367a.5.5 0 00-.5.438l-.391 3.125a.5.5 0 01-.5.437H6.75a.5.5 0 01-.5-.437l-.391-3.125a.5.5 0 00-.5-.438H5a.5.5 0 01-.5-.5V16a3 3 0 016 0zm9 0a.5.5 0 01-.5.5h-.367a.5.5 0 00-.5.438l-.391 3.125a.5.5 0 01-.5.437h-1.5a.5.5 0 01-.5-.437l-.391-3.125a.5.5 0 00-.5-.438H14a.5.5 0 01-.5-.5V16a3 3 0 016 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});