define("ember-svg-jar/inlined/crypto-currency-bitcoin-increase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-increase</title><path d=\"M8.623 7.784H6.9a.25.25 0 00-.25.25V9.3a.25.25 0 00.25.25h1.723a.884.884 0 100-1.768zM6.9 4.518a.25.25 0 00-.25.25v1.266a.25.25 0 00.25.25h1.723a.883.883 0 100-1.766z\"/><path d=\"M7.5 0a7 7 0 107 7 7.009 7.009 0 00-7-7zM5.15 3.768a.75.75 0 01.75-.75h.09a.25.25 0 00.25-.25v-.09a.75.75 0 111.5 0v.09a.25.25 0 00.25.25h.633A2.386 2.386 0 0111.007 5.4a2.361 2.361 0 01-.521 1.478.25.25 0 000 .312 2.377 2.377 0 01-1.863 3.862H7.99a.25.25 0 00-.25.25v.089a.75.75 0 01-1.5 0V11.3a.25.25 0 00-.25-.25H5.9a.75.75 0 01-.75-.75zM23.5 11a.5.5 0 00-.5-.5h-5a.5.5 0 00-.354.854l1.415 1.414a.251.251 0 01-.007.361l-4.583 4.225a.249.249 0 01-.325.012l-2.9-2.317a2.154 2.154 0 00-2.771.071l-.219.194-7.314 6.5a1.25 1.25 0 001.66 1.87l7.155-6.36a.251.251 0 01.323-.009l2.9 2.324a2.164 2.164 0 002.805-.1l5.047-4.653a.251.251 0 01.347.007l1.461 1.462A.5.5 0 0023.5 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});