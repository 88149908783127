define("@gavant/ember-shopify-draggable/components/swappable-item", ["exports", "@ember/component", "@gavant/ember-shopify-draggable/templates/components/swappable-item", "@ember/utils", "@ember/object"], function (_exports, _component, _swappableItem, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _swappableItem.default,
    classNames: ['swappable-item'],
    _dragStart(event) {
      const source = (0, _object.get)(event, 'data.originalSource');
      if (this.element && this.element.isSameNode(source)) {
        (0, _utils.tryInvoke)(this, 'dragStart', [(0, _object.get)(this, 'item'), (0, _object.get)(this, 'index')]);
      }
    },
    _swap(event) {
      const source = (0, _object.get)(event, 'data.over');
      if (this.element && this.element.isSameNode(source)) {
        (0, _utils.tryInvoke)(this, 'swapped', [(0, _object.get)(this, 'item'), (0, _object.get)(this, 'index'), (0, _object.get)(event, 'overContainer')]);
      }
    },
    init() {
      this._super(...arguments);
      (0, _object.get)(this, 'container.group').on('drag:start', this, '_dragStart');
      (0, _object.get)(this, 'container.group').on('swappable:swap', this, '_swap');
    },
    willDestroyElement() {
      this._super(...arguments);
      (0, _object.get)(this, 'container.group').off('drag:start', this, '_dragStart');
      (0, _object.get)(this, 'container.group').off('swappable:swap', this, '_swap');
    }
  }).reopenClass({
    positionalParams: ['item']
  });
});