define("ember-svg-jar/inlined/taking-pictures-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>taking-pictures-square</title><path d=\"M21 0H3a3 3 0 00-3 3v18a3 3 0 003 3h18a3 3 0 003-3V3a3 3 0 00-3-3zm-1.5 10v5a2 2 0 01-2 2h-11a2 2 0 01-2-2v-5a2 2 0 012-2H8a.913.913 0 00.5-.207L9.793 6.5A1.872 1.872 0 0111 6h2a1.872 1.872 0 011.207.5L15.5 7.793A.913.913 0 0016 8h1.5a2 2 0 012 2z\"/><circle cx=\"12\" cy=\"12.5\" r=\"3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});