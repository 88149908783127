define("ember-svg-jar/inlined/professions-woman-nurse-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-woman-nurse-1</title><path d=\"M20.667 18.193a.749.749 0 10-.525 1.4 3.265 3.265 0 012.108 3.047v.609a.75.75 0 001.5 0v-.609a4.77 4.77 0 00-3.083-4.447zM3.333 18.084A4.771 4.771 0 00.25 22.531v.609a.75.75 0 001.5 0v-.609a3.264 3.264 0 012.108-3.042.75.75 0 10-.525-1.405zM12.665 12.823a1.311 1.311 0 01-.236.1 2.138 2.138 0 01-1.158 0 1.289 1.289 0 01-.238-.1.75.75 0 00-.715 1.318 3.431 3.431 0 003.062 0 .75.75 0 10-.715-1.318zM10.992 10.019a.751.751 0 00-.055-1.059 2.1 2.1 0 00-2.635 0 .75.75 0 101 1.114.637.637 0 01.627 0 .749.749 0 001.063-.055zM12.867 8.96a.75.75 0 001 1.114.637.637 0 01.627 0 .75.75 0 101-1.114 2.1 2.1 0 00-2.627 0zM12 19.39a.75.75 0 00-.75.75v3a.75.75 0 001.5 0v-3a.75.75 0 00-.75-.75z\"/><circle cx=\"14.75\" cy=\"22.39\" r=\".75\"/><circle cx=\"14.75\" cy=\"19.89\" r=\".75\"/><path d=\"M8.5 20.89h-.75v-.75a.75.75 0 00-1.5 0v.75H5.5a.75.75 0 000 1.5h.75v.75a.75.75 0 001.5 0v-.75h.75a.75.75 0 000-1.5zM21.384 15.479a.5.5 0 00-.231-.8c-1.374-.442-2.024-2.087-2.333-3.434a3.141 3.141 0 00.35-1.361 2.109 2.109 0 00-.47-1.437c.388-2.76-.041-4.9-1.275-6.354C16.254.705 14.394 0 11.9 0S7.546.705 6.37 2.092C5.136 3.549 4.707 5.686 5.094 8.447A2.112 2.112 0 004.63 9.89a3.1 3.1 0 00.435 1.5c-.322 1.318-.973 2.864-2.3 3.29a.5.5 0 00-.231.8A4.747 4.747 0 006.9 17.043a2.107 2.107 0 001.339-1.208A6.486 6.486 0 0011.9 17.5a6.537 6.537 0 003.746-1.739 2.119 2.119 0 001.377 1.281 4.752 4.752 0 004.361-1.563zM11.9 16c-1.032 0-3.83-1.49-4.207-3.738a1.746 1.746 0 00-1-1.306 1.277 1.277 0 01-.558-1.1.626.626 0 01.1-.439.751.751 0 00.406-.794 13.352 13.352 0 01-.137-1.119.252.252 0 01.249-.27 6.658 6.658 0 004.886-1.757.248.248 0 01.345 0 6.841 6.841 0 005.059 1.77.252.252 0 01.186.079.254.254 0 01.067.191c-.029.354-.071.72-.136 1.106a.751.751 0 00.405.794c.025.013.111.148.106.439a1.279 1.279 0 01-.559 1.1 1.752 1.752 0 00-1 1.3C15.729 14.511 12.932 16 11.9 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});