define("ember-svg-jar/inlined/saving-piggy-dollars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>saving-piggy-dollars</title><path d=\"M17.38 14.328A7.842 7.842 0 019.6 7.467a.25.25 0 00-.265-.219 9.15 9.15 0 00-1.05.13A3.319 3.319 0 004.755 6.1a.9.9 0 00-.388 1.515 2.34 2.34 0 01.585.88.248.248 0 01-.091.292 6.835 6.835 0 00-2.809 3.892.248.248 0 01-.242.188h-.264a1.3 1.3 0 00-1.3 1.3v1.617a1.3 1.3 0 001.3 1.3h.926a.252.252 0 01.218.126 7.334 7.334 0 002.472 2.526l-.748 2.583a1.223 1.223 0 00.835 1.517 1.193 1.193 0 00.342.049A1.225 1.225 0 006.767 23l.583-2.014a.25.25 0 01.3-.172 9.29 9.29 0 004.771.005.25.25 0 01.3.172l.59 2.009a1.225 1.225 0 001.175.885 1.193 1.193 0 00.342-.049 1.223 1.223 0 00.836-1.517l-.7-2.4a.251.251 0 01.1-.278 6.707 6.707 0 003.123-5.066.25.25 0 00-.267-.266 7.93 7.93 0 01-.54.019zM4.292 12.956a.894.894 0 11.894.9.9.9 0 01-.894-.9z\"/><path d=\"M17.38 12.858a6.373 6.373 0 10-6.373-6.373 6.38 6.38 0 006.373 6.373zm.735-10.3a.25.25 0 00.25.25h.485a.736.736 0 010 1.471h-1.993A.58.58 0 0016.64 5.4l2.024.81a2.043 2.043 0 01-.349 3.9.251.251 0 00-.2.245v.3a.736.736 0 01-1.471 0v-.24a.25.25 0 00-.25-.25h-.485a.736.736 0 010-1.471H17.9a.58.58 0 00.217-1.118L16.1 6.763a2.043 2.043 0 01.35-3.9.249.249 0 00.2-.244v-.3a.736.736 0 111.471 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});