define("ember-svg-jar/inlined/medical-instrument-sampler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-instrument-sampler</title><path d=\"M20.656 18.71a.522.522 0 00-.824 0c-.265.384-1.588 2.348-1.588 3.284a2 2 0 004 0c0-.936-1.323-2.9-1.588-3.284zM11.615 1.4a.5.5 0 00-.154-.812A7.012 7.012 0 003.759 2l-.006.006a7.014 7.014 0 00-1.417 7.7.5.5 0 00.812.155zM19.285 17.535a1.563 1.563 0 00.066-2.141c-.4-.457-.79-.871-1.151-1.259-1.942-2.084-3.012-3.231-2.6-6.18a6.9 6.9 0 00-1.891-5.808.5.5 0 00-.713-.006l-9.1 9.1a.5.5 0 000 .713 6.906 6.906 0 005.81 1.891c2.946-.412 4.1.661 6.179 2.6.387.362.8.748 1.258 1.152a1.565 1.565 0 002.142-.066z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});