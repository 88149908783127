define("ember-svg-jar/inlined/swimming-cap-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>swimming-cap-1</title><path d=\"M1.5 10.5a12.6 12.6 0 01.176-2.1.25.25 0 00-.415-.226A3.982 3.982 0 000 11.125a4.524 4.524 0 001.763 3.643.25.25 0 00.391-.277A12.454 12.454 0 011.5 10.5zM22.74 8.175a.25.25 0 00-.416.226 12.6 12.6 0 01.176 2.1 12.454 12.454 0 01-.654 3.991.25.25 0 00.391.277A4.524 4.524 0 0024 11.125a3.985 3.985 0 00-1.26-2.95zM13.97 16.349a.749.749 0 00-1.057.077 1.254 1.254 0 01-.93.341 1.284 1.284 0 01-.936-.341.75.75 0 10-1.134.981 2.744 2.744 0 002.07.86 2.7 2.7 0 002.064-.86.749.749 0 00-.077-1.058zM9.854 12.633a.751.751 0 001.292-.765A1.822 1.822 0 009.5 11a1.822 1.822 0 00-1.646.868.751.751 0 001.292.765c.021-.037.078-.133.354-.133s.333.1.354.133zM14.5 11a1.822 1.822 0 00-1.646.868.751.751 0 001.292.765c.021-.037.078-.133.354-.133s.333.1.354.133a.751.751 0 001.292-.765A1.822 1.822 0 0014.5 11z\"/><path d=\"M14.763.454a.25.25 0 00-.288.375A5.727 5.727 0 0115.5 4a.5.5 0 01-1 0A5.468 5.468 0 0012.777.329a1.082 1.082 0 00-1.554 0A5.472 5.472 0 009.5 4a.5.5 0 01-1 0A5.727 5.727 0 019.525.829a.25.25 0 00-.288-.375A10.443 10.443 0 002.5 10.5a11.1 11.1 0 002.406 6.976 4.021 4.021 0 01.446.687 7.829 7.829 0 004.086 3.869.253.253 0 01.15.164.248.248 0 01-.04.219 1 1 0 001.488 1.323l.626-.573a.5.5 0 01.676 0l.626.573a1 1 0 001.488-1.323.248.248 0 01-.04-.219.253.253 0 01.15-.164 7.829 7.829 0 004.086-3.869 4.021 4.021 0 01.446-.687A11.1 11.1 0 0021.5 10.5 10.443 10.443 0 0014.763.454zM12 20.5c-3.533 0-6-3.5-6-8.5a6 6 0 0112 0c0 5.005-2.467 8.5-6 8.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});