define("ember-svg-jar/inlined/tags-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-alert</title><path d=\"M17.6 9.882l1.427-1.456a3.974 3.974 0 00.983-2.407V2.035A1.994 1.994 0 0018.023.043l-4.248.005a3.93 3.93 0 00-2.4 1L.583 11.835a2 2 0 000 2.819L5.4 19.473a1.968 1.968 0 001.4.579h.012a1.967 1.967 0 001.405-.594l5.528-5.642 1.176-2.24a2.92 2.92 0 012.365-1.553.493.493 0 00.314-.141zm-1.078-4.36a1.993 1.993 0 11-1.993-1.993 1.993 1.993 0 011.997 1.993zM18.8 12.271a1.444 1.444 0 00-2.553 0l-5.038 9.6a1.422 1.422 0 00.048 1.4 1.447 1.447 0 001.228.684h10.077a1.448 1.448 0 001.228-.684 1.422 1.422 0 00.048-1.4zm-1.276 2.469a.747.747 0 01.747.748v2.989a.747.747 0 11-1.494 0v-2.989a.747.747 0 01.747-.748zm0 5.481a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});