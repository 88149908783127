define("ember-svg-jar/inlined/presentation-projector-screen-programing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>presentation-projector-screen-programing</title><path d=\"M7.3 13.459a1 1 0 001.41-1.419l-1.119-1.106a.252.252 0 010-.354l1.118-1.126a1 1 0 10-1.418-1.409l-2 2.013a1 1 0 000 1.414zM15.293 13.457a1 1 0 001.414 0l2-2a1 1 0 000-1.415l-2-2a1 1 0 00-1.414 1.415l1.116 1.116a.249.249 0 010 .353l-1.116 1.116a1 1 0 000 1.415zM10.684 14.7a1 1 0 001.264-.632l2-6a1 1 0 10-1.9-.633l-2 6a1 1 0 00.636 1.265z\"/><path d=\"M24 1.75a1.5 1.5 0 00-1.5-1.5h-21a1.5 1.5 0 00-.214 2.984.25.25 0 01.214.248v14.543a.251.251 0 01-.2.245 1 1 0 00.2 1.98H11a.249.249 0 01.25.25v.314a.249.249 0 01-.107.2 1.5 1.5 0 101.714 0 .249.249 0 01-.107-.2V20.5a.249.249 0 01.25-.25h9.5a1 1 0 00.2-1.98.249.249 0 01-.2-.245V3.482a.251.251 0 01.214-.248A1.5 1.5 0 0024 1.75zM20.5 18a.25.25 0 01-.25.25H3.75A.25.25 0 013.5 18V3.5a.249.249 0 01.25-.25h16.5a.249.249 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});