define("ember-svg-jar/inlined/asp-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>asp-1</title><path d=\"M6.5 12.126A1.377 1.377 0 005.125 13.5v1.125a.25.25 0 00.25.25h2.25a.25.25 0 00.25-.25V13.5A1.377 1.377 0 006.5 12.126zM18.5 12.126h-1.125a.25.25 0 00-.25.25v2.25a.25.25 0 00.25.25H18.5a1.375 1.375 0 000-2.75z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM9.125 19.5a.625.625 0 01-1.25 0v-3.124a.25.25 0 00-.25-.25h-2.25a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0v-5.9a2.7 2.7 0 012.1-2.678 2.629 2.629 0 013.15 2.578zm2.68-5.047l1.949.975a2.479 2.479 0 01-1.108 4.7H10.5a.625.625 0 010-1.25h2.146a1.229 1.229 0 00.548-2.329l-1.949-.974a2.48 2.48 0 011.109-4.7H14.5a.625.625 0 010 1.25h-2.146a1.229 1.229 0 00-.549 2.328zm6.595 1.673h-1.025a.25.25 0 00-.25.25V19.5a.625.625 0 11-1.25 0v-8a.625.625 0 01.625-.625h2a2.629 2.629 0 012.574 3.148 2.7 2.7 0 01-2.674 2.103z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});