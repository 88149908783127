define("ember-svg-jar/inlined/common-file-text-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-text-star</title><path d=\"M20 7.5a1 1 0 00-1-1h-7a1 1 0 000 2h7a1 1 0 001-1zM12.5 11a1 1 0 000 2h4a1 1 0 000-2z\"/><path d=\"M23.413 3L21 .586A2 2 0 0019.584 0H8a2 2 0 00-2 2v6.792a.252.252 0 00.232.249 8.436 8.436 0 011.451.211.249.249 0 00.317-.24V2.5a.5.5 0 01.5-.5h10.878a.5.5 0 01.353.147l2.121 2.121a.5.5 0 01.148.353V18a.5.5 0 01-.5.5h-7.24a.489.489 0 00-.332.126c-.353.319-1.137.95-1.228 1.079-.043.06 0 .126.026.178.061.142.145.355.2.489a.22.22 0 00.2.127H22a2 2 0 002-2V4.415A2 2 0 0023.413 3z\"/><path d=\"M12.608 17.138a1.262 1.262 0 00.3-1.378 1.232 1.232 0 00-1.169-.76h-2.5a.252.252 0 01-.229-.151l-1.345-3.118-.013-.031a1.293 1.293 0 00-2.317.027l-1.346 3.121a.252.252 0 01-.23.151h-2.5a1.229 1.229 0 00-1.166.759 1.271 1.271 0 00.319 1.4l2.193 1.952a.249.249 0 01.063.286L1.445 22.21a1.255 1.255 0 00.315 1.452 1.358 1.358 0 001.506.166l3.111-1.753a.249.249 0 01.245 0l3.113 1.753a1.277 1.277 0 001.506-.166 1.257 1.257 0 00.316-1.451L10.333 19.4a.249.249 0 01.063-.286z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});