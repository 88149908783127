define("ember-svg-jar/inlined/style-three-pin-theater", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-theater</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM9.247 15.3a.571.571 0 01-.434.2H6.571A.571.571 0 016 14.929v-2.844a.5.5 0 01.647-.478 4.116 4.116 0 012.73 3.232.569.569 0 01-.13.461zM6.55 9.973a.5.5 0 01-.55-.5V5.5a1 1 0 011-1h3.975a.5.5 0 01.5.55A5.5 5.5 0 016.55 9.973zM18 14.929a.571.571 0 01-.571.571h-2.242a.572.572 0 01-.564-.661 4.116 4.116 0 012.73-3.232.5.5 0 01.647.478zm0-5.454a.5.5 0 01-.55.5 5.5 5.5 0 01-4.923-4.925.5.5 0 01.5-.55H17a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});