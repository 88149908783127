define("ember-svg-jar/inlined/arrow-down-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-down-2-alternate</title><path d=\"M21.537 2.384v12.922a2.391 2.391 0 01-.8 1.786l-7.156 6.314a2.393 2.393 0 01-3.156 0L3.269 17.1a2.4 2.4 0 01-.806-1.789V2.384A2.386 2.386 0 014.847 0h14.306a2.386 2.386 0 012.384 2.384zm-2.945 12.783a.254.254 0 00.084-.188V3.111a.25.25 0 00-.25-.25H5.574a.25.25 0 00-.25.25v11.868a.254.254 0 00.084.188l6.427 5.669a.248.248 0 00.33 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});