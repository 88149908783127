define("ember-svg-jar/inlined/content-pen-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-pen-3</title><path d=\"M22.453 1.973a3.1 3.1 0 00-3.595-.564.959.959 0 00-.095-.143L18.4.9a3.076 3.076 0 00-4.345 0L8.581 6.37a1 1 0 000 1.414 1.012 1.012 0 001.414 0l5.47-5.47a1.073 1.073 0 011.517 0l.366.366-2.224 2.225a.517.517 0 000 .733L18.789 9.3a.517.517 0 00.733 0l2.931-2.931a3.11 3.11 0 000-4.396z\"/><path d=\"M14.023 6.738a.519.519 0 00-.733 0l-8.227 8.229a1.554 1.554 0 000 2.2l2.2 2.2a1.555 1.555 0 002.2 0l8.228-8.229a.518.518 0 000-.732zM3.434 17.76a.5.5 0 00-.824.184L.665 23.33a.5.5 0 00.118.523.508.508 0 00.523.117l5.386-1.943a.5.5 0 00.183-.824z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});