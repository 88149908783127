define("ember-svg-jar/inlined/social-media-bebo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-bebo</title><path d=\"M14.5 21h-2A7.51 7.51 0 015 13.5v-10a1.5 1.5 0 013 0v10a4.5 4.5 0 004.5 4.5h2a3.5 3.5 0 000-7H12a1.5 1.5 0 010-3h2.5a6.5 6.5 0 010 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});