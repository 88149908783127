define("ember-svg-jar/inlined/modern-music-guitar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-music-guitar</title><path d=\"M23.707 10.705a1 1 0 000-1.414l-.793-.791a1 1 0 00-1.414 0l-4.712 4.713a.251.251 0 01-.267.056 3.251 3.251 0 00-2.656.1 2.91 2.91 0 00-1.429 1.661.249.249 0 01-.256.168 3.474 3.474 0 00-3.509 1.735 4.736 4.736 0 001.1 5.5A5.176 5.176 0 0013.377 24a3.52 3.52 0 003.636-3.975.249.249 0 01.168-.258 2.91 2.91 0 001.663-1.432 3.2 3.2 0 00.1-2.653.251.251 0 01.056-.266zm-12.53 8.323a.75.75 0 011.061-1.061l2 2a.75.75 0 01-1.061 1.061zm3.223-1.217a1.251 1.251 0 111.768 0 1.252 1.252 0 01-1.768 0z\"/><path d=\"M9 15a1 1 0 001-1v-1.75a.249.249 0 01.25-.25H16a2 2 0 002-2V2a2 2 0 00-2-2H2a2 2 0 00-2 2v8a2 2 0 002 2h5.75a.249.249 0 01.25.25v.5a.25.25 0 01-.25.25H6.043a1 1 0 000 2zM2 2.248A.249.249 0 012.25 2h13.5a.249.249 0 01.25.25v7.5a.25.25 0 01-.25.25H2.25A.25.25 0 012 9.748z\"/><path d=\"M10.139 7.491V5.164l.411.309a.751.751 0 00.9-1.2L9.839 3.064a.75.75 0 00-1.2.6v2.089a1.681 1.681 0 00-.257-.019 1.757 1.757 0 101.757 1.757z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});