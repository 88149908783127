define("ember-svg-jar/inlined/social-flickr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-flickr</title><circle cx=\"18.5\" cy=\"12\" r=\"5.5\"/><circle cx=\"5.5\" cy=\"12\" r=\"5.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});