define("ember-svg-jar/inlined/single-neutral-actions-image.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-image</title><path d=\"M8.956 11a.345.345 0 00-.273-.337A6.894 6.894 0 007.25 10.5a7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h7.912a.5.5 0 00.5-.5z\"/><circle cx=\"7.044\" cy=\"4.75\" r=\"4.75\"/><path d=\"M12.5 10.5a2 2 0 00-2 2V22a2 2 0 002 2H22a2 2 0 002-2v-9.5a2 2 0 00-2-2zm8.775 6.387a1.25 1.25 0 00-2.064-.024l-1.537 2.2a.254.254 0 01-.169.1.25.25 0 01-.192-.053l-.612-.489a1.249 1.249 0 00-1.821.282l-2 3.005a.2.2 0 01-.171.092.206.206 0 01-.206-.206V12.75a.25.25 0 01.25-.25h9a.25.25 0 01.25.25v4.4a.25.25 0 01-.458.139z\"/><circle cx=\"15.5\" cy=\"15\" r=\"1.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});