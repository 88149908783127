define("ember-svg-jar/inlined/video-game-logo-dota2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-logo-dota2</title><path d=\"M24 1.5A1.5 1.5 0 0022.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5zm-8.773 3.228L18.234 3.6a.5.5 0 01.488.077l2.006 1.605a.5.5 0 01.162.549l-1.134 3.4a.5.5 0 01-.828.2l-3.879-3.882a.5.5 0 01.178-.821zM9.62 19.408a.5.5 0 01-.31.351L6.272 20.9a.5.5 0 01-.5-.084L3.29 18.742a.5.5 0 01-.136-.587L4.689 14.7a.5.5 0 01.827-.133L9.5 18.954a.5.5 0 01.12.454zm11.18-3.583a.5.5 0 01.17.441l-.419 3.348a.5.5 0 01-.434.434l-3.351.419a.5.5 0 01-.438-.167L3.039 5.116a.5.5 0 01.255-.816l2.955-.739a.5.5 0 01.447.106z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});