define("ember-svg-jar/inlined/duck-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>duck-2</title><path d=\"M11.927 21.117a.5.5 0 00-.478-.355H2.911a.5.5 0 00-.476.648c.21.665.421 1.307.626 1.913a1 1 0 00.944.677h7.47a1 1 0 00.947-1.308q-.273-.83-.495-1.575z\"/><path d=\"M23.247 17.424c-2.4-3.2-3.946-6.254-3.946-7.79C19.3 8.054 17.942 0 9.665 0A9.646 9.646 0 00.03 9.634 37.428 37.428 0 001.684 18.9a.5.5 0 00.479.363h8.617a.5.5 0 00.485-.612c-.942-4.02-.521-5.567 0-5.826.419-.209 1.7.63 2.1.938 1.535 1.17 5.247 6.768 5.305 6.845a3.015 3.015 0 004.261.876c1.814-1.359.804-3.41.316-4.06zM14.323 9.6a1.246 1.246 0 111.246-1.246A1.245 1.245 0 0114.323 9.6zm7.406 10.3c-.341.254-.939.217-1.455-.458-.351-.529-2.341-3.506-4.063-5.564a4.454 4.454 0 001.865-1.178 31.373 31.373 0 003.577 5.92c.315.42.493.962.076 1.28z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});