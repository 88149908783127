define("ember-svg-jar/inlined/swan-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>swan-1</title><path d=\"M22.73 12.057a.5.5 0 00-.446-.008.5.5 0 00-.274.353c0 .021-.438 2.1-2.01 2.1a4.7 4.7 0 01-2.344-.769A4.455 4.455 0 0015.5 13c-2.765 0-2.414 2.043-2.387 2.151a1.117 1.117 0 001.354.812.75.75 0 01.363 1.455 2.619 2.619 0 01-3.172-1.9 3.081 3.081 0 01.375-2.363 3.163 3.163 0 011.378-1.235C13.7 10.577 15 8.819 15 7a7 7 0 00-14-.183A6.032 6.032 0 000 10.5a4.026 4.026 0 002 3.287V16.5a1.5 1.5 0 103 0V7a3 3 0 016 0 4.649 4.649 0 01-1.3 2.248C8.5 10.752 7 12.625 7 15.5a8.409 8.409 0 008.5 8.5 8.51 8.51 0 008.5-8.5c0-2.7-1.14-3.376-1.27-3.443zM2.5 11.5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});