define("ember-svg-jar/inlined/earth-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earth-1</title><path d=\"M24 12a12 12 0 10-12 12h.125A11.888 11.888 0 0024 12.125v-.063V12zm-2 0a9.862 9.862 0 01-.359 2.633.5.5 0 01-.681.326 3.484 3.484 0 01-1.517-1.268l-2.218-3.328a2.5 2.5 0 00-2.08-1.113h-.433a2.75 2.75 0 110-5.5h2.772a.5.5 0 01.289.092A9.993 9.993 0 0122 12zM2.179 10.155a.5.5 0 01.491-.4h4.337a3.477 3.477 0 012.475 1.025L10.6 11.9a3.5 3.5 0 01.14 4.8l-1.359 1.529a2.5 2.5 0 00-.631 1.662v.827a.5.5 0 01-.7.458A10 10 0 012 12a9.765 9.765 0 01.179-1.845z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});