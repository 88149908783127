define("ember-svg-jar/inlined/clean-car-cable-charge-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>clean-car-cable-charge-2</title><path d=\"M10.658 8.033a.5.5 0 01.342.474V12a1 1 0 002 0V8.507a.5.5 0 01.342-.474A4.259 4.259 0 0016.25 4a.75.75 0 00-.75-.75.5.5 0 01-.5-.5V1a1 1 0 00-2 0v1.75a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v1.75a.5.5 0 01-.5.5.75.75 0 00-.75.75 4.258 4.258 0 002.908 4.033z\"/><path d=\"M20.857 13.985a1 1 0 00-1.372-.342l-.509.3a.5.5 0 01-.754-.37l-.122-1.057a2 2 0 00-1.986-1.766h-.864a1 1 0 000 2h.416a.5.5 0 01.5.441l.206 1.75a.5.5 0 01-.5.559H8.128a.5.5 0 01-.5-.559l.206-1.75a.5.5 0 01.5-.441h.416a1 1 0 000-2h-.861A2 2 0 005.9 12.517l-.125 1.061a.5.5 0 01-.754.37l-.509-.3a1 1 0 10-1.03 1.714l.818.491a.5.5 0 01.151.716A2.494 2.494 0 004 18v1a1.5 1.5 0 001.5 1.5h13A1.5 1.5 0 0020 19v-1a2.491 2.491 0 00-.454-1.436.5.5 0 01.152-.716l.817-.491a1 1 0 00.342-1.372zM7.5 21.5h-2a.5.5 0 00-.5.5v.5a1.5 1.5 0 003 0V22a.5.5 0 00-.5-.5zM18.5 21.5h-2a.5.5 0 00-.5.5v.5a1.5 1.5 0 003 0V22a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});