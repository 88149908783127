define("ember-svg-jar/inlined/pin.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin</title><path d=\"M12 0a8.009 8.009 0 00-8 8c0 3.51 5 12.025 7.148 15.524A1 1 0 0012 24a.991.991 0 00.852-.477C15 20.026 20 11.514 20 8a8.009 8.009 0 00-8-8zm0 11.5A3.5 3.5 0 1115.5 8a3.5 3.5 0 01-3.5 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});