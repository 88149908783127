define("ember-svg-jar/inlined/elephant-mother", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>elephant-mother</title><path d=\"M11 14.872a4.707 4.707 0 01.159-1.081 4.446 4.446 0 013.052-3.107 4.574 4.574 0 012.922.123A6.368 6.368 0 0119 10.5a2.542 2.542 0 012.366 1.674A4.949 4.949 0 0124 13.969V10.93a5.293 5.293 0 00-5.13-5.43h-2.086c-.6 2.608-1.685 4.53-4.394 4.53a.75.75 0 010-1.5c1.522 0 2.3-.846 2.867-3.1a3.649 3.649 0 00-.379-3 3.443 3.443 0 00-2.893-.93l-.3.009c-1.513.04-2.641.07-3.618.917a5.934 5.934 0 00-3.077-.392A5.948 5.948 0 000 7.989V13.5a7.911 7.911 0 002.687 5.89.507.507 0 00.568.04l1.69-1a.5.5 0 00.1-.784A4.661 4.661 0 014 14.449v-.6a.5.5 0 01.621-.486 9.491 9.491 0 001.6.241.5.5 0 01.455.4l.115.575A16.391 16.391 0 017 17.729V22a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-7.128zM5.5 7a1 1 0 11-1-1 1 1 0 011 1z\"/><path d=\"M23.5 16.936a3.394 3.394 0 00-2.3-3.229 3.213 3.213 0 01-1.23 2.992 1.572 1.572 0 01-2.171-.723.5.5 0 01.892-.452.574.574 0 00.831.281 2.472 2.472 0 00.661-2.225.479.479 0 010-.076c-.152-.949-.58-1.5-1.188-1.5a5.4 5.4 0 00-1.95.431 3.041 3.041 0 00-2.424-.307 2.975 2.975 0 00-2.011 2.052 3.232 3.232 0 00-.109.749V20A1.458 1.458 0 0014 21.5a.5.5 0 00.5-.5v-2.5a.5.5 0 01.5-.5h.5a1.1 1.1 0 011 1v3a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1.5a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5v1.25a.75.75 0 001.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});