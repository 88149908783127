define("ember-svg-jar/inlined/light-mode-sunny-man-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-mode-sunny-man-alternate</title><path d=\"M16.31 22.587c-.536-1.071-2.775-1.815-5.586-2.856-.76-.282-.635-2.265-.3-2.636a5.609 5.609 0 001.459-4.348A3.432 3.432 0 008.327 9a3.433 3.433 0 00-3.558 3.747 5.605 5.605 0 001.46 4.353c.337.371.461 2.354-.3 2.636-2.81 1.036-5.049 1.78-5.585 2.851a6.644 6.644 0 00-.315.746A.5.5 0 00.5 24h15.654a.5.5 0 00.472-.667 6.474 6.474 0 00-.316-.746z\"/><circle cx=\"17.75\" cy=\"6.25\" r=\"3\"/><path d=\"M17.75 2a.75.75 0 00.75-.75v-.5a.75.75 0 00-1.5 0v.5a.75.75 0 00.75.75zM13.685 3.245a.75.75 0 001.06-1.061l-.353-.353a.75.75 0 10-1.061 1.06zM12.25 7h.5a.75.75 0 000-1.5h-.5a.75.75 0 000 1.5zM13.685 9.255l-.354.354a.75.75 0 101.061 1.06l.353-.353a.75.75 0 00-1.06-1.061zM17.75 10.5a.75.75 0 00-.75.75v.5a.75.75 0 001.5 0v-.5a.75.75 0 00-.75-.75zM21.816 9.255a.75.75 0 00-1.061 1.061l.354.353a.75.75 0 101.06-1.06zM23.25 5.5h-.5a.75.75 0 000 1.5h.5a.75.75 0 000-1.5zM21.285 3.464a.749.749 0 00.531-.219l.353-.354a.75.75 0 10-1.06-1.06l-.354.353a.75.75 0 00.53 1.28z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});