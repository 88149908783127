define("ember-svg-jar/inlined/phone-actions-refresh-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-refresh-1</title><path d=\"M15.5 15.669a3.116 3.116 0 00-4.4 0l-.827.828A4.249 4.249 0 008.92 21a11.357 11.357 0 01-6.032-6.03 4.25 4.25 0 004.5-1.346l.83-.829a3.117 3.117 0 000-4.4L4.635 4.808a2.233 2.233 0 00-3.649.74A13.313 13.313 0 0018.343 22.9a2.233 2.233 0 00.737-3.648zM23.691.424a.5.5 0 00-.545.108L21.674 2a6.374 6.374 0 10-4.528 10.86 6.346 6.346 0 004.983-2.4 1 1 0 00-1.563-1.248 4.376 4.376 0 11-.3-5.8l-1.577 1.577a.5.5 0 00.354.854H23.5a.5.5 0 00.5-.5V.886a.5.5 0 00-.309-.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});