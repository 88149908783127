define("ember-svg-jar/inlined/paralympics-race-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paralympics-race-1</title><path d=\"M13.49 7.964a.251.251 0 00-.267-.369A6.083 6.083 0 0112 7.719a6.034 6.034 0 01-1.074-.1.25.25 0 00-.262.368l1.183 2.113a.251.251 0 00.436 0z\"/><circle cx=\"12\" cy=\"3.75\" r=\"2.5\"/><path d=\"M18.757 14.947a1.25 1.25 0 10-2.425.607l.424 1.7H14a.5.5 0 01-.5-.5V16h.5a1 1 0 001-1V9.676A8.726 8.726 0 0020.719 1.5a1.25 1.25 0 00-2.5 0 6.213 6.213 0 01-2.836 5.209.247.247 0 00-.083.091l-2.306 4.07a.251.251 0 00.044.3 1.484 1.484 0 01.462 1.08 1.5 1.5 0 11-2.465-1.144.249.249 0 00.057-.313L8.927 6.938a.248.248 0 00-.09-.092A6.218 6.218 0 015.781 1.5a1.25 1.25 0 10-2.5 0A8.726 8.726 0 009 9.676V15a1 1 0 001 1h.5v.75a.5.5 0 01-.5.5H7.244l.424-1.7a1.25 1.25 0 00-2.425-.607l-1.455 5.825a1.25 1.25 0 002.425.606l.531-2.124H10a.5.5 0 01.5.5v2.5a1.5 1.5 0 003 0v-2.5a.5.5 0 01.5-.5h3.256l.531 2.124a1.25 1.25 0 002.425-.606z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});