define("ember-svg-jar/inlined/car-tool-jumper-cables", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-tool-jumper-cables</title><path d=\"M23.715 23a1 1 0 00-1-1 1.94 1.94 0 01-.416-.039.16.16 0 01-.1-.073.157.157 0 01-.017-.125 50.128 50.128 0 001.535-7.256c0-1.391-.4-2.021-.792-2.308.013-.041.034-.08.046-.122a2.752 2.752 0 00-1.945-3.366L16.194 7.42a1.226 1.226 0 00-1.329.506.248.248 0 01-.271.1l-2.943-.788a.248.248 0 01-.153-.117 2.873 2.873 0 00-.46-.617L4.674.146C4.665.137 4.6 0 4.071 0h-.707a.75.75 0 100 1.5h.51a.249.249 0 01.242.186l.657 2.493a.25.25 0 01-.306.3l-2.495-.652a.25.25 0 01-.187-.241v-.511a.75.75 0 00-1.5 0v.707c0 .523.137.594.147.6L6.8 10.743a2.883 2.883 0 00.618.459.255.255 0 01.118.153l.783 2.945a.251.251 0 01-.1.271 1.225 1.225 0 00-.507 1.329l1.294 4.82a2.75 2.75 0 003.369 1.942 2.747 2.747 0 001.944-3.362l-1.294-4.826a1.225 1.225 0 00-1.1-.9.251.251 0 01-.222-.185l-.686-2.558a.094.094 0 01.024-.089.092.092 0 01.09-.024l2.556.684a.25.25 0 01.185.229 1.119 1.119 0 00.143.514 1.238 1.238 0 00.759.582l4.83 1.293a2.784 2.784 0 00.716.095 2.6 2.6 0 001.032-.225.25.25 0 01.349.206c.01.109.017.244.017.412a48 48 0 01-1.449 6.677 2.215 2.215 0 00.264 1.9 2.522 2.522 0 002.182.915 1 1 0 001-1zM8.785 9.514a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});