define("ember-svg-jar/inlined/dresser-wardrobe-drawers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dresser-wardrobe-drawers</title><rect x=\"13.5\" y=\"6\" width=\"8.5\" height=\"3.5\" rx=\".5\" ry=\".5\"/><rect x=\"13.5\" y=\"11\" width=\"8.5\" height=\"3.5\" rx=\".5\" ry=\".5\"/><path d=\"M13.5 4a.5.5 0 00.5.5h7.5A.5.5 0 0022 4V1a1 1 0 00-1-1h-7a.5.5 0 00-.5.5zM14 16a.5.5 0 00-.5.5v7a.5.5 0 00.5.5h7a1 1 0 001-1v-6.5a.5.5 0 00-.5-.5zm5.25 3.5h-3a.75.75 0 010-1.5h3a.75.75 0 010 1.5zM12 .5a.5.5 0 00-.5-.5H3a1 1 0 00-1 1v22a1 1 0 001 1h8.5a.5.5 0 00.5-.5zM9.25 14a.75.75 0 01-.75-.75v-3a.75.75 0 011.5 0v3a.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});