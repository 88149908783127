define("ember-svg-jar/inlined/diagram-arrow-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-arrow-down</title><path d=\"M5.5 15.215a1 1 0 00-.733 1.685l6.16 6.633a1.458 1.458 0 002.146 0l6.16-6.633a1 1 0 00-.733-1.681h-3.25a.25.25 0 01-.25-.25V1.5A1.5 1.5 0 0013.5 0h-3A1.5 1.5 0 009 1.5v13.465a.25.25 0 01-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});