define("ember-svg-jar/inlined/electronics-integrated-circuit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>electronics-integrated-circuit</title><rect x=\"9\" y=\"5.501\" width=\"6\" height=\"13\" rx=\".25\" ry=\".25\"/><path d=\"M20 11a1 1 0 000-2h-1.25a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H20a1 1 0 000-2h-1.25a.25.25 0 01-.25-.25V3.5A1.5 1.5 0 0017 2h-.25a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v.75a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v.75a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25V1a1 1 0 10-2 0v.75a.25.25 0 01-.25.25H7a1.5 1.5 0 00-1.5 1.5v1.75a.25.25 0 01-.25.25H4a1 1 0 000 2h1.25a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H4a1 1 0 000 2h1.25a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H4a1 1 0 100 2h1.25a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H4a1 1 0 000 2h1.25a.25.25 0 01.25.25v1.75A1.5 1.5 0 007 22h.25a.25.25 0 01.25.25V23a1 1 0 002 0v-.75a.25.25 0 01.25-.25h1a.25.25 0 01.25.25V23a1 1 0 102 0v-.75a.25.25 0 01.25-.25h1a.25.25 0 01.25.25V23a1 1 0 102 0v-.75a.25.25 0 01.25-.25H17a1.5 1.5 0 001.5-1.5v-1.75a.25.25 0 01.25-.25H20a1 1 0 000-2h-1.25a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H20a1 1 0 000-2h-1.25a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25zm-3.5 8.5a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5v-15A.5.5 0 018 4h8a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});