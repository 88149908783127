define("ember-svg-jar/inlined/table-lamp-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>table-lamp-3</title><path d=\"M13.484 19.805a.25.25 0 01-.234-.246v-4.727a.163.163 0 01.249-.143 2.175 2.175 0 00.5.061 2.367 2.367 0 00.815-.15c.181-.105.185.154.185.154v2a1 1 0 002 0v-2s-.039-.259.161-.163a2.383 2.383 0 00.84.159 2.479 2.479 0 002.427-3.076L19.09 1.15a1 1 0 00-1-.9H5.905a1 1 0 00-1 .9L3.573 11.674a2.129 2.129 0 00.342 1.826 2.577 2.577 0 002.311 1.24 2.482 2.482 0 001.585-.778.255.255 0 01.378 0A2.479 2.479 0 0010 14.75a2.214 2.214 0 00.525-.067c.076-.018.225.168.225.168v4.718a.246.246 0 01-.228.245C7.114 20.1 4.006 21.332 4 23.244a.5.5 0 00.5.506h15a.5.5 0 00.5-.5c0-1.835-2.615-3.174-6.516-3.445zM8.447 10.8a1 1 0 01-1 .952H7.4a1 1 0 01-.951-1.052l.36-7.5a1.018 1.018 0 011.046-.951A1 1 0 018.807 3.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});