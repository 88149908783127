define("ember-svg-jar/inlined/meeting-team-monitor-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-team-monitor-man</title><circle cx=\"14.58\" cy=\"4.749\" r=\"2\"/><path d=\"M17.4 10.749a.5.5 0 00.47-.683 3.6 3.6 0 00-3.29-2.317 3.54 3.54 0 00-3.048 1.817.25.25 0 00.014.274 2.788 2.788 0 01.388.741.25.25 0 00.237.168z\"/><path d=\"M22.25 0h-16A1.753 1.753 0 004.5 1.749v4.5a.75.75 0 001.5 0v-4.5a.253.253 0 01.25-.25h16a.253.253 0 01.25.25v12a.253.253 0 01-.25.25h-8.5a.75.75 0 00-.375 1.4.252.252 0 01.125.217v2.134a.75.75 0 00.75.75h3.5a.75.75 0 000-1.5h-2.5a.249.249 0 01-.25-.25v-1a.25.25 0 01.25-.25h7A1.752 1.752 0 0024 13.749v-12A1.753 1.753 0 0022.25 0z\"/><path d=\"M9.468 18.8l-.391-.145a.25.25 0 01-.163-.234v-.831a.248.248 0 01.073-.176 2.622 2.622 0 00.752-1.514.255.255 0 01.052-.123 1.384 1.384 0 00.246-.544A1.539 1.539 0 009.846 14a.016.016 0 010-.015v-.007a4.836 4.836 0 00.469-2.477c-.212-1.061-1.58-1.47-2.79-1.5a3.318 3.318 0 00-2.495.773.247.247 0 01-.137.086 1.252 1.252 0 00-.734.47 3.059 3.059 0 00-.018 2.523.253.253 0 01-.029.206 1.572 1.572 0 00-.149 1.176 1.369 1.369 0 00.245.544.242.242 0 01.052.123 2.626 2.626 0 00.753 1.512.248.248 0 01.073.176v.834a.25.25 0 01-.163.234l-.393.142c-2.008.738-3.459 1.272-3.912 2.179A6.216 6.216 0 000 23.5a.5.5 0 00.5.5h13a.5.5 0 00.5-.5 6.216 6.216 0 00-.618-2.516c-.454-.909-1.905-1.443-3.914-2.184z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});