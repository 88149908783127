define("ember-svg-jar/inlined/landmarks-telescope-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmarks-telescope-person</title><path d=\"M3 1a1 1 0 00-1-1H1a1 1 0 00-1 1v5a1 1 0 001 1h1a1.026 1.026 0 001-1.027zM4 5.3a.25.25 0 00.28.248l2.185-.259a.249.249 0 00.205-.161 2.458 2.458 0 01.877-1.158A2.49 2.49 0 019 3.5a2.425 2.425 0 011.1.271.25.25 0 00.363-.223v-.839a1.006 1.006 0 00-.925-1l-5.271-.344a.25.25 0 00-.267.25zM4.465 12.787l-4.378 9.8a1 1 0 101.826.814l2.552-5.715V23a1 1 0 002 0v-5.374l2.624 5.786a1 1 0 101.822-.826l-4.446-9.8V6.571a.251.251 0 00-.279-.248l-1.5.178a.25.25 0 00-.221.248z\"/><circle cx=\"15.5\" cy=\"3.25\" r=\"2.25\"/><path d=\"M19.2 6.6a1.5 1.5 0 00-2.072-.32L14.32 8.285a1.51 1.51 0 01-2.093-.349l-2.006-2.808a1.518 1.518 0 00-2.093-.349 1.5 1.5 0 00-.348 2.093L9.785 9.68a4.5 4.5 0 006.279 1.046l1.417-1.012a.249.249 0 01.345.053L20.7 13.6a1.487 1.487 0 01.3.9v8a1.5 1.5 0 003 0v-8a4.54 4.54 0 00-.9-2.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});