define("ember-svg-jar/inlined/notes-module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-module</title><path d=\"M12 5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v6.15a.248.248 0 00.284.248c.416-.067 1.12-.113 1.484-.133a.246.246 0 00.232-.249V4.5a2 2 0 00-2-2h-1.25a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-1.5A.25.25 0 016 2.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25H2a2 2 0 00-2 2v13a2 2 0 002 2h8.361a.246.246 0 00.235-.186 2.5 2.5 0 01.977-1.365.247.247 0 00-.143-.449H2.25a.25.25 0 01-.25-.25V4.75a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25z\"/><rect x=\"19\" y=\"19\" width=\"5\" height=\"5\" rx=\"1\" ry=\"1\"/><rect x=\"12\" y=\"19\" width=\"5\" height=\"5\" rx=\"1\" ry=\"1\"/><rect x=\"15.5\" y=\"12.5\" width=\"5\" height=\"5\" rx=\"1\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});