define("ember-svg-jar/inlined/outdoors-kite-flying", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-kite-flying</title><path d=\"M7.712 6.265h5.774a.523.523 0 00.523-.523V.525a.522.522 0 00-.871-.39 2302.168 2302.168 0 00-5.78 5.223.522.522 0 00.354.907zM16.244 6.265h5.773a.522.522 0 00.354-.907c-1.5-1.367-4.347-3.931-5.779-5.222a.522.522 0 00-.871.39v5.216a.523.523 0 00.523.523zM13.039 18.649a.522.522 0 00.97-.27V8.5a.523.523 0 00-.523-.523H7.619a.522.522 0 00-.45.788c1.177 1.982 4.28 7.209 5.87 9.884zM22.112 7.977h-5.868a.523.523 0 00-.523.523v9.879a.522.522 0 00.969.271c1.591-2.676 4.7-7.9 5.872-9.885a.522.522 0 00-.45-.788z\"/><path d=\"M15.216 19.063a1 1 0 00-1.288.585 1.535 1.535 0 01-.8.986c-1.005.454-2.843-.253-4.32-.821A9.138 9.138 0 005.865 19c-3.184 0-4.4 3.533-4.449 3.683a1 1 0 101.9.633C3.321 23.292 4.134 21 5.865 21a8.919 8.919 0 012.222.68c1.918.737 4.092 1.573 5.857.778a3.545 3.545 0 001.856-2.107 1 1 0 00-.584-1.288z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});