define("ember-svg-jar/inlined/add-bold-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>add-bold-alternate</title><path d=\"M21.545 7.886h-5.182a.249.249 0 01-.25-.25V2.454a2.206 2.206 0 00-2.2-2.2H10.09a2.206 2.206 0 00-2.2 2.2v5.182a.249.249 0 01-.25.25H2.454a2.207 2.207 0 00-2.2 2.2v3.818a2.206 2.206 0 002.205 2.2h5.177a.25.25 0 01.25.25v5.183a2.206 2.206 0 002.2 2.2h3.818a2.207 2.207 0 002.205-2.2v-5.174a.25.25 0 01.25-.25h5.182a2.206 2.206 0 002.2-2.2v-3.822a2.206 2.206 0 00-2.196-2.205zm-.3 5.477a.25.25 0 01-.25.25h-6.132a1.25 1.25 0 00-1.25 1.25V21a.25.25 0 01-.25.25h-2.727a.249.249 0 01-.25-.25v-6.137a1.25 1.25 0 00-1.25-1.25H3a.25.25 0 01-.25-.25v-2.727a.25.25 0 01.25-.25h6.136a1.25 1.25 0 001.25-1.25V3a.249.249 0 01.25-.25h2.727a.25.25 0 01.25.25v6.136a1.25 1.25 0 001.25 1.25H21a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});