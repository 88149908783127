define("ember-svg-jar/inlined/official-building-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>official-building-1</title><path d=\"M23.25 22.5H23a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h.25a.75.75 0 000-1.5H.75a.75.75 0 000 1.5H1a.5.5 0 01.5.5v4a.5.5 0 01-.5.5H.75a.75.75 0 000 1.5h22.5a.75.75 0 000-1.5zM6 21.25a.75.75 0 01-1.5 0v-2.5a.75.75 0 011.5 0zm4.5 0a.75.75 0 01-1.5 0v-2.5a.75.75 0 011.5 0zm4.5 0a.75.75 0 01-1.5 0v-2.5a.75.75 0 011.5 0zm4.5 0a.75.75 0 01-1.5 0v-2.5a.75.75 0 011.5 0zM6.179 7.5h11.642a.249.249 0 00.233-.34 6.506 6.506 0 00-4.661-4A.5.5 0 0113 2.671V1a1 1 0 00-2 0v1.671a.5.5 0 01-.393.488 6.506 6.506 0 00-4.661 4 .249.249 0 00.233.34zM4.25 10H5a.5.5 0 01.5.5v4a.5.5 0 00.5.5h12a.5.5 0 00.5-.5v-4a.5.5 0 01.5-.5h.75a.75.75 0 000-1.5H4.25a.75.75 0 000 1.5zm9.25.5a.75.75 0 011.5 0V13a.75.75 0 01-1.5 0zm-4.5 0a.75.75 0 011.5 0V13A.75.75 0 019 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});