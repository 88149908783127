define("ember-svg-jar/inlined/self-payment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>self-payment</title><path d=\"M17.864 5.77a.733.733 0 100-1.466h-.483a.25.25 0 01-.25-.25v-.116a.733.733 0 10-1.466 0v.156a.218.218 0 01-.2.218A1.312 1.312 0 0014.2 5.77c0 .869.869 1.454 1.789 2.073.169.114.386.26.585.408a.25.25 0 01-.15.451h-1.492a.733.733 0 100 1.466h.483a.25.25 0 01.25.25v.116a.733.733 0 001.466 0v-.187a.186.186 0 01.171-.186A1.345 1.345 0 0018.6 8.7c0-.87-.87-1.455-1.79-2.075-.17-.114-.388-.261-.589-.4a.25.25 0 01.144-.454z\"/><path d=\"M19.818 14.688c1.7 0 2.932-1.028 2.932-2.444V2.472A2.245 2.245 0 0020.307.028H3.693A2.245 2.245 0 001.25 2.472v9.772c0 1.416 1.233 2.444 2.932 2.444h6.1a.249.249 0 01.25.25v1.41a.25.25 0 01-.25.25H4.9a.951.951 0 00-.715.311l-.014.015-2.2 2.192-.019.02a.9.9 0 00-.132.223.25.25 0 00.23.347h19.896a.25.25 0 00.23-.347.9.9 0 00-.142-.235.425.425 0 00-.029-.031l-2.144-2.135-.013-.014a.98.98 0 00-.748-.344h-5.384a.25.25 0 01-.25-.25v-1.41a.249.249 0 01.25-.25zM3.205 12.244V2.472c0-.443.045-.489.488-.489h16.614c.443 0 .488.046.488.489v9.772c0 .356-.6.489-.977.489H4.182c-.382 0-.977-.133-.977-.489zM1.989 20.683a.25.25 0 00-.25.25v2.061a.979.979 0 00.977.978h18.568a.979.979 0 00.977-.978v-2.061a.25.25 0 00-.25-.25z\"/><rect x=\"5.648\" y=\"3.938\" width=\"2.932\" height=\"2.932\" rx=\".489\" ry=\".489\"/><rect x=\"9.557\" y=\"3.938\" width=\"2.932\" height=\"2.932\" rx=\".489\" ry=\".489\"/><rect x=\"5.648\" y=\"7.847\" width=\"2.932\" height=\"2.932\" rx=\".489\" ry=\".489\"/><rect x=\"9.557\" y=\"7.847\" width=\"2.932\" height=\"2.932\" rx=\".489\" ry=\".489\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});