define("ember-svg-jar/inlined/modern-tv-flat-screen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-tv-flat-screen</title><path d=\"M21.75 1.75H2.25A2.253 2.253 0 000 4v11a2.253 2.253 0 002.25 2.25h8a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5h-3a1.25 1.25 0 000 2.5h9.5a1.25 1.25 0 000-2.5h-3a.5.5 0 01-.5-.5v-1.5a.5.5 0 01.5-.5h8A2.253 2.253 0 0024 15V4a2.253 2.253 0 00-2.25-2.25zm-.25 12.5a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5v-9.5a.5.5 0 01.5-.5h18a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});