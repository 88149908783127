define("ember-svg-jar/inlined/single-neutral-mail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-mail</title><path d=\"M5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M24 3.761a.25.25 0 00-.383-.212l-4.425 3.078a2.26 2.26 0 01-2.384 0l-4.425-3.078a.25.25 0 00-.383.212V8a1.5 1.5 0 001.5 1.5h9A1.5 1.5 0 0024 8z\"/><path d=\"M17.6 5.355a.752.752 0 00.794 0l5.568-3.792A.075.075 0 0024 1.5 1.5 1.5 0 0022.5 0h-9A1.5 1.5 0 0012 1.5a.073.073 0 00.035.063zM5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M24 3.761a.25.25 0 00-.383-.212l-4.425 3.078a2.26 2.26 0 01-2.384 0l-4.425-3.078a.25.25 0 00-.383.212V8a1.5 1.5 0 001.5 1.5h9A1.5 1.5 0 0024 8z\"/><path d=\"M17.6 5.355a.752.752 0 00.794 0l5.568-3.792A.075.075 0 0024 1.5 1.5 1.5 0 0022.5 0h-9A1.5 1.5 0 0012 1.5a.073.073 0 00.035.063z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});