define("ember-svg-jar/inlined/single-man.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man</title><path d=\"M12 9.5A5.506 5.506 0 006.5 15v2.75a.5.5 0 00.5.5h1.811a.249.249 0 01.249.228l.44 5.064A.5.5 0 0010 24h4a.5.5 0 00.5-.458l.442-5.064a.249.249 0 01.249-.228H17a.5.5 0 00.5-.5V15A5.506 5.506 0 0012 9.5zm.75 5.948a.75.75 0 01-1.5 0V11.5a.75.75 0 011.5 0zM12 8.5a4.25 4.25 0 10-4.25-4.25A4.255 4.255 0 0012 8.5zm0-2a2.252 2.252 0 01-2.25-2.25 2.157 2.157 0 01.05-.444.5.5 0 01.728-.334 5.377 5.377 0 002.575.653 5.413 5.413 0 00.573-.03.5.5 0 01.549.566A2.322 2.322 0 0112 6.5z\"/><path d=\"M12 9.5A5.506 5.506 0 006.5 15v2.75a.5.5 0 00.5.5h1.811a.249.249 0 01.249.228l.44 5.064A.5.5 0 0010 24h4a.5.5 0 00.5-.458l.442-5.064a.249.249 0 01.249-.228H17a.5.5 0 00.5-.5V15A5.506 5.506 0 0012 9.5zm.75 5.948a.75.75 0 01-1.5 0V11.5a.75.75 0 011.5 0zM12 8.5a4.25 4.25 0 10-4.25-4.25A4.255 4.255 0 0012 8.5zm0-2a2.252 2.252 0 01-2.25-2.25 2.157 2.157 0 01.05-.444.5.5 0 01.728-.334 5.377 5.377 0 002.575.653 5.413 5.413 0 00.573-.03.5.5 0 01.549.566A2.322 2.322 0 0112 6.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});