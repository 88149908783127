define("ember-svg-jar/inlined/smart-light-smartphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-light-smartphone</title><path d=\"M22 0h-7.5a2 2 0 00-2 2v.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5.5.5 0 01.5-.5h6.5a.5.5 0 01.5.5V16a.5.5 0 01-.5.5H15a.5.5 0 01-.5-.5v-1.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5V18a2 2 0 002 2H22a2 2 0 002-2V2a2 2 0 00-2-2zM5.75 9A5.757 5.757 0 000 14.75a5.57 5.57 0 001.56 4.01.751.751 0 00.54.24h2.4a.5.5 0 00.5-.5v-2.466a.25.25 0 00-.111-.208L3.08 14.62a.749.749 0 11.84-1.24l1.691 1.128a.251.251 0 00.278 0L7.58 13.38a.749.749 0 01.84 1.24l-1.809 1.206a.25.25 0 00-.111.208V18.5a.5.5 0 00.5.5h2.4a.751.751 0 00.54-.24 5.57 5.57 0 001.56-4.01A5.757 5.757 0 005.75 9zM7.75 20h-4a.5.5 0 00-.5.5 2.492 2.492 0 001.591 2.316.251.251 0 01.159.233v.2a.75.75 0 001.5 0v-.2a.251.251 0 01.159-.233A2.493 2.493 0 008.25 20.5a.5.5 0 00-.5-.5z\"/><path d=\"M13.265 12.289a.751.751 0 00.381-.99A8.279 8.279 0 009.45 7.1a.75.75 0 00-.608 1.371 6.786 6.786 0 013.433 3.433.749.749 0 00.99.381zM9.4 5.469a9.771 9.771 0 015.887 5.9.751.751 0 00.707.5.761.761 0 00.251-.043.749.749 0 00.455-.958 11.272 11.272 0 00-6.794-6.81.75.75 0 00-.506 1.411z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});