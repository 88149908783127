define("ember-svg-jar/inlined/programming-hold-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-hold-code</title><path d=\"M23.609 6.787a1.249 1.249 0 000-1.767l-4-4a1.25 1.25 0 10-1.768 1.767L20.6 5.55a.5.5 0 010 .707L17.841 9.02a1.25 1.25 0 001.768 1.767zM10.609 1.02a1.249 1.249 0 00-1.768 0l-4 4a1.249 1.249 0 000 1.767l4 4a1.25 1.25 0 101.768-1.767L7.846 6.257a.5.5 0 010-.707l2.763-2.763a1.249 1.249 0 000-1.767zM12.954 11.624a1.264 1.264 0 00.272.029 1.252 1.252 0 001.22-.978l2-9A1.251 1.251 0 0014 1.132l-2 9a1.252 1.252 0 00.954 1.492zM4.969 15.9a4 4 0 00-1.789.423L.163 17.834a.25.25 0 00-.138.224v5.394a.25.25 0 00.382.212l3.418-2.136a.5.5 0 01.424-.05c10.252 3.436 6.773 3.495 18.1-2.236a.514.514 0 00.084-.86 2.48 2.48 0 00-2.411-.479L16.6 19.039a.5.5 0 00-.29.252 1.993 1.993 0 01-1.785 1.109h-4a.5.5 0 110-1h4a1 1 0 000-2H11.2a.5.5 0 01-.3-.1 6.97 6.97 0 00-3.872-1.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});