define("ember-svg-jar/inlined/medical-instrument-scalpel-cut", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-instrument-scalpel-cut</title><path d=\"M3.5 21.249H1a1 1 0 000 2h2.5a1 1 0 000-2zM10 21.249H7.5a1 1 0 000 2H10a1 1 0 000-2zM16.5 21.249H14a1 1 0 100 2h2.5a1 1 0 000-2zM23 21.249h-2.5a1 1 0 000 2H23a1 1 0 000-2zM14.738 13.934a.5.5 0 00-.086-.416l-.861-1.148a.5.5 0 01.075-.68l1.9-1.627a.251.251 0 00-.105-.434L11.218 8.58a.5.5 0 00-.415.087L.2 16.622a.5.5 0 00-.1.7 4.433 4.433 0 003.549 1.763 4.525 4.525 0 001.619-.3l9.2-4.517a.5.5 0 00.27-.334zM19.11 6.879l-4.277-1.011a.5.5 0 00-.415.087l-1.439 1.079a.25.25 0 00-.095.251.252.252 0 00.188.193l4.277 1.01a.5.5 0 00.415-.087L19.2 7.322a.249.249 0 00-.093-.443zM16.686 4.766l4.278 1.01a.5.5 0 00.415-.087l1.829-1.372a1.985 1.985 0 00.4-2.775 1.988 1.988 0 00-2.776-.395l-4.238 3.176a.249.249 0 00-.1.251.253.253 0 00.192.192z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});