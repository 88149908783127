define("ember-svg-jar/inlined/programming-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-code</title><path d=\"M0 20.5a2.006 2.006 0 002 2h20a2.006 2.006 0 002-2v-17a2.006 2.006 0 00-2-2H2a2 2 0 00-2 2zM2.25 5A1.252 1.252 0 013.5 3.75H21A1.252 1.252 0 0122.25 5v14.5A1.251 1.251 0 0121 20.75H3.5a1.251 1.251 0 01-1.25-1.25z\"/><path d=\"M20.75 5.75a.5.5 0 00-.5-.5h-16a.5.5 0 00-.5.5v13a.5.5 0 00.5.5h16a.5.5 0 00.5-.5zm-10.043 5.043a1 1 0 010 1.414l-2.5 2.5a1 1 0 11-1.414-1.414l1.616-1.616a.25.25 0 000-.354L6.793 9.707a1 1 0 111.414-1.414zM17.5 13.5a1 1 0 01-1 1h-4a1 1 0 010-2h4a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});