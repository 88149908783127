define("ember-svg-jar/inlined/hat-lady", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hat-lady</title><path d=\"M23.672 15.954a.5.5 0 01-.015-.491 3.022 3.022 0 00.23-2.21l-1.081-3.819A3 3 0 0020.861 7.4a2.962 2.962 0 00-.888-.152.5.5 0 01-.42-.242 8.8 8.8 0 00-16.332 4.159.5.5 0 00.312.49 22.47 22.47 0 007.348 1.529.5.5 0 01.459.363l.5 1.766a.5.5 0 01-.491.636 25.1 25.1 0 01-8.864-1.8.5.5 0 00-.542.107L.669 15.517a2.253 2.253 0 00-.6 2.168C.651 19.888 6.714 21.25 12 21.25s11.349-1.362 11.926-3.561a2.246 2.246 0 00-.254-1.735zm-1.71-2.154a1 1 0 01-1 1.272 6.321 6.321 0 01-2.374-.63 1 1 0 00-1.251.355 6.33 6.33 0 01-1.694 1.783 1 1 0 01-1.521-.557L13.038 12.2a1 1 0 011.062-1.266 5.894 5.894 0 012.259.652 1 1 0 001.351-.375 6.074 6.074 0 011.6-1.754 1 1 0 011.571.521z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});