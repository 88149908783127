define("ember-svg-jar/inlined/quill-write-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>quill-write-1-alternate</title><path d=\"M4.914 17.67a1 1 0 000 1.414 1.013 1.013 0 001.415 0l4.14-4.14a14.576 14.576 0 009.4-4.1A14.7 14.7 0 0024 1.038a1 1 0 00-.292-.744.981.981 0 00-.746-.294 15.214 15.214 0 00-6.689 1.823.5.5 0 00-.256.505l.35 2.63-2.044-1.37a.5.5 0 00-.611.043 13.4 13.4 0 00-2.8 3.482 14.9 14.9 0 00-1.565 4.238 15.666 15.666 0 00-.291 2.179z\"/><path d=\"M21 13.5a1 1 0 00-1 1V21a1 1 0 01-1 1H3a1 1 0 01-1-1V5a1 1 0 011-1h6a1 1 0 000-2H3a3 3 0 00-3 3v16a3 3 0 003 3h16a3 3 0 003-3v-6.5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});