define("ember-svg-jar/inlined/pin-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin-2</title><path d=\"M17.5.006a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-.229 3.333a2.942 2.942 0 00-2.938 2.939.5.5 0 11-1 0 3.943 3.943 0 013.938-3.939.5.5 0 010 1zM9.939 11.945l-9.5 9.5a1.5 1.5 0 000 2.122 1.535 1.535 0 002.122 0l9.5-9.5a1.5 1.5 0 00-2.122-2.122z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});