define("ember-svg-jar/inlined/primitive-symbols-man-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>primitive-symbols-man-1</title><path d=\"M15 6.5H9a3 3 0 00-3 3v5a1 1 0 002 0v-5a.936.936 0 01.147-.5c.069-.109.279-.127.307.126L9 14.025v8.728a1 1 0 002 0v-5.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v5.5a1 1 0 002 0v-8.719s.55-4.776.55-4.954a.165.165 0 01.3-.093.935.935 0 01.15.513v5a1 1 0 002 0v-5a3 3 0 00-3-3z\"/><circle cx=\"12\" cy=\"2.75\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});