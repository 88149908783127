define("ember-svg-jar/inlined/shipment-fragile-break", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-fragile-break</title><path d=\"M19 8.079v-.033L18.055.87a1 1 0 00-.992-.87h-4.732a.645.645 0 00-.456.189l-.988.988a.249.249 0 000 .353l.939.94a1.249 1.249 0 010 1.767L10.18 5.884a.75.75 0 01-1.061-1.061l1.293-1.293a.249.249 0 000-.353l-.939-.94a1.251 1.251 0 01.043-1.81A.25.25 0 009.339 0h-2.4a1 1 0 00-.992.868S5 7.978 5 8a7 7 0 005.789 6.886.253.253 0 01.211.247v6.617a.25.25 0 01-.25.25H9a1 1 0 000 2h6a1 1 0 000-2h-1.75a.25.25 0 01-.25-.25v-6.617a.252.252 0 01.211-.247A6.887 6.887 0 0019 8.079z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});