define("ember-svg-jar/inlined/church", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>church</title><path d=\"M13.334 8.369A.5.5 0 0113 7.9V5.375a.5.5 0 01.5-.5h.5a1 1 0 000-2h-.5a.5.5 0 01-.5-.5v-.5a1 1 0 00-2 0v.5a.5.5 0 01-.5.5H10a1 1 0 000 2h.5a.5.5 0 01.5.5V7.9a.5.5 0 01-.333.471A3.992 3.992 0 008 12.125v10.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-4.5a2 2 0 014 0v4.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-10.5a3.993 3.993 0 00-2.666-3.756zM12 14.125a2 2 0 112-2 2 2 0 01-2 2zM23 21.125h-.5a.5.5 0 01-.5-.5v-3.643a.307.307 0 01.474-.257 1 1 0 001.057-1.7h-.008l-5.751-3.737A.5.5 0 0017 11.7v10.425a1 1 0 001 1h5a1 1 0 000-2zM6.74 11.265a.5.5 0 00-.511.02L.478 15.022H.47a1 1 0 001.057 1.7.307.307 0 01.473.26v3.643a.5.5 0 01-.5.5H1a1 1 0 000 2h5a1 1 0 001-1V11.7a.5.5 0 00-.26-.435z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});