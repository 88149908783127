define("ember-svg-jar/inlined/navigation-right-circle-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-right-circle-2-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M19.6 10.9l-4-4a.5.5 0 00-.854.354v1.5A.249.249 0 0114.5 9H8.746a3.99 3.99 0 00-.406 7.961.5.5 0 00.151-.987 1.25 1.25 0 01.255-2.474H14.5a.249.249 0 01.25.25v1.5a.5.5 0 00.854.353l4-4a.5.5 0 00-.004-.703z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});