define("ember-svg-jar/inlined/flying-insect-ladybug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flying-insect-ladybug</title><path d=\"M22.816 15.551l-.894-.3A10.173 10.173 0 0022 14a10.061 10.061 0 00-.15-1.729l.966-.322a1 1 0 10-.632-1.9l-.876.292A10.007 10.007 0 0019.676 7.6 2.133 2.133 0 0121.5 6.5a1 1 0 000-2 4.022 4.022 0 00-3.266 1.688A9.955 9.955 0 0016.5 5.071a4.48 4.48 0 00-.7-2.471 9.643 9.643 0 00.6-1.153 1 1 0 00-1.79-.895l-.293.588a4.49 4.49 0 00-4.624 0L9.4.552a1 1 0 00-1.79.9A9.609 9.609 0 008.2 2.6a4.48 4.48 0 00-.7 2.475 9.955 9.955 0 00-1.734 1.112A4.022 4.022 0 002.5 4.5a1 1 0 000 2 2.147 2.147 0 011.824 1.1 10.007 10.007 0 00-1.632 2.745l-.876-.292a1 1 0 10-.632 1.9l.966.322A10.061 10.061 0 002 14a10.173 10.173 0 00.078 1.254l-.894.3A1 1 0 001.5 17.5a3.334 3.334 0 001.029-.289 10 10 0 0018.942 0 3.34 3.34 0 001.029.289 1 1 0 00.316-1.948zm-6.542 5.208A7.938 7.938 0 0113 21.937V17.1a3.26 3.26 0 000-6.2V6.062a7.927 7.927 0 013.28 1.182 3 3 0 003.3 4.2 8.009 8.009 0 010 5.113 3.008 3.008 0 00-3.306 4.202zm-8.55 0a3 3 0 00-3.305-4.2 8.009 8.009 0 010-5.113 3 3 0 003.3-4.2A7.939 7.939 0 0111 6.062V10.9a3.26 3.26 0 000 6.2v4.835a7.948 7.948 0 01-3.276-1.177z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});