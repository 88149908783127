define("ember-svg-jar/inlined/messages-bubble-add-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-add-alternate</title><path d=\"M9.314 17.509q-.359-.08-.713-.181a1 1 0 00-.661.038l-3.648 1.52a.25.25 0 01-.331-.316l.851-2.339a1 1 0 00-.276-1.089A7.043 7.043 0 012 9.9C2 5.546 6.486 2 12 2s10 3.546 10 7.9a6.1 6.1 0 01-.048.743.249.249 0 00.1.236 7.985 7.985 0 011.268 1.1.251.251 0 00.245.07.249.249 0 00.18-.181A8.063 8.063 0 0024 9.9C24 4.443 18.617 0 12 0S0 4.443 0 9.9a8.877 8.877 0 002.708 6.26l-1.6 4.4a1 1 0 001.325 1.265l5.959-2.483c.343.09.688.163 1.035.224a.25.25 0 00.288-.3 7.869 7.869 0 01-.205-1.522.25.25 0 00-.196-.235z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.5 7.25h-1.5a.25.25 0 00-.25.25V20a.75.75 0 01-1.5 0v-1.5a.25.25 0 00-.25-.25H15a.75.75 0 010-1.5h1.5a.25.25 0 00.25-.25V15a.75.75 0 011.5 0v1.5a.25.25 0 00.25.25H20a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});