define("ember-svg-jar/inlined/image-file-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-settings</title><path d=\"M13.187 7.03A1.493 1.493 0 1011.7 5.538a1.493 1.493 0 001.487 1.492z\"/><path d=\"M23.414 3L21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v6.294a.245.245 0 00.214.247 7.858 7.858 0 011.45.318A.248.248 0 008 8.626V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-7.359a.25.25 0 00-.238.315 3.245 3.245 0 01.023 1.377.248.248 0 00.239.307H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M20.477 12.9a.186.186 0 00-.006-.189l-3.236-5.175a.373.373 0 00-.633 0l-1.81 2.9a.187.187 0 01-.317 0L13.831 9.4a.375.375 0 00-.633 0l-1.069 1.71a.253.253 0 00.1.356 3.224 3.224 0 011.4 1.373.245.245 0 00.227.161h6.459a.185.185 0 00.162-.1z\"/><circle cx=\"6.252\" cy=\"17.031\" r=\"1.5\"/><path d=\"M12.02 15.654a1.782 1.782 0 00-1.7-2.953l-1.411.326a.453.453 0 01-.533-.31l-.424-1.393a1.775 1.775 0 00-3.4 0l-.424 1.393a.456.456 0 01-.533.31l-1.41-.327a1.782 1.782 0 00-1.7 2.952l.988 1.066a.463.463 0 010 .625l-.989 1.065a1.782 1.782 0 001.7 2.953l1.416-.326a.453.453 0 01.533.311l.424 1.391a1.774 1.774 0 003.4 0l.424-1.392a.453.453 0 01.533-.311l1.411.326a1.782 1.782 0 001.7-2.952l-.988-1.065a.463.463 0 010-.625zm-5.768 4.377a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});