define("ember-svg-jar/inlined/old-people-man-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>old-people-man-3</title><path d=\"M21 18.369a.25.25 0 00-.125-.216A18.551 18.551 0 0011.5 16C5.661 16 2.394 17.828.967 18.917A2.462 2.462 0 000 20.894V23.5a.5.5 0 00.5.5h8.648a.251.251 0 00.241-.181l1.63-5.706a.52.52 0 01.962 0l1.63 5.706a.251.251 0 00.241.181h6.9a.25.25 0 00.25-.25z\"/><path d=\"M18.427 9.982a.029.029 0 01.021-.025l.024-.01A1 1 0 0019 9.065V4.882a1.5 1.5 0 00-1.314-1.488l-.086-.011a.25.25 0 01-.2-.142c0-.009-.009-.018-.014-.027a7 7 0 00-11.778 0 .215.215 0 00-.014.026.25.25 0 01-.2.142l-.087.011A1.5 1.5 0 004 4.882v4.183a1 1 0 00.528.882.093.093 0 01.05.07 7 7 0 0013.849-.035zM11.5 14a5 5 0 01-4.993-4.727.021.021 0 01.008-.016l.259-.173A3.486 3.486 0 008.25 6.931l.431-1.938a1.507 1.507 0 00-.355-1.335q-.039-.042-.081-.081a.25.25 0 01.007-.377 4.983 4.983 0 016.5 0 .249.249 0 01.087.185.252.252 0 01-.08.188q-.042.039-.081.081a1.507 1.507 0 00-.355 1.335l.431 1.938A3.486 3.486 0 0016.23 9.08l.258.173a.016.016 0 01.008.016A5 5 0 0111.5 14z\"/><path d=\"M10.5 4.5h2a.5.5 0 000-1h-2a.5.5 0 000 1zM10.5 6.5h2a.5.5 0 000-1h-2a.5.5 0 000 1z\"/><circle cx=\"13.5\" cy=\"9.02\" r=\"1\"/><circle cx=\"9.5\" cy=\"9.02\" r=\"1\"/><path d=\"M12.577 11.132h-2a.639.639 0 00-.3 1.2 2.3 2.3 0 00.437.181 3.267 3.267 0 00.873.126 3 3 0 00.863-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2zM22 20.894V23a1 1 0 002 0v-8.25a3.25 3.25 0 00-6.5 0v.75a1 1 0 002 0v-.75a1.25 1.25 0 012.5 0v6.144z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});