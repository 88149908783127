define("ember-svg-jar/inlined/navigation-right-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-right-1</title><path d=\"M23.6 11.22l-7.991-9.987a1.232 1.232 0 00-1.383-.416A1.245 1.245 0 0013.373 2v1.5a.25.25 0 01-.25.25H1.373A1.251 1.251 0 00.123 5v8.752a.25.25 0 00.25.25h4a2.5 2.5 0 012.5 2.5V20a.25.25 0 00.25.25h6a.25.25 0 01.25.25V22a1.259 1.259 0 001.257 1.248 1.227 1.227 0 00.972-.475l7.986-9.982a1.229 1.229 0 00.012-1.571z\"/><path d=\"M5.876 16.5a1.5 1.5 0 00-1.5-1.5H.973a.249.249 0 00-.168.434l4.649 4.238a.25.25 0 00.418-.185z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});