define("ember-svg-jar/inlined/calendar-search-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>calendar-search-1</title><path d=\"M9.5 16.5h-7A.5.5 0 012 16V7.5a.5.5 0 01.5-.5H16a.5.5 0 01.5.5v2c.01 0 1.466 0 2 .068V3a1 1 0 00-1-1H15a.25.25 0 01-.25-.25v-1a.75.75 0 10-1.5 0V4.5a.75.75 0 11-1.5 0v-2a.5.5 0 00-.5-.5H7a.25.25 0 01-.25-.25v-1a.75.75 0 00-1.5 0V4.5a.75.75 0 11-1.5 0v-2a.5.5 0 00-.5-.5H1a1 1 0 00-1 1v13.5a2 2 0 002 2h7.569c-.069-.529-.069-1.989-.069-2z\"/><path d=\"M21.078 19.665a5.54 5.54 0 10-1.414 1.414l2.629 2.629a1 1 0 001.414-1.414zM13 16.53a3.529 3.529 0 113.529 3.529A3.533 3.533 0 0113 16.53z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});