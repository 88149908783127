define("ember-svg-jar/inlined/single-man-podium.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-podium</title><path d=\"M5 13.625a.5.5 0 00.5.5h13a.5.5 0 00.5-.5 6.216 6.216 0 00-.618-2.517c-.454-.907-1.9-1.44-3.912-2.178l-.394-.146a.25.25 0 01-.163-.235v-.833a.248.248 0 01.073-.176 2.626 2.626 0 00.753-1.512.251.251 0 01.052-.123 1.384 1.384 0 00.246-.544 1.592 1.592 0 00-.131-1.146.25.25 0 01-.018-.215 4.54 4.54 0 00.428-2.372C15.1.564 13.736.155 12.526.127A3.276 3.276 0 0010.031.9a.247.247 0 01-.137.086 1.252 1.252 0 00-.734.47 3.057 3.057 0 00-.018 2.522.253.253 0 01-.029.206 1.58 1.58 0 00-.15 1.177 1.384 1.384 0 00.246.544.245.245 0 01.052.123 2.626 2.626 0 00.753 1.512.248.248 0 01.073.176v.833a.249.249 0 01-.164.235l-.395.146c-2.007.738-3.457 1.271-3.91 2.178A6.216 6.216 0 005 13.625zM23.75 17.125a.75.75 0 00-.75-.75H1a.75.75 0 000 1.5h1.219a.25.25 0 01.243.189l1.31 5.243a.751.751 0 00.728.568h15a.751.751 0 00.728-.568l1.31-5.243a.25.25 0 01.243-.189H23a.75.75 0 00.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});