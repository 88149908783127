define("ember-svg-jar/inlined/folder-file-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-file-star</title><path d=\"M13 15H6a1 1 0 01-.8-.4l-.9-1.2a1 1 0 00-.8-.4H1a1 1 0 00-1 1v9a1 1 0 001 1h12a1 1 0 001-1v-7a1 1 0 00-1-1zM16.625 10.356l2.246-1.263a.254.254 0 01.245 0l2.247 1.263a1.082 1.082 0 001.275-.141 1.063 1.063 0 00.268-1.231l-.838-1.923a.25.25 0 01.086-.3l1.514-1.064a1.069 1.069 0 00.255-1.168 1.049 1.049 0 00-.994-.65H21.2a.242.242 0 01-.232-.156c-.222-.585-.99-2.61-1-2.623a1.127 1.127 0 00-1.951 0c-.007.013-.774 2.038-1 2.623a.243.243 0 01-.232.156h-1.727a1.049 1.049 0 00-.994.65 1.069 1.069 0 00.255 1.168l1.514 1.064a.248.248 0 01.086.3l-.837 1.923a1.063 1.063 0 00.267 1.231 1.084 1.084 0 001.276.141zM23.611 11.245a5.335 5.335 0 01-1.412.714.245.245 0 00-.2.232V19.5a.5.5 0 01-.5.5H15.75a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25H22a2 2 0 002-2v-8.548a.249.249 0 00-.389-.207z\"/><path d=\"M6 13.166c0 .148.1.334.25.334h1.5a.25.25 0 00.25-.25V2.5a.5.5 0 01.5-.5h7.418a.245.245 0 00.228-.146c.134-.313.454-1.052.661-1.5A.25.25 0 0016.58 0H8a2 2 0 00-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});