define("ember-svg-jar/inlined/business-trip-plane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-trip-plane</title><path d=\"M5.724 9.956A.249.249 0 006 9.707v-.124A1.044 1.044 0 017 8.5h4.5a2.871 2.871 0 012.445 1.411.246.246 0 00.191.089H17.5A2.065 2.065 0 0019 9l.49-.872A.251.251 0 0119.708 8H21a1 1 0 000-2 .183.183 0 01-.16-.272l2.45-4.355a.249.249 0 000-.249.252.252 0 00-.218-.124H21.5a1.771 1.771 0 00-1.5.5l-2.929 2.44a.251.251 0 01-.16.058H7C3.615 4 .451 6.492.5 7.462c.061 1.161 3.031 2.265 5.224 2.494zM5 5.385h1.5a.75.75 0 010 1.5H5a.75.75 0 010-1.5z\"/><path d=\"M7.25 9.5a.249.249 0 00-.25.25v1.921a.25.25 0 00.245.25L11.5 12h.012c.534 0 .76 0 1.789-1.193a.086.086 0 000-.112c-.814-.966-1.036-1.2-1.8-1.2zM23.5 22v-2.25a.25.25 0 00-.25-.25H18.5a.25.25 0 00-.25.25v.75a.75.75 0 01-1.5 0v-.75a.25.25 0 00-.25-.25h-4.75a.25.25 0 00-.25.25V22a1 1 0 001 1h10a1 1 0 001-1zM23.5 16a1 1 0 00-1-1h-1.228a.249.249 0 01-.223-.138l-.655-1.309A2.5 2.5 0 0018 12h-1a2.5 2.5 0 00-2.395 1.553l-.654 1.309a.25.25 0 01-.224.138H12.5a1 1 0 00-1 1v2.25a.249.249 0 00.25.25h11.5a.249.249 0 00.25-.25zm-4.81-1.118a.252.252 0 01-.213.118h-1.955a.249.249 0 01-.223-.361l.095-.192C16.618 14 16.729 14 17 14h1c.27 0 .381 0 .605.447l.1.192a.253.253 0 01-.015.243z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});