define("ember-svg-jar/inlined/single-neutral-actions-image.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-image</title><path d=\"M9 11a1.994 1.994 0 011.519-1.934.251.251 0 00.188-.212 1.284 1.284 0 01.083-.366 5.618 5.618 0 001.466-4.314C12.256 1.756 10.677 0 8.5 0S4.744 1.756 4.744 4.174A5.605 5.605 0 006.2 8.471a2.505 2.505 0 01.073 1.317.614.614 0 01-.135.308l-.632.233c-2.446.9-4.214 1.549-4.753 2.626A9.167 9.167 0 000 16.5a.5.5 0 00.5.5h8.25a.25.25 0 00.25-.25zM22 10.5h-9.5a2 2 0 00-2 2V22a2 2 0 002 2H22a2 2 0 002-2v-9.5a2 2 0 00-2-2zm-9.5 2.25a.25.25 0 01.25-.25h9a.25.25 0 01.25.25v4.4a.25.25 0 01-.458.138l-.267-.4a1.247 1.247 0 00-1.026-.556 1.216 1.216 0 00-1.038.533l-1.537 2.2a.248.248 0 01-.169.1.251.251 0 01-.192-.052l-.612-.489a1.249 1.249 0 00-1.821.282l-2 3.005a.206.206 0 01-.377-.114z\"/><circle cx=\"15.5\" cy=\"15\" r=\"1.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});