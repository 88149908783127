define("ember-svg-jar/inlined/file-c-plus-plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-c-plus-plus</title><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.428A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.251A.25.25 0 0014.75 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.251A.25.25 0 0021.75 7zM7.5 18.376a.625.625 0 010 1.25 3.625 3.625 0 110-7.25.625.625 0 010 1.25 2.375 2.375 0 100 4.75zm6-1.75h-1.125a.25.25 0 00-.25.25V18a.625.625 0 01-1.25 0v-1.124a.25.25 0 00-.25-.25H9.5a.625.625 0 110-1.25h1.125a.25.25 0 00.25-.25V14a.625.625 0 111.25 0v1.125a.25.25 0 00.25.25H13.5a.625.625 0 110 1.25zm6 0h-1.125a.25.25 0 00-.25.25V18a.625.625 0 11-1.25 0v-1.124a.25.25 0 00-.25-.25H15.5a.625.625 0 010-1.25h1.125a.25.25 0 00.25-.25V14a.625.625 0 111.25 0v1.125a.25.25 0 00.25.25H19.5a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});