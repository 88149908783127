define("ember-svg-jar/inlined/picture-flower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>picture-flower</title><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2zm-2 18.5a1.5 1.5 0 01-1.5 1.5h-17A1.5 1.5 0 012 20.5v-2.25a.25.25 0 01.25-.25h19.5a.25.25 0 01.25.25zM5.705 8.155a3.622 3.622 0 012.634-.566c.118 0 .233.013.346.024a.249.249 0 00.226-.4A3.272 3.272 0 018.7 6.9c-.652-1.14-1.515-2.651-.078-3.48a1.5 1.5 0 011.186-.159c.827.239 1.366 1.157 1.806 2 .055.105.106.209.152.312a.25.25 0 00.457 0 3.4 3.4 0 01.173-.34c.664-1.137 1.538-2.637 2.976-1.808a1.486 1.486 0 01.73.947 3.617 3.617 0 01-.826 2.564c-.063.1-.128.2-.194.287a.25.25 0 00.231.394 3.344 3.344 0 01.364-.02h.023c1.316.007 3.053.016 3.053 1.674a1.49 1.49 0 01-.455 1.105 2.949 2.949 0 01-2.068.58c-.191 0-.38-.006-.566-.013-.118 0-.233-.014-.346-.025a.25.25 0 00-.226.4 3.256 3.256 0 01.208.32c.652 1.142 1.515 2.651.078 3.481a1.549 1.549 0 01-.778.218 1.468 1.468 0 01-.408-.058c-.827-.241-1.366-1.158-1.806-2a5.565 5.565 0 01-.152-.312.25.25 0 00-.457 0 3.4 3.4 0 01-.173.34c-.531.908-1.2 2.048-2.174 2.048a1.6 1.6 0 01-.8-.24 1.482 1.482 0 01-.73-.947 3.617 3.617 0 01.821-2.568c.063-.1.128-.2.194-.287a.249.249 0 00.013-.272.252.252 0 00-.243-.123 3.342 3.342 0 01-.364.021H8.3c-1.312-.011-3.05-.019-3.05-1.677a1.493 1.493 0 01.455-1.107z\"/><path d=\"M9.4 9.262a2.6 2.6 0 102.6-2.6 2.6 2.6 0 00-2.6 2.6zm2.6-1.1a1.1 1.1 0 11-1.1 1.1A1.1 1.1 0 0112 8.165z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});