define("ember-svg-jar/inlined/earth-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earth-lock</title><path d=\"M12.249 22.5v-.25A.249.249 0 0012 22a9.9 9.9 0 01-4.212-.942.5.5 0 01-.288-.453v-.992a2.492 2.492 0 01.893-1.913A4.5 4.5 0 005.5 9.75H2.915a.5.5 0 01-.478-.645A9.984 9.984 0 0115.889 2.79a.5.5 0 01-.195.96H14.25a2.75 2.75 0 000 5.5 2.564 2.564 0 011.826.781.5.5 0 00.6.084A4.683 4.683 0 0119 9.5a4.756 4.756 0 014.74 4.426.122.122 0 00.07.1 11.993 11.993 0 10-16.482 9.027l.027.012.127.063a.011.011 0 00.012 0 .011.011 0 00.006-.01A11.927 11.927 0 0012 24a.421.421 0 00.4-.551 3.072 3.072 0 01-.151-.949z\"/><path d=\"M22.874 15.547a.5.5 0 01-.375-.484v-.813a3.5 3.5 0 00-7 0v.813a.5.5 0 01-.375.484A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.126-1.453zM19 21.25a1 1 0 111-1 1 1 0 01-1 1zM20.5 15a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-.75a1.5 1.5 0 113 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});