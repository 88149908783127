define("ember-svg-jar/inlined/medical-hospital", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-hospital</title><path d=\"M23.39 16.268a1 1 0 00-.365-1.367l-4.651-2.685a.25.25 0 01-.125-.216.252.252 0 01.125-.217L23.025 9.1a1 1 0 00.366-1.366l-2-3.465a.986.986 0 00-.607-.465 1 1 0 00-.758.1l-4.652 2.682A.25.25 0 0115 6.37V1a1 1 0 00-1-1h-4a1 1 0 00-1 1v5.369a.25.25 0 01-.375.216L3.976 3.9a1.006 1.006 0 00-.759-.1.989.989 0 00-.608.466l-2 3.464A1 1 0 00.975 9.1l4.65 2.686A.252.252 0 015.75 12a.25.25 0 01-.125.216L.976 14.9a1 1 0 00-.367 1.367l2 3.463a.991.991 0 00.608.467 1.008 1.008 0 00.758-.1l4.649-2.685a.25.25 0 01.376.216V23a1 1 0 001 1h4a1 1 0 001-1v-5.372a.25.25 0 01.375-.216l4.65 2.685a1 1 0 00.759.1.989.989 0 00.608-.466z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});