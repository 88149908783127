define("ember-svg-jar/inlined/app-window-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-graph</title><path d=\"M24 4.751a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h18a3 3 0 003-3zm-14.346-1a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.562.091a1 1 0 01.908-.591.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.987.987 0 01.092-.409zM2 7a.25.25 0 01.25-.25h19.5A.25.25 0 0122 7v5.255a.5.5 0 01-.5.5h-4.95a.748.748 0 00-.636.353l-1.626 2.6a.251.251 0 01-.428-.006L9.7 8.627a.712.712 0 00-.655-.371.749.749 0 00-.647.386L6.251 12.5a.5.5 0 01-.437.257H2.5a.5.5 0 01-.5-.5zm20 12.25a1 1 0 01-1 1H3a1 1 0 01-1-1v-4.5a.5.5 0 01.5-.5h4.05a.749.749 0 00.655-.385L8.856 10.9a.25.25 0 01.434 0l4.11 6.986a.747.747 0 00.637.37h.01a.744.744 0 00.635-.352l2.134-3.413a.5.5 0 01.424-.235h4.26a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});