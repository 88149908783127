define("ember-svg-jar/inlined/single-woman-actions-chat.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-chat</title><path d=\"M9.508 12.25a.5.5 0 00-.368-.487 7.331 7.331 0 00-1.934-.263 7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643H9a.5.5 0 00.5-.5c0-1.106 0-3.739.008-4.75zM24 12a2 2 0 00-2-2h-9a2 2 0 00-2 2v7a2 2 0 002 2h1v2a1 1 0 001.625.781L19.1 21H22a2 2 0 002-2zm-5.875 7.219L16 20.92V20a1 1 0 00-1-1h-1.75a.25.25 0 01-.25-.25v-6.5a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v6.5a.249.249 0 01-.25.25h-3a1 1 0 00-.625.219z\"/><path d=\"M15 15h5a.75.75 0 000-1.5h-5a.75.75 0 000 1.5zM18.75 16.75A.75.75 0 0018 16h-3a.75.75 0 000 1.5h3a.75.75 0 00.75-.75zM10.6 9.163a.253.253 0 01.228-.071.43.43 0 00.313-.057 3.484 3.484 0 011.4-.5.25.25 0 00.205-.326 5.313 5.313 0 01-.24-1.656V5.3a5.3 5.3 0 10-10.594 0v1.248a4.564 4.564 0 01-.643 2.512 1 1 0 001.681 1.085 5.2 5.2 0 00.454-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0zm-.649-2.917A2.708 2.708 0 017.209 8.6a2.7 2.7 0 01-2.741-2.35.5.5 0 01.324-.578 5.723 5.723 0 002.245-1.44.25.25 0 01.361 0 5.7 5.7 0 002.23 1.437.5.5 0 01.323.577z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});