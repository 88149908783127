define("ember-svg-jar/inlined/multiple-actions-wifi.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-wifi</title><path d=\"M19.4 12.144a.5.5 0 00.535-.707A6.018 6.018 0 0014.489 8a5.958 5.958 0 00-2.466.534.5.5 0 00-.181.771 7.441 7.441 0 011.454 2.91.5.5 0 00.644.354 11.294 11.294 0 015.46-.425z\"/><ellipse cx=\"14.013\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M11.7 13.591a.5.5 0 00.237-.5A6.023 6.023 0 000 13.333.6.6 0 00.6 14h10.314a.5.5 0 00.279-.085q.251-.169.507-.324z\"/><ellipse cx=\"5.513\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><circle cx=\"17.5\" cy=\"22.25\" r=\"1.75\"/><path d=\"M11.379 15.627a1 1 0 101.242 1.567 7.826 7.826 0 019.758 0 1 1 0 101.242-1.567 9.817 9.817 0 00-12.242 0z\"/><path d=\"M13.853 18.451a1 1 0 001.293 1.526 3.586 3.586 0 014.707 0 1 1 0 101.293-1.526 5.624 5.624 0 00-7.293 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});