define("ember-svg-jar/inlined/delivery-truck-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>delivery-truck-4</title><path d=\"M.75 13.125h13.5a.75.75 0 00.75-.75V4.446a1.475 1.475 0 00-1.616-1.321H1.616A1.475 1.475 0 000 4.446v7.929a.75.75 0 00.75.75zM11 5.375v5.5a.75.75 0 01-1.5 0v-5.5a.75.75 0 011.5 0zm-6 0v5.5a.75.75 0 11-1.5 0v-5.5a.75.75 0 011.5 0z\"/><path d=\"M24 10.875a4 4 0 00-4-4h-3a1 1 0 00-1 1v6a.25.25 0 01-.25.25h-15a.75.75 0 00-.75.75v3a.75.75 0 00.75.75h1.015a.252.252 0 00.177-.074.255.255 0 00.073-.179 3.485 3.485 0 116.97 0 .255.255 0 00.073.179.252.252 0 00.177.074h3.53a.252.252 0 00.177-.074.255.255 0 00.073-.179 3.493 3.493 0 116.985 0 2.039 2.039 0 01-.013.223.251.251 0 00.249.277H22a2 2 0 002-2zm-2 0v.732a.251.251 0 01-.254.25l-2.5-.038a.25.25 0 01-.246-.25V9.125a.25.25 0 01.25-.25H20a2 2 0 012 2z\"/><circle cx=\"16.5\" cy=\"18.375\" r=\"2.5\"/><circle cx=\"5.5\" cy=\"18.375\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});