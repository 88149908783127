define("ember-svg-jar/inlined/open-quote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>open-quote</title><path d=\"M9.928 3.932A9.709 9.709 0 00.5 13.88v1.243a4.942 4.942 0 104.941-4.941 4.609 4.609 0 00-1.115.14.25.25 0 01-.277-.368 6.832 6.832 0 015.879-3.522 1.25 1.25 0 000-2.5zM22.25 6.432a1.25 1.25 0 000-2.5 9.71 9.71 0 00-9.428 9.948v1.243a4.942 4.942 0 104.942-4.941 4.611 4.611 0 00-1.116.14.249.249 0 01-.26-.092.252.252 0 01-.017-.276 6.832 6.832 0 015.879-3.522z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});