define("ember-svg-jar/inlined/performance-increase-mail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>performance-increase-mail</title><path d=\"M23.875 23v-7.9a.25.25 0 00-.394-.2l-7.818 5.518a.5.5 0 01-.575 0l-7.819-5.5a.25.25 0 00-.394.2V23a1 1 0 001 1h15a1 1 0 001-1z\"/><path d=\"M10.375 13a1 1 0 00-1 1v1.041a.251.251 0 00.106.2l2 1.406a.25.25 0 00.394-.2V14a1 1 0 00-1-1zM14.875 10.42a1.043 1.043 0 00-1 1.08v6.7a.251.251 0 00.106.2l1.249.878a.249.249 0 00.288 0l1.251-.883a.249.249 0 00.106-.2V11.5a1.043 1.043 0 00-1-1.08zM19.875 7.5a1 1 0 00-1 1v7.936a.25.25 0 00.394.2l2.5-1.765a.249.249 0 00.106-.2V8.5a1 1 0 00-1-1zM.418 15.207a1 1 0 001.414 0L11.2 5.841a.251.251 0 01.354 0l.909.91a2 2 0 002.828 0l3.263-3.263a.25.25 0 01.353 0l.866.867a.5.5 0 00.354.146.489.489 0 00.191-.038.5.5 0 00.307-.463V.5a.5.5 0 00-.5-.5h-3.5a.5.5 0 00-.354.854l.867.866a.25.25 0 010 .353l-3.086 3.086a.25.25 0 01-.354 0l-.909-.909a2 2 0 00-2.828 0L.418 13.793a1 1 0 000 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});