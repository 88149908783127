define("ember-svg-jar/inlined/building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>building</title><path d=\"M23 24a1 1 0 001-1 .5.5 0 00-.5-.5h-9a.5.5 0 010-1h9a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5h-9a.5.5 0 010-1h9a.5.5 0 00.5-.5V18a.5.5 0 00-.25-.433.5.5 0 01-.25-.434V1a1 1 0 00-1-1H16a.5.5 0 00-.5.5V17a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5V.5a.5.5 0 00-.5-.5h-12A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24zM19.5 2.5a2 2 0 11-2 2 2 2 0 012-2zm-2 12a2 2 0 014 0V17a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5zM5.5 19a.5.5 0 01-.5.5H2.5A.5.5 0 012 19v-3.25a1.75 1.75 0 013.5 0zm0-10a.5.5 0 01-.5.5H2.5A.5.5 0 012 9V5.75a1.75 1.75 0 013.5 0zM12 19a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5v-3.25a1.75 1.75 0 013.5 0zm0-10a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5V5.75a1.75 1.75 0 013.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});