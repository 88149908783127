define("ember-svg-jar/inlined/barcode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>barcode</title><path d=\"M5 7a1 1 0 00-1 1v7.5a1 1 0 002 0V8a1 1 0 00-1-1zM8.5 7a1 1 0 00-1 1v4a1 1 0 002 0V8a1 1 0 00-1-1zM12 7a1 1 0 00-1 1v4a1 1 0 002 0V8a1 1 0 00-1-1zM15.5 7a1 1 0 00-1 1v4a1 1 0 002 0V8a1 1 0 00-1-1zM8.5 14a1 1 0 00-1 1v.5a1 1 0 002 0V15a1 1 0 00-1-1zM12 14a1 1 0 00-1 1v.5a1 1 0 002 0V15a1 1 0 00-1-1zM15.5 14a1 1 0 00-1 1v.5a1 1 0 002 0V15a1 1 0 00-1-1zM19 7a1 1 0 00-1 1v7.5a1 1 0 002 0V8a1 1 0 00-1-1z\"/><path d=\"M21 3H3a3 3 0 00-3 3v12a3 3 0 003 3h18a3 3 0 003-3V6a3 3 0 00-3-3zm1 15a1 1 0 01-1 1H3a1 1 0 01-1-1V6a1 1 0 011-1h18a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});