define("ember-svg-jar/inlined/organic-flask", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>organic-flask</title><path d=\"M21.767 19.371L16.5 10.72V8a1 1 0 00-2 0v3a.994.994 0 00.146.52l.822 1.35a.25.25 0 01-.213.38h-2a.5.5 0 01-.5-.5v-7A5.757 5.757 0 007 0a.75.75 0 00-.75.75 5.757 5.757 0 004.14 5.517.5.5 0 01.36.48v6a.5.5 0 01-.5.5H8.322a.25.25 0 01-.219-.371l.771-1.4A.993.993 0 009 11V8a1 1 0 10-2 0v2.742l-4.747 8.594A3 3 0 004.749 24h14.5a3 3 0 002.518-4.629zm-13.52-.621A.75.75 0 119 18a.75.75 0 01-.753.75zm3.75 2a.75.75 0 11.75-.75.75.75 0 01-.747.75zm3.75-2A.75.75 0 1116.5 18a.75.75 0 01-.753.75zm3 3A.75.75 0 1119.5 21a.75.75 0 01-.751.75z\"/><path d=\"M13.5 5.75v.7a.25.25 0 00.334.235A5.5 5.5 0 0017.5 1.5.5.5 0 0017 1a5.48 5.48 0 00-3.925 1.657.5.5 0 00-.1.548A6.43 6.43 0 0113.5 5.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});