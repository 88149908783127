define("ember-svg-jar/inlined/retouch-sticker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>retouch-sticker</title><path d=\"M10.631 22.139a.25.25 0 00-.207-.246A9.984 9.984 0 1121.791 9.445a.25.25 0 00.263.184c.357-.031.717-.046 1.082-.046a1.965 1.965 0 01.394.039.25.25 0 00.293-.3 11.985 11.985 0 10-13.387 14.6.249.249 0 00.275-.318 1.993 1.993 0 01-.08-.559z\"/><path d=\"M8.094 7.763a1.672 1.672 0 101.672 1.671 1.671 1.671 0 00-1.672-1.671zM6.483 15.052a.75.75 0 10-1.326.7 7.9 7.9 0 005.424 4.063.25.25 0 00.188-.037.256.256 0 00.107-.159q.1-.488.233-.96a.25.25 0 00-.192-.314 6.461 6.461 0 01-4.434-3.293zM12.131 23.043a.5.5 0 00.5.493.518.518 0 00.379-.167l10.484-11.437.014-.016a.493.493 0 00.128-.333.5.5 0 00-.5-.5 11.017 11.017 0 00-11 11.005zM19.017 9.411a2.908 2.908 0 00-2.567-1.443 2.9 2.9 0 00-2.563 1.443.75.75 0 001.312.728 1.388 1.388 0 011.252-.671 1.393 1.393 0 011.256.671.75.75 0 101.311-.728z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});