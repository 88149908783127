define("ember-svg-jar/inlined/data-transfer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-transfer</title><path d=\"M17.854.276A.5.5 0 0017 .629v1.75a.25.25 0 01-.25.25H7.88A5.882 5.882 0 002 8.5a5.74 5.74 0 00.921 3.142.5.5 0 00.419.228.534.534 0 00.069 0 .5.5 0 00.408-.347A4.154 4.154 0 017.76 8.629h8.99a.25.25 0 01.25.25v1.75a.5.5 0 00.854.354l5-5a.5.5 0 000-.707zM16.24 15.37H7.25a.25.25 0 01-.25-.25v-1.75a.5.5 0 00-.854-.354l-5 5a.5.5 0 000 .707l5 5a.5.5 0 00.354.147.512.512 0 00.191-.038A.5.5 0 007 23.37v-1.75a.25.25 0 01.25-.25h8.87A5.882 5.882 0 0022 15.5a5.741 5.741 0 00-.921-3.143.508.508 0 00-.488-.223.5.5 0 00-.408.347 4.154 4.154 0 01-3.943 2.889z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});