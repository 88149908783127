define("ember-svg-jar/inlined/science-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-graph</title><path d=\"M24 11.492a1 1 0 00-1-1h-7.12a.5.5 0 01-.494-.58c.546-3.409 1.228-5.42 3.256-5.42 1.277 0 2.538 1.595 3.137 3.971a1 1 0 001.94-.489c-.854-3.382-2.8-5.482-5.077-5.482-3.822 0-4.742 3.793-5.307 7.574a.5.5 0 01-.494.426H5.5a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5H7a1 1 0 00.759-1.651l-3-3.5a1.031 1.031 0 00-1.518 0l-3 3.5A1 1 0 001 5.492h1.5a.5.5 0 01.5.5v17a1 1 0 002 0v-10a.5.5 0 01.5-.5h6.9a.5.5 0 01.494.579c-.546 3.409-1.228 5.421-3.256 5.421a2.532 2.532 0 01-1.848-1.136 1 1 0 10-1.58 1.225 4.356 4.356 0 003.432 1.911c3.822 0 4.741-3.793 5.307-7.574a.5.5 0 01.494-.426H23a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});