define("ember-svg-jar/inlined/subtract-bold-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>subtract-bold-alternate</title><path d=\"M21.545 7.886H2.454a2.207 2.207 0 00-2.2 2.2v3.818a2.206 2.206 0 002.205 2.2h19.086a2.206 2.206 0 002.2-2.2v-3.813a2.206 2.206 0 00-2.2-2.205zm-.3 5.477a.25.25 0 01-.25.25H3a.25.25 0 01-.25-.25v-2.727a.25.25 0 01.25-.25h18a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});