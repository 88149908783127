define("ember-svg-jar/inlined/amphibian-frog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>amphibian-frog</title><circle cx=\"4.5\" cy=\"6.575\" r=\"1.25\"/><circle cx=\"19.5\" cy=\"6.575\" r=\"1.25\"/><path d=\"M24 6.575a4.5 4.5 0 00-7.961-2.87A11.8 11.8 0 0012 3.075a11.8 11.8 0 00-4.039.63 4.494 4.494 0 10-6.226 6.408C.1 13.3.739 15.67 1.588 17.12 3.3 20.039 7.384 21.925 12 21.925s8.7-1.886 10.412-4.806c.849-1.45 1.487-3.823-.147-7.007A4.464 4.464 0 0024 6.575zm-22.15 0a2.65 2.65 0 112.65 2.65 2.648 2.648 0 01-2.65-2.65zM20.1 16.809c-1.732 1.992-4.918 3.116-8.1 3.116S5.628 18.8 3.9 16.809a1 1 0 01.142-1.447A13.154 13.154 0 0112 12.925a13.161 13.161 0 017.962 2.436 1 1 0 01.142 1.448zm-.6-7.584a2.65 2.65 0 112.65-2.65 2.648 2.648 0 01-2.65 2.65z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});