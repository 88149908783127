define("ember-svg-jar/inlined/lion-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lion-body</title><path d=\"M5.5 16.23v5.52a1.5 1.5 0 003 0v-3.523a12.978 12.978 0 01-3-1.997zM23.26 6.543a1.032 1.032 0 00-1.44-.03.964.964 0 00-.026 1.387 3.351 3.351 0 01-.659 3.4 4.981 4.981 0 00-3.175-1.53c-1.579 7.575-7.6 8.818-7.96 8.851v3.129a1.5 1.5 0 003 0v-1a1.5 1.5 0 011.5-1.5h3.521a1.5 1.5 0 011.5 1.5v1a1.5 1.5 0 003 0v-7a4.987 4.987 0 00-.313-1.742 4.778 4.778 0 001.777-3.52 4.162 4.162 0 00-.725-2.945z\"/><path d=\"M6.13 14.75a11.482 11.482 0 003.5 2.342.554.554 0 00.295.027c.069-.015 6.881-1.572 6.881-10.869 0-3.136-2.59-5.5-6.025-5.5a6.4 6.4 0 00-5.025 2.234 4.946 4.946 0 00-1.964 1.2 5.183 5.183 0 00-1.285 2.107A3 3 0 00.072 9.9l.382 1.717a3.974 3.974 0 003.753 3.129c.05.003 1.923.004 1.923.004zm-3.724-3.566l-.381-1.717A1 1 0 013 8.25h1.254v-.33a3.166 3.166 0 013-3.17 3 3 0 013 3V9a3.78 3.78 0 01-3.5 3.75H4.359a2 2 0 01-1.953-1.566z\"/><circle cx=\"7.004\" cy=\"8\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});