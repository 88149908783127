define("ember-svg-jar/inlined/video-edit-cc-titles-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-edit-cc-titles-alternate</title><path d=\"M21 1H3a3 3 0 00-3 3v16a3 3 0 003 3h18a3 3 0 003-3V4a3 3 0 00-3-3zm1 19a1 1 0 01-1 1H3a1 1 0 01-1-1V4a1 1 0 011-1h18a1 1 0 011 1z\"/><path d=\"M10.5 11.516a1.013 1.013 0 001-1.016 3 3 0 00-3-3h-2a3 3 0 00-3 3v3a3 3 0 003 3h2a3.008 3.008 0 003-3.021.991.991 0 00-1-.99 1.009 1.009 0 00-1 1.011 1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 011-1h2a1 1 0 011 1v.031a.986.986 0 001 .985zM19.5 11.516a1.013 1.013 0 001-1.016 3 3 0 00-3-3h-2a3 3 0 00-3 3v3a3 3 0 003 3h2a3.008 3.008 0 003-3.021.991.991 0 00-1-.99 1.009 1.009 0 00-1 1.011 1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 011-1h2a1 1 0 011 1v.031a.986.986 0 001 .985z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});