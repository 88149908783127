define("ember-svg-jar/inlined/social-media-meetup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-meetup</title><path d=\"M4 8.23c-.73 0-.89.86-1.26 3.31v.12l-.56-1c-1.13-2-1.15-2-1.57-2a.62.62 0 00-.46.21c-.21.24-.3.34.26 4.72a.6.6 0 101.19-.15c-.07-.56-.14-1.15-.21-1.7 1.13 2 1.24 2.1 1.65 2.1.6 0 .66-.36.91-2.08 0-.23.08-.51.12-.8.2.84.44 1.9.66 2.94a.6.6 0 00.59.48h.12a.6.6 0 00.46-.71C4.76 8.38 4.54 8.15 4 8.23zM19.21 10.33a.59.59 0 00-.44.13.59.59 0 00-.22.4c-.11 1.13-.41 1.85-.9 2.13a3.85 3.85 0 01-.14-2 .6.6 0 00-1.18-.21v.05a.58.58 0 00-.56-.09 3.57 3.57 0 01-.85.13v-1a18.67 18.67 0 010-2.49.6.6 0 10-1.17-.28 14.91 14.91 0 000 2.81v.99a5.06 5.06 0 01-.67-.06.59.59 0 00-.45.09 2.23 2.23 0 00-.09-.77.87.87 0 00-.54-.44 1.37 1.37 0 00-1.44.26 3.56 3.56 0 00-1.23 2.87.58.58 0 00-.79.17l.08.06-.08-.08a.87.87 0 01-.95.3.59.59 0 01-.45-.5v-.11c1.3.28 1.59.19 1.78-.2a4.46 4.46 0 00.17-2.26.87.87 0 00-.46-.53 1.37 1.37 0 00-1.45.3A3.51 3.51 0 006 13a1.77 1.77 0 001.27 1.43 2.09 2.09 0 00.63.1 2.12 2.12 0 001.66-.85.58.58 0 000-.09 1.78 1.78 0 001.05.85 2.09 2.09 0 00.63.1 2.12 2.12 0 001.66-.85.6.6 0 00-1-.69.87.87 0 01-.95.3.59.59 0 01-.45-.5 1.14 1.14 0 010-.11c1.3.28 1.59.19 1.78-.2a3.34 3.34 0 00.17-.67.59.59 0 00.36.22 6.79 6.79 0 00.95.08c.07 1.06.19 2.31.19 2.32a.6.6 0 00.6.54h.06a.6.6 0 00.54-.65c0-.39-.12-1.36-.18-2.2a4.68 4.68 0 001.22-.2h.07a3.19 3.19 0 00.74 2.2.61.61 0 00.55.16c1.27-.28 2-1.39 2.19-3.31a.6.6 0 00-.53-.65zM7.43 11.54a2 2 0 01.49-.61h.06a5.37 5.37 0 01-.07.74zm3.38 0a2 2 0 01.49-.61h.06a5.31 5.31 0 01-.07.74zM24 10.86a2 2 0 00-2.09-1.74c-.75.12-2 .86-2 4.53a.51.51 0 000 .07 24 24 0 00.24 3 .6.6 0 00.59.52h.08a.6.6 0 00.51-.68 24.42 24.42 0 01-.22-2.44c2.41-.6 3.06-2.12 2.89-3.26zm-2.87 2c.11-1.95.65-2.56 1-2.6.29 0 .6.36.67.77.11.81-.51 1.49-1.71 1.88z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});