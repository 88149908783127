define("ember-svg-jar/inlined/people-woman-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-4</title><path d=\"M3.6 6.423a.5.5 0 00.768-.36 6.861 6.861 0 011.51-3.707 5.835 5.835 0 011.26-1.11.5.5 0 00-.046-.862A3.492 3.492 0 003.6 6.423zM18.082 2.356A6.88 6.88 0 0119.6 6.084a.5.5 0 00.762.365A3.493 3.493 0 0016.882.4a.5.5 0 00-.044.86 5.859 5.859 0 011.244 1.096zM22.248 19.812a7.1 7.1 0 00-3.056-1.157c-.3-.063-2.638-.64-2.638-.64a.5.5 0 00-.395.065.5.5 0 00-.22.333A3.844 3.844 0 0112 21.381a3.844 3.844 0 01-3.938-2.968.5.5 0 00-.22-.333.5.5 0 00-.395-.065s-2.335.577-2.638.64a7.107 7.107 0 00-3.057 1.157A4.529 4.529 0 000 23.376v.124a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-.124a4.529 4.529 0 00-1.752-3.564zM9.984 13.111a.751.751 0 00.011 1.06 2.7 2.7 0 001.884.715h.283a2.7 2.7 0 001.883-.714A.75.75 0 0013 13.1a1.229 1.229 0 01-.834.287h-.283a1.229 1.229 0 01-.833-.286.751.751 0 00-1.066.01z\"/><path d=\"M6.45 2.841C5.216 4.3 4.787 6.435 5.174 9.2a2.112 2.112 0 00-.464 1.444 2.729 2.729 0 001.439 2.433.25.25 0 01.144.187c.511 3.048 4.021 4.991 5.686 4.991s5.176-1.943 5.687-4.989a.252.252 0 01.145-.189 2.731 2.731 0 001.439-2.434 2.116 2.116 0 00-.465-1.443c.388-2.759-.041-4.9-1.275-6.354C16.334 1.454 14.474.75 11.979.75s-4.353.704-5.529 2.091zm10.741 8.865a1.746 1.746 0 00-1 1.3c-.378 2.249-3.176 3.739-4.209 3.739s-3.83-1.49-4.207-3.738a1.747 1.747 0 00-1-1.306 1.275 1.275 0 01-.559-1.1.626.626 0 01.1-.439.752.752 0 00.406-.794 13.345 13.345 0 01-.137-1.121.248.248 0 01.248-.269A6.622 6.622 0 0011.89 6.06 6.737 6.737 0 0017.125 8a.25.25 0 01.25.27c-.029.354-.071.721-.136 1.107a.752.752 0 00.406.8.616.616 0 01.105.439 1.279 1.279 0 01-.559 1.09z\"/><path d=\"M9.387 10.823a.628.628 0 01.625 0 .751.751 0 001.006-1.114 2.094 2.094 0 00-2.637 0 .751.751 0 101.006 1.114zM13.952 10.823a.628.628 0 01.625 0 .751.751 0 001.006-1.114 2.094 2.094 0 00-2.637 0 .751.751 0 001.006 1.114z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});