define("ember-svg-jar/inlined/video-game-kirby-enemy-kracko", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-kirby-enemy-kracko</title><path d=\"M2.593 5.657a.25.25 0 00.436.183 5.253 5.253 0 013.335-1.7.25.25 0 00.136-.435L3.3.884a.249.249 0 00-.258-.043.251.251 0 00-.154.215zM13.947 4.276a.251.251 0 00.219-.372L12.229.417a.25.25 0 00-.437 0L9.855 3.9a.251.251 0 00.219.372zM17.516 3.709a.25.25 0 00.136.436 5.241 5.241 0 013.341 1.725.25.25 0 00.437-.181l-.3-4.633a.251.251 0 00-.154-.215.249.249 0 00-.261.043zM7.155 19.719a.243.243 0 00-.161-.426 5.276 5.276 0 01-4-1.791.25.25 0 00-.439.18l.338 5.263a.251.251 0 00.154.215.249.249 0 00.261-.044zM11.792 23.583a.25.25 0 00.437 0l1.937-3.483a.25.25 0 00-.219-.372h-3.873a.25.25 0 00-.219.372zM21.473 17.651a.25.25 0 00-.44-.178 5.267 5.267 0 01-4.027 1.82.236.236 0 00-.156.412l3.868 3.411a.249.249 0 00.261.044.251.251 0 00.154-.215zM3.23 14.476a.248.248 0 01.126.206 3.484 3.484 0 003.638 3.111 3.892 3.892 0 002.352-.77.249.249 0 01.3 0 3.977 3.977 0 004.7 0 .249.249 0 01.3 0 3.892 3.892 0 002.352.77 3.484 3.484 0 003.638-3.111.251.251 0 01.126-.206 3.233 3.233 0 001.7-2.778 3.19 3.19 0 00-1.7-2.778.25.25 0 01-.128-.207A3.484 3.484 0 0017.006 5.6a3.888 3.888 0 00-2.352.77.249.249 0 01-.3 0 3.977 3.977 0 00-4.7 0 .249.249 0 01-.3 0 3.886 3.886 0 00-2.36-.77 3.483 3.483 0 00-3.638 3.114.251.251 0 01-.126.206 3.233 3.233 0 00-1.7 2.778 3.234 3.234 0 001.7 2.778zm7.78-2.749a3.5 3.5 0 11-3.5-3.5 3.5 3.5 0 013.5 3.5z\"/><circle cx=\"7.51\" cy=\"11.727\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});