define("ember-svg-jar/inlined/paginate-filter-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paginate-filter-3</title><path d=\"M22 0H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2V2a2 2 0 00-2-2zm-4.75 12a2.752 2.752 0 01-2.75 2.75h-3a.75.75 0 010-1.5h3a1.25 1.25 0 000-2.5H13a.75.75 0 010-1.5h1.5a1.25 1.25 0 000-2.5h-3a.75.75 0 010-1.5h3A2.749 2.749 0 0116.386 10a2.747 2.747 0 01.864 2z\"/><path d=\"M19.5 22h-17a.5.5 0 01-.5-.5v-17a1 1 0 00-2 0V22a2 2 0 002 2h17.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});