define("ember-svg-jar/inlined/paginate-filter-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paginate-filter-4</title><path d=\"M11.75 11a.25.25 0 00.25.25h3a.25.25 0 00.25-.25V7.124a.25.25 0 00-.313-.242A4.257 4.257 0 0011.75 11z\"/><path d=\"M22 0H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2V2a2 2 0 00-2-2zm-5.25 14a.75.75 0 01-1.5 0v-1.25H11a.75.75 0 01-.75-.75v-1A5.757 5.757 0 0116 5.247a.75.75 0 01.75.75z\"/><path d=\"M19.5 22h-17a.5.5 0 01-.5-.5v-17a1 1 0 00-2 0V22a2 2 0 002 2h17.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});