define("ember-svg-jar/inlined/messages-bubble-forward-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-forward-alternate</title><path d=\"M12 17.833a12.3 12.3 0 01-3.4-.481 1 1 0 00-.661.038l-3.647 1.52a.25.25 0 01-.331-.316l.851-2.339a1 1 0 00-.276-1.089A7.043 7.043 0 012 9.928c0-4.358 4.486-7.9 10-7.9s10 3.546 10 7.9a6.294 6.294 0 01-.15 1.349.25.25 0 00.1.258l1.3.928a.251.251 0 00.385-.131A8.212 8.212 0 0024 9.928c0-5.461-5.383-9.9-12-9.9S0 4.467 0 9.928a8.877 8.877 0 002.708 6.26l-1.6 4.4a1 1 0 001.325 1.265l5.959-2.483a14.079 14.079 0 003.48.462.251.251 0 00.248-.208 8.271 8.271 0 01.388-1.459.25.25 0 00-.244-.336q-.132.004-.264.004z\"/><path d=\"M20.291 12.193a.5.5 0 00-.791.407v1.579a6.942 6.942 0 00-6 6.828v1.969a1 1 0 002 0v-1.969a4.936 4.936 0 014-4.8v1.4a.5.5 0 00.5.5.5.5 0 00.291-.093l3.5-2.5a.5.5 0 000-.814z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});