define("ember-svg-jar/inlined/products-gifts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>products-gifts</title><path d=\"M20.25 13.5a.25.25 0 00-.25.25v3a.25.25 0 00.25.25h2.25a1.5 1.5 0 001.5-1.5v-1.75a.25.25 0 00-.25-.25zM18.75 12.5a.25.25 0 00.25-.25V10.5a.5.5 0 011 0v1.75a.25.25 0 00.25.25h3.5a.25.25 0 00.25-.25V10.5A1.5 1.5 0 0022.5 9h-.836a.25.25 0 01-.177-.427l.543-.543a.75.75 0 00-1.06-1.06l-1.293 1.293a.252.252 0 01-.354 0L18.03 6.97a.75.75 0 00-1.06 1.06l.543.543a.25.25 0 01-.177.427H16.5a1.5 1.5 0 00-1.5 1.5v1.75a.25.25 0 00.25.25zM19 13.75a.25.25 0 00-.25-.25h-3.5a.25.25 0 00-.25.25v1.75a1.5 1.5 0 001.5 1.5h2.25a.25.25 0 00.25-.25z\"/><path d=\"M10.61 15.5H13a1 1 0 000-2h-1.834a.5.5 0 01-.5-.552l.684-6.5a.5.5 0 01.5-.448h6.65a1 1 0 000-2h-.75a.25.25 0 01-.25-.25V3a3 3 0 00-6 0v.9a.1.1 0 01-.1.1 2 2 0 00-1.989 1.791l-.79 7.5A2 2 0 0010.61 15.5zM13.5 3a1 1 0 012 0v.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25zM1 14.75a1 1 0 00-1 1V23a1 1 0 002 0v-7.25a1 1 0 00-1-1zM15.5 19.5H6.375a.625.625 0 010-1.25H8.9a1 1 0 00.92-1.394A2.233 2.233 0 007.764 15.5H3.5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.764a2.238 2.238 0 002.236-2.236V20.5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});