define("ember-svg-jar/inlined/seafood-tuna", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seafood-tuna</title><path d=\"M7.7 15.608a1 1 0 00.576-.184l2.229-1.57a.25.25 0 00-.024-.424l-2.766-1.51a.25.25 0 00-.361.153l-.616 2.27a.99.99 0 00.39 1.082 1 1 0 00.572.183zM10.536 3.312h4.034a.25.25 0 00.156-.445L12.278.908a1 1 0 00-1.6.539L10.294 3a.249.249 0 00.242.312zM22.857 18.312h-5a.5.5 0 00-.5.5v1.548a.5.5 0 00.45.5l4.918.445a1 1 0 001.132-.991v-1a1 1 0 00-1-1.002zM15.357 18.312H4.833a15.268 15.268 0 00-4.125.751.5.5 0 00-.2.831 11.715 11.715 0 008.27 3.418h6.583a.5.5 0 00.5-.5v-4a.5.5 0 00-.504-.5z\"/><path d=\"M23.223 9.815a1 1 0 00-.365-.924l-1.131-.9a.25.25 0 01-.006-.385l1.786-1.531a1 1 0 00-.651-1.759H9.735a8.75 8.75 0 00-4.606 1.329.239.239 0 01-.285-.008l-3.09-2.379A1 1 0 00.167 4.267l.671 3.019L.27 8.991A1 1 0 001.676 10.2l2.647-1.36a.246.246 0 01.279.038 4.2 4.2 0 00.855.573l6.06 3.149a5.514 5.514 0 002.653.685 5.423 5.423 0 002.07-.41l6.374-2.275a.988.988 0 00.609-.785zm-5.116-2a1 1 0 111-1 1 1 0 01-1 .997z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});