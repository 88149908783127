define("ember-svg-jar/inlined/phone-action-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-sync</title><path d=\"M15.936 12.043a1 1 0 00-1.271.622 2.7 2.7 0 01-4.566.942l.755-.753A.5.5 0 0010.5 12H8a.5.5 0 00-.5.5V15a.5.5 0 00.309.462.507.507 0 00.545-.108l.326-.327a4.709 4.709 0 007.878-1.715 1 1 0 00-.622-1.269zM8.064 10.957a1.013 1.013 0 001.271-.622 2.692 2.692 0 014.559-.935l-.748.748A.5.5 0 0013.5 11H16a.5.5 0 00.5-.5V8a.5.5 0 00-.854-.354l-.327.328a4.708 4.708 0 00-7.877 1.713 1 1 0 00.622 1.27z\"/><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});