define("ember-svg-jar/inlined/loading-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>loading-circle</title><path d=\"M12 6.376a1.222 1.222 0 001.222-1.223V1.242a1.222 1.222 0 00-2.444 0v3.911A1.222 1.222 0 0012 6.376zM6.786 6.389A1.222 1.222 0 008.9 5.167L8.17 3.9a1.222 1.222 0 00-2.117 1.219zM2.921 9.145l1.27.734a1.222 1.222 0 101.222-2.117l-1.27-.734a1.222 1.222 0 00-1.222 2.117zM2.222 14.2h1.467a1.223 1.223 0 000-2.445H2.222a1.223 1.223 0 000 2.445zM4.191 16.073l-1.27.733a1.222 1.222 0 101.222 2.117l1.27-.733a1.222 1.222 0 10-1.222-2.117zM6.5 22.5a1.222 1.222 0 001.67-.447l.733-1.27a1.222 1.222 0 00-2.117-1.223l-.733 1.27A1.222 1.222 0 006.5 22.5zM12 23.976a1.222 1.222 0 001.222-1.223v-1.466a1.222 1.222 0 00-2.444 0v1.466A1.222 1.222 0 0012 23.976zM15.83 22.055a1.222 1.222 0 102.117-1.223l-.733-1.27a1.222 1.222 0 10-2.114 1.223zM21.079 16.806l-1.27-.733a1.222 1.222 0 10-1.222 2.117l1.27.733a1.222 1.222 0 001.222-2.117zM19.089 12.976a1.222 1.222 0 001.222 1.224h1.467a1.223 1.223 0 000-2.445h-1.467a1.223 1.223 0 00-1.222 1.221zM18.139 9.431a1.224 1.224 0 001.67.448l1.27-.734a1.222 1.222 0 00-1.222-2.117l-1.27.734a1.222 1.222 0 00-.448 1.669zM15.544 6.836a1.222 1.222 0 001.67-.447l.733-1.27A1.222 1.222 0 0015.83 3.9l-.73 1.267a1.222 1.222 0 00.444 1.669z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});