define("ember-svg-jar/inlined/business-pearl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-pearl</title><path d=\"M5.226 12.221c-1.9 1.02-3.476 2.3-3.476 4.279C1.75 20.775 6.157 24 12 24s10.25-3.225 10.25-7.5c0-1.978-1.578-3.259-3.476-4.279a.25.25 0 010-.441c1.9-1.02 3.476-2.3 3.476-4.28C22.25 3.225 17.843 0 12 0S1.75 3.225 1.75 7.5c0 1.979 1.578 3.26 3.476 4.28a.25.25 0 010 .441zm-.312-5.9l-.609.375a.251.251 0 01-.371-.286 5.689 5.689 0 012.972-3.268.25.25 0 01.35.31 4.06 4.06 0 00-.194.677.75.75 0 101.471.29 3.5 3.5 0 011.816-2.248.929.929 0 01.352-.107.5.5 0 01.549.5V4.5a.75.75 0 001.5 0V2.562a.5.5 0 01.549-.5.929.929 0 01.352.107 3.5 3.5 0 011.816 2.248.75.75 0 101.471-.29 4.06 4.06 0 00-.194-.677.25.25 0 01.35-.31 5.689 5.689 0 012.972 3.263.251.251 0 01-.371.286l-.609-.375a2.233 2.233 0 00-2.567.414 2.23 2.23 0 01-3 .135 2.387 2.387 0 00-3.046 0 2.23 2.23 0 01-3-.135 2.233 2.233 0 00-2.559-.412zM15 12.25a3 3 0 11-3-3 3 3 0 013 3zm-7.519 5.02a2.232 2.232 0 013-.135 2.387 2.387 0 003.046 0 2.232 2.232 0 013 .135 2.235 2.235 0 002.567.415l.609-.375a.25.25 0 01.371.285 5.686 5.686 0 01-2.972 3.263.25.25 0 01-.35-.31 4.033 4.033 0 00.194-.677.75.75 0 10-1.471-.29 3.5 3.5 0 01-1.816 2.249.966.966 0 01-.352.107.5.5 0 01-.549-.5V19.5a.75.75 0 00-1.5 0v1.938a.5.5 0 01-.549.5.966.966 0 01-.352-.107 3.5 3.5 0 01-1.816-2.249.75.75 0 10-1.471.29 4.033 4.033 0 00.194.677.25.25 0 01-.35.31 5.686 5.686 0 01-2.98-3.259.25.25 0 01.371-.285l.609.375a2.235 2.235 0 002.567-.42z\"/><path d=\"M10 12.25a.5.5 0 001 0 1 1 0 011-1 .5.5 0 000-1 2 2 0 00-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});