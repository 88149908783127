define("ember-svg-jar/inlined/water-protection-tower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-protection-tower</title><path d=\"M19.25 16.25H4.75a1 1 0 000 2h.5a.5.5 0 01.5.5V23a1 1 0 001.45.9l4.579-2.29a.5.5 0 01.448 0L16.8 23.9a1 1 0 001.45-.9v-4.25a.5.5 0 01.5-.5h.5a1 1 0 000-2zM9.317 20.6l-1.2.6a.25.25 0 01-.362-.224v-1.2a.25.25 0 01.362-.224l1.205.6a.251.251 0 010 .448zm3.75-1.875l-.843.421a.5.5 0 01-.448 0l-.842-.421a.25.25 0 01.111-.474h1.91a.25.25 0 01.112.474zm3.183 2.253a.25.25 0 01-.362.224l-1.2-.6a.251.251 0 010-.448l1.205-.6a.25.25 0 01.362.224zM17.5 12.25h-11a.5.5 0 00-.5.5A2.25 2.25 0 008.25 15h7.5A2.25 2.25 0 0018 12.75a.5.5 0 00-.5-.5z\"/><rect x=\"6\" y=\"8.25\" width=\"12\" height=\"2.5\" rx=\".5\" ry=\".5\"/><path d=\"M5.5 5a.5.5 0 01.5.5v.75a.5.5 0 00.5.5h11a.5.5 0 00.5-.5V5.5a.5.5 0 01.5-.5.5.5 0 00.262-.926l-6.5-4a.5.5 0 00-.524 0l-6.5 4A.5.5 0 005.5 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});