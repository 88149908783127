define("ember-svg-jar/inlined/resize-expand-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>resize-expand-arrow</title><rect x=\"20\" y=\"7.5\" width=\"2\" height=\"9\" rx=\".25\" ry=\".25\"/><rect x=\"7.5\" y=\"2\" width=\"9\" height=\"2\" rx=\".25\" ry=\".25\"/><rect width=\"6\" height=\"6\" rx=\"2\" ry=\"2\"/><rect x=\"18\" width=\"6\" height=\"6\" rx=\"2\" ry=\"2\"/><rect x=\"18\" y=\"18\" width=\"6\" height=\"6\" rx=\"2\" ry=\"2\"/><path d=\"M12 13.164a.251.251 0 01.073-.177l2.25-2.25a.25.25 0 01.427.177V12.5a.75.75 0 001.5 0v-4a.753.753 0 00-.75-.75h-4a.75.75 0 000 1.5h1.586a.25.25 0 01.177.427l-2.25 2.25a.251.251 0 01-.177.073H4.25a.25.25 0 01-.25-.25v-4a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V21a1 1 0 001 1h13.25a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25h-4a.25.25 0 01-.25-.25zm-8 .586a.25.25 0 01.25-.25h6a.25.25 0 01.25.25v6a.25.25 0 01-.25.25H4.5a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});