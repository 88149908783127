define("ember-svg-jar/inlined/deforestation-cut-tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>deforestation-cut-tree</title><path d=\"M3.762 18.067a.5.5 0 00-.562.262l-.48.961h-.309a.5.5 0 00-.5.5v3.71a.5.5 0 00.5.5h1.237a.5.5 0 00.5-.5v-4.947a.5.5 0 00-.386-.486zM21.976.184a.5.5 0 00-.459-.179l-6.056.884a1.117 1.117 0 00-.95 1.223.5.5 0 01-.438.548L8.68 3.3a1.118 1.118 0 00-.733 1.82.525.525 0 01-.367.854L3.2 6.3a1.119 1.119 0 00-.782 1.824L5.336 11.7a.5.5 0 01-.071.7l-3.056 2.5a.5.5 0 00.427.875l1.047-.236.2.239a.5.5 0 00.337.182h.05a.5.5 0 00.316-.113l2.1-1.716a.5.5 0 01.7.071l2.921 3.573a1.119 1.119 0 001.943-.405l1.19-4.23a.523.523 0 01.908-.189 1.119 1.119 0 001.929-.357l1.7-5.157a.5.5 0 01.625-.32 1.118 1.118 0 001.387-.689L22.06.669a.5.5 0 00-.084-.485z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});