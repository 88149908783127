define("ember-svg-jar/inlined/real-estate-sign-rent-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-sign-rent-1</title><path d=\"M23.734 23.058A25.57 25.57 0 0012 20.5 25.57 25.57 0 00.266 23.058.5.5 0 00.5 24h23a.5.5 0 00.234-.942zM10.879 13.409a.5.5 0 00-.529.468L10.035 19a.5.5 0 00.52.531c.77-.034 1.346-.035 1.968-.026a.5.5 0 00.507-.469l.309-4.976a.5.5 0 00-.469-.53zM6.1 5.476a.625.625 0 10.076-1.248.123.123 0 00-.132.116l-.061 1a.126.126 0 00.117.132z\"/><path d=\"M3.949 11.98l15.97.98a2.5 2.5 0 002.648-2.343L23 3.631A2.505 2.505 0 0020.652.982L4.684 0a2.5 2.5 0 00-2.649 2.346l-.429 6.987a2.5 2.5 0 002.343 2.647zm13.529-8.312l2 .123a.625.625 0 01-.038 1.249h-.04l-.123-.008a.251.251 0 00-.265.235l-.252 4.117a.625.625 0 01-1.248-.076l.252-4.118a.25.25 0 00-.234-.264l-.125-.008a.626.626 0 01.071-1.249zm-4.649.342a.626.626 0 01.544-.582.636.636 0 01.674.424l.263.8a.25.25 0 00.487-.063l.028-.454a.629.629 0 01.658-.587h.009a.625.625 0 01.581.662l-.306 4.99a.625.625 0 01-1.218.158l-.264-.8a.25.25 0 00-.487.063l-.027.454a.635.635 0 01-.66.586h-.005A.625.625 0 0112.523 9zm-4.054.753a1.614 1.614 0 01.545-1.117 1.585 1.585 0 011.18-.406l1 .062a.625.625 0 01.586.662.635.635 0 01-.659.586h-.007l-.992-.061h-.005a.374.374 0 00-.395.351l-.073 1.122a.25.25 0 00.234.265l1.123.069a.625.625 0 11-.078 1.248l-1.121-.069a.249.249 0 00-.265.234l-.008.125a.372.372 0 00.35.4l1 .062a.625.625 0 01-.078 1.248l-1-.061a1.625 1.625 0 01-1.52-1.726zM4.844 3.52a.631.631 0 01.656-.587l.753.046a1.875 1.875 0 01.763 3.529.252.252 0 00-.1.337l.8 1.538a.625.625 0 11-1.116.576l-.336-.645a.25.25 0 00-.471.1l-.011.173a.625.625 0 01-1.248-.076z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});