define("ember-svg-jar/inlined/crypto-currency-bitcoin-monitor-mining-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-monitor-mining-1</title><path d=\"M9.25 11.479a.75.75 0 00.75.75h1a.25.25 0 01.25.25v1a.75.75 0 001.5 0v-1a.25.25 0 01.25-.25 2.252 2.252 0 002.25-2.25 2.219 2.219 0 00-.46-1.348.251.251 0 010-.3 2.219 2.219 0 00.46-1.349A2.253 2.253 0 0013 4.729a.249.249 0 01-.25-.25V3.5a.75.75 0 00-1.5 0v.979a.249.249 0 01-.25.25h-1a.75.75 0 00-.75.75zm3.75-.75h-2a.249.249 0 01-.25-.25v-1a.25.25 0 01.25-.25h2a.75.75 0 010 1.5zm-2.25-4.25a.25.25 0 01.25-.25h2a.75.75 0 010 1.5h-2a.249.249 0 01-.25-.25z\"/><path d=\"M17.5 7.75H17a.75.75 0 000 1.5h.5a.25.25 0 01.25.25V15a.25.25 0 01-.25.25h-11a.25.25 0 01-.25-.25V9.5a.25.25 0 01.25-.25H7a.75.75 0 000-1.5h-.5A1.751 1.751 0 004.75 9.5v7a1.751 1.751 0 001.75 1.75H11a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H9a.75.75 0 000 1.5h6a.75.75 0 000-1.5h-2a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h4.5a1.751 1.751 0 001.75-1.75v-7a1.751 1.751 0 00-1.75-1.75zM3 8.5h-.25a.25.25 0 01-.25-.25V5a1 1 0 00-2 0v3.5a2 2 0 002 2H3a1 1 0 000-2z\"/><circle cx=\"1.5\" cy=\"1.5\" r=\"1.5\"/><path d=\"M21 10.5h.5a2 2 0 002-2V5a1 1 0 00-2 0v3.25a.25.25 0 01-.25.25H21a1 1 0 000 2z\"/><circle cx=\"22.5\" cy=\"1.5\" r=\"1.5\"/><path d=\"M3 13.5h-.5a2 2 0 00-2 2V19a1 1 0 002 0v-3.25a.25.25 0 01.25-.25H3a1 1 0 000-2z\"/><circle cx=\"1.5\" cy=\"22.5\" r=\"1.5\"/><path d=\"M21.25 15.5a.25.25 0 01.25.25V19a1 1 0 002 0v-3.5a2 2 0 00-2-2H21a1 1 0 000 2z\"/><circle cx=\"22.5\" cy=\"22.5\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});