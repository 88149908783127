define("ember-svg-jar/inlined/folder-edit-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-edit-1</title><path d=\"M22.5 4.5H11.736a.5.5 0 01-.447-.276l-.947-1.9A1.494 1.494 0 009 1.5H1.5A1.5 1.5 0 000 3v18a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 21V6a1.5 1.5 0 00-1.5-1.5zm-5.663 7.37l-6.358 6.359a.5.5 0 01-.256.136L7.1 18.99A.473.473 0 017 19a.5.5 0 01-.49-.6l.625-3.125a.5.5 0 01.136-.256l6.359-6.356a2.268 2.268 0 013.207 3.207z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});