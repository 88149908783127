define("ember-svg-jar/inlined/wild-bird-eagle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wild-bird-eagle-1</title><path d=\"M18.06 7.356C17.591 3.765 14.323 0 8.625 0h-8a.5.5 0 00-.493.582A5.579 5.579 0 001.315 3.25h-.69a.5.5 0 00-.493.582A4.862 4.862 0 001.315 6.25h-.69a.5.5 0 00-.493.582c.182 1.094 1.037 3.087 2.993 3.382V21.75A2.6 2.6 0 005.958 24a3.1 3.1 0 002.334-.975 3.094 3.094 0 002.333.975 3.094 3.094 0 002.333-.975 3.1 3.1 0 002.334.975 2.6 2.6 0 002.833-2.25v-5h.5c2.356 0 3.166.768 3.258 1.124a1 1 0 001.992-.124v-4c0-4.008-3.568-5.945-5.815-6.394zm3.815 7.992a7.749 7.749 0 00-3.25-.6h-5.017a.7.7 0 01-.375-1.194L17.5 9.285c1.341.214 4.371 1.429 4.371 4.465zM9.3 5.5l2.5 1.836a2.25 2.25 0 01.1.685A2.529 2.529 0 119.3 5.5z\"/><circle cx=\"9.375\" cy=\"8\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});