define("ember-svg-jar/inlined/email-action-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-edit</title><path d=\"M21 1.756a.159.159 0 00-.271-.113L12.2 10.17a2.407 2.407 0 01-3.4 0L.272 1.643A.155.155 0 00.1 1.609a.158.158 0 00-.1.147v10.5a1.75 1.75 0 001.75 1.75h12.536a.25.25 0 00.177-.074l6.465-6.465A.247.247 0 0021 7.291z\"/><path d=\"M9.727 9.242a1.1 1.1 0 001.547 0L19.749.767a.439.439 0 00.106-.448c-.1-.286-.364-.313-.605-.313H1.75c-.24 0-.51.027-.6.313a.443.443 0 00.106.448zM11.6 23.985l3.143-.629a.25.25 0 00.128-.422l-2.809-2.808a.249.249 0 00-.249-.062.253.253 0 00-.173.19l-.629 3.146a.5.5 0 00.588.588zM23.227 11.76a2.62 2.62 0 00-3.7 0l-6.679 6.68a.25.25 0 000 .353l3.352 3.354a.25.25 0 00.353 0l6.679-6.68a2.621 2.621 0 00-.005-3.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});