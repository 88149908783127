define("ember-svg-jar/inlined/primitive-symbols-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>primitive-symbols-man</title><path d=\"M16.5 13a3.25 3.25 0 003.25-3.25v-3.5a1.25 1.25 0 00-2.5 0v3.5a.752.752 0 01-.75.75h-3a.25.25 0 01-.25-.25V7.938a.243.243 0 01.158-.226 4 4 0 10-2.82.022.242.242 0 01.162.227v2.289a.25.25 0 01-.25.25h-3a.752.752 0 01-.75-.75v-3.5a1.25 1.25 0 00-2.5 0v3.5A3.25 3.25 0 007.5 13h3a.25.25 0 01.25.25v1.95a.248.248 0 01-.06.162l-5.639 6.574a1.25 1.25 0 101.9 1.627l4.861-5.671a.251.251 0 01.38 0l4.861 5.671a1.3 1.3 0 001.762.136 1.25 1.25 0 00.136-1.763l-5.641-6.579a.248.248 0 01-.06-.162V13.25a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});