define("ember-svg-jar/inlined/text-strike-through-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>text-strike-through-1</title><path d=\"M20 0H4a2 2 0 00-2 2v2a1 1 0 002 0V2.248A.25.25 0 014.25 2h6.5a.25.25 0 01.25.25V9a1 1 0 002 0V2.248a.25.25 0 01.25-.25h6.5a.25.25 0 01.25.25V4a1 1 0 102 0V2a2 2 0 00-2-2zM15.5 22h-2.25a.25.25 0 01-.25-.25V16a1 1 0 00-2 0v5.75a.25.25 0 01-.25.25H8.5a1 1 0 000 2h7a1 1 0 100-2zM21 11.5H3.031a1 1 0 000 2H21a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});