define("ember-svg-jar/inlined/picture-stack-landscape", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>picture-stack-landscape</title><path d=\"M14.335 9.7a.873.873 0 00-1.7-.039l-1.592 3.5a.25.25 0 01-.42.056l-1.1-1.314a1.163 1.163 0 00-1.967.153L5.071 16.2a.5.5 0 00.429.757h11a.5.5 0 00.464-.686z\"/><path d=\"M20.5 18.5V2a2 2 0 00-2-2H2a2 2 0 00-2 2v16.5a2 2 0 002 2h16.5a2 2 0 002-2zM2 2.5a.5.5 0 01.5-.5H18a.5.5 0 01.5.5V18a.5.5 0 01-.5.5H2.5A.5.5 0 012 18z\"/><circle cx=\"6\" cy=\"6\" r=\"2\"/><path d=\"M23 6a1 1 0 00-1 1v14.5a.5.5 0 01-.5.5H7a1 1 0 000 2h15a2 2 0 002-2V7a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});