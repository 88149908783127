define("ember-svg-jar/inlined/radio-retro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>radio-retro</title><path d=\"M23.75 15a.25.25 0 00.25-.25V9.5A2.5 2.5 0 0021.5 7H6.25A.25.25 0 016 6.75V1a1 1 0 00-2 0v5.75a.25.25 0 01-.25.25H2.5A2.5 2.5 0 000 9.5v5.25a.25.25 0 00.25.25zM20 12a1 1 0 01-1 1H5a1 1 0 01-1-1v-1a1 1 0 011-1h14a1 1 0 011 1zM.25 16.5a.25.25 0 00-.25.25v2.75a2.5 2.5 0 002.273 2.49.249.249 0 01.227.249V23a1 1 0 002 0v-.75a.25.25 0 01.25-.25h14.5a.25.25 0 01.25.25V23a1 1 0 002 0v-.761a.249.249 0 01.227-.249A2.5 2.5 0 0024 19.5v-2.75a.25.25 0 00-.25-.25zm20.75 1a1.25 1.25 0 11-1.25 1.25A1.249 1.249 0 0121 17.5zM7.25 18h9.5a.75.75 0 010 1.5h-9.5a.75.75 0 010-1.5zM3 17.5a1.25 1.25 0 11-1.25 1.25A1.249 1.249 0 013 17.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});