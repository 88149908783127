define("ember-svg-jar/inlined/floppy-disk-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>floppy-disk-3</title><path d=\"M12 23.5A11.5 11.5 0 10.5 12 11.5 11.5 0 0012 23.5zm2.846-7.875a7.673 7.673 0 001.717-2.972.5.5 0 01.81-.238l2.927 2.569a.5.5 0 01.143.535 4.775 4.775 0 01-1.172 2.031.5.5 0 01-.536.144l-3.684-1.247a.5.5 0 01-.2-.821zm-4.411-12.3a.5.5 0 01.392-.393 4.777 4.777 0 012.346 0 .5.5 0 01.392.393l.763 3.814a.5.5 0 01-.612.583 7.677 7.677 0 00-3.432 0 .5.5 0 01-.612-.584zM12 9.5A2.5 2.5 0 119.5 12 2.5 2.5 0 0112 9.5zm-8.3 5.483l2.921-2.567a.5.5 0 01.811.238 7.671 7.671 0 001.716 2.972.5.5 0 01-.2.821l-3.681 1.247a.5.5 0 01-.535-.144 4.768 4.768 0 01-1.173-2.031.5.5 0 01.141-.536z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});