define("ember-svg-jar/inlined/martial-arts-mask-helmet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>martial-arts-mask-helmet</title><path d=\"M23.749 16.845a1.009 1.009 0 00-.682-.366A4.478 4.478 0 0119 12V7A7 7 0 005 7v5a4.479 4.479 0 01-4.067 4.479 1 1 0 00-.9 1.113A5 5 0 005 22a3.838 3.838 0 003.53-1.736.251.251 0 01.47.128V21a3 3 0 006 0c0-.236.005-.437.005-.608a.25.25 0 01.465-.127A3.841 3.841 0 0019 22a5 5 0 004.965-4.408 1.006 1.006 0 00-.216-.747zM8.627 4.107A3.832 3.832 0 0112 2.5a3.832 3.832 0 013.373 1.607.25.25 0 01-.206.393H8.832a.25.25 0 01-.205-.393zm6.325 9.8A3.825 3.825 0 0112 15.5a3.825 3.825 0 01-2.952-1.594.25.25 0 01.2-.406h5.513a.25.25 0 01.2.406zm1.413-3.32c-.031.18-.068.357-.109.53a.5.5 0 01-.487.384H8.23a.5.5 0 01-.486-.384 8.711 8.711 0 01-.109-.53.5.5 0 01.493-.587h7.744a.5.5 0 01.493.586zM16.3 7.835a.5.5 0 01-.37.165H8.069a.5.5 0 01-.5-.549c.018-.178.039-.352.064-.523a.5.5 0 01.496-.428h7.741a.5.5 0 01.495.428c.025.171.046.345.064.522a.5.5 0 01-.129.385z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});