define("ember-svg-jar/inlined/fitness-six-pack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fitness-six-pack</title><path d=\"M14 14.5a1 1 0 01-1-1 1 1 0 00-2 0 1 1 0 01-1 1 1 1 0 000 2 2.987 2.987 0 001.714-.539.5.5 0 01.572 0A2.982 2.982 0 0014 16.5a1 1 0 000-2zM14 20a1 1 0 01-1-1 1 1 0 00-2 0 1 1 0 01-1 1 1 1 0 000 2 2.987 2.987 0 001.714-.539.5.5 0 01.572 0A2.982 2.982 0 0014 22a1 1 0 000-2z\"/><path d=\"M10 11a1 1 0 00-1-1 3 3 0 00-3 3v10a1 1 0 002 0V13a1 1 0 011-1 1 1 0 001-1zM15 10a1 1 0 000 2 1 1 0 011 1v10a1 1 0 002 0V13a3 3 0 00-3-3zM2.558 10.544a1 1 0 10-1.772.928l1.6 3.056a1 1 0 01.114.464V23a1 1 0 002 0v-8.5a1 1 0 00-.114-.464zM22.792 10.122a1 1 0 00-1.35.422l-1.828 3.492a1 1 0 00-.114.464V23a1 1 0 002 0v-8.008a1 1 0 01.114-.464l1.6-3.056a1 1 0 00-.422-1.35zM23 0a1 1 0 00-1 1v2c0 2.368-2.829 3-4.5 3S13 5.368 13 3a1 1 0 00-2 0c0 2.368-2.829 3-4.5 3S2 5.368 2 3V1a1 1 0 00-2 0v2c0 3.454 3.265 5 6.5 5a7.521 7.521 0 005.154-1.758.5.5 0 01.692 0A7.519 7.519 0 0017.5 8C20.735 8 24 6.454 24 3V1a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});