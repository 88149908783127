define("ember-svg-jar/inlined/road-sign-side-road-angle-left-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-side-road-angle-left-1</title><path d=\"M12.53.22a.749.749 0 00-1.06 0L.22 11.47a.749.749 0 000 1.06l11.25 11.25a.748.748 0 001.06 0l11.25-11.25a.749.749 0 000-1.06zm-.975 16.28v-1.9a.25.25 0 00-.427-.177l-1.962 1.966a.5.5 0 01-.707 0l-.707-.707a.5.5 0 010-.707l3.73-3.73a.251.251 0 00.073-.177V9.25a.25.25 0 00-.25-.25h-.646a.25.25 0 01-.177-.427L12.2 6.854a.5.5 0 01.707 0l1.719 1.719a.25.25 0 01-.174.427h-.647a.25.25 0 00-.25.25v7.25a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});