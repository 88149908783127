define("ember-svg-jar/inlined/single-woman-actions-share-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-share-2</title><path d=\"M9.372 17.5a.249.249 0 00.243-.194A4.249 4.249 0 0113.75 14a4.177 4.177 0 011.25.193.248.248 0 00.2-.024l.8-.477a.249.249 0 000-.432 35.315 35.315 0 00-4.521-1.837.779.779 0 01-.513-.73v-.467a.25.25 0 01.25-.25A6.374 6.374 0 0015 9.081a.388.388 0 00.084-.593c-.494-.537-.835-1.185-.981-3.317C13.838 1.346 11.236 0 9 0S4.161 1.346 3.9 5.171c-.146 2.132-.487 2.78-.981 3.317A.388.388 0 003 9.081a6.348 6.348 0 003.782.9.251.251 0 01.254.25v.462a.779.779 0 01-.513.73c-2.9 1.065-5.212 1.825-5.764 2.922A7.822 7.822 0 000 17.076a.386.386 0 00.1.3.4.4 0 00.29.126z\"/><path d=\"M24 21.25a2.741 2.741 0 00-4.637-1.989.253.253 0 01-.266.051l-2.445-.978a.251.251 0 01-.157-.217c0-.045-.008-.1-.016-.162a.247.247 0 01.119-.247L19.436 16a.251.251 0 01.271.008 2.707 2.707 0 001.543.488 2.75 2.75 0 10-2.75-2.75 2.4 2.4 0 00.021.295.247.247 0 01-.119.247L15.563 16a.249.249 0 01-.27-.009 2.714 2.714 0 00-1.543-.491 2.75 2.75 0 101.887 4.739.253.253 0 01.266-.051l2.45.98a.251.251 0 01.157.21A2.746 2.746 0 0024 21.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});