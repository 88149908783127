define("ember-svg-jar/inlined/pin-location-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin-location-1</title><path d=\"M16.726 18.285a1 1 0 00-.168 1.993 29.747 29.747 0 013.776.523.25.25 0 010 .488A40 40 0 0112 22a40.006 40.006 0 01-8.333-.711.25.25 0 010-.488 29.551 29.551 0 013.736-.52 1 1 0 00-.17-1.992C0 18.9 0 20.4 0 21.044 0 23.712 8.317 24 12 24s12-.288 12-2.956c0-.644 0-2.144-7.274-2.759z\"/><path d=\"M12 21.6a1 1 0 00.842-.461c1.958-3.063 6.512-10.536 6.512-13.786a7.355 7.355 0 10-14.71 0c0 3.25 4.554 10.724 6.513 13.786A.993.993 0 0012 21.6zM8.5 7a3.5 3.5 0 113.5 3.5A3.5 3.5 0 018.5 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});