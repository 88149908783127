define("ember-svg-jar/inlined/gesture-expand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-expand</title><path d=\"M9.5 3.749h1.25A.25.25 0 0111 4v2a1 1 0 002 0V4a.25.25 0 01.25-.25h1.25a.751.751 0 00.469-1.336l-2.5-2a.752.752 0 00-.938 0l-2.5 2A.751.751 0 009.5 3.749zM14.5 20.249h-1.25A.25.25 0 0113 20v-2a1 1 0 00-2 0v2a.25.25 0 01-.25.25H9.5a.751.751 0 00-.469 1.335l2.5 2a.751.751 0 00.938 0l2.5-2a.75.75 0 00-.469-1.336zM6 10.5H4a.25.25 0 01-.25-.25V9a.75.75 0 00-1.335-.469l-2 2.5a.749.749 0 000 .938l2 2.5A.751.751 0 003.75 14v-1.25A.25.25 0 014 12.5h2a1 1 0 000-2zM23.585 11.029l-2-2.5A.751.751 0 0020.25 9v1.25a.25.25 0 01-.25.25h-2a1 1 0 000 2h2a.25.25 0 01.25.25V14a.75.75 0 001.335.469l2-2.5a.749.749 0 000-.94zM12 8a3.5 3.5 0 00-3.5 3.5v4a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-4A3.5 3.5 0 0012 8zm1.5 4.625a.375.375 0 01-.375.375h-2.25a.375.375 0 01-.375-.375V11.5a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});