define("ember-svg-jar/inlined/direction-button-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>direction-button-2</title><circle cx=\"12\" cy=\"11.987\" r=\"2.5\"/><path d=\"M12.8.387a1.036 1.036 0 00-1.6 0l-3 4a1 1 0 00.8 1.6h6a1 1 0 00.8-1.6zM5.447 8.092a1 1 0 00-1.047.095l-4 3a1 1 0 000 1.6l4 3a1 1 0 001.6-.8v-6a1 1 0 00-.553-.895zM15 17.987H9a1 1 0 00-.8 1.6l3 4a1 1 0 001.6 0l3-4a1 1 0 00-.8-1.6zM23.6 11.187l-4-3a1 1 0 00-1.6.8v6a1 1 0 001.6.8l4-3a1 1 0 000-1.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});