define("ember-svg-jar/inlined/volume-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>volume-down</title><path d=\"M12 18.751a7 7 0 004.744-12.144.25.25 0 00-.388.063 4.966 4.966 0 01-8.712 0 .249.249 0 00-.388-.062A7 7 0 0012 18.751z\"/><path d=\"M12 7.751a3.5 3.5 0 10-3.5-3.5 3.5 3.5 0 003.5 3.5zm0-5.5a2 2 0 11-2 2 2 2 0 012-2z\"/><path d=\"M12 23.251A11.46 11.46 0 0022.368 6.825a.249.249 0 01.049-.284l.437-.437a.5.5 0 00-.354-.853H20a.5.5 0 00-.5.5v2.5a.5.5 0 00.309.462.508.508 0 00.191.038.5.5 0 00.354-.147l.161-.161a.25.25 0 01.412.091A9.494 9.494 0 114.2 6.323a1 1 0 10-1.64-1.144A11.5 11.5 0 0012 23.251z\"/><circle cx=\"12\" cy=\"4.251\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});