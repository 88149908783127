define("ember-svg-jar/inlined/hand-drag-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hand-drag-1-alternate</title><path d=\"M18 6a2.449 2.449 0 00-.891.172A2.49 2.49 0 0015 5a2.449 2.449 0 00-.891.172 2.484 2.484 0 00-4.219 0A2.443 2.443 0 009 5a2.5 2.5 0 00-2.5 2.5V8H6a2.723 2.723 0 00-2.5 3v1c0 2.971 1.614 8 7.665 8H14.5a6.007 6.007 0 006-6V8.5A2.5 2.5 0 0018 6zm.5 8a4 4 0 01-4 4h-3.336C5.658 18 5.5 12.612 5.5 12v-1c0-.419.174-1 .5-1h.5v1a1 1 0 002 0V7.5a.5.5 0 111 0v1a1 1 0 002 0v-2a.5.5 0 111 0v2a1 1 0 002 0v-1a.5.5 0 111 0V10a1 1 0 002 0V8.5a.5.5 0 011 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});