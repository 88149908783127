define("ember-svg-jar/inlined/gymnastics-acrobatic-hanging-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gymnastics-acrobatic-hanging-2</title><path d=\"M16.223 5.989a.5.5 0 00-.723.447V8.5a1 1 0 00.4.8A6.5 6.5 0 1112 8a6.629 6.629 0 01.929.066.5.5 0 00.571-.5V1.5a1.5 1.5 0 00-3 0v3.2a.5.5 0 01-.4.49 9.5 9.5 0 106.124.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});