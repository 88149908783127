define("ember-svg-jar/inlined/christmas-sock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-sock</title><path d=\"M6.669 8.19a.249.249 0 00.433.049 2.315 2.315 0 011.14-.845 2.342 2.342 0 011.779.115l.976.48a.5.5 0 00.559-.08 2.2 2.2 0 011.689-.575.5.5 0 00.491-.278l.48-.973a2.33 2.33 0 012.465-1.271.25.25 0 00.277-.331l-.158-.443a1.927 1.927 0 00-.437-.688.5.5 0 01-.143-.35V1a1 1 0 10-2 0v1.483a.5.5 0 01-.334.472l-6.194 2.19a2 2 0 00-1.169 1.084 1.978 1.978 0 00-.015 1.578zM20.972 14.489q-.928-1.944-1.755-3.951a.251.251 0 00-.428-.059 2.315 2.315 0 01-1.827.885 2.34 2.34 0 01-1.031-.238l-.5-.248a.5.5 0 00-.61.136 2.222 2.222 0 01-1.02.717 2.257 2.257 0 01-.732.122 2.059 2.059 0 01-.511-.066.5.5 0 00-.571.264l-.247.5a2.329 2.329 0 01-3.225 1 .5.5 0 00-.42-.032l-2.131.8a7.37 7.37 0 00-.476.208A4.994 4.994 0 007.72 24a5.135 5.135 0 001.755-.314l8.89-3.328a4.425 4.425 0 002.514-2.329 4.571 4.571 0 00.093-3.54zM8.306 21.765a.5.5 0 01-.389.229 2.995 2.995 0 01-2.008-5.381.5.5 0 01.437-.082 3.449 3.449 0 012.307 2.119 3.488 3.488 0 01-.347 3.115zm9.361-3.281l-1.405.529a.5.5 0 01-.641-.284 3.507 3.507 0 012.051-4.513 3.031 3.031 0 01.466-.136.5.5 0 01.553.273l.442.918a2.5 2.5 0 01.157.879 2.461 2.461 0 01-1.623 2.334zM12.661 8.391a1.264 1.264 0 101.6.789 1.264 1.264 0 00-1.6-.789z\"/><path d=\"M10.814 9.375a.5.5 0 00-.277-.5l-.957-.469a1.33 1.33 0 00-1.847 1.621l.656 1.924a1.329 1.329 0 002.451.159l.377-.766a.5.5 0 00-.033-.5 2.27 2.27 0 01-.37-1.47zM15.878 5.854a1.321 1.321 0 00-.766.671l-.471.955a.5.5 0 00.084.563 2.261 2.261 0 01.6 1.393.5.5 0 00.278.416l.767.377a1.33 1.33 0 001.78-.6 1.312 1.312 0 00.066-1.015l-.655-1.925a1.325 1.325 0 00-1.685-.83z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});