define("ember-svg-jar/inlined/shooting-rifle-target", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shooting-rifle-target</title><circle cx=\"5.5\" cy=\"5.5\" r=\"2.25\"/><path d=\"M5.5 11A5.5 5.5 0 100 5.5 5.506 5.506 0 005.5 11zm0-9.25A3.75 3.75 0 111.75 5.5 3.755 3.755 0 015.5 1.75zM23.891 5.477a1 1 0 00-1.346-.436l-.411.21a.4.4 0 01-.533-.173 1 1 0 00-1.781.908.4.4 0 01-.173.535l-11.87 6.057a1.99 1.99 0 00-.834.8l-.48.848a.994.994 0 01-.356.364L.983 17.671a2.011 2.011 0 00-.746 2.666l1.442 2.572a2.013 2.013 0 001.448 1.064 2.013 2.013 0 001.728-.539l5.357-5.292a2 2 0 00.6-1.214l.1-.743a.5.5 0 01.221-.353l5.313-3.508a1.493 1.493 0 00.537-1.876.3.3 0 01.137-.392l6.335-3.234a1 1 0 00.436-1.345z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});