define("ember-svg-jar/inlined/connect-device-exchange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>connect-device-exchange</title><path d=\"M9.5 14.25v2.25a1 1 0 01-1 1h-6a1 1 0 01-1-1V5a1 1 0 011-1h6a1 1 0 011 1v3.75a.25.25 0 00.25.25h1a.25.25 0 00.25-.25V4.5A2.5 2.5 0 008.5 2h-6A2.5 2.5 0 000 4.5v15A2.5 2.5 0 002.5 22h6a2.5 2.5 0 002.5-2.5v-5.25a.25.25 0 00-.25-.25h-1a.25.25 0 00-.25.25zM21.5 2h-6A2.5 2.5 0 0013 4.5v4.25a.25.25 0 00.25.25h1a.25.25 0 00.25-.25V5a1 1 0 011-1h6a1 1 0 011 1v11.5a1 1 0 01-1 1h-6a1 1 0 01-1-1v-2.25a.25.25 0 00-.25-.25h-1a.25.25 0 00-.25.25v5.25a2.5 2.5 0 002.5 2.5h6a2.5 2.5 0 002.5-2.5v-15A2.5 2.5 0 0021.5 2z\"/><path d=\"M16 12.5v.808a1 1 0 00.617.923 1.007 1.007 0 001.09-.216l1.793-1.793a1 1 0 000-1.414l-1.793-1.793A1 1 0 0016 9.722v.778H8v-.778a1 1 0 00-1.707-.707L4.5 10.808a1 1 0 000 1.414l1.793 1.793a1.053 1.053 0 001.09.216A1 1 0 008 13.308V12.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});