define("ember-svg-jar/inlined/meeting-team-laptop-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-team-laptop-man</title><path d=\"M23.5 11.5h-.75a.249.249 0 01-.25-.25V2a2 2 0 00-2-2h-13a2 2 0 00-2 2v6.44a.251.251 0 00.312.242A6.248 6.248 0 017.254 8.5a.25.25 0 00.246-.25v-6A.25.25 0 017.75 2h12.5a.25.25 0 01.25.25v9a.249.249 0 01-.25.25H12.1a.251.251 0 00-.249.261 5.811 5.811 0 01-.362 2.1.251.251 0 000 .146 2.86 2.86 0 01.061.281.249.249 0 00.247.208H21.5A2.5 2.5 0 0024 12a.5.5 0 00-.5-.5z\"/><circle cx=\"14.294\" cy=\"4.624\" r=\"1.625\"/><path d=\"M12 9.5h4.588a.409.409 0 00.383-.555 2.844 2.844 0 00-5.353 0A.408.408 0 0012 9.5zM9.846 14a.016.016 0 010-.015v-.007a4.836 4.836 0 00.469-2.477c-.212-1.061-1.58-1.47-2.79-1.5a3.312 3.312 0 00-2.495.773.247.247 0 01-.137.086 1.252 1.252 0 00-.734.47 3.059 3.059 0 00-.018 2.523.253.253 0 01-.029.206 1.577 1.577 0 00-.15 1.176 1.384 1.384 0 00.246.544.245.245 0 01.052.123 2.622 2.622 0 00.753 1.512.248.248 0 01.073.176v.834a.25.25 0 01-.163.234l-.393.146c-2.008.738-3.459 1.272-3.912 2.179A6.216 6.216 0 000 23.5a.5.5 0 00.5.5h13a.5.5 0 00.5-.5 6.216 6.216 0 00-.618-2.516c-.454-.908-1.9-1.442-3.914-2.18l-.391-.145a.25.25 0 01-.163-.234v-.835a.248.248 0 01.073-.176 2.622 2.622 0 00.752-1.514.255.255 0 01.052-.123 1.384 1.384 0 00.246-.544A1.539 1.539 0 009.846 14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});