define("ember-svg-jar/inlined/phone-action-add-user-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-add-user-1</title><path d=\"M11 13.75v4.75a1 1 0 01-1 1H3a1 1 0 01-1-1v-11a1 1 0 011-1h5.681a.24.24 0 00.187-.091 6.369 6.369 0 011.066-1.048.24.24 0 00.066-.3 4.478 4.478 0 01-.342-.875A.244.244 0 009.423 4H2.5A2.5 2.5 0 000 6.5v15A2.5 2.5 0 002.5 24h8a2.5 2.5 0 002.5-2.5v-7.75a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25z\"/><path d=\"M15.056 10.523A2.053 2.053 0 0117.08 9h.435a.461.461 0 00.5-.509c0-.47.019-.838.019-.838A4.941 4.941 0 0015.315 5.7a3.012 3.012 0 001.544-3.63A2.93 2.93 0 0014.954.148a3 3 0 00-2.325 5.519A4.812 4.812 0 009 10.277V11.5a.5.5 0 00.5.5h5.383a.245.245 0 00.234-.325 2.022 2.022 0 01-.061-1.152z\"/><path d=\"M23 10h-1.75a.25.25 0 01-.25-.25V8a1 1 0 00-2 0v1.75a.25.25 0 01-.25.25H17a1 1 0 000 2h1.75a.25.25 0 01.25.25V14a1 1 0 002 0v-1.75a.25.25 0 01.25-.25H23a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});