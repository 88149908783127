define("ember-svg-jar/inlined/tags-cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-cash</title><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zM16 21.249a.75.75 0 110-1.5h2.033a.592.592 0 00.221-1.141l-2.065-.826a2.084 2.084 0 01.361-3.98.249.249 0 00.2-.245v-.308a.75.75 0 011.5 0v.25a.25.25 0 00.25.25h.5a.75.75 0 010 1.5h-2.033a.592.592 0 00-.221 1.141l2.065.826a2.084 2.084 0 01-.361 3.98.249.249 0 00-.2.245v.308a.75.75 0 01-1.5 0V21.5a.25.25 0 00-.25-.25z\"/><path d=\"M5.422 19.5a1.972 1.972 0 001.406.582h.012a1.975 1.975 0 001.41-.6l1.217-1.241a.493.493 0 00.136-.367 7.976 7.976 0 018.2-8.363.486.486 0 00.363-.143l.939-.958A3.984 3.984 0 0020.086 6V2a2 2 0 00-2-2h-4.26a3.758 3.758 0 00-2.413 1L.586 11.834a2 2 0 000 2.828zm7.164-14a2 2 0 112 2 2 2 0 01-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});