define("ember-svg-jar/inlined/radio-stereo-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>radio-stereo-1</title><path d=\"M19.5 11h-1.446a.25.25 0 01-.234-.162L16.68 7.8a2.009 2.009 0 00-1.873-1.3H9.193A2.009 2.009 0 007.32 7.8l-1.14 3.04a.25.25 0 01-.234.16H4.5A4.505 4.505 0 000 15.5v2A6.508 6.508 0 006.5 24h11a6.508 6.508 0 006.5-6.5v-2a4.505 4.505 0 00-4.5-4.5zm-1 9a3 3 0 113-3 3 3 0 01-3 3zm-4.5.25a.75.75 0 010 1.5h-4a.75.75 0 010-1.5zm-4-5.75a1 1 0 011-1h2a1 1 0 010 2h-2a1 1 0 01-1-1zM8.5 17a3 3 0 11-3-3 3 3 0 013 3zm.633-8.338a.249.249 0 01.234-.162h5.266a.249.249 0 01.234.162l.75 2a.25.25 0 01-.234.338H8.617a.25.25 0 01-.234-.338zM3 6a1 1 0 002 0 1 1 0 011-1 1 1 0 000-2 3 3 0 00-3 3z\"/><path d=\"M1 6a1 1 0 001-1 3 3 0 013-3 1 1 0 000-2 5.006 5.006 0 00-5 5 1 1 0 001 1zM18 5a1 1 0 011 1 1 1 0 002 0 3 3 0 00-3-3 1 1 0 000 2z\"/><path d=\"M19 2a3 3 0 013 3 1 1 0 002 0 5.006 5.006 0 00-5-5 1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});