define("ember-svg-jar/inlined/pool-ball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pool-ball</title><path d=\"M22.948 7.09a1 1 0 00-.912-.59H1.964a1 1 0 00-.912.59 11.984 11.984 0 000 9.82 1 1 0 00.912.59h20.072a1 1 0 00.912-.59 11.984 11.984 0 000-9.82zM12.006 15.5a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5zM20.252 19.5H3.748a.5.5 0 00-.359.848 11.978 11.978 0 0017.222 0 .5.5 0 00-.359-.848zM3.748 4.5h16.5a.5.5 0 00.359-.848 11.978 11.978 0 00-17.222 0 .5.5 0 00.359.848z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});