define("ember-svg-jar/inlined/style-three-pin-x-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-x-remove</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.749 7.3 12.286 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm4.207 13.293a1 1 0 11-1.414 1.414l-2.439-2.439a.5.5 0 00-.708 0l-2.439 2.439a1 1 0 11-1.414-1.414l2.439-2.439a.5.5 0 000-.708L7.793 7.707a1 1 0 111.414-1.414l2.439 2.439a.5.5 0 00.708 0l2.439-2.439a1 1 0 111.414 1.414l-2.439 2.439a.5.5 0 000 .708z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});