define("ember-svg-jar/inlined/envelope-back-front", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>envelope-back-front</title><path d=\"M4.75 10.25h11.742a.25.25 0 00.25-.25l.008-8.375a.125.125 0 00-.213-.089l-6.7 6.7a1.889 1.889 0 01-2.673 0l-6.7-6.7a.125.125 0 00-.136-.027.126.126 0 00-.077.116v8.25a1.374 1.374 0 001.374 1.375h1.717a1.5 1.5 0 011.408-1z\"/><path d=\"M7.892 7.507a.86.86 0 001.215 0L15.766.848A.341.341 0 0015.849.5c-.075-.229-.285-.25-.475-.25H1.624c-.188 0-.4.021-.475.247a.347.347 0 00.083.351zM5.734 12.493L7.4 15.5a.5.5 0 00.438.257l12.823-.007a.5.5 0 00.435-.256l1.668-3a.5.5 0 00-.437-.743H6.171a.5.5 0 00-.437.742z\"/><path d=\"M21.973 15.979a1.5 1.5 0 01-1.31.771l-12.824.007a1.5 1.5 0 01-1.312-.772l-1.308-2.359a.25.25 0 00-.469.121v9a1 1 0 001 1h17a1 1 0 001-1v-9.002a.25.25 0 00-.469-.122z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});