define("ember-svg-jar/inlined/saving-bull-increase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>saving-bull-increase</title><path d=\"M23.75.5a.5.5 0 00-.5-.5h-5.5a.5.5 0 00-.35.849L19.042 2.5a.25.25 0 01.008.345l-1.709 1.876a.5.5 0 01-.429.159l-6.006-.752A3.481 3.481 0 008 5.127L.689 12.435a1.5 1.5 0 102.122 2.121l7.308-7.308a.5.5 0 01.415-.143l6.006.751a3.5 3.5 0 003.018-1.113l1.6-1.762a.247.247 0 01.179-.082.252.252 0 01.182.073L22.9 6.349a.5.5 0 00.354.147.489.489 0 00.191-.038A.5.5 0 0023.75 6z\"/><path d=\"M22.75 13.875a2.2 2.2 0 00-1.887-2.217H19.45c-.667 0-1.1-.943-1.294-1.5a.249.249 0 00-.187-.158.252.252 0 00-.235.077 3.924 3.924 0 00-1.114 2.384 2.84 2.84 0 00.323 1.342.25.25 0 01-.118.346l-.075.034a1.989 1.989 0 01-1 .212 1.487 1.487 0 01-.874-.191 3.923 3.923 0 00-.185-.094.25.25 0 01-.116-.34 2.866 2.866 0 00.305-1.309 3.924 3.924 0 00-1.114-2.384.251.251 0 00-.422.084c-.2.553-.627 1.5-1.294 1.5h-1.413a2.2 2.2 0 00-1.887 2.214 2.179 2.179 0 001.415 2.359l.365.183a.249.249 0 01.124.308 4.087 4.087 0 00-.25 1.394.5.5 0 00.11.313L12.565 21a.261.261 0 01.047.1l.5 2a.513.513 0 00.114.213c.481.535 2.366.7 2.526.7s2.045-.169 2.526-.7a.513.513 0 00.114-.213l.5-2a.261.261 0 01.047-.1l2.051-2.564a.5.5 0 00.11-.313 4.7 4.7 0 00-.219-1.42A.25.25 0 0121 16.4l.332-.166a2.179 2.179 0 001.418-2.359zM18 16.119a.75.75 0 11-.75.75.75.75 0 01.75-.75zm-4.5 0a.75.75 0 11-.75.75.75.75 0 01.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});