define("ember-svg-jar/inlined/scooter-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>scooter-3</title><path d=\"M22.469 7.75a1 1 0 00-1-1H17.75a.25.25 0 01-.25-.25v-1a5.5 5.5 0 00-11 0v1a.25.25 0 01-.25.25H2.531a1 1 0 000 2h2.662a.249.249 0 01.244.2L7.7 19.388a2.339 2.339 0 00.9 1.391.25.25 0 00.262.021.248.248 0 00.138-.223v-3.428a3 3 0 011.386-2.52.252.252 0 01.268 0l.453.281a1.646 1.646 0 00.827.238 2 2 0 00-1.934 2V22a2.005 2.005 0 004.01 0v-4.851a2.007 2.007 0 00-1.959-2.005 1.636 1.636 0 00.826-.242l.461-.286a.249.249 0 01.265 0 2.993 2.993 0 011.407 2.531v3.417a.25.25 0 00.4.2 2.334 2.334 0 00.891-1.379L18.54 8.948a.25.25 0 01.245-.2h2.684a1 1 0 001-.998zM7.377 10.833L7 9.09a1.144 1.144 0 01-.016-.408l3.375 1.283a.265.265 0 01.03.039l.553 3.038zM8.75 6.808a.252.252 0 01-.177-.074.248.248 0 01-.073-.176V5.5a3.5 3.5 0 017 0v1.057a.25.25 0 01-.25.25zm8.224 2.286l-.374 1.739-3.568 2.209.543-2.989a.212.212 0 01.043-.089l3.372-1.282a1.168 1.168 0 01-.016.412z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});