define("ember-svg-jar/inlined/arrow-thick-down-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-down-4</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-5.516 10.689a.5.5 0 01.854.353v3.071a.5.5 0 01-.163.37l-5 4.545a.5.5 0 01-.672 0L7 14.483a.5.5 0 01-.163-.37V11.04a.5.5 0 01.853-.353l2.724 2.721a.25.25 0 00.426-.177v-7.9a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v7.9a.25.25 0 00.427.177z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});