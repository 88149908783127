define("ember-svg-jar/inlined/dog-pitbull", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dog-pitbull</title><path d=\"M22.844 4.99l-.115-.29C22.19 3.318 21.189.747 18.22.747h-3.27a1.087 1.087 0 00-.985.636 1.3 1.3 0 00-.119.369 7.263 7.263 0 00-3.692 0 1.3 1.3 0 00-.119-.369A1.087 1.087 0 009.05.747H5.78C2.811.747 1.81 3.318 1.271 4.7l-.115.29a1.933 1.933 0 00.034 1.87 1.78 1.78 0 001.13.707 8.629 8.629 0 00-.82 3.681A8.773 8.773 0 002.913 16a4.883 4.883 0 00.537 4.318 4.287 4.287 0 004.071 1.665A7.753 7.753 0 0012 23.253a7.753 7.753 0 004.479-1.275 4.285 4.285 0 004.071-1.665 4.883 4.883 0 00.536-4.322 8.668 8.668 0 00.594-8.425 1.783 1.783 0 001.13-.707 1.932 1.932 0 00.034-1.869zM12 18.457a7.778 7.778 0 002.01 2.507 7.805 7.805 0 01-4.02 0A7.778 7.778 0 0012 18.457zm5.009 1.549c-2.915 0-4.026-4.472-4.036-4.517a1 1 0 00-1.946 0c-.01.045-1.121 4.517-4.036 4.517-2.3 0-2.625-2.134-2.187-3.361.576-1.624 2.413-3.116 5.041-4.092a1 1 0 00.651-.938c0-.406.657-.878 1.5-.878s1.5.472 1.5.878a1 1 0 00.651.938c2.628.976 4.465 2.468 5.042 4.093.451 1.244.122 3.36-2.18 3.36zm-.259-9.019A1.25 1.25 0 1118 9.737a1.25 1.25 0 01-1.25 1.25zm-9.5-2.5A1.25 1.25 0 116 9.737a1.25 1.25 0 011.25-1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});