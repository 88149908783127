define("ember-svg-jar/inlined/phone-action-ring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-ring</title><path d=\"M15.5 13a.5.5 0 01-.5-.5v-2a3 3 0 00-6 0v2a.5.5 0 01-.5.5.5.5 0 000 1h7a.5.5 0 000-1zM12.867 15h-1.733a.5.5 0 00-.433.75 1.5 1.5 0 002.6 0 .5.5 0 00-.434-.75z\"/><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});