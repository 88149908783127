define("ember-svg-jar/inlined/diagram-dash-rise-steady", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-dash-rise-steady</title><path d=\"M8.412 16.136a1.452 1.452 0 01-.741.585 1.44 1.44 0 10.942 2.721 4.342 4.342 0 002.221-1.742 1.44 1.44 0 00-2.422-1.56zM1.44 19.68h1.92a1.44 1.44 0 100-2.88H1.44a1.44 1.44 0 000 2.88zM16.389 10.08a1.44 1.44 0 100-2.88h-1.92a1.44 1.44 0 000 2.88zM11.52 12.205v-.685a1.438 1.438 0 01.129-.6 1.44 1.44 0 10-2.619-1.2 4.285 4.285 0 00-.39 1.8v.685a1.44 1.44 0 002.88 0zM23.577 7.621L20.7 4.742a1.44 1.44 0 00-2.46 1.018v5.76a1.44 1.44 0 00.889 1.33 1.415 1.415 0 00.551.11 1.435 1.435 0 001.018-.422l2.879-2.879a1.439 1.439 0 000-2.038z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});