define("ember-svg-jar/inlined/police-hat-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-hat-3</title><path d=\"M19.709 18.045A.487.487 0 0019.5 18h-15a.5.5 0 00-.207.045 1.947 1.947 0 00-1.161 1.537 1.878 1.878 0 00.788 1.744 13.543 13.543 0 0016.159 0 1.876 1.876 0 00.788-1.744 1.943 1.943 0 00-1.158-1.537zM12 0C5.521 0 .25 3.6.25 8.029a5.052 5.052 0 001.737 3.84.5.5 0 00.337.131h19.353a.5.5 0 00.336-.131 5.052 5.052 0 001.737-3.84C23.75 3.6 18.479 0 12 0zm2.221 6.578l.5.535a.644.644 0 01-.621 1.068l-.7-.165a.48.48 0 00-.566.328l-.213.7a.64.64 0 01-1.226 0l-.213-.7a.48.48 0 00-.565-.328l-.717.165a.645.645 0 01-.614-1.068l.5-.535a.481.481 0 000-.656l-.5-.535A.645.645 0 019.9 4.319l.709.164a.478.478 0 00.565-.328l.213-.7a.64.64 0 011.226 0l.213.7a.479.479 0 00.566.328l.709-.164a.644.644 0 01.613 1.068l-.5.535a.484.484 0 00.007.656z\"/><rect x=\"3.25\" y=\"13.5\" width=\"17.5\" height=\"3\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});