define("ember-svg-jar/inlined/optimization-timer-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>optimization-timer-1</title><path d=\"M21 0H3a3 3 0 00-3 3v15a3 3 0 003 3 1 1 0 000-2 1 1 0 01-1-1V7.5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5V18a1 1 0 01-1 1 1 1 0 000 2 3 3 0 003-3V3a3 3 0 00-3-3zM4 4.5a1 1 0 111-1 1 1 0 01-1 1zm3 0a1 1 0 111-1 1 1 0 01-1 1zm3 0a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M5.5 17.5a6.5 6.5 0 1011.513-4.133.249.249 0 01.016-.335l.5-.5a.75.75 0 00-1.06-1.061l-.576.576a.25.25 0 01-.315.031 6.465 6.465 0 00-2.616-1.005.25.25 0 01-.213-.247V10.5a.25.25 0 01.25-.25h.5a.75.75 0 000-1.5h-3a.75.75 0 000 1.5H11a.25.25 0 01.25.25v.325a.25.25 0 01-.213.247 6.451 6.451 0 00-2.616 1.005.25.25 0 01-.315-.031l-.576-.576a.75.75 0 10-1.06 1.061l.5.5a.249.249 0 01.016.335A6.475 6.475 0 005.5 17.5zM12 13a4.5 4.5 0 11-4.5 4.5A4.505 4.505 0 0112 13z\"/><path d=\"M12 18.25a.747.747 0 00.53-.219l1.5-1.5a.75.75 0 10-1.06-1.061l-1.5 1.5a.75.75 0 00.53 1.28z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});