define("ember-svg-jar/inlined/touchpad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>touchpad</title><path d=\"M10.123 18.26a3.047 3.047 0 012.577-1.718c.067-.006.136-.011.2-.013a.25.25 0 00.241-.25v-2.4a3.75 3.75 0 117.5 0v2.232a.249.249 0 00.185.241c.423.113 1.463.393 2 .545a.25.25 0 00.319-.24V8.626a2 2 0 00-2-2h-12a2 2 0 00-2 2v9.5a2 2 0 002 2h.509a.25.25 0 00.247-.287 2.8 2.8 0 01.222-1.579zM8.144 4.126a1 1 0 00-1-1 4 4 0 00-4 4 1 1 0 102 0 2 2 0 012-2 1 1 0 001-1z\"/><path d=\"M6.644 2.126a1 1 0 100-2 6.508 6.508 0 00-6.5 6.5 1 1 0 002 0 4.5 4.5 0 014.5-4.5zM22.349 18.038l-2.768-.725a.251.251 0 01-.187-.242v-3.2a2.5 2.5 0 10-5 0v3.867a.249.249 0 01-.34.233 2.791 2.791 0 00-1.245-.189 1.819 1.819 0 00-1.55.991 1.7 1.7 0 00.261 1.764l2 3a.75.75 0 001.248-.832s-1.467-2.249-1.974-3.039a.25.25 0 01.186-.384 1.714 1.714 0 011.547.933.75.75 0 001.367-.426v-5.913a1 1 0 012 0v3.967a.75.75 0 00.56.725l3.514.921a.489.489 0 01.414.55l-.891 2.861a.75.75 0 001.429.456l.825-2.588a2 2 0 00-1.396-2.73z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});