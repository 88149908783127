define("ember-svg-jar/inlined/ecology-globe-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ecology-globe-house</title><path d=\"M5.5 6H7a.5.5 0 01.5.5v3a1 1 0 001 1H10a.5.5 0 00.5-.5V7.5a1.5 1.5 0 013 0V10a.5.5 0 00.5.5h1.5a1 1 0 001-1v-3A.5.5 0 0117 6h1.5a.5.5 0 00.31-.893l-2.12-1.674a.5.5 0 01-.19-.392V.5A.5.5 0 0016 0h-1a.5.5 0 00-.5.5v.685a.25.25 0 01-.4.2L12.622.217a1 1 0 00-1.244 0L5.19 5.108A.5.5 0 005.5 6zM23.723 11.809A1 1 0 0023 11.5H1a1 1 0 00-1 1.045 12.012 12.012 0 0024 0 1 1 0 00-.277-.736zM3.151 16a.5.5 0 01-.465-.317 9.908 9.908 0 01-.477-1.583.5.5 0 01.49-.6h2.353a.5.5 0 01.5.465q.051.75.154 1.464a.5.5 0 01-.5.571zm3.941 4.519a.25.25 0 01-.3.03 10 10 0 01-2.487-2.139A.25.25 0 014.5 18h1.4a.5.5 0 01.476.345 13.25 13.25 0 00.77 1.877.25.25 0 01-.054.297zm3.908.747a.251.251 0 01-.4.2 7.177 7.177 0 01-2.1-3.125.251.251 0 01.234-.341H10.5a.5.5 0 01.5.5zm0-5.766a.5.5 0 01-.5.5H8.248a.5.5 0 01-.493-.415 17.217 17.217 0 01-.193-1.544.5.5 0 01.5-.541H10.5a.5.5 0 01.5.5zm4.5 2.841a7.168 7.168 0 01-2.1 3.125.251.251 0 01-.4-.2V18.5a.5.5 0 01.5-.5h1.766a.251.251 0 01.233.341zm.939-4.3a17.217 17.217 0 01-.193 1.544.5.5 0 01-.493.415H13.5a.5.5 0 01-.5-.5V14a.5.5 0 01.5-.5h2.44a.5.5 0 01.5.541zM19.7 18.41a10 10 0 01-2.487 2.139.25.25 0 01-.353-.327 13.37 13.37 0 00.771-1.877A.5.5 0 0118.1 18h1.4a.25.25 0 01.192.41zm2.1-4.3a9.925 9.925 0 01-.476 1.578.5.5 0 01-.466.317h-2.067a.5.5 0 01-.5-.571q.1-.714.155-1.464a.5.5 0 01.5-.465H21.3a.5.5 0 01.489.605z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});