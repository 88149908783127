define("ember-svg-jar/inlined/school-board-maths", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>school-board-maths</title><path d=\"M23 14.5H1a1 1 0 00-1 1v.5a1.5 1.5 0 001.125 1.453.5.5 0 01.375.484V23a1 1 0 002 0v-.75a.5.5 0 01.5-.5h16a.5.5 0 01.5.5V23a1 1 0 002 0v-5.063a.5.5 0 01.375-.484A1.5 1.5 0 0024 16v-.5a1 1 0 00-1-1zm-2.5 4.75a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5V18a.5.5 0 01.5-.5h16a.5.5 0 01.5.5zM2.5 13h19a.5.5 0 00.5-.5v-11A1.5 1.5 0 0020.5 0h-17A1.5 1.5 0 002 1.5v11a.5.5 0 00.5.5zm14.6-7.817a.253.253 0 00-.273.054l-.293.293a.75.75 0 11-1.06-1.06l1.323-1.324a1.348 1.348 0 01.957-.4 1 1 0 011 1v4.5a.5.5 0 00.5.5H20a.75.75 0 010 1.5h-4a.75.75 0 010-1.5h.75a.5.5 0 00.5-.5V5.414a.251.251 0 00-.15-.231zM11 5.75h.25a.5.5 0 00.5-.5V5a.75.75 0 011.5 0v.25a.5.5 0 00.5.5H14a.75.75 0 010 1.5h-.25a.5.5 0 00-.5.5V8a.75.75 0 01-1.5 0v-.25a.5.5 0 00-.5-.5H11a.75.75 0 010-1.5zm-4.5-1.5A1.252 1.252 0 005.25 5.5a.75.75 0 01-1.5 0 2.75 2.75 0 114.4 2.2l-.8.6a.25.25 0 00.15.45h1a.75.75 0 010 1.5h-4a.75.75 0 01-.45-1.35l3.2-2.4a1.25 1.25 0 00-.75-2.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});