define("ember-svg-jar/inlined/book-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-share</title><path d=\"M15.749 21.918a.252.252 0 00-.318-.158A4.434 4.434 0 0114 22a4.5 4.5 0 010-9 4.3 4.3 0 011.244.19.2.2 0 00.256-.19A4.505 4.505 0 0120 8.5a1.8 1.8 0 01.221.015.251.251 0 00.279-.249V6a2 2 0 00-1.6-1.96.5.5 0 01-.4-.489V2a2 2 0 00-2-2H4a3 3 0 00-3 3v18a3 3 0 003 3h12.566a.249.249 0 00.181-.422 4.461 4.461 0 01-.998-1.66zM3 3a1 1 0 011-1h12.25a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H4a1 1 0 01-1-1z\"/><path d=\"M23 20.5a3 3 0 00-3-3 2.972 2.972 0 00-1.843.64.5.5 0 01-.533.054l-.349-.175A.5.5 0 0117 17.55a2.572 2.572 0 00-.066-.632.5.5 0 01.188-.509l.951-.713a.5.5 0 01.534-.042A2.967 2.967 0 0020 16a3 3 0 10-3-3 2.655 2.655 0 00.067.582.5.5 0 01-.188.509l-.951.713a.5.5 0 01-.534.042A2.967 2.967 0 0014 14.5a3 3 0 000 6 2.972 2.972 0 001.843-.64.5.5 0 01.533-.054l.349.175a.5.5 0 01.275.469v.05a3 3 0 006 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});