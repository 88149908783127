define("ember-svg-jar/inlined/face-id-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>face-id-2</title><path d=\"M13.041 14.906a1.863 1.863 0 01-2.082 0 .751.751 0 00-.918 1.188 3.253 3.253 0 003.918 0 .751.751 0 00-.918-1.188z\"/><circle cx=\"14\" cy=\"12.5\" r=\"1\"/><circle cx=\"10\" cy=\"12.5\" r=\"1\"/><path d=\"M19 13v-2.5a7 7 0 00-14 0V13a7 7 0 0014 0zm-7 5a5.006 5.006 0 01-5-5v-2.25a.25.25 0 01.25-.25H8c1.291 0 3.908.318 5.838-1.813A.22.22 0 0114 8.615a.217.217 0 01.162.072 5.281 5.281 0 002.673 1.6.219.219 0 01.165.213V13a5.006 5.006 0 01-5 5zM6.5 22H2.25a.25.25 0 01-.25-.25V17.5a1 1 0 00-2 0V22a2 2 0 002 2h4.5a1 1 0 000-2zM1 7.5a1 1 0 001-1V2.25A.25.25 0 012.25 2H6.5a1 1 0 000-2H2a2 2 0 00-2 2v4.5a1 1 0 001 1zM23 16.5a1 1 0 00-1 1v4.25a.25.25 0 01-.25.25H17.5a1 1 0 000 2H22a2 2 0 002-2v-4.5a1 1 0 00-1-1zM22 0h-4.5a1 1 0 000 2h4.25a.25.25 0 01.25.25V6.5a1 1 0 002 0V2a2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});