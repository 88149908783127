define("ember-svg-jar/inlined/pet-tracking-cat-signal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pet-tracking-cat-signal</title><circle cx=\"17.5\" cy=\"8.765\" r=\"1.75\"/><path d=\"M12.621 3.709a7.823 7.823 0 019.758 0 1 1 0 101.242-1.568 9.821 9.821 0 00-12.242 0 1 1 0 101.242 1.568z\"/><path d=\"M20.5 6.728a1 1 0 00.647-1.762 5.621 5.621 0 00-7.293 0 1 1 0 101.293 1.525 3.586 3.586 0 014.707 0 1 1 0 00.646.237zM23.347 15h-5.49a1.313 1.313 0 01-.843-.3l-.385-.334a.5.5 0 00-.681.024l-6.127 6.123a.5.5 0 01-.761-.063 2.146 2.146 0 00-.376-.4.5.5 0 01-.035-.74l5.974-5.975a.5.5 0 00-.023-.735l-4.2-3.641a12.334 12.334 0 00-2.57-3.211.955.955 0 00-1.6.707v1.314a.25.25 0 01-.23.249 4.024 4.024 0 00-3.7 2.834 6.262 6.262 0 01-.524.985A8.029 8.029 0 01.49 13.285a1.415 1.415 0 00-.434 1.464l.353 1.211a2.331 2.331 0 002.227 1.671h2.169c.161.02.447.319.618.5l1.2 1.2a.25.25 0 01-.081.407A2.114 2.114 0 109.3 22.494a.251.251 0 01.408-.081l1.439 1.441A.5.5 0 0011.5 24h11.847a.5.5 0 00.5-.5v-8a.5.5 0 00-.5-.5zm-18-2.5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});