define("ember-svg-jar/inlined/cash-payment-coin-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-payment-coin-1</title><path d=\"M19.537 1.917a2 2 0 00-1.167-.293l-7.177.422a2 2 0 00-1.211.5L6.4 5.732a2 2 0 00-.569.862l-.125.375a.249.249 0 01-.165.16 7.793 7.793 0 109.913 8.911 1 1 0 00-1.946-.453 6.149 6.149 0 01-3.415 4.346 5.829 5.829 0 01-7.864-7 5.648 5.648 0 013.4-3.748 5.986 5.986 0 013.761-.173 1 1 0 00.551-1.923q-.248-.071-.5-.123a.251.251 0 01-.088-.453l1.63-1.089a2 2 0 011.117-.337h3.76a2 2 0 011.712.966l.358.591a2 2 0 01.077 1.929l-.324.648a2 2 0 01-2.183 1.067L12.571 9.7a1.219 1.219 0 00-1.094.277 2.009 2.009 0 00-.576 1.135 1.191 1.191 0 00.793 1.241l5.161 1.985a1.982 1.982 0 001.31.04l5.659-1.769a.249.249 0 00.176-.237V4.8a.253.253 0 00-.119-.213z\"/><path d=\"M7.79 19.629a.75.75 0 00.75-.75v-.307a.249.249 0 01.2-.245 2.084 2.084 0 00.36-3.981l-2.062-.825a.592.592 0 01.219-1.142H9.29a.75.75 0 000-1.5h-.5a.249.249 0 01-.25-.25v-.25a.75.75 0 10-1.5 0v.308a.25.25 0 01-.2.245 2.084 2.084 0 00-.36 3.981l2.062.825a.591.591 0 01-.219 1.141H6.29a.75.75 0 100 1.5h.5a.25.25 0 01.25.25v.25a.75.75 0 00.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});