define("ember-svg-jar/inlined/squircle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#fff\" d=\"M0 18C0 0 0 0 18 0s18 0 18 18 0 18-18 18S0 36 0 18\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36"
    }
  };
});