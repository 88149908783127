define("ember-svg-jar/inlined/car-garage-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-garage-2</title><path d=\"M6.5 22h-3a.5.5 0 00-.5.5v.5a1 1 0 001 1h2a1 1 0 001-1v-.5a.5.5 0 00-.5-.5zM20.5 22h-3a.5.5 0 00-.5.5v.5a1 1 0 001 1h2a1 1 0 001-1v-.5a.5.5 0 00-.5-.5zM22 17.958a1.24 1.24 0 00-.366-.884l-1.224-1.222a.5.5 0 01-.111-.168l-2-4.989a1.491 1.491 0 00-1.392-.943H7.089A1.491 1.491 0 005.7 10.7l-2 4.989a.5.5 0 01-.111.168l-1.221 1.217a1.24 1.24 0 00-.368.884v1.793A1.25 1.25 0 003.251 21h17.5A1.25 1.25 0 0022 19.751zM6.966 14.752a.5.5 0 01-.464-.686l.8-2a.5.5 0 01.464-.314h8.468a.5.5 0 01.464.314l.8 2a.5.5 0 01-.464.686z\"/><path d=\"M23.736 11.365a1 1 0 00-.064-1.412L13.348.527a1.987 1.987 0 00-2.7 0L.328 9.953a1 1 0 001.348 1.477l9.987-9.119a.5.5 0 01.674 0l9.987 9.119a1 1 0 001.412-.065z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});