define("ember-svg-jar/inlined/armchair-hanging", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>armchair-hanging</title><path d=\"M18.081 15.684a.5.5 0 00-.244-.3A12.339 12.339 0 0012 14a12.339 12.339 0 00-5.837 1.38.5.5 0 00-.2.689A6.859 6.859 0 0012 19.5a6.859 6.859 0 006.034-3.431.5.5 0 00.047-.385z\"/><path d=\"M13.424 2.61A.5.5 0 0113 2.116V1a1 1 0 00-2 0v1.116a.5.5 0 01-.424.494A10.545 10.545 0 002 13.25C2 19.178 6.486 24 12 24s10-4.822 10-10.75a10.545 10.545 0 00-8.576-10.64zM12 22c-4.411 0-8-3.925-8-8.75S7.589 4.5 12 4.5s8 3.925 8 8.75S16.411 22 12 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});