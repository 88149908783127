define("ember-svg-jar/inlined/read-email-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-email-circle</title><path d=\"M12 0a12 12 0 1012 12A12 12 0 0012 0zm6.5 15.5A1.5 1.5 0 0117 17H7a1.5 1.5 0 01-1.5-1.5v-4.739a.249.249 0 01.382-.212l4.925 3.078a2.261 2.261 0 002.385 0l4.925-3.078a.249.249 0 01.254-.007.251.251 0 01.129.219zm-.035-6.937L12.4 12.355a.754.754 0 01-.795 0L5.539 8.563A.073.073 0 015.5 8.5 1.5 1.5 0 017 7h10a1.5 1.5 0 011.5 1.5.077.077 0 01-.031.063z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});