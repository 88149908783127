define("ember-svg-jar/inlined/pin-x-mark-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin-x-mark-2</title><path d=\"M15.874 21.263l.942-.315a1 1 0 10-.632-1.9l-4.105 1.368a.251.251 0 01-.158 0l-4.1-1.368a1 1 0 10-.632 1.9l.942.315a.25.25 0 010 .474l-.942.314a1 1 0 10.632 1.9l4.1-1.368a.251.251 0 01.158 0l4.105 1.368a1 1 0 10.632-1.9l-.942-.314a.25.25 0 010-.474z\"/><path d=\"M10.95 15.994a.5.5 0 01.05.217v2.039a1 1 0 002 0v-2.039a.5.5 0 01.05-.217 19.552 19.552 0 011.615-2.5C16.151 11.409 18 8.818 18 6A6 6 0 006 6c0 2.818 1.849 5.409 3.335 7.49a19.552 19.552 0 011.615 2.504zM12 3.5A2.5 2.5 0 119.5 6 2.5 2.5 0 0112 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});