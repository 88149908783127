define("ember-svg-jar/inlined/school-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>school-building</title><path d=\"M23 13h-5.5a.5.5 0 01-.5-.5V11a5.01 5.01 0 00-3.636-4.811.5.5 0 01-.364-.481V5a.5.5 0 01.5-.5h2.253A1.252 1.252 0 0017 3.25v-2A1.252 1.252 0 0015.753 0h-4A.75.75 0 0011 .75v4.958a.5.5 0 01-.363.481A5.009 5.009 0 007 11v1.5a.5.5 0 01-.5.5H1a1.013 1.013 0 00-1 1v9a1 1 0 001 1h9.5a.5.5 0 00.5-.5V19a1 1 0 012 0v4.5a.5.5 0 00.5.5H23a.989.989 0 001-1v-9a1 1 0 00-1-1zM5.753 21a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm0-4a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zM12 12.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm9.75 8.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm0-4a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h2a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});