define("ember-svg-jar/inlined/surveillance-camera", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>surveillance-camera</title><path d=\"M23.288 2.885A1 1 0 0022.5 2.5h-21a1 1 0 00-.97 1.243l.5 2.5A1 1 0 002 7h20a1 1 0 00.97-.757l.5-2.5a1 1 0 00-.182-.858zM12 12.25A3.755 3.755 0 008.25 16v4.421a.5.5 0 00.328.469 9.9 9.9 0 006.844 0 .5.5 0 00.328-.469V16A3.755 3.755 0 0012 12.25zM12 19a2 2 0 112-2 2 2 0 01-2 2z\"/><path d=\"M21.161 8.5H2.839a.5.5 0 00-.482.368 9.944 9.944 0 003.59 10.573.5.5 0 00.8-.4V16a5.25 5.25 0 0110.5 0v3.044a.5.5 0 00.8.4 9.944 9.944 0 003.59-10.573.5.5 0 00-.476-.371z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});