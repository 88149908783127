define("ember-svg-jar/inlined/table-lamp-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>table-lamp-4</title><path d=\"M21.718 8.175l-1.556-7A1.509 1.509 0 0018.7 0H5.3a1.491 1.491 0 00-1.462 1.175l-1.556 7A1.5 1.5 0 003.746 10h16.507a1.5 1.5 0 001.465-1.825zM14.047 11.5H9.893a1 1 0 00-.9.573C7.931 14.313 6.97 16.989 6.97 18.5a5.274 5.274 0 005 5.5 5.274 5.274 0 005-5.5c0-1.511-.96-4.187-2.018-6.427a1 1 0 00-.905-.573z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});