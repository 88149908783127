define("ember-svg-jar/inlined/book-open-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-open-text</title><path d=\"M24 3.009a2.021 2.021 0 00-.609-1.441 1.942 1.942 0 00-1.436-.56c-3.84.114-7.7.868-9.651 2.308a.5.5 0 01-.594 0C9.76 1.877 5.89 1.122 2.045 1.008a1.95 1.95 0 00-1.436.56A2.021 2.021 0 000 3.009v14.5a2 2 0 001.955 2c5.973.171 9.054 1.821 9.054 2.486a1 1 0 102 0c0-.666 3.075-2.315 9.037-2.486a2 2 0 001.955-2zM2.477 17.526a.5.5 0 01-.479-.5l-.012-13.5a.5.5 0 01.523-.5c5.61.255 8.5 1.822 8.5 2.466v13.089a.5.5 0 01-.7.457 22.968 22.968 0 00-7.832-1.512zm19.511-.5a.5.5 0 01-.478.5 22.879 22.879 0 00-7.8 1.511.5.5 0 01-.7-.458V5.493c0-.643 2.884-2.211 8.47-2.464a.5.5 0 01.523.5z\"/><path d=\"M9.268 6.936a24.043 24.043 0 00-5.181-1.02.75.75 0 10-.145 1.493 22.571 22.571 0 014.849.949.75.75 0 00.477-1.422zM9.268 11.109a23.957 23.957 0 00-5.181-1.02.75.75 0 10-.145 1.493 22.485 22.485 0 014.849.949.75.75 0 00.477-1.422zM9.268 15.282a23.978 23.978 0 00-5.181-1.021.751.751 0 00-.145 1.494 22.389 22.389 0 014.849.949.75.75 0 00.477-1.422zM15.226 8.358a22.569 22.569 0 014.85-.949.75.75 0 00-.146-1.493 24.043 24.043 0 00-5.181 1.02.75.75 0 00.477 1.422zM15.226 12.531a22.483 22.483 0 014.85-.949.75.75 0 00-.146-1.493 23.97 23.97 0 00-5.181 1.02.75.75 0 00.477 1.422zM15.226 16.7a22.387 22.387 0 014.85-.949.751.751 0 00-.146-1.494 23.978 23.978 0 00-5.181 1.021.75.75 0 00.477 1.422z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});