define("ember-svg-jar/inlined/beard-style-shaving-machine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beard-style-shaving-machine</title><rect x=\"10\" y=\"15.75\" width=\"4\" height=\"3\" rx=\".5\" ry=\".5\"/><path d=\"M18 0a1 1 0 00-1 1v2a.5.5 0 01-1 0V1a1 1 0 00-2 0v2a.5.5 0 01-1 0V1a1 1 0 00-2 0v2a.5.5 0 01-1 0V1a1 1 0 00-2 0v2a.5.5 0 01-1 0V1a1 1 0 00-2 0v3a.975.975 0 00.6.895.5.5 0 01.274.3l.426 1.28A1.5 1.5 0 007.721 7.5h8.558A1.5 1.5 0 0017.7 6.475l.426-1.28a.5.5 0 01.275-.3A.975.975 0 0019 4V1a1 1 0 00-1-1zM16.876 8.68a.5.5 0 00-.384-.18H7.508a.5.5 0 00-.384.18v.006a3.758 3.758 0 00-.869 2.4v7.164a5.75 5.75 0 0011.5 0v-7.163a3.758 3.758 0 00-.869-2.4zM15 20.3a.5.5 0 01-.113.318 3.713 3.713 0 01-5.774 0A.5.5 0 019 20.3v-5.8a3 3 0 016 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});