define("ember-svg-jar/inlined/clean-car-cable-charge-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>clean-car-cable-charge-1</title><circle cx=\"5.5\" cy=\"22\" r=\"2\"/><circle cx=\"18.5\" cy=\"22\" r=\"2\"/><path d=\"M18.854 16h-.224a1.877 1.877 0 01-.7-.879l-.743-1.856a1 1 0 10-1.857.742l.663 1.65a.25.25 0 01-.232.343H13a3 3 0 01-3-3v-1.5a.5.5 0 00-.5-.5h-.36a2.508 2.508 0 00-2.452 2.01l-.356 1.784A1.5 1.5 0 014.86 16H3.5A2.5 2.5 0 001 18.5v2a1.5 1.5 0 00.827 1.341.5.5 0 00.718-.361 3 3 0 015.924.092.5.5 0 00.5.428h6.072a.5.5 0 00.494-.428 3 3 0 015.925-.092.5.5 0 00.717.361A1.5 1.5 0 0023 20.5v-.354A4.15 4.15 0 0018.854 16z\"/><path d=\"M11.408 8.033a.5.5 0 01.342.474V12a1 1 0 002 0V8.507a.5.5 0 01.342-.474A4.259 4.259 0 0017 4a.75.75 0 00-.75-.75.5.5 0 01-.5-.5V1a1 1 0 00-2 0v1.75a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v1.75a.5.5 0 01-.5.5.75.75 0 00-.75.75 4.258 4.258 0 002.908 4.033z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});