define("ember-svg-jar/inlined/barbecue-pot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>barbecue-pot</title><path d=\"M18.2 11.713a.25.25 0 00-.353-.14 12.639 12.639 0 01-1.808.77.25.25 0 00-.145.126.256.256 0 00-.012.192l1.9 5.508a.25.25 0 01-.236.331H6.457a.25.25 0 01-.236-.331l1.9-5.508a.249.249 0 00-.157-.318 12.639 12.639 0 01-1.808-.77.25.25 0 00-.353.14L2.14 22.343a1.25 1.25 0 00.774 1.589 1.237 1.237 0 00.407.068 1.25 1.25 0 001.179-.843l.685-1.988A.25.25 0 015.424 21h13.152a.25.25 0 01.236.169l.685 1.988a1.25 1.25 0 001.182.843 1.237 1.237 0 00.407-.068 1.25 1.25 0 00.774-1.589z\"/><path d=\"M22.61.5A1.5 1.5 0 0021.5 0H2.505A1.5 1.5 0 001.39.5a1.52 1.52 0 00-.381 1.178 11.06 11.06 0 0021.982 0A1.52 1.52 0 0022.61.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});