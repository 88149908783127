define("ember-svg-jar/inlined/synchronize-arrows-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>synchronize-arrows-square</title><path d=\"M6.5 5.248h4.25A.25.25 0 0111 5.5v1a.5.5 0 00.288.453A.507.507 0 0011.5 7a.494.494 0 00.32-.116l3-2.5a.5.5 0 000-.768l-3-2.5A.5.5 0 0011 1.5v1a.25.25 0 01-.25.25H6.5A4.255 4.255 0 002.25 7v10a4.255 4.255 0 004.25 4.25 1.25 1.25 0 000-2.5A1.752 1.752 0 014.75 17V7A1.752 1.752 0 016.5 5.248zM12.18 22.882a.494.494 0 00.32.118.507.507 0 00.212-.047A.5.5 0 0013 22.5v-1a.25.25 0 01.25-.25h4.25A4.255 4.255 0 0021.75 17V7a4.255 4.255 0 00-4.25-4.25 1.25 1.25 0 000 2.5A1.752 1.752 0 0119.25 7v10a1.752 1.752 0 01-1.75 1.75h-4.25a.25.25 0 01-.25-.25v-1a.5.5 0 00-.82-.384l-3 2.5a.5.5 0 000 .768z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});