define("ember-svg-jar/inlined/movies-reel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>movies-reel</title><path d=\"M20 18H3.5a1.5 1.5 0 010-3h4.523a.25.25 0 00.192-.41 9.568 9.568 0 01-1.009-1.462.252.252 0 00-.219-.128H3.5a3.5 3.5 0 000 7H20a1 1 0 010 2h-9a1 1 0 000 2h9a3 3 0 000-6z\"/><path d=\"M15.5 17A8.5 8.5 0 107 8.5a8.509 8.509 0 008.5 8.5zm3.807-9.729a.25.25 0 01.148-.309l1.867-.726a.25.25 0 01.327.153 6.53 6.53 0 010 4.223.25.25 0 01-.327.152l-1.867-.726a.249.249 0 01-.147-.31 4.015 4.015 0 00-.001-2.457zm-5.919-4.919a6.519 6.519 0 014.224 0 .248.248 0 01.152.326l-.726 1.867a.249.249 0 01-.31.147 4.015 4.015 0 00-2.457 0 .25.25 0 01-.309-.147l-.726-1.867a.249.249 0 01.152-.326zM17.5 8.5a2 2 0 11-2-2 2 2 0 012 2zm-4.264 5.822l.726-1.867a.249.249 0 01.31-.147 4.015 4.015 0 002.457 0 .25.25 0 01.309.147l.726 1.867a.249.249 0 01-.152.327 6.519 6.519 0 01-4.224 0 .248.248 0 01-.152-.327zM9.678 6.236l1.867.726a.249.249 0 01.147.31 4.015 4.015 0 000 2.457.25.25 0 01-.148.309l-1.867.726a.25.25 0 01-.327-.153c-.61-1.79-.332-4.639.328-4.375z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});