define("ember-svg-jar/inlined/outdoors-barbeque", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-barbeque</title><path d=\"M19.75 3.25H4.25a1.75 1.75 0 000 3.5h15.5a1.75 1.75 0 000-3.5zM23 12.25h-3.529a.25.25 0 01-.247-.209l-.6-3.582a.25.25 0 00-.247-.209h-1.52a.25.25 0 00-.246.291l.572 3.418a.25.25 0 01-.247.291H7.064a.25.25 0 01-.247-.291l.572-3.418a.25.25 0 00-.246-.291H5.622a.25.25 0 00-.247.209l-.6 3.582a.25.25 0 01-.247.209H1a1 1 0 000 2h3.111a.25.25 0 01.247.291l-.844 5.044a1 1 0 00.821 1.151.938.938 0 00.166.014 1 1 0 00.985-.835l.914-5.456a.25.25 0 01.247-.209h10.707a.25.25 0 01.247.209l.913 5.456a1 1 0 00.985.835.938.938 0 00.166-.014 1 1 0 00.821-1.151l-.844-5.044a.25.25 0 01.247-.291H23a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});