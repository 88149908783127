define("ember-svg-jar/inlined/farming-sell-vegetable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>farming-sell-vegetable</title><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm1.188 10.145a.637.637 0 00-.438.605.75.75 0 01-1.5 0 .5.5 0 00-.5-.5H16a.75.75 0 010-1.5h2.034a.592.592 0 00.219-1.141l-2.063-.825a2.092 2.092 0 01.123-3.929.637.637 0 00.437-.605.75.75 0 011.5 0 .5.5 0 00.5.5H19a.75.75 0 010 1.5h-2.034a.591.591 0 00-.219 1.141l2.063.825a2.092 2.092 0 01-.123 3.929zM15.249 3H17a2 2 0 012 2v.5a.5.5 0 01-.5.5h-1a.5.5 0 00-.353.853l2.5 2.5a.5.5 0 00.707 0l2.5-2.5A.5.5 0 0022.5 6h-1a.5.5 0 01-.5-.5V5a4 4 0 00-4-4h-1.75a1 1 0 000 2zM8.749 21H7a2 2 0 01-2-2v-.5a.5.5 0 01.5-.5h1a.5.5 0 00.354-.854l-2.5-2.5a.5.5 0 00-.707 0l-2.5 2.5A.5.5 0 001.5 18h1a.5.5 0 01.5.5v.5a4 4 0 004 4h1.75a1 1 0 100-2zM11.418 7.288a1.357 1.357 0 00-.016-.552.5.5 0 01.314-.579l1.794-.664a.75.75 0 10-.521-1.407l-2.6.961a.13.13 0 01-.143-.036.116.116 0 010-.16l2.407-2.407a.75.75 0 00-1.06-1.06L9.219 3.765a.155.155 0 01-.212.007.164.164 0 01-.047-.183l.954-2.579A.75.75 0 008.506.49l-.675 1.826a.5.5 0 01-.565.317 1.353 1.353 0 00-.511-.006 1.114 1.114 0 00-.731.475l-.249.375a.5.5 0 00.063.63l2.051 2.05a.5.5 0 11-.707.707L5.417 5.1a.5.5 0 00-.77.077l-4.464 6.73a1.348 1.348 0 00.28 1.675 1.522 1.522 0 001.056.463 1.1 1.1 0 00.616-.181l4.182-2.774a.5.5 0 00.077-.77L5.06 8.985a.5.5 0 11.707-.707L7.388 9.9a.5.5 0 00.63.063l2.926-1.941a1.119 1.119 0 00.474-.734z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});