define("ember-svg-jar/inlined/vectors-add-anchor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vectors-add-anchor</title><path d=\"M8.207 8.957l3.043-3.043a.25.25 0 01.354 0l.366.366a.749.749 0 001.06 0l2.5-2.5a.749.749 0 000-1.06l-2.5-2.5a.749.749 0 00-1.06 0l-2.5 2.5a.749.749 0 000 1.06l.366.366a.25.25 0 010 .354L6.793 7.543a1 1 0 001.414 1.414z\"/><path d=\"M23.78 12.53a.749.749 0 000-1.06l-2.5-2.5a.749.749 0 00-1.06 0l-1.957 1.957a.251.251 0 01-.177.073H5.914a.251.251 0 01-.177-.073L3.78 8.97a.749.749 0 00-1.06 0l-2.5 2.5a.749.749 0 000 1.06l2.5 2.5a.749.749 0 001.06 0l1.957-1.957A.251.251 0 015.914 13h12.172a.251.251 0 01.177.073l1.957 1.957a.749.749 0 001.06 0z\"/><path d=\"M13.03 17.72a.749.749 0 00-1.06 0l-.366.366a.25.25 0 01-.354 0l-3.043-3.043a1 1 0 10-1.414 1.414L9.836 19.5a.25.25 0 010 .354l-.366.366a.749.749 0 000 1.06l2.5 2.5a.749.749 0 001.06 0l2.5-2.5a.749.749 0 000-1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});