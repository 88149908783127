define("ember-svg-jar/inlined/navigation-menu-horizontal-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-menu-horizontal-1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM8.5 12a2 2 0 11-2-2 2 2 0 012 2zm3.5-2a2 2 0 11-2 2 2 2 0 012-2zm5.5 4a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});