define("ember-svg-jar/inlined/blazer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>blazer</title><path d=\"M9 19.1v-4.745a3.007 3.007 0 01.2-1.082.5.5 0 00-.08-.5 38.314 38.314 0 01-3.705-5.515 2.489 2.489 0 01-.215-2 .5.5 0 00-.074-.45 2.424 2.424 0 01-.318-2.357.249.249 0 00-.315-.322l-1.149.4a.5.5 0 00-.33.391L.016 20.9a.5.5 0 00.493.581h2.537a.5.5 0 00.493-.421l.931-5.846a.27.27 0 01.536.042v7.705a1 1 0 001 1.037h3.752a1.006 1.006 0 00.8-.4l.293-.4a.5.5 0 000-.593l-1.262-1.719A3.006 3.006 0 019 19.1zM17.4 4.155a1 1 0 00.326-1.3A5.673 5.673 0 0014.609.014.53.53 0 0014.49 0h-5a.53.53 0 00-.119.014 5.667 5.667 0 00-3.114 2.837 1 1 0 00.326 1.3l.511.342a.25.25 0 01.07.346l-.412.618a.994.994 0 00-.036 1.052 33.167 33.167 0 004.916 6.824.5.5 0 00.719 0 33.158 33.158 0 004.917-6.824.992.992 0 00-.036-1.051l-.412-.619a.25.25 0 01-.037-.188.246.246 0 01.106-.158zM13.407 1.5a.5.5 0 01.5.547 39.894 39.894 0 01-1.675 8.873.25.25 0 01-.475 0A39.5 39.5 0 0110.1 2.046a.5.5 0 01.5-.547z\"/><path d=\"M23.984 20.9l-3-17.986a.5.5 0 00-.33-.391l-1.168-.4a.25.25 0 00-.316.323 2.426 2.426 0 01-.314 2.363.5.5 0 00-.075.45 2.484 2.484 0 01-.213 2 34.557 34.557 0 01-5.14 7.122 2 2 0 01-1.438.61 1.934 1.934 0 01-1.1-.334.249.249 0 00-.389.207V19.1a1.506 1.506 0 00.3.895l2.646 3.6c.011.016.023.03.035.045a1.017 1.017 0 00.59.34l.057.008a.918.918 0 00.117.007H18a1 1 0 001-1.037v-7.7a.27.27 0 01.536-.042l.931 5.846a.5.5 0 00.493.421h2.537a.5.5 0 00.493-.581zm-10.242-.418a.75.75 0 11.749-.749.749.749 0 01-.749.751zm.007-3a.749.749 0 11.749-.749.749.749 0 01-.749.753z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});