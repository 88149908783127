define("ember-svg-jar/inlined/messages-bubble-question-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-question-alternate</title><path d=\"M12 .836c-6.617 0-12 4.475-12 9.975a9.009 9.009 0 003.057 6.658L.931 21.713a1 1 0 001.316 1.355l5.981-2.784a14.273 14.273 0 003.772.5c6.617 0 12-4.474 12-9.974S18.617.836 12 .836zm0 17.949a12.186 12.186 0 01-3.563-.523.992.992 0 00-.713.05l-3.07 1.429a.25.25 0 01-.329-.338l.869-1.736a1 1 0 00-.269-1.229A7.207 7.207 0 012 10.811c0-4.4 4.486-7.975 10-7.975s10 3.578 10 7.975-4.486 7.974-10 7.974z\"/><circle cx=\"12.5\" cy=\"15.874\" r=\"1.5\"/><path d=\"M12.5 4.43a4 4 0 00-4 4 1.25 1.25 0 002.5 0 1.5 1.5 0 112.1 1.376 2.727 2.727 0 00-1.849 2.6 1.25 1.25 0 102.5 0c0-.075 0-.152.35-.306A4 4 0 0012.5 4.43z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});