define("ember-svg-jar/inlined/discount-dollar-dash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>discount-dollar-dash</title><path d=\"M9.183 1.213A1 1 0 007.9.614a12.056 12.056 0 00-6.193 5.108 1 1 0 001.715 1.03 10.047 10.047 0 015.163-4.258 1 1 0 00.598-1.281zM20.471 5.876a1 1 0 00.768-1.638A12.025 12.025 0 0014.345.124a1 1 0 00-.389 1.962A10.025 10.025 0 0119.7 5.514a1 1 0 00.771.362zM15.37 21.314a10.1 10.1 0 01-6.691.016 1 1 0 10-.664 1.887 12.057 12.057 0 008.029-.02 1 1 0 00-.674-1.883zM23.872 10.14a1 1 0 00-1.978.291A10.226 10.226 0 0122 11.9a10.023 10.023 0 01-2.236 6.3 1 1 0 001.552 1.261 12.019 12.019 0 002.556-9.32zM2 11.947a1.015 1.015 0 00-1-1 1 1 0 00-.995 1 12.018 12.018 0 002.723 7.551 1 1 0 001.545-1.269A10.012 10.012 0 012 11.947zM8.475 9.469a3.317 3.317 0 001.844 2.983l2.195 1.1a.836.836 0 01-.374 1.583H9.725a1.25 1.25 0 100 2.5h.75a.25.25 0 01.25.25v.187a1.25 1.25 0 002.5 0v-.445a.25.25 0 01.156-.232 3.336 3.336 0 00.25-6.08l-2.194-1.1a.836.836 0 01.374-1.583h2.414a1.25 1.25 0 000-2.5h-.75a.25.25 0 01-.25-.25V5.7a1.25 1.25 0 00-2.5 0v.446a.25.25 0 01-.157.232 3.341 3.341 0 00-2.093 3.091z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});