define("ember-svg-jar/inlined/touch-finger-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>touch-finger-1</title><path d=\"M17.129 17H14.5a.25.25 0 01-.25-.25v-6.377a2.375 2.375 0 00-4.75 0V18.9a.25.25 0 01-.454.145l-.885-1.242a2.075 2.075 0 00-3.672 1.932l2.1 3.991a.5.5 0 00.443.274H21.25a.5.5 0 00.5-.5v-1.655A4.8 4.8 0 0017.129 17zM11.75 5a1 1 0 001-1V1a1 1 0 10-2 0v3a1 1 0 001 1zM16.346 6.316l2.122-2.122a1 1 0 00-1.415-1.414L14.932 4.9a1 1 0 101.414 1.414zM16.25 9.5a1 1 0 001 1h3a1 1 0 000-2h-3a1 1 0 00-1 1zM7.154 6.316A1 1 0 008.568 4.9L6.447 2.78a1 1 0 00-1.414 1.414zM7.25 9.5a1 1 0 00-1-1h-3a1 1 0 000 2h3a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});