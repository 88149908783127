define("ember-svg-jar/inlined/smart-watch-wrist-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-wrist-alternate</title><path d=\"M23.971 17.58l-.816-5.18A3.977 3.977 0 0019.2 9h-3.73a6.007 6.007 0 00-2.977.79L10.376 11H9.349a.25.25 0 01-.236-.167.5.5 0 00-.472-.333h-4a.5.5 0 00-.471.333.25.25 0 01-.236.167H1a1 1 0 100 2h2.06a.249.249 0 01.225.357 1.49 1.49 0 00-.144.643v4a1.485 1.485 0 00.145.642.25.25 0 01-.225.358H1a1 1 0 000 2h2.934a.25.25 0 01.236.167.5.5 0 00.471.333h4a.5.5 0 00.472-.333.25.25 0 01.236-.167h.824l.7.841A5.982 5.982 0 0015.483 24h1.8a3 3 0 002.991-2.769.251.251 0 01.249-.231H21a3 3 0 002.97-3.418zm-18.33.387a.751.751 0 01-.75-.75v-2.5a.752.752 0 01.75-.75h2a.751.751 0 01.75.75v2.5a.75.75 0 01-.75.75zM21 19h-4.717a1 1 0 000 2h1.706a.25.25 0 01.236.334 1.012 1.012 0 01-.236.373.987.987 0 01-.706.293h-1.8a3.99 3.99 0 01-3.072-1.439l-1-1.2a1 1 0 00-.769-.36h-.419a.251.251 0 01-.223-.36 1.49 1.49 0 00.141-.641v-4a1.5 1.5 0 00-.141-.645.249.249 0 01.222-.355h.419a1.01 1.01 0 00.5-.132l2.347-1.341A4 4 0 0115.47 11h3.73a1.992 1.992 0 011.98 1.711l.813 5.159A1 1 0 0121 19zM5.108 7.277a1 1 0 101.385 1.442.751.751 0 011.016 0 1 1 0 101.384-1.442 2.759 2.759 0 00-3.785 0z\"/><path d=\"M11.321 6.384a1 1 0 00-.028-1.414 6.255 6.255 0 00-8.584 0 1 1 0 001.385 1.443 4.236 4.236 0 015.814 0 1 1 0 001.413-.029z\"/><path d=\"M1.694 4.107a7.73 7.73 0 0110.613 0 1 1 0 101.387-1.442 9.753 9.753 0 00-13.387 0 1 1 0 001.387 1.442z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});