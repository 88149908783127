define("ember-svg-jar/inlined/single-neutral-id-card-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-id-card-4</title><path d=\"M24 4.5a2 2 0 00-2-2H2a2 2 0 00-2 2v15a2 2 0 002 2h3a1 1 0 001-1v-.75a.752.752 0 01.75-.75.761.761 0 01.75.75v.75a1 1 0 001 1h7a1 1 0 001-1v-.75a.752.752 0 01.75-.75.761.761 0 01.75.75v.75a1 1 0 001 1h3a2 2 0 002-2zM22 19a.5.5 0 01-.5.5h-1.292a.251.251 0 01-.247-.208 2.749 2.749 0 00-5.422 0 .251.251 0 01-.247.209H9.708a.251.251 0 01-.247-.208 2.749 2.749 0 00-5.422 0 .251.251 0 01-.247.209H2.5A.5.5 0 012 19V5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M15 12.25h4.782a.75.75 0 000-1.5H15a.75.75 0 000 1.5zM14.25 14.5a.75.75 0 00.75.75h2.608a.75.75 0 100-1.5H15a.75.75 0 00-.75.75zM15 9.25h5a.75.75 0 000-1.5h-5a.75.75 0 000 1.5zM4 15.768h8a.5.5 0 00.383-.179.5.5 0 00.109-.407 4.247 4.247 0 00-.368-1.155c-.348-.7-1.2-1.129-2.667-1.657a.894.894 0 01-.026-.391 4.033 4.033 0 00.869-2.766 2.3 2.3 0 10-4.59 0 3.988 3.988 0 00.833 2.723c.043.089.053.355.076.382-1.543.58-2.39 1-2.738 1.709a4.247 4.247 0 00-.368 1.155.5.5 0 00.109.407.5.5 0 00.378.179z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});