define("ember-svg-jar/inlined/drugs-pills-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drugs-pills-box</title><path d=\"M22 2.5H12a2 2 0 00-2 2V5a1 1 0 001 1h12a1 1 0 001-1v-.5a2 2 0 00-2-2zM22 7.5H12a1 1 0 00-1 1V19a2.5 2.5 0 002.5 2.5h7A2.5 2.5 0 0023 19V8.5a1 1 0 00-1-1zm-1.5 8a.5.5 0 01-.5.5h-6a.5.5 0 01-.5-.5V12a.5.5 0 01.5-.5h6a.5.5 0 01.5.5zM6.75 15.5h-4a2.75 2.75 0 000 5.5h4a2.75 2.75 0 000-5.5zm-2 3.5a.5.5 0 01-.5.5h-1.5a1.25 1.25 0 010-2.5h1.5a.5.5 0 01.5.5zM5.193 13.2l2.829-2.829a2.75 2.75 0 10-3.889-3.893L1.3 9.307A2.75 2.75 0 005.193 13.2zm-2.828-2.833l1.061-1.06a.5.5 0 01.707 0l1.06 1.06a.5.5 0 010 .707l-1.06 1.061a1.25 1.25 0 11-1.768-1.768z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});