define("ember-svg-jar/inlined/e-commerce-amazon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-amazon</title><path d=\"M6 5.25a1 1 0 001.27-.62A3.86 3.86 0 0110.77 2a3.94 3.94 0 013.77 4v.07C7.59 5.5 6 8.9 5.74 10a4.81 4.81 0 002.63 5.6 4.43 4.43 0 001.78.36 6.26 6.26 0 004.56-2.3A2.82 2.82 0 0017.25 16a1 1 0 000-2c-.31 0-.71-.67-.71-1.41V6a6 6 0 00-5.77-6 5.89 5.89 0 00-5.43 4A1 1 0 006 5.25zm3.22 8.53a2.81 2.81 0 01-1.51-3.23c.54-1.91 3.09-2.8 6.87-2.47v2.54c-1.8 2.68-3.79 3.87-5.41 3.16zM23.33 17.66L19 16.45a1 1 0 10-.53 1.93l1.8.5A21.57 21.57 0 0112 20.5a21.5 21.5 0 01-10.51-2.76 1 1 0 00-1 1.74A23.51 23.51 0 0012 22.5a23.57 23.57 0 008.48-1.59l-.63 1a1 1 0 001.71 1l2.31-3.85a1.08 1.08 0 00-.54-1.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});