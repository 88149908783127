define("ember-svg-jar/inlined/video-game-logo-heartstone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-logo-heartstone</title><path d=\"M23.6 11.2l-2.971-2.229a1 1 0 01-.324-.415 9.2 9.2 0 00-.6-1.2 1 1 0 01-.072-.887l.534-1.334a1 1 0 00-1.3-1.3l-1.333.533a1 1 0 01-.888-.072 9.081 9.081 0 00-1.2-.605.984.984 0 01-.415-.323L12.8.4a1 1 0 00-1.6 0L8.971 3.371a.99.99 0 01-.415.323 9.081 9.081 0 00-1.2.605 1 1 0 01-.889.072l-1.33-.533a1 1 0 00-1.3 1.3l.534 1.334a1 1 0 01-.072.887 9.2 9.2 0 00-.605 1.2.976.976 0 01-.323.415L.4 11.2a1 1 0 000 1.6l2.971 2.228a1 1 0 01.324.415 9.136 9.136 0 00.6 1.2 1 1 0 01.072.887l-.534 1.334a1 1 0 001.3 1.3l1.333-.533a1 1 0 01.888.071 9.036 9.036 0 001.2.606.987.987 0 01.414.323L11.2 23.6a1 1 0 001.6 0l2.229-2.971a.993.993 0 01.414-.323 9.036 9.036 0 001.2-.606 1 1 0 01.888-.071l1.333.533a1 1 0 001.3-1.3l-.534-1.334a1 1 0 01.072-.887 9.136 9.136 0 00.6-1.2.992.992 0 01.323-.415L23.6 12.8a1 1 0 000-1.6zM12 18.5a6.5 6.5 0 116.5-6.5 6.5 6.5 0 01-6.5 6.5z\"/><path d=\"M11.8 7.51a3.477 3.477 0 00-3.111 2.515 2.511 2.511 0 001.457 2.939 2.358 2.358 0 002.976-.8.75.75 0 00-1.248-.832.882.882 0 01-1.121.259 1.018 1.018 0 01-.609-1.2 1.957 1.957 0 011.771-1.384 2.481 2.481 0 011.941.659 2.887 2.887 0 01.892 2.085 3.272 3.272 0 01-1.856 3.018 2.508 2.508 0 01-2.79-.455.75.75 0 00-1 1.122 4 4 0 004.4.7 4.77 4.77 0 002.748-4.386 4.356 4.356 0 00-1.37-3.183A4.036 4.036 0 0011.8 7.51z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});