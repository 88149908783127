define("ember-svg-jar/inlined/style-three-pin-bread", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-bread</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm.221 10.32a.252.252 0 00-.145.259l.42 3.359a.5.5 0 01-.5.562H7a.5.5 0 01-.5-.562l.42-3.359a.25.25 0 00-.145-.259A2.394 2.394 0 015.5 8 2.5 2.5 0 018 5.5h3A2.5 2.5 0 0113.5 8a2.394 2.394 0 01-1.279 2.32zm4.771 3.674a1.5 1.5 0 01-1.125.507h-2.138a.252.252 0 01-.19-.087.249.249 0 01-.057-.2 1.446 1.446 0 00.006-.4l-.254-2.031a.251.251 0 01.248-.281H16a3.288 3.288 0 00.887-.126.244.244 0 01.2.033.249.249 0 01.11.177l.153 1.23a1.5 1.5 0 01-.358 1.178zM16 10.5h-1.882a.25.25 0 01-.209-.387A3.825 3.825 0 0014.5 8a3.469 3.469 0 00-.7-2.1.251.251 0 01.2-.4h2a2.5 2.5 0 110 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});