define("ember-svg-jar/inlined/car-sun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-sun</title><path d=\"M17 3.091a1 1 0 001-1V1a1 1 0 00-2 0v1.091a1 1 0 001 1zM20.442 4.531a.993.993 0 00.707-.293l.772-.771a1 1 0 00-1.414-1.414l-.772.771a1 1 0 00.707 1.707zM23 6h-1.091a1 1 0 000 2H23a1 1 0 000-2zM21.149 9.767a1 1 0 00-1.414 1.414l.772.771a1 1 0 001.414-1.414zM18 13v-1.091a1 1 0 10-2 0V13a1 1 0 002 0zM12.021 10.538a1 1 0 101.414 1.414l.771-.771a1 1 0 00-1.414-1.414zM11 8h1.091a1 1 0 100-2H11a1 1 0 000 2zM12.792 4.238a1 1 0 001.414-1.414l-.771-.771a1 1 0 00-1.414 1.414zM17 3.815a3.205 3.205 0 103.2 3.2 3.208 3.208 0 00-3.2-3.2zM18.118 21.25a.484.484 0 01-.477-.4l-.362-2.183a7.046 7.046 0 00-6.97-5.913 7.416 7.416 0 00-6.824 4.5l-.206.482a.5.5 0 01-.408.3 3.206 3.206 0 00-2.162 1 3.467 3.467 0 00-.66 2.844 1 1 0 00.992.871h.3a.5.5 0 01.433.25A2 2 0 005.23 23a.5.5 0 01.433-.25h6.175a.5.5 0 01.433.25 2 2 0 003.577-.24.016.016 0 01.015-.01h2.255a.75.75 0 000-1.5zm-4.43-3.733a.5.5 0 01-.422.233H6.97a.5.5 0 01-.452-.714 3.977 3.977 0 017.2 0 .5.5 0 01-.03.481z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});