define("ember-svg-jar/inlined/sofa-double", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sofa-double</title><path d=\"M2.233 8.132a3.507 3.507 0 013.24 3.055.5.5 0 00.5.438h4.777a.5.5 0 00.5-.5v-5a.75.75 0 011.5 0v5a.5.5 0 00.5.5h4.782a.5.5 0 00.5-.438 3.507 3.507 0 013.109-3.044A.4.4 0 0022 7.74v-.115a4.506 4.506 0 00-4.5-4.5h-11a4.506 4.506 0 00-4.5 4.5v.258a.249.249 0 00.233.249z\"/><path d=\"M22 9.625a2 2 0 00-2 2v1a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-1a2 2 0 00-4 0v4a2.49 2.49 0 001.458 2.26.5.5 0 01.292.454v1.286a1.25 1.25 0 002.5 0v-1a.5.5 0 01.5-.5h14.5a.5.5 0 01.5.5v1a1.25 1.25 0 002.5 0v-1.286a.5.5 0 01.291-.454A2.489 2.489 0 0024 15.625v-4a2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});