define("ember-svg-jar/inlined/security-officer-scanner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>security-officer-scanner</title><path d=\"M16.335 9.977a2.193 2.193 0 10-2.191-2.192 2.194 2.194 0 002.191 2.192zM2.3 7.746a2.193 2.193 0 004.385 0 .243.243 0 00-.246-.246h-3.9a.242.242 0 00-.239.246zM7.628 17.8a.248.248 0 00.168.109.253.253 0 00.195-.05.944.944 0 00.366-.747v-3.866a1.934 1.934 0 00-1.934-1.934H3.209a.25.25 0 00-.2.4l4.6 6.067zM.621 17.114a.967.967 0 00.967.967h.717a.25.25 0 01.25.25v4.162a.966.966 0 00.967.966h1.934a.966.966 0 00.967-.966v-4.412a.081.081 0 00.073-.045.084.084 0 00-.008-.085c-3.48-4.586-4.439-5.841-4.71-6.212a.249.249 0 00-.347-.056 1.915 1.915 0 00-.81 1.563z\"/><path d=\"M9.291 22.774a1 1 0 002 0V5.377a.5.5 0 01.5-.5h9.088a.5.5 0 01.5.5v17.4a1 1 0 002 0c0-32.028-.008-13.528-.008-20.8a1.75 1.75 0 00-1.75-1.75H11.05A1.75 1.75 0 009.3 1.976c0 5.653-.009-4.876-.009 10.658v10.14zM21.171 1.7a.726.726 0 11-.726.726.726.726 0 01.726-.726zm-2.9 0a.726.726 0 11-.725.726.726.726 0 01.723-.726zM6.652 6.54a.244.244 0 00.216-.122c.054-.095.143-.261.287-.548a.983.983 0 00-.375-1.282L5 3.517a.985.985 0 00-1.013 0L2.2 4.588a.991.991 0 00-.377 1.282c.1.2.225.414.3.543a.251.251 0 00.215.123z\"/><path d=\"M14.151 18.141a.25.25 0 01.25.25v4.1a.966.966 0 00.967.966H17.3a.966.966 0 00.967-.966v-4.1a.25.25 0 01.25-.25h.717a.966.966 0 00.967-.967v-3.868a1.934 1.934 0 00-1.934-1.934H14.4a1.934 1.934 0 00-1.934 1.934v3.868a.967.967 0 00.967.967z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});