define("ember-svg-jar/inlined/wolf-sit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wolf-sit</title><path d=\"M24 14c0-4.943-2.874-5-3-5a.5.5 0 00-.474.656 8.183 8.183 0 01-.016 3.746 5.5 5.5 0 01-1.454 2.89 9.36 9.36 0 00-2.831-2.467C14.4 12.626 12.506 11.385 12.018 8V5c0-2.18-2.534-4.744-2.642-4.852a.484.484 0 00-.412-.143A.5.5 0 008.6.24L7.191 2.531a11.858 11.858 0 00-1.857.5A3.854 3.854 0 003.119 5.1l-2.1.7A1.5 1.5 0 000 7.222V8.2a1.494 1.494 0 001.147 1.455c2.75.659 4.614 1.611 4.863 2.483.964 3.378.522 11.254.517 11.333a.5.5 0 00.5.529h2.5a.25.25 0 00.25-.25V23a2.751 2.751 0 012.746-2.75c.525 0 1.086.072 1.086.75s-.556.75-1.086.75A1.251 1.251 0 0011.269 23v.75a.25.25 0 00.25.25h4.457a3.916 3.916 0 003.032-1.149 4.906 4.906 0 00.961-3.842 4.089 4.089 0 002.555-.973A5.136 5.136 0 0024 14zM7.525 5.5a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});