define("ember-svg-jar/inlined/cat-fish-toy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cat-fish-toy</title><path d=\"M22.642 17.938a.405.405 0 00.3-.692c-1.56-1.56-3.607-2.113-5.3-1.073-.018-.023-.381-.4-.6-.631a.25.25 0 010-.35l.549-.549a1 1 0 00-1.415-1.414l-.545.545a.249.249 0 01-.353 0l-.355-.355a.249.249 0 010-.353l1.591-1.592A1 1 0 1015.1 10.06l-1.591 1.592a.253.253 0 01-.177.073.249.249 0 01-.176-.074l-.353-.353a.249.249 0 010-.353l.544-.545a1 1 0 00-1.414-1.414l-.544.544a.25.25 0 01-.354 0l-.54-.54c1.513-1.693 2.31-3.9.992-5.215L3.6 1.042a.235.235 0 00-.3.291L4.348 4.81a.5.5 0 01-.957.289l-.969-3.216a.249.249 0 00-.422-.1C.911 2.934-.559 5.555 4.577 10.69c1.125 1.126 2.9.706 4.444-.382.017.021.386.4.61.637a.249.249 0 010 .35l-.872.873a1 1 0 001.414 1.414l.869-.87a.251.251 0 01.354 0l.353.354a.249.249 0 010 .353l-1.594 1.591a1 1 0 101.414 1.415l1.591-1.591a.25.25 0 01.353 0l.354.354a.249.249 0 010 .354L13 16.41a1 1 0 101.414 1.414l.871-.871a.251.251 0 01.351 0c.23.223.608.587.631.6-1.038 1.692-.486 3.739 1.075 5.3a.4.4 0 00.691-.3 4.426 4.426 0 014.609-4.615zM8.337 6.33a.945.945 0 11-.669-1.613.946.946 0 01.669 1.613z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});