define("ember-svg-jar/inlined/amusement-park-electric-cars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>amusement-park-electric-cars</title><path d=\"M22 20H2a2.006 2.006 0 00-2 2 2.006 2.006 0 002 2h20a2.006 2.006 0 002-2 2.006 2.006 0 00-2-2zM15 10.5a1 1 0 00-1 1 .164.164 0 01-.276.121l-1.383-1.277a.442.442 0 01-.013-.637 1 1 0 00-1.414-1.414l-2.121 2.121a1 1 0 101.414 1.414.562.562 0 01.778-.015l1.358 1.254a.247.247 0 01.063.274.25.25 0 01-.233.159H7.5A.5.5 0 017 13v-1a2.5 2.5 0 00-1.667-2.357A.5.5 0 015 9.172V1A1 1 0 003.628.073L.91 1.161a1.5 1.5 0 00-.072 2.726L3 4.926v4.16a.5.5 0 01-.333.471A1 1 0 002 10.5V18a.5.5 0 00.5.5h19a.5.5 0 00.5-.5v-.5a7.008 7.008 0 00-7-7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});