define("ember-svg-jar/inlined/task-list-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-list-warning</title><path d=\"M12 7.749H6a.75.75 0 000 1.5h6a.75.75 0 000-1.5zM12.751 12a.75.75 0 00-.75-.75H6a.75.75 0 100 1.5h6a.75.75 0 00.751-.75zM6 14.749a.75.75 0 100 1.5h4a.75.75 0 000-1.5z\"/><path d=\"M10.59 19H3.5a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v5.217a.249.249 0 00.417.186A2.892 2.892 0 0117.7 10a.252.252 0 00.3-.24V3.5A1.5 1.5 0 0016.5 2h-4a.239.239 0 01-.207-.121 3.828 3.828 0 00-6.589 0A.239.239 0 015.5 2h-4A1.5 1.5 0 000 3.5v17A1.5 1.5 0 001.5 22h7.89a.24.24 0 00.229-.151c.2-.48.87-1.84 1.2-2.492A.248.248 0 0010.59 19z\"/><path d=\"M23.836 21.905l-5.055-9.635a1.449 1.449 0 00-2.561 0l-5.055 9.63a1.428 1.428 0 00.047 1.409 1.456 1.456 0 001.233.691h10.111a1.457 1.457 0 001.233-.685 1.428 1.428 0 00.047-1.41zM16.751 16a.75.75 0 111.5 0v2.75a.75.75 0 01-1.5 0zm.75 6.25a1 1 0 111-1 1 1 0 01-1.001.999z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});