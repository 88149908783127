define("ember-svg-jar/inlined/gesture-tap-expand-all-direction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-tap-expand-all-direction</title><path d=\"M8.531 2.165A.75.75 0 009 3.5h5a.751.751 0 00.469-1.335l-2.5-2a.749.749 0 00-.938 0zM14 20.5H9a.751.751 0 00-.469 1.335l2.5 2a.751.751 0 00.938 0l2.5-2A.75.75 0 0014 20.5zM2.165 8.531l-2 2.5a.749.749 0 000 .938l2 2.5A.751.751 0 003.5 14V9a.751.751 0 00-1.335-.469zM23.835 11.031l-2-2.5A.75.75 0 0020.5 9v5a.75.75 0 001.335.469l2-2.5a.749.749 0 000-.938zM18.339 9.495a7.238 7.238 0 00-12.673-.01 7.156 7.156 0 00.186 7.357.749.749 0 101.271-.794A5.757 5.757 0 0112 7.25a5.757 5.757 0 014.869 8.809.75.75 0 001.268.8 7.15 7.15 0 00.202-7.364z\"/><path d=\"M12.647 11.567a.252.252 0 01-.147-.229V9.5a1 1 0 10-2 0v3.853a.25.25 0 01-.414.189c-1.038-.9-2.461-.354-1.586.958l2.35 2.82a.5.5 0 00.384.18h3.394a.5.5 0 00.479-.355c.251-.829.749-2.475.86-2.955.287-1.245-.817-1.497-3.32-2.623z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});