define("ember-svg-jar/inlined/store", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.502 13h-8a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h8a.5.5 0 00.5-.5v-6a.5.5 0 00-.5-.5zm-.5 6h-7v-5h7v5z\"/><path d=\"M23.952 6.29c-.002-.004-.001-.009-.003-.013l-2-4A.5.5 0 0021.502 2h-19a.5.5 0 00-.447.276l-2 4-.003.014a.495.495 0 00-.05.21v1c0 .89.359 1.777.986 2.436.298.312.643.553 1.014.723V22.5a.5.5 0 00.5.5h19a.5.5 0 00.5-.5V10.674a3.542 3.542 0 002-3.174v-1a.492.492 0 00-.05-.21zM22.693 6h-3.801l-.75-3h3.051l1.5 3zm-4.691 3.341c-.701 1.035-1.825 1.653-3.041 1.653-.937 0-1.799-.386-2.459-1.069V7h5.5v2.341zM6.002 7h5.5v2.926c-.66.683-1.519 1.069-2.451 1.069-1.201 0-2.354-.633-3.049-1.654V7zm5.5-4v3H6.143l.75-3h4.609zm5.609 0l.75 3h-5.359V3h4.609zm-14.3 0h3.051l-.75 3H1.311l1.5-3zM1.002 7.5V7h4v2.27c-1.06.936-2.407.901-3.29-.025a2.56 2.56 0 01-.71-1.745zm14 14.5v-8h4v8h-4zm6 0h-1v-8.5a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5V22h-11V10.929a3.342 3.342 0 002.416-.69c.893 1.095 2.239 1.756 3.633 1.756a4.348 4.348 0 002.951-1.143 4.37 4.37 0 002.959 1.142 4.648 4.648 0 003.626-1.754c.639.491 1.344.748 2.068.748.118 0 .231-.024.347-.038V22zm2-14.5c0 1.325-1.097 2.487-2.347 2.487-.571 0-1.137-.254-1.653-.716V7h4v.5z\"/><circle cx=\"17.502\" cy=\"18.5\" r=\".5\"/><g><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});