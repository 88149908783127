define("ember-svg-jar/inlined/casino-lucky-horseshoe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>casino-lucky-horseshoe</title><path d=\"M20 5a2 2 0 002-2V2a2 2 0 00-2-2h-3a2.5 2.5 0 00-2.5 2.5v3.066c0 2.12.09 4.011 1.2 5.715A4.982 4.982 0 0116.5 14a4 4 0 01-4 4h-1a4 4 0 01-4-4 4.988 4.988 0 01.8-2.72c1.107-1.7 1.2-3.594 1.2-5.714V2.5A2.5 2.5 0 007 0H4a2 2 0 00-2 2v1a2 2 0 002 2 .5.5 0 01.5.5v.066a5.49 5.49 0 01-.889 2.991 10 10 0 1016.778 0 5.5 5.5 0 01-.889-2.991V5.5A.5.5 0 0120 5zM5 17a1 1 0 111-1 1 1 0 01-1 1zm1.5-7.5a1 1 0 111-1 1 1 0 01-1 1zM12 22a1 1 0 111-1 1 1 0 01-1 1zm4.5-13.5a1 1 0 111 1 1 1 0 01-1-1zM20 16a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});