define("ember-svg-jar/inlined/real-estate-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-house</title><path d=\"M23 3a1 1 0 00-1 1v17a1 1 0 01-1 1H4a1 1 0 000 2h17a3 3 0 003-3V4a1 1 0 00-1-1z\"/><path d=\"M20.5 19V1.5A1.5 1.5 0 0019 0H1.5A1.5 1.5 0 000 1.5V19a1.5 1.5 0 001.5 1.5H19a1.5 1.5 0 001.5-1.5zm-3.25-8.5h-2a.5.5 0 00-.5.5v4.5a1 1 0 01-1 1h-1.5a.5.5 0 01-.5-.5v-2a1.5 1.5 0 00-3 0v2a.5.5 0 01-.5.5h-1.5a1 1 0 01-1-1V11a.5.5 0 00-.5-.5h-2a.5.5 0 01-.331-.875l6.668-5.874a1 1 0 011.326 0l6.668 5.874a.5.5 0 01-.331.875z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});