define("ember-svg-jar/inlined/roe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>roe</title><path d=\"M16.694 14.938a.25.25 0 00-.457-.107l-.779 1.178a3.235 3.235 0 01-5.4 0l-1.265-1.915a.25.25 0 00-.456.1l-.173 1.124c-.53 2.189-1.13 4.672-4.4 4.672-2.009 0-2.512.011-2.512.011a.5.5 0 00-.488.5v3a.5.5 0 00.5.5l16-.012a.5.5 0 00.5-.562z\"/><path d=\"M23.238 1.073A1 1 0 0022.957.3a.98.98 0 00-.763-.3 8.083 8.083 0 00-6.872 3.741 4.855 4.855 0 00-5.127 0A8.083 8.083 0 003.324 0a.936.936 0 00-.761.3 1 1 0 00-.284.77c.295 4.245 3.729 5.7 5.662 6.188a4.909 4.909 0 00-.038.61 7.176 7.176 0 001.19 3.957l2.217 3.356a1.736 1.736 0 002.9 0l2.218-3.356a7.175 7.175 0 001.189-3.957 4.909 4.909 0 00-.038-.61c1.93-.484 5.365-1.941 5.659-6.185zM10.759 9.489a1 1 0 111-1 1 1 0 01-1 1zm4 0a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});