define("ember-svg-jar/inlined/html-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>html-1</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM6.625 19.5a.625.625 0 01-1.25 0v-2.124a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0v-6a.625.625 0 111.25 0v2.125a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V13.5a.625.625 0 111.25 0zM12 14.126h-1.125a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0v-5.124a.25.25 0 00-.25-.25H8a.625.625 0 110-1.25h4a.625.625 0 010 1.25zm5.625 5.374a.625.625 0 11-1.25 0v-3.046c0-.142-.12-.067-.16.013l-.156.313a.65.65 0 01-1.118 0s-.1-.211-.15-.3-.166-.086-.166 0v3.02a.625.625 0 11-1.25 0v-6a.625.625 0 011.184-.279s.72 1.436.783 1.566a.183.183 0 00.32-.009l.779-1.557a.625.625 0 011.184.279zm4.375.626h-2a1.627 1.627 0 01-1.625-1.626v-5a.625.625 0 111.25 0v5a.376.376 0 00.375.375h2a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});