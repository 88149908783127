define("ember-svg-jar/inlined/ui-webpage-play-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ui-webpage-play-1</title><path d=\"M22.667 16.372a.25.25 0 01-.167-.236V2.75a2 2 0 00-2-2h-17a2 2 0 00-2 2v13.386a.25.25 0 01-.167.236A1.994 1.994 0 000 18.25v2a2 2 0 002 2h4.955a.249.249 0 00.219-.37 5.468 5.468 0 010-5.26.249.249 0 00-.219-.37H4a.5.5 0 01-.5-.5V3.25a.5.5 0 01.5-.5h16a.5.5 0 01.5.5v12.5a.5.5 0 01-.5.5h-2.955a.251.251 0 00-.215.123.248.248 0 000 .247 5.468 5.468 0 010 5.26.249.249 0 00.219.37H22a2 2 0 002-2v-2a1.994 1.994 0 00-1.333-1.878zM3.723 17.834a.5.5 0 01.777.416v2a.5.5 0 01-.264.441.5.5 0 01-.513-.025l-1.5-1a.5.5 0 010-.832zm18.054 1.832l-1.5 1a.5.5 0 01-.777-.416v-2a.5.5 0 01.777-.416l1.5 1a.5.5 0 010 .832z\"/><path d=\"M12 15.25a4 4 0 102.828 6.829 4.081 4.081 0 00.489-.592A4 4 0 0012 15.25zm1.747 4.437l-2 1.144a.5.5 0 01-.752-.437v-2.288a.5.5 0 01.752-.437l2 1.145a.5.5 0 010 .873z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});