define("ember-svg-jar/inlined/messages-bubble-square-typing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-typing</title><path d=\"M22 1.5H2A1.5 1.5 0 00.5 3v13.552A1.459 1.459 0 002 18h3v4a.5.5 0 00.785.411L12.156 18H22a1.5 1.5 0 001.5-1.5V3A1.5 1.5 0 0022 1.5zM19 10a1.5 1.5 0 11-1.5-1.5A1.5 1.5 0 0119 10zM5 10a1.5 1.5 0 111.5 1.5A1.5 1.5 0 015 10zm5.5 0a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});