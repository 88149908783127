define("ember-svg-jar/inlined/light-mode-hdr-off-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-mode-hdr-off-alternate</title><path d=\"M15.391 18.573a.248.248 0 00-.176-.073H3.75a2.253 2.253 0 01-2.25-2.25v-8.5a2.246 2.246 0 01.883-1.785.251.251 0 00.1-.182.246.246 0 00-.072-.193L1.7 4.878a.249.249 0 00-.336-.015A3.743 3.743 0 000 7.75v8.5A3.755 3.755 0 003.75 20h12.465a.25.25 0 00.176-.427zM8.609 5.427a.248.248 0 00.176.073H20.25a2.253 2.253 0 012.25 2.25v8.5a2.246 2.246 0 01-.883 1.785.251.251 0 00-.1.182.246.246 0 00.072.193l.712.712a.249.249 0 00.336.015A3.743 3.743 0 0024 16.25v-8.5A3.755 3.755 0 0020.25 4H7.785a.25.25 0 00-.176.427z\"/><path d=\"M19.72 16.53a.75.75 0 001.06-1.06l-2.318-2.318a.252.252 0 01-.06-.257.249.249 0 01.2-.168 2.75 2.75 0 00-.352-5.477h-2a.75.75 0 00-.75.75v4.215a.251.251 0 00.073.177zM17 9a.25.25 0 01.25-.25h1a1.25 1.25 0 010 2.5h-1A.25.25 0 0117 11zM7.427 10.608a.25.25 0 00-.427.177V11a.25.25 0 01-.25.25h-2A.25.25 0 014.5 11V8A.75.75 0 003 8v8a.75.75 0 001.5 0v-3a.25.25 0 01.25-.25h2A.25.25 0 017 13v3a.75.75 0 001.5 0v-4.215a.251.251 0 00-.073-.177zM11.169 7.34a.25.25 0 00-.225.422l3.544 3.544a.25.25 0 00.422-.225 4.76 4.76 0 00-3.741-3.741zM11.334 15.064a.25.25 0 01-.334-.236v-.543a.251.251 0 00-.073-.177l-1-1a.25.25 0 00-.427.177V16a.75.75 0 00.75.75 4.714 4.714 0 002.281-.585.248.248 0 00.127-.183.254.254 0 00-.07-.213l-.746-.745a.251.251 0 00-.279-.052 3.038 3.038 0 01-.229.092zM1.28.22A.75.75 0 00.22 1.28l22.5 22.5a.75.75 0 001.06-1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});