define("ember-svg-jar/inlined/baby-care-cot-mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baby-care-cot-mobile</title><path d=\"M24 4.5a1 1 0 00-1-1h-9.5A.5.5 0 0113 3V1a1 1 0 00-2 0v2a.5.5 0 01-.5.5H1a1 1 0 000 2h2.5A.5.5 0 014 6v3.141a.5.5 0 01-.312.463 3.5 3.5 0 102.624 0A.5.5 0 016 9.141V6a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v8.233a.5.5 0 01-.242.428 4.645 4.645 0 00-2.237 4.2c0 3.005 2.021 4.925 5.408 5.137h.049a.743.743 0 00.509-1.284 5.426 5.426 0 01-1.361-3.91 5.22 5.22 0 011.374-3.741.743.743 0 00.182-.842.729.729 0 00-.726-.45c-.137.008-.272.018-.405.031A.5.5 0 0113 13.3V6a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v5.909a1.006 1.006 0 01-.118.471l-2.414 4.514a1 1 0 00.882 1.47h5.3a1 1 0 00.882-1.47l-2.414-4.514a1.006 1.006 0 01-.118-.471V6a.5.5 0 01.5-.5H23a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});