define("ember-svg-jar/inlined/common-file-bookmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-bookmark</title><path d=\"M22 6.328a2 2 0 00-.586-1.414L17.086.586A2 2 0 0015.672 0H11.75a.25.25 0 00-.25.25V11.4a.25.25 0 01-.426.177l-1.9-1.9a.25.25 0 00-.354 0l-1.9 1.9a.248.248 0 01-.272.054.251.251 0 01-.148-.231V.25A.25.25 0 006.252 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});