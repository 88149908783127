define("ember-svg-jar/inlined/navigation-down-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-down-1</title><path d=\"M23.742 12.247a1.441 1.441 0 00-2.005-.36l-9.463 6.583a.482.482 0 01-.548 0l-9.463-6.583a1.44 1.44 0 10-1.645 2.364l9.463 6.583a3.373 3.373 0 003.838 0l9.463-6.583a1.439 1.439 0 00.36-2.004z\"/><path d=\"M.892 7.525l9.464 6.583a2.892 2.892 0 003.289 0l9.463-6.583a.961.961 0 00.412-.788V3.523a.96.96 0 00-1.509-.788l-9.463 6.583a.962.962 0 01-1.1 0L1.989 2.735a.96.96 0 00-1.509.788v3.214a.961.961 0 00.412.788z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});