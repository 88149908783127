define("ember-svg-jar/inlined/smiley-sick-contageous", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-sick-contageous</title><path d=\"M5.988 12.077a.252.252 0 00.3-.107A2 2 0 018 11h8a2 2 0 011.713.97.251.251 0 00.3.107l5.651-2.019a.214.214 0 00.137-.24 12 12 0 00-23.6 0 .214.214 0 00.137.24zM9 7a2.656 2.656 0 00-2.1.947 1 1 0 11-1.79-.894C5.858 5.547 7.847 5 9 5a1 1 0 010 2zm9.447 1.4a1 1 0 01-1.342-.448A2.656 2.656 0 0015 7a1 1 0 010-2c1.153 0 3.142.547 3.895 2.053a1 1 0 01-.448 1.347zM18.166 14.145a.25.25 0 00-.166.236v1.369a.25.25 0 00.25.25h4.886a.248.248 0 00.236-.17 11.942 11.942 0 00.62-3.4.251.251 0 00-.334-.245zM5.75 16a.25.25 0 00.25-.25v-1.369a.25.25 0 00-.166-.236L.342 12.183a.251.251 0 00-.334.245 11.942 11.942 0 00.62 3.4.25.25 0 00.236.172zM18.236 18a.249.249 0 00-.248.222A2 2 0 0116 20H8a2 2 0 01-1.988-1.778.25.25 0 00-.248-.222h-3.71a.251.251 0 00-.219.129.249.249 0 00.008.254 11.992 11.992 0 0020.314 0 .249.249 0 00.008-.254.251.251 0 00-.219-.129z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});