define("ember-svg-jar/inlined/car-repair-rotating-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-repair-rotating-light</title><path d=\"M22.5 15.214h-8.25a.25.25 0 01-.25-.25v-4.25a1.5 1.5 0 00-1.5-1.5H7.55A2.463 2.463 0 005.222 10.9l-.982 2.933a.249.249 0 01-.149.155l-2.606.976A2.345 2.345 0 000 17.125v3.589a1.5 1.5 0 001.5 1.5h1.345a.249.249 0 00.243-.311A2.766 2.766 0 013 21.218a3 3 0 016 0 2.813 2.813 0 01-.088.685.249.249 0 00.242.311h5.191a.249.249 0 00.243-.311 2.766 2.766 0 01-.088-.685 3 3 0 016 0 2.813 2.813 0 01-.088.685.249.249 0 00.242.311h1A2.352 2.352 0 0024 19.865v-3.151a1.5 1.5 0 00-1.5-1.5zm-19.25 2.5h-1a.5.5 0 010-1h1a.5.5 0 010 1zm7-4.5a1 1 0 01-1 1H6.569a.25.25 0 01-.237-.329l.785-2.349a.462.462 0 01.433-.322H10a.25.25 0 01.25.25z\"/><path d=\"M6 19.218a2 2 0 00-2 2 1.971 1.971 0 00.275 1 2 2 0 101.725-3zM17.5 19.218a2 2 0 00-2 2 1.971 1.971 0 00.275 1 2 2 0 101.725-3zM8 8.33h2.75a.75.75 0 00.75-.75 2.125 2.125 0 10-4.25 0 .75.75 0 00.75.75zM9.375 4.455a1 1 0 001-1V1.782a1 1 0 00-2 0v1.673a1 1 0 001 1zM12 5.223a1 1 0 001.408-.129l1.113-1.336a1 1 0 00-1.538-1.279l-1.114 1.336A1 1 0 0012 5.223zM5.626 5.1a1 1 0 101.523-1.3L6.011 2.47a1 1 0 10-1.522 1.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});