define("ember-svg-jar/inlined/style-two-pin-dollar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-two-pin-dollar</title><path d=\"M12 0a9.511 9.511 0 00-9.5 9.5c0 6.544 6.537 12.487 8.541 14.153a1.5 1.5 0 001.917 0c2-1.666 8.542-7.609 8.542-14.154A9.511 9.511 0 0012 0zm0 15.5a6 6 0 116-6 6.006 6.006 0 01-6 6z\"/><path d=\"M13.4 8.984l-2.06-.825a.342.342 0 01.127-.659H13.5a1 1 0 000-2h-.25a.25.25 0 01-.25-.25 1 1 0 00-2 0 .387.387 0 01-.266.368 2.341 2.341 0 00-.136 4.4l2.062.825a.342.342 0 01-.127.659H10.5a1 1 0 000 2h.25a.25.25 0 01.25.25 1 1 0 002 0 .387.387 0 01.266-.368 2.341 2.341 0 00.136-4.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});