define("ember-svg-jar/inlined/style-three-pin-search-plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-search-plus</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm5.208 13.805a1 1 0 11-1.416 1.411l-2.133-2.143a.5.5 0 00-.587-.089A4.44 4.44 0 0111 13.5 4.5 4.5 0 1115.5 9a4.435 4.435 0 01-.517 2.072.5.5 0 00.088.586z\"/><path d=\"M13.25 8.25h-1a.5.5 0 01-.5-.5v-1a.75.75 0 00-1.5 0v1a.5.5 0 01-.5.5h-1a.75.75 0 000 1.5h1a.5.5 0 01.5.5v1a.75.75 0 001.5 0v-1a.5.5 0 01.5-.5h1a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});