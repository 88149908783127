define("ember-svg-jar/inlined/multiple-actions-star.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-star</title><path d=\"M13.346 12.46a.251.251 0 00.475.048c.705-1.634.688-1.6.728-1.678a3.3 3.3 0 012.426-1.79.25.25 0 00.066-.473 5.992 5.992 0 00-5.017-.033.5.5 0 00-.182.771 7.426 7.426 0 011.504 3.155z\"/><ellipse cx=\"14.015\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M9.7 14a.5.5 0 00.339-.133 3.225 3.225 0 011.421-.763.5.5 0 00.364-.612 6.028 6.028 0 00-11.817.841A.6.6 0 00.6 14z\"/><ellipse cx=\"5.515\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M20.735 23.827a1.275 1.275 0 001.506-.166 1.254 1.254 0 00.315-1.45L21.333 19.4a.251.251 0 01.063-.287l2.212-1.971a1.264 1.264 0 00.3-1.378A1.233 1.233 0 0022.739 15h-2.5a.249.249 0 01-.229-.151l-1.344-3.116-.013-.028a1.293 1.293 0 00-2.317.028l-1.346 3.116a.25.25 0 01-.23.151h-2.5a1.228 1.228 0 00-1.166.76 1.269 1.269 0 00.319 1.4l2.194 1.954a.251.251 0 01.063.287l-1.225 2.809a1.252 1.252 0 00.315 1.451 1.284 1.284 0 001.505.166l3.112-1.752a.249.249 0 01.245 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});