define("ember-svg-jar/inlined/smiley-thrilled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-thrilled</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM7.5 7a3.187 3.187 0 012.935 2.144 1 1 0 01-1.87.712A1.207 1.207 0 007.5 9a1.207 1.207 0 00-1.065.856 1 1 0 01-1.87-.712A3.187 3.187 0 017.5 7zm-.441 8.709a.438.438 0 01.38-.22h9.122a.439.439 0 01.38.658 5.706 5.706 0 01-9.882 0 .439.439 0 010-.438zM18.5 10.5a1 1 0 01-.935-.644A1.207 1.207 0 0016.5 9a1.207 1.207 0 00-1.065.856 1 1 0 01-1.87-.712 3.081 3.081 0 015.87 0A1 1 0 0118.5 10.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});