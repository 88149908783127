define("ember-svg-jar/inlined/file-code-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-2-alternate</title><path d=\"M21.706 5.708L16.292.294A1 1 0 0015.585 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.415a1 1 0 00-.294-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.464a.5.5 0 01.354.147l4.535 4.535a.5.5 0 01.147.354z\"/><path d=\"M8.073 10.178a1 1 0 00-1.415 1.414l1.616 1.616a.25.25 0 010 .354l-1.616 1.615a1 1 0 001.415 1.415l2.5-2.5a1 1 0 000-1.414zM16.5 11.5H13a1 1 0 000 2h3.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});