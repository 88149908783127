define("ember-svg-jar/inlined/multiple-actions-mail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-mail</title><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.381 12.761a.251.251 0 00.284.2 2.174 2.174 0 01.335-.023h5.75a.25.25 0 00.25-.25V11.9a4.456 4.456 0 00-4.5-4.4 4.53 4.53 0 00-3.6 1.76.25.25 0 00.005.311 6.955 6.955 0 011.476 3.19zM10.952 14.02a.25.25 0 00.048-.148A5.5 5.5 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81v-2.562a2.474 2.474 0 01.452-1.418z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M23.872 18.042a.252.252 0 00-.234-.005l-.027.016-4.411 3.07-.011.008a2.263 2.263 0 01-2.373 0l-.011-.008-4.411-3.067a.248.248 0 00-.263-.014.251.251 0 00-.129.219V22.5A1.5 1.5 0 0013.5 24h9a1.5 1.5 0 001.5-1.5v-4.239a.251.251 0 00-.128-.219z\"/><path d=\"M22.5 14.5h-9A1.5 1.5 0 0012 16a.077.077 0 00.034.063l5.56 3.787.011.007a.753.753 0 00.782 0l.012-.007 5.56-3.787A.074.074 0 0024 16a1.5 1.5 0 00-1.5-1.5z\"/><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.381 12.761a.251.251 0 00.284.2 2.174 2.174 0 01.335-.023h5.75a.25.25 0 00.25-.25V11.9a4.456 4.456 0 00-4.5-4.4 4.53 4.53 0 00-3.6 1.76.25.25 0 00.005.311 6.955 6.955 0 011.476 3.19zM10.952 14.02a.25.25 0 00.048-.148A5.5 5.5 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81v-2.562a2.474 2.474 0 01.452-1.418z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M23.872 18.042a.252.252 0 00-.234-.005l-.027.016-4.411 3.07-.011.008a2.263 2.263 0 01-2.373 0l-.011-.008-4.411-3.067a.248.248 0 00-.263-.014.251.251 0 00-.129.219V22.5A1.5 1.5 0 0013.5 24h9a1.5 1.5 0 001.5-1.5v-4.239a.251.251 0 00-.128-.219z\"/><path d=\"M22.5 14.5h-9A1.5 1.5 0 0012 16a.077.077 0 00.034.063l5.56 3.787.011.007a.753.753 0 00.782 0l.012-.007 5.56-3.787A.074.074 0 0024 16a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});