define("ember-svg-jar/inlined/body-care-toothbrush", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>body-care-toothbrush</title><path d=\"M23.394 5.656a1.494 1.494 0 00-1.411-2.511.5.5 0 01-.472-.132L19.124.639a2.047 2.047 0 00-2.828 0L11.053 5.88a2 2 0 000 2.83l2.17 2.16a.5.5 0 010 .708l-.278.278a1.525 1.525 0 01-.509.336L8.9 13.59a4.473 4.473 0 00-1.523 1L.606 21.368a1.5 1.5 0 102.121 2.121L9.5 16.711a1.5 1.5 0 01.507-.333l3.532-1.4a4.516 4.516 0 001.523-1zM12.822 7.648a.5.5 0 010-.708l.442-.441a.5.5 0 01.709 0l.76.771A.75.75 0 0015.8 6.22l-.773-.784a.5.5 0 010-.7l.266-.265a.5.5 0 01.709 0l.744.75a.75.75 0 101.064-1.056l-.75-.757a.5.5 0 010-.706l.3-.293a.5.5 0 01.7 0l1.819 1.81a.5.5 0 010 .708l-4.532 4.53a.5.5 0 01-.706 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});