define("ember-svg-jar/inlined/meeting-team-monitor-man-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-team-monitor-man-1</title><circle cx=\"14.33\" cy=\"4.999\" r=\"2\"/><path d=\"M17.154 11a.5.5 0 00.47-.683 3.492 3.492 0 00-6.472-.259.252.252 0 00.01.24 5.571 5.571 0 01.294.557.25.25 0 00.227.145z\"/><path d=\"M24 2a2 2 0 00-2-2H6a2 2 0 00-2 2v4.5a1 1 0 002 0v-4a.5.5 0 01.5-.5h15a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-9a1 1 0 100 2h.5a.25.25 0 01.25.25v.5A.249.249 0 0113 17h-.5a1 1 0 000 2h5a1 1 0 000-2H15a.249.249 0 01-.25-.25v-.5A.25.25 0 0115 16h7a2 2 0 002-2z\"/><path d=\"M10.975 13.237A4.738 4.738 0 103.82 17.31a.251.251 0 01.121.231.248.248 0 01-.151.213A6.271 6.271 0 000 23.5a.5.5 0 00.5.5H12a.5.5 0 00.5-.5 6.254 6.254 0 00-3.166-5.434 6.327 6.327 0 00-.639-.317.25.25 0 01-.03-.444 4.742 4.742 0 002.31-4.068zm-7.314-.925a.25.25 0 01.375-.123A6.2 6.2 0 008.8 13.1a.144.144 0 01.175.141 2.738 2.738 0 11-5.314-.925z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});