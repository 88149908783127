define("ember-svg-jar/inlined/social-media-bnter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-bnter</title><path d=\"M21.69 2.94H2.31A2.32 2.32 0 000 5.25v11.38a2.32 2.32 0 002.31 2.32h.39a2.93 2.93 0 01-.4 1.37.5.5 0 00.43.75c.11 0 2.51-.14 3.27-2.12h15.69A2.32 2.32 0 0024 16.63V5.25a2.32 2.32 0 00-2.31-2.31zM4.32 13.41A1.36 1.36 0 115.68 12a1.36 1.36 0 01-1.36 1.41zm8.14 3.25a2.67 2.67 0 01-2.54-1.84.5.5 0 01.38-.65l4.23-.86a.5.5 0 01.6.46v.21a2.68 2.68 0 01-2.68 2.68zm7.32-3.25A1.36 1.36 0 1121.13 12a1.36 1.36 0 01-1.36 1.41z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});