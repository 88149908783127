define("ember-svg-jar/inlined/ranking-people-first", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ranking-people-first</title><circle cx=\"3.67\" cy=\"9.35\" r=\"2.85\"/><path d=\"M.534 14.75h6.272a.5.5 0 00.389-.814 4.509 4.509 0 00-3.526-1.686 4.5 4.5 0 00-3.524 1.687.5.5 0 00.389.813zM12.116 6.95a2.85 2.85 0 10-2.85-2.85 2.853 2.853 0 002.85 2.85zM8.47 9.75h7.292a.5.5 0 00.389-.814 5.16 5.16 0 00-4.036-1.93 5.155 5.155 0 00-4.034 1.931.5.5 0 00.389.813z\"/><circle cx=\"20.334\" cy=\"9.35\" r=\"2.85\"/><path d=\"M17.2 14.75h6.27a.5.5 0 00.389-.814 4.509 4.509 0 00-3.526-1.686 4.5 4.5 0 00-3.523 1.687.5.5 0 00.389.813z\"/><path d=\"M23 16.25h-6.5a.5.5 0 01-.5-.5v-3.5a1 1 0 00-1-1H9a1 1 0 00-1 1v3.5a.5.5 0 01-.5.5H1a1 1 0 00-1 1v4.5a1 1 0 001 1h22a1 1 0 001-1v-4.5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});