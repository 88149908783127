define("ember-svg-jar/inlined/toys-ping-pong", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toys-ping-pong</title><path d=\"M23.416 13.447a.5.5 0 01-.166-.372V9.75a3 3 0 00-3-3h-.137a.5.5 0 01-.492-.409 7.75 7.75 0 00-15.242 0 .5.5 0 01-.492.409H3.75a3 3 0 00-3 3v3.325a.5.5 0 01-.166.372 1.75 1.75 0 102.332 0 .5.5 0 01-.166-.372V9.75a1 1 0 011-1h.138a.5.5 0 01.491.409 7.77 7.77 0 006.212 6.212.5.5 0 01.409.491V23a1 1 0 002 0v-7.138a.5.5 0 01.409-.491 7.77 7.77 0 006.212-6.212.5.5 0 01.491-.409h.138a1 1 0 011 1v3.325a.5.5 0 01-.166.372 1.75 1.75 0 102.332 0zM12 11a3.25 3.25 0 113.25-3.25A3.254 3.254 0 0112 11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});