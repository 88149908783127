define("ember-svg-jar/inlined/single-neutral-actions-mail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-mail</title><path d=\"M0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h1.56a.25.25 0 00.25-.25v-2.25a2.473 2.473 0 01.5-1.484v-.016a5.5 5.5 0 00-11 0z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M13.5 23.75h9a1.5 1.5 0 001.5-1.5v-4.239a.25.25 0 00-.383-.212l-4.425 3.078a2.26 2.26 0 01-2.384 0L12.383 17.8a.25.25 0 00-.383.212v4.238a1.5 1.5 0 001.5 1.5z\"/><path d=\"M17.6 19.605a.752.752 0 00.794 0l5.568-3.792A.075.075 0 0024 15.75a1.5 1.5 0 00-1.5-1.5h-9a1.5 1.5 0 00-1.5 1.5.073.073 0 00.035.063zM0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h1.56a.25.25 0 00.25-.25v-2.25a2.473 2.473 0 01.5-1.484v-.016a5.5 5.5 0 00-11 0z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M13.5 23.75h9a1.5 1.5 0 001.5-1.5v-4.239a.25.25 0 00-.383-.212l-4.425 3.078a2.26 2.26 0 01-2.384 0L12.383 17.8a.25.25 0 00-.383.212v4.238a1.5 1.5 0 001.5 1.5z\"/><path d=\"M17.6 19.605a.752.752 0 00.794 0l5.568-3.792A.075.075 0 0024 15.75a1.5 1.5 0 00-1.5-1.5h-9a1.5 1.5 0 00-1.5 1.5.073.073 0 00.035.063z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});