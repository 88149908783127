define("ember-svg-jar/inlined/phone-actions-ringing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-ringing</title><path d=\"M11.5 0a1 1 0 000 2A10.512 10.512 0 0122 12.5a1 1 0 002 0A12.514 12.514 0 0011.5 0z\"/><path d=\"M11.5 4a1 1 0 000 2 6.508 6.508 0 016.5 6.5 1 1 0 002 0A8.51 8.51 0 0011.5 4z\"/><path d=\"M14.25 12.5a.75.75 0 001.5 0 4.254 4.254 0 00-4.25-4.25.75.75 0 000 1.5 2.752 2.752 0 012.75 2.75zM15.5 15.792a3.119 3.119 0 00-4.4 0l-.826.827a4.252 4.252 0 00-1.347 4.5 11.345 11.345 0 01-6.03-6.031 4.242 4.242 0 004.5-1.345l.832-.83a3.119 3.119 0 000-4.4L4.634 4.932a2.234 2.234 0 00-3.648.74 13.322 13.322 0 008.978 17.914 13.6 13.6 0 008.378-.559 2.233 2.233 0 00.738-3.649z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});