define("ember-svg-jar/inlined/astrology-aries-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astrology-aries-1</title><path d=\"M21.791 3.678a1 1 0 00-1.4-.179c-4.846 3.751-6.753 7.933-8.148 12.837a.25.25 0 01-.481 0c-1.394-4.9-3.3-9.086-8.147-12.837a1 1 0 10-1.227 1.582c5.231 4.048 6.68 8.56 8.156 14.492a1.5 1.5 0 002.912 0c1.476-5.931 2.926-10.443 8.156-14.491a1 1 0 00.179-1.404z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});