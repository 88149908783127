define("ember-svg-jar/inlined/spa-soap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>spa-soap</title><path d=\"M11.135 22.328a4.1 4.1 0 01.18-1.393.5.5 0 00-.178-.546 3.527 3.527 0 01-1.4-3.878 3.332 3.332 0 012.031-2.059 4.088 4.088 0 011.382-.259.5.5 0 00.464-.335 4.222 4.222 0 01.459-.922.5.5 0 00.082-.275V9.5a2.5 2.5 0 00-2.5-2.5h-.563l-.625-1.751a1.5 1.5 0 00-1.414-1A.151.151 0 018.9 4.1V2.5a.5.5 0 01.5-.5h2a1 1 0 000-2H4.876a2 2 0 00-1.543.727L1.57 2.863a1 1 0 001.543 1.274l1.613-1.955A.5.5 0 015.112 2H6.4a.5.5 0 01.5.5V4a.247.247 0 01-.247.247 1.5 1.5 0 00-1.412 1L4.618 7h-.461a2.5 2.5 0 00-2.5 2.5v12a2.5 2.5 0 002.5 2.5h6.706a.5.5 0 00.471-.67 3.4 3.4 0 01-.199-1.002z\"/><path d=\"M20.819 19.649a2.293 2.293 0 001.76-2.674 2.292 2.292 0 00-3-1.13 3.061 3.061 0 00-.593.264.125.125 0 01-.185-.135 3.087 3.087 0 00.068-.645 2.293 2.293 0 00-2-2.5 2.292 2.292 0 00-2 2.5 3.087 3.087 0 00.067.645.125.125 0 01-.049.128.126.126 0 01-.136.007 3.061 3.061 0 00-.593-.264 2.292 2.292 0 00-3 1.13 2.293 2.293 0 001.76 2.674 3.04 3.04 0 00.635.135.125.125 0 01.106.086.127.127 0 01-.035.132 3.106 3.106 0 00-.435.482 2.294 2.294 0 00.149 3.2 2.291 2.291 0 003.087-.848 2.985 2.985 0 00.325-.562.126.126 0 01.229 0 3.041 3.041 0 00.325.562 2.291 2.291 0 003.087.848 2.294 2.294 0 00.149-3.2 3.056 3.056 0 00-.427-.484.127.127 0 01-.035-.132.125.125 0 01.106-.086 3.027 3.027 0 00.635-.133zm-3.946.679a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});