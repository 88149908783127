define("ember-svg-jar/inlined/toys-balloon-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toys-balloon-alternate</title><path d=\"M8.539 5.907A1.25 1.25 0 116.92 5.2a1.249 1.249 0 011.619.707zM13.118 5.868a1.25 1.25 0 11-1.375-1.111 1.25 1.25 0 011.375 1.111zM9.791 12.783A4.258 4.258 0 016.2 11a1 1 0 111.675-1.1 2.382 2.382 0 002.231.862 2.375 2.375 0 002-1.31 1 1 0 011.866.721 4.344 4.344 0 01-3.655 2.577 4.906 4.906 0 01-.526.033z\"/><path d=\"M21.771 23.764a1 1 0 00.118-1.41l-.982-1.16a4.391 4.391 0 00-5.422-1.059 2.413 2.413 0 01-1.729.195.25.25 0 01.013-.487 1.467 1.467 0 001.158-.929 1.492 1.492 0 00-.278-1.537l-.716-.807a.5.5 0 01.107-.755 8.034 8.034 0 002.122-1.966 8.727 8.727 0 001.675-6.2A8.271 8.271 0 008.985.042a8.271 8.271 0 00-7.059 9.3 8.726 8.726 0 002.936 5.708 8.02 8.02 0 002.532 1.49.5.5 0 01.266.717l-.517.916a1.5 1.5 0 001.3 2.238 1.435 1.435 0 00.161-.009l1.627-.172a.5.5 0 01.461.208 4.416 4.416 0 005.719 1.468 2.4 2.4 0 012.967.58l.981 1.16a1 1 0 001.41.118zM3.914 9.127A6.272 6.272 0 019.2 2.031 5.852 5.852 0 019.784 2a6.338 6.338 0 016.065 5.863 6.734 6.734 0 01-1.286 4.782 5.674 5.674 0 01-8.39.89 6.732 6.732 0 01-2.259-4.408zm6.058 8.1a.5.5 0 01.409-.253 7.494 7.494 0 00.846-.093.5.5 0 01.457.162l.5.566a.251.251 0 01-.16.415l-2.182.231a.25.25 0 01-.243-.372z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});