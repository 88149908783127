define("ember-svg-jar/inlined/coin-purse-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>coin-purse-1</title><path d=\"M19.426 11.2a.5.5 0 01-.426-.495v-1.08a1.5 1.5 0 00-1.5-1.5h-11a1.5 1.5 0 00-1.5 1.5v1.08a.5.5 0 01-.427.493A5.7 5.7 0 000 17.169a6.066 6.066 0 006.11 5.956h11.78A6.066 6.066 0 0024 17.169a5.7 5.7 0 00-4.574-5.969z\"/><path d=\"M3 8.625a1.25 1.25 0 001.25-1.25v-.5a.311.311 0 01.338-.25h14.824a.311.311 0 01.338.25v.5a1.25 1.25 0 002.5 0v-.5a2.8 2.8 0 00-2.838-2.75h-3.4a.25.25 0 01-.223-.364A1.939 1.939 0 0016 2.875a2 2 0 00-2-2 1.977 1.977 0 00-1.785 1.01.25.25 0 01-.431 0A1.982 1.982 0 0010 .875a2 2 0 00-2 2 1.939 1.939 0 00.215.886.25.25 0 01-.223.364h-3.4a2.8 2.8 0 00-2.842 2.75v.5A1.25 1.25 0 003 8.625z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});