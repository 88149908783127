define("ember-svg-jar/inlined/toilet-paper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toilet-paper</title><path d=\"M19.729.5c-2.243 0-4 2.855-4 6.5s1.757 6.5 4 6.5 4-2.855 4-6.5-1.757-6.5-4-6.5zm0 8.5c-.553 0-1-.895-1-2s.447-2 1-2 1 .9 1 2-.447 2-1 2z\"/><path d=\"M17.291.219A.114.114 0 0017.245 0H7.229c-1.93 0-3.5 2.691-3.5 6v12.5a.5.5 0 00.5.5H8.26a1.991 1.991 0 011.984 1.742 1.994 1.994 0 001.539 1.7A3.5 3.5 0 0014.729 19V6c0-2.752 1.086-5.076 2.562-5.781zM7.77 11.75h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zm4.5 0h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5z\"/><path d=\"M11.007 23.78a3.5 3.5 0 01-2.247-2.812.524.524 0 00-.5-.468H.771a.5.5 0 00-.493.583A3.5 3.5 0 003.729 24h7.243a.113.113 0 00.035-.22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});