define("ember-svg-jar/inlined/technology-bandage-chip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>technology-bandage-chip</title><path d=\"M21.8 2.2a7.51 7.51 0 00-10.6 0l-9 9a7.5 7.5 0 0010.6 10.6l9-9a7.509 7.509 0 000-10.6zm-6.165 10.1l.892.892a.75.75 0 01-1.06 1.061l-.436-.436a.5.5 0 00-.707 0l-.518.518a.5.5 0 000 .707l.436.436a.75.75 0 11-1.061 1.061l-.9-.9a.4.4 0 00-.57 0l-.9.9a.75.75 0 11-1.061-1.061l.436-.435a.5.5 0 000-.708l-.518-.518a.5.5 0 00-.707 0l-.436.436a.75.75 0 01-1.06-1.061l.892-.892c.09-.09.231-.352.081-.5l-.973-.973A.75.75 0 018.53 9.755l.436.436a.5.5 0 00.707 0l.518-.519a.5.5 0 000-.707l-.436-.435a.75.75 0 111.061-1.061l.892.892c.091.09.352.231.5.081l.974-.973a.75.75 0 111.063 1.061l-.436.435a.5.5 0 000 .707l.518.519a.5.5 0 00.707 0l.436-.436a.75.75 0 011.06 1.061l-.972.972c-.151.15-.01.412.08.503z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});