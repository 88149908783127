define("ember-svg-jar/inlined/water-pitcher-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-pitcher-1</title><path d=\"M23.942 14.368L20.7 3.883A5.861 5.861 0 0015.189 0H3.434A2.412 2.412 0 001.24 1.367a1.486 1.486 0 000 1.324 1.5 1.5 0 001.1.8 2.385 2.385 0 011.941 2.441c0 .95-.561 1.553-1.411 2.467a9.487 9.487 0 00-2.531 4.077v.006A9.388 9.388 0 000 15.1C0 20.283 4.177 24 9.311 24s9.312-3.717 9.312-8.9a10.24 10.24 0 00-.307-2.611 8.642 8.642 0 00-3.459-4.763c-1.194-.964-1.983-1.6-1.983-2.978 0-2.576 1.751-2.776 2.287-2.776h.03a3.888 3.888 0 013.654 2.575l3.243 10.483a1 1 0 001.257.6.983.983 0 00.597-1.262zM3.183 11.755a.249.249 0 01-.21-.385 11.692 11.692 0 011.342-1.633 5.425 5.425 0 001.935-3.8 4.519 4.519 0 00-1.63-3.523.25.25 0 01.158-.444h6.391a.249.249 0 01.225.357 5.582 5.582 0 00-.494 2.419c0 2.318 1.443 3.483 2.716 4.511a9.125 9.125 0 012.094 2.113.25.25 0 01-.211.385z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});