define("ember-svg-jar/inlined/search-engine-bing-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>search-engine-bing-1</title><path d=\"M12 6.23A15.72 15.72 0 004.5 8V2.53A.5.5 0 004 2H1a.5.5 0 00-.5.5v11.82C.69 18.56 5.76 22 12 22s11.5-3.52 11.5-7.87-5.15-7.9-11.5-7.9zm0 13.11c-4 0-7.32-2.35-7.32-5.25S8 8.85 12 8.85s7.32 2.35 7.32 5.25S16 19.34 12 19.34z\"/><ellipse cx=\"12\" cy=\"14.1\" rx=\"4.37\" ry=\"3.13\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});