define("ember-svg-jar/inlined/paralympics-running", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paralympics-running</title><circle cx=\"14.75\" cy=\"2.5\" r=\"2.5\"/><path d=\"M21.687 6.29a1.5 1.5 0 00-2.108.234l-.656.819a1.492 1.492 0 01-1.684.472l-7-2.544a4.513 4.513 0 00-4.994 1.35L4.1 8a1.5 1.5 0 102.3 1.92l1.153-1.38a1.506 1.506 0 011.665-.449l1.208.439a.5.5 0 01.3.638c-.2.574-.662 1.857-1.714 4.806a1.5 1.5 0 01-.764.874 1.179 1.179 0 00-.659 1.058 1.609 1.609 0 002.5 1.34 4.458 4.458 0 001.55-1.775 4.073 4.073 0 011.66.942 1.152 1.152 0 001.114.443 1.608 1.608 0 00.874-2.717 4.048 4.048 0 00-1.41-1l-1.207-.482.876-2.454a.5.5 0 01.642-.3l2.028.737a4.49 4.49 0 005.052-1.417l.655-.818a1.5 1.5 0 00-.236-2.115zM5.578 15.679l-1.219.071a2.629 2.629 0 00-1.448 4.81 1 1 0 101.11-1.66.631.631 0 01.4-1.148l1.278-.073a1 1 0 00.94-1.055 1.014 1.014 0 00-1.061-.945zM15.543 21.846a.617.617 0 01-.589.126.607.607 0 01-.434-.742l.546-2.077a1 1 0 00-1.934-.508l-.547 2.077a2.63 2.63 0 004.286 2.62 1 1 0 10-1.328-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});