define("ember-svg-jar/inlined/module-hand-puzzle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>module-hand-puzzle</title><path d=\"M17.811 8.35a2 2 0 013.276.683.5.5 0 00.819.171l1.561-1.562a1 1 0 000-1.414L22.139 4.9a.252.252 0 01.069-.4 2 2 0 10-2.276-3.219 2.037 2.037 0 00-.391.552.245.245 0 01-.183.139.249.249 0 01-.219-.069L17.811.571a1 1 0 00-1.415 0l-1.449 1.45a.5.5 0 00.077.771 2 2 0 11-2.771 2.771.5.5 0 00-.771-.077l-1.45 1.449a1 1 0 000 1.415l5.657 5.656a1 1 0 001.415 0l1.561-1.561a.5.5 0 00-.171-.819 2 2 0 01-.683-3.276zM22.567 19.117a.514.514 0 00.084-.86 2.48 2.48 0 00-2.411-.479l-3.425 1.136a.5.5 0 00-.29.252 1.994 1.994 0 01-1.785 1.112h-4a.5.5 0 010-1h4a1 1 0 100-2h-3.414a.253.253 0 01-.152-.051 7 7 0 00-3.934-1.449H5.184A4 4 0 003.4 16.2L.378 17.709a.25.25 0 00-.138.224v5.394a.249.249 0 00.382.212L4.04 21.4a.5.5 0 01.424-.05c10.251 3.439 6.773 3.498 18.103-2.233z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});