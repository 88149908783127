define("ember-svg-jar/inlined/weather-cloud-snow-thunder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-cloud-snow-thunder</title><path d=\"M17.5 16a5 5 0 003.094-8.926.252.252 0 01-.1-.193A7 7 0 006.838 4.84a.249.249 0 01-.22.173A5.5 5.5 0 007 16zM7 7a3.487 3.487 0 01.448.029A1 1 0 008.564 6.2 5 5 0 0118.5 7c0 .146-.007.289-.02.433a1 1 0 00.5.959A3 3 0 0117.5 14H7a3.5 3.5 0 010-7z\"/><circle cx=\"8\" cy=\"23\" r=\"1\"/><circle cx=\"6\" cy=\"21\" r=\"1\"/><circle cx=\"16\" cy=\"23\" r=\"1\"/><circle cx=\"18\" cy=\"21\" r=\"1\"/><circle cx=\"8\" cy=\"19\" r=\"1\"/><circle cx=\"16\" cy=\"19\" r=\"1\"/><path d=\"M13.461 22.092a.75.75 0 00-.011-1.192l-.953-.714a.25.25 0 010-.4l1.217-.946a.75.75 0 00-.922-1.184l-2.25 1.75a.75.75 0 00.008 1.194l.953.714a.25.25 0 010 .4l-1.217.946a.75.75 0 00.922 1.184z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});