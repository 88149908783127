define("ember-svg-jar/inlined/design-file-vector-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-vector-alternate</title><path d=\"M14.3 10.1c.177-.177.519-.1.7-.1a2 2 0 002-2 2 2 0 10-4 0c0 .173.07.481-.1.668l-4.744 4.748c-.194.194-.483.085-.656.085a2 2 0 102 2c0-.182-.113-.489.087-.689z\"/><path d=\"M21.707 5.707L16.293.293A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.465a.5.5 0 01.354.146l4.535 4.536a.5.5 0 01.146.353z\"/><path d=\"M16.917 11.5h-1.168a4.336 4.336 0 00-4.332 4.332V17.5a1 1 0 002 0v-1.668a2.334 2.334 0 012.332-2.332h1.168a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});