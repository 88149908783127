define("ember-svg-jar/inlined/cog-circle-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cog-circle-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M17.769 10.654a1.783 1.783 0 00-1.7-2.953l-1.411.327a.453.453 0 01-.533-.311L13.7 6.325a1.776 1.776 0 00-3.4 0l-.424 1.392a.454.454 0 01-.533.311L7.932 7.7a1.783 1.783 0 00-1.7 2.952l.988 1.066a.463.463 0 010 .625l-.988 1.065a1.783 1.783 0 001.7 2.952l1.411-.326a.451.451 0 01.533.311l.424 1.392a1.776 1.776 0 003.4 0l.424-1.392a.449.449 0 01.533-.311l1.411.326A1.738 1.738 0 0018 15.521a1.757 1.757 0 00-.234-2.112l-.988-1.065a.463.463 0 010-.625zM12 14.281a2.25 2.25 0 112.25-2.25 2.25 2.25 0 01-2.25 2.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});