define("ember-svg-jar/inlined/wifi-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-heart</title><path d=\"M14.782 13.041a2.928 2.928 0 00-2.557.825l-.146.146a.25.25 0 01-.354 0l-.145-.146A2.949 2.949 0 007.4 18.03l3.777 3.94a1 1 0 001.445 0l3.77-3.934a2.948 2.948 0 00-1.613-5zM20.128 5.088A11.412 11.412 0 0012 1.72a11.419 11.419 0 00-8.128 3.368A1 1 0 105.286 6.5 9.437 9.437 0 0112 3.72a9.429 9.429 0 016.714 2.78 1 1 0 001.414-1.414z\"/><path d=\"M8.91 9.5a4.375 4.375 0 016.179 0A1 1 0 0016.5 8.085a6.378 6.378 0 00-9.007 0A1 1 0 108.91 9.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});