define("ember-svg-jar/inlined/style-three-pin-ink", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-ink</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm1 12.01a1 1 0 01.86 1.51.25.25 0 00.076.336 3.431 3.431 0 011.453 1.862 1 1 0 01-.96 1.28H7.571a1 1 0 01-.96-1.279 3.43 3.43 0 011.453-1.863.25.25 0 00.076-.336A1 1 0 019 12.01zm3.539-7.266a1.2 1.2 0 00-.788.481L13.223 8.7a2.387 2.387 0 01-1.981.972 2.688 2.688 0 01-.594-.067.5.5 0 00-.514.193l-.73 1a.5.5 0 11-.808-.588l1.177-1.621 2.651-3.636a3.639 3.639 0 012.23-1.545 2.688 2.688 0 012.1.438.5.5 0 01-.217.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});