define("ember-svg-jar/inlined/style-three-pin-chemistry-epruvette", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-chemistry-epruvette</title><rect x=\"10.5\" y=\"9\" width=\"3\" height=\"4\" rx=\".5\" ry=\".5\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm2.5 17a2.5 2.5 0 01-5 0V8.5A.5.5 0 0110 8h4a.5.5 0 01.5.5zm-.75-11.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm3 3a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});