define("ember-svg-jar/inlined/audio-document-wav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-document-wav</title><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zm-12.344 6.264a10.143 10.143 0 01-1.066 4.515.625.625 0 01-.559.346.527.527 0 01-.068 0 .624.624 0 01-.538-.47s-.237-.924-.272-1.088-.207-.143-.243 0l-.272 1.089a.626.626 0 01-.538.47.611.611 0 01-.627-.342 10.13 10.13 0 01-1.067-4.515V9a.625.625 0 011.25 0v1.764a8.852 8.852 0 00.13 1.506c.036.207.21.234.272.047l.367-1.468a.625.625 0 011.213 0l.356 1.424c.049.207.246.2.282 0a8.852 8.852 0 00.13-1.506V9a.625.625 0 011.25 0zm5 4.236a.625.625 0 01-1.25 0v-2.125a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0v-4.375a2.211 2.211 0 011.9-2.238 2.127 2.127 0 012.35 2.113zm5-4.5a8.176 8.176 0 01-1.625 4.875.625.625 0 01-1 0 8.176 8.176 0 01-1.625-4.875V9a.625.625 0 011.25 0v1.5a6.93 6.93 0 00.674 2.966.252.252 0 00.4.011 6.93 6.93 0 00.679-2.977V9a.625.625 0 011.25 0z\"/><path d=\"M13.031 9.625a.877.877 0 00-.875.875v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V10.5a.876.876 0 00-.875-.875z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});