define("ember-svg-jar/inlined/single-neutral-actions-mail.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-mail</title><path d=\"M10.5 15.5a2.487 2.487 0 011.5-2.281.5.5 0 00.131-.834 7.46 7.46 0 00-12.11 3.472.5.5 0 00.479.643H10a.5.5 0 00.5-.5z\"/><circle cx=\"7\" cy=\"4.75\" r=\"4.75\"/><path d=\"M23.871 18.042a.249.249 0 00-.254.007l-4.425 3.078a2.26 2.26 0 01-2.384 0l-4.425-3.078a.25.25 0 00-.383.212V22.5a1.5 1.5 0 001.5 1.5h9a1.5 1.5 0 001.5-1.5v-4.239a.249.249 0 00-.129-.219z\"/><path d=\"M24 16a1.5 1.5 0 00-1.5-1.5h-9A1.5 1.5 0 0012 16a.074.074 0 00.035.063l5.565 3.792a.752.752 0 00.794 0l5.568-3.792A.075.075 0 0024 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});