define("ember-svg-jar/inlined/breakup-couple-man-woman-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>breakup-couple-man-woman-1</title><path d=\"M12.02 5.75h-1.544a.75.75 0 01-.7-1.006l1.353-3.721a.5.5 0 00-.2-.594A2.749 2.749 0 009.123.02a3.1 3.1 0 00-2.647 3.213c0 2.116 2.6 4.58 4.246 5.924a.25.25 0 00.391-.1l1.14-2.963a.25.25 0 00-.233-.34z\"/><path d=\"M17.476 3.233A3.1 3.1 0 0014.83.02a2.748 2.748 0 00-1.773.394.5.5 0 00-.208.254l-1.18 3.247a.249.249 0 00.235.335h1.572a.75.75 0 01.7 1.02l-1.408 3.661a.25.25 0 00.391.284c1.658-1.356 4.317-3.836 4.317-5.982zM6.823 13.47a.247.247 0 01-.119-.2.251.251 0 01.094-.211 2.918 2.918 0 10-3.616 0 .254.254 0 01.1.211.25.25 0 01-.119.2 3.515 3.515 0 00-1.682 3v2.011a.5.5 0 00.5.5h.6a.5.5 0 01.5.45l.413 4.123a.5.5 0 00.5.45H6a.5.5 0 00.5-.45l.412-4.123a.5.5 0 01.5-.45H8a.5.5 0 00.5-.5v-2.014a3.514 3.514 0 00-1.677-2.997zM4.99 12.191a1.419 1.419 0 01-1.418-1.418 1.481 1.481 0 01.028-.285.25.25 0 01.367-.168 3.576 3.576 0 001.746.452 3.453 3.453 0 00.4-.022.251.251 0 01.273.3 1.421 1.421 0 01-1.396 1.141zM22.5 19.855l-1.19-3.922a2.947 2.947 0 00-1.333-1.7.25.25 0 010-.433 3.042 3.042 0 10-3.006 0 .25.25 0 010 .433 2.953 2.953 0 00-1.334 1.7l-1.192 3.922a.5.5 0 00.079.446.594.594 0 00.407.2l.867-.012a.5.5 0 01.5.45l.261 2.612a.5.5 0 00.5.45h2.821a.5.5 0 00.5-.45l.261-2.612a.5.5 0 01.5-.45l.868.012a.6.6 0 00.406-.2.5.5 0 00.085-.446zM18.476 12.7a1.543 1.543 0 01-1.447-1.011.248.248 0 01.152-.322 3.054 3.054 0 001.12-.7.25.25 0 01.349 0 3.047 3.047 0 001.121.7.251.251 0 01.152.322 1.543 1.543 0 01-1.447 1.011z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});