define("ember-svg-jar/inlined/car-repair-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-repair-lock</title><path d=\"M5.25 12.779h-4a.25.25 0 00-.25.25v.5a1.251 1.251 0 001.25 1.25h2a1.251 1.251 0 001.25-1.25v-.5a.25.25 0 00-.25-.25zM15.1 11.779a.247.247 0 00.2-.108 4.489 4.489 0 013.7-1.95 4.263 4.263 0 011.181.173.251.251 0 00.319-.241V8.236a1.258 1.258 0 00-.366-.884l-1.085-1.085a.5.5 0 01-.111-.168L16.888.973A1.494 1.494 0 0015.5.029H5.171a1.5 1.5 0 00-1.393.943l-1.94 4.851a.5.5 0 01-.111.168L.22 7.5a.747.747 0 00-.22.53v2.5a1.251 1.251 0 001.25 1.25zM4.447 4.686l1-2.5a.25.25 0 01.232-.157h9.308a.25.25 0 01.232.157l1 2.5a.25.25 0 01-.232.343H4.679a.25.25 0 01-.207-.11.254.254 0 01-.025-.233z\"/><path d=\"M22.714 15.486a.249.249 0 01-.214-.247v-1.018a3.5 3.5 0 00-7 0v1.018a.249.249 0 01-.214.247A1.5 1.5 0 0014 16.971v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5v-5.5a1.5 1.5 0 00-1.286-1.485zM19 21.221a1 1 0 111-1 1 1 0 01-1 1zm1.5-6a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-1a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});