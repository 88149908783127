define("ember-svg-jar/inlined/road-sign-horn-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-horn-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M19.287 10.057a.749.749 0 00-.817.163l-.707.707a.251.251 0 01-.177.073h-6.869a.266.266 0 01-.1-.022L6.018 8.889a1.251 1.251 0 00-1.768 1.138v3.447a1.247 1.247 0 001.768 1.137l.912-.411a.25.25 0 01.289.06A3.759 3.759 0 0010 15.5h4.5a2.253 2.253 0 002.25-2.25 2.053 2.053 0 00-.05-.446.254.254 0 01.049-.211.25.25 0 01.2-.093h.642a.251.251 0 01.177.073l.707.707a.746.746 0 00.53.22.75.75 0 00.75-.75v-2a.75.75 0 00-.468-.693zM14.5 14H10a2.174 2.174 0 01-1.058-.28.249.249 0 01.018-.446l1.653-.752a.266.266 0 01.1-.022H14.5a.75.75 0 010 1.5z\"/><path d=\"M11 9.75h1a.75.75 0 000-1.5h-1a.75.75 0 000 1.5zM14 9.75h1a.75.75 0 000-1.5h-1a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});