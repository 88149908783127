define("ember-svg-jar/inlined/fossil-energy-battery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fossil-energy-battery</title><path d=\"M13.5 8.25H13a.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0V8a.25.25 0 01-.25.25h-.5a.75.75 0 000 1.5h.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0V10a.25.25 0 01.25-.25h.5a.75.75 0 000-1.5z\"/><path d=\"M16 3.5h-.25a.25.25 0 01-.25-.25V1.5A1.5 1.5 0 0014 0h-4a1.5 1.5 0 00-1.5 1.5v1.75a.25.25 0 01-.25.25H8a3 3 0 00-3 3v15A2.5 2.5 0 007.5 24h9a2.5 2.5 0 002.5-2.5v-15a3 3 0 00-3-3zm-2.5 15.75h-3a.75.75 0 010-1.5h3a.75.75 0 010 1.5zM17 12a.5.5 0 01-.5.5h-9A.5.5 0 017 12V6.5a1 1 0 011-1h8a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});