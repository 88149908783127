define("ember-svg-jar/inlined/ambulance-helicopter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ambulance-helicopter</title><path d=\"M3.27 4.625h13.98a1 1 0 000-2h-5.48a.5.5 0 01-.5-.5v-.25a1 1 0 00-2 0v.25a.5.5 0 01-.5.5h-5.5a1 1 0 100 2zM20.5 4.875a3.481 3.481 0 00-3.2 2.1.249.249 0 01-.229.151h-5.3a.5.5 0 01-.5-.5v-.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v.5a.5.5 0 01-.5.5H8.5a8.506 8.506 0 00-8.5 8.5 2.5 2.5 0 002.5 2.5h.3a.5.5 0 01.463.688l-.817 2.01a.455.455 0 01-.041.079.25.25 0 01-.45-.063A1 1 0 000 21.125a2 2 0 002 2h10a2 2 0 002-2 1 1 0 00-1.958-.286.25.25 0 01-.45.064.516.516 0 01-.042-.081l-.877-2.16a.5.5 0 01.327-.671c.58-.16.788-.5.888-1.166.394-2.579 4.905-5.469 5.864-6a.25.25 0 01.3.042 3.45 3.45 0 002.448 1.008 3.5 3.5 0 000-7zM9.186 20.9a.5.5 0 01-.415.221H5.229a.5.5 0 01-.463-.688l.786-1.933.016-.042a.5.5 0 01.472-.334h1.921a.5.5 0 01.471.333l.016.043.786 1.936a.5.5 0 01-.048.464zm1.064-7.529a.25.25 0 01-.25.25H8.5a.25.25 0 00-.25.25v1.5a.25.25 0 01-.25.25H6.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 00-.25-.25H4.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25H6a.25.25 0 00.25-.25v-1.5a.25.25 0 01.25-.25H8a.25.25 0 01.25.25v1.5a.25.25 0 00.25.25H10a.25.25 0 01.25.25zm10.25-3.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.504z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});