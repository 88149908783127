define("ember-svg-jar/inlined/design-draw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-draw</title><path d=\"M18 21a1 1 0 00-1 1H4a.5.5 0 01-.5-.5v-.25a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2H17a2 2 0 002-2 1 1 0 00-1-1zM3.25 5a.25.25 0 00.25-.25V2.5A.5.5 0 014 2h13a.5.5 0 01.5.5v1.471a1 1 0 002 0V2a2 2 0 00-2-2h-14a2 2 0 00-2 2v.75a.25.25 0 01-.25.25H1a1 1 0 000 2zM3.25 10a.25.25 0 00.25-.25v-3.5A.25.25 0 003.25 6h-1.5a.25.25 0 00-.25.25v1.5a.25.25 0 01-.25.25H1a1 1 0 000 2zM3.25 15a.25.25 0 00.25-.25v-3.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25v1.5a.25.25 0 01-.25.25H1a1 1 0 000 2zM3.25 20a.25.25 0 00.25-.25v-3.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V18H1a1 1 0 000 2z\"/><path d=\"M9.87 6.674a1.113 1.113 0 01-1.377.765.75.75 0 10-.412 1.442 2.613 2.613 0 003.231-1.795A3.083 3.083 0 009.2 3.279a3.669 3.669 0 00-4.53 2.515 4.394 4.394 0 003.015 5.427.739.739 0 00.207.029.75.75 0 00.2-1.471 2.894 2.894 0 01-1.98-3.573 2.162 2.162 0 012.673-1.485A1.581 1.581 0 019.87 6.674zM9.594 16.887a.251.251 0 00-.4.073L8 19.582a.5.5 0 00.662.662l2.621-1.191a.25.25 0 00.074-.405zM23.243 18.961a.733.733 0 00-.349.076c-2.158 1.13-3.595 1.058-4.661-.227a.749.749 0 00-.577-.272h-2.8a1.1 1.1 0 110-2.2h2.8a2.1 2.1 0 001.482-3.579l-.059-.059a.249.249 0 010-.353l1.171-1.172a.252.252 0 01.354 0l2.116 2.116a.75.75 0 101.06-1.06l-2.292-2.295a.249.249 0 010-.353L23.3 7.768a1.5 1.5 0 000-2.122l-.7-.707a1.5 1.5 0 00-2.121 0l-2.26 2.255a.248.248 0 01-.265.057 6.218 6.218 0 00-8.344 4.667.749.749 0 00.6.876.73.73 0 00.14.014.749.749 0 00.735-.611 4.751 4.751 0 014.667-3.86 4.486 4.486 0 01.707.058.25.25 0 01.137.424l-6.729 6.727a.14.14 0 000 .2l2.533 2.537a2.607 2.607 0 002.448 1.755h2.467a3.93 3.93 0 002.949 1.289 7.309 7.309 0 003.321-.961.743.743 0 00-.346-1.405zm-5.755-5.379a.25.25 0 01.354 0l.236.236a.6.6 0 01-.422 1.018h-.818a.25.25 0 01-.177-.427z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});