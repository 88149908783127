define("ember-svg-jar/inlined/hand-drag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hand-drag</title><path d=\"M19 5.248a1.473 1.473 0 00-.61.121.249.249 0 00-.15.229v5.02a.5.5 0 01-1 0V4.882a1.749 1.749 0 00-1.74-1.634 1.473 1.473 0 00-.61.121.249.249 0 00-.15.229v6.52a.5.5 0 01-1 0V3.882A1.749 1.749 0 0012 2.248a1.473 1.473 0 00-.61.121.249.249 0 00-.15.229v7.02a.5.5 0 01-1 0V4.882A1.747 1.747 0 006.75 5l-.01 9.479a.5.5 0 01-1 0V10a.25.25 0 00-.25-.25H5a1.752 1.752 0 00-1.75 1.75v4c0 2.112 3.128 6.25 6.91 6.25h5.34a5.256 5.256 0 005.25-5.25V7A1.752 1.752 0 0019 5.248z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});