define("ember-svg-jar/inlined/saving-dog-guard-increase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>saving-dog-guard-increase</title><path d=\"M10.294 7.118l6.006.751a3.523 3.523 0 003.024-1.119l1.583-1.764a.252.252 0 01.179-.083.249.249 0 01.184.073l1.387 1.386a.5.5 0 00.853-.353v-4.29a1.515 1.515 0 000-.456V.509a.5.5 0 00-.5-.5h-.751a1.5 1.5 0 00-.463 0H17.51a.5.5 0 00-.353.853L18.791 2.5a.251.251 0 01.01.344L17.1 4.735a.5.5 0 01-.429.158l-6.005-.751a3.465 3.465 0 00-2.909 1L.45 12.448a1.5 1.5 0 002.121 2.122l7.308-7.309a.5.5 0 01.415-.143z\"/><path d=\"M23.99 16.677a5.7 5.7 0 00-1.022-3.265v-.008a.245.245 0 01-.043-.139v-1.819a2.29 2.29 0 00-2.187-2.187 2.42 2.42 0 00-1.957 1.549.25.25 0 01-.224.138h-3.518a.249.249 0 01-.224-.138 2.42 2.42 0 00-1.957-1.549 2.291 2.291 0 00-2.188 2.187v1.734a.252.252 0 01-.039.135l-.065.1a5.721 5.721 0 00.779 7.428l.032.026c.092.064.521.355 1.138.711a.239.239 0 01.095.1 5.15 5.15 0 004.19 2.329 5.145 5.145 0 004.186-2.336.261.261 0 01.077-.088 15.786 15.786 0 001.709-1.385.155.155 0 00.024-.027 5.667 5.667 0 001.194-3.496zm-7.19 5.832a3.928 3.928 0 01-2.274-.869.251.251 0 01.158-.444h.991a1.791 1.791 0 00.987-.309.251.251 0 01.276 0 1.794 1.794 0 00.987.309h.991a.25.25 0 01.158.444 3.923 3.923 0 01-2.274.869zm-3-9.063a.75.75 0 11-.75.75.75.75 0 01.75-.75zm3 3.75a.75.75 0 00-.75.75v1.375a.489.489 0 01-.375.375h-1.298a.25.25 0 01-.235-.334c.547-1.533 1.618-3.791 2.653-3.791s2.115 2.243 2.665 3.792a.249.249 0 01-.235.333h-1.3a.49.49 0 01-.375-.375v-1.375a.75.75 0 00-.75-.746zm3.531-2.25a.719.719 0 11.719-.718.718.718 0 01-.724.718z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});