define("ember-svg-jar/inlined/external-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>external-upload</title><path d=\"M18.622 12.89a1.5 1.5 0 00-1.008-.39H6.387a1.5 1.5 0 00-1.009.39l-3.649 3.317a.25.25 0 00.227.428 4.453 4.453 0 01.924-.123c.831-.024 17.7-.012 18.241 0a4.452 4.452 0 01.923.123.25.25 0 00.227-.428zM21 18H3a3 3 0 100 6h18a3 3 0 100-6zM7 21.75H4a.75.75 0 010-1.5h3a.75.75 0 010 1.5zm10.5.75A1.5 1.5 0 1119 21a1.515 1.515 0 01-1.5 1.5zM10.643 5.619a.25.25 0 00-.25-.25h-1.7A.976.976 0 018 3.7L11.417.286a.977.977 0 011.381 0L16.214 3.7a.976.976 0 01-.69 1.666h-1.7a.25.25 0 00-.25.25V9.8a1.465 1.465 0 11-2.929 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});