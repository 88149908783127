define("ember-svg-jar/inlined/gesture-swipe-horizontal-right-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-horizontal-right-3</title><path d=\"M16.311 12.581l-3.741-1.117a.25.25 0 01-.178-.24V6.657a1 1 0 00-2 0v7.063a.25.25 0 01-.456.143c-1.489-2.138-3.855-1.449-2.761.224l2.912 4.367a.5.5 0 00.416.222h5.435a.5.5 0 00.492-.41c.2-1.1.664-3.684.775-4.166a1.281 1.281 0 00-.894-1.519zM23.847 10.116L22.9 7.282a.749.749 0 00-.658-.51.722.722 0 00-.414.09.249.249 0 00-.115.3l.4 1.194a1.75 1.75 0 01-1.107 2.213l-1.271.424a.248.248 0 00-.168.2.738.738 0 00.168.6.75.75 0 00.8.22l2.834-.945a.75.75 0 00.478-.952zM8.879 7.124a1 1 0 00-1.134-.845c-3.255.476-5.731 1.6-7.361 3.342a1 1 0 001.461 1.366 10.289 10.289 0 016.189-2.729 1 1 0 00.845-1.134z\"/><path d=\"M17.021 8.692a.252.252 0 01.165.139.249.249 0 010 .215l-.234.469a.75.75 0 00.908 1.047l2.834-.945a.75.75 0 00.474-.948l-.948-2.834a.75.75 0 00-1.383-.1l-.515 1.032a.25.25 0 01-.293.128 23.534 23.534 0 00-2.985-.642 1 1 0 00-.291 1.979 22.45 22.45 0 012.268.46z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});