define("ember-svg-jar/inlined/earth-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earth-add</title><path d=\"M10.967 22.108a.5.5 0 00-.339-.206 9.975 9.975 0 01-2.987-.914.25.25 0 01-.141-.225v-1.15a2.492 2.492 0 01.893-1.913 4.492 4.492 0 001.266-1.779 7.851 7.851 0 01.264-.965 4.448 4.448 0 00.077-.7 4.505 4.505 0 00-4.5-4.5H2.584a.25.25 0 01-.241-.314 9.982 9.982 0 0114.546-6.16.25.25 0 01-.122.468H14.25a2.75 2.75 0 000 5.5 2.554 2.554 0 011.358.408.246.246 0 00.187.032 7.922 7.922 0 017.681 2.51A.3.3 0 0024 12 12 12 0 107.341 23.059l.159.078v-.02A11.927 11.927 0 0012 24a.3.3 0 00.2-.524 7.962 7.962 0 01-1.233-1.368z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.5 7.5h-1a.5.5 0 00-.5.5v1a1 1 0 01-2 0v-1a.5.5 0 00-.5-.5h-1a1 1 0 010-2h1a.5.5 0 00.5-.5v-1a1 1 0 012 0v1a.5.5 0 00.5.5h1a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});