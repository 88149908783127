define("ember-svg-jar/inlined/road-sign-divided-highway-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-divided-highway-1</title><path d=\"M23.53 11.47l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0l11-11a.749.749 0 000-1.06zm-12.349 5.06l-1 1a.751.751 0 01-1.06 0l-1-1a.75.75 0 01.53-1.28A.25.25 0 008.9 15v-.585a.251.251 0 00-.073-.177l-1.56-1.561a1.244 1.244 0 01-.366-.884V8A.75.75 0 018.4 8v3.586a.251.251 0 00.073.177l1.561 1.561a1.244 1.244 0 01.366.884V15a.25.25 0 00.25.25.75.75 0 01.53 1.28zm.97-6.03a1.5 1.5 0 01-1.5-1.5V6.25A.25.25 0 0110.9 6h2.5a.25.25 0 01.25.25V9a1.5 1.5 0 01-1.499 1.5zm5.25 1.293a1.244 1.244 0 01-.366.884l-1.561 1.561a.251.251 0 00-.073.177V15a.25.25 0 00.25.25.75.75 0 01.53 1.28l-1 1a.751.751 0 01-1.06 0l-1-1a.75.75 0 01.53-1.28.25.25 0 00.249-.25v-.792a1.244 1.244 0 01.366-.884l1.561-1.561a.251.251 0 00.073-.177V8a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});