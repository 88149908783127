define("ember-svg-jar/inlined/road-sign-lane-narrowing-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-lane-narrowing-right</title><path d=\"M23.53 12.53a.749.749 0 000-1.06l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0zM15.75 16a.75.75 0 01-1.5 0v-3.117a.252.252 0 00-.056-.158l-2.129-2.605a1.185 1.185 0 01-.315-.827V6.5a.75.75 0 011.5 0v2.617a.252.252 0 00.056.158l2.129 2.605a1.185 1.185 0 01.315.827zm-5-9.5V16a.75.75 0 01-1.5 0V6.5a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});