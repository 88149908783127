define("ember-svg-jar/inlined/climbing-mountain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>climbing-mountain</title><path d=\"M24 .5a.5.5 0 00-.5-.5H23a.5.5 0 00-.416.223l-1.664 2.5a2.5 2.5 0 00-.42 1.386v3.063a1.511 1.511 0 01-.439 1.06l-.828.829a2.479 2.479 0 00-.733 1.767v4.662c0 .891-1.3 2.669-2.587 3.56l-1.344.471a.248.248 0 00-.161.29l.032.146a2.5 2.5 0 01-4.734 1.516.249.249 0 00-.313-.14l-.558.2a.5.5 0 00-.188.118l-1.5 1.5A.5.5 0 007.5 24h16a.5.5 0 00.5-.5z\"/><path d=\"M12.325 21.964a1.5 1.5 0 001.139-1.789l-.564-2.556a4.49 4.49 0 00-3.587-3.451l-1.171-.213a.248.248 0 01-.175-.364l1.185-2.2a.25.25 0 01.409-.045A4.482 4.482 0 0013.044 13H15.5a1.5 1.5 0 000-3h-2.456a1.51 1.51 0 01-1.222-.628c-.073-.1.015 0-1.717-1.887a1.5 1.5 0 00-2.425.3l-3.49 6.486A1.515 1.515 0 004 15v3.146l-1.842 3.678a1.5 1.5 0 002.684 1.341l2-3.994A1.5 1.5 0 007 18.5v-1.7l1.772.322a1.5 1.5 0 011.2 1.151l.568 2.555a1.5 1.5 0 001.785 1.136zM3.658 11.325l2.776-4.748A1.5 1.5 0 005.9 4.525l-.864-.5a1.5 1.5 0 00-2.056.535L.205 9.309a1.5 1.5 0 00.539 2.05l.864.505a1.5 1.5 0 002.05-.539z\"/><circle cx=\"11.5\" cy=\"3.5\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});