define("ember-svg-jar/inlined/american-football-helmet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>american-football-helmet</title><path d=\"M23 16H12.5a.5.5 0 01-.5-.5 3.5 3.5 0 013.5-3.5h6.488a1 1 0 001-1.047 11.715 11.715 0 00-.131-1.282.5.5 0 00-.494-.421H14.5a5.256 5.256 0 00-5.25 5.25.5.5 0 00.308.462 2.751 2.751 0 11-2.116 0 .5.5 0 00.308-.462 6.758 6.758 0 016.75-6.75h8.75a.75.75 0 000-1.5h-1.208a.5.5 0 01-.44-.261A11.505 11.505 0 000 11.5c0 5.252 3.909 10.194 8.531 11.142.4.082.361.07 2.352.292a2.005 2.005 0 01.731.228 6.964 6.964 0 003.327.838H21a3 3 0 003-3v-4a1 1 0 00-1-1zm-9 5.265a.5.5 0 01-.66.474 4.981 4.981 0 01-1.112-.537.5.5 0 01-.228-.42V18.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5zm4 .235a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h1a.5.5 0 01.5.5zm4-.5a1 1 0 01-1 1h-.5a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h1a.5.5 0 01.5.5z\"/><circle cx=\"8.5\" cy=\"17.5\" r=\"1.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});