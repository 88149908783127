define("ember-svg-jar/inlined/real-estate-location-house-pin-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-location-house-pin-1</title><path d=\"M10.1 19.959a2 2 0 01-.891-3.485l5.851-4.953a.5.5 0 00.144-.2A10.25 10.25 0 0016 8 8 8 0 100 8c0 4.243 7.273 15.307 7.583 15.775a.5.5 0 00.834 0c.1-.155.969-1.473 2.08-3.317v-.01a.5.5 0 00-.397-.489zM8 10.5A2.5 2.5 0 1110.5 8 2.5 2.5 0 018 10.5z\"/><path d=\"M23.822 17.618l-6.5-5.5a.5.5 0 00-.647 0l-6.5 5.5a.5.5 0 00.323.882h1a.5.5 0 01.5.5v4.5a.5.5 0 00.5.5H15a.5.5 0 00.5-.5v-2.25a1.5 1.5 0 013 0v2.25a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5V19a.5.5 0 01.5-.5h1a.5.5 0 00.322-.882z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});