define("ember-svg-jar/inlined/instrument-harp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>instrument-harp</title><path d=\"M24 4a2 2 0 00-2-2h-9.5a6.7 6.7 0 01-2.776-.947A7.5 7.5 0 006.5 0h-5A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24H8a4.527 4.527 0 003.6-1.8L23 7a5.035 5.035 0 001-3zM5.5 19H4.25a.25.25 0 01-.25-.25V4.25A.25.25 0 014.25 4h1.5a.25.25 0 01.25.25v14.481a.249.249 0 01-.212.247A1.843 1.843 0 015.5 19zm2-14.481a.251.251 0 01.338-.234c.467.177.946.416 1.438.662l.335.166a.25.25 0 01.139.225v10.1a.249.249 0 01-.073.177l-1.75 1.75a.25.25 0 01-.427-.177zm3.75 1.607a.25.25 0 01.308-.243A4.05 4.05 0 0012.5 6h.75a.25.25 0 01.25.25v5.439a.249.249 0 01-.073.177l-1.75 1.75a.25.25 0 01-.427-.177zM15 6.25a.25.25 0 01.25-.25h2.25a.5.5 0 01.5.5 1.9 1.9 0 01-.561 1.354l-2.012 2.012A.25.25 0 0115 9.689z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});