define("ember-svg-jar/inlined/single-man-actions-player.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-player</title><path d=\"M.124 17.539a.493.493 0 00.494.494h1.788a.246.246 0 01.246.227l.438 5.249a.493.493 0 00.492.453h3.952a.494.494 0 00.493-.453l.437-5.249a.248.248 0 01.246-.227h1.542a.247.247 0 00.247-.247l-.008-5.865a.506.506 0 00-.051-.219A5.432 5.432 0 00.124 14.081z\"/><path d=\"M1.359 4.2A4.2 4.2 0 105.558 0a4.2 4.2 0 00-4.199 4.2zm4.2 2.223A2.226 2.226 0 013.335 4.2a2.135 2.135 0 01.156-.8.248.248 0 01.359-.118 5.307 5.307 0 002.795.791 5.341 5.341 0 00.9-.073.2.2 0 01.236.2 2.226 2.226 0 01-2.223 2.223zM13.805 24h8.209a1.864 1.864 0 001.862-1.862v-8.209a1.865 1.865 0 00-1.862-1.863h-8.209a1.865 1.865 0 00-1.862 1.863v8.209A1.864 1.864 0 0013.805 24zm6.47-5.967a.924.924 0 01-.446.788l-3 1.8a.919.919 0 01-1.391-.788v-3.6a.918.918 0 011.391-.787l3 1.8a.924.924 0 01.446.787z\"/><path d=\"M.124 17.539a.493.493 0 00.494.494h1.788a.246.246 0 01.246.227l.438 5.249a.493.493 0 00.492.453h3.952a.494.494 0 00.493-.453l.437-5.249a.248.248 0 01.246-.227h1.542a.247.247 0 00.247-.247l-.008-5.865a.506.506 0 00-.051-.219A5.432 5.432 0 00.124 14.081z\"/><path d=\"M1.359 4.2A4.2 4.2 0 105.558 0a4.2 4.2 0 00-4.199 4.2zm4.2 2.223A2.226 2.226 0 013.335 4.2a2.135 2.135 0 01.156-.8.248.248 0 01.359-.118 5.307 5.307 0 002.795.791 5.341 5.341 0 00.9-.073.2.2 0 01.236.2 2.226 2.226 0 01-2.223 2.223zM13.805 24h8.209a1.864 1.864 0 001.862-1.862v-8.209a1.865 1.865 0 00-1.862-1.863h-8.209a1.865 1.865 0 00-1.862 1.863v8.209A1.864 1.864 0 0013.805 24zm6.47-5.967a.924.924 0 01-.446.788l-3 1.8a.919.919 0 01-1.391-.788v-3.6a.918.918 0 011.391-.787l3 1.8a.924.924 0 01.446.787z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});