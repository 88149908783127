define("ember-svg-jar/inlined/modern-tv-curvy-edge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-tv-curvy-edge</title><path d=\"M24 3.642A1.751 1.751 0 0022.11 1.9a113.26 113.26 0 01-20.21 0 1.711 1.711 0 00-1.334.458A1.756 1.756 0 000 3.642v12.414A1.749 1.749 0 001.89 17.8c3.175-.255 5.68-.4 8.352-.439a.5.5 0 01.508.5v1.247a.5.5 0 01-.5.5H7a1.25 1.25 0 000 2.5h10a1.25 1.25 0 000-2.5h-3.25a.5.5 0 01-.5-.5v-1.246a.5.5 0 01.508-.5c2.672.042 5.177.184 8.355.439A1.75 1.75 0 0024 16.056zm-2.5 11.065a.5.5 0 01-.537.5 112.389 112.389 0 00-17.926 0 .5.5 0 01-.537-.5V4.992a.5.5 0 01.537-.5 112.567 112.567 0 0017.926 0 .5.5 0 01.537.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});