define("ember-svg-jar/inlined/transfusion-bag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transfusion-bag</title><path d=\"M3 7.5a.5.5 0 00-.425.236C1.862 8.887.5 11.216.5 12.235a2.5 2.5 0 005 0c0-1.019-1.362-3.348-2.075-4.5A.5.5 0 003 7.5z\"/><path d=\"M23.5 2.75A2.752 2.752 0 0020.75 0h-7.5a2.752 2.752 0 00-2.75 2.75V14.5A2.5 2.5 0 0013 17h2.5a.5.5 0 01.5.5v1a3.5 3.5 0 01-7 0V6.75a3.25 3.25 0 00-5.778-2.043 1 1 0 001.555 1.258A1.25 1.25 0 017 6.75V18.5a5.5 5.5 0 0011 0v-1a.5.5 0 01.5-.5H21a2.5 2.5 0 002.5-2.5zm-3 9.75a1 1 0 01-1 1h-5a1 1 0 01-1-1v-8a1 1 0 011-1h5a1 1 0 011 1z\"/><path d=\"M18.5 6.75h-3a.75.75 0 000 1.5h3a.75.75 0 000-1.5zM18.5 9.75h-3a.75.75 0 000 1.5h3a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});