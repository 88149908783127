define("ember-svg-jar/inlined/plane-trip-land-cancel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-trip-land-cancel</title><path d=\"M10.182 5.209a.249.249 0 00.143.145L14.554 7a.247.247 0 00.268-.057.252.252 0 00.054-.27L12.9 1.83a1 1 0 00-.532-.544L10.143.332a1 1 0 00-1.329 1.274z\"/><path d=\"M17.551 9.765a.25.25 0 00.109-.472 2.8 2.8 0 00-.278-.126L5.761 4.654a.247.247 0 01-.147-.154L4.8 2.047a1 1 0 00-.554-.6L1.646.332a1 1 0 00-1.395.919v5.065a1.492 1.492 0 00.943 1.392l5.263 2.105a.25.25 0 01.013.459l-4.851 2.264a1 1 0 00.071 1.842L4.419 15.4a1.015 1.015 0 00.634.023l5.689-1.673a.249.249 0 00.144-.112 7.96 7.96 0 016.665-3.875zM23.594 16.368a5.933 5.933 0 00-4.464-4.462 6.011 6.011 0 00-7.23 7.223 5.938 5.938 0 005.835 4.62 6.006 6.006 0 005.859-7.381zm-9.461 3.115a4.013 4.013 0 015.347-5.347.25.25 0 01.068.4l-5.013 5.013a.251.251 0 01-.402-.066zm6.444 1.1a4.015 4.015 0 01-4.56.787.251.251 0 01-.068-.4l5.013-5.012a.25.25 0 01.4.068 4.007 4.007 0 01-.785 4.554z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});