define("ember-svg-jar/inlined/modern-tv-remote-smart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-tv-remote-smart</title><path d=\"M15 0H9a2.5 2.5 0 00-2.5 2.5v19A2.5 2.5 0 009 24h6a2.5 2.5 0 002.5-2.5v-19A2.5 2.5 0 0015 0zM9.5 15.5A1.5 1.5 0 1111 14a1.5 1.5 0 01-1.5 1.5zm1.5 3A1.5 1.5 0 119.5 17a1.5 1.5 0 011.5 1.5zM9.5 10A1.5 1.5 0 1111 8.5 1.5 1.5 0 019.5 10zm.75-7.5a.75.75 0 01.75-.75h2a.75.75 0 010 1.5h-2a.75.75 0 01-.75-.75zM13 14.083a1.5 1.5 0 113 0v4.334a1.5 1.5 0 11-3 0zM14.5 10A1.5 1.5 0 1116 8.5a1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});