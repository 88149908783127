define("ember-svg-jar/inlined/single-neutral-actions-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-monitor</title><path d=\"M0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.56a.25.25 0 00.25-.25v-4.75a3.475 3.475 0 01.529-1.844.25.25 0 00-.006-.275A5.5 5.5 0 000 13.75z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M20 21.75h-1.25a.25.25 0 01-.25-.25V21a.25.25 0 01.25-.25H22a2 2 0 002-2v-6a2 2 0 00-2-2h-9a2 2 0 00-2 2v6a2 2 0 002 2h3.25a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H15a1.041 1.041 0 00-1 1 1 1 0 001 1h5a1 1 0 001-1 1.043 1.043 0 00-1-1zm2-3.25a.249.249 0 01-.25.25h-8.5a.25.25 0 01-.25-.25V13a.25.25 0 01.25-.25h8.5A.25.25 0 0122 13zM0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.56a.25.25 0 00.25-.25v-4.75a3.475 3.475 0 01.529-1.844.25.25 0 00-.006-.275A5.5 5.5 0 000 13.75z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M20 21.75h-1.25a.25.25 0 01-.25-.25V21a.25.25 0 01.25-.25H22a2 2 0 002-2v-6a2 2 0 00-2-2h-9a2 2 0 00-2 2v6a2 2 0 002 2h3.25a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H15a1.041 1.041 0 00-1 1 1 1 0 001 1h5a1 1 0 001-1 1.043 1.043 0 00-1-1zm2-3.25a.249.249 0 01-.25.25h-8.5a.25.25 0 01-.25-.25V13a.25.25 0 01.25-.25h8.5A.25.25 0 0122 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});