define("ember-svg-jar/inlined/road-sign-speed-limit-40", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-speed-limit-40</title><path d=\"M9.839 9.716a.247.247 0 00-.216-.044 4.22 4.22 0 00-3 3 .25.25 0 00.242.313h2.821a.25.25 0 00.25-.25V9.914a.25.25 0 00-.097-.198zM16.258 9.535a1.728 1.728 0 00-1.726 1.727v2.475a1.726 1.726 0 103.452 0v-2.475a1.729 1.729 0 00-1.726-1.727z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm-.564 16.214a.75.75 0 01-1.5 0v-1.477a.25.25 0 00-.25-.25H5.734a.75.75 0 01-.75-.75 5.709 5.709 0 015.7-5.7.75.75 0 01.75.75zm8.048-2.477a3.226 3.226 0 11-6.452 0v-2.475a3.226 3.226 0 116.452 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});