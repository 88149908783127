define("ember-svg-jar/inlined/time-clock-file-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-clock-file-share</title><path d=\"M23.414 3L21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v10.016a.25.25 0 00.469.121A6.2 6.2 0 017.9 10.223a.232.232 0 00.1-.191V2.251A.25.25 0 018.25 2h11.232a.249.249 0 01.177.073l2.268 2.268a.248.248 0 01.073.177v13.733a.25.25 0 01-.25.25h-7.769a.246.246 0 00-.211.379 4.245 4.245 0 01.616 1.425.246.246 0 00.238.2H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M18.75 8.5a3.254 3.254 0 01-3.25 3.25 3.161 3.161 0 01-1.419-.338c-.116-.071-.231.065-.2.17a4.2 4.2 0 01.524 1.366.467.467 0 00.22.212 4.488 4.488 0 00.873.09A4.75 4.75 0 1010.75 8.5a4.587 4.587 0 00.107.958.258.258 0 00.123.121 4.181 4.181 0 011.506.58c.092.038.191-.071.123-.2A3.159 3.159 0 0112.25 8.5a3.25 3.25 0 016.5 0zM8.442 16a.245.245 0 01.266.012 2.74 2.74 0 004.167-1.425A2.758 2.758 0 0010.25 11a2.807 2.807 0 00-2.713 3.058.243.243 0 01-.115.222L4.558 16a.245.245 0 01-.266-.012 2.707 2.707 0 00-2.406-.349 2.706 2.706 0 00-1.762 1.775A2.759 2.759 0 002.75 21a2.729 2.729 0 001.887-.761.246.246 0 01.259-.054s1.924.782 2.464 1a.237.237 0 01.144.214 2.753 2.753 0 105.361-1.025 2.681 2.681 0 00-1.736-1.74 2.74 2.74 0 00-2.767.623.242.242 0 01-.258.053L5.553 18.3a.114.114 0 01-.06-.084l-.03-.353a.118.118 0 01.056-.1z\"/><path d=\"M15.5 5.983a.75.75 0 00-.75.75V8.5a.75.75 0 00.75.75h1.768a.75.75 0 100-1.5H16.5a.252.252 0 01-.25-.25v-.767a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});