define("ember-svg-jar/inlined/paginate-filter-plus-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paginate-filter-plus-alternate</title><path d=\"M18.25 8.247H16A.25.25 0 0115.75 8V5.747a1.5 1.5 0 00-1.5-1.5h-1a1.5 1.5 0 00-1.5 1.5V8a.25.25 0 01-.25.25H9.25a1.5 1.5 0 00-1.5 1.5v1a1.5 1.5 0 001.5 1.5h2.25a.25.25 0 01.25.25v2.25a1.5 1.5 0 001.5 1.5h1a1.5 1.5 0 001.5-1.5V12.5a.25.25 0 01.25-.25h2.25a1.5 1.5 0 001.5-1.5v-1a1.5 1.5 0 00-1.5-1.503z\"/><path d=\"M22 0H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2V2a2 2 0 00-2-2zm-.145 18.354a.5.5 0 01-.354.146H6a.5.5 0 01-.5-.5V2.5A.5.5 0 016 2h15.5a.5.5 0 01.5.5V18a.5.5 0 01-.145.351z\"/><path d=\"M19.5 22h-17a.5.5 0 01-.5-.5v-17a1 1 0 00-2 0V22a2 2 0 002 2h17.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});