define("ember-svg-jar/inlined/navigation-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-right</title><path d=\"M23.074 4.963a1.5 1.5 0 00-1.632.321l-3.006 2.993a.249.249 0 01-.289.046C13.2 5.812 8.431 5.529 4.965 7.569 1.763 9.454 0 13.211 0 18.146a1 1 0 002 0c0-2.373.9-4.112 2.617-5.028 2.115-1.132 5.125-.892 7.971.565a.249.249 0 01.063.4l-2.5 2.5a1.5 1.5 0 001.061 2.561H23a1 1 0 001-1V6.349a1.5 1.5 0 00-.926-1.386z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});