define("ember-svg-jar/inlined/multiple-actions-sync.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-sync</title><path d=\"M16.691 9.793a.25.25 0 00.089-.475c-.632-.3-1.413-.59-2.309-.92l-.486-.172a1.8 1.8 0 01-.055-1.172 4.647 4.647 0 001.216-3.569A3.22 3.22 0 0012 0a2.994 2.994 0 00-1.342.314.25.25 0 00-.1.363 5 5 0 01.842 2.808 6.271 6.271 0 01-.922 3.624.254.254 0 00-.029.216.25.25 0 00.152.156c1.648.608 3.186 1.2 3.9 2.553a.252.252 0 00.3.122 8.268 8.268 0 011.89-.363z\"/><path d=\"M13.224 10.826a.252.252 0 00.09-.35c-.5-.809-1.568-1.241-3.843-2.078l-.486-.172a1.8 1.8 0 01-.055-1.172 4.647 4.647 0 001.216-3.569A3.22 3.22 0 007 0a3.22 3.22 0 00-3.146 3.485 4.605 4.605 0 001.193 3.538 1.877 1.877 0 010 1.185l-.515.19c-2.01.739-3.46 1.272-3.914 2.179A7.542 7.542 0 000 13.5a.5.5 0 00.5.5h9.478a.249.249 0 00.219-.129 7.907 7.907 0 013.027-3.045zM24 12.021a.5.5 0 00-.854-.353l-1.3 1.3a.25.25 0 01-.348.006 6.374 6.374 0 10.628 8.631 1 1 0 10-1.563-1.248 4.376 4.376 0 11-.495-5.982.25.25 0 01.009.362l-1.39 1.391a.5.5 0 00.353.854h4.46a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});