define("ember-svg-jar/inlined/phone-action-search-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-search-3</title><path d=\"M12 11.976V18.5a1 1 0 01-1 1H4a1 1 0 01-1-1v-11a1 1 0 011-1h4.28a.249.249 0 00.25-.265 10.36 10.36 0 01.07-1.953A.245.245 0 008.355 4H3.5A2.5 2.5 0 001 6.5v15A2.5 2.5 0 003.5 24h8a2.5 2.5 0 002.5-2.5v-8.969a.244.244 0 00-.189-.237 6.978 6.978 0 01-1.446-.536.248.248 0 00-.365.218z\"/><path d=\"M22.707 11.293l-1.6-1.6-1.077-1.078a5.513 5.513 0 10-1.415 1.414L19.69 11.1l1.6 1.6a1 1 0 001.414-1.414zM15.5 2A3.5 3.5 0 1112 5.5 3.5 3.5 0 0115.5 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});