define("ember-svg-jar/inlined/car-dashboard-speed-copy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-dashboard-speed copy</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M17.087 4.547a1 1 0 00-1.387.274L12.3 9.9a.25.25 0 01-.233.11.633.633 0 00-.077-.01 2.25 2.25 0 102.25 2.25 2.214 2.214 0 00-.26-1.034.25.25 0 01.013-.256l3.368-5.025a1 1 0 00-.274-1.388zM4.5 11.491h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM5.9 7.861l-.866-.5a.75.75 0 10-.75 1.3l.866.5a.738.738 0 00.374.1.75.75 0 00.376-1.4zM8.269 6.54a.738.738 0 00.374-.1.748.748 0 00.274-1.024l-.5-.866a.75.75 0 00-1.3.75l.5.866a.748.748 0 00.652.374zM12.008 5.558a.75.75 0 00.75-.75v-1a.75.75 0 00-1.5 0v1a.75.75 0 00.75.75zM19.7 8.726a.75.75 0 00-.75-1.3l-.867.5a.75.75 0 00.75 1.3zM20.44 11.566h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5z\"/><rect x=\"7.5\" y=\"16.75\" width=\"9\" height=\"3\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});