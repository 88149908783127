define("ember-svg-jar/inlined/kitchenware-molds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchenware-molds</title><path d=\"M9.9 3.716l-1.954-.291A1.256 1.256 0 017 2.721L6.132.9a.7.7 0 00-1.264 0L4 2.721a1.253 1.253 0 01-.942.7L1.1 3.716a.709.709 0 00-.569.493.738.738 0 00.178.743l1.412 1.411a1.307 1.307 0 01.358 1.146l-.37 2.139a.736.736 0 00.289.721.688.688 0 00.757.034l1.708-1.015a1.217 1.217 0 01.637-.173 1.217 1.217 0 01.635.173l1.71 1.012a.688.688 0 00.757-.034.734.734 0 00.289-.721l-.37-2.139a1.31 1.31 0 01.358-1.146l1.408-1.411a.741.741 0 00.179-.743.712.712 0 00-.566-.49zM21.544 13.557a2.505 2.505 0 00-2.814 1.394.248.248 0 01-.46 0 2.5 2.5 0 00-2.814-1.394 2.537 2.537 0 00-1.948 2.7c.178 3.023 3.442 6.146 4.594 7.1a.623.623 0 00.8 0c1.152-.955 4.415-4.078 4.593-7.1a2.538 2.538 0 00-1.951-2.7z\"/><circle cx=\"18.5\" cy=\"5.5\" r=\"5\"/><path d=\"M10 16.75a2.749 2.749 0 00-4.583-2.05.251.251 0 01-.334 0A2.749 2.749 0 001.2 18.583a.251.251 0 010 .334A2.749 2.749 0 005.083 22.8a.251.251 0 01.334 0A2.749 2.749 0 009.3 18.917a.251.251 0 010-.334 2.736 2.736 0 00.7-1.833z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});