define("ember-svg-jar/inlined/vip-crown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vip-crown</title><path d=\"M2.109 20a.5.5 0 00-.379.174 2.306 2.306 0 001.77 3.7h17a2.174 2.174 0 002.239-2.25 2.338 2.338 0 00-.484-1.43.5.5 0 00-.395-.194zM22.614 19a.5.5 0 00.434-.251 5.916 5.916 0 00-5.139-8.875.335.335 0 00-.265.539 7.076 7.076 0 011.487 4.342 7.155 7.155 0 01-.1 1.189.75.75 0 11-1.48-.25A5.625 5.625 0 0013 9.219V6.962a3.5 3.5 0 10-2 0v2.257a5.634 5.634 0 00-4.533 6.59.75.75 0 11-1.473.279 7.1 7.1 0 011.362-5.674.335.335 0 00-.265-.539A5.916 5.916 0 00.952 18.75a.5.5 0 00.434.25zM9.5 2.875a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});