define("ember-svg-jar/inlined/desktop-computer-pc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>desktop-computer-pc</title><path d=\"M24 7.251a1 1 0 00-1-1h-2.75A.25.25 0 0020 6.5v10.25a2 2 0 01-2 2h-.75A.25.25 0 0017 19v.75a1 1 0 001 1h5a1 1 0 001-1z\"/><path d=\"M12 18.751h-1.25a.25.25 0 01-.25-.25V18a.25.25 0 01.25-.25H17a2 2 0 002-2V5.251a2 2 0 00-2-2H2a2 2 0 00-2 2v10.5a2 2 0 002 2h6.25A.25.25 0 018.5 18v.5a.25.25 0 01-.25.25H7a1 1 0 000 2h5a1 1 0 000-2zm-10-3.5v-9.5a.5.5 0 01.5-.5h14a.5.5 0 01.5.5v9.5a.5.5 0 01-.5.5h-14a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});