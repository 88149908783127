define("ember-svg-jar/inlined/ee-icon-question-text-answer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>picture-landscape</title><g fill=\"none\" fill-rule=\"evenodd\"><rect fill=\"#5E7AF9\" width=\"54\" height=\"54\" rx=\"5\"/><path fill=\"#FFF\" d=\"M7 9h40v4H7zM7 16h40v4H7zM7 23h36v4H7z\"/><path stroke=\"#FFF\" stroke-width=\"2\" d=\"M8 34h38v11H8z\"/></g>",
    "attrs": {
      "width": "54",
      "height": "54",
      "viewBox": "0 0 54 54",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});