define("ember-svg-jar/inlined/file-code-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-2</title><path d=\"M21.414 4.915L17.086.587A2 2 0 0015.672 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.329a2 2 0 00-.586-1.414zm-10.841 9.677l-2.5 2.5a1 1 0 01-1.414-1.414l1.616-1.616a.25.25 0 000-.354l-1.616-1.615a1 1 0 011.414-1.415l2.5 2.5a1 1 0 010 1.414zM16.5 14H13a1 1 0 010-2h3.5a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});