define("ember-svg-jar/inlined/fitness-bicycle-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fitness-bicycle-2</title><path d=\"M21.75 17a1 1 0 000 2 .25.25 0 010 .5h-1.238a.5.5 0 01-.482-.365L16.354 6.066A1 1 0 0117 4.846l1.191-.4a1 1 0 10-.632-1.9l-1.191.4a3 3 0 00-1.939 3.659l.3 1.061a.5.5 0 01-.347.617L9.551 9.634a.5.5 0 01-.532-.178L7.449 7.4a.249.249 0 01.2-.4h.728a1 1 0 00.383-1.924L3.38 2.844a1 1 0 00-1.359 1.143l.5 2.232A1 1 0 003.5 7h.879a.5.5 0 01.4.2l2.468 3.227a.5.5 0 01.055.518l-3.789 7.984a1 1 0 01-.9.572H1a1 1 0 000 2h7.75a2.25 2.25 0 000-4.5 1 1 0 000 2 .25.25 0 010 .5h-2.9a.251.251 0 01-.226-.357l1.354-2.857A.5.5 0 017.43 16H12a1 1 0 00.834-.449l2.582-3.908a.25.25 0 01.449.07l2.011 7.151a.5.5 0 01-.481.636H14.5a1 1 0 000 2h7.25a2.25 2.25 0 000-4.5zm-8.371-5.9l-1.769 2.676a.5.5 0 01-.417.225H8.851a.5.5 0 01-.452-.714l.489-1.034a1 1 0 01.635-.535l3.58-1a.249.249 0 01.276.378z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});