define("ember-svg-jar/inlined/office-file-adobe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-file-adobe</title><path d=\"M11.1 10.336a.521.521 0 01-.164-.32 15.827 15.827 0 00-.812-3.445 1.412 1.412 0 00-1.184-.978 1.628 1.628 0 00-1.519.78A1.7 1.7 0 007.429 8.2a14.74 14.74 0 001.953 2.562.48.48 0 01.137.307 12.436 12.436 0 01-.063 2.364.511.511 0 01-.217.349 12.958 12.958 0 00-2.579 2.339 1.592 1.592 0 00-.3 1.622 1.637 1.637 0 001.332.992 1.671 1.671 0 00.211.013 1.725 1.725 0 001.4-.73 8.925 8.925 0 001.451-3.257.493.493 0 01.229-.318 12.294 12.294 0 011.909-.91.527.527 0 01.394.019 12.647 12.647 0 003.735 1.065 1.479 1.479 0 001.442-.605 1.7 1.7 0 00-1.393-2.656 11.744 11.744 0 00-3.639.433.49.49 0 01-.377-.043 11.377 11.377 0 01-1.954-1.41z\"/><path d=\"M21.707 5.705L16.293.291A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.412a1 1 0 00-.293-.707zM19.5 22h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.465a.5.5 0 01.353.147l4.536 4.533a.5.5 0 01.146.353V21.5a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});