define("ember-svg-jar/inlined/army-symbol-non-specific", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-symbol-non-specific</title><path d=\"M22 20.5H2a2 2 0 01-2-2v-13a2 2 0 012-2h20a2 2 0 012 2v13a2 2 0 01-2 2zM2.5 5.5A.5.5 0 002 6v12a.5.5 0 00.5.5h19a.5.5 0 00.5-.5V6a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});