define("ember-svg-jar/inlined/download-square-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>download-square-2</title><path d=\"M6.975 11.914a.75.75 0 00.071.786l4.5 6a.752.752 0 00.6.3h.01a.749.749 0 00.6-.317l4.251-6A.75.75 0 0016.4 11.5h-2.5a.25.25 0 01-.25-.25v-5.5a1.5 1.5 0 00-3 0v5.5a.25.25 0 01-.25.25H7.646a.75.75 0 00-.671.414zM17.229 22h-1a1 1 0 000 2h1a1 1 0 000-2zM6.771 24h1a1 1 0 100-2h-1a1 1 0 000 2zM11.521 22a1 1 0 000 2h1a1 1 0 000-2zM17.271 0h-1a1 1 0 000 2h1a1 1 0 000-2zM7.771 0h-1a1 1 0 000 2h1a1 1 0 100-2zM11.521 2h1a1 1 0 000-2h-1a1 1 0 000 2zM.021 17.25a1 1 0 002 0v-1a1 1 0 00-2 0zM1.021 8.75a1 1 0 001-1v-1a1 1 0 00-2 0v1a1 1 0 001 1zM.021 12.5a1 1 0 002 0v-1a1 1 0 00-2 0zM22.979 15.25a1 1 0 00-1 1v1a1 1 0 002 0v-1a1 1 0 00-1-1zM23.021 5.75a1 1 0 00-1 1v1a1 1 0 002 0v-1a1 1 0 00-1-1zM23.021 10.5a1 1 0 00-1 1v1a1 1 0 002 0v-1a1 1 0 00-1-1zM22.979 20a1 1 0 00-1 1v.75a.25.25 0 01-.25.25h-.75a1 1 0 000 2h1a2 2 0 002-2v-1a1 1 0 00-1-1zM2.979 22h-.75a.25.25 0 01-.25-.25V21a1 1 0 00-2 0v1a2 2 0 002 2h1a1 1 0 000-2zM21.979 0h-1a1 1 0 000 2h.75a.25.25 0 01.25.25V3a1 1 0 002 0V2a2 2 0 00-2-2zM2.979 0h-1a2 2 0 00-2 2v1a1 1 0 002 0v-.75a.25.25 0 01.25-.25h.75a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});