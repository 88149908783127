define("ember-svg-jar/inlined/controls-movie-forward", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>controls-movie-forward</title><path d=\"M9.5 17.5a8 8 0 0112.072-6.884.25.25 0 00.377-.215V6a.5.5 0 00-.5-.5H.5A.5.5 0 000 6v10a2.5 2.5 0 002.5 2.5h6.784a.25.25 0 00.249-.273A7.939 7.939 0 019.5 17.5zM4.487 3.573A.25.25 0 004.664 4h3.172a.251.251 0 00.177-.073l3.5-3.5A.25.25 0 0011.336 0H8.164a.251.251 0 00-.177.073zM0 3.5a.5.5 0 00.5.5h1.336a.251.251 0 00.177-.073l3.5-3.5A.25.25 0 005.336 0H2.5A2.5 2.5 0 000 2.5zM10.487 3.573a.25.25 0 00.177.427h3.172a.251.251 0 00.177-.073l3.5-3.5A.25.25 0 0017.336 0h-3.172a.251.251 0 00-.177.073zM16.487 3.573a.25.25 0 00.177.427h4.785a.5.5 0 00.5-.5v-1a2.489 2.489 0 00-1.8-2.4.249.249 0 00-.249.064z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm3.975 7.08l-2.75 2.25a.75.75 0 11-.95-1.16l1.8-1.477a.248.248 0 000-.386l-1.8-1.477a.75.75 0 11.95-1.16l2.75 2.25a.749.749 0 010 1.16zM13.75 15a.5.5 0 01.82-.384l3 2.5a.5.5 0 010 .768l-3 2.5a.5.5 0 01-.82-.384z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});