define("ember-svg-jar/inlined/bicycle-sports-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bicycle-sports-1</title><path d=\"M19 11.5a3.078 3.078 0 00-.41.034.25.25 0 01-.275-.184l-1.2-4.564a.248.248 0 01.032-.2.251.251 0 01.17-.111 2 2 0 001.639-2.423 2.058 2.058 0 00-2.04-1.552H13.5a1 1 0 000 2h2.519a.121.121 0 01.064.224A2 2 0 0015 6.5a1 1 0 00.033.255l.577 2.185a.25.25 0 01-.3.308 9.435 9.435 0 00-6.182.721.251.251 0 01-.328-.113l-.51-1.014A.236.236 0 018.5 8.5a1 1 0 000-2h-5a1 1 0 000 2h2.228a.252.252 0 01.224.138l1.2 2.4a.249.249 0 01-.06.3c-.128.111-.255.226-.382.347a.249.249 0 01-.248.057 4.408 4.408 0 00-3.926.529A5.1 5.1 0 000 16.552 5 5 0 009.856 17.7a.252.252 0 01.243-.2h1.536a1.5 1.5 0 001.416-1 8.49 8.49 0 00.191-.678A6.176 6.176 0 0116 11.658a.248.248 0 01.223-.018.251.251 0 01.149.168l.058.223a.249.249 0 01-.107.274A4.969 4.969 0 1019 11.5zm-14 8a3 3 0 112.658-4.364.25.25 0 01-.222.364H4.751a1 1 0 000 2h2.686a.25.25 0 01.222.364A2.986 2.986 0 015 19.5zm6.3-4.175a.232.232 0 01-.225.175H10.1a.249.249 0 01-.243-.19 4.98 4.98 0 00-1.275-2.276.247.247 0 01-.07-.191.25.25 0 01.1-.181c.1-.08.207-.156.311-.231a.232.232 0 01.036-.022.9.9 0 00.209-.161l.032-.026a7.066 7.066 0 013.776-1.2.249.249 0 01.2.413 9.026 9.026 0 00-1.876 3.89zM19 19.5a3 3 0 01-3-3 2.954 2.954 0 01.7-1.9.249.249 0 01.433.1l.459 1.739a1 1 0 00.645.691l.947.322a1 1 0 00.643-1.894l-.311-.106a.248.248 0 01-.162-.172l-.438-1.661a.094.094 0 01.016-.079.091.091 0 01.068-.04 3 3 0 010 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});