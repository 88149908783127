define("ember-svg-jar/inlined/file-code-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-1</title><path d=\"M21.414 4.915L17.086.587A2 2 0 0015.672 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.329a2 2 0 00-.586-1.414zm-13.259 9.81a1.855 1.855 0 011.045 1.64v1.171c0 .283.264.465.673.465h.6a.75.75 0 010 1.5h-.6A2.032 2.032 0 017.7 17.536v-1.171c0-.116-.116-.25-.3-.342l-1.378-.893a.749.749 0 010-1.259l1.319-.854c.245-.13.361-.264.361-.38v-1.172A2.032 2.032 0 019.875 9.5h.6a.75.75 0 010 1.5h-.6c-.409 0-.673.182-.673.464v1.172a1.9 1.9 0 01-1.112 1.679s-.167.079-.167.184c0 .164.232.226.232.226zm10.785.429l-1.507.854c-.312.143-.424.289-.424.357v1.171a2.127 2.127 0 01-2.378 1.964h-.686a.75.75 0 110-1.5h.686c.5 0 .878-.2.878-.465v-1.17a1.9 1.9 0 011.248-1.695s.16-.09.16-.2-.223-.175-.223-.175a1.861 1.861 0 01-1.185-1.663v-1.167c0-.264-.377-.464-.878-.464h-.686a.75.75 0 010-1.5h.686a2.127 2.127 0 012.378 1.964v1.172c0 .068.112.214.361.325l1.57.886a.75.75 0 010 1.306z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});