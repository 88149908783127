define("ember-svg-jar/inlined/data-file-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-graph</title><path d=\"M20 24a2 2 0 002-2V6.329a2 2 0 00-.586-1.415L17.086.586A2 2 0 0015.672 0H4a2 2 0 00-2 2v20a2 2 0 002 2zm-2.5-6a.75.75 0 01-.75.75H7a.75.75 0 010-1.5h9.75a.75.75 0 01.75.75zM6.527 13.81l3.266-3.266a1 1 0 011.222-.151l1.663 1a.251.251 0 00.3-.038l2.5-2.5A1 1 0 0116.9 10.27l-3.188 3.187a1 1 0 01-1.221.15l-1.664-1a.25.25 0 00-.3.037l-2.586 2.58a1 1 0 01-1.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});