define("ember-svg-jar/inlined/single-man-actions-add.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-add</title><path d=\"M7.044 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.743a.5.5 0 01.741-.3A6.958 6.958 0 008.5 5a6.35 6.35 0 00.929-.07.5.5 0 01.571.564A3 3 0 014.044 5a2.8 2.8 0 01.1-.743zM11.317 12.386a.5.5 0 00-.118-.745A7.456 7.456 0 007.25 10.5a7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643H9.1a.5.5 0 00.492-.411 7.96 7.96 0 011.725-3.703zM17.456 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm3.5 7.5h-2a.5.5 0 00-.5.5v2a1 1 0 01-2 0v-2a.5.5 0 00-.5-.5h-2a1 1 0 010-2h2a.5.5 0 00.5-.5v-2a1 1 0 012 0v2a.5.5 0 00.5.5h2a1 1 0 110 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});