define("ember-svg-jar/inlined/halloween-broom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>halloween-broom</title><path d=\"M23.183 15.264l-4.773-3.819a4.93 4.93 0 00-4.21-.945.5.5 0 01-.467-.133l-.819-.819a2.418 2.418 0 00-2.236-.607.5.5 0 01-.463-.134L1.705.291A1 1 0 00.3 1.709L8.787 10.2a.5.5 0 01.133.474 2.35 2.35 0 00.627 2.24l.819.819a.5.5 0 01.133.467 4.926 4.926 0 00.945 4.207l3.82 4.774a2.185 2.185 0 001.707.819 2.161 2.161 0 001.117-.309 16.382 16.382 0 005.6-5.6 2.186 2.186 0 00-.505-2.827zm-1.359 2.041c-.052.083-.113.178-.182.283a.5.5 0 01-.771.076l-2.339-2.339a.75.75 0 00-1.06 1.06L20 18.915a.5.5 0 01.008.7q-.2.207-.416.417a.5.5 0 01-.7-.007l-2.508-2.508a.75.75 0 00-1.061 1.061l2.312 2.311a.5.5 0 01-.076.77c-.094.063-.179.118-.253.165a.5.5 0 01-.658-.11l-3.641-4.554a2.955 2.955 0 014.153-4.154l4.553 3.643a.5.5 0 01.111.656zM5.25 10.25a.5.5 0 01-.5-.5.75.75 0 00-1.5 0 .5.5 0 01-.5.5.75.75 0 000 1.5.5.5 0 01.5.5.75.75 0 001.5 0 .5.5 0 01.5-.5.75.75 0 000-1.5zM10.25 21.25a.5.5 0 01-.5-.5.75.75 0 00-1.5 0 .5.5 0 01-.5.5.75.75 0 000 1.5.5.5 0 01.5.5.75.75 0 001.5 0 .5.5 0 01.5-.5.75.75 0 000-1.5zM12.25 4H13a.5.5 0 01.5.5v.75a.75.75 0 001.5 0V4.5a.5.5 0 01.5-.5h.75a.75.75 0 000-1.5h-.75A.5.5 0 0115 2v-.75a.75.75 0 00-1.5 0V2a.5.5 0 01-.5.5h-.75a.75.75 0 000 1.5z\"/><circle cx=\"3.5\" cy=\"18.5\" r=\"1\"/><circle cx=\"21.5\" cy=\"6\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});