define("ember-svg-jar/inlined/honey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>honey</title><path d=\"M23.168 1.367a2.31 2.31 0 00-4.264.12 2.38 2.38 0 00-.13.5.5.5 0 01-.291.386l-4.5 2.014a.5.5 0 01-.661-.252l-.2-.457a1 1 0 00-1.826.817l1.634 3.652a1 1 0 001.826-.817l-.205-.457a.5.5 0 01.253-.661l4.5-2.015a.5.5 0 01.481.04 2.325 2.325 0 001.281.386 2.288 2.288 0 00.939-.2 2.313 2.313 0 001.163-3.056zM15.632 17.5h-14a1 1 0 100 2 .673.673 0 01.658.519A5.66 5.66 0 007.632 24h2a5.66 5.66 0 005.341-3.981.675.675 0 01.659-.519 1 1 0 100-2zM11.382 16.5a1.25 1.25 0 001.25-1.25v-3.307a.25.25 0 00-.112-.209.254.254 0 00-.236-.022 1.984 1.984 0 01-.778.159 2.015 2.015 0 01-1.259-.445.243.243 0 00-.35.035 2 2 0 01-2.851.229.25.25 0 00-.414.189V14a1.25 1.25 0 002.5 0 .5.5 0 011 0v1.25a1.25 1.25 0 001.25 1.25zM10.593 10.279a1 1 0 001.826-.817L9.968 3.985a1 1 0 00-1.826.815zM7.446 10.591a1 1 0 001.826-.817L7.638 6.123a1 1 0 00-1.825.817z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});