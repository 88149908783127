define("ember-svg-jar/inlined/road-sign-left-curve-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-left-curve-1</title><path d=\"M23.78 12.53a.749.749 0 000-1.06L12.53.22a.749.749 0 00-1.06 0L.22 11.47a.749.749 0 000 1.06l11.25 11.25a.748.748 0 001.06 0zM14.508 17a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1.793a.5.5 0 00-.147-.354l-2.707-2.707a.5.5 0 01-.146-.354V8.75a.25.25 0 00-.25-.25h-.647a.25.25 0 01-.176-.427l1.719-1.72a.5.5 0 01.707 0l1.72 1.72a.25.25 0 01-.181.427h-.646a.25.25 0 00-.25.25v2.042a.5.5 0 00.146.354l2.707 2.707a.5.5 0 01.147.354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});