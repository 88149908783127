define("ember-svg-jar/inlined/smart-house-open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-house-open</title><path d=\"M5.757 8.712a.248.248 0 00-.142-.044H1.5a1.5 1.5 0 00-1.5 1.5v12a1.5 1.5 0 001.5 1.5h8a1.5 1.5 0 001.5-1.5V10.841a.25.25 0 00-.222-.248 10.961 10.961 0 01-5.021-1.881zm.95 8.749a1 1 0 11-1.414 1.414l-2-2a1 1 0 010-1.414l2-2a1 1 0 111.414 1.414l-1.116 1.116a.25.25 0 000 .354zM24 10.168a1.5 1.5 0 00-1.5-1.5h-4.115a.251.251 0 00-.142.044 10.961 10.961 0 01-5.021 1.881.249.249 0 00-.222.248v11.327a1.5 1.5 0 001.5 1.5h8a1.5 1.5 0 001.5-1.5zm-6.707 4.707a1 1 0 111.414-1.414l2 2a1 1 0 010 1.414l-2 2a1 1 0 01-1.414-1.414l1.116-1.116a.25.25 0 000-.354zM12 2.416a2.741 2.741 0 001.944-.8.75.75 0 10-1.06-1.06 1.252 1.252 0 01-1.768 0 .75.75 0 10-1.06 1.06 2.741 2.741 0 001.944.8z\"/><path d=\"M7.935 3.734A5.71 5.71 0 0012 5.418a5.71 5.71 0 004.065-1.684.776.776 0 000-1.061.749.749 0 00-1.06 0A4.222 4.222 0 0112 3.918a4.222 4.222 0 01-3.005-1.245.749.749 0 00-1.06 0 .776.776 0 000 1.061z\"/><path d=\"M18.187 4.794a.749.749 0 00-1.06 0 7.259 7.259 0 01-10.254 0 .75.75 0 00-1.061 1.061A8.73 8.73 0 0012 8.414a8.726 8.726 0 006.187-2.559.75.75 0 000-1.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});