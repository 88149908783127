define("ember-svg-jar/inlined/rotate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rotate</title><path d=\"M10.664 3.335a2 2 0 00-2.828 0L.585 10.586a2 2 0 000 2.828l7.251 7.252a2 2 0 002.828 0l7.252-7.252a2 2 0 000-2.828zM9.6 18.9a.5.5 0 01-.707 0l-6.541-6.546a.5.5 0 010-.707L8.9 5.1a.5.5 0 01.707 0l6.544 6.544a.5.5 0 010 .708zM24 12a2.978 2.978 0 00-.879-2.121l-1.414-1.415.354-.353a.5.5 0 00-.283-.849L19.3 6.909a.5.5 0 00-.566.566l.354 2.475a.5.5 0 00.849.283l.353-.354 1.414 1.415a1 1 0 010 1.413l-1.414 1.414-.353-.353a.5.5 0 00-.849.282l-.354 2.476a.5.5 0 00.566.565l2.475-.353a.5.5 0 00.283-.849l-.354-.353 1.414-1.414A2.982 2.982 0 0024 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});