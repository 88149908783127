define("ember-svg-jar/inlined/bomb-detonator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bomb-detonator</title><path d=\"M3.048 1.5a1.5 1.5 0 001.5 1.5h8a1.5 1.5 0 000-3h-8a1.5 1.5 0 00-1.5 1.5zM22.952 16a3.883 3.883 0 00-3.573 2.356 1.886 1.886 0 01-1.735 1.144h-.1a.5.5 0 01-.5-.5V9.5a1.5 1.5 0 00-1.5-1.5h-5a.5.5 0 01-.5-.5V5a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v2.5a.5.5 0 01-.5.5h-5a1.5 1.5 0 00-1.5 1.5v13a1.5 1.5 0 001.5 1.5h14a1.5 1.5 0 001.5-1.5V22a.5.5 0 01.5-.5h.1a3.883 3.883 0 003.573-2.356A1.886 1.886 0 0122.952 18a1 1 0 000-2zm-9.9 1a1.5 1.5 0 01-1.125 1.453.5.5 0 00-.375.484v1.146a.913.913 0 01-.909.917H10.3a.25.25 0 01-.25-.25V19.5a.5.5 0 00-1 0v1.25a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25V19.5a.5.5 0 00-1 0v1.25a.25.25 0 01-.25.25h-.343a.913.913 0 01-.909-.917v-1.146a.5.5 0 00-.375-.484A1.5 1.5 0 014.048 17v-1a4.5 4.5 0 019 0z\"/><circle cx=\"6.548\" cy=\"15.5\" r=\"1\"/><circle cx=\"10.548\" cy=\"15.5\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});