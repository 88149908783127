define("ember-svg-jar/inlined/style-three-pin-wifi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-wifi</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm0 16a2 2 0 112-2 2 2 0 01-2 2zm3.922-4.927a.749.749 0 01-1.05.143 4.757 4.757 0 00-5.745 0 .75.75 0 01-.908-1.194 6.26 6.26 0 017.561 0 .75.75 0 01.142 1.051zM18.6 7.95a.752.752 0 01-.6.3.743.743 0 01-.45-.151 9.278 9.278 0 00-11.1 0A.743.743 0 016 8.25a.75.75 0 01-.452-1.35 10.788 10.788 0 0112.9 0 .751.751 0 01.152 1.05z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});