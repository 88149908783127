define("ember-svg-jar/inlined/room-service-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>room-service-cart</title><path d=\"M22.749 21.5a1 1 0 001-1v-7a1 1 0 00-1-1H21.5a.251.251 0 01-.25-.249c0-.655-.006-.534.051-.751a.987.987 0 01.949-.75c.7 0 1.5-.044 1.5-1 0-.912-.759-1-1.5-1a2.994 2.994 0 00-2.974 2.75 3.154 3.154 0 00-.025.75.252.252 0 01-.074.177.247.247 0 01-.177.073h-.75a2 2 0 00-2 2v4a1.915 1.915 0 00.119.663.25.25 0 01-.235.336H14.5a.249.249 0 01-.25-.25V6.5a3 3 0 013-3h3.25a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-1.735a1 1 0 100 2h3.984a1 1 0 00.2-1.979.25.25 0 01-.2-.245v-.552a.248.248 0 01.2-.244 1 1 0 000-1.96.25.25 0 01-.2-.245V1a1 1 0 10-2 0v.25a.25.25 0 01-.25.25H17.25a5 5 0 00-5 5v1.75a.25.25 0 01-.25.25H5.531a2.5 2.5 0 00-2.425 1.894L.3 22.134a1.5 1.5 0 002.91.728l.959-4.3a.25.25 0 01.389-.149l.971.694a1.5 1.5 0 01.571.808l.712 2.992a1.5 1.5 0 002.885-.824l-.712-2.992a4.522 4.522 0 00-1.712-2.425l-2.1-1.5a.249.249 0 01-.1-.264l.8-3.216a.248.248 0 01.242-.189H12a.255.255 0 01.25.256c-.033 8.886.018 8.907-.048 8.989A1.977 1.977 0 0011.75 22a2 2 0 002 2 2.024 2.024 0 001.988-2.2.252.252 0 01.241-.3h6.77zM8.251 1.433a.905.905 0 00-.6-.856 5.685 5.685 0 00-3.792 0 .9.9 0 00-.6.856v.817a.25.25 0 00.25.25H8a.25.25 0 00.25-.25zM3.251 4.5a2.5 2.5 0 005 0v-.75A.25.25 0 008 3.5H3.5a.25.25 0 00-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});