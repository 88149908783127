define("ember-svg-jar/inlined/family-outdoors-playhouse-swing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-outdoors-playhouse-swing</title><path d=\"M11.5 5.5h12a.5.5 0 00.3-.9l-6-4.5a.5.5 0 00-.6 0l-6 4.5a.5.5 0 00.3.9zM23 7H12a1 1 0 00-1 1v1a.5.5 0 01-.5.5H1a1 1 0 000 2h1a.5.5 0 01.5.5v6.72a.5.5 0 01-.252.434.972.972 0 00-.5.846v1a1 1 0 001 1h6a1 1 0 001-1v-1a.972.972 0 00-.5-.846A.5.5 0 019 18.72V12a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v2.5a1 1 0 00.667.943.5.5 0 01.333.472V23a1 1 0 002 0 .5.5 0 01.5-.5h1a.5.5 0 01.5.5 1 1 0 002 0v-7a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v7a1 1 0 002 0v-7.085a.5.5 0 01.333-.472A1 1 0 0024 14.5V8a1 1 0 00-1-1zM7 18.5a.5.5 0 01-.5.5H5a.5.5 0 01-.5-.5V12a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5zm9 1.5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5zm0-3.5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V16a.5.5 0 01.5-.5h1a.5.5 0 01.5.5zm.5-3a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5V11a1.5 1.5 0 013 0zm4-1A1.5 1.5 0 1122 11a1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});