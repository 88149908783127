define("ember-svg-jar/inlined/performance-decrease", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>performance-decrease</title><path d=\"M20 18a1 1 0 00-1 1v1.5a.249.249 0 00.25.25h3.5a.249.249 0 00.25-.25V19a1 1 0 00-1-1zM16 15h-2a1 1 0 00-1 1v4.5a.249.249 0 00.25.25h3.5a.249.249 0 00.25-.25V16a1 1 0 00-1-1zM8 11.5a1 1 0 00-1 1v8a.249.249 0 00.25.25h3.5a.249.249 0 00.25-.25v-8a1 1 0 00-1-1zM1.25 20.75h3.5A.249.249 0 005 20.5v-12a1 1 0 00-1-1H2a1 1 0 00-1 1v12a.249.249 0 00.25.25zM21 13.751a.75.75 0 00.651-1.122l-2-3.5a.748.748 0 00-.626-.377.767.767 0 00-.649.333l-.307.46a.244.244 0 01-.159.106.247.247 0 01-.187-.037L3.555.169a1 1 0 00-1.11 1.664l14.168 9.445a.248.248 0 01.107.16.252.252 0 01-.037.187l-.307.46a.75.75 0 00.531 1.16l4 .5a.726.726 0 00.093.006zM1 24h22a1 1 0 000-2H1a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});