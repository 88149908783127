define("ember-svg-jar/inlined/room-service-cart-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>room-service-cart-1</title><path d=\"M24 20.327a.98.98 0 00-.98-.98h-.734V7.1a4.9 4.9 0 00-4.9-4.9h-4.162a.245.245 0 01-.244-.245v-.731a.98.98 0 00-1.96 0v.735a.245.245 0 01-.244.245H6.612a4.9 4.9 0 00-4.9 4.9v12.243H.98a.98.98 0 100 1.959h.168a.245.245 0 01.221.352 1.435 1.435 0 00-.145.628 1.47 1.47 0 002.939 0 1.434 1.434 0 00-.144-.628.245.245 0 01.221-.352h15.52a.245.245 0 01.221.352 1.434 1.434 0 00-.144.628 1.47 1.47 0 002.939 0 1.435 1.435 0 00-.145-.628.245.245 0 01.221-.352h.168a.979.979 0 00.98-.979zM3.673 7.1a2.943 2.943 0 012.939-2.937h4.164a.245.245 0 01.244.245V4.9a.245.245 0 01-.244.245h-1.96a.98.98 0 000 1.959h5.878a.98.98 0 000-1.959h-1.47a.245.245 0 01-.244-.245v-.49a.245.245 0 01.244-.245h4.164A2.943 2.943 0 0120.327 7.1v12.247H3.673z\"/><path d=\"M17.878 12.49a.98.98 0 00-.98-.98h-1.469a.244.244 0 01-.245-.245v-.734a1.963 1.963 0 00-1.96-1.96h-2.448a1.963 1.963 0 00-1.96 1.96v.734a.244.244 0 01-.245.245H7.1a.98.98 0 00-.98.98v4.9a.979.979 0 00.98.979h9.8a.979.979 0 00.98-.979zm-7.1-1.47a.489.489 0 01.489-.489h1.47a.489.489 0 01.489.489v.245a.244.244 0 01-.244.245H11.02a.244.244 0 01-.244-.245z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});