define("ember-svg-jar/inlined/plane-boarding-pass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-boarding-pass</title><path d=\"M24 5.5A1.5 1.5 0 0022.5 4h-21A1.5 1.5 0 000 5.5v13A1.5 1.5 0 001.5 20h21a1.5 1.5 0 001.5-1.5zM11.209 8.017l.784-.4a1 1 0 01.99.041l2 1.251a.25.25 0 01-.037.442l-1.682.7a.5.5 0 01-.474-.05l-1.635-1.127a.5.5 0 01.054-.857zM3 18.25a1 1 0 111-1 1 1 0 01-1 1zm0-3.5a1 1 0 111-1 1 1 0 01-1 1zm0-3.5a1 1 0 111-1 1 1 0 01-1 1zm0-3.5a1 1 0 111-1 1 1 0 01-1 1zm17.737 3.05l-2.825 1.394a.5.5 0 00-.206.19l-2.633 4.346a.515.515 0 01-.2.185l-.91.472a.5.5 0 01-.691-.64l1.153-2.7a.251.251 0 00-.322-.331L10.6 15.1a1 1 0 01-.909-.091l-3.443-2.218a.5.5 0 01.022-.853l1.073-.618a.5.5 0 01.457-.02l2.226 1a.5.5 0 00.4.006l9.33-3.858a1.278 1.278 0 01.989 2.357z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});