define("ember-svg-jar/inlined/instrument-piano-keys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>instrument-piano-keys</title><path d=\"M22 3h-2.768a.249.249 0 01-.247-.214A1.5 1.5 0 0017.5 1.5h-2a1.5 1.5 0 00-1.485 1.286.249.249 0 01-.247.214h-3.536a.249.249 0 01-.247-.214A1.5 1.5 0 008.5 1.5h-2a1.5 1.5 0 00-1.485 1.286.249.249 0 01-.247.214H2a2 2 0 00-2 2v15.5a2 2 0 002 2h20a2 2 0 002-2V5a2 2 0 00-2-2zm-8.25 2a.25.25 0 01.25.25v7.25a1.5 1.5 0 001.309 1.488.218.218 0 01.191.217v6.045a.25.25 0 01-.25.25h-6.5a.25.25 0 01-.25-.25v-6.018a.249.249 0 01.214-.247A1.5 1.5 0 0010 12.5V5.25a.25.25 0 01.25-.25zM2 5.5a.5.5 0 01.5-.5h2.25a.25.25 0 01.25.25v7.25a1.5 1.5 0 001.286 1.485.249.249 0 01.214.247v6.018a.25.25 0 01-.25.25H2.5A.5.5 0 012 20zM22 20a.5.5 0 01-.5.5h-3.75a.25.25 0 01-.25-.25v-6.018a.249.249 0 01.214-.247A1.5 1.5 0 0019 12.5V5.25a.25.25 0 01.25-.25h2.25a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});