define("ember-svg-jar/inlined/database-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-warning</title><path d=\"M1.657 15.59a.25.25 0 00-.408.194V17.5c0 1.856 3 3.412 7.074 3.864a.258.258 0 00.259-.14c.078-.156.38-.729 1.508-2.88a.248.248 0 00-.207-.366c-3.227-.124-6.448-.941-8.226-2.388zM19.127 11.588a.241.241 0 00-.092.318l.731 1.392a.25.25 0 00.469-.084 1.7 1.7 0 00.014-.214v-1.718a.25.25 0 00-.407-.194 6.6 6.6 0 01-.715.5z\"/><path d=\"M1.249 13c0 2.2 4.2 3.976 9.394 4a.252.252 0 00.223-.134l1.6-3.049a.25.25 0 00-.239-.366c-5.137.349-8.895-1.036-10.572-2.369a.25.25 0 00-.406.2z\"/><path d=\"M10.75 9c-3.5 0-7.156-.833-9.093-2.411a.25.25 0 00-.408.194V8.5c0 2.21 4.254 4 9.5 4a21.966 21.966 0 002.35-.128.254.254 0 00.2-.134l.288-.549A3.075 3.075 0 0115.5 10.1a2.94 2.94 0 012.718.665.257.257 0 00.3.034A2.926 2.926 0 0020.249 8.5V6.784a.25.25 0 00-.408-.193C17.9 8.168 14.254 9 10.75 9z\"/><ellipse cx=\"10.75\" cy=\"4\" rx=\"9.5\" ry=\"4\"/><path d=\"M17.533 12.272a1.449 1.449 0 00-2.562 0l-5.056 9.633a1.433 1.433 0 00.048 1.409A1.457 1.457 0 0011.2 24h10.108a1.457 1.457 0 001.233-.686 1.433 1.433 0 00.048-1.409zm-2.281 8.978a1 1 0 111 1 1 1 0 01-1-1zm.25-5.75a.75.75 0 011.5 0v3a.75.75 0 01-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});