define("ember-svg-jar/inlined/fox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fox</title><path d=\"M23.772 11.232a10.5 10.5 0 00-3.144-3.3 8.609 8.609 0 00.307-5.9 1.595 1.595 0 00-2.435-.913c-1.648 1.106-3.647 2.773-4.229 4.635a18.5 18.5 0 00-4.547 0C9.144 3.892 7.145 2.225 5.5 1.119a1.594 1.594 0 00-2.432.919 8.6 8.6 0 00.306 5.9 10.518 10.518 0 00-3.144 3.3 1.594 1.594 0 001.335 2.418 5.261 5.261 0 013.186 1.414 30.841 30.841 0 014.791 5.13v.488a2.459 2.459 0 104.917 0V20.2a30.788 30.788 0 014.792-5.142 5.261 5.261 0 013.186-1.414 1.595 1.595 0 001.335-2.416zM9.077 13.324A1.329 1.329 0 1110.406 12a1.328 1.328 0 01-1.329 1.324zm5.847 0A1.329 1.329 0 1116.253 12a1.328 1.328 0 01-1.329 1.324z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});