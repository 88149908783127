define("ember-svg-jar/inlined/database-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-clock</title><path d=\"M8.167 21.122a.25.25 0 00.257-.352 8.335 8.335 0 01-.73-2.964.25.25 0 00-.213-.233 12.41 12.41 0 01-5.83-2.15.249.249 0 00-.407.194v1.691c0 1.824 2.935 3.357 6.923 3.814zM1.244 12.859c0 1.713 2.586 3.168 6.2 3.718a.25.25 0 00.286-.219 8.348 8.348 0 01.809-2.764.251.251 0 00-.2-.36 13.284 13.284 0 01-6.689-2.259.249.249 0 00-.407.194zM10.635 8.9c-3.461 0-7.068-.822-8.984-2.379a.249.249 0 00-.407.194v1.696c0 1.977 3.447 3.61 7.945 3.9a.25.25 0 00.215-.1 8.352 8.352 0 019.955-2.632.25.25 0 00.3-.085c.422-.591.377-.973.366-2.781a.25.25 0 00-.408-.192C17.7 8.083 14.1 8.9 10.635 8.9z\"/><path d=\"M1.245 3.963c0 2.184 4.2 3.954 9.39 3.954s9.391-1.77 9.391-3.954S15.822.009 10.635.009s-9.39 1.77-9.39 3.954zM16.072 10.624a6.684 6.684 0 106.684 6.684 6.692 6.692 0 00-6.684-6.684zm0 11.367a4.684 4.684 0 114.684-4.683 4.688 4.688 0 01-4.684 4.683z\"/><path d=\"M16.072 18.308h1.977a1 1 0 000-2h-.727a.25.25 0 01-.25-.25v-1.222a1 1 0 00-2 0v2.472a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});