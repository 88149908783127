define("ember-svg-jar/inlined/punishment-prisoner-bars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>punishment-prisoner-bars</title><path d=\"M2 0a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zm7.693 3.354A.25.25 0 009.75 3.2v-.95A.25.25 0 0110 2h4a.25.25 0 01.25.25v.95a.25.25 0 00.057.159 2.94 2.94 0 010 3.792.25.25 0 00-.057.159v1.319a.25.25 0 01-.31.243A3.887 3.887 0 0013 8.75h-2a3.887 3.887 0 00-.94.122.25.25 0 01-.31-.243V7.305a.25.25 0 00-.057-.159 2.94 2.94 0 010-3.792zM4.25 21.752A.25.25 0 014 22H2.25a.25.25 0 01-.25-.25V2.25A.25.25 0 012.25 2H4a.25.25 0 01.25.25zm8.25 0a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25V18.5a.5.5 0 011 0zm5.75 0A.249.249 0 0118 22h-2.75a.249.249 0 01-.25-.25v-5h-1a2.233 2.233 0 01-1.8-.915.249.249 0 00-.4 0 2.233 2.233 0 01-1.8.915H9v5a.249.249 0 01-.25.25H6a.249.249 0 01-.25-.25V2.25A.25.25 0 016 2h2a.25.25 0 01.25.25v7.424a.248.248 0 01-.083.185A4.217 4.217 0 006.75 13v.5A2.253 2.253 0 009 15.75h1a1.25 1.25 0 000-2.5h-.225a.5.5 0 110-1H10a2.233 2.233 0 011.8.915.249.249 0 00.4 0 2.233 2.233 0 011.8-.915h.225a.5.5 0 110 1H14a1.25 1.25 0 000 2.5h1a2.253 2.253 0 002.25-2.25V13a4.217 4.217 0 00-1.417-3.141.248.248 0 01-.083-.185V2.25A.25.25 0 0116 2h2a.25.25 0 01.25.25zm3.75 0a.25.25 0 01-.25.25H20a.25.25 0 01-.25-.25V2.25A.25.25 0 0120 2h1.75a.25.25 0 01.25.25z\"/><circle cx=\"12\" cy=\"5.25\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});