define("ember-svg-jar/inlined/pets-paw-bowl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pets-paw-bowl</title><path d=\"M19.322 8.307a.5.5 0 00-.461-.3H5.256a.5.5 0 00-.458.3C4 10.134-.075 19.8 1.854 22.126a4.489 4.489 0 003.463 1.626h13.361a4.489 4.489 0 003.462-1.626c1.916-2.309-2.04-11.974-2.818-13.819zM9.228 18.14l1.822-2.486a1.194 1.194 0 011.924 0l1.824 2.5a1.227 1.227 0 01-.961 1.942h-3.648a1.219 1.219 0 01-1.064-.682 1.247 1.247 0 01.103-1.274zm6.539-4.9a1.246 1.246 0 11-1.246 1.246 1.245 1.245 0 011.246-1.242zM12 10.752A1.246 1.246 0 1110.751 12 1.246 1.246 0 0112 10.752zm-3.741 2.492a1.246 1.246 0 11-1.246 1.246 1.247 1.247 0 011.246-1.246zM18.78 6.5a.5.5 0 00.469-.327L21.1 1.191a.653.653 0 00-.149-.821.638.638 0 00-.81.046l-4.375 2.823L12.772.6a.966.966 0 00-1.381-.037L8.647 3.236 4 .418A.648.648 0 003.183.37a.647.647 0 00-.151.811l1.852 4.994a.5.5 0 00.469.327z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});