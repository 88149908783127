define("ember-svg-jar/inlined/gesture-swipe-horizontal-right-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-horizontal-right-2</title><path d=\"M23.352 7.92A.751.751 0 0022 7.612l-.525.734a.249.249 0 01-.312.079 17.118 17.118 0 00-3.685-1.264 1 1 0 10-.443 1.95 15.638 15.638 0 012.86.931.249.249 0 01.1.374l-.442.618a.751.751 0 00.733 1.176l2.951-.489a.75.75 0 00.617-.868zM7.436 7.912a1 1 0 00-1.2-.753A10.882 10.882 0 00.4 10.277a1 1 0 001.46 1.367 9 9 0 014.822-2.535 1 1 0 00.754-1.197zM16.3 12.413L12.559 11.3a.25.25 0 01-.179-.239V6.489a1 1 0 10-2 0v7.063a.25.25 0 01-.455.143c-1.49-2.138-3.855-1.449-2.761.224l2.912 4.368a.5.5 0 00.416.222h5.434a.5.5 0 00.492-.411c.2-1.1.665-3.683.776-4.165a1.281 1.281 0 00-.894-1.52z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});