define("ember-svg-jar/inlined/public-service-police-van", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>public-service-police-van</title><path d=\"M23 2.5H11a.5.5 0 00-.5.5v2.75a.25.25 0 01-.25.25H5.54a1.5 1.5 0 00-1.355.88l-1.894 4.608a.253.253 0 01-.086.108L.313 12.984A.969.969 0 000 13.71v4.79a.989.989 0 001 1h.552a.25.25 0 00.247-.214 3.232 3.232 0 016.4 0 .25.25 0 00.247.214h7.1a.25.25 0 00.247-.214 3.232 3.232 0 016.4 0 .25.25 0 00.247.214H23a.989.989 0 001-1v-15a1 1 0 00-1-1zm-13 8.75a.25.25 0 01-.25.25H4.821a.253.253 0 01-.208-.111.25.25 0 01-.023-.234l1.233-3A.25.25 0 016.054 8h3.7a.25.25 0 01.25.25zm12.029-2.808L19.7 10.385a.249.249 0 00-.074.28l1.15 3.069a.25.25 0 01-.38.291L17.7 12.107a.251.251 0 00-.291 0l-2.688 1.918a.25.25 0 01-.379-.291l1.15-3.069a.25.25 0 00-.074-.28L13.09 8.442A.25.25 0 0113.25 8h2.65a.25.25 0 00.23-.151l1.21-2.811a.25.25 0 01.46 0l1.2 2.81a.249.249 0 00.224.152h2.645a.25.25 0 01.16.442z\"/><path d=\"M5.5 5h3a.75.75 0 00.75-.75 2.25 2.25 0 00-4.5 0A.75.75 0 005.5 5z\"/><circle cx=\"5\" cy=\"19.75\" r=\"2.25\"/><circle cx=\"19\" cy=\"19.75\" r=\"2.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});