define("ember-svg-jar/inlined/social-profile-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-profile-avatar</title><path d=\"M23.106 13.688q-.257-.8-.507-1.608C20.936 6.763 19.216 1.264 13.3.183A10.769 10.769 0 004.866 2.2 9.961 9.961 0 00.889 9.283a10.186 10.186 0 003.956 9.071v5.139a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-2.5h.5a3.505 3.505 0 003.5-3.5v-2.5h2.312a1 1 0 00.949-1.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});