define("ember-svg-jar/inlined/single-woman-news", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-news</title><path d=\"M24 2.5A1.5 1.5 0 0022.5 1h-21A1.5 1.5 0 000 2.5V17a1.5 1.5 0 001.5 1.5h9a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5H4a1 1 0 000 2h16a1 1 0 000-2h-6.5a.5.5 0 01-.5-.5V19a.5.5 0 01.5-.5h9A1.5 1.5 0 0024 17zm-15 7a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5zm11.28 5.686a.5.5 0 01-.388.814h-8a.5.5 0 01-.388-.815A5.69 5.69 0 0115.9 13a5.676 5.676 0 014.38 2.186zm.119-4.041a.633.633 0 01-1.065.686 4.112 4.112 0 01-.516-1.206.252.252 0 00-.175-.178.249.249 0 00-.242.062 3.595 3.595 0 01-5.017 0 .25.25 0 00-.417.116 4.1 4.1 0 01-.517 1.208.633.633 0 11-1.064-.686 3.521 3.521 0 00.5-1.934v-1.7a4.012 4.012 0 118.024 0v1.7a3.521 3.521 0 00.49 1.932z\"/><path d=\"M19.09 7.9a.5.5 0 00-.4-.585 4.345 4.345 0 01-2.408-1.381.425.425 0 00-.394-.193c-.311.013-.391.189-.393.193a4.348 4.348 0 01-2.405 1.382.5.5 0 00-.4.585.505.505 0 00.586.4 5.3 5.3 0 002.449-1.211.25.25 0 01.327 0 5.294 5.294 0 002.453 1.21.5.5 0 00.585-.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});