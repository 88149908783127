define("ember-svg-jar/inlined/car-voyage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-voyage</title><path d=\"M7 2.5h.775a.5.5 0 01.433.25A1.493 1.493 0 009.5 3.5h6A1.5 1.5 0 0017 2v-.5A1.5 1.5 0 0015.5 0h-6a1.493 1.493 0 00-1.292.75.5.5 0 01-.433.25H7a.75.75 0 000 1.5zM22.125 13.281a1 1 0 10-1.25-1.562l-.395.315a.5.5 0 01-.809-.336l-.021-.191V11.5a3.018 3.018 0 00-2.99-2.75H7.34a3.011 3.011 0 00-2.986 2.719v.012l-.024.217a.5.5 0 01-.809.336l-.395-.315a1 1 0 10-1.25 1.562l.853.683a.5.5 0 01.035.751A2.471 2.471 0 002 16.5v2A1.5 1.5 0 003.5 20h17a1.5 1.5 0 001.5-1.5v-2a2.471 2.471 0 00-.763-1.785.5.5 0 01.035-.751zM6.344 11.673v-.011a.991.991 0 011-.912h9.32a1 1 0 011 .94v.01l.164 1.5a.5.5 0 01-.5.554H6.673a.5.5 0 01-.5-.555zM5.5 18.5A1.5 1.5 0 117 17a1.5 1.5 0 01-1.5 1.5zm13 0A1.5 1.5 0 1120 17a1.5 1.5 0 01-1.5 1.5zM7.5 21h-3a.5.5 0 00-.5.5v.5a2 2 0 004 0v-.5a.5.5 0 00-.5-.5zM19.5 21h-3a.5.5 0 00-.5.5v.5a2 2 0 004 0v-.5a.5.5 0 00-.5-.5z\"/><rect x=\"10\" y=\"4.5\" width=\"4\" height=\"3.5\" rx=\".5\" ry=\".5\"/><path d=\"M7 6v.5A1.5 1.5 0 008.5 8a.5.5 0 00.5-.5V5a.5.5 0 00-.5-.5A1.5 1.5 0 007 6zM15.5 4.5a.5.5 0 00-.5.5v2.5a.5.5 0 00.5.5A1.5 1.5 0 0017 6.5V6a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});