define("ember-svg-jar/inlined/style-three-pin-money", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-money</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm0 17a6.5 6.5 0 116.5-6.5A6.508 6.508 0 0112 17z\"/><path d=\"M13.31 10.216l-2.063-.825a.592.592 0 01.22-1.141H13.5a.75.75 0 000-1.5h-.25a.5.5 0 01-.5-.5.75.75 0 00-1.5 0v.111a.5.5 0 01-.362.481 2.084 2.084 0 00-.2 3.941l2.063.825a.592.592 0 01-.22 1.142H10.5a.75.75 0 000 1.5h.25a.5.5 0 01.5.5.75.75 0 001.5 0v-.112a.5.5 0 01.362-.481 2.084 2.084 0 00.2-3.941z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});