define("ember-svg-jar/inlined/instrument-classical-piano", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>instrument-classical-piano</title><path d=\"M10.7 8.053a1 1 0 111.6-1.2l4.5 6a1.046 1.046 0 01.061.092.016.016 0 00.014.008H22.5a.5.5 0 00.5-.5v-.5a6.007 6.007 0 00-6-6 2 2 0 01-2-2A4.014 4.014 0 0012.085.1a1.5 1.5 0 00-1.818.941L6.032 12.277a.5.5 0 00.468.676h7.375a.25.25 0 00.2-.4zM24 15.953a1.5 1.5 0 00-1.5-1.5h-16a1.5 1.5 0 00-1.5 1.5v.75a.25.25 0 01-.25.25H1.5a1.5 1.5 0 00-1.5 1.5v2a1.5 1.5 0 001.5 1.5h1.75a.25.25 0 01.25.25v.75a1 1 0 002 0V22.2a.25.25 0 01.25-.25h14.5a.25.25 0 01.25.25v.75a1 1 0 102 0v-.768a.25.25 0 01.214-.247A1.5 1.5 0 0024 20.453z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});