define("ember-svg-jar/inlined/history-woman-queen.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-woman-queen</title><path d=\"M17.146 16.838a5.351 5.351 0 001.317.162 3.8 3.8 0 002.921-1.12.5.5 0 00-.231-.8c-3.075-.98-1.834-5.153-2.253-7.68a.247.247 0 01.033-.17.425.425 0 00.067-.185l.5-5.5a.5.5 0 00-.656-.519l-4.06 1.353a.252.252 0 01-.256-.06L12.354.146a.5.5 0 00-.708 0L9.474 2.319a.252.252 0 01-.256.06l-4.06-1.353a.5.5 0 00-.656.519l.5 5.5a.425.425 0 00.063.182.247.247 0 01.035.173c-.408 2.479.787 6.65-2.251 7.626a.5.5 0 00-.327.337.5.5 0 00.1.46 3.8 3.8 0 002.921 1.119 5.351 5.351 0 001.317-.161 3.48 3.48 0 001.028-.436.25.25 0 01.274 0 6.97 6.97 0 007.643.032.252.252 0 01.275 0 3.478 3.478 0 001.066.461zM7 10.5v-2a3.907 3.907 0 01.042-.546.249.249 0 01.274-.213A45 45 0 0012 8a45 45 0 004.684-.259.249.249 0 01.274.213A3.907 3.907 0 0117 8.5v2a5 5 0 01-10 0z\"/><path d=\"M.5 23.5a.5.5 0 00.5.5h4a7.2 7.2 0 006.778-4 .25.25 0 01.444 0A7.2 7.2 0 0019 24h4a.5.5 0 00.5-.5v-1.782a2.105 2.105 0 00-1-1.756c-1.423-.92-4.68-2.462-10.5-2.462s-9.078 1.542-10.5 2.462a2.105 2.105 0 00-1 1.756zM10.38 12.462a.641.641 0 00.317.72 2.4 2.4 0 00.435.179 4.157 4.157 0 00.863.125h.022a2.987 2.987 0 00.853-.125 2.441 2.441 0 00.435-.181.639.639 0 00-.3-1.2H11a.64.64 0 00-.62.482z\"/><circle cx=\"14\" cy=\"10\" r=\"1\"/><circle cx=\"10\" cy=\"10\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});