define("ember-svg-jar/inlined/crypto-currency-bitcoin-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-shield</title><path d=\"M10.5 6.384a.25.25 0 00-.25.25v2.778a.25.25 0 00.25.25h2.111a1.639 1.639 0 100-3.278zM12.611 12.162H10.5a.25.25 0 00-.25.25v2.777a.249.249 0 00.25.25h2.111a1.639 1.639 0 100-3.277z\"/><path d=\"M21.506 1.95A23.116 23.116 0 0012 0a23.111 23.111 0 00-9.506 1.95 1.6 1.6 0 00-.929 1.467v7.969a12.822 12.822 0 008.168 11.968l1.114.431a3.2 3.2 0 002.306 0l1.114-.431a12.822 12.822 0 008.168-11.968V3.417a1.6 1.6 0 00-.929-1.467zM7.75 5.134A1.25 1.25 0 019 3.884h.723a.194.194 0 00.194-.195V3a1.25 1.25 0 012.5 0v.689a.194.194 0 00.194.195 4.132 4.132 0 013.108 6.863.25.25 0 000 .33 4.131 4.131 0 01-3.108 6.862.194.194 0 00-.194.2v.361a1.25 1.25 0 01-2.5 0v-.366a.194.194 0 00-.194-.2H9a1.25 1.25 0 01-1.25-1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});