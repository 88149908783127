define("ember-svg-jar/inlined/phone-action-cart-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-cart-1</title><circle cx=\"14.125\" cy=\"12.875\" r=\"1.5\"/><circle cx=\"18.125\" cy=\"12.875\" r=\"1.5\"/><path d=\"M11.125 3.375a.5.5 0 00-.475.658l2 6a.5.5 0 00.475.342h6.75a.5.5 0 00.481-.365l2.491-8.934A.75.75 0 0021.4.674l-.648 2.335a.5.5 0 01-.482.366zM12.125 16.125v2.25a1 1 0 01-1 1h-7a1 1 0 01-1-1v-11a1 1 0 011-1h5.14a.25.25 0 00.235-.337l-.745-2a.25.25 0 00-.234-.163H3.625a2.5 2.5 0 00-2.5 2.5v15a2.5 2.5 0 002.5 2.5h8a2.5 2.5 0 002.5-2.5v-5.25a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});