define("ember-svg-jar/inlined/app-window-mail-at", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-mail-at</title><path d=\"M12.05 19.251a5.722 5.722 0 001.479-.193.75.75 0 00-.385-1.449A4.251 4.251 0 1116.3 13.5v.5a.75.75 0 11-1.5 0 .718.718 0 00-.034-.167 2.608 2.608 0 00.034-.333 2.75 2.75 0 10-2.75 2.75 2.726 2.726 0 001.85-.73A2.242 2.242 0 0017.8 14v-.5a5.75 5.75 0 10-5.75 5.75zm0-4.5A1.25 1.25 0 1113.3 13.5a1.251 1.251 0 01-1.25 1.251z\"/><path d=\"M24 4.751a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h18a3 3 0 003-3zm-14.346-1a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.562.091a1 1 0 01.908-.591.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.987.987 0 01.092-.409zM22 19.251a1 1 0 01-1 1H3a1 1 0 01-1-1V7a.25.25 0 01.25-.25h19.5A.25.25 0 0122 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});