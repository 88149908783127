define("ember-svg-jar/inlined/conversation-seen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>conversation-seen</title><path d=\"M15.156 10.851a.253.253 0 000-.2 5.032 5.032 0 00-9.312 0 .253.253 0 000 .2 5.032 5.032 0 009.312 0zM8.5 10.75a2 2 0 112 2 2 2 0 01-2-2z\"/><path d=\"M18.5 3h-16A2.5 2.5 0 000 5.5v11A2.5 2.5 0 002.5 19h.689a.249.249 0 01.177.073l4.78 4.781A.5.5 0 008.5 24a.489.489 0 00.191-.038A.5.5 0 009 23.5v-4.25a.25.25 0 01.25-.25h9.25a2.5 2.5 0 002.5-2.5v-11A2.5 2.5 0 0018.5 3zM4.285 10.976a.742.742 0 010-.452 6.543 6.543 0 0112.43 0 .742.742 0 010 .452 6.543 6.543 0 01-12.43 0z\"/><path d=\"M21.25 0h-16a.75.75 0 000 1.5h16a1.252 1.252 0 011.25 1.25v8a.75.75 0 001.5 0v-8A2.752 2.752 0 0021.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});