define("ember-svg-jar/inlined/subtract-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>subtract-square</title><path d=\"M22 .5H2A1.5 1.5 0 00.5 2v20A1.5 1.5 0 002 23.5h20a1.5 1.5 0 001.5-1.5V2A1.5 1.5 0 0022 .5zM19 12a1 1 0 01-1 1H6a1 1 0 010-2h12a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});