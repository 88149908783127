define("ember-svg-jar/inlined/dog-jump", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dog-jump</title><path d=\"M19.814 12a32.616 32.616 0 00-.22-3.849.249.249 0 00-.184-.211c-4.943-1.313-6.986-1.811-11.253-4.323L6.716 1.7a.5.5 0 00-.364-.2.512.512 0 00-.389.146 5.36 5.36 0 00-1.012 1.881 2.94 2.94 0 00-2.022 2.038L1.9 5.776A1.5 1.5 0 00.794 7.808l.259.627a1.506 1.506 0 001.579.928 8.432 8.432 0 013.068.119 7.72 7.72 0 011.287.443A3.889 3.889 0 003.9 11.219a2.964 2.964 0 00-.711 2.223 1.587 1.587 0 00.9 1 2.913 2.913 0 003.393-.876c.836.226 3.05.669 4.369-.352a2.4 2.4 0 00.191-1.72.75.75 0 111.483-.221 4.748 4.748 0 01-.056 1.909 16.013 16.013 0 013.711 1.028 3.506 3.506 0 001.648 3.96.251.251 0 00.233.03.247.247 0 00.155-.175A26.805 26.805 0 0019.814 12zM6.564 6a1 1 0 11-1-1 1 1 0 011 1z\"/><path d=\"M18.814.5c-1.084 0-2.594.769-3.631 4.433a1 1 0 001.925.545c.637-2.25 1.424-2.978 1.706-2.978.411 0 1.123.981 1.683 2.9a23.869 23.869 0 01.817 6.6 21.252 21.252 0 01-1.214 7.752c-.47 1.157-.97 1.748-1.288 1.748-.282 0-1.069-.729-1.706-2.979a1 1 0 00-1.925.545c1.04 3.664 2.549 4.434 3.633 4.434 2.954 0 4.5-5.785 4.5-11.5 0-5.539-1.408-11.5-4.5-11.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});