define("ember-svg-jar/inlined/keyhole-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyhole-square</title><path d=\"M21.857 0H2.143A2.145 2.145 0 000 2.143v19.715A2.145 2.145 0 002.143 24h19.714A2.145 2.145 0 0024 21.858V2.143A2.145 2.145 0 0021.857 0zM14.5 17.928a.5.5 0 01-.493.572H9.712a.5.5 0 01-.49-.594l1.255-5.86a3.429 3.429 0 01.693-6.452 3.492 3.492 0 014.33 3.352 3.44 3.44 0 01-1.978 3.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});