define("ember-svg-jar/inlined/phone-expand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-expand</title><path d=\"M3.476 1.708L4.33.854A.5.5 0 003.977 0H.5a.5.5 0 00-.5.5v3.477a.5.5 0 00.854.353l.854-.854 1.641 1.64a1.249 1.249 0 001.767-1.767zM3.349 18.884l-1.641 1.64-.854-.854a.5.5 0 00-.854.353V23.5a.5.5 0 00.5.5h3.477a.5.5 0 00.353-.854l-.854-.854 1.64-1.641a1.249 1.249 0 10-1.767-1.767zM23.5 0h-3.477a.5.5 0 00-.353.854l.854.854-1.64 1.641a1.249 1.249 0 101.767 1.767l1.641-1.64.854.854A.5.5 0 0024 3.977V.5a.5.5 0 00-.5-.5zM23.691 19.562a.5.5 0 00-.545.108l-.854.854-1.641-1.64a1.249 1.249 0 10-1.767 1.767l1.64 1.641-.854.854a.5.5 0 00.353.854H23.5a.5.5 0 00.5-.5v-3.477a.5.5 0 00-.309-.461zM15 3H9a2.5 2.5 0 00-2.5 2.5v13A2.5 2.5 0 009 21h6a2.5 2.5 0 002.5-2.5v-13A2.5 2.5 0 0015 3zm1 12.5a1 1 0 01-1 1H9a1 1 0 01-1-1V6a1 1 0 011-1h6a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});