define("ember-svg-jar/inlined/phone-monitor-exchange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-monitor-exchange</title><path d=\"M23.8 11.221a1.753 1.753 0 00-1.75-1.75h-6a1.753 1.753 0 00-1.75 1.75v11a1.752 1.752 0 001.75 1.75h6a1.752 1.752 0 001.75-1.75zm-2 9a.249.249 0 01-.25.25h-5a.249.249 0 01-.25-.25v-8.5a.251.251 0 01.25-.25h5a.251.251 0 01.25.25zM12.2 2.028a2 2 0 00-2-2h-8a2 2 0 00-2 2v5.693a1.493 1.493 0 00.485 1.1 1.99 1.99 0 001.515.707h2.75a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H3.7a1 1 0 000 2h5a1 1 0 000-2H7.45a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h2.75a1.987 1.987 0 001.514-.709 1.49 1.49 0 00.486-1.1V2.028zm-2.25 0a.25.25 0 01.25.25v4a.25.25 0 01-.25.25h-7.5a.25.25 0 01-.25-.25v-4a.25.25 0 01.25-.25zM20.3 8.179a1 1 0 001-1v-.208a4 4 0 00-4-4h-2.255a1 1 0 100 2H17.3a2 2 0 012 2v.208a1 1 0 001 1zM12.05 19.971H8.3a2 2 0 01-2-2v-2.794a1 1 0 00-2 0v2.794a4 4 0 004 4h3.75a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});