define("ember-svg-jar/inlined/martial-arts-kimono", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>martial-arts-kimono</title><path d=\"M17.443 15.7a.5.5 0 00-.493-.415h-3.564a.248.248 0 00-.2.1.251.251 0 00-.035.225l1.049 3.167a.75.75 0 01-1.423.475l-.546-1.638a.25.25 0 00-.474 0l-.546 1.638a.75.75 0 11-1.423-.475l1.056-3.167a.25.25 0 00-.237-.329H7.034a.5.5 0 00-.492.411L5.2 23.117a.749.749 0 00.737.883h12.04a.751.751 0 00.74-.877zM23.633 6.837c-1.084-1.086-1.919-1.937-2.581-2.608C18.528 1.661 18.429 1.561 16.7.744l-.393-.185A5.768 5.768 0 0013.839 0h-3.68a5.763 5.763 0 00-2.47.56L7.3.747c-1.723.814-1.822.913-4.339 3.474-.663.675-1.5 1.526-2.59 2.617a1.256 1.256 0 000 1.77l2.314 2.315A1.242 1.242 0 004.359 11l.949-.792a.5.5 0 01.814.3l.446 2.807a.5.5 0 00.494.421h1.919a.5.5 0 00.457-.3l1.82-4.114a.5.5 0 00-.01-.426l-2.91-5.811A.75.75 0 019.009 2h5.979a.751.751 0 01.686 1.053L11.1 13.39a.249.249 0 00.229.351h5.6a.5.5 0 00.494-.421l.446-2.807a.5.5 0 01.814-.306l.957.793a1.242 1.242 0 001.681-.077l2.316-2.316a1.255 1.255 0 00-.004-1.77z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});