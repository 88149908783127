define("ember-svg-jar/inlined/wifi-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-disable</title><path d=\"M14.865 8.631a1 1 0 001.414-1.414 9.553 9.553 0 00-13.494 0A1 1 0 004.2 8.631a7.55 7.55 0 0110.665 0z\"/><path d=\"M17.281 5.577A1 1 0 0018.7 4.163a12.976 12.976 0 00-18.328 0 1 1 0 101.409 1.414 10.975 10.975 0 0115.5 0zM13.221 10.931a1 1 0 00-.27-1.387A6.132 6.132 0 005.2 10.3a1 1 0 101.414 1.414 4.13 4.13 0 015.22-.507 1 1 0 001.387-.276zM18.934 10.817a6.5 6.5 0 00-7.817 7.813 6.412 6.412 0 004.824 4.83 6.8 6.8 0 001.486.166 6.494 6.494 0 006.333-7.984 6.409 6.409 0 00-4.826-4.825zm-3.6 10.295a.251.251 0 01-.058-.4l5.738-5.738a.25.25 0 01.4.057 4.5 4.5 0 01-6.077 6.078zm-1.07-7.149a4.6 4.6 0 015.278-.8.25.25 0 01.058.4L13.863 19.3a.25.25 0 01-.4-.057 4.574 4.574 0 01.802-5.28z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});