define("ember-svg-jar/inlined/stove-gas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>stove-gas</title><path d=\"M6.4.025A.263.263 0 006.063.4a3.2 3.2 0 01.243 2.756.25.25 0 01-.4.086 1.675 1.675 0 01-.446-.638.264.264 0 00-.149-.156.267.267 0 00-.216.009A2.716 2.716 0 003.5 4.973a3.027 3.027 0 006.054 0A5.573 5.573 0 006.4.025zM22.5 11.5h-21A1.5 1.5 0 000 13v9.5A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5V13a1.5 1.5 0 00-1.5-1.5zM7 13a1 1 0 11-1 1 1 1 0 011-1zm11 9a.5.5 0 01-.5.5h-11A.5.5 0 016 22v-5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5zm-1-7a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M14.75 19.5h-5.5a.75.75 0 010-1.5h5.5a.75.75 0 010 1.5zM9.527 10h-6a1 1 0 010-2h6a1 1 0 110 2zM16.9.025a.263.263 0 00-.337.375 3.2 3.2 0 01.243 2.756.25.25 0 01-.4.086 1.675 1.675 0 01-.446-.638.264.264 0 00-.149-.156.267.267 0 00-.216.009A2.716 2.716 0 0014 4.973a3.027 3.027 0 106.054 0A5.573 5.573 0 0016.9.025z\"/><path d=\"M20.027 10h-6a1 1 0 010-2h6a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});