define("ember-svg-jar/inlined/bulb-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bulb-1</title><path d=\"M12 4.118a7.2 7.2 0 00-7.1 7.106 6.978 6.978 0 004.394 6.561.734.734 0 00.259.048.042.042 0 00.034-.017.041.041 0 00.009-.037l-1.253-5.917a.734.734 0 011-.83l1.433.6a3.2 3.2 0 002.449 0l1.432-.6a.734.734 0 011 .83l-1.251 5.917a.041.041 0 00.009.037.045.045 0 00.034.017.734.734 0 00.259-.048 6.978 6.978 0 004.392-6.561A7.2 7.2 0 0012 4.118z\"/><path d=\"M13.9 13.076a.083.083 0 00-.029-.076.079.079 0 00-.082-.012 4.679 4.679 0 01-3.578 0 .079.079 0 00-.082.012.083.083 0 00-.028.079l.965 4.558a.249.249 0 00.244.2h1.38a.249.249 0 00.244-.2zM11.02 1.023v1.871a.98.98 0 001.96 0V1.023a.98.98 0 00-1.96 0zM3.469 9.751H1.225a.98.98 0 100 1.959h2.244a.98.98 0 000-1.959zM3.07 2.78a.978.978 0 000 1.385l1.87 1.871A.98.98 0 006.325 4.65l-1.87-1.87a.978.978 0 00-1.385 0zM22.776 9.751h-2.245a.98.98 0 000 1.959h2.245a.98.98 0 000-1.959zM19.545 2.78l-1.87 1.87a.98.98 0 001.385 1.386l1.87-1.871a.979.979 0 00-1.385-1.385zM9.551 18.567a.98.98 0 100 1.959h4.9a.98.98 0 100-1.959h-4.9zM14.449 21.016h-4.9a.98.98 0 100 1.96H10.8a.25.25 0 01.245.2.98.98 0 001.918 0 .25.25 0 01.245-.2h1.245a.98.98 0 100-1.96z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});