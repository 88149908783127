define("ember-svg-jar/inlined/e-learning-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-learning-laptop</title><path d=\"M3.5 16.5a1 1 0 001-1V6a1 1 0 011-1h13a1 1 0 011 1v9.5a1 1 0 002 0V6a3 3 0 00-3-3h-13a3 3 0 00-3 3v9.5a1 1 0 001 1zM23.5 18H.5a.5.5 0 00-.5.5A2.5 2.5 0 002.5 21h19a2.5 2.5 0 002.5-2.5.5.5 0 00-.5-.5z\"/><path d=\"M14.25 13.251A1.75 1.75 0 1016 11.5a1.634 1.634 0 00-.222.015.5.5 0 01-.417-.143l-.921-.921a.5.5 0 01-.112-.537 2.5 2.5 0 10-4.656 0 .5.5 0 01-.112.537l-.921.921a.5.5 0 01-.417.143A1.634 1.634 0 008 11.5a1.752 1.752 0 101.69 1.3.5.5 0 01.13-.484l.834-.835a.5.5 0 01.519-.118 2.513 2.513 0 001.654 0 .5.5 0 01.519.118l.834.835a.5.5 0 01.13.484 1.743 1.743 0 00-.06.451z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});