define("ember-svg-jar/inlined/footwear-flip-flops", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>footwear-flip-flops</title><path d=\"M23.8 9.267a.25.25 0 01-.426.164l-4.151-4.137a1 1 0 00-1.386-.024l-4.41 4.1a.25.25 0 01-.42-.183V5.7A5.635 5.635 0 0116.6.343 5.5 5.5 0 0123.992 5.8z\"/><path d=\"M18.142 7.716a.5.5 0 01.692.012l4.477 4.456a1 1 0 01.293.763l-.337 6.189a5.134 5.134 0 01-10.26-.278v-5.935a1 1 0 01.319-.732zM.2 9.267a.25.25 0 00.426.164l4.151-4.137a1 1 0 011.386-.024l4.41 4.1a.25.25 0 00.42-.183V5.7A5.635 5.635 0 007.4.343 5.5 5.5 0 00.008 5.8z\"/><path d=\"M5.858 7.716a.5.5 0 00-.692.012L.689 12.184a1 1 0 00-.293.763l.337 6.189a5.134 5.134 0 0010.26-.278v-5.935a1 1 0 00-.319-.732z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});