define("ember-svg-jar/inlined/video-player-cloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-player-cloud</title><path d=\"M24 9a6.009 6.009 0 00-5.642-5.99.251.251 0 01-.178-.09A8 8 0 004.3 5.838a.25.25 0 01-.217.182 4.5 4.5 0 00-.373 8.91.251.251 0 00.29-.247v-1.537a.251.251 0 00-.182-.241A2.5 2.5 0 014.9 8.032a1 1 0 001.155-.851 6 6 0 0110.873-2.6 1 1 0 00.873.427 4 4 0 012.315 7.388.251.251 0 00-.117.212v1.686a.25.25 0 00.348.23A6.007 6.007 0 0024 9z\"/><path d=\"M18.5 12.5A1.5 1.5 0 0017 11H7a1.5 1.5 0 00-1.5 1.5v10A1.5 1.5 0 007 24h10a1.5 1.5 0 001.5-1.5zm-4.23 4.27a.846.846 0 010 1.46l-3.7 2.157a.838.838 0 01-1.255-.732v-4.311a.838.838 0 011.255-.732z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});