define("ember-svg-jar/inlined/multiple-actions-refresh.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-refresh</title><path d=\"M14.471 8.4l-.486-.172a1.8 1.8 0 01-.055-1.172 4.647 4.647 0 001.216-3.569A3.22 3.22 0 0012 0a2.994 2.994 0 00-1.342.314.25.25 0 00-.1.363 5 5 0 01.842 2.808 6.263 6.263 0 01-.922 3.624.248.248 0 00-.028.215.246.246 0 00.151.157c1.511.557 3.11 1.172 3.83 2.411a.249.249 0 00.3.109 7.864 7.864 0 011.558-.4.25.25 0 00.061-.477 40.604 40.604 0 00-1.879-.724z\"/><path d=\"M13.207 10.763a.251.251 0 00.11-.159.255.255 0 00-.037-.19c-.5-.759-1.622-1.211-3.809-2.016l-.486-.172a1.8 1.8 0 01-.055-1.172 4.647 4.647 0 001.216-3.569A3.22 3.22 0 007 0a3.22 3.22 0 00-3.146 3.485 4.605 4.605 0 001.193 3.538 1.877 1.877 0 010 1.185l-.515.19c-2.01.739-3.46 1.272-3.914 2.179A7.542 7.542 0 000 13.5a.5.5 0 00.5.5h9.663a.252.252 0 00.223-.136 8.031 8.031 0 012.821-3.101zM23.392 19.871a1 1 0 00-1.893-.648 4.091 4.091 0 01-6.918 1.419.25.25 0 01.008-.345l1.721-1.721a.5.5 0 00-.353-.853H11.5a.5.5 0 00-.5.5v4.457a.5.5 0 00.854.353l.969-.97a.251.251 0 01.359.005 6.085 6.085 0 0010.21-2.2zM24 12.234a.5.5 0 00-.854-.353l-1.025 1.026a.251.251 0 01-.358 0 6.1 6.1 0 00-10.2 2.226 1 1 0 001.893.648 4.092 4.092 0 016.9-1.445.249.249 0 01-.007.346l-1.659 1.66a.5.5 0 00.353.853H23.5a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});