define("ember-svg-jar/inlined/task-checklist-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-checklist-remove</title><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.5 7.25h-5a.75.75 0 110-1.5h5a.75.75 0 010 1.5zM5 10.249h6.5a.75.75 0 000-1.5H5a.75.75 0 000 1.5zM5 13.749h3a.75.75 0 000-1.5H5a.75.75 0 000 1.5zM5 17.25h2.75a.75.75 0 000-1.5H5a.75.75 0 000 1.5zM5.25 6.249h8.5A.25.25 0 0014 6V3a.251.251 0 00-.25-.25H12V2.5A2.509 2.509 0 008.821.09 2.559 2.559 0 007 2.587v.162H5.25A.251.251 0 005 3v3a.25.25 0 00.25.249zM9.5 1.5a.75.75 0 11-.75.75.75.75 0 01.75-.75z\"/><path d=\"M10.96 22.105a.249.249 0 00-.2-.106H2.5a.5.5 0 01-.5-.5V5.249a.5.5 0 01.5-.5h.75A.25.25 0 003.5 4.5V3a.251.251 0 00-.25-.25H2a2 2 0 00-2 2V22a2 2 0 002 2h10.125a.25.25 0 00.234-.163.247.247 0 00-.072-.276 8.044 8.044 0 01-1.327-1.456zM15.75 4.749h.75a.5.5 0 01.5.5v4.008a.25.25 0 00.265.25c.078 0 .156-.008.235-.008a7.926 7.926 0 011.211.1.249.249 0 00.2-.058.245.245 0 00.089-.193v-4.6a2 2 0 00-2-2h-1.25A.251.251 0 0015.5 3v1.5a.25.25 0 00.25.249z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});