define("ember-svg-jar/inlined/professions-woman-office-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-woman-office-1</title><path d=\"M.707 23.509A.491.491 0 001.2 24h7.736a.245.245 0 00.14-.447l-4.3-2.976a.616.616 0 01-.212-.754l1.3-2.921a.245.245 0 00-.3-.333 12.855 12.855 0 00-3.909 1.949 2.421 2.421 0 00-.949 1.941zM12 15.711a6.968 6.968 0 003.73-1.1.245.245 0 01.271 0 3.393 3.393 0 001.052.452 5.26 5.26 0 001.294.158 3.73 3.73 0 002.868-1.1.492.492 0 00-.227-.782c-2.525-.811-2.114-4.412-2.114-4.5V6.874a6.874 6.874 0 00-13.748 0v1.964c0 .087.384 3.639-2.114 4.441a.492.492 0 00-.227.782 3.73 3.73 0 002.868 1.1A5.26 5.26 0 006.947 15a3.4 3.4 0 001.007-.427.245.245 0 01.27 0A7.01 7.01 0 0012 15.711zm0-1.964a4.915 4.915 0 01-4.91-4.909v-.992a.245.245 0 01.239-.246 6.867 6.867 0 004.609-1.662.092.092 0 01.124 0A6.872 6.872 0 0016.671 7.6a.245.245 0 01.239.245v.99A4.915 4.915 0 0112 13.747z\"/><circle cx=\"13.964\" cy=\"9.329\" r=\".982\"/><circle cx=\"10.036\" cy=\"9.329\" r=\".982\"/><path d=\"M12.928 12.779a2.345 2.345 0 00.428-.178.627.627 0 00-.3-1.179h-1.963a.627.627 0 00-.3 1.18 2.382 2.382 0 00.426.177 3.008 3.008 0 001.706 0zM23.293 23.509A.491.491 0 0122.8 24h-7.736a.245.245 0 01-.14-.447l4.3-2.976a.616.616 0 00.212-.754l-1.3-2.921a.245.245 0 01.3-.333 12.855 12.855 0 013.909 1.949 2.421 2.421 0 01.949 1.941z\"/><path d=\"M16.523 16.415a.247.247 0 00-.2-.14.244.244 0 00-.221.1l-3.9 5.386a.247.247 0 01-.4 0L7.9 16.376a.244.244 0 00-.221-.1.249.249 0 00-.2.14c-.374.8-1.224 2.656-1.5 3.253a.245.245 0 00.085.3l5.8 3.933a.244.244 0 00.276 0l5.8-3.933a.245.245 0 00.085-.3c-.278-.598-1.125-2.455-1.502-3.254z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});