define("ember-svg-jar/inlined/surveillance-camera-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>surveillance-camera-1</title><path d=\"M23.5 3.75H.5a.5.5 0 00-.5.5v2a2.5 2.5 0 002.5 2.5h19a2.5 2.5 0 002.5-2.5v-2a.5.5 0 00-.5-.5zM20.5 10.25h-17a.5.5 0 00-.5.5v.5a9 9 0 0018 0v-.5a.5.5 0 00-.5-.5zm-9 7a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});