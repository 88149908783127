define("ember-svg-jar/inlined/single-neutral-actions-player.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-player</title><path d=\"M10.5 12a1.5 1.5 0 01.776-1.312.249.249 0 00-.034-.453l-.372-.135a.6.6 0 01-.138-.31 2.632 2.632 0 01.058-1.3 5.618 5.618 0 001.466-4.314C12.256 1.756 10.677 0 8.5 0S4.744 1.756 4.744 4.174A5.605 5.605 0 006.2 8.471a2.505 2.505 0 01.073 1.317.614.614 0 01-.135.308l-.632.233c-2.446.9-4.214 1.549-4.753 2.626A9.167 9.167 0 000 16.5a.5.5 0 00.5.5h9.75a.25.25 0 00.25-.25zM24 13.875A1.877 1.877 0 0022.125 12h-8.25A1.877 1.877 0 0012 13.875v8.25A1.877 1.877 0 0013.875 24h8.25A1.877 1.877 0 0024 22.125zm-8.5 2.3a.93.93 0 011.408-.8l3.033 1.82a.928.928 0 010 1.595l-3.033 1.819a.93.93 0 01-1.408-.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});