define("ember-svg-jar/inlined/social-video-vimeo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-video-vimeo</title><path d=\"M.7 7.12a.5.5 0 000 .63l.39.52a.5.5 0 00.68.11c.6-.4 1.72-1 2.19-.49.64.76 3.09 9.93 3.91 11.62.71 1.48 2.68 3.44 4.84 2S22 14 23.31 6.78C24.52 0 15.79.91 13.89 6.31a.5.5 0 00.62.64c2-.63 3 1.35 1.8 3.56C15 13 13.74 14.67 13.1 14.67S12 13 11.23 10.05s-.75-8.5-3.91-7.88C4.81 2.66 1.63 6.06.7 7.12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});