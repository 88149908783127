define("ember-svg-jar/inlined/medical-specialty-feet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-specialty-feet</title><path d=\"M11 5H8.568a4.568 4.568 0 00-1.444 8.9l.436.147A3.561 3.561 0 0110 17.432v1.818A4.636 4.636 0 0014.5 24a4.636 4.636 0 004.5-4.75V13a8.009 8.009 0 00-8-8z\"/><circle cx=\"5.5\" cy=\"2.5\" r=\"2.5\"/><circle cx=\"11\" cy=\"2\" r=\"2\"/><circle cx=\"16\" cy=\"3\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});