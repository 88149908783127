define("ember-svg-jar/inlined/cleaning-cat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cleaning-cat</title><path d=\"M18.843 4.5a4.1 4.1 0 00-4.1 4 .341.341 0 01-.652.14L13.5 7.321a.5.5 0 01.084-.539 3.466 3.466 0 00.9-2.326V1a.25.25 0 00-.413-.19l-1.9 1.632a.5.5 0 01-.441.108 2.976 2.976 0 00-1.484 0 .5.5 0 01-.446-.108L7.892.81A.249.249 0 007.48 1v3.456a3.466 3.466 0 00.9 2.326.5.5 0 01.084.539L6.693 11.27a2.476 2.476 0 002.287 3.48h4a2.468 2.468 0 002-1 .978.978 0 00.219-.084A2.99 2.99 0 0016.743 11V8.5a2.08 2.08 0 012.1-2 1.4 1.4 0 011.4 1.4.921.921 0 01-.92.92.53.53 0 01-.323-.111 1 1 0 00-1.222 1.583 2.515 2.515 0 001.549.528 2.923 2.923 0 002.92-2.92 3.4 3.4 0 00-3.404-3.4zM2.257 19h6.56a.5.5 0 00.433-.25 2 2 0 013.459 0 .5.5 0 00.434.25H19.7a.5.5 0 00.454-.709 3.5 3.5 0 00-3.177-2.041h-12A3.5 3.5 0 001.8 18.291a.5.5 0 00.457.709zM19.7 20.5h-6.557a.5.5 0 00-.434.25 2 2 0 01-3.459 0 .5.5 0 00-.433-.25h-6.56a.5.5 0 00-.454.709 3.5 3.5 0 003.176 2.041h12a3.5 3.5 0 003.177-2.041.5.5 0 00-.456-.709z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});