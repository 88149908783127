define("ember-svg-jar/inlined/currency-pound-bill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-pound-bill</title><path d=\"M21.143 7.488A.251.251 0 0121 7.262V6a1 1 0 00-1-1H4a1 1 0 00-1 1v1.262a.25.25 0 01-.143.226 4.993 4.993 0 000 9.026.25.25 0 01.143.226V18a1 1 0 001 1h16a1 1 0 001-1v-1.26a.25.25 0 01.143-.226 4.993 4.993 0 000-9.026zM13.708 11a1 1 0 010 2h-1a.25.25 0 00-.25.25v1a3.428 3.428 0 01-.142.931.245.245 0 00.041.219.249.249 0 00.2.1h1.9a1 1 0 010 2h-4.5a1 1 0 01-.1-2 .249.249 0 00.183-.107 2.539 2.539 0 00.412-1.147v-1a.25.25 0 00-.25-.25h-1a1 1 0 110-2h1a.25.25 0 00.25-.25V9a2.5 2.5 0 015 0 1 1 0 01-2 0 .5.5 0 00-1 0v1.75a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});