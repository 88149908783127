define("ember-svg-jar/inlined/pin-remove-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin-remove-1</title><path d=\"M12 0a7.258 7.258 0 00-7.25 7.25c0 2.36 1.749 5.085 3.44 7.721a27.253 27.253 0 012.529 4.49.514.514 0 01.031.174v3.115a1.25 1.25 0 002.5 0v-3.115a.514.514 0 01.031-.174 27.253 27.253 0 012.529-4.49c1.69-2.636 3.44-5.361 3.44-7.721A7.258 7.258 0 0012 0zm2.75 8.25h-5.5a.75.75 0 010-1.5h5.5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});