define("ember-svg-jar/inlined/e-commerce-shopping-bag-browser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-shopping-bag-browser</title><path d=\"M23.144 16.123A1.907 1.907 0 0021.25 14.5a.25.25 0 01-.25-.25 2.75 2.75 0 00-5.5 0 .25.25 0 01-.25.25 1.907 1.907 0 00-1.894 1.625l-.831 5.406a2.223 2.223 0 00-.025.325A2.146 2.146 0 0014.644 24h7.212A2.146 2.146 0 0024 21.856a2.223 2.223 0 00-.025-.325zM19.25 14.5h-2a.25.25 0 01-.25-.25 1.25 1.25 0 012.5 0 .25.25 0 01-.25.25z\"/><path d=\"M21 0H3a3 3 0 00-3 3v14.5a3 3 0 003 3h7.5a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5H3a1 1 0 01-1-1V6a1 1 0 011-1h18a1 1 0 011 1v4a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V3a3 3 0 00-3-3zM3.5 3.5a1 1 0 111-1 1 1 0 01-1 1zm3.5 0a1 1 0 111-1 1 1 0 01-1 1zm3.5 0a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M13.5 13.158a2.08 2.08 0 00-1.315-1.941l-2.063-.826a.592.592 0 01-.062-1.07c.017-.009.034-.016.052-.024a.513.513 0 01.111-.035.579.579 0 01.119-.012h2.033a.75.75 0 000-1.5h-.5a.25.25 0 01-.25-.25v-.25a.75.75 0 00-1.5 0v.3a.25.25 0 01-.2.245 2.091 2.091 0 00-.36 3.991l2.063.826a.592.592 0 01-.1 1.129.579.579 0 01-.119.012H9.375a.75.75 0 000 1.5h.5a.25.25 0 01.25.25v.25a.75.75 0 001.5 0v-.3a.25.25 0 01.2-.245 2.094 2.094 0 001.675-2.05z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});