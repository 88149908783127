define("ember-svg-jar/inlined/shape-triangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shape-triangle</title><path d=\"M13.385 1.077a1.5 1.5 0 00-2.771 0L3.249 18.833A2.239 2.239 0 003 19.846c0 2.626 4.527 4 9 4s9-1.374 9-4a2.239 2.239 0 00-.249-1.013zM12 21.846c-4.547 0-7-1.419-7-2 0-.01 5.493-13.255 6.769-16.332a.25.25 0 01.462 0C13.508 6.594 19 19.836 19 19.846c0 .581-2.453 2-7 2z\"/><path d=\"M12 16.1a20.15 20.15 0 00-4.149.415.253.253 0 00-.179.148l-.478 1.155a.25.25 0 00.3.337A17.218 17.218 0 0112 17.6a17.218 17.218 0 014.509.555.25.25 0 00.3-.337l-.478-1.155a.251.251 0 00-.179-.148A20.15 20.15 0 0012 16.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});