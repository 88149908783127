define("ember-svg-jar/inlined/ankle-tracker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ankle-tracker</title><path d=\"M7.674 10.1a1.458 1.458 0 001.456 1.455h5.74a1.458 1.458 0 001.456-1.455V5.315a1.454 1.454 0 00-1.206-1.431.25.25 0 01-.207-.247V1.489a1 1 0 00-2 0v2.119a.25.25 0 01-.25.25h-1.326a.25.25 0 01-.25-.25V1.489a1 1 0 00-2 0v2.148a.25.25 0 01-.207.247 1.454 1.454 0 00-1.206 1.431zm2.978-.6H9.9a.25.25 0 01-.25-.25V6.167a.25.25 0 01.25-.25h.75a1.79 1.79 0 010 3.579zM21.536 19.677c-.641-.827-.031-.354-3.416-3.01-.892-.7-2.068-1.623-3.626-2.853a.249.249 0 01-.1-.193v-.183a.5.5 0 00-.5-.493h-3.807a.5.5 0 00-.5.507v.17a.248.248 0 01-.1.2c-1.556 1.229-2.73 2.149-3.62 2.849-3.357 2.64-2.767 2.178-3.411 3.013-1.322 1.771.231 3.095.816 3.594a.976.976 0 001.608-.746v-.894a1.274 1.274 0 01.577-.971l4.416-2.389a.251.251 0 01.366.177 48.69 48.69 0 00.815 3.71.976.976 0 001.889 0c.321-1.233.621-2.6.815-3.712a.251.251 0 01.366-.177l4.417 2.389a1.276 1.276 0 01.576.971v.894a.977.977 0 001.609.746c.639-.546 2.138-1.822.816-3.594zM18.258 8.2a1 1 0 000 2 2.435 2.435 0 100-4.87 1 1 0 000 2 .435.435 0 010 .87z\"/><path d=\"M19.652 2.88a1 1 0 000 2A2.623 2.623 0 0122 7.706a2.623 2.623 0 01-2.348 2.826 1 1 0 000 2A4.611 4.611 0 0024 7.706a4.611 4.611 0 00-4.348-4.826zM3.307 7.768A2.437 2.437 0 005.742 10.2a1 1 0 100-2 .435.435 0 010-.87 1 1 0 000-2 2.438 2.438 0 00-2.435 2.438z\"/><path d=\"M5.348 11.532a1 1 0 00-1-1A2.623 2.623 0 012 7.706 2.623 2.623 0 014.348 4.88a1 1 0 000-2A4.611 4.611 0 000 7.706a4.611 4.611 0 004.348 4.826 1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});