define("ember-svg-jar/inlined/keyboard-arrow-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-arrow-down</title><path d=\"M17.7 17.838a1 1 0 00-.924-.617H13.75a.249.249 0 01-.25-.25V1.5a1.5 1.5 0 00-3 0v15.471a.25.25 0 01-.25.25H7.222a1 1 0 00-.707 1.707l4.777 4.778a1 1 0 001.415 0l4.778-4.778a1 1 0 00.215-1.09z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});