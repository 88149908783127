define("ember-svg-jar/inlined/peopleman-moustache-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>peopleman-moustache-2</title><path d=\"M18.5 17.815a.25.25 0 00.165.235 11.153 11.153 0 012.959 1.559.974.974 0 01.377.785V23a1 1 0 002 0v-2.606a2.956 2.956 0 00-1.164-2.374 13.257 13.257 0 00-3.726-1.93.255.255 0 00-.22.027c-.088.059-.175.118-.268.172a.251.251 0 00-.123.216zM0 23a1 1 0 002 0v-2.606a.974.974 0 01.377-.785 11.115 11.115 0 012.958-1.559.25.25 0 00.165-.235V16.5a.251.251 0 00-.124-.216c-.092-.054-.179-.113-.267-.171a.248.248 0 00-.22-.027 13.271 13.271 0 00-3.726 1.93A2.958 2.958 0 000 20.394zM5.383 13.306a.5.5 0 00-.383.828 4.307 4.307 0 002 1.215V18.5A1.5 1.5 0 008.47 20a.031.031 0 01.03.03A1.5 1.5 0 0010 21.5a.49.49 0 01.5.5 1.5 1.5 0 003 0 .49.49 0 01.5-.5 1.5 1.5 0 001.5-1.47.031.031 0 01.03-.03A1.5 1.5 0 0017 18.5v-3.151a4.307 4.307 0 002-1.215.5.5 0 00-.382-.828 5.573 5.573 0 01-.627-.028.25.25 0 01-.2-.143.247.247 0 01.02-.245A6.945 6.945 0 0019 9V7A7 7 0 005 7v2a6.934 6.934 0 001.191 3.89.247.247 0 01.02.245.25.25 0 01-.2.143 5.573 5.573 0 01-.628.028zM7 8.049a.249.249 0 01.243-.249 6.99 6.99 0 004.715-1.712.062.062 0 01.084 0A6.99 6.99 0 0016.757 7.8a.251.251 0 01.243.25V9a4.962 4.962 0 01-.38 1.9 4.748 4.748 0 01-.642 1.093.251.251 0 01-.192.1.255.255 0 01-.2-.088A2.48 2.48 0 0013.5 10.75a1.988 1.988 0 00-1.333.51.251.251 0 01-.334 0 1.992 1.992 0 00-1.333-.51A2.48 2.48 0 008.411 12a.25.25 0 01-.389-.01 4.748 4.748 0 01-.642-1.09A4.962 4.962 0 017 9zm6.727 4.766a.75.75 0 01-.368.844 2.32 2.32 0 01-.432.18 2.907 2.907 0 01-.845.123 2.479 2.479 0 01-.853-.123 2.387 2.387 0 01-.432-.179.75.75 0 01.357-1.41H13a.751.751 0 01.727.565z\"/><path d=\"M14 10.25a.988.988 0 00.943-.719A.975.975 0 0015 9.25a1 1 0 00-2 0c0 .02.01.036.011.055a.994.994 0 00.989.945zM10 10.25a.994.994 0 00.989-.945c0-.019.011-.035.011-.055a1 1 0 00-2 0 .975.975 0 00.057.281.988.988 0 00.943.719z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});