define("ember-svg-jar/inlined/road-sign-right-reverse-turn-ahead-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-right-reverse-turn-ahead-alternate</title><path d=\"M23.707 12.707a1 1 0 000-1.414l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0zm-11.53 8.7a.25.25 0 01-.354 0l-9.232-9.23a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/><path d=\"M10.47 9.47a.75.75 0 00.53 1.28h.75A.25.25 0 0112 11v.75a.25.25 0 01-.25.25H10.5A1.5 1.5 0 009 13.5V17a1 1 0 002 0v-2.75a.25.25 0 01.25-.25h1.25a1.5 1.5 0 001.5-1.5V11a.25.25 0 01.25-.25H15a.75.75 0 00.53-1.28l-2-2a.749.749 0 00-1.06 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});