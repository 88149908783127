define("ember-svg-jar/inlined/messages-bubble-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-alternate</title><path d=\"M12 .836C5.383.836 0 5.31 0 10.811a9.01 9.01 0 003.057 6.658L.931 21.713a1 1 0 001.316 1.355l5.981-2.784a14.243 14.243 0 003.772.5c6.617 0 12-4.475 12-9.975S18.617.836 12 .836zm0 17.95a12.189 12.189 0 01-3.562-.524 1 1 0 00-.714.05l-3.07 1.429a.25.25 0 01-.329-.339l.869-1.735a1 1 0 00-.269-1.228A7.214 7.214 0 012 10.811c0-4.4 4.486-7.975 10-7.975s10 3.577 10 7.975-4.486 7.975-10 7.975z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});