define("ember-svg-jar/inlined/plane-info-center", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-info-center</title><circle cx=\"8\" cy=\"3.275\" r=\"3\"/><path d=\"M12 9.776a2 2 0 00-2-2h-.533a.25.25 0 00-.208.111L8.208 9.464a.25.25 0 01-.416 0L6.741 7.887a.25.25 0 00-.208-.111H6a2 2 0 00-2 2v1a.5.5 0 00.5.5h7a.5.5 0 00.5-.5zM.5 23.225a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-8.5a2.5 2.5 0 00-2.5-2.5H3a2.5 2.5 0 00-2.5 2.5zM12 14.276a4 4 0 11-4 4 4 4 0 014-4zM15.941 3.2a.518.518 0 00-.622-.079l-.714.414a.517.517 0 00-.118.8l1.441 1.556a.518.518 0 00.547.137l5.406-1.844a1.3 1.3 0 00.77-.766l.037-.113a1.243 1.243 0 00-1.741-1.495l-.01.005-1.13.534a.25.25 0 01-.248-.019l-1.848-1.267A.514.514 0 0017.254 1l-.747.255a.517.517 0 00-.335.363.522.522 0 00.128.482l.963 1.067a.25.25 0 01-.107.4l-.478.158a.249.249 0 01-.254-.059z\"/><path d=\"M12 18.025a.75.75 0 00-.75.75v1.5a.75.75 0 001.5 0v-1.5a.75.75 0 00-.75-.75z\"/><circle cx=\"12\" cy=\"16.276\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});