define("ember-svg-jar/inlined/hotel-bunk-bed-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hotel-bunk-bed-1</title><path d=\"M13.5 6.5H3a.5.5 0 01-.5-.5V1.25a1.25 1.25 0 00-2.5 0v21.5a1.25 1.25 0 002.5 0V22a.5.5 0 01.5-.5h10.5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5H3a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5h10.5A.5.5 0 0014 9V7a.5.5 0 00-.5-.5z\"/><path d=\"M5.5 5h3a1.5 1.5 0 000-3h-3a1.5 1.5 0 000 3zM5.5 14a1.5 1.5 0 000 3h3a1.5 1.5 0 000-3zM23 0a1 1 0 00-1 1v2a.5.5 0 01-.5.5H18a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v22a1 1 0 002 0v-2a.5.5 0 01.5-.5h3.5a.5.5 0 01.5.5v2a1 1 0 002 0V1a1 1 0 00-1-1zm-5 13.5a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h3.5a.5.5 0 01.5.5v2a.5.5 0 01-.5.5zm0-5a.5.5 0 01-.5-.5V6a.5.5 0 01.5-.5h3.5a.5.5 0 01.5.5v2a.5.5 0 01-.5.5zm-.5 7.5a.5.5 0 01.5-.5h3.5a.5.5 0 01.5.5v2a.5.5 0 01-.5.5H18a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});