define("ember-svg-jar/inlined/file-css-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-css-1</title><path d=\"M20 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h18a1 1 0 001-1zM6 16.876a.625.625 0 010 1.25 3.625 3.625 0 010-7.25.625.625 0 010 1.25 2.375 2.375 0 000 4.75zm2.927-3.508l1.84 1.226A1.928 1.928 0 019.7 18.126H8a.625.625 0 010-1.25h1.7a.678.678 0 00.376-1.242l-1.84-1.226A1.928 1.928 0 019.3 10.876H11a.625.625 0 010 1.25H9.3a.678.678 0 00-.376 1.242zm5 0l1.84 1.226a1.928 1.928 0 01-1.07 3.532H13a.625.625 0 010-1.25h1.7a.678.678 0 00.376-1.242l-1.84-1.226a1.928 1.928 0 011.07-3.532H16a.625.625 0 010 1.25h-1.7a.678.678 0 00-.376 1.242z\"/><path d=\"M23.707 5.708L18.293.294A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.751V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.415a1 1 0 00-.293-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});