define("ember-svg-jar/inlined/agriculture-machine-tractor-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>agriculture-machine-tractor-2</title><path d=\"M4.5 14.5A3.5 3.5 0 108 18a3.5 3.5 0 00-3.5-3.5zm0 4.5a1 1 0 111-1 1 1 0 01-1 1zM16.5 15.5a3 3 0 103 3 3 3 0 00-3-3zm0 4a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M23.7 11.319l-.915-1.219a1.508 1.508 0 00-1.2-.6H15.12a1.5 1.5 0 00-1.248.668l-.205.308a.5.5 0 01-.694.139l-.1-.068a.5.5 0 01-.152-.673l.173-.288a.75.75 0 00-1.286-.772l-1.5 2.5a.75.75 0 001.286.772l.04-.066a.5.5 0 01.706-.159l.058.039a.5.5 0 01.187.6 2.484 2.484 0 00-.126 1.411l.1.491a.5.5 0 01-.491.6h-1.89a.5.5 0 01-.421-.23 6.005 6.005 0 00-.841-1.034.5.5 0 01-.136-.471l1.79-7.6a.5.5 0 01.218-.307.888.888 0 00.386-.968A2.5 2.5 0 008.548 2.5h-6.1A2.5 2.5 0 00.027 4.393a.889.889 0 00.349.944.5.5 0 01.206.342l.9 6.782a.5.5 0 01-.225.487 6.1 6.1 0 00-1.043.852.75.75 0 001.073 1.049A4.5 4.5 0 019 18v1a.5.5 0 00.5.5h2.527a.5.5 0 00.5-.555 4.091 4.091 0 01-.027-.445 4 4 0 017.975-.444.5.5 0 00.5.444h.325a1.5 1.5 0 001.456-1.136l.763-3.053a.249.249 0 00-.238-.311H20.75a.75.75 0 010-1.5h3a.251.251 0 00.25-.244 1.5 1.5 0 00-.3-.937zM8.092 6.615l-.824 3.5a.5.5 0 01-.487.385h-.56a.5.5 0 01-.474-.342l-.772-2.316A.5.5 0 004.5 7.5H3.28a.5.5 0 01-.495-.434l-.067-.5A.5.5 0 013.214 6h4.392a.5.5 0 01.486.615z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});