define("ember-svg-jar/inlined/office-file-txt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-file-txt</title><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.425A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.248A.25.25 0 0014.75 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.248A.25.25 0 0021.75 7zM8.479 13.123h-.624a.251.251 0 00-.251.251V18.5a.625.625 0 01-1.25 0v-5.126a.251.251 0 00-.252-.251h-.623a.625.625 0 010-1.25h3a.625.625 0 010 1.25zm5.558 5.1a.624.624 0 11-1.117.558s-.862-1.71-.881-1.762a.066.066 0 00-.122 0l-.88 1.759a.624.624 0 11-1.117-.558l1.3-2.609a.252.252 0 000-.224l-1.3-2.609a.624.624 0 011.117-.558l.875 1.75a.072.072 0 00.133 0l.875-1.75a.624.624 0 011.117.558l-1.3 2.609a.252.252 0 000 .224zm4.442-5.1h-.624a.251.251 0 00-.251.251V18.5a.625.625 0 11-1.25 0v-5.126a.251.251 0 00-.252-.251h-.623a.625.625 0 010-1.25h3a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});