define("ember-svg-jar/inlined/single-man-actions-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-image</title><path d=\"M9 11a2 2 0 011.776-1.988.253.253 0 00.218-.189.252.252 0 01.068-.117 3.594 3.594 0 00.792-2.244.251.251 0 01.088-.176 1.873 1.873 0 00.538-1.4 1.58 1.58 0 00-.317-1.015.251.251 0 01-.034-.264 2.531 2.531 0 00.157-1.727C11.944.493 10.242 0 8.888 0a3.752 3.752 0 00-3.163 1.324.251.251 0 01-.212.118 1.072 1.072 0 00-.9.375c-.372.493-.106 1.17.176 1.732a.251.251 0 01-.046.288 1.3 1.3 0 00-.162.2A1.763 1.763 0 004.32 5a1.521 1.521 0 00.638 1.331.249.249 0 01.117.194A3.167 3.167 0 005.92 8.7a.25.25 0 01.08.183v1.123a.248.248 0 01-.151.229l-3.937 1.687C.64 12.468.457 13.626.205 15.23c-.055.359-.117.751-.2 1.179A.5.5 0 00.5 17h8.249A.25.25 0 009 16.75zM24 22v-9.5a2 2 0 00-2-2h-9.5a2 2 0 00-2 2V22a2 2 0 002 2H22a2 2 0 002-2zm-11.5-9.25a.25.25 0 01.25-.25h9a.25.25 0 01.25.25v4.4a.25.25 0 01-.458.138l-.267-.4a1.251 1.251 0 00-2.064-.024l-1.537 2.2a.251.251 0 01-.361.052l-.612-.49a1.25 1.25 0 00-1.821.283l-2 3.005a.2.2 0 01-.171.092.206.206 0 01-.206-.206z\"/><circle cx=\"15.499\" cy=\"15\" r=\"1.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});