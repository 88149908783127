define("ember-svg-jar/inlined/cocktail-shaker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cocktail-shaker</title><path d=\"M10 3h4a.5.5 0 00.5-.5v-1A1.5 1.5 0 0013 0h-2a1.5 1.5 0 00-1.5 1.5v1a.5.5 0 00.5.5zM18.244 10.178a.5.5 0 00-.383-.178H6.139a.5.5 0 00-.493.585l2.175 12.579a1 1 0 00.986.836h6.386a1 1 0 00.986-.836l2.175-12.579a.5.5 0 00-.11-.407zM6.769 8.5h10.462a.5.5 0 00.447-.724l-1.085-2.171A2 2 0 0014.8 4.5H9.2a2 2 0 00-1.792 1.105L6.322 7.776a.5.5 0 00.447.724z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});