define("ember-svg-jar/inlined/rotate-front-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rotate-front-alternate</title><path d=\"M23.9 6.8a.5.5 0 00-.4-.8h-.25a.25.25 0 01-.25-.25V4a3 3 0 00-3-3h-1.75A.25.25 0 0118 .75V.5a.5.5 0 00-.8-.4l-2 1.5a.5.5 0 000 .8l2 1.5a.5.5 0 00.8-.4v-.25a.25.25 0 01.25-.25H20a1 1 0 011 1v1.75a.25.25 0 01-.25.25h-.25a.5.5 0 00-.4.8l1.5 2a.5.5 0 00.8 0z\"/><rect y=\"4\" width=\"13\" height=\"13\" rx=\"2\" ry=\"2\"/><path d=\"M20 12a2 2 0 00-2-2h-2.5a1 1 0 000 2h2a.5.5 0 01.5.5v9a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5v-2a1 1 0 00-2 0V22a2 2 0 002 2h10a2 2 0 002-2z\"/><path d=\"M9.75 19.25a.75.75 0 00.75.75h4.75a.75.75 0 00.75-.75V14.5a.75.75 0 00-1.5 0v3.75a.25.25 0 01-.25.25H10.5a.75.75 0 00-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});