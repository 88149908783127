define("ember-svg-jar/inlined/list-quill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>list-quill</title><path d=\"M8.49 6.757a.751.751 0 00-1.055.117L6.06 8.593a.138.138 0 01-.227 0l-.781-.781a.75.75 0 10-1.061 1.061l1.5 1.5a.75.75 0 00.53.22h.041a.751.751 0 00.545-.281l2-2.5a.75.75 0 00-.117-1.055zM7.435 12.374L6.06 14.093a.138.138 0 01-.227 0l-.781-.781a.75.75 0 10-1.061 1.061l1.5 1.5a.75.75 0 00.53.22h.041a.751.751 0 00.545-.281l2-2.5a.75.75 0 00-1.172-.937zM13.769 9.343a.75.75 0 00-.75-.75h-2.5a.75.75 0 000 1.5h2.5a.75.75 0 00.75-.75zM10.519 14.093a.75.75 0 000 1.5h1.5a.75.75 0 100-1.5z\"/><path d=\"M10.593 21.843H2.519a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25v2.75a2 2 0 002 2h2.75a.25.25 0 01.25.25v4.531a.248.248 0 00.37.219 14.809 14.809 0 011.476-.7.246.246 0 00.154-.231V6.257a1 1 0 00-.293-.707L14.312.136a1 1 0 00-.707-.293H2.019a2 2 0 00-2 2v20a2 2 0 002 2h8.35a.252.252 0 00.248-.256 5.624 5.624 0 01.206-1.412.248.248 0 00-.23-.332z\"/><path d=\"M23.863 12.05a.5.5 0 00-.41-.177A13.48 13.48 0 0019.2 13a.5.5 0 00-.3.416c0 .025-.2 2.127-1.419 2.791-.334.154-.3-.136-.3-.136v-1.418a.5.5 0 00-.169-.374.508.508 0 00-.393-.122c-.787.1-2.684 1.976-3.312 3.8a3.558 3.558 0 00.1 2.828.241.241 0 01-.041.281l-1.38 1.38a1 1 0 001.414 1.417l1.489-1.488a.277.277 0 01.3-.066 2.662 2.662 0 00.629.077 3.644 3.644 0 00.885-.114 8.07 8.07 0 003.553-2.26c2.555-2.556 3.663-7.327 3.709-7.529a.5.5 0 00-.102-.433z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});