define("ember-svg-jar/inlined/tools-vice-grip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-vice-grip</title><path d=\"M22.432 2.982a4.215 4.215 0 00-5.5-.413.467.467 0 01-.61-.045l-.072-.073a1.5 1.5 0 00-2.121 0 .5.5 0 000 .708L18.367 7.4a.5.5 0 010 .706L16.6 9.876a.5.5 0 000 .707l1.768 1.768a.5.5 0 00.707 0l3.358-3.359a4.249 4.249 0 000-6.01zM11.3 15.886a.516.516 0 00-.707 0l-.176.177a1.75 1.75 0 002.474 2.475l.177-.176a.5.5 0 000-.708z\"/><path d=\"M14.477 10.583a.5.5 0 010-.707l1.415-1.414a1 1 0 000-1.414l-2.829-2.829a.5.5 0 00-.707 0 1.5 1.5 0 000 2.122l.707.706a.5.5 0 010 .708l-2.475 2.475a.5.5 0 01-.707 0l-.354-.354a1 1 0 00-1.414 1.415l.354.353a.5.5 0 010 .707L.689 20.129A1.25 1.25 0 002.457 21.9l8.131-8.132a.5.5 0 01.707 0l2.3 2.3a2.249 2.249 0 103.181-3.181zm.884 4.066a1 1 0 110-1.414 1 1 0 010 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});