define("ember-svg-jar/inlined/heavy-equipment-excavator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>heavy-equipment-excavator</title><path d=\"M10.6 10.737a.251.251 0 00-.059-.264l-2.2-2.167a.987.987 0 00-.819-.3L3.359 8.7a.254.254 0 00-.178.125.251.251 0 00-.012.217l.709 1.726a.249.249 0 00.231.155h2.4a1.1 1.1 0 01.794.4l2.763 3.347a.25.25 0 00.443-.159V11.25a1.5 1.5 0 01.091-.513z\"/><path d=\"M.261 13.168A3.251 3.251 0 004.353 15c.122-.011 1.361-.6 1.361-.6a1 1 0 00-.9-1.79L4 13.015a.25.25 0 01-.344-.129L2.137 9.147a.25.25 0 00-.345-.129 3.3 3.3 0 00-1.531 4.15zM11.625 10.92a.5.5 0 00-.125.33v3.934a.25.25 0 00.367.221A3.485 3.485 0 0113.5 15h8a3.475 3.475 0 011.8.5.25.25 0 00.376-.254l-.527-3.291a1.5 1.5 0 00-1.469-1.205H20.5V5a2 2 0 00-2-2h-4.133a2.013 2.013 0 00-1.982 1.736l-.618 5.032zM18.25 5a.25.25 0 01.25.25v5.25a.25.25 0 01-.25.25h-4.306a.254.254 0 01-.188-.084.25.25 0 01-.06-.2l.088-.72.556-4.53A.25.25 0 0114.588 5zM24 18.5a2.5 2.5 0 00-2.5-2.5h-8a2.5 2.5 0 000 5h8a2.5 2.5 0 002.5-2.5zm-10.5-.75a.75.75 0 11-.75.75.75.75 0 01.75-.75zm7.25.75a.75.75 0 11.75.75.75.75 0 01-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});