define("ember-svg-jar/inlined/spaces-menu-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect x=\".5\" y=\".5\" width=\"6.2\" height=\"6.2\" rx=\".5\" stroke=\"#435BC8\"/><rect x=\"9.3\" y=\".5\" width=\"6.2\" height=\"6.2\" rx=\".5\" stroke=\"#435BC8\"/><rect x=\".5\" y=\"9.3\" width=\"6.2\" height=\"6.2\" rx=\".5\" stroke=\"#435BC8\"/><rect x=\"9.3\" y=\"9.3\" width=\"6.2\" height=\"6.2\" rx=\".5\" stroke=\"#435BC8\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});