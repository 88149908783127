define("ember-svg-jar/inlined/gesture-expand-two-fingers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-expand-two-fingers</title><path d=\"M9.5 3.5h5a.751.751 0 00.469-1.336l-2.5-2a.752.752 0 00-.938 0l-2.5 2A.751.751 0 009.5 3.5zM14.5 20.5h-5a.751.751 0 00-.469 1.335l2.5 2a.751.751 0 00.938 0l2.5-2A.75.75 0 0014.5 20.5zM2.165 9.28l-2 2.5a.749.749 0 000 .938l2 2.5a.751.751 0 001.335-.469v-5a.75.75 0 00-1.335-.469zM23.835 11.78l-2-2.5a.75.75 0 00-1.335.469v5a.75.75 0 001.335.469l2-2.5a.749.749 0 000-.938zM11.5 15.574v-4.613a3 3 0 00-6 0v4.613a.429.429 0 00.427.426h5.142a.428.428 0 00.431-.426zM10 11.962a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1a1.5 1.5 0 013 0zM15.5 8a3 3 0 00-3 3v4.572a.429.429 0 00.429.428h5.142a.428.428 0 00.428-.428V11A3 3 0 0015.5 8zm1.5 4a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});