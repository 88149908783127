define("ember-svg-jar/inlined/real-estate-action-house-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-house-remove</title><path d=\"M9.669 15.843a7.985 7.985 0 0112.968-4.461.5.5 0 00.322.118h.541a.5.5 0 00.344-.864L13.03.408a1.5 1.5 0 00-2.06 0L.157 10.636A.5.5 0 00.5 11.5h2a.5.5 0 01.5.5v10a1 1 0 001 1h6.64a.5.5 0 00.4-.8 7.978 7.978 0 01-1.371-6.357z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.507 6.507 0 00-6.5-6.5zm1.414 6.853l1.237 1.236a.75.75 0 11-1.06 1.061l-1.237-1.237a.5.5 0 00-.707 0l-1.238 1.237a.75.75 0 01-1.06-1.061l1.237-1.236a.5.5 0 000-.707l-1.237-1.237a.75.75 0 011.06-1.061l1.238 1.237a.5.5 0 00.707 0l1.237-1.237a.75.75 0 111.06 1.061l-1.237 1.237a.5.5 0 000 .707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});