define("ember-svg-jar/inlined/cursor-move-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-move-left</title><path d=\"M20.571 4.922A10.03 10.03 0 008.6 3.276a1 1 0 00.981 1.743 8 8 0 11.344 14.133 1 1 0 10-.894 1.788 10 10 0 0011.54-16.018z\"/><path d=\"M10.773 16.48a1 1 0 00.105-1.181l-1.93-3.177a.249.249 0 010-.258l1.942-3.237A1 1 0 009.617 7.2l-8.531 3.882a1 1 0 000 1.823l8.523 3.826a1 1 0 001.164-.251z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});