define("ember-svg-jar/inlined/cloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud</title><path d=\"M23.75 13.869a5.707 5.707 0 00-1.739-4.088 5.6 5.6 0 00-3.369-1.554.25.25 0 01-.19-.12 7.532 7.532 0 00-13.806 2.242.25.25 0 01-.226.2 4.507 4.507 0 00-4.17 4.489 4.252 4.252 0 001.214 3.154 5.267 5.267 0 003.493 1.351h13.586a.922.922 0 00.129-.009 5.7 5.7 0 005.078-5.665zm-5.281 3.674H4.974a3.3 3.3 0 01-2.106-.777 2.3 2.3 0 01-.618-1.728 2.507 2.507 0 012.505-2.506 2.522 2.522 0 01.517.054 1 1 0 001.2-.92A5.533 5.533 0 0117 9.632a.96.96 0 00.941.57 3.666 3.666 0 013.805 3.667 3.706 3.706 0 01-3.277 3.674z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});