define("ember-svg-jar/inlined/video-game-mario-flower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-mario-flower</title><path d=\"M23.477 10.96l-3.436-2.291.81-4.049a1.3 1.3 0 00-1.471-1.471l-4.049.81L13.04.523a1.3 1.3 0 00-2.08 0L8.669 3.959l-4.049-.81A1.3 1.3 0 003.149 4.62l.81 4.049L.523 10.96a1.3 1.3 0 000 2.08l3.436 2.291-.81 4.049a1.3 1.3 0 001.471 1.471l4.049-.81 2.291 3.436a1.3 1.3 0 002.08 0l2.291-3.436 4.049.81a1.3 1.3 0 001.471-1.471l-.81-4.049 3.436-2.291a1.3 1.3 0 000-2.08zM12 18a6 6 0 116-6 6 6 0 01-6 6z\"/><path d=\"M10 9.5a1 1 0 00-1 1V13a1 1 0 002 0v-2.5a1 1 0 00-1-1zM14 9.5a1 1 0 00-1 1V13a1 1 0 002 0v-2.5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});