define("ember-svg-jar/inlined/archive-locker-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>archive-locker-1</title><path d=\"M20 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V2a2 2 0 00-2-2zM4 10a.5.5 0 01.5-.5h15a.5.5 0 01.5.5v4.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5zm15.5-8a.5.5 0 01.5.5V7a.5.5 0 01-.5.5h-15A.5.5 0 014 7V2.5a.5.5 0 01.5-.5zm-15 20a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h15a.5.5 0 01.5.5v4a.5.5 0 01-.5.5z\"/><path d=\"M9.912 5.316A1 1 0 0010.86 6h2.28a1 1 0 00.948-.684l.167-.5a1 1 0 00-.949-1.316h-2.612a1 1 0 00-.949 1.316zM14.118 11.416a1 1 0 00-.812-.416h-2.612a1 1 0 00-.949 1.316l.167.5a1 1 0 00.948.684h2.28a1 1 0 00.948-.684l.167-.5a1 1 0 00-.137-.9zM13.306 18.5h-2.612a1 1 0 00-.949 1.316l.167.5a1 1 0 00.948.684h2.28a1 1 0 00.948-.684l.167-.5a1 1 0 00-.949-1.316z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});