define("ember-svg-jar/inlined/transform-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transform-left</title><path d=\"M13.823 9.431A1 1 0 0013 9H1a1 1 0 00-.823 1.57l9 13A1 1 0 0010 24h12a1 1 0 00.822-1.569zM10.656 22a.249.249 0 01-.205-.107l-7.27-10.5A.25.25 0 013.386 11h8.959a.25.25 0 01.206.108l7.27 10.5a.25.25 0 01-.206.392zM23 9h-1.5a1 1 0 000 2h.25a.25.25 0 01.25.25v.25a1 1 0 102 0V10a1 1 0 00-1-1zM23 14a1 1 0 00-1 1v3a1 1 0 002 0v-3a1 1 0 00-1-1zM17 9a1 1 0 000 2h1a1 1 0 000-2zM8.673 6.877A.5.5 0 009.5 6.5V4.75a.25.25 0 01.25-.25h8.75a1 1 0 000-2H9.751a.25.25 0 01-.25-.25V.5a.5.5 0 00-.828-.377l-3.455 3a.5.5 0 000 .754z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});