define("ember-svg-jar/inlined/multiple-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-chat</title><path d=\"M3.5 18.405a2.2 2.2 0 10-2.2-2.2 2.2 2.2 0 002.2 2.2zM3.5 19.2a3.592 3.592 0 00-3.41 2.47.249.249 0 00.237.33h6.346a.252.252 0 00.2-.1.248.248 0 00.034-.225A3.592 3.592 0 003.5 19.2zM18.3 16.206a2.2 2.2 0 102.2-2.2 2.2 2.2 0 00-2.2 2.2zM20.5 19.2a3.592 3.592 0 00-3.41 2.47.249.249 0 00.237.328h6.346a.252.252 0 00.2-.1.248.248 0 00.034-.225A3.592 3.592 0 0020.5 19.2z\"/><circle cx=\"12\" cy=\"18.201\" r=\"2.199\"/><path d=\"M12 21.2a3.592 3.592 0 00-3.41 2.469.251.251 0 00.237.331h6.346a.251.251 0 00.237-.329A3.592 3.592 0 0012 21.2zM12.381 15.426A1 1 0 0013 14.5v-2.25a.25.25 0 01.25-.25h6.25a2 2 0 002-2V2a2 2 0 00-2-2h-13a2 2 0 00-2 2v8a2 2 0 002 2h1.481a.247.247 0 01.176.074l3.134 3.133a1 1 0 001.09.219zM12 10a1 1 0 00-1 1v.483a.25.25 0 01-.426.177L9.205 10.3A1 1 0 008.5 10H6.748a.249.249 0 01-.25-.25v-7.5a.25.25 0 01.25-.25h12.5a.25.25 0 01.25.25v7.5a.249.249 0 01-.25.25z\"/><path d=\"M16.247 7.5a.75.75 0 00-.75-.75h-7a.75.75 0 000 1.5h7a.75.75 0 00.75-.75zM7.747 4.5a.75.75 0 00.75.75h9a.75.75 0 000-1.5h-9a.75.75 0 00-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});