define("ember-svg-jar/inlined/single-woman-actions-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-view</title><path d=\"M.141 17.931a.5.5 0 00.478.626h2.3a.247.247 0 01.243.2l.921 4.847v.007a.5.5 0 00.484.389h2.969a.5.5 0 00.483-.388v-.007l.921-4.847a.247.247 0 01.243-.2h.308a.248.248 0 00.24-.308 6.864 6.864 0 01.811-5.27.253.253 0 00.028-.194c-.356-1.325-.512-2.44-1.6-3.455a.5.5 0 00-.757.1L6.482 12.16a.5.5 0 01-.837 0L3.923 9.411a.5.5 0 00-.755-.1C2.014 10.373 2.1 10.78.141 17.931zM6.041 8.412a4.206 4.206 0 10-4.207-4.206 4.211 4.211 0 004.207 4.206zm0-1.979A2.223 2.223 0 013.9 4.81a.247.247 0 01.158-.3 4.63 4.63 0 001.8-1.128.247.247 0 01.353 0 4.615 4.615 0 001.8 1.128.248.248 0 01.157.3 2.223 2.223 0 01-2.127 1.623zM11.01 16.577a5.437 5.437 0 008.361 4.591.248.248 0 01.308.034l2.508 2.508a.989.989 0 001.4-1.4l-2.509-2.51a.247.247 0 01-.033-.308 5.442 5.442 0 10-10.035-2.915zm5.444-3.464a3.464 3.464 0 11-3.464 3.464 3.469 3.469 0 013.464-3.464z\"/><path d=\"M.141 17.931a.5.5 0 00.478.626h2.3a.247.247 0 01.243.2l.921 4.847v.007a.5.5 0 00.484.389h2.969a.5.5 0 00.483-.388v-.007l.921-4.847a.247.247 0 01.243-.2h.308a.248.248 0 00.24-.308 6.864 6.864 0 01.811-5.27.253.253 0 00.028-.194c-.356-1.325-.512-2.44-1.6-3.455a.5.5 0 00-.757.1L6.482 12.16a.5.5 0 01-.837 0L3.923 9.411a.5.5 0 00-.755-.1C2.014 10.373 2.1 10.78.141 17.931zM6.041 8.412a4.206 4.206 0 10-4.207-4.206 4.211 4.211 0 004.207 4.206zm0-1.979A2.223 2.223 0 013.9 4.81a.247.247 0 01.158-.3 4.63 4.63 0 001.8-1.128.247.247 0 01.353 0 4.615 4.615 0 001.8 1.128.248.248 0 01.157.3 2.223 2.223 0 01-2.127 1.623zM11.01 16.577a5.437 5.437 0 008.361 4.591.248.248 0 01.308.034l2.508 2.508a.989.989 0 001.4-1.4l-2.509-2.51a.247.247 0 01-.033-.308 5.442 5.442 0 10-10.035-2.915zm5.444-3.464a3.464 3.464 0 11-3.464 3.464 3.469 3.469 0 013.464-3.464z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});