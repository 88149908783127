define("ember-svg-jar/inlined/temperature-thermometer-minus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>temperature-thermometer-minus</title><path d=\"M10.5 4.5a4.5 4.5 0 00-9 0v9.438a.248.248 0 01-.06.162A5.941 5.941 0 000 18a6 6 0 0012 0 5.941 5.941 0 00-1.44-3.9.248.248 0 01-.06-.162zM6 22a4 4 0 01-2.8-6.856 1 1 0 00.3-.714V4.5a2.5 2.5 0 015 0v9.93a1 1 0 00.3.714A4 4 0 016 22z\"/><path d=\"M7.111 16.338A.253.253 0 017 16.13V7a1 1 0 00-2 0v9.13a.253.253 0 01-.111.208 2 2 0 102.222 0zM23 6h-7a1 1 0 000 2h7a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});