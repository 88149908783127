define("ember-svg-jar/inlined/road-sign-obstruction-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-obstruction-1</title><path d=\"M11.513.073A.249.249 0 0011.336 0H8.665a.25.25 0 00-.177.427l8.335 8.335a.25.25 0 00.427-.177V5.914a.249.249 0 00-.073-.177zM17.142 16.943a.335.335 0 01-.051-.042L7.177 6.987a.25.25 0 00-.427.177v2.922a.251.251 0 00.073.177l10 10a.25.25 0 00.427-.177v-2.937a.253.253 0 00-.108-.206zM7.177 1.237a.25.25 0 00-.427.177v2.921a.249.249 0 00.073.177l10 10a.25.25 0 00.427-.177v-2.921a.249.249 0 00-.073-.177zM7.177 12.737a.25.25 0 00-.427.177v2.922a.251.251 0 00.073.177l7.914 7.914a.251.251 0 00.177.073H16.5a.75.75 0 00.75-.75v-.337a.251.251 0 00-.073-.177zM16.5 0h-2.335a.25.25 0 00-.177.427l2.835 2.835a.25.25 0 00.427-.177V.75A.75.75 0 0016.5 0zM7.177 18.487a.25.25 0 00-.427.177v4.586a.75.75 0 00.75.75h4.586a.25.25 0 00.177-.427z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});