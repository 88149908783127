define("ember-svg-jar/inlined/photography-equipment-flash-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>photography-equipment-flash-light</title><path d=\"M18 12.5H6a.5.5 0 00-.493.582l1 6A.5.5 0 007 19.5h10a.5.5 0 00.493-.418l1-6A.5.5 0 0018 12.5zm-4 5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM21.5 2.5a2.482 2.482 0 00-.245-1.078.25.25 0 00-.183-.138.247.247 0 00-.219.07l-2.28 2.28a.249.249 0 00-.073.177v3.378a.249.249 0 00.073.177l2.281 2.279a.245.245 0 00.218.07.252.252 0 00.184-.138A2.491 2.491 0 0021.5 8.5zM6.134 2.927A.249.249 0 006.311 3h11.378a.249.249 0 00.177-.073l2.28-2.28a.254.254 0 00.07-.219.251.251 0 00-.139-.183A2.474 2.474 0 0019 0H5a2.474 2.474 0 00-1.077.245.251.251 0 00-.139.183.254.254 0 00.07.219zM5.427 7.366a.249.249 0 00.073-.177V3.811a.249.249 0 00-.073-.177l-2.28-2.28a.247.247 0 00-.219-.07.25.25 0 00-.183.138A2.482 2.482 0 002.5 2.5v6a2.482 2.482 0 00.245 1.078.25.25 0 00.183.138.247.247 0 00.219-.07zM17.866 8.073A.247.247 0 0017.69 8H6.311a.249.249 0 00-.177.073l-2.28 2.28a.254.254 0 00-.07.219.251.251 0 00.139.183A2.474 2.474 0 005 11h14a2.486 2.486 0 001.079-.245.25.25 0 00.068-.4zM14 21h-4a.5.5 0 00-.5.5v1A1.5 1.5 0 0011 24h2a1.5 1.5 0 001.5-1.5v-1a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});