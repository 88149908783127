define("ember-svg-jar/inlined/antenna", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>antenna</title><path d=\"M15.407 6.909a1 1 0 00.707.293 1.009 1.009 0 00.707-.293L19.3 4.432A2.5 2.5 0 0019.3.9a2.56 2.56 0 00-3.54 0l-2.476 2.475a1 1 0 000 1.413zM4.8 13.273a1.025 1.025 0 00-1.414 0L.911 15.747a2.5 2.5 0 001.767 4.268 2.486 2.486 0 001.768-.732l2.475-2.476a1 1 0 000-1.414zM6.446 13.505a.5.5 0 00.707 0l6.364-6.364a.5.5 0 000-.707 5 5 0 00-7.071 7.071zM9.893 12.313a.251.251 0 00.025.377 2.055 2.055 0 002.667-.117 2.093 2.093 0 00.142-2.69.251.251 0 00-.377-.027zM9.639 19.574a9.283 9.283 0 001.011.055 9 9 0 008.936-10 1 1 0 00-1.988.221 7 7 0 01-7.738 7.736 1 1 0 10-.221 1.988z\"/><path d=\"M23.633 9.713a1 1 0 10-1.969.347 10.007 10.007 0 01-11.5 11.608 1 1 0 10-.331 1.972 12.371 12.371 0 002.037.17 12.013 12.013 0 0011.768-14.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});