define("ember-svg-jar/inlined/trekking-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trekking-map</title><path d=\"M24 1.989A1.99 1.99 0 0022.011 0H11.129A1.989 1.989 0 009.14 1.989v8.26a.919.919 0 001.048.889c.029 0 .056-.005.084-.006a.9.9 0 00.865-.9L11.129 2.5a.5.5 0 01.5-.5l9.871-.01a.5.5 0 01.5.5l.01 9.871a.5.5 0 01-.5.5h-8.241a.991.991 0 00-.955.755l-.006.023a.978.978 0 00.956 1.222h6.507a.249.249 0 01.25.25V19.3a1 1 0 102 0v-4.206a.251.251 0 01.221-.248A1.992 1.992 0 0024 12.871z\"/><path d=\"M16.781 5.91a.75.75 0 001.061 1.061l.53-.53a.25.25 0 01.353 0l.53.53a.75.75 0 101.06-1.061l-.529-.529a.251.251 0 010-.354l.529-.529a.75.75 0 10-1.06-1.06l-.53.529a.249.249 0 01-.353 0l-.53-.529A.75.75 0 1016.781 4.5l.53.529a.251.251 0 010 .354zM3.547 5.233a2.479 2.479 0 00.4 4.923A2.478 2.478 0 006.4 7.267a2.483 2.483 0 00-2.853-2.034zM10.387 12.129a.986.986 0 00-.7.29l-.412.412a1.011 1.011 0 01-.716.29h-.041a.982.982 0 01-.721-.372 3.147 3.147 0 00-2.2-1.114h-3.04a1.044 1.044 0 00-.29.037A3.069 3.069 0 000 14.6v3.462a.989.989 0 00.989.99h.74a.25.25 0 01.25.25v3.707a.989.989 0 00.989.991h1.978a.989.989 0 00.989-.994v-9.044a.177.177 0 01.32-.105A2.906 2.906 0 008.6 15.1a2.953 2.953 0 002.075-.873l.412-.411a.99.99 0 00-.7-1.689zM14.393 8.006a.747.747 0 00.48-.174l.384-.32a.75.75 0 10-.957-1.153l-.384.321a.75.75 0 00.481 1.326zM13.849 11.153a.75.75 0 00.75-.75V9.9a.75.75 0 00-1.5 0v.5a.75.75 0 00.75.753z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});