define("ember-svg-jar/inlined/shipment-lift-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-lift-1</title><path d=\"M23.25 1.75h-1A.25.25 0 0122 1.5V1a1 1 0 00-2 0v.4a.1.1 0 01-.03.073l-2.543 2.54A.25.25 0 0117 3.836V1a1 1 0 00-2 0v.25a.25.25 0 01-.25.25H1a1 1 0 000 2h4.25a.25.25 0 01.25.25v4.189a.253.253 0 01-.084.187l-4.08 3.626C.619 12.39 0 12.54 0 13.5v5A1.5 1.5 0 001.5 20h10a1.5 1.5 0 001.5-1.5v-5c0-.96-.619-1.11-1.336-1.748l-4.08-3.626a.253.253 0 01-.084-.187V3.75a.25.25 0 01.25-.25h7a.25.25 0 01.25.25V23a1 1 0 002 0v-.4a.107.107 0 01.03-.074l2.543-2.543a.25.25 0 01.427.177V23a1 1 0 002 0V3.5a.25.25 0 01.25-.25h1a.75.75 0 000-1.5zM9.75 14.5v3a.75.75 0 01-1.5 0v-3a.75.75 0 011.5 0zm-5 0v3a.75.75 0 01-1.5 0v-3a.75.75 0 011.5 0zM4.725 12a.25.25 0 01-.166-.437l1.775-1.577a.248.248 0 01.332 0l1.775 1.577a.25.25 0 01-.166.437zm12.7.013a.25.25 0 01-.425-.177V8.164a.25.25 0 01.427-.177l1.836 1.836a.252.252 0 010 .354zm2.146-.026a.25.25 0 01.427.177v3.672a.25.25 0 01-.427.177l-1.836-1.836a.252.252 0 010-.354zm-1.836-5.81a.252.252 0 010-.354l1.836-1.836a.25.25 0 01.429.177v3.672a.25.25 0 01-.427.177zm-.31 13.836a.25.25 0 01-.425-.177v-3.672a.25.25 0 01.427-.177l1.836 1.836a.252.252 0 010 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});