define("ember-svg-jar/inlined/scooter-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>scooter-2</title><path d=\"M11.1 14.4a2.91 2.91 0 011.8 0 .246.246 0 00.224-.036.25.25 0 00.1-.2V9.908a.25.25 0 00-.293-.247 5.263 5.263 0 01-1.862 0 .247.247 0 00-.2.055.25.25 0 00-.089.192v4.253a.249.249 0 00.326.237zM17.042 7.968a3.849 3.849 0 00-.42-.337.25.25 0 00-.339.048A5.508 5.508 0 0114.366 9.2a.251.251 0 00-.142.226v5.739a.25.25 0 00.059.161A2.971 2.971 0 0115 17.254v4.3a.179.179 0 00.179.179 3.266 3.266 0 003.319-3.2v-7.18a4.611 4.611 0 00-1.456-3.385zM9.634 9.2a5.517 5.517 0 01-1.917-1.521.25.25 0 00-.339-.048 3.748 3.748 0 00-.414.333A4.6 4.6 0 005.5 11.353v7.181a3.266 3.266 0 003.319 3.2.179.179 0 00.181-.177v-4.3a2.971 2.971 0 01.715-1.924.25.25 0 00.059-.161V9.43a.251.251 0 00-.14-.23zM20.245 0h-2a1.742 1.742 0 00-1.361.656.253.253 0 01-.2.094h-2.2a.25.25 0 01-.155-.041 4.206 4.206 0 00-4.674 0 .25.25 0 01-.147.041H7.31a.253.253 0 01-.195-.094A1.742 1.742 0 005.754 0h-2a1.749 1.749 0 000 3.5h2a1.713 1.713 0 00.846-.228 1.764 1.764 0 00.521-.431.248.248 0 01.194-.093h.381a.25.25 0 01.239.322 4.088 4.088 0 00-.177 1.177 4.247 4.247 0 008.494 0 4.088 4.088 0 00-.182-1.177.25.25 0 01.239-.322h.381a.248.248 0 01.194.093 1.764 1.764 0 00.521.431 1.713 1.713 0 00.841.226h2a1.749 1.749 0 000-3.5zM12 5.747a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM12 15.256a2 2 0 00-2 2V22a2 2 0 004 0v-4.746a2 2 0 00-2-1.998z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});