define("ember-svg-jar/inlined/style-three-pin-snowflake", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-snowflake</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm7.25 10.5a.75.75 0 01-.75.75h-1.586a.25.25 0 00-.177.427l1.293 1.293a.75.75 0 11-1.06 1.06l-2.707-2.707a.251.251 0 00-.177-.073H13a.25.25 0 00-.25.25v1.086a.251.251 0 00.073.177l2.707 2.707a.75.75 0 11-1.06 1.06l-1.293-1.293a.25.25 0 00-.427.177V17a.75.75 0 01-1.5 0v-1.586a.25.25 0 00-.427-.177L9.53 16.53a.75.75 0 01-1.06-1.06l2.707-2.707a.251.251 0 00.073-.177V11.5a.25.25 0 00-.25-.25H9.914a.251.251 0 00-.177.073L7.03 14.03a.75.75 0 01-1.06-1.06l1.293-1.293a.25.25 0 00-.177-.427H5.5a.75.75 0 010-1.5h1.586a.25.25 0 00.177-.427L5.97 8.03a.75.75 0 011.06-1.06l2.707 2.707a.251.251 0 00.177.073H11a.25.25 0 00.25-.25V8.414a.251.251 0 00-.073-.177L8.47 5.53a.75.75 0 011.06-1.06l1.293 1.293a.25.25 0 00.427-.177V4a.75.75 0 011.5 0v1.586a.25.25 0 00.427.177L14.47 4.47a.75.75 0 011.06 1.06l-2.707 2.707a.251.251 0 00-.073.177V9.5a.25.25 0 00.25.25h1.086a.251.251 0 00.177-.073L16.97 6.97a.75.75 0 011.06 1.06l-1.293 1.293a.25.25 0 00.177.427H18.5a.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});