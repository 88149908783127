define("ember-svg-jar/inlined/ranking-winner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ranking-winner</title><path d=\"M5.5 9.5h-4A1.5 1.5 0 000 11v9a1.5 1.5 0 001.5 1.5h4A.5.5 0 006 21V10a.5.5 0 00-.5-.5zM22.5 13.5h-4a.5.5 0 00-.5.5v7a.5.5 0 00.5.5h4A1.5 1.5 0 0024 20v-5a1.5 1.5 0 00-1.5-1.5zM15 2.5H9A1.5 1.5 0 007.5 4v17a.5.5 0 00.5.5h8a.5.5 0 00.5-.5V4A1.5 1.5 0 0015 2.5zm-2 8.25h-2a.75.75 0 010-1.5.25.25 0 00.25-.25V7a.25.25 0 00-.25-.25.75.75 0 010-1.5h.5a1.252 1.252 0 011.25 1.25V9a.25.25 0 00.25.25.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});