define("ember-svg-jar/inlined/halloween-calendar-tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>halloween-calendar-tree</title><path d=\"M14.29 15.25h.86a.5.5 0 00.4-.8L12.4 10.2a.517.517 0 00-.8 0l-3.151 4.25a.5.5 0 00.4.8h.86l-2.173 3.22a.5.5 0 00.414.78H11V20a1 1 0 002 0v-.75h3.05a.5.5 0 00.414-.78z\"/><path d=\"M22 3h-3a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v4.75a.75.75 0 01-1.5 0V3.5a.5.5 0 00-.5-.5h-6a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v4.75a.75.75 0 01-1.5 0V3.5A.5.5 0 004 3H2a2 2 0 00-2 2v17a2 2 0 002 2h20a2 2 0 002-2V5a2 2 0 00-2-2zm0 18.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});