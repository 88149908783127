define("ember-svg-jar/inlined/famous-character-power-ranger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character-power-ranger</title><path d=\"M18.343 4.5H5.657A.658.658 0 005 5.157v3.686a.658.658 0 00.657.657h12.686A.658.658 0 0019 8.843V5.157a.658.658 0 00-.657-.657zm-1.843 3h-9a.5.5 0 010-1h9a.5.5 0 010 1zM6.675 3.5h10.65a.254.254 0 00.221-.379 6.488 6.488 0 00-11.092 0 .254.254 0 00.221.379zM15.126 14.2a6.534 6.534 0 002.934-3.354.256.256 0 00-.241-.342h-1.351a.245.245 0 00-.217.135 5.459 5.459 0 01-2.133 2.3.258.258 0 01-.338-.1A2.126 2.126 0 0012 12a2.126 2.126 0 00-1.78.83.259.259 0 01-.338.1 5.438 5.438 0 01-2.123-2.3.242.242 0 00-.217-.135H6.181a.256.256 0 00-.241.342A6.534 6.534 0 008.874 14.2a.242.242 0 01.126.207v1.055a.251.251 0 01-.22.245 20.5 20.5 0 00-2.115.386.251.251 0 00-.122.411L7.68 17.8a3.5 3.5 0 002.635 1.2h3.37a3.5 3.5 0 002.635-1.2l1.137-1.3a.251.251 0 00-.122-.411 20.5 20.5 0 00-2.115-.386.251.251 0 01-.22-.245v-1.051a.242.242 0 01.126-.207z\"/><path d=\"M18.062 17.331l-.991 1.132A4.5 4.5 0 0113.682 20h-.932a.25.25 0 00-.25.25v3.5a.25.25 0 00.25.25h5.5a.25.25 0 00.25-.25V17.5a.25.25 0 00-.438-.169zM22.533 18.417a12.083 12.083 0 00-2.678-1.512.258.258 0 00-.355.232v6.613a.25.25 0 00.25.25H23a.5.5 0 00.5-.5v-3.106a2.467 2.467 0 00-.967-1.977zM11.25 20h-.935a4.5 4.5 0 01-3.385-1.536l-.992-1.133a.25.25 0 00-.438.169v6.25a.25.25 0 00.25.25h5.5a.25.25 0 00.25-.25v-3.5a.25.25 0 00-.25-.25zM4.145 16.905a12.083 12.083 0 00-2.678 1.512A2.467 2.467 0 00.5 20.394V23.5a.5.5 0 00.5.5h3.25a.25.25 0 00.25-.25v-6.613a.258.258 0 00-.355-.232z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});