define("ember-svg-jar/inlined/signal-full", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>signal-full</title><rect x=\".5\" y=\"15\" width=\"3\" height=\"6\" rx=\".5\" ry=\".5\"/><rect x=\"5.5\" y=\"12\" width=\"3\" height=\"9\" rx=\".5\" ry=\".5\"/><rect x=\"10.5\" y=\"9\" width=\"3\" height=\"12\" rx=\".5\" ry=\".5\"/><rect x=\"15.5\" y=\"6\" width=\"3\" height=\"15\" rx=\".5\" ry=\".5\"/><rect x=\"20.5\" y=\"3\" width=\"3\" height=\"18\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});