define("ember-svg-jar/inlined/target-correct", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>target-correct</title><path d=\"M14.5 3.914V5.5a.249.249 0 01-.1.2 1.168 1.168 0 00-.107.093l-5 5a1 1 0 101.414 1.414l5-5A.938.938 0 0015.8 7.1.251.251 0 0116 7h1.586a1.507 1.507 0 001.06-.44l2.708-2.707A.5.5 0 0021 3h-2.25a.25.25 0 01-.25-.25V.5a.5.5 0 00-.854-.354l-2.707 2.707a1.491 1.491 0 00-.439 1.061z\"/><path d=\"M10 5.5a6 6 0 000 12 1 1 0 000-2 4 4 0 010-8 1 1 0 000-2z\"/><path d=\"M10 21.5a1 1 0 000-2 8 8 0 112.225-15.687 1 1 0 10.554-1.921A10 10 0 1010 21.5z\"/><path d=\"M17.5 24a6.5 6.5 0 10-6.5-6.5 6.508 6.508 0 006.5 6.5zm-3.394-6.323a.752.752 0 011.061 0l1.3 1.3a.255.255 0 00.2.073.248.248 0 00.182-.1l2.725-3.65a.75.75 0 011.2.9l-2.906 3.88a1.5 1.5 0 01-1.1.6h-.109a1.493 1.493 0 01-1.056-.441l-1.5-1.5a.75.75 0 01.003-1.062z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});