define("ember-svg-jar/inlined/upload-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.5 23C17.841 23 23 17.841 23 11.5S17.841 0 11.5 0 0 5.159 0 11.5 5.159 23 11.5 23zm0-22C17.29 1 22 5.71 22 11.5S17.29 22 11.5 22 1 17.29 1 11.5 5.71 1 11.5 1z\"/><path d=\"M6.354 10.854L11 6.207V18a.5.5 0 001 0V6.207l4.646 4.646a.5.5 0 00.707-.707l-5.499-5.5a.503.503 0 00-.709 0l-5.499 5.5a.5.5 0 00.708.708z\"/><g><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});