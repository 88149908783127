define("ember-svg-jar/inlined/console-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>console-table</title><path d=\"M23 4.14H1a.75.75 0 000 1.5h.5a.286.286 0 01.269.206l2.539 8.474a1.474 1.474 0 00.661.839.256.256 0 01.111.334l-1.475 2.949a1 1 0 00.448 1.342 1.146 1.146 0 001.347-.447l1.9-3.809a.249.249 0 01.224-.138H10.5a.25.25 0 00.25-.25V5.89a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v3a.25.25 0 00.25.25h8.561a.251.251 0 00.239-.179s.861-2.9.92-3.065a.362.362 0 01.3-.256H23a.75.75 0 000-1.5zm-14.75 5h-2.5a.75.75 0 010-1.5h2.5a.75.75 0 110 1.5z\"/><path d=\"M20.461 10.64H12.5a.25.25 0 00-.25.25v4.25a.25.25 0 00.25.25h3.977a.249.249 0 01.224.138l1.9 3.809a1.093 1.093 0 001.342.447 1 1 0 00.448-1.342l-1.471-2.949a.256.256 0 01.111-.334 1.474 1.474 0 00.661-.839l1.008-3.359a.249.249 0 00-.239-.321z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});