define("ember-svg-jar/inlined/landmark-chinese-pagoda", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-chinese-pagoda</title><path d=\"M19.416 17.223A3.5 3.5 0 0016 15.5H8a3.5 3.5 0 00-3.416 1.723A.5.5 0 005 18h14a.5.5 0 00.416-.777zM6.5 14h11a.5.5 0 00.416-.777A3.5 3.5 0 0014.5 11.5h-5a3.5 3.5 0 00-3.416 1.723A.5.5 0 006.5 14zM7.5 10h9a.5.5 0 00.416-.777A3.5 3.5 0 0013.5 7.5h-3a3.5 3.5 0 00-3.416 1.723A.5.5 0 007.5 10zM9 6h6a.5.5 0 00.416-.777 8.074 8.074 0 00-2.194-2.076.5.5 0 01-.222-.415V1a1 1 0 00-2 0v1.732a.5.5 0 01-.222.415 8.074 8.074 0 00-2.194 2.076A.5.5 0 009 6zM17 19.5H7a.5.5 0 00-.5.5v3.5a.5.5 0 00.5.5h1.75a.5.5 0 00.5-.5v-1a.75.75 0 011.5 0v1a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-1a.75.75 0 011.5 0v1a.5.5 0 00.5.5H17a.5.5 0 00.5-.5V20a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});