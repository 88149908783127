define("ember-svg-jar/inlined/underwear-bra-lace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>underwear-bra-lace</title><path d=\"M23.079 12.033a3.046 3.046 0 01-.079-.686V1.75a1 1 0 00-2 0v9.278a.5.5 0 01-.38.485c-3.605.893-5.84 3.863-7.525 6.975a.5.5 0 01-.44.262h-1.31a.5.5 0 01-.44-.262c-1.685-3.112-3.92-6.082-7.525-6.975a.5.5 0 01-.38-.485V1.75a1 1 0 00-2 0v9.607a3.01 3.01 0 01-.076.669A25.27 25.27 0 000 18.25a5.006 5.006 0 005 5 7.487 7.487 0 004.582-1.511 11.143 11.143 0 00.987-.85.5.5 0 01.346-.139h2.17a.5.5 0 01.346.139 11.157 11.157 0 00.992.854A7.479 7.479 0 0019 23.25a5.006 5.006 0 005-5 25.146 25.146 0 00-.921-6.217zM8.344 19.87a8.827 8.827 0 01-.455.418.514.514 0 01-.843-.329l-.117-1.038a.672.672 0 00-.612-.571l-1.094-.117a.673.673 0 01-.6-.578l-.124-1.032a.66.66 0 00-.6-.571L2.8 15.935a.673.673 0 01-.612-.578l-.089-.749v-.014a.777.777 0 01.879-.6c2.5.4 4.132 2.28 5.523 4.882a.838.838 0 01-.157.994zm13.562-5.262l-.089.749a.673.673 0 01-.612.578l-1.094.117a.66.66 0 00-.605.571l-.124 1.032a.673.673 0 01-.605.578l-1.094.117a.672.672 0 00-.612.571l-.117 1.038a.514.514 0 01-.843.329 8.827 8.827 0 01-.455-.418.838.838 0 01-.151-.99c1.395-2.602 3.019-4.48 5.523-4.88a.777.777 0 01.879.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});