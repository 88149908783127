define("ember-svg-jar/inlined/medical-specialty-optometrist-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-specialty-optometrist-alternate</title><path d=\"M19.5 16a1 1 0 00-1 1v4.5a.5.5 0 01-.5.5l-15.5.007a.5.5 0 01-.5-.5V2.5a.5.5 0 01.5-.5L18 1.993a.5.5 0 01.5.5v7.192a1 1 0 102 0V1.993A2 2 0 0018.5 0H2a2 2 0 00-2 1.993v20.014A2 2 0 002 24h16.5a2 2 0 002-1.993V17a1 1 0 00-1-1z\"/><path d=\"M9 5.25a.751.751 0 01.75-.75h2.5a.75.75 0 000-1.5h-2.5A2.253 2.253 0 007.5 5.25v4.5A2.253 2.253 0 009.75 12h2.5a.75.75 0 000-1.5h-2.5A.751.751 0 019 9.75V8.5a.5.5 0 01.5-.5h1.25a.75.75 0 000-1.5H9.5A.5.5 0 019 6zM23.78 17.78a.749.749 0 000-1.06l-8.5-8.5a.75.75 0 00-1.06 1.06l8.5 8.5a.748.748 0 001.06 0zM17 14.25a.75.75 0 00-.75-.75h-12a.75.75 0 000 1.5h12a.75.75 0 00.75-.75zM5.25 21a.75.75 0 00.75-.75v-2a.25.25 0 01.25-.25.75.75 0 000-1.5h-2a.75.75 0 000 1.5.25.25 0 01.25.25v2a.75.75 0 00.75.75zM10.25 16.5a1.752 1.752 0 00-1.75 1.75v1a1.75 1.75 0 003.5 0v-1a1.752 1.752 0 00-1.75-1.75zm.25 1.75v1c0 .275-.5.275-.5 0v-1a.25.25 0 01.5 0zM16.5 18.749a1.25 1.25 0 00-.75-2.249h-1.5a.75.75 0 000 1.5.25.25 0 01.15.45l-.4.3a1.25 1.25 0 00.75 2.25h1.5a.75.75 0 000-1.5.25.25 0 01-.15-.45z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});