define("ember-svg-jar/inlined/road-sign-stop-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-stop-alternate</title><path d=\"M10.75 9h-2a.75.75 0 000 1.5.25.25 0 01.25.25v4a.75.75 0 001.5 0v-4a.25.25 0 01.25-.25.75.75 0 000-1.5zM18.25 9h-.5a.75.75 0 00-.75.75v5a.75.75 0 001.5 0v-1.062a.249.249 0 01.2-.245A2.244 2.244 0 0018.25 9zm.5 2.5a.25.25 0 11.25-.25.25.25 0 01-.25.25zM14.25 9a1.752 1.752 0 00-1.75 1.75v3a1.75 1.75 0 003.5 0v-3A1.752 1.752 0 0014.25 9zm.25 4.75c0 .275-.5.275-.5 0v-3a.25.25 0 01.5 0zM5.25 10.5h1a.75.75 0 000-1.5h-1a1.752 1.752 0 00-1.75 1.75 2.553 2.553 0 001.3 2.1c.508.381.7.557.7.9a.25.25 0 01-.25.25h-1a.75.75 0 000 1.5h1A1.752 1.752 0 007 13.75a2.553 2.553 0 00-1.3-2.1c-.508-.381-.7-.557-.7-.9a.25.25 0 01.25-.25z\"/><path d=\"M23.707 6.736L17.263.293A1 1 0 0016.556 0H7.444a1 1 0 00-.707.293L.293 6.736A1 1 0 000 7.443v9.114a1 1 0 00.293.707l6.444 6.443a1 1 0 00.707.293h9.112a1 1 0 00.707-.293l6.444-6.443a1 1 0 00.293-.707V7.443a1 1 0 00-.293-.707zM22 16.039a.251.251 0 01-.073.177l-5.712 5.711a.248.248 0 01-.176.073H7.961a.248.248 0 01-.176-.073l-5.712-5.711A.251.251 0 012 16.039V7.961a.251.251 0 01.073-.177l5.712-5.711A.248.248 0 017.961 2h8.078a.248.248 0 01.176.073l5.712 5.711a.251.251 0 01.073.177z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});