define("ember-svg-jar/inlined/products-gift-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>products-gift-tag</title><path d=\"M11 13.446A2.5 2.5 0 0113.5 11H16a.25.25 0 00.25-.25V9a.75.75 0 00-.75-.75h-6a.25.25 0 00-.25.25v7a.25.25 0 00.25.25h1.207a.25.25 0 00.25-.245zM7.75 8.5a.25.25 0 00-.25-.25h-6A.75.75 0 00.75 9v5a1.752 1.752 0 001.75 1.75h5a.25.25 0 00.25-.25z\"/><path d=\"M18.914 13a1.906 1.906 0 00-1.414-.5h-4a1 1 0 00-1 .978l-.086 4.022A1.988 1.988 0 0013 18.915l4.5 4.5a2 2 0 002.828 0l3.086-3.085a2 2 0 000-2.829zm-4.441 1.745a.25.25 0 01.25-.245H17.5a.208.208 0 01.147.061l4.176 4.176a.25.25 0 010 .354l-2.732 2.733a.25.25 0 01-.353 0L14.49 17.6a.25.25 0 01-.074-.183zM15.5 7A1.5 1.5 0 0017 5.5V5a1.5 1.5 0 00-1.5-1.5h-.876a.249.249 0 01-.2-.1.245.245 0 01-.038-.222 2.561 2.561 0 00.115-.762A2.414 2.414 0 0010.379.707l-1.7 1.7a.251.251 0 01-.354 0l-1.7-1.7A2.414 2.414 0 002.5 2.414a2.561 2.561 0 00.115.762.248.248 0 01-.037.222.252.252 0 01-.2.1H1.5A1.5 1.5 0 000 5v.5A1.5 1.5 0 001.5 7zm-3.706-4.879a.43.43 0 01.706.293.587.587 0 01-.586.586h-.4a.25.25 0 01-.177-.427zM4.914 2a.417.417 0 01.293.121l.452.452A.25.25 0 015.482 3h-.4a.587.587 0 01-.582-.586A.415.415 0 014.914 2z\"/><path d=\"M19 19.751a.75.75 0 00.53-1.28l-2-2a.75.75 0 10-1.06 1.06l2 2a.744.744 0 00.53.22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});