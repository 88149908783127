define("ember-svg-jar/inlined/music-genre-team", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-genre-team</title><circle cx=\"3.535\" cy=\"5.491\" r=\"2.5\"/><path d=\"M4.928 9.241a4 4 0 00-4.593 1.35.25.25 0 00.2.4H5.15a.252.252 0 00.161-.059q.38-.321.784-.6a.247.247 0 00.107-.193.25.25 0 00-.088-.2 3.992 3.992 0 00-1.186-.698z\"/><circle cx=\"20.465\" cy=\"5.491\" r=\"2.5\"/><path d=\"M21.857 9.241a4 4 0 00-3.971.693.25.25 0 00.019.4q.4.282.785.6a.25.25 0 00.161.059h4.614a.25.25 0 00.2-.4 4.017 4.017 0 00-1.808-1.352zM13.452 14.238c-.041.008-3.572 1.14-3.572 1.14a2 2 0 00-1.559 1.952v2.984a.25.25 0 01-.188.242 1.75 1.75 0 102.188 1.694v-4.737a.251.251 0 01.175-.238c.554-.175 2.273-.726 3-.958a.251.251 0 01.327.238v1.759a.25.25 0 01-.188.242 1.75 1.75 0 102.188 1.694V16.2a2 2 0 00-2.371-1.962z\"/><path d=\"M10.242 10.171a9.183 9.183 0 00-6.174 4.579.5.5 0 00.432.75h3.135a3.251 3.251 0 011.978-1.343s3.444-1.109 3.5-1.121a3.271 3.271 0 012.787.664 3.237 3.237 0 011.1 1.8h2.5a.5.5 0 00.433-.75 9.187 9.187 0 00-6.175-4.58.252.252 0 01-.2-.224.247.247 0 01.161-.252 5 5 0 10-3.432 0 .247.247 0 01.161.252.254.254 0 01-.206.225zM9 5a3.014 3.014 0 01.156-.954.25.25 0 01.356-.14 9.084 9.084 0 004.321 1.088 9.094 9.094 0 001.088-.065.07.07 0 01.055.018A.068.068 0 0115 5a3 3 0 01-6 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});