define("ember-svg-jar/inlined/real-estate-app-house-laptop-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-app-house-laptop-1</title><path d=\"M12.673 6.311a1 1 0 00-1.345 0l-5.164 4.694a.5.5 0 00.336.87h1a.5.5 0 01.5.5v2.5a1 1 0 001 1h1a1 1 0 001-1v-2a1 1 0 012 0v2a1 1 0 001 1h1a1 1 0 001-1v-2.5a.5.5 0 01.5-.5h1a.5.5 0 00.336-.87z\"/><path d=\"M24 17.875a.5.5 0 00-.5-.5h-.25a.5.5 0 01-.5-.5V5.125a2.5 2.5 0 00-2.5-2.5H3.75a2.5 2.5 0 00-2.5 2.5v11.75a.5.5 0 01-.5.5H.5a.5.5 0 00-.5.5 3.5 3.5 0 003.5 3.5h17a3.5 3.5 0 003.5-3.5zM3.25 5.125a.5.5 0 01.5-.5h16.5a.5.5 0 01.5.5v11.75a.5.5 0 01-.5.5H3.75a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});