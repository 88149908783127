define("ember-svg-jar/inlined/designer-community-design-float", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>designer-community-design-float</title><path d=\"M12 0a12 12 0 1012 12A12 12 0 0012 0zm0 22a10 10 0 1110-10 10 10 0 01-10 10z\"/><circle cx=\"12\" cy=\"12\" r=\"4\"/><path d=\"M4 10.62h2.3a.5.5 0 00.47-.34 5.54 5.54 0 013.51-3.51.5.5 0 00.34-.47V4a.5.5 0 00-.62-.49A8.74 8.74 0 003.51 10a.5.5 0 00.49.61zM13.72 6.77a5.52 5.52 0 013.51 3.51.5.5 0 00.48.34H20a.5.5 0 00.49-.61A8.73 8.73 0 0014 3.51a.5.5 0 00-.61.49v2.3a.5.5 0 00.33.47zM10.28 17.23a5.52 5.52 0 01-3.51-3.51.5.5 0 00-.48-.34H4a.5.5 0 00-.49.61 8.73 8.73 0 006.49 6.5.5.5 0 00.61-.49v-2.3a.5.5 0 00-.33-.47zM20.4 13.57a.5.5 0 00-.39-.19h-2.3a.5.5 0 00-.47.34 5.52 5.52 0 01-3.5 3.51.5.5 0 00-.34.47V20a.5.5 0 00.61.49 8.73 8.73 0 006.5-6.5.5.5 0 00-.11-.42z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});