define("ember-svg-jar/inlined/vintage-car-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-car-5</title><path d=\"M22.651 11.735l-3.078-.435a.2.2 0 01-.125-.07L17.706 9.1C15.419 6.3 12.2 5.781 9.648 5.641a1.749 1.749 0 00-1.6.847L5.5 10.741a.249.249 0 01-.214.121H1.758C.7 10.862 0 12.222 0 13.12a1.758 1.758 0 00.125.652l.539 1.347A1.969 1.969 0 002.5 16.362h.76a.25.25 0 00.249-.23 3 3 0 015.982 0 .25.25 0 00.249.23h5.52a.25.25 0 00.249-.23 2.992 2.992 0 015.748-.923.25.25 0 00.231.153h.941c.945 0 1.571-1.247 1.571-2.071a1.583 1.583 0 00-1.349-1.556zM11.5 14.362h-1a.5.5 0 010-1h1a.5.5 0 010 1zm1.876-3.662a.251.251 0 01-.235.163H8.2a.249.249 0 01-.214-.378l1-1.672a.25.25 0 01.269-.113 9.167 9.167 0 014.045 1.723.249.249 0 01.076.277z\"/><circle cx=\"18.5\" cy=\"16.362\" r=\"2\"/><circle cx=\"6.5\" cy=\"16.362\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});