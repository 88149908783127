define("ember-svg-jar/inlined/night-moon-begin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>night-moon-begin</title><path d=\"M4.935 16.937a1 1 0 101.414-1.414A8 8 0 018.911 2.484a.25.25 0 01.346.235v.147a9.761 9.761 0 009.75 9.75h.143a.25.25 0 01.234.347 8.026 8.026 0 01-2.248 3.043 1 1 0 101.283 1.534 10.005 10.005 0 003.439-5.952 1 1 0 00-1.2-1.148 7.752 7.752 0 01-9.225-9.226 1 1 0 00-1.149-1.2 10 10 0 00-5.35 16.922zM22.994 22H1.006a1 1 0 000 2h21.988a1 1 0 000-2z\"/><path d=\"M12.6 12.631a.772.772 0 00-1.2 0L8.655 16.3c-.015.02-.028.041-.041.062a.749.749 0 00.642 1.138h1.5a.25.25 0 01.25.25V20a1 1 0 002 0v-2.25a.25.25 0 01.25-.25h1.5a.746.746 0 00.672-.416.756.756 0 00.074-.413.743.743 0 00-.146-.37z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});