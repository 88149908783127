define("ember-svg-jar/inlined/real-estate-person-search-house-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-person-search-house-1</title><path d=\"M12.75 6.75a.5.5 0 01.5.5v2.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-2.5a.5.5 0 01.5-.5.5.5 0 00.325-.88l-3.5-3a.5.5 0 00-.65 0l-3.5 3a.5.5 0 00.325.88z\"/><path d=\"M24 2a2 2 0 00-2-2H10.5a2 2 0 00-2 2v9.5a2 2 0 002 2h.25a.5.5 0 01.5.5v1.146a1.356 1.356 0 002.311.958l2.457-2.458a.5.5 0 01.353-.146H22a2 2 0 002-2zm-2 9a.5.5 0 01-.5.5h-5.75a1 1 0 00-.707.293l-1.366 1.366a.25.25 0 01-.427-.177V12.5a1 1 0 00-1-1H11a.5.5 0 01-.5-.5V2.5A.5.5 0 0111 2h10.5a.5.5 0 01.5.5zM3.5 13.5A3.5 3.5 0 000 17v2a.5.5 0 00.5.5h.605a.5.5 0 01.5.445L2 23.556a.5.5 0 00.5.444h2a.5.5 0 00.5-.444l.4-3.611a.5.5 0 01.5-.445h.6A.5.5 0 007 19v-2a3.5 3.5 0 00-3.5-3.5z\"/><circle cx=\"3.5\" cy=\"10\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});