define("ember-svg-jar/inlined/vintage-car-luggage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-car-luggage</title><path d=\"M22.25 14.5h1.5a.25.25 0 00.25-.25v-.5a.859.859 0 00-.234-.545l-3.882-3.677a3.722 3.722 0 00-2.4-1.015.258.258 0 01-.131-.045.243.243 0 01-.105-.2V6.121a.238.238 0 01.013-.079l.8-2.409A.837.837 0 0017.25 2.5H7.872a1.372 1.372 0 00-.434 2.673l.609.2a.25.25 0 01.145.126l.137.274A1.4 1.4 0 009.5 6.5h.7a.25.25 0 01.233.16l.851 2.213a.248.248 0 01-.052.262l-3.8 4.01a.252.252 0 01-.134.074l-5.881 1.137A1.756 1.756 0 000 16.075v1.011A1.746 1.746 0 001.071 18.7l1.142.481a.247.247 0 00.217-.011.251.251 0 00.126-.177A2.993 2.993 0 018.5 19.5a2.8 2.8 0 01-.089.689.251.251 0 00.243.311h7.692a.251.251 0 00.243-.311 2.8 2.8 0 01-.089-.689 2.992 2.992 0 015.915-.648.249.249 0 00.391.148l.474-.345a1.754 1.754 0 00.72-1.414v-.991a.25.25 0 00-.25-.25h-1.5a.75.75 0 010-1.5zm-9.386-7.392A.253.253 0 0113.07 7H15a.25.25 0 01.25.25V8a.25.25 0 01-.25.25h-1.641a.252.252 0 01-.234-.16l-.288-.75a.249.249 0 01.027-.232zm2.886 6a4 4 0 01-.129 1.01l-1.1 4.194a.249.249 0 01-.242.187h-3.824a.252.252 0 01-.242-.184l-.571-2.275a.25.25 0 01.247-.291H11a.25.25 0 01.25.25.75.75 0 001.5 0V14a.75.75 0 00-1.5 0 .25.25 0 01-.25.25H9.46a.137.137 0 01-.1-.232l3.5-3.69a.25.25 0 01.182-.078H15.5a.25.25 0 01.25.25z\"/><circle cx=\"5.5\" cy=\"19.5\" r=\"2\"/><circle cx=\"19.5\" cy=\"19.5\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});