define("ember-svg-jar/inlined/single-neutral-aim.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-aim</title><path d=\"M24 12a1 1 0 00-1-1h-.827a.249.249 0 01-.248-.22 10.021 10.021 0 00-8.706-8.706.25.25 0 01-.219-.248V1a1 1 0 00-2 0v.826a.249.249 0 01-.22.248 10.02 10.02 0 00-8.7 8.707.25.25 0 01-.253.219H1a1 1 0 000 2h.827a.25.25 0 01.248.219 10.019 10.019 0 008.706 8.705.25.25 0 01.219.248V23a1 1 0 002 0v-.828a.25.25 0 01.219-.248 10.019 10.019 0 008.706-8.7.25.25 0 01.248-.224H23a1 1 0 001-1zm-10.747 7.894a.22.22 0 01-.253-.216V19.5a1 1 0 00-2 0v.141a.25.25 0 01-.291.247 8.01 8.01 0 01-6.6-6.6.251.251 0 01.249-.288H4.5a1 1 0 000-2h-.142a.25.25 0 01-.247-.291 8.011 8.011 0 016.6-6.6.251.251 0 01.289.248V4.5a1 1 0 002 0v-.216a.19.19 0 01.065-.143.192.192 0 01.151-.043 8.011 8.011 0 016.673 6.611.251.251 0 01-.247.291H19.5a1 1 0 000 2h.252a.158.158 0 01.118.054.154.154 0 01.036.125 8.009 8.009 0 01-6.653 6.715z\"/><circle cx=\"12\" cy=\"9.5\" r=\"3\"/><path d=\"M12 13.5a5.742 5.742 0 00-4.94 2.809.449.449 0 000 .461.486.486 0 00.42.23h9.038a.486.486 0 00.415-.229.444.444 0 000-.461A5.729 5.729 0 0012 13.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});