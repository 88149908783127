define("ember-svg-jar/inlined/professions-man-chef-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-chef-3</title><path d=\"M23.382 23.178a4.685 4.685 0 00-2-1.394l-4.5-1.59a.5.5 0 00-.5.1A6.482 6.482 0 0112 22a6.051 6.051 0 01-1.441-.188.25.25 0 00-.309.243v1.7a.25.25 0 00.25.25H23a.5.5 0 00.382-.822zM8.75 21.249a.25.25 0 00-.121-.214 6.351 6.351 0 01-1-.739.5.5 0 00-.5-.1l-4.5 1.591a4.685 4.685 0 00-2 1.394A.5.5 0 001 24h7.5a.25.25 0 00.25-.25zM11.979 20.75c1.666 0 5.176-1.943 5.687-4.989a.252.252 0 01.145-.189 2.732 2.732 0 001.439-2.434 1.885 1.885 0 00-.936-1.809.75.75 0 00-1 .336.724.724 0 00-.072.335.743.743 0 00.408.671.615.615 0 01.105.438 1.281 1.281 0 01-.559 1.1 1.75 1.75 0 00-1 1.3c-.377 2.249-3.175 3.739-4.208 3.739s-3.83-1.49-4.207-3.738a1.747 1.747 0 00-1-1.306 1.275 1.275 0 01-.559-1.1.618.618 0 01.105-.439.744.744 0 00.395-.665.724.724 0 00-.072-.335.749.749 0 00-1.006-.336 1.886 1.886 0 00-.934 1.81 2.731 2.731 0 001.439 2.433.25.25 0 01.144.187 6.214 6.214 0 002.993 4.034 7.754 7.754 0 00.921.494 4.647 4.647 0 001.772.463z\"/><path d=\"M8.45 13.286a.753.753 0 001.061.039.562.562 0 01.557 0 .75.75 0 001.022-1.1 1.629 1.629 0 00-.319-.225 2.087 2.087 0 00-1.963 0 1.66 1.66 0 00-.319.226.751.751 0 00-.039 1.06zM12.871 13.286a.753.753 0 001.061.039.562.562 0 01.557 0 .75.75 0 001.022-1.1 1.629 1.629 0 00-.319-.225 2.085 2.085 0 00-1.962 0 1.636 1.636 0 00-.32.226.75.75 0 00-.039 1.06zM9.892 17.214a3.315 3.315 0 00.494.037 2.878 2.878 0 001.489-.408 2.979 2.979 0 001.506.407 3.116 3.116 0 00.789-.1.75.75 0 00-.379-1.451 1.438 1.438 0 01-1.352-.288.777.777 0 00-1.135 0 1.379 1.379 0 01-1.188.321.75.75 0 00-.224 1.484zM5.371 10a.5.5 0 00.692.461 14.992 14.992 0 0111.616.012.492.492 0 00.192.039.5.5 0 00.5-.5V6.985a.246.246 0 01.084-.186A2.75 2.75 0 0016.621 2a2.673 2.673 0 00-.956.175.25.25 0 01-.307-.113 3.981 3.981 0 00-6.976 0 .248.248 0 01-.307.112A2.679 2.679 0 007.121 2a2.749 2.749 0 00-1.833 4.8.249.249 0 01.083.186z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});