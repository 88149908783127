define("ember-svg-jar/inlined/smart-watch-square-music-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-music-alternate</title><path d=\"M21 6a3 3 0 00-3-3h-1.078a.249.249 0 01-.24-.182L15.98.365A.5.5 0 0015.5 0h-7a.5.5 0 00-.481.363l-.7 2.455A.248.248 0 017.077 3H6a3 3 0 00-3 3v12a3 3 0 003 3h1.077a.249.249 0 01.24.181l.7 2.456A.5.5 0 008.5 24h7a.5.5 0 00.48-.363l.7-2.456a.25.25 0 01.24-.181H18a3 3 0 003-3zm-2 12a1 1 0 01-1 1H6a1 1 0 01-1-1V6a1 1 0 011-1h12a1 1 0 011 1z\"/><path d=\"M15.75 8.889a1.757 1.757 0 00-.727-1.42 1.729 1.729 0 00-1.523-.255l-3.3.848A1.747 1.747 0 009 9.722v3.03a.25.25 0 01-.25.25 1.75 1.75 0 101.75 1.75v-5.03a.254.254 0 01.12-.222l3.3-.848a.25.25 0 01.329.237v2.363a.25.25 0 01-.25.25 1.75 1.75 0 101.75 1.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});