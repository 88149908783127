define("ember-svg-jar/inlined/shop-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shop-cart</title><path d=\"M10.884 11.8a.75.75 0 00-1.03.258L8.5 14.325a.251.251 0 01-.215.121h-.45a.5.5 0 00-.478.644l1.5 5a.5.5 0 00.478.356h5a.5.5 0 00.475-.342l1.667-5a.5.5 0 00-.477-.658h-.283a.252.252 0 01-.215-.121l-1.358-2.264a.751.751 0 00-1.288.771l.742 1.236a.25.25 0 01-.215.378h-2.768a.25.25 0 01-.215-.378l.742-1.236a.752.752 0 00-.258-1.032z\"/><path d=\"M21.549 10.446a1 1 0 00-1 1l-.049 10a.5.5 0 01-.5.5H3.945a.5.5 0 01-.5-.5v-10a1 1 0 00-2 0v10.5a2.029 2.029 0 002.053 2h17a2.029 2.029 0 002.053-2v-10.5a1 1 0 00-1.002-1zM23.956 5.126L22.235 1.02a1.5 1.5 0 00-1.354-.966H3.119a1.5 1.5 0 00-1.354.966L.044 5.126a.529.529 0 00-.042.2v.908A3.243 3.243 0 001.99 9.156a3.4 3.4 0 003.258-.283.25.25 0 01.313.036A4.9 4.9 0 008.65 10.35c.133.011.266.016.4.016a4.613 4.613 0 002.8-.931.249.249 0 01.3 0 4.662 4.662 0 003.21.914 4.8 4.8 0 003.08-1.437.25.25 0 01.314-.038 3.394 3.394 0 003.252.287A3.3 3.3 0 0024 6.235v-.908a.516.516 0 00-.044-.201z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});