define("ember-svg-jar/inlined/style-one-pin-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-star</title><path d=\"M12 0a9.5 9.5 0 00-4 18.119 2.5 2.5 0 011.218 1.222l1.873 4.076a1 1 0 001.817 0l1.876-4.08A2.5 2.5 0 0116 18.118 9.5 9.5 0 0012 0zm4.8 8.2l-2.043 2.015a.249.249 0 00-.054.277l1.132 2.6a.654.654 0 01-.931.819l-2.778-1.565a.249.249 0 00-.245 0L9.1 13.912a.654.654 0 01-.931-.819l1.132-2.6a.249.249 0 00-.054-.277L7.2 8.2a.613.613 0 01.429-1.07h2.339a.25.25 0 00.227-.144l1.217-2.625a.662.662 0 011.177 0L13.8 6.982a.248.248 0 00.226.145h2.336A.613.613 0 0116.8 8.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});