define("ember-svg-jar/inlined/safety-fire-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-fire-shield</title><path d=\"M24 1.953A1.958 1.958 0 0022.044.006H1.959A1.958 1.958 0 00.012 1.965L0 9.305a15.148 15.148 0 0011.861 14.67 1 1 0 00.4 0A15.145 15.145 0 0023.988 9.2zM11.909 17.506a5.091 5.091 0 01-5.091-5.091 4.6 4.6 0 012.868-4.321.268.268 0 01.373.175 2.892 2.892 0 00.982 1.4.251.251 0 00.388-.075 5.267 5.267 0 00-.63-5.334.27.27 0 01.3-.423A9.347 9.347 0 0117 12.415a5.091 5.091 0 01-5.091 5.091z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});