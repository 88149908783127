define("ember-svg-jar/inlined/smiley-in-trouble-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-in-trouble-alternate</title><path d=\"M22.948 10.5a1 1 0 00-.958 1.041c.006.151.01.3.01.458a9.992 9.992 0 11-6.364-9.318 1 1 0 00.728-1.863A12 12 0 1024 12c0-.182 0-.361-.012-.541a1.025 1.025 0 00-1.04-.959z\"/><path d=\"M20.5 9.5A3.5 3.5 0 0024 6c0-1.57-2.049-4.659-2.677-5.568a1.038 1.038 0 00-1.646 0C19.049 1.341 17 4.43 17 6a3.5 3.5 0 003.5 3.5zm-.215-6.309a.25.25 0 01.43 0A9.055 9.055 0 0122 6a1.5 1.5 0 01-3 0 9.055 9.055 0 011.285-2.809zM7.508 18.37a1 1 0 001.362-.378A3.656 3.656 0 0112 16a3.656 3.656 0 013.13 1.992 1 1 0 001.74-.984A5.624 5.624 0 0012 14a5.624 5.624 0 00-4.87 3.008 1 1 0 00.378 1.362z\"/><circle cx=\"8.5\" cy=\"9.5\" r=\"2\"/><circle cx=\"15.5\" cy=\"9.5\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});