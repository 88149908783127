define("ember-svg-jar/inlined/time-clock-file-favorite-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-clock-file-favorite-heart</title><path d=\"M23.414 3L21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v9.573a.258.258 0 00.076.189c.467.42 1.247-.214 1.768-.455A.254.254 0 008 11.078V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.354.146l2.121 2.121a.5.5 0 01.146.355V18a.5.5 0 01-.5.5h-7.53a.254.254 0 00-.208.111c-.237.334-.844 1.027-1.243 1.474a.249.249 0 00.189.414H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M12.628 14.45a3.505 3.505 0 00-3.044-1.95 3.657 3.657 0 00-2.694 1.127l-.212.212a.252.252 0 01-.354 0l-.212-.212A3.66 3.66 0 003.418 12.5a3.527 3.527 0 00-2.391 6.012l5.113 5.335a.5.5 0 00.722 0l5.106-5.327a3.522 3.522 0 00.66-4.07zM15.5 13.251A4.75 4.75 0 1010.751 8.5a4.756 4.756 0 004.749 4.751zm0-8A3.25 3.25 0 1112.251 8.5 3.254 3.254 0 0115.5 5.251z\"/><path d=\"M15.5 9.251h1.768a.75.75 0 100-1.5H16.5a.252.252 0 01-.25-.251v-.767a.75.75 0 00-1.5 0V8.5a.75.75 0 00.75.751z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});