define("ember-svg-jar/inlined/astrology-cane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astrology-cane</title><path d=\"M21.261 16.874c-1.4-.03-2.016-.646-2.011-2a.5.5 0 10-1 0c0 1.364-.624 1.981-2.007 2a.5.5 0 00.007 1c.423 0 1.981-.1 2 2a.5.5 0 001 .021c.12-2.4 2-2.025 2-2.025a.5.5 0 00.011-1zM10.261 5.855C7.514 5.8 6.24 4.527 6.25 1.857a.522.522 0 00-.5-.5.5.5 0 00-.5.5c0 2.772-1.373 3.965-4.007 4a.5.5 0 00.008 1c2.687 0 3.975 1.25 4 4a.5.5 0 001 .02c.141-2.818 1.459-4.025 4-4.025a.5.5 0 00.011-1zM21.452 10.929a6.148 6.148 0 000-8.689 4.734 4.734 0 00-7.919 4.572 1.911 1.911 0 103.692-.987.917.917 0 01.236-.882.934.934 0 011.289 0 2.325 2.325 0 010 3.284 3.732 3.732 0 01-3.169 1.056 5.783 5.783 0 00-5.023 1.817 9.212 9.212 0 00-2.112 3.3c-.925 2.514-3.593 5.187-5.668 6.987a1 1 0 001.1 1.653c6.922-3.44 7.555-5.44 8.022-6.907a5.441 5.441 0 011.57-2.543 1.863 1.863 0 011.571-.524 7.6 7.6 0 006.411-2.137z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});