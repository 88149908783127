define("ember-svg-jar/inlined/server-server-exchange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-server-exchange</title><path d=\"M9.276 3.947A.508.508 0 009.5 4a.5.5 0 00.3-.1l2-1.5a.5.5 0 000-.8L9.8.1a.505.505 0 00-.524-.048A.5.5 0 009 .5v.25a.25.25 0 01-.25.25H7a3 3 0 00-3 3v1.5a.25.25 0 01-.25.25H3.5a.5.5 0 00-.4.8l1.5 2a.5.5 0 00.8 0l1.5-2a.5.5 0 00-.4-.8h-.25A.25.25 0 016 5.5V4a1 1 0 011-1h1.75a.25.25 0 01.25.25v.25a.5.5 0 00.276.447zM2 14h6a2 2 0 000-4H2a2 2 0 000 4zm2-2a1 1 0 11-1-1 1 1 0 011 1zM2 19h6a2 2 0 000-4H2a2 2 0 000 4zm2-2a1 1 0 11-1-1 1 1 0 011 1zM2 24h6a2 2 0 000-4H2a2 2 0 000 4zm2-2a1 1 0 11-1-1 1 1 0 011 1zM22 0h-6a2 2 0 000 4h6a2 2 0 000-4zm-6 2a1 1 0 111 1 1 1 0 01-1-1zM22 5h-6a2 2 0 000 4h6a2 2 0 000-4zm-6 2a1 1 0 111 1 1 1 0 01-1-1zM22 10h-6a2 2 0 000 4h6a2 2 0 000-4zm-6 2a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});