define("ember-svg-jar/inlined/single-woman-actions-graduate.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-graduate</title><path d=\"M10.712 15.662l1.922-.824a.5.5 0 00.177-.791 7.476 7.476 0 00-12.79 2.811.5.5 0 00.479.642h8.55a.5.5 0 00.49-.4 2 2 0 011.172-1.438zM23.684 17.111l-6-2.383a.5.5 0 00-.369 0l-6 2.383a.5.5 0 00-.315.474v3.38a.75.75 0 101.5 0v-2.153a.25.25 0 01.333-.236l4.5 1.589a.532.532 0 00.167.028.526.526 0 00.166-.028l6-2.118a.5.5 0 00.018-.936z\"/><path d=\"M21.1 22.918a.5.5 0 00.333-.472V20.3a.5.5 0 00-.666-.472l-3.179 1.122a.249.249 0 01-.166 0l-3.18-1.122a.5.5 0 00-.666.472v2.145a.5.5 0 00.333.472l2.573.907a3.075 3.075 0 002.045 0zM1.564 10.442a1 1 0 001.383-.3 5.093 5.093 0 00.454-.9.25.25 0 01.412-.082 4.727 4.727 0 006.786 0 .251.251 0 01.228-.071.255.255 0 01.184.153 5.093 5.093 0 00.454.9 1 1 0 001.681-1.082 4.561 4.561 0 01-.646-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248a4.573 4.573 0 01-.639 2.512 1 1 0 00.297 1.382zM7.206 8.6a2.7 2.7 0 01-2.741-2.352.5.5 0 01.324-.577 5.7 5.7 0 002.245-1.439.25.25 0 01.361 0 5.719 5.719 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.206 8.6z\"/><path d=\"M1.564 10.442a1 1 0 001.383-.3 5.093 5.093 0 00.454-.9.25.25 0 01.412-.082 4.727 4.727 0 006.786 0 .251.251 0 01.228-.071.255.255 0 01.184.153 5.093 5.093 0 00.454.9 1 1 0 001.681-1.082 4.561 4.561 0 01-.646-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248a4.573 4.573 0 01-.639 2.512 1 1 0 00.297 1.382zM7.206 8.6a2.7 2.7 0 01-2.741-2.352.5.5 0 01.324-.577 5.7 5.7 0 002.245-1.439.25.25 0 01.361 0 5.719 5.719 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.206 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});