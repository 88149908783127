define("ember-svg-jar/inlined/paralympics-race", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paralympics-race</title><path d=\"M22.1 17.411a.249.249 0 00-.115.244 1.592 1.592 0 01.018.22 3.75 3.75 0 11-4.585-3.649.25.25 0 00.19-.2l.293-1.567a.248.248 0 00-.062-.216.252.252 0 00-.21-.079 5.754 5.754 0 00-5.033 4.709H9.521a4.96 4.96 0 00-1.857.357l-.359.144A4 4 0 108 19.625c0-.123-.025-.238-.036-.358l.444-.178a2.974 2.974 0 011.113-.214h3.072a5.747 5.747 0 0011.407-1 5.678 5.678 0 00-.286-1.777.251.251 0 00-.467-.022 3 3 0 01-1.147 1.335zM4 21.625a2 2 0 112-2 2 2 0 01-2 2z\"/><circle cx=\"14.75\" cy=\"2.875\" r=\"2.5\"/><path d=\"M22.1 7.036a3.492 3.492 0 00-2.6-1.161 3.052 3.052 0 00-.69.073l-3.7.977a1.5 1.5 0 00-1.088 1.744l.485 2.424a.251.251 0 00.121.168.256.256 0 00.206.019l2.384-.831a.25.25 0 00.162-.285l-.047-.238a.5.5 0 01.363-.581l1.774-.469a.434.434 0 01.395.165.58.58 0 01.086.574l-.927 4.985a1.5 1.5 0 102.949.55l.924-4.935a3.488 3.488 0 00-.797-3.179z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});