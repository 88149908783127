define("ember-svg-jar/inlined/arrow-dot-up-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-dot-up-1</title><circle cx=\"14\" cy=\"21.75\" r=\"1.5\"/><circle cx=\"14\" cy=\"17.75\" r=\"1.5\"/><circle cx=\"12\" cy=\"2.25\" r=\"1.5\"/><circle cx=\"10\" cy=\"21.75\" r=\"1.5\"/><circle cx=\"10\" cy=\"17.75\" r=\"1.5\"/><circle cx=\"10\" cy=\"13.75\" r=\"1.5\"/><circle cx=\"14\" cy=\"13.75\" r=\"1.5\"/><circle cx=\"18\" cy=\"13.75\" r=\"1.5\"/><circle cx=\"22\" cy=\"13.75\" r=\"1.5\"/><circle cx=\"10\" cy=\"9.75\" r=\"1.5\"/><circle cx=\"14\" cy=\"9.75\" r=\"1.5\"/><circle cx=\"18\" cy=\"9.75\" r=\"1.5\"/><circle cx=\"14\" cy=\"5.75\" r=\"1.5\"/><circle cx=\"2\" cy=\"13.75\" r=\"1.5\"/><circle cx=\"6\" cy=\"13.75\" r=\"1.5\"/><circle cx=\"6\" cy=\"9.75\" r=\"1.5\"/><circle cx=\"10\" cy=\"5.75\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});