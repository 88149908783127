define("ember-svg-jar/inlined/composition-16-to-9", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>composition-16-to-9</title><circle cx=\"19.25\" cy=\"11\" r=\".75\"/><circle cx=\"9.75\" cy=\"13\" r=\".75\"/><path d=\"M20.25 4.25H3.75A3.755 3.755 0 000 8v8a3.755 3.755 0 003.75 3.75h16.5A3.755 3.755 0 0024 16V8a3.755 3.755 0 00-3.75-3.75zm1.25 8.25a2.752 2.752 0 01-2.75 2.75.75.75 0 010-1.5 1.245 1.245 0 00.589-.147.188.188 0 00-.089-.353A2.25 2.25 0 1121.5 11zm-7.25-1.25A1.25 1.25 0 1115.5 10a1.25 1.25 0 01-1.25 1.25zM15.5 14a1.25 1.25 0 11-1.25-1.25A1.25 1.25 0 0115.5 14zm-5.75-3.25A2.25 2.25 0 117.5 13v-1.5a2.752 2.752 0 012.75-2.75.75.75 0 010 1.5 1.245 1.245 0 00-.589.147.188.188 0 00.089.353zm-6.05.35a.75.75 0 01-.9-1.2L4 9a1.25 1.25 0 012 1v3.5a.25.25 0 00.25.25.75.75 0 010 1.5h-2a.75.75 0 010-1.5.25.25 0 00.25-.25V11a.25.25 0 00-.4-.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});