define("ember-svg-jar/inlined/hand-drag-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hand-drag-1</title><path d=\"M19 6.5a1.473 1.473 0 00-.61.121.249.249 0 00-.15.229v2.52a.5.5 0 01-1 0V6.132A1.749 1.749 0 0015.5 4.5a1.473 1.473 0 00-.61.121.249.249 0 00-.15.229v4.02a.5.5 0 01-1 0V5.132A1.749 1.749 0 0012 3.5a1.473 1.473 0 00-.61.121.249.249 0 00-.15.229v4.52a.5.5 0 01-1 0V6.132a1.747 1.747 0 00-3.49.116l-.01 6.979a.5.5 0 01-1 0V8.748a.25.25 0 00-.25-.25H5a1.752 1.752 0 00-1.75 1.75v4c0 2.112 3.128 6.25 6.91 6.25h5.34a5.256 5.256 0 005.25-5.25v-7A1.752 1.752 0 0019 6.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});