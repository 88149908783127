define("ember-svg-jar/inlined/amphibian-foot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>amphibian-foot</title><path d=\"M22 7.25a2 2 0 00-2 2 1.972 1.972 0 00.07.495 2.145 2.145 0 01-1.547-.229c-.717-.442-1.581-1.675-1.72-4.937a2.01 2.01 0 10-2.074-.3A3.044 3.044 0 0112 6.225a3.037 3.037 0 01-2.729-1.943 1.994 1.994 0 10-2.076.3C7.057 7.825 6.2 9.06 5.489 9.508a2.146 2.146 0 01-1.558.235A1.956 1.956 0 004 9.25a2 2 0 10-2 2 1.974 1.974 0 00.245-.025l4.309 8.415A6.112 6.112 0 0012 23.25a6.1 6.1 0 005.433-3.59l4.323-8.435a1.974 1.974 0 00.244.025 2 2 0 000-4zm-10 .975a4.518 4.518 0 001.463-.238.5.5 0 01.654.551l-.655 4.175a.5.5 0 01-.579.416 4.974 4.974 0 00-1.766 0 .5.5 0 01-.579-.416l-.655-4.175a.5.5 0 01.654-.551A4.508 4.508 0 0012 8.225zm-5.474 2.993a4.112 4.112 0 00.83-.68.5.5 0 01.857.266l.466 2.973a.5.5 0 01-.2.481 5.842 5.842 0 00-1.104 1.052.5.5 0 01-.835-.086L5.091 12.4a.5.5 0 01.327-.714 3.824 3.824 0 001.108-.468zm10.932 4.006a.5.5 0 01-.835.085 5.831 5.831 0 00-1.105-1.051.5.5 0 01-.2-.482l.466-2.973a.5.5 0 01.858-.266 4.031 4.031 0 00.83.681 3.824 3.824 0 001.108.463.5.5 0 01.327.714z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});