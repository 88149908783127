define("ember-svg-jar/inlined/dislike-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dislike-1</title><path d=\"M0 12.966a2.5 2.5 0 002.5 2.5h6.311a.25.25 0 01.237.328 8.685 8.685 0 00-.52 4.407c.588 2.1 1.834 2.7 2.808 2.565A2 2 0 0013 20.716c0-2.746 2.129-5.746 4.034-7.061a.5.5 0 00.216-.412V3.127a.5.5 0 00-.405-.491 46.964 46.964 0 01-.987-.2 17.361 17.361 0 00-4.108-.471h-6.5C3.292 1.966 3 3.062 3 3.716a2.247 2.247 0 00.231 1 2.251 2.251 0 00-1 3 2.25 2.25 0 00-.959 3.078A2.5 2.5 0 000 12.966zM18.75 13.216a1 1 0 001 1H23a1 1 0 001-1v-11a1 1 0 00-1-1h-3.25a1 1 0 00-1 1zm4.25 0zm-2.75-9.25a.75.75 0 11.75.75.75.75 0 01-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});