define("ember-svg-jar/inlined/social-media-my-space", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-my-space</title><path d=\"M18 9a4.81 4.81 0 00-4.08 2.21 4 4 0 00-7-1.77A3 3 0 001 10v4.5a.5.5 0 00.5.5H6v4.5a.5.5 0 00.5.5H13v3.5a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-9.11A5.09 5.09 0 0018 9z\"/><circle cx=\"18\" cy=\"4\" r=\"4\"/><circle cx=\"10\" cy=\"4\" r=\"3\"/><circle cx=\"4\" cy=\"4\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});