define("ember-svg-jar/inlined/famous-character-black-lightning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character-black-lightning</title><path d=\"M22.533 18.69a15.434 15.434 0 00-7.763-2.765.247.247 0 01-.063-.476A7 7 0 0019 9V7.245A7.166 7.166 0 0012.922.059 7.009 7.009 0 005 7v2a7 7 0 004.293 6.449.247.247 0 01-.063.476 15.434 15.434 0 00-7.763 2.765A2.467 2.467 0 00.5 20.667V23.5a.5.5 0 00.5.5h6.7a.244.244 0 00.241-.267 3.923 3.923 0 011.176-3.084 4.06 4.06 0 012.308-1.115c.241-.028.295.084.212.226l-1.587 2.716a.656.656 0 000 .663.681.681 0 00.588.332H12c.2 0 .051.181.051.181l-.09.146s-.151.2.11.2H13.2a.318.318 0 00.342-.2c.091-.147.4-.658.4-.658a.653.653 0 00.009-.669.682.682 0 00-.592-.338h-1.3c-.178 0-.177-.122-.14-.186l1.218-2.085a.255.255 0 01.3-.114 3.991 3.991 0 011.441.894 3.922 3.922 0 011.177 3.084.244.244 0 00.245.274H23a.5.5 0 00.5-.5v-2.833a2.467 2.467 0 00-.967-1.977zM13 6h2a1.5 1.5 0 010 3 3.823 3.823 0 01-2.5-2.5.5.5 0 01.5-.5zM9 6h2a.5.5 0 01.5.5A3.823 3.823 0 019 9a1.5 1.5 0 010-3zm4.91 7.4a2.484 2.484 0 00-3.82 0 .255.255 0 01-.308.074 5.007 5.007 0 01-2.23-2.21c-.07-.136-.084-.393.147-.335l.73.182a.964.964 0 00.869-.2A4.164 4.164 0 0112 10a4.164 4.164 0 012.7.91.964.964 0 00.869.2s.536-.131.724-.181.225.194.156.329a5.015 5.015 0 01-2.233 2.215.255.255 0 01-.306-.073z\"/><path d=\"M23.562 2.642a.446.446 0 00-.545.125L21.77 4.375a.224.224 0 01-.2.053l-1.032-.344a.451.451 0 00-.584.328l-.766 3.444a.448.448 0 00.246.5.437.437 0 00.2.044.442.442 0 00.35-.169l1.257-1.62s.026-.094.161-.049l1.057.352a.451.451 0 00.584-.328l.766-3.442a.448.448 0 00-.247-.502zM1.174 6.88a.445.445 0 00.365.037s.85-.318 1-.364.178.055.178.055l1.3 1.625a.445.445 0 00.35.169.437.437 0 00.2-.044.449.449 0 00.246-.5l-.765-3.44a.452.452 0 00-.582-.332s-.817.29-.961.35-.269-.107-.269-.107L.983 2.767a.45.45 0 00-.791.377l.765 3.441a.437.437 0 00.217.295z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});