define("ember-svg-jar/inlined/medical-hospital-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-hospital-1</title><path d=\"M8.328 7.7a.5.5 0 00.453-.529C8.661 5.237 8.57 3.6 8.525 2.7a.5.5 0 00-.3-.433C5.7 1.16 2.548 3.9.371 4.539a.519.519 0 00-.35.34.483.483 0 00.11.47c1.32 1.463 4.654 2.68 8.197 2.351zM23.979 4.879a.519.519 0 00-.35-.34C21.452 3.9 18.3 1.16 15.774 2.266a.5.5 0 00-.3.433c-.045.9-.136 2.538-.256 4.475a.5.5 0 00.453.529c3.543.326 6.877-.891 8.2-2.354a.483.483 0 00.108-.47z\"/><path d=\"M16.5 10a1 1 0 000 2 1.5 1.5 0 011.5 1.5c0 1.245-2.261 1.858-4.561 2.345a.252.252 0 01-.216-.056.249.249 0 01-.085-.207l.156-2.076C13.679 8.364 14 2.442 14 2a2 2 0 00-4 0c0 .442.321 6.364.706 11.506l.156 2.076a.251.251 0 01-.3.263C8.26 15.357 6 14.745 6 13.5A1.5 1.5 0 017.5 12a1 1 0 000-2A3.5 3.5 0 004 13.5c0 1.932 1.515 2.949 3.472 3.608a.25.25 0 01.05.451A2.752 2.752 0 006 20a3 3 0 003 3 1 1 0 000-2 1 1 0 01-1-1c0-.688 1.282-1.164 2.791-1.539a.249.249 0 01.309.222c.141 1.739.28 3.232.4 4.857a.5.5 0 00.991 0c.125-1.625.263-3.118.4-4.857a.249.249 0 01.31-.222C14.718 18.836 16 19.312 16 20a1 1 0 01-1 1 1 1 0 000 2 3 3 0 003-3 2.752 2.752 0 00-1.522-2.441.25.25 0 01.05-.451C18.485 16.449 20 15.432 20 13.5a3.5 3.5 0 00-3.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});