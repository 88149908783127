define("ember-svg-jar/inlined/office-file-sheet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-file-sheet</title><path d=\"M21.707 5.705L16.293.291A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.412a1 1 0 00-.293-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25z\"/><rect x=\"6.5\" y=\"10.498\" width=\"3\" height=\"2\" rx=\".5\" ry=\".5\"/><rect x=\"6.5\" y=\"13.998\" width=\"3\" height=\"2\" rx=\".5\" ry=\".5\"/><rect x=\"6.5\" y=\"17.498\" width=\"3\" height=\"2\" rx=\".5\" ry=\".5\"/><rect x=\"11\" y=\"10.498\" width=\"6.5\" height=\"2\" rx=\".5\" ry=\".5\"/><rect x=\"11\" y=\"13.998\" width=\"6.5\" height=\"2\" rx=\".5\" ry=\".5\"/><rect x=\"11\" y=\"17.498\" width=\"6.5\" height=\"2\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});