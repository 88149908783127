define("ember-svg-jar/inlined/house-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>house-user</title><path d=\"M20.5 1.493a.5.5 0 00-.5-.5h-3.5a.5.5 0 00-.5.5v.289a1.441 1.441 0 00.346.861l3.981 4.162c.095.1.173.068.173-.069zM24 22.992a1 1 0 00-1-1h-1.5a.5.5 0 01-.5-.5v-7.5a.5.5 0 01.5-.5H23a1 1 0 00.723-1.691L12.722.3a1.033 1.033 0 00-1.445 0l-11 11.5A1 1 0 001 13.492h1.5a.5.5 0 01.5.5v7.5a.5.5 0 01-.5.5H1a1 1 0 000 2h22a1 1 0 001-1zM12 7.243a2.5 2.5 0 11-2.5 2.5 2.5 2.5 0 012.5-2.5zM9.927 18.734a.627.627 0 00-.592-.491H9a.5.5 0 01-.5-.5v-1a3.5 3.5 0 017 0v1a.5.5 0 01-.5.5h-.335a.628.628 0 00-.592.491s-.379 1.986-.555 2.857a.5.5 0 01-.489.4h-2.057a.5.5 0 01-.49-.4c-.175-.871-.555-2.857-.555-2.857z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});