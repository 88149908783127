define("ember-svg-jar/inlined/dating-makeup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dating-makeup</title><path d=\"M4.687 7.046v.828a1 1 0 002 0v-.841a.123.123 0 01.125-.153A7.545 7.545 0 008.1 6.585c.175-.056.193.082.193.082l.318.677a1.047 1.047 0 001.331.479 1 1 0 00.48-1.331l-.332-.7s-.082-.1.062-.217a7.835 7.835 0 001.028-.863 1 1 0 10-1.418-1.419 5.659 5.659 0 01-4.028 1.669 5.66 5.66 0 01-4.027-1.669A1 1 0 00.293 4.707a7.771 7.771 0 00.929.789.166.166 0 01.093.23l-.36.766a1 1 0 00.48 1.331 1.045 1.045 0 001.331-.479l.334-.707s.027-.139.2-.077a7.648 7.648 0 001.246.3.162.162 0 01.141.186zM23.707 4.707a1 1 0 10-1.414-1.414 5.658 5.658 0 01-4.027 1.669 5.66 5.66 0 01-4.028-1.669 1 1 0 10-1.414 1.414 7.7 7.7 0 00.93.789.167.167 0 01.093.23l-.361.766a1 1 0 00.48 1.331 1.045 1.045 0 001.334-.479l.332-.707s.028-.139.2-.077a7.611 7.611 0 001.245.3.162.162 0 01.145.184v.828a1 1 0 002 0v-.839a.123.123 0 01.125-.153 7.5 7.5 0 001.284-.295c.175-.056.193.082.193.082l.319.677a1.047 1.047 0 001.331.479 1 1 0 00.479-1.331l-.332-.7s-.082-.1.063-.217a7.761 7.761 0 001.023-.868zM20.068 15.177l-2.777-2A5.838 5.838 0 0012 12.356a5.83 5.83 0 00-5.291.817s-2.637 1.9-2.779 2.006a1.083 1.083 0 00-.426.791 1.066 1.066 0 00.336.781l3.067 2.7A5.934 5.934 0 0010.9 21h2.2a5.924 5.924 0 003.982-1.54l3.079-2.709a1.307 1.307 0 00.31-.941.89.89 0 00-.403-.633zM7.879 14.8a3.842 3.842 0 013.739-.447 1.02 1.02 0 00.764 0 3.845 3.845 0 013.739.446s.308.206-.04.206h-8.2c-.252-.005-.002-.205-.002-.205zm8.733 2.414l-.863.76A3.938 3.938 0 0113.1 19h-2.2a3.948 3.948 0 01-2.662-1.042l-.8-.706c-.12-.106-.1-.252.07-.252h9.005c.287 0 .099.209.099.209z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});