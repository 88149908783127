define("ember-svg-jar/inlined/escalator-descend-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>escalator-descend-person</title><path d=\"M7.423 9.031v3.69a.247.247 0 00.42.177l2.474-2.422a.248.248 0 00.075-.177V9.031a1.485 1.485 0 10-2.969 0z\"/><circle cx=\"8.907\" cy=\"3.588\" r=\"2.474\"/><path d=\"M19.025 12.99h1.759a2.969 2.969 0 100-5.938h-3.638a2.967 2.967 0 00-2.152.923l-8.733 8.549a1.475 1.475 0 01-1.039.424H2.969a2.97 2.97 0 000 5.939H7.1a2.988 2.988 0 002.159-.93l8.725-8.543a1.477 1.477 0 011.041-.424zM23.71 15.506a.99.99 0 00-1.4 0l-3.682 3.682-1.028-1.032a.742.742 0 00-1.267.524v3.464a.743.743 0 00.742.743h3.464a.742.742 0 00.525-1.267l-1.032-1.033 3.681-3.681a.989.989 0 00-.003-1.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});