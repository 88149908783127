define("ember-svg-jar/inlined/gmo-food-chicken-leg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gmo-food-chicken-leg</title><circle cx=\"3.545\" cy=\"17.5\" r=\"1\"/><path d=\"M23.662 4.293l-4-4a1 1 0 10-1.414 1.414l.564.564a.5.5 0 010 .707l-.682.682a.5.5 0 01-.707 0l-1.746-1.746a1 1 0 10-1.414 1.414l.759.759a.429.429 0 010 .6.426.426 0 000 .6L16.134 6.4a.5.5 0 11-.707.707l-1.061-1.054a.5.5 0 00-.707 0l-.859.86a.5.5 0 000 .707l1.008 1.009a.5.5 0 01-.707.707l-1.008-1.009a.5.5 0 00-.354-.147.5.5 0 00-.353.147l-.3.3a3 3 0 00-.7 3.129.5.5 0 01-.117.521l-.2.2a.5.5 0 01-.617.071A11.259 11.259 0 003.545 11c-1.995 0-3.5 2.794-3.5 6.5S1.55 24 3.545 24c3.687 0 6.789-1.524 8.194-3.765a.5.5 0 01.424-.235H15.1a2.5 2.5 0 104.613-1.75.5.5 0 010-.5 2.5 2.5 0 10-4.52-2.083.5.5 0 01-.471.333h-2.05a.5.5 0 01-.466-.318 5.336 5.336 0 00-.231-.513.251.251 0 00-.4-.062l-2.9 2.9a.5.5 0 11-.707-.707l3.63-3.63a.5.5 0 01.533-.113 3 3 0 003.194-.68l3.89-3.89a.5.5 0 01.707 0l.707.707a1 1 0 001.414-1.414L20.3 6.532a.5.5 0 010-.707l.682-.681a.5.5 0 01.707 0l.564.563a1 1 0 001.414-1.414zM13.045 17.5h2.768a.75.75 0 00.567-.259.743.743 0 00.175-.6 1.135 1.135 0 01-.01-.144 1 1 0 111.515.857.751.751 0 000 1.286 1 1 0 11-1.515.857 1.135 1.135 0 01.01-.144.743.743 0 00-.175-.6.75.75 0 00-.567-.259h-2.768a.5.5 0 010-1zm-9.5 5c-.815 0-2-1.948-2-5s1.185-5 2-5 2 1.948 2 5-1.184 5-2 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});