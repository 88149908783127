define("ember-svg-jar/inlined/book-close-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-close-1</title><path d=\"M20.166 4.254a1 1 0 00-.781-.247L6.452 5.5a1 1 0 01-.952-1c0-.312 0-.784 1.193-1.018C8.349 3.155 17.532 2 17.625 1.992a1 1 0 00-.25-1.984C17 .056 8.03 1.18 6.307 1.519 3.865 2 3.5 3.593 3.5 4.5V20a3.887 3.887 0 004 4c.1 0 12.124-1.508 12.124-1.508a1 1 0 00.876-.992V5a1 1 0 00-.334-.746z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});