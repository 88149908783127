define("ember-svg-jar/inlined/professions-man-bellboy.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-bellboy</title><path d=\"M11.5 17.318a.249.249 0 00-.22-.248 6.005 6.005 0 01-1.921-.557.25.25 0 00-.359.225v.843a.249.249 0 01-.227.248 16.314 16.314 0 00-7.459 2.765A2.713 2.713 0 00.25 22.769v.356a.75.75 0 00.75.75h10.25a.25.25 0 00.25-.25zM12.5 23.625a.25.25 0 00.25.25H23a.75.75 0 00.75-.75v-.356a2.715 2.715 0 00-1.065-2.176 16.313 16.313 0 00-7.458-2.763.25.25 0 01-.227-.249v-.843a.25.25 0 00-.359-.225 6.005 6.005 0 01-1.921.557.249.249 0 00-.22.248z\"/><circle cx=\"14\" cy=\"9.375\" r=\"1\"/><circle cx=\"10\" cy=\"9.375\" r=\"1\"/><path d=\"M10.457 11.969a.638.638 0 00.317.719 2.31 2.31 0 00.435.18 2.99 2.99 0 00.867.126h.006a2.976 2.976 0 00.863-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2h-2a.639.639 0 00-.624.481z\"/><path d=\"M11.786 16.122h.217a6.994 6.994 0 006.632-4.773A7.029 7.029 0 0019 9.125V3.756A2.977 2.977 0 0017.182 1 13.511 13.511 0 0012 .125 13.511 13.511 0 006.818 1 2.977 2.977 0 005 3.756v5.118a7.32 7.32 0 00.371 2.287 7.082 7.082 0 006.415 4.961zM12 3.375a13.571 13.571 0 014.839.751.25.25 0 01.161.233v1.089a.25.25 0 01-.352.228A11.368 11.368 0 0012 4.875a11.252 11.252 0 00-4.69.825.219.219 0 01-.31-.2V4.359a.25.25 0 01.161-.233A13.571 13.571 0 0112 3.375zM7 8a.25.25 0 01.138-.224A10.844 10.844 0 0112 6.875a10.859 10.859 0 014.862.9A.251.251 0 0117 8v1.125a5 5 0 01-5.155 5A5.171 5.171 0 017 8.874z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});