define("ember-svg-jar/inlined/archive-drawer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>archive-drawer</title><path d=\"M17.5 6.5a.75.75 0 000-1.5h-5a.75.75 0 000 1.5zM9.75 8.75a.75.75 0 00.75.75h7a.75.75 0 000-1.5h-7a.75.75 0 00-.75.75zM10.5 12.5h7a.75.75 0 000-1.5h-7a.75.75 0 000 1.5z\"/><path d=\"M22.5 14h-.75V2a2 2 0 00-2-2H8.25a2 2 0 00-2 2v11.25a1 1 0 002 0V2.5a.5.5 0 01.5-.5h10.5a.5.5 0 01.5.5v11a.5.5 0 01-.5.5H19a1 1 0 00-1 1 1 1 0 01-1 1H7a1 1 0 01-1-1 1 1 0 00-1-1h-.75a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5 1 1 0 000-2h-.5a2 2 0 00-2 2v9H1.5A1.5 1.5 0 000 15.5V22a2 2 0 002 2h20a2 2 0 002-2v-6.5a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});