define("ember-svg-jar/inlined/settings-toggle-horizontal-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g stroke-linecap=\"round\" stroke-width=\"1.5\" fill-rule=\"evenodd\" stroke=\"#000\" fill=\"none\" stroke-linejoin=\"round\"><path d=\"M18.376 10.5H5.626h0A4.888 4.888 0 01.751 5.625v0h0A4.889 4.889 0 015.626.75h12.75a4.89 4.89 0 014.875 4.875v0h0a4.889 4.889 0 01-4.875 4.875zM18.376 23.25H5.626h0a4.888 4.888 0 01-4.875-4.875v0h0A4.889 4.889 0 015.626 13.5h12.75a4.89 4.89 0 014.875 4.875v0h0a4.889 4.889 0 01-4.875 4.875z\"/></g><path fill=\"none\" stroke=\"#000\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M6.952 4.3A1.875 1.875 0 114.3 6.95 1.875 1.875 0 016.952 4.3M19.702 17.05a1.875 1.875 0 11-2.652 2.65 1.875 1.875 0 012.652-2.65\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});