define("ember-svg-jar/inlined/ecology-leaf-book", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ecology-leaf-book</title><path d=\"M20.8 5.174a.5.5 0 01-.3-.458V2a2 2 0 00-2-2h-13A3.5 3.5 0 002 3.5V21a3 3 0 003 3h15a2 2 0 002-2V7a2 2 0 00-1.2-1.826zM14.83 17.42a4.5 4.5 0 01-2.4.788 2.677 2.677 0 01-1.109-.227 2.486 2.486 0 01-.873-.671.5.5 0 01.019-.664 8.266 8.266 0 013.674-2.52.5.5 0 00-.236-.972c-2.57.622-5.252 3.719-6.513 5.865a.75.75 0 01-1.292-.762 20.842 20.842 0 012.7-3.506.5.5 0 00.1-.542 2.5 2.5 0 01-.159-1.591 3.208 3.208 0 012.164-2.107 8.314 8.314 0 012.871-.4 8.062 8.062 0 003.337-.57.5.5 0 01.69.362c.51 2.552-1.246 6.397-2.973 7.517zM18.5 4.5a.5.5 0 01-.5.5H5.5a1.5 1.5 0 010-3H18a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});