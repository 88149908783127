define("ember-svg-jar/inlined/single-neutral-vintage-tv.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-vintage-tv</title><path d=\"M24 3.249a1.5 1.5 0 00-1.5-1.5h-21a1.5 1.5 0 00-1.5 1.5v14.5a1.5 1.5 0 001.5 1.5h.8a.25.25 0 01.2.406l-.775.969a1 1 0 101.562 1.25L5.2 19.44a.5.5 0 01.393-.191h12.819a.5.5 0 01.393.191l1.914 2.434a1 1 0 001.562-1.25l-.775-.969a.25.25 0 01.2-.406h.8a1.5 1.5 0 001.5-1.5zm-15.492 1.5a3 3 0 11-3.012 3 3.006 3.006 0 013.012-3zm5.007 11.667a.305.305 0 01-.085.233.31.31 0 01-.228.1H4.017a.5.5 0 01-.5-.556 5.021 5.021 0 0110 .223zm4.485.333a.5.5 0 01-1 0V4a.5.5 0 111 0zm4.25-2a.75.75 0 01-.75.75h-1a.75.75 0 010-1.5h1a.75.75 0 01.75.75zM21.5 12.5h-1a.75.75 0 010-1.5h1a.75.75 0 010 1.5zM21 8.749a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});