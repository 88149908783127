define("ember-svg-jar/inlined/dislike-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dislike-2</title><path d=\"M.13 11.753A3 3 0 003 15.627h4.9a1 1 0 01.967 1.256l-.881 3.343a2.712 2.712 0 004.839 2.253l4.89-6.928a1 1 0 01.818-.424h2.055a1.5 1.5 0 001.408 1h1.5a.5.5 0 00.5-.5v-14a.5.5 0 00-.5-.5H22a1.5 1.5 0 00-1.5 1.5v.627C15.378.678 14.257.373 10.788.373c-.351 0-2.334.02-3.054.02-2.8 0-4.665 1.613-5.695 4.958l-1.9 6.357c0 .015-.005.03-.009.045zm1.91.593l.009-.028 1.909-6.4c.76-2.469 1.89-3.521 3.776-3.521.728 0 1.367-.007 1.94-.013 4.175-.046 4.587-.046 10.551 2.975a.5.5 0 01.275.441v6.826a.5.5 0 01-.5.5h-1.464a3.006 3.006 0 00-2.452 1.27L11.2 21.324a.739.739 0 01-1.138.039.712.712 0 01-.135-.627l.882-3.343a3 3 0 00-2.9-3.766H3a1 1 0 01-.959-1.281z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});