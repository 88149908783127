define("ember-svg-jar/inlined/gesture-tap-expand-all-directions-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-tap-expand-all-directions-1</title><path d=\"M11.506 7.993a1 1 0 00-1 1v2.833a.25.25 0 01-.414.189c-1.037-.9-2.461-.335-1.586.978l2.353 2.353a.5.5 0 00.354.146h2.925a.5.5 0 00.477-.35c.242-.775.676-2.16.784-2.63.334-1.446-1.251-1.631-2.7-1.973a.249.249 0 01-.193-.243v-1.3a1 1 0 00-1-1.003zM11.063 1.829a1.5 1.5 0 011.874 0l1.791 1.432a.25.25 0 00.344-.03.722.722 0 00.136-.231.749.749 0 00-.239-.833l-2.5-2a.748.748 0 00-.937 0l-2.5 2A.747.747 0 008.793 3a.715.715 0 00.135.232.25.25 0 00.345.031zM12.937 22.171a1.5 1.5 0 01-1.874 0l-1.79-1.432a.25.25 0 00-.345.031.7.7 0 00-.135.23.747.747 0 00.239.833l2.5 2a.75.75 0 00.937 0l2.5-2a.749.749 0 00.239-.833.722.722 0 00-.136-.233.249.249 0 00-.344-.03zM23.835 11.031l-2-2.5A.749.749 0 0021 8.292a.728.728 0 00-.232.136.25.25 0 00-.03.345l1.432 1.79a1.5 1.5 0 010 1.874l-1.432 1.79a.249.249 0 00.03.344.693.693 0 00.232.136.749.749 0 00.834-.238l2-2.5a.752.752 0 00.001-.938zM1.829 12.437a1.5 1.5 0 010-1.874l1.432-1.79a.25.25 0 00-.03-.345A.728.728 0 003 8.292a.751.751 0 00-.834.239l-2 2.5a.752.752 0 000 .938l2 2.5a.749.749 0 00.834.238.693.693 0 00.231-.135.251.251 0 00.032-.345z\"/><path d=\"M9.032 4.415A.75.75 0 009.5 5.75h5a.75.75 0 00.469-1.335l-2.5-2a.748.748 0 00-.937 0zM14.5 18.25h-5a.75.75 0 00-.468 1.335l2.5 2a.75.75 0 00.937 0l2.5-2a.75.75 0 00-.469-1.335zM5.75 9a.75.75 0 00-1.335-.469l-2 2.5a.752.752 0 000 .938l2 2.5A.751.751 0 005.75 14zM21.585 11.969a.752.752 0 000-.938l-2-2.5A.75.75 0 0018.25 9v5a.75.75 0 001.335.469z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});