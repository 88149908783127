define("ember-svg-jar/inlined/award-wall-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>award-wall-star</title><path d=\"M24 7.791a2 2 0 00-3.851-.75H3.851a2.009 2.009 0 10-.1 1.7v8.877A2.842 2.842 0 004.919 19.8l5.833 3.837a2.361 2.361 0 002.492 0L19.08 19.8a2.84 2.84 0 001.166-2.179V8.739A1.993 1.993 0 0024 7.791zm-8.928 9.849a.524.524 0 01-.746.655l-2.2-1.237a.249.249 0 00-.245 0L9.678 18.3a.524.524 0 01-.746-.655l.889-2.045a.248.248 0 00-.063-.287l-1.6-1.413a.489.489 0 01.344-.855h1.835a.25.25 0 00.23-.151l.964-2.236a.529.529 0 01.941 0l.963 2.236a.249.249 0 00.229.151H15.5a.49.49 0 01.346.855l-1.6 1.415a.25.25 0 00-.064.287z\"/><path d=\"M11.839 2.144a.249.249 0 01.321 0l4.008 3.34a.251.251 0 00.16.057h2.343a.249.249 0 00.235-.164.251.251 0 00-.075-.278L13.28.474a2 2 0 00-2.56 0L5.169 5.1a.251.251 0 00-.075.278.249.249 0 00.235.164h2.343a.248.248 0 00.16-.058z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});