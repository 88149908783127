define("ember-svg-jar/inlined/landmark-chandi-borobudur", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-chandi-borobudur</title><path d=\"M3.5 12.985a.813.813 0 00-.791.64l-.279 1.3a.5.5 0 00.566.6 3.215 3.215 0 011.008 0 .5.5 0 00.566-.6l-.278-1.3a.815.815 0 00-.792-.64zM4 4.525a3.215 3.215 0 011 0 .5.5 0 00.566-.6l-.278-1.3a.809.809 0 00-1.583 0l-.279 1.3a.5.5 0 00.574.6zM19 4.525a3.215 3.215 0 011.008 0 .5.5 0 00.566-.6l-.278-1.3a.809.809 0 00-1.583 0l-.279 1.3a.5.5 0 00.566.6zM20.5 12.985a.813.813 0 00-.791.64l-.279 1.3a.5.5 0 00.566.6 3.215 3.215 0 011.008 0 .5.5 0 00.566-.6l-.278-1.3a.815.815 0 00-.792-.64zM1.5 11.985h5a.5.5 0 00.5-.5v-3.5a2.5 2.5 0 10-5 0v2.086a.5.5 0 01-.333.471 1.006 1.006 0 00-.667.943.5.5 0 00.5.5zM6.333 21.542A.5.5 0 016 21.071v-2.086a2.5 2.5 0 00-5 0v2.086a.5.5 0 01-.333.471 1 1 0 00-.667.943.5.5 0 00.5.5h6a.5.5 0 00.5-.5 1 1 0 00-.667-.943zM17.5 11.985h5a.5.5 0 00.5-.5 1.006 1.006 0 00-.667-.943.5.5 0 01-.333-.471V7.985a2.5 2.5 0 10-5 0v3.5a.5.5 0 00.5.5zM23.333 21.542a.5.5 0 01-.333-.471v-2.086a2.5 2.5 0 00-5 0v2.086a.5.5 0 01-.333.471 1 1 0 00-.667.943.5.5 0 00.5.5h6a.5.5 0 00.5-.5 1 1 0 00-.667-.943zM6.5 15.485a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h11a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5.5.5 0 01-.5-.5.5.5 0 00-.5-.5h-9a.5.5 0 00-.5.5.5.5 0 01-.5.5zM8.5 13.485h7a.5.5 0 00.5-.5v-2.5a4.316 4.316 0 00-1.339-3.107.5.5 0 01-.161-.368v-.525a1 1 0 00-1-1h-3a1 1 0 00-1 1v.525a.5.5 0 01-.161.367A4.319 4.319 0 008 10.485v2.5a.5.5 0 00.5.5zM11 4.485h2a.5.5 0 00.464-.685l-1-2.5a.52.52 0 00-.928 0l-1 2.5a.5.5 0 00.464.685z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});