define("ember-svg-jar/inlined/road-sign-side-road-right-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-side-road-right-1-alternate</title><path d=\"M11.293 23.707a1 1 0 001.414 0l11-11a1 1 0 000-1.414l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414zm-8.7-11.53a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354l-9.232 9.232a.25.25 0 01-.354 0z\"/><path d=\"M12.53 7.47l2 2a.75.75 0 01-.53 1.28h-.75A.25.25 0 0013 11v.482a.249.249 0 00.073.177l2.634 2.634a1 1 0 11-1.414 1.414l-.866-.866a.25.25 0 00-.427.177V17a1 1 0 01-2 0v-6a.25.25 0 00-.25-.25H10a.75.75 0 01-.53-1.28l2-2a.749.749 0 011.06 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});