define("ember-svg-jar/inlined/construction-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>construction-building</title><path d=\"M18 5.408a1 1 0 00-1-1h-5.453a.5.5 0 01-.319-.116L6.539.4a1.691 1.691 0 00-2.245.342L.281 4.691A1.38 1.38 0 000 5.515v3.393a1.5 1.5 0 001.5 1.5H2a.5.5 0 01.5.5v8.48a.5.5 0 01-.5.5H1a1 1 0 00-1 1v2a1 1 0 001 1h22a1 1 0 001-1v-9a1 1 0 00-1-1h-3a1 1 0 00-1 1v2.5a.5.5 0 01-.5.5h-.75a.5.5 0 01-.5-.5v-2.25a1 1 0 10-2 0v2.25a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-2.25a1 1 0 10-2 0v2.25a.5.5 0 01-.5.5H11a1 1 0 00-1 1v1.5a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5v-8.48a.5.5 0 01.5-.5h6.5a.5.5 0 01.5.5v.5a1 1 0 002 0zm-16 1.5a.5.5 0 01.5-.5h13.005A.5.5 0 0116 6.9v1.008a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5zm3.3-4.35a.5.5 0 01.671-.029l1.2.994a.5.5 0 01-.32.885h-2.2a.5.5 0 01-.351-.856zM5 19.888a.5.5 0 01-.5-.5v-8.48a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v8.48a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});