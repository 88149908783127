define("ember-svg-jar/inlined/car-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-2</title><path d=\"M22.384 9.875H23a1 1 0 001-1v-1a1 1 0 00-1-1h-1.35a1.508 1.508 0 00-1.2.6.095.095 0 01-.168-.038l-.48-2.4a3.007 3.007 0 00-2.942-2.412H7.14A3.007 3.007 0 004.2 5.036l-.48 2.4a.095.095 0 01-.168.038 1.508 1.508 0 00-1.2-.6H1a1 1 0 00-1 1v1a1 1 0 001 1h.616a.25.25 0 01.152.449A4.46 4.46 0 000 13.875v3a1.5 1.5 0 00.857 1.349.25.25 0 01.143.226v1.425a1.5 1.5 0 001.5 1.5h2a1.5 1.5 0 001.5-1.5v-1.25a.25.25 0 01.25-.25h11.5a.25.25 0 01.25.25v1.25a1.5 1.5 0 001.5 1.5h2a1.5 1.5 0 001.5-1.5V18.45a.25.25 0 01.143-.226A1.5 1.5 0 0024 16.875v-3a4.46 4.46 0 00-1.768-3.551.25.25 0 01.152-.449zm-16.617 2.9L3.4 14.51c-.044.031-.088.06-.133.087A.84.84 0 012 13.874a2.985 2.985 0 01.4-1.5 1 1 0 01.867-.5h2.2a.5.5 0 01.3.9zm9.2-.211l-1.336 3.196a1 1 0 01-.923.615h-1.416a1 1 0 01-.923-.615l-1.331-3.193a.5.5 0 01.462-.692h5a.5.5 0 01.462.692zM5.675 9.375a.25.25 0 01-.246-.3l.73-3.647a1 1 0 01.981-.8h9.72a1 1 0 01.981.8l.73 3.647a.25.25 0 01-.246.3zm15.9 5.229a.838.838 0 01-.844-.007 1.915 1.915 0 01-.134-.087l-2.365-1.732a.5.5 0 01.3-.9h2.2a1 1 0 01.867.5 2.985 2.985 0 01.4 1.5.84.84 0 01-.423.722z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});