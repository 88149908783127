define("ember-svg-jar/inlined/share-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>share-2-alternate</title><path d=\"M11.642 8.666a.249.249 0 00.235.334H14.5a.5.5 0 01.5.5v12a.5.5 0 01-.5.5h-12a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h2.791a.249.249 0 00.249-.23 8.542 8.542 0 01.245-1.457A.251.251 0 005.543 7H2a2 2 0 00-2 2v13a2 2 0 002 2h13a2 2 0 002-2V9a2 2 0 00-2-2h-1a2.5 2.5 0 00-2.358 1.666z\"/><path d=\"M23.705 4.706a1 1 0 000-1.414l-3-3A1 1 0 0019 1v1.25a.25.25 0 01-.25.25H14a7.008 7.008 0 00-7 7v2a1.5 1.5 0 003 0v-2a4.005 4.005 0 014-4h4.75a.25.25 0 01.25.25V7a1 1 0 001.707.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});