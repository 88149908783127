define("ember-svg-jar/inlined/file-code-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-settings</title><path d=\"M17.751 11.532v3.5a.75.75 0 001.5 0v-3.5a.75.75 0 00-1.5 0zM13 4.282a.75.75 0 00-.75.75v3.5a.75.75 0 001.5 0v-3.5a.75.75 0 00-.75-.75zM20.251 8.032v-2.5A1.252 1.252 0 0019 4.282h-1.5a1.252 1.252 0 00-1.25 1.25v2.5a1.251 1.251 0 001.25 1.25H19a1.251 1.251 0 001.251-1.25zm-1.5-.5a.25.25 0 01-.25.25H18a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25z\"/><path d=\"M23.415 3.032L21 .618a2 2 0 00-1.414-.586H8a2 2 0 00-2 2v6.294a.245.245 0 00.214.247 7.875 7.875 0 011.45.318A.248.248 0 008 8.658V2.532a.5.5 0 01.5-.5h10.88a.5.5 0 01.353.147L21.855 4.3a.5.5 0 01.145.353v13.379a.5.5 0 01-.5.5h-7.358a.25.25 0 00-.238.316 3.224 3.224 0 01.022 1.376.25.25 0 00.239.307H22a2 2 0 002-2V4.447a2 2 0 00-.585-1.415z\"/><path d=\"M12.019 15.623a1.783 1.783 0 00-1.7-2.953L8.908 13a.45.45 0 01-.534-.311l-.424-1.395a1.775 1.775 0 00-3.4 0l-.424 1.392A.456.456 0 013.6 13l-1.416-.33a1.783 1.783 0 00-1.7 2.953l.988 1.065a.464.464 0 010 .626l-.989 1.064a1.782 1.782 0 001.7 2.953L3.594 21a.454.454 0 01.534.311l.424 1.392a1.774 1.774 0 003.4 0l.424-1.391A.454.454 0 018.907 21l1.411.327a1.782 1.782 0 001.7-2.953l-.988-1.064a.464.464 0 010-.626zM6.251 20a3 3 0 113-3 3 3 0 01-3 3z\"/><circle cx=\"6.251\" cy=\"17.001\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});