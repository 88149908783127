define("ember-svg-jar/inlined/data-file-bars-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-bars-info</title><rect x=\"14.5\" y=\"5.5\" width=\"2\" height=\"8\" rx=\".5\" ry=\".5\"/><rect x=\"18\" y=\"7.5\" width=\"2\" height=\"6\" rx=\".5\" ry=\".5\"/><path d=\"M21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.275a.247.247 0 00.242.25c.366.007 1.038.034 1.485.082A.245.245 0 008 9.359V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.245.245 0 00-.241.21 7.966 7.966 0 01-.364 1.459.243.243 0 00.228.33H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M12.5 10h-1a.5.5 0 00-.5.5v.229a.325.325 0 00.156.271 8.012 8.012 0 011.41 1.3.25.25 0 00.434-.171V10.5a.5.5 0 00-.5-.5zM6.5 11a6.5 6.5 0 106.5 6.5A6.508 6.508 0 006.5 11zM6 13.249a1 1 0 11-1 1 1 1 0 011-1zm2 8.5H5a.75.75 0 010-1.5h.627a.123.123 0 00.123-.123v-2.251a.125.125 0 00-.125-.125H5a.75.75 0 010-1.5h1a1.252 1.252 0 011.25 1.25v2.623a.127.127 0 00.126.127H8a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});