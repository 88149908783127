define("ember-svg-jar/inlined/protection-shield-3-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>protection-shield-3-alternate</title><path d=\"M23.044 4.132a2.472 2.472 0 00-1.494-2.26A24.144 24.144 0 0012 0a24.156 24.156 0 00-9.549 1.871A2.474 2.474 0 00.956 4.132V11.537a12.858 12.858 0 008.517 11.809l1.072.395a4.216 4.216 0 002.91 0l1.072-.395a12.858 12.858 0 008.513-11.809V11.511v-.074zm-2 0v6.139a.249.249 0 01-.25.25H13.25a.249.249 0 01-.25-.25V2.293a.25.25 0 01.263-.25 21.778 21.778 0 017.487 1.662.466.466 0 01.294.427zM11 10.271a.249.249 0 01-.25.25h-2.5a.249.249 0 01-.25-.25V2.548a.249.249 0 01.211-.248 23.943 23.943 0 012.526-.258.247.247 0 01.185.068.251.251 0 01.078.183zM3.251 3.7a19.127 19.127 0 012.431-.851.251.251 0 01.318.245v7.177a.249.249 0 01-.25.25H3.206a.249.249 0 01-.25-.25V4.132a.468.468 0 01.295-.432zm6.913 17.765a10.928 10.928 0 01-7.107-8.663.25.25 0 01.247-.285h7.446a.25.25 0 01.25.25v8.647a.25.25 0 01-.337.235zm3.672 0l-.5.184a.25.25 0 01-.336-.231v-8.647a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25v7.483a.253.253 0 01-.118.213 11.254 11.254 0 01-2.046 1.002zm4.6-3.177a.25.25 0 01-.436-.16v-5.357a.25.25 0 01.25-.25h2.45a.25.25 0 01.247.285 10.592 10.592 0 01-2.508 5.486z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});