define("ember-svg-jar/inlined/single-man-actions-address.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-address</title><path d=\"M7.087 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.743a.5.5 0 01.742-.3A6.956 6.956 0 008.547 5a6.214 6.214 0 00.929-.071.5.5 0 01.569.565A3 3 0 014.087 5a2.841 2.841 0 01.1-.743zM10.852 12.113a.5.5 0 00-.155-.761 7.341 7.341 0 00-3.4-.852 7.543 7.543 0 00-7.189 5.357.5.5 0 00.479.642h7.956a.5.5 0 00.493-.416 8.466 8.466 0 011.816-3.97zM17.337 10.5a6.753 6.753 0 00-1.76 13.315 7.074 7.074 0 001.587.185 6.7 6.7 0 001.735-.227 1 1 0 10-.513-1.933 4.873 4.873 0 01-2.362.028 4.726 4.726 0 01-3.484-3.495 4.793 4.793 0 01.947-4.132 4.71 4.71 0 013.8-1.74 4.922 4.922 0 014.627 5.015v.811a.8.8 0 01-1.594 0v-1.078a3.157 3.157 0 10-3.156 3.156 3.1 3.1 0 001.476-.377.251.251 0 01.312.063 2.793 2.793 0 004.962-1.764v-.811a6.932 6.932 0 00-6.577-7.016zm-.174 7.9a1.157 1.157 0 111.156-1.157 1.158 1.158 0 01-1.156 1.163z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});