define("ember-svg-jar/inlined/single-neutral-actions-check-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-check-1</title><path d=\"M0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.568a.25.25 0 00.182-.078.254.254 0 00.068-.187c0-.078-.008-.156-.008-.235a7.948 7.948 0 011.345-4.43.25.25 0 00.036-.19A5.5 5.5 0 000 13.75z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M17.5 23.75a6.5 6.5 0 10-6.5-6.5 6.508 6.508 0 006.5 6.5zm3.5-9.219a1 1 0 01.2 1.4l-3.148 4.2a1.807 1.807 0 01-1.322.721h-.131a1.806 1.806 0 01-1.281-.534L13.7 18.7a1 1 0 011.414-1.414l1.272 1.27a.248.248 0 00.376-.027l2.845-3.794a1 1 0 011.398-.204zM0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.568a.25.25 0 00.182-.078.254.254 0 00.068-.187c0-.078-.008-.156-.008-.235a7.948 7.948 0 011.345-4.43.25.25 0 00.036-.19A5.5 5.5 0 000 13.75z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M17.5 23.75a6.5 6.5 0 10-6.5-6.5 6.508 6.508 0 006.5 6.5zm3.5-9.219a1 1 0 01.2 1.4l-3.148 4.2a1.807 1.807 0 01-1.322.721h-.131a1.806 1.806 0 01-1.281-.534L13.7 18.7a1 1 0 011.414-1.414l1.272 1.27a.248.248 0 00.376-.027l2.845-3.794a1 1 0 011.398-.204z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});