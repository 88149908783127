define("ember-svg-jar/inlined/smart-watch-circle-sound-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-sound-alternate</title><path d=\"M21.5 12A9.5 9.5 0 0017 3.927a.245.245 0 01-.109-.148L15.983.373A.5.5 0 0015.5 0h-7a.5.5 0 00-.484.371l-.908 3.408A.245.245 0 017 3.927a9.5 9.5 0 000 16.149.25.25 0 01.109.148l.908 3.407A.5.5 0 008.5 24h7a.5.5 0 00.482-.368l.935-3.428a.247.247 0 01.109-.146A9.5 9.5 0 0021.5 12zM12 19.5a7.5 7.5 0 117.5-7.5 7.509 7.509 0 01-7.5 7.5z\"/><path d=\"M16.3 8.281a.75.75 0 00-1.168.942 4.39 4.39 0 010 5.558.75.75 0 001.168.942 5.881 5.881 0 000-7.442z\"/><path d=\"M14.584 9.781a.75.75 0 00-1.168.941 2.026 2.026 0 010 2.559.75.75 0 001.168.941 3.514 3.514 0 000-4.441zM12 15.5a.5.5 0 00.5-.5V9a.5.5 0 00-.5-.5h-.5a3.5 3.5 0 00-3.083 1.866.251.251 0 01-.217.134H7a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h1.2a.249.249 0 01.221.133A3.5 3.5 0 0011.5 15.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});