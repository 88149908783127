define("ember-svg-jar/inlined/water-pitcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-pitcher</title><path d=\"M8.355 5.476a.5.5 0 01.5-.468h5.605a2.984 2.984 0 001.64-.485c.875-.574 1.559-1.017 2.112-1.377a.351.351 0 01.526.4c-.809 2.511 1.084 8.324 1.835 11.338a.5.5 0 01-.485.621H6.843a.5.5 0 01-.485-.623c.818-3.223 1.784-6.1 1.997-9.406zm14.577 10.85c-.687-3.9-2.275-7.2-2.382-11.525 0-.452.023-.416.747-2.8a1.543 1.543 0 00-.655-1.759 1.51 1.51 0 00-1.7.041C17.5 1.225 16.7 1.737 15 2.851a.975.975 0 01-.541.158H8.38a1.986 1.986 0 00-2 1.916c-.011.263-.033.512-.058.758a.5.5 0 01-.5.449H3.9a3 3 0 00-3 3v5.119a1 1 0 002 0V9.132a1 1 0 011-1h1.482a.5.5 0 01.489.6c-.542 2.556-1.4 5.077-1.894 7.572C2.446 23.934 8.932 24 9.069 24H17.6c2.474 0 6.353-1.908 5.332-7.674z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});