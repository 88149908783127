define("ember-svg-jar/inlined/concert-beach", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>concert-beach</title><path d=\"M16.25 4h.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.5a.25.25 0 01.25-.25h.5a.75.75 0 000-1.5h-.5a.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25h-.5a.75.75 0 000 1.5z\"/><circle cx=\"16.25\" cy=\"7.75\" r=\".75\"/><circle cx=\"22.25\" cy=\".75\" r=\".75\"/><path d=\"M23.655 18c-3.546-3.07-11.54-3-11.655-3a30.164 30.164 0 00-3.5.22.5.5 0 01-.542-.365A13.862 13.862 0 017.5 11.25a13.752 13.752 0 01.548-3.909.25.25 0 01.4-.115l4.218 3.713a.55.55 0 00.9-.291 4.531 4.531 0 00-1.394-3.98A5.4 5.4 0 0010.094 5.3a.25.25 0 01-.037-.455l4.5-2.471a.548.548 0 00-.009-.966 4.579 4.579 0 00-4.137.351A5.355 5.355 0 008.5 3.48a.25.25 0 01-.443-.049 5.349 5.349 0 00-1.494-2.1A4.579 4.579 0 002.6.094a.548.548 0 00-.217.942l3.338 2.925a.249.249 0 01-.18.437 5.408 5.408 0 00-2.56.506A4.533 4.533 0 00.213 8.091a.551.551 0 00.723.6l4.4-1.542a.25.25 0 01.3.354A14.962 14.962 0 004 14.5q0 .618.045 1.232a.5.5 0 01-.34.51A10.085 10.085 0 00.35 18a1 1 0 00-.35.76v1.074a.25.25 0 00.308.244A2.979 2.979 0 011 20c.5 0 .822-.281 1.355-.913a3.022 3.022 0 014.6-.023c.553.654.875.936 1.375.936s.822-.281 1.356-.913a3.021 3.021 0 014.6-.023c.554.655.876.936 1.376.936s.822-.282 1.356-.914a3.022 3.022 0 014.6-.022c.553.655.875.936 1.375.936a2.979 2.979 0 01.692.081.25.25 0 00.315-.244V18.76a1 1 0 00-.345-.76z\"/><path d=\"M23 22a3.747 3.747 0 01-2.9-1.645 1.055 1.055 0 00-1.546.022A3.72 3.72 0 0115.667 22a3.745 3.745 0 01-2.9-1.645 1.055 1.055 0 00-1.547.023A3.72 3.72 0 018.333 22a3.747 3.747 0 01-2.9-1.645 1.055 1.055 0 00-1.547.023A3.718 3.718 0 011 22a1 1 0 000 2 5.121 5.121 0 003.345-1.243.5.5 0 01.64 0 5.128 5.128 0 006.694 0 .5.5 0 01.639 0 5.128 5.128 0 006.694 0 .5.5 0 01.64 0A5.131 5.131 0 0023 24a1 1 0 000-2zM19.4 9.268a1.5 1.5 0 101.44 1.885l.789-2.974A.248.248 0 0121.812 8a.251.251 0 01.245.078l.631.714a.75.75 0 001.124-.992l-1.25-1.414a1.25 1.25 0 00-2.145.507L19.885 8.9a.5.5 0 01-.485.368z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});