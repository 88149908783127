define("ember-svg-jar/inlined/safety-exit-door-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-exit-door-left</title><path d=\"M20.565 3.052a.5.5 0 00-.545.109l-1.914 1.914a.5.5 0 000 .707L20.02 7.7a.5.5 0 00.854-.354v-.668a.249.249 0 01.25-.25h1.642a1 1 0 000-2h-1.642a.249.249 0 01-.25-.25v-.664a.5.5 0 00-.309-.462zM8.455 22.766a1 1 0 00-1-1l-4.718.039a.5.5 0 01-.5-.5L2.192 2.733a.5.5 0 01.5-.5l11.4-.039a.5.5 0 01.5.5v.933a1 1 0 002 0V2.191A1.957 1.957 0 0014.631.234H2.191A1.957 1.957 0 00.234 2.191v19.618a1.957 1.957 0 001.957 1.957h5.264a1 1 0 001-1z\"/><path d=\"M19.269 20.3l2.909-.265a1.435 1.435 0 00-.12-2.865 1.339 1.339 0 00-.14.007l-2.91.264h-.037a.479.479 0 01-.433-.264L16.551 13.2a.252.252 0 01.008-.237.247.247 0 01.2-.124h.145a2.689 2.689 0 012.106 1.016 1.436 1.436 0 002.242-1.794 5.553 5.553 0 00-4.345-2.1 5.61 5.61 0 00-2.066.4l-2.165.857a1.91 1.91 0 01-1.912-.342l-.839-.72a1.435 1.435 0 10-1.868 2.18l.839.72A4.777 4.777 0 0012.01 14.2a4.889 4.889 0 001.477-.23.251.251 0 01.3.126l.635 1.27a.249.249 0 01.013.191.252.252 0 01-.125.145l-1.294.647a3.369 3.369 0 00-1.835 3.329l.252 2.515a1.435 1.435 0 001.426 1.293c.048 0 .1 0 .145-.007a1.435 1.435 0 001.286-1.571l-.252-2.516a.481.481 0 01.263-.475l1.293-.647a.25.25 0 01.336.112l.04.08a3.327 3.327 0 002.988 1.852c.103-.002.206-.007.311-.014zM11.929 9.566a2.427 2.427 0 00.554.063c.063 0 .124-.021.186-.026a2.372 2.372 0 001.082-.338A2.5 2.5 0 0014 9.079a2.361 2.361 0 10-2.067.487z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});