define("ember-svg-jar/inlined/mask-star-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mask-star-alternate</title><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2zm-2 19.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M6.965 13.735a.25.25 0 01.067.29L5.72 17.044a1.875 1.875 0 00.472 2.168 1.911 1.911 0 001.285.5 1.94 1.94 0 00.955-.255l3.446-1.941a.251.251 0 01.246 0l3.446 1.944a1.9 1.9 0 002.24-.248 1.875 1.875 0 00.471-2.168l-1.312-3.02a.249.249 0 01.066-.289l2.384-2.047a1.857 1.857 0 00-1.306-3.209H15.4a.25.25 0 01-.225-.14l-1.451-2.991-.008-.015A1.911 1.911 0 0012 4.283a1.922 1.922 0 00-1.724 1.064L8.821 8.339a.249.249 0 01-.224.14H5.885a1.844 1.844 0 00-1.746 1.146 1.91 1.91 0 00.5 2.111zm2.414-3.256a1 1 0 00.9-.562L11.754 6.8a.25.25 0 01.451 0l1.515 3.117a1 1 0 00.9.562h2.465a.25.25 0 01.163.44l-2.214 1.9a1 1 0 00-.265 1.158l1.277 2.94a.251.251 0 01-.352.318l-3.2-1.8a1 1 0 00-.982 0l-3.228 1.858a.251.251 0 01-.355-.316l1.3-3a1 1 0 00-.265-1.158l-2.216-1.9a.25.25 0 01.162-.44z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});