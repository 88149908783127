define("ember-svg-jar/inlined/monitor-heart.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-heart</title><path d=\"M10.03 13.78a5 5 0 011.57-1.826.25.25 0 00-.145-.453H2.5A.5.5 0 012 11V2.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v8.537a.25.25 0 00.169.259 4.973 4.973 0 011.2.584.249.249 0 00.27 0c.077-.047.156-.09.235-.133a.248.248 0 00.126-.213V2a2 2 0 00-2-2H2a2 2 0 00-2 2v10.5a2 2 0 002 2h5.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5H5a1 1 0 000 2h4.548a.249.249 0 00.236-.332 5.013 5.013 0 01.246-3.888z\"/><path d=\"M23.628 14.45a3.527 3.527 0 00-5.647-.916l-.305.3a.25.25 0 01-.353 0l-.3-.3a3.525 3.525 0 00-4.993 4.977l5.114 5.335a.5.5 0 00.721 0l5.107-5.327a3.522 3.522 0 00.656-4.069z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});