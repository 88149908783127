define("ember-svg-jar/inlined/iris-scan-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>iris-scan-user</title><circle cx=\"12\" cy=\"7\" r=\"1.5\"/><path d=\"M12.632 13.8a5.745 5.745 0 018.658-3.68.248.248 0 00.29-.018 20.64 20.64 0 001.844-1.685 2.036 2.036 0 000-2.837C20.777 2.839 16.359-.08 12 0 7.622-.082 3.222 2.84.579 5.58a2.038 2.038 0 000 2.837C1.765 9.651 6.191 13.944 12.147 14c.084 0 .169 0 .254-.008a.249.249 0 00.231-.192zM12 11a4 4 0 114-4 4 4 0 01-4 4z\"/><path d=\"M20.064 19.306a.251.251 0 01-.028-.465 4.253 4.253 0 10-3.618-.024.25.25 0 01-.034.464 6.122 6.122 0 00-3.891 3.7.75.75 0 00.7 1.018h10a.751.751 0 00.7-1.018 6.129 6.129 0 00-3.829-3.675zM16 15a2.149 2.149 0 01.126-.72.251.251 0 01.365-.131 5.652 5.652 0 002.945.851 5.225 5.225 0 00.764-.056.25.25 0 01.286.266A2.248 2.248 0 0116 15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});