define("ember-svg-jar/inlined/messages-bubble-search-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-search-1</title><path d=\"M16.5 9.5a6.981 6.981 0 015.454 2.628.25.25 0 00.409-.027A7.992 7.992 0 0015.5 0H8a7.992 7.992 0 00-3 15.4v4.1a.5.5 0 00.854.354l3.594-3.595a.247.247 0 00.072-.159 7 7 0 016.98-6.6z\"/><path d=\"M23.707 22.293l-2.534-2.535a.25.25 0 01-.034-.311 5.507 5.507 0 10-1.691 1.692.25.25 0 01.311.034l2.534 2.534a1 1 0 001.414-1.414zM16.5 13a3.5 3.5 0 11-3.5 3.5 3.5 3.5 0 013.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});