define("ember-svg-jar/inlined/business-deal-cash-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-deal-cash-3</title><path d=\"M12 12.5a6.25 6.25 0 10-6.25-6.25A6.257 6.257 0 0012 12.5zm1.167-5.625h-2.334a1.792 1.792 0 010-3.583h.292a.25.25 0 00.25-.25V2.75a.625.625 0 011.25 0v.292a.25.25 0 00.25.25h1.458a.625.625 0 010 1.25h-3.5a.542.542 0 000 1.083h2.334a1.792 1.792 0 010 3.584h-.292a.249.249 0 00-.25.25v.291a.625.625 0 01-1.25 0v-.291a.249.249 0 00-.25-.25H9.667a.625.625 0 110-1.25h3.5a.542.542 0 000-1.084z\"/><circle cx=\"20.5\" cy=\"10\" r=\"2.5\"/><path d=\"M20.5 13a3.5 3.5 0 00-3.5 3.5v2a.5.5 0 00.5.5h.822a.25.25 0 01.248.225L19 23.55a.5.5 0 00.5.45h2a.5.5 0 00.5-.45l.433-4.324a.25.25 0 01.246-.226h.821a.5.5 0 00.5-.5v-2a3.5 3.5 0 00-3.5-3.5z\"/><circle cx=\"3.5\" cy=\"10\" r=\"2.5\"/><path d=\"M.5 19h.822a.25.25 0 01.248.225L2 23.55a.5.5 0 00.5.45h2a.5.5 0 00.5-.45l.433-4.324A.25.25 0 015.678 19H6.5a.5.5 0 00.5-.5v-2a3.5 3.5 0 00-7 0v2a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});