define("ember-svg-jar/inlined/single-neutral-actions-edit-2.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-edit-2</title><path d=\"M13.95 15.569a.5.5 0 00.1-.571 7.533 7.533 0 00-6.735-4.257A7.544 7.544 0 00.127 16.1a.5.5 0 00.479.642h11.965a.5.5 0 00.354-.146z\"/><circle cx=\"7.106\" cy=\"4.991\" r=\"4.75\"/><path d=\"M19.635 12.006l-6.5 6.5a.5.5 0 000 .707l3 3a.5.5 0 00.707 0l6.5-6.5a2.621 2.621 0 00-3.707-3.707zM12.5 20.7a.5.5 0 00-.843.255l-.537 2.685a.5.5 0 00.136.451.5.5 0 00.452.137l2.684-.537a.5.5 0 00.256-.844z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});