define("ember-svg-jar/inlined/landmark-mountain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-mountain</title><path d=\"M17 4.56c-3.195 0-4.935 3.8-5.882 8.048a.5.5 0 00.181.5c3.448 2.681 3.9 9.359 3.947 10.288v.1a.5.5 0 00.5.5h7.211A1.038 1.038 0 0024 22.94c-.091-4.31-.851-18.38-7-18.38zm1.979 5.81a.717.717 0 01-1.029 0l-.43-.43a.749.749 0 00-1.04 0l-.43.43a.718.718 0 01-1.03 0l-.747-.746a.5.5 0 01-.107-.549C14.8 7.569 15.717 6.5 17 6.5s2.2 1.069 2.833 2.575a.5.5 0 01-.107.549z\"/><path d=\"M8.025 13.5c-4.473 0-5.482 7.223-5.685 9.437A.976.976 0 003.312 24h9.937a.5.5 0 00.5-.525c-.02-.407-.558-9.975-5.724-9.975zM2.614 8h6.871a.773.773 0 00.1-.006 3.086 3.086 0 00.04-6.131A3.964 3.964 0 006.223 0a4.161 4.161 0 00-4 3.019A2.5 2.5 0 000 5.5 2.449 2.449 0 002.6 8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});