define("ember-svg-jar/inlined/video-game-sling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-sling</title><path d=\"M19.72.249c-2.1-.661-4.132.038-4.681 1.587a10.128 10.128 0 00-6.026 2.908L7.628 6.128A4.032 4.032 0 015.756 7.19a.5.5 0 00-.349.318l-.2.549a.5.5 0 00.562.658 5.5 5.5 0 002.92-1.527l1.385-1.383a8.629 8.629 0 014.945-2.456 4.233 4.233 0 002.9 2.624 4.375 4.375 0 003 0 5.754 5.754 0 01-5.673 4.854 5.155 5.155 0 01-.715-.053.5.5 0 00-.54.328l-.168.475a.5.5 0 00.39.661 6.479 6.479 0 001.033.089 7.258 7.258 0 007.25-7.25c0-.134-.024-.264-.031-.4a2.23 2.23 0 00.172-.363C23.14 2.707 21.86.923 19.72.249z\"/><path d=\"M12.561 11.067a.5.5 0 00.639-.3l.425-1.2a1.063 1.063 0 00-2-.711l-.425 1.2a.5.5 0 00.305.638zM3.693 7.92a.5.5 0 00.638-.3l.425-1.2a1.062 1.062 0 00-2-.711l-.425 1.2a.5.5 0 00.3.638zM9.075 15.618a3.7 3.7 0 003.053-1.952.5.5 0 00.022-.413.5.5 0 00-.3-.289l-1.615-.572a2.937 2.937 0 01-2.528 1.032 4.725 4.725 0 01-2.795-.991 3.121 3.121 0 01-1.3-2.085.5.5 0 00-.331-.424l-1.293-.459a.5.5 0 00-.413.036.5.5 0 00-.244.335 3.692 3.692 0 001.14 3.439l1.541 1.395-2.56 7.215a1.583 1.583 0 002.174 1.96 1.566 1.566 0 00.811-.9L7 15.73z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});