define("ember-svg-jar/inlined/player-phone-station-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>player-phone-station-1</title><path d=\"M21.5 11h-2.75a.25.25 0 00-.25.25v12.5a.25.25 0 00.25.25h2.75a2.5 2.5 0 002.5-2.5v-8a2.5 2.5 0 00-2.5-2.5zm1 4a1.5 1.5 0 11-1.5-1.5 1.5 1.5 0 011.5 1.5zM21 18.5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zM17 18.1a.25.25 0 00-.383-.212 3.973 3.973 0 01-2.117.612h-5a3.973 3.973 0 01-2.117-.608A.25.25 0 007 18.1v5.65a.25.25 0 00.25.25h9.5a.25.25 0 00.25-.25zM5.5 11.25a.25.25 0 00-.25-.25H2.5A2.5 2.5 0 000 13.5v8A2.5 2.5 0 002.5 24h2.75a.25.25 0 00.25-.25zM3 16.5A1.5 1.5 0 114.5 15 1.5 1.5 0 013 16.5zM4.5 20A1.5 1.5 0 113 18.5 1.5 1.5 0 014.5 20zM17 2.5A2.5 2.5 0 0014.5 0h-5A2.5 2.5 0 007 2.5v12A2.5 2.5 0 009.5 17h5a2.5 2.5 0 002.5-2.5zM9.25 13a.25.25 0 01-.25-.25v-8.5A.25.25 0 019.25 4h5.5a.25.25 0 01.25.25v8.5a.25.25 0 01-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});