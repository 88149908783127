define("ember-svg-jar/inlined/love-it-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it-sync</title><path d=\"M22.739 17.572a1 1 0 00-1.27.623A4.568 4.568 0 0113.6 19.6l1.6-1.605a1 1 0 00-.707-1.707H11a1 1 0 00-1 1v3.5a1 1 0 001.707.707l.472-.472a6.574 6.574 0 0011.184-2.178 1 1 0 00-.624-1.273zM23.381 10.862a1 1 0 00-1.089.217l-.472.472a6.575 6.575 0 00-11.185 2.178 1 1 0 001.893.648 4.569 4.569 0 017.869-1.4l-1.6 1.605a1 1 0 00.707 1.707H23a1 1 0 001-1v-3.5a1 1 0 00-.619-.927z\"/><path d=\"M5.813 2.777A4.575 4.575 0 019.807 4.06l1.531 1.4a1 1 0 001.348 0l1.561-1.424a4.546 4.546 0 013.965-1.256 4.5 4.5 0 013.329 2.42 4.111 4.111 0 01.334 2.9.253.253 0 00.119.279l1.337.757a.251.251 0 00.214.015.248.248 0 00.147-.151 6.119 6.119 0 00-.367-4.7A6.589 6.589 0 0012.87 2.582l-.858.783-.829-.755A6.526 6.526 0 005.491.8 6.478 6.478 0 00.7 4.293a6.4 6.4 0 001.2 7.454l6.165 6.683a.25.25 0 00.434-.17v-2.211a.247.247 0 00-.067-.169l-5.1-5.523A4.421 4.421 0 012.483 5.2a4.5 4.5 0 013.33-2.423z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});