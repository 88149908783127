define("ember-svg-jar/inlined/lock-7", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lock-7</title><path d=\"M22 19.375h-1.25a.25.25 0 00-.25.25v2.5a.25.25 0 00.25.25H22a1.5 1.5 0 000-3zM19 19.375v-.011a1.5 1.5 0 00-1.5-1.493h-.75v-2.246a.25.25 0 00-.25-.25h-2a.25.25 0 00-.25.25v2a.25.25 0 01-.25.25H6a.25.25 0 01-.25-.25V6.875a4.25 4.25 0 018.5 0v6.75a.25.25 0 00.25.25h2a.25.25 0 00.25-.25v-6.75a6.75 6.75 0 00-13.5 0v11H1.993A1.5 1.5 0 00.5 19.378l.018 3a1.5 1.5 0 001.5 1.493h15.507a1.466 1.466 0 001.052-.446 1.49 1.49 0 00.423-1.05z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});