define("ember-svg-jar/inlined/snail-shell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>snail-shell</title><path d=\"M12.494 1.187A12.444 12.444 0 000 13.093a1.007 1.007 0 00.277.729 1 1 0 00.716.3h1.688a.248.248 0 00.176-.073.252.252 0 00.072-.176 8.492 8.492 0 011.89-5.388 9.772 9.772 0 017.6-3.574 7.6 7.6 0 017.7 7.671 6.813 6.813 0 01-6.708 6.738A5.518 5.518 0 017.7 13.757a4.066 4.066 0 013.362-4.083 3.655 3.655 0 014.24 2.167.746.746 0 01-1.384.555 2.194 2.194 0 00-2.574-1.258 2.579 2.579 0 00-2.153 2.619 4.061 4.061 0 004.223 4.076 5.3 5.3 0 005.218-5.248A6.131 6.131 0 0012.423 6.4a8.269 8.269 0 00-6.439 3.019A7 7 0 004.5 14.51a9.28 9.28 0 009.231 8.3A10.283 10.283 0 0024 12.542 11.5 11.5 0 0012.494 1.187z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});