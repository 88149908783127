define("ember-svg-jar/inlined/book-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-heart</title><path d=\"M20.15 4.04a.5.5 0 01-.4-.489V2a2 2 0 00-2-2H5.25a3 3 0 00-3 3v18a3 3 0 003 3h14.5a2 2 0 002-2V6a2 2 0 00-1.6-1.96zM16.4 14.443l-4.041 4.216a.5.5 0 01-.721 0l-4.047-4.223a2.89 2.89 0 01-.535-3.336 2.9 2.9 0 014.637-.753l.307.308.308-.306a2.894 2.894 0 014.636.752 2.891 2.891 0 01-.544 3.342zM17.75 3.75a.25.25 0 01-.25.25H5.25a1 1 0 010-2H17.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});