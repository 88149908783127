define("ember-svg-jar/inlined/wolf-howl-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wolf-howl-1</title><path d=\"M16.718 10.3l-3.146 2.647A16.6 16.6 0 0014.5 9a2.717 2.717 0 00-1.842-2.475.5.5 0 00-.6.25A23.04 23.04 0 0110.1 9.7c-.995 1.328-1.422 1.41-2.2 1.561a7.931 7.931 0 00-1.061.264c-1.395.465-2.36 2.306-2.686 3.013a3.859 3.859 0 00-2.009 1.108 19.814 19.814 0 00-2.06 2.577.5.5 0 00-.072.386.5.5 0 00.229.319 5.923 5.923 0 001.738.656 20.05 20.05 0 01-.926 3.692A.5.5 0 001.5 24h14a.5.5 0 00.355-.853A5.547 5.547 0 0114.5 19.5a6.126 6.126 0 011.854-4.646 9.3 9.3 0 01.864-.77l.01-.009a2.481 2.481 0 00.372-3.5c-.223-.275-.5-.595-.882-.275zM8.25 13.25a1 1 0 11-1 1 1 1 0 011-1zM15.25 3.25a1 1 0 000 2 3.5 3.5 0 013.5 3.5 1 1 0 002 0 5.507 5.507 0 00-5.5-5.5z\"/><path d=\"M16 0a1 1 0 000 2 6.006 6.006 0 016 6 1 1 0 002 0 8.009 8.009 0 00-8-8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});