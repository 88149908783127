define("ember-svg-jar/inlined/plane-trip-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-trip-person</title><circle cx=\"9.376\" cy=\"3.008\" r=\"2.5\"/><path d=\"M23.43 4.926l-2.555-1.7V1a1 1 0 00-2 0v2.223l-2.555 1.7a1 1 0 101.11 1.667l1.445-.964v2.1l-1.055.7a1 1 0 101.11 1.664l.945-.63.945.63a1 1 0 101.11-1.664l-1.055-.7v-2.1l1.445.964a1 1 0 101.11-1.664zM15.375 10.5a1.572 1.572 0 01-1.493-.823A5.442 5.442 0 009.258 7C3.861 7 3 11.925 2.889 13.237a.25.25 0 00.249.271h2.493a.249.249 0 00.25-.236 3.349 3.349 0 011.613-2.766.249.249 0 01.253-.006.252.252 0 01.128.219v2.728a.251.251 0 00.159.233 2.5 2.5 0 011.591 2.328v1.842a.25.25 0 00.483.09c.021-.056.043-.111.063-.167a14.455 14.455 0 013.281 5.176A1.5 1.5 0 0016.3 22a18.585 18.585 0 00-5.423-7.71v-2.917a.242.242 0 01.449-.127 4.537 4.537 0 004.049 2.254 1.5 1.5 0 000-3zM4.653 22.508a.251.251 0 00-.245.3 1.5 1.5 0 002.667.59c.025-.033.157-.21.357-.5a.25.25 0 00-.215-.391H4.653z\"/><rect x=\".125\" y=\"15.008\" width=\"8\" height=\"6\" rx=\"1\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});