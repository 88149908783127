define("ember-svg-jar/inlined/bird", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bird</title><path d=\"M4 10.75a7.926 7.926 0 01.731-3.319.25.25 0 00-.107-.324l-1.58-.86A2 2 0 101.131 9.76l.044.023a2.506 2.506 0 001.2 2.929l1.71.931a.25.25 0 00.281-.028.253.253 0 00.075-.273A7.921 7.921 0 014 10.75zM23.669 7.047a2 2 0 00-2.713-.8l-1.58.86a.25.25 0 00-.107.324 7.91 7.91 0 01.3 5.911.25.25 0 00.356.3l1.71-.931a2.506 2.506 0 001.2-2.929l.044-.023a2 2 0 00.8-2.713z\"/><path d=\"M15.081 17.026a7 7 0 10-6.162 0L7.7 18.65a1 1 0 101.6 1.2s1.491-1.97 1.593-2.2a6.431 6.431 0 002.214 0c.1.226 1.593 2.2 1.593 2.2a1 1 0 001.6-1.2zM14.75 7.75A1.25 1.25 0 1113.5 9a1.25 1.25 0 011.25-1.25zM8 9a1.25 1.25 0 111.25 1.25A1.25 1.25 0 018 9zm3.68 5.979l-1.858-2.233a.417.417 0 01.109-.642l1.641-.984a.92.92 0 01.858 0l1.641.985a.417.417 0 01.109.642l-1.86 2.232a.4.4 0 01-.64 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});