define("ember-svg-jar/inlined/transfusion-human", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transfusion-human</title><circle cx=\"4.25\" cy=\"7.5\" r=\"2.75\"/><path d=\"M4.25 11.25a4 4 0 00-4 4v3a.5.5 0 00.5.5h.663a.5.5 0 01.5.458l.34 4.084a.5.5 0 00.5.458h3a.5.5 0 00.5-.458l.34-4.084a.5.5 0 01.5-.458h.657a.5.5 0 00.5-.5v-3a4 4 0 00-4-4zM23.75 23a1 1 0 00-1-1h-1.5a.5.5 0 01-.5-.5V3a3 3 0 00-3-3h-3a1 1 0 00-1 1v1a.5.5 0 01-.5.5 1 1 0 000 2h.062a.438.438 0 110 .875h-.062a1.5 1.5 0 00-1.5 1.5v4.25a1.5 1.5 0 001.5 1.5h.5v.125a3.989 3.989 0 01-3.579 3.978.748.748 0 00-.671.746v.5a.749.749 0 00.749.749h.072a5.974 5.974 0 005.429-5.973v-.125h.5a1.5 1.5 0 001.5-1.5v-4.25a1.5 1.5 0 00-1.5-1.5h-.063a.438.438 0 010-.875h.063a1 1 0 000-2H16a.25.25 0 010-.5h1.75a1 1 0 011 1v18.5a.5.5 0 01-.5.5h-1.5a1 1 0 000 2h6a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});