define("ember-svg-jar/inlined/zip-file-rar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>zip-file-rar</title><path d=\"M16 4.5A1.5 1.5 0 0017.5 6h3.9a.25.25 0 00.177-.427L16.427.427A.25.25 0 0016 .6zM12 13.125a.877.877 0 00-.875.875v.623a.252.252 0 00.252.252h1.249a.249.249 0 00.249-.249V14a.877.877 0 00-.875-.875zM7.875 14A.877.877 0 007 13.125h-.626a.249.249 0 00-.249.249v1.252a.249.249 0 00.249.249H7A.877.877 0 007.875 14z\"/><path d=\"M17.5 7A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.25a.25.25 0 00-.25-.25zM7.118 16.233l1.824 1.826a.624.624 0 11-.884.882S6.242 17.133 6.213 17.1s-.088-.037-.088.02v1.38a.625.625 0 01-1.25 0v-6a.625.625 0 01.625-.625H7a2.124 2.124 0 01.206 4.239c-.097 0-.13.078-.088.119zm7.007 2.267a.625.625 0 01-1.25 0v-2.126a.249.249 0 00-.249-.249h-1.25a.251.251 0 00-.251.251V18.5a.625.625 0 01-1.25 0V14a2.125 2.125 0 014.25 0zM17 11.875a2.124 2.124 0 01.206 4.239c-.1 0-.13.078-.088.119l1.824 1.826a.624.624 0 11-.884.882s-1.816-1.808-1.845-1.843-.088-.037-.088.02V18.5a.625.625 0 01-1.25 0v-6a.625.625 0 01.625-.625z\"/><path d=\"M16.374 13.125a.249.249 0 00-.249.249v1.252a.249.249 0 00.249.249H17a.875.875 0 000-1.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});