define("ember-svg-jar/inlined/road-sign-roundabout-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-roundabout -alternate</title><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/><path d=\"M16.932 12.619l-.022-.007a.249.249 0 01-.176-.26c.01-.117.016-.234.016-.352a4.772 4.772 0 00-2.35-4.1.75.75 0 00-.757 1.3A3.266 3.266 0 0115.25 12a.077.077 0 01-.1.074l-.13-.04a.75.75 0 00-.881 1.07l.664 1.249a.75.75 0 001.033.3L17.065 14a.75.75 0 00-.133-1.379zM8.75 12a3.234 3.234 0 011.522-2.734.166.166 0 01.15-.013.162.162 0 01.1.115.747.747 0 00.607.57.657.657 0 00.124.011.75.75 0 00.636-.352l.749-1.2a.778.778 0 00-.238-1.033l-1.2-.749a.75.75 0 00-1.128.8v.023a.249.249 0 01-.135.281A4.754 4.754 0 007.25 12a.75.75 0 001.5 0zM13.654 14.8a3.228 3.228 0 01-3.156.076.171.171 0 01-.037-.277.751.751 0 00-.486-1.3l-1.413-.049a.785.785 0 00-.775.724l-.05 1.413A.751.751 0 009 15.962l.019-.018a.249.249 0 01.311-.023 4.735 4.735 0 005.088.169.75.75 0 10-.763-1.291z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});