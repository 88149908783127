define("ember-svg-jar/inlined/arrow-dot-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-dot-up</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-3 11.5A1.5 1.5 0 1118 10a1.5 1.5 0 011.5 1.5zM10 17a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0110 17zm1.5 2.5A1.5 1.5 0 1110 18a1.5 1.5 0 011.5 1.5zM10 13a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0110 13zm0-4a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0110 9zm.5-5.5A1.5 1.5 0 1112 5a1.5 1.5 0 01-1.5-1.5zM14 17a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0114 17zm1.5 2.5A1.5 1.5 0 1114 18a1.5 1.5 0 011.5 1.5zM14 13a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0114 13zm0-4a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0114 9zm-6.5 2.5A1.5 1.5 0 116 10a1.5 1.5 0 011.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});