define("ember-svg-jar/inlined/js-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>js-1</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM11.125 17.5a2.625 2.625 0 01-5.25 0 .625.625 0 011.25 0 1.375 1.375 0 002.75 0v-6a.625.625 0 111.25 0zm2.68-3.047l1.949.975a2.479 2.479 0 01-1.108 4.7H12.5a.625.625 0 010-1.25h2.146a1.229 1.229 0 00.549-2.329l-1.95-.974a2.48 2.48 0 011.109-4.7H16.5a.625.625 0 010 1.25h-2.146a1.229 1.229 0 00-.549 2.328z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});