define("ember-svg-jar/inlined/office-printer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-printer</title><path d=\"M23.75 19a.25.25 0 00.25-.25V14.5a1.5 1.5 0 00-1.5-1.5h-3a.5.5 0 00-.354.147l-1.78 1.78a.249.249 0 01-.177.073H15.25a.25.25 0 01-.25-.25v-4.5a.25.25 0 01.25-.25h7.25A1.5 1.5 0 0024 8.5V7a1.5 1.5 0 00-1.5-1.5H10.536a.25.25 0 01-.067-.49l10.8-3.047a1 1 0 10-.54-1.927L1.324 5.509A1.5 1.5 0 000 7v11.75a.25.25 0 00.25.25zM11 10.5A1.5 1.5 0 019.5 12H5a1.5 1.5 0 01-1.5-1.5V9a.5.5 0 01.5-.5h6.5a.5.5 0 01.5.5zM.25 20.5a.25.25 0 00-.25.25v1.75A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-1.75a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});