define("ember-svg-jar/inlined/launch-go", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>launch-go</title><path d=\"M15.36 12.96a1.2 1.2 0 001.2-1.2V7.92a1.2 1.2 0 00-2.4 0v3.841a1.2 1.2 0 001.2 1.199z\"/><path d=\"M1.44 24a1.44 1.44 0 001.44-1.439V17.53a.251.251 0 01.25-.25h17.74a.251.251 0 01.25.25v5.031a1.44 1.44 0 002.88 0V1.44a1.44 1.44 0 00-2.88 0v1.19a.249.249 0 01-.25.25H3.13a.249.249 0 01-.25-.25V1.44a1.44 1.44 0 00-2.88 0v21.121A1.44 1.44 0 001.44 24zM12.72 7.92a2.64 2.64 0 015.28 0v3.841a2.64 2.64 0 01-5.28 0zM6 8.16a2.64 2.64 0 015.28 0 .72.72 0 01-1.44 0 1.2 1.2 0 10-2.4 0V12a1.2 1.2 0 102.4 0 .239.239 0 00-.24-.239h-.48a.721.721 0 010-1.441h1.44a.721.721 0 01.72.72V12A2.64 2.64 0 116 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});