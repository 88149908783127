define("ember-svg-jar/inlined/design-tool-paper-glue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-tool-paper-glue</title><path d=\"M13.455 5.533a1 1 0 00-1.414 0l-7.249 7.25a1.5 1.5 0 000 2.12L9.084 19.2a1.5 1.5 0 002.122 0l7.249-7.249a1 1 0 000-1.414zM8.8 13.788l4-4a1 1 0 111.411 1.412l-4 4A1 1 0 118.8 13.788zM4.207 15.782a1.029 1.029 0 00-1.414 0L1.171 17.4a3.714 3.714 0 000 5.414 3.82 3.82 0 002.707 1.176 3.82 3.82 0 002.707-1.176L8.206 21.2a1 1 0 000-1.414zM23.416 4.158L19.83.572A2.051 2.051 0 0017 .573l-2.083 2.085a2 2 0 000 2.828L18.5 9.071a2 2 0 002.828 0l2.086-2.085a2 2 0 00.002-2.828zm-5.177-1.994a.25.25 0 01.354 0l.735.735a.249.249 0 010 .353L17.6 4.984a.249.249 0 01-.353 0l-.736-.735a.252.252 0 010-.354zm1.854 5.317a.252.252 0 01-.354 0l-.729-.73a.249.249 0 010-.353l1.732-1.732a.252.252 0 01.354 0l.729.73a.25.25 0 010 .353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});