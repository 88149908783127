define("ember-svg-jar/inlined/folder-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-warning</title><path d=\"M11.59 17.866l3.3-6.292a2.948 2.948 0 013.337-1.486 3.057 3.057 0 011.934 1.587l.419.8c.048.09.419.173.419-.157V4.5A1.5 1.5 0 0019.5 3H9.619a.5.5 0 01-.448-.276L8.223.829A1.5 1.5 0 006.882 0H1.5A1.5 1.5 0 000 1.5v15A1.5 1.5 0 001.5 18h9.868a.25.25 0 00.222-.134z\"/><path d=\"M23.836 21.905l-5.055-9.634a1.449 1.449 0 00-2.561 0L11.165 21.9a1.428 1.428 0 00.047 1.409 1.457 1.457 0 001.233.691h10.111a1.458 1.458 0 001.233-.686 1.428 1.428 0 00.047-1.409zM16.751 16a.75.75 0 011.5 0v2.5a.75.75 0 01-1.5 0zm.75 6.25a1 1 0 111-1 1 1 0 01-1.001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});