define("ember-svg-jar/inlined/arrow-thick-down-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-down-2</title><path d=\"M22.334 10.127a2.6 2.6 0 00-3.689-.066l-3.716 3.872a.25.25 0 01-.43-.173V2.139A2.277 2.277 0 0012 .1c-.817 0-2.516.449-2.5 2v11.681a.251.251 0 01-.427.177l-3.741-3.73a2.616 2.616 0 00-3.659.1 2.6 2.6 0 00-.1 3.659l9.717 9.623a.988.988 0 00.7.289H12a.993.993 0 00.705-.3l9.7-9.791a2.61 2.61 0 00-.071-3.681z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});