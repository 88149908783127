define("ember-svg-jar/inlined/single-man-actions-music.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-music</title><path d=\"M7.044 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.742a.5.5 0 01.741-.3A6.967 6.967 0 008.5 5a6.363 6.363 0 00.93-.07.5.5 0 01.57.564A3 3 0 014.044 5a2.792 2.792 0 01.1-.742z\"/><path d=\"M12.456 13.83a3.318 3.318 0 01.1-.776.5.5 0 00-.143-.48A7.481 7.481 0 00.065 15.858a.5.5 0 00.479.642h11.412a.5.5 0 00.5-.5zM23.956 19.5v-6.795a2 2 0 00-2.368-1.966l-6 1.125a2 2 0 00-1.631 1.966v4.931a.249.249 0 01-.273.249 2.416 2.416 0 00-.227-.01 2.5 2.5 0 102.5 2.5v-7.256a.5.5 0 01.408-.491l5-.937a.5.5 0 01.592.491v3.454a.249.249 0 01-.273.249 2.416 2.416 0 00-.227-.01 2.5 2.5 0 102.5 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});