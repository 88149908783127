define("ember-svg-jar/inlined/barcode-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>barcode-search</title><path d=\"M3.969 12.484H2.5a.5.5 0 01-.5-.5v-1.468a1 1 0 00-2 0v1.968a2 2 0 002 2h1.969a1 1 0 100-2zM1 4.984a1 1 0 001-1V2.516a.5.5 0 01.5-.5h1.469a1 1 0 100-2H2a2 2 0 00-2 2v1.968a1 1 0 001 1zM18.031 2.047H19.5a.5.5 0 01.5.5v1.469a1 1 0 002 0V2.047a2 2 0 00-2-2h-1.969a1 1 0 000 2zM23.707 23.691a1 1 0 000-1.414l-1.9-1.895a.251.251 0 01-.036-.307 5 5 0 10-1.684 1.684.248.248 0 01.306.037l1.9 1.895a1 1 0 001.414 0zM14.5 17.484a3 3 0 113 3 3 3 0 01-3-3zM5 3.516a1 1 0 00-1 1v5.5a1 1 0 002 0v-5.5a1 1 0 00-1-1zM9 3.516a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zM13 7.516a1 1 0 001-1v-2a1 1 0 10-2 0v2a1 1 0 001 1zM9 8.516a1 1 0 00-1 1v.5a1 1 0 002 0v-.5a1 1 0 00-1-1zM13 11.016a1 1 0 001-1v-.5a1 1 0 10-2 0v.5a1 1 0 001 1zM17 11.016a1 1 0 001-1v-5.5a1 1 0 10-2 0v5.5a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});