define("ember-svg-jar/inlined/real-estate-market-building-fall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-market-building-fall</title><path d=\"M9.028 14.028a.5.5 0 00.776-.054l.966-1.383a3.745 3.745 0 014.092-1.446.5.5 0 00.638-.481V6.5a.512.512 0 00-.28-.45l-3-1.5a.487.487 0 00-.44 0l-3 1.5a.512.512 0 00-.28.45v6.763a.5.5 0 00.134.341zM10.5 7h3a.5.5 0 110 1h-3a.5.5 0 010-1zm0 2.5h3a.5.5 0 010 1h-3a.5.5 0 010-1zM23.19 2.541l-6-2.5a.493.493 0 00-.47.04.508.508 0 00-.22.419v11.42a.5.5 0 00.133.339c.38.413 1.458 1.624 1.858 2.073a.5.5 0 00.374.168H23a.5.5 0 00.5-.5V3a.5.5 0 00-.31-.459zM21.5 10.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 110 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1z\"/><path d=\"M23.037 18.558a.749.749 0 00-.817.163l-.649.649a.5.5 0 01-.73-.025l-5.319-6.091a2.257 2.257 0 00-3.522.2L9.965 16.35a.5.5 0 01-.774.054L2.664 9.4a1.25 1.25 0 10-1.829 1.7l7.156 7.678a2.249 2.249 0 003.486-.236l2.02-2.87a.5.5 0 01.785-.041l4.831 5.533a.5.5 0 01-.023.682l-.87.871A.75.75 0 0018.75 24h4a.75.75 0 00.75-.75v-4a.75.75 0 00-.463-.692z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});