define("ember-svg-jar/inlined/skull-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skull-1</title><path d=\"M12 0A10.261 10.261 0 001.75 10.25a10.154 10.154 0 003 7.235v3.765a2.749 2.749 0 005 1.58 2.748 2.748 0 004.5 0 2.749 2.749 0 005-1.58v-3.765a10.15 10.15 0 003-7.235A10.261 10.261 0 0012 0zm2.25 10.25a2.25 2.25 0 112.25 2.25 2.25 2.25 0 01-2.25-2.25zm-.5 6.868a.25.25 0 01-.22.132h-3.063a.25.25 0 01-.208-.389l1.533-2.3a.249.249 0 01.416 0l1.533 2.3a.252.252 0 01.012.257zm-8.5-6.868A2.25 2.25 0 117.5 12.5a2.25 2.25 0 01-2.25-2.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});