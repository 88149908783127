define("ember-svg-jar/inlined/image-file-bmp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-bmp</title><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.427A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6zM7.5 17.125h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H7.5a.875.875 0 000-1.75zM17.5 14.125h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25h.625a.875.875 0 000-1.75zM7.5 14.125h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H7.5a.875.875 0 000-1.75z\"/><path d=\"M22 7.25a.25.25 0 00-.25-.25H17.5A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2zM9.149 16.661a2.13 2.13 0 01.412 1.867 2.174 2.174 0 01-2.137 1.6H6a.625.625 0 01-.625-.625v-6A.625.625 0 016 12.875h1.424a2.174 2.174 0 012.137 1.6 2.13 2.13 0 01-.412 1.867.248.248 0 000 .319zm5.476 2.839a.625.625 0 01-1.25 0v-3.064c0-.107-.108-.073-.144 0l-.172.343a.65.65 0 01-1.118 0s-.138-.261-.172-.343-.144-.094-.144 0V19.5a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279s.731 1.474.8 1.606.206.139.276 0l.8-1.606a.625.625 0 011.184.279zm2.75-2.375h-.5a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0v-6a.625.625 0 01.625-.625h1.5a2.128 2.128 0 012.114 2.347 2.213 2.213 0 01-2.239 1.903z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});