define("ember-svg-jar/inlined/diagram-increase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-increase</title><path d=\"M0 19.919a1.44 1.44 0 001.44 1.44h8.935a9.119 9.119 0 008.825-6.834l1.474-5.694a.249.249 0 01.3-.18l1.252.3a1.44 1.44 0 001.556-2.158l-2.14-3.466a1.474 1.474 0 00-.744-.594 1.4 1.4 0 00-1.214.114L16.2 4.993a1.44 1.44 0 00.425 2.627l1.064.252a.25.25 0 01.185.306L16.416 13.8a6.24 6.24 0 01-6.041 4.676H1.44A1.44 1.44 0 000 19.919z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});