define("ember-svg-jar/inlined/folder-edit-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-edit-alternate</title><path d=\"M12.077 16.5H2.5A.5.5 0 012 16V2.5a.5.5 0 01.5-.5h4.573a.5.5 0 01.447.277L8.33 3.9A2 2 0 0010.118 5H19a.5.5 0 01.5.5v4.092a.247.247 0 00.349.223 4.072 4.072 0 011.409-.3.243.243 0 00.242-.241V5a2 2 0 00-2-2h-9.073a.5.5 0 01-.447-.276l-.81-1.619A2 2 0 007.382 0H2a2 2 0 00-2 2v14.5a2 2 0 002 2h8.609a.236.236 0 00.176-.072c.232-.24.99-1.013 1.472-1.5a.25.25 0 00-.18-.428zM12.062 20.132a.25.25 0 00-.422.127l-.628 3.141a.5.5 0 00.49.6.473.473 0 00.1-.01l3.143-.628a.25.25 0 00.128-.422zM23.036 11.591a2.71 2.71 0 00-3.606.274l-6.58 6.58a.249.249 0 000 .353l3.35 3.353a.249.249 0 00.353 0l6.679-6.679a2.622 2.622 0 00-.196-3.881z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});