define("ember-svg-jar/inlined/style-three-pin-bench", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-bench</title><path d=\"M10.936 6a.5.5 0 00-.48.359l-.588 2a.5.5 0 00.479.641h3.306a.5.5 0 00.479-.641l-.588-2a.5.5 0 00-.48-.359z\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm6.75 10.5h-1.756a.5.5 0 00-.479.641l.7 2.4a.751.751 0 01-.508.931.786.786 0 01-.212.03.751.751 0 01-.72-.538l-.912-3.1a.5.5 0 00-.48-.359H9.612a.5.5 0 00-.48.359l-.912 3.1a.751.751 0 01-.72.538.786.786 0 01-.212-.03.751.751 0 01-.508-.931l.705-2.4a.5.5 0 00-.479-.641H5.25a.75.75 0 010-1.5h2.491a.5.5 0 00.48-.359l.588-2A.5.5 0 008.329 6H7.25a.75.75 0 010-1.5h9.5a.75.75 0 010 1.5h-1.079a.5.5 0 00-.48.641l.588 2a.5.5 0 00.48.359h2.491a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});