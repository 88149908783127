define("ember-svg-jar/inlined/user-live", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>user-live</title><path d=\"M9.75 17a.75.75 0 00-.75.75v5a.75.75 0 001.5 0v-5a.75.75 0 00-.75-.75zM15 17a.75.75 0 00-.75.75v1.7a3.612 3.612 0 01-.267 1.3.25.25 0 01-.466 0 3.612 3.612 0 01-.267-1.3v-1.7a.75.75 0 00-1.5 0v1.7a6.993 6.993 0 001.376 3.719.75.75 0 001.248 0 6.993 6.993 0 001.376-3.719v-1.7A.75.75 0 0015 17zM7.5 22.75a.75.75 0 00-.75-.75h-1a.25.25 0 01-.25-.25v-4a.75.75 0 00-1.5 0v4a1.752 1.752 0 001.75 1.75h1a.75.75 0 00.75-.75zM18.5 18.75a.25.25 0 01.25-.25h1a.75.75 0 000-1.5h-1A1.752 1.752 0 0017 18.75v3a1.752 1.752 0 001.75 1.75h1a.75.75 0 000-1.5h-1a.25.25 0 010-.5h1a.75.75 0 000-1.5h-1a.25.25 0 01-.25-.25z\"/><circle cx=\"12.013\" cy=\"9.022\" r=\"2.363\"/><path d=\"M12.013 12.243a3.859 3.859 0 00-3.673 2.68.246.246 0 00.036.224.249.249 0 00.2.1h6.87a.25.25 0 00.238-.327 3.862 3.862 0 00-3.671-2.677zM12 3.5a5.5 5.5 0 00-4.844 8.108.751.751 0 00.661.392.732.732 0 00.355-.09.749.749 0 00.3-1.015 4 4 0 117.046 0 .751.751 0 001.321.713A5.5 5.5 0 0012 3.5z\"/><path d=\"M12 .5A8.51 8.51 0 003.5 9a8.423 8.423 0 001.781 5.208.75.75 0 001.186-.92A6.93 6.93 0 015 9a7 7 0 0114 0 6.931 6.931 0 01-1.468 4.29.75.75 0 101.184.92A8.413 8.413 0 0020.5 9 8.51 8.51 0 0012 .5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});