define("ember-svg-jar/inlined/single-neutral-statics-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-statics-1</title><path d=\"M24 2a2 2 0 00-2-2H11.5a1 1 0 000 2h10a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-7.75a1 1 0 000 2H22a2 2 0 002-2z\"/><path d=\"M14.348 10.663l1.334-2.134a.249.249 0 01.388-.044l1.056 1.056a1 1 0 001.556-.178L20.848 5.9a1 1 0 00-1.7-1.059l-1.33 2.13a.249.249 0 01-.388.044l-1.056-1.056a1 1 0 00-1.556.178L12.652 9.6a1 1 0 001.7 1.059zM5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M24 2a2 2 0 00-2-2H11.5a1 1 0 000 2h10a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-7.75a1 1 0 000 2H22a2 2 0 002-2z\"/><path d=\"M14.348 10.663l1.334-2.134a.249.249 0 01.388-.044l1.056 1.056a1 1 0 001.556-.178L20.848 5.9a1 1 0 00-1.7-1.059l-1.33 2.13a.249.249 0 01-.388.044l-1.056-1.056a1 1 0 00-1.556.178L12.652 9.6a1 1 0 001.7 1.059zM5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});