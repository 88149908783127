define("ember-svg-jar/inlined/vr-user-box-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vr-user-box-1</title><path d=\"M3.731 5.927l2.475 1.061a.5.5 0 00.657-.657L5.8 3.856a.522.522 0 00-.1-.156L2.268.271a.5.5 0 00-.707 0L.146 1.685a.5.5 0 000 .708l3.429 3.428a.5.5 0 00.156.106z\"/><path d=\"M14.573 7.978a.248.248 0 00.178-.24.8.8 0 00-.108-.458.76.76 0 00-.379-.317l-5.441-2.04a.75.75 0 10-.527 1.4L11.947 7.7 8 9.314 2.284 6.971a.75.75 0 00-1.034.694v6.46a.75.75 0 00.513.711l6 2a.74.74 0 00.237.039.954.954 0 00.342-.072.25.25 0 00.169-.25 8.67 8.67 0 01-.011-.428 8.516 8.516 0 016.073-8.147z\"/><path d=\"M23.254 13.067a6.758 6.758 0 00-12.5-.005A2 2 0 0010 14.625v3a2 2 0 002 2h2.5a1 1 0 00.8-.4l.9-1.2a1 1 0 011.6 0l.9 1.2a1 1 0 00.8.4H22a2 2 0 002-2v-3a2 2 0 00-.746-1.558zM14.5 15.625h-2a.5.5 0 010-1h2a.5.5 0 010 1zm3 0h-1a.5.5 0 010-1h1a.5.5 0 010 1zm-4.3-3a.25.25 0 01-.19-.412 5.269 5.269 0 018 0 .25.25 0 01-.191.412zM21.257 21.416a.75.75 0 00-1.052-.135 5.319 5.319 0 01-6.41 0 .75.75 0 10-.916 1.188 6.742 6.742 0 008.242 0 .751.751 0 00.136-1.053z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});