define("ember-svg-jar/inlined/smiley-fever", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-fever</title><path d=\"M15.408 16.711a.25.25 0 00.308.025l3.569-2.365a.252.252 0 00.072-.345l-.56-.861a.251.251 0 00-.346-.073l-3.91 2.525a.25.25 0 000 .42 6.458 6.458 0 01.867.674z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm5.355 6.447A2.656 2.656 0 0015.25 5.5a1 1 0 010-2c1.153 0 3.142.547 3.895 2.053a1 1 0 01-1.79.894zM17.5 9.5A1.5 1.5 0 1116 8a1.5 1.5 0 011.5 1.5zm-9.25-6a1 1 0 010 2 2.656 2.656 0 00-2.105.947 1 1 0 11-1.79-.894C5.108 4.047 7.1 3.5 8.25 3.5zm1.25 6A1.5 1.5 0 118 8a1.5 1.5 0 011.5 1.5zm12.477 4.887l-5.312 3.519a.251.251 0 00-.1.284 1 1 0 01-1.792.846A4.5 4.5 0 008.158 18 1 1 0 116.9 16.451a6.506 6.506 0 015.488-1.313.248.248 0 00.188-.034l6.953-4.491a2.251 2.251 0 012.453 3.774z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});