define("ember-svg-jar/inlined/quill-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>quill-circle</title><path d=\"M13.476 13.244a.75.75 0 01.75-.75h6.086a.254.254 0 00.207-.107c3.057-4.249 4.406-8.924 2.075-11.259a.5.5 0 00-.7 0 .453.453 0 00-.054.062l-4.688 4.69a.251.251 0 01-.427-.177V.994A.5.5 0 0016 .546 30.084 30.084 0 008.8 6a23.25 23.25 0 00-5.368 7.994c-.808 2.171-.91 4.009-.341 5.246L.519 21.805a1 1 0 000 1.414 1.015 1.015 0 001.414 0l2.556-2.555a5.5 5.5 0 002.425.5 14.254 14.254 0 008.186-3.217.253.253 0 00-.154-.453h-5.22a.75.75 0 110-1.5h7.56a.258.258 0 00.178-.072c.5-.485.983-.991 1.442-1.511a.253.253 0 00-.192-.417h-4.488a.75.75 0 01-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});