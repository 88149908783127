define("ember-svg-jar/inlined/skateboard-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skateboard-1</title><path d=\"M22.837.588a2.008 2.008 0 00-3.115.343L17.962 3.7a1.092 1.092 0 01-.147.183L1.166 20.528A2.033 2.033 0 104.041 23.4l16.49-16.485a5.157 5.157 0 00.7-.874l1.88-2.954a2 2 0 00-.274-2.499zM8.906 20.487a2.058 2.058 0 102.91 0 2.059 2.059 0 00-2.91 0zM19.562 9.685a2.057 2.057 0 102.909 0 2.057 2.057 0 00-2.909 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});