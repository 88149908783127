define("ember-svg-jar/inlined/smiley-head-patch-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-head-patch-alternate</title><path d=\"M4.4 13a4.372 4.372 0 003.109-1.288l4.2-4.2a4.4 4.4 0 10-6.219-6.22l-4.2 4.2A4.4 4.4 0 004.4 13zM2.7 6.906l4.2-4.2a2.4 2.4 0 013.4 3.388l-4.2 4.2a2.4 2.4 0 11-3.4-3.388z\"/><rect x=\"4.151\" y=\"5.344\" width=\"4.746\" height=\"2.266\" rx=\".5\" ry=\".5\" transform=\"rotate(-45 6.524 6.477)\"/><path d=\"M12 14.1a6.054 6.054 0 00-5.087 2.758 1 1 0 101.675 1.092 4.072 4.072 0 016.825 0 1 1 0 101.675-1.092A6.055 6.055 0 0012 14.1z\"/><circle cx=\"17\" cy=\"9\" r=\"1.75\"/><path d=\"M23.216 7.727a1 1 0 00-1.869.712 10 10 0 11-19.1 5.779 1 1 0 00-1.947.441 12 12 0 1022.916-6.932zM20.5 6a1 1 0 000-2c-1.513 0-3.524-.782-4.106-1.947a1 1 0 10-1.789.894C15.6 4.93 18.381 6 20.5 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});