define("ember-svg-jar/inlined/flying-insect-bee-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flying-insect-bee-2</title><path d=\"M7.5 2.3a4.022 4.022 0 012.558.926 2.36 2.36 0 00-.536 1.294.249.249 0 00.1.231.246.246 0 00.251.022 5 5 0 014.246 0 .249.249 0 00.355-.253 2.363 2.363 0 00-.535-1.294A4.021 4.021 0 0116.5 2.3a1 1 0 000-2 6.033 6.033 0 00-4.472 2h-.056A6.033 6.033 0 007.5.3a1 1 0 000 2zM8.736 18.655a.5.5 0 00.489.4h5.551a.5.5 0 00.489-.4 11.057 11.057 0 00.211-1.574.5.5 0 00-.5-.532H9.024a.5.5 0 00-.5.531 10.773 10.773 0 00.212 1.575zM11.7 23.6a.5.5 0 00.3.1.5.5 0 00.3-.1 7.471 7.471 0 001.981-2.3.5.5 0 00-.435-.746h-3.69a.5.5 0 00-.435.747A7.482 7.482 0 0011.7 23.6zM12 5.8a3.494 3.494 0 00-1.875 6.446h-.014a8.079 8.079 0 00-1.235 2.123.5.5 0 00.468.678h5.313a.5.5 0 00.467-.678 8.061 8.061 0 00-1.234-2.122h-.016A3.494 3.494 0 0012 5.8zM22.827 13.924a2605.213 2605.213 0 00-5.523-4.761A.184.184 0 0017 9.3a4.961 4.961 0 01-1.027 3.031.248.248 0 00-.011.289c.39.6 1.837 2.783 2.622 3.958a3.08 3.08 0 002.63 1.316 3.745 3.745 0 00.571-.044 2.415 2.415 0 002.082-1.534c.158-.544.104-1.404-1.04-2.392zM7 9.3a.183.183 0 00-.3-.139 2359.801 2359.801 0 00-5.524 4.761c-1.143.988-1.2 1.848-1.041 2.394a2.422 2.422 0 002.082 1.534 3.768 3.768 0 00.572.044 3.079 3.079 0 002.63-1.316c.781-1.178 2.229-3.36 2.62-3.955a.251.251 0 00-.011-.29A4.962 4.962 0 017 9.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});