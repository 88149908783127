define("ember-svg-jar/inlined/team-share-idea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>team-share-idea</title><path d=\"M13 11.875h-2a.75.75 0 000 1.5h2a.75.75 0 000-1.5zM12 2.375a.75.75 0 00.75-.75v-1a.75.75 0 00-1.5 0v1a.75.75 0 00.75.75zM7 5.375H5.8a.75.75 0 000 1.5H7a.75.75 0 000-1.5zM18 6.875a.75.75 0 000-1.5h-1a.75.75 0 000 1.5zM8.53 2.594l-1-1a.75.75 0 10-1.06 1.061l1 1a.75.75 0 101.06-1.061zM17.53 2.655a.75.75 0 10-1.06-1.061l-1 1a.75.75 0 101.06 1.061zM12 2.875a3.848 3.848 0 00-3.75 3.75 3.641 3.641 0 001.4 2.83.248.248 0 01.1.2v.474a.75.75 0 00.75.75h3a.75.75 0 00.75-.75v-.478a.248.248 0 01.1-.2 3.641 3.641 0 001.4-2.83A3.848 3.848 0 0012 2.875z\"/><circle cx=\"20.5\" cy=\"10.125\" r=\"2.5\"/><path d=\"M20.5 13.125a3.5 3.5 0 00-3.5 3.5v2a.5.5 0 00.5.5h.822a.249.249 0 01.248.225l.43 4.324a.5.5 0 00.5.451h2a.5.5 0 00.5-.451l.433-4.324a.249.249 0 01.248-.225h.819a.5.5 0 00.5-.5v-2a3.5 3.5 0 00-3.5-3.5z\"/><circle cx=\"3.5\" cy=\"10.125\" r=\"2.5\"/><path d=\"M3.5 13.125a3.5 3.5 0 00-3.5 3.5v2a.5.5 0 00.5.5h.822a.249.249 0 01.248.225L2 23.674a.5.5 0 00.5.451h2a.5.5 0 00.5-.451l.43-4.324a.249.249 0 01.248-.225H6.5a.5.5 0 00.5-.5v-2a3.5 3.5 0 00-3.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});