define("ember-svg-jar/inlined/gesture-swipe-vertical-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-vertical-up</title><path d=\"M13.588 4.362l.576-.411a.248.248 0 01.211-.038.251.251 0 01.163.14 15.154 15.154 0 01.9 2.767 1 1 0 001.95-.443 16.63 16.63 0 00-1.234-3.592.251.251 0 01.079-.313l.7-.5a.75.75 0 00-.313-1.35L13.754.143a.751.751 0 00-.863.616l-.479 2.869a.751.751 0 001.176.734zM16.636 16.187a1 1 0 00-1.2.754 10.586 10.586 0 01-2.473 5.194 1 1 0 001.37 1.465 12.344 12.344 0 003.057-6.213 1 1 0 00-.754-1.2zM16.5 8.365h-12a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h12a3.5 3.5 0 000-7zm0 5h-1.125A.375.375 0 0115 12.99v-2.25a.375.375 0 01.375-.375H16.5a1.5 1.5 0 010 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});