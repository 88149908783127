define("ember-svg-jar/inlined/gas-load", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gas-load</title><path d=\"M23.682 8.645a1 1 0 00-1.414.047c-1.838 1.965-2.768 3.372-2.768 4.183v7.5a1 1 0 01-2 0v-13.5a2.5 2.5 0 00-2.5-2.5h-.248a.25.25 0 01-.25-.25v-1.75a1.752 1.752 0 00-1.75-1.75h-11A1.753 1.753 0 000 2.375v19a1.753 1.753 0 001.75 1.75h11a1.752 1.752 0 001.75-1.75V6.625a.25.25 0 01.25-.25H15a.5.5 0 01.5.5v13.5a3 3 0 006 0v-2.227a.249.249 0 01.138-.224l1.033-.517a1.493 1.493 0 00.829-1.341v-1.191a1.5 1.5 0 00-1.5-1.5h-.26a.25.25 0 01-.25-.248v-.156a13.9 13.9 0 012.24-2.913 1 1 0 00-.048-1.413zm-12.933 6.98a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h1a.5.5 0 01.5.5zm1-8.75a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h8a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});