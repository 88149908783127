define("ember-svg-jar/inlined/outdoors-dog-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-dog-house</title><path d=\"M8.291 12.075a.25.25 0 01.177.314 1.934 1.934 0 00-.081.552v.994a1.94 1.94 0 01-.2.865l-.559 1.118a1.937 1.937 0 00-.2.865V20.2a.967.967 0 001.934 0v-2.1a1.935 1.935 0 01.567-1.368l.458-.458a.25.25 0 01.426.177v.848a.966.966 0 00.1.432l1.452 2.9a.966.966 0 00.864.534.967.967 0 00.864-1.4l-1.226-2.452a.246.246 0 01-.014-.19l.3-.919a.479.479 0 01.459-.333h2.319a.484.484 0 01.482.444l.335 4.011a.967.967 0 00.958.839h.121a.967.967 0 00.967-.967v-.711c0-3.927.03-4.962 1.864-8.348a.249.249 0 01.22-.131h1.3a1.137 1.137 0 001.071-.431l.313-.841a.241.241 0 00-.092-.285l-2.638-1.757a.25.25 0 01-.111-.208V6.1a.241.241 0 00-.242-.242.239.239 0 00-.118.031A6.2 6.2 0 0017 10.3a.96.96 0 01-.931.709h-5.747a1.837 1.837 0 00-.527.08.248.248 0 01-.217-.038 2.7 2.7 0 00-1-.451 2.7 2.7 0 00-3.1 1.687l-.692 1.844a.75.75 0 101.4.527l.692-1.844a1.19 1.19 0 01.629-.658c.02-.009.034-.027.054-.036a1.192 1.192 0 01.73-.045z\"/><path d=\"M3.833 21.915a.249.249 0 01-.25-.25V8.2a.25.25 0 01.177-.239l19.427-5.887a1 1 0 00-.58-1.914L.812 6.767A1 1 0 001.1 8.724a.941.941 0 00.183-.017.251.251 0 01.3.245v12.963a1.97 1.97 0 001.967 1.968H22.9a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});