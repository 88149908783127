define("ember-svg-jar/inlined/receipt-register-print", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>receipt-register-print</title><path d=\"M.25 19.375a.25.25 0 00-.25.25v2.75a1.5 1.5 0 001.5 1.5h21a1.5 1.5 0 001.5-1.5v-2.75a.25.25 0 00-.25-.25zm10.25 1.75h3a.75.75 0 010 1.5h-3a.75.75 0 010-1.5zM23.667 18.375a.237.237 0 00.207-.354l-2.364-7.993a.244.244 0 01-.01-.071V7.875a2 2 0 00-2-2h-2.25a.25.25 0 01-.25-.25v-.75a.25.25 0 01.25-.25h1.25a1.752 1.752 0 001.75-1.75v-1A1.752 1.752 0 0018.5.125h-5a1.752 1.752 0 00-1.75 1.75v1a1.752 1.752 0 001.75 1.75h1.25a.25.25 0 01.25.25v.75a.25.25 0 01-.25.25H12.5a2 2 0 00-1.995 1.855.156.156 0 01-.155.145h-.1a.25.25 0 01-.25-.25V3.132a1 1 0 00-1.4-.915l-2.1.923-2.1-.923a1 1 0 00-1.4.915v5.135a.237.237 0 01-.01.07L.158 17.94a1.42 1.42 0 00-.04.089.249.249 0 00.231.346zM6.1 5.147a1 1 0 00.8 0l.747-.328A.249.249 0 018 5.047v4.578a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25V5.047a.249.249 0 01.351-.228zm13.15 2.728a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25h-6.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25zM7.5 16.132h-1a1 1 0 110-2h1a1 1 0 010 2zm5 0h-1a1 1 0 110-2h1a1 1 0 010 2zm5.5 0h-1a1 1 0 110-2h1a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});