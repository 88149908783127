define("ember-svg-jar/inlined/single-neutral-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-shield</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v7.625A15.288 15.288 0 0011.9 23.99a.5.5 0 00.2 0A15.288 15.288 0 0024 9.125V1.5A1.5 1.5 0 0022.5 0zm-4.615 16.32a.5.5 0 01-.385.18h-11a.5.5 0 01-.491-.591 5.275 5.275 0 01.491-1.476c.453-.876 1.591-1.415 3.667-2.16a1 1 0 00-.007-.71 4.977 4.977 0 01-1.13-3.51A2.882 2.882 0 0112 5a2.882 2.882 0 012.969 3.053 5 5 0 01-1.149 3.534.976.976 0 00.027.7c2.061.736 3.2 1.275 3.652 2.152a5.258 5.258 0 01.492 1.475.5.5 0 01-.106.406z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});