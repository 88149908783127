define("ember-svg-jar/inlined/multiple-neutral-2.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-neutral-2</title><path d=\"M18 14.5a5.95 5.95 0 00-2.619.608.5.5 0 00-.206.711A8.934 8.934 0 0116.5 20.5a.5.5 0 00.5.5h6.5a.5.5 0 00.5-.5 6 6 0 00-6-6z\"/><circle cx=\"17.944\" cy=\"10.25\" r=\"3.75\"/><circle cx=\"7.5\" cy=\"7.75\" r=\"4.75\"/><path d=\"M15 20.5a7.5 7.5 0 00-15 0 .5.5 0 00.5.5h14a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});