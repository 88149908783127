define("ember-svg-jar/inlined/dress-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dress-2</title><path d=\"M18.55 17.2a.251.251 0 00.163-.31l-1.5-5.039a.5.5 0 00-.607-.341A23.616 23.616 0 0112 12a24.89 24.89 0 01-4.613-.5.5.5 0 00-.6.342l-1.5 5.042a.251.251 0 00.163.31 21.055 21.055 0 0013.1.006zM19.942 21.032l-.66-2.224a.251.251 0 00-.315-.167A22.84 22.84 0 0112 19.748a22.84 22.84 0 01-6.967-1.107.251.251 0 00-.315.167l-.662 2.232a1.5 1.5 0 00.89 1.8A19.882 19.882 0 0012 24a19.882 19.882 0 007.054-1.159 1.5 1.5 0 00.89-1.8zM16.127 4.395A.253.253 0 0116 4.177V1a1 1 0 00-2 0v2.848a.251.251 0 01-.158.233 1.031 1.031 0 00-.471.351l-1.087 1.643a.316.316 0 01-.568 0l-1.087-1.643a1.031 1.031 0 00-.471-.351.25.25 0 01-.158-.233V1a1 1 0 10-2 0v3.177a.251.251 0 01-.128.218 2.509 2.509 0 00-1.221 3.061l.842 2.291a.5.5 0 00.366.316 19.7 19.7 0 008.282 0 .5.5 0 00.366-.316l.842-2.291a2.51 2.51 0 00-1.222-3.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});