define("ember-svg-jar/inlined/translate.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>translate</title><path d=\"M22.917 11H15.5a.5.5 0 00-.5.5v.417A3.083 3.083 0 0111.917 15H11.5a.5.5 0 00-.5.5v7.417A1.083 1.083 0 0012.083 24h10.834A1.083 1.083 0 0024 22.915V12.081A1.083 1.083 0 0022.917 11zm-2.153 11.2a.741.741 0 01-.264.048.749.749 0 01-.7-.486l-.507-1.352a.251.251 0 00-.234-.162h-3.116a.251.251 0 00-.234.162l-.509 1.352a.75.75 0 01-1.4-.528l2.532-6.751a1.25 1.25 0 012.34 0l1.758 4.687a.889.889 0 01.038.1l.736 1.963a.75.75 0 01-.44.967z\"/><path d=\"M16.459 18.41a.25.25 0 00.234.338h1.614a.25.25 0 00.234-.338l-.807-2.151a.25.25 0 00-.468 0zM7.779 5.607a.251.251 0 00-.226-.359H5.447a.251.251 0 00-.226.359 7.962 7.962 0 001.088 1.667.249.249 0 00.382 0 7.962 7.962 0 001.088-1.667z\"/><path d=\"M11.917 13A1.083 1.083 0 0013 11.915V1.081A1.083 1.083 0 0011.917 0H1.083A1.083 1.083 0 000 1.081v10.834A1.083 1.083 0 001.083 13zm-1.193-2.3a.751.751 0 01-.724.554.782.782 0 01-.2-.026A9.212 9.212 0 016.651 9.67a.249.249 0 00-.3 0A9.212 9.212 0 013.2 11.222a.782.782 0 01-.2.026.749.749 0 01-.2-1.473 7.707 7.707 0 002.365-1.1.253.253 0 00.11-.175.248.248 0 00-.058-.2A9.508 9.508 0 013.53 5.412a.249.249 0 00-.23-.164h-.769a.75.75 0 010-1.5H5.5a.25.25 0 00.25-.25v-1a.75.75 0 111.5 0v1a.25.25 0 00.25.25h3a.75.75 0 010 1.5h-.795a.249.249 0 00-.235.164A9.508 9.508 0 017.783 8.3a.25.25 0 00.048.368 7.707 7.707 0 002.365 1.1.749.749 0 01.528.926zM17.646 5.352A.5.5 0 0018.5 5v-.31a.25.25 0 01.367-.221A4 4 0 0121 8a1 1 0 102 0 6 6 0 00-4.32-5.753.25.25 0 01-.18-.24V1a.5.5 0 00-.309-.462.506.506 0 00-.545.109l-2 2a.5.5 0 000 .707zM2 14a1 1 0 00-1 .992V15a6 6 0 004.32 5.753.25.25 0 01.18.24V22a.5.5 0 00.854.354l2-2a.5.5 0 000-.707l-2-2A.5.5 0 005.5 18v.31a.252.252 0 01-.121.215.248.248 0 01-.246.006A4 4 0 013 15a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});