define("ember-svg-jar/inlined/wifi-signal-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-signal-4</title><path d=\"M13.041 11.933a3 3 0 10-2.082 0L7.06 22.656A1 1 0 008 24h8a1 1 0 00.94-1.342zM20.339.291a1 1 0 10-1.414 1.414 10.515 10.515 0 010 14.85 1 1 0 101.414 1.414 12.516 12.516 0 000-17.678zM5.075 1.705A1 1 0 003.661.291a12.516 12.516 0 000 17.678 1 1 0 101.414-1.414 10.515 10.515 0 010-14.85z\"/><path d=\"M17.053 3.827a1 1 0 00-1.414 1.414 5.5 5.5 0 010 7.778 1 1 0 001.414 1.414 7.5 7.5 0 000-10.606zM8.361 14.433a1 1 0 000-1.414 5.5 5.5 0 010-7.778 1 1 0 00-1.414-1.414 7.5 7.5 0 000 10.606 1 1 0 001.414 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});