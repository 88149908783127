define("ember-svg-jar/inlined/single-neutral-id-card-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-id-card-2</title><circle cx=\"12\" cy=\"12\" r=\"3\"/><path d=\"M19.5 3H17a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h2a.5.5 0 01.5.5v12a.5.5 0 01-.5.5h-2.653a5.722 5.722 0 00-8.692 0H5a.5.5 0 01-.5-.5v-12A.5.5 0 015 5h2a.5.5 0 00.5-.5v-1A.5.5 0 007 3H4.5a2 2 0 00-2 2v17a2 2 0 002 2h15a2 2 0 002-2V5a2 2 0 00-2-2zM5 22a.5.5 0 01-.5-.5v-1A.5.5 0 015 20h14a.5.5 0 01.5.5v1a.5.5 0 01-.5.5z\"/><path d=\"M9 6a1.5 1.5 0 001.5 1.5h3A1.5 1.5 0 0015 6V3a3 3 0 00-6 0zm2-3.5a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});