define("ember-svg-jar/inlined/measure-construction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>measure-construction</title><circle cx=\"8\" cy=\"8\" r=\"2.75\"/><path d=\"M15 16a1 1 0 001-1V8a8.011 8.011 0 00-8-8H4a4 4 0 00-4 4v11a1 1 0 001 1zM8 3.75A4.25 4.25 0 113.75 8 4.259 4.259 0 018 3.75z\"/><path d=\"M3.75 19.5H20.5a3.5 3.5 0 000-7h-3a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h3a1.5 1.5 0 010 3H3.75a3.25 3.25 0 000 6.5H20.5a2.006 2.006 0 002-2v-1a1 1 0 00-2 0v.5a.5.5 0 01-.5.5H3.75a1.25 1.25 0 010-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});