define("ember-svg-jar/inlined/copy-paste", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>copy-paste</title><path d=\"M13.4 4.727a.251.251 0 00.2.265 1.089 1.089 0 01.824 1.108v1.4a1.244 1.244 0 001.244 1.244A1.244 1.244 0 0016.913 7.5V3.975a1.494 1.494 0 00-1.5-1.494h-1.724a.246.246 0 00-.177.074.249.249 0 00-.073.177c.002.473.001 1.492-.039 1.995zM8.954 21.262a1.244 1.244 0 00-1.244-1.244H3.484a1.073 1.073 0 01-1-1.136V6.1a1.086 1.086 0 01.842-1.115.25.25 0 00.2-.258c-.039-.766-.051-1.6-.055-2a.25.25 0 00-.25-.247H1.5A1.494 1.494 0 000 3.975v17.037a1.493 1.493 0 001.494 1.493H7.71a1.244 1.244 0 001.244-1.243zM11.937 4.472V2.484a.5.5 0 00-.5-.5h-.758a.251.251 0 01-.249-.222 1.989 1.989 0 00-3.953 0 .249.249 0 01-.249.222h-.755a.5.5 0 00-.5.5v1.988a.5.5 0 00.5.5h5.967a.5.5 0 00.497-.5zM19.02 17.269h-3.979a.75.75 0 100 1.5h3.979a.75.75 0 100-1.5zM14.291 14.54a.75.75 0 00.75.749h2.486a.75.75 0 100-1.5h-2.486a.75.75 0 00-.75.751z\"/><path d=\"M23.5 13.46a1.991 1.991 0 00-.584-1.409l-1.406-1.4a1.994 1.994 0 00-1.41-.584h-7.606a1.993 1.993 0 00-1.994 1.988V22a2 2 0 002 2h9a2 2 0 002-2zm-11-.4a1 1 0 011-1h6.187a1 1 0 01.707.292l.818.816a1 1 0 01.293.708V21a1 1 0 01-1 1h-7a1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});