define("ember-svg-jar/inlined/ab-testing-browsers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ab-testing-browsers</title><path d=\"M21 9h-1.5a1 1 0 00-1 1v3a1 1 0 001 1h2.25a.25.25 0 01.25.25V21a1 1 0 01-1 1H10a1 1 0 01-1-1v-3.365a1 1 0 00-2 0V21a3 3 0 003 3h11a3 3 0 003-3v-9a3 3 0 00-3-3z\"/><path d=\"M15 5.25v5.25a1 1 0 002 0V3a3 3 0 00-3-3H3a3 3 0 00-3 3v9a3 3 0 003 3h8a1 1 0 000-2H3a1 1 0 01-1-1V5.25A.25.25 0 012.25 5h12.5a.25.25 0 01.25.25zM3.25 3.5A.75.75 0 114 2.75a.749.749 0 01-.75.75zm2.5 0a.75.75 0 11.75-.75.749.749 0 01-.75.75zm2.5 0A.75.75 0 119 2.75a.749.749 0 01-.75.75z\"/><path d=\"M10 12.25a.75.75 0 00.75-.75V8a2.25 2.25 0 00-4.5 0v3.5a.75.75 0 001.5 0v-1a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v1a.75.75 0 00.75.75zm-1-3.5H8a.25.25 0 01-.25-.25V8a.75.75 0 011.5 0v.5a.25.25 0 01-.25.25zM18.25 15.5A2.253 2.253 0 0016 13.25h-1.5a.75.75 0 00-.75.75v6a.75.75 0 00.75.75H16a2.253 2.253 0 002.25-2.25 2.219 2.219 0 00-.46-1.348.251.251 0 010-.3 2.219 2.219 0 00.46-1.352zM16 19.25h-.5a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h.5a.75.75 0 010 1.5zm0-3h-.5a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h.5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});