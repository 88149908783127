define("ember-svg-jar/inlined/monetization-user-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monetization-user-laptop</title><path d=\"M16.146 7.75a1 1 0 000 2h.75a.25.25 0 01.25.25v.25a1 1 0 002 0v-.281a.251.251 0 01.214-.248 1.953 1.953 0 001.786-1.971c0-1.186-1.185-1.984-2.441-2.829a10.258 10.258 0 01-1.412-1.066.063.063 0 01-.012-.067.065.065 0 01.058-.038h2.807a1 1 0 000-2H19.4a.249.249 0 01-.25-.25v-.25a1 1 0 00-2 0v.275a.249.249 0 01-.22.248 1.881 1.881 0 00-1.78 1.977c0 1.187 1.186 1.984 2.442 2.83A11.394 11.394 0 0119 7.636a.066.066 0 01-.047.114z\"/><path d=\"M5.238 15.855a7.166 7.166 0 00-5.246 6.9 1 1 0 002 0 5.152 5.152 0 018.156-4.187.253.253 0 01.092.283l-1.078 3.228a.249.249 0 01-.237.171h-5.28a.75.75 0 100 1.5h17a.75.75 0 00.712-.513l2.56-7.681a1.751 1.751 0 00-1.66-2.306h-8.89a1.748 1.748 0 00-1.66 1.2l-.653 1.95a.25.25 0 01-.362.138 7.108 7.108 0 00-1.638-.685.252.252 0 01-.183-.226.25.25 0 01.156-.246 5 5 0 10-3.761 0 .25.25 0 01.155.246.252.252 0 01-.183.228zM17.146 17.5a1 1 0 11-1 1 1 1 0 011-1zm-13-6.75a2.979 2.979 0 01.192-1.05.251.251 0 01.16-.15.248.248 0 01.216.033 6.61 6.61 0 003.765 1.167 6.723 6.723 0 001.464-.162.17.17 0 01.141.032.168.168 0 01.062.13 3 3 0 01-6 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});