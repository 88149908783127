define("ember-svg-jar/inlined/trip-destination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trip-destination</title><path d=\"M5.5 21a1 1 0 00.761-.351C6.454 20.423 11 15.057 11 11.5a5.5 5.5 0 00-11 0c0 3.558 4.545 8.922 4.739 9.149A1 1 0 005.5 21zM3 11.5A2.5 2.5 0 115.5 14 2.5 2.5 0 013 11.5z\"/><path d=\"M19.5 13H15a2 2 0 00-2 2v6.75a.25.25 0 01-.25.25H5.5a1 1 0 000 2H13a2 2 0 002-2v-6.75a.25.25 0 01.25-.25h4.25a1 1 0 000-2zM19.5 12a.5.5 0 00.354-.146C20.023 11.684 24 7.671 24 4.5a4.5 4.5 0 00-9 0c0 3.171 3.977 7.184 4.146 7.354A.5.5 0 0019.5 12zM18 4.5A1.5 1.5 0 1119.5 6 1.5 1.5 0 0118 4.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});