define("ember-svg-jar/inlined/bus-school", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bus-school</title><path d=\"M22.25 4.012H4.5a1.752 1.752 0 00-1.75 1.75v5.245a.25.25 0 01-.219.248A1.753 1.753 0 001 12.992v4a.25.25 0 01-.25.25.75.75 0 000 1.5h1.8a.25.25 0 00.246-.294 2.667 2.667 0 01-.042-.46 3 3 0 016 0 2.667 2.667 0 01-.042.46.25.25 0 00.246.294h7.593a.251.251 0 00.247-.294 2.572 2.572 0 01-.043-.46 3 3 0 016 0 2.37 2.37 0 01-.016.281.251.251 0 00.37.246 1.679 1.679 0 00.371-.279 1.717 1.717 0 00.52-1.244V5.762a1.752 1.752 0 00-1.75-1.75zM7 10.762a.25.25 0 01-.25.25H5a.25.25 0 01-.25-.25v-3.75a.25.25 0 01.25-.25h1.75a.25.25 0 01.25.25zm5.6 5a.5.5 0 01-.5.5H9.6a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5h2.5a.5.5 0 01.5.5zm4.4-5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm5 0a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h2a.5.5 0 01.5.5z\"/><circle cx=\"5.75\" cy=\"17.988\" r=\"2\"/><circle cx=\"19.751\" cy=\"17.988\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});