define("ember-svg-jar/inlined/buildings-modern", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>buildings-modern</title><path d=\"M11.046 2.431a1 1 0 00-.952-.076L6.2 4.085a.5.5 0 01-.7-.457V1a1 1 0 00-2 0v3.962a.5.5 0 01-.3.456l-1.1.493a1 1 0 00-.6.914V23a1 1 0 001 1h2a.5.5 0 00.5-.5V21a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2.5a.5.5 0 00.5.5h2a1 1 0 001-1v-7a.5.5 0 00-.5-.5H6A.75.75 0 016 14h5a.5.5 0 00.5-.5V12a.5.5 0 00-.5-.5H8A.75.75 0 018 10h3a.5.5 0 00.5-.5V8a.5.5 0 00-.5-.5H9A.75.75 0 019 6h2a.5.5 0 00.5-.5V3.27a1 1 0 00-.454-.839zM20.235 9.628A.5.5 0 0021 9.2V6.5a.5.5 0 00-.5-.5h-5a.307.307 0 00-.163.567zM22.029 12.527l-7-4.374A1 1 0 0013.5 9v2.5a.5.5 0 00.5.5h2a.75.75 0 010 1.5h-2a.5.5 0 00-.5.5v1.5a.5.5 0 00.5.5h4a.75.75 0 010 1.5h-4a.5.5 0 00-.5.5v5a1 1 0 001 1H16a.5.5 0 00.5-.5v-3a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v3a.5.5 0 00.5.5h1.5a1 1 0 001-1v-9.625a.993.993 0 00-.471-.848z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});