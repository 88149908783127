define("ember-svg-jar/inlined/common-file-text-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-text-shield</title><path d=\"M19 8.5a1 1 0 000-2h-7a1 1 0 000 2z\"/><path d=\"M24 4.415A2 2 0 0023.414 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.266a.248.248 0 00.244.25c.368 0 1.045.021 1.5.047A.246.246 0 008 9.314V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-7.01a.242.242 0 00-.235.184 8.217 8.217 0 01-.53 1.46.245.245 0 00.218.355H22a2 2 0 002-2z\"/><path d=\"M14.437 12.8a.246.246 0 00.239.2H16.5a1 1 0 000-2h-2.559a.246.246 0 00-.184.41 3.03 3.03 0 01.68 1.39zM13 13.456a1.545 1.545 0 00-.928-1.416A16.386 16.386 0 006.479 11a15.988 15.988 0 00-5.551 1.04A1.545 1.545 0 000 13.456v3.393a7.716 7.716 0 005.19 6.805A3.8 3.8 0 006.479 24a3.8 3.8 0 001.288-.346C10.848 22.48 13 19.682 13 16.849zM10.25 16.5a.75.75 0 01-.75.75h-2a.25.25 0 00-.25.25v2a.75.75 0 01-1.5 0v-2a.25.25 0 00-.25-.25h-2a.75.75 0 010-1.5h2a.25.25 0 00.25-.25v-2a.75.75 0 011.5 0v2a.25.25 0 00.25.25h2a.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});