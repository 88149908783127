define("ember-svg-jar/inlined/baggage-weight-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baggage-weight-1</title><path d=\"M10.194 4.89H8.933a3.5 3.5 0 00-2.885 1.526.25.25 0 01-.33.074l-.243-.14a.25.25 0 01-.125-.2l-.059-.9a1.234 1.234 0 00-.618-.991l-1.4-.808a1.692 1.692 0 00-2.309.623l-.469.815a1.693 1.693 0 00.62 2.311l1.405.81a1.244 1.244 0 00.615.163 1.259 1.259 0 00.553-.129l.8-.4a.25.25 0 01.236.008l.575.33a.251.251 0 01.122.253 1.448 1.448 0 00-.015.324.25.25 0 01-.25.25H2.994a1.5 1.5 0 00-1.5 1.5v8.254a1.5 1.5 0 001.5 1.5h13.14a1.5 1.5 0 001.5-1.5v-8.254a1.5 1.5 0 00-1.5-1.5h-2.166a.25.25 0 01-.25-.25v-.146a3.528 3.528 0 00-3.524-3.523zm1.524 3.669a.25.25 0 01-.25.25H7.66a.25.25 0 01-.25-.25v-.146A1.524 1.524 0 018.933 6.89h1.261a1.525 1.525 0 011.524 1.523z\"/><path d=\"M20.063.75a3.91 3.91 0 00-1.174 7.641.248.248 0 01.174.238V20.3a.954.954 0 01-.953.953H1.018a1 1 0 000 2H18.11a2.957 2.957 0 002.953-2.953V8.629a.249.249 0 01.175-.238A3.909 3.909 0 0020.063.75zm1.859 3.163l-1.337 1.3a.75.75 0 11-1.043-1.083l1.337-1.295a.75.75 0 011.043 1.078z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});