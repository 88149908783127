define("ember-svg-jar/inlined/arrow-thick-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-up</title><path d=\"M21.5 9.186L12.708.39a1.027 1.027 0 00-1.416 0L2.5 9.186a1 1 0 00.707 1.707H8.25a.249.249 0 01.25.25v11.75a1 1 0 001 1h5a1 1 0 001-1v-11.75a.25.25 0 01.25-.25h5.043a1 1 0 00.707-1.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});