define("ember-svg-jar/inlined/data-file-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-warning</title><path d=\"M12.292 12.089l1.643-2.321a.25.25 0 01.333-.07l1.507.9A1 1 0 0017 10.451a.977.977 0 00.106-.123l2.687-3.734a1 1 0 10-1.623-1.168l-2.016 2.8a.251.251 0 01-.331.068L14.3 7.388a1 1 0 00-1.331.279l-2.309 3.265a1 1 0 001.632 1.157z\"/><path d=\"M24 18.5V4.414A2 2 0 0023.414 3L21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.818a.248.248 0 00.234.25 7.43 7.43 0 011.443.216.249.249 0 00.323-.238V2.5a.5.5 0 01.5-.5h10.878a.5.5 0 01.354.146l2.121 2.122a.5.5 0 01.147.353V18a.5.5 0 01-.5.5h-8.344a.25.25 0 00-.221.366l.787 1.5a.25.25 0 00.221.134H22a2 2 0 002-2z\"/><path d=\"M6.5 11.5a1.439 1.439 0 00-1.28.771L.164 21.9a1.428 1.428 0 00.047 1.409A1.457 1.457 0 001.444 24h10.111a1.458 1.458 0 001.233-.686 1.428 1.428 0 00.047-1.409L7.78 12.271A1.44 1.44 0 006.5 11.5zM5.749 16a.75.75 0 011.5 0v3a.75.75 0 01-1.5 0zm.75 6.25a1 1 0 111-1 1 1 0 01-.999 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});