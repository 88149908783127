define("ember-svg-jar/inlined/book-library-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-library-2</title><path d=\"M24 18a.5.5 0 00-.5-.5H.5a.5.5 0 00-.5.5v1a2.5 2.5 0 002.5 2.5V23a1 1 0 002 0v-1.25a.25.25 0 01.25-.25h14.5a.25.25 0 01.25.25V23a1 1 0 002 0v-1.5A2.5 2.5 0 0024 19zM9.25 16.5h4a.5.5 0 00.5-.5V1a1 1 0 00-1-1h-3a1 1 0 00-1 1v15a.5.5 0 00.5.5zM2.25 16.5h4a.5.5 0 00.5-.5V1a1 1 0 00-1-1h-3a1 1 0 00-1 1v15a.5.5 0 00.5.5zM18.276 16.491l3.842-.717a.489.489 0 00.391-.57L19.819.8a.978.978 0 00-1.14-.781L15.8.555a.976.976 0 00-.781 1.139L17.706 16.1a.488.488 0 00.57.391z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});