define("ember-svg-jar/inlined/safety-helmet-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-helmet-1</title><path d=\"M22.9 14.136a10.922 10.922 0 00-6.2-9.384.5.5 0 00-.716.423l-.311 5.7a1.91 1.91 0 01-1.906 1.8h-3.391a1.91 1.91 0 01-1.907-1.8l-.314-5.759a.5.5 0 00-.709-.427A10.918 10.918 0 001.1 14.136 1.638 1.638 0 000 15.682v1.091a1.638 1.638 0 001.636 1.636h2.215l.333 1.455a1.628 1.628 0 001.595 1.272h12.442a1.628 1.628 0 001.595-1.272l.333-1.455h2.215A1.638 1.638 0 0024 16.773v-1.091a1.638 1.638 0 00-1.1-1.546zm-7.082 1.273a.818.818 0 010 1.636H8.182a.818.818 0 110-1.636z\"/><path d=\"M10.1 10.788a.271.271 0 00.272.257h3.389a.271.271 0 00.272-.257l.416-7.628a.275.275 0 00-.072-.21.271.271 0 00-.2-.086H9.959a.271.271 0 00-.2.086.268.268 0 00-.073.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});