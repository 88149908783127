define("ember-svg-jar/inlined/drone-picture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drone-picture</title><path d=\"M18.875 10.375h-5a.75.75 0 000 1.5h5a.75.75 0 000-1.5zM5.875 11.875a.75.75 0 000-1.5h-5a.75.75 0 000 1.5zM19.375 16.125a1.5 1.5 0 00-1.5-1.5h-.5a.25.25 0 01-.25-.25v-.75a.75.75 0 00-1.5 0v.75a.25.25 0 01-.25.25h-1a2.561 2.561 0 01-1.9-.845 3.5 3.5 0 00-5.2 0 2.565 2.565 0 01-1.9.845h-1a.25.25 0 01-.25-.25v-.75a.75.75 0 00-1.5 0v.75a.25.25 0 01-.25.25h-.5a1.5 1.5 0 000 3h3.5a2.557 2.557 0 01.637.081.251.251 0 01.182.193.248.248 0 01-.094.248 6.243 6.243 0 00-2.475 4.978.75.75 0 001.5 0 4.753 4.753 0 012.459-4.16.249.249 0 01.266.015l.082.056a.251.251 0 01.068.346 2.25 2.25 0 103.748 0 .251.251 0 01.069-.346l.082-.056a.249.249 0 01.266-.015 4.753 4.753 0 012.459 4.16.75.75 0 001.5 0 6.243 6.243 0 00-2.475-4.978.25.25 0 01.088-.441 2.563 2.563 0 01.637-.081h3.5a1.5 1.5 0 001.501-1.5zm-9.5 5.25a.75.75 0 11.75-.75.751.751 0 01-.75.75zM22.458 1.125h-1.039l-.1-.237a1.411 1.411 0 00-1.26-.763h-2.076a1.425 1.425 0 00-1.284.8l-.079.2h-3.328a1.4 1.4 0 00-1.417 1.389v5.222a1.4 1.4 0 001.417 1.389h9.166a1.4 1.4 0 001.417-1.389V2.514a1.4 1.4 0 00-1.417-1.389zm-3.583 2a2 2 0 11-2 2 2 2 0 012-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});