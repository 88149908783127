define("ember-svg-jar/inlined/business-climb-top-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-climb-top-1</title><path d=\"M9.689 15.982a.246.246 0 00-.173.115L7.874 18.7a.25.25 0 01-.423 0L5.6 15.764c-.778-1.214-1.608.717-5.512 6.918a.577.577 0 00.489.885H22a1 1 0 00.846-1.532L19.107 16.1a.248.248 0 00-.173-.115.254.254 0 00-.2.057c-1.2 1.015-1.543 1.455-2.364 1.455a1.745 1.745 0 01-1.239-.509l-.643-.64a.249.249 0 00-.353 0l-.643.642a1.76 1.76 0 01-2.367.1l-1.236-1.051a.255.255 0 00-.2-.057z\"/><path d=\"M14.3.433a1 1 0 00-1 1v8.584a.243.243 0 01-.039.133l-2.667 4.237a.25.25 0 00.05.324l1.452 1.227a.251.251 0 00.338-.014l.642-.642a1.754 1.754 0 012.475 0l.642.642a.25.25 0 00.338.014l1.452-1.227a.25.25 0 00.05-.324l-2.72-4.32a.082.082 0 01-.013-.046V7.183a.25.25 0 01.25-.25h3.2a.25.25 0 01.25.25v1.25a.5.5 0 00.5.5h3.875a.573.573 0 00.625-.5v-4a.573.573 0 00-.625-.5H21.75a.25.25 0 00-.25.25v2.25a.5.5 0 01-1 0v-4a.5.5 0 00-.5-.5h-4.452a.25.25 0 01-.25-.25v-.25a1 1 0 00-.998-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});