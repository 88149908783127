define("ember-svg-jar/inlined/app-window-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-sync</title><path d=\"M22.77 18.6a1 1 0 00-1.271.623 4.091 4.091 0 01-6.918 1.419.25.25 0 01.008-.345l1.722-1.721a.5.5 0 00-.354-.853H11.5a.5.5 0 00-.5.5v4.457a.5.5 0 00.854.354l.969-.97a.25.25 0 01.359.006 6.086 6.086 0 0010.21-2.2 1 1 0 00-.622-1.27zM23.691 11.773a.5.5 0 00-.545.109l-1.026 1.026a.249.249 0 01-.179.073.253.253 0 01-.178-.077 6.1 6.1 0 00-10.2 2.226 1 1 0 001.893.648 4.091 4.091 0 016.9-1.445.252.252 0 01-.006.347l-1.66 1.659a.5.5 0 00.353.853H23.5a.5.5 0 00.5-.5v-4.457a.5.5 0 00-.309-.462z\"/><path d=\"M24 3a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h5.5a1 1 0 001-1 1 1 0 00-1-1H3a1 1 0 01-1-1V5.251A.25.25 0 012.25 5h19.5a.25.25 0 01.25.25V8.5a1 1 0 001 1 1 1 0 001-1zM4.346 3a.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.987.987 0 01.092-.409A1 1 0 013.5 1.5a.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5zm3.5 0a.966.966 0 01-1.692 0A.972.972 0 016 2.5a.969.969 0 01.154-.5.966.966 0 011.692 0A.969.969 0 018 2.5a.972.972 0 01-.154.5zm3.5 0a.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});