define("ember-svg-jar/inlined/pasta-fork", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pasta-fork</title><path d=\"M17.5 1.25a1 1 0 00-1 1v10a1 1 0 002 0v-10a1 1 0 00-1-1zM14 1.25a1 1 0 00-1 1v10a1 1 0 002 0v-10a1 1 0 00-1-1zM8.75 22.75a1 1 0 01-1-1 4.505 4.505 0 014.5-4.5h.5A3 3 0 0015.656 15a1 1 0 111.936.5 5 5 0 01-4.842 3.75h-.5a2.5 2.5 0 00-2.5 2.5 1 1 0 01-1 1zM11.25 4a.751.751 0 00.75-.75v-.5a.751.751 0 00-.75-.75h-2a4.819 4.819 0 00-4.239 2.376A1.257 1.257 0 013.927 5H.5a.5.5 0 00-.5.5V9a.5.5 0 00.5.5h3.427a1.258 1.258 0 011.084.623A4.821 4.821 0 009.25 12.5h2a.751.751 0 00.75-.75v-.5a.751.751 0 00-.75-.75h-.75a.5.5 0 01-.5-.5V8.75a.5.5 0 01.5-.5h.75A.751.751 0 0012 7.5V7a.751.751 0 00-.75-.75h-.75a.5.5 0 01-.5-.5V4.5a.5.5 0 01.5-.5zM20.25 4a.751.751 0 01-.75-.75v-.5a.751.751 0 01.75-.75H23a1 1 0 010 2zM20.25 8.25a.751.751 0 01-.75-.75V7a.751.751 0 01.75-.75H23a1 1 0 010 2zM20.25 12.5a.751.751 0 01-.75-.75v-.5a.751.751 0 01.75-.75H23a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});