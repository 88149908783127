define("ember-svg-jar/inlined/style-two-pin-bolt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-two-pin-bolt</title><path d=\"M12 0a9.511 9.511 0 00-9.5 9.5c0 6.544 6.537 12.488 8.541 14.153a1.5 1.5 0 001.917 0c2-1.665 8.542-7.609 8.542-14.154A9.511 9.511 0 0012 0zm4.476 9.47l-5.782 7.187a.248.248 0 01-.194.093.241.241 0 01-.083-.014.25.25 0 01-.167-.236v-5.25a.5.5 0 00-.5-.5H8.108a.75.75 0 01-.584-1.22l5.782-7.187a.25.25 0 01.444.157v5.25a.5.5 0 00.5.5h1.642a.75.75 0 01.584 1.22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});