define("ember-svg-jar/inlined/laboratory-sperm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laboratory-sperm</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm4 4a1 1 0 11-1 1 1 1 0 011-1zm-1.5 5a1.5 1.5 0 11-1.5 1.5A1.5 1.5 0 0114.5 9zm-9.041 3.79a1.287 1.287 0 01-.936-.32 2.268 2.268 0 01-.723-1.549 2.2 2.2 0 01.481-1.658.5.5 0 00.128-.328c.044-3.5 2.353-4.006 4.226-4.411a5.464 5.464 0 003.292-1.509.75.75 0 011.144.97 6.791 6.791 0 01-4.118 2.006C7.138 6.383 6 6.65 5.92 8.771a.5.5 0 00.175.4 2.145 2.145 0 01.7 1.5 2.434 2.434 0 01-.266 1.364 1.365 1.365 0 01-1.07.755zM7.5 18A1.5 1.5 0 119 16.5 1.5 1.5 0 017.5 18zM9 9a1 1 0 111 1 1 1 0 01-1-1zm8 11.5a1 1 0 111-1 1 1 0 01-1 1zm3.007-7.985a.5.5 0 00-.126.326c-.045 3.5-2.353 4-4.225 4.408a5.462 5.462 0 00-3.292 1.51 2.34 2.34 0 00-.308 2.062.75.75 0 01-1.456.362 3.825 3.825 0 01.62-3.395 6.794 6.794 0 014.119-2.006c1.81-.39 2.944-.658 3.032-2.766a.5.5 0 00-.17-.4 2.258 2.258 0 01-.7-1.52 2.44 2.44 0 01.267-1.366 1.368 1.368 0 011.065-.751c.834-.077 1.566.753 1.659 1.871a2.23 2.23 0 01-.485 1.663z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});