define("ember-svg-jar/inlined/type-cursor-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>type-cursor-alternate</title><path d=\"M22 6.249h-2.936a.25.25 0 01-.222-.364A2.986 2.986 0 0121.5 4.249a1 1 0 100-2 4.994 4.994 0 00-3.81 1.765.248.248 0 01-.19.088.255.255 0 01-.191-.088A4.991 4.991 0 0013.5 2.249a1 1 0 000 2 2.985 2.985 0 012.658 1.636.25.25 0 01-.222.364H2a2 2 0 00-2 2v7a2 2 0 002 2h14.137a.251.251 0 01.241.318 3 3 0 01-2.878 2.182 1 1 0 100 2 4.991 4.991 0 003.809-1.765.252.252 0 01.191-.084.248.248 0 01.19.088 4.994 4.994 0 003.81 1.765 1 1 0 000-2 3 3 0 01-2.878-2.182.25.25 0 01.241-.318H22a2 2 0 002-2v-7a2 2 0 00-2-2.004zM2 8.5a.25.25 0 01.25-.25h14a.25.25 0 01.25.25V15a.25.25 0 01-.25.25h-14A.25.25 0 012 15zM22 15a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25V8.5a.25.25 0 01.25-.25h3a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});