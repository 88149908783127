define("ember-svg-jar/inlined/medical-specialty-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-specialty-heart</title><path d=\"M20.522 12.153a.5.5 0 00-.146-.465l-.011-.011a2.891 2.891 0 00-2.423-.749 4.906 4.906 0 00-2.881 1.456A.75.75 0 1114 11.323a6.406 6.406 0 013.775-1.886 4.7 4.7 0 012.12.218.5.5 0 00.658-.564 7.588 7.588 0 00-1.376-3.154.5.5 0 01-.075-.455l1.109-3.395a.5.5 0 00-.32-.631L17.04.524a.5.5 0 00-.63.321l-.57 1.743a.5.5 0 01-.317.319.262.262 0 00-.154.145l-.512 1.2a.776.776 0 01-.046.091 2.107 2.107 0 00.054 2.244.75.75 0 11-1.248.832 3.615 3.615 0 01-.134-3.777.812.812 0 00.026-.051L14.2 1.97a.493.493 0 00.041-.2V.5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v1.524a.5.5 0 01-.395.489 4.144 4.144 0 00-1.27.5.5.5 0 01-.688-.18L6.969 1.25a.5.5 0 00-.683-.183l-2.6 1.5a.5.5 0 00-.186.683l1.512 2.612A.5.5 0 015 6.384a7.621 7.621 0 00-1.217 4.339.5.5 0 00.557.485C6.385 10.976 7.963 9.629 7.963 8a.75.75 0 011.5 0 4.007 4.007 0 01-.358 1.649.5.5 0 00.009.429l.794 1.586a.751.751 0 01-.336 1.007.75.75 0 01-1.006-.335l-.3-.606a.5.5 0 00-.743-.18A6.277 6.277 0 014.7 12.674a.5.5 0 00-.4.677c.028.071.057.141.087.212l.949 2.178a.5.5 0 00.361.291l3.681.733a.749.749 0 11-.293 1.47l-1.977-.393a.5.5 0 00-.556.69l1.326 3.044a.617.617 0 00.038.072 5.128 5.128 0 008.667-.069.694.694 0 00.038-.071l.54-1.242a.5.5 0 00-.357-.689 6.049 6.049 0 01-4.128-3.557.75.75 0 111.371-.61 4.3 4.3 0 003.7 2.779.5.5 0 00.45-.3l1.881-4.325a7.308 7.308 0 00.444-1.411z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});