define("ember-svg-jar/inlined/material-stone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>material-stone</title><path d=\"M12.75 14a.249.249 0 00.25.25h4.815a.25.25 0 00.185-.08.253.253 0 00.065-.191l-.341-4a.249.249 0 00-.249-.228H13a.249.249 0 00-.25.25zM11.25 10a.249.249 0 00-.25-.25H6.733a.25.25 0 00-.249.224l-.409 4a.252.252 0 00.063.193.248.248 0 00.186.082H11a.249.249 0 00.25-.25zM7.549 16.013a.251.251 0 00-.25-.263H1.647a.25.25 0 00-.247.213l-.895 5.962A.5.5 0 001 22.5h5.986a.25.25 0 00.25-.238zM16.7 15.75a.251.251 0 00-.25.263l.313 6.249a.25.25 0 00.25.238H23a.5.5 0 00.5-.563l-.746-5.968a.25.25 0 00-.248-.219zM4.313 14.25a.25.25 0 00.249-.224l.409-4a.252.252 0 00-.063-.193.249.249 0 00-.186-.082H2.547a.249.249 0 00-.247.212l-.6 4a.248.248 0 00.247.287zM14.948 15.988a.25.25 0 00-.249-.238H9.3a.25.25 0 00-.249.238l-.313 6.249a.255.255 0 00.069.185.251.251 0 00.181.078h6.022a.251.251 0 00.181-.078.255.255 0 00.069-.185zM16.051 8.012a.251.251 0 00.25.238h5.2a.251.251 0 00.248-.281l-.645-5.155A1.5 1.5 0 0019.617 1.5h-3.628a.251.251 0 00-.25.263zM9.452 7.987a.25.25 0 00.068.185.253.253 0 00.18.078h4.6a.253.253 0 00.181-.078.25.25 0 00.068-.185l-.312-6.249a.25.25 0 00-.25-.238h-3.973a.25.25 0 00-.25.238zM7.7 8.25a.251.251 0 00.25-.238l.312-6.249a.255.255 0 00-.069-.185.251.251 0 00-.182-.078h-3.15a1.493 1.493 0 00-1.484 1.278L2.6 7.962a.251.251 0 00.247.288zM19.48 9.75a.249.249 0 00-.249.271l.341 4a.25.25 0 00.249.228h2.431a.25.25 0 00.248-.281l-.5-4a.249.249 0 00-.248-.218z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});