define("ember-svg-jar/inlined/moose-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>moose-body</title><path d=\"M4.331 6.663h8.54c.773-.59 1.21-1.172 2.135-1.827a.217.217 0 00-.125-.394h-1.666a1.087 1.087 0 01-1.11-1.11 1.111 1.111 0 10-2.221 0 1.111 1.111 0 01-2.221 0V2.221a1.111 1.111 0 10-2.221 0v1.111a1.087 1.087 0 01-1.111 1.11 1.087 1.087 0 01-1.11-1.11V1.11A1.087 1.087 0 002.11 0 1.087 1.087 0 001 1.11v2.222a3.331 3.331 0 003.331 3.331zM11.734 9.453a.25.25 0 00-.34-.256A13.921 13.921 0 016.25 10h-2.5a3.5 3.5 0 00-3.5 3.5V23a1 1 0 002 0v-3a2.5 2.5 0 012.5-2.5h5.516a2.5 2.5 0 012.5 2.5v3a1 1 0 002 0v-5.81a.252.252 0 00-.088-.19 8.677 8.677 0 01-2.944-7.547zM19.056 4.924A6.2 6.2 0 0014.766 7a10.405 10.405 0 01-1.17.993.249.249 0 00-.1.153 7.6 7.6 0 001.751 7.325.251.251 0 00.373-.016c1.2-1.474 2.35-2.882 3.021-3.707a.976.976 0 01.761-.363 1.023 1.023 0 01.532.151 2.782 2.782 0 001.434.448A2.442 2.442 0 0023.25 11c1.55-1.807-.678-6.076-4.194-6.076zM19.5 8.5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});