define("ember-svg-jar/inlined/equipment-ladder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>equipment-ladder</title><rect x=\"2.5\" width=\"19\" height=\"4\" rx=\"1.5\" ry=\"1.5\"/><path d=\"M12.936 5.679a.5.5 0 00-.384-.179h-1a.5.5 0 00-.492.411l-.249 1.383a.25.25 0 01-.246.206H5.1a.25.25 0 01-.246-.294l.2-1.117a.5.5 0 00-.492-.589H3.548a.5.5 0 00-.492.412L.02 22.82a.988.988 0 00.8 1.16A.554.554 0 001 24a.988.988 0 00.98-.82l.443-2.474a.25.25 0 01.246-.206h5.463a.25.25 0 01.246.293L8.02 22.82a.988.988 0 00.8 1.16A.554.554 0 009 24a.988.988 0 00.98-.82l3.064-17.092a.5.5 0 00-.108-.409zM8.827 18.294a.25.25 0 01-.246.206H3.119a.25.25 0 01-.246-.294l.54-3A.25.25 0 013.659 15h5.462a.25.25 0 01.246.294zm.99-5.5a.25.25 0 01-.246.206H4.109a.25.25 0 01-.246-.294l.54-3a.25.25 0 01.246-.206h5.462a.25.25 0 01.246.294zM20.944 5.912a.5.5 0 00-.492-.412h-1.014a.5.5 0 00-.492.589l.2 1.117a.25.25 0 01-.246.294H15a1 1 0 000 2h4.351a.25.25 0 01.246.206l.54 3a.25.25 0 01-.246.294H13.38c-.529 0-.98.392-.98 1.18l1.62 9A.988.988 0 0015 24a.554.554 0 00.18-.02.988.988 0 00.8-1.16l-.358-2.027a.25.25 0 01.246-.293h5.463a.25.25 0 01.246.206l.443 2.474A.988.988 0 0023 24a.554.554 0 00.18-.02.988.988 0 00.8-1.16zm.129 12.5a.251.251 0 01-.192.09h-5.462a.25.25 0 01-.246-.206l-.54-3a.25.25 0 01.246-.296h5.462a.25.25 0 01.246.206l.54 3a.25.25 0 01-.054.204z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});