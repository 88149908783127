define("ember-svg-jar/inlined/analytics-graph-bar-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>analytics-graph-bar-1</title><path d=\"M3.167 11.5c-.369 0-.667.224-.667.5v8a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-8c0-.276-.3-.5-.667-.5zM11.167 14.5c-.369 0-.667.224-.667.5v5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-5c0-.276-.3-.5-.667-.5zM18.5 20.5h3a.483.483 0 00.5-.464V7.965c0-.257-.3-.465-.667-.465h-2.666c-.369 0-.667.208-.667.465v12.071a.483.483 0 00.5.464zM23 22H1a1 1 0 000 2h22a1 1 0 000-2zM19.053 3.941A1.785 1.785 0 0019.5 4a2 2 0 10-2-2 1.958 1.958 0 00.028.318.247.247 0 01-.076.225L12.678 7a.251.251 0 01-.231.06A1.832 1.832 0 0012 7a1.985 1.985 0 00-.989.264.253.253 0 01-.236.007L6.111 4.938a.255.255 0 01-.136-.192 1.993 1.993 0 10-.986 1.991.253.253 0 01.236-.007l4.664 2.332a.256.256 0 01.136.193A2 2 0 0014 9a1.946 1.946 0 00-.028-.317.249.249 0 01.076-.226L18.822 4a.251.251 0 01.231-.059z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});