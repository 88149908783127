define("ember-svg-jar/inlined/casino-pull-machine-slot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>casino-pull-machine-slot</title><path d=\"M23 21.667a.994.994 0 01-.736-.323l-4.772-5.2a2.96 2.96 0 00-2.073-.83H15.4L9.708 15h-.017a2.5 2.5 0 01.049-5l2.805-.019a1 1 0 00.732-.321 1.184 1.184 0 00.257-.887 2.846 2.846 0 00-.751-1.751A3.718 3.718 0 0010.212 6h-.025a4 4 0 00-2.6.985 2.707 2.707 0 01-3.6-.03 3 3 0 01-.094-4.364A8.956 8.956 0 0110.156 0h.056a8.925 8.925 0 016.441 2.714L23.7 9.54a1 1 0 11-1.4 1.436L15.242 4.13A7.046 7.046 0 0010.168 2 6.963 6.963 0 005.3 4.016a1 1 0 00.031 1.455.71.71 0 00.939.008A6 6 0 0110.175 4a5.5 5.5 0 013.944 1.534 4.77 4.77 0 011.413 3.147 3.141 3.141 0 01-.787 2.338 3.01 3.01 0 01-2.187.962L9.753 12a.5.5 0 000 1l5.69.32h.037a4.925 4.925 0 013.445 1.441l4.805 5.23a1 1 0 01-.73 1.676z\"/><path d=\"M9.5 16a1 1 0 00-1 1 1 1 0 01-1 1H5.25a.25.25 0 01-.25-.25v-.25A2.5 2.5 0 002.5 15h-.25a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v22a1 1 0 001.98.2.25.25 0 01.245-.2H2.5A2.5 2.5 0 005 20.5v-.25a.25.25 0 01.25-.25H7.5a3 3 0 003-3 1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});