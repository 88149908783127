define("ember-svg-jar/inlined/match-fire-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>match-fire-1</title><path d=\"M9.5.375a.5.5 0 00-.493.582 27.012 27.012 0 01.433 5.205c-.016.4-.327.119-.327.119l-.259-.26a.5.5 0 00-.708 0A9.741 9.741 0 005.5 12.375a6.5 6.5 0 005.254 6.372.245.245 0 01.2.239v3.389a1.25 1.25 0 002.5 0v-3.481a.241.241 0 01.185-.234 6.508 6.508 0 004.86-6.285c.001-5.72-4.709-12-8.999-12zm5.6 12.365a3.115 3.115 0 01-6.156-.957 4.879 4.879 0 011.156-1.97c.144-.165.3-.15.386.018.126.246.311.606.462.883.086.16.424.088.514-.056a5.891 5.891 0 00.508-3.869.248.248 0 01.375-.244A6.474 6.474 0 0115.1 12.74z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});