define("ember-svg-jar/inlined/pollution-pm10", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pollution-pm10</title><path d=\"M18.253 5.532a1 1 0 00-1.125.477l-1.16 2.063a.251.251 0 01-.436 0l-1.16-2.063A1 1 0 0012.5 6.5v8a1 1 0 102 0v-3.229a.25.25 0 01.468-.123.9.9 0 001.564 0 .25.25 0 01.468.123V14.5a1 1 0 102 0v-8a1 1 0 00-.747-.968zM7 5.5a1 1 0 00-1 1v8a1 1 0 102 0V12a.5.5 0 01.5-.5H9a3 3 0 000-6zm3 3a1 1 0 01-1 1h-.5A.5.5 0 018 9V8a.5.5 0 01.5-.5H9a1 1 0 011 1zM18.25 22a.25.25 0 01-.25-.25v-3.5a1.25 1.25 0 00-2-1l-1.2.9a.75.75 0 00.9 1.2l.4-.3a.25.25 0 01.4.2v2.5a.25.25 0 01-.25.25.75.75 0 000 1.5h2a.75.75 0 000-1.5zM21.75 17a2.252 2.252 0 00-2.25 2.25v2a2.25 2.25 0 004.5 0v-2A2.252 2.252 0 0021.75 17zm.75 4.25a.75.75 0 01-1.5 0v-2a.75.75 0 011.5 0z\"/><path d=\"M12.41 18.993C12.282 19 12.147 19 12 19a11.867 11.867 0 01-3.287-.458 1.024 1.024 0 00-.684.038l-3.622 1.553a.251.251 0 01-.315-.356l1.1-1.892a.993.993 0 00-.22-1.269A7.558 7.558 0 012 10.75C2 6.2 6.486 2.5 12 2.5s10 3.7 10 8.25a6.914 6.914 0 01-.529 2.648.99.99 0 00.406 1.228 1 1 0 001.445-.478A8.877 8.877 0 0024 10.75C24 5.1 18.617.5 12 .5S0 5.1 0 10.75a9.3 9.3 0 002.776 6.563.5.5 0 01.083.609L1.2 20.763A1.49 1.49 0 002.486 23a1.507 1.507 0 00.589-.121l5.265-2.256a.5.5 0 01.32-.025A13.937 13.937 0 0012 21c.148 0 .286 0 .417-.006a1.025 1.025 0 00.981-1.185.967.967 0 00-.988-.816z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});