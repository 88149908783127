define("ember-svg-jar/inlined/performance-tablet-increase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>performance-tablet-increase</title><path d=\"M4.916 11.158a1.25 1.25 0 101.767 1.768l3.543-3.543a.25.25 0 01.354 0l.939.939a.749.749 0 00.531.22.761.761 0 00.287-.057.75.75 0 00.463-.693v-4a.75.75 0 00-.75-.75h-4a.75.75 0 00-.531 1.28l.94.94a.249.249 0 010 .353z\"/><path d=\"M15.848 12.574a1 1 0 001.7-.713V3.292a3 3 0 00-3-3h-11a3 3 0 00-3 3v15.5a3 3 0 003 3h9.5a1 1 0 000-2H3.55a1 1 0 01-1-1v-15.5a1 1 0 011-1h11a1 1 0 011 1v8.569a1 1 0 00.298.713z\"/><path d=\"M23.421 23.575a.25.25 0 00-.014-.258l-1.438-2.105a.253.253 0 01-.044-.141v-5.832c0-1.333-1.18-2.635-2.463-3.725a.25.25 0 00-.412.19v3.588a3.336 3.336 0 01-.465 1.026.752.752 0 01-1.062.009l-2.9-2.853a.238.238 0 00-.075-.051 1.36 1.36 0 00-1.515.276 1.33 1.33 0 00-.183.277 1.355 1.355 0 00.179 1.663l.009.009 3.662 4.137a.251.251 0 01.063.166V22.5a2.613 2.613 0 00.307 1.07.249.249 0 00.225.143H23.2a.25.25 0 00.221-.138z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});