define("ember-svg-jar/inlined/multiple-actions-wifi.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-wifi</title><path d=\"M18.518 12.039a.25.25 0 00.266-.306 5.254 5.254 0 00-.4-1.156c-.454-.907-1.9-1.44-3.911-2.179l-.485-.172a1.8 1.8 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 0012 0a2.986 2.986 0 00-1.341.314.247.247 0 00-.131.16.251.251 0 00.034.2 5 5 0 01.838 2.811 6.259 6.259 0 01-.922 3.624.254.254 0 00-.029.216.249.249 0 00.151.156c1.917.716 3.3 1.328 3.9 2.537a7.186 7.186 0 01.61 1.968.25.25 0 00.293.2 11.3 11.3 0 013.115-.147z\"/><path d=\"M13.739 12.632a.248.248 0 00.164-.272 6.09 6.09 0 00-.521-1.783c-.454-.907-1.9-1.44-3.911-2.179l-.485-.172a1.8 1.8 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 007 0a3.219 3.219 0 00-3.145 3.485 4.609 4.609 0 001.192 3.538 1.877 1.877 0 010 1.185L4.53 8.4c-2.007.737-3.457 1.27-3.911 2.177A7.531 7.531 0 000 13.5a.5.5 0 00.5.5h10.491a.249.249 0 00.14-.043 11.365 11.365 0 012.608-1.325z\"/><circle cx=\"17.499\" cy=\"22.25\" r=\"1.75\"/><path d=\"M11.378 15.627a1 1 0 001.243 1.567 7.825 7.825 0 019.757 0 1 1 0 101.243-1.567 9.818 9.818 0 00-12.243 0z\"/><path d=\"M13.853 18.451a1 1 0 001.293 1.526 3.587 3.587 0 014.707 0 1 1 0 001.293-1.526 5.624 5.624 0 00-7.293 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});