define("ember-svg-jar/inlined/award-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>award-star</title><path d=\"M6.525 10.135l3.113-.179 1.192 3.023a1.26 1.26 0 001.25.8 1.28 1.28 0 001.181-.947l1-3.577 3.576-1a1.279 1.279 0 00.948-1.18 1.258 1.258 0 00-.8-1.251l-3.029-1.187.18-3.138a1.259 1.259 0 00-.761-1.187A1.23 1.23 0 0013.01.6l-1.886 1.885-3.306-1.314-.03-.012A1.294 1.294 0 006.17 2.818l1.313 3.308L5.6 8.011a1.231 1.231 0 00-.29 1.361 1.272 1.272 0 001.2.763zM7.284 19.784a1.5 1.5 0 00-1.5 1.5v2a.5.5 0 00.5.5h12a.5.5 0 00.5-.5v-2a1.5 1.5 0 00-1.5-1.5zM16.284 18.284a.5.5 0 00.5-.5v-1.5a1.847 1.847 0 00-1.348-1.727l-3.03-.758a.477.477 0 00-.243 0l-3.029.757a1.849 1.849 0 00-1.35 1.728v1.5a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});