define("ember-svg-jar/inlined/real-estate-search-house-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-search-house-1</title><path d=\"M12 0a12 12 0 1012 12A12.01 12.01 0 0012 0zm0 22.5A10.5 10.5 0 1122.5 12 10.506 10.506 0 0112 22.5z\"/><path d=\"M20.329 11.2l-7.342-6.424a1.5 1.5 0 00-1.974 0L3.671 11.2a.5.5 0 00.329.876h1.5a.5.5 0 01.5.5v6a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v3.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-6a.5.5 0 01.5-.5H20a.5.5 0 00.329-.876z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});