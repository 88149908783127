define("ember-svg-jar/inlined/vectors-anchor-square-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vectors-anchor-square-1</title><path d=\"M21.666 17.2a.249.249 0 01-.166-.235v-9.93a.249.249 0 01.167-.235A3.5 3.5 0 1017.2 2.334a.251.251 0 01-.236.166H7.036a.25.25 0 01-.236-.167A3.5 3.5 0 102.334 6.8a.249.249 0 01.166.235v9.93a.249.249 0 01-.167.235A3.5 3.5 0 106.8 21.666a.251.251 0 01.236-.166h9.928a.25.25 0 01.236.167 3.5 3.5 0 104.466-4.467zM17.2 19.334a.251.251 0 01-.236.166H7.036a.251.251 0 01-.236-.166A3.519 3.519 0 004.666 17.2a.249.249 0 01-.166-.235v-9.93a.25.25 0 01.166-.235A3.519 3.519 0 006.8 4.666a.251.251 0 01.236-.166h9.928a.251.251 0 01.236.166A3.519 3.519 0 0019.334 6.8a.249.249 0 01.166.235v9.93a.25.25 0 01-.166.235 3.519 3.519 0 00-2.134 2.134zM20.5 2A1.5 1.5 0 1119 3.5 1.5 1.5 0 0120.5 2zM2 3.5A1.5 1.5 0 113.5 5 1.5 1.5 0 012 3.5zM3.5 22A1.5 1.5 0 115 20.5 1.5 1.5 0 013.5 22zm17 0a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});