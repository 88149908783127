define("ember-svg-jar/inlined/single-man-actions-alarm.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-alarm</title><path d=\"M.619 18.062H2.41a.247.247 0 01.247.227l.443 5.257a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h1.791a.494.494 0 00.495-.5V14.1a5.443 5.443 0 10-10.886 0v3.464a.494.494 0 00.495.498z\"/><path d=\"M1.361 4.206A4.206 4.206 0 105.567 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM23.539 19.927a4.042 4.042 0 01-1.147-2.689v-1.65a4.454 4.454 0 10-8.907 0v1.649a4.046 4.046 0 01-1.147 2.689l-.238.312a.495.495 0 00.4.792h10.881a.495.495 0 00.4-.792zM19.6 22.212a.5.5 0 00-.391-.191h-2.544a.494.494 0 00-.479.618 1.808 1.808 0 003.5 0 .492.492 0 00-.086-.427z\"/><path d=\"M.619 18.062H2.41a.247.247 0 01.247.227l.443 5.257a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h1.791a.494.494 0 00.495-.5V14.1a5.443 5.443 0 10-10.886 0v3.464a.494.494 0 00.495.498z\"/><path d=\"M1.361 4.206A4.206 4.206 0 105.567 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM23.539 19.927a4.042 4.042 0 01-1.147-2.689v-1.65a4.454 4.454 0 10-8.907 0v1.649a4.046 4.046 0 01-1.147 2.689l-.238.312a.495.495 0 00.4.792h10.881a.495.495 0 00.4-.792zM19.6 22.212a.5.5 0 00-.391-.191h-2.544a.494.494 0 00-.479.618 1.808 1.808 0 003.5 0 .492.492 0 00-.086-.427z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});