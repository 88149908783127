define("ember-svg-jar/inlined/wolf-sit-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wolf-sit-1</title><path d=\"M23.488 12.029A5.43 5.43 0 0021 8.553a.5.5 0 00-.721.447c0 3.457-.989 6.495-2.542 7.887A9.482 9.482 0 0013 12.553 13.759 13.759 0 018.521 8.22a14.147 14.147 0 002.979-.773 2.091 2.091 0 00.751-2.605.5.5 0 00-.316-.316A23.419 23.419 0 019.5 3.553a4.5 4.5 0 01-.9-.663A2.715 2.715 0 006.779 2H3.454L1.091.11A.5.5 0 00.562.05.5.5 0 00.279.5v7.76a14.882 14.882 0 001.38 6.54 7.33 7.33 0 01.62 3.2v5.5a.5.5 0 00.5.5h2a.25.25 0 00.25-.25V18a.75.75 0 011.5 0v5.75a.25.25 0 00.25.25h2a.25.25 0 00.25-.25v-.26a3.755 3.755 0 013.75-3.75h.482a.75.75 0 010 1.5h-.482a2.253 2.253 0 00-2.25 2.25v.26a.25.25 0 00.25.25h4c1.08 0 3.247-.984 3.479-3.968a5.42 5.42 0 004.442-2.754 6.463 6.463 0 00.788-5.249zM5.529 4.751a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});