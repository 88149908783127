define("ember-svg-jar/inlined/single-woman-actions-setting.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-setting</title><path d=\"M11.055 17.2a.25.25 0 000-.34l-.174-.188a3.273 3.273 0 01.486-4.894.25.25 0 00-.021-.42.723.723 0 01-.385-.666v-.467a.249.249 0 01.249-.25A6.374 6.374 0 0015 9.081a.387.387 0 00.084-.592c-.494-.538-.836-1.186-.981-3.318C13.837 1.347 11.235 0 9 0S4.161 1.347 3.9 5.171c-.146 2.132-.487 2.78-.981 3.318a.385.385 0 00-.1.315.391.391 0 00.181.277 6.35 6.35 0 003.782.9.251.251 0 01.254.25v.462a.779.779 0 01-.512.73c-2.9 1.065-5.212 1.825-5.764 2.922a7.818 7.818 0 00-.76 2.73.384.384 0 00.1.3.392.392 0 00.29.127h10.281a.249.249 0 00.193-.092l.018-.02z\"/><circle cx=\"17.749\" cy=\"17.031\" r=\"1.5\"/><path d=\"M23.516 15.654a1.782 1.782 0 00-1.7-2.953l-1.411.327a.455.455 0 01-.534-.311l-.423-1.392a1.775 1.775 0 00-3.4 0l-.423 1.392a.454.454 0 01-.534.311l-1.41-.328a1.782 1.782 0 00-1.7 2.952l.988 1.065a.463.463 0 010 .625l-.988 1.065a1.783 1.783 0 001.7 2.953l1.411-.327a.454.454 0 01.534.311l.423 1.391a1.775 1.775 0 003.4 0l.423-1.392a.452.452 0 01.534-.311l1.411.327a1.782 1.782 0 001.7-2.952l-.988-1.066a.463.463 0 010-.625zm-5.767 4.377a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});