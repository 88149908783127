define("ember-svg-jar/inlined/single-woman-actions-shield.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-shield</title><path d=\"M9.5 16.847v-3.393a2.963 2.963 0 01.167-.978.5.5 0 00-.325-.641 7.193 7.193 0 00-2.136-.335 7.543 7.543 0 00-7.185 5.359.5.5 0 00.479.641H9a.5.5 0 00.5-.51v-.143zM24 13.454a1.545 1.545 0 00-.928-1.416A16.393 16.393 0 0017.478 11a16 16 0 00-5.551 1.04A1.546 1.546 0 0011 13.454v3.393a7.716 7.716 0 005.19 6.805l.553.21a2.054 2.054 0 001.47 0l.554-.21C21.848 22.478 24 19.68 24 16.847zm-5.75 1.794a.5.5 0 00.5.5h1.75a.75.75 0 010 1.5h-1.75a.5.5 0 00-.5.5V19.5a.75.75 0 11-1.5 0v-1.75a.5.5 0 00-.5-.5H14.5a.75.75 0 110-1.5h1.75a.5.5 0 00.5-.5V13.5a.75.75 0 111.5 0zM1.568 10.442a1 1 0 001.383-.3 5.162 5.162 0 00.454-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .251.251 0 01.228-.071.255.255 0 01.184.153 5.093 5.093 0 00.454.9 1 1 0 001.68-1.085 4.569 4.569 0 01-.642-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248A4.564 4.564 0 011.27 9.06a1 1 0 00.298 1.382zM7.21 8.6a2.7 2.7 0 01-2.741-2.352.5.5 0 01.324-.577 5.7 5.7 0 002.245-1.439.25.25 0 01.361 0 5.712 5.712 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.21 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});