define("ember-svg-jar/inlined/responsive-design-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>responsive-design-laptop</title><path d=\"M24 2a2 2 0 00-2-2h-5.5a2 2 0 00-2 2v6.25a.25.25 0 01-.25.25h-4a.25.25 0 01-.25-.25V2a2 2 0 00-2-2H2a2 2 0 00-2 2v11.5a2 2 0 002 2h6a2 2 0 002-2v-2.75a.25.25 0 01.25-.25h4a.25.25 0 01.25.25v2.75a2 2 0 002 2H22a2 2 0 002-2zM7.5 2a.5.5 0 01.5.5v8a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5zM4 13.5a.5.5 0 010-1h2a.5.5 0 010 1zm18-.25a.25.25 0 01-.25.25h-5a.25.25 0 01-.25-.25v-11a.25.25 0 01.25-.25h5a.25.25 0 01.25.25zM21.5 20h-.75a.25.25 0 01-.25-.25V17.5a1 1 0 00-2 0v2.25a.25.25 0 01-.25.25H13.5a.489.489 0 00-.5.479 1 1 0 11-2 0 .489.489 0 00-.5-.479H5.75a.25.25 0 01-.25-.25V17.5a1 1 0 00-2 0v2.25a.25.25 0 01-.25.25H2.5a.5.5 0 00-.5.5v1A2.5 2.5 0 004.5 24h15a2.5 2.5 0 002.5-2.5v-1a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});