define("ember-svg-jar/inlined/baby-care-bottle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baby-care-bottle</title><path d=\"M19.75 10a2.252 2.252 0 00-2.05-2.24.5.5 0 01-.432-.341 4.2 4.2 0 00-1.491-1.964A3.074 3.074 0 0114.5 2.5a2.5 2.5 0 00-5 0 3.072 3.072 0 01-1.278 2.955 4.193 4.193 0 00-1.49 1.962.5.5 0 01-.431.34 2.251 2.251 0 00-1.028 4.128.5.5 0 01.227.415V21a3 3 0 003 3h7a3 3 0 003-3v-8.7a.5.5 0 01.227-.419A2.251 2.251 0 0019.75 10zM9.784 7.75a.431.431 0 01-.277-.762A4.993 4.993 0 0011.5 2.5a.5.5 0 011 0 4.993 4.993 0 001.993 4.487.432.432 0 01-.278.763zM16.5 21a1 1 0 01-1 1h-7a1 1 0 01-1-1v-8a.5.5 0 01.5-.5h8a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1.5a.75.75 0 000 1.5H16a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1.5a.75.75 0 000 1.5H16a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});