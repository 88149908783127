define("ember-svg-jar/inlined/phone-antenna", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-antenna</title><path d=\"M10.14.261a.748.748 0 00-1.06.024L6.65 2.822a.25.25 0 01-.33.027L3.3.588a.5.5 0 00-.7.1 5.4 5.4 0 00.14 6.683.25.25 0 010 .317l-2.535 3.1a.73.73 0 00-.142.8.733.733 0 00.712.4h5.691a.706.706 0 00.57-1.2l-.85-1.039a.25.25 0 01.223-.407 4.4 4.4 0 00.528.033 5.548 5.548 0 00.779-.056 5.393 5.393 0 003.57-2.125.5.5 0 00-.1-.7L7.932 4.056a.25.25 0 01-.03-.373l2.261-2.361A.75.75 0 0010.14.261zM18.275 8.2a1 1 0 001-1v-.711a4 4 0 00-4-4H13.02a1 1 0 000 2h2.255a2 2 0 012 2V7.2a1 1 0 001 1zM4.275 17.489v-2.544a1 1 0 00-2 0v2.544a4 4 0 004 4h5.975a1 1 0 100-2H6.275a2 2 0 01-2-2zM24 11.2a1.752 1.752 0 00-1.75-1.75h-6a1.752 1.752 0 00-1.75 1.75v11a1.752 1.752 0 001.75 1.75h6A1.752 1.752 0 0024 22.2zm-2 9a.25.25 0 01-.25.25h-5a.25.25 0 01-.25-.25v-8.5a.251.251 0 01.25-.25h5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});