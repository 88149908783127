define("ember-svg-jar/inlined/file-doc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-doc</title><path d=\"M6.451 9.827c-.1-.046-.326-.048-.326.164v3.978a.237.237 0 00.327.209 2.374 2.374 0 000-4.351zM12 9.623a.877.877 0 00-.875.875v3a.875.875 0 001.75 0v-3A.877.877 0 0012 9.623z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zm-17 11.123A.625.625 0 014.875 15V9a.625.625 0 01.625-.627 3.625 3.625 0 010 7.25zm8.625-2.123a2.125 2.125 0 01-4.25 0v-3a2.125 2.125 0 114.25 0zm4.375.875a.625.625 0 010 1.25 3.625 3.625 0 010-7.25.625.625 0 010 1.25 2.375 2.375 0 000 4.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});