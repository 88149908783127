define("ember-svg-jar/inlined/landmark-chinese-pagoda-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-chinese-pagoda-1</title><path d=\"M1.838 12.913a11.3 11.3 0 002.222.475.5.5 0 01.44.5V15a.5.5 0 00.5.5h14a.5.5 0 00.5-.5v-1.116a.5.5 0 01.44-.5 11.3 11.3 0 002.222-.475.751.751 0 00-.169-1.459 10.7 10.7 0 01-4.709-1.365A.505.505 0 0017 10H7a.505.505 0 00-.284.089 10.7 10.7 0 01-4.71 1.366.751.751 0 00-.168 1.458zM23 22h-1.5a.5.5 0 01-.5-.5V20a.5.5 0 01.5-.5A1.5 1.5 0 0023 18v-.5a.5.5 0 00-.5-.5h-21a.5.5 0 00-.5.5v.5a1.5 1.5 0 001.5 1.5.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5H1a1 1 0 000 2h22a1 1 0 000-2zM5 20a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5zM5.185 5.617a4.775 4.775 0 002.34.621.5.5 0 01.475.5V8a.5.5 0 00.5.5h7A.5.5 0 0016 8V6.738a.5.5 0 01.474-.5 4.772 4.772 0 002.342-.622.75.75 0 00-.371-1.379 13.4 13.4 0 01-4.629-1.109.5.5 0 01-.025-.905l.656-.329a1 1 0 00-.894-1.789L12.224.77a.5.5 0 01-.448 0L10.447.105a1 1 0 00-.894 1.789l.656.329a.5.5 0 01-.025.905 13.4 13.4 0 01-4.627 1.109.75.75 0 00-.372 1.38z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});