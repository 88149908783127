define("ember-svg-jar/inlined/file-hqx", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-hqx</title><path d=\"M12.5 13.626a.876.876 0 00-.875.875V17a.875.875 0 001.75 0v-2.5a.876.876 0 00-.875-.874zM17.5 6h3.9a.25.25 0 00.177-.427L16.427.428A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.251A.25.25 0 0014.75 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.251A.25.25 0 0021.75 7zM9.625 19a.625.625 0 01-1.25 0v-2.124a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V19a.625.625 0 01-1.25 0v-6a.625.625 0 111.25 0v2.125a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V13a.625.625 0 111.25 0zm5-2a2.112 2.112 0 01-.993 1.786c-.029.019-.044.088.029.147l.727.577a.633.633 0 01.1.879.627.627 0 01-.878.1l-1.93-1.53A2.126 2.126 0 0110.375 17v-2.5a2.125 2.125 0 114.25 0zm4.934 1.721a.625.625 0 11-1.118.558s-.752-1.5-.814-1.629-.194-.12-.243-.023l-.825 1.652a.625.625 0 01-.559.346.625.625 0 01-.559-.9l1.3-2.609a.252.252 0 000-.224l-1.3-2.609a.625.625 0 111.118-.558s.778 1.564.833 1.666a.122.122 0 00.22-.007l.829-1.659a.625.625 0 011.118.558l-1.3 2.609a.252.252 0 000 .224z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});