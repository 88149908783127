define("ember-svg-jar/inlined/touchpad-finger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>touchpad-finger</title><path d=\"M17.115 17H14.5a.249.249 0 01-.25-.25v-6.377a2.375 2.375 0 00-4.75 0V18.9a.251.251 0 01-.455.145L8.022 17.6a2.117 2.117 0 00-2.662-.659 2.075 2.075 0 00-1.026 2.423 44.186 44.186 0 002.257 4.373.5.5 0 00.441.263h14.222a.5.5 0 00.5-.5v-1.669A4.811 4.811 0 0017.115 17z\"/><path d=\"M5.25 11a1 1 0 01-1-1V3a1 1 0 011-1h13.5a1 1 0 011 1v7a1 1 0 01-1 1H16a.249.249 0 00-.25.25v1.5A.249.249 0 0016 13h2.75a3 3 0 003-3V3a3 3 0 00-3-3H5.25a3 3 0 00-3 3v7a3 3 0 003 3h2.5a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});