define("ember-svg-jar/inlined/clothes-design-animal-skin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>clothes-design-animal-skin</title><path d=\"M23.351 2.1A1.5 1.5 0 0021.974 0h-.89a1.5 1.5 0 00-1.306.765l-3.031 5.386a.456.456 0 01-.819-.4 4 4 0 10-7.856 0c.123.57-.525.887-.819.4L4.223.766A1.5 1.5 0 002.916 0h-.891A1.5 1.5 0 00.649 2.1S5 10.083 5 14.08v1.092a4.515 4.515 0 01-.593 2.233L1.92 21.756A1.5 1.5 0 003.223 24h1.4a1.5 1.5 0 001-.379l3.863-3.434 1.46 1.459a1.5 1.5 0 002.121 0l1.46-1.459 3.863 3.434a1.5 1.5 0 001 .379h1.4a1.5 1.5 0 001.3-2.244l-2.487-4.351A4.51 4.51 0 0119 15.172V14.08c0-3.872 4.351-11.98 4.351-11.98z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});