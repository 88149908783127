define("ember-svg-jar/inlined/beach-sunbed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beach-sunbed</title><path d=\"M6.194 20.75h-1.25a.5.5 0 00-.4.2l-1.09 1.45a.985.985 0 00.2 1.4 1 1 0 001.4-.2l1.54-2.05a.5.5 0 00-.4-.8zM16.414 20.949a.5.5 0 00-.4-.2h-1.25a.5.5 0 00-.4.8L15.9 23.6a1 1 0 001.4.2.985.985 0 00.2-1.4zM23.262 9.69a2.515 2.515 0 00-3.616.023l-5.552 4.878a.5.5 0 01-.366.159H2.5a2.5 2.5 0 000 5h12.931a1.508 1.508 0 001.069-.45l6.8-6.086a2.519 2.519 0 00-.038-3.524z\"/><circle cx=\"6.5\" cy=\"6.5\" r=\"3\"/><path d=\"M6.5 2.5a.75.75 0 00.75-.75v-1a.75.75 0 00-1.5 0v1a.75.75 0 00.75.75zM9.858 3.891a.749.749 0 00.531-.219l.707-.707A.75.75 0 0010.035 1.9l-.707.707a.75.75 0 00.53 1.28zM10.5 6.5a.75.75 0 00.75.75h1a.75.75 0 000-1.5h-1a.75.75 0 00-.75.75zM10.389 9.329a.75.75 0 00-1.061 1.061l.707.707a.75.75 0 001.065-1.061zM5.75 11.25v1a.75.75 0 001.5 0v-1a.75.75 0 00-1.5 0zM2.611 9.329l-.707.707A.75.75 0 002.965 11.1l.707-.707a.75.75 0 00-1.061-1.064zM.75 7.25h1a.75.75 0 000-1.5h-1a.75.75 0 000 1.5zM2.611 3.672a.75.75 0 001.061-1.061L2.965 1.9A.75.75 0 001.9 2.965z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});