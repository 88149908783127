define("ember-svg-jar/inlined/graph-stats-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>graph-stats-square</title><path d=\"M19.354 14.531a2.807 2.807 0 01-2.554-1.468.5.5 0 00-.9.164l-1.4 5.852a1.5 1.5 0 01-2.93-.049L9.521 9.049a.5.5 0 00-.98.009 24.423 24.423 0 01-.749 3.062 3.108 3.108 0 01-1.938 2.294 3.678 3.678 0 01-.98.117H.5a.5.5 0 00-.5.5V21.5A2.5 2.5 0 002.5 24h19a2.5 2.5 0 002.5-2.5v-6.47a.5.5 0 00-.5-.5z\"/><path d=\"M4.874 12.531c.822 0 1-.3 1.866-4.851.213-1.115.464-2.433.788-3.985a1.5 1.5 0 012.937 0l2.16 10.533a.5.5 0 00.976.016l1.06-4.419a1.5 1.5 0 012.853-.205c.119.3.228.594.333.88.6 1.622.836 2.03 1.507 2.03H23.5a.5.5 0 00.5-.5V2.5A2.5 2.5 0 0021.5 0h-19A2.5 2.5 0 000 2.5v9.53a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});