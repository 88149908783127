define("ember-svg-jar/inlined/smartwatch-shopping-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smartwatch-shopping-cart</title><path d=\"M16.5 11h-.758a.249.249 0 01-.223-.138L14.4 8.646a.75.75 0 10-1.34.673l.664 1.319A.25.25 0 0113.5 11h-3a.25.25 0 01-.224-.362l.664-1.319a.75.75 0 10-1.34-.673l-1.119 2.216a.249.249 0 01-.223.138H7.5a.5.5 0 00-.488.607l.859 3.919a1.174 1.174 0 001.2.973h5.85a1.174 1.174 0 001.2-.975l.859-3.917A.5.5 0 0016.5 11z\"/><path d=\"M21.5 5.5a3 3 0 00-3-3h-1.085a1 1 0 01-.949-.684L15.975.342A.5.5 0 0015.5 0h-7a.5.5 0 00-.475.342l-.491 1.474a1 1 0 01-.949.684H5.5a3 3 0 00-3 3v13a3 3 0 003 3h1.085a1 1 0 01.949.684l.491 1.474A.5.5 0 008.5 24h7a.5.5 0 00.475-.342l.491-1.474a1 1 0 01.949-.684H18.5a3 3 0 003-3zm-2 13a1 1 0 01-1 1h-13a1 1 0 01-1-1v-13a1 1 0 011-1h13a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});