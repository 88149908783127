define("ember-svg-jar/inlined/cash-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-pin</title><path d=\"M4 8.21c0 3.563 5.006 11.762 7.158 15.12a1 1 0 001.684 0C14.521 20.71 20 11.877 20 8.21a8 8 0 00-16 0zm8.75-5.7a1 1 0 011 1v.25a.251.251 0 00.25.25h.25a1 1 0 010 2H11.7a.954.954 0 00-.529 1.748l2.76 1.84a2.941 2.941 0 01-1.451 5.387.25.25 0 00-.234.249v.276a1 1 0 01-2 0v-.25a.25.25 0 00-.25-.25H9.75a1 1 0 010-2h2.55a.953.953 0 00.529-1.747l-2.76-1.841a2.952 2.952 0 011.451-5.4.25.25 0 00.234-.25v-.26a1 1 0 01.996-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});