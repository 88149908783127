define("ember-svg-jar/inlined/currency-euro-dollar-exchange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-euro-dollar-exchange</title><path d=\"M21.4 16.526a.489.489 0 00-.692 0l-1.954 1.965a.49.49 0 00.347.835h.551a.243.243 0 01.205.109.246.246 0 01.021.231 3.956 3.956 0 01-3.708 2.12.98.98 0 000 1.96 5.693 5.693 0 005.78-4.23.245.245 0 01.24-.2h.827a.49.49 0 00.344-.837zM2.11 7.461a.489.489 0 00.692 0l1.954-1.967a.489.489 0 00-.347-.835H3.86a.244.244 0 01-.226-.34A3.955 3.955 0 017.34 2.2a.98.98 0 000-1.959A5.7 5.7 0 001.555 4.47a.245.245 0 01-.24.2H.488a.489.489 0 00-.343.837zM8 9.267a.246.246 0 00-.243-.2 7.345 7.345 0 107.416 7.415.245.245 0 00-.2-.242A8.829 8.829 0 018 9.267zm-.17 7.389a.735.735 0 010 1.47H6.04a.244.244 0 00-.228.333A1.908 1.908 0 007.834 19.6a.735.735 0 010 1.47 3.3 3.3 0 01-3.583-2.74.244.244 0 00-.241-.2h-.095a.735.735 0 010-1.47h.095a.245.245 0 00.241-.2 3.3 3.3 0 013.583-2.74.735.735 0 010 1.47 1.908 1.908 0 00-2.022 1.136.244.244 0 00.228.333z\"/><path d=\"M24 7.594a7.348 7.348 0 10-7.348 7.348A7.357 7.357 0 0024 7.594zm-6.613-4.409a.245.245 0 00.245.245h.734a.735.735 0 110 1.469h-2.324a.8.8 0 00-.3 1.541l2.357.943a2.262 2.262 0 01-.508 4.341.243.243 0 00-.208.242v.485a.735.735 0 01-1.47 0V12a.245.245 0 00-.245-.245h-.735a.735.735 0 010-1.469h2.324a.8.8 0 00.3-1.541L15.2 7.8a2.262 2.262 0 01.508-4.34.245.245 0 00.209-.243v-.481a.735.735 0 111.47 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});