define("ember-svg-jar/inlined/e-learning-laptop-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-learning-laptop-1</title><path d=\"M23.753 19.238l-2.386-4a.378.378 0 01-.024-.34 1.973 1.973 0 00.156-.773v-10a2 2 0 00-2-2H4.5a2 2 0 00-2 2v10a1.98 1.98 0 00.157.775.372.372 0 01-.025.337l-2.386 4a1.716 1.716 0 00-.018 1.744 1.755 1.755 0 001.546.894h20.45a1.755 1.755 0 001.546-.894 1.716 1.716 0 00-.017-1.743zm-9.253.638h-5a.75.75 0 010-1.5h5a.75.75 0 110 1.5zm4.853-5.9a.5.5 0 01-.353.146H5a.5.5 0 01-.5-.5v-9a.5.5 0 01.5-.5h14a.5.5 0 01.5.5v9a.5.5 0 01-.147.358z\"/><path d=\"M14.342 9.886l-2.105.7a.768.768 0 01-.474 0l-2.105-.7a.5.5 0 00-.658.475v.839a1 1 0 00.667.943l1.834.648a1.51 1.51 0 001 0l1.833-.648A1 1 0 0015 11.2v-.839a.5.5 0 00-.658-.475z\"/><path d=\"M16.658 7.152l-4.5-1.5a.5.5 0 00-.316 0l-4.5 1.5A.5.5 0 007 7.626v2.5a.5.5 0 001 0V8.667a.251.251 0 01.329-.238L11.842 9.6a.493.493 0 00.316 0l4.5-1.5a.5.5 0 000-.948z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});