define("ember-svg-jar/inlined/phone-actions-lock-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-lock-1</title><path d=\"M15.5 15.783a3.116 3.116 0 00-4.4 0l-.827.828a4.249 4.249 0 00-1.346 4.5 11.357 11.357 0 01-6.032-6.031 4.249 4.249 0 004.5-1.346l.83-.829a3.116 3.116 0 000-4.4l-3.59-3.582a2.232 2.232 0 00-3.649.739 13.313 13.313 0 0017.357 17.354 2.233 2.233 0 00.737-3.648zM22.5 4.75V3.5a3.5 3.5 0 00-7 0v1.25a1.5 1.5 0 00-1.5 1.5v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5v-5.5a1.5 1.5 0 00-1.5-1.5zM19 10.5a1 1 0 111-1 1 1 0 01-1 1zm1.5-5.75h-3V3.5a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});