define("ember-svg-jar/inlined/ecology-globe-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ecology-globe-message</title><path d=\"M23.861 21.877l-1.936-4.146a11.766 11.766 0 10-4.191 4.191l4.148 1.935a1.49 1.49 0 001.979-1.98zm-3.79-15.26a.25.25 0 01-.212.383h-2.35a.25.25 0 01-.246-.208 21.772 21.772 0 00-.738-3.048.25.25 0 01.369-.29 9.84 9.84 0 013.177 3.163zm1.473 5.153a9.794 9.794 0 01-.334 2.543.249.249 0 01-.241.185h-3.147a.25.25 0 01-.249-.27 30.868 30.868 0 000-4.958.25.25 0 01.246-.27h3.136a.251.251 0 01.241.183 9.744 9.744 0 01.348 2.587zM2 11.77a9.744 9.744 0 01.349-2.588A.251.251 0 012.59 9h3.136a.249.249 0 01.249.27c-.065.814-.1 1.651-.1 2.5s.033 1.658.1 2.458a.252.252 0 01-.249.27H2.578a.249.249 0 01-.241-.185A9.74 9.74 0 012 11.77zm5.876 0c0-.864.038-1.717.111-2.544A.25.25 0 018.236 9h7.073a.249.249 0 01.249.227 29.007 29.007 0 01.004 5.044.251.251 0 01-.249.228h-7.08a.249.249 0 01-.249-.228 28.828 28.828 0 01-.108-2.501zm5.693-9.6a.246.246 0 01.172.123A14.913 14.913 0 0115.212 6.7a.251.251 0 01-.245.3H8.579a.251.251 0 01-.246-.3 14.912 14.912 0 011.472-4.411.246.246 0 01.172-.123 9.8 9.8 0 013.592 0zM6.651 3.452a.251.251 0 01.37.29 21.543 21.543 0 00-.738 3.049.25.25 0 01-.247.209h-2.35a.25.25 0 01-.212-.382 9.853 9.853 0 013.177-3.166zm-3.2 13.427a.249.249 0 01.212-.381h2.366a.249.249 0 01.246.208 21.867 21.867 0 00.746 3.094.25.25 0 01-.369.29 9.854 9.854 0 01-3.206-3.211zm6.531 4.5a.246.246 0 01-.172-.123 14.942 14.942 0 01-1.48-4.458.249.249 0 01.246-.3h6.4a.249.249 0 01.246.3 15 15 0 01-1.479 4.459.248.248 0 01-.172.123 9.85 9.85 0 01-1.795.165 9.747 9.747 0 01-1.799-.17zm8.112-1.494a1 1 0 00-.97.07c-.076.049-.153.1-.229.145a.25.25 0 01-.369-.29 21.828 21.828 0 00.748-3.1.248.248 0 01.246-.208h2.376a.25.25 0 01.214.38c-.05.08-.1.16-.151.238a1 1 0 00-.07.971l1.28 2.741a.252.252 0 01-.05.283.249.249 0 01-.282.049z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});