define("ember-svg-jar/inlined/gesture-tap-all-direction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-tap-all-direction</title><path d=\"M9.5 3.5h5a.75.75 0 00.469-1.335l-2.5-2a.749.749 0 00-.938 0l-2.5 2A.75.75 0 009.5 3.5zM14.5 20.5h-5a.75.75 0 00-.469 1.335l2.5 2a.752.752 0 00.938 0l2.5-2A.75.75 0 0014.5 20.5zM2.164 8.531l-2 2.5a.752.752 0 000 .938l2 2.5A.751.751 0 003.5 14V9a.751.751 0 00-1.336-.469zM23.836 11.031l-2-2.5A.751.751 0 0020.5 9v5a.751.751 0 001.336.469l2-2.5a.752.752 0 000-.938zM12 5.75a7.25 7.25 0 00-6.148 11.092.749.749 0 101.271-.794 5.751 5.751 0 119.745.012.75.75 0 001.27.8A7.251 7.251 0 0012 5.75z\"/><path d=\"M12 9.5A3.5 3.5 0 008.5 13v3.5a.5.5 0 00.5.5h6a.5.5 0 00.5-.5V13A3.5 3.5 0 0012 9.5zm1.5 4.625a.375.375 0 01-.375.375h-2.25a.375.375 0 01-.375-.375V13a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});