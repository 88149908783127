define("ember-svg-jar/inlined/plane-trip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-trip</title><path d=\"M19.5 0h-15A4.505 4.505 0 000 4.5v15A4.505 4.505 0 004.5 24h15a4.505 4.505 0 004.5-4.5v-15A4.505 4.505 0 0019.5 0zM8.721 7.146l.934-.482a1.19 1.19 0 011.179.048l1.423.893a.5.5 0 01-.074.885l-1.654.684a.5.5 0 01-.474-.05l-1.4-.958a.6.6 0 01.063-1.02zm11.35 3.307l-.016.008-3.379 1.668a.5.5 0 00-.207.189l-3.148 5.2a.592.592 0 01-.235.22L12 18.3a.6.6 0 01-.822-.762l1.182-2.765a.5.5 0 00-.643-.661L7.994 15.58a1.189 1.189 0 01-1.082-.107l-4.095-2.644a.595.595 0 01.025-1.016l1.277-.735a.6.6 0 01.54-.027l2.688 1.2a.5.5 0 00.395.006l11.141-4.605h.007a1.522 1.522 0 011.181 2.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});