define("ember-svg-jar/inlined/keyboard-arrow-return-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-arrow-return-1</title><path d=\"M21.545 0h-8.123a1.5 1.5 0 000 3h7.328a.25.25 0 01.25.25v13.22a.25.25 0 01-.25.25H7.027a.25.25 0 01-.25-.25v-3.028a1 1 0 00-1.707-.707L.293 17.513a1 1 0 000 1.414L5.07 23.7a1 1 0 00.707.3.987.987 0 00.383-.076A1 1 0 006.777 23v-3.03a.25.25 0 01.25-.25h14.518A2.458 2.458 0 0024 17.264V2.453A2.459 2.459 0 0021.545 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});