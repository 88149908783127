define("ember-svg-jar/inlined/upload-brackets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>upload-brackets</title><path d=\"M24 2.2A2.207 2.207 0 0021.8 0h-4.411a1.225 1.225 0 100 2.449H21.3a.25.25 0 01.25.25V21.3a.25.25 0 01-.25.25h-3.911a1.225 1.225 0 100 2.449H21.8A2.207 2.207 0 0024 21.8zM0 2.2v19.6A2.207 2.207 0 002.2 24h4.413a1.225 1.225 0 100-2.449H2.7a.25.25 0 01-.25-.25V2.7a.25.25 0 01.25-.25h3.913a1.225 1.225 0 100-2.45H2.2A2.207 2.207 0 000 2.2z\"/><path d=\"M16.9 12.979a.979.979 0 00.739-1.622l-4.9-5.633a1.011 1.011 0 00-1.478 0l-4.9 5.633a.979.979 0 00.739 1.622h3.179a.25.25 0 01.25.25v5.488a1.469 1.469 0 102.938 0v-5.488a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});