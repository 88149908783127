define("ember-svg-jar/inlined/bandage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bandage</title><path d=\"M13.109 5.331a.5.5 0 000-.707l-2.866-2.867a6 6 0 00-8.485 8.487l2.865 2.865a.5.5 0 00.708 0zM7.625 2.626a1 1 0 11-1 1 1 1 0 011-1zm-.211 3.788a1 1 0 11-1 1 1 1 0 011-1zM3.75 2.75a1 1 0 11-1 1 1 1 0 011-1zm-.125 5.876a1 1 0 111-1 1 1 0 01-1 1zM22.243 13.758l-2.866-2.866a.5.5 0 00-.707 0l-7.778 7.779a.5.5 0 000 .707l2.866 2.866a6 6 0 008.485-8.486zm-5.762 7.724a1 1 0 111-1 1 1 0 01-1 1zm.125-3.875a1 1 0 111-1 1 1 0 01-1 1zm3.665 3.664a1 1 0 111-1 1 1 0 01-1 1zm.21-3.789a1 1 0 111-1 1 1 0 01-1 1zM14.877 6.391a.5.5 0 00-.708 0L6.391 14.17a.5.5 0 000 .707l2.734 2.734a.5.5 0 00.707 0l7.778-7.779a.5.5 0 000-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});