define("ember-svg-jar/inlined/finger-tap-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>finger-tap-1</title><path d=\"M11.646 3.853a.5.5 0 00.708 0l3-3A.5.5 0 0015 0H9a.5.5 0 00-.354.853zM22.941 7.788a.5.5 0 00-.541.112l-3 3a.5.5 0 000 .707l3 3a.5.5 0 00.854-.353v-6a.5.5 0 00-.313-.466zM1.6 7.9a.5.5 0 00-.85.35v6a.5.5 0 00.85.35l3-3a.5.5 0 000-.707zM15.506 16.786h-.567a6.25 6.25 0 10-5.516.178v1.629a2.424 2.424 0 00-3.209.126 2.364 2.364 0 000 3.347l1.714 1.714a.75.75 0 101.06-1.06l-1.714-1.715a.867.867 0 010-1.225.887.887 0 011.225 0l1.144 1.143a.75.75 0 001.28-.53V13.25a1.25 1.25 0 012.5 0v4.286a.75.75 0 00.75.75h1.333a3.062 3.062 0 013.059 3.059v1.905a.75.75 0 001.5 0v-1.905a4.564 4.564 0 00-4.559-4.559z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});