define("ember-svg-jar/inlined/database-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-upload</title><path d=\"M8.707 21.392a.249.249 0 00.248-.359 7.959 7.959 0 01-.8-3 .25.25 0 00-.217-.231 12.8 12.8 0 01-6.156-2.212.25.25 0 00-.408.194V17.5c0 1.9 3.132 3.479 7.333 3.892zM1.782 11.09a.25.25 0 00-.408.194V13c0 1.773 2.738 3.273 6.528 3.8a.245.245 0 00.188-.05.251.251 0 00.1-.169 7.936 7.936 0 01.874-2.808.25.25 0 00-.2-.366c-2.829-.256-5.516-1.042-7.082-2.317zM16.126 9.5a7.926 7.926 0 013.162.66.251.251 0 00.272-.048 2.265 2.265 0 00.814-1.612V6.785a.25.25 0 00-.408-.194C18.029 8.168 14.379 9 10.875 9s-7.156-.833-9.093-2.411a.25.25 0 00-.408.194V8.5c0 2.054 3.675 3.746 8.406 3.974a.252.252 0 00.205-.09A7.982 7.982 0 0116.126 9.5z\"/><ellipse cx=\"10.875\" cy=\"4\" rx=\"9.5\" ry=\"4\"/><path d=\"M16.126 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-1 9.5v-2.75a.25.25 0 00-.25-.25h-.75a.5.5 0 01-.391-.812l2-2.5a.519.519 0 01.782 0l2 2.5a.5.5 0 01-.391.812h-.75a.25.25 0 00-.25.25v2.75a1 1 0 11-2 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});