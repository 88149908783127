define("ember-svg-jar/inlined/organic-flask-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>organic-flask-1</title><path d=\"M17.842 12.334a.5.5 0 00-.409-.137 6.514 6.514 0 01-2.748-.321 6.924 6.924 0 00-2.4-.353 2.737 2.737 0 00-2.277 1.212 2.173 2.173 0 00-.241 1.454.5.5 0 01-.241.507 17.3 17.3 0 00-2.773 2.033.5.5 0 00.684.73c1.55-1.454 4.461-3.318 6.731-3.188a.5.5 0 01-.059 1 6.889 6.889 0 00-3.578 1.159.5.5 0 00-.188.667 2.113 2.113 0 00.481.6 2.653 2.653 0 001.755.577 4.4 4.4 0 001.239-.19 7.306 7.306 0 004.177-5.35.5.5 0 00-.153-.4z\"/><path d=\"M15.092 5a.5.5 0 01-.342-.474V3a1 1 0 011-1 1 1 0 000-2h-7.5a1 1 0 000 2 1 1 0 011 1v1.527A.5.5 0 018.908 5a9.75 9.75 0 106.184 0zM12 22a7.751 7.751 0 01-1.551-15.345 1 1 0 00.8-.98V3a2.888 2.888 0 00-.031-.429.5.5 0 01.5-.571h.57a.5.5 0 01.5.571A2.888 2.888 0 0012.75 3v2.675a1 1 0 00.8.98A7.751 7.751 0 0112 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});