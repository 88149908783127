define("ember-svg-jar/inlined/app-window-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-settings</title><circle cx=\"17.748\" cy=\"17.001\" r=\"1.5\"/><path d=\"M20.4 13a.455.455 0 01-.533-.311l-.42-1.389a1.774 1.774 0 00-3.4 0l-.424 1.391a.457.457 0 01-.533.311l-1.41-.332a1.783 1.783 0 00-1.7 2.953l.989 1.065a.462.462 0 010 .625l-.987 1.066a1.783 1.783 0 001.7 2.953l1.411-.327a.456.456 0 01.533.31l.424 1.392a1.774 1.774 0 003.4 0l.424-1.392a.453.453 0 01.533-.31l1.412.327a1.783 1.783 0 001.7-2.953l-.988-1.066a.462.462 0 010-.625l.986-1.065a1.782 1.782 0 00-1.7-2.953zm-2.656 7a3 3 0 113-3 3 3 0 01-2.996 3z\"/><path d=\"M21 .032H3a3 3 0 00-3 3v14.5a3 3 0 003 3h5.5a1 1 0 001-1 1 1 0 00-1-1H3a1 1 0 01-1-1V5.282a.25.25 0 01.25-.25h19.5a.25.25 0 01.25.25v4.25a1 1 0 001 1 1 1 0 001-1v-6.5a3 3 0 00-3-3zm-16.654 3a.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.979.979 0 01.092-.408 1 1 0 01.908-.592.985.985 0 01.846.5.969.969 0 01.154.5.969.969 0 01-.154.5zm3.5 0a.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.969.969 0 01.154-.5.966.966 0 011.692 0 .969.969 0 01.154.5.969.969 0 01-.154.5zm3.5 0a.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.969.969 0 01.154-.5.966.966 0 011.692 0 .969.969 0 01.154.5.969.969 0 01-.154.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});