define("ember-svg-jar/inlined/allowances-no-talking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>allowances-no-talking</title><path d=\"M17.25 12.5a.5.5 0 01-1 0v-.922a.25.25 0 00-.427-.177l-9.05 9.051a.25.25 0 00-.025.325c.059.08.118.16.178.238A5.007 5.007 0 0010.915 23h3.835a5 5 0 005-5V8.078a.25.25 0 00-.427-.177l-2 2a.251.251 0 00-.073.177zM22.28 1.97a.749.749 0 00-1.06 0l-4.543 4.543a.25.25 0 01-.427-.177V4.25a1.25 1.25 0 00-2.5 0v5.086a.251.251 0 01-.073.177l-.5.5a.25.25 0 01-.427-.177V2.25a1.25 1.25 0 00-2.5 0v9.25a.5.5 0 01-1 0v-7a1.25 1.25 0 00-2.5 0v9.24a.25.25 0 01-.44.162l-1.7-1.988a.3.3 0 00-.031-.031 1.2 1.2 0 00-1.612.077 1.632 1.632 0 00-.357 1.765 30.768 30.768 0 002.271 4.314.25.25 0 01-.036.308L1.72 21.47a.75.75 0 001.06 1.06l19.5-19.5a.749.749 0 000-1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});