define("ember-svg-jar/inlined/playlist-star-favorite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>playlist-star-favorite</title><path d=\"M8.989 15a3.151 3.151 0 012.934-2h1.154a.244.244 0 00.224-.151l.8-1.909c.015-.037.031-.072.048-.108a3.233 3.233 0 012.194-1.757.248.248 0 00.191-.245V2.5A2.469 2.469 0 0014.105 0H2.432A2.47 2.47 0 000 2.5v12A2.47 2.47 0 002.432 17h6.126a.24.24 0 00.185-.088.259.259 0 00.055-.2A3.234 3.234 0 018.989 15zm-.234-4a2.47 2.47 0 01-2.432 2.5 2.5 2.5 0 010-5 2.351 2.351 0 01.663.1.24.24 0 00.214-.046.251.251 0 00.1-.2V4a.74.74 0 01.73-.75c1.43 0 4.134.784 4.134 3.75A.73.73 0 1110.7 7a2.075 2.075 0 00-1.634-2.087.244.244 0 00-.311.24zM22.12 15h-2.432a.244.244 0 01-.224-.151l-1.307-3.116-.01-.023v-.01a1.243 1.243 0 00-2.248.025l-.006.015-1.307 3.109a.242.242 0 01-.223.151h-2.438a1.2 1.2 0 00-1.135.76 1.3 1.3 0 00.31 1.39l.01.009 2.129 1.95a.255.255 0 01.061.287l-1.193 2.814a1.277 1.277 0 00.306 1.451 1.224 1.224 0 001.465.166l3.027-1.752a.236.236 0 01.238 0l3.028 1.752a1.216 1.216 0 001.465-.167 1.279 1.279 0 00.307-1.45l-1.191-2.81a.256.256 0 01.061-.287l2.148-1.967.01-.009a1.287 1.287 0 00.286-1.373A1.2 1.2 0 0022.12 15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});