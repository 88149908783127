define("ember-svg-jar/inlined/decoration-cherry-blossom-vase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>decoration-cherry-blossom-vase</title><path d=\"M12.122 14.5H9.878a1.512 1.512 0 00-1.19.587A6.867 6.867 0 007 19.214C7 22.121 8.57 24 11 24s4-1.879 4-4.786a6.867 6.867 0 00-1.688-4.127 1.511 1.511 0 00-1.19-.587z\"/><path d=\"M22 6a2 2 0 00-1.6.8.5.5 0 01-.4.2h-1.028a1.767 1.767 0 01-1.054-.352l-.325-.248a.5.5 0 01-.1-.7l.778-1.01a.5.5 0 01.362-.194A2 2 0 1016.5 2.5a1.965 1.965 0 00.12.68.5.5 0 01-.074.476l-4.9 6.372a.25.25 0 01-.435-.074l-.358-1.076a.5.5 0 01.09-.478c1.222-1.466 1.735-2.078 1.928-2.316a.492.492 0 01.248-.164A2 2 0 1010.553 4a1.979 1.979 0 00.243.953.5.5 0 01-.055.56l-.438.525a.248.248 0 01-.238.086.252.252 0 01-.191-.167L9.1 3.63a.5.5 0 01.07-.453 2 2 0 10-2.3.7.5.5 0 01.3.312l.207.62a.228.228 0 01-.1.264.226.226 0 01-.278-.033A4.832 4.832 0 003.983 4a.469.469 0 01-.377-.19A2 2 0 103.6 6.2.5.5 0 014 6a3.146 3.146 0 011.6.457A3.5 3.5 0 007.941 7.5a.5.5 0 01.451.341l.335 1.006a.5.5 0 01-.474.658h-.281a3.784 3.784 0 00-2.254.751l-1.065.8a.5.5 0 01-.471.07A2 2 0 105.5 13.137a.5.5 0 01.2-.367l1.224-.918a1.767 1.767 0 011.048-.352h1.28a.5.5 0 01.475.342l.324.974a1 1 0 001.741.293l1.708-2.216a.5.5 0 01.674-.111l1.105.736a.5.5 0 01.222.431A2 2 0 1017 10.063a.5.5 0 01-.4-.068l-1.146-.764a.5.5 0 01-.119-.721l.334-.433a.5.5 0 01.7-.095l.355.266A3.779 3.779 0 0018.972 9H20a.5.5 0 01.4.2A2 2 0 1022 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});