define("ember-svg-jar/inlined/building-modern-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>building-modern-1</title><path d=\"M5.973 11.553a1.006 1.006 0 00-1.042.059l-.012.008L.45 14.932l-.009.006a1 1 0 00-.441.83V23a1 1 0 001 1h.25a.25.25 0 00.25-.25V17a.5.5 0 011 0v6.75a.25.25 0 00.25.25h1a.25.25 0 00.25-.25V17a.5.5 0 011 0v6.75a.25.25 0 00.25.25h.25a1 1 0 001-1V12.434a1 1 0 00-.527-.881z\"/><rect x=\"18\" y=\"14.5\" width=\"6\" height=\"3\" rx=\".25\" ry=\".25\"/><rect x=\"18\" y=\"18.5\" width=\"6\" height=\"2.5\" rx=\".25\" ry=\".25\"/><path d=\"M18.409 13.5h5.182a.25.25 0 00.206-.393.836.836 0 00-.09-.107l-2-2a1 1 0 00-1.414 0l-2 2a.836.836 0 00-.09.107.25.25 0 00.206.393zM23.75 22h-5.5a.25.25 0 00-.25.25V23a1 1 0 001 1h4a1 1 0 001-1v-.75a.25.25 0 00-.25-.25zM13.4 3.086a.5.5 0 01-.146-.354V1a1 1 0 00-2 0v1.732a.5.5 0 01-.146.354L8.086 6.1A2 2 0 007.5 7.518V23a1 1 0 001 1H16a1 1 0 001-1V7.518a2 2 0 00-.586-1.418zm-3.9 7.093a.25.25 0 01.381-.213l3.465 2.134a.249.249 0 01.011.418l-3.465 2.4a.25.25 0 01-.392-.208zM14.969 22H9.75a.25.25 0 01-.25-.25v-3a.25.25 0 01.389-.208l5.1 3.4a.029.029 0 01.014.026.031.031 0 01-.034.032zM15 19.081a.25.25 0 01-.389.208l-3.526-2.351a.249.249 0 010-.413l3.526-2.441a.25.25 0 01.389.206zm0-8.76a.249.249 0 01-.128.218.252.252 0 01-.253 0L11.66 8.713a.25.25 0 01.131-.463h2.959a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});