define("ember-svg-jar/inlined/cat-bell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cat-bell</title><path d=\"M3.83 16.53a.25.25 0 00-.248.281 8.6 8.6 0 007.389 7.157.251.251 0 00.279-.249v-1.828l-1.3-1.561a.5.5 0 01-.116-.321c0-.871.848-1.479 2.063-1.479s2.062.608 2.062 1.479a.5.5 0 01-.1.295l-1.109 1.525v1.891a.251.251 0 00.279.248 8.554 8.554 0 007.387-7.156.25.25 0 00-.248-.282zM21.444 12.53h-1.489a8.519 8.519 0 00-6.948-5.441V5.811a8.315 8.315 0 006.373-4.278 1 1 0 00-1.76-.952 6.346 6.346 0 01-5.613 3.289A6.339 6.339 0 016.382.558a1 1 0 00-1.764.942 8.3 8.3 0 006.389 4.31v1.278a8.517 8.517 0 00-6.962 5.442H2.556c-.306 0-.556.337-.556.75v1c0 .412.25.75.556.75h18.888c.306 0 .556-.338.556-.75v-1c0-.413-.25-.75-.556-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});