define("ember-svg-jar/inlined/soft-drinks-bottle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>soft-drinks-bottle-1</title><path d=\"M16.78 11.664C15.865 10.656 15 9.706 15 9V5.5a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5V9c0 .706-.865 1.656-1.78 2.664C5.945 13.065 4.5 14.655 4.5 16.5a28.831 28.831 0 00.772 6.151A1.849 1.849 0 007 24h10a1.847 1.847 0 001.727-1.349A28.727 28.727 0 0019.5 16.5c0-1.845-1.445-3.435-2.72-4.836zM9.5 3.5h5A.5.5 0 0015 3V1a1 1 0 00-1-1h-4a1 1 0 00-1 1v2a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});