define("ember-svg-jar/inlined/vectors-anchor-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vectors-anchor-circle</title><circle cx=\"12\" cy=\"3\" r=\"3\"/><circle cx=\"12\" cy=\"21\" r=\"3\"/><circle cx=\"21\" cy=\"12\" r=\"3\"/><circle cx=\"3\" cy=\"12\" r=\"3\"/><path d=\"M16.207 18.806a.251.251 0 00-.085.338l.752 1.3a.251.251 0 00.155.117.248.248 0 00.192-.029 10.045 10.045 0 003.309-3.309.248.248 0 00.029-.192.251.251 0 00-.117-.155l-1.3-.752a.251.251 0 00-.338.085 8.044 8.044 0 01-2.597 2.597zM18.805 7.793a.251.251 0 00.338.085l1.3-.752a.251.251 0 00.117-.155.248.248 0 00-.029-.192 10.045 10.045 0 00-3.31-3.309.248.248 0 00-.192-.029.251.251 0 00-.155.117l-.752 1.3a.251.251 0 00.085.338 8.044 8.044 0 012.598 2.597zM3.47 6.779a.248.248 0 00-.029.192.251.251 0 00.117.155l1.3.752a.251.251 0 00.342-.085 8.044 8.044 0 012.6-2.6.251.251 0 00.085-.338l-.752-1.3a.251.251 0 00-.155-.117.248.248 0 00-.192.029A10.045 10.045 0 003.47 6.779zM5.2 16.207a.251.251 0 00-.338-.085l-1.3.752a.251.251 0 00-.117.155.248.248 0 00.029.192 10.045 10.045 0 003.305 3.309.248.248 0 00.192.029.251.251 0 00.155-.117l.752-1.3a.251.251 0 00-.085-.338A8.044 8.044 0 015.2 16.207z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});