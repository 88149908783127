define("ember-svg-jar/inlined/style-one-pin-plane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-plane</title><path d=\"M12 0a9.5 9.5 0 00-4 18.119 2.5 2.5 0 011.218 1.222l1.873 4.076a1 1 0 001.817 0l1.876-4.08A2.5 2.5 0 0116 18.118 9.5 9.5 0 0012 0zm6.757 8.556L9.6 14.244a.561.561 0 01-.621-.018l-3.363-2.357a.31.31 0 01-.047-.437.327.327 0 01.053-.052l1.031-.864a.31.31 0 01.3-.052l1.479.882a.5.5 0 00.554-.028l1.759-1.3a.5.5 0 00-.035-.827L7.092 6.947a.385.385 0 01-.044-.547.378.378 0 01.062-.058l1.038-.769a.386.386 0 01.364-.052l5.607 2.088a.5.5 0 00.456-.056l2.493-1.7a1.508 1.508 0 011.187-.228 1.611 1.611 0 011.155 1.04 1.512 1.512 0 01.09.512 1.594 1.594 0 01-.743 1.379z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});