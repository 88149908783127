define("ember-svg-jar/inlined/escalator-people", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>escalator-people</title><path d=\"M13.5 8a1.5 1.5 0 00-3 0v2.792a.25.25 0 00.425.178l2.5-2.447a.251.251 0 00.075-.179z\"/><circle cx=\"12\" cy=\"2.5\" r=\"2.5\"/><path d=\"M7.425 14.4a.251.251 0 00.075-.179V13a1.5 1.5 0 00-3 0v3.25a.25.25 0 00.25.25h.424a.251.251 0 00.175-.071z\"/><circle cx=\"6\" cy=\"7.5\" r=\"2.5\"/><path d=\"M24 11a3 3 0 00-3-3h-3.676a3 3 0 00-2.174.933l-8.824 8.638a1.49 1.49 0 01-1.05.429H3a3 3 0 000 6h4.176a3.017 3.017 0 002.181-.939l8.817-8.632a1.49 1.49 0 011.05-.429H21a3 3 0 003-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});