define("ember-svg-jar/inlined/landmark-merlion-statue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-merlion-statue</title><path d=\"M22.582 16.5l-5.772 1.02a2.3 2.3 0 01-.755.013.249.249 0 00-.272.166 2.706 2.706 0 01-2.408 1.551 2.622 2.622 0 01-2.475-1.765.25.25 0 01.186-.287l1.308-.327a.64.64 0 00.485-.621c0-1.657-1.343-2.5-3-2.5s-3 .843-3 2.5a.64.64 0 00.485.621l1.329.332a.252.252 0 01.189.235c.116 3.5 3.47 6.312 7 6.312 3.59 0 7-2.91 7-6.5v-.5a.25.25 0 00-.3-.25z\"/><path d=\"M22.345 2.119a.249.249 0 01.007-.346L23 1.126a.513.513 0 00-.364-.876h-4.4c-2.982.03-4.285 1.471-5.792 3.883a.248.248 0 01-.212.117h-1.857a1.5 1.5 0 000 3h2a1 1 0 010 2h-.5a1 1 0 000 2h4.25a2.25 2.25 0 001.293-4.091.5.5 0 11.576-.818 3.25 3.25 0 01-1.869 5.909h-.441a.25.25 0 00-.247.212 13.324 13.324 0 00-.062 1.788v.808a1 1 0 001.174.985l.619-.11a.249.249 0 00.207-.246V13.75a.5.5 0 011 0v1.673a.25.25 0 00.293.246l2-.353a.25.25 0 00.207-.246V5.25a.5.5 0 011 0v9.555a.25.25 0 00.293.246l.881-.155a1 1 0 00.826-.985v-7.87a5.771 5.771 0 00-1.53-3.922zm-7.22 2.631a.75.75 0 11.75-.75.75.75 0 01-.75.75zM9.625 9a1 1 0 00-1-1h-3.5a5.006 5.006 0 00-5 5v4.25a1 1 0 002 0V13a2.936 2.936 0 01.547-1.707.25.25 0 01.454.149v3.808a1 1 0 002 0V11.5a1.5 1.5 0 011.5-1.5h2a1 1 0 00.999-1zM1.125 19.5a1 1 0 00-1 1v1a1 1 0 002 0v-1a1 1 0 00-1-1zM4.125 17.5a1 1 0 00-1 1v1a1 1 0 002 0v-1a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});