define("ember-svg-jar/inlined/smart-watch-circle-location-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-location-alternate</title><path d=\"M21.5 12A9.5 9.5 0 0017 3.928a.245.245 0 01-.109-.148L15.983.374A.5.5 0 0015.5 0h-7a.5.5 0 00-.484.371L7.108 3.78A.245.245 0 017 3.928a9.5 9.5 0 000 16.149.25.25 0 01.109.148l.908 3.407A.5.5 0 008.5 24h7a.5.5 0 00.482-.368l.935-3.428a.247.247 0 01.109-.146A9.5 9.5 0 0021.5 12zM12 19.5a7.5 7.5 0 117.5-7.5 7.509 7.509 0 01-7.5 7.5z\"/><path d=\"M12 7.552a3.5 3.5 0 00-3.5 3.5c0 1.319 1.474 3.483 2.711 5.065A.989.989 0 0012 16.5a1.009 1.009 0 00.787-.384c1.237-1.582 2.713-3.745 2.713-5.067A3.5 3.5 0 0012 7.552zm0 2.5a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});