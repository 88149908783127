define("ember-svg-jar/inlined/app-window-lock-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-lock-alternate</title><path d=\"M21 0H3a3 3 0 00-3 3v15a3 3 0 003 3h2a1 1 0 000-2H3a1 1 0 01-1-1V7.5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5V18a1 1 0 01-1 1h-2a1 1 0 000 2h2a3 3 0 003-3V3a3 3 0 00-3-3zM11 3.5a1 1 0 11-1-1 1 1 0 011 1zm-3 0a1 1 0 11-1-1 1 1 0 011 1zm-3 0a1 1 0 11-1-1 1 1 0 011 1z\"/><path d=\"M15.714 15.515a.249.249 0 01-.214-.247V14.25a3.5 3.5 0 00-7 0v1.018a.249.249 0 01-.214.247A1.5 1.5 0 007 17v5.5A1.5 1.5 0 008.5 24h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.286-1.485zM13 20.25a1 1 0 11-1-1 1 1 0 011 1zm-2.5-6a1.5 1.5 0 013 0v1a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});