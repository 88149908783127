define("ember-svg-jar/inlined/award-badge-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>award-badge-star</title><path d=\"M3 20.5h18a1.5 1.5 0 001.5-1.5V6.473A1.994 1.994 0 0021.431 4.7L12.93.229a2.006 2.006 0 00-1.862 0L2.568 4.7A1.993 1.993 0 001.5 6.473V19A1.5 1.5 0 003 20.5zm13.162-4.768a.421.421 0 01-.594.535L12.245 14.4a.5.5 0 00-.491 0l-3.321 1.867a.422.422 0 01-.595-.536l1.353-3.111a.5.5 0 00-.126-.573l-2.312-2.06a.422.422 0 01.281-.737h2.743a.5.5 0 00.459-.3l1.376-3.193a.422.422 0 01.775 0l1.377 3.193a.5.5 0 00.459.3h2.743a.422.422 0 01.281.737l-2.312 2.06a.5.5 0 00-.126.573zM21.5 22h-19a1 1 0 000 2h19a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});