define("ember-svg-jar/inlined/animal-print-two-fingers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>animal-print-two-fingers</title><path d=\"M9.753 1.613A1 1 0 008.175.631C4.935 3.02 3 7.792 3 13.4c0 4.652 1.108 8.119 3.042 9.513a3.339 3.339 0 003.116.461c3-1 2.54-3 1.438-7.852a32.553 32.553 0 01-.843-13.909zM15.825.631a1 1 0 00-1.578.982 32.56 32.56 0 01-.847 13.908c-1.1 4.848-1.558 6.853 1.438 7.852a3.342 3.342 0 003.116-.461C19.892 21.518 21 18.051 21 13.4 21 7.792 19.065 3.02 15.825.631z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});