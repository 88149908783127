define("ember-svg-jar/inlined/business-human-plant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-human-plant</title><path d=\"M.888 9.364a10.212 10.212 0 003.68 8.845.238.238 0 01.089.186v4.559a1 1 0 002 0V18.28a1.956 1.956 0 00-.736-1.52 8.3 8.3 0 01-3.04-7.216c.01-.126.025-.25.043-.373a1 1 0 10-1.981-.277c-.021.156-.043.313-.055.47zM22.874 14.77a1.48 1.48 0 00.209-1.322c-.163-.506-.322-1.015-.482-1.524-1.63-5.218-3.317-10.613-9.267-11.7A10.76 10.76 0 004.682 2.4a1 1 0 001.166 1.623 10.121 10.121 0 015.375-1.981c6.188-.02 7.776 5.063 9.469 10.479.09.285.282.855-.27.855h-1.315a1 1 0 00-1 1v2.859a1.859 1.859 0 01-1.859 1.86h-.584a1.476 1.476 0 00-1.477 1.476v2.383a1 1 0 002 0v-1.77a.083.083 0 01.061-.089 3.86 3.86 0 003.859-3.86v-1.609a.25.25 0 01.25-.25h1.324a1.482 1.482 0 001.193-.606z\"/><circle cx=\"16.117\" cy=\"10.564\" r=\"1.25\"/><path d=\"M9.863 11.578a1 1 0 10.793-1.836 3.507 3.507 0 00-4.6 1.822 3.5 3.5 0 001.822 4.6 1.063 1.063 0 001.315-.521 1 1 0 00-.52-1.315 1.5 1.5 0 111.192-2.753zM1.321 5.993a1 1 0 001 1h3.871a.252.252 0 01.181.077l1.059 1.113a1.248 1.248 0 001.414.034.977.977 0 00.215-1.1.09.09 0 01.09-.122h1.229a.25.25 0 01.181.077l1.06 1.113a1.26 1.26 0 001.413.034.977.977 0 00.22-1.092.133.133 0 01.131-.171h1.46a1 1 0 000-2h-1.383a.157.157 0 01-.142-.184 1 1 0 00-1.682-.914L10.574 4.92a.249.249 0 01-.177.073H9.239c-.136 0-.136-.134-.119-.2a.981.981 0 00-.257-.935 1 1 0 00-1.414 0L6.386 4.92a.251.251 0 01-.177.073H2.321a1 1 0 00-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});