define("ember-svg-jar/inlined/playlist-album", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>playlist-album</title><path d=\"M20 7a3 3 0 00-3-3H3a3 3 0 00-3 3v14a3 3 0 003 3h14a3 3 0 003-3zM8.73 11.449a.251.251 0 00-.2.243L8.5 18v.052A2.5 2.5 0 116 15.5c.077 0 .152 0 .227.01a.249.249 0 00.273-.249V11.5a2 2 0 011.632-1.966L13.1 8.417a2 2 0 012.4 1.958v5.677A2.5 2.5 0 1113 13.5c.077 0 .152 0 .227.01a.249.249 0 00.273-.249v-2.573a.25.25 0 00-.3-.244z\"/><path d=\"M24 3a3 3 0 00-3-3H4.5a1 1 0 000 2H21a1 1 0 011 1v15a1 1 0 002 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});