define("ember-svg-jar/inlined/zip-file-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>zip-file-clock</title><path d=\"M17.25 10.5A6.75 6.75 0 1024 17.25a6.758 6.758 0 00-6.75-6.75zm0 11.5A4.75 4.75 0 1122 17.25 4.756 4.756 0 0117.25 22z\"/><path d=\"M20.25 17.25a1 1 0 00-1-1h-.75a.25.25 0 01-.25-.25v-1.25a1 1 0 00-2 0v2.5a1 1 0 001 1h2a1 1 0 001-1zM9 17.25A8.259 8.259 0 0117.25 9c.163 0 .325.005.485.015a.254.254 0 00.187-.068.25.25 0 00.078-.182V5.207a1.5 1.5 0 00-.439-1.06L13.853.439A1.5 1.5 0 0012.793 0H1.5A1.5 1.5 0 000 1.5v19A1.5 1.5 0 001.5 22h8.542a.251.251 0 00.212-.383A8.2 8.2 0 019 17.25zM6 18.5a1 1 0 111-1 1 1 0 01-1 1zM6 15a1 1 0 111-1 1 1 0 01-.995 1zm0-3.5a1 1 0 111-1 1 1 0 01-.995 1zm1.5-4.9a1.968 1.968 0 01-1.479.67 1.975 1.975 0 01-2.005-2.221l.49-3.127A.5.5 0 015 1.5h2a.5.5 0 01.494.423l.485 3.111v.015A1.985 1.985 0 017.508 6.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});