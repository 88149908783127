define("ember-svg-jar/inlined/crime-man-inmate.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-man-inmate</title><path d=\"M10.5 9.133h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM15.25 9.883a.75.75 0 00-.75-.75h-1a.75.75 0 000 1.5h1a.75.75 0 00.75-.75zM23.523 20.383a4.581 4.581 0 00-2.5-2.23l-5-1.262a.25.25 0 00-.207.039 7.13 7.13 0 01-3.842 1.453 7.123 7.123 0 01-3.835-1.449.25.25 0 00-.207-.039L2.92 18.17a4.508 4.508 0 00-2.445 2.213zM.039 21.883a4.394 4.394 0 00-.039.5v1a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-1a4.394 4.394 0 00-.039-.5z\"/><path d=\"M5.25 7.9a.249.249 0 01-.063.166 2.1 2.1 0 00-.477 1.457 2.728 2.728 0 001.439 2.432.251.251 0 01.144.188c.511 3.047 4.021 4.99 5.686 4.99a5.718 5.718 0 002.773-1l.038-.02a.736.736 0 00.242-.166.324.324 0 01.034-.029 6.023 6.023 0 002.6-3.77.254.254 0 01.145-.189 2.731 2.731 0 001.439-2.437 2.114 2.114 0 00-.443-1.4.25.25 0 01-.057-.159V3.482A2.747 2.747 0 0016.809.844 16.229 16.229 0 007.2.846a2.746 2.746 0 00-1.95 2.638V7.9zm11.684-3.631a.251.251 0 01.316.241v1.033a.25.25 0 01-.187.242A20.084 20.084 0 0112 6.444a20.084 20.084 0 01-5.063-.659.25.25 0 01-.187-.242V4.51a.246.246 0 01.1-.2.249.249 0 01.217-.043 18.569 18.569 0 009.867.002zM10.25 14.383a1.212 1.212 0 01.24-.695 1.226 1.226 0 011.01-.555h1a1.315 1.315 0 011.25 1.25v.46a.25.25 0 01-.127.218 5.542 5.542 0 01-.785.372.25.25 0 01-.338-.233v-.317a.5.5 0 00-1 0v.334a.25.25 0 01-.336.235 5.61 5.61 0 01-.785-.364.249.249 0 01-.129-.219zm6.941-3.794a1.75 1.75 0 00-1 1.306 3.576 3.576 0 01-.7 1.544.25.25 0 01-.432-.056 2.833 2.833 0 00-.49-.794 2.732 2.732 0 00-2.067-.956h-1a2.734 2.734 0 00-2.091.985 2.834 2.834 0 00-.48.8.249.249 0 01-.431.061 3.613 3.613 0 01-.728-1.579 1.749 1.749 0 00-1-1.307 1.275 1.275 0 01-.559-1.1.954.954 0 01.041-.322.255.255 0 01.115-.139.744.744 0 00.384-.65v-.293a.249.249 0 01.325-.238q.436.138.877.25a16.17 16.17 0 004.04.534 16.211 16.211 0 004.015-.527c.308-.078.613-.165.917-.261a.253.253 0 01.224.037.25.25 0 01.1.2v.18a.739.739 0 00.395.789.616.616 0 01.105.439 1.278 1.278 0 01-.56 1.097z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});