define("ember-svg-jar/inlined/settings-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>settings-slider</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM9 9.5a3 3 0 01-2.828-2H4.5a1 1 0 010-2h1.672A3 3 0 119 9.5zm1 8a1 1 0 01-1 1H4.5a1 1 0 010-2H9a1 1 0 011 1zm4.5-3a3 3 0 012.828 2H19.5a1 1 0 010 2h-2.172a3 3 0 11-2.828-4zm5-7h-5a1 1 0 010-2h5a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});