define("ember-svg-jar/inlined/single-woman-actions-edit-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-edit-2</title><path d=\"M11.57 17.5a.248.248 0 00.176-.073l3.961-3.96a.25.25 0 00-.065-.4c-1.054-.529-2.514-1.037-4.168-1.644a.778.778 0 01-.513-.729v-.468a.251.251 0 01.25-.25A6.37 6.37 0 0015 9.081a.387.387 0 00.084-.592c-.494-.538-.835-1.186-.981-3.318C13.838 1.346 11.236 0 9 0S4.161 1.346 3.9 5.171c-.146 2.132-.487 2.78-.981 3.318A.387.387 0 003 9.081a6.355 6.355 0 003.782.9.249.249 0 01.254.25v.462a.777.777 0 01-.513.729c-2.9 1.065-5.212 1.826-5.764 2.922A7.828 7.828 0 000 17.076a.386.386 0 00.1.3.391.391 0 00.29.126zM12.062 20.131a.251.251 0 00-.422.128l-.628 3.141a.5.5 0 00.49.6.473.473 0 00.1-.01l3.143-.629a.251.251 0 00.128-.422zM23.228 11.765a2.62 2.62 0 00-3.7 0l-6.678 6.679a.251.251 0 000 .354l3.35 3.353a.249.249 0 00.353 0l6.679-6.679a2.621 2.621 0 00-.004-3.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});