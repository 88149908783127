define("ember-svg-jar/inlined/shop-dislike", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shop-dislike</title><path d=\"M11.921 17.8l-.946-3.158c-.538-1.735-1.568-2.614-3.062-2.614-.488 0-.894-.009-1.246-.016-2.051-.046-2.538 0-5.665 1.576l-.594.3a.747.747 0 00-.408.67v4.5a.75.75 0 00.75.75h1.811a.242.242 0 01.2.1l2.422 3.414A1.6 1.6 0 008.032 22L7.6 20.349a.228.228 0 01.041-.2.246.246 0 01.192-.093h2.43a1.739 1.739 0 001.666-2.243z\"/><path d=\"M23.836 5.114L22.257.966A1.511 1.511 0 0020.854 0H4.233a1.508 1.508 0 00-1.4.966L1.252 5.113a2.5 2.5 0 00-.019 1.733 3.254 3.254 0 001.2 1.569.25.25 0 01.108.205V10a1 1 0 002 0v-.786a.249.249 0 01.214-.247 3.253 3.253 0 002.092-1.209.251.251 0 01.393 0 3.246 3.246 0 005.107 0 .251.251 0 01.393 0 3.246 3.246 0 005.107 0 .251.251 0 01.393 0A3.246 3.246 0 0020.794 9a.233.233 0 01.165.069.229.229 0 01.068.166L21 19.251a.251.251 0 01-.25.249h-1.259a.25.25 0 01-.25-.25V13.5a1 1 0 00-1-1h-3a1 1 0 00-1 1v5.75a.25.25 0 01-.25.25 1 1 0 000 2h8a1 1 0 001-1l.04-12.293a.251.251 0 01.072-.175 3.226 3.226 0 00.751-1.189 2.5 2.5 0 00-.018-1.729z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});