define("ember-svg-jar/inlined/knife-board", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>knife-board</title><path d=\"M19.9 14.679L9.058 18.426a.5.5 0 00-.31.636l.99 2.866a.59.59 0 00.749.364l3.133-1.082a13.086 13.086 0 006.993-5.694.589.589 0 00-.71-.837zM7.494 20.024a.5.5 0 00-.636-.309L2.2 21.325a1.07 1.07 0 10.7 2.022l4.66-1.611a.5.5 0 00.31-.635zM22.5.6H8a1.5 1.5 0 00-1.5 1.5v2.6a.5.5 0 01-.613.487A21.454 21.454 0 002.5 4.6a2.5 2.5 0 000 5 28.093 28.093 0 003.391-.62.5.5 0 01.609.488V12.1A1.5 1.5 0 008 13.6h14.5a1.5 1.5 0 001.5-1.5v-10A1.5 1.5 0 0022.5.6zm-20 7.5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});