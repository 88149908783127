define("ember-svg-jar/inlined/non-gmo-fertilizer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>non-gmo-fertilizer</title><path d=\"M8.2 6.805l3.343.418a.25.25 0 01.219.248v.735a.25.25 0 00.427.177l3.206-3.206a.25.25 0 00-.177-.427H15a.5.5 0 01-.5-.5V2.5A.5.5 0 0115 2h.5a1 1 0 000-2H9a1 1 0 000 2h3a.5.5 0 01.5.5v1.75a.5.5 0 01-.5.5 3.882 3.882 0 00-.481.03L8.2 5.2a.5.5 0 00-.438.5v.618a.5.5 0 00.438.487z\"/><circle cx=\"4\" cy=\"4.5\" r=\"1\"/><circle cx=\"4\" cy=\"7.5\" r=\"1\"/><circle cx=\"1\" cy=\"3\" r=\"1\"/><circle cx=\"1\" cy=\"6\" r=\"1\"/><circle cx=\"1\" cy=\"9\" r=\"1\"/><path d=\"M23.707 1.707A1 1 0 0022.293.293l-22 22a1 1 0 101.414 1.414l5.875-5.875a.25.25 0 01.427.177V22a2 2 0 002 2h7.5a2 2 0 002-2v-9.5a4.005 4.005 0 00-2.431-3.679.25.25 0 01-.147-.182.247.247 0 01.069-.224l1.5-1.5a.5.5 0 01.437-.14 2 2 0 011.619 1.538l1.037 4.669a.976.976 0 101.906-.423l-1.04-4.68a4 4 0 00-1.892-2.588.249.249 0 01-.05-.393zM17.509 12.5v1.75a.5.5 0 01-.5.5h-5.741a.25.25 0 01-.177-.427l3.53-3.53a1 1 0 01.707-.293h.181a2 2 0 012 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});