define("ember-svg-jar/inlined/arrow-button-left-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-left-2-alternate</title><path d=\"M19.578 24a2.827 2.827 0 01-1.468-.41L2.959 14.42a2.829 2.829 0 010-4.84L18.113.408a2.829 2.829 0 014.293 2.42v18.343A2.828 2.828 0 0119.578 24zm.485-3.637zM6.6 11.786a.251.251 0 000 .428l11.656 7.054a.25.25 0 00.38-.214V4.945a.25.25 0 00-.38-.214z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});