define("ember-svg-jar/inlined/add-square-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>add-square-alternate</title><path d=\"M21.545.25H2.455a2.206 2.206 0 00-2.2 2.2v19.096a2.207 2.207 0 002.205 2.2h19.09a2.207 2.207 0 002.2-2.2V2.454A2.206 2.206 0 0021.545.25zm-.3 21H2.75V2.75h18.5z\"/><path d=\"M6.273 13.25H10.5a.25.25 0 01.25.25v4.227a1.25 1.25 0 002.5 0V13.5a.25.25 0 01.25-.25h4.227a1.25 1.25 0 000-2.5H13.5a.25.25 0 01-.25-.25V6.272a1.25 1.25 0 10-2.5 0V10.5a.25.25 0 01-.25.25H6.273a1.25 1.25 0 000 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});