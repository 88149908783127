define("ember-svg-jar/inlined/kitchen-counter-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchen-counter-1</title><path d=\"M23.876 11.629a1.486 1.486 0 00-1.484-1.485H2.6a.5.5 0 01-.495-.5V.99a.99.99 0 10-1.981 0v21.773a.99.99 0 101.979 0v-9.155a.5.5 0 01.495-.5h19.794a1.486 1.486 0 001.484-1.479z\"/><path d=\"M16.279 21.786a1.686 1.686 0 00-.711-.424.494.494 0 01-.352-.474v-3.816a.5.5 0 01.5-.495h.248a.99.99 0 000-1.979H12.99a.99.99 0 100 1.979h.247a.5.5 0 01.495.495v3.816a.494.494 0 01-.352.474 1.683 1.683 0 00-.71.424l-.7.7a.742.742 0 001.05 1.049l.7-.7a.249.249 0 01.175-.072h1.16a.249.249 0 01.175.072l.7.7a.742.742 0 101.049-1.049zM8.361 21.786a1.683 1.683 0 00-.71-.424.494.494 0 01-.352-.473v-3.817a.5.5 0 01.495-.495h.247a.99.99 0 100-1.979H5.072a.99.99 0 100 1.979h.248a.5.5 0 01.494.495v3.816a.5.5 0 01-.351.474 1.686 1.686 0 00-.711.424l-.7.7A.742.742 0 005.1 23.535l.7-.7a.249.249 0 01.175-.072h1.16a.249.249 0 01.175.072l.7.7a.742.742 0 001.05-1.049zM20.907 14.6h-.989a.494.494 0 00-.5.495v7.915a.99.99 0 101.979 0v-7.917a.5.5 0 00-.49-.493zM4.577 6.631h3.959a.743.743 0 00.63-1.131L7.374 2.628a.493.493 0 01-.074-.262V.742a.743.743 0 00-1.485 0v1.624a.493.493 0 01-.075.262L3.948 5.5a.743.743 0 00.629 1.136zM12.5 6.631h3.959a.743.743 0 00.624-1.131l-1.791-2.872a.494.494 0 01-.076-.262V.742a.742.742 0 00-1.484 0v1.624a.493.493 0 01-.075.262L11.865 5.5a.743.743 0 00.63 1.136z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});