define("ember-svg-jar/inlined/road-sign-no-pedestrian-crossing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-no-pedestrian-crossing</title><path d=\"M15.269 16.25h-.001.001zM22.041 5.446a.25.25 0 00-.386-.04l-4.947 4.947a.086.086 0 00.061.147 1.5 1.5 0 11-1.5 1.5.086.086 0 00-.053-.079.088.088 0 00-.095.018l-3.349 3.349a.252.252 0 00-.072.2l.041.47a.5.5 0 01-.5.543.224.224 0 00-.224.225v2.524a.75.75 0 01-1.5 0v-1.1a.25.25 0 00-.427-.177l-1 1a.249.249 0 00-.073.177v.1a.75.75 0 01-.75.75.319.319 0 01-.054 0 .247.247 0 00-.219.07l-1.59 1.59a.248.248 0 00.04.386 11.987 11.987 0 0016.6-16.595zm-3.106 14.178a.75.75 0 01-1.04-.208l-.668-1a.25.25 0 00-.458.139v.7a.75.75 0 01-1.5 0v-2.762a.251.251 0 00-.138-.223l-1.2-.6a.75.75 0 01.67-1.342l.669.335a.25.25 0 00.31-.073 1.5 1.5 0 012.686.909V17a.479.479 0 01-.013.109.248.248 0 00.035.2l.852 1.278a.75.75 0 01-.205 1.037zM20.766 3.833A11.883 11.883 0 0012 0a11.982 11.982 0 00-8.167 20.766.249.249 0 00.346-.006L20.76 4.18a.25.25 0 00.006-.347zM8.769 3a2.5 2.5 0 11-2.5 2.5 2.5 2.5 0 012.5-2.5zm3.031 8.145l-5.288 5.282a.247.247 0 01-.176.073h-.02a.5.5 0 01-.5-.545l.45-4.943v-.022a2.5 2.5 0 012.5-2.49 2.637 2.637 0 012.422 1.588.247.247 0 00.05.084l.564.629a.251.251 0 01-.002.344z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});