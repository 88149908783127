define("ember-svg-jar/inlined/style-one-pin-wifi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-wifi</title><path d=\"M12 0a9.5 9.5 0 00-4 18.119 2.5 2.5 0 011.218 1.222l1.873 4.076a1 1 0 001.817 0l1.876-4.08A2.5 2.5 0 0116 18.118 9.5 9.5 0 0012 0zm0 15.5a1.75 1.75 0 111.75-1.75A1.752 1.752 0 0112 15.5zm4.278-4.634a1 1 0 01-1.406.151 4.538 4.538 0 00-5.744 0A1 1 0 017.872 9.46a6.526 6.526 0 018.256 0 1 1 0 01.15 1.406zM18 8.123a1 1 0 01-.628-.222 8.493 8.493 0 00-10.744 0 1 1 0 01-1.256-1.557 10.481 10.481 0 0113.256 0A1 1 0 0118 8.123z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});