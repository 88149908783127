define("ember-svg-jar/inlined/police-woman-5.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-woman-5</title><path d=\"M21.026 18.27l-4.886-1.235a.5.5 0 00-.409.075 8.168 8.168 0 01-2.6 1.228.5.5 0 00-.377.485V23.5a.5.5 0 00.5.5H23.5a.5.5 0 00.5-.5v-1a4.531 4.531 0 00-2.974-4.23zM19 20v.43a2 2 0 01-.891 1.664l-.609.406-.609-.406A2 2 0 0116 20.43V20a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zM11.25 18.832a.5.5 0 00-.38-.485 8.115 8.115 0 01-2.636-1.233.5.5 0 00-.409-.074l-4.9 1.247A4.519 4.519 0 000 22.5v1a.5.5 0 00.5.5h10.25a.5.5 0 00.5-.5zM12.741 12.328a1.7 1.7 0 01-.811.178 1.9 1.9 0 01-.823-.18.75.75 0 00-.711 1.321 3.435 3.435 0 003.06 0 .75.75 0 10-.715-1.318zM10.992 10.026a.751.751 0 00-.055-1.059 2.1 2.1 0 00-2.636 0 .75.75 0 101.006 1.113.63.63 0 01.626 0 .749.749 0 001.059-.054zM12.866 8.968a.75.75 0 101.006 1.113.63.63 0 01.626 0 .75.75 0 101-1.115 2.1 2.1 0 00-2.632.002zM12 7.464a42.266 42.266 0 0010.163-1.232.75.75 0 00-.326-1.464c-1.2.267-2.419.467-3.641.634l-.337-2.39A3.516 3.516 0 0014.394 0H9.606a3.516 3.516 0 00-3.465 3.012L5.8 5.4a44.678 44.678 0 01-3.641-.634.75.75 0 00-.326 1.464A42.284 42.284 0 0012 7.464zM10 2.93V2.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v.43a2.5 2.5 0 01-1.113 2.08l-.61.406a.5.5 0 01-.554 0l-.61-.406A2.5 2.5 0 0110 2.93z\"/><path d=\"M8.132 15.387A6.6 6.6 0 0012 17.25a6.6 6.6 0 003.868-1.863 2.076 2.076 0 00.909.864c1.832.886 4.069-.784 4.612-1.458a.5.5 0 00-.231-.788 3.057 3.057 0 01-1.674-1.671 7.678 7.678 0 01-.531-1.452 3.121 3.121 0 00.3-1.243 1.964 1.964 0 00-.692-1.634c-.416.058-.831.123-1.249.17a.747.747 0 00.338.995.634.634 0 01.1.44 1.281 1.281 0 01-.557 1.1 1.75 1.75 0 00-1 1.306C15.816 14.26 13.029 15.75 12 15.75s-3.816-1.49-4.191-3.738a1.75 1.75 0 00-1-1.306 1.281 1.281 0 01-.557-1.1.634.634 0 01.1-.44.747.747 0 00.338-.995c-.418-.047-.833-.112-1.249-.17a1.964 1.964 0 00-.692 1.634 3.121 3.121 0 00.3 1.243 7.535 7.535 0 01-.545 1.48 3.041 3.041 0 01-1.66 1.643.5.5 0 00-.231.788c.537.666 2.757 2.324 4.584 1.469a2.074 2.074 0 00.935-.871z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});