define("ember-svg-jar/inlined/soft-drinks-milk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>soft-drinks-milk</title><rect x=\"8\" width=\"8\" height=\"4\" rx=\"1\" ry=\"1\"/><path d=\"M15.113 8.737a.989.989 0 01-.613-.894V6a.5.5 0 00-.5-.5h-4a.5.5 0 00-.5.5v1.843a.989.989 0 01-.613.894C6.362 9.86 5.5 12.214 5.5 15v7a2 2 0 002 2h9a2 2 0 002-2v-7c0-2.786-.862-5.14-3.387-6.263zM15.5 15.5a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5v-2A.5.5 0 019 13h6a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});