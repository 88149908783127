define("ember-svg-jar/inlined/currency-euro-bill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-euro-bill</title><path d=\"M21.143 7.487A.25.25 0 0121 7.261V5.5a1 1 0 00-1-1H4a1 1 0 00-1 1v1.761a.25.25 0 01-.143.226 4.994 4.994 0 000 9.027.25.25 0 01.143.226v1.76a1 1 0 001 1h16a1 1 0 001-1v-1.76a.25.25 0 01.143-.226 4.994 4.994 0 000-9.027zM13.214 11a1 1 0 010 2h-1.8a.25.25 0 00-.212.382A3.682 3.682 0 0014.357 15a1 1 0 010 2 5.5 5.5 0 01-5.413-3.815A.249.249 0 008.7 13h-.06a1 1 0 010-2h.06a.249.249 0 00.241-.185A5.5 5.5 0 0114.357 7a1 1 0 010 2 3.682 3.682 0 00-3.157 1.618.25.25 0 00.212.382z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});