define("ember-svg-jar/inlined/design-file-indd", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-indd</title><path d=\"M15.5 6h3.9a.25.25 0 00.177-.427L14.427.427A.25.25 0 0014 .6v3.9A1.5 1.5 0 0015.5 6zM19.456 13.319a.249.249 0 00-.331.24v3.882a.25.25 0 00.331.241 2.239 2.239 0 001.419-2.182 2.238 2.238 0 00-1.419-2.181zM14.456 13.319a.249.249 0 00-.331.24v3.882a.25.25 0 00.331.241 2.239 2.239 0 001.419-2.182 2.238 2.238 0 00-1.419-2.181z\"/><path d=\"M22.8 10H3.25A.25.25 0 013 9.75v-1a.25.25 0 01.25-.25h16.5a.25.25 0 00.25-.25v-1a.25.25 0 00-.25-.25H15.5A2.5 2.5 0 0113 4.5V.25a.25.25 0 00-.25-.25H2a2 2 0 00-2 2v20a2 2 0 002 2h16a1.993 1.993 0 001.894-1.38.1.1 0 00-.11-.12H3.25a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H22.8a1.114 1.114 0 001.2-1v-9a1.114 1.114 0 00-1.2-1zM6.377 17.875H6.5a.625.625 0 010 1.25h-2a.625.625 0 010-1.25h.126a.249.249 0 00.249-.249v-4.249a.252.252 0 00-.252-.252H4.5a.625.625 0 010-1.25h2a.625.625 0 010 1.25h-.126a.249.249 0 00-.249.249v4.249a.252.252 0 00.252.252zm5.748.625a.624.624 0 01-.481.608.59.59 0 01-.144.017.625.625 0 01-.559-.346s-1.492-2.974-1.563-3.127-.253-.116-.253.048v2.8a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279s1.53 3.049 1.586 3.166a.122.122 0 00.23-.071V12.5a.625.625 0 011.25 0zm1.375.625a.625.625 0 01-.625-.625v-6a.625.625 0 01.625-.625 3.625 3.625 0 010 7.25zm5 0a.625.625 0 01-.625-.625v-6a.625.625 0 01.625-.625 3.625 3.625 0 010 7.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});