define("ember-svg-jar/inlined/toilet-seat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toilet-seat</title><path d=\"M13 12.505a.5.5 0 00.5-.5V10a.5.5 0 00-.5-.5H3a1 1 0 00-1 1v1.005a1 1 0 001 1z\"/><path d=\"M19.5 0h-2A2.5 2.5 0 0015 2.5v10a1.5 1.5 0 01-1.5 1.5h-10a.5.5 0 00-.5.5c0 3.488 2.735 4.2 4.174 4.407a.5.5 0 01.424.569l-.507 3.377A1 1 0 008.081 24H17a1 1 0 001-1v-3.7c0-1.473.987-2.213 2.033-3C21 15.579 22 14.829 22 13.5v-11A2.5 2.5 0 0019.5 0zm-1 5.5A1.5 1.5 0 1120 4a1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});