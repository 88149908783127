define("ember-svg-jar/inlined/arrow-button-right-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-right-1</title><path d=\"M13.584 12a2.643 2.643 0 01-.775 1.875l-9.541 9.541a1.768 1.768 0 01-2.5-2.5l8.739-8.739a.25.25 0 000-.354L.768 3.084a1.768 1.768 0 012.5-2.5l9.541 9.541A2.643 2.643 0 0113.584 12z\"/><path d=\"M23.75 12a2.643 2.643 0 01-.775 1.875l-9.541 9.541a1.768 1.768 0 01-2.5-2.5l8.739-8.739a.25.25 0 000-.354l-8.739-8.739a1.768 1.768 0 012.5-2.5l9.541 9.541A2.643 2.643 0 0123.75 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});