define("ember-svg-jar/inlined/design-tool-magic-wand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-tool-magic-wand</title><path d=\"M23.543 10.547l-2.671-2.67a.39.39 0 01-.051-.185l1.394-4.185a1.314 1.314 0 00-1.722-1.723l-4.17 1.392a.412.412 0 01-.189-.037L13.453.457a1.211 1.211 0 00-2.132 1.026l.457 4.093a.428.428 0 01-.044.11L7.442 8.071a1.234 1.234 0 00.334 2.363l2.83.652a.249.249 0 01.121.42L.366 21.866a1.25 1.25 0 001.768 1.768l10.36-10.36a.25.25 0 01.42.121l.653 2.831a1.234 1.234 0 002.363.334l2.37-4.273a.382.382 0 01.1-.063l4.118.456a1.212 1.212 0 001.025-2.133zm-4.923-.311a2.159 2.159 0 00-2.054 1.064l-1.156 2.08a.25.25 0 01-.462-.065l-.5-2.147A2.263 2.263 0 0012.83 9.55l-2.146-.494a.251.251 0 01-.065-.463L12.7 7.437a2.166 2.166 0 001.061-2.055l-.144-1.294a.251.251 0 01.426-.205l.672.673a2.275 2.275 0 002.222.523l2.5-.832a.25.25 0 01.316.316l-.833 2.5a2.281 2.281 0 00.525 2.221l.674.673a.251.251 0 01-.2.426z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});