define("ember-svg-jar/inlined/arrow-thick-left-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-left-3</title><path d=\"M22.915 9h-11.37a.251.251 0 01-.19-.413l4.233-4.937a1 1 0 00-.76-1.65h-5.69a1 1 0 00-.746.334l-8.053 9a1 1 0 000 1.332l8.053 9a1 1 0 00.746.334h5.69a1 1 0 00.76-1.65l-4.233-4.937a.251.251 0 01.19-.413h11.37a1 1 0 001-1v-4a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});