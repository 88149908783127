define("ember-svg-jar/inlined/board-game-deuce", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>board-game-deuce</title><path d=\"M23.269 6.594L17.406.733a2.5 2.5 0 00-3.535 0L8.186 6.417a.5.5 0 000 .708l8.691 8.69a.5.5 0 00.707 0l5.685-5.685a2.5 2.5 0 000-3.536zm-9.392 1.568a1 1 0 111-1 1 1 0 01-1 1zm5.552.37a1 1 0 111-1 1 1 0 01-1 1zM7.125 8.185a.5.5 0 00-.707 0L.733 13.87a2.5 2.5 0 000 3.535L6.6 23.268a2.5 2.5 0 003.536 0l5.684-5.685a.5.5 0 000-.707zm-2.593 9.322a1 1 0 111-1 1 1 0 01-1 1zm2.961 2.961a1 1 0 111-1 1 1 0 01-1 1zm0-5.921a1 1 0 111-1 1 1 0 01-1 1zm2.961 2.96a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});