define("ember-svg-jar/inlined/diving-boat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diving-boat</title><path d=\"M3.5 6a1 1 0 000 2h13a1 1 0 000-2zM21.5 4a1 1 0 00-1-1h-17a1 1 0 000 2h17a1 1 0 001-1zM20.5 0h-17a1 1 0 000 2h17a1 1 0 000-2zM7.242 19.317a.25.25 0 00-.135.176.247.247 0 00.058.213 1.945 1.945 0 001.4.755 2.792 2.792 0 001.888-1.016 2.5 2.5 0 014.287.389 1.279 1.279 0 001.051.627 1.868 1.868 0 001.477-.845A2.413 2.413 0 0119.4 18.41a2.281 2.281 0 01.585.081.249.249 0 00.289-.133 6.72 6.72 0 00.407-5.055.5.5 0 00-.477-.352.509.509 0 00-.222.052l-5.3 2.627a.25.25 0 01-.333-.109l-1.008-1.952a.251.251 0 01.107-.337l1.182-.61a1 1 0 10-.918-1.778l-1.182.611a.251.251 0 01-.337-.108l-.807-1.563a1 1 0 10-1.778.918l.808 1.563a.254.254 0 01.016.191.251.251 0 01-.123.146l-1.182.61a1 1 0 00.918 1.778l1.182-.611a.251.251 0 01.337.108l.989 1.915a.25.25 0 01-.111.338z\"/><path d=\"M22.931 21.951c-1.575.132-2.668-1.556-2.676-1.57a1.048 1.048 0 00-1.716.023 3.375 3.375 0 01-2.753 1.557 2.8 2.8 0 01-2.4-1.481 1.043 1.043 0 00-1.717-.156 4.3 4.3 0 01-3.1 1.637 3.555 3.555 0 01-2.76-1.58 1.042 1.042 0 00-1.638-.09C4.148 20.308 2.771 22 1.17 22h-.117A.983.983 0 000 22.939a1 1 0 00.946 1.052q.114.009.223.009a5.708 5.708 0 003.559-1.463.251.251 0 01.329.006 5.177 5.177 0 003.5 1.421A5.791 5.791 0 0012.2 22.6a.251.251 0 01.332.016 4.548 4.548 0 003.258 1.342 5.118 5.118 0 003.455-1.341.248.248 0 01.334 0 4.678 4.678 0 003.494 1.326 1 1 0 10-.138-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});