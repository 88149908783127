define("ember-svg-jar/inlined/read-email-at-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-email-at-1</title><path d=\"M12.033 9.686a2.816 2.816 0 102.784 2.814 2.8 2.8 0 00-2.784-2.814z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm5.566 15.626c1.47 0 2.238-1.842 2.238-3.662a7.113 7.113 0 00-1.864-4.8 8.417 8.417 0 00-6.373-2.408 7.415 7.415 0 00-7.314 7.5 7.383 7.383 0 007.247 7.5 7.05 7.05 0 003.614-1 .75.75 0 01.769 1.288 8.553 8.553 0 01-4.383 1.21 8.885 8.885 0 01-8.747-9 8.917 8.917 0 018.814-9 9.741 9.741 0 017.472 2.888 8.627 8.627 0 012.261 5.822c0 2.991-1.572 5.162-3.738 5.162a2.6 2.6 0 01-2.417-1.246.25.25 0 00-.379-.068 4.233 4.233 0 01-2.737 1.005 4.315 4.315 0 010-8.63 4.177 4.177 0 012.391.757.25.25 0 00.393-.205v-.611a.75.75 0 011.5 0v6.478c.004.677.425 1.02 1.253 1.02z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});