define("ember-svg-jar/inlined/reception-pay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>reception-pay</title><path d=\"M.5 11.5h7A.5.5 0 008 11v-.5a3 3 0 00-3-3H3a3 3 0 00-3 3v.5a.5.5 0 00.5.5z\"/><circle cx=\"4\" cy=\"3.5\" r=\"2.5\"/><path d=\"M13.5 22.25v-7.617a.25.25 0 00-.434-.169A4.976 4.976 0 019.96 16.11a2.257 2.257 0 01-2.16-1.6A2.219 2.219 0 017.893 13H.75a.75.75 0 000 1.5h.75V23a.5.5 0 00.5.5h11.459a.253.253 0 00.21-.113.25.25 0 00.019-.237 2.234 2.234 0 01-.188-.9zM8 16.5a.5.5 0 010 1H5a.5.5 0 000 1h2a1.5 1.5 0 010 3h-.5v.5a.5.5 0 01-1 0v-.5H4a.5.5 0 010-1h3a.5.5 0 000-1H5a1.5 1.5 0 010-3h.5V16a.5.5 0 011 0v.5z\"/><circle cx=\"17.5\" cy=\"3\" r=\"2.5\"/><path d=\"M24 10.89a4.005 4.005 0 00-4-4h-5a4 4 0 00-3.99 3.76 2.06 2.06 0 01-1.42 2.01 1.254 1.254 0 00-.83 1.56c.011.036.459 1.527 2.27.54a4.489 4.489 0 002.47-4.12.5.5 0 011 0v11.61a1.25 1.25 0 002.5 0V16a.5.5 0 011 0v6.25a1.25 1.25 0 002.5 0V10.64a.5.5 0 011 0v3.86a1.25 1.25 0 002.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});