define("ember-svg-jar/inlined/trends-torch-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trends-torch-2</title><path d=\"M5.429 11a.5.5 0 00-.415.777l1.4 2.109A2.494 2.494 0 008.5 15h.1l1.7 7.633a1.746 1.746 0 003.409 0L15.4 15h.1a2.5 2.5 0 002.08-1.112l1.407-2.11a.5.5 0 00-.416-.778zM10.369 9.5a.249.249 0 00.246-.292 1.412 1.412 0 01-.017-.144 1.494 1.494 0 01.742-1.423 3.213 3.213 0 001.473-1.521 5.253 5.253 0 011.063 3.118.246.246 0 00.068.184.249.249 0 00.181.078h2.917a.249.249 0 00.246-.209C18.07 4.535 13.643.8 13.118.378c-.357-.287-.594-.474-.9-.327a.465.465 0 00-.283.426 11.119 11.119 0 01-3.318 3.636c-2.372 1.9-2.354 3.8-1.967 5.207a.249.249 0 00.241.183z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});