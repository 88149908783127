define("ember-svg-jar/inlined/athletics-shooting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>athletics-shooting</title><path d=\"M5.777 4.009a.25.25 0 00-.188.085.246.246 0 00-.06.2 2.486 2.486 0 004.942 0 .25.25 0 00-.248-.283zM23 2.259a1 1 0 00-1 1v.5h-4.75a1.5 1.5 0 00-1.5 1.5v1.5a.25.25 0 00.25.25h1a2.406 2.406 0 01.908.179.249.249 0 00.344-.231v-.2h1.5a1 1 0 001-1H23a1 1 0 001-1v-1.5a1 1 0 00-1-.998zM11.75 3.009a1 1 0 000-2h-1.472A3.069 3.069 0 008 .009a3 3 0 00-3 2.445.5.5 0 00.109.407.493.493 0 00.242.148zM18.5 9.509a1.5 1.5 0 00-1.5-1.5H4.914A4.913 4.913 0 000 12.923v1.586a1.5 1.5 0 003 0v-1.586a1.9 1.9 0 01.561-1.354 1.849 1.849 0 011.163-.547.249.249 0 01.276.249v4.715l-.978 6.245a1.5 1.5 0 002.956.51l.776-4.5a.25.25 0 01.492 0l.776 4.5a1.517 1.517 0 001.733 1.223 1.5 1.5 0 001.223-1.733L11 15.986v-4.727a.25.25 0 01.25-.25H17a1.5 1.5 0 001.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});