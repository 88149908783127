define("ember-svg-jar/inlined/maps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>maps</title><path d=\"M24 7.015a1.5 1.5 0 00-.656-1.241l-7-4.761a1.493 1.493 0 00-1.677-.007l-.019.013-5.832 4.29a.5.5 0 01-.592 0l-5.837-4.27A1.5 1.5 0 000 2.25v14.735a1.5 1.5 0 00.656 1.241l7 4.761a1.5 1.5 0 001.676.007l.019-.014 5.832-4.289a.5.5 0 01.592 0l5.837 4.27A1.5 1.5 0 0024 21.75zM9.5 7.53a.5.5 0 01.2-.4l4.4-3.291a.25.25 0 01.4.2V16.47a.5.5 0 01-.2.4l-4.4 3.292a.25.25 0 01-.4-.2zM2 3.727a.25.25 0 01.4-.2l4.9 3.58a.5.5 0 01.205.4v12.482a.249.249 0 01-.133.221.252.252 0 01-.258-.014l-4.89-3.327A.5.5 0 012 16.456zm20 16.545a.25.25 0 01-.4.2l-4.9-3.582a.5.5 0 01-.2-.4V4.011a.249.249 0 01.133-.221.252.252 0 01.258.014l4.89 3.326a.5.5 0 01.219.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});