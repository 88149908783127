define("ember-svg-jar/inlined/programming-language-css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-language-css</title><path d=\"M21 1.5H3a3 3 0 00-3 3v15a3 3 0 003 3h18a3 3 0 003-3v-15a3 3 0 00-3-3zM3 5a1 1 0 111 1 1 1 0 01-1-1zm3 0a1 1 0 111 1 1 1 0 01-1-1zm3 0a1 1 0 111 1 1 1 0 01-1-1zm13 14.5a1 1 0 01-1 1H3a1 1 0 01-1-1V9a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M8 17.75a.75.75 0 000-1.5A1.251 1.251 0 016.75 15v-1A1.252 1.252 0 018 12.75a.75.75 0 000-1.5A2.753 2.753 0 005.25 14v1A2.752 2.752 0 008 17.75zM11.5 16.25h-1a.75.75 0 000 1.5h1A1.752 1.752 0 0013.25 16a2.553 2.553 0 00-1.3-2.1c-.508-.381-.7-.557-.7-.9a.25.25 0 01.25-.25h1a.75.75 0 000-1.5h-1A1.752 1.752 0 009.75 13a2.551 2.551 0 001.3 2.1c.508.381.7.557.7.9a.25.25 0 01-.25.25zM16 16.25h-1a.75.75 0 000 1.5h1A1.752 1.752 0 0017.75 16a2.553 2.553 0 00-1.3-2.1c-.508-.381-.7-.557-.7-.9a.25.25 0 01.25-.25h1a.75.75 0 000-1.5h-1A1.752 1.752 0 0014.25 13a2.551 2.551 0 001.3 2.1c.508.381.7.557.7.9a.25.25 0 01-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});