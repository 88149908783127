define("ember-svg-jar/inlined/spa-lotus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>spa-lotus</title><path d=\"M21.766 15.863a9.145 9.145 0 01-1.983 1.272 10.24 10.24 0 01-4.311.886 14.01 14.01 0 01-3.351-.418.5.5 0 00-.242 0 14.018 14.018 0 01-3.351.418 10.24 10.24 0 01-4.311-.886 9.145 9.145 0 01-1.983-1.272.5.5 0 00-.659.019A2.925 2.925 0 00.5 17.991c0 2.76 5.15 5 11.5 5a25.7 25.7 0 003.759-.274.5.5 0 00.382-.7L15.5 20.6a.5.5 0 01.633-.673l3.9 1.485a.5.5 0 00.389-.014c1.906-.892 3.076-2.086 3.076-3.4a2.925 2.925 0 00-1.075-2.109.5.5 0 00-.657-.026z\"/><path d=\"M23.791 10.016a.955.955 0 00-.624-.521 12.591 12.591 0 00-1.4-.276.5.5 0 00-.555.379 9.922 9.922 0 01-1.6 3.576 10.2 10.2 0 01-3.327 2.843.25.25 0 00.143.468 8.257 8.257 0 002.725-.711 10.563 10.563 0 004.636-4.946.954.954 0 00.002-.812zM4.849 15.774a8.263 8.263 0 002.725.711.25.25 0 00.144-.468 10.193 10.193 0 01-3.328-2.843A9.922 9.922 0 012.788 9.6a.5.5 0 00-.555-.379 12.544 12.544 0 00-1.4.276.953.953 0 00-.619 1.334 10.558 10.558 0 004.635 4.943zM17.052 8.253a11.964 11.964 0 01-1.915 6.158.25.25 0 00.332.351 8.777 8.777 0 002.931-2.475 10.55 10.55 0 001.673-6.567.953.953 0 00-1.186-.869 12.578 12.578 0 00-1.8.615.5.5 0 00-.292.571 9.924 9.924 0 01.257 2.216zM8.53 14.762a.25.25 0 00.332-.352 11.961 11.961 0 01-1.915-6.157A9.924 9.924 0 017.2 6.037a.5.5 0 00-.291-.571 12.578 12.578 0 00-1.8-.615.953.953 0 00-1.186.87A10.549 10.549 0 005.6 12.287a8.776 8.776 0 002.93 2.475zM12 15.776a.5.5 0 00.358-.152c.131-.135 3.194-3.337 3.194-7.371a11.582 11.582 0 00-2.781-6.9 1.032 1.032 0 00-1.543 0 11.585 11.585 0 00-2.781 6.9c0 4.034 3.063 7.236 3.194 7.371a.5.5 0 00.359.152z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});