define("ember-svg-jar/inlined/target-center-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>target-center-1</title><path d=\"M1.92 20.549a.249.249 0 010 .309L.716 22.38a1 1 0 101.568 1.24l1.052-1.329a.25.25 0 01.344-.046 8.964 8.964 0 0010.856-.153.249.249 0 01.341.03l1.376 1.543a1.013 1.013 0 001.412.081 1 1 0 00.082-1.411l-1.5-1.685a.25.25 0 01-.014-.314 8.956 8.956 0 00.41-10.084.256.256 0 00-.4-.041L14.6 11.859a.242.242 0 00-.042.285 6.278 6.278 0 11-1.94-2.232.25.25 0 01.032.38l-.724.724a.25.25 0 01-.315.031 4.69 4.69 0 00-3.656-.681 4.751 4.751 0 105.659 5.773 4.712 4.712 0 00-.12-2.671.252.252 0 00-.415-.087L10.961 15.5a.236.236 0 00-.061.1A2 2 0 119 13a2.059 2.059 0 01.353.031.251.251 0 01.133.423l-.886.886a.749.749 0 000 1.06.763.763 0 001.061 0l7.326-7.327A.251.251 0 0117.164 8H20.5a.5.5 0 00.339-.132l3-2.757a.5.5 0 00-.18-.842l-2.826-.942a.253.253 0 01-.158-.158L19.732.342a.5.5 0 00-.37-.331.506.506 0 00-.473.151l-2.758 3A.5.5 0 0016 3.5v3.336a.255.255 0 01-.073.177l-.961.961a.25.25 0 01-.333.017A8.992 8.992 0 001.92 20.549z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});