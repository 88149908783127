define("ember-svg-jar/inlined/task-finger-show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-finger-show</title><path d=\"M4.5 17.171a.251.251 0 00.123-.216V6a1 1 0 01.4-.8.249.249 0 00.1-.2V3.354a.25.25 0 00-.318-.241A3 3 0 002.625 6l.006 12.3a.25.25 0 00.45.149A4.879 4.879 0 014.5 17.171zM19.193 3.113a.25.25 0 00-.318.241V5a.249.249 0 00.1.2 1 1 0 01.4.8v13a1 1 0 01-1 1h-1.5a.249.249 0 00-.25.248 2.651 2.651 0 01-.406 1.371.249.249 0 00.213.381h1.943a3 3 0 003-3V6a3 3 0 00-2.182-2.887zM6.625 5.749a.25.25 0 00.25.25h10.25a.25.25 0 00.25-.25v-3a.25.25 0 00-.25-.25h-2.261a.25.25 0 01-.249-.227 2.5 2.5 0 00-4.98 0 .249.249 0 01-.249.227H6.875a.25.25 0 00-.25.25zm6.25-3.5a.75.75 0 11-.75-.75.75.75 0 01.75.75zM14.674 19.257a1.25 1.25 0 00-1.76.163l-.879.735a.25.25 0 01-.41-.191V13.5a1.5 1.5 0 00-3 0v4.25a.25.25 0 01-.25.25H6.958a3.333 3.333 0 00-3.333 3.333v2.417a.25.25 0 00.25.25h8.365a.251.251 0 00.192-.09l2.4-2.892a1.25 1.25 0 00-.158-1.761z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});