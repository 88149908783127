define("ember-svg-jar/inlined/check-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M23.854 7.146l-3.5-3.5a.5.5 0 00-.707 0L7.5 15.792l-3.147-3.146a.5.5 0 00-.707 0l-3.5 3.5a.5.5 0 000 .707l7 7a.499.499 0 00.707 0l16-16a.5.5 0 00.001-.707z\"/><g><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});