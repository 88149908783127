define("ember-svg-jar/inlined/phone-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-scroll</title><path d=\"M23 16.5h-3.5A3.5 3.5 0 0116 13a1 1 0 00-1-1h-3a1 1 0 010-2h5.5a1.042 1.042 0 001-1 1 1 0 00-1-1H16V5h.369a1 1 0 01.574.181l4.484 3.138A1 1 0 0022 8.5h1a1 1 0 000-2h-.685L18.09 3.538A2.992 2.992 0 0016.369 3H16a3 3 0 00-3-3H3a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3v-3.769a5.467 5.467 0 003.5 1.269H23a1 1 0 000-2zm-9.5 3h-11A.5.5 0 012 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5V8h-2a3 3 0 000 6h2v5a.5.5 0 01-.5.5z\"/><path d=\"M5.75 7h.5v2.375a1 1 0 002 0V7h.5a.5.5 0 00.4-.8l-1.5-2a.518.518 0 00-.8 0l-1.5 2a.5.5 0 00.4.8zM8.75 15.5h-.5v-2a1 1 0 00-2 0v2h-.5a.5.5 0 00-.4.8l1.5 2a.5.5 0 00.8 0l1.5-2a.5.5 0 00-.4-.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});