define("ember-svg-jar/inlined/programming-language-css-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-language-css-3</title><path d=\"M4.067 20.731l7.6 2.682a1 1 0 00.666 0l7.6-2.682a1 1 0 00.656-.8l2.739-18.252A1 1 0 0022.339.53H1.661a1 1 0 00-.989 1.149l2.739 18.257a1 1 0 00.656.795zM6.5 3.434h9.2a1.5 1.5 0 01.771 2.786l-4.586 2.75a.25.25 0 00.128.464h3.91a1.5 1.5 0 011.486 1.712l-.873 6.108a1.5 1.5 0 01-1.071 1.23l-3.053.872a1.459 1.459 0 01-.411.059 1.5 1.5 0 01-.419-.061l-3.052-.872a1.51 1.51 0 01-1.069-1.193L7.013 14.6a1 1 0 011.973-.328l.344 2.064a.5.5 0 00.356.4l2.177.622a.5.5 0 00.275 0l2.151-.614a.5.5 0 00.358-.41l.659-4.61a.247.247 0 00-.058-.2.252.252 0 00-.189-.087H9.305a1.5 1.5 0 01-.768-2.788L13.115 5.9a.25.25 0 00-.128-.465H6.5a1 1 0 010-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});