define("ember-svg-jar/inlined/marine-mammal-sea-lion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>marine-mammal-sea-lion</title><path d=\"M22.261 20.146c-.7-.7-2.354-3.3-2.354-5.146V8a8 8 0 00-16 0v7a9.55 9.55 0 01-1.913 5.146C.9 21.245-.143 22.284.227 23.177c.3.729 1.324.823 2.18.823a6.45 6.45 0 003.042-.788h.013a3.884 3.884 0 002.046-2.462.75.75 0 111.5 0 4.352 4.352 0 01-1.663 2.944 28.643 28.643 0 004.562.306 28.778 28.778 0 004.559-.3 4.286 4.286 0 01-1.658-2.947.75.75 0 011.5 0 3.943 3.943 0 002.05 2.467h.01a6.452 6.452 0 003.039.78c.85 0 2.02-.095 2.359-.915.37-.893-.553-1.985-1.505-2.939zM15.655 3a1 1 0 11-1 1 1 1 0 011-1zm-7.5 0a1 1 0 11-1 1 1 1 0 011-1zm8.781 5.031a4.726 4.726 0 01-.528.447V13a3.525 3.525 0 01-2.2 3.362.5.5 0 01-.636-.64 8.149 8.149 0 00.84-3.222V9.25a4.5 4.5 0 01-2.5-.8 4.513 4.513 0 01-2.5.8v3.25a8.168 8.168 0 00.839 3.222.5.5 0 01-.635.64A3.525 3.525 0 017.408 13V8.481a4.785 4.785 0 01-.533-.45.75.75 0 011.061-1.06 2.73 2.73 0 001.47.779 3.085 3.085 0 001.969-.78.771.771 0 011.061 0 3.109 3.109 0 001.97.78 2.733 2.733 0 001.471-.781.75.75 0 011.059 1.062z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});