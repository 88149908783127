define("ember-svg-jar/inlined/movies-reel-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>movies-reel-1</title><path d=\"M22.75 16.5a1.25 1.25 0 00-1.25 1.25v2a1.75 1.75 0 01-3.5 0v-4.512a.25.25 0 00-.453-.145 10.6 10.6 0 01-1.965 2.082.219.219 0 00-.082.171v2.4a4.25 4.25 0 008.5 0v-2a1.25 1.25 0 00-1.25-1.246zM18 9a9 9 0 10-9 9 9 9 0 009-9zM4.987 13.049A1.5 1.5 0 115.536 11a1.5 1.5 0 01-.549 2.049zM5.536 7a1.5 1.5 0 11-.549-2.049A1.5 1.5 0 015.536 7zM9 16a1.5 1.5 0 111.5-1.5A1.5 1.5 0 019 16zM9 5a1.5 1.5 0 111.5-1.5A1.5 1.5 0 019 5zm6.062 7.5a1.5 1.5 0 11-.549-2.049 1.5 1.5 0 01.549 2.049zm-.549-4.951a1.5 1.5 0 11.549-2.049 1.5 1.5 0 01-.549 2.049z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});