define("ember-svg-jar/inlined/cloud-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-sync</title><path d=\"M24 11.378a5.765 5.765 0 00-5.216-5.952.249.249 0 01-.191-.12A7.684 7.684 0 004.493 7.6a.25.25 0 01-.227.2 4.644 4.644 0 00-3.642 2.24A4.467 4.467 0 000 12.488a4.293 4.293 0 001.235 3.1 4.612 4.612 0 001.78 1.062.963.963 0 001.243-.86v-.026a.986.986 0 00-.658-1.033 2.58 2.58 0 01-.96-.572A2.374 2.374 0 012 12.374a2.588 2.588 0 012.586-2.588 2.626 2.626 0 01.535.055 1 1 0 001.2-.963c.085-5.736 8.293-7.373 10.818-2.045a.974.974 0 00.941.57 3.737 3.737 0 013 1.309 3.888 3.888 0 01.9 2.812 3.426 3.426 0 01-1.473 2.667.973.973 0 00-.4.964l.01.059a.968.968 0 001.525.608A5.4 5.4 0 0024 11.378z\"/><path d=\"M6.014 21.464l.97-.97a.25.25 0 01.358 0 6.086 6.086 0 0010.21-2.2 1 1 0 00-1.893-.648 4.091 4.091 0 01-6.918 1.42.25.25 0 01.009-.345L10.471 17a.5.5 0 00-.353-.854H5.661a.5.5 0 00-.5.5v4.464a.5.5 0 00.308.462.47.47 0 00.14.014.485.485 0 00.405-.122zM16.281 11.337a.25.25 0 01-.357 0 6.1 6.1 0 00-10.2 2.226 1 1 0 001.893.649 4.091 4.091 0 016.9-1.446.251.251 0 01-.006.347l-1.66 1.659a.5.5 0 00.354.854h4.457a.5.5 0 00.5-.5v-4.461a.5.5 0 00-.854-.354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});