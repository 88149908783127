define("ember-svg-jar/inlined/file-asp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-asp</title><path d=\"M7 13.626a.876.876 0 00-.875.875v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V14.5A.876.876 0 007 13.626zM17 13.626h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H17a.875.875 0 000-1.75zM17.5 6h3.9a.25.25 0 00.177-.427L16.427.428A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.251A.25.25 0 0014.75 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.251A.25.25 0 0021.75 7zM9.125 19a.625.625 0 01-1.25 0v-2.124a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V19a.625.625 0 01-1.25 0v-4.374a2.212 2.212 0 011.9-2.239 2.128 2.128 0 012.35 2.113zm2.3-4.133l1.84 1.226a1.928 1.928 0 01-1.07 3.532H10.5a.625.625 0 010-1.25h1.7a.678.678 0 00.376-1.242l-1.84-1.226a1.928 1.928 0 011.07-3.532h1.7a.625.625 0 010 1.25H11.8a.678.678 0 00-.376 1.242zm5.448 1.758h-.5a.25.25 0 00-.25.25V19a.625.625 0 11-1.25 0v-6a.625.625 0 01.625-.625H17a2.128 2.128 0 012.114 2.347 2.213 2.213 0 01-2.239 1.904z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});