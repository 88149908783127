define("ember-svg-jar/inlined/calendar-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>calendar-refresh</title><path d=\"M2.5 16.5A.5.5 0 012 16V7.5a.5.5 0 01.5-.5H16a.5.5 0 01.5.5v2.07a7.281 7.281 0 012 0V3a1 1 0 00-1-1H15a.25.25 0 01-.25-.25v-1a.75.75 0 10-1.5 0V4.5a.75.75 0 11-1.5 0v-2a.5.5 0 00-.5-.5H7a.25.25 0 01-.25-.25v-1a.75.75 0 00-1.5 0V4.5a.75.75 0 11-1.5 0v-2a.5.5 0 00-.5-.5H1a1 1 0 00-1 1v13.5a2 2 0 002 2h7.569a7.281 7.281 0 010-2zM22.769 18.6a1 1 0 00-1.27.623 4.093 4.093 0 01-7.074 1.238l1.886-1.885a.5.5 0 00-.354-.854H11.5a.5.5 0 00-.5.5v4.458a.5.5 0 00.854.353l1.155-1.155a6.088 6.088 0 0010.383-2.008 1 1 0 00-.623-1.27z\"/><path d=\"M23.691 11.773a.5.5 0 00-.545.109l-1.21 1.21a6.1 6.1 0 00-10.369 2.038 1 1 0 001.893.648 4.094 4.094 0 017.054-1.264l-1.825 1.825a.5.5 0 00.354.853H23.5a.5.5 0 00.5-.5v-4.457a.5.5 0 00-.309-.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});