define("ember-svg-jar/inlined/lucide-fingerprint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2 12C2 6.5 6.5 2 12 2a10 10 0 018 4\"/><path d=\"M5 19.5C5.5 18 6 15 6 12c0-.7.12-1.37.34-2M17.29 21.02c.12-.6.43-2.3.5-3.02M12 10a2 2 0 00-2 2c0 1.02-.1 2.51-.26 4M8.65 22c.21-.66.45-1.32.57-2M14 13.12c0 2.38 0 6.38-1 8.88M2 16h.01M21.8 16c.2-2 .131-5.354 0-6M9 6.8a6 6 0 019 5.2c0 .47 0 1.17-.02 2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});