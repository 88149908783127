define("ember-svg-jar/inlined/road-sign-3-way", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-3-way</title><path d=\"M15.75 10.5v1a.25.25 0 00.5 0v-1c0-.275-.5-.275-.5 0z\"/><path d=\"M23.25 5.75H.75A.75.75 0 000 6.5v11a.75.75 0 00.75.75h22.5a.75.75 0 00.75-.75v-11a.75.75 0 00-.75-.75zM6.413 12.138a2 2 0 01-1.653 3.108h-.757A1.753 1.753 0 012.25 13.5a.75.75 0 011.5 0 .25.25 0 00.25.25h.753a.5.5 0 000-1 .75.75 0 110-1.5.5.5 0 000-1h-.758a.249.249 0 00-.246.25.75.75 0 01-1.5 0A1.752 1.752 0 014 8.749h.76a2 2 0 011.653 3.111.251.251 0 000 .278zm7.337-1.294a6.725 6.725 0 01-.483 2.508l-.567 1.426a.749.749 0 01-.7.472h-.038a.75.75 0 01-.683-.543l-.039-.134a.249.249 0 00-.48 0l-.039.134a.75.75 0 01-1.417.072l-.571-1.427a6.716 6.716 0 01-.483-2.507V9.5a.75.75 0 011.5 0v1.344a5.243 5.243 0 00.15 1.263l.375-1.313a.75.75 0 011.442 0l.375 1.313a5.249 5.249 0 00.154-1.263V9.5a.75.75 0 011.5 0zm4 3.656a.75.75 0 01-1.5 0v-1a.25.25 0 00-.5 0v1a.75.75 0 01-1.5 0v-4a1.75 1.75 0 013.5 0zm4-3.5a1.746 1.746 0 01-.875 1.509.25.25 0 00-.125.216V14.5a.75.75 0 01-1.5 0v-1.775a.25.25 0 00-.125-.216A1.746 1.746 0 0118.25 11V9.5a.75.75 0 011.5 0V11c0 .275.5.275.5 0V9.5a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});