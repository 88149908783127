define("ember-svg-jar/inlined/delivery-truck", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>delivery-truck</title><path d=\"M8.25 3.75A.249.249 0 008.5 4h5.353a.25.25 0 00.177-.427L11.146.689A1.511 1.511 0 0010.085.25H8.5a.249.249 0 00-.25.25zM6.5 4a.25.25 0 00.25-.25V.5A.25.25 0 006.5.25H4.914a1.511 1.511 0 00-1.061.439L.969 3.573A.25.25 0 001.146 4zM6.5 14.25a2.5 2.5 0 012.5-2.5h5.75a.249.249 0 00.25-.25V5.75a.25.25 0 00-.25-.25H.25a.249.249 0 00-.25.25v9a1.5 1.5 0 001.5 1.5h4.75A.249.249 0 006.5 16z\"/><path d=\"M9.5 13.25a1.5 1.5 0 00-1.5 1.5v7a.5.5 0 00.5.5h.262a.25.25 0 00.249-.227 2.5 2.5 0 014.979 0 .25.25 0 00.249.227h1.523a.25.25 0 00.249-.227 2.5 2.5 0 014.979 0 .25.25 0 00.249.227H22.5a1.5 1.5 0 001.5-1.5v-2.5a3.754 3.754 0 00-3.75-3.75h-1a.544.544 0 00-.057 0 .25.25 0 01-.261-.179A1.5 1.5 0 0017.5 13.25zm12.929 4.437a.25.25 0 01-.242.313H20.25a.251.251 0 01-.25-.25v-1.5a.25.25 0 01.25-.25 2.253 2.253 0 012.179 1.687z\"/><circle cx=\"18.5\" cy=\"22.25\" r=\"1.5\"/><circle cx=\"11.5\" cy=\"22.25\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});