define("ember-svg-jar/inlined/party-beer-cheers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>party-beer-cheers</title><path d=\"M5.75 1.5h-.5A.25.25 0 015 1.25v-.5a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25h-.5a.75.75 0 000 1.5h.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.5A.25.25 0 015.25 3h.5a.75.75 0 000-1.5zM22.75 6.5h-.5a.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25h-.5a.75.75 0 000 1.5h.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.5a.25.25 0 01.25-.25h.5a.75.75 0 000-1.5zM8.018 13.377a3.52 3.52 0 01.643-.06h2.867a.5.5 0 00.5-.435 2.137 2.137 0 011.6-1.965.5.5 0 00.38-.485v-1.5a1.6 1.6 0 00-1.012-1.52c-.473-.267-.846-.476-.846-1.577V2.119A1.121 1.121 0 0011.022 1H10.4a1.12 1.12 0 00-1.116 1.119v3.717c0 1.111-.38 1.328-.86 1.6a1.589 1.589 0 00-1 1.5v3.953a.5.5 0 00.593.491z\"/><circle cx=\"18.25\" cy=\"2.5\" r=\"1.25\"/><circle cx=\"1\" cy=\"8.25\" r=\"1\"/><path d=\"M24 13.5a.5.5 0 00-.5-.5h-4a.5.5 0 00-.5.5v.291a.5.5 0 01-.687.464 1.917 1.917 0 01-.8-.527 3.961 3.961 0 00-3.278-1.638 1 1 0 00-1 1v.977a.5.5 0 01-.5.5H8.661a2.239 2.239 0 00-2.124 2.947l1.651 4.956A2.236 2.236 0 0010.312 24h3.78a4.575 4.575 0 001.794-.357l2.449-.859a.5.5 0 01.665.472v.244a.5.5 0 00.5.5h4a.5.5 0 00.5-.5zm-8.886 1.553a.458.458 0 01.8-.125 4.531 4.531 0 002.691 1.49.5.5 0 01.4.489v3.171a.5.5 0 01-.334.472l-3.5 1.23a2.7 2.7 0 01-1.07.22h-3.78a.237.237 0 01-.226-.163L8.434 16.88a.24.24 0 01.227-.313H13a2.244 2.244 0 002.114-1.514z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});