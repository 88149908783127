define("ember-svg-jar/inlined/accounting-calculator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accounting-calculator</title><path d=\"M16 5.75a.75.75 0 00.75-.75V3.5a.75.75 0 00-1.5 0V5a.75.75 0 00.75.75z\"/><path d=\"M21 3a3 3 0 00-3-3H6a3 3 0 00-3 3v18a3 3 0 003 3h12a3 3 0 003-3zm-9 11a1 1 0 11-1 1 1 1 0 011-1zm-1-3.5a1 1 0 111 1 1 1 0 01-1-1zm5.5 8.25a.75.75 0 010 1.5H11a.75.75 0 010-1.5zm0-2.75a1 1 0 111-1 1 1 0 01-1 1zm0-4.5a1 1 0 111-1 1 1 0 01-1 1zm-9 4.5a1 1 0 111-1 1 1 0 01-1 1zm1 3.5a1 1 0 11-1-1 1 1 0 011 1zm-1-8a1 1 0 111-1 1 1 0 01-1 1zM6 2h12a1 1 0 011 1v3.25a.25.25 0 01-.25.25H5.25A.25.25 0 015 6.25V3a1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});