define("ember-svg-jar/inlined/single-man-actions-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-setting</title><path d=\"M10.448 12.788a3.244 3.244 0 011.985-1.517.25.25 0 00.035-.471l-1.317-.565a.25.25 0 01-.152-.23V8.87a.252.252 0 01.074-.177 3.305 3.305 0 00.779-2.23.253.253 0 01.088-.177 1.873 1.873 0 00.538-1.4 1.58 1.58 0 00-.317-1.015.251.251 0 01-.034-.264 2.531 2.531 0 00.156-1.727C11.943.493 10.24 0 8.887 0a3.752 3.752 0 00-3.163 1.325.25.25 0 01-.212.118 1.069 1.069 0 00-.9.375c-.372.493-.106 1.17.176 1.732a.25.25 0 01-.046.287 1.352 1.352 0 00-.162.2A1.774 1.774 0 004.319 5a1.52 1.52 0 00.639 1.331.253.253 0 01.117.194A3.158 3.158 0 005.92 8.7a.253.253 0 01.08.183v1.122a.249.249 0 01-.151.23l-3.937 1.687C.639 12.468.457 13.626.205 15.23c-.056.359-.117.751-.2 1.179a.5.5 0 00.106.411A.506.506 0 00.5 17h10.122a.25.25 0 00.193-.409 3.167 3.167 0 01-.367-3.803z\"/><circle cx=\"17.748\" cy=\"17.031\" r=\"1.5\"/><path d=\"M23.516 15.653a1.782 1.782 0 00-1.7-2.952l-1.411.326a.45.45 0 01-.532-.31l-.424-1.393a1.747 1.747 0 00-1.7-1.261 1.746 1.746 0 00-1.7 1.261l-.424 1.393a.45.45 0 01-.533.31l-1.411-.327a1.782 1.782 0 00-1.7 2.952l.987 1.066a.461.461 0 010 .624l-.987 1.066a1.782 1.782 0 001.7 2.952l1.413-.326a.451.451 0 01.532.31l.424 1.392A1.746 1.746 0 0017.747 24a1.747 1.747 0 001.7-1.261l.424-1.393a.45.45 0 01.532-.31l1.411.326a1.782 1.782 0 001.7-2.952l-.987-1.066a.461.461 0 010-.624zm-5.768-1.622a3 3 0 11-3 3 3 3 0 013-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});