define("ember-svg-jar/inlined/photography-equipment-light-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>photography-equipment-light-1</title><path d=\"M19.5 0h-15A1.5 1.5 0 003 1.5v9A1.5 1.5 0 004.5 12h15a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0019.5 0zm-2.75 5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm1.5 3.5a1.5 1.5 0 11-1.5-1.5 1.5 1.5 0 011.5 1.5zM12 5a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0112 5zm1.5 3.5A1.5 1.5 0 1112 7a1.5 1.5 0 011.5 1.5zM7.25 5a1.5 1.5 0 111.5-1.5A1.5 1.5 0 017.25 5zm1.5 3.5A1.5 1.5 0 117.25 7a1.5 1.5 0 011.5 1.5zM18 13.5H6a.5.5 0 00-.492.589l.85 4.68A1.5 1.5 0 007.835 20h8.33a1.5 1.5 0 001.477-1.231l.85-4.679A.5.5 0 0018 13.5zm-3.5 4a1 1 0 111-1 1 1 0 01-1 1zM13.5 21.5h-3a.5.5 0 00-.5.5v.5a1.5 1.5 0 001.5 1.5h1a1.5 1.5 0 001.5-1.5V22a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});