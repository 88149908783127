define("ember-svg-jar/inlined/paralympics-fencing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paralympics-fencing</title><path d=\"M8.8 19a.249.249 0 00-.221.133 3.489 3.489 0 11-5.38-4.25.261.261 0 00.063-.084c.12-.267.7-1.54 1.021-2.239a.25.25 0 00-.3-.344 5.5 5.5 0 106.517 7.561v-.527a.25.25 0 00-.25-.25z\"/><circle cx=\"10\" cy=\"3.5\" r=\"2.5\"/><path d=\"M8.788 8.015a1.5 1.5 0 00-1.151.857l-3 6.5A1.5 1.5 0 006 17.5h5.5a.5.5 0 01.5.5v3.5a1.5 1.5 0 003 0V18a3.5 3.5 0 00-3.5-3.5H9.126a.5.5 0 01-.454-.709l1.348-2.922 4.526-.676a.251.251 0 00.209-.29l-.426-2.466a.25.25 0 00-.283-.205zM22.83 6.28l-4.655.8a2.556 2.556 0 00-1.957-1.169.5.5 0 00-.529.584l.681 3.942a.5.5 0 00.7.373 2.561 2.561 0 001.451-1.759l4.654-.8a1 1 0 00-.34-1.971z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});