define("ember-svg-jar/inlined/smiley-kiss-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-kiss-2-alternate</title><path d=\"M10.4 9.916a1 1 0 00.516-1.316 3.5 3.5 0 00-6.416 0 1 1 0 101.832.8 1.5 1.5 0 012.751 0A1 1 0 0010 10a.973.973 0 00.4-.084zM19.5 8.6a3.5 3.5 0 00-6.415 0 1 1 0 101.832.8 1.5 1.5 0 012.751 0 1 1 0 00.917.6.973.973 0 00.4-.084A1 1 0 0019.5 8.6z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M13 11.5h-2a1 1 0 000 2h2a.5.5 0 010 1 1 1 0 000 2 .5.5 0 010 1h-2a1 1 0 000 2h2a2.5 2.5 0 002-4 2.5 2.5 0 00-2-4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});