define("ember-svg-jar/inlined/hierarchy-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hierarchy-5</title><path d=\"M24 19.5a1.5 1.5 0 00-1.5-1.5h-.75a.25.25 0 01-.25-.25V12.5a2 2 0 00-2-2h-6.25a.25.25 0 01-.25-.25v-5a.25.25 0 01.25-.25h5.25A1.5 1.5 0 0020 3.5v-2A1.5 1.5 0 0018.5 0h-13A1.5 1.5 0 004 1.5v2A1.5 1.5 0 005.5 5h5.25a.25.25 0 01.25.25v5a.25.25 0 01-.25.25H4.5a2 2 0 00-2 2v5.25a.25.25 0 01-.25.25H1.5A1.5 1.5 0 000 19.5v3A1.5 1.5 0 001.5 24h3A1.5 1.5 0 006 22.5v-3a1.5 1.5 0 00-1.286-1.485.25.25 0 01-.214-.247v-5.019a.25.25 0 01.25-.25h6a.25.25 0 01.25.25v5a.25.25 0 01-.25.25h-.25A1.5 1.5 0 009 19.5v3a1.5 1.5 0 001.5 1.5h3a1.5 1.5 0 001.5-1.5v-3a1.5 1.5 0 00-1.5-1.5h-.25a.25.25 0 01-.25-.25v-5a.25.25 0 01.25-.25h6a.25.25 0 01.25.25v5.018a.25.25 0 01-.214.247A1.5 1.5 0 0018 19.5v3a1.5 1.5 0 001.5 1.5h3a1.5 1.5 0 001.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});