define("ember-svg-jar/inlined/pollution-faucet-water", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pollution-faucet-water</title><path d=\"M.5 13.014h5.4a.5.5 0 01.464.313 1.5 1.5 0 001.386.937h1a1.5 1.5 0 001.5-1.5v-6.5a1.5 1.5 0 00-1.5-1.5h-1a1.5 1.5 0 00-1.386.936.5.5 0 01-.464.312h-.4a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5H6a1 1 0 000-2H2a1 1 0 000 2h.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-2a.5.5 0 00-.5.5v6a.5.5 0 00.5.502zM17.97 5.044a.748.748 0 001.06 0l1-1a.75.75 0 00-1.06-1.06.666.666 0 01-.94 0 .75.75 0 00-1.06 1.06zM19.97 8.544l1 1a.748.748 0 001.06 0l1-1a.75.75 0 10-1.06-1.06.666.666 0 01-.94 0 .75.75 0 10-1.06 1.06zM12.205 11.535a5 5 0 014.545 4.979v.642a.371.371 0 00.655.239 2.637 2.637 0 013.838-.023.5.5 0 00.885-.4 10.494 10.494 0 00-9.853-8.945.5.5 0 00-.525.5v2.511a.5.5 0 00.455.497zM23 19.986a3.745 3.745 0 01-2.9-1.646 1.056 1.056 0 00-1.546.023 3.72 3.72 0 01-2.884 1.623 3.743 3.743 0 01-2.9-1.646 1.055 1.055 0 00-1.547.024 3.72 3.72 0 01-2.884 1.622 3.745 3.745 0 01-2.9-1.646 1.055 1.055 0 00-1.547.024A3.718 3.718 0 011 19.986a1 1 0 000 2 5.126 5.126 0 003.346-1.243.5.5 0 01.639 0 5.126 5.126 0 006.694 0 .5.5 0 01.639 0 5.128 5.128 0 006.694 0 .5.5 0 01.64 0A5.126 5.126 0 0023 21.986a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});