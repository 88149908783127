define("ember-svg-jar/inlined/share-location-hand-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>share-location-hand-2</title><path d=\"M16 17a1 1 0 00.762-.352C17.349 15.958 22.5 9.81 22.5 6.5a6.5 6.5 0 00-13 0c0 3.31 5.151 9.458 5.738 10.148A1 1 0 0016 17zm0-12.5a2 2 0 11-2 2 2 2 0 012-2zM17 19.5H7.875a.625.625 0 010-1.25H10.4a1 1 0 00.919-1.394A2.234 2.234 0 009.264 15.5H5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.764A2.238 2.238 0 0018 20.764V20.5a1 1 0 00-1-1zM2.5 14.75a1 1 0 00-1 1V23a1 1 0 002 0v-7.25a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});