define("ember-svg-jar/inlined/android", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>android</title><path d=\"M19.5 10.251h-15a3 3 0 00-3 3v4.5a1 1 0 002 0v-4.5a1 1 0 011-1h.25a.5.5 0 01.5.5V19.5a.75.75 0 00.75.75h1a.5.5 0 01.5.5V23a1 1 0 002 0v-2.25a.5.5 0 01.5-.5h4a.5.5 0 01.5.5V23a1 1 0 102 0v-2.25a.5.5 0 01.5-.5h1a.75.75 0 00.75-.75v-6.75a.5.5 0 01.5-.5h.25a1 1 0 011 1v4.5a1 1 0 102 0v-4.5a3 3 0 00-3-2.999zM6.459 3.374a.249.249 0 01.02.33A6.963 6.963 0 005 8v.5a.5.5 0 00.5.5h13a.5.5 0 00.5-.5V8a6.963 6.963 0 00-1.479-4.3.249.249 0 01.02-.33l1.666-1.666a1 1 0 10-1.414-1.41L16.04 2.046a.248.248 0 01-.31.034 6.984 6.984 0 00-7.46 0 .25.25 0 01-.31-.035L6.207.294a1 1 0 00-1.414 1.414zM15.25 4.5V6a.75.75 0 11-1.5 0V4.5a.75.75 0 111.5 0zm-5 1.5a.75.75 0 11-1.5 0V4.5a.75.75 0 111.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});