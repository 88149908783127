define("ember-svg-jar/inlined/tags-check-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-check-alternate</title><circle cx=\"17.17\" cy=\"5.999\" r=\"1.5\"/><path d=\"M9.9 21.191a.249.249 0 00-.32-.114 1.029 1.029 0 01-1.118-.2l-6.171-6.171a1 1 0 010-1.414l11-11A1 1 0 0114 2h6.67a.5.5 0 01.5.5v6.67a1 1 0 01-.293.708l-.166.166a.251.251 0 00.059.4 7.98 7.98 0 011.23.813.25.25 0 00.342-.027 2.927 2.927 0 00.825-2.06V2a2 2 0 00-2-2H14a2.982 2.982 0 00-2.122.879l-11 11a3 3 0 000 4.242l6.172 6.172a3.005 3.005 0 003.57.5.251.251 0 00.077-.373 8 8 0 01-.797-1.229z\"/><path d=\"M17.457 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-.727 9.675h-.111a1.487 1.487 0 01-1.055-.444l-1.5-1.5a.75.75 0 011.06-1.06l1.3 1.3a.247.247 0 00.2.072.25.25 0 00.182-.1l2.725-3.643a.75.75 0 111.2.9l-2.9 3.874a1.493 1.493 0 01-1.101.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});