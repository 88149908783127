define("ember-svg-jar/inlined/taxi-driver", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>taxi-driver</title><path d=\"M19.875 18h-.25a1.973 1.973 0 01-1.258-.452.254.254 0 00-.214-.05.249.249 0 00-.171.138 1.5 1.5 0 11-1.357-2.136 1.458 1.458 0 01.57.116.25.25 0 00.347-.257l-.127-1.137a.25.25 0 00-.248-.222H4.492a.249.249 0 01-.248-.277l.225-2.056a1 1 0 011-.917h8.32a1 1 0 000-2H5.465a3.011 3.011 0 00-2.987 2.725l-.054.492a.251.251 0 01-.4.168l-.394-.316a1 1 0 10-1.254 1.562l.842.674a.25.25 0 01-.013.4A2.484 2.484 0 00.125 16.5v2a1.5 1.5 0 001.5 1.5h17a1.5 1.5 0 001.5-1.5v-.25a.25.25 0 00-.25-.25zm-16.25.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM5.625 21h-3a.5.5 0 00-.5.5v.5a2 2 0 004 0v-.5a.5.5 0 00-.5-.5zM17.625 21h-3a.5.5 0 00-.5.5v.5a2 2 0 004 0v-.5a.5.5 0 00-.5-.5zM20.5 6A3.5 3.5 0 0017 9.5v2a.5.5 0 00.5.5h.828a.25.25 0 01.249.222L19 16.056a.5.5 0 00.5.444h2a.5.5 0 00.5-.444l.426-3.834a.25.25 0 01.246-.222h.828a.5.5 0 00.5-.5v-2A3.5 3.5 0 0020.5 6zM19.28 4.668a2.408 2.408 0 002.44 0 2.5 2.5 0 10-2.44 0zM6.375 8a1 1 0 001-1V6a1 1 0 00-2 0v1a1 1 0 001 1zM6.375 4.25a1 1 0 001-1V3a.25.25 0 01.25-.25h.25a1 1 0 000-2h-1a1.5 1.5 0 00-1.5 1.5v1a1 1 0 001 1zM10.625 2.75h1.5a1 1 0 000-2h-1.5a1 1 0 000 2zM15.125 2.75h1a1 1 0 000-2h-1a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});