define("ember-svg-jar/inlined/arrow-thick-circle-left-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-circle-left-1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm6.778 13a.5.5 0 01-.5.5h-7.392a.25.25 0 00-.177.426l3.212 3.212a.5.5 0 010 .707l-1.289 1.29a.5.5 0 01-.707 0l-6.779-6.78a.5.5 0 010-.707l6.779-6.779a.5.5 0 01.707 0l1.289 1.289a.5.5 0 010 .707l-3.212 3.214a.25.25 0 00.177.426h7.392a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});