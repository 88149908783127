define("ember-svg-jar/inlined/cursor-hand-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-hand-alternate</title><path d=\"M18 9h-.77a2 2 0 00-1.73-1h-1.27a2 2 0 00-1.73-1h-1V2.5a2.5 2.5 0 00-5 0v7.64a4 4 0 00-3 3.86v2.842a5.03 5.03 0 001.465 3.536L7 22.412V23a1 1 0 002 0v-1a1 1 0 00-.293-.707l-2.329-2.33a3.023 3.023 0 01-.878-2.122V14a1.992 1.992 0 011-1.722V15a1 1 0 002 0V2.5a.5.5 0 111 0V11a1 1 0 002 0V9.248a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25V11a1 1 0 002 0v-.75a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25V12a1 1 0 002 0v-.75a.25.25 0 01.25-.25H18a.5.5 0 01.5.5v3.1a9.05 9.05 0 01-.346 2.474l-1.616 5.653a1 1 0 101.923.549l1.616-5.653A11.055 11.055 0 0020.5 14.6v-3.1A2.5 2.5 0 0018 9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});