define("ember-svg-jar/inlined/arduino-plus-minus-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arduino-plus-minus-1-alternate</title><path d=\"M24 10a2.009 2.009 0 00-.8-1.6L22 7.5v-4a2 2 0 00-2-2H2a2 2 0 00-2 2v17a2 2 0 002 2h18a2 2 0 002-2v-2l1.2-.9A2.009 2.009 0 0024 16zm-2 6l-1.6 1.2a1 1 0 00-.4.8v2a.5.5 0 01-.5.5h-17A.5.5 0 012 20V4a.5.5 0 01.5-.5h17a.5.5 0 01.5.5v4a1 1 0 00.4.8L22 10z\"/><path d=\"M19.125 12.062a3.684 3.684 0 00-2.728-3.6c-2.289-.577-3.961 1.1-4.819 2.276a.25.25 0 01-.406 0c-.858-1.175-2.53-2.853-4.819-2.276a3.684 3.684 0 00-2.728 3.6 3.529 3.529 0 003.652 3.591 5.136 5.136 0 003.9-2.393.249.249 0 01.406 0 5.136 5.136 0 003.9 2.393 3.529 3.529 0 003.642-3.591zM15.5 13.5a.5.5 0 01-.5-.5v-.25a.25.25 0 00-.25-.25h-.25a.5.5 0 010-1h.25a.25.25 0 00.25-.25V11a.5.5 0 111 0v.25a.25.25 0 00.25.25h.25a.5.5 0 010 1h-.25a.25.25 0 00-.25.25V13a.5.5 0 01-.5.5zM9 12a.5.5 0 01-.5.5h-2a.5.5 0 110-1h2a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});