define("ember-svg-jar/inlined/disability-heart-plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-heart-plus</title><circle cx=\"3.75\" cy=\"3.006\" r=\"2.5\"/><path d=\"M12.909 17.689a3.483 3.483 0 00-3.485-3.183H7a.507.507 0 01-.5-.452l-.766-5.265a1.5 1.5 0 10-2.968.434l.766 5.247A3.512 3.512 0 007 17.506h2.424a.494.494 0 01.5.439l.335 4.67a1.5 1.5 0 102.99-.241z\"/><path d=\"M7.5 18.756H1a1 1 0 000 2h6.5a1 1 0 000-2zM23.627 1.917a3.569 3.569 0 00-5.652-.9L17 1.9l-.974-.881a3.57 3.57 0 00-5.653.9 3.406 3.406 0 00.661 3.992l5.609 5.732a.5.5 0 00.714 0l5.609-5.732a3.406 3.406 0 00.661-3.994zM19.5 6.5a.25.25 0 01-.25.25H18a.25.25 0 00-.25.25v1.25a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25V7a.25.25 0 00-.25-.25h-1.25a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H16a.25.25 0 00.25-.25V3.751a.25.25 0 01.25-.25h1a.25.25 0 01.25.25V5a.25.25 0 00.25.25h1.25a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});