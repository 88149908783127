define("ember-svg-jar/inlined/currency-euro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-euro</title><path d=\"M20.967 22.5a1.5 1.5 0 00-1.5-1.5c-4.9 0-7.917-1.1-9.432-3.621a.25.25 0 01.214-.379H14.8a1.5 1.5 0 000-3H9.179a.249.249 0 01-.249-.222A15.788 15.788 0 018.833 12c0-.09 0-.175.006-.261a.25.25 0 01.25-.239h7.578a1.5 1.5 0 000-3H9.589a.25.25 0 01-.239-.326C10.5 4.552 13.656 3 19.467 3a1.5 1.5 0 000-3C16.485 0 8.121.008 6.224 8.306a.25.25 0 01-.243.194H4.533a1.5 1.5 0 000 3h1.054a.253.253 0 01.18.076.249.249 0 01.07.183V12c0 .607.028 1.18.079 1.727a.252.252 0 01-.253.273H4.535a1.5 1.5 0 000 3h1.884a.251.251 0 01.235.164C9.15 23.994 16.673 24 19.467 24a1.5 1.5 0 001.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});