define("ember-svg-jar/inlined/ee-icon-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-player</title><path d=\"M43.875 0h-33.75C4.535.006.006 4.536 0 10.125v24.75C.006 40.465 4.536 44.994 10.125 45h33.75c5.59-.006 10.119-4.536 10.125-10.125v-24.75C53.994 4.535 49.464.006 43.875 0zm-8.021 25.52l-12.97 6.484A3.375 3.375 0 0118 28.984V16.016a3.375 3.375 0 014.885-3.019l12.969 6.484a3.375 3.375 0 010 6.04z\" fill=\"#F77074\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "54",
      "height": "45",
      "viewBox": "0 0 54 45",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});