define("ember-svg-jar/inlined/fast-food-pizza", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fast-food-pizza</title><path d=\"M13.846 9.958a.517.517 0 00.579-.115l4.094-3.33a.5.5 0 00.056-.722 7.087 7.087 0 00-4.516-1.984.5.5 0 00-.539.5v5.167a.514.514 0 00.326.484zM14.006 2.369a8.5 8.5 0 015.5 2.423.5.5 0 00.733 0l.841-.911a.6.6 0 00.157-.451.679.679 0 00-.2-.428 9.821 9.821 0 00-6.927-3 .557.557 0 00-.41.166.593.593 0 00-.18.432v1.269a.5.5 0 00.486.5z\"/><path d=\"M20.658 7.5a.5.5 0 00-.726-.1l-1.046.845a.5.5 0 00-.1.669 8.183 8.183 0 11-7.259-3.6.5.5 0 00.472-.5V3.556a.6.6 0 00-.187-.42.581.581 0 00-.432-.153A10.507 10.507 0 1020.658 7.5z\"/><path d=\"M12 7.5a.5.5 0 00-.541-.5 6.5 6.5 0 105.982 2.92.5.5 0 00-.726-.12l-3.965 3.1a.465.465 0 01-.75-.366zm-3.5 7.98a1 1 0 111-1 1 1 0 01-1 1.002zm1-4.5a1 1 0 111-1 1 1 0 01-1 1.002zm6.5 2.5a1 1 0 11-1 1 1 1 0 011-.998zm-3.5 3a1 1 0 11-1 1 1 1 0 011-.998z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});