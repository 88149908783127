define("ember-svg-jar/inlined/road-sign-no-entry-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-no-entry-1-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM1.959 12a10.035 10.035 0 0114.568-8.959.246.246 0 01.063.392L3.433 16.59a.246.246 0 01-.392-.063A9.972 9.972 0 011.959 12zm20.082 0a10.035 10.035 0 01-14.568 8.959.245.245 0 01-.062-.392L20.567 7.411a.245.245 0 01.392.062A9.972 9.972 0 0122.041 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});