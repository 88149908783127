define("ember-svg-jar/inlined/messages-bubble-square-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-search</title><path d=\"M16.5 9.5a6.962 6.962 0 014.586 1.72.25.25 0 00.414-.189V1.5A1.5 1.5 0 0020 0H1.5A1.5 1.5 0 000 1.5v12.053A1.459 1.459 0 001.5 15H3v3.5a.5.5 0 00.832.374L8.19 15h1.278a.248.248 0 00.242-.19A7.011 7.011 0 0116.5 9.5z\"/><path d=\"M23.707 22.293l-2.534-2.534a.25.25 0 01-.034-.311 5.509 5.509 0 10-1.691 1.691.25.25 0 01.311.034l2.534 2.534a1 1 0 001.414-1.414zM16.5 13a3.5 3.5 0 11-3.5 3.5 3.5 3.5 0 013.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});