define("ember-svg-jar/inlined/trees", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trees</title><path d=\"M5.929 7.726l-.851-1.419a.25.25 0 01.122-.362.459.459 0 00.234-.216.5.5 0 00-.006-.5l-.85-1.422a.25.25 0 01.122-.362.459.459 0 00.234-.216.5.5 0 00-.006-.5l-1.5-2.5a.52.52 0 00-.858 0l-1.5 2.5a.5.5 0 00-.006.5.447.447 0 00.234.212.248.248 0 01.147.153.244.244 0 01-.022.21L.571 5.226a.5.5 0 00-.006.5.447.447 0 00.235.215.251.251 0 01.124.359L.071 7.726a.5.5 0 00.429.757h1a.5.5 0 01.5.5v1.25a1 1 0 002 0v-1.25a.5.5 0 01.5-.5h1a.5.5 0 00.429-.757zM23.929 7.726L23.076 6.3a.247.247 0 01-.022-.21.25.25 0 01.147-.153.447.447 0 00.234-.212.5.5 0 00-.006-.5L22.576 3.8a.247.247 0 01-.022-.21.25.25 0 01.147-.153.447.447 0 00.234-.212.5.5 0 00-.006-.5l-1.5-2.5a.52.52 0 00-.858 0l-1.5 2.5a.5.5 0 00-.006.5.459.459 0 00.234.216.25.25 0 01.123.362l-.851 1.419a.5.5 0 00-.006.5.459.459 0 00.234.216.25.25 0 01.123.362l-.851 1.419a.5.5 0 00.429.757h1a.5.5 0 01.5.5v1.25a1 1 0 002 0V8.983a.5.5 0 01.5-.5h1a.5.5 0 00.429-.757zM15.821 15.015a.327.327 0 01.268-.515 1 1 0 00.844-1.537l-2.389-3.754a.251.251 0 01.083-.349 1 1 0 00.347-1.375l-2.545-4.243a.52.52 0 00-.858 0L9.026 7.485a1 1 0 00.347 1.375.251.251 0 01.083.349l-2.39 3.755a1 1 0 00.845 1.536.327.327 0 01.268.515l-2.038 2.912A1 1 0 006.96 19.5h3.54a.5.5 0 01.5.5v3a1 1 0 002 0v-3a.5.5 0 01.5-.5h3.539a1 1 0 00.82-1.573z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});