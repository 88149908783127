define("ember-svg-jar/inlined/night-moon-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>night-moon-new</title><path d=\"M19.566 23.34a1 1 0 000-1.9 9.943 9.943 0 010-18.878 1 1 0 000-1.9A11.57 11.57 0 008.878 2.184 12.35 12.35 0 003.75 12a12.311 12.311 0 005.114 9.79A11.438 11.438 0 0015.613 24a12.484 12.484 0 003.953-.66zm-9.532-3.173A10.278 10.278 0 015.75 12a10.315 10.315 0 014.3-8.2A9.38 9.38 0 0115.591 2a.179.179 0 01.105.324 11.937 11.937 0 00-.185 19.212.25.25 0 01-.157.45 9.465 9.465 0 01-5.32-1.819z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});