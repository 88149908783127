define("ember-svg-jar/inlined/accessories-ribbon-tie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accessories-ribbon-tie</title><path d=\"M8.5 13.25a.5.5 0 00-.5-.5H6.5a.75.75 0 010-1.5H8a.5.5 0 00.5-.5V8.739a.5.5 0 00-.252-.434L3.475 5.578a1.5 1.5 0 00-1.351.205C.653 6.836 0 9.665 0 12s.66 5.168 2.122 6.216a1.432 1.432 0 00.85.273 1.751 1.751 0 00.592-.107L8.249 15.7a.5.5 0 00.251-.434z\"/><rect x=\"10\" y=\"8\" width=\"4\" height=\"8\" rx=\"1\" ry=\"1\"/><path d=\"M21.878 5.784a1.523 1.523 0 00-1.442-.166L15.751 8.3a.5.5 0 00-.251.434v2.016a.5.5 0 00.5.5h1.5a.75.75 0 010 1.5H16a.5.5 0 00-.5.5v2.011a.5.5 0 00.252.434l4.773 2.727a1.493 1.493 0 001.351-.205C23.34 17.168 24 14.339 24 12s-.653-5.164-2.122-6.216z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});