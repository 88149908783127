define("ember-svg-jar/inlined/speakers-stand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>speakers-stand</title><path d=\"M11 2.5A2.5 2.5 0 008.5 0h-6A2.5 2.5 0 000 2.5v14A2.5 2.5 0 002.5 19h6a2.5 2.5 0 002.5-2.5zM5.5 9A3.5 3.5 0 112 12.5 3.5 3.5 0 015.5 9zm-2-4.5a2 2 0 112 2 2 2 0 01-2-2zM8.5 22H6.75a.25.25 0 01-.25-.25v-1.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25v1.5a.25.25 0 01-.25.25H2.5a1 1 0 000 2h6a1 1 0 000-2zM21.5 0h-6A2.5 2.5 0 0013 2.5v14a2.5 2.5 0 002.5 2.5h6a2.5 2.5 0 002.5-2.5v-14A2.5 2.5 0 0021.5 0zm-3 9a3.5 3.5 0 11-3.5 3.5A3.5 3.5 0 0118.5 9zm-2-4.5a2 2 0 112 2 2 2 0 01-2-2zM21.5 22h-1.75a.25.25 0 01-.25-.25v-1.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25v1.5a.25.25 0 01-.25.25H15.5a1 1 0 000 2h6a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});