define("ember-svg-jar/inlined/baseball-score", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baseball-score</title><path d=\"M12 10.5C3.166 10.5.67 14.372.116 15.558a1.236 1.236 0 00.723 1.692l4.7 1.6A.683.683 0 016 19.5c0 2.523 2.636 4.5 6 4.5s6-1.977 6-4.5a.685.685 0 01.464-.648l4.69-1.6a1.234 1.234 0 00.73-1.693C23.33 14.372 20.834 10.5 12 10.5zm3.834 9.7C15.337 21.226 13.782 22 12 22s-3.337-.774-3.834-1.8a.25.25 0 01.3-.345l3.206 1.093a.993.993 0 00.646 0l3.206-1.093a.25.25 0 01.3.345zM9.4 17.615A5.529 5.529 0 0112 17a5.529 5.529 0 012.6.615.25.25 0 01-.039.457l-2.233.761a.993.993 0 01-.646 0l-2.233-.761a.25.25 0 01-.039-.457zm11.794-2.065a.5.5 0 01-.328.369l-3.376 1.152a.5.5 0 01-.532-.137A6.694 6.694 0 0012 15a6.694 6.694 0 00-4.958 1.934.5.5 0 01-.532.137l-3.376-1.152a.5.5 0 01-.178-.84C4.206 13.926 6.8 12.5 12 12.5s7.793 1.426 9.044 2.579a.5.5 0 01.15.471zM1 9h22a1 1 0 000-2h-6a.5.5 0 01-.5-.5v-1A.5.5 0 0117 5a.5.5 0 00.5-.5v-4A.5.5 0 0017 0H7a.5.5 0 00-.5.5v4A.5.5 0 007 5a.5.5 0 01.5.5v1A.5.5 0 017 7H1a1 1 0 000 2zm8.5-3.5A.5.5 0 0110 5h4a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});