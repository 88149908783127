define("ember-svg-jar/inlined/move-down-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>move-down-1</title><path d=\"M12.457.705a1.25 1.25 0 00-1.25 1.25v10.163a.25.25 0 01-.25.25H8.8a1 1 0 00-.7 1.707l3.653 3.652a1 1 0 001.414 0l3.652-3.652a1 1 0 00-.707-1.707h-2.155a.249.249 0 01-.25-.25V1.955a1.25 1.25 0 00-1.25-1.25zM23.75 22.043a1.25 1.25 0 00-1.25-1.25h-21a1.25 1.25 0 000 2.5h21a1.25 1.25 0 001.25-1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});