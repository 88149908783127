define("ember-svg-jar/inlined/arrow-button-left-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-left-2</title><path d=\"M21.016.531A2 2 0 0019 .563L2.929 10.288a2 2 0 000 3.422L19 23.436a2 2 0 003.035-1.711V2.274A2 2 0 0021.016.531z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});