define("ember-svg-jar/inlined/monitor-shopping-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-shopping-cart</title><path d=\"M24 2.59A2.548 2.548 0 0021.5 0h-19A2.548 2.548 0 000 2.59V16.5a3 3 0 003 3h7.25a.25.25 0 01.25.25v2a.25.25 0 01-.25.25H5a1 1 0 000 2h14a1 1 0 000-2h-6.25a.25.25 0 01-.25-.25v-2a.25.25 0 01.25-.25H21a3 3 0 003-3zM2.5 15a.5.5 0 01-.5-.5V2.59A.551.551 0 012.5 2h19a.551.551 0 01.5.589V14.5a.5.5 0 01-.5.5z\"/><path d=\"M8.973 9.912a1.248 1.248 0 001.148.756H13.7a1.242 1.242 0 001.165-.8l1.88-4.668a.75.75 0 10-1.391-.561L15 5.511a.248.248 0 01-.231.157H9.05A1.25 1.25 0 007.9 7.41z\"/><circle cx=\"10.55\" cy=\"12.168\" r=\"1\"/><path d=\"M13.3 11.168a1 1 0 101 1 1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});