define("ember-svg-jar/inlined/smiley-fever-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-fever-alternate</title><path d=\"M12 15a4.491 4.491 0 013.773 2.046 1 1 0 001.792-.846.251.251 0 01.1-.283l5.311-3.52a2.25 2.25 0 00-2.452-3.774l-6.953 4.491a.248.248 0 01-.188.034A6.548 6.548 0 0012 13c-.205 0-.409.01-.612.028a6.486 6.486 0 00-4.836 2.926 1 1 0 101.676 1.092 4.486 4.486 0 013.04-1.986A4.566 4.566 0 0112 15zm9.34-5.118a.75.75 0 11.814 1.261l-.827.548a.25.25 0 01-.347-.072l-.552-.849a.244.244 0 01-.035-.188.25.25 0 01.109-.158zm-2.519 1.627a.25.25 0 01.346.074l.564.868a.25.25 0 01-.071.345l-2.943 1.95a.25.25 0 01-.308-.024 6.412 6.412 0 00-.868-.675.247.247 0 01-.113-.21.25.25 0 01.114-.21z\"/><path d=\"M21.227 14.864c-.1.243-.213.481-.332.712A10 10 0 012 11c0-.32.015-.637.045-.949a1 1 0 00-1.992-.187C.019 10.238 0 10.617 0 11a12 12 0 0023.071 4.638 1 1 0 00-.4-1.24 1.084 1.084 0 00-.136-.069 1 1 0 00-1.308.535z\"/><circle cx=\"8\" cy=\"7.5\" r=\"1.75\"/><circle cx=\"16\" cy=\"7.5\" r=\"1.75\"/><path d=\"M3.053 5.4a1 1 0 001.342-.448C4.977 3.782 6.988 3 8.5 3a1 1 0 000-2C6.382 1 3.6 2.07 2.606 4.053A1 1 0 003.053 5.4zM15.5 3c1.513 0 3.524.782 4.106 1.947a1 1 0 101.794-.894C20.4 2.07 17.619 1 15.5 1a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});