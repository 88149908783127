define("ember-svg-jar/inlined/phone-retro-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-retro-1</title><circle cx=\"12\" cy=\"16.501\" r=\"2.25\"/><path d=\"M15 10.642V9a1 1 0 10-2 0v1.5h-2V9a1 1 0 10-2 0v1.641A8.508 8.508 0 002 19v2a1.5 1.5 0 001.5 1.5h17A1.5 1.5 0 0022 21v-2a8.508 8.508 0 00-7-8.358zm-3 2.109A3.75 3.75 0 118.25 16.5 3.755 3.755 0 0112 12.751z\"/><path d=\"M21.1 2.42a45.417 45.417 0 00-18.2 0A3.7 3.7 0 000 5.91v2a2.5 2.5 0 002.5 2.5h3A2.5 2.5 0 008 7.91V7.1a.253.253 0 01.232-.252 45.366 45.366 0 017.536 0A.253.253 0 0116 7.1v.81a2.5 2.5 0 002.5 2.5h3a2.5 2.5 0 002.5-2.5v-2a3.7 3.7 0 00-2.9-3.49z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});