define("ember-svg-jar/inlined/video-game-logo-steering-wheel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-logo-steering-wheel</title><path d=\"M7.667 3.623a.5.5 0 00.62.176 9.027 9.027 0 017.426 0 .5.5 0 00.62-.176l1.131-1.678a.5.5 0 00-.2-.729 12.019 12.019 0 00-10.536 0 .5.5 0 00-.263.32.5.5 0 00.067.409z\"/><path d=\"M20.077 3.126a.5.5 0 00-.721.049l-1.283 1.537a.5.5 0 00.044.687 9 9 0 011.715 11.037.5.5 0 01-.789.107L15.5 13l-1.563-2.813a.5.5 0 00-.937.243v4.363a.5.5 0 00.146.353l3.71 3.71a.5.5 0 01-.088.778 9 9 0 01-9.536 0 .5.5 0 01-.088-.778l3.71-3.71a.5.5 0 00.146-.353V10.43a.5.5 0 00-.937-.243L8.5 13l-3.543 3.543a.5.5 0 01-.789-.107A9 9 0 015.883 5.4a.5.5 0 00.044-.687L4.644 3.175a.5.5 0 00-.721-.049 12 12 0 1016.154 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});