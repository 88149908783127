define("ember-svg-jar/inlined/halloween-pumpkin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>halloween-pumpkin</title><path d=\"M11.546 5.522a4.86 4.86 0 01.908 0 .5.5 0 00.546-.5V1.5a1 1 0 00-2 0v3.523a.5.5 0 00.163.369.5.5 0 00.383.13zM21.653 10.019a9.242 9.242 0 00-4.488-2.383.25.25 0 00-.25.4 7.236 7.236 0 01.792 1.24.5.5 0 01-.894.45 5.642 5.642 0 00-3.654-3.073 5.522 5.522 0 00-2.318 0 5.64 5.64 0 00-3.653 3.072.5.5 0 01-.895-.45 7.236 7.236 0 01.792-1.24.25.25 0 00-.25-.4 9.242 9.242 0 00-4.488 2.383A7.581 7.581 0 000 15.444C0 19.886 4.037 23.5 9 23.5h6c4.963 0 9-3.614 9-8.056a7.581 7.581 0 00-2.347-5.425zM5.5 13.464a2.215 2.215 0 014.429 0 .5.5 0 01-.5.5H6a.5.5 0 01-.5-.5zM12 21c-2.559 0-4.641-1.659-4.641-3.7a2.311 2.311 0 01.032-.386.5.5 0 01.815-.3l.813.686a.5.5 0 00.68-.033l.322-.329a1.018 1.018 0 011.348.006l.294.284a.5.5 0 00.709-.013l.266-.278a1.019 1.019 0 011.348.007l.349.338a.5.5 0 00.678.017l.781-.685a.5.5 0 01.815.3 2.311 2.311 0 01.032.386c0 2.041-2.082 3.7-4.641 3.7zm6-7.036h-3.429a.5.5 0 01-.5-.5 2.215 2.215 0 014.429 0 .5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});