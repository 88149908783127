define("ember-svg-jar/inlined/microphone-podcast-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>microphone-podcast-2</title><path d=\"M9.5 9a.75.75 0 010 1.5H6.75a.25.25 0 00-.25.25v.75a5.5 5.5 0 0011 0v-.75a.25.25 0 00-.25-.25H14.5a.75.75 0 010-1.5h2.75a.25.25 0 00.25-.25v-1a.25.25 0 00-.25-.25H14.5a.75.75 0 010-1.5h2.75a.25.25 0 00.25-.25V5.5A5.5 5.5 0 0014.619.665a.25.25 0 00-.369.22V3a.75.75 0 01-1.5 0V.274a.251.251 0 00-.226-.249 5.33 5.33 0 00-1.048 0 .25.25 0 00-.226.249V3a.75.75 0 01-1.5 0V.885a.25.25 0 00-.369-.22A5.5 5.5 0 006.5 5.5v.25a.25.25 0 00.25.25H9.5a.75.75 0 010 1.5H6.75a.25.25 0 00-.25.25v1a.25.25 0 00.25.25z\"/><path d=\"M20 8.5a1 1 0 00-1 1v2a7 7 0 01-14 0v-2a1 1 0 00-2 0v2a9.014 9.014 0 007.784 8.918.249.249 0 01.216.247V23a1 1 0 002 0v-2.335a.248.248 0 01.216-.247A9.014 9.014 0 0021 11.5v-2a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});