define("ember-svg-jar/inlined/landmark-pyramid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-pyramid</title><path d=\"M23.611 21.49l-8.87-9.76a1.512 1.512 0 00-2.22 0l-8.87 9.76A1.5 1.5 0 004.761 24H22.5a1.5 1.5 0 001.11-2.51zm-8.524.51H7.021a.5.5 0 01-.37-.836l5.976-6.572a.5.5 0 01.846.185l2.09 6.571a.5.5 0 01-.476.652z\"/><circle cx=\"7.751\" cy=\"7.75\" r=\"3.5\"/><path d=\"M7.751 3.5a1 1 0 001-1V1a1 1 0 10-2 0v1.5a1 1 0 001 1zM3.331 4.745a1 1 0 101.414-1.414L3.685 2.27a1 1 0 00-1.414 1.414zM3.5 7.75a1 1 0 00-1-1H1a1 1 0 100 2h1.5a1 1 0 001-1zM4.745 12.169a1 1 0 10-1.414-1.414l-1.06 1.061a1 1 0 001.414 1.414zM6.751 13v1.5a1 1 0 002 0V13a1 1 0 10-2 0zM12.17 4.745l1.061-1.061a1 1 0 00-1.415-1.414l-1.06 1.061a1 1 0 001.414 1.414zM12 7.75a1 1 0 001 1h1.5a1 1 0 000-2H13a1 1 0 00-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});