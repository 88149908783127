define("ember-svg-jar/inlined/chair-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chair-1</title><path d=\"M18 18.5h-1a.5.5 0 00-.5.5v4a1 1 0 002 0v-4a.5.5 0 00-.5-.5zM7 18.5H6a.5.5 0 00-.5.5v4a1 1 0 002 0v-4a.5.5 0 00-.5-.5zM21.5 15a1 1 0 00-1-1H19a.5.5 0 01-.5-.5v-4A.5.5 0 0119 9a1 1 0 001-1V4a1 1 0 00-1-1 .5.5 0 01-.5-.5V1a1 1 0 00-2 0v1.5a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v1.5A.5.5 0 015 3a1 1 0 00-1 1v4a1 1 0 001 1 .5.5 0 01.5.5v4a.5.5 0 01-.5.5H3.5a1 1 0 00-1 1v1a1 1 0 001 1h17a1 1 0 001-1zM16 9a.5.5 0 01.5.5v4a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5v-4A.5.5 0 018 9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});