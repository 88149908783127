define("ember-svg-jar/inlined/messages-bubble-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-sync</title><path d=\"M17.5 9.5a7.831 7.831 0 013.363.763c.214.1.422.211.626.33a.249.249 0 00.371-.168A7.4 7.4 0 0022 9c0-4.962-4.935-9-11-9S0 4.038 0 9a8.08 8.08 0 002.657 5.854L1.03 19.329a.5.5 0 00.662.632l5.832-2.429a13.111 13.111 0 001.693.34.249.249 0 00.2-.06.258.258 0 00.085-.191 7.144 7.144 0 01.035-.716A8.057 8.057 0 0117.5 9.5zM22.769 18.6a1 1 0 00-1.27.622 4.093 4.093 0 01-7.074 1.238l1.886-1.885a.5.5 0 00-.354-.853H11.5a.5.5 0 00-.5.5v4.458a.5.5 0 00.854.353l1.155-1.155a6.088 6.088 0 0010.383-2.007 1.015 1.015 0 00-.623-1.271z\"/><path d=\"M24 12.234a.5.5 0 00-.85-.355l-1.21 1.21a6.1 6.1 0 00-10.369 2.038 1.045 1.045 0 00.623 1.27 1 1 0 001.27-.622 4.094 4.094 0 017.054-1.264l-1.825 1.825a.5.5 0 00.354.853H23.5a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});