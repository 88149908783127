define("ember-svg-jar/inlined/single-woman-actions-graduate.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-graduate</title><path d=\"M10.712 15.662l4.946-2.12a.25.25 0 00.015-.452 43.329 43.329 0 00-4.2-1.667.778.778 0 01-.513-.729v-.468a.251.251 0 01.25-.25A6.374 6.374 0 0015 9.081a.387.387 0 00.084-.592c-.494-.538-.836-1.186-.981-3.318C13.838 1.346 11.236 0 9 0S4.161 1.346 3.9 5.171c-.146 2.132-.487 2.78-.981 3.318A.387.387 0 003 9.081a6.355 6.355 0 003.782.9.248.248 0 01.253.25v.462a.776.776 0 01-.512.729c-2.9 1.065-5.212 1.826-5.764 2.922A7.828 7.828 0 000 17.076a.386.386 0 00.1.3.391.391 0 00.29.126h8.874a.249.249 0 00.248-.222 2 2 0 011.2-1.618zM11 20.965a.75.75 0 101.5 0v-2.154a.249.249 0 01.333-.235l4.5 1.589a.506.506 0 00.332 0l6-2.119a.5.5 0 00.019-.935l-6-2.383a.5.5 0 00-.37 0l-6 2.383a.5.5 0 00-.315.472v3.382z\"/><path d=\"M21.1 22.917a.5.5 0 00.334-.471V20.3a.5.5 0 00-.666-.471l-3.18 1.122a.249.249 0 01-.166 0l-3.18-1.122a.5.5 0 00-.666.471v2.146a.5.5 0 00.334.471l2.572.908a3.072 3.072 0 002.044 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});