define("ember-svg-jar/inlined/file-code-star-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-star-1</title><path d=\"M12.607 17.139a1.264 1.264 0 00.3-1.378A1.234 1.234 0 0011.738 15h-2.5a.25.25 0 01-.229-.15l-1.345-3.118-.013-.032a1.293 1.293 0 00-2.317.028l-1.346 3.121a.251.251 0 01-.23.15h-2.5a1.23 1.23 0 00-1.166.76 1.272 1.272 0 00.32 1.4L2.6 19.111a.25.25 0 01.063.286l-1.219 2.814a1.253 1.253 0 00.314 1.451 1.359 1.359 0 001.506.167l3.112-1.753a.249.249 0 01.245 0l3.113 1.753a1.277 1.277 0 001.506-.167 1.254 1.254 0 00.315-1.45L10.331 19.4a.252.252 0 01.063-.287zM14.714 6.451a.75.75 0 00-1.078-1.043l-.018.019-2.666 2.857a.749.749 0 000 1.023l2.666 2.857a.751.751 0 001.061.036l.018-.017a.751.751 0 00.018-1.043l-2.029-2.174a.249.249 0 010-.341zM16.286 11.14a.75.75 0 001.078 1.043l.017-.019 2.667-2.857a.749.749 0 000-1.023l-2.666-2.857a.751.751 0 00-1.061-.036.731.731 0 00-.036 1.06l2.029 2.174a.249.249 0 010 .341z\"/><path d=\"M23.414 3L21 .587A2 2 0 0019.586 0H8a2 2 0 00-2 2v6.793a.248.248 0 00.232.249 8.446 8.446 0 011.452.211.248.248 0 00.316-.24V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.121a.5.5 0 01.146.355V18a.5.5 0 01-.5.5h-7.335a.231.231 0 00-.163.06l-1.175 1.06a.279.279 0 00-.086.3l.172.4a.308.308 0 00.275.187H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});