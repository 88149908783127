define("ember-svg-jar/inlined/image-file-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-light</title><path d=\"M21.414 4.914L17.086.586A2 2 0 0015.672 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.328a2 2 0 00-.586-1.414zM8.916 14.25h-3.73a.25.25 0 01-.22-.368l3.56-6.611a.251.251 0 01.421-.033l2.466 3.289a.244.244 0 01-.062.35 5.43 5.43 0 00-2.2 3.184.242.242 0 01-.235.189zm5.5 5a3.917 3.917 0 113.916-3.917 3.921 3.921 0 01-3.916 3.917z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});