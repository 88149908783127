define("ember-svg-jar/inlined/single-neutral-actions-check-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-check-2</title><path d=\"M11.845 11.842a7.249 7.249 0 01.792-.679.25.25 0 00-.061-.434c-.342-.129-.7-.262-1.078-.4l-.628-.23a.6.6 0 01-.138-.311 2.573 2.573 0 01.058-1.3 5.618 5.618 0 001.466-4.314C12.256 1.756 10.677 0 8.5 0S4.744 1.756 4.744 4.174A5.605 5.605 0 006.2 8.471a2.453 2.453 0 01.073 1.316.615.615 0 01-.135.309l-.631.232c-2.447.9-4.215 1.55-4.754 2.626A9.173 9.173 0 000 16.5a.5.5 0 00.5.5h8.8a.249.249 0 00.248-.227 7.955 7.955 0 012.297-4.931z\"/><path d=\"M23.049 18.19a1 1 0 00-1.253.656A4.5 4.5 0 1117.5 13a1 1 0 000-2 6.5 6.5 0 106.2 8.443 1 1 0 00-.651-1.253z\"/><path d=\"M23.707 12.668a1 1 0 00-1.414 0l-4.439 4.439a.5.5 0 01-.708 0l-1-1a1 1 0 00-1.416 1.413l1.577 1.58a1.69 1.69 0 002.386 0l5.014-5.014a1 1 0 000-1.418z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});