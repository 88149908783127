define("ember-svg-jar/inlined/vegetables-pumpkin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vegetables-pumpkin</title><path d=\"M5.721 13.872A11.908 11.908 0 017.6 6.792.5.5 0 007.256 6a3.952 3.952 0 00-.972 0 5.04 5.04 0 00-3.811 2.573 9.472 9.472 0 00-.862 5.876C2.2 19.1 4.99 24 8.161 24a.308.308 0 00.244-.5c-1.831-2.376-2.684-6.434-2.684-9.628zM21.527 8.573A5.04 5.04 0 0017.716 6a3.952 3.952 0 00-.972 0 .5.5 0 00-.343.79 11.914 11.914 0 011.878 7.081c0 3.194-.853 7.252-2.684 9.632a.308.308 0 00.244.5c3.171 0 5.964-4.9 6.55-9.551a9.472 9.472 0 00-.862-5.879z\"/><path d=\"M13.31 6.143a.5.5 0 01-.31-.454 3.752 3.752 0 011.086-2.86l1.122-1.122A1 1 0 0013.793.293l-1.122 1.122A5.916 5.916 0 0011 5.679a.5.5 0 01-.312.464c-1.875.764-3.466 3.236-3.466 7.729C7.221 18.662 9.184 24 12 24s4.779-5.338 4.779-10.128c0-4.495-1.592-6.967-3.469-7.729z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});