define("ember-svg-jar/inlined/people-man-beard-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-beard-3</title><path d=\"M17.6 4a.25.25 0 00.224-.36 6.473 6.473 0 00-11.64 0A.25.25 0 006.4 4zM11.25 19.182a.251.251 0 00-.163-.234 2.844 2.844 0 01-1.409-1.157.25.25 0 01.316-.36l.1.045a4.549 4.549 0 003.816 0l.1-.046a.251.251 0 01.317.361 2.851 2.851 0 01-1.409 1.156.251.251 0 00-.163.234v4.569A.25.25 0 0013 24h10a.5.5 0 00.5-.5v-2.106a2.464 2.464 0 00-.967-1.977 13 13 0 00-3.848-1.942.25.25 0 01-.17-.206l-.034-.26a.749.749 0 00-.576-.635c-.219-.05-.436-.1-.653-.139a.25.25 0 01-.113-.436 4.524 4.524 0 001.6-3.728l.009-2.568V7.928a.25.25 0 01.107-.205A1.495 1.495 0 0018 5H6a1.5 1.5 0 00-1.5 1.5 1.528 1.528 0 00.643 1.223.25.25 0 01.107.205V9.5l.011 2.511a4.577 4.577 0 001.6 3.785.25.25 0 01-.113.435c-.217.044-.434.09-.653.14a.749.749 0 00-.576.635l-.034.26a.249.249 0 01-.17.206 13.029 13.029 0 00-3.849 1.942 2.466 2.466 0 00-.966 1.98V23.5a.5.5 0 00.5.5h10a.25.25 0 00.25-.25zM6.915 17.9a.252.252 0 01.2-.213 21 21 0 01.6-.109.249.249 0 01.276.161 4.486 4.486 0 001.023 1.617.249.249 0 01-.1.413 9.123 9.123 0 00-1.947.9.252.252 0 01-.267 0 .248.248 0 01-.112-.242zM10.35 14a.75.75 0 01.727-.566h1.846a.75.75 0 01.359 1.408 2.349 2.349 0 01-.432.18 2.874 2.874 0 01-.845.123H12a2.95 2.95 0 01-.847-.123 2.42 2.42 0 01-.431-.179.75.75 0 01-.372-.843zm7.06 6.421a.248.248 0 01-.112.242.252.252 0 01-.267 0 9.123 9.123 0 00-1.947-.9.249.249 0 01-.1-.413 4.486 4.486 0 001.023-1.617.25.25 0 01.276-.161c.2.033.4.07.6.109a.252.252 0 01.2.213zM17 8a.25.25 0 01.25.25v.85a.251.251 0 01-.07.174l-2.1 2.181-.018.021a1.148 1.148 0 01-1.276.391 5.686 5.686 0 00-3.578 0 1.2 1.2 0 01-1.333-.467l-.018-.02L6.82 9.27a.251.251 0 01-.07-.17v-.85A.25.25 0 017 8z\"/><circle cx=\"14\" cy=\"9.5\" r=\"1\"/><circle cx=\"10\" cy=\"9.5\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});