define("ember-svg-jar/inlined/wild-pig-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wild-pig-body</title><path d=\"M23.877 14.746A1.98 1.98 0 0023 13.723v-2.1a.505.505 0 00-.309-.462 4.979 4.979 0 01-2.178-2.341 6.06 6.06 0 00-1.408-1.9l.454-2.716a.5.5 0 00-.77-.5c-.029.02-2.9 1.916-4.723 1.916h-7a5.974 5.974 0 00-4.114 1.649c-.439-.238-1.159-.909-.9-2.752a1 1 0 10-1.98-.283c-.362 2.533.637 3.95 1.667 4.634a5.957 5.957 0 00-.677 2.757c0 3.33 1.233 4.423 2.723 5.416 1.256.837 1.277 3.063 1.277 3.085a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1a1.5 1.5 0 011.5-1.5h3a1.5 1.5 0 011.5 1.5v1a.5.5 0 00.5.5h2a.5.5 0 00.5-.5c0-.242 0-.875.006-1.591a6.48 6.48 0 001.4.152 7.637 7.637 0 004.808-1.712 2 2 0 00.601-2.229zm-5.561-3.453a1 1 0 111-1 1 1 0 01-1 1zm-2.582 4.075c-.5-1.7.551-1.907 1.1-1.907.586 0 2.8 1.974 5.164 1.974a5.673 5.673 0 01-3.531 1.251 2.91 2.91 0 01-2.733-1.318z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});