define("ember-svg-jar/inlined/light-mode-flash-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-mode-flash-off</title><path d=\"M24 12a12 12 0 10-12 12 12.013 12.013 0 0012-12zm-4.084 6.1a.25.25 0 01-.375.024l-3.741-3.74a.252.252 0 00-.354 0l-1.06 1.061a.25.25 0 000 .353l3.743 3.743a.25.25 0 01-.024.375A9.994 9.994 0 014.084 5.9a.25.25 0 01.375-.024l2.5 2.5a.25.25 0 00.4-.065l.707-1.414a.25.25 0 00-.047-.288l-2.146-2.15a.25.25 0 01.027-.375A9.994 9.994 0 0119.916 18.1z\"/><path d=\"M16.4 9.5h-2.5a.25.25 0 01-.208-.389l1.964-2.946A.75.75 0 0015.033 5h-3.724a1 1 0 00-.895.552l-3.182 6.365A.75.75 0 007.9 13h1.56a.25.25 0 01.237.329l-1.672 5.013a.5.5 0 00.829.512l8.074-8.074A.751.751 0 0016.4 9.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});