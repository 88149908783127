define("ember-svg-jar/inlined/antique-catapult", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>antique-catapult</title><path d=\"M20 16.75h-2.75a.25.25 0 01-.25-.25v-5.75a1 1 0 00-2 0v.982a.249.249 0 01-.073.177l-1.582 1.582a.251.251 0 01-.35 0L4.938 5.742a1.256 1.256 0 00-.893-.349 1.24 1.24 0 00-.874.384 3.747 3.747 0 002.705 6.344 3.764 3.764 0 001.957-.548.248.248 0 01.3.033l3.437 3.3a.252.252 0 010 .358l-1.417 1.417a.249.249 0 01-.177.073H1a1 1 0 00-1 1v2a1 1 0 001 1h.723a.5.5 0 00.471-.333 3.238 3.238 0 016.112 0 .5.5 0 00.471.333h3.446a.5.5 0 00.471-.333 3.238 3.238 0 016.112 0 .5.5 0 00.471.333H20a1 1 0 001-1v-2a1 1 0 00-1-1.004zm-5-.25a.25.25 0 01-.25.25h-1.232a.25.25 0 01-.177-.427l1.232-1.232a.25.25 0 01.427.177z\"/><circle cx=\"5.25\" cy=\"21.5\" r=\"2.25\"/><circle cx=\"15.75\" cy=\"21.5\" r=\"2.25\"/><circle cx=\"21.5\" cy=\"2.75\" r=\"2.5\"/><circle cx=\"9.5\" cy=\"5.75\" r=\"1\"/><circle cx=\"12.5\" cy=\"3.75\" r=\"1\"/><circle cx=\"16\" cy=\"2.75\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});