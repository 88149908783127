define("ember-svg-jar/inlined/design-tool-pen-station", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-tool-pen-station</title><path d=\"M21.459 16.5a4 4 0 00-4-4h-.5V12a1 1 0 00-1-1h-12a1 1 0 00-1 1v9.5a2.5 2.5 0 002.5 2.5h9a2.5 2.5 0 002.5-2.5v-1h.5a4 4 0 004-4zm-4 2h-.25a.249.249 0 01-.25-.25v-3.5a.249.249 0 01.25-.25h.25a2 2 0 110 4zM5.649 2.864a.249.249 0 00.017-.429L3.3.905a.5.5 0 00-.75.511l.422 2.53a.25.25 0 00.366.178zM3.646 5.094a.25.25 0 00-.1.334l2.22 4.3a.5.5 0 00.445.27h3a.5.5 0 00.438-.741l-3.026-5.5a.252.252 0 00-.339-.1zM13.539 7.293h-.007a3.525 3.525 0 01-.636-.348.252.252 0 00-.361.084l-1.242 2.228a.5.5 0 00.437.743h1.832a.5.5 0 00.468-.323l.67-1.784a.251.251 0 00-.2-.337 3.526 3.526 0 01-.68-.156 4.233 4.233 0 01-.281-.107zM17.017 2.815a3.679 3.679 0 00-2.81-2.8.5.5 0 00-.536.761c.529.805.21 1.282-.353 1.994a3.621 3.621 0 00-.542.819 2.037 2.037 0 001.151 2.783 2.044 2.044 0 002.245-.257 3.363 3.363 0 00.845-3.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});