define("ember-svg-jar/inlined/messages-people-man-bubble-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-man-bubble-1</title><circle cx=\"12.87\" cy=\"5.75\" r=\"1\"/><circle cx=\"16.12\" cy=\"5.75\" r=\"1\"/><circle cx=\"19.37\" cy=\"5.75\" r=\"1\"/><path d=\"M17.894 0a5.434 5.434 0 00-3.606 1.275 5.642 5.642 0 00-6.4 2.13 1 1 0 001.65 1.129 3.633 3.633 0 014.529-1.2 1 1 0 001.133-.188A3.56 3.56 0 0117.894 2 3.91 3.91 0 0122 5.976a3.531 3.531 0 01-.945 2.473 4.129 4.129 0 01-3.061 1.116 1 1 0 00-.813.419A2.285 2.285 0 0115.494 11a1.981 1.981 0 01-1.067-.3 1 1 0 10-1.086 1.68 3.953 3.953 0 002.153.62 4.062 4.062 0 002.991-1.449 5.9 5.9 0 004.009-1.714A5.513 5.513 0 0024 5.976 5.913 5.913 0 0017.894 0z\"/><circle cx=\"15.754\" cy=\"15.75\" r=\"1.5\"/><path d=\"M7 17.125a4.75 4.75 0 10-4.75-4.75A4.756 4.756 0 007 17.125zm-2.451-5.971a7.043 7.043 0 003.911 1.221 6.6 6.6 0 001.277-.125c0 .042.013.082.013.125a2.75 2.75 0 01-5.5 0 2.713 2.713 0 01.299-1.221zM7.206 18a7.544 7.544 0 00-7.185 5.357A.5.5 0 00.5 24h13.412a.5.5 0 00.48-.643A7.544 7.544 0 007.206 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});