define("ember-svg-jar/inlined/pin-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin-4</title><path d=\"M12 18.48a1.44 1.44 0 001.44-1.44v-4.319a.25.25 0 01.18-.24 5.76 5.76 0 10-3.24 0 .25.25 0 01.18.24v4.319A1.44 1.44 0 0012 18.48zm-.96-12.96A1.44 1.44 0 119.6 4.08a1.44 1.44 0 011.44 1.44zM1.44 22.8h21.12a1.44 1.44 0 100-2.88H1.44a1.44 1.44 0 000 2.88z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});