define("ember-svg-jar/inlined/smart-house-car", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-house-car</title><path d=\"M14.281 10.5h.469a.25.25 0 00.25-.25V8.5a.5.5 0 01.5-.5h1.857a.643.643 0 00.6-.416.628.628 0 00-.189-.7L9.416.15a.652.652 0 00-.832 0L.228 6.882a.628.628 0 00-.189.7A.643.643 0 00.643 8H2.5a.5.5 0 01.5.5v5.866a.639.639 0 00.643.634h6.975a.25.25 0 00.243-.189l.509-2.039a3 3 0 012.911-2.272zM4.416 7.187a.751.751 0 01.113-1.055 7.068 7.068 0 018.942 0 .75.75 0 11-.942 1.168 5.58 5.58 0 00-7.058 0 .751.751 0 01-1.055-.113zM9 12.5a1 1 0 111-1 1 1 0 01-1 1zm1.862-2.791a2.946 2.946 0 00-3.724 0A.75.75 0 116.2 8.542a4.432 4.432 0 015.608 0 .75.75 0 11-.942 1.167z\"/><path d=\"M23.561 17.854L22.1 16.388a.25.25 0 01-.066-.116l-.78-3.119a1.886 1.886 0 00-1.832-1.431h-4.839a1.885 1.885 0 00-1.832 1.431l-.78 3.119a.25.25 0 01-.066.116l-1.466 1.466a1.49 1.49 0 00-.439 1.06V20.5a1.5 1.5 0 001.286 1.485.249.249 0 01.214.247V23a1 1 0 002 0v-.75a.25.25 0 01.25-.25h6.5a.25.25 0 01.25.25V23a1 1 0 002 0v-.768a.249.249 0 01.214-.247A1.5 1.5 0 0024 20.5v-1.586a1.49 1.49 0 00-.439-1.06zM19.6 15.182a.253.253 0 01-.2.1h-4.8a.249.249 0 01-.242-.311l.264-1.055a.248.248 0 01.242-.19h4.27a.248.248 0 01.242.19l.264 1.055a.251.251 0 01-.04.211z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});