define("ember-svg-jar/inlined/table-restaurant-beach-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>table-restaurant-beach-1</title><path d=\"M9 19a2 2 0 00-2-2H4.14a1 1 0 01-.981-.8L1.98 10.3a1 1 0 10-1.96.4l1.441 7.2a1.013 1.013 0 01.014.3L1 22.9a1 1 0 002 .2l.388-3.875A.249.249 0 013.631 19H6.75a.25.25 0 01.25.25V23a1 1 0 002 0zM23.2 9.52a.993.993 0 00-1.176.784L20.841 16.2a1 1 0 01-.981.8H17a2 2 0 00-2 2v4a1 1 0 002 0v-3.75a.25.25 0 01.25-.25h3.119a.249.249 0 01.248.225l.388 3.875A1 1 0 0023 22.9l-.47-4.7a1.013 1.013 0 01.014-.3l1.436-7.2a1 1 0 00-.78-1.18zM22 7a1 1 0 00.363-1.932 88.49 88.49 0 01-10.112-5 .505.505 0 00-.5 0 88.523 88.523 0 01-10.111 5A1 1 0 002 7z\"/><path d=\"M18.5 14.5a1 1 0 00-1-1h-4.25a.25.25 0 01-.25-.25v-4.5a.751.751 0 00-.75-.75h-.5a.751.751 0 00-.75.75v4.5a.25.25 0 01-.25.25H6.5a1 1 0 000 2h4.25a.25.25 0 01.25.25V23a1 1 0 002 0v-7.25a.25.25 0 01.25-.25h4.25a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});