define("ember-svg-jar/inlined/safety-fire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-fire</title><circle cx=\"12\" cy=\"16.5\" r=\"2\"/><path d=\"M14.1 4.969a.719.719 0 00-.719-.719.131.131 0 00-.131.131v1.175a.132.132 0 00.131.132.72.72 0 00.719-.719z\"/><path d=\"M24 1.5A1.5 1.5 0 0022.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5zm-7.25 3.175a1.926 1.926 0 011.925-1.925h1.175a.75.75 0 010 1.5h-1.175a.425.425 0 00-.425.425v1.35a.25.25 0 00.25.25h1.35a.75.75 0 010 1.5H18.5a.25.25 0 00-.25.25V8.2a.425.425 0 00.425.425h1.175a.75.75 0 010 1.5h-1.175A1.926 1.926 0 0116.75 8.2zm-5-1.175a.75.75 0 01.75-.75h.881a2.216 2.216 0 01.911 4.237l1.2 2a.75.75 0 11-1.286.772l-.493-.821a.25.25 0 00-.464.128v.307a.75.75 0 01-1.5 0zm-3 0a.75.75 0 011.5 0v5.875a.75.75 0 01-1.5 0zm-5 1.175A1.926 1.926 0 015.675 2.75H6.85a.75.75 0 010 1.5H5.675a.425.425 0 00-.425.425v1.35a.25.25 0 00.25.25h.763a.75.75 0 010 1.5H5.5a.25.25 0 00-.25.25v1.35a.75.75 0 01-1.5 0zM20.5 20a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5v-2.5a.25.25 0 01.25-.25H5a.25.25 0 01.25.25v.5a.75.75 0 001.28.53l1.5-1.5a.751.751 0 000-1.06l-1.5-1.5a.75.75 0 00-1.28.53v.5a.25.25 0 01-.25.25H3.75a.25.25 0 01-.25-.25V13a.5.5 0 01.5-.5h16a.5.5 0 01.5.5v2.5a.25.25 0 01-.25.25H19a.25.25 0 01-.25-.25V15a.75.75 0 00-1.28-.53l-1.5 1.5a.751.751 0 000 1.06l1.5 1.5a.746.746 0 00.53.22.75.75 0 00.75-.75v-.5a.25.25 0 01.25-.25h1.25a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});