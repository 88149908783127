define("ember-svg-jar/inlined/historical-building-tower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>historical-building-tower</title><path d=\"M17.5 9.5h-11a.5.5 0 00-.5.427L4.1 22.838A1 1 0 005.09 24H9.5a.5.5 0 00.5-.5V20a2 2 0 014 0v3.5a.5.5 0 00.5.5h4.41a1 1 0 00.988-1.155L18 9.927a.5.5 0 00-.5-.427zM20 0h-2a1 1 0 00-1 1v1a1 1 0 01-2 0V1a1 1 0 00-1-1h-4a1 1 0 00-1 1v1a1 1 0 01-2 0V1a1 1 0 00-1-1H4a1 1 0 00-1 1v5a2 2 0 002 2h14a2 2 0 002-2V1a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});