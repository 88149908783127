define("ember-svg-jar/inlined/programming-language-html-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-language-html-5</title><path d=\"M4.067 20.731l7.6 2.682a1 1 0 00.666 0l7.6-2.682a1 1 0 00.656-.8l2.739-18.252A1 1 0 0022.339.53H1.661a1 1 0 00-.989 1.149l2.739 18.257a1 1 0 00.656.795zM5.948 4.059A1.492 1.492 0 017.09 3.53H17.5a1 1 0 010 2H7.976a.251.251 0 00-.191.089.248.248 0 00-.056.2l.549 3.291a.5.5 0 00.493.418h7.153a1.5 1.5 0 011.485 1.712l-.873 6.109a1.5 1.5 0 01-1.075 1.23l-3.049.872a1.53 1.53 0 01-.412.059 1.483 1.483 0 01-.408-.057l-3.062-.874a1.5 1.5 0 01-1.067-1.2l-.448-2.687a1 1 0 111.972-.328l.345 2.065a.5.5 0 00.356.4l2.176.622a.491.491 0 00.274 0l2.153-.615a.5.5 0 00.357-.41l.66-4.61a.252.252 0 00-.248-.286H7.924a1.494 1.494 0 01-1.48-1.254l-.834-5a1.5 1.5 0 01.338-1.217z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});