define("ember-svg-jar/inlined/seo-trophy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seo-trophy</title><path d=\"M21 0H3a3 3 0 00-3 3v15a3 3 0 003 3h3a1 1 0 000-2H3a1 1 0 01-1-1V7.5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5V18a1 1 0 01-1 1h-2.5a1 1 0 000 2H21a3 3 0 003-3V3a3 3 0 00-3-3zM4 4.5a1 1 0 111-1 1 1 0 01-1 1zm3 0a1 1 0 111-1 1 1 0 01-1 1zm3 0a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M18.151 12.244a.248.248 0 01.026.233 5.5 5.5 0 01-.283.611 1 1 0 101.761.948 6.561 6.561 0 00.845-2.9 1 1 0 00-1-1h-2.188a.251.251 0 01-.236-.168A1.434 1.434 0 0015.722 9H8.278a1.431 1.431 0 00-1.354.967.251.251 0 01-.236.168H4.5a1 1 0 00-1 1 6.561 6.561 0 00.845 2.9 1 1 0 101.761-.948 5.5 5.5 0 01-.283-.611.25.25 0 01.232-.342H6.7a.249.249 0 01.248.226c.369 3.895 1.537 4.74 2.812 5.283.584.249.8.342.8 1.189a2.659 2.659 0 01-1.62 2.676 1.144 1.144 0 00-.707 1.059A1.436 1.436 0 009.667 24h4.666a1.436 1.436 0 001.434-1.433 1.144 1.144 0 00-.707-1.059 2.659 2.659 0 01-1.626-2.675c0-.847.218-.94.8-1.189 1.275-.543 2.443-1.388 2.812-5.283a.249.249 0 01.248-.226h.649a.25.25 0 01.208.109z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});