define("ember-svg-jar/inlined/single-neutral-actions-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-cart</title><path d=\"M.043 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229l.443 5.313a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h1.81a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 00-11 0z\"/><circle cx=\"5.543\" cy=\"3.75\" r=\"3.5\"/><path d=\"M15.615 21.155a1.423 1.423 0 101.423 1.423 1.435 1.435 0 00-1.423-1.423zM19.308 21.155a1.423 1.423 0 101.423 1.423 1.429 1.429 0 00-1.423-1.423zM12.846 14a.5.5 0 00-.474.659l1.846 5.5a.5.5 0 00.473.341h6.232a.5.5 0 00.481-.365c1.69-6.042.852-3.242 2.544-8.318a1 1 0 10-1.9-.633l-.878 2.646a.25.25 0 01-.237.171zM.043 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229l.443 5.313a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h1.81a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 00-11 0z\"/><circle cx=\"5.543\" cy=\"3.75\" r=\"3.5\"/><path d=\"M15.615 21.155a1.423 1.423 0 101.423 1.423 1.435 1.435 0 00-1.423-1.423zM19.308 21.155a1.423 1.423 0 101.423 1.423 1.429 1.429 0 00-1.423-1.423zM12.846 14a.5.5 0 00-.474.659l1.846 5.5a.5.5 0 00.473.341h6.232a.5.5 0 00.481-.365c1.69-6.042.852-3.242 2.544-8.318a1 1 0 10-1.9-.633l-.878 2.646a.25.25 0 01-.237.171z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});