define("ember-svg-jar/inlined/light-mode-cloudy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-mode-cloudy</title><path d=\"M24 15.25A4.252 4.252 0 0019.841 11a.5.5 0 01-.481-.406A7.489 7.489 0 005.5 8.269a.5.5 0 01-.4.249A5.494 5.494 0 000 14c0 4.8 4.886 5.489 5 5.5h14.513a5.139 5.139 0 003.358-1.283A4 4 0 0024 15.25zm-6.37-.022l-.773 1.287a1 1 0 11-1.714-1.03l.772-1.286a1 1 0 111.715 1.029zm-6.962.217l2-3a1 1 0 011.664 1.11l-2 3a1 1 0 11-1.664-1.11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});