define("ember-svg-jar/inlined/business-shark-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-shark-1</title><path d=\"M5.634 2.439a2.2 2.2 0 00-.477 1.394v6.9a.251.251 0 00.29.248 4.381 4.381 0 013.332.8l.144.108a.251.251 0 00.3 0l.141-.107a4.416 4.416 0 015.27 0l.143.108a.251.251 0 00.3 0l.141-.107a4.364 4.364 0 012.517-.858.25.25 0 00.218-.361A15.526 15.526 0 007.039 1.9a1.457 1.457 0 00-1.405.539zM.582 20.76l1.172.879a2.453 2.453 0 002.928 0l1.17-.879a.491.491 0 01.585 0l1.172.879a2.453 2.453 0 002.928 0l1.17-.879a.491.491 0 01.585 0l1.172.879a2.453 2.453 0 002.928 0l1.17-.879a.491.491 0 01.585 0l1.172.879a2.456 2.456 0 002.928 0l1.171-.879a.976.976 0 00-1.172-1.56l-1.17.878a.492.492 0 01-.586 0l-1.172-.878a2.45 2.45 0 00-2.927 0l-1.17.878a.492.492 0 01-.586 0l-1.172-.878a2.45 2.45 0 00-2.927 0l-1.17.878a.492.492 0 01-.586 0L7.608 19.2a2.45 2.45 0 00-2.927 0l-1.171.878a.491.491 0 01-.585 0L1.754 19.2a.976.976 0 00-1.172 1.56zM3.51 14.223a.492.492 0 01-.585 0l-1.171-.879A.976.976 0 00.582 14.9l1.172.879a2.45 2.45 0 002.928 0l1.17-.879a.492.492 0 01.585 0l1.172.879a2.45 2.45 0 002.928 0l1.17-.879a.492.492 0 01.585 0l1.172.879a2.45 2.45 0 002.928 0l1.17-.879a.492.492 0 01.585 0l1.172.879a2.448 2.448 0 002.928 0l1.171-.879a.976.976 0 00-1.172-1.56l-1.17.879a.494.494 0 01-.586 0l-1.172-.879a2.448 2.448 0 00-2.927 0l-1.17.879a.494.494 0 01-.586 0l-1.172-.879a2.448 2.448 0 00-2.927 0l-1.17.879a.494.494 0 01-.586 0l-1.172-.879a2.448 2.448 0 00-2.927 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});