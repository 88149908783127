define("ember-svg-jar/inlined/composition-net", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>composition-net</title><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2zm-2 13.059a.251.251 0 01-.353.228l-8.1-3.68a.25.25 0 01-.124-.331l4.15-9.129A.25.25 0 0117.805 2h3.7a.5.5 0 01.5.5zM15.059 2a.251.251 0 01.228.353l-3.68 8.1a.25.25 0 01-.331.124L2.147 6.423A.25.25 0 012 6.2V2.5a.5.5 0 01.5-.5zM2 8.941a.251.251 0 01.353-.228l8.1 3.68a.25.25 0 01.124.331l-4.15 9.129A.25.25 0 016.2 22H2.5a.5.5 0 01-.5-.5zM8.941 22a.251.251 0 01-.228-.353l3.68-8.095a.25.25 0 01.331-.124l9.129 4.149a.25.25 0 01.147.228v3.7a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});