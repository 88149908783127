define("ember-svg-jar/inlined/content-pens-pocket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-pens-pocket</title><path d=\"M4.5 4v4.5A.5.5 0 005 9h4a.5.5 0 00.5-.5V4zM9.5 3v-.5a2.5 2.5 0 10-5 0V3z\"/><path d=\"M21 10.5h-1.5v-5c0-2.946-1.549-4-3-4v-1A.5.5 0 0016 0h-4a.5.5 0 00-.5.5v8a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-5c.9 0 1 1.4 1 2v9a.5.5 0 01-1 0v-4H3a.5.5 0 00-.5.5v7.067a4.5 4.5 0 003.354 4.352l5.44 1.433a4.542 4.542 0 001.145.148 4.5 4.5 0 001.27-.184l4.561-1.34a4.526 4.526 0 003.23-4.317V11a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});