define("ember-svg-jar/inlined/blood-drops-positive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>blood-drops-positive</title><path d=\"M7.6 6.25a.5.5 0 000-.513q-.759-1.278-1.767-2.8a1.03 1.03 0 00-1.66 0C1.4 7.115 0 9.995 0 11.485a5 5 0 003.37 4.724.5.5 0 00.66-.516 8.328 8.328 0 01-.03-.708c0-.74 0-2.743 3.6-8.735zM19.83 2.935a1.03 1.03 0 00-1.66 0q-1.02 1.538-1.777 2.811a.5.5 0 000 .514C20 12.251 20 14.245 20 14.985c0 .237-.01.474-.03.708a.5.5 0 00.66.516A5 5 0 0024 11.485c0-1.49-1.4-4.37-4.17-8.55zM11.168 3.43C8.582 7.309 5.5 12.462 5.5 14.985a6.5 6.5 0 0013 0c0-2.52-3.082-7.674-5.667-11.554a1.038 1.038 0 00-1.665-.001zM13.5 13.985H15a1 1 0 010 2h-1.5a.5.5 0 00-.5.5v1.5a1 1 0 01-2 0v-1.5a.5.5 0 00-.5-.5H9a1 1 0 010-2h1.5a.5.5 0 00.5-.5v-1.5a1 1 0 012 0v1.5a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});