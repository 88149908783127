define("ember-svg-jar/inlined/design-tool-razor-cut", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-tool-razor-cut</title><path d=\"M22.838 2.739c-.726-.727-2.728-1.515-3.889-.353l-9.256 9.273a.25.25 0 000 .353l3.707 3.719a.249.249 0 00.35 0l9.436-9.1.006-.006c.99-.986.62-2.914-.354-3.886zM8.987 12.72a.247.247 0 00-.177-.073.251.251 0 00-.177.073L7.158 14.2a.25.25 0 000 .353l3.658 3.671a.25.25 0 00.35 0l1.5-1.45a.254.254 0 00.076-.178.251.251 0 00-.073-.179zM8.466 17.989a.249.249 0 00-.179-.073.245.245 0 00-.177.075l-1.3 1.333a.75.75 0 11-1.075-1.047l1.317-1.355a.25.25 0 000-.35l-.952-.958a.249.249 0 00-.354 0l-5.3 5.312a.75.75 0 00.63 1.273l6.118-.816a2.484 2.484 0 001.406-.678l1.131-1.091a.254.254 0 00.076-.178.247.247 0 00-.073-.179z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});