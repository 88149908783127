define("ember-svg-jar/inlined/crypto-currency-bitcoin-imac", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-imac</title><path d=\"M8.75 12a.75.75 0 00.75.75.25.25 0 01.25.25v.5a.75.75 0 001.5 0V13a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.719a.251.251 0 01.15-.229 2.244 2.244 0 001.35-2.052 2.219 2.219 0 00-.46-1.349.249.249 0 010-.3 2.219 2.219 0 00.46-1.351 2.244 2.244 0 00-1.35-2.052.251.251 0 01-.15-.229V4.5a.75.75 0 00-1.5 0V5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25v-.484a.75.75 0 00-1.5 0V5a.25.25 0 01-.25.25.75.75 0 00-.75.75v6zm4.25-.75h-2.75v-1.5H13a.75.75 0 010 1.5zm-2.75-4.5H13a.75.75 0 010 1.5h-2.75z\"/><path d=\"M24 3.589A2.518 2.518 0 0021.565 1H2.435A2.518 2.518 0 000 3.589V16.5a2.961 2.961 0 002.913 3h7.837a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H5a1 1 0 000 2h14a1 1 0 000-2h-5.75a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h7.837a2.961 2.961 0 002.913-3zM2.435 3h19.13c.236 0 .435.27.435.589V14.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5V3.589C2 3.27 2.2 3 2.435 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});