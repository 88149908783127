define("ember-svg-jar/inlined/messaging-we-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messaging-we-chat</title><path d=\"M8.81 12.79a5 5 0 011.6-2.79 7 7 0 015.31-1.73.48.48 0 00.41-.17.5.5 0 00.11-.43 7.14 7.14 0 00-3.58-4.69A9.34 9.34 0 005 2.34a7.85 7.85 0 00-4.06 3.2 6.07 6.07 0 00-.76 4.68 6.92 6.92 0 002.75 3.94c-.22.63-.43 1.26-.63 1.89a.5.5 0 00.71.59l1.64-.87.74-.4a10.11 10.11 0 003.17.42.5.5 0 00.44-.66 5 5 0 01-.19-2.34zm1-6.44a1 1 0 01.65-.78 1 1 0 01.93.14 1 1 0 01-.19 1.71 1 1 0 01-.42.09.93.93 0 01-.63-.24.94.94 0 01-.34-.92zm-3.32.42a1 1 0 01-.65.68 1 1 0 01-.33.06.94.94 0 01-.62-.23 1 1 0 01-.35-.93 1 1 0 111.95.42z\"/><path d=\"M23.55 13.45a6.28 6.28 0 00-2.94-3 7.94 7.94 0 00-7.13 0 6 6 0 00-3.22 4 5.17 5.17 0 00.65 3.84 6.91 6.91 0 004.68 3.07 8.35 8.35 0 003.86-.21 10.64 10.64 0 011 .54c.29.16.58.33.88.47a.51.51 0 00.22 0 .5.5 0 00.48-.64q-.23-.76-.48-1.51a6.2 6.2 0 001.81-2.12 5.17 5.17 0 00.19-4.44zm-7.46.61a1 1 0 01-.65.68 1 1 0 01-.33.06.94.94 0 01-.62-.23 1 1 0 01-.35-.93 1 1 0 111.95.42zm4.21 0a1 1 0 01-.65.68 1 1 0 01-.33.06.94.94 0 01-.62-.23 1 1 0 01-.35-.93 1 1 0 111.95.42z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});