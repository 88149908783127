define("ember-svg-jar/inlined/single-neutral-actions-alarm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-alarm</title><path d=\"M.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M23.5 20.75a.5.5 0 00.4-.8l-.241-.316a4.086 4.086 0 01-1.159-2.717V15.25a4.5 4.5 0 00-9 0v1.667a4.086 4.086 0 01-1.159 2.717l-.241.316a.5.5 0 00.4.8zM19.682 21.944a.5.5 0 00-.4-.194h-2.568a.5.5 0 00-.484.625 1.827 1.827 0 003.54 0 .5.5 0 00-.088-.431zM.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M23.5 20.75a.5.5 0 00.4-.8l-.241-.316a4.086 4.086 0 01-1.159-2.717V15.25a4.5 4.5 0 00-9 0v1.667a4.086 4.086 0 01-1.159 2.717l-.241.316a.5.5 0 00.4.8zM19.682 21.944a.5.5 0 00-.4-.194h-2.568a.5.5 0 00-.484.625 1.827 1.827 0 003.54 0 .5.5 0 00-.088-.431z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});