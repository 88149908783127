define("ember-svg-jar/inlined/reptile-snake", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>reptile-snake</title><path d=\"M22 13.16V11a11 11 0 00-22 0 8.009 8.009 0 008 8 2 2 0 010 4 .5.5 0 000 1 5 5 0 000-10 3 3 0 01-3-3 6 6 0 0112 0v2.16c-.673.324-2 1.287-2 3.844a12.629 12.629 0 001.952 5.784A2.5 2.5 0 0019.1 24h.81a2.5 2.5 0 002.143-1.212A12.629 12.629 0 0024 17c0-2.553-1.327-3.516-2-3.84zM20.5 17a1 1 0 111 1 1 1 0 01-1-1zm-3 1a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});