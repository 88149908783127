define("ember-svg-jar/inlined/file-database", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-database</title><path d=\"M21.414 4.915L17.086.587A2 2 0 0015.672 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.329a2 2 0 00-.586-1.414zM16.75 18.484c0 .623-2 1.767-5.264 1.767-3.379 0-5.221-1.14-5.221-1.726v-1.46a.244.244 0 01.356-.219 11.744 11.744 0 004.865.938 11.7 11.7 0 004.906-.978.244.244 0 01.358.218zm0-3.967c0 .623-2 1.767-5.264 1.767-3.379 0-5.221-1.141-5.221-1.727v-1.573a.243.243 0 01.356-.219 11.744 11.744 0 004.865.938 11.7 11.7 0 004.906-.978.244.244 0 01.358.219zM11.486 12.2c-3.379 0-5.221-1.141-5.221-1.727s1.842-1.725 5.221-1.725c3.407 0 5.264 1.114 5.264 1.686 0 .626-1.998 1.766-5.264 1.766z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});