define("ember-svg-jar/inlined/earpods-double-tap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earpods-double-tap</title><path d=\"M23.908 9.311a10.481 10.481 0 00-2.285-2.349L17.257 3.7a1.431 1.431 0 00-1.069-.42 1.5 1.5 0 00-1.012 2.58l4.543 4.358a.992.992 0 01-1.056 1.637c-1.179-.469-1.875-.915-2.752-.219a1.644 1.644 0 00.207 2.714l3.437 1.59a10.032 10.032 0 013.324 2.406c.232.268.34.454.621.454a.5.5 0 00.5-.5V9.6a.5.5 0 00-.092-.289z\"/><path d=\"M14.3 6.383a1.751 1.751 0 01-1.05-1.6 1.731 1.731 0 01.35-1.05.75.75 0 00-1.2-.9 3.252 3.252 0 001.3 4.93.8.8 0 00.989-.387.751.751 0 00-.389-.993z\"/><path d=\"M13.663 8.435a3.751 3.751 0 01-2.08-6.013.75.75 0 10-1.166-.943A5.25 5.25 0 0013.331 9.9a.8.8 0 00.9-.565.752.752 0 00-.568-.9z\"/><path d=\"M4.1 3.3A5.063 5.063 0 00.335 4.93 1.31 1.31 0 000 5.805v4.033a1.308 1.308 0 00.336.875 5.059 5.059 0 003.764 1.63 5.118 5.118 0 00.82-.065.5.5 0 01.58.495V21.5a1.467 1.467 0 001.4 1.3h.8A1.3 1.3 0 009 21.5V7.821A4.727 4.727 0 004.1 3.3zM2.511 8.626a.8.8 0 11.8-.8.8.8 0 01-.8.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});