define("ember-svg-jar/inlined/hierarchy-10", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hierarchy-10</title><path d=\"M24 20.5a3.489 3.489 0 00-6.382-1.962.249.249 0 01-.283.1L7.164 15.4a.25.25 0 01-.173-.266A1.172 1.172 0 007 15a1.006 1.006 0 00-.008-.124.251.251 0 01.18-.268l10.776-3.078a.248.248 0 01.236.055 3.435 3.435 0 002.316.915 3.5 3.5 0 000-7 3.468 3.468 0 00-2.584 1.166.251.251 0 01-.262.071L7.146 3.392a.248.248 0 01-.173-.215A3.494 3.494 0 103.5 7a3.468 3.468 0 002.584-1.167.251.251 0 01.262-.071l9.942 3.163a.25.25 0 01-.007.479L5.942 12.358a.25.25 0 01-.229-.048A3.449 3.449 0 003.5 11.5a3.5 3.5 0 000 7 3.461 3.461 0 002.22-.815.248.248 0 01.236-.046L16.93 21.13a.248.248 0 01.168.18A3.5 3.5 0 0024 20.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});