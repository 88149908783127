define("ember-svg-jar/inlined/famous-character-megaman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character-megaman</title><path d=\"M23.879 8.659A14.256 14.256 0 009.151.1a1.54 1.54 0 00-1.28 1.056L6.763 4.582a.251.251 0 01-.447.06l-.467-.713a2.053 2.053 0 00-3.027-.458A14.215 14.215 0 00.4 6.048a2.052 2.052 0 00.529 2.929l.57.372a.5.5 0 01.176.636 10.454 10.454 0 00-.765 2.149 1.023 1.023 0 00.347 1.028 1.011 1.011 0 001.067.146 7.268 7.268 0 014.559-.478.5.5 0 01.4.489l.009 5.675a1 1 0 01-.2.606l-1.74 2.3a.486.486 0 00-.087.18 1.571 1.571 0 00.259 1.382A1.389 1.389 0 006.609 24h12.184a1 1 0 001-1v-2.132a1 1 0 01.164-.548 10.392 10.392 0 001.651-6.387 1.01 1.01 0 01.15-.591c.364-.583 1.2-1.952 2.013-3.278a1.539 1.539 0 00.108-1.405zM4.433 6.458a10.544 10.544 0 00-1.079 1.025.639.639 0 01-.836.1.587.587 0 01-.12-.854A10.083 10.083 0 013.6 5.481a.641.641 0 11.831.977zm8.86 14.042a4 4 0 114-4 4 4 0 01-4 4zM21.8 9.447l-.59.963a.249.249 0 01-.442-.031 10.538 10.538 0 00-8.062-6.212 10.775 10.775 0 00-3.338.04.249.249 0 01-.279-.323L9.58 2.37a.5.5 0 01.444-.345 12.256 12.256 0 0111.8 6.947.5.5 0 01-.024.475z\"/><circle cx=\"13.293\" cy=\"16.499\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});