define("ember-svg-jar/inlined/building-daylight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>building-daylight</title><path d=\"M10 10.5V9a.5.5 0 00-.5-.5H7a.5.5 0 010-1h2.5A.5.5 0 0010 7v-.5a.5.5 0 00-.5-.5H6a.5.5 0 010-1h3.5a.5.5 0 00.5-.5A1.5 1.5 0 008.5 3h-7A1.5 1.5 0 000 4.5v19a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V21a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-10A2.5 2.5 0 019.5 11a.5.5 0 00.5-.5z\"/><path d=\"M17 15h-3a.5.5 0 010-1h2.866a.5.5 0 00.432-.75A1.5 1.5 0 0016 12.5h-6A1.5 1.5 0 008.5 14v9.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V21a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V18a.5.5 0 00-.5-.5h-2a.5.5 0 010-1h2a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5z\"/><circle cx=\"18.252\" cy=\"5.75\" r=\"2.5\"/><path d=\"M18.25 2.25A.75.75 0 0019 1.5V.75a.75.75 0 00-1.5 0v.75a.75.75 0 00.75.75zM21.255 3.5a.748.748 0 00.531-.22l.53-.53a.75.75 0 00-1.061-1.061l-.53.531a.75.75 0 00.53 1.28zM24 5.75a.75.75 0 00-.75-.75h-.75a.75.75 0 000 1.5h.75a.75.75 0 00.75-.75zM21.786 8.225a.75.75 0 10-1.061 1.06l.53.531a.75.75 0 001.061-1.061zM18.25 9.25a.75.75 0 00-.75.75v.75a.75.75 0 001.5 0V10a.75.75 0 00-.75-.75zM14.184 8.755a.75.75 0 101.061 1.061l.53-.531a.75.75 0 10-1.061-1.06zM13.25 6.5H14A.75.75 0 0014 5h-.75a.75.75 0 000 1.5zM14.714 3.275a.75.75 0 101.061-1.06l-.53-.531a.75.75 0 00-1.061 1.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});