define("ember-svg-jar/inlined/police-polygraph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-polygraph</title><circle cx=\"19\" cy=\"19.5\" r=\"1.5\"/><path d=\"M24 16.5a1.5 1.5 0 00-1.5-1.5H4a.5.5 0 01-.5-.5V4a2 2 0 014 0v6a4 4 0 004 4h3a3.987 3.987 0 003.973-3.821.5.5 0 01.315-.442A3.488 3.488 0 0021 6.5v-2a.5.5 0 00-.5-.5h-6a.5.5 0 00-.5.5v2a3.484 3.484 0 002.168 3.215.5.5 0 01.306.519A1.99 1.99 0 0114.5 12h-3a2 2 0 01-2-2V4a4 4 0 00-8 0v10.563a.5.5 0 01-.375.484A1.5 1.5 0 000 16.5v6A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5zM14.5 21a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h8a.5.5 0 01.5.5zm4.5 1a2.5 2.5 0 112.5-2.5A2.5 2.5 0 0119 22z\"/><path d=\"M20.5 2.5A.5.5 0 0021 2v-.5A1.5 1.5 0 0019.5 0h-4A1.5 1.5 0 0014 1.5V2a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});