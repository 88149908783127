define("ember-svg-jar/inlined/vide-document-mp4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vide-document-mp4</title><path d=\"M12.006 9.625h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25h.625a.875.875 0 000-1.75zM17.718 10.273a4.908 4.908 0 00-1.353 1.946.115.115 0 00.116.156h1.138a.25.25 0 00.25-.25v-1.784c0-.121-.101-.111-.151-.068z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM9.131 15a.625.625 0 01-1.25 0v-3.13c0-.07-.084-.055-.111 0l-.2.409a.649.649 0 01-1.117 0s-.183-.356-.205-.409-.112-.052-.112 0V15a.625.625 0 01-1.25 0V9a.625.625 0 011.184-.279l.8 1.609a.167.167 0 00.273 0l.805-1.609A.624.624 0 019.131 9zm2.75-2.375h-.5a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0V9a.625.625 0 01.625-.625h1.5a2.127 2.127 0 012.114 2.347 2.212 2.212 0 01-2.239 1.903zm7.238.375v2a.625.625 0 01-1.25 0v-1.127a.248.248 0 00-.249-.248h-2.114a.626.626 0 01-.616-.731 6.146 6.146 0 013.327-4.445.625.625 0 01.9.561z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});