define("ember-svg-jar/inlined/mobile-shopping-bag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mobile-shopping-bag</title><path d=\"M13.125 12a1 1 0 001-1V2.5a2.5 2.5 0 00-2.5-2.5h-9a2.5 2.5 0 00-2.5 2.5v19a2.5 2.5 0 002.5 2.5h7a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5h-7a.5.5 0 01-.5-.5v-14a.5.5 0 01.5-.5h9a.5.5 0 01.5.5V11a1 1 0 001 1zM23.85 21.281l-.831-5.407a1.907 1.907 0 00-1.894-1.624.25.25 0 01-.25-.25 2.75 2.75 0 00-5.5 0 .25.25 0 01-.25.25 1.907 1.907 0 00-1.894 1.625l-.831 5.406a2.223 2.223 0 00-.025.325 2.146 2.146 0 002.144 2.144h7.212a2.146 2.146 0 002.144-2.144 2.223 2.223 0 00-.025-.325zM16.875 14a1.25 1.25 0 012.5 0 .25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25z\"/><path d=\"M7 16a1 1 0 001-1v-.1a.251.251 0 01.181-.24 2.342 2.342 0 00.219-4.426l-2.06-.825a.342.342 0 01.127-.659H8.5a1 1 0 000-2h-.25A.25.25 0 018 6.5a1 1 0 00-2 0v.1a.251.251 0 01-.181.24 2.342 2.342 0 00-.219 4.426l2.062.825a.342.342 0 01-.127.659H5.5a1 1 0 000 2h.25A.25.25 0 016 15a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});