define("ember-svg-jar/inlined/camping-tent-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camping-tent-3</title><path d=\"M17.964 11.48a.25.25 0 00.223-.362l-.931-1.862a.5.5 0 00-.447-.276H7.191a.5.5 0 00-.447.276l-.931 1.862a.25.25 0 00.223.362zM15.964 7.48a.25.25 0 00.223-.362L12.894.532a1.037 1.037 0 00-1.789 0L7.813 7.118a.25.25 0 00.223.362zM23 23.98a1 1 0 00.895-1.447l-4.638-9.277a.5.5 0 00-.447-.276H5.191a.5.5 0 00-.447.276L.106 22.533A1 1 0 001 23.98h5.82a.5.5 0 00.439-.262l4.3-7.933a.5.5 0 01.877 0l4.364 7.936a.5.5 0 00.438.259z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});