define("ember-svg-jar/inlined/season-summer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>season-summer</title><path d=\"M10.934 18.947a1 1 0 00.128-1.991 5.251 5.251 0 115.919-5.65 1 1 0 001.994-.166 7.25 7.25 0 10-8.173 7.8.987.987 0 00.132.007zM11.75 3.5a1 1 0 001-1V1a1 1 0 00-2 0v1.5a1 1 0 001 1zM4.5 5.916A1 1 0 005.916 4.5L4.855 3.441a1 1 0 00-1.414 1.415zM19 5.916l1.061-1.06a1 1 0 00-1.414-1.415L17.583 4.5A1 1 0 0019 5.916z\"/><path d=\"M3.5 11.75a1 1 0 00-1-1H1a1 1 0 000 2h1.5a1 1 0 001-1zM4.5 17.583l-1.059 1.061a1 1 0 001.414 1.415L5.916 19A1 1 0 004.5 17.583zM23.942 13.753a.5.5 0 00-.674-.391 8.847 8.847 0 01-3.724.388 6.666 6.666 0 00-3.9.613 3.792 3.792 0 00-2.158 2.706 2.781 2.781 0 00.369 1.653.5.5 0 01-.112.618 20.534 20.534 0 00-3.274 3.484.75.75 0 001.233.854 24.3 24.3 0 016.89-5.98.75.75 0 01.756 1.295 23.331 23.331 0 00-3.553 2.556.5.5 0 00-.031.724 2.273 2.273 0 00.6.446 2.533 2.533 0 001.147.266 5.418 5.418 0 002.7-.865c2.001-1.183 4.175-5.452 3.731-8.367z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});