define("ember-svg-jar/inlined/amusement-park-speed-machine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>amusement-park-speed-machine</title><path d=\"M14.75 19h-5.5a.75.75 0 00-.75.75v3.5a.75.75 0 00.75.75h5.5a.75.75 0 00.75-.75v-3.5a.75.75 0 00-.75-.75zM12 22.5a.75.75 0 11.75-.75.75.75 0 01-.75.75zM19.5 11.5h-4.25a.5.5 0 01-.5-.5V6a.75.75 0 00-.75-.75h-.75a.5.5 0 01-.5-.5.608.608 0 01.335-.543l2.521-1.26a.5.5 0 000-.894L12.929.714a.492.492 0 01-.238-.254.75.75 0 00-1.441.29v4a.5.5 0 01-.5.5H10a.75.75 0 00-.75.75v5a.5.5 0 01-.5.5H4.5a1 1 0 00-1 1v3a2 2 0 002 2h13a2 2 0 002-2v-3a1 1 0 00-1-1zM12 7a.75.75 0 11-.75.75A.75.75 0 0112 7zm0 3a.75.75 0 11-.75.75A.75.75 0 0112 10zm6.5 5a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h12a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});