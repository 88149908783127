define("ember-svg-jar/inlined/walking-cross-street", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>walking-cross-street</title><circle cx=\"12.872\" cy=\"2.625\" r=\"2.5\"/><path d=\"M2.217 17.4a1.012 1.012 0 00-1.193.76l-1 4.5a1 1 0 001.953.433l1-4.5a1 1 0 00-.76-1.193zM5.717 17.4a1.01 1.01 0 00-1.193.76l-1 4.5a1 1 0 101.953.433l1-4.5a1 1 0 00-.76-1.193zM23.976 22.658l-1-4.5a1 1 0 10-1.953.433l1 4.5a1 1 0 001.953-.433zM19.726 18.158a1 1 0 00-1.953.433l1 4.5a1 1 0 001.953-.433z\"/><path d=\"M11.8 14.074l.885-4.049a.25.25 0 01.412-.132 2.909 2.909 0 01.7.969 4.659 4.659 0 004.252 2.763H19a1.5 1.5 0 000-3h-.95a1.654 1.654 0 01-1.511-.983A5.923 5.923 0 006.5 8.348l-2.671 3.339a1.5 1.5 0 102.342 1.875l2.672-3.339a2.66 2.66 0 01.341-.354.251.251 0 01.408.243L7.034 21.8a1.5 1.5 0 002.932.641l1.055-4.825a.25.25 0 01.412-.132 6.243 6.243 0 012.067 4.64 1.5 1.5 0 003 0 9.243 9.243 0 00-4.7-8.05z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});