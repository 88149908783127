define("ember-svg-jar/inlined/single-neutral-circle.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-circle</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 21.5a9.5 9.5 0 119.5-9.5 9.511 9.511 0 01-9.5 9.5z\"/><circle cx=\"12\" cy=\"6.5\" r=\"3\"/><path d=\"M12 10.5a4.456 4.456 0 00-4.5 4.4v2.6a.5.5 0 00.5.5h1.323a.25.25 0 01.249.225l.187 1.826a.5.5 0 00.5.449h3.488a.5.5 0 00.5-.449l.187-1.826a.25.25 0 01.243-.225H16a.5.5 0 00.5-.5v-2.6a4.456 4.456 0 00-4.5-4.4z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 21.5a9.5 9.5 0 119.5-9.5 9.511 9.511 0 01-9.5 9.5z\"/><circle cx=\"12\" cy=\"6.5\" r=\"3\"/><path d=\"M12 10.5a4.456 4.456 0 00-4.5 4.4v2.6a.5.5 0 00.5.5h1.323a.25.25 0 01.249.225l.187 1.826a.5.5 0 00.5.449h3.488a.5.5 0 00.5-.449l.187-1.826a.25.25 0 01.243-.225H16a.5.5 0 00.5-.5v-2.6a4.456 4.456 0 00-4.5-4.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});