define("ember-svg-jar/inlined/video-player-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-player-alternate</title><path d=\"M19 2H5a5.006 5.006 0 00-5 5v10a5.006 5.006 0 005 5h14a5.006 5.006 0 005-5V7a5.006 5.006 0 00-5-5zm3 15a3 3 0 01-3 3H5a3 3 0 01-3-3V7a3 3 0 013-3h14a3 3 0 013 3z\"/><path d=\"M15.935 10.658l-5.764-2.882A1.5 1.5 0 008 9.118v5.764a1.5 1.5 0 002.171 1.342l5.764-2.882a1.5 1.5 0 000-2.684z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});