define("ember-svg-jar/inlined/accounting-coins-stack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accounting-coins-stack</title><path d=\"M8.222 19.475a.248.248 0 00.239-.249v-2.341a.25.25 0 00-.261-.25c-.4.018-.8.027-1.2.027a18.6 18.6 0 01-6.2-.9.25.25 0 00-.339.238v2.153C.461 18.9 3.388 19.5 7 19.5q.627 0 1.222-.025zM7 7.662a14.944 14.944 0 006.406-1.087.249.249 0 00.133-.221V4a.25.25 0 00-.341-.233 18.6 18.6 0 01-6.2.9 18.6 18.6 0 01-6.2-.9A.25.25 0 00.461 4v2.355a.249.249 0 00.133.22A14.944 14.944 0 007 7.662zM7 3.662a14.944 14.944 0 006.406-1.087.249.249 0 00.133-.221v-.421c0-.745-2.927-1.351-6.539-1.351S.461 1.188.461 1.933v.422a.249.249 0 00.133.22A14.944 14.944 0 007 3.662zM7 15.662c.427 0 .834-.011 1.223-.029a.25.25 0 00.238-.25v-1.532a2.325 2.325 0 01.145-.885.248.248 0 00-.03-.237.252.252 0 00-.216-.1c-.453.024-.908.037-1.36.037a18.6 18.6 0 01-6.2-.9.25.25 0 00-.339.234v2.359a.249.249 0 00.133.22A14.944 14.944 0 007 15.662zM7 11.662a21 21 0 006.4-1.087.25.25 0 00.134-.221V8a.25.25 0 00-.341-.233 18.6 18.6 0 01-6.2.9 18.6 18.6 0 01-6.2-.9A.25.25 0 00.461 8v2.359a.249.249 0 00.133.22A14.944 14.944 0 007 11.662zM10.8 15.681a.25.25 0 00-.341.233v2.358a.249.249 0 00.133.221A14.944 14.944 0 0017 19.58a14.944 14.944 0 006.406-1.087.25.25 0 00.133-.221v-2.358a.25.25 0 00-.341-.233 18.6 18.6 0 01-6.2.9 18.6 18.6 0 01-6.198-.9zM17 20.58a18.6 18.6 0 01-6.2-.9.25.25 0 00-.341.233v2.153c0 .747 2.927 1.351 6.539 1.351s6.539-.6 6.539-1.351v-2.152a.25.25 0 00-.341-.233A18.6 18.6 0 0117 20.58zM10.461 13.851v.421a.249.249 0 00.133.221A14.944 14.944 0 0017 15.58a14.944 14.944 0 006.406-1.087.249.249 0 00.133-.221v-.421c0-.745-2.927-1.351-6.539-1.351s-6.539.606-6.539 1.351z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});