define("ember-svg-jar/inlined/dislike-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dislike-1-alternate</title><path d=\"M0 12.842a2.753 2.753 0 002.75 2.75h5.72a.25.25 0 01.239.323 8.409 8.409 0 00-.422 4.229c.633 2.252 2.055 2.886 3.084 2.746a2.245 2.245 0 001.879-2.3c0-3.019 2.694-6.319 5.053-7.24a.987.987 0 00.947.74H23a1 1 0 001-1v-11a1 1 0 00-1-1h-3.75a1 1 0 00-1 1v.329c-1.029-.147-1.8-.288-2.462-.408a19.267 19.267 0 00-4.038-.421h-6.5c-2.256 0-2.5 1.4-2.5 2a2.486 2.486 0 00.163.887 2.5 2.5 0 00-1 3 2.5 2.5 0 00-.9 3.231A2.749 2.749 0 000 12.842zm20.25-9a.75.75 0 11.75.75.75.75 0 01-.75-.75zM23 13.093zm-21-.251a.751.751 0 01.75-.75h.5a1 1 0 000-2 .5.5 0 010-1h1a1 1 0 000-2 .5.5 0 010-1h1a1 1 0 000-2 .5.5 0 01-.492-.46 2.246 2.246 0 01.492-.04h6.5a17.46 17.46 0 013.677.387c.734.135 1.621.295 2.823.46v6.83c-3.495.909-7 5.359-7 9.323 0 .192-.079.3-.152.316-.163.021-.6-.276-.885-1.3-.294-1.046.431-3.387.956-4.618a1 1 0 00-.919-1.393h-7.5a.751.751 0 01-.75-.755z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});