define("ember-svg-jar/inlined/file-dmg-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-dmg-1</title><path d=\"M23.707 5.708L18.293.294A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.751V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.415a1 1 0 00-.293-.707z\"/><path d=\"M4.456 12.32a.249.249 0 00-.331.239v3.883a.249.249 0 00.331.24A2.238 2.238 0 005.875 14.5a2.237 2.237 0 00-1.419-2.18z\"/><path d=\"M20 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h18a1 1 0 001-1zm-16.5-.874a.625.625 0 01-.625-.625v-6a.625.625 0 01.625-.625 3.625 3.625 0 010 7.25zm8.625-.625a.625.625 0 01-1.25 0v-2.927c0-.154-.15-.134-.224.021l-.092.185a.65.65 0 01-1.118 0l-.107-.214c-.06-.122-.209-.117-.209.008V17.5a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279s.72 1.429.786 1.571a.173.173 0 00.311 0l.785-1.569a.625.625 0 011.184.279zm5-4a.625.625 0 01-1.25 0V13a.875.875 0 10-1.75 0v3a.875.875 0 001.718.237c.01-.036.033-.112-.085-.112H15.5a.625.625 0 010-1.25h1a.625.625 0 01.625.625v.5a2.125 2.125 0 01-4.25 0v-3a2.125 2.125 0 114.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});