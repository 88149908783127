define("ember-svg-jar/inlined/appliances-fridge-double-door", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>appliances-fridge-double-door</title><path d=\"M12.75 13.25a.5.5 0 00.5.5h7.25a.5.5 0 00.5-.5V2.5A2.5 2.5 0 0018.5 0h-5.25a.5.5 0 00-.5.5zm2.75-9a.75.75 0 01.75.75v5a.75.75 0 01-1.5 0V5a.75.75 0 01.75-.75zM10.75 13.75a.5.5 0 00.5-.5V.5a.5.5 0 00-.5-.5H5.5A2.5 2.5 0 003 2.5v10.75a.5.5 0 00.5.5zM7.75 5a.75.75 0 011.5 0v5a.75.75 0 01-1.5 0zM3.5 15.25a.5.5 0 00-.5.5v5.75A2.5 2.5 0 005.5 24h13a2.5 2.5 0 002.5-2.5v-5.75a.5.5 0 00-.5-.5zm12 3.5h-7a.75.75 0 010-1.5h7a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});