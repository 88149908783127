define("ember-svg-jar/inlined/single-man-actions-video.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-video</title><path d=\"M9.526 15.588a2.959 2.959 0 011.253-2.414.246.246 0 00.1-.255A5.442 5.442 0 00.124 14.1v3.464a.494.494 0 00.5.5H2.41a.246.246 0 01.246.227l.444 5.255a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h.554a.248.248 0 00.248-.248z\"/><path d=\"M5.567 8.412a4.206 4.206 0 10-4.206-4.206 4.211 4.211 0 004.206 4.206zm0-1.979A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM23.254 15.605l-2.778.74a.248.248 0 01-.311-.239v-.518A1.486 1.486 0 0018.68 14.1H12.5a1.486 1.486 0 00-1.485 1.485v6.927A1.486 1.486 0 0012.5 24h6.18a1.486 1.486 0 001.485-1.485V22a.248.248 0 01.311-.239l2.777.74a.49.49 0 00.428-.086.5.5 0 00.193-.392v-5.941a.5.5 0 00-.193-.392.489.489 0 00-.427-.085z\"/><path d=\"M9.526 15.588a2.959 2.959 0 011.253-2.414.246.246 0 00.1-.255A5.442 5.442 0 00.124 14.1v3.464a.494.494 0 00.5.5H2.41a.246.246 0 01.246.227l.444 5.255a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h.554a.248.248 0 00.248-.248z\"/><path d=\"M5.567 8.412a4.206 4.206 0 10-4.206-4.206 4.211 4.211 0 004.206 4.206zm0-1.979A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM23.254 15.605l-2.778.74a.248.248 0 01-.311-.239v-.518A1.486 1.486 0 0018.68 14.1H12.5a1.486 1.486 0 00-1.485 1.485v6.927A1.486 1.486 0 0012.5 24h6.18a1.486 1.486 0 001.485-1.485V22a.248.248 0 01.311-.239l2.777.74a.49.49 0 00.428-.086.5.5 0 00.193-.392v-5.941a.5.5 0 00-.193-.392.489.489 0 00-.427-.085z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});