define("ember-svg-jar/inlined/single-man-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-check</title><path d=\"M23.786 1.663a.726.726 0 00-.375-.189.993.993 0 00-1.112.2L17.683 6.29a.249.249 0 01-.354 0L16.15 5.111a1 1 0 00-1.414 1.414L16.313 8.1a1.691 1.691 0 002.386 0l5.014-5.014a.983.983 0 00.274-.8.734.734 0 00-.201-.623z\"/><path d=\"M23.055 7.2a1 1 0 00-1.254.656 4.5 4.5 0 11-4.295-5.846 1 1 0 000-2 6.5 6.5 0 106.2 8.444 1 1 0 00-.651-1.254zM5.506 8.994a5.506 5.506 0 00-5.5 5.5v3a.5.5 0 00.5.5h1.81a.249.249 0 01.249.229l.443 5.312a.5.5 0 00.5.459h4a.5.5 0 00.5-.459l.443-5.312a.249.249 0 01.249-.229h1.81a.5.5 0 00.5-.5v-3a5.506 5.506 0 00-5.504-5.5zm.75 5.948a.75.75 0 01-1.5 0v-3.948a.75.75 0 011.5 0zM5.509 8.144A4.069 4.069 0 101.44 4.075a4.074 4.074 0 004.069 4.069zM3.577 3.356a.252.252 0 01.152-.146.257.257 0 01.21.022 5.049 5.049 0 002.608.726 4.964 4.964 0 00.737-.058.25.25 0 01.287.247 2.028 2.028 0 01-2.062 2A2.071 2.071 0 013.44 4.075a1.977 1.977 0 01.137-.719z\"/><path d=\"M23.786 1.663a.726.726 0 00-.375-.189.993.993 0 00-1.112.2L17.683 6.29a.249.249 0 01-.354 0L16.15 5.111a1 1 0 00-1.414 1.414L16.313 8.1a1.691 1.691 0 002.386 0l5.014-5.014a.983.983 0 00.274-.8.734.734 0 00-.201-.623z\"/><path d=\"M23.055 7.2a1 1 0 00-1.254.656 4.5 4.5 0 11-4.295-5.846 1 1 0 000-2 6.5 6.5 0 106.2 8.444 1 1 0 00-.651-1.254zM5.506 8.994a5.506 5.506 0 00-5.5 5.5v3a.5.5 0 00.5.5h1.81a.249.249 0 01.249.229l.443 5.312a.5.5 0 00.5.459h4a.5.5 0 00.5-.459l.443-5.312a.249.249 0 01.249-.229h1.81a.5.5 0 00.5-.5v-3a5.506 5.506 0 00-5.504-5.5zm.75 5.948a.75.75 0 01-1.5 0v-3.948a.75.75 0 011.5 0zM5.509 8.144A4.069 4.069 0 101.44 4.075a4.074 4.074 0 004.069 4.069zM3.577 3.356a.252.252 0 01.152-.146.257.257 0 01.21.022 5.049 5.049 0 002.608.726 4.964 4.964 0 00.737-.058.25.25 0 01.287.247 2.028 2.028 0 01-2.062 2A2.071 2.071 0 013.44 4.075a1.977 1.977 0 01.137-.719z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});