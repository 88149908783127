define("ember-svg-jar/inlined/idea-strategy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>idea-strategy</title><circle cx=\"4.155\" cy=\"21.466\" r=\"2.5\"/><rect x=\"9.655\" y=\"18.966\" width=\"5\" height=\"5\" rx=\".5\" ry=\".5\"/><path d=\"M22.293 23.242L20.6 19.86a.5.5 0 00-.895 0l-1.691 3.382a.5.5 0 00.448.724h3.381a.5.5 0 00.448-.724zM4.155 17.966a1 1 0 001-1v-1.25a.249.249 0 01.25-.25h5.5a.25.25 0 01.25.25v1.25a1 1 0 002 0v-1.25a.249.249 0 01.25-.25h5.5a.25.25 0 01.25.25v1.25a1 1 0 002 0v-2a1.5 1.5 0 00-1.5-1.5h-15a1.5 1.5 0 00-1.5 1.5v2a1 1 0 001 1zM9.556 7.533a.252.252 0 01.1.2v.551a.75.75 0 00.75.75h3.5a.75.75 0 00.75-.75v-.552a.251.251 0 01.1-.2 4.126 4.126 0 001.652-3.25 4.362 4.362 0 00-4.25-4.25 4.361 4.361 0 00-4.25 4.25 4.125 4.125 0 001.648 3.251zM12.072 12.7a.75.75 0 00.75-.75v-.167a.249.249 0 01.25-.25h.333a.75.75 0 000-1.5h-2.5a.75.75 0 000 1.5h.167a.25.25 0 01.25.25v.167a.75.75 0 00.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});