define("ember-svg-jar/inlined/crime-man-thief-3.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-man-thief-3</title><path d=\"M16.445 16.591a.247.247 0 00-.237.042A7.358 7.358 0 0112 18.375a7.363 7.363 0 01-4.211-1.744.247.247 0 00-.237-.042L2.92 18.162A4.519 4.519 0 000 22.375v1a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-1a4.524 4.524 0 00-2.937-4.219z\"/><circle cx=\"10\" cy=\"3.375\" r=\".75\"/><circle cx=\"14\" cy=\"3.375\" r=\".75\"/><circle cx=\"12\" cy=\"3.875\" r=\".75\"/><path d=\"M5.155 7.986a.252.252 0 01-.033.227 2.148 2.148 0 00-.371 1.3 2.728 2.728 0 001.435 2.431.251.251 0 01.142.188c.509 3.047 4.011 4.991 5.672 4.991s5.163-1.944 5.672-4.992a.251.251 0 01.142-.187 2.728 2.728 0 001.435-2.431 2.148 2.148 0 00-.371-1.3.252.252 0 01-.033-.227A2.772 2.772 0 0019 7.077a2.842 2.842 0 00-.044-.443 2.78 2.78 0 00-.792-1.554 2.866 2.866 0 00-3.615-.333L12.14 6.378a.247.247 0 01-.28 0L9.451 4.747a2.864 2.864 0 00-3.615.333 2.78 2.78 0 00-.792 1.554A2.842 2.842 0 005 7.077a2.772 2.772 0 00.155.909zm12.038 2.6a1.752 1.752 0 00-1 1.306c-.375 2.248-3.162 3.738-4.191 3.738s-3.816-1.49-4.191-3.738a1.752 1.752 0 00-1-1.306 1.043 1.043 0 01-.476-.631.253.253 0 01.065-.269.25.25 0 01.27-.041 2.861 2.861 0 002.779-.238l1.524-1.032.885-.6a.247.247 0 01.28 0l.885.6 1.524 1.032a2.861 2.861 0 002.779.238.25.25 0 01.27.041.253.253 0 01.071.264 1.043 1.043 0 01-.474.631zM9 7.625H8a.75.75 0 010-1.5h1a.75.75 0 010 1.5zm7.75-.75a.75.75 0 01-.75.75h-1a.75.75 0 010-1.5h1a.75.75 0 01.75.75zM6.883 2.812a.749.749 0 001.06.044A5.858 5.858 0 0112 1.625a5.858 5.858 0 014.057 1.231.75.75 0 101.015-1.1A7.268 7.268 0 0012 .125a7.268 7.268 0 00-5.072 1.627.751.751 0 00-.045 1.06z\"/><path d=\"M9.873 13.037a.741.741 0 00.352.088.751.751 0 00.663-.4.777.777 0 01.457-.29 2.011 2.011 0 01.582-.083 2 2 0 01.576.082.794.794 0 01.465.3.75.75 0 001.314-.722A2.2 2.2 0 0012.943 11a3.511 3.511 0 00-1.014-.147 3.544 3.544 0 00-1.022.147 2.181 2.181 0 00-1.344 1.019.751.751 0 00.31 1.018z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});