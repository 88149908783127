define("ember-svg-jar/inlined/chick-1-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chick-1_1</title><path d=\"M24 5.005a1 1 0 10-2 0 1 1 0 01-.606.918 5.5 5.5 0 10-9.365 4.877 2 2 0 01-1.52.7h-4A2.5 2.5 0 014.013 9a.5.5 0 00-.5-.5C1.445 8.5 0 10.448 0 13.236a9.488 9.488 0 0010.009 9.257A10.5 10.5 0 0020.5 12a4.963 4.963 0 00-.237-1.524A5.481 5.481 0 0021.4 8.083 1 1 0 0122 9a1 1 0 002 0 2.986 2.986 0 00-.766-2A2.984 2.984 0 0024 5.005zm-7.247 9.372a5.747 5.747 0 01-11.446.256.749.749 0 01.663-.827.741.741 0 01.827.663 4.248 4.248 0 008.46-.189.75.75 0 011.5.1zm.252-6.877a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});