define("ember-svg-jar/inlined/file-sql", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-sql</title><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.428A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6zM12.318 13.5a.876.876 0 00-.875.875v2.5a.875.875 0 001.75 0v-2.5a.875.875 0 00-.875-.875z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.251A.25.25 0 0014.75 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.251A.25.25 0 0021.75 7zm-15 7.742l1.84 1.226A1.928 1.928 0 017.516 19.5h-1.7a.625.625 0 010-1.25h1.7a.678.678 0 00.376-1.242l-1.84-1.226a1.928 1.928 0 011.069-3.532h1.7a.625.625 0 010 1.25h-1.7a.678.678 0 00-.376 1.242zm7.7 2.133a2.112 2.112 0 01-.973 1.774.1.1 0 00.009.159l.728.577a.634.634 0 01.1.878.627.627 0 01-.879.1l-1.93-1.53a2.128 2.128 0 01-1.307-1.96v-2.5a2.125 2.125 0 014.25 0zm4.368 2.625h-2a1.627 1.627 0 01-1.625-1.625v-5a.625.625 0 111.25 0v5a.376.376 0 00.375.375h2a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});