define("ember-svg-jar/inlined/road-sign-slide-right-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-slide-right-alternate</title><path d=\"M.293 12.707l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414zm2.3-.53a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354l-9.232 9.232a.25.25 0 01-.354 0z\"/><path d=\"M13.53 6.47l2 2A.75.75 0 0115 9.75h-.75A.25.25 0 0014 10v1a1 1 0 01-.293.707l-2.634 2.634a.249.249 0 00-.073.177V17a1 1 0 01-2 0v-2.793a1.494 1.494 0 01.439-1.061l2.488-2.487a.249.249 0 00.073-.177V10a.25.25 0 00-.25-.25H11a.75.75 0 01-.53-1.28l2-2a.749.749 0 011.06 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});