define("ember-svg-jar/inlined/cash-payment-bill-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-payment-bill-3</title><path d=\"M22.5 1.294h-21a1.5 1.5 0 00-1.5 1.5v12a1.5 1.5 0 001.5 1.5H7a1 1 0 000-2H2.25a.25.25 0 01-.25-.25v-10.5a.25.25 0 01.25-.25h19.5a.25.25 0 01.25.25v7.662a1 1 0 102 0V2.794a1.5 1.5 0 00-1.5-1.5z\"/><path d=\"M20.994 11.154a1.64 1.64 0 00-3.241-.294.224.224 0 01-.016.05.944.944 0 00-.094.262l-.324 2.263a2.185 2.185 0 01-1.081 1.591l-1.8 1.028a2.311 2.311 0 00-1.189 1.805v1.347a.769.769 0 01-.75.75.75.75 0 01-.75-.75v-1.25a.25.25 0 00-.25-.25H5.926a.251.251 0 00-.218.372l1.027 1.834a.247.247 0 01.032.122v2.422a.25.25 0 00.25.25h9.354a.25.25 0 00.25-.25v-.844a2.189 2.189 0 01.879-1.752l2.628-1.971A2.192 2.192 0 0021 16.137v-4.96a.2.2 0 00-.006-.023z\"/><path d=\"M11.875 4.206a1 1 0 00-1 1v.1a.251.251 0 01-.181.24 2.341 2.341 0 00-.221 4.424l2.062.826a.341.341 0 01-.127.658h-2.033a1 1 0 000 2h.25a.25.25 0 01.25.25 1 1 0 002 0v-.1a.25.25 0 01.181-.24 2.341 2.341 0 00.221-4.424l-2.062-.826A.338.338 0 0111 7.8a.342.342 0 01.342-.342h2.033a1 1 0 000-2h-.25a.25.25 0 01-.25-.25 1 1 0 00-1-1.002z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});