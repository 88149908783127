define("ember-svg-jar/inlined/single-man-actions-sync.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-sync</title><path d=\"M.629 18.062h1.792a.247.247 0 01.246.227l.438 5.257A.5.5 0 003.6 24h3.957a.494.494 0 00.493-.454l.438-5.257a.247.247 0 01.247-.227h.541a.248.248 0 00.247-.262c0-.077-.008-.155-.008-.233a7.17 7.17 0 011.376-4.212.248.248 0 00.043-.187 5.44 5.44 0 00-10.8.935v3.464a.5.5 0 00.495.495z\"/><path d=\"M1.371 4.206A4.207 4.207 0 105.578 0a4.212 4.212 0 00-4.207 4.206zm4.207 2.227a2.229 2.229 0 01-2.227-2.227 2.135 2.135 0 01.156-.8.249.249 0 01.36-.119 5.314 5.314 0 002.8.793 5.335 5.335 0 00.9-.077.2.2 0 01.165.045.2.2 0 01.072.155 2.229 2.229 0 01-2.226 2.23zM17.083 24a6.282 6.282 0 004.931-2.373.99.99 0 00-1.547-1.236 4.33 4.33 0 11-.49-5.92.247.247 0 01.009.359l-1.376 1.376a.5.5 0 00.35.845h4.411a.5.5 0 00.5-.495v-4.411a.5.5 0 00-.845-.35l-1.284 1.284a.248.248 0 01-.345.006 6.253 6.253 0 00-4.309-1.7 6.31 6.31 0 000 12.619z\"/><path d=\"M.629 18.062h1.792a.247.247 0 01.246.227l.438 5.257A.5.5 0 003.6 24h3.957a.494.494 0 00.493-.454l.438-5.257a.247.247 0 01.247-.227h.541a.248.248 0 00.247-.262c0-.077-.008-.155-.008-.233a7.17 7.17 0 011.376-4.212.248.248 0 00.043-.187 5.44 5.44 0 00-10.8.935v3.464a.5.5 0 00.495.495z\"/><path d=\"M1.371 4.206A4.207 4.207 0 105.578 0a4.212 4.212 0 00-4.207 4.206zm4.207 2.227a2.229 2.229 0 01-2.227-2.227 2.135 2.135 0 01.156-.8.249.249 0 01.36-.119 5.314 5.314 0 002.8.793 5.335 5.335 0 00.9-.077.2.2 0 01.165.045.2.2 0 01.072.155 2.229 2.229 0 01-2.226 2.23zM17.083 24a6.282 6.282 0 004.931-2.373.99.99 0 00-1.547-1.236 4.33 4.33 0 11-.49-5.92.247.247 0 01.009.359l-1.376 1.376a.5.5 0 00.35.845h4.411a.5.5 0 00.5-.495v-4.411a.5.5 0 00-.845-.35l-1.284 1.284a.248.248 0 01-.345.006 6.253 6.253 0 00-4.309-1.7 6.31 6.31 0 000 12.619z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});