define("ember-svg-jar/inlined/camping-tent-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camping-tent-1</title><path d=\"M10.775 6.7a.25.25 0 010 .267L9.136 9.576a.25.25 0 00.212.383h5.3a.249.249 0 00.211-.383l-1.635-2.608a.25.25 0 010-.267l3.26-5.123A1 1 0 1014.8.5l-2.589 4.069a.251.251 0 01-.422 0L9.2.5a1 1 0 10-1.685 1.078zM16.121 11.576a.25.25 0 00-.212-.117H8.091a.25.25 0 00-.212.117l-.943 1.5a.25.25 0 00.212.383h9.7a.25.25 0 00.212-.383zM22.941 22.427l-4.62-7.351a.25.25 0 00-.212-.117H5.89a.249.249 0 00-.211.117l-4.621 7.351a1 1 0 00.847 1.532h5.881a.249.249 0 00.224-.138l3.181-6.362a.94.94 0 011.619 0l3.18 6.361a.249.249 0 00.224.138H22.1a1 1 0 00.846-1.532z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});