define("ember-svg-jar/inlined/army-symbol-armor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-symbol-armor</title><path d=\"M22 3.5H2a2 2 0 00-2 2v13a2 2 0 002 2h20a2 2 0 002-2v-13a2 2 0 00-2-2zM22 18a.5.5 0 01-.5.5h-19A.5.5 0 012 18V6a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M15.5 7h-7a5 5 0 000 10h7a5 5 0 000-10zm0 8h-7a3 3 0 010-6h7a3 3 0 010 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});