define("ember-svg-jar/inlined/single-woman-focus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-focus</title><path d=\"M7.7 22l-5.2.039a.5.5 0 01-.5-.5v-5.234a1 1 0 00-2 0v5.738A1.959 1.959 0 001.957 24H7.7a1 1 0 000-2zM1 8.7a1 1 0 001-1l-.04-5.2a.5.5 0 01.5-.5H7.7a1 1 0 000-2H1.957A1.959 1.959 0 000 1.957V7.7a1 1 0 001 1zM23 15.305a1 1 0 00-1 1l.04 5.191a.5.5 0 01-.5.5H16.3a1 1 0 100 2h5.739A1.959 1.959 0 0024 22.043v-5.738a1 1 0 00-1-1zM24 1.957A1.959 1.959 0 0022.043 0H16.3a1 1 0 000 2l5.2-.039a.5.5 0 01.5.5V7.7a1 1 0 002 0zM6.789 18.144a.5.5 0 00.35.856h10a.5.5 0 00.351-.855A7.548 7.548 0 0012.142 16a7.567 7.567 0 00-5.353 2.144zM17.4 9.545a5.3 5.3 0 10-10.594 0v1.249a4.57 4.57 0 01-.642 2.512 1 1 0 001.68 1.085 5.128 5.128 0 00.454-.9.252.252 0 01.412-.082 4.727 4.727 0 006.786 0 .25.25 0 01.412.082 5.128 5.128 0 00.454.9 1 1 0 001.681-1.085A4.558 4.558 0 0117.4 10.8zm-5.3 3.3a2.706 2.706 0 01-2.741-2.351.5.5 0 01.324-.577 5.7 5.7 0 002.245-1.439.254.254 0 01.181-.077.25.25 0 01.18.077 5.719 5.719 0 002.23 1.437.5.5 0 01.323.576 2.707 2.707 0 01-2.742 2.354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});