define("ember-svg-jar/inlined/travel-woman-luggage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>travel-woman-luggage</title><path d=\"M11.2 11.9a4.3 4.3 0 00-1.688-2.424.495.495 0 01.019-.821 4.7 4.7 0 10-5.062 0 .495.495 0 01.019.821A4.3 4.3 0 002.8 11.9l-1.722 6.531a.5.5 0 00.479.621h2.157a.494.494 0 01.485.4l.752 3.757a.991.991 0 00.97.794h2.158a.99.99 0 00.97-.8l.751-3.754a.5.5 0 01.485-.4h2.158a.5.5 0 00.479-.621zM7 7.423A2.725 2.725 0 014.278 4.7v-.011a.063.063 0 01.052-.056 4.547 4.547 0 002.339-1.081.5.5 0 01.662 0 4.543 4.543 0 002.338 1.081.063.063 0 01.052.056V4.7A2.726 2.726 0 017 7.423zM21.5 13h-2a.5.5 0 01-.5-.5V8.75a1 1 0 00-2 0V19.5a.5.5 0 00.5.5 2.5 2.5 0 012.5 2.5.5.5 0 00.5.5h1a1.5 1.5 0 001.5-1.5v-7a1.5 1.5 0 00-1.5-1.5z\"/><circle cx=\"17.5\" cy=\"22.5\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});