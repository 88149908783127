define("ember-svg-jar/inlined/lucide-fan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.827 16.379a6.082 6.082 0 01-8.618-7.002l5.412 1.45a6.082 6.082 0 017.002-8.618l-1.45 5.412a6.082 6.082 0 018.618 7.002l-5.412-1.45a6.082 6.082 0 01-7.002 8.618l1.45-5.412zM12 12v.01\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});