define("ember-svg-jar/inlined/e-commerce-apparel-browse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-apparel-browse</title><path d=\"M16 16v-3.25a.25.25 0 01.25-.25h1.25a.5.5 0 00.485-.621l-.81-3.243A1.5 1.5 0 0015.719 7.5H13.5a.5.5 0 00-.5.5 1 1 0 01-2 0 .5.5 0 00-.5-.5H8.281a1.5 1.5 0 00-1.456 1.136l-.81 3.243a.5.5 0 00.485.621h1.25a.25.25 0 01.25.25V16a.5.5 0 00.5.5h7a.5.5 0 00.5-.5zM20.8 18.6l-2-1.5a.5.5 0 00-.8.4v.5a.25.25 0 01-.25.25H16.5a.75.75 0 000 1.5h1.25A.25.25 0 0118 20v.5a.5.5 0 00.276.447.508.508 0 00.224.053.5.5 0 00.3-.1l2-1.5a.5.5 0 000-.8zM7.5 18.25H6.25A.25.25 0 016 18v-.5a.5.5 0 00-.8-.4l-2 1.5a.5.5 0 000 .8l2 1.5a.5.5 0 00.3.1.508.508 0 00.224-.053A.5.5 0 006 20.5V20a.25.25 0 01.25-.25H7.5a.75.75 0 000-1.5z\"/><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2zM6 2.5a1 1 0 111 1 1 1 0 01-1-1zm3.5 0a1 1 0 111 1 1 1 0 01-1-1zm-6-1a1 1 0 11-1 1 1 1 0 011-1zM2.5 22a.5.5 0 01-.5-.5V6a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v15.5a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});