define("ember-svg-jar/inlined/shopping-basket-like", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-basket-like</title><path d=\"M1.714 8.25a.25.25 0 00-.245.3l.441 2.12a.248.248 0 01-.053.211 8 8 0 1013.6 8.028.249.249 0 01.232-.159H20a.5.5 0 00.489-.4l2.042-9.8a.25.25 0 00-.245-.3zM8 22a6 6 0 116-6 6.006 6.006 0 01-6 6zM23.5 7.25a.5.5 0 00.5-.5v-1a1.5 1.5 0 00-1.5-1.5h-1.193a.247.247 0 01-.2-.105L18.518.524a1.251 1.251 0 00-2.036 1.453l1.342 1.878a.249.249 0 01-.2.4H6.156a.249.249 0 01-.217-.374L7.085 1.87A1.25 1.25 0 004.915.63l-2 3.494a.251.251 0 01-.215.126H1.5A1.5 1.5 0 000 5.75v1a.5.5 0 00.5.5z\"/><path d=\"M11.49 18.129l.649-2.163.049-.173a1.206 1.206 0 00-1.177-1.475H9.275a.212.212 0 01-.166-.081.2.2 0 01-.035-.175l.313-1.179a1.109 1.109 0 00-.211-.976 1.149 1.149 0 00-1.763.059l-1.729 2.44a.211.211 0 01-.171.088h-.3a1 1 0 00-1 1v2.657a1 1 0 00.556.9 6 6 0 003.18.949c.153 0 .316 0 .5-.007.251-.005.542-.012.891-.012a2.209 2.209 0 002.15-1.852z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});