define("ember-svg-jar/inlined/casino-chips-double", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>casino-chips-double</title><circle cx=\"9\" cy=\"8.954\" r=\"3.75\"/><path d=\"M10.142 3.829a5.245 5.245 0 012.271 1.139.5.5 0 00.625.02l2.238-1.678a.5.5 0 00.051-.756 8.981 8.981 0 00-5-2.5.5.5 0 00-.574.5v2.8a.5.5 0 00.389.475zM16.917 4.671a.5.5 0 00-.333-.25.494.494 0 00-.407.088L13.94 6.186a.5.5 0 00-.156.607 5.246 5.246 0 010 4.323.5.5 0 00.156.606l2.237 1.678a.5.5 0 00.74-.162 9.013 9.013 0 000-8.567zM4.962 4.988a.5.5 0 00.625-.02 5.232 5.232 0 012.272-1.139.5.5 0 00.391-.488V.546a.5.5 0 00-.573-.5 8.981 8.981 0 00-5 2.5.5.5 0 00.052.756zM15.474 14.965a.5.5 0 00-.2-.367l-2.236-1.678a.5.5 0 00-.625.02 5.232 5.232 0 01-2.272 1.139.5.5 0 00-.391.488v2.795a.5.5 0 00.574.5 8.981 8.981 0 005-2.5.5.5 0 00.15-.397zM7.859 14.079a5.243 5.243 0 01-2.272-1.139.5.5 0 00-.625-.02L2.725 14.6a.5.5 0 00-.052.756 8.981 8.981 0 005 2.5.5.5 0 00.573-.5v-2.8a.5.5 0 00-.387-.477zM4.216 11.115a5.244 5.244 0 010-4.322.5.5 0 00-.156-.607L1.823 4.509a.5.5 0 00-.74.162 9.011 9.011 0 000 8.566.5.5 0 00.74.163l2.237-1.678a.5.5 0 00.156-.607zM16.051 19.661a1 1 0 00-1.414-1.414l-.616.616a.25.25 0 01-.354 0l-.616-.616a1 1 0 00-1.414 1.414l.616.616a.25.25 0 010 .354l-.616.616a1 1 0 101.414 1.414l.616-.616a.25.25 0 01.354 0l.616.616a1 1 0 001.414-1.414l-.616-.616a.25.25 0 010-.354zM23 21.954h-1.506a.25.25 0 01-.165-.437l.662-.584c.876-.772 1.869-1.647 1.508-3.428a3.188 3.188 0 00-6.311.637 1 1 0 002 0 1.188 1.188 0 012.351-.239c.121.6-.02.781-.87 1.529L17.526 22.2a1 1 0 00.662 1.75H23a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});