define("ember-svg-jar/inlined/people-man-glasses-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-glasses-2</title><path d=\"M0 23a1 1 0 002 0v-1.606a.98.98 0 01.376-.785 11.026 11.026 0 012.786-1.5.25.25 0 01.338.234v4.4a.25.25 0 00.25.25h12.5a.25.25 0 00.25-.25v-4.4a.25.25 0 01.338-.234 11 11 0 012.785 1.5.978.978 0 01.377.785V23a1 1 0 002 0v-1.606a2.959 2.959 0 00-1.164-2.374C21.358 17.892 17.985 16 12 16s-9.358 1.892-10.837 3.02A2.961 2.961 0 000 21.394zm14.786-4.838a.249.249 0 01.162.408 3.755 3.755 0 01-5.9 0 .251.251 0 01.163-.409 24.111 24.111 0 015.572 0z\"/><path d=\"M18.854 10.412a.242.242 0 01.067-.126l.347-.347A2.484 2.484 0 0020 8.171V6.5a1.5 1.5 0 00-1.152-1.453.25.25 0 01-.181-.167 6.994 6.994 0 00-13.334 0 .25.25 0 01-.181.167A1.5 1.5 0 004 6.5v1.671a2.484 2.484 0 00.732 1.768l.347.347a.242.242 0 01.067.126 7 7 0 0013.708 0zM12 2a5 5 0 014.4 2.631.25.25 0 01-.22.369H7.824a.25.25 0 01-.22-.369A5 5 0 0112 2zm0 12a4.987 4.987 0 01-4.357-2.58.25.25 0 01.154-.363.835.835 0 00.071-.022l3.962-1.441a.505.505 0 01.341 0l3.961 1.441c.026.01.053.018.079.027a.249.249 0 01.158.146.255.255 0 01-.014.214A4.986 4.986 0 0112 14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});