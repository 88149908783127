define("ember-svg-jar/inlined/picture-polaroid-album", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>picture-polaroid-album</title><path d=\"M8.75 0h-7A1.752 1.752 0 000 1.75v7a1.752 1.752 0 001.75 1.75h7a1.752 1.752 0 001.75-1.75v-7A1.752 1.752 0 008.75 0zm-7 1.5h7a.25.25 0 01.25.25v4.5a.25.25 0 01-.25.25h-7a.25.25 0 01-.25-.25v-4.5a.25.25 0 01.25-.25zM8.75 13.5h-7A1.752 1.752 0 000 15.25v7A1.752 1.752 0 001.75 24h7a1.752 1.752 0 001.75-1.75v-7a1.752 1.752 0 00-1.75-1.75zm-7 1.5h7a.25.25 0 01.25.25v4.5a.25.25 0 01-.25.25h-7a.25.25 0 01-.25-.25v-4.5a.25.25 0 01.25-.25zM22.25 0h-7a1.752 1.752 0 00-1.75 1.75v7a1.752 1.752 0 001.75 1.75h7A1.752 1.752 0 0024 8.75v-7A1.752 1.752 0 0022.25 0zm-7 1.5h7a.25.25 0 01.25.25v4.5a.25.25 0 01-.25.25h-7a.25.25 0 01-.25-.25v-4.5a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});