define("ember-svg-jar/inlined/measure-ruler-divider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>measure-ruler-divider</title><path d=\"M15.792 20.7a1.719 1.719 0 00.365-1.834L11.95 8.326a.5.5 0 00-.85-.126 3.5 3.5 0 01-1.889 1.19.5.5 0 00-.346.671l4.029 10.071a1.708 1.708 0 001.5 1.108l.782 2.032a.75.75 0 001.4-.539zM7.542 9.39A3.5 3.5 0 015.654 8.2a.5.5 0 00-.852.13L.593 18.875A1.724 1.724 0 00.96 20.7l-.781 2.033a.75.75 0 001.4.539l.783-2.032a1.707 1.707 0 001.495-1.1l4.03-10.079a.5.5 0 00-.345-.671zM10.875 6.008v-2.5a1 1 0 00-1-1 .5.5 0 01-.5-.5v-1a1 1 0 00-2 0v1a.5.5 0 01-.5.5 1 1 0 00-1 1v2.5a2.5 2.5 0 105 0zM20.122 5.508h3.249a.5.5 0 00.5-.5V1.5a1.5 1.5 0 00-1.5-1.5h-5a1.5 1.5 0 00-1.5 1.5V14a.511.511 0 00.035.185l2.762 6.915a3.216 3.216 0 01.028 2.231.5.5 0 00.473.666l3.2-.008a1.5 1.5 0 001.5-1.5V18.5a.5.5 0 00-.5-.5h-3.247a.75.75 0 010-1.5h3.249a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-2.249a.75.75 0 010-1.5h2.249a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-3.249a.75.75 0 010-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});