define("ember-svg-jar/inlined/single-neutral-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-check</title><path d=\"M5.506 8.5a5.506 5.506 0 00-5.5 5.5v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229l.443 5.313a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.7 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.504-5.5z\"/><circle cx=\"5.506\" cy=\"4\" r=\"3.5\"/><path d=\"M23.786 1.657a.735.735 0 00-.375-.19 1 1 0 00-1.112.2l-4.616 4.617a.251.251 0 01-.354 0L16.15 5.105a1 1 0 00-1.414 1.414L16.313 8.1a1.69 1.69 0 002.386 0l5.014-5.014a.984.984 0 00.274-.8.734.734 0 00-.201-.629z\"/><path d=\"M23.055 7.19a1 1 0 00-1.254.656A4.5 4.5 0 1117.506 2a1 1 0 000-2 6.5 6.5 0 106.2 8.443 1 1 0 00-.651-1.253zM5.506 8.5a5.506 5.506 0 00-5.5 5.5v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229l.443 5.313a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.7 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.504-5.5z\"/><circle cx=\"5.506\" cy=\"4\" r=\"3.5\"/><path d=\"M23.786 1.657a.735.735 0 00-.375-.19 1 1 0 00-1.112.2l-4.616 4.617a.251.251 0 01-.354 0L16.15 5.105a1 1 0 00-1.414 1.414L16.313 8.1a1.69 1.69 0 002.386 0l5.014-5.014a.984.984 0 00.274-.8.734.734 0 00-.201-.629z\"/><path d=\"M23.055 7.19a1 1 0 00-1.254.656A4.5 4.5 0 1117.506 2a1 1 0 000-2 6.5 6.5 0 106.2 8.443 1 1 0 00-.651-1.253z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});