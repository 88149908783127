define("ember-svg-jar/inlined/gesture-swipe-horizontal-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-horizontal-left</title><path d=\"M23.6 9.742a12.341 12.341 0 00-6.213-3.058 1 1 0 00-.442 1.951 10.59 10.59 0 015.2 2.473A1 1 0 1023.6 9.742zM6.378 6.685a16.918 16.918 0 00-3.6 1.225.25.25 0 01-.312-.08l-.494-.691a.75.75 0 00-1.35.313l-.478 2.869a.75.75 0 00.616.863l2.87.478a.751.751 0 00.733-1.176l-.413-.579a.249.249 0 01.1-.374 15.234 15.234 0 012.767-.9 1 1 0 00-.442-1.951zM11.739 4a3.5 3.5 0 00-3.5 3.5v12a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-12a3.5 3.5 0 00-3.5-3.5zm1.5 4.625a.375.375 0 01-.375.375h-2.25a.374.374 0 01-.375-.375V7.5a1.5 1.5 0 113 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});