define("ember-svg-jar/inlined/ee-icon-question-shared-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>picture-landscape</title><g fill=\"none\" fill-rule=\"evenodd\"><rect fill=\"#5E7AF9\" width=\"54\" height=\"54\" rx=\"5\"/><path fill=\"#FFF\" d=\"M7 6h40v4H7zM43.744 17.588c-.07-.079-.2-.127-.341-.127h-3.806c-.14 0-.271.048-.34.127-.07.078-.067.174.006.25l1.902 2.001a.47.47 0 00.335.12.47.47 0 00.335-.12l1.903-2c.073-.077.075-.173.006-.251z\"/><path stroke=\"#FFF\" stroke-width=\"2\" d=\"M8 15h38v7H8z\"/><path fill=\"#FFF\" d=\"M7 30h40v4H7zM43.744 41.588c-.07-.079-.2-.127-.341-.127h-3.806c-.14 0-.271.048-.34.127-.07.078-.067.174.006.25l1.902 2.001a.47.47 0 00.335.12.47.47 0 00.335-.12l1.903-2c.073-.077.075-.173.006-.251z\"/><path stroke=\"#FFF\" stroke-width=\"2\" d=\"M8 39h38v7H8z\"/></g>",
    "attrs": {
      "width": "54",
      "height": "54",
      "viewBox": "0 0 54 54",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});