define("ember-svg-jar/inlined/human-resources-woman-previous", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-woman-previous</title><path d=\"M23.992 11.609a10.71 10.71 0 00-11.1-10.315 10.843 10.843 0 00-9.735 7.161.248.248 0 01-.253.163L.538 8.436a.5.5 0 00-.454.776l3.068 4.6a.5.5 0 00.382.222h.034a.5.5 0 00.376-.17l3.58-4.092a.5.5 0 00-.338-.827L5.52 8.82a.251.251 0 01-.208-.354 8.813 8.813 0 017.651-5.173 8.716 8.716 0 019.031 8.39 8.931 8.931 0 01-2.739 6.752.251.251 0 01-.24.059.247.247 0 01-.173-.175 5.777 5.777 0 00-3.489-3.874.25.25 0 00-.306.107L13.62 17a.25.25 0 01-.432 0l-1.468-2.517a.251.251 0 00-.3-.111 5.76 5.76 0 00-3.885 5.439v.011a1 1 0 00.214.609c.073.093 1.847 2.282 5.889 2.282a10.592 10.592 0 0010.354-11.104z\"/><path d=\"M13.483 14.311a4.393 4.393 0 001.977-.468 4.442 4.442 0 00.847-.557.25.25 0 01.372.059 3.807 3.807 0 00.544.678 1 1 0 001.406-1.423c-.315-.312-.734-.93-.734-2.7a4.413 4.413 0 00-8.825 0c0 1.772-.419 2.39-.734 2.7a1 1 0 001.4 1.424 3.771 3.771 0 00.544-.678.251.251 0 01.373-.059 4.437 4.437 0 00.617.433 4.386 4.386 0 002.213.591zm-1.212-4.593a2.64 2.64 0 001.032-.86.25.25 0 01.4-.016 4.351 4.351 0 00.988.876 3.066 3.066 0 00.953.438.249.249 0 01.182.3 2.412 2.412 0 01-4.725-.151.25.25 0 01.2-.288 3.69 3.69 0 00.97-.299z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});