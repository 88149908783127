define("ember-svg-jar/inlined/diagram-wave-both-direction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-wave-both-direction</title><path d=\"M4.871 15.47a1.44 1.44 0 00-1.569.312L.423 18.661A1.459 1.459 0 000 19.68a1.476 1.476 0 00.423 1.02L3.3 23.578a1.44 1.44 0 002.46-1.018V16.8a1.439 1.439 0 00-.889-1.33zM18.067 12.041a1.44 1.44 0 00-2 2.071 2.411 2.411 0 01.626 1.011 1.44 1.44 0 002.749-.86 5.306 5.306 0 00-1.375-2.222zM8.069 6.078a1.432 1.432 0 00.574-.12 2.383 2.383 0 01.957-.2h.343a1.441 1.441 0 000-2.881H9.6a5.247 5.247 0 00-2.107.438 1.44 1.44 0 00.576 2.76zM14.743 5.761h1.92a1.441 1.441 0 000-2.881h-1.92a1.441 1.441 0 000 2.881zM13.988 12a1.439 1.439 0 00-1.44-1.44h-1.919a1.44 1.44 0 100 2.879h1.919A1.44 1.44 0 0013.988 12zM15.806 17.787a2.415 2.415 0 01-1.106.435 1.44 1.44 0 00.177 2.869 1.58 1.58 0 00.18-.011 5.313 5.313 0 002.43-.954 1.44 1.44 0 00-1.681-2.339zM10.08 18.24H8.16a1.44 1.44 0 100 2.88h1.92a1.44 1.44 0 000-2.88zM7.6 9.493a2.4 2.4 0 01-.39-1.117 1.44 1.44 0 00-2.869.253 5.27 5.27 0 00.869 2.465A1.44 1.44 0 107.6 9.493zM23.577 3.3L20.7.422a1.44 1.44 0 00-2.46 1.018V7.2a1.44 1.44 0 002.46 1.019l2.879-2.88a1.439 1.439 0 000-2.038z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});