define("ember-svg-jar/inlined/old-people-man-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>old-people-man-1</title><path d=\"M2.985 10.831a.032.032 0 01.015.027v.017a3 3 0 003 3 2.8 2.8 0 00.5-.048.249.249 0 01.269.136 7.022 7.022 0 005.251 3.662 7.021 7.021 0 005.248-3.659.251.251 0 01.268-.136 2.661 2.661 0 00.463.045 3 3 0 003-3v-.026a.016.016 0 01.008-.014 4 4 0 00-.718-7.248.248.248 0 01-.141-.119A3.022 3.022 0 0017.5 1.875a2.212 2.212 0 00-.318.027.25.25 0 01-.208-.068A6.864 6.864 0 0012.041.125 6.9 6.9 0 007.092 1.84a.253.253 0 01-.21.067 2.535 2.535 0 00-.382-.032 3.022 3.022 0 00-2.649 1.593.248.248 0 01-.141.119 4 4 0 00-.725 7.244zm9.056-9.206a5.964 5.964 0 013.244.812.247.247 0 01.115.18.251.251 0 01-.067.2 2.974 2.974 0 001.877 5.03.156.156 0 01.141.166 7.256 7.256 0 01-.071.738.753.753 0 00.406.8.626.626 0 01.1.439 1.277 1.277 0 01-.559 1.1 1.744 1.744 0 00-1 1.3c-.378 2.249-3.176 3.739-4.208 3.739s-3.831-1.49-4.209-3.74a1.747 1.747 0 00-1-1.3 1.279 1.279 0 01-.559-1.1.615.615 0 01.105-.438.751.751 0 00.4-.795 8.728 8.728 0 01-.082-.647.251.251 0 01.216-.271A3.011 3.011 0 009.5 4.875a2.967 2.967 0 00-.8-2.027.252.252 0 01-.065-.2.248.248 0 01.112-.179 5.964 5.964 0 013.294-.844zM7.762 17.449a.5.5 0 00-.448-.038L2.92 19.162A4.519 4.519 0 000 23.375a.5.5 0 00.5.5H8a.5.5 0 00.5-.545l-.5-5.5a.5.5 0 00-.238-.381zM21.09 19.166l-4.4-1.755a.5.5 0 00-.69.419l-.5 5.5a.5.5 0 00.5.545h7.5a.5.5 0 00.5-.5 4.515 4.515 0 00-2.91-4.209zM12.464 19.439a.521.521 0 00-.928 0l-1.5 3.75a.5.5 0 00.464.686h3a.5.5 0 00.464-.686z\"/><path d=\"M10.231 12.741a.751.751 0 00.29 1.02 2.717 2.717 0 00.5.214 2.872 2.872 0 001.005.15 3.346 3.346 0 00.993-.148 2.669 2.669 0 00.5-.217.75.75 0 10-.734-1.307 1.2 1.2 0 01-.22.093 1.834 1.834 0 01-.544.079 1.837 1.837 0 01-.55-.081 1.2 1.2 0 01-.222-.093.749.749 0 00-1.018.29zM8.451 10.161a.75.75 0 001.06.039.557.557 0 01.557 0 .75.75 0 101.022-1.1 2.019 2.019 0 00-2.6 0 .75.75 0 00-.039 1.061zM13.932 10.2a.557.557 0 01.557 0 .75.75 0 101.022-1.1 2.019 2.019 0 00-2.6 0 .75.75 0 001.022 1.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});