define("ember-svg-jar/inlined/multiple-actions-monitor.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-monitor</title><path d=\"M13 9.5h3.784a.5.5 0 00.209-.955 5.991 5.991 0 00-4.967-.012.5.5 0 00-.281.341.557.557 0 00.163.509.5.5 0 00.488.18A3.086 3.086 0 0113 9.5z\"/><ellipse cx=\"14.013\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M5.989 8A6.014 6.014 0 000 13.333.6.6 0 00.6 14H9a.5.5 0 00.5-.5V13a3.448 3.448 0 01.937-2.359.5.5 0 000-.677A6.01 6.01 0 005.989 8z\"/><ellipse cx=\"5.513\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M20 22h-1.25a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25H22a2 2 0 002-2v-6a2 2 0 00-2-2h-9a2 2 0 00-2 2v6a2 2 0 002 2h3.25a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H15a.988.988 0 00-.847.5.977.977 0 00-.153.5 1 1 0 001 1h5a1 1 0 001-1 .977.977 0 00-.153-.5A.988.988 0 0020 22zm1.75-9a.25.25 0 01.25.25v5.5a.249.249 0 01-.25.25h-8.5a.25.25 0 01-.25-.25v-5.5a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});