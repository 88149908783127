define("ember-svg-jar/inlined/move-shrink-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>move- shrink-vertical</title><path d=\"M20.384.8a1.249 1.249 0 00-1.768 0l-6.439 6.442a.25.25 0 01-.354 0L5.384.8a1.25 1.25 0 10-1.768 1.77l7.5 7.5a1.251 1.251 0 001.768 0l7.5-7.5a1.249 1.249 0 000-1.77zM12.884 13.928a1.249 1.249 0 00-1.768 0l-7.5 7.5A1.25 1.25 0 105.384 23.2l6.439-6.439a.25.25 0 01.354 0l6.439 6.439a1.25 1.25 0 101.768-1.767z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});