define("ember-svg-jar/inlined/data-file-graph-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-graph-alternate</title><path d=\"M16.293.293A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.465a.5.5 0 01.353.146l4.536 4.536a.5.5 0 01.146.353z\"/><path d=\"M7.941 14.66l2.576-2.575a.25.25 0 01.3-.038l1.664 1a1 1 0 001.222-.151L16.9 9.707a1 1 0 00-1.414-1.414l-2.5 2.5a.248.248 0 01-.3.037l-1.664-1a1 1 0 00-1.221.15l-3.274 3.266a1 1 0 001.414 1.414zM16.75 16.438H7a1 1 0 000 2h9.75a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});