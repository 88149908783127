define("ember-svg-jar/inlined/password-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>password-key</title><path d=\"M21 0H3a3 3 0 00-3 3v15a3 3 0 003 3h6.5a1 1 0 000-2H3a1 1 0 01-1-1V7.5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5V9a1 1 0 002 0V3a3 3 0 00-3-3zM11 3.5a1 1 0 11-1-1 1 1 0 011 1zm-3 0a1 1 0 11-1-1 1 1 0 011 1zm-3 0a1 1 0 11-1-1 1 1 0 011 1z\"/><path d=\"M11.942 9.913a1 1 0 00-1.414.03L7.118 13.5a.25.25 0 01-.181.077.246.246 0 01-.18-.077l-.535-.558a1 1 0 00-1.444 1.384l1.438 1.5a1 1 0 001.443 0l4.313-4.5a1 1 0 00-.03-1.413zM17.382 16.118a.25.25 0 01-.25.063A3.853 3.853 0 0016 16a4 4 0 104 4 3.841 3.841 0 00-.181-1.131.249.249 0 01.063-.25l4.045-4.046A.248.248 0 0024 14.4v-2.15a.25.25 0 00-.25-.25H21.6a.249.249 0 00-.177.073zM16 21.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});