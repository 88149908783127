define("ember-svg-jar/inlined/align-bottom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>align-bottom</title><path d=\"M22 20.25H2a1 1 0 000 2h20a1 1 0 000-2z\"/><rect x=\"13\" y=\"7.75\" width=\"6\" height=\"11\" rx=\"1.5\" ry=\"1.5\"/><rect x=\"5\" y=\"1.75\" width=\"6\" height=\"17\" rx=\"1.5\" ry=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});