define("ember-svg-jar/inlined/smart-watch-square-microphone-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-microphone-alternate</title><path d=\"M21 6a3 3 0 00-3-3h-1.078a.249.249 0 01-.24-.182L15.98.365A.5.5 0 0015.5 0h-7a.5.5 0 00-.481.363l-.7 2.455A.248.248 0 017.077 3H6a3 3 0 00-3 3v12a3 3 0 003 3h1.077a.249.249 0 01.24.181l.7 2.456A.5.5 0 008.5 24h7a.5.5 0 00.48-.363l.7-2.456a.25.25 0 01.24-.181H18a3 3 0 003-3zm-2 12a1 1 0 01-1 1H6a1 1 0 01-1-1V6a1 1 0 011-1h12a1 1 0 011 1z\"/><path d=\"M12 13a2 2 0 002-2V9a2 2 0 10-4 0v2a2 2 0 002 2z\"/><path d=\"M15.5 10.752a.75.75 0 00-.75.75 2.75 2.75 0 11-5.5 0 .75.75 0 00-1.5 0 4.258 4.258 0 003.305 4.144.25.25 0 01.2.243V17a.75.75 0 001.5 0v-1.111a.249.249 0 01.194-.243A4.258 4.258 0 0016.25 11.5a.75.75 0 00-.75-.748z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});