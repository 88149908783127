define("ember-svg-jar/inlined/railroad-fast-train", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>railroad-fast-train</title><path d=\"M23.25 3.121H14A6.9 6.9 0 009.712 4.6l-5.26 5.42a.25.25 0 00.042.383 5.783 5.783 0 012.6 4.823 5.756 5.756 0 01-.394 2.065.247.247 0 00.027.231.25.25 0 00.206.108h7.456a.253.253 0 00.2-.1l2.593-3.359a3.5 3.5 0 012.38-.887h4.188a.25.25 0 00.25-.25V3.871a.75.75 0 00-.75-.75zM17.4 7.483l-1.667 1.671a2.1 2.1 0 01-1.682.846H8.428a.5.5 0 01-.358-.85l2.3-2.369a.506.506 0 01.359-.151h6.321a.5.5 0 01.354.853zM4.705 17.624a.249.249 0 00.231-.118 4.355 4.355 0 00.656-2.28 4.3 4.3 0 00-2.253-3.775.251.251 0 00-.3.049C1.641 13.006 1.5 13.4 1.5 14.371a3.244 3.244 0 003.205 3.253z\"/><path d=\"M23.75 14.786h-4.186a1.953 1.953 0 00-1.544.758l-1.3 1.684a.25.25 0 00.2.4h6.33a.75.75 0 00.75-.75v-1.842a.25.25 0 00-.25-.25zM23 18.879H1a1 1 0 000 2h22a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});