define("ember-svg-jar/inlined/send-email-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>send-email-1-alternate</title><path d=\"M23.429.5a1.512 1.512 0 00-1.661-.155L1.069 11.48a2.023 2.023 0 00.161 3.642l3.244 1.391 2.244 6.023c.35.94 1.763 2.1 3.509.517l3.123-2.736 2.769 1.183a2.025 2.025 0 002.746-1.314l5.076-18.102A1.513 1.513 0 0023.429.5zM6.478 16.165L12.9 12l-4.3 4.853a1.106 1.106 0 00-.238.5l-.432 2.709zm3.371 4.56l.287-1.785 1.176.5zm7.135-1.334a.249.249 0 01-.339.162l-5.309-2.275a.25.25 0 01-.089-.395l8-9.056a1.01 1.01 0 00-.143-1.444 1.069 1.069 0 00-1.15-.057l-12.79 8.3-2.68-1.178A.25.25 0 012.467 13L21.7 2.652z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});