define("ember-svg-jar/inlined/focus-frame", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>focus-frame</title><path d=\"M21.25 2H2.75A2.752 2.752 0 000 4.75v14.5A2.752 2.752 0 002.75 22h18.5A2.752 2.752 0 0024 19.25V4.75A2.752 2.752 0 0021.25 2zM21 12.75v4.5A1.752 1.752 0 0119.25 19h-4.5a.75.75 0 010-1.5h4.5a.25.25 0 00.25-.25v-4.5a.75.75 0 011.5 0zm-11-7a.75.75 0 01-.75.75h-4.5a.25.25 0 00-.25.25v4.5a.75.75 0 01-1.5 0v-4.5A1.752 1.752 0 014.75 5h4.5a.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});