define("ember-svg-jar/inlined/flying-insect-butterfly-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flying-insect-butterfly-1</title><path d=\"M19.747 15.81a.252.252 0 01-.075-.213.248.248 0 01.127-.187 7.517 7.517 0 001.634-1.221c2.318-2.274 2.752-5.535.969-7.283s-5.107-1.324-7.425.949c-.15.147-.291.3-.425.453a.333.333 0 01-.482.023.226.226 0 01-.07-.164V5.5A3.5 3.5 0 0117.5 2a1 1 0 000-2 5.51 5.51 0 00-5.259 3.888.249.249 0 01-.482 0A5.51 5.51 0 006.5 0a1 1 0 000 2A3.5 3.5 0 0110 5.5v2.622a.333.333 0 01-.1.238.292.292 0 01-.423-.018 7.658 7.658 0 00-.454-.487C6.705 5.582 3.381 5.156 1.6 6.906s-1.349 5.009.969 7.283A7.517 7.517 0 004.2 15.41a.251.251 0 01.052.4c-2.182 2.078-2.291 4.89-.88 6.27 1.309 1.285 3.592 1.2 5.464-.091a.252.252 0 01.215-.033.249.249 0 01.16.147 3 3 0 005.578 0 .251.251 0 01.375-.114c1.872 1.286 4.155 1.376 5.464.091 1.395-1.368 1.318-4.18-.881-6.27zM13 21a1 1 0 01-2 0V10.5a1 1 0 012 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});