define("ember-svg-jar/inlined/messages-bubble-square-edit-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-edit-alternate</title><path d=\"M8.5 14a1 1 0 00-.664.252L5 16.773V15a1 1 0 00-1-1H2.249A.251.251 0 012 13.747V2.25A.25.25 0 012.25 2h18a.25.25 0 01.25.25V8.4a.25.25 0 00.427.177l1.5-1.5A.253.253 0 0022.5 6.9V2a2 2 0 00-2-2H2a2 2 0 00-2 2v12.053A1.953 1.953 0 002 16h1v3a1 1 0 001.664.748L8.88 16h3.42a.251.251 0 00.177-.073l1.5-1.5A.25.25 0 0013.8 14zM11.5 24a.492.492 0 00.137-.019l3.1-.886a.5.5 0 00.216-.834l-2.215-2.215a.5.5 0 00-.834.216l-.886 3.1A.5.5 0 0011.5 24zM21.773 16.229l-3-3a.5.5 0 00-.707 0l-5.011 5.01a.5.5 0 000 .707l3 3a.5.5 0 00.707 0l5.011-5.01a.5.5 0 000-.707zM23.234 11.768a2.639 2.639 0 00-3.754.046.5.5 0 000 .707l3 3a.456.456 0 00.327.125.615.615 0 00.427-.171 2.624 2.624 0 000-3.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});