define("ember-svg-jar/inlined/phone-action-question-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-question-1</title><path d=\"M12 13.557V18.5a1 1 0 01-1 1H4a1 1 0 01-1-1v-11a1 1 0 011-1h4.26a.245.245 0 00.24-.241 7.924 7.924 0 01.3-1.942A.245.245 0 008.573 4H3.5A2.5 2.5 0 001 6.5v15A2.5 2.5 0 003.5 24h8a2.5 2.5 0 002.5-2.5v-7.234a.243.243 0 00-.164-.228 8.017 8.017 0 01-1.46-.687.244.244 0 00-.376.206z\"/><path d=\"M16.5 0A6.5 6.5 0 1023 6.5 6.508 6.508 0 0016.5 0zm2.625 4.875a2.64 2.64 0 01-1.875 2.516v.359a.75.75 0 01-1.5 0V7.2a1.256 1.256 0 01.988-1.221 1.125 1.125 0 10-1.363-1.1.75.75 0 01-1.5 0 2.625 2.625 0 015.25 0zM16.5 9.5a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});