define("ember-svg-jar/inlined/real-estate-action-building-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-building-badge</title><path d=\"M6.892 6.053l-3-1.5a.5.5 0 00-.447 0l-3 1.5a.5.5 0 00-.276.447V14a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5V6.5a.5.5 0 00-.277-.447zM5.169 10.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 110-1h3a.5.5 0 110 1zM14.861 2.539l-6-2.5A.5.5 0 008.169.5V14a.5.5 0 00.5.5h4.3a.047.047 0 00.047-.043 2.622 2.622 0 01.179-.773.5.5 0 000-.369 2.6 2.6 0 01-.178-.772 3.91 3.91 0 01.3-1.822.16.16 0 00-.015-.15.156.156 0 00-.132-.071h-3a.5.5 0 010-1h3a.472.472 0 01.276.087.5.5 0 00.658-.069 3.8 3.8 0 01.825-.678.5.5 0 00.241-.428V3a.5.5 0 00-.309-.461zM13.169 8h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zM23.162 13.612a.123.123 0 010-.223 1.165 1.165 0 00.662-.96 2.478 2.478 0 00-1.4-2.424 1.164 1.164 0 00-1.163.094.125.125 0 01-.191-.11 1.17 1.17 0 00-.5-1.056 2.524 2.524 0 00-2.8 0 1.166 1.166 0 00-.5 1.054.125.125 0 01-.191.111 1.162 1.162 0 00-1.163-.1 2.484 2.484 0 00-1.4 2.424 1.165 1.165 0 00.662.961.123.123 0 010 .223 1.162 1.162 0 00-.662.958A2.481 2.481 0 0015.914 17a1.162 1.162 0 001.163-.093.125.125 0 01.191.11 1.168 1.168 0 00.5 1.054 2.478 2.478 0 002.8 0 1.162 1.162 0 00.5-1.054.125.125 0 01.191-.11 1.174 1.174 0 001.163.1 2.481 2.481 0 001.4-2.427 1.165 1.165 0 00-.66-.968zM19.169 15a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/><path d=\"M22.087 18.1h-.009a.5.5 0 00-.378.219 3.345 3.345 0 01-5.065 0 .612.612 0 00-.435-.219.5.5 0 00-.527.5V23a1 1 0 001.64.767l1.539-1.283a.5.5 0 01.641 0l1.54 1.284A1 1 0 0022.669 23v-4.4a.532.532 0 00-.582-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});