define("ember-svg-jar/inlined/microphone-podcast-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>microphone-podcast-woman</title><path d=\"M15.541 13.907a1.863 1.863 0 01-2.082 0 .75.75 0 10-.918 1.186 3.249 3.249 0 003.918 0 .75.75 0 10-.918-1.186z\"/><circle cx=\"16.5\" cy=\"11.5\" r=\"1\"/><circle cx=\"12.5\" cy=\"11.5\" r=\"1\"/><path d=\"M23.059 8.614A.093.093 0 0123 8.527V8.5a8.5 8.5 0 00-17 0 .134.134 0 01-.082.124.239.239 0 00-.059.4A4.494 4.494 0 017.5 12.5v1.756a.25.25 0 00.25.25h.388a.25.25 0 01.236.166 6.5 6.5 0 0012.252 0 .252.252 0 01.236-.166H22.5a1.5 1.5 0 001.5-1.5v-3a1.5 1.5 0 00-.941-1.392zM14.5 2a6.511 6.511 0 016.333 5.033.25.25 0 01-.469.165 6.5 6.5 0 00-11.728 0 .25.25 0 01-.469-.165A6.511 6.511 0 0114.5 2zm0 15a4.505 4.505 0 01-4.5-4.5v-2.251a.25.25 0 01.236-.25 5.688 5.688 0 004.124-1.837.189.189 0 01.28 0A5.688 5.688 0 0018.764 10a.25.25 0 01.236.25v2.25a4.505 4.505 0 01-4.5 4.5zM6 17a.25.25 0 00-.25-.25H4a.5.5 0 010-1h1.75A.25.25 0 006 15.5V14a.25.25 0 00-.25-.25H4a.5.5 0 010-1h1.75A.25.25 0 006 12.5a3 3 0 00-6 0 .25.25 0 00.25.25H2a.5.5 0 010 1H.25A.25.25 0 000 14v1.5a.25.25 0 00.25.25H2a.5.5 0 010 1H.25A.25.25 0 000 17a3 3 0 001.846 2.769A.251.251 0 012 20v1.75a.25.25 0 01-.25.25H1a1 1 0 000 2h4a1 1 0 000-2h-.75a.25.25 0 01-.25-.25V20a.251.251 0 01.154-.231A3 3 0 006 17z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});