define("ember-svg-jar/inlined/common-file-dash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-dash</title><path d=\"M3 4a1 1 0 01-1-1V2a2 2 0 012-2h1.5a1 1 0 010 2H4v1a1 1 0 01-1 1zM5.5 22a1 1 0 010 2H4a2 2 0 01-2-2v-1a1 1 0 012 0v1zM20 24h-1.5a1 1 0 010-2H20v-1a1 1 0 012 0v1a2 2 0 01-2 2zM17 3a1 1 0 01-.707-.293L15.586 2H14.5a1 1 0 010-2h1.086A2.015 2.015 0 0117 .586l.707.707A1 1 0 0117 3zM3 9a1 1 0 01-1-1V6.5a1 1 0 012 0V8a1 1 0 01-1 1zM10.75 2h-1.5a1 1 0 010-2h1.5a1 1 0 010 2zM10.5 24H9a1 1 0 010-2h1.5a1 1 0 010 2zM15 24h-1.5a1 1 0 010-2H15a1 1 0 010 2zM3 13.5a1 1 0 01-1-1V11a1 1 0 012 0v1.5a1 1 0 01-1 1zM3 18.5a1 1 0 01-1-1V16a1 1 0 012 0v1.5a1 1 0 01-1 1zM21 9a1 1 0 01-1-1V6.914l-.707-.707a1 1 0 011.414-1.414l.707.707A1.986 1.986 0 0122 6.914V8a1 1 0 01-1 1zM21 13.5a1 1 0 01-1-1V11a1 1 0 012 0v1.5a1 1 0 01-1 1zM21 18.5a1 1 0 01-1-1V16a1 1 0 012 0v1.5a1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});