define("ember-svg-jar/inlined/messaging-aim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messaging-aim</title><path d=\"M8.22 15.64c-1.33 2.55-2.35 3.53-4.85 4.2a.5.5 0 00-.33.68c.36.85.62 1.4 1.18 2.6a.47.47 0 00.56.25 13.92 13.92 0 007-4.89A8.48 8.48 0 0117.22 23a.5.5 0 00.76.14c1-.85 1.32-1.1 2.24-1.84a.5.5 0 00.14-.6 12.61 12.61 0 00-5.72-5.36c0-.58 0-.73.06-1.18 2.82.74 5.28-1 6.13-1.73a.5.5 0 00.12-.62c-.24-.42-.67-1.19-1.19-2a.5.5 0 00-.76-.19c-3 2.29-4.49-.75-7.91-1.88-2.09 1.31-2.97 4.58-2.87 7.9zM18.34 3.87a3.28 3.28 0 00-6.57 0 3.28 3.28 0 006.57 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});