define("ember-svg-jar/inlined/mood-happy-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mood-happy-alternate</title><path d=\"M12.629 0A11.233 11.233 0 002.9 16.75L.147 22.575A1 1 0 001.486 23.9l5.827-2.782A11.245 11.245 0 1012.629 0zm9.321 11.193a9.238 9.238 0 01-14.073 7.941 1 1 0 00-.956-.047l-3.139 1.488a.25.25 0 01-.333-.332l1.479-3.125a1 1 0 00-.059-.955 9.248 9.248 0 1117.081-4.97z\"/><circle cx=\"7.96\" cy=\"9.513\" r=\"1.498\" transform=\"rotate(-.345 7.943 9.49)\"/><circle cx=\"17.446\" cy=\"9.456\" r=\"1.498\" transform=\"rotate(-.345 17.41 9.519)\"/><path d=\"M15.217 12.482l-4.959.029a.5.5 0 00-.5.5 2.979 2.979 0 005.958-.036.5.5 0 00-.499-.493z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});