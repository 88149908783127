define("ember-svg-jar/inlined/single-man-actions-check-2.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-check-2</title><path d=\"M.619 18.062H2.41a.246.246 0 01.246.227l.444 5.257a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227H9.3a.248.248 0 00.247-.255 7.911 7.911 0 011.315-4.61.247.247 0 00.037-.187A5.441 5.441 0 00.124 14.1v3.464a.494.494 0 00.495.498z\"/><path d=\"M5.567 0a4.206 4.206 0 104.206 4.206A4.211 4.211 0 005.567 0zm0 6.433A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM17.443 24a6.405 6.405 0 006.141-4.51.99.99 0 00-1.89-.591 4.454 4.454 0 11-4.251-5.786.99.99 0 100-1.979 6.433 6.433 0 000 12.866z\"/><path d=\"M22.187 12.784l-4.569 4.569a.244.244 0 01-.175.073.248.248 0 01-.175-.073L16.1 16.186a.99.99 0 00-1.4 1.4l1.561 1.561a1.673 1.673 0 002.361 0l4.962-4.962a.99.99 0 00-1.4-1.4zM.619 18.062H2.41a.246.246 0 01.246.227l.444 5.257a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227H9.3a.248.248 0 00.247-.255 7.911 7.911 0 011.315-4.61.247.247 0 00.037-.187A5.441 5.441 0 00.124 14.1v3.464a.494.494 0 00.495.498z\"/><path d=\"M5.567 0a4.206 4.206 0 104.206 4.206A4.211 4.211 0 005.567 0zm0 6.433A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM17.443 24a6.405 6.405 0 006.141-4.51.99.99 0 00-1.89-.591 4.454 4.454 0 11-4.251-5.786.99.99 0 100-1.979 6.433 6.433 0 000 12.866z\"/><path d=\"M22.187 12.784l-4.569 4.569a.244.244 0 01-.175.073.248.248 0 01-.175-.073L16.1 16.186a.99.99 0 00-1.4 1.4l1.561 1.561a1.673 1.673 0 002.361 0l4.962-4.962a.99.99 0 00-1.4-1.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});