define("ember-svg-jar/inlined/flying-insect-butterfly", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flying-insect-butterfly</title><path d=\"M10.81 20.29A3.507 3.507 0 018.5 17V6.665a.5.5 0 00-.257-.437L7.6 5.871A5.113 5.113 0 000 10.342c0 3.208 2.232 4.289 5.094 5.724a.25.25 0 01.01.442 3.947 3.947 0 00-2.034 3.441A3.864 3.864 0 007 24a4.174 4.174 0 003.1-1.368 4.95 4.95 0 001.011-1.7.5.5 0 00-.301-.642zm-6.06-8.54A1.75 1.75 0 116.5 10a1.749 1.749 0 01-1.75 1.75zM18.906 16.066C21.768 14.63 24 13.543 24 10.342a5.113 5.113 0 00-7.6-4.471l-.644.357a.5.5 0 00-.257.437V17a3.507 3.507 0 01-2.31 3.292.5.5 0 00-.3.638 4.966 4.966 0 001.011 1.7A4.174 4.174 0 0017 24a3.864 3.864 0 003.934-4.051 3.947 3.947 0 00-2.034-3.441.25.25 0 01.01-.442zM17.5 10a1.75 1.75 0 111.75 1.75A1.749 1.749 0 0117.5 10zM6.313 1.726L9.9 5.112a.5.5 0 01.146.469A1.973 1.973 0 0010 6v1.75a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V6a1.983 1.983 0 00-.044-.418.5.5 0 01.146-.469l3.585-3.384A1 1 0 0016.313.272l-3.808 3.6a.5.5 0 01-.377.128 2.019 2.019 0 00-.258 0 .5.5 0 01-.378-.135L7.687.273a1 1 0 10-1.374 1.453z\"/><rect x=\"10\" y=\"9.75\" width=\"4\" height=\"3.5\" rx=\".5\" ry=\".5\"/><path d=\"M14 17v-1.75a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5V17a2 2 0 004 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});