define("ember-svg-jar/inlined/people-woman-beanie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-beanie</title><path d=\"M22.685 20.218a13.023 13.023 0 00-3.665-1.894.5.5 0 00-.661.431 1.194 1.194 0 01-.088.359.476.476 0 00.285.638 11.488 11.488 0 013.218 1.659 1.224 1.224 0 01.476.983V23a.75.75 0 001.5 0v-.606a2.715 2.715 0 00-1.065-2.176zM5.75 18.5a.313.313 0 00-.407-.3 13.244 13.244 0 00-4.028 2.016A2.715 2.715 0 00.25 22.394V23a.75.75 0 001.5 0v-.606a1.224 1.224 0 01.476-.983 11.51 11.51 0 013.285-1.682.5.5 0 00.321-.6 2.481 2.481 0 01-.082-.629zM10.894 11.026a.751.751 0 00-.056-1.059 2.1 2.1 0 00-2.635 0 .75.75 0 101 1.115.632.632 0 01.627 0 .751.751 0 001.064-.056zM13.772 11.082a.632.632 0 01.627 0 .75.75 0 101-1.115 2.1 2.1 0 00-2.634 0 .75.75 0 101 1.115z\"/><path d=\"M11.5 20.975c.167.008.329.025.5.025 4.131 0 5.381-1.991 5.336-3.578a2.761 2.761 0 00-.056-.488 4.79 4.79 0 004.11-1.641.5.5 0 00-.232-.788c-1.181-.393-1.819-1.728-2.164-2.977a3.128 3.128 0 00.156-.889 2.1 2.1 0 00-.454-1.41 1.341 1.341 0 001.054-1.354v-1.75A1.319 1.319 0 0018.5 4.75h-.3C17.651 2.282 16.388 0 12 0S6.349 2.282 5.8 4.75h-.3a1.319 1.319 0 00-1.25 1.375v1.75a1.363 1.363 0 00.888 1.31 2.093 2.093 0 00-.486 1.454 3.15 3.15 0 00.267 1.18 3.808 3.808 0 01-2.077 2.686.5.5 0 00-.232.788A5.057 5.057 0 006.15 17a2.163 2.163 0 001.739-.747 7.78 7.78 0 00.8.685c-.229.019-.459.05-.688.064a1.007 1.007 0 00-1 1v.5a1.574 1.574 0 00.5 1.072V23.5A.5.5 0 008 24h3a.5.5 0 00.5-.5zM5.75 6.75a.5.5 0 01.5-.5h11.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5H6.25a.5.5 0 01-.5-.5zm1.96 6.262a1.752 1.752 0 00-1-1.306 1.281 1.281 0 01-.557-1.1.625.625 0 01.105-.44.74.74 0 00.358-.92h10.573a.74.74 0 00.358.92.634.634 0 01.1.44 1.28 1.28 0 01-.556 1.1 1.747 1.747 0 00-1 1.306 4.877 4.877 0 01-2.5 3.139c-.78.185-1.56.337-2.341.466-1.25-.396-3.227-1.729-3.54-3.605z\"/><path d=\"M13.659 13.63a.749.749 0 00-1.016-.3 1.966 1.966 0 01-1.634 0 .75.75 0 00-.711 1.321 3.433 3.433 0 003.059 0 .749.749 0 00.302-1.021z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});