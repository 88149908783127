define("ember-svg-jar/inlined/animal-print-paw-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>animal-print-paw-2</title><path d=\"M12 11.17a5.006 5.006 0 00-5 5v5.5c0 2.627 4.5 2.627 4.5 0 0-1 .289-1 .5-1s.5 0 .5 1a2.075 2.075 0 002.25 2 2.075 2.075 0 002.25-2v-5.5a5.006 5.006 0 00-5-5zM4 9.67a2 2 0 00-2 2v3a2 2 0 104 0v-3a2 2 0 00-2-2zM3 8.67h2a.5.5 0 00.447-.723l-.552-1.106a1.039 1.039 0 00-1.79 0l-.552 1.106A.5.5 0 003 8.67zM9 10.67a2 2 0 002-2v-3a2 2 0 00-4 0v3a2 2 0 002 2zM8 2.67h2a.5.5 0 00.447-.723L9.9.841a1.039 1.039 0 00-1.79 0l-.557 1.106A.5.5 0 008 2.67zM20 9.67a2 2 0 00-2 2v3a2 2 0 004 0v-3a2 2 0 00-2-2zM19 8.67h2a.5.5 0 00.447-.723L20.9 6.841a1.039 1.039 0 00-1.79 0l-.552 1.106A.5.5 0 0019 8.67zM15 3.67a2 2 0 00-2 2v3a2 2 0 104 0v-3a2 2 0 00-2-2zM14 2.67h2a.5.5 0 00.447-.723L15.9.841a1.039 1.039 0 00-1.79 0l-.552 1.106A.5.5 0 0014 2.67z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});