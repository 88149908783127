define("ember-svg-jar/inlined/office-file-pdf-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-file-pdf-1</title><path d=\"M8.451 12.322c-.105-.046-.326-.048-.326.164v3.978a.238.238 0 00.327.21 2.375 2.375 0 000-4.352zM4 12.123h-.623a.252.252 0 00-.252.252v1.246a.252.252 0 00.252.252H4a.875.875 0 000-1.75z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zM4 15.123h-.623a.252.252 0 00-.252.252V17.5a.625.625 0 01-1.25 0v-6a.625.625 0 01.625-.625H4a2.125 2.125 0 010 4.25zm3.5 3a.625.625 0 01-.625-.625v-6a.625.625 0 01.625-.625 3.625 3.625 0 010 7.25zm8-6h-2a.375.375 0 00-.375.375v1.123a.252.252 0 00.252.252H14.5a.625.625 0 010 1.25h-1.123a.252.252 0 00-.252.252V17.5a.625.625 0 01-1.25 0v-5a1.627 1.627 0 011.625-1.627h2a.625.625 0 010 1.25z\"/><path d=\"M23.707 5.705L18.293.291A1 1 0 0017.585 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.748V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.248.248 0 00-.249-.25H4.25a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.412a1 1 0 00-.293-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});