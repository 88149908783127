define("ember-svg-jar/inlined/car-actions-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-actions-settings</title><path d=\"M5.25 12.734h-4a.25.25 0 00-.25.25v.5a1.25 1.25 0 001.25 1.249h2a1.251 1.251 0 001.25-1.25v-.5a.25.25 0 00-.25-.249zM14.02 11.256l.227.053a.251.251 0 00.3-.171l.071-.234a3.272 3.272 0 015.452-1.352.25.25 0 00.428-.175V8.2a1.254 1.254 0 00-.366-.883L19.047 6.23a.493.493 0 01-.111-.167l-2.05-5.12A1.492 1.492 0 0015.493 0H5.171a1.494 1.494 0 00-1.393.942l-1.94 4.845a.5.5 0 01-.111.168L.22 7.46a.75.75 0 00-.22.53v2.5a1.249 1.249 0 001.25 1.248h10.123a.24.24 0 00.135-.04 3.252 3.252 0 012.512-.442zM4.679 4.994a.25.25 0 01-.232-.343l1-2.5A.25.25 0 015.679 2h9.306a.249.249 0 01.232.157l1 2.5a.25.25 0 01-.232.343z\"/><circle cx=\"17.748\" cy=\"17.04\" r=\"1.499\"/><path d=\"M22.529 17.352a.461.461 0 010-.624l.987-1.064a1.781 1.781 0 00-1.7-2.949l-1.412.327a.451.451 0 01-.532-.311l-.424-1.39a1.776 1.776 0 00-3.4 0l-.424 1.39a.453.453 0 01-.533.31l-1.411-.326a1.781 1.781 0 00-1.7 2.949l.988 1.064a.461.461 0 010 .624l-.988 1.064a1.782 1.782 0 001.7 2.95l1.411-.327a.451.451 0 01.533.31l.424 1.39a1.776 1.776 0 003.4 0l.424-1.39a.451.451 0 01.533-.31l1.411.327a1.781 1.781 0 001.7-2.95zm-4.781 2.685a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});