define("ember-svg-jar/inlined/file-rb-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-rb-1</title><path d=\"M8.375 13a.876.876 0 00-.875-.875h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H7.5A.877.877 0 008.375 13zM12.5 12.126h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25h.625a.875.875 0 000-1.75z\"/><path d=\"M20 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h18a1 1 0 001-1zM9.442 17.943a.625.625 0 01-.884 0l-1.506-1.506a.25.25 0 00-.427.177v.886a.625.625 0 01-1.25 0v-6A.625.625 0 016 10.876h1.375a2.21 2.21 0 012.238 1.9 2.127 2.127 0 01-1.628 2.293.246.246 0 00-.119.414l1.576 1.576a.625.625 0 010 .884zm4.707-3.281a2.13 2.13 0 01.412 1.867 2.174 2.174 0 01-2.137 1.6H11a.625.625 0 01-.625-.625v-6a.625.625 0 01.625-.628h1.424a2.174 2.174 0 012.137 1.6 2.13 2.13 0 01-.412 1.867.25.25 0 000 .319z\"/><path d=\"M12.5 15.126h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25h.625a.875.875 0 100-1.75z\"/><path d=\"M23.707 5.708L18.293.294A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.751V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.415a1 1 0 00-.293-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});