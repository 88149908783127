define("ember-svg-jar/inlined/navigation-right-circle-1-alternate-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-right-circle-1-alternate_1</title><path d=\"M12 24A12 12 0 100 12a12.013 12.013 0 0012 12zm0-2a10 10 0 1110-10 10.012 10.012 0 01-10 10z\"/><path d=\"M12.115 15.616l1.939-1.94a.25.25 0 00-.177-.426H6a1.25 1.25 0 010-2.5h7.879a.25.25 0 00.177-.427l-1.939-1.94a1.249 1.249 0 011.767-1.767l4.5 4.5a1.249 1.249 0 010 1.767l-4.5 4.5a1.249 1.249 0 01-1.767-1.767z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});