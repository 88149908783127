define("ember-svg-jar/inlined/single-neutral-actions-mobilephone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-mobilephone</title><path d=\"M0 17.248a.5.5 0 00.5.5h1.81a.249.249 0 01.249.229L3 23.289a.5.5 0 00.5.459h4a.5.5 0 00.5-.459l.443-5.312a.249.249 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 10-11 0z\"/><circle cx=\"5.5\" cy=\"3.748\" r=\"3.5\"/><path d=\"M22.526 10.752h-6.052A1.493 1.493 0 0015 12.261v9.982a1.493 1.493 0 001.474 1.509h6.052A1.493 1.493 0 0024 22.243v-9.982a1.493 1.493 0 00-1.474-1.509zM17 21.252v-8a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v8a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5zM0 17.248a.5.5 0 00.5.5h1.81a.249.249 0 01.249.229L3 23.289a.5.5 0 00.5.459h4a.5.5 0 00.5-.459l.443-5.312a.249.249 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 10-11 0z\"/><circle cx=\"5.5\" cy=\"3.748\" r=\"3.5\"/><path d=\"M22.526 10.752h-6.052A1.493 1.493 0 0015 12.261v9.982a1.493 1.493 0 001.474 1.509h6.052A1.493 1.493 0 0024 22.243v-9.982a1.493 1.493 0 00-1.474-1.509zM17 21.252v-8a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v8a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});