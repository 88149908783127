define("ember-svg-jar/inlined/fitness-jumping-rope-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fitness-jumping-rope-2</title><path d=\"M19 11h-.249a.5.5 0 01-.48-.358 14.25 14.25 0 00-3.883-5.765.5.5 0 01-.084-.644 2.7 2.7 0 00.446-1.483 2.75 2.75 0 00-5.5 0 2.7 2.7 0 00.45 1.483.5.5 0 01-.084.644 14.25 14.25 0 00-3.883 5.765.5.5 0 01-.48.358H5a1.5 1.5 0 00-1.5 1.5V21a3 3 0 006 0v-8.5A1.5 1.5 0 008.022 11a.247.247 0 01-.2-.113.252.252 0 01-.023-.228 13.608 13.608 0 013.914-4.939.5.5 0 01.575 0 13.607 13.607 0 013.912 4.941.252.252 0 01-.023.228.247.247 0 01-.2.113A1.5 1.5 0 0014.5 12.5V21a3 3 0 006 0v-8.5A1.5 1.5 0 0019 11zm-7-7.5a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});