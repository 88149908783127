define("ember-svg-jar/inlined/multiple-actions-cart.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-cart</title><path d=\"M19.631 12.49a.5.5 0 00.464-.684 6.015 6.015 0 00-8.072-3.272.5.5 0 00-.182.771 7.441 7.441 0 011.422 2.811.5.5 0 00.484.374z\"/><ellipse cx=\"14.013\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M11.377 13.321a1.745 1.745 0 01.283-.3.5.5 0 00.159-.5A6.024 6.024 0 000 13.333.6.6 0 00.6 14h10.113a.5.5 0 00.463-.312 1.8 1.8 0 01.201-.367z\"/><ellipse cx=\"5.513\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M15.769 23.989a1.423 1.423 0 10-1.423-1.423 1.424 1.424 0 001.423 1.423zM20.9 22.577A1.423 1.423 0 1019.472 24a1.425 1.425 0 001.428-1.423zM23.487 10.789a.749.749 0 00-.949.474l-.794 2.384a.5.5 0 01-.475.342H13a.5.5 0 00-.474.659l1.846 5.5a.5.5 0 00.474.341h6.231a.5.5 0 00.481-.365l2.4-8.387a.749.749 0 00-.471-.948z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});