define("ember-svg-jar/inlined/dog-bone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dog-bone</title><path d=\"M22.478 5a3.577 3.577 0 00-2.489-1.019A3.47 3.47 0 0014.066 1.5a3.608 3.608 0 00.205 5.107l-7.688 7.686a3.558 3.558 0 00-2.568-1.145 3.3 3.3 0 00-2.486.989A3.472 3.472 0 00.5 16.572a3.387 3.387 0 001 2.442 3.583 3.583 0 002.47 1 3.443 3.443 0 001.007 2.476 3.5 3.5 0 002.46 1.026 3.453 3.453 0 002.539-5.834L17.665 10a3.393 3.393 0 002.362.951A3.481 3.481 0 0022.478 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});