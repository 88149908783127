define("ember-svg-jar/inlined/road-sign-lane-crossing-left-alternate.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-lane-crossing-left -alternate</title><path d=\"M15.1 12.742l-3.5-2.625a.249.249 0 01-.1-.2V9.46a.25.25 0 01.25-.25H12a.5.5 0 00.354-.854L10.707 6.71a1 1 0 00-1.414 0L7.646 8.356A.5.5 0 008 9.21h.25a.25.25 0 01.25.25v1.583a1 1 0 00.4.8l3.5 2.625a.249.249 0 01.1.2V16.5a1 1 0 001 1h1a1 1 0 001-1v-2.957a1.007 1.007 0 00-.4-.801zM15.25 6.793h-1a1 1 0 00-1 1v.75a1 1 0 00.4.8l1 .749a1 1 0 00.6.2 1.012 1.012 0 00.449-.1 1 1 0 00.552-.9v-1.5a1 1 0 00-1.001-.999zM10.35 14.242l-1-.75a1 1 0 00-1.6.8V16a1 1 0 001 1h1a1 1 0 001-1v-.957a1.007 1.007 0 00-.4-.801z\"/><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});