define("ember-svg-jar/inlined/meal-can", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meal-can</title><path d=\"M12 0C5.65 0 2 1.46 2 4v16c0 2.5 3.74 4 10 4s10-1.5 10-4V4c0-2.54-3.65-4-10-4zm5 18.527a.5.5 0 01-.406.491 22.123 22.123 0 01-9.188 0A.5.5 0 017 18.527v-5.42a.5.5 0 01.593-.491 21.989 21.989 0 008.815 0 .5.5 0 01.592.491zM12 6C6.01 6 4 4.63 4 4s2.01-2 8-2 8 1.37 8 2-2.01 2-8 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});