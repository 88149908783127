define("ember-svg-jar/inlined/professions-man-astronaut", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-astronaut</title><path d=\"M20.5 8.25v1.031a7.417 7.417 0 01-1.807 4.989.5.5 0 00.222.806A15.227 15.227 0 0122 16.435a.5.5 0 00.75-.435V7a3.737 3.737 0 00-2.664-3.572.5.5 0 00-.59.709A8.881 8.881 0 0120.5 8.25zM5.085 15.076a.5.5 0 00.222-.806A7.417 7.417 0 013.5 9.281V8.25a8.871 8.871 0 01.989-4.083.5.5 0 00-.6-.706A3.539 3.539 0 001.25 7v9a.5.5 0 00.75.436 15.168 15.168 0 013.085-1.36zM22.533 17.994a13.914 13.914 0 00-5.233-2.327.5.5 0 00-.373.057A9.5 9.5 0 0112 17a9.5 9.5 0 01-4.926-1.276.5.5 0 00-.373-.057 13.914 13.914 0 00-5.234 2.327A2.467 2.467 0 00.5 19.971V23a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-3.029a2.467 2.467 0 00-.967-1.977zm-2.283 2.083a.75.75 0 01-.75.75h-2a.75.75 0 010-1.5h2a.75.75 0 01.75.75zm-13 0a.75.75 0 01-.75.75h-2a.75.75 0 010-1.5h2a.75.75 0 01.75.75zm1.25-.057a.511.511 0 01.5-.52h6a.511.511 0 01.5.52V22a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5z\"/><path d=\"M12 16c3.733 0 7.5-2.078 7.5-6.719V8.25A7.637 7.637 0 0012 .5a7.637 7.637 0 00-7.5 7.75v1.031C4.5 13.922 8.267 16 12 16zm0-13.422a5.534 5.534 0 015.5 5.569c0 3.076-2.462 3.712-5.5 3.712s-5.5-.636-5.5-3.712A5.534 5.534 0 0112 2.578z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});