define("ember-svg-jar/inlined/stopwatch-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>stopwatch-alternate</title><path d=\"M19.159 8.257l1.548-1.549a1 1 0 10-1.414-1.414l-1.6 1.6A9.448 9.448 0 0013 5.054V2h2a1 1 0 100-2H9a1 1 0 000 2h2v3.054a9.5 9.5 0 108.159 3.2zM12 22.5a8 8 0 118-8 8 8 0 01-8 8z\"/><path d=\"M12 8a6.5 6.5 0 106.5 6.5A6.5 6.5 0 0012 8zm.53 6.822a.759.759 0 01-1.06 0l-2.042-2.04a.75.75 0 011.061-1.061l2.041 2.042a.749.749 0 010 1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});