define("ember-svg-jar/inlined/legal-scale-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>legal-scale-document</title><path d=\"M23.727 18.063a.505.505 0 01-.247-.279L21.235 11.5a.574.574 0 01-.025-.1.765.765 0 00-.733-.648h-11a.75.75 0 00-.744.5L6.4 17.784a.505.505 0 01-.247.279.486.486 0 00-.273.437 3.561 3.561 0 107.12 0 .486.486 0 00-.273-.437.505.505 0 01-.247-.279l-1.737-4.866a.5.5 0 01.47-.668h2.476a.5.5 0 01.5.5V22a.5.5 0 01-.5.5h-1.75a.75.75 0 000 1.5h6a.75.75 0 000-1.5h-1.75a.5.5 0 01-.5-.5v-9.25a.5.5 0 01.5-.5h2.476a.5.5 0 01.471.668L17.4 17.784a.5.5 0 01-.246.279.486.486 0 00-.273.437 3.561 3.561 0 107.119 0 .486.486 0 00-.273-.437zM19.624 18a.5.5 0 01-.471-.668l.816-2.284a.5.5 0 01.941 0l.816 2.284a.5.5 0 01-.471.668zm-11 0a.5.5 0 01-.471-.668l.816-2.284a.5.5 0 01.941 0l.816 2.284a.5.5 0 01-.471.668z\"/><path d=\"M6.5 22.5A.5.5 0 006 22H2.5a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5H13a.5.5 0 01.5.5v3a2 2 0 002 2h3a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1A.5.5 0 0021 9V7a1 1 0 00-.293-.707l-6-6A1 1 0 0014 0H2a2 2 0 00-2 2v20a2 2 0 002 2h4a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});