define("ember-svg-jar/inlined/single-neutral-podium.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-podium</title><path d=\"M24 17a1 1 0 00-1-1H1a1 1 0 000 2h1.024a.25.25 0 01.243.189l1.263 5.053A1 1 0 004.5 24h15a1 1 0 00.97-.758l1.263-5.053a.25.25 0 01.243-.189H23a1 1 0 001-1zM6.007 13.332A1.052 1.052 0 007.051 14.5h10.1a.846.846 0 00.615-.266A.836.836 0 0018 13.6a6.023 6.023 0 00-11.991-.269z\"/><circle cx=\"11.991\" cy=\"3.5\" r=\"3.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});