define("ember-svg-jar/inlined/footwear-winter-boots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>footwear-winter-boots</title><path d=\"M23.5 18.823a.5.5 0 00.5-.5v-14a1.164 1.164 0 00-.867-1.1l-8.279-2.017a1.043 1.043 0 00-1.233.69l-.878 2.735a.5.5 0 00.046.407.5.5 0 00.334.236L16.805 6a1.173 1.173 0 01-.443 2.3l-4.045-.869a.5.5 0 00-.581.336l-.805 2.507a.5.5 0 00.38.644l4.064.8a1.173 1.173 0 01-.443 2.3L10.1 12.983a1 1 0 00-.8.169 3.5 3.5 0 01-2.053.671H4.5a4.505 4.505 0 00-4.5 4.5.5.5 0 00.5.5zM.9 20.323a.25.25 0 00-.212.382A4.5 4.5 0 004.5 22.823h17a2.5 2.5 0 002.421-1.875.5.5 0 00-.484-.625z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});