define("ember-svg-jar/inlined/circus-clown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>circus-clown</title><path d=\"M22.147 10.047a.252.252 0 01-.2-.19 1.743 1.743 0 00-2.768-.991.249.249 0 01-.366-.066 8.021 8.021 0 00-1.864-2.085 1 1 0 10-1.238 1.57 6 6 0 11-7.422 0 1 1 0 10-1.238-1.57A8.039 8.039 0 005.187 8.8a.248.248 0 01-.168.115.254.254 0 01-.2-.048 1.742 1.742 0 00-2.768.99.252.252 0 01-.2.19 2.236 2.236 0 000 4.406.252.252 0 01.2.19A1.741 1.741 0 004.3 15.912a.25.25 0 01.309.142A8.039 8.039 0 007.488 19.6a.249.249 0 01-.033.431A10.534 10.534 0 003.626 23.2a.5.5 0 00.4.8h15.95a.5.5 0 00.4-.8 10.529 10.529 0 00-3.83-3.165.25.25 0 01-.032-.431 8.039 8.039 0 002.881-3.549.25.25 0 01.309-.142 1.741 1.741 0 002.245-1.269.252.252 0 01.2-.19 2.236 2.236 0 000-4.406zM5 5.5h14a1 1 0 000-2h-2.5A.5.5 0 0116 3v-.5A2.5 2.5 0 0013.5 0h-3A2.5 2.5 0 008 2.5V3a.5.5 0 01-.5.5H5a1 1 0 000 2z\"/><circle cx=\"11.979\" cy=\"15\" r=\"2\"/><path d=\"M9.5 13.25a.75.75 0 00.75-.75V12a.25.25 0 01.25-.25h.5a.75.75 0 000-1.5h-.5a.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25H8a.75.75 0 000 1.5h.5a.25.25 0 01.25.25v.5a.75.75 0 00.75.75zM15.25 12.5V12a.25.25 0 01.25-.25h.5a.75.75 0 000-1.5h-.5a.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25H13a.75.75 0 000 1.5h.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});