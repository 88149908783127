define("ember-svg-jar/inlined/people-man-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-5</title><path d=\"M0 12.569a2.667 2.667 0 002.667 2.667A1.333 1.333 0 004 13.9V9.254a8.039 8.039 0 0116 0v4.4a1.333 1.333 0 001.333 1.334A2.667 2.667 0 0024 12.319V9.652a2.656 2.656 0 00-2.282-2.625.249.249 0 01-.2-.169A10.043 10.043 0 002.4 7.116a.251.251 0 01-.2.174A2.656 2.656 0 000 9.9zM.5 23.486a.5.5 0 00.5.5h22a.5.5 0 00.5-.5c0-2.575-4.461-3.473-6.875-3.959a.5.5 0 00-.552.279c-.518 1.112-2.169 1.858-4.108 1.859s-3.512-.73-4.038-1.859a.523.523 0 00-.573-.275C4.959 20.013.5 20.911.5 23.486z\"/><path d=\"M19 12.486v-2a7 7 0 00-14 0v2a7 7 0 0014 0zm-12 0v-.95a.249.249 0 01.243-.25 6.99 6.99 0 004.715-1.712.062.062 0 01.084 0 6.99 6.99 0 004.715 1.713.25.25 0 01.243.25v.949a5 5 0 01-10 0z\"/><circle cx=\"14\" cy=\"12.736\" r=\"1\"/><circle cx=\"10\" cy=\"12.736\" r=\"1\"/><path d=\"M12.082 16.355a2.976 2.976 0 00.863-.125 2.433 2.433 0 00.436-.181.64.64 0 00-.3-1.2h-2a.639.639 0 00-.3 1.2 2.451 2.451 0 00.435.18 3.024 3.024 0 00.867.126z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});