define("ember-svg-jar/inlined/style-three-pin-reel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-reel</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm5.5 6.744v.75a.5.5 0 01-.5.5h-2.086a.25.25 0 01-.177-.427l2.043-2.043a.062.062 0 01.078-.007 1.5 1.5 0 01.642 1.227zm-7.086 1.25a.25.25 0 01-.177-.427l2.043-2.043a.652.652 0 00.114-.151.249.249 0 01.219-.129h1.723a.25.25 0 01.177.427l-2.25 2.25a.251.251 0 01-.177.073zM6.5 6.744a1.5 1.5 0 011.5-1.5h1.836a.25.25 0 01.177.427l-2.25 2.25a.251.251 0 01-.177.073H7a.5.5 0 01-.5-.5zm11 6.994a1.5 1.5 0 01-1.5 1.5H8a1.5 1.5 0 01-1.5-1.5V9.994a.5.5 0 01.5-.5h10a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});