define("ember-svg-jar/inlined/signal-flags-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>signal-flags-1</title><circle cx=\"11.513\" cy=\"5.614\" r=\"2.5\"/><path d=\"M20.243 10.583a.732.732 0 00.735.667h2.272a.75.75 0 000-1.5h-2.274a.743.743 0 00-.735.817z\"/><rect x=\"20\" y=\"12.25\" width=\"4\" height=\"5\" rx=\".5\" ry=\".5\"/><path d=\"M5.521 3a.727.727 0 00.716-.738V.75a.75.75 0 00-1.5 0v1.513A.747.747 0 005.5 3zM.5 5l1.719-.006a1.589 1.589 0 001.36-.853l.006-.01a1.43 1.43 0 00.165-.714V.5a.5.5 0 00-.5-.5H.5a.5.5 0 00-.5.5v4a.5.5 0 00.5.5zM8.294 11.921a.261.261 0 01.219.248v3.925a1.017 1.017 0 01-.028.233l-1.471 6.131a1.251 1.251 0 00.924 1.508A1.278 1.278 0 008.23 24a1.25 1.25 0 001.215-.958l1.238-5.159a.5.5 0 01.487-.383h.619a.5.5 0 01.487.383l1.238 5.159a1.25 1.25 0 001.215.958 1.278 1.278 0 00.292-.034 1.251 1.251 0 00.924-1.508L14.54 16.6a1.025 1.025 0 01-.027-.234V12.25a.25.25 0 01.25-.25h3.25a1.25 1.25 0 000-2.5h-9a2.25 2.25 0 01-2.25-2.25v-2a1.25 1.25 0 00-2.5 0v2a4.737 4.737 0 004.031 4.671z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});