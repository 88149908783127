define("ember-svg-jar/inlined/messages-bubble-square-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-alternate</title><path d=\"M21.5 1.5h-19a2 2 0 00-2 2v13a2 2 0 002 2h4v3a1 1 0 001.707.707l3.709-3.707H21.5a2 2 0 002-2v-13a2 2 0 00-2-2zm0 14.748a.25.25 0 01-.25.25H11.5a1 1 0 00-.707.293L8.5 19.084V17.5a1 1 0 00-1-1H2.75a.25.25 0 01-.25-.25V3.75a.25.25 0 01.25-.25h18.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});