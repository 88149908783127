define("ember-svg-jar/inlined/temperature-thermometer-sunny", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>temperature-thermometer-sunny</title><path d=\"M19.75 11.25a7.009 7.009 0 00-7-7 .251.251 0 00-.25.251v1.53a.215.215 0 00.065.155.22.22 0 00.155.064h.03a5 5 0 011.268 9.836.25.25 0 00-.181.292A8.056 8.056 0 0114 17.837a.249.249 0 00.306.239 7.013 7.013 0 005.444-6.826zM23 10.25h-1.5a1 1 0 000 2H23a1 1 0 000-2zM18.938 6.063a1 1 0 00.707-.293l1.06-1.061A1 1 0 0019.291 3.3L18.23 4.355a1 1 0 00.708 1.708zM12.75 3.5a1 1 0 001-1V1a1 1 0 00-2 0v.411a.249.249 0 00.028.114 6.44 6.44 0 01.608 1.762.251.251 0 00.207.2 1.009 1.009 0 00.157.013zM19.645 16.73a1 1 0 00-1.415 1.415l1.061 1.06a1 1 0 001.414-1.414zM6 24a6.006 6.006 0 006-6 5.941 5.941 0 00-1.44-3.9.248.248 0 01-.06-.162V4.5a4.5 4.5 0 00-9 0v9.438a.248.248 0 01-.06.162A5.941 5.941 0 000 18a6.006 6.006 0 006 6zm0-2a4 4 0 01-2.8-6.856 1 1 0 00.3-.714V4.5a2.5 2.5 0 015 0v9.93a1 1 0 00.3.714A4 4 0 016 22z\"/><path d=\"M6.889 16.208a.25.25 0 01-.139-.224V6.5a.75.75 0 00-1.5 0v9.484a.25.25 0 01-.139.224 2 2 0 101.778 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});