define("ember-svg-jar/inlined/tools-pliers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-pliers</title><path d=\"M22.98 2.937a.5.5 0 00-.784-.1L20 5.035a.5.5 0 00-.141.428 1.571 1.571 0 11-1.352-1.325.5.5 0 00.417-.138l2.209-2.208a.5.5 0 00-.1-.786 5.413 5.413 0 00-8.137 4.45.5.5 0 00.353.5 7.243 7.243 0 014.779 4.8.5.5 0 00.5.355 5.411 5.411 0 004.456-8.17zM15.007 17.12A5.772 5.772 0 106.813 9L.419 15.458a.524.524 0 00.186.859c3.171 1.2 8.917-7.886 11.933-4.9S6.516 20.236 7.751 23.4a.525.525 0 00.861.178z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});