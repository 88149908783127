define("ember-svg-jar/inlined/style-three-pin-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-cart</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM9.321 16a1 1 0 111-1 1 1 0 01-1 1zm4.5 0a1 1 0 111-1 1 1 0 01-1 1zm.979-3.356a.5.5 0 01-.479.356h-5.5a.5.5 0 01-.471-.335l-1.75-5A.5.5 0 017.071 7h7.485a.5.5 0 00.479-.356l.746-2.488a.751.751 0 011.438.431z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});