define("ember-svg-jar/inlined/messages-bubble-smile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-smile</title><path d=\"M12 1.375C5.682 1.375.541 5.611.541 10.817A8.566 8.566 0 003.7 17.332L1.415 21.9a.5.5 0 00.658.677L8.2 19.726a13.665 13.665 0 003.8.531c6.318 0 11.459-4.235 11.459-9.44S18.318 1.375 12 1.375zM7.045 8.87a.75.75 0 01-1.5 0 2.75 2.75 0 015.5 0 .75.75 0 01-1.5 0 1.25 1.25 0 00-2.5 0zm1.466 3.982a.749.749 0 011.06 0 3.576 3.576 0 004.94 0 .75.75 0 111.06 1.061 4.993 4.993 0 01-7.06 0 .75.75 0 010-1.061zM17.8 9.62a.75.75 0 01-.75-.75 1.25 1.25 0 10-2.5 0 .75.75 0 01-1.5 0 2.75 2.75 0 015.5 0 .75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});