define("ember-svg-jar/inlined/ui-webpage-play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ui-webpage-play</title><path d=\"M24 1.5A1.5 1.5 0 0022.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5zm-21 2a.5.5 0 01.5-.5h17a.5.5 0 01.5.5v10a.5.5 0 01-.5.5h-17a.5.5 0 01-.5-.5zm1.442 16.958a.625.625 0 01-.884.885l-1.5-1.5a.626.626 0 010-.885l1.5-1.5a.625.625 0 01.884.885l-.881.881a.25.25 0 000 .353zm3.25 0a.625.625 0 01-.884.885l-1.5-1.5a.626.626 0 010-.885l1.5-1.5a.625.625 0 01.884.885l-.881.881a.25.25 0 000 .353zm6.13-.634l-2.557 1.6A.5.5 0 0110.5 21v-3.2a.5.5 0 01.765-.424l2.557 1.6a.5.5 0 010 .848zm4.87.019l-1.5 1.5a.625.625 0 11-.884-.885l.881-.881a.25.25 0 000-.353l-.881-.881a.625.625 0 01.884-.885l1.5 1.5a.626.626 0 010 .885zm3.25 0l-1.5 1.5a.625.625 0 11-.884-.885l.881-.881a.25.25 0 000-.353l-.881-.881a.625.625 0 01.884-.885l1.5 1.5a.626.626 0 010 .885z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});