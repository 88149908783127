define("ember-svg-jar/inlined/retouch-patch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>retouch-patch</title><path d=\"M23 13h-1.75a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25H23a1 1 0 000-2h-1.75a.25.25 0 01-.25-.25V6a3 3 0 00-3-3h-2.75a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v1.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v1.75a.25.25 0 01-.25.25H6a3 3 0 00-3 3v2.75a.25.25 0 01-.25.25H1a1 1 0 000 2h1.75a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H1a1 1 0 000 2h1.75a.25.25 0 01.25.25V18a3 3 0 003 3h2.75a.25.25 0 01.25.25V23a1 1 0 002 0v-1.75a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V23a1 1 0 002 0v-1.75a.25.25 0 01.25-.25H18a3 3 0 003-3v-2.75a.25.25 0 01.25-.25H23a1 1 0 000-2zm-4 5a1 1 0 01-1 1h-2.75a.25.25 0 01-.25-.25V17a1 1 0 00-2 0v1.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V17a1 1 0 00-2 0v1.75a.25.25 0 01-.25.25H6a1 1 0 01-1-1v-2.75a.25.25 0 01.25-.25H7a1 1 0 000-2H5.25a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25H7a1 1 0 000-2H5.25A.25.25 0 015 8.75V6a1 1 0 011-1h2.75a.25.25 0 01.25.25V7a1 1 0 002 0V5.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V7a1 1 0 002 0V5.25a.25.25 0 01.25-.25H18a1 1 0 011 1v2.75a.25.25 0 01-.25.25H17a1 1 0 000 2h1.75a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H17a1 1 0 000 2h1.75a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});