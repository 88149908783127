define("ember-svg-jar/inlined/check-circle-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>check-circle-1-alternate</title><path d=\"M18.48 6.449a1.249 1.249 0 00-1.747.265l-5.924 8.04-3.767-3.014a1.251 1.251 0 00-1.563 1.953l4.783 3.826a1.263 1.263 0 001.787-.235l6.7-9.087a1.25 1.25 0 00-.269-1.748z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});