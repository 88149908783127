define("ember-svg-jar/inlined/phone-selfie-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-selfie-man</title><path d=\"M16.5 15.5A4.5 4.5 0 1012 20a4.505 4.505 0 004.5-4.5zm-7 0a2.477 2.477 0 01.205-.99.251.251 0 01.35-.12 6.983 6.983 0 003.361.86 6.888 6.888 0 00.8-.047.251.251 0 01.279.242v.055a2.5 2.5 0 01-5 0zM16.876 23.164a6.575 6.575 0 00-9.752 0 .5.5 0 00.37.836h9.012a.5.5 0 00.37-.836z\"/><path d=\"M19 3a1 1 0 000 2 1 1 0 011 1v2.25a.25.25 0 01-.25.25H4.25A.25.25 0 014 8.25V6a1 1 0 011-1 1 1 0 000-2 3 3 0 00-3 3v17a1 1 0 002 0V10.25a.25.25 0 01.25-.25h15.5a.25.25 0 01.25.25V23a1 1 0 002 0V6a3 3 0 00-3-3z\"/><circle cx=\"12\" cy=\"5.5\" r=\"1.5\"/><path d=\"M12 3a.75.75 0 00.75-.75V.75a.75.75 0 00-1.5 0v1.5A.75.75 0 0012 3zM9.172 3.732a.75.75 0 001.06-1.06l-1.06-1.061a.75.75 0 00-1.061 1.061zM7.25 6.25h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5zM14.828 3.732l1.061-1.06a.75.75 0 00-1.061-1.061l-1.06 1.061a.75.75 0 001.06 1.06zM14.5 5.5a.75.75 0 00.75.75h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 00-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});