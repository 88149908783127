define("ember-svg-jar/inlined/lucide-radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"12\" cy=\"12\" r=\"2\"/><path d=\"M4.93 19.07a10 10 0 010-14.14M7.76 16.24a6 6 0 01-1.3-1.95 6 6 0 010-4.59 6 6 0 011.3-1.95M16.24 7.76a6 6 0 011.3 2 6 6 0 010 4.59 6 6 0 01-1.3 1.95M19.07 4.93a10 10 0 010 14.14\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});