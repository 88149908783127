define("ember-svg-jar/inlined/content-ink-pen-write", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-ink-pen-write</title><path d=\"M18.682 5.317a4.509 4.509 0 00-6.365 0c-1.228 1.228-2.87 8.044-3.053 8.818a.5.5 0 00.6.6c.774-.183 7.589-1.827 8.817-3.054a4.5 4.5 0 000-6.365zM15.5 10A1.5 1.5 0 1117 8.5a1.5 1.5 0 01-1.5 1.5zM23.854 4.147l-4-4A.493.493 0 0019.439 0a.5.5 0 00-.368.238l-1.5 2.5a.5.5 0 00.076.612l3 3a.5.5 0 00.61.075l2.5-1.5a.5.5 0 00.1-.782zM17 18H5.012A.918.918 0 014 17a.915.915 0 011-1h2a1 1 0 000-2H5a2.916 2.916 0 00-3 3 2.916 2.916 0 003 3h11.988A1 1 0 1117 22H1a1 1 0 000 2h16a3 3 0 000-6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});