define("ember-svg-jar/inlined/history-noah-s-ark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-noah&amp;apos;s-ark</title><path d=\"M18.7 15.6a.967.967 0 00-.271-.433 1.5 1.5 0 10-1.537 2.367.5.5 0 01.364.353l.189.688a.2.2 0 01-.2.258 2.73 2.73 0 01-2.7-3.25H14.5v-2.75a.5.5 0 01.5-.5h.5a1 1 0 000-2H15a.5.5 0 01-.5-.5v-4.5a.5.5 0 01.5-.5h.5a1 1 0 000-2H15a.5.5 0 01-.5-.5v-.5a1 1 0 00-2 0v.5a.5.5 0 01-.5.5h-1a3 3 0 00-3 3v3.5a3 3 0 003 3h1a.5.5 0 01.5.5v2.75a.5.5 0 00-.5.5 2.04 2.04 0 01-.024.284.492.492 0 00.013.2l1.276 4.646a.5.5 0 00.482.368h2.722a4.733 4.733 0 001.243-.17.5.5 0 01.612.35l.185.671a1 1 0 001.928-.531zM21.75 12.082a2.253 2.253 0 00-2.25 2.25v.025a.5.5 0 00.077.266A2.731 2.731 0 0120 16.082a2.04 2.04 0 01-.024.284.492.492 0 00.013.2l.369 1.345a.5.5 0 00.96.015 4.871 4.871 0 00.182-.846v-2.75c0-.276.5-.276.5 0a1 1 0 002 0 2.253 2.253 0 00-2.25-2.248z\"/><path d=\"M10.7 15.6a.967.967 0 00-.271-.433 1.5 1.5 0 10-1.537 2.367.5.5 0 01.364.353l.189.688a.2.2 0 01-.2.258 2.75 2.75 0 01-2.75-2.75 2.715 2.715 0 01.233-1.1.5.5 0 00-.195-.628 6.728 6.728 0 01-.865-.629.5.5 0 01-.173-.379V9.832a3 3 0 00-3-3H1a1 1 0 000 2h1.5a.25.25 0 010 .5H2a1 1 0 000 2h1a.5.5 0 01.5.5v4.25a5.506 5.506 0 005.5 5.5h1.275l.234.851a1 1 0 001.928-.531z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});