define("ember-svg-jar/inlined/professional-tool-evernote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professional-tool-evernote</title><path d=\"M16.48 1.85h-2.62A3 3 0 0011 .25c-2.22 0-2.41 1-2.41 2V5.5a.75.75 0 01-.75.75h-4.4a.25.25 0 00-.13 0 3.27 3.27 0 00-1.4 2.8c0 2.79.93 8.22 4.45 8.76 2.7.42 4.06-.18 4.73-.75a1.76 1.76 0 00.67-1.06v-1.75a4 4 0 003 1.86c1.85 0 2.84 1 2.84 2v2.09s-.1 1.07-1 1.07h-2.34a1.74 1.74 0 01-.67-1.27c0-.74.34-.84.63-.84a6.57 6.57 0 01.79 0 .24.24 0 00.2-.06.25.25 0 00.09-.19v-1.8A.25.25 0 0015 17a5.12 5.12 0 00-3.2 1.12 2.58 2.58 0 00-.76 1.9 3.49 3.49 0 003.74 3.77h2.53c.19 0 4.73-.71 4.73-9.74.05-6.93-.36-12.2-5.56-12.2zm2.37 10.35a.25.25 0 01-.21.11c-1.06-.2-2.75-.49-3-.49a.25.25 0 01-.25-.29c0-.07.26-1.68 1.32-1.68s2 1.74 2.24 2.09a.25.25 0 01-.1.27z\"/><path d=\"M3.36 5.27h4A.25.25 0 007.61 5V1a.25.25 0 00-.43-.16l-4 4a.25.25 0 00.18.43z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});