define("ember-svg-jar/inlined/tags-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-remove</title><path d=\"M18.24 9.375l.943-.963A3.982 3.982 0 0020.169 6V2a2 2 0 00-2-2l-4.26.005a3.938 3.938 0 00-2.412 1L.67 11.833a2 2 0 000 2.829L5.505 19.5a1.978 1.978 0 001.407.581h.012a1.971 1.971 0 001.409-.6l.972-.991a.492.492 0 00.135-.38 8 8 0 018.422-8.595.491.491 0 00.378-.14zM12.669 5.5a2 2 0 112 2 2 2 0 01-2-2z\"/><path d=\"M17.416 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.651 8.091a.75.75 0 11-1.061 1.06l-1.414-1.414a.25.25 0 00-.353 0l-1.414 1.413a.75.75 0 01-1.061-1.06l1.414-1.414a.25.25 0 000-.354l-1.414-1.414a.75.75 0 011.061-1.06l1.414 1.414a.25.25 0 00.353 0l1.414-1.414a.75.75 0 011.061 1.06l-1.414 1.414a.25.25 0 000 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});