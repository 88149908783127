define("ember-svg-jar/inlined/wedding-luggage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wedding-luggage</title><path d=\"M24 9.25a3.5 3.5 0 00-3.5-3.5h-3a.5.5 0 01-.5-.5v-1a3 3 0 00-3-3h-4a3 3 0 00-3 3v1a.5.5 0 01-.5.5h-3A3.5 3.5 0 000 9.25v10a3.5 3.5 0 003.5 3.5h17a3.5 3.5 0 003.5-3.5zm-15-5a1 1 0 011-1h4a1 1 0 011 1v1a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5zm3.352 14.539a.568.568 0 01-.7 0c-1.164-.912-4.207-3.486-4.207-5.5 0-2.467 2.816-3.474 4.143-1.488a.5.5 0 00.832 0c1.327-1.986 4.143-.979 4.143 1.488-.004 2.011-3.047 4.588-4.211 5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});