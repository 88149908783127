define("ember-svg-jar/inlined/safety-call-firefighters-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-call-firefighters-2</title><path d=\"M5 10.5a1 1 0 001-1A3.5 3.5 0 019.5 6a1 1 0 000-2A5.506 5.506 0 004 9.5a1 1 0 001 1z\"/><path d=\"M9.5 2a1 1 0 000-2A9.511 9.511 0 000 9.5a1 1 0 002 0A7.508 7.508 0 019.5 2zM14.5 4a1 1 0 000 2A3.5 3.5 0 0118 9.5a1 1 0 002 0A5.506 5.506 0 0014.5 4z\"/><path d=\"M14.5 0a1 1 0 000 2A7.508 7.508 0 0122 9.5a1 1 0 002 0A9.511 9.511 0 0014.5 0zM11.75 9a7.5 7.5 0 107.5 7.5 7.508 7.508 0 00-7.5-7.5zm0 12a3.116 3.116 0 01-3.115-3.115 4.179 4.179 0 01.633-1.78.5.5 0 01.837-.047l.094.127a.5.5 0 00.88-.151 5.689 5.689 0 00-.072-3.006.5.5 0 01.74-.552 6.515 6.515 0 013.118 5.409A3.115 3.115 0 0111.75 21z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});