define("ember-svg-jar/inlined/smiley-rich-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-rich-alternate</title><path d=\"M22.952 7.089a1 1 0 10-1.824.82A9.9 9.9 0 0122 12a10 10 0 01-20 0 9.9 9.9 0 01.872-4.09 1 1 0 10-1.824-.82A12 12 0 1024 12a11.9 11.9 0 00-1.048-4.911zM9.272 2.375a10.13 10.13 0 015.456 0 .988.988 0 00.272.038 1 1 0 00.272-1.962 12.121 12.121 0 00-6.544 0 1 1 0 00.544 1.924z\"/><path d=\"M16.492 14.129a1 1 0 00-1.362.378A3.656 3.656 0 0112 16.5a3.656 3.656 0 01-3.13-1.992 1 1 0 00-1.74.984A5.624 5.624 0 0012 18.5a5.624 5.624 0 004.87-3.008 1 1 0 00-.378-1.363zM5.1 8.014l2.06.826a.338.338 0 01.215.317.342.342 0 01-.342.342H5a1 1 0 000 2h.25a.25.25 0 01.25.25 1 1 0 102 0v-.1a.25.25 0 01.181-.24A2.342 2.342 0 007.9 6.983l-2.06-.825a.342.342 0 01.127-.658H8a1 1 0 100-2h-.25a.25.25 0 01-.25-.25 1 1 0 00-2 0v.1a.251.251 0 01-.181.24A2.341 2.341 0 005.1 8.014zM14.625 5.841A2.329 2.329 0 0016.1 8.014l2.062.826a.338.338 0 01.215.317.342.342 0 01-.342.342H16a1 1 0 000 2h.25a.25.25 0 01.25.25 1 1 0 102 0v-.1a.25.25 0 01.181-.24 2.342 2.342 0 00.219-4.426l-2.06-.825a.342.342 0 01.127-.659H19a1 1 0 100-2h-.25a.25.25 0 01-.25-.25 1 1 0 10-2 0v.1a.251.251 0 01-.181.24 2.345 2.345 0 00-1.694 2.252z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});