define("ember-svg-jar/inlined/vr-360-remote-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vr-360-remote-controller</title><path d=\"M10.75 7h-3a1.5 1.5 0 00-1.5 1.5v14a1.5 1.5 0 001.5 1.5h3a1.5 1.5 0 001.5-1.5v-14a1.5 1.5 0 00-1.5-1.5zm-1.5 7a2 2 0 112-2 2 2 0 01-2 2z\"/><circle cx=\"9.25\" cy=\"12\" r=\".75\"/><path d=\"M4.466 9.892a.75.75 0 10-.432-1.436A10.9 10.9 0 002.587 9a.25.25 0 01-.33-.335 7.754 7.754 0 018.361-4.291.75.75 0 00.264-1.477 9.244 9.244 0 00-7.073 16.572.749.749 0 10.882-1.212 7.779 7.779 0 01-2.425-2.915.25.25 0 01.33-.335 10.962 10.962 0 001.438.537.75.75 0 10.432-1.436C2.664 13.567 1.5 12.74 1.5 12s1.164-1.567 2.966-2.108zM15.671 6.394a.751.751 0 00-.163 1.049 7.889 7.889 0 01.732 1.217.251.251 0 01-.331.34 10.654 10.654 0 00-1.443-.54.75.75 0 10-.432 1.436C15.836 10.433 17 11.26 17 12s-1.164 1.567-2.966 2.108a.751.751 0 00-.5.934c.29.962.914.509.935.5a10.962 10.962 0 001.438-.537.25.25 0 01.33.335 7.779 7.779 0 01-2.425 2.915.75.75 0 00-.165 1.048c.517.706.843.314 1.047.164A9.277 9.277 0 0018.5 12a9.163 9.163 0 00-1.781-5.443.75.75 0 00-1.048-.163zM18.5 2a1.491 1.491 0 00-.42.06.064.064 0 01-.055-.01A.062.062 0 0118 2a1 1 0 011-1 .5.5 0 000-1 2 2 0 00-2 2v1.5A1.5 1.5 0 1018.5 2zm0 2a.5.5 0 11.5-.5.5.5 0 01-.5.5zM16 3.5a1.491 1.491 0 00-.269-.857.25.25 0 010-.286 1.5 1.5 0 10-2.68-1.244.5.5 0 00.967.257.5.5 0 11.482.63.5.5 0 000 1 .5.5 0 11-.482.635.5.5 0 10-.963.267A1.5 1.5 0 0016 3.5zM22.5 0A1.5 1.5 0 0021 1.5v2a1.5 1.5 0 003 0v-2A1.5 1.5 0 0022.5 0zm.5 3.5a.5.5 0 01-1 0v-2a.5.5 0 011 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});