define("ember-svg-jar/inlined/search-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>search-text</title><path d=\"M23.457 23.211a1 1 0 000-1.414l-4.664-4.665a.5.5 0 01-.045-.655 10.291 10.291 0 10-8.958 4.054.25.25 0 01.232.249v.962a.249.249 0 01-.25.25H8.579a1 1 0 000 2h4.887a1 1 0 000-2h-1.194a.25.25 0 01-.25-.25v-1.075a.251.251 0 01.209-.247 10.17 10.17 0 004.493-1.92.5.5 0 01.655.045l4.664 4.665a1 1 0 001.414 0zM2.25 10.288a8.284 8.284 0 1114.15 5.849l-.009.007-.007.009A8.284 8.284 0 012.25 10.288z\"/><path d=\"M11.022 16.663a1 1 0 001-1v-5.357a.5.5 0 01.5-.5L14.9 9.8a.5.5 0 01.5.5v1.454a1 1 0 002 0V9.8a1.979 1.979 0 00-1.976-1.978h-8.8A1.98 1.98 0 004.647 9.8v1.955a1 1 0 002 0l-.016-1.428a.5.5 0 01.5-.506l2.391-.006a.5.5 0 01.5.5v5.349a1 1 0 001 .999z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});