define("ember-svg-jar/inlined/medium-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Medium-new</title><path d=\"M23 0H1a1 1 0 00-1 1v22a1 1 0 001 1h22a1 1 0 001-1V1a1 1 0 00-1-1zm-2.64 5.85L19 7.08a.38.38 0 00-.14.36v9.48a.38.38 0 00.14.36l1.35 1.33v.27H14v-.27l1.16-1.36c.13-.13.13-.17.13-.36V10l-2.8 7.9-.3 1h-.79c-2.65-5.79-3.49-7.82-4.3-9.21v7l1.64 2v.27H4v-.27l1.6-2v-9L4.36 6.15v-.53h4.77l3.22 7.71L15 6.06a.75.75 0 01.79-.58h4.58z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});