define("ember-svg-jar/inlined/shellfish-crab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shellfish-crab</title><circle cx=\"9.75\" cy=\"5.501\" r=\"1.25\"/><circle cx=\"14.25\" cy=\"5.501\" r=\"1.25\"/><path d=\"M20.5 12.5a5 5 0 002-4.107c1.961-.906 1.657-3.562.928-4.976A4.243 4.243 0 0020.3 1a.5.5 0 00-.546.574l.282 1.813-1.315-1.278a.514.514 0 00-.419-.136.5.5 0 00-.365.249 4.241 4.241 0 00.156 3.949A4.49 4.49 0 0020.5 8.428a3.394 3.394 0 01-1.129 2.421l.877.988a1 1 0 01.252.663zM3.753 11.837l.878-.988A3 3 0 013.5 8.427a4.592 4.592 0 002.43-2.256 4.244 4.244 0 00.156-3.949.5.5 0 00-.365-.249.522.522 0 00-.42.136L3.988 3.391l.283-1.813a.5.5 0 00-.132-.422A.478.478 0 003.725 1 4.243 4.243 0 00.6 3.42c-.715 1.38-1.043 4.043.9 4.966a5 5 0 002 4.117 1 1 0 01.253-.666z\"/><path d=\"M23.8 14.957a5.066 5.066 0 00-4.317-1.951c.011-.167.017-.335.017-.505l-3.4-3.829A2 2 0 0014.6 8H9.4a2 2 0 00-1.5.672L4.5 12.5c0 .17.006.338.017.505A5.073 5.073 0 00.2 14.957a1 1 0 001.6 1.2 3.266 3.266 0 013.146-1.1c.038.1.077.205.119.306a5.251 5.251 0 00-3.551 3.976 1 1 0 001.972.328 3.433 3.433 0 012.606-2.544c.109.14.223.275.342.407A5.542 5.542 0 004.5 22a1 1 0 002 0 3.551 3.551 0 011.517-3.145 7.5 7.5 0 007.967 0A3.365 3.365 0 0117.5 22a1 1 0 102 0 5.541 5.541 0 00-1.934-4.473c.118-.131.232-.267.341-.406a3.444 3.444 0 012.607 2.543 1 1 0 101.972-.328 4.9 4.9 0 00-2.642-3.563 7.294 7.294 0 00-.909-.413c.041-.1.081-.2.118-.306a3.266 3.266 0 013.147 1.1 1 1 0 001.6-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});