define("ember-svg-jar/inlined/align-landscape", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>align-landscape</title><path d=\"M20.5 0h-17a2 2 0 00-2 2v12.5a1 1 0 002 0V3a1 1 0 011-1h15a1 1 0 011 1v11.5a1 1 0 002 0V2a2 2 0 00-2-2zM20.5 19H7.75a.25.25 0 01-.25-.25V16.5a.5.5 0 00-.854-.353l-3.5 3.5a.5.5 0 000 .707l3.5 3.5A.5.5 0 007.5 23.5v-2.25a.25.25 0 01.25-.25H20.5a1 1 0 000-2z\"/><circle cx=\"12\" cy=\"6.07\" r=\"2.286\"/><path d=\"M9.173 13.5h5.654a.994.994 0 00.962-1.284 4 4 0 00-7.578 0 .994.994 0 00.962 1.284z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});