define("ember-svg-jar/inlined/asian-interior-furniture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>asian-interior-furniture</title><path d=\"M5.189 4.5a3.5 3.5 0 003.665-3A1.251 1.251 0 007.627 0H2.75a1.251 1.251 0 00-1.226 1.5 3.5 3.5 0 003.665 3zM19.25 19h-6a1 1 0 000 2 .081.081 0 01.077.107l-.527 1.577a1 1 0 001.9.632l.715-2.145a.25.25 0 01.236-.171h1.2a.25.25 0 01.237.171l.715 2.145a1 1 0 001.9-.632l-.526-1.577A.081.081 0 0119.25 21a1 1 0 000-2z\"/><rect x=\"12.75\" y=\"10.5\" width=\"11\" height=\"2.5\" rx=\".5\" ry=\".5\"/><path d=\"M22.25 5.25h-5a.5.5 0 00-.5.5V8.5a.5.5 0 00.5.5h6a.5.5 0 00.5-.5V6.75a1.5 1.5 0 00-1.5-1.5z\"/><rect x=\"8.75\" y=\"5.25\" width=\"6.5\" height=\"3.75\" rx=\".5\" ry=\".5\"/><rect x=\".25\" y=\"10.5\" width=\"11\" height=\"2.5\" rx=\".5\" ry=\".5\"/><path d=\"M23.75 15a.5.5 0 00-.5-.5h-10a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h8a.5.5 0 01.5.5v5a1 1 0 002 0zM.75 9h6a.5.5 0 00.5-.5V5.75a.5.5 0 00-.5-.5h-5a1.5 1.5 0 00-1.5 1.5V8.5a.5.5 0 00.5.5zM10.75 14.5h-10a.5.5 0 00-.5.5v8a1 1 0 002 0v-5a.5.5 0 01.5-.5h8a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});