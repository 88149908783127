define("ember-svg-jar/inlined/credit-card-online-payment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-online-payment</title><path d=\"M22.259 13.533h-9.434a1.652 1.652 0 00-1.65 1.651v6.6a1.652 1.652 0 001.65 1.651h9.434a1.653 1.653 0 001.651-1.651v-6.6a1.653 1.653 0 00-1.651-1.651zm0 8.491h-9.434a.236.236 0 01-.235-.236v-2.817a.25.25 0 01.25-.25h9.405a.25.25 0 01.25.25v2.817a.236.236 0 01-.236.236zm-9.434-7.076h9.434a.236.236 0 01.236.236v.929a.25.25 0 01-.25.25H12.84a.25.25 0 01-.25-.25v-.929a.236.236 0 01.235-.236z\"/><path d=\"M21.08 20.373a.708.708 0 00-.707-.708h-1.18a.708.708 0 000 1.415h1.18a.708.708 0 00.707-.707zM9.995 16.127a.943.943 0 00-.943-.943H2.92a.944.944 0 01-.944-.944V6.278a1 1 0 011-1h14.982a1 1 0 011 1v5.132a.943.943 0 101.886 0V3.391a2.83 2.83 0 00-2.83-2.83H2.92a2.83 2.83 0 00-2.83 2.83V14.24a2.831 2.831 0 002.83 2.831h6.132a.944.944 0 00.943-.944zM5.75 2.92a.944.944 0 11.943.943.945.945 0 01-.943-.943zm4.245.943a.944.944 0 11.944-.943.944.944 0 01-.944.943zm-5.66-.943a.944.944 0 11-.943-.944.944.944 0 01.943.944z\"/><path d=\"M12.036 11.383A1.8 1.8 0 0010.9 9.707l-1.685-.675a.389.389 0 01.144-.751h1.663a.708.708 0 000-1.415h-.269a.25.25 0 01-.25-.25v-.064a.708.708 0 10-1.415 0v.163a.219.219 0 01-.166.212 1.8 1.8 0 00-.233 3.419l1.687.675a.389.389 0 01-.145.751H8.568a.708.708 0 000 1.415h.27a.25.25 0 01.25.25v.063a.708.708 0 00.707.707.713.713 0 00.689-.612.726.726 0 00.016-.095v-.14a.249.249 0 01.186-.241 1.837 1.837 0 00.42-.172 1.815 1.815 0 00.927-1.566z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});