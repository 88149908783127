define("ember-svg-jar/inlined/agriculture-machine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>agriculture-machine</title><path d=\"M17.6 3.777a.5.5 0 00.418.223h2.464a.5.5 0 00.416-.223l.684-1.025a1 1 0 00.168-.552V1a1 1 0 00-1-1h-3a1 1 0 00-1 1v1.2a1 1 0 00.168.555zM2.75 16H10a1 1 0 000-2H2.75a1 1 0 000 2z\"/><path d=\"M22.55 20.166a.5.5 0 01-.3-.458V12.5a1.5 1.5 0 00-1.125-1.453.5.5 0 01-.375-.484V6a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v4.5a.5.5 0 01-.5.5h-.5a1.5 1.5 0 00-1.5 1.5.5.5 0 01-.5.5h-1a1.5 1.5 0 00-1.5 1.5V16a1.5 1.5 0 01-1.5 1.5h-10a.5.5 0 00-.5.5v.5a1.5 1.5 0 001.5 1.5h2.587a.25.25 0 01.158.444 2 2 0 102.51 0A.25.25 0 017.163 20h3.587a.5.5 0 01.5.5v1a2 2 0 002 2h7a.5.5 0 01.3.1 2 2 0 102-3.434zm-2.056.278a2.019 2.019 0 00-.578.756.5.5 0 01-.458.3H13.75a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h6.586a.25.25 0 01.158.444z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});