define("ember-svg-jar/inlined/dislike", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dislike</title><path d=\"M.25 12.435a2.253 2.253 0 002.25 2.25h6.726a.25.25 0 01.244.306 25.026 25.026 0 00-.72 4.725 3.35 3.35 0 00.757 2.3 2.349 2.349 0 001.748.8 1.9 1.9 0 001.995-1.88A7.193 7.193 0 0120 13.685h3a.75.75 0 00.75-.75v-9a.75.75 0 00-.75-.75c-4.532 0-6.009-.635-7.312-1.193A8.044 8.044 0 0012 1.185H5.5a2.251 2.251 0 00-2.019 3.244 2.251 2.251 0 00-1 3 2.251 2.251 0 00-1 3A2.251 2.251 0 00.25 12.435z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});