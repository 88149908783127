define("ember-svg-jar/inlined/multiple-actions-lock.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-lock</title><path d=\"M17.226 9.594a.5.5 0 00.054-.91 6 6 0 00-5.256-.15.5.5 0 00-.182.771 7.393 7.393 0 011.658 4.222.278.278 0 00.552.024 4.87 4.87 0 013.174-3.957z\"/><ellipse cx=\"14.013\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M12 13.6a6.025 6.025 0 00-12-.267.6.6 0 00.6.667h11.023a.377.377 0 00.275-.119A.372.372 0 0012 13.6z\"/><ellipse cx=\"5.513\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M22.5 14.25a3.5 3.5 0 00-7 0v1.018a.249.249 0 01-.214.247A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.286-1.485.249.249 0 01-.214-.247zm-2.5 6a1 1 0 11-1-1 1 1 0 011 1zm-2.25-4.75a.25.25 0 01-.25-.25v-1a1.5 1.5 0 013 0v1a.25.25 0 01-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});