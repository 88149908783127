define("ember-svg-jar/inlined/social-media-yelp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-yelp</title><path d=\"M10.81 12.14a.5.5 0 00.37.16.5.5 0 00.5-.47L12.26.54A.5.5 0 0011.6 0L3.22 2.91a.5.5 0 00-.2.81zM21.27 11.84L18.16 6.6a.5.5 0 00-.42-.24.53.53 0 00-.43.23l-4.06 6.2a.5.5 0 00.42.77h.07l7.18-1a.5.5 0 00.36-.75zM20.62 17.54l-7-2.46a.5.5 0 00-.63.66l2.66 6.74a.5.5 0 00.36.31h.1a.5.5 0 00.35-.14l4.34-4.28a.5.5 0 00-.19-.83zM11.48 15.63a.5.5 0 00-.53.12l-5.06 5.17a.5.5 0 00.17.82L11.74 24a.5.5 0 00.68-.51l-.6-7.39a.5.5 0 00-.34-.47zM10.44 14.32a.5.5 0 00-.32-.43l-6.79-2.53a.5.5 0 00-.67.49l.28 6.09a.5.5 0 00.25.41.5.5 0 00.49 0l6.5-3.56a.5.5 0 00.26-.47z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});