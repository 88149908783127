define("ember-svg-jar/inlined/phone-actions-pause-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-pause-1</title><rect x=\"15.25\" y=\".75\" width=\"3\" height=\"9\" rx=\"1\" ry=\"1\"/><rect x=\"20.25\" y=\".75\" width=\"3\" height=\"9\" rx=\"1\" ry=\"1\"/><path d=\"M16.245 15.033a3.114 3.114 0 00-4.4 0l-.828.828a4.251 4.251 0 00-1.346 4.5A11.351 11.351 0 013.64 14.33a4.248 4.248 0 004.5-1.346l.831-.829a3.118 3.118 0 000-4.4L5.385 4.173a2.232 2.232 0 00-3.649.739 13.32 13.32 0 009.653 18.07 13.421 13.421 0 007.705-.715 2.235 2.235 0 00.736-3.649z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});