define("ember-svg-jar/inlined/shield-phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shield-phone</title><rect x=\"8.75\" y=\"4.506\" width=\"6.5\" height=\"9.75\" rx=\".25\" ry=\".25\"/><path d=\"M22.043.006H1.96A1.961 1.961 0 00.012 1.964L0 9.306a15.144 15.144 0 0011.861 14.668.976.976 0 00.4 0A15.144 15.144 0 0023.988 9.2L24 1.954A1.96 1.96 0 0022.043.006zm-5.293 15.75a1.758 1.758 0 01-1.75 1.75H9a1.758 1.758 0 01-1.75-1.75v-11A1.758 1.758 0 019 3.006h6a1.758 1.758 0 011.75 1.75z\"/><rect x=\"8.75\" y=\"4.506\" width=\"6.5\" height=\"9.75\" rx=\".25\" ry=\".25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});