define("ember-svg-jar/inlined/mask-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mask-star</title><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2zm-2 19.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M7.572 13.6a.249.249 0 01.066.289l-1.46 3.358a1.416 1.416 0 002.008 1.78l3.692-2.08a.251.251 0 01.246 0l3.691 2.08a1.416 1.416 0 002.008-1.78l-1.459-3.358a.249.249 0 01.066-.289l2.638-2.264a1.357 1.357 0 00-.955-2.353h-3.025a.25.25 0 01-.225-.141l-1.589-3.276v-.008a1.428 1.428 0 00-2.543.007L9.135 8.838a.25.25 0 01-.225.141H5.885a1.356 1.356 0 00-.925 2.376z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});