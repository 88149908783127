define("ember-svg-jar/inlined/monitor-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-page</title><path d=\"M22 3v11.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5V3a1 1 0 00-1-1 1 1 0 00-1 1v13.5a3 3 0 003 3h7.25a.25.25 0 01.25.25v2a.25.25 0 01-.25.25H5a1 1 0 000 2h14a1 1 0 000-2h-6.25a.25.25 0 01-.25-.25v-2a.25.25 0 01.25-.25H21a3 3 0 003-3V3a1 1 0 00-1-1 1 1 0 00-1 1z\"/><path d=\"M5.5 12.5V5.248A.25.25 0 015.75 5h12.5a.25.25 0 01.25.25v7.25a1 1 0 001 1 1 1 0 001-1V3a3 3 0 00-3-3h-11a3 3 0 00-3 3v9.5a1 1 0 001 1 1 1 0 001-1zM13.154 2a.966.966 0 011.692 0 .972.972 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0A.969.969 0 0113 2.5a.972.972 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0 .972.972 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.972.972 0 01.154-.5zm-3.562.09A1 1 0 017 1.5a.985.985 0 01.846.5.972.972 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0A.969.969 0 016 2.5a.979.979 0 01.092-.41z\"/><path d=\"M16.25 6.5h-4a.75.75 0 100 1.5h4a.75.75 0 000-1.5zM16.25 10h-7.5a.75.75 0 000 1.5h7.5a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});