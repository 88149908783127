define("ember-svg-jar/inlined/deer-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>deer-1</title><path d=\"M22.5.5a1 1 0 00-1 1c0 1.5-1 2-3 2h-.111C18.1 1.513 17.222.5 16 .5c-1.5 0 .5 2.5.5 4.5a5.483 5.483 0 01-.432 2.071.251.251 0 00.083.3 7.1 7.1 0 011.16 1.078.249.249 0 00.417-.059 8.366 8.366 0 00.75-2.886c5.236 0 5.022-3.442 5.022-4A1 1 0 0022.5.5z\"/><path d=\"M21.235 9.944a.5.5 0 00-.512-.015l-3.356 1.865a5.5 5.5 0 00-10.734 0L3.272 9.929a.5.5 0 00-.741.473c.334 4.733 3.3 5.464 4.2 5.577C7.234 19.35 8.642 23.5 12 23.5s4.766-4.151 5.265-7.521c.913-.115 3.866-.852 4.2-5.577a.5.5 0 00-.23-.458zM9.5 14.5a1 1 0 111-1 1 1 0 01-1 1zm3.544 5.729l-.616 1.028A.5.5 0 0112 21.5a.5.5 0 01-.428-.242l-.618-1.029a.5.5 0 01.429-.757h1.233a.5.5 0 01.429.757zM14.5 14.5a1 1 0 111-1 1 1 0 01-1 1zM5.521 5.5a8.361 8.361 0 00.751 2.886.249.249 0 00.417.059 7.1 7.1 0 011.16-1.078.251.251 0 00.083-.3A5.483 5.483 0 017.5 5c0-2 2-4.5.5-4.5-1.222 0-2.1 1.012-2.389 3H5.5c-2 0-3-.5-3-2a1 1 0 00-2 0c0 .538-.214 4 5.021 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});