define("ember-svg-jar/inlined/night-stand-lamp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>night-stand-lamp</title><path d=\"M20.5 14h-17A1.5 1.5 0 002 15.5V19a.5.5 0 00.5.5h19a.5.5 0 00.5-.5v-3.5a1.5 1.5 0 00-1.5-1.5zM12 17.75a1 1 0 111-1 1 1 0 01-1 1zM21.5 21h-19a.5.5 0 00-.5.5V23a1 1 0 001 1h18a1 1 0 001-1v-1.5a.5.5 0 00-.5-.5zM7.906 7.5H10.5a.5.5 0 01.5.5v1.351a.5.5 0 01-.285.452A2.989 2.989 0 009 12.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5 2.989 2.989 0 00-1.715-2.7.5.5 0 01-.285-.449V8a.5.5 0 01.5-.5h2.594a.906.906 0 00.711-.329.925.925 0 00.157-.821L15.086.85A1.157 1.157 0 0014 0h-4a1.136 1.136 0 00-1.078.827l-1.9 5.579A.887.887 0 007.906 7.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});