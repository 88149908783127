define("ember-svg-jar/inlined/landmark-golden-temple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-golden-temple</title><path d=\"M22.25 12H1.75a.75.75 0 000 1.5.25.25 0 01.25.25v2.75a.5.5 0 00.5.5H4a.5.5 0 00.5-.5v-1a1 1 0 012 0v1a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V15a1.5 1.5 0 013 0v1.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-1a1 1 0 012 0v1a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-2.75a.25.25 0 01.25-.25.75.75 0 000-1.5zM21.5 18.5h-19a.5.5 0 00-.5.5v4.5a.5.5 0 00.5.5H9a.5.5 0 00.5-.5V23a2.5 2.5 0 015 0v.5a.5.5 0 00.5.5h6.5a.5.5 0 00.5-.5V19a.5.5 0 00-.5-.5zM2.5 10.5h19a.5.5 0 00.5-.5V9a1.5 1.5 0 00-1.5-1.5h-2.037a.5.5 0 00-.494.426 3.064 3.064 0 01-.576 1.374.5.5 0 01-.8-.594A1.989 1.989 0 0017 7.5c0-1.869-1.21-2.572-2.492-3.315a9.716 9.716 0 01-2.154-1.539.5.5 0 00-.708 0 10.839 10.839 0 01-2.407 1.687C8.051 5.03 7 5.758 7 7.5a1.989 1.989 0 00.411 1.2.5.5 0 01-.8.594 3.056 3.056 0 01-.575-1.371.5.5 0 00-.499-.423H3.5A1.5 1.5 0 002 9v1a.5.5 0 00.5.5z\"/><path d=\"M3 6h3a.5.5 0 00.5-.5 3 3 0 00-.895-1.788A.5.5 0 015.5 3.4V1a1 1 0 00-2 0v2.4a.5.5 0 01-.1.306A3 3 0 002.5 5.5.5.5 0 003 6zM18 6h3a.5.5 0 00.5-.5 2.977 2.977 0 00-.894-1.775.5.5 0 01-.106-.307V1a1 1 0 00-2 0v2.418a.5.5 0 01-.106.307A2.977 2.977 0 0017.5 5.5a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});