define("ember-svg-jar/inlined/style-three-pin-facade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-facade</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm3.9 4.362a1.251 1.251 0 010 1.767l-1.7 1.7a.5.5 0 000 .707l.177.177a1.751 1.751 0 010 2.475l-.53.53a.5.5 0 000 .707l.306.307a1.5 1.5 0 01-.546 2.469l-4.763 1.738A.991.991 0 018.5 17a1 1 0 01-.941-1.342L9.3 10.892a1.5 1.5 0 012.469-.546l.307.307a.5.5 0 00.707 0l.53-.531a.249.249 0 000-.353L11.9 8.354a.758.758 0 01-.165-.248 1.236 1.236 0 01.165-1.52l2.225-2.224a1.252 1.252 0 011.775 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});