define("ember-svg-jar/inlined/army-symbol-artillery-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-symbol-artillery-1</title><path d=\"M22 3.5H2a2 2 0 00-2 2v13a2 2 0 002 2h20a2 2 0 002-2v-13a2 2 0 00-2-2zM22 18a.5.5 0 01-.5.5h-19A.5.5 0 012 18V6a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M15 6.5H9a5.5 5.5 0 000 11h6a5.5 5.5 0 000-11zm0 9H9a3.5 3.5 0 010-7h6a3.5 3.5 0 010 7z\"/><circle cx=\"12\" cy=\"12\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});