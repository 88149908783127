define("ember-svg-jar/inlined/task-list-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-list-search</title><path d=\"M12.767 8.516a.75.75 0 00-.75-.75h-6a.75.75 0 000 1.5h6a.75.75 0 00.75-.75z\"/><path d=\"M10.047 19.168a.242.242 0 00-.224-.152H3.517a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v3.865a.246.246 0 00.294.242 6.85 6.85 0 012.412 0 .246.246 0 00.294-.242V3.516a1.5 1.5 0 00-1.5-1.5h-4a.241.241 0 01-.207-.121 3.828 3.828 0 00-6.589 0 .241.241 0 01-.207.121h-4a1.5 1.5 0 00-1.5 1.5v17a1.5 1.5 0 001.5 1.5h10.018a.249.249 0 00.179-.424 7.009 7.009 0 01-1.664-2.424z\"/><path d=\"M10.687 12.657a7.006 7.006 0 01.964-1.159c.082-.08.118-.232-.258-.232H6.017a.75.75 0 000 1.5h4.47a.239.239 0 00.2-.109zM6.017 14.766a.75.75 0 100 1.5H9.3a.247.247 0 00.245-.233 6.833 6.833 0 01.134-.965.246.246 0 00-.24-.3zM23.69 22.276l-2.505-2.506a.242.242 0 01-.031-.3 5.518 5.518 0 10-1.688 1.687.243.243 0 01.3.031l2.5 2.506a1 1 0 001.414-1.414zm-7.173-2.26a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});