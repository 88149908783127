define("ember-svg-jar/inlined/car-retro-luggage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-retro-luggage</title><rect x=\"6.996\" y=\".5\" width=\"5\" height=\"7\" rx=\"1.5\" ry=\"1.5\"/><path d=\"M14.5 7.5h1.258a.5.5 0 00.5-.5V3a.5.5 0 00-.5-.5H14.5A1.5 1.5 0 0013 4v2a1.5 1.5 0 001.5 1.5zM18.254 7.5H19.5A1.5 1.5 0 0021 6V4a1.5 1.5 0 00-1.5-1.5h-1.246a.5.5 0 00-.5.5v4a.5.5 0 00.5.5zM23.246 20a.482.482 0 01-.476-.4l-.558-3.351A9.228 9.228 0 0013.084 8.5a9.723 9.723 0 00-8.946 5.9l-.358.838a.5.5 0 01-.4.3C1.241 15.794.106 17.45 0 20.469A1 1 0 001 21.5h.082a.437.437 0 00.434-.382 3 3 0 015.946-.046.5.5 0 00.5.428h7.071a.5.5 0 00.495-.428 3 3 0 015.939 0 .5.5 0 00.495.428h1.285a.75.75 0 000-1.5zM11.5 14.5a.5.5 0 01-.5.5H7.36a.5.5 0 01-.415-.779 8.076 8.076 0 013.894-3.05.5.5 0 01.657.475zm7.091.241a.5.5 0 01-.441.259H14a.5.5 0 01-.5-.5v-2.968a.5.5 0 01.564-.5 6.686 6.686 0 014.512 3.2.5.5 0 01.011.509z\"/><circle cx=\"4.496\" cy=\"21.5\" r=\"2\"/><circle cx=\"18.496\" cy=\"21.5\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});