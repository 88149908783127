define("ember-svg-jar/inlined/graph-stats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>graph-stats</title><path d=\"M22.75 11.251h-4.879l-1.606-4.2a1.25 1.25 0 00-2.392.2l-1.458 7.116L10.6 2.314a1.25 1.25 0 00-2.438-.158l-2.614 9.095H1.25a1.25 1.25 0 000 2.5h5.239a1.248 1.248 0 001.2-.906l1.29-4.489 2.013 13.331a1.25 1.25 0 001.2 1.063h.033a1.25 1.25 0 001.224-1l2.009-9.8.382 1a1.249 1.249 0 001.167.8h5.743a1.25 1.25 0 000-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});