define("ember-svg-jar/inlined/grid-ruler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>grid-ruler</title><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h14.5a3.741 3.741 0 002.414-1L23 18.914a3.745 3.745 0 001-2.414zm-6 14a2 2 0 00-2 2v3.75a.25.25 0 01-.25.25h-8a.25.25 0 01-.25-.25v-14a.25.25 0 01.25-.25h14a.25.25 0 01.25.25v8a.25.25 0 01-.25.25zm4-10.25a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V4a.75.75 0 00-1.5 0v1.75a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25V5A.75.75 0 0014 5v.75a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25V4a.75.75 0 00-1.5 0v1.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-3.5A.25.25 0 017.75 2H21.5a.5.5 0 01.5.5zM5.75 2a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25h-3.5A.25.25 0 012 5.75V2.5a.5.5 0 01.5-.5zM2 7.75a.25.25 0 01.25-.25h3.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H4A.75.75 0 004 11h1.75a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25H5a.75.75 0 000 1.5h.75a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25H4A.75.75 0 004 20h1.75a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H2.5a.5.5 0 01-.5-.5zm16.427 12.909a.25.25 0 01-.427-.177V18.25a.25.25 0 01.25-.25h2.232a.25.25 0 01.177.427z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});