define("ember-svg-jar/inlined/real-estate-sign-for-rent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-sign-for-rent</title><rect x=\"4\" y=\"19.5\" width=\"4\" height=\"4.5\" rx=\".5\" ry=\".5\"/><rect x=\"16\" y=\"19.5\" width=\"4\" height=\"4.5\" rx=\".5\" ry=\".5\"/><path d=\"M16.251 3.125a.125.125 0 00-.125.125v1a.125.125 0 00.125.125.625.625 0 000-1.25zM12.5 3.125a.376.376 0 00-.375.375v3a.375.375 0 00.75 0v-3a.375.375 0 00-.375-.375zM6.251 11.125a.125.125 0 00-.125.125v1a.125.125 0 00.125.125.625.625 0 100-1.25z\"/><path d=\"M21.5 0h-19A1.5 1.5 0 001 1.5v15A1.5 1.5 0 002.5 18h19a1.5 1.5 0 001.5-1.5v-15A1.5 1.5 0 0021.5 0zM7.144 13.689l.894 1.49a.625.625 0 01-.215.857.618.618 0 01-.321.089.626.626 0 01-.537-.3l-.374-.625a.251.251 0 00-.465.129v.171a.625.625 0 01-1.25 0v-5a.625.625 0 01.624-.625h.75a1.873 1.873 0 01.977 3.472.25.25 0 00-.083.342zm.982-7.314V7.5a.625.625 0 11-1.25 0v-4A1.627 1.627 0 018.5 1.875h1a.625.625 0 010 1.25h-1a.376.376 0 00-.375.375v1.125a.25.25 0 00.25.25H9a.625.625 0 010 1.25h-.624a.25.25 0 00-.25.25zm2.25 6.5H11.5a.625.625 0 010 1.25h-1.124a.25.25 0 00-.25.25v.125a.376.376 0 00.375.375h1a.625.625 0 010 1.25h-1A1.627 1.627 0 018.876 14.5v-3A1.627 1.627 0 0110.5 9.875h1a.625.625 0 010 1.25h-1a.376.376 0 00-.375.375v1.125a.25.25 0 00.251.25zm.5-6.375v-3a1.625 1.625 0 113.25 0v3a1.625 1.625 0 01-3.25 0zm5.25 9a.625.625 0 01-1.205.232l-.312-.782a.251.251 0 00-.483.093v.457a.625.625 0 01-1.25 0v-5a.626.626 0 011.206-.232l.312.782a.25.25 0 00.482-.093V10.5a.625.625 0 111.25 0zm.184-8.415a.249.249 0 00-.184.241V7.5a.625.625 0 01-1.25 0v-5a.625.625 0 01.625-.625h.75a1.873 1.873 0 01.977 3.472.25.25 0 00-.084.342l.894 1.49a.625.625 0 01-.215.857.618.618 0 01-.321.089.626.626 0 01-.537-.3l-.374-.625a.25.25 0 00-.281-.115zm3.191 4.04h-.125a.25.25 0 00-.25.25V15.5a.625.625 0 11-1.25 0v-4.125a.249.249 0 00-.25-.25H17.5a.625.625 0 010-1.25h2a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});