define("ember-svg-jar/inlined/content-quill-ink", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-quill-ink</title><path d=\"M8.5 17.312V16.5A.5.5 0 008 16H5a.5.5 0 00-.5.5v.758a5.958 5.958 0 00-4.463 4.7 1.886 1.886 0 00.24 1.365A1.4 1.4 0 001.442 24h10.116a1.393 1.393 0 001.168-.688 1.881 1.881 0 00.234-1.371 6.119 6.119 0 00-4.46-4.629z\"/><rect x=\"3.5\" y=\"12.5\" width=\"6\" height=\"2.5\" rx=\".5\" ry=\".5\"/><path d=\"M24 .645a.634.634 0 00-.182-.463.623.623 0 00-.463-.182 11.172 11.172 0 00-4.92 1.341.249.249 0 00-.129.237l.232 3.269a.121.121 0 01-.154.133l-1.455-2.26a.246.246 0 00-.177-.114.263.263 0 00-.2.062 10.584 10.584 0 00-3.3 6.137c-.019.122 0 .522-.085.61l-2.061 2.065a1 1 0 000 1.415 1.015 1.015 0 001.414 0l2.063-2.064a10.66 10.66 0 006.38-2.971A10.812 10.812 0 0024 .645z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});