define("ember-svg-jar/inlined/equipment-cement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>equipment-cement</title><path d=\"M3.353 8.5h17.294a.5.5 0 00.5-.5 3.822 3.822 0 00-3.811-3.793.25.25 0 01-.235-.164 5.444 5.444 0 00-10.2 0 .252.252 0 01-.235.164A3.828 3.828 0 002.853 8a.5.5 0 00.5.5z\"/><circle cx=\"7\" cy=\"21.5\" r=\"2\"/><path d=\"M23.771 10.363A1 1 0 0023 10H1a1 1 0 00-.982 1.188l1.5 7.874a2.953 2.953 0 001.333 1.951.5.5 0 00.754-.309 3.492 3.492 0 016.868.359.5.5 0 00.5.437h2.062a.5.5 0 00.5-.437 3.493 3.493 0 016.87-.353.5.5 0 00.757.309 2.986 2.986 0 001.328-1.957l1.5-7.874a1 1 0 00-.219-.825z\"/><circle cx=\"17\" cy=\"21.5\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});