define("ember-svg-jar/inlined/3d-print-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>3d-print-warning</title><path d=\"M2.251 16.3a.249.249 0 01-.25-.25V2.25a.25.25 0 01.25-.25h6.022a.25.25 0 01.25.25v.818a.406.406 0 00.082.245L9.423 4.4a.41.41 0 00.655 0l.822-1.087a.406.406 0 00.082-.245V2.25a.25.25 0 01.25-.25h6.023a.251.251 0 01.25.25v7.51a.25.25 0 00.23.249 2.949 2.949 0 011.383.469.251.251 0 00.387-.209V1.75A1.753 1.753 0 0017.751 0h-16A1.752 1.752 0 000 1.75v16a1.752 1.752 0 001.75 1.75h8.83a.253.253 0 00.222-.134l1.419-2.7A.25.25 0 0012 16.3z\"/><path d=\"M6.8 4.2a.75.75 0 00-1.06 1.06l1.026 1.02a.75.75 0 101.061-1.06zM12.7 4.2l-1.026 1.02a.75.75 0 001.061 1.06l1.023-1.023A.75.75 0 0012.7 4.2zM18.781 12.271a1.449 1.449 0 00-2.561 0l-5.055 9.634a1.426 1.426 0 00.047 1.408 1.454 1.454 0 001.233.687h10.111a1.456 1.456 0 001.233-.686 1.428 1.428 0 00.047-1.409zM17.5 15.25a.75.75 0 01.75.75v3a.75.75 0 01-1.5 0v-3a.75.75 0 01.75-.75zm-1 6a1 1 0 111 1 1 1 0 01-1-1zM10.182 7.5a.519.519 0 00-.863 0l-3.5 6a.5.5 0 00.432.752h7a.5.5 0 00.431-.752z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});