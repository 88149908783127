define("ember-svg-jar/inlined/desktop-computer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>desktop-computer</title><path d=\"M23.5 2a2 2 0 00-2-2h-19a2 2 0 00-2 2v12a2 2 0 002 2h19a2 2 0 002-2zm-2 11.5a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5v-11A.5.5 0 013 2h18a.5.5 0 01.5.5zM22.327 22.746l-1.247-4.488a1 1 0 00-.97-.757H3.891a1 1 0 00-.968.745L1.67 22.759A1 1 0 002.641 24H21.36a1.009 1.009 0 00.788-.385.994.994 0 00.182-.857zm-5.827-.995a.5.5 0 01-.5.5H8a.5.5 0 010-1h8a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});