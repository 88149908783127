define("ember-svg-jar/inlined/time-clock-circle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-clock-circle-1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 20.5a1.25 1.25 0 111.25-1.25A1.25 1.25 0 0112 20.5zm0-7.5a1 1 0 01-.707-.293l-3-3a1 1 0 011.414-1.413L12.414 11H19a1 1 0 010 2zm-6-1a1.25 1.25 0 11-1.25-1.25A1.25 1.25 0 016 12zm6-8.5a1.25 1.25 0 11-1.25 1.25A1.25 1.25 0 0112 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});