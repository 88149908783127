define("ember-svg-jar/inlined/headphones", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>headphones</title><path d=\"M7.444 14.022a5 5 0 00.223 9.959.313.313 0 00.333-.313v-9.149a.5.5 0 00-.556-.497zM16.556 14.022a.5.5 0 00-.556.5v9.116a.346.346 0 00.369.343 5 5 0 00.187-9.956z\"/><path d=\"M12 .019a12.013 12.013 0 00-12 12v6a1 1 0 002 0v-6a10 10 0 0120 0v6a1 1 0 002 0v-6a12.013 12.013 0 00-12-12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});