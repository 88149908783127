define("ember-svg-jar/inlined/conversation-question-warning-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>conversation-question-warning-alternate</title><path d=\"M7.375 18.718l-.875.7V17.5a1 1 0 00-1-1h-3A.5.5 0 012 16V2.5a.5.5 0 01.5-.5H19a.5.5 0 01.5.5v5a1 1 0 002 0V2a2 2 0 00-2-2H2a2 2 0 00-2 2v14.5a2 2 0 002 2h2.5v3a1 1 0 001.625.782l2.5-2a1 1 0 00-1.25-1.563z\"/><path d=\"M22 10H12a2 2 0 00-2 2v7a2 2 0 002 2h3.667l3.733 2.8A1 1 0 0021 23v-2h1a2 2 0 002-2v-7a2 2 0 00-2-2zm0 8.5a.5.5 0 01-.5.5H20a1 1 0 00-1 1v1l-2.4-1.8a1 1 0 00-.6-.2h-3.5a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5h9a.5.5 0 01.5.5zM11.25 8.5v-.171a.25.25 0 01.167-.236A2.75 2.75 0 107.75 5.5a.75.75 0 001.5 0 1.25 1.25 0 111.666 1.178A1.757 1.757 0 009.75 8.329V8.5a.75.75 0 001.5 0z\"/><circle cx=\"16.75\" cy=\"17.708\" r=\".75\"/><path d=\"M16.75 16.207a.75.75 0 00.75-.75v-2a.75.75 0 10-1.5 0v2a.75.75 0 00.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});