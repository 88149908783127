define("ember-svg-jar/inlined/meeting-team-laptop-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-team-laptop-woman</title><path d=\"M24 12a.5.5 0 00-.5-.5h-.75a.249.249 0 01-.25-.25V2a2 2 0 00-2-2h-13a2 2 0 00-2 2v6.3a.251.251 0 00.281.248 5.767 5.767 0 011.438 0A.25.25 0 007.5 8.3V2.5A.5.5 0 018 2h12a.5.5 0 01.5.5v8.75a.249.249 0 01-.25.25h-8.417a.25.25 0 00-.229.35 7.491 7.491 0 01.576 2.512.147.147 0 00.147.138H21.5A2.5 2.5 0 0024 12z\"/><circle cx=\"14.294\" cy=\"4.624\" r=\"1.625\"/><path d=\"M11.618 8.944A.408.408 0 0012 9.5h4.588a.409.409 0 00.383-.555 2.844 2.844 0 00-5.353 0zM12.716 24a.278.278 0 00.21-.1.313.313 0 00.073-.23 6.4 6.4 0 00-.549-2.107c-.4-.846-2.068-1.432-4.163-2.254a.6.6 0 01-.37-.563v-.3a.246.246 0 01.074-.177.251.251 0 01.178-.073 4.311 4.311 0 002.662-.7.3.3 0 00.132-.213.319.319 0 00-.071-.244c-.357-.414-.6-.914-.709-2.558C9.994 11.538 8.115 10.5 6.5 10.5s-3.494 1.039-3.683 3.99c-.1 1.644-.352 2.144-.709 2.558a.319.319 0 00-.071.244.3.3 0 00.132.213 4.311 4.311 0 002.662.7.251.251 0 01.178.073.246.246 0 01.074.177v.3a.6.6 0 01-.37.563c-2.1.822-3.764 1.408-4.163 2.254a6.4 6.4 0 00-.55 2.1.313.313 0 00.073.23.278.278 0 00.21.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});