define("ember-svg-jar/inlined/punishment-handcuffs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>punishment-handcuffs</title><path d=\"M8.832 13.8l-.753-3.04a1 1 0 00-.971-.76H3.891a1 1 0 00-.971.759l-.742 2.989a1.5 1.5 0 01-.431.732A5.5 5.5 0 1011 18.5a5.233 5.233 0 00-1.735-3.961 1.491 1.491 0 01-.433-.739zM5.5 21A2.5 2.5 0 118 18.5 2.5 2.5 0 015.5 21zM22.265 14.539a1.491 1.491 0 01-.433-.735l-.753-3.044a1 1 0 00-.971-.76h-3.217a1 1 0 00-.971.759l-.742 2.989a1.5 1.5 0 01-.431.732A5.5 5.5 0 1024 18.5a5.233 5.233 0 00-1.735-3.961zM18.5 21a2.5 2.5 0 112.5-2.5 2.5 2.5 0 01-2.5 2.5zM17.25 7.75v.5a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-.5a7.75 7.75 0 00-15.5 0v.5a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-.5a5.25 5.25 0 0110.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});