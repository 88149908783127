define("ember-svg-jar/inlined/road-sign-side-road-left-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-side-road-left-1_1</title><path d=\"M.22 12.53l11.25 11.25a.748.748 0 001.06 0l11.25-11.25a.749.749 0 000-1.06L12.53.22a.749.749 0 00-1.06 0L.22 11.47a.749.749 0 000 1.06zm13.74 3.97h-2a.5.5 0 01-.5-.5v-2.25a.249.249 0 00-.25-.25H8.96a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2.25a.249.249 0 00.25-.25V8a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v8a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});