define("ember-svg-jar/inlined/social-500px", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-500px</title><path d=\"M19.5 9.5a4.57 4.57 0 00-3.5 1.61 4.57 4.57 0 00-3.5-1.61 4.52 4.52 0 00-4 2.32 4.48 4.48 0 00-6-2.41L2.71 8h5.64a1 1 0 000-2h-6.5a1 1 0 00-1 .85L0 12.23a1 1 0 102 .46A2.5 2.5 0 114.35 16a2.49 2.49 0 01-2.25-1.4 1 1 0 00-1.79.88 4.49 4.49 0 008.07 0A4.52 4.52 0 0012.5 18a4.57 4.57 0 003.5-1.61A4.57 4.57 0 0019.5 18a4.39 4.39 0 004.5-4.25 4.39 4.39 0 00-4.5-4.25zm-7 6.5a2.39 2.39 0 01-2.5-2.25 2.39 2.39 0 012.5-2.25 2.39 2.39 0 012.5 2.25A2.39 2.39 0 0112.5 16zm7 0a2.39 2.39 0 01-2.5-2.25 2.39 2.39 0 012.5-2.25 2.39 2.39 0 012.5 2.25A2.39 2.39 0 0119.5 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});