define("ember-svg-jar/inlined/electronics-zener-diode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>electronics-zener-diode</title><path d=\"M23 11h-3.75a.25.25 0 01-.25-.25V6a1 1 0 00-.293-.707l-2-2a1 1 0 00-1.414 1.414l1.634 1.634a.247.247 0 01.073.177v3.668a.25.25 0 01-.376.216L7.7 5.194a1.5 1.5 0 00-1.448-.03 1.4 1.4 0 00-.752 1.23v4.352a.25.25 0 01-.25.25H1a1 1 0 000 2h4.25a.25.25 0 01.25.25V17.6a1.4 1.4 0 00.754 1.231A1.51 1.51 0 007.7 18.8l8.923-5.209a.251.251 0 01.376.216V18a1 1 0 00.293.707l2 2a1 1 0 001.414-1.414l-1.633-1.633a.251.251 0 01-.073-.177v-4.237a.25.25 0 01.25-.25H23a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});