define("ember-svg-jar/inlined/dressing-mirror", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dressing-mirror</title><path d=\"M11.25 17.5a.5.5 0 00-.5-.5h-7.5a.5.5 0 00-.5.5v5a1.5 1.5 0 001.5 1.5h6.5a.5.5 0 00.5-.5zm-2.5 3a1 1 0 111-1 1 1 0 01-1 1zM20.75 17h-7.5a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h6.5a1.5 1.5 0 001.5-1.5v-5a.5.5 0 00-.5-.5zm-5.5 3.5a1 1 0 111-1 1 1 0 01-1 1zM22.75 14.5a1 1 0 00-1-1H20.5a.25.25 0 01-.25-.25v-5a8.25 8.25 0 00-16.5 0v5a.25.25 0 01-.25.25H2.25a1 1 0 000 2h19.5a1 1 0 001-1zm-17-6.25a6.25 6.25 0 0112.5 0v5a.25.25 0 01-.25.25H6a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});