define("ember-svg-jar/inlined/touchpad-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>touchpad-1</title><path d=\"M9.719 20.393a3.41 3.41 0 013.173-3.172.249.249 0 00.233-.249v-2.6a3.752 3.752 0 014.365-3.7 3.833 3.833 0 013.135 3.794v1.512a.25.25 0 00.2.245 5.313 5.313 0 012.388 1.146.25.25 0 00.411-.192V8.626a2.5 2.5 0 00-2.5-2.5h-12a2.5 2.5 0 00-2.5 2.5v9.5a2.5 2.5 0 002.5 2.5h.344a.25.25 0 00.251-.233zM8.125 4.126a1 1 0 00-1-1 4 4 0 00-4 4 1 1 0 002 0 2 2 0 012-2 1 1 0 001-1z\"/><path d=\"M6.625 2.126a1 1 0 100-2 6.508 6.508 0 00-6.5 6.5 1 1 0 002 0 4.5 4.5 0 014.5-4.5zM19.792 17.376h-.167a.25.25 0 01-.25-.25v-2.657a2.572 2.572 0 00-2.086-2.56 2.5 2.5 0 00-2.914 2.467v4.053a.25.25 0 01-.353.227 2.164 2.164 0 00-2.427 3.5l1.5 1.5a.75.75 0 001.06-1.061l-1.5-1.5a.664.664 0 01.94-.939l1 1a.75.75 0 001.28-.53v-6.25a1 1 0 011.174-.986 1.061 1.061 0 01.826 1.079v3.657a.75.75 0 00.75.75h1.167a2.586 2.586 0 012.583 2.583v1.667a.75.75 0 101.5 0v-1.667a4.088 4.088 0 00-4.083-4.083z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});