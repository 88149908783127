define("ember-svg-jar/inlined/email-action-cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-cash</title><path d=\"M5.12 13.041a.25.25 0 00.385-.21L5.5 2.25A.249.249 0 015.75 2h12.5a.25.25 0 01.25.25l.01 10.575a.25.25 0 00.385.21l4.053-2.607a.248.248 0 00.115-.206.251.251 0 00-.107-.21L20.5 8.3V2.207a2.114 2.114 0 00-2-2.208h-13a2.115 2.115 0 00-2 2.209V8.3l-2.458 1.71a.249.249 0 00.007.415z\"/><path d=\"M18.409 15.131a.758.758 0 01-.409.119H6a.757.757 0 01-.405-.119l-5.21-3.35a.25.25 0 00-.385.21V22a2 2 0 002 2h20a2 2 0 002-2V11.993a.25.25 0 00-.385-.21z\"/><path d=\"M9.375 6.592a2.082 2.082 0 001.315 1.942l2.063.825a.592.592 0 01-.22 1.141H10.5a.75.75 0 000 1.5h.75v.5a.75.75 0 001.5 0v-.522a2.085 2.085 0 00.56-4.012l-2.063-.825A.592.592 0 0111.467 6H13.5a.75.75 0 000-1.5h-.75V4a.75.75 0 00-1.5 0v.522a2.088 2.088 0 00-1.875 2.07z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});