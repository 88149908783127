define("ember-svg-jar/inlined/music-genre-stars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-genre-stars</title><path d=\"M18.5 11.705a2 2 0 00-2.37-1.966l-6 1.125A2 2 0 008.5 12.83v4.508a.25.25 0 01-.308.243A3 3 0 1010.5 20.5v-7.255a.5.5 0 01.407-.491l5-.938a.5.5 0 01.593.492v3.03a.25.25 0 01-.308.243A3 3 0 1018.5 18.5zM22.569 6a.5.5 0 000-.99l-.332-.047a2.011 2.011 0 01-1.7-1.7l-.047-.332a.5.5 0 00-.991 0l-.046.331a2.012 2.012 0 01-1.7 1.7l-.331.047a.5.5 0 000 .99l.332.047a2.012 2.012 0 011.7 1.7l.046.332a.5.5 0 00.991 0l.047-.331a2.011 2.011 0 011.7-1.7zM6.232 11h.016A.751.751 0 007 10.288C7.083 8.583 8.816 6.5 10.748 6.5a.75.75 0 00.015-1.5A4.125 4.125 0 017 1.282V1.25A.749.749 0 006.249.5a.749.749 0 00-.75.748A4.127 4.127 0 011.737 5 .747.747 0 001 5.755a.755.755 0 00.755.745A4.036 4.036 0 015.5 10.257a.749.749 0 00.732.743z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});