define("ember-svg-jar/inlined/pin-x-mark-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin-x-mark-1</title><path d=\"M17.449 19.684a1 1 0 00-1.265-.633l-4.026 1.342a.5.5 0 01-.316 0l-4.026-1.342a1 1 0 10-.632 1.9l.942.315a.25.25 0 010 .474l-.942.314a1 1 0 10.632 1.9l4.026-1.342a.5.5 0 01.316 0l4.026 1.342a1 1 0 10.632-1.9l-.942-.314a.25.25 0 010-.474l.942-.315a1 1 0 00.633-1.267z\"/><path d=\"M12 0a8 8 0 00-1.926 15.766l1.479 2.958a.5.5 0 00.894 0l1.479-2.958A8 8 0 0012 0zm0 13.5A5.5 5.5 0 1117.5 8a5.506 5.506 0 01-5.5 5.5z\"/><path d=\"M12 4a4 4 0 104 4 4 4 0 00-4-4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});