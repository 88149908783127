define("ember-svg-jar/inlined/sea-transport-buoy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sea-transport-buoy</title><path d=\"M22.92 21.612c-1.677.083-3.14-1.7-3.153-1.714a1 1 0 00-1.64.117 3.269 3.269 0 01-2.639 1.509 3.086 3.086 0 01-2.618-1.469 1 1 0 00-1.765 0 3.06 3.06 0 01-2.617 1.47 3.267 3.267 0 01-2.636-1.5 1 1 0 00-1.638-.13c-.015.018-1.45 1.762-3.143 1.7a1 1 0 10-.081 2 5.728 5.728 0 003.754-1.443.251.251 0 01.328.009 4.992 4.992 0 003.416 1.366 4.929 4.929 0 003.334-1.259.249.249 0 01.331 0 4.93 4.93 0 003.335 1.259 4.984 4.984 0 003.41-1.362.251.251 0 01.328-.008 5.8 5.8 0 003.583 1.461h.209a1 1 0 00-.1-2zM.965 17.611h.2a5.8 5.8 0 003.581-1.459.251.251 0 01.328.009 5.5 5.5 0 001.48.982 1 1 0 10.816-1.826 3.788 3.788 0 01-1.522-1.3 1 1 0 00-.787-.488.989.989 0 00-.851.368c-.014.018-1.486 1.807-3.15 1.717a1.019 1.019 0 00-1.047.951 1 1 0 00.952 1.046zM14.956 16.611a1 1 0 001 .966h.035a5.135 5.135 0 003.278-1.342.248.248 0 01.334 0 4.7 4.7 0 003.441 1.333 1 1 0 00-.111-2A3.445 3.445 0 0120.281 14a1.048 1.048 0 00-1.716.023 3.386 3.386 0 01-2.643 1.559 1 1 0 00-.966 1.029zM11.487 17.885a2.5 2.5 0 001.136-4.725.25.25 0 01-.136-.222v-2.3a.25.25 0 01.25-.25h3.75a1 1 0 000-2h-3.75a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h3.75a1 1 0 000-2h-3.75a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h3.75a1 1 0 000-2h-3.75a.25.25 0 01-.25-.25v-.75a1 1 0 00-2 0v11.55a.25.25 0 01-.136.222 2.5 2.5 0 001.136 4.725z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});