define("ember-svg-jar/inlined/single-neutral-actions-flight.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-flight</title><path d=\"M10.8 14.91a2.674 2.674 0 01.376-.353l.9-.666a2.319 2.319 0 012.2-.311l2.441.908a.249.249 0 00.324-.313 5.529 5.529 0 00-.294-.721c-.539-1.076-2.307-1.725-4.753-2.625l-.624-.229a.589.589 0 01-.138-.31 2.619 2.619 0 01.058-1.3 5.618 5.618 0 001.466-4.314C12.756 2.256 11.177.5 9 .5S5.244 2.256 5.244 4.674A5.605 5.605 0 006.7 8.971a2.508 2.508 0 01.072 1.317.609.609 0 01-.134.308L6 10.829c-2.446.9-4.214 1.549-4.753 2.626A9.167 9.167 0 00.5 17a.5.5 0 00.5.5h9.146a.25.25 0 00.236-.331 2.321 2.321 0 01.418-2.259z\"/><path d=\"M22.046 15.055a1.8 1.8 0 00-1.411.269l-2 1.365a.25.25 0 01-.228.028l-4.65-1.732a.828.828 0 00-.784.112l-.892.661a1.324 1.324 0 00-.134.126.844.844 0 00.153 1.216l2.7 1.671a.25.25 0 01.118.2.246.246 0 01-.1.211l-.957.71a.25.25 0 01-.275.014l-1.172-.69a.775.775 0 00-.746.132l-.86.722a1.092 1.092 0 00-.138.134.762.762 0 00-.165.563.779.779 0 00.308.534l2.878 2.02a.983.983 0 001.084.031l7.854-4.877a1.866 1.866 0 00.871-1.605 1.78 1.78 0 00-.105-.6 1.866 1.866 0 00-1.349-1.215z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});