define("ember-svg-jar/inlined/real-estate-market-house-rise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-market-house-rise</title><path d=\"M13.668 3.54a.5.5 0 00.832-.374V1a1 1 0 00-1-1H11a.5.5 0 00-.332.874zM3 8v5a1 1 0 001 1h2.419a.5.5 0 00.366-.159l3.858-4.147A3.71 3.71 0 0112.6 8.6a.5.5 0 00.4-.489V8a.5.5 0 01.5-.5h2a.5.5 0 00.332-.874L8.664.252a1 1 0 00-1.328 0L.168 6.626A.5.5 0 00.5 7.5h2A.5.5 0 013 8zM23.25 9h-4a.75.75 0 00-.53 1.28l.913.913a.5.5 0 01.03.674l-1.643 1.97a.5.5 0 01-.794-.033l-2-2.851a2.244 2.244 0 00-3.485-.236L1.335 21.9a1.25 1.25 0 101.83 1.7l9.775-10.5a.5.5 0 01.775.054l2.031 2.9a2.252 2.252 0 003.552.16l2.082-2.5a.5.5 0 01.738-.033l.6.6A.75.75 0 0024 13.75v-4a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});