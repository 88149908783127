define("ember-svg-jar/inlined/library-music-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>library-music-1</title><path d=\"M14.874 14.741a.248.248 0 00-.251 0 3.171 3.171 0 01-1.623.51H2a.75.75 0 110-1.5h.5a.5.5 0 00.5-.5V8.5a.5.5 0 01.5-.5H5a.5.5 0 01.5.5v4.75a.5.5 0 00.5.5h4.24a.25.25 0 00.191-.41A3.95 3.95 0 019.5 10.8a3.992 3.992 0 012.667-3.756.5.5 0 00.333-.474V5.5A.5.5 0 0012 5H1.5A1.5 1.5 0 000 6.5v16A1.5 1.5 0 001.5 24h12a1.5 1.5 0 001.5-1.5v-7.542a.249.249 0 00-.126-.217zM5 22a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5zm4.828.276l-1.428.469a.5.5 0 01-.631-.319l-1.247-3.8a.5.5 0 01.319-.631l1.426-.468a.5.5 0 01.631.32l1.246 3.8a.5.5 0 01-.316.63z\"/><path d=\"M23.277.461a2 2 0 00-1.645-.425l-6 1.125A2 2 0 0014 3.127V7.8a.5.5 0 01-.5.5 2.5 2.5 0 102.5 2.5V3.541a.5.5 0 01.408-.491l5-.937A.5.5 0 0122 2.6v3.2a.5.5 0 01-.5.5A2.5 2.5 0 1024 8.8V2a2 2 0 00-.723-1.539z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});