define("ember-svg-jar/inlined/folder-search-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-search-1</title><path d=\"M10.233 9.02a3.62 3.62 0 000 7.239 3.707 3.707 0 003.62-3.62 3.624 3.624 0 00-3.62-3.619z\"/><path d=\"M22.5 4.5H11.736a.5.5 0 01-.447-.276l-.947-1.9A1.494 1.494 0 009 1.5H1.5A1.5 1.5 0 000 3v18a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 21V6a1.5 1.5 0 00-1.5-1.5zm-6.106 14.3a.75.75 0 01-1.061 0l-1.917-1.917a.243.243 0 00-.312-.025 5.079 5.079 0 01-6.491-.6 5.121 5.121 0 118.74-3.621 5.056 5.056 0 01-.9 2.87.244.244 0 00.025.313l1.917 1.916a.75.75 0 01-.001 1.064z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});