define("ember-svg-jar/inlined/natural-disaster-flood-house-wave", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>natural-disaster-flood-house-wave</title><path d=\"M23 15.25a2.772 2.772 0 01-2.43-1.428 1 1 0 00-.807-.565 1.017 1.017 0 00-.9.4 4.183 4.183 0 01-3.027 1.591 1 1 0 100 2 5.692 5.692 0 003.559-1.317.251.251 0 01.329.014A4.625 4.625 0 0023 17.25a1 1 0 000-2zM4.1 17.261a2.492 2.492 0 012.254 1.023 2.665 2.665 0 001.809.966 2.673 2.673 0 001.83-1 2.549 2.549 0 011.8-.978.218.218 0 00.2-.218V15a.25.25 0 01.25-.25H13.5a.5.5 0 00.324-.881l-6.5-5.5a.5.5 0 00-.647 0l-6.5 5.5a.5.5 0 00.323.881h1.25A.25.25 0 012 15v2.959a.25.25 0 00.441.162 2.4 2.4 0 011.659-.86z\"/><path d=\"M24 21.75a1 1 0 00-1-1 2.772 2.772 0 01-2.43-1.428 1 1 0 00-.807-.565 1.021 1.021 0 00-.9.4 4.183 4.183 0 01-3.027 1.591 4.179 4.179 0 01-3.022-1.586 1 1 0 00-.814-.41.943.943 0 00-.811.407 4.182 4.182 0 01-3.022 1.591 4.18 4.18 0 01-3.023-1.586 1.009 1.009 0 00-.9-.41 1 1 0 00-.811.568A2.748 2.748 0 011 20.75a1 1 0 000 2 4.625 4.625 0 003.279-1.3.251.251 0 01.329-.014 5.692 5.692 0 003.559 1.314 5.74 5.74 0 003.673-1.41.248.248 0 01.32 0 5.74 5.74 0 003.673 1.41 5.692 5.692 0 003.559-1.317.251.251 0 01.329.014A4.625 4.625 0 0023 22.75a1 1 0 001-1zM10.73 6.835a3.468 3.468 0 01.77-.085 3.5 3.5 0 013.5 3.5 3.463 3.463 0 01-.363 1.555 1 1 0 101.791.89A5.437 5.437 0 0017 10.25a5.506 5.506 0 00-5.5-5.5.167.167 0 01-.1-.3 6.01 6.01 0 013.6-1.2 7.008 7.008 0 017 7 1 1 0 002 0 9.01 9.01 0 00-9-9 8.011 8.011 0 00-5.978 2.684 1.768 1.768 0 001.708 2.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});