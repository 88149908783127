define("ember-svg-jar/inlined/electronics-capacitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>electronics-capacitor</title><path d=\"M17 12.251a.5.5 0 00.5-.5V1.5A1.5 1.5 0 0016 0H8a1.5 1.5 0 00-1.5 1.5v10.25a.5.5 0 00.5.5zM7 13.751a.5.5 0 00-.5.5V16a.5.5 0 00.5.5h1.25a.25.25 0 01.25.25V23a1 1 0 102 0v-6.25a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25V23a1 1 0 102 0v-6.25a.25.25 0 01.25-.25H17a.5.5 0 00.5-.5v-1.75a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});