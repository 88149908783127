define("ember-svg-jar/inlined/blood-drop-type-ab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>blood-drop-type-ab</title><path d=\"M15.572 13.6a7.48 7.48 0 01-4.559-9.946.5.5 0 00-.024-.432A27.846 27.846 0 009.269.381a1.1 1.1 0 00-1.638 0C6.155 2.317.45 13.286.45 16.488c0 3.925 3.813 7.5 8 7.5s8-3.575 8-7.5a9.37 9.37 0 00-.561-2.571.5.5 0 00-.317-.317zm-7.391 7.443a.749.749 0 01-.731.584.812.812 0 01-.167-.018 5.235 5.235 0 01-3.537-2.787.75.75 0 011.343-.668 3.738 3.738 0 002.527 1.992.749.749 0 01.565.897z\"/><path d=\"M15.1 2.738a2.4 2.4 0 00-2.4 2.389v3.861a.75.75 0 001.5 0v-.95a.5.5 0 01.5-.5h.8a.5.5 0 01.5.5v.95a.75.75 0 001.5 0V5.127a2.4 2.4 0 00-2.4-2.389zm.9 2.8a.5.5 0 01-.5.5h-.8a.5.5 0 01-.5-.5v-.411a.9.9 0 011.8 0zM23.55 4.863a2.128 2.128 0 00-2.125-2.125H19.5a.75.75 0 00-.75.75v5.5a.75.75 0 00.75.75h1.925a2.124 2.124 0 001.619-3.5 2.111 2.111 0 00.506-1.375zm-2.125 3.375h-.675a.5.5 0 01-.5-.5v-.25a.5.5 0 01.5-.5h.675a.625.625 0 010 1.25zm0-2.75h-.675a.5.5 0 01-.5-.5v-.25a.5.5 0 01.5-.5h.675a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});