define("ember-svg-jar/inlined/shape-cube", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shape-cube</title><path d=\"M23 2a1 1 0 00-1-1H8a1 1 0 00-.707.293l-6 6A1 1 0 001 8v14a1 1 0 001 1h14a1 1 0 00.707-.293l6-6A1 1 0 0023 16zM6.823 4.591a.25.25 0 01.427.177v.482a.249.249 0 00.25.25h1a.249.249 0 00.25-.25v-2A.25.25 0 019 3h9.983a.25.25 0 01.177.427l-3.5 3.5a.249.249 0 01-.178.073H5.018a.25.25 0 01-.177-.427zM15 20.75a.25.25 0 01-.25.25H4.664a.25.25 0 01-.177-.427l3.75-3.75a.251.251 0 01.177-.073h4.836a.25.25 0 00.25-.25v-1a.25.25 0 00-.25-.25H9a.25.25 0 01-.25-.25v-4.25a.25.25 0 00-.25-.25h-1a.25.25 0 00-.25.25v4.836a.251.251 0 01-.073.177l-3.75 3.75A.25.25 0 013 19.336V9.25A.25.25 0 013.25 9h11.5a.25.25 0 01.25.25zm2.427-1.591a.25.25 0 01-.427-.177V8.518a.249.249 0 01.073-.177l3.5-3.5a.25.25 0 01.427.177V15a.25.25 0 01-.25.25h-2a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h.482a.25.25 0 01.177.427z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});