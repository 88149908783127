define("ember-svg-jar/inlined/file-txt-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-txt-1</title><path d=\"M22 0H2A1.5 1.5 0 00.5 1.5v21A1.5 1.5 0 002 24h20a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022 0zM8 12.123H6.874a.249.249 0 00-.249.249V19.5a.625.625 0 01-1.25 0v-7.128a.249.249 0 00-.249-.249H4a.625.625 0 010-1.25h4a.625.625 0 010 1.25zm12.021 0H18.9a.249.249 0 00-.249.249V19.5a.625.625 0 11-1.25 0v-7.128a.249.249 0 00-.249-.249h-1.131a.625.625 0 010-1.25h4a.625.625 0 010 1.25zm-5.462 7.1a.624.624 0 01-.28.838.618.618 0 01-.279.066.625.625 0 01-.559-.346l-1.217-2.435a.251.251 0 00-.448 0l-1.217 2.435a.625.625 0 01-.559.346.618.618 0 01-.279-.066.624.624 0 01-.28-.838l1.8-3.609a.252.252 0 000-.224l-1.8-3.609a.625.625 0 011.118-.558l1.217 2.435a.25.25 0 00.448 0l1.217-2.435a.625.625 0 011.118.558l-1.8 3.609a.252.252 0 000 .224z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});