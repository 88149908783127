define("ember-svg-jar/inlined/phone-actions-restaurant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-restaurant</title><path d=\"M17.294 16.04a2.5 2.5 0 00-3.536 0l-.386.385a47.037 47.037 0 01-5.789-5.789l.386-.385a2.5 2.5 0 000-3.535L5.847 4.6a2.5 2.5 0 00-3.536 0L1.148 5.758a3.509 3.509 0 00-.442 4.4A46.919 46.919 0 0013.847 23.3a3.545 3.545 0 004.4-.443l1.168-1.157a2.5 2.5 0 000-3.535zM23.431.633a.8.8 0 00-.531-.22.744.744 0 00-.53.22l-5.914 5.914-.565-.565a3.162 3.162 0 00-.46-3.909L13.707.349a.75.75 0 10-1.061 1.06l1.724 1.725a1.649 1.649 0 01.4 1.727l-2.788-2.788a.75.75 0 10-1.06 1.061l2.788 2.788a1.686 1.686 0 01-1.728-.4L10.258 3.8A.75.75 0 109.2 4.857l1.725 1.725a3.168 3.168 0 003.909.461l.565.564-2.681 2.681a.75.75 0 000 1.061.761.761 0 001.06 0l2.681-2.681 2.693 2.692a.76.76 0 001.061 0 .749.749 0 000-1.06l-2.696-2.693.948-.948 1.194 1.194a.758.758 0 001.06 0 12.872 12.872 0 002.593-3.794c.488-1.123.909-2.625.119-3.426z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});