define("ember-svg-jar/inlined/ceiling-lamp-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ceiling-lamp-1</title><path d=\"M12 20.5a1 1 0 00-1 1V23a1 1 0 002 0v-1.5a1 1 0 00-1-1zM7.541 20.524a1 1 0 00-1.192.76l-.325 1.464a1 1 0 101.952.433l.324-1.465a1 1 0 00-.759-1.192zM17.651 21.284a1 1 0 10-1.952.432l.325 1.465a1 1 0 101.952-.433zM23 0H1a1 1 0 000 2h9.75a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25h-.883A1.494 1.494 0 008.39 6.246l-.377 1.642A.5.5 0 008.5 8.5h7a.5.5 0 00.487-.612l-.373-1.622A1.506 1.506 0 0014.133 5h-.883a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25H23a1 1 0 000-2zM16.479 10.354A.5.5 0 0016 10H8a.5.5 0 00-.479.357l-1.816 6.114a1.828 1.828 0 00.044 1.764A1.826 1.826 0 007.34 19h9.27a1.756 1.756 0 001.73-2.521z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});