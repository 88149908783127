define("ember-svg-jar/inlined/business-deal-cash-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-deal-cash-2</title><circle cx=\"20.222\" cy=\"10.048\" r=\"2.491\"/><path d=\"M16.734 18.52a.5.5 0 00.5.5h.818a.25.25 0 01.249.225l.431 4.309a.5.5 0 00.5.448h1.994a.5.5 0 00.495-.448l.431-4.309a.25.25 0 01.249-.225h.818a.5.5 0 00.5-.5v-2a3.488 3.488 0 00-6.976 0z\"/><circle cx=\"3.778\" cy=\"10.048\" r=\"2.491\"/><path d=\"M.29 18.52a.5.5 0 00.5.5h.818a.25.25 0 01.249.225l.431 4.309a.5.5 0 00.5.448h1.987a.5.5 0 00.495-.448l.43-4.311a.25.25 0 01.249-.225h.818a.5.5 0 00.5-.5v-2a3.488 3.488 0 00-6.976 0zM11.751 0a1.246 1.246 0 00-1.246 1.246v.42a.248.248 0 01-.2.244 3.2 3.2 0 00-.526 6.1L12.8 9.218a.716.716 0 01-.265 1.38H9.558a1.246 1.246 0 100 2.491h.7a.249.249 0 01.25.25v.331a1.246 1.246 0 002.492 0v-.419a.25.25 0 01.2-.245 3.2 3.2 0 00.526-6.1L10.706 5.7a.715.715 0 01.265-1.379h2.973a1.246 1.246 0 000-2.492h-.7a.249.249 0 01-.25-.25v-.333A1.246 1.246 0 0011.751 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});