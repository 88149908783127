define("ember-svg-jar/inlined/olympics-torch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>olympics-torch</title><circle cx=\"11.375\" cy=\"4.622\" r=\"2.5\"/><path d=\"M21.754 8.532a1.5 1.5 0 00-1.969-.788L16.855 9a1.521 1.521 0 01-.6.122H7.672a4.52 4.52 0 00-2.232.593L2.631 11.32a1.5 1.5 0 101.489 2.605l2.808-1.6a1.5 1.5 0 01.744-.2h1.954a.25.25 0 01.25.25v5a.249.249 0 01-.25.25h-4.25a1.5 1.5 0 000 3h7.593a1.493 1.493 0 011.465 1.175l.227 1.406a.5.5 0 00.492.42h2.051a.5.5 0 00.494-.587l-.336-1.894a4.449 4.449 0 00-4.252-3.515.249.249 0 01-.234-.249v-5.009a.25.25 0 01.25-.25h3.133a4.512 4.512 0 001.774-.363l.494-.212a.249.249 0 01.348.23v2.845a1 1 0 002 0V10.54a1.5 1.5 0 00.879-2.008zM19.864 1.241A9.542 9.542 0 0018.07.986 3.126 3.126 0 0116.291.5a.75.75 0 00-1.166.625A3.661 3.661 0 0017.7 4.686a2.248 2.248 0 004.424-.513.872.872 0 000-.09 2.963 2.963 0 00-2.26-2.842z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});