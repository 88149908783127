define("ember-svg-jar/inlined/radiology-scan-doctor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>radiology-scan-doctor</title><path d=\"M22.5 0h-10A1.5 1.5 0 0011 1.5v10a1.5 1.5 0 001.5 1.5h10a1.5 1.5 0 001.5-1.5v-10A1.5 1.5 0 0022.5 0zm-.445 5A.749.749 0 0121 5.055l-.642-.583-.088-.052a3.22 3.22 0 00-1.037-.17h-.483a.5.5 0 00-.5.5v.525a.5.5 0 00.452.5A4.79 4.79 0 0119.77 6a1.762 1.762 0 01.5.27l.891.686a.75.75 0 01-.916 1.188l-.887-.684a3.545 3.545 0 00-.511-.149.5.5 0 00-.6.49v.95a.5.5 0 00.5.5H19a.75.75 0 010 1.5h-3a.75.75 0 010-1.5h.25a.5.5 0 00.5-.5V7.8a.5.5 0 00-.592-.492 3.209 3.209 0 00-.448.117l-.959.722a.75.75 0 11-.916-1.188l.888-.684A1.766 1.766 0 0115.231 6a4.77 4.77 0 011.069-.229.5.5 0 00.452-.5V4.75a.5.5 0 00-.5-.5h-.479a3.22 3.22 0 00-1.037.17l-.088.052-.648.583a.75.75 0 11-1-1.11l.641-.583A1.715 1.715 0 0114.256 3a4.73 4.73 0 011.515-.248h3.458A4.752 4.752 0 0120.746 3a1.724 1.724 0 01.618.365l.64.582A.75.75 0 0122.055 5zM6.641 9.133A4.082 4.082 0 103.1 9.072 4.769 4.769 0 000 13.526v2.851a.5.5 0 00.5.5h1.232a.5.5 0 01.5.45l.622 6.224a.5.5 0 00.5.45H6.2a.5.5 0 00.5-.45l.623-6.224a.5.5 0 01.5-.45h1.225a.5.5 0 00.352-.147.5.5 0 00.146-.353v-2.851a4.781 4.781 0 00-2.905-4.393zm-1.117 4.084a.75.75 0 01-1.5 0v-1.692a.75.75 0 011.5 0zm-.6-5.692A2.077 2.077 0 012.85 5.451a1.981 1.981 0 01.094-.6.249.249 0 01.368-.138 5.076 5.076 0 002.638.737 5.152 5.152 0 00.75-.05.251.251 0 01.286.267 2.075 2.075 0 01-2.061 1.858z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});