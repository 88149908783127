define("ember-svg-jar/inlined/pin-remove-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin-remove-3</title><path d=\"M12 0a8.51 8.51 0 00-8.5 8.5c0 4.625 6.415 13.391 7.7 15.1a1 1 0 001.6 0c1.286-1.71 7.7-10.475 7.7-15.1A8.51 8.51 0 0012 0zm4.75 10.25h-9.5a1 1 0 010-2h9.5a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});