define("ember-svg-jar/inlined/medical-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-file</title><path d=\"M21.5 7a1 1 0 00-.293-.707l-6-6A1 1 0 0014.5 0h-10a2 2 0 00-2 2v20a2 2 0 002 2h15a2 2 0 002-2zM5 22a.5.5 0 01-.5-.5v-19A.5.5 0 015 2h8.5a.5.5 0 01.5.5v3a2 2 0 002 2h3a.5.5 0 01.5.5v13.5a.5.5 0 01-.5.5z\"/><path d=\"M17 12.214a.643.643 0 00-.643-.643h-1.928a.5.5 0 01-.5-.5V9.143a.643.643 0 00-.643-.643h-2.572a.642.642 0 00-.642.643v1.928a.5.5 0 01-.5.5H7.643a.643.643 0 00-.643.643v2.572a.643.643 0 00.643.643h1.929a.5.5 0 01.5.5v1.928a.642.642 0 00.642.643h2.572a.643.643 0 00.643-.643v-1.928a.5.5 0 01.5-.5h1.928a.643.643 0 00.643-.643z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});