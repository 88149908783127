define("ember-svg-jar/inlined/e-commerce-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-cart</title><path d=\"M13.32 15.931a2 2 0 012.611-2.611l1.986.818a.25.25 0 00.318-.119 9.5 9.5 0 10-4.216 4.216.25.25 0 00.119-.318zM6.25 14.25a1 1 0 111 1 1 1 0 01-1-1zm4 0a1 1 0 111 1 1 1 0 01-1-1zm4.712-9.763l-2.5 7.5a.752.752 0 01-.712.513h-5a.752.752 0 01-.712-.513l-1.5-4.5A.751.751 0 015.25 6.5h7.279a.25.25 0 00.237-.171l.772-2.316a.75.75 0 011.424.474z\"/><path d=\"M23.457 22.043L20.5 19.087a.25.25 0 01.078-.407l1.077-.461a.5.5 0 00-.006-.922l-6.29-2.59a.512.512 0 00-.191-.038.5.5 0 00-.462.69l2.59 6.29a.5.5 0 00.459.31.5.5 0 00.46-.3l.461-1.077a.252.252 0 01.183-.147.249.249 0 01.224.069l2.956 2.956a1 1 0 001.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});