define("ember-svg-jar/inlined/allowances-no-boots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>allowances-no-boots</title><path d=\"M4.75 15.467a4.23 4.23 0 001.443 3 2.935 2.935 0 001.827.782h6.733a.25.25 0 00.177-.427l-1-1a.251.251 0 00-.177-.073H8.02a1.963 1.963 0 01-1.352-1h5.585a.25.25 0 00.177-.427l-3.93-3.93a.25.25 0 00-.208-.072 5 5 0 00-2.354.83 2.811 2.811 0 00-1.188 2.317z\"/><path d=\"M24 12a12 12 0 10-12 12 12.013 12.013 0 0012-12zM11.25 7V5.5a.25.25 0 01.25-.25H16a.25.25 0 01.25.25V7a.25.25 0 01-.25.25h-4.5a.25.25 0 01-.25-.25zM22 12a9.947 9.947 0 01-2.486 6.577L16.75 15.72V8.574a1.748 1.748 0 001-1.574V5.5A1.752 1.752 0 0016 3.75h-4.5A1.752 1.752 0 009.75 5.5V7a1.75 1.75 0 001 1.575v.946L5.667 4.268A9.994 9.994 0 0122 12zM12 22A9.992 9.992 0 014.255 5.685l13.8 14.257A9.889 9.889 0 0112 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});