define("ember-svg-jar/inlined/mood-skull-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mood-skull-chat</title><path d=\"M24 2.952A1.954 1.954 0 0022.048 1H1.952A1.954 1.954 0 000 2.952v14.286a1.954 1.954 0 001.952 1.952h4.715V22a1 1 0 001.707.707l3.516-3.517h10.158A1.954 1.954 0 0024 17.238zM22 16.69a.5.5 0 01-.5.5H11.476a1 1 0 00-.707.293l-2.1 2.1v-1.4a1 1 0 00-1-1l-5.169.051a.5.5 0 01-.505-.5L2 3.5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M9.046 12.929v1.176a.9.9 0 001.8 0 .226.226 0 01.451 0 .9.9 0 001.8 0 .226.226 0 01.451 0 .9.9 0 001.8 0v-1.142a1.72 1.72 0 01.362-1.106 4.456 4.456 0 00.991-2.882 4.509 4.509 0 10-9.01.307 4.4 4.4 0 00.975 2.566 1.69 1.69 0 01.38 1.081zm4.659-5.143a1.2 1.2 0 11-1.2 1.2 1.2 1.2 0 011.2-1.2zm-3.006 0a1.2 1.2 0 11-1.2 1.2 1.2 1.2 0 011.201-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});