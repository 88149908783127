define("ember-svg-jar/inlined/add-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>add-square</title><path d=\"M23.5 2A1.5 1.5 0 0022 .5H2A1.5 1.5 0 00.5 2v20A1.5 1.5 0 002 23.5h20a1.5 1.5 0 001.5-1.5zM6 13a1 1 0 010-2h4.75a.25.25 0 00.25-.25V6a1 1 0 012 0v4.75a.25.25 0 00.25.25H18a1 1 0 010 2h-4.75a.25.25 0 00-.25.25V18a1 1 0 01-2 0v-4.75a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});