define("ember-svg-jar/inlined/office-stapler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-stapler</title><path d=\"M23.446 15.627a1.544 1.544 0 00-.158-2.126L10.514 2.148C8.922.88 7.836 1.64 7.335 2.2l-1.8 1.94a.5.5 0 00-.132.369.472.472 0 00.138.314.25.25 0 01.012.329L2.924 8.411a1 1 0 001.557 1.256l.247-.3A.252.252 0 014.9 9.27a.257.257 0 01.185.056l5.307 4.4a.25.25 0 01-.159.442H2.63a1 1 0 00-1.93.371v2.793a.249.249 0 01-.167.235.5.5 0 00-.333.472v2c0 1.544.335 2.5 2 2.5h17.5a1.5 1.5 0 001.5-1.5v-2.793a.252.252 0 01.166-.236.482.482 0 00.217-.151zM2.7 16.414a.25.25 0 01.25-.25h8.75a1 1 0 00.9-.572l.563-1.188a1 1 0 00-.266-1.2L6.334 7.767a.25.25 0 01-.035-.35l.768-.951a.251.251 0 01.357-.034L19.917 17.1a.25.25 0 01-.162.44H2.953a.25.25 0 01-.25-.25z\"/><circle cx=\"14.953\" cy=\"15.289\" r=\".75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});