define("ember-svg-jar/inlined/microphone-podcast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>microphone-podcast</title><path d=\"M17 9.75a.75.75 0 010-1.5h1.75A.25.25 0 0019 8V6a.25.25 0 00-.25-.25H17a.75.75 0 010-1.5h1.531a.25.25 0 00.24-.322A5.511 5.511 0 0013.5 0h-3a5.511 5.511 0 00-5.271 3.928.25.25 0 00.24.322H11a.75.75 0 010 1.5H5.25A.25.25 0 005 6v2a.25.25 0 00.25.25H11a.75.75 0 010 1.5H5.25A.25.25 0 005 10v2.5a5.508 5.508 0 004.714 5.443A.25.25 0 0010 17.7v-3.2a2.5 2.5 0 015 0v2.95a.251.251 0 00.334.236A5.511 5.511 0 0019 12.5V10a.25.25 0 00-.25-.25z\"/><path d=\"M13.5 14.5a1 1 0 00-2 0v4.419a.251.251 0 01-.183.241 4.533 4.533 0 00-3.272 3.7 1 1 0 00.99 1.14h6.929a1 1 0 00.991-1.142 4.529 4.529 0 00-3.271-3.7.251.251 0 01-.184-.242z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});