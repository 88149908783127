define("ember-svg-jar/inlined/keyboard-arrow-bottom-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-arrow-bottom-left</title><path d=\"M15.85 6.027L4.359 17.517a.25.25 0 01-.354 0l-2.3-2.3A1 1 0 000 15.926V23a1 1 0 001 1h7.071a1 1 0 00.707-1.707l-2.3-2.3a.249.249 0 010-.353L17.971 8.148a1.5 1.5 0 00-2.121-2.121z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});