define("ember-svg-jar/inlined/make-up-mirror-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>make-up-mirror-1</title><path d=\"M10.677 5.854a7.529 7.529 0 00-4.73 4.846 1 1 0 001.908.6 5.521 5.521 0 013.468-3.554 1 1 0 00-.646-1.892z\"/><path d=\"M1.655 17.5h2.276a.5.5 0 00.424-.764A9 9 0 1121 12a8.93 8.93 0 01-1.355 4.736.5.5 0 00.424.764h2.276a.5.5 0 00.45-.282A12 12 0 100 12a11.894 11.894 0 001.205 5.218.5.5 0 00.45.282zM23 19h-6.293a1 1 0 00-.707.293l-1.561 1.561a.5.5 0 01-.353.146H9.914a.5.5 0 01-.353-.146L8 19.293A1 1 0 007.293 19H1a1 1 0 00-1 1v.5C0 22.725 1.64 24 4.5 24h15c2.86 0 4.5-1.275 4.5-3.5V20a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});