define("ember-svg-jar/inlined/buildings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>buildings</title><path d=\"M11 9.5H1a1 1 0 00-1 1V23a1 1 0 001 1h3a.5.5 0 00.5-.5V21a1 1 0 011-1h1a1 1 0 011 1v2.5a.5.5 0 00.5.5h3a1 1 0 001-1V10.5a1 1 0 00-1-1zm-1.75 7.75h-6.5a.75.75 0 010-1.5h6.5a.75.75 0 010 1.5zm0-3.5h-6.5a.75.75 0 010-1.5h6.5a.75.75 0 010 1.5zM2.5 8h7a.5.5 0 00.5-.5v-4a1 1 0 00-1-1H6.75a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v1a.5.5 0 01-.5.5H3a1 1 0 00-1 1v4a.5.5 0 00.5.5zm2.25-3.25h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 010-1.5zM23 6h-8a1 1 0 00-1 1v16.5a.5.5 0 00.5.5H17a.5.5 0 00.5-.5V21a1 1 0 011-1h1a1 1 0 011 1v2.5a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5V7a1 1 0 00-1-1zm-1.75 11.75h-4.5a.75.75 0 010-1.5h4.5a.75.75 0 010 1.5zm0-4h-4.5a.75.75 0 010-1.5h4.5a.75.75 0 010 1.5zm0-4h-4.5a.75.75 0 010-1.5h4.5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});