define("ember-svg-jar/inlined/christmas-snow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-snow</title><circle cx=\"1\" cy=\"1\" r=\"1\"/><circle cx=\"1\" cy=\"9.5\" r=\"1\"/><circle cx=\"4.5\" cy=\"4.5\" r=\"1\"/><circle cx=\"9.5\" cy=\"1.5\" r=\"1\"/><circle cx=\"8\" cy=\"7\" r=\"1\"/><circle cx=\"13.5\" cy=\"4.5\" r=\"1\"/><circle cx=\"17.5\" cy=\"1\" r=\"1\"/><circle cx=\"23\" cy=\"3.5\" r=\"1\"/><circle cx=\"11.5\" cy=\"11\" r=\"1\"/><path d=\"M23.709 15.774a8.419 8.419 0 00-2.755-.746.5.5 0 01-.454-.5v-1.091a.5.5 0 01.5-.5h1.482a.622.622 0 00.439-1.062l-.881-1.127a.25.25 0 01.133-.395.623.623 0 00.3-1.051l-1.225-1.555a.249.249 0 01.129-.4.627.627 0 00.254-1.095l-1.763-1.903a.5.5 0 00-.736 0l-1.763 1.907a.627.627 0 00.254 1.095.249.249 0 01.129.4L16.523 9.3a.623.623 0 00.3 1.051.25.25 0 01.133.395l-.881 1.127a.622.622 0 00.439 1.062H18a.5.5 0 01.5.5v1.345a.5.5 0 01-.373.483 8.694 8.694 0 00-2.533 1.137l-1.438.933c-.138.09-.6.431-1.169.848-.41.3-.99.73-1.51 1.105a.25.25 0 00.084.445l3.174.823a12.156 12.156 0 007.953-.656l1.015-.45a.5.5 0 00.3-.457v-2.762a.5.5 0 00-.294-.455z\"/><path d=\"M23.3 21.265a13.661 13.661 0 01-8.942.735L10.1 20.9a14.55 14.55 0 00-4.089-.466.506.506 0 01-.363-.142.5.5 0 01-.152-.358v-.5a.5.5 0 01.5-.5h1.486a.622.622 0 00.439-1.062l-.881-1.124a.25.25 0 01.133-.395.623.623 0 00.3-1.051l-1.225-1.555a.249.249 0 01.129-.4.627.627 0 00.254-1.1l-1.763-1.898a.5.5 0 00-.736 0l-1.763 1.907a.627.627 0 00.254 1.1.249.249 0 01.129.4L1.523 15.3a.623.623 0 00.3 1.051.25.25 0 01.133.395l-.881 1.127a.622.622 0 00.439 1.062H3a.5.5 0 01.5.5v.89a.5.5 0 01-.385.486 14.69 14.69 0 00-2.1.663l-.7.281a.5.5 0 00-.315.467V23.5a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-1.778a.5.5 0 00-.228-.42.5.5 0 00-.472-.037z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});