define("ember-svg-jar/inlined/user-signal-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>user-signal-1</title><path d=\"M11.993 17.883h-.022A5.014 5.014 0 007 22.927a.5.5 0 00.5.5h9a.5.5 0 00.5-.5 5.054 5.054 0 00-1.48-3.558 4.943 4.943 0 00-3.527-1.486zM12.017 17.383a3.5 3.5 0 00-.017-7h-.016a3.5 3.5 0 00.016 7z\"/><path d=\"M17.631 6.728a8 8 0 00-12.555 9.691 1 1 0 001.73-1 6 6 0 1110.389 0 1 1 0 001.731 1 8 8 0 00-1.295-9.691z\"/><path d=\"M20.447 4.045A12 12 0 103.553 21.094a1 1 0 001.407-1.421 10 10 0 1114.14-.065 1 1 0 101.421 1.408 12.014 12.014 0 00-.074-16.971z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});