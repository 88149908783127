define("ember-svg-jar/inlined/death-grave", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>death-grave</title><path d=\"M22.762 16.75a.5.5 0 00.489-.4l.734-3.44a1 1 0 00-.2-.785 1.021 1.021 0 00-.749-.378h-.516a2.741 2.741 0 01-2.7-2.183 8 8 0 00-15.648 0 2.775 2.775 0 01-2.7 2.183H.93a1 1 0 00-.913 1.185l.729 3.418a.5.5 0 00.489.4zM8.969 8.78a.75.75 0 111.06-1.06l1.616 1.616a.5.5 0 00.707 0l1.617-1.616a.75.75 0 011.06 1.06l-1.616 1.62a.5.5 0 000 .708l1.616 1.616a.75.75 0 11-1.06 1.06l-1.617-1.616a.5.5 0 00-.707 0l-1.616 1.612a.75.75 0 11-1.06-1.06l1.616-1.62a.5.5 0 000-.708zM1.769 18.25a.5.5 0 00-.489.6l.226 1.06a1 1 0 00.986.836h19.014a.991.991 0 00.982-.813l.23-1.083a.5.5 0 00-.489-.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});