define("ember-svg-jar/inlined/cloud-wifi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-wifi</title><path d=\"M7.175 3.414a1 1 0 01-.557-1.831 9.643 9.643 0 0110.54 0 1 1 0 11-1.11 1.664 7.613 7.613 0 00-8.319 0 .984.984 0 01-.554.167zM14.384 6.741a1 1 0 01-.554-.168 3.549 3.549 0 00-3.882 0 1 1 0 01-1.11-1.664 5.585 5.585 0 016.1 0 1 1 0 01-.555 1.832zM4.807 23.985a5.472 5.472 0 01-3.571-1.377A4.324 4.324 0 010 19.4a4.588 4.588 0 014.266-4.577.25.25 0 00.227-.2 7.684 7.684 0 0114.1-2.295.249.249 0 00.191.12 5.717 5.717 0 013.447 1.585 5.8 5.8 0 01-3.4 9.941.932.932 0 01-.129.009H4.807zm.314-7.119A2.589 2.589 0 002 19.4a2.374 2.374 0 00.639 1.785 3.6 3.6 0 002.172.8h13.811A3.814 3.814 0 0022 18.2a3.753 3.753 0 00-1.16-2.726 3.665 3.665 0 00-2.757-1.049.984.984 0 01-.941-.57 5.686 5.686 0 00-10.82 2.089 1 1 0 01-.39.734 1.01 1.01 0 01-.811.188z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});