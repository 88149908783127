define("ember-svg-jar/inlined/app-window-error-404", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-error-404</title><path d=\"M12.05 16.751A2.253 2.253 0 0014.3 14.5v-2a2.25 2.25 0 00-4.5 0v2a2.253 2.253 0 002.25 2.251zM11.3 12.5a.75.75 0 011.5 0v2a.75.75 0 11-1.5 0zM8.8 11.011a.751.751 0 00-1.082-.673 6.272 6.272 0 00-3.395 4.535.75.75 0 00.739.878H7.05A.25.25 0 017.3 16a.75.75 0 101.5 0zm-1.941 1.863a.25.25 0 01.441.162V14a.25.25 0 01-.25.25h-.627a.251.251 0 01-.226-.359 4.848 4.848 0 01.662-1.017zM19.787 11a.75.75 0 00-.354-.637.742.742 0 00-.728-.035 6.272 6.272 0 00-3.395 4.534.751.751 0 00.74.878h1.987a.25.25 0 01.25.25.75.75 0 001.5 0zm-1.941 1.863a.25.25 0 01.441.162v.965a.25.25 0 01-.25.25h-.627a.249.249 0 01-.225-.358 4.786 4.786 0 01.661-1.018z\"/><path d=\"M24 4.751a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h18a3 3 0 003-3zm-14.346-1a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.562.091a1 1 0 01.908-.591.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.987.987 0 01.092-.409zM22 19.251a1 1 0 01-1 1H3a1 1 0 01-1-1V7a.25.25 0 01.25-.25h19.5A.25.25 0 0122 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});