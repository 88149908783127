define("ember-svg-jar/inlined/direction-button-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>direction-button-square</title><path d=\"M22 0H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zm-.641 12a.744.744 0 01-.27.576l-2.359 1.965a.75.75 0 01-1.23-.577v-3.932a.75.75 0 011.229-.577l2.36 1.967a.747.747 0 01.27.578zm-6.713-5.934a.756.756 0 01-.68.432h-3.932a.749.749 0 01-.576-1.23l1.966-2.36a.775.775 0 011.153 0l1.965 2.359a.753.753 0 01.104.799zm-.1 12.663l-1.965 2.358a.75.75 0 01-1.154 0l-1.969-2.358a.75.75 0 01.576-1.231h3.932a.75.75 0 01.576 1.231zM6.5 10.032v3.932a.749.749 0 01-1.229.577L2.91 12.574a.75.75 0 010-1.152l2.36-1.967a.751.751 0 011.23.577z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});