define("ember-svg-jar/inlined/single-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man</title><path d=\"M12 12.5a6 6 0 10-6-6 6.006 6.006 0 006 6zm0-2a4 4 0 01-4-4 3.886 3.886 0 01.294-1.47.255.255 0 01.152-.143.249.249 0 01.208.023 9.572 9.572 0 004.925 1.362 9.517 9.517 0 002.1-.236.249.249 0 01.3.215A2.28 2.28 0 0116 6.5a4 4 0 01-4 4zM12 13.5A9.511 9.511 0 002.5 23a.5.5 0 00.5.5h18a.5.5 0 00.5-.5 9.511 9.511 0 00-9.5-9.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});