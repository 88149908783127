define("ember-svg-jar/inlined/modern-music-electric-guitar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-music-electric-guitar</title><path d=\"M23.582 1.169a1.415 1.415 0 00-1.461-.344l-4.535 1.511A1.5 1.5 0 0017 2.7l-.187.186a1.5 1.5 0 00-.141 1.959.25.25 0 01-.023.327L11.533 10.3a.25.25 0 01-.374-.023 2.386 2.386 0 01-.472-1.883 5.832 5.832 0 01.681-1.83A1.65 1.65 0 008.746 4.6a7.046 7.046 0 00-2.041 3.65c-.184.866-.85 2.074-1.609 2.089A5.122 5.122 0 000 15.578a6.444 6.444 0 001.958 4.611l1.1 1.1a6.462 6.462 0 004.509 1.96 5.131 5.131 0 005.34-5.1.993.993 0 01.335-.728 2.069 2.069 0 011.438-.521 4.61 4.61 0 003.7-1.417 1.549 1.549 0 00.346-1.649 1.59 1.59 0 00-1.381-1.047 129.72 129.72 0 00-1.42-.1c-2.142-.145-2.564-.19-3.157-.783V11.9l5.3-5.309a.25.25 0 01.327-.024 1.5 1.5 0 001.958-.141l3.234-3.234a1.426 1.426 0 00-.005-2.023zM7.34 18.032a1 1 0 010 1.414l-.353.354a1 1 0 01-1.415 0l-2.121-2.121a1 1 0 010-1.415l.353-.353a1 1 0 011.415 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});