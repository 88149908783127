define("ember-svg-jar/inlined/audio-file-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file-sync</title><path d=\"M23.414 3L21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.277a.247.247 0 00.241.249c.364.009 1.037.041 1.485.093A.246.246 0 008 9.371V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.244.244 0 00-.241.21 7.935 7.935 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M14.5 13.325c0 .181.153.161.227.135a2.247 2.247 0 001.506-1.96.734.734 0 00.017-.084v-5.1c0-.2.164-.264.281-.19l1.07.673a.75.75 0 10.8-1.269l-1.818-1.135A1.25 1.25 0 0014.75 5.5v3.625c0 .118-.074.075-.112.063a2.167 2.167 0 00-.638-.1 2.232 2.232 0 00-1.83.959c-.072.114.034.2.226.2a1.938 1.938 0 01.871.141 1.994 1.994 0 011.233 1.846zM11.77 18.6a1 1 0 00-1.255.58 4.067 4.067 0 01-1.279 1.931 4.162 4.162 0 01-5.656-.47.256.256 0 01.014-.349l1.717-1.717a.5.5 0 00-.354-.853H.5a.5.5 0 00-.5.5v4.458a.5.5 0 00.854.353l.974-.974a.244.244 0 01.353.009 6.086 6.086 0 0010.211-2.2 1 1 0 00-.622-1.268zM12.691 11.772a.5.5 0 00-.545.109l-1.03 1.03a.244.244 0 01-.352-.008 6.1 6.1 0 00-10.2 2.226 1 1 0 001.879.686A4.054 4.054 0 013.719 13.9a4.153 4.153 0 015.637.435.255.255 0 01-.012.35l-1.655 1.653a.5.5 0 00.354.853H12.5a.5.5 0 00.5-.5v-4.457a.5.5 0 00-.309-.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});