define("ember-svg-jar/inlined/vintage-tv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-tv</title><path d=\"M8.468 4.642a.25.25 0 010 .352 5.085 5.085 0 00-.917 1.293A.5.5 0 008 7.01h8a.5.5 0 00.447-.723 5.05 5.05 0 00-.917-1.293.25.25 0 010-.352l2.925-2.925A1 1 0 0017.043.3l-3.357 3.36a.249.249 0 01-.249.062 4.976 4.976 0 00-2.874 0 .251.251 0 01-.25-.062L6.957.3a1 1 0 00-1.414 1.417zM20.5 7.99h-17a3.5 3.5 0 00-3.5 3.5v9a3.5 3.5 0 003.5 3.5h17a3.5 3.5 0 003.5-3.5v-9a3.5 3.5 0 00-3.5-3.5zm-.5 11.5a1.25 1.25 0 111.25-1.25A1.249 1.249 0 0120 19.49zm0-5a1.25 1.25 0 111.25-1.25A1.249 1.249 0 0120 14.49zm-17-1.5c0-2.5 4.516-2.5 6-2.5h1c1.484 0 6 0 6 2.5v6c0 2.5-4.516 2.5-6 2.5H9c-1.484 0-6 0-6-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});