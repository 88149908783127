define("ember-svg-jar/inlined/light-mode-hdr-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-mode-hdr-alternate</title><path d=\"M24 7.75A3.755 3.755 0 0020.25 4H3.75A3.755 3.755 0 000 7.75v8.5A3.755 3.755 0 003.75 20h16.5A3.755 3.755 0 0024 16.25zm-1.5 8.5a2.253 2.253 0 01-2.25 2.25H3.75a2.253 2.253 0 01-2.25-2.25v-8.5A2.253 2.253 0 013.75 5.5h16.5a2.253 2.253 0 012.25 2.25z\"/><path d=\"M21 10a2.752 2.752 0 00-2.75-2.75h-2a.75.75 0 00-.75.75v8a.75.75 0 001.5 0v-1.586a.25.25 0 01.427-.177l2.293 2.293a.75.75 0 001.06-1.06l-2.318-2.318a.253.253 0 01-.06-.258.25.25 0 01.2-.167A2.754 2.754 0 0021 10zm-4-1a.25.25 0 01.25-.25h1a1.25 1.25 0 010 2.5h-1A.25.25 0 0117 11zM7.75 7.25A.75.75 0 007 8v3a.25.25 0 01-.25.25h-2A.25.25 0 014.5 11V8A.75.75 0 003 8v8a.75.75 0 001.5 0v-3a.25.25 0 01.25-.25h2A.25.25 0 017 13v3a.75.75 0 001.5 0V8a.75.75 0 00-.75-.75zM10.25 7.25A.75.75 0 009.5 8v8a.75.75 0 00.75.75 4.75 4.75 0 000-9.5zm1.084 7.814a.25.25 0 01-.334-.235V9.171a.25.25 0 01.334-.235 3.25 3.25 0 010 6.128z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});