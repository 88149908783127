define("ember-svg-jar/inlined/ee-icon-question-conversation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>picture-landscape</title><g fill=\"none\" fill-rule=\"evenodd\"><rect fill=\"#5E7AF9\" width=\"54\" height=\"54\" rx=\"5\"/><path d=\"M13.174 9.25a7.674 7.674 0 000 15.348H14v3.652a.5.5 0 00.863.344l3.785-4h2.178a7.674 7.674 0 100-15.348l-7.652.004z\" fill=\"#273C9B\"/><path d=\"M40.826 26.25a7.674 7.674 0 110 15.348H40v3.652a.5.5 0 01-.863.344l-3.785-4h-2.178a7.674 7.674 0 110-15.348l7.652.004z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "54",
      "height": "54",
      "viewBox": "0 0 54 54",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});