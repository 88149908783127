define("ember-svg-jar/inlined/crime-man-thug.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-man-thug</title><path d=\"M8.489 9.1a.75.75 0 001.022 1.1.562.562 0 01.557 0 .75.75 0 001.022-1.1 2.02 2.02 0 00-2.601 0zM12.91 9.1a.75.75 0 001.022 1.1.562.562 0 01.557 0 .75.75 0 001.022-1.1 2.02 2.02 0 00-2.601 0z\"/><path d=\"M6.149 12.447a.25.25 0 01.144.187c.511 3.048 4.021 4.991 5.686 4.991s5.176-1.943 5.687-4.989a.252.252 0 01.145-.189 2.732 2.732 0 001.439-2.434 2.116 2.116 0 00-.465-1.443c.388-2.759-.041-4.9-1.275-6.354C16.334.829 14.474.125 11.979.125S7.626.828 6.45 2.216C5.216 3.673 4.787 5.81 5.174 8.57a2.113 2.113 0 00-.464 1.444 2.731 2.731 0 001.439 2.433zm4.082 1.063a.75.75 0 011.021-.29 1.161 1.161 0 00.224.094 1.32 1.32 0 00.551.08 1.82 1.82 0 00.544-.079 1.194 1.194 0 00.221-.093.75.75 0 01.734 1.308 2.72 2.72 0 01-.5.215 3.323 3.323 0 01-.995.149h-.006a3.35 3.35 0 01-1-.15 2.7 2.7 0 01-.5-.214.751.751 0 01-.294-1.02zM6.315 9.546a.754.754 0 00.4-.794C6.3 6.238 6.6 4.365 7.6 3.186c.878-1.036 2.352-1.561 4.384-1.561s3.508.525 4.386 1.561c1 1.18 1.294 3.053.874 5.566a.753.753 0 00.406.794.615.615 0 01.105.438 1.281 1.281 0 01-.559 1.1 1.75 1.75 0 00-1 1.3 3.133 3.133 0 01-.165.541 4.994 4.994 0 00-8.071.044 3.26 3.26 0 01-.179-.584 1.747 1.747 0 00-1-1.306 1.275 1.275 0 01-.559-1.1.618.618 0 01.093-.433z\"/><path d=\"M21.959 18.4a7.12 7.12 0 00-3.029-1.123 12.867 12.867 0 01-1.3-.307.664.664 0 00-.139-.015l-.2-.069a.5.5 0 00-.624.294 4.994 4.994 0 01-9.316 0 .5.5 0 00-.625-.294l-.205.069a.648.648 0 00-.138.015 12.867 12.867 0 01-1.3.307A7.12 7.12 0 002.041 18.4 4.46 4.46 0 00.25 21.946v.929a.75.75 0 001.5 0v-.929A2.95 2.95 0 012.939 19.6 5.1 5.1 0 015 18.826v4.549a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-4.549a5.1 5.1 0 012.061.776 2.95 2.95 0 011.189 2.344v.929a.75.75 0 001.5 0v-.929a4.46 4.46 0 00-1.791-3.546z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});