define("ember-svg-jar/inlined/yoga-shoulder-stretch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>yoga-shoulder-stretch</title><circle cx=\"12\" cy=\"4\" r=\"2.5\"/><path d=\"M13.5 11.5a.5.5 0 01.5-.5h7.5a1.5 1.5 0 000-3h-19a1.5 1.5 0 000 3H10a.5.5 0 01.5.5v1a.5.5 0 00.5.5h2a.5.5 0 00.5-.5zM21.5 19.5h-4.267l-3.522-4.243a1 1 0 01-.211-.614V14.5a.5.5 0 00-.5-.5H8.5A3.5 3.5 0 005 17.5V21a1.5 1.5 0 003 0v-3.5a.5.5 0 01.5-.5h2.531a.5.5 0 01.386.182l3.9 4.739a1.5 1.5 0 001.183.579h5a1.5 1.5 0 000-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});