define("ember-svg-jar/inlined/cloud-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-lock</title><path d=\"M5.5 16.208v-.035a1 1 0 00-.883-1 2.877 2.877 0 01-1.978-.763A2.371 2.371 0 012 12.624a2.588 2.588 0 012.586-2.587 2.635 2.635 0 01.535.056 1.008 1.008 0 00.811-.186 1 1 0 00.392-.779c.081-5.754 8.3-7.363 10.818-2.045a.986.986 0 00.941.571 3.684 3.684 0 012.973 1.272A3.828 3.828 0 0122 11.6a3.689 3.689 0 01-2.788 3.483.982.982 0 00-.709.941.978.978 0 001.248.946A5.56 5.56 0 0024 11.641a5.766 5.766 0 00-5.216-5.964.252.252 0 01-.191-.12 7.685 7.685 0 00-14.1 2.3.251.251 0 01-.227.2A4.642 4.642 0 00.62 10.3 4.479 4.479 0 000 12.744a4.287 4.287 0 001.235 3.09 5.14 5.14 0 003.179 1.351.986.986 0 001.086-.977z\"/><path d=\"M17 15.179a1.52 1.52 0 00-1.286-1.485.25.25 0 01-.214-.247v-1.018a3.5 3.5 0 00-7 0v1.018a.25.25 0 01-.213.247A1.519 1.519 0 007 15.179v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5zm-5 4.25a1 1 0 111-1 1 1 0 01-1 1zm-1.5-7a1.5 1.5 0 013 0v1a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});