define("ember-svg-jar/inlined/protection-shield-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>protection-shield-4</title><path d=\"M22.038.006H1.959A1.958 1.958 0 00.013 1.965L0 9.305a15.147 15.147 0 0011.862 14.67.974.974 0 00.194.019 1 1 0 00.2-.021A15.145 15.145 0 0023.988 9.2L24 1.953A1.962 1.962 0 0022.038.006zM20.011 4.2L20 9.475a10.226 10.226 0 01-.9 4.235.246.246 0 01-.183.143.25.25 0 01-.222-.069L8.841 3.933a.25.25 0 01.177-.427h10.294a.7.7 0 01.699.694zm-7.866 15.3a.508.508 0 01-.209 0A10.239 10.239 0 013.99 9.547L4 6.518a.249.249 0 01.426-.176l11.228 11.232a.251.251 0 01.072.2.248.248 0 01-.105.183 9.628 9.628 0 01-3.476 1.543z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});