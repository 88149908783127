define("ember-svg-jar/inlined/single-man-actions-location.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-location</title><path d=\"M7.544 10a5 5 0 10-5-5 5.006 5.006 0 005 5zM4.888 3.635A7.085 7.085 0 009 5a6.585 6.585 0 001.522-.185c0 .062.019.122.019.185a3 3 0 01-6 0 2.954 2.954 0 01.347-1.365z\"/><path d=\"M12.923 12.607a7.464 7.464 0 00-12.358 3.25.5.5 0 00.479.643h10.947a4.982 4.982 0 01-.035-.5 6.448 6.448 0 01.967-3.393zM18.456 11a5.006 5.006 0 00-5 5c0 3.171 4.457 7.664 4.647 7.854a.5.5 0 00.707 0c.19-.19 4.646-4.683 4.646-7.854a5.006 5.006 0 00-5-5zm0 3.25a1.75 1.75 0 11-1.75 1.75 1.752 1.752 0 011.75-1.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});