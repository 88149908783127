define("ember-svg-jar/inlined/information-desk-customer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>information-desk-customer</title><circle cx=\"16.125\" cy=\"6.25\" r=\"2.25\"/><path d=\"M11.738 12.662a.25.25 0 00.235.338h8.392a.189.189 0 00.154-.08.185.185 0 00.024-.172 4.629 4.629 0 00-8.805-.086z\"/><path d=\"M22.375 16.5a1 1 0 000-2h-12.25a.5.5 0 01-.5-.5v-1.5a4.5 4.5 0 00-9 0V16a.5.5 0 00.5.5h1.037a.5.5 0 01.5.465l.428 6.07a.5.5 0 00.5.465h3.072a.5.5 0 00.5-.465l.428-6.07a.5.5 0 01.5-.465z\"/><circle cx=\"5.125\" cy=\"3.5\" r=\"3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});