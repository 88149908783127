define("ember-svg-jar/inlined/appliances-hand-mixer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>appliances-hand-mixer</title><path d=\"M14.272 6.193a.5.5 0 00-.707 0L11.62 8.137a1 1 0 000 1.414l2.829 2.829a1 1 0 001.414 0l1.944-1.945a.5.5 0 000-.707zm.943 3.653a.75.75 0 110-1.061.75.75 0 010 1.061zM23.121.879a3 3 0 00-4.242 0l-3.547 3.546a.5.5 0 000 .707l3.536 3.536a.5.5 0 00.707 0l3.546-3.547a3 3 0 000-4.242zM3.829 24a1 1 0 01-.707-.293L.293 20.878a1 1 0 111.414-1.414l2.829 2.829A1 1 0 013.829 24zM12.608 11.746l-.354-.354a1 1 0 00-1.414 0l-4.388 4.389a.5.5 0 01-.541.11 4.006 4.006 0 00-4.335.876.5.5 0 000 .707l4.95 4.95a.5.5 0 00.707 0 4.005 4.005 0 00.877-4.334.5.5 0 01.11-.542l4.388-4.388a1 1 0 000-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});