define("ember-svg-jar/inlined/professions-man-office-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-office-2</title><path d=\"M24 22.379a4.53 4.53 0 00-1.753-3.565 7.1 7.1 0 00-3.056-1.156c-.245-.051-1.906-.46-2.538-.616a.5.5 0 00-.433.1A7.38 7.38 0 0112 18.879a7.385 7.385 0 01-4.221-1.741.5.5 0 00-.432-.1c-.632.156-2.293.565-2.538.616a7.106 7.106 0 00-3.057 1.156A4.532 4.532 0 000 22.379v1a.5.5 0 00.5.5h9.131a.5.5 0 00.475-.346l.782-2.338a1.193 1.193 0 012.264 0c.47 1.409.684 2.038.781 2.336a.5.5 0 00.475.346H23.5a.5.5 0 00.5-.5zm-4.229-1a.75.75 0 01-.75.75h-2a.75.75 0 010-1.5h2a.75.75 0 01.75.75zM10.542 14.034a3.072 3.072 0 001.5.364h.006a3.094 3.094 0 001.5-.365.75.75 0 00-.734-1.308 1.62 1.62 0 01-.764.173 1.594 1.594 0 01-.772-.174.75.75 0 00-.73 1.31z\"/><path d=\"M12 17.629c1.665 0 5.176-1.944 5.688-4.992a.245.245 0 01.142-.186 2.729 2.729 0 001.44-2.433 2.117 2.117 0 00-.464-1.443C20.076-.454 12.161.129 12 .129S3.924-.452 5.194 8.575a2.117 2.117 0 00-.464 1.443 2.729 2.729 0 001.44 2.433.249.249 0 01.143.187c.511 3.047 4.022 4.991 5.687 4.991zm-3.729-7.75a1 1 0 111 1 1 1 0 01-1-1zm1-2.5a2.454 2.454 0 00-.775.135l.08-.4A4.983 4.983 0 0012 5.9a4.983 4.983 0 003.424 1.217l.076.383a2.443 2.443 0 00-3.1 1.629h-.755a2.5 2.5 0 00-2.374-1.75zm5.5 1.5a1 1 0 11-1 1 1 1 0 011-1zm2.44 2.205a1.73 1.73 0 00-.541.4 2.479 2.479 0 00.6-1.607 2.414 2.414 0 00-.028-.271c.041 0 .077.021.118.021h.347a.77.77 0 01.062.36 1.272 1.272 0 01-.558 1.097zm-9.418 1.307a1.752 1.752 0 00-1-1.307 1.272 1.272 0 01-.563-1.095.77.77 0 01.062-.36h.347A1.494 1.494 0 006.8 9.6a2.354 2.354 0 00-.028.279 2.524 2.524 0 002.5 2.5 2.5 2.5 0 002.372-1.75h.756a2.5 2.5 0 002.373 1.75 2.6 2.6 0 001.744-.714 1.724 1.724 0 00-.307.725c-.378 2.249-3.176 3.739-4.208 3.739s-3.832-1.49-4.209-3.738z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});