define("ember-svg-jar/inlined/real-estate-market-house-increase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-market-house-increase</title><path d=\"M2 19.75a1 1 0 001 1h6a1 1 0 001-1v-2.676a.5.5 0 01.5-.5h1a.5.5 0 00.337-.87l-5.165-4.695a1 1 0 00-1.345 0L.164 15.7a.5.5 0 00.336.87h1a.5.5 0 01.5.5zM14 20.25a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V6.75a1 1 0 00-1-1h-2a1 1 0 00-1 1zM24 1.75a1 1 0 00-1-1h-2a1 1 0 00-1 1v18.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5zM23 22H2.5a1 1 0 000 2H23a1 1 0 000-2zM7.259 8.509l4.491-4.491a.5.5 0 01.707 0l.263.262A.75.75 0 0014 3.75v-3a.75.75 0 00-.75-.75h-3a.75.75 0 00-.53 1.28l.263.263a.5.5 0 010 .707L5.491 6.741a1.25 1.25 0 001.768 1.768z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});