define("ember-svg-jar/inlined/discount-parking-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>discount-parking-2</title><path d=\"M13 7.5h-1.75a.25.25 0 00-.25.25v3.5a.25.25 0 00.25.25H13a2 2 0 000-4z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm1 13.5h-1.75a.25.25 0 00-.25.25v4.75a1 1 0 01-2 0v-12a1 1 0 011-1h3a4 4 0 010 8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});