define("ember-svg-jar/inlined/add-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>add-circle</title><path d=\"M12 23.5A11.5 11.5 0 10.5 12 11.513 11.513 0 0012 23.5zM6 11h4.75a.25.25 0 00.25-.25V6a1 1 0 012 0v4.75a.25.25 0 00.25.25H18a1 1 0 010 2h-4.75a.25.25 0 00-.25.25V18a1 1 0 01-2 0v-4.75a.25.25 0 00-.25-.25H6a1 1 0 010-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});