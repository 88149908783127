define("ember-svg-jar/inlined/table-lamp-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>table-lamp-square</title><path d=\"M3.5 9.25a1 1 0 000-2H1a1 1 0 000 2zM2.793 4.957a1 1 0 001.414-1.414L2.586 1.922a1 1 0 00-1.414 1.414zM4.207 11.543a1 1 0 00-1.414 0l-1.621 1.621a1 1 0 101.414 1.414l1.621-1.621a1 1 0 000-1.414zM23 7.25h-2.5a1 1 0 000 2H23a1 1 0 000-2zM20.5 5.25a1 1 0 00.707-.293l1.621-1.621a1 1 0 10-1.414-1.414l-1.621 1.621A1 1 0 0020.5 5.25zM21.207 11.543a1 1 0 00-1.414 1.414l1.621 1.621a1 1 0 101.414-1.414zM13.484 19.805a.25.25 0 01-.234-.246V17.5a.25.25 0 01.25-.25H17a1 1 0 001-1v-15a1 1 0 00-1-1H7a1 1 0 00-1 1v15a1 1 0 001 1h3.5a.25.25 0 01.25.25v2.069a.246.246 0 01-.228.245C7.115 20.1 4.006 21.332 4 23.244a.5.5 0 00.5.506h15a.5.5 0 00.5-.5c0-1.835-2.615-3.174-6.516-3.445zM10 14.25a1 1 0 01-2 0v-11a1 1 0 012 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});