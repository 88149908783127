define("ember-svg-jar/inlined/medical-personnel-hat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-personnel-hat</title><path d=\"M6.418 7.483a70.676 70.676 0 0111.157 0 .5.5 0 00.487-.722L17.5 5.632a2.485 2.485 0 00-2.24-1.382H8.732A2.485 2.485 0 006.5 5.632l-.569 1.129a.5.5 0 00.487.722zM23.825 10.1a1 1 0 00-.664-.427 68.728 68.728 0 00-22.323 0 1 1 0 00-.8 1.228c.185.744.6 2.542 1.517 6.88A2.512 2.512 0 004 19.75h16a2.485 2.485 0 002.44-1.958l1.539-6.921a1 1 0 00-.154-.771zM15.5 15.125a.292.292 0 01-.291.292h-1.546a.5.5 0 00-.5.5v1.541a.292.292 0 01-.292.292h-1.75a.291.291 0 01-.291-.292v-1.541a.5.5 0 00-.5-.5H8.788a.292.292 0 01-.292-.292v-1.75a.292.292 0 01.292-.292h1.542a.5.5 0 00.5-.5v-1.541a.291.291 0 01.291-.292h1.75a.292.292 0 01.292.292v1.541a.5.5 0 00.5.5h1.542a.292.292 0 01.291.292z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});