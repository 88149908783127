define("ember-svg-jar/inlined/server-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-upload</title><path d=\"M2.75 6h17a2.5 2.5 0 002.5-2.5v-1a2.5 2.5 0 00-2.5-2.5h-17a2.5 2.5 0 00-2.5 2.5v1A2.5 2.5 0 002.75 6zm1.135-3A1.115 1.115 0 115 4.115 1.115 1.115 0 013.885 3zM7.75 3a1.115 1.115 0 111.115 1.115A1.114 1.114 0 017.75 3zM17.25 9.5a7.919 7.919 0 014.25 1.231.493.493 0 00.756-.416v-.81A2.509 2.509 0 0019.75 7h-17a2.5 2.5 0 00-2.5 2.5v1a2.5 2.5 0 002.5 2.5h7.639a.49.49 0 00.4-.206A7.992 7.992 0 0117.25 9.5zm-7.271.5a1.115 1.115 0 11-1.114-1.115A1.114 1.114 0 019.979 10zm-3.864 0A1.115 1.115 0 115 8.885 1.115 1.115 0 016.115 10zM2.75 20h6.242a.505.505 0 00.481-.638 7.868 7.868 0 01.3-4.687A.5.5 0 009.31 14H2.75a2.5 2.5 0 00-2.5 2.5v1a2.5 2.5 0 002.5 2.5zm1.135-3A1.115 1.115 0 115 18.114 1.115 1.115 0 013.885 17z\"/><path d=\"M10.75 17.5a6.5 6.5 0 106.5-6.5 6.508 6.508 0 00-6.5 6.5zm6.5 4a1 1 0 01-1-1v-2.75a.25.25 0 00-.25-.25h-.75a.5.5 0 01-.391-.813l2-2.5a.52.52 0 01.782 0l2 2.5a.5.5 0 01-.391.813h-.75a.25.25 0 00-.25.25v2.75a1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});