define("ember-svg-jar/inlined/parrot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>parrot</title><path d=\"M18.593 16.4a.25.25 0 01-.226-.289l.084-.515a.249.249 0 01.271-.209 3.832 3.832 0 012.864 1.655.177.177 0 00.138.065.268.268 0 00.186-.069 2.922 2.922 0 00.794-2.328 3.638 3.638 0 00-1.471-2.319 4.175 4.175 0 00-2.124-.859l.114-.706a2.127 2.127 0 00.192-.26A2 2 0 0017.723 7.5a7 7 0 01-7-7 2.5 2.5 0 00-2.5 2.5 2.419 2.419 0 00.056.453l-.012.015A2.5 2.5 0 007.223 5.5a11.223 11.223 0 00.9 3.437 20.95 20.95 0 00-6.845 14.026.5.5 0 00.5.537h14.8a.5.5 0 00.492-.6 8.2 8.2 0 00-1.223-2.969 3 3 0 002.019-1.526 4.621 4.621 0 002.32-1 .251.251 0 000-.394 2.965 2.965 0 00-1.593-.611zm-2.87-3.647a.75.75 0 11.75-.75.75.75 0 01-.75.747z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});