define("ember-svg-jar/inlined/style-three-pin-diamond", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-diamond</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm3.354 5.646l2 2A.5.5 0 0117 8.5h-1.562a.253.253 0 01-.218-.127l-1.406-2.5a.25.25 0 01.218-.373H15a.5.5 0 01.354.146zM12.312 5.5a.253.253 0 01.218.127l1.406 2.5a.25.25 0 01-.218.373h-3.436a.25.25 0 01-.218-.373l1.406-2.5a.253.253 0 01.218-.127zM6.646 7.646l2-2A.5.5 0 019 5.5h.968a.25.25 0 01.218.373l-1.406 2.5a.253.253 0 01-.218.127H7a.5.5 0 01-.354-.854zm-.272 2.683A.5.5 0 016.75 9.5h1.673a.249.249 0 01.242.186l.975 3.656a.25.25 0 01-.43.229zM12 16.5a.5.5 0 01-.376-.171l-.18-.206a.236.236 0 01-.053-.1L9.734 9.814a.251.251 0 01.242-.314h4.048a.251.251 0 01.242.314l-1.657 6.209a.236.236 0 01-.053.1l-.18.206A.5.5 0 0112 16.5zm5.626-6.171l-2.836 3.242a.25.25 0 01-.43-.229l.975-3.656a.249.249 0 01.242-.186h1.673a.5.5 0 01.376.829z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});