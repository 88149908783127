define("ember-svg-jar/inlined/landmark-japan-shrine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-japan-shrine</title><path d=\"M23.393 1.718A1 1 0 0022 .462a21.94 21.94 0 01-10 2.19A21.931 21.931 0 012 .461 1 1 0 00.6 1.675l1.832 5.9a1 1 0 00.906.578h2.527a.5.5 0 01.5.564l-.259 2a.5.5 0 01-.5.436H4a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1.218a.5.5 0 01.5.564l-1.145 8.814v.01a1 1 0 00.994 1.111H7.5a1 1 0 001-1v-9a.5.5 0 01.5-.5h6a.5.5 0 01.5.5v9a1 1 0 001 1h1.939a1 1 0 00.993-1.117v-.01l-1.14-8.809a.5.5 0 01.5-.564H20a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1.6a.5.5 0 01-.5-.436l-.259-2a.5.5 0 01.5-.564h2.529a1.006 1.006 0 00.926-.629zM15.5 10.652a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5H15a.5.5 0 01.5.5zm-7-2a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5v2a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});