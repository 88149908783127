define("ember-svg-jar/inlined/single-neutral-actions-cart.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-cart</title><path d=\"M15.769 21.143a1.423 1.423 0 101.423 1.423 1.425 1.425 0 00-1.423-1.423z\"/><circle cx=\"19.471\" cy=\"22.577\" r=\"1.423\"/><path d=\"M11.1 15.126A2 2 0 0113 12.49h2.228a.25.25 0 00.142-.456 19.017 19.017 0 00-3.87-1.7l-.63-.234a.589.589 0 01-.138-.31 2.619 2.619 0 01.058-1.3 5.618 5.618 0 001.465-4.314C12.255 1.756 10.676 0 8.5 0S4.744 1.756 4.744 4.174A5.605 5.605 0 006.2 8.471a2.508 2.508 0 01.072 1.317.609.609 0 01-.134.308l-.632.233c-2.446.9-4.214 1.549-4.753 2.626A9.164 9.164 0 000 16.5a.5.5 0 00.5.5h10.885a.25.25 0 00.2-.1.248.248 0 00.034-.225z\"/><path d=\"M23.487 10.789a.746.746 0 00-.949.474l-.852 2.555a.25.25 0 01-.237.171H13a.5.5 0 00-.473.659l1.845 5.5a.5.5 0 00.474.341h6.231a.5.5 0 00.482-.365l2.4-8.387a.75.75 0 00-.472-.948z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});