define("ember-svg-jar/inlined/real-estate-app-house-smartphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-app-house-smartphone</title><path d=\"M6.5 11.25h1a.5.5 0 01.5.5v2.5a1 1 0 001 1h1a1 1 0 001-1v-2a1 1 0 012 0v2a1 1 0 001 1h1a1 1 0 001-1v-2.5a.5.5 0 01.5-.5h1a.5.5 0 00.336-.87l-5.163-4.694a1 1 0 00-1.345 0L6.164 10.38a.5.5 0 00.336.87z\"/><path d=\"M20.75 3a3 3 0 00-3-3H6.25a3 3 0 00-3 3v18.25A2.752 2.752 0 006 24h12a2.752 2.752 0 002.75-2.75zM6.25 2h11.5a1 1 0 011 1v15a.5.5 0 01-.5.5H5.75a.5.5 0 01-.5-.5V3a1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});