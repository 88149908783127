define("ember-svg-jar/inlined/light-mode-bright-dark-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-mode-bright-dark-2-alternate</title><path d=\"M17.073 10.005A.251.251 0 0117 9.828V8a1 1 0 00-1-1h-1.812a.249.249 0 01-.177-.073l-1.3-1.3a1 1 0 00-1.414 0l-1.3 1.3A.249.249 0 019.812 7H8a1 1 0 00-1 1v1.828a.251.251 0 01-.073.177l-1.3 1.3a1 1 0 000 1.414l1.3 1.3A.251.251 0 017 14.2V16a1 1 0 001 1h1.812a.249.249 0 01.177.073l1.3 1.3a1 1 0 001.414 0l1.3-1.3a.249.249 0 01.184-.073H16a1 1 0 001-1v-1.8a.251.251 0 01.073-.177l1.3-1.3a1 1 0 000-1.414zM15 12a2.781 2.781 0 01-2.734 2.992.251.251 0 01-.266-.25V9.258a.25.25 0 01.266-.25A2.782 2.782 0 0115 12z\"/><path d=\"M24 5a3 3 0 00-3-3H3a3 3 0 00-3 3v14a3 3 0 003 3h18a3 3 0 003-3zm-2 14a1 1 0 01-1 1H3a1 1 0 01-1-1V5a1 1 0 011-1h18a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});