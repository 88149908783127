define("ember-svg-jar/inlined/gender-transgender", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gender-transgender</title><path d=\"M22 .75a.5.5 0 00-.5-.5h-3.029a.5.5 0 00-.365.842l.739.79a.25.25 0 010 .346l-2.7 2.744a.25.25 0 01-.329.028 6.455 6.455 0 00-7.633 0 .247.247 0 01-.325-.027L7.6 4.714a.25.25 0 010-.352l.671-.673a1 1 0 00-1.409-1.414l-.656.657a.247.247 0 01-.178.073.25.25 0 01-.177-.074l-.692-.7a.25.25 0 010-.346l.74-.79a.5.5 0 00-.37-.845H2.5a.5.5 0 00-.5.5v3.235a.5.5 0 00.865.342l.58-.619a.248.248 0 01.18-.079.251.251 0 01.181.074l.634.645a.25.25 0 010 .352L4 5.143a1 1 0 001.41 1.414l.427-.427a.249.249 0 01.355 0l.3.308a.251.251 0 01.033.311A6.486 6.486 0 0010.8 16.63a.25.25 0 01.2.245V19a.25.25 0 01-.25.25H9.411a1 1 0 000 2h1.339a.25.25 0 01.25.25v1.25a1 1 0 002 0V21.5a.25.25 0 01.25-.25h1.338a1 1 0 100-2H13.25A.25.25 0 0113 19v-2.125a.25.25 0 01.2-.245 6.487 6.487 0 004.268-9.881.249.249 0 01.032-.31L20.194 3.7a.251.251 0 01.181-.074.248.248 0 01.18.079l.58.619A.5.5 0 0022 3.985zm-10 14a4.5 4.5 0 114.5-4.5 4.505 4.505 0 01-4.5 4.5z\"/><path d=\"M22 .75a.5.5 0 00-.5-.5h-3.029a.5.5 0 00-.365.842l.739.79a.25.25 0 010 .346l-2.7 2.744a.25.25 0 01-.329.028 6.455 6.455 0 00-7.633 0 .247.247 0 01-.325-.027L7.6 4.714a.25.25 0 010-.352l.671-.673a1 1 0 00-1.409-1.414l-.656.657a.247.247 0 01-.178.073.25.25 0 01-.177-.074l-.692-.7a.25.25 0 010-.346l.74-.79a.5.5 0 00-.37-.845H2.5a.5.5 0 00-.5.5v3.235a.5.5 0 00.865.342l.58-.619a.248.248 0 01.18-.079.251.251 0 01.181.074l.634.645a.25.25 0 010 .352L4 5.143a1 1 0 001.41 1.414l.427-.427a.249.249 0 01.355 0l.3.308a.251.251 0 01.033.311A6.486 6.486 0 0010.8 16.63a.25.25 0 01.2.245V19a.25.25 0 01-.25.25H9.411a1 1 0 000 2h1.339a.25.25 0 01.25.25v1.25a1 1 0 002 0V21.5a.25.25 0 01.25-.25h1.338a1 1 0 100-2H13.25A.25.25 0 0113 19v-2.125a.25.25 0 01.2-.245 6.487 6.487 0 004.268-9.881.249.249 0 01.032-.31L20.194 3.7a.251.251 0 01.181-.074.248.248 0 01.18.079l.58.619A.5.5 0 0022 3.985zm-10 14a4.5 4.5 0 114.5-4.5 4.505 4.505 0 01-4.5 4.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});