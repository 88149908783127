define("ember-svg-jar/inlined/basketball-hoop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>basketball-hoop</title><path d=\"M15.383 11.5H8.617a2 2 0 00-1.989 2.21l.779 8.511A2 2 0 009.4 24h5.21a2 2 0 001.989-1.8l.776-8.48a2 2 0 00-1.992-2.22zM11 21.515a.5.5 0 01-.5.5h-.647a.5.5 0 01-.5-.454l-.047-.517a.5.5 0 01.5-.546h.694a.5.5 0 01.5.5zM11 18a.5.5 0 01-.5.5h-.969a.5.5 0 01-.5-.454l-.045-.5a.5.5 0 01.5-.546H10.5a.5.5 0 01.5.5zm0-3.5a.5.5 0 01-.5.5H9.211a.5.5 0 01-.5-.454l-.046-.5a.5.5 0 01.5-.545h1.334a.5.5 0 01.5.5zm3.692 6.546l-.046.5a.5.5 0 01-.5.455L13.5 22a.5.5 0 01-.5-.5V21a.5.5 0 01.5-.5h.694a.5.5 0 01.5.546zm.321-3.5l-.046.5a.5.5 0 01-.5.454H13.5a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h1.015a.5.5 0 01.5.546zm.318-3.483l-.044.483a.5.5 0 01-.5.454H13.5a.5.5 0 01-.5-.5v-.486a.5.5 0 01.5-.5h1.334a.5.5 0 01.5.546z\"/><path d=\"M23.048 3.855L17.265.3a2 2 0 00-1.048-.3H7.783a2 2 0 00-1.048.3L.952 3.855A1.988 1.988 0 000 5.559v7.791a2 2 0 001.188 1.828l2.906 1.292a1 1 0 001.406-.914V9a.5.5 0 01.5-.5h12a.5.5 0 01.5.5v6.556a1 1 0 001.406.914l2.907-1.292A2 2 0 0024 13.35V5.559a1.988 1.988 0 00-.952-1.704zM22 12.7a1 1 0 01-.594.914.645.645 0 01-.906-.589V8.563A2.072 2.072 0 0018.423 6.5H5.577A2.072 2.072 0 003.5 8.563v4.462a.645.645 0 01-.906.589A1 1 0 012 12.7V5.838a.5.5 0 01.238-.426L7.3 2.3A2 2 0 018.349 2h7.3a2 2 0 011.048.3l5.063 3.115a.5.5 0 01.24.423z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});