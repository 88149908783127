define("ember-svg-jar/inlined/locker-room-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>locker-room-key</title><path d=\"M21.5 3h-19A2.5 2.5 0 000 5.5v1a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-1A2.5 2.5 0 0021.5 3zM23.5 8.5H.5A.5.5 0 000 9v9.5A2.5 2.5 0 002.5 21h19a2.5 2.5 0 002.5-2.5V9a.5.5 0 00-.5-.5zm-3.854 6.354l-1 1a.5.5 0 01-.353.146h-5.761a.5.5 0 00-.448.278 4 4 0 110-3.556.5.5 0 00.448.278h5.761a.5.5 0 01.353.146l1 1a.5.5 0 010 .708z\"/><circle cx=\"8.5\" cy=\"14.5\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});