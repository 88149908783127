define("ember-svg-jar/inlined/phone-actions-menu-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-menu-1</title><path d=\"M15.991 2.408H23a1 1 0 000-2h-7.009a1 1 0 000 2zM23 4.408h-7.009a1 1 0 000 2H23a1 1 0 000-2zM23 8.408h-7.009a1 1 0 000 2H23a1 1 0 000-2z\"/><circle cx=\"12\" cy=\"1.408\" r=\"1.25\"/><circle cx=\"12\" cy=\"5.408\" r=\"1.25\"/><circle cx=\"12\" cy=\"9.408\" r=\"1.25\"/><path d=\"M15.5 15.625a3.116 3.116 0 00-4.4 0l-.827.828a4.249 4.249 0 00-1.346 4.5 11.357 11.357 0 01-6.032-6.031 4.249 4.249 0 004.5-1.346l.83-.829a3.116 3.116 0 000-4.4l-3.59-3.582A2.232 2.232 0 00.986 5.5a13.321 13.321 0 009.653 18.07 13.417 13.417 0 007.7-.715 2.234 2.234 0 00.737-3.649z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});