define("ember-svg-jar/inlined/car-repair-checklist-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-repair-checklist-1</title><path d=\"M17.829 13.5H4.758a.252.252 0 01-.249-.276l.209-2.057a1.006 1.006 0 011-.917h4.911a1 1 0 000-2H5.715a3.013 3.013 0 00-2.988 2.733l-.092.9a.25.25 0 01-.4.17l-.735-.588a1 1 0 00-1.25 1.561l.884.707a.254.254 0 01.094.194.251.251 0 01-.092.2 2.738 2.738 0 00-1.011 2.119V18.5a1.753 1.753 0 001.75 1.75h16.954a1.753 1.753 0 001.75-1.75v-2.25a2.753 2.753 0 00-2.75-2.75zm-13.954 5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm13 0a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM6.375 21.246h-4a.249.249 0 00-.25.25v.25a2.25 2.25 0 004.5 0V21.5a.25.25 0 00-.25-.254zM18.375 21.246h-4a.249.249 0 00-.25.25v.25a2.25 2.25 0 004.5 0V21.5a.25.25 0 00-.25-.254z\"/><path d=\"M23.626 0h-10a.5.5 0 00-.5.5v11a.5.5 0 00.5.5h10a.5.5 0 00.5-.5V.5a.5.5 0 00-.5-.5zm-3 8.082l-1.782 1.582a1.251 1.251 0 01-1.869-.242l-.432-.647a.75.75 0 111.248-.832l.113.17a.254.254 0 00.176.109.251.251 0 00.2-.061l1.35-1.2a.75.75 0 111 1.122zm0-4.529l-1.782 1.582a1.25 1.25 0 01-1.869-.242l-.432-.647a.75.75 0 011.248-.832l.113.169a.249.249 0 00.374.049l1.35-1.2a.75.75 0 111 1.122z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});