define("ember-svg-jar/inlined/cursor-select-frame-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-select-frame-2</title><path d=\"M10.5 1h-8A1.5 1.5 0 001 2.5v8a.5.5 0 00.5.5h4.968a.994.994 0 00.8-.4 1.008 1.008 0 00.165-.875L6.75 7.316A.251.251 0 016.991 7H10a1 1 0 001-1V1.5a.5.5 0 00-.5-.5zM21.5 1h-8a.5.5 0 00-.5.5V6a1 1 0 001 1h3.009a.251.251 0 01.241.318l-.682 2.412a1.011 1.011 0 00.167.875 1 1 0 00.8.395H22.5a.5.5 0 00.5-.5v-8A1.5 1.5 0 0021.5 1zM10 17H6.991a.251.251 0 01-.241-.318l.682-2.413a1.012 1.012 0 00-.167-.875.994.994 0 00-.8-.394H1.5a.5.5 0 00-.5.5v8A1.5 1.5 0 002.5 23h8a.5.5 0 00.5-.5V18a1 1 0 00-1-1zM22.5 13h-4.968a.993.993 0 00-.8.4 1.008 1.008 0 00-.165.875l.681 2.411a.251.251 0 01-.241.318H14a1 1 0 00-1 1v4.5a.5.5 0 00.5.5h8A1.5 1.5 0 0023 21.5v-8a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});