define("ember-svg-jar/inlined/wildlife-protection-hold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wildlife-protection-hold</title><path d=\"M18.37 9.305a8.777 8.777 0 00-.212-1.9.5.5 0 01.39-.6A3.435 3.435 0 0017.87 0a3.4 3.4 0 00-3.082 1.933.5.5 0 01-.64.247 5.736 5.736 0 00-4.322.011.469.469 0 01-.6-.234A3.4 3.4 0 006.13 0a3.435 3.435 0 00-.678 6.8.5.5 0 01.39.6 8.777 8.777 0 00-.212 1.9c0 3.5 2.084 5.2 6.37 5.2s6.37-1.7 6.37-5.195zm-8.718-.174a1 1 0 111-1 1 1 0 01-1 1zm3.7 2.223l-1 1a.5.5 0 01-.708 0l-1-1A.5.5 0 0111 10.5h2a.5.5 0 01.354.854zm.994-2.223a1 1 0 111-1 1 1 0 01-.998 1zM4.124 15.836a1.4 1.4 0 00-.458 1.823L5 19.876a.5.5 0 11-.857.516l-1.352-2.25a2.472 2.472 0 01.125-2.485.5.5 0 00.084-.276V12.5a1.5 1.5 0 00-3 0v5.171a4.5 4.5 0 00.986 2.812l2.664 3.329a.5.5 0 00.39.188H8a.5.5 0 00.5-.5v-3.01a4.5 4.5 0 00-.757-2.5c-1.887-1.965-2.477-2.916-3.619-2.154zM22.5 11a1.5 1.5 0 00-1.5 1.5v2.881a.5.5 0 00.084.276 2.472 2.472 0 01.125 2.485l-1.352 2.25a.5.5 0 11-.857-.516l1.334-2.217a1.4 1.4 0 00-.458-1.823c-1.142-.762-1.732.189-3.619 2.156a4.5 4.5 0 00-.757 2.5V23.5a.5.5 0 00.5.5h3.96a.5.5 0 00.39-.188l2.664-3.329A4.5 4.5 0 0024 17.671V12.5a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});