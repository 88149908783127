define("ember-svg-jar/inlined/road-sign-2-5m-high", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-2.5m-high</title><path d=\"M12 .25A11.75 11.75 0 1023.75 12 11.763 11.763 0 0012 .25zm-1.693 3.463A.751.751 0 0111 3.25h2a.75.75 0 01.53 1.28l-1 1a.748.748 0 01-1.06 0l-1-1a.749.749 0 01-.163-.817zM12.75 12a.75.75 0 11-.75-.75.75.75 0 01.75.75zm-5.25.75a.75.75 0 01-.585-1.219L8.6 9.422A.682.682 0 008.75 9c0-.275-.5-.275-.5 0a.75.75 0 01-1.5 0 1.75 1.75 0 013.5 0 2.175 2.175 0 01-.477 1.358l-.438.549a.208.208 0 00-.025.223.211.211 0 00.19.12.75.75 0 010 1.5zm6.193 8.537a.751.751 0 01-.693.463h-2a.75.75 0 01-.53-1.28l1-1a.749.749 0 011.06 0l1 1a.749.749 0 01.163.817zm.557-3.787a.75.75 0 01-1.5 0v-.585a.058.058 0 00-.107-.029.78.78 0 01-1.286 0 .058.058 0 00-.107.029v.585a.75.75 0 01-1.5 0V14a.75.75 0 011.393-.386l.643 1.071a.25.25 0 00.428 0l.643-1.071A.75.75 0 0114.25 14zm1.25-8.25a1.75 1.75 0 010 3.5h-1a.75.75 0 010-1.5h1a.25.25 0 000-.5H15a1.252 1.252 0 01-1.25-1.25V8a.75.75 0 01.75-.75h2a.75.75 0 010 1.5h-1a.25.25 0 000 .5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});