define("ember-svg-jar/inlined/multiple-actions-video.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-video</title><path d=\"M18.752 12.508a2.965 2.965 0 01.8.111.5.5 0 00.606-.652A6.025 6.025 0 0014.5 8.006a5.957 5.957 0 00-2.466.532.5.5 0 00-.183.77 7.461 7.461 0 011.423 2.822.5.5 0 00.484.374zM14.02 7.006a3.5 3.5 0 10-3.51-3.5 3.507 3.507 0 003.51 3.5zM11.408 12.715a.5.5 0 00.29-.624A6.026 6.026 0 00.005 13.325a.6.6 0 00.6.667L9.644 14a.5.5 0 00.411-.214 3 3 0 011.353-1.071zM9.037 3.5A3.514 3.514 0 105.52 7a3.539 3.539 0 003.517-3.5zM23.37 15.523l-2.807.746a.25.25 0 01-.314-.242V15.5a1.5 1.5 0 00-1.5-1.5H12.5a1.463 1.463 0 00-1.5 1.5l-.006 7a1.5 1.5 0 001.5 1.5h6.25a1.5 1.5 0 001.5-1.5v-.524a.25.25 0 01.315-.241l2.805.751a.5.5 0 00.63-.483v-6a.5.5 0 00-.2-.4.506.506 0 00-.424-.08z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});