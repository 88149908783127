define("ember-svg-jar/inlined/fitness-jumping-rope-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fitness-jumping-rope-1</title><path d=\"M8.5 7A3.5 3.5 0 1012 3.5 3.5 3.5 0 008.5 7zm5.03-1.53a.749.749 0 010 1.06l-1 1a.75.75 0 01-1.06-1.06l1-1a.749.749 0 011.06 0zM4.5 18.5h-3a.5.5 0 00-.5.5v3a2 2 0 004 0v-3a.5.5 0 00-.5-.5zM6 12H5a3 3 0 00-3 3 .624.624 0 01-.385.577A1 1 0 001 16.5v.5a.5.5 0 00.5.5h3A.5.5 0 005 17v-.5a1 1 0 00-.615-.923A.627.627 0 014 15a1 1 0 011-1h1a1 1 0 000-2zM22.5 18.5h-3a.5.5 0 00-.5.5v3a2 2 0 004 0v-3a.5.5 0 00-.5-.5z\"/><path d=\"M22.385 15.577A.627.627 0 0122 15a3 3 0 00-3-3h-7a5 5 0 114.332-2.5 1 1 0 001.731 1A7 7 0 1012 14h7a1 1 0 011 1 .624.624 0 01-.385.577A1 1 0 0019 16.5v.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-.5a1 1 0 00-.615-.923z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});