define("ember-svg-jar/inlined/music-genre-moon-night", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-genre-moon-night</title><path d=\"M15.923 20.018a9.146 9.146 0 01-4.007-15.509A1.221 1.221 0 0011.1 2.4a10.645 10.645 0 105.132 19.856 1.221 1.221 0 00-.308-2.24z\"/><path d=\"M23.089.733a1.839 1.839 0 00-1.514-.392l-5.041.945a1.842 1.842 0 00-1.5 1.809v4.273a2.421 2.421 0 00-.21-.01 2.18 2.18 0 100 4.36 2.268 2.268 0 002.21-2.18v-5.9a.5.5 0 01.408-.491l3.719-.7a.5.5 0 01.593.491v2.75c-.07-.007-.14-.01-.21-.01a2.18 2.18 0 100 4.36 2.266 2.266 0 002.21-2.18V2.15a1.838 1.838 0 00-.665-1.417z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});