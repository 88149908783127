define("ember-svg-jar/inlined/folder-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-lock</title><path d=\"M14.358 14.226a.236.236 0 00.148-.208A4.505 4.505 0 0119 9.75a4.372 4.372 0 011.652.332.247.247 0 00.348-.224V4.5A1.5 1.5 0 0019.5 3H9.618a.5.5 0 01-.447-.276L8.224.83A1.5 1.5 0 006.88 0H1.5A1.5 1.5 0 000 1.5v15A1.5 1.5 0 001.5 18h10.773c.156 0 .227-.112.227-.259V17a3 3 0 011.858-2.774z\"/><path d=\"M22.752 15.529a.3.3 0 01-.252-.3v-.98a3.5 3.5 0 00-7 0v.963a.323.323 0 01-.265.318A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.248-1.471zM19 21.25a1 1 0 111-1 1 1 0 01-1 1zm1.5-6a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-1a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});