define("ember-svg-jar/inlined/transportation-ticket-train", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transportation-ticket-train</title><rect x=\"8.75\" y=\"9.246\" width=\"6.5\" height=\"2.75\" rx=\".25\" ry=\".25\"/><path d=\"M22.906 9.271A1.252 1.252 0 0024 8.031V5a1.752 1.752 0 00-1.75-1.75H1.75A1.752 1.752 0 000 5v3.031a1.253 1.253 0 001.094 1.24 2.751 2.751 0 010 5.458A1.252 1.252 0 000 15.969V19a1.752 1.752 0 001.75 1.75h20.5A1.752 1.752 0 0024 19v-3.031a1.252 1.252 0 00-1.094-1.24 2.751 2.751 0 010-5.458zM11 5.25h2a.75.75 0 010 1.5h-2a.75.75 0 010-1.5zm5.937 13.331a.752.752 0 01-1.051-.149L14.7 16.846a.251.251 0 00-.2-.1h-5a.251.251 0 00-.2.1l-1.184 1.583a.75.75 0 01-1.2-.9l.794-1.059a.249.249 0 00-.014-.316A1.732 1.732 0 017.25 15V9.5A1.752 1.752 0 019 7.746h6A1.752 1.752 0 0116.75 9.5V15a1.732 1.732 0 01-.446 1.157.249.249 0 00-.014.316l.8 1.062a.75.75 0 01-.153 1.046z\"/><circle cx=\"10\" cy=\"14\" r=\".75\"/><circle cx=\"14\" cy=\"14\" r=\".75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});