define("ember-svg-jar/inlined/single-woman-actions-edit-1.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-edit-1</title><path d=\"M13.49 15.682a.5.5 0 00.07-.618A7.53 7.53 0 007.206 11.5a7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h10.98a.5.5 0 00.386-.182c.04-.048 1.624-1.636 1.624-1.636zM15.084 22.74a.5.5 0 00-.13-.479l-2.215-2.215a.5.5 0 00-.834.216l-.886 3.1A.5.5 0 0011.5 24a.492.492 0 00.137-.019l3.1-.886a.5.5 0 00.347-.355zM21.771 16.229l-3-3a.5.5 0 00-.707 0l-5.011 5.011a.5.5 0 000 .707l3 3a.5.5 0 00.707 0l5.011-5.011a.5.5 0 000-.707zM19.477 11.816a.5.5 0 000 .706l3 3a.457.457 0 00.328.125.619.619 0 00.426-.172 2.621 2.621 0 000-3.707 2.654 2.654 0 00-3.754.048zM1.568 10.442a1 1 0 001.383-.3 5.162 5.162 0 00.454-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .251.251 0 01.228-.071.255.255 0 01.184.153 5.093 5.093 0 00.454.9 1 1 0 001.68-1.085 4.569 4.569 0 01-.642-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248A4.564 4.564 0 011.27 9.06a1 1 0 00.298 1.382zM7.21 8.6a2.7 2.7 0 01-2.741-2.352.5.5 0 01.324-.577 5.7 5.7 0 002.245-1.439.25.25 0 01.361 0 5.712 5.712 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.21 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});