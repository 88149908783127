define("ember-svg-jar/inlined/data-file-bars-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-bars-clock</title><path d=\"M6.75 24A6.75 6.75 0 100 17.25 6.758 6.758 0 006.75 24zm0-11.5A4.75 4.75 0 112 17.25a4.756 4.756 0 014.75-4.75z\"/><path d=\"M6.75 18.25h2a1 1 0 000-2h-.818a.176.176 0 01-.182-.2v-1.3a1 1 0 00-2 0v2.5a1 1 0 001 1z\"/><rect x=\"14.5\" y=\"5.5\" width=\"2\" height=\"8\" rx=\".5\" ry=\".5\"/><rect x=\"18\" y=\"7.5\" width=\"2\" height=\"6\" rx=\".5\" ry=\".5\"/><path d=\"M21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.275a.248.248 0 00.242.25c.366.007 1.038.034 1.485.082A.245.245 0 008 9.359V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.245.245 0 00-.241.21 7.956 7.956 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.414A2 2 0 0023.414 3z\"/><path d=\"M12.566 12.3a.25.25 0 00.434-.172V10.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v.229a.325.325 0 00.156.271 8.012 8.012 0 011.41 1.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});