define("ember-svg-jar/inlined/module-four", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>module-four</title><ellipse cx=\"12\" cy=\"9.272\" rx=\"3\" ry=\"1.5\"/><ellipse cx=\"12\" cy=\"3.772\" rx=\"3\" ry=\"1.5\"/><ellipse cx=\"18.5\" cy=\"6.272\" rx=\"3\" ry=\"1.5\"/><ellipse cx=\"6\" cy=\"6.272\" rx=\"3\" ry=\"1.5\"/><path d=\"M.9 17.492l9.649 4.2a.5.5 0 00.7-.458v-8a.5.5 0 00-.3-.459L.7 8.313a.5.5 0 00-.7.459v7.344a1.5 1.5 0 00.9 1.376zM12.75 13.228v8a.5.5 0 00.7.458l9.65-4.2a1.5 1.5 0 00.9-1.375V8.772a.5.5 0 00-.7-.459l-10.25 4.456a.5.5 0 00-.3.459z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});