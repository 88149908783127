define("ember-svg-jar/inlined/building-night", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>building-night</title><path d=\"M20.25 4a.5.5 0 00-.5.5v6a.5.5 0 00.5.5 3.5 3.5 0 000-7zM17.75 3.5a.5.5 0 00.011-1c-1.4-.029-2.016-.646-2.011-2a.5.5 0 00-.5-.5.5.5 0 00-.5.5c-.005 1.364-.624 1.981-2.007 2a.5.5 0 00.007 1h.007c1.385 0 1.981.6 1.993 2a.5.5 0 00.49.5h.01a.5.5 0 00.5-.475c.069-1.4.681-2.025 1.983-2.025zM9.75 11a.5.5 0 00.5-.5V9a.5.5 0 00-.5-.5h-2.5a.5.5 0 010-1h2.5a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5h-3.5a.5.5 0 010-1h3.5a.5.5 0 00.5-.5A1.5 1.5 0 008.75 3h-7a1.5 1.5 0 00-1.5 1.5v19a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V21a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-10a2.5 2.5 0 012.5-2.5z\"/><path d=\"M17.75 15.5a.5.5 0 00-.5-.5h-3a.5.5 0 010-1h2.866a.5.5 0 00.432-.75 1.5 1.5 0 00-1.3-.75h-6A1.5 1.5 0 008.75 14v9.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V21a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V18a.5.5 0 00-.5-.5h-2a.5.5 0 010-1h2a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});