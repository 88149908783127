define("ember-svg-jar/inlined/human-resources-employee-stress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-employee-stress</title><path d=\"M21.767 23.377a8.629 8.629 0 00-4.256-5.534.246.246 0 00-.193-.021.252.252 0 00-.15.125l-2.935 5.688a.251.251 0 00.223.365h6.827a.5.5 0 00.484-.623zM11.574 23.865A.249.249 0 0011.8 24h.409a.249.249 0 00.222-.135l3.339-6.473a.253.253 0 00.008-.212.25.25 0 00-.156-.142 12.156 12.156 0 00-3.628-.533 12.1 12.1 0 00-3.609.53.251.251 0 00-.148.354zM6.831 17.944a.247.247 0 00-.15-.124.253.253 0 00-.194.02 8.637 8.637 0 00-4.254 5.537.5.5 0 00.484.623h6.828a.25.25 0 00.222-.365zM5.953 9.172a6 6 0 0012 0v-7.4A1.5 1.5 0 0015.482.623l-1.2 1.022a.249.249 0 01-.36-.039l-.79-1.022A1.493 1.493 0 0011.958 0a1.522 1.522 0 00-1.186.563l-.8 1.006a.251.251 0 01-.355.036l-1.2-1a1.5 1.5 0 00-2.465 1.152l.001 7.415zm6 3.988a4.006 4.006 0 01-4-3.99V8.142a.25.25 0 01.3-.244 16.819 16.819 0 003.7.415 16.824 16.824 0 003.693-.413.247.247 0 01.211.049.25.25 0 01.094.195v1.024a4.005 4.005 0 01-3.999 3.992z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});