define("ember-svg-jar/inlined/receipt-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>receipt-1</title><path d=\"M21.6.973a3.518 3.518 0 00-2.226-.9H6.75a3.5 3.5 0 00-3.5 3.5v15.5a3.747 3.747 0 01-1.489 2.981l-.11.082a1 1 0 00.6 1.8h13a1 1 0 00.6-.2l.11-.082a5.754 5.754 0 002.291-4.582V9.318a.25.25 0 01.25-.25h2.25a2 2 0 002-2v-3.5A3.505 3.505 0 0021.6.973zM6.75 14.318a.75.75 0 110-1.5h7.5a.75.75 0 010 1.5zm5.25 3.25a.75.75 0 01-.75.75h-4.5a.75.75 0 010-1.5h4.5a.75.75 0 01.75.75zm-6-12a.75.75 0 01.75-.75h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 01-.75-.75zm8.25 4.75h-5.5a.75.75 0 010-1.5h5.5a.75.75 0 010 1.5zm6.5-3.75a.5.5 0 01-.5.5H18.5a.25.25 0 01-.25-.25V3.711c0-.167.028-1.624 1.033-1.643a1.5 1.5 0 011.467 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});