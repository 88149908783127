define("ember-svg-jar/inlined/sign-badge-bubble-message-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sign-badge-bubble-message-alternate</title><path d=\"M23.75 3.25A3.254 3.254 0 0020.5 0h-17A3.254 3.254 0 00.25 3.25v12.5A3.254 3.254 0 003.5 19h2.948l3.887 4.263a2.251 2.251 0 003.329 0L17.552 19H20.5a3.254 3.254 0 003.25-3.25zm-2.5 12.5a.751.751 0 01-.75.75H17a1.25 1.25 0 00-.924.408l-3.891 4.268a.25.25 0 01-.37 0l-3.891-4.267A1.25 1.25 0 007 16.5H3.5a.751.751 0 01-.75-.75V3.25a.751.751 0 01.75-.75h17a.751.751 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});