define("ember-svg-jar/inlined/real-estate-market-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-market-house</title><path d=\"M7.649 16.958h5.714a.5.5 0 00.5-.5v-3a.5.5 0 01.5-.5h.143a.5.5 0 00.325-.88l-4-3.428a.5.5 0 00-.65 0l-4 3.428a.5.5 0 00.325.88h.143a.5.5 0 01.5.5v3a.5.5 0 00.5.5z\"/><path d=\"M20.494 13.494h-2a.5.5 0 00-.5.469 7.5 7.5 0 11-7.955-7.955.5.5 0 00.469-.5v-2a.5.5 0 00-.525-.5 10.5 10.5 0 1011.01 11.011.5.5 0 00-.499-.525zM14.031.007a.5.5 0 00-.525.5v2a.5.5 0 00.469.5 7.513 7.513 0 017.017 7.017.5.5 0 00.5.468h2a.5.5 0 00.5-.525 10.515 10.515 0 00-9.961-9.96z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});