define("ember-svg-jar/inlined/audio-document-wav-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-document-wav-1</title><path d=\"M13.5 12.125a1.377 1.377 0 00-1.375 1.375v1.125a.25.25 0 00.25.25h2.25a.25.25 0 00.25-.25V13.5a1.377 1.377 0 00-1.375-1.375z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM10.125 14.084c0 2.692-.6 4.772-1.683 5.857a.624.624 0 01-1.042-.277s-.649-2.369-.7-2.579a.2.2 0 00-.388 0l-.7 2.579a.624.624 0 01-1.045.277c-1.085-1.085-1.683-3.165-1.683-5.857V11.5a.625.625 0 011.25 0v2.584a11.349 11.349 0 00.456 3.424c.056.172.246.2.311.014l1-3.686a.625.625 0 011.206 0l1.018 3.735c.055.135.236.125.29-.038a11.3 11.3 0 00.464-3.449V11.5a.625.625 0 011.25 0zm6 5.416a.625.625 0 01-1.25 0v-3.125a.25.25 0 00-.25-.25h-2.25a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0v-5.9a2.7 2.7 0 012.1-2.678 2.629 2.629 0 013.15 2.578zm6-5.7a9.788 9.788 0 01-2.137 6.092.626.626 0 01-.976 0 9.788 9.788 0 01-2.137-6.092v-2.3a.625.625 0 011.25 0v2.3a8.535 8.535 0 001.154 4.275.26.26 0 00.442 0 8.535 8.535 0 001.154-4.275v-2.3a.625.625 0 011.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});