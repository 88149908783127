define("ember-svg-jar/inlined/social-music-spotify-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-music-spotify-1</title><path d=\"M12 0a12 12 0 1012 12A12 12 0 0012 0zm5.38 17.72a.75.75 0 01-1.06.07 9.59 9.59 0 00-4.89-2 9.64 9.64 0 00-5.24.6.75.75 0 01-.63-1.39 11 11 0 016.08-.73 11 11 0 015.67 2.4.75.75 0 01.07 1.05zm.55-4a.75.75 0 01-1.06.07 9.59 9.59 0 00-4.88-2 9.57 9.57 0 00-5.26.61.75.75 0 11-.64-1.4 10.94 10.94 0 016.11-.74 11 11 0 015.66 2.39.75.75 0 01.07 1.1zm.64-3.91a.75.75 0 01-1.06.05 9.49 9.49 0 00-5-2.1 9.54 9.54 0 00-5.33.65.75.75 0 11-.66-1.35 11 11 0 016.2-.78 11 11 0 015.75 2.48.75.75 0 01.1 1.08z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});