define("ember-svg-jar/inlined/calendar-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>calendar-2</title><path d=\"M21.036 3.7a1.06 1.06 0 00-.984-.8H3.21a1 1 0 00-.991.868L.061 19.969a1 1 0 00.991 1.131H17.96a1 1 0 00.991-.872l.243-1.878h4a.749.749 0 00.734-.9zM8.124 19.1a.25.25 0 01-.248-.282l.359-2.75a.249.249 0 01.248-.218h3.109a.25.25 0 01.248.282l-.355 2.75a.249.249 0 01-.248.218zm-5.644 0a.25.25 0 01-.248-.283l.366-2.75a.251.251 0 01.248-.217h3.115a.25.25 0 01.248.282l-.358 2.75a.251.251 0 01-.251.218zm.851-8.533a.249.249 0 01.248-.217h3.1a.25.25 0 01.248.282l-.391 3a.25.25 0 01-.248.218H3.179a.25.25 0 01-.188-.085.252.252 0 01-.06-.2zM13 4.9a.25.25 0 01.248.282l-.38 2.95a.251.251 0 01-.248.218h-3.1a.25.25 0 01-.248-.282l.385-2.951A.249.249 0 019.91 4.9zm5.626 0a.25.25 0 01.248.282L18.5 8.133a.249.249 0 01-.248.218h-3.1a.25.25 0 01-.248-.282l.381-2.95a.249.249 0 01.248-.218zm-4.195 8.95a.25.25 0 01-.188-.085.253.253 0 01-.06-.2l.387-3a.249.249 0 01.248-.218h3.1a.25.25 0 01.248.282l-.387 3a.251.251 0 01-.248.218zm-2.134-3.5a.25.25 0 01.248.282l-.387 3a.249.249 0 01-.248.218H8.808a.25.25 0 01-.248-.282l.391-3a.251.251 0 01.248-.218zm1.564 5.718a.249.249 0 01.247-.218h3.1a.251.251 0 01.248.282l-.355 2.75a.251.251 0 01-.248.218h-3.1a.25.25 0 01-.188-.085.253.253 0 01-.06-.2zM7.388 4.9a.25.25 0 01.188.085.245.245 0 01.06.2l-.384 2.95A.251.251 0 017 8.351H3.912a.25.25 0 01-.248-.283l.393-2.95a.249.249 0 01.248-.218z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});