define("ember-svg-jar/inlined/video-player-playing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-player-playing</title><path d=\"M12 4a8 8 0 108 8 8 8 0 00-8-8zm3.768 8.678l-5.59 3.022A.768.768 0 019 15V9a.768.768 0 011.178-.7l5.59 3.023a.741.741 0 010 1.354zM9.727 2.259a10.113 10.113 0 014.546 0 1 1 0 00.454-1.948 12.159 12.159 0 00-5.454 0 1 1 0 00.454 1.948zM2.126 7.039A1 1 0 003.5 6.72 10.12 10.12 0 016.72 3.5a1 1 0 00-1.057-1.7 12.113 12.113 0 00-3.856 3.861 1 1 0 00.319 1.378zM2.259 14.273a10.108 10.108 0 010-4.547 1 1 0 10-1.947-.453 12.069 12.069 0 000 5.453 1 1 0 101.947-.453zM6.72 20.494a10.12 10.12 0 01-3.22-3.216 1 1 0 10-1.7 1.059 12.13 12.13 0 003.856 3.856 1 1 0 001.057-1.7zM14.273 21.739a10.113 10.113 0 01-4.546 0 1 1 0 00-.454 1.949 12.111 12.111 0 005.454 0 1 1 0 00-.454-1.949zM21.874 16.959a1 1 0 00-1.378.319 10.12 10.12 0 01-3.216 3.216 1 1 0 001.057 1.7 12.13 12.13 0 003.856-3.856 1 1 0 00-.319-1.379zM23.688 9.273a1 1 0 10-1.947.453 10.108 10.108 0 010 4.547 1 1 0 101.947.453 12.069 12.069 0 000-5.453zM17.28 3.5a10.12 10.12 0 013.22 3.22 1 1 0 001.7-1.059 12.113 12.113 0 00-3.856-3.855A1 1 0 0017.28 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});