define("ember-svg-jar/inlined/smart-house-phone-lock-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-house-phone-lock-1</title><path d=\"M17.279 10.505a.249.249 0 00.221-.248v-.536a.25.25 0 01.25-.25h1.938a.729.729 0 00.471-1.286L10.68.164a.728.728 0 00-.943 0L.258 8.185A.73.73 0 00.73 9.471h2.02a.25.25 0 01.25.25v7.041a.729.729 0 00.729.729H14.25a.249.249 0 00.25-.25v-3.75a2.956 2.956 0 012.779-2.986zM13.5 11.991a1 1 0 01-1 1h-4a1 1 0 01-1-1v-3a1 1 0 01.4-.8.249.249 0 00.1-.2v-.5a2.5 2.5 0 015 0v.5a.249.249 0 00.1.2 1 1 0 01.4.8z\"/><path d=\"M12 7.491a1.5 1.5 0 00-3 0v.25a.25.25 0 00.25.25h2.5a.25.25 0 00.25-.25zM10.5 9.53a.981.981 0 10.98.981.98.98 0 00-.98-.981zM22.526 12.009h-5.052A1.5 1.5 0 0016 13.518V22.5a1.493 1.493 0 001.474 1.509h5.052A1.493 1.493 0 0024 22.5v-8.982a1.493 1.493 0 00-1.474-1.509zM18 20.509v-5.982a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v5.982a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});