define("ember-svg-jar/inlined/server-monitor-exchange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-monitor-exchange</title><path d=\"M12.2 13.528a2 2 0 00-2-2h-8a2 2 0 00-2 2v5.693a2.022 2.022 0 002 1.807h2.75a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H3.7a1 1 0 100 2h5a1 1 0 000-2H7.45a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h2.75a2.022 2.022 0 002-1.807zm-2.5 0a.5.5 0 01.5.5v3.5a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-3.5a.5.5 0 01.5-.5zM10.076 5.169a.506.506 0 00.524-.047l2-1.5a.5.5 0 000-.8l-2-1.5a.5.5 0 00-.8.4v.25a.25.25 0 01-.25.25H7.8a3 3 0 00-3 3v1.5a.25.25 0 01-.25.25H4.3a.5.5 0 00-.4.8l1.5 2a.5.5 0 00.8 0l1.5-2a.5.5 0 00-.4-.8h-.25a.25.25 0 01-.25-.25v-1.5a1 1 0 011-1h1.75a.25.25 0 01.25.25v.25a.5.5 0 00.276.447zM21.8-.029h-6a2 2 0 000 4h6a2 2 0 000-4zm-6 2a1 1 0 111 1 1 1 0 01-1-1zM21.8 4.971h-6a2 2 0 000 4h6a2 2 0 000-4zm-6 2a1 1 0 111 1 1 1 0 01-1-1zM21.8 9.971h-6a2 2 0 000 4h6a2 2 0 000-4zm-6 2a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});