define("ember-svg-jar/inlined/flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flash</title><path d=\"M17.5 8h-4.1L17.924.763A.5.5 0 0017.5 0h-8a.5.5 0 00-.485.379l-3 12A.5.5 0 006.5 13h3.345L7.018 23.367a.5.5 0 00.275.587.509.509 0 00.623-.178l10-15A.5.5 0 0017.5 8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});