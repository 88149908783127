define("ember-svg-jar/inlined/safety-helmlet-mine-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-helmlet-mine-light</title><path d=\"M3.25 16.207a1 1 0 01-.232.64L.866 19.43a.5.5 0 00.384.82h7.5a3.958 3.958 0 003.157-1.45 4.523 4.523 0 013.843-1.55h2a1.5 1.5 0 001.5-1.5v-.5a.5.5 0 00-.5-.5h-15a.5.5 0 00-.5.5z\"/><path d=\"M19.517 9.913a.25.25 0 01-.474-.037A8.009 8.009 0 003.25 11.75v1a.5.5 0 00.5.5h15.294a.5.5 0 01.463.312 3.505 3.505 0 002.544 2.118 1.041 1.041 0 00.2.02 1 1 0 001-1V8.8a1 1 0 00-1.2-.98 3.508 3.508 0 00-2.534 2.093z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});