define("ember-svg-jar/inlined/police-woman-spy-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-woman-spy-1</title><path d=\"M17.146 15.714a5.4 5.4 0 001.317.161 3.8 3.8 0 002.921-1.12.5.5 0 00-.231-.8c-1.352-.434-2.105-2.169-2.439-3.885.023-.23.036-.462.036-.7v-.25H20a.744.744 0 00.551-1.25.742.742 0 00-.551-.244H4a.765.765 0 00-.75.75.75.75 0 00.75.75h1.25v.25c0 .221.012.439.033.655-.336 1.71-1.083 3.435-2.436 3.869a.5.5 0 00-.231.8 3.8 3.8 0 002.921 1.12 5.351 5.351 0 001.317-.161 3.092 3.092 0 001.358-.7 6.731 6.731 0 007.542.023 3.03 3.03 0 001.392.732zM6.75 9.376v-.25h10.5v.25a5.25 5.25 0 01-10.5 0z\"/><path d=\"M13.077 11.237h-2a.64.64 0 00-.3 1.2 2.488 2.488 0 00.436.181 3.289 3.289 0 00.877.125 2.949 2.949 0 00.861-.126 2.408 2.408 0 00.431-.179.639.639 0 00-.3-1.2zM18.6 6.626c-.34-2.226-1.228-5.138-2.635-6.16a1.738 1.738 0 00-1.333-.315 1.806 1.806 0 00-1.19.769 1.815 1.815 0 01-2.884 0A1.805 1.805 0 009.369.151a1.729 1.729 0 00-1.334.315C6.628 1.488 5.74 4.4 5.4 6.626zM11.512 17.766l-1.25 5.5a.5.5 0 00.488.61h2.5a.5.5 0 00.488-.61l-1.25-5.5a.519.519 0 00-.976 0zM8.5 23.876h.487a.5.5 0 00.388-.185.493.493 0 00.1-.418L8.2 17.156a.5.5 0 00-.582-.388c-.552.1-1.054.229-1.531.362a.252.252 0 00-.164.146l-1.258 3.048a.251.251 0 00.065.282l3.608 3.207a.249.249 0 00.162.063z\"/><path d=\"M4.5 18.111a.25.25 0 00-.327-.326 12.2 12.2 0 00-2.7 1.508A2.461 2.461 0 00.5 21.271v2.105a.5.5 0 00.5.5h5.246a.25.25 0 00.166-.437L3.668 21a.5.5 0 01-.13-.564zM15.009 23.876h.491a.249.249 0 00.166-.063l3.608-3.207a.251.251 0 00.065-.282l-1.259-3.048a.248.248 0 00-.163-.145c-.478-.134-.98-.259-1.532-.363a.5.5 0 00-.582.388l-1.283 6.117a.493.493 0 00.1.418.5.5 0 00.389.185z\"/><path d=\"M17.754 23.876H23a.5.5 0 00.5-.5v-2.105a2.459 2.459 0 00-.968-1.977 12.15 12.15 0 00-2.7-1.509.251.251 0 00-.272.054.248.248 0 00-.055.272l.957 2.325a.5.5 0 01-.13.564l-2.744 2.439a.25.25 0 00.166.437z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});