define("ember-svg-jar/inlined/traffic-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>traffic-light</title><path d=\"M23 0H7.25a1 1 0 00-1 1v.75a.5.5 0 01-.5.5A3.254 3.254 0 002.5 5.5V21a.5.5 0 01-.5.5h-.75a1.25 1.25 0 000 2.5h5a1.25 1.25 0 000-2.5H5.5A.5.5 0 015 21V5.5a.75.75 0 01.75-.75.5.5 0 01.5.5V7a1 1 0 001 1H23a1 1 0 001-1V1a1 1 0 00-1-1zM10 5.5A1.5 1.5 0 1111.5 4 1.5 1.5 0 0110 5.5zm5 0A1.5 1.5 0 1116.5 4 1.5 1.5 0 0115 5.5zm5 0A1.5 1.5 0 1121.5 4 1.5 1.5 0 0120 5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});