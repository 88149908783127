define("ember-svg-jar/inlined/fitness-shaker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fitness-shaker</title><path d=\"M15 2h-1.25a.5.5 0 01-.5-.5v-.25a1.25 1.25 0 00-2.5 0v.25a.5.5 0 01-.5.5H9a2.5 2.5 0 00-2.5 2.5v1A.5.5 0 007 6h10a.5.5 0 00.5-.5v-1A2.5 2.5 0 0015 2zM17 7.5H7a.5.5 0 00-.5.5v1.1a1.494 1.494 0 00.419 1.04L8.4 11.676a.5.5 0 010 .7l-1.467 1.48a1.493 1.493 0 00-.433 1.055V21.5A2.5 2.5 0 009 24h6a2.5 2.5 0 002.5-2.5v-6.578a1.51 1.51 0 00-.453-1.074L15.565 12.4a.5.5 0 01-.008-.707l1.517-1.556a1.492 1.492 0 00.426-1.044V8a.5.5 0 00-.5-.5zM14.391 17l-2.785 3.242a.249.249 0 01-.439-.163v-2.162a.5.5 0 00-.5-.5H9.8A.251.251 0 019.609 17l2.785-3.242a.249.249 0 01.439.163v2.158a.5.5 0 00.5.5h.867a.251.251 0 01.19.413z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});