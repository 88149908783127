define("ember-svg-jar/inlined/database-remove-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-remove-alternate</title><path d=\"M3.009 17.505v-.723a.251.251 0 01.372-.218A13.839 13.839 0 007.7 17.959a.994.994 0 001.152-.927v-.023A.969.969 0 008.041 16c-3.144-.523-5.032-1.738-5.032-2.643v-.723a.25.25 0 01.372-.218 15.231 15.231 0 005.6 1.572.991.991 0 00.954-.465l.013-.021a.973.973 0 00-.7-1.491c-3.865-.379-6.242-1.786-6.242-2.8v-.726a.25.25 0 01.372-.218 17.243 17.243 0 007.9 1.7 17.249 17.249 0 007.9-1.7.25.25 0 01.371.215 4.92 4.92 0 01-.027.868 1.015 1.015 0 00.521 1.135l.093.047a.975.975 0 001.41-.877V5.059c0-3.186-5.293-4.9-10.274-4.9S1.023 1.87 1.011 5.049v12.456c0 2.608 3.545 4.23 7.562 4.733a.982.982 0 001.036-1.367l-.009-.026a1.021 1.021 0 00-.795-.584c-3.605-.44-5.796-1.78-5.796-2.756zm8.275-15.351c5.047 0 8.268 1.717 8.273 2.9v.009c-.011 1.184-3.231 2.9-8.273 2.9S3.01 6.243 3.01 5.059s3.223-2.905 8.274-2.905z\"/><path d=\"M16.646 11.16a6.346 6.346 0 106.345 6.345 6.353 6.353 0 00-6.345-6.345zm-1.554 8.933a.732.732 0 01-1.035-1.035l1.376-1.376a.251.251 0 000-.354l-1.376-1.376a.732.732 0 011.035-1.035l1.377 1.376a.249.249 0 00.353 0l1.378-1.376a.732.732 0 011.035 1.035l-1.376 1.376a.251.251 0 000 .354l1.376 1.376a.732.732 0 11-1.035 1.035l-1.376-1.376a.249.249 0 00-.353 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});