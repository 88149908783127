define("ember-svg-jar/inlined/style-three-pin-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-table</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm3.875 13.826A.5.5 0 0115.5 14h-7a.5.5 0 01-.5-.562l.5-3.995A.5.5 0 019 9h6a.5.5 0 01.5.438l.5 3.995a.5.5 0 01-.125.393zM20 7.25a.75.75 0 01-.75.75h-12a.5.5 0 00-.5.5v3a.75.75 0 01-1.5 0v-3a.5.5 0 00-.5-.5.75.75 0 010-1.5h14.5a.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});