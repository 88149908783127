define("ember-svg-jar/inlined/3d-box-expand-corners", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>3d-box-expand-corners</title><path d=\"M10 3.312h.75a.25.25 0 01.25.25v1a1 1 0 102 0v-1a.25.25 0 01.25-.25H14a.75.75 0 00.473-1.332l-2-1.625a.752.752 0 00-.946 0l-2 1.625A.75.75 0 0010 3.312zM15.586 18.476a2 2 0 112.828-2.828l.659.659a.25.25 0 00.427-.176v-5.975a.751.751 0 00-.449-.687L12.3 6.516a.751.751 0 00-.6 0L4.949 9.469a.751.751 0 00-.449.687v5.975a.25.25 0 00.427.176l.659-.659a2 2 0 112.828 2.828l-1.161 1.161a.254.254 0 00-.069.224.251.251 0 00.146.182l4.47 1.947a.5.5 0 00.4 0l4.464-1.953a.25.25 0 00.076-.406zm.769-8.549a.25.25 0 010 .458L12.1 12.247a.257.257 0 01-.2 0l-4.255-1.862a.25.25 0 010-.458L11.9 8.066a.249.249 0 01.2 0z\"/><path d=\"M7.707 17.769a1 1 0 10-1.414-1.414L2.5 20.148a.25.25 0 01-.354 0l-.616-.616a.75.75 0 00-1.28.53v3a.75.75 0 00.75.75h3a.75.75 0 00.53-1.28l-.616-.616a.25.25 0 010-.354zM23.75 23.062v-3a.75.75 0 00-1.28-.53l-.616.616a.25.25 0 01-.354 0l-3.793-3.793a1 1 0 10-1.414 1.414l3.793 3.793a.25.25 0 010 .354l-.616.616a.75.75 0 00.53 1.28h3a.75.75 0 00.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});