define("ember-svg-jar/inlined/fitness-protein", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fitness-protein</title><path d=\"M5 5.5h14a.5.5 0 00.5-.5V1.5A1.5 1.5 0 0018 0H6a1.5 1.5 0 00-1.5 1.5V5a.5.5 0 00.5.5zM19 7H5a3.5 3.5 0 00-3.5 3.5v10A3.5 3.5 0 005 24h14a3.5 3.5 0 003.5-3.5v-10A3.5 3.5 0 0019 7zm-1 10a.5.5 0 00-.5.5v2a.75.75 0 01-.75.75h-2a.75.75 0 01-.75-.75v-2a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v2a.75.75 0 01-.75.75h-2a.75.75 0 01-.75-.75v-2A.5.5 0 006 17a1 1 0 010-2 .5.5 0 00.5-.5v-2a.75.75 0 01.75-.75h2a.75.75 0 01.75.75v2a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-2a.75.75 0 01.75-.75h2a.75.75 0 01.75.75v2a.5.5 0 00.5.5 1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});