define("ember-svg-jar/inlined/sign-badge-rectangular-round-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sign-badge-rectangular-round-alternate</title><path d=\"M17.751 0H6.249A6.256 6.256 0 000 6.249v11.5A6.256 6.256 0 006.249 24h11.5A6.256 6.256 0 0024 17.751V6.249A6.256 6.256 0 0017.751 0zM21.5 17.751a3.754 3.754 0 01-3.749 3.749H6.249A3.754 3.754 0 012.5 17.751V6.249A3.754 3.754 0 016.249 2.5h11.5A3.754 3.754 0 0121.5 6.249z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});