define("ember-svg-jar/inlined/video-file-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-settings</title><path d=\"M23.416 3L21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v6.356a.246.246 0 00.214.248 7.766 7.766 0 011.45.317A.247.247 0 008 8.689V2.5a.5.5 0 01.5-.5h10.88a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.145.353V18a.5.5 0 01-.5.5h-7.374a.249.249 0 00-.236.32 3.245 3.245 0 01.048 1.377.249.249 0 00.24.3H22a2 2 0 002-2V4.414A2 2 0 0023.416 3z\"/><path d=\"M14.077 13.436l5.023-2.781a.735.735 0 000-1.31l-5.845-3.236a.886.886 0 00-.834-.009.755.755 0 00-.42.665v4.5a.241.241 0 00.141.218 3.28 3.28 0 011.412 1.311 3.1 3.1 0 01.217.465c.036.094.183.235.306.177z\"/><circle cx=\"6.251\" cy=\"17.031\" r=\"1.5\"/><path d=\"M12.019 15.654a1.782 1.782 0 00-1.7-2.953l-1.411.326a.452.452 0 01-.533-.31l-.425-1.393a1.775 1.775 0 00-3.4 0l-.424 1.393a.454.454 0 01-.533.31L2.184 12.7a1.782 1.782 0 00-1.7 2.952l.988 1.066a.463.463 0 010 .625l-.989 1.065a1.782 1.782 0 001.7 2.953l1.417-.326a.454.454 0 01.533.311l.424 1.392a1.775 1.775 0 003.4 0l.424-1.392a.451.451 0 01.533-.311l1.411.326a1.782 1.782 0 001.7-2.952l-.988-1.065a.463.463 0 010-.625zm-5.768 4.377a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});