define("ember-svg-jar/inlined/hyperlink", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hyperlink</title><path d=\"M10.232 14.341a1 1 0 00-1.307.54.966.966 0 01-.219.326A.985.985 0 018 15.5H4a2 2 0 01-2-2v-3a2 2 0 012-2h4a.991.991 0 01.708.294.972.972 0 01.217.324 1 1 0 101.847-.767A3 3 0 008 6.5H4a4 4 0 00-4 4v3a4 4 0 004 4h4a3.007 3.007 0 002.772-1.85 1 1 0 00-.54-1.309z\"/><path d=\"M6 12a1 1 0 001 1h11a1 1 0 000-2H7a1 1 0 00-1 1z\"/><path d=\"M20 6.5h-4a3.006 3.006 0 00-2.772 1.85 1 1 0 101.847.767.976.976 0 01.219-.326A.989.989 0 0116 8.5h4a2 2 0 012 2v3a2 2 0 01-2 2h-4a.987.987 0 01-.708-.294.962.962 0 01-.217-.324 1 1 0 10-1.847.768A3 3 0 0016 17.5h4a4 4 0 004-4v-3a4 4 0 00-4-4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});