define("ember-svg-jar/inlined/professions-woman-magician", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-woman-magician</title><path d=\"M1 22.266v1.356a.25.25 0 00.25.25h21.5a.25.25 0 00.25-.25v-1.356a1.976 1.976 0 00-.77-1.579c-1.33-1.019-4.5-2.815-10.23-2.815s-8.895 1.8-10.23 2.815A1.976 1.976 0 001 22.266zm7.277-2.307a.553.553 0 01.756-.279l2.474 1.237a1.1 1.1 0 00.986 0l2.474-1.237a.553.553 0 01.756.279 3.729 3.729 0 010 2.823.553.553 0 01-.756.279l-2.474-1.237a1.1 1.1 0 00-.986 0l-2.474 1.237a.553.553 0 01-.756-.279 3.729 3.729 0 010-2.823z\"/><path d=\"M1.455 7.692a1 1 0 00.784 1.177 49.31 49.31 0 003.516.56.251.251 0 01.219.231c.034.522.026.33.026 1.65a.265.265 0 01-.008.063c-.333 1.284-.857 2.418-2.145 2.832a.5.5 0 00-.288.71A2.929 2.929 0 006.1 16.32a2.351 2.351 0 001.4-.423.25.25 0 01.32.024 5.974 5.974 0 008.35 0 .25.25 0 01.32-.024 2.346 2.346 0 001.4.423 2.929 2.929 0 002.542-1.405.5.5 0 00-.288-.71c-1.288-.414-1.812-1.548-2.145-2.832A.259.259 0 0118 11.31a16.306 16.306 0 01.028-1.649.251.251 0 01.219-.232 49.346 49.346 0 003.514-.56.995.995 0 00-.322-1.962h-.018a51.473 51.473 0 01-9.391.914 50.534 50.534 0 01-9.4-.916 1 1 0 00-1.175.787zM8 11.622V10.016a.094.094 0 01.028-.074.091.091 0 01.072-.026c1.3.1 2.6.169 3.9.169s2.6-.066 3.893-.169a.1.1 0 01.079.029.1.1 0 01.03.078v1.599a4 4 0 01-8 0z\"/><path d=\"M18.026 6.433a.251.251 0 00.219-.232l.255-4.059a1 1 0 00-.626-.991 15.108 15.108 0 00-4.319-.957 18.351 18.351 0 00-3.11 0 15.108 15.108 0 00-4.317.957 1 1 0 00-.626.991l.254 4.052a.25.25 0 00.218.232 47.8 47.8 0 0012.052.007z\"/><circle cx=\"14\" cy=\"11.372\" r=\"1\"/><circle cx=\"10\" cy=\"11.372\" r=\"1\"/><path d=\"M13.077 13.253h-2a.64.64 0 00-.3 1.2 2.443 2.443 0 00.437.181 3.024 3.024 0 00.867.126h.006a2.976 2.976 0 00.863-.125 2.433 2.433 0 00.436-.181.64.64 0 00-.3-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});