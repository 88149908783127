define("ember-svg-jar/inlined/outdoors-barn-bench", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-barn-bench</title><path d=\"M21 15a1 1 0 00-1-1h-1.594a.25.25 0 01-.24-.179l-.736-2.5a.251.251 0 01.24-.321h.83a1 1 0 000-2h-8a1 1 0 000 2h.83a.251.251 0 01.24.321l-.736 2.5a.25.25 0 01-.24.179H9a1 1 0 000 2h.859a.251.251 0 01.24.321l-.558 1.9a1 1 0 00.677 1.241.953.953 0 00.282.041 1 1 0 00.959-.717l.766-2.6a.248.248 0 01.239-.18h4.072a.248.248 0 01.239.18l.766 2.6a1 1 0 00.959.717.953.953 0 00.282-.041 1 1 0 00.677-1.241l-.558-1.9a.251.251 0 01.24-.321H20a1 1 0 001-1zm-7.8-1a.249.249 0 01-.24-.32l.735-2.5a.25.25 0 01.24-.18h1.13a.25.25 0 01.24.18l.735 2.5a.249.249 0 01-.24.32z\"/><path d=\"M1.3 8.784l1.379-.428A.251.251 0 013 8.6V22a2 2 0 002 2h18a1 1 0 000-2H6a1 1 0 01-1-1V8.372a1 1 0 01.7-.955l17.6-5.463a1 1 0 10-.6-1.91l-22 6.83a1 1 0 00.6 1.91z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});