define("ember-svg-jar/inlined/smiley-drop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-drop</title><path d=\"M24 5.8c0-1.325-1.165-3.29-2.143-4.7a1.959 1.959 0 00-3.224 0c-.164.237-.333.49-.5.753a.246.246 0 01-.333.078 11.737 11.737 0 105.19 6.614.245.245 0 01.05-.245A3.735 3.735 0 0024 5.8zm-3.96-3.29a.244.244 0 01.409 0c.471.716 1.592 2.5 1.592 3.286a1.8 1.8 0 01-3.592 0c0-.783 1.12-2.57 1.591-3.286zm-1 7.182a.98.98 0 01-.566 1.264 1 1 0 01-.35.064.98.98 0 01-.915-.63 1.183 1.183 0 00-1.044-.839 1.182 1.182 0 00-1.043.839.98.98 0 01-1.831-.7 3.017 3.017 0 015.749 0zm-11.693-2.1a3.121 3.121 0 012.875 2.1.981.981 0 01-.567 1.264.992.992 0 01-.349.064.98.98 0 01-.915-.63 1.184 1.184 0 00-1.044-.839 1.183 1.183 0 00-1.047.839.98.98 0 01-1.831-.7 3.121 3.121 0 012.878-2.098zm-.432 8.292a.426.426 0 01.372-.215h8.936a.428.428 0 01.372.644 5.589 5.589 0 01-9.68 0 .427.427 0 010-.429z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});