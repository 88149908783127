define("ember-svg-jar/inlined/car-tool-steering-wheel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-tool-steering-wheel</title><path d=\"M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm6.75 12.125a6.718 6.718 0 01-1.81 4.591.247.247 0 01-.2.079.25.25 0 01-.187-.1l-1.984-2.731a.25.25 0 01-.012-.277 2.976 2.976 0 00.424-1.316.252.252 0 01.172-.218l3.214-1.044a.252.252 0 01.326.206 6.388 6.388 0 01.057.81zm-13.5 0a6.209 6.209 0 01.055-.812.249.249 0 01.114-.18.253.253 0 01.211-.026l3.215 1.044a.252.252 0 01.172.218 2.975 2.975 0 00.423 1.316.25.25 0 01-.011.277l-1.984 2.731a.25.25 0 01-.386.023 6.722 6.722 0 01-1.809-4.591zM11 9.137a.251.251 0 01-.154.231 2.983 2.983 0 00-1.111.808.25.25 0 01-.267.075L6.25 9.205a.251.251 0 01-.141-.36A6.757 6.757 0 0110.7 5.509a.251.251 0 01.3.245zm.053 5.992a.25.25 0 01.264-.095 2.609 2.609 0 001.366 0 .25.25 0 01.263.095l1.991 2.74a.25.25 0 01-.1.374 6.7 6.7 0 01-5.681 0 .25.25 0 01-.1-.374zm3.459-4.872a.216.216 0 01-.234-.066 2.992 2.992 0 00-1.143-.831.217.217 0 01-.135-.2v-3.4a.249.249 0 01.3-.245 6.758 6.758 0 014.59 3.33.249.249 0 01-.141.36z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});