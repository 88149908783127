define("ember-svg-jar/inlined/phone-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-off</title><path d=\"M19.2 14.708l-1.55-1.549a1.549 1.549 0 00-2.18.114l-.574.574-.446-.361-2.26 2.264a.257.257 0 00.018.371 20.778 20.778 0 003.011 2.156 2.385 2.385 0 002.926-.443l.943-.944a1.549 1.549 0 00.112-2.182z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm-1.349 2.089A9.972 9.972 0 0118.1 4.084a.256.256 0 01.021.381l-6.264 6.264a.25.25 0 01-.368-.016A105.058 105.058 0 0110.153 9.1l.575-.574a1.55 1.55 0 00.114-2.181L9.292 4.8a1.547 1.547 0 00-2.181.115l-.944.943a2.384 2.384 0 00-.444 2.926 49.67 49.67 0 003.345 4.423.244.244 0 01-.018.332l-4.585 4.583a.256.256 0 01-.381-.021 9.993 9.993 0 016.567-16.012zm2.7 19.822A9.972 9.972 0 015.9 19.917a.256.256 0 01-.022-.381L19.536 5.879a.256.256 0 01.381.022 9.992 9.992 0 01-6.567 16.01z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});