define("ember-svg-jar/inlined/single-man-actions-alarm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-alarm</title><path d=\"M12 15.5a5.968 5.968 0 011.465-3.919.25.25 0 00-.09-.394l-2.222-.952a.249.249 0 01-.153-.229V8.87a.252.252 0 01.074-.177 3.311 3.311 0 00.779-2.231.251.251 0 01.088-.176 1.873 1.873 0 00.538-1.4 1.58 1.58 0 00-.317-1.015.251.251 0 01-.034-.264 2.526 2.526 0 00.156-1.727C11.944.493 10.241 0 8.888 0a3.752 3.752 0 00-3.163 1.324.252.252 0 01-.213.118 1.074 1.074 0 00-.9.375c-.371.493-.1 1.17.177 1.732a.253.253 0 01-.046.288 1.3 1.3 0 00-.162.2A1.763 1.763 0 004.32 5a1.521 1.521 0 00.638 1.331.249.249 0 01.117.194A3.167 3.167 0 005.92 8.7a.25.25 0 01.08.183v1.123a.248.248 0 01-.151.229l-3.937 1.687C.64 12.468.457 13.626.205 15.23c-.055.359-.117.751-.2 1.179A.5.5 0 00.5 17h11.249a.25.25 0 00.25-.25zM23.657 19.882a4.08 4.08 0 01-1.157-2.715V15.5a4.5 4.5 0 10-9 0v1.667a4.08 4.08 0 01-1.16 2.718l-.24.315a.5.5 0 00.4.8h11a.5.5 0 00.4-.8zM19.681 22.194a.5.5 0 00-.4-.194h-2.568a.5.5 0 00-.483.626 1.827 1.827 0 003.54 0 .5.5 0 00-.089-.432z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});