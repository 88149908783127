define("ember-svg-jar/inlined/livestock-bull-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>livestock-bull-body</title><path d=\"M23.915 15.12c0-1.542 0-4.752-2.247-6.4a7.434 7.434 0 00-3.489-1.079c-.532-.932-2.541-4.017-5.807-4.017A6.37 6.37 0 006.862 6.5 3.394 3.394 0 004.889 4a1 1 0 10-.895 1.789c.509.254 1.5.838.493 2.543a2.045 2.045 0 00-1.953.37 2.522 2.522 0 01-.3-2.284 1 1 0 10-1.9-.633 4.34 4.34 0 001.094 4.643 17.492 17.492 0 00-.916 3.331.5.5 0 00.272.532 6.027 6.027 0 001.981.631 6.622 6.622 0 002.41-1.111c.464.2 1.233.648 1.233 1.2v4.37a1 1 0 102 0V16.62a.5.5 0 01.5-.5h.252a.5.5 0 01.5.5v2.76a1 1 0 002 0v-2.76a.5.5 0 01.5-.5h5.5a.5.5 0 01.5.5v2.76a1 1 0 002 0v-2.76a.5.5 0 01.5-.5h.748a.5.5 0 01.5.5v2.76a1 1 0 102 0zM5.126 11.37a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});