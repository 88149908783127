define("ember-svg-jar/inlined/layers-stacked-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layers-stacked-1</title><path d=\"M1.155 7.4l9.649 4.2a3.008 3.008 0 002.392 0l9.649-4.2a1.5 1.5 0 000-2.751L13.2.452a3.009 3.009 0 00-2.392 0L1.157 4.646a1.5 1.5 0 000 2.752z\"/><path d=\"M22.844 10.648l-1.244-.542a1 1 0 00-.8 1.834l.191.083-8.591 3.736a.989.989 0 01-.8 0l-8.592-3.736.192-.083a1 1 0 10-.8-1.834l-1.244.541a1.5 1.5 0 000 2.752l9.644 4.194a3.008 3.008 0 002.392 0l9.649-4.193a1.5 1.5 0 000-2.752z\"/><path d=\"M22.844 16.6l-1.244-.539a1 1 0 00-.8 1.834l.191.083-8.591 3.736a1 1 0 01-.8 0l-8.592-3.736.192-.078a1 1 0 00-.8-1.834l-1.244.54a1.5 1.5 0 000 2.752l9.644 4.19a3.008 3.008 0 002.392 0l9.645-4.193a1.5 1.5 0 000-2.753z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});