define("ember-svg-jar/inlined/phone-action-wifi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-wifi</title><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/><circle cx=\"12\" cy=\"14.199\" r=\"1\"/><path d=\"M16.471 8.832a7.068 7.068 0 00-8.942 0A.75.75 0 108.471 10a5.58 5.58 0 017.058 0 .761.761 0 001.055-.112.751.751 0 00-.113-1.056z\"/><path d=\"M14.8 11.242a4.432 4.432 0 00-5.608 0 .75.75 0 10.942 1.167 2.941 2.941 0 013.724 0 .765.765 0 001.055-.112.751.751 0 00-.113-1.055z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});