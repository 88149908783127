define("ember-svg-jar/inlined/pin-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin-1</title><path d=\"M12 0a7.258 7.258 0 00-7.25 7.25c0 2.359 1.749 5.085 3.44 7.72a27.253 27.253 0 012.529 4.49.514.514 0 01.031.174v3.116a1.25 1.25 0 002.5 0v-3.116a.514.514 0 01.031-.174 27.253 27.253 0 012.529-4.49c1.691-2.635 3.44-5.361 3.44-7.72A7.258 7.258 0 0012 0zm0 10.749a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});