define("ember-svg-jar/inlined/asian-interior-bonsai-tree-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>asian-interior-bonsai-tree-1</title><path d=\"M21.21 20H.79a.509.509 0 00-.5.588A4 4 0 004.25 24h13.5a4 4 0 003.957-3.412.509.509 0 00-.497-.588zM22.288 11.5a1.5 1.5 0 00-.9.27c-.068.047-.231.014-.257-.055a2 2 0 10-1.921 2.709s.168.011.021.127a2.157 2.157 0 01-1.266.449l-2.444-.049a.25.25 0 01-.226-.334c.994-3.04-1.539-5.1-2.653-6.187a.25.25 0 01.174-.43h6.934a1.474 1.474 0 001.5-1.368A3.5 3.5 0 0017.75 3a3.436 3.436 0 00-1.893.573.243.243 0 01-.372-.129 4.977 4.977 0 00-9.47 0 .243.243 0 01-.372.129A3.436 3.436 0 003.75 3a3.5 3.5 0 00-3.5 3.632A1.474 1.474 0 001.75 8h6.663a.235.235 0 01.186.087l2.2 2.708a1.986 1.986 0 01-.5 2.95 6.082 6.082 0 00-2.855 4.49.256.256 0 00.257.265h4.674a.238.238 0 00.191-.089c.229-.291.982-1.045 1.3-1.408a.238.238 0 01.189-.081l3.869.078h.081a4.013 4.013 0 003.149-1.537s.449-.622.593-.882a.207.207 0 01.186-.092l.279.011a1.5 1.5 0 00.076-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});