define("ember-svg-jar/inlined/police-footsteps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-footsteps</title><path d=\"M8.621 13.978a.577.577 0 00-.664-.387L3.4 14.2a.491.491 0 00-.476.539l.376 1.85a2.88 2.88 0 003.319 1.931A2.45 2.45 0 009 15.828zM8.831 3.5C8.468 1.709 6.388.5 4.186.794S.493 2.778.857 4.566l1.5 7.4a.575.575 0 00.663.386l4.58-.612a.5.5 0 00.481-.513 4.547 4.547 0 01.555-2.952 4.639 4.639 0 00.514-3.208zM19.368 19.126l-4.462-.969a.6.6 0 00-.707.332L13.6 20.3c-.412 1.25.5 2.535 2.043 2.87a3 3 0 003.534-1.659l.6-1.811a.467.467 0 00-.409-.574zM20.2 5.72c-2.156-.468-4.372.571-4.948 2.322l-.507 1.537a4.581 4.581 0 00.13 3.233 4.493 4.493 0 01.2 2.982.472.472 0 00.415.548l4.478.973a.6.6 0 00.707-.332l2.385-7.245c.577-1.75-.704-3.549-2.86-4.018z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});