define("ember-svg-jar/inlined/notes-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-share</title><path d=\"M12 4.75V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v8.944c0 .153.1.238.222.166l1.242-.745a.238.238 0 00.111-.161 4.211 4.211 0 01.4-1.134.224.224 0 00.026-.105V4.5a2 2 0 00-2-2H14.25a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-1.5A.25.25 0 016 2.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25H2a2 2 0 00-2 2v13a2 2 0 002 2h7.4a.248.248 0 00.246-.286 11.266 11.266 0 01-.09-1.458.25.25 0 00-.25-.256H2.25a.25.25 0 01-.25-.25V4.75a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25z\"/><path d=\"M22.129 18.639a2.74 2.74 0 00-2.767.623.24.24 0 01-.258.053L16.553 18.3a.114.114 0 01-.06-.084l-.03-.353a.118.118 0 01.056-.1L19.442 16a.245.245 0 01.266.012 2.741 2.741 0 004.168-1.425A2.759 2.759 0 0021.25 11a2.808 2.808 0 00-2.713 3.059.243.243 0 01-.115.221L15.558 16a.245.245 0 01-.266-.012 2.741 2.741 0 00-4.168 1.426A2.759 2.759 0 0013.75 21a2.729 2.729 0 001.887-.761.245.245 0 01.26-.053l2.463 1a.239.239 0 01.144.214 2.753 2.753 0 105.361-1.025 2.681 2.681 0 00-1.736-1.736z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});