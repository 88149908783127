define("ember-svg-jar/inlined/heavy-equipment-tractor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>heavy-equipment-tractor</title><path d=\"M10.875 18.625a6.747 6.747 0 0111.871-4.393.5.5 0 00.879-.326v-1.784c0-.29-.026-.764-.058-1.053l-.89-8a1.07 1.07 0 00-1.052-.942h-7.5a1.193 1.193 0 00-1.116.848l-2.263 8.152H3.125a1.166 1.166 0 00-1.1.865l-.909 3.673a.5.5 0 00.679.581 4.75 4.75 0 016.585 4.381.5.5 0 00.5.5h1.781a.5.5 0 00.48-.638 6.733 6.733 0 01-.266-1.864zm8.84-10.736a.5.5 0 01-.314.464l-5.59 2.236a.508.508 0 01-.186.036.5.5 0 01-.476-.652l1.757-5.5a.5.5 0 01.476-.348h3.833a.5.5 0 01.5.5z\"/><path d=\"M22.875 18.625a5.25 5.25 0 10-5.25 5.25 5.256 5.256 0 005.25-5.25zm-5.25 2.5a2.5 2.5 0 112.5-2.5 2.5 2.5 0 01-2.5 2.5zM3.625 17.375a3.25 3.25 0 103.25 3.25 3.254 3.254 0 00-3.25-3.25zm0 4.5a1.25 1.25 0 111.25-1.25 1.25 1.25 0 01-1.249 1.25zM5.625 2.125h.5v3.086a1.5 1.5 0 00-1 1.414v2.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-2.5a1.5 1.5 0 00-1-1.414V1.125a1 1 0 00-1-1h-1.5a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});