define("ember-svg-jar/inlined/love-it-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it-warning</title><path d=\"M3.331 10.392a4.422 4.422 0 01-.849-5.16 4.494 4.494 0 013.328-2.42A4.578 4.578 0 019.8 4.1l1.531 1.4a1 1 0 001.347 0l1.561-1.423a4.545 4.545 0 013.965-1.257 4.5 4.5 0 013.328 2.42 4.4 4.4 0 01-1.164 5.354l-.126.13a.251.251 0 00-.042.29l.733 1.4a.25.25 0 00.186.131.253.253 0 00.215-.073l.431-.445a6.392 6.392 0 001.555-7.699 6.588 6.588 0 00-10.453-1.71l-.859.783-.828-.756A6.525 6.525 0 005.489.839 6.472 6.472 0 00.7 4.328a6.4 6.4 0 001.2 7.454l7.72 8.372a.25.25 0 00.405-.054l.74-1.41a.252.252 0 00-.037-.286z\"/><path d=\"M18.782 11.522a1.449 1.449 0 00-2.561 0l-5.055 9.635a1.431 1.431 0 00.048 1.408 1.455 1.455 0 001.233.686h10.108a1.458 1.458 0 001.234-.686 1.434 1.434 0 00.047-1.408zM17.5 14a.749.749 0 01.749.75v3a.75.75 0 11-1.5 0v-3A.75.75 0 0117.5 14zm0 7.5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});