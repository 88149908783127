define("ember-svg-jar/inlined/rating-star-bubble-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rating-star-bubble-1</title><path d=\"M23.541 11.314l-1.813-1.92.61-2.569a1 1 0 00-.688-1.189l-2.53-.754-.754-2.532a1 1 0 00-1.19-.687l-2.569.609L12.688.459a1 1 0 00-1.374 0L9.393 2.272l-2.569-.611a1 1 0 00-1.189.688l-.754 2.53-2.531.754a1 1 0 00-.687 1.19l.61 2.57-1.812 1.92a1 1 0 000 1.373l1.813 1.92-.611 2.57a1 1 0 00.688 1.189l2.53.754.754 2.531a1 1 0 001.189.686l2.57-.609 1.92 1.813a1 1 0 001.374 0l1.92-1.814 2.569.611a1 1 0 001.189-.687l.754-2.531 2.531-.754a1 1 0 00.687-1.19l-.609-2.57 1.813-1.92a1 1 0 00-.001-1.371zm-7.379 5.1a.422.422 0 01-.594.536l-3.323-1.872a.5.5 0 00-.49 0l-3.322 1.876a.423.423 0 01-.595-.537l1.353-3.111a.5.5 0 00-.126-.573l-2.311-2.06a.422.422 0 01.28-.737h2.743a.5.5 0 00.459-.3l1.377-3.193a.422.422 0 01.775 0l1.377 3.193a.5.5 0 00.459.3h2.742a.422.422 0 01.281.737l-2.311 2.06a.5.5 0 00-.126.573z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});