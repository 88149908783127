define("ember-svg-jar/inlined/outdoors-camp-fire-marshmallows", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-camp-fire-marshmallows</title><path d=\"M5.1 10.08a.355.355 0 00-.441.511 5.873 5.873 0 01.241 4.878 1 1 0 01-1.438.5 2.346 2.346 0 01-.971-1.149.339.339 0 00-.483-.187 4.885 4.885 0 00-2 4.385 5.258 5.258 0 004.55 4.941 5.27 5.27 0 005.88-5.176A8.525 8.525 0 005.1 10.08zM13.488 3.868a.99.99 0 00-.293-.709l-2.121-2.121a1 1 0 00-.707-.293.993.993 0 00-.706.293l-.177.177a.249.249 0 01-.353 0L8.208.293a1 1 0 00-1.415 1.414l.922.922a.247.247 0 010 .353l-.176.177a1 1 0 000 1.414L9.66 6.7a.989.989 0 00.706.293.993.993 0 00.707-.293l2.12-2.121a.986.986 0 00.295-.711zM19.333 9.3l-2.122-2.124a.989.989 0 00-.706-.293.993.993 0 00-.707.293l-.177.176a.249.249 0 01-.353 0l-.957-.952a.75.75 0 00-1.061 0l-.35.35a.753.753 0 000 1.061l.956.955a.252.252 0 010 .354l-.177.177a1 1 0 000 1.414l2.121 2.121a.993.993 0 00.707.293.962.962 0 00.707-.293l2.122-2.121a1 1 0 00-.003-1.411zM23.707 15.793l-3.343-3.344a.751.751 0 00-1.06 0l-.354.354a.751.751 0 000 1.06l3.343 3.344a1 1 0 001.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});