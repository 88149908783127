define("ember-svg-jar/inlined/yoga-arms-stretch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>yoga-arms-stretch</title><circle cx=\"12.138\" cy=\"6.195\" r=\"2.5\"/><path d=\"M14.945 17.887a.251.251 0 00.18-.24v-5.506a.251.251 0 01.134-.222A6.745 6.745 0 0016.9 1.172a1.25 1.25 0 00-1.77 1.768 4.25 4.25 0 11-6.01 0 1.25 1.25 0 10-1.768-1.768 6.746 6.746 0 001.639 10.747.253.253 0 01.134.222v5.579a.25.25 0 00.179.24l2.625.772a.245.245 0 00.142 0zM17.273 18.245l-2.686.79a.25.25 0 000 .479l.922.271a2.227 2.227 0 011.29 1.008.249.249 0 00.285.113l.894-.263a1.25 1.25 0 00-.705-2.4zM15.227 20.745l-8.5-2.5a1.25 1.25 0 00-.7 2.4l8.5 2.5a1.279 1.279 0 00.354.051 1.25 1.25 0 00.351-2.449z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});