define("ember-svg-jar/inlined/camera-settings-frame", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camera-settings-frame</title><path d=\"M19 10.134a2 2 0 00-2-2h-1a.92.92 0 01-.5-.207l-1.293-1.293a1.872 1.872 0 00-1.207-.5h-2a1.872 1.872 0 00-1.207.5L8.5 7.927a.92.92 0 01-.5.207H7a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2zm-9.5 2.5a2.5 2.5 0 112.5 2.5 2.5 2.5 0 01-2.5-2.5zM3.155 10.463l-.925-.771a.251.251 0 01-.09-.192.248.248 0 01.09-.192l.925-.771a2 2 0 000-3.074L2.116 4.6A1 1 0 10.837 6.134l.809.674a.251.251 0 010 .384l-.926.772a2 2 0 000 3.072l.926.772a.251.251 0 010 .384l-.926.772a2 2 0 000 3.072l.926.772a.252.252 0 010 .384l-.809.674A1 1 0 102.116 19.4l1.039-.865a2 2 0 000-3.074l-.925-.771a.251.251 0 01-.09-.192.248.248 0 01.09-.192l.925-.771a2 2 0 000-3.074zM24 14.5a2 2 0 00-.72-1.537l-.926-.771a.251.251 0 010-.384l.926-.772a2 2 0 000-3.073l-.926-.771A.25.25 0 0122.265 7a.247.247 0 01.089-.192l.809-.674A1 1 0 1021.884 4.6l-1.039.865a2 2 0 000 3.073l.925.772a.25.25 0 010 .384l-.925.771a2 2 0 000 3.073l.925.772a.25.25 0 010 .384l-.925.771a2 2 0 000 3.073l1.039.866a1 1 0 101.279-1.536l-.809-.674a.251.251 0 010-.384l.926-.772A2 2 0 0024 14.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});