define("ember-svg-jar/inlined/multiple-actions-laptop.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-laptop</title><path d=\"M13.5 11h5.245a.5.5 0 00.385-.819 6.007 6.007 0 00-7.107-1.647.5.5 0 00-.182.77 7.553 7.553 0 01.91 1.428.5.5 0 00.492.279c.085-.011.171-.011.257-.011z\"/><ellipse cx=\"14.013\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M11.323 11.957a.5.5 0 00.086-.562A6.024 6.024 0 000 13.333.6.6 0 00.6 14h9.436a.5.5 0 00.494-.427 2.976 2.976 0 01.793-1.616z\"/><ellipse cx=\"5.513\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M23.9 22.634a.321.321 0 00-.019-.031l-1.785-2.868a.5.5 0 00-.096-.086V14a1.5 1.5 0 00-1.5-1.5h-7A1.5 1.5 0 0012 14v5.649a.524.524 0 00-.09.086l-1.8 2.9a.924.924 0 00.044.93.957.957 0 00.809.436h12.079a.957.957 0 00.809-.436.922.922 0 00.049-.931zm-8.379-.254a.243.243 0 010-.238l.272-.476a.232.232 0 01.2-.119h1.854a.232.232 0 01.2.119l.272.476a.243.243 0 010 .238.231.231 0 01-.2.12h-2.4a.232.232 0 01-.203-.12zM14 14.75a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25v4a.25.25 0 01-.25.25h-5.5a.248.248 0 01-.176-.073.252.252 0 01-.074-.177z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});