define("ember-svg-jar/inlined/table-lamp-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>table-lamp-1</title><path d=\"M15 22h-1.5a.5.5 0 01-.5-.5V16a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v5.5a.5.5 0 01-.5.5H9a1 1 0 000 2h6a1 1 0 000-2z\"/><path d=\"M13.438 2.6A.5.5 0 0113 2.1V1a1 1 0 00-2 0v1.107a.5.5 0 01-.438.5A11.5 11.5 0 00.552 12.9a1.008 1.008 0 001 1.1H14.5a.5.5 0 01.5.5v2a1 1 0 002 0v-2a.5.5 0 01.5-.5h4.947a1.008 1.008 0 001-1.1A11.5 11.5 0 0013.438 2.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});