define("ember-svg-jar/inlined/pasta-bowl-warm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pasta-bowl-warm</title><path d=\"M8.277 9.5a1 1 0 01-.708-1.707 1.655 1.655 0 000-2.336 3.65 3.65 0 010-5.164 1 1 0 111.415 1.414 1.651 1.651 0 000 2.336 3.657 3.657 0 010 5.164 1 1 0 01-.707.293zM16.848 9.5a1 1 0 01-.707-1.707 1.651 1.651 0 000-2.336 3.65 3.65 0 010-5.164 1 1 0 011.414 1.414 1.651 1.651 0 000 2.336 3.65 3.65 0 010 5.164 1 1 0 01-.707.293zM12.562 9.5a1 1 0 01-.707-1.707 1.651 1.651 0 000-2.336 3.653 3.653 0 010-5.164 1 1 0 011.415 1.414 1.651 1.651 0 000 2.336 3.653 3.653 0 010 5.164 1 1 0 01-.708.293zM12 10.5c-2.815 0-12 .234-12 3.25C0 19.411 5.373 24 12 24s12-4.589 12-10.25c0-3.016-9.185-3.25-12-3.25zm0 2a32.367 32.367 0 019.037 1.012.25.25 0 010 .476A32.362 32.362 0 0112 15a32.367 32.367 0 01-9.037-1.012.25.25 0 010-.476A32.362 32.362 0 0112 12.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});