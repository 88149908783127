define("ember-svg-jar/inlined/chicken-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chicken-body</title><path d=\"M20.359 6.016a3.517 3.517 0 00-2.375-2.375A4.006 4.006 0 0014 0a1 1 0 000 2 2 2 0 011.97 1.655A3.506 3.506 0 0013.5 7v2a2 2 0 01-2 2H8.761a.25.25 0 00-.249.23 3.247 3.247 0 003.238 3.52.75.75 0 010 1.5 4.749 4.749 0 01-4.682-5.556A4 4 0 003.5 8.5H1a1 1 0 00-.981 1.2l.429 2.14a3.005 3.005 0 001.692 2.134l.308 1.362a3.007 3.007 0 002.942 2.412h2.252A7.5 7.5 0 0012 19.933V23a1 1 0 002 0v-3.067a7.51 7.51 0 006.5-7.433v-1a.5.5 0 01.5-.5h2a1 1 0 001-1 4.005 4.005 0 00-3.641-3.984zM4.89 15.748a1.009 1.009 0 01-.99-.828l-.152-.672h.5a1 1 0 000-2H3.39a1 1 0 01-.981-.8L2.22 10.5H3.5a2 2 0 012 2v.073a7.553 7.553 0 00.738 3.175zm12.36-8a1 1 0 111-1 1 1 0 01-1 1.002z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});