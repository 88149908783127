define("ember-svg-jar/inlined/chef-gear-biscuits", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chef-gear-biscuits</title><path d=\"M5.5 1.5A5.506 5.506 0 000 7v10a5.5 5.5 0 0011 0V7a5.506 5.506 0 00-5.5-5.5zM4.205 11.763a5.532 5.532 0 010 3.474 4.026 4.026 0 000 2.526.749.749 0 01-.474.948.731.731 0 01-.237.039.75.75 0 01-.712-.513 5.532 5.532 0 010-3.474 4.026 4.026 0 000-2.526 5.532 5.532 0 010-3.474 4.026 4.026 0 000-2.526.75.75 0 111.423-.474 5.532 5.532 0 010 3.474 4.026 4.026 0 000 2.526zm4.013 0a5.532 5.532 0 010 3.474 4.026 4.026 0 000 2.526.75.75 0 11-1.423.474 5.532 5.532 0 010-3.474 4.026 4.026 0 000-2.526 5.532 5.532 0 010-3.474 4.026 4.026 0 000-2.526.75.75 0 011.423-.474 5.532 5.532 0 010 3.474 4.026 4.026 0 000 2.526zM18.5 1.5A5.506 5.506 0 0013 7v10a5.5 5.5 0 0011 0V7a5.506 5.506 0 00-5.5-5.5zm-1.3 10.263a5.532 5.532 0 010 3.474 4.026 4.026 0 000 2.526.749.749 0 01-.474.948.731.731 0 01-.237.039.75.75 0 01-.712-.513 5.532 5.532 0 010-3.474 4.026 4.026 0 000-2.526 5.532 5.532 0 010-3.474 4.026 4.026 0 000-2.526.75.75 0 111.423-.474 5.532 5.532 0 010 3.474 4.026 4.026 0 000 2.526zm4.013 0a5.532 5.532 0 010 3.474 4.026 4.026 0 000 2.526.75.75 0 11-1.423.474 5.532 5.532 0 010-3.474 4.026 4.026 0 000-2.526 5.532 5.532 0 010-3.474 4.026 4.026 0 000-2.526.75.75 0 011.423-.474 5.532 5.532 0 010 3.474 4.026 4.026 0 00.005 2.526z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});