define("ember-svg-jar/inlined/expand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>expand</title><path d=\"M23.75 5.8a2.207 2.207 0 00-2.2-2.2H16.3a1.25 1.25 0 000 2.5H21a.25.25 0 01.25.25v3.262a1.25 1.25 0 102.5 0zM.25 9.612a1.25 1.25 0 002.5 0V6.34A.25.25 0 013 6.09h4.705a1.25 1.25 0 000-2.5h-5.25A2.207 2.207 0 00.25 5.8zM23.75 18.2v-3.814a1.25 1.25 0 10-2.5 0v3.272a.25.25 0 01-.25.25h-4.7a1.25 1.25 0 000 2.5h5.25a2.207 2.207 0 002.2-2.208zM.25 18.2a2.207 2.207 0 002.205 2.205h5.25a1.25 1.25 0 000-2.5H3a.25.25 0 01-.25-.25v-3.269a1.25 1.25 0 00-2.5 0z\"/><rect x=\"4.795\" y=\"8.612\" width=\"14.409\" height=\"6.773\" rx=\"1.955\" ry=\"1.955\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});