define("ember-svg-jar/inlined/multiple-actions-share-2.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-share-2</title><path d=\"M18.26 10.744a.25.25 0 00.034-.314c-.494-.766-1.642-1.23-3.823-2.03l-.485-.172a1.8 1.8 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 0012 0a2.986 2.986 0 00-1.341.314.25.25 0 00-.1.363 5 5 0 01.841 2.808 6.259 6.259 0 01-.922 3.624.254.254 0 00-.029.216.249.249 0 00.151.156c1.917.716 3.3 1.328 3.9 2.537a8.734 8.734 0 01.75 3.482 1.487 1.487 0 01-.025.269.2.2 0 00.043.161.2.2 0 00.151.07.24.24 0 00.128-.036l1.411-.846a.252.252 0 00.117-.167 4.232 4.232 0 011.185-2.207z\"/><path d=\"M14 13.5a7.522 7.522 0 00-.618-2.923c-.454-.907-1.9-1.44-3.911-2.179l-.485-.172a1.8 1.8 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 007 0a3.219 3.219 0 00-3.145 3.485 4.609 4.609 0 001.192 3.538 1.877 1.877 0 010 1.185L4.53 8.4c-2.007.737-3.457 1.27-3.911 2.177A7.531 7.531 0 000 13.5a.5.5 0 00.5.5h13a.5.5 0 00.5-.5zM24 21.25a2.742 2.742 0 00-4.651-1.977.218.218 0 01-.233.046l-2.465-.985a.25.25 0 01-.156-.218c0-.044-.008-.095-.017-.161a.25.25 0 01.119-.247L19.436 16a.251.251 0 01.271.009 2.709 2.709 0 001.543.487 2.75 2.75 0 10-2.75-2.75 2.214 2.214 0 00.021.295.25.25 0 01-.119.247L15.563 16a.251.251 0 01-.271-.009 2.708 2.708 0 00-1.542-.491 2.75 2.75 0 101.887 4.739.251.251 0 01.265-.051l2.445.978a.249.249 0 01.157.223A2.75 2.75 0 0024 21.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});