define("ember-svg-jar/inlined/whale-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>whale-body</title><path d=\"M23.376 1.25a3.5 3.5 0 00-3.483 3.156 3.4 3.4 0 00-1.017-.156 3.5 3.5 0 00-3.5 3.5.5.5 0 00.5.5h2.5a2 2 0 010 4h-11.5a15.879 15.879 0 00-6.128 1.323.923.923 0 00-.559.568 1.04 1.04 0 00.025.783 13.472 13.472 0 00.734 1.348c1.009 3.6 2.969 7.373 8.518 7.373a13.515 13.515 0 003.433-.44 8.267 8.267 0 01-.987-1.82c-2.421.53-5.27.406-7.037-1.546A9.09 9.09 0 013.1 16.48a10.192 10.192 0 015.243 2.049 20.62 20.62 0 003.289 1.8.75.75 0 011.472-.1 6.058 6.058 0 002.085 3.156 4 4 0 002 .61.447.447 0 00.439-.623l-1.142-2.663a.75.75 0 011.379-.592l.3.691c.007-.012 1.563-1.2 1.563-1.2a12.44 12.44 0 004.157-8.864v-9a.5.5 0 00-.509-.494zM9.876 16.5a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M3.126 4.5a1 1 0 001-1 1.5 1.5 0 013 0v6a1 1 0 002 0v-6a1.5 1.5 0 013 0 1 1 0 002 0 3.5 3.5 0 00-6-2.447 3.5 3.5 0 00-6 2.447 1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});