define("ember-svg-jar/inlined/multiple-actions-warning.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-warning</title><path d=\"M17.151 10.021a.25.25 0 00.092-.466A20.736 20.736 0 0014.471 8.4l-.485-.172a1.8 1.8 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 0012 0a2.991 2.991 0 00-1.342.314.25.25 0 00-.1.363 4.989 4.989 0 01.842 2.808 6.259 6.259 0 01-.922 3.624.249.249 0 00.122.372c1.917.716 3.3 1.328 3.9 2.537a5.908 5.908 0 01.358.916.249.249 0 00.188.172.253.253 0 00.241-.082 2.911 2.911 0 011.864-1.003z\"/><path d=\"M13.382 10.577c-.454-.907-1.9-1.44-3.911-2.179l-.485-.172a1.8 1.8 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 007 0a3.219 3.219 0 00-3.145 3.485 4.609 4.609 0 001.192 3.538 1.884 1.884 0 010 1.185L4.53 8.4c-2.007.737-3.457 1.27-3.911 2.177A7.522 7.522 0 000 13.5a.5.5 0 00.5.5h13a.2.2 0 00.173-.105l.289-.55a.251.251 0 00.028-.129 7.124 7.124 0 00-.608-2.639zM18.781 12.271a1.449 1.449 0 00-2.561 0l-5.055 9.634a1.426 1.426 0 00.047 1.408 1.454 1.454 0 001.233.687h10.111a1.456 1.456 0 001.233-.686 1.428 1.428 0 00.047-1.409zM17.5 22.25a1 1 0 111-1 1 1 0 01-1 1zm0-7a.75.75 0 01.75.75v3a.75.75 0 01-1.5 0v-3a.75.75 0 01.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});