define("ember-svg-jar/inlined/messages-people-user-question-exclamation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-user-question-exclamation</title><circle cx=\"13.982\" cy=\"10.5\" r=\"1\"/><path d=\"M12.5 6.232a.75.75 0 00.75-.75.733.733 0 11.732.732.75.75 0 00-.75.75v1.27a.75.75 0 101.5 0v-.65a2.232 2.232 0 10-2.982-2.1.75.75 0 00.75.748zM17.981 8.75a.75.75 0 00.75-.75V4a.75.75 0 00-1.5 0v4a.75.75 0 00.75.75z\"/><circle cx=\"17.981\" cy=\"10.494\" r=\"1\"/><path d=\"M22 0H9a2 2 0 00-2 2v11a2 2 0 002 2h.5v2.5a1 1 0 001.707.707L14.414 15H22a2 2 0 002-2V2a2 2 0 00-2-2zm0 12.75a.25.25 0 01-.25.25H14a1 1 0 00-.707.293L11.5 15.086V14a1 1 0 00-1-1H9.25a.25.25 0 01-.25-.25V2.25A.25.25 0 019.25 2h12.5a.25.25 0 01.25.25zM3.505 13a2.75 2.75 0 10-.01 0A3.5 3.5 0 000 16.5v2a.5.5 0 00.5.5h.821a.25.25 0 01.249.225L2 23.55a.5.5 0 00.5.45h2a.5.5 0 00.5-.45l.432-4.325A.25.25 0 015.679 19H6.5a.5.5 0 00.5-.5v-2A3.5 3.5 0 003.505 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});