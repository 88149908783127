define("ember-svg-jar/inlined/wild-bird-eagle-head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wild-bird-eagle-head</title><path d=\"M23.5 20a.5.5 0 00.391-.813c-1.233-1.541-1.795-2.42-1.88-4.185H23.5a.5.5 0 00.39-.813A7.028 7.028 0 0122 9.5C22 2.533 16.02 0 12 0S2 2.533 2 9.5a7.02 7.02 0 01-1.89 4.692.5.5 0 00.39.813h1.489C1.9 16.77 1.342 17.649.109 19.19A.5.5 0 00.5 20h2.479a7.788 7.788 0 01-.895 3.217.5.5 0 00.6.741l4.387-1.75a.5.5 0 01.54.112l1.533 1.528a.5.5 0 00.666.037l1.877-1.494a.5.5 0 01.622 0l1.876 1.494a.5.5 0 00.667-.037l1.534-1.528a.5.5 0 01.539-.112l4.387 1.75a.5.5 0 00.6-.741A7.788 7.788 0 0121.021 20zM6 9.752A1.25 1.25 0 117.25 11 1.25 1.25 0 016 9.752zm6 9.048a.5.5 0 01-.354-.146l-2.757-2.763a1 1 0 01-.266-.942C8.98 13.465 10.109 9.5 12 9.5c1.8 0 2.984 3.95 3.366 5.44a1 1 0 01-.262.956l-2.75 2.751A.5.5 0 0112 18.8zm1.908-11.092a.75.75 0 01.132-1.053l2.25-1.75a.75.75 0 11.92 1.184l-2.25 1.75a.773.773 0 01-1.052-.131zm-3.816 0a.762.762 0 01-1.052.131l-2.25-1.75a.75.75 0 11.92-1.184l2.25 1.75a.75.75 0 01.132 1.053zM15.5 9.752A1.25 1.25 0 1116.75 11a1.25 1.25 0 01-1.25-1.248z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});