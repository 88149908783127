define("ember-svg-jar/inlined/road-sign-obstruction-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-obstruction-horizontal</title><path d=\"M21.763 11.823L16.7 6.765a.049.049 0 00-.036-.015h-2.75a.25.25 0 00-.177.427l3.94 3.939a1.249 1.249 0 010 1.769l-3.938 3.938a.25.25 0 00.177.427h2.729a.188.188 0 00.161-.09.491.491 0 01.077-.1l4.882-4.882a.252.252 0 00-.002-.355z\"/><path d=\"M23.25 6.75h-3.836a.25.25 0 00-.177.427l3.94 3.939a1.249 1.249 0 010 1.769l-3.937 3.938a.25.25 0 00.176.427h3.834a.75.75 0 00.75-.75v-9a.75.75 0 00-.75-.75zM16.336 12a.251.251 0 00-.073-.177l-4.086-4.085a.25.25 0 00-.354 0l-4.086 4.085a.251.251 0 000 .354l4.086 4.086a.251.251 0 00.354 0l4.086-4.086a.251.251 0 00.073-.177zM.823 12.884a1.251 1.251 0 010-1.768l3.94-3.939a.25.25 0 00-.177-.427H.75A.75.75 0 000 7.5v9a.75.75 0 00.75.75h3.835a.25.25 0 00.177-.427z\"/><path d=\"M6.323 12.884a1.251 1.251 0 010-1.768l3.94-3.939a.25.25 0 00-.177-.427H7.415a.251.251 0 00-.177.073l-5 5a.251.251 0 000 .354l5 5a.249.249 0 00.177.073h2.671a.25.25 0 00.177-.427z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});