define("ember-svg-jar/inlined/smiley-smirk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-smirk</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm6.925 14.885a7.508 7.508 0 01-9.81 4.04 1 1 0 11.77-1.846 5.508 5.508 0 007.194-2.964 1 1 0 111.846.77zM7.5 7a2 2 0 11-2 2 2 2 0 012-2zm7 2a2 2 0 112 2 2 2 0 01-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});