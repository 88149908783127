define("ember-svg-jar/inlined/folder-file-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-file-lock</title><path d=\"M13 15H6a1 1 0 01-.8-.4l-.9-1.2a1 1 0 00-.8-.4H1a1 1 0 00-1 1v9a1 1 0 001 1h12a1 1 0 001-1v-7a1 1 0 00-1-1zM24 12.365a.248.248 0 00-.336-.233 8.859 8.859 0 01-1.456.337.246.246 0 00-.208.247V19.5a.5.5 0 01-.5.5h-5.75a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25H22a2 2 0 002-2zM16.105.389A.247.247 0 0015.9 0H8a2 2 0 00-2 2v11.166a.359.359 0 00.312.334H7.75a.25.25 0 00.25-.25V2.5a.5.5 0 01.5-.5h6.663a.245.245 0 00.234-.181 4.471 4.471 0 01.708-1.43zM19.778 0a3.194 3.194 0 00-3.195 3.2v.67a.251.251 0 01-.138.224 1.486 1.486 0 00-.834 1.327v4.107a1.5 1.5 0 001.5 1.5h5.333a1.5 1.5 0 001.5-1.5V5.416a1.5 1.5 0 00-.833-1.338.249.249 0 01-.138-.224V3.2A3.2 3.2 0 0019.778 0zm0 8.718A1.222 1.222 0 1121 7.5a1.222 1.222 0 01-1.222 1.218zM18.083 3.2a1.7 1.7 0 113.39 0v.221a.25.25 0 01-.25.25h-2.89a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});