define("ember-svg-jar/inlined/christmas-snowman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-snowman</title><path d=\"M24 13a1 1 0 00-1-1h-1.154a.25.25 0 01-.232-.343l.5-1.255a1 1 0 00-1.857-.742l-.974 2.44a.5.5 0 01-.241.262l-.92.46a.5.5 0 01-.616-.138 7.054 7.054 0 00-2.1-1.793.252.252 0 01-.064-.386 4.494 4.494 0 00.923-4.425.251.251 0 01.237-.329H18a1 1 0 000-2h-2a.5.5 0 01-.5-.5V1.5A1.5 1.5 0 0014 0h-4a1.5 1.5 0 00-1.5 1.5v1.75a.5.5 0 01-.5.5H6a1 1 0 000 2h1.493a.251.251 0 01.237.329 4.5 4.5 0 00.922 4.421.248.248 0 01.061.208.252.252 0 01-.124.178A7.035 7.035 0 006.5 12.682a.5.5 0 01-.617.139l-.921-.461a.494.494 0 01-.24-.261L3.741 9.66a1 1 0 00-1.857.742l.5 1.255a.25.25 0 01-.23.343H1a1 1 0 000 2h2.646a.5.5 0 01.223.053l1.111.555a.5.5 0 01.259.577 7 7 0 1013.522 0 .5.5 0 01.259-.577l1.11-.555a.5.5 0 01.224-.053H23a1 1 0 001-1zm-12-3a2.5 2.5 0 01-1.949-4.063.5.5 0 01.39-.187h3.118a.5.5 0 01.39.187A2.5 2.5 0 0112 10zm0 12a5 5 0 115-5 5.006 5.006 0 01-5 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});