define("ember-svg-jar/inlined/cloud-photo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-photo</title><path d=\"M2.588 16.857A1 1 0 004 15.952v-.006a.963.963 0 00-.565-.9 2.512 2.512 0 01-.8-.516A2.371 2.371 0 012 12.749a2.588 2.588 0 012.586-2.587 2.635 2.635 0 01.535.056 1 1 0 001.2-.965c.081-5.754 8.3-7.363 10.818-2.045a.97.97 0 00.941.571 3.69 3.69 0 012.981 1.282 3.86 3.86 0 01.928 2.776 3.436 3.436 0 01-1.549 2.778.991.991 0 00-.443.823 1 1 0 001.56.826A5.41 5.41 0 0024 11.8a5.766 5.766 0 00-5.215-6 .252.252 0 01-.191-.12 7.685 7.685 0 00-14.1 2.3.251.251 0 01-.227.2A4.642 4.642 0 00.62 10.422 4.479 4.479 0 000 12.869a4.287 4.287 0 001.235 3.09 4.433 4.433 0 001.353.898z\"/><path d=\"M18.5 14.554a1.522 1.522 0 00-1.5-1.5h-2.038a.25.25 0 01-.223-.137l-.017-.033a1.49 1.49 0 00-1.341-.83h-2.764a1.493 1.493 0 00-1.341.829l-.016.032a.25.25 0 01-.224.139H7a1.522 1.522 0 00-1.5 1.5v6a1.5 1.5 0 001.5 1.5h10a1.5 1.5 0 001.5-1.5v-6zm-4.5 3a2 2 0 11-2-2 2 2 0 012 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});