define("ember-svg-jar/inlined/composition-frame-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>composition-frame-man</title><path d=\"M12 14.083a4 4 0 10-4-4 4 4 0 004 4zm0-2a2 2 0 01-1.858-2.739.251.251 0 01.346-.13 5.973 5.973 0 002.726.655c.178 0 .353-.008.527-.023a.237.237 0 01.259.237 2 2 0 01-2 2zM12 15.083a6.59 6.59 0 00-4.876 2.165.5.5 0 00.37.835h9.012a.5.5 0 00.37-.835A6.59 6.59 0 0012 15.083z\"/><path d=\"M21 3H3a3 3 0 00-3 3v12a3 3 0 003 3h18a3 3 0 003-3V6a3 3 0 00-3-3zm1 15a1 1 0 01-1 1H3a1 1 0 01-1-1V6a1 1 0 011-1h18a1 1 0 011 1zM2 1.5h20A.75.75 0 0022 0H2a.75.75 0 000 1.5zM22 22.5H2A.75.75 0 002 24h20a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});