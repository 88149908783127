define("ember-svg-jar/inlined/desktop-monitor-back", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>desktop-monitor-back</title><path d=\"M22.3 18.5a1.5 1.5 0 001.474-1.777l-2.625-14A1.5 1.5 0 0019.67 1.5H1.705A1.5 1.5 0 00.231 3.277l2.625 14A1.5 1.5 0 004.33 18.5h2.259a.5.5 0 00.485-.381l1.053-4.3a.75.75 0 011.457.357l-1.865 7.7a.5.5 0 00.486.619h8.5a.75.75 0 000-1.5h-2.5a.5.5 0 01-.5-.5V19a.5.5 0 01.5-.5zm-.855-4.144a.75.75 0 11-1.471.293l-.2-1a.75.75 0 011.471-.293zm-2.5 0a.75.75 0 11-1.471.293l-.2-1a.75.75 0 011.471-.293z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});