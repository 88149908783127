define("ember-svg-jar/inlined/transportation-ticket-train-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transportation-ticket-train-alternate</title><path d=\"M22.687 9.52A1.5 1.5 0 0024 8.031V5a2 2 0 00-2-2H2a2 2 0 00-2 2v3.031A1.5 1.5 0 001.313 9.52a2.5 2.5 0 010 4.96A1.5 1.5 0 000 15.969V19a2 2 0 002 2h20a2 2 0 002-2v-3.031a1.506 1.506 0 00-1.313-1.489 2.5 2.5 0 010-4.96zm-.871 6.823a.25.25 0 01.184.241v2.166a.25.25 0 01-.25.25H2.25a.25.25 0 01-.25-.25v-2.167a.249.249 0 01.184-.241 4.5 4.5 0 000-8.684A.249.249 0 012 7.417V5.25A.25.25 0 012.25 5h19.5a.25.25 0 01.25.25v2.167a.249.249 0 01-.184.241 4.5 4.5 0 000 8.685z\"/><path d=\"M11 6.75h2a.75.75 0 000-1.5h-2a.75.75 0 000 1.5zM16.29 16.47a.25.25 0 01.013-.317A1.727 1.727 0 0016.75 15V9.5A1.752 1.752 0 0015 7.746H9A1.752 1.752 0 007.25 9.5V15a1.728 1.728 0 00.45 1.153.25.25 0 01.013.317l-.794 1.059a.75.75 0 001.2.9L9.3 16.846a.251.251 0 01.2-.1h5a.251.251 0 01.2.1l1.189 1.587a.751.751 0 00.6.3.739.739 0 00.449-.15.749.749 0 00.15-1.05zM10 14.75a.75.75 0 11.75-.75.75.75 0 01-.75.75zm4 0a.75.75 0 11.75-.75.75.75 0 01-.75.75zm1.25-3A.25.25 0 0115 12H9a.25.25 0 01-.25-.25V9.5A.253.253 0 019 9.246h6a.253.253 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});