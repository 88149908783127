define("ember-svg-jar/inlined/style-three-pin-airchair", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-airchair</title><path d=\"M12 0C6.21 0 1.5 4.26 1.5 9.5c0 5.75 7.3 12.29 9.54 14.15a1.492 1.492 0 001.92 0c2.24-1.87 9.54-8.4 9.54-14.15C22.5 4.26 17.79 0 12 0zm0 3.5a4.494 4.494 0 013.886 2.261.25.25 0 01-.14.363A2.5 2.5 0 0014 8.5v.652a.25.25 0 01-.088.191.254.254 0 01-.2.056 9.875 9.875 0 00-3.418 0A.251.251 0 0110 9.152V8.5a2.5 2.5 0 00-1.746-2.376.25.25 0 01-.14-.363A4.494 4.494 0 0112 3.5zm-5.319 7.974a.252.252 0 01-.164.153.25.25 0 01-.221-.04A1.978 1.978 0 015.5 10V9a2.006 2.006 0 012-2A1.5 1.5 0 019 8.5v1.017a.251.251 0 01-.173.238c-.32.1-.609.213-.847.315a2.454 2.454 0 00-1.299 1.404zM16.5 13a1.5 1.5 0 01-.856 1.352.249.249 0 00-.144.226V15a.75.75 0 01-1.5 0v-.25a.25.25 0 00-.25-.25h-3.5a.25.25 0 00-.25.25V15a.75.75 0 01-1.5 0v-.422a.249.249 0 00-.144-.226A1.5 1.5 0 017.5 13v-.65a1.5 1.5 0 01.89-1.37 8.941 8.941 0 013.61-.73 8.941 8.941 0 013.61.73 1.514 1.514 0 01.89 1.37zm2-3a1.978 1.978 0 01-.8 1.587.25.25 0 01-.221.04.252.252 0 01-.164-.153 2.454 2.454 0 00-1.3-1.4 10.92 10.92 0 00-.847-.315.251.251 0 01-.168-.242V8.5A1.5 1.5 0 0116.5 7a2.006 2.006 0 012 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});