define("ember-svg-jar/inlined/toys-car-push", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toys-car-push</title><path d=\"M22.75 8H19.5a.25.25 0 01-.227-.355 18.156 18.156 0 011.715-2.96.5.5 0 01.472-.205 2.335 2.335 0 00.292.019A2.25 2.25 0 1019.5 2.25a2.221 2.221 0 00.089.625.5.5 0 01-.078.437 20.14 20.14 0 00-2.435 4.375.5.5 0 01-.465.313H16a.5.5 0 01-.5-.5V5.751A2.753 2.753 0 0012.75 3h-2.29a4.991 4.991 0 00-4.649 3.142L5.2 7.686A.5.5 0 014.732 8H2.75A2.753 2.753 0 000 10.751v4A1.752 1.752 0 001.75 16.5a1.914 1.914 0 001.841-1.382A2.254 2.254 0 015.75 13.5h.114a.251.251 0 01.221.338l-.876 2.334a.5.5 0 01-.466.325 3.75 3.75 0 103.516 5.072.5.5 0 01.467-.323h7.048a.5.5 0 01.467.323 3.75 3.75 0 103.518-5.069.5.5 0 01-.467-.324l-.86-2.293a.25.25 0 01.286-.332 2.259 2.259 0 011.694 1.576A1.91 1.91 0 0022.25 16.5 1.752 1.752 0 0024 14.751v-5.5A1.252 1.252 0 0022.75 8zm-18 13.25a1 1 0 111-1 1 1 0 01-1 1zm15-2a1 1 0 11-1 1 1 1 0 011-1zM7.5 7.316l.171-.43a2.879 2.879 0 012.534-1.866.5.5 0 01.382.128.5.5 0 01.163.37V7.5a.5.5 0 01-.5.5H7.961a.5.5 0 01-.461-.684zm9.78 10.115a3.762 3.762 0 00-1.039 1.5.5.5 0 01-.468.323H8.726a.5.5 0 01-.467-.323 3.769 3.769 0 00-1.039-1.5.5.5 0 01-.138-.551l.65-1.733a.1.1 0 01.1-.064.1.1 0 01.09.072A1.784 1.784 0 009.62 16.5h4.76a1.785 1.785 0 001.7-1.351 2.387 2.387 0 01.112-.314.252.252 0 01.236-.148.25.25 0 01.227.163l.761 2.028a.5.5 0 01-.136.553z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});