define("ember-svg-jar/inlined/style-three-pin-tomb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-tomb</title><path d=\"M12.75 9.25v-1a.5.5 0 01.5-.5.75.75 0 000-1.5.5.5 0 01-.5-.5.75.75 0 00-1.5 0 .5.5 0 01-.5.5.75.75 0 000 1.5.5.5 0 01.5.5v1a.75.75 0 001.5 0z\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM8.5 7a3.5 3.5 0 017 0v4.412a.5.5 0 01-.636.481 10.632 10.632 0 00-5.728 0 .5.5 0 01-.636-.481zm7.215 7.033a.753.753 0 01-.964.443 8.345 8.345 0 00-5.5 0 .75.75 0 11-.521-1.407 9.86 9.86 0 016.544 0 .752.752 0 01.441.964z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});