define("ember-svg-jar/inlined/temperature-fahrenheit-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>temperature-fahrenheit-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><circle cx=\"17.5\" cy=\"9.5\" r=\"2\"/><path d=\"M14.5 6a1 1 0 00-1-1h-5a2 2 0 00-2 2v10.5a1 1 0 002 0v-5.25a.25.25 0 01.25-.25h3.75a1 1 0 000-2H8.75a.25.25 0 01-.25-.25v-2.5A.25.25 0 018.75 7h4.75a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});