define("ember-svg-jar/inlined/pstyle-two-in-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pstyle-two-in-cart</title><path d=\"M12 0a9.511 9.511 0 00-9.5 9.5c0 6.547 6.538 12.488 8.541 14.153a1.5 1.5 0 001.917 0c2-1.665 8.542-7.608 8.542-14.154A9.511 9.511 0 0012 0zM9.321 16.5a1 1 0 111-1 1 1 0 01-1 1zm4.5 0a1 1 0 111-1 1 1 0 01-1 1zm.979-3.356a.5.5 0 01-.479.356h-5.5a.5.5 0 01-.472-.334l-1.75-5a.5.5 0 01.472-.666h7.485a.5.5 0 00.479-.356l.747-2.488a.75.75 0 111.436.431z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});