define("ember-svg-jar/inlined/diagram-steady-down-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-steady-down-1</title><path d=\"M3.015 10.739A1.447 1.447 0 005.48 9.222c-.312-.507-.6-1.034-.862-1.566a1.447 1.447 0 10-2.6 1.27c.3.617.636 1.227.997 1.813zM3.169 3.441a18.552 18.552 0 01-.283-1.766A1.447 1.447 0 00.009 2c.076.681.191 1.368.328 2.038a1.448 1.448 0 001.415 1.149 1.47 1.47 0 00.3-.031 1.447 1.447 0 001.117-1.715zM23.511 18.312l-2.9-2.542a1.448 1.448 0 00-2.4 1.088v.54a.249.249 0 01-.089.192.252.252 0 01-.2.055 18.365 18.365 0 01-10.316-5.613 1.447 1.447 0 10-2.129 1.959A21.276 21.276 0 0018 20.608a.249.249 0 01.213.247v1.31a1.446 1.446 0 00.879 1.33 1.43 1.43 0 00.568.117 1.451 1.451 0 001-.4l2.895-2.77A1.375 1.375 0 0024 19.364a1.437 1.437 0 00-.489-1.052z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});