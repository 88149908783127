define("ember-svg-jar/inlined/check-circle-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>check-circle-alternate</title><path d=\"M23.409.4a1.25 1.25 0 00-1.758.19L8.969 16.332l-2.651-2.651a1.25 1.25 0 10-1.768 1.768l3.636 3.635a1.261 1.261 0 001.857-.1L23.6 2.159A1.25 1.25 0 0023.409.4z\"/><path d=\"M17.865 12.89a1 1 0 00-.932 1.064q.018.252.018.507a7.408 7.408 0 11-4.769-6.927 1 1 0 00.718-1.869 9.414 9.414 0 106.056 8.8 9.7 9.7 0 00-.022-.64 1 1 0 00-1.069-.935z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});