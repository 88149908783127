define("ember-svg-jar/inlined/car", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car</title><path d=\"M22.242 10.027a.252.252 0 01-.068-.209.249.249 0 01.123-.181l.8-.463a1.5 1.5 0 00-1.5-2.6l-1.065.615a.25.25 0 01-.371-.176l-.4-2.383a2.991 2.991 0 00-2.959-2.507H7.194a2.991 2.991 0 00-2.959 2.509l-.4 2.383a.251.251 0 01-.372.176L2.4 6.576a1.5 1.5 0 10-1.5 2.6l.8.463a.249.249 0 01.123.181.252.252 0 01-.068.209A4.451 4.451 0 00.5 13.121v2a2.493 2.493 0 001.591 2.316.251.251 0 01.159.233v1.951a2.25 2.25 0 004.5 0v-1.75a.25.25 0 01.25-.25h10a.25.25 0 01.25.25v1.75a2.25 2.25 0 004.5 0v-1.947a.251.251 0 01.159-.233 2.493 2.493 0 001.591-2.316v-2a4.451 4.451 0 00-1.258-3.098zM7.194 4.125h9.612a1 1 0 01.986.836l.479 2.873a.253.253 0 01-.056.2.25.25 0 01-.191.088H5.976a.25.25 0 01-.247-.291l.479-2.873a1 1 0 01.986-.833zm12.306 11a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM3 13.625a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});