define("ember-svg-jar/inlined/love-it-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it-alternate</title><path d=\"M23.305 5.075a6.583 6.583 0 00-10.447-1.708L12 4.148l-.828-.754a6.525 6.525 0 00-5.687-1.807A6.476 6.476 0 00.7 5.075a6.4 6.4 0 001.21 7.469l9.373 9.656a1 1 0 001.434 0l9.359-9.638a6.416 6.416 0 001.229-7.487zm-2.648 6.076l-8.3 8.545a.5.5 0 01-.717 0l-8.313-8.56A4.419 4.419 0 012.48 5.98a4.494 4.494 0 013.326-2.418A4.564 4.564 0 019.8 4.845l1.53 1.4a1 1 0 001.347 0l1.561-1.423a4.546 4.546 0 013.962-1.26 4.492 4.492 0 013.321 2.418 4.427 4.427 0 01-.864 5.171z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});