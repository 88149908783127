define("ember-svg-jar/inlined/phone-actions-bluetooth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-bluetooth</title><path d=\"M17.418 16.163a2.56 2.56 0 00-3.536 0l-.385.384a46.837 46.837 0 01-5.789-5.79l.385-.385a2.5 2.5 0 000-3.535L5.972 4.715a2.5 2.5 0 00-3.537 0L1.272 5.879a3.51 3.51 0 00-.442 4.4 46.945 46.945 0 0013.142 13.145 3.537 3.537 0 004.4-.442l1.164-1.163a2.5 2.5 0 000-3.535z\"/><path d=\"M20.422 7.442l2.94-2.287a1 1 0 00-.014-1.589L18.868.205a1 1 0 00-1.6.8v3.976l-2.875-2.244a1 1 0 10-1.231 1.576l4.006 3.126-4.034 3.137a1 1 0 101.228 1.578l2.906-2.26v3.991a1 1 0 001.6.8l4.48-3.36a1 1 0 00.015-1.588zm-1.154-4.437L21.1 4.38l-1.832 1.425zm0 8.88V9.079l1.834 1.43z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});