define("ember-svg-jar/inlined/phone-actions-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-remove</title><path d=\"M17.168 16.164a2.561 2.561 0 00-3.537 0l-.384.385a46.768 46.768 0 01-5.789-5.79l.386-.385a2.506 2.506 0 000-3.535L5.722 4.718a2.5 2.5 0 00-3.537 0L1.022 5.881a3.514 3.514 0 00-.442 4.4 46.955 46.955 0 0013.141 13.146 3.555 3.555 0 004.4-.442l1.164-1.165a2.5 2.5 0 000-3.534zM17.5.007a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-2.654 4.909a.75.75 0 011.061-1.06l1.414 1.414a.249.249 0 00.353 0l1.414-1.414a.75.75 0 011.061 1.06L18.735 6.33a.25.25 0 000 .354L20.149 8.1a.75.75 0 010 1.061.761.761 0 01-1.061 0l-1.414-1.416a.249.249 0 00-.353 0l-1.414 1.414a.759.759 0 01-1.061 0 .75.75 0 010-1.061l1.414-1.414a.25.25 0 000-.354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});