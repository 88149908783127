define("ember-svg-jar/inlined/gesture-two-finger-tap-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-two-finger-tap-clock</title><path d=\"M10.2 17.44a.249.249 0 01-.139-.293l1.247-4.657a.93.93 0 00-1.8-.454l-1.43 4.591a.25.25 0 01-.3.169l-.585-.143A.251.251 0 017 16.41v-4.558a1 1 0 00-2 0v7.063a.25.25 0 01-.455.143c-1.49-2.138-3.856-1.449-2.762.224L4.7 23.649a.5.5 0 00.416.222h5.435a.5.5 0 00.492-.41c.2-1.1.664-3.684.775-4.166.251-1.095-.663-1.429-1.618-1.855zM2.5 12.121a.75.75 0 00.75-.75A2.631 2.631 0 014.457 9.1 2.836 2.836 0 017.1 8.839a.75.75 0 00.6-1.377 4.353 4.353 0 00-4.065.384 4.143 4.143 0 00-1.88 3.525.75.75 0 00.745.75z\"/><path d=\"M10.5 8.121a.75.75 0 000 1.5 2.755 2.755 0 011.969 4.67.75.75 0 101.074 1.047A4.254 4.254 0 0010.5 8.121zM17.744.126a4.75 4.75 0 104.75 4.75 4.756 4.756 0 00-4.75-4.75zm0 8a3.25 3.25 0 113.25-3.25 3.254 3.254 0 01-3.25 3.25z\"/><path d=\"M19.511 4.126h-.767a.25.25 0 01-.25-.25v-.767a.75.75 0 00-1.5 0v1.767a.75.75 0 00.75.75h1.767a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});