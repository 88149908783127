define("ember-svg-jar/inlined/folder-subtract-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-subtract-alternate</title><path d=\"M2.5 2h4.573a.5.5 0 01.447.276L8.33 3.9A2 2 0 0010.118 5H19a.5.5 0 01.5.5v4.079a.242.242 0 00.179.233 7.951 7.951 0 011.46.573.244.244 0 00.361-.215V5a2 2 0 00-2-2h-9.073a.5.5 0 01-.447-.276l-.81-1.619A2 2 0 007.382 0H2a2 2 0 00-2 2v14.5a2 2 0 002 2h7.3a.245.245 0 00.25-.255c-.018-.4-.018-1.086 0-1.49a.245.245 0 00-.25-.255H2.5A.5.5 0 012 16V2.5a.5.5 0 01.5-.5z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.5 7.25h-5a.75.75 0 010-1.5h5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});