define("ember-svg-jar/inlined/famous-people-man-hitler.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-people-man-hitler</title><path d=\"M13.6 12.754a.5.5 0 00-.485-.379H10.89a.5.5 0 00-.485.379l-.25 1a.5.5 0 00.485.621h2.72a.5.5 0 00.485-.621z\"/><path d=\"M6.149 12.447a.25.25 0 01.144.187c.511 3.048 4.021 4.991 5.686 4.991s5.176-1.943 5.687-4.989a.252.252 0 01.145-.189 2.732 2.732 0 001.439-2.434 2.116 2.116 0 00-.465-1.443c.388-2.759-.041-4.9-1.275-6.354C16.334.829 14.474.125 11.979.125S7.626.828 6.45 2.216C5.216 3.673 4.787 5.81 5.174 8.57a2.113 2.113 0 00-.464 1.444 2.731 2.731 0 001.439 2.433zm11.042-1.366a1.746 1.746 0 00-1 1.3c-.378 2.249-3.176 3.739-4.209 3.739s-3.83-1.49-4.207-3.738a1.747 1.747 0 00-1-1.306 1.275 1.275 0 01-.559-1.1.618.618 0 01.105-.439.754.754 0 00.4-.794 10.254 10.254 0 01-.1-.776A9.03 9.03 0 009.6 5.624a8.9 8.9 0 007.765 2.131c-.031.321-.072.652-.13 1a.753.753 0 00.406.794.615.615 0 01.105.438 1.281 1.281 0 01-.555 1.094z\"/><path d=\"M11.289 9.65a.75.75 0 00-.75-.75H8.96a.75.75 0 000 1.5h1.579a.75.75 0 00.75-.75zM14.96 8.9h-1.58a.75.75 0 000 1.5h1.58a.75.75 0 000-1.5zM12.468 19.2a.521.521 0 00-.936 0l-1.5 4a.5.5 0 00.468.676h3a.5.5 0 00.468-.676zM7.762 17.449a.506.506 0 00-.448-.039l-1.323.528a.5.5 0 01-.044.161l-1.276 2.552 1.553.777a.5.5 0 01.192.724l-1.148 1.723H8a.5.5 0 00.5-.545l-.5-5.5a.5.5 0 00-.238-.381z\"/><path d=\"M5.27 22.069l-1.494-.747a.5.5 0 01-.223-.671l1.087-2.175-1.72.686A4.519 4.519 0 000 23.375a.5.5 0 00.5.5h3.566zM21.09 19.165l-1.731-.689 1.088 2.175a.5.5 0 01-.223.671l-1.494.747 1.2 1.806h3.57a.5.5 0 00.5-.5 4.518 4.518 0 00-2.91-4.21z\"/><path d=\"M17.584 22.152a.5.5 0 01.192-.724l1.553-.777-1.276-2.551a.5.5 0 01-.044-.161l-1.323-.528a.5.5 0 00-.684.42l-.5 5.5a.5.5 0 00.5.545h2.732z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});