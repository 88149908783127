define("ember-svg-jar/inlined/messages-bubble-square-warning-triangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-warning-triangle</title><path d=\"M14.891 11.574a2.95 2.95 0 015.218 0l.923 1.631a.251.251 0 00.468-.124V1.5A1.5 1.5 0 0020 0H1.5A1.5 1.5 0 000 1.5v12.053A1.458 1.458 0 001.5 15H3v3.5a.5.5 0 00.832.374L8.19 15h4.752a.253.253 0 00.222-.134z\"/><path d=\"M18.782 12.271a1.45 1.45 0 00-2.562 0l-5.055 9.634a1.433 1.433 0 00.048 1.409 1.457 1.457 0 001.232.686h10.111a1.459 1.459 0 001.234-.687 1.434 1.434 0 00.047-1.408zM17.5 15.25a.75.75 0 01.75.75v3a.75.75 0 01-1.5 0v-3a.75.75 0 01.75-.75zm0 7a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});