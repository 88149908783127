define("ember-svg-jar/inlined/messaging-line-app-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messaging-line-app-1</title><path d=\"M24 4.5A4.5 4.5 0 0019.5 0h-15A4.5 4.5 0 000 4.5v15A4.5 4.5 0 004.5 24h15a4.5 4.5 0 004.5-4.5zM12 18.56a10.83 10.83 0 01-3-.41.5.5 0 00-.35 0L5 19.89a.5.5 0 01-.66-.68l1.21-2.41a.5.5 0 00-.12-.6 6.66 6.66 0 01-2.41-5c0-4.06 4-7.34 9-7.34s9 3.29 9 7.34S17 18.56 12 18.56z\"/><path d=\"M9.5 8.75a.75.75 0 00-.75.75v4a.75.75 0 001.5 0v-4a.75.75 0 00-.75-.75zM14.5 8.75a.75.75 0 00-.75.75v1a.25.25 0 01-.45.15L12.1 9a.75.75 0 00-1.35.45v4a.75.75 0 001.5 0v-1a.25.25 0 01.45-.15l1.2 1.6a.75.75 0 00.6.3.77.77 0 00.24 0 .75.75 0 00.51-.71v-4a.75.75 0 00-.75-.74zM18.29 10.25a.75.75 0 000-1.5H16.5a.75.75 0 00-.75.75v4a.75.75 0 00.75.75h1.79a.75.75 0 000-1.5h-.79a.25.25 0 010-.5h.5a.75.75 0 000-1.5h-.5a.25.25 0 010-.5zM7.5 12.75h-1a.25.25 0 01-.25-.25v-3a.75.75 0 00-1.5 0v4a.75.75 0 00.75.75h2a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});