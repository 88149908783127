define("ember-svg-jar/inlined/road-sign-traffic-right-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-traffic-right-alternate</title><path d=\"M.293 12.707l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414zm2.3-.53a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354l-9.232 9.232a.25.25 0 01-.354 0z\"/><path d=\"M14.53 7.47l2 2a.75.75 0 01-.53 1.28h-.75A.25.25 0 0015 11v5a1 1 0 01-2 0v-5a.25.25 0 00-.25-.25H12a.75.75 0 01-.53-1.28l2-2a.749.749 0 011.06 0zM10.187 13.726a.25.25 0 00-.187.242V16a1 1 0 01-2 0v-2.032a.25.25 0 00-.187-.242A.75.75 0 018 12.25h2a.75.75 0 01.187 1.476z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});