define("ember-svg-jar/inlined/video-edit-camera-archive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-edit-camera-archive</title><path d=\"M10.5 12.5a2.963 2.963 0 01.029-.413.25.25 0 00-.323-.273 4 4 0 112.608-2.609.251.251 0 00.273.324A2.963 2.963 0 0113.5 9.5h4.25a.25.25 0 00.25-.25V4.182A2.158 2.158 0 0016 2h-2.89a.246.246 0 01-.169-.066L11.116.262A1 1 0 0010.441 0H7.559a1 1 0 00-.676.263L5.059 1.934A.246.246 0 014.89 2H2a2.158 2.158 0 00-2 2.182V13a2 2 0 002 2h8.25a.25.25 0 00.25-.25zM4 5.75A.75.75 0 113.25 5a.75.75 0 01.75.75z\"/><path d=\"M22.5 11h-9a1.5 1.5 0 00-1.5 1.5v10a1.5 1.5 0 001.5 1.5h9a1.5 1.5 0 001.5-1.5v-10a1.5 1.5 0 00-1.5-1.5zm-5.25 8.25a.75.75 0 011.5 0V20a.75.75 0 01-1.5 0zm.75-1.5a.75.75 0 01-.75-.75v-.75a.75.75 0 011.5 0V17a.75.75 0 01-.75.75zm0-3a.75.75 0 01-.75-.75v-.75a.75.75 0 011.5 0V14a.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});