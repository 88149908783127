define("ember-svg-jar/inlined/smiley-disapointed-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-disapointed-2</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm-.793 13.707l-.5.5a1 1 0 01-1.414-1.414l.5-.5a1 1 0 011.414 1.414zm-3.94-9.793A6.32 6.32 0 019.7 3.431a1 1 0 010 2 4.348 4.348 0 00-3.081 1.276 1 1 0 01-1.414-1.414 6.324 6.324 0 012.062-1.379zm.566 4.336a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm-.774 11.091a5.706 5.706 0 019.882 0 .439.439 0 01-.38.658 21.078 21.078 0 00-9.123 0 .44.44 0 01-.379-.659zm7.648-6.548a1 1 0 11-1.414 1.414l-.5-.5a1 1 0 011.414-1.414zm-.04-3.043a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5zM18.088 7a1 1 0 01-.707-.293A4.337 4.337 0 0014.3 5.431a1 1 0 010-2 6.345 6.345 0 014.5 1.862A1 1 0 0118.088 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});