define("ember-svg-jar/inlined/dating-smartphone-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dating-smartphone-woman</title><path d=\"M23.627 1.949a3.526 3.526 0 00-5.646-.916.681.681 0 01-.963 0 3.525 3.525 0 00-4.993 4.978l5.113 5.335a.5.5 0 00.722 0l5.107-5.327a3.524 3.524 0 00.66-4.07zM4.088 18.168a.5.5 0 00.373.832h6.077a.5.5 0 00.374-.832 4.564 4.564 0 00-6.824 0z\"/><path d=\"M14 11a1 1 0 00-1 1v7.5a.5.5 0 01-.5.5h-10a.5.5 0 01-.5-.5V5a2 2 0 012-2h4.5a1 1 0 000-2H3a3 3 0 00-3 3v17a3 3 0 003 3h9a3 3 0 003-3v-9a1 1 0 00-1-1z\"/><path d=\"M11.92 15.992a1 1 0 00.072-1.412c-.116-.128-.492-.674-.492-2.33a4 4 0 00-8 0c0 1.656-.377 2.2-.493 2.33a1 1 0 001.485 1.34 2.8 2.8 0 00.285-.377.253.253 0 01.165-.112.249.249 0 01.194.044 3.991 3.991 0 004.727 0 .25.25 0 01.359.068 2.978 2.978 0 00.285.377 1 1 0 001.413.072zM7.5 14.25a2 2 0 01-1.955-1.576.251.251 0 01.193-.3 2.967 2.967 0 00.709-.235 2.337 2.337 0 00.852-.683.25.25 0 01.4 0 2.351 2.351 0 00.853.683 2.953 2.953 0 00.709.235.25.25 0 01.192.3A2 2 0 017.5 14.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});