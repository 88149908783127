define("ember-svg-jar/inlined/landmarks-view-point", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmarks-view-point</title><path d=\"M13.391 15.5h-2.482a1 1 0 100 2h2.482a.25.25 0 01.177.427l-1.755 1.755a1 1 0 101.414 1.418l1.755-1.756a.25.25 0 01.427.178V22a1 1 0 002 0v-2.482a.25.25 0 01.427-.178l1.755 1.76a1 1 0 101.414-1.414l-1.755-1.759a.25.25 0 01.177-.427h2.482a1 1 0 000-2h-2.482a.25.25 0 01-.177-.427l1.755-1.755a1 1 0 10-1.414-1.418l-1.755 1.755a.25.25 0 01-.427-.176V11a1 1 0 10-2 0v2.483a.25.25 0 01-.427.176L13.227 11.9a1 1 0 00-1.414 1.414l1.755 1.755a.25.25 0 01-.177.427zM9.091 2a1 1 0 00-1 1v2.634a.25.25 0 01-.42.183l-4.9-4.549A1 1 0 001.41 2.733l4.668 4.334a.25.25 0 01-.17.433H3.091a1 1 0 000 2h6a1 1 0 00.391-.08c.012-.005.021-.016.032-.021a1.015 1.015 0 00.3-.208c0-.005.009-.006.013-.01a.819.819 0 00.071-.116 1 1 0 00.124-.2.979.979 0 00.043-.214.936.936 0 00.03-.149V3a1 1 0 00-1.004-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});