define("ember-svg-jar/inlined/presentation-speaker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>presentation-speaker</title><path d=\"M22 0H2a2 2 0 00-2 2v12a2 2 0 002 2h3.5a1 1 0 000-2h-3a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-3a1 1 0 000 2H22a2 2 0 002-2V2a2 2 0 00-2-2z\"/><circle cx=\"12.003\" cy=\"8.75\" r=\"3.25\"/><path d=\"M15.769 15.289a.5.5 0 00.083-.439A3.974 3.974 0 0012 12a4.024 4.024 0 00-3.836 2.863.5.5 0 00.477.641l6.723-.016a.5.5 0 00.405-.199zM18 17H6a1 1 0 000 2h.683l.837 4.2a1 1 0 00.98.8h7a1 1 0 00.98-.8l.843-4.2H18a1 1 0 000-2zm-7.247 3.75A1.25 1.25 0 1112 22a1.251 1.251 0 01-1.247-1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});