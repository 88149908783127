define("ember-svg-jar/inlined/iris-scan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>iris-scan</title><path d=\"M8 12a4 4 0 104-4 4 4 0 00-4 4zm5 0a1 1 0 11-1-1 1 1 0 011 1zM20.886 13.357a.253.253 0 00-.079.179.25.25 0 00.073.181l1.059 1.059a.251.251 0 00.348.006c.367-.343.713-.689 1.03-1.034a2.591 2.591 0 000-3.5 19.538 19.538 0 00-1.027-1.031.25.25 0 00-.347.006l-1.063 1.063a.251.251 0 00.007.361c.347.32.67.642.961.958a.585.585 0 010 .787c-.297.322-.62.645-.962.965z\"/><path d=\"M5.457 9.793a1 1 0 001.414-1.414.307.307 0 01.072-.488 9.871 9.871 0 0110.113 0 .307.307 0 01.072.488 1 1 0 101.414 1.414l4.75-4.75a1 1 0 00-1.414-1.414L19.157 6.35a.5.5 0 01-.6.079 12.086 12.086 0 00-13.107 0 .5.5 0 01-.6-.079L2.121 3.629A1 1 0 00.707 5.043zM1.712 14.782a.248.248 0 00.347-.006l1.06-1.059a.251.251 0 00-.006-.36c-.342-.32-.665-.643-.961-.963a.586.586 0 010-.789c.291-.316.614-.637.961-.958a.249.249 0 00.08-.179.254.254 0 00-.073-.182L2.059 9.224a.249.249 0 00-.347-.006C1.345 9.56 1 9.9.684 10.249a2.6 2.6 0 000 3.5c.316.343.661.69 1.028 1.033zM18.542 14.207a1 1 0 00-1.414 1.415.33.33 0 01.094.283.334.334 0 01-.172.243 10.059 10.059 0 01-10.1 0 .328.328 0 01-.078-.522 1 1 0 10-1.414-1.415l-4.75 4.75a1 1 0 001.414 1.415l2.72-2.721a.5.5 0 01.6-.079 12.074 12.074 0 0013.108 0 .5.5 0 01.6.079l2.721 2.723a1 1 0 101.414-1.415z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});