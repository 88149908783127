define("ember-svg-jar/inlined/bathroom-mirror", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bathroom-mirror</title><path d=\"M22.25 9.75a.5.5 0 01-.5-.5V9a1 1 0 00-2 0v.25a.5.5 0 01-.5.5.015.015 0 01-.015-.015C19.04 4.323 15.875 0 12 0S4.96 4.323 4.768 9.735a.015.015 0 01-.015.015.5.5 0 01-.5-.5V9a1 1 0 00-2 0v.25a.5.5 0 01-.5.5 1 1 0 000 2 .5.5 0 01.5.5V23a1 1 0 001.943.333.5.5 0 01.469-.333h14.67a.5.5 0 01.472.333A1 1 0 0021.75 23V12.25a.5.5 0 01.5-.5 1 1 0 000-2zM12 2c2.846 0 5.25 3.778 5.25 8.25S14.846 18.5 12 18.5s-5.25-3.778-5.25-8.25S9.154 2 12 2zm7.75 18.5a.5.5 0 01-.5.5H4.75a.5.5 0 01-.5-.5v-8.25a.5.5 0 01.5-.5h.058a.032.032 0 01.032.028C5.364 16.706 8.371 20.5 12 20.5s6.636-3.794 7.16-8.722a.032.032 0 01.032-.028h.058a.5.5 0 01.5.5z\"/><path d=\"M9.5 8.827a1 1 0 00.959-.717 3.866 3.866 0 011.674-2.464A1 1 0 1011.3 3.83a5.719 5.719 0 00-2.759 3.714A1 1 0 009.5 8.827z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});