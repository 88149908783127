define("ember-svg-jar/inlined/shopping-cart-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-cart-upload</title><circle cx=\"14.531\" cy=\"21.792\" r=\"2\"/><circle cx=\"4.531\" cy=\"21.792\" r=\"2\"/><path d=\"M22.469 1.042h-3.062a1.756 1.756 0 00-1.735 1.518L15.9 15.825a.251.251 0 01-.248.217H1.531a1.25 1.25 0 100 2.5h15a1.758 1.758 0 001.736-1.519l1.768-13.265a.248.248 0 01.247-.216h2.187a1.25 1.25 0 000-2.5z\"/><path d=\"M3.419 7.379l2.435-2.454a.25.25 0 01.427.176v6.441a1.25 1.25 0 002.5 0V5.1a.25.25 0 01.428-.176l2.435 2.454a1.249 1.249 0 101.775-1.758l-5-5.041a1.248 1.248 0 00-1.772 0l-5 5.041a1.249 1.249 0 101.772 1.759z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});