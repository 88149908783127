define("ember-svg-jar/inlined/recycling-bag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>recycling-bag</title><path d=\"M22 8.5A1.5 1.5 0 0020.5 7h-2.75a.5.5 0 01-.5-.5V5.25a5.25 5.25 0 00-10.5 0V6.5a.5.5 0 01-.5.5H3.5A1.5 1.5 0 002 8.5v14A1.5 1.5 0 003.5 24h17a1.5 1.5 0 001.5-1.5zM8.75 5.25a3.25 3.25 0 016.5 0V6.5a.5.5 0 01-.5.5h-5.5a.5.5 0 01-.5-.5zm4.81 16.562a.252.252 0 01-.214-.046.249.249 0 01-.1-.2v-3.01a.5.5 0 01.5-.5h1.39a1.858 1.858 0 10-.329-3.687.248.248 0 01-.294-.206 2.548 2.548 0 00-5.034 0 .246.246 0 01-.1.166.253.253 0 01-.191.041 1.858 1.858 0 10-.329 3.686h1.39a.5.5 0 01.5.5v3.012a.25.25 0 01-.31.244 6.5 6.5 0 113.12 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});