define("ember-svg-jar/inlined/bread-herb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bread-herb</title><path d=\"M11.5 20.111V17.75a2.252 2.252 0 012.455-2.241.5.5 0 00.545-.5 2.307 2.307 0 011.228-2.264.6.6 0 00.321-.6.468.468 0 01.384-.518A5.631 5.631 0 0021 5.856 5.884 5.884 0 0015.1 0H5.9A5.884 5.884 0 000 5.856a5.631 5.631 0 004.54 5.765.5.5 0 01.408.552l-1.176 9.733a.972.972 0 00.246.769.991.991 0 00.734.325H9.1a.5.5 0 00.481-.364 2.234 2.234 0 01.574-.977l1.195-1.194a.5.5 0 00.15-.354z\"/><path d=\"M23.25 14.5h-1.086a.25.25 0 01-.177-.427l.293-.293a.75.75 0 00-1.06-1.06l-.293.293a.25.25 0 01-.427-.177V11.75a.75.75 0 00-1.5 0v2.775a1 1 0 01-.293.707l-.78.781a.25.25 0 01-.427-.177V14.75a.75.75 0 00-1.5 0v2.775a1 1 0 01-.293.707l-.78.781a.25.25 0 01-.427-.177V17.75a.75.75 0 00-1.5 0v2.775a1 1 0 01-.293.707L11.22 22.72a.75.75 0 101.06 1.06l1.488-1.487a1 1 0 01.707-.293h2.775a.75.75 0 000-1.5h-1.086a.25.25 0 01-.177-.427l.781-.78a1 1 0 01.707-.293h2.775a.75.75 0 000-1.5h-1.086a.25.25 0 01-.177-.427l.781-.78a1 1 0 01.707-.293h2.775a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});