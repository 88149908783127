define("ember-svg-jar/inlined/sailing-finish-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sailing-finish-line</title><path d=\"M10 15a2.527 2.527 0 014.028.03 2.665 2.665 0 001.805.97 2.254 2.254 0 001.377-.55.25.25 0 00.063-.314A6.009 6.009 0 0013.2 12.12a.25.25 0 01-.2-.245V8.8a.25.25 0 01.144-.227l6.779-3.163a1 1 0 000-1.812L12.438.1A1.017 1.017 0 0012 0a1 1 0 00-1 1v10.875a.25.25 0 01-.2.245 6.016 6.016 0 00-4.074 3.016.252.252 0 00.063.315A2.267 2.267 0 008.167 16 2.673 2.673 0 0010 15zM12.132 19.921a.249.249 0 00-.264 0A6.969 6.969 0 018.167 21a6.846 6.846 0 01-.921-.062.251.251 0 00-.246.116.249.249 0 000 .271 6 6 0 009.99 0 .25.25 0 00-.241-.387 6.846 6.846 0 01-.921.062 6.961 6.961 0 01-3.696-1.079z\"/><path d=\"M23 17.5a2.772 2.772 0 01-2.43-1.428 1 1 0 00-.807-.565 1.019 1.019 0 00-.9.4 4.179 4.179 0 01-3.03 1.593 4.176 4.176 0 01-3.022-1.586A1 1 0 0012 15.5a.979.979 0 00-.811.407A4.179 4.179 0 018.167 17.5a4.176 4.176 0 01-3.023-1.586 1 1 0 00-1.714.158A2.748 2.748 0 011 17.5a1 1 0 000 2 4.629 4.629 0 003.279-1.3.25.25 0 01.329-.015A5.692 5.692 0 008.167 19.5a5.74 5.74 0 003.673-1.41.249.249 0 01.321 0 5.734 5.734 0 003.672 1.41 5.692 5.692 0 003.559-1.317.25.25 0 01.329.015A4.629 4.629 0 0023 19.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});