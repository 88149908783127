define("ember-svg-jar/inlined/folder-media-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-media-alternate</title><path d=\"M5.365 3.4l1.192 1.238A2 2 0 008 5.25h13a1 1 0 000-2H8.208a.5.5 0 01-.359-.15L6.657 1.862a2 2 0 00-1.441-.612h-2.93A2.285 2.285 0 000 3.536V15.25a1 1 0 002 0V3.536a.286.286 0 01.286-.286h2.719a.5.5 0 01.36.15z\"/><path d=\"M22 6.75H6a2 2 0 00-2 2v12a2 2 0 002 2h16a2 2 0 002-2v-12a2 2 0 00-2-2zm0 13.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5h15a.5.5 0 01.5.5z\"/><path d=\"M16.72 14.036l-4.5-2.629a1.157 1.157 0 00-1.138-.014 1.123 1.123 0 00-.581.978v5.258a1.122 1.122 0 00.582.978 1.154 1.154 0 001.136-.013l4.5-2.629a1.114 1.114 0 000-1.929z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});