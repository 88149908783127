define("ember-svg-jar/inlined/instrument-trumpet-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>instrument-trumpet-1</title><path d=\"M23.706 8.778a1 1 0 000-1.414L16.635.292a1 1 0 00-1.414 0 1.518 1.518 0 00-.434 1.266A10.067 10.067 0 0113.559 7.8a.251.251 0 00.219.374 5.986 5.986 0 013.943 1.43.25.25 0 00.242.047 10.148 10.148 0 014.478-.441 1.512 1.512 0 001.265-.432zM4.706 14.738l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a1 1 0 001.414 1.414zM17.014 17.6a.177.177 0 010-.246 4.5 4.5 0 10-6.364-6.364h-.005l-8.417 8.636a.248.248 0 01-.355 0l-.166-.165a1 1 0 00-1.414 1.416l2.828 2.829a1 1 0 001.4.02l.02-.02.006-.006a1 1 0 00-.006-1.408l-.194-.192a.252.252 0 010-.352l.545-.559a.25.25 0 01.4.049 4.705 4.705 0 00.743.97 4.507 4.507 0 006.37-.006h.005l.011-.011a.248.248 0 01.357 0l.521.52a1 1 0 101.414-1.414l-.566-.566a.251.251 0 010-.349l1.2-1.263a.25.25 0 01.358 0l.6.6a1 1 0 001.414 0 1.011 1.011 0 000-1.414zm-8.9.287a.254.254 0 01.178-.076.251.251 0 01.178.073l1.83 1.826a.249.249 0 010 .349l-.028.03v.005a1.5 1.5 0 01-2.119 0 1.566 1.566 0 01-.092-2.148zm6.767-2.645a.156.156 0 01-.222 0l-1.89-1.89a.174.174 0 010-.244 1.5 1.5 0 112.113 2.131zm-1.66 1.393a.25.25 0 01.005.349l-1.2 1.264a.25.25 0 01-.358 0l-1.807-1.802a.251.251 0 010-.351l1.217-1.248a.249.249 0 01.356 0zM8.206 11.238l1.5-1.5a1 1 0 00-1.414-1.414l-1.5 1.5a1 1 0 001.414 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});