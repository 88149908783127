define("ember-svg-jar/inlined/read-music", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-music</title><path d=\"M20.75 0H4.25a2 2 0 00-2 2v19a3 3 0 003 3h13.5a2 2 0 002-2v-1.584a.5.5 0 01.333-.471A1 1 0 0021.75 19V1a1 1 0 00-1-1zM10.5 10.752c.071 0 .142 0 .211.009a.508.508 0 00.379-.132.5.5 0 00.16-.367V6a1.5 1.5 0 012.4-1.2l3.2 2.4a1 1 0 11-1.2 1.6l-2-1.5a.25.25 0 00-.4.2v6a2.75 2.75 0 11-2.75-2.75zM18.75 21.5a.5.5 0 01-.5.5h-13a1 1 0 010-2h13a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});