define("ember-svg-jar/inlined/single-woman-actions-laptop.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-laptop</title><path d=\"M11.479 11.8a.25.25 0 00-.065-.4.741.741 0 01-.452-.7v-.467a.25.25 0 01.25-.25A6.375 6.375 0 0015 9.081a.388.388 0 00.084-.593c-.494-.537-.836-1.185-.981-3.317C13.839 1.346 11.237 0 9 0S4.162 1.346 3.9 5.171c-.145 2.129-.486 2.78-.98 3.317a.388.388 0 00.08.593 6.348 6.348 0 003.782.9.252.252 0 01.179.072.248.248 0 01.074.178v.462a.778.778 0 01-.512.73c-2.9 1.065-5.212 1.825-5.764 2.922A7.822 7.822 0 000 17.076a.386.386 0 00.1.3.4.4 0 00.29.126h9.94a.25.25 0 00.25-.25v-3.335a2.972 2.972 0 01.899-2.117z\"/><path d=\"M23.893 22.634l-.017-.031-1.838-2.953a.247.247 0 01-.038-.132V14a1.5 1.5 0 00-1.5-1.5h-7A1.5 1.5 0 0012 14v5.518a.247.247 0 01-.038.132l-1.856 2.984a.927.927 0 00.043.931.96.96 0 00.809.435h12.084a.96.96 0 00.81-.437.922.922 0 00.041-.929zm-5.574-.254a.229.229 0 01-.2.12h-2.4a.229.229 0 01-.2-.12.241.241 0 010-.238l.273-.476a.23.23 0 01.2-.119h1.855a.231.231 0 01.2.119l.273.476a.241.241 0 01-.001.238zM20 18.75a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25v-4a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});