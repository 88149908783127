define("ember-svg-jar/inlined/road-sign-stop-arrow-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-stop-arrow-alternate</title><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/><path d=\"M13.8 10.146a.5.5 0 00-.35-.146h-2.9a.5.5 0 00-.353.146L8.146 12.2a.5.5 0 00-.146.35v2.9a.5.5 0 00.146.353l2.054 2.051a.5.5 0 00.35.146h2.9a.5.5 0 00.353-.146l2.051-2.054a.5.5 0 00.146-.35v-2.9a.5.5 0 00-.146-.353zM10.5 7.5h.25a.25.25 0 01.25.25v.5a1 1 0 002 0v-.5a.25.25 0 01.25-.25h.25a.75.75 0 00.53-1.28l-1.5-1.5a.749.749 0 00-1.06 0l-1.5 1.5a.75.75 0 00.53 1.28z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});