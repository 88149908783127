define("ember-svg-jar/inlined/cog-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cog-search</title><path d=\"M23.414 20.592s-5.05-5.049-5.076-5.072a10.01 10.01 0 10-2.824 2.824c.023.026 5.072 5.076 5.072 5.077a2.025 2.025 0 002.828 0 2 2 0 000-2.829zM10 18.006a8 8 0 118-8 8.009 8.009 0 01-8 8z\"/><path d=\"M15.082 11.248l-.572-.33a4.572 4.572 0 000-1.824l.574-.331a.837.837 0 00.306-1.143l-.63-1.087a.836.836 0 00-1.143-.306l-.573.33a4.63 4.63 0 00-1.58-.915v-.658a.837.837 0 00-.837-.837H9.372a.837.837 0 00-.837.837v.665a4.551 4.551 0 00-1.578.908l-.573-.331a.838.838 0 00-1.144.307L4.612 7.62a.836.836 0 00.307 1.143l.572.331a4.567 4.567 0 000 1.823l-.573.331a.839.839 0 00-.39.509.831.831 0 00.084.635l.627 1.087a.837.837 0 001.144.307l.572-.331a4.623 4.623 0 001.579.914v.659a.837.837 0 00.837.837h1.255a.837.837 0 00.837-.837v-.664a4.576 4.576 0 001.579-.91l.574.331a.835.835 0 001.143-.306l.628-1.087a.838.838 0 00-.305-1.144zM10 12.206a2.2 2.2 0 112.2-2.2 2.2 2.2 0 01-2.2 2.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});