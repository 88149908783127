define("ember-svg-jar/inlined/shipment-cargo-boat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-cargo-boat</title><path d=\"M.951 20.478a2.3 2.3 0 01.446.012c.586.079 1.489-.679 1.836-1.1a2.552 2.552 0 011.949-.935A2.506 2.506 0 017.3 19.621l.012.017a1.993 1.993 0 001.406.823 2.68 2.68 0 001.807-.973 2.5 2.5 0 014.283.379l.011.021a1.171 1.171 0 00.975.573 1.788 1.788 0 001.376-.761 2.5 2.5 0 014.285-.083l.016.023c.079.1.7.868 1.264.807a2.446 2.446 0 01.35-.022.251.251 0 00.221-.131A5.668 5.668 0 0024 17.578a.75.75 0 00-.75-.75H1.28a1.221 1.221 0 00-.922.413l-.007.007a1.218 1.218 0 00-.316.973 5.716 5.716 0 00.68 2.128.252.252 0 00.236.129zM19.75 15.328h3.5a.75.75 0 00.75-.75V1.749a.75.75 0 10-1.5 0v1.629a.25.25 0 01-.373.218A4.654 4.654 0 0020.25 3 1.232 1.232 0 0019 4.245V6.5a.25.25 0 00.25.25h1.768a.75.75 0 010 1.5H19.25a.25.25 0 00-.25.25v6.083a.75.75 0 00.75.745zM3.547 9.495a1 1 0 001-1 1 1 0 012 0 1 1 0 002 0A2.992 2.992 0 006.7 5.739a.25.25 0 01-.154-.23V3.854a.25.25 0 01.412-.191l6.078 5.155a.752.752 0 00.485.177h3.5a.75.75 0 00.426-1.367L6.878.322 6.856.305a1.482 1.482 0 00-2.317.946l.007 4.257a.251.251 0 01-.153.232 2.991 2.991 0 00-1.846 2.755 1 1 0 001 1z\"/><path d=\"M23.991 22.8a1 1 0 00-1.124-.858c-1.623.216-2.737-1.5-2.746-1.513a1.043 1.043 0 00-1.719.028 3.262 3.262 0 01-2.673 1.509 2.719 2.719 0 01-2.336-1.439 1.044 1.044 0 00-1.717-.155 4.2 4.2 0 01-3.023 1.594 3.462 3.462 0 01-2.685-1.537 1.042 1.042 0 00-1.636-.094c-.015.019-1.529 1.867-3.2 1.647a1 1 0 00-.264 1.982 4.113 4.113 0 00.549.036A5.6 5.6 0 004.9 22.573a.25.25 0 01.327.006 5.092 5.092 0 003.429 1.382 5.686 5.686 0 003.556-1.32.25.25 0 01.331.015 4.465 4.465 0 003.189 1.3 5.024 5.024 0 003.379-1.3.248.248 0 01.333 0 4.542 4.542 0 003.692 1.26 1 1 0 00.855-1.116z\"/><rect x=\"2.018\" y=\"10.495\" width=\"7\" height=\"5.333\" rx=\"1\" ry=\"1\"/><rect x=\"10.518\" y=\"10.495\" width=\"7\" height=\"5.333\" rx=\"1\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});