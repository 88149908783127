define("ember-svg-jar/inlined/multiple-actions-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-view</title><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M11.764 10.887a.25.25 0 00.17.133.252.252 0 00.21-.049A6.949 6.949 0 0116.5 9.438a6.84 6.84 0 011.248.116.25.25 0 00.239-.4 4.532 4.532 0 00-7.083.11.25.25 0 00.005.311 7.089 7.089 0 01.855 1.312zM0 17.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.685a.249.249 0 00.192-.09.252.252 0 00.054-.206 6.784 6.784 0 01-.121-1.266 6.953 6.953 0 011.2-3.909.25.25 0 00.031-.217A5.5 5.5 0 000 14z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M11 16.5a5.495 5.495 0 008.448 4.639.25.25 0 01.311.034l2.534 2.534a1 1 0 001.414-1.414l-2.534-2.534a.25.25 0 01-.034-.311A5.5 5.5 0 1011 16.5zm5.5-3.5a3.5 3.5 0 11-3.5 3.5 3.5 3.5 0 013.5-3.5z\"/><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M11.764 10.887a.25.25 0 00.17.133.252.252 0 00.21-.049A6.949 6.949 0 0116.5 9.438a6.84 6.84 0 011.248.116.25.25 0 00.239-.4 4.532 4.532 0 00-7.083.11.25.25 0 00.005.311 7.089 7.089 0 01.855 1.312zM0 17.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.685a.249.249 0 00.192-.09.252.252 0 00.054-.206 6.784 6.784 0 01-.121-1.266 6.953 6.953 0 011.2-3.909.25.25 0 00.031-.217A5.5 5.5 0 000 14z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M11 16.5a5.495 5.495 0 008.448 4.639.25.25 0 01.311.034l2.534 2.534a1 1 0 001.414-1.414l-2.534-2.534a.25.25 0 01-.034-.311A5.5 5.5 0 1011 16.5zm5.5-3.5a3.5 3.5 0 11-3.5 3.5 3.5 3.5 0 013.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});