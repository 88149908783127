define("ember-svg-jar/inlined/smiley-shocked-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-shocked-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M12 13a2.5 2.5 0 00-2.5 2.5v2a2.5 2.5 0 005 0v-2A2.5 2.5 0 0012 13zM10.452 9.805a1 1 0 00-.245-1.012l-3-3a1 1 0 00-1.414 1.414l1.626 1.626-1.083.181A1 1 0 006.5 11a.927.927 0 00.165-.014l3-.5a1 1 0 00.787-.681zM17.641 9.01l-1.52-.217 1.586-1.586a1 1 0 10-1.414-1.414l-3 3a1 1 0 00.565 1.7l3.5.5a1 1 0 10.283-1.98z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});