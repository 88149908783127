define("ember-svg-jar/inlined/multiple-actions-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-sync</title><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.028 11.485a.25.25 0 00.385.108 7.878 7.878 0 014.816-1.632 7.418 7.418 0 01.874.053.25.25 0 00.24-.114.252.252 0 000-.266 4.541 4.541 0 00-7.44-.374.25.25 0 000 .311 7.011 7.011 0 011.125 1.914zM.5 18h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.549a.254.254 0 00.183-.079.251.251 0 00.067-.186c-.006-.1-.01-.2-.01-.3a7.246 7.246 0 011.381-4.244.249.249 0 00.04-.191A5.5 5.5 0 000 14v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M10.771 17.625A6.375 6.375 0 0022.129 21.6a1 1 0 10-1.562-1.249 4.376 4.376 0 11-.5-5.981.25.25 0 01.009.362l-1.391 1.391a.5.5 0 00.354.854H23.5a.5.5 0 00.5-.5v-4.456a.5.5 0 00-.854-.353l-1.3 1.3a.251.251 0 01-.348.005 6.374 6.374 0 00-10.73 4.654z\"/><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.028 11.485a.25.25 0 00.385.108 7.878 7.878 0 014.816-1.632 7.418 7.418 0 01.874.053.25.25 0 00.24-.114.252.252 0 000-.266 4.541 4.541 0 00-7.44-.374.25.25 0 000 .311 7.011 7.011 0 011.125 1.914zM.5 18h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.549a.254.254 0 00.183-.079.251.251 0 00.067-.186c-.006-.1-.01-.2-.01-.3a7.246 7.246 0 011.381-4.244.249.249 0 00.04-.191A5.5 5.5 0 000 14v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M10.771 17.625A6.375 6.375 0 0022.129 21.6a1 1 0 10-1.562-1.249 4.376 4.376 0 11-.5-5.981.25.25 0 01.009.362l-1.391 1.391a.5.5 0 00.354.854H23.5a.5.5 0 00.5-.5v-4.456a.5.5 0 00-.854-.353l-1.3 1.3a.251.251 0 01-.348.005 6.374 6.374 0 00-10.73 4.654z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});