define("ember-svg-jar/inlined/modern-weapon-cannon-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-weapon-cannon-1</title><path d=\"M23.9 8.068l-2.014-4.022a.976.976 0 00-1.286-.452l-14.37 6.57a3.186 3.186 0 00-1.636 4.085l.547 1.366a.489.489 0 01-.29.643L.976 17.642a1.47 1.47 0 10.988 2.768l4.829-1.725a.489.489 0 00.3-.612 4.892 4.892 0 017.923-5.143.489.489 0 00.518.086h.01l.014-.006L23.43 9.4a.979.979 0 00.47-1.332z\"/><path d=\"M11.756 13.148a3.429 3.429 0 103.428 3.429 3.433 3.433 0 00-3.428-3.429zm0 4.409a.98.98 0 11.979-.98.98.98 0 01-.979.98z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});