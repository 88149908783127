define("ember-svg-jar/inlined/baby-trolley", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baby-trolley</title><path d=\"M19.078 9.53a.933.933 0 00-.744.246 1.015 1.015 0 00-.334.751V14a.5.5 0 01-.5.5h-4.47a.994.994 0 00-.742.333 1.028 1.028 0 00-.251.8A5.5 5.5 0 0017.5 20.5h.764a5.684 5.684 0 005.708-4.937 5.519 5.519 0 00-4.894-6.033z\"/><circle cx=\"14.5\" cy=\"22.5\" r=\"1.5\"/><circle cx=\"21.5\" cy=\"22.5\" r=\"1.5\"/><path d=\"M7.888 9.032a.5.5 0 00-.7.111L5.7 11.215a.249.249 0 01-.406 0L3.817 9.143a.5.5 0 00-.7-.111 4 4 0 00-1.527 2.346L.012 18.391A.5.5 0 00.5 19h1.6a.5.5 0 01.5.45l.4 4.1a.5.5 0 00.5.45h4a.5.5 0 00.5-.45l.41-4.1A.5.5 0 018.9 19h1.6a.5.5 0 00.488-.609L9.41 11.378a4 4 0 00-1.522-2.346z\"/><path d=\"M2.021 8.638a5.854 5.854 0 00.6-.879.25.25 0 01.367-.081 4.249 4.249 0 005.024 0 .252.252 0 01.367.081 5.781 5.781 0 00.6.879 1 1 0 001.541-1.276 3.141 3.141 0 01-.771-1.98V4.25a4.25 4.25 0 00-8.5 0v1.132a3.147 3.147 0 01-.77 1.98 1 1 0 101.542 1.276zM3.473 4.26a3.409 3.409 0 00.909-.28 2.471 2.471 0 00.918-.74.251.251 0 01.4 0 2.471 2.471 0 00.916.74 3.409 3.409 0 00.909.28.25.25 0 01.2.278 2.249 2.249 0 01-4.462 0 .251.251 0 01.21-.278z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});