define("ember-svg-jar/inlined/ticket-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ticket-remove</title><circle cx=\"15.5\" cy=\"3.625\" r=\"1\"/><circle cx=\"15.5\" cy=\"6.375\" r=\"1\"/><path d=\"M14.6 9.527a.249.249 0 00.313.123 8 8 0 011.485-.4.127.127 0 00.106-.125 1 1 0 00-2 0 .916.916 0 00.096.402z\"/><path d=\"M22 .375H2a2 2 0 00-2 2v2.781a1.5 1.5 0 001.313 1.489 2.5 2.5 0 010 4.96A1.5 1.5 0 000 13.094v2.781a2 2 0 002 2h7.267a.251.251 0 00.25-.266 7.046 7.046 0 01-.017-.484 7.553 7.553 0 01.067-.968.25.25 0 00-.248-.282H2.25a.25.25 0 01-.25-.25v-1.917a.249.249 0 01.184-.241 4.5 4.5 0 000-8.684A.249.249 0 012 4.542V2.625a.25.25 0 01.25-.25h19.5a.25.25 0 01.25.25v1.917a.249.249 0 01-.186.241 4.466 4.466 0 00-3.3 4.138.25.25 0 00.218.267 7.826 7.826 0 011.447.323.25.25 0 00.326-.254v-.132a2.5 2.5 0 012.187-2.48A1.5 1.5 0 0024 5.156V2.375a2 2 0 00-2-2z\"/><path d=\"M17.5 10.625a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.5 7.25h-5a.75.75 0 010-1.5h5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});