define("ember-svg-jar/inlined/water-straw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-straw</title><path d=\"M17.909 2h2.341a1 1 0 000-2h-2.341a4.98 4.98 0 00-4.863 3.842.2.2 0 01-.2.158H4.254a1.4 1.4 0 00-1.119.507 1.437 1.437 0 00-.358 1.237c1.527 9.161.887 5.316 2.62 15.75A2.99 2.99 0 008.353 24h6.8a2.991 2.991 0 002.957-2.506C19.844 11.06 19.2 14.9 20.728 5.762a1.4 1.4 0 00-.293-1.181A1.458 1.458 0 0019.254 4H15.47a.25.25 0 01-.223-.363A2.976 2.976 0 0117.909 2zm-6.642 9.605a.25.25 0 01-.295.189 3.671 3.671 0 01-2.2-1.41 1 1 0 00-.8-.4 1 1 0 00-.8.406 3.833 3.833 0 01-1.073.966.249.249 0 01-.376-.172l-.828-4.893A.25.25 0 015.142 6h7.1a.25.25 0 01.243.306zm5.081-1.219a1.038 1.038 0 00-1.607 0 3.73 3.73 0 01-.895.858.25.25 0 01-.384-.262l1.1-4.786A.25.25 0 0114.8 6h3.563a.25.25 0 01.247.291l-.81 4.889a.25.25 0 01-.377.172 3.837 3.837 0 01-1.075-.966z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});