define("ember-svg-jar/inlined/deforestation-axe-forbidden", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>deforestation-axe-forbidden</title><path d=\"M16.1 13.733a.5.5 0 00-.707 0l-.706.705a.5.5 0 000 .708l2.8 2.8a1 1 0 001.413-1.413z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM5.065 19a.5.5 0 01-.356-.158A9.995 9.995 0 0118.836 4.709a.5.5 0 01.012.718l-5.178 5.178a.5.5 0 01-.707 0L11.608 9.25a.5.5 0 01.016-.722c.157-.145.328-.291.511-.437a.97.97 0 00.081-1.443l-1.791-1.791a1 1 0 00-1.487.082c-1.686 2.1-3.247 2.39-3.838 2.417a1 1 0 00-.95.895 4.334 4.334 0 001.264 3.515A4.116 4.116 0 008.324 13a3.866 3.866 0 00.4-.021 1 1 0 00.9-.928 3.844 3.844 0 01.288-1.18.251.251 0 01.409-.083l1.23 1.231a.5.5 0 010 .707l-6.124 6.123a.5.5 0 01-.362.151zM12 22a9.942 9.942 0 01-4.774-1.215.5.5 0 01-.114-.793l12.88-12.88a.5.5 0 01.792.114A10 10 0 0112 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});