define("ember-svg-jar/inlined/hotel-single-bed-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hotel-single-bed-add</title><path d=\"M2.5 6h3.823a.24.24 0 00.237-.2A3.159 3.159 0 019.5 3h3a3.159 3.159 0 012.94 2.8.24.24 0 00.237.2H19.5a.5.5 0 00.5-.5V2a2 2 0 00-2-2H4a2 2 0 00-2 2v3.5a.5.5 0 00.5.5zM17.5 9.5a7.942 7.942 0 013.764.944A.5.5 0 0022 10V9a1.5 1.5 0 00-1.5-1.5h-19A1.5 1.5 0 000 9v5a1.5 1.5 0 001.125 1.453.5.5 0 01.375.483V17a1 1 0 002 0v-1a.5.5 0 01.5-.5h5.385a.5.5 0 00.478-.352A8.005 8.005 0 0117.5 9.5z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.5 7.5h-1a.5.5 0 00-.5.5v1a1 1 0 01-2 0v-1a.5.5 0 00-.5-.5h-1a1 1 0 010-2h1a.5.5 0 00.5-.5v-1a1 1 0 012 0v1a.5.5 0 00.5.5h1a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});