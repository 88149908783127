define("ember-svg-jar/inlined/bomb-grenade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bomb-grenade</title><path d=\"M20 0a4 4 0 00-3.808 2.824.25.25 0 01-.238.176h-9.61a1 1 0 00-.811.416C2.221 6.083-1.272 13.013.458 19.749a1 1 0 00.968.751 1.031 1.031 0 00.25-.031 1 1 0 00.72-1.218A15.243 15.243 0 016.683 5.058.254.254 0 016.843 5h9.111a.249.249 0 01.238.176A3.994 3.994 0 1020 0zm0 6a2 2 0 112-2 2 2 0 01-2 2z\"/><path d=\"M5.1 14.5h12.8a.5.5 0 00.5-.56 13 13 0 00-3.062-6.74 1.957 1.957 0 00-1.519-.7H9.185a1.961 1.961 0 00-1.52.7A13.011 13.011 0 004.6 13.94a.5.5 0 00.5.56zM17.923 16H5.081a.5.5 0 00-.5.553 12.384 12.384 0 003.089 6.774 1.958 1.958 0 001.5.673h4.664a1.958 1.958 0 001.5-.673 12.384 12.384 0 003.089-6.774.5.5 0 00-.5-.553z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});