define("ember-svg-jar/inlined/rating-star-subtract-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rating-star-subtract-alternate</title><path d=\"M23.124 10.39a1.5 1.5 0 00-.974-2.641h-6.089a.5.5 0 01-.471-.334L13.4 1.221a1.5 1.5 0 00-2.809 0l-.006.016L8.41 7.415a.5.5 0 01-.471.334H1.85A1.5 1.5 0 00.887 10.4l5.183 4.3a.5.5 0 01.156.543l-2.178 6.531a1.5 1.5 0 002.309 1.685l5.348-3.921a.5.5 0 01.592 0l5.346 3.922a1.5 1.5 0 002.31-1.686l-2.179-6.535a.5.5 0 01.155-.543zM9.65 13.749a1 1 0 010-2h5a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});