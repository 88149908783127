define("ember-svg-jar/inlined/army-badge-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-badge-3</title><path d=\"M5.65 11a.35.35 0 00.35-.35V8.5a.5.5 0 00-.5-.5H.25a.225.225 0 00-.21.12.23.23 0 00-.01.24l.76 1.53A2 2 0 002.58 11zM23.96 8.12a.225.225 0 00-.21-.12H18.5a.5.5 0 00-.5.5v2.15a.35.35 0 00.35.35h3.07a2 2 0 001.79-1.11l.76-1.53a.23.23 0 00-.01-.24zM6.261 12.887a.5.5 0 00-.487-.387H1.67a.225.225 0 00-.21.12.255.255 0 00-.02.24l.77 1.53A2 2 0 004 15.5h2.452a.5.5 0 00.454-.709 9.886 9.886 0 01-.645-1.904zM22.33 12.5h-4.1a.5.5 0 00-.487.387 10.073 10.073 0 01-.65 1.9.5.5 0 00.455.708H20a2 2 0 001.79-1.11l.77-1.53a.255.255 0 00-.02-.24.225.225 0 00-.21-.115zM15.906 6H8.094a1 1 0 00-.594.914v3.739a8.507 8.507 0 003.97 7.195 1 1 0 001.06 0 8.507 8.507 0 003.97-7.195V6.914A1 1 0 0015.906 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});