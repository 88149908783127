define("ember-svg-jar/inlined/camera-settings-flip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camera-settings-flip</title><path d=\"M15.918 7.592a.892.892 0 01-.489-.2l-1.267-1.27a1.833 1.833 0 00-1.182-.489h-1.96a1.833 1.833 0 00-1.182.489L8.571 7.389a.892.892 0 01-.489.2h-1.47a1.961 1.961 0 00-1.959 1.962v4.9a1.961 1.961 0 001.959 1.959h10.776a1.961 1.961 0 001.959-1.959v-4.9a1.961 1.961 0 00-1.959-1.959zM14.449 12A2.449 2.449 0 1112 9.551 2.449 2.449 0 0114.449 12z\"/><path d=\"M2.618 8.413A10.1 10.1 0 0112 1.959a9.966 9.966 0 016.016 2 .248.248 0 01.1.179.244.244 0 01-.071.19l-1.451 1.448a.49.49 0 00.346.836h4.366a.49.49 0 00.49-.49V1.756a.49.49 0 00-.836-.346l-1.172 1.172a.245.245 0 01-.326.018A11.9 11.9 0 0012 0 12.071 12.071 0 00.789 7.712a.979.979 0 001.829.7zM22.646 15.023a.98.98 0 00-1.264.564A10.1 10.1 0 0112 22.041a9.966 9.966 0 01-6.016-2 .248.248 0 01-.1-.179.244.244 0 01.071-.19l1.448-1.449a.49.49 0 00-.346-.836H2.694a.49.49 0 00-.49.49v4.366a.49.49 0 00.836.346l1.172-1.172a.245.245 0 01.326-.018A11.9 11.9 0 0012 24a12.071 12.071 0 0011.211-7.712.98.98 0 00-.565-1.265z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});