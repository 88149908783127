define("ember-svg-jar/inlined/remove-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>remove-shield</title><path d=\"M20.419 0H3.581a1.5 1.5 0 00-1.49 1.5v6.264a18.256 18.256 0 009.675 16.177A.5.5 0 0012 24a.5.5 0 00.235-.059 18.259 18.259 0 009.674-16.177V1.5a1.5 1.5 0 00-1.49-1.5zM16.62 13.2a1 1 0 010 1.414 1.009 1.009 0 01-1.414 0l-2.766-2.761a.5.5 0 00-.707 0l-2.765 2.765a1.031 1.031 0 01-1.414 0 1 1 0 010-1.414l2.765-2.765a.5.5 0 000-.708L7.554 6.966a1 1 0 111.414-1.414l2.765 2.765a.5.5 0 00.707 0l2.766-2.765a1 1 0 011.414 1.414l-2.765 2.765a.5.5 0 000 .708z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});