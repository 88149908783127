define("ember-svg-jar/inlined/professions-woman-surgeon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-woman-surgeon</title><path d=\"M2.6 12.619a3.22 3.22 0 003.216 3.217A3.187 3.187 0 008.1 14.86a.246.246 0 01.3-.039 6.87 6.87 0 007.188 0 .246.246 0 01.3.039 3.187 3.187 0 002.287.976 3.212 3.212 0 001.377-6.115.25.25 0 01-.142-.233v-.086a2.921 2.921 0 00-.6-1.761.246.246 0 01-.018-.269.915.915 0 00.12-.444V2.474a.986.986 0 00-.4-.791A11.349 11.349 0 0012 0h-.006a11.351 11.351 0 00-6.526 1.683.988.988 0 00-.4.791v4.454a.915.915 0 00.12.444.246.246 0 01-.018.269 2.921 2.921 0 00-.6 1.761v.086a.249.249 0 01-.142.232A3.206 3.206 0 002.6 12.619zm12.124-.01a.248.248 0 01.123.206.251.251 0 01-.111.214 4.933 4.933 0 01-5.468 0 .251.251 0 01-.111-.214.248.248 0 01.123-.206 5.427 5.427 0 015.444 0zm2.227-4.691a.248.248 0 01.247.247v.743a5.07 5.07 0 01-.546 2.266.249.249 0 01-.366.092 7.389 7.389 0 00-8.569 0 .248.248 0 01-.365-.092A5.07 5.07 0 016.8 8.908v-.743a.248.248 0 01.247-.247zm-.138-4.954a.249.249 0 01.138.222v2.505a.247.247 0 01-.248.247H7.3a.247.247 0 01-.248-.247v-2.5a.248.248 0 01.138-.222 12.223 12.223 0 019.621-.005zM.123 23.01a.99.99 0 001.98 0v-2.085a.962.962 0 01.372-.776 11.62 11.62 0 013.721-1.8.248.248 0 01.241.058A8.2 8.2 0 0012 20.394a8.2 8.2 0 005.563-1.994.248.248 0 01.241-.058 11.641 11.641 0 013.721 1.8.964.964 0 01.372.776v2.092a.99.99 0 101.98 0v-2.085a2.926 2.926 0 00-1.152-2.349 14.089 14.089 0 00-5.151-2.347.989.989 0 00-1.023.353A5.8 5.8 0 0112 18.414a5.8 5.8 0 01-4.551-1.832.993.993 0 00-1.023-.353 14.116 14.116 0 00-5.151 2.346 2.929 2.929 0 00-1.152 2.35z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});