define("ember-svg-jar/inlined/data-transfer-square-vertical-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-transfer-square-vertical-alternate</title><path d=\"M18.251 14.5h-1a.25.25 0 01-.25-.25V9.5a1.5 1.5 0 10-3 0v4.75a.25.25 0 01-.25.25h-1a.749.749 0 00-.6 1.2l2.749 3.667a.75.75 0 001.2 0l2.748-3.667a.749.749 0 00-.6-1.2z\"/><path d=\"M24 1.955A1.959 1.959 0 0022.044 0H1.957A1.959 1.959 0 000 1.955v20.088A1.959 1.959 0 001.957 24h20.087A1.959 1.959 0 0024 22.043zM2 21.041V3a1 1 0 011-1l18-.04a1 1 0 011 1L22.041 21a1 1 0 01-1 1L3 22.041a1 1 0 01-1-1z\"/><path d=\"M9.1 4.631a.781.781 0 00-1.2 0L5.152 8.3a.749.749 0 00.6 1.2h1a.25.25 0 01.25.25v4.75a1.5 1.5 0 103 0V9.749a.25.25 0 01.25-.25h1a.749.749 0 00.6-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});