define("ember-svg-jar/inlined/people-woman-glasses-4.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-glasses-4</title><path d=\"M.5 23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-2.606a2.466 2.466 0 00-.966-1.977 12.232 12.232 0 00-2.63-1.479.248.248 0 01-.153-.235.764.764 0 00-.026-.203.747.747 0 00-.49-.519 19.568 19.568 0 00-2.881-.717.763.763 0 00-.594.153l-3.6 2.877a.25.25 0 01-.312 0l-3.6-2.877a.759.759 0 00-.594-.153 19.568 19.568 0 00-2.881.717.747.747 0 00-.49.519.764.764 0 00-.025.206.248.248 0 01-.153.235 12.271 12.271 0 00-2.631 1.479A2.466 2.466 0 00.5 20.894zm6.133-5.619a.25.25 0 01.128-.411c.23-.054.461-.1.7-.15a.251.251 0 01.2.051l2.893 2.309a.249.249 0 010 .39l-.947.757a.25.25 0 01-.341-.028zm8.1 2.918a.25.25 0 01-.341.028l-.947-.757a.249.249 0 010-.39l2.893-2.309a.249.249 0 01.2-.05c.234.045.465.095.7.149a.25.25 0 01.128.411z\"/><path d=\"M2.847 13.523a.5.5 0 00-.231.8 3.8 3.8 0 002.921 1.12 5.351 5.351 0 001.317-.161 3.461 3.461 0 001.029-.436.25.25 0 01.273 0 6.97 6.97 0 007.643.032.252.252 0 01.275 0 3.478 3.478 0 001.072.461 5.4 5.4 0 001.317.161 3.8 3.8 0 002.921-1.12.5.5 0 00-.231-.8C18.6 12.761 19 9.544 19 7A7 7 0 005 7c0 2.552.375 5.711-2.153 6.523zm8.825-5.273a.249.249 0 01-.235-.166 2.691 2.691 0 00-.2-.439.25.25 0 01.217-.374h1.1a.25.25 0 01.217.374 2.691 2.691 0 00-.2.439.249.249 0 01-.235.166zm-2.815-.5A1.25 1.25 0 117.607 9a1.252 1.252 0 011.25-1.25zm6.286 0A1.25 1.25 0 1113.893 9a1.251 1.251 0 011.25-1.25zM8.107 12.1a.25.25 0 01.244-.4 2.561 2.561 0 00.506.05 2.74 2.74 0 002.579-1.834.252.252 0 01.236-.166h.656a.252.252 0 01.236.166 2.74 2.74 0 002.579 1.834 2.561 2.561 0 00.506-.05.25.25 0 01.244.4 4.94 4.94 0 01-7.786 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});