define("ember-svg-jar/inlined/loading-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>loading-3</title><path d=\"M11.25 1.1a.752.752 0 00-.85-.746A11.641 11.641 0 002.73 4.8a.751.751 0 00.218 1.1l4.4 2.54a.743.743 0 00.375.1.75.75 0 00.536-.225 5.2 5.2 0 012.428-1.407.749.749 0 00.564-.727zM13.007.531a.75.75 0 00-.257.566v5.088a.75.75 0 00.563.727 5.2 5.2 0 012.429 1.407.749.749 0 00.911.124l4.4-2.539a.751.751 0 00.216-1.104A11.636 11.636 0 0013.6.354a.746.746 0 00-.593.177zM22.186 16.9a.755.755 0 00.243-.04.747.747 0 00.451-.427 11.716 11.716 0 000-8.861.751.751 0 00-1.07-.372l-4.4 2.547a.748.748 0 00-.346.854 5.19 5.19 0 010 2.79.749.749 0 00.346.854l4.4 2.547a.747.747 0 00.376.108zM13.5 23.649a.686.686 0 00.1-.007 11.635 11.635 0 007.667-4.44.75.75 0 00-.218-1.109l-4.4-2.54a.747.747 0 00-.911.125 5.212 5.212 0 01-2.429 1.406.75.75 0 00-.563.727V22.9a.75.75 0 00.751.749zM10.4 23.642a.7.7 0 00.1.007.749.749 0 00.75-.75V17.81a.75.75 0 00-.564-.726 5.215 5.215 0 01-2.428-1.407.747.747 0 00-.911-.124l-4.4 2.539A.752.752 0 002.73 19.2a11.642 11.642 0 007.67 4.442zM1.572 16.854a.724.724 0 00.242.041.749.749 0 00.376-.1l4.4-2.547a.748.748 0 00.346-.854 5.19 5.19 0 010-2.79.748.748 0 00-.336-.855L2.19 7.2a.748.748 0 00-1.07.366 11.716 11.716 0 000 8.861.745.745 0 00.452.427z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});