define("ember-svg-jar/inlined/messages-bubble-sync-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-sync-1-alternate</title><path d=\"M9.293 15.293L7 17.586V15.56a1 1 0 00-.664-.942A6.493 6.493 0 018.5 2h7A6.508 6.508 0 0122 8.5a6.327 6.327 0 01-.055.831.248.248 0 00.128.252l1.394.759a.251.251 0 00.365-.17A8.369 8.369 0 0024 8.5 8.51 8.51 0 0015.5 0h-7A8.492 8.492 0 005 16.232V20a1 1 0 001.707.707l2.731-2.73a.25.25 0 00.072-.194c0-.073-.01-.146-.01-.221a8.018 8.018 0 01.277-2.085.25.25 0 00-.392-.264.776.776 0 00-.092.08z\"/><path d=\"M22.77 18.6a1 1 0 00-1.271.622 4.092 4.092 0 01-7.073 1.238l1.885-1.885a.5.5 0 00-.354-.853H11.5a.5.5 0 00-.5.5v4.458a.5.5 0 00.854.353l1.155-1.155a6.088 6.088 0 0010.383-2.007 1 1 0 00-.622-1.271zM23.691 11.772a.5.5 0 00-.545.109l-1.21 1.21a6.1 6.1 0 00-10.369 2.038 1 1 0 001.893.648 4.093 4.093 0 017.054-1.263l-1.824 1.824a.5.5 0 00.353.853H23.5a.5.5 0 00.5-.5v-4.457a.5.5 0 00-.309-.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});