define("ember-svg-jar/inlined/data-file-bars-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-bars-search</title><path d=\"M4.219 21.87a5.5 5.5 0 004.231-.716.242.242 0 01.3.031l2.51 2.505a1 1 0 001.414-1.414l-2.506-2.5a.241.241 0 01-.03-.3 5.5 5.5 0 10-5.919 2.4zM5.5 13.017a3.5 3.5 0 11-3.5 3.5 3.5 3.5 0 013.5-3.5z\"/><rect x=\"14.5\" y=\"5.517\" width=\"2\" height=\"8\" rx=\".5\" ry=\".5\"/><rect x=\"18\" y=\"7.517\" width=\"2\" height=\"6\" rx=\".5\" ry=\".5\"/><path d=\"M12.2 20.391a.4.4 0 00.3.126H22a2 2 0 002-2V4.431a2 2 0 00-.586-1.414L21 .6a2 2 0 00-1.414-.583H8a2 2 0 00-2 2v7.3a.243.243 0 00.222.242 6.864 6.864 0 011.453.309A.243.243 0 008 9.641V2.517a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.121a.5.5 0 01.146.354v13.379a.5.5 0 01-.5.5h-9.118a.241.241 0 00-.23.169 6.925 6.925 0 01-.4.978.353.353 0 00.059.332z\"/><path d=\"M11.941 13.517h.559a.5.5 0 00.5-.5v-2.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v1.6a.241.241 0 00.05.146 6.994 6.994 0 01.7 1.118.275.275 0 00.191.136z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});