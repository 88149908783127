define("ember-svg-jar/inlined/vintage-car-6", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-car-6</title><path d=\"M22.75 11.875h1a.25.25 0 00.25-.25v-.5a1.225 1.225 0 00-.331-.743L19.24 6.4a2.989 2.989 0 00-2.008-.771h-4.018a3.009 3.009 0 00-1.9.678l-5.171 4.227a.244.244 0 01-.1.049l-4.7 1.129A1.743 1.743 0 000 13.413v.792a1.744 1.744 0 001.083 1.618l1.105.455a.25.25 0 00.344-.206 2.991 2.991 0 015.968.3 2.8 2.8 0 01-.089.689.251.251 0 00.243.311h7.692a.251.251 0 00.243-.311 2.8 2.8 0 01-.089-.689 2.995 2.995 0 015.947-.51.25.25 0 00.38.169l.363-.231a1.744 1.744 0 00.81-1.474v-.7a.25.25 0 00-.25-.25h-1a.751.751 0 01-.75-.75.751.751 0 01.75-.751zm-7.75 3.5h-5a.5.5 0 010-1h5a.5.5 0 010 1zm1.75-5.25a.25.25 0 01-.25.25h-6.3a.25.25 0 01-.158-.443l2.542-2.08a1 1 0 01.634-.227H16.5a.25.25 0 01.25.25z\"/><circle cx=\"5.5\" cy=\"16.375\" r=\"2\"/><circle cx=\"19.5\" cy=\"16.375\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});