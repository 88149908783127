define("ember-svg-jar/inlined/car-tool-battery.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-tool-battery</title><path d=\"M22.5 6.5h-21A1.5 1.5 0 000 8v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 20V8a1.5 1.5 0 00-1.5-1.5zM7.75 15h-4a1 1 0 010-2h4a1 1 0 010 2zm12.5 0h-.75a.25.25 0 00-.25.25V16a1 1 0 01-2 0v-.75A.25.25 0 0017 15h-.75a1 1 0 010-2H17a.25.25 0 00.25-.25V12a1 1 0 012 0v.75a.25.25 0 00.25.25h.75a1 1 0 010 2zM3.75 5.5h4a.5.5 0 00.5-.5V3.5a1 1 0 00-1-1h-3a1 1 0 00-1 1V5a.5.5 0 00.5.5zM16.25 5.5h4a.5.5 0 00.5-.5V3.5a1 1 0 00-1-1h-3a1 1 0 00-1 1V5a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});