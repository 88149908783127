define("ember-svg-jar/inlined/beach-sun-birds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beach-sun-birds</title><path d=\"M7.5 3.78a1 1 0 001-1V1.6a1 1 0 00-2 0v1.18a1 1 0 001 1zM3.064 5.048a1 1 0 101.415-1.414L3.643 2.8a1 1 0 00-1.415 1.411zM1 9.1h1.182a1 1 0 100-2H1a1 1 0 000 2zM3.064 11.154l-.835.836a1 1 0 101.414 1.41l.836-.836a1 1 0 00-1.415-1.414zM6.5 13.416V14.6a1 1 0 002 0v-1.184a1 1 0 00-2 0zM10.585 11.154a1 1 0 000 1.414l.836.836a1 1 0 001.414-1.414L12 11.154a1 1 0 00-1.415 0zM11.818 8.1a1 1 0 001 1H14a1 1 0 000-2h-1.182a1 1 0 00-1 1zM11.292 5.341A1 1 0 0012 5.048l.837-.837A1 1 0 0011.421 2.8l-.837.837a1 1 0 00.708 1.707zM7.5 11.528A3.409 3.409 0 104.091 8.12 3.412 3.412 0 007.5 11.528zM17.832 11.4a.516.516 0 01.538-.2.826.826 0 01.659.649 1 1 0 001.941 0 .826.826 0 01.66-.649.517.517 0 01.538.2 1 1 0 101.664-1.109 2.481 2.481 0 00-2.546-1.058 2.8 2.8 0 00-1.019.391.5.5 0 01-.535 0 2.8 2.8 0 00-1.018-.391 2.472 2.472 0 00-2.546 1.058 1 1 0 101.664 1.109zM22.867 21.343c-1.629.218-2.737-1.5-2.746-1.513a1.048 1.048 0 00-1.715.023 3.283 3.283 0 01-2.677 1.515 2.725 2.725 0 01-2.336-1.439 1.045 1.045 0 00-1.714-.16 4.2 4.2 0 01-3.027 1.6 3.461 3.461 0 01-2.684-1.539 1.044 1.044 0 00-1.636-.094c-.014.02-1.509 1.874-3.2 1.647a1 1 0 00-.264 1.982 4.113 4.113 0 00.549.036 5.486 5.486 0 003.324-1.295.5.5 0 01.632.01 5.037 5.037 0 003.279 1.252 5.623 5.623 0 003.4-1.2.5.5 0 01.636.027 4.449 4.449 0 003.044 1.17 5 5 0 003.223-1.165.5.5 0 01.64 0 4.5 4.5 0 003.538 1.126 1 1 0 00-.266-1.982z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});