define("ember-svg-jar/inlined/road-sign-turn-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-turn-alternate</title><path d=\"M23 10.586L13.414 1a2 2 0 00-2.828 0L1 10.586a2 2 0 000 2.828L10.586 23a2 2 0 002.828 0L23 13.414a2 2 0 000-2.828zm-1.59 1.59l-9.233 9.233a.25.25 0 01-.354 0l-9.232-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.233 9.231a.251.251 0 010 .354z\"/><path d=\"M14.5 7.5h-.75a.25.25 0 01-.25-.25V6.5a1 1 0 00-1-1h-1a1 1 0 00-1 1v5.75a.25.25 0 01-.25.25H9.5a1 1 0 00-1 1v1a1 1 0 001 1h.75a.25.25 0 01.25.25v.75a1 1 0 001 1h1a1 1 0 001-1v-5.75a.25.25 0 01.25-.25h.75a1 1 0 001-1v-1a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});