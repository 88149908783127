define("ember-svg-jar/inlined/professions-man-doctor-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-doctor-2</title><path d=\"M16 15.5a.2.2 0 00-.176-.2.212.212 0 01-.084-.389A6.993 6.993 0 0019 9V7A7 7 0 005 7v2a7 7 0 003.162 5.847.25.25 0 01-.1.455c-.44.072-.858.154-1.257.245a.048.048 0 01-.04-.01.045.045 0 01-.015-.037.75.75 0 00-1.5 0v3.719a.251.251 0 01-.15.229 2.25 2.25 0 101.8 0 .251.251 0 01-.15-.229v-1.411a.249.249 0 01.191-.243 20.389 20.389 0 014.049-.543.249.249 0 01.26.249V23a.75.75 0 001.5 0v-5.712a.249.249 0 01.26-.249 22.1 22.1 0 012.7.252.251.251 0 00.29-.247zM6 22.25a.75.75 0 11.75-.75.75.75 0 01-.75.75zM7 9v-.951a.249.249 0 01.243-.249 6.986 6.986 0 004.736-1.731.031.031 0 01.042 0A6.978 6.978 0 0016.757 7.8a.251.251 0 01.243.25V9A5 5 0 017 9z\"/><path d=\"M1.164 18.018A2.957 2.957 0 000 20.393V23a1 1 0 002 0v-2.607a.974.974 0 01.377-.785 10.163 10.163 0 011.732-1.044.252.252 0 00.141-.225v-1.645a.25.25 0 00-.347-.23 12.547 12.547 0 00-2.739 1.554zM22.837 18.019a13.625 13.625 0 00-4.162-2.071.249.249 0 01-.174-.238v-.21a.75.75 0 00-1.5 0v4.2a.251.251 0 01-.159.233 2.491 2.491 0 00-1.592 2.317V23a.75.75 0 001.5 0v-.75a1 1 0 012 0V23a.75.75 0 001.5 0v-.75a2.492 2.492 0 00-1.59-2.316.249.249 0 01-.16-.234v-1.35a.25.25 0 01.338-.233 10.953 10.953 0 012.785 1.49.973.973 0 01.376.784V23a1 1 0 002 0v-2.607a2.955 2.955 0 00-1.162-2.374z\"/><circle cx=\"14\" cy=\"9.25\" r=\"1\"/><circle cx=\"10\" cy=\"9.25\" r=\"1\"/><path d=\"M12.082 12.869a2.976 2.976 0 00.863-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2h-2a.639.639 0 00-.3 1.2 2.31 2.31 0 00.435.18 2.99 2.99 0 00.867.126z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});