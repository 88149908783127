define("ember-svg-jar/inlined/accessories-earrings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accessories-earrings</title><path d=\"M16.458 15.5a.5.5 0 00.611.432 2.5 2.5 0 10-1.593-4.716.5.5 0 00-.225.712 8.947 8.947 0 011.207 3.572z\"/><path d=\"M17.907 7.633a.5.5 0 01-.407-.491V5a1 1 0 00-1-1 1 1 0 111-1 1 1 0 002 0 3 3 0 10-4.285 2.71.5.5 0 01.285.452v.979a.5.5 0 01-.406.492 7.42 7.42 0 00-2.194.789.5.5 0 00-.057.84A9.07 9.07 0 0114 10.279a.5.5 0 00.556.114A5 5 0 1116.5 20c-.116 0-.23 0-.344-.012a.5.5 0 00-.488.289 9 9 0 01-.766 1.337.5.5 0 00.326.778 7.5 7.5 0 002.679-14.759z\"/><path d=\"M8.907 9.133a.5.5 0 01-.407-.491V6.5a1 1 0 00-1-1 1 1 0 111-1 1 1 0 002 0 3 3 0 10-4.285 2.71.5.5 0 01.285.452v.98a.5.5 0 01-.407.491 7.5 7.5 0 102.814 0zM7.5 21.5a5 5 0 115-5 5 5 0 01-5 5z\"/><circle cx=\"7.5\" cy=\"15\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});