define("ember-svg-jar/inlined/file-key-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-key-1</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM5.561 15.675l3.381 3.382a.625.625 0 01-.884.883s-2.7-2.694-2.763-2.762-.17-.069-.17.085V19.5a.625.625 0 01-1.25 0v-8a.625.625 0 011.25 0v2.253c0 .082.121.116.191.046l2.742-2.74a.625.625 0 01.884.883l-3.381 3.379a.25.25 0 000 .354zm5.813-.8H13a.625.625 0 010 1.25h-1.623a.252.252 0 00-.252.252V18a.876.876 0 00.875.875h2.5a.625.625 0 110 1.25H12A2.127 2.127 0 019.875 18v-5A2.127 2.127 0 0112 10.873h2.5a.625.625 0 010 1.25H12a.877.877 0 00-.875.875v1.626a.249.249 0 00.249.249zm9.751-1.375a2.622 2.622 0 01-1.825 2.489.246.246 0 00-.176.233V19.5a.625.625 0 11-1.25 0v-3.278a.246.246 0 00-.176-.233 2.622 2.622 0 01-1.823-2.489v-2a.625.625 0 111.25 0v2a1.375 1.375 0 002.75 0v-2a.625.625 0 111.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});