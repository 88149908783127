define("ember-svg-jar/inlined/cd-play-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cd-play-alternate</title><path d=\"M13.25 8.036a3.214 3.214 0 01.081-.719.25.25 0 00-.127-.276 8.991 8.991 0 104.045 4.359.251.251 0 00-.267-.147 3.188 3.188 0 01-.482.037 3.254 3.254 0 01-3.25-3.254zM3.5 15a.5.5 0 01-1 0A6.508 6.508 0 019 8.5a.5.5 0 010 1A5.506 5.506 0 003.5 15zM9 17a2 2 0 112-2 2 2 0 01-2 2zM24 2A2 2 0 0021.631.035c-.041.008-3.57 1.14-3.57 1.14A2 2 0 0016.5 3.126V6.11a.25.25 0 01-.188.242A1.75 1.75 0 1018.5 8.046V3.308a.249.249 0 01.174-.238l3-.957a.25.25 0 01.326.238V4.11a.25.25 0 01-.188.242A1.75 1.75 0 1024 6.046z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});