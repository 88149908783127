define("ember-svg-jar/inlined/video-player-smartphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-player-smartphone</title><path d=\"M10.446 13.866l5.076-3.019a.992.992 0 000-1.7l-5.076-3.013A.962.962 0 009 6.981v6.038a.962.962 0 001.446.847z\"/><path d=\"M21 3a3 3 0 00-3-3H6a3 3 0 00-3 3v18a3 3 0 003 3h12a3 3 0 003-3zM5 3a1 1 0 011-1h12a1 1 0 011 1v14.5a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5zm7 19.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});