define("ember-svg-jar/inlined/cleaning-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cleaning-woman</title><path d=\"M11.908 17.978a.247.247 0 000-.206.251.251 0 00-.154-.138 2.211 2.211 0 01-1.038-.694 2.178 2.178 0 01-.517-1.448.25.25 0 00-.259-.253c-.626.022-2 .022-2.653-.012a.249.249 0 00-.258.2l-.469 2.479a.5.5 0 00.492.594h1.395V23a1 1 0 001 1h2a.994.994 0 00.881-.536.249.249 0 00.017-.191c-.182-.579-1.139-3.787-.437-5.295z\"/><circle cx=\"10.447\" cy=\"2.5\" r=\"2.5\"/><path d=\"M4.7 11.5a2.753 2.753 0 002.75 2.75h2.5a1.25 1.25 0 000-2.5h-2.5a.251.251 0 01-.25-.25 2.677 2.677 0 01.068-.591 4.265 4.265 0 01.193-.561 3.542 3.542 0 01.313-.511.144.144 0 01.256.107l-.1.806h2.017A2.242 2.242 0 0112.1 12.4a.251.251 0 00.4.125l.423-.352a.41.41 0 01.1-.065.25.25 0 00.145-.276l-.302-1.632a.089.089 0 01.165-.061l.008.012a2.682 2.682 0 01.19.41l.563 1.547a.752.752 0 01-.224.832l-.155.13-1.766 1.47a1.242 1.242 0 00-.308.385 1.258 1.258 0 00.148 1.377h.006a1.247 1.247 0 001.754.155l1.922-1.6a3.26 3.26 0 00.973-3.606l-.563-1.547a5.264 5.264 0 00-4.933-3.454h-.7a4.984 4.984 0 00-1.317.192.251.251 0 01-.273-.1c-.613-.911-.2-.293-2.975-4.416a1 1 0 10-1.657 1.116l2.828 4.2a.25.25 0 01-.043.328A5.209 5.209 0 004.7 11.5zM19.718 18.155a3.751 3.751 0 00-5.37-.9 3.894 3.894 0 00-.732 5.264l.441.654a1 1 0 001.388.271l.4-.268a.25.25 0 00.077-.331l-1.256-2.2a.5.5 0 01.869-.5l1.2 2.1a.254.254 0 00.16.119.25.25 0 00.2-.036l1.176-.792a.251.251 0 00.077-.332l-1.18-2.065a.5.5 0 01.869-.5l1.125 1.969a.251.251 0 00.357.083l.492-.331a1 1 0 00.27-1.389z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});