define("ember-svg-jar/inlined/sports-car-convertible-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sports-car-convertible-3</title><path d=\"M14 10.006a.25.25 0 00-.25-.25h-3.732a.25.25 0 01-.177-.427l1.116-1.116A1 1 0 009.543 6.8L6.635 9.707a.258.258 0 01-.164.073A8.937 8.937 0 00.59 12.473a1.9 1.9 0 001.31 3.283h1.36a.251.251 0 00.249-.231 3 3 0 015.982 0 .251.251 0 00.249.231h2.8a.25.25 0 00.224-.139l.845-1.691a3.736 3.736 0 00.391-1.67zM24 11.256a2.558 2.558 0 00-1.454-2.312l-4.991-2.538A1.83 1.83 0 0015 8.086v4.17a4.765 4.765 0 01-.5 2.118l-.51 1.02a.25.25 0 00.224.362h2.046a.251.251 0 00.249-.231 3 3 0 015.843-.7.25.25 0 00.387.123l.261-.2a2.511 2.511 0 001-2v-1.5zm-5.067-1.692a.251.251 0 01-.243.192h-1.44a.25.25 0 01-.25-.25v-.733a.25.25 0 01.363-.223l1.44.733a.249.249 0 01.13.281z\"/><circle cx=\"6.5\" cy=\"15.756\" r=\"2\"/><circle cx=\"19.5\" cy=\"15.756\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});