define("ember-svg-jar/inlined/file-pps-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-pps-1</title><path d=\"M6 12H4.75a.25.25 0 00-.25.25v2.5a.248.248 0 00.248.25H6a1.5 1.5 0 000-3zM12 12h-1.25a.25.25 0 00-.25.25v2.5a.248.248 0 00.248.248H12a1.5 1.5 0 000-3z\"/><path d=\"M22 0H2A1.5 1.5 0 00.5 1.5v21A1.5 1.5 0 002 24h20a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022 0zM6 16H4.749a.249.249 0 00-.249.249V19.5a.5.5 0 01-1 0v-8A.5.5 0 014 11h2a2.5 2.5 0 010 5zm6 0h-1.251a.249.249 0 00-.249.249V19.5a.5.5 0 01-1 0v-8a.5.5 0 01.5-.5h2a2.5 2.5 0 010 5zm5.248-1.434l1.95.974A2.354 2.354 0 0118.146 20H16a.5.5 0 010-1h2.146a1.354 1.354 0 00.605-2.565l-1.95-.975A2.354 2.354 0 0117.854 11H20a.5.5 0 010 1h-2.146a1.355 1.355 0 00-.606 2.566z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});