define("ember-svg-jar/inlined/button-fast-forward", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-fast-forward</title><path d=\"M12 24A12 12 0 100 12a12.013 12.013 0 0012 12zM7.536 9.785a.743.743 0 011.135-.679l3.02 1.626a.248.248 0 00.246 0 .251.251 0 00.122-.215v-.732a.744.744 0 011.141-.679l5.083 2.738a.715.715 0 010 1.309l-5.085 2.738a.743.743 0 01-1.135-.678v-.727a.249.249 0 00-.368-.22l-3.02 1.625a.852.852 0 01-.411.109.715.715 0 01-.368-.1.77.77 0 01-.36-.686z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});