define("ember-svg-jar/inlined/monitor-transfer-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-transfer-file</title><path d=\"M20.5 4a.5.5 0 01-.5-.5V.25a.25.25 0 00-.25-.25H16.5a.5.5 0 00-.5.5v9a.5.5 0 00.5.5h7a.5.5 0 00.5-.5V4.25a.25.25 0 00-.25-.25z\"/><path d=\"M21 2.75a.25.25 0 00.25.25h1.85a.25.25 0 00.177-.427L21.427.719A.25.25 0 0021 .9zM14.5 4a1 1 0 00-1-1 6 6 0 00-5.753 4.32.251.251 0 01-.24.18H6.5a1 1 0 00-.707 1.707l2 2a1 1 0 001.416 0l2-2A1 1 0 0010.5 7.5h-.31a.249.249 0 01-.221-.367A4 4 0 0113.5 5a1 1 0 001-1z\"/><path d=\"M4.045 8.933A.976.976 0 003.5 8.75H2a2 2 0 00-2 2V19a2 2 0 002 2h5.75a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H5a1 1 0 000 2h8a1 1 0 000-2h-2.75a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25H16a2 2 0 002-2v-6.25a1 1 0 00-2 0v4.5a.25.25 0 01-.25.25H2.25a.25.25 0 01-.25-.25V11a.25.25 0 01.25-.25H3.5a.987.987 0 00.968-.841.953.953 0 00.032-.159.983.983 0 00-.455-.817z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});