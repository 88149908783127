define("ember-svg-jar/inlined/video-game-mario-bomb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-mario-bomb</title><path d=\"M2.5 10.3H1a1 1 0 000 2h1.5a1 1 0 000-2zM1 15.8h.75a1 1 0 000-2H1a1 1 0 000 2zM18.815 9.659l.567-.841a1 1 0 00-.27-1.389.5.5 0 01-.135-.693l.838-1.245a1 1 0 10-1.659-1.117l-.838 1.244a.5.5 0 01-.694.135 1 1 0 00-1.388.27l-.566.84a.5.5 0 01-.54.2 8.514 8.514 0 00-8.686 2.826.25.25 0 00.193.41H12.5a.75.75 0 010 1.5H4.582a.5.5 0 00-.464.312 8.53 8.53 0 00-.35 1.063.5.5 0 00.484.625H10.5a.75.75 0 010 1.5H4.015a.5.5 0 00-.5.531 8.648 8.648 0 00.134 1.063.5.5 0 00.491.406h3.139a.75.75 0 010 1.5h-2.63a.249.249 0 00-.222.363 8.5 8.5 0 1014.4-8.925.5.5 0 01-.012-.578zM2.5 17.3H1a1 1 0 000 2h1.5a1 1 0 000-2zM17.471 2.706a1 1 0 101.117-1.658l-.829-.559a1 1 0 00-1.117 1.658zM22.33 1.76A1 1 0 0020.671.641l-.559.829a1 1 0 001.659 1.12zM23.558 4.4l-.829-.558A1 1 0 1021.612 5.5l.829.558A1 1 0 1023.558 4.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});