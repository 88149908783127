define("ember-svg-jar/inlined/movies-director-chair", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>movies-director-chair</title><path d=\"M23 8h-3.25a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v.25a.25.25 0 01-.25.25H6.75a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v6.75a.25.25 0 01-.25.25H1a1 1 0 000 2h3.25a.25.25 0 01.25.25v3.517a.252.252 0 01-.072.176l-.493.5a1.505 1.505 0 00-.323 1.631A1.486 1.486 0 004.989 17h.633a.252.252 0 01.138.042l4.125 2.75a.249.249 0 010 .416l-2.94 1.96a1 1 0 001.11 1.664l3.806-2.538a.251.251 0 01.278 0l3.806 2.538a1 1 0 101.11-1.664l-2.94-1.96a.249.249 0 010-.416l4.125-2.75a.252.252 0 01.138-.042h.633a1.486 1.486 0 001.377-.928 1.505 1.505 0 00-.323-1.631l-.493-.5a.252.252 0 01-.072-.176V10.25a.25.25 0 01.25-.25H23a1 1 0 000-2zM6.75 13a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h10.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25zm6.95-6.017l.373 1.681a.276.276 0 01-.416.294l-1.506-.941a.274.274 0 00-.294 0l-1.506.941a.276.276 0 01-.416-.294l.365-1.681a.276.276 0 00-.093-.272L9.1 5.785a.279.279 0 01-.093-.275.275.275 0 01.2-.206l1.561-.39a.278.278 0 00.181-.145l.8-1.6a.276.276 0 01.494 0l.8 1.6a.278.278 0 00.181.145l1.561.39a.275.275 0 01.2.206.279.279 0 01-.093.275l-1.111.926a.276.276 0 00-.081.272zm-1.561 11.723a.251.251 0 01-.278 0L9.99 17.458a.25.25 0 01.138-.458h3.744a.25.25 0 01.138.458z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});