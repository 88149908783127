define("ember-svg-jar/inlined/dog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dog</title><path d=\"M23.6 5.589a1 1 0 10-1.826.816 2.556 2.556 0 01-2.011 3.573 4.246 4.246 0 00-3.421-1.728H11.9a3 3 0 01-2.757 2 2.931 2.931 0 01-2.886-3.188l.262-2.636a.75.75 0 111.492.148l-.255 2.575a1.419 1.419 0 002.392 1.175 1.919 1.919 0 00.266-1.857 12.6 12.6 0 01-.225-2.078A2.743 2.743 0 007.443 1.75h-1.6a3.488 3.488 0 00-3.508 2h-.59A1.751 1.751 0 00.084 6.06l.385 1.146A1.729 1.729 0 001.7 8.357l1.63.412a.5.5 0 01.375.422l1.286 10.054a1.74 1.74 0 00-1.55 1.265 4.239 4.239 0 00-.337.964.8.8 0 00.811.776h3.927a.75.75 0 00.749-.719l.2-4.8a.5.5 0 01.5-.479h5.078a4.36 4.36 0 001.03.379l.149 2.623a1.74 1.74 0 00-1.607 1.266c-.155.369-.45.815-.308 1.224a.761.761 0 00.709.508h4.5a.749.749 0 00.745-.668c.048 0 .976-9.724.957-9.72A4.571 4.571 0 0023.6 5.589z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});