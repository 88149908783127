define("ember-svg-jar/inlined/download-arrow-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>download-arrow-2</title><path d=\"M10 15.574a2.354 2.354 0 004.062-.1L21.67 3.215a1.411 1.411 0 00-1.2-2.156h-.942a1.412 1.412 0 00-1.2.671l-5.9 9.59a.5.5 0 01-.852 0l-5.9-9.59a1.412 1.412 0 00-1.2-.671h-.947a1.411 1.411 0 00-1.2 2.156zM22.353 19.647H1.647a1.647 1.647 0 100 3.294h20.706a1.647 1.647 0 000-3.294z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});