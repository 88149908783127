define("ember-svg-jar/inlined/video-game-logo-gears", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-logo-gears</title><path d=\"M10.5 1.856L7.481.133a.991.991 0 00-.944-.026L3.4 1.677a1 1 0 00-.26 1.6L5 5.142v13.134a1.494 1.494 0 00.174.7 17.392 17.392 0 004.19 4.794.992.992 0 001.064.131A.991.991 0 0011 23V2.725a1 1 0 00-.5-.869zM21.143 2.412a1 1 0 00-.54-.735l-3.14-1.57a.991.991 0 00-.944.026L13.5 1.856a1 1 0 00-.5.869V23a1 1 0 001 1 1 1 0 00.635-.229 17.392 17.392 0 004.19-4.794 1.494 1.494 0 00.174-.7V5.142l1.863-1.863a1 1 0 00.281-.867z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});