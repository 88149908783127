define("ember-svg-jar/inlined/tags-edit-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-edit-alternate</title><circle cx=\"17.172\" cy=\"5.999\" r=\"1.5\"/><path d=\"M12.782 20.044a.5.5 0 00-.835.216l-.886 3.1a.5.5 0 00.481.638.487.487 0 00.137-.019l3.1-.886a.5.5 0 00.221-.834zM18.814 13.227a.5.5 0 00-.707 0l-5.007 5.01a.5.5 0 000 .707l3 3a.5.5 0 00.707 0l5.011-5.01a.5.5 0 000-.707z\"/><path d=\"M9.619 22.949l.544-1.9a.17.17 0 00-.284-.167 1.025 1.025 0 01-1.414 0l-6.172-6.176a1 1 0 010-1.414l11-11A.993.993 0 0114 2h6.672a.5.5 0 01.5.5v6.745a.25.25 0 00.25.269h.012a4.171 4.171 0 011.386.234.248.248 0 00.215-.023.251.251 0 00.117-.181 3.091 3.091 0 00.023-.374V2a2 2 0 00-2-2H14a2.978 2.978 0 00-2.121.879l-11 11a3 3 0 000 4.242l6.172 6.172a2.99 2.99 0 002.121.877c.072 0 .144 0 .216-.008a.248.248 0 00.223-.184z\"/><path d=\"M19.525 11.809a.5.5 0 00-.151.354.507.507 0 00.147.357l3 3a.54.54 0 00.377.123.583.583 0 00.377-.169 2.626 2.626 0 000-3.707 2.658 2.658 0 00-3.75.042z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});