define("ember-svg-jar/inlined/library-maths", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>library-maths</title><path d=\"M23.25 8.126h-5a.75.75 0 000 1.5h5a.75.75 0 000-1.5z\"/><circle cx=\"20.75\" cy=\"6.376\" r=\"1\"/><circle cx=\"20.75\" cy=\"11.376\" r=\"1\"/><path d=\"M14.6 8.336a2 2 0 01-1.6-1.96.5.5 0 00-.5-.5 1.988 1.988 0 01-1.825-1.2.5.5 0 00-.459-.3H1.5a1.5 1.5 0 00-1.5 1.5v16a1.5 1.5 0 001.5 1.5h12a1.5 1.5 0 001.5-1.5V8.826a.5.5 0 00-.4-.49zM5 21.376a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5zm4.828.276L8.4 22.12a.5.5 0 01-.631-.319L6.525 18a.5.5 0 01.319-.631L8.27 16.9a.5.5 0 01.631.32l1.246 3.8a.5.5 0 01-.319.632zM13 14.626H2a.75.75 0 010-1.5h.5a.5.5 0 00.5-.5v-4.75a.5.5 0 01.5-.5H5a.5.5 0 01.5.5v4.75a.5.5 0 00.5.5h.5a.5.5 0 00.5-.5v-2.75a.5.5 0 01.5-.5H9a.5.5 0 01.5.5v2.75a.5.5 0 00.5.5h3a.75.75 0 010 1.5zM18.25 3.876a.75.75 0 00-.75-.75h-1.25a.5.5 0 01-.5-.5v-1.25a.75.75 0 00-1.5 0v1.25a.5.5 0 01-.5.5H12.5a.75.75 0 100 1.5h1.25a.5.5 0 01.5.5v1.25a.75.75 0 101.5 0v-1.25a.5.5 0 01.5-.5h1.25a.75.75 0 00.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});