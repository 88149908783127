define("ember-svg-jar/inlined/road-sign-speed-limit-60", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-speed-limit-60</title><path d=\"M16.274 9.536a1.728 1.728 0 00-1.726 1.726v2.476a1.726 1.726 0 003.452 0v-2.476a1.728 1.728 0 00-1.726-1.726zM8.226 12.012a1.726 1.726 0 101.726 1.726 1.728 1.728 0 00-1.726-1.726z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM8.226 16.964A3.229 3.229 0 015 13.738v-1.857a3.85 3.85 0 013.845-3.845h.619a.75.75 0 010 1.5h-.619a2.32 2.32 0 00-1.612.65.25.25 0 00.235.423 3.005 3.005 0 01.758-.1 3.226 3.226 0 010 6.452zM19.5 13.738a3.226 3.226 0 11-6.452 0v-2.476a3.226 3.226 0 116.452 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});