define("ember-svg-jar/inlined/skiing-chest-slide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skiing-chest-slide</title><circle cx=\"21.375\" cy=\"10.896\" r=\"2.5\"/><path d=\"M20.619 20.263a.249.249 0 00-.234.04 1.45 1.45 0 01-1.466.22l-17.421-7a1 1 0 10-.746 1.855l17.427 7a3.518 3.518 0 003.137-.284 3.408 3.408 0 001.336-1.571.25.25 0 00-.329-.328 2.293 2.293 0 01-1.7.066zM13.6 14.779a.249.249 0 00-.145-.159L1.5 9.774a1 1 0 10-.752 1.853l13.565 5.5a.25.25 0 00.29-.387 5.816 5.816 0 01-1.003-1.961z\"/><path d=\"M22.046 16.554l-3.819-1.91a1.5 1.5 0 01-.772-.929l-.637-2.231a1.5 1.5 0 00-.944-1L8.481 7.765a1.5 1.5 0 01-.7-.516L3.826 1.976a1.5 1.5 0 00-2.4 1.8L5.381 9.05a4.484 4.484 0 002.1 1.543l6.663 2.46.425 1.485a4.506 4.506 0 002.314 2.789l3.817 1.911a1.5 1.5 0 101.342-2.684z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});