define("ember-svg-jar/inlined/lab-flask-experiment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lab-flask-experiment</title><path d=\"M20.5 19.345l-4.362-7.383a1 1 0 01-.138-.509V2.5a.5.5 0 01.5-.5H18a1 1 0 000-2H6a1 1 0 000 2h1.5a.5.5 0 01.5.5v8.954a1 1 0 01-.139.508l-4.323 7.319A3 3 0 005.99 24h12.016a3 3 0 002.494-4.655zM8.673 16a.5.5 0 01-.43-.754l1.617-2.737A1.013 1.013 0 0010 12V2.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v2.75a.5.5 0 01-.5.5h-1.32a.75.75 0 000 1.5h1.32a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1.32a.75.75 0 000 1.5h1.32a.5.5 0 01.5.5V12a1 1 0 00.139.509l1.618 2.737a.5.5 0 01-.43.754zm.813 5.822a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm4.219-3.017a1 1 0 111-1 1 1 0 01-1 1zm2.5 3.082a1 1 0 111-1 1 1 0 01-1.005 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});