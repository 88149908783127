define("ember-svg-jar/inlined/flag-plain-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flag-plain-1</title><path d=\"M21.25 4.5H15.5a.25.25 0 00-.25.25v8.025a.75.75 0 01-1.5 0V3a1.5 1.5 0 00-1.5-1.5h-6a.5.5 0 00-.5.5v12a.5.5 0 00.5.5h4.23a3.562 3.562 0 00-1.561 1.627 1 1 0 00.929 1.373h11.4a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.498-1.5zM2.75 0a1.5 1.5 0 00-1.5 1.5v21a1.5 1.5 0 003 0v-21A1.5 1.5 0 002.75 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});