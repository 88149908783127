define("ember-svg-jar/inlined/smart-watch-square-data-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-data-alternate</title><path d=\"M21 6a3 3 0 00-3-3h-1.078a.249.249 0 01-.24-.182L15.98.365A.5.5 0 0015.5 0h-7a.5.5 0 00-.481.363l-.7 2.455A.248.248 0 017.077 3H6a3 3 0 00-3 3v12a3 3 0 003 3h1.077a.249.249 0 01.24.181l.7 2.456A.5.5 0 008.5 24h7a.5.5 0 00.48-.363l.7-2.456a.25.25 0 01.24-.181H18a3 3 0 003-3zm-2 12a1 1 0 01-1 1H6a1 1 0 01-1-1V6a1 1 0 011-1h12a1 1 0 011 1z\"/><path d=\"M9.762 7.092a.68.68 0 00-1.025 0l-1.75 2.187a.656.656 0 00.513 1.066H8a.25.25 0 01.25.25v3.438a1 1 0 102 0V10.6a.25.25 0 01.25-.25h.5a.656.656 0 00.512-1.066zM16 13.22h-.5a.249.249 0 01-.25-.25v-2.937a1 1 0 00-2 0v2.937a.249.249 0 01-.25.25h-.5a.657.657 0 00-.513 1.067l1.75 2.187a.657.657 0 001.025 0l1.75-2.187A.657.657 0 0016 13.22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});