define("ember-svg-jar/inlined/single-woman-actions-laptop.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-laptop</title><path d=\"M10.5 14a2.9 2.9 0 01.215-1.088.5.5 0 00-.242-.636 7.377 7.377 0 00-3.266-.776 7.543 7.543 0 00-7.185 5.357.5.5 0 00.478.643H10a.5.5 0 00.5-.5zM23.894 22.634a.314.314 0 00-.018-.031l-1.785-2.867A.463.463 0 0022 19.65V14a1.5 1.5 0 00-1.5-1.5h-7A1.5 1.5 0 0012 14v5.649a.5.5 0 00-.091.087l-1.8 2.9a.922.922 0 00.043.93.957.957 0 00.809.436h12.081a.957.957 0 00.809-.436.922.922 0 00.043-.932zM14 14.75a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25v4a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25zm1.516 7.63a.241.241 0 010-.238l.273-.476a.231.231 0 01.2-.119h1.855a.23.23 0 01.2.119l.273.476a.241.241 0 010 .238.229.229 0 01-.2.12h-2.4a.229.229 0 01-.201-.12zM1.567 10.442a1 1 0 001.383-.3 5.233 5.233 0 00.454-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .249.249 0 01.411.082 5.233 5.233 0 00.454.9 1 1 0 001.681-1.082 4.569 4.569 0 01-.642-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248a4.573 4.573 0 01-.643 2.512 1 1 0 00.298 1.382zM7.209 8.6a2.7 2.7 0 01-2.741-2.352.5.5 0 01.324-.577 5.711 5.711 0 002.245-1.439.25.25 0 01.361 0 5.7 5.7 0 002.23 1.437.5.5 0 01.323.576A2.708 2.708 0 017.209 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});