define("ember-svg-jar/inlined/canoe-paddles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>canoe-paddles</title><path d=\"M22.393.607a3.2 3.2 0 00-4.464.716l-3.478 4.806a2.651 2.651 0 00-.409 2.265l.346 1.25a.5.5 0 01-.077.426L12.2 12.983a.25.25 0 01-.406 0L9.689 10.07a.5.5 0 01-.076-.426l.345-1.25a2.644 2.644 0 00-.409-2.265L6.071 1.323A3.2 3.2 0 10.892 5.071L4.37 9.877a2.643 2.643 0 002.024 1.1l1.294.063a.5.5 0 01.381.206l2.484 3.433a.5.5 0 010 .587L6.64 20.67a.5.5 0 01-.316.2 1.579 1.579 0 101.562 2.479A1.573 1.573 0 008.17 22.2a.5.5 0 01.09-.361l3.54-4.884a.25.25 0 01.406 0l3.536 4.886a.505.505 0 01.091.363 1.558 1.558 0 00.285 1.142 1.578 1.578 0 101.561-2.478.5.5 0 01-.316-.2L13.45 15.26a.5.5 0 010-.587l2.484-3.433a.5.5 0 01.381-.206l1.294-.063a2.645 2.645 0 002.025-1.1l3.477-4.806a3.2 3.2 0 00-.718-4.458z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});