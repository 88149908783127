define("ember-svg-jar/inlined/car-actions-wifi-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-actions-wifi-1</title><path d=\"M21.215 14.135a.249.249 0 01-.09-.2.245.245 0 01.093-.193l.895-.716a1 1 0 00-1.249-1.562l-.725.58a.249.249 0 01-.4-.168l-.1-.88a3.017 3.017 0 00-2.99-2.751h-9.32a3.011 3.011 0 00-2.987 2.725l-.1.889a.25.25 0 01-.4.168l-.7-.563a1 1 0 00-1.249 1.562l.884.707a.25.25 0 010 .389 2.738 2.738 0 00-1.016 2.128v2.25a1.753 1.753 0 001.75 1.75h16.955a1.753 1.753 0 001.75-1.75v-2.25a2.739 2.739 0 00-1.001-2.115zm-15.08-1.162l.2-1.806a1.007 1.007 0 011-.917h9.319a1.013 1.013 0 011 .943l.2 1.78a.249.249 0 01-.248.277H6.383a.249.249 0 01-.248-.277zM5.511 18.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm13 0a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM8.011 21.25h-4a.25.25 0 00-.25.25v.25a2.25 2.25 0 004.5 0v-.25a.25.25 0 00-.25-.25zM20.011 21.25h-4a.25.25 0 00-.25.25v.25a2.25 2.25 0 004.5 0v-.25a.25.25 0 00-.25-.25zM6.369 4.328a7.951 7.951 0 0111.239 0 1 1 0 001.414-1.414 9.946 9.946 0 00-14.067 0 1 1 0 001.414 1.414z\"/><path d=\"M7.485 5.838a1 1 0 000 1.415 1.027 1.027 0 001.415 0 4.383 4.383 0 016.179 0 1 1 0 101.414-1.415 6.471 6.471 0 00-1.95-1.323 6.378 6.378 0 00-7.058 1.323z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});