define("ember-svg-jar/inlined/travel-luggage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>travel-luggage</title><path d=\"M20.5 4.25h-.75a.5.5 0 00-.5.5v4.557a.5.5 0 00.367.482 7.987 7.987 0 013.53 2.054.5.5 0 00.853-.354V7.75a3.5 3.5 0 00-3.5-3.5zM4.25 4.25H3.5A3.5 3.5 0 000 7.75v10a3.5 3.5 0 003.5 3.5h.75a.5.5 0 00.5-.5v-16a.5.5 0 00-.5-.5zM9.5 17.5a8 8 0 018-8 .25.25 0 00.25-.25v-4.5a.5.5 0 00-.5-.5.5.5 0 01-.5-.5V3a3 3 0 00-3-3h-3.5a3 3 0 00-3 3v.75a.5.5 0 01-.5.5.5.5 0 00-.5.5v16a.5.5 0 00.5.5h2.9a.5.5 0 00.462-.692A7.956 7.956 0 019.5 17.5zM9.25 3.75V3a1 1 0 011-1h3.5a1 1 0 011 1v.75a.5.5 0 01-.5.5h-4.5a.5.5 0 01-.5-.5z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm4.33 8.994A5 5 0 0117.5 22.5a4.85 4.85 0 01-2.074-.466.5.5 0 01-.021-.894A2.985 2.985 0 0017 18.5a1.5 1.5 0 00-1.5-1.5.5.5 0 01-.5-.5 1.99 1.99 0 00-.966-1.7.5.5 0 01-.1-.777A4.956 4.956 0 0117.5 12.5a4.737 4.737 0 011.509.249.5.5 0 01.22.8A2.971 2.971 0 0018.5 15.5 1.5 1.5 0 0020 17a.5.5 0 01.5.5 2 2 0 001.119 1.8.5.5 0 01.211.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});