define("ember-svg-jar/inlined/allowances-no-food", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>allowances-no-food</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm8.1 17.862a.5.5 0 01-.759.06l-1.973-1.973a1 1 0 10-1.414 1.414l1.973 1.973a.5.5 0 01-.06.759A9.993 9.993 0 013.905 6.138a.5.5 0 01.759-.06l1 1a1 1 0 101.415-1.414l-1-1a.5.5 0 01.06-.759A9.993 9.993 0 0120.1 17.862z\"/><path d=\"M12.522 13.1a.5.5 0 00-.545 0 3.978 3.978 0 01-2.171.647.465.465 0 00-.44.617l1.959 5.663a1 1 0 001.889 0l1.953-5.617a.5.5 0 00-.473-.664 3.979 3.979 0 01-2.172-.646z\"/><path d=\"M15.983 7.042a.5.5 0 01-.281-.37 3.492 3.492 0 00-6.89 0 .5.5 0 01-.282.368 3 3 0 103.37 4.851.5.5 0 01.7 0 3 3 0 103.384-4.849z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});