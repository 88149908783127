define("ember-svg-jar/inlined/night-stand-book", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>night-stand-book</title><path d=\"M20.3 19.625h-1.6a.5.5 0 00-.5.55l.2 1.955a1.106 1.106 0 002.2 0l.2-1.955a.5.5 0 00-.5-.55zM5.3 19.625H3.7a.5.5 0 00-.5.55l.2 1.955a1.106 1.106 0 002.2 0l.2-1.955a.5.5 0 00-.5-.55zM22.5.875h-21a1.5 1.5 0 00-1.5 1.5v3a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-3a1.5 1.5 0 00-1.5-1.5zm-9 3.25h-3a.75.75 0 010-1.5h3a.75.75 0 010 1.5zM23.5 6.875H.5a.5.5 0 00-.5.5v9.25a1.5 1.5 0 001.5 1.5h21a1.5 1.5 0 001.5-1.5v-9.25a.5.5 0 00-.5-.5zm-19 8.636a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5v-4.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5zm3 0a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5v-4.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5zm3 0a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5v-4.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5zm3.836-.182l-.891.454a.5.5 0 01-.672-.218l-2.046-4.008a.5.5 0 01.218-.672l.891-.455a.5.5 0 01.673.217l2.045 4.009a.5.5 0 01-.218.673z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});