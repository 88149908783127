define("ember-svg-jar/inlined/cash-payment-coins", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-payment-coins</title><path d=\"M20.5 7.031a3.5 3.5 0 10-3.5-3.5 3.5 3.5 0 003.5 3.5zm-.75-4.5a.75.75 0 111.5 0v2a.75.75 0 01-1.5 0zM14 16.031a4.5 4.5 0 10-4.5-4.5 4.5 4.5 0 004.5 4.5zm-.75-5.714a.75.75 0 011.5 0v2.428a.75.75 0 01-1.5 0zM.437 15.031h2.625a.438.438 0 01.438.438v6.125a.438.438 0 01-.437.438H.437A.438.438 0 010 21.594v-6.125a.437.437 0 01.437-.438zM10.867 18.031a.247.247 0 01-.19-.095A4.727 4.727 0 007 16.031H4.749a.25.25 0 00-.25.25v5.133a.256.256 0 00.176.238c4.421 1.48 6.314 2.317 7.984 2.317 2.037 0 3.741-1.246 9.291-4.091a1.017 1.017 0 00-.631-1.91 2.955 2.955 0 00-.82.126l-3.366 1.066a.259.259 0 00-.173.207A1.994 1.994 0 0115 21.031h-4a.5.5 0 010-1h4a1 1 0 100-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});