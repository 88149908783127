define("ember-svg-jar/inlined/safety-helmet-mine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-helmet-mine</title><path d=\"M7.215 15.286a.5.5 0 00.5-.515v-.057a4.249 4.249 0 011.024-2.757.5.5 0 00.119-.323V6.207a.5.5 0 00-.687-.463 10.3 10.3 0 00-6.443 9.016.5.5 0 00.5.526zM15.143 11.634a.5.5 0 00.119.323 4.249 4.249 0 011.024 2.757v.057a.5.5 0 00.5.515h4.988a.5.5 0 00.5-.526 10.3 10.3 0 00-6.444-9.016.5.5 0 00-.687.463zM15.612 17a4.262 4.262 0 01-7.224 0H1.714a1.715 1.715 0 000 3.429h20.572a1.715 1.715 0 000-3.429zM10.571 10.029a.5.5 0 00.605.489 3.859 3.859 0 011.648 0 .5.5 0 00.605-.489V3.857a.287.287 0 00-.286-.286h-2.286a.287.287 0 00-.286.286z\"/><circle cx=\"12\" cy=\"14.714\" r=\"2.571\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});