define("ember-svg-jar/inlined/crime-man-inmate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-man-inmate</title><path d=\"M23.16 19.045a16.176 16.176 0 00-7.284-2.669.073.073 0 00-.081.056 4.174 4.174 0 01-8.117.241.249.249 0 00-.285-.181A15.662 15.662 0 00.84 19.045a2.022 2.022 0 00-.65.794.249.249 0 00.227.354h23.166a.249.249 0 00.227-.354 2.022 2.022 0 00-.65-.794z\"/><rect y=\"22.193\" width=\"24\" height=\"1.792\" rx=\".25\" ry=\".25\"/><path d=\"M11.719 16.015a7.008 7.008 0 007-7V3.646a3.022 3.022 0 00-.284-1.284A2.951 2.951 0 0016.9.887a13.518 13.518 0 00-5.181-.872 13.525 13.525 0 00-5.182.872 2.976 2.976 0 00-1.818 2.759v5.369a7.008 7.008 0 007 7zm.5-2.25h-1a.75.75 0 010-1.5h1a.75.75 0 110 1.5zm-5.5-10.119a.982.982 0 01.591-.915 11.481 11.481 0 014.409-.716 11.481 11.481 0 014.409.716.982.982 0 01.591.915v1.808a.252.252 0 01-.1.2.249.249 0 01-.227.032 14.288 14.288 0 00-4.669-.675 14.286 14.286 0 00-4.668.675.249.249 0 01-.227-.032.253.253 0 01-.1-.2zm0 4.527a.252.252 0 01.132-.22 10.284 10.284 0 014.868-.938 10.318 10.318 0 014.869.936.249.249 0 01.131.22v.844a4.976 4.976 0 01-1.242 3.294.249.249 0 01-.2.085.252.252 0 01-.192-.11 3.988 3.988 0 00-6.662.052.25.25 0 01-.4.032 4.963 4.963 0 01-1.3-3.353z\"/><path d=\"M9.219 9.265h1a.75.75 0 000-1.5h-1a.75.75 0 000 1.5zM13.219 9.265h1a.75.75 0 000-1.5h-1a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});