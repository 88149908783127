define("ember-svg-jar/inlined/database-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-star</title><path d=\"M9.474 21.578a.252.252 0 00.246-.151c.07-.164.26-.6.764-1.759a.253.253 0 00-.063-.286c-.622-.557-1.2-1.061-1.43-1.281a.246.246 0 00-.15-.069c-2.817-.257-5.493-1.045-7.055-2.317a.252.252 0 00-.266-.032.249.249 0 00-.142.226v1.716c0 2.009 3.517 3.667 8.096 3.953zM19.215 11.738a.251.251 0 00-.1.313l.5 1.172a.252.252 0 00.23.151h.257a.249.249 0 00.249-.243c.018-.623.022-1.1.023-1.723a.251.251 0 00-.408-.2 6.533 6.533 0 01-.751.53zM1.378 13.125c0 1.772 2.737 3.271 6.526 3.8a.246.246 0 00.216-.076.249.249 0 00.063-.221 2.68 2.68 0 01.951-2.636.25.25 0 00-.134-.448c-2.874-.241-5.622-1.033-7.213-2.329a.252.252 0 00-.266-.032.249.249 0 00-.142.226zM10.879 9.125c-3.5 0-7.156-.833-9.093-2.411a.25.25 0 00-.408.194v1.717c0 2.21 4.254 4 9.5 4 .651 0 1.285-.029 1.9-.082a.253.253 0 00.209-.15l.6-1.382.018-.041.02-.039a2.8 2.8 0 012.5-1.557 2.728 2.728 0 012.374 1.35.252.252 0 00.158.11.248.248 0 00.189-.035 2.781 2.781 0 001.534-2.174V6.91a.25.25 0 00-.408-.194c-1.939 1.577-5.59 2.409-9.093 2.409z\"/><ellipse cx=\"10.879\" cy=\"4.125\" rx=\"9.5\" ry=\"4\"/><path d=\"M21.363 14.874h-2.5a.249.249 0 01-.229-.151l-1.345-3.117-.012-.027a1.294 1.294 0 00-2.318.027l-1.346 3.117a.249.249 0 01-.229.151h-2.5a1.231 1.231 0 00-1.166.759 1.272 1.272 0 00.32 1.4l2.194 1.955a.251.251 0 01.063.286l-1.223 2.815a1.253 1.253 0 00.314 1.451 1.278 1.278 0 001.506.166L16 21.951a.251.251 0 01.245 0l3.114 1.749a1.278 1.278 0 001.506-.166 1.254 1.254 0 00.315-1.45l-1.224-2.816a.251.251 0 01.063-.286l2.213-1.972a1.264 1.264 0 00.3-1.378 1.234 1.234 0 00-1.169-.758z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});