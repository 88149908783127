define("ember-svg-jar/inlined/beach-parasol-water-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beach-parasol-water-1</title><path d=\"M2.483 11.9a1 1 0 00.517.563 1.014 1.014 0 00.432.1.991.991 0 00.336-.059L5.175 12a.959.959 0 00.6-.616l.36-1.572a.25.25 0 01.4-.142l1.274.988a.953.953 0 00.858.094l2.393-.856a.252.252 0 01.32.152l2.256 6.311a.249.249 0 01-.191.33 7.606 7.606 0 00-5.4 3.328.249.249 0 00.125.354 1.293 1.293 0 00.486.1 2.685 2.685 0 001.83-1 2.581 2.581 0 012-1.006 2.505 2.505 0 012.255 1.415l.012.022a1.175 1.175 0 00.975.573 1.788 1.788 0 001.374-.759 2.5 2.5 0 014.287-.08l.012.017c.067.09.68.9 1.267.81a2.47 2.47 0 011.011.081A.25.25 0 0024 20.3V17a.5.5 0 00-.5-.5h-7.513a.249.249 0 01-.235-.166L13.261 9.37a.25.25 0 01.152-.319l2.4-.858a.959.959 0 00.6-.616l.359-1.572a.253.253 0 01.16-.18.249.249 0 01.237.038l1.275.989a.967.967 0 00.857.094l1.412-.506a1 1 0 00.605-1.277l-.169-.472a5.551 5.551 0 00-4.494-3.576 13.082 13.082 0 00-5.935.446.25.25 0 01-.3-.156l-.272-.742a1 1 0 00-1.882.674l.265.741a.251.251 0 01-.136.314 13.113 13.113 0 00-4.872 3.42 5.55 5.55 0 00-1.208 5.613z\"/><path d=\"M1.417 24a4.315 4.315 0 01-.55-.036 1 1 0 01.265-1.983c1.674.218 3.183-1.627 3.2-1.644a1.042 1.042 0 011.638.091 3.465 3.465 0 002.685 1.538 4.191 4.191 0 003.026-1.6 1.047 1.047 0 011.714.16 2.725 2.725 0 002.336 1.44 3.265 3.265 0 002.674-1.51 1.042 1.042 0 011.718-.028c.009.012 1.121 1.725 2.746 1.513a1 1 0 01.265 1.983 4.519 4.519 0 01-3.691-1.261.249.249 0 00-.333 0 5.028 5.028 0 01-3.379 1.3 4.473 4.473 0 01-3.189-1.3.249.249 0 00-.331-.016 5.687 5.687 0 01-3.556 1.321 5.085 5.085 0 01-3.428-1.383.251.251 0 00-.328-.006A5.6 5.6 0 011.417 24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});