define("ember-svg-jar/inlined/marine-mammal-seal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>marine-mammal-seal</title><path d=\"M21.625 20.37a15.077 15.077 0 01-2.678-3.594 21.375 21.375 0 01-1.954-8.457l3.307.661a1 1 0 10.392-1.96l-2.142-.429 1.893-.946a1 1 0 10-.894-1.79l-2.584 1.293v-.7A5 5 0 007.02 4.5c0 .191 0 .408.005.648L4.444 3.855a1 1 0 00-.894 1.79l1.893.946L3.3 7.02a1 1 0 00-.783 1.18 1.009 1.009 0 001.176.784l3.317-.667a20.966 20.966 0 01-1.957 8.459 15.077 15.077 0 01-2.678 3.594C1.577 21.226 1 21.845 1 22.5 1 24 2.859 24 4.5 24c2.562 0 4.338-3.012 4.338-4.25a.75.75 0 011.5 0 5.929 5.929 0 01-1.75 3.735 11.554 11.554 0 006.7.037A6.037 6.037 0 0113.5 19.75a.75.75 0 011.5 0c0 1.337 1.849 4.25 4.5 4.25 1.641 0 3.5 0 3.5-1.5 0-.655-.577-1.274-1.375-2.13zM9.5 5.5a1 1 0 111-1 1 1 0 01-1 1zm3.351 1.354l-.5.5a.5.5 0 01-.707 0l-.5-.5A.5.5 0 0111.5 6h1a.5.5 0 01.354.854zM14.5 5.5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});