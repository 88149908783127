define("ember-svg-jar/inlined/multiple-actions-chat.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-chat</title><ellipse cx=\"14.013\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M10.052 10.142A.5.5 0 009.96 9.5 6.022 6.022 0 000 13.333.6.6 0 00.6 14H9a.5.5 0 00.5-.5V12a3.43 3.43 0 01.552-1.858z\"/><ellipse cx=\"5.513\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M24 12a2 2 0 00-2-2h-9a2 2 0 00-2 2v7a2 2 0 002 2h.75a.25.25 0 01.25.25V23a1.01 1.01 0 001 1 1 1 0 00.625-.219l3.407-2.726a.247.247 0 01.156-.055H22a2 2 0 002-2zm-5.875 7.219L16.406 20.6a.25.25 0 01-.406-.2V20a1 1 0 00-1-1h-1.75a.25.25 0 01-.25-.25v-6.5a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v6.5a.249.249 0 01-.25.25h-3a1 1 0 00-.625.219z\"/><path d=\"M15 15h5a.75.75 0 000-1.5h-5a.75.75 0 000 1.5zM18.75 16.75A.75.75 0 0018 16h-3a.75.75 0 000 1.5h3a.75.75 0 00.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});