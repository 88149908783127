define("ember-svg-jar/inlined/deer-jump", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>deer-jump</title><path d=\"M18.5 10H21a1.571 1.571 0 001.5-1.766.5.5 0 00-.209-.407L19.57 5.884a3.477 3.477 0 00-2.032-.65H16.5a2.418 2.418 0 00-.569.066l-.642-1.368 1.436-1.51a1 1 0 00-1.45-1.377l-1.61 1.694H13.5V1.213a1 1 0 00-2 0v1.526h-.1a1 1 0 01-.939-.656L9.939.657a1 1 0 10-1.878.686l.52 1.425A3.009 3.009 0 0011.4 4.739h2.059l.894 1.9A3.688 3.688 0 0014 8.234c0 .872 0 2.192-.711 2.547l-6.831 3.188-3.321-.949a.5.5 0 00-.437.08.5.5 0 00-.2.4V15a1.821 1.821 0 002 2h1v1.5c0 1.451 0 2.5-3.5 2.5a.5.5 0 00-.5.5A2.5 2.5 0 004 24c3.3 0 5.311-3.142 5.822-4.043a.244.244 0 01.115-.105l8.211-3.687a.25.25 0 01.352.228v.107A2.5 2.5 0 0021 19a.5.5 0 00.5-.5v-3.38a2.5 2.5 0 00-3.377-2.341l-.286.107a.255.255 0 01-.23-.029.252.252 0 01-.107-.206V11c0-.72.28-1 1-1zM17 7.5a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});