define("ember-svg-jar/inlined/keyboard-shift-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-shift-alternate</title><path d=\"M19.174 0H4.826A4.832 4.832 0 000 4.822V19.17A4.832 4.832 0 004.826 24h14.348A4.832 4.832 0 0024 19.17V4.822A4.832 4.832 0 0019.174 0zM22 19.17A2.83 2.83 0 0119.174 22H4.826A2.829 2.829 0 012 19.17V4.822A2.828 2.828 0 014.826 2h14.348A2.829 2.829 0 0122 4.822z\"/><path d=\"M12.376 4.926a.5.5 0 00-.753 0l-5.9 6.741a.5.5 0 00.377.833h3.149a.25.25 0 01.25.25V19a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-6.25a.25.25 0 01.25-.25H17.9a.5.5 0 00.376-.829z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});