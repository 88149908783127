define("ember-svg-jar/inlined/shell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shell</title><path d=\"M20.234 11.52A6.5 6.5 0 008.882 7.857a.25.25 0 00.2.415 10.019 10.019 0 019.852 6 .25.25 0 00.417.065 3.66 3.66 0 00.883-2.817z\"/><path d=\"M9.755 9.75a8.508 8.508 0 00-8.51 8.5 18.226 18.226 0 00.543 3.475 2 2 0 001.944 1.525h9.1a5.365 5.365 0 005.4-5.712A8.555 8.555 0 009.755 9.75zM22.534.835a.5.5 0 00-.544-.009l-2.742 1.712a4.1 4.1 0 00-1.357-.285 4.187 4.187 0 00-3.41 1.547.25.25 0 00.167.406 8.024 8.024 0 016 3.873.247.247 0 00.17.118.253.253 0 00.2-.051 2.365 2.365 0 00.882-2.119 4.065 4.065 0 00-.251-1.07l1.1-3.622a.5.5 0 00-.215-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});