define("ember-svg-jar/inlined/fruit-orange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fruit-orange</title><path d=\"M1.411 12.485a9.5 9.5 0 008.941-8.94.5.5 0 00-.529-.53 9.5 9.5 0 00-8.94 8.942.5.5 0 00.528.528z\"/><path d=\"M16.785 5.554a.5.5 0 00-.667.471v3.417a.75.75 0 01-1.5 0V2.5a.5.5 0 01.5-.5h2.75a1 1 0 000-2h-8a1 1 0 000 2h2.25a.5.5 0 01.5.5v2.127a.5.5 0 01-.421.494.792.792 0 00-.665.578 11.044 11.044 0 01-7.055 7.691.5.5 0 00-.334.436 9.4 9.4 0 00-.025.674 9.5 9.5 0 1012.667-8.946z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});