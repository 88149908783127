define("ember-svg-jar/inlined/single-man-circle-alternate.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-circle-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm6.878 18.541a.5.5 0 01-.554.117c-1.036-.428-3.289-1.248-4-1.51a.251.251 0 01-.164-.234v-1.739a.249.249 0 01.119-.212 3.072 3.072 0 001.305-2.614.249.249 0 01.121-.2 1.252 1.252 0 00.483-1.107 1.328 1.328 0 00-.4-1.049.25.25 0 01-.081-.27 5.825 5.825 0 00.36-3.045c-.28-1.122-1.884-1.624-3.351-1.624-1.3 0-2.708.394-3.207 1.266a.249.249 0 01-.228.125 1.181 1.181 0 00-.965.34c-.616.663-.268 2.028-.016 2.938a.249.249 0 01-.086.264 1.316 1.316 0 00-.41 1.056 1.253 1.253 0 00.483 1.108.25.25 0 01.12.2 3.074 3.074 0 001.3 2.613.249.249 0 01.119.212v1.739a.248.248 0 01-.164.234c-.693.256-2.919 1.07-3.982 1.516a.5.5 0 01-.556-.116 9.5 9.5 0 1113.748-.007z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});