define("ember-svg-jar/inlined/cellular-network-wifi-lte", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cellular-network-wifi-lte</title><path d=\"M12 8.279a6.018 6.018 0 00-4.791 2.349 1 1 0 101.58 1.225 4.06 4.06 0 016.42 0 1 1 0 101.58-1.225A6.017 6.017 0 0012 8.279z\"/><path d=\"M17.715 8.875a1 1 0 101.554-1.257 9.349 9.349 0 00-14.555.009A1 1 0 106.27 8.882a7.352 7.352 0 0111.445-.007z\"/><path d=\"M21.8 4.747a12.505 12.505 0 00-19.6-.013 1 1 0 101.565 1.243 10.5 10.5 0 0116.469.011A1 1 0 0021.8 4.747zM8.518 22h-2.5a.5.5 0 01-.5-.5V15a1 1 0 00-2 0v6.5a2.5 2.5 0 002.5 2.5h2.5a1 1 0 000-2zM17.018 16h2.5a1 1 0 000-2h-2.5a2.5 2.5 0 00-2.5 2.5v5a2.5 2.5 0 002.5 2.5h2.5a1 1 0 000-2h-2.5a.5.5 0 01-.5-.5V20h1.5a1 1 0 000-2h-1.5v-1.5a.5.5 0 01.5-.5zM13.518 14h-4a1 1 0 000 2h1v7a1 1 0 002 0v-7h1a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});