define("ember-svg-jar/inlined/settings-human", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>settings-human</title><path d=\"M23.25 10.313a10.308 10.308 0 00-10.3-10.3C5.225.017 3.246 6.368 1.5 11.971c-.229.736-.458 1.471-.7 2.2a1 1 0 00.948 1.316H3.5a.25.25 0 01.25.25v1.494a3.864 3.864 0 003.86 3.861.139.139 0 01.14.139v1.756a1 1 0 002 0v-2.9a1 1 0 00-1-1H7.61a1.863 1.863 0 01-1.86-1.861v-2.744a1 1 0 00-1-1H3.465a.25.25 0 01-.239-.325l.184-.591C5.174 6.906 6.7 2.017 12.953 2.017a8.306 8.306 0 018.3 8.3A8.442 8.442 0 0117.659 17a1 1 0 00-.409.806v5.173a1 1 0 002 0V18.42a.254.254 0 01.091-.193 10.365 10.365 0 003.909-7.914z\"/><path d=\"M16.046 12.143a1.392 1.392 0 001.328-2.306l-.686-.737a.169.169 0 010-.228l.686-.74a1.393 1.393 0 00-1.329-2.307l-.981.228a.157.157 0 01-.191-.113l-.295-.968a1.387 1.387 0 00-2.656 0l-.295.968a.16.16 0 01-.19.113l-.983-.228a1.393 1.393 0 00-1.328 2.303l.687.74a.171.171 0 010 .228l-.687.741a1.392 1.392 0 001.329 2.306l.982-.227a.156.156 0 01.19.112l.295.968a1.387 1.387 0 002.656 0l.295-.968a.161.161 0 01.191-.112zm-2.8-1.661a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.496 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});