define("ember-svg-jar/inlined/drawer-play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drawer-play</title><path d=\"M4.51 12.5a1 1 0 001-1L5.5 2.25a.247.247 0 01.073-.176.252.252 0 01.18-.074h12.494a.25.25 0 01.25.25l.003 9.25a1 1 0 001 1 1 1 0 001-1V2a2 2 0 00-2-2h-13a2 2 0 00-2 2l.007 9.5a1 1 0 001.003 1z\"/><path d=\"M10.774 4.657a1.153 1.153 0 00-1.136-.014 1.12 1.12 0 00-.582.978v5.258a1.12 1.12 0 00.582.978 1.159 1.159 0 001.136-.013l4.5-2.629a1.113 1.113 0 000-1.929zM23.868 16l-1.75-3a1 1 0 10-1.728 1l1.164 2H17.5a1.5 1.5 0 00-1.5 1.5 1.5 1.5 0 01-1.5 1.5h-5A1.5 1.5 0 018 17.5 1.5 1.5 0 006.5 16H2.454l1.164-2a1 1 0 10-1.728-1L.14 16a1.414 1.414 0 00-.14.5v6A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-6a1.255 1.255 0 00-.132-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});