define("ember-svg-jar/inlined/appliances-microwave-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>appliances-microwave-1</title><path d=\"M24 4.75a3 3 0 00-3-3H3a3 3 0 00-3 3v12a3 3 0 001.715 2.71.5.5 0 01.285.452V21a1.25 1.25 0 002.5 0v-.75a.5.5 0 01.5-.5h14a.5.5 0 01.5.5V21a1.25 1.25 0 002.5 0v-1.088a.5.5 0 01.285-.452A3 3 0 0024 16.75zm-8 11.5a1 1 0 01-1 1H3.5a1 1 0 01-1-1v-11a1 1 0 011-1H15a1 1 0 011 1zm3.5 0a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm0-5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});