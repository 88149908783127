define("ember-svg-jar/inlined/locker-room-hanger-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>locker-room-hanger-man</title><circle cx=\"19.75\" cy=\"3.5\" r=\"2.5\"/><path d=\"M21.749 7.5h-4a2 2 0 00-2 2v5a1 1 0 001 1h.75a.25.25 0 01.25.25V23a1 1 0 001 1h2a1 1 0 001-1v-7.25A.25.25 0 0122 15.5h.751a1 1 0 001-1v-5a2 2 0 00-2.002-2zM13 11H3.605a.25.25 0 01-.1-.48L10.5 7.585a.5.5 0 01.419.015l2.756 1.392a.985.985 0 001.311-.411.985.985 0 00-.41-1.349 652.345 652.345 0 00-2.687-1.382.251.251 0 01-.137-.224V4.8a1 1 0 00-1-1 .9.9 0 11.644-1.535A1 1 0 0012.8.845 2.932 2.932 0 009.891.122a2.842 2.842 0 00-1.907 1.853A2.909 2.909 0 009.272 5.4a.25.25 0 01-.027.445L1.65 9.136A1.975 1.975 0 002.231 13H13a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});