define("ember-svg-jar/inlined/smart-watch-circle-sound", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-sound</title><path d=\"M12 3.5a8.5 8.5 0 108.5 8.5A8.5 8.5 0 0012 3.5zm.583 11.5a.5.5 0 01-.5.5h-.5A3.5 3.5 0 018.5 13.633a.25.25 0 00-.22-.133h-1.2a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h1.2a.252.252 0 00.221-.134A3.5 3.5 0 0111.583 8.5h.5a.5.5 0 01.5.5zm1.361-.278a.5.5 0 01-.075-.7 3.2 3.2 0 000-4.039.5.5 0 01.778-.628 4.184 4.184 0 010 5.3.5.5 0 01-.703.067zm2.113 1.592a.5.5 0 11-.777-.628 5.83 5.83 0 000-7.372.5.5 0 11.777-.628 6.821 6.821 0 010 8.628zM7.379 3.051a.125.125 0 00.135.014 9.98 9.98 0 018.972 0 .125.125 0 00.177-.144l-.68-2.55A.5.5 0 0015.5 0h-7a.5.5 0 00-.483.371l-.68 2.551a.125.125 0 00.042.129z\"/><path d=\"M16.663 21.078a.125.125 0 00-.177-.144 9.972 9.972 0 01-8.972 0 .125.125 0 00-.177.144l.68 2.551A.5.5 0 008.5 24h7a.5.5 0 00.483-.371z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});