define("ember-svg-jar/inlined/video-game-kirby-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-kirby-2</title><path d=\"M9.094 20.34a10.484 10.484 0 01-4.113-2.289.5.5 0 00-.807.205 2.992 2.992 0 00-.107.387l-.526 2.5a1.8 1.8 0 00-.035.352 1.752 1.752 0 001.75 1.75 1.79 1.79 0 00.612-.114l2.374-1.152a3.011 3.011 0 001.1-.85.5.5 0 00-.251-.794zM19.933 18.643a2.992 2.992 0 00-.107-.387.5.5 0 00-.807-.205 10.484 10.484 0 01-4.113 2.289.5.5 0 00-.251.794 3 3 0 001.1.85l2.374 1.152a1.79 1.79 0 00.612.114 1.752 1.752 0 001.75-1.75 1.8 1.8 0 00-.035-.352z\"/><path d=\"M23.9 2.195A1 1 0 0022.662.81l-5.148 1.838a1 1 0 01-.855-.087 9.1 9.1 0 00-9.318 0 1 1 0 01-.855.087L1.338.81A1 1 0 00.105 2.195L3.038 8.12a1 1 0 01.09.61A9.11 9.11 0 003 10.25a9 9 0 1017.872-1.52.989.989 0 01.09-.61zM8.751 8.75v-1.5a.75.75 0 111.5 0v1.5a.75.75 0 01-1.5 0zm3.25 7a3 3 0 01-3-3 .5.5 0 01.5-.5h5a.5.5 0 01.5.5 3 3 0 01-3.001 3zm3.25-7a.75.75 0 01-1.5 0v-1.5a.75.75 0 111.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});