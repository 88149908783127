define("ember-svg-jar/inlined/audio-document-aac", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-document-aac</title><path d=\"M7 9.625a.877.877 0 00-.875.875v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V10.5A.877.877 0 007 9.625z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM9.125 15a.625.625 0 01-1.25 0v-2.125a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0v-4.375a2.211 2.211 0 011.9-2.238 2.127 2.127 0 012.35 2.113zm5 0a.625.625 0 01-1.25 0v-2.125a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0v-4.375a2.211 2.211 0 011.9-2.238 2.127 2.127 0 012.35 2.113zm4.375-.625a.625.625 0 010 1.25 3.625 3.625 0 010-7.25.625.625 0 010 1.25 2.375 2.375 0 000 4.75z\"/><path d=\"M12 9.625a.877.877 0 00-.875.875v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V10.5A.877.877 0 0012 9.625z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});