define("ember-svg-jar/inlined/single-neutral-actions-share-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-share-1</title><path d=\"M18.5 10.328l-.628-.23a.6.6 0 01-.138-.311 2.573 2.573 0 01.058-1.3 5.618 5.618 0 001.466-4.314C19.256 1.756 17.677 0 15.5 0s-3.756 1.756-3.756 4.174a5.605 5.605 0 001.451 4.3 2.453 2.453 0 01.073 1.316.615.615 0 01-.135.309l-.631.232c-2.447.9-4.215 1.55-4.754 2.626a7.139 7.139 0 00-.6 1.947.249.249 0 00.247.292h4.714a1.991 1.991 0 011.955 1.604.25.25 0 00.245.2H23.5a.5.5 0 00.5-.5 9.173 9.173 0 00-.748-3.546c-.539-1.076-2.307-1.725-4.752-2.626zM1 16.387a1 1 0 00-1 1V23a1 1 0 002 0v-5.613a1 1 0 00-1-1zM12.5 20.193h-7a.348.348 0 01-.322-.386.371.371 0 01.371-.371H7.5a.887.887 0 00.819-1.236 1.9 1.9 0 00-1.813-1.006l-3-.032a.553.553 0 00-.358.145.5.5 0 00-.148.354v5.032a.5.5 0 00.5.5h8a2.04 2.04 0 002-1.924v-.2a.971.971 0 00-1-.876z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});