define("ember-svg-jar/inlined/camera-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camera-small</title><path d=\"M21.5 2H14a1.5 1.5 0 00-1.5 1.5v1.25a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25V4.5A4.505 4.505 0 006.5 0h-2A4.505 4.505 0 000 4.5v15A4.505 4.505 0 004.5 24h3A1.5 1.5 0 009 22.5v-9.75a.25.25 0 01.25-.25h.25A1.5 1.5 0 0011 11V8.25a.25.25 0 01.25-.25h1a.25.25 0 01.25.25V9.5A1.5 1.5 0 0014 11h7.5A2.5 2.5 0 0024 8.5v-4A2.5 2.5 0 0021.5 2zm-16 6A2.5 2.5 0 118 5.5 2.5 2.5 0 015.5 8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});