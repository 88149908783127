define("ember-svg-jar/inlined/data-file-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-download</title><path d=\"M6.5 24A6.5 6.5 0 100 17.5 6.508 6.508 0 006.5 24zm-2.45-6.217a.5.5 0 01.45-.283h.876a.125.125 0 00.124-.121V14.5a1 1 0 012 0v2.875a.126.126 0 00.124.125H8.5a.5.5 0 01.391.812l-2 2.5a.517.517 0 01-.782 0l-2-2.5a.5.5 0 01-.059-.529z\"/><path d=\"M21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.275a.247.247 0 00.242.25c.366.007 1.038.034 1.485.082A.245.245 0 008 9.359V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.859a.244.244 0 00-.24.21 7.956 7.956 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.414A2 2 0 0023.414 3z\"/><path d=\"M12.5 11.8l1.438-2.03a.25.25 0 01.332-.071l1.507.905A1 1 0 0017 10.451a.9.9 0 00.105-.123l2.688-3.734a1 1 0 10-1.624-1.168l-2.016 2.8a.25.25 0 01-.331.068L14.3 7.387a1 1 0 00-1.33.28l-2.042 2.884A.243.243 0 0011 10.9a8.011 8.011 0 011.129.932.243.243 0 00.371-.032z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});