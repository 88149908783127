define("ember-svg-jar/inlined/water-fountain-sink-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-fountain-sink-1</title><path d=\"M19.112 7.466a5.883 5.883 0 00-1.089 2.284 1.5 1.5 0 003 0 5.883 5.883 0 00-1.089-2.284.518.518 0 00-.822 0z\"/><path d=\"M23.539 17.511A1 1 0 0022.678 16h-5.905a.5.5 0 01-.5-.5v-.25a1 1 0 011-1h3.25a1 1 0 000-2h-3.25a3 3 0 00-3 3v.25a.5.5 0 01-.5.5h-.25a.5.5 0 01-.5-.5V4.75a2.75 2.75 0 015.5 0v.75a1 1 0 002 0v-.75a4.75 4.75 0 00-9.5 0V15.5a.5.5 0 01-.5.5h-.25a.5.5 0 01-.5-.5v-.25a3 3 0 00-3-3h-3.25a1 1 0 100 2h3.25a1 1 0 011 1v.25a.5.5 0 01-.5.5H1.257a.937.937 0 00-.809 1.409 13.461 13.461 0 0023.091.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});