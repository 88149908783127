define("ember-svg-jar/inlined/browser-gauge-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>browser-gauge-1</title><path d=\"M6.122 17.838H4.163a.735.735 0 000 1.469h1.959a.735.735 0 100-1.469zM7.278 15l-1.7-.979a.735.735 0 10-.735 1.272l1.7.98A.735.735 0 007.278 15zM8.718 11.417a.735.735 0 00-1.273.735l.98 1.7a.735.735 0 001.275-.738zM12 13.428a.735.735 0 00.735-.735v-1.958a.735.735 0 00-1.47 0v1.958a.735.735 0 00.735.735zM19.156 15.289a.735.735 0 00-.734-1.274l-1.7.979a.735.735 0 00.368 1.371.726.726 0 00.366-.1zM19.839 17.836H17.88a.735.735 0 000 1.469h1.959a.735.735 0 000-1.469zM15.5 12.137l-4.277 4.273a2.726 2.726 0 00-.692 1.673v.489a.774.774 0 00.96.788l.528-.106a2.02 2.02 0 001.324-1.1l2.357-5.885a.123.123 0 00-.2-.132z\"/><path d=\"M24 2.939A2.942 2.942 0 0021.061 0H2.939A2.942 2.942 0 000 2.939v18.122A2.942 2.942 0 002.939 24h18.122A2.942 2.942 0 0024 21.061zm-22.041 0a.98.98 0 01.98-.98H7.25a.25.25 0 01.25.25V5.25a.25.25 0 01-.25.25H2.209a.25.25 0 01-.25-.25zm20.082 18.122a.98.98 0 01-.98.98H2.939a.98.98 0 01-.98-.98V7.75a.25.25 0 01.25-.25h19.582a.25.25 0 01.25.25zm0-15.811a.25.25 0 01-.25.25H9.75a.25.25 0 01-.25-.25V2.209a.25.25 0 01.25-.25h11.311a.98.98 0 01.98.98z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});