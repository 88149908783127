define("ember-svg-jar/inlined/video-game-mario-turtle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-mario-turtle</title><path d=\"M1.5 16.25h10a2.5 2.5 0 002.5-2.5V8.844a1 1 0 00-.344-.755 7.411 7.411 0 00-5.313-1.828 7.881 7.881 0 00-7.343 8v1.489a.5.5 0 00.5.5zM20.25 5.25v-1a.75.75 0 00-1.5 0v1a.75.75 0 001.5 0z\"/><path d=\"M19.5.75a4.505 4.505 0 00-4.5 4.5v1a1 1 0 002 0v-1a2.5 2.5 0 113.57 2.25 1 1 0 00-.57.9v8.1a1.752 1.752 0 01-1.75 1.75H17a.25.25 0 01-.2-.4 5.975 5.975 0 001.2-3.6v-1.5a2.731 2.731 0 00-1.886-2.932.5.5 0 00-.614.487v3.445a4 4 0 01-4 4H.5a.509.509 0 00-.5.519 1.947 1.947 0 001.9 1.939.25.25 0 01.156.408l-.828.993a1 1 0 001.536 1.282l1.9-2.281a1 1 0 01.768-.36H12a5.952 5.952 0 00.86-.062 1 1 0 01.912.351l1.96 2.352a1 1 0 101.536-1.282l-.791-.949a.25.25 0 01.192-.41h1.581A3.755 3.755 0 0022 16.5V9.481a1 1 0 01.362-.77A4.492 4.492 0 0019.5.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});