define("ember-svg-jar/inlined/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>delete</title><path d=\"M20.485 3.534a12 12 0 00-16.97 16.98 12.136 12.136 0 0016.684.277 12.072 12.072 0 002.471-3.26 11.936 11.936 0 001.2-3.744 12.238 12.238 0 00.13-1.763 11.932 11.932 0 00-3.515-8.49zM5.292 5.292a9.623 9.623 0 0112.23-1.023.264.264 0 01.031.4L4.667 17.561a.265.265 0 01-.4-.031A9.5 9.5 0 015.292 5.292zm13.457 13.464a9.518 9.518 0 01-12.231 1.023.264.264 0 01-.032-.4L19.373 6.486a.265.265 0 01.4.031 9.5 9.5 0 01-1.023 12.239z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});