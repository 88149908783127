define("ember-svg-jar/inlined/style-three-pin-kayak", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-kayak</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm-1.707 7.741l1.615-.969a.977.977 0 01.922-.041.251.251 0 00.282-.05l2.534-2.535a.5.5 0 01.708.708l-2.535 2.534a.249.249 0 00-.049.282.983.983 0 01-.041.922l-.971 1.616a.992.992 0 01-.734.478 1.046 1.046 0 01-.124.008.994.994 0 01-.706-.293l-1.1-1.1a1 1 0 01.194-1.565zM17 15.5a3.515 3.515 0 01-2.351-.83.219.219 0 00-.3 0A3.515 3.515 0 0112 15.5a3.513 3.513 0 01-2.351-.83.219.219 0 00-.3 0A3.515 3.515 0 017 15.5a.5.5 0 010-1c.826 0 1.81-.354 2.017-1.129a.52.52 0 01.966 0c.207.775 1.19 1.129 2.017 1.129s1.81-.354 2.017-1.129a.52.52 0 01.966 0c.207.775 1.19 1.129 2.017 1.129a.5.5 0 010 1zm3-5.5a3 3 0 01-3 3h-.654a.25.25 0 01-.232-.157 1.742 1.742 0 00-1.606-1.111 1.723 1.723 0 00-1.623 1.107.25.25 0 01-.233.161h-1.306a.25.25 0 01-.232-.157 1.742 1.742 0 00-1.606-1.111 1.723 1.723 0 00-1.623 1.107.25.25 0 01-.233.161H7a3 3 0 01-.6-5.94.5.5 0 01.6.49V9a.5.5 0 00.5.5h1.384a.252.252 0 01.217.125 1.82 1.82 0 00.291.389l1.1 1.093a1.983 1.983 0 001.409.587 2.08 2.08 0 00.249-.015 1.984 1.984 0 001.47-.956l.661-1.1a.25.25 0 01.209-.123h2.01A.5.5 0 0017 9V7.55a.5.5 0 01.6-.49A3 3 0 0120 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});