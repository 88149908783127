define("ember-svg-jar/inlined/christmas-santa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-santa</title><path d=\"M16.869 21.454a2.194 2.194 0 01-2.641-2.17 2.572 2.572 0 00-4.176-2 .5.5 0 01-.605 0 2.573 2.573 0 00-4.176 2 2.194 2.194 0 01-2.641 2.17.5.5 0 00-.357.9 5.512 5.512 0 007.1-.959.5.5 0 01.754 0 5.514 5.514 0 007.1.959.5.5 0 00-.357-.9zM23.544 4.662a3 3 0 00-4.3-.845.5.5 0 01-.649-.054A9.728 9.728 0 009.055 1.1C5.538 2.106 3.8 4.85 2.935 8.424a.5.5 0 01-.408.377A2.994 2.994 0 003 14.752h13.5a3 3 0 000-6 .628.628 0 01-.572-.369l-.3-.65A1.455 1.455 0 0117.2 5.7l.4.067a.5.5 0 01.417.5 2.938 2.938 0 00.443 1.583 3 3 0 105.088-3.18zm-7.044 6.09a1 1 0 010 2H3a1 1 0 010-2zm5.3-3.228a1.5 1.5 0 11.478-2.067 1.5 1.5 0 01-.478 2.067z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});