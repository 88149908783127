define("ember-svg-jar/inlined/professions-woman-nurse-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-woman-nurse-2</title><path d=\"M22.509 18.2a15.463 15.463 0 00-7.762-2.777.241.241 0 01-.213-.205.244.244 0 01.144-.259 6.7 6.7 0 001-.534.242.242 0 01.267 0 3.357 3.357 0 001.041.447 5.176 5.176 0 001.277.157 3.686 3.686 0 002.837-1.083.485.485 0 00-.224-.773C17 11.928 19.753 4.287 18.692 2.018 18.052.623 13.644 0 12 0h-.006c-1.63 0-6.05.631-6.686 2.018-.846 1.807 1.439 9.933-2.184 11.1a.485.485 0 00-.224.773 3.687 3.687 0 002.832 1.085 5.225 5.225 0 001.277-.156 3.383 3.383 0 001-.424.243.243 0 01.265 0 6.758 6.758 0 001.051.565.243.243 0 01-.069.464A15.465 15.465 0 001.492 18.2a2.867 2.867 0 00-1.129 2.3v2.53a.97.97 0 101.939 0V20.5a.945.945 0 01.366-.76c1.158-.885 3.739-2.326 8.352-2.509a.244.244 0 01.178.068.238.238 0 01.075.174v5.557a.727.727 0 101.454 0v-5.554a.238.238 0 01.073-.176.244.244 0 01.178-.068c4.613.183 7.194 1.624 8.353 2.509a.947.947 0 01.365.76v2.529a.97.97 0 101.939 0V20.5a2.865 2.865 0 00-1.126-2.3zM7.151 8.728v-.89a.242.242 0 01.21-.24 34.236 34.236 0 019.278 0 .242.242 0 01.21.24v.89a4.849 4.849 0 11-9.7 0zM16.714 2.9a.244.244 0 01.135.217v2.268a.24.24 0 01-.083.182.244.244 0 01-.19.059 36.24 36.24 0 00-9.151 0 .245.245 0 01-.191-.059.24.24 0 01-.083-.182V3.122a.245.245 0 01.135-.222 12 12 0 019.428 0z\"/><path d=\"M8.606 22.3a.727.727 0 000-1.454h-.485a.242.242 0 01-.242-.243v-.485a.728.728 0 00-1.455 0v.485a.243.243 0 01-.243.243H5.7a.727.727 0 100 1.454h.484a.243.243 0 01.243.243v.485a.728.728 0 001.455 0v-.485a.242.242 0 01.242-.243z\"/><circle cx=\"14.667\" cy=\"19.395\" r=\".97\"/><path d=\"M15.637 22.3a.97.97 0 00-1.94 0 .97.97 0 001.94 0z\"/><circle cx=\"13.94\" cy=\"8.747\" r=\".97\"/><circle cx=\"10.06\" cy=\"8.747\" r=\".97\"/><path d=\"M12.08 12.257a2.936 2.936 0 00.837-.121 2.308 2.308 0 00.423-.176.62.62 0 00-.295-1.165h-1.94a.62.62 0 00-.3 1.165 2.261 2.261 0 00.424.176 2.926 2.926 0 00.84.122z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});