define("ember-svg-jar/inlined/car-actions-music", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-actions-music</title><path d=\"M5.25 12.7h-4a.25.25 0 00-.25.25v.5a1.25 1.25 0 001.25 1.246h2a1.249 1.249 0 001.25-1.248v-.5a.25.25 0 00-.25-.248zM13.145 11.705a.252.252 0 00.191-.088 3.485 3.485 0 012.018-1.175l4.946-.923a.249.249 0 00.2-.245v-1.1a1.251 1.251 0 00-.366-.881l-1.085-1.079a.493.493 0 01-.111-.167L16.888.941A1.491 1.491 0 0015.5 0H5.172a1.493 1.493 0 00-1.393.939L1.838 5.772a.5.5 0 01-.11.167L.22 7.441a.744.744 0 00-.22.528v2.491a1.249 1.249 0 001.25 1.245zm-8.7-7.066l1-2.49a.25.25 0 01.232-.157h9.308a.25.25 0 01.232.157l1 2.49a.25.25 0 01-.232.342H4.679a.251.251 0 01-.207-.11.248.248 0 01-.024-.232zM23.277 11.214a2 2 0 00-1.645-.424l-6 1.12A2 2 0 0014 13.869v4.912a.25.25 0 01-.273.248 2.851 2.851 0 00-.227-.01A2.491 2.491 0 1016 21.51v-7.435a.249.249 0 01.2-.245l5.5-1.03a.252.252 0 01.2.053.249.249 0 01.091.192v3.741a.25.25 0 01-.273.248 2.447 2.447 0 00-.227-.01 2.491 2.491 0 102.5 2.49v-6.766a1.985 1.985 0 00-.714-1.534z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});