define("ember-svg-jar/inlined/style-two-pin-skull", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-two-pin-skull</title><path d=\"M12 0a9.511 9.511 0 00-9.5 9.5c0 6.544 6.537 12.487 8.541 14.153a1.5 1.5 0 001.917 0c2-1.666 8.542-7.609 8.542-14.154A9.511 9.511 0 0012 0zm6 11.5a3 3 0 01-2.143 2.875.5.5 0 00-.357.479v.646a1.5 1.5 0 01-1.2 1.47.251.251 0 01-.3-.245V15a.5.5 0 00-1 0v1.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V15a.5.5 0 00-1 0v1.725a.251.251 0 01-.3.245 1.5 1.5 0 01-1.2-1.47v-.646a.5.5 0 00-.357-.479A3 3 0 016 11.5V10a6 6 0 0112 0z\"/><circle cx=\"9\" cy=\"10.5\" r=\"1.5\"/><circle cx=\"15\" cy=\"10.5\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});