define("ember-svg-jar/inlined/bus-double", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bus-double</title><path d=\"M20.5 8.5A.5.5 0 0021 8V3.5A3.5 3.5 0 0017.5 0h-11A3.5 3.5 0 003 3.5V20a1.5 1.5 0 001.5 1.5.25.25 0 01.25.25V23a1 1 0 001 1h1a1 1 0 001-1v-1a.5.5 0 01.5-.5h7.5a.5.5 0 01.5.5v1a1 1 0 001 1h1a1 1 0 001-1v-1.25a.25.25 0 01.25-.25A1.5 1.5 0 0021 20v-4.5a1 1 0 00-.667-.943.5.5 0 01-.333-.471V9a.5.5 0 01.5-.5zM5 17.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5zm.5-3A.5.5 0 015 14V9a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v5a.5.5 0 01-.5.5zM6 6a.5.5 0 01-.5-.5v-1A.5.5 0 016 4h12a.5.5 0 01.5.5v1a.5.5 0 01-.5.5zm9 3a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5zm1 8.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});