define("ember-svg-jar/inlined/style-three-pin-factory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-factory</title><path d=\"M11.75 13h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM15.25 13h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5z\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM7.329 3.915A2.933 2.933 0 0110 2.5a2.555 2.555 0 012.1 1.3c.381.508.557.7.9.7a1.451 1.451 0 001.337-.6.76.76 0 011.011-.3.742.742 0 01.323.989A2.935 2.935 0 0113 6a2.553 2.553 0 01-2.1-1.3c-.381-.508-.557-.7-.9-.7a1.453 1.453 0 00-1.337.6.761.761 0 01-1.011.3.742.742 0 01-.323-.985zM17.5 15a1 1 0 01-1 1h-9a1 1 0 01-1-1V7a1 1 0 011-1h1.048a1 1 0 01.994.9l.341 3.4a.25.25 0 00.372.192l1.749-1a1 1 0 011.5.868.206.206 0 00.1.174.2.2 0 00.2.008l2.258-1.129a1 1 0 011.447.894z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});