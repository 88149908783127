define("ember-svg-jar/inlined/security-officer-luggage-reject", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>security-officer-luggage-reject</title><path d=\"M23.98 16a4.005 4.005 0 00-4-4h-1.16a.5.5 0 00-.47.31l-.64 1.61a.262.262 0 01-.23.16.251.251 0 01-.23-.16l-.65-1.61a.489.489 0 00-.46-.31H14.4a2 2 0 00-1.415.587L8.39 17.19a1.245 1.245 0 101.76 1.76l3.9-3.892a.25.25 0 01.427.177V23.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-7.75a.5.5 0 011 0v5.75a1.25 1.25 0 002.5 0z\"/><path d=\"M12.02 20.5h-8a.5.5 0 01-.5-.5v-6.227a.252.252 0 00-.166-.236 7.988 7.988 0 01-2.846-1.754.25.25 0 00-.415.121 2.488 2.488 0 00-.073.6v6a2.5 2.5 0 002.01 2.451.386.386 0 00-.01.045v.5a2.5 2.5 0 002.5 2.5h6a2.5 2.5 0 002.5-2.5 1 1 0 00-1-1zM15.183 6.5a.25.25 0 00-.25.25v.75a2.5 2.5 0 005 .012V6.52a.024.024 0 000-.014.02.02 0 00-.014-.006zM12.478 4.5a1 1 0 001 1h6.368a.5.5 0 00.475-.342l.33-.988a1 1 0 00-.707-1.286L14.91 1.625a.751.751 0 00-.894.965l.3.91h-.841a1 1 0 00-.997 1zM6.02 12a6 6 0 10-6-6 6.006 6.006 0 006 6zm0-10a3.951 3.951 0 012.02.567L2.587 8.02A3.954 3.954 0 012.02 6a4 4 0 014-4zm0 8A3.956 3.956 0 014 9.434l5.454-5.453A3.959 3.959 0 0110.02 6a4 4 0 01-4 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});