define("ember-svg-jar/inlined/file-code-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-sync</title><path d=\"M17.75 11.5V15a.75.75 0 001.5 0v-3.5a.75.75 0 10-1.5 0zM13 4.251a.75.75 0 00-.75.75V8.5a.75.75 0 001.5 0V5a.75.75 0 00-.75-.749zM20.25 8V5.5A1.251 1.251 0 0019 4.251h-1.5A1.251 1.251 0 0016.25 5.5V8a1.252 1.252 0 001.25 1.25H19A1.252 1.252 0 0020.25 8zm-1.5-.5a.25.25 0 01-.25.25H18a.25.25 0 01-.25-.25V6a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25z\"/><path d=\"M23.414 3L21 .587A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.278a.247.247 0 00.241.249 20.06 20.06 0 011.485.092A.246.246 0 008 9.371V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.121a.5.5 0 01.146.355V18a.5.5 0 01-.5.5h-6.858a.245.245 0 00-.241.21 7.935 7.935 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.415A2 2 0 0023.414 3zM11.77 18.6a1 1 0 00-1.256.582 4.036 4.036 0 01-1.278 1.931 4.162 4.162 0 01-5.656-.472.254.254 0 01.014-.348l1.716-1.716a.5.5 0 00-.353-.853H.5a.5.5 0 00-.5.5v4.457a.5.5 0 00.854.353l.974-.975a.244.244 0 01.353.009 6.085 6.085 0 0010.211-2.2 1 1 0 00-.622-1.268z\"/><path d=\"M12.691 11.773a.5.5 0 00-.545.109l-1.03 1.03a.244.244 0 01-.352-.007A6.1 6.1 0 00.567 15.13a1 1 0 001.88.683A4.02 4.02 0 013.718 13.9a4.157 4.157 0 015.638.435.256.256 0 01-.012.351L7.69 16.339a.5.5 0 00.353.853H12.5a.5.5 0 00.5-.5v-4.457a.5.5 0 00-.309-.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});