define("ember-svg-jar/inlined/headphones-cable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>headphones-cable</title><path d=\"M4.594 18.27a.494.494 0 01.248.191 2 2 0 001.658.883h2a2 2 0 002-2v-9a2 2 0 00-2-2h-2a2 2 0 00-1.657.882.5.5 0 01-.248.192 5.629 5.629 0 00-.863.386.25.25 0 01-.353-.312 8.75 8.75 0 0116.242 0 .25.25 0 01-.353.312 5.629 5.629 0 00-.863-.386.506.506 0 01-.248-.192 2 2 0 00-1.657-.882h-2a2 2 0 00-2 2v9a2 2 0 002 2h2a2 2 0 001.658-.883.49.49 0 01.248-.191 5.776 5.776 0 003.813-4.836 1 1 0 00.031-.246V10.75a10.75 10.75 0 00-21.5 0v2.438a1 1 0 00.031.246 5.776 5.776 0 003.813 4.836zM6.5 8.844a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v8a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5zm10 8a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5h1a.5.5 0 01.5.5z\"/><path d=\"M22.242 17.519a1 1 0 10-.829 1.82A1 1 0 0121 21.25H9.675a.5.5 0 01-.372-.166A1.746 1.746 0 008 20.5H5a.751.751 0 00-.688.45.5.5 0 01-.458.3H1a1 1 0 000 2h2.854a.5.5 0 01.458.3A.751.751 0 005 24h3a1.746 1.746 0 001.3-.584.5.5 0 01.372-.166H21a3 3 0 001.242-5.731z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});