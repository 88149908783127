define("ember-svg-jar/inlined/shopping-basket-arrow-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-basket-arrow-out</title><path d=\"M9.172 3.823H10.5a.25.25 0 01.25.25v3.421a1.25 1.25 0 002.5 0V4.073a.25.25 0 01.25-.25h1.328a.5.5 0 00.354-.854L12.354.141a.515.515 0 00-.708 0L8.818 2.969a.5.5 0 00.354.854zM20.5 15.5h-17a.5.5 0 00-.49.6l1.281 6.4A2.354 2.354 0 006.766 24h10.468a2.354 2.354 0 002.475-1.5l1.281-6.4a.5.5 0 00-.49-.6zM7.75 21v-2.5a.75.75 0 011.5 0V21a.75.75 0 01-1.5 0zm8.5 0a.75.75 0 01-1.5 0v-2.5a.75.75 0 011.5 0z\"/><path d=\"M21.5 12H19a.249.249 0 01-.218-.127.252.252 0 010-.252l1.787-2.978a1.25 1.25 0 10-2.144-1.286l-2.713 4.522A.251.251 0 0115.5 12h-7a.251.251 0 01-.215-.121L5.572 7.357a1.25 1.25 0 10-2.144 1.286l1.787 2.978a.252.252 0 010 .252A.249.249 0 015 12H2.5a.5.5 0 00-.5.5V14a.5.5 0 00.5.5h19a.5.5 0 00.5-.5v-1.5a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});