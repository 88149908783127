define("ember-svg-jar/inlined/beard-style-shave", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beard-style-shave</title><path d=\"M23 7.5h-1.5A.5.5 0 0121 7V4.5a1 1 0 00-1-1H4a1 1 0 00-1 1V7a.5.5 0 01-.5.5H1a1 1 0 00-1 1v7a1 1 0 001 1h1.5a.5.5 0 01.5.5v2.5a1 1 0 001 1h16a1 1 0 001-1V17a.5.5 0 01.5-.5H23a1 1 0 001-1v-7a1 1 0 00-1-1zM5.5 6a.5.5 0 01.5-.5h12.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5zM19 18a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h12.5a.5.5 0 01.5.5zm1.745-5.491l-1.484 1.114a.639.639 0 01-.764 0l-.7-.523a.5.5 0 00-.3-.1h-3.823a.5.5 0 00-.373.167 1.743 1.743 0 01-2.6 0 .5.5 0 00-.375-.167H6.5a.5.5 0 00-.3.1l-.7.523a.639.639 0 01-.764 0l-1.481-1.114a.636.636 0 010-1.018l1.484-1.114a.639.639 0 01.764 0l.7.523a.5.5 0 00.3.1h3.826a.5.5 0 00.373-.167 1.743 1.743 0 012.6 0 .5.5 0 00.373.167H17.5a.5.5 0 00.3-.1l.7-.523a.639.639 0 01.764 0l1.484 1.114a.636.636 0 010 1.018z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});