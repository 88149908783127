define("ember-svg-jar/inlined/baggage-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baggage-check</title><path d=\"M19.766 6.859a2.086 2.086 0 00.839-1.609 2.2 2.2 0 00-.88-1.649A11.43 11.43 0 0012 0C7.971 0 4.782 3.046 3.9 3.981a1.852 1.852 0 000 2.54c.882.933 4.071 3.979 8.1 3.979a11.364 11.364 0 007.766-3.641zM12 8.5c-2.912 0-5.372-2.087-6.37-3.072a.249.249 0 010-.356C6.63 4.085 9.09 2 12 2a9.5 9.5 0 016.372 3.073.251.251 0 010 .354A9.488 9.488 0 0112 8.5zM15.5 14.5a3 3 0 00-3-3h-1a3 3 0 00-3 3v9.25a.25.25 0 00.25.25h6.5a.25.25 0 00.25-.25zm-2 .25a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-.25a1 1 0 011-1h1a1 1 0 011 1zM7 15a1.5 1.5 0 00-1.5 1.5v6A1.5 1.5 0 007 24h.25a.25.25 0 00.25-.25v-8.5a.25.25 0 00-.25-.25zM17 24a1.5 1.5 0 001.5-1.5v-6A1.5 1.5 0 0017 15h-.25a.25.25 0 00-.25.25v8.5a.25.25 0 00.25.25z\"/><ellipse cx=\"12\" cy=\"5.25\" rx=\"3\" ry=\"2.966\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});