define("ember-svg-jar/inlined/laptop-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-download</title><path d=\"M2.5 8v8a1 1 0 002 0V8a1 1 0 011-1H8a1 1 0 001-1 1 1 0 00-1-1H5.5a3 3 0 00-3 3zM18.5 7a1 1 0 011 1v8a1 1 0 002 0V8a3 3 0 00-3-3H16a1 1 0 00-1 1 1 1 0 001 1zM6.74 24h10.52a7.076 7.076 0 006.714-4.842.5.5 0 00-.474-.658H16a.5.5 0 00-.5.5c0 .708-1.5 1.5-3.5 1.5s-3.5-.792-3.5-1.5a.5.5 0 00-.5-.5H.5a.5.5 0 00-.474.658A7.076 7.076 0 006.74 24z\"/><path d=\"M10.5 8.75a.25.25 0 01-.25.25H8.5a1 1 0 00-.708 1.707l3.5 3.5a1 1 0 001.415 0l3.5-3.5A1 1 0 0015.5 9h-1.75a.249.249 0 01-.25-.25V1.5a1.5 1.5 0 00-3 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});