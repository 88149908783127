define("ember-svg-jar/inlined/beacon-wireless-remote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beacon-wireless-remote</title><path d=\"M7.4 5.793a1 1 0 001.418 1.414 4.5 4.5 0 016.365 0A1 1 0 0016.6 5.793a6.5 6.5 0 00-9.193 0z\"/><path d=\"M6.7 4.2a7.5 7.5 0 0110.6 0 1 1 0 101.414-1.414 9.5 9.5 0 00-13.435 0A1 1 0 006.7 4.2zM17 9H7a2.5 2.5 0 00-2.5 2.5v10A2.5 2.5 0 007 24h10a2.5 2.5 0 002.5-2.5v-10A2.5 2.5 0 0017 9zm-7 7.5a2 2 0 112.889 1.789.25.25 0 00-.139.224V21a.75.75 0 11-1.5 0v-2.487a.25.25 0 00-.139-.224A2 2 0 0110 16.5zm2-3.749a3.765 3.765 0 00-3.76 3.761.75.75 0 01-1.5 0 5.261 5.261 0 1110.521 0 .75.75 0 01-1.5 0A3.766 3.766 0 0012 12.751z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});