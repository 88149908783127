define("ember-svg-jar/inlined/halloween-grim-reaper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>halloween-grim-reaper</title><path d=\"M22.814 21.639l-2.56-5.049a.5.5 0 00-.806-.121 3.359 3.359 0 01-.7.554.5.5 0 00-.188.655l2.467 4.865a1 1 0 00.892.548.982.982 0 00.451-.108 1 1 0 00.444-1.344zM18.922 13.9a1.9 1.9 0 00-1.9-1.9h-4.6a.5.5 0 01-.5-.5v-2a5 5 0 00-10 0v7a17.787 17.787 0 01-.576 4.339c-.261 1.105-.448 1.9.012 2.485C1.8 23.885 2.7 24 3.922 24a2.657 2.657 0 001.792-.6 1.676 1.676 0 011.2-.4h.012a1.672 1.672 0 011.2.4 2.661 2.661 0 001.792.6c1.376 0 2.205-.25 2.61-.787a2.123 2.123 0 00.057-1.946 17.4 17.4 0 01-.667-4.767.5.5 0 01.5-.5h4.6a1.9 1.9 0 001.9-1.9zm-9.75-3a2.25 2.25 0 01-4.5 0v-.861c0-.667.172-1.266 2.25-1.266s2.25.6 2.25 1.266z\"/><path d=\"M14.786 10.226a.5.5 0 00.446.274h1.12a.5.5 0 00.446-.726l-1.574-3.106a.5.5 0 01.148-.627 26.511 26.511 0 016.405-3.275 1 1 0 00-.07-1.889 13.419 13.419 0 00-8.49.733.5.5 0 01-.67-.221L12.12.548a1 1 0 10-1.783.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});