define("ember-svg-jar/inlined/religion-cao-dai-hands", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-cao-dai-hands</title><path d=\"M19.182 5.438a.752.752 0 000-.626 8.031 8.031 0 00-14.364 0 .752.752 0 000 .626 8.031 8.031 0 0014.364 0zM12 7.125a2 2 0 112-2 2 2 0 01-2 2zM23.67 6.093a1.644 1.644 0 00-1.457.262c-1.1.774-1.677 2.777-1.711 5.96a.25.25 0 01-.073.174l-.908.907a.25.25 0 01-.42-.233l.1-.452a1.647 1.647 0 00-.788-2.025 1.461 1.461 0 00-1.806.7 23.33 23.33 0 00-1.59 4.089.488.488 0 00-.021.142v3.508a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-.218a.252.252 0 01.074-.178c.653-.647 2.578-2.554 3.484-3.456A1.508 1.508 0 0024 14.21V6.562a.5.5 0 00-.33-.469zM3.583 20.625a.548.548 0 00-.583.5v2a.548.548 0 00.583.5h5.834a.548.548 0 00.583-.5v-2a.548.548 0 00-.583-.5zM14.583 20.625a.548.548 0 00-.583.5v2a.548.548 0 00.583.5h5.834a.548.548 0 00.583-.5v-2a.548.548 0 00-.583-.5zM8.5 19.625a.5.5 0 00.5-.5v-3.508a.488.488 0 00-.021-.142 23.33 23.33 0 00-1.59-4.089 1.459 1.459 0 00-1.806-.7 1.646 1.646 0 00-.789 2.02l.105.458a.25.25 0 01-.42.232l-.908-.907a.25.25 0 01-.073-.174c-.034-3.183-.61-5.186-1.711-5.96A1.644 1.644 0 00.33 6.093a.5.5 0 00-.33.469v7.606a1.5 1.5 0 00.494 1.113c.976.883 2.819 2.8 3.437 3.44a.254.254 0 01.069.173v.231a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});