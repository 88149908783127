define("ember-svg-jar/inlined/single-woman-actions-address.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-address</title><path d=\"M10.393 12.858a.5.5 0 00-.218-.733 7.414 7.414 0 00-2.969-.625 7.542 7.542 0 00-7.185 5.356.5.5 0 00.479.644h8.014a.5.5 0 00.5-.472 8.426 8.426 0 011.379-4.17zM17.424 10.5a6.753 6.753 0 00-1.761 13.316 7.088 7.088 0 001.588.184 6.751 6.751 0 001.735-.226 1 1 0 00-.513-1.933 4.873 4.873 0 01-2.362.027 4.723 4.723 0 01-3.484-3.494 4.8 4.8 0 01.947-4.133 4.722 4.722 0 013.8-1.739A4.921 4.921 0 0122 17.516v.812a.8.8 0 01-1.594 0V17.25a3.157 3.157 0 10-3.156 3.156 3.106 3.106 0 001.476-.377.249.249 0 01.312.062A2.793 2.793 0 0024 18.328v-.812a6.933 6.933 0 00-6.576-7.016zm-.174 7.9a1.157 1.157 0 111.156-1.156 1.158 1.158 0 01-1.156 1.162zM1.564 10.442a1 1 0 001.383-.3 5.093 5.093 0 00.454-.9.25.25 0 01.412-.082 4.727 4.727 0 006.786 0 .251.251 0 01.228-.071.255.255 0 01.184.153 5.093 5.093 0 00.454.9 1 1 0 001.681-1.082 4.561 4.561 0 01-.646-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248a4.573 4.573 0 01-.639 2.512 1 1 0 00.297 1.382zM7.206 8.6a2.7 2.7 0 01-2.741-2.352.5.5 0 01.324-.577 5.7 5.7 0 002.245-1.439.25.25 0 01.361 0 5.719 5.719 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.206 8.6z\"/><path d=\"M1.564 10.442a1 1 0 001.383-.3 5.093 5.093 0 00.454-.9.25.25 0 01.412-.082 4.727 4.727 0 006.786 0 .251.251 0 01.228-.071.255.255 0 01.184.153 5.093 5.093 0 00.454.9 1 1 0 001.681-1.082 4.561 4.561 0 01-.646-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248a4.573 4.573 0 01-.639 2.512 1 1 0 00.297 1.382zM7.206 8.6a2.7 2.7 0 01-2.741-2.352.5.5 0 01.324-.577 5.7 5.7 0 002.245-1.439.25.25 0 01.361 0 5.719 5.719 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.206 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});