define("ember-svg-jar/inlined/ice-cream-truck", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ice-cream-truck</title><path d=\"M22.5 12.624h-7.247a.249.249 0 01-.25-.25V8.735a.251.251 0 01.1-.2.248.248 0 01.22-.041 3.348 3.348 0 003.024-.6.249.249 0 01.31 0 3.364 3.364 0 002.094.735 3.4 3.4 0 002.895-1.6 1.7 1.7 0 00-.057-1.292 11.247 11.247 0 00-19.16 0 1.694 1.694 0 00-.056 1.291 3.386 3.386 0 004.98.869.251.251 0 01.308 0 3.373 3.373 0 002.094.731 3.1 3.1 0 00.925-.15.25.25 0 01.223.038.248.248 0 01.1.2v3.658a.249.249 0 01-.249.25h-2.748a1 1 0 100 2h2.249a.25.25 0 01.25.25v4.5a.249.249 0 01-.25.249H10.12a.249.249 0 01-.245-.2 6 6 0 00-4.2-4.535.249.249 0 01-.179-.239v-1.8a.251.251 0 01.175-.239l1.1-.346h.006a1.232 1.232 0 00.7-1.485 1.255 1.255 0 00-1.2-.9H1.5a.749.749 0 00-.749.75A2.59 2.59 0 003.266 13.2a.25.25 0 01.233.249v.985a.25.25 0 01-.221.248A5.9 5.9 0 00.4 15.823a1 1 0 001.2 1.6 4.227 4.227 0 012.4-.8 4 4 0 014 4 1 1 0 001 1h4.836a.252.252 0 00.246-.3 3.534 3.534 0 01-.074-.7 4 4 0 018 0 3.592 3.592 0 01-.073.7.251.251 0 00.245.3h.32a1.5 1.5 0 001.5-1.5v-6a1.5 1.5 0 00-1.5-1.499zm-4.012-9.46a.25.25 0 01.3-.04 9.752 9.752 0 013.347 3.126.249.249 0 01-.035.317 1.99 1.99 0 01-2.792-.105.245.245 0 01-.061-.153 6.565 6.565 0 00-.8-2.846.25.25 0 01.042-.299zm-5.235 3.118a.249.249 0 01-.058.161 1.863 1.863 0 01-2.864.025.249.249 0 01-.061-.177 4.727 4.727 0 012.623-4.007.25.25 0 01.36.224z\"/><path d=\"M6.119 18.5a2.994 2.994 0 10.642.953 2.989 2.989 0 00-.642-.953zM20.123 18.5a2.983 2.983 0 10.643.953 2.989 2.989 0 00-.643-.953z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});