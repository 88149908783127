define("ember-svg-jar/inlined/common-file-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-star</title><path d=\"M23.414 3L21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v6.792a.252.252 0 00.232.249 8.446 8.446 0 011.452.211.248.248 0 00.316-.24V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-7.335a.232.232 0 00-.163.06c-.276.251-1.329 1.2-1.329 1.2a.455.455 0 00-.124.475c.086.265.445.265.445.265H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M10.331 19.4a.25.25 0 01.063-.286l2.213-1.972a1.264 1.264 0 00.3-1.378A1.234 1.234 0 0011.738 15h-2.5a.249.249 0 01-.229-.151l-1.345-3.118-.013-.031a1.293 1.293 0 00-2.317.027l-1.346 3.121a.252.252 0 01-.23.151h-2.5a1.231 1.231 0 00-1.166.759 1.272 1.272 0 00.32 1.4L2.6 19.11a.25.25 0 01.063.286L1.444 22.21a1.255 1.255 0 00.314 1.452 1.278 1.278 0 001.506.166l3.112-1.753a.254.254 0 01.245 0l3.113 1.753a1.277 1.277 0 001.506-.166 1.255 1.255 0 00.315-1.451z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});