define("ember-svg-jar/inlined/pollution-water", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pollution-water</title><path d=\"M.866 11.628a1.252 1.252 0 001.768 0l.646-.647a.75.75 0 00-1.06-1.06l-.116.117a.5.5 0 01-.708 0l-.116-.117a.75.75 0 00-1.06 1.061zM19.5 2.514a1.251 1.251 0 001.768 0l.646-.646a.75.75 0 00-1.06-1.061l-.117.116a.5.5 0 01-.707 0l-.116-.116a.75.75 0 00-1.06 1.061zM21.6 14.058a.5.5 0 01-.708 0l-.116-.116A.75.75 0 0019.72 15l.646.646a1.253 1.253 0 001.768 0L22.78 15a.75.75 0 00-1.06-1.061zM6.844 12.945a.5.5 0 01.156.362v6.324a.5.5 0 00.167.369 1.668 1.668 0 001.167.482c.755 0 1.215-.473 1.737-1.09a2.525 2.525 0 011.938-.882 2.47 2.47 0 011.9.858c.551.652 1 1.114 1.758 1.114a1.688 1.688 0 001.165-.48.5.5 0 00.168-.371v-6.324a.5.5 0 01.156-.362 7.5 7.5 0 10-10.312 0zm3.906-.931a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zm4 1a.75.75 0 01-1.5 0v-2a.75.75 0 011.5 0zm-2.75-11a5.506 5.506 0 015.5 5.5 5.409 5.409 0 01-.309 1.8.25.25 0 01-.447.051 1.45 1.45 0 00-.71-.586 11.044 11.044 0 00-8.068 0 1.45 1.45 0 00-.71.586.25.25 0 01-.447-.051 5.409 5.409 0 01-.309-1.8 5.506 5.506 0 015.5-5.5z\"/><path d=\"M23 21.986a3.745 3.745 0 01-2.9-1.646 1.056 1.056 0 00-1.546.023 3.72 3.72 0 01-2.884 1.623 3.743 3.743 0 01-2.9-1.646 1.055 1.055 0 00-1.547.024 3.72 3.72 0 01-2.883 1.622 3.745 3.745 0 01-2.9-1.646 1.055 1.055 0 00-1.547.024A3.718 3.718 0 011 21.986a1 1 0 000 2 5.126 5.126 0 003.346-1.243.5.5 0 01.639 0 5.126 5.126 0 006.694 0 .5.5 0 01.639 0 5.126 5.126 0 006.694 0 .5.5 0 01.64 0A5.126 5.126 0 0023 23.986a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});