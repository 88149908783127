define("ember-svg-jar/inlined/audio-book-headphones-head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-book-headphones-head</title><path d=\"M23.386.574a2.048 2.048 0 00-1.492-.56 21.492 21.492 0 00-3.086.349 1 1 0 00.375 1.965 18.941 18.941 0 012.283-.287.5.5 0 01.534.5l-.02 14.8a.5.5 0 01-.47.5 15.777 15.777 0 00-8.119 2.459.25.25 0 01-.391-.2v-6.588a1 1 0 00-2 0V20.1a.25.25 0 01-.391.207 15.822 15.822 0 00-8.138-2.469.5.5 0 01-.47-.5L2.02 2.54a.5.5 0 01.534-.5 18.684 18.684 0 012.263.286A1 1 0 005.192.363 21.492 21.492 0 002.106.014a2.024 2.024 0 00-1.492.56A1.975 1.975 0 000 2.012v15.8a2 2 0 001.94 2c7 .278 9.119 3.561 9.2 3.692a1 1 0 001.718 0c.021-.034 2.111-3.41 9.2-3.692a2 2 0 001.941-2v-15.8a1.975 1.975 0 00-.613-1.438z\"/><circle cx=\"12\" cy=\"7.512\" r=\"3.5\"/><path d=\"M4.25 8.262A2.752 2.752 0 007 11.012a.75.75 0 00.75-.75v-4.5a4.25 4.25 0 018.5 0v4.5a.75.75 0 00.75.75 2.75 2.75 0 001.035-5.3.5.5 0 01-.31-.423 5.744 5.744 0 00-11.45 0 .5.5 0 01-.31.423 2.753 2.753 0 00-1.715 2.55z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});