define("ember-svg-jar/inlined/multiple-actions-flag.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-flag</title><path d=\"M14.1 11.724l1.266-.43a5.35 5.35 0 013.742.071.5.5 0 00.593-.75A6.019 6.019 0 0014.735 8a5.955 5.955 0 00-2.466.534.5.5 0 00-.181.771 7.479 7.479 0 011.231 2.172.5.5 0 00.665.292c.039-.017.079-.032.116-.045zM14.259 7a3.5 3.5 0 10-3.513-3.5A3.507 3.507 0 0014.259 7zM12.245 13.6a6.025 6.025 0 00-12-.268.6.6 0 00.6.667h11.024a.38.38 0 00.275-.119.372.372 0 00.101-.28zM9.273 3.5A3.514 3.514 0 105.759 7a3.548 3.548 0 003.514-3.5zM23.754 13.619a.5.5 0 00-.709-.454c-2.177 1.01-3.061.406-3.6.04-2.094-1.419-4.234-.274-4.868-.059a.5.5 0 00-.339.473L14.246 23a1 1 0 002 0v-3.664a.249.249 0 01.191-.243 3.045 3.045 0 012.447.44 4.022 4.022 0 002.271.7 4.761 4.761 0 002.309-.662.5.5 0 00.29-.454z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});