define("ember-svg-jar/inlined/office-folder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-folder</title><path d=\"M5 2.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v7.25a.25.25 0 00.25.25h1.5a.249.249 0 00.25-.25V2a2 2 0 00-2-2H5a2 2 0 00-2 2v4.75a.25.25 0 00.25.25h1.5A.249.249 0 005 6.748zM23.967 13.31A1.5 1.5 0 0022.5 11.5H9.884a.5.5 0 01-.49-.4l-.278-1.4a1.5 1.5 0 00-1.471-1.2H1.5a1.5 1.5 0 00-1.479 1.752l2.122 12.5A1.5 1.5 0 003.622 24H20.5a1.505 1.505 0 001.468-1.192c.532-2.546 1.403-6.676 1.999-9.498z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});