define("ember-svg-jar/inlined/safety-bell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-bell</title><path d=\"M6.341 4.119A9.477 9.477 0 003.57 6.355a1 1 0 101.524 1.3 7.524 7.524 0 012.189-1.773 1 1 0 10-.942-1.763zM6.66 2.438a1 1 0 10-.7-1.875A12.5 12.5 0 00.4 4.676 1 1 0 101.981 5.9 10.5 10.5 0 016.66 2.438zM13.341 5.882a7.515 7.515 0 012.19 1.768 1 1 0 001.524-1.295 9.468 9.468 0 00-2.772-2.236 1 1 0 10-.942 1.763zM13.965 2.438A10.507 10.507 0 0118.644 5.9a1 1 0 101.586-1.224A12.5 12.5 0 0014.66.563a1 1 0 00-.7 1.875z\"/><circle cx=\"21.812\" cy=\"11.501\" r=\"2\"/><path d=\"M22.5 14.159a2.6 2.6 0 01-1.371 0 .251.251 0 00-.315.242v3.573a3.5 3.5 0 01-3.5 3.5h-1.285a.249.249 0 01-.185-.419 7.492 7.492 0 10-5.532 2.445l7-.027a5.506 5.506 0 005.5-5.5V14.4a.25.25 0 00-.314-.242zM10.312 18a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});