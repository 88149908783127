define("ember-svg-jar/inlined/programming-user-chat-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-user-chat-1</title><path d=\"M.282 23.39a.5.5 0 00.46.694h12.129a.507.507 0 00.411-.238.533.533 0 00.039-.485 3.55 3.55 0 00-.132-.294c-.454-.908-1.9-1.441-3.911-2.179l-.4-.145a.251.251 0 01-.163-.235v-.834a.251.251 0 01.073-.177 2.619 2.619 0 00.753-1.511.234.234 0 01.052-.123 1.383 1.383 0 00.245-.544A1.58 1.58 0 009.7 16.16a.248.248 0 01-.027-.2 4.474 4.474 0 00.445-2.38c-.213-1.061-1.582-1.469-2.792-1.5a3.356 3.356 0 00-2.493.773.252.252 0 01-.137.086 1.243 1.243 0 00-.734.471 3.057 3.057 0 00-.018 2.522.253.253 0 01-.029.206 1.577 1.577 0 00-.15 1.176 1.382 1.382 0 00.246.545.238.238 0 01.052.123 2.619 2.619 0 00.753 1.51.251.251 0 01.073.177v.834a.248.248 0 01-.164.234l-.4.146c-1.997.743-3.447 1.277-3.9 2.184a4.609 4.609 0 00-.143.323zM11 11.416h.756a.5.5 0 01.5.5v1.75a.75.75 0 001.238.569l3.149-2.7a.5.5 0 01.326-.12h5.037a1.752 1.752 0 001.75-1.75v-8a1.752 1.752 0 00-1.75-1.75h-11a1.752 1.752 0 00-1.75 1.75v8A1.769 1.769 0 0011 11.416zm5.756-4.249a.75.75 0 01.75-.75h3a.75.75 0 010 1.5h-3a.75.75 0 01-.749-.75zM12.477 3.7a.75.75 0 011.06-1.061l2 2a.75.75 0 010 1.061l-2 2a.75.75 0 01-1.06-1.061l1.116-1.119a.5.5 0 000-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});