define("ember-svg-jar/inlined/rooster-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rooster-2</title><path d=\"M9.766 19.507v1.8l-1.7.339a1 1 0 10.396 1.954l2.239-.448 1.249.416a1 1 0 10.632-1.9l-.816-.273v-1.907a9.352 9.352 0 01-1.108.068 8.233 8.233 0 01-.892-.049zM18.135 12.308c2.079-2.385 4.626-.362 5.065-.058a.5.5 0 00.713-.668 5.8 5.8 0 00-6.388-2.958c2.216-1.718 5.088.436 5.712.792a.5.5 0 00.732-.556 5.735 5.735 0 00-5.693-4.19A5.707 5.707 0 0012.7 9.049a.5.5 0 01-.485.383h-1.563a1.4 1.4 0 01-1.4-1.405v-.816a3.1 3.1 0 00-.916-2.2 2.7 2.7 0 001.648-2.636A2 2 0 006.734.814a2 2 0 00-3.25 1.561 3.835 3.835 0 001.175 2.379C4.6 4.8.693 4.472.031 7.725a.749.749 0 00.588.885 10.026 10.026 0 011.818.364 3.62 3.62 0 00-.953 2.4A1.981 1.981 0 004 13.384a7.523 7.523 0 001.685 2.576 7.032 7.032 0 005 2.1 7.21 7.21 0 006.2-3.611c1.707-1.477 4-.326 4.757 1.027a.759.759 0 001.02.291.75.75 0 00.29-1.02 4.883 4.883 0 00-4.817-2.439zM6.234 5.624a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});