define("ember-svg-jar/inlined/sim-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sim-card</title><path d=\"M20.558 5.351l-5-4.912A1.494 1.494 0 0014.5 0h-10A1.5 1.5 0 003 1.5v21A1.5 1.5 0 004.5 24h15a1.5 1.5 0 001.5-1.5V6.414a1.513 1.513 0 00-.442-1.063zM6.25 13.25h2.9l1.25 1.5H6.25A.25.25 0 016 14.5v-1a.25.25 0 01.25-.25zM6 16.5a.25.25 0 01.25-.25h4.15l-1.176 1.41a.251.251 0 01-.192.09H6.25A.25.25 0 016 17.5zm12 0v1a.25.25 0 01-.25.25h-2.782a.251.251 0 01-.192-.09L13.6 16.25h4.15a.25.25 0 01.25.25zm-4.4-1.75l1.175-1.41a.251.251 0 01.192-.09h2.783a.25.25 0 01.25.25v1a.25.25 0 01-.25.25zm4.149-3H14.5a.748.748 0 00-.575.27l-1.733 2.08a.25.25 0 01-.384 0l-1.732-2.08a.748.748 0 00-.575-.27H6.25A.25.25 0 016 11.5 1.5 1.5 0 017.5 10h9a1.5 1.5 0 011.5 1.5.25.25 0 01-.25.25zm-11.5 7.5H9.5a.748.748 0 00.575-.27l1.733-2.08a.25.25 0 01.384 0l1.732 2.078a.748.748 0 00.575.27h3.251a.25.25 0 01.25.25A1.5 1.5 0 0116.5 21h-9A1.5 1.5 0 016 19.5a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});