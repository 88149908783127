define("ember-svg-jar/inlined/music-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-player</title><path d=\"M24 7.5a1.5 1.5 0 00-2.856-.639.252.252 0 01-.219.139 2 2 0 000 3.994.251.251 0 01.22.145 1.388 1.388 0 00.28.4.254.254 0 01.071.175V19a1.5 1.5 0 01-3 0V5A2.5 2.5 0 0016 2.5h-1.75a.25.25 0 01-.25-.25V2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h10a2 2 0 002-2V4.75a.25.25 0 01.25-.25H16a.5.5 0 01.5.5v14a3.5 3.5 0 007 0v-7.277a.254.254 0 01.071-.175A1.492 1.492 0 0024 10.5zm-21.5 7a1 1 0 01-1-1v-11a1 1 0 011-1h9a1 1 0 011 1v11a1 1 0 01-1 1zM8.809 19a.637.637 0 01-.284.531l-2.042 1.362a.639.639 0 01-.993-.532v-2.722a.639.639 0 01.993-.532l2.042 1.362a.637.637 0 01.284.531z\"/><path d=\"M4 6.75H3a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM4 9.25H3a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM10 8.25h1a.75.75 0 000-1.5h-1a.75.75 0 000 1.5zM10 10.75h1a.75.75 0 000-1.5h-1a.75.75 0 000 1.5zM7.5 9.25h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM11.75 12.5a.75.75 0 00-.75-.75h-1a.75.75 0 000 1.5h1a.75.75 0 00.75-.75zM4 11.75H3a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM7.5 11.75h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});