define("ember-svg-jar/inlined/electronics-battery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>electronics-battery</title><path d=\"M18 12.751a.5.5 0 00.5-.5V3.5A1.5 1.5 0 0017 2h-1a.5.5 0 01-.5-.5A1.5 1.5 0 0014 0h-4a1.5 1.5 0 00-1.5 1.5.5.5 0 01-.5.5H7a1.5 1.5 0 00-1.5 1.5v8.75a.5.5 0 00.5.5zm-4-4.5h-1a.25.25 0 00-.25.25v1a.75.75 0 01-1.5 0v-1a.25.25 0 00-.25-.25h-1a.75.75 0 010-1.5h1a.25.25 0 00.25-.25v-1a.75.75 0 011.5 0v1a.25.25 0 00.25.25h1a.75.75 0 110 1.5zM6 14.251a.5.5 0 00-.5.5V22.5A1.5 1.5 0 007 24h10a1.5 1.5 0 001.5-1.5v-7.75a.5.5 0 00-.5-.5zm4 4h4a.75.75 0 110 1.5h-4a.75.75 0 010-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});