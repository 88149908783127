define("ember-svg-jar/inlined/business-magic-rabbit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-magic-rabbit</title><path d=\"M5.458 17h1.856a.252.252 0 01.248.286L6.9 21.947a1.3 1.3 0 00.386 1.113 1.571 1.571 0 001.1.44h6.91a1.577 1.577 0 001.1-.44 1.305 1.305 0 00.385-1.113l-.666-4.66a.252.252 0 01.247-.287h2.18a1 1 0 000-2H5.458a1 1 0 100 2zM20 3.909c1.5-1.5-1-4.5-3-3-.915.687-2.961 3.853-3.712 5.04a.251.251 0 01-.278.108 3.52 3.52 0 00-2.02 0 .251.251 0 01-.278-.108C9.961 4.762 7.915 1.6 7 .909c-2-1.5-4.5 1.5-3 3 .783.783 3.4 2.789 4.5 3.623a.251.251 0 01.064.327 4 4 0 106.87 0 .25.25 0 01.064-.327C16.6 6.7 19.217 4.692 20 3.909zM10.25 10.5a.75.75 0 11.75-.75.749.749 0 01-.75.75zm3.5 0a.75.75 0 11.75-.75.749.749 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});