define("ember-svg-jar/inlined/monitor-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-sync</title><path d=\"M11.619 11.656a.25.25 0 00-.232-.156H2.5A.5.5 0 012 11V2.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v6.847a.25.25 0 00.287.247A8.226 8.226 0 0117.5 9.5c.079 0 .157 0 .235.008a.25.25 0 00.186-.067.252.252 0 00.079-.182V2a2 2 0 00-2-2H2a2 2 0 00-2 2v10.5a2 2 0 002 2h5.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5H5a1 1 0 000 2h3.754A.25.25 0 009 17.757a8.468 8.468 0 012.558-5.828.25.25 0 00.061-.273zM22.769 18.6a1 1 0 00-1.27.622 4.091 4.091 0 01-6.918 1.42.25.25 0 01.008-.345l1.722-1.722a.5.5 0 00-.354-.853H11.5a.5.5 0 00-.5.5v4.458a.5.5 0 00.854.353l.969-.969a.251.251 0 01.359 0 6.086 6.086 0 0010.21-2.2 1 1 0 00-.623-1.264z\"/><path d=\"M23.692 11.773a.5.5 0 00-.545.108l-1.026 1.026a.252.252 0 01-.18.073.255.255 0 01-.178-.077 6.1 6.1 0 00-10.2 2.226 1 1 0 001.893.649 4.091 4.091 0 016.9-1.446.251.251 0 01-.007.347l-1.659 1.659a.5.5 0 00.353.854H23.5a.5.5 0 00.5-.5v-4.457a.5.5 0 00-.308-.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});