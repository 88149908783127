define("ember-svg-jar/inlined/question-help-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>question-help-square</title><path d=\"M2.5 0A2.5 2.5 0 000 2.5v19A2.5 2.5 0 002.5 24h19a2.5 2.5 0 002.5-2.5v-19A2.5 2.5 0 0021.5 0zM12 19a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0112 19zm1.6-6.08a1 1 0 00-.6.917 1 1 0 11-2 0 3 3 0 011.8-2.75A2 2 0 1010 9.255a1 1 0 11-2 0 4 4 0 115.6 3.666z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});