define("ember-svg-jar/inlined/safety-exit-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-exit-left</title><path d=\"M2.4 16.478a.5.5 0 00.353.147.508.508 0 00.191-.038.5.5 0 00.309-.462v-.75a.249.249 0 01.25-.25H5.75a1 1 0 000-2H3.5a.25.25 0 01-.25-.25v-.75a.5.5 0 00-.853-.354l-2 2a.5.5 0 000 .707z\"/><circle cx=\"10.5\" cy=\"3.375\" r=\"2.5\"/><path d=\"M5.511 9.754a5.506 5.506 0 003.58 1.319 5.6 5.6 0 001.88-.327l.345-.132a.25.25 0 01.312.122l1.309 2.617a.25.25 0 01-.074.312l-2.214 1.66a3.52 3.52 0 00-1.4 2.8v3.5a1.5 1.5 0 003 0v-3.5a.5.5 0 01.2-.4l1.7-1.278a.251.251 0 01.374.088l.27.54a3.474 3.474 0 003.127 1.935 3.227 3.227 0 00.325-.01l4.141-.376a1.5 1.5 0 00-.134-2.994 1.288 1.288 0 00-.138.007s-4.169.378-4.183.378a.5.5 0 01-.45-.276l-3-6a.25.25 0 01.17-.356 2.533 2.533 0 01.532-.057 2.483 2.483 0 011.811.784l1.657 1.76a1.5 1.5 0 102.184-2.057l-1.657-1.766a5.487 5.487 0 00-5.966-1.368L9.931 7.932a2.473 2.473 0 01-.838.146 2.509 2.509 0 01-1.63-.6L5.726 5.987a1.5 1.5 0 10-1.952 2.277z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});