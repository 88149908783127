define("ember-svg-jar/inlined/surveillance-target", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>surveillance-target</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm7.515 4.485a.75.75 0 010 1.061L12.53 12.53a.75.75 0 01-1.06-1.06l6.984-6.985a.75.75 0 011.061 0zM12 15.25A3.254 3.254 0 0015.25 12a.75.75 0 011.5 0A4.75 4.75 0 1112 7.25a.75.75 0 010 1.5 3.25 3.25 0 000 6.5zm0-12.5a9.162 9.162 0 014.047.93.75.75 0 11-.657 1.349A7.67 7.67 0 0012 4.25a7.759 7.759 0 00-7.68 6.729.311.311 0 01-.312.271H4a3.226 3.226 0 00-.568.051.5.5 0 01-.585-.56A9.254 9.254 0 0112 2.75zM2.25 14.5A1.75 1.75 0 114 16.25a1.752 1.752 0 01-1.75-1.75zM12 21.25a9.211 9.211 0 01-6.811-3.011.5.5 0 01.156-.79 3.2 3.2 0 00.385-.214.5.5 0 01.635.076 7.749 7.749 0 0012.822-8.217.75.75 0 111.39-.563A9.254 9.254 0 0112 21.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});