define("ember-svg-jar/inlined/day-morning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>day-morning</title><path d=\"M23 18.5h-.882a10.069 10.069 0 00-.564-3.416 1 1 0 10-1.885.667 8.093 8.093 0 01.451 2.749H3.98a8.035 8.035 0 014.159-7.128 1 1 0 00-.969-1.75A10.021 10.021 0 001.984 18.5H1a1 1 0 000 2h22a1 1 0 000-2z\"/><path d=\"M13 17.5v-2a1 1 0 00-2 0v2a1 1 0 002 0zM12 13.5a1 1 0 001-1v-2a1 1 0 00-2 0v2a1 1 0 001 1zM12 7.5a1 1 0 001-1v-2a1 1 0 00-2 0v2a1 1 0 001 1zM14.771 10.278h1.157a2.566 2.566 0 00.2.471l-.818.818a.75.75 0 101.06 1.061l.819-.818a2.586 2.586 0 00.47.2v1.157a.75.75 0 101.5 0v-1.162a2.566 2.566 0 00.471-.2l.818.818a.75.75 0 001.052-1.056l-.818-.818a2.566 2.566 0 00.2-.471h1.157a.75.75 0 000-1.5h-1.158a2.625 2.625 0 00-.2-.471l.818-.818a.75.75 0 10-1.061-1.06l-.818.818a2.566 2.566 0 00-.471-.2V5.9a.75.75 0 00-1.5 0v1.152a2.586 2.586 0 00-.47.2l-.819-.818a.75.75 0 10-1.06 1.06l.818.818a2.625 2.625 0 00-.2.471h-1.147a.75.75 0 000 1.5zm4.734-.75a1.1 1.1 0 11-1.1-1.1 1.1 1.1 0 011.1 1.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});