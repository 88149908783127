define("ember-svg-jar/inlined/bus-station", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bus-station</title><path d=\"M16.687 6.44a.25.25 0 01-.187-.24V5.123a3 3 0 00-3-3h-9a3 3 0 00-3 3V6.2a.25.25 0 01-.188.242A1.745 1.745 0 000 8.127v2a1.746 1.746 0 001.313 1.687.25.25 0 01.187.242v4.067a2.985 2.985 0 001.385 2.512.25.25 0 01.115.211v1.281a1.75 1.75 0 003.5 0v-.754a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25v.754a1.75 1.75 0 003.5 0v-1.281a.25.25 0 01.115-.211 2.985 2.985 0 001.385-2.512v-4.067a.25.25 0 01.187-.242A1.746 1.746 0 0018 10.127v-2a1.745 1.745 0 00-1.313-1.687zM5.5 16.377h-1a.75.75 0 110-1.5h1a.75.75 0 110 1.5zm8 0h-1a.75.75 0 110-1.5h1a.75.75 0 010 1.5zm1-3.754a.25.25 0 01-.25.25H3.75a.25.25 0 01-.25-.25v-7.5a1 1 0 011-1h9a1 1 0 011 1zM21.25 8.127a2.746 2.746 0 00-1.15 5.243.251.251 0 01.146.227v7.28a1 1 0 002 0V13.6a.251.251 0 01.146-.227 2.746 2.746 0 00-1.142-5.246z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});