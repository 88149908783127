define("ember-svg-jar/inlined/road-sign-2-5m-high-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-2.5m-high-1-alternate</title><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-11.3 9.884l-.232.232a.25.25 0 01-.354 0l-.232-.232a.25.25 0 01.177-.427h.464a.25.25 0 01.177.427zm9-9L13.99 19.6a.249.249 0 01-.38-.031.71.71 0 00-.08-.094l-1-1a.749.749 0 00-1.06 0l-1 1a.71.71 0 00-.08.094.249.249 0 01-.38.031l-7.419-7.423a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/><path d=\"M11.47 6.53a.748.748 0 001.06 0l1-1A.75.75 0 0013 4.25h-2a.75.75 0 00-.53 1.28zM10.75 12a.75.75 0 00-.75-.75.211.211 0 01-.19-.12.208.208 0 01.025-.223l.439-.549A2.18 2.18 0 0010.75 9a1.75 1.75 0 00-3.5 0 .75.75 0 001.5 0c0-.275.5-.275.5 0a.685.685 0 01-.147.422l-1.688 2.109A.75.75 0 008 12.75h2a.75.75 0 00.75-.75zM15 8.75h1a.75.75 0 000-1.5h-2a.75.75 0 00-.75.75v1.5a1.252 1.252 0 001.25 1.25h.5a.25.25 0 010 .5h-1a.75.75 0 000 1.5h1a1.75 1.75 0 000-3.5.25.25 0 010-.5z\"/><circle cx=\"12\" cy=\"12\" r=\".75\"/><path d=\"M13.7 13.277a.749.749 0 00-.843.337l-.643 1.071a.25.25 0 01-.428 0l-.643-1.071A.75.75 0 009.75 14v3.5a.75.75 0 001.5 0v-.585a.058.058 0 01.107-.029.78.78 0 001.286 0 .058.058 0 01.107.029v.585a.75.75 0 001.5 0V14a.75.75 0 00-.55-.723z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});