define("ember-svg-jar/inlined/social-music-soundcloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-music-soundcloud</title><path d=\"M20.66 10.71a3.31 3.31 0 00-.68.07 5.46 5.46 0 00-5.38-4.4 5.56 5.56 0 00-2 .37.83.83 0 00-.6.8v9a.83.83 0 00.8.83h7.85a3.33 3.33 0 000-6.66zM9.74 7.11a.75.75 0 00-.74.75v9a.75.75 0 001.5 0v-9a.75.75 0 00-.76-.75zM6.75 8.61a.75.75 0 00-.75.75v7.51a.75.75 0 001.5 0V9.36a.75.75 0 00-.75-.75zM3.75 10.61a.75.75 0 00-.75.75v5.51a.75.75 0 001.5 0v-5.51a.75.75 0 00-.75-.75zM.76 11.61a.75.75 0 00-.75.75v4a.75.75 0 001.5 0v-4a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});