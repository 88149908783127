define("ember-svg-jar/inlined/history-fish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-fish</title><path d=\"M11.007 18.182a2.739 2.739 0 00-1.624-.375.249.249 0 01-.27-.192l-.043-.181a18.4 18.4 0 01-.534-2.874 9.252 9.252 0 01.064-1.566.25.25 0 01.342-.2 1.641 1.641 0 00.492.117 1.381 1.381 0 001.086-.411 2.544 2.544 0 00-.04-3.028 2.747 2.747 0 00-.5-.571.252.252 0 01-.075-.267c1.187-3.688.614-6.323-1.593-7.257-4-1.7-5.5 1.076-6.352 3.545a13.084 13.084 0 00-.488 2.666v.86a.249.249 0 01-.131.219 2.547 2.547 0 00-1.033 1.19A2.514 2.514 0 00.7 12.864a1.259 1.259 0 001.091.194.218.218 0 01.264.147 12.575 12.575 0 004.249 6 .251.251 0 01.026.348 3.754 3.754 0 00-.6 1.03 4.5 4.5 0 00.348 3.14.5.5 0 00.444.277.547.547 0 00.09-.008 3.312 3.312 0 002.354-1.734c.025-.065.049-.13.072-.2a.234.234 0 01.338-.128c1.266.714 2.848-.4 2.914-.449a.5.5 0 00.206-.469c-.028-.212-.306-2.162-1.489-2.83zM6.957 6.067a11.092 11.092 0 00-.807 3.111 13.937 13.937 0 00.662 6.152.5.5 0 01-.309.636.516.516 0 01-.163.027.5.5 0 01-.473-.337 14.938 14.938 0 01-.709-6.6 12.075 12.075 0 01.885-3.4.5.5 0 11.914.4zM23.3 11.137a1.261 1.261 0 00-1.091-.2.218.218 0 01-.265-.147 12.575 12.575 0 00-4.248-6 .251.251 0 01-.026-.348 3.743 3.743 0 00.6-1.031 4.5 4.5 0 00-.344-3.133.5.5 0 00-.538-.269 3.309 3.309 0 00-2.354 1.734c-.025.065-.049.13-.072.2a.234.234 0 01-.338.128c-1.266-.715-2.847.4-2.914.449a.5.5 0 00-.206.469c.028.22.306 2.166 1.489 2.834a2.746 2.746 0 001.624.375.249.249 0 01.27.192l.043.181a18.4 18.4 0 01.534 2.874 9.252 9.252 0 01-.068 1.566.25.25 0 01-.122.184.247.247 0 01-.22.016 1.64 1.64 0 00-.493-.117 1.345 1.345 0 00-1.08.414 2.543 2.543 0 00.039 3.028 2.693 2.693 0 00.5.571.252.252 0 01.075.267c-1.187 3.689-.614 6.324 1.593 7.257 1.663.705 5.148-.075 6.352-3.545a13.025 13.025 0 00.487-2.666v-.86a.249.249 0 01.131-.219 2.542 2.542 0 001.036-1.193 2.514 2.514 0 00-.394-3.011zm-4.46 3.805a12.084 12.084 0 01-.885 3.4.5.5 0 11-.914-.4 11.092 11.092 0 00.807-3.111 13.923 13.923 0 00-.663-6.152.5.5 0 11.946-.326 14.938 14.938 0 01.711 6.589z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});