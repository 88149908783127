define("ember-svg-jar/inlined/credit-card-visa-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-visa</title><path d=\"M21.913 3.5H2.087A2.051 2.051 0 000 5.5v13a2.051 2.051 0 002.087 2h19.826a2.05 2.05 0 002.087-2v-13a2.05 2.05 0 00-2.087-2zM8.521 10.215l-1.969 4.109a.751.751 0 01-.677.426h-.688a.75.75 0 01-.746-.674l-.315-3.1a.249.249 0 00-.248-.225H3.5a.75.75 0 010-1.5h1.281a.75.75 0 01.746.674l.167 1.644a.25.25 0 00.474.083l1-2.085a.751.751 0 011.354.649zm1.855 3.964a.75.75 0 01-.734.6.844.844 0 01-.149-.014.751.751 0 01-.588-.883l.813-4.063a.75.75 0 111.47.295zm5.209-4.068a.755.755 0 01-.912.537c-.259-.065-.78-.154-1 .061s.359.5.482.573c1.257.771 1.351 1.574 1.208 2.114a2.006 2.006 0 01-.992 1.259 2.234 2.234 0 01-1.061.251 3.726 3.726 0 01-1.216-.229.75.75 0 11.5-1.416 1.468 1.468 0 001.078.069.339.339 0 00.22-.316c-.021-.226-.35-.351-.516-.452-1.549-.95-1.4-1.941-1-2.6.737-1.229 2.48-.813 2.677-.762a.751.751 0 01.532.911zm4.477 4.6a.745.745 0 01-.809-.686l-.019-.223a.25.25 0 00-.249-.229h-.779a.249.249 0 00-.225.142l-.3.635a.751.751 0 01-1.354-.648l1.934-4.042a.772.772 0 01.692-.426l.734.016a.751.751 0 01.732.688l.328 3.969a.75.75 0 01-.685.809z\"/><path d=\"M18.766 12.078l.277-.578.048.578h-.325z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});