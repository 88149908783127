define("ember-svg-jar/inlined/folder-image-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-image-1</title><path d=\"M22.5 4.5H11.736a.5.5 0 01-.447-.276l-.947-1.9A1.494 1.494 0 009 1.5H1.5A1.5 1.5 0 000 3v18a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 21V6a1.5 1.5 0 00-1.5-1.5zM8.8 7a2 2 0 11-2 2 2 2 0 012-2zm9.768 11.871a.249.249 0 01-.219.129H6.25a.25.25 0 01-.212-.383l2.775-4.439a.5.5 0 01.848 0L10.576 16A.251.251 0 0011 16l2.375-4.322a.5.5 0 01.848 0l4.337 6.939a.249.249 0 01.007.254z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});