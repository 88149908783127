define("ember-svg-jar/inlined/lucide-archive-restore", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect x=\"2\" y=\"4\" width=\"20\" height=\"5\" rx=\"2\"/><path d=\"M12 13v7M9 16l3-3 3 3M4 9v9a2 2 0 002 2h2M20 9v9a2 2 0 01-2 2h-2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});