define("ember-svg-jar/inlined/style-three-pin-radio-van", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-radio-van</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm.133 7.031a.5.5 0 01-.063-.788l.862-.787a.25.25 0 00.016-.353l-.817-.895a.5.5 0 11.738-.675l.817.9a.251.251 0 00.354.016l.89-.813a.5.5 0 01.779.134 2.5 2.5 0 01-3.576 3.266zm-4.786 6.358a.253.253 0 01-.208.111H7a.5.5 0 01-.5-.5v-2.5a2 2 0 012-2h.75a.25.25 0 01.25.25v3.516a.251.251 0 01-.282.249A1.576 1.576 0 009 12.5a1.986 1.986 0 00-1.653.889zM9 15.5a1 1 0 111-1 1 1 0 01-1 1zm6 0a1 1 0 111-1 1 1 0 01-1 1zm2.5-3a.975.975 0 01-.6.895.249.249 0 01-.3-.081 1.983 1.983 0 00-3.256.075.253.253 0 01-.208.111h-2.275c-.169 0-.361-.264-.361-.4V9.5a1 1 0 011-1h5a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});