define("ember-svg-jar/inlined/saving-money-egg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>saving-money-egg</title><path d=\"M12 16.637a16.534 16.534 0 007.242-1.565.249.249 0 00.137-.194 18.941 18.941 0 00-2.508-10.7C15.359 1.521 13.584 0 12 0S8.642 1.522 7.13 4.176a18.929 18.929 0 00-2.509 10.7.249.249 0 00.137.194A16.534 16.534 0 0012 16.637zm-1.084-8.3l2.71 1.015a1.99 1.99 0 011.124 1.927 1.975 1.975 0 01-1.789 1.979.248.248 0 00-.211.246v.539a.75.75 0 01-1.5 0v-.5a.25.25 0 00-.25-.25h-1a.75.75 0 010-1.5h2.5c.5 0 .75-.173.75-.514a.745.745 0 00-.166-.53l-2.71-1.015A1.99 1.99 0 019.25 7.807a2.057 2.057 0 011.794-1.974.25.25 0 00.206-.246v-.544a.75.75 0 111.5 0v.5a.25.25 0 00.25.25h1a.75.75 0 010 1.5h-2.5c-.406 0-.75.235-.75.514a.745.745 0 00.166.53z\"/><path d=\"M22.656 15.115a.748.748 0 00-.7.112A16.619 16.619 0 0112 18.209a16.619 16.619 0 01-9.951-2.982.752.752 0 00-1.186.416 3.736 3.736 0 00-.113.9C.75 21 5.271 24 12 24s11.25-3 11.25-7.46a3.736 3.736 0 00-.113-.9.75.75 0 00-.481-.525z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});