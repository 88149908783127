define("ember-svg-jar/inlined/squid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>squid</title><path d=\"M19.953 10.811c-.387.388-.749.728-1.1 1.048a9.037 9.037 0 003.008 1.816.5.5 0 00.628-.3c1.7-4.677 1.578-7.526 1.141-9.134a14.19 14.19 0 01-3.677 6.57zM13.151 3.9A13.779 13.779 0 0119.626.3C18-.118 15.17-.2 10.582 1.463a.5.5 0 00-.3.628 8.958 8.958 0 001.778 2.964c.328-.37.685-.755 1.091-1.155z\"/><path d=\"M14.061 15.178a3.169 3.169 0 01.4-1.3 19.742 19.742 0 004.78-3.774A13.405 13.405 0 0023.058 1.4a.5.5 0 00-.492-.5 12.968 12.968 0 00-8.708 3.7 18.245 18.245 0 00-3.7 4.832l-1.254.3c-1.571-1.211-3.993-.27-4.43-.1a1.8 1.8 0 01-1.98-.212 1.8 1.8 0 01-.216-1.984 1.2 1.2 0 01.873-.85.409.409 0 01.375.109c.05.071.021.237-.071.423a1 1 0 001.789.894 2.357 2.357 0 00-.08-2.465A2.381 2.381 0 002.92 4.6a3.16 3.16 0 00-2.5 2.094 3.771 3.771 0 00.66 4.14 3.459 3.459 0 002.484.99c1.534 0 3.108-1.236 4.221-.453a1.546 1.546 0 01-.251.718c-.736-.14-1.865.756-1.924.8a4.927 4.927 0 00-1.741 2.977c-.173.863-.635 1.2-1.01 1.218a.532.532 0 01-.561-.339 1 1 0 00-1.9.633 2.512 2.512 0 002.583 1.7 3.211 3.211 0 002.849-2.817c.869-1.653.694-1.7 2.029-2.354a2.319 2.319 0 011.077.825 2.6 2.6 0 01-1 2.3A6.185 6.185 0 016 18.128c-2.849 1.069-3.243 2.825-3.28 3.53a2.28 2.28 0 001.18 2.194.964.964 0 001.281-.429 1.038 1.038 0 00-.381-1.359.4.4 0 01-.079-.335c.048-.494.595-1.207 1.982-1.728a6.149 6.149 0 003.932-3.401c.973-.533 1.491-.4 1.808-.217.149.408-.33 1.09-1.4 1.8-2.234 1.49-2.33 3.221-1.732 4.251a2.628 2.628 0 002.314 1.259 3.179 3.179 0 001.42-.34 1 1 0 10-.894-1.789.843.843 0 01-1.11-.134c-.175-.3.15-.942 1.112-1.583 2.397-1.597 2.763-3.379 1.908-4.669zm-1.6-.4a.75.75 0 110-1.06.748.748 0 01-.003 1.058zm-3.332-3.33a.75.75 0 110 1.061.748.748 0 010-1.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});