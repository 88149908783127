define("ember-svg-jar/inlined/common-file-double-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-double-1-alternate</title><path d=\"M20.482 4.819l-2.863-3.031a1.82 1.82 0 00-1.3-.538H8.333A1.835 1.835 0 006.5 3.083V4.75H4.833A1.835 1.835 0 003 6.583v14.334a1.835 1.835 0 001.833 1.833h10.834a1.835 1.835 0 001.833-1.833V19.25h1.667A1.835 1.835 0 0021 17.417V6.1a1.8 1.8 0 00-.518-1.281zM15 20.75H5.5a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5h.75A.25.25 0 016.5 7v10.417a1.833 1.833 0 001.833 1.833h6.917a.25.25 0 01.25.25v.75a.5.5 0 01-.5.5zm3.5-3.5H9a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5h7.035a.5.5 0 01.363.157l2.465 2.61A.5.5 0 0119 6.36v10.39a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});