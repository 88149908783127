define("ember-svg-jar/inlined/shipment-international", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-international</title><path d=\"M20.7 11.5h.053a.75.75 0 00.75-.75C21.5 3.02 14.624 0 10.75 0a10.751 10.751 0 00-5.1 20.215.75.75 0 10.712-1.315 9.274 9.274 0 01-2.508-1.979.25.25 0 01.186-.421h1.595a.75.75 0 000-1.5H2.687a.25.25 0 01-.224-.139 9.238 9.238 0 01-.906-3.083.249.249 0 01.249-.278zM2.465 6.639a.251.251 0 01.224-.139h3.428a.251.251 0 01.245.3 16.605 16.605 0 00-.354 2.956.25.25 0 01-.25.242H1.806a.249.249 0 01-.249-.278 9.176 9.176 0 01.908-3.081zm17.478 3.083a.251.251 0 01-.249.278h-3.952a.25.25 0 01-.25-.242 16.6 16.6 0 00-.353-2.958.25.25 0 01.245-.3h3.427a.251.251 0 01.224.139 9.176 9.176 0 01.908 3.083zm-5.953.02a.249.249 0 01-.25.258H7.76a.249.249 0 01-.25-.259 15.056 15.056 0 01.416-3.05.25.25 0 01.243-.191h5.163a.249.249 0 01.242.191 15.056 15.056 0 01.416 3.051zm3.648-5.159a.25.25 0 01-.186.417h-2.625a.249.249 0 01-.236-.168 16.511 16.511 0 00-1.086-2.457.25.25 0 01.3-.355 9.283 9.283 0 013.833 2.563zm-6.563-3.077a.248.248 0 01.2.107A15.164 15.164 0 0112.94 4.7a.219.219 0 01-.2.3H8.81a.25.25 0 01-.232-.343 15.091 15.091 0 011.652-3.044.248.248 0 01.2-.107q.16-.006.324-.006c.105 0 .213 0 .321.006zm-3.381.513a.25.25 0 01.3.356A16.466 16.466 0 006.91 4.832.25.25 0 016.674 5H4.048a.25.25 0 01-.186-.417 9.282 9.282 0 013.832-2.564z\"/><path d=\"M20.3 14.25h-.967a.219.219 0 01-.2-.139A1.752 1.752 0 0017.5 13h-8a1.752 1.752 0 00-1.75 1.75v6a1.751 1.751 0 00.991 1.576.247.247 0 01.128.145 2.25 2.25 0 004.193.179.251.251 0 01.229-.15h2.918a.25.25 0 01.229.15 2.249 2.249 0 004.01.225.25.25 0 01.216-.125H22.1a1.954 1.954 0 001.9-2v-2.5a3.863 3.863 0 00-3.7-4zm-2.55 7.5a.75.75 0 11.75.75.751.751 0 01-.75-.75zM11 22.5a.75.75 0 11.75-.75.751.751 0 01-.75.75zm10.98-4.536a.252.252 0 01-.248.286H20.25A.25.25 0 0120 18v-1.482a.25.25 0 01.286-.248 2.007 2.007 0 011.694 1.694z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});