define("ember-svg-jar/inlined/arrow-button-circle-right-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-circle-right-1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm5.316 12.384L9.6 18.817a.5.5 0 01-.82-.385v-2.687a.5.5 0 01.193-.395l4.05-3.15a.249.249 0 000-.394L8.97 8.65a.5.5 0 01-.193-.395V5.568a.5.5 0 01.82-.385l7.719 6.433a.5.5 0 010 .768z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});