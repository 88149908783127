define("ember-svg-jar/inlined/car-truck-woods", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-truck-woods</title><path d=\"M1.019 8.677H2.25a.25.25 0 01.25.25v.823a1 1 0 002 0v-.823a.25.25 0 01.25-.25h1.231c.464 0 .691-.674.366-1.063L5.4 6.158a.051.051 0 010-.059.05.05 0 01.057-.015c.45.177.938-.543.518-1.042L4.738 3.159a.05.05 0 01.06-.074c.47.183.956-.619.476-1.089L3.9.2a.5.5 0 00-.8 0L1.726 2c-.48.47.006 1.272.476 1.089a.05.05 0 01.06.074L1.023 5.042c-.419.5.068 1.219.518 1.042A.05.05 0 011.6 6.1a.051.051 0 010 .059L.653 7.614C.328 8 .555 8.677 1.019 8.677zM18.019 8.677h1.231a.25.25 0 01.25.25v.823a1 1 0 002 0v-.823a.25.25 0 01.25-.25h1.231c.464 0 .691-.674.366-1.063L22.4 6.158a.051.051 0 010-.059.05.05 0 01.057-.015c.45.177.937-.543.518-1.042l-1.237-1.883a.05.05 0 01.06-.074c.47.183.956-.619.476-1.089L20.9.2a.5.5 0 00-.8 0L18.726 2c-.48.47.006 1.272.476 1.089a.05.05 0 01.06.074l-1.239 1.879c-.42.5.068 1.219.518 1.042a.05.05 0 01.059.016.051.051 0 010 .059l-.948 1.456c-.324.385-.097 1.062.367 1.062zM9.519 8.677h1.231a.25.25 0 01.25.25v.823a1 1 0 002 0v-.823a.25.25 0 01.25-.25h1.231c.464 0 .691-.674.366-1.063L13.9 6.158a.051.051 0 010-.059.05.05 0 01.057-.015c.45.177.937-.543.518-1.042l-1.237-1.883a.05.05 0 01.06-.074c.47.183.956-.619.476-1.089L12.4.2a.5.5 0 00-.8 0L10.226 2c-.48.47.006 1.272.476 1.089a.05.05 0 01.06.074L9.523 5.042c-.42.5.068 1.219.518 1.042a.05.05 0 01.059.016.051.051 0 010 .059l-.947 1.455c-.325.386-.098 1.063.366 1.063zM23.25 22a.75.75 0 000-1.5H23a.5.5 0 01-.5-.5v-2a1 1 0 00-1-1h-7a.25.25 0 01-.25-.25v-3a2 2 0 00-2-2H8.677a1.99 1.99 0 00-1.857 1.258l-1.534 3.835a.25.25 0 01-.232.157H2a2 2 0 00-2 2v2a1 1 0 001 1h.26a.25.25 0 00.249-.231 3 3 0 015.982 0A.251.251 0 007.74 22h5.52a.25.25 0 00.249-.231 3 3 0 015.982 0 .251.251 0 00.249.231zm-11-5.5a.25.25 0 01-.25.25H7.846a.25.25 0 01-.232-.343l1-2.5a.25.25 0 01.232-.157H12a.25.25 0 01.25.25z\"/><circle cx=\"4.5\" cy=\"22\" r=\"2\"/><circle cx=\"16.5\" cy=\"22\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});