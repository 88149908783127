define("ember-svg-jar/inlined/police-man-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-man-1</title><path d=\"M21.026 18.27l-5-1.263a.251.251 0 00-.207.04 7.13 7.13 0 01-3.84 1.453 7.123 7.123 0 01-3.835-1.449.251.251 0 00-.207-.04L2.92 18.287A4.519 4.519 0 000 22.5v1a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-1a4.531 4.531 0 00-2.974-4.23zm-4.3 5.146l-.61-.406A2.5 2.5 0 0115 20.93V20a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v.93a2.5 2.5 0 01-1.113 2.08l-.61.406a.5.5 0 01-.554 0zM10.521 13.386a2.767 2.767 0 00.5.214 3.35 3.35 0 001 .15 3.374 3.374 0 001-.148 2.7 2.7 0 00.5-.216.75.75 0 00-.734-1.309 1.14 1.14 0 01-.222.094 1.815 1.815 0 01-.544.079 1.846 1.846 0 01-.548-.08 1.161 1.161 0 01-.224-.094.75.75 0 10-.731 1.31z\"/><path d=\"M20.475 1.658a.5.5 0 00-.582-.646c-2.75.6-5.6 1.036-7.542-.869a.5.5 0 00-.7 0C9.71 2.049 6.86 1.616 4.109 1.012a.5.5 0 00-.582.646L5.34 6.614a2.656 2.656 0 00.406.841.251.251 0 01-.1.371H5.64a1.886 1.886 0 00-.934 1.81 2.731 2.731 0 001.439 2.433.252.252 0 01.144.187c.511 3.048 4.021 4.991 5.686 4.991s5.176-1.943 5.687-4.989a.254.254 0 01.145-.189 2.732 2.732 0 001.443-2.431 1.885 1.885 0 00-.936-1.809.236.236 0 01-.085-.349 2.732 2.732 0 00.423-.853zM9.776 4.086v-.93a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v.93a2.5 2.5 0 01-1.113 2.08l-.609.406a.5.5 0 01-.555 0l-.609-.406a2.492 2.492 0 01-1.114-2.08zm7.415 6.62a1.75 1.75 0 00-1 1.3c-.377 2.249-3.175 3.739-4.208 3.739s-3.83-1.49-4.207-3.738a1.747 1.747 0 00-1-1.306 1.275 1.275 0 01-.559-1.1.618.618 0 01.105-.439.724.724 0 00.369-.433.25.25 0 01.358-.129 6.92 6.92 0 00.845.416A10.874 10.874 0 0012 9.75a10.866 10.866 0 004.069-.724 6.934 6.934 0 00.847-.41.247.247 0 01.212-.017.249.249 0 01.149.152.732.732 0 00.368.42.615.615 0 01.105.438 1.281 1.281 0 01-.559 1.097z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});