define("ember-svg-jar/inlined/pin-location-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin-location-2</title><path d=\"M10.181 8.669a.5.5 0 01.323.467v10.808a1.247 1.247 0 102.493 0V9.136a.5.5 0 01.324-.467 4.488 4.488 0 10-3.14 0z\"/><path d=\"M16.481 17.575a.992.992 0 00-1.058.926 1.007 1.007 0 00.888 1.1 26.014 26.014 0 013.865.6.5.5 0 010 .968 35.953 35.953 0 01-8.174.79 35.953 35.953 0 01-8.174-.79.5.5 0 010-.968 26.014 26.014 0 013.865-.6 1.007 1.007 0 00.888-1.1.99.99 0 00-1.058-.926c-7.49.682-7.49 2.513-7.49 3.115C.033 23.761 9.192 24 12 24s11.967-.239 11.967-3.31c0-.602 0-2.433-7.486-3.115z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});