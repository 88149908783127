define("ember-svg-jar/inlined/road-sign-speed-limit-60-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-speed-limit-60-alternate</title><path d=\"M15.9 7.2a3.6 3.6 0 00-3.6 3.6v2.6a3.6 3.6 0 107.2 0v-2.6a3.6 3.6 0 00-3.6-3.6zm1.6 6.2a1.6 1.6 0 11-3.2 0v-2.6a1.6 1.6 0 113.2 0z\"/><path d=\"M8.1 9.8a.245.245 0 01-.081-.476A2.183 2.183 0 018.75 9.2h.65a1 1 0 000-2h-.65a4.255 4.255 0 00-4.25 4.25v1.95a3.6 3.6 0 103.6-3.6zm0 5.2a1.6 1.6 0 111.6-1.6A1.6 1.6 0 018.1 15z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});