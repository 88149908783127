define("ember-svg-jar/inlined/ui-webpage-template-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ui-webpage-template-add</title><path d=\"M18.5 24a2 2 0 002-2V2a2 2 0 00-2-2h-13a2 2 0 00-2 2v20a2 2 0 002 2zm-5-2.75H13a.25.25 0 00-.25.25v.5a.75.75 0 01-1.5 0v-.5a.25.25 0 00-.25-.25h-.5a.75.75 0 010-1.5h.5a.25.25 0 00.25-.25V19a.75.75 0 011.5 0v.5a.25.25 0 00.25.25h.5a.75.75 0 010 1.5zM5.5 2.5A.5.5 0 016 2h12a.5.5 0 01.5.5v3a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5zm0 5.5a.5.5 0 01.5-.5h12a.5.5 0 01.5.5v3a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5zm0 5.5A.5.5 0 016 13h12a.5.5 0 01.5.5v3.25a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5z\"/><circle cx=\"15.75\" cy=\"15\" r=\"1.25\"/><circle cx=\"15.75\" cy=\"9.501\" r=\"1.25\"/><circle cx=\"15.75\" cy=\"4\" r=\"1.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});