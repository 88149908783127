define("ember-svg-jar/inlined/car-actions-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-actions-check</title><path d=\"M5.25 12.75h-4A.25.25 0 001 13v.5a1.252 1.252 0 001.25 1.25h2A1.252 1.252 0 005.5 13.5V13a.25.25 0 00-.25-.25zM11.851 11.75a.246.246 0 00.171-.068 7.954 7.954 0 018.144-1.72.251.251 0 00.334-.236V8.207a1.258 1.258 0 00-.366-.884l-1.085-1.085a.5.5 0 01-.111-.168L16.888.944A1.494 1.494 0 0015.5 0H5.171a1.5 1.5 0 00-1.393.943l-1.94 4.851a.5.5 0 01-.111.168L.22 7.47A.747.747 0 000 8v2.5a1.252 1.252 0 001.25 1.25zm-7.4-7.093l1-2.5A.25.25 0 015.679 2h9.308a.25.25 0 01.232.157l1 2.5a.25.25 0 01-.232.343H4.679a.25.25 0 01-.207-.11.254.254 0 01-.025-.233z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm3.474 5.355l-2.905 3.874a1.747 1.747 0 01-1.278.7h-.125a1.746 1.746 0 01-1.237-.515l-1.5-1.5a1 1 0 011.415-1.414l1.094 1.094a.25.25 0 00.377-.026l2.559-3.413a1 1 0 111.6 1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});