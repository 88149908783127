define("ember-svg-jar/inlined/folder-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-1-alternate</title><path d=\"M18 18.5H6a2 2 0 01-2-2v-9a2 2 0 012-2h2.5a2 2 0 011.6.8l.749 1a.5.5 0 00.4.2H18a2 2 0 012 2v7a2 2 0 01-2 2zM6 8v8a.5.5 0 00.5.5h11a.5.5 0 00.5-.5v-6a.5.5 0 00-.5-.5H11a2 2 0 01-1.6-.8l-.75-1a.5.5 0 00-.4-.2H6.5A.5.5 0 006 8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});