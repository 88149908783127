define("ember-svg-jar/inlined/arrow-thick-circle-down-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-circle-down-2</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm6.549 12.7l-5.825 5.61a1.059 1.059 0 01-1.446.006L5.456 12.8a.973.973 0 01-.019-1.4l.3-.295a1.072 1.072 0 011.452-.042l2.573 2.3c.41.366.746.216.746-.334v-6.5a1 1 0 011-1h1a1 1 0 011 1v6.485c0 .55.332.7.737.324l2.588-2.373a1.055 1.055 0 011.433.035l.3.3a.982.982 0 01-.017 1.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});