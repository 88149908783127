define("ember-svg-jar/inlined/bomb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bomb</title><path d=\"M14.382 7.6a1 1 0 01-.632-.93V6a1 1 0 00-1-1h-3a1 1 0 00-1 1v.666a1 1 0 01-.632.93 8.5 8.5 0 106.264 0zM20.25 2.5a1 1 0 00-1 1 1 1 0 01-2 0 3.5 3.5 0 00-6.95-.584.5.5 0 00.493.584h1.022a.5.5 0 00.483-.375 1.5 1.5 0 012.952.375 3 3 0 006 0 1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});