define("ember-svg-jar/inlined/presentation-projector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>presentation-projector</title><path d=\"M24 16.5v-7A1.5 1.5 0 0022.5 8h-.254a.249.249 0 00-.25.258V8.5a7 7 0 01-14 0v-.242A.249.249 0 007.754 8H1.5A1.5 1.5 0 000 9.5v7A1.5 1.5 0 001.5 18h21a1.5 1.5 0 001.5-1.5zM3.5 13.75a.75.75 0 01-1.5 0v-2.5a.75.75 0 011.5 0zm3 0a.75.75 0 01-1.5 0v-2.5a.75.75 0 011.5 0zM1.5 20a1 1 0 002 0v-.75a.249.249 0 00-.25-.25h-1.5a.249.249 0 00-.25.25zM20.5 20a1 1 0 002 0v-.75a.249.249 0 00-.25-.25h-1.5a.249.249 0 00-.25.25z\"/><path d=\"M15 14a5.5 5.5 0 10-5.5-5.5A5.507 5.507 0 0015 14zm0-9a3.5 3.5 0 11-3.5 3.5A3.5 3.5 0 0115 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});