define("ember-svg-jar/inlined/flag-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flag-settings</title><path d=\"M23.382 2.174a1.586 1.586 0 00-1.616-.1 12.136 12.136 0 01-4.126.885 18.636 18.636 0 01-4.74-.979C9.543 1 8.38.768 5.806 1.1A1.5 1.5 0 004.5 2.59v15.02a1.5 1.5 0 001.672 1.49c2.255-.259 3.305-.039 6.442.879A19.314 19.314 0 0017.64 21a14.09 14.09 0 005.08-1.152c.672-.256 1.28-.6 1.28-1.528V3.4a1.5 1.5 0 00-.618-1.226zm-5.237 9.133l.767.828a1.248 1.248 0 01-1.189 2.065l-1.1-.255a.493.493 0 00-.579.337l-.329 1.083a1.242 1.242 0 01-2.378 0l-.33-1.083a.492.492 0 00-.579-.337l-1.1.255a1.248 1.248 0 01-1.189-2.067l.767-.828a.5.5 0 000-.676l-.763-.829a1.248 1.248 0 011.19-2.067l1.1.254a.486.486 0 00.579-.337l.33-1.082a1.242 1.242 0 012.378 0l.329 1.082a.49.49 0 00.579.337l1.1-.254A1.247 1.247 0 0118.912 9.8l-.768.828a.5.5 0 00.001.679zM1.5 0A1.5 1.5 0 000 1.5v21a1.5 1.5 0 003 0v-21A1.5 1.5 0 001.5 0z\"/><path d=\"M17.125 10.969a2.6 2.6 0 10-2.6 2.6 2.6 2.6 0 002.6-2.6zm-2.6 1.1a1.1 1.1 0 111.1-1.1 1.1 1.1 0 01-1.097 1.097z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});