define("ember-svg-jar/inlined/allowances-no-bikinis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>allowances-no-bikinis</title><path d=\"M6.322 14.87l-.246.913a.5.5 0 00.3.6 10.821 10.821 0 014.342 3.142 5.78 5.78 0 01.588.848.25.25 0 00.219.13h.879a.25.25 0 00.219-.13 5.78 5.78 0 01.588-.848 9.891 9.891 0 01.913-.968.25.25 0 00.007-.36l-3.621-3.621a.251.251 0 00-.177-.073H6.805a.5.5 0 00-.483.367zM6.488 11.866a3.911 3.911 0 001.939.622l-2.022-2.023a6.62 6.62 0 00-.154.929.524.524 0 00.237.472z\"/><path d=\"M24 12a12 12 0 10-12 12 12.013 12.013 0 0012-12zM12 2a9.984 9.984 0 017.5 16.589l-2.132-2.132c.062-.026.132-.057.185-.077a.5.5 0 00.3-.6l-.247-.913a.5.5 0 00-.483-.37h-1.709l-2.559-2.559a3.923 3.923 0 004.157-.075.524.524 0 00.237-.472 5.367 5.367 0 00-1.281-3.239V5.5a.75.75 0 00-1.5 0v2.457a5.714 5.714 0 00-2.167 3.43L10.494 9.58a5.5 5.5 0 00-1.462-1.623V5.5a.75.75 0 00-1.5 0v1.118L5.411 4.5A9.948 9.948 0 0112 2zm0 20A10.011 10.011 0 012 12a9.835 9.835 0 012.048-6.038l13.99 13.99A9.806 9.806 0 0112 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});