define("ember-svg-jar/inlined/house-signal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>house-signal</title><path d=\"M12.327 11.118a.5.5 0 00-.654 0l-7.5 6.5a.5.5 0 00.327.882h2.25a.25.25 0 01.25.25V23a1 1 0 001 1h1.5a1 1 0 001-1v-2.879a.573.573 0 01.5-.621h2a.573.573 0 01.5.625V23a1 1 0 001 1H16a1 1 0 001-1v-4.25a.25.25 0 01.25-.25h2.25a.5.5 0 00.327-.878zM7.894 8.779a1 1 0 101.394 1.434 3.919 3.919 0 015.424 0 1 1 0 001.394-1.434 5.928 5.928 0 00-8.212 0z\"/><path d=\"M17.454 7.634a1 1 0 00.7-1.717 8.883 8.883 0 00-12.3 0 1 1 0 001.389 1.434 6.87 6.87 0 019.514 0 1 1 0 00.697.283z\"/><path d=\"M20.2 3.3a11.836 11.836 0 00-16.4 0 1 1 0 101.4 1.434 9.83 9.83 0 0113.606 0A1 1 0 1020.2 3.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});