define("ember-svg-jar/inlined/skiing-bobsled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skiing-bobsled</title><path d=\"M10.547 18.034l-1.393.672a.5.5 0 01-.617-.15l-.44-.588a.5.5 0 01.191-.754c.894-.412 1.979-.932 3.146-1.5a.5.5 0 00.277-.4 7.987 7.987 0 00-1.425-5.4A7.41 7.41 0 007.232 7.35a.5.5 0 00-.408 0L3.141 9.005a4.946 4.946 0 00-2.93 5.949 4.766 4.766 0 002.421 2.977 4.323 4.323 0 001.917.436 5.075 5.075 0 00.932-.089.5.5 0 01.492.192l.61.814a.5.5 0 01-.182.75l-.863.416a1 1 0 00.869 1.8l5.009-2.416a1 1 0 00-.869-1.8zM23.9 12.632a1 1 0 00-1.335-.466l-.616.3a.5.5 0 01-.566-.092l-.624-.608a.5.5 0 01.122-.8l1.545-.794a1 1 0 00.541-.889V6.688a2.342 2.342 0 00-1.64-2 2.032 2.032 0 00-1.949.357.228.228 0 01-.181.05.168.168 0 01-.152-.178 3.461 3.461 0 00-.622-2.4 2.436 2.436 0 00-1.977-.867c-1.134 0-2.043 1.1-2.564 1.918a2.014 2.014 0 01-.725.681l-2.609 1.43-1.348.604a.249.249 0 00-.038.434 9.154 9.154 0 012.355 2.343 9.563 9.563 0 011.736 5.354.251.251 0 00.362.221 616.978 616.978 0 004.447-2.237.5.5 0 01.575.087l.57.556a.5.5 0 01-.132.808l-1.518.733a1 1 0 10.869 1.8l5.008-2.416a1 1 0 00.466-1.334zM17.6 6.75a.5.5 0 01-.269.318L14.7 8.3a.5.5 0 01-.63-.177 6.656 6.656 0 00-.768-.953.5.5 0 01-.133-.422.5.5 0 01.254-.363l1.435-.785a1 1 0 00.413-.43 5.542 5.542 0 01.982-1.384.471.471 0 01.423-.112c.436.1.418.976.344 1.37a1 1 0 00.149.751l.364.546a.5.5 0 01.067.409z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});