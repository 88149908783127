define("ember-svg-jar/inlined/programming-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-search</title><path d=\"M-.27 10.755a10.98 10.98 0 0017.322 8.983.5.5 0 01.642.054l4.015 4.014a1.5 1.5 0 102.121-2.121l-4.023-4.023a.5.5 0 01-.056-.639A10.994 10.994 0 10-.27 10.755zm11-9a9 9 0 11-9 9 9.01 9.01 0 019-9z\"/><path d=\"M14.024 13.129a1 1 0 001.538 1.281l2.5-3a1 1 0 000-1.281l-2.5-3a1 1 0 00-1.538 1.281l1.834 2.2a.251.251 0 010 .32zM6.794 14.77a1 1 0 00.768-1.641l-1.834-2.2a.251.251 0 010-.32l1.834-2.2a1 1 0 00-1.538-1.28l-2.5 3a1 1 0 000 1.281l2.5 3a1 1 0 00.77.36zM9.441 14.373a1.016 1.016 0 00.352.064 1 1 0 00.936-.649l2-5.334a1 1 0 10-1.873-.7l-2 5.334a1 1 0 00.585 1.285z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});