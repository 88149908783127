define("ember-svg-jar/inlined/beacon-remote-plane-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beacon-remote-plane-1</title><path d=\"M21.137 3.673a3.541 3.541 0 00-1.181-2.492 3.545 3.545 0 00-5.1-.161L12.477 3.4 5.3 1.511a2.256 2.256 0 00-2.172.589l-.572.572A2.255 2.255 0 002.99 6.2l3.992 2.394a.249.249 0 01.058.379l-2.657 3.013a.252.252 0 01-.184.085l-.624.009a2.244 2.244 0 00-1.811.587l-1.289 1.18a1.628 1.628 0 00.451 2.618 10.807 10.807 0 012.331 1.412 15.621 15.621 0 011.414 2.334 1.628 1.628 0 002.651.416l1.137-1.243a2.267 2.267 0 00.6-1.822l.009-.624a.249.249 0 01.084-.184l2.275-2.007a1 1 0 10-1.323-1.5L7.412 15.62a1 1 0 00-.339.736v1.438a.313.313 0 01-.077.229l-.546.6a.25.25 0 01-.4-.047 12.4 12.4 0 00-1.25-2.059 9.052 9.052 0 00-2.232-1.423.25.25 0 01-.046-.4l.608-.556a.262.262 0 01.219-.067c.06.011 1.437 0 1.437 0a1 1 0 00.737-.338L9.534 9.17A1 1 0 009.3 7.651L4.018 4.483a.255.255 0 01-.048-.4l.573-.573a.25.25 0 01.244-.066l7.736 2.036a1 1 0 00.962-.26l2.788-2.787a1.5 1.5 0 012.213.1.97.97 0 00.112.11 1.491 1.491 0 01.534 1.1.22.22 0 00.2.211 8.466 8.466 0 011.483.274.248.248 0 00.211-.036.252.252 0 00.106-.186 3.129 3.129 0 00.005-.333z\"/><path d=\"M18.5 5.409a6.952 6.952 0 00-4.949 2.05 1 1 0 101.414 1.414 5 5 0 017.07 0 1 1 0 101.414-1.414 6.952 6.952 0 00-4.949-2.05zM24 14.5a1.5 1.5 0 00-1.5-1.5h-8a1.5 1.5 0 00-1.5 1.5v8a1.5 1.5 0 001.5 1.5h8a1.5 1.5 0 001.5-1.5zm-4.659 5.777a.247.247 0 00-.091.192v1.281a.75.75 0 01-1.5 0v-1.277a.251.251 0 00-.091-.193 1.278 1.278 0 01-.492-.991 1.333 1.333 0 112.666 0 1.272 1.272 0 01-.492.985zm2.409-.777a.75.75 0 01-.75-.75 2.5 2.5 0 00-5 0 .75.75 0 01-1.5 0 4 4 0 018 0 .75.75 0 01-.75.75z\"/><path d=\"M22.035 10.373a5 5 0 00-7.07 0 1 1 0 001.414 1.414 3.074 3.074 0 014.242 0 1 1 0 001.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});