define("ember-svg-jar/inlined/button-zigzag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-zigzag</title><path d=\"M.5 10.416a4.658 4.658 0 004.652 4.653h13.7a2.153 2.153 0 110 4.3H2.718a1.25 1.25 0 000 2.5h16.133a4.653 4.653 0 100-9.3H5.148a2.153 2.153 0 010-4.306h11.967a.25.25 0 01.25.25V10.9a1 1 0 00.617.923.989.989 0 00.383.077 1 1 0 00.707-.293l3.889-3.887a1 1 0 000-1.414l-3.889-3.889a1 1 0 00-1.707.707v2.389a.25.25 0 01-.25.25H5.148A4.658 4.658 0 00.5 10.416z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});