define("ember-svg-jar/inlined/video-file-camera-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-camera-alternate</title><path d=\"M21.707 5.707L16.293.293A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.465a.5.5 0 01.353.146l4.536 4.536a.5.5 0 01.146.353z\"/><path d=\"M17.708 16.345a.5.5 0 01-.631.483l-1.99-.542a.249.249 0 00-.314.21A1.746 1.746 0 0113.042 18h-4.5a1.749 1.749 0 01-1.75-1.75v-4.5A1.749 1.749 0 018.542 10h4.5a1.741 1.741 0 011.728 1.5.25.25 0 00.107.182.255.255 0 00.208.035l1.992-.543a.5.5 0 01.631.483z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});