define("ember-svg-jar/inlined/show-theater-mask-sad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>show-theater-mask-sad</title><path d=\"M22.351.267A1.448 1.448 0 0021 .093c-3.955 1.47-14.051 1.47-18 0a1.453 1.453 0 00-1.351.174A1.511 1.511 0 001 1.507V12c0 6.753 8.774 12 11 12s11-5.247 11-12V1.507a1.511 1.511 0 00-.649-1.24zm-17.23 7.61a.75.75 0 111.3-.753 1.3 1.3 0 002.165 0 .75.75 0 011.3.753 2.75 2.75 0 01-4.759 0zM16 18.25H8a.75.75 0 01-.75-.75c0-2.068 2.131-3.75 4.75-3.75s4.75 1.682 4.75 3.75a.75.75 0 01-.75.75zm2.879-10.373a2.75 2.75 0 01-4.759 0 .75.75 0 111.3-.753 1.3 1.3 0 002.165 0 .75.75 0 011.3.753z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});