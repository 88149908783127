define("ember-svg-jar/inlined/phone-action-wifi-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-wifi-1</title><path d=\"M12 11.475V18.5a1 1 0 01-1 1H4a1 1 0 01-1-1v-11a1 1 0 011-1h4.856a.246.246 0 00.244-.279 9.19 9.19 0 01-.078-1.954A.247.247 0 008.777 4H3.5A2.5 2.5 0 001 6.5v15A2.5 2.5 0 003.5 24h8a2.5 2.5 0 002.5-2.5v-9.259a.243.243 0 00-.16-.228 7.5 7.5 0 01-1.449-.741.249.249 0 00-.391.203z\"/><circle cx=\"16.5\" cy=\"8.765\" r=\"1.75\"/><path d=\"M22.621 2.142a9.817 9.817 0 00-12.242 0 1 1 0 101.242 1.567 7.826 7.826 0 019.758 0 1.013 1.013 0 001.4-.163 1 1 0 00-.158-1.404z\"/><path d=\"M20.146 4.966a5.622 5.622 0 00-7.293 0 1 1 0 00-.116 1.409 1.017 1.017 0 001.409.116 3.587 3.587 0 014.707 0 1 1 0 101.293-1.525z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});