define("ember-svg-jar/inlined/lucide-package-open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20.91 8.84L8.56 2.23a1.93 1.93 0 00-1.81 0L3.1 4.13a2.12 2.12 0 00-.05 3.69l12.22 6.93a2 2 0 001.94 0L21 12.51a2.12 2.12 0 00-.09-3.67z\"/><path d=\"M3.09 8.84l12.35-6.61a1.93 1.93 0 011.81 0l3.65 1.9a2.12 2.12 0 01.1 3.69L8.73 14.75a2 2 0 01-1.94 0L3 12.51a2.12 2.12 0 01.09-3.67zM12 22v-9\"/><path d=\"M20 13.5v3.37a2.06 2.06 0 01-1.11 1.83l-6 3.08a1.93 1.93 0 01-1.78 0l-6-3.08A2.06 2.06 0 014 16.87V13.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});