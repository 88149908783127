define("ember-svg-jar/inlined/business-magic-rabbit-hide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-magic-rabbit-hide</title><path d=\"M4.612 15.184H6.86a.249.249 0 01.245.3l-1.3 6.888a1.359 1.359 0 00.309 1.143A1.422 1.422 0 007.2 24h9.312a1.421 1.421 0 001.078-.489 1.352 1.352 0 00.309-1.143L16.6 15.48a.249.249 0 01.246-.3h2.544a.98.98 0 000-1.959H4.612a.98.98 0 100 1.959zM19.837 3.34c1.469-1.47-.98-4.408-2.937-2.94-.9.672-2.9 3.769-3.634 4.935a.249.249 0 01-.278.107 3.471 3.471 0 00-1.972 0 .249.249 0 01-.278-.107C10 4.169 8 1.072 7.1.4 5.143-1.068 2.694 1.87 4.163 3.34c.767.766 3.326 2.727 4.406 3.546a.251.251 0 01.064.327 3.9 3.9 0 00-.551 2 3.612 3.612 0 00.579 1.956.253.253 0 00.194.093h6.425a.253.253 0 00.218-.127 4.436 4.436 0 00.42-1.922 3.906 3.906 0 00-.55-2 .249.249 0 01.063-.327c1.08-.819 3.639-2.78 4.406-3.546zm-9.593 5.967a.735.735 0 11.735-.736.734.734 0 01-.735.736zm3.429 0a.735.735 0 11.734-.736.735.735 0 01-.734.736z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});