define("ember-svg-jar/inlined/shipment-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-sync</title><path d=\"M9 3.625a.249.249 0 00.25.25h5.35a.25.25 0 00.177-.427L11.9.564a1.511 1.511 0 00-1.065-.439H9.25a.249.249 0 00-.25.25zM7.25 3.875a.25.25 0 00.25-.25V.375a.25.25 0 00-.25-.25H5.664A1.511 1.511 0 004.6.564L1.719 3.448a.25.25 0 00.177.427zM15.536 8.967a.249.249 0 00.214-.247v-3.1a.25.25 0 00-.25-.25H1a.249.249 0 00-.25.25v9a1.5 1.5 0 001.5 1.5h5.89a.248.248 0 00.246-.207 8.511 8.511 0 017.15-6.946zM22.642 19.746a1 1 0 10-1.892-.646 4.091 4.091 0 01-6.919 1.419.249.249 0 01.009-.344l1.721-1.722a.5.5 0 00-.354-.853H10.75a.5.5 0 00-.5.5v4.457a.5.5 0 00.854.353l.97-.97a.253.253 0 01.18-.073.249.249 0 01.178.079 6.086 6.086 0 0010.21-2.2z\"/><path d=\"M22.942 11.647a.5.5 0 00-.545.109l-1.026 1.026a.252.252 0 01-.18.073.255.255 0 01-.178-.077A6.1 6.1 0 0010.818 15a1 1 0 101.892.648 4.092 4.092 0 016.9-1.445.249.249 0 01-.007.346l-1.659 1.66a.5.5 0 00.354.853h4.452a.5.5 0 00.5-.5v-4.453a.5.5 0 00-.308-.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});