define("ember-svg-jar/inlined/clothes-design-sewing-machine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>clothes-design-sewing-machine</title><path d=\"M24 2a2 2 0 00-2-2H8.25a.5.5 0 00-.5.5v4a.75.75 0 01-1.5 0V.7a.5.5 0 00-.639-.484A4.989 4.989 0 002 5v5a1 1 0 001 1h1a.5.5 0 01.5.5V16a1 1 0 002 0 .5.5 0 01.5-.5 1 1 0 000-2 .5.5 0 01-.5-.5v-1.5A.5.5 0 017 11h1a1 1 0 001-1V8a1.5 1.5 0 011.5-1.5h3A1.5 1.5 0 0115 8v9.5a.5.5 0 01-.5.5h-12A2.5 2.5 0 000 20.5v1A2.5 2.5 0 002.5 24h20a1.5 1.5 0 001.5-1.5zm-4.5 16a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});