define("ember-svg-jar/inlined/performance-increase-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>performance-increase-2</title><path d=\"M13.177 5.909a.25.25 0 01-.354 0L10.914 4a2 2 0 00-2.828 0L4.293 7.793a1 1 0 101.414 1.414l3.616-3.616a.25.25 0 01.354 0L11.586 7.5a2 2 0 002.828 0l4.013-4.013a.25.25 0 01.353 0l1.366 1.367A.5.5 0 0020.5 5a.489.489 0 00.191-.038A.5.5 0 0021 4.5v-4a.5.5 0 00-.5-.5h-4a.5.5 0 00-.354.854l.867.866a.25.25 0 010 .353zM3.167 11.5c-.369 0-.667.224-.667.5v8a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-8c0-.276-.3-.5-.667-.5zM13.833 14.5h-2.666c-.369 0-.667.224-.667.5v5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-5c0-.276-.3-.5-.667-.5zM18 7.965v12.071a.483.483 0 00.5.464h3a.483.483 0 00.5-.464V7.965c0-.257-.3-.465-.667-.465h-2.666c-.367 0-.667.208-.667.465zM23 22H1a1 1 0 000 2h22a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});