define("ember-svg-jar/inlined/champagne-glass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>champagne-glass</title><path d=\"M16.037 1.382A1.509 1.509 0 0014.54 0H9.46a1.506 1.506 0 00-1.5 1.377L7 13a5.008 5.008 0 003.636 4.81.5.5 0 01.364.481V21.5a.5.5 0 01-.5.5h-1a1 1 0 000 2h5a1 1 0 000-2h-1a.5.5 0 01-.5-.5v-3.209a.5.5 0 01.364-.481A5.008 5.008 0 0017 13c0-.066-.957-11.553-.963-11.618zM13.5 10.25a1 1 0 111-1 1 1 0 01-1 1zM9.88 2.459a.5.5 0 01.5-.459h3.242a.5.5 0 01.5.459l.249 3a.5.5 0 01-.5.541h-3.742a.5.5 0 01-.5-.541zM10 12.25a1 1 0 111-1 1 1 0 01-1 1zm2 2a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});