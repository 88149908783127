define("ember-svg-jar/inlined/single-neutral-actions-view.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-view</title><path d=\"M12.19 10.991a.251.251 0 00.092-.236.246.246 0 00-.159-.2q-.3-.114-.625-.232l-.628-.23a.6.6 0 01-.138-.311 2.573 2.573 0 01.058-1.3 5.618 5.618 0 001.466-4.314C12.256 1.756 10.677 0 8.5 0S4.744 1.756 4.744 4.174A5.605 5.605 0 006.2 8.471a2.453 2.453 0 01.073 1.316.615.615 0 01-.135.309l-.631.232c-2.447.9-4.215 1.55-4.754 2.626A9.173 9.173 0 000 16.5a.5.5 0 00.5.5h8.758a.25.25 0 00.249-.264c0-.078-.007-.157-.007-.236a6.987 6.987 0 012.69-5.509z\"/><path d=\"M21.173 19.759a.25.25 0 01-.034-.311 5.513 5.513 0 10-1.692 1.691.25.25 0 01.311.034l2.535 2.534a1 1 0 001.414-1.414zM13 16.5a3.5 3.5 0 113.5 3.5 3.5 3.5 0 01-3.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});