define("ember-svg-jar/inlined/office-file-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-file-graph</title><path d=\"M15.523 9.681l-2.37 3.46a.5.5 0 00.029.6l2.689 3.219a.256.256 0 00.39 0 5.474 5.474 0 00-.35-7.325.254.254 0 00-.388.046zM11.8 14.428a.5.5 0 00-.384-.18H6.859a.257.257 0 00-.253.3 5.487 5.487 0 008.049 3.763.255.255 0 00.066-.383zM6.859 12.748h4.482a.5.5 0 00.412-.218l2.534-3.7a.255.255 0 00-.1-.375 5.485 5.485 0 00-7.579 3.994.257.257 0 00.251.299z\"/><path d=\"M21.707 5.705L16.293.291A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.412a1 1 0 00-.293-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.465a.5.5 0 01.353.147l4.536 4.533a.5.5 0 01.146.353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});