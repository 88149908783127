define("ember-svg-jar/inlined/messages-people-woman-bubble-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-woman-bubble-square</title><path d=\"M11.174 17.385a.255.255 0 01-.174-.224v-.678a5.244 5.244 0 003.015-.768.5.5 0 00.132-.743c-.421-.5-.713-1.154-.842-3.06C13.059 8.277 10.607 7 8.5 7s-4.557 1.277-4.8 4.912c-.129 1.9-.421 2.563-.842 3.06a.5.5 0 00.131.743A5.259 5.259 0 006 16.483v.679a.254.254 0 01-.174.224c-2.868 1.062-4.434 1.821-5.034 3.022A7.584 7.584 0 000 23.5a.5.5 0 00.5.5h6.9a.25.25 0 00.231-.346L6.14 20.049a.248.248 0 01.06-.279.251.251 0 01.284-.039 4.679 4.679 0 004.21.037.25.25 0 01.338.327l-1.591 3.553A.251.251 0 009.67 24h6.83a.5.5 0 00.5-.5 7.578 7.578 0 00-.792-3.091c-.601-1.209-2.166-1.963-5.034-3.024z\"/><path d=\"M22 0H9a2 2 0 00-2 2v3a1 1 0 002 0V2.25A.25.25 0 019.25 2h12.5a.25.25 0 01.25.25v6.5a.25.25 0 01-.25.25H20.5a1 1 0 00-.707.293l-1.866 1.866a.25.25 0 01-.427-.177V10a1 1 0 00-1-1H15a1 1 0 000 2h.5v3a1 1 0 001.707.707L20.914 11H22a2 2 0 002-2V2a2 2 0 00-2-2z\"/><circle cx=\"12.502\" cy=\"5.75\" r=\"1\"/><circle cx=\"15.752\" cy=\"5.75\" r=\"1\"/><circle cx=\"19.003\" cy=\"5.75\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});