define("ember-svg-jar/inlined/data-file-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-lock</title><path d=\"M12.292 12.089l1.644-2.321a.249.249 0 01.332-.07l1.507.9A1 1 0 0017 10.451a.9.9 0 00.105-.123l2.688-3.734a1 1 0 10-1.624-1.168l-2.015 2.8a.252.252 0 01-.332.068L14.3 7.388a1 1 0 00-1.331.279l-2.311 3.265a1 1 0 001.631 1.157z\"/><path d=\"M21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.683a.244.244 0 00.184.235 4.459 4.459 0 011.429.687A.247.247 0 008 10.4V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-9.75a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25H22a2 2 0 002-2V4.414A2 2 0 0023.414 3z\"/><path d=\"M1.5 24h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.389-1.488.123.123 0 01-.111-.119V14.25a3.5 3.5 0 00-7 0v1.134a.114.114 0 01-.113.128A1.5 1.5 0 000 17v5.5A1.5 1.5 0 001.5 24zM5 21.25a1 1 0 111-1 1 1 0 01-1 1zm-1.5-7a1.5 1.5 0 013 0v1.134a.12.12 0 01-.106.116H3.612a.122.122 0 01-.112-.116z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});