define("ember-svg-jar/inlined/flying-insect-dragonfly", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flying-insect-dragonfly</title><path d=\"M7.927 16.181l-5 3.5a1 1 0 101.146 1.638l5-3.5a1 1 0 00-1.146-1.638zM7.927 20.1l-2.974 2.08A1 1 0 006.1 23.819l2.973-2.082A1 1 0 107.927 20.1zM16.073 16.181a1 1 0 10-1.146 1.638l5 3.5a1 1 0 101.146-1.638zM16.073 20.1a1 1 0 10-1.146 1.638L17.9 23.82a1 1 0 001.147-1.639zM15.079 7.211a.5.5 0 00.826-.081C16.572 5.889 18.21 4 22 4a1 1 0 000-2c-4.52 0-6.693 2.239-7.693 3.936a.5.5 0 00.125.65 3.986 3.986 0 01.647.625zM2 4c3.808 0 5.441 1.893 6.1 3.139a.5.5 0 00.828.082 4.015 4.015 0 01.643-.627.5.5 0 00.129-.648C8.7 4.248 6.529 2 2 2a1 1 0 000 2zM11.157 14.62a.5.5 0 00-.647.479v7.151a1.5 1.5 0 003 0V15.1a.5.5 0 00-.647-.478 2.874 2.874 0 01-1.706 0z\"/><path d=\"M22.82 13.076c-1.02-.5-8.49-4.246-8.49-4.246a2.495 2.495 0 00-4.637-.012S2.2 12.576 1.18 13.076a2.312 2.312 0 00-.85 2.581A2.208 2.208 0 002.51 17.5c1.6 0 6.055-3.144 7.749-5.46a2.28 2.28 0 001.751 1.21 2.272 2.272 0 001.741-1.2c1.7 2.315 6.141 5.447 7.739 5.447a2.208 2.208 0 002.18-1.843 2.312 2.312 0 00-.85-2.578zM13.51 6.047v-.8a1.493 1.493 0 00-.5-1.11V1a1 1 0 00-2 0v3.14a1.49 1.49 0 00-.5 1.11v.8a3.936 3.936 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});