define("ember-svg-jar/inlined/ecology-leaf-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ecology-leaf-search</title><path d=\"M23.634 21.865l-4.574-4.573a.5.5 0 01-.051-.647 10.512 10.512 0 10-2.363 2.363.5.5 0 01.646.051l4.574 4.574a1.25 1.25 0 001.768-1.768zM10.5 19a8.5 8.5 0 118.5-8.5 8.51 8.51 0 01-8.5 8.5z\"/><path d=\"M13.013 4.48a.553.553 0 00-.443.165 7.119 7.119 0 01-2.559 1.611A7.588 7.588 0 007.725 7.6a2.991 2.991 0 00-1.108 2.6 2.377 2.377 0 00.786 1.4.548.548 0 01.141.6 18.77 18.77 0 00-.961 3.637.547.547 0 001.083.153c.365-2.4 1.553-5.923 3.544-7.333a.547.547 0 11.635.89 7.552 7.552 0 00-2.253 3.446.547.547 0 00.3.7 2.292 2.292 0 00.827.185 3.535 3.535 0 002.811-1.738 7.986 7.986 0 00-.112-7.418.55.55 0 00-.405-.242z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});