define("ember-svg-jar/inlined/love-song", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-song</title><path d=\"M20.549 11.991a.5.5 0 01-.734-.365 1.7 1.7 0 00-3.286-.332c-.679 1.368.546 4.17 1.014 5.144a.489.489 0 00.543.271c1.057-.215 4.033-.933 4.713-2.3a1.7 1.7 0 00-2.25-2.418zM20.67 8.023c.868-.917 3.6-3.99 3.276-5.96a2.192 2.192 0 00-4.235-.645.5.5 0 01-.84.139 2.192 2.192 0 00-3.8 1.969c.324 1.97 3.9 4 5.015 4.594a.488.488 0 00.584-.097zM13.4 5.977a1.5 1.5 0 00-1.351-.206l-8 2.666A1.5 1.5 0 003.027 9.86v8.64a.5.5 0 01-.5.5 2.5 2.5 0 102.5 2.5v-6.419a.5.5 0 01.342-.475l6-2a.5.5 0 01.658.474v2.42a.5.5 0 01-.5.5 2.5 2.5 0 102.5 2.5V7.193a1.5 1.5 0 00-.627-1.216zm-1.373 3.942a.5.5 0 01-.342.475l-6 2a.5.5 0 01-.658-.475v-1.338a.5.5 0 01.342-.474l6-2a.5.5 0 01.658.475z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});