define("ember-svg-jar/inlined/phone-actions-smile-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-smile-1</title><path d=\"M17.422.01a6.57 6.57 0 00-5.863 9.551l-1.415 2.972a1 1 0 00.2 1.137 1.011 1.011 0 001.137.2l2.971-1.415A6.576 6.576 0 1017.422.01zm0 11.156a4.556 4.556 0 01-2.408-.69 1 1 0 00-.957-.054l-.9.427.427-.9a1 1 0 00-.05-.949 4.574 4.574 0 113.888 2.17z\"/><circle cx=\"15.273\" cy=\"5.32\" r=\".75\"/><circle cx=\"19.773\" cy=\"5.32\" r=\".75\"/><path d=\"M18.939 7.57h-2.831a.493.493 0 00-.487.62 2 2 0 003.8 0 .492.492 0 00-.482-.62zM15.5 15.773a3.116 3.116 0 00-4.4 0l-.827.828a4.25 4.25 0 00-1.346 4.5 11.348 11.348 0 01-6.032-6.031 4.247 4.247 0 004.5-1.346l.83-.829a3.116 3.116 0 000-4.4l-3.59-3.582a2.234 2.234 0 00-3.649.739 13.314 13.314 0 0017.357 17.355 2.234 2.234 0 00.737-3.649z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});