define("ember-svg-jar/inlined/medical-instrument-stethoscope", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-instrument-stethoscope</title><path d=\"M19.207 15.23a5.266 5.266 0 01-.914 0 .5.5 0 00-.543.5v1.02a5.25 5.25 0 01-10.5 0v-2.84a6.006 6.006 0 005-5.91V5a3.994 3.994 0 00-2.887-3.827.156.156 0 01-.113-.15V1a1 1 0 00-2 0v2.5a1 1 0 002 0 .119.119 0 01.066-.107.12.12 0 01.125.011A1.986 1.986 0 0110.25 5v3a4 4 0 01-8 0V5a1.986 1.986 0 01.809-1.6.12.12 0 01.125-.011.119.119 0 01.066.111 1 1 0 002 0V1a1 1 0 00-2 0v.023a.155.155 0 01-.113.15A3.994 3.994 0 00.25 5v3a6.006 6.006 0 005 5.91v2.84a7.25 7.25 0 0014.5 0v-1.021a.5.5 0 00-.543-.5z\"/><path d=\"M18.75 4.25a5 5 0 105 5 5.006 5.006 0 00-5-5zm0 8a3 3 0 113-3 3 3 0 01-3 3z\"/><circle cx=\"18.75\" cy=\"9.25\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});