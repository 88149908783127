define("ember-svg-jar/inlined/settings-toggle-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>settings-toggle-horizontal</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-6 10.5h-9a3.5 3.5 0 110-7h9a3.5 3.5 0 110 7zm3.529 6.5a3.515 3.515 0 01-3.529 3.5h-9a3.5 3.5 0 110-7h9a3.515 3.515 0 013.529 3.5z\"/><circle cx=\"7.5\" cy=\"6.972\" r=\"2\"/><circle cx=\"16.5\" cy=\"17.03\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});