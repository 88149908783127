define("ember-svg-jar/inlined/color-bucket-brush", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-bucket-brush</title><path d=\"M3.857 13.185a.25.25 0 01.143.226v5.339a1.5 1.5 0 003 0v-5.339a.25.25 0 01.143-.226 4.884 4.884 0 002.85-4.172.25.25 0 00-.25-.263H1.257a.25.25 0 00-.25.263 4.884 4.884 0 002.85 4.172zM11 1.25a1 1 0 00-1-1H1a1 1 0 00-1 1l.967 6.288a.25.25 0 00.247.212h8.572a.25.25 0 00.247-.212zM17.5 7.75c-3.23 0-6.5 1.2-6.5 3.5v9c0 2.3 3.27 3.5 6.5 3.5s6.5-1.2 6.5-3.5v-9c0-2.298-3.27-3.5-6.5-3.5zm4.5 8.5a2 2 0 01-4 0v-3.258a.251.251 0 00-.264-.249c-.076 0-.154.007-.236.007-2.923 0-4.5-1.064-4.5-1.5s1.577-1.5 4.5-1.5 4.5 1.064 4.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});