define("ember-svg-jar/inlined/messages-bubble-edit-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-edit-alternate</title><path d=\"M8.6 17.328a1 1 0 00-.662.038l-3.648 1.52a.25.25 0 01-.331-.316l.851-2.339a1 1 0 00-.275-1.089A7.038 7.038 0 012 9.9C2 5.546 6.486 2 12 2c4.934 0 9.034 2.843 9.843 6.564a.255.255 0 00.1.151l1.851 1.3A.13.13 0 0024 9.9C24 4.443 18.617 0 12 0S0 4.443 0 9.9a8.876 8.876 0 002.707 6.26l-1.6 4.4a1 1 0 001.325 1.265l5.959-2.483c.106.028.214.051.321.074a.25.25 0 00.23-.067l1.275-1.276a.25.25 0 00-.138-.424 11.968 11.968 0 01-1.479-.321z\"/><path d=\"M23.226 11.766a2.618 2.618 0 00-3.7 0l-6.679 6.679a.252.252 0 000 .354l3.353 3.353a.249.249 0 00.353 0l6.679-6.679a2.621 2.621 0 00-.006-3.707zM11.6 23.991l3.143-.629a.251.251 0 00.128-.422l-2.811-2.808a.25.25 0 00-.422.128l-.628 3.14a.5.5 0 00.588.588z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});