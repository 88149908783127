define("ember-svg-jar/inlined/modern-weapon-van-machine-gun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-weapon-van-machine-gun</title><circle cx=\"4.5\" cy=\"18.75\" r=\"2\"/><path d=\"M22.5 12.75H19a.5.5 0 01-.5-.5V9.457a1.5 1.5 0 00-.439-1.06l-1.236-1.236a.251.251 0 01-.023-.328A.962.962 0 0017 6.25V5.5a.25.25 0 01.25-.25h1A.751.751 0 0119 6v.5a.75.75 0 001.5 0 2.75 2.75 0 00-2.75-2.75h-.389a.539.539 0 01-.442-.272.5.5 0 00-.419-.228h-3a1 1 0 00-.943.666.494.494 0 01-.465.334H7.25a1 1 0 000 2h5a.25.25 0 01.25.25v.25a.5.5 0 00.5.5h.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353v2.379a.5.5 0 01-.5.5h-1.25a.5.5 0 01-.5-.5v-2.5a1.5 1.5 0 00-1.5-1.5H8.676a2.5 2.5 0 00-2.235 1.381L5.02 12.474a.5.5 0 01-.447.276H2.5a2.5 2.5 0 00-2.5 2.5v2.5a1 1 0 001 1h.036a.5.5 0 00.495-.428 3 3 0 015.862-.369 1.029 1.029 0 00.976.8h4.262a1.03 1.03 0 00.976-.8 3 3 0 015.786 0 1.029 1.029 0 00.976.8H23a1 1 0 001-1v-3.5a1.5 1.5 0 00-1.5-1.503zm-10.25-1a1 1 0 01-1 1H7.927a.5.5 0 01-.447-.724l.612-1.226a1 1 0 01.894-.553h2.264a1 1 0 011 1z\"/><circle cx=\"16.5\" cy=\"18.75\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});