define("ember-svg-jar/inlined/renewable-energy-wind-turbine-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>renewable-energy-wind-turbine-2</title><path d=\"M23 22h-7.507a.5.5 0 01-.493-.426l-1.111-7.405a.5.5 0 01.3-.537c.112-.046.222-.1.329-.153a.5.5 0 01.465 0 12.406 12.406 0 003.469 1.205 6.806 6.806 0 001.128.1 2.52 2.52 0 002.4-1.124.5.5 0 00-.183-.683l-4.884-2.82a.5.5 0 01-.249-.411 4 4 0 00-1.848-3.192.5.5 0 01-.231-.4C14.509 4.014 13.629 0 11.25 0a.5.5 0 00-.5.5v5.642a.5.5 0 01-.23.421 4 4 0 00-1.842 3.195.5.5 0 01-.234.4 12.352 12.352 0 00-2.777 2.4c-1.434 1.729-1.271 2.9-.882 3.568a.5.5 0 00.433.25.5.5 0 00.25-.067l4.885-2.82a.5.5 0 01.48-.011 3.864 3.864 0 00.451.2.5.5 0 01.32.543l-1.1 7.349a.5.5 0 01-.494.426H1a1 1 0 000 2h22a1 1 0 000-2zM10.674 9.932a2 2 0 112 2 2 2 0 01-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});