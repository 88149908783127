define("ember-svg-jar/inlined/road-sign-divided-highway-ahead", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-divided-highway-ahead</title><path d=\"M23.785 12.519a.733.733 0 000-1.038L12.519.215a.733.733 0 00-1.038 0L.215 11.481a.733.733 0 000 1.038l11.266 11.266a.734.734 0 001.038 0zm-8.46-1.119a1.253 1.253 0 01-.365-.881V8.228a.748.748 0 011.5 0v2.08a.249.249 0 00.073.177l1.557 1.556a1.241 1.241 0 01.365.882v.3a.249.249 0 00.251.249.734.734 0 01.527.215.748.748 0 010 1.058l-1 1a.751.751 0 01-.529.219.745.745 0 01-.286-.057.755.755 0 01-.243-.162l-1-1a.747.747 0 010-1.058.732.732 0 01.527-.215.245.245 0 00.177-.072.252.252 0 00.074-.177v-.09a.248.248 0 00-.073-.176zm-4.354 7.8v-2.58a.255.255 0 00-.073-.177l-3.7-3.7a.745.745 0 01-.22-.529v-2a.247.247 0 00-.073-.177.251.251 0 00-.178-.073.73.73 0 01-.527-.21.747.747 0 010-1.054l1-1a.732.732 0 01.243-.161.751.751 0 01.814.161l1 1a.748.748 0 01-.529 1.276.249.249 0 00-.25.25V11.8a.253.253 0 00.073.177l3.549 3.555a1.24 1.24 0 01.365.881V19.2a.748.748 0 01-1.5 0zm1.246-8.478a1.5 1.5 0 01-1.5-1.5v-2.74a.25.25 0 01.25-.249h2.493a.249.249 0 01.249.249v2.743a1.5 1.5 0 01-1.492 1.496z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});