define("ember-svg-jar/inlined/calendar-favorite-heart-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>calendar-favorite-heart-1</title><path d=\"M2.5 16.5A.5.5 0 012 16V7.5a.5.5 0 01.5-.5H16a.5.5 0 01.5.5v3.92a5.07 5.07 0 011 .556 5.043 5.043 0 011-.555V3a1 1 0 00-1-1H15a.25.25 0 01-.25-.25v-1a.75.75 0 10-1.5 0V4.5a.75.75 0 01-1.5 0v-2a.5.5 0 00-.5-.5H7a.25.25 0 01-.25-.25v-1a.75.75 0 00-1.5 0V4.5a.75.75 0 11-1.5 0v-2a.5.5 0 00-.5-.5H1a1 1 0 00-1 1v13.5a2 2 0 002 2h8.159a4.963 4.963 0 01-.626-2z\"/><path d=\"M21.038 12.547a3.486 3.486 0 00-3.057.987l-.482.481-.481-.481a3.527 3.527 0 00-5.647.916 3.519 3.519 0 00.654 4.063l5.113 5.334a.5.5 0 00.722 0l5.106-5.327a3.522 3.522 0 00.661-4.07 3.487 3.487 0 00-2.589-1.903z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});