define("ember-svg-jar/inlined/show-hat-magician-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>show-hat-magician-1</title><path d=\"M18.358 17.652A22.955 22.955 0 0112 18.5a22.955 22.955 0 01-6.358-.848.5.5 0 00-.642.479v3.627a1 1 0 00.43.822C6.045 23.007 7.916 24 12 24s5.955-.993 6.569-1.42a1 1 0 00.431-.822v-3.627a.5.5 0 00-.642-.479zM2 2.25A.25.25 0 012.25 2h.25a.5.5 0 000-1h-.25A.25.25 0 012 .75V.5a.5.5 0 00-1 0v.25A.25.25 0 01.75 1H.5a.5.5 0 000 1h.25a.25.25 0 01.25.25v.25a.5.5 0 001 0zM21.5 5.5h-.25a.25.25 0 01-.25-.25V5a.5.5 0 00-1 0v.25a.25.25 0 01-.25.25h-.25a.5.5 0 000 1h.25a.25.25 0 01.25.25V7a.5.5 0 001 0v-.25a.25.25 0 01.25-.25h.25a.5.5 0 000-1zM23.917.6A1 1 0 0022.6.084l-11.5 5A1 1 0 0011.5 7a.988.988 0 00.4-.083l11.5-5A1 1 0 0023.917.6zM12 9C6.477 9 2 10.791 2 13s4.477 4 10 4 10-1.791 10-4-4.477-4-10-4zm0 5.5c-3.722 0-6-.971-6-1.5s2.278-1.5 6-1.5 6 .971 6 1.5-2.278 1.5-6 1.5zM2.25 5a.752.752 0 00.414.671l1.18.589a2 2 0 01.9.9l.59 1.18a.75.75 0 001.342 0l.59-1.18a2 2 0 01.9-.9l1.179-.589a.75.75 0 000-1.342L8.156 3.74a2 2 0 01-.9-.895l-.59-1.18a.78.78 0 00-1.342 0l-.59 1.18a2 2 0 01-.895.9l-1.18.589A.752.752 0 002.25 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});