define("ember-svg-jar/inlined/shipment-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-container</title><path d=\"M21.239 19.525a.241.241 0 00.018.1l.036.086a.235.235 0 00.054.081L23.558 22a.25.25 0 00.426-.176V2.18a.249.249 0 00-.422-.18l-2.178 2.174a.252.252 0 00-.073.176zM20.076 3.261a.248.248 0 00.176-.073L22.478.962A.25.25 0 0022.3.536H1.7a.25.25 0 00-.177.426l2.225 2.226a.248.248 0 00.176.073z\"/><rect x=\"4.26\" y=\"9.753\" width=\"15.48\" height=\"4.494\" rx=\".25\" ry=\".25\"/><path d=\"M2.761 4.474a.241.241 0 00-.018-.1l-.035-.084a.249.249 0 00-.054-.082L.442 2a.25.25 0 00-.426.176V21.82a.249.249 0 00.422.18l2.178-2.174a.252.252 0 00.073-.176z\"/><rect x=\"4.26\" y=\"4.759\" width=\"15.48\" height=\"3.496\" rx=\".25\" ry=\".25\"/><path d=\"M3.924 20.739a.248.248 0 00-.176.073l-2.226 2.226a.25.25 0 00.177.426H22.3a.25.25 0 00.177-.426l-2.226-2.226a.248.248 0 00-.176-.073z\"/><rect x=\"4.26\" y=\"15.745\" width=\"15.48\" height=\"3.496\" rx=\".25\" ry=\".25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});