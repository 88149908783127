define("ember-svg-jar/inlined/shopping-cart-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-cart-remove</title><path d=\"M18.25 12.5A5.75 5.75 0 1024 18.25a5.757 5.757 0 00-5.75-5.75zm2.386 7.076a.75.75 0 01-1.06 1.061l-1.149-1.149a.247.247 0 00-.353 0l-1.149 1.149a.75.75 0 11-1.06-1.061l1.148-1.149a.25.25 0 000-.354l-1.148-1.149a.75.75 0 111.06-1.061l1.149 1.149a.247.247 0 00.353 0l1.149-1.149a.75.75 0 111.06 1.061l-1.149 1.149a.252.252 0 000 .354z\"/><path d=\"M12.657 14a1 1 0 00-1-1H8.239A.25.25 0 018 12.808l-1.15-5a.25.25 0 01.239-.308H20.28a.5.5 0 01.474.658L20 10.406a1 1 0 101.9.633l1-3A1.929 1.929 0 0021.072 5.5H6.514a.249.249 0 01-.244-.194l-.878-3.819A1.921 1.921 0 003.514 0H1a1 1 0 000 2l2.24-.055a.252.252 0 01.25.194l3.357 14.6a.25.25 0 01-.107.265 2.25 2.25 0 102.286-.1.253.253 0 01-.126-.165l-.33-1.437A.25.25 0 018.813 15h2.844a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});