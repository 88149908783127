define("ember-svg-jar/inlined/modern-architecture-spiral", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-architecture-spiral</title><path d=\"M23 22h-4.5a.5.5 0 01-.5-.5v-1.756a.966.966 0 00-.332-.724.5.5 0 01-.168-.373v-3.806a.5.5 0 01.168-.373.965.965 0 00.332-.724V8.25a.966.966 0 00-.332-.724.5.5 0 01-.168-.373V4.347a.5.5 0 01.168-.373A.966.966 0 0018 3.25V1.5A1.5 1.5 0 0016.5 0h-9A1.5 1.5 0 006 1.5v6.75a1 1 0 00.667.943.5.5 0 01.333.471v2.672a.5.5 0 01-.333.471A1 1 0 006 13.75v1.75a.5.5 0 00.5.5H9a.75.75 0 010 1.5H6.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5H9A.75.75 0 019 21H6.5a.5.5 0 00-.5.5.5.5 0 01-.5.5H1a1 1 0 000 2h22a1 1 0 000-2zm-7-3.756a.5.5 0 01-.5.5H14a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5zM16 6.75a.5.5 0 01-.5.5h-1a.5.5 0 00-.5.5v1.5a.5.5 0 01-.5.5h-2a.5.5 0 00-.5.5v2a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5v-2.5a.5.5 0 01.5-.5h.5a.5.5 0 00.5-.5v-1.5a.5.5 0 01.5-.5h2a.5.5 0 00.5-.5v-1.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});