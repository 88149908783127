define("ember-svg-jar/inlined/multiple-actions-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-shield</title><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M11.454 10.335a.25.25 0 00.3.1 17.874 17.874 0 015.726-1h.229a.25.25 0 00.193-.415 4.554 4.554 0 00-7 .235.25.25 0 00.005.311 7.1 7.1 0 01.547.769zM9.5 16.786v-3.392a3.035 3.035 0 01.7-1.93.25.25 0 00.021-.287A5.5 5.5 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.626a.249.249 0 00.247-.283 7.256 7.256 0 01-.063-.931z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M23.073 12.04A16.4 16.4 0 0017.479 11a15.993 15.993 0 00-5.551 1.04A1.547 1.547 0 0011 13.456v3.393a7.717 7.717 0 005.191 6.805l.552.211a2.071 2.071 0 001.471 0l.553-.211C21.848 22.48 24 19.682 24 16.849v-3.393a1.546 1.546 0 00-.927-1.416zM18.25 15.5a.25.25 0 00.25.25h2a.75.75 0 010 1.5h-2a.25.25 0 00-.25.25v2a.75.75 0 01-1.5 0v-2a.25.25 0 00-.25-.25h-2a.75.75 0 010-1.5h2a.25.25 0 00.25-.25v-2a.75.75 0 011.5 0z\"/><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M11.454 10.335a.25.25 0 00.3.1 17.874 17.874 0 015.726-1h.229a.25.25 0 00.193-.415 4.554 4.554 0 00-7 .235.25.25 0 00.005.311 7.1 7.1 0 01.547.769zM9.5 16.786v-3.392a3.035 3.035 0 01.7-1.93.25.25 0 00.021-.287A5.5 5.5 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.626a.249.249 0 00.247-.283 7.256 7.256 0 01-.063-.931z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M23.073 12.04A16.4 16.4 0 0017.479 11a15.993 15.993 0 00-5.551 1.04A1.547 1.547 0 0011 13.456v3.393a7.717 7.717 0 005.191 6.805l.552.211a2.071 2.071 0 001.471 0l.553-.211C21.848 22.48 24 19.682 24 16.849v-3.393a1.546 1.546 0 00-.927-1.416zM18.25 15.5a.25.25 0 00.25.25h2a.75.75 0 010 1.5h-2a.25.25 0 00-.25.25v2a.75.75 0 01-1.5 0v-2a.25.25 0 00-.25-.25h-2a.75.75 0 010-1.5h2a.25.25 0 00.25-.25v-2a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});