define("ember-svg-jar/inlined/road-sign-bump-ahead", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-bump-ahead</title><path d=\"M23.53 12.53a.749.749 0 000-1.06l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0zM5 13.75a.75.75 0 010-1.5h.83a.25.25 0 00.222-.136A3.252 3.252 0 019 10.25a3.2 3.2 0 012.788 1.582.249.249 0 00.424 0A3.2 3.2 0 0115 10.25a3.252 3.252 0 012.948 1.864.25.25 0 00.222.136H19a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});