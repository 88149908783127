define("ember-svg-jar/inlined/retouch-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>retouch-graph</title><path d=\"M21.353 18.146a.5.5 0 00-.853.354v1.25a.25.25 0 01-.25.25H7.278a.25.25 0 01-.131-.463l2.805-1.717a.25.25 0 01.221-.02 3 3 0 003.759-4.142.252.252 0 010-.23l3.531-6.592a.25.25 0 01.264-.128 2.967 2.967 0 00.52.046 3 3 0 002.935-3.62.249.249 0 01.142-.28l2.084-.938a1 1 0 00-.819-1.824l-2.516 1.134a.253.253 0 01-.236-.016 3 3 0 00-4.056 4.246.25.25 0 01.015.261l-3.372 6.3a.249.249 0 01-.293.121 3 3 0 00-3.658 4 .251.251 0 01-.1.308L4.38 18.885a.249.249 0 01-.38-.213V3.75a.25.25 0 01.25-.25H5.5a.5.5 0 00.353-.854l-2.5-2.5a.5.5 0 00-.707 0l-2.5 2.5A.5.5 0 00.5 3.5h1.25a.25.25 0 01.25.25V21a1 1 0 001 1h17.25a.25.25 0 01.25.25v1.25a.5.5 0 00.308.462A.494.494 0 0021 24a.5.5 0 00.353-.146l2.5-2.5a.5.5 0 000-.708zM12.25 15a1 1 0 11-1-1 1 1 0 011 1zm7-11.246a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});