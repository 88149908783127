define("ember-svg-jar/inlined/drugs-pill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drugs-pill</title><path d=\"M22.414 10.571a5.183 5.183 0 00-7.319 0l-2.292 2.3-2.3 2.293a5.175 5.175 0 007.317 7.32l2.3-2.293 2.291-2.3a5.166 5.166 0 000-7.318zm-4.77 9.266l-1.238 1.233a3.246 3.246 0 01-4.491 0 3.179 3.179 0 010-4.488l1.241-1.237a.5.5 0 01.707 0l1.894 1.893 1.889 1.89a.5.5 0 01.147.354.506.506 0 01-.149.355zm2.462-7.56a.5.5 0 01-.653.27 1.817 1.817 0 00-1.985.395.5.5 0 01-.707-.707 2.81 2.81 0 013.074-.611.5.5 0 01.271.653zM.567 5.752H12.3a.5.5 0 00.49-.6 6.5 6.5 0 00-12.71 0 .5.5 0 00.489.6zM12.788 7.857a.5.5 0 00-.489-.605H.568a.5.5 0 00-.49.6 6.5 6.5 0 0012.71 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});