define("ember-svg-jar/inlined/app-window-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-link</title><path d=\"M17.516 11.772l-1.188 1.191a1 1 0 101.416 1.412l1.187-1.19a1.828 1.828 0 112.585 2.586l-1.189 1.189a1 1 0 101.414 1.414l1.189-1.189a3.828 3.828 0 10-5.414-5.413zM17.078 20.211l-1.257 1.257a1.828 1.828 0 01-2.585-2.586l1.256-1.257a1 1 0 00-1.414-1.414l-1.256 1.257a3.828 3.828 0 005.413 5.414l1.257-1.257a1 1 0 00-1.414-1.414z\"/><path d=\"M19.742 14.961a1 1 0 00-1.414 0L14.793 18.5a1 1 0 101.414 1.414l3.535-3.535a1 1 0 000-1.418zM7.465 18.5H2.95a1 1 0 01-1-1V5.251A.25.25 0 012.2 5h19.5a.25.25 0 01.25.25v3.266a1 1 0 002 .017V3a3 3 0 00-3-3h-18a3 3 0 00-3 3v14.5a3 3 0 003 3h4.521a1 1 0 00-.006-2zM9.6 2a.967.967 0 011.7 0 .969.969 0 01.154.5.972.972 0 01-.154.5.967.967 0 01-1.7 0 .98.98 0 01-.153-.5A.977.977 0 019.6 2zM6.1 2a.967.967 0 011.7 0 .969.969 0 01.154.5.972.972 0 01-.154.5.967.967 0 01-1.7 0 .98.98 0 01-.153-.5A.977.977 0 016.1 2zm-3.561.091A1 1 0 013.45 1.5a.983.983 0 01.85.5.969.969 0 01.154.5.972.972 0 01-.154.5.967.967 0 01-1.7 0 .98.98 0 01-.153-.5.987.987 0 01.095-.408z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});