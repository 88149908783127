define("ember-svg-jar/inlined/bomb-explosive-belt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bomb-explosive-belt</title><path d=\"M22.984 13.585a145.654 145.654 0 01-21.968 0 .247.247 0 00-.266.247v3.493a.254.254 0 00.233.252 144.19 144.19 0 0022.034 0 .254.254 0 00.233-.252v-3.493a.247.247 0 00-.266-.247zM7.011 19.405q-3-.1-5.995-.328a.247.247 0 00-.266.247v2.426c0 .082.037 2 3.25 2s3.25-1.918 3.25-2v-2.1a.248.248 0 00-.239-.245zM.983 12.073q3 .23 6.006.332a.253.253 0 00.261-.253V6.25a.722.722 0 00-.1-.358 2.788 2.788 0 00-1.968-1.721A.247.247 0 015 3.936V1.25a1 1 0 00-2 0v2.687a.246.246 0 01-.182.234A2.788 2.788 0 00.854 5.892a.722.722 0 00-.1.358v5.571a.253.253 0 00.229.252zM4 5.5c1 0 1.75.528 1.75 1S5 7.5 4 7.5s-1.75-.528-1.75-1S3 5.5 4 5.5zM9 12.465q3 .062 6.006 0a.253.253 0 00.247-.252V6.25a.722.722 0 00-.1-.358 2.788 2.788 0 00-1.964-1.721.247.247 0 01-.189-.235V1.25a1 1 0 00-2 0v2.687a.246.246 0 01-.182.234 2.788 2.788 0 00-1.964 1.721.722.722 0 00-.1.358v5.963a.253.253 0 00.246.252zM12 5.5c1 0 1.75.528 1.75 1s-.75 1-1.75 1-1.75-.528-1.75-1 .75-1 1.75-1zM15 19.465q-1.5.032-3 .033t-3-.033a.249.249 0 00-.254.249v2.036c0 .082.037 2 3.25 2s3.25-1.918 3.25-2v-2.036a.249.249 0 00-.246-.249zM23.146 5.892a2.788 2.788 0 00-1.964-1.721.247.247 0 01-.182-.235V1.25a1 1 0 00-2 0v2.687a.246.246 0 01-.182.234 2.788 2.788 0 00-1.964 1.721.722.722 0 00-.1.358v5.9a.253.253 0 00.261.253q3.011-.1 6.006-.332a.253.253 0 00.233-.252V6.25a.722.722 0 00-.108-.358zM20 7.5c-1 0-1.75-.528-1.75-1s.75-1 1.75-1 1.75.528 1.75 1-.75 1-1.75 1zM22.984 19.077q-2.99.226-6 .329a.247.247 0 00-.239.247v2.1c0 .082.037 2 3.25 2s3.25-1.918 3.25-2v-2.429a.247.247 0 00-.261-.247z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});