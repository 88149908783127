define("ember-svg-jar/inlined/style-three-pin-palette", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-palette</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm3 12.205a2.166 2.166 0 00-1.809.889 3.578 3.578 0 01-3 1.806 3.471 3.471 0 01-1.022-.158A5.687 5.687 0 015.5 9.679a6.5 6.5 0 1113 0c0 2.526-1.7 2.526-3.5 2.526z\"/><path d=\"M12 4.5a1 1 0 101 1 1 1 0 00-1-1zM15 6.5a1 1 0 101 1 1 1 0 00-1-1zM8.5 6.5a1 1 0 101 1 1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});