define("ember-svg-jar/inlined/job-seach-team-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>job-seach-team-woman</title><path d=\"M17.75 18.75a4.738 4.738 0 00-3.324 1.359.251.251 0 00-.055.276A7.979 7.979 0 0115 23.5v.242a.249.249 0 00.25.258H22a.5.5 0 00.5-.5 4.755 4.755 0 00-4.75-4.75zM17.75 18.34a3.263 3.263 0 10-3.263-3.262 3.267 3.267 0 003.263 3.262zM13.5 23.5a6.762 6.762 0 00-4.4-6.328.251.251 0 00-.3.105l-1.836 3.058a.25.25 0 01-.428 0L4.7 17.277a.251.251 0 00-.3-.105A6.762 6.762 0 000 23.5a.5.5 0 00.5.5H13a.5.5 0 00.5-.5zM6.79 16.125a5.051 5.051 0 003.443-1.355.25.25 0 01.21-.064.253.253 0 01.18.126 4.33 4.33 0 00.756 1 1 1 0 101.4-1.423c-.4-.394-.93-1.171-.93-3.351a5.063 5.063 0 00-10.125 0c0 2.181-.531 2.957-.93 3.351a1 1 0 101.4 1.423 4.3 4.3 0 00.756-1 .25.25 0 01.39-.063 5.042 5.042 0 003.45 1.356zm-3.027-4.6a.25.25 0 01.21-.285 4.76 4.76 0 001.375-.394A3.1 3.1 0 006.61 9.774a.249.249 0 01.408-.019 5.062 5.062 0 001.215 1.091 3.468 3.468 0 001.346.567.254.254 0 01.166.106.251.251 0 01.037.194 3.062 3.062 0 01-6.019-.188zM23.707 10.292L21.311 7.9a.249.249 0 01-.036-.306 5.009 5.009 0 10-1.684 1.683.25.25 0 01.306.037l2.4 2.4a1 1 0 101.414-1.415zM14 5a3 3 0 113 3 3 3 0 01-3-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});