define("ember-svg-jar/inlined/check-shield-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>check-shield-alternate</title><path d=\"M9.218 14.792a1.249 1.249 0 001.745.029l7.105-6.695a1.25 1.25 0 10-1.714-1.819l-6.218 5.859-1.511-1.525A1.25 1.25 0 106.849 12.4z\"/><path d=\"M20.052 0H3.947A1.954 1.954 0 002 1.955v5.992a17.982 17.982 0 009.53 15.935 1 1 0 00.939 0A17.987 17.987 0 0022 7.947V1.955A1.955 1.955 0 0020.052 0zM20 7.947a16 16 0 01-8 13.91 15.993 15.993 0 01-8-13.91V2.5a.5.5 0 01.5-.5l15-.04a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});