define("ember-svg-jar/inlined/smart-watch-circle-target-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-target-alternate</title><path d=\"M21.5 12A9.5 9.5 0 0017 3.928a.25.25 0 01-.109-.148L15.983.373A.5.5 0 0015.5 0h-7a.5.5 0 00-.483.371l-.909 3.408A.245.245 0 017 3.927a9.5 9.5 0 000 16.149.25.25 0 01.109.148l.908 3.407A.5.5 0 008.5 24h7a.5.5 0 00.482-.369l.935-3.427a.247.247 0 01.109-.146A9.5 9.5 0 0021.5 12zm-8.031 7.359a7.5 7.5 0 115.89-5.89 7.515 7.515 0 01-5.89 5.892z\"/><path d=\"M10.012 7.311a.681.681 0 00-1.025 0L7.237 9.5a.656.656 0 00.513 1.066h.75a.25.25 0 01.25.25v3.438a.75.75 0 001.5 0v-3.439a.25.25 0 01.25-.25h.75a.656.656 0 00.512-1.065zM16.841 13.812a.656.656 0 00-.591-.372h-.75a.25.25 0 01-.25-.25v-2.937a.75.75 0 00-1.5 0v2.937a.25.25 0 01-.25.25h-.75a.657.657 0 00-.513 1.067l1.75 2.187a.657.657 0 001.025 0l1.75-2.187a.658.658 0 00.079-.695z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});