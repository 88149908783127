define("ember-svg-jar/inlined/pool-triangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pool-triangle</title><circle cx=\"7\" cy=\"19.268\" r=\"2\"/><circle cx=\"9.5\" cy=\"14.268\" r=\"2\"/><circle cx=\"14.5\" cy=\"14.268\" r=\"2\"/><circle cx=\"12\" cy=\"19.268\" r=\"2\"/><circle cx=\"12\" cy=\"9.268\" r=\"2\"/><circle cx=\"17\" cy=\"19.268\" r=\"2\"/><path d=\"M23.787 21.105l-9.991-20a2.012 2.012 0 00-3.592 0l-9.991 20A1.989 1.989 0 00.3 23.05 2.014 2.014 0 002 24h20a2.014 2.014 0 001.7-.95 1.989 1.989 0 00.087-1.945zm-2.171.658a.5.5 0 01-.425.237H2.809a.5.5 0 01-.447-.724l9.191-18.359a.5.5 0 01.894 0l9.191 18.359a.5.5 0 01-.022.487z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});