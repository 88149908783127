define("ember-svg-jar/inlined/professions-man-chef-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-chef-2</title><path d=\"M23.381 20.432a2.435 2.435 0 00-.956-1.957A11.934 11.934 0 0020 17.088a.247.247 0 00-.316.108l-3.512 6.438a.248.248 0 00.218.366h6.5a.494.494 0 00.494-.5zM18.928 2.969A2.973 2.973 0 0015.959 0H8.041a2.973 2.973 0 00-2.969 2.969v5.938a6.928 6.928 0 0013.856 0zM7.359 6.162a.251.251 0 01-.212-.045.248.248 0 01-.1-.2V3.8a.245.245 0 01.1-.195.248.248 0 01.212-.046l3.711.928a.249.249 0 01.188.24v.73a.248.248 0 01-.239.247 17.851 17.851 0 00-3.66.458zm5.383-1.431a.249.249 0 01.188-.24l3.711-.928a.248.248 0 01.212.046.245.245 0 01.095.195v2.118a.247.247 0 01-.307.24 17.851 17.851 0 00-3.66-.454.248.248 0 01-.239-.247zm3.217-2.752a.111.111 0 01.027.218l-3.926.982a.244.244 0 01-.12 0L8.014 2.2a.111.111 0 01.027-.218zM12 13.856a4.954 4.954 0 01-4.948-4.949v-.928a.247.247 0 01.173-.236A16.208 16.208 0 0112 7.175a16.268 16.268 0 014.775.567.247.247 0 01.173.236v.929A4.954 4.954 0 0112 13.856zM.619 20.432v3.073a.494.494 0 00.494.5h7.251a.247.247 0 00.188-.086l3.034-3.524a.247.247 0 000-.326l-3.494-3.93a.492.492 0 00-.462-.158 14.376 14.376 0 00-6.055 2.494 2.434 2.434 0 00-.956 1.957zM17.087 16.277l-6.3 7.314a.248.248 0 00.188.409h3.158a.247.247 0 00.217-.129l3.883-7.118a.247.247 0 00-.149-.356 19.79 19.79 0 00-.751-.2.246.246 0 00-.246.08z\"/><circle cx=\"13.979\" cy=\"9.402\" r=\".99\"/><circle cx=\"10.021\" cy=\"9.402\" r=\".99\"/><path d=\"M13.066 11.264h-1.98a.632.632 0 00-.3 1.189 2.324 2.324 0 00.43.178 2.967 2.967 0 00.858.125h.006a2.961 2.961 0 00.855-.124 2.366 2.366 0 00.431-.179.633.633 0 00-.3-1.189z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});