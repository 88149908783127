define("ember-svg-jar/inlined/layers-back", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layers-back</title><rect width=\"17\" height=\"17\" rx=\"2\" ry=\"2\"/><path d=\"M21.5 7h-2a1 1 0 000 2h2a.5.5 0 01.5.5v12a.5.5 0 01-.5.5h-12a.5.5 0 01-.5-.5v-2a1 1 0 00-2 0v2A2.5 2.5 0 009.5 24h12a2.5 2.5 0 002.5-2.5v-12A2.5 2.5 0 0021.5 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});