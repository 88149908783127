define("ember-svg-jar/inlined/single-man-actions-flight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-flight</title><path d=\"M10.255 16.6a2.317 2.317 0 01.554-1.7 2.833 2.833 0 01.331-.317l.937-.7a2.329 2.329 0 012.2-.313l2.387.889a.25.25 0 00.256-.05.253.253 0 00.072-.252 2.4 2.4 0 00-1.4-1.742l-3.936-1.687a.25.25 0 01-.152-.23V9.37a.25.25 0 01.074-.177 3.311 3.311 0 00.779-2.231.251.251 0 01.088-.176 1.874 1.874 0 00.538-1.4 1.577 1.577 0 00-.317-1.014.252.252 0 01-.034-.265 2.528 2.528 0 00.156-1.726C12.444.992 10.741.5 9.388.5a3.751 3.751 0 00-3.163 1.324.252.252 0 01-.213.118 1.074 1.074 0 00-.9.375c-.371.492-.1 1.17.176 1.732a.251.251 0 01-.045.288 1.343 1.343 0 00-.162.2 1.766 1.766 0 00-.261.963 1.521 1.521 0 00.638 1.331.249.249 0 01.117.193A3.17 3.17 0 006.42 9.2a.248.248 0 01.08.183v1.122a.249.249 0 01-.151.23l-3.937 1.687C1.14 12.968.957 14.126.705 15.73c-.055.359-.117.751-.2 1.179A.5.5 0 001 17.5h9.139a.248.248 0 00.2-.106.251.251 0 00.033-.227 2.215 2.215 0 01-.117-.567z\"/><path d=\"M22.046 15.054a1.8 1.8 0 00-1.411.27l-2 1.365a.25.25 0 01-.228.027l-4.65-1.731a.826.826 0 00-.784.111l-.9.669a1.507 1.507 0 00-.123.117.828.828 0 00-.2.605.855.855 0 00.352.611l2.7 1.672a.25.25 0 01.018.413l-.957.709a.25.25 0 01-.276.015l-1.173-.69a.773.773 0 00-.746.132l-.87.73a1.472 1.472 0 00-.126.124.762.762 0 00-.166.562.782.782 0 00.308.537l2.876 2.018a.98.98 0 001.084.032l7.854-4.878a1.864 1.864 0 00.871-1.6 1.821 1.821 0 00-.1-.606 1.872 1.872 0 00-1.353-1.214z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});