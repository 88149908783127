define("ember-svg-jar/inlined/moose", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>moose</title><path d=\"M22 .506a1.5 1.5 0 00-1.5 1.5v4a1.5 1.5 0 01-3 0v-2.5a1 1 0 00-2 0v3a1 1 0 01-1 1h-5a1 1 0 01-1-1v-3a1 1 0 00-2 0v2.5a1.5 1.5 0 01-3 0v-4a1.5 1.5 0 00-3 0v4.5a4 4 0 004 4h5.764c-1.8 2.353-4.528 6.791-5.14 7.795a.5.5 0 00-.038.444 7.593 7.593 0 006.888 4.748.5.5 0 00.526-.5v-4.987c0-2.391 2.749 3.21 5.5 0 2.391-2.79-1.479-5.555-4-7.5h5.5a4 4 0 004-4v-4.5a1.5 1.5 0 00-1.5-1.5zm-9 13a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});