define("ember-svg-jar/inlined/love-it-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it-download</title><path d=\"M5.813 2.777A4.575 4.575 0 019.807 4.06l1.531 1.4a1 1 0 001.348 0l1.561-1.424a4.546 4.546 0 013.965-1.256 4.5 4.5 0 013.329 2.42 4.175 4.175 0 01-.134 4.056.243.243 0 00-.022.2.248.248 0 00.129.151 8.009 8.009 0 011.24.754.255.255 0 00.2.044.252.252 0 00.166-.12 6.194 6.194 0 00.2-5.986 6.589 6.589 0 00-10.45-1.717l-.858.783-.829-.755A6.526 6.526 0 005.491.8 6.478 6.478 0 00.7 4.293a6.4 6.4 0 001.2 7.454L9 19.433a.25.25 0 00.434-.16l.066-2.129a.244.244 0 00-.066-.178l-6.1-6.609A4.421 4.421 0 012.483 5.2a4.5 4.5 0 013.33-2.423z\"/><path d=\"M17.5 23.286a6.5 6.5 0 10-6.5-6.5 6.508 6.508 0 006.5 6.5zm-2.451-6.217a.5.5 0 01.451-.283h1v-3a1 1 0 112 0v3h1a.5.5 0 01.39.812l-2 2.5a.516.516 0 01-.781 0l-2-2.5a.5.5 0 01-.061-.529z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});