define("ember-svg-jar/inlined/architecture-door-retro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>architecture-door-retro</title><path d=\"M21 8a1 1 0 00-1 1v4a1 1 0 002 0V9a1 1 0 00-1-1zM21 17a1 1 0 00-1 1v4a1 1 0 002 0v-4a1 1 0 00-1-1zM10.5 0A8.51 8.51 0 002 8.5v2.513a.5.5 0 00.5.5H5a3.25 3.25 0 010 6.5H2.5a.5.5 0 00-.5.5V22.5A1.5 1.5 0 003.5 24h14a1.5 1.5 0 001.5-1.5v-14A8.51 8.51 0 0010.5 0z\"/><circle cx=\"5\" cy=\"14.75\" r=\"1.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});