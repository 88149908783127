define("ember-svg-jar/inlined/money-wallet-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>money-wallet-1</title><path d=\"M18.874 18a3.757 3.757 0 01-3.669-4.538 3.819 3.819 0 013.777-2.962h2.393a.5.5 0 00.5-.5V7.746a2.5 2.5 0 00-2.5-2.5c-4.476 0 8.862-.01-14-.01a.75.75 0 010-1.5h13.25a.25.25 0 00.25-.249V3.25c0-1.379-1.57-2.5-3.5-2.5h-11a3.5 3.5 0 00-3.5 3.5v15.5a3.5 3.5 0 003.5 3.5h15a2.5 2.5 0 002.5-2.5v-2.5a.25.25 0 00-.25-.25z\"/><path d=\"M21.874 12h-3a2.25 2.25 0 100 4.5h3a1.506 1.506 0 001.25-1.5v-1.5a1.506 1.506 0 00-1.25-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});