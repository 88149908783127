define("ember-svg-jar/inlined/love-candle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-candle</title><path d=\"M9.513 8.8a.683.683 0 00.974 0L14.6 4.573a2.646 2.646 0 00.509-3.092 2.746 2.746 0 00-4.36-.7l-.4.394a.5.5 0 01-.7 0l-.4-.394a2.746 2.746 0 00-4.36.7A2.645 2.645 0 005.4 4.573zM7 10a1 1 0 00-1 1v8a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-8a1 1 0 00-1-1zm1.5 7a.5.5 0 01-1 0v-1.5a.5.5 0 011 0zm0-4a.5.5 0 01-1 0v-1a.5.5 0 011 0z\"/><path d=\"M20 12a4 4 0 00-4 4 1 1 0 002 0 2 2 0 014 0v.5a4.977 4.977 0 01-2.287 4.179 1.988 1.988 0 01-1.087.321H.5a.5.5 0 00-.5.5A2.5 2.5 0 002.5 24h15a2.474 2.474 0 001.813-.794.987.987 0 01.348-.24A7 7 0 0024 16.5V16a4 4 0 00-4-4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});