define("ember-svg-jar/inlined/warehouse-cart-packages-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>warehouse-cart-packages-2</title><circle cx=\"15.84\" cy=\"21.36\" r=\"1.92\"/><circle cx=\"5.28\" cy=\"21.36\" r=\"1.92\"/><path d=\"M20.733 17.264a1.919 1.919 0 01-1.907 1.7H.96a.96.96 0 010-1.92h17.012a.96.96 0 00.954-.848l1.621-13.78A1.919 1.919 0 0122.454.72h.586a.96.96 0 110 1.92h-.373a.24.24 0 00-.238.212z\"/><rect x=\".96\" y=\"11.76\" width=\"8.64\" height=\"3.84\" rx=\".96\" ry=\".96\"/><rect x=\"3.84\" y=\"7.44\" width=\"5.76\" height=\"2.88\" rx=\".96\" ry=\".96\"/><rect x=\"11.04\" y=\"5.04\" width=\"6.72\" height=\"10.56\" rx=\".96\" ry=\".96\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});