define("ember-svg-jar/inlined/road-sign-left-reverse-turn-ahead-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-left-reverse-turn-ahead-alternate</title><path d=\"M11.293 23.707a1 1 0 001.414 0l11-11a1 1 0 000-1.414l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414zm-8.7-11.53a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354l-9.232 9.232a.25.25 0 01-.354 0z\"/><path d=\"M13.53 9.47a.75.75 0 01-.53 1.28h-.75A.25.25 0 0012 11v.75a.25.25 0 00.25.25h1.25a1.5 1.5 0 011.5 1.5V17a1 1 0 01-2 0v-2.75a.25.25 0 00-.25-.25H11.5a1.5 1.5 0 01-1.5-1.5V11a.25.25 0 00-.25-.25H9a.75.75 0 01-.53-1.28l2-2a.749.749 0 011.06 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});