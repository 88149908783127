define("ember-svg-jar/inlined/stairs-person-ascend-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>stairs-person-ascend-1</title><path d=\"M14.712 9.9a1 1 0 001.323.313L23.4 5.9a1 1 0 10-1.009-1.726l-7.365 4.31a.971.971 0 00-.324 1.4zM4.084 16.5a1 1 0 00-1.5-.74L.5 16.981a1 1 0 101.01 1.726s1.339-.746 2.111-1.228a1 1 0 00.463-.979zM5.305.9A2.5 2.5 0 007.2 5.036c.061 0 .122 0 .184-.006A2.5 2.5 0 105.305.9z\"/><path d=\"M1.883 12.767h.092a1.251 1.251 0 001.156-1.338A2.2 2.2 0 014.1 9.444c.348-.234.545-.37.683-.458a.249.249 0 01.384.226l-.317 4.926c-.008.119-.013-.01.153 2.257a1.741 1.741 0 01-.223.989l-1.833 2.847a1.25 1.25 0 102.175 1.232l1.829-2.847a4.238 4.238 0 00.54-2.4l-.07-.965a.252.252 0 01.1-.221.255.255 0 01.244-.03c.317.13.7.29 1.156.49a1.743 1.743 0 01.961 1.057l.8 2.138a1.25 1.25 0 002.375-.783l-.8-2.136a4.249 4.249 0 00-2.341-2.57 43.478 43.478 0 00-2.266-.926.25.25 0 01-.163-.251l.178-2.764a.25.25 0 01.3-.229.738.738 0 01.662.55 3.418 3.418 0 002.343 2.379l1.571.462a1.23 1.23 0 00.352.052h.092a1.249 1.249 0 00.261-2.445l-1.57-.462a.939.939 0 01-.64-.65 3.218 3.218 0 00-2.678-2.355 16.137 16.137 0 00-2.6-.377 4.249 4.249 0 00-2.364.72l-.694.469a4.7 4.7 0 00-2.062 4.239 1.249 1.249 0 001.245 1.159zM22.991 16.036h-3.1a1.5 1.5 0 00-1.5 1.5v.75a.25.25 0 01-.25.25h-2.25a1.5 1.5 0 00-1.5 1.5v.75a.25.25 0 01-.25.25h-2.75a1.5 1.5 0 00-1.5 1.5v.427a1 1 0 001.966.259.249.249 0 01.241-.186h3.293a1 1 0 001-1v-1.25a.249.249 0 01.25-.25h2.75a1 1 0 001-1v-1.25a.249.249 0 01.25-.25h2.348a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});