define("ember-svg-jar/inlined/disability-oc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-oc</title><path d=\"M6.226 18.227A6.227 6.227 0 100 12a6.234 6.234 0 006.226 6.227zm0-8.454A2.227 2.227 0 114 12a2.229 2.229 0 012.226-2.227zM23.761 14.778a2 2 0 00-2.709-.813 2.139 2.139 0 01-1.026.262 2.227 2.227 0 010-4.454 2.155 2.155 0 01.984.239 2 2 0 001.8-3.571 6.138 6.138 0 00-2.789-.668 6.227 6.227 0 102.922 11.715 2 2 0 00.813-2.71z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});