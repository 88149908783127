define("ember-svg-jar/inlined/paralympics-torch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paralympics-torch</title><path d=\"M21.391 9.628V8a.75.75 0 00-1.5 0v1.714c-2.507.624-2.983.282-4.03-.763a3.255 3.255 0 00-2.5-.951h-5.69a7.013 7.013 0 00-6.46 4.26 1.248 1.248 0 102.3.97 4.088 4.088 0 013.68-2.73h.42a.25.25 0 01.25.25v4.5a1 1 0 001 1h.5V17a.5.5 0 01-.5.5H6.1l.424-1.7a1.25 1.25 0 10-2.424-.6l-1.455 5.82a1.25 1.25 0 002.425.606L5.6 19.5h3.258a.5.5 0 01.5.5v2.5a1.5 1.5 0 003 0V20a.5.5 0 01.5-.5h3.256l.531 2.124a1.25 1.25 0 002.425-.606L17.615 15.2a1.25 1.25 0 10-2.425.606l.424 1.7h-2.756a.5.5 0 01-.5-.5v-.75h.5a1 1 0 001-1v-4.164a.25.25 0 01.421-.181 5.226 5.226 0 003.849 1.659 6.355 6.355 0 001.76-.285v1.965a.75.75 0 001.5 0v-2.385a1.223 1.223 0 00.71-1.414 1.238 1.238 0 00-.707-.823zM20.63.869a9.542 9.542 0 00-1.794-.255 3.144 3.144 0 01-1.78-.487.75.75 0 00-1.166.625 3.661 3.661 0 002.571 3.562 2.248 2.248 0 004.425-.524.679.679 0 000-.078A2.963 2.963 0 0020.63.869z\"/><circle cx=\"10.858\" cy=\"4.001\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});