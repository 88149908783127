define("ember-svg-jar/inlined/history-babushka", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-babushka</title><path d=\"M17.794 14.711a.249.249 0 00-.3.264c.014.173.022.348.022.525 0 3.584-2.467 6.5-5.5 6.5s-5.5-2.916-5.5-6.5c0-.178.008-.353.022-.526a.249.249 0 00-.3-.264 5.1 5.1 0 00-2.189.82.036.036 0 00-.009.015C4.251 18.937 5.136 24 8.5 24h7c3.337 0 4.236-4.971 4.463-8.352a.252.252 0 00-.1-.216 6 6 0 00-2.069-.721zM11.36 11.114a.78.78 0 011.286 0c.694 1.156 2.743 1.572 4.551 1.94a19.16 19.16 0 012.388.586.249.249 0 00.328-.278C19.51 11 18 10.267 18 7.494V6A6 6 0 006 6v1.494c0 2.717-1.5 3.518-1.908 5.874a.25.25 0 00.329.278 18.8 18.8 0 012.4-.592c1.802-.367 3.846-.784 4.539-1.94zM12 2a4 4 0 11-4 4 4 4 0 014-4z\"/><circle cx=\"9.013\" cy=\"16\" r=\"1\"/><circle cx=\"15.013\" cy=\"16\" r=\"1\"/><circle cx=\"12.013\" cy=\"15\" r=\"1\"/><path d=\"M12.1 4.2a.5.5 0 01.816.023 2.864 2.864 0 001.607 1.113.251.251 0 00.307-.323 2.99 2.99 0 00-5.721.23.249.249 0 00.25.313A3.464 3.464 0 0012.1 4.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});