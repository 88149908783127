define("ember-svg-jar/inlined/baby-care-pacifier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baby-care-pacifier</title><path d=\"M13.963 16.08a.5.5 0 00-.84.234 4.5 4.5 0 11-5.439-5.439.5.5 0 00.234-.839L5.4 7.519a.5.5 0 00-.578-.093 8.751 8.751 0 1011.75 11.748.5.5 0 00-.093-.578zM22.954 1.044a3.589 3.589 0 00-5.079.023c-1.623 1.7-2.6 1.508-3.83 1.265a3.88 3.88 0 00-3.526.553.5.5 0 01-.666-.036L9.5 2.5A2.475 2.475 0 006 6l12 12a2.475 2.475 0 003.5-3.5l-.351-.351a.5.5 0 01-.036-.666 3.888 3.888 0 00.552-3.527c-.244-1.235-.436-2.21 1.287-3.852a3.58 3.58 0 00.002-5.06zm-1.747 3.268c-2.7 2.569-2.273 4.71-1.993 6.126.047.238.086.438.11.613a.5.5 0 01-.849.42l-5.948-5.948a.5.5 0 01.42-.849c.176.024.377.063.614.11 1.416.281 3.556.7 6.1-1.97a1.076 1.076 0 011.544 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});