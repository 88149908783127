define("ember-svg-jar/inlined/color-spray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-spray</title><rect x=\"4\" y=\"4\" width=\"3\" height=\"3\" rx=\"1\" ry=\"1\"/><path d=\"M15.316 8.051l-3-1a1 1 0 00-.632 1.9l3 1a1 1 0 00.632-1.9zM14.684 2.051l-3 1a1 1 0 00.632 1.9l3-1a1 1 0 00-.632-1.9z\"/><rect y=\"12\" width=\"11\" height=\"12\" rx=\"1.5\" ry=\"1.5\"/><path d=\"M2 11h7a.5.5 0 00.447-.724L8.724 8.83A1.491 1.491 0 007.382 8H3.618a1.492 1.492 0 00-1.342.829l-.723 1.447A.5.5 0 002 11zM21.387 2.613A3.507 3.507 0 0018 0a1 1 0 000 2 1.5 1.5 0 011.5 1.5 1 1 0 001 1 1.5 1.5 0 010 3 1 1 0 00-1 1A1.5 1.5 0 0118 10a1 1 0 000 2 3.507 3.507 0 003.387-2.613 3.5 3.5 0 000-6.774z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});