define("ember-svg-jar/inlined/envelope", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>envelope</title><path d=\"M11.114 14.556a1.252 1.252 0 001.768 0l9.686-9.686a.5.5 0 00-.281-.849A1.966 1.966 0 0022 4H2a1.966 1.966 0 00-.289.021.5.5 0 00-.281.849z\"/><path d=\"M23.888 5.832a.182.182 0 00-.2.039l-6.2 6.2a.251.251 0 000 .354l5.043 5.043a.75.75 0 11-1.06 1.061l-5.043-5.043a.25.25 0 00-.354 0l-2.129 2.129a2.75 2.75 0 01-3.888 0l-2.131-2.127a.251.251 0 00-.354 0l-5.043 5.043a.75.75 0 01-1.06-1.061l5.043-5.043a.251.251 0 000-.354l-6.2-6.2a.18.18 0 00-.2-.039A.182.182 0 000 6v12a2 2 0 002 2h20a2 2 0 002-2V6a.181.181 0 00-.112-.168z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});