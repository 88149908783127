define("ember-svg-jar/inlined/diving-mask", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diving-mask</title><path d=\"M22.625 5.771a1 1 0 00-1 1v8.291a4.659 4.659 0 01-4.5 4.688h-3.517a.22.22 0 01-.205-.141 3.225 3.225 0 00-6.045-.019.252.252 0 01-.234.16H4.875a1 1 0 000 2h2.249a.252.252 0 01.234.16 3.225 3.225 0 006.045-.019.22.22 0 01.205-.141h3.517a6.684 6.684 0 006.5-6.688V6.771a1 1 0 00-1-1zM22.625 0a1 1 0 00-1 1v2a1 1 0 002 0V1a1 1 0 00-1-1z\"/><path d=\"M9.877 12.312V10.5a.5.5 0 111 0v1.811a4.153 4.153 0 004 4.188h2.5a3.179 3.179 0 003-3.188v-1a4.855 4.855 0 00-5-4.812h-10a4.857 4.857 0 00-5 4.812v1a3.181 3.181 0 003 3.188h2.5a4.151 4.151 0 004-4.187zM5.375 10a2.5 2.5 0 00-2.5 2.5.5.5 0 01-1 0 3.5 3.5 0 013.5-3.5.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});