define("ember-svg-jar/inlined/religion-hand-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-hand-1</title><path d=\"M20.983 3.67h-1.5a.5.5 0 01-.5-.5v-1.5a1 1 0 00-1-1h-2a1 1 0 00-1 1v1.5a.5.5 0 01-.5.5h-1.5a1 1 0 00-1 1v2a1 1 0 001 1h1.5a.5.5 0 01.5.5v5.5a1 1 0 001 1h2a1 1 0 001-1v-5.5a.5.5 0 01.5-.5h1.5a1 1 0 001-1v-2a1 1 0 00-1-1zM17.517 18.83H8.392a.625.625 0 110-1.25h2.525a1 1 0 00.919-1.393A2.233 2.233 0 009.78 14.83H5.517a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5H16.28a2.239 2.239 0 002.237-2.236v-.264a1 1 0 00-1-1zM3.017 14.08a1 1 0 00-1 1v7.25a1 1 0 002 0v-7.25a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});