define("ember-svg-jar/inlined/smiley-crying-rainbow-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-crying-rainbow-alternate</title><path d=\"M2.912 16.143a9.913 9.913 0 01-.7-6.2A10 10 0 0122 12a9.9 9.9 0 01-.914 4.147c-.6 1.306 1.2 2.161 1.759.988A11.915 11.915 0 0024 12.069 12 12 0 100 12a11.927 11.927 0 001.122 5.064 1.007 1.007 0 101.79-.921z\"/><path d=\"M16.8 8a3.26 3.26 0 00-2.875 1.621 1 1 0 00.388 1.36.989.989 0 00.485.126 1 1 0 00.875-.513A1.275 1.275 0 0116.8 10a1.242 1.242 0 011.123.592 1 1 0 101.749-.969A3.254 3.254 0 0016.8 8zM7.5 8a3.26 3.26 0 00-2.874 1.621 1 1 0 00.388 1.36.989.989 0 00.485.126 1 1 0 00.875-.513A1.248 1.248 0 017.5 10a1.241 1.241 0 011.123.593 1 1 0 001.75-.971A3.257 3.257 0 007.5 8zM12 14.5a1.5 1.5 0 00-1.5 1.5v2a1.5 1.5 0 003 0v-2a1.5 1.5 0 00-1.5-1.5zM9 24a1.5 1.5 0 000-3 1.448 1.448 0 00-.444.071.249.249 0 01-.3-.131 2.54 2.54 0 00-.664-.858.248.248 0 01-.09-.192V14.5a1.5 1.5 0 00-3 0v5.39a.249.249 0 01-.09.192 2.54 2.54 0 00-.664.858.25.25 0 01-.3.131A1.448 1.448 0 003 21a1.5 1.5 0 000 3zM21 21a1.448 1.448 0 00-.444.071.249.249 0 01-.3-.131 2.54 2.54 0 00-.664-.858.248.248 0 01-.09-.192V14.5a1.5 1.5 0 00-3 0v5.39a.249.249 0 01-.09.192 2.54 2.54 0 00-.664.858.25.25 0 01-.3.131A1.448 1.448 0 0015 21a1.5 1.5 0 000 3h6a1.5 1.5 0 000-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});