define("ember-svg-jar/inlined/social-profile-smartphone-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-profile-smartphone-add</title><path d=\"M18.5 17.5a1 1 0 00-1-1 1 1 0 00-1 1V19a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5h13.5a1 1 0 001-1V3a3 3 0 00-3-3h-11a3 3 0 00-3 3v18a3 3 0 003 3h11a3 3 0 003-3z\"/><path d=\"M9.138 9.409a2.363 2.363 0 102.362 2.362 2.363 2.363 0 00-2.362-2.362zM5.465 17.673A.25.25 0 005.7 18h6.87a.249.249 0 00.2-.1.252.252 0 00.036-.224 3.857 3.857 0 00-7.346 0zM18 6a4.5 4.5 0 104.5 4.5A4.505 4.505 0 0018 6zm2.75 4.5a.75.75 0 01-.75.75h-1a.25.25 0 00-.25.25v1a.75.75 0 01-1.5 0v-1a.249.249 0 00-.25-.25h-1a.75.75 0 010-1.5h1a.249.249 0 00.25-.25v-1a.75.75 0 011.5 0v1a.25.25 0 00.25.25h1a.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});