define("ember-svg-jar/inlined/video-game-mario-question-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-mario-question-box</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM3 22a1 1 0 111-1 1 1 0 01-1 1zM3 4a1 1 0 111-1 1 1 0 01-1 1zm9 17.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm1.5-7.2V16a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-3.5a1 1 0 011-1h.5a2.5 2.5 0 002.414-3.166 2.47 2.47 0 00-1.748-1.748 2.506 2.506 0 00-3.135 2.02.486.486 0 01-.487.394H7.025a.493.493 0 01-.5-.524A5.5 5.5 0 1113.5 14.3zM21 22a1 1 0 111-1 1 1 0 01-1 1zm0-18a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});