define("ember-svg-jar/inlined/easter-egg-decoration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>easter-egg-decoration</title><path d=\"M23.965 1.472A1.659 1.659 0 0022.126.011a1.634 1.634 0 00-1.1.6l-5.892 6.866a.5.5 0 00.2.792A4.852 4.852 0 0117.5 9.94a.5.5 0 00.808 0l5.308-7.249a1.651 1.651 0 00.349-1.219zM3.7 3.348a3.624 3.624 0 012.386 1.029l.378.369a2.157 2.157 0 002.991 0l.2-.2a3.664 3.664 0 015.084 0 2.174 2.174 0 00.2.173 1 1 0 001.365-.143.989.989 0 00.008-1.276q-.237-.282-.49-.544A8.478 8.478 0 009.75 0a8.482 8.482 0 00-6.224 2.929.25.25 0 00.174.419z\"/><path d=\"M19.463 12a.643.643 0 00-.463-.592.5.5 0 00-.634.567 4.87 4.87 0 01-.176 2.411 7.782 7.782 0 01-7.335 4.748 8.889 8.889 0 01-2.885-.492 2.589 2.589 0 01-.05-4.881c.343-.125.349-.128.6-1.148.1-.393.207-.838.374-1.335a4.891 4.891 0 014.142-3.32 1 1 0 00.656-.343l.676-.788a.5.5 0 00-.107-.746 3.537 3.537 0 01-.562-.449 2.159 2.159 0 00-3-.01l-.2.2a3.663 3.663 0 01-5.085 0l-.377-.368a2.171 2.171 0 00-2.85-.125l-.474.385a.993.993 0 00-.273.35 15.424 15.424 0 00-1.416 6.548C.024 19.211 4.115 24 9.75 24c5.49 0 9.485-4.438 9.716-10.792.014-.408.013-.808-.003-1.208zm-14.9.249a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.501z\"/><path d=\"M11.917 9.565a3.628 3.628 0 00-1.832 2.109 15.26 15.26 0 00-.346 1.24c-.283 1.143-.412 1.665-1.386 2.021a1.339 1.339 0 00.026 2.524 7.64 7.64 0 002.479.421 7.048 7.048 0 003.126-.724 5.954 5.954 0 003.024-3.166 3.65 3.65 0 00-5.091-4.425zm3.669 3.949a4.431 4.431 0 01-2.266 2.3 5.566 5.566 0 01-3.413.5.25.25 0 01-.107-.449 4.054 4.054 0 001.4-2.59c.086-.348.179-.724.312-1.125a2.151 2.151 0 114.079 1.364z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});