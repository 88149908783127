define("ember-svg-jar/inlined/beach-palm-water-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beach-palm-water-1</title><path d=\"M7.806 20.47a.5.5 0 00-.5-.47h-4.5a.5.5 0 00-.5.438c-.1.785-.153 1.461-.312 3.012a.5.5 0 00.5.55h5a.5.5 0 00.5-.531c-.12-1.944-.166-2.633-.188-2.999zM7.515 15.5H3.7a.5.5 0 00-.48.36 25.691 25.691 0 00-.6 2.549.5.5 0 00.49.591h4.171a.5.5 0 00.5-.482 20.815 20.815 0 01.229-2.442.5.5 0 00-.5-.576zM8.968 10.57a.5.5 0 00-.569-.109L5.07 11.978a1.007 1.007 0 00-.464.432 15.441 15.441 0 00-.667 1.4.5.5 0 00.461.69h3.521a.5.5 0 00.485-.377 20.548 20.548 0 01.909-2.761.5.5 0 00-.1-.535z\"/><path d=\"M9.2 9.368l5.762 6.012a1 1 0 001.673-.38c1.037-3.2-2.42-7.431-5.962-7.493a.5.5 0 01-.491-.509.491.491 0 01.508-.498 6.008 6.008 0 011.787.315.5.5 0 00.4-.037l4.993-2.739a1 1 0 00.062-1.716 5.7 5.7 0 00-5.684.24 7.123 7.123 0 00-2.184 1.825.249.249 0 01-.429-.061A6.973 6.973 0 007.734 1.7 5.7 5.7 0 002.236.24a1 1 0 00-.31 1.689l3.94 3.453a.5.5 0 00.349.124 6.558 6.558 0 011.712.152.5.5 0 01-.222.975C4.493 5.9.215 8.359 0 11.573a1 1 0 001.413.975l7.218-3.289a.5.5 0 01.569.109zM23 22a3.745 3.745 0 01-2.9-1.646 1.056 1.056 0 00-1.546.023A3.72 3.72 0 0115.667 22a3.743 3.743 0 01-2.9-1.646 1.055 1.055 0 00-1.547.024 5.247 5.247 0 01-1.269 1.17 1 1 0 001.013 1.722 5.805 5.805 0 00.719-.514.5.5 0 01.64 0 5.129 5.129 0 006.694 0 .5.5 0 01.638 0A5.124 5.124 0 0023 24a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});