define("ember-svg-jar/inlined/shopping-bag-barcode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-bag-barcode</title><path d=\"M20 9.5A1.5 1.5 0 0018.5 8h-17A1.5 1.5 0 000 9.5v13A1.5 1.5 0 001.5 24H10a.5.5 0 00.5-.5V15a.5.5 0 01.5-.5h8.5a.5.5 0 00.5-.5zM5.5 6.5a1 1 0 001-1 3.5 3.5 0 017 0 1 1 0 002 0 5.5 5.5 0 00-11 0 1 1 0 001 1z\"/><path d=\"M13.25 16a.75.75 0 00-.75.75v6.5a.75.75 0 001.5 0v-6.5a.75.75 0 00-.75-.75zM15.75 16a.75.75 0 00-.75.75v4a.75.75 0 001.5 0v-4a.75.75 0 00-.75-.75zM18.25 16a.75.75 0 00-.75.75v4a.75.75 0 001.5 0v-4a.75.75 0 00-.75-.75zM20.75 16a.75.75 0 00-.75.75v4a.75.75 0 001.5 0v-4a.75.75 0 00-.75-.75zM15.75 22a.75.75 0 00-.75.75v.5a.75.75 0 001.5 0v-.5a.75.75 0 00-.75-.75zM18.25 22a.75.75 0 00-.75.75v.5a.75.75 0 001.5 0v-.5a.75.75 0 00-.75-.75zM20.75 22a.75.75 0 00-.75.75v.5a.75.75 0 001.5 0v-.5a.75.75 0 00-.75-.75zM23.25 16a.75.75 0 00-.75.75v6.5a.75.75 0 001.5 0v-6.5a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});