define("ember-svg-jar/inlined/road-sign-no-parking-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-no-parking-alternate</title><path d=\"M24 12a12 12 0 10-12 12 12.016 12.016 0 0012-12zm-4.072 6.154a.246.246 0 01-.367.023l-6-6a.245.245 0 010-.347l6-6a.245.245 0 01.188-.072.248.248 0 01.179.1 10.019 10.019 0 010 12.307zM18.154 4.072a.246.246 0 01.023.367l-6 6a.244.244 0 01-.346 0l-6-6a.246.246 0 01.023-.367 10.018 10.018 0 0112.308 0zM4.071 5.847a.246.246 0 01.367-.023l6 6a.245.245 0 010 .347l-6 6a.244.244 0 01-.366-.024 10.017 10.017 0 010-12.306zm1.776 14.081a.244.244 0 01-.024-.366l6-6a.244.244 0 01.346 0l6 6a.245.245 0 01.072.188.248.248 0 01-.1.179 10.017 10.017 0 01-12.306 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});