define("ember-svg-jar/inlined/messages-bubble-square-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-star</title><path d=\"M12.259 13h1.187a.25.25 0 00.23-.151c.837-1.94.829-1.926.874-2.017a3.293 3.293 0 015.887-.03c.189.359.4.794.587 1.182a.25.25 0 00.476-.108V1.5A1.5 1.5 0 0020 0H1.5A1.5 1.5 0 000 1.5v12.053A1.459 1.459 0 001.5 15H3v3.5a.5.5 0 00.832.373L8.19 15h.884a.251.251 0 00.227-.145A3.179 3.179 0 0112.259 13z\"/><path d=\"M20.737 23.827a1.273 1.273 0 001.505-.166 1.256 1.256 0 00.316-1.45l-1.3-2.981 2.348-2.092a1.262 1.262 0 00.3-1.378A1.232 1.232 0 0022.74 15h-2.665l-1.409-3.267-.013-.028a1.293 1.293 0 00-2.317.028L14.925 15h-2.666a1.229 1.229 0 00-1.166.76 1.273 1.273 0 00.319 1.4l2.329 2.075-1.295 2.98a1.255 1.255 0 00.315 1.451 1.286 1.286 0 001.506.166l3.233-1.826z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});