define("ember-svg-jar/inlined/uv-medium", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>uv-medium</title><rect y=\"20\" width=\"11\" height=\"4\" rx=\"1\" ry=\"1\"/><path d=\"M10 14H1a1 1 0 00-1 1v2a1 1 0 001 1h9a1 1 0 001-1v-2a.99.99 0 00-.093-.421.241.241 0 00-.069-.089c-.155-.126-.3-.256-.451-.392a.266.266 0 00-.1-.056A1.02 1.02 0 0010 14zM1 8a1 1 0 00-1 1v2a1 1 0 001 1h7.286a.251.251 0 00.222-.365 8.919 8.919 0 01-.978-3.4A.25.25 0 007.281 8zM16.5 0A7.5 7.5 0 1024 7.5 7.5 7.5 0 0016.5 0zM16 9.25a2.25 2.25 0 01-4.5 0v-4.5a.75.75 0 011.5 0v4.5a.75.75 0 001.5 0v-4.5a.75.75 0 011.5 0zm5.5-3a8.3 8.3 0 01-1.65 4.95.75.75 0 01-1.2 0A8.3 8.3 0 0117 6.25v-1.5a.75.75 0 011.5 0v1.5a6.793 6.793 0 00.519 2.591.25.25 0 00.462 0A6.793 6.793 0 0020 6.25v-1.5a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});