define("ember-svg-jar/inlined/uv-high-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>uv-high-1</title><path d=\"M11 21.5a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h6.761a.25.25 0 00.2-.094A9.035 9.035 0 0111.1.3.169.169 0 0011 0H2a2 2 0 00-2 2v20a2 2 0 002 2h9a2 2 0 002-2v-6.044a.25.25 0 00-.148-.229 9 9 0 01-1.46-.821.25.25 0 00-.392.205z\"/><path d=\"M3 19.5a1 1 0 001 1h5a1 1 0 000-2H4a1 1 0 00-1 1zM4 17h5a1 1 0 000-2H4a1 1 0 000 2zM3 12.5a1 1 0 001 1h5a.979.979 0 00.308-.049.249.249 0 00.119-.392 9.037 9.037 0 01-.919-1.424.25.25 0 00-.222-.135H4a1 1 0 00-1 1zM3 9a1 1 0 001 1h3.527a.251.251 0 00.2-.1.255.255 0 00.046-.215A8.884 8.884 0 017.53 8.23.25.25 0 007.281 8H4a1 1 0 00-1 1zM3 5.5a1 1 0 001 1h3.335a.25.25 0 00.248-.216 8.816 8.816 0 01.324-1.459.253.253 0 00-.038-.225.25.25 0 00-.2-.1H4a1 1 0 00-1 1zM16.5 0A7.5 7.5 0 1024 7.5 7.5 7.5 0 0016.5 0zM16 9.25a2.25 2.25 0 01-4.5 0v-4.5a.75.75 0 011.5 0v4.5a.75.75 0 001.5 0v-4.5a.75.75 0 011.5 0zm5.5-3a8.3 8.3 0 01-1.65 4.95.75.75 0 01-1.2 0A8.3 8.3 0 0117 6.25v-1.5a.75.75 0 011.5 0v1.5a6.793 6.793 0 00.519 2.591.25.25 0 00.462 0A6.793 6.793 0 0020 6.25v-1.5a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});