define("ember-svg-jar/inlined/tea-box-herbal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tea-box-herbal</title><path d=\"M1.821 8.5h9.737a.5.5 0 00.369-.164l3.606-3.966A.221.221 0 0015.37 4H4.63a.5.5 0 00-.389.187l-2.81 3.5a.5.5 0 00.39.813zM11.5 20V10a.5.5 0 00-.5-.5H.429A.429.429 0 000 9.929v9.238A1.331 1.331 0 001.326 20.5H11a.5.5 0 00.5-.5zM9 15a1 1 0 01-1 1H3a1 1 0 01-1-1v-2a1 1 0 011-1h5a1 1 0 011 1zM4.923 3h10.154a.426.426 0 00.423-.429v-.428A2.373 2.373 0 0012.961 0H7.039A2.373 2.373 0 004.5 2.143v.428A.426.426 0 004.923 3zM12.5 9.387V15.1a.5.5 0 00.637.481 2.252 2.252 0 01.613-.085c.069 0 .137 0 .205.009a.5.5 0 00.545-.5v-.255a2.253 2.253 0 012.25-2.25c.069 0 .137 0 .2.009a.5.5 0 00.545-.5v-.259a2.243 2.243 0 011.976-2.228.291.291 0 00.171-.5L16.732 5.4a.5.5 0 00-.76-.023L12.63 9.05a.5.5 0 00-.13.337zM23.25 14.5h-1.086a.25.25 0 01-.177-.427l.293-.293a.75.75 0 00-1.06-1.06l-.293.293a.25.25 0 01-.427-.177V11.75a.75.75 0 00-1.5 0v2.982a.5.5 0 01-.146.354l-.927.927a.25.25 0 01-.427-.177V14.75a.75.75 0 00-1.5 0v2.982a.5.5 0 01-.146.354l-.927.927a.25.25 0 01-.427-.177V17.75a.75.75 0 00-1.5 0v2.982a.5.5 0 01-.146.354L11.22 22.72a.75.75 0 101.06 1.06l1.634-1.634a.5.5 0 01.354-.146h2.982a.75.75 0 000-1.5h-1.086a.25.25 0 01-.177-.427l.927-.927a.5.5 0 01.354-.146h2.982a.75.75 0 000-1.5h-1.086a.25.25 0 01-.177-.427l.927-.927a.5.5 0 01.354-.146h2.982a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});