define("ember-svg-jar/inlined/old-people-woman-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>old-people-woman-1</title><path d=\"M22.344 19.5a14.459 14.459 0 00-6.327-2.534h-.007a.479.479 0 00-.4.108.494.494 0 00-.175.376v1.125a.247.247 0 01-.159.23L12.9 19.7a.245.245 0 00-.16.23v3.829a.246.246 0 00.246.245H22.8a.491.491 0 00.491-.491v-2.072a2.421 2.421 0 00-.947-1.941zM11.264 19.926a.245.245 0 00-.16-.23L8.722 18.8a.247.247 0 01-.159-.23v-1.122a.494.494 0 00-.175-.376.474.474 0 00-.4-.107A14.461 14.461 0 001.656 19.5a2.421 2.421 0 00-.949 1.941v2.068A.491.491 0 001.2 24h9.82a.246.246 0 00.246-.245z\"/><circle cx=\"13.964\" cy=\"10.252\" r=\".982\"/><circle cx=\"10.036\" cy=\"10.252\" r=\".982\"/><path d=\"M13.058 12.345h-1.965a.628.628 0 00-.3 1.178 2.252 2.252 0 00.43.18 2.982 2.982 0 00.854.124 2.94 2.94 0 00.85-.122 2.294 2.294 0 00.433-.181.628.628 0 00-.3-1.178z\"/><path d=\"M22.589 5.85a35.015 35.015 0 01-3.484.568.24.24 0 01-.182-.053.244.244 0 01-.091-.167 6.864 6.864 0 00-13.664 0 .244.244 0 01-.091.167.24.24 0 01-.182.053 35.015 35.015 0 01-3.484-.568.982.982 0 00-.426 1.918 38.373 38.373 0 003.932.632.246.246 0 01.218.234c.039.972.117 4.85-2.123 5.57a.49.49 0 00-.227.782 3.73 3.73 0 002.869 1.1 4.528 4.528 0 002.3-.588.248.248 0 01.268 0 6.847 6.847 0 007.505.032.245.245 0 01.27 0 4.538 4.538 0 002.346.611 3.73 3.73 0 002.869-1.1.489.489 0 00-.227-.781c-2.249-.722-2.157-4.622-2.121-5.628a.245.245 0 01.218-.235 38.375 38.375 0 003.93-.631.982.982 0 00-.426-1.918zm-5.646-.684a.247.247 0 01.255.18 5.314 5.314 0 01.171 1 .247.247 0 01-.226.264 67.66 67.66 0 01-10.286 0 .247.247 0 01-.226-.264 5.314 5.314 0 01.171-1 .247.247 0 01.255-.18 68.386 68.386 0 009.886 0zM7.09 9.761v-.9a.245.245 0 01.263-.245 67.387 67.387 0 009.294 0 .247.247 0 01.185.065.251.251 0 01.078.18v.9a4.91 4.91 0 01-9.82 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});