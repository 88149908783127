define("ember-svg-jar/inlined/laundry-iron-water", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laundry-iron-water</title><path d=\"M19.231 6.433a.494.494 0 00.495-.5V4.454a.99.99 0 00-.989-.99h-3.959a.991.991 0 00-.99.99v1.484a.494.494 0 00.495.5z\"/><path d=\"M19.615 0H7.739a.99.99 0 100 1.979h11.876a1.98 1.98 0 011.979 1.98v3.216a.247.247 0 01-.247.248h-8.669a10.39 10.39 0 00-7.766 3.487L3.319 12.7a.247.247 0 00.185.411h19.822a.247.247 0 00.248-.247V3.959A3.959 3.959 0 0019.615 0zM23.326 14.6H1.744a.246.246 0 00-.185.083l-.881.992a.99.99 0 00.74 1.647h20.671a1.485 1.485 0 001.485-1.485v-.99a.248.248 0 00-.248-.247z\"/><circle cx=\"13.182\" cy=\"19.794\" r=\"1.237\"/><circle cx=\"13.182\" cy=\"22.763\" r=\"1.237\"/><circle cx=\"8.728\" cy=\"19.794\" r=\"1.237\"/><circle cx=\"5.759\" cy=\"22.763\" r=\"1.237\"/><circle cx=\"17.636\" cy=\"19.794\" r=\"1.237\"/><circle cx=\"20.605\" cy=\"22.763\" r=\"1.237\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});