define("ember-svg-jar/inlined/office-table-cabinets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-table-cabinets</title><path d=\"M6.75.249A.25.25 0 006.5 0h-5A1.5 1.5 0 000 1.5v5A1.5 1.5 0 001.5 8h5a.249.249 0 00.25-.25zM2 3.5v-1a.5.5 0 011 0v1a.5.5 0 11-1 0zM16.25 16a.25.25 0 00-.25.25V23c0 .458.813 1 1.5 1h5c.687 0 1.5-.542 1.5-1v-6.75a.25.25 0 00-.25-.25zM18 18a.5.5 0 111 0v1a.5.5 0 01-1 0zM15 .249A.25.25 0 0014.75 0H8a.25.25 0 00-.25.25v7.5A.249.249 0 008 8h6.75a.249.249 0 00.25-.25zM10.25 4a.5.5 0 01-.5-.5v-1a.5.5 0 011 0v1a.5.5 0 01-.5.5zM7.75 13.5a1 1 0 00-1-1H2a2 2 0 00-2 2V23a1 1 0 002 0v-8a.5.5 0 01.5-.5h4.25a1 1 0 001-1z\"/><path d=\"M10.5 10.5a1 1 0 00-1 1v5a1 1 0 01-1 1h-4a1 1 0 000 2h1.75a.25.25 0 01.25.25v1.042a.251.251 0 01-.121.215l-1.894 1.135a1 1 0 001.03 1.715l1.856-1.114a.249.249 0 01.258 0l1.856 1.114a1 1 0 001.03-1.715l-1.894-1.136a.251.251 0 01-.121-.215V19.74a.25.25 0 01.231-.249A3 3 0 0011.5 16.5v-5a1 1 0 00-1-1zM23.75 14.5a.249.249 0 00.25-.25V2c0-1.474-.774-2-1.5-2h-6.25a.25.25 0 00-.25.25v12a.249.249 0 01-.25.25H13.5a1 1 0 000 2zM19 3.5a.5.5 0 01-1 0v-1a.5.5 0 111 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});