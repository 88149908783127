define("ember-svg-jar/inlined/crypto-currency-infinitecoin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-infinitecoin</title><path d=\"M4.75 12a2.277 2.277 0 002.3 2.25c.765 0 2.331-1.043 3.577-2.056a.251.251 0 000-.388C9.378 10.794 7.811 9.75 7.048 9.75A2.277 2.277 0 004.75 12zM16.952 9.75c-.765 0-2.331 1.043-3.577 2.056a.251.251 0 000 .388c1.247 1.012 2.814 2.056 3.577 2.056a2.251 2.251 0 100-4.5z\"/><path d=\"M12 1a11 11 0 1011 11A11 11 0 0012 1zm4.952 14.75c-1.48 0-3.65-1.649-4.791-2.612a.25.25 0 00-.322 0C10.7 14.1 8.527 15.75 7.048 15.75a3.75 3.75 0 110-7.5c1.48 0 3.65 1.649 4.791 2.612a.25.25 0 00.322 0C13.3 9.9 15.473 8.25 16.952 8.25a3.75 3.75 0 110 7.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});