define("ember-svg-jar/inlined/circle-pad-finger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>circle-pad-finger</title><path d=\"M6.428 10.042a1 1 0 00.387-1.36 4.5 4.5 0 117.962-.173 1 1 0 101.789.895 6.5 6.5 0 10-11.5.251 1.012 1.012 0 001.362.387z\"/><path d=\"M16.129 17H13.5a.249.249 0 01-.25-.25v-6.379a2.375 2.375 0 00-4.75 0V18.9a.25.25 0 01-.454.145L7.162 17.8a2.075 2.075 0 10-3.672 1.937l2.1 3.991a.5.5 0 00.443.272H20.25a.5.5 0 00.5-.5v-1.657A4.8 4.8 0 0016.129 17z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});