define("ember-svg-jar/inlined/gmo-food-apple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gmo-food-apple</title><ellipse cx=\"4.25\" cy=\"8.822\" rx=\"1.061\" ry=\"3\" transform=\"rotate(-45 4.25 8.822)\"/><path d=\"M23.694 5.642L18.658.78a1 1 0 00-1.388 1.44l1.056 1.019a.508.508 0 01.153.351.5.5 0 01-.141.357l-.694.719a.5.5 0 01-.707.012l-1.776-1.714A1 1 0 1013.772 4.4l.772.746a.426.426 0 01.011.6.427.427 0 00.01.6L15.7 7.447a.5.5 0 11-.695.72l-1.082-1.042a.5.5 0 00-.707.012l-.844.875a.5.5 0 00.012.707l1.027.991a.5.5 0 01-.7.72l-1.021-.992a.5.5 0 00-.356-.14.5.5 0 00-.351.153l-.3.31a3 3 0 00-.7 2.982.5.5 0 01-.118.5L8.73 14.41a.25.25 0 01-.414-.086A4.99 4.99 0 018 12.572a.357.357 0 00-.485-.334 2.989 2.989 0 00-.784.454.381.381 0 01-.462 0A3.328 3.328 0 003.974 12C1.783 12 0 14.219 0 16.947 0 19.475 1.963 23.5 4.29 23.5c1.2 0 1.559-.227 1.879-.5.1-.087.123-.25.331-.25s.229.163.332.25c.319.273.682.5 1.878.5 1.99 0 3.709-2.941 4.167-5.373a.5.5 0 00-.436-.59 4.957 4.957 0 01-1.627-.471.5.5 0 00-.578.1L8.73 18.728a.5.5 0 01-.72-.694l3.174-3.286a.5.5 0 01.545-.117 3 3 0 003.271-.7l3.871-4.011a.427.427 0 01.6-.01l.772.746a1 1 0 101.389-1.439L19.86 7.5a.5.5 0 01-.013-.707l.7-.72a.5.5 0 01.707-.012l1.057 1.02a1 1 0 101.388-1.439z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});