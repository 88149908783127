define("ember-svg-jar/inlined/saving-safe-open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>saving-safe-open</title><path d=\"M7.011 9.967l-.667 2a.5.5 0 00.475.658h4.612a.5.5 0 00.475-.658l-.667-2a.5.5 0 00-.474-.342h-3.28a.5.5 0 00-.474.342zM7.933 13.967a.5.5 0 00-.475-.342H4.679a.5.5 0 00-.474.342l-.667 2a.5.5 0 00.474.658h4.113a.5.5 0 00.474-.658zM14.045 13.967a.5.5 0 00-.474-.342h-2.779a.5.5 0 00-.475.342l-.666 2a.5.5 0 00.474.658h4.113a.5.5 0 00.474-.658z\"/><path d=\"M23.563 2.017a.747.747 0 00-.675-.1L17.9 3.575a.248.248 0 01-.287-.1 2.984 2.984 0 00-2.49-1.351h-12a3 3 0 00-3 3v12A2.994 2.994 0 002.2 19.968a.251.251 0 01.173.238v.669a1.25 1.25 0 002.5 0v-.5a.25.25 0 01.25-.25h8a.25.25 0 01.25.25v.5a1.25 1.25 0 002.5 0v-.669a.251.251 0 01.173-.238 2.993 2.993 0 001.608-1.259.249.249 0 01.272-.11l5.015 1.254a.75.75 0 00.932-.728v-16.5a.749.749 0 00-.31-.608zm-7.438 15.108a1 1 0 01-1 1h-12a1 1 0 01-1-1v-12a1 1 0 011-1h12a1 1 0 011 1z\"/><path d=\"M3.819 8.158a1.508 1.508 0 011.273 1.273l.038.269a.5.5 0 00.99 0l.038-.265a1.508 1.508 0 011.274-1.277L7.7 8.12a.5.5 0 000-.99l-.265-.038a1.507 1.507 0 01-1.277-1.273l-.038-.265a.5.5 0 00-.99 0l-.038.266a1.507 1.507 0 01-1.274 1.272l-.264.038a.5.5 0 000 .99z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});