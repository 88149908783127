define("ember-svg-jar/inlined/direction-button-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>direction-button-3</title><path d=\"M9.5 12a1.986 1.986 0 00-.586-1.414l-1.5-1.5A2.015 2.015 0 006 8.5H1.5A1.5 1.5 0 000 10v4a1.5 1.5 0 001.5 1.5H6a1.986 1.986 0 001.414-.586l1.5-1.5A1.986 1.986 0 009.5 12zM22.5 8.5H18a1.986 1.986 0 00-1.414.586l-1.5 1.5a2 2 0 000 2.828l1.5 1.5A1.986 1.986 0 0018 15.5h4.5A1.5 1.5 0 0024 14v-4a1.5 1.5 0 00-1.5-1.5zM13.414 15.084a2 2 0 00-2.828 0l-1.5 1.5A1.992 1.992 0 008.5 18v4.5A1.5 1.5 0 0010 24h4a1.5 1.5 0 001.5-1.5V18a1.993 1.993 0 00-.585-1.414zM10.586 8.912a2 2 0 002.828 0l1.5-1.5A1.992 1.992 0 0015.5 6V1.5A1.5 1.5 0 0014 0h-4a1.5 1.5 0 00-1.5 1.5V6a1.989 1.989 0 00.586 1.415z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});