define("ember-svg-jar/inlined/vintage-camera-foldable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-camera-foldable</title><path d=\"M11.444 18.427a.252.252 0 010-.354l2.94-2.939a1.25 1.25 0 00-1.768-1.768l-2.939 2.94a.252.252 0 01-.354 0l-1.879-1.879A.25.25 0 017.621 14H8.5a.5.5 0 00.5-.5v-12A1.5 1.5 0 007.5 0h-6A1.5 1.5 0 000 1.5v11A1.5 1.5 0 001.5 14h1.879a.251.251 0 01.177.073l4 4a.252.252 0 010 .354l-3.44 3.439a1.25 1.25 0 001.768 1.768l3.439-3.44a.252.252 0 01.354 0l3.439 3.44a1.25 1.25 0 001.768-1.768z\"/><path d=\"M12 11.5a2 2 0 002-2v-6a2 2 0 00-4 0v6a2 2 0 002 2zM16.5 2.5A1.5 1.5 0 0015 4v5a1.5 1.5 0 003 0V4a1.5 1.5 0 00-1.5-1.5zM22.382 3.5l-3.106 1.553A.5.5 0 0019 5.5v2a.5.5 0 00.276.447L22.382 9.5A1.064 1.064 0 0024 8.5v-4a1.062 1.062 0 00-1.618-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});