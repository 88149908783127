define("ember-svg-jar/inlined/ads-window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ads-window</title><path d=\"M23 7.5a1 1 0 00-1 1v13a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h12.75a1 1 0 000-2H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2V8.5a1 1 0 00-1-1z\"/><path d=\"M17.5 10.25H19a.75.75 0 000-1.5h-1.5a2.16 2.16 0 00-2.25 2.05 3.1 3.1 0 001.841 2.579c.8.524 1.159.807 1.159 1.322 0 .3-.344.549-.75.549H16a.75.75 0 000 1.5h1.5a2.159 2.159 0 002.25-2.05 3.1 3.1 0 00-1.841-2.579c-.8-.524-1.159-.807-1.159-1.322 0-.299.344-.549.75-.549zM7.5 16.75a.75.75 0 00.75-.75v-5.2A2.16 2.16 0 006 8.75a2.16 2.16 0 00-2.25 2.05V16a.75.75 0 001.5 0v-1.6a.25.25 0 01.25-.25h1a.25.25 0 01.25.25V16a.75.75 0 00.75.75zm-1-4.1h-1a.25.25 0 01-.25-.25v-1.6c0-.3.344-.55.75-.55s.75.252.75.55v1.6a.25.25 0 01-.25.251zM9.75 9.5V16a.75.75 0 00.75.75 3.571 3.571 0 003.75-3.35v-1.3a3.572 3.572 0 00-3.75-3.35.75.75 0 00-.75.75zm1.5 1.216a.251.251 0 01.116-.211.248.248 0 01.239-.016A1.83 1.83 0 0112.75 12.1v1.3a1.829 1.829 0 01-1.145 1.61.249.249 0 01-.355-.226zM23.707 1.708A1 1 0 0022.293.293L21.177 1.41a.252.252 0 01-.354 0L19.707.293a1 1 0 00-1.414 1.415l1.116 1.116a.249.249 0 010 .353l-1.116 1.116a1 1 0 001.414 1.415l1.116-1.117a.252.252 0 01.354 0l1.116 1.117a1 1 0 101.414-1.415l-1.116-1.116a.249.249 0 010-.353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});