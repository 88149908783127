define("ember-svg-jar/inlined/expand-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>expand-vertical</title><path d=\"M9 19a1 1 0 00-.707 1.707l3 3a1 1 0 001.414 0l3-3A1 1 0 0015 19h-1.5a.25.25 0 01-.25-.25V5.249A.25.25 0 0113.5 5H15a1 1 0 00.707-1.707l-3-3a1 1 0 00-1.414 0l-3 3A1 1 0 009 5h1.5a.25.25 0 01.25.25v13.5a.25.25 0 01-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});