define("ember-svg-jar/inlined/gymnastics-horse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gymnastics-horse</title><circle cx=\"3\" cy=\"2.5\" r=\"2.5\"/><path d=\"M5 14.5a1.5 1.5 0 003 0v-3.952a.25.25 0 01.376-.216l3.368 1.968a1.5 1.5 0 00.756.2H22a1.5 1.5 0 000-3h-5.275a.25.25 0 01-.177-.427l3.513-3.512a1.5 1.5 0 00-2.122-2.122l-5.546 5.547a.25.25 0 01-.3.039L9.218 7.349a.25.25 0 01-.118-.184.253.253 0 01.071-.209l4.394-4.4A1.5 1.5 0 0011.439.439L6.9 4.975A6.455 6.455 0 005 9.571zM24 22a2.5 2.5 0 00-2.5-2.5h-2.25a.25.25 0 00-.25.25V21a.5.5 0 01-1 0v-3.03a1 1 0 00-2 0v1.53H8.25a.25.25 0 00-.25.25V21a.5.5 0 01-1 0v-3.03a1 1 0 00-2 0v1.53H2.5A2.5 2.5 0 000 22v1.5a.5.5 0 00.5.5h23a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});