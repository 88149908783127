define("ember-svg-jar/inlined/style-one-pin-bolt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-bolt</title><path d=\"M12 0a9.5 9.5 0 00-4 18.119 2.5 2.5 0 011.218 1.222l1.873 4.076a1 1 0 001.817 0l1.876-4.08A2.5 2.5 0 0116 18.118 9.5 9.5 0 0012 0zm4.477 9.47L10.7 16.657a.252.252 0 01-.2.093.263.263 0 01-.083-.014.251.251 0 01-.167-.236V11a.25.25 0 00-.25-.25H8.108a.751.751 0 01-.585-1.22l5.782-7.187a.25.25 0 01.445.157V8a.25.25 0 00.25.25h1.892a.751.751 0 01.585 1.22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});