define("ember-svg-jar/inlined/heavy-equipment-hook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>heavy-equipment-hook</title><path d=\"M23 8.986a1 1 0 00.421-1.907L8.553.185a2.692 2.692 0 00-2.8.588L.234 7.342A1 1 0 001 8.986h3a.25.25 0 01.25.25v5.579l-2.36 2.091a2.27 2.27 0 00-.71 1.58v3.5H1a1 1 0 000 2h12a1 1 0 100-2h-.07v-3.5a2.314 2.314 0 00-.683-1.572L9.75 14.62V9.236a.25.25 0 01.25-.25h8.75a.25.25 0 01.25.25v6.208a2 2 0 001.2 1.833.5.5 0 11-.7.459 1 1 0 00-2 0 2.5 2.5 0 103.5-2.292V9.236a.25.25 0 01.25-.25zM5.809 3.815a.25.25 0 01.441.161v2.76a.25.25 0 01-.25.25H3.682a.25.25 0 01-.192-.411zm-2.63 14.7a.868.868 0 01.038-.112l1.033-.915v4.5H3.18zm7.687-.154a.552.552 0 01.064.126v3.5H9.75v-4.668zM7.75 21.986h-1.5V9.236a.25.25 0 01.25-.25h1a.25.25 0 01.25.25zm.75-15a.25.25 0 01-.25-.25V2.64a.25.25 0 01.355-.227l9.861 4.573z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});