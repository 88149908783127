define("ember-svg-jar/inlined/bicycle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bicycle</title><path d=\"M5.5 6.137a1 1 0 000 2h1.409a.249.249 0 01.231.156l.637 1.568a.251.251 0 01-.036.25l-.35.437a.25.25 0 01-.3.07A4.894 4.894 0 005 10.137a5 5 0 104.856 6.19.25.25 0 01.243-.19h.4a1 1 0 00.807-.409l4.281-5.837a.247.247 0 01.236-.1.252.252 0 01.2.161l.281.762a.251.251 0 01-.095.293 4.978 4.978 0 102.79-.87 3.824 3.824 0 00-.549.046.25.25 0 01-.27-.161L16.92 6.6a.249.249 0 01.174-.329l1.742-.435a1 1 0 00-.485-1.941l-2.551.637a1.5 1.5 0 00-1.042 1.974l.08.217a.253.253 0 01-.008.193.25.25 0 01-.142.129L9.764 8.8a.251.251 0 01-.316-.141l-.113-.279a.178.178 0 01.165-.243 1 1 0 000-2zm-.5 12a3 3 0 112.658-4.364.25.25 0 01-.222.364H5a1 1 0 000 2h2.436a.25.25 0 01.222.364A2.985 2.985 0 015 18.137zm5.049-4.076a.1.1 0 01-.174-.036 4.941 4.941 0 00-.927-1.916.249.249 0 010-.309l.609-.761a.252.252 0 01.111-.08L12.5 9.95a.25.25 0 01.286.383zM19 18.137a3 3 0 01-3-3 2.959 2.959 0 01.8-2.022.249.249 0 01.417.084l.842 2.284a1 1 0 101.876-.692l-.964-2.617a.028.028 0 010-.025.028.028 0 01.029-.012 3 3 0 010 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});