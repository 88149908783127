define("ember-svg-jar/inlined/laptop-heart.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-heart</title><path d=\"M9.542 15.426a.248.248 0 00-.143-.259 2.382 2.382 0 01-1.349-1.316.492.492 0 00-.47-.36H.5a.478.478 0 00-.41.22.467.467 0 00-.05.47 5.431 5.431 0 005 3.31h4.35a.248.248 0 00.2-.1.252.252 0 00.046-.214 4.765 4.765 0 01-.094-1.751zM3.5 12a1 1 0 001-1V3a1 1 0 011-1h12a1 1 0 011 1v7.75a.25.25 0 00.25.25h1.5a.25.25 0 00.25-.25V3a3.009 3.009 0 00-3-3h-12a3.009 3.009 0 00-3 3v8a1 1 0 001 1zM23.628 14.45a3.526 3.526 0 00-5.646-.916l-.3.3a.25.25 0 01-.353 0l-.305-.3a3.525 3.525 0 00-4.993 4.977l5.114 5.335a.5.5 0 00.721 0l5.107-5.327a3.522 3.522 0 00.655-4.069z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});