define("ember-svg-jar/inlined/password-cursor-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>password-cursor-1</title><path d=\"M0 12.777a3 3 0 003 3h8.5a1 1 0 000-2H3a1 1 0 01-1-1v-7a1 1 0 011-1h18a1 1 0 011 1v7a1 1 0 102 0v-7a3 3 0 00-3-3H3a3 3 0 00-3 3z\"/><circle cx=\"5.499\" cy=\"9.277\" r=\"1.5\"/><circle cx=\"12\" cy=\"9.277\" r=\"1.5\"/><circle cx=\"18.501\" cy=\"9.277\" r=\"1.5\"/><path d=\"M15.223 12.83a.5.5 0 00-.67.671l3.724 7.449a.5.5 0 00.932-.1l.6-2.4a.5.5 0 01.364-.364l2.4-.6a.5.5 0 00.1-.932z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});