define("ember-svg-jar/inlined/plant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plant</title><path d=\"M10.866 7.854v.6a.5.5 0 00.205.4 6.835 6.835 0 012.737 4.617.5.5 0 00.725.38A5.84 5.84 0 0017.7 8.667a.5.5 0 00-.5-.5 5.806 5.806 0 00-3.916 1.514.251.251 0 01-.418-.186v-1.64a1 1 0 01.155-.534 5.784 5.784 0 00-.8-7.175.5.5 0 00-.707 0 5.785 5.785 0 00-.8 7.174 1 1 0 01.152.534zM12.866 16.782v-2.415a5.84 5.84 0 00-5.833-5.834.5.5 0 00-.5.5 5.845 5.845 0 003.7 5.43 1 1 0 01.633.929v2.33a.249.249 0 01-.429.173 7.664 7.664 0 00-5.5-2.328.75.75 0 00-.75.75 7.7 7.7 0 005.587 7.393.479.479 0 00.609-.46 8.649 8.649 0 012.227-5.8 1 1 0 00.256-.668zM19.067 15.568a7.692 7.692 0 00-7.684 7.682.75.75 0 00.75.75 7.692 7.692 0 007.684-7.682.749.749 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});