define("ember-svg-jar/inlined/toilet-paper-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toilet-paper-1</title><path d=\"M22.741 12.121a.5.5 0 010-.449l1.15-2.286a1 1 0 00-.031-.958L22.8 6.633a.5.5 0 010-.511l.739-1.232a1 1 0 00-.857-1.515H18a.5.5 0 00-.5.5v16a.5.5 0 00.5.5h4.686a1 1 0 00.857-1.515l-.735-1.226a.5.5 0 010-.516l1.045-1.727a1 1 0 00.039-.965zM8 7.625c4.678 0 7.821-1.939 7.821-3.75S12.678.125 8 .125.179 2.064.179 3.875 3.321 7.625 8 7.625zm0-5.25c1.933 0 3.5.672 3.5 1.5s-1.567 1.5-3.5 1.5-3.5-.675-3.5-1.5 1.567-1.5 3.5-1.5z\"/><path d=\"M15.732 7.2a.5.5 0 00-.515.03A13.011 13.011 0 018 9.125a13.008 13.008 0 01-7.217-1.9.5.5 0 00-.783.412v12.238c0 2.243 3.514 4 8 4s8-1.757 8-4V7.637a.5.5 0 00-.268-.437z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});