define("ember-svg-jar/inlined/arrow-button-up-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-up-1</title><path d=\"M12 10.416a2.643 2.643 0 011.875.775l9.541 9.541a1.768 1.768 0 01-2.5 2.5l-8.739-8.739a.25.25 0 00-.354 0l-8.739 8.739a1.768 1.768 0 01-2.5-2.5l9.541-9.541A2.643 2.643 0 0112 10.416z\"/><path d=\"M12 .25a2.643 2.643 0 011.875.775l9.541 9.541a1.768 1.768 0 01-2.5 2.5l-8.739-8.739a.25.25 0 00-.354 0l-8.739 8.739a1.768 1.768 0 01-2.5-2.5l9.541-9.541A2.643 2.643 0 0112 .25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});