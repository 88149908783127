define("ember-svg-jar/inlined/accounting-bills", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accounting-bills</title><path d=\"M20.375 15.792V4.958a1.068 1.068 0 00-1.053-1.083H1.428A1.068 1.068 0 00.375 4.958v10.834a1.068 1.068 0 001.053 1.083h17.894a1.068 1.068 0 001.053-1.083zm-10-2.417a3 3 0 113-3 3 3 0 01-3 3z\"/><path d=\"M22.875 6.625a.75.75 0 00-.75.75v11a.25.25 0 01-.25.25h-18a.75.75 0 000 1.5h18a1.752 1.752 0 001.75-1.75v-11a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});