define("ember-svg-jar/inlined/video-edit-magic-wand-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-edit-magic-wand-alternate</title><path d=\"M12.319 11.529a.25.25 0 00-.319.241v2.73a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5V10a.5.5 0 01.5-.5h5.066a.25.25 0 00.2-.4 6.494 6.494 0 01-.8-1.445.249.249 0 00-.234-.155H3.5A.5.5 0 013 7V2.5a.5.5 0 01.5-.5h4.731a.251.251 0 00.214-.12A6.531 6.531 0 019.638.435.25.25 0 009.47 0H1a1 1 0 00-1 1v22a1 1 0 001 1h13a1 1 0 001-1V11.959a.249.249 0 00-.083-.186.252.252 0 00-.194-.063 6.652 6.652 0 01-.723.04 6.5 6.5 0 01-1.681-.221zM3.5 17h8a.5.5 0 01.5.5v4a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5zM23.7 14.949a1 1 0 000-1.414l-4.451-4.45L14.771 4.6a1 1 0 00-1.415 1.414l4.482 4.486 4.451 4.45a1 1 0 001.411-.001z\"/><path d=\"M10.205 1.451a.75.75 0 000 1.061l1.414 1.414a.75.75 0 001.061-1.061l-1.414-1.414a.75.75 0 00-1.061 0zM13.67 2.565a.75.75 0 101.471.292L15.53.9a.749.749 0 10-1.47-.3zM15.534 4.494a.75.75 0 00.981.405l1.847-.764a.75.75 0 10-.574-1.386l-1.848.765a.751.751 0 00-.406.98zM12.2 5.505a.748.748 0 00-.882-.589l-1.962.39a.75.75 0 00.294 1.471l1.962-.39a.749.749 0 00.588-.882zM13.248 6.78a.752.752 0 00-.98.406L11.5 9.034a.751.751 0 001.387.574l.765-1.848a.75.75 0 00-.404-.98z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});