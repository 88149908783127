define("ember-svg-jar/inlined/fox-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fox-body</title><path d=\"M23.5 9.995h-2.526A4.008 4.008 0 0018 6.6V4.5a.5.5 0 00-.728-.446c-3.282 1.678-4.94 5.714-5.38 6.946H6.75a4.5 4.5 0 00-4.5 4.5v3.25H2a1 1 0 00-.956.706l-1 3.25a1 1 0 001.912.588l.709-2.3a3.58 3.58 0 00.782-.048c1.677-.261 4.03-1.862 3.473-4.34a.75.75 0 011.463-.33A4.73 4.73 0 018.187 19h5.377a.5.5 0 01.5.473l.187 3.583a1 1 0 002-.1L16 18a3.5 3.5 0 013.5-3.5h1c2.769 0 3.5-2.617 3.5-4a.5.5 0 00-.5-.505zM18 11a1 1 0 111-1 1 1 0 01-1 1zM1.432 12.719A6.02 6.02 0 015.645 9.6a7.753 7.753 0 001.918-3.1 8.978 8.978 0 00-.1-5.828.993.993 0 00-1.2-.629 8.409 8.409 0 00-4.7 3.312l-.009.013a8.1 8.1 0 00-.122 9.351zM5.851 2.3a6.965 6.965 0 01-.014 2.929A3 3 0 013.58 4a6.429 6.429 0 012.271-1.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});