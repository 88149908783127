define("ember-svg-jar/inlined/calendar-school", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>calendar-school</title><path d=\"M21.5 3h-2.75a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v4.75a.75.75 0 11-1.5 0V3.5a.5.5 0 00-.5-.5H8.25A.25.25 0 018 2.751V1a1 1 0 10-2 0v4.75a.75.75 0 11-1.5 0V3.5A.5.5 0 004 3H2.5a2 2 0 00-2 2v17a2 2 0 002 2h19a2 2 0 002-2V5a2 2 0 00-2-2zm0 18.5a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5v-12A.5.5 0 013 9h18a.5.5 0 01.5.5z\"/><path d=\"M18.185 13.111l-6-2.383a.513.513 0 00-.37 0l-6 2.383a.5.5 0 00-.315.474v3.38a.75.75 0 001.5 0v-2.506l4.834 1.706a.5.5 0 00.332 0l6-2.118a.5.5 0 00.019-.936z\"/><path d=\"M15.263 15.829L12 16.981l-3.263-1.152a.5.5 0 00-.666.472v2.145a.5.5 0 00.334.472l2.574.908a3.078 3.078 0 002.042 0l2.574-.908a.5.5 0 00.334-.472V16.3a.5.5 0 00-.666-.472z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});