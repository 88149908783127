define("ember-svg-jar/inlined/e-commerce-play-store", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-play-store</title><path d=\"M22 9.73l-1.87-.88a.5.5 0 00-.55.09l-2.83 2.65a.5.5 0 000 .73L19.62 15a.5.5 0 00.55.09l1.83-.84a2.5 2.5 0 000-4.53zM2.61 22.77l11.12-10.43a.5.5 0 000-.73L2.5 1.23a2.48 2.48 0 00-.74.3A2.49 2.49 0 00.6 3.64v16.7a2.49 2.49 0 001.16 2.11 2.48 2.48 0 00.85.32zM17.79 8.53a.5.5 0 00-.13-.82L5.6 2l9.28 8.58a.5.5 0 00.68 0zM15.57 13.31a.5.5 0 00-.68 0L5.8 21.84l11.93-5.6a.5.5 0 00.13-.82z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});