define("ember-svg-jar/inlined/delivery-truck-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>delivery-truck-clock</title><circle cx=\"10.163\" cy=\"21.904\" r=\"1.5\"/><path d=\"M22.1 13.364a2.013 2.013 0 00-1.9-1.459h-2.491a.249.249 0 01-.241-.228 1.841 1.841 0 00-1.812-1.52h-2.4a.252.252 0 00-.225.14 7.538 7.538 0 01-6.722 4.148c-.213 0-.423-.011-.631-.03a.249.249 0 00-.273.249V19.9a1.75 1.75 0 001.75 1.75h.309a.25.25 0 00.245-.2 2.485 2.485 0 014.889-.011.266.266 0 00.248.216h4.124a.265.265 0 00.248-.216 2.485 2.485 0 014.9.054.186.186 0 00.2.155 1.692 1.692 0 001.384-.834 2.04 2.04 0 00.2-1.66zm-2.129.528a.247.247 0 01.237.169c.07.2.211.631.489 1.518a.25.25 0 01-.239.325h-2.791a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.249-.25z\"/><circle cx=\"19.662\" cy=\"21.904\" r=\"1.5\"/><path d=\"M6.317 13.211A6.308 6.308 0 10.009 6.9a6.314 6.314 0 006.308 6.311zM10.625 6.9a4.308 4.308 0 11-4.308-4.3 4.313 4.313 0 014.308 4.3z\"/><path d=\"M6.317 7.9h1.846a1 1 0 000-2h-.6a.25.25 0 01-.25-.25V4.6a1 1 0 00-2 0v2.3a1 1 0 001.004 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});