define("ember-svg-jar/inlined/tools-kitchen-scale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-kitchen-scale</title><path d=\"M12 18.5a1 1 0 01-1-1v-2a1 1 0 012 0v2a1 1 0 01-1 1z\"/><path d=\"M20.5 24a2 2 0 001.911-2.588l-2.986-9.706a1 1 0 00-.956-.706h-.719a.5.5 0 01-.5-.5V6.906a1 1 0 01.5-.868A11.064 11.064 0 0022.462.691.5.5 0 0022 0H2.5a.5.5 0 00-.417.222.5.5 0 00-.047.469A11.066 11.066 0 006.289 5.76a1 1 0 01.461.84v3.9a.5.5 0 01-.5.5h-.719a1 1 0 00-.956.706l-2.987 9.706A2 2 0 003.5 24zM9.25 7.729a.5.5 0 01.608-.488 11.145 11.145 0 002.392.259 11.168 11.168 0 001.914-.165.5.5 0 01.586.493V10.5a.5.5 0 01-.5.5h-4.5a.5.5 0 01-.5-.5zM12 22a4.5 4.5 0 114.5-4.5A4.5 4.5 0 0112 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});