define("ember-svg-jar/inlined/e-commerce-cart-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-cart-laptop</title><path d=\"M16.244 4.767a.752.752 0 00-.961.449l-.306.841a.248.248 0 01-.235.165H8.988a1.242 1.242 0 00-1.043.562 1.225 1.225 0 00-.123 1.137l1.088 3.041a1.245 1.245 0 001.149.76h3.578a1.256 1.256 0 001.176-.823l1.88-5.17a.751.751 0 00-.449-.962z\"/><circle cx=\"9.988\" cy=\"13.472\" r=\"1\"/><circle cx=\"13.488\" cy=\"13.472\" r=\"1\"/><path d=\"M23.692 19.21l-1.629-2.617a.5.5 0 01-.075-.264V3.972a2.34 2.34 0 00-2.213-2.444H4.2a2.34 2.34 0 00-2.212 2.444v12.36a.5.5 0 01-.073.26L.367 19.136a1.38 1.38 0 00-.35 1.058c.171 1.982 1.455 2.278 2.206 2.278h19.554c.751 0 2.036-.3 2.2-2.277a1.3 1.3 0 00-.285-.985zm-8.454.262a.75.75 0 01-.75.75h-5a.75.75 0 010-1.5h5a.75.75 0 01.75.75zM3.988 4.528a1 1 0 011-1h14a1 1 0 011 1v9.944a1 1 0 01-1 1h-14a1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});