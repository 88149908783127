define("ember-svg-jar/inlined/phone-action-refresh-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-refresh-1</title><path d=\"M12 13.577V18.5a1 1 0 01-1 1H4a1 1 0 01-1-1v-11a1 1 0 011-1h3.776a.245.245 0 00.249-.243 8.977 8.977 0 01.225-1.94A.249.249 0 008.012 4H3.5A2.5 2.5 0 001 6.5v15A2.5 2.5 0 003.5 24h8a2.5 2.5 0 002.5-2.5v-7.325a.244.244 0 00-.178-.233 7.946 7.946 0 01-1.46-.58.244.244 0 00-.362.215z\"/><path d=\"M16.146 0a6.375 6.375 0 104.983 10.352A1 1 0 1019.566 9.1a4.375 4.375 0 11-.3-5.8L17.69 4.875a.5.5 0 00.353.854H22.5a.5.5 0 00.5-.5V.771a.5.5 0 00-.854-.353l-1.47 1.471A6.323 6.323 0 0016.146 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});