define("ember-svg-jar/inlined/smiley-scared-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-scared-alternate</title><path d=\"M15.75 12.25h-7.5a3.5 3.5 0 000 7h7.5a3.5 3.5 0 000-7zm-3 2.25a.25.25 0 01.25-.25h1a.25.25 0 01.25.25V17a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25zm-1.5 2.5a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h1a.25.25 0 01.25.25zm-4.5-1.25a1.5 1.5 0 011.2-1.47.251.251 0 01.3.245v2.45a.251.251 0 01-.3.245 1.5 1.5 0 01-1.2-1.47zm9.3 1.47a.251.251 0 01-.3-.245v-2.45a.251.251 0 01.3-.245 1.5 1.5 0 010 2.94z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><circle cx=\"8\" cy=\"8.5\" r=\"2\"/><circle cx=\"16\" cy=\"8.5\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});