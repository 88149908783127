define("ember-svg-jar/inlined/controls-previous", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>controls-previous</title><path d=\"M21.5 1.385L11 10.863a1.541 1.541 0 000 2.273l10.5 9.479a1.5 1.5 0 002.5-1.136V2.521a1.5 1.5 0 00-2.5-1.136z\"/><rect width=\"7\" height=\"24\" rx=\"1.5\" ry=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});