define("ember-svg-jar/inlined/gardening-boots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gardening-boots</title><path d=\"M20.5 20h-4.884a3.485 3.485 0 00-1.378.282L11.869 21.3a2.484 2.484 0 01-.985.2H3.5a.5.5 0 00-.5.5v.5A1.5 1.5 0 004.5 24h7.384a3.476 3.476 0 001.379-.283l1.9-.813a.25.25 0 01.347.2 1 1 0 00.99.896h3a1.5 1.5 0 001.5-1.5v-2a.5.5 0 00-.5-.5zM20.366.331a1.616 1.616 0 00-1.4-.274l-3.541.966a1.506 1.506 0 01-.826-.011l-3.173-.95A1.466 1.466 0 009.54 1.516L10 14.821A1.488 1.488 0 018.749 16.3l-3.688.412A2.5 2.5 0 003 19.175v.325a.5.5 0 00.5.5h7.384a3.476 3.476 0 001.379-.283l2.368-1.017a2.491 2.491 0 01.985-.2H20.5a.5.5 0 00.5-.5V1.614a1.615 1.615 0 00-.634-1.283zm-2.744 7.755L16.207 9.5a1 1 0 01-1.414 0l-1.414-1.414a1 1 0 010-1.414l1.414-1.415a1.025 1.025 0 011.415 0l1.414 1.414a1 1 0 010 1.415z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});