define("ember-svg-jar/inlined/video-game-pacman-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-pacman-1</title><circle cx=\"13.534\" cy=\"2.021\" r=\".75\"/><circle cx=\"16.75\" cy=\"1.25\" r=\"1.25\"/><circle cx=\"10.75\" cy=\"14.25\" r=\".75\"/><path d=\"M14.833 7.483a.5.5 0 01-.333-.47V6.5A.5.5 0 0115 6h1a1 1 0 000-2H8a1 1 0 000 2h1a.5.5 0 01.5.5v.513a.5.5 0 01-.333.47 8.5 8.5 0 105.666 0zm-.024 12.07a4.5 4.5 0 110-7.107.5.5 0 01.046.748l-2.094 2.094a1.006 1.006 0 000 1.419l2.1 2.1a.5.5 0 01-.045.747z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});