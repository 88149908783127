define("ember-svg-jar/inlined/smiley-disapointed-mad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-disapointed-mad</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM9.8 6.4a1 1 0 01-1.6 1.2 1.544 1.544 0 00-2.4 0 1 1 0 01-1.6-1.2 3.5 3.5 0 015.6 0zM7 9a1.5 1.5 0 11-1.5 1.5A1.5 1.5 0 017 9zm-.448 7.954a6.5 6.5 0 0110.9 0 1 1 0 01-1.676 1.092 4.5 4.5 0 00-7.544 0 1 1 0 11-1.676-1.092zM15.5 10.5A1.5 1.5 0 1117 12a1.5 1.5 0 01-1.5-1.5zm2.7-2.9a1.544 1.544 0 00-2.4 0 1 1 0 11-1.6-1.2 3.5 3.5 0 015.6 0 1 1 0 01-1.6 1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});