define("ember-svg-jar/inlined/landmark-buckingham-palace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-buckingham-palace</title><rect y=\"16\" width=\"2\" height=\"8\" rx=\".5\" ry=\".5\"/><rect x=\"22\" y=\"16\" width=\"2\" height=\"8\" rx=\".5\" ry=\".5\"/><path d=\"M15.5 12.5h-.75a.25.25 0 01-.25-.25V10.5a.5.5 0 00-.026-.158l-.961-2.885a.234.234 0 01-.013-.079V5a.5.5 0 00-.036-.186L13.018 3.7A.244.244 0 0113 3.605V1a1 1 0 00-2 0v2.605a.244.244 0 01-.018.093l-.446 1.116A.5.5 0 0010.5 5v2.378a.234.234 0 01-.013.079l-.961 2.885a.5.5 0 00-.026.158v1.75a.25.25 0 01-.25.25H8.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm-3-2a.5.5 0 01-1 0v-1a.5.5 0 011 0zM15.5 16h-7a.5.5 0 00-.5.5v7a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-7a.5.5 0 00-.5-.5zM10 19.5a.5.5 0 01-1 0v-1a.5.5 0 011 0zm2.5 0a.5.5 0 01-1 0v-1a.5.5 0 011 0zm2.5 0a.5.5 0 01-1 0v-1a.5.5 0 011 0zM20.5 11.5a.5.5 0 00-.5.5.121.121 0 01-.207.086l-.439-.44a.5.5 0 00-.708 0l-.439.44A.121.121 0 0118 12a.5.5 0 00-1 0v11.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V12a.5.5 0 00-.5-.5zm-1 4a.5.5 0 01-1 0v-1a.5.5 0 011 0zM6.5 11.5a.5.5 0 00-.5.5.121.121 0 01-.207.086l-.439-.44a.5.5 0 00-.708 0l-.439.44A.121.121 0 014 12a.5.5 0 00-1 0v11.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V12a.5.5 0 00-.5-.5zm-1 4a.5.5 0 01-1 0v-1a.5.5 0 011 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});