define("ember-svg-jar/inlined/house-fire-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>house-fire-2</title><path d=\"M23.054 6.849a.749.749 0 00-1.336-.19 2.44 2.44 0 01-.2.26.25.25 0 01-.435-.14A9.964 9.964 0 0016.994.148a.75.75 0 00-1.178.77 1.977 1.977 0 01-.755 2.037A10.371 10.371 0 0013.4 4.49a.251.251 0 01-.388-.014A3.57 3.57 0 0010.9 3.169a.751.751 0 00-.871.976 2.252 2.252 0 01-.238 1.389 9.333 9.333 0 00-.283.892.5.5 0 00.39.618 1.935 1.935 0 011.062.592c1.187 1.268 6.474 6.88 7.714 8.2a.5.5 0 00.57.113 7.176 7.176 0 003.442-2.984 7.958 7.958 0 00.368-6.116zM10.229 8.316a1.035 1.035 0 00-1.459 0l-8 8.5A1 1 0 001.5 18.5H3a.5.5 0 01.5.5v4a1 1 0 001 1h10a1 1 0 001-1v-4a.5.5 0 01.5-.5h1.5a1 1 0 00.727-1.687s-6.648-7.054-7.998-8.497zM11 16a.5.5 0 01-.5.5h-2A.5.5 0 018 16v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});