define("ember-svg-jar/inlined/dog-mouth-protection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dog-mouth-protection</title><path d=\"M23.388 9.553A6.2 6.2 0 0021.3 8.179a1.064 1.064 0 01-.67-.826 7.794 7.794 0 00-4.6-5.785 1 1 0 00-1.43 1.094l.749 3.745a13.424 13.424 0 00-4.4 1.9.9.9 0 00-.217.208.5.5 0 00-.052.515l5.1 10.757a.407.407 0 00.315.229 17.4 17.4 0 016.041 1.958l.816.489a.5.5 0 00.757-.427l.045-11.912a.5.5 0 00-.147-.356zm-8.794 1.362a.75.75 0 11.75-.75.749.749 0 01-.75.75zM9.59 9.988a1 1 0 00-1.037-.564l-5.747.776a2.985 2.985 0 00-2.547 3.205c.453 6.208 2.843 7.51 4.769 7.51h8.158a1 1 0 00.9-1.427zm-7.336 3.27a.984.984 0 01.814-1.079l.9-.121a.246.246 0 01.2.06.25.25 0 01.085.188v1.359a.25.25 0 01-.25.25H2.539a.25.25 0 01-.248-.226 14.23 14.23 0 01-.037-.431zM6.5 18.915a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25zm.967-7.327a.249.249 0 01.283.247v1.83a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25V11.97a.251.251 0 01.217-.248zM4 15.915a.25.25 0 01.25.25v2.054a.25.25 0 01-.423.18 4.815 4.815 0 01-1.1-2.168.251.251 0 01.242-.316zm6 3a.25.25 0 01-.25-.25V16.11a.25.25 0 01.476-.11l1.211 2.555a.25.25 0 01-.226.357z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});