define("ember-svg-jar/inlined/office-file-key-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-file-key-1</title><path d=\"M23.707 5.705L18.293.291A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.748V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.249A.249.249 0 016 21.749v-.5A.251.251 0 005.749 21H4.25a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.412a1 1 0 00-.293-.707z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zM3.061 14.675l2.381 2.382a.625.625 0 01-.884.883l-1.806-1.8c-.04-.04-.127-.028-.127.075V17.5a.625.625 0 01-1.25 0v-6a.625.625 0 011.25 0v1.241c0 .114.12.129.184.066l1.749-1.748a.625.625 0 01.884.883l-2.381 2.379a.25.25 0 000 .354zm4.815-.8H9a.625.625 0 010 1.25H7.873a.248.248 0 00-.248.248V16.5a.375.375 0 00.375.373h2a.625.625 0 010 1.25H8A1.626 1.626 0 016.375 16.5v-4A1.627 1.627 0 018 10.873h2a.625.625 0 010 1.25H8a.375.375 0 00-.375.375v1.124a.251.251 0 00.251.251zm8.749-1.375a2.622 2.622 0 01-1.825 2.489.247.247 0 00-.177.233V17.5a.625.625 0 01-1.25 0v-2.278a.247.247 0 00-.177-.233 2.622 2.622 0 01-1.821-2.489v-1a.625.625 0 111.25 0v1a1.375 1.375 0 002.75 0v-1a.625.625 0 111.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});