define("ember-svg-jar/inlined/player-phone-station", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>player-phone-station</title><path d=\"M19 14H5a5 5 0 000 10h14a5 5 0 000-10zm0 7.75A2.75 2.75 0 1121.75 19 2.75 2.75 0 0119 21.75zm-14-5.5A2.75 2.75 0 112.25 19 2.75 2.75 0 015 16.25zM7 12.5h1.25a.25.25 0 00.25-.25V4.5A.5.5 0 019 4h6a.5.5 0 01.5.5v7.75a.25.25 0 00.25.25H17a.5.5 0 00.5-.5V2.5A2.5 2.5 0 0015 0H9a2.5 2.5 0 00-2.5 2.5V12a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});