define("ember-svg-jar/inlined/database-key-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-key-alternate</title><path d=\"M23.249 11.494a.5.5 0 00-.5-.5h-2a.5.5 0 00-.343.137l-4.239 4a.25.25 0 01-.245.057 4.5 4.5 0 103 3.012.252.252 0 01.063-.249l4.115-4.103a.5.5 0 00.147-.354zm-9.716 9.084a1.752 1.752 0 010-2.474 1.793 1.793 0 012.475 0 1.75 1.75 0 11-2.475 2.474z\"/><path d=\"M11.252.011C6.167.011.764 1.759.753 5v12.76c0 2.5 3.213 4.115 7.016 4.723a.994.994 0 001.1-1.291l-.006-.021a.966.966 0 00-.794-.655c-3.319-.527-5.317-1.806-5.317-2.756v-.788a.25.25 0 01.373-.218 14.432 14.432 0 004.758 1.5.994.994 0 00.283-1.967c-3.379-.517-5.414-1.814-5.414-2.773v-.788a.25.25 0 01.373-.218 17.854 17.854 0 008.5 1.753.318.318 0 00.118-.027 5.956 5.956 0 012.867-.734 6.069 6.069 0 01.9.066.251.251 0 00.209-.066l1.639-1.548a.25.25 0 00.078-.182v-.181a.247.247 0 00-.106-.2.252.252 0 00-.229-.031 17.4 17.4 0 01-5.846.909c-5.189 0-8.5-1.776-8.5-3v-.795a.25.25 0 01.373-.218 17.574 17.574 0 008.128 1.757 17.588 17.588 0 008.127-1.756.25.25 0 01.372.218v.018a1 1 0 001 1 1 1 0 001-1v-3.48c-.003-3.248-5.413-5-10.503-5zm8.5 5v.006c-.011 1.223-3.318 2.994-8.5 2.994s-8.5-1.776-8.5-3 3.311-3 8.5-3 8.495 1.774 8.5 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});