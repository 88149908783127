define("ember-svg-jar/inlined/move-bottom-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>move-bottom-left</title><path d=\"M0 22a2 2 0 002 2h15a1 1 0 001-1v-1.5a1 1 0 00-1-1H3.75a.25.25 0 01-.25-.25V7a1 1 0 00-1-1H1a1 1 0 00-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});