define("ember-svg-jar/inlined/animal-print-bird-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>animal-print-bird-2</title><path d=\"M12.254 6.605a1.5 1.5 0 00-2.122-2.122l-1.91 1.911V2.515a1.5 1.5 0 10-3 0v3.879L3.344 4.515a1.5 1.5 0 00-2.122 2.121l4 4v3.879a1.5 1.5 0 103 0v-3.879zM22.316 16.121a1.5 1.5 0 10-1.207-2.747l-2.472 1.088 1.407-3.615a1.5 1.5 0 10-2.8-1.088l-1.406 3.613-1.07-2.431a1.5 1.5 0 00-2.747 1.207l2.279 5.178-1.408 3.615a1.5 1.5 0 002.8 1.088l1.408-3.614z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});