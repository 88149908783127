define("ember-svg-jar/inlined/hotel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hotel</title><path d=\"M21.48 17h-19a.5.5 0 00-.5.5V23a1 1 0 001 1h6a.5.5 0 00.5-.5v-3a1 1 0 011-1h3a1 1 0 011 1v3a.5.5 0 00.5.5h6a1 1 0 001-1v-5.5a.5.5 0 00-.5-.5zM23.229 6.5h-.361a.5.5 0 01-.447-.275L20.569 2.55a1.013 1.013 0 00-.9-.55h-.691a.5.5 0 01-.5-.5v-.25A1.25 1.25 0 0017.229 0h-2.5a1.25 1.25 0 00-1.25 1.25v.25a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-.25A1.25 1.25 0 009.229 0h-2.5a1.25 1.25 0 00-1.25 1.25v.25a.5.5 0 01-.5.5h-.69a1 1 0 00-.9.55L1.557 6.223a.5.5 0 01-.447.277H.771a.75.75 0 000 1.5h22.458a.75.75 0 000-1.5zm-14.25-2a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V2a.5.5 0 01.5-.5h1a.5.5 0 01.5.5zm8 0a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V2a.5.5 0 01.5-.5h1a.5.5 0 01.5.5zM21.48 9.5h-19a.5.5 0 00-.5.5v5a.5.5 0 00.5.5h19a.5.5 0 00.5-.5v-5a.5.5 0 00-.5-.5zm-13 4a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm5 0a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm5 0a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});