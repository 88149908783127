define("ember-svg-jar/inlined/smart-house-timer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-house-timer</title><path d=\"M8.539 10.439l-.5 2a.251.251 0 00.045.215.248.248 0 00.2.1H9.5a.25.25 0 00.25-.25v-2a.249.249 0 00-.25-.25h-.72a.251.251 0 00-.241.185z\"/><path d=\"M12-.023A12.023 12.023 0 1024.022 12 12.036 12.036 0 0012-.023zm7.619 6.593a.85.85 0 011.152.349 9.888 9.888 0 01.981 2.745.851.851 0 01-1.67.329 8.189 8.189 0 00-.812-2.271.851.851 0 01.349-1.152zm-8.368 8.93a.75.75 0 01-1.5 0v-1a.25.25 0 00-.25-.25H7a.751.751 0 01-.728-.932l.811-3.242a1.748 1.748 0 011.7-1.326H10.5a.75.75 0 01.75.75zM15 11.749a2.25 2.25 0 110 4.5h-1.5a.75.75 0 110-1.5H15a.75.75 0 000-1.5h-.813a1.439 1.439 0 01-1.437-1.438V9.5a.75.75 0 01.75-.75h3a.75.75 0 010 1.5h-2a.25.25 0 00-.25.25v1.04a.25.25 0 00.271.249zm3.262-6.22a.851.851 0 01-1.2.148A8.23 8.23 0 006.036 17.829.851.851 0 114.8 19 9.932 9.932 0 0118.115 4.334a.852.852 0 01.148 1.195zm1.4 12.4c-.141.171-.288.337-.439.5a.851.851 0 01-1.239-1.168c.126-.133.248-.271.366-.412a.851.851 0 111.312 1.083zm1.792-3.255a.851.851 0 11-1.617-.53 8.176 8.176 0 00.254-.991.851.851 0 111.672.317 9.678 9.678 0 01-.307 1.203z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});