define("ember-svg-jar/inlined/mobile-phone-blackberry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mobile-phone-blackberry</title><path d=\"M16.5 3H9V1.5a1.5 1.5 0 00-3 0v2.013A2.488 2.488 0 005 5.5v16A2.5 2.5 0 007.5 24h9a2.5 2.5 0 002.5-2.5v-16A2.5 2.5 0 0016.5 3zm-1 16a1 1 0 11-1 1 1 1 0 011-1zm-1-2.25a1 1 0 111 1 1 1 0 01-1-1zM17 12a1 1 0 01-1 1H8a1 1 0 01-1-1V6a1 1 0 011-1h8a1 1 0 011 1zm-5 5.75a1 1 0 111-1 1 1 0 01-1 1zM13 20a1 1 0 11-1-1 1 1 0 011 1zm-4.5-2.25a1 1 0 111-1 1 1 0 01-1 1zm1 2.25a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});