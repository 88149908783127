define("ember-svg-jar/inlined/design-file-pyramid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-pyramid</title><path d=\"M22 6.329a2 2 0 00-.586-1.415L17.086.586A2 2 0 0015.672 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2zm-10.133.735a.249.249 0 01.458 0l3.661 8.54a.245.245 0 01-.317.328 9.447 9.447 0 00-3.625-.648 9.438 9.438 0 00-3.617.645.245.245 0 01-.316-.33zm.177 12.706c-2.6 0-4.544-.92-4.544-1.743s1.943-1.741 4.544-1.741 4.544.919 4.544 1.741-1.943 1.743-4.544 1.743z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});