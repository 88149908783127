define("ember-svg-jar/inlined/accessories-umbrella-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accessories-umbrella-1</title><path d=\"M11.913 7.573a.5.5 0 00-.542-.1c-5.109 2.2-8.534 8.311-9.546 12.12a2.007 2.007 0 01-.48.861L.274 21.587a1 1 0 001.452 1.375l1.074-1.13a1.983 1.983 0 01.834-.525c3.745-1.215 9.666-4.964 11.586-10.184a.5.5 0 00-.126-.536zM23.137 1.482a2.771 2.771 0 00-3.912.106l-2.738 2.889a.25.25 0 01-.353.01L14.7 3.132a.516.516 0 00-.771.018 5.294 5.294 0 00-1.2 3.059 1 1 0 00.312.705l2.65 2.511a1.006 1.006 0 00.72.274A5.3 5.3 0 0019.4 8.332a.516.516 0 00-.023-.771l-1.43-1.355a.25.25 0 01-.009-.353l2.737-2.889a.768.768 0 111.117 1.056l-.469.494a1 1 0 101.45 1.377l.471-.5a2.774 2.774 0 00-.107-3.909z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});