define("ember-svg-jar/inlined/people-man-9", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-9</title><circle cx=\"14.034\" cy=\"9\" r=\"1\"/><circle cx=\"10.034\" cy=\"9\" r=\"1\"/><path d=\"M12.034 16a7.008 7.008 0 007-7V7a7 7 0 10-14 0v2a7.008 7.008 0 007 7zm-5-9v-.016a.1.1 0 01.079-.084A5.385 5.385 0 0010.3 5.211a.255.255 0 01.185-.081.249.249 0 01.184.082C12.6 7.316 15.2 7 16.485 7h.3a.25.25 0 01.25.25V9a5 5 0 11-10 0z\"/><path d=\"M10.418 11.485a.626.626 0 00.311.7 2.281 2.281 0 00.426.176 2.945 2.945 0 00.849.124h.006a2.9 2.9 0 00.846-.123 2.293 2.293 0 00.427-.177.626.626 0 00-.3-1.177h-1.96a.626.626 0 00-.605.477zM22.318 19.858C20.92 18.791 17.72 17 12 17s-8.92 1.791-10.318 2.857a2.412 2.412 0 00-.947 1.937v1.956a.25.25 0 00.25.25h8.947a.251.251 0 00.24-.181l1.357-4.749a.49.49 0 01.942 0l1.357 4.749a.251.251 0 00.24.181h8.947a.25.25 0 00.25-.25v-1.956a2.408 2.408 0 00-.947-1.936z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});