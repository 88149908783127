define("ember-svg-jar/inlined/warehouse-cart-worker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>warehouse-cart-worker</title><circle cx=\"5.5\" cy=\"21.57\" r=\"1.75\"/><path d=\"M13 7.07a1 1 0 00-1-1h-.7a2 2 0 00-1.95 1.557l-1.971 8.665a1 1 0 01-.975.778H1a1 1 0 000 2h6.2a2 2 0 001.95-1.557l2.1-9.248a.249.249 0 01.243-.2H12a1 1 0 001-.995z\"/><rect x=\"1\" y=\"9.07\" width=\"5\" height=\"7\" rx=\".5\" ry=\".5\"/><path d=\"M21.3 8.427a.25.25 0 01-.011-.437 4.249 4.249 0 002.106-3.665A4.348 4.348 0 0019.136.07a4.3 4.3 0 00-3.8 2.362.249.249 0 01-.223.138H13.5a1 1 0 100 2h1.176a.25.25 0 01.248.223 4.236 4.236 0 001.948 3.116.251.251 0 01-.028.438 5.006 5.006 0 00-2.893 4.526v3.018a.5.5 0 00.5.5h1.584a.251.251 0 01.249.225l.687 6.864a.5.5 0 00.5.451h3.015a.5.5 0 00.5-.451l.688-6.864a.251.251 0 01.249-.225H23.5a.5.5 0 00.5-.5v-3.018a5.019 5.019 0 00-2.7-4.446zm-.233-3.857a.251.251 0 01.242.312 2.24 2.24 0 01-4.347 0 .251.251 0 01.238-.312z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});