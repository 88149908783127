define("ember-svg-jar/inlined/aircraft-propeller-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>aircraft-propeller-2</title><path d=\"M23.5 1.125h-.79a.469.469 0 00-.35.15l-2.787 2.778a.252.252 0 01-.176.072h-5.226a.248.248 0 01-.235-.166 4.966 4.966 0 00-9.442.187.246.246 0 01-.149.159 2.392 2.392 0 00-1.32 1.183.251.251 0 01-.223.137H1.247a1 1 0 000 2H2.8a.252.252 0 01.223.136 2.5 2.5 0 002.224 1.364h6.283a.253.253 0 01.181.078l2.782 2.922a3.719 3.719 0 002.56 1.01h1.69a.5.5 0 00.43-.24.484.484 0 00.02-.48L17.47 9.5a.251.251 0 01.216-.378H19a5 5 0 005-5v-2.5a.5.5 0 00-.5-.497zM11.578 3.982a.25.25 0 01-.226.143H7.143a.249.249 0 01-.193-.409 2.965 2.965 0 014.6 0 .252.252 0 01.028.266z\"/><path d=\"M1 5.125a1 1 0 001-1v-2a1 1 0 00-2 0v2a1 1 0 001 1zM1 12.125a1 1 0 001-1v-2a1 1 0 00-2 0v2a1 1 0 001 1zM10.1 17.628a4.212 4.212 0 00-3.6-2 4.264 4.264 0 00-4.077 3.102A3.253 3.253 0 000 21.875a1.5 1.5 0 001.5 1.5H13a1.5 1.5 0 001.5-1.5 4.265 4.265 0 00-4.4-4.247zm2.21 3.637a.249.249 0 01-.207.11H2.527a.226.226 0 01-.159-.386 1.22 1.22 0 01.887-.364c.017-.006.1 0 .141-.009a1 1 0 00.876-.915 2.236 2.236 0 014.32-.635 1.01 1.01 0 001.057.631.835.835 0 00.1-.017 2.319 2.319 0 01.5-.055 2.252 2.252 0 012.085 1.406.247.247 0 01-.023.234z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});