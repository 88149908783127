define("ember-svg-jar/inlined/playlist-songs-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>playlist-songs-1</title><path d=\"M18 0a1 1 0 00-1 1v15.127a.251.251 0 01-.353.228A4 4 0 1015 24a4.1 4.1 0 004-4V2.465a.251.251 0 01.334-.236A4.006 4.006 0 0122 6a3.923 3.923 0 01-.585 2.062 1 1 0 001.7 1.049A5.92 5.92 0 0024 6a6.006 6.006 0 00-6-6zM1 4h11a1 1 0 000-2H1a1 1 0 000 2zM1 9h11a1 1 0 000-2H1a1 1 0 000 2z\"/><path d=\"M13 13a1 1 0 00-1-1H1a1 1 0 000 2h11a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});