define("ember-svg-jar/inlined/shopping-cart-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-cart-star</title><path d=\"M11.656 13H8.238A.25.25 0 018 12.808l-1.15-5a.25.25 0 01.238-.308h13.19a.5.5 0 01.474.658L20 10.406a1 1 0 101.9.633l1-3A1.928 1.928 0 0021.07 5.5H6.514a.25.25 0 01-.244-.194l-.878-3.819A1.921 1.921 0 003.514 0H1a1 1 0 000 2l2.24-.055a.25.25 0 01.25.194l3.357 14.6a.251.251 0 01-.107.265 2.25 2.25 0 102.285-.1.255.255 0 01-.125-.165l-.331-1.437A.25.25 0 018.813 15h2.843a1 1 0 000-2z\"/><path d=\"M23.666 18.194a1.068 1.068 0 00.254-1.165 1.043 1.043 0 00-.989-.644H20.84a.251.251 0 01-.229-.151l-1.127-2.614-.011-.023a1.094 1.094 0 00-1.961.023l-1.128 2.614a.252.252 0 01-.23.151h-2.091a1.039 1.039 0 00-.986.642 1.075 1.075 0 00.27 1.182l1.835 1.636a.249.249 0 01.063.286l-1.023 2.355a1.06 1.06 0 00.266 1.228 1.079 1.079 0 001.274.14l2.613-1.472a.256.256 0 01.246 0l2.614 1.472a1.079 1.079 0 001.274-.14 1.06 1.06 0 00.267-1.227l-1.024-2.356a.249.249 0 01.063-.286z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});