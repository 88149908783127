define("ember-svg-jar/inlined/wifi-monitor-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-monitor-1</title><circle cx=\"12\" cy=\"9.633\" r=\"1.75\"/><path d=\"M18 4.006a1 1 0 00.629-1.779 10.485 10.485 0 00-13.256 0 1 1 0 101.255 1.557 8.5 8.5 0 0110.744 0 1 1 0 00.628.222z\"/><path d=\"M16.278 6.749a1 1 0 00-.15-1.406 6.528 6.528 0 00-8.256 0A1 1 0 109.128 6.9a4.54 4.54 0 015.744 0 1 1 0 001.406-.151zM14.35 20.814a.5.5 0 00-.385-.181h-3.93a.5.5 0 00-.385.181l-2.1 2.532a.5.5 0 00.409.787h8.079a.5.5 0 00.41-.787z\"/><path d=\"M21 4.633h-1.75a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25H20a.5.5 0 01.5.5v8.5a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5v-8.5a.5.5 0 01.5-.5h.75a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25H3a1.5 1.5 0 00-1.5 1.5v12a1.5 1.5 0 001.5 1.5h18a1.5 1.5 0 001.5-1.5v-12a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});