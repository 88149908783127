define("ember-svg-jar/inlined/hotel-bedroom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hotel-bedroom</title><path d=\"M1.6 9.691L2 9.4V11a.5.5 0 00.5.5h19a.5.5 0 00.5-.5V9.4l.405.306A1 1 0 1023.6 8.1L13.176.388a1.974 1.974 0 00-2.352 0L.405 8.084A1 1 0 101.6 9.691z\"/><circle cx=\"6\" cy=\"15.5\" r=\"2.5\"/><path d=\"M11 13.5a1 1 0 00-1 1v3a.5.5 0 00.5.5h10a.5.5 0 00.5-.5V16a2.5 2.5 0 00-2.5-2.5z\"/><path d=\"M23 19H2v-5a1 1 0 00-2 0v9a1 1 0 002 0v-1.5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5V23a1 1 0 002 0v-3a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});