define("ember-svg-jar/inlined/toys-finger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toys-finger</title><path d=\"M16.562 15.5h-1.508a.251.251 0 01-.166-.438 1.746 1.746 0 00.593-1.311v-4.8a3.012 3.012 0 01.134-.889l1.6-5.146a2.25 2.25 0 00-4.3-1.332l-1.078 3.469a.635.635 0 01-1.213 0L9.55 1.585a2.25 2.25 0 00-4.3 1.332l1.596 5.147a3 3 0 01.135.888v4.8a1.752 1.752 0 001.127 1.635.5.5 0 01.322.468v.7a.25.25 0 01-.436.166l-.406-.453a2.95 2.95 0 00-4.432 3.9l2.929 3.483a1 1 0 001.532-1.287l-2.944-3.5A.951.951 0 016.1 17.6l2.588 2.893a1 1 0 001.745-.666V16a.5.5 0 01.5-.5h1.132a.5.5 0 01.5.5v.5a1 1 0 001 1h3a3 3 0 013 3V23a1 1 0 002 0v-2.5a5.005 5.005 0 00-5.003-5zm-2.9-4.429a2.5 2.5 0 01-4.843 0 .5.5 0 01.484-.625h3.874a.5.5 0 01.485.625z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});