define("ember-svg-jar/inlined/focus-frame-target-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>focus-frame-target-alternate</title><path d=\"M21.25 2H2.75A2.752 2.752 0 000 4.75v14.5A2.752 2.752 0 002.75 22h18.5A2.752 2.752 0 0024 19.25V4.75A2.752 2.752 0 0021.25 2zm1.25 17.25a1.252 1.252 0 01-1.25 1.25H2.75a1.252 1.252 0 01-1.25-1.25V4.75A1.252 1.252 0 012.75 3.5h18.5a1.252 1.252 0 011.25 1.25z\"/><path d=\"M3.75 9.5a.75.75 0 00.75-.75v-2a.25.25 0 01.25-.25h2a.75.75 0 000-1.5h-2A1.752 1.752 0 003 6.75v2a.75.75 0 00.75.75zM19.25 5h-2a.75.75 0 000 1.5h2a.25.25 0 01.25.25v2a.75.75 0 001.5 0v-2A1.752 1.752 0 0019.25 5zM6.75 17.5h-2a.25.25 0 01-.25-.25v-2a.75.75 0 00-1.5 0v2A1.752 1.752 0 004.75 19h2a.75.75 0 000-1.5zM20.25 14.5a.75.75 0 00-.75.75v2a.25.25 0 01-.25.25h-2a.75.75 0 000 1.5h2A1.752 1.752 0 0021 17.25v-2a.75.75 0 00-.75-.75zM16.654 12.95a.249.249 0 01.245-.2H18a.75.75 0 000-1.5h-1.1a.25.25 0 01-.245-.2 4.762 4.762 0 00-3.7-3.7.25.25 0 01-.2-.245V6a.75.75 0 00-1.5 0v1.1a.25.25 0 01-.2.245 4.762 4.762 0 00-3.7 3.7.25.25 0 01-.245.2H6a.75.75 0 000 1.5h1.1a.25.25 0 01.245.2 4.762 4.762 0 003.7 3.7.25.25 0 01.2.245V18a.75.75 0 001.5 0v-1.1a.25.25 0 01.2-.245 4.762 4.762 0 003.709-3.705zM12 15.25A3.25 3.25 0 1115.25 12 3.254 3.254 0 0112 15.25z\"/><circle cx=\"12\" cy=\"12\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});