define("ember-svg-jar/inlined/building-cloudy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>building-cloudy</title><rect x=\"1\" y=\"19.5\" width=\"5\" height=\"4.5\" rx=\".5\" ry=\".5\"/><path d=\"M5.5 14.5H1.834a1 1 0 00-.924.615l-.833 2A1 1 0 001 18.5h4.5A.5.5 0 006 18v-3a.5.5 0 00-.5-.5zM2.705 7.75h6.359a.773.773 0 00.1-.006 2.9 2.9 0 00.059-5.754A3.778 3.778 0 006.053.25a3.9 3.9 0 00-3.736 2.8A2.361 2.361 0 00.25 5.393 2.316 2.316 0 002.705 7.75zM23.5 0h-7a.5.5 0 00-.5.5v23a.5.5 0 00.5.5h7a.5.5 0 00.5-.5V.5a.5.5 0 00-.5-.5zm-4.25 17.5a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zm0-4.5a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zm0-4.5a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zm0-4.5a.75.75 0 01-1.5 0V3a.75.75 0 011.5 0zm3 13.5a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zm0-4.5a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zm0-4.5a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zm0-4.5a.75.75 0 01-1.5 0V3a.75.75 0 011.5 0zM11.354 10.146a.5.5 0 00-.707 0l-3.5 3.5A.5.5 0 007 14v9.5a.5.5 0 00.5.5h7a.5.5 0 00.5-.5V14a.5.5 0 00-.146-.354zM10.25 21a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zm0-4.5a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zm3 4.5a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zm0-4.5a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});