define("ember-svg-jar/inlined/real-estate-deal-shake-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-deal-shake-building</title><path d=\"M8.25 12.5h2a.5.5 0 00.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V3a.5.5 0 00-.235-.424l-4-2.5a.5.5 0 00-.53 0l-4 2.5A.5.5 0 007.75 3v9a.5.5 0 00.5.5zm2.5-9h3a.75.75 0 010 1.5h-3a.75.75 0 010-1.5zm0 3h3a.75.75 0 010 1.5h-3a.75.75 0 010-1.5zM19.75 14.748h-.681a.509.509 0 01-.145-.021l-3.595-1.085a2.441 2.441 0 00-1.889.106c-.154.075-1.135.548-1.636.8a.5.5 0 01-.5-.033 2.5 2.5 0 00-2.316-.067c-.536.149-1.406.39-2.81.774a.5.5 0 01-.459-.1 1.475 1.475 0 00-.97-.365h-1a1 1 0 00-1 1v5.5a1 1 0 001 1h1a1.489 1.489 0 001.163-.562.5.5 0 01.388-.195h1.341a.5.5 0 01.348.142l1.93 1.88a1.431 1.431 0 001.973.158l2.577-2.07a.5.5 0 01.313-.11h2.688a.5.5 0 01.434.252.971.971 0 00.846.5h1a1.5 1.5 0 001.5-1.5v-4.5a1.5 1.5 0 00-1.5-1.504zm-5.776 5.336L11.347 22.2a.5.5 0 01-.663-.031L8.6 20.14a.5.5 0 00-.35-.14h-1.5a.5.5 0 01-.5-.5v-2.367a.5.5 0 01.368-.482l3.527-.969a.385.385 0 01.379.442 1.945 1.945 0 002.246 2.193.5.5 0 01.456.156l.8.886a.5.5 0 01-.052.725zm3.776-.584a.5.5 0 01-.5.5h-.86a.5.5 0 01-.5-.431 1.532 1.532 0 00-.316-.734l-.867-.954a.1.1 0 01.044-.159.751.751 0 10-.444-1.434l-1.695.527a.45.45 0 01-.371-.815l1.861-.9a.871.871 0 01.756-.029l2.537.765a.5.5 0 01.355.479z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});