define("ember-svg-jar/inlined/phone-action-location-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-location-1</title><path d=\"M14.719 11v7.484a1 1 0 01-1 1h-7a1 1 0 01-1-1v-11a1 1 0 011-1h4.94a.25.25 0 00.234-.162l.75-2a.249.249 0 00-.234-.338h-6.19a2.5 2.5 0 00-2.5 2.5v15a2.5 2.5 0 002.5 2.5h8a2.5 2.5 0 002.5-2.5V9.961a.25.25 0 00-.393-.205l-1.5 1.038a.252.252 0 00-.107.206z\"/><path d=\"M16.687.308a.521.521 0 00-.936 0l-3.562 9.5a.5.5 0 00.788.56l3.242-2.7 3.242 2.7a.509.509 0 00.609.024.5.5 0 00.179-.584z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});