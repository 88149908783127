define("ember-svg-jar/inlined/like-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>like-chat</title><path d=\"M22.048 1H1.952A1.954 1.954 0 000 2.952v14.286a1.954 1.954 0 001.952 1.952h4.715V22a1 1 0 001.707.707l3.516-3.517h10.158A1.954 1.954 0 0024 17.238V2.952A1.954 1.954 0 0022.048 1zM22 16.69a.5.5 0 01-.5.5H11.476a1 1 0 00-.707.293l-2.1 2.1v-1.4a1 1 0 00-1-1l-5.169.051a.5.5 0 01-.505-.5L1.954 3.5a.5.5 0 01.5-.5H21.5a.5.5 0 01.5.5z\"/><path d=\"M6.452 14h1a.458.458 0 00.5-.4V8.4a.458.458 0 00-.5-.4h-1a.458.458 0 00-.5.4v5.2a.458.458 0 00.5.4zM9.246 13.34l2.354 1.067a3.012 3.012 0 001.2.247h2.677a1.345 1.345 0 001.277-.921l1.128-3.384a1.347 1.347 0 00-.182-1.214 1.727 1.727 0 00-1.248-.635h-1.5V5.75a1.25 1.25 0 10-2.5 0V6c0 1.59-1.43 2.154-2.654 2.154h-.346a.5.5 0 00-.5.5v4.231a.5.5 0 00.294.455z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});