define("ember-svg-jar/inlined/arrow-rectangle-down-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-rectangle-down-2</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-4 7.642v3.151a.5.5 0 01-.146.353l-6 6a.5.5 0 01-.706 0l-6-5.955a.5.5 0 01-.148-.355v-3.2a.5.5 0 01.839-.368l5.5 5.07a.25.25 0 00.339 0l5.488-5.068a.5.5 0 01.834.372z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});