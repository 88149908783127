define("ember-svg-jar/inlined/office-shredder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-shredder</title><path d=\"M23 7.248a3 3 0 00-3-3h-1A.25.25 0 0118.75 4v-.5a1 1 0 00-.293-.707l-2.5-2.5A1 1 0 0015.25 0h-8a2 2 0 00-2 2v2a.25.25 0 01-.25.25H4a3 3 0 00-3 3V22a2 2 0 002 2h18a2 2 0 002-2zm-6.25-.5A.25.25 0 0116.5 7h-9a.25.25 0 01-.25-.25V2.5a.5.5 0 01.5-.5h6.982a.249.249 0 01.177.073l1.768 1.768a.249.249 0 01.073.177zm1.5 1.75h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 010-1.5zM21 21.5a.5.5 0 01-.5.5h-17a.5.5 0 01-.5-.5v-9.75a.5.5 0 01.5-.5h17a.5.5 0 01.5.5z\"/><path d=\"M7.65 19.2a.75.75 0 001.05.15c1.482-1.111 1.368-4.734 1.3-5.895a.75.75 0 00-1.5.09c.119 2-.134 4.18-.7 4.605a.75.75 0 00-.15 1.05zM5.416 18.123c1.855-1.237 1.849-2.182 1.836-4.251v-.374a.75.75 0 10-1.5 0v.383c.012 2 .013 2.206-1.169 2.994a.75.75 0 00.832 1.248zM18.584 18.123a.75.75 0 10.832-1.248c-1.182-.789-1.181-1-1.169-2.992v-.385a.75.75 0 10-1.5 0v.376c-.012 2.068-.018 3.012 1.837 4.249zM15.3 19.348a.75.75 0 00.9-1.2c-.358-.269-.729-.608-.7-4.65a.75.75 0 10-1.5 0c-.039 5.265.772 5.455 1.3 5.85zM12 19.748a.75.75 0 00.75-.75V13.5a.75.75 0 10-1.5 0V19a.75.75 0 00.75.748z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});