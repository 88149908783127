define("ember-svg-jar/inlined/keyboard-asterisk-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-asterisk-1</title><path d=\"M.691 16.7a1.333 1.333 0 001.285 2.336l8.32-4.576a.251.251 0 01.371.219v7.989a1.333 1.333 0 102.666 0v-7.993a.251.251 0 01.371-.219l8.32 4.576a1.333 1.333 0 101.285-2.332l-8.144-4.479a.25.25 0 010-.439L23.309 7.3a1.333 1.333 0 10-1.285-2.336L13.7 9.539a.251.251 0 01-.371-.22V1.331a1.333 1.333 0 00-2.666 0v7.988a.251.251 0 01-.371.22L1.976 4.963A1.333 1.333 0 10.691 7.3l8.144 4.479a.25.25 0 010 .439z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});