define("ember-svg-jar/inlined/road-sign-cattle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-cattle</title><path d=\"M23.53 12.53a.749.749 0 000-1.06l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0zM9.949 17.082l-.39-3.061a.247.247 0 00-.108-.176.252.252 0 00-.2-.035 3.112 3.112 0 01-.78.112 1.53 1.53 0 01-1.411-.69.5.5 0 01.089-.586l1.9-1.9a.249.249 0 00.071-.208l-.02-.161a.249.249 0 00-.183-.209A2.252 2.252 0 017.25 8v-.5a.75.75 0 011.5 0V8a.751.751 0 00.75.75h.163a.251.251 0 00.128-.035 1.49 1.49 0 01.766-.215h2.886a1.49 1.49 0 01.766.215.251.251 0 00.128.035h.163a.751.751 0 00.75-.75v-.5a.75.75 0 011.5 0V8a2.252 2.252 0 01-1.664 2.17.248.248 0 00-.183.21l-.02.158a.246.246 0 00.071.208l1.9 1.9a.5.5 0 01.089.585 1.53 1.53 0 01-1.411.691 3.1 3.1 0 01-.779-.112.254.254 0 00-.2.035.251.251 0 00-.108.176l-.39 3.081a1.5 1.5 0 01-1.5 1.4h-1.109a1.5 1.5 0 01-1.497-1.42z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});