define("ember-svg-jar/inlined/color-brush-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-brush-3</title><path d=\"M23.6 2.745A1.66 1.66 0 0021.105.552L7.9 13.9a.251.251 0 00.1.413A5.116 5.116 0 0111.05 17a.249.249 0 00.417.056zM9.793 17.909a3.65 3.65 0 00-6.742-.2c-.191.426-.334.827-.467 1.2-.394 1.11-.574 1.617-1.578 1.875A1.339 1.339 0 00.781 23.3a7.521 7.521 0 003.138.7 7 7 0 002.47-.447A5.946 5.946 0 009.711 20.7a3.62 3.62 0 00.082-2.791z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});