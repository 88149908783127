define("ember-svg-jar/inlined/livestock-bull-footstep", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>livestock-bull-footstep</title><path d=\"M15.005 10.541a5.268 5.268 0 00-.041-.654c0-.007.682-.358.74-.392a4.648 4.648 0 001.775-1.484A4.44 4.44 0 0018 5.691c0-1.672-1.169-3.535-2.847-3.535h-1.244a3.994 3.994 0 01-.547-1.367 1 1 0 00-1.7-.477A6.115 6.115 0 0010 4.074 4.712 4.712 0 0010.4 6c-1.737.694-2.171.235-2.783-.021A4.739 4.739 0 008 4.074 6.115 6.115 0 006.34.312a1 1 0 00-1.7.477 3.994 3.994 0 01-.547 1.367H2.847C1.169 2.156 0 4.019 0 5.691a4.331 4.331 0 00.585 2.422A3.838 3.838 0 001.7 9.152c.113.074 1.29.676 1.283.717a4.472 4.472 0 00-.051.672.507.507 0 00.109.313l2.394 2.992.585 2.354a.489.489 0 00.113.213c.5.557 2.53.782 2.834.782s2.335-.226 2.833-.782a.489.489 0 00.113-.213l.589-2.354 2.398-2.992a.507.507 0 00.105-.313zM2 5.691c0-.777.536-1.535.847-1.535H4.23a1.919 1.919 0 001.584-.98 2.548 2.548 0 01.186.9c0 1.158-.6 1.783-.847 1.783A1 1 0 004.488 7.6l-.515.576a46.799 46.799 0 00-1.084-.656C2.315 7.188 2 7 2 5.691zm10.186-2.515a1.919 1.919 0 001.584.98h1.383c.311 0 .847.758.847 1.535 0 1.313-.315 1.5-.889 1.832 0 0-1.034.612-1.083.655l-.517-.578a1 1 0 00-.664-1.747C12.6 5.849 12 5.227 12 4.074a2.544 2.544 0 01.186-.898zm-1.963 7.064a1 1 0 111 1 1 1 0 01-1-1zm-3.513-1a1 1 0 11-1 1 1 1 0 011-1zM17.173 13.031a.494.494 0 00-.464.062C16.558 13.2 13 15.78 13 19c0 3.318.976 5 2.9 5 2.1 0 2.1-3.187 2.1-6a20.959 20.959 0 00-.515-4.621.5.5 0 00-.312-.348zM20.291 13.093a.5.5 0 00-.776.286A20.959 20.959 0 0019 18c0 2.813 0 6 2.1 6 1.924 0 2.9-1.682 2.9-5 0-3.22-3.558-5.8-3.709-5.907z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});