define("ember-svg-jar/inlined/console-drawers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>console-drawers</title><path d=\"M7 14a.5.5 0 00.5-.5v-10A.5.5 0 007 3H1.5A1.5 1.5 0 000 4.5V13a1 1 0 001 1zM5 5a1 1 0 11-1 1 1 1 0 011-1zM22.5 3h-6a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-1A1.5 1.5 0 0022.5 3z\"/><rect x=\"16\" y=\"7\" width=\"8\" height=\"3.5\" rx=\".5\" ry=\".5\"/><path d=\"M9 14h5.5a.5.5 0 00.5-.5v-10a.5.5 0 00-.5-.5H9a.5.5 0 00-.5.5v10a.5.5 0 00.5.5zm3.5-9a1 1 0 11-1 1 1 1 0 011-1zM23.5 11.5h-7a.5.5 0 00-.5.5v1.5a.5.5 0 00.5.5H23a1 1 0 001-1v-1a.5.5 0 00-.5-.5zM22.5 15h-21a1 1 0 000 2h.25a.5.5 0 01.5.5v2.25a1.25 1.25 0 002.5 0V17.5a.5.5 0 01.5-.5h13.5a.5.5 0 01.5.5v2.25a1.25 1.25 0 002.5 0V17.5a.5.5 0 01.5-.5h.25a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});