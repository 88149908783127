define("ember-svg-jar/inlined/pets-paw-soap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pets-paw-soap</title><path d=\"M18.281 8.75a2 2 0 00-2-2h-1.5c0-.062-.849-2.222-.849-2.222a1.28 1.28 0 00-.65-.648V2.5a.25.25 0 01.25-.25h2.25a1 1 0 000-2h-6a3.384 3.384 0 00-2.408 1.135L5.947 3.113A1 1 0 007.49 4.387l1.427-1.73a1.456 1.456 0 01.864-.407h1.25a.25.25 0 01.25.25v1.38a1.283 1.283 0 00-.65.648s-.848 2.16-.848 2.222h-1.5a2 2 0 00-2 2v13a2 2 0 002 2h8a2 2 0 002-2zm-5 3.5a1 1 0 11-1-1 1 1 0 011 1zm-5 2a1 1 0 111 1 1 1 0 01-1-1zm6.305 3.95a.982.982 0 01-.854.547h-2.927a.982.982 0 01-.854-.547 1 1 0 01.082-1.023l1.463-2a.958.958 0 011.544 0l1.46 2.014a.982.982 0 01.086 1.009zm.7-2.953a1 1 0 111-1 1 1 0 01-1.005 1.003z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});