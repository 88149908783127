define("ember-svg-jar/inlined/wedding-bride-groom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wedding-bride-groom</title><path d=\"M6.7 9.437a.25.25 0 01-.128-.208.253.253 0 01.109-.217 3.878 3.878 0 10-4.354 0 .25.25 0 01-.019.425A4.5 4.5 0 000 13.363v2.668a.5.5 0 00.5.5h1.085a.5.5 0 01.5.455l.585 6.559a.5.5 0 00.5.456h2.663a.5.5 0 00.5-.455l.586-6.56a.5.5 0 01.5-.455H8.5a.5.5 0 00.5-.5v-2.668a4.5 4.5 0 00-2.3-3.926zm-1.45 3.168a.75.75 0 01-1.5 0v-1.583a.75.75 0 011.5 0zM4.5 7.683a1.876 1.876 0 01-1.81-2.377.248.248 0 01.146-.165.253.253 0 01.221.016 4.756 4.756 0 002.4.648 4.926 4.926 0 00.623-.04.248.248 0 01.28.279A1.88 1.88 0 014.5 7.683zM17 0a7.008 7.008 0 00-7 7v7.5a2 2 0 002 2h.2l-.9 2.684a1 1 0 00.95 1.316h1.2a.5.5 0 01.5.429l.316 2.213a1 1 0 00.99.858h3.5a1 1 0 00.99-.858l.316-2.213a.5.5 0 01.495-.429h1.2a1 1 0 00.949-1.316L21.8 16.5h.2a2 2 0 002-2V7a7.008 7.008 0 00-7-7zm3.218 18.4a.25.25 0 01-.2.1h-.768a1 1 0 00-.99.858l-.346 2.427a.251.251 0 01-.248.215h-1.332a.249.249 0 01-.247-.215l-.347-2.427a1 1 0 00-.99-.858h-.765a.251.251 0 01-.238-.329l1.24-3.72a2.123 2.123 0 01.436-.749.249.249 0 01.4.043l.707 1.234a.538.538 0 00.934 0l.707-1.234a.249.249 0 01.4-.043 2.123 2.123 0 01.436.749l1.24 3.72a.251.251 0 01-.029.229zm-5.61-9.672a.248.248 0 01.178-.315 4.317 4.317 0 001.876-1.015.5.5 0 01.676 0 4.317 4.317 0 001.876 1.015.248.248 0 01.178.315 2.5 2.5 0 01-4.784 0zM22 14a.5.5 0 01-.977.158l-.113-.339a4.114 4.114 0 00-1.62-2.124.247.247 0 01-.111-.205.251.251 0 01.106-.208 4 4 0 10-4.57 0 .251.251 0 010 .413 4.114 4.114 0 00-1.62 2.124l-.113.339A.5.5 0 0112 14V7a5 5 0 0110 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});