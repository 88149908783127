define("ember-svg-jar/inlined/vr-video-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vr-video-1</title><path d=\"M13 12.5h-1.5a.5.5 0 00-.485.379s-.525 2.11-.793 3.2a.251.251 0 00.362.28A2.98 2.98 0 0112 16h10a2.98 2.98 0 011.416.356.25.25 0 00.362-.279c-.268-1.088-.793-3.2-.793-3.2a.5.5 0 00-.485-.377H21a.5.5 0 00-.354.146 2.892 2.892 0 01-2.06.854h-3.172a2.892 2.892 0 01-2.06-.854A.5.5 0 0013 12.5zM22 17H12a2 2 0 00-2 2v3a2 2 0 002 2h10a2 2 0 002-2v-3a2 2 0 00-2-2zm-8 5a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0114 22zm6 0a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0120 22zM3.731 5.8l2.475 1.063a.5.5 0 00.657-.657L5.8 3.731a.522.522 0 00-.1-.156L2.268.146a.5.5 0 00-.707 0L.146 1.56a.5.5 0 000 .708L3.575 5.7a.5.5 0 00.156.1z\"/><path d=\"M11.5 11.5H13a1.5 1.5 0 011.061.439 1.857 1.857 0 00.3.246.249.249 0 00.387-.208V7.54a.753.753 0 00-.486-.7L8.823 4.8A.75.75 0 108.3 6.2l3.651 1.369L8 9.189 2.284 6.846a.75.75 0 00-1.034.694V14a.75.75 0 00.513.711l6 2a.74.74 0 00.474 0l.737-.245a.251.251 0 00.164-.178c.165-.677.907-3.652.907-3.652A1.5 1.5 0 0111.5 11.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});