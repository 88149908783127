define("ember-svg-jar/inlined/car-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-key</title><path d=\"M7.249 21.992h-3a.5.5 0 00-.5.5v.5a1 1 0 001 1h2a1 1 0 001-1v-.5a.5.5 0 00-.5-.5zM21.254 21.992h-3a.5.5 0 00-.5.5v.5a1 1 0 001 1h2a1 1 0 001-1v-.5a.5.5 0 00-.5-.5zM22.324 16.778l-1.633-1.426a.249.249 0 01-.071-.106l-1.429-4.082a1.754 1.754 0 00-1.652-1.172H7.958a1.753 1.753 0 00-1.652 1.172l-1.429 4.082a.237.237 0 01-.071.105l-1.63 1.429a1.254 1.254 0 00-.425.939v2.023A1.251 1.251 0 004 20.992h17.5a1.25 1.25 0 001.25-1.25V17.72a1.252 1.252 0 00-.426-.942zm-3.532-1.891a.248.248 0 01-.2.105H6.909a.249.249 0 01-.2-.105.252.252 0 01-.033-.227l1.05-3a.251.251 0 01.236-.168h9.581a.251.251 0 01.236.168l1.05 3a.252.252 0 01-.037.227zM9.933 6.151a.249.249 0 01.232-.159H12.5a.249.249 0 01.25.25v.25a1.5 1.5 0 003 0v-.25a.25.25 0 01.25-.25h1a.249.249 0 01.25.25v.25a1.5 1.5 0 103 0v-.25a.25.25 0 01.25-.25h.25a1.5 1.5 0 000-3H10.175a.25.25 0 01-.233-.159 4.527 4.527 0 10-.009 3.318zM3.749 4.508a2 2 0 112 2 2 2 0 01-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});