define("ember-svg-jar/inlined/yoga-down-stretch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>yoga-down-stretch</title><path d=\"M7.278 9.535a.251.251 0 00.027-.24A2.5 2.5 0 104.86 12.73a.248.248 0 00.217-.1zM6.577 14.01a.5.5 0 00-.709.11l-.994 1.4a.5.5 0 01-.408.211H2a1.5 1.5 0 000 3h3.5a1.5 1.5 0 001.223-.631l1.558-2.2a.5.5 0 00-.106-.688zM22.832 18.482a1.5 1.5 0 00.416-2.08l-6.386-9.579A3.493 3.493 0 0014 5.266a3.586 3.586 0 00-2.9 1.476l-.213.3a.5.5 0 00.107.688l1.6 1.205a.5.5 0 00.709-.11l.248-.349a.473.473 0 01.376-.212.255.255 0 01.039 0 .5.5 0 01.408.221l6.386 9.579a1.5 1.5 0 002.08.416zM10.438 8.562a.5.5 0 00-.71.111L7.035 12.5a.5.5 0 00.107.686l1.584 1.2a.5.5 0 00.709-.11l2.706-3.819a.5.5 0 00-.106-.688z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});