define("ember-svg-jar/inlined/mailbox-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mailbox-house</title><path d=\"M10.715 5a.5.5 0 00.416-.223L14.057.389A.25.25 0 0013.849 0H5.25A1.525 1.525 0 004.1.439L.4 4.146A.5.5 0 00.75 5z\"/><path d=\"M12.75 4.977a.25.25 0 00-.458-.139l-.329.494A1.5 1.5 0 0110.715 6H1.5a.25.25 0 00-.25.25v4.25a1.5 1.5 0 001.5 1.5H9.5a.25.25 0 01.25.25V23.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5zM23.65 4.2L20.95.6a1.506 1.506 0 00-1.2-.6h-4.1a.253.253 0 00-.208.111l-1.65 2.477a.255.255 0 00-.042.139v9.023A.25.25 0 0014 12h5.75a1.5 1.5 0 001.5-1.5V3.417a.25.25 0 01.45-.15L22.85 4.8a.5.5 0 10.8-.6zm-7.4-.45h3a.75.75 0 010 1.5h-3a.75.75 0 010-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});