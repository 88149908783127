define("ember-svg-jar/inlined/paginate-filter-mail-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paginate-filter-mail-alternate</title><path d=\"M22 1.5H5.5a2 2 0 00-2 2V17a2 2 0 002 2H22a2 2 0 002-2V3.5a2 2 0 00-2-2zm-.5 2a.5.5 0 01.5.5v.2a.5.5 0 01-.223.416l-7.522 5.009a.5.5 0 01-.544.007L5.733 4.594a.5.5 0 01-.233-.423V4a.5.5 0 01.5-.5zM6 17a.5.5 0 01-.5-.5V7.719a.5.5 0 01.767-.419l7.2 4.546A1 1 0 0014 12a1.009 1.009 0 00.555-.168l6.668-4.446A.5.5 0 0122 7.8v8.7a.5.5 0 01-.5.5z\"/><path d=\"M19.5 20.5h-17A.5.5 0 012 20V6a1 1 0 00-2 0v14.5a2 2 0 002 2h17.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});