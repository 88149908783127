define("ember-svg-jar/inlined/seo-search-eye", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seo-search-eye</title><path d=\"M11.875 8.527a5.057 5.057 0 00-4.655 3.124.252.252 0 000 .2 5.033 5.033 0 009.312 0 .247.247 0 000-.2 5.019 5.019 0 00-4.657-3.124zm0 5.223a2 2 0 112-2 2 2 0 01-2 2z\"/><path d=\"M22.375 24a1.5 1.5 0 001.061-2.561l-4.362-4.361a.25.25 0 01-.028-.32 8.746 8.746 0 10-2.453 2.359.25.25 0 01.311.033l4.41 4.411a1.5 1.5 0 001.061.439zM18.09 11.976a6.543 6.543 0 01-12.43 0 .745.745 0 010-.453 6.543 6.543 0 0112.43 0 .739.739 0 010 .453z\"/><path d=\"M1.445 22.18a.746.746 0 00.737.191l3.01-.86a.248.248 0 01.207.032 11.733 11.733 0 0011.335.909.75.75 0 00-.621-1.366 10.23 10.23 0 01-10.232-1.034.75.75 0 00-.646-.114l-1.735.5a.252.252 0 01-.245-.064.249.249 0 01-.064-.246l.5-1.734a.751.751 0 00-.114-.646 10.246 10.246 0 1118.552-6 10.158 10.158 0 01-1.033 4.492.75.75 0 101.347.658 11.616 11.616 0 001.186-5.15 11.75 11.75 0 10-21.547 6.479.248.248 0 01.033.207l-.86 3.01a.75.75 0 00.19.736z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});