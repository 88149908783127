define("ember-svg-jar/inlined/smiley-unhappy-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-unhappy-1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm5.977 15.217a1 1 0 01-1.194.759 5.764 5.764 0 00-5.935 2.554 1 1 0 01-1.7-1.06 7.752 7.752 0 018.065-3.446 1 1 0 01.764 1.193zM8 7a2 2 0 11-2 2 2 2 0 012-2zm6 2a2 2 0 112 2 2 2 0 01-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});