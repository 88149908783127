define("ember-svg-jar/inlined/fitness-weights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fitness-weights</title><path d=\"M2 7.5H1a1 1 0 00-1 1v7a1 1 0 001 1h1a.5.5 0 00.5-.5V8a.5.5 0 00-.5-.5zM7 5.5H4.5a1 1 0 00-1 1v11a1 1 0 001 1H7a.5.5 0 00.5-.5V6a.5.5 0 00-.5-.5zM23 7.5h-1a.5.5 0 00-.5.5v8a.5.5 0 00.5.5h1a1 1 0 001-1v-7a1 1 0 00-1-1zM19.5 5.5H17a.5.5 0 00-.5.5v12a.5.5 0 00.5.5h2.5a1 1 0 001-1v-11a1 1 0 00-1-1z\"/><rect x=\"8.5\" y=\"10.5\" width=\"7\" height=\"3\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});