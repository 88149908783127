define("ember-svg-jar/inlined/email-action-search-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-search-1</title><path d=\"M9.069 16.636a.243.243 0 00.244-.236 6.86 6.86 0 016.858-6.6 6.76 6.76 0 012.125.348.245.245 0 00.322-.232V7.35a.245.245 0 00-.354-.219l-3.721 1.851a.716.716 0 01-.328.077l-9.787-.015a.765.765 0 01-.328-.078L.381 7.1a.245.245 0 00-.355.218l-.012 7.656a1.643 1.643 0 001.635 1.65z\"/><path d=\"M4.6 7.576l9.44.015 3.425-1.7a.248.248 0 00.135-.2.244.244 0 00-.1-.218L10.053.226a1.258 1.258 0 00-1.436 0L1.154 5.44a.245.245 0 00.03.419zM23.2 23.713a.979.979 0 000-1.384l-2.477-2.485a.243.243 0 01-.032-.3 5.392 5.392 0 10-1.658 1.653.244.244 0 01.3.033l2.476 2.485a.979.979 0 001.391-.002zm-7.034-10.491a3.426 3.426 0 11-3.431 3.42 3.43 3.43 0 013.431-3.42z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});