define("ember-svg-jar/inlined/common-file-module-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-module-1</title><path d=\"M23.414 3L21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v8.753a.247.247 0 00.242.25c.38 0 1.11.015 1.5.03A.246.246 0 008 10.784V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-8.115a.25.25 0 00-.232.34 8.783 8.783 0 01.318 1.449.246.246 0 00.247.21H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><rect x=\"7\" y=\"19\" width=\"5\" height=\"5\" rx=\"1\" ry=\"1\"/><rect y=\"19\" width=\"5\" height=\"5\" rx=\"1\" ry=\"1\"/><rect x=\"3.5\" y=\"12.5\" width=\"5\" height=\"5\" rx=\"1\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});