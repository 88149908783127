define("ember-svg-jar/inlined/multiple-actions-flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-flag</title><circle cx=\"14.388\" cy=\"3.473\" r=\"2.977\"/><path d=\"M14.388 7.442a4.5 4.5 0 00-3.566 1.743.249.249 0 00.011.316 5.612 5.612 0 011.587 2.757.249.249 0 00.24.187h.4a.252.252 0 00.2-.1c.658-.884 3.072-1.778 5.136-1.37a.248.248 0 00.287-.31 4.444 4.444 0 00-4.295-3.223zM10.915 14.078a5.458 5.458 0 10-10.915 0v3.472a.5.5 0 00.5.5h1.8a.247.247 0 01.247.227l.44 5.271a.5.5 0 00.486.452h3.969a.5.5 0 00.494-.455l.439-5.271a.248.248 0 01.248-.227h1.8a.5.5 0 00.5-.5z\"/><circle cx=\"5.457\" cy=\"3.473\" r=\"3.473\"/><path d=\"M23.771 13.034A.493.493 0 0023.3 13l-.865.4-.025.011a3.034 3.034 0 01-2.682-.372 4.074 4.074 0 00-3.571-.486l-1.259.427a.5.5 0 00-.337.47l.008 9.556a.993.993 0 001.985 0v-3.882a.247.247 0 01.189-.241 3.019 3.019 0 012.428.437 4.041 4.041 0 002.268.692 4.171 4.171 0 001.338-.222l.025-.01.914-.421a.5.5 0 00.288-.45v-5.457a.494.494 0 00-.233-.418z\"/><circle cx=\"14.388\" cy=\"3.473\" r=\"2.977\"/><path d=\"M14.388 7.442a4.5 4.5 0 00-3.566 1.743.249.249 0 00.011.316 5.612 5.612 0 011.587 2.757.249.249 0 00.24.187h.4a.252.252 0 00.2-.1c.658-.884 3.072-1.778 5.136-1.37a.248.248 0 00.287-.31 4.444 4.444 0 00-4.295-3.223zM10.915 14.078a5.458 5.458 0 10-10.915 0v3.472a.5.5 0 00.5.5h1.8a.247.247 0 01.247.227l.44 5.271a.5.5 0 00.486.452h3.969a.5.5 0 00.494-.455l.439-5.271a.248.248 0 01.248-.227h1.8a.5.5 0 00.5-.5z\"/><circle cx=\"5.457\" cy=\"3.473\" r=\"3.473\"/><path d=\"M23.771 13.034A.493.493 0 0023.3 13l-.865.4-.025.011a3.034 3.034 0 01-2.682-.372 4.074 4.074 0 00-3.571-.486l-1.259.427a.5.5 0 00-.337.47l.008 9.556a.993.993 0 001.985 0v-3.882a.247.247 0 01.189-.241 3.019 3.019 0 012.428.437 4.041 4.041 0 002.268.692 4.171 4.171 0 001.338-.222l.025-.01.914-.421a.5.5 0 00.288-.45v-5.457a.494.494 0 00-.233-.418z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});