define("ember-svg-jar/inlined/notes-diary-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-diary-alternate</title><path d=\"M20.25 0h-12a.75.75 0 00-.75.75v22.5a.75.75 0 00.75.75h12A1.752 1.752 0 0022 22.25V1.75A1.752 1.752 0 0020.25 0zM17.5 6.5v1A1.5 1.5 0 0116 9h-3.5A1.5 1.5 0 0111 7.5v-1A1.5 1.5 0 0112.5 5H16a1.5 1.5 0 011.5 1.5zM5.25 0h-1.5A1.752 1.752 0 002 1.75v20.5A1.752 1.752 0 003.75 24h1.5a.75.75 0 00.75-.75V.75A.75.75 0 005.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});