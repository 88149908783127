define("ember-svg-jar/inlined/credit-card-flag-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-flag-alternate</title><path d=\"M2.992 15a1 1 0 01-1-1V7.75a.25.25 0 01.25-.25h17.5a.25.25 0 01.25.25v4.15a.075.075 0 00.032.062 1.559 1.559 0 001.323.219.149.149 0 00.037-.014l.463-.213a.25.25 0 00.145-.227V3a3 3 0 00-3-3h-16a3 3 0 00-3 3v11a3 3 0 003 3h8.5a1 1 0 001-1 1 1 0 00-1-1zm0-13h16a1 1 0 011 1v1.25a.25.25 0 01-.25.25h-17.5a.25.25 0 01-.25-.25V3a1 1 0 011-1z\"/><path d=\"M7.992 11h-3a1 1 0 000 2h3a1 1 0 100-2zM14.5 23.25a.75.75 0 001.5 0v-3.766a.248.248 0 01.17-.236l.251-.085a3.059 3.059 0 012.717.37 4.064 4.064 0 002.285.7 4.2 4.2 0 001.349-.223L22.8 20l.92-.424a.5.5 0 00.291-.454v-5.5a.5.5 0 00-.709-.454l-.87.4-.027.011a3.049 3.049 0 01-2.7-.375 4.052 4.052 0 00-3.6-.49l-1.269.431a.5.5 0 00-.34.473z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});