define("ember-svg-jar/inlined/audio-book-blind-exam-f", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-book-blind-exam-f</title><path d=\"M10 6H7.5A2.5 2.5 0 005 8.5V15a1 1 0 002 0v-2.5a.5.5 0 01.5-.5h1a1 1 0 000-2h-1a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5H10a1 1 0 000-2zM23.712 18.942a7.683 7.683 0 00-6.079-3.3 7.688 7.688 0 00-6.079 3.3 1.49 1.49 0 000 1.763A7.683 7.683 0 0017.633 24a7.681 7.681 0 006.078-3.3 1.488 1.488 0 00.001-1.758zm-9.158 1.988a6.692 6.692 0 01-.855-.767.5.5 0 010-.683 5.317 5.317 0 014.069-1.831.25.25 0 01.171.424l-2.727 2.805a.5.5 0 01-.658.052zm7.014-.767A5.317 5.317 0 0117.5 22a.25.25 0 01-.171-.424l2.727-2.805a.5.5 0 01.658-.052 6.611 6.611 0 01.855.767.5.5 0 01-.001.677z\"/><path d=\"M14.707 5.123A1 1 0 0115 5.83v7.67a1 1 0 002 0V5.415A1.986 1.986 0 0016.414 4L13 .587A2.015 2.015 0 0011.586 0H2a2 2 0 00-2 2v18a2 2 0 002 2h7a1 1 0 000-2H2.5a.5.5 0 01-.5-.5v-17a.5.5 0 01.5-.5h8.672a1 1 0 01.707.293z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});