define("ember-svg-jar/inlined/single-woman-circle.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-circle</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM6.258 14.709a.5.5 0 01-.125-.755c.451-.527.764-1.22.9-3.251C7.3 6.854 9.891 5.5 12.121 5.5s4.827 1.354 5.087 5.2c.138 2.031.451 2.724.9 3.251a.5.5 0 01-.125.755 6.417 6.417 0 01-3.664.818.251.251 0 00-.243.25v.483a.784.784 0 00.511.734c1.579.584 2.971 1.079 4 1.587a.5.5 0 01.115.819 9.96 9.96 0 01-13.329.083.5.5 0 01.1-.819 42.554 42.554 0 014.172-1.67.784.784 0 00.511-.734v-.467a.251.251 0 00-.254-.25 6.338 6.338 0 01-3.644-.831z\"/><path d=\"M15.124 11.544a.5.5 0 10.152-.988 4.816 4.816 0 01-2.937-1.626.517.517 0 00-.769 0 4.569 4.569 0 01-2.846 1.625.5.5 0 10.152.988 5.51 5.51 0 002.916-1.4.249.249 0 01.335 0 5.91 5.91 0 002.997 1.401z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});