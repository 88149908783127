define("ember-svg-jar/inlined/headphones-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>headphones-woman</title><path d=\"M21.114 8.313a.25.25 0 01-.159-.209 9 9 0 00-17.91 0 .25.25 0 01-.159.209A4.5 4.5 0 004.121 17 .814.814 0 005 16.188V9a7 7 0 0114 0v7.188a.815.815 0 00.881.809 4.5 4.5 0 001.233-8.684zM12 18.5a9.055 9.055 0 00-7.933 4.749A.5.5 0 004.5 24h15a.5.5 0 00.433-.751A9.055 9.055 0 0012 18.5z\"/><path d=\"M7.022 14.077a2.549 2.549 0 01-.552 1.934c-.1.138-.2.283-.31.447a1 1 0 101.68 1.084c.085-.132.169-.247.25-.358a4.818 4.818 0 00.6-1.026.251.251 0 01.379-.108 4.987 4.987 0 005.856 0 .251.251 0 01.379.108 4.818 4.818 0 00.6 1.026c.081.111.165.226.25.358a1 1 0 001.68-1.084 9.203 9.203 0 00-.31-.447 2.549 2.549 0 01-.552-1.934S17 12.16 17 12a5 5 0 00-10 0c0 .16.022 2.077.022 2.077zM12 15a3 3 0 01-2.9-2.24.251.251 0 01.146-.295 7.334 7.334 0 002.573-1.775.25.25 0 01.181-.078.247.247 0 01.182.079 7.344 7.344 0 002.573 1.774.25.25 0 01.146.295A3 3 0 0112 15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});