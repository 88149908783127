define("ember-svg-jar/inlined/real-estate-favorite-hold-building-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-favorite-hold-building-1</title><rect x=\"3\" y=\"21\" width=\"6\" height=\"3\" rx=\".5\" ry=\".5\"/><path d=\"M4 18.668v.832a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3.934a.509.509 0 00-.021-.145 22.378 22.378 0 00-1.51-3.89c-.717-1.115-2.03-.5-1.688.935v.005l.382 1.667a.392.392 0 01-.66.365l-1.857-1.858A.5.5 0 012.5 12.3C2.467 7.855 1.4 6.722.666 6.466A.5.5 0 000 6.938v7.105a1 1 0 00.329.742c1.035.936 3 2.977 3.532 3.538a.5.5 0 01.139.345z\"/><rect x=\"15\" y=\"21\" width=\"6\" height=\"3\" rx=\".5\" ry=\".5\"/><path d=\"M23.789 6.53a.5.5 0 00-.455-.064c-.729.256-1.8 1.389-1.833 5.829a.5.5 0 01-.147.35L19.5 14.5a.392.392 0 01-.66-.365l.382-1.667v-.005c.342-1.436-.971-2.05-1.688-.935a22.378 22.378 0 00-1.51 3.89.509.509 0 00-.021.145V19.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-.832a.5.5 0 01.139-.345c.536-.561 2.5-2.6 3.532-3.538a1 1 0 00.326-.742v-7.1a.5.5 0 00-.211-.413zM15.5 9.5V2a.5.5 0 00-.276-.447l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 008.5 2v7.5a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5H15a.5.5 0 00.5-.5zm-2-3.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});