define("ember-svg-jar/inlined/science-molecules", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-molecules</title><path d=\"M22.055 4.7a1.945 1.945 0 10-1.944-1.95 1.945 1.945 0 001.944 1.95zM8.666 10.529a1.945 1.945 0 101.945 1.944 1.944 1.944 0 00-1.945-1.944z\"/><path d=\"M20.956 6.483a.749.749 0 10-1.275-.783c-.278.45-.593.922-.938 1.411a.5.5 0 01-.743.076 8.7 8.7 0 00-2.225-1.477.5.5 0 01-.086-.857A25.147 25.147 0 0118.4 3.092a.75.75 0 10-.72-1.315 31.527 31.527 0 00-4.43 3.063.5.5 0 01-.356.109q-.364-.031-.737-.031a8.7 8.7 0 00-1.237.088.5.5 0 01-.385-.105C7.649 2.58 3.278-.426 1.656 1.191.25 2.6 2.259 6.023 4.477 8.952a.5.5 0 01.041.539 8.624 8.624 0 00-1.03 4.1 8.9 8.9 0 00.048.913.492.492 0 01-.1.355C1.165 17.826-.965 21.375.462 22.8a1.681 1.681 0 001.244.457c3.072 0 9.173-5.231 12.383-8.44a49.113 49.113 0 006.867-8.334zM2.723 2.859a.5.5 0 01.6-.592 16.467 16.467 0 015.264 3.016.25.25 0 01-.047.426 8.712 8.712 0 00-2.75 2 .25.25 0 01-.385-.021 14.471 14.471 0 01-2.682-4.829zm3.063 17.024a12.641 12.641 0 01-3.661 1.89.5.5 0 01-.595-.59 12.868 12.868 0 012.225-4.228.251.251 0 01.23-.1.247.247 0 01.2.15A8.692 8.692 0 005.83 19.5a.247.247 0 01.065.2.25.25 0 01-.109.183zm2.232-1.636a.5.5 0 01-.669-.046 6.664 6.664 0 019.5-9.346.5.5 0 01.033.672 59.491 59.491 0 01-3.855 4.231 57.134 57.134 0 01-5.009 4.489zM13.18 20.176a6.785 6.785 0 01-2.662-.125 1 1 0 00-.491 1.939 8.689 8.689 0 002.129.264 8.874 8.874 0 001.328-.1 1 1 0 00-.3-1.977zM20.688 15.1a8.9 8.9 0 00-.062-3.392 1 1 0 10-1.957.413 6.714 6.714 0 01-1.984 6.356.5.5 0 01-.645.029q-.7-.543-1.475-1.193a.756.756 0 00-1 .027.731.731 0 00.029 1.1c2.469 2.067 6.182 4.825 8.418 4.825a1.71 1.71 0 001.26-.465c1.108-1.108.27-3.568-2.491-7.316a.5.5 0 01-.093-.384zm1.378 6.523a.5.5 0 01-.459.132 11.561 11.561 0 01-3.3-1.632.252.252 0 01-.04-.388 8.6 8.6 0 001.633-2.253.25.25 0 01.431-.027 11.66 11.66 0 011.869 3.712.5.5 0 01-.134.458z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});