define("ember-svg-jar/inlined/tools-box-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-box-1</title><path d=\"M24 9.375a3.5 3.5 0 00-3.5-3.5h-2.75a.5.5 0 01-.5-.5v-1A3.256 3.256 0 0014 1.125h-4a3.256 3.256 0 00-3.25 3.25v1a.5.5 0 01-.5.5H3.5a3.5 3.5 0 00-3.5 3.5v10a3.5 3.5 0 003.5 3.5h17a3.5 3.5 0 003.5-3.5zm-14.75-5a.755.755 0 01.75-.75h4a.755.755 0 01.75.75v1a.5.5 0 01-.5.5h-4.5a.5.5 0 01-.5-.5zm7.52 10.41l.59.48a.517.517 0 00.32.11h1.31a.507.507 0 01.4.8 2.982 2.982 0 01-5.091-.516.5.5 0 00-.451-.284h-3.7a.5.5 0 00-.451.284A3 3 0 017 17.375 3.1 3.1 0 014.68 16.2a.5.5 0 01.39-.82h1.25a.517.517 0 00.32-.11l.59-.48a.474.474 0 00.19-.38.522.522 0 00-.17-.39l-.61-.52a.49.49 0 00-.32-.12H5.07a.5.5 0 01-.39-.82A3.1 3.1 0 017 11.375a3 3 0 012.7 1.716.5.5 0 00.451.284h3.7a.5.5 0 00.451-.284 2.982 2.982 0 015.091-.516.507.507 0 01-.4.8H17.68a.49.49 0 00-.32.12l-.61.52a.522.522 0 00-.17.39.474.474 0 00.19.38z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});