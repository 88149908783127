define("ember-svg-jar/inlined/single-neutral-actions-edit-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-edit-1</title><path d=\"M16.1 13.071a.25.25 0 00.03-.317c-.649-.959-2.342-1.584-4.633-2.426l-.628-.23a.59.59 0 01-.138-.311 2.561 2.561 0 01.058-1.3 5.618 5.618 0 001.466-4.314C12.257 1.756 10.677 0 8.5 0S4.745 1.756 4.745 4.174A5.605 5.605 0 006.2 8.471a2.456 2.456 0 01.072 1.316.609.609 0 01-.134.309l-.631.232c-2.448.9-4.215 1.55-4.754 2.626A9.173 9.173 0 000 16.5a.5.5 0 00.5.5h11.569a.251.251 0 00.177-.073zM15.086 22.74a.5.5 0 00-.131-.479l-2.215-2.215a.5.5 0 00-.834.216l-.886 3.1a.5.5 0 00.48.638.459.459 0 00.137-.02l3.1-.885a.5.5 0 00.349-.355zM21.772 16.936a.5.5 0 000-.707l-3-3a.5.5 0 00-.707 0l-5.011 5.01a.5.5 0 000 .707l3 3a.5.5 0 00.707 0zM19.48 11.813a.5.5 0 000 .708l3 3a.536.536 0 00.377.124.584.584 0 00.377-.17 2.624 2.624 0 000-3.707 2.655 2.655 0 00-3.754.045z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});