define("ember-svg-jar/inlined/products-purse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>products-purse</title><path d=\"M22.378 17.478L20.99 9.72a1.438 1.438 0 00-1.422-1.181h-1.276c-.014 0-.138.013-.152.014H5.84c-.023 0-.1-.01-.126-.01H4.432A1.442 1.442 0 003.009 9.72l-1.387 7.759a5.11 5.11 0 001.124 4.19 5.228 5.228 0 004.02 1.87h10.467a5.237 5.237 0 004.025-1.872 5.115 5.115 0 001.12-4.189zM5.96 13.272a.75.75 0 110-1.5h12a.75.75 0 010 1.5h-8.5a.25.25 0 00-.25.25v2.017a.75.75 0 11-1.5 0v-2.017a.25.25 0 00-.25-.25zM6.459 7.539a1 1 0 001-1c0-4.316 5.324-4.943 7.646-3.036a3.731 3.731 0 011.354 3.036 1 1 0 002 0c0-3.99-3.27-6.078-6.5-6.078s-6.5 2.088-6.5 6.078a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});