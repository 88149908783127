define("ember-svg-jar/inlined/style-three-pin-sailing-boat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-sailing-boat</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm.694 5.207a.5.5 0 01.853-.353L16.2 7.51a.5.5 0 01-.13.8l-2.653 1.328a.5.5 0 01-.486-.022.5.5 0 01-.237-.425zm-6.048 1.34l3.694-3.693a.5.5 0 01.854.353v5.91a.5.5 0 01-.757.429L6.743 7.329a.5.5 0 01-.1-.782zM17 15.5a3.624 3.624 0 01-2.191-.693.5.5 0 00-.617 0 3.809 3.809 0 01-4.383 0 .5.5 0 00-.617 0A3.621 3.621 0 017 15.5a.5.5 0 010-1c.827 0 1.811-.354 2.018-1.129a.519.519 0 01.965 0c.207.775 1.191 1.129 2.017 1.129s1.811-.354 2.018-1.129a.519.519 0 01.965 0c.207.775 1.191 1.129 2.017 1.129a.5.5 0 010 1zm0-2.5l-.235.117a.5.5 0 01-.39.024h-.017a.345.345 0 01-.206-.21 1.752 1.752 0 00-1.643-1.189 1.725 1.725 0 00-1.632 1.123.5.5 0 01-.3.3 1.749 1.749 0 01-.577.1 1.864 1.864 0 01-.549-.084.5.5 0 01-.319-.3 1.751 1.751 0 00-1.623-1.135 1.727 1.727 0 00-1.631 1.12.5.5 0 01-.3.3.393.393 0 01-.306-.02L7 13a2.028 2.028 0 01-.941-1.4.5.5 0 01.49-.6h10.9a.5.5 0 01.49.6A2.028 2.028 0 0117 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});