define("ember-svg-jar/inlined/school-bell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>school-bell</title><path d=\"M13.236 19.674a.5.5 0 01-.236-.425v-.916a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v.916a.5.5 0 01-.236.425 2.34 2.34 0 102.472 0zM20.539 15.561l-.208-.213c-1.246-1.282-1.763-1.812-1.763-3.443V9.5c0-3.7-2-6.537-4.944-7.3a.5.5 0 01-.374-.484V1.25a1.25 1.25 0 00-2.5 0v.47a.5.5 0 01-.374.484c-2.943.762-4.944 3.6-4.944 7.3v2.4c0 1.631-.517 2.161-1.763 3.443l-.208.213A.751.751 0 004 16.833h16a.751.751 0 00.539-1.272z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});