define("ember-svg-jar/inlined/video-file-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-edit</title><path d=\"M19.5 10a.755.755 0 00-.4-.655l-5.847-3.236a.889.889 0 00-.834-.009.756.756 0 00-.42.665v6.471a.757.757 0 00.42.665.889.889 0 00.834-.009l5.847-3.236a.755.755 0 00.4-.656z\"/><path d=\"M23.413 3L21 .586A2 2 0 0019.584 0H8a2 2 0 00-2 2v9.068a.251.251 0 00.073.177l1.5 1.5A.25.25 0 008 12.568V2.5a.5.5 0 01.5-.5h10.878a.5.5 0 01.353.147l2.122 2.121a.5.5 0 01.147.354V18a.5.5 0 01-.5.5h-7.822a.247.247 0 00-.234.334c.162.437.359 1.153.447 1.485a.243.243 0 00.24.18H22a2 2 0 002-2V4.415A2 2 0 0023.413 3zM11.151 18.445l-6.58-6.58a2.71 2.71 0 00-3.606-.274 2.622 2.622 0 00-.2 3.881l6.678 6.679a.25.25 0 00.354 0l3.354-3.351a.249.249 0 000-.355zM11.938 20.131L9.13 22.939a.25.25 0 00.128.422S12.467 24 12.5 24a.5.5 0 00.49-.6l-.629-3.143a.25.25 0 00-.423-.126z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});