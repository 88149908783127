define("ember-svg-jar/inlined/landmark-london-tower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-london-tower</title><rect x=\"7.5\" y=\"20.993\" width=\"9\" height=\"3\" rx=\".5\" ry=\".5\"/><path d=\"M8 6.993h8a.5.5 0 00.416-.777l-4-6a.518.518 0 00-.832 0l-4 6A.5.5 0 008 6.993zM16 8.493H8a1.5 1.5 0 00-1.5 1.5v8a1.5 1.5 0 001.5 1.5h8a1.5 1.5 0 001.5-1.5v-8a1.5 1.5 0 00-1.5-1.5zm-4 9a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5z\"/><path d=\"M12.95 13.768a.5.5 0 01-.2-.4v-.875a.75.75 0 00-1.5 0v1.5a.749.749 0 00.3.6l1.333 1a.75.75 0 10.9-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});