define("ember-svg-jar/inlined/architecture-well", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>architecture-well</title><path d=\"M20.5 15h-4a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-2a1.5 1.5 0 00-1.5-1.5zM2.5 19h5a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-4A1.5 1.5 0 002 16.5v2a.5.5 0 00.5.5z\"/><rect x=\"9\" y=\"15\" width=\"6\" height=\"4\" rx=\".5\" ry=\".5\"/><rect x=\"6\" y=\"20\" width=\"5.5\" height=\"4\" rx=\".5\" ry=\".5\"/><rect x=\"2\" y=\"20\" width=\"3\" height=\"4\" rx=\".5\" ry=\".5\"/><rect x=\"12.5\" y=\"20\" width=\"5.5\" height=\"4\" rx=\".5\" ry=\".5\"/><rect x=\"19\" y=\"20\" width=\"3\" height=\"4\" rx=\".5\" ry=\".5\"/><path d=\"M2.914 3.553a1 1 0 00.044.972A1.007 1.007 0 003.809 5h.441a.5.5 0 01.5.5v7.75a1 1 0 002 0V5.5a.5.5 0 01.5-.5h3.5a.5.5 0 01.5.5v2a.5.5 0 01-.5.5H9a.5.5 0 00-.488.608l1 4.5A.5.5 0 0010 13.5h4a.5.5 0 00.488-.392l1-4.5A.5.5 0 0015 8h-1.75a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h3.5a.5.5 0 01.5.5v7.75a1 1 0 002 0V5.5a.5.5 0 01.5-.5h.441a1.007 1.007 0 00.851-.475 1 1 0 00.044-.972l-1.5-3A1 1 0 0018.691 0H5.309a1 1 0 00-.9.553z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});