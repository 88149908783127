define("ember-svg-jar/inlined/desktop-monitor-smiley", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>desktop-monitor-smiley</title><path d=\"M24 1.5A1.5 1.5 0 0022.5 0h-21A1.5 1.5 0 000 1.5v16A1.5 1.5 0 001.5 19h21a1.5 1.5 0 001.5-1.5v-16zm-2 13a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5zM14.35 20.682a.5.5 0 00-.385-.181h-3.93a.5.5 0 00-.385.181l-2.1 2.532a.5.5 0 00.41.786h8.079a.5.5 0 00.41-.787z\"/><path d=\"M9.5 7.251a1 1 0 001-1V5a1 1 0 00-2 0v1.25a1 1 0 001 1.001zM15 7.251a1 1 0 001-1V5a1 1 0 00-2 0v1.25a1 1 0 001 1.001zM15.443 9.682a4.5 4.5 0 01-6.364 0A1 1 0 107.665 11.1a6.5 6.5 0 009.192 0 1 1 0 00-1.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});