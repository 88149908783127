define("ember-svg-jar/inlined/amusement-park-balloon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>amusement-park-balloon</title><path d=\"M21.421 14.721a5.688 5.688 0 002.366-3.492c.684-2.694-.329-5.2-2.3-5.7s-4.06 1.216-4.744 3.908a6.608 6.608 0 00-.2 2.041 8.011 8.011 0 00-3.935-.023 6.787 6.787 0 00-.208-1.517 6.226 6.226 0 00-1.5-2.785 4.213 4.213 0 001.381-3.181A3.753 3.753 0 008.777 0a3.669 3.669 0 00-3.466 3.416A4.49 4.49 0 004 3.222a3.777 3.777 0 00-4 3.5 3.778 3.778 0 004 3.5 4.539 4.539 0 001.136-.144 6.79 6.79 0 00.213 1.651 5.9 5.9 0 002 3.243 5.892 5.892 0 00-2 3.243c-.684 2.693.329 5.2 2.3 5.7a2.714 2.714 0 00.685.085 3.5 3.5 0 002.325-.976 6.084 6.084 0 001.741-3.019 6.624 6.624 0 00.2-2.019 8 8 0 003.944-.022 6.608 6.608 0 00.2 2.041c.6 2.378 2.3 4 4.048 4a2.761 2.761 0 00.7-.088c1.976-.5 2.989-3 2.3-5.7a5.688 5.688 0 00-2.371-3.496zM4 8.222c-1.084 0-2-.687-2-1.5s.916-1.5 2-1.5 2 .686 2 1.5-.919 1.5-2 1.5zm4.78-6.25c.813 0 1.5.916 1.5 2s-.687 2-1.5 2-1.5-.916-1.5-2 .684-2 1.497-2zm1.68 17.541c-.285 1.121-1.28 2.714-2.314 2.462-.748-.191-1.275-1.628-.859-3.268.305-1.2 1.3-2.714 2.314-2.461s1.138 2.154.856 3.267zM9.6 13.7c-.748.2-1.885-.772-2.314-2.46-.418-1.64.114-3.081.857-3.271 1.03-.256 2.033 1.355 2.314 2.462s.171 3.004-.857 3.269zm4.929 2.526c-1.984 0-3.25-.889-3.25-1.5s1.266-1.5 3.25-1.5 3.25.888 3.25 1.5-1.268 1.496-3.252 1.496zm4.149-6.291c.286-1.128 1.262-2.723 2.314-2.462.748.19 1.274 1.628.858 3.267-.429 1.688-1.567 2.642-2.314 2.461-1.025-.265-1.136-2.173-.86-3.27zm2.312 12.039c-.75.2-1.886-.774-2.314-2.461-.284-1.117-.169-3.005.858-3.266.981-.258 2 1.227 2.314 2.46.416 1.64-.11 3.077-.858 3.267z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});