define("ember-svg-jar/inlined/marine-mammal-dolphin-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>marine-mammal-dolphin-2</title><path d=\"M23.61 12.488a.734.734 0 00-.665-.159l-2.517.608-1.463-2.657a.75.75 0 00-1.14-.211 4.6 4.6 0 00-1.472 2.917 4.55 4.55 0 00.695 2.746c-.347.47-1.36.687-3.119.316-2.387-.5-2.92-2.064-3.535-3.87a10.692 10.692 0 00-.959-2.236 6.337 6.337 0 00-2.992-2.594 4.52 4.52 0 00-1.288-.3l-3.7-4.388a.749.749 0 00-1.313.6L1.16 9.58c-1.607 3.42.384 6.685 1.613 8.135-.373 1.352-.564 2.447-.58 2.538A.751.751 0 003.35 21l2.2-1.474a12.742 12.742 0 007.28 2.074c1.537 0 6.011-.194 7.565-4.553a3.2 3.2 0 002.658-1.147 7.238 7.238 0 00.816-2.776.751.751 0 00-.259-.636zM5.2 10.537a1 1 0 111 1.084 1 1 0 01-1-1.084zm-2.3.074a11.508 11.508 0 001.559 3.017 10.085 10.085 0 00-.9 1.761 5.108 5.108 0 01-.659-4.778z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});