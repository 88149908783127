define("ember-svg-jar/inlined/tags-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-1</title><path d=\"M23.476 13.588L10.894 1a3.931 3.931 0 00-2.411-1H2a2 2 0 00-2 2l.007 6.486a3.739 3.739 0 001 2.411l12.579 12.579a1.674 1.674 0 001.178.524 1.481 1.481 0 00.355-.044 1.6 1.6 0 001.127-1.144l1.454-4.776a.5.5 0 01.332-.332l4.774-1.457a1.54 1.54 0 00.666-2.659zM5.993 7.492a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});