define("ember-svg-jar/inlined/shipment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment</title><path d=\"M22.083 21.625h-.208a.25.25 0 01-.25-.25v-6.833a.25.25 0 01.25-.25h.208a.917.917 0 100-1.834h-1.629a.25.25 0 01-.153-.052l-4.988-3.88a.917.917 0 00-1.126 1.448l2.619 2.037a.25.25 0 01-.153.447H7.347a.25.25 0 01-.153-.447l2.619-2.037a.917.917 0 00-1.126-1.448L3.7 12.406a.25.25 0 01-.153.052h-1.63a.917.917 0 000 1.834h.208a.25.25 0 01.25.25v6.833a.25.25 0 01-.25.25h-.208a.917.917 0 100 1.833h20.166a.917.917 0 100-1.833zm-3.437-5.958v4.583a.688.688 0 11-1.375 0v-4.583a.688.688 0 111.375 0zm-4.125 0v4.583a.688.688 0 11-1.375 0v-4.583a.688.688 0 111.375 0zm-3.667 0v4.583a.688.688 0 11-1.375 0v-4.583a.688.688 0 111.375 0zm-4.125 0v4.583a.688.688 0 11-1.375 0v-4.583a.688.688 0 111.375 0z\"/><path d=\"M12 8.792a1.828 1.828 0 001.026-3.344.25.25 0 01-.109-.207V1.458a.917.917 0 00-1.834 0v3.783a.25.25 0 01-.109.207A1.828 1.828 0 0012 8.792z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});