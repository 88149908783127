define("ember-svg-jar/inlined/amusement-park-merry-go-round-toys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>amusement-park-merry-go-round-toys</title><path d=\"M12.5 15.5a.5.5 0 00.5-.5v-2a1 1 0 00-2 0v2a.5.5 0 00.5.5z\"/><path d=\"M18.96 17H9a.5.5 0 01-.5-.5v-2a.5.5 0 00-.59-.49c-1.27.2-4.28 3.98-4.38 4.61a2 2 0 001.97 2.34h5a.5.5 0 01.5.5V23a1 1 0 002 0v-1.543a.5.5 0 01.5-.5h2a3.969 3.969 0 003.96-3.367.514.514 0 00-.5-.59zM23.109 5.545L12.464.109A1 1 0 0012.009 0h-.018a1 1 0 00-.455.109L.891 5.545A1.579 1.579 0 000 6.871a4 4 0 007.767 1.478.251.251 0 01.466 0 4 4 0 007.534 0 .251.251 0 01.466 0A4 4 0 0024 6.871a1.579 1.579 0 00-.891-1.326z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});