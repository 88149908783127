define("ember-svg-jar/inlined/science-bacteria", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-bacteria</title><path d=\"M23 11h-1.858a.5.5 0 01-.421-.229l-3.474-5.405a.5.5 0 01.031-.582L18.6 3.125a1 1 0 10-1.562-1.249l-1.545 1.936A.5.5 0 0115.1 4H8.544a.5.5 0 01-.39-.188L6.6 1.876a1 1 0 10-1.558 1.249l1.327 1.659a.5.5 0 01.03.582l-3.474 5.405A.5.5 0 012.5 11H1a1 1 0 000 2h1.5a.5.5 0 01.421.23l3.474 5.4a.5.5 0 01-.03.583l-1.323 1.662a1 1 0 001.558 1.25l1.551-1.937A.5.5 0 018.545 20H15.1a.5.5 0 01.391.188l1.55 1.937a1 1 0 001.562-1.25l-1.327-1.658a.5.5 0 01-.03-.583l3.474-5.4a.5.5 0 01.421-.23H23a1 1 0 000-2zM9.573 16.5a1.25 1.25 0 111.25-1.25 1.25 1.25 0 01-1.25 1.25zm4.177-3.491a2.75 2.75 0 112.75-2.75 2.75 2.75 0 01-2.75 2.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});