define("ember-svg-jar/inlined/non-gmo-lab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>non-gmo-lab</title><path d=\"M14.846 12.871a.249.249 0 00-.273.054l-5.347 5.347a.5.5 0 00-.131.479A3 3 0 0015 18v-4.9a.249.249 0 00-.154-.229z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM4.269 18.068a.5.5 0 01-.365-.205A9.995 9.995 0 0117.863 3.9a.5.5 0 01.06.759l-2.8 2.8a.5.5 0 01-.561.1.75.75 0 00-.31-.067H9.75A.75.75 0 009 8.25v5.129a.5.5 0 01-.146.353l-4.191 4.191a.5.5 0 01-.394.145zm8.89-8.641l-2.232 2.232a.25.25 0 01-.427-.177V9.5A.5.5 0 0111 9h1.982a.25.25 0 01.177.427zM12 22a9.949 9.949 0 01-5.864-1.9.5.5 0 01-.059-.759l13.26-13.26a.5.5 0 01.759.06A9.995 9.995 0 0112 22z\"/><circle cx=\"10.25\" cy=\"5.75\" r=\".75\"/><circle cx=\"13.75\" cy=\"4.75\" r=\".75\"/><circle cx=\"11.75\" cy=\"3.75\" r=\".75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});