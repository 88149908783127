define("ember-svg-jar/inlined/single-man-actions-edit-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-edit-1</title><path d=\"M7 10a5 5 0 10-5-5 5.006 5.006 0 005 5zM4.1 4.258a.5.5 0 01.741-.3A6.956 6.956 0 008.46 5a6.35 6.35 0 00.929-.07.5.5 0 01.569.564A3 3 0 014 5a2.792 2.792 0 01.1-.742zM13.845 15.328a.5.5 0 00.1-.571A7.531 7.531 0 007.207 10.5a7.542 7.542 0 00-7.185 5.358.494.494 0 00.078.442.5.5 0 00.4.2h11.965a.5.5 0 00.354-.146zM15.085 22.74a.5.5 0 00-.13-.479l-2.215-2.215a.5.5 0 00-.835.216l-.885 3.1a.5.5 0 00.618.619l3.1-.886a.5.5 0 00.347-.355zM21.772 16.229l-3-3a.5.5 0 00-.707 0l-5.011 5.01a.5.5 0 000 .707l3 3a.5.5 0 00.707 0l5.011-5.01a.5.5 0 000-.707zM19.48 11.814a.5.5 0 00-.147.353.5.5 0 00.146.355l3 3a.54.54 0 00.377.123.579.579 0 00.377-.17 2.624 2.624 0 000-3.707 2.656 2.656 0 00-3.753.046z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});