define("ember-svg-jar/inlined/single-neutral-actions-coding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-coding</title><path d=\"M.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.568a.25.25 0 00.182-.078.254.254 0 00.068-.187c0-.078-.008-.156-.008-.235a7.948 7.948 0 011.345-4.43.25.25 0 00.036-.19A5.5 5.5 0 000 13.75v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M17.5 23.75a6.5 6.5 0 10-6.5-6.5 6.508 6.508 0 006.5 6.5zm2.28-3.97a.75.75 0 01-1.06-1.06l1.293-1.293a.252.252 0 000-.354L18.72 15.78a.75.75 0 011.06-1.06l2 2a.749.749 0 010 1.06zm-4.56-5.06a.75.75 0 011.06 1.06l-1.293 1.293a.252.252 0 000 .354l1.293 1.293a.75.75 0 11-1.06 1.06l-2-2a.749.749 0 010-1.06zM.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.568a.25.25 0 00.182-.078.254.254 0 00.068-.187c0-.078-.008-.156-.008-.235a7.948 7.948 0 011.345-4.43.25.25 0 00.036-.19A5.5 5.5 0 000 13.75v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M17.5 23.75a6.5 6.5 0 10-6.5-6.5 6.508 6.508 0 006.5 6.5zm2.28-3.97a.75.75 0 01-1.06-1.06l1.293-1.293a.252.252 0 000-.354L18.72 15.78a.75.75 0 011.06-1.06l2 2a.749.749 0 010 1.06zm-4.56-5.06a.75.75 0 011.06 1.06l-1.293 1.293a.252.252 0 000 .354l1.293 1.293a.75.75 0 11-1.06 1.06l-2-2a.749.749 0 010-1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});