define("ember-svg-jar/inlined/farming-farmer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>farming-farmer</title><path d=\"M12.375 16v-5a2.5 2.5 0 00-2.5-2.5h-4a2.5 2.5 0 00-2.5 2.5v5a1.5 1.5 0 001.5 1.5h.25a.25.25 0 01.25.25v4.75a1.5 1.5 0 001.5 1.5h2a1.5 1.5 0 001.5-1.5v-4.75a.25.25 0 01.25-.25h.25a1.5 1.5 0 001.5-1.5zm-3.75-.5h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zm.75-3.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zM20.125 4.125a1 1 0 00-1 1v2a1.973 1.973 0 01-.572 1.389.25.25 0 01-.428-.176V5.125a1 1 0 00-2 0v3.213a.25.25 0 01-.428.176 1.973 1.973 0 01-.572-1.389v-2a1 1 0 00-2 0v2a3.993 3.993 0 002.824 3.808.25.25 0 01.176.239V23a1 1 0 002 0V11.172a.249.249 0 01.176-.239 3.993 3.993 0 002.824-3.808v-2a1 1 0 00-1-1zM5.315 4.875a2.5 2.5 0 105 0 .249.249 0 00-.25-.25h-4.5a.25.25 0 00-.25.25zM12.875 2.75a1 1 0 00-1-1h-1.352a.25.25 0 01-.223-.15l-.232-.514A1.5 1.5 0 008.621 0H7.129a1.474 1.474 0 00-1.417 1.021l-.26.581a.249.249 0 01-.228.148H3.875a1 1 0 000 2h8a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});