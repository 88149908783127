define("ember-svg-jar/inlined/vintage-man-moustache-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-man-moustache-1</title><circle cx=\"14\" cy=\"10.25\" r=\"1\"/><circle cx=\"10\" cy=\"10.25\" r=\"1\"/><path d=\"M14.458 0H9.541a3.99 3.99 0 00-3.947 3.352L5.3 5.248a.25.25 0 00.211.286 45.631 45.631 0 0012.959 0 .255.255 0 00.166-.1.249.249 0 00.045-.189l-.194-1.162C18.165 2.153 16.432 0 14.458 0zM18.745 14.632a.251.251 0 00-.273-.3c-2.143.237-2.726-.459-3.288-1.134a2.225 2.225 0 00-1.884-1.031 1.988 1.988 0 00-1.154.37.248.248 0 01-.291 0 1.991 1.991 0 00-1.155-.37A2.223 2.223 0 008.817 13.2c-.564.675-1.145 1.376-3.289 1.134a.251.251 0 00-.273.3c.019.1.525 2.368 5.012 2.368a2.178 2.178 0 001.556-.668.249.249 0 01.354 0 2.178 2.178 0 001.556.666c4.487 0 4.993-2.271 5.012-2.368z\"/><path d=\"M12 7.005a44.78 44.78 0 01-9.419-.98.347.347 0 00-.045-.006.988.988 0 00-1.012.764 1 1 0 00.759 1.193c.82.182 1.658.333 2.506.465A.25.25 0 015 8.688v.562a6.967 6.967 0 001.146 3.838 1 1 0 001.672-1.1A4.982 4.982 0 017 9.25v-.233a.251.251 0 01.275-.249A47.247 47.247 0 0012 9.005c1.583 0 3.165-.08 4.724-.236a.25.25 0 01.276.248v.233a4.981 4.981 0 01-.778 2.679A1 1 0 1017.909 13 6.981 6.981 0 0019 9.25v-.562a.25.25 0 01.211-.247 39.942 39.942 0 002.507-.465 1 1 0 00.759-1.193.988.988 0 00-1.013-.764.311.311 0 00-.044.006 44.78 44.78 0 01-9.42.98zM12.483 18.623a.519.519 0 00-.966 0l-1.25 4.75a.5.5 0 00.483.627h2.5a.5.5 0 00.483-.627zM.5 23.5a.5.5 0 00.5.5h8a.5.5 0 00.462-.691l-2.24-5.415a.5.5 0 00-.579-.295 13.822 13.822 0 00-5.176 2.318A2.464 2.464 0 00.5 21.894zM22.533 19.917a13.816 13.816 0 00-5.175-2.317.5.5 0 00-.58.295l-2.24 5.415A.5.5 0 0015 24h8a.5.5 0 00.5-.5v-1.606a2.462 2.462 0 00-.967-1.977z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});