define("ember-svg-jar/inlined/tennis-ball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tennis-ball</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM4.967 4.446a.749.749 0 011.06.049 10.862 10.862 0 011.281 1.721.75.75 0 01-1.287.771 9.257 9.257 0 00-1.1-1.482.75.75 0 01.046-1.059zm1.977 13.389a10.766 10.766 0 01-1.384 1.637.75.75 0 11-1.044-1.072 9.349 9.349 0 001.193-1.411.75.75 0 011.235.851zm1.884-5.753a10.809 10.809 0 01-.255 2.018.75.75 0 01-1.464-.328 9.319 9.319 0 00.22-1.743 9.4 9.4 0 00-.111-1.757.75.75 0 011.482-.231 10.866 10.866 0 01.128 2.041zm7.864-5.866A10.8 10.8 0 0117.973 4.5a.75.75 0 011.109 1.01 9.243 9.243 0 00-1.1 1.482.75.75 0 11-1.287-.771zm-.37 8.456a.751.751 0 01-.9-.568 10.9 10.9 0 01-.126-4.063.75.75 0 111.481.236 9.393 9.393 0 00.109 3.5.75.75 0 01-.564.895zm3.178 4.784a.757.757 0 01-1.061.016 10.812 10.812 0 01-1.385-1.637.75.75 0 011.236-.851 9.331 9.331 0 001.194 1.416.749.749 0 01.016 1.056z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});