define("ember-svg-jar/inlined/app-window-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-download</title><path d=\"M11.646 15.612a.5.5 0 00.708 0l3-3.008a.5.5 0 00-.354-.853h-1.75A.25.25 0 0113 11.5V9.251a1 1 0 10-2 0V11.5a.25.25 0 01-.25.25H9a.5.5 0 00-.354.853z\"/><path d=\"M7.5 18.751h9a1.5 1.5 0 001.5-1.5v-1.492a1 1 0 00-2 0v.741a.25.25 0 01-.25.25h-7.5A.25.25 0 018 16.5v-.742a1 1 0 00-2 0v1.492a1.5 1.5 0 001.5 1.501z\"/><path d=\"M24 4.751a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h18a3 3 0 003-3zm-14.346-1a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.562.091a1 1 0 01.908-.591.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.987.987 0 01.092-.409zM22 19.251a1 1 0 01-1 1H3a1 1 0 01-1-1V7a.25.25 0 01.25-.25h19.5A.25.25 0 0122 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});