define("ember-svg-jar/inlined/server", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server</title><path d=\"M24 6a1.5 1.5 0 00-1.5-1.5h-21A1.5 1.5 0 000 6v2.5A1.5 1.5 0 001.5 10h21A1.5 1.5 0 0024 8.5zM3.25 7.248A1.25 1.25 0 114.5 8.5a1.251 1.251 0 01-1.25-1.252zM8.5 8.5a1.25 1.25 0 111.25-1.25A1.252 1.252 0 018.5 8.5zM22.735 2.054L18.841.107a1 1 0 00-.447-.1H5.63a1.007 1.007 0 00-.448.105L1.288 2.054A.5.5 0 001.512 3h21a.5.5 0 00.223-.948zM24 13a1.5 1.5 0 00-1.5-1.5h-21A1.5 1.5 0 000 13v2.5A1.5 1.5 0 001.5 17h9.25a.25.25 0 01.25.25v4.5a.25.25 0 01-.25.25H5.5a1 1 0 000 2h13a1 1 0 000-2h-5.25a.25.25 0 01-.25-.25v-4.5a.25.25 0 01.25-.25h9.25a1.5 1.5 0 001.5-1.5zM3.25 14.248A1.25 1.25 0 114.5 15.5a1.251 1.251 0 01-1.25-1.252zM8.5 15.5a1.25 1.25 0 111.25-1.25A1.252 1.252 0 018.5 15.5zM23 24a1 1 0 000-2h-1a1 1 0 000 2zM1 24h1a1 1 0 000-2H1a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});