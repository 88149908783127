define("ember-svg-jar/inlined/office-folder-sealed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-folder-sealed</title><path d=\"M21.5 3a3.006 3.006 0 00-3-3h-13a3.006 3.006 0 00-3 3v18a3.039 3.039 0 003 3h13a3.04 3.04 0 003-3zm-3.089 16.092A3.2 3.2 0 0016 18H8a3.2 3.2 0 00-2.411 1.094l-.651.743a.25.25 0 01-.438-.164V6.223a.25.25 0 01.479-.1l.705 1.609A2.526 2.526 0 008 9.248h8a2.527 2.527 0 002.316-1.515l.705-1.61a.25.25 0 01.479.1v13.448a.25.25 0 01-.438.165zm-.478 2.491a.25.25 0 01-.188.415H6.255a.25.25 0 01-.188-.415L7.1 20.409A1.2 1.2 0 018 20h8a1.2 1.2 0 01.905.411zM18.5 2a.093.093 0 01.078.042.094.094 0 01.008.089l-2.1 4.8a.528.528 0 01-.486.317H8a.527.527 0 01-.484-.317l-2.1-4.8a.089.089 0 01.008-.088A.091.091 0 015.5 2z\"/><circle cx=\"12\" cy=\"4.498\" r=\"1.5\"/><circle cx=\"12\" cy=\"13.015\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});