define("ember-svg-jar/inlined/christmas-angel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-angel</title><path d=\"M14.174 22.23c-5.3-.089-8.948-3.493-11.107-8.334a.5.5 0 00-.59-.278l-.363.1a1.5 1.5 0 00-.985 2.057C2.5 18.874 5.755 24 12.265 24c.138 0 .276 0 .417-.007a1.49 1.49 0 001.153-.6q.282-.378.544-.773a.25.25 0 00-.205-.388z\"/><path d=\"M23 3.375C23 1.4 19.89.5 17 .5s-6 .9-6 2.875a2.373 2.373 0 001.625 2.058.25.25 0 01.11.349 4.357 4.357 0 00-.563 1.775.25.25 0 01-.4.178A28.463 28.463 0 015.329.668a1.5 1.5 0 00-2.41-.115 7.232 7.232 0 00.4 9.714A7.054 7.054 0 006.163 12a.25.25 0 01.009.473q-.6.217-1.286.434a.5.5 0 00-.3.69C6.1 16.827 9.256 21.086 15.2 20.7a.5.5 0 00.419-.282 25.266 25.266 0 002.171-7.783.5.5 0 01.331-.408 4.489 4.489 0 003.022-4.23 4.4 4.4 0 00-.482-1.983.25.25 0 01.139-.348c1.413-.511 2.2-1.304 2.2-2.291zm-12.376 6.46a5.191 5.191 0 01-5.9-.982 5.178 5.178 0 01-1.05-5.725.5.5 0 01.864-.1 29.009 29.009 0 006.128 6.375.251.251 0 01-.042.428zm6.022.658a2.5 2.5 0 112.5-2.5 2.5 2.5 0 01-2.5 2.5zm3.925-6.9a5.739 5.739 0 01-1.53.474.508.508 0 01-.325-.05 4.309 4.309 0 00-3.943-.1.5.5 0 01-.335.031 4.992 4.992 0 01-1-.358.25.25 0 010-.448A8.435 8.435 0 0117 2.5a8.415 8.415 0 013.568.65.25.25 0 010 .447z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});