define("ember-svg-jar/inlined/single-man-actions-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-email</title><path d=\"M10.5 15.5A2.5 2.5 0 0113 13h2.782a.251.251 0 00.2-.4 2.173 2.173 0 00-.891-.675l-3.936-1.687a.249.249 0 01-.155-.232V8.87a.252.252 0 01.074-.177 3.311 3.311 0 00.779-2.231.251.251 0 01.088-.176 1.873 1.873 0 00.538-1.4 1.58 1.58 0 00-.317-1.015.251.251 0 01-.034-.264 2.531 2.531 0 00.156-1.727C11.944.493 10.241 0 8.888 0a3.752 3.752 0 00-3.163 1.324.252.252 0 01-.213.118 1.074 1.074 0 00-.9.375c-.371.493-.1 1.17.176 1.732a.251.251 0 01-.045.288 1.3 1.3 0 00-.162.2A1.763 1.763 0 004.319 5a1.522 1.522 0 00.639 1.331.249.249 0 01.117.194A3.167 3.167 0 005.92 8.7a.25.25 0 01.08.183v1.123a.248.248 0 01-.151.229l-3.937 1.687C.64 12.468.457 13.626.205 15.23c-.055.359-.117.751-.2 1.179A.5.5 0 00.5 17h9.749a.25.25 0 00.25-.25zM23.871 18.042a.249.249 0 00-.254.007l-4.425 3.078a2.261 2.261 0 01-2.385 0l-4.425-3.078a.249.249 0 00-.382.212V22.5a1.5 1.5 0 001.5 1.5h9a1.5 1.5 0 001.5-1.5v-4.239a.251.251 0 00-.129-.219z\"/><path d=\"M24 16a1.5 1.5 0 00-1.5-1.5h-9A1.5 1.5 0 0012 16a.073.073 0 00.035.063l5.565 3.792a.754.754 0 00.795 0l5.568-3.792A.077.077 0 0024 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});