define("ember-svg-jar/inlined/social-media-plurk-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-plurk-1</title><path d=\"M12.5.5a9 9 0 00-9 9v11a3 3 0 006 0V17a.5.5 0 01.5-.5h2.5a8 8 0 000-16zm3 8a2.42 2.42 0 01-2.42 2.42H10a.5.5 0 01-.5-.5v-.75a3.59 3.59 0 013.58-3.58A2.42 2.42 0 0115.5 8.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});