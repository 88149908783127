define("ember-svg-jar/inlined/analytics-graph-lines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>analytics-graph-lines</title><path d=\"M23.664 9.679L17.546 12.1a14.284 14.284 0 01-4.459.846h-2.718a9.43 9.43 0 00-3.713.931L.126 17.4a.248.248 0 00-.132.219v2.812a.25.25 0 00.388.208l5.611-3.716a8.144 8.144 0 014.425-1.147l2.73.2a16.933 16.933 0 004.064-.3l6.6-1.487a.25.25 0 00.2-.244V9.911a.251.251 0 00-.342-.232z\"/><path d=\"M23.345.56a1 1 0 00-1.109.3L17.27 6.851a2.033 2.033 0 01-1.971.573l-3.547-1.236a5.755 5.755 0 00-4.32.413l-5.063 2.92a.25.25 0 01-.375-.216V1a1 1 0 00-2 0v14.343a.249.249 0 00.369.219l5.582-3.007a10.867 10.867 0 014.424-1.111h2.718A12.922 12.922 0 0017 10.7l6.853-2.708a.251.251 0 00.158-.232V1.5a1 1 0 00-.666-.94zM23.7 15.746l-6.159 1.388a18.344 18.344 0 01-4.5.334l-2.729-.2a6.689 6.689 0 00-3.488.9L.142 22.592a.253.253 0 00-.1.267A1.485 1.485 0 001.473 24h21.054a1.491 1.491 0 001.479-1.5v-6.511a.251.251 0 00-.3-.243z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});