define("ember-svg-jar/inlined/road-sign-split", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-split</title><path d=\"M23.78 12.53a.749.749 0 000-1.06L12.53.22a.749.749 0 00-1.06 0L.22 11.47a.749.749 0 000 1.06l11.25 11.25a.748.748 0 001.06 0zm-6.573-3.116l-3.634 3.634a.251.251 0 00-.073.177V18a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-4.775a.251.251 0 00-.073-.177L6.793 9.414a.5.5 0 010-.707l1.414-1.414a.5.5 0 01.707 0l2.909 2.907a.25.25 0 00.354 0l2.909-2.909a.5.5 0 01.707 0l1.414 1.414a.5.5 0 010 .709z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});