define("ember-svg-jar/inlined/layers-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layers-1-alternate</title><rect x=\"8\" y=\"2\" width=\"16\" height=\"12\" rx=\"2\" ry=\"2\"/><path d=\"M19 15a1 1 0 00-.98.8.249.249 0 01-.245.2H6.25a.25.25 0 01-.25-.25V8.225a.25.25 0 01.2-.245A1 1 0 006 6a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2 1 1 0 00-1-1z\"/><path d=\"M15 19a1 1 0 00-.98.8.249.249 0 01-.245.2H2.25a.25.25 0 01-.25-.25v-7.525a.25.25 0 01.2-.245A1 1 0 002 10a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2 1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});