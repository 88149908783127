define("ember-svg-jar/inlined/audio-document-aif", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-document-aif</title><path d=\"M8.005 9.625a.877.877 0 00-.875.875v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V10.5a.876.876 0 00-.875-.875z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM10.13 15a.625.625 0 01-1.25 0v-2.125a.25.25 0 00-.25-.25H7.38a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0v-4.375a2.211 2.211 0 011.9-2.238 2.126 2.126 0 012.35 2.113zm3.25-.625h.125a.625.625 0 110 1.25h-2a.625.625 0 010-1.25h.125a.25.25 0 00.25-.25v-4.25a.25.25 0 00-.25-.25h-.125a.625.625 0 010-1.25h2a.625.625 0 110 1.25h-.125a.25.25 0 00-.25.25v4.25a.25.25 0 00.25.25zm5.125-4.75h-2a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25h1.125a.625.625 0 010 1.25H16.38a.25.25 0 00-.25.25V15a.625.625 0 11-1.25 0v-5a1.626 1.626 0 011.625-1.625h2a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});