define("ember-svg-jar/inlined/video-projector-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-projector-1</title><path d=\"M18 11H6a1 1 0 000 2h12a1 1 0 000-2zM22 0H2a1 1 0 000 2h20a1 1 0 000-2zM10.243 9.937l5.4-3a.5.5 0 000-.874l-5.4-3A.5.5 0 009.5 3.5v6a.5.5 0 00.743.437zM16.5 17h-2.26a.249.249 0 00-.249.231 3 3 0 01-5.982 0A.251.251 0 007.76 17H7.5A2.5 2.5 0 005 19.5v1.25a1.494 1.494 0 00.75 1.291v1.209a.75.75 0 001.5 0v-.75a.25.25 0 01.25-.25h9a.25.25 0 01.25.25v.75a.75.75 0 001.5 0v-1.209A1.494 1.494 0 0019 20.75V19.5a2.5 2.5 0 00-2.5-2.5z\"/><path d=\"M11 19a2 2 0 10-2-2 2 2 0 002 2zm-.75-2a.75.75 0 11.75.75.75.75 0 01-.75-.75zM6 8.5a1 1 0 00-1-1H4a1 1 0 000 2h1a1 1 0 001-1zM4 5.5a1 1 0 000-2H3a1 1 0 000 2zM20 7.5h-1a1 1 0 000 2h1a1 1 0 000-2zM21 3.5h-1a1 1 0 000 2h1a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});