define("ember-svg-jar/inlined/trousers-calves-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trousers-calves-2</title><path d=\"M19.729 6.739a.249.249 0 00.19-.065A.253.253 0 0020 6.49V4.25a.5.5 0 00-.5-.5h-2.458a.251.251 0 00-.247.292 3.257 3.257 0 002.934 2.697zM19.5 2.25a.5.5 0 00.5-.5V1a1 1 0 00-1-1H5a1 1 0 00-1 1v.75a.5.5 0 00.5.5zM7.205 4.042a.251.251 0 00-.247-.292H4.5a.5.5 0 00-.5.5v2.24a.25.25 0 00.271.249 3.257 3.257 0 002.934-2.697zM15.3 4.179a.5.5 0 00-.5-.429H9.2a.5.5 0 00-.494.429 4.761 4.761 0 01-4.25 4.05.5.5 0 00-.452.5V23a1 1 0 001 1h5a1 1 0 001-1V10.5c0-.21.58-.5 1-.5s1 .29 1 .5V23a1 1 0 001 1h5a1 1 0 001-1V8.726a.5.5 0 00-.452-.5A4.761 4.761 0 0115.3 4.179z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});