define("ember-svg-jar/inlined/single-neutral-actions-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-laptop</title><path d=\"M.542 17.75h1.81a.25.25 0 01.249.229l.443 5.313a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h1.476a.249.249 0 00.25-.25v-3.833a2.946 2.946 0 01.326-1.329.248.248 0 00.016-.185A5.5 5.5 0 00.042 13.75v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.542\" cy=\"3.75\" r=\"3.5\"/><path d=\"M10.107 23.23a.96.96 0 00.81.437H23a.96.96 0 00.81-.436.925.925 0 00.043-.93l-.019-.032c-1.905-3.06-1.786-2.873-1.8-2.893a.252.252 0 01-.071-.174v-5.535a1.5 1.5 0 00-1.5-1.5h-7a1.5 1.5 0 00-1.5 1.5V19.2a.251.251 0 01-.07.174c-.019.02.1-.166-1.825 2.926a.925.925 0 00.039.93zm5.367-1.183a.243.243 0 010-.238l.272-.476a.232.232 0 01.2-.119H17.8a.232.232 0 01.2.119l.272.476a.241.241 0 010 .238.233.233 0 01-.2.12h-2.4a.234.234 0 01-.198-.12zm4.484-3.63a.249.249 0 01-.25.25h-5.5a.25.25 0 01-.25-.25v-4a.249.249 0 01.25-.25h5.5a.249.249 0 01.25.25zM.542 17.75h1.81a.25.25 0 01.249.229l.443 5.313a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h1.476a.249.249 0 00.25-.25v-3.833a2.946 2.946 0 01.326-1.329.248.248 0 00.016-.185A5.5 5.5 0 00.042 13.75v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.542\" cy=\"3.75\" r=\"3.5\"/><path d=\"M10.107 23.23a.96.96 0 00.81.437H23a.96.96 0 00.81-.436.925.925 0 00.043-.93l-.019-.032c-1.905-3.06-1.786-2.873-1.8-2.893a.252.252 0 01-.071-.174v-5.535a1.5 1.5 0 00-1.5-1.5h-7a1.5 1.5 0 00-1.5 1.5V19.2a.251.251 0 01-.07.174c-.019.02.1-.166-1.825 2.926a.925.925 0 00.039.93zm5.367-1.183a.243.243 0 010-.238l.272-.476a.232.232 0 01.2-.119H17.8a.232.232 0 01.2.119l.272.476a.241.241 0 010 .238.233.233 0 01-.2.12h-2.4a.234.234 0 01-.198-.12zm4.484-3.63a.249.249 0 01-.25.25h-5.5a.25.25 0 01-.25-.25v-4a.249.249 0 01.25-.25h5.5a.249.249 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});