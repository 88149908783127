define("ember-svg-jar/inlined/gesture-swipe-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-vertical</title><path d=\"M17.5 20.5h-1.25a.25.25 0 01-.25-.25v-2a1 1 0 00-2 0v2a.25.25 0 01-.25.25H12.5a.75.75 0 00-.469 1.335l2.5 2a.75.75 0 00.937 0l2.5-2A.75.75 0 0017.5 20.5zM12.5 3.5h1.25a.25.25 0 01.25.25v2a1 1 0 002 0v-2a.25.25 0 01.25-.25h1.25a.75.75 0 00.468-1.335l-2.5-2a.748.748 0 00-.937 0l-2.5 2A.75.75 0 0012.5 3.5zM19 11.875a3.5 3.5 0 00-3.5-3.5h-10a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h10a3.5 3.5 0 003.5-3.5zM14 13v-2.25a.374.374 0 01.375-.375H15.5a1.5 1.5 0 110 3h-1.124A.374.374 0 0114 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});