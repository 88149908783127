define("ember-svg-jar/inlined/music-genre-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-genre-settings</title><circle cx=\"6.252\" cy=\"17.031\" r=\"1.5\"/><path d=\"M11.355 18.126a.249.249 0 00.049-.38l-.372-.4a.464.464 0 010-.626l.988-1.064a1.783 1.783 0 00-1.7-2.953l-1.41.327a.453.453 0 01-.534-.311l-.424-1.392a1.774 1.774 0 00-3.4 0l-.424 1.391a.457.457 0 01-.533.312l-1.41-.33a1.783 1.783 0 00-1.7 2.953l.988 1.064a.464.464 0 010 .626l-.989 1.066a1.783 1.783 0 001.7 2.953l1.41-.327a.451.451 0 01.534.311l.424 1.392a1.775 1.775 0 003.4 0l.424-1.392a.452.452 0 01.533-.311l.335.077a.249.249 0 00.3-.207 4.012 4.012 0 011.811-2.779zm-5.1 1.9a3 3 0 113-3 3 3 0 01-3.003 3.005zM24 12.705a2 2 0 00-2.368-1.966l-6 1.125A2 2 0 0014 13.83v4.931a.255.255 0 01-.081.185.251.251 0 01-.192.064c-.075-.01-.15-.01-.227-.01a2.5 2.5 0 102.5 2.5v-7.255a.5.5 0 01.408-.491l5-.938a.5.5 0 01.592.492v3.453a.255.255 0 01-.081.185.251.251 0 01-.192.064c-.075-.01-.15-.01-.227-.01a2.5 2.5 0 102.5 2.5zM13.062 4.886a.169.169 0 010 .228l-.686.74a1.393 1.393 0 001.329 2.307l.982-.227a.162.162 0 01.19.111l.295.97a1.388 1.388 0 002.656 0l.3-.969a.166.166 0 01.19-.111l.982.227a1.393 1.393 0 001.33-2.306l-.687-.741a.169.169 0 010-.228l.686-.74a1.371 1.371 0 00.179-1.647 1.362 1.362 0 00-1.508-.661l-.982.227a.164.164 0 01-.19-.111l-.3-.969a1.388 1.388 0 00-2.656 0l-.295.969a.158.158 0 01-.19.111l-.982-.227a1.362 1.362 0 00-1.513.658 1.37 1.37 0 00.183 1.648zM16.5 3.5A1.5 1.5 0 1115 5a1.5 1.5 0 011.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});