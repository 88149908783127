define("ember-svg-jar/inlined/shop-street-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shop-street-sign</title><path d=\"M23.224 19.271C18.766.328 19.2 1.961 19.046 1.769A1.987 1.987 0 0017.469 1H6.994A2.008 2.008 0 005.06 2.5L.773 21.783a1 1 0 00.76 1.193 1.018 1.018 0 00.218.024 1 1 0 00.976-.783l.56-2.521a.248.248 0 01.244-.2h10.833a.251.251 0 01.247.292l-.347 2.04a1 1 0 101.972.336L18.383 9.53a.25.25 0 01.49-.015l1.454 6.178a.251.251 0 01-.244.307H19.5a1 1 0 000 2h1.172a.25.25 0 01.243.193l.361 1.536a1 1 0 00.973.771 1.025 1.025 0 00.231-.027 1 1 0 00.744-1.202z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});