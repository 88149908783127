define("ember-svg-jar/inlined/multiple-actions-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-information</title><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M11.933 11.261a.25.25 0 00.392.092A7.948 7.948 0 0117.5 9.438h.2a.25.25 0 00.2-.416 4.552 4.552 0 00-7 .235.25.25 0 00.005.311 7.015 7.015 0 011.028 1.693zM9.5 17.438a7.948 7.948 0 011.336-4.418.247.247 0 00.036-.192A5.5 5.5 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.571a.248.248 0 00.182-.079.246.246 0 00.067-.186c-.005-.099-.01-.198-.01-.297z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.25 10a.75.75 0 01-.75.75h-3a.75.75 0 010-1.5h.5a.25.25 0 00.25-.25v-2a.249.249 0 00-.25-.25H16a.75.75 0 010-1.5h1a1.25 1.25 0 011.25 1.25V20a.25.25 0 00.25.25h.5a.75.75 0 01.75.75zM18 14.25a1 1 0 11-1-1 1 1 0 011 1z\"/><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M11.933 11.261a.25.25 0 00.392.092A7.948 7.948 0 0117.5 9.438h.2a.25.25 0 00.2-.416 4.552 4.552 0 00-7 .235.25.25 0 00.005.311 7.015 7.015 0 011.028 1.693zM9.5 17.438a7.948 7.948 0 011.336-4.418.247.247 0 00.036-.192A5.5 5.5 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.571a.248.248 0 00.182-.079.246.246 0 00.067-.186c-.005-.099-.01-.198-.01-.297z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.25 10a.75.75 0 01-.75.75h-3a.75.75 0 010-1.5h.5a.25.25 0 00.25-.25v-2a.249.249 0 00-.25-.25H16a.75.75 0 010-1.5h1a1.25 1.25 0 011.25 1.25V20a.25.25 0 00.25.25h.5a.75.75 0 01.75.75zM18 14.25a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});