define("ember-svg-jar/inlined/meeting-camera-square-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-camera-square-alternate</title><path d=\"M20.131 0H3.869A3.873 3.873 0 000 3.87v16.26A3.873 3.873 0 003.869 24h16.262A3.873 3.873 0 0024 20.13V3.87A3.873 3.873 0 0020.131 0zM22 20.13A1.872 1.872 0 0120.131 22H3.869A1.872 1.872 0 012 20.13V3.87A1.872 1.872 0 013.869 2h16.262A1.872 1.872 0 0122 3.87z\"/><path d=\"M17.239 8.838a.5.5 0 00-.51.02l-1.843 1.194a.25.25 0 01-.386-.21V8.5A.5.5 0 0014 8H7a.5.5 0 00-.5.5v7a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-1.342a.25.25 0 01.386-.21l1.843 1.194a.5.5 0 00.771-.42V9.278a.5.5 0 00-.261-.44z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});