define("ember-svg-jar/inlined/protection-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>protection-shield</title><path d=\"M23.527 1.048A1.007 1.007 0 0022.555 1 12.578 12.578 0 0117 2a12.591 12.591 0 01-5.556-1A1 1 0 0010 1.9v17.851a1 1 0 00.448.835C11.092 21.011 13.018 22 17 22s5.908-.989 6.553-1.415a1 1 0 00.447-.834V1.9a.991.991 0 00-.473-.852zM20.5 9a.5.5 0 01-.5.5h-6a.5.5 0 01-.5-.5V7a.5.5 0 01.5-.5h6a.5.5 0 01.5.5zM6.5.5A1.5 1.5 0 005 2v12.25a.25.25 0 01-.25.25H1.5a1.5 1.5 0 000 3h6A.5.5 0 008 17V2A1.5 1.5 0 006.5.5zM7.5 19h-2a.5.5 0 00-.5.5V22a1.5 1.5 0 003 0v-2.5a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});