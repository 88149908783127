define("ember-svg-jar/inlined/mood-happy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mood-happy</title><path d=\"M12.766 0a11.218 11.218 0 00-9.682 16.909L.049 23.284a.5.5 0 00.666.666l6.376-3.036A11.228 11.228 0 1012.766 0zM19 9.5A1.5 1.5 0 1117.5 8 1.5 1.5 0 0119 9.5zM8 8a1.5 1.5 0 11-1.5 1.5A1.5 1.5 0 018 8zm1.783 5.016a.5.5 0 01.5-.5h4.967a.5.5 0 01.5.5 2.984 2.984 0 01-5.967 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});