define("ember-svg-jar/inlined/notes-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-settings</title><path d=\"M10.3 17.5H2.249A.25.25 0 012 17.25V4.75a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 102 0v-.25a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v4.194a.246.246 0 00.38.21 3.238 3.238 0 011.4-.548.245.245 0 00.217-.24V4.5a2 2 0 00-2-2h-1.25A.25.25 0 0114 2.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-1.5A.25.25 0 016 2.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25H2a2 2 0 00-2 2v13a2 2 0 002 2h7.787a.245.245 0 00.242-.225 3.248 3.248 0 01.491-1.4.247.247 0 00-.22-.375z\"/><circle cx=\"17.749\" cy=\"17.031\" r=\"1.5\"/><path d=\"M22.529 17.344a.464.464 0 010-.626l.988-1.064a1.783 1.783 0 00-1.7-2.953l-1.41.327a.451.451 0 01-.534-.311l-.424-1.392a1.774 1.774 0 00-3.4 0l-.424 1.391a.455.455 0 01-.533.312l-1.41-.328a1.783 1.783 0 00-1.7 2.953l.988 1.064a.464.464 0 010 .626l-.988 1.065a1.783 1.783 0 001.7 2.953l1.41-.327a.453.453 0 01.534.311l.424 1.392a1.775 1.775 0 003.4 0l.424-1.392a.452.452 0 01.533-.311l1.411.327a1.783 1.783 0 001.7-2.953zm-4.78 2.687a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});