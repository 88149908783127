define("ember-svg-jar/inlined/hotel-bunk-bed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hotel-bunk-bed</title><circle cx=\"5.25\" cy=\"16.25\" r=\"2\"/><path d=\"M20.54 16.864c-2.057-2.317-6.187-3.742-10.981-3.6a.833.833 0 00-.809.832v3.654a.5.5 0 00.5.5h10.667a.833.833 0 00.623-1.386z\"/><circle cx=\"5.25\" cy=\"6.25\" r=\"2\"/><path d=\"M8.75 7.75a.5.5 0 00.5.5h10.667a.833.833 0 00.623-1.386c-2.057-2.317-6.187-3.742-10.981-3.6a.833.833 0 00-.809.833z\"/><path d=\"M23 2.5a1 1 0 00-1 1V9a.5.5 0 01-.5.5h-19A.5.5 0 012 9V1a1 1 0 00-2 0v22a1 1 0 002 0v-1a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v1a1 1 0 002 0V3.5a1 1 0 00-1-1zm-20.5 17A.5.5 0 012 19v-7a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v7a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});