define("ember-svg-jar/inlined/earpods-tap-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earpods-tap-1</title><path d=\"M11.75 6a4.984 4.984 0 011.6-3.661.25.25 0 00.015-.351A6.218 6.218 0 008.75 0a6.215 6.215 0 00-4.612 1.986 1.5 1.5 0 00-.388 1.007v5.014a1.5 1.5 0 00.389 1.008A6.214 6.214 0 008.75 11a6.445 6.445 0 001.392-.15.5.5 0 01.608.488V22.5a1.5 1.5 0 001.5 1.5h1a1.5 1.5 0 001.5-1.5V10.743a.25.25 0 00-.143-.226A5.007 5.007 0 0111.75 6zm-4-.5a1 1 0 11-1-1 1 1 0 011 1z\"/><path d=\"M16.75 2.5a3.5 3.5 0 103.5 3.5 3.5 3.5 0 00-3.5-3.5zm0 5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});