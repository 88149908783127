define("ember-svg-jar/inlined/shape-cylinder-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shape-cylinder-alternate</title><path d=\"M12 24c3.93 0 8.116-.923 9.22-2.925a.251.251 0 00.023-.182C21.1 20.332 19.876 18.25 12 18.25s-9.1 2.082-9.243 2.643a.251.251 0 00.023.182C3.884 23.077 8.07 24 12 24z\"/><path d=\"M12 16.75c3.543 0 7.114.642 9.118 1.889a.25.25 0 00.382-.213V4v-.055C21.433 1.235 16.545 0 12 0S2.567 1.235 2.5 3.945v14.481a.25.25 0 00.382.213C4.886 17.392 8.457 16.75 12 16.75zM12 2c4.872 0 7.5 1.42 7.5 2S16.872 6 12 6 4.5 4.58 4.5 4 7.128 2 12 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});