define("ember-svg-jar/inlined/natural-disaster-hurricane-house-damaged", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>natural-disaster-hurricane-house-damaged</title><path d=\"M9.5 13a.25.25 0 00-.25-.25H7.205a.25.25 0 01-.245-.2l-.379-1.894a.25.25 0 00-.4-.145l-6 4.858a.5.5 0 00.319.881h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5H5a.5.5 0 00.5-.5V19a1.5 1.5 0 013 0v2.25a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-4.831a.25.25 0 00-.157-.232l-2.186-.874a.25.25 0 01-.157-.232zM11.5 4.25H23a1 1 0 000-2H11.5a1 1 0 000 2zM9 7.75h11a1 1 0 000-2H9a1 1 0 000 2zM23 10.25a1 1 0 00-1-1h-9a1 1 0 000 2h9a1 1 0 001-1zM23 12.75h-6.5a1 1 0 000 2H23a1 1 0 000-2zM21 17.25a1 1 0 00-1-1h-3a1 1 0 000 2h3a1 1 0 001-1zM21 19.75h-2a1 1 0 000 2h2a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});