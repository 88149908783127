define("ember-svg-jar/inlined/navigation-smartphone-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-smartphone-message</title><circle cx=\"4.75\" cy=\"19.75\" r=\"1\"/><path d=\"M22.5 2.5H21a1 1 0 000 2h.75a.25.25 0 01.25.25v8a.25.25 0 01-.25.25H16.5a1 1 0 00-.752.342l-2.31 2.639a.25.25 0 01-.438-.165V14a1 1 0 00-1-1H6.25a.25.25 0 01-.25-.25v-8a.25.25 0 01.25-.25H7a1 1 0 000-2H5.5A1.5 1.5 0 004 4v3.25a.25.25 0 01-.25.25H2a2 2 0 00-2 2V22a2 2 0 002 2h5.5a2 2 0 002-2v-6.75a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v2.062a1.688 1.688 0 002.958 1.112l2.921-3.339a.25.25 0 01.188-.085H22.5a1.5 1.5 0 001.5-1.5V4a1.5 1.5 0 00-1.5-1.5zm-15 19.25a.25.25 0 01-.25.25h-5a.25.25 0 01-.25-.25v-12a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v3.75A1.5 1.5 0 005.5 15h1.75a.25.25 0 01.25.25z\"/><path d=\"M14 13a.5.5 0 00.354-.146C14.543 12.664 19 8.171 19 5A5 5 0 009 5c0 3.171 4.457 7.664 4.646 7.854A.5.5 0 0014 13zm-1.75-8A1.75 1.75 0 1114 6.75 1.752 1.752 0 0112.25 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});