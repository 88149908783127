define("ember-svg-jar/inlined/designer-community-path", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>designer-community-path</title><path d=\"M6.21 1.51A8.52 8.52 0 00.68 8a8.6 8.6 0 001.44 7 .5.5 0 00.59.14l.42-.21A12.67 12.67 0 005.37 13a.5.5 0 00.07-.66c-2.37-3.25.59-7.11 4.25-8S18 5 19.08 8.62s-2 6.15-5.76 5.27c-.47-.11-.81-.11-.81-.61v-6c0-.41 0-.32-.37-.36l-.8-.08a11.62 11.62 0 00-2.85 0c-.35.06-.37 0-.37.38 0 2.65.08 9.76.08 9.83 0 .66 0 2.25-.78 2.57a2 2 0 01-1.69-.31.5.5 0 00-.73.49c-.08 1.2-.35 2.72.71 3.2a5.33 5.33 0 003.81.28c2.63-.89 3.48-3.81 3.14-6.21a8.64 8.64 0 0010.17-4.48 7.67 7.67 0 00-1.15-8.06c-3.48-4.15-10.55-5-15.47-3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});