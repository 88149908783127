define("ember-svg-jar/inlined/maps-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>maps-pin</title><path d=\"M22.136 5.238A2.494 2.494 0 0019.649 3h-1.073a.25.25 0 00-.244.307A6.353 6.353 0 0118.5 4.75a.249.249 0 00.25.25h.9a.5.5 0 01.5.447l.315 3a.5.5 0 01-.5.552h-2.74a.251.251 0 00-.215.122 23.389 23.389 0 01-2.352 3.2.247.247 0 00-.06.179l.181 2.97a.5.5 0 01-.5.531H9.72a.5.5 0 01-.5-.531l.18-2.97a.248.248 0 00-.061-.179 23.389 23.389 0 01-2.352-3.2A.25.25 0 006.775 9h-2.74a.5.5 0 01-.5-.552l.316-3A.5.5 0 014.35 5h.9a.25.25 0 00.25-.25 6.353 6.353 0 01.168-1.443A.251.251 0 005.424 3H4.35a2.5 2.5 0 00-2.486 2.238l-1.684 16A2.5 2.5 0 002.666 24h18.668a2.5 2.5 0 002.486-2.762zM6.848 21.531a.5.5 0 01-.5.469H2.666a.5.5 0 01-.5-.553l.316-3a.5.5 0 01.5-.448h3.55a.5.5 0 01.5.531zm.366-6a.5.5 0 01-.5.469H3.3a.5.5 0 01-.5-.552l.421-4a.5.5 0 01.5-.448h3.24a.5.5 0 01.5.531zm7.8 6.312a.5.5 0 01-.364.157H9.354a.5.5 0 01-.5-.531l.183-3a.5.5 0 01.5-.469h4.926a.5.5 0 01.5.469l.183 3a.5.5 0 01-.136.374zm1.776-6.312l-.244-4a.5.5 0 01.5-.531h3.24a.5.5 0 01.5.448l.421 4a.5.5 0 01-.5.552h-3.422a.5.5 0 01-.499-.469zm4.921 6.3a.506.506 0 01-.373.166h-3.687a.5.5 0 01-.5-.469l-.183-3a.5.5 0 01.5-.531h3.55a.5.5 0 01.5.448l.316 3a.5.5 0 01-.127.389z\"/><path d=\"M12 13a.5.5 0 00.354-.146C12.543 12.664 17 8.171 17 5A5 5 0 007 5c0 3.171 4.457 7.664 4.647 7.854A.5.5 0 0012 13zm-1.75-8A1.75 1.75 0 1112 6.75 1.752 1.752 0 0110.25 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});