define("ember-svg-jar/inlined/composition-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>composition-woman</title><path d=\"M22.5 19.943c-.7-1.41-3.65-2.387-7.347-3.756a1 1 0 01-.653-.938v-.891a.032.032 0 01.032-.032 8.262 8.262 0 005.112-1.151.5.5 0 00.106-.762c-.629-.69-1.064-1.523-1.25-4.265C18.167 3.23 14.85 1.5 12 1.5S5.833 3.23 5.5 8.148c-.186 2.742-.621 3.575-1.25 4.265a.5.5 0 00.106.762 8.1 8.1 0 004.889 1.157.249.249 0 01.255.25v.667a1 1 0 01-.653.938C5.15 17.556 2.2 18.533 1.5 19.943a10.158 10.158 0 00-.969 3.511.5.5 0 00.5.546H8.98a.25.25 0 00.231-.346L7.535 19.6a.453.453 0 01.113-.509.461.461 0 01.518-.072 8.522 8.522 0 007.678.068.456.456 0 01.616.6l-1.775 3.961a.25.25 0 00.228.352h8.057a.5.5 0 00.5-.546 10.158 10.158 0 00-.97-3.511zM1 5a1 1 0 001-1V2.5a.5.5 0 01.5-.5H4a1 1 0 000-2H2a2 2 0 00-2 2v2a1 1 0 001 1z\"/><path d=\"M2 16.5h2a1 1 0 000-2H2.5A.5.5 0 012 14v-1.5a1 1 0 00-2 0v2a2 2 0 002 2zM22 0h-2a1 1 0 000 2h1.5a.5.5 0 01.5.5V4a1 1 0 002 0V2a2 2 0 00-2-2zM23 11.5a1 1 0 00-1 1V14a.5.5 0 01-.5.5H20a1 1 0 000 2h2a2 2 0 002-2v-2a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});