define("ember-svg-jar/inlined/construction-paint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>construction-paint</title><path d=\"M22.5 0H17a.5.5 0 00-.5.5V5a.5.5 0 00.5.5h6.5A.5.5 0 0024 5V1.5A1.5 1.5 0 0022.5 0z\"/><rect x=\"16.5\" y=\"13\" width=\"7.5\" height=\"4.5\" rx=\".5\" ry=\".5\"/><rect x=\"16.5\" y=\"7\" width=\"7.5\" height=\"4.5\" rx=\".5\" ry=\".5\"/><path d=\"M3.25 17.75a3.231 3.231 0 003.169-2.528 1 1 0 10-1.95-.444A1.25 1.25 0 012 14.5V2.25A.25.25 0 012.25 2h11a.25.25 0 01.25.25v1a1 1 0 002 0V2a2 2 0 00-2-2H2a2 2 0 00-2 2v12.5a3.254 3.254 0 003.25 3.25zM23.5 19h-9.1a.25.25 0 00-.243.192 2.989 2.989 0 01-5.82 0A.251.251 0 008.1 19H.5a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h22a1.5 1.5 0 001.5-1.5v-3a.5.5 0 00-.5-.5z\"/><path d=\"M5.959 8.543a.249.249 0 01.2.023.254.254 0 01.119.16 1 1 0 00.972.774h7a1 1 0 001-1v-2a1 1 0 00-1-1h-7a1 1 0 00-.981.806.25.25 0 01-.228.2A3 3 0 006.25 12.5H10a.25.25 0 01.25.25v1.034a.25.25 0 01-.071.174A1.486 1.486 0 009.75 15v3.5a1.5 1.5 0 003 0V15a1.486 1.486 0 00-.429-1.042.25.25 0 01-.071-.174V12.5a2 2 0 00-2-2h-4a1 1 0 01-.291-1.957z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});