define("ember-svg-jar/inlined/paragraph-left-to-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paragraph-left-to-right</title><path d=\"M5.871 8.816h2.2a.245.245 0 01.245.244v7.592a.98.98 0 101.959 0V2.2a.245.245 0 01.245-.245h2.449a.245.245 0 01.245.245v14.452a.98.98 0 101.959 0V2.2a.245.245 0 01.245-.245h2.694a.98.98 0 000-1.959H5.871a4.409 4.409 0 000 8.817zm0-6.858h2.2a.245.245 0 01.249.242v4.411a.245.245 0 01-.245.245h-2.2a2.449 2.449 0 010-4.9z\"/><path d=\"M22.87 19.246l-3.9-3.919a.49.49 0 00-.837.346v2.694a.245.245 0 01-.245.244H1.967a.98.98 0 000 1.96h15.918a.245.245 0 01.245.245v2.693a.49.49 0 00.837.346l3.9-3.919a.489.489 0 00.003-.69z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});