define("ember-svg-jar/inlined/reorder-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>reorder-down</title><path d=\"M22.248 17h-1.5a.25.25 0 01-.25-.25V3a1 1 0 00-2 0v13.75a.25.25 0 01-.25.25h-1.5a.75.75 0 00-.6 1.2l2.753 3.67a.75.75 0 001.2 0l2.75-3.667A.749.749 0 0022.248 17z\"/><rect x=\".998\" width=\"11\" height=\"11\" rx=\"2\" ry=\"2\"/><path d=\"M10 13H8.5a1 1 0 100 2h1a.5.5 0 01.5.5v1a1 1 0 002 0V15a2 2 0 00-2-2zM11 19.5a1 1 0 00-1 1v1a.5.5 0 01-.5.5h-1a1 1 0 100 2H10a2 2 0 002-2v-1.5a1 1 0 00-1-1zM4.5 22h-1a.5.5 0 01-.5-.5v-1a1 1 0 00-2 0V22a2 2 0 002 2h1.5a1 1 0 100-2zM5.5 14a1 1 0 00-1-1H3a2 2 0 00-2 2v1.5a1 1 0 002 0v-1a.5.5 0 01.5-.5h1a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});