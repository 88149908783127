define("ember-svg-jar/inlined/school-teacher-braille", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>school-teacher-braille</title><path d=\"M8.626 8.606a.254.254 0 01-.14-.205.25.25 0 01.1-.224 4.5 4.5 0 10-5.182 0 .25.25 0 01-.035.429A6.006 6.006 0 000 14v3.5a1 1 0 001 1h1.126a.5.5 0 01.5.457L3 23.315a.749.749 0 00.75.685h4.5a.749.749 0 00.75-.685l.379-4.358a.5.5 0 01.5-.457H11a1 1 0 001-1V14a6.006 6.006 0 00-3.374-5.394zM3.5 4.5a2.5 2.5 0 01.094-.679.251.251 0 01.367-.148A5.97 5.97 0 007 4.5a6.026 6.026 0 001.2-.12.249.249 0 01.213.055.251.251 0 01.087.2 2.5 2.5 0 01-5-.138zM10 16a.5.5 0 01-.5.5h-7A.5.5 0 012 16v-2a4.006 4.006 0 012.666-3.771.25.25 0 01.334.235v2.786a1 1 0 002 0v-2.786a.25.25 0 01.334-.235A4.006 4.006 0 0110 14z\"/><path d=\"M21 0h-9.5a1 1 0 000 2H21a1 1 0 011 1v13a1 1 0 01-1 1h-6a1 1 0 000 2h6a3 3 0 003-3V3a3 3 0 00-3-3z\"/><circle cx=\"15.25\" cy=\"5.357\" r=\"1\"/><circle cx=\"19.25\" cy=\"5.357\" r=\"1\"/><circle cx=\"15.25\" cy=\"8.857\" r=\"1\"/><circle cx=\"19.25\" cy=\"8.857\" r=\"1\"/><circle cx=\"15.25\" cy=\"12.357\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});