define("ember-svg-jar/inlined/pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin</title><path d=\"M23.492 6.275L17.728.51a1.481 1.481 0 00-2.3.253l-4.1 6.443a7.468 7.468 0 00-5.468.8 1.48 1.48 0 00-.3 2.325l8.105 8.1a1.5 1.5 0 001.235.422 1.483 1.483 0 001.09-.72 7.423 7.423 0 00.8-5.467l6.443-4.1a1.48 1.48 0 00.253-2.295zm-6.2-2.848l-2.12 3.333a.508.508 0 01-.69.153.5.5 0 01-.154-.69l2.121-3.332a.5.5 0 11.844.536zM6.454 15.428l-5.94 5.94a1.5 1.5 0 000 2.121 1.517 1.517 0 002.121 0l5.94-5.94a1.5 1.5 0 00-2.121-2.121z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});