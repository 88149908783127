define("ember-svg-jar/inlined/house-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>house-1</title><path d=\"M22.843 12.246l-3.024-3.162a.25.25 0 01-.07-.173V5.232a.5.5 0 00-.5-.5H15.87a.5.5 0 01-.362-.154l-3.241-3.389a1.017 1.017 0 00-1.418 0L.274 12.245a.981.981 0 00.707 1.66h1.654a.25.25 0 01.25.25v8.384A1.463 1.463 0 004.346 24h4.327a.982.982 0 00.981-.981v-3.846a1.9 1.9 0 013.807 0v3.846a.982.982 0 00.981.981h4.327a1.464 1.464 0 001.462-1.461v-8.384a.25.25 0 01.25-.25h1.653a.981.981 0 00.709-1.659z\"/><path d=\"M23.707.293a1 1 0 00-1.414 0l-.944.944a.336.336 0 01-.475 0 2.341 2.341 0 00-3.3 0l-.944.945A1 1 0 1018.04 3.6l.943-.944a.339.339 0 01.477 0 2.338 2.338 0 003.3 0l.944-.944a1 1 0 00.003-1.419z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});