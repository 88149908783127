define("ember-svg-jar/inlined/bomb-rocket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bomb-rocket</title><path d=\"M12.889 5.763L18.414 2.9l.061-.029a.251.251 0 00.142-.262l-.223-1.569a1 1 0 00-.68-.82 1.007 1.007 0 00-1.04.25l-3.28 3.29a2.446 2.446 0 00-.62.99l-.237.717a.25.25 0 00.352.3zM23.784 6.286a1 1 0 00-.82-.68L21.4 5.383a.249.249 0 00-.261.142l-.03.061-2.867 5.525a.25.25 0 00.3.352l.717-.237a2.446 2.446 0 00.99-.62l3.29-3.28a1.007 1.007 0 00.245-1.04zM19.686 4.315a.5.5 0 00-.584-.091L8.915 9.505a11.819 11.819 0 00-1.752 1.046.5.5 0 00-.062.76l5.588 5.589a.5.5 0 00.76-.062 11.819 11.819 0 001.051-1.753L19.776 4.9a.5.5 0 00-.09-.585zM5.919 12.251a.5.5 0 00-.707 0L3.8 13.665a.5.5 0 000 .707l5.83 5.83a.5.5 0 00.707 0l1.414-1.414a.5.5 0 000-.707zM2.737 15.433a.5.5 0 00-.707 0l-.512.511a4.623 4.623 0 006.538 6.538l.511-.512a.5.5 0 000-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});