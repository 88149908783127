define("ember-svg-jar/inlined/single-neutral-actions-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-text</title><path d=\"M.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.56a.25.25 0 00.25-.25v-5.25a2.976 2.976 0 01.414-1.515.252.252 0 00-.015-.277A5.5 5.5 0 000 13.75v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M11 21.75a2 2 0 002 2h9a2 2 0 002-2v-9a2 2 0 00-2-2h-9a2 2 0 00-2 2v9zm2.25 0a.25.25 0 01-.25-.25V13a.25.25 0 01.25-.25h8.5A.25.25 0 0122 13v8.5a.249.249 0 01-.25.25z\"/><path d=\"M20 14.75h-4.75a.75.75 0 000 1.5H20a.75.75 0 000-1.5zM17.5 19.25a.75.75 0 000-1.5h-2.25a.75.75 0 000 1.5zM.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.56a.25.25 0 00.25-.25v-5.25a2.976 2.976 0 01.414-1.515.252.252 0 00-.015-.277A5.5 5.5 0 000 13.75v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M11 21.75a2 2 0 002 2h9a2 2 0 002-2v-9a2 2 0 00-2-2h-9a2 2 0 00-2 2v9zm2.25 0a.25.25 0 01-.25-.25V13a.25.25 0 01.25-.25h8.5A.25.25 0 0122 13v8.5a.249.249 0 01-.25.25z\"/><path d=\"M20 14.75h-4.75a.75.75 0 000 1.5H20a.75.75 0 000-1.5zM17.5 19.25a.75.75 0 000-1.5h-2.25a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});