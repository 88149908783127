define("ember-svg-jar/inlined/ticket-day-30", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ticket-day-30</title><path d=\"M11.75 13.5v1.25h.5V13.5c0-.275-.5-.275-.5 0zM6.75 13.5v3a1.246 1.246 0 00.5-1v-1a1.246 1.246 0 00-.5-1zM14.217 9V7a.25.25 0 10-.5 0v2c0 .275.5.275.5 0z\"/><path d=\"M23.125 9.023A1 1 0 0024 8.031V4a1.5 1.5 0 00-1.5-1.5h-21A1.5 1.5 0 000 4v4.031a1 1 0 00.875.992 3 3 0 010 5.954 1 1 0 00-.875.992V20a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 20v-4.031a1 1 0 00-.875-.992 3 3 0 010-5.954zM15.25 14v-1.5a.75.75 0 011.5 0V14c0 .275.5.275.5 0v-1.5a.75.75 0 011.5 0V14a1.748 1.748 0 01-1 1.574V17.5a.75.75 0 01-1.5 0v-1.926a1.748 1.748 0 01-1-1.574zm-3.5 2.25v1.25a.75.75 0 01-1.5 0v-4a1.75 1.75 0 013.5 0v4a.75.75 0 01-1.5 0v-1.25zM8.25 10A.75.75 0 019 9.25h.967a.25.25 0 000-.5.75.75 0 110-1.5.25.25 0 000-.5H9a.75.75 0 010-1.5h.967A1.752 1.752 0 0111.717 7a1.734 1.734 0 01-.317 1 1.734 1.734 0 01.315 1 1.752 1.752 0 01-1.75 1.75H9a.75.75 0 01-.75-.75zm.5 4.5v1A2.752 2.752 0 016 18.25a.75.75 0 01-.75-.75v-5a.75.75 0 01.75-.75 2.752 2.752 0 012.75 2.75zM12.217 9V7a1.75 1.75 0 113.5 0v2a1.75 1.75 0 01-3.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});