define("ember-svg-jar/inlined/gardening-glove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gardening-glove</title><path d=\"M21.7 10.4l-3.741 2.942a.5.5 0 11-.619-.787l6.1-4.8a1.253 1.253 0 00-1.548-1.97l-5.315 4.177a.5.5 0 01-.619-.788l6.892-5.419a1.253 1.253 0 00-1.55-1.969l-6.892 5.42a.5.5 0 01-.619-.788l5.316-4.181A1.252 1.252 0 1017.558.268l-6.476 5.093a.251.251 0 01-.4-.246l.325-1.64a1.534 1.534 0 00-2.877-.986L4.616 9.5a2 2 0 01-.7.783L.765 12.328a1.5 1.5 0 00-.365 2.19l7.005 8.908a1.5 1.5 0 002.213.163l3.191-3.017 10.436-8.207A1.253 1.253 0 0021.7 10.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});