define("ember-svg-jar/inlined/trends-torch-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trends-torch-1</title><path d=\"M18.608 11.5H5.468a.5.5 0 00-.417.775l1.4 2.111A2.492 2.492 0 008.538 15.5h.207l1.586 7.133a1.746 1.746 0 003.409 0l1.585-7.133h.213a2.482 2.482 0 002.075-1.111l1.411-2.112a.5.5 0 00-.416-.777zM18.123 3.869a.5.5 0 00-.833.323 1.356 1.356 0 01-1.508 1.194 4.149 4.149 0 01-.846-4.613.5.5 0 00-.418-.773c-3.375 0-5.792 1.862-6.41 4.892a1.463 1.463 0 01-1.264-1.187.5.5 0 00-.819-.317A5.339 5.339 0 004.58 9.721a.5.5 0 00.448.279h4.39a.5.5 0 00.488-.389 6.036 6.036 0 011.613-3 .25.25 0 01.235-.059.254.254 0 01.175.169C12.622 9 14.272 10 14.754 10h4.414a.5.5 0 00.469-.328 4.644 4.644 0 00.281-1.612 5.993 5.993 0 00-1.795-4.191z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});