define("ember-svg-jar/inlined/astronomy-planet-ring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astronomy-planet-ring</title><path d=\"M22.593 1.394c-1.5-1.5-4.683.077-7.52 2.065a.25.25 0 01-.228.031 9 9 0 00-11.5 11.577.25.25 0 01-.029.228C1.464 18-.085 21.142 1.38 22.607a2.158 2.158 0 001.594.6c1.57 0 3.712-1.112 5.876-2.633a.25.25 0 01.22-.034A9 9 0 0020.384 9.275a.253.253 0 01.035-.22 27.94 27.94 0 001.175-1.79c1.734-2.89 2.061-4.811.999-5.871zm-9.786 8.188a.75.75 0 11-.75-.75.751.751 0 01.75.75zm-3-1.626a2.51 2.51 0 01-2.255-1.313.247.247 0 01.076-.309 6.954 6.954 0 014.428-1.372.248.248 0 01.239.222 2.506 2.506 0 01-2.484 2.772zm-4 3.626a1.75 1.75 0 111.75 1.75 1.75 1.75 0 01-1.75-1.75zm-.248 6.859a.249.249 0 00.309.03 43.624 43.624 0 006.967-5.771 44.687 44.687 0 005.533-6.6.249.249 0 00-.026-.31 9.007 9.007 0 00-1.085-.98.251.251 0 01.019-.41 14.4 14.4 0 012.79-1.423 3.388 3.388 0 01.89-.211.251.251 0 01.261.234c.076 1.206-2.163 5.469-7.462 10.768s-9.544 7.524-10.729 7.5a.251.251 0 01-.244-.249 3.373 3.373 0 01.245-1.056A14.059 14.059 0 014.276 17.5a.25.25 0 01.41-.023 9.018 9.018 0 00.873.964z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});