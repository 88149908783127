define("ember-svg-jar/inlined/style-two-pin-car", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-two-pin-car</title><path d=\"M12 0a9.511 9.511 0 00-9.5 9.5c0 6.547 6.538 12.488 8.541 14.153a1.5 1.5 0 001.917 0c2-1.665 8.542-7.608 8.542-14.154A9.511 9.511 0 0012 0zM8.032 7.825l.507-1.352a1.5 1.5 0 011.4-.973h4.114a1.5 1.5 0 011.4.974l.507 1.351a.5.5 0 01-.46.675h-7a.5.5 0 01-.468-.675zm8.611 5.524a.249.249 0 00-.143.225v.676a.75.75 0 01-1.5 0v-.5a.25.25 0 00-.25-.25h-5.5a.25.25 0 00-.25.25v.5a.75.75 0 01-1.5 0v-.676a.249.249 0 00-.143-.225A1.5 1.5 0 016.53 12.3a.251.251 0 01.246-.3H8.5a.5.5 0 000-1H6.793a.207.207 0 01-.147-.353l1-1A.5.5 0 018 9.5h8a.5.5 0 01.354.146l1 1a.208.208 0 01.044.226.206.206 0 01-.191.128H15.5a.5.5 0 000 1h1.724a.251.251 0 01.246.3 1.5 1.5 0 01-.827 1.049z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});