define("ember-svg-jar/inlined/phone-action-email-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-email-1</title><path d=\"M12 11.75v6.75a1 1 0 01-1 1H4a1 1 0 01-1-1v-11a1 1 0 011-1h4.25a.25.25 0 00.25-.25v-2A.25.25 0 008.25 4H3.5A2.5 2.5 0 001 6.5v15A2.5 2.5 0 003.5 24h8a2.5 2.5 0 002.5-2.5v-9.75a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25z\"/><path d=\"M22.871 3.542a.249.249 0 00-.254.007l-4.925 3.078a2.26 2.26 0 01-2.384 0l-4.925-3.078a.25.25 0 00-.383.212V8.5a1.5 1.5 0 001.5 1.5h10A1.5 1.5 0 0023 8.5V3.761a.249.249 0 00-.129-.219z\"/><path d=\"M21.5 0h-10A1.5 1.5 0 0010 1.5a.074.074 0 00.035.063L16.1 5.355a.752.752 0 00.794 0l6.068-3.793A.072.072 0 0023 1.5 1.5 1.5 0 0021.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});