define("ember-svg-jar/inlined/mask-diamond-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mask-diamond-alternate</title><path d=\"M22 0H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zm0 21.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M10.99 19.042a1.342 1.342 0 002.02 0l5.382-6.083a1.467 1.467 0 000-1.915L13.01 4.959a1.384 1.384 0 00-2.02 0l-5.382 6.083a1.467 1.467 0 000 1.915zm.823-11.994a.248.248 0 01.374 0l4.235 4.786a.252.252 0 010 .332l-4.235 4.786a.248.248 0 01-.374 0l-4.235-4.786a.252.252 0 010-.332z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});