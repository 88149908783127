define("ember-svg-jar/inlined/taxi-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>taxi-1</title><path d=\"M23.25 15.808a.25.25 0 01-.25-.25v-1a3.751 3.751 0 00-3.55-3.74.249.249 0 01-.219-.157 6.907 6.907 0 00-5.718-4.053.247.247 0 01-.211-.16l-.487-1.279a1.254 1.254 0 00-1.135-.726h-1.3a1.253 1.253 0 00-1.2.915c-.068.244-.16.634-.244 1.006a.25.25 0 01-.244.195H8.5a1.989 1.989 0 00-1.789 1.105l-1.5 3.006a.249.249 0 01-.224.138H3.749A2.752 2.752 0 001 13.557v2a.249.249 0 01-.25.25.75.75 0 000 1.5H2.3a.25.25 0 00.248-.211 2.982 2.982 0 015.9 0 .25.25 0 00.248.211H13.3a.25.25 0 00.248-.211 2.982 2.982 0 015.9 0 .25.25 0 00.248.211h3.554a.75.75 0 000-1.5zM4.5 13.558a.75.75 0 11.75-.75.75.75 0 01-.75.75zm3.056-3.112L8.431 8.7a.251.251 0 01.224-.138h2.6a.25.25 0 01.25.25v1.75a.25.25 0 01-.25.25H7.78a.25.25 0 01-.224-.362zm.694 3.112a.75.75 0 11.75-.75.75.75 0 01-.75.75zm3.75 0a.75.75 0 11.75-.75.75.75 0 01-.75.75zm3.75 0a.75.75 0 11.75-.75.75.75 0 01-.75.75zm1.069-2.892a.249.249 0 01-.226.142H13.75a.25.25 0 01-.25-.25V8.9a.249.249 0 01.09-.192.252.252 0 01.206-.054 5.145 5.145 0 012.991 1.746.25.25 0 01.032.266zm2.681 2.892a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/><circle cx=\"5.5\" cy=\"17.558\" r=\"2\"/><circle cx=\"16.5\" cy=\"17.558\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});