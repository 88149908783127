define("ember-svg-jar/inlined/monitor-heart-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-heart-desktop</title><path d=\"M24 1.5A1.5 1.5 0 0022.5 0h-21A1.5 1.5 0 000 1.5v16A1.5 1.5 0 001.5 19h21a1.5 1.5 0 001.5-1.5v-16zm-22 1a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v5.322a.5.5 0 01-.5.5h-2.829a.5.5 0 01-.471-.33l-1.276-3.526a1.514 1.514 0 00-2.885.055L12.51 9.845a.25.25 0 01-.471.028l-1.595-3.806a1.521 1.521 0 00-2.729-.245L6.126 8.108a.5.5 0 01-.41.214H2.5a.5.5 0 01-.5-.5zm20 12a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-3.678a.5.5 0 01.5-.5h4a1 1 0 00.821-.429l1.337-1.924a.251.251 0 01.232-.105.248.248 0 01.2.152l1.885 4.5a1.5 1.5 0 001.425.984h.072a1.473 1.473 0 001.382-1.081l1.476-5.153a.251.251 0 01.232-.181.248.248 0 01.243.165l.742 2.05a1.487 1.487 0 001.434 1.024H21.5a.5.5 0 01.5.5zM14.35 20.681a.5.5 0 00-.385-.181h-3.93a.5.5 0 00-.385.181l-2.1 2.532a.5.5 0 00.41.787h8.079a.5.5 0 00.41-.787z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});