define("ember-svg-jar/inlined/pet-tracking-tablet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pet-tracking-tablet</title><circle cx=\"4.997\" cy=\"9.494\" r=\"1.5\"/><circle cx=\"8.497\" cy=\"7.494\" r=\"1.5\"/><circle cx=\"11.997\" cy=\"9.494\" r=\"1.5\"/><path d=\"M7.615 14.494h1.764a1.5 1.5 0 001.326-2.2l-.866-1.47a1.5 1.5 0 00-2.675-.017l-.891 1.517a1.5 1.5 0 001.342 2.171z\"/><path d=\"M13.168 24a.5.5 0 00.42-.771 4.151 4.151 0 01-.732-2.244v-.4a.5.5 0 00-.131-.337l-.539-.59a.5.5 0 00-.369-.162H2.5A.5.5 0 012 19V3.5a.5.5 0 01.5-.5h12a.5.5 0 01.5.5v8.362a.5.5 0 00.385.487 3.911 3.911 0 011 .382.419.419 0 00.414-.007.426.426 0 00.12-.107.25.25 0 01.35-.049l.008.006C18.488 13.5 20 14.643 20 15.424v4.558a1 1 0 00.147.523l2 3.017a1 1 0 101.705-1.045l-1.769-2.651a.5.5 0 01-.083-.278v-4.124c0-1.774-1.709-3.071-3.519-4.443-.578-.44-.822-.625-1.3-1.013A.5.5 0 0117 9.581V3a3 3 0 00-3-3H3a3 3 0 00-3 3v18a3 3 0 003 3z\"/><path d=\"M16.18 14.4a2.5 2.5 0 00-3.548 3.526l1.724 1.885v1.172c0 1.358 1.552 2.43 2.028 2.8a1 1 0 001.234-1.573c-.608-.48-1.234-.966-1.262-1.229v-1.56a1 1 0 00-.262-.675l-1.985-2.172a.5.5 0 01.712-.708l2.015 1.864a1 1 0 001.364-1.465z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});