define("ember-svg-jar/inlined/wifi-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-sync</title><path d=\"M15.373 8.854a1 1 0 001.414-1.414 9.9 9.9 0 00-13.979 0 1 1 0 101.414 1.414 7.9 7.9 0 0111.151 0z\"/><path d=\"M17.886 5.676A1 1 0 0019.3 4.262a13.457 13.457 0 00-19.007 0 1 1 0 001.414 1.414 11.456 11.456 0 0116.179 0zM5.319 10.641a1 1 0 101.414 1.414 4.332 4.332 0 014.839-.877.962.962 0 00.994-.165l.016-.013a.966.966 0 00-.164-1.646 6.28 6.28 0 00-7.099 1.287zM23.4 19.56a1 1 0 10-1.892-.646 4.056 4.056 0 01-6.858 1.408.25.25 0 01.008-.345l1.7-1.7a.5.5 0 00-.351-.847h-4.42a.5.5 0 00-.5.5v4.426a.5.5 0 00.848.351l.956-.956a.249.249 0 01.359.005A6.049 6.049 0 0023.4 19.56zM23.693 11.517a.5.5 0 00-.541.107l-1.012 1.013a.25.25 0 01-.357 0 6.063 6.063 0 00-10.136 2.214 1 1 0 001.893.649 4.057 4.057 0 016.835-1.434.251.251 0 01-.007.347l-1.642 1.637a.5.5 0 00.351.848H23.5a.5.5 0 00.5-.5v-4.423a.5.5 0 00-.307-.458z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});