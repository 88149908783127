define("ember-svg-jar/inlined/rating-star-three", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rating-star-three</title><path d=\"M15.742 10.635a1.241 1.241 0 00.408-1.43l-.927-2.473 1.644-1.045a1.259 1.259 0 00.5-1.407 1.243 1.243 0 00-1.2-.886h-2.379l-.573-2.348a1.251 1.251 0 00-2.424 0v.011l-.57 2.337H7.825A1.25 1.25 0 007.15 5.7l1.629 1.032L7.85 9.21a1.25 1.25 0 001.9 1.451L12 9.054l2.253 1.607a1.244 1.244 0 001.489-.026zM23.872 17.28a1.243 1.243 0 00-1.2-.886h-2.4l-.566-2.236a1.251 1.251 0 00-2.424 0l-.568 2.235h-2.4a1.25 1.25 0 00-.675 2.3l1.629 1.036-.918 2.481a1.25 1.25 0 001.9 1.451l2.25-1.607 2.253 1.607a1.249 1.249 0 001.9-1.456l-.927-2.473 1.644-1.045a1.259 1.259 0 00.502-1.407zM9.677 16.394H7.288l-.573-2.348a1.251 1.251 0 00-2.424 0v.01l-.573 2.338H1.325A1.25 1.25 0 00.65 18.7l1.629 1.036-.929 2.474a1.25 1.25 0 001.9 1.451l2.25-1.607 2.253 1.607a1.249 1.249 0 001.9-1.456l-.927-2.473 1.644-1.045a1.259 1.259 0 00.5-1.407 1.243 1.243 0 00-1.193-.886z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});