define("ember-svg-jar/inlined/single-neutral-actions-laptop.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-laptop</title><path d=\"M10.5 14a2.991 2.991 0 012.015-2.822.25.25 0 00.007-.47q-.488-.183-1.023-.379l-.628-.231a.589.589 0 01-.138-.31 2.635 2.635 0 01.057-1.3 5.618 5.618 0 001.466-4.314C12.256 1.756 10.677 0 8.5 0S4.745 1.756 4.745 4.174A5.6 5.6 0 006.2 8.471a2.508 2.508 0 01.072 1.317.6.6 0 01-.135.308l-.632.233c-2.446.9-4.213 1.549-4.753 2.626A9.167 9.167 0 000 16.5a.5.5 0 00.5.5h9.75a.249.249 0 00.25-.25zM23.894 22.635l-.018-.032-1.838-2.952a.26.26 0 01-.038-.133V14a1.5 1.5 0 00-1.5-1.5h-7A1.5 1.5 0 0012 14v5.517a.253.253 0 01-.037.132l-1.856 2.986a.923.923 0 00.043.93.958.958 0 00.808.435h12.084a.959.959 0 00.81-.437.921.921 0 00.042-.928zm-5.574-.254a.235.235 0 01-.2.119h-2.4a.235.235 0 01-.2-.119.242.242 0 010-.239l.272-.476a.234.234 0 01.2-.118h1.854a.234.234 0 01.2.118l.272.476a.245.245 0 01.002.239zM20 18.75a.249.249 0 01-.25.25h-5.5a.25.25 0 01-.25-.25v-4a.25.25 0 01.25-.25h5.5a.249.249 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});