define("ember-svg-jar/inlined/transform-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transform-right</title><path d=\"M23.821 10.57A1 1 0 0023 9H11a1 1 0 00-.822.431l-9 13A1 1 0 002 24h12a1 1 0 00.822-.43zM13.549 21.893a.249.249 0 01-.205.107H4.385a.25.25 0 01-.206-.392l7.27-10.5a.253.253 0 01.206-.108h8.959a.25.25 0 01.2.393zM1 12.5a1 1 0 001-1v-.25a.25.25 0 01.25-.25h.25a1 1 0 100-2H1a1 1 0 00-1 1v1.5a1 1 0 001 1zM2 18v-3a1 1 0 00-2 0v3a1 1 0 002 0zM6 9a1 1 0 100 2h1a1 1 0 000-2zM4.5 3.5a1 1 0 001 1h8.75a.25.25 0 01.25.25V6.5a.5.5 0 00.828.377l3.454-3a.5.5 0 000-.754l-3.454-3A.5.5 0 0014.5.5v1.75a.25.25 0 01-.25.25H5.5a1 1 0 00-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});