define("ember-svg-jar/inlined/cog-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cog-2</title><path d=\"M0 12a1.5 1.5 0 001.5 1.5h1.934a.25.25 0 01.245.2 8.46 8.46 0 001.235 2.981.25.25 0 01-.031.319l-1.368 1.365a1.5 1.5 0 102.121 2.121L7 19.118a.25.25 0 01.315-.031 8.434 8.434 0 002.985 1.235.25.25 0 01.2.245V22.5a1.5 1.5 0 103 0v-1.933a.25.25 0 01.2-.245 8.434 8.434 0 002.981-1.235.25.25 0 01.315.031l1.368 1.368a1.5 1.5 0 102.121-2.121L19.117 17a.25.25 0 01-.031-.315 8.46 8.46 0 001.235-2.985.249.249 0 01.245-.2H22.5a1.5 1.5 0 000-3h-1.934a.249.249 0 01-.245-.2 8.452 8.452 0 00-1.235-2.98.25.25 0 01.031-.315l1.368-1.369a1.5 1.5 0 10-2.121-2.121L17 4.884a.25.25 0 01-.315.031A8.434 8.434 0 0013.7 3.68a.25.25 0 01-.2-.245V1.5a1.5 1.5 0 10-3 0v1.935a.25.25 0 01-.2.245 8.444 8.444 0 00-2.981 1.235A.25.25 0 017 4.884L5.636 3.515a1.5 1.5 0 00-2.121 2.121l1.368 1.369a.25.25 0 01.031.315 8.452 8.452 0 00-1.235 2.98.25.25 0 01-.245.2H1.5A1.5 1.5 0 000 12zm12-5.5A5.5 5.5 0 116.5 12 5.5 5.5 0 0112 6.5z\"/><circle cx=\"12\" cy=\"12.001\" r=\"3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});