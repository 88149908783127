define("ember-svg-jar/inlined/vide-document-flv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vide-document-flv</title><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zm-14 5.125h-2a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25H7.5a.625.625 0 010 1.25H6.375a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0v-5A1.626 1.626 0 016.5 8.375h2a.625.625 0 010 1.25zm5 6h-2A1.627 1.627 0 019.875 14V9a.625.625 0 011.25 0v5a.375.375 0 00.375.375h2a.625.625 0 010 1.25zm5.625-5.125a8.176 8.176 0 01-1.625 4.875.625.625 0 01-1 0 8.176 8.176 0 01-1.625-4.875V9a.625.625 0 011.25 0v1.5a6.917 6.917 0 00.738 3.1c.044.087.237.074.284-.02a6.923 6.923 0 00.728-3.076V9a.625.625 0 011.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});