define("ember-svg-jar/inlined/office-folder-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-folder-1</title><path d=\"M22.5 2a2 2 0 00-2-2h-17a2 2 0 00-2 2v20.165A1.835 1.835 0 003.333 24H20.5a2 2 0 002-2zm-19 .5A.5.5 0 014 2h16a.5.5 0 01.5.5v1.225a.251.251 0 01-.138.224l-2.2 1.1A2.984 2.984 0 0016.5 7.734v14.014a.25.25 0 01-.25.25H4a.5.5 0 01-.5-.5zm15 5.236a.994.994 0 01.553-.894l1.085-.542a.25.25 0 01.362.224v3.2a.251.251 0 01-.138.224l-1.5.75a.25.25 0 01-.362-.224zm2 8.491a.251.251 0 01-.138.224l-1.5.75a.25.25 0 01-.362-.224v-3.7a.252.252 0 01.138-.224l1.5-.75a.25.25 0 01.362.224zm-2 3.546a.252.252 0 01.138-.224l1.5-.75a.25.25 0 01.362.224V21.5a.5.5 0 01-.5.5h-1.25a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});