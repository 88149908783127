define("ember-svg-jar/inlined/kitchenware-peeler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchenware-peeler</title><path d=\"M20 0h-1a.5.5 0 00-.5.5V3c0 2.757-2.243 4.5-5 4.5h-3c-2.757 0-5-1.743-5-4.5V.5A.5.5 0 005 0H4a.5.5 0 00-.5.5V3a7.006 7.006 0 004.379 6.486A1.159 1.159 0 018.5 10.5a.991.991 0 01-.176.56A1.989 1.989 0 008 12.152V20a4 4 0 008 0v-7.848a1.994 1.994 0 00-.324-1.092.991.991 0 01-.176-.56 1.159 1.159 0 01.621-1.014A7.006 7.006 0 0020.5 3V.5A.5.5 0 0020 0zm-8 22.25a2 2 0 112-2 2 2 0 01-2 2z\"/><path d=\"M7.75 2a1 1 0 010-2h8.5a1 1 0 010 2zM8.25 5a1 1 0 010-2h7.5a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});