define("ember-svg-jar/inlined/network-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>network-pin</title><path d=\"M12.768 22.192A9.733 9.733 0 018.761 17.5h2.031a1 1 0 100-2H8.106a15 15 0 01-.4-3.5A15.263 15.263 0 018 9c11.014 0 13.746.008 14.486-.018a.942.942 0 00.892-1.375A11.944 11.944 0 0012.147 0C4.635.486.583 6.091.237 11.161A12.012 12.012 0 0012.206 24h.007a.984.984 0 00.957-.809.947.947 0 00-.402-.999zM6.062 15.5H2.853A9.888 9.888 0 012.67 9h3.285a17.823 17.823 0 00-.247 3 17.249 17.249 0 00.354 3.5zm6.145-13.331A10.161 10.161 0 0115.849 7H8.564a10.048 10.048 0 013.643-4.831zM20.873 7h-2.907a12.982 12.982 0 00-2.4-4.42A9.908 9.908 0 0120.873 7zM8.82 2.6A12.9 12.9 0 006.451 7H3.562A10.041 10.041 0 018.82 2.6zM3.866 17.5h2.741a12.715 12.715 0 002.213 3.9 10.04 10.04 0 01-4.954-3.9z\"/><path d=\"M18.792 11a5.006 5.006 0 00-5 5c0 3.171 4.457 7.664 4.646 7.854a.5.5 0 00.707 0c.189-.19 4.647-4.683 4.647-7.854a5.006 5.006 0 00-5-5zm0 3.25a1.75 1.75 0 11-1.75 1.75 1.753 1.753 0 011.75-1.752z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});