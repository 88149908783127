define("ember-svg-jar/inlined/music-genre-pacman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-genre-pacman</title><path d=\"M24 9.748a2.009 2.009 0 00-.83-1.623 1.979 1.979 0 00-1.754-.289l-3.048.848A2 2 0 0017 10.581v2.529a2 2 0 102 2.052l-.046-4.374a.252.252 0 01.183-.244l2.547-.708a.247.247 0 01.218.042.251.251 0 01.1.2v1.532a2 2 0 102 2.052C24 13.645 24 9.748 24 9.748zM14.5 8.894a2 2 0 002-1.948l-.046-4.374a.252.252 0 01.183-.244l1.315-.365a1 1 0 00-.536-1.927l-1.549.431a2 2 0 00-1.367 1.9v2.527a2 2 0 000 4zM14.015 18.312L8.9 15.868a.251.251 0 01-.118-.333l2.444-5.118a1.5 1.5 0 00-.926-2.084 8 8 0 00-9.84 10.33 8 8 0 0014.146 1.85 1.5 1.5 0 00-.593-2.2zM4.005 13a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});