define("ember-svg-jar/inlined/archive-locker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>archive-locker</title><path d=\"M21.5 8a.5.5 0 00.5-.5V2a2 2 0 00-2-2H4a2 2 0 00-2 2v5.5a.5.5 0 00.5.5zm-7.245-3.184l-.167.5A1 1 0 0113.14 6h-2.28a1 1 0 01-.948-.684l-.167-.5a1 1 0 01.949-1.316h2.612a1 1 0 01.949 1.316zM21.5 15.5a.5.5 0 00.5-.5V9.5a.5.5 0 00-.5-.5h-19a.5.5 0 00-.5.5V15a.5.5 0 00.5.5zM9.882 11.416a1 1 0 01.812-.416h2.612a1 1 0 01.949 1.316l-.167.5a1 1 0 01-.948.684h-2.28a1 1 0 01-.948-.684l-.167-.5a1 1 0 01.137-.9zM2.5 16.5a.5.5 0 00-.5.5v5a2 2 0 002 2h16a2 2 0 002-2v-5a.5.5 0 00-.5-.5zm7.382 2.416a1 1 0 01.812-.416h2.612a1 1 0 01.949 1.316l-.167.5a1 1 0 01-.948.684h-2.28a1 1 0 01-.948-.684l-.167-.5a1 1 0 01.137-.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});