define("ember-svg-jar/inlined/focus-eye-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>focus-eye-alternate</title><path d=\"M21.25 2H2.75A2.752 2.752 0 000 4.75v14.5A2.752 2.752 0 002.75 22h18.5A2.752 2.752 0 0024 19.25V4.75A2.752 2.752 0 0021.25 2zm1.25 17.25a1.252 1.252 0 01-1.25 1.25H2.75a1.252 1.252 0 01-1.25-1.25V4.75A1.252 1.252 0 012.75 3.5h18.5a1.252 1.252 0 011.25 1.25z\"/><path d=\"M3.75 9.5a.75.75 0 00.75-.75v-2a.25.25 0 01.25-.25h2a.75.75 0 000-1.5h-2A1.752 1.752 0 003 6.75v2a.75.75 0 00.75.75zM19.25 5h-2a.75.75 0 000 1.5h2a.25.25 0 01.25.25v2a.75.75 0 001.5 0v-2A1.752 1.752 0 0019.25 5zM6.75 17.5h-2a.25.25 0 01-.25-.25v-2a.75.75 0 00-1.5 0v2A1.752 1.752 0 004.75 19h2a.75.75 0 000-1.5zM20.25 14.5a.75.75 0 00-.75.75v2a.25.25 0 01-.25.25h-2a.75.75 0 000 1.5h2A1.752 1.752 0 0021 17.25v-2a.75.75 0 00-.75-.75zM12 10a2 2 0 102 2 2 2 0 00-2-2z\"/><path d=\"M18.854 12.768a1.252 1.252 0 000-1.536C16.285 7.94 13.709 7.25 12 7.25s-4.286.69-6.854 3.982a1.251 1.251 0 000 1.537C7.714 16.06 10.29 16.75 12 16.75s4.286-.69 6.854-3.982zM12 15.25c-1.878 0-3.744-1.066-5.418-3.091a.249.249 0 010-.318C8.255 9.817 10.122 8.75 12 8.75s3.744 1.067 5.419 3.091a.249.249 0 010 .318C15.745 14.183 13.877 15.25 12 15.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});