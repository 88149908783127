define("ember-svg-jar/inlined/skiing-ice-skates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skiing-ice-skates</title><path d=\"M8.005 10.779a.417.417 0 00-.417.417v.622a3.817 3.817 0 003.812 3.813h8.652a.208.208 0 00.175-.1.2.2 0 00.014-.2 4.854 4.854 0 00-4.4-2.824h-1.11a4.01 4.01 0 01-3.178-1.569.419.419 0 00-.33-.163zM10.708 6.2a.417.417 0 00-.417-.417H8.005a.417.417 0 00-.417.417v2.913a.417.417 0 00.417.416h2.338a.417.417 0 00.412-.483 3.391 3.391 0 01-.047-.556z\"/><path d=\"M23 19.5a1 1 0 00-1 1 1.5 1.5 0 01-1.5 1.5h-3a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h.556a2.639 2.639 0 002.636-2.635.484.484 0 00-.485-.484H11.4a5.068 5.068 0 01-5.062-5.063v-6.1a1.181 1.181 0 011.18-1.18h2.773a.417.417 0 00.417-.416V.971A.972.972 0 009.4.061L1.63 2.973a.978.978 0 00-.63.91v13.482A2.638 2.638 0 003.635 20H5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H1a1 1 0 000 2h19.5a3.5 3.5 0 003.5-3.5 1 1 0 00-1-1zm-15.5 1A.5.5 0 018 20h6.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});