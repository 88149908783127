define("ember-svg-jar/inlined/controls-rewind", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>controls-rewind</title><path d=\"M21.5 1.385l-7.582 6.847a.25.25 0 01-.418-.185V2.521A1.5 1.5 0 0011 1.385L.505 10.863a1.541 1.541 0 000 2.273L11 22.614a1.5 1.5 0 002.5-1.136v-5.525a.25.25 0 01.418-.185l7.582 6.846a1.5 1.5 0 002.5-1.136V2.521a1.5 1.5 0 00-2.5-1.136z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});