define("ember-svg-jar/inlined/navigation-up-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-up-2</title><path d=\"M5.93 4.675a.99.99 0 00.627 1.756h3.956a.25.25 0 01.25.25V16.82a.25.25 0 01-.25.25H7.052a1.237 1.237 0 100 2.474h9.9a1.237 1.237 0 100-2.474h-3.465a.25.25 0 01-.25-.25V6.681a.25.25 0 01.25-.25h3.956a.99.99 0 00.627-1.756L12.627.222a.989.989 0 00-1.254 0zM16.948 21.523h-9.9a1.238 1.238 0 100 2.475h9.9a1.238 1.238 0 100-2.475z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});