define("ember-svg-jar/inlined/smiley-head-sick-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-head-sick-alternate</title><path d=\"M12 0A12.008 12.008 0 000 12c0 9.423 8.68 12 12 12a12.013 12.013 0 0012-12c0-8.819-7.874-12-12-12zM4.792 5.077A.25.25 0 014.972 5h14.056a.25.25 0 01.18.077 10.1 10.1 0 011.215 1.538.251.251 0 01-.211.385H3.788a.251.251 0 01-.211-.385 10.1 10.1 0 011.215-1.538zM2 12a9.993 9.993 0 01.4-2.821A.251.251 0 012.645 9h3.418a.25.25 0 01.141.457l-3.948 2.678A.163.163 0 012 12zm13.76-9.266a.128.128 0 01.078.1.127.127 0 01-.055.119.249.249 0 01-.14.043H8.916a.25.25 0 01-.079-.487 10 10 0 016.923.221zM12 22a10.018 10.018 0 01-9.634-7.316.249.249 0 01.1-.274l7.909-5.367A.254.254 0 0110.516 9h10.839a.251.251 0 01.24.179A10 10 0 0112 22z\"/><path d=\"M12 15.5a5.386 5.386 0 00-4.526 2.454 1 1 0 101.675 1.092 3.4 3.4 0 015.7 0 1 1 0 101.675-1.092A5.386 5.386 0 0012 15.5z\"/><circle cx=\"16\" cy=\"12\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});