define("ember-svg-jar/inlined/file-code-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-warning</title><path d=\"M17.749 11.5V15a.75.75 0 101.5 0v-3.5a.75.75 0 00-1.5 0zM11.249 12v2.5a1.252 1.252 0 001.25 1.25H14a1.251 1.251 0 001.25-1.25V12A1.25 1.25 0 0014 10.751h-1.5A1.251 1.251 0 0011.249 12zm1.5.25h1v2h-1zM13.749 8.5V5a.75.75 0 10-1.5 0v3.5a.75.75 0 001.5 0zM20.249 8V5.5A1.25 1.25 0 0019 4.251h-1.5a1.251 1.251 0 00-1.25 1.25V8a1.252 1.252 0 001.25 1.25H19A1.251 1.251 0 0020.249 8zm-1.5-.5a.249.249 0 01-.25.25H18a.25.25 0 01-.25-.25V6a.25.25 0 01.25-.25h.5a.249.249 0 01.25.25z\"/><path d=\"M23.414 3L21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.82a.248.248 0 00.234.249 7.354 7.354 0 011.443.216.249.249 0 00.323-.238V2.5a.5.5 0 01.5-.5h10.878a.5.5 0 01.354.146l2.121 2.121a.5.5 0 01.147.355V18a.5.5 0 01-.5.5h-8.344a.25.25 0 00-.221.366l.787 1.5a.25.25 0 00.221.134H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M7.78 12.272a1.449 1.449 0 00-2.561 0L.164 21.906a1.426 1.426 0 00.047 1.408A1.454 1.454 0 001.444 24h10.111a1.456 1.456 0 001.233-.686 1.428 1.428 0 00.047-1.409zM5.749 16a.75.75 0 011.5 0v2.5a.75.75 0 01-1.5 0zm.75 6.25a1 1 0 111-1 1 1 0 01-.999 1.001z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});