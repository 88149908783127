define("ember-svg-jar/inlined/shelf-tv-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shelf-tv-1</title><path d=\"M24 13.5a1.5 1.5 0 00-1.5-1.5h-9.25a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25H18a2 2 0 002-2V2a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2h4.75a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H1.5A1.5 1.5 0 000 13.5v.75a.5.5 0 00.5.5h23a.5.5 0 00.5-.5zm-18-6v-5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v5a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5zM23.5 16.25H13.25a.5.5 0 00-.5.5v6.75a.5.5 0 00.5.5h9.25a1.5 1.5 0 001.5-1.5v-5.75a.5.5 0 00-.5-.5zm-8 3.25a1 1 0 111-1 1 1 0 01-1 1zM10.75 16.25H.5a.5.5 0 00-.5.5v5.75A1.5 1.5 0 001.5 24h9.25a.5.5 0 00.5-.5v-6.75a.5.5 0 00-.5-.5zM8.5 19.5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});