define("ember-svg-jar/inlined/vide-document-mov", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vide-document-mov</title><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM9.125 15a.625.625 0 01-1.25 0v-2.978c0-.147-.15-.075-.19 0l-.126.252a.65.65 0 01-1.118 0l-.128-.257c-.061-.121-.188-.128-.188 0V15a.625.625 0 01-1.25 0V9a.625.625 0 011.184-.279l.738 1.475a.242.242 0 00.4 0l.74-1.479A.625.625 0 019.125 9zm5-1.5a2.125 2.125 0 01-4.25 0v-3a2.125 2.125 0 014.25 0zm5-3a8.176 8.176 0 01-1.625 4.875.625.625 0 01-1 0 8.176 8.176 0 01-1.625-4.875V9a.625.625 0 011.25 0v1.5a6.931 6.931 0 00.771 3.161.132.132 0 00.2.01 6.923 6.923 0 00.776-3.171V9a.625.625 0 011.25 0z\"/><path d=\"M12 9.625a.877.877 0 00-.875.875v3a.875.875 0 001.75 0v-3A.877.877 0 0012 9.625z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});