define("ember-svg-jar/inlined/underwear-corset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>underwear-corset</title><path d=\"M14.14 20.726l-.348-.29a.25.25 0 010-.384l.348-.29a1 1 0 10-1.28-1.536l-.7.583a.248.248 0 01-.32 0l-.7-.583a1 1 0 00-1.28 1.536l.348.29a.25.25 0 010 .384l-.348.29a1 1 0 001.28 1.536l.7-.583a.251.251 0 01.32 0l.7.583a1 1 0 101.28-1.536zM5.574 5.615a.252.252 0 00-.091-.191.248.248 0 00-.2-.053 6.819 6.819 0 00-.882.222 5.122 5.122 0 00-3.046 2.519 1 1 0 00-.032.875c.745 1.688 2.066 4.065 2.892 5.519.562.994-.019 1.905-1.095 3.43A8.881 8.881 0 00.99 23.11a.925.925 0 00.78.89h4.31a.5.5 0 00.464-.314l.72-1.8A14.1 14.1 0 007.347 11.6a14.367 14.367 0 01-1.773-5.985zM18.426 5.615a.252.252 0 01.091-.191.248.248 0 01.2-.053 6.874 6.874 0 01.883.222 5.123 5.123 0 013.041 2.519 1 1 0 01.032.875c-.745 1.688-2.066 4.065-2.892 5.519-.562.994.019 1.905 1.095 3.43a8.881 8.881 0 012.13 5.174.925.925 0 01-.78.89H17.92a.5.5 0 01-.464-.314l-.72-1.8a14.1 14.1 0 01-.083-10.286 14.367 14.367 0 001.773-5.985zM14.268 11.854a1 1 0 00-1.408-.128l-.7.583a.248.248 0 01-.32 0l-.7-.583a1 1 0 10-1.28 1.536l.348.29a.25.25 0 010 .384l-.348.29a1 1 0 101.28 1.536l.7-.583a.251.251 0 01.32 0l.7.583a1 1 0 101.28-1.536l-.348-.29a.25.25 0 010-.384l.348-.29a1 1 0 00.128-1.408z\"/><path d=\"M11.819 6.069a.247.247 0 00.181.078.251.251 0 00.181-.078 7.5 7.5 0 017.882-1.909 6.818 6.818 0 013.012 1.946.249.249 0 00.436-.16A5.767 5.767 0 0019.687.372a6.419 6.419 0 00-5.374.487 5.946 5.946 0 00-2.1 2.07.25.25 0 01-.43 0 5.959 5.959 0 00-2.1-2.07 6.422 6.422 0 00-5.37-.487A5.763 5.763 0 00.489 5.946a.249.249 0 00.436.16A6.818 6.818 0 013.937 4.16a7.5 7.5 0 017.882 1.909z\"/><path d=\"M11.137 8.181a.5.5 0 000-.608 5.972 5.972 0 00-3.774-2.239.251.251 0 00-.289.25A10.514 10.514 0 008.2 9.9a.5.5 0 00.688.215 7.216 7.216 0 002.249-1.934zM16.927 5.583a.255.255 0 00-.087-.192.249.249 0 00-.2-.057 5.971 5.971 0 00-3.774 2.24.5.5 0 000 .607 7.216 7.216 0 002.25 1.938.5.5 0 00.39.039.5.5 0 00.3-.254 10.527 10.527 0 001.121-4.321z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});