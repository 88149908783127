define("ember-svg-jar/inlined/surveillance-cctv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>surveillance-cctv</title><path d=\"M21 6a1 1 0 00-1 1v3a1 1 0 002 0V7a1 1 0 00-1-1z\"/><path d=\"M23 0H4.5A4.5 4.5 0 00.338 2.812.5.5 0 00.8 3.5h3.7a.5.5 0 010 1h-4A.5.5 0 000 5v1a.5.5 0 00.5.5h5a.5.5 0 010 1H.531a.5.5 0 00-.5.563A4.506 4.506 0 004.5 12h3.75a.5.5 0 01.5.5v4.25a1.556 1.556 0 01-1.5 1.75H3a.5.5 0 01-.5-.5v-1.25a1.25 1.25 0 00-2.5 0v6a1.25 1.25 0 002.5 0V21.5A.5.5 0 013 21h4.25a4.049 4.049 0 004-4.25V12.5a.5.5 0 01.5-.5h4.75c1 0 2-.773 2-2.5V6.6a.5.5 0 01.262-.44l4.768-2.589a1 1 0 00.47-.848V1a1 1 0 00-1-1zM10 9.5A1.5 1.5 0 1111.5 8 1.5 1.5 0 0110 9.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});