define("ember-svg-jar/inlined/move-up-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>move-up-1</title><path d=\"M12.455 23.249A1.25 1.25 0 0013.705 22V11.886a.25.25 0 01.25-.25h2.136a1 1 0 00.709-1.707l-3.638-3.637a1.029 1.029 0 00-1.414 0L8.111 9.929a1 1 0 00.707 1.707h2.137a.25.25 0 01.25.25V22a1.25 1.25 0 001.25 1.249zM23.705 2a1.25 1.25 0 00-1.25-1.25H1.545a1.25 1.25 0 000 2.5h20.91A1.25 1.25 0 0023.705 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});