define("ember-svg-jar/inlined/disability-blind-read", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-blind-read</title><path d=\"M23 8.252a1 1 0 00-1 1l-.018 8.289a.5.5 0 01-.472.5 16.254 16.254 0 00-8.123 2.366A.251.251 0 0113 20.2V8.944a1 1 0 10-2 0V20.2a.25.25 0 01-.387.209 16.3 16.3 0 00-8.142-2.369.5.5 0 01-.472-.5L1.981 4.048a.5.5 0 01.532-.5 15.87 15.87 0 015.809 1.363A1 1 0 009.178 3.1 18.283 18.283 0 002.06 1.523a1.96 1.96 0 00-1.443.55A2.026 2.026 0 000 3.522v14.5a2 2 0 001.943 2c7 .266 9.193 3.492 9.208 3.516a1 1 0 00.841.466H12a1.013 1.013 0 00.84-.457c.021-.033 2.214-3.259 9.214-3.525a2 2 0 001.943-2v-8.77a1 1 0 00-.997-1z\"/><circle cx=\"4.75\" cy=\"7.502\" r=\"1\"/><circle cx=\"8.75\" cy=\"7.502\" r=\"1\"/><circle cx=\"4.75\" cy=\"11.002\" r=\"1\"/><circle cx=\"8.75\" cy=\"11.002\" r=\"1\"/><circle cx=\"15.75\" cy=\"11.002\" r=\"1\"/><circle cx=\"19.75\" cy=\"14.502\" r=\"1\"/><circle cx=\"15.75\" cy=\"14.502\" r=\"1\"/><circle cx=\"4.75\" cy=\"14.502\" r=\"1\"/><path d=\"M17.633 8.362a7.685 7.685 0 006.078-3.3 1.488 1.488 0 000-1.761A7.687 7.687 0 0017.633 0a7.684 7.684 0 00-6.079 3.3 1.488 1.488 0 000 1.762 7.682 7.682 0 006.079 3.3zm3.079-5.288a6.671 6.671 0 01.854.767.5.5 0 010 .683A5.318 5.318 0 0117.5 6.355a.25.25 0 01-.171-.424l2.727-2.8a.5.5 0 01.656-.057zm-7.012.767a5.317 5.317 0 014.069-1.832.25.25 0 01.172.424l-2.73 2.805a.5.5 0 01-.658.052 6.678 6.678 0 01-.855-.767.5.5 0 01.002-.682z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});