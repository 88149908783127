define("ember-svg-jar/inlined/scooter-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>scooter-1</title><path d=\"M15.138 7.69a.251.251 0 00-.238 0 5.606 5.606 0 01-1.7.574.25.25 0 00-.2.246v3.865a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V8.507a.25.25 0 00-.205-.246 6.206 6.206 0 01-1.7-.566.252.252 0 00-.234 0 6.47 6.47 0 00-3.361 5.68v3.25a4.513 4.513 0 003.176 4.3.251.251 0 00.324-.236v-3.064a3 3 0 016 0v3.064a.252.252 0 00.1.2.248.248 0 00.222.037 4.5 4.5 0 003.176-4.3v-3.25a6.492 6.492 0 00-3.36-5.686z\"/><path d=\"M12 15.625a2.006 2.006 0 00-2 2v4a2 2 0 004 0v-4a2 2 0 00-2-2zM21.5 2.875a1 1 0 00-1-1h-4.708a.254.254 0 01-.182-.075A4.989 4.989 0 0012 .375 5 5 0 008.384 1.8a.25.25 0 01-.181.077H3.5a1 1 0 000 2h3.758a.25.25 0 01.249.227C7.654 5.648 9.28 7.375 12 7.375c2.675 0 4.344-1.669 4.49-3.272a.249.249 0 01.249-.228H20.5a1 1 0 001-1zm-8 1a1.5 1.5 0 11-1.5-1.5 1.5 1.5 0 011.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});