define("ember-svg-jar/inlined/real-estate-action-building-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-building-search</title><path d=\"M6.724 6.053l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 000 6.5V14a.5.5 0 00.5.5H2a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5A.5.5 0 007 14V6.5a.5.5 0 00-.276-.447zM5 10.5H2a.5.5 0 010-1h3a.5.5 0 010 1zM5 8H2a.5.5 0 010-1h3a.5.5 0 010 1zM11.877 10.818a.5.5 0 00-.466-.318H10a.5.5 0 010-1h3a.5.5 0 01.5.5.116.116 0 00.053.1.118.118 0 00.112.009 6.953 6.953 0 01.97-.351.5.5 0 00.365-.483V3a.5.5 0 00-.308-.462l-6-2.5A.5.5 0 008 .5V14a.5.5 0 00.5.5h.931a.5.5 0 00.471-.333 6.991 6.991 0 011.848-2.8.5.5 0 00.127-.549zM10 4.5h3a.5.5 0 010 1h-3a.5.5 0 010-1zM10 7h3a.5.5 0 010 1h-3a.5.5 0 010-1zM23.634 21.866l-2.2-2.2a.5.5 0 01-.087-.589A5.44 5.44 0 0022 16.5a5.5 5.5 0 10-5.5 5.5 5.44 5.44 0 002.58-.65.5.5 0 01.59.087l2.2 2.2a1.25 1.25 0 001.768-1.768zM16.5 20a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});