define("ember-svg-jar/inlined/cog-search-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cog-search-1</title><path d=\"M23.414 20.592s-5.05-5.049-5.076-5.072a10 10 0 10-2.824 2.825c.023.025 5.07 5.074 5.072 5.075a2.024 2.024 0 002.828 0 2 2 0 000-2.828zM10 18.006a8 8 0 118-8 8.009 8.009 0 01-8 8z\"/><path d=\"M15.048 10.7l-.463-.419a.371.371 0 010-.55l.463-.418a1.37 1.37 0 00-.988-2.386l-.623.032a.383.383 0 01-.281-.108.37.37 0 01-.108-.281l.032-.623a1.372 1.372 0 00-2.387-.989l-.417.463a.384.384 0 01-.552 0l-.417-.463a1.372 1.372 0 00-2.387.989l.032.623a.37.37 0 01-.389.389l-.623-.032a1.37 1.37 0 00-.988 2.386l.463.418a.369.369 0 01.122.275.373.373 0 01-.122.276l-.463.418a1.37 1.37 0 00.988 2.385l.623-.031a.376.376 0 01.281.107.372.372 0 01.108.282l-.032.623a1.371 1.371 0 002.387.988l.417-.462a.383.383 0 01.551 0l.418.463a1.371 1.371 0 002.387-.988l-.032-.623a.372.372 0 01.108-.282.352.352 0 01.281-.107l.623.031a1.37 1.37 0 00.988-2.385zm-3.23-.694A1.818 1.818 0 1110 8.188a1.819 1.819 0 011.818 1.818z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});