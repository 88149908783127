define("ember-svg-jar/inlined/3d-box-expand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>3d-box-expand</title><path d=\"M17.494 8.035a.25.25 0 000-.447l-5.2-2.6a.648.648 0 00-.582 0l-5.2 2.6a.25.25 0 000 .447l5.382 2.691a.252.252 0 00.224 0zM12.5 18.487a.25.25 0 00.362.223l5.279-2.639a.65.65 0 00.359-.582V9.055a.25.25 0 00-.362-.224l-5.5 2.75a.252.252 0 00-.138.224zM5.862 8.831a.25.25 0 00-.362.224v6.434a.65.65 0 00.359.582l5.279 2.639a.25.25 0 00.362-.223v-6.682a.252.252 0 00-.138-.224zM15 20H9a.5.5 0 00-.354.853l3 3a.5.5 0 00.708 0l3-3A.5.5 0 0015 20zM9 4h6a.5.5 0 00.354-.854l-3-3a.5.5 0 00-.708 0l-3 3A.5.5 0 009 4zM23.854 11.676l-3-3a.5.5 0 00-.854.353v6a.5.5 0 00.309.462.489.489 0 00.191.038.5.5 0 00.354-.146l3-3a.5.5 0 000-.707zM3.691 8.567a.5.5 0 00-.545.109l-3 3a.5.5 0 000 .707l3 3a.5.5 0 00.354.146.489.489 0 00.191-.038.5.5 0 00.309-.462v-6a.5.5 0 00-.309-.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});