define("ember-svg-jar/inlined/real-estate-action-house-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-house-pin</title><path d=\"M12.5 16a6.493 6.493 0 0110.972-4.708.308.308 0 00.275.077c.3-.067.326-.514.1-.732L13.031.41a1.5 1.5 0 00-2.059 0L.157 10.636A.5.5 0 00.5 11.5h2a.5.5 0 01.5.5v10a1 1 0 001 1h10.833a.5.5 0 00.4-.8C13.886 20.419 12.5 18.084 12.5 16z\"/><path d=\"M19 11a5.006 5.006 0 00-5 5c0 3.171 4.457 7.664 4.647 7.853a.5.5 0 00.707 0C19.543 23.663 24 19.17 24 16a5.006 5.006 0 00-5-5zm0 6.75A1.75 1.75 0 1120.75 16 1.753 1.753 0 0119 17.749z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});