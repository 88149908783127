define("ember-svg-jar/inlined/layout-corners-dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layout-corners-dashboard</title><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2zm-7.027 19.853a.5.5 0 01-.354.147H7.381a.5.5 0 01-.354-.147l-4.881-4.881A.5.5 0 012 16.619V7.381a.5.5 0 01.146-.354l4.881-4.881A.5.5 0 017.381 2h9.238a.5.5 0 01.354.146l4.881 4.882a.5.5 0 01.146.353v9.236a.5.5 0 01-.146.354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});