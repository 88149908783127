define("ember-svg-jar/inlined/picture-polaroid-landscape-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>picture-polaroid-landscape</title><path d=\"M12.8 8.2a.873.873 0 00-1.7-.038l-1.6 3.504a.25.25 0 01-.42.056l-1.092-1.314a1.163 1.163 0 00-1.967.153L3.535 14.7a.5.5 0 00.429.757h11a.5.5 0 00.464-.686z\"/><circle cx=\"5.964\" cy=\"5.5\" r=\"2\"/><path d=\"M17 19a2 2 0 002-2V2a2 2 0 00-2-2H2a2 2 0 00-2 2v15a2 2 0 002 2zM2 2.5a.5.5 0 01.5-.5h14a.5.5 0 01.5.5v14a.5.5 0 01-.5.5h-14a.5.5 0 01-.5-.5z\"/><path d=\"M23.992 9.235a2 2 0 00-1.812-2.172l-1-.091A1 1 0 1021 8.964l.5.044a.5.5 0 01.453.544L20.863 21.5a.5.5 0 01-.543.452L6.877 20.728a1 1 0 10-.177 1.991l13.941 1.274a2 2 0 002.174-1.811z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});