define("ember-svg-jar/inlined/music-ear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-ear</title><path d=\"M2 9.132a10.432 10.432 0 013.075-7.425A1 1 0 003.661.293a12.5 12.5 0 000 17.678 1 1 0 101.414-1.414A10.433 10.433 0 012 9.132z\"/><path d=\"M7.9 3.122a1 1 0 00-1.414 0 8.5 8.5 0 000 12.02A1 1 0 007.9 13.728a6.5 6.5 0 010-9.192 1 1 0 000-1.414zM23 17.5a1 1 0 00-1 1 3.5 3.5 0 01-7 0 6.649 6.649 0 00-2.191-4.938A5.28 5.28 0 0111 9.5a5.5 5.5 0 0111 0 1 1 0 002 0 7.5 7.5 0 00-15 0 7.169 7.169 0 002.33 5.41A4.728 4.728 0 0113 18.5a5.5 5.5 0 0011 0 1 1 0 00-1-1z\"/><path d=\"M16.5 7.5a2 2 0 012 2 1 1 0 002 0 4 4 0 00-8 0 3.672 3.672 0 001.594 3.063c.532.445.656.584.656.937a1 1 0 002 0 3.05 3.05 0 00-1.375-2.472c-.546-.456-.875-.73-.875-1.528a2 2 0 012-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});