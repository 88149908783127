define("ember-svg-jar/inlined/multiple-actions-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-video</title><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.273 12.25a.248.248 0 00.242.187h6.235a.25.25 0 00.25-.25V11.9a4.456 4.456 0 00-4.5-4.4 4.53 4.53 0 00-3.6 1.76.25.25 0 00.005.311 6.987 6.987 0 011.368 2.679zM9.5 15.438a2.991 2.991 0 011.257-2.433.25.25 0 00.1-.26A5.5 5.5 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.56a.25.25 0 00.25-.25z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M23.371 15.517l-2.807.748a.25.25 0 01-.314-.241V15.5a1.5 1.5 0 00-1.5-1.5H12.5a1.5 1.5 0 00-1.5 1.5v7a1.5 1.5 0 001.5 1.5h6.25a1.5 1.5 0 001.5-1.5v-.524a.25.25 0 01.314-.241l2.806.748a.492.492 0 00.433-.087A.5.5 0 0024 22v-6a.5.5 0 00-.629-.483z\"/><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.273 12.25a.248.248 0 00.242.187h6.235a.25.25 0 00.25-.25V11.9a4.456 4.456 0 00-4.5-4.4 4.53 4.53 0 00-3.6 1.76.25.25 0 00.005.311 6.987 6.987 0 011.368 2.679zM9.5 15.438a2.991 2.991 0 011.257-2.433.25.25 0 00.1-.26A5.5 5.5 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.56a.25.25 0 00.25-.25z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M23.371 15.517l-2.807.748a.25.25 0 01-.314-.241V15.5a1.5 1.5 0 00-1.5-1.5H12.5a1.5 1.5 0 00-1.5 1.5v7a1.5 1.5 0 001.5 1.5h6.25a1.5 1.5 0 001.5-1.5v-.524a.25.25 0 01.314-.241l2.806.748a.492.492 0 00.433-.087A.5.5 0 0024 22v-6a.5.5 0 00-.629-.483z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});