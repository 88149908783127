define("ember-svg-jar/inlined/button-play-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-play-1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm4.828 12.894l-7.381 3.69A1 1 0 018 15.69V8.308a1 1 0 011.446-.895l7.383 3.687a1 1 0 01-.001 1.793z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});