define("ember-svg-jar/inlined/single-woman-id-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-id-card</title><path d=\"M10 6.5h4a1 1 0 001-1V3A3.007 3.007 0 0011.192.108 3.044 3.044 0 009 3.087V5.5a1 1 0 001 1zm1.25-3.75a.75.75 0 11.75.75.75.75 0 01-.75-.751z\"/><path d=\"M21.5 5a2 2 0 00-2-2h-2.75a.251.251 0 00-.25.25v1.5a.249.249 0 00.25.25H19a.5.5 0 01.5.5v16a.5.5 0 01-.5.5H5a.5.5 0 01-.5-.5v-16A.5.5 0 015 5h2.25a.249.249 0 00.25-.25v-1.5A.251.251 0 007.25 3H4.5a2 2 0 00-2 2v17a2 2 0 002 2h15a2 2 0 002-2z\"/><path d=\"M7.444 16.488a1 1 0 001.384-.288q.041-.065.078-.132a.251.251 0 01.378-.073 4.275 4.275 0 005.412 0 .251.251 0 01.378.072q.036.068.078.132a1 1 0 101.676-1.099 3.3 3.3 0 01-.468-1.829V12.3a4.37 4.37 0 00-8.74 0v.972a3.315 3.315 0 01-.468 1.828 1 1 0 00.292 1.388zm4.546-1.531a2.235 2.235 0 01-2.21-1.836.25.25 0 01.17-.282 5.334 5.334 0 001.877-1.039.251.251 0 01.326 0 5.347 5.347 0 001.876 1.037.25.25 0 01.171.282 2.233 2.233 0 01-2.21 1.838zM11.992 17.457A5.688 5.688 0 007.6 19.641a.5.5 0 00.388.816h8a.5.5 0 00.388-.816 5.68 5.68 0 00-4.384-2.184z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});