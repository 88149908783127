define("ember-svg-jar/inlined/casino-diamond-bag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>casino-diamond-bag</title><path d=\"M13.112 2.229A1.5 1.5 0 0011.8 0H6.2a1.5 1.5 0 00-1.291 2.263l1.675 2.514A.5.5 0 007 5h4a.5.5 0 00.416-.223zM9 17.25a8.264 8.264 0 015.7-7.85.251.251 0 00.109-.4 9.33 9.33 0 00-3.252-2.421 1 1 0 00-.389-.079H6.837a1 1 0 00-.393.08C3.3 7.921 0 12.239 0 17c0 4.448 3.28 7 9 7a15.927 15.927 0 002.6-.2.25.25 0 00.127-.432A8.229 8.229 0 019 17.25z\"/><path d=\"M17.25 10.5A6.75 6.75 0 1024 17.25a6.758 6.758 0 00-6.75-6.75zm-4.75 6.75a4.73 4.73 0 01.338-1.761.255.255 0 01.16-.146.252.252 0 01.215.033l1.283.885a.251.251 0 01.1.272 2.753 2.753 0 000 1.434.251.251 0 01-.1.272l-1.283.885a.25.25 0 01-.214.033.254.254 0 01-.161-.146 4.73 4.73 0 01-.338-1.761zm7.405-.717a.251.251 0 01.1-.272l1.283-.885a.25.25 0 01.214-.033.254.254 0 01.161.146 4.756 4.756 0 010 3.522.255.255 0 01-.16.146.252.252 0 01-.215-.033L20 18.239a.251.251 0 01-.1-.272 2.753 2.753 0 000-1.434zm.556-2.78a.251.251 0 01-.027.39l-1.281.884a.249.249 0 01-.289 0 2.776 2.776 0 00-.7-.366.249.249 0 01-.164-.239v-1.56a.25.25 0 01.094-.2.256.256 0 01.212-.049 4.746 4.746 0 012.155 1.14zm-4.267-1.134a.25.25 0 01.306.243v1.56a.25.25 0 01-.167.235 2.776 2.776 0 00-.7.366.249.249 0 01-.289 0l-1.281-.884a.25.25 0 01-.108-.188.253.253 0 01.081-.2 4.735 4.735 0 012.158-1.132zm-2.155 8.128a.251.251 0 01.027-.39l1.281-.884a.249.249 0 01.289 0 2.776 2.776 0 00.7.366.249.249 0 01.167.235v1.56a.25.25 0 01-.094.195.256.256 0 01-.212.049 4.746 4.746 0 01-2.158-1.131zm4.267 1.134a.25.25 0 01-.306-.243v-1.56a.25.25 0 01.167-.235 2.776 2.776 0 00.7-.366.249.249 0 01.289 0l1.281.884a.25.25 0 01.108.188.253.253 0 01-.081.2 4.735 4.735 0 01-2.158 1.132z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});