define("ember-svg-jar/inlined/body-care-sun-cream", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>body-care-sun-cream</title><path d=\"M16 20H8a.5.5 0 00-.5.5v2A1.5 1.5 0 009 24h6a1.5 1.5 0 001.5-1.5v-2a.5.5 0 00-.5-.5zM18.83.623A1.5 1.5 0 0017.612 0H6.388a1.5 1.5 0 00-1.424 1.975l.51 1.525a.5.5 0 01.026.158v3.989a28.428 28.428 0 002.03 10.524.5.5 0 00.47.329h8a.5.5 0 00.47-.329A28.454 28.454 0 0018.5 7.648V3.662a.5.5 0 01.026-.158l.51-1.529A1.512 1.512 0 0018.83.623zm-4.355 5.695l.707-.707a.5.5 0 01.707.707l-.707.707a.5.5 0 01-.707-.707zM11.5 4.5a.5.5 0 011 0v1a.5.5 0 01-1 0zM7 10a.5.5 0 010-1h1a.5.5 0 010 1zm2.525 2.682l-.707.707a.5.5 0 01-.707-.707l.707-.707a.5.5 0 01.707.707zm0-5.657a.5.5 0 01-.707 0l-.707-.707a.5.5 0 01.707-.707l.707.707a.5.5 0 010 .707zM12.5 14.5a.5.5 0 01-1 0v-1a.5.5 0 011 0zm-.5-3a2 2 0 112-2 2 2 0 01-2 2zm3.889 1.889a.5.5 0 01-.707 0l-.707-.707a.5.5 0 01.707-.707l.707.707a.5.5 0 010 .707zM17 10h-1a.5.5 0 010-1h1a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});