define("ember-svg-jar/inlined/time-clock-file-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-clock-file-shield</title><path d=\"M23.414 3L21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.267a.248.248 0 00.244.25c.368 0 1.045.021 1.5.047A.246.246 0 008 9.315V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.121a.5.5 0 01.146.355V18a.5.5 0 01-.5.5h-7.01a.242.242 0 00-.235.183 8.16 8.16 0 01-.53 1.46.245.245 0 00.218.356H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M11.308 10.2a11.232 11.232 0 011.365.465 3.035 3.035 0 011.76 2.184.638.638 0 00.262.326 4.635 4.635 0 00.8.074A4.75 4.75 0 1010.75 8.5a4.55 4.55 0 00.2 1.322.98.98 0 00.358.378zM15.5 5.251A3.25 3.25 0 1112.25 8.5a3.254 3.254 0 013.25-3.249zM12.072 12.041A16.381 16.381 0 006.479 11a15.983 15.983 0 00-5.551 1.04A1.545 1.545 0 000 13.457v3.392a7.716 7.716 0 005.19 6.806A9 9 0 006.479 24a9.023 9.023 0 001.288-.346C10.848 22.481 13 19.682 13 16.849v-3.392a1.545 1.545 0 00-.928-1.416zM9.5 17.251h-2a.25.25 0 00-.25.25v2a.75.75 0 01-1.5 0v-2a.25.25 0 00-.25-.25h-2a.75.75 0 110-1.5h2a.25.25 0 00.25-.25v-2a.75.75 0 111.5 0v2a.25.25 0 00.25.25h2a.75.75 0 110 1.5z\"/><path d=\"M15.5 9.251h1.768a.75.75 0 100-1.5H16.5a.252.252 0 01-.25-.251v-.767a.75.75 0 00-1.5 0V8.5a.75.75 0 00.75.751z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});