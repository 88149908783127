define("ember-svg-jar/inlined/upload-button-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>upload-button-alternate</title><path d=\"M20.5 9a2 2 0 00-2-2H16a1 1 0 00-1 1 1 1 0 001 1h2.25a.25.25 0 01.25.25v12.5a.25.25 0 01-.25.25H5.75a.25.25 0 01-.25-.25V9.249A.25.25 0 015.75 9H8a1 1 0 001-1 1 1 0 00-1-1H5.5a2 2 0 00-2 2v13a2 2 0 002 2h13a2 2 0 002-2z\"/><path d=\"M10.5 13.5a1.5 1.5 0 103 0V5.749a.25.25 0 01.25-.25h1.75a1 1 0 00.707-1.707l-3.5-3.5a1 1 0 00-1.414 0l-3.5 3.5A1 1 0 008.5 5.5h1.75a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});