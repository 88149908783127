define("ember-svg-jar/inlined/transfusion-bag-hang", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transfusion-bag-hang</title><path d=\"M17.25 0h-7a2 2 0 00-2 2v1a1 1 0 01-2 0V2a1 1 0 00-2 0v1a3 3 0 006 0v-.5a.5.5 0 01.5-.5h6.5a3 3 0 013 3v18a1 1 0 002 0V5a5.006 5.006 0 00-5-5z\"/><path d=\"M10 7H4.5a2.752 2.752 0 00-2.75 2.75V19a2.5 2.5 0 002.5 2.5h1.5a.5.5 0 01.5.5v1a1 1 0 002 0v-1a.5.5 0 01.5-.5h1.5a2.5 2.5 0 002.5-2.5V9.75A2.752 2.752 0 0010 7zM7.25 18.965a1.978 1.978 0 01-2-1.956c0-.826 1.168-2.752 1.719-3.613a.333.333 0 01.562 0c.551.861 1.719 2.787 1.719 3.613a1.978 1.978 0 01-2 1.956zm3.5-8.47a.5.5 0 01-.5.5h-6a.5.5 0 01-.5-.5v-.245A1.252 1.252 0 015 9h4.5a1.252 1.252 0 011.25 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});