define("ember-svg-jar/inlined/history-man-chinese-elder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-man-chinese-elder</title><path d=\"M17 22.4a1 1 0 01-1.316.948 3.613 3.613 0 01-2.77-3.538C12.832 19 12.832 19 12 19s-.843 0-.931.831a3.612 3.612 0 01-2.753 3.518A1 1 0 017 22.4c0-.848-.041-2.039.035-3.061a.25.25 0 00-.307-.262 13.913 13.913 0 00-5.261 2.339A2.462 2.462 0 00.5 23.394v.106a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-.106a2.464 2.464 0 00-.967-1.977 13.915 13.915 0 00-5.26-2.339.25.25 0 00-.307.262c.076 1.032.034 2.248.034 3.06z\"/><path d=\"M8 22.042a.247.247 0 00.112.207.25.25 0 00.234.022C11.491 20.962 8.616 18 12 18s.467 2.952 3.656 4.272a.25.25 0 00.344-.229 20.275 20.275 0 00-.14-3.543.249.249 0 01.1-.25 6.832 6.832 0 00.737-.579A6.987 6.987 0 0019 12.5v-2a6.962 6.962 0 00-1.72-4.586.25.25 0 01.189-.414H18a1 1 0 000-2h-2.76a.252.252 0 01-.184-.08.257.257 0 01-.066-.19C15 3.154 15 3.077 15 3a3 3 0 00-6 0c0 .077 0 .154.01.23a.252.252 0 01-.25.271H6a1 1 0 000 2h.531a.25.25 0 01.189.414A6.962 6.962 0 005 10.5v2a7.039 7.039 0 003.037 5.753.25.25 0 01.1.249A20.341 20.341 0 008 22.042zm.3-6.2A5.01 5.01 0 017 12.5v-.951a.251.251 0 01.243-.25 7 7 0 004.694-1.692.1.1 0 01.126 0 7 7 0 004.694 1.693.251.251 0 01.243.25v.95a4.987 4.987 0 01-1.3 3.345 5.227 5.227 0 01-.555.538.249.249 0 01-.328-.008A4 4 0 0012 15.5a4 4 0 00-2.816.872.252.252 0 01-.327.009 5.164 5.164 0 01-.557-.537z\"/><circle cx=\"14\" cy=\"13\" r=\"1\"/><circle cx=\"10\" cy=\"13\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});