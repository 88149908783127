define("ember-svg-jar/inlined/image-file-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-star</title><path d=\"M12.607 17.138a1.264 1.264 0 00.3-1.378 1.234 1.234 0 00-1.169-.76h-2.5a.251.251 0 01-.229-.151l-1.345-3.118-.013-.031a1.293 1.293 0 00-2.317.027l-1.346 3.121a.252.252 0 01-.23.151h-2.5a1.231 1.231 0 00-1.166.759 1.272 1.272 0 00.32 1.4L2.6 19.11a.249.249 0 01.063.286L1.444 22.21a1.255 1.255 0 00.314 1.452 1.358 1.358 0 001.506.166l3.112-1.753a.249.249 0 01.245 0l3.113 1.753a1.277 1.277 0 001.506-.166 1.255 1.255 0 00.315-1.451L10.332 19.4a.249.249 0 01.063-.286zM14.68 5.538a1.493 1.493 0 10-1.493 1.492 1.492 1.492 0 001.493-1.492zM11.122 12.9a.187.187 0 00.163.1h9.028a.186.186 0 00.158-.286l-3.236-5.178a.374.374 0 00-.633 0l-1.81 2.9a.188.188 0 01-.317 0L13.831 9.4a.373.373 0 00-.633 0l-2.071 3.312a.187.187 0 00-.005.188z\"/><path d=\"M23.415 3L21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v6.792a.252.252 0 00.232.249 8.446 8.446 0 011.452.211.248.248 0 00.316-.24V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.147l2.122 2.121a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-7.335a.232.232 0 00-.163.06c-.218.2-.922.832-1.207 1.089a.247.247 0 00-.051.273l.183.423a.29.29 0 00.269.154H22a2 2 0 002-2V4.414A2 2 0 0023.415 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});