define("ember-svg-jar/inlined/vr-user-headphones", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vr-user-headphones</title><path d=\"M22.491 11.583a10.5 10.5 0 00-20.982 0A1.5 1.5 0 00.5 13v3A1.5 1.5 0 002 17.5h1.5A.5.5 0 004 17v-5a.5.5 0 00-.485-.5 8.5 8.5 0 0116.97 0A.5.5 0 0020 12v5a.5.5 0 00.5.5H22a1.5 1.5 0 001.5-1.5v-3a1.5 1.5 0 00-1.009-1.417z\"/><path d=\"M12 7a7.02 7.02 0 00-6.584 4.619.981.981 0 00-.055.246A1.99 1.99 0 005 13.01v3a2 2 0 002 2h2.5a1 1 0 00.8-.4l.9-1.2a1 1 0 011.6 0l.9 1.2a1 1 0 00.8.4H17a2 2 0 002-2v-3a2 2 0 00-.307-1.065A7.013 7.013 0 0012 7zm-2.5 7.01h-2a.5.5 0 110-1h2a.5.5 0 010 1zm3 0h-1a.5.5 0 010-1h1a.5.5 0 010 1zm-3.971-3a.25.25 0 01-.183-.421 5 5 0 017.31 0 .25.25 0 01-.183.421zM15.052 19.458a5.067 5.067 0 01-6.1 0 1 1 0 10-1.222 1.583 6.99 6.99 0 008.548 0 1 1 0 10-1.222-1.583z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});