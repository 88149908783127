define("ember-svg-jar/inlined/gardening-watering-can", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gardening-watering-can</title><path d=\"M24 9.593a6.006 6.006 0 00-6-6h-4.25c-2.4 0-5.5 1.354-5.5 3.75v1.666a.25.25 0 01-.44.162L4.847 5.686a.571.571 0 01.182-.886 1 1 0 10-.887-1.793L.557 4.785a1 1 0 00.886 1.793l.349-.172a.572.572 0 01.754.236L8 16.567a2 2 0 01.247.963v.813A2.753 2.753 0 0011 21.093h6a2.752 2.752 0 002.75-2.75v-2.652a.5.5 0 01.327-.469A6.009 6.009 0 0024 9.593zm-3.866 3.382a.25.25 0 01-.384-.211v-3A1.752 1.752 0 0018 8.015a2.92 2.92 0 01-3.026-2.1.252.252 0 01.036-.215.249.249 0 01.2-.1H18a4 4 0 012.134 7.382z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});