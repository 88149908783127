define("ember-svg-jar/inlined/developer-community-stack-overflow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>developer-community-stack-overflow</title><path d=\"M15.8 24h-11a3 3 0 01-3-3v-6.5a1 1 0 012 0V21a1 1 0 001 1h11a1 1 0 001-1v-6.5a1 1 0 012 0V21a3 3 0 01-3 3z\"/><path d=\"M14.3 20h-8a1 1 0 010-2h8a1 1 0 110 2zM14.68 17.27h-.21l-7.82-1.68a1 1 0 01.42-2l7.83 1.66a1 1 0 01-.21 2zM15.62 14.72a1 1 0 01-.41-.09L7.9 11.39a1 1 0 11.81-1.83L16 12.81a1 1 0 01-.41 1.91zM17.06 12.43a1 1 0 01-.59-.19L10 7.54a1 1 0 111.17-1.62l6.48 4.69a1 1 0 01-.59 1.81zM19 10.48a1 1 0 01-.74-.33l-5.41-5.94a1 1 0 111.48-1.34l5.36 5.94a1 1 0 01-.69 1.67zM21.21 9a1 1 0 01-.87-.5l-4-6.92a1 1 0 111.73-1l4 6.92a1 1 0 01-.86 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});