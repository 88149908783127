define("ember-svg-jar/inlined/color-tubes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-tubes</title><path d=\"M.25 20a.25.25 0 00-.25.25v2.25A1.5 1.5 0 001.5 24h4A1.5 1.5 0 007 22.5v-2.25a.25.25 0 00-.25-.25zM6.75 10A.25.25 0 007 9.75V7.5A1.5 1.5 0 005.5 6h-4A1.5 1.5 0 000 7.5v2.25a.25.25 0 00.25.25z\"/><rect y=\"11\" width=\"7\" height=\"8\" rx=\".25\" ry=\".25\"/><path d=\"M1.5 5h4a.5.5 0 00.483-.629l-.8-3.005a1.72 1.72 0 00-3.363-.014l-.8 3.019A.5.5 0 001.5 5z\"/><rect x=\"8.5\" y=\"11\" width=\"7\" height=\"8\" rx=\".25\" ry=\".25\"/><path d=\"M15.25 10a.25.25 0 00.25-.25V7.5A1.5 1.5 0 0014 6h-4a1.5 1.5 0 00-1.5 1.5v2.25a.25.25 0 00.25.25zM8.75 20a.25.25 0 00-.25.25v2.25A1.5 1.5 0 0010 24h4a1.5 1.5 0 001.5-1.5v-2.25a.25.25 0 00-.25-.25zM10 5h4a.5.5 0 00.483-.629l-.8-3.005a1.72 1.72 0 00-3.363-.014l-.8 3.019A.5.5 0 0010 5z\"/><rect x=\"17\" y=\"11\" width=\"7\" height=\"8\" rx=\".25\" ry=\".25\"/><path d=\"M23.75 10a.25.25 0 00.25-.25V7.5A1.5 1.5 0 0022.5 6h-4A1.5 1.5 0 0017 7.5v2.25a.25.25 0 00.25.25zM17.25 20a.25.25 0 00-.25.25v2.25a1.5 1.5 0 001.5 1.5h4a1.5 1.5 0 001.5-1.5v-2.25a.25.25 0 00-.25-.25zM18.5 5h4a.5.5 0 00.483-.629l-.8-3.005a1.72 1.72 0 00-3.363-.014l-.8 3.019A.5.5 0 0018.5 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});