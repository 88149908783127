define("ember-svg-jar/inlined/pathfinder-intersect-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pathfinder-intersect-alternate</title><path d=\"M24 9a2 2 0 00-2-2h-4.75a.25.25 0 01-.25-.25V2a2 2 0 00-2-2H2a2 2 0 00-2 2v13a2 2 0 002 2h4.75a.25.25 0 01.25.25V22a2 2 0 002 2h13a2 2 0 002-2zM2.5 15a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h12a.5.5 0 01.5.5v4.25a.25.25 0 01-.25.25H9a2 2 0 00-2 2v5.75a.25.25 0 01-.25.25zM22 21.5a.5.5 0 01-.5.5h-12a.5.5 0 01-.5-.5v-4.25a.25.25 0 01.25-.25H15a2 2 0 002-2V9.25a.25.25 0 01.25-.25h4.25a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});