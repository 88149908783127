define("ember-svg-jar/inlined/products-shopping-bags-double", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>products-shopping-bags-double</title><path d=\"M11.358 21.2l1.125-6a3 3 0 011.973-2.283.252.252 0 00.167-.209A3.46 3.46 0 0116 10.339a.251.251 0 00.1-.2V8.821a1.616 1.616 0 00-1.652-1.571h-.6a.25.25 0 00-.25.25v1.25a.75.75 0 01-1.5 0v-4a4 4 0 00-8 0v4a.75.75 0 01-1.5 0V7.5a.25.25 0 00-.25-.25h-.6A1.616 1.616 0 00.1 8.821v12.858a1.616 1.616 0 001.656 1.571h9.55a.25.25 0 00.231-.346 2.99 2.99 0 01-.179-1.704zM6.1 4.75a2 2 0 014 0V7a.25.25 0 01-.25.25h-3.5A.25.25 0 016.1 7z\"/><path d=\"M22.747 15.474a1.5 1.5 0 00-1.475-1.224H21.1a.25.25 0 01-.25-.25v-.926a2.393 2.393 0 00-2.5-2.262 2.393 2.393 0 00-2.5 2.262V14a.25.25 0 01-.25.25h-.17a1.5 1.5 0 00-1.474 1.224l-1.125 6a1.5 1.5 0 001.474 1.776H22.4a1.5 1.5 0 001.475-1.776zm-4.395-3.162c.542 0 1 .349 1 .762V14a.25.25 0 01-.25.25H17.6a.25.25 0 01-.25-.25v-.926c.002-.413.46-.762 1.002-.762z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});