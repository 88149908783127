define("ember-svg-jar/inlined/power-tools-wood-cutter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>power-tools-wood-cutter</title><path d=\"M22 13h-.989a.25.25 0 00-.218.128 9.536 9.536 0 01-3.253 3.41.25.25 0 00.133.462H22a2 2 0 000-4z\"/><path d=\"M2.5 14H3a.5.5 0 01.5.5v2.25a.755.755 0 00.75.75h.5a.755.755 0 00.75-.75V14.5A.5.5 0 016 14h.5a.5.5 0 01.5.5V16a1 1 0 001 1h4.5a8.5 8.5 0 000-17H6a4.507 4.507 0 00-4.5 4.5V13a1 1 0 001 1zm11-5.25H18a.75.75 0 010 1.5h-4.5a.75.75 0 010-1.5zm0 3H16a.75.75 0 010 1.5h-2.5a.75.75 0 010-1.5z\"/><path d=\"M17 18.5H2.5A.5.5 0 012 18a1 1 0 00-2 0 2.5 2.5 0 002.5 2.5H17a1 1 0 000-2zM5 21.5H4a.5.5 0 00-.5.5v1a1 1 0 002 0v-1a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});