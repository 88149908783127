define("ember-svg-jar/inlined/diagram-arrow-dash-corner-point-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-arrow-dash-corner-point-right</title><path d=\"M23.531 6.428L16.9.267A1 1 0 0015.22 1v3.25a.25.25 0 01-.25.25H2a2 2 0 00-2 2V14a1 1 0 001 1h4a1 1 0 001-1v-3.25a.25.25 0 01.25-.25h8.72a.25.25 0 01.25.25V14a1 1 0 00.6.917 1.017 1.017 0 00.4.083 1 1 0 00.68-.268l6.639-6.167a1.46 1.46 0 00-.008-2.137z\"/><rect y=\"16.5\" width=\"6\" height=\"3\" rx=\"1\" ry=\"1\"/><rect y=\"21\" width=\"6\" height=\"3\" rx=\"1\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});