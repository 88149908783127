define("ember-svg-jar/inlined/zip-file-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>zip-file-2</title><path d=\"M23.707 5.707L18.293.293A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.75V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707z\"/><path d=\"M13.5 12.128h-.623a.252.252 0 00-.252.252v1.246a.252.252 0 00.252.252h.623a.875.875 0 000-1.75z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zm-12-.874H3.5a1.127 1.127 0 01-.936-1.75l2.464-3.866a.25.25 0 00-.211-.384H3a.625.625 0 010-1.25h2.5a1.126 1.126 0 01.936 1.75l-2.464 3.866a.25.25 0 00.211.384H6a.625.625 0 010 1.25zm3.877-1.25H10a.625.625 0 010 1.25H8a.625.625 0 010-1.25h.126a.249.249 0 00.249-.249v-4.249a.252.252 0 00-.252-.252H8a.625.625 0 110-1.25h2a.625.625 0 010 1.25h-.126a.249.249 0 00-.249.249v4.249a.252.252 0 00.252.252zm3.623-1.752h-.623a.252.252 0 00-.252.252V17.5a.625.625 0 01-1.25 0v-6a.625.625 0 01.625-.626h1.5a2.125 2.125 0 010 4.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});