define("ember-svg-jar/inlined/basketball-ball-dribble-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>basketball-ball-dribble-player</title><path d=\"M6.149 15.485a2.484 2.484 0 01-2.113-1.173.25.25 0 00-.291-.1 3.494 3.494 0 104.6 3.308 3.454 3.454 0 00-.726-2.106.25.25 0 00-.24-.1 7.115 7.115 0 01-1.23.171z\"/><circle cx=\"12.345\" cy=\"2.515\" r=\"2.5\"/><path d=\"M21.745 14.709a1.5 1.5 0 00.788-1.969l-1.01-2.357a4.527 4.527 0 00-2.468-2.407L16.51 6.96a5.318 5.318 0 00-2.238-.492 5.5 5.5 0 00-4.745 2.746l-.964 1.3a1.491 1.491 0 01-1.048.724l-1.615.267a1.5 1.5 0 00-1.23 1.727 1.516 1.516 0 001.73 1.232l1.608-.264a4.487 4.487 0 003.147-2.171c.237-.319.911-1.28 1.376-1.795a.249.249 0 01.421.085l1.168 3.347a.25.25 0 01-.115.3l-1.536.853a4.505 4.505 0 00-2.315 3.934v3.735a1.5 1.5 0 003 0V18.75a1.5 1.5 0 01.772-1.311l1.135-.631a.25.25 0 01.207-.016.247.247 0 01.148.145l2.346 6.1a1.5 1.5 0 102.785-1.113l-4.265-11.375a.25.25 0 01.327-.32l1.334.534a1.513 1.513 0 01.823.8l1.009 2.357a1.5 1.5 0 001.97.789z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});