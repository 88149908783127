define("ember-svg-jar/inlined/school-teacher-blind", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>school-teacher-blind</title><path d=\"M9.73 10.463a6.964 6.964 0 005.52 3.037 6.96 6.96 0 005.519-3.037 1.221 1.221 0 000-1.426A6.964 6.964 0 0015.25 6a6.96 6.96 0 00-5.519 3.037 1.221 1.221 0 00-.001 1.426zm8.383-1.992a6.5 6.5 0 011.045.949.5.5 0 010 .661A5.252 5.252 0 0115.25 12a4.009 4.009 0 01-.534-.037.249.249 0 01-.147-.42l2.891-3.012a.5.5 0 01.653-.06zm-6.772.948A5.252 5.252 0 0115.25 7.5a4.158 4.158 0 01.533.036.251.251 0 01.148.421l-2.891 3.012a.5.5 0 01-.653.06 6.5 6.5 0 01-1.045-.949.5.5 0 01-.001-.661z\"/><path d=\"M21 0h-8.5a1 1 0 000 2H21a1 1 0 011 1v13a1 1 0 01-1 1h-6a1 1 0 000 2h6a3 3 0 003-3V3a3 3 0 00-3-3zM11 13.5a1 1 0 00-1 1v1.25a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-2a4.005 4.005 0 012.667-3.771.245.245 0 01.227.031.249.249 0 01.106.2v3.54a1 1 0 002 0V9.261a.5.5 0 01.35-.477 4.5 4.5 0 10-4.12-.749.248.248 0 01.1.219.251.251 0 01-.131.2A6 6 0 000 13.75v3.5a1 1 0 001 1h1.121a.5.5 0 01.5.458L3 23.312a.749.749 0 00.75.688h4.5a.749.749 0 00.75-.688l.384-4.6a.5.5 0 01.5-.458H11a1 1 0 001-1V14.5a1 1 0 00-1-1zM3.6 3.818a.25.25 0 01.367-.147A5.964 5.964 0 007 4.5a5.784 5.784 0 001.186-.125.254.254 0 01.214.055.247.247 0 01.087.2A2.495 2.495 0 113.6 3.818z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});