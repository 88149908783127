define("ember-svg-jar/inlined/rain-cloud-umbrella", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rain-cloud-umbrella</title><path d=\"M24 7.25a3.248 3.248 0 00-2.335-3.112.251.251 0 01-.178-.221 4.25 4.25 0 00-8.312-.876.25.25 0 01-.159.165 3.753 3.753 0 00-2.372 4.573.247.247 0 00.162.169c.242.079.48.169.712.268a.25.25 0 00.322-.116l.246-.494a.25.25 0 00.016-.186A2.249 2.249 0 0113.9 4.527a.747.747 0 00.627-.642A2.751 2.751 0 0120 4.25a2.858 2.858 0 01-.028.395.755.755 0 00.193.6.789.789 0 00.585.258 1.749 1.749 0 011.46 2.713.267.267 0 00-.032.071 2.018 2.018 0 01-.139.361l-.689 1.378a.249.249 0 00.292.352A3.254 3.254 0 0024 7.25z\"/><path d=\"M13.753 9.584a.248.248 0 00.211.053.252.252 0 00.173-.133l.835-1.672A.239.239 0 0015 7.706a.749.749 0 00-1.419-.291l-.648 1.3a.25.25 0 00.084.319 9.046 9.046 0 01.736.55zM16.777 10.374a.234.234 0 00.02-.058 2.018 2.018 0 01.164-.461l1.011-2.023A.239.239 0 0018 7.706a.749.749 0 00-1.419-.291l-1.5 3a.747.747 0 00-.081.355.255.255 0 00.054.148c.117.146.228.3.335.45a.251.251 0 00.126.094.749.749 0 00.906-.377zM20.921 8.085a.75.75 0 10-1.342-.67l-1.5 3a.75.75 0 00.336 1.006.741.741 0 00.334.079.752.752 0 00.672-.415zM7 21.5a2.5 2.5 0 005 0 1 1 0 00-2 0 .5.5 0 01-1 0v-3.25a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25zM7 8.345a.25.25 0 01-.212.247A8.013 8.013 0 000 16.5a.5.5 0 00.5.5h15a.5.5 0 00.5-.5 8.013 8.013 0 00-6.788-7.908A.25.25 0 019 8.345V8a1 1 0 10-2 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});