define("ember-svg-jar/inlined/farming-field-sun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>farming-field-sun</title><path d=\"M12 3a1 1 0 001-1V1a1 1 0 00-2 0v1a1 1 0 001 1zM5.814 4.978a1 1 0 001.415-1.414l-.709-.708A1 1 0 005.106 4.27zM3.25 10.75h1a1 1 0 000-2h-1a1 1 0 000 2zM18.186 4.978l.708-.708a1 1 0 00-1.414-1.414l-.709.708a1 1 0 001.415 1.414zM18.748 9.75a1 1 0 001 1h1a1 1 0 000-2h-1a1 1 0 00-1 1z\"/><path d=\"M6.459 11.289a1 1 0 001.927-.535 3.75 3.75 0 117.228 0 1 1 0 001.927.535 5.75 5.75 0 10-11.082 0zM5.373 13.886a.248.248 0 00-.269-.11 17.413 17.413 0 00-4.33 1.607A1.5 1.5 0 000 16.7v3.392a.25.25 0 00.444.157l4.912-6.068a.247.247 0 00.017-.295zM12.524 13.426A.535.535 0 0012 13a.534.534 0 00-.523.426L9.413 23.4a.5.5 0 00.49.6H14.1a.5.5 0 00.49-.6zM8.929 13.148a.428.428 0 00-.295.158l-8.013 9.9A.488.488 0 001 24h5.839a.5.5 0 00.489-.4l2.058-9.944a.425.425 0 00-.457-.509zM23.226 15.383a17.413 17.413 0 00-4.33-1.607.25.25 0 00-.252.4l4.912 6.068a.25.25 0 00.444-.157V16.7a1.5 1.5 0 00-.774-1.317zM15.366 13.306a.43.43 0 00-.295-.158.425.425 0 00-.457.509l2.058 9.943a.5.5 0 00.489.4H23a.488.488 0 00.379-.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});