define("ember-svg-jar/inlined/wireless-payment-credit-card-dollar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wireless-payment-credit-card-dollar</title><path d=\"M8.5 19h-6a.5.5 0 01-.5-.5v-14a.5.5 0 01.5-.5h9a.5.5 0 01.5.5v6a1 1 0 002 0v-8A2.5 2.5 0 0011.5 0h-9A2.5 2.5 0 000 2.5v19A2.5 2.5 0 002.5 24h6a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5z\"/><path d=\"M6.875 15.75a.75.75 0 00.75-.75v-.3a.25.25 0 01.2-.245 2.092 2.092 0 00.36-3.992l-2.063-.822a.592.592 0 01.22-1.141h2.033a.75.75 0 000-1.5h-.5a.25.25 0 01-.25-.25V6.5a.75.75 0 00-1.5 0v.3a.25.25 0 01-.2.245 2.092 2.092 0 00-.36 3.992l2.063.825A.592.592 0 017.408 13H5.375a.75.75 0 000 1.5h.5a.25.25 0 01.25.25V15a.75.75 0 00.75.75zM24 15.25a1.752 1.752 0 00-1.75-1.75h-10a1.752 1.752 0 00-1.75 1.75v7A1.752 1.752 0 0012.25 24h10A1.752 1.752 0 0024 22.25zm-1.75 7.25h-10a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25h10a.25.25 0 01.25.25v3a.25.25 0 01-.25.25zm-10-7.5h10a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-10a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25z\"/><path d=\"M21 20.75a.75.75 0 00-.75-.75H19a.75.75 0 000 1.5h1.25a.75.75 0 00.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});