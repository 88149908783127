define("ember-svg-jar/inlined/design-document-psd", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-document-psd</title><path d=\"M7 9.625h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H7a.875.875 0 000-1.75zM16.125 10.059v3.882a.249.249 0 00.331.24A2.237 2.237 0 0017.875 12a2.237 2.237 0 00-1.419-2.181.249.249 0 00-.331.24z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM6.875 12.625h-.5a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0V9a.625.625 0 01.625-.625H7a2.128 2.128 0 012.114 2.347 2.213 2.213 0 01-2.239 1.903zm4.552-1.758l1.84 1.226a1.928 1.928 0 01-1.07 3.532H10.5a.625.625 0 010-1.25h1.7a.678.678 0 00.376-1.242l-1.84-1.226A1.928 1.928 0 0111.8 8.375h1.7a.625.625 0 010 1.25h-1.7a.678.678 0 00-.376 1.242zm4.073 4.758a.625.625 0 01-.625-.625V9a.625.625 0 01.625-.625 3.625 3.625 0 010 7.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});