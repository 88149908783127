define("ember-svg-jar/inlined/upload-square-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>upload-square-3</title><path d=\"M2 2.25A.25.25 0 012.25 2H3.5a1 1 0 000-2H2a2 2 0 00-2 2v1.5a1 1 0 002 0zM24 2a2 2 0 00-2-2h-1.5a1 1 0 000 2h1.25a.25.25 0 01.25.25V3.5a1 1 0 002 0zM10 0H7.5a1 1 0 000 2H10a1 1 0 000-2zM16.5 0H14a1 1 0 000 2h2.5a1 1 0 000-2zM7.5 24H10a1 1 0 000-2H7.5a1 1 0 000 2zM17.5 23a1 1 0 00-1-1H14a1 1 0 000 2h2.5a1 1 0 001-1zM2 24h1.5a1 1 0 000-2H2.25a.25.25 0 01-.25-.25V20.5a1 1 0 00-2 0V22a2 2 0 002 2zM24 22v-1.5a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25H20.5a1 1 0 000 2H22a2 2 0 002-2zM2 7.5a1 1 0 00-2 0V10a1 1 0 002 0zM1 13a1 1 0 00-1 1v2.5a1 1 0 002 0V14a1 1 0 00-1-1zM24 7.5a1 1 0 00-2 0V10a1 1 0 002 0zM24 14a1 1 0 00-2 0v2.5a1 1 0 002 0zM11.876 5h-.01a.75.75 0 00-.6.316l-4.251 6a.751.751 0 00.61 1.184h2.5a.25.25 0 01.25.25v5.5a1.5 1.5 0 003 0v-5.5a.25.25 0 01.25-.25h2.749a.75.75 0 00.6-1.2l-4.5-6a.75.75 0 00-.598-.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});