define("ember-svg-jar/inlined/programming-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-monitor</title><path d=\"M24 3.005A2.548 2.548 0 0021.5.416h-19A2.548 2.548 0 000 3.005v13.911a3 3 0 003 3h7.25a.25.25 0 01.25.25v1.168a.249.249 0 01-.25.25H5a1 1 0 000 2h14a1 1 0 000-2h-6.25a.249.249 0 01-.25-.25v-1.168a.25.25 0 01.25-.25H21a3 3 0 003-3zm-22 0a.55.55 0 01.5-.589h19a.55.55 0 01.5.589v11.911a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5z\"/><path d=\"M11 7.166a.25.25 0 01-.25-.25v-2.5a1.251 1.251 0 00-1.25-1.25H9a.75.75 0 100 1.5.25.25 0 01.25.25v2a.25.25 0 01-.25.25.75.75 0 000 1.5h2a.75.75 0 000-1.5zM20 13.166a.25.25 0 01-.25-.25v-2.5a1.251 1.251 0 00-1.25-1.25H18a.75.75 0 000 1.5.25.25 0 01.25.25v2a.25.25 0 01-.25.25.75.75 0 000 1.5h2a.75.75 0 000-1.5zM7.25 13.916a.75.75 0 00-.75-.75.25.25 0 01-.25-.25v-2.5A1.251 1.251 0 005 9.166h-.5a.75.75 0 000 1.5.25.25 0 01.25.25v2a.25.25 0 01-.25.25.75.75 0 000 1.5h2a.75.75 0 00.75-.75zM20 7.166a.25.25 0 01-.25-.25v-2.5a1.251 1.251 0 00-1.25-1.25H18a.75.75 0 100 1.5.25.25 0 01.25.25v2a.25.25 0 01-.25.25.75.75 0 000 1.5h2a.75.75 0 000-1.5zM5.5 3.166a1.752 1.752 0 00-1.75 1.75v2a1.75 1.75 0 003.5 0v-2a1.752 1.752 0 00-1.75-1.75zm.25 3.75c0 .275-.5.275-.5 0v-2a.25.25 0 01.5 0zM10 9.166a1.752 1.752 0 00-1.75 1.75v2a1.75 1.75 0 003.5 0v-2A1.752 1.752 0 0010 9.166zm.25 3.75c0 .275-.5.275-.5 0v-2a.25.25 0 01.5 0zM14.5 3.166a1.752 1.752 0 00-1.75 1.75v2a1.75 1.75 0 003.5 0v-2a1.752 1.752 0 00-1.75-1.75zm.25 3.75c0 .275-.5.275-.5 0v-2a.25.25 0 01.5 0zM15.75 13.247A.25.25 0 0115.5 13v-2.5a1.251 1.251 0 00-1.25-1.25h-.5a.75.75 0 000 1.5.25.25 0 01.25.25v2a.25.25 0 01-.25.25.75.75 0 000 1.5h2a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});