define("ember-svg-jar/inlined/chef-gear-asian-hat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chef-gear-asian-hat</title><path d=\"M6.415 9.334a.5.5 0 00.224.589 2.922 2.922 0 01.613.47l3.571 3.571a.25.25 0 00.427-.177V8.55a.75.75 0 111.5 0v5.237a.25.25 0 00.427.177l3.57-3.57a2.959 2.959 0 01.614-.471.5.5 0 00.224-.589C16.47 5.952 14.2 0 12 0S7.53 5.952 6.415 9.334zM5.055 22.086l5.531-5.531a.5.5 0 000-.707l-4.393-4.393a1.5 1.5 0 00-2.4.378A42.762 42.762 0 00.034 22.182 1.5 1.5 0 001.5 24h2.469a.5.5 0 00.5-.5 2 2 0 01.586-1.414zM22.5 24a1.5 1.5 0 001.467-1.818A42.808 42.808 0 0020.2 11.833a1.5 1.5 0 00-2.4-.378L6.115 23.146a.5.5 0 00.353.854z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});