define("ember-svg-jar/inlined/image-document-tiff", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-document-tiff</title><path d=\"M22.375 4.375H1.625A1.627 1.627 0 000 6v12a1.627 1.627 0 001.625 1.625h20.75A1.627 1.627 0 0024 18V6a1.627 1.627 0 00-1.625-1.625zM7.5 9.625H6.375a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0V9.875a.25.25 0 00-.25-.25H3.5a.625.625 0 010-1.25h4a.625.625 0 010 1.25zm3.875 4.75h.125a.625.625 0 010 1.25h-2a.625.625 0 010-1.25h.125a.25.25 0 00.25-.25v-4.25a.25.25 0 00-.25-.25H9.5a.625.625 0 010-1.25h2a.625.625 0 010 1.25h-.125a.25.25 0 00-.25.25v4.25a.25.25 0 00.25.25zm5.125-4.75h-2a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25H15.5a.625.625 0 010 1.25h-1.125a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0v-5A1.626 1.626 0 0114.5 8.375h2a.625.625 0 010 1.25zm5 0h-2a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25H20.5a.625.625 0 010 1.25h-1.125a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0v-5A1.626 1.626 0 0119.5 8.375h2a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});