define("ember-svg-jar/inlined/insert-image-bottom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>insert-image-bottom</title><path d=\"M3.7 16.649a.75.75 0 00-1.2.6v1.5a.25.25 0 01-.25.25H1a1 1 0 000 2h1.25a.25.25 0 01.25.25v1.5a.75.75 0 001.2.6L7.366 20.6a.751.751 0 000-1.2zM10 2h10a1 1 0 000-2H10a1 1 0 000 2zM10 7h13a1 1 0 000-2H10a1 1 0 000 2zM10 12h10a1 1 0 000-2H10a1 1 0 000 2z\"/><rect x=\"9\" y=\"15.998\" width=\"15\" height=\"8\" rx=\"2\" ry=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});