define("ember-svg-jar/inlined/flying-insect-bees", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flying-insect-bees</title><path d=\"M23.757 6.938a2.989 2.989 0 00-3.921-1.565 4.77 4.77 0 00-1.468 1.237 4.78 4.78 0 00-.453-1.865 2.986 2.986 0 10-5.142 3.037c.647 1.1 3.133 2.328 4.172 2.808h.013a16.941 16.941 0 003.718.489 4.065 4.065 0 001.515-.223 2.988 2.988 0 001.566-3.918zM14.5 6.764a.985.985 0 011.7-1 7.418 7.418 0 01.229 2.325A7.354 7.354 0 0114.5 6.764zm6.129.447a.985.985 0 11.778 1.81 7.422 7.422 0 01-2.335-.07zM6.462 7.1c.438-.024 3.885-1.188 4.738-2.268a2.986 2.986 0 10-4.695-3.693 4.769 4.769 0 00-.7 1.788 4.747 4.747 0 00-1.291-1.42 2.985 2.985 0 10-3.059 5.125c.882.527 2.975.606 5.007.468zm1.615-4.724a.985.985 0 111.549 1.218 7.383 7.383 0 01-2.084 1.055 7.335 7.335 0 01.535-2.273zm-5.6 2.54A.986.986 0 013.49 3.223 7.377 7.377 0 014.8 5.156a7.413 7.413 0 01-2.32-.24zM11.751 11c-4.318 0-7.58 1.685-7.66 3.932a7.149 7.149 0 001.376 5.391 6.412 6.412 0 003.359 2.048V23.5a.5.5 0 00.5.5h4.848a.5.5 0 00.5-.5v-1.13a6.4 6.4 0 003.358-2.047 7.14 7.14 0 001.379-5.373c-.063-2.256-3.333-3.95-7.66-3.95zm5.666 4.015c0 .685-2 2.016-5.666 2.016S6.084 15.7 6.084 15.015 8.084 13 11.751 13s5.666 1.331 5.666 2.015z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});