define("ember-svg-jar/inlined/shop-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shop-sign</title><path d=\"M21.087 8a.479.479 0 01-.329-.13L12.686.272a1 1 0 00-1.372 0L3.242 7.87a.479.479 0 01-.329.13A2.961 2.961 0 000 11v10a2.961 2.961 0 002.913 3h18.174A2.961 2.961 0 0024 21V11a2.961 2.961 0 00-2.913-3zm-9.43-5.3a.5.5 0 01.686 0l5.176 4.872a.25.25 0 01-.171.428H6.652a.25.25 0 01-.171-.432zM22 21a.96.96 0 01-.913 1H2.913A.96.96 0 012 21V11a.96.96 0 01.913-1h18.174A.96.96 0 0122 11z\"/><path d=\"M19.059 11.5H4.941A1.473 1.473 0 003.5 13v6a1.473 1.473 0 001.441 1.5h14.118A1.473 1.473 0 0020.5 19v-6a1.473 1.473 0 00-1.441-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});