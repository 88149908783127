define("ember-svg-jar/inlined/bag-elegant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bag-elegant</title><path d=\"M23 13.25h-3.5a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5H23a1 1 0 001-1V6.5A1.5 1.5 0 0022.5 5h-21A1.5 1.5 0 000 6.5v4.25a1 1 0 001 1h3.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5H1a2.729 2.729 0 01-.687-.087.25.25 0 00-.313.242v3.1A2.5 2.5 0 002.5 19h19a2.5 2.5 0 002.5-2.5v-3.1a.25.25 0 00-.313-.242 2.729 2.729 0 01-.687.092zm-13.332.85a.249.249 0 01-.059.33 5.954 5.954 0 01-1.8.99 1 1 0 01-.9-.144c-1.223-.893-.992-4.921.032-5.608a1 1 0 01.933-.1A6.092 6.092 0 019.742 10.7a.249.249 0 01.033.331 2.744 2.744 0 00-.107 3.07zM12 13.894a1.25 1.25 0 111.25-1.25 1.252 1.252 0 01-1.25 1.25zm5.09 1.382a1 1 0 01-.9.144 5.954 5.954 0 01-1.8-.99.249.249 0 01-.059-.33 2.744 2.744 0 00-.107-3.07.249.249 0 01.033-.331 6.092 6.092 0 011.866-1.128 1 1 0 01.933.1c1.025.684 1.256 4.712.034 5.605z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});