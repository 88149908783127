define("ember-svg-jar/inlined/tea-herbal-to-go", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tea-herbal-to-go</title><path d=\"M18 7V6a1 1 0 00-1-1H7.281a.5.5 0 01-.41-.213L3.819.427a1 1 0 10-1.638 1.146L4.3 4.607A.25.25 0 014.1 5H1.5a1 1 0 00-1 1v1a1 1 0 001 1H17a1 1 0 001-1zM10.854 20.465a.5.5 0 00.146-.354V17.75a2.253 2.253 0 012.25-2.25c.069 0 .137 0 .205.009a.5.5 0 00.545-.5v-.259a2.241 2.241 0 011.355-2.05.5.5 0 00.3-.383l.343-2.238A.5.5 0 0015.5 9.5H3a.5.5 0 00-.494.576l2 12.99A1 1 0 005.5 24h3.012a.5.5 0 00.5-.55 1.9 1.9 0 01-.01-.2 2.235 2.235 0 01.659-1.591z\"/><path d=\"M22.75 14.5h-1.086a.25.25 0 01-.177-.427l.293-.293a.75.75 0 00-1.06-1.06l-.293.293a.25.25 0 01-.427-.177V11.75a.75.75 0 00-1.5 0v2.982a.5.5 0 01-.146.354l-.927.927a.25.25 0 01-.427-.177V14.75a.75.75 0 00-1.5 0v2.982a.5.5 0 01-.146.354l-.927.927a.25.25 0 01-.427-.177V17.75a.75.75 0 00-1.5 0v2.982a.5.5 0 01-.146.354L10.72 22.72a.75.75 0 101.06 1.06l1.634-1.634a.5.5 0 01.354-.146h2.982a.75.75 0 000-1.5h-1.086a.25.25 0 01-.177-.427l.927-.927a.5.5 0 01.354-.146h2.982a.75.75 0 000-1.5h-1.086a.25.25 0 01-.177-.427l.927-.927a.5.5 0 01.354-.146h2.982a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});