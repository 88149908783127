define("ember-svg-jar/inlined/recycling-hand-trash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>recycling-hand-trash</title><path d=\"M14.25 12H.75a.75.75 0 000 1.5h.882a.5.5 0 01.5.455l.789 8.681A1.493 1.493 0 004.413 24h6.174a1.493 1.493 0 001.494-1.364l.789-8.681a.5.5 0 01.5-.455h.882a.75.75 0 000-1.5zM5.5 20.5a.5.5 0 01-1 0v-5a.5.5 0 011 0zm2.5 0a.5.5 0 01-1 0v-5a.5.5 0 011 0zm2.5 0a.5.5 0 01-1 0v-5a.5.5 0 011 0z\"/><rect x=\"20\" y=\"1.007\" width=\"4\" height=\"9\" rx=\"1\" ry=\"1\"/><path d=\"M18.661 2.14c-.863-.3-1.62-.561-2.292-.8C10.9-.582 10.793-.573 5.535 2.258 4.185 2.984 2.505 3.889.276 5a.5.5 0 00-.13.8A3.247 3.247 0 003.67 6.92l5.717-2.07a.506.506 0 01.288-.016l1.912.463a.284.284 0 01.1.506C9.963 7.066 8.18 8.372 8.811 9.537a1.128 1.128 0 001.043.739 9.346 9.346 0 002.9-1.006c.647-.283 1.435-.627 2.4-1.033a.5.5 0 01.352-.014l2.832.945A.5.5 0 0019 8.694V2.612a.5.5 0 00-.339-.472z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});