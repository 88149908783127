define("ember-svg-jar/inlined/hardware-nails", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hardware-nails</title><path d=\"M6.5 4.5h-3A.5.5 0 003 5v11a1.062 1.062 0 00.068.36l1.475 3.34a.5.5 0 00.915 0l1.49-3.385A.98.98 0 007 16V5a.5.5 0 00-.5-.5zM8.5 0h-7a1.5 1.5 0 000 3h7a1.5 1.5 0 000-3zM19.588 9.938L16.772 8.9a.5.5 0 00-.642.3l-3.793 10.324a1.021 1.021 0 00-.06.363l.231 3.645a.5.5 0 00.327.437.488.488 0 00.172.031.5.5 0 00.36-.153l2.567-2.662a.994.994 0 00.157-.281l3.794-10.325a.5.5 0 00-.016-.382.491.491 0 00-.281-.259zM23.017 6.4l-6.571-2.411a1.5 1.5 0 00-1.034 2.816l6.571 2.414A1.5 1.5 0 1023.017 6.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});