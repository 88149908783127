define("ember-svg-jar/inlined/newspaper-read-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>newspaper-read-woman</title><path d=\"M21.781 12.114a.5.5 0 01-.281-.45V11.2a1.67 1.67 0 00-.688-1.353 1.8 1.8 0 00-1.6-.268l-1 .33a.25.25 0 01-.326-.273l.636-4.336a4.716 4.716 0 00-1.18-3.423C16.212.632 14.415 0 12 0S7.788.632 6.658 1.878A4.716 4.716 0 005.478 5.3l.632 4.34a.253.253 0 01-.087.228.25.25 0 01-.239.046l-1.017-.337a1.791 1.791 0 00-1.58.274A1.672 1.672 0 002.5 11.2v.46a.5.5 0 01-.281.45 3.87 3.87 0 000 7 .5.5 0 01.281.449v.569a1.707 1.707 0 001.3 1.645l8 2.2a.739.739 0 00.4 0l7.989-2.192a1.712 1.712 0 001.311-1.649v-.569a.5.5 0 01.281-.449 3.87 3.87 0 000-7zM5 17.114a.5.5 0 01-.5.5c-1.1 0-2.5-.5-2.5-2s1.4-2 2.5-2a.5.5 0 01.5.5zm11.283-6.851a.5.5 0 01-.337.4l-3.631 1.2a1 1 0 01-.63 0l-3.631-1.2a.5.5 0 01-.337-.4l-.465-3.2a.5.5 0 01.5-.572c2.157.035 3.264-.373 3.925-.921a.5.5 0 01.638 0c.662.548 1.768.956 3.925.921a.5.5 0 01.5.572zm3.217 7.351a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5c1.105 0 2.5.5 2.5 2s-1.395 2-2.5 2z\"/><circle cx=\"10\" cy=\"8.25\" r=\"1\"/><circle cx=\"14\" cy=\"8.25\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});