define("ember-svg-jar/inlined/messages-bubble-download-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-download-1-alternate</title><path d=\"M9.76 15.49a.25.25 0 00-.4-.258.96.96 0 00-.068.061L7 17.586V15.56a1 1 0 00-.664-.942A6.493 6.493 0 018.5 2h7A6.508 6.508 0 0122 8.5a6.419 6.419 0 01-.314 1.974.249.249 0 00.1.287A8.111 8.111 0 0123 11.7a.251.251 0 00.4-.09A8.486 8.486 0 0015.5 0h-7A8.492 8.492 0 005 16.232V20a1 1 0 001.707.707l2.733-2.733a.251.251 0 00.073-.194c-.006-.093-.013-.186-.013-.28a7.927 7.927 0 01.26-2.01z\"/><path d=\"M17.5 24a6.5 6.5 0 10-6.5-6.5 6.508 6.508 0 006.5 6.5zm-2.391-5.688a.5.5 0 01.391-.812h1a.25.25 0 00.25-.25V14.5a.75.75 0 011.5 0v2.75a.25.25 0 00.25.25h1a.5.5 0 01.391.812l-2 2.5a.519.519 0 01-.782 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});