define("ember-svg-jar/inlined/tablet-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tablet-1</title><path d=\"M18.5 0h-13A2.5 2.5 0 003 2.5v19A2.5 2.5 0 005.5 24h13a2.5 2.5 0 002.5-2.5v-19A2.5 2.5 0 0018.5 0zm.5 18.5a1.5 1.5 0 01-1.5 1.5h-11A1.5 1.5 0 015 18.5v-13A1.5 1.5 0 016.5 4h11A1.5 1.5 0 0119 5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});