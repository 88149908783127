define("ember-svg-jar/inlined/single-woman-actions-subtract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-subtract</title><path d=\"M.141 17.931a.5.5 0 00.478.626h2.3a.247.247 0 01.243.2l.921 4.847v.007a.5.5 0 00.484.389h2.969a.5.5 0 00.483-.388v-.007l.921-4.847a.247.247 0 01.243-.2h.132a.249.249 0 00.184-.082.245.245 0 00.063-.19 7.085 7.085 0 01-.038-.718 7.857 7.857 0 011.153-4.1.249.249 0 00.028-.194c-.526-1.935-.655-2.941-1.731-3.948a.5.5 0 00-.757.1L6.482 12.16a.5.5 0 01-.837 0L3.923 9.411a.5.5 0 00-.755-.1C2.014 10.373 2.1 10.78.141 17.931zM6.041 8.412a4.206 4.206 0 10-4.207-4.206 4.211 4.211 0 004.207 4.206zm0-1.979A2.223 2.223 0 013.9 4.81a.247.247 0 01.158-.3 4.63 4.63 0 001.8-1.128.247.247 0 01.353 0 4.615 4.615 0 001.8 1.128.248.248 0 01.157.3 2.223 2.223 0 01-2.127 1.623zM11.01 17.567a6.433 6.433 0 106.433-6.433 6.441 6.441 0 00-6.433 6.433zm2.969-.99h6.928a.99.99 0 110 1.98h-6.928a.99.99 0 010-1.98z\"/><path d=\"M.141 17.931a.5.5 0 00.478.626h2.3a.247.247 0 01.243.2l.921 4.847v.007a.5.5 0 00.484.389h2.969a.5.5 0 00.483-.388v-.007l.921-4.847a.247.247 0 01.243-.2h.132a.249.249 0 00.184-.082.245.245 0 00.063-.19 7.085 7.085 0 01-.038-.718 7.857 7.857 0 011.153-4.1.249.249 0 00.028-.194c-.526-1.935-.655-2.941-1.731-3.948a.5.5 0 00-.757.1L6.482 12.16a.5.5 0 01-.837 0L3.923 9.411a.5.5 0 00-.755-.1C2.014 10.373 2.1 10.78.141 17.931zM6.041 8.412a4.206 4.206 0 10-4.207-4.206 4.211 4.211 0 004.207 4.206zm0-1.979A2.223 2.223 0 013.9 4.81a.247.247 0 01.158-.3 4.63 4.63 0 001.8-1.128.247.247 0 01.353 0 4.615 4.615 0 001.8 1.128.248.248 0 01.157.3 2.223 2.223 0 01-2.127 1.623zM11.01 17.567a6.433 6.433 0 106.433-6.433 6.441 6.441 0 00-6.433 6.433zm2.969-.99h6.928a.99.99 0 110 1.98h-6.928a.99.99 0 010-1.98z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});