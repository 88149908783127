define("ember-svg-jar/inlined/people-man-beanie-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-beanie-1</title><circle cx=\"13.979\" cy=\"11.876\" r=\".99\"/><circle cx=\"10.021\" cy=\"11.876\" r=\".99\"/><path d=\"M10.472 14.444a.634.634 0 00.315.712 2.322 2.322 0 00.43.177 2.967 2.967 0 00.858.125h.006a2.927 2.927 0 00.855-.124 2.3 2.3 0 00.431-.179.633.633 0 00-.3-1.189h-1.98a.633.633 0 00-.615.478zM17.932 6.68a.247.247 0 00.237-.317 6.448 6.448 0 00-4.519-4.411.248.248 0 01-.181-.277 1.188 1.188 0 00.016-.19 1.485 1.485 0 10-2.97 0 1.188 1.188 0 00.016.19.248.248 0 01-.181.277 6.448 6.448 0 00-4.519 4.411.247.247 0 00.237.317z\"/><path d=\"M22.725 20.555a13.846 13.846 0 00-4.84-2.26c-.378-.1-.635-.164-.782-.2a.246.246 0 01-.185-.186 1.412 1.412 0 00-.1-.316.958.958 0 00-.291-.325.248.248 0 01-.019-.391 6.9 6.9 0 002.423-5.249v-1.01a.247.247 0 01.1-.2.966.966 0 00.4-.772c0-1.389.005-.9-.027-1.123a.981.981 0 00-.963-.856H5.567a.991.991 0 00-.99.99v.989a.966.966 0 00.4.772.246.246 0 01.1.2v1.011A6.9 6.9 0 007.5 16.878a.248.248 0 01-.019.391.958.958 0 00-.291.325 1.011 1.011 0 00-.123.339.218.218 0 01-.168.179c-.145.031-.269.06-.779.183a12.673 12.673 0 00-4.841 2.261A2.941 2.941 0 00.124 23.01a.99.99 0 101.979 0 1 1 0 01.373-.881 12 12 0 014.24-1.946c.114-.03.33-.091.514-.144a.247.247 0 01.316.237v2.982a.5.5 0 00.5.5h2.474a.5.5 0 00.495-.5v-2.007a.248.248 0 01.261-.251c.236.018.478.027.724.027a7.1 7.1 0 004.1-1.1.248.248 0 01.275-.015.87.87 0 00.263.106c.227.051.438.107.65.163a12 12 0 014.239 1.945 1 1 0 01.374.882.99.99 0 101.979 0 2.944 2.944 0 00-1.155-2.453zM12 16.577a4.954 4.954 0 01-4.948-4.948v-.742a.247.247 0 01.247-.248h9.4a.247.247 0 01.247.248v.742A4.954 4.954 0 0112 16.577z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});