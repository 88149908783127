define("ember-svg-jar/inlined/jellyfish-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>jellyfish-1</title><path d=\"M9.446 16.92a11.805 11.805 0 001.389-1.72.53.53 0 00-.063-.673l-.765-.765a.531.531 0 00-.82.084 10.178 10.178 0 01-1.243 1.576 8.042 8.042 0 01-1.931 1.35 11.866 11.866 0 00-2.873 2.073 1.062 1.062 0 001.5 1.5 9.78 9.78 0 012.409-1.726 9.974 9.974 0 002.397-1.699zM13.105 16.856a.531.531 0 00-.823.088 10.4 10.4 0 01-1.325 1.7A8.042 8.042 0 019.026 20a11.9 11.9 0 00-2.873 2.077 1.062 1.062 0 001.5 1.5 9.825 9.825 0 012.409-1.726 10 10 0 002.395-1.7 12.171 12.171 0 001.479-1.856.531.531 0 00-.067-.669 64.287 64.287 0 00-.764-.77zM6.618 13.822a11.6 11.6 0 001.276-1.559.532.532 0 00-.06-.678l-.763-.764a.532.532 0 00-.816.078 9.835 9.835 0 01-1.139 1.421 8.02 8.02 0 01-1.93 1.348 11.859 11.859 0 00-2.875 2.079 1.062 1.062 0 001.5 1.5A9.741 9.741 0 014.22 15.52a10.015 10.015 0 002.398-1.698zM21.822 2.291a7.433 7.433 0 00-10.514 0l-3.8 3.8a2.065 2.065 0 000 2.921l7.594 7.6a2.074 2.074 0 002.922 0l3.795-3.8a7.433 7.433 0 000-10.514zm-1.5 2.253a.532.532 0 01-.751 0 4.247 4.247 0 00-6.008 0 .531.531 0 01-.751-.751 5.309 5.309 0 017.51 0 .53.53 0 01-.002.751z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});