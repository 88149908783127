define("ember-svg-jar/inlined/taxi-van", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>taxi-van</title><path d=\"M23.39 10.625h-9a.249.249 0 01-.25-.25v-3.75a.25.25 0 01.25-.25h8.25a1 1 0 000-2H11.754a3 3 0 00-2.575 1.462L6.5 10.671a.248.248 0 01-.178.125l-4.333.723A1.506 1.506 0 00.765 12.7l-.125.622a.25.25 0 00.246.3h1.5a.5.5 0 110 1h-1.8a.251.251 0 00-.246.2l-.2 1a1.5 1.5 0 001.47 1.803h.54a.251.251 0 00.249-.231 3 3 0 015.982 0 .25.25 0 00.25.231h7.019a.251.251 0 00.249-.231 3 3 0 015.982 0 .25.25 0 00.25.231h1.259a.5.5 0 00.5-.5v-6a.5.5 0 00-.5-.5zm-11.25-.245a.25.25 0 01-.25.25H9.232a.25.25 0 01-.219-.371l1.887-3.41a.993.993 0 01.85-.474h.136a.25.25 0 01.25.25z\"/><circle cx=\"5.39\" cy=\"17.625\" r=\"2\"/><circle cx=\"18.89\" cy=\"17.625\" r=\"2\"/><path d=\"M15.39 8.375v.5a.75.75 0 001.5 0v-.5a.75.75 0 00-1.5 0zM20.39 8.375v.5a.75.75 0 001.5 0v-.5a.75.75 0 00-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});