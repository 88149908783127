define("ember-svg-jar/inlined/house-garage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>house-garage</title><path d=\"M23.7 5.917l-11.5-5a.492.492 0 00-.4 0l-11.5 5a.5.5 0 00-.3.458v1.5a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-1.5a.5.5 0 00-.3-.458zM17.25 14.375h-10a.75.75 0 000 1.5h10a.75.75 0 000-1.5zM17.25 17.875h-10a.75.75 0 000 1.5h10a.75.75 0 000-1.5z\"/><path d=\"M24 22.125a1 1 0 00-1-1h-.5a.5.5 0 01-.5-.5v-10.25a.5.5 0 00-.5-.5h-19a.5.5 0 00-.5.5v10.25a.5.5 0 01-.5.5H1a1 1 0 000 2h22a1 1 0 001-1zm-19-8.75a1 1 0 011-1h12.5a1 1 0 011 1v7.25a.5.5 0 01-.5.5H5.5a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});