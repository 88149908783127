define("ember-svg-jar/inlined/tennis-backhand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tennis-backhand</title><circle cx=\"7.884\" cy=\"2.999\" r=\"2.5\"/><circle cx=\"19.884\" cy=\"14.499\" r=\"2\"/><path d=\"M14.3 10.463l2.485-1.131h.015a5.355 5.355 0 004.132-2.26C22.584 4.869 22.5 2 20.735.673s-4.511-.655-6.2 1.595a5.237 5.237 0 00-1.011 4.573l-.4 2.736c-.014.015-.868 1.134-.868 1.134l-2.19-.486a1.513 1.513 0 01-.737-.4L6.445 6.938a1.5 1.5 0 00-2.5.649l-2 7a1.5 1.5 0 00.139 1.156l1.26 2.2a1.505 1.505 0 01.075 1.335l-1.164 2.63a1.5 1.5 0 002.758 1.182l1.16-2.626a4.522 4.522 0 00.2-2.976l1.166.359a1.5 1.5 0 011.051 1.284l.3 3.517a1.5 1.5 0 002.984-.3l-.3-3.518a4.492 4.492 0 00-3.153-3.853l-2.956-.91a.249.249 0 01-.167-.307l.712-2.491a.25.25 0 01.417-.108l.778.778a4.49 4.49 0 002.206 1.211l3.648.811a1.5 1.5 0 00.792-2.881s.439-.6.449-.617zm5.232-8.191c.867.651.774 2.3-.2 3.6s-2.537 1.845-3.4 1.2-.765-2.312.2-3.6c.929-1.241 2.502-1.872 3.401-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});