define("ember-svg-jar/inlined/time-clock-file-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-clock-file-home</title><path d=\"M23.414 3L21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.868a.249.249 0 00.33.232 2.479 2.479 0 011.34 0A.25.25 0 008 9.868V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.121a.5.5 0 01.146.355V18a.5.5 0 01-.5.5h-5.324a.253.253 0 00-.244.229 1.977 1.977 0 01-.958 1.316.25.25 0 00.146.455H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M7.324 12.119a.5.5 0 00-.647 0l-6.5 5.5A.5.5 0 00.5 18.5h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5H5a.5.5 0 00.5-.5v-2.25a1.5 1.5 0 013 0v2.25a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-4.75a.25.25 0 01.25-.25h1.25a.5.5 0 00.324-.882zM20.25 8.5a4.75 4.75 0 10-4.75 4.75 4.756 4.756 0 004.75-4.75zm-8 0a3.25 3.25 0 113.25 3.25 3.254 3.254 0 01-3.25-3.25z\"/><path d=\"M17.268 9.251a.75.75 0 100-1.5H16.5a.252.252 0 01-.25-.251v-.767a.75.75 0 00-1.5 0V8.5a.75.75 0 00.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});