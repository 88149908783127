define("ember-svg-jar/inlined/crypto-currency-bitcoin-decrease", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-decrease</title><path d=\"M18.11 7.784h-1.722a.25.25 0 00-.25.25V9.3a.25.25 0 00.25.25h1.722a.884.884 0 100-1.768zM16.388 4.518a.25.25 0 00-.25.25v1.266a.25.25 0 00.25.25h1.722a.883.883 0 100-1.766z\"/><path d=\"M16.987 0a7 7 0 107 7 7.009 7.009 0 00-7-7zm-2.349 3.768a.75.75 0 01.75-.75h.09a.25.25 0 00.25-.25v-.09a.75.75 0 111.5 0v.09a.249.249 0 00.25.25h.632A2.386 2.386 0 0120.494 5.4a2.361 2.361 0 01-.521 1.478.25.25 0 000 .312 2.377 2.377 0 01-1.863 3.862h-.632a.249.249 0 00-.25.25v.089a.75.75 0 01-1.5 0V11.3a.25.25 0 00-.25-.25h-.09a.75.75 0 01-.75-.75zM.433 12.685a1.25 1.25 0 011.66-1.87l7.155 6.36a.25.25 0 00.322.009l2.9-2.324a2.163 2.163 0 012.8.1l5.047 4.653a.249.249 0 00.346-.007l1.462-1.462a.5.5 0 01.362-.144.513.513 0 01.192.038.5.5 0 01.308.462v5a.5.5 0 01-.5.5h-5a.5.5 0 01-.353-.854l1.415-1.414a.25.25 0 00-.008-.361l-4.582-4.225a.25.25 0 00-.326-.012l-2.9 2.317a2.152 2.152 0 01-2.771-.071z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});