define("ember-svg-jar/inlined/email-action-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-check</title><path d=\"M17 8.5a8.341 8.341 0 011.7.18.25.25 0 00.3-.245V1.583a.144.144 0 00-.245-.1L11.038 9.2a2.178 2.178 0 01-3.078 0L.245 1.481a.141.141 0 00-.156-.031.145.145 0 00-.089.133v9.5a1.583 1.583 0 001.583 1.584H9.56a.25.25 0 00.213-.119A8.492 8.492 0 0117 8.5z\"/><path d=\"M8.8 8.357a.993.993 0 001.4 0L17.867.688a.4.4 0 00-.222-.671A1.453 1.453 0 0017.416 0H1.583a1.435 1.435 0 00-.229.017.4.4 0 00-.223.671zM17 10a7 7 0 107 7 7.008 7.008 0 00-7-7zm0 12a5 5 0 115-5 5.006 5.006 0 01-5 5z\"/><path d=\"M18.355 15.046l-2.09 2.788-.9-.9a1 1 0 00-1.414 1.414l1.219 1.219a1.611 1.611 0 002.43-.167l2.361-3.149a1 1 0 00-1.6-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});