define("ember-svg-jar/inlined/playlist-buy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>playlist-buy</title><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-.75 10.5a.25.25 0 00-.25-.25H16a.75.75 0 010-1.5h2.033a.592.592 0 00.22-1.141l-2.063-.825a2.092 2.092 0 01.36-3.992.25.25 0 00.2-.245v-.3a.75.75 0 011.5 0v.25a.25.25 0 00.25.25h.5a.75.75 0 010 1.5h-2.033a.592.592 0 00-.22 1.141l2.063.825a2.092 2.092 0 01-.36 3.992.25.25 0 00-.2.245v.3a.75.75 0 01-1.5 0z\"/><path d=\"M16.315 9.533a.247.247 0 00.222-.248V2.5A2.47 2.47 0 0014.105 0H2.432A2.47 2.47 0 000 2.5v12A2.47 2.47 0 002.432 17h6.6a.246.246 0 00.242-.227 7.906 7.906 0 017.041-7.24zM11.187 8a.987.987 0 01-.973-1A1.784 1.784 0 009 5.233a.183.183 0 00-.248.175V11.052A2.467 2.467 0 016.323 13.5a2.5 2.5 0 010-5c.074 0 .148 0 .221.01a.237.237 0 00.186-.064.253.253 0 00.079-.185V4a.987.987 0 01.973-1c1.514 0 4.377.836 4.377 4a.986.986 0 01-.972 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});