define("ember-svg-jar/inlined/lucide-dices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect x=\"2\" y=\"10\" width=\"12\" height=\"12\" rx=\"2\" ry=\"2\"/><path d=\"M17.92 14l3.5-3.5a2.24 2.24 0 000-3l-5-4.92a2.24 2.24 0 00-3 0L10 6M6 18h.01M10 14h.01M15 6h.01M18 9h.01\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});