define("ember-svg-jar/inlined/folder-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-image</title><path d=\"M21.5 5.25a.25.25 0 00.25-.25v-.75A.75.75 0 0021 3.5H8l-.18-.076-1.342-1.389a1.751 1.751 0 00-1.26-.535H2.286A2.037 2.037 0 00.25 3.536V16.25A1.749 1.749 0 002 18h.25a.25.25 0 00.25-.25v-9.5a3 3 0 013-3z\"/><path d=\"M22 7H6a1.752 1.752 0 00-1.75 1.75v12A1.752 1.752 0 006 22.5h16a1.752 1.752 0 001.75-1.75v-12A1.752 1.752 0 0022 7zm-10.9 3.25a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm7.241 9H10a.5.5 0 01-.425-.763l1.479-2.387a1 1 0 011.273-.207l1.579 1.207a.249.249 0 00.349-.045l1.511-1.945a1 1 0 011.587.068l1.447 3.375a.5.5 0 01-.456.697z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});