define("ember-svg-jar/inlined/style-three-pin-truck", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-truck</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM9 15a1.5 1.5 0 111.5-1.5A1.5 1.5 0 019 15zm4.054-3.213a.5.5 0 01-.41.213h-.788a.5.5 0 01-.41-.214A2.978 2.978 0 006.86 11.4a.5.5 0 01-.854-.292A1 1 0 016 11V6.5a1 1 0 011-1h6a1 1 0 011 1v4.144a.5.5 0 01-.213.41 2.985 2.985 0 00-.733.733zM15.5 15a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm3-3.25a.25.25 0 01-.25.25h-.027a.25.25 0 01-.211-.115 3 3 0 00-2.084-1.354.5.5 0 01-.428-.494V8a.5.5 0 01.5-.5h.5a2 2 0 012 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});