define("ember-svg-jar/inlined/history-spartan-helmet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-spartan-helmet</title><path d=\"M19.517 14.351a7.5 7.5 0 00-15 0v4.5a1 1 0 002 0v-2.5a2.5 2.5 0 012.5-2.5h3.5a1 1 0 010 2 4 4 0 00-4 4v3.3a.5.5 0 00.663.472l10.406-3.6a1.546 1.546 0 00.833-2.229 6.575 6.575 0 01-.902-3.443zM7.06 6.841a.5.5 0 00.158-.667L5.055 2.428a.5.5 0 00-.7-.17A12.245 12.245 0 00.069 7.127a.5.5 0 00.191.653l3.4 2.033a.5.5 0 00.683-.167A8.979 8.979 0 017.06 6.841zM13.438 5.467a8.938 8.938 0 012.609.833.5.5 0 00.655-.2l1.656-2.869a.5.5 0 00-.149-.661A12.392 12.392 0 0013.616.624a.5.5 0 00-.6.49v3.858a.5.5 0 00.422.495zM8.132 5.757a.5.5 0 00.615.216 8.981 8.981 0 012.8-.609.5.5 0 00.473-.5v-4a.5.5 0 00-.472-.5 10.272 10.272 0 00-.528-.016 12.487 12.487 0 00-4.82.937.5.5 0 00-.239.715zM23.24 9.432a.5.5 0 00-.725-.276l-1.966 1.134a.5.5 0 00-.213.623 9 9 0 01.642 2.6 1.5 1.5 0 001.494 1.34 1.511 1.511 0 001.508-1.613 15.359 15.359 0 00-.74-3.808zM17.647 7.317a8.922 8.922 0 011.794 1.926.5.5 0 00.662.151l2.152-1.242a.5.5 0 00.2-.657 13.269 13.269 0 00-2.611-3.583.5.5 0 00-.782.108l-1.537 2.657a.5.5 0 00.122.64z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});