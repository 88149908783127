define("ember-svg-jar/inlined/fitness-machine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fitness-machine</title><path d=\"M14 14.5a.5.5 0 00.5-.5V9a2.5 2.5 0 00-5 0v5a.5.5 0 00.5.5zM13.5 18.5H15a1.5 1.5 0 000-3H9a1.5 1.5 0 000 3h1.5a.5.5 0 01.5.5v2.5a.5.5 0 01-.5.5h-2a1 1 0 000 2h7a1 1 0 000-2h-2a.5.5 0 01-.5-.5V19a.5.5 0 01.5-.5zM5.75 5h-1.5a.75.75 0 00-.75.75v4.5a.735.735 0 00.3.592.5.5 0 01.2.4v.258a.5.5 0 01-.5.5h-1a1 1 0 000 2h1a.5.5 0 01.5.5v.5a1 1 0 002 0v-3.757a.5.5 0 01.2-.4.735.735 0 00.3-.592V5.75A.75.75 0 005.75 5zM17.869 4.044A1.649 1.649 0 0118.25 4h1.034a.249.249 0 00.23-.348A6 6 0 0014 0h-4a6 6 0 00-5.514 3.652.249.249 0 00.23.348H5.75a1.649 1.649 0 01.381.044.5.5 0 00.532-.215A3.975 3.975 0 0110 2h.5a.5.5 0 01.5.5V4a1 1 0 002 0V2.5a.5.5 0 01.5-.5h.5a3.975 3.975 0 013.337 1.829.5.5 0 00.532.215zM21.5 12h-1a.5.5 0 01-.5-.5v-.257a.5.5 0 01.2-.4.735.735 0 00.3-.592V5.75a.75.75 0 00-.75-.75h-1.5a.75.75 0 00-.75.75v4.5a.735.735 0 00.3.592.5.5 0 01.2.4V15a1 1 0 002 0v-.5a.5.5 0 01.5-.5h1a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});