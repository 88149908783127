define("ember-svg-jar/inlined/history-epgyptian-mythology", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-epgyptian-mythology</title><path d=\"M23.145 15.921a2.107 2.107 0 00-1.894-1.171h-.331a.5.5 0 01-.493-.416 3.985 3.985 0 00-1.533-2.519A.176.176 0 0119 11.5 3.5 3.5 0 0022.5 8a.5.5 0 01.5-.5 1 1 0 000-2A2.5 2.5 0 0020.5 8 1.5 1.5 0 0119 9.5a.4.4 0 01-.376-.53A6.75 6.75 0 105.5 6.75V8a1 1 0 01-1 1 1 1 0 000 2 2.95 2.95 0 001.771-.594.249.249 0 01.192-.045.245.245 0 01.166.108 6.809 6.809 0 001.305 1.457.251.251 0 01-.04.411 10.6 10.6 0 00-4.053 3.89l-3.7 6.265a1 1 0 00.352 1.37l.011.005a.494.494 0 01.1.05.455.455 0 00.248.083h11.405a.5.5 0 00.465-.334l1.5-4.236v-.007a.5.5 0 00-.469-.673h-.5a2 2 0 010-4h4.5a.955.955 0 01.523.157.5.5 0 01.227.419V18a4.517 4.517 0 01-.287 1.585l-1.149 3.063a1 1 0 00.585 1.289A1.029 1.029 0 0018 24a1 1 0 00.937-.648l1.149-3.065a5.976 5.976 0 00.289-1.119.5.5 0 01.493-.418h2.073a.993.993 0 00.851-.473 1.01 1.01 0 00.044-.974zm-8.7-4.978a.5.5 0 01-.235.057H13a10.381 10.381 0 00-2.125.239.253.253 0 01-.131-.008 4.746 4.746 0 113.7-.288z\"/><circle cx=\"16.5\" cy=\"17\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});