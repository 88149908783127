define("ember-svg-jar/inlined/body-care-snail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>body-care-snail</title><path d=\"M23 3h-1.5a.5.5 0 01-.5-.5V1a1 1 0 00-1-1h-2a1 1 0 00-1 1v1.5a.5.5 0 01-.5.5H15a1 1 0 00-1 1v1.49a1 1 0 001 1h1.5a.5.5 0 01.5.5v1.5a1 1 0 001 1h2a1 1 0 001-1v-1.5a.5.5 0 01.5-.5H23a1 1 0 001-1V4a1 1 0 00-1-1zM6.5 6a.5.5 0 01.5.5 1 1 0 002 0 .5.5 0 01.5-.5 1 1 0 000-2 .5.5 0 01-.5-.5 1 1 0 00-2 0 .5.5 0 01-.5.5 1 1 0 000 2zM18.733 21.1a.5.5 0 00-.471-.031 8.1 8.1 0 01-3.32.785 5.781 5.781 0 01-3.428-1.067A5.578 5.578 0 019.836 14a4.573 4.573 0 016.018-2.342 3.809 3.809 0 011.819 5.061 3.194 3.194 0 01-4.208 1.633.75.75 0 01.621-1.366 1.693 1.693 0 002.223-.889 2.309 2.309 0 00-1.078-3.074 3.075 3.075 0 00-4.03 1.6 4.071 4.071 0 001.2 4.957c1.473 1.089 3.476 1.022 5.657-.095a4.309 4.309 0 002.4-4.21l-.008-.073a7.069 7.069 0 00-6.24-6.166 6.994 6.994 0 00-6.749 3.43A.25.25 0 017 12.34V12a2.5 2.5 0 00-2.5-2.5 1 1 0 000 2 .5.5 0 01.5.5v.966a.25.25 0 01-.108.206.247.247 0 01-.231.027 3.209 3.209 0 00-.758-.185.5.5 0 01-.434-.418A2.5 2.5 0 001 10.49a1 1 0 000 2 .5.5 0 01.5.5v.578a.5.5 0 01-.142.349A2.953 2.953 0 00.5 15.99c0 5.754 2.1 8 7.5 8.01l12.5-.01a.5.5 0 00.416-.777 7.182 7.182 0 00-2.183-2.113z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});