define("ember-svg-jar/inlined/hotel-single-bed-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hotel-single-bed-2</title><path d=\"M23.225 15.5a.5.5 0 00.492-.592l-1.489-8a.5.5 0 00-.491-.408H2.263a.5.5 0 00-.491.408l-1.489 8a.5.5 0 00.492.592zM.5 16.5a.5.5 0 00-.5.5v3.25a2.253 2.253 0 002.25 2.25.25.25 0 01.25.25v.5a.75.75 0 001.5 0V23a.5.5 0 01.5-.5h15a.5.5 0 01.5.5v.25a.75.75 0 001.5 0v-.5a.25.25 0 01.25-.25A2.253 2.253 0 0024 20.25V17a.5.5 0 00-.5-.5zM6.115 5.5a.4.4 0 00.392-.333l.333-2a.5.5 0 01.493-.417h9.334a.5.5 0 01.493.417l.333 2a.4.4 0 00.392.333H21.5A.5.5 0 0022 5V2.25A2.253 2.253 0 0019.75 0H4.25A2.253 2.253 0 002 2.25V5a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});