define("ember-svg-jar/inlined/tool-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tool-box</title><path d=\"M9.375 13.5a.75.75 0 01.75-.75h3.75a.75.75 0 01.75.75v.25a.5.5 0 00.5.5H23a.5.5 0 00.5-.5V10a1 1 0 00-1-1h-5.525a5 5 0 00-9.95 0H1.5a1 1 0 00-1 1v3.75a.5.5 0 00.5.5h7.875a.5.5 0 00.5-.5zM12 6.5A3 3 0 0114.958 9H9.042A3 3 0 0112 6.5zM14.625 16.626a2.629 2.629 0 01-3.149 2.574 2.7 2.7 0 01-2.1-2.678v-.271a.5.5 0 00-.5-.5H1a.5.5 0 00-.5.5V23a1 1 0 001 1h21a1 1 0 001-1v-6.75a.5.5 0 00-.5-.5h-7.83a.5.5 0 00-.545.5z\"/><path d=\"M12.625 14.251h-1.25a.5.5 0 00-.5.5v1.875a1.125 1.125 0 002.25 0v-1.875a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});