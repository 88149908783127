define("ember-svg-jar/inlined/music-genre-burn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-genre-burn</title><path d=\"M20.268 5.051a1 1 0 00-1.693.179 1.84 1.84 0 01-1.857 1.052.249.249 0 01-.153-.055A4.939 4.939 0 0115.246 1.3 1 1 0 0014.215 0a7.6 7.6 0 00-7.232 6.047.251.251 0 01-.295.192 2.119 2.119 0 01-1.463-1.1 1 1 0 00-.76-.543 1.013 1.013 0 00-.878.322c-1.453 1.617-1.674 4.658-.527 7.236a7.767 7.767 0 003.726 3.87.244.244 0 00.191.012.247.247 0 00.144-.127 3.252 3.252 0 012.215-1.752l3.333-1.077a1.643 1.643 0 01.167-.044 3.272 3.272 0 012.783.667 3.237 3.237 0 011.14 2.037.251.251 0 00.349.193 7.543 7.543 0 003.854-3.533 7.114 7.114 0 00-.694-7.349z\"/><path d=\"M15.544 16.2a2 2 0 00-2.369-1.967c-.041.008-3.572 1.14-3.572 1.14a2 2 0 00-1.559 1.957v2.984a.251.251 0 01-.188.242 1.75 1.75 0 102.188 1.694v-4.738a.25.25 0 01.174-.238l3-.958a.251.251 0 01.327.239v1.759a.251.251 0 01-.188.242 1.75 1.75 0 102.188 1.694z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});