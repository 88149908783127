define("ember-svg-jar/inlined/pollution-skull", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pollution-skull</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm8.1 17.863a.5.5 0 01-.758.06l-1.131-1.13a1 1 0 00-1.414 1.414l1.13 1.13a.5.5 0 01-.06.759 9.979 9.979 0 01-11.726 0 .5.5 0 01-.06-.759l1.13-1.13a1 1 0 00-1.414-1.414l-1.13 1.13a.5.5 0 01-.759-.06 9.979 9.979 0 010-11.726.5.5 0 01.759-.06l1.13 1.13a1 1 0 001.41-1.414l-1.13-1.13a.5.5 0 01.06-.759 9.979 9.979 0 0111.726 0 .5.5 0 01.06.759l-1.13 1.13a1 1 0 001.414 1.414l1.131-1.13a.5.5 0 01.758.06 9.979 9.979 0 010 11.726z\"/><path d=\"M12 6a5.506 5.506 0 00-5.5 5.5v2a2.48 2.48 0 001.682 2.347.47.47 0 01.318.445v.208a1.5 1.5 0 001.2 1.47.251.251 0 00.3-.245V16.5a.5.5 0 011 0v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 011 0v1.225a.251.251 0 00.3.245 1.5 1.5 0 001.2-1.47v-.194a.5.5 0 01.333-.472 2.5 2.5 0 001.667-2.356V11.5A5.506 5.506 0 0012 6zm-2.5 6.5a1 1 0 111-1 1 1 0 01-1 1zm5 0a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});