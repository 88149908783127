define("ember-svg-jar/inlined/messages-bubble-settings-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-settings-1-alternate</title><path d=\"M10.1 15.208a.25.25 0 00-.287-.188.95.95 0 00-.522.273L7 17.586V15.56a1 1 0 00-.664-.942A6.494 6.494 0 018.5 2h7A6.507 6.507 0 0122 8.5a6.408 6.408 0 01-.445 2.344.251.251 0 00.264.34 3.162 3.162 0 011.427.136.248.248 0 00.316-.158A8.379 8.379 0 0024 8.5 8.509 8.509 0 0015.5 0h-7A8.492 8.492 0 005 16.232V20a1 1 0 001.706.707L10.413 17h.324a.195.195 0 00.143-.327 3.262 3.262 0 01-.78-1.465z\"/><circle cx=\"17.748\" cy=\"17.031\" r=\"1.5\"/><path d=\"M23.516 15.653a1.783 1.783 0 00-1.7-2.953l-1.412.327a.449.449 0 01-.532-.31l-.424-1.392a1.775 1.775 0 00-3.4 0l-.424 1.392a.456.456 0 01-.533.31l-1.41-.327a1.783 1.783 0 00-1.7 2.953l.988 1.066a.463.463 0 010 .625l-.988 1.065a1.783 1.783 0 001.7 2.953l1.411-.327a.453.453 0 01.533.311l.424 1.391a1.775 1.775 0 003.4 0l.424-1.391a.449.449 0 01.533-.311l1.411.327a1.783 1.783 0 001.7-2.953l-.987-1.065a.463.463 0 010-.625zm-2.768 1.378a3 3 0 11-3-3 3 3 0 013 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});