define("ember-svg-jar/inlined/style-two-pin-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-two-pin-home</title><path d=\"M12 0a9.511 9.511 0 00-9.5 9.5c0 6.544 6.537 12.487 8.541 14.153a1.5 1.5 0 001.917 0c2-1.666 8.542-7.609 8.542-14.154A9.511 9.511 0 0012 0zm6.824 9.118A.5.5 0 0118.5 10h-1a.5.5 0 00-.5.5V15a.5.5 0 01-.5.5H14a.5.5 0 01-.5-.5v-2.25a1.5 1.5 0 00-3 0V15a.5.5 0 01-.5.5H7.5A.5.5 0 017 15v-4.5a.5.5 0 00-.5-.5h-1a.5.5 0 01-.323-.882l6.5-5.5a.5.5 0 01.647 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});