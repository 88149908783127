define("ember-svg-jar/inlined/design-file-3ds-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-3ds-1</title><path d=\"M23.707 5.708L18.293.293A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.75V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.706z\"/><path d=\"M8.456 12.319a.249.249 0 00-.331.24v3.882a.25.25 0 00.331.241A2.239 2.239 0 009.875 14.5a2.238 2.238 0 00-1.419-2.181z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zM5.7 14.647A2.29 2.29 0 016.125 16a2.014 2.014 0 01-1.865 2.121h-.757A1.736 1.736 0 011.875 16.3a.625.625 0 011.25 0c0 .339.2.575.375.575h.754c.337 0 .621-.4.621-.873s-.285-.873-.623-.873a.625.625 0 110-1.25c.338 0 .623-.4.623-.873s-.284-.872-.621-.873h-.757c-.175 0-.372.236-.372.575a.625.625 0 01-1.25 0A1.736 1.736 0 013.5 10.875h.76A2.014 2.014 0 016.125 13a2.3 2.3 0 01-.425 1.354.242.242 0 000 .293zm1.8 3.478a.625.625 0 01-.625-.625v-6a.625.625 0 01.625-.625 3.625 3.625 0 010 7.25zm5.927-4.758l1.84 1.226a1.928 1.928 0 01-1.07 3.532H12.5a.625.625 0 010-1.25h1.7a.678.678 0 00.376-1.242l-1.84-1.226a1.928 1.928 0 011.07-3.532h1.7a.625.625 0 010 1.25H13.8a.678.678 0 00-.376 1.242z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});