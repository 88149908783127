define("ember-svg-jar/inlined/weather-app-sun-coudy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-app-sun-coudy</title><path d=\"M14 18.5V21a1 1 0 01-1 1H3a1 1 0 01-1-1V3a1 1 0 011-1h5a1 1 0 000-2H3a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3v-2.5a1 1 0 10-2 0z\"/><path d=\"M10 19H6a.75.75 0 000 1.5h4a.75.75 0 000-1.5zM21 7a4 4 0 00-7.177-2.427.251.251 0 00.061.362 5.793 5.793 0 011.1.931.249.249 0 00.387-.025 2 2 0 112.65 2.878.249.249 0 00-.072.365 5.219 5.219 0 01.695 1.251.25.25 0 00.359.126A4 4 0 0021 7z\"/><circle cx=\"17\" cy=\"1\" r=\"1\"/><path d=\"M21.95 3.464a1 1 0 10-1.414 0 1 1 0 001.414 0z\"/><circle cx=\"23\" cy=\"7\" r=\"1\"/><path d=\"M20.536 10.536a1 1 0 101.414 0 1 1 0 00-1.414 0zM13.464 3.464a1 1 0 10-1.414 0 1 1 0 001.414 0zM17.5 12.25a3.729 3.729 0 00-2.516-3.544.25.25 0 01-.159-.165 4.251 4.251 0 00-8.312.87.248.248 0 01-.178.22A3.25 3.25 0 007.25 16h6.5a3.755 3.755 0 003.75-3.75zm-12 .5A1.759 1.759 0 017.284 11a.86.86 0 00.569-.259.746.746 0 00.175-.6A2.858 2.858 0 018 9.75a2.751 2.751 0 015.477-.365.747.747 0 00.627.642 2.251 2.251 0 01-.354 4.473h-6.5a1.752 1.752 0 01-1.75-1.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});