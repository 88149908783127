define("ember-svg-jar/inlined/uv-sun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>uv-sun</title><path d=\"M12.347 16.051a.251.251 0 00-.258-.38 4.506 4.506 0 112.051-.971.169.169 0 00-.051.19.172.172 0 00.161.11 2.238 2.238 0 011.35.45.249.249 0 00.3 0 2.25 2.25 0 01.487-.278.244.244 0 00.105-.083 6.5 6.5 0 10-5.242 2.661c.175 0 .349-.007.52-.021a.25.25 0 00.23-.249v-.23a2.231 2.231 0 01.347-1.199zM3.5 11.25a1 1 0 00-1-1H1a1 1 0 000 2h1.5a1 1 0 001-1zM4.355 5.77A1 1 0 005.77 4.355L4.709 3.3A1 1 0 003.3 4.709zM11.25 3.5a1 1 0 001-1V1a1 1 0 00-2 0v1.5a1 1 0 001 1zM4.355 16.73L3.3 17.791A1 1 0 104.709 19.2l1.061-1.06a1 1 0 00-1.415-1.41zM19 11.25a1 1 0 001 1h1.5a1 1 0 000-2H20a1 1 0 00-1 1zM17.438 6.063a1 1 0 00.707-.293L19.2 4.709A1 1 0 0017.791 3.3L16.73 4.355a1 1 0 00.708 1.708zM18 17.25a.75.75 0 00-1.5 0v4.5a.75.75 0 01-1.5 0v-4.5a.75.75 0 00-1.5 0v4.5a2.25 2.25 0 004.5 0zM23.25 16.5a.75.75 0 00-.75.75v1.5a6.793 6.793 0 01-.519 2.591.25.25 0 01-.462 0A6.793 6.793 0 0121 18.75v-1.5a.75.75 0 00-1.5 0v1.5a8.3 8.3 0 001.65 4.95.75.75 0 001.2 0A8.3 8.3 0 0024 18.75v-1.5a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});