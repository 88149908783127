define("ember-svg-jar/inlined/spirits-glass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>spirits-glass</title><path d=\"M5.5 3h3a.5.5 0 00.5-.5V1a1 1 0 00-1-1H6a1 1 0 00-1 1v1.5a.5.5 0 00.5.5zM11.381 10.022c-.171-.1-.34-.2-.505-.3C9.912 9.151 9 8.613 9 8V5a.5.5 0 00-.5-.5h-3A.5.5 0 005 5v3c0 .613-.912 1.151-1.876 1.721C1.732 10.541 0 11.563 0 13.5a71.843 71.843 0 00.753 8.847A2 2 0 002.723 24h7.429a.5.5 0 00.478-.648 2.853 2.853 0 01-.13-.852v-2a.5.5 0 00-.5-.5H4a.5.5 0 01-.5-.5v-4A.5.5 0 014 15h6a.5.5 0 00.5-.5V13a2.966 2.966 0 01.959-2.184.5.5 0 00-.078-.794zM22.25 11.5h-8.5A1.752 1.752 0 0012 13.25v8A2.753 2.753 0 0014.75 24h6.5A2.753 2.753 0 0024 21.25v-8a1.752 1.752 0 00-1.75-1.75zM15 19a1 1 0 111-1 1 1 0 01-1 1zm2.5 3a1 1 0 111-1 1 1 0 01-1 1zm3.5-2.5a1 1 0 111-1 1 1 0 01-1 1zm1.5-4.5a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5v-1.75a.253.253 0 01.25-.25h8.5a.253.253 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});