define("ember-svg-jar/inlined/bird-house.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bird-house</title><path d=\"M23.668 10.875L13.38 1.628a2.087 2.087 0 00-2.76 0L.332 10.875a1 1 0 101.336 1.488l2.669-2.379 2.57 12.024A1.153 1.153 0 008 22.893h8a1.155 1.155 0 001.094-.884l2.591-12.025 2.647 2.379a1 1 0 101.336-1.488zM12 14.834a3 3 0 113-3 3 3 0 01-3 3zm1.5 3.785a1.5 1.5 0 11-1.5-1.5 1.5 1.5 0 011.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});