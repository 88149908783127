define("ember-svg-jar/inlined/phone-hand-hold-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-hand-hold-1</title><path d=\"M21 0H11a3 3 0 00-3 3v3H7a7 7 0 00-7 7v5.546a3.839 3.839 0 002 3.527V23a1 1 0 002 0v-1.5a1 1 0 00-.515-.874C2.32 19.99 2 19.545 2 18.546V13a5 5 0 015-5h.75a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25H6a1 1 0 000 2h5.5a1 1 0 010 2H8.467a1 1 0 00-.967 1.03 2.172 2.172 0 01-1.078 1.835 2.038 2.038 0 01-.3.175 1 1 0 00.378 1.927 1.012 1.012 0 00.375-.073A4.165 4.165 0 008 19.187V21a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5v-2h1.5a3 3 0 000-6H10V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});