define("ember-svg-jar/inlined/image-file-gif", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-gif</title><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.427A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.25a.25.25 0 00-.25-.25zm-11.625 7.5a.625.625 0 01-1.25 0V14a.875.875 0 00-1.75 0v3a.875.875 0 001.664.379c.038-.079.093-.254-.113-.254H8.5a.625.625 0 010-1.25h1a.625.625 0 01.625.625v.5a2.125 2.125 0 01-4.25 0v-3a2.125 2.125 0 014.25 0zm3.25 3.375h.125a.625.625 0 010 1.25h-2a.625.625 0 010-1.25h.125a.25.25 0 00.25-.25v-4.25a.25.25 0 00-.25-.25H11.5a.625.625 0 010-1.25h2a.625.625 0 010 1.25h-.125a.25.25 0 00-.25.25v4.25a.25.25 0 00.25.25zm5.125-4.75h-2a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25H17.5a.625.625 0 010 1.25h-1.125a.25.25 0 00-.25.25V18.5a.625.625 0 01-1.25 0v-5a1.626 1.626 0 011.625-1.625h2a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});