define("ember-svg-jar/inlined/photo-frame-hang-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>photo-frame-hang-1</title><path d=\"M24 7.5a3 3 0 00-3-3h-3.764a.254.254 0 01-.173-.069L12.688.275a1 1 0 00-1.376 0L6.937 4.431a.254.254 0 01-.173.069H3a3 3 0 00-3 3V21a3 3 0 003 3h18a3 3 0 003-3zM11.828 2.543a.251.251 0 01.344 0l1.606 1.526a.25.25 0 01-.172.431h-3.212a.25.25 0 01-.172-.431zM21 6.5a1 1 0 011 1V21a1 1 0 01-1 1H3a1 1 0 01-1-1V7.5a1 1 0 011-1z\"/><path d=\"M19.25 19.5h-1.811a.25.25 0 01-.188-.085A18.977 18.977 0 0112.748 8.7a.75.75 0 00-1.5 0 19.045 19.045 0 01-4.5 10.718.249.249 0 01-.188.086H4.75a.75.75 0 000 1.5h14.5a.75.75 0 000-1.5zm-7.487-5.876a.251.251 0 01.474 0 20.389 20.389 0 00.93 2.267.254.254 0 01-.013.242.25.25 0 01-.212.117h-1.884a.25.25 0 01-.212-.117.254.254 0 01-.013-.242 20.389 20.389 0 00.93-2.267zm-2.016 4.244a.25.25 0 01.212-.118h4.082a.25.25 0 01.212.118c.288.464.57.877.83 1.235a.249.249 0 01-.2.4H9.119a.25.25 0 01-.2-.4c.258-.358.54-.771.828-1.235z\"/><path d=\"M5.25 18.917a.75.75 0 00.75-.75v-2.084a.75.75 0 00-1.5 0v2.084a.75.75 0 00.75.75zM18.583 18.917a.75.75 0 00.75-.75v-2.084a.75.75 0 00-1.5 0v2.084a.75.75 0 00.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});