define("ember-svg-jar/inlined/clothes-design-thread-boots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>clothes-design-thread-boots</title><rect x=\"7\" width=\"10\" height=\"4\" rx=\"1.5\" ry=\"1.5\"/><path d=\"M10.5 16.937a.5.5 0 00-.375-.484A1.5 1.5 0 019 15v-2a1.5 1.5 0 011.5-1.5H16a.5.5 0 00.5-.5V6.5a1 1 0 00-1-1h-7a1 1 0 00-1 1v4.747A1.754 1.754 0 015.747 13H3.5a3.5 3.5 0 000 7H10a.5.5 0 00.5-.5z\"/><path d=\"M23 16a1 1 0 00-1 1v1a1 1 0 01-1 1h-.25a.25.25 0 01-.25-.25v-3.5a.25.25 0 01.25-.25H21a1 1 0 000-2h-9.5a1 1 0 000 2h.25a.25.25 0 01.25.25v6.5a.25.25 0 01-.25.25h-.25a1 1 0 000 2H21a1 1 0 000-2h-.25a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25H21a3 3 0 003-3v-1a1 1 0 00-1-1zm-4.75-1a.25.25 0 01.25.25v2a.25.25 0 01-.25.25h-4a.25.25 0 01-.25-.25v-2a.25.25 0 01.25-.25zm-4 7a.25.25 0 01-.25-.25v-2a.25.25 0 01.25-.25h4a.25.25 0 01.25.25v2a.25.25 0 01-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});