define("ember-svg-jar/inlined/network-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>network-heart</title><path d=\"M12.576 22.106A9.388 9.388 0 018.8 17.778a.249.249 0 01.147-.341 1 1 0 00-.323-1.937h-.4a.25.25 0 01-.244-.2 15.128 15.128 0 01-.356-3.3 15.3 15.3 0 01.254-2.8.25.25 0 01.246-.2h8a.249.249 0 01.245.2c.075.4.138.8.18 1.242a1 1 0 101.99-.189 15.576 15.576 0 00-.123-.968.251.251 0 01.251-.285h2.805a.249.249 0 01.239.178 9.519 9.519 0 01.3 1.313 1 1 0 001.977-.3C22.507.389 12.638-.035 12.056 0a12 12 0 00.066 24h.006a1.022 1.022 0 00.892-.572 1.035 1.035 0 00-.444-1.322zM5.92 15.2a.252.252 0 01-.246.3h-2.73a.252.252 0 01-.236-.166 9.886 9.886 0 01-.177-6.155.25.25 0 01.24-.179h2.806a.251.251 0 01.248.288A17.876 17.876 0 005.624 12a17.2 17.2 0 00.296 3.2zM8.847 7a.25.25 0 01-.231-.346 10.055 10.055 0 013.364-4.386.249.249 0 01.29 0 10.1 10.1 0 013.36 4.385.25.25 0 01-.23.347zm11.687-.386a.25.25 0 01-.21.386h-2.263a.25.25 0 01-.237-.171 13.157 13.157 0 00-1.743-3.483.251.251 0 01.312-.371 10.16 10.16 0 014.141 3.638zM7.857 2.969a.25.25 0 01.311.37 13.181 13.181 0 00-1.746 3.488.249.249 0 01-.237.173H3.923a.25.25 0 01-.21-.385 10.042 10.042 0 014.144-3.646zM4.059 17.9a.25.25 0 01.2-.4h2.089a.25.25 0 01.234.163 12.86 12.86 0 001.583 3 .25.25 0 01-.311.371A10.048 10.048 0 014.059 17.9z\"/><path d=\"M21.039 12.545a3.481 3.481 0 00-3.058.987l-.305.3a.25.25 0 01-.353 0l-.3-.3a3.526 3.526 0 00-5.646.916 3.52 3.52 0 00.653 4.062l5.113 5.335a.5.5 0 00.723 0l5.106-5.327a3.525 3.525 0 00-1.928-5.973z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});