define("ember-svg-jar/inlined/single-neutral-actions-shield.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-shield</title><path d=\"M9.5 13.456a2.928 2.928 0 011.8-2.756.25.25 0 00-.028-.457l-.4-.148a.6.6 0 01-.138-.311 2.573 2.573 0 01.058-1.3 5.618 5.618 0 001.466-4.314C12.256 1.756 10.677 0 8.5 0S4.744 1.756 4.744 4.174A5.605 5.605 0 006.2 8.471a2.453 2.453 0 01.073 1.316.615.615 0 01-.135.309l-.631.232c-2.447.9-4.215 1.55-4.754 2.626A9.173 9.173 0 000 16.5a.5.5 0 00.5.5h8.75a.249.249 0 00.25-.251zM23.072 12.04A16.386 16.386 0 0017.479 11a15.988 15.988 0 00-5.551 1.04A1.545 1.545 0 0011 13.456v3.393a7.718 7.718 0 005.19 6.805l.553.211a2.071 2.071 0 00.736.135 2.04 2.04 0 00.734-.136l.554-.21C21.848 22.48 24 19.682 24 16.849v-3.393a1.545 1.545 0 00-.928-1.416zM18.25 15.5a.25.25 0 00.25.25h2a.75.75 0 010 1.5h-2a.25.25 0 00-.25.25v2a.75.75 0 01-1.5 0v-2a.25.25 0 00-.25-.25h-2a.75.75 0 010-1.5h2a.25.25 0 00.25-.25v-2a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});