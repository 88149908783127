define("ember-svg-jar/inlined/file-pdf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-pdf</title><path d=\"M8 9.623h-.623a.252.252 0 00-.252.252v1.246a.252.252 0 00.252.252H8a.875.875 0 000-1.75zM12.451 9.822c-.105-.046-.326-.048-.326.164v3.978a.238.238 0 00.327.21 2.375 2.375 0 000-4.352z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM8 12.623h-.623a.252.252 0 00-.252.252V15a.625.625 0 01-1.25 0V9a.625.625 0 01.625-.627H8a2.125 2.125 0 010 4.25zm3.5 3a.625.625 0 01-.625-.623V9a.625.625 0 01.625-.625 3.625 3.625 0 010 7.25zm8-6h-2a.375.375 0 00-.375.375v1.123a.252.252 0 00.252.252H18.5a.625.625 0 010 1.25h-1.123a.252.252 0 00-.252.252V15a.625.625 0 01-1.25 0v-5A1.627 1.627 0 0117.5 8.373h2a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});