define("ember-svg-jar/inlined/multiple-actions-block.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-block</title><path d=\"M14.472 8.4l-.485-.172a1.791 1.791 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 0012 0a2.986 2.986 0 00-1.34.315.25.25 0 00-.1.363 5 5 0 01.84 2.808 6.259 6.259 0 01-.922 3.624.254.254 0 00-.029.216.249.249 0 00.151.156c1.851.692 3.2 1.286 3.839 2.414a.25.25 0 00.3.112 7.3 7.3 0 011.551-.4.25.25 0 00.061-.477 33.82 33.82 0 00-1.879-.731z\"/><path d=\"M13.207 10.754a.25.25 0 00.073-.352c-.546-.8-1.934-1.313-3.808-2l-.485-.172a1.791 1.791 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 007 0a3.219 3.219 0 00-3.144 3.486 4.609 4.609 0 001.192 3.538 1.877 1.877 0 010 1.185l-.514.19c-2.01.739-3.46 1.272-3.915 2.179A7.542 7.542 0 000 13.5a.5.5 0 00.5.5h9.667a.25.25 0 00.223-.137 7.788 7.788 0 012.817-3.109zM23.832 16A6.422 6.422 0 0019 11.169 6.513 6.513 0 0011.169 19 6.418 6.418 0 0016 23.832a6.76 6.76 0 001.49.167 6.5 6.5 0 006.341-8zm-8.442 5.481a.251.251 0 01-.058-.4l5.752-5.751a.252.252 0 01.213-.071.246.246 0 01.184.129 4.119 4.119 0 01.4 1.05 4.51 4.51 0 01-6.491 5.04zm4.223-7.96a.251.251 0 01.058.4l-5.753 5.749a.252.252 0 01-.213.071.248.248 0 01-.184-.129 4.508 4.508 0 016.092-6.091z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});