define("ember-svg-jar/inlined/shield-globe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shield-globe</title><path d=\"M24 1.954A1.96 1.96 0 0022.043.006H1.96A1.961 1.961 0 00.012 1.964L0 9.305a15.143 15.143 0 0011.861 14.669.976.976 0 00.4 0A15.144 15.144 0 0023.988 9.2zM10.1 13.179a.5.5 0 01.469-.674h2.91a.5.5 0 01.469.674 9.439 9.439 0 01-1.736 2.928.254.254 0 01-.188.084.248.248 0 01-.187-.085 9.419 9.419 0 01-1.737-2.927zm0-1.674a.5.5 0 01-.5-.435 8.438 8.438 0 01-.071-1.065A8.248 8.248 0 019.6 8.941a.5.5 0 01.5-.436h3.865a.5.5 0 01.5.436 8.016 8.016 0 010 2.129.5.5 0 01-.5.435zm-4.567-1.5a6.266 6.266 0 01.1-1.086.5.5 0 01.492-.414h1.951a.5.5 0 01.5.548 10.104 10.104 0 000 1.905.5.5 0 01-.5.547H6.117a.5.5 0 01-.492-.413 6.278 6.278 0 01-.097-1.087zm8.424-3.173a.5.5 0 01-.469.673h-2.91a.5.5 0 01-.468-.673 9.413 9.413 0 011.736-2.926.248.248 0 01.187-.084.254.254 0 01.188.084 9.425 9.425 0 011.731 2.926zm1.53 2.221a.5.5 0 01.5-.548h1.959a.5.5 0 01.493.414 6.2 6.2 0 010 2.173.5.5 0 01-.493.413H15.98a.5.5 0 01-.5-.547 10.109 10.109 0 00.002-1.905zm2.172-2.3a.5.5 0 01-.432.751H15.6a.5.5 0 01-.48-.36 10.155 10.155 0 00-1.4-2.952.249.249 0 01.28-.379 6.534 6.534 0 013.654 2.941zm-7.6-2.941a.25.25 0 01.282.38 10.155 10.155 0 00-1.408 2.953.5.5 0 01-.48.36H6.835a.5.5 0 01-.435-.751 6.531 6.531 0 013.657-2.941zM6.4 13.256a.5.5 0 01.432-.751h1.621a.5.5 0 01.48.361 10.148 10.148 0 001.4 2.951.25.25 0 01-.282.381A6.527 6.527 0 016.4 13.256zM14 16.2a.251.251 0 01-.282-.381 10.148 10.148 0 001.405-2.951.5.5 0 01.48-.361h1.618a.5.5 0 01.432.751A6.53 6.53 0 0114 16.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});