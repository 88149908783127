define("ember-svg-jar/inlined/zip-file-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>zip-file-share</title><path d=\"M9.609 17.278a4.077 4.077 0 011.031-1.9 4.21 4.21 0 014.366-1.182.235.235 0 00.2-.022l1.763-1.057a.241.241 0 00.111-.162 4.22 4.22 0 01.872-1.869.235.235 0 00.048-.15V5.207a1.5 1.5 0 00-.439-1.06L13.854.439A1.5 1.5 0 0012.793 0H1.5A1.5 1.5 0 000 1.5v19A1.5 1.5 0 001.5 22h9.459a.248.248 0 00.147-.448 4.212 4.212 0 01-1.497-4.274zM6 18.5a1 1 0 111-1 1 1 0 01-1 1zM6 15a1 1 0 111-1 1 1 0 01-.995 1zm0-3.5a1 1 0 111-1 1 1 0 01-.995 1zm1.5-4.9a1.968 1.968 0 01-1.479.67 1.975 1.975 0 01-2.005-2.221l.49-3.127A.5.5 0 015 1.5h2a.5.5 0 01.494.423l.485 3.111v.015A1.985 1.985 0 017.508 6.6z\"/><path d=\"M22.129 18.639a2.74 2.74 0 00-2.767.623.24.24 0 01-.258.053L16.553 18.3a.114.114 0 01-.06-.084l-.03-.353a.118.118 0 01.056-.1L19.442 16a.245.245 0 01.266.012 2.741 2.741 0 004.168-1.425A2.759 2.759 0 0021.25 11a2.808 2.808 0 00-2.713 3.059.243.243 0 01-.115.221L15.558 16a.245.245 0 01-.266-.012 2.741 2.741 0 00-4.168 1.426A2.759 2.759 0 0013.75 21a2.729 2.729 0 001.887-.761.245.245 0 01.26-.053l2.463 1a.239.239 0 01.144.214 2.753 2.753 0 105.361-1.025 2.681 2.681 0 00-1.736-1.736z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});