define("ember-svg-jar/inlined/cash-payment-bills", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-payment-bills</title><path d=\"M22.263 3.354A1 1 0 0021.5 3h-9a1 1 0 00-.991 1.132l1 7.5a1 1 0 101.982-.264l-.811-6.085a.252.252 0 01.06-.2.25.25 0 01.188-.083h6.1a.25.25 0 01.191.088.253.253 0 01.056.2l-1.1 6.571A1 1 0 0020 13.013a1.031 1.031 0 00.165.014 1 1 0 00.985-.836l1.338-8.026a1 1 0 00-.225-.811z\"/><path d=\"M19 14h-6.03a.25.25 0 01-.225-.141l-.921-1.908a.5.5 0 00-.393-.279.491.491 0 00-.447.183L9.1 14.206l-.007.01a.867.867 0 01-.593.284h-1a.5.5 0 00-.5.5v6a.5.5 0 00.2.4c2.3 1.725 4.083 2.6 5.3 2.6h5.546a1.713 1.713 0 001.929-1.342A55.867 55.867 0 0021 15.5c0-.746-.687-1.5-2-1.5zM4.833 13.5H1.75a.25.25 0 00-.25.25v9a.25.25 0 00.25.25h3.083a.6.6 0 00.667-.594v-8.312a.6.6 0 00-.667-.594z\"/><circle cx=\"17\" cy=\"9\" r=\"1.5\"/><path d=\"M10 11a.927.927 0 00.165-.014 1 1 0 00.822-1.151L9.729 2.291A.25.25 0 019.976 2H18a1 1 0 000-2H8.5a1 1 0 00-.986 1.165l1.5 9A1 1 0 0010 11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});