define("ember-svg-jar/inlined/single-neutral-id-card-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-id-card-2</title><path d=\"M21.5 5a2 2 0 00-2-2H17a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h2a.5.5 0 01.5.5v12.25a.5.5 0 01-.5.5h-1.48c-.575-1.141-2.392-1.743-3.673-2.2a.977.977 0 01-.027-.7 5 5 0 001.149-3.534A2.909 2.909 0 0013.217 9a3.308 3.308 0 00-2.434 0 2.909 2.909 0 00-1.752 2.82 4.979 4.979 0 001.13 3.511 1 1 0 01.007.71c-1.368.491-3.119 1.088-3.688 2.209H5a.5.5 0 01-.5-.5V5.5A.5.5 0 015 5h2a.5.5 0 00.5-.5v-1A.5.5 0 007 3H4.5a2 2 0 00-2 2v17a2 2 0 002 2h15a2 2 0 002-2zM5 22a.5.5 0 01-.5-.5v-.75a.5.5 0 01.5-.5h14a.5.5 0 01.5.5v.75a.5.5 0 01-.5.5z\"/><path d=\"M9 6a1.5 1.5 0 001.5 1.5h3A1.5 1.5 0 0015 6V3a3 3 0 00-6 0zm2-3.5a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});