define("ember-svg-jar/inlined/cloud-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-image</title><path d=\"M2.587 15.107A1 1 0 004 14.2v-.009a.963.963 0 00-.571-.9 2.482 2.482 0 01-.79-.509A2.371 2.371 0 012 11a2.588 2.588 0 012.586-2.588 2.635 2.635 0 01.535.056 1 1 0 001.2-.965c.084-5.737 8.296-7.371 10.821-2.045a1.024 1.024 0 00.977.569A3.689 3.689 0 0121.058 7.3a3.822 3.822 0 01.928 2.868 3.422 3.422 0 01-1.564 2.707.966.966 0 00-.422.809 1 1 0 001.56.825A5.4 5.4 0 0024 10.051a5.766 5.766 0 00-5.215-6 .252.252 0 01-.191-.12 7.685 7.685 0 00-14.1 2.3.251.251 0 01-.227.2A4.642 4.642 0 00.62 8.672 4.479 4.479 0 000 11.119a4.287 4.287 0 001.235 3.09 4.441 4.441 0 001.352.898z\"/><path d=\"M18.75 12.3a2.015 2.015 0 00-2-2h-9.5a2.015 2.015 0 00-2 2v9.5a2 2 0 002 2h9.5a2 2 0 002-2zm-2 4.9a.25.25 0 01-.458.138l-.268-.4A1.277 1.277 0 0015 16.383a1.54 1.54 0 00-.61.171 1.189 1.189 0 00-.429.362l-1.537 2.2a.25.25 0 01-.361.052l-.612-.49a1.249 1.249 0 00-1.82.283l-1.8 2.706a.25.25 0 01-.331.079.482.482 0 01-.246-.421V12.8a.5.5 0 01.5-.5h8.5a.5.5 0 01.5.5z\"/><path d=\"M10.981 16.554a1.7 1.7 0 10-.887.25 1.744 1.744 0 00.887-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});