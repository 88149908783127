define("ember-svg-jar/inlined/web-service-baidu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>web-service-baidu</title><path d=\"M6.64 9.08c.14 2-.92 3.62-2.37 3.72s-2.73-1.4-2.86-3.36.92-3.62 2.37-3.72S6.5 7.13 6.64 9.08M16.58 1c-1.4-.36-2.93.89-3.41 2.79s.26 3.73 1.66 4.09 2.94-.88 3.42-2.81A3.66 3.66 0 0016.58 1m3.29 6.4c-1.48 0-2.65 1.43-2.61 3.6s1.25 3.17 2.73 3.14 2.65-1 2.62-3.22a3.4 3.4 0 00-2.73-3.52M9.18.5C7.78.5 6.64 2.08 6.64 4s1.14 3.53 2.54 3.53S11.72 6 11.72 4 10.58.5 9.18.5m-.32 10.92c-2.93 4.21-6.06 4.75-6.06 7.83 0 1.52.87 4 3.33 4s3.65-.54 5.6-.54 3.25.76 5.71.76a3.61 3.61 0 003.49-3.85c-.22-3.18-3.3-2.95-6.41-8.45a3 3 0 00-2.79-1.3 3.33 3.33 0 00-2.87 1.52\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});