define("ember-svg-jar/inlined/flags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flags</title><path d=\"M22.838 6.867l-6.226-3.2.228-.446a1 1 0 00-1.78-.911l-2.837 5.545a.25.25 0 01-.223.136.247.247 0 01-.222-.136L8.94 2.312a1 1 0 00-1.78.911l.228.446-6.226 3.2c-.592.3-.636.691-.608.895s.169.566.82.705l2.7.576a.25.25 0 01.185.324l-.919 2.755a.89.89 0 00.191 1.067c.083.067.442.339 1.082-.044l5.61-3.351a.254.254 0 01.2-.025.25.25 0 01.153.126l6.034 11.787a1 1 0 101.78-.911L13.182 10.6a.248.248 0 010-.228l.243-.475a.252.252 0 01.351-.1l5.61 3.351c.641.383 1 .111 1.083.044a.891.891 0 00.189-1.067l-.918-2.758a.25.25 0 01.185-.324l2.7-.576c.651-.139.793-.5.82-.705s-.015-.591-.607-.895z\"/><path d=\"M5.61 20.773a1 1 0 101.78.911l3.99-7.793a.254.254 0 000-.228l-.843-1.646a.25.25 0 00-.445 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});