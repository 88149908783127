define("ember-svg-jar/inlined/meeting-remote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-remote</title><path d=\"M6.765 12.588h11.47A2.768 2.768 0 0021 9.823V2.764A2.768 2.768 0 0018.235 0H6.765A2.768 2.768 0 004 2.764v7.059a2.768 2.768 0 002.765 2.765zM6 2.764A.766.766 0 016.765 2h11.47a.766.766 0 01.765.764v7.059a.766.766 0 01-.765.765h-1.757a.248.248 0 01-.176-.073 5.142 5.142 0 00-2.161-1.29.249.249 0 01-.058-.453 2.85 2.85 0 10-2.929.015.25.25 0 01-.053.454 5.14 5.14 0 00-2.111 1.274.251.251 0 01-.176.073H6.765A.766.766 0 016 9.823zM23.39 23.185a5.154 5.154 0 00-2.54-1.7.25.25 0 01-.058-.454 2.849 2.849 0 10-2.931.016.25.25 0 01-.053.454 5.108 5.108 0 00-1.908 1.077.251.251 0 01-.334 0 5.117 5.117 0 00-1.962-1.1.251.251 0 01-.058-.454 2.85 2.85 0 10-2.93.016.25.25 0 01-.053.454 5.123 5.123 0 00-2.012 1.178.249.249 0 01-.344 0 5.136 5.136 0 00-2.037-1.186.25.25 0 01-.055-.454 2.85 2.85 0 10-2.93 0 .25.25 0 01-.056.454 5.149 5.149 0 00-2.515 1.7A.5.5 0 001 24h22a.5.5 0 00.39-.814z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});