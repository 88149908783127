define("ember-svg-jar/inlined/outdoors-landscape-meadow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-landscape-meadow</title><path d=\"M22.5 16a1.5 1.5 0 00-1.5 1.5.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5 1.5 1.5 0 00-3 0 .5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5 1.5 1.5 0 00-3 0 .5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5 1.5 1.5 0 00-3 0v6a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V23a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V23a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V23a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-6a1.5 1.5 0 00-1.5-1.5zM7 20.5a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V20a.5.5 0 01.5-.5h3a.5.5 0 01.5.5zm7 0a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V20a.5.5 0 01.5-.5h3a.5.5 0 01.5.5zm7 0a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V20a.5.5 0 01.5-.5h3a.5.5 0 01.5.5zM23.82 10.866l-3.348-2.79a4.764 4.764 0 00-5.945 0L12.34 9.9a.5.5 0 000 .767l2.569 2.158a.748.748 0 00.483.176H23.5a.5.5 0 00.5-.5V11.25a.5.5 0 00-.18-.384zM.5 14.5H14a.5.5 0 00.32-.884l-4.848-4.04a4.764 4.764 0 00-5.945 0L.18 12.366a.5.5 0 00-.18.384V14a.5.5 0 00.5.5z\"/><circle cx=\"15\" cy=\"3\" r=\"3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});