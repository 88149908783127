define("ember-svg-jar/inlined/meeting-team-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-team-laptop</title><path d=\"M23.5 11.5h-.75a.249.249 0 01-.25-.25V2a2 2 0 00-2-2h-13a2 2 0 00-2 2v6.037a.251.251 0 00.332.237 4.982 4.982 0 011.429-.269.25.25 0 00.239-.25V2.5A.5.5 0 018 2h12a.5.5 0 01.5.5v8.75a.249.249 0 01-.25.25h-7.791a.25.25 0 00-.244.3 5.882 5.882 0 01.134 1.265 11.322 11.322 0 01-.058 1.156.251.251 0 00.249.275h8.96A2.5 2.5 0 0024 12a.5.5 0 00-.5-.5z\"/><path d=\"M10.849 13.068A3.314 3.314 0 007.5 9.5a3.314 3.314 0 00-3.349 3.569A6.069 6.069 0 005.416 17.2a.243.243 0 01.048.082 1.482 1.482 0 01.018.82v.013a.248.248 0 01-.149.151l-.473.175c-2.155.8-3.713 1.377-4.2 2.348A6.76 6.76 0 000 23.5a.5.5 0 00.5.5h14a.5.5 0 00.5-.5 6.76 6.76 0 00-.66-2.711c-.483-.971-2.041-1.548-4.2-2.348l-.473-.175a.25.25 0 01-.149-.151v-.013a1.53 1.53 0 01.007-.8.247.247 0 01.052-.09 6.086 6.086 0 001.272-4.144z\"/><circle cx=\"14.294\" cy=\"4.624\" r=\"1.625\"/><path d=\"M12 9.5h4.588a.409.409 0 00.383-.555 2.844 2.844 0 00-5.353 0A.408.408 0 0012 9.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});