define("ember-svg-jar/inlined/notes-book", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-book</title><path d=\"M24 4a3 3 0 00-3-3h-.774a.5.5 0 01-.433-.25A1.491 1.491 0 0018.5 0H4.044a1.5 1.5 0 00-1.5 1.5v.75a.25.25 0 00.25.25H4a1 1 0 010 2H1a1 1 0 000 2h1.294a.25.25 0 01.25.25v3a.25.25 0 00.25.25H4a1 1 0 010 2H1a1 1 0 000 2h1.294a.25.25 0 01.25.25v3a.25.25 0 00.25.25H4a1 1 0 010 2H1a1 1 0 000 2h1.294a.25.25 0 01.25.25v.75a1.5 1.5 0 001.5 1.5H18.5a1.491 1.491 0 001.293-.75.5.5 0 01.433-.25H21a3 3 0 003-3V4zm-8.794 4a1.5 1.5 0 01-1.5 1.5H9.5A1.5 1.5 0 018 8V5.5A1.5 1.5 0 019.5 4h4.206a1.5 1.5 0 011.5 1.5zM22 10.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25zm-2 2.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25zM22 4v1.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25H21a1 1 0 011 1zm0 16a1 1 0 01-1 1h-.75a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});