define("ember-svg-jar/inlined/night-moon-gibbous-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>night-moon-gibbous-1</title><path d=\"M17.1 2.713A11.716 11.716 0 007.333.208a1 1 0 00-.457.212C6.7.567 2.5 4.118 2.5 12s4.2 11.437 4.374 11.585a1.008 1.008 0 00.458.211 12.531 12.531 0 002.242.2 11.709 11.709 0 007.576-2.763A12.014 12.014 0 0021.5 12a12.261 12.261 0 00-4.4-9.287zM15.868 19.7a9.8 9.8 0 01-7.819 2.186.251.251 0 01-.14-.071C7.087 20.992 4.5 17.923 4.5 12s2.589-8.988 3.41-9.813a.244.244 0 01.14-.07 9.712 9.712 0 017.773 2.132A10.237 10.237 0 0119.5 12a10.016 10.016 0 01-3.632 7.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});