define("ember-svg-jar/inlined/barbecue-set", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>barbecue-set</title><path d=\"M15.5 0a.5.5 0 00-.5.5v9a.5.5 0 01-.5.5h-11a.5.5 0 00-.5.5 6.494 6.494 0 002.524 5.139 1 1 0 01.378.931l-.892 6.289a1 1 0 00.849 1.131A.988.988 0 006 24a1 1 0 00.989-.859l.243-1.711a.5.5 0 01.5-.43h3.544a.5.5 0 01.495.43l.243 1.711A1 1 0 0013 24a.988.988 0 00.142-.01 1 1 0 00.849-1.131L13.1 16.57a1 1 0 01.378-.931 6.5 6.5 0 002.467-4.279.5.5 0 01.418-.428A5.5 5.5 0 0015.5 0zM7.75 12.5a.75.75 0 01.75-.75h2a.75.75 0 010 1.5h-2a.75.75 0 01-.75-.75zm.4 6.5a.5.5 0 01-.5-.57l.156-1.1a.5.5 0 01.58-.423 6.542 6.542 0 002.21 0 .5.5 0 01.58.423l.156 1.1a.5.5 0 01-.495.57z\"/><path d=\"M13.25 9h-8.5a.75.75 0 010-1.5h8.5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});