define("ember-svg-jar/inlined/audio-book-record-microphone-a", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-book-record-microphone-a</title><path d=\"M11.375 20h-7a.5.5 0 01-.5-.5v-17a.5.5 0 01.5-.5h8.672a1 1 0 01.707.293l2.828 2.829a1 1 0 01.293.707V9.5a1 1 0 002 0V5.415A1.986 1.986 0 0018.289 4L14.875.587A2.015 2.015 0 0013.461 0H3.875a2 2 0 00-2 2v18a2 2 0 002 2h7.5a1 1 0 100-2z\"/><path d=\"M12.875 8.251a3 3 0 00-6 0v5.5a1 1 0 002 0v-2a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v2a1 1 0 002 0zm-2 .5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-.5a1 1 0 012 0zM15.125 14v3a2.25 2.25 0 104.5 0v-3a2.25 2.25 0 10-4.5 0z\"/><path d=\"M21.375 16.376a.75.75 0 00-.75.75 3.25 3.25 0 11-6.5 0 .75.75 0 00-1.5 0 4.742 4.742 0 003.619 4.6.5.5 0 01.381.486v1.042a.75.75 0 101.5 0v-1.045a.5.5 0 01.381-.486 4.742 4.742 0 003.619-4.6.75.75 0 00-.75-.747z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});