define("ember-svg-jar/inlined/laptop-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-1</title><path d=\"M3.5 14.734a1 1 0 001-1V5.418a.667.667 0 01.667-.667h13.666a.667.667 0 01.667.667v8.316a1 1 0 102 0V5.418a2.669 2.669 0 00-2.667-2.667H5.167A2.669 2.669 0 002.5 5.418v8.316a1 1 0 001 1zM23.894 16.443a.5.5 0 00-.394-.192h-8a.5.5 0 00-.5.5c0 .71-1.232 1.5-3 1.5s-3-.79-3-1.5a.5.5 0 00-.5-.5h-8a.5.5 0 00-.485.621 5.773 5.773 0 005.608 4.379h12.754a5.773 5.773 0 005.608-4.379.5.5 0 00-.091-.429z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});