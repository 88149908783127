define("ember-svg-jar/inlined/sign-badge-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sign-badge-badge</title><path d=\"M21.771 1.753a1.506 1.506 0 00-1.478-.037l-2.064 1.1a.5.5 0 01-.467 0L12.694.17a1.5 1.5 0 00-1.389 0L6.236 2.82a.5.5 0 01-.466 0l-2.063-1.1A1.5 1.5 0 001.5 3.039V11.5C1.5 18.432 10.821 24 12 24s10.5-5.568 10.5-12.5V3.039a1.505 1.505 0 00-.729-1.286z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});