define("ember-svg-jar/inlined/real-estate-action-house-like", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-house-like</title><path d=\"M14.367 15.341l2-3.075A2.885 2.885 0 0118.737 11a2.783 2.783 0 012.181 1.029.046.046 0 00.051.014A.045.045 0 0021 12a.5.5 0 01.5-.5h2a.5.5 0 00.344-.863L13.029.408a1.5 1.5 0 00-2.059 0L.156 10.637A.5.5 0 00.5 11.5h2a.5.5 0 01.5.5v10a1 1 0 001 1h4a.5.5 0 00.5-.5V17a2.5 2.5 0 012.5-2.5h1a2.5 2.5 0 011.895.872.295.295 0 00.472-.031z\"/><rect x=\"10\" y=\"16\" width=\"3\" height=\"8\" rx=\"1\" ry=\"1\"/><path d=\"M23.708 17.6a1.5 1.5 0 00-1.2-.6h-2.43a.488.488 0 01-.39-.191.859.859 0 01-.079-.589l.431-2.031A1.344 1.344 0 0019.784 13a1.391 1.391 0 00-2.16.09l-2.412 3.7a.489.489 0 01-.4.207H14.5a.5.5 0 00-.5.5V22.2a.5.5 0 00.273.445c2.29 1.17 2.805 1.355 4.044 1.355l1.846-.029c1.392 0 2.315-.8 2.817-2.421l.955-2.633a1.492 1.492 0 00-.227-1.317z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});