define("ember-svg-jar/inlined/graphic-tablet-intous-draw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>graphic-tablet-intous-draw</title><path d=\"M13.953 18.075a.251.251 0 00-.177-.075H3a1 1 0 01-1-1V6a1 1 0 011-1h18a1 1 0 011 1v11a.992.992 0 01-.129.49.251.251 0 00.041.3l1.221 1.22a.25.25 0 00.378-.027A2.486 2.486 0 0024 17.5v-15A2.5 2.5 0 0021.5 0h-19A2.5 2.5 0 000 2.5v15A2.5 2.5 0 002.5 20h12.776a.25.25 0 00.177-.427zM5.25 3h-2.5a.75.75 0 010-1.5h2.5a.75.75 0 010 1.5zm6.75.75a1.25 1.25 0 111.25-1.25A1.25 1.25 0 0112 3.752zM21.25 3h-2.5a.75.75 0 010-1.5h2.5a.75.75 0 010 1.5z\"/><path d=\"M13.669 11.668a.506.506 0 00-.2-.12l-1.94-.647a.5.5 0 00-.632.633l.646 1.939a.5.5 0 00.121.195l9.917 9.917a1.414 1.414 0 002-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});