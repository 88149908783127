define("ember-svg-jar/inlined/couple-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>couple-house</title><path d=\"M23.683 1.638a3.022 3.022 0 00-4.806-.77l-.492.448a.5.5 0 01-.673 0L17.22.868a3.022 3.022 0 00-4.806.77 2.934 2.934 0 00.562 3.419l4.612 4.748a.641.641 0 00.921 0l4.612-4.748a2.934 2.934 0 00.562-3.419zM7.324 12.118a.5.5 0 00-.647 0l-6.5 5.5A.5.5 0 00.5 18.5H2v5a.5.5 0 00.5.5H5a.5.5 0 00.5-.5v-2.25a1.5 1.5 0 013 0v2.25a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-5h1.5a.5.5 0 00.324-.882zM13.681 10.393a.557.557 0 01-.75 0 1.368 1.368 0 00-2.175.348 1.328 1.328 0 00.254 1.548l2.09 2.149a.291.291 0 00.417 0l2.083-2.149a1.328 1.328 0 00.254-1.548 1.368 1.368 0 00-2.173-.348z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});