define("ember-svg-jar/inlined/messages-bubble-forward-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-forward-all</title><path d=\"M13.327 13.361a.25.25 0 00.174-.238v-.138a1.972 1.972 0 011.105-1.775 2 2 0 012.048.154l1.2.826a.246.246 0 00.2.037.253.253 0 00.162-.131 1.957 1.957 0 01.886-.885 2 2 0 012.048.153l.1.069a.249.249 0 00.38-.128A7.44 7.44 0 0022 9c0-4.962-4.935-9-11-9S0 4.038 0 9a8.08 8.08 0 002.657 5.854L1.03 19.329a.5.5 0 00.662.632l5.832-2.429c.19.052.381.1.573.14a.249.249 0 00.276-.13 8.479 8.479 0 014.954-4.181z\"/><path d=\"M19.5 15.374a.5.5 0 00-.216-.412l-3.5-2.42a.5.5 0 00-.784.411v1.5a6.821 6.821 0 00-6 6.643V23a1 1 0 002 0v-1.906a4.8 4.8 0 014-4.62v1.321a.5.5 0 00.785.412l3.5-2.421a.5.5 0 00.215-.412z\"/><path d=\"M23.677 14.757l-3.5-2.421a.75.75 0 00-.854 1.234l2.609 1.8-2.609 1.8a.75.75 0 10.854 1.233l3.5-2.421a.75.75 0 000-1.233z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});