define("ember-svg-jar/inlined/settings-slider-desktop-vertical-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>settings-slider-desktop-vertical-alternate</title><path d=\"M7 6.251a.75.75 0 00.75-.75v-.75a.75.75 0 00-1.5 0V5.5a.75.75 0 00.75.751zM7 10.751a.75.75 0 00-.75.75v1a.75.75 0 001.5 0v-1a.75.75 0 00-.75-.75zM6.926 7A1.5 1.5 0 108.5 8.427 1.5 1.5 0 006.926 7zM17 6.251a.75.75 0 00.75-.75v-.75a.75.75 0 00-1.5 0V5.5a.75.75 0 00.75.751zM17 10.751a.75.75 0 00-.75.75v1a.75.75 0 001.5 0v-1a.75.75 0 00-.75-.75zM16.926 7A1.5 1.5 0 1018.5 8.427 1.5 1.5 0 0016.926 7zM12 8a.75.75 0 00-.75.75v3.5a.75.75 0 001.5 0v-3.5A.75.75 0 0012 8zM12.075 4A1.5 1.5 0 1013.5 5.576 1.5 1.5 0 0012.075 4z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v16A1.5 1.5 0 001.5 19h21a1.5 1.5 0 001.5-1.5v-16A1.5 1.5 0 0022.5 0zM22 14.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5zM14.35 20.682a.5.5 0 00-.385-.181h-3.93a.5.5 0 00-.385.181l-2.1 2.532a.5.5 0 00.41.786h8.079a.5.5 0 00.41-.787z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});