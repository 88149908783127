define("ember-svg-jar/inlined/diagram-split-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-split-horizontal</title><path d=\"M18.24 6.06a4.506 4.506 0 01-4.5 4.5H1.44a1.44 1.44 0 000 2.88h12.3a4.506 4.506 0 014.5 4.5v.05a.25.25 0 01-.25.25H16.8a1.44 1.44 0 00-1.018 2.46l2.878 2.878a1.439 1.439 0 002.04 0l2.878-2.878a1.44 1.44 0 00-1.018-2.46h-1.19a.25.25 0 01-.25-.25v-.05a7.359 7.359 0 00-2.766-5.74.251.251 0 010-.39 7.359 7.359 0 002.766-5.75v-.05a.25.25 0 01.25-.25h1.19a1.44 1.44 0 001.018-2.46L20.7.424a1.439 1.439 0 00-2.04 0L15.782 3.3A1.44 1.44 0 0016.8 5.76h1.19a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});