define("ember-svg-jar/inlined/search-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>search-square</title><path d=\"M22 24H2a2 2 0 01-2-2V2a2 2 0 012-2h20a2 2 0 012 2v20a2 2 0 01-2 2zM2.5 2a.5.5 0 00-.5.5v19a.5.5 0 00.5.5h19a.5.5 0 00.5-.5v-19a.5.5 0 00-.5-.5z\"/><path d=\"M15.239 14.146a.249.249 0 01-.03-.316 5.05 5.05 0 10-1.381 1.381.25.25 0 01.316.03l2.535 2.534a.784.784 0 001.1 0 .774.774 0 000-1.094zm-4.206.373a3.484 3.484 0 113.484-3.484 3.488 3.488 0 01-3.484 3.484z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});