define("ember-svg-jar/inlined/people-woman-cap-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-cap-1</title><path d=\"M.5 23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-3a2.467 2.467 0 00-.966-1.977 14.271 14.271 0 00-5.908-2.481.494.494 0 00-.48.176.464.464 0 00-.072.1 4.886 4.886 0 01-2.192 2.327l-.087.045a4.453 4.453 0 01-.431.173c-.108.038-.219.068-.33.1s-.207.055-.313.074a3.791 3.791 0 01-1.44 0c-.1-.019-.209-.047-.312-.074s-.223-.059-.332-.1a4.418 4.418 0 01-.429-.173l-.089-.045a4.889 4.889 0 01-2.191-2.327.455.455 0 00-.073-.106.5.5 0 00-.479-.173 14.283 14.283 0 00-5.909 2.481A2.467 2.467 0 00.5 20.5z\"/><path d=\"M15.412 15.106c.132-.074.261-.15.387-.232a.252.252 0 01.274 0 2.991 2.991 0 00.413.228h.007a4.767 4.767 0 001.97.391 3.8 3.8 0 002.921-1.12.5.5 0 00-.231-.8C18.538 12.743 19 9.033 19 7A7 7 0 005 7c0 1.953.467 5.681-2.153 6.523a.5.5 0 00-.231.8 3.8 3.8 0 002.921 1.12 4.618 4.618 0 002.346-.6.25.25 0 01.273 0c.141.093.284.18.432.263a6.958 6.958 0 006.824 0zM7 9V7.5a.25.25 0 01.25-.25h4.164a.248.248 0 01.236.17 3.207 3.207 0 002.125 1.765 6.085 6.085 0 012.652 1.782.25.25 0 01.029.27A4.99 4.99 0 017 9zm5-7a4.992 4.992 0 014.725 3.421.25.25 0 01-.237.329H7.512a.248.248 0 01-.2-.1.251.251 0 01-.035-.225A4.992 4.992 0 0112 2z\"/><circle cx=\"10\" cy=\"9.25\" r=\"1\"/><path d=\"M12.943 12.745a2.375 2.375 0 00.441-.183.64.64 0 00-.307-1.2h-2a.64.64 0 00-.307 1.2 2.355 2.355 0 00.438.182 3.017 3.017 0 00.869.127 2.977 2.977 0 00.866-.126z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});