define("ember-svg-jar/inlined/gesture-two-fingers-swipe-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-two-fingers-swipe-up</title><path d=\"M15.034 9.592H7.97a.25.25 0 01-.143-.455c2.139-1.49 1.45-3.855-.223-2.762L3.237 9.288a.5.5 0 00-.223.416v5.435a.5.5 0 00.411.492c1.1.2 3.683.664 4.165.775 1.094.252 1.43-.661 1.855-1.615a.251.251 0 01.293-.14L14.4 15.9a.929.929 0 00.454-1.8l-4.592-1.43a.249.249 0 01-.168-.3l.143-.585a.25.25 0 01.243-.191h4.558a1 1 0 00-.004-2.002z\"/><path d=\"M19.233 2.839a.25.25 0 01.118-.322l.97-.485a.75.75 0 00-.211-1.41l-2.935-.49a.756.756 0 00-.82.461l-.978 2.446a.75.75 0 001.031.949l.7-.352a.249.249 0 01.2-.01.252.252 0 01.143.138 22.1 22.1 0 011.531 8.357 18.174 18.174 0 01-2.705 10.19 1 1 0 001.659 1.118 20.149 20.149 0 003.046-11.308 24.024 24.024 0 00-1.749-9.282z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});