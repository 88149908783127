define("ember-svg-jar/inlined/flower-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flower-2</title><circle cx=\"12\" cy=\"8.199\" r=\"1.5\"/><path d=\"M20.375 17.574a7.448 7.448 0 00-5.639 1.076 7.57 7.57 0 00-1.306 1.084.25.25 0 01-.43-.173v-3.428a.25.25 0 01.384-.211 3 3 0 004.241-3.981.5.5 0 01.426-.742 3 3 0 000-6 .5.5 0 01-.426-.742 3 3 0 00-5.2-3 .5.5 0 01-.854 0 3 3 0 00-5.2 3.005.5.5 0 01-.426.742 3 3 0 000 6 .5.5 0 01.426.742 3 3 0 004.241 3.981.25.25 0 01.384.211v3.435a.25.25 0 01-.431.172 7.5 7.5 0 00-6.944-2.166.5.5 0 00-.388.591 7.355 7.355 0 007.18 5.83h3.174a7.355 7.355 0 007.176-5.835.5.5 0 00-.388-.591zm-7.625-5.625a.75.75 0 01-1.5 0V11.8a1 1 0 00-.562-.9 2.927 2.927 0 01-.365-.21 1 1 0 00-1.062-.04l-.134.078a.75.75 0 01-.75-1.3l.133-.076a1 1 0 00.5-.938 2.844 2.844 0 010-.423 1 1 0 00-.5-.938l-.133-.076a.75.75 0 01.75-1.3l.134.077a1 1 0 001.062-.038 2.82 2.82 0 01.365-.212 1 1 0 00.562-.9v-.155a.75.75 0 111.5 0V4.6a1 1 0 00.562.9 2.82 2.82 0 01.365.212 1 1 0 001.062.038l.134-.077a.75.75 0 01.75 1.3l-.133.076a1 1 0 00-.5.938 2.831 2.831 0 010 .422 1 1 0 00.5.939l.133.076a.75.75 0 01-.75 1.3l-.134-.078a1 1 0 00-1.062.04 2.927 2.927 0 01-.365.21 1 1 0 00-.562.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});