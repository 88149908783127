define("ember-svg-jar/inlined/medical-folder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-folder</title><path d=\"M24 9a1.5 1.5 0 00-1.5-1.5h-16A1.5 1.5 0 005 9v10a1.5 1.5 0 01-3 0V4a.5.5 0 01.5-.5h4.086L8.5 5.414A1.986 1.986 0 009.914 6H20.5a1 1 0 000-2H9.914L8 2.086A1.986 1.986 0 006.586 1.5H1.5A1.5 1.5 0 000 3v16a3.5 3.5 0 003.5 3.5H20a4 4 0 004-4zm-5.5 7a.5.5 0 01-.5.5h-1.5a.5.5 0 00-.5.5v1.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5V17a.5.5 0 00-.5-.5H11a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h1.5a.5.5 0 00.5-.5v-1.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5V13a.5.5 0 00.5.5H18a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});