define("ember-svg-jar/inlined/view-circle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>view-circle-1</title><path d=\"M2.049 7.094a1 1 0 001.373-.342 10.052 10.052 0 015.163-4.258A1 1 0 007.9.614a12.056 12.056 0 00-6.193 5.108 1 1 0 00.342 1.372zM23.872 10.141a1 1 0 10-1.978.291A10.2 10.2 0 0122 11.9a10.023 10.023 0 01-2.236 6.3 1 1 0 001.552 1.261 12.017 12.017 0 002.556-9.319zM15.37 21.314a10.1 10.1 0 01-6.691.016 1 1 0 10-.664 1.887 12.057 12.057 0 008.029-.02 1 1 0 00-.674-1.883zM13.956 2.086A10.025 10.025 0 0119.7 5.514a1 1 0 101.539-1.276A12.025 12.025 0 0014.345.124a1 1 0 00-.389 1.962zM2 11.947a1.011 1.011 0 00-1-.995 1 1 0 00-.995 1 12.018 12.018 0 002.723 7.551 1 1 0 001.545-1.269A10.012 10.012 0 012 11.947zM19.449 10.857l-.3-.319c-1.325-1.421-3.544-3.8-7.024-3.8-3.35 0-5.4 2.27-6.62 3.627-.161.178-.312.345-.453.494a2 2 0 000 2.758c.141.148.291.315.452.493 1.224 1.357 3.27 3.628 6.62 3.628 3.48 0 5.7-2.381 7.025-3.8l.3-.318a2 2 0 000-2.763zm-7.325 4.879c-2.707 0-4.282-2.063-5.464-3.33a.25.25 0 010-.341c1.21-1.3 2.779-3.329 5.465-3.329 2.5 0 4.13 1.642 5.715 3.328a.248.248 0 010 .342c-1.566 1.667-3.189 3.33-5.716 3.33z\"/><circle cx=\"12.124\" cy=\"12.236\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});