define("ember-svg-jar/inlined/arrow-rectangle-right-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-rectangle-right-1</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-9.229 11.809L8.038 7.382a.5.5 0 01.323-.882H10.7a.5.5 0 01.335.129l5.547 5a.5.5 0 010 .742l-5.547 5a.5.5 0 01-.335.129H8.361a.5.5 0 01-.323-.882l5.233-4.427a.25.25 0 000-.382z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});