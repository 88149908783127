define("ember-svg-jar/inlined/server-star-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-star-1</title><path d=\"M9.953 20a.249.249 0 01-.25-.25V17.1a2.705 2.705 0 01.05-1.91 2.741 2.741 0 012.553-1.69h1.352a.5.5 0 00.459-.3l.215-.5a.5.5 0 00-.459-.7H2.453a2.5 2.5 0 000 5h5.5a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25h-3a.75.75 0 100 1.5h5.886a.5.5 0 00.459-.3l.217-.5a.5.5 0 00-.458-.7zm-7-5.5A1.25 1.25 0 114.2 15.75a1.249 1.249 0 01-1.247-1.25zm5 1.25A1.25 1.25 0 119.2 14.5a1.25 1.25 0 01-1.247 1.25zM1.953 20h-1a.75.75 0 100 1.5h1a.75.75 0 000-1.5zM2.453 5h13a2.5 2.5 0 000-5h-13a2.5 2.5 0 000 5zm.5-2.5A1.25 1.25 0 114.2 3.75 1.249 1.249 0 012.953 2.5zm3.75 0a1.25 1.25 0 111.25 1.25A1.249 1.249 0 016.7 2.5zM17.4 9.516a.5.5 0 00.461-.366 2.406 2.406 0 00.09-.65 2.5 2.5 0 00-2.5-2.5h-13a2.5 2.5 0 000 5H14.8a.5.5 0 00.421-.23A2.67 2.67 0 0117.4 9.516zM2.953 8.5A1.25 1.25 0 114.2 9.75 1.249 1.249 0 012.953 8.5zm5 1.25A1.25 1.25 0 119.2 8.5a1.25 1.25 0 01-1.247 1.25z\"/><path d=\"M22.788 15h-2.5a.248.248 0 01-.229-.151l-1.344-3.116-.015-.033a1.293 1.293 0 00-2.317.028l-1.346 3.116a.25.25 0 01-.23.151h-2.5a1.23 1.23 0 00-1.166.76 1.272 1.272 0 00.32 1.4l2.194 1.954a.252.252 0 01.063.287l-1.224 2.814a1.253 1.253 0 00.314 1.451 1.278 1.278 0 001.506.166l3.112-1.752a.249.249 0 01.245 0l3.113 1.752a1.273 1.273 0 001.506-.166 1.254 1.254 0 00.315-1.45L21.382 19.4a.25.25 0 01.062-.287l2.213-1.971a1.264 1.264 0 00.3-1.378A1.234 1.234 0 0022.788 15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});