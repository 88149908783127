define("ember-svg-jar/inlined/layout-module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layout-module</title><rect x=\".25\" y=\".247\" width=\"6.5\" height=\"6.5\" rx=\"1.5\" ry=\"1.5\"/><rect x=\".25\" y=\"8.747\" width=\"6.5\" height=\"6.5\" rx=\"1.5\" ry=\"1.5\"/><rect x=\".25\" y=\"17.247\" width=\"6.5\" height=\"6.5\" rx=\"1.5\" ry=\"1.5\"/><rect x=\"8.75\" y=\".247\" width=\"6.5\" height=\"6.5\" rx=\"1.5\" ry=\"1.5\"/><rect x=\"8.75\" y=\"8.747\" width=\"6.5\" height=\"6.5\" rx=\"1.5\" ry=\"1.5\"/><rect x=\"8.75\" y=\"17.247\" width=\"6.5\" height=\"6.5\" rx=\"1.5\" ry=\"1.5\"/><rect x=\"17.25\" y=\".247\" width=\"6.5\" height=\"6.5\" rx=\"1.5\" ry=\"1.5\"/><rect x=\"17.25\" y=\"8.747\" width=\"6.5\" height=\"6.5\" rx=\"1.5\" ry=\"1.5\"/><rect x=\"17.25\" y=\"17.247\" width=\"6.5\" height=\"6.5\" rx=\"1.5\" ry=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});