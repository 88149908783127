define("ember-svg-jar/inlined/martial-arts-swords", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>martial-arts-swords</title><path d=\"M5.043 17.543a.5.5 0 00-.707 0l-3.9 3.9a1.5 1.5 0 002.122 2.122l3.9-3.9a.5.5 0 000-.707zM19.664 17.543a.5.5 0 00-.707 0l-1.414 1.414a.5.5 0 000 .707l3.9 3.9a1.5 1.5 0 002.122-2.122zM9.333 17.5a.5.5 0 010-.707l1.253-1.253a.5.5 0 000-.707l-1.414-1.419a.5.5 0 00-.707 0l-1.254 1.253a.5.5 0 01-.707 0L5.09 13.253a1 1 0 00-1.414 1.414l5.657 5.657a1 1 0 001.414-1.414zM14.828 10.586a.5.5 0 00.708 0l8.025-8.025A1.5 1.5 0 0021.439.439l-8.025 8.026a.5.5 0 000 .707z\"/><path d=\"M20.324 13.253a1 1 0 00-1.414 0l-1.41 1.414a.5.5 0 01-.707 0L2.561.439A1.5 1.5 0 00.439 2.561l14.229 14.228a.5.5 0 010 .707l-1.415 1.414a1 1 0 101.414 1.414l5.657-5.657a1 1 0 000-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});