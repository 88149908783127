define("ember-svg-jar/inlined/smiley-rich-alternate-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-rich-alternate-1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM5.875 12.75a1 1 0 010-2h2.033a.342.342 0 00.127-.659l-2.062-.825a2.342 2.342 0 01.221-4.425.251.251 0 00.181-.24V4.5a1 1 0 012 0 .25.25 0 00.25.25h.25a1 1 0 010 2H6.842a.342.342 0 00-.127.659l2.062.825a2.342 2.342 0 01-.221 4.425.251.251 0 00-.181.24V13a1 1 0 01-2 0 .25.25 0 00-.25-.25zm1.184 3.959a.438.438 0 01.379-.22h9.123a.439.439 0 01.38.658 5.706 5.706 0 01-9.882 0 .436.436 0 010-.438zM17.375 13a1 1 0 01-2 0 .25.25 0 00-.25-.25h-.25a1 1 0 010-2h2.033a.342.342 0 00.127-.659l-2.062-.825a2.342 2.342 0 01.221-4.425.251.251 0 00.181-.24V4.5a1 1 0 012 0 .25.25 0 00.25.25h.25a1 1 0 010 2h-2.033a.342.342 0 00-.127.659l2.062.825a2.342 2.342 0 01-.221 4.425.251.251 0 00-.181.24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});