define("ember-svg-jar/inlined/monitor-transfer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-transfer</title><path d=\"M4.25 11.963a.75.75 0 000-1.5h-1a.75.75 0 000 1.5zM11.25 5.755a.75.75 0 00.75-.75v-3.25a1.751 1.751 0 00-1.75-1.75h-8.5A1.751 1.751 0 000 1.755v5.958a1.75 1.75 0 001.75 1.75h2.5a.75.75 0 00.75-.75V7.505a.5.5 0 00-.5-.5H1.75a.25.25 0 01-.25-.25v-5a.251.251 0 01.25-.25h8.5a.251.251 0 01.25.25v3.25a.75.75 0 00.75.75z\"/><path d=\"M24 9.505a2 2 0 00-2-2H8.5a2 2 0 00-2 2v9.458a2 2 0 002 2H14a.25.25 0 01.25.25v.531a.25.25 0 01-.25.25h-2.75a1 1 0 000 2h8a1 1 0 000-2H16.5a.25.25 0 01-.25-.25v-.531a.25.25 0 01.25-.25H22a2 2 0 002-2zm-2.25 0a.25.25 0 01.25.25v7.458a.25.25 0 01-.25.25h-13a.25.25 0 01-.25-.25V9.755a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});