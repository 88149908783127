define("ember-svg-jar/inlined/soccer-player-kick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>soccer-player-kick</title><circle cx=\"12.45\" cy=\"2.75\" r=\"2.5\"/><circle cx=\"11.95\" cy=\"21.25\" r=\"2.5\"/><path d=\"M20.225 21.977l-1.275-4.264a4.51 4.51 0 00-3.337-3.561l-1.065-.266a.251.251 0 01-.187-.209l-.487-3.642a.246.246 0 01.06-.2.251.251 0 01.188-.086h1.328a4.476 4.476 0 004.178-2.828l.246-.614a1.5 1.5 0 10-2.785-1.115l-.246.615a1.493 1.493 0 01-1.393.942h-7.5a5.01 5.01 0 00-3.9 2.1 1.5 1.5 0 002.4 1.8c.422-.422.733-.9 1.5-.9h2.589a.251.251 0 01.248.216l.534 3.865a.25.25 0 01-.175.274l-2.179.654a1.506 1.506 0 01-1.2-.151L6.4 13.791a1.5 1.5 0 10-1.543 2.572l1.363.819a4.511 4.511 0 003.608.451l2.546-.764a3 3 0 011.59-.037l.922.231A1.5 1.5 0 0116 18.25l1.275 4.263a1.5 1.5 0 002.951-.536z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});