define("ember-svg-jar/inlined/disability-cane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-cane</title><circle cx=\"14\" cy=\"2.5\" r=\"2.5\"/><path d=\"M16.5 6.5h-5a4 4 0 00-3.99 3.76 2.543 2.543 0 01-.375 1.322.249.249 0 00.13.373 4.011 4.011 0 011.6 1.007.253.253 0 00.205.075.249.249 0 00.185-.115A4.909 4.909 0 0010 10.25a.5.5 0 011 0v12.5a1.25 1.25 0 002.5 0V16.5a.5.5 0 011 0v6.25a1.25 1.25 0 002.5 0v-12.5a.5.5 0 011 0v4a1.25 1.25 0 002.5 0V10.5a4.005 4.005 0 00-4-4z\"/><path d=\"M6 13.25a2.5 2.5 0 00-2.5 2.5V23a1 1 0 002 0v-7.25a.5.5 0 011 0v.5a1 1 0 002 0v-.5a2.5 2.5 0 00-2.5-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});