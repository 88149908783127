define("ember-svg-jar/inlined/shopping-cart-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-cart-1</title><path d=\"M24 2.5a1.25 1.25 0 00-1.25-1.25h-2.309A1.753 1.753 0 0018.7 2.783l-.281 2.248a.25.25 0 01-.248.219H2.752A2.749 2.749 0 00.129 8.825l2.061 6.493a2.735 2.735 0 002.625 1.932h12.327a2.238 2.238 0 002.224-1.908l.716-4.656a1.25 1.25 0 10-2.471-.38l-.651 4.232a.249.249 0 01-.247.212H4.815a.25.25 0 01-.241-.181l-2.061-6.5a.25.25 0 01.239-.323h15.864a2.255 2.255 0 002.234-1.968l.226-1.809a.25.25 0 01.248-.219h1.426A1.25 1.25 0 0024 2.5z\"/><circle cx=\"6.752\" cy=\"20.5\" r=\"2.25\"/><circle cx=\"16.5\" cy=\"20.5\" r=\"2.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});