define("ember-svg-jar/inlined/professions-man-construction-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-construction-1</title><path d=\"M22.616 19.367a15.372 15.372 0 00-7.434-2.759.245.245 0 01-.08-.461 6.855 6.855 0 003.755-6.106v-.735a.245.245 0 01.245-.245h.245a.98.98 0 100-1.959h-.359a.244.244 0 01-.241-.2 6.785 6.785 0 00-4-5.078.25.25 0 01-.142-.174A1.955 1.955 0 0012.678 0h-1.355a1.971 1.971 0 00-1.93 1.648.248.248 0 01-.142.173 6.784 6.784 0 00-4 5.078.245.245 0 01-.241.2h-.357a.98.98 0 100 1.959H4.9a.245.245 0 01.245.245v.735A6.855 6.855 0 008.9 16.147a.245.245 0 01-.08.461 15.379 15.379 0 00-7.433 2.758 2.9 2.9 0 00-1.14 2.326v1.328a.98.98 0 101.959 0v-1.328a.953.953 0 01.369-.768 9.293 9.293 0 011.025-.672.214.214 0 01.32.186v2.827a.49.49 0 00.49.49h15.182a.49.49 0 00.49-.49v-2.827a.214.214 0 01.32-.186 9.317 9.317 0 011.026.672.955.955 0 01.368.768v1.328a.98.98 0 101.959 0v-1.328a2.9 2.9 0 00-1.139-2.325zm-6.405-.61a.244.244 0 01.2.24v1.819a.49.49 0 01-.49.49H12.98a.245.245 0 01-.245-.245v-2.426a.244.244 0 01.254-.245 20.787 20.787 0 013.222.367zm-4.946 2.3a.245.245 0 01-.245.245H8.082a.49.49 0 01-.49-.49V19a.244.244 0 01.2-.24 20.8 20.8 0 013.222-.367.244.244 0 01.254.245zm2.224-14.24a.246.246 0 01-.241.285h-2.5a.246.246 0 01-.241-.285l.778-4.653a.246.246 0 01.242-.205h.938a.246.246 0 01.241.205zM7.1 10.041v-.735a.245.245 0 01.245-.245h9.306a.245.245 0 01.245.245v.735a4.9 4.9 0 01-9.8 0z\"/><circle cx=\"13.959\" cy=\"10.511\" r=\".98\"/><circle cx=\"10.041\" cy=\"10.511\" r=\".98\"/><path d=\"M12.08 14.076a2.9 2.9 0 00.846-.123 2.293 2.293 0 00.427-.177.626.626 0 00-.3-1.177H11.1a.626.626 0 00-.3 1.177 2.309 2.309 0 00.426.176 2.945 2.945 0 00.849.124z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});