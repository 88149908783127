define("ember-svg-jar/inlined/folder-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-code</title><path d=\"M22.5 4.5H11.736a.5.5 0 01-.447-.276l-.947-1.9A1.494 1.494 0 009 1.5H1.5A1.5 1.5 0 000 3v18a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 21V6a1.5 1.5 0 00-1.5-1.5zM10.281 17.53a.75.75 0 01-1.061 0l-4-4a.749.749 0 010-1.06l4-4a.75.75 0 011.06 1.06l-3.292 3.293a.25.25 0 000 .354l3.293 3.293a.749.749 0 010 1.06zm8-4l-4 4a.75.75 0 01-1.06-1.06l3.292-3.293a.25.25 0 000-.354L13.219 9.53a.75.75 0 011.061-1.06l4 4a.749.749 0 010 1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});