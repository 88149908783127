define("ember-svg-jar/inlined/outdoors-tree-valley", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-tree-valley</title><path d=\"M23.5 14.992h-2a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5H23a.5.5 0 00.434-.748l-1.256-2.2a.371.371 0 01.322-.554.5.5 0 00.424-.765l-2.5-4a.519.519 0 00-.848 0l-2.5 4a.5.5 0 00.424.765.371.371 0 01.322.554l-1.256 2.2a.5.5 0 00.434.748h1.5a.5.5 0 01.5.5v.83a.5.5 0 01-.375.484 14.612 14.612 0 00-5.348 2.815.25.25 0 00.075.431 12.67 12.67 0 015.685 3.659 2 2 0 01.463 1.281.5.5 0 00.5.5h3.5a.5.5 0 00.5-.5v-8a.5.5 0 00-.5-.5z\"/><path d=\"M8.455 19.061a.5.5 0 01-.455-.5v-1.569a.5.5 0 01.5-.5h3.04a1 1 0 00.819-1.574l-2.038-2.911a.327.327 0 01.268-.515 1 1 0 00.844-1.537L9.17 6.4a.5.5 0 01.1-.648 1 1 0 00.207-1.274L6.929.234a.52.52 0 00-.858 0L3.526 4.477a1 1 0 00.207 1.274.5.5 0 01.1.649L1.566 9.956a1 1 0 00.845 1.536.327.327 0 01.268.515L.641 14.918a1 1 0 00.819 1.574H4.5a.5.5 0 01.5.5v1.734a.5.5 0 01-.405.49 11.676 11.676 0 00-4.372 1.86.5.5 0 00-.223.416v2a.5.5 0 00.5.5h17a.5.5 0 00.384-.821c-2.322-2.786-6.523-3.847-9.429-4.11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});