define("ember-svg-jar/inlined/module-three-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>module-three-2</title><path d=\"M12.3 12.363l-5-1.663a1.762 1.762 0 00-1.1 0l-5 1.666A1.748 1.748 0 000 14.024v6.559a1.747 1.747 0 001.2 1.66l5 1.667a1.741 1.741 0 001.1 0l5-1.666a1.749 1.749 0 001.2-1.661v-6.559a1.8 1.8 0 00-1.2-1.661zm-5.63-.243a.244.244 0 01.08-.013.261.261 0 01.079.012l2.844.948a.25.25 0 010 .474c-1.013.343-2.8.95-3.2 1.088a.247.247 0 01-.161 0l-2.877-.959a.25.25 0 010-.475zm5.159 8.7l-4.25 1.417A.25.25 0 017.25 22v-5.893a.25.25 0 01.171-.237l4.25-1.417a.251.251 0 01.329.238v5.892a.25.25 0 01-.171.237zM18.917 9.94A1.58 1.58 0 0020 8.438V2.973a1.627 1.627 0 00-1.083-1.5L14.749.08a1.6 1.6 0 00-1 0L9.583 1.471A1.579 1.579 0 008.5 2.973v5.465a1.582 1.582 0 001.083 1.5l4.167 1.39a1.6 1.6 0 001 0zm-3.839-.315a.25.25 0 01-.328-.237V4.842a.25.25 0 01.171-.237l3.242-1.08a.247.247 0 01.226.035.252.252 0 01.1.205l-.047 4.574a.248.248 0 01-.172.234zm-.8-8.121l1.9.633a.25.25 0 010 .474c-.756.257-1.891.645-2.2.753a.25.25 0 01-.162 0l-1.865-.622a.25.25 0 010-.474zM23.031 12.684L19.7 11.572a1.424 1.424 0 00-.894 0l-3.334 1.112a1.415 1.415 0 00-.968 1.344V18.4a1.414 1.414 0 00.969 1.344l3.327 1.112a1.417 1.417 0 00.895 0l3.333-1.11A1.416 1.416 0 0024 18.4v-4.372a1.457 1.457 0 00-.969-1.344zM19.223 13l.955.318a.25.25 0 010 .474c-.456.157-1 .346-1.205.419a.246.246 0 01-.163 0l-.891-.3a.25.25 0 010-.474zm.857 6.166a.25.25 0 01-.33-.237v-3.424l2.433-.812a.251.251 0 01.329.234l.042 3.213a.248.248 0 01-.17.24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});