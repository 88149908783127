define("ember-svg-jar/inlined/performance-presentation-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>performance-presentation-graph</title><path d=\"M12 0a1 1 0 00-1 1v.31a.25.25 0 01-.25.249H2.026a1.5 1.5 0 00-1.5 1.5v13.959a1.5 1.5 0 001.5 1.5h8.727a.25.25 0 01.25.249v1.1a.25.25 0 01-.105.2L7.93 22.191a1 1 0 001.159 1.624l2.766-1.976a.246.246 0 01.29 0l2.766 1.976a1 1 0 001.159-1.624l-2.97-2.12a.25.25 0 01-.105-.2v-1.1a.25.25 0 01.25-.249h8.727a1.5 1.5 0 001.5-1.5V3.055a1.5 1.5 0 00-1.5-1.5h-8.725A.25.25 0 0113 1.31V1a1 1 0 00-1-1zm2.306 8.7l1.969 1.968a.247.247 0 00.352 0l2.9-2.9a1 1 0 111.41 1.411l-3.072 3.072a2 2 0 01-2.82 0l-1.969-1.97a.249.249 0 00-.352 0L10.105 12.9a1.983 1.983 0 01-1.411.585 1.975 1.975 0 01-1.411-.585l-1-1a.25.25 0 00-.353 0l-.864.863a1 1 0 01-1.41-1.41l1.04-1.04a2.044 2.044 0 012.821 0l1 1a.25.25 0 00.353 0L11.486 8.7a1.994 1.994 0 012.82 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});