define("ember-svg-jar/inlined/image-file-tiff-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-tiff-1</title><path d=\"M23.707 5.707L18.293.293A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.75V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V9H1.2A1.114 1.114 0 000 10v9a1.114 1.114 0 001.2 1H22v1.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707zm-17.2 6.419H5.381a.25.25 0 00-.25.25V17.5a.625.625 0 11-1.25 0v-5.124a.25.25 0 00-.25-.25H2.506a.625.625 0 110-1.25h4a.625.625 0 010 1.25zm3.875 4.75h.125a.625.625 0 010 1.25h-2a.625.625 0 010-1.25h.125a.25.25 0 00.25-.25v-4.25a.25.25 0 00-.25-.25h-.126a.625.625 0 010-1.25h2a.625.625 0 010 1.25h-.125a.25.25 0 00-.25.25v4.25a.25.25 0 00.25.25zm5.125-4.75h-2a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25h1.125a.625.625 0 010 1.25h-1.126a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-5a1.625 1.625 0 011.625-1.625h2a.625.625 0 010 1.25zm5 0h-2a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25h1.125a.625.625 0 010 1.25h-1.126a.25.25 0 00-.25.25V17.5a.625.625 0 11-1.25 0v-5a1.625 1.625 0 011.625-1.625h2a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});