define("ember-svg-jar/inlined/appliances-mixer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>appliances-mixer</title><path d=\"M14.313 0H5.5A4.5 4.5 0 001 4.5v5A1.5 1.5 0 002.5 11h2.75a.25.25 0 01.25.25v1.25a.238.238 0 01-.15.221c-2.265.956-3.658 4.558-3.658 6.576A4.761 4.761 0 006.5 24a4.761 4.761 0 004.808-4.7c0-2.018-1.393-5.62-3.658-6.576a.238.238 0 01-.15-.224v-1.25a.25.25 0 01.25-.25H21.5A1.5 1.5 0 0023 9.5v-.813A8.687 8.687 0 0014.313 0zM5.177 21.673A2.685 2.685 0 013.692 19.3a7.277 7.277 0 011.395-3.8c.134-.18.413-.2.413.233v5.746a.221.221 0 01-.323.194zM9.308 19.3a2.678 2.678 0 01-1.428 2.342.26.26 0 01-.38-.242v-5.59c0-.581.318-.445.469-.235A7.27 7.27 0 019.308 19.3zM4.5 8.75h-1a.75.75 0 010-1.5h1a.75.75 0 010 1.5zm0-3h-1a.75.75 0 010-1.5h1a.75.75 0 010 1.5zm15.212 2.417a.994.994 0 01-.742.333H11a1 1 0 01-1-1v-4a1 1 0 011-1h3.5a5.5 5.5 0 015.464 4.867 1.031 1.031 0 01-.252.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});