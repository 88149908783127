define("ember-svg-jar/inlined/dating-lipstick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dating-lipstick</title><path d=\"M6.94 11.872a1.5 1.5 0 00-2.122 0L.676 16.015a1.5 1.5 0 000 2.121L6.1 23.561a1.5 1.5 0 002.121 0l4.142-4.143a1.5 1.5 0 000-2.121zM17.488 11.725a.25.25 0 01.071-.214l5.979-5.979a1.568 1.568 0 00-.676-2.62L19 1.809a1.6 1.6 0 00-1.748.346L12.714 6.69a.252.252 0 01-.21.071 1.52 1.52 0 00-1.274.41L7.95 10.45a.5.5 0 000 .707l5.13 5.129a.5.5 0 00.707 0l3.279-3.279a1.5 1.5 0 00.422-1.282zm-2.929-3.7a.247.247 0 010-.353l3.825-3.826a.25.25 0 01.246-.063l2.569.733a.25.25 0 01.108.417l-4.743 4.745a.252.252 0 01-.354 0zM4.941 8.825a.58.58 0 00.83 0l4.151-4.274a2.639 2.639 0 00.5-3.077A2.72 2.72 0 006.1.781l-.409.373a.5.5 0 01-.674 0L4.611.781a2.721 2.721 0 00-4.326.693 2.64 2.64 0 00.506 3.077zM20.926 18.521a.737.737 0 01-.994 0 1.813 1.813 0 00-2.883.461 1.76 1.76 0 00.337 2.052l2.767 2.849a.383.383 0 00.276.117.387.387 0 00.277-.117l2.767-2.849a1.762 1.762 0 00.337-2.052 1.813 1.813 0 00-2.884-.461z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});