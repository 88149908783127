define("ember-svg-jar/inlined/task-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-text</title><path d=\"M13.709 4.727a.251.251 0 00.2.265 1.089 1.089 0 01.825 1.108v3.233a1.244 1.244 0 001.244 1.244 1.244 1.244 0 001.244-1.244V3.975a1.494 1.494 0 00-1.495-1.494H14a.246.246 0 00-.177.074.249.249 0 00-.073.177c.001.473 0 1.492-.041 1.995zM9.264 21.262a1.244 1.244 0 00-1.244-1.244H3.794a1.073 1.073 0 01-1-1.136V6.1a1.086 1.086 0 01.842-1.115.25.25 0 00.2-.258c-.039-.766-.051-1.6-.055-2a.249.249 0 00-.25-.247H1.805A1.494 1.494 0 00.31 3.975v17.037a1.493 1.493 0 001.49 1.493h6.22a1.244 1.244 0 001.244-1.243zM12.247 4.472V2.484a.5.5 0 00-.5-.5h-.758a.252.252 0 01-.249-.222 1.989 1.989 0 00-3.953 0 .249.249 0 01-.249.222h-.755a.5.5 0 00-.5.5v1.988a.5.5 0 00.5.5h5.967a.5.5 0 00.497-.5zM21.7 12.646a1.994 1.994 0 00-1.41-.584h-7.545a1.994 1.994 0 00-1.995 1.993V23a1 1 0 002 0l-.005-7.939a1 1 0 011-1h6.127a1 1 0 01.707.292l.818.816a1 1 0 01.293.708V23a1 1 0 002 0v-7.54a1.993 1.993 0 00-.584-1.409z\"/><path d=\"M19.209 19.269h-3.978a.75.75 0 100 1.5h3.978a.75.75 0 100-1.5zM15.231 17.289h2.486a.75.75 0 100-1.5h-2.486a.75.75 0 100 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});