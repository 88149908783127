define("ember-svg-jar/inlined/equipment-cement-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>equipment-cement-cart</title><circle cx=\"5.501\" cy=\"20\" r=\"2.25\"/><path d=\"M23.705 5.771a1 1 0 00-1.414 0L19.084 9H.8a.772.772 0 00-.713.386.77.77 0 00.072.807L4.2 16.29a.248.248 0 00.276.1 3.754 3.754 0 014.586 2.43.248.248 0 00.236.171L10.8 19a1.882 1.882 0 001.214-.506l1.382-1.431a.251.251 0 01.343-.016l4.212 3.626A1.777 1.777 0 0019 21a2 2 0 002-2V9.918l2.713-2.729a1 1 0 00-.008-1.418zm-8.558 9.854a.254.254 0 01-.087-.178.251.251 0 01.07-.185l3.441-3.562a.25.25 0 01.43.174V18.4a.248.248 0 01-.145.227.252.252 0 01-.268-.037z\"/><path d=\"M2.93 7.25h14.724a.75.75 0 00.659-1.108 3.225 3.225 0 00-2.872-1.707 3.5 3.5 0 00-.712.071A4.828 4.828 0 0010.3 1.75a4.831 4.831 0 00-4.44 2.756 3.473 3.473 0 00-.709-.071A3.223 3.223 0 002.27 6.144a.75.75 0 00.66 1.106z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});