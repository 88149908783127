define("ember-svg-jar/inlined/phone-action-bin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-bin</title><path d=\"M14.5 10.5h-5a.5.5 0 00-.5.5v3.5a1.5 1.5 0 001.5 1.5h3a1.5 1.5 0 001.5-1.5V11a.5.5 0 00-.5-.5zM15.5 8.25h-2.75V7.5a.75.75 0 00-1.5 0v.75H8.5a.75.75 0 000 1.5h7a.75.75 0 000-1.5z\"/><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});