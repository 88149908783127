define("ember-svg-jar/inlined/modern-architecture-top", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-architecture-top</title><path d=\"M8.874 5.457A1.006 1.006 0 008.032 5h-.674a.25.25 0 00-.235.335c2.145 5.946 1.6 10.879-6.018 14.289a1.009 1.009 0 00-.6.915V23a1 1 0 001 1h2.809a1.021 1.021 0 00.59-.185A14.48 14.48 0 0011 12a10.862 10.862 0 00-2.126-6.543zM10.134 4.642a16.179 16.179 0 011.632 3.1.25.25 0 00.468 0 16.209 16.209 0 011.632-3.1 1.74 1.74 0 01.178-.228.25.25 0 00.022-.307A2.511 2.511 0 0013 3.212V1a1 1 0 00-2 0v2.212a2.511 2.511 0 00-1.066.9.251.251 0 00.021.307 1.665 1.665 0 01.179.223zM12.238 16.774A.248.248 0 0012 16.6a.251.251 0 00-.239.175 16.074 16.074 0 01-3.212 5.732.5.5 0 00-.12.383l.074.666A.5.5 0 009 24h6a.5.5 0 00.5-.445l.074-.666a.5.5 0 00-.12-.384 16.088 16.088 0 01-3.216-5.731zM22.9 19.624c-7.619-3.41-8.163-8.343-6.018-14.289a.25.25 0 00-.24-.335h-.674a1.006 1.006 0 00-.842.457A10.862 10.862 0 0013 12a14.48 14.48 0 006.1 11.815 1.021 1.021 0 00.59.185h2.81a1 1 0 001-1v-2.461a1.009 1.009 0 00-.6-.915z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});