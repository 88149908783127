define("ember-svg-jar/inlined/underwear-shorts-male", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>underwear-shorts-male</title><path d=\"M9.6 13.435a1.753 1.753 0 01-.85-1.5V5.5a.25.25 0 00-.25-.25H.25A.25.25 0 000 5.5v17A1.5 1.5 0 001.5 24h7.513a1.492 1.492 0 001.481-1.265c.263-1.666.653-5.791.861-8.088a.24.24 0 00-.12-.231zM22.5 0h-21A1.5 1.5 0 000 1.5v2a.25.25 0 00.25.25h23.5A.25.25 0 0024 3.5v-2A1.5 1.5 0 0022.5 0zM23.75 5.25H15.5a.25.25 0 00-.25.25v6.434a1.749 1.749 0 01-.848 1.5l-1.615.969a.252.252 0 00-.12.241l.839 8.091A1.494 1.494 0 0014.987 24H22.5a1.5 1.5 0 001.5-1.5v-17a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});