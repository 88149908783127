define("ember-svg-jar/inlined/shopping-basket-sad-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-basket-sad-1</title><path d=\"M22 8.625h-.24a.249.249 0 01-.235-.166L20 4.2a4.259 4.259 0 00-4-2.821H8A4.259 4.259 0 004 4.2L2.475 8.459a.249.249 0 01-.235.166H2a1.5 1.5 0 000 3 .073.073 0 01.073.063l1.213 8.9a2.451 2.451 0 002.48 2.039h12.468a2.451 2.451 0 002.48-2.039l1.213-8.9a.073.073 0 01.073-.063 1.5 1.5 0 000-3zM6.352 5.037A1.755 1.755 0 018 3.875h8a1.755 1.755 0 011.648 1.162l1.073 3a.25.25 0 01-.235.334H5.514a.25.25 0 01-.235-.334zM7.5 14.875a1.25 1.25 0 111.25-1.25 1.25 1.25 0 01-1.25 1.25zM12 16.44a4.956 4.956 0 014.123 2.2.75.75 0 01-1.246.835 3.46 3.46 0 00-5.754 0 .75.75 0 01-1.246-.835A4.955 4.955 0 0112 16.44zm4.5-1.565a1.25 1.25 0 111.25-1.25 1.25 1.25 0 01-1.25 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});