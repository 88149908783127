define("ember-svg-jar/inlined/lock-unlock-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lock-unlock-4</title><path d=\"M18.75 10.06V6.75a6.75 6.75 0 00-13.5 0 1.25 1.25 0 002.5 0 4.25 4.25 0 018.5 0V9a.5.5 0 01-.5.5H7.5a5.006 5.006 0 00-5 5V19a5.006 5.006 0 005 5h9a5.006 5.006 0 005-5v-4.5a4.988 4.988 0 00-2.75-4.44zM14 16.5a2 2 0 11-2-2 2 2 0 012 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});