define("ember-svg-jar/inlined/shipment-previous", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-previous</title><path d=\"M9.715 15.973a2.5 2.5 0 010-3.946l4.5-3.5a2.358 2.358 0 01.85-.41.251.251 0 00.185-.242V6.5a.25.25 0 00-.25-.25H.5a.249.249 0 00-.25.25v9a1.5 1.5 0 001.5 1.5h8.557a.25.25 0 00.153-.447zM6.75 4.75A.25.25 0 007 4.5V1.25A.25.25 0 006.75 1H5.164a1.511 1.511 0 00-1.064.439L1.219 4.323a.25.25 0 00.181.427zM8.5 4.5a.249.249 0 00.25.25h5.35a.25.25 0 00.177-.427L11.4 1.439A1.511 1.511 0 0010.335 1H8.75a.249.249 0 00-.25.25z\"/><path d=\"M18.25 13.166a6.986 6.986 0 00-1.5-.166h-.25a.25.25 0 01-.25-.25V10.5a.5.5 0 00-.806-.395l-4.5 3.5a.5.5 0 000 .79l4.5 3.5a.5.5 0 00.806-.395v-2.25a.25.25 0 01.25-.25h.25a4.936 4.936 0 011.5.255A4.99 4.99 0 0121.75 20v2a1 1 0 002 0v-2a7.008 7.008 0 00-5.5-6.834z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});