define("ember-svg-jar/inlined/bed-double", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bed-double</title><path d=\"M23.25 17.207a.5.5 0 01-.25-.433V7.5a2 2 0 00-2-2h-4a1 1 0 000 2h3.5a.5.5 0 01.5.5v6.529a.25.25 0 01-.438.165 2.749 2.749 0 00-1-.719.5.5 0 01-.308-.462v-1.149a1.244 1.244 0 00-.881-1.194 7.045 7.045 0 00-4.738 0 1.244 1.244 0 00-.881 1.194v.886a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-.886a1.244 1.244 0 00-.881-1.194 7.045 7.045 0 00-4.738 0 1.244 1.244 0 00-.881 1.194v1.149a.5.5 0 01-.308.462 2.749 2.749 0 00-1 .719.251.251 0 01-.446-.165V8a.5.5 0 01.5-.5H7a1 1 0 000-2H3a2 2 0 00-2 2v9.274a.5.5 0 01-.25.433A1.493 1.493 0 000 18.5V23a1 1 0 002 0v-1.5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5V23a1 1 0 002 0v-4.5a1.493 1.493 0 00-.75-1.293zM5.5 15.25h13a1.252 1.252 0 011.25 1.25.5.5 0 01-.5.5H4.75a.5.5 0 01-.5-.5 1.252 1.252 0 011.25-1.25z\"/><path d=\"M9.063 7.648A1 1 0 0010 9h4a1 1 0 00.937-1.352l-1.125-3a.977.977 0 00-.518-.545.5.5 0 01-.294-.456V1a1 1 0 00-2 0v2.647a.5.5 0 01-.294.456.977.977 0 00-.518.545z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});