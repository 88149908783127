define("ember-svg-jar/inlined/single-man-actions-view.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-view</title><path d=\"M.619 18.062H2.41a.246.246 0 01.246.227l.444 5.257a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h.665a.248.248 0 00.243-.291 6.86 6.86 0 011.1-5.079.247.247 0 00.031-.213A5.441 5.441 0 00.124 14.1v3.464a.494.494 0 00.495.498z\"/><path d=\"M1.361 4.206A4.206 4.206 0 105.567 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM16.454 22.021a5.4 5.4 0 002.917-.853.248.248 0 01.308.034l2.508 2.508a.989.989 0 001.4-1.4l-2.509-2.51a.247.247 0 01-.033-.308 5.44 5.44 0 10-4.591 2.527zm0-8.908a3.464 3.464 0 11-3.464 3.464 3.469 3.469 0 013.464-3.464z\"/><path d=\"M.619 18.062H2.41a.246.246 0 01.246.227l.444 5.257a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h.665a.248.248 0 00.243-.291 6.86 6.86 0 011.1-5.079.247.247 0 00.031-.213A5.441 5.441 0 00.124 14.1v3.464a.494.494 0 00.495.498z\"/><path d=\"M1.361 4.206A4.206 4.206 0 105.567 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM16.454 22.021a5.4 5.4 0 002.917-.853.248.248 0 01.308.034l2.508 2.508a.989.989 0 001.4-1.4l-2.509-2.51a.247.247 0 01-.033-.308 5.44 5.44 0 10-4.591 2.527zm0-8.908a3.464 3.464 0 11-3.464 3.464 3.469 3.469 0 013.464-3.464z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});