define("ember-svg-jar/inlined/headphones-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>headphones-man</title><path d=\"M21.113 8.3a.252.252 0 01-.159-.209 9 9 0 00-17.908 0 .252.252 0 01-.159.209 4.5 4.5 0 001.234 8.685.814.814 0 00.879-.806V9a7 7 0 0114 0v7.179a.815.815 0 00.881.809A4.5 4.5 0 0021.113 8.3z\"/><path d=\"M17 12a5 5 0 10-5 5 5.006 5.006 0 005-5zm-8 0a2.977 2.977 0 01.156-.954.251.251 0 01.356-.141 9.07 9.07 0 004.321 1.089 9.308 9.308 0 001.088-.064.066.066 0 01.055.017A.071.071 0 0115 12a3 3 0 01-6 0zM12 18.5a9.052 9.052 0 00-7.932 4.75.5.5 0 00.432.75h15a.5.5 0 00.433-.75A9.054 9.054 0 0012 18.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});