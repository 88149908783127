define("ember-svg-jar/inlined/job-choose-candidate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>job-choose-candidate</title><path d=\"M13.5 13.374a1.5 1.5 0 113 0v4.25a.25.25 0 00.25.25h2.75a4 4 0 014 4v1.251a.25.25 0 01-.25.25H11.607a.253.253 0 01-.186-.082l-2.236-2.465a1.5 1.5 0 012.309-1.915l1.564 1.787a.25.25 0 00.438-.164z\"/><circle cx=\"15.419\" cy=\"2.846\" r=\"2.222\"/><path d=\"M11.678 9.957a.251.251 0 01-.183-.079.248.248 0 01-.067-.187 4 4 0 017.982 0 .248.248 0 01-.067.188.25.25 0 01-.182.079z\"/><circle cx=\"4.496\" cy=\"8.346\" r=\"2.222\"/><path d=\"M.754 15.457a.25.25 0 01-.182-.079.248.248 0 01-.067-.187 4 4 0 017.982 0 .25.25 0 01-.249.267z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});