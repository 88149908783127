define("ember-svg-jar/inlined/bandage-leg-hanging-hospital", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bandage-leg-hanging-hospital</title><path d=\"M.5 6.75H3a.25.25 0 01.25.25v2.5a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5V7A.25.25 0 017 6.75h2.5a.5.5 0 00.5-.5v-2.5a.5.5 0 00-.5-.5H7A.25.25 0 016.75 3V.5a.5.5 0 00-.5-.5h-2.5a.5.5 0 00-.5.5V3a.25.25 0 01-.25.25H.5a.5.5 0 00-.5.5v2.5a.5.5 0 00.5.5z\"/><circle cx=\"6\" cy=\"15.5\" r=\"2.5\"/><path d=\"M24 20.5a1 1 0 00-1-1H2.25a.25.25 0 01-.25-.25V13.5a1 1 0 00-2 0V23a1 1 0 002 0v-.5a.25.25 0 01.25-.25h19.5a.25.25 0 01.25.25v.5a1 1 0 002 0z\"/><path d=\"M11.5 17.5h2.559a3.5 3.5 0 003.32-2.394l.588-1.764a.489.489 0 01.134-.183.992.992 0 00.4.091h2.646A1 1 0 0021.8 13h.7a1.5 1.5 0 00.028-3l1.458-8.838a1 1 0 00-.824-1.146.989.989 0 00-1.148.824L20.3 11.25h-.921L17.991.869a1 1 0 10-1.982.264l1.212 9.094a3.49 3.49 0 00-2.1 2.167l-.588 1.764a.5.5 0 01-.474.342H11.5a1.5 1.5 0 000 3z\"/><path d=\"M19.86 14.5a.5.5 0 00-.474.342l-.667 2a.5.5 0 00.475.658H22a1.5 1.5 0 000-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});