define("ember-svg-jar/inlined/baggage-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baggage-1</title><path d=\"M16.5 5.75h-1a.5.5 0 01-.5-.5V3a.5.5 0 01.5-.5 1.25 1.25 0 000-2.5h-7a1.25 1.25 0 000 2.5.5.5 0 01.5.5v2.25a.5.5 0 01-.5.5h-1A2.5 2.5 0 005 8.25v12a2.494 2.494 0 002 2.437.312.312 0 01.25.306V23a1 1 0 002 0 .25.25 0 01.25-.25h5a.25.25 0 01.25.25 1 1 0 002 0v-.007a.311.311 0 01.25-.306 2.494 2.494 0 002-2.437v-12a2.5 2.5 0 00-2.5-2.5zM11 3a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v2.25a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5zm5 15.25a1.5 1.5 0 01-1.5 1.5h-5a1.5 1.5 0 01-1.5-1.5v-2.5a1 1 0 011-1h6a1 1 0 011 1zM15.5 10h-4.25a.5.5 0 00-.5.5v1a.75.75 0 01-1.5 0v-1a.5.5 0 00-.5-.5H8.5a.75.75 0 010-1.5h7a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});