define("ember-svg-jar/inlined/natural-disaster-hurricane-radar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>natural-disaster-hurricane-radar</title><path d=\"M12.8 19.82A9.107 9.107 0 0111 20 9 9 0 013.8 5.613a.251.251 0 01.377-.027l6.121 6.121a1 1 0 001.414-1.414L5.586 4.172a.246.246 0 01-.072-.194.25.25 0 01.1-.183A9 9 0 0119.82 9.2a8.694 8.694 0 01.136.9 1 1 0 101.99-.2A10.991 10.991 0 1013.2 21.78a1 1 0 00-.4-1.96z\"/><path d=\"M6.5 11a1 1 0 00-2 0 6.465 6.465 0 005.913 6.474h.09a1 1 0 00.088-2A4.473 4.473 0 016.5 11zM11 4.5a1 1 0 000 2 4.509 4.509 0 014.414 3.616 1 1 0 00.98.8 1.024 1.024 0 00.2-.019 1 1 0 00.785-1.175A6.512 6.512 0 0011 4.5zM14 15h9a1 1 0 000-2h-9a1 1 0 000 2zM22 16h-6.5a1 1 0 000 2H22a1 1 0 000-2zM20 21a1 1 0 000-2h-3a1 1 0 000 2zM21 22h-2a1 1 0 000 2h2a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});