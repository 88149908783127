define("ember-svg-jar/inlined/goods-boxes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Group 13</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#3755E1\" d=\"M20 20h10v10H20z\"/><path fill=\"#5B74E5\" d=\"M10 20h10v10H10z\"/><path fill=\"#7E94F7\" d=\"M0 20h10v10H0z\"/><path fill=\"#3C68DC\" d=\"M10 10h10v10H10z\"/><path fill=\"#2845E7\" d=\"M20 10h10v10H20z\"/><path fill=\"#1327CF\" d=\"M20 0h10v10H20z\"/></g>",
    "attrs": {
      "width": "30",
      "height": "30",
      "viewBox": "0 0 30 30",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});