define("ember-svg-jar/inlined/medical-condition-throat-problem", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-condition-throat-problem</title><path d=\"M14.393 18a2.253 2.253 0 00-2.25 2.25v3.25a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3.25a.25.25 0 01.25-.25h1.75a1 1 0 100-2z\"/><path d=\"M21.428 9.856a7.154 7.154 0 01-.721-1.45C20.707 3.145 15.844 0 11.143 0a9.925 9.925 0 00-10 10.063c0 3.5 1.271 6.124 3.779 7.813a.5.5 0 01.221.415V23.5a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-3.75a3.755 3.755 0 013.75-3.75h5.75a1 1 0 00.941-.661.5.5 0 01.477-.331h.633a1.162 1.162 0 001.153-1.262v-.138c.084-1.119-.714-2.518-1.419-3.752z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});