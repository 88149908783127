define("ember-svg-jar/inlined/segway-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>segway-person</title><circle cx=\"17.25\" cy=\"2.5\" r=\"2.5\"/><path d=\"M17.262 5.872l-.99-.146A2 2 0 0014 7.41l-1.24 8.37a.25.25 0 00.29.283 4.129 4.129 0 01.7-.063 4.429 4.429 0 012.47.76.249.249 0 00.214.031.253.253 0 00.158-.147l.46-1.174a31.277 31.277 0 001.822-6.834l.073-.492a2 2 0 00-1.685-2.272zM13.75 17a3.5 3.5 0 103.5 3.5 3.5 3.5 0 00-3.5-3.5zm0 5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM6.25 6.75h5.564a1 1 0 000-2H6.25a1 1 0 000 2zM12.314 9.25a1 1 0 00-1-1H5.25a1 1 0 000 2h6.064a1 1 0 001-1zM11.814 12.75a1 1 0 00-1-1H7.75a1 1 0 000 2h3.064a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});