define("ember-svg-jar/inlined/kitchenware-ladle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchenware-ladle</title><path d=\"M24 1.5A1.5 1.5 0 0022.5 0c-7.333 0-9.926 7.441-10.414 12.547a.5.5 0 01-.5.453H4.5A1.5 1.5 0 003 14.5V18a6 6 0 0012 0v-3.978C15.011 13.7 15.438 3 22.5 3A1.5 1.5 0 0024 1.5z\"/><path d=\"M5.528 9.379a.252.252 0 01.206.042A2.96 2.96 0 007.5 10a3 3 0 001.558-5.557.246.246 0 01-.117-.173A4.5 4.5 0 104.5 9.5a4.432 4.432 0 001.028-.121zM2 5a2.5 2.5 0 015-.044c0 .026 0 .053-.006.07a1 1 0 00.751 1.012A1 1 0 116.707 7.6a1 1 0 00-1.2-.312 2.46 2.46 0 01-1 .215A2.5 2.5 0 012 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});