define("ember-svg-jar/inlined/single-woman-actions-address.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-address</title><path d=\"M11.915 12.039a.251.251 0 00-.092-.41l-.224-.082a.778.778 0 01-.513-.729v-.468a.251.251 0 01.25-.25 6.374 6.374 0 003.786-.895.387.387 0 00.084-.592c-.494-.538-.835-1.186-.981-3.318-.262-3.825-2.864-5.17-5.1-5.17S4.286 1.47 4.025 5.3c-.145 2.132-.487 2.78-.981 3.318a.387.387 0 00.084.592 6.355 6.355 0 003.782.9.248.248 0 01.253.25v.462a.776.776 0 01-.512.729c-2.9 1.065-5.212 1.826-5.764 2.922a7.828 7.828 0 00-.76 2.727.386.386 0 00.1.3.392.392 0 00.29.127h8.862a.251.251 0 00.25-.243 7.961 7.961 0 012.286-5.345z\"/><path d=\"M17.543 10.876A6.5 6.5 0 0015.849 23.7a6.838 6.838 0 001.528.175 6.485 6.485 0 001.671-.218 1 1 0 10-.514-1.933 4.6 4.6 0 01-2.238.026A4.472 4.472 0 0113 18.44a4.544 4.544 0 01.9-3.916 4.482 4.482 0 013.6-1.648 4.664 4.664 0 014.383 4.753v.777a.709.709 0 01-.536.681.663.663 0 01-.183.038.72.72 0 01-.719-.719v-1.031a3.063 3.063 0 10-3.062 3.062 3.005 3.005 0 001.4-.349.248.248 0 01.307.06 2.734 2.734 0 00.9.7 2.687 2.687 0 001.179.281 2.722 2.722 0 002.719-2.719v-.777a6.677 6.677 0 00-6.345-6.757zm-.168 7.561a1.063 1.063 0 111.062-1.062 1.064 1.064 0 01-1.062 1.062z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});