define("ember-svg-jar/inlined/breakup-cry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>breakup-cry</title><path d=\"M21 15a.5.5 0 00-.431.247C19.739 16.658 18 19.739 18 21a3 3 0 006 0c0-1.262-1.739-4.342-2.569-5.754A.5.5 0 0021 15zM12.007 4a3.9 3.9 0 00-.392.02.249.249 0 00-.183.385A2 2 0 019.758 7.5a1.968 1.968 0 01-1.252-.45.25.25 0 00-.4.137 3.591 3.591 0 00-.1.814 4 4 0 104-4z\"/><path d=\"M23.02 10.52a3.729 3.729 0 000-5.04C21.508 3.833 17.408 0 12 0S2.492 3.833.98 5.48a3.729 3.729 0 000 5.04C2.492 12.167 6.592 16 12 16s9.508-3.833 11.02-5.48zm-1.475-1.352C20.212 10.621 16.615 14 12 14s-8.212-3.379-9.545-4.832a1.727 1.727 0 010-2.336C3.788 5.379 7.385 2 12 2s8.212 3.379 9.545 4.832a1.727 1.727 0 010 2.336z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});