define("ember-svg-jar/inlined/vintage-car-carriage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-car-carriage</title><path d=\"M21.669 16.467a.247.247 0 01.059-.409A3.943 3.943 0 0024 12.5a.99.99 0 10-1.979 0 1.981 1.981 0 01-1.98 1.979H17.7a.247.247 0 01-.238-.312l1.2-4.375 1.345-4.948a.953.953 0 00.035-.26c0-2.922-4.465-3.958-8.288-3.958s-8.29 1.029-8.29 3.951a.957.957 0 00.034.256l1.326 4.949.11.41a.248.248 0 00.255.183c.11-.007.22-.012.331-.012a7.507 7.507 0 017.322 5.9.248.248 0 00.241.2h2.784a.247.247 0 01.187.409 3.9 3.9 0 00-.961 2.56 3.959 3.959 0 106.576-2.956zM8.412 8.289a.247.247 0 01-.247.247H6.729a.247.247 0 01-.239-.183l-1.021-3.81a.253.253 0 010-.144c.18-.527 1.148-1.063 2.634-1.412a.248.248 0 01.3.241zm4.7 0a.247.247 0 01-.247.247h-2.226a.247.247 0 01-.247-.247V2.88a.247.247 0 01.232-.247 18.605 18.605 0 012.257 0 .247.247 0 01.232.247zm3.647.247H15.34a.247.247 0 01-.247-.247V3.231a.247.247 0 01.3-.241 8.771 8.771 0 011.039.291c.883.319 1.457.725 1.595 1.116a.246.246 0 01.006.147L17 8.354a.248.248 0 01-.24.182zM19.052 21.4a1.98 1.98 0 111.979-1.979 1.982 1.982 0 01-1.979 1.979z\"/><path d=\"M5.52 12.342a5.52 5.52 0 105.52 5.52 5.526 5.526 0 00-5.52-5.52zm0 9.06a3.54 3.54 0 113.54-3.54A3.544 3.544 0 015.52 21.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});