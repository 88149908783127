define("ember-svg-jar/inlined/messages-people-person-bubble-oval-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-person-bubble-oval-1</title><path d=\"M7.5 16A7.508 7.508 0 000 23.5a.5.5 0 00.5.5h14a.5.5 0 00.5-.5A7.508 7.508 0 007.5 16z\"/><circle cx=\"7.5\" cy=\"10.75\" r=\"4.75\"/><circle cx=\"13.116\" cy=\"5.5\" r=\"1\"/><circle cx=\"16.366\" cy=\"5.5\" r=\"1\"/><circle cx=\"19.616\" cy=\"5.5\" r=\"1\"/><path d=\"M18.5 0h-6a5.465 5.465 0 00-4.916 3.043 1 1 0 101.79.893A3.475 3.475 0 0112.5 2h6a3.5 3.5 0 010 7h-1a1 1 0 00-.625.219l-.878.7A1 1 0 0015 9h-1a1 1 0 000 2v1a1 1 0 001.625.781L17.851 11h.649a5.5 5.5 0 000-11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});