define("ember-svg-jar/inlined/outdoors-camp-fire-make", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-camp-fire-make</title><path d=\"M5.75 16.5a.75.75 0 00.75-.75v-.5a.25.25 0 01.25-.25h.5a.75.75 0 000-1.5h-.5a.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25h-.5a.75.75 0 000 1.5h.5a.25.25 0 01.25.25v.5a.75.75 0 00.75.75zM.75 11.75H1a.25.25 0 01.25.25v.25a.75.75 0 001.5 0V12a.25.25 0 01.25-.25h.25a.75.75 0 000-1.5H3a.25.25 0 01-.25-.25v-.25a.75.75 0 00-1.5 0V10a.25.25 0 01-.25.25H.75a.75.75 0 000 1.5z\"/><rect x=\"10.5\" width=\"2\" height=\"2\" rx=\".5\" ry=\".5\"/><path d=\"M23 9.5H12.507a.5.5 0 01-.464-.312A25.5 25.5 0 0110.75 5.25.221.221 0 0111 5h6.25a1 1 0 000-2h-1a.5.5 0 010-1h1.4a2.317 2.317 0 011.82.875l.214.272A2.713 2.713 0 0022.1 4.5h.9a1 1 0 000-2h-.9c-.388 0-.4 0-.835-.575l-.235-.3A4.312 4.312 0 0017.649 0h-1.4a2.5 2.5 0 00-2.5 2.5.5.5 0 01-.5.5H11a2.2 2.2 0 00-2.25 2.25 18.614 18.614 0 001.177 4.019 2.99 2.99 0 002.9 2.231H23a1 1 0 000-2zM8.077 11.5a1 1 0 000-2 1 1 0 01-.977-.767.875.875 0 00-.038-.123A21.935 21.935 0 016 5.25.221.221 0 016.25 5H7a1 1 0 000-2h-.75A2.2 2.2 0 004 5.25a18.614 18.614 0 001.177 4.019 2.99 2.99 0 002.9 2.231zM20.75 17.5H13a.5.5 0 01-.5-.5v-4a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v4a.5.5 0 01-.5.5H3.25a3.25 3.25 0 000 6.5h17.5a3.25 3.25 0 000-6.5zm-17.5 5A1.75 1.75 0 115 20.75a1.749 1.749 0 01-1.75 1.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});