define("ember-svg-jar/inlined/bathroom-tub-towel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bathroom-tub-towel</title><path d=\"M23.729 14.32A1.007 1.007 0 0023 14h-.25a.5.5 0 01-.5-.5V3A3 3 0 0017.214.8l-.463.463a.5.5 0 01-.505.123 2.931 2.931 0 00-2.931.668l-.205.205a1 1 0 000 1.414l2.645 2.646a1 1 0 001.414 0l.2-.205a2.864 2.864 0 00.683-2.939.5.5 0 01.119-.512l.41-.41A1 1 0 0120.249 3v10.5a.5.5 0 01-.5.5h-5.367a.5.5 0 01-.477-.351 2 2 0 00-1.9-1.4H7a2 2 0 00-1.9 1.4.5.5 0 01-.482.351H1a.985.985 0 00-.73.32 1.016 1.016 0 00-.27.74A9.508 9.508 0 009.49 24h5.02A9.508 9.508 0 0024 15.06a.986.986 0 00-.271-.74zM12 19.75a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5z\"/><path d=\"M9 6.088A1 1 0 009.419 6l1.816-.838a1 1 0 10-.835-1.819l-1.819.837A1 1 0 009 6.088zM14.122 11.509a1 1 0 001.312-.527l.786-1.84a1 1 0 00-1.839-.785L13.6 10.2a1 1 0 00.522 1.309zM10.486 9.076a1 1 0 001.414.007l1.422-1.406a1 1 0 00-1.406-1.422l-1.422 1.407a1 1 0 00-.008 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});