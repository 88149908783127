define("ember-svg-jar/inlined/kitchenware-spatula-scraper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchenware-spatula-scraper</title><path d=\"M23.707 5.958a1 1 0 000-1.414l-2.828-2.829a3.509 3.509 0 00-4.95 0l-5.3 5.3a1.5 1.5 0 000 2.121l.18.18a.25.25 0 010 .354L.586 19.893a2 2 0 102.828 2.828L13.635 12.5a.251.251 0 01.354 0l.88.88a1 1 0 001.414 0zm-2.761.207l-4.6 4.6A.75.75 0 0115.289 9.7l4.6-4.6a.75.75 0 011.061 1.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});