define("ember-svg-jar/inlined/real-estate-update-house-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-update-house-refresh</title><path d=\"M20.61 17.851a.987.987 0 00-1.371.28A8.657 8.657 0 117.475 6a.5.5 0 01.754.382l.065.853a.743.743 0 001.23.5l3.958-3.465a.742.742 0 00-.077-1.176L8.951.125A.743.743 0 007.8.8l.182 2.356a.5.5 0 01-.292.49 10.636 10.636 0 1013.2 15.577.99.99 0 00-.28-1.372zM21.712 13.086a1 1 0 00-1.058.914 8.562 8.562 0 01-.208 1.359.989.989 0 00.732 1.192.971.971 0 00.231.027.989.989 0 00.962-.759 10.534 10.534 0 00.256-1.676.989.989 0 00-.915-1.057zM20.067 10.161a.99.99 0 101.84-.731 10.422 10.422 0 00-.753-1.52.989.989 0 10-1.7 1.021 8.4 8.4 0 01.613 1.23zM15.572 5.46a8.4 8.4 0 011.205.659.99.99 0 101.084-1.656 10.574 10.574 0 00-1.489-.814.99.99 0 10-.8 1.811z\"/><path d=\"M13.509 18.875h1.5a1 1 0 001-1v-2.5a.5.5 0 01.5-.5h1a.5.5 0 00.336-.87l-5.164-4.694a.994.994 0 00-1.343 0l-5.165 4.694a.5.5 0 00.336.87h1a.5.5 0 01.5.5v2.5a1 1 0 001 1h1.5a.5.5 0 00.5-.5v-1.5a1 1 0 012 0v1.5a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});