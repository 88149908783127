define("ember-svg-jar/inlined/cd-broken", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cd-broken</title><path d=\"M14.73.735a.509.509 0 00-.547.228L11.9 4.642a.5.5 0 00.425.763h1.646a.5.5 0 01.373.834L8.457 12.75a.5.5 0 01-.826-.545l2.483-4.087a.5.5 0 00-.453-.713H7.614a.5.5 0 01-.426-.76l3.227-5.283a.5.5 0 00-.524-.757 11.491 11.491 0 104.839.13zM12 16.155a4.23 4.23 0 01-2.978-1.217.75.75 0 011.051-1.071A2.75 2.75 0 1013.882 9.9a.75.75 0 111.029-1.092A4.25 4.25 0 0112 16.155z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});