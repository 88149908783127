define("ember-svg-jar/inlined/weather-night-snow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-night-snow</title><path d=\"M23.523 10.338a4.5 4.5 0 010-7.676 1 1 0 000-1.7 6.5 6.5 0 00-9.174 2.558.25.25 0 00-.008.212.246.246 0 00.157.142 8.5 8.5 0 011.356.552.251.251 0 00.333-.1 4.492 4.492 0 014.444-2.295.249.249 0 01.2.153.252.252 0 01-.037.252A6.462 6.462 0 0019.375 6.5a6.615 6.615 0 00.211 1.658.33.33 0 00.019.049 8.443 8.443 0 01.54 1.363.226.226 0 00.021.048 6.381 6.381 0 00.632.948.25.25 0 01-.168.4.193.193 0 00-.17.21l.006.056a.12.12 0 00.037.078 6.544 6.544 0 011.067 1.34.249.249 0 00.28.112 6.451 6.451 0 001.673-.725 1 1 0 000-1.7z\"/><path d=\"M19.094 12.074a.252.252 0 01-.1-.193A7 7 0 005.338 9.84a.249.249 0 01-.22.173 5.5 5.5 0 00-1.568 10.63.25.25 0 00.3-.362A2.481 2.481 0 013.5 19a2.514 2.514 0 01.037-.428.25.25 0 00-.1-.245 3.5 3.5 0 012.509-6.3 1 1 0 001.118-.827A5 5 0 0117 12c0 .146-.007.289-.02.433a1 1 0 00.5.959 3 3 0 01.883 4.452.249.249 0 00-.036.246 2.5 2.5 0 010 1.816.25.25 0 00.368.3 5 5 0 00.4-8.134z\"/><circle cx=\"6\" cy=\"23\" r=\"1\"/><circle cx=\"8.5\" cy=\"21\" r=\"1\"/><circle cx=\"11\" cy=\"23\" r=\"1\"/><circle cx=\"13.5\" cy=\"21\" r=\"1\"/><circle cx=\"16\" cy=\"23\" r=\"1\"/><circle cx=\"6\" cy=\"19\" r=\"1\"/><circle cx=\"8.5\" cy=\"17\" r=\"1\"/><circle cx=\"11\" cy=\"19\" r=\"1\"/><circle cx=\"13.5\" cy=\"17\" r=\"1\"/><circle cx=\"16\" cy=\"19\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});