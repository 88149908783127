define("ember-svg-jar/inlined/landmark-empire-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-empire-state</title><path d=\"M6.75 19h-3a1.5 1.5 0 00-1.5 1.5v1a.5.5 0 01-.5.5H1a1 1 0 000 2h5.75a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5zM6.75 13.5h-2a1.5 1.5 0 00-1.5 1.5v2a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5zM6.75 9h-1a1.5 1.5 0 00-1.5 1.5v1a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5zM12.75 13.5a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-2a1.5 1.5 0 00-1.5-1.5zM12.25 9.5v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1a1.5 1.5 0 00-1.5-1.5h-1a.5.5 0 00-.5.5zM18.5 22h-.75a.5.5 0 01-.5-.5v-1a1.5 1.5 0 00-1.5-1.5h-3a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h5.75a1 1 0 000-2z\"/><path d=\"M12.75 7V5.667a.5.5 0 00-.1-.3l-1.8-2.4a.5.5 0 01-.1-.3V1a1 1 0 00-2 0v1.667a.5.5 0 01-.1.3l-1.8 2.4a.5.5 0 00-.1.3V7a.5.5 0 00.5.5h1a.5.5 0 01.5.5v15.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V8a.5.5 0 01.5-.5h1a.5.5 0 00.5-.5zM20.75 0a3.231 3.231 0 00-2.941 1.877.25.25 0 01-.261.143 2.25 2.25 0 10-.3 4.48h3.5a3.25 3.25 0 000-6.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});