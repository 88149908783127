define("ember-svg-jar/inlined/warehouse-package-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>warehouse-package-search</title><path d=\"M15 5.75c.207 0 .412.008.615.022a.25.25 0 00.267-.25V.882A.882.882 0 0015 0h-4.25a.25.25 0 00-.25.25v3.867a.5.5 0 01-.757.429l-1.486-.892a.5.5 0 00-.514 0l-1.486.892a.5.5 0 01-.757-.429V.25A.25.25 0 005.25 0H.882A.882.882 0 000 .882v13.236A.882.882 0 00.882 15h4.877a.249.249 0 00.25-.25 8.982 8.982 0 018.991-9z\"/><path d=\"M21.182 18.979a7.526 7.526 0 10-2.493 2.289.243.243 0 01.3.033c.556.54 2.481 2.286 2.5 2.308a1.5 1.5 0 002.022-2.218L21.216 19.3a.244.244 0 01-.034-.321zM9.5 14.75a5.5 5.5 0 115.5 5.5 5.506 5.506 0 01-5.5-5.5z\"/><path d=\"M12.5 14.25a.75.75 0 00.75-.75v-1a.75.75 0 00-1.5 0v1a.75.75 0 00.75.75zM12.5 15.25a.75.75 0 00-.75.75v.5a.75.75 0 001.5 0V16a.75.75 0 00-.75-.75zM17.5 11.75a.75.75 0 00-.75.75v4a.75.75 0 001.5 0v-4a.75.75 0 00-.75-.75zM15.75 12.5a.75.75 0 00-1.5 0v4a.75.75 0 001.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});