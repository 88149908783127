define("ember-svg-jar/inlined/coffee-cold-press-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>coffee-cold-press-1</title><path d=\"M5.05 5.25h9.9a.5.5 0 00.49-.6 3 3 0 00-2.94-2.4h-.826a.249.249 0 01-.2-.1.245.245 0 01-.038-.222A1.427 1.427 0 0011.5 1.5a1.5 1.5 0 00-3 0 1.427 1.427 0 00.065.426.251.251 0 01-.239.324H7.5a3 3 0 00-2.94 2.4.5.5 0 00.49.6zM16 6.75h-4.75a.5.5 0 00-.5.5V15a.5.5 0 00.5.5H13a.75.75 0 010 1.5H7a.75.75 0 010-1.5h1.75a.5.5 0 00.5-.5V7.25a.5.5 0 00-.5-.5H3a1 1 0 00-.681 1.731l1.534 1.33a.5.5 0 01.147.353V20.25a1.5 1.5 0 00.9 1.373.255.255 0 01.141.166A.25.25 0 015 22l-.3.4a1 1 0 00.8 1.6 1 1 0 00.8-.4l1.237-1.65a.5.5 0 01.4-.2h4.125a.5.5 0 01.4.2L13.7 23.6a1 1 0 00.8.4 1 1 0 00.8-1.6L15 22a.247.247 0 01-.042-.213.25.25 0 01.141-.166A1.5 1.5 0 0016 20.25V19a.251.251 0 01.24-.25 6 6 0 00-.24-12zm.571 9.959a.5.5 0 01-.571-.5V9.286a.5.5 0 01.571-.495 4 4 0 010 7.918z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});