define("ember-svg-jar/inlined/seat-child", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seat-child</title><circle cx=\"8.5\" cy=\"3\" r=\"2.5\"/><circle cx=\"14.5\" cy=\"5\" r=\"2\"/><path d=\"M17.659 17.684a3.483 3.483 0 00-3.485-3.184h-2.423a.506.506 0 01-.5-.452l-.769-5.265a1.5 1.5 0 00-2.968.434l.766 5.247a3.512 3.512 0 003.471 3.036h2.423a.493.493 0 01.5.44l.335 4.18A1.5 1.5 0 1018 21.88z\"/><path d=\"M19.857 14.486l-.917-1.531a3.02 3.02 0 00-2.573-1.455h-1.283a.249.249 0 01-.247-.215l-.143-1a.25.25 0 01.248-.285H17a1 1 0 000-2h-2.347a2 2 0 00-1.98 2.283l.214 1.5a2.009 2.009 0 001.98 1.717h1.5a1.009 1.009 0 01.859.486l.917 1.529a1 1 0 101.714-1.029zM12.5 18.75H8.584a1 1 0 01-.974-.77l-1.636-6.958a1 1 0 00-1.948.457l1.637 6.959a2.991 2.991 0 002.921 2.312H12.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});