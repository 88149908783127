define("ember-svg-jar/inlined/go-pro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>go-pro</title><path d=\"M12.5 9.25a2.253 2.253 0 002.25 2.25h9a.25.25 0 00.25-.25V2.5A2.5 2.5 0 0021.5 0h-8.75a.25.25 0 00-.25.25zm6-1.25A2.5 2.5 0 1121 5.5 2.5 2.5 0 0118.5 8z\"/><path d=\"M14.75 13A3.755 3.755 0 0111 9.25v-9a.25.25 0 00-.25-.25H2.5A2.5 2.5 0 000 2.5v12A2.5 2.5 0 002.5 17h19a2.5 2.5 0 002.5-2.5v-1.25a.25.25 0 00-.25-.25zM2.875 4.5a.75.75 0 01.75-.75H7a.75.75 0 010 1.5H3.625a.75.75 0 01-.75-.75zM16.5 22h-2.25a.25.25 0 01-.25-.25V18.5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v.5a.25.25 0 01-.25.25h-3.5A.25.25 0 016 19a.5.5 0 00-.5-.5h-4a1.5 1.5 0 000 3h4A.5.5 0 006 21a.25.25 0 01.25-.25h3.5A.25.25 0 0110 21v.75a.25.25 0 01-.25.25H7.5a1 1 0 000 2h9a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});