define("ember-svg-jar/inlined/phone-off-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-off-alternate</title><path d=\"M18.424 6.636L6.637 18.425a.256.256 0 00.019.38A8.65 8.65 0 0018.8 6.656a.256.256 0 00-.376-.02zM17.6 15.81l-.741.742a1.873 1.873 0 01-2.3.348 14.54 14.54 0 01-2.394-1.764l1.926-1.927.217.211.45-.45a1.216 1.216 0 011.713-.09l1.217 1.22a1.215 1.215 0 01-.088 1.71zM9.7 13.238a.242.242 0 00.016-.333A36.611 36.611 0 017.1 9.442a1.872 1.872 0 01.349-2.3l.742-.742A1.215 1.215 0 019.9 6.313l1.217 1.216a1.218 1.218 0 01-.09 1.714l-.451.45c.173.216.685.834 1.037 1.251a.247.247 0 00.366.013l5.381-5.381a.256.256 0 00-.02-.38A8.65 8.65 0 005.2 17.345a.256.256 0 00.38.019z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22.159A10.159 10.159 0 1122.159 12 10.17 10.17 0 0112 22.159z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});