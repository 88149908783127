define("ember-svg-jar/inlined/farming-farmer-crops", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>farming-farmer-crops</title><path d=\"M9.566 5.5a2.5 2.5 0 105 0 2.548 2.548 0 00-.015-.278A.249.249 0 0014.3 5H9.83a.249.249 0 00-.248.222 2.547 2.547 0 00-.016.278zM16.126 4a1 1 0 000-2h-1.465a.25.25 0 01-.228-.148l-.118-.263A1.507 1.507 0 0012.872.5H11.38a1.474 1.474 0 00-1.417 1.02l-.148.331A.249.249 0 019.587 2H8.126a1 1 0 100 2h8z\"/><path d=\"M2.476 13.465l.6.759a.253.253 0 01.045.219 1.227 1.227 0 00.267 1.142 1.247 1.247 0 001.764.092l3.5-3.147a.25.25 0 01.417.186v3.771l-1.432 5.97a1.25 1.25 0 002.431.584l1.232-5.159a.5.5 0 01.487-.383h.619a.5.5 0 01.487.383l1.238 5.159a1.25 1.25 0 002.431-.584l-1.5-6.246v-4.444a.25.25 0 01.246-.25A5.224 5.224 0 0019.8 9.05l.635-1.25a1.217 1.217 0 00.129-.992.238.238 0 010-.127l.237-.912a3.031 3.031 0 002.854-1.6A3 3 0 0022.393.114a1 1 0 00-.929 1.772 1 1 0 01.42 1.35 1.009 1.009 0 01-1.117.508 1.5 1.5 0 00-1.8 1.105c-.106.435.013-.115-.309 1.207a.249.249 0 01-.085.134 1.167 1.167 0 00-.254.282l-.634 1.254a2.738 2.738 0 01-2.335 1.292H9.967A3.121 3.121 0 007.96 9.9L6.8 10.916a.249.249 0 01-.414-.2l.034-.8.06-1.365a1 1 0 00-.957-1.042 1.016 1.016 0 00-1.041.956l-.06 1.366-.533-1.724a1 1 0 10-1.91.59l.463 1.5-.658-.832a1 1 0 00-1.568 1.24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});