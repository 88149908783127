define("ember-svg-jar/inlined/question-help-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>question-help-message</title><path d=\"M21.5 0h-19A2.5 2.5 0 000 2.5v20.485a.5.5 0 00.805.4l5.56-4.277a.5.5 0 01.3-.1H21.5A2.5 2.5 0 0024 16.5v-14A2.5 2.5 0 0021.5 0zM12 16.247a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm1.6-6.08a1 1 0 00-.6.917 1 1 0 01-2 0 3 3 0 011.8-2.75A2 2 0 1010 6.5a1 1 0 11-2 0 4 4 0 115.6 3.666z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});