define("ember-svg-jar/inlined/image-file-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-sync</title><path d=\"M14.68 5.538a1.493 1.493 0 10-1.493 1.492 1.493 1.493 0 001.493-1.492zM14.5 12.234v.507a.248.248 0 00.265.259h5.548a.186.186 0 00.158-.286l-3.236-5.178a.373.373 0 00-.633 0l-1.81 2.9a.187.187 0 01-.317 0L13.831 9.4a.375.375 0 00-.633 0l-.365.583c-.162.232.039.284.139.308a1.909 1.909 0 01.295.1 1.994 1.994 0 011.233 1.843zM11.77 18.6a1 1 0 00-1.255.58 4.067 4.067 0 01-1.279 1.931 4.162 4.162 0 01-5.656-.47.256.256 0 01.014-.349l1.717-1.717a.5.5 0 00-.354-.853H.5a.5.5 0 00-.5.5v4.458a.5.5 0 00.854.353l.974-.974a.244.244 0 01.353.009 6.086 6.086 0 0010.211-2.2 1 1 0 00-.622-1.268zM12.691 11.772a.5.5 0 00-.545.109l-1.03 1.03a.244.244 0 01-.352-.008 6.1 6.1 0 00-10.2 2.226 1 1 0 001.879.685A4.056 4.056 0 013.718 13.9a4.154 4.154 0 015.638.435.255.255 0 01-.012.35l-1.655 1.653a.5.5 0 00.354.853H12.5a.5.5 0 00.5-.5v-4.457a.5.5 0 00-.309-.462z\"/><path d=\"M23.414 3L21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.8a.246.246 0 00.23.25c.35.02 1.016.069 1.487.14A.247.247 0 008 9.939V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-7.374a.243.243 0 00-.239.206 7.192 7.192 0 01-.408 1.453.244.244 0 00.225.34H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});