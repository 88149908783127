define("ember-svg-jar/inlined/tablet-touch-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tablet-touch-1</title><path d=\"M6.7 20.229a3.812 3.812 0 011.735-2.363.2.2 0 00-.1-.367H5a1 1 0 01-1-1V4.5a1 1 0 011-1h12a1 1 0 011 1v11.6a.25.25 0 00.25.25H19a5.016 5.016 0 011.183.163.25.25 0 00.314-.242V2.5A2.5 2.5 0 0018 0H4a2.5 2.5 0 00-2.5 2.5v17A2.5 2.5 0 004 22h2.4a.252.252 0 00.19-.087.255.255 0 00.057-.2 3.792 3.792 0 01.053-1.484z\"/><rect x=\"6.25\" y=\"5.749\" width=\"3.5\" height=\"3.5\" rx=\".75\" ry=\".75\"/><rect x=\"12.25\" y=\"5.749\" width=\"3.5\" height=\"3.5\" rx=\".75\" ry=\".75\"/><rect x=\"6.25\" y=\"11.249\" width=\"3.5\" height=\"3.5\" rx=\".75\" ry=\".75\"/><path d=\"M19 17.852h-2.25a.25.25 0 01-.25-.25V13.1a2.25 2.25 0 00-4.5 0v5.87a.251.251 0 01-.129.219.249.249 0 01-.254-.008 2.289 2.289 0 00-3.2 3.088l.668 1.318a.75.75 0 001.338-.679l-.7-1.376a.79.79 0 011.287-.912l1.159 1.448A.75.75 0 0013.5 21.6v-8.5a.75.75 0 011.5 0v5.5a.75.75 0 00.75.75h3.238A2.138 2.138 0 0121 21.5v1.75a.75.75 0 001.5 0V21.5a3.621 3.621 0 00-3.5-3.648z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});