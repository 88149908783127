define("ember-svg-jar/inlined/check-double-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>check-double-1</title><path d=\"M23.223 2.058a1.5 1.5 0 00-2.037.591L12.693 18.08l-6.231-6.794a1.5 1.5 0 10-2.211 2.027l7.643 8.335a1.518 1.518 0 002.42-.291l9.5-17.257a1.5 1.5 0 00-.591-2.042zM2.605 14.625a1.5 1.5 0 00-2.211 2.028l4.581 5a1.517 1.517 0 002.119.091 1.5 1.5 0 00.092-2.119z\"/><path d=\"M10.3 12.973a1.407 1.407 0 002.01-.54L16.894 4.1a1.5 1.5 0 10-2.627-1.446l-4.589 8.332a1.425 1.425 0 00.622 1.987z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});