define("ember-svg-jar/inlined/safety-fire-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-fire-right</title><path d=\"M18.339.512A2.056 2.056 0 0016.9 0a1.983 1.983 0 00-1.382.66 2 2 0 00.149 2.825l.641.577a.25.25 0 01-.167.436H9.5a2.012 2.012 0 00-1.983 1.777.251.251 0 00.3.268A2.321 2.321 0 019.731 7a2.358 2.358 0 01.83 1.307.25.25 0 00.244.192h5.331a.25.25 0 01.167.436l-.642.578a2 2 0 002.678 2.973l5-4.5a2 2 0 000-2.973zM7.7 8.179A4.864 4.864 0 014.99 9H.5a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3.059a5.907 5.907 0 005.516-3.835.87.87 0 00-.276-.99.9.9 0 00-1.099.004zM5.483 13.986A5.988 5.988 0 00.055 17.82a.875.875 0 00.277.991.829.829 0 00.523.183 1.038 1.038 0 00.576-.188 4.865 4.865 0 012.71-.821h3.547a5.907 5.907 0 005.517-3.834.875.875 0 00-.277-.991.9.9 0 00-1.1 0 4.867 4.867 0 01-2.71.82H5.483zM7.693 19.179a4.864 4.864 0 01-2.71.82H.5a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h4.687c2.48 0 3.06-1.588 3.733-3.427l.149-.407a.87.87 0 00-.275-.989.9.9 0 00-1.101.003z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});