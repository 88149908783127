define("ember-svg-jar/inlined/martial-arts-fencing-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>martial-arts-fencing-person</title><path d=\"M11.671 13.944a2.672 2.672 0 01-.509-.727A7.076 7.076 0 0014 7.422C14 3.653 11.308.588 8 .588S2 3.653 2 7.422a7.09 7.09 0 002.792 5.764 5.975 5.975 0 01-2.75 2.13A2.946 2.946 0 000 18.088v1a.5.5 0 00.5.5l8.122.062a4.979 4.979 0 013.049-5.706zM9 2.76a4.064 4.064 0 012.225 1.828H9zm0 3.828h2.93a5.7 5.7 0 01.07.834 5.607 5.607 0 01-.056.666H9zm0 3.5h2.331a4.115 4.115 0 01-2.331 2zM7 2.76v1.828H4.775A4.061 4.061 0 017 2.76zM4.056 8.088A5.607 5.607 0 014 7.422a5.7 5.7 0 01.07-.834H7v1.5zm.613 2H7v2a4.115 4.115 0 01-2.331-2z\"/><path d=\"M23.888 1.548a1 1 0 00-1.776-.92l-7.623 14.21a3.952 3.952 0 00-4.12 1.549 1 1 0 00.342 1.46l1.691.907s-1.982 3.718-1.991 3.738a.993.993 0 001.771.9l.01-.013.461-.861a3.957 3.957 0 004.883-3.97.972.972 0 00-.036-.155 3.947 3.947 0 00-1.246-2.614zM13.672 20.617l.492-.917.742.4a1.95 1.95 0 01-1.234.517z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});