define("ember-svg-jar/inlined/sound", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sound</title><path d=\"M16.239 0H7.761a1.963 1.963 0 00-1.886 2.03V13a1 1 0 002 0l.017-10.5a.5.5 0 01.5-.5h7.233a.5.5 0 01.5.5V13a1 1 0 002 0V2.03A1.963 1.963 0 0016.239 0z\"/><path d=\"M11.875 14a5 5 0 105 5 5 5 0 00-5-5zm2 7a.5.5 0 01-.237.426.5.5 0 01-.487.021l-1.789-.894a.5.5 0 00-.223-.053h-.764a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h.764a.5.5 0 00.223-.053l1.789-.894a.5.5 0 01.487.021.5.5 0 01.237.426zM11.875 3a1 1 0 00-1 1v2.25a.25.25 0 01-.25.25h-.5a1 1 0 000 2h.5a.25.25 0 01.25.25V11a1 1 0 002 0V8.751a.25.25 0 01.25-.25h.5a1 1 0 100-2h-.5a.25.25 0 01-.25-.25V4a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});