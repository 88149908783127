define("ember-svg-jar/inlined/professional-network-linkedin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professional-network-linkedin</title><rect x=\"2\" y=\"8.5\" width=\"5\" height=\"14\" rx=\".5\" ry=\".5\"/><ellipse cx=\"4.48\" cy=\"4\" rx=\"2.48\" ry=\"2.5\"/><path d=\"M18.5 22.5h3a.5.5 0 00.5-.5v-8.4C22 9.83 19.87 8 16.89 8a4.21 4.21 0 00-3.17 1.27A.41.41 0 0113 9a.5.5 0 00-.5-.5h-3A.5.5 0 009 9v13a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-7.5a2.5 2.5 0 015 0V22a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});