define("ember-svg-jar/inlined/meeting-smartphone-message-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-smartphone-message-alternate</title><path d=\"M12.5 12.371a.248.248 0 00-.105-.2 4.511 4.511 0 01-.947-.914.25.25 0 00-.448.152v8.183a.414.414 0 01-.417.411H2.417A.414.414 0 012 19.589V6.911a.414.414 0 01.417-.411h7.833a.25.25 0 00.25-.25v-2a.25.25 0 00-.25-.25H2.438A2.44 2.44 0 000 6.438v14.624A2.44 2.44 0 002.438 23.5h8.124A2.44 2.44 0 0013 21.062V15.08a.253.253 0 00-.045-.144A2.491 2.491 0 0112.5 13.5zM24 3.5a3 3 0 00-3-3h-6a3 3 0 00-3 3v5a3 3 0 001.846 2.769.251.251 0 01.154.231v2a1 1 0 001.832.555l1.629-2.444a.25.25 0 01.208-.111H21a3 3 0 003-3zm-2 5a1 1 0 01-1 1h-4a1 1 0 00-.832.445.2.2 0 01-.168.09.2.2 0 01-.168-.09A1 1 0 0015 9.5a1 1 0 01-1-1v-5a1 1 0 011-1h6a1 1 0 011 1z\"/><path d=\"M20 3.75h-4a.75.75 0 000 1.5h4a.75.75 0 000-1.5zM20.75 7.5a.75.75 0 00-.75-.75h-4a.75.75 0 000 1.5h4a.75.75 0 00.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});