define("ember-svg-jar/inlined/rating-five-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rating-five-star</title><path d=\"M14.68 8.4a.816.816 0 00.334-.9l-.523-1.9a.248.248 0 01.079-.256l1.438-1.216a.815.815 0 00-.526-1.428H13.8a.25.25 0 01-.232-.157l-.809-2.03a.815.815 0 00-1.514 0l-.811 2.027a.25.25 0 01-.234.16H8.518a.815.815 0 00-.527 1.437l1.443 1.22a.25.25 0 01.079.257l-.521 1.872a.816.816 0 001.285.863l1.571-1.217a.249.249 0 01.307 0l1.569 1.231a.817.817 0 00.956.037zM4.481 13.32l1.57 1.231a.815.815 0 001.289-.858l-.523-1.9a.25.25 0 01.079-.257l1.438-1.216a.815.815 0 00-.526-1.438h-1.68a.25.25 0 01-.228-.155L5.085 6.7a.815.815 0 00-1.514 0L2.76 8.727a.248.248 0 01-.231.157H.844a.816.816 0 00-.527 1.438l1.443 1.222a.25.25 0 01.079.257l-.521 1.872a.815.815 0 001.285.863l1.572-1.217a.248.248 0 01.306.001zM23.682 10.322a.815.815 0 00-.527-1.438h-1.68a.248.248 0 01-.231-.157L20.433 6.7a.815.815 0 00-1.514 0l-.811 2.027a.25.25 0 01-.232.157h-1.684a.815.815 0 00-.527 1.438l1.443 1.222a.25.25 0 01.079.257l-.521 1.872a.815.815 0 001.285.863l1.571-1.217a.249.249 0 01.307 0l1.571 1.232a.816.816 0 001.29-.858l-.524-1.9a.248.248 0 01.08-.257zM21.314 18.159h-1.68A.25.25 0 0119.4 18l-.811-2.028a.816.816 0 00-1.514 0L16.266 18a.25.25 0 01-.232.156H14.35a.816.816 0 00-.527 1.438l1.443 1.222a.25.25 0 01.079.257l-.521 1.873a.816.816 0 001.285.863l1.571-1.218a.251.251 0 01.307 0l1.57 1.231a.815.815 0 001.289-.858l-.523-1.9a.25.25 0 01.079-.257L21.84 19.6a.816.816 0 00-.526-1.439zM10.622 18.159h-1.68A.25.25 0 018.71 18l-.81-2.025a.816.816 0 00-1.514 0L5.574 18a.25.25 0 01-.232.156H3.658a.816.816 0 00-.527 1.444l1.443 1.222a.25.25 0 01.079.257l-.521 1.873a.816.816 0 001.285.863l1.571-1.218a.251.251 0 01.307 0l1.569 1.231a.816.816 0 001.29-.858l-.523-1.9a.25.25 0 01.079-.257l1.438-1.213a.816.816 0 00-.526-1.439z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});