define("ember-svg-jar/inlined/wildlife-protection-tree-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wildlife-protection-tree-shield</title><path d=\"M9.554 17.725a.5.5 0 00-.381-.426 10.885 10.885 0 00-1.232-.216.5.5 0 01-.441-.5v-4.608a.5.5 0 01.3-.457l2.422-1.077A3 3 0 0012 7.7V7a1 1 0 00-2 0v.7a1 1 0 01-.594.914l-1.555.691a.25.25 0 01-.351-.229v-2.61a.5.5 0 01.293-.455l3.449-1.568A3.007 3.007 0 0013 1.712V1a1 1 0 00-2 0v.712a1 1 0 01-.586.911L7.853 3.787a.251.251 0 01-.353-.228V3a1 1 0 00-2 0v3.223a.25.25 0 01-.384.211L2.463 4.745A.992.992 0 012 3.9V1a1 1 0 00-2 0v2.9a2.986 2.986 0 001.389 2.533L5.269 8.9a.5.5 0 01.231.422v2.051a.25.25 0 01-.371.219l-1.615-.9A1 1 0 013 9.823V9.5a1 1 0 00-2 0v.323a3 3 0 001.543 2.623l2.7 1.5a.5.5 0 01.257.437v2.2a.5.5 0 01-.441.5C2.13 17.427 0 18.805 0 20.5a.5.5 0 00.5.5h9.791a.249.249 0 00.219-.37 8.1 8.1 0 01-.956-2.905z\"/><path d=\"M23.072 12.04A16.393 16.393 0 0017.478 11a15.992 15.992 0 00-5.55 1.04A1.545 1.545 0 0011 13.456v3.393a7.716 7.716 0 005.19 6.805l.553.211a2.071 2.071 0 001.471 0l.553-.211C21.848 22.48 24 19.682 24 16.849v-3.393a1.545 1.545 0 00-.928-1.416zM20.5 18H19a.5.5 0 00-.5.5V20a1 1 0 01-2 0v-1.5a.5.5 0 00-.5-.5h-1.5a1 1 0 010-2H16a.5.5 0 00.5-.5V14a1 1 0 012 0v1.5a.5.5 0 00.5.5h1.5a1 1 0 010 2z\"/><circle cx=\"1.25\" cy=\"15.25\" r=\".75\"/><circle cx=\"4.25\" cy=\".75\" r=\".75\"/><circle cx=\"15.25\" cy=\"6.25\" r=\".75\"/><circle cx=\"21.75\" cy=\"7.25\" r=\".75\"/><circle cx=\"19.25\" cy=\"1.75\" r=\".75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});