define("ember-svg-jar/inlined/make-up-powder-tap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>make-up-powder-tap</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22.08A10.08 10.08 0 1122.08 12 10.092 10.092 0 0112 22.08z\"/><path d=\"M4.955 13.529a.241.241 0 00-.159.291 7.43 7.43 0 0014.409 0 .241.241 0 00-.16-.291 24.181 24.181 0 00-14.09 0zM18.606 9.557a.243.243 0 00.293-.319 7.43 7.43 0 00-13.8 0 .243.243 0 00.293.319 24.253 24.253 0 0113.212 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});