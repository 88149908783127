define("ember-svg-jar/inlined/keyboard-arrow-bottom-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-arrow-bottom-right</title><path d=\"M23.383 15a1 1 0 00-1.09.217l-2.3 2.3a.25.25 0 01-.354 0L8.15 6.027a1.5 1.5 0 00-2.121 2.121L17.52 19.639a.249.249 0 010 .353l-2.3 2.3A1 1 0 0015.929 24H23a1 1 0 001-1v-7.074a1 1 0 00-.617-.926z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});