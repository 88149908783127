define("ember-svg-jar/inlined/diagram-steady-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-steady-down</title><path d=\"M16.8 0a7.208 7.208 0 00-7.2 7.2v4.07a.25.25 0 01-.25.25H1.44a1.44 1.44 0 000 2.88h7.91a.25.25 0 01.25.25v3.34a.25.25 0 01-.25.25H8.16a1.44 1.44 0 00-1.018 2.46l2.879 2.879a1.439 1.439 0 002.038 0l2.879-2.879a1.44 1.44 0 00-1.018-2.46h-1.19a.25.25 0 01-.25-.25v-3.34a.25.25 0 01.25-.25h4.07a7.2 7.2 0 000-14.4zm0 11.52h-4.07a.25.25 0 01-.25-.25V7.2a4.32 4.32 0 114.32 4.32z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});