define("ember-svg-jar/inlined/sd-card-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sd-card-warning</title><path d=\"M14.891 11.575a2.93 2.93 0 011-1.092.25.25 0 00.114-.21V4.915a1.512 1.512 0 00-.44-1.061L12.147.44A1.491 1.491 0 0011.086 0H1.5A1.5 1.5 0 000 1.5V9a.5.5 0 00.5.5h.75a.25.25 0 01.25.25v2a.25.25 0 01-.25.25H.5a.5.5 0 00-.5.5V20a1.5 1.5 0 001.5 1.5h8.049a.25.25 0 00.237-.171.791.791 0 01.05-.121zM8.251 3.5v4a.75.75 0 01-1.5 0v-4a.75.75 0 011.5 0zm-3.5 0v4a.75.75 0 01-1.5 0v-4a.75.75 0 011.5 0zm7 4a.75.75 0 11-1.5 0v-4a.75.75 0 111.5 0zM18.782 12.272a1.451 1.451 0 00-2.563 0l-5.055 9.634a1.431 1.431 0 00.048 1.408 1.456 1.456 0 001.233.686h10.111a1.459 1.459 0 001.234-.687 1.434 1.434 0 00.047-1.408zM17.5 15.251a.75.75 0 01.75.75v3a.75.75 0 01-1.5 0V16a.75.75 0 01.75-.749zm0 7a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});