define("ember-svg-jar/inlined/single-woman-actions-flash.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-flash</title><path d=\"M12.09 17.5a.251.251 0 00.235-.164 2.144 2.144 0 01.368-.659l3.166-3.88a.249.249 0 00.049-.221.252.252 0 00-.151-.17c-.788-.309-1.683-.628-2.643-.98a.779.779 0 01-.513-.73v-.467a.25.25 0 01.25-.25 6.374 6.374 0 003.786-.9.388.388 0 00.084-.593c-.494-.537-.835-1.185-.981-3.317C15.481 1.346 12.879 0 10.643 0S5.8 1.346 5.543 5.171C5.4 7.3 5.056 7.951 4.562 8.488a.388.388 0 00.084.593 6.348 6.348 0 003.782.9.249.249 0 01.253.25v.462a.778.778 0 01-.512.73c-2.9 1.065-5.212 1.825-5.764 2.922a7.822 7.822 0 00-.76 2.731.386.386 0 00.1.3.4.4 0 00.29.126zM21.536 16H20.25a.25.25 0 01-.25-.25V11.5a.5.5 0 00-.887-.316l-5.273 6.461A.821.821 0 0014.464 19h1.286a.25.25 0 01.25.25v4.25a.5.5 0 00.332.471.516.516 0 00.168.029.5.5 0 00.388-.184l5.272-6.461A.821.821 0 0021.536 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});