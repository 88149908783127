define("ember-svg-jar/inlined/lab-flame-bottle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lab-flame-bottle</title><path d=\"M20.125 10h-2a2 2 0 00-1.96 1.6.5.5 0 01-.489.4h-1.051a.5.5 0 01-.5-.5v-2a1 1 0 00-.6-.915.251.251 0 01-.1-.373 3.971 3.971 0 00.7-2.326A6.514 6.514 0 0010 .057a.74.74 0 00-.86.22.751.751 0 00-.049.9 2.982 2.982 0 01.469 1.556.25.25 0 01-.408.2.739.739 0 00-.172-.1.757.757 0 00-.625 0 3.306 3.306 0 00-2.23 3.051 4.284 4.284 0 00.6 2.283.251.251 0 01.021.22.249.249 0 01-.162.151 1.006 1.006 0 00-.708.959v1.75a2 2 0 00.8 1.6.5.5 0 01.2.4v1.572a.5.5 0 01-.171.377l-2.548 2.229A3.751 3.751 0 006.625 24h6.75a3.751 3.751 0 002.469-6.574L13.3 15.2a.5.5 0 01-.17-.376V14.5a.5.5 0 01.5-.5h2.05a.5.5 0 01.489.4 2 2 0 001.96 1.6h2a1 1 0 001-1v-4a1 1 0 00-1.004-1zm-5.6 8.931a1.7 1.7 0 01.349.418.5.5 0 01-.27.731c-4.773 1.605-3.262-2.349-9.376.281a.25.25 0 01-.349-.248 1.748 1.748 0 01.594-1.182l3.061-2.676a1 1 0 00.342-.753V12.25a1 1 0 00-.724-.961.381.381 0 01-.276-.367.422.422 0 01.424-.422h3.4a.422.422 0 01.3.124.417.417 0 01.123.3.38.38 0 01-.276.367 1 1 0 00-.724.961v3.253a1 1 0 00.342.752zm4.6-5.431a.5.5 0 01-1 0v-1a.5.5 0 011 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});