define("ember-svg-jar/inlined/alarm-bell-sleep-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>alarm-bell-sleep-1</title><path d=\"M23 15h-1.454l2.245-2.886A1 1 0 0023 10.5h-3.5a1 1 0 000 2h1.456l-2.245 2.886A1 1 0 0019.5 17H23a1 1 0 000-2zM15.5 2h2.39l-3.163 3.868A1 1 0 0015.5 7.5H20a1 1 0 000-2h-2.39l3.164-3.867A1 1 0 0020 0h-4.5a1 1 0 000 2zM9.5 21.5h-3a.5.5 0 00-.5.5 2 2 0 004 0 .5.5 0 00-.5-.5zM15 18.5a1 1 0 01-1-1V13c0-3.451-2.167-6.317-5-6.874V4.5a1 1 0 10-2 0v1.627C4.168 6.684 2 9.55 2 13v4.5a1 1 0 01-1 1 1 1 0 000 2h14a1 1 0 100-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});