define("ember-svg-jar/inlined/key-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>key-lock</title><path d=\"M22 6.5h-.5V5a5 5 0 00-10 0v1.5a2 2 0 00-2 2v4.089a.25.25 0 00.1.2 7.089 7.089 0 012.187 2.54.251.251 0 00.223.137h5.919a3.012 3.012 0 013.049 2.771.249.249 0 00.249.232H22a2 2 0 002-2V8.5a2 2 0 00-2-2zM13.5 5a3 3 0 016 0v1.25a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25zm4 7.263v1.208a.75.75 0 01-1.5 0v-1.208a1.5 1.5 0 111.5 0z\"/><path d=\"M19.5 18.406a1.547 1.547 0 00-1.566-1.438h-7.1a5.541 5.541 0 00-5.3-4.018c-.169 0-.341.008-.513.024A5.6 5.6 0 000 18.46 5.525 5.525 0 005.524 24h.026a5.5 5.5 0 005.293-4.013h.51a.248.248 0 01.219.127l.935 1.659a.5.5 0 00.859.021l1.061-1.694a.248.248 0 01.209-.116h.217a.254.254 0 01.22.126l.945 1.653a.5.5 0 00.858.017L18 19.982a1.507 1.507 0 001.5-1.576zm-15 1.319a1.25 1.25 0 111.25-1.25 1.25 1.25 0 01-1.25 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});