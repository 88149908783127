define("ember-svg-jar/inlined/style-three-pin-anchor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-anchor</title><circle cx=\"12\" cy=\"4.5\" r=\"1\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.749 7.3 12.286 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.245 22.5 9.5 22.5 4.262 17.79 0 12 0zm0 18.5A7.508 7.508 0 014.5 11a1 1 0 011-1H7a1 1 0 010 2 .326.326 0 00-.316.411 5.52 5.52 0 003.666 3.835.5.5 0 00.65-.477V11a.5.5 0 00-.5-.5H10a1 1 0 010-2h.5A.5.5 0 0011 8v-.351a.5.5 0 00-.285-.452 3 3 0 112.57 0 .5.5 0 00-.285.452V8a.5.5 0 00.5.5h.5a1 1 0 010 2h-.5a.5.5 0 00-.5.5v4.769a.5.5 0 00.651.477 5.52 5.52 0 003.665-3.835A.326.326 0 0017 12a1 1 0 010-2h1.5a1 1 0 011 1 7.508 7.508 0 01-7.5 7.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});