define("ember-svg-jar/inlined/single-neutral-actions-credit-card.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-credit-card</title><path d=\"M9 15.25a3.236 3.236 0 011.819-2.906.5.5 0 00.025-.885 7.432 7.432 0 00-3.638-.959 7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h8A.5.5 0 009 16z\"/><circle cx=\"7\" cy=\"4.75\" r=\"4.75\"/><path d=\"M24 15.25a1.752 1.752 0 00-1.75-1.75h-10a1.752 1.752 0 00-1.75 1.75v7A1.752 1.752 0 0012.25 24h10A1.752 1.752 0 0024 22.25zm-1.75 7.25h-10a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25h10a.25.25 0 01.25.25v3a.25.25 0 01-.25.25zM12 15.25a.25.25 0 01.25-.25h10a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-10a.25.25 0 01-.25-.25z\"/><path d=\"M20.25 20H19a.75.75 0 000 1.5h1.25a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});