define("ember-svg-jar/inlined/gesture-swipe-vertical-down-two-fingers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-vertical-down-two-fingers</title><path d=\"M20.1 23.373a.751.751 0 00.212-1.411l-.97-.485a.249.249 0 01-.118-.321 24.015 24.015 0 001.753-9.283A20.159 20.159 0 0017.934.565a1 1 0 10-1.658 1.118 18.192 18.192 0 012.7 10.19 22.088 22.088 0 01-1.531 8.357.246.246 0 01-.143.138.249.249 0 01-.2-.01l-.7-.352a.751.751 0 00-1.032.949l.978 2.446a.753.753 0 00.819.461z\"/><path d=\"M16.061 15.372a3 3 0 00-3-3H3.449a.429.429 0 00-.429.428v5.143a.429.429 0 00.429.428h9.612a3 3 0 003-2.999zm-3 1.5h-1a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h1a1.5 1.5 0 010 3zM3.02 10.944a.429.429 0 00.429.428h9.571a3 3 0 100-6H3.449a.429.429 0 00-.429.428zm8.5-3.572a.5.5 0 01.5-.5h1a1.5 1.5 0 010 3h-1a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});