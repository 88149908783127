define("ember-svg-jar/inlined/wifi-transfer-hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-transfer-hand</title><path d=\"M5.432 2.431a1 1 0 00-1.414 0 3.479 3.479 0 000 4.913 1 1 0 101.414-1.412 1.478 1.478 0 010-2.087 1 1 0 000-1.414z\"/><path d=\"M2 4.888a4.466 4.466 0 011.318-3.181A1 1 0 001.9.293a6.5 6.5 0 000 9.191A1 1 0 103.318 8.07 4.47 4.47 0 012 4.888zM18.457 7.345a1 1 0 001.414 0 3.472 3.472 0 000-4.914 1 1 0 00-1.414 1.415 1.471 1.471 0 010 2.085 1 1 0 000 1.414z\"/><path d=\"M22 4.888a4.47 4.47 0 01-1.318 3.182A1 1 0 1022.1 9.484a6.5 6.5 0 000-9.191 1 1 0 00-1.414 1.414A4.466 4.466 0 0122 4.888zM21.75 18.48v-4.806c0-1.6-1.319-2.556-2.717-3.564-.5-.363-1.025-.739-1.533-1.17V6.5A2.5 2.5 0 0015 4H9a2.5 2.5 0 00-2.5 2.5v15A2.5 2.5 0 009 24h6a2.5 2.5 0 002.327-1.588 11.077 11.077 0 001.14 1.367.75.75 0 001.067-1.054 9.511 9.511 0 01-.708-.813 3.6 3.6 0 01-.852-1.595v-1.826a.747.747 0 00-.178-.484l-3.3-3.9a.506.506 0 01.716-.715l2.194 2.195a.75.75 0 001.061-1.061l-.968-.967v-2.71c.226.168.447.327.656.478 1.3.936 2.094 1.55 2.094 2.347v5.046a.75.75 0 00.14.436l1.983 2.777a.758.758 0 001.046.174.749.749 0 00.175-1.045zm-8.313-6.145a2 2 0 00-.042 2.792L16 18.206v.294a1 1 0 01-1 1H9a1 1 0 01-1-1V7a1 1 0 011-1h6a1 1 0 011 1v5.109a2.008 2.008 0 00-2.563.226z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});