define("ember-svg-jar/inlined/renewable-energy-battery-leaf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>renewable-energy-battery-leaf</title><path d=\"M11.868 18.933a.5.5 0 00.154-.494 3.993 3.993 0 01-.087-1.506 4.529 4.529 0 011.707-2.9 1 1 0 00.358-.77V5.5A2.5 2.5 0 0011.5 3h-1a.5.5 0 01-.5-.5v-1A1.5 1.5 0 008.5 0h-3A1.5 1.5 0 004 1.5v1a.5.5 0 01-.5.5h-1A2.5 2.5 0 000 5.5v16A2.5 2.5 0 002.5 24h5.807a.344.344 0 00.341-.4 2.24 2.24 0 01.405-1.676 20.632 20.632 0 012.815-2.991zm-4.974-1.825A.5.5 0 016 16.8v-2.55a.5.5 0 00-.5-.5H4a.5.5 0 01-.394-.808l3.5-4.5A.5.5 0 018 8.75v2.5a.5.5 0 00.5.5h1.561a.5.5 0 01.394.808zM23.96 14.071a.5.5 0 00-.664-.407 8.88 8.88 0 01-3.733.3 6.656 6.656 0 00-3.917.516 3.8 3.8 0 00-2.225 2.652 2.8 2.8 0 00.329 1.663.5.5 0 01-.127.615 20.449 20.449 0 00-3.358 3.4.75.75 0 001.212.884 24.271 24.271 0 017.034-5.809.75.75 0 11.724 1.313 23.375 23.375 0 00-3.615 2.468.5.5 0 00-.049.723 2.267 2.267 0 00.586.46 2.5 2.5 0 001.14.294 5.408 5.408 0 002.72-.8c2.038-1.132 4.315-5.343 3.943-8.272z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});