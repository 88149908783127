define("ember-svg-jar/inlined/cat-bed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cat-bed</title><path d=\"M22.062 7.678a4.071 4.071 0 00.767-.6c.908-.908 1.137-3.659 1.169-5.808a1 1 0 00-.3-.723.934.934 0 00-.725-.291C20.8.3 18.012.531 17.146 1.4a3.493 3.493 0 00-.81 1.206 11.447 11.447 0 00-8.672 0 3.493 3.493 0 00-.81-1.206C5.988.531 3.205.3 1.023.255A.99.99 0 00.3.544a1 1 0 00-.3.726c.032 2.148.261 4.9 1.17 5.807a4.033 4.033 0 00.766.6A11.443 11.443 0 00.5 13.249v8.5a2 2 0 002 2h19a2 2 0 002-2v-8.5a11.443 11.443 0 00-1.442-5.57zM20 17.749a2.5 2.5 0 01-2.5 2.5h-11a2.5 2.5 0 01-2.5-2.5v-4.5a8 8 0 0116 0z\"/><path d=\"M17 18.749c.813 0 1.5-1.03 1.5-2.25v-1.286a4.156 4.156 0 00-2-.464 8.12 8.12 0 00-4.229 1.215.494.494 0 01-.542 0A8.12 8.12 0 007.5 14.749a6.2 6.2 0 00-2 .477V16.5c0 1.22.687 2.25 1.5 2.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});