define("ember-svg-jar/inlined/mobile-shopping-cart-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mobile-shopping-cart-1</title><path d=\"M7.834 9.949l1.088 3.041a1.245 1.245 0 001.149.76h3.578a1.255 1.255 0 001.176-.823L16.7 7.756a.75.75 0 10-1.41-.512l-.306.841a.248.248 0 01-.235.165H9a1.243 1.243 0 00-1.043.562 1.228 1.228 0 00-.123 1.137z\"/><circle cx=\"10.5\" cy=\"15.25\" r=\"1\"/><circle cx=\"13.25\" cy=\"15.25\" r=\"1\"/><path d=\"M19.25 3A2.752 2.752 0 0016.5.25h-9A2.752 2.752 0 004.75 3v18a2.752 2.752 0 002.75 2.75h9A2.752 2.752 0 0019.25 21zm-1.5 2.25v12.5a1 1 0 01-1 1h-9.5a1 1 0 01-1-1V5.25a1 1 0 011-1h9.5a1 1 0 011 1zM13 21a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});