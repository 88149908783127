define("ember-svg-jar/inlined/amusement-park-ferris-wheel-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>amusement-park-ferris-wheel-person</title><path d=\"M3.5 13A3.5 3.5 0 000 16.5v2a.5.5 0 00.5.5h.594a.5.5 0 01.5.451L2 23.55a.5.5 0 00.5.45h2a.5.5 0 00.5-.45l.41-4.1a.5.5 0 01.5-.451h.59a.5.5 0 00.5-.5v-2A3.5 3.5 0 003.5 13z\"/><circle cx=\"3.5\" cy=\"10\" r=\"2.5\"/><path d=\"M22.438 9.183a.5.5 0 01-.294-.331 9.91 9.91 0 00-.954-2.295.5.5 0 01-.026-.444 2.5 2.5 0 00-3.277-3.277.506.506 0 01-.444-.026 9.91 9.91 0 00-2.3-.954.5.5 0 01-.331-.294 2.5 2.5 0 00-4.633 0 .5.5 0 01-.332.294 9.948 9.948 0 00-4.418 2.573 1 1 0 101.414 1.414A7.979 7.979 0 0110 3.9a.5.5 0 01.546.163 2.5 2.5 0 003.905 0A.5.5 0 0115 3.9a8.1 8.1 0 011.111.462.5.5 0 01.271.5 2.5 2.5 0 002.759 2.76.5.5 0 01.5.27A8.1 8.1 0 0120.1 9a.5.5 0 01-.163.546 2.5 2.5 0 000 3.905.5.5 0 01.164.546 7.945 7.945 0 01-2.07 3.278 1 1 0 101.384 1.444 9.915 9.915 0 002.729-4.575.5.5 0 01.294-.332 2.5 2.5 0 00-.005-4.632z\"/><path d=\"M22.5 23a1 1 0 00-1-1h-3a.5.5 0 01-.461-.306L14.4 13.039a.5.5 0 01.067-.5 2.5 2.5 0 10-3.94 0 .5.5 0 01.067.5l-1.516 3.618a1 1 0 001.844.774l1.387-3.3a.207.207 0 01.382 0l3.014 7.179a.5.5 0 01-.462.694H8a1 1 0 000 2h13.5a1 1 0 001-1.004z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});