define("ember-svg-jar/inlined/messages-bubble-warning-triangle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-warning-triangle-1</title><path d=\"M14.891 11.574a2.949 2.949 0 015.218 0l.912 1.738a.25.25 0 00.407.052A7.994 7.994 0 0015.5 0H8a7.992 7.992 0 00-3 15.4v4.1a.5.5 0 00.853.354L9.708 16h2.71a.25.25 0 00.221-.134z\"/><path d=\"M18.782 12.271a1.451 1.451 0 00-2.563 0l-5.055 9.634a1.431 1.431 0 00.048 1.408 1.456 1.456 0 001.233.687h10.111a1.459 1.459 0 001.234-.687 1.434 1.434 0 00.047-1.408zM16.751 16a.75.75 0 011.5 0v2.5a.75.75 0 01-1.5 0zm.75 6.25a1 1 0 111-1 1 1 0 01-1.001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});