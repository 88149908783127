define("ember-svg-jar/inlined/social-profile-avatar-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-profile-avatar-alternate</title><path d=\"M13.75 9a.75.75 0 00.75.75H18a.75.75 0 000-1.5h-3.5a.75.75 0 00-.75.75zM19.75 12a.75.75 0 00-.75-.75h-5.5a.75.75 0 000 1.5H19a.75.75 0 00.75-.75z\"/><path d=\"M24 11a8.009 8.009 0 00-8-8H8a7.984 7.984 0 00-6.6 12.494.5.5 0 01.055.458l-1.387 3.7A1 1 0 001 21a1.028 1.028 0 00.263-.035L8.4 19.018A.489.489 0 018.534 19H16a8.009 8.009 0 008-8zm-2 0a6.006 6.006 0 01-6 6H8.333a1.01 1.01 0 00-.263.035L3.1 18.392a.25.25 0 01-.3-.329l.819-2.184a1 1 0 00-.175-1A5.986 5.986 0 018 5h8a6.006 6.006 0 016 6z\"/><path d=\"M8.237 11.065a2.033 2.033 0 10-2.032-2.032 2.033 2.033 0 002.032 2.032zM8.237 11.805a3.32 3.32 0 00-3.158 2.3.218.218 0 00.208.286h5.858a.25.25 0 00.236-.331 3.321 3.321 0 00-3.144-2.255z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});