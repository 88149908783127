define("ember-svg-jar/inlined/e-commerce-touch-buy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-touch-buy</title><path d=\"M8.055 5.107a.749.749 0 00-1.029.257L5.667 7.629a.249.249 0 01-.214.121H5.17a.5.5 0 00-.479.644l1.5 5a.5.5 0 00.479.356h5a.5.5 0 00.474-.342l1.667-5a.5.5 0 00-.474-.658h-.451a.249.249 0 01-.214-.121l-1.359-2.265a.75.75 0 00-1.287.772l.741 1.235a.25.25 0 01-.214.379H7.786a.25.25 0 01-.214-.379l.741-1.235a.75.75 0 00-.258-1.029zM23.64 18.174a1.285 1.285 0 00-.893-1.522l-3.787-1.1a.25.25 0 01-.18-.24V10.75a1 1 0 00-2 0v7.061a.25.25 0 01-.456.142c-1.482-2.138-3.808-1.465-2.714.205l2.99 4.481a.253.253 0 00.208.111h5.778a.25.25 0 00.246-.2l.727-4z\"/><path d=\"M12.826 20.25a1 1 0 00-1-1h-.25a.25.25 0 01-.25-.25v-.525a.249.249 0 01.2-.245 1 1 0 00-.2-1.98h-8.5a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h18a.5.5 0 01.5.5v10.5a1 1 0 002 0v-11a2 2 0 00-2-2h-19a2 2 0 00-2 2v13a2 2 0 002 2h6.75a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-2.25a1 1 0 000 2h5a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});