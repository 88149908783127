define("ember-svg-jar/inlined/smart-watch-circle-ringing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-ringing</title><path d=\"M12 3.5a8.5 8.5 0 108.5 8.5A8.5 8.5 0 0012 3.5zm2.129 6.336l.625-1.363a.5.5 0 11.908.417l-.625 1.363a.5.5 0 11-.908-.417zM11.583 8a.5.5 0 111 0v1.5a.5.5 0 01-1 0zm-2.833.227a.5.5 0 01.662.246l.625 1.363a.5.5 0 11-.908.417L8.5 8.89a.5.5 0 01.25-.663zM17.085 14a1.579 1.579 0 01-1.5 1.5h-1.03a1.589 1.589 0 01-1.472-1.5v-1.73a.25.25 0 00-.235-.25 16.61 16.61 0 00-1.53 0 .25.25 0 00-.235.25V14a1.579 1.579 0 01-1.5 1.5h-1a1.58 1.58 0 01-1.5-1.5v-1c0-.723.686-2 5-2 .629 0 5 .058 5 2zM7.337 2.922a.124.124 0 00.177.143 9.98 9.98 0 018.972 0 .125.125 0 00.177-.144l-.68-2.55A.5.5 0 0015.5 0h-7a.5.5 0 00-.483.371z\"/><path d=\"M16.663 21.078a.125.125 0 00-.177-.144 9.972 9.972 0 01-8.972 0 .125.125 0 00-.177.144l.68 2.551A.5.5 0 008.5 24h7a.5.5 0 00.483-.371z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});