define("ember-svg-jar/inlined/performance-money-decrease", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>performance-money-decrease</title><path d=\"M14.493 6.826a.5.5 0 01.679.05l2.917 3.134a.25.25 0 01-.006.347L16.47 11.97a.75.75 0 00.53 1.28h5.5a.75.75 0 00.75-.75V7a.75.75 0 00-1.28-.53l-1.411 1.411a.25.25 0 01-.181.073.253.253 0 01-.179-.08L16.724 4.14a2.518 2.518 0 00-3.381-.24l-2.882 2.311a.5.5 0 01-.661-.037L4.061.439a1.5 1.5 0 00-2.122 2.122l6.368 6.367a2.5 2.5 0 003.33.184zM11.5 22.5A1.5 1.5 0 0010 21H3a1.5 1.5 0 000 3h7a1.5 1.5 0 001.5-1.5zM22.5 22.5A1.5 1.5 0 0021 21h-7a1.5 1.5 0 000 3h7a1.5 1.5 0 001.5-1.5zM23.5 18a1.5 1.5 0 00-1.5-1.5h-7a1.5 1.5 0 000 3h7a1.5 1.5 0 001.5-1.5zM9 19.5a1.5 1.5 0 000-3H2a1.5 1.5 0 000 3zM10 12H3a1.5 1.5 0 000 3h7a1.5 1.5 0 000-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});