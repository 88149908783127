define("ember-svg-jar/inlined/audio-book-volume-low", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-book-volume-low</title><path d=\"M10.25 9.642h-1a1 1 0 00-1 1v2a1 1 0 001 1h1l1.688 1.351a.5.5 0 00.812-.391V8.683a.5.5 0 00-.812-.391zM14.487 13.94a.749.749 0 010-1.06 1.749 1.749 0 000-2.475.75.75 0 011.061-1.06 3.248 3.248 0 010 4.595.749.749 0 01-1.061 0z\"/><path d=\"M24 2a2.024 2.024 0 00-.615-1.445A2.031 2.031 0 0021.943 0c-4.931.185-7.813 1.656-9.338 2.815a1 1 0 01-1.21 0C9.871 1.659 6.989.188 2.057 0A2.031 2.031 0 00.615.555 2.024 2.024 0 000 2v16.216a2 2 0 001.945 2c3.235.117 7.148.929 9.223 3.4a1.082 1.082 0 001.664 0c2.075-2.467 5.988-3.279 9.223-3.4a2 2 0 001.945-2zm-2.018 15.739a.5.5 0 01-.473.5c-4.667.253-7.424 1.67-8.9 2.8a1 1 0 01-1.211 0c-1.479-1.124-4.236-2.543-8.9-2.8a.5.5 0 01-.473-.5L2 2.527a.5.5 0 01.53-.5c4.953.3 7.347 2.032 8.227 2.87.5.476.612.889 1.242.889s.743-.413 1.242-.889c.88-.838 3.274-2.574 8.227-2.87a.5.5 0 01.53.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});