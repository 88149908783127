define("ember-svg-jar/inlined/diagram-all-direction-expand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-all-direction-expand</title><path d=\"M23.56 13.062a1.5 1.5 0 000-2.124l-3-3A1.5 1.5 0 0018 9v1.25a.25.25 0 01-.25.25h-4a.25.25 0 01-.25-.25v-4a.25.25 0 01.25-.25H15a1.5 1.5 0 001.061-2.561l-3-3a1.5 1.5 0 00-2.124 0l-3 3A1.5 1.5 0 009 6h1.25a.25.25 0 01.25.25v4a.25.25 0 01-.25.25h-4a.25.25 0 01-.25-.25V9a1.5 1.5 0 00-2.561-1.061l-3 3a1.5 1.5 0 000 2.124l3 3A1.5 1.5 0 006 15v-1.25a.25.25 0 01.25-.25h4a.25.25 0 01.25.25v4a.25.25 0 01-.25.25H9a1.5 1.5 0 00-1.061 2.561l3 3a1.5 1.5 0 002.124 0l3-3A1.5 1.5 0 0015 18h-1.25a.25.25 0 01-.25-.25v-4a.25.25 0 01.25-.25h4a.25.25 0 01.25.25V15a1.5 1.5 0 002.561 1.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});