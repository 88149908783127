define("ember-svg-jar/inlined/footwear-sneakers-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>footwear-sneakers-1</title><path d=\"M24 3a1 1 0 00-1-1 .5.5 0 01-.5-.5V1a1 1 0 00-2 0v.5a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v.5a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v.5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v.5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v1.266a.5.5 0 01-.166.372A2.492 2.492 0 000 4.5v.3a1.5 1.5 0 001 1.408l5.444 1.946a.5.5 0 00.528-.125L8.459 6.48a.75.75 0 111.082 1.04l-.977 1.017a.252.252 0 00-.063.23.25.25 0 00.16.178l1.052.377a.5.5 0 00.522-.118L11.97 7.47a.75.75 0 011.06 1.06l-1.176 1.177a.25.25 0 00.092.412l1.844.658a1.032 1.032 0 001.254-.435A3.467 3.467 0 0118 9a3.922 3.922 0 012.983 1.438 1.006 1.006 0 00.9.562h.617A1.5 1.5 0 0024 9.5zM0 21a1 1 0 001 1 .5.5 0 01.5.5v.5a1 1 0 002 0v-.5A.5.5 0 014 22h1.5a.5.5 0 01.5.5v.5a1 1 0 002 0v-.5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v.5a1 1 0 002 0v-.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v.5a1 1 0 002 0v-.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v.5a1 1 0 002 0v-1.266a.5.5 0 01.166-.372A2.492 2.492 0 0024 19.5v-.3a1.5 1.5 0 00-1-1.413l-5.448-1.946a.5.5 0 00-.528.125l-1.483 1.554a.75.75 0 11-1.082-1.04l.977-1.017a.252.252 0 00.063-.23.25.25 0 00-.16-.178l-1.052-.377a.5.5 0 00-.522.118L12.03 16.53a.75.75 0 01-1.06-1.06l1.176-1.177a.25.25 0 00-.092-.412l-1.844-.658a1.032 1.032 0 00-1.254.435A3.467 3.467 0 016 15a3.922 3.922 0 01-2.983-1.438 1.006 1.006 0 00-.9-.562H1.5A1.5 1.5 0 000 14.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});