define("ember-svg-jar/inlined/technology-ati", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>technology-ati</title><path d=\"M18 6.82h-4a1.5 1.5 0 100 3h.5v6.5a1.5 1.5 0 003 0v-6.5h.5a1.5 1.5 0 100-3zM22 6.82a1.5 1.5 0 00-1.5 1.5v8a1.5 1.5 0 003 0v-8a1.5 1.5 0 00-1.5-1.5zM11.21 6.22a.49.49 0 00-.54.08l-9.56 8.37a1.8 1.8 0 00-.1 2.6 1.79 1.79 0 002.5.09l5-4.43v3.39a1.5 1.5 0 003 0V6.68a.5.5 0 00-.3-.46z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});