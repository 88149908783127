define("ember-svg-jar/inlined/baby-care-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baby-care-body</title><path d=\"M23.028 2.13L18.8.111a1.22 1.22 0 00-1.475.364A6.5 6.5 0 0112 2.94 6.5 6.5 0 016.678.476 1.224 1.224 0 005.19.117L.972 2.13A1.724 1.724 0 00.115 4.3l1.1 2.839a1.708 1.708 0 001.843 1.073l1.269-.18a.5.5 0 01.57.495v8.884A1.222 1.222 0 006 18.63a3.659 3.659 0 013.306 3.656A1.716 1.716 0 0011.02 24h1.96a1.716 1.716 0 001.714-1.714A3.659 3.659 0 0118 18.63a1.222 1.222 0 001.1-1.219V8.527a.5.5 0 01.57-.495l1.269.18a1.715 1.715 0 001.84-1.076l1.1-2.838a1.724 1.724 0 00-.851-2.168zm-7.619 9.84L12.505 15a.7.7 0 01-1.01 0l-2.9-3.029a2.017 2.017 0 112.852-2.852.789.789 0 001.114 0 2.017 2.017 0 112.852 2.852z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});