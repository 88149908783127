define("ember-svg-jar/inlined/car-dashboard-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-dashboard-warning</title><path d=\"M20.257 11.846a8.29 8.29 0 00-2.448-5.79 8.017 8.017 0 00-5.822-2.324 8.4 8.4 0 00-5.856 2.514 8.123 8.123 0 00-2.388 5.888 8.106 8.106 0 008.121 8.115h.149a8.334 8.334 0 008.244-8.403zM11 7.988a1 1 0 112 0v4.5a1 1 0 01-2 0zm1.022 9.023H12a1.249 1.249 0 01-.021-2.5H12a1.249 1.249 0 01.02 2.5z\"/><path d=\"M5.18 19.3A10.037 10.037 0 012 11.989a9.909 9.909 0 013.141-7.276 1 1 0 10-1.372-1.455A11.884 11.884 0 000 11.989a12.046 12.046 0 003.816 8.777A1 1 0 005.18 19.3zM20.208 3.235a1 1 0 10-1.369 1.458A9.911 9.911 0 0122 11.989a10.038 10.038 0 01-3.182 7.316 1 1 0 001.364 1.463 12 12 0 00.026-17.533z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});