define("ember-svg-jar/inlined/shrink-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shrink-2</title><path d=\"M.616 23.383a1.251 1.251 0 001.768 0L6.5 19.267a.25.25 0 01.354 0l1.439 1.439A1 1 0 009 21a.987.987 0 00.383-.076A1 1 0 0010 20v-5a1 1 0 00-1-1H4a1 1 0 00-.707 1.707l1.439 1.439a.25.25 0 010 .354L.616 21.615a1.251 1.251 0 000 1.768zM23.384.615a1.251 1.251 0 00-1.768 0L17.5 4.731a.25.25 0 01-.354 0l-1.439-1.439A1 1 0 0014 4v5a1 1 0 001 1h5a1 1 0 00.707-1.707l-1.439-1.44a.249.249 0 010-.353l4.116-4.116a1.251 1.251 0 000-1.769z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});