define("ember-svg-jar/inlined/social-media-hi-five", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-hi-five</title><path d=\"M20.4 10a4.62 4.62 0 00-1.58.26l.08-.8h3.6A.5.5 0 0023 9l.12-2a.5.5 0 00-.5-.53H16.5a.5.5 0 00-.49.42l-.79 5a.5.5 0 00.37.56l1.64.43a.5.5 0 00.62-.39 1.45 1.45 0 011.15-.12c.41.14.62.5.62 1.11a1.07 1.07 0 01-.9 1.11 3.39 3.39 0 01-.87-.06.69.69 0 010-.28 1.24 1.24 0 00-.29-.93 1.12 1.12 0 00-.84-.31h-.18c-.64 0-1.36 0-1.55 1.25a2.53 2.53 0 00.63 2 5.18 5.18 0 003.52 1.22c2.76 0 4.61-1.66 4.61-4.12A3.3 3.3 0 0020.4 10z\"/><rect x=\"10.55\" y=\"7\" width=\"3\" height=\"2\" rx=\".5\" ry=\".5\"/><path d=\"M14 14.5h-.4V10a.5.5 0 00-.5-.5H9.55a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h.53v1h-.53a.5.5 0 00-.5.5v2a.5.5 0 00.5.5H14a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5zM6.48 10.47a2.29 2.29 0 00-1.28.38V8a.5.5 0 00-.5-.5h-4a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h.5v4H.7a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-2.5a.5.5 0 011 0V17a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-4.22c0-2.14-1.31-2.31-1.72-2.31z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});