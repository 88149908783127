define("ember-svg-jar/inlined/online-reading-readernaut", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>online-reading-readernaut</title><path d=\"M23.49 15.66c-.33 0-8 .26-11.49 4.08C8.54 15.92.84 15.67.51 15.66c-.06 0-.13 0-.18.07a.25.25 0 00-.08.18v3a.25.25 0 00.25.25 15.94 15.94 0 0111.31 4.58.26.26 0 00.19.08h.09a.26.26 0 00.1-.07 16 16 0 0111.31-4.54.25.25 0 00.25-.25v-3.05a.25.25 0 00-.08-.18.2.2 0 00-.18-.07z\"/><path d=\"M6 15.13a8.09 8.09 0 015.58 2.61.5.5 0 00.38.18.5.5 0 00.37-.17A9.09 9.09 0 0118 15.1h.06a.5.5 0 00.39-.3 7 7 0 10-12.91 0 .59.59 0 00.46.33z\"/><path d=\"M22.5 8.31V4.05a2 2 0 10-1 0v4a.49.49 0 00-.29.1 10 10 0 00-18.42 0 .49.49 0 00-.29-.1A2.5 2.5 0 000 10.59v1a2.5 2.5 0 002.5 2.5.5.5 0 00.5-.5v-1.5a9 9 0 0118 0v1.5a.5.5 0 00.5.5 2.5 2.5 0 002.5-2.5v-1a2.5 2.5 0 00-1.5-2.28z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});