define("ember-svg-jar/inlined/presentation-microphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>presentation-microphone</title><path d=\"M14.493 6a.25.25 0 00.25-.25V3.5a3.5 3.5 0 00-7 0L7.748 6z\"/><path d=\"M21.187 9.06a1.5 1.5 0 002.123-2.121l-2.423-2.42a.253.253 0 01-.058-.264 1.5 1.5 0 00-.35-1.559l-.708-.707a2.5 2.5 0 00-3.537 3.535l.707.707a1.5 1.5 0 001.56.351.25.25 0 01.264.057l.382.382a.248.248 0 01.038.3l-2.73 4.553a.251.251 0 01-.214.122H12.5a.25.25 0 01-.25-.25v-1.732a.248.248 0 01.165-.235 3.49 3.49 0 002.228-2.512A.219.219 0 0014.43 7H8.092a.249.249 0 00-.243.308 3.486 3.486 0 002.234 2.477.25.25 0 01.167.236v1.73A.25.25 0 0110 12H5.235a.252.252 0 01-.213-.118l-1.79-2.874a1 1 0 01-.01-1.045l.432-.719a.25.25 0 01.336-.09l.124.069a.5.5 0 00.68-.193L6.5 3.973A2 2 0 103 2.027L1.3 5.085a.5.5 0 00.193.68l.313.174a.254.254 0 01.12.154.251.251 0 01-.028.193l-.389.649a2.987 2.987 0 00.029 3.134l.969 1.549a.25.25 0 01-.214.382H1.75a1.5 1.5 0 00-1.375 2.1l2.14 4.74a1.5 1.5 0 001.38.911H8.5a.249.249 0 01.25.25v3a1 1 0 002 0V18.25a1 1 0 012 0V23a1 1 0 002 0v-3a.249.249 0 01.25-.25h4.587a1.5 1.5 0 001.375-.9l2.148-4.758A1.5 1.5 0 0021.731 12h-2.578a.253.253 0 01-.218-.127.25.25 0 010-.252l1.64-2.732a.246.246 0 01.183-.119.251.251 0 01.208.071z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});