define("ember-svg-jar/inlined/historical-building-castle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>historical-building-castle-1</title><path d=\"M12.75 12h-2a.5.5 0 00-.5.5v1.25A.25.25 0 0110 14H8.5a.25.25 0 01-.25-.25V12.5a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v1.25A.25.25 0 015 14H3.5a.25.25 0 01-.25-.25V12.5a.5.5 0 00-.342-.475.264.264 0 01-.158-.259V8.75A.25.25 0 013 8.5h1.527a.263.263 0 01.248.222 1 1 0 00.975.778h3.5a.5.5 0 00.416-.777L8.851 7.5l.815-1.223A.5.5 0 009.25 5.5H5.973a.263.263 0 01-.248-.222A1 1 0 004.75 4.5h-3.5a.5.5 0 00-.5.5v6.766a.264.264 0 01-.158.259.5.5 0 00-.342.475v11a.5.5 0 00.5.5H4.5a.25.25 0 00.25-.25v-2.143a2.076 2.076 0 011.664-2.08A2 2 0 018.75 21.5v2.25A.25.25 0 009 24h3.75a.5.5 0 00.5-.5v-11a.5.5 0 00-.5-.5zM17.171 8.25h6.158a.249.249 0 00.234-.338L20.718.324a.5.5 0 00-.936 0l-2.845 7.588a.249.249 0 00.234.338zM23.5 9.75H17a.25.25 0 00-.25.25v3.25a.25.25 0 01-.25.25h-1.25a.5.5 0 00-.5.5v9.5a.5.5 0 00.5.5h8a.5.5 0 00.5-.5V10a.25.25 0 00-.25-.25zm-3.25 4.75a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});