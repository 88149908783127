define("ember-svg-jar/inlined/style-one-pin-car", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-car</title><path d=\"M12 0a9.5 9.5 0 00-4 18.119 2.509 2.509 0 011.218 1.222l1.872 4.076a1 1 0 001.817 0l1.876-4.08A2.5 2.5 0 0116 18.118 9.5 9.5 0 0012 0zM8 7a2.5 2.5 0 012.5-2.5h3A2.5 2.5 0 0116 7a.5.5 0 01-.5.5h-7A.5.5 0 018 7zm8.61 5.152a.252.252 0 00-.11.208v.89a.75.75 0 01-1.5 0v-.5a.25.25 0 00-.25-.25h-5.5a.25.25 0 00-.25.25v.5a.75.75 0 01-1.5 0v-.89a.253.253 0 00-.111-.208 2.014 2.014 0 01-.688-.793.25.25 0 01.225-.359H8.5a.5.5 0 000-1H6.927a.25.25 0 01-.227-.357A1.994 1.994 0 018.5 8.5h7a2 2 0 011.8 1.142.251.251 0 01-.226.358H15.5a.5.5 0 000 1h1.574a.25.25 0 01.225.359 2.009 2.009 0 01-.689.793z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});