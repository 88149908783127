define("ember-svg-jar/inlined/flash-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flash-1</title><path d=\"M20.444 9.27A.5.5 0 0020 9h-7.376L14.488.606a.5.5 0 00-.895-.4l-10 14A.5.5 0 004 15h7.376l-1.864 8.39a.5.5 0 00.895.4l10-14a.5.5 0 00.037-.52z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});