define("ember-svg-jar/inlined/dog-mouth-protection-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dog-mouth-protection-1</title><path d=\"M23.764 6.9l-.791-.923a7.2 7.2 0 00-4.59-2.231c-.348-.008-.925.026-1.246.047a.25.25 0 00-.233.25v5.098a1.128 1.128 0 001.211 1.212h1.472A1.129 1.129 0 0020.8 9.141a.75.75 0 011.5 0 2.631 2.631 0 01-2.658 2.711h-1.527A2.635 2.635 0 0115.4 9.141s.005-3.608.008-4.727a.251.251 0 00-.318-.241 11.31 11.31 0 00-4.258 2.283.975.975 0 00-.232 1.162l4.5 9.518a.98.98 0 00.734.546 18.4 18.4 0 015.242 1.656l1.475.795A.981.981 0 0024 19.269V7.542a.983.983 0 00-.236-.642zM13.211 9.877a.736.736 0 11.736-.736.736.736 0 01-.736.736zM9.2 7.717a1 1 0 00-1.04-.563l-5.637.756a2.948 2.948 0 00-2.515 3.167c.447 6.1 2.8 7.385 4.7 7.385h8a1 1 0 00.9-1.428zM2 10.932a.947.947 0 01.782-1.039l1.142-.153v1.818H2.06c-.023-.218-.042-.43-.06-.626zm3.921 5.53v-2.9h1.436v2.9zm1.436-7.183v2.279H5.924V9.471zm-3.433 4.279v2.691a4.166 4.166 0 01-1.534-2.691zm5.433 2.9v-3.733l1.77 3.737z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});