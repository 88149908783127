define("ember-svg-jar/inlined/diagram-fall-fast-dash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-fall-fast-dash</title><path d=\"M3.045 7.6A1.44 1.44 0 00.366 8.655 5.3 5.3 0 001.9 10.772a1.44 1.44 0 001.841-2.211 2.4 2.4 0 01-.696-.961zM19.68 16.732a1.44 1.44 0 001.44-1.44A8.011 8.011 0 0020.933 13a1.44 1.44 0 00-2.777.761 5.911 5.911 0 01.084 1.527 1.44 1.44 0 001.44 1.444zM9.326 12a1.44 1.44 0 000-2.88h-1.92a1.44 1.44 0 000 2.88zM16.047 12.006a1.44 1.44 0 00.077-2.878c-.267-.014-.259-.005-2-.008a1.44 1.44 0 000 2.88zM1.44 4.8a1.44 1.44 0 001.44-1.44V1.44a1.44 1.44 0 00-2.88 0v1.92A1.44 1.44 0 001.44 4.8zM23.89 19.129a1.438 1.438 0 00-1.33-.889H16.8a1.44 1.44 0 00-1.018 2.46l2.88 2.88a1.439 1.439 0 002.036 0l2.88-2.88a1.439 1.439 0 00.312-1.571z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});