define("ember-svg-jar/inlined/dancing-ballet-dress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dancing-ballet-dress</title><path d=\"M23.039 18.707a13.038 13.038 0 00-5.4-4.9.5.5 0 00-.652.208c-.81 1.455-3.257 2.266-4.987 2.266-2.011 0-4.2-.5-5-2.217a.5.5 0 00-.671-.24 13.039 13.039 0 00-5.368 4.883 2.219 2.219 0 00.514 3.231 3.91 3.91 0 003.638.194.5.5 0 01.663.257A2.618 2.618 0 007.2 23.761a4.132 4.132 0 004.441-1.207.5.5 0 01.712 0 4.138 4.138 0 004.447 1.207 2.616 2.616 0 001.427-1.373.5.5 0 01.663-.256 3.916 3.916 0 003.638-.194 2.219 2.219 0 00.511-3.231z\"/><path d=\"M8.161 12.764a2 2 0 00.821 1.145 5.677 5.677 0 006.037 0 2 2 0 00.82-1.145l2.315-8.636C18.64 2.183 18.2.7 16.977.183c-1.2-.514-3.083.023-4.341 2.035a.779.779 0 01-1.272 0C10.106.206 8.228-.331 7.023.183 5.8.7 5.36 2.183 5.846 4.128z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});