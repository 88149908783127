define("ember-svg-jar/inlined/real-estate-neighbourhood", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-neighbourhood</title><path d=\"M6.208 12.391a1.027 1.027 0 00-1.414 0L.147 17.037a.5.5 0 00.354.854H1a.5.5 0 01.5.5v2.5a1 1 0 001 1h1a1 1 0 001-1v-2a1 1 0 012 0v2a1 1 0 001 1h1a1 1 0 001-1v-2.5a.5.5 0 01.5-.5h.5a.5.5 0 00.353-.854zM23.853 17.037l-4.647-4.646a1.027 1.027 0 00-1.414 0l-4.646 4.646a.5.5 0 00.353.854H14a.5.5 0 01.5.5v2.5a1 1 0 001 1h1a1 1 0 001-1v-2a1 1 0 012 0v2a1 1 0 001 1h1a1 1 0 001-1v-2.5a.5.5 0 01.5-.5h.5a.5.5 0 00.353-.854zM13 10.891a1 1 0 001 1h1a1 1 0 001-1v-2.5a.5.5 0 01.5-.5h.5a.5.5 0 00.354-.854l-4.647-4.646a1.029 1.029 0 00-1.414 0L6.646 7.037A.5.5 0 007 7.891h.5a.5.5 0 01.5.5v2.5a1 1 0 001 1h1a1 1 0 001-1v-2a1 1 0 112 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});