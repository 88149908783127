define("ember-svg-jar/inlined/agriculture-machine-plowing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>agriculture-machine-plowing</title><path d=\"M15 14a.5.5 0 00.5-.5v-3a1 1 0 00-2 0v3a.5.5 0 00.5.5z\"/><path d=\"M23.333 11.057a.5.5 0 01-.333-.471V1.5a1 1 0 00-1-1h-1a1 1 0 00-.821.43 1 1 0 01-.259.255L5.833 10.744A1.482 1.482 0 015 11a1 1 0 00-.764.356.407.407 0 01-.31.144H1a1 1 0 000 2h2.883a.5.5 0 01.373.166A1 1 0 005 14h3.279a.25.25 0 01.18.424C6.089 16.88 5 17.409 5 19.5a4 4 0 006.874 2.779.5.5 0 00.1-.551 5.494 5.494 0 010-4.456.5.5 0 00-.1-.551 4 4 0 00-1.954-1.114.25.25 0 01-.123-.416l.754-.786A1.314 1.314 0 0111.5 14a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5H9.832a.25.25 0 01-.141-.457l9.919-6.73a.25.25 0 01.39.207v6.48a.5.5 0 01-.5.5h-2a.5.5 0 00-.5.5v2.158a.5.5 0 01-.138.344C14.107 16.9 13 17.368 13 19.5a4 4 0 104.92-3.893.25.25 0 01-.123-.416l.847-.883a1 1 0 01.722-.308H23a1 1 0 001-1v-1a1 1 0 00-.667-.943z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});