define("ember-svg-jar/inlined/baby-care-trolley", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baby-care-trolley</title><path d=\"M.75 9.75h9A.75.75 0 0010.5 9V1.25a1.252 1.252 0 00-.391-.908A1.264 1.264 0 009.178 0 9.5 9.5 0 000 9a.75.75 0 00.75.75z\"/><circle cx=\"5.5\" cy=\"22.25\" r=\"1.75\"/><path d=\"M13.911 19.849H7.588a.25.25 0 00-.174.429 2.764 2.764 0 01.728 1.21.5.5 0 00.48.361h4.255a.5.5 0 00.48-.361 2.754 2.754 0 01.728-1.21.25.25 0 00-.174-.429zM23 2.849h-1.166a2.991 2.991 0 00-2.921 2.313l-1.224 5.2a.5.5 0 01-.487.386H.75a.749.749 0 00-.658 1.109l3 5.5a.749.749 0 00.658.391h11.571a.5.5 0 01.486.614l-.162.689A.364.364 0 0016 19.5a2.73 2.73 0 011.213.283.249.249 0 00.218 0 .245.245 0 00.136-.169l3.293-14a1 1 0 01.974-.771H23a1 1 0 000-2z\"/><circle cx=\"16\" cy=\"22.25\" r=\"1.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});