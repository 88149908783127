define("ember-svg-jar/inlined/mobile-phone-blackberry-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mobile-phone-blackberry-1</title><path d=\"M15.5 0h-7A4.505 4.505 0 004 4.5v17A2.5 2.5 0 006.5 24h11a2.5 2.5 0 002.5-2.5v-17A4.505 4.505 0 0015.5 0zm1 19a1 1 0 11-1 1 1 1 0 011-1zm-1-2.5a1 1 0 111 1 1 1 0 01-1-1zM18 12a1 1 0 01-1 1H7a1 1 0 01-1-1V6a1 1 0 011-1h10a1 1 0 011 1zm-6 5.5a1 1 0 111-1 1 1 0 01-1 1zm1 2.5a1 1 0 11-1-1 1 1 0 011 1zm-5.5-2.5a1 1 0 111-1 1 1 0 01-1 1zm1 2.5a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});