define("ember-svg-jar/inlined/feed-rss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>feed-rss</title><path d=\"M2.25 8.72a1 1 0 000 2 11 11 0 0111 11 1 1 0 002 0 13 13 0 00-13-13zM2.25 16.22a1 1 0 00-1 1v4.53a1 1 0 001 1h4.5a1 1 0 001-1 5.59 5.59 0 00-5.5-5.53z\"/><path d=\"M2.25 1.25a1 1 0 000 2 18.52 18.52 0 0118.5 18.5 1 1 0 002 0 20.52 20.52 0 00-20.5-20.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});