define("ember-svg-jar/inlined/check-circle-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.659 8.135l-7.146 6.67-2.159-2.158a.5.5 0 00-.707.707l2.5 2.5a.5.5 0 00.695.012l7.5-7a.5.5 0 00-.683-.731z\"/><path d=\"M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm0 23C5.935 23 1 18.066 1 12 1 5.935 5.935 1 12 1s11 4.935 11 11c0 6.066-4.935 11-11 11z\"/><g><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});