define("ember-svg-jar/inlined/diagram-arrow-dash-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-arrow-dash-left</title><path d=\"M.478 13.072l6.633 6.161a1 1 0 001.681-.733v-3.25a.25.25 0 01.25-.25h4.951a1 1 0 001-1v-4a1 1 0 00-1-1H9.042a.25.25 0 01-.25-.25V5.5a1 1 0 00-1.681-.732L.479 10.926a1.457 1.457 0 000 2.146z\"/><rect x=\"16.493\" y=\"9\" width=\"3\" height=\"6\" rx=\"1\" ry=\"1\"/><rect x=\"20.993\" y=\"9\" width=\"3\" height=\"6\" rx=\"1\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});