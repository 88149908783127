define("ember-svg-jar/inlined/supermarket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>supermarket</title><path d=\"M24 12.5a1.25 1.25 0 00-1.25-1.25h-5.5A.25.25 0 0117 11V9a.5.5 0 00-.5-.5h-9A.5.5 0 007 9v2a.25.25 0 01-.25.25h-5.5a1.25 1.25 0 000 2.5h.5A.25.25 0 012 14v9.75a.25.25 0 00.25.25h19.5a.25.25 0 00.25-.25V14a.25.25 0 01.25-.25h.5A1.25 1.25 0 0024 12.5zM11 22a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5v-4.5A.5.5 0 018 17h2.5a.5.5 0 01.5.5zm-1-9.5a2 2 0 112 2 2 2 0 01-2-2zm6.5 9.5a.5.5 0 01-.5.5h-2.5a.5.5 0 01-.5-.5v-4.5a.5.5 0 01.5-.5H16a.5.5 0 01.5.5zM5.5 7.5h13a1.25 1.25 0 000-2.5h-5.25a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h2a1 1 0 001-1V1a1 1 0 00-1-1H12a1 1 0 00-1 1v3.75a.25.25 0 01-.25.25H5.5a1.25 1.25 0 000 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});