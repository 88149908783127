define("ember-svg-jar/inlined/religion-woman-japan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-woman-japan</title><path d=\"M16.924 10a.249.249 0 00-.235.166 4.964 4.964 0 01-9.378 0A.249.249 0 007.076 10h-1.81a.251.251 0 00-.239.177 3.984 3.984 0 01-2.18 2.846.5.5 0 00-.327.337.5.5 0 00.1.46 3.8 3.8 0 002.921 1.119 4.6 4.6 0 002.345-.6.25.25 0 01.273 0 6.972 6.972 0 007.644.032.25.25 0 01.275 0 4.414 4.414 0 002.385.629 3.8 3.8 0 002.921-1.12.5.5 0 00-.231-.8 4.029 4.029 0 01-2.2-2.9.251.251 0 00-.24-.178z\"/><path d=\"M10.7 11.832a2.36 2.36 0 00.437.181 3 3 0 00.867.126h.006a3.02 3.02 0 00.865-.125 2.441 2.441 0 00.435-.181.639.639 0 00-.3-1.2H11a.639.639 0 00-.3 1.2zM.635 8.489A.984.984 0 001.506 9h20.988a.984.984 0 00.871-.511 1.017 1.017 0 00-.026-1.029C21.3 4.151 17.668 1.661 16.276.732A4.417 4.417 0 0013.812 0h-3.624a4.417 4.417 0 00-2.464.732C6.343 1.654 2.7 4.154.661 7.459a1.019 1.019 0 00-.026 1.03zM22.533 18.417a11.994 11.994 0 00-2.45-1.4.25.25 0 00-.32.109l-3.548 6.5a.249.249 0 00.22.37H23a.5.5 0 00.5-.5V20.4a2.463 2.463 0 00-.967-1.983zM8.052 16.057a.5.5 0 00-.467-.16 14.53 14.53 0 00-6.118 2.52A2.461 2.461 0 00.5 20.4v3.1a.5.5 0 00.5.5h7.326a.252.252 0 00.19-.087l3.066-3.56a.251.251 0 000-.33zM10.738 23.854a.251.251 0 00.227.146h3.191a.251.251 0 00.22-.13l3.924-7.192a.252.252 0 00-.15-.361c-.244-.07-.5-.136-.76-.2a.252.252 0 00-.249.08l-6.364 7.391a.25.25 0 00-.039.266z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});