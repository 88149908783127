define("ember-svg-jar/inlined/history-geisha", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-geisha</title><path d=\"M16.706 4.384a.251.251 0 00-.334.174 4.5 4.5 0 01-8.744 0 .251.251 0 00-.334-.174A11.026 11.026 0 000 14.5a10.376 10.376 0 00.26 2.29 1.507 1.507 0 001.88 1.11l1.34-.4a.25.25 0 01.312.176 8.49 8.49 0 0016.416 0 .25.25 0 01.312-.176l1.34.4a1.511 1.511 0 001.88-1.11A10.376 10.376 0 0024 14.5a11.026 11.026 0 00-7.294-10.116zM12 22.5a7.018 7.018 0 01-7-7c0-2.155 1.228-5.463 3.58-4.75a5.685 5.685 0 012.4 1.44 1.487 1.487 0 002.04 0 5.685 5.685 0 012.4-1.44c2.339-.709 3.58 2.578 3.58 4.75a7.018 7.018 0 01-7 7z\"/><path d=\"M12.293 18.24l-.293.293-.293-.293a1 1 0 00-1.414 1.414l.646.647a1.5 1.5 0 002.122 0l.646-.647a1 1 0 00-1.414-1.414zM10.53 15.77a.749.749 0 000-1.06 3.366 3.366 0 00-3.267-.682.75.75 0 00.469 1.425 1.888 1.888 0 011.738.317.749.749 0 001.06 0zM16.737 14.028a3.36 3.36 0 00-3.267.682.75.75 0 001.06 1.06 1.884 1.884 0 011.738-.317.75.75 0 00.469-1.425zM2.5 3.25h4.779a.25.25 0 00.249-.224 4.376 4.376 0 01.393-1.419.25.25 0 00-.221-.357H2.5a1 1 0 000 2zM19.93 3.25a1.059 1.059 0 00.23.19l1.48.93A.892.892 0 0023 3.61V.89a.886.886 0 00-1.36-.75l-1.48.92a1.059 1.059 0 00-.23.19h-3.625a.25.25 0 00-.226.357 4.376 4.376 0 01.393 1.419.25.25 0 00.249.224z\"/><circle cx=\"12\" cy=\"3.5\" r=\"3.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});