define("ember-svg-jar/inlined/school-teacher-correct", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>school-teacher-correct</title><path d=\"M16.344 12.317a1.5 1.5 0 001.056-.657l3.179-4.776a1 1 0 00-1.664-1.108l-2.534 3.811a.5.5 0 01-.752.094L14.42 8.588a1 1 0 10-1.34 1.484l2.07 1.871a1.5 1.5 0 001.194.374zM8.807 8.453a.25.25 0 01-.037-.418 4.5 4.5 0 10-5.54 0 .251.251 0 01-.036.418A6 6 0 000 13.75v3.5a1 1 0 001 1h1.121a.5.5 0 01.5.458L3 23.312a.749.749 0 00.75.688h4.5a.749.749 0 00.75-.688l.384-4.6a.5.5 0 01.5-.458H11a1 1 0 001-1v-3.5a6 6 0 00-3.193-5.301zM3.6 3.818a.25.25 0 01.367-.147A5.964 5.964 0 007 4.5a5.854 5.854 0 001.186-.124.25.25 0 01.214.054.247.247 0 01.087.2A2.495 2.495 0 113.6 3.818zM10 15.75a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-2a3.991 3.991 0 012.667-3.755.245.245 0 01.227.031.251.251 0 01.106.2v3.52a1 1 0 002 0V10.23a.251.251 0 01.106-.2.247.247 0 01.228-.031A3.991 3.991 0 0110 13.75z\"/><path d=\"M21 0h-8.5a1 1 0 000 2H21a1 1 0 011 1v13a1 1 0 01-1 1h-6a1 1 0 000 2h6a3 3 0 003-3V3a3 3 0 00-3-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});