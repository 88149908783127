define("ember-svg-jar/inlined/baggage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baggage</title><path d=\"M6 22.375a.5.5 0 00.5.5h11a.5.5 0 00.5-.5v-16a.5.5 0 00-.5-.5h-.25a.5.5 0 01-.5-.5v-1.25a3 3 0 00-3-3h-3.5a3 3 0 00-3 3v1.25a.5.5 0 01-.5.5H6.5a.5.5 0 00-.5.5zm3.25-18.25a1 1 0 011-1h3.5a1 1 0 011 1v1.25a.5.5 0 01-.5.5h-4.5a.5.5 0 01-.5-.5zM4 6.375a.5.5 0 00-.5-.5 3.5 3.5 0 00-3.5 3.5v10a3.5 3.5 0 003.5 3.5.5.5 0 00.5-.5zM20.5 5.875a.5.5 0 00-.5.5v16a.5.5 0 00.5.5 3.5 3.5 0 003.5-3.5v-10a3.5 3.5 0 00-3.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});