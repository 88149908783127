define("ember-svg-jar/inlined/fishing-fish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fishing-fish</title><path d=\"M20.925 12.919a.5.5 0 00.4.115 2.636 2.636 0 01.844 0 .5.5 0 00.578-.5V1a1 1 0 00-2 0v11.539a.5.5 0 00.178.38zM22.575 19.08a.5.5 0 00-.4-.114 2.636 2.636 0 01-.844 0 .5.5 0 00-.578.495V21.5a.5.5 0 11-1 0V21a1 1 0 00-2 0v.5a2.5 2.5 0 105 0v-2.039a.5.5 0 00-.178-.381zM18.343 12.837C17.32 11.683 14.58 9 11.25 9c-2.91 0-5.373 2.061-6.587 3.294a.5.5 0 01-.751-.043L2.04 9.844a1 1 0 00-1.79.613v6.086a1 1 0 00.676.945.985.985 0 00.324.055 1 1 0 00.789-.386l1.873-2.408a.5.5 0 01.751-.043C5.877 15.939 8.34 18 11.25 18c3.328 0 6.069-2.683 7.092-3.836a1 1 0 00.001-1.327zM13.25 14a1 1 0 111-1 1 1 0 01-1 1z\"/><circle cx=\"21.75\" cy=\"16\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});