define("ember-svg-jar/inlined/religion-woman-japan.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-woman-japan</title><path d=\"M10.635 12.886a3.18 3.18 0 001.5.364h.006a3.094 3.094 0 001.5-.365.75.75 0 10-.735-1.307 1.615 1.615 0 01-.763.172 1.659 1.659 0 01-.772-.174.75.75 0 00-.73 1.31z\"/><path d=\"M16.759 10a1.744 1.744 0 00-.568 1.012C15.816 13.26 13.029 14.75 12 14.75a5.37 5.37 0 01-4.191-3.738A1.744 1.744 0 007.241 10H4.927a3.688 3.688 0 01-2.08 2.531.5.5 0 00-.327.337.5.5 0 00.1.46A5.117 5.117 0 006.185 15a2.229 2.229 0 001.786-.765A6.7 6.7 0 0012 16.25a6.715 6.715 0 004.069-2.05 2.234 2.234 0 001.831.8 5.116 5.116 0 003.568-1.672.506.506 0 00.1-.46.5.5 0 00-.328-.337A3.688 3.688 0 0119.153 10zM.635 8.489A.984.984 0 001.506 9h20.988a.984.984 0 00.871-.511 1.016 1.016 0 00-.026-1.03c-.095-.153-.2-.306-.307-.459A23.956 23.956 0 0016.276.732 4.417 4.417 0 0013.812 0h-3.624a4.417 4.417 0 00-2.464.732A23.937 23.937 0 00.969 7c-.1.153-.213.306-.308.459a1.016 1.016 0 00-.026 1.03zM23.033 18.417a11.994 11.994 0 00-2.45-1.4.25.25 0 00-.32.109l-3.548 6.5a.25.25 0 00.219.37H23.5a.5.5 0 00.5-.5V20.4a2.463 2.463 0 00-.967-1.983zM11.079 20.023l-3.527-3.966a.5.5 0 00-.467-.16 14.53 14.53 0 00-6.118 2.52A2.461 2.461 0 000 20.4v3.1a.5.5 0 00.5.5h7.326a.252.252 0 00.19-.087l3.066-3.56a.251.251 0 00-.003-.33zM18.31 16.462a.252.252 0 00-.161-.145 23.11 23.11 0 00-.76-.2.252.252 0 00-.249.08l-6.364 7.391a.25.25 0 00.189.413h3.191a.251.251 0 00.22-.13l3.924-7.193a.252.252 0 00.01-.216z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});