define("ember-svg-jar/inlined/seat-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seat-settings</title><path d=\"M16.5 16.915l-8.869.828a.251.251 0 01-.2-.076.247.247 0 01-.067-.207A32.434 32.434 0 005.522 2.673a1.537 1.537 0 00-1.417-.942H1.56a1.527 1.527 0 00-1.115.48 1.556 1.556 0 00-.422 1.15L.9 21.267a2.588 2.588 0 002.577 2.464h12.592a2.679 2.679 0 002.406-1.492l.7-1.4a2.715 2.715 0 00-.249-2.851 2.684 2.684 0 00-2.426-1.073zM18.842 5.72a.249.249 0 01-.068-.224.246.246 0 01.147-.182l.82-.352a1.112 1.112 0 00-.015-2.05L13.516.356a1.111 1.111 0 00-1.452 1.45l2.556 6.21a1.106 1.106 0 001.021.689h.009a1.109 1.109 0 001.021-.673l.351-.819a.25.25 0 01.406-.079l4.845 4.844a1 1 0 101.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});