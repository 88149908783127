define("ember-svg-jar/inlined/translate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>translate</title><path d=\"M8 1a1 1 0 00-2 0v6a1 1 0 002 0zM21.5 16a2.5 2.5 0 00-2.5 2.5V23a1 1 0 002 0v-1.75a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25V23a1 1 0 002 0v-4.5a2.5 2.5 0 00-2.5-2.5zm-.25 3a.25.25 0 01-.25-.25v-.25a.5.5 0 011 0v.25a.25.25 0 01-.25.25zM10.5 16A2.5 2.5 0 008 18.5v3a2.5 2.5 0 005 0v-3a2.5 2.5 0 00-2.5-2.5zm.5 5.5a.5.5 0 01-1 0v-3a.5.5 0 011 0zM17.5 22h-1.75a.25.25 0 01-.25-.25V17a1 1 0 00-2 0v5a2 2 0 002 2h2a1 1 0 000-2zM1 8a1 1 0 001-1V5.25A.25.25 0 012.25 5h.5a.25.25 0 01.25.25V7a1 1 0 002 0V1a1 1 0 00-2 0v1.75a.25.25 0 01-.25.25h-.5A.25.25 0 012 2.75V1a1 1 0 00-2 0v6a1 1 0 001 1zM6.5 16a1 1 0 00-1 1v1.75a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25V17a1 1 0 00-2 0v6a1 1 0 002 0v-1.75a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25V23a1 1 0 002 0v-6a1 1 0 00-1-1zM2.846 15.014a1 1 0 101.73-1 12.3 12.3 0 01-.915-1.67.249.249 0 01.027-.232.253.253 0 01.206-.112H4.5a.5.5 0 00.354-.854l-2-2a.5.5 0 00-.708 0l-2 2A.5.5 0 00.5 12h.8a.251.251 0 01.243.191 11.089 11.089 0 001.303 2.823zM10.5 2.215a1 1 0 001 1 8.065 8.065 0 017.151 4.422.25.25 0 01-.222.363H17.5a.5.5 0 00-.354.853l3 3A.5.5 0 0020.5 12h.022a.5.5 0 00.362-.179l2.5-3A.5.5 0 0023 8h-1.855a.25.25 0 01-.235-.165 10.083 10.083 0 00-9.41-6.62 1 1 0 00-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});