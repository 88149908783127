define("ember-svg-jar/inlined/file-php-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-php-1</title><path d=\"M15 12.126h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H15a.875.875 0 000-1.75z\"/><path d=\"M20 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h18a1 1 0 001-1zM4.875 15.126h-.5a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-6a.625.625 0 01.625-.625H5a2.128 2.128 0 012.114 2.347 2.213 2.213 0 01-2.239 1.904zm7.25 2.375a.625.625 0 01-1.25 0v-2.125a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-6a.625.625 0 111.25 0v2.125a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V11.5a.625.625 0 111.25 0zm2.75-2.375h-.5a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-6a.625.625 0 01.625-.625H15a2.128 2.128 0 012.114 2.347 2.213 2.213 0 01-2.239 1.904z\"/><path d=\"M5 12.126h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H5a.875.875 0 000-1.75z\"/><path d=\"M23.707 5.708L18.293.294A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.751V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.415a1 1 0 00-.293-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});