define("ember-svg-jar/inlined/certified-diploma-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>certified-diploma-2-alternate</title><path d=\"M23.352 17.8L18.8 13.251a.25.25 0 00-.42.119 6.724 6.724 0 01-4.98 4.985.25.25 0 00-.118.42l4.551 4.552a1 1 0 001.707-.707v-3.109h3.108a1 1 0 00.707-1.707zM11.686 17.041a5.257 5.257 0 003.843-1.531 5.2 5.2 0 001.534-3.842l5.664-3.8a1 1 0 00.443-.83c0-2.3-4.357-6.657-6.657-6.657a1 1 0 00-.838.454L11.95 6.551a5.215 5.215 0 00-5.38 5.373L.808 15.7a.994.994 0 00-.453.836c0 2.3 4.355 6.657 6.658 6.657a1 1 0 00.829-.441zm2.429-7.551a3.257 3.257 0 11-4.606 0 3.254 3.254 0 014.606 0zm2.747-6.816a.251.251 0 01.324-.086 11.323 11.323 0 013.78 3.787.249.249 0 01-.084.32L16.57 9.589a5.165 5.165 0 00-.392-.724l.832-.831a1 1 0 10-1.41-1.415l-.832.832a5.213 5.213 0 00-.746-.409zM6.673 20.906a.249.249 0 01-.32.084 11.314 11.314 0 01-3.789-3.784.251.251 0 01.085-.323L7.059 14a5.164 5.164 0 00.41.75l-1.123 1.118a1 1 0 001.414 1.414l1.126-1.126a5.085 5.085 0 00.722.389z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});