define("ember-svg-jar/inlined/file-database-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-database-alternate</title><path d=\"M21.706 5.708L16.292.294A1 1 0 0015.585 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.415a1 1 0 00-.294-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.464a.5.5 0 01.354.147l4.535 4.535a.5.5 0 01.147.354z\"/><path d=\"M11.986 8.251c-3.379 0-5.222 1.14-5.222 1.726S8.607 11.7 11.986 11.7c3.265 0 5.263-1.143 5.263-1.765 0-.57-1.857-1.684-5.263-1.684zM16.891 16.306a11.687 11.687 0 01-4.9.978 11.741 11.741 0 01-4.865-.938.245.245 0 00-.357.219v1.461c0 .585 1.843 1.725 5.222 1.725 3.265 0 5.263-1.144 5.263-1.767v-1.459a.244.244 0 00-.363-.219zM16.891 12.225a11.687 11.687 0 01-4.9.978 11.741 11.741 0 01-4.865-.938.245.245 0 00-.357.219v1.574c0 .586 1.843 1.726 5.222 1.726 3.265 0 5.263-1.144 5.263-1.767v-1.573a.245.245 0 00-.363-.219z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});