define("ember-svg-jar/inlined/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>login</title><path d=\"M15.087 13.5a.5.5 0 00-.5.5v3.6a1 1 0 01-.567.9 1.029 1.029 0 01-1.058-.121l-7-5.6a1 1 0 010-1.562l7-5.6a1 1 0 011.625.781V10a.5.5 0 00.5.5h8.32a.5.5 0 00.5-.581A12.021 12.021 0 0012.022 0 12.113 12.113 0 00.1 11.511a12 12 0 0023.809 2.572.506.506 0 00-.5-.583z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});