define("ember-svg-jar/inlined/fast-food-burger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fast-food-burger</title><rect y=\"11.5\" width=\"24\" height=\"4.5\" rx=\"2\" ry=\"2\"/><path d=\"M22 17.5a.5.5 0 01.5.5v.5a2 2 0 01-2 2h-17a2 2 0 01-2-2V18a.5.5 0 01.5-.5zM16 3.5H8a6.5 6.5 0 00-6.478 5.959.5.5 0 00.5.541h19.957a.5.5 0 00.5-.541A6.5 6.5 0 0016 3.5zM7.5 7a1 1 0 111-1 1 1 0 01-1 1zM12 8.5a1 1 0 111-1 1 1 0 01-1 1zM16.5 7a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});