define("ember-svg-jar/inlined/space-rocket-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>space-rocket-base</title><path d=\"M15.7 16.562A6.724 6.724 0 0114.465 13V8a2.465 2.465 0 10-4.93 0v5A6.716 6.716 0 018.3 16.562l-3.448 4.376c-.677.859-.344 1.562.74 1.562h3.272a.25.25 0 00.228-.148l1.335-3a1.687 1.687 0 013.147 0l1.336 3a.25.25 0 00.228.148h3.272c1.084 0 1.417-.7.74-1.562z\"/><path d=\"M11.8 19.957l-1.6 3.586a.3.3 0 00.3.457h3a.3.3 0 00.3-.457l-1.6-3.586c-.108-.251-.29-.251-.4 0z\"/><path d=\"M22.5 8.652a2.961 2.961 0 00-3-2.913 3.063 3.063 0 00-1.01.171A6.408 6.408 0 0012 0a6.408 6.408 0 00-6.49 5.91 3.063 3.063 0 00-1.01-.171 2.961 2.961 0 00-3 2.913V23a1 1 0 002 0V12.522a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v1.926a1 1 0 002 0V6.261A4.39 4.39 0 0112 2a4.39 4.39 0 014.5 4.261v8.172a1 1 0 002 0v-1.661a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V23a1 1 0 002 0zm-17 2.12h-2v-2.12a.96.96 0 011-.913.96.96 0 011 .913zm15-.25h-2v-1.87a1 1 0 012 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});