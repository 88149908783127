define("ember-svg-jar/inlined/wench", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wench</title><path d=\"M23.582 4.986a.5.5 0 00-.831-.2l-3.557 3.555a.5.5 0 01-.4.144L16.2 8.249a.5.5 0 01-.453-.452L15.513 5.2a.5.5 0 01.145-.4l3.558-3.558a.5.5 0 00-.2-.83 7.182 7.182 0 00-7.136 1.846 6.986 6.986 0 00-1.588 7.5.49.49 0 01-.1.532l-9.28 9.273a2.582 2.582 0 00-.6 2.847 2.514 2.514 0 004.053.76l9.335-9.336a.493.493 0 01.532-.1 6.973 6.973 0 007.5-1.566 7.221 7.221 0 001.85-7.182z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});