define("ember-svg-jar/inlined/drone-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drone-controller</title><path d=\"M19 9.752h-5.749A.25.25 0 0113 9.5V4.252a1 1 0 10-2 0V9.5a.249.249 0 01-.25.25H5a3.5 3.5 0 00-3.5 3.5v7a3.5 3.5 0 003.5 3.5h14a3.5 3.5 0 003.5-3.5v-7A3.5 3.5 0 0019 9.752zm-2 10a3.5 3.5 0 110-7c.082 0 .164 0 .245.009a.248.248 0 01.2.124.251.251 0 01.008.235l-1.318 2.693a1 1 0 001.8.879l1.306-2.669a.25.25 0 01.414-.053A3.5 3.5 0 0117 19.752zM5.655 13.021a.25.25 0 01.345.231v3a1 1 0 002 0v-3a.25.25 0 01.346-.231 3.5 3.5 0 11-2.692 0zm4.845 7.731H14a.75.75 0 010 1.5h-3.5a.75.75 0 110-1.5z\"/><path d=\"M9.437 7a1 1 0 00.075-1.412 2 2 0 010-2.669 1 1 0 10-1.49-1.333 4 4 0 000 5.339A1 1 0 009.437 7zM14.563 7a1 1 0 001.414-.075 4 4 0 000-5.339 1 1 0 10-1.49 1.333 2 2 0 010 2.669A1 1 0 0014.563 7zM5.8 8.116a1 1 0 00.368-1.366 5.007 5.007 0 010-5 1 1 0 00-1.731-1 7.009 7.009 0 000 7 1 1 0 001.363.366zM18.5 4.252a4.993 4.993 0 01-.668 2.5 1 1 0 001.73 1 7.005 7.005 0 000-7.007 1 1 0 00-1.731 1 4.976 4.976 0 01.669 2.507z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});