define("ember-svg-jar/inlined/show-theater-play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>show-theater-play</title><path d=\"M5 19a3 3 0 00-3 3v1.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V22a3 3 0 00-3-3zM12 19a3 3 0 00-3 3v1.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V22a3 3 0 00-3-3zM19 19a3 3 0 00-3 3v1.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V22a3 3 0 00-3-3zM5.489 16.984C5.29 12.5 2.5 12.5 2.5 12.5h-2a.5.5 0 00-.5.5v3.5a1 1 0 001 1h3.987a.5.5 0 00.502-.516zM.5 11.5H3a1 1 0 000-2H.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5zM23.5 12.5h-1.985s-2.79 0-2.989 4.484a.5.5 0 00.5.516H23a1 1 0 001-1V13a.5.5 0 00-.5-.5zM23.5 9.5h-2.485a1 1 0 000 2H23.5a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zM23 0H1a1 1 0 00-1 1v6.979a.5.5 0 00.521.5 8.5 8.5 0 007.5-5.651.5.5 0 01.466-.328h7.026a.5.5 0 01.471.333 8.5 8.5 0 007.494 5.651.5.5 0 00.522-.5V1a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});