define("ember-svg-jar/inlined/arrow-button-down-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-down-1</title><path d=\"M12 13.584a2.643 2.643 0 01-1.875-.775L.584 3.268a1.768 1.768 0 012.5-2.5l8.739 8.739a.25.25 0 00.354 0L20.916.768a1.768 1.768 0 012.5 2.5l-9.541 9.541a2.643 2.643 0 01-1.875.775z\"/><path d=\"M12 23.75a2.643 2.643 0 01-1.875-.775L.584 13.434a1.768 1.768 0 012.5-2.5l8.739 8.739a.25.25 0 00.354 0l8.739-8.739a1.768 1.768 0 012.5 2.5l-9.541 9.541A2.643 2.643 0 0112 23.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});