define("ember-svg-jar/inlined/gesture-swipe-vertical-down-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-vertical-down-3</title><path d=\"M17.343 12.256a1 1 0 000-2H10.28a.25.25 0 01-.143-.455c2.138-1.49 1.449-3.855-.224-2.761L5.546 9.952a.5.5 0 00-.223.416V15.8a.5.5 0 00.411.493c1.1.2 3.684.664 4.166.775a1.281 1.281 0 001.519-.894l1.117-3.741a.25.25 0 01.239-.179zM15.094 22.2a1.748 1.748 0 01-1.661-1.2l-.423-1.272a.249.249 0 00-.2-.168.731.731 0 00-.6.169.748.748 0 00-.221.8l.945 2.834a.755.755 0 00.95.474l2.834-.944a.748.748 0 00.51-.659.738.738 0 00-.091-.412.251.251 0 00-.3-.116l-1.192.4a1.722 1.722 0 01-.551.094zM15.742 8.031a1.011 1.011 0 001.134.845 1 1 0 00.844-1.134c-.475-3.255-1.6-5.731-3.34-7.361a1 1 0 00-1.368 1.46 10.3 10.3 0 012.73 6.19z\"/><path d=\"M14.485 16.945a.751.751 0 00-1.048.908l.945 2.834a.75.75 0 00.949.475l2.834-.945a.751.751 0 00.1-1.382l-1.032-.517a.249.249 0 01-.129-.292 23.594 23.594 0 00.644-2.986 1 1 0 00-1.979-.29 21.548 21.548 0 01-.293 1.569c-.052.237-.106.47-.164.7a.245.245 0 01-.138.166.248.248 0 01-.216 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});