define("ember-svg-jar/inlined/casino-winner-chip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>casino-winner-chip</title><path d=\"M10.351 16.52H2.75a1 1 0 000 2h7.5a.249.249 0 00.18-.076.253.253 0 00.07-.182 7.5 7.5 0 01.1-1.452.252.252 0 00-.057-.2.248.248 0 00-.192-.09zM18 10.52a.245.245 0 00.243-.213l.615-4.677a.251.251 0 01.162-.2 1.5 1.5 0 10-1.478-.254.25.25 0 01.062.307A3.436 3.436 0 0114.5 7.52c-2.514 0-3.48-2.845-3.826-4.458a.251.251 0 01.114-.265 1.5 1.5 0 10-1.575 0 .251.251 0 01.113.265C8.98 4.675 8.014 7.52 5.5 7.52a3.417 3.417 0 01-3.11-2.036.249.249 0 01.064-.306 1.5 1.5 0 10-1.474.25.249.249 0 01.162.2l1.112 8.456a.5.5 0 00.5.434h8.47a.249.249 0 00.218-.129A7.507 7.507 0 0118 10.52z\"/><circle cx=\"18\" cy=\"18.067\" r=\"2.25\"/><path d=\"M18.84 12.212a.251.251 0 00-.09.192v1.79a.248.248 0 00.188.241 3.759 3.759 0 011.4.7.248.248 0 00.306.005l1.436-1.07a.252.252 0 00.1-.187.249.249 0 00-.079-.2 5.99 5.99 0 00-3.056-1.53.254.254 0 00-.205.059zM13.9 13.687a.249.249 0 00-.079.2.252.252 0 00.1.187l1.433 1.074a.248.248 0 00.306-.005 3.755 3.755 0 011.4-.7.249.249 0 00.187-.242V12.4a.25.25 0 00-.293-.247 5.989 5.989 0 00-3.054 1.534zM23.354 15.356a.25.25 0 00-.373-.088l-1.433 1.075a.252.252 0 00-.081.3 3.744 3.744 0 010 2.856.249.249 0 00.081.295l1.433 1.075a.249.249 0 00.373-.087 6.011 6.011 0 000-5.422zM14.533 19.5a3.744 3.744 0 010-2.856.251.251 0 00-.081-.3l-1.433-1.075a.25.25 0 00-.207-.043.254.254 0 00-.166.131 6.011 6.011 0 000 5.422.251.251 0 00.373.087l1.433-1.075a.25.25 0 00.081-.291zM20.647 20.989a.249.249 0 00-.306 0 3.741 3.741 0 01-1.4.7.25.25 0 00-.187.242v1.79a.25.25 0 00.294.246 5.981 5.981 0 003.052-1.52.252.252 0 00.079-.2.25.25 0 00-.1-.186zM17.062 21.7a3.744 3.744 0 01-1.4-.7.249.249 0 00-.306 0l-1.436 1.064a.25.25 0 00-.02.382 5.974 5.974 0 003.057 1.53.25.25 0 00.293-.246v-1.79a.25.25 0 00-.188-.24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});