define("ember-svg-jar/inlined/laboratory-test-stool", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laboratory-test-stool</title><path d=\"M21.658 16.908a.5.5 0 01-.25-.751 3.5 3.5 0 00-2.4-5.408.157.157 0 01-.1-.247 5.19 5.19 0 00.206-6.153A13.805 13.805 0 0015.219.478a1 1 0 00-1.582.768 3.691 3.691 0 01-3.682 3.465H8.631a3.58 3.58 0 00-3.561 2.8 3.5 3.5 0 00.73 2.916.173.173 0 01-.134.284H5.5a3.5 3.5 0 00-2.908 5.446.5.5 0 01-.25.751 3.5 3.5 0 001.158 6.8h17a3.5 3.5 0 001.158-6.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});