define("ember-svg-jar/inlined/book-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-star</title><path d=\"M20.15 4.04a.5.5 0 01-.4-.489V2a2 2 0 00-2-2H5.25a3 3 0 00-3 3v18a3 3 0 003 3h14.5a2 2 0 002-2V6a2 2 0 00-1.6-1.96zM4.25 3a1 1 0 011-1H17.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H5.25a1 1 0 01-1-1zm13.507 10.283l-2.464 2.2a.251.251 0 00-.063.287l1.37 3.142a.785.785 0 01-1.117.982L12.123 18a.249.249 0 00-.245 0L8.52 19.894a.785.785 0 01-1.12-.982l1.368-3.147a.251.251 0 00-.063-.287l-2.464-2.2A.734.734 0 016.758 12h2.831a.249.249 0 00.229-.151l1.476-3.419a.794.794 0 011.412 0l1.474 3.419a.249.249 0 00.229.151h2.831a.735.735 0 01.517 1.283z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});