define("ember-svg-jar/inlined/light-mode-night-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-mode-night-man</title><path d=\"M16.8 22.232c-.253-1.605-.436-2.765-1.707-3.31l-3.937-1.687a.252.252 0 01-.151-.23V15.87a.247.247 0 01.074-.177 3.394 3.394 0 00.784-2.344 1.79 1.79 0 00.62-1.462 1.489 1.489 0 00-.425-1.129 2.539 2.539 0 00.231-1.877C11.943 7.492 10.241 7 8.888 7c-1.2 0-2.679.389-3.234 1.447a1.152 1.152 0 00-1.038.37c-.41.544-.043 1.314.264 1.9A1.632 1.632 0 004.319 12a1.479 1.479 0 00.747 1.391A3.254 3.254 0 005.92 15.7a.25.25 0 01.08.183v1.122a.251.251 0 01-.152.23l-3.936 1.687C.64 19.468.457 20.626.205 22.23c-.056.359-.117.751-.2 1.179A.5.5 0 00.5 24h16a.5.5 0 00.491-.591c-.078-.427-.14-.819-.191-1.177zM19.564.037a1 1 0 00-1.117 1.482 4.941 4.941 0 01.234 4.611 4.96 4.96 0 01-3.646 2.859 1 1 0 00-.386 1.815A6 6 0 1019.564.037z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});