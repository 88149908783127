define("ember-svg-jar/inlined/navigation-arrows-up-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-arrows-up-1</title><path d=\"M2.029 15.308H3.5a.751.751 0 00.583-.278l6.946-8.58a1.289 1.289 0 011.942 0l6.946 8.58a.754.754 0 00.583.278h1.471a1.25 1.25 0 00.983-2.023L12.983.6a1.289 1.289 0 00-1.966 0L1.046 13.285a1.251 1.251 0 00.983 2.023z\"/><path d=\"M12.983 9.143a1.288 1.288 0 00-1.966 0L1.045 21.834a1.251 1.251 0 00.984 2.022h19.942a1.249 1.249 0 00.983-2.022z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});