define("ember-svg-jar/inlined/shipment-package", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-package</title><path d=\"M15.973 8.271a.25.25 0 000-.455L6.216 3.368a.5.5 0 00-.406 0L1.993 5.024a.25.25 0 000 .46l10.29 4.288a.5.5 0 00.4-.006zM22.062 5.5a.25.25 0 000-.456L12.4.847a1 1 0 00-.8 0l-3.22 1.4a.25.25 0 000 .457l9.7 4.422a.5.5 0 00.414 0zM.846 6.63a.25.25 0 00-.346.231v11.146a1 1 0 00.615.923L11.4 23.217a.25.25 0 00.346-.231V11.34a.251.251 0 00-.154-.231zM13.25 22.944a.251.251 0 00.353.228l9.311-4.232a1 1 0 00.586-.91V6.886a.25.25 0 00-.353-.228L13.4 11.09a.248.248 0 00-.147.227z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});