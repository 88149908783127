define("ember-svg-jar/inlined/protection-shield-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>protection-shield-1</title><path d=\"M24 1.953A1.958 1.958 0 0022.044.006H1.959A1.958 1.958 0 00.013 1.965L0 9.305a15.147 15.147 0 0011.862 14.67.974.974 0 00.194.019 1 1 0 00.2-.021A15.145 15.145 0 0023.988 9.2zM4.383 12.286a.5.5 0 01.138-.5l8.605-8.143a.5.5 0 01.344-.137h2.565a.25.25 0 01.171.432L5.455 14.111a.249.249 0 01-.4-.071 10.41 10.41 0 01-.672-1.754zm.309-8.78h4.313a.251.251 0 01.172.432L4.414 8.445a.25.25 0 01-.422-.182L4 4.205a.7.7 0 01.692-.699zm2.14 13.087a.251.251 0 01.01-.353L19.706 4.068a.181.181 0 01.3.131v2.464a.5.5 0 01-.158.364L8.59 17.58a.5.5 0 01-.652.028 10.2 10.2 0 01-1.106-1.015zm5.315 2.9a.517.517 0 01-.211 0 9.024 9.024 0 01-.926-.251.25.25 0 01-.166-.184.246.246 0 01.073-.236l8.554-8.017a.25.25 0 01.418.219 10.211 10.211 0 01-7.742 8.476z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});