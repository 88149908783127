define("ember-svg-jar/inlined/phone-double-camera-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-double-camera-1</title><path d=\"M19.5 6h-15A4.505 4.505 0 000 10.5v3A4.505 4.505 0 004.5 18h15a4.505 4.505 0 004.5-4.5v-3A4.505 4.505 0 0019.5 6zM17 16a4 4 0 114-4 4 4 0 01-4 4zM7 8a4 4 0 11-4 4 4 4 0 014-4z\"/><path d=\"M7 11.25a.75.75 0 01.75.75.75.75 0 001.5 0A2.252 2.252 0 007 9.75a.75.75 0 000 1.5zM17 9.75a.75.75 0 000 1.5.75.75 0 01.75.75.75.75 0 001.5 0A2.252 2.252 0 0017 9.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});