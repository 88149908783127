define("ember-svg-jar/inlined/disability-blind-read-book-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-blind-read-book-1</title><path d=\"M20.158 6.241a1.506 1.506 0 000-1.726C19.414 3.456 16.628 0 12 0S4.586 3.456 3.843 4.515a1.506 1.506 0 000 1.726C4.587 7.3 7.373 10.757 12 10.757s7.413-3.457 8.158-4.516zM7.98 7.5a9.711 9.711 0 01-2.029-1.8.5.5 0 010-.646A8.066 8.066 0 0112 2a6.366 6.366 0 011.229.125.25.25 0 01.131.42L8.612 7.429a.5.5 0 01-.632.071zm10.07-1.8A8.063 8.063 0 0112 8.757a6.374 6.374 0 01-1.229-.125.251.251 0 01-.131-.42l4.748-4.884a.5.5 0 01.633-.07 9.7 9.7 0 012.028 1.8.5.5 0 01.001.642zM10.905 13.3l-5.364-1.741a1.75 1.75 0 00-2.291 1.665v7a1.745 1.745 0 001.21 1.664l6.463 2.1a.25.25 0 00.327-.238v-9.972a.5.5 0 00-.345-.478zM20.028 11.807a1.754 1.754 0 00-1.568-.248L13.1 13.3a.5.5 0 00-.346.476v9.974a.25.25 0 00.327.238l6.463-2.1a1.745 1.745 0 001.21-1.664v-7a1.757 1.757 0 00-.726-1.417z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});