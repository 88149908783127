define("ember-svg-jar/inlined/common-file-give-hand-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-give-hand-2</title><path d=\"M23.482 3.569L20.618.537A1.819 1.819 0 0019.321 0H8a2 2 0 00-2 2v11.752a.249.249 0 00.246.25c.384 0 1.121.008 1.507.012a.246.246 0 00.247-.25V2.5a.5.5 0 01.5-.5h10.534a.5.5 0 01.364.157l2.466 2.61A.5.5 0 0122 5.11V19.5a.5.5 0 01-.5.5h-3.293a.248.248 0 00-.25.242 12.041 12.041 0 01-.117 1.468.247.247 0 00.245.29h4.082A1.833 1.833 0 0024 20.167V4.845a1.8 1.8 0 00-.518-1.276z\"/><path d=\"M15.5 19.5H6.375a.625.625 0 010-1.25H8.9a1 1 0 00.92-1.394A2.233 2.233 0 007.764 15.5H3.5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.764a2.238 2.238 0 002.236-2.236V20.5a1 1 0 00-1-1zM1 14.75a1 1 0 00-1 1V23a1 1 0 002 0v-7.25a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});