define("ember-svg-jar/inlined/read-sports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-sports</title><path d=\"M20.75 0H4.25a2 2 0 00-2 2v19a3 3 0 003 3h13.5a2 2 0 002-2v-1.584a.5.5 0 01.333-.471A1 1 0 0021.75 19V1a1 1 0 00-1-1zm-2 21.5a.5.5 0 01-.5.5h-13a1 1 0 010-2h13a.5.5 0 01.5.5zm-2.55-7.163l-2.708.543a1.454 1.454 0 00-.746.4 4.084 4.084 0 11-5.759-5.79 1.379 1.379 0 00.375-.7L7.9 6.1a3.85 3.85 0 011.481-2.64 3.028 3.028 0 012.346-.408 3.813 3.813 0 011.879.906 1 1 0 00.617.263 3.974 3.974 0 012.581 1.223 3.965 3.965 0 011.245 2.6 1 1 0 00.259.614 3.8 3.8 0 01.89 1.872c.187.919.266 3.147-2.998 3.807z\"/><path d=\"M16.766 9.327a.5.5 0 01-.263-.551 2.389 2.389 0 00-.754-2.276 2.381 2.381 0 00-2.268-.749.5.5 0 01-.554-.264 2.011 2.011 0 00-1.489-.968 1.466 1.466 0 00-1.62.581 3.452 3.452 0 00-.445 1.29l-.637 3.184a1 1 0 01-.273.511l-.445.445a2.619 2.619 0 003.7 3.7l.445-.445a1 1 0 01.512-.274l3.2-.64a3.386 3.386 0 001.271-.442 1.461 1.461 0 00.581-1.618 2.012 2.012 0 00-.961-1.484zM10 9.383a.252.252 0 01-.15-.283l.506-2.531c.137-.68.217-1.2.885-1.068.7.139 1.035.463.762 1.116l-1.7 2.667a.25.25 0 01-.303.099zm1.767 2.139a.5.5 0 01-.623-.068l-.348-.349a.5.5 0 01-.068-.622l1.916-3.01a1.545 1.545 0 112.133 2.133zm3.9.376l-2.516.5a.25.25 0 01-.183-.456l2.668-1.7c.657-.27.976.074 1.114.764.132.672-.389.753-1.084.894z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});