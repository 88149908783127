define("ember-svg-jar/inlined/zip-file-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>zip-file-warning</title><path d=\"M17.687 9.986A.256.256 0 0018 9.745V5.207a1.5 1.5 0 00-.44-1.06L13.854.439A1.5 1.5 0 0012.793 0H1.5A1.5 1.5 0 000 1.5v19A1.5 1.5 0 001.5 22h7.9a.246.246 0 00.226-.145c.59-1.277 5.265-10.281 5.265-10.281a2.919 2.919 0 012.796-1.588zM6 18.5a1 1 0 111-1 1 1 0 01-1 1zM6 15a1 1 0 111-1 1 1 0 01-.995 1zm0-3.5a1 1 0 111-1 1 1 0 01-.995 1zm1.5-4.9a1.968 1.968 0 01-1.478.67 1.977 1.977 0 01-2.006-2.221l.49-3.127A.5.5 0 015 1.5h2a.5.5 0 01.5.423l.485 3.111v.015A1.99 1.99 0 017.508 6.6z\"/><path d=\"M23.836 21.905l-5.055-9.634a1.449 1.449 0 00-2.561 0l-5.055 9.634a1.426 1.426 0 00.047 1.408 1.454 1.454 0 001.233.687h10.111a1.456 1.456 0 001.233-.686 1.428 1.428 0 00.047-1.409zM16.751 16a.75.75 0 011.5 0v2.5a.75.75 0 01-1.5 0zm.75 6.25a1 1 0 111-1 1 1 0 01-1.001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});