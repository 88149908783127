define("ember-svg-jar/inlined/gesture-swipe-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-horizontal</title><path d=\"M5.75 8h-2a.249.249 0 01-.25-.25V6.5a.75.75 0 00-1.335-.47l-2 2.5a.748.748 0 000 .937l2 2.5A.75.75 0 003.5 11.5v-1.25a.25.25 0 01.25-.25h2a1 1 0 100-2zM23.835 8.53l-2-2.5a.75.75 0 00-1.335.47v1.25a.249.249 0 01-.25.25h-2a1 1 0 100 2h2a.25.25 0 01.25.25v1.25a.75.75 0 001.335.469l2-2.5a.748.748 0 000-.939zM12.125 5a3.5 3.5 0 00-3.5 3.5v10a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-10a3.5 3.5 0 00-3.5-3.5zm1.5 4.625a.375.375 0 01-.375.375H11a.375.375 0 01-.375-.375V8.5a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});