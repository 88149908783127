define("ember-svg-jar/inlined/bathroom-hair-dryer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bathroom-hair-dryer</title><path d=\"M4.621 2.015l-4-1a.515.515 0 00-.429.091A.5.5 0 000 1.5v7a.5.5 0 00.5.5.555.555 0 00.121-.014l4-1A.5.5 0 005 7.5v-5a.5.5 0 00-.379-.485zM24 23a1 1 0 00-1-1h-2.75a1 1 0 01-1-1v-2.67a.33.33 0 01.33-.33 1.5 1.5 0 001.493-1.366l.607-5.918a.5.5 0 00-.743-.487 5.722 5.722 0 01-3.194.749l-1.511-.263a.5.5 0 00-.583.439l-.557 5.223A1.5 1.5 0 0016.587 18h.163a.5.5 0 01.5.5V21a3 3 0 003 3H23a1 1 0 001-1zM23 5a5.006 5.006 0 00-5-5C17.936 0 6.477 2 6.414 2.008A.5.5 0 006 2.5v5a.5.5 0 00.414.492L18 10a5.006 5.006 0 005-5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});