define("ember-svg-jar/inlined/style-three-pin-church", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-church</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm5.467 11.679A.5.5 0 0117 12h-.5a.5.5 0 00-.5.5v4a1 1 0 01-1 1h-1a1 1 0 01-1-1v-2a1 1 0 00-2 0v2a1 1 0 01-1 1H9a1 1 0 01-1-1v-4a.5.5 0 00-.5-.5H7a.5.5 0 01-.334-.872l4.418-3.977a.5.5 0 00.166-.371v-.53a.5.5 0 00-.5-.5h-.5a.75.75 0 010-1.5h.5a.5.5 0 00.5-.5v-.5a.75.75 0 011.5 0v.5a.5.5 0 00.5.5h.5a.75.75 0 010 1.5h-.5a.5.5 0 00-.5.5v.53a.5.5 0 00.166.371l4.418 3.977a.5.5 0 01.133.551z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});