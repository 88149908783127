define("ember-svg-jar/inlined/shell-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shell-1</title><path d=\"M8.1 1.062a.249.249 0 00-.164-.439H5a1.5 1.5 0 00-1.5 1.5V5.6a.25.25 0 00.429.174c1.223-1.257 2.339-2.594 3.39-3.9a6.145 6.145 0 01.781-.812zM16.681 1.869a50.344 50.344 0 003.894 4.41A.25.25 0 0021 6.1V2.123a1.5 1.5 0 00-1.5-1.5h-3.436a.25.25 0 00-.163.439 6.081 6.081 0 01.78.807zM23.32 10.527l-1.1-.83a38.974 38.974 0 01-6.711-6.89 4.5 4.5 0 00-7.022 0A38.935 38.935 0 011.778 9.7l-1.109.839a1.837 1.837 0 00-.635 1.581 10.914 10.914 0 00.571 2.642.249.249 0 00.421.086L4.7 10.785a.5.5 0 01.77.63l-3.865 5.244a.251.251 0 00-.016.272 13.86 13.86 0 002.771 3.447.252.252 0 00.223.057.249.249 0 00.175-.148l3.115-7.517a.5.5 0 01.937.334l-2.553 8.469a.249.249 0 00.111.286 11.149 11.149 0 004.4 1.517.25.25 0 00.191-.058.248.248 0 00.088-.179l.478-9.565a.5.5 0 011 0l.475 9.56a.249.249 0 00.28.236 11.165 11.165 0 004.392-1.533.25.25 0 00.11-.287l-2.548-8.45a.5.5 0 01.937-.334l3.1 7.489a.249.249 0 00.4.089A13.826 13.826 0 0022.43 16.9a.249.249 0 00-.016-.271l-3.844-5.21a.5.5 0 01.77-.63l3.646 4.028a.249.249 0 00.421-.087 10.892 10.892 0 00.559-2.606 1.84 1.84 0 00-.646-1.597z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});