define("ember-svg-jar/inlined/electronics-vacuum-tube", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>electronics-vacuum-tube</title><path d=\"M17.5 4.5A2.5 2.5 0 0015 2h-1a.5.5 0 01-.5-.5 1.5 1.5 0 10-3 0 .5.5 0 01-.5.5H9a2.5 2.5 0 00-2.5 2.5v12a2.5 2.5 0 001.364 2.223.252.252 0 01.136.224V23a1 1 0 102 0v-3.75a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25V23a1 1 0 102 0v-3.75a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25V23a1 1 0 102 0v-4.053a.252.252 0 01.136-.223A2.5 2.5 0 0017.5 16.5zM16 15.251a.25.25 0 01-.25.25H14.5a.25.25 0 01-.25-.25v-9.5a.25.25 0 01.25-.25h1.25a.25.25 0 01.25.25zm-8-9.5a.25.25 0 01.25-.25h4.25a.25.25 0 01.25.25V11a.25.25 0 01-.25.25h-2a.75.75 0 00-.75.75v3.25a.25.25 0 01-.25.25H8.25a.25.25 0 01-.25-.25zm3.5 9.75a.25.25 0 01-.25-.25V13a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v2.25a.25.25 0 01-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});