define("ember-svg-jar/inlined/social-media-gowalla-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-gowalla-2</title><path d=\"M20.48 10h-7a.5.5 0 00-.5.5v3.6a2.69 2.69 0 01-1.33-.67 5.24 5.24 0 01-1.25-3.96c0-3.72 1.67-4.74 2.5-5 .44 2.38 2 3.39 3.41 3.31a3.15 3.15 0 002.89-3.4C19.2 1.81 16.64 0 13 0a11 11 0 00-6.67 2.21A8.48 8.48 0 003 9.18C3 14 4.93 17 8.71 18.35 8.8 20.72 9.14 24 13.9 24c5.22 0 7.08-3.26 7.08-6v-7.53a.5.5 0 00-.5-.47zm-6.21 9.44a2.5 2.5 0 01-1.72.78 1.46 1.46 0 01-1.06-.42 1.56 1.56 0 01-.38-1.4 2.66 2.66 0 01.74-1.4 2 2 0 012.78-.37 2 2 0 01-.36 2.78z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});