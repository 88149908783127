define("ember-svg-jar/inlined/professions-woman-telecommunicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-woman-telecommunicator</title><path d=\"M.5 23.441a.5.5 0 00.5.5h7.88a.25.25 0 00.142-.455l-4.378-3.031a.625.625 0 01-.215-.768l1.322-2.974a.25.25 0 00-.305-.339 13.1 13.1 0 00-3.98 1.985A2.464 2.464 0 00.5 20.335zM23.5 23.441a.5.5 0 01-.5.5h-7.88a.25.25 0 01-.142-.455l4.378-3.031a.625.625 0 00.215-.768l-1.322-2.974a.25.25 0 01.3-.339 13.1 13.1 0 013.98 1.985 2.464 2.464 0 01.966 1.976z\"/><path d=\"M16.606 16.217a.251.251 0 00-.429-.04L12.2 21.662a.25.25 0 01-.4 0l-3.977-5.485a.251.251 0 00-.429.04c-.381.814-1.247 2.705-1.525 3.313a.25.25 0 00.087.311l5.9 4a.247.247 0 00.28 0l5.9-4a.25.25 0 00.087-.311 291.26 291.26 0 00-1.517-3.313z\"/><path d=\"M12.27 16.059a1 1 0 00.926-.631 1.012 1.012 0 00.074-.369 1 1 0 00-1-1 5.006 5.006 0 01-5-5v-.95a.249.249 0 01.243-.25A7.019 7.019 0 0012.166 6.2a.156.156 0 01.208 0 7.018 7.018 0 004.653 1.66.25.25 0 01.243.25v2.449a1 1 0 01-1 1h-3.25a1 1 0 000 2h3.25a3 3 0 002.912-2.314.25.25 0 01.229-.192 2.5 2.5 0 00-.021-4.989.249.249 0 01-.232-.207A7 7 0 005.27 7.059c0 5.269-1.841 5.9-2.4 6.082a.5.5 0 00-.327.337.5.5 0 00.1.46 3.8 3.8 0 002.921 1.119 5.365 5.365 0 001.311-.157 3.472 3.472 0 00.832-.332.251.251 0 01.274.021 7.093 7.093 0 001.4.843 6.958 6.958 0 002.889.627zM5.52 7.1z\"/><circle cx=\"14.27\" cy=\"9.309\" r=\"1\"/><circle cx=\"10.27\" cy=\"9.309\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});