define("ember-svg-jar/inlined/road-sign-side-road-angle-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-side-road-angle-left</title><path d=\"M.47 12.53l11 11a.748.748 0 001.06 0l11-11a.749.749 0 000-1.06l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06zm8.5-3.56a.749.749 0 011.06 0l1.793 1.793a.25.25 0 00.427-.177V8.5a.75.75 0 011.5 0v8a.75.75 0 01-1.5 0v-3.086a.251.251 0 00-.073-.177L8.97 10.03a.749.749 0 010-1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});