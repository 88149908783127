define("ember-svg-jar/inlined/loading-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>loading-2</title><path d=\"M5.661 20.709a.855.855 0 00.716-.482l2-3.461a1.2 1.2 0 000-1.116l-1.351-2.343a1.214 1.214 0 00-.966-.559H2.07a.852.852 0 00-.776.38.853.853 0 00.058.861l3.592 6.237a.854.854 0 00.717.483zM4.944 3.77l-3.592 6.237a.855.855 0 00-.058.862.854.854 0 00.776.379h3.99a1.215 1.215 0 00.966-.558l1.351-2.344a1.2 1.2 0 000-1.116l-2-3.461a.788.788 0 00-1.433.001zM15.6 1.778H8.4a.854.854 0 00-.776.379.855.855 0 00.06.862l2 3.461a1.2 1.2 0 00.966.558h2.7a1.2 1.2 0 00.966-.558l2-3.461a.855.855 0 00.06-.862.854.854 0 00-.776-.379zM18.339 3.287a.852.852 0 00-.715.482l-2 3.462a1.2 1.2 0 000 1.115l1.351 2.343a1.214 1.214 0 00.966.559h3.99a.774.774 0 00.717-1.242L19.056 3.77a.854.854 0 00-.717-.483zM18.339 20.709a.854.854 0 00.717-.483l3.591-6.237a.774.774 0 00-.717-1.241h-3.99a1.215 1.215 0 00-.966.558l-1.35 2.344a1.2 1.2 0 000 1.116l2 3.461a.852.852 0 00.715.482zM13.35 16.958h-2.7a1.2 1.2 0 00-.966.558l-2 3.461a.773.773 0 00.716 1.241h7.2a.773.773 0 00.716-1.241l-2-3.461a1.2 1.2 0 00-.966-.558z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});