define("ember-svg-jar/inlined/zip-file-cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>zip-file-cash</title><path d=\"M9.5 17.5c0-4.346 2.791-7.87 8.253-7.972A.249.249 0 0018 9.283V5.207a1.5 1.5 0 00-.439-1.06L13.851.439A1.5 1.5 0 0012.791 0H1.5A1.5 1.5 0 000 1.5v19A1.5 1.5 0 001.5 22h8.94a.246.246 0 00.207-.379A7.948 7.948 0 019.5 17.5zm-3.5 1a1 1 0 111-1 1 1 0 01-1 1zM6 15a1 1 0 111-1 1 1 0 01-1 1zm0-3.5a1 1 0 111-1 1 1 0 01-1 1zm1.5-4.9a1.97 1.97 0 01-1.479.67 1.977 1.977 0 01-2.008-2.221L4.5 1.922A.5.5 0 015 1.5h2a.5.5 0 01.494.423l.486 3.111v.015A1.989 1.989 0 017.506 6.6z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm1.039 10.177c-.094.023-.289.109-.289.293v.28a.75.75 0 01-1.5 0v-.25a.248.248 0 00-.247-.248H16a.75.75 0 110-1.5h2.033a.592.592 0 00.22-1.141l-2.062-.825a2.084 2.084 0 01.343-3.978.331.331 0 00.216-.328v-.23a.75.75 0 011.5 0v.25a.25.25 0 00.25.25h.5a.75.75 0 010 1.5h-2.031a.592.592 0 00-.22 1.141l2.063.825a2.084 2.084 0 01-.271 3.961z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});