define("ember-svg-jar/inlined/accessories-earrings-oriental", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accessories-earrings-oriental</title><path d=\"M6.573 7.027a.5.5 0 01.279-.3 3.5 3.5 0 10-2.7 0 .5.5 0 01.242.706C3 9.909 0 15.556 0 18.016A5.764 5.764 0 005.5 24a5.764 5.764 0 005.5-5.984c0-2.46-3-8.107-4.39-10.582a.5.5 0 01-.037-.407zM5.5 2.5a1 1 0 11-1 1 1 1 0 011-1zm0 16.5a2.01 2.01 0 01-2.01-2.01 12.156 12.156 0 011.419-3.54.667.667 0 011.182 0 12.156 12.156 0 011.419 3.54A2.01 2.01 0 015.5 19zM19.61 7.434a.5.5 0 01.242-.706 3.5 3.5 0 10-2.7 0 .5.5 0 01.242.706C16 9.909 13 15.556 13 18.016A5.764 5.764 0 0018.5 24a5.764 5.764 0 005.5-5.984c0-2.46-3-8.107-4.39-10.582zM18.5 2.5a1 1 0 11-1 1 1 1 0 011-1zm0 16.5a2.01 2.01 0 01-2.01-2.01 12.156 12.156 0 011.419-3.54.667.667 0 011.182 0 12.156 12.156 0 011.419 3.54A2.01 2.01 0 0118.5 19z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});