define("ember-svg-jar/inlined/reflect-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>reflect-down</title><path d=\"M15.249 11h-14a1 1 0 000 2h14a1 1 0 000-2zM14.249.249h-12A1.169 1.169 0 001.058.92a1.169 1.169 0 00.368 1.316l5.586 5.586a1.753 1.753 0 002.474 0l5.586-5.586A1.169 1.169 0 0015.44.92a1.169 1.169 0 00-1.191-.671zM9.486 16.176a1.752 1.752 0 00-2.474 0l-5.586 5.586a1.169 1.169 0 00-.368 1.316 1.169 1.169 0 001.191.671h13a.75.75 0 00.53-1.28z\"/><path d=\"M23.75 11.5a7.008 7.008 0 00-7-7 1 1 0 000 2 4.993 4.993 0 013.468 8.592.248.248 0 01-.259.055.252.252 0 01-.163-.21l-.048-.487a.5.5 0 00-.882-.27l-2.5 3a.5.5 0 00.107.736l3 2a.5.5 0 00.277.084.509.509 0 00.258-.071.5.5 0 00.24-.479l-.163-1.627a.25.25 0 01.125-.243 6.985 6.985 0 003.54-6.08z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});