define("ember-svg-jar/inlined/car-garage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-garage</title><path d=\"M23.673 9.954L13.348.527a1.987 1.987 0 00-2.7 0L.327 9.954a1 1 0 001.349 1.476l9.987-9.119a.5.5 0 01.674 0l9.987 9.119a1 1 0 001.349-1.476z\"/><path d=\"M18.888 16a1.278 1.278 0 01-1.2-.787l-.743-1.856A3.751 3.751 0 0013.469 11H9.14a2.5 2.5 0 00-2.451 2.01l-.357 1.79a1.5 1.5 0 01-1.47 1.2H3.5A2.5 2.5 0 001 18.5v2a1.5 1.5 0 001.213 1.472.251.251 0 00.3-.225 3 3 0 015.979.011.257.257 0 00.25.241h6.518a.259.259 0 00.25-.241A3 3 0 0118.5 19a3.058 3.058 0 013.009 2.734.249.249 0 00.1.186.245.245 0 00.2.047A1.5 1.5 0 0023 20.5v-.355A4.148 4.148 0 0018.888 16zm-2.605-.074a.167.167 0 01-.138.074H13.5a1.5 1.5 0 01-1.5-1.5V13a.5.5 0 01.5-.5h.969a2.24 2.24 0 012.089 1.414l.742 1.856a.167.167 0 01-.017.158z\"/><circle cx=\"5.501\" cy=\"22\" r=\"2\"/><circle cx=\"18.499\" cy=\"22\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});