define("ember-svg-jar/inlined/design-file-text-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-text-image</title><path d=\"M21.414 4.914L17.086.586A2 2 0 0015.672 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.329a2 2 0 00-.586-1.415zM8.6 4.5A1.5 1.5 0 117.1 6a1.5 1.5 0 011.5-1.5zm-1.524 8.237l1.478-2.388a1 1 0 011.273-.206l1.579 1.206a.249.249 0 00.349-.045l1.511-1.944a1 1 0 011.587.067L16.3 12.8a.5.5 0 01-.46.7H7.5a.5.5 0 01-.424-.763zM16.5 20.75h-10a.75.75 0 010-1.5h10a.75.75 0 010 1.5zm0-3.5h-10a.75.75 0 010-1.5h10a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});