define("ember-svg-jar/inlined/style-three-pin-shinto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-shinto</title><path d=\"M13.25 7.5a.5.5 0 00-.5.5v1.25a.5.5 0 00.5.5h.777a.5.5 0 00.5-.561l-.155-1.25a.5.5 0 00-.5-.439z\"/><path d=\"M22.389 7.937a8.668 8.668 0 00-4.01-6.032 12.126 12.126 0 00-12.758 0 8.668 8.668 0 00-4.01 6.032 10.786 10.786 0 002.577 8.486 59.243 59.243 0 007.17 7.252 1 1 0 001.284 0 60.382 60.382 0 007.17-7.253 10.786 10.786 0 002.577-8.485zM18.75 10.5a.75.75 0 01-.75.75h-1.144a.5.5 0 00-.5.562l.384 3.1a.749.749 0 01-.652.836.726.726 0 01-.093.006.749.749 0 01-.743-.657l-.422-3.4a.5.5 0 00-.5-.439H9.663a.5.5 0 00-.5.439l-.422 3.4a.749.749 0 01-.741.649.726.726 0 01-.093-.006.749.749 0 01-.652-.836l.384-3.1a.5.5 0 00-.5-.562H6a.75.75 0 010-1.5h1.454a.5.5 0 00.5-.438l.155-1.25a.5.5 0 00-.5-.562H7.39a.5.5 0 01-.485-.378l-.6-2.4a.5.5 0 01.728-.558A10.384 10.384 0 0012 5.5a10.384 10.384 0 004.966-1.334.5.5 0 01.728.558l-.6 2.4a.5.5 0 01-.485.378h-.219a.5.5 0 00-.5.562l.155 1.25a.5.5 0 00.5.438H18a.75.75 0 01.75.748z\"/><path d=\"M11.25 8a.5.5 0 00-.5-.5h-.622a.5.5 0 00-.5.439l-.155 1.25a.5.5 0 00.5.561h.777a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});