define("ember-svg-jar/inlined/xml-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>xml-1</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM8.559 19.222a.625.625 0 11-1.118.558l-1.217-2.435a.25.25 0 00-.448 0L4.559 19.78a.625.625 0 01-.559.346.625.625 0 01-.559-.9l1.805-3.609a.252.252 0 000-.224L3.441 11.78a.625.625 0 111.118-.558l1.217 2.435a.251.251 0 00.448 0l1.217-2.435a.625.625 0 111.118.558l-1.8 3.609a.252.252 0 000 .224zm6.066.279a.625.625 0 11-1.25 0v-4.555c0-.306-.292-.215-.381-.036l-.435.87a.625.625 0 01-1.118 0s-.33-.685-.436-.873-.38-.2-.38 0V19.5a.625.625 0 01-1.25 0v-8a.625.625 0 011.184-.279l1.217 2.434a.25.25 0 00.448 0l1.217-2.434a.625.625 0 011.184.279zm5.375.625h-2.5A2.127 2.127 0 0115.375 18v-6.5a.625.625 0 111.25 0V18a.877.877 0 00.875.875H20a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});