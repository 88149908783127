define("ember-svg-jar/inlined/diagram-fast-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-fast-up</title><path d=\"M1.834 24c.053 0 .106 0 .16-.008a21.13 21.13 0 002.046-.328 1.448 1.448 0 00-.6-2.833 17.808 17.808 0 01-1.763.283A1.447 1.447 0 001.834 24zM8.292 22.13a1.441 1.441 0 00.634-.147c.615-.3 1.225-.636 1.813-1a1.447 1.447 0 10-1.517-2.463c-.509.313-1.036.6-1.566.862a1.447 1.447 0 00.636 2.748zM11.947 18.439a1.447 1.447 0 002.044.085A21.274 21.274 0 0020.607 6a.249.249 0 01.247-.213h1.31a1.448 1.448 0 001.046-2.445L20.44.447A1.556 1.556 0 0019.363 0a1.354 1.354 0 00-1.05.487l-2.544 2.9a1.447 1.447 0 001.089 2.4h.54a.249.249 0 01.246.293 18.347 18.347 0 01-5.613 10.311 1.447 1.447 0 00-.084 2.048z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});