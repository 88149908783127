define("ember-svg-jar/inlined/programming-team-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-team-chat</title><circle cx=\"19.12\" cy=\"16.25\" r=\"3.25\"/><path d=\"M12.213 14.443a.738.738 0 00.287.057.746.746 0 00.53-.22l2.634-2.636a.5.5 0 01.354-.147H19.5a1.752 1.752 0 001.75-1.75v-8A1.752 1.752 0 0019.5 0h-14a1.752 1.752 0 00-1.75 1.75v8A1.752 1.752 0 005.5 11.5h5.75a.5.5 0 01.5.5v1.75a.75.75 0 00.463.693zM15.47 4.28a.75.75 0 111.06-1.06l2 2a.749.749 0 010 1.06l-2 2a.75.75 0 11-1.06-1.06l1.293-1.293a.252.252 0 000-.354zM9.53 7.22a.75.75 0 11-1.06 1.06l-2-2a.749.749 0 010-1.06l2-2a.75.75 0 111.06 1.06L8.237 5.573a.252.252 0 000 .354zM11.5 8.5a.741.741 0 01-.334-.079.75.75 0 01-.336-1.006l2-4a.75.75 0 111.342.67l-2 4a.75.75 0 01-.672.415z\"/><circle cx=\"4.88\" cy=\"16.25\" r=\"3.25\"/><path d=\"M.5 24h8.76a.5.5 0 00.487-.611 4.99 4.99 0 00-9.734 0A.5.5 0 00.5 24zM14.253 23.389a.5.5 0 00.487.611h8.76a.5.5 0 00.487-.611 4.99 4.99 0 00-9.734 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});