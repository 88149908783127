define("ember-svg-jar/inlined/cursor-double", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-double</title><path d=\"M22.748 10.892L10.064 4.153a1.467 1.467 0 00-1.711.261A1 1 0 009.4 6.063l11.065 5.878a.25.25 0 01-.006.444l-2.44 1.223a1.008 1.008 0 00-.462.481L16.32 16.8a.25.25 0 01-.45.012l-.536-1.037a1 1 0 00-1.777.918l1.283 2.48a1.458 1.458 0 002.625-.061l1.762-3.872L22.7 13.5a1.458 1.458 0 00.051-2.606z\"/><path d=\"M16.206 12.224a1 1 0 00-.532-.895L1.942 4.033A1 1 0 00.584 5.375l7.3 14.163a1 1 0 001.8-.044l2-4.4 3.974-1.987a1 1 0 00.548-.883z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});