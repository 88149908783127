define("ember-svg-jar/inlined/pill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pill</title><path d=\"M21.89 2.113a7.218 7.218 0 00-10.2 0l-9.57 9.574A7.2 7.2 0 007.21 24a7.156 7.156 0 005.095-2.105l9.581-9.581a7.231 7.231 0 000-10.2zM20.476 10.9l-3.726 3.722a.5.5 0 01-.707 0L9.382 7.961a.5.5 0 010-.707l3.726-3.727a5.2 5.2 0 018.885 3.692 5.159 5.159 0 01-1.517 3.681z\"/><path d=\"M16.634 3.159a4.091 4.091 0 00-2.91 1.205.75.75 0 001.06 1.061 2.678 2.678 0 013.7 0 .75.75 0 001.061-1.061 4.09 4.09 0 00-2.911-1.205z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});