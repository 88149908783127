define("ember-svg-jar/inlined/soccer-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>soccer-field</title><path d=\"M11.137 9.513a.25.25 0 00-.237-.02 2.73 2.73 0 000 5.014.25.25 0 00.35-.229V9.722a.251.251 0 00-.113-.209zM12.863 14.487a.25.25 0 00.237.02 2.73 2.73 0 000-5.014.25.25 0 00-.35.229v4.556a.249.249 0 00.113.209z\"/><rect x=\"21.25\" y=\"8.25\" width=\"2.75\" height=\"7.5\" rx=\".5\" ry=\".5\"/><path d=\"M10.75 4H1a1 1 0 00-1 1v1.25a.5.5 0 00.5.5h2A1.752 1.752 0 014.25 8.5v7a1.752 1.752 0 01-1.75 1.75h-2a.5.5 0 00-.5.5V19a1 1 0 001 1h9.75a.5.5 0 00.5-.5v-2.927a.5.5 0 00-.368-.482 4.238 4.238 0 010-8.182.5.5 0 00.368-.482V4.5a.5.5 0 00-.5-.5z\"/><rect y=\"8.25\" width=\"2.75\" height=\"7.5\" rx=\".5\" ry=\".5\"/><path d=\"M23 4h-9.75a.5.5 0 00-.5.5v2.927a.5.5 0 00.368.482 4.238 4.238 0 010 8.182.5.5 0 00-.368.482V19.5a.5.5 0 00.5.5H23a1 1 0 001-1v-1.25a.5.5 0 00-.5-.5h-2a1.752 1.752 0 01-1.75-1.75v-7a1.752 1.752 0 011.75-1.75h2a.5.5 0 00.5-.5V5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});