define("ember-svg-jar/inlined/database-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-2</title><path d=\"M21.5 17.034a.25.25 0 00-.408-.194C19.155 18.417 15.5 19.25 12 19.25s-7.156-.833-9.093-2.41a.249.249 0 00-.407.194v1.716c0 2.21 4.254 4 9.5 4s9.5-1.79 9.5-4z\"/><path d=\"M2.5 14.25c0 2.21 4.254 4 9.5 4s9.5-1.79 9.5-4v-1.716a.25.25 0 00-.408-.194C19.155 13.917 15.5 14.75 12 14.75s-7.156-.833-9.093-2.41a.249.249 0 00-.407.194z\"/><path d=\"M12 10.25c-3.5 0-7.156-.833-9.093-2.411a.249.249 0 00-.407.194V9.75c0 2.21 4.254 4 9.5 4s9.5-1.79 9.5-4V8.034a.25.25 0 00-.408-.193C19.154 9.418 15.5 10.25 12 10.25z\"/><ellipse cx=\"12\" cy=\"5.25\" rx=\"9.5\" ry=\"4\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});