define("ember-svg-jar/inlined/indent-left-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>indent-left-alternate</title><path d=\"M20 20h-6.5a1 1 0 100 2H20a1 1 0 000-2zM23 15.5h-9.5a1 1 0 100 2H23a1 1 0 100-2zM13.5 13H20a1 1 0 000-2h-6.5a1 1 0 000 2zM13.5 8.5H23a1 1 0 100-2h-9.5a1 1 0 100 2zM13.5 4H20a1 1 0 000-2h-6.5a1 1 0 000 2zM10 0a1 1 0 00-1 1v22a1 1 0 002 0V1a1 1 0 00-1-1zM3.7 8.647a.751.751 0 00-1.2.6v1.5a.25.25 0 01-.25.25H1a1 1 0 000 2h1.249a.25.25 0 01.25.25v1.5a.748.748 0 001.2.6L7.366 12.6a.751.751 0 000-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});