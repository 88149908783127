define("ember-svg-jar/inlined/earpods-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earpods-right</title><path d=\"M23.8 14.512a7.716 7.716 0 00-15.016 3.554 7.717 7.717 0 0012.958 3.677 7.749 7.749 0 002.058-7.231zm-3.473 5.817a5.708 5.708 0 111.522-5.378 5.715 5.715 0 01-1.521 5.378z\"/><path d=\"M18.558 20.065a1 1 0 00.343-1.371l-.93-1.549a.251.251 0 01.067-.33 2.458 2.458 0 00-1.454-4.44h-1.458a1 1 0 00-1 1v5.833a1 1 0 002 0v-.349a.251.251 0 01.465-.128l.595.992a1 1 0 001.372.342zm-1.515-5.232a.46.46 0 01-.459.459h-.3a.162.162 0 01-.162-.162v-.505a.25.25 0 01.25-.25h.208a.459.459 0 01.463.458zM10.613 9.015A1.5 1.5 0 0011 8.007V2.993a1.5 1.5 0 00-.388-1.008A6.218 6.218 0 006 0a5.773 5.773 0 00-6 5.5v17A1.5 1.5 0 001.5 24h1A1.5 1.5 0 004 22.5V11.338a.5.5 0 01.608-.488A6.445 6.445 0 006 11a6.218 6.218 0 004.613-1.985zM9 5.5a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});