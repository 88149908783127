define("ember-svg-jar/inlined/cash-payment-bag-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-payment-bag-2</title><path d=\"M19.388.974a.5.5 0 00-.447-.724h-5.382a.5.5 0 00-.447.724l1.069 2.138a.249.249 0 00.223.138H18.1a.249.249 0 00.224-.138zM19.31 14.031a.279.279 0 00-.174-.092.251.251 0 00-.187.061.982.982 0 01-.664.25H11.75a.837.837 0 01-.1-.005l-.994-.1a.249.249 0 00-.218.089 8.6 8.6 0 00-2.188 5.287c0 3.122 3.6 4.229 6.682 4.229 3.145 0 6.853-1.107 6.818-4.229a9.445 9.445 0 00-2.44-5.49zm-4.729 5.153C13.767 18.637 13 18.12 13 17.313a1.215 1.215 0 011.055-1.334.233.233 0 00.2-.229.75.75 0 011.5 0 .188.188 0 00.187.188h.313a.75.75 0 010 1.5h-.758a.25.25 0 00-.14.457l.067.045C16.231 18.487 17 19 17 19.813a1.258 1.258 0 01-1.055 1.328.238.238 0 00-.2.234.75.75 0 01-1.5 0 .187.187 0 00-.188-.187h-.307a.75.75 0 010-1.5h.759a.251.251 0 00.14-.458z\"/><path d=\"M11.737 13.249h6.414a.25.25 0 00.208-.111l.655-.982a1 1 0 00.116-.871l-.19-.572a1 1 0 01.116-.871l.359-.538a1 1 0 000-1.11l-.26-.39a1 1 0 010-1.11l.26-.39a1 1 0 000-1.11l-.333-.5a1 1 0 00-.832-.445H13.4a.251.251 0 01-.223-.138l-.919-1.839a.251.251 0 00-.336-.111l-1.874.939a1 1 0 00-.447.447L8.435 5.879a1 1 0 01-.578.5l-.936.312a.25.25 0 00-.171.237v5.594a.25.25 0 00.225.249zM4.25 6.25H2.5a.25.25 0 00-.25.25V13a.25.25 0 00.25.25h1.75a1 1 0 001-1v-5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});