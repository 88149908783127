define("ember-svg-jar/inlined/soft-drinks-can-energy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>soft-drinks-can-energy</title><path d=\"M7 6h10a1 1 0 000-2h-6.15a.5.5 0 01-.429-.243L9.358 1.985a1.006 1.006 0 00-1.372-.342 1 1 0 00-.344 1.372l.364.606A.25.25 0 017.792 4H7a1 1 0 000 2z\"/><circle cx=\"12.25\" cy=\".75\" r=\".75\"/><circle cx=\"14.75\" cy=\"2\" r=\".75\"/><circle cx=\"12.25\" cy=\"3\" r=\".75\"/><path d=\"M17.914 9.586l-1.765-1.924a.5.5 0 00-.369-.162H8.22a.5.5 0 00-.369.162L6.086 9.586A2 2 0 005.5 11v9.379a5.122 5.122 0 00.82 2.779A1.933 1.933 0 007.955 24h8.089a1.936 1.936 0 001.637-.843 5.118 5.118 0 00.819-2.778V11a2 2 0 00-.586-1.414zm-2.592 6.149l-3.943 4.591A.5.5 0 0110.5 20v-3a.5.5 0 00-.5-.5h-.75a.75.75 0 01-.572-1.235l3.943-4.591A.5.5 0 0113.5 11v3a.5.5 0 00.5.5h.75a.75.75 0 01.572 1.235z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});