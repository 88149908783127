define("ember-svg-jar/inlined/armchair-modern", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>armchair-modern</title><path d=\"M5.313 2.752l2.673 8.4a.5.5 0 00.476.348h7.076a.5.5 0 00.476-.348l2.673-8.4a.194.194 0 00-.029-.173.192.192 0 00-.158-.079h-13a.191.191 0 00-.156.079.194.194 0 00-.031.173z\"/><path d=\"M23.5 3.733a1.733 1.733 0 00-3.384-.525L17.3 12.047A1.366 1.366 0 0116 13H8a1.366 1.366 0 01-1.3-.953L3.884 3.208A1.738 1.738 0 00.563 4.227l4.428 15.942A2.5 2.5 0 007.4 22h9.2a2.5 2.5 0 002.409-1.831l4.428-15.942a1.741 1.741 0 00.063-.494z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});