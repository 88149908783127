define("ember-svg-jar/inlined/video-game-logo-xbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-logo-xbox</title><path d=\"M19 2.5a5.006 5.006 0 00-5 5c0 .114 0 .226.012.338a.508.508 0 01-.118.358l-5.212 6.139a.5.5 0 01-.419.175 3.433 3.433 0 00-.527 0 .5.5 0 01-.455-.222l-1.455-2.2a.5.5 0 01-.047-.465A3 3 0 102.913 13.5a.5.5 0 01.405.226L4.809 16a.5.5 0 01.032.49 3.5 3.5 0 106.613 2.074.5.5 0 01.168-.3l6.605-5.664a.5.5 0 01.366-.119c.134.011.27.017.407.017a5 5 0 000-10zm-16 9a1 1 0 111-1 1 1 0 01-1 1zm5 8A1.5 1.5 0 119.5 18 1.5 1.5 0 018 19.5zm11-10a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});