define("ember-svg-jar/inlined/farming-wheat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>farming-wheat</title><path d=\"M12.168 7.5a4.57 4.57 0 012-1.422.5.5 0 00.332-.434v-.145a6.078 6.078 0 00-1.53-3.141 1 1 0 01-.22-.626V.75a.75.75 0 00-1.5 0v.985a.992.992 0 01-.22.625A6.08 6.08 0 009.5 5.5c0 .048 0 .1.006.146a.5.5 0 00.332.434 4.558 4.558 0 011.994 1.42.218.218 0 00.336 0z\"/><path d=\"M8.088 10.042a10.42 10.42 0 012.531.906.249.249 0 00.381-.213 4.993 4.993 0 01.262-1.613 1 1 0 00-.176-.955 3.98 3.98 0 00-1.548-1.148l-.933-.283a.5.5 0 01-.355-.478V3.25a.75.75 0 00-1.5 0v3.9a4.356 4.356 0 00.824 2.514 1 1 0 00.514.378zM16.5 2.5a.75.75 0 00-.75.75v3.008a.5.5 0 01-.355.478l-.933.283a4 4 0 00-2.382 4.493.251.251 0 00.422.127 6.848 6.848 0 013.4-1.587 1 1 0 00.512-.373 4.362 4.362 0 00.833-2.528V3.25a.75.75 0 00-.747-.75zM8.085 14.547a10.454 10.454 0 012.536.9.249.249 0 00.379-.208 5.011 5.011 0 01.262-1.616 1.006 1.006 0 00-.175-.954 3.991 3.991 0 00-1.551-1.15l-2.312-.7a.25.25 0 00-.31.163 3.594 3.594 0 00.669 3.2 1.006 1.006 0 00.502.365zM16.776 10.818l-2.313.7a4 4 0 00-2.381 4.491.249.249 0 00.42.127 6.913 6.913 0 013.407-1.582 1 1 0 00.5-.362 3.6 3.6 0 00.676-3.212.25.25 0 00-.309-.162zM9.538 16.019l-2.312-.7a.25.25 0 00-.31.163c-.64 1.982.967 4.182 2.93 5l.887.37a.25.25 0 00.342-.275 5 5 0 01.268-2.676.5.5 0 00-.054-.466 3.981 3.981 0 00-1.751-1.416zM16.774 15.318l-2.312.7A4 4 0 0012 19.79v3.46a.75.75 0 001.5 0v-2.163a.5.5 0 01.308-.462l.346-.144c1.963-.818 3.57-3.018 2.93-5a.249.249 0 00-.31-.163z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});