define("ember-svg-jar/inlined/science-momentum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-momentum</title><path d=\"M16.509 6.381A.25.25 0 0116.722 6H23a1 1 0 000-2H1a1 1 0 000 2h1a.5.5 0 01.5.5v6.292a.5.5 0 01-.312.464 3.5 3.5 0 104.577 4.506.252.252 0 01.47 0 3.5 3.5 0 104.577-4.506.5.5 0 01-.312-.464V6.5A.5.5 0 0112 6h1.52a.734.734 0 01.629.358l3.56 5.806a.5.5 0 01-.024.558 3.5 3.5 0 105.8.252 3.508 3.508 0 00-3.561-1.623.5.5 0 01-.508-.231zM9.5 12.792a.5.5 0 01-.312.464 3.518 3.518 0 00-1.956 1.99.248.248 0 01-.464 0 3.518 3.518 0 00-1.956-1.99.5.5 0 01-.312-.464V6.5A.5.5 0 015 6h4a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});