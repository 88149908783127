define("ember-svg-jar/inlined/ecology-plant-deal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ecology-plant-deal</title><path d=\"M7.6 9.944a2.228 2.228 0 001.2-.712.5.5 0 01.787.065 17.613 17.613 0 011.762 3.366.5.5 0 00.95-.019A14.985 14.985 0 0113.42 10.1a6.87 6.87 0 012.667-2.979.5.5 0 11.447.895 6.182 6.182 0 00-2.206 2.507.5.5 0 00.231.7 2.708 2.708 0 002.981-.923 6.618 6.618 0 00.865-6.089.5.5 0 00-.755-.191 5.787 5.787 0 01-2.264.972 6.261 6.261 0 00-2.042.807A2.506 2.506 0 0012.091 7.8a1.987 1.987 0 00.435 1.22.5.5 0 01.037.548c-.21.373-.392.738-.547 1.074a.249.249 0 01-.449.01 22.425 22.425 0 00-5.846-7.021.5.5 0 01.62-.785 23.378 23.378 0 013.893 3.919.5.5 0 00.8-.01 2.4 2.4 0 00.457-1.048 3.453 3.453 0 00-1.229-2.9A6.113 6.113 0 007 1.321 7.912 7.912 0 013.894.1a.5.5 0 00-.754.2c-1.093 2.425-.233 6.67 1.23 8.186.846.876 2.044 1.703 3.23 1.458zM19.854 14.748h-.46a2.017 2.017 0 01-.578-.085l-3.381-1.02a2.442 2.442 0 00-1.891.1c-.8.388-1.342.649-1.644.8a.5.5 0 01-.486-.027 2.186 2.186 0 00-1.17-.335 28.9 28.9 0 00-3.955 1.03.5.5 0 01-.46-.1 1.488 1.488 0 00-.975-.362h-1a1 1 0 00-1 1v5.5a1 1 0 001 1h1a1.5 1.5 0 001.169-.563.5.5 0 01.39-.187h1.331a.5.5 0 01.349.142l1.929 1.879a1.434 1.434 0 001.072.48 1.416 1.416 0 00.9-.322l2.44-1.96a1.006 1.006 0 01.627-.22h2.493a.5.5 0 01.433.25 1 1 0 00.866.5h1a1.5 1.5 0 001.5-1.5v-4.5a1.5 1.5 0 00-1.499-1.5zm-5.592 4.986a.5.5 0 01-.185.35L11.45 22.2a.5.5 0 01-.663-.031l-2.012-1.958A.746.746 0 008.252 20h-1.4a.5.5 0 01-.5-.5v-2.369a.5.5 0 01.367-.482c2.177-.6 3.1-.856 3.407-.938a.393.393 0 01.49.43 1.955 1.955 0 001.164 2.043 1.985 1.985 0 001.1.137.5.5 0 01.454.157l.8.881a.5.5 0 01.128.375zm3.592-.236a.5.5 0 01-.5.5H16.5a.5.5 0 01-.5-.428 1.543 1.543 0 00-.321-.737l-.724-.8a.249.249 0 01.06-.384.751.751 0 00-.6-1.365l-1.7.526a.45.45 0 01-.37-.81l1.86-.9a.873.873 0 01.757-.029l2.536.766a.5.5 0 01.356.479z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});