define("ember-svg-jar/inlined/asian-food-soup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>asian-food-soup</title><path d=\"M11 13a1 1 0 01-.926-1.377C14.62.465 14.62.465 14.793.293A1 1 0 0116.4 1.438c-.206.479-2.773 6.768-4.473 10.939A1 1 0 0111 13zM14.5 13a1 1 0 01-.831-1.555l6-9a1 1 0 011.664 1.11l-6 9A1 1 0 0114.5 13z\"/><path d=\"M19.708 9.693a1 1 0 00-.416 1.956 10.935 10.935 0 012.1.629.25.25 0 010 .458C20.072 13.307 16.887 14 12 14s-8.084-.7-9.4-1.267a.251.251 0 010-.459 19.9 19.9 0 015.975-1.148 1 1 0 00-.152-1.994C4.581 9.425 0 10.247 0 12.5A11.758 11.758 0 0012 24a11.758 11.758 0 0012-11.5c0-1.294-1.364-2.186-4.292-2.807z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});