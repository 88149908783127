define("ember-svg-jar/inlined/monitor-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-star</title><path d=\"M9.892 17.624A2.716 2.716 0 0112.26 13.5h1.516a.252.252 0 00.23-.151l.648-1.5a.25.25 0 00-.23-.349H2.5A.5.5 0 012 11V2.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v7.025a.251.251 0 00.355.228A2.724 2.724 0 0117.5 9.5a1.507 1.507 0 01.214.017.254.254 0 00.2-.058A.251.251 0 0018 9.27V2a2 2 0 00-2-2H2a2 2 0 00-2 2v10.5a2 2 0 002 2h5.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5H5a1 1 0 000 2h4.677a.25.25 0 00.215-.377z\"/><path d=\"M23.61 17.141L21.4 19.11a.251.251 0 00-.063.287l1.227 2.814a1.26 1.26 0 01-.32 1.45 1.3 1.3 0 01-.86.34 1.4 1.4 0 01-.65-.17l-3.111-1.751a.249.249 0 00-.245 0l-3.118 1.751a1.331 1.331 0 01-.639.17 1.3 1.3 0 01-.861-.34 1.26 1.26 0 01-.32-1.45l1.228-2.811a.251.251 0 00-.063-.287l-2.194-1.959a1.266 1.266 0 01-.321-1.39A1.228 1.228 0 0112.26 15h2.5a.249.249 0 00.229-.151l1.345-3.119A1.311 1.311 0 0117.5 11a1.285 1.285 0 011.15.7c0 .01.011.02.011.03l1.344 3.119a.252.252 0 00.23.151h2.5a1.24 1.24 0 011.171.76 1.278 1.278 0 01-.296 1.381z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});