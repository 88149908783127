define("ember-svg-jar/inlined/add-circle-bold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>add-circle-bold</title><path d=\"M.5 12A11.5 11.5 0 1012 .5 11.513 11.513 0 00.5 12zm5-.5a1 1 0 011-1h3.75a.25.25 0 00.25-.25V6.5a1 1 0 011-1h1a1 1 0 011 1v3.75a.25.25 0 00.25.25h3.75a1 1 0 011 1v1a1 1 0 01-1 1h-3.75a.25.25 0 00-.25.25v3.75a1 1 0 01-1 1h-1a1 1 0 01-1-1v-3.75a.25.25 0 00-.25-.25H6.5a1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});