define("ember-svg-jar/inlined/study-desk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>study-desk</title><path d=\"M24 14.108a1 1 0 00-1-1H1a1 1 0 000 2h.42a.5.5 0 01.491.6L.77 21.411a1 1 0 101.96.393l1.259-6.294a.5.5 0 01.491-.4h15.04a.5.5 0 01.491.4l1.259 6.29a1 1 0 101.96-.393l-1.141-5.705a.5.5 0 01.491-.6H23a1 1 0 001-.994zM21.121 10.358h-.75a.25.25 0 01-.25-.25V5.621a3.767 3.767 0 00-3.764-3.763 1 1 0 00-.347.062 3.6 3.6 0 00-4.364.463.5.5 0 000 .707l4.243 4.242a.5.5 0 00.707 0 3.5 3.5 0 00.988-2.977 1.759 1.759 0 01.537 1.266v4.487a.25.25 0 01-.25.25h-.75a1 1 0 000 2h4a1 1 0 000-2zM2.75 12.108H10a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5H2.75a1.5 1.5 0 000 3zM2.75 8.108H10a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5H2.75a1.5 1.5 0 000 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});