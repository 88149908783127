define("ember-svg-jar/inlined/arrow-dot-corner-right-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-dot-corner-right-1</title><circle cx=\"6\" cy=\"10\" r=\"1.5\"/><circle cx=\"10\" cy=\"10\" r=\"1.5\"/><circle cx=\"10\" cy=\"14\" r=\"1.5\"/><circle cx=\"14\" cy=\"10\" r=\"1.5\"/><circle cx=\"14\" cy=\"14\" r=\"1.5\"/><circle cx=\"18\" cy=\"14\" r=\"1.5\"/><circle cx=\"18\" cy=\"10\" r=\"1.5\"/><circle cx=\"22\" cy=\"14\" r=\"1.5\"/><circle cx=\"22\" cy=\"10\" r=\"1.5\"/><circle cx=\"6\" cy=\"22\" r=\"1.5\"/><circle cx=\"10\" cy=\"18\" r=\"1.5\"/><circle cx=\"10\" cy=\"22\" r=\"1.5\"/><circle cx=\"14\" cy=\"18\" r=\"1.5\"/><circle cx=\"14\" cy=\"22\" r=\"1.5\"/><circle cx=\"18\" cy=\"22\" r=\"1.5\"/><circle cx=\"18\" cy=\"18\" r=\"1.5\"/><circle cx=\"22\" cy=\"22\" r=\"1.5\"/><circle cx=\"22\" cy=\"18\" r=\"1.5\"/><circle cx=\"2\" cy=\"6\" r=\"1.5\"/><circle cx=\"6\" cy=\"6\" r=\"1.5\"/><circle cx=\"6\" cy=\"2\" r=\"1.5\"/><circle cx=\"10\" cy=\"6\" r=\"1.5\"/><circle cx=\"22\" cy=\"6\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});