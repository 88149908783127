define("ember-svg-jar/inlined/smart-house-phone-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-house-phone-sync</title><path d=\"M18 5v1.25a.25.25 0 01-.25.25H16.5a.5.5 0 00-.354.853l2.5 2.5a.5.5 0 00.708 0l2.5-2.5A.5.5 0 0021.5 6.5h-1.25a.25.25 0 01-.25-.25V5a4 4 0 00-4-4h-2.5a1 1 0 000 2H16a2 2 0 012 2zM13 18a1 1 0 00-1-1H8a2 2 0 01-2-2v-1.25a.25.25 0 01.25-.25H7.5a.5.5 0 00.354-.854l-2.5-2.5a.5.5 0 00-.708 0l-2.5 2.5a.5.5 0 00.354.854h1.25a.25.25 0 01.25.25V15a4 4 0 004 4h4a1 1 0 001-1zM22.526 12h-5.052A1.493 1.493 0 0016 13.508v8.983A1.493 1.493 0 0017.474 24h5.052A1.493 1.493 0 0024 22.491v-8.983A1.493 1.493 0 0022.526 12zM22 20.5a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-5.982a.5.5 0 01.5-.5h3a.5.5 0 01.5.5zM3 8.5a.5.5 0 00.5-.5V6.75a1.25 1.25 0 012.5 0V8a.5.5 0 00.5.5h1A.5.5 0 008 8V4.75a.25.25 0 01.25-.25h.911a.338.338 0 00.318-.219.329.329 0 00-.1-.369L4.969.079a.344.344 0 00-.438 0L.12 3.912a.329.329 0 00-.1.369.338.338 0 00.319.219h.911a.25.25 0 01.25.25V8a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});