define("ember-svg-jar/inlined/social-bookmark-delicious", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-bookmark-delicious</title><path d=\"M22.75 0H12a1.25 1.25 0 00-1.25 1.25v9.5h-9.5A1.25 1.25 0 000 12v10.75A1.25 1.25 0 001.25 24H12a1.25 1.25 0 001.25-1.25v-9.5h9.5A1.25 1.25 0 0024 12V1.25A1.25 1.25 0 0022.75 0zm-12 21.5H2.5v-8.25h8.25zM21.5 10.75h-8.25V2.5h8.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});