define("ember-svg-jar/inlined/arrow-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-right</title><path d=\"M23.987 12a2.411 2.411 0 00-.814-1.8L11.994.361a1.44 1.44 0 00-1.9 2.162l8.637 7.6a.25.25 0 01-.165.437H1.452a1.44 1.44 0 000 2.88h17.111a.251.251 0 01.165.438l-8.637 7.6a1.44 1.44 0 101.9 2.161L23.172 13.8a2.409 2.409 0 00.815-1.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});