define("ember-svg-jar/inlined/shipment-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-clock</title><path d=\"M14.652 9.356a.25.25 0 00.171-.237V5.5a.25.25 0 00-.25-.25H.348A.25.25 0 00.1 5.5v8.83a1.474 1.474 0 001.47 1.47h7.294a.249.249 0 00.244-.2 8.37 8.37 0 015.544-6.244zM8.2 3.529a.25.25 0 00.25.25h5.239a.25.25 0 00.177-.427L11.04.529A1.484 1.484 0 0010 .1H8.447a.25.25 0 00-.25.25zM6.474 3.779a.25.25 0 00.25-.25V.347A.25.25 0 006.474.1H4.922a1.484 1.484 0 00-1.041.429L1.058 3.352a.25.25 0 00.177.427z\"/><path d=\"M17.276 10.65a6.627 6.627 0 106.624 6.626 6.633 6.633 0 00-6.624-6.626zm0 11.289a4.663 4.663 0 114.663-4.663 4.669 4.669 0 01-4.663 4.663z\"/><path d=\"M16.294 14.822v2.454a.982.982 0 00.982.982h1.963a.982.982 0 100-1.963h-.731a.249.249 0 01-.25-.25v-1.223a.982.982 0 00-1.964 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});