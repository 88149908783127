define("ember-svg-jar/inlined/spirits", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>spirits</title><path d=\"M8.385 2.316A1 1 0 009.333 3h5.334a1 1 0 00.948-.684l.334-1A1 1 0 0015 0H9a1 1 0 00-.949 1.316zM15.575 11.1a.5.5 0 00-.476-.347H8.9a.5.5 0 00-.291.907l3.1 2.214a.5.5 0 00.582 0l3.1-2.214a.5.5 0 00.184-.56zM4.89 19.157l5.25-3.75a.5.5 0 000-.814l-5.25-3.75a.506.506 0 00-.29-.093H2.035a.5.5 0 00-.494.427 3.743 3.743 0 00-.035.765 19.974 19.974 0 001.735 7.242.11.11 0 00.1.066H4.6a.506.506 0 00.29-.093zM3.752 9.25h16.492a.5.5 0 00.25-.933c-.227-.131.217.065-5.317-2.264A1.17 1.17 0 0114.5 5a.5.5 0 00-.5-.5h-4a.5.5 0 00-.5.5 1.158 1.158 0 01-.648 1.039C3.3 8.378 3.708 8.2 3.5 8.316a.5.5 0 00.249.934zM22.459 11.177a.5.5 0 00-.494-.427H19.4a.5.5 0 00-.291.093l-5.25 3.75a.5.5 0 000 .814l5.25 3.75a.5.5 0 00.291.093h1.257a.11.11 0 00.1-.066 20 20 0 001.735-7.242 3.747 3.747 0 00-.033-.765zM11.709 16.129l-3.1 2.214a.5.5 0 00.291.907h6.2a.5.5 0 00.291-.907l-3.1-2.214a.5.5 0 00-.582 0zM19.13 20.75H4.87a.5.5 0 00-.435.748c.282.492.579.982.892 1.476A2.245 2.245 0 007.2 24h9.6a2.243 2.243 0 001.875-1.026c.313-.494.61-.984.892-1.476a.5.5 0 00-.435-.748z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});