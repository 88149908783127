define("ember-svg-jar/inlined/surveillance-cctv-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>surveillance-cctv-1</title><path d=\"M19.37 5.53l-1.53.412a.5.5 0 00-.365.412A2.5 2.5 0 0115 8.5H5.5A.5.5 0 005 9v.5A2.5 2.5 0 007.5 12h1.25a.5.5 0 01.5.5v4.288a.5.5 0 01-.421.494l-5.75.918a.5.5 0 01-.579-.493v-.957a1.25 1.25 0 00-2.5 0v6a1.25 1.25 0 002.5 0v-1.5a.5.5 0 01.421-.494l7.01-1.117h.021a2.255 2.255 0 001.8-2.2V12.5a.5.5 0 01.5-.5H19a1 1 0 001-1V6.013a.5.5 0 00-.63-.483z\"/><path d=\"M23.788.385A.991.991 0 0023 0H7a3 3 0 00-3 3v3a1 1 0 001 1h10a1 1 0 001-1v-.733a.5.5 0 01.37-.483l6.413-1.724a.953.953 0 00.674-.641l.513-1.176a.994.994 0 00-.182-.858zM22 6a1 1 0 00-1 1v2a1 1 0 002 0V7a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});