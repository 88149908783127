define("ember-svg-jar/inlined/modern-tv-3d-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-tv-3d-sync</title><path d=\"M23.678 8.033A12.576 12.576 0 0012 0a12.4 12.4 0 00-7.795 2.726.249.249 0 01-.333-.019L2.854 1.689A.5.5 0 002 2.043V6.5a.5.5 0 00.5.5h4.457a.5.5 0 00.354-.854l-1.3-1.3a.249.249 0 01.027-.377A9.915 9.915 0 0112 2.5a10.063 10.063 0 019.344 6.427 1.264 1.264 0 001.614.72 1.249 1.249 0 00.72-1.614zM21.5 17h-4.457a.5.5 0 00-.353.854l1.3 1.3a.252.252 0 01.072.2.247.247 0 01-.1.182A9.909 9.909 0 0112 21.5a10.063 10.063 0 01-9.344-6.427 1.25 1.25 0 00-2.334.894A12.576 12.576 0 0012 24a12.4 12.4 0 007.8-2.726.247.247 0 01.332.019l1.019 1.018a.5.5 0 00.849-.354V17.5a.5.5 0 00-.5-.5z\"/><path d=\"M9.261 14.259h-2.5a1 1 0 100 2h2.5a2.5 2.5 0 002.1-3.863.248.248 0 010-.274 2.5 2.5 0 00-2.1-3.863h-2.5a1 1 0 100 2h2.5a.5.5 0 010 1h-1.5a1 1 0 100 2h1.5a.5.5 0 010 1zM15.261 8.259h-1a1 1 0 00-1 1v6a1 1 0 001 1h1a4 4 0 000-8zm.286 5.979a.249.249 0 01-.286-.247v-3.464a.248.248 0 01.086-.189.251.251 0 01.2-.059 2 2 0 010 3.959z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});