define("ember-svg-jar/inlined/like-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>like-2-alternate</title><path d=\"M23.87 12.247A3 3 0 0021 8.373h-4.9a1 1 0 01-.967-1.256l.881-3.343a2.712 2.712 0 00-4.839-2.253L6.282 8.449a1 1 0 01-.818.424H3.409a1.5 1.5 0 00-1.409-1H.5a.5.5 0 00-.5.5v14a.5.5 0 00.5.5H2a1.5 1.5 0 001.5-1.5v-.627c5.121 2.576 6.242 2.881 9.711 2.881.351 0 2.334-.02 3.054-.02 2.8 0 4.665-1.613 5.7-4.958l1.9-6.357c-.004-.015.001-.03.005-.045zm-1.91-.593l-.009.028-1.909 6.4c-.76 2.469-1.89 3.521-3.776 3.521-.728 0-1.367.007-1.94.013-4.175.046-4.587.046-10.551-2.975A.5.5 0 013.5 18.2v-6.827a.5.5 0 01.5-.5h1.464A3.006 3.006 0 007.916 9.6l4.889-6.927a.739.739 0 011.138-.039.712.712 0 01.135.627L13.2 6.607a3 3 0 002.9 3.766H21a1 1 0 01.959 1.281z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});