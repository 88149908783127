define("ember-svg-jar/inlined/plane-trip-cocktail-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-trip-cocktail-service</title><path d=\"M7.8 2.458l1.537.636a.249.249 0 00.195 0l3.225-1.4a.25.25 0 000-.46L10.016.1A.935.935 0 009.3.1L7.8.723a.939.939 0 000 1.735z\"/><path d=\"M5.865 8.7a.938.938 0 00.664.277c.117 0 4.1-1.578 4.2-1.611s.166.051.128.158l-.283.782a.5.5 0 00.471.67h2.617a.5.5 0 00.449-.281L15.7 5.452a.26.26 0 01.138-.125l3.1-1.155a2.09 2.09 0 001.056-2.743A1.906 1.906 0 0017.451.6L7.978 4.724a.247.247 0 01-.211-.006L6.112 3.89a.941.941 0 00-.79-.023L3.6 4.606a.939.939 0 00-.3 1.528zM22.5 9.971h-21a1 1 0 000 2h21a1 1 0 000-2zM14.96 13.971H8.04a.915.915 0 00-.684.317 1.357 1.357 0 00-.31 1.043c.261 2.446 1.575 4.326 3.288 4.924a.241.241 0 01.166.228v1.239a.25.25 0 01-.249.249H9.5a1 1 0 000 2h4a1 1 0 100-2h-.751a.25.25 0 01-.249-.249v-1.239a.241.241 0 01.166-.228c1.713-.6 3.027-2.478 3.288-4.924a1.357 1.357 0 00-.31-1.043.915.915 0 00-.684-.317z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});