define("ember-svg-jar/inlined/body-care-eye-mask", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>body-care-eye-mask</title><path d=\"M14.161 18.538a2.437 2.437 0 01-3.44 0 .75.75 0 10-1.06 1.062 3.938 3.938 0 005.56 0 .75.75 0 00-1.06-1.061zM9.438 10.75a3 3 0 103 3 3 3 0 00-3-3zm-.893 2.936l-.078.29a.5.5 0 01-.483.37.485.485 0 01-.13-.018.5.5 0 01-.352-.612l.078-.29a.5.5 0 11.965.26zm1.831 1.758l-.29.077a.482.482 0 01-.129.017.5.5 0 01-.129-.983l.29-.077a.5.5 0 01.612.354.5.5 0 01-.354.612zm.27-2.433a.5.5 0 01-.707 0l-.212-.211a.5.5 0 01.709-.7l.212.213a.5.5 0 01-.002.698z\"/><path d=\"M18.438 13.75a3 3 0 10-3 3 3 3 0 003-3zm-3.893-.064l-.078.29a.5.5 0 01-.483.37.485.485 0 01-.13-.018.5.5 0 01-.352-.612l.078-.29a.5.5 0 11.965.26zm1.831 1.758l-.29.077a.482.482 0 01-.129.017.5.5 0 01-.129-.983l.29-.077a.5.5 0 01.612.354.5.5 0 01-.354.612zm.27-2.433a.5.5 0 01-.707 0l-.212-.213a.5.5 0 01.709-.7l.212.213a.5.5 0 01-.002.7z\"/><path d=\"M20.908 3.769a8.035 8.035 0 00-4.555-2.464.5.5 0 00-.569.641 4.169 4.169 0 01.193 1.246A4.348 4.348 0 0114.765 6.2a.5.5 0 00.168.806 6.5 6.5 0 014 5.99v3a6.5 6.5 0 11-13 0V13A6.516 6.516 0 0112.3 6.507a3.4 3.4 0 002.68-3.315A3.123 3.123 0 0011.937 0a11.131 11.131 0 00-8.192 3.052 10.731 10.731 0 00-2.8 7.959A11.151 11.151 0 005.348 19.8a.5.5 0 01.135.152A8 8 0 0020 18.607a.5.5 0 01.092-.168 13.7 13.7 0 002.846-6.869 9.413 9.413 0 00-2.03-7.801z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});