define("ember-svg-jar/inlined/hair-dress-barber", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hair-dress-barber</title><path d=\"M4.375 18.585a.5.5 0 01-.333.472 1 1 0 00-.667.943v1.5a2.5 2.5 0 002.5 2.5h12.25a2.5 2.5 0 002.5-2.5V20a1 1 0 00-.667-.943.5.5 0 01-.333-.471V5.414a.5.5 0 01.333-.471A1 1 0 0020.625 4V2.5a2.5 2.5 0 00-2.5-2.5H5.875a2.5 2.5 0 00-2.5 2.5V4a1 1 0 00.667.943.5.5 0 01.333.471zm13.25-7.413a.5.5 0 01-.334.472L6.708 15.357a.249.249 0 01-.227-.032.251.251 0 01-.106-.2v-2.297a.5.5 0 01.335-.472l10.582-3.713a.25.25 0 01.333.236zM14.808 5a.25.25 0 01.083.486L7.041 8.24a.5.5 0 01-.666-.472V5.5a.5.5 0 01.5-.5zM9.193 19a.25.25 0 01-.083-.486l7.85-2.754a.5.5 0 01.665.472V18.5a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});