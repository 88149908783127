define("ember-svg-jar/inlined/bluetooth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bluetooth</title><path d=\"M18.712 8.215a2.174 2.174 0 00.062-3.156L14.253.577A1.68 1.68 0 0012.4.115a1.681 1.681 0 00-.972 1.64v6.766a.25.25 0 01-.419.184L6.7 4.739a1.25 1.25 0 10-1.695 1.839L10.7 11.81a.249.249 0 010 .368l-5.718 5.243a1.25 1.25 0 101.69 1.842l4.34-3.982a.25.25 0 01.419.184v6.78a1.682 1.682 0 00.973 1.641 1.468 1.468 0 00.57.114 1.835 1.835 0 001.28-.579l4.52-4.479a2.177 2.177 0 00-.059-3.156l-3.922-3.606a.251.251 0 010-.369zm-2.127 9.008a.253.253 0 01.08.18.245.245 0 01-.074.182L14.357 19.8a.25.25 0 01-.426-.178v-4.268a.25.25 0 01.419-.184zm-2.235-8.4a.251.251 0 01-.419-.184V4.377a.25.25 0 01.426-.177l2.233 2.213a.25.25 0 01-.007.362z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});