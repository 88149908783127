define("ember-svg-jar/inlined/products-purse-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>products-purse-2</title><path d=\"M19.263 9.007a.25.25 0 00-.263.25V10.5a.75.75 0 01-1.5 0v-5a5.5 5.5 0 00-11 0v5a.75.75 0 01-1.5 0V9.257a.25.25 0 00-.263-.25A5.006 5.006 0 000 14v6.5A3.5 3.5 0 003.5 24h17a3.5 3.5 0 003.5-3.5V14a5.006 5.006 0 00-4.737-4.993zM8.5 5.5a3.5 3.5 0 017 0v3.25a.25.25 0 01-.25.25h-6.5a.25.25 0 01-.25-.25zm12 8.734a.75.75 0 010 1.5H8a.25.25 0 00-.25.25V17a.75.75 0 01-1.5 0v-1.016a.25.25 0 00-.25-.25H3.5a.75.75 0 110-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});