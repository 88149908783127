define("ember-svg-jar/inlined/renewable-energy-water-dam", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>renewable-energy-water-dam</title><path d=\"M23 15.972a3.745 3.745 0 01-2.9-1.646 1.055 1.055 0 00-1.546.023 3.72 3.72 0 01-2.884 1.623 3.743 3.743 0 01-2.9-1.646 1.055 1.055 0 00-1.547.024 6.387 6.387 0 01-.875.893 1 1 0 00-.205 1.333.985.985 0 001.421.251c.035-.028.069-.055.1-.083a.532.532 0 01.657-.017 5.125 5.125 0 006.7 0 .5.5 0 01.637 0A5.126 5.126 0 0023 17.972a1 1 0 000-2zM9.064 12.42a5.145 5.145 0 002.593-1.174.538.538 0 01.66-.019 5.125 5.125 0 006.7 0 .5.5 0 01.637 0A5.126 5.126 0 0023 12.472a1 1 0 000-2 3.745 3.745 0 01-2.9-1.646 1.055 1.055 0 00-1.546.023 3.72 3.72 0 01-2.884 1.623 3.743 3.743 0 01-2.9-1.646 1.055 1.055 0 00-1.547.024 4.033 4.033 0 01-2.436 1.6.994.994 0 00.283 1.968zM5.5 4.487a1 1 0 00-1-.987H1a1 1 0 00-1 1v15a1 1 0 001 1h8.249a1 1 0 00.739-1.673c-1.312-1.44-4.375-5.72-4.488-14.34z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});