define("ember-svg-jar/inlined/tree-log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tree-log</title><path d=\"M20.031 7.121a.5.5 0 01-.225-.634l.44-1.051a1.941 1.941 0 00-.175-1.831 2.007 2.007 0 00-2.9-.387L14 5.989a1 1 0 01-.658.247H7.5a7.5 7.5 0 000 15h9a7.5 7.5 0 003.531-14.115zM7.5 19.736a6 6 0 116-6 6.006 6.006 0 01-6 6zm10-2.5h-2.25a.75.75 0 010-1.5h2.25a.75.75 0 010 1.5zm3-4.5h-3.75a.75.75 0 010-1.5h3.75a.75.75 0 010 1.5z\"/><path d=\"M7.5 9.236a4.5 4.5 0 104.5 4.5 4.505 4.505 0 00-4.5-4.5zm0 7.5a3 3 0 113-3 3 3 0 01-3 3z\"/><circle cx=\"7.5\" cy=\"13.736\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});