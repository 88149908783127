define("ember-svg-jar/inlined/ceiling-ball-chandelier-retro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ceiling-ball-chandelier-retro</title><path d=\"M22.975 6.764a.558.558 0 00-.949 0 6.324 6.324 0 00-1.015 2.246 1.47 1.47 0 00.366.971.5.5 0 01.123.328v.94a4.243 4.243 0 01-7.822 2.287.25.25 0 01.266-.379 3.569 3.569 0 00.806.092A3.754 3.754 0 0018.5 9.5V7.811a.5.5 0 01.123-.328 1.474 1.474 0 00.366-.972 6.337 6.337 0 00-1.014-2.246.558.558 0 00-.949 0 6.353 6.353 0 00-1.015 2.247 1.47 1.47 0 00.366.971.5.5 0 01.123.328V9.5a1.75 1.75 0 01-3.5 0V4.977a.5.5 0 00-.547-.5 4.855 4.855 0 01-.906 0 .5.5 0 00-.547.5V9.5a1.75 1.75 0 01-3.5 0V7.811a.5.5 0 01.123-.328 1.474 1.474 0 00.366-.972 6.349 6.349 0 00-1.014-2.247.559.559 0 00-.95 0 6.337 6.337 0 00-1.014 2.247 1.47 1.47 0 00.366.971.5.5 0 01.123.328V9.5a3.754 3.754 0 003.75 3.75 3.569 3.569 0 00.806-.092.25.25 0 01.266.379A4.243 4.243 0 012.5 11.25v-.939a.5.5 0 01.123-.328 1.474 1.474 0 00.366-.972 6.349 6.349 0 00-1.014-2.247.559.559 0 00-.95 0A6.337 6.337 0 00.011 9.011a1.47 1.47 0 00.366.971.5.5 0 01.123.328v.94a6.262 6.262 0 004.629 6.037.5.5 0 01.371.482v2.421a.5.5 0 01-.123.328 1.472 1.472 0 00-.366.971 6.342 6.342 0 001.015 2.246.557.557 0 00.948 0 6.346 6.346 0 001.015-2.247 1.47 1.47 0 00-.366-.971.5.5 0 01-.123-.329v-2.335a.469.469 0 01.383-.461 6.211 6.211 0 002.717-1.227.25.25 0 01.4.2v1.825a.5.5 0 01-.123.328 1.472 1.472 0 00-.366.971 6.346 6.346 0 001.015 2.247.558.558 0 00.949 0 6.337 6.337 0 001.014-2.246 1.47 1.47 0 00-.366-.971.5.5 0 01-.123-.33v-1.827a.25.25 0 01.4-.2 6.22 6.22 0 002.689 1.223.5.5 0 01.407.491V20.2a.5.5 0 01-.123.328 1.452 1.452 0 00-.366.959 6.328 6.328 0 001.015 2.247.558.558 0 00.949 0 6.351 6.351 0 001.014-2.247 1.452 1.452 0 00-.366-.959.5.5 0 01-.119-.328v-2.431a.5.5 0 01.37-.482 6.261 6.261 0 004.63-6.037v-.94a.5.5 0 01.123-.328 1.47 1.47 0 00.366-.971 6.367 6.367 0 00-1.014-2.247zM15.877.751A.5.5 0 0015.445 0h-6.89a.5.5 0 00-.433.751A4.51 4.51 0 0012 3 4.508 4.508 0 0015.877.751z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});