define("ember-svg-jar/inlined/family-baby", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-baby</title><circle cx=\"12.069\" cy=\"3\" r=\"2.5\"/><path d=\"M13.367 17.36c.2.185.24.232 1.417 1.409a.5.5 0 01.031.674l-1.331 1.6a1.5 1.5 0 102.3 1.92l1.331-1.6a3.5 3.5 0 00-.214-4.714l-1.182-1.182a.25.25 0 00-.329-.022l-2.008 1.534a.25.25 0 00-.015.381zM4.082 14.164a1.5 1.5 0 002.118-.141l1.933-2.209a.25.25 0 01.438.164v2.272a.25.25 0 00.25.25h6.5a.25.25 0 00.25-.25v-2.113a.25.25 0 01.438-.165l1.791 2.051a1.5 1.5 0 002.258-1.976l-2.6-2.976a4.5 4.5 0 00-3.388-1.536H9.93a4.5 4.5 0 00-3.386 1.537l-2.6 2.975a1.5 1.5 0 00.138 2.117zM7.162 16.648a3.5 3.5 0 00-.214 4.716l1.33 1.6a1.5 1.5 0 002.3-1.92l-1.331-1.6a.5.5 0 01.031-.673c1.193-1.194 1.209-1.223 1.45-1.42a.252.252 0 000-.392L8.722 15.4a.249.249 0 00-.33.02z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});