define("ember-svg-jar/inlined/video-edit-peel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-edit-peel</title><path d=\"M24 4.5A1.5 1.5 0 0022.5 3h-3.084a.251.251 0 01-.233-.158A4.507 4.507 0 0015 0h-1.5a1 1 0 00-1 1v1.75a.25.25 0 01-.25.25H2a2 2 0 00-2 2v17a2 2 0 002 2h20.5a1.5 1.5 0 001.5-1.5zm-22 1a.5.5 0 01.5-.5h9.75a.25.25 0 01.25.25V16a1 1 0 001 1H15a2.5 2.5 0 010 5H2.5a.5.5 0 01-.5-.5zM15 2a2.5 2.5 0 012.5 2.5v10.817a.25.25 0 01-.122.215.253.253 0 01-.247.006A4.472 4.472 0 0015 15a.5.5 0 01-.5-.5v-12A.5.5 0 0115 2z\"/><path d=\"M9.28 6.72A.75.75 0 008 7.25v1a.25.25 0 01-.25.25h-3.5a.75.75 0 000 1.5h3.5a.25.25 0 01.25.25v1a.75.75 0 001.28.53l2-2a.749.749 0 000-1.06zM10.75 17h-3.5a.25.25 0 01-.25-.25v-1a.75.75 0 00-1.28-.53l-2 2a.749.749 0 000 1.06l2 2A.75.75 0 007 19.75v-1a.25.25 0 01.25-.25h3.5a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});