define("ember-svg-jar/inlined/antique-sling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>antique-sling</title><path d=\"M12.32 13.3l-2.674 2.735a.5.5 0 00.043.739 3.488 3.488 0 01.607.631.5.5 0 00.759.05l2.7-2.755a1 1 0 00-.01-1.41 1.008 1.008 0 00-1.425.01z\"/><path d=\"M22 0a2 2 0 00-2 2v2.048a6.417 6.417 0 01-1.3 3.9 1.51 1.51 0 01-1.2.595 1.508 1.508 0 01-1.2-.6 6.417 6.417 0 01-1.3-3.9V2a2 2 0 00-4 0v2.048a10.493 10.493 0 003.5 7.832A1.5 1.5 0 0115 13v9.5a1.5 1.5 0 001.5 1.5h2a1.5 1.5 0 001.5-1.5V13a1.5 1.5 0 01.5-1.12A10.493 10.493 0 0024 4.048V2a2 2 0 00-2-2zM4.99 12.186a.5.5 0 00.781.1L9.87 8.21a.987.987 0 00.01-1.41 1 1 0 00-1.42-.01l-4.019 4a.5.5 0 00.006.715 3.273 3.273 0 01.543.681zM7.5 17.5A3.5 3.5 0 014 14a2 2 0 00-4 0 7.508 7.508 0 007.5 7.5 2 2 0 000-4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});