define("ember-svg-jar/inlined/hat-architect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hat-architect</title><path d=\"M22.526 13.358a.309.309 0 00-.526.22 2.492 2.492 0 01-.9 1.919c-1.117.934-3.787 2.5-9.1 2.5s-7.985-1.569-9.1-2.5a2.5 2.5 0 01-.9-1.919.309.309 0 00-.526-.22A4.427 4.427 0 000 16.5C0 20.145 5.271 23 12 23s12-2.855 12-6.5a4.427 4.427 0 00-1.474-3.142z\"/><path d=\"M6.688 4.865a.5.5 0 00-.124.633c.36.646 1.466 2.7 5.217 9.539a.249.249 0 00.438 0c3.75-6.837 4.857-8.893 5.217-9.537a.5.5 0 00-.124-.633A8.472 8.472 0 0014.225 3.3.305.305 0 0114 3a2 2 0 00-4 0 .306.306 0 01-.225.295 8.472 8.472 0 00-3.087 1.57zM5.473 6.631a.251.251 0 00-.424-.024A8.459 8.459 0 003.5 11.5v2.078a1 1 0 00.359.767c.727.608 2.647 1.832 6.545 2.1a.251.251 0 00.236-.37zM18.951 6.607a.251.251 0 00-.424.024l-5.167 9.446a.251.251 0 00.236.37c3.9-.27 5.818-1.494 6.545-2.1a1 1 0 00.359-.767V11.5a8.459 8.459 0 00-1.549-4.893z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});