define("ember-svg-jar/inlined/police-man-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-man-1</title><path d=\"M.5 21.394V23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-2.106a2.459 2.459 0 00-.967-1.976C21.106 18.328 17.839 16.5 12 16.5s-9.106 1.828-10.533 2.917A2.462 2.462 0 00.5 21.394zm16.361 1.566l-.609-.407a2.247 2.247 0 01-1-1.872v-.929a.249.249 0 01.25-.25h3a.249.249 0 01.25.25v.929a2.246 2.246 0 01-1 1.872l-.609.407a.251.251 0 01-.278 0zM12 16.5a7.008 7.008 0 007-7V5.937a.248.248 0 01.112-.208 1.466 1.466 0 00.591-.755l.771-2.316a.5.5 0 00-.094-.483.5.5 0 00-.462-.168c-2.324.386-4.059-.374-6.257-1.339-.473-.207-.961-.421-1.472-.631a.5.5 0 00-.378 0c-.511.21-1 .424-1.472.631-2.2.964-3.935 1.724-6.257 1.339a.5.5 0 00-.556.651L4.3 4.974a1.457 1.457 0 00.591.755.25.25 0 01.109.208V9.5a7.008 7.008 0 007 7zm.014-10.415a.251.251 0 01-.278 0l-.609-.407a2.247 2.247 0 01-1-1.872v-.929a.249.249 0 01.25-.25h3a.249.249 0 01.25.25v.929a2.246 2.246 0 01-1 1.872zM12 14.5a5.006 5.006 0 01-5-5V7.792a.249.249 0 01.369-.219A9.691 9.691 0 0012 8.75a9.683 9.683 0 004.631-1.178.25.25 0 01.369.22V9.5a5.006 5.006 0 01-5 5z\"/><circle cx=\"14\" cy=\"10.001\" r=\"1\"/><circle cx=\"10\" cy=\"10.001\" r=\"1\"/><path d=\"M13.077 12.132h-2a.639.639 0 00-.3 1.2 2.3 2.3 0 00.437.181 2.967 2.967 0 00.868.126 3.016 3.016 0 00.868-.125 2.365 2.365 0 00.439-.182.64.64 0 00-.307-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});