define("ember-svg-jar/inlined/toys-lego", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toys-lego</title><path d=\"M12 6.376a.735.735 0 00-.06-.293l-.2-.462a1.5 1.5 0 00-1.555-.905.583.583 0 01-.606-.35 1 1 0 00-1.312-.53l-.92.391a1 1 0 00-.53 1.311.5.5 0 01-.264.655l-.921.391a.5.5 0 01-.656-.265 1 1 0 00-1.307-.529l-.921.391a1 1 0 00-.529 1.311.583.583 0 01-.168.679 1.5 1.5 0 00-.431 1.748l1.367 3.223a1 1 0 001.313.528l7.243-3.07A.751.751 0 0012 9.9z\"/><rect x=\"13\" y=\"8.75\" width=\"11\" height=\"4\" rx=\".5\" ry=\".5\"/><rect x=\"13\" y=\"13.75\" width=\"11\" height=\"4\" rx=\".5\" ry=\".5\"/><rect x=\"13\" y=\"18.75\" width=\"11\" height=\"4\" rx=\".5\" ry=\".5\"/><path d=\"M9.92 16.81a.584.584 0 01-.42-.56 1 1 0 00-1-1h-1a1 1 0 00-1 1 .5.5 0 01-.5.5H5a.5.5 0 01-.5-.5 1 1 0 00-1-1h-1a1 1 0 00-1 1 .583.583 0 01-.42.56A1.5 1.5 0 000 18.25v3.5a1 1 0 001 1h9a1 1 0 001-1v-3.5a1.5 1.5 0 00-1.08-1.44zM22.92 2.81a.584.584 0 01-.42-.56 1 1 0 00-1-1h-1a1 1 0 00-1 1 .5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5 1 1 0 00-1-1h-1a1 1 0 00-1 1 .583.583 0 01-.42.56A1.5 1.5 0 0013 4.25v2.5a1 1 0 001 1h9a1 1 0 001-1v-2.5a1.5 1.5 0 00-1.08-1.44z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});