define("ember-svg-jar/inlined/presentation-board", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>presentation-board</title><path d=\"M.25 5.25A.249.249 0 000 5.5v9.25a1.5 1.5 0 001.5 1.5h7.129a.25.25 0 01.177.426l-5.19 5.19a1.25 1.25 0 001.768 1.767l4.439-4.439a.25.25 0 01.427.177v3.379a1.25 1.25 0 002.5 0v-3.379a.25.25 0 01.427-.177l4.439 4.439a1.25 1.25 0 001.768-1.767l-5.19-5.19a.25.25 0 01.177-.426H22.5a1.5 1.5 0 001.5-1.5V5.5a.249.249 0 00-.25-.25zM23.75 3.75A.25.25 0 0024 3.5v-2A1.5 1.5 0 0022.5 0h-21A1.5 1.5 0 000 1.5v2a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});