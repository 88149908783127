define("ember-svg-jar/inlined/aircraft-military-plane-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>aircraft-military-plane-1</title><path d=\"M23 12.5h-6.6a.25.25 0 01-.232-.157 4.5 4.5 0 00-2.98-2.676.249.249 0 01-.188-.241V6.5a1 1 0 00-2 0v2.926a.25.25 0 01-.184.241 4.5 4.5 0 00-2.98 2.676.25.25 0 01-.236.157H1a1 1 0 000 2h.559a.251.251 0 01.222.366A2.434 2.434 0 001.5 16a2.5 2.5 0 005 0 2.434 2.434 0 00-.281-1.134.253.253 0 01.008-.246.25.25 0 01.214-.12h.892a.25.25 0 01.247.21 4.473 4.473 0 008.84 0 .25.25 0 01.247-.21h.892a.25.25 0 01.222.366A2.434 2.434 0 0017.5 16a2.5 2.5 0 005 0 2.434 2.434 0 00-.281-1.134.251.251 0 01.222-.366H23a1 1 0 000-2zm-8.918.648a2.579 2.579 0 01-4.151 0 .914.914 0 01-.172-.623.815.815 0 01.258-.564 2.968 2.968 0 013.978 0 .81.81 0 01.258.564.913.913 0 01-.171.623z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});