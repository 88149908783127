define("ember-svg-jar/inlined/single-man-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-shield</title><path d=\"M23.5 1.957A1.458 1.458 0 0022.043.5H1.957A1.458 1.458 0 00.5 1.957V9.25a14.645 14.645 0 0011.4 14.24.5.5 0 00.2 0A14.645 14.645 0 0023.5 9.25zm-5.874 14.955a.748.748 0 01-.626.338H7a.751.751 0 01-.689-1.05A6.305 6.305 0 0112 12.25a6.3 6.3 0 015.689 3.95.749.749 0 01-.063.712zM13.2 7.947a7.38 7.38 0 002-.275.238.238 0 01.219.048.233.233 0 01.081.208 3.513 3.513 0 01-7-.428 3.213 3.213 0 01.094-.752.233.233 0 01.147-.165.236.236 0 01.22.027A7.362 7.362 0 0013.2 7.947zM9.64 5.345a.254.254 0 01-.1-.187.25.25 0 01.078-.2 3.5 3.5 0 015.524.938.25.25 0 01-.146.353 5.936 5.936 0 01-1.791.277A6.169 6.169 0 019.64 5.345z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});