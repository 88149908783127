define("ember-svg-jar/inlined/skull-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skull-2</title><path d=\"M18.5 11a6.5 6.5 0 10-11 4.689v2.816a1.5 1.5 0 103 0 1.5 1.5 0 003 0 1.5 1.5 0 103 0V15.69a6.477 6.477 0 002-4.69zm-9.38 1.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm6 0a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.501 1.5zM2.8 4.919l1.827 1.827a.5.5 0 00.406.144.5.5 0 00.367-.224 8.028 8.028 0 011.207-1.455.5.5 0 00.155-.356.5.5 0 00-.146-.36L4.92 2.8A1.751 1.751 0 002.759.173a1 1 0 00-.549.716l-.146.775a.5.5 0 01-.4.4L.89 2.21a1 1 0 00-.716.548A1.751 1.751 0 002.8 4.919zM18.6 6.666a.5.5 0 00.367.224.5.5 0 00.406-.144L21.2 4.919a1.751 1.751 0 002.626-2.161 1 1 0 00-.716-.548l-.775-.147a.5.5 0 01-.4-.4L21.79.889a1 1 0 00-.549-.716A1.751 1.751 0 0019.08 2.8l-1.7 1.7a.5.5 0 00-.146.36.5.5 0 00.155.356 8.028 8.028 0 011.211 1.45zM5.4 17.336a.5.5 0 00-.773-.082L2.8 19.082a1.75 1.75 0 00-2.626 2.16 1 1 0 00.716.55l.775.145a.5.5 0 01.4.4l.146.775a1 1 0 00.549.716A1.751 1.751 0 004.92 21.2l1.7-1.7a.5.5 0 00.146-.36.5.5 0 00-.155-.356A8.084 8.084 0 015.4 17.336zM21.2 19.082l-1.827-1.828a.5.5 0 00-.773.082 8.084 8.084 0 01-1.207 1.453.5.5 0 00-.155.356.5.5 0 00.146.36l1.7 1.7a1.751 1.751 0 002.161 2.626 1 1 0 00.549-.716l.146-.775a.5.5 0 01.4-.4l.775-.145a1 1 0 00.716-.55 1.75 1.75 0 00-2.626-2.16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});