define("ember-svg-jar/inlined/electronics-led-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>electronics-LED-light</title><path d=\"M19 13.5h-.25a.25.25 0 01-.25-.25V6.5a6.5 6.5 0 00-13 0v6.75a.25.25 0 01-.25.25H5a1 1 0 100 2h2.75a.25.25 0 01.25.25V23a1 1 0 102 0v-7.25a.25.25 0 01.25-.25h3.5a.25.25 0 01.25.25V23a1 1 0 102 0v-7.25a.25.25 0 01.25-.25H19a1 1 0 000-2zM9.47 7.531a.75.75 0 111.06-1.061l1.293 1.293a.25.25 0 00.354 0L13.47 6.47a.75.75 0 011.06 1.061l-1.707 1.707a.249.249 0 00-.073.177v3.836a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25V9.415a.249.249 0 00-.073-.177z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});