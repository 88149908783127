define("ember-svg-jar/inlined/network-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>network-share</title><path d=\"M18.449 10.255a16.373 16.373 0 00-.123-.968.253.253 0 01.057-.2.249.249 0 01.191-.087h2.805a.25.25 0 01.239.178c.019.064.033.13.047.2a.249.249 0 00.206.2 4.2 4.2 0 011.819.746.128.128 0 00.144 0 .131.131 0 00.061-.131C22.414.389 12.544-.036 11.963 0a12 12 0 00.066 24h.006a1.024 1.024 0 00.892-.572 1.035 1.035 0 00-.444-1.32 9.382 9.382 0 01-3.774-4.328.251.251 0 01.146-.342.988.988 0 00.676-.937 1 1 0 00-1-1h-.4a.251.251 0 01-.244-.2 15.606 15.606 0 01-.1-6.1A.249.249 0 018.03 9h8a.249.249 0 01.245.2c.075.4.138.806.18 1.243a1.007 1.007 0 001.09.9 1.052 1.052 0 00.904-1.088zM2.85 15.5a.25.25 0 01-.235-.167 9.883 9.883 0 01-.177-6.154A.25.25 0 012.677 9h2.807a.249.249 0 01.247.287 17.9 17.9 0 00-.2 2.713 17.189 17.189 0 00.3 3.2.252.252 0 01-.246.3zm5.222 5.157a.25.25 0 01-.311.371A10.036 10.036 0 013.966 17.9a.25.25 0 01.2-.4h2.089a.25.25 0 01.234.162 12.84 12.84 0 001.583 2.994zm0-17.317a13.15 13.15 0 00-1.743 3.487.25.25 0 01-.237.173H3.83a.25.25 0 01-.21-.386 10.04 10.04 0 014.144-3.645.25.25 0 01.311.37zm7.439 3.548a.248.248 0 01-.205.112H8.754a.25.25 0 01-.231-.346 10.049 10.049 0 013.364-4.385.249.249 0 01.29 0 10.089 10.089 0 013.36 4.385.25.25 0 01-.023.233zm2.217-.06a13.188 13.188 0 00-1.743-3.483.25.25 0 01.312-.37 10.153 10.153 0 014.141 3.639.25.25 0 01-.21.386h-2.26a.25.25 0 01-.237-.173z\"/><path d=\"M15.606 20.238a.253.253 0 01.266-.051l2.576 1.031a.034.034 0 01.021.031 2.77 2.77 0 10.863-1.989.249.249 0 01-.266.051l-2.445-.978a.252.252 0 01-.157-.215c0-.044-.008-.1-.017-.164a.249.249 0 01.12-.247L19.406 16a.249.249 0 01.27.009 2.714 2.714 0 001.543.487 2.75 2.75 0 10-2.726-2.451.252.252 0 01-.12.243L15.532 16a.253.253 0 01-.271-.008 2.7 2.7 0 00-1.542-.488 2.788 2.788 0 00-2.75 2.75A2.752 2.752 0 0013.719 21a2.8 2.8 0 001.887-.762z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});