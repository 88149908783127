define("ember-svg-jar/inlined/kitchen-window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchen-window</title><path d=\"M23.055 3.167a.5.5 0 00.416-.223l.445-.667A.5.5 0 0024 2V1a1 1 0 00-1-1H1a1 1 0 00-1 1v1a.5.5 0 00.084.277l.445.667a.5.5 0 00.416.223zM1.5 4.167a.5.5 0 00-.5.5V23a1 1 0 001 1h.5a.513.513 0 00.444-.27A1.7 1.7 0 014.5 23a1.7 1.7 0 011.553.724A.5.5 0 006.5 24h1a.513.513 0 00.444-.27A1.7 1.7 0 019.5 23a1.7 1.7 0 011.553.724.5.5 0 00.447.276h1a.513.513 0 00.444-.27A1.7 1.7 0 0114.5 23a1.7 1.7 0 011.553.724.5.5 0 00.447.276h1a.513.513 0 00.444-.27A1.7 1.7 0 0119.5 23a1.7 1.7 0 011.553.724.5.5 0 00.447.276h.5a1 1 0 001-1V4.667a.5.5 0 00-.5-.5zM19.09 18.5a.25.25 0 01-.25.25h-.9a.249.249 0 01-.25-.25v-2.513a.249.249 0 01.25-.25h.9a.25.25 0 01.25.25zm-2.9 0a.25.25 0 01-.25.25h-.9a.25.25 0 01-.25-.25v-2.513a.25.25 0 01.25-.25h.9a.25.25 0 01.25.25zm3.652-4.263h-5.8a.75.75 0 00-.75.75v3.763a.25.25 0 01-.25.25H2.25a.25.25 0 01-.25-.25V13.5a.25.25 0 01.25-.25h19.5a.25.25 0 01.25.25v5.25a.25.25 0 01-.25.25h-.91a.25.25 0 01-.25-.25v-3.763a.75.75 0 00-.75-.75zM6.947 9.564l.307.923a.75.75 0 00.712.513.782.782 0 00.237-.038.751.751 0 00.474-.949l-.244-.733c.186-.014.371-.03.567-.03.126 0 .243.013.365.019l.394 1.181a.749.749 0 00.711.513.789.789 0 00.238-.038.751.751 0 00.474-.949l-.139-.414c1.041.34 1.707.908 1.707 1.438v.5a.25.25 0 01-.25.25h-7a.25.25 0 01-.25-.25V11c0-.529.661-1.094 1.697-1.436zM2.5 7a.513.513 0 00.444-.27A1.7 1.7 0 014.5 6a1.7 1.7 0 011.553.724A.5.5 0 006.5 7h1a.513.513 0 00.444-.27A1.7 1.7 0 019.5 6a1.7 1.7 0 011.553.724A.5.5 0 0011.5 7h1a.513.513 0 00.444-.27A1.7 1.7 0 0114.5 6a1.7 1.7 0 011.553.724A.5.5 0 0016.5 7h1a.513.513 0 00.444-.27A1.7 1.7 0 0119.5 6a1.7 1.7 0 011.553.724A.5.5 0 0021.5 7h.25a.25.25 0 01.25.25v4.25a.25.25 0 01-.25.25H14.5a.25.25 0 01-.25-.25V11c0-1.822-2.306-3.25-5.25-3.25S3.75 9.178 3.75 11v.5a.25.25 0 01-.25.25H2.25A.25.25 0 012 11.5V7.25A.25.25 0 012.25 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});