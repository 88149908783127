define("ember-svg-jar/inlined/multiple-actions-mobilephone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-mobilephone</title><circle cx=\"14.499\" cy=\"3.499\" r=\"2.999\"/><path d=\"M11 14a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5h1.809a.25.25 0 01.249.23L3 23.533a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.31a.25.25 0 01.249-.23H10.5a.5.5 0 00.5-.5z\"/><circle cx=\"5.498\" cy=\"3.499\" r=\"3.499\"/><path d=\"M22.527 11.005h-6.051A1.492 1.492 0 0015 12.513v9.979A1.493 1.493 0 0016.476 24h6.051A1.493 1.493 0 0024 22.492v-9.979a1.492 1.492 0 00-1.473-1.508zM17 13.5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v8a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5zM14.5 7.5a4.527 4.527 0 00-3.594 1.76.25.25 0 00.005.311 6.99 6.99 0 011.424 2.919.249.249 0 00.243.2h.679a.249.249 0 00.249-.237A2.985 2.985 0 0116.472 9.5h1.287a.25.25 0 00.19-.412A4.519 4.519 0 0014.5 7.5z\"/><circle cx=\"14.499\" cy=\"3.499\" r=\"2.999\"/><path d=\"M11 14a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5h1.809a.25.25 0 01.249.23L3 23.533a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.31a.25.25 0 01.249-.23H10.5a.5.5 0 00.5-.5z\"/><circle cx=\"5.498\" cy=\"3.499\" r=\"3.499\"/><path d=\"M22.527 11.005h-6.051A1.492 1.492 0 0015 12.513v9.979A1.493 1.493 0 0016.476 24h6.051A1.493 1.493 0 0024 22.492v-9.979a1.492 1.492 0 00-1.473-1.508zM17 13.5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v8a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5zM14.5 7.5a4.527 4.527 0 00-3.594 1.76.25.25 0 00.005.311 6.99 6.99 0 011.424 2.919.249.249 0 00.243.2h.679a.249.249 0 00.249-.237A2.985 2.985 0 0116.472 9.5h1.287a.25.25 0 00.19-.412A4.519 4.519 0 0014.5 7.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});