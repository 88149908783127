define("ember-svg-jar/inlined/ice-cream-stand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ice-cream-stand</title><path d=\"M11.75 0A9.329 9.329 0 002.2 9.072V9.5a.5.5 0 001 0c0-.547 1.021-1.214 2.214-1.214 1.262 0 2.214.522 2.214 1.214a.5.5 0 001 0c0-.458 1.458-1.214 3.119-1.214s3.118.756 3.118 1.214a.5.5 0 001 0c0-.692.953-1.214 2.215-1.214 1.192 0 2.214.667 2.214 1.214a.5.5 0 101 0v-.428A9.329 9.329 0 0011.75 0z\"/><path d=\"M21.566 11.866a1 1 0 00-1.408-.128l-3.044 2.536a1 1 0 01-.641.232H13.3a.5.5 0 01-.5-.5V10.5a1 1 0 00-2 0v3.506a.5.5 0 01-.5.5H8.8a2.5 2.5 0 00-2.5 2.5v3.75a.75.75 0 00.75.75.5.5 0 01.5.5v.988a1 1 0 002 0v-.988a.5.5 0 01.5-.5h3.375a.5.5 0 00.484-.376 3.5 3.5 0 013.828-2.6.5.5 0 00.563-.5v-1.671a1 1 0 01.359-.768l2.781-2.317a1 1 0 00.126-1.408z\"/><circle cx=\"17.298\" cy=\"22\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});