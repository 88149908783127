define("ember-svg-jar/inlined/delete-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>delete-2</title><path d=\"M20.488 3.515a11.994 11.994 0 100 16.97 12.014 12.014 0 000-16.97zm-3.779 11.764a1 1 0 01-1.409 1.415l-3.11-3.11a.252.252 0 00-.354 0l-3.109 3.11a1.019 1.019 0 01-1.415 0 1 1 0 010-1.415l3.11-3.109a.249.249 0 000-.353l-3.11-3.11a1 1 0 011.41-1.415l3.109 3.11a.25.25 0 00.354 0l3.11-3.11a1 1 0 011.414 1.415l-3.11 3.11a.25.25 0 000 .353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});