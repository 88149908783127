define("ember-svg-jar/inlined/cog-double-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cog-double-3</title><path d=\"M23.6 6.488l-.764-.823a.242.242 0 010-.328l.762-.823a1.47 1.47 0 00.2-1.768 1.46 1.46 0 00-1.62-.7l-1.09.253a.232.232 0 01-.278-.163l-.328-1.076A1.462 1.462 0 0019.055 0a1.462 1.462 0 00-1.423 1.056L17.3 2.133a.241.241 0 01-.277.163l-1.091-.253a1.46 1.46 0 00-1.62.7 1.47 1.47 0 00.2 1.768l.764.823a.243.243 0 010 .328l-.765.823a1.493 1.493 0 001.425 2.471l1.091-.253a.241.241 0 01.277.163l.327 1.076A1.462 1.462 0 0019.054 11a1.462 1.462 0 001.421-1.056l.325-1.075a.234.234 0 01.277-.163l1.091.253A1.492 1.492 0 0023.6 6.488zm-4.542.951A1.938 1.938 0 1120.991 5.5a1.94 1.94 0 01-1.937 1.939zM14.709 15.4l.694-.627a1.806 1.806 0 00-1.3-3.144l-.933.048a.807.807 0 01-.847-.846l.048-.934A1.805 1.805 0 009.225 8.6l-.625.691a.834.834 0 01-1.2 0L6.774 8.6A1.806 1.806 0 003.63 9.9l.048.933a.808.808 0 01-.847.847l-.931-.048a1.838 1.838 0 00-1.763 1.112A1.809 1.809 0 00.6 14.776l.7.627a.808.808 0 010 1.2l-.7.623a1.806 1.806 0 001.3 3.144l.933-.047a.806.806 0 01.847.846l-.05.931a1.806 1.806 0 003.144 1.3l.626-.7a.834.834 0 011.2 0l.627.7a1.805 1.805 0 003.143-1.3l-.048-.934a.806.806 0 01.847-.846l.933.047a1.831 1.831 0 001.761-1.112 1.81 1.81 0 00-.458-2.032l-.694-.626a.806.806 0 010-1.2zM12 16a4 4 0 11-4-4 4 4 0 014 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});