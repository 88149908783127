define("ember-svg-jar/inlined/common-file-quill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-quill</title><path d=\"M23.374 3.055L20.96.64a2 2 0 00-1.415-.586H7.96a2 2 0 00-2 2v9.79a.248.248 0 00.4.2 8.911 8.911 0 011.34-.862.493.493 0 00.263-.44V2.554a.5.5 0 01.5-.5h10.876a.5.5 0 01.353.147l2.121 2.121a.5.5 0 01.147.353v13.379a.5.5 0 01-.5.5h-9.827a.491.491 0 00-.416.233 12.476 12.476 0 01-.978 1.359.245.245 0 00.185.407H21.96a2 2 0 002-2V4.468a2 2 0 00-.586-1.413z\"/><path d=\"M12.206 11.839a.5.5 0 00-.409-.176 13.448 13.448 0 00-4.251 1.122.5.5 0 00-.3.416c0 .026-.333 2.571-1.532 2.822a.19.19 0 01-.186-.19v-1.39a.5.5 0 00-.561-.5c-.787.1-2.685 1.975-3.313 3.8a3.555 3.555 0 00.1 2.827.242.242 0 01-.041.282l-1.38 1.38a1 1 0 001.414 1.414L3.26 22.14a.252.252 0 01.226-.065 3.464 3.464 0 001.564-.013A7.939 7.939 0 008.6 19.8c2.555-2.555 3.664-7.326 3.709-7.528a.5.5 0 00-.103-.433z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});