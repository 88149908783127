define("ember-svg-jar/inlined/discount-star-premium", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>discount-star-premium</title><path d=\"M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm0 20a8 8 0 118-8 8.009 8.009 0 01-8 8z\"/><path d=\"M16.366 9.5h-2.339a.252.252 0 01-.225-.141l-1.213-2.5a.662.662 0 00-1.177 0l-1.212 2.5a.25.25 0 01-.225.141H7.632a.612.612 0 00-.432 1.069l2.051 2.144a.249.249 0 01.049.272l-1.131 2.609a.654.654 0 00.931.818l2.778-1.564a.249.249 0 01.245 0l2.777 1.564a.654.654 0 00.931-.818L14.7 12.985a.25.25 0 01.048-.272l2.052-2.144a.612.612 0 00-.434-1.069z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});