define("ember-svg-jar/inlined/single-woman-actions-wifi.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-wifi</title><path d=\"M10.724 14.4a11.142 11.142 0 012.86-1.644.245.245 0 000-.457c-.71-.273-1.5-.556-2.343-.865a.763.763 0 01-.5-.715v-.458a.245.245 0 01.244-.245 6.241 6.241 0 003.709-.877.382.382 0 00.178-.271.378.378 0 00-.1-.309c-.484-.527-.818-1.161-.961-3.25-.256-3.747-2.8-5.065-5-5.065s-4.739 1.318-5 5.065C3.678 7.4 3.343 8.033 2.86 8.56a.378.378 0 00-.1.309.382.382 0 00.178.271 6.228 6.228 0 003.705.882.246.246 0 01.249.245v.453a.763.763 0 01-.5.715C3.551 12.478 1.287 13.223.746 14.3A7.683 7.683 0 000 16.972a.378.378 0 00.1.292.387.387 0 00.284.124h9.3a.245.245 0 00.233-.32 2.447 2.447 0 01.807-2.668z\"/><circle cx=\"17.633\" cy=\"22.041\" r=\"1.714\"/><path d=\"M11.636 15.553a.979.979 0 001.217 1.535 7.665 7.665 0 019.559 0 .979.979 0 101.217-1.535 9.619 9.619 0 00-11.993 0z\"/><path d=\"M14.06 18.319a.98.98 0 001.267 1.5 3.514 3.514 0 014.611 0 .98.98 0 001.267-1.5 5.51 5.51 0 00-7.145 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});