define("ember-svg-jar/inlined/baseball-glove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baseball-glove</title><path d=\"M4.786 7.647v4.037a3.118 3.118 0 002.805 3.173 2.967 2.967 0 003.128-2.963l-.014-10.156a.25.25 0 00-.25-.249 7.95 7.95 0 00-6.687 3.622.25.25 0 00.046.326 2.919 2.919 0 01.972 2.21z\"/><path d=\"M23.449 5.715a1.358 1.358 0 00-1.357-1.354 1.392 1.392 0 00-1.026.4 1.346 1.346 0 00-.4.961l.011 4.458a.75.75 0 01-.748.752.75.75 0 01-.75-.748c0-1.8.044-5.4.042-7.2a1.365 1.365 0 00-1.363-1.36A1.364 1.364 0 0016.5 2.986c-.016 4.642-.011 3.3-.033 7.141a.748.748 0 01-.746.738.749.749 0 01-.75-.749l-.014-8.648A1.358 1.358 0 0013.6.113a1.373 1.373 0 00-.994.4 1.353 1.353 0 00-.4.961l.014 10.421a4.468 4.468 0 01-4.706 4.462 4.578 4.578 0 01-4.227-4.671V7.647a1.294 1.294 0 00-1.375-1.374A1.293 1.293 0 00.538 7.647v4.373a11.728 11.728 0 0011.247 11.865 11.462 11.462 0 0011.677-11.459zM4.017 17.8a.749.749 0 01-1.031-.25 11.834 11.834 0 01-.268-.463.75.75 0 111.316-.719c.074.136.152.269.233.4a.75.75 0 01-.25 1.032zm3.234 2.9a.75.75 0 01-1.04.208 11.138 11.138 0 01-.934-.7.751.751 0 01.964-1.151 9.4 9.4 0 00.8.6.749.749 0 01.21 1.043zm3.393 1.9a.791.791 0 01-.118-.009 10.452 10.452 0 01-1.141-.243.749.749 0 11.391-1.448 9.366 9.366 0 00.983.208.75.75 0 01-.115 1.491zm4.5-.284a10.941 10.941 0 01-1.136.256.778.778 0 01-.127.011.75.75 0 01-.126-1.49 9.114 9.114 0 00.981-.22.75.75 0 11.408 1.443zm4.083-2.188a10.856 10.856 0 01-.925.711.762.762 0 01-.424.13.75.75 0 01-.424-1.369 9.091 9.091 0 00.8-.611.75.75 0 01.976 1.139zm2.513-3.125c-.084.155-.171.309-.261.46a.75.75 0 01-.645.366.741.741 0 01-.383-.106.749.749 0 01-.251-1.023c.078-.133.155-.266.227-.4a.75.75 0 111.322.709z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});