define("ember-svg-jar/inlined/single-woman-actions-heart.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-heart</title><path d=\"M9.394 17.5a.25.25 0 00.243-.308 5.014 5.014 0 012.015-5.285.251.251 0 00-.057-.44l-.121-.044a.779.779 0 01-.513-.73v-.467a.25.25 0 01.249-.25A6.377 6.377 0 0015 9.081a.387.387 0 00.083-.593c-.493-.537-.835-1.185-.98-3.317C13.838 1.346 11.236 0 9 0S4.161 1.346 3.9 5.171c-.146 2.132-.487 2.78-.981 3.317a.39.39 0 00-.1.316.385.385 0 00.181.277 6.347 6.347 0 003.781.9.249.249 0 01.254.25v.462a.778.778 0 01-.512.73c-2.9 1.065-5.212 1.825-5.764 2.922A7.822 7.822 0 000 17.076a.383.383 0 00.1.3.392.392 0 00.29.127z\"/><path d=\"M21.038 12.546a3.486 3.486 0 00-3.057.987l-.305.3a.25.25 0 01-.353 0l-.3-.3a3.526 3.526 0 00-5.646.916 3.521 3.521 0 00.653 4.062l5.113 5.334a.5.5 0 00.723 0l5.106-5.327a3.522 3.522 0 00.66-4.069 3.485 3.485 0 00-2.594-1.903z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});