define("ember-svg-jar/inlined/space-rocket-earth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>space-rocket-earth</title><path d=\"M14.723 13.207a2 2 0 01-2.516-2.517c.818-2.546 1.333-3.059 1.552-3.278l.027-.027a.247.247 0 00.072-.223.252.252 0 00-.145-.185 8 8 0 00-11.168 8.176.249.249 0 01-.069.2 15.217 15.217 0 00-2.2 3.858 2.7 2.7 0 00.259 2.846 1.9 1.9 0 001.4.528 8.469 8.469 0 003.985-1.547.252.252 0 01.268 0 8 8 0 0011.963-9.06.248.248 0 00-.162-.165.251.251 0 00-.229.038 11.664 11.664 0 01-3.037 1.356zm-5.172.547a.75.75 0 011.157.93c-3.921 3.707-7.083 5.576-8.36 5.878a.25.25 0 01-.3-.3 6.352 6.352 0 01.523-1.323c.126-.251.278-.529.46-.838a.251.251 0 01.435.008l.021.038a.248.248 0 00.155.121.245.245 0 00.194-.028 36.6 36.6 0 005.715-4.486z\"/><path d=\"M17.378 9.534a1.486 1.486 0 00-.439-1.059 1.535 1.535 0 00-2.121 0 10.131 10.131 0 00-1.183 2.674.5.5 0 00.629.629 10.134 10.134 0 002.675-1.178 1.492 1.492 0 00.439-1.066zM23 1.494l-1.556.668a3.539 3.539 0 00-.969.656l-1.2 1.2a4.05 4.05 0 00-3.755.924l-.353.355a.5.5 0 000 .707l4.243 4.242a.5.5 0 00.707 0l.354-.353a4.044 4.044 0 00.929-3.757l1.2-1.2a3.563 3.563 0 00.655-.968l.667-1.556a.7.7 0 00-.1-.817.7.7 0 00-.822-.101z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});