define("ember-svg-jar/inlined/car-truck-luggage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-truck-luggage</title><path d=\"M18.5 18.375a2.976 2.976 0 011.149.231.261.261 0 00.1.019H22.5a.75.75 0 00.75-.75v-4a.733.733 0 00-.3-.584.254.254 0 01-.066-.076l-2.766-4.836a1 1 0 00-.868-.5H8.784a2.514 2.514 0 00-2.337 1.61l-1.4 3.667a.251.251 0 01-.146.145l-2.628.985a2.737 2.737 0 00-1.727 2.032l-.281 1.411a.749.749 0 00.735.9h3.255a.242.242 0 00.1-.019A3 3 0 018.5 21.375a2.532 2.532 0 01-.03.371.248.248 0 00.1.238.725.725 0 00.43.141h6a.725.725 0 00.429-.141.249.249 0 00.1-.238 2.532 2.532 0 01-.03-.371 3 3 0 013.001-3zm-7.75-5.5a.25.25 0 01-.25.25H7.563a.247.247 0 01-.205-.108.25.25 0 01-.028-.231l.986-2.586a.505.505 0 01.468-.322H10.5a.25.25 0 01.25.25zm2.25.25a.25.25 0 01-.25-.25v-2.75a.25.25 0 01.25-.25h5.525a.25.25 0 01.217.126l1.571 2.75a.25.25 0 01-.217.374z\"/><path d=\"M2.772 19.375H1a1 1 0 000 2h1.26a.25.25 0 00.249-.23 2.96 2.96 0 01.473-1.384.25.25 0 00-.21-.386zM23.5 19.375h-2.272a.25.25 0 00-.21.386 2.96 2.96 0 01.473 1.383.25.25 0 00.249.231h.76a1.5 1.5 0 001.5-1.5.5.5 0 00-.5-.5zM11.5 6.875h8a.5.5 0 00.5-.5v-1.5a1.5 1.5 0 00-1.5-1.5.25.25 0 01-.25-.25v-.75A1.752 1.752 0 0016.5.625h-2a1.752 1.752 0 00-1.75 1.75v.75a.25.25 0 01-.25.25 1.5 1.5 0 00-1.5 1.5v1.5a.5.5 0 00.5.5zm2.75-4.5a.25.25 0 01.25-.25h2a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25z\"/><circle cx=\"18.5\" cy=\"21.375\" r=\"2\"/><circle cx=\"5.5\" cy=\"21.375\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});