define("ember-svg-jar/inlined/multiple-actions-alarm.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-alarm</title><path d=\"M14.471 8.4l-.486-.172a1.8 1.8 0 01-.055-1.172 4.651 4.651 0 001.215-3.569A3.219 3.219 0 0012 0a2.991 2.991 0 00-1.342.314.248.248 0 00-.1.363 5 5 0 01.842 2.808 6.267 6.267 0 01-.921 3.624.25.25 0 00.121.372c1.918.716 3.3 1.328 3.9 2.537.033.067.065.135.094.2a.251.251 0 00.153.139.248.248 0 00.2-.023 5.969 5.969 0 011.572-.648.25.25 0 00.041-.47c-.587-.263-1.292-.524-2.089-.816z\"/><path d=\"M13.579 11.454a.249.249 0 00.054-.246 4.457 4.457 0 00-.251-.631c-.454-.907-1.9-1.44-3.911-2.179l-.486-.172a1.8 1.8 0 01-.055-1.172 4.651 4.651 0 001.215-3.569A3.219 3.219 0 007 0a3.22 3.22 0 00-3.146 3.485 4.605 4.605 0 001.193 3.538 1.877 1.877 0 010 1.185l-.515.19c-2.01.739-3.46 1.272-3.914 2.179A7.542 7.542 0 000 13.5a.5.5 0 00.5.5h11.507a.25.25 0 00.24-.18 6 6 0 011.332-2.366zM23.658 19.882a4.08 4.08 0 01-1.158-2.715V15.5a4.5 4.5 0 00-9 0v1.667a4.08 4.08 0 01-1.158 2.715l-.242.318a.5.5 0 00.4.8h11a.5.5 0 00.4-.8zM19.682 22.194a.5.5 0 00-.4-.194h-2.568a.5.5 0 00-.4.194.5.5 0 00-.089.431 1.827 1.827 0 003.541 0 .5.5 0 00-.084-.431z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});