define("ember-svg-jar/inlined/audio-file-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file-home</title><path d=\"M16.233 11.5a.734.734 0 00.017-.084V6.247c0-.141.178-.18.271-.122l1.08.68a.75.75 0 10.8-1.269l-1.818-1.141A1.25 1.25 0 0014.75 5.5v3.4a.246.246 0 01-.294.244 2.029 2.029 0 00-1.47.221 2.314 2.314 0 00-1.229 1.8 2.245 2.245 0 104.476.344z\"/><path d=\"M23.414 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.868a.25.25 0 00.33.232 2.479 2.479 0 011.34 0A.25.25 0 008 9.868V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-5.269c-.266 0-.34.241-.383.358a2.511 2.511 0 01-1.2 1.362c-.2.1-.093.279.307.279H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M7.324 12.118a.5.5 0 00-.647 0l-6.5 5.5A.5.5 0 00.5 18.5h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-4.75a.25.25 0 01.25-.25h1.25a.5.5 0 00.324-.882z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});