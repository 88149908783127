define("ember-svg-jar/inlined/keyboard-button-direction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-button-direction</title><path d=\"M8 11h8a1.5 1.5 0 001.5-1.5v-8A1.5 1.5 0 0016 0H8a1.5 1.5 0 00-1.5 1.5v8A1.5 1.5 0 008 11zm1.5-5.5a.5.5 0 01-.354-.853l2.5-2.5a.5.5 0 01.708 0l2.5 2.5a.5.5 0 01-.354.853H13a.25.25 0 00-.25.25v2.732a.75.75 0 01-1.5 0V5.747A.25.25 0 0011 5.5zM9.5 13h-8A1.5 1.5 0 000 14.5v8A1.5 1.5 0 001.5 24h8a1.5 1.5 0 001.5-1.5v-8A1.5 1.5 0 009.5 13zm-1.016 4.75a.75.75 0 110 1.5H5.75a.25.25 0 00-.25.25V21a.5.5 0 01-.309.462A.489.489 0 015 21.5a.5.5 0 01-.354-.146l-2.5-2.5a.5.5 0 010-.707l2.5-2.5A.5.5 0 015.5 16v1.5a.249.249 0 00.25.25zM22.5 13h-8a1.5 1.5 0 00-1.5 1.5v8a1.5 1.5 0 001.5 1.5h8a1.5 1.5 0 001.5-1.5v-8a1.5 1.5 0 00-1.5-1.5zm-6.984 6.25a.75.75 0 110-1.5h2.734a.249.249 0 00.25-.25V16a.5.5 0 01.309-.461.505.505 0 01.545.108l2.5 2.5a.5.5 0 010 .707l-2.5 2.5A.5.5 0 0119 21.5a.489.489 0 01-.191-.038A.5.5 0 0118.5 21v-1.5a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});