define("ember-svg-jar/inlined/retouch-triangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>retouch-triangle</title><path d=\"M12 4.5a1 1 0 001-1V1a1 1 0 00-2 0v2.5a1 1 0 001 1zM15.062 6.942l2.1-1.364A1 1 0 0016.065 3.9l-2.1 1.364a1 1 0 001.092 1.676zM6.846 5.578l2.1 1.364a1 1 0 001.092-1.676L7.938 3.9a1 1 0 00-1.092 1.678zM21.3 21.362L12.636 7.6a.78.78 0 00-1.269 0L2.678 21.4A1.752 1.752 0 004.206 24H19.8a1.751 1.751 0 001.5-2.638zm-17.336.8L11.54 10.14a.25.25 0 01.461.133V22.25a.25.25 0 01-.25.25H4.206a.257.257 0 01-.238-.338z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});