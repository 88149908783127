define("ember-svg-jar/inlined/natural-disaster-water-level", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>natural-disaster-water-level</title><path d=\"M1.5 17.789a3 3 0 003 3h15a3 3 0 003-3V4.211a1 1 0 00-2 0v8.632a.249.249 0 01-.331.236 3.542 3.542 0 01-1.635-1.306.532.532 0 00-.868.017 3.1 3.1 0 01-2.536 1.439 2.613 2.613 0 01-2.268-1.4.5.5 0 00-.853-.084 3.638 3.638 0 01-2.82 1.488 3.3 3.3 0 01-2.545-1.456.5.5 0 00-.4-.232.473.473 0 00-.419.187 4.81 4.81 0 01-2.005 1.407.25.25 0 01-.32-.239V4.211a1 1 0 10-2 0zm19-.625a.25.25 0 01-.315.242 3.663 3.663 0 01-1.7-1.107.5.5 0 00-.371-.165.5.5 0 00-.37.164 3.514 3.514 0 01-2.612 1.21 3.009 3.009 0 01-2.39-1.185.5.5 0 00-.742-.047 4.086 4.086 0 01-2.81 1.232 3.587 3.587 0 01-2.631-1.256.5.5 0 00-.355-.164h-.016a.5.5 0 00-.349.142 4.725 4.725 0 01-2.028 1.216.25.25 0 01-.311-.246v-1.046a.25.25 0 01.16-.233 3.679 3.679 0 001.133-.767 1.989 1.989 0 011.395-.567 2.015 2.015 0 011.485.66 2.089 2.089 0 001.517.761 2.6 2.6 0 001.761-.806 2 2 0 012.962.187 1.507 1.507 0 001.217.619 2.025 2.025 0 001.5-.719 2 2 0 012.97.011 2.446 2.446 0 00.755.579.248.248 0 01.146.227z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});