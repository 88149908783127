define("ember-svg-jar/inlined/expand-diagonal-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>expand-diagonal-2</title><path d=\"M15.5 16.249a1.25 1.25 0 00.884-2.134l-6.5-6.5a1.25 1.25 0 10-1.769 1.768l6.5 6.5a1.246 1.246 0 00.885.366zM1 24h4a1 1 0 00.707-1.707l-.939-.94a.249.249 0 010-.353l4.616-4.616a1.25 1.25 0 10-1.768-1.768L3 19.231a.25.25 0 01-.354 0l-.939-.939A1 1 0 000 19v4a1 1 0 001 1zM23.383 5.923A1 1 0 0024 5V1a1 1 0 00-1-1h-4a1 1 0 00-.707 1.707l.939.939a.25.25 0 010 .354l-4.116 4.115a1.25 1.25 0 001.768 1.768L21 4.767a.25.25 0 01.354 0l.939.939A1 1 0 0023 6a.987.987 0 00.383-.077z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});