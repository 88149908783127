define("ember-svg-jar/inlined/award-medal-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>award-medal-1</title><path d=\"M12 8.5a7.75 7.75 0 107.75 7.75A7.759 7.759 0 0012 8.5zm3.449 11.427a.589.589 0 01-.838.736l-2.488-1.4a.249.249 0 00-.245 0l-2.488 1.4a.589.589 0 01-.838-.736L9.564 17.6a.248.248 0 00-.054-.278l-1.827-1.8a.549.549 0 01.386-.96h2.087a.249.249 0 00.227-.144l1.088-2.343a.594.594 0 011.057 0l1.088 2.343a.249.249 0 00.227.144h2.086a.55.55 0 01.389.96l-1.827 1.8a.248.248 0 00-.054.278zM6.7 7.621a.752.752 0 001.056.423 9.224 9.224 0 018.561.029.751.751 0 001.064-.431l1.3-4.005a1.756 1.756 0 00.087-.545V1.75A1.752 1.752 0 0017.011 0H6.99a1.752 1.752 0 00-1.75 1.75v1.333a1.734 1.734 0 00.094.567z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});