define("ember-svg-jar/inlined/folder-star-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-star-alternate</title><path d=\"M23.609 17.138a1.262 1.262 0 00.3-1.378A1.232 1.232 0 0022.74 15h-2.5a.252.252 0 01-.23-.151l-1.344-3.117-.013-.027a1.294 1.294 0 00-2.3 0l-.013.027-1.344 3.117a.251.251 0 01-.229.151h-2.5a1.233 1.233 0 00-1.169.761 1.264 1.264 0 00.3 1.378l2.213 1.972a.25.25 0 01.063.286l-1.224 2.815a1.255 1.255 0 00.315 1.451 1.277 1.277 0 001.506.166l3.113-1.753a.249.249 0 01.245 0l3.112 1.753a1.276 1.276 0 001.506-.166 1.257 1.257 0 00.316-1.451L21.334 19.4a.249.249 0 01.063-.286z\"/><path d=\"M2.5 2h4.573a.5.5 0 01.447.276L8.33 3.9A2 2 0 0010.118 5H19a.5.5 0 01.5.5v3.947a.5.5 0 00.179.377 3.268 3.268 0 01.759.976l.546 1.13a.285.285 0 00.516-.2V5a2 2 0 00-2-2h-9.073a.5.5 0 01-.447-.276l-.81-1.619A2 2 0 007.382 0H2a2 2 0 00-2 2v14.5a2 2 0 002 2h7.429a.247.247 0 00.211-.382 3.38 3.38 0 01-.588-1.408.243.243 0 00-.24-.21H2.5A.5.5 0 012 16V2.5a.5.5 0 01.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});