define("ember-svg-jar/inlined/smiley-mad-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-mad-1-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><circle cx=\"8\" cy=\"11\" r=\"1.5\"/><circle cx=\"16\" cy=\"11\" r=\"1.5\"/><path d=\"M8.053 6.105l-3 1.5A1 1 0 005.5 9.5a.989.989 0 00.447-.1l3-1.5a1 1 0 00-.894-1.79zM18.947 7.605l-3-1.5a1 1 0 10-.894 1.79l3 1.5a.989.989 0 00.447.105 1 1 0 00.448-1.895zM12 14.5a2 2 0 00-2 2v2a2 2 0 004 0v-2a2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});