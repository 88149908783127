define("ember-svg-jar/inlined/people-woman-glasses-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-glasses-1</title><path d=\"M3.5 21.468a3.172 3.172 0 01.442-1.668 3.845 3.845 0 01-.379-1.143.5.5 0 00-.676-.383A4.467 4.467 0 000 22.468v1a.5.5 0 00.5.5h3.143a.5.5 0 00.415-.778 3.079 3.079 0 01-.558-1.722zM22.248 18.9a7.1 7.1 0 00-3.056-1.157c-.3-.063-.607-.126-.906-.2l-1.732-.438a.507.507 0 00-.395.065.5.5 0 00-.221.333A3.842 3.842 0 0112 20.473a4.284 4.284 0 01-2.5-.782.3.3 0 00-.445.108 3.172 3.172 0 01.445 1.669 3.079 3.079 0 01-.558 1.722.5.5 0 00.415.778H23.5a.5.5 0 00.5-.5v-1a4.529 4.529 0 00-1.752-3.568zM11.879 13.968h.283a2.7 2.7 0 001.882-.713A.75.75 0 1013 12.181a1.235 1.235 0 01-.835.287h-.283a1.233 1.233 0 01-.834-.286A.75.75 0 1010 13.254a2.7 2.7 0 001.879.714z\"/><ellipse cx=\"6.5\" cy=\"17.968\" rx=\"1.5\" ry=\"2\"/><path d=\"M5 21.468c0 1.105 1.5 2 1.5 2s1.5-.895 1.5-2a1.351 1.351 0 00-1.5-1.5 1.351 1.351 0 00-1.5 1.5zM6.022 12.081A2.029 2.029 0 005 13.968c0 1.105.672 2 1.5 2a1.439 1.439 0 001.232-.868 6.728 6.728 0 004.247 2.231c1.666 0 5.176-1.943 5.687-4.989a.254.254 0 01.145-.189 2.733 2.733 0 001.439-2.434 2.109 2.109 0 00-.465-1.441c.376-2.686-.037-4.759-1.229-6.166-1.168-1.38-3.045-2.08-5.577-2.08S7.572.731 6.4 2.111c-1.192 1.407-1.6 3.48-1.23 6.167a2.112 2.112 0 00-.46 1.443 2.784 2.784 0 001.312 2.36zm8.728-3.863a1 1 0 11-1 1 1 1 0 011-1zm-2.372.25h-.756a2.49 2.49 0 00-2.3-1.743 6.032 6.032 0 002.568-1.583 6.087 6.087 0 002.6 1.6 2.488 2.488 0 00-2.112 1.726zm-2.128.75a1 1 0 11-1-1 1 1 0 011 1zm-4.04.474a.626.626 0 01.1-.439.751.751 0 00.406-.794 13.037 13.037 0 01-.155-1.39 11.074 11.074 0 001.57-.077A2.5 2.5 0 006.75 9.218a2.525 2.525 0 002.5 2.5 2.493 2.493 0 002.372-1.75h.756a2.492 2.492 0 103.561-2.935c.394.038.766.049 1.1.049h.352a13.125 13.125 0 01-.155 1.38.751.751 0 00.406.794.616.616 0 01.105.439 1.279 1.279 0 01-.559 1.1 1.751 1.751 0 00-1 1.306c-.377 2.248-3.175 3.738-4.208 3.738-1.074 0-3.843-1.569-4.207-3.738a1.747 1.747 0 00-1-1.306.821.821 0 01-.318-.314 1.519 1.519 0 01-.245-.789z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});