define("ember-svg-jar/inlined/body-care-lotion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>body-care-lotion</title><path d=\"M23 14.987a.25.25 0 01-.25-.25 3.983 3.983 0 00-2.35-3.622.5.5 0 01-.253-.65c.936-2.2.754-4.708-.585-7.487A1.494 1.494 0 0018.3 2.13a1.511 1.511 0 00-1.355.694c-.805 1.271-1.771 2.413-4.7 2.413q-.29 0-.564.012a1 1 0 00-.959 1.039.97.97 0 001.039.959c.157-.006.318-.01.484-.01a6.814 6.814 0 005.325-1.952.5.5 0 01.84.2 5.741 5.741 0 01-.528 5.02.5.5 0 01-.422.233H11.5a1 1 0 000 2h7.25a2 2 0 012 2 .25.25 0 01-.25.25H1a1 1 0 00-1 1v5.5a2.5 2.5 0 002.5 2.5h19a2.5 2.5 0 002.5-2.5v-5.5a1 1 0 00-1-1.001z\"/><path d=\"M5 13.643a4.89 4.89 0 005-5C10 6.516 7.375 2.566 5.811.4a1.042 1.042 0 00-1.622 0C2.625 2.566 0 6.516 0 8.643a4.89 4.89 0 005 5zm-2.75-5.5a.5.5 0 01.5.5A2.252 2.252 0 005 10.893a.5.5 0 010 1 3.254 3.254 0 01-3.25-3.25.5.5 0 01.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});