define("ember-svg-jar/inlined/smartwatch-shopping-basket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smartwatch-shopping-basket</title><path d=\"M16.256 6.8a.751.751 0 00-.961.449l-.306.841a.248.248 0 01-.235.165H9a1.242 1.242 0 00-1.042.561 1.227 1.227 0 00-.124 1.138l1.088 3.036a1.245 1.245 0 001.149.76h3.578a1.256 1.256 0 001.176-.823L16.7 7.756a.749.749 0 00-.444-.956z\"/><circle cx=\"10\" cy=\"15.5\" r=\"1\"/><circle cx=\"13.5\" cy=\"15.5\" r=\"1\"/><path d=\"M18.5 2.5h-1.085a1 1 0 01-.949-.684L15.975.342A.5.5 0 0015.5 0h-7a.5.5 0 00-.475.342l-.491 1.474a1 1 0 01-.949.684H5.5a3 3 0 00-3 3v13a3 3 0 003 3h1.085a1 1 0 01.949.684l.491 1.474A.5.5 0 008.5 24h7a.5.5 0 00.475-.342l.491-1.474a1 1 0 01.949-.684H18.5a3 3 0 003-3v-13a3 3 0 00-3-3zm1 16a1 1 0 01-1 1h-13a1 1 0 01-1-1v-13a1 1 0 011-1h13a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});