define("ember-svg-jar/inlined/currency-yuan-international", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-yuan -international</title><path d=\"M12.2 2.074A.248.248 0 0112.375 2h.353a1 1 0 000-2C1.173 0 .25 10.53.25 12c0 1.446.907 12 12.478 12a1 1 0 000-2h-.354a.247.247 0 01-.182-.079 16.855 16.855 0 01-2.62-3.292.25.25 0 01.218-.372h1.537a1 1 0 000-2H8.742a.251.251 0 01-.24-.182 13.931 13.931 0 01-.473-2.816.248.248 0 01.067-.188.25.25 0 01.182-.071h2.051a1 1 0 000-2H8.278a.25.25 0 01-.182-.079.248.248 0 01-.067-.188A13.941 13.941 0 018.5 7.918a.251.251 0 01.24-.182h2.585a1 1 0 000-2H9.793a.249.249 0 01-.215-.375A17.613 17.613 0 0112.2 2.074zM8.627 20.821a.25.25 0 01-.3.375 10.037 10.037 0 01-3.513-2.52A.25.25 0 015 18.26h2.01a.25.25 0 01.228.148 13.564 13.564 0 001.389 2.413zM6.42 15.957a.253.253 0 01-.048.212.248.248 0 01-.2.095h-2.8a.249.249 0 01-.227-.147 9.934 9.934 0 01-.817-2.836.25.25 0 01.249-.281h3.2a.249.249 0 01.249.234 16.46 16.46 0 00.394 2.723zm-.39-5.189a.249.249 0 01-.248.232h-3.2a.25.25 0 01-.248-.282 9.94 9.94 0 01.817-2.837.252.252 0 01.227-.147h2.8a.251.251 0 01.243.307 16.488 16.488 0 00-.391 2.727zM5 5.74a.25.25 0 01-.186-.416 10.031 10.031 0 013.512-2.519.249.249 0 01.3.374 13.626 13.626 0 00-1.388 2.413.249.249 0 01-.228.148z\"/><path d=\"M22.75 15.979H20a.249.249 0 01-.25-.25V13.75a.25.25 0 01.25-.25h2.75a1 1 0 000-2h-2.132a.25.25 0 01-.217-.374L23.618 5.5a1 1 0 10-1.736-1l-2.915 5.1a.25.25 0 01-.434 0l-2.915-5.1a1 1 0 10-1.736.992l3.217 5.63a.25.25 0 01-.217.374H14.75a1 1 0 000 2h2.75a.25.25 0 01.25.25v1.979a.249.249 0 01-.25.25h-2.75a1 1 0 000 2h2.75a.25.25 0 01.25.25V21a1 1 0 002 0v-2.771a.25.25 0 01.25-.25h2.75a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});