define("ember-svg-jar/inlined/medical-specialty-vertebra", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-specialty-vertebra</title><path d=\"M5.12 4.654a1.486 1.486 0 001.12.27l1.865-.3a.5.5 0 01.488.206A4.135 4.135 0 0012 6.482a4.136 4.136 0 003.407-1.647.5.5 0 01.487-.206l1.866.3a1.5 1.5 0 001.451-2.372 1.492 1.492 0 00-.982-.6l-2.31-.364a.5.5 0 01-.353-.239A3.624 3.624 0 0014.459.188a1 1 0 00-1.03-.052 3.459 3.459 0 01-2.858 0A1 1 0 009.54.188a3.636 3.636 0 00-1.1 1.158.5.5 0 01-.353.239l-2.316.365a1.505 1.505 0 00-.651 2.7zM18.229 10.7l-2.31-.364a.5.5 0 01-.353-.239 3.624 3.624 0 00-1.107-1.159 1 1 0 00-1.03-.052 3.459 3.459 0 01-2.858 0 1 1 0 00-1.031.052 3.636 3.636 0 00-1.1 1.158.5.5 0 01-.353.239l-2.316.365a1.505 1.505 0 00-.651 2.7 1.477 1.477 0 001.12.27l1.865-.3a.5.5 0 01.488.206A4.135 4.135 0 0012 15.232a4.136 4.136 0 003.407-1.647.5.5 0 01.487-.206l1.866.295a1.505 1.505 0 00.469-2.974zM18.229 19.45l-2.31-.364a.5.5 0 01-.353-.239 3.624 3.624 0 00-1.107-1.159 1 1 0 00-1.03-.052 3.459 3.459 0 01-2.858 0 1 1 0 00-1.031.052 3.636 3.636 0 00-1.1 1.158.5.5 0 01-.353.239l-2.311.365a1.505 1.505 0 00.469 2.974l1.865-.3a.5.5 0 01.488.206A4.135 4.135 0 0012 23.982a4.136 4.136 0 003.407-1.647.5.5 0 01.487-.206l1.866.295a1.505 1.505 0 00.469-2.974z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});