define("ember-svg-jar/inlined/paginate-filter-camera", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paginate-filter-camera</title><path d=\"M22 0H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2V2a2 2 0 00-2-2zm-2.5 12.5a.5.5 0 01-.724.447l-2.105-1.053a.121.121 0 00-.115.005.119.119 0 00-.056.1 2 2 0 01-2 2H10a2 2 0 01-2-2V8a2 2 0 012-2h4.5a2 2 0 012 2 .119.119 0 00.056.1.121.121 0 00.115 0l2.105-1.05a.5.5 0 01.724.45z\"/><path d=\"M19.5 22h-17a.5.5 0 01-.5-.5v-17a1 1 0 00-2 0V22a2 2 0 002 2h17.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});