define("ember-svg-jar/inlined/vest-male", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vest-male</title><path d=\"M21.732 7.925A2.234 2.234 0 0120 5.75V3.118a1.493 1.493 0 00-.829-1.342L15.724.053a.5.5 0 00-.361-.034A12.251 12.251 0 0112 .5 12.251 12.251 0 018.637.019a.5.5 0 00-.361.034L4.829 1.776A1.493 1.493 0 004 3.118V5.75a2.234 2.234 0 01-1.732 2.175A1 1 0 001.5 8.9v11.969a1.7 1.7 0 00.856 1.344 8.751 8.751 0 005 1.787 6.55 6.55 0 002.86-.664.25.25 0 00.059-.411 5.523 5.523 0 01-.757-.79C9.292 21.806 9 21.521 9 21.12V11.065a.75.75 0 01.742-.75 1.608 1.608 0 001.058-.733.5.5 0 00.038-.57 16.385 16.385 0 01-1.9-6.049.5.5 0 01.6-.556A12.252 12.252 0 0012 2.662a12.3 12.3 0 002.469-.254.5.5 0 01.6.559c-.446 3.2-1.862 7.454-4.277 8.59a.5.5 0 00-.287.453v8.363a2 2 0 00.455 1.27 6.661 6.661 0 003.07 2.069 6.847 6.847 0 001.863.249 11.931 11.931 0 005.712-1.72 1.581 1.581 0 00.9-1.372V8.9a1 1 0 00-.773-.975zM13 18.75a1 1 0 111-1 1 1 0 01-1 1zm0-3.5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});