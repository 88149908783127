define("ember-svg-jar/inlined/text-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>text-bar</title><path d=\"M17.25 21.248h-1.432a2.571 2.571 0 01-2.568-2.568v-1.841a.25.25 0 01.25-.25h.887a1.25 1.25 0 100-2.5H13.5a.25.25 0 01-.25-.25V5.317a2.572 2.572 0 012.568-2.569h1.432a1.25 1.25 0 000-2.5h-1.432a5.049 5.049 0 00-3.638 1.544.251.251 0 01-.36 0A5.049 5.049 0 008.182.248H6.75a1.25 1.25 0 100 2.5h1.432a2.572 2.572 0 012.568 2.569v8.522a.25.25 0 01-.25.25h-.887a1.25 1.25 0 000 2.5h.887a.25.25 0 01.25.25v1.841a2.571 2.571 0 01-2.568 2.568H6.75a1.25 1.25 0 000 2.5h1.432A5.049 5.049 0 0011.82 22.2a.251.251 0 01.36 0 5.049 5.049 0 003.638 1.544h1.432a1.25 1.25 0 000-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});