define("ember-svg-jar/inlined/school-teacher-art", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>school-teacher-art</title><path d=\"M21 0h-8.5a1 1 0 000 2H21a1 1 0 011 1v13a1 1 0 01-1 1h-6a1 1 0 000 2h6a3 3 0 003-3V3a3 3 0 00-3-3z\"/><path d=\"M16.355 14.97a.75.75 0 00.415.976.77.77 0 00.281.054.747.747 0 00.7-.47l1.215-3.014a.5.5 0 01.375-.3 1.9 1.9 0 001.432-1.156 3.891 3.891 0 00-.962-4.067.751.751 0 00-1.262.466A2.523 2.523 0 0117.7 9a3.354 3.354 0 00-.427.514 1.622 1.622 0 00-.15 1.38 1.9 1.9 0 00.343.613.5.5 0 01.078.506zM16.356 12.719a.75.75 0 10-1.371-.608c-.3.686-.556.6-.824.5a3.612 3.612 0 01-2.648-3.246c0-1.954 1.974-3.544 4.4-3.544a5.379 5.379 0 011.209.136.75.75 0 00.34-1.457 6.858 6.858 0 00-1.547-.175c-3.255 0-5.9 2.263-5.9 5.044a5.083 5.083 0 003.651 4.661 2.342 2.342 0 00.782.143 2.105 2.105 0 001.908-1.454z\"/><circle cx=\"13.417\" cy=\"10.283\" r=\".75\"/><circle cx=\"14.167\" cy=\"7.783\" r=\".75\"/><circle cx=\"16.667\" cy=\"7.283\" r=\".75\"/><path d=\"M11 14.5a1 1 0 00-1 1v.25a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-2a4.006 4.006 0 012.666-3.771.25.25 0 01.334.235v3.536a1 1 0 002 0V9.261a.5.5 0 01.35-.477 4.5 4.5 0 10-4.119-.749.251.251 0 01-.037.418A6 6 0 000 13.75v3.5a1 1 0 001 1h1.121a.5.5 0 01.5.458L3 23.312a.749.749 0 00.75.688h4.5a.749.749 0 00.75-.688l.384-4.6a.5.5 0 01.5-.458H11a1 1 0 001-1V15.5a1 1 0 00-1-1zM3.6 3.818a.25.25 0 01.367-.147A5.964 5.964 0 007 4.5a5.854 5.854 0 001.186-.124.249.249 0 01.3.256A2.495 2.495 0 113.6 3.818z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});