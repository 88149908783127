define("ember-svg-jar/inlined/vr-play-device-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vr-play-device-1</title><path d=\"M22 0h-9a2 2 0 00-2 2v8.75a.25.25 0 00.25.25h1.25a2 2 0 011.94 1.515l.074.3a.25.25 0 00.243.189H22a2 2 0 002-2V2a2 2 0 00-2-2zm-2.106 6.5a.935.935 0 01-.453.8l-3.033 1.82A.931.931 0 0115 8.32V4.68a.93.93 0 011.408-.8l3.033 1.82a.933.933 0 01.453.8zM12.985 12.879a.5.5 0 00-.485-.379H11a.5.5 0 00-.354.146 2.894 2.894 0 01-2.061.854H5.414a2.929 2.929 0 01-2.06-.854A.5.5 0 003 12.5H1.5a.5.5 0 00-.485.379s-.525 2.11-.793 3.2a.253.253 0 00.09.258.25.25 0 00.271.022A2.987 2.987 0 012 16h10a2.98 2.98 0 011.416.356.25.25 0 00.362-.28c-.268-1.087-.793-3.197-.793-3.197zM14 19a2 2 0 00-2-2H2a2 2 0 00-2 2v3a2 2 0 002 2h10a2 2 0 002-2zM2.5 20.5A1.5 1.5 0 114 22a1.5 1.5 0 01-1.5-1.5zm6 0A1.5 1.5 0 1110 22a1.5 1.5 0 01-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});