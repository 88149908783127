define("ember-svg-jar/inlined/landmark-japan-castle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-japan-castle-1</title><path d=\"M20.038 20.5H19.5a.25.25 0 01-.25-.25v-2a.5.5 0 00-.5-.5H5.25a.5.5 0 00-.5.5v2a.25.25 0 01-.25.25h-.538c-.335 0-.549.287-.741.581-.011.017-1.137 1.77-.893 2.323a1 1 0 00.915.6h17.514a1 1 0 00.916-.6c.24-.547-.886-2.31-.9-2.327-.188-.293-.401-.577-.735-.577zM23.25 14.5a1 1 0 00-1.98-.2.25.25 0 01-.245.2h-.786a.248.248 0 01-.227-.146l-.924-2.02a1 1 0 00-.909-.584h-2.955a.25.25 0 00-.176.427l1.9 1.9a.25.25 0 01-.177.427h-.593a.249.249 0 01-.178-.077L12.973 11.4a1.377 1.377 0 00-1.946 0L8 14.427a.249.249 0 01-.177.073h-.59a.25.25 0 01-.177-.427l1.9-1.9a.25.25 0 00-.176-.427H5.821a1 1 0 00-.909.584l-.924 2.021a.251.251 0 01-.227.146h-.6a.5.5 0 01-.472-.334A1 1 0 00.75 14.5a2 2 0 002 2h18.5a2 2 0 002-2zm-12.595 0a.25.25 0 01-.177-.427l1.345-1.345a.25.25 0 01.354 0l1.345 1.345a.25.25 0 01-.177.427zM2.5 8.5a2 2 0 002 2h15a2 2 0 002-2 1 1 0 00-1.98-.2.25.25 0 01-.245.2h-.567a.25.25 0 01-.232-.157l-.512-1.279a.5.5 0 00-.464-.314h-1.526a.25.25 0 00-.176.427l.9.9a.25.25 0 01-.176.426h-1.135a.251.251 0 01-.177-.073l-2.3-2.3a1.291 1.291 0 00-1.82 0l-2.3 2.3a.249.249 0 01-.177.073H7.482a.25.25 0 01-.177-.427l.9-.9a.25.25 0 00-.176-.427H6.5a.5.5 0 00-.464.314l-.512 1.28a.25.25 0 01-.232.157h-.377a.5.5 0 01-.472-.334A1 1 0 002.5 8.5zM16.25 5.25a2 2 0 002-2 1 1 0 00-1.98-.2.25.25 0 01-.245.2h-1.387a.25.25 0 01-.161-.059l-.556-.47a.25.25 0 01-.088-.187.254.254 0 01.082-.19A1.751 1.751 0 0014.5 1a1 1 0 00-1.973-.233.248.248 0 01-.183.185 1.638 1.638 0 01-.337.048 1.712 1.712 0 01-.35-.048.251.251 0 01-.184-.185A1 1 0 009.5 1a1.751 1.751 0 00.585 1.344.254.254 0 01.082.19.25.25 0 01-.088.187l-.556.47a.25.25 0 01-.162.06h-1.2a.5.5 0 01-.472-.334 1 1 0 00-1.939.333 2 2 0 002 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});