define("ember-svg-jar/inlined/folder-warning-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-warning-alternate</title><path d=\"M23.836 21.905l-5.055-9.634a1.449 1.449 0 00-2.561 0L11.165 21.9a1.428 1.428 0 00.047 1.409 1.457 1.457 0 001.233.691h10.111a1.458 1.458 0 001.233-.686 1.428 1.428 0 00.047-1.409zM16.751 16a.75.75 0 011.5 0v2.5a.75.75 0 01-1.5 0zm.75 6.25a1 1 0 111-1 1 1 0 01-1.001 1z\"/><path d=\"M11.328 18.366l.787-1.5a.25.25 0 00-.221-.366H2.5A.5.5 0 012 16V2.5a.5.5 0 01.5-.5h4.573a.5.5 0 01.448.276L8.33 3.9A2 2 0 0010.119 5H19a.5.5 0 01.5.5v5.2a.239.239 0 00.074.173 2.839 2.839 0 01.535.7s.661 1.27.8 1.516.6.246.6-.224V5a2 2 0 00-2-2h-9.081a.5.5 0 01-.447-.276l-.81-1.619A2 2 0 007.382 0H2a2 2 0 00-2 2v14.5a2 2 0 002 2h9.105a.253.253 0 00.223-.134z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});