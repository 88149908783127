define("ember-svg-jar/inlined/smiley-unhappy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-unhappy</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm6.477 15.377a1 1 0 01-1.192.762 6 6 0 00-5.881 2 1 1 0 11-1.531-1.286 8 8 0 017.842-2.671 1 1 0 01.762 1.195zM9.5 9a2 2 0 11-2-2 2 2 0 012 2zm5 0a2 2 0 112 2 2 2 0 01-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});