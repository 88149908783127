define("ember-svg-jar/inlined/file-ppt-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-ppt-1</title><path d=\"M6.5 12.123H5.374a.249.249 0 00-.249.249v2.252a.249.249 0 00.249.249H6.5a1.375 1.375 0 000-2.75zM12.5 12.123h-1.126a.249.249 0 00-.249.249v2.252a.249.249 0 00.249.249H12.5a1.375 1.375 0 000-2.75z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-16 16.123H5.374a.249.249 0 00-.249.249V19.5a.625.625 0 01-1.25 0v-8a.625.625 0 01.625-.625h2a2.625 2.625 0 010 5.25zm6 0h-1.126a.249.249 0 00-.249.249V19.5a.625.625 0 01-1.25 0v-8a.625.625 0 01.625-.625h2a2.625 2.625 0 010 5.25zm8-4h-1.124a.251.251 0 00-.251.251V19.5a.625.625 0 11-1.25 0v-7.128a.249.249 0 00-.249-.249H16.5a.625.625 0 010-1.25h4a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});