define("ember-svg-jar/inlined/livestock-sheep-body-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>livestock-sheep-body-alternate</title><path d=\"M23.75 13.125a8.07 8.07 0 00-3.387-6.777 7.382 7.382 0 00-4.983-1.182 4.733 4.733 0 01-2.723 2.195L12.483 8a5.679 5.679 0 011.7 4.96 1.029 1.029 0 00.989 1.152 1 1 0 00.987-.847 7.584 7.584 0 00-1.585-5.925 5.718 5.718 0 014.672.67 6.083 6.083 0 012.5 5.119c0 3.477-2.314 6-5.5 6a8.425 8.425 0 01-3.231-.581 1 1 0 00-.783 0 9.44 9.44 0 01-3.376.58c-2.817 0-5.11-2.691-5.11-6a16 16 0 01.114-1.894A6.561 6.561 0 012.3 8.856a16.511 16.511 0 00-.546 4.269 8.52 8.52 0 002 5.556v3.694a1 1 0 002 0v-1.27a.493.493 0 01.673-.464 6.386 6.386 0 002.437.484 12.888 12.888 0 001.337-.073.5.5 0 01.553.5v.825a1 1 0 002 0V21.25a.5.5 0 01.641-.476 11.3 11.3 0 002.859.351 7.58 7.58 0 001.876-.232.5.5 0 01.624.484v1a1 1 0 002 0v-2.765a8.074 8.074 0 002.996-6.487z\"/><path d=\"M7.25 11.125c2.162 0 3.929-2.263 4-5.078a3.2 3.2 0 002.965-1.9.5.5 0 00-.342-.673l-3.51-.874A3.694 3.694 0 007.25.625 3.694 3.694 0 004.137 2.6l-3.508.873a.5.5 0 00-.342.673 3.156 3.156 0 002.965 1.9c.069 2.816 1.836 5.079 3.998 5.079zm-1-6.25a1 1 0 11-1-1 1 1 0 011 1zm2 0a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});