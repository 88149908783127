define("ember-svg-jar/inlined/professions-man-office-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-office-2</title><path d=\"M12 15.673a6.865 6.865 0 006.857-6.857V6.857a6.857 6.857 0 00-13.714 0v1.959A6.865 6.865 0 0012 15.673zm3.673-6.857a.98.98 0 11-.979-.979.981.981 0 01.979.979zm-3.248-.89a.245.245 0 01-.228.156H11.8a.245.245 0 01-.228-.156 2.46 2.46 0 00-.593-.886.245.245 0 01.044-.387 5.9 5.9 0 00.814-.584.244.244 0 01.32 0 5.957 5.957 0 00.809.592.243.243 0 01.116.182.247.247 0 01-.074.2 2.446 2.446 0 00-.583.883zm-3.119-.089a.98.98 0 11-.979.979.981.981 0 01.979-.979zm-1.5 3.463a.245.245 0 01.329-.339 2.4 2.4 0 001.171.3 2.44 2.44 0 002.269-1.559.246.246 0 01.228-.155h.397a.246.246 0 01.228.155 2.44 2.44 0 002.269 1.559 2.4 2.4 0 001.171-.3.245.245 0 01.329.339 4.851 4.851 0 01-8.388 0z\"/><path d=\"M12.08 12.607a2.933 2.933 0 00.846-.123 2.293 2.293 0 00.427-.177.626.626 0 00-.3-1.177H11.1a.626.626 0 00-.3 1.178 2.309 2.309 0 00.426.176 2.945 2.945 0 00.849.124zM22.776 24a.489.489 0 00.489-.49v-2.553a2.408 2.408 0 00-.947-1.936c-1.4-1.067-4.6-2.858-10.318-2.858s-8.92 1.791-10.318 2.858a2.408 2.408 0 00-.947 1.936v2.553a.489.489 0 00.489.49H9.7a.244.244 0 00.236-.178l1.6-5.589a.509.509 0 01.942 0l1.6 5.589A.244.244 0 0014.3 24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});