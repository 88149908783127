define("ember-svg-jar/inlined/aircraft-water-plane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>aircraft-water-plane</title><path d=\"M5.069 12.01a.251.251 0 01.26.052l.9.85a.25.25 0 00.416-.126 5.472 5.472 0 011.1-2.253.25.25 0 00-.022-.339l-.337-.32a.248.248 0 01-.066-.258A3.615 3.615 0 007.5 8.5a3.751 3.751 0 10-2.431 3.51zM2 8.5a1.75 1.75 0 111.75 1.75A1.752 1.752 0 012 8.5zM20.25 4.75A3.755 3.755 0 0016.5 8.5a3.615 3.615 0 00.184 1.116.248.248 0 01-.066.258l-.337.32a.249.249 0 00-.022.339 5.472 5.472 0 011.1 2.253.25.25 0 00.416.126l.9-.85a.251.251 0 01.26-.052 3.75 3.75 0 101.315-7.26zm0 5.5A1.75 1.75 0 1122 8.5a1.752 1.752 0 01-1.75 1.75z\"/><path d=\"M22.2 15.605a1 1 0 00-1.342.448l-.329.658a1.418 1.418 0 01-1.276.789h-3.817a.25.25 0 01-.181-.423A4.438 4.438 0 0016.5 14a4.489 4.489 0 00-2.919-4.192.249.249 0 01-.161-.208l-.084-.926-.082-.9A.25.25 0 0113.5 7.5h1.222a.774.774 0 00.152-1.534L13.2 5.631a.2.2 0 01-.161-.179 1.047 1.047 0 00-2.086 0 .2.2 0 01-.16.178l-1.673.335A.774.774 0 009.275 7.5H10.5a.25.25 0 01.249.273l-.082.9-.087.927a.248.248 0 01-.161.211A4.489 4.489 0 007.5 14a4.438 4.438 0 001.245 3.077.25.25 0 01-.181.423H4.75a1.418 1.418 0 01-1.276-.789l-.329-.658a1 1 0 00-1.79.894l.33.658A3.407 3.407 0 004.75 19.5h14.5a3.407 3.407 0 003.065-1.895l.33-.658a1 1 0 00-.445-1.342zM13.25 14h-2.5a.75.75 0 010-1.5h2.5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});