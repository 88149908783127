define("ember-svg-jar/inlined/water-bottle-glass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-bottle-glass</title><path d=\"M15.5 3a.5.5 0 00.5-.5V1a1 1 0 00-1-1H9a1 1 0 00-1 1v1.5a.5.5 0 00.5.5zM16.69 6.146a12.619 12.619 0 01-.613-1.312.5.5 0 00-.472-.334H8.4a.5.5 0 00-.472.334 12.619 12.619 0 01-.618 1.312A9.294 9.294 0 006 10v11.5A2.5 2.5 0 008.5 24h7a2.5 2.5 0 002.5-2.5V10a9.294 9.294 0 00-1.31-3.854z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});