define("ember-svg-jar/inlined/volume-control-low", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>volume-control-low</title><path d=\"M13.8 2.291L7.868 8.218a.249.249 0 01-.177.073H5.5a2 2 0 00-2 2v3.414a2 2 0 002 2h2.191a.25.25 0 01.177.074l5.926 5.926A1 1 0 0015.5 21V3a1 1 0 00-1.7-.709zM18.639 7.5a1 1 0 10-1.416 1.413 4.379 4.379 0 010 6.179 1 1 0 101.415 1.408 6.382 6.382 0 00.001-9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});