define("ember-svg-jar/inlined/weather-cloud-hail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-cloud-hail</title><path d=\"M21.094 7.074a.252.252 0 01-.1-.193A7 7 0 007.338 4.84a.249.249 0 01-.22.173A5.5 5.5 0 007.5 16H18a5 5 0 003.094-8.926zM21 11a3 3 0 01-3 3H7.5a3.5 3.5 0 11.448-6.971A1 1 0 009.064 6.2 5 5 0 0119 7c0 .146-.007.289-.02.433a1 1 0 00.5.959A3.007 3.007 0 0121 11z\"/><circle cx=\"2\" cy=\"23\" r=\"1\"/><circle cx=\"7\" cy=\"23\" r=\"1\"/><circle cx=\"12\" cy=\"23\" r=\"1\"/><path d=\"M3.47 21.53a.748.748 0 001.06 0l3-3a.75.75 0 00-1.06-1.06l-3 3a.749.749 0 000 1.06zM12.53 18.53a.75.75 0 00-1.06-1.06l-3 3a.75.75 0 101.06 1.06zM17.53 17.47a.749.749 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l3-3a.749.749 0 000-1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});