define("ember-svg-jar/inlined/video-game-shwings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-shwings</title><path d=\"M16.2 15.842a.618.618 0 00-.486.087l-8.807 5.886a2.771 2.771 0 01-1.485.435H.5a.5.5 0 00-.5.5A1.25 1.25 0 001.251 24h4.842a2.5 2.5 0 001.335-.386l8.662-5.471a1.5 1.5 0 00.5-2 .618.618 0 00-.39-.301z\"/><path d=\"M23.449 4.949a.512.512 0 01-.04-.366A4.029 4.029 0 0021.766.332a1.466 1.466 0 00-2.341.736c-.293 1.034-1.019 2.79-2.6 2.918a7.328 7.328 0 00-3.665 1.276.5.5 0 01-.759-.246l-.565-1.632a1.5 1.5 0 00-2.052-.869l-5.657 2.64a1.511 1.511 0 00-.77 1.887l.316.842a.5.5 0 00.671.281l2.675-1.189a.75.75 0 01.609 1.371L4.818 9.6a.5.5 0 00-.265.632l.308.821a.5.5 0 00.671.282l2.623-1.166a.75.75 0 01.609 1.371l-2.759 1.223a.5.5 0 00-.265.633 1.124 1.124 0 01-.117 1.019L3.233 18a.512.512 0 01-.193.171C1.569 18.931.444 19.347.073 20a.5.5 0 00.435.746h4.914a1.249 1.249 0 00.668-.194l7.958-5.318a1 1 0 01.684-.16 4.573 4.573 0 00.585.038 4.774 4.774 0 001.981-.438 4.355 4.355 0 001.548-1.162 2.991 2.991 0 01.733-.636l1.87-1.152a5.215 5.215 0 002-6.775zM20.4 10.025l-.153.094a.5.5 0 01-.734-.26 3.746 3.746 0 00-.938-1.459 3.3 3.3 0 00-3.96-.473 1 1 0 00.99 1.738 1.286 1.286 0 011.583.176A1.75 1.75 0 0117.7 11.4a2 2 0 01-1.233 1.46 2.574 2.574 0 01-2.76-.334 3.346 3.346 0 01-.6-4.372 4.8 4.8 0 013.88-2.174 4.777 4.777 0 003.9-3.1.25.25 0 01.451-.009 2.059 2.059 0 01-.438 2.277 1 1 0 00.662 1.606.5.5 0 01.436.5 3.32 3.32 0 01-1.598 2.771z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});