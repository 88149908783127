define("ember-svg-jar/inlined/lucide-camera-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2 2l20 20M7 7H4a2 2 0 00-2 2v9a2 2 0 002 2h16M9.5 4h5L17 7h3a2 2 0 012 2v7.5M14.121 15.121A3 3 0 119.88 10.88\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});