define("ember-svg-jar/inlined/wifi-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-lock</title><path d=\"M15.146 8.661a1 1 0 101.414-1.414 9.757 9.757 0 00-13.783 0 1 1 0 101.414 1.414 7.757 7.757 0 0110.955 0z\"/><path d=\"M17.618 5.533a1 1 0 101.414-1.414 13.261 13.261 0 00-18.732 0 1 1 0 101.417 1.414 11.26 11.26 0 0115.901 0zM13.426 11.019a1 1 0 00-.271-1.388 6.258 6.258 0 00-7.9.766 1 1 0 101.414 1.414 4.252 4.252 0 015.374-.522 1 1 0 001.383-.27zM22.746 15.442a.25.25 0 01-.21-.247v-.985a3.444 3.444 0 00-6.888 0v.984a.25.25 0 01-.21.247 1.479 1.479 0 00-1.245 1.459v5.376a1.48 1.48 0 001.478 1.478h6.842a1.479 1.479 0 001.477-1.478V16.9a1.478 1.478 0 00-1.244-1.458zm-3.654-2.675a1.444 1.444 0 011.444 1.443v.961a.25.25 0 01-.25.25H17.9a.25.25 0 01-.25-.25v-.961a1.445 1.445 0 011.442-1.443zm.978 7.308a.978.978 0 11-.978-.978.977.977 0 01.978.978z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});