define("ember-svg-jar/inlined/protection-shield-skull", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>protection-shield-skull</title><path d=\"M24 1.953A1.959 1.959 0 0022.043.006H1.959A1.958 1.958 0 00.012 1.965L0 9.306a15.146 15.146 0 0011.861 14.669 1 1 0 00.4 0A15.145 15.145 0 0023.988 9.2zm-6 9.541a3 3 0 01-2.143 2.875.5.5 0 00-.357.48v.645a1.5 1.5 0 01-1.2 1.47.251.251 0 01-.3-.245v-1.725a.5.5 0 00-1 0v1.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-1.75a.5.5 0 00-1 0v1.725a.251.251 0 01-.3.245 1.5 1.5 0 01-1.2-1.47v-.645a.5.5 0 00-.357-.48A3 3 0 016 11.494v-1.5a6 6 0 0112 0z\"/><circle cx=\"9\" cy=\"10.494\" r=\"1.5\"/><circle cx=\"15\" cy=\"10.494\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});