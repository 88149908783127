define("ember-svg-jar/inlined/file-code-subtract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-subtract</title><path d=\"M6.5 10.97a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zM9 18.22H4a.75.75 0 010-1.5h5a.75.75 0 010 1.5zM17.75 11.532v3.5a.75.75 0 001.5 0v-3.5a.75.75 0 00-1.5 0zM13.75 8.532v-3.5a.75.75 0 00-1.5 0v3.5a.75.75 0 001.5 0zM20.25 8.032v-2.5A1.252 1.252 0 0019 4.282h-1.5a1.252 1.252 0 00-1.25 1.25v2.5a1.251 1.251 0 001.25 1.25H19a1.251 1.251 0 001.25-1.25zm-1.5-.5a.25.25 0 01-.25.25H18a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25z\"/><path d=\"M23.414 3.032L21 .618a2 2 0 00-1.415-.586H8a2 2 0 00-2 2v7.276a.247.247 0 00.242.249 19.68 19.68 0 011.485.083A.247.247 0 008 9.392v-6.86a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.147L21.854 4.3a.5.5 0 01.146.353v13.379a.5.5 0 01-.5.5h-6.858a.245.245 0 00-.241.21 7.935 7.935 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.446a2 2 0 00-.586-1.414z\"/><path d=\"M14.406 15.689a1.227 1.227 0 00.844-1.157v-2.5a1.25 1.25 0 00-1.25-1.25h-1.5a1.211 1.211 0 00-.914.429.192.192 0 00.029.179 8.134 8.134 0 011.008 1.01c.043.051.127.124.127.018a.111.111 0 01.115-.134h.635a.25.25 0 01.25.25v1.589a.235.235 0 00.021.1 7.983 7.983 0 01.484 1.376c.011.038.112.101.151.09z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});