define("ember-svg-jar/inlined/dog-leash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dog-leash</title><path d=\"M21.705 7.976a1.037 1.037 0 01-.934-.578 2.563 2.563 0 00-2.3-1.422 3.78 3.78 0 00-3.6 2.621L6.911.331A1 1 0 005.47 1.717l12.6 12.969a.25.25 0 00.414-.089 2.448 2.448 0 012.316-1.621 2.958 2.958 0 002.955-2.955 2.047 2.047 0 00-2.05-2.045zm-2.455.8a.75.75 0 11-.75-.75.749.749 0 01.75.748zM12.418 10.98a.251.251 0 00-.229-.072 3.381 3.381 0 01-.647.068H6.25c-1.446 0-2.444-.4-2.809-1.127-.373-.744-.087-1.844.788-3.016a1 1 0 00-1.181-1.524 4.5 4.5 0 00-.7 7.976 36.3 36.3 0 00-1.6 8.691 2 2 0 004 0v-.63a3.284 3.284 0 013.486-3.37c.144.005 1.516 0 1.514 0 2.505 0 4 1.279 4 3.423v.577a2 2 0 004 0c0-.6 0-1.071-.006-1.447a13.729 13.729 0 01.285-3.381l.028-.171a.254.254 0 00-.067-.213z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});