define("ember-svg-jar/inlined/exotic-food-kebab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>exotic-food-kebab</title><path d=\"M17.5 23a1 1 0 00-1-1h-3a.5.5 0 01-.5-.5v-.3a.248.248 0 00-.086-.189.25.25 0 00-.2-.059 5.248 5.248 0 01-1.431 0A.25.25 0 0011 21.2v.3a.5.5 0 01-.5.5h-3a1 1 0 000 2h9a1 1 0 001-1zM17.038 11.216A4.331 4.331 0 0013.423 6.3.5.5 0 0113 5.809V5a1 1 0 00-1-1 1 1 0 111-1 1 1 0 002 0 3 3 0 10-4.189 2.754.314.314 0 01.189.289.232.232 0 01-.207.231 4.331 4.331 0 00-3.831 4.942l.754 5.084a4.331 4.331 0 008.568 0zM9.385 9.25H12a.5.5 0 010 1H9.385a.5.5 0 110-1zM12 16.75H9.259a.5.5 0 010-1H12a.5.5 0 010 1zm3.256-3.5H12.5a.5.5 0 010-1h2.756a.5.5 0 010 1zM5.112 1.85a.5.5 0 00.018.623 2.057 2.057 0 01.081 2.578L4.8 5.6a1 1 0 11-1.6-1.2l.188-.251a.5.5 0 00-.018-.623A2.057 2.057 0 013.289.948L3.7.4a1 1 0 111.6 1.2zM18.888 1.85a.5.5 0 01-.018.623 2.057 2.057 0 00-.081 2.578l.411.549a1 1 0 101.6-1.2l-.188-.251a.5.5 0 01.018-.623 2.057 2.057 0 00.081-2.578L20.3.4a1 1 0 00-1.6 1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});