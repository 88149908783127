define("ember-svg-jar/inlined/smart-watch-circle-missed-call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-missed-call</title><path d=\"M12 3.5a8.5 8.5 0 108.5 8.5A8.5 8.5 0 0012 3.5zm2.125 12.754l-.456.455a1.681 1.681 0 01-2.107.213 18.7 18.7 0 01-5.234-5.233 1.682 1.682 0 01.21-2.106L7 9.126a1.289 1.289 0 011.817 0l.83.833a1.282 1.282 0 010 1.813l-.973.971a.249.249 0 00-.01.342 17.543 17.543 0 001.506 1.506.248.248 0 00.342-.01l.971-.971a1.272 1.272 0 01.905-.376 1.274 1.274 0 01.908.376l.83.831a1.285 1.285 0 01-.001 1.813zM15.854 8.1l-1.47 1.47a.25.25 0 000 .354l.47.469a.5.5 0 01-.354.854h-2a.5.5 0 01-.5-.5v-2a.5.5 0 01.854-.354l.469.47a.25.25 0 00.354 0L15.146 7.4a.5.5 0 11.708.707zM7.379 3.051a.125.125 0 00.135.014 9.98 9.98 0 018.972 0 .125.125 0 00.177-.144l-.68-2.55A.5.5 0 0015.5 0h-7a.5.5 0 00-.483.371l-.68 2.551a.125.125 0 00.042.129z\"/><path d=\"M16.663 21.078a.125.125 0 00-.177-.144 9.972 9.972 0 01-8.972 0 .125.125 0 00-.177.144l.68 2.551A.5.5 0 008.5 24h7a.5.5 0 00.483-.371z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});