define("ember-svg-jar/inlined/saving-bank-drawer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>saving-bank-drawer</title><path d=\"M9 13H1a1 1 0 00-1 1v8a1 1 0 001 1h8a1 1 0 001-1v-8a1 1 0 00-1-1zm-6.5 6.5a1 1 0 111-1 1 1 0 01-1 1zM20 13h-8a1 1 0 00-1 1v8a1 1 0 001 1h8a1 1 0 001-1v-8a1 1 0 00-1-1zm-6 6.5a1 1 0 111-1 1 1 0 01-1 1zM9 2H1a1 1 0 00-1 1v7.5a1 1 0 001 1h8a1 1 0 001-1V3a1 1 0 00-1-1zM2.5 8a1 1 0 111-1 1 1 0 01-1 1zM14.167 8.389a.25.25 0 00.208.111h3.75a.25.25 0 00.229-.35l-.911-2.486a.251.251 0 00-.235-.164h-1.916a.251.251 0 00-.235.164l-.911 2.486a.251.251 0 00.021.239z\"/><path d=\"M24 1.5a.5.5 0 00-.658-.474l-2.884.961a.238.238 0 01-.079.013H12a1 1 0 00-1 1v7.5a1 1 0 001 1h8.379a.267.267 0 01.079.013l2.884.961A.5.5 0 0024 12zm-4.5 7.75a.25.25 0 01-.25.25h-6a.25.25 0 01-.25-.25v-5a.25.25 0 01.25-.25h6a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});