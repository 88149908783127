define("ember-svg-jar/inlined/tank-top-female", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tank-top-female</title><path d=\"M22.5 20a.5.5 0 00.5-.5v-6.848a.967.967 0 00-.177-.557C20.813 9.23 20.531 3.37 20.5.983a.988.988 0 00-1-.981h-2.933a1 1 0 00-1.051.853C15.4 1.634 13.932 2.5 12.005 2.5S8.608 1.634 8.494.855A1 1 0 007.443 0H4.505a.989.989 0 00-1 .982C3.478 3.37 3.187 9.23 1.176 12.1a.968.968 0 00-.176.552V19.5a.5.5 0 00.5.5zM8.505 4.087a.25.25 0 01.354-.227 7.573 7.573 0 003.146.642 7.567 7.567 0 003.144-.641.25.25 0 01.354.227V6a3.5 3.5 0 01-7 0zM1.5 21.5a.5.5 0 00-.5.5v1a1 1 0 001 1h20a1 1 0 001-1v-1a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});