define("ember-svg-jar/inlined/car-dashboard-steering", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-dashboard-steering</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 3.5a8.494 8.494 0 017.824 5.2.25.25 0 01-.3.338 28.305 28.305 0 00-15.052 0 .25.25 0 01-.3-.338A8.494 8.494 0 0112 3.5zm1.5 8.5a1.5 1.5 0 11-1.5-1.5 1.5 1.5 0 011.5 1.5zM4 13a6 6 0 015.924 6.947.247.247 0 01-.113.172.253.253 0 01-.2.029 8.5 8.5 0 01-6-6.857.249.249 0 01.055-.2.253.253 0 01.177-.091zm10.286 7.178a.156.156 0 01-.2-.121A5.3 5.3 0 0114 19a6.006 6.006 0 016-6 1.419 1.419 0 01.173.011.245.245 0 01.172.1.251.251 0 01.05.191 8.5 8.5 0 01-6.109 6.876z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});