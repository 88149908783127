define("ember-svg-jar/inlined/truck-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>truck-2</title><path d=\"M23.75 13.5H6.233a4.524 4.524 0 00-2.785.965L.054 17.139A.142.142 0 000 17.25 1.752 1.752 0 001.75 19h1.6a.251.251 0 00.243-.311A2.8 2.8 0 013.5 18a3 3 0 016 0 2.8 2.8 0 01-.089.689.251.251 0 00.243.311h6.692a.251.251 0 00.243-.311A2.8 2.8 0 0116.5 18a3 3 0 016 0 2.761 2.761 0 01-.074.628.249.249 0 00.313.3A1.747 1.747 0 0024 17.25v-3.5a.25.25 0 00-.25-.25z\"/><path d=\"M21.25 4H6.486a4.344 4.344 0 00-3.907 2.415L.526 10.521A5.007 5.007 0 000 12.75v2.643a.251.251 0 00.405.2l2.424-1.91a5.524 5.524 0 013.4-1.18H23.75a.25.25 0 00.25-.25V6.75A2.753 2.753 0 0021.25 4zM7 9.75a.25.25 0 01-.25.25H4a.5.5 0 01-.447-.724l1-2A.5.5 0 015 7h1.75a.25.25 0 01.25.25zm5.5 0a.25.25 0 01-.25.25h-3A.25.25 0 019 9.75v-2.5A.25.25 0 019.25 7h3a.25.25 0 01.25.25zM18 9.5a.5.5 0 01-.5.5h-2.75a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h2.75a.5.5 0 01.5.5zm3.75 1h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zm0-2.5h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5z\"/><circle cx=\"6.5\" cy=\"18\" r=\"2\"/><circle cx=\"19.5\" cy=\"18\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});