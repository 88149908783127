define("ember-svg-jar/inlined/photo-frame-human", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>photo-frame-human</title><path d=\"M21.5 2.5A2.5 2.5 0 0019 0H5a2.5 2.5 0 00-2.5 2.5v19A2.5 2.5 0 005 24h14a2.5 2.5 0 002.5-2.5zM5 3.5a1 1 0 011-1h12a1 1 0 011 1v17a1 1 0 01-1 1H6a1 1 0 01-1-1z\"/><path d=\"M8.109 13.5h.667a.25.25 0 01.25.25v.75a1 1 0 001 1h2.041a.25.25 0 01.226.356 1.143 1.143 0 01-.252.359 2.172 2.172 0 01-1.592.3 3.373 3.373 0 00-.534-.007 1 1 0 00-.889.994 3.617 3.617 0 001.274 2.727 1 1 0 001.381-1.446.188.188 0 01-.05-.189.186.186 0 01.146-.129 3.149 3.149 0 001.629-.788 4.164 4.164 0 001.12-3.177 1 1 0 00-1-1h-2.25a.25.25 0 01-.25-.25v-.75a1 1 0 00-1-1H9.24a.251.251 0 01-.214-.12.253.253 0 01-.008-.246l1.395-2.671a1.084 1.084 0 00.113-.482 6.67 6.67 0 01.324-2.217 1 1 0 00-1.875-.7 8.3 8.3 0 00-.448 2.686l-1.76 3.376A1.505 1.505 0 008.109 13.5z\"/><path d=\"M13 9h1.406a1 1 0 000-2H13a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});