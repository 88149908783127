define("ember-svg-jar/inlined/style-two-pin-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-two-pin-remove</title><path d=\"M12 0a9.511 9.511 0 00-9.5 9.5c0 6.547 6.538 12.488 8.541 14.153a1.5 1.5 0 001.917 0c2-1.665 8.542-7.608 8.542-14.154A9.511 9.511 0 0012 0zm4.066 12.735a1 1 0 11-1.414 1.414l-2.3-2.3a.5.5 0 00-.708 0l-2.3 2.3a1 1 0 11-1.414-1.414l2.3-2.3a.5.5 0 000-.707l-2.3-2.3a1 1 0 111.418-1.41l2.3 2.3a.5.5 0 00.708 0l2.3-2.3a1 1 0 011.414 1.414l-2.3 2.3a.5.5 0 000 .707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});