define("ember-svg-jar/inlined/video-game-360-vr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-360-vr</title><path d=\"M23.751 12.7a.5.5 0 00-.5 0A23.32 23.32 0 0112 15.25 23.32 23.32 0 01.747 12.7a.5.5 0 00-.747.436V19c0 2.944 4.935 5 12 5s12-2.056 12-5v-5.864a.5.5 0 00-.249-.436zM22 19c0 1.077-3.342 3-10 3S2 20.077 2 19v-3.152a.5.5 0 01.715-.451c4.624 2.2 13.946 2.2 18.57 0a.5.5 0 01.715.451zM6.754 6.247L6 6.25A.25.25 0 015.75 6a.75.75 0 00-1.5 0A1.753 1.753 0 006 7.75h.757a2 2 0 001.99-2 1.981 1.981 0 00-.267-1 .5.5 0 010-.5 1.981 1.981 0 00.267-1 2 2 0 00-1.99-2H6A1.752 1.752 0 004.25 3a.75.75 0 001.5 0A.249.249 0 016 2.75h.756a.5.5 0 010 1 .75.75 0 000 1.5.5.5 0 010 1zM12 7.75a2.25 2.25 0 000-4.5.188.188 0 01-.089-.353 1.245 1.245 0 01.589-.147.75.75 0 000-1.5A2.752 2.752 0 009.75 4v1.5A2.253 2.253 0 0012 7.75zm0-3a.75.75 0 11-.75.75.751.751 0 01.75-.75zM17.5 7.75a2.253 2.253 0 002.25-2.25v-2a2.25 2.25 0 00-4.5 0v2a2.253 2.253 0 002.25 2.25zm-.75-4.25a.75.75 0 011.5 0v2a.75.75 0 01-1.5 0z\"/><circle cx=\"21.5\" cy=\"1\" r=\"1\"/><path d=\"M5.37 12.916a.5.5 0 00.63-.483V10.5a2.005 2.005 0 00-2.78-1.841 6.738 6.738 0 00-2.447 1.6.5.5 0 00.1.752 15.8 15.8 0 004.497 1.905zM18 10.5v1.933a.5.5 0 00.63.483 15.8 15.8 0 004.5-1.9.5.5 0 00.1-.752 6.738 6.738 0 00-2.447-1.6A2.005 2.005 0 0018 10.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});