define("ember-svg-jar/inlined/video-game-logo-playstation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-logo-playstation</title><path d=\"M17 11.7a3.727 3.727 0 002.657-.226 2.441 2.441 0 001.213-2.113V8.3a4.111 4.111 0 00-2.629-4.06L10.668.838a.522.522 0 00-.755.467v19.3a.524.524 0 00.288.467l4.174 2.087a.522.522 0 00.755-.467V6.557a.523.523 0 01.77-.457l.521.278a.524.524 0 01.277.461v4.382A.513.513 0 0017 11.7zM19.826 14.369a.522.522 0 000 1.044 7.922 7.922 0 011.763.159.521.521 0 01.273.855 6.106 6.106 0 01-1.773 1.27l-3.1 1.553a.521.521 0 00-.288.467v1.626a.521.521 0 00.771.458l5.45-2.973a2.259 2.259 0 001.073-2.144c-.195-1.958-2.216-2.315-4.169-2.315zM7.614 11.112l-5.975 2.656a2.76 2.76 0 00-1.14 4.1 4.269 4.269 0 004.957 1.564L8 18.51a.521.521 0 00.344-.491v-1.595a.522.522 0 00-.705-.489l-2.919 1.1a.966.966 0 01-.7-1.8l3.994-1.6a.521.521 0 00.328-.484v-1.562a.523.523 0 00-.734-.477z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});