define("ember-svg-jar/inlined/designer-community-behance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>designer-community-behance</title><path d=\"M12 8.89a3.88 3.88 0 00-.67-2.29A4.44 4.44 0 007.57 5H1a.5.5 0 00-.5.5v14a.5.5 0 00.5.5h6.09a9.88 9.88 0 002.07-.2 4 4 0 001.65-.8 4.12 4.12 0 001-1.18 4.48 4.48 0 00.65-2.39 4.05 4.05 0 00-.57-2.2A3.27 3.27 0 0010.24 12a3.51 3.51 0 001.12-.85A3.4 3.4 0 0012 8.89zM3.5 8.5A.5.5 0 014 8h3.5a1.5 1.5 0 110 3H4a.5.5 0 01-.5-.5zm6 6.5a2 2 0 01-2 2H4a.5.5 0 01-.5-.5v-3A.5.5 0 014 13h3.5a2 2 0 012 2z\"/><rect x=\"15.5\" y=\"4\" width=\"6\" height=\"2\" rx=\".5\" ry=\".5\"/><path d=\"M23 15a.5.5 0 00.5-.5 12 12 0 00-.09-1.65 5.4 5.4 0 00-.84-2.24A4.32 4.32 0 0020.73 9a4.77 4.77 0 00-2.23-.5 5.64 5.64 0 00-4 1.5 6 6 0 00-1.5 4.32 5.32 5.32 0 001.62 4.34A6.1 6.1 0 0018.5 20a5.06 5.06 0 004.61-2.22.5.5 0 00-.42-.78h-2a.5.5 0 00-.35.14l-.07.06a3.12 3.12 0 01-1.81.5 2.76 2.76 0 01-1.53-.41 2.37 2.37 0 01-1-1.7.5.5 0 01.49-.59zm-6.53-2a.43.43 0 01-.42-.53 2.5 2.5 0 014.89 0 .43.43 0 01-.42.53z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});