define("ember-svg-jar/inlined/pets-paw-trophy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pets-paw-trophy</title><path d=\"M23.4.987a1.459 1.459 0 00-1.358-.214l-1.542.536V1a.5.5 0 00-.5-.5H4a.5.5 0 00-.5.5v.312L1.958.773A1.464 1.464 0 00.6.987 1.513 1.513 0 000 2.19c-.008 8.116 4.407 8.252 4.729 8.194a8.516 8.516 0 005.1 3.827 1 1 0 01.742.893 11.168 11.168 0 01-.986 5.181A2 2 0 017.742 21.5H6.5a1 1 0 000 2h11a1 1 0 000-2h-1.243a2 2 0 01-1.844-1.224 11.715 11.715 0 01-.955-5.161 1 1 0 01.741-.909 8.517 8.517 0 005.077-3.821c.311.054 4.732-.073 4.724-8.194a1.514 1.514 0 00-.6-1.204zM2.011 2.91l1.489.52V6a8.446 8.446 0 00.235 1.967C2.68 7.177 2.1 5.492 2.011 2.91zM12 3.5a1 1 0 11-1 1 1 1 0 011-1zm-4 3a1 1 0 111 1 1 1 0 01-1-1zm6.33 3.952a.979.979 0 01-.854.548h-2.924a.979.979 0 01-.854-.548A1 1 0 019.78 9.43l1.463-1.995a.956.956 0 011.544 0l1.464 2.006a.98.98 0 01.082 1.011zm.7-2.952a1 1 0 111-1 1 1 0 01-1.002 1zm5.241.463A8.514 8.514 0 0020.5 6V3.427l1.486-.518c-.086 2.577-.663 4.261-1.717 5.054z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});