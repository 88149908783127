define("ember-svg-jar/inlined/crypto-currency-bitcoin-coins", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-coins</title><path d=\"M9.084 18.87a.25.25 0 00.234-.285 7.761 7.761 0 01-.081-1.085 7.394 7.394 0 01.065-.947.251.251 0 00-.264-.282c-.64.041-1.253.059-1.8.059A21.894 21.894 0 01.6 15.419a.25.25 0 00-.335.235v1.913c0 .747 3.122 1.351 6.974 1.351.638 0 1.261-.018 1.845-.048zM.6 3.419a.25.25 0 00-.335.235V5.5a.25.25 0 00.132.22c.857.459 3.1 1.106 6.842 1.106s5.987-.647 6.847-1.128a.252.252 0 00.128-.219V3.654a.25.25 0 00-.335-.235 21.9 21.9 0 01-6.64.911A21.894 21.894 0 01.6 3.419zM7.237 10.83c3.748 0 5.987-.647 6.847-1.128a.252.252 0 00.128-.219V7.654a.25.25 0 00-.335-.235 21.9 21.9 0 01-6.64.911A21.894 21.894 0 01.6 7.419a.25.25 0 00-.335.235V9.5a.25.25 0 00.132.22c.855.463 3.094 1.11 6.84 1.11zM9.592 14.726a.248.248 0 00.211-.157A8.028 8.028 0 0111 12.514a.25.25 0 00-.227-.4 28.752 28.752 0 01-3.531.22A21.894 21.894 0 01.6 11.419a.25.25 0 00-.335.235V13.5a.25.25 0 00.132.22c.857.459 3.1 1.106 6.842 1.106.873.004 1.643-.038 2.353-.1zM.263 1.351V1.5a.25.25 0 00.132.22c.857.459 3.1 1.106 6.842 1.106s5.987-.647 6.847-1.128a.252.252 0 00.128-.219v-.128C14.212.606 11.09 0 7.237 0S.263.606.263 1.351zM16.237 15.25a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h1.5a.75.75 0 000-1.5zM17.737 18.25h-1.5a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h1.5a.75.75 0 000-1.5z\"/><path d=\"M17.237 11a6.5 6.5 0 106.5 6.5 6.5 6.5 0 00-6.5-6.5zm-2.75 3.5a.75.75 0 01.75-.75.25.25 0 00.25-.25.75.75 0 011.5 0 .25.25 0 00.25.25h.5a2.253 2.253 0 012.25 2.25 2.219 2.219 0 01-.46 1.349.249.249 0 000 .3 2.219 2.219 0 01.46 1.351 2.253 2.253 0 01-2.25 2.25h-.5a.25.25 0 00-.25.25.75.75 0 01-1.5 0 .25.25 0 00-.25-.25.75.75 0 01-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});