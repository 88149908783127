define("ember-svg-jar/inlined/shape-cube-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shape-cube-alternate</title><rect x=\"8.75\" y=\"8.75\" width=\"6.5\" height=\"6.5\" rx=\".25\" ry=\".25\"/><path d=\"M7 7.25A.25.25 0 007.25 7V2.293a.25.25 0 00-.427-.177L2.116 6.823a.25.25 0 00.177.427zM17 16.75a.25.25 0 00-.25.25v4.707a.25.25 0 00.427.177l4.707-4.707a.25.25 0 00-.177-.427zM7.177 15.763a.251.251 0 00.073-.177V9A.25.25 0 007 8.75H1.5a.25.25 0 00-.25.25v12.086a.25.25 0 00.427.177zM15.25 17a.25.25 0 00-.25-.25H8.414a.251.251 0 00-.177.073l-5.5 5.5a.25.25 0 00.176.427H15a.25.25 0 00.25-.25zM16.823 8.237a.251.251 0 00-.073.177V15a.25.25 0 00.25.25h5.5a.25.25 0 00.25-.25V2.914a.25.25 0 00-.427-.177zM8.75 7a.25.25 0 00.25.25h6.586a.251.251 0 00.177-.073l5.5-5.5a.25.25 0 00-.177-.427H9a.25.25 0 00-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});