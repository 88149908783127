define("ember-svg-jar/inlined/phone-action-lock-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-lock-1</title><path d=\"M13 15.01v3.49a1 1 0 01-1 1H5a1 1 0 01-1-1v-11a1 1 0 011-1h5.26a.249.249 0 00.246-.241 3.228 3.228 0 01.639-1.859.246.246 0 00-.2-.4H4.5A2.5 2.5 0 002 6.5v15A2.5 2.5 0 004.5 24h8a2.5 2.5 0 002.5-2.5V15a.247.247 0 00-.249-.25c-.383 0-1.113.013-1.5.007a.247.247 0 00-.251.253z\"/><path d=\"M20.5 4.75V3.5a3.5 3.5 0 00-7 0v1.25a1.5 1.5 0 00-1.5 1.5v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5v-5.5a1.5 1.5 0 00-1.5-1.5zm-5-1.25a1.5 1.5 0 013 0v1.25h-3zm2.5 6a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});