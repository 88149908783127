define("ember-svg-jar/inlined/cat-grass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cat-grass</title><path d=\"M14.25 20h-12a.5.5 0 00-.493.582l.5 3A.5.5 0 002.75 24h11a.5.5 0 00.493-.418l.5-3A.5.5 0 0014.25 20zM15.25 16.5h-1.667l.355-1.22a1 1 0 10-1.92-.559l-.466 1.6a.249.249 0 01-.24.18H10.5a.25.25 0 01-.25-.25v-7.18a1 1 0 10-2 0v7.179a.25.25 0 01-.25.25h-.845a.25.25 0 01-.249-.23l-.461-5.849a1 1 0 10-1.994.159l.449 5.651a.248.248 0 01-.249.269h-.435a.249.249 0 01-.242-.187L2.265 9.748a1 1 0 00-1.936.5L1.956 16.5H1.25a1 1 0 000 2h14a1 1 0 000-2zM23.359.076a.758.758 0 00-.806.1l-3.769 2.688a7.1 7.1 0 00-2.068 0L12.948.173a.759.759 0 00-.807-.1.761.761 0 00-.391.712v7c0 3.317 2.019 5 6 5s6-1.683 6-5v-7a.761.761 0 00-.391-.709zm-9.409 6.21a2 2 0 012.743.686.5.5 0 01-.858.514 1 1 0 00-1.371-.343.5.5 0 11-.514-.857zM16.788 9.6a.5.5 0 01.462-.309h1a.5.5 0 01.354.853l-.5.5a.5.5 0 01-.708 0l-.5-.5a.5.5 0 01-.108-.544zm4.261-2.424a1 1 0 00-1.366.365.5.5 0 11-.866-.5 2 2 0 012.733-.731.5.5 0 01-.5.866z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});