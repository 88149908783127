define("ember-svg-jar/inlined/phone-action-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-check</title><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/><path d=\"M12 6.5a4.5 4.5 0 104.5 4.5A4.505 4.505 0 0012 6.5zm2.382 3.787l-1.937 2.582a1.267 1.267 0 01-1.884.131l-1-1a.75.75 0 011.06-1.061l.8.8 1.764-2.351a.75.75 0 111.2.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});