define("ember-svg-jar/inlined/wifi-signal-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-signal-2</title><path d=\"M20.339 1.666a1 1 0 10-1.414 1.414 10.5 10.5 0 010 14.85 1 1 0 001.414 1.415 12.5 12.5 0 000-17.679z\"/><path d=\"M17.509 4.494A1 1 0 0016.1 5.91a6.5 6.5 0 010 9.192 1 1 0 001.415 1.414 8.5 8.5 0 000-12.022zM2 10.506A10.436 10.436 0 015.075 3.08a1 1 0 00-1.414-1.414 12.5 12.5 0 000 17.679 1 1 0 001.414-1.415A10.428 10.428 0 012 10.506z\"/><path d=\"M7.9 4.494a1 1 0 00-1.415 0 8.5 8.5 0 000 12.02A1 1 0 107.9 15.1a6.5 6.5 0 010-9.193 1 1 0 000-1.413zM12 7.373a2.993 2.993 0 00-1 5.816v8.434a1 1 0 102 0v-8.434a2.993 2.993 0 00-1-5.816z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});