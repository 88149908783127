define("ember-svg-jar/inlined/smiley-drool", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-drool</title><path d=\"M23 11a11 11 0 10-11 11c.109 0 .219 0 .327-.006h.173a2.525 2.525 0 00.446-.04h.022l.11-.01a.1.1 0 01.1.062A3 3 0 0019 21v-1.4a.25.25 0 01.089-.191A10.982 10.982 0 0023 11zm-7 11a1 1 0 01-1-1v-1.5a1 1 0 00-2 0 .5.5 0 01-1 0v-1.4a2.01 2.01 0 011.718-1.98l3-.428a.25.25 0 01.285.248V21A1 1 0 0116 22zm1.858-8.49l-4.423.632a4.021 4.021 0 00-3.124 2.423.249.249 0 01-.3.144 7.456 7.456 0 01-4.459-3.452.573.573 0 01.494-.86h11.9a.573.573 0 01.5.859l-.079.134a.251.251 0 01-.245.118.973.973 0 00-.264.002zM9 5.25a1 1 0 010 2 2.656 2.656 0 00-2.1.95 1 1 0 11-1.795-.9C5.858 5.8 7.847 5.25 9 5.25zm9.447 3.395a1 1 0 01-1.342-.445A2.656 2.656 0 0015 7.25a1 1 0 010-2c1.153 0 3.142.547 3.895 2.053a1 1 0 01-.448 1.342z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});