define("ember-svg-jar/inlined/lucide-microwave", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect x=\"2\" y=\"4\" width=\"20\" height=\"15\" rx=\"2\"/><rect x=\"6\" y=\"8\" width=\"8\" height=\"7\" rx=\"1\"/><path d=\"M18 8v7M6 19v2M18 19v2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});