define("ember-svg-jar/inlined/design-pen-tool", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-pen-tool</title><path d=\"M24 3a2 2 0 00-2-2H2a2 2 0 00-2 2v18a2 2 0 002 2h20a2 2 0 002-2zm-18.5.583a1 1 0 111 1 1 1 0 01-1-1zm3.5 0a1 1 0 111 1 1 1 0 01-1-1zm-6-1a1 1 0 11-1 1 1 1 0 011-1zM22 20.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5V7a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M19.75 15.5a1.252 1.252 0 00-1.25-1.25h-2.537l-.292-.586A.75.75 0 0015 13.25h-2a1.252 1.252 0 00-1.25 1.25v4A1.252 1.252 0 0013 19.75h5.5a1.252 1.252 0 001.25-1.25zm-1.5 2.5a.25.25 0 01-.25.25h-4.5a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25h.882a.252.252 0 01.224.138l.223.448a.75.75 0 00.671.414H18a.25.25 0 01.25.25zM10.5 13a1.5 1.5 0 001.436-1.067l5.972-1.406a1.5 1.5 0 10-.344-1.46l-5.972 1.406A1.5 1.5 0 1010.5 13zM3.75 16.5A1.252 1.252 0 005 17.75h2a1.252 1.252 0 001.25-1.25v-6A1.252 1.252 0 007 9.25H5a1.252 1.252 0 00-1.25 1.25zm1.75-.25a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h1a.25.25 0 01.25.25V16a.25.25 0 01-.25.25zm1-5.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25V11a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});