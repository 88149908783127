define("ember-svg-jar/inlined/style-three-pin-air-boat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-air-boat</title><rect x=\"9.5\" y=\"10.5\" width=\"5\" height=\"2\" rx=\".5\" ry=\".5\"/><path d=\"M12 16a2.5 2.5 0 002.421-1.877.5.5 0 00-.484-.625h-3.874a.5.5 0 00-.484.625A2.5 2.5 0 0012 16zM14.5 9a.5.5 0 00-.2-.4l-2-1.5a.5.5 0 00-.6 0l-2 1.5a.5.5 0 00.3.9h4a.5.5 0 00.5-.5z\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.749 7.3 12.286 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.245 22.5 9.5 22.5 4.262 17.79 0 12 0zM6.5 8a.97.97 0 01-.5.846.5.5 0 00-.252.435V14.5a.75.75 0 11-1.5 0V9.283A.5.5 0 004 8.848.97.97 0 013.5 8V6a1 1 0 011-1h1a1 1 0 011 1zm10 5.5a4.5 4.5 0 01-9 0v-5a2.514 2.514 0 011-2l2-1.5a2.514 2.514 0 013 0l2 1.5a2.514 2.514 0 011 2zM20 8.848a.5.5 0 00-.252.435V14.5a.75.75 0 11-1.5 0V9.283A.5.5 0 0018 8.848.97.97 0 0117.5 8V6a1 1 0 011-1h1a1 1 0 011 1v2a.97.97 0 01-.5.848z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});