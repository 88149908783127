define("ember-svg-jar/inlined/body-care-water-hand-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>body-care-water-hand-1</title><path d=\"M14.5 18.815H7a.5.5 0 010-1h3a.5.5 0 00.5-.5 2 2 0 00-2-2H3.25a.25.25 0 01-.25-.25.75.75 0 10-1.5 0v7.5a.75.75 0 001.5 0 .25.25 0 01.25-.25H17a.5.5 0 00.5-.5 3 3 0 00-3-3zM18.311 1.073a1.041 1.041 0 00-1.622 0C15.125 3.238 12.5 7.188 12.5 9.315a4.89 4.89 0 005 5 4.89 4.89 0 005-5c0-2.127-2.625-6.077-4.189-8.242zM17.5 12.565a3.254 3.254 0 01-3.25-3.25.5.5 0 111 0 2.253 2.253 0 002.25 2.25.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});