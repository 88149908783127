define("ember-svg-jar/inlined/road-sign-left-turn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-left-turn</title><path d=\"M23.53 11.47l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0l11-11a.749.749 0 000-1.06zm-9.03 1.28H12a.25.25 0 00-.25.25v4a.75.75 0 01-1.5 0V7a.75.75 0 011.5 0v4a.25.25 0 00.25.25h2.5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});