define("ember-svg-jar/inlined/archery-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>archery-person</title><circle cx=\"7.794\" cy=\"3.307\" r=\"2.5\"/><path d=\"M17.759 15.62a1 1 0 001.641 1.144c3.741-5.356 3.741-10.788 0-16.144a1 1 0 00-1.641 1.144c3.256 4.662 3.256 9.194 0 13.856z\"/><path d=\"M7.681 11.192H5.544a.25.25 0 00-.25.25v3.679a.946.946 0 01-.01.141l-.968 6.79a1.5 1.5 0 001.223 1.733 1.527 1.527 0 00.259.022 1.5 1.5 0 001.474-1.246l.775-4.96a.25.25 0 01.494 0l.775 4.96a1.5 1.5 0 102.956-.509l-.968-6.79a1.1 1.1 0 01-.01-.141v-4.679a.25.25 0 01.25-.25h5.75a1.5 1.5 0 000-3h-7.058a.251.251 0 00-.214.12.253.253 0 00-.008.246 2.456 2.456 0 01.2 1.765 2.585 2.585 0 01-2.533 1.869z\"/><path d=\"M1.794 8.692a1.5 1.5 0 001.5 1.5h4.5a1.5 1.5 0 100-3h-4.5a1.5 1.5 0 00-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});