define("ember-svg-jar/inlined/gesture-swipe-horizontal-left-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-horizontal-left-2</title><path d=\"M23.61 10.278a10.9 10.9 0 00-5.844-3.121 1 1 0 00-.443 1.95 9.011 9.011 0 014.827 2.538 1 1 0 001.46-1.367zM7.712 7.913a1 1 0 00-1.2-.754 17.263 17.263 0 00-3.7 1.262.251.251 0 01-.313-.08l-.519-.729a.75.75 0 00-1.35.307l-.498 2.936a.749.749 0 00.616.867l2.941.489a.751.751 0 00.735-1.174l-.441-.62a.252.252 0 01-.038-.211.256.256 0 01.14-.163 15.807 15.807 0 012.873-.934 1 1 0 00.754-1.196zM16.55 12.413L12.809 11.3a.25.25 0 01-.179-.239V6.489a1 1 0 10-2 0v7.063a.25.25 0 01-.455.143c-1.49-2.138-3.856-1.449-2.762.224l2.913 4.368a.5.5 0 00.416.222h5.434a.5.5 0 00.492-.411c.2-1.1.664-3.683.776-4.165a1.281 1.281 0 00-.894-1.52z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});