define("ember-svg-jar/inlined/music-player-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-player-1</title><circle cx=\"9\" cy=\"18.75\" r=\"2\"/><path d=\"M21.5 4.5a1.5 1.5 0 00-2.856-.641.251.251 0 01-.219.141 2 2 0 000 4 .251.251 0 01.219.143 1.492 1.492 0 00.285.407.254.254 0 01.071.173v6.527a1.75 1.75 0 01-3.5 0V2.5A2.5 2.5 0 0013 0H5a2.5 2.5 0 00-2.5 2.5v19A2.5 2.5 0 005 24h8a2.5 2.5 0 002.5-2.5v-2.542a.249.249 0 01.344-.232A3.716 3.716 0 0017.25 19 3.755 3.755 0 0021 15.25V8.723a.254.254 0 01.071-.175A1.492 1.492 0 0021.5 7.5zm-9.5 9H6A1.5 1.5 0 014.5 12V3.5A1.5 1.5 0 016 2h6a1.5 1.5 0 011.5 1.5V12a1.5 1.5 0 01-1.5 1.5zm.5 5.25a3.5 3.5 0 11-3.5-3.5 3.5 3.5 0 013.5 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});