define("ember-svg-jar/inlined/saving-money-seedling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>saving-money-seedling</title><path d=\"M23.721 7.814l-3.086-3.935a1.25 1.25 0 00-1.755-.211l-5.536 4.343a.251.251 0 01-.4-.2V1.534a1 1 0 00-2 0v1.845a.249.249 0 01-.125.217.253.253 0 01-.25 0L4.572.134a1.252 1.252 0 00-1.708.457L.113 5.354a.749.749 0 00.275 1.024l7.557 4.366a1.245 1.245 0 00.623.168 1.286 1.286 0 00.326-.043 1.239 1.239 0 00.758-.583l.821-1.42a.25.25 0 01.466.125v4.027a.249.249 0 01-.324.238l-4.149-1.29a1.251 1.251 0 00-1.566.822l-.821 2.644A1.251 1.251 0 004.9 17l5.043 1.568a1.243 1.243 0 00.369.056 1.117 1.117 0 00.307-.044.249.249 0 01.319.24v.967a.25.25 0 01-.25.25h-2a1 1 0 00-.948 1.313l.781 2.342a.5.5 0 00.479.342h6a.5.5 0 00.475-.342l.78-2.343a1 1 0 00-.949-1.315h-2.117a.25.25 0 01-.25-.25v-7.17a.25.25 0 01.447-.154l1.681 2.14a1.254 1.254 0 00.986.477 1.241 1.241 0 00.769-.266l6.686-5.241a1.252 1.252 0 00.213-1.756zm-4.782 1.47a1.25 1.25 0 11-1.25-1.25 1.25 1.25 0 011.25 1.25zm-11.5-4a1.25 1.25 0 11-1.25-1.25 1.25 1.25 0 011.25 1.25zm.75 10.884a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});