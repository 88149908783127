define("ember-svg-jar/inlined/phone-action-text-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-text-1</title><path d=\"M13.5 15.5H12a.25.25 0 00-.25.25v2.75a1 1 0 01-1 1h-7a1 1 0 01-1-1v-11a1 1 0 011-1H7.5a.25.25 0 00.25-.25v-2A.25.25 0 007.5 4H3.25a2.5 2.5 0 00-2.5 2.5v15a2.5 2.5 0 002.5 2.5h8a2.5 2.5 0 002.5-2.5v-5.75a.25.25 0 00-.25-.25z\"/><path d=\"M21.25 0h-10a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V2a2 2 0 00-2-2zM21 12h-9.5a.25.25 0 01-.25-.25v-9.5A.25.25 0 0111.5 2H21a.25.25 0 01.25.25v9.5A.25.25 0 0121 12z\"/><path d=\"M18.75 4.75h-5a.75.75 0 000 1.5h5a.75.75 0 000-1.5zM16.25 7.75h-2.5a.75.75 0 000 1.5h2.5a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});