define("ember-svg-jar/inlined/single-man-actions-up-down.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-up-down</title><path d=\"M.619 18.062H2.41a.247.247 0 01.247.227l.443 5.257a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h1.053a.247.247 0 00.247-.24 7.878 7.878 0 01.956-3.53.244.244 0 00.03-.118V14.1a5.443 5.443 0 10-10.886 0v3.464a.494.494 0 00.495.498z\"/><path d=\"M1.361 4.206A4.206 4.206 0 105.567 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM14.227 13.608v5.2a.248.248 0 01-.248.248h-.989a.495.495 0 00-.4.782l2.471 3.462a.5.5 0 00.806 0l2.474-3.464a.495.495 0 00-.4-.782h-.99a.248.248 0 01-.251-.254v-5.2a1.237 1.237 0 10-2.474 0zM20.505 12.826L18.03 16.29a.495.495 0 00.4.782h.99a.248.248 0 01.247.248v5.2a1.237 1.237 0 102.474 0v-5.2a.248.248 0 01.248-.248h.989a.495.495 0 00.4-.782l-2.468-3.464a.513.513 0 00-.805 0z\"/><path d=\"M.619 18.062H2.41a.247.247 0 01.247.227l.443 5.257a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h1.053a.247.247 0 00.247-.24 7.878 7.878 0 01.956-3.53.244.244 0 00.03-.118V14.1a5.443 5.443 0 10-10.886 0v3.464a.494.494 0 00.495.498z\"/><path d=\"M1.361 4.206A4.206 4.206 0 105.567 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM14.227 13.608v5.2a.248.248 0 01-.248.248h-.989a.495.495 0 00-.4.782l2.471 3.462a.5.5 0 00.806 0l2.474-3.464a.495.495 0 00-.4-.782h-.99a.248.248 0 01-.251-.254v-5.2a1.237 1.237 0 10-2.474 0zM20.505 12.826L18.03 16.29a.495.495 0 00.4.782h.99a.248.248 0 01.247.248v5.2a1.237 1.237 0 102.474 0v-5.2a.248.248 0 01.248-.248h.989a.495.495 0 00.4-.782l-2.468-3.464a.513.513 0 00-.805 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});