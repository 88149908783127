define("ember-svg-jar/inlined/seafood-salmon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seafood-salmon</title><path d=\"M23.911 9.093A1 1 0 0022.52 7.8a7.994 7.994 0 00-3.128 2.757.25.25 0 01-.4.055C17.362 8.944 13.433 6 6 6a6 6 0 000 12c7.168 0 11.269-3.024 12.971-4.672a.25.25 0 01.395.063 7.9 7.9 0 003.155 2.809 1 1 0 001.39-1.29 7.251 7.251 0 010-5.817zM6 16.5a4.5 4.5 0 114.5-4.5A4.5 4.5 0 016 16.5z\"/><path d=\"M6 14.75A2.75 2.75 0 118.75 12 2.753 2.753 0 016 14.75zm0-4A1.25 1.25 0 107.25 12 1.251 1.251 0 006 10.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});