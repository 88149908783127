define("ember-svg-jar/inlined/feed-dailybooth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>feed-dailybooth</title><path d=\"M22.16 18.38a12 12 0 10-2.83 3.11l3.77 1.36a.5.5 0 00.63-.66zM19.32 15a1.21 1.21 0 01-.79.62L7.79 18.15a1.15 1.15 0 01-.26 0 1.38 1.38 0 01-1.29-1.1l-1.57-6.64a1.47 1.47 0 01.13-1 1.21 1.21 0 01.79-.62l2.29-.54a4.33 4.33 0 016.25-1.47l2.21-.52a1.21 1.21 0 01.91.16 1.47 1.47 0 01.64.91L19.45 14a1.47 1.47 0 01-.13 1z\"/><path d=\"M12.23 8.44a3.65 3.65 0 103.65 3.65 3.65 3.65 0 00-3.65-3.65zm0 5.79a2.15 2.15 0 112.15-2.15 2.15 2.15 0 01-2.15 2.15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});