define("ember-svg-jar/inlined/safety-helmet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-helmet</title><path d=\"M2.25 17a.5.5 0 00-.5.5V20a2 2 0 004 0 1 1 0 011-1h10.5a2.5 2.5 0 002.163-1.25.5.5 0 00-.433-.75zM22.75 13.5h-2v-1.015a10.628 10.628 0 00-9.324-10.459 3.122 3.122 0 00-2.375.674l-2.2 1.824a8.544 8.544 0 00-5.573 7.961A1.016 1.016 0 00.25 13.49v1a1.017 1.017 0 001.027 1.01H22.75a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});