define("ember-svg-jar/inlined/design-tool-quill-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-tool-quill-3</title><path d=\"M23.267.732A2.511 2.511 0 0021.19.019a16.594 16.594 0 00-7.18 2.7.252.252 0 00-.106.257l1.315 6.572a.249.249 0 00.422.127l2.5-2.524a.5.5 0 01.71.7l-2.2 2.222a.25.25 0 00.177.426H20.8a.248.248 0 00.2-.099 16.571 16.571 0 002.98-7.59 2.481 2.481 0 00-.713-2.078zM9.375 15.989a.25.25 0 00.068-.225l-1.394-6.97a.251.251 0 00-.422-.128L4.732 11.56A2.487 2.487 0 004 13.328v5.154a.251.251 0 01-.073.177L.293 22.293a1 1 0 001.414 1.414zM10.225 14.579a.251.251 0 00.423.127l3.72-3.75a.25.25 0 00.068-.225l-1.372-6.858a.247.247 0 00-.161-.187.253.253 0 00-.244.043q-.567.472-1.092 1L8.9 7.392a.249.249 0 00-.068.225zM10.381 16.5a.248.248 0 00-.178.074l-2.976 3a.25.25 0 00.178.426h3.266a2.481 2.481 0 001.768-.733l2.341-2.34a.25.25 0 00-.18-.427zM15.34 11.5a.247.247 0 00-.177.074l-3.472 3.5a.25.25 0 00.178.426H16.1a.249.249 0 00.178-.075l3.438-3.5a.25.25 0 00-.178-.425z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});