define("ember-svg-jar/inlined/messages-people-user-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-user-star</title><path d=\"M13.048 8.589l-.687 1.577a.937.937 0 00.235 1.083.956.956 0 001.122.125l1.788-1.007 1.794 1.007a.957.957 0 001.122-.125.937.937 0 00.235-1.083l-.688-1.578 1.252-.988a.928.928 0 00-.653-1.6h-1.411l-.774-1.935c0-.013-.011-.027-.018-.041a.957.957 0 00-.858-.524.973.973 0 00-.877.565L13.855 6h-1.412a.938.938 0 00-.875.574.963.963 0 00.267 1.069z\"/><path d=\"M22 0H9a2 2 0 00-2 2v11a2 2 0 002 2h.5v2.5a1 1 0 001.707.707L14.414 15H22a2 2 0 002-2V2a2 2 0 00-2-2zm0 12.75a.25.25 0 01-.25.25H14a1 1 0 00-.707.293L11.5 15.086V14a1 1 0 00-1-1H9.25a.25.25 0 01-.25-.25V2.25A.25.25 0 019.25 2h12.5a.25.25 0 01.25.25zM3.505 13a2.75 2.75 0 10-.01 0A3.5 3.5 0 000 16.5v2a.5.5 0 00.5.5h.821a.25.25 0 01.249.225L2 23.55a.5.5 0 00.5.45h2a.5.5 0 00.5-.45l.432-4.325A.25.25 0 015.679 19H6.5a.5.5 0 00.5-.5v-2A3.5 3.5 0 003.505 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});