define("ember-svg-jar/inlined/couple-man-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>couple-man-man</title><path d=\"M17.5 3.232A3.1 3.1 0 0014.854.019a2.835 2.835 0 00-2.654 1.22.25.25 0 01-.406 0A2.831 2.831 0 009.146.019 3.1 3.1 0 006.5 3.232c0 2.427 3.423 5.312 4.9 6.44a.981.981 0 001.208 0c1.468-1.127 4.892-4.013 4.892-6.44zM8.366 18.38a.25.25 0 01-.033-.424 4.4 4.4 0 10-5.166 0 .25.25 0 01-.033.424A5.757 5.757 0 000 23.5a.5.5 0 00.5.5H11a.5.5 0 00.5-.5 5.757 5.757 0 00-3.134-5.12zM5.75 16.8a2.4 2.4 0 01-2.276-3.156.251.251 0 01.154-.156.247.247 0 01.218.025 5.616 5.616 0 003.037.888 5.7 5.7 0 00.966-.083.249.249 0 01.292.265A2.4 2.4 0 015.75 16.8zM20.866 18.38a.25.25 0 01-.033-.424 4.4 4.4 0 10-5.166 0 .25.25 0 01-.033.424A5.757 5.757 0 0012.5 23.5a.5.5 0 00.5.5h10.5a.5.5 0 00.5-.5 5.757 5.757 0 00-3.134-5.12zM18.25 16.8a2.4 2.4 0 01-2.276-3.156.251.251 0 01.154-.156.247.247 0 01.218.025 5.616 5.616 0 003.037.888 5.7 5.7 0 00.966-.083.249.249 0 01.292.265A2.4 2.4 0 0118.25 16.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});