define("ember-svg-jar/inlined/road-sign-slide-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-slide-left</title><path d=\"M22.78 11.22l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0l11-11a.749.749 0 000-1.06zM14 16.75a.75.75 0 01-1.5 0v-2.586a.251.251 0 00-.073-.177L9.72 11.28a.747.747 0 01-.22-.53v-1a.25.25 0 00-.25-.25h-1a.75.75 0 01-.53-1.28l2-2a.749.749 0 011.06 0l2 2a.75.75 0 01-.53 1.28h-1a.25.25 0 00-.25.25v.586a.251.251 0 00.073.177l2.561 2.56a1.258 1.258 0 01.366.884z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});