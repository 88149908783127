define("ember-svg-jar/inlined/smart-house-light-on", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-house-light-on</title><path d=\"M8.108 5.768a.252.252 0 01-.1-.2V4.5a1 1 0 00-.4-.8.252.252 0 01-.1-.2V2.25A.25.25 0 017.754 2h9.5a.25.25 0 01.25.25V3.5a.032.032 0 01-.033.032h-.863a.82.82 0 00-.782.427.822.822 0 00.14.88l1.755 2.277a.962.962 0 001.564 0l1.757-2.278a.821.821 0 00.139-.88.82.82 0 00-.781-.427h-.864A.032.032 0 0119.5 3.5V1a1 1 0 00-1-1h-12a1 1 0 00-1 1v2.5a.252.252 0 01-.1.2 1 1 0 00-.4.8v1.065a.25.25 0 01-.1.2 2.75 2.75 0 103.208 0zM6.5 11.75a.75.75 0 00-.75.75v1a.75.75 0 001.5 0v-1a.75.75 0 00-.75-.75zM9.473 10.97a.749.749 0 000 1.06l.5.5a.75.75 0 101.061-1.06l-.5-.5a.75.75 0 00-1.061 0z\"/><path d=\"M12.754 8A.75.75 0 0012 7.25h-1a.75.75 0 100 1.5h1a.75.75 0 00.754-.75zM2.473 10.97l-.5.5a.75.75 0 001.061 1.06l.5-.5a.75.75 0 00-1.061-1.06zM2.754 8A.75.75 0 002 7.25H1a.75.75 0 100 1.5h1A.75.75 0 002.754 8zM14.192 21.489a.25.25 0 01-.056-.452 2.85 2.85 0 10-2.972 0 .25.25 0 01-.057.452 5.163 5.163 0 00-2.492 1.7A.5.5 0 009 24h7.3a.5.5 0 00.389-.814 5.165 5.165 0 00-2.497-1.697zM16.382 8.31a1 1 0 00-1.414 1.415 5 5 0 007.071 0 1 1 0 10-1.414-1.415 3.073 3.073 0 01-4.243 0z\"/><path d=\"M23.453 11.554a1 1 0 00-1.414 0 5.006 5.006 0 01-7.071 0 1 1 0 10-1.414 1.414 7.007 7.007 0 009.9 0 1 1 0 00-.001-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});