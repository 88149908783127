define("ember-svg-jar/inlined/synchronize-arrow-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>synchronize-arrow-clock</title><path d=\"M12.965 6a1 1 0 00-1 1v5.5a1 1 0 001 1h5a1 1 0 000-2h-3.75a.25.25 0 01-.25-.25V7a1 1 0 00-1-1z\"/><path d=\"M12.567 1.258A10.822 10.822 0 002.818 8.4a.25.25 0 01-.271.163L.858 8.309a.514.514 0 00-.485.213.5.5 0 00-.021.53l2.679 4.7a.5.5 0 00.786.107l3.77-3.746a.5.5 0 00-.279-.85l-1.715-.256a.25.25 0 01-.192-.35 8.259 8.259 0 117.866 11.59 1.25 1.25 0 00.045 2.5h.047a10.751 10.751 0 10-.792-21.487z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});