define("ember-svg-jar/inlined/style-three-pin-plane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-plane</title><path d=\"M12.5 0C6.71 0 2 4.262 2 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.7 21.784 23 15.246 23 9.5 23 4.262 18.29 0 12.5 0zm6.757 10.056L10.1 15.744a.56.56 0 01-.621-.018l-3.363-2.357a.31.31 0 01-.047-.437.327.327 0 01.053-.052l1.031-.864a.31.31 0 01.3-.052l1.479.882a.5.5 0 00.554-.028l1.759-1.3a.5.5 0 00-.035-.827L7.592 8.448a.387.387 0 01-.044-.548.378.378 0 01.062-.058l1.038-.769a.386.386 0 01.364-.052l5.607 2.088a.5.5 0 00.456-.056l2.493-1.7a1.5 1.5 0 011.187-.228 1.612 1.612 0 011.155 1.039 1.522 1.522 0 01.09.513 1.593 1.593 0 01-.743 1.379z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});