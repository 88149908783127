define("ember-svg-jar/inlined/cloud-phone-exchange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-phone-exchange</title><path d=\"M22.879 3.2a3.749 3.749 0 00-2.052-1.014.25.25 0 01-.167-.1A4.943 4.943 0 0016.625 0a4.87 4.87 0 00-4.663 3.359.253.253 0 01-.194.171 3.1 3.1 0 00-1.415.653A2.975 2.975 0 009.25 6.49a2.855 2.855 0 00.82 2.089 3.594 3.594 0 002.378.921h8.058a1.066 1.066 0 00.126-.008A3.732 3.732 0 0024 5.819 3.6 3.6 0 0022.879 3.2zM20.43 7.5h-7.967c-.453 0-1.213-.137-1.213-1.01a.975.975 0 01.369-.759 1.116 1.116 0 01.707-.25 1.159 1.159 0 01.233.024 1.023 1.023 0 00.807-.188 1 1 0 00.389-.732A2.824 2.824 0 0116.625 2a2.9 2.9 0 012.606 1.588.963.963 0 00.935.558 1.756 1.756 0 011.325.5A1.629 1.629 0 0122 5.819 1.735 1.735 0 0120.43 7.5zM15 17a.5.5 0 00-.276-.448.51.51 0 00-.524.047l-2 1.5a.5.5 0 000 .8l2 1.5a.5.5 0 00.3.1.508.508 0 00.224-.053A.5.5 0 0015 20v-.25a.25.25 0 01.25-.25H17a3 3 0 003-3V15a.25.25 0 01.25-.25h.25a.5.5 0 00.4-.8l-1.5-2a.518.518 0 00-.8 0l-1.5 2a.5.5 0 00.4.8h.25A.25.25 0 0118 15v1.5a1 1 0 01-1 1h-1.75a.25.25 0 01-.25-.25zM7.75 9.5h-6A1.752 1.752 0 000 11.25v11A1.752 1.752 0 001.75 24h6a1.752 1.752 0 001.75-1.75v-11A1.752 1.752 0 007.75 9.5zM7.5 20.25a.25.25 0 01-.25.25h-5a.25.25 0 01-.25-.25v-8.5a.25.25 0 01.25-.25h5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});