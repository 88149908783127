define("ember-svg-jar/inlined/monitor-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-add</title><path d=\"M11.1 11.915a.25.25 0 00-.189-.414H2.5A.5.5 0 012 11V2.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v6.347a.25.25 0 00.287.247A8.226 8.226 0 0117.5 9c.079 0 .157 0 .235.008A.251.251 0 0018 8.759V2a2 2 0 00-2-2H2a2 2 0 00-2 2v10.5a2 2 0 002 2h5.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5H5a1 1 0 000 2h3.759a.25.25 0 00.181-.079.247.247 0 00.068-.186C9 17.658 9 17.58 9 17.5a8.464 8.464 0 012.1-5.585z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.5 7.5h-1.25a.25.25 0 00-.25.25V20a1 1 0 11-2 0v-1.25a.25.25 0 00-.25-.25H15a1 1 0 010-2h1.25a.25.25 0 00.25-.25V15a1 1 0 112 0v1.25a.25.25 0 00.25.25H20a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});