define("ember-svg-jar/inlined/crafts-model-plane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crafts-model-plane</title><path d=\"M2.5 3.5a1 1 0 00-1 1v2.55a.5.5 0 01-.272.446 2.248 2.248 0 000 4.006.5.5 0 01.272.446V14.5a1 1 0 002 0v-10a1 1 0 00-1-1zM15 21h-1.25a.5.5 0 01-.5-.5v-5.517a.5.5 0 01.433-.5l2.738-.367a1.313 1.313 0 00-.109-2.621H9.75a1.75 1.75 0 000 3.5 1.772 1.772 0 00.232-.016l1.2-.161a.5.5 0 01.567.5V20.5a.5.5 0 01-.5.5H10a3.012 3.012 0 00-2.939 2.4.5.5 0 00.49.6h9.9a.5.5 0 00.49-.6A3.011 3.011 0 0015 21z\"/><path d=\"M22.5 0h-1.461a1.5 1.5 0 00-1.471 1.206L18.79 5.1a.5.5 0 01-.49.4h-1.165a.5.5 0 01-.41-.214A7.583 7.583 0 0010.5 2.5a5.217 5.217 0 00-4.23 2.754.5.5 0 01-.43.246H5.5a1 1 0 00-1 1v6a1 1 0 001 1h.511a.5.5 0 00.5-.467A3.255 3.255 0 019.75 10h6.562a2.815 2.815 0 012.342 1.256.5.5 0 00.57.2 32.049 32.049 0 003.086-1.2A2.843 2.843 0 0024 7.651V1.5A1.5 1.5 0 0022.5 0zm-8.648 5.829a10.893 10.893 0 01-3.5.671 3.729 3.729 0 01-1.766-.583.253.253 0 01-.12-.177.249.249 0 01.06-.205A2.718 2.718 0 0110.5 4.5a6.338 6.338 0 013.4.881.249.249 0 01-.049.448z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});