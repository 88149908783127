define("ember-svg-jar/inlined/single-woman-actions-flight.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-flight</title><path d=\"M10.8 14.91a2.674 2.674 0 01.376-.353l.9-.666a2.319 2.319 0 012.2-.311l2.5.929a.25.25 0 00.226-.442 28.293 28.293 0 00-5.031-2.144.779.779 0 01-.513-.73v-.467a.25.25 0 01.249-.25 6.377 6.377 0 003.787-.9.388.388 0 00.084-.593c-.494-.537-.836-1.185-.981-3.317C14.338 1.846 11.736.5 9.5.5S4.661 1.846 4.4 5.671c-.146 2.132-.487 2.78-.981 3.317a.387.387 0 00.083.593 6.352 6.352 0 003.782.9.249.249 0 01.254.25v.462a.778.778 0 01-.512.73c-2.9 1.065-5.212 1.825-5.764 2.922A7.851 7.851 0 00.5 17.576a.386.386 0 00.1.3.4.4 0 00.293.124h9.48a.25.25 0 00.214-.379 2.332 2.332 0 01.213-2.711z\"/><path d=\"M22.046 15.055a1.792 1.792 0 00-1.411.269l-2 1.365a.25.25 0 01-.228.028l-4.65-1.732a.831.831 0 00-.785.112l-.891.661a1.437 1.437 0 00-.134.125.845.845 0 00.153 1.217l2.7 1.671a.25.25 0 01.118.2.246.246 0 01-.1.211l-.957.71a.25.25 0 01-.275.014l-1.172-.69a.773.773 0 00-.746.132l-.86.722a1.092 1.092 0 00-.138.134.763.763 0 00-.165.563.781.781 0 00.308.534l2.878 2.02a.983.983 0 001.084.031l7.854-4.877a1.867 1.867 0 00.871-1.605 1.789 1.789 0 00-.105-.6 1.866 1.866 0 00-1.349-1.215z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});