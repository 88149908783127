define("ember-svg-jar/inlined/plane-trip-return", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-trip-return</title><path d=\"M21.513 21A2 2 0 0023 20.3l.132-.177a1.863 1.863 0 00-1.748-2.963h-.011l-2.582.309a.5.5 0 01-.459-.2L15.65 13.7a.5.5 0 00-.4-.2h-1.481a.5.5 0 00-.457.7l1.376 3.1a.5.5 0 01-.457.7h-1.922a.5.5 0 01-.447-.276l-.691-1.382a.5.5 0 00-.546-.267l-1.512.3a.5.5 0 00-.359.694l1.614 3.631a.5.5 0 00.457.3h10.688zM.873 7.382a1.863 1.863 0 000 2.236L1 9.8a2 2 0 001.482.705h10.693a.5.5 0 00.457-.3l1.614-3.632a.5.5 0 00-.359-.694l-1.512-.3a.5.5 0 00-.546.267l-.691 1.382a.5.5 0 01-.447.276H9.769a.5.5 0 01-.457-.7L10.688 3.7a.5.5 0 00-.457-.7H8.75a.5.5 0 00-.4.2L5.673 6.769a.5.5 0 01-.459.2l-2.582-.312h-.011a1.862 1.862 0 00-1.748.725z\"/><circle cx=\"17\" cy=\"9.5\" r=\"1\"/><circle cx=\"21\" cy=\"9.5\" r=\"1\"/><circle cx=\"3\" cy=\"20\" r=\"1\"/><circle cx=\"7\" cy=\"20\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});