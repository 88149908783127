define("ember-svg-jar/inlined/weather-cloud-heavy-rain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-cloud-heavy-rain</title><path d=\"M19 16a5 5 0 003.094-8.926.252.252 0 01-.1-.193A7 7 0 008.338 4.84a.249.249 0 01-.22.173A5.5 5.5 0 008.5 16zM8.5 7a3.487 3.487 0 01.448.029 1 1 0 001.116-.829A5 5 0 0120 7c0 .146-.007.289-.02.433a1 1 0 00.5.959A3 3 0 0119 14H8.5a3.5 3.5 0 010-7zM6.28 17.72a.749.749 0 00-1.06 0l-5 5a.75.75 0 101.06 1.06l5-5a.749.749 0 000-1.06zM10.28 17.72a.749.749 0 00-1.06 0l-3.75 3.75a.75.75 0 101.06 1.06l3.75-3.75a.749.749 0 000-1.06zM14.28 17.72a.749.749 0 00-1.06 0l-5 5a.75.75 0 101.06 1.06l5-5a.749.749 0 000-1.06zM21.22 17.72l-5 5a.75.75 0 101.06 1.06l5-5a.75.75 0 00-1.06-1.06zM18.28 17.72a.749.749 0 00-1.06 0l-3.75 3.75a.75.75 0 101.06 1.06l3.75-3.75a.749.749 0 000-1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});