define("ember-svg-jar/inlined/stove-gas-pot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>stove-gas-pot</title><path d=\"M19.75 6.25H18.5a.5.5 0 01-.5-.5V4.5a.5.5 0 01.5-.5h.75a.75.75 0 000-1.5h-4.088a.5.5 0 01-.487-.385 2.749 2.749 0 00-5.35 0 .5.5 0 01-.486.385H4.75a.75.75 0 000 1.5h.75a.5.5 0 01.5.5v1.25a.5.5 0 01-.5.5H4.25a.75.75 0 000 1.5H5.5a.5.5 0 01.5.5v3.063c0 2.35 1.292 3.187 2.5 3.187h7c1.208 0 2.5-.837 2.5-3.187V8.25a.5.5 0 01.5-.5h1.25a.75.75 0 000-1.5zm-6.668-3.875a.25.25 0 01-.216.125h-1.732a.25.25 0 01-.216-.375 1.249 1.249 0 012.164 0 .248.248 0 010 .25zM15.429 17.252a.5.5 0 00-.377.227l-.818 1.266a.251.251 0 01-.407.019l-1.434-1.823a.518.518 0 00-.786 0l-1.434 1.823a.25.25 0 01-.406-.019l-.819-1.266a.5.5 0 00-.377-.227.486.486 0 00-.411.159c-.067.073-1.66 1.818-1.66 3.451A2.905 2.905 0 009.083 24h5.834a2.905 2.905 0 002.583-3.138c0-1.633-1.593-3.378-1.66-3.451a.491.491 0 00-.411-.159z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});