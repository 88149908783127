define("ember-svg-jar/inlined/ice-cream-cone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ice-cream-cone</title><path d=\"M16.482 12.961a3.5 3.5 0 01-1.141-.38.5.5 0 00-.625.124 3.5 3.5 0 01-5.432 0 .5.5 0 00-.625-.124 3.5 3.5 0 01-1.141.38.25.25 0 00-.2.335l3.741 10.051a1 1 0 001.875 0L16.679 13.3a.254.254 0 00-.019-.215.249.249 0 00-.178-.124z\"/><path d=\"M12 0a7.008 7.008 0 00-7 7v2.5a2 2 0 004 0 .5.5 0 011 0v1a2 2 0 004 0v-1a.5.5 0 011 0 2 2 0 004 0V7a7.008 7.008 0 00-7-7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});