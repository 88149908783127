define("ember-svg-jar/inlined/adventure-car-truck", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>adventure-car-truck</title><circle cx=\"17\" cy=\"16.875\" r=\"2.5\"/><circle cx=\"5\" cy=\"16.875\" r=\"2.5\"/><path d=\"M22.75 14.125H21.5a.5.5 0 01-.5-.5v-3.75a1.376 1.376 0 00-.16-.543l-2.159-3.338a3.165 3.165 0 00-2.519-1.369H8a1 1 0 00-.943.667L5.675 9.208a.25.25 0 01-.236.167H2.5a1.5 1.5 0 00-1.5 1.5v2.75a.25.25 0 01-.25.25.75.75 0 000 1.5h.75a.657.657 0 00.354-.147l1.707-1.707a.507.507 0 01.353-.146h2.367a.5.5 0 01.385.18l1.9 2.281a1.5 1.5 0 001.152.539h2.564a1.5 1.5 0 001.152-.539l1.9-2.28a.493.493 0 01.384-.181h2.368a.5.5 0 01.33.122l1.706 1.955v.006a.607.607 0 00.373.167h2.25a.75.75 0 000-1.5zm-11-5a.25.25 0 01-.25.25H8.09a.251.251 0 01-.236-.333l.795-2.25a.249.249 0 01.235-.167H11.5a.25.25 0 01.25.25zm3.735-2.076l-1.806 1.862a.25.25 0 01-.429-.174V6.875a.25.25 0 01.25-.25h1.8a.25.25 0 01.18.424zm2.761 2.195a.25.25 0 01-.22.131h-2.169a.251.251 0 01-.18-.424l1.3-1.342a.251.251 0 01.2-.075.253.253 0 01.186.113l.868 1.342a.251.251 0 01.015.255z\"/><rect x=\"22\" y=\"7.625\" width=\"2\" height=\"5.5\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});