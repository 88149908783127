define("ember-svg-jar/inlined/cat-toy-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cat-toy-1</title><path d=\"M19.537 3.114l-1.965-.246.735-.734A1.25 1.25 0 1016.539.366L1.69 15.213a1.25 1.25 0 001.768 1.767l12.4-12.395c.115.067 3.434.513 3.434.513a1.581 1.581 0 011.369 1.79A1.562 1.562 0 0119 8.243l-.733-.043a3.746 3.746 0 00-3.648 5.248 1 1 0 001.83-.807 1.732 1.732 0 01-.147-.7 1.751 1.751 0 01.547-1.269 1.783 1.783 0 011.3-.473l.734.043a3.579 3.579 0 00.654-7.124zM17.074 14.5a.75.75 0 00-.75.75v1.334a.75.75 0 001.5 0v-1.337a.75.75 0 00-.75-.747zM14.774 15.888a.75.75 0 00-1.061 1.061l.944.943a.75.75 0 101.06-1.06zM15.156 19.248a.75.75 0 00-.75-.75h-1.334a.75.75 0 000 1.5h1.334a.75.75 0 00.75-.75zM14.657 20.6l-.944.943a.75.75 0 001.061 1.061l.943-.943a.75.75 0 10-1.06-1.061zM17.074 21.167a.749.749 0 00-.75.749v1.334a.75.75 0 001.5 0v-1.334a.749.749 0 00-.75-.749zM19.491 20.6a.75.75 0 00-1.061 1.061l.943.943a.75.75 0 001.061-1.061zM21.076 18.5h-1.334a.75.75 0 000 1.5h1.334a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});