define("ember-svg-jar/inlined/sea-transport-cruiser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sea-transport-cruiser</title><path d=\"M.1 20.012a.251.251 0 00.22.04 2.44 2.44 0 011.011-.079c.583.073 1.489-.68 1.836-1.1a2.55 2.55 0 011.949-.935 2.508 2.508 0 012.126 1.179 2.019 2.019 0 001.413.832 2.681 2.681 0 001.829-1 2.584 2.584 0 012-1.006 2.506 2.506 0 012.261 1.425 1.177 1.177 0 00.982.585 1.775 1.775 0 001.4-.8 2.414 2.414 0 012.138-1.205 2.507 2.507 0 012.127 1.18c.019.005.517.759 1.15.818a.253.253 0 00.237-.119 8.2 8.2 0 001.2-3.7 1.251 1.251 0 00-1.247-1.338h-5.3a.249.249 0 01-.248-.223 9.971 9.971 0 00-1.008-3.435 4.915 4.915 0 00-4.379-2.592H11a.25.25 0 01-.25-.25V6.021a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v2.25a.25.25 0 01-.25.25H5a.25.25 0 01-.25-.25v-2.25a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v2.25a.25.25 0 01-.25.25H1a1 1 0 000 2h10.794a2.9 2.9 0 012.6 1.5 7.918 7.918 0 01.775 2.463.249.249 0 01-.247.286h-1.771a1.729 1.729 0 00-1.492.876.25.25 0 01-.216.124H.75a.75.75 0 00-.75.75v3.291a.249.249 0 00.1.201zM21.396 19.115h-.001.001zM4.527 3.1l.362-.247A2.4 2.4 0 016.1 2.521a2.386 2.386 0 011.91.829 1 1 0 101.524-1.295A4.418 4.418 0 006.1.521a4.441 4.441 0 00-2.276.636l-.418.283a1.3 1.3 0 01-1.656-.214 1 1 0 10-1.5 1.322 3.345 3.345 0 004.277.552z\"/><circle cx=\"9.25\" cy=\"13.021\" r=\"1.5\"/><circle cx=\"3.25\" cy=\"13.021\" r=\"1.5\"/><path d=\"M23.991 22.279a1.006 1.006 0 00-1.123-.859c-1.632.215-2.738-1.5-2.746-1.513a1.047 1.047 0 00-1.715.022 3.28 3.28 0 01-2.677 1.516 2.723 2.723 0 01-2.336-1.44 1.046 1.046 0 00-1.714-.16 4.194 4.194 0 01-3.026 1.6 3.467 3.467 0 01-2.686-1.539 1.042 1.042 0 00-1.635-.092c-.015.018-1.53 1.866-3.2 1.646a1 1 0 00-.264 1.982 4.117 4.117 0 00.549.037 5.718 5.718 0 003.648-1.567 5.139 5.139 0 003.588 1.533 5.75 5.75 0 003.729-1.466 4.5 4.5 0 003.347 1.466 5.065 5.065 0 003.546-1.458 4.629 4.629 0 003.856 1.413 1 1 0 00.859-1.121z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});