define("ember-svg-jar/inlined/baggage-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baggage-line</title><path d=\"M23.5 18.5H2.75a2.75 2.75 0 000 5.5H23.5a.5.5 0 00.5-.5V19a.5.5 0 00-.5-.5zm-21 2.75a1 1 0 111 1 1 1 0 01-1-1zm4.5 0a1 1 0 111 1 1 1 0 01-1-1zm4.5 0a1 1 0 111 1 1 1 0 01-1-1zm4.5 0a1 1 0 111 1 1 1 0 01-1-1zm4.5 0a1 1 0 111 1 1 1 0 01-1-1zM4.5 16.25h1a.5.5 0 00.5-.5v-11a.5.5 0 00-.5-.5h-1A1.5 1.5 0 003 5.75v9a1.5 1.5 0 001.5 1.5zM7 15.75a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-11a.5.5 0 00-.5-.5h-1V3.5a3.5 3.5 0 00-7 0v.75h-1a.5.5 0 00-.5.5zM10.5 3.5a1.5 1.5 0 013 0v.75h-3zM18 15.75a.5.5 0 00.5.5h1a1.5 1.5 0 001.5-1.5v-9a1.5 1.5 0 00-1.5-1.5h-1a.5.5 0 00-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});