define("ember-svg-jar/inlined/database-hierarchy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-hierarchy</title><path d=\"M6.953 1.841a.968.968 0 001.354.874 9.9 9.9 0 017.352-.015.97.97 0 001.351-.887v-.07a.961.961 0 00-.59-.887 11.841 11.841 0 00-8.88.016.954.954 0 00-.586.873zM22 12a9.881 9.881 0 01-.35 2.607.976.976 0 00.775 1.234h.021a1 1 0 001.131-.727 11.7 11.7 0 00-.007-6.259 1 1 0 00-1.133-.725h-.02a.976.976 0 00-.772 1.235A9.918 9.918 0 0122 12zM2 12a9.88 9.88 0 01.354-2.624.976.976 0 00-.777-1.235h-.02a1 1 0 00-1.129.726 11.709 11.709 0 00-.006 6.252 1 1 0 001.131.727h.02a.977.977 0 00.777-1.234A9.873 9.873 0 012 12zM12 22a9.876 9.876 0 01-3.668-.7.989.989 0 00-1.332.933.987.987 0 00.608.922 11.838 11.838 0 008.777 0 .988.988 0 00.609-.923.989.989 0 00-1.332-.937A9.883 9.883 0 0112 22zM6 6.25V2.8a.249.249 0 00-.343-.232A7.409 7.409 0 013 3a7.409 7.409 0 01-2.657-.432A.249.249 0 000 2.8v3.45c0 .55 1.34 1 3 1s3-.45 3-1zM12 16.5c2.485 0 4.5-.672 4.5-1.5v-1.524a.25.25 0 00-.359-.224A10.155 10.155 0 0112 14a10.155 10.155 0 01-4.141-.748.25.25 0 00-.359.224V15c0 .828 2.015 1.5 4.5 1.5zM12 13c2.485 0 4.5-.672 4.5-1.5V9.976a.25.25 0 00-.359-.224A10.155 10.155 0 0112 10.5a10.155 10.155 0 01-4.141-.748.25.25 0 00-.359.224V11.5c0 .828 2.015 1.5 4.5 1.5z\"/><ellipse cx=\"12\" cy=\"8\" rx=\"4.5\" ry=\"1.5\"/><ellipse cx=\"3\" cy=\"1\" rx=\"3\" ry=\"1\"/><path d=\"M3 23.98c1.66 0 3-.45 3-1v-3.45a.249.249 0 00-.343-.232A7.409 7.409 0 013 19.73a7.409 7.409 0 01-2.657-.43.249.249 0 00-.343.23v3.45c0 .55 1.34 1 3 1z\"/><ellipse cx=\"3\" cy=\"17.73\" rx=\"3\" ry=\"1\"/><path d=\"M20.994 19.73a7.405 7.405 0 01-2.657-.432.249.249 0 00-.343.231v3.451c0 .55 1.34 1 3 1s3-.45 3-1v-3.45a.249.249 0 00-.344-.232 7.4 7.4 0 01-2.656.432z\"/><ellipse cx=\"20.994\" cy=\"17.73\" rx=\"3\" ry=\"1\"/><path d=\"M20.968 3a7.409 7.409 0 01-2.657-.432.249.249 0 00-.343.232v3.45c0 .55 1.34 1 3 1s3-.45 3-1V2.8a.249.249 0 00-.343-.232A7.409 7.409 0 0120.968 3z\"/><ellipse cx=\"20.968\" cy=\"1\" rx=\"3\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});