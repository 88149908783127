define("ember-svg-jar/inlined/sports-car-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sports-car-1</title><path d=\"M21.482 11.393h2.25a.25.25 0 00.25-.25v-.768a1.168 1.168 0 00-.864-1.106l-.137-.035a2.73 2.73 0 01-.918-.422 16.765 16.765 0 00-9.081-2.687A11.926 11.926 0 004.2 10.25a1.26 1.26 0 01-.545.394 6.743 6.743 0 00-3.126 2.269A2.642 2.642 0 00.022 14.1a.249.249 0 00.247.29h1.213a.5.5 0 010 1H.533a.249.249 0 00-.217.374 3.876 3.876 0 00.666.815.989.989 0 00.706.293h2.64a.249.249 0 00.242-.311 2.8 2.8 0 01-.088-.689 2.99 2.99 0 015.15-2.072.25.25 0 00.18.077h2.669a1.113 1.113 0 001-.618l.552-1.106a.5.5 0 01.895.448l-.552 1.099a2.105 2.105 0 01-1.895 1.171h-1.846a.249.249 0 00-.242.311 2.711 2.711 0 010 1.378.249.249 0 00.242.311h4.693a.249.249 0 00.242-.311 2.8 2.8 0 01-.088-.689 3 3 0 016 0v.153a.249.249 0 00.376.228 4.277 4.277 0 001.174-1.014 4.363 4.363 0 00.954-2.718.132.132 0 00-.132-.131h-2.372a.5.5 0 010-1zm-7.625-2.888l-.616 1.233a.251.251 0 01-.222.138l-4.6.033a.25.25 0 01-.129-.466 9.235 9.235 0 014.691-1.318c.225 0 .447.007.665.019a.249.249 0 01.21.361z\"/><circle cx=\"18.482\" cy=\"15.875\" r=\"2\"/><circle cx=\"7.482\" cy=\"15.875\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});