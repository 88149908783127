define("ember-svg-jar/inlined/nautic-sports-sailing-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>nautic-sports-sailing-person</title><path d=\"M12.318 8.038a.251.251 0 00.237.169h8.532a.25.25 0 00.22-.369c-1.995-3.7-5.327-7.5-10.64-7.833a.773.773 0 00-.629.288.76.76 0 00-.119.7zM13.362 10.207a.25.25 0 00-.237.329l2.814 8.415a.747.747 0 001.392.06 6.392 6.392 0 015.7-4.048.771.771 0 00.573-.32.727.727 0 00.111-.607 29.028 29.028 0 00-1.222-3.673.249.249 0 00-.231-.156zM12.475 18.409a.132.132 0 00.093-.225l-.844-.844a5.46 5.46 0 00-3.888-1.611H6.509l-.662-1.658a.249.249 0 01.232-.342h.329a4.5 4.5 0 002.879-1.043l2.165-1.8a1.5 1.5 0 00-1.919-2.3l-2.165 1.8a1.5 1.5 0 01-.96.348H3.5a1.5 1.5 0 00-1.4 2.051l1.918 4.52a2.339 2.339 0 002.151 1.424h1.667a2.48 2.48 0 011.768.732l.241.241a.25.25 0 00.347.006 3 3 0 00.28-.294 2.578 2.578 0 012.003-1.005z\"/><circle cx=\"2.495\" cy=\"6.957\" r=\"2.25\"/><path d=\"M22.927 21.95c-1.574.143-2.667-1.556-2.675-1.57a1.047 1.047 0 00-1.715.023 3.376 3.376 0 01-2.752 1.557 2.8 2.8 0 01-2.4-1.481 1.043 1.043 0 00-1.712-.161 4.31 4.31 0 01-3.112 1.642A3.554 3.554 0 015.8 20.38a1.04 1.04 0 00-1.637-.091c-.014.019-1.463 1.832-3.109 1.7a.975.975 0 00-1.051.946C0 23.125.047 24 1.172 24A5.834 5.834 0 004.9 22.388a5.235 5.235 0 003.661 1.572 5.857 5.857 0 003.81-1.5 4.571 4.571 0 003.414 1.5 5.15 5.15 0 003.62-1.5 4.774 4.774 0 003.66 1.483 1 1 0 10-.138-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});