define("ember-svg-jar/inlined/data-transfer-square-horizontal-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-transfer-square-horizontal-alternate</title><path d=\"M15.7 5.148a.75.75 0 00-1.2.6v1a.25.25 0 01-.25.25H9.5a1.5 1.5 0 000 3h4.75a.25.25 0 01.25.25v1a.75.75 0 001.2.6L19.364 9.1a.75.75 0 000-1.2z\"/><path d=\"M22.044 0H1.957A1.959 1.959 0 000 1.955v20.088A1.959 1.959 0 001.957 24h20.087A1.959 1.959 0 0024 22.043V1.955A1.959 1.959 0 0022.044 0zM2 21L1.959 3a1 1 0 011-1L21 1.957a1 1 0 011 1L22.041 21a1 1 0 01-1 1H3a1 1 0 01-1-1z\"/><path d=\"M14.5 14H9.751a.25.25 0 01-.25-.25v-1a.75.75 0 00-1.2-.6L4.636 14.9a.75.75 0 000 1.2L8.3 18.851a.752.752 0 00.451.149.733.733 0 00.334-.08.743.743 0 00.416-.671v-1a.25.25 0 01.25-.25H14.5a1.5 1.5 0 100-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});