define("ember-svg-jar/inlined/vectors-path-corner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vectors-path-corner</title><path d=\"M24 9.5a1 1 0 00-1-1h-3a1 1 0 00-1 1v.426a.249.249 0 01-.232.249 12.115 12.115 0 00-4.852 1.289.162.162 0 01-.239-.142 2.972 2.972 0 00-.239-1.172.249.249 0 01.05-.272L16.465 6.8a.248.248 0 01.3-.047 3.583 3.583 0 10-1.605-1.7.251.251 0 01-.048.277L12.06 8.478a.25.25 0 01-.273.058 2.969 2.969 0 00-1.11-.214 3 3 0 00-2.756 4.184.251.251 0 01-.05.273L5.536 15.2a.251.251 0 01-.3.048 3.579 3.579 0 101.606 1.7.249.249 0 01.048-.276l2.413-2.5a.25.25 0 01.272-.059 2.976 2.976 0 001.1.209h.025a.25.25 0 01.23.35 11.04 11.04 0 00-.766 4.086.25.25 0 01-.254.242H9.5a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1v-3a1 1 0 00-1-1h-.09a.249.249 0 01-.25-.258 6.561 6.561 0 011.525-4.642 8.254 8.254 0 015.078-1.913.216.216 0 01.166.057.219.219 0 01.071.156v.1a1 1 0 001 1h3a1 1 0 001-1zM18.419 2a1.581 1.581 0 11-1.58 1.581A1.582 1.582 0 0118.419 2zM3.581 20a1.581 1.581 0 111.58-1.581A1.582 1.582 0 013.581 20zm6.1-8.678a1 1 0 111 1 1 1 0 01-1.004-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});