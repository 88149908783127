define("ember-svg-jar/inlined/heater", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>heater</title><path d=\"M15.55 7.707a1 1 0 001.414-1.414c-.328-.328-.236-.738.228-1.889.471-1.168 1.117-2.768-.228-4.111a1 1 0 10-1.414 1.414c.352.352.261.774-.213 1.949-.465 1.155-1.103 2.735.213 4.051zM9.782 7.707A1 1 0 1011.2 6.293c-.328-.328-.237-.738.227-1.889C11.9 3.236 12.54 1.636 11.2.293a1 1 0 10-1.418 1.414c.352.352.26.774-.214 1.949-.468 1.155-1.103 2.735.214 4.051zM21.277 7.707a1 1 0 101.414-1.414c-.328-.328-.236-.738.228-1.889.471-1.168 1.117-2.768-.228-4.111a1 1 0 00-1.414 1.414c.352.352.261.774-.213 1.949-.464 1.155-1.103 2.735.213 4.051z\"/><rect x=\"8.5\" y=\"9.992\" width=\"4.5\" height=\"14\" rx=\"1.5\" ry=\"1.5\"/><rect x=\"14\" y=\"9.992\" width=\"4.5\" height=\"14\" rx=\"1.5\" ry=\"1.5\"/><rect x=\"19.5\" y=\"9.992\" width=\"4.5\" height=\"14\" rx=\"1.5\" ry=\"1.5\"/><path d=\"M5.5 9.992L1.5 10A1.5 1.5 0 000 11.5v11A1.5 1.5 0 001.5 24l4-.008a1.5 1.5 0 001.5-1.5v-11a1.5 1.5 0 00-1.5-1.5zm-2 8.508a1 1 0 111-1 1 1 0 01-1 1zm0-4a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});