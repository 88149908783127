define("ember-svg-jar/inlined/bed-single", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bed-single</title><circle cx=\"5.5\" cy=\"14\" r=\"2.5\"/><path d=\"M23 15a1 1 0 00-1 1v1.75a.25.25 0 01-.25.25H2.25a.25.25 0 01-.25-.25V13.5a1 1 0 00-2 0V23a1 1 0 002 0v-1a.25.25 0 01.25-.25h19.5A.25.25 0 0122 22v1a1 1 0 002 0v-7a1 1 0 00-1-1z\"/><path d=\"M9.5 16.5h11a.5.5 0 00.5-.5 3.052 3.052 0 00-2.3-2.965l-5.353-1.589A3.5 3.5 0 009 14.843V16a.5.5 0 00.5.5zM17.5 10.5a1 1 0 002 0V7.25a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25zM15.7 6h5.6a1 1 0 00.98-1.2l-.8-4a1 1 0 00-.98-.8h-4a1 1 0 00-.98.805l-.8 4A1 1 0 0015.7 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});