define("ember-svg-jar/inlined/asian-interior-entrance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>asian-interior-entrance</title><path d=\"M23.947 3.276l-1.5-3A.5.5 0 0022 0h-1.128a.5.5 0 00-.481.637l.33 1.157a.75.75 0 01-.515.927.776.776 0 01-.206.029.75.75 0 01-.721-.544L18.752.363A.5.5 0 0018.271 0h-1.222a.5.5 0 00-.5.571l.189 1.323a.75.75 0 01-.637.848.782.782 0 01-.101.008.752.752 0 01-.742-.645l-.24-1.676a.5.5 0 00-.5-.429H13.25a.5.5 0 00-.5.5V2a.75.75 0 01-1.5 0V.5a.5.5 0 00-.5-.5H9.477a.5.5 0 00-.5.429l-.234 1.676A.752.752 0 018 2.75a.782.782 0 01-.107-.008.75.75 0 01-.637-.848l.19-1.323A.5.5 0 006.951 0H5.729a.5.5 0 00-.481.363l-.527 1.843A.75.75 0 014 2.75a.776.776 0 01-.206-.029.75.75 0 01-.515-.927l.33-1.157A.5.5 0 003.128 0H2a.5.5 0 00-.447.276l-1.5 3A.5.5 0 00.5 4h23a.5.5 0 00.447-.724zM12 8.681a7.279 7.279 0 00-4.281 13.165.747.747 0 01.309.605v1.475A.074.074 0 008.1 24H9a.5.5 0 00.5-.5v-1.732a.5.5 0 00-.269-.444 6 6 0 115.538 0 .5.5 0 00-.269.444V23.5a.5.5 0 00.5.5h.472a.5.5 0 00.5-.5v-1.049a.747.747 0 01.309-.605A7.279 7.279 0 0012 8.681z\"/><path d=\"M23 5.5H1a.5.5 0 00-.5.5v17a1 1 0 001 1h4.954a.074.074 0 00.074-.074v-.867a.5.5 0 00-.178-.383 8.778 8.778 0 1111.3 0 .5.5 0 00-.178.383v.441a.5.5 0 00.5.5H22.5a1 1 0 001-1V6a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});