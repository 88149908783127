define("ember-svg-jar/inlined/stove-steamer-gas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>stove-steamer-gas</title><path d=\"M4.75 4h.75a.5.5 0 01.5.5v3a.5.5 0 00.5.5h11a.5.5 0 00.5-.5v-3a.5.5 0 01.5-.5h.75a.75.75 0 000-1.5h-4.088a.5.5 0 01-.486-.385 2.75 2.75 0 00-5.351 0 .5.5 0 01-.486.385H4.75a.75.75 0 000 1.5zm6.168-1.875a1.249 1.249 0 012.164 0 .249.249 0 01-.216.375h-1.732a.25.25 0 01-.216-.375zM19.25 9.5H4.75a.75.75 0 000 1.5h.75a.5.5 0 01.5.5v.688C6 14.63 7.292 15.5 8.5 15.5h7c1.209 0 2.5-.87 2.5-3.312V11.5a.5.5 0 01.5-.5h.75a.75.75 0 000-1.5zM15.429 17.252a.5.5 0 00-.377.227l-.818 1.266a.251.251 0 01-.407.019l-1.434-1.823a.5.5 0 00-.786 0l-1.434 1.823a.25.25 0 01-.406-.019l-.819-1.266a.5.5 0 00-.377-.227.485.485 0 00-.411.159c-.067.073-1.66 1.818-1.66 3.451A2.905 2.905 0 009.083 24h5.834a2.905 2.905 0 002.583-3.138c0-1.633-1.593-3.378-1.66-3.451a.49.49 0 00-.411-.159z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});