define("ember-svg-jar/inlined/gesture-swipe-vertical-down-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-vertical-down-1</title><path d=\"M14.245 22.015A1.746 1.746 0 0113.368 21l-.424-1.272a.253.253 0 00-.2-.168.735.735 0 00-.6.169.751.751 0 00-.22.8l.945 2.834a.756.756 0 00.949.474l2.834-.945a.747.747 0 00.51-.658.736.736 0 00-.091-.412.249.249 0 00-.3-.116l-1.192.4a1.754 1.754 0 01-1.334-.091zM15.385 6.465a1 1 0 001.951-.442A10.5 10.5 0 0014.313.381a1 1 0 00-1.366 1.461 8.492 8.492 0 012.438 4.623z\"/><path d=\"M15.243 17.022a.249.249 0 01-.353.158l-.471-.235a.751.751 0 00-1.047.908l.944 2.834a.752.752 0 00.949.474l2.834-.945a.751.751 0 00.1-1.382l-1.027-.513a.25.25 0 01-.129-.291c.073-.257.139-.522.2-.789.028-.116.061-.225.088-.343a.99.99 0 00-1.9-.561.85.85 0 00-.054.119 7.187 7.187 0 01-.134.566zM19 11.382a3.5 3.5 0 00-3.5-3.5h-10a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h10a3.5 3.5 0 003.5-3.5zm-5 1.125v-2.25a.375.375 0 01.375-.375H15.5a1.5 1.5 0 010 3h-1.125a.375.375 0 01-.375-.375z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});