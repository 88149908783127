define("ember-svg-jar/inlined/safety-rocky-road", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-rocky-road</title><path d=\"M15.044 21L13.4 22.646a.5.5 0 00.353.853h4a.5.5 0 00.354-.853L16.458 21a1 1 0 00-1.414 0zM16.762 2.762a2.5 2.5 0 002.252 2.726c.08.007.159.011.238.011a2.5 2.5 0 002.488-2.262 2.5 2.5 0 00-4.978-.474zM19.108 13.165a1.337 1.337 0 00.142.007 1.5 1.5 0 00.8-2.772l-1.6-1a2.5 2.5 0 01-1.055-1.357l-.3-.948a4.5 4.5 0 00-2.473-2.745L13.38 3.8a5.517 5.517 0 00-4.98.268L6.435 5.2A1.5 1.5 0 007.94 7.8L9.9 6.665a2.5 2.5 0 011.254-.336 2.182 2.182 0 01.871.165.25.25 0 01.074.428l-4.351 3.591a1.508 1.508 0 00-.5.8l-.412 1.708a1.5 1.5 0 01-1.068 1.1l-2.407.65a1.5 1.5 0 00.248 2.941 1.356 1.356 0 00.143.007 1.46 1.46 0 00.391-.052l2.407-.65a4.5 4.5 0 003.2-3.288l.148-.618a.25.25 0 01.469-.05l1.1 2.3a1.5 1.5 0 01-.313 1.726l-1.762 1.7a1.5 1.5 0 00.9 2.574c.048 0 .1.007.143.007a1.5 1.5 0 001.041-.42l1.762-1.7a4.5 4.5 0 00.938-5.179c-1.19-2.495-1.5-3.156-1.619-3.382a.25.25 0 01.063-.309l1.6-1.32a.25.25 0 01.393.1 5.451 5.451 0 002.245 2.775l1.6 1a1.507 1.507 0 00.65.232z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});