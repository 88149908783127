define("ember-svg-jar/inlined/bowling-pins", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bowling-pins</title><path d=\"M3.414 7.948a.5.5 0 00.494.575h2.038a.5.5 0 00.494-.575 7.187 7.187 0 01.066-2.515s.33-1.608.33-1.888a1.909 1.909 0 00-3.818 0c0 .28.33 1.888.33 1.888a7.187 7.187 0 01.066 2.515zM6.136 14.182A6.636 6.636 0 017.2 10.709a.5.5 0 000-.469.4.4 0 00-.357-.217H3.074a.5.5 0 00-.444.269c-.7 1.338-1.521 2.33-1.521 3.753 0 .548-.065.082 1.823 8.223a.478.478 0 00.465.369h3.06a.477.477 0 00.465-.369l.422-1.817a1 1 0 000-.468 43.3 43.3 0 01-1.208-5.801zM17.56 7.948a.5.5 0 00.494.575h2.038a.5.5 0 00.494-.575 7.187 7.187 0 01.066-2.515s.33-1.608.33-1.888a1.909 1.909 0 10-3.818 0c0 .28.33 1.888.33 1.888a7.187 7.187 0 01.066 2.515zM22.891 14.045c0-1.423-.823-2.415-1.521-3.753a.5.5 0 00-.444-.269h-3.769a.4.4 0 00-.357.217.5.5 0 000 .469 6.636 6.636 0 011.06 3.473 43.3 43.3 0 01-1.205 5.8 1 1 0 000 .468l.422 1.817a.477.477 0 00.465.369H20.6a.478.478 0 00.465-.369c1.891-8.14 1.826-7.674 1.826-8.222zM10.245 7.392a.5.5 0 00.494.585h2.523a.5.5 0 00.493-.585 8.214 8.214 0 01.05-3.053s.377-1.838.377-2.157a2.182 2.182 0 00-4.364 0c0 .319.377 2.157.377 2.157a8.214 8.214 0 01.05 3.053zM14.734 10.1a6.74 6.74 0 01-.187-.344.5.5 0 00-.446-.274H9.9a.5.5 0 00-.446.274 6.74 6.74 0 01-.187.344c-1.28 2.2-1.631 2.9-1.631 4.082 0 .626-.074.092 2.084 9.4a.546.546 0 00.532.422h3.5a.547.547 0 00.532-.422c2.158-9.3 2.084-8.77 2.084-9.4-.004-1.182-.355-1.882-1.634-4.082z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});