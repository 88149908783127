define("ember-svg-jar/inlined/office-file-pps-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-file-pps-1</title><path d=\"M23.707 5.705L18.293.291A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.748V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.249A.249.249 0 016 21.749v-.5A.251.251 0 005.749 21H4.25a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.412a1 1 0 00-.293-.707z\"/><path d=\"M4 12.123h-.623a.252.252 0 00-.252.252v1.246a.252.252 0 00.252.252H4a.875.875 0 000-1.75zM9 12.123h-.623a.252.252 0 00-.252.252v1.246a.252.252 0 00.252.252H9a.875.875 0 000-1.75z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zM4 15.119h-.623a.252.252 0 00-.252.252v2.123a.625.625 0 01-1.25 0v-6a.625.625 0 01.625-.625H4a2.125 2.125 0 010 4.25zm5 0h-.623a.252.252 0 00-.252.252v2.123a.625.625 0 01-1.25 0v-6a.625.625 0 01.625-.625H9a2.125 2.125 0 010 4.25zm4.427-1.754l1.84 1.227a1.927 1.927 0 01-1.07 3.531H12.5a.625.625 0 010-1.25h1.7a.678.678 0 00.376-1.242L12.733 14.4a1.927 1.927 0 011.07-3.531h1.7a.625.625 0 010 1.25H13.8a.678.678 0 00-.376 1.242z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});