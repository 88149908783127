define("ember-svg-jar/inlined/people-woman-beanie.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-beanie</title><circle cx=\"14\" cy=\"11.75\" r=\"1\"/><circle cx=\"10\" cy=\"11.75\" r=\"1\"/><path d=\"M13.077 13.861h-2a.64.64 0 00-.307 1.2 2.355 2.355 0 00.438.182 3.017 3.017 0 00.869.127 2.977 2.977 0 00.866-.125 2.375 2.375 0 00.441-.183.64.64 0 00-.307-1.2zM18.318 6.5a6.509 6.509 0 00-5.509-4.944A.975.975 0 0013 1a1 1 0 00-2 0 .975.975 0 00.191.556A6.509 6.509 0 005.682 6.5zM22.837 20.52A14.012 14.012 0 0018 18.248v.252a2.487 2.487 0 01-.661 1.644 12.112 12.112 0 014.284 1.964.98.98 0 01.377.786V23a1 1 0 002 0v-.106a2.961 2.961 0 00-1.163-2.374zM6 18.248a13.991 13.991 0 00-4.837 2.273A2.958 2.958 0 000 22.894V23a1 1 0 002 0v-.106a.977.977 0 01.377-.785 12.092 12.092 0 014.284-1.965A2.487 2.487 0 016 18.5z\"/><path d=\"M8 16h1.339a5.235 5.235 0 01-2.589-4.5v-1h10.5v1a5.235 5.235 0 01-2.589 4.5H16a2 2 0 012 1.97c.154.014.309.029.466.029a3.8 3.8 0 002.921-1.12.5.5 0 00-.231-.8c-1.352-.434-2.105-2.169-2.439-3.885a6.984 6.984 0 00.036-.7v-1.045A.987.987 0 0019.5 9.5v-1a1.008 1.008 0 00-.027-.135.99.99 0 00-.973-.865h-13a1 1 0 00-1 1v1a.987.987 0 00.75.949V11.5c0 .221.012.439.033.655-.336 1.71-1.088 3.435-2.436 3.868a.5.5 0 00-.231.8 3.8 3.8 0 002.921 1.12c.16 0 .316-.015.472-.028A2 2 0 018 16z\"/><path d=\"M12 21c2.761 0 5-1.119 5-2.5V18a1 1 0 00-1-1H8a1 1 0 00-1 1v.5a1.574 1.574 0 00.5 1.072V23.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-2.525c.167.008.329.025.5.025z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});