define("ember-svg-jar/inlined/navigation-up-circle-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-up-circle-alternate</title><path d=\"M24 12a12 12 0 10-12 12 12.013 12.013 0 0012-12zm-2 0A10 10 0 1112 2a10.011 10.011 0 0110 10z\"/><path d=\"M15.615 11.883l-1.94-1.939a.25.25 0 00-.427.177V18a1.25 1.25 0 01-2.5 0v-7.879a.25.25 0 00-.426-.177l-1.94 1.939a1.249 1.249 0 01-1.767-1.767l4.5-4.5a1.249 1.249 0 011.767 0l4.5 4.5a1.249 1.249 0 01-1.767 1.767z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});