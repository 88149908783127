define("ember-svg-jar/inlined/sea-transport-bark-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sea-transport-bark-1</title><path d=\"M23.015 10.408c-2.081 0-2.515 2.425-2.64 3.223a.246.246 0 01-.244.208h-7.26a.247.247 0 01-.246-.247v-.739a.246.246 0 01.246-.246h2.709a.735.735 0 00.323-.074 6.663 6.663 0 003.372-6.083A6.663 6.663 0 0015.9.367a.736.736 0 00-.32-.075H6.191A1.232 1.232 0 005.328 2.4 5.5 5.5 0 016.96 6.45a5.5 5.5 0 01-1.633 4.05 1.232 1.232 0 00.864 2.11h4.217a.246.246 0 01.246.246v.739a.247.247 0 01-.246.247H1.469a1.481 1.481 0 00-1.183.593 1.532 1.532 0 00-.207 1.395 8.2 8.2 0 001.884 3.72.247.247 0 00.346 0l3.031-3.084a.493.493 0 01.7.691l-4.98 5.06a.256.256 0 00-.043.059.968.968 0 00.167 1.148.984.984 0 001.393-.01l2.565-2.606a.248.248 0 01.176-.073h.663a.249.249 0 00.176-.074l4.131-4.195a.493.493 0 11.7.691l-4.982 5.06a.208.208 0 00-.043.059.966.966 0 00.166 1.148.984.984 0 001.393-.01l2.566-2.606a.246.246 0 01.175-.073h.642a.249.249 0 00.176-.074l4.131-4.195a.493.493 0 11.7.691l-4.983 5.06a.226.226 0 00-.042.059.966.966 0 00.166 1.148.984.984 0 001.393-.01l2.566-2.606a.244.244 0 01.175-.073h1.625c4.913 0 5.371-6.07 5.681-7.643v-.008c.08-.35.34-.706.516-.706a.985.985 0 000-1.97z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});