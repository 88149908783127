define("ember-svg-jar/inlined/bomb-fire-bottle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bomb-fire-bottle</title><path d=\"M23.772.183a.252.252 0 00-.485 0 3.694 3.694 0 01-2.43 2.42.25.25 0 01-.315-.257 1.966 1.966 0 01.283-.977.252.252 0 00.032-.207.249.249 0 00-.135-.162 3.17 3.17 0 00-3.5.375 3.524 3.524 0 004.436 5.476A6.48 6.48 0 0023.772.183zM14.743 7.118a.5.5 0 00-.707 0l-2.3 2.3a5.945 5.945 0 01-1.84.394c-1.453.192-3.261.431-4.216 1.386L.584 16.3a2 2 0 000 2.828l4.291 4.291a2 2 0 002.828 0l5.097-5.098c.955-.954 1.194-2.761 1.386-4.214a5.94 5.94 0 01.4-1.841l2.3-2.3a.5.5 0 000-.707zm-4.762 10.8l-1.768 1.763a.5.5 0 01-.707 0l-3.188-3.189a.5.5 0 010-.707l1.767-1.768a.5.5 0 01.707 0l3.189 3.189a.5.5 0 010 .707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});