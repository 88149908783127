define("ember-svg-jar/inlined/task-list-pin-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-list-pin-1-alternate</title><path d=\"M22 6.513a2 2 0 00-2-2h-1a1 1 0 000 2h.5a.5.5 0 01.5.5v9.474a.25.25 0 01-.25.25H16.5a1.752 1.752 0 00-1.75 1.75v3.25a.25.25 0 01-.25.25h-10a.5.5 0 01-.5-.5V7.013a.5.5 0 01.5-.5H8a1 1 0 000-2H4a2 2 0 00-2 2v15.474a2 2 0 002 2h11.025a7.02 7.02 0 004.932-2.043A6.93 6.93 0 0022 17.013zm-5.422 15.223a.25.25 0 01-.328-.237v-3.012a.253.253 0 01.25-.25h3.016a.252.252 0 01.2.1.248.248 0 01.035.224 4.974 4.974 0 01-3.176 3.171z\"/><path d=\"M8.793 8.306a1 1 0 101.414 1.414l4.768-4.769a.251.251 0 01.254-.061 2.5 2.5 0 10-1.607-1.607.248.248 0 01-.061.254zM17.75 13.013a.75.75 0 00-.75-.75H7a.75.75 0 100 1.5h10a.75.75 0 00.75-.75zM7 17.263a.75.75 0 000 1.5h4.25a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});