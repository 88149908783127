define("ember-svg-jar/inlined/office-chair", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-chair</title><path d=\"M22.968 8.461a2.5 2.5 0 00-4.392 2.157.5.5 0 00.486.381h.513a.249.249 0 01.194.092.252.252 0 01.052.207l-.409 2.044a1.516 1.516 0 01-.927 1.035.249.249 0 01-.295-.085 1.462 1.462 0 00-.718-.533A17.655 17.655 0 0012 13a17.655 17.655 0 00-5.472.76 1.462 1.462 0 00-.718.533.249.249 0 01-.295.085 1.516 1.516 0 01-.927-1.035L4.179 11.3a.252.252 0 01.052-.207.249.249 0 01.194-.093h.513a.5.5 0 00.486-.381 2.5 2.5 0 10-4.848 0 .5.5 0 00.486.381h.608a.5.5 0 01.491.4l.466 2.333a3.546 3.546 0 002.814 2.7.251.251 0 01.186.148A1.494 1.494 0 007 17.5h3.75a.25.25 0 01.25.25v.6a.25.25 0 01-.217.248A7.245 7.245 0 006.2 20.9a.248.248 0 01-.2.1 1.5 1.5 0 101.5 1.56.251.251 0 01.055-.155L7.8 22.1a4.973 4.973 0 012.905-1.486.246.246 0 01.205.054.251.251 0 01.09.192v.531a1.5 1.5 0 102 0v-.533a.247.247 0 01.09-.191.244.244 0 01.2-.055A4.988 4.988 0 0116.2 22.1l.243.3a.249.249 0 01.056.159A1.5 1.5 0 1018 21a.25.25 0 01-.2-.1v-.006a7.245 7.245 0 00-4.583-2.3.249.249 0 01-.217-.246v-.6a.25.25 0 01.25-.25H17a1.494 1.494 0 001.373-.914.251.251 0 01.186-.148 3.546 3.546 0 002.814-2.7l.466-2.333a.5.5 0 01.491-.4h.608a.5.5 0 00.486-.381 2.5 2.5 0 00-.456-2.161z\"/><path d=\"M7.182 11.565a.5.5 0 00.571.327 23.231 23.231 0 018.494 0 .475.475 0 00.1.01.5.5 0 00.472-.337A28.541 28.541 0 0018.5 5.5 5.9 5.9 0 0013.092.048a12.445 12.445 0 00-2.184 0A5.9 5.9 0 005.5 5.5a28.541 28.541 0 001.682 6.065z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});