define("ember-svg-jar/inlined/rating-star-subtract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rating-star-subtract</title><path d=\"M21.552 10.578A1.465 1.465 0 0020.6 8h-5.945l-2.127-6.05a1.466 1.466 0 00-2.751.019L7.657 8H1.714a1.464 1.464 0 00-.938 2.589L5.8 14.736l-2.107 6.332a1.466 1.466 0 002.256 1.648l5.208-3.816 5.207 3.818a1.465 1.465 0 002.256-1.644l-2.111-6.336zm-6.845 3.052a1 1 0 00-.313 1.087l1.549 4.647a.25.25 0 01-.385.281l-3.811-2.794a1 1 0 00-1.183 0l-3.81 2.794a.25.25 0 01-.385-.281l1.549-4.647a1 1 0 00-.313-1.087L3.74 10.442A.25.25 0 013.9 10h4.466a1 1 0 00.943-.668l1.611-4.584a.25.25 0 01.472 0L13 9.332a1 1 0 00.944.668h4.466a.25.25 0 01.159.442zM22.749 2h-5a1 1 0 000 2h5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});