define("ember-svg-jar/inlined/building-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>building-house</title><path d=\"M19.905 3.607a.5.5 0 00-.81 0l-3 4.132a.492.492 0 00-.1.293V20.5a.5.5 0 00.5.5h6a.5.5 0 00.5-.5V8.032a.5.5 0 00-.095-.293zM18.5 17.5a.5.5 0 01-1 0v-1a.5.5 0 011 0zm0-4a.5.5 0 01-1 0v-1a.5.5 0 011 0zm0-4a.5.5 0 01-1 0v-1a.5.5 0 011 0zm3 8a.5.5 0 01-1 0v-1a.5.5 0 011 0zm0-4a.5.5 0 01-1 0v-1a.5.5 0 011 0zm0-4a.5.5 0 01-1 0v-1a.5.5 0 011 0zM14.5 7.5h-7A.5.5 0 007 8v5.5a.5.5 0 00.5.5h3a1.5 1.5 0 011.2.6l2.85 3.8a.25.25 0 00.45-.15V8a.5.5 0 00-.5-.5zM10.889 18.351a.5.5 0 00-.8 0L8.2 20.805a.5.5 0 01-.4.2H1.5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h11a.5.5 0 00.5-.5v-2.172a.5.5 0 00-.1-.3z\"/><path d=\"M10.9 15.2a.534.534 0 00-.4-.2h-7a.5.5 0 00-.4.2l-3 4a.5.5 0 00.4.8h7a.5.5 0 00.4-.2l2.2-2.934a.5.5 0 01.8 0l2.2 2.934a.5.5 0 10.8-.6zM23.9 5.706l-4-5.5A.515.515 0 0019.5 0h-8.763a1 1 0 00-.774.367l-3.682 4.5A1 1 0 007.055 6.5h8.19a1 1 0 00.809-.412L19.1 1.906a.5.5 0 01.808 0L23.1 6.294a.5.5 0 00.808-.588z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});