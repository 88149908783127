define("ember-svg-jar/inlined/beach-person-water-parasol", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beach-person-water-parasol</title><path d=\"M1 3.462l5.509.627-1.624 12.67a1 1 0 001.985.254L8.492 4.329v-.014L14 4.942a.505.505 0 00.478-.779A9.031 9.031 0 00.707 2.6.5.5 0 001 3.462zM24 22.782a1.013 1.013 0 00-1.068-.928c-1.578.127-2.667-1.556-2.675-1.57a1.048 1.048 0 00-1.716.023 3.375 3.375 0 01-2.753 1.557 2.8 2.8 0 01-2.4-1.481 1.043 1.043 0 00-1.717-.156 4.308 4.308 0 01-3.105 1.637 3.554 3.554 0 01-2.766-1.58 1.042 1.042 0 00-1.638-.091c-.014.019-1.455 1.839-3.11 1.7A.969.969 0 000 22.842c-.01.187.042 1.057 1.168 1.057A5.833 5.833 0 004.9 22.292a5.237 5.237 0 003.663 1.572 5.858 5.858 0 003.811-1.5 4.576 4.576 0 003.416 1.5 5.155 5.155 0 003.622-1.5 4.8 4.8 0 003.661 1.483A1 1 0 0024 22.782z\"/><circle cx=\"12\" cy=\"9.172\" r=\"2.5\"/><path d=\"M12.788 12.753a1.157 1.157 0 00-1.006.381l-4 4.572A1.143 1.143 0 009.5 19.21l2.633-3.01a.251.251 0 01.412.053l1.358 2.716a1.142 1.142 0 001.751.368l2.961-2.452a1.132 1.132 0 011.3.141l1.508 2.1a1.143 1.143 0 001.857-1.332s-1.648-2.286-1.692-2.33a3.414 3.414 0 00-4.241-.484 228.97 228.97 0 00-1.8 1.482.251.251 0 01-.211.051.245.245 0 01-.172-.133l-1.5-3a1.145 1.145 0 00-.876-.627z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});