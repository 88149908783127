define("ember-svg-jar/inlined/circus-lion-ring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>circus-lion-ring</title><path d=\"M23.705 19.318a2.511 2.511 0 00-1-1.688l-3.5-2.564a.25.25 0 00-.392.148l-.44 1.983a.249.249 0 00.1.256l1.505 1.1a3.517 3.517 0 011.4 2.362 3.165 3.165 0 010 .778c-.01.168.185.235.293.235a2.476 2.476 0 001.508-.724 2.511 2.511 0 00.526-1.886z\"/><path d=\"M19.387 19.363l-1.067-.782a.238.238 0 00-.326.082 1.481 1.481 0 01-.8.729 1.869 1.869 0 01-1.415-.2l-3.024-2.218a.266.266 0 00-.117-.047l-2.264-.283a2.493 2.493 0 01-1.842-1.207 3.174 3.174 0 01-.163-2.165.244.244 0 00-.111-.268l-1.36-.85a2.5 2.5 0 01.084-4.291l.389-.223s.091-.161-.19-.209a36.497 36.497 0 00-2.45-.306.506.506 0 00-.531.4 18.539 18.539 0 00.44 9.715.5.5 0 00.4.335 23.281 23.281 0 014.929 1.3.265.265 0 01.113.4A3.75 3.75 0 017.274 21c-2.71 0-5-4.351-5-9.5s2.29-9.5 5-9.5a2.739 2.739 0 011.368.386A1 1 0 009.636.649 4.725 4.725 0 007.274 0c-3.925 0-7 5.052-7 11.5 0 5.888 2.566 10.6 6 11.375V23a1 1 0 002 0v-.131A6.455 6.455 0 0012 20.021a.252.252 0 01.322-.087 23.438 23.438 0 014.784 3.257 2.149 2.149 0 002.754-.251 2.5 2.5 0 00-.474-3.577z\"/><path d=\"M8.116 4.211a1.506 1.506 0 00-.066 1.46l.959 1.918a.251.251 0 01-.1.329l-1.431.818a1.5 1.5 0 00-.051 2.575s1.964 1.222 2.053 1.282a.255.255 0 01.095.309l-.284.709a1.5 1.5 0 001.209 2.046l2.522.315a.257.257 0 01.116.047l3.234 2.372a.647.647 0 00.472.064.5.5 0 00.312-.359l1.082-4.871.966-.138a1.5 1.5 0 001.231-1.072l.525-1.835a1.5 1.5 0 00-1.152-1.88s-.811-.151-.9-.18a.211.211 0 01-.142-.145 1.492 1.492 0 00-.823-1.2.36.36 0 01-.252-.307l-.354-2.124a1.529 1.529 0 00-.689-1.026l-3.665-1.967a1.5 1.5 0 00-2.209 1.321v.482a.328.328 0 01-.341.346H9.392a1.509 1.509 0 00-1.276.711zM14.274 10a2.884 2.884 0 011.849-2.934.235.235 0 01.192.014l1.183.591a.5.5 0 01.276.447V8.5a.5.5 0 00.4.49l1.436.287A.5.5 0 0120 9.9l-.524 1.834a.5.5 0 01-.41.358l-2.823.4h-1.469a.5.5 0 01-.5-.5z\"/><circle cx=\"16.273\" cy=\"9.5\" r=\".75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});