define("ember-svg-jar/inlined/view-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>view-1</title><path d=\"M23.432 10.524C20.787 7.614 16.4 4.538 12 4.6 7.6 4.537 3.213 7.615.568 10.524a2.211 2.211 0 000 2.948C3.182 16.351 7.507 19.4 11.839 19.4h.308c4.347 0 8.671-3.049 11.288-5.929a2.21 2.21 0 00-.003-2.947zM7.4 12a4.6 4.6 0 114.6 4.6A4.6 4.6 0 017.4 12z\"/><circle cx=\"12\" cy=\"12\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});