define("ember-svg-jar/inlined/photography-equipment-lights-background", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>photography-equipment-lights-background</title><path d=\"M24 20.25a1 1 0 00-1-1h-2.357a.252.252 0 01-.225-.139A4.029 4.029 0 0120 17.333V3.232a.249.249 0 01.214-.247A1.5 1.5 0 0020 0H4a1.5 1.5 0 00-.214 2.985.249.249 0 01.214.247v14.1a4.029 4.029 0 01-.418 1.778.25.25 0 01-.225.139H1a1 1 0 000 2h.563a.25.25 0 01.2.4L1.2 22.4A1 1 0 002 24h20a1 1 0 00.8-1.6l-.563-.75a.25.25 0 01.2-.4H23a1 1 0 001-1zm-4.3 1.35a.25.25 0 01-.2.4h-15a.25.25 0 01-.2-.4l.5-.666a6.037 6.037 0 001.2-3.6V3.75a.25.25 0 01.25-.25h11.5a.25.25 0 01.25.25v13.583a6.037 6.037 0 001.2 3.6z\"/><path d=\"M8.759 19.886a.75.75 0 101.482.228l.1-.652a.249.249 0 01.247-.212h2.822a.249.249 0 01.247.212l.1.652a.75.75 0 101.482-.228l-.784-5.1a.249.249 0 01.245-.286h.546a1 1 0 000-2H8.75a1 1 0 000 2h.55a.249.249 0 01.247.288zm3.922-5.386a.25.25 0 01.247.212l.423 2.75a.251.251 0 01-.247.288H10.9a.251.251 0 01-.247-.288l.423-2.75a.25.25 0 01.247-.212z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});