define("ember-svg-jar/inlined/house-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>house-alternate</title><path d=\"M23.611 8.709l-11-8.5a1 1 0 00-1.222 0l-11 8.5A1 1 0 000 9.5V23a1 1 0 001 1h22a1 1 0 001-1V9.5a1 1 0 00-.389-.791zM22 21.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5V10.237a.5.5 0 01.194-.4l9.5-7.341a.5.5 0 01.612 0l9.5 7.341a.5.5 0 01.194.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});