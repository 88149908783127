define("ember-svg-jar/inlined/logout-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>logout-alternate</title><path d=\"M10 13.5v3.7a1 1 0 00.567.9 1.036 1.036 0 001.058-.121l6.5-5.2a1 1 0 000-1.561l-6.5-5.2A1 1 0 0010 6.8v3.7H1.5a1.5 1.5 0 000 3z\"/><path d=\"M12 0A12.025 12.025 0 001.046 7.094a1 1 0 001.826.819 10 10 0 11.02 8.223 1 1 0 10-1.82.828A12 12 0 1012 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});