define("ember-svg-jar/inlined/calendar-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>calendar-date</title><path d=\"M21.5 3h-2.75a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v4.75a.75.75 0 11-1.5 0V3.5a.5.5 0 00-.5-.5H8.25A.25.25 0 018 2.751V1a1 1 0 10-2 0v4.75a.75.75 0 11-1.5 0V3.5A.5.5 0 004 3H2.5a2 2 0 00-2 2v17a2 2 0 002 2h19a2 2 0 002-2V5a2 2 0 00-2-2zm0 18.5a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5v-12A.5.5 0 013 9h18a.5.5 0 01.5.5z\"/><path d=\"M9.653 11.151a1.5 1.5 0 00-1.59.179l-1.688 1.35a1 1 0 001.25 1.562l.875-.7V19a1 1 0 002 0v-6.5a1.51 1.51 0 00-.847-1.349zM16 11h-3a1 1 0 000 2h2.212L12.6 18.576a1 1 0 00.48 1.33 1.016 1.016 0 001.33-.48l2.973-6.334A1.5 1.5 0 0016 11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});