define("ember-svg-jar/inlined/search-engine-google", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>search-engine-google</title><path d=\"M23.7 14.29a.5.5 0 00-.45-.28h-6.59a.5.5 0 00-.32.11l-2.44 2a.5.5 0 00.32.89h4v3.86a3.26 3.26 0 01-.38.15 12.47 12.47 0 01-4.11.29A9.88 9.88 0 015 13a9 9 0 014.55-9.85 7.39 7.39 0 015.67-.49 8.74 8.74 0 013.33 2L18 5.73a.5.5 0 000 .55.49.49 0 00.53.17L20 6a.5.5 0 00.22-.13l2.25-2.29a.5.5 0 00.14-.38.5.5 0 00-.18-.36C18.57-.31 11.87-.94 7.17 1.43A12.38 12.38 0 00.25 11.79 12 12 0 005.54 22a13.64 13.64 0 007.61 2A20.26 20.26 0 0022 22a.5.5 0 00.27-.45v-4.93l1.41-1.81a.5.5 0 00.02-.52z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});