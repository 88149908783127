define("ember-svg-jar/inlined/show-hat-magician", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>show-hat-magician</title><path d=\"M.5 2h.25a.25.25 0 01.25.25v.25a.5.5 0 001 0v-.25A.25.25 0 012.25 2h.25a.5.5 0 000-1h-.25A.25.25 0 012 .75V.5a.5.5 0 00-1 0v.25A.25.25 0 01.75 1H.5a.5.5 0 000 1zM20 9h-.25a.25.25 0 01-.25-.25V8.5a.5.5 0 00-1 0v.25a.25.25 0 01-.25.25H18a.5.5 0 000 1h.25a.25.25 0 01.25.25v.25a.5.5 0 001 0v-.25a.25.25 0 01.25-.25H20a.5.5 0 000-1zM11 2.5h.25a.25.25 0 01.25.25V3a.5.5 0 001 0v-.25a.25.25 0 01.25-.25H13a.5.5 0 000-1h-.25a.25.25 0 01-.25-.25V1a.5.5 0 00-1 0v.25a.25.25 0 01-.25.25H11a.5.5 0 000 1zM1.5 6.5a.75.75 0 00.414.671l1.347.673a2 2 0 01.895.894l.673 1.347a.75.75 0 001.342 0l.673-1.347a2 2 0 01.895-.894l1.346-.673a.75.75 0 000-1.342l-1.346-.672a2.008 2.008 0 01-.895-.9l-.673-1.342a.78.78 0 00-1.342 0l-.673 1.347a2.008 2.008 0 01-.895.9l-1.347.672A.75.75 0 001.5 6.5zM23.894.553a1 1 0 00-1.341-.447l-10 5a1 1 0 00.894 1.794l10-5a1 1 0 00.447-1.347zM15.5 18.5h-11a.5.5 0 00-.5.5v2.333A2.633 2.633 0 006.6 24h6.8a2.635 2.635 0 002.6-2.667V19a.5.5 0 00-.5-.5zM19 12.5H1a1 1 0 000 2h2.5a.5.5 0 01.5.5v1.5a.5.5 0 00.5.5h11a.5.5 0 00.5-.5V15a.5.5 0 01.5-.5H19a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});