define("ember-svg-jar/inlined/outdoors-woodchopping-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-woodchopping-1</title><path d=\"M10.932 17a.5.5 0 00.491-.411 4.506 4.506 0 00-1.915-4.583.5.5 0 00-.682.108l-2.457 3.212a.75.75 0 00.509 1.2l3.994.474a.394.394 0 00.06 0zM17.816 11.4a.749.749 0 00-1.253.358l-.952 3.908a.5.5 0 00.337.6 4.67 4.67 0 001.381.212 4.487 4.487 0 003.4-1.558.5.5 0 00-.025-.688zM5.824 19.208a.5.5 0 00-.671-.136 4.5 4.5 0 00-2.115 4.494.5.5 0 00.5.434h.041l4.028-.33a.749.749 0 00.552-1.181zM13.4 22.991a.751.751 0 001.281-.246l1.292-3.809a.5.5 0 00-.285-.623 4.508 4.508 0 00-4.881.919.5.5 0 00-.036.689zM19.573 17.772a.5.5 0 00-.462.511l.1 4.043a.753.753 0 00.4.643.764.764 0 00.354.088.735.735 0 00.4-.116l3.408-2.134a.5.5 0 00.173-.663 4.509 4.509 0 00-4.366-2.372zM11.214 7.807a.4.4 0 00.445.264 4.174 4.174 0 002.325-1.177 5.6 5.6 0 001.375-2.542.412.412 0 00-.259-.482l-2.724-1.049a.422.422 0 01-.145-.094L10.12.616a.683.683 0 00-.966 0L7.947 1.823a.683.683 0 000 .966L10.058 4.9a.431.431 0 01.094.145zM8.008 4.472a.209.209 0 00-.295 0L.2 11.985a.683.683 0 000 .966l.483.483a.683.683 0 00.966 0l7.513-7.513a.208.208 0 000-.295zM12.791 2a.208.208 0 00.294 0l.349-.349a.683.683 0 000-.966L12.951.2a.683.683 0 00-.966 0l-.35.35a.209.209 0 000 .295z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});