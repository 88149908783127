define("ember-svg-jar/inlined/volume-control-up-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>volume-control-up-3</title><path d=\"M13.864 10.377a.252.252 0 00.136-.223V1a1 1 0 00-1.061-1 9.731 9.731 0 00-7.924 4.85.249.249 0 01-.221.133H3a3 3 0 00-3 3v2a3 3 0 003 3h1.794a.249.249 0 01.221.133 9.161 9.161 0 004.16 3.867.25.25 0 00.357-.2 8.015 8.015 0 014.332-6.406z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm.75 9a.75.75 0 01-1.5 0v-1.5a.25.25 0 00-.25-.25H15a.75.75 0 010-1.5h1.5a.25.25 0 00.25-.25V15a.75.75 0 011.5 0v1.5a.25.25 0 00.25.25H20a.75.75 0 010 1.5h-1.5a.25.25 0 00-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});