define("ember-svg-jar/inlined/remove-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>remove-square</title><path d=\"M22.455 0H1.545A1.547 1.547 0 000 1.545v20.909A1.547 1.547 0 001.545 24h20.91A1.547 1.547 0 0024 22.454V1.545A1.547 1.547 0 0022.455 0zm-4.748 16.292a1 1 0 010 1.414 1.028 1.028 0 01-1.414 0l-4.116-4.116a.252.252 0 00-.354 0l-4.116 4.116a1.02 1.02 0 01-1.414 0 1 1 0 010-1.414l4.116-4.116a.249.249 0 000-.353L6.293 7.706a1 1 0 011.414-1.414l4.116 4.116a.25.25 0 00.354 0l4.116-4.116a1 1 0 011.414 1.414l-4.116 4.117a.249.249 0 000 .353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});