define("ember-svg-jar/inlined/smiley-drool-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-drool-alternate</title><path d=\"M7 10a1 1 0 00.9-.553A2.656 2.656 0 0110 8.5a1 1 0 000-2c-1.153 0-3.142.547-3.895 2.053A1 1 0 006.553 9.9.989.989 0 007 10zM16.105 9.447A1 1 0 0017 10a.989.989 0 00.446-.105 1 1 0 00.454-1.342C17.142 7.047 15.153 6.5 14 6.5a1 1 0 000 2 2.656 2.656 0 012.105.947zM17.5 14.5H6.8a.5.5 0 00-.433.75 6.525 6.525 0 002.982 2.687.25.25 0 01.148.228V19.5A2.5 2.5 0 0012 22a2.447 2.447 0 00.439-.039.25.25 0 01.275.153A3 3 0 0018.5 21v-5.5a1 1 0 00-1-1zm-3 6.5v-1.5a1 1 0 00-2 0 .5.5 0 01-1 0v-2.75a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25V21a1 1 0 01-2 0z\"/><path d=\"M12 0a12 12 0 00-6.015 22.386 1 1 0 101-1.73A10 10 0 1122 12a9.911 9.911 0 01-2.1 6.139 1 1 0 001.578 1.229A11.89 11.89 0 0024 12 12.013 12.013 0 0012 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});