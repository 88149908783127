define("ember-svg-jar/inlined/cog-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cog-circle</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm5.357 12.43l1.116 1.2a1.95 1.95 0 01-1.86 3.23l-1.595-.37a.578.578 0 00-.68.4l-.479 1.573a1.942 1.942 0 01-3.718 0l-.479-1.573a.578.578 0 00-.68-.4l-1.595.37a1.95 1.95 0 01-1.86-3.23l1.116-1.2a.588.588 0 000-.8l-1.116-1.2a1.95 1.95 0 011.86-3.23l1.595.369a.574.574 0 00.68-.4l.479-1.569a1.942 1.942 0 013.718 0l.479 1.573a.575.575 0 00.68.4l1.595-.373a1.95 1.95 0 011.86 3.23l-1.116 1.2a.587.587 0 000 .8z\"/><path d=\"M15 12.032a3 3 0 10-3 3 3 3 0 003-3zm-3 1.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});