define("ember-svg-jar/inlined/business-deal-handshake-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-deal-handshake-1</title><path d=\"M1 16.25h1.667a1.861 1.861 0 001.784-1.5 2.027 2.027 0 00.049-.444V14a.25.25 0 01.25-.25H6a.5.5 0 01.373.167l3.59 4.023a.871.871 0 001.537-.561v-.318a.249.249 0 00-.073-.177l-.781-.78a.5.5 0 01.708-.708l.927.928.083.033a1.192 1.192 0 001.585-.764.249.249 0 00-.062-.249L12.646 14.1a.5.5 0 01.708-.708l1.28 1.281a.249.249 0 00.177.073H15a1 1 0 001-1 1.011 1.011 0 00-.2-.6l-2.874-3.678a.251.251 0 00-.276-.083l-1.56.515a1.686 1.686 0 01-1.8-2.712.251.251 0 00-.154-.413 1.668 1.668 0 00-.838.1L4.843 8.252a.254.254 0 01-.233-.025.25.25 0 01-.11-.207v-.326a2.034 2.034 0 00-.034-.369 1.867 1.867 0 00-1.8-1.575H1a1 1 0 00-1 1v8.5a1 1 0 001 1z\"/><path d=\"M14.656 8.726l-.49.161a.25.25 0 00-.166.164.248.248 0 00.043.226l2.557 3.266a2.027 2.027 0 01.4 1.207 1.962 1.962 0 01-.026.319.254.254 0 00.073.22.25.25 0 00.221.066l1.935-.368a.255.255 0 01.206.053.249.249 0 01.091.193v.073a2.049 2.049 0 00.1.625 1.848 1.848 0 001.735 1.319H23a1 1 0 001-1v-8.5a1 1 0 00-1-1h-1.667a1.794 1.794 0 00-1.442.747 1.969 1.969 0 00-.319.659.252.252 0 01-.327.17l-4.015-1.46a1.95 1.95 0 00-1.518.087l-3.455 1.728a.686.686 0 00.52 1.265l3.567-1.171a.5.5 0 01.312.951z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});