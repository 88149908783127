define("ember-svg-jar/inlined/like-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>like-1-alternate</title><path d=\"M24 11.158a2.753 2.753 0 00-2.75-2.75h-5.72a.25.25 0 01-.239-.323 8.409 8.409 0 00.422-4.229C15.08 1.6 13.658.97 12.629 1.11a2.245 2.245 0 00-1.879 2.3c0 3.019-2.694 6.319-5.053 7.24a.987.987 0 00-.947-.74H1a1 1 0 00-1 1v11a1 1 0 001 1h3.75a1 1 0 001-1v-.329c1.029.147 1.8.288 2.462.408a19.267 19.267 0 004.038.421h6.5c2.256 0 2.5-1.4 2.5-2a2.486 2.486 0 00-.163-.887 2.5 2.5 0 001-3 2.5 2.5 0 00.9-3.231A2.749 2.749 0 0024 11.158zm-20.25 9a.75.75 0 11-.75-.75.75.75 0 01.75.75zM1 10.907zm21 .251a.751.751 0 01-.75.75h-.5a1 1 0 000 2 .5.5 0 010 1h-1a1 1 0 100 2 .5.5 0 010 1h-1a1 1 0 000 2 .5.5 0 01.492.46 2.246 2.246 0 01-.492.04h-6.5a17.46 17.46 0 01-3.677-.387c-.734-.135-1.621-.3-2.823-.46v-6.83c3.495-.909 7-5.359 7-9.323 0-.192.079-.3.152-.316.163-.021.6.276.885 1.305.294 1.046-.431 3.387-.956 4.618a1 1 0 00.919 1.393h7.5a.751.751 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});