define("ember-svg-jar/inlined/hand-expand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hand-expand</title><path d=\"M22.313 10.572a2 2 0 10-3.429-2.053l-.859 1.747a.25.25 0 01-.467-.17l1.476-5.962a2 2 0 10-3.911-.834l-.732 3.745A.25.25 0 0113.9 7V2.248a2 2 0 00-4 0v5.517a.25.25 0 01-.492.062l-1.019-4a2 2 0 10-3.907.858l2.25 8.966a.25.25 0 01-.422.235l-1.563-1.613a2 2 0 00-2.763 2.882l5.3 7.045a5.026 5.026 0 003.616 1.548h3.5c3.316 0 5-2.236 5-6.649a4.92 4.92 0 01.573-2.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});