define("ember-svg-jar/inlined/screwdriver", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>screwdriver</title><path d=\"M23.414 2.516l-1.98-1.98a1.249 1.249 0 00-1.87.116l-2.378 3.057a2 2 0 00-.286 1.946L11.649 10.9a.251.251 0 000 .354l1.06 1.061a.25.25 0 00.354 0l5.257-5.254a2.011 2.011 0 00.694.124 1.988 1.988 0 001.228-.421L23.3 4.387a1.251 1.251 0 00.117-1.871zM8.4 10.124a1 1 0 10-1.414 1.414l.345.344L1.2 18.013a3.307 3.307 0 00-.98 2.354 3.56 3.56 0 003.468 3.466 3.319 3.319 0 002.354-.973l6.131-6.13.465.465a1 1 0 001.414-1.414zm.957 3.785L2.92 20.342a.506.506 0 01-.707 0 .5.5 0 010-.707L8.645 13.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});