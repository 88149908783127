define("ember-svg-jar/inlined/astronomy-comet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astronomy-comet</title><path d=\"M12.3 12.3a7.448 7.448 0 015.267-2.2.5.5 0 00-.042-.449l-5.59-8.9a.65.65 0 00-1.229.343L10.4 5.635a.25.25 0 01-.343.215L3.435 3.159a.655.655 0 00-.862.949l3.121 5.958a.25.25 0 01-.206.365l-4.388.28a.675.675 0 00-.682.471.7.7 0 00.369.776l8.754 5.189a.5.5 0 00.58-.05A7.442 7.442 0 0112.3 12.3z\"/><path d=\"M17.6 11.605a6 6 0 106 6 6.036 6.036 0 00-6-6zm4.943 5.347a.249.249 0 01-.152.263 2.414 2.414 0 01-2.645-3.915.249.249 0 01.3-.045 4.893 4.893 0 011.093.816 4.955 4.955 0 011.407 2.881zM18.1 21.605a1.475 1.475 0 01-.286.874.251.251 0 01-.205.1A5.118 5.118 0 0115.251 22a.249.249 0 01-.129-.179 1.263 1.263 0 01-.019-.219 1.5 1.5 0 113 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});