define("ember-svg-jar/inlined/gardening-scissors-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gardening-scissors-1</title><path d=\"M7.931 9.986a.5.5 0 00.779.136 4.823 4.823 0 012.38-1.177.5.5 0 00.357-.716L7.608.552a1 1 0 00-1.653-.2 4.341 4.341 0 00-.541 4.883zM12.91 8.945a4.823 4.823 0 012.38 1.177.5.5 0 00.779-.136l2.517-4.754a4.341 4.341 0 00-.541-4.883 1 1 0 00-1.653.2l-3.839 7.68a.5.5 0 00.357.716zM20.05 21.339l-4.573-9.2a3.8 3.8 0 00-6.944-.021L3.95 21.339a1.843 1.843 0 003.3 1.641l3.9-7.839a.5.5 0 01.448-.276h.8a.5.5 0 01.448.277l3.9 7.838a1.842 1.842 0 003.3-1.641zM12 13.115a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});