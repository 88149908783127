define("ember-svg-jar/inlined/crypto-currency-bitcoin-chip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-chip</title><path d=\"M13.2 12.75H10a.25.25 0 00-.25.25v1.6a.25.25 0 00.25.25h3.2a1.05 1.05 0 100-2.1zM14.25 10.2a1.051 1.051 0 00-1.05-1.05H10a.25.25 0 00-.25.25V11a.25.25 0 00.25.25h3.2a1.051 1.051 0 001.05-1.05z\"/><path d=\"M23 12.5a1 1 0 000-2h-2.25a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25H23a1 1 0 000-2h-2.25a.25.25 0 01-.25-.25V4.633A1.137 1.137 0 0019.367 3.5H17.75a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v2.25a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v2.25a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v2.25a.25.25 0 01-.25.25H4.633A1.137 1.137 0 003.5 4.633V6.25a.25.25 0 01-.25.25H1a1 1 0 000 2h2.25a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25H1a1 1 0 000 2h2.25a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H1a1 1 0 000 2h2.25a.25.25 0 01.25.25v1.617A1.137 1.137 0 004.633 20.5H6.25a.25.25 0 01.25.25V23a1 1 0 002 0v-2.25a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25V23a1 1 0 002 0v-2.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V23a1 1 0 002 0v-2.25a.25.25 0 01.25-.25h1.617a1.136 1.136 0 001.133-1.133V17.75a.25.25 0 01.25-.25H23a1 1 0 000-2h-2.25a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25zM8.25 8.4A.75.75 0 019 7.65h.2a.25.25 0 00.25-.25v-.8a.75.75 0 111.5 0v.8a.25.25 0 00.25.25h1a.25.25 0 00.25-.25v-.8a.75.75 0 111.5 0v1a.251.251 0 00.161.234A2.536 2.536 0 0115.1 11.9a.156.156 0 000 .208 2.536 2.536 0 01-.992 4.064.251.251 0 00-.161.234v1a.75.75 0 01-1.5 0v-.8a.25.25 0 00-.25-.25h-1a.25.25 0 00-.25.25v.8a.75.75 0 01-1.5 0v-.8a.25.25 0 00-.25-.25H9a.75.75 0 01-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});