define("ember-svg-jar/inlined/rss-feed-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rss-feed-square</title><path d=\"M20 0H4a4 4 0 00-4 4v16a4 4 0 004 4h16a4 4 0 004-4V4a4 4 0 00-4-4zM5.5 11a1 1 0 010-2 9.511 9.511 0 019.5 9.5 1 1 0 01-2 0A7.509 7.509 0 005.5 11zm4 6A2.5 2.5 0 117 14.5 2.5 2.5 0 019.5 17zm-4-11a1 1 0 010-2A14.517 14.517 0 0120 18.5a1 1 0 01-2 0A12.514 12.514 0 005.5 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});