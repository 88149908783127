define("ember-svg-jar/inlined/database-sync-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-sync-alternate</title><path d=\"M12.18 22.067a.247.247 0 01.18-.074.251.251 0 01.178.079 6.033 6.033 0 0010.12-2.182 1 1 0 00-1.893-.647 4.037 4.037 0 01-6.828 1.4.25.25 0 01.008-.344l1.7-1.695a.495.495 0 00-.35-.845h-4.416a.5.5 0 00-.494.5v4.411a.494.494 0 00.844.35z\"/><path d=\"M2.75 17.381v-.724a.25.25 0 01.371-.219 13.714 13.714 0 004.215 1.38A.954.954 0 008.449 17l.011-.083a.941.941 0 00-.771-1.058C4.6 15.328 2.75 14.127 2.75 13.23v-.725a.25.25 0 01.371-.218 15.356 15.356 0 005.743 1.587.974.974 0 00.9-.457l.013-.02a.955.955 0 00-.67-1.5c-3.935-.363-6.357-1.792-6.357-2.819v-.725a.25.25 0 01.371-.218 17.251 17.251 0 007.909 1.7 17.242 17.242 0 007.91-1.7.25.25 0 01.371.215 5.753 5.753 0 01-.015.81.918.918 0 00.557 1.014c.08.031.16.063.239.1a.888.888 0 001.216-.833V4.927c0-3.187-5.3-4.906-10.279-4.906S.763 1.737.752 4.917v12.464c0 2.494 3.245 4.086 7.044 4.661a.949.949 0 001.069-1.219l-.031-.09a.977.977 0 00-.78-.664c-3.308-.498-5.304-1.757-5.304-2.688zm8.28-15.36c5.05 0 8.273 1.719 8.278 2.9v.009c-.011 1.185-3.233 2.9-8.278 2.9S2.75 6.112 2.75 4.927s3.226-2.906 8.28-2.906z\"/><path d=\"M21.4 12.986a.251.251 0 01-.358 0 6.044 6.044 0 00-10.105 2.209 1 1 0 001.892.648 4.039 4.039 0 016.805-1.428.248.248 0 01-.006.346L18 16.391a.494.494 0 00.35.844h4.411a.494.494 0 00.494-.5v-4.406a.5.5 0 00-.3-.457.626.626 0 00-.539.108z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});