define("ember-svg-jar/inlined/gesture-tap-clock-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-tap-clock-1</title><path d=\"M13.665 17.536l-3.741-1.117a.25.25 0 01-.178-.24v-4.567a1 1 0 00-2 0v7.063a.25.25 0 01-.456.143c-1.49-2.138-3.855-1.449-2.761.223l2.912 4.368a.5.5 0 00.416.222h5.434a.5.5 0 00.493-.41c.2-1.1.664-3.684.775-4.166a1.281 1.281 0 00-.894-1.519z\"/><path d=\"M4.118 6.99a7.293 7.293 0 00-.99 9.021.75.75 0 101.264-.811 5.744 5.744 0 016.667-8.53.246.246 0 00.245-.051.25.25 0 00.078-.239 5.973 5.973 0 01-.131-1.044.25.25 0 00-.188-.233A7.254 7.254 0 004.118 6.99zM14.1 15.2a.75.75 0 101.264.807 7.2 7.2 0 001.05-4.776.252.252 0 00-.2-.216 5.99 5.99 0 01-1-.263.25.25 0 00-.33.282A5.746 5.746 0 0114.1 15.2zM17.245.367A4.75 4.75 0 1022 5.117a4.755 4.755 0 00-4.755-4.75zm0 8a3.25 3.25 0 113.25-3.25 3.254 3.254 0 01-3.25 3.25z\"/><path d=\"M19.013 4.367h-.768a.25.25 0 01-.25-.25v-.768a.75.75 0 00-1.5 0v1.768a.75.75 0 00.75.75h1.768a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});