define("ember-svg-jar/inlined/design-file-dwg-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-dwg-1</title><path d=\"M23.707 5.708L18.293.293A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.75V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.706z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zm-16-.875a.625.625 0 01-.625-.625v-6A.625.625 0 012 10.875a3.625 3.625 0 010 7.25zm9.625-4.861a10.158 10.158 0 01-1.065 4.515.625.625 0 01-1.166-.128s-.281-1.138-.317-1.265c-.018-.1-.134-.082-.155 0l-.316 1.262a.625.625 0 01-.538.47.61.61 0 01-.628-.342 10.158 10.158 0 01-1.065-4.515V11.5a.625.625 0 011.25 0v1.764a8.837 8.837 0 00.183 1.783c.024.113.145.066.16.006l.426-1.7a.625.625 0 011.212 0s.393 1.622.414 1.693.157.121.183-.01a9.2 9.2 0 00.172-1.767V11.5a.625.625 0 011.25 0zm5 .236a.625.625 0 01-1.25 0V13a.875.875 0 00-1.75 0v3a.875.875 0 001.722.221.076.076 0 00-.069-.1H15a.625.625 0 010-1.25h1a.625.625 0 01.625.625V16a2.125 2.125 0 01-4.25 0v-3a2.125 2.125 0 014.25 0z\"/><path d=\"M2.956 12.318a.25.25 0 00-.331.24v3.883a.25.25 0 00.331.24A2.238 2.238 0 004.375 14.5a2.239 2.239 0 00-1.419-2.182z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});