define("ember-svg-jar/inlined/real-estate-action-building-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-building-heart</title><path d=\"M6.723 6.053l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 000 6.5V14a.5.5 0 00.5.5H2a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5A.5.5 0 007 14V6.5a.5.5 0 00-.277-.447zM5 10.5H2a.5.5 0 010-1h3a.5.5 0 010 1zM5 8H2a.5.5 0 010-1h3a.5.5 0 010 1zM13.721 11.065a4.436 4.436 0 01.77-.051.5.5 0 00.509-.5V3a.5.5 0 00-.308-.462l-6-2.5A.5.5 0 008 .5V14a.5.5 0 00.5.5h.885a.5.5 0 00.466-.318 4.35 4.35 0 01.179-.4 5.039 5.039 0 013.691-2.717zM10 4.5h3a.5.5 0 010 1h-3a.5.5 0 010-1zM10 7h3a.5.5 0 010 1h-3a.5.5 0 010-1zm0 3.5a.5.5 0 010-1h3a.5.5 0 010 1zM23.627 14.449a3.525 3.525 0 00-5.646-.916l-.128.128a.5.5 0 01-.707 0l-.128-.128a3.525 3.525 0 00-4.993 4.978l5.113 5.335a.5.5 0 00.723 0l5.106-5.327a3.524 3.524 0 00.66-4.07z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});