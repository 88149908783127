define("ember-svg-jar/inlined/love-it-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it-lock</title><path d=\"M3.332 10.142a4.422 4.422 0 01-.849-5.16 4.5 4.5 0 013.329-2.42 4.578 4.578 0 013.994 1.284l1.531 1.4a1 1 0 001.348 0l1.561-1.423a4.55 4.55 0 013.965-1.257 4.5 4.5 0 013.329 2.42 4.24 4.24 0 01-.446 4.552.25.25 0 00.043.342 4.986 4.986 0 01.972 1.058.251.251 0 00.4.015 6.273 6.273 0 00.814-6.871A6.59 6.59 0 0012.87 2.368l-.859.783-.828-.751A6.53 6.53 0 005.49.589 6.472 6.472 0 00.7 4.078a6.4 6.4 0 001.2 7.454l9.083 9.84a.814.814 0 00.134.116.249.249 0 00.392-.206v-2.191a.248.248 0 00-.066-.17z\"/><path d=\"M21.5 15v-1.25a3.5 3.5 0 00-7 0V15a1.5 1.5 0 00-1.5 1.5V22a1.5 1.5 0 001.5 1.5h7A1.5 1.5 0 0023 22v-5.5a1.5 1.5 0 00-1.5-1.5zM18 12.251a1.5 1.5 0 011.5 1.5v1a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-1a1.5 1.5 0 011.5-1.5zm1 7.5a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});