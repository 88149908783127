define("ember-svg-jar/inlined/christmas-bells", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-bells</title><path d=\"M23.922 15.4a1.165 1.165 0 00-.711-.682 3.835 3.835 0 01-2.238-2.321l-1.214-3.14a.3.3 0 00-.436-.147 3.661 3.661 0 01-4.494-.54c-.364-.366-.541-.54-.637-.636a.2.2 0 00-.337.1.378.378 0 00.077.314 6.359 6.359 0 01.783 6.55l-1.359 3.075a2.851 2.851 0 00-.074 2.382.414.414 0 00.522.205l9.45-3.657a1.173 1.173 0 00.668-1.51zM20.767 18.94L16.1 20.748a.5.5 0 00-.186.807 3.2 3.2 0 005.538-2.143.5.5 0 00-.68-.472z\"/><path d=\"M12.442 17.571L13.8 14.5a5.269 5.269 0 00-1.4-6.263.507.507 0 00-.249-.1 3.185 3.185 0 01-.8-.209.5.5 0 00-.541.109 97.93 97.93 0 00-.543.544 3.661 3.661 0 01-4.448.566.5.5 0 00-.66.14 4.943 4.943 0 00-.507.888L3.187 13.48a3.837 3.837 0 01-2.345 2.213A1.168 1.168 0 00.7 17.881l10.169 4.5a1.167 1.167 0 001.52-1.581 3.836 3.836 0 01.053-3.229zM7.532 22l-4.31-1.9a.5.5 0 00-.7.45 3.06 3.06 0 005.153 2.277.5.5 0 00-.143-.827zM12.548 7.152a2.189 2.189 0 10-2.188-2.189 2.189 2.189 0 002.188 2.189z\"/><path d=\"M7.676 8.644a2.664 2.664 0 001.884-.781l.368-.363a.5.5 0 00.049-.649 3.183 3.183 0 01.377-4.194.5.5 0 00.009-.716l-.8-.8a2.664 2.664 0 00-4.552 1.877v2.961a2.665 2.665 0 002.665 2.665zM15.736 4.963a3.169 3.169 0 01-.617 1.883.5.5 0 00.049.649l.368.368a2.664 2.664 0 004.549-1.884V3.018a2.664 2.664 0 00-4.549-1.884l-.8.8a.5.5 0 00.009.716 3.178 3.178 0 01.991 2.313z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});