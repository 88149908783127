define("ember-svg-jar/inlined/medical-room-clock-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-room-clock-woman</title><path d=\"M5.5 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm0-8a3 3 0 11-3 3 3 3 0 013-3z\"/><path d=\"M5.5 6.3h1.086a.75.75 0 000-1.5H6.25v-.866a.75.75 0 00-1.5 0v1.619a.75.75 0 00.75.747zM11.882 17.757l.965-2.414a.25.25 0 00-.232-.343H7.734l-2.617-3.675a1.986 1.986 0 00-1.31-.8 1.966 1.966 0 00-1.483.36 2 2 0 00-.444 2.79l3.216 4.5A2.007 2.007 0 006.713 19h4.8a.254.254 0 00.188-.084.257.257 0 00.061-.2 1.929 1.929 0 01.12-.959zM14.5 23v-2.25a.25.25 0 00-.25-.25h-.512a1.991 1.991 0 01-1.253-.445.25.25 0 00-.157-.055H2a1 1 0 00-1 1v2.5a.5.5 0 00.5.5h12.857a.25.25 0 00.24-.32A2.424 2.424 0 0114.5 23zM21.75 20.5a.25.25 0 00-.25.25V23a2.468 2.468 0 01-.1.681.251.251 0 00.241.319H23a.5.5 0 00.5-.5V21a.992.992 0 00-.321-.726 1.99 1.99 0 01-.917.226z\"/><circle cx=\"18.022\" cy=\"3.5\" r=\"2.5\"/><path d=\"M20 19h2.262a.5.5 0 00.464-.686L21 14v-4a2 2 0 00-2-2h-2a2 2 0 00-2 2v4l-1.726 4.314a.5.5 0 00.464.686H16v4a1.019 1.019 0 001.022 1h2A.981.981 0 0020 23z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});