define("ember-svg-jar/inlined/science-perspective", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-perspective</title><path d=\"M23.982 22.821l-1.746-9a1.051 1.051 0 00-1.05-.821H16.25a.5.5 0 00-.5.5v.5a4.268 4.268 0 002.76 3.981 1.753 1.753 0 011.147 1.635 1.722 1.722 0 01-1.094 1.628A18.94 18.94 0 0112 22.25a18.93 18.93 0 01-6.562-1.006 1.722 1.722 0 01-1.095-1.628 1.753 1.753 0 011.147-1.635A4.268 4.268 0 008.25 14v-.5a.5.5 0 00-.5-.5h-4.5a1.056 1.056 0 00-1.041.778l-2.183 9A1.019 1.019 0 001.068 24h21.865a1.021 1.021 0 001.049-1.179z\"/><path d=\"M9.4 15.97a.5.5 0 00.425.671 26.383 26.383 0 004.355.009.5.5 0 00.427-.672 5.72 5.72 0 01-.32-1.346.5.5 0 00-.534-.443c-.531.04-1.092.061-1.665.061-.635 0-1.253-.025-1.831-.074a.5.5 0 00-.539.444 5.7 5.7 0 01-.318 1.35zM8.011 18.115a5.723 5.723 0 01-1.813 1.2.331.331 0 00.015.618A18.354 18.354 0 0012 20.75a18.354 18.354 0 005.787-.817.331.331 0 00.015-.618A5.727 5.727 0 0116 18.128a.5.5 0 00-.43-.135 22.768 22.768 0 01-3.524.257 22.386 22.386 0 01-3.6-.271.5.5 0 00-.435.136zM14.113 10.9a.5.5 0 00-.359-.152h-3.508a.5.5 0 00-.5.516c.006.179 0 1 0 .9a.5.5 0 00.454.5 21.689 21.689 0 003.587.016.5.5 0 00.459-.5v-.916a.5.5 0 00-.133-.364zM3.037 9.75h18.926a.54.54 0 00.44-.215.466.466 0 00.077-.418l-1.48-5a.532.532 0 00-.518-.367h-3.015a.25.25 0 00-.126.466A2.03 2.03 0 0118.5 6c0 2.393-4.072 2.75-6.5 2.75S5.5 8.393 5.5 6a2.03 2.03 0 011.159-1.784.25.25 0 00-.126-.466H4.889a.538.538 0 00-.508.337l-1.851 5a.464.464 0 00.057.435.54.54 0 00.45.228z\"/><path d=\"M10.625 5.75A.625.625 0 0110 5.125a.51.51 0 00-.567-.508C6.751 4.91 6.75 5.672 6.75 6c0 .41 0 1.5 5.25 1.5s5.25-1.09 5.25-1.5c0-.328 0-1.09-2.683-1.383a.51.51 0 00-.567.508.625.625 0 01-1.25 0V4a.5.5 0 01.5-.5H14a.75.75 0 00.53-1.28l-2-2a.749.749 0 00-1.06 0l-2 2A.75.75 0 0010 3.5h.75a.5.5 0 01.5.5v1.125a.625.625 0 01-.625.625z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});