define("ember-svg-jar/inlined/real-estate-sign-house-rent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-sign-house-rent</title><path d=\"M23.844 10.636L13.03.409a1.5 1.5 0 00-2.06 0L.156 10.636A.5.5 0 00.5 11.5h2a.5.5 0 01.5.5v2.5a.5.5 0 00.5.5h17a.5.5 0 00.5-.5V12a.5.5 0 01.5-.5h2a.5.5 0 00.344-.863zM16 16.5a.75.75 0 00-.75.75v1.764a.25.25 0 01-.474.112l-1.105-2.211a.75.75 0 00-1.421.335v6a.75.75 0 001.5 0v-1.764a.25.25 0 01.474-.112l1.105 2.211a.75.75 0 001.421-.335v-6a.75.75 0 00-.75-.75zM9 18h2a.75.75 0 000-1.5H9a1.752 1.752 0 00-1.75 1.75v4A1.752 1.752 0 009 24h2a.75.75 0 000-1.5H9a.25.25 0 01-.25-.25v-1A.25.25 0 019 21h1a.75.75 0 000-1.5H9a.25.25 0 01-.25-.25v-1A.25.25 0 019 18zM2.25 23.25a.75.75 0 001.5 0v-.586a.25.25 0 01.427-.177L5.47 23.78a.75.75 0 001.06-1.06L5.1 21.291a.25.25 0 01.1-.414 2.244 2.244 0 00-.7-4.377H3a.75.75 0 00-.75.75zM4.5 19.5H4a.25.25 0 01-.25-.25v-1A.25.25 0 014 18h.5a.75.75 0 010 1.5zM21 16.5h-3a.75.75 0 000 1.5h.5a.25.25 0 01.25.25v5a.75.75 0 001.5 0v-5a.25.25 0 01.25-.25h.5a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});