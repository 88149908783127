define("ember-svg-jar/inlined/human-resources-search-women", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-search-women</title><path d=\"M19.977 12.012a.25.25 0 00-.273-.054 6.972 6.972 0 01-2.129.518.249.249 0 00-.22.318 1.959 1.959 0 01.071.71 2.006 2.006 0 01-.826 1.483 8.963 8.963 0 01-1.936 1.055.25.25 0 00-.025.456q.7.36 1.4.778a5.22 5.22 0 012.46 4.7v1.774a.25.25 0 00.25.25h5a.25.25 0 00.25-.25v-1.262a2.81 2.81 0 00-1.307-2.56 16.426 16.426 0 00-2.955-1.391c-.268-.093-.551-.332-.551-.6V17.6a.252.252 0 01.074-.178.246.246 0 01.178-.072 5.651 5.651 0 003.609-1.025.383.383 0 00.145-.287.41.41 0 00-.122-.311 2.431 2.431 0 01-.826-1.708.247.247 0 00-.156-.2 2.5 2.5 0 01-.857-.561z\"/><path d=\"M10 5.5c0-.124 0-.247.01-.368a.249.249 0 00-.181-.253 4.883 4.883 0 00-2.855.061.354.354 0 01-.044.011c-2.18.322-4.464 1.909-4.713 5.594-.159 2.349-.521 2.709-1.151 3.339a.5.5 0 00.067.763 6.775 6.775 0 004.3 1.263h.154a.249.249 0 01.253.25v.423c0 .327-.343.619-.668.732a19.891 19.891 0 00-3.589 1.7A3.452 3.452 0 000 22.15v1.6a.25.25 0 00.25.25h15.083a.25.25 0 00.25-.25v-1.6A3.448 3.448 0 0014 19.017a19.923 19.923 0 00-3.589-1.7c-.326-.113-.668-.406-.668-.732v-.424a.247.247 0 01.074-.177.251.251 0 01.178-.073 6.886 6.886 0 004.458-1.261.517.517 0 00.2-.382.548.548 0 00-.162-.413 3.075 3.075 0 01-1.009-2.2.249.249 0 00-.117-.179A6.994 6.994 0 0110 5.5zM17 0a5.5 5.5 0 102.949 10.14.25.25 0 01.311.034l2.033 2.033a1 1 0 101.414-1.415L21.674 8.76a.25.25 0 01-.034-.311A5.5 5.5 0 0017 0zm-3.5 5.5A3.5 3.5 0 1117 9a3.5 3.5 0 01-3.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});