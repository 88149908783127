define("ember-svg-jar/inlined/alarm-bell-check-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>alarm-bell-check-1</title><path d=\"M9.5 21.5h-3a.5.5 0 00-.5.5 2 2 0 004 0 .5.5 0 00-.5-.5zM11.838 7.646A5.662 5.662 0 009 6.127V4.5a1 1 0 10-2 0v1.627C4.168 6.684 2 9.55 2 13v4.5a1 1 0 01-1 1 1 1 0 000 2h14a1 1 0 100-2 1 1 0 01-1-1V13a7.912 7.912 0 00-.4-2.508 7 7 0 01-1.762-2.846zM18.5 0A5.5 5.5 0 1024 5.5 5.5 5.5 0 0018.5 0zm-1.124 8.118a.746.746 0 01-.53-.212l-1.5-1.5a.75.75 0 111.06-1.06l.97.969L20.6 3.1a.75.75 0 011.06 1.06l-3.75 3.75a.751.751 0 01-.534.208z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});