define("ember-svg-jar/inlined/app-window-upload-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-upload-2</title><path d=\"M16.354 14.147A.5.5 0 0116 15h-2a.5.5 0 00-.5.5V22a1.5 1.5 0 11-3 0v-6.5a.5.5 0 00-.5-.5H8a.5.5 0 01-.353-.854l4-4a.5.5 0 01.707 0z\"/><path d=\"M24 3.5a3 3 0 00-3-3H3a3 3 0 00-3 3v12a3 3 0 003 3h3.5a1 1 0 001-1 1 1 0 00-1-1H3a1 1 0 01-1-1V5.751a.25.25 0 01.25-.25h19.5a.25.25 0 01.25.25V15.5a1 1 0 01-1 1h-3.5a1 1 0 00-1 1 1 1 0 001 1H21a3 3 0 003-3zm-19.654 0a.966.966 0 01-1.692 0A.972.972 0 012.5 3a.987.987 0 01.092-.409A1 1 0 013.5 2a.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5zm3.5 0a.966.966 0 01-1.692 0A.972.972 0 016 3a.969.969 0 01.154-.5.966.966 0 011.692 0A.969.969 0 018 3a.972.972 0 01-.154.5zm3.5 0a.966.966 0 01-1.692 0A.972.972 0 019.5 3a.969.969 0 01.154-.5.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});