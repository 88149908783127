define("ember-svg-jar/inlined/temperature-celsius-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>temperature-celsius-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M11 7.5a4.458 4.458 0 012.045.49 1 1 0 10.91-1.781 6.5 6.5 0 100 11.582 1 1 0 10-.91-1.781A4.458 4.458 0 0111 16.5a4.5 4.5 0 010-9z\"/><circle cx=\"17.5\" cy=\"9.5\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});