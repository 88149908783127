define("ember-svg-jar/inlined/file-code-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-search</title><path d=\"M10.168 19.788a.241.241 0 01-.03-.3 5.515 5.515 0 10-1.688 1.684.241.241 0 01.3.03l2.506 2.506a1 1 0 001.414-1.414zm-4.668.246a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5zM17.75 11.5V15a.75.75 0 001.5 0v-3.5a.75.75 0 10-1.5 0zM13.75 8.5V5a.75.75 0 00-1.5 0v3.5a.75.75 0 001.5 0zM20.25 8V5.5A1.251 1.251 0 0019 4.251h-1.5A1.251 1.251 0 0016.25 5.5V8a1.252 1.252 0 001.25 1.25H19A1.252 1.252 0 0020.25 8zm-1.5-.5a.25.25 0 01-.25.25H18a.25.25 0 01-.25-.25V6a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25z\"/><path d=\"M23.414 3L21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.3a.243.243 0 00.222.241 6.864 6.864 0 011.453.309A.243.243 0 008 9.625V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.121a.5.5 0 01.146.355V18a.5.5 0 01-.5.5h-9.118a.241.241 0 00-.23.169 6.9 6.9 0 01-.426 1.026.245.245 0 00.066.268l.394.393a.448.448 0 00.328.143H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M11.25 12v.446a.231.231 0 00.041.132 6.971 6.971 0 011.14 2.95.24.24 0 00.236.214l1.333.009a1.252 1.252 0 001.25-1.25V12A1.25 1.25 0 0014 10.751h-1.5A1.25 1.25 0 0011.25 12zm1.5.5a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25V14a.25.25 0 01-.25.25H13a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});