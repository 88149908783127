define("ember-svg-jar/inlined/video-game-sword", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-sword</title><path d=\"M7.254 8.315a.75.75 0 011.061-1.061l8.328 8.329a.5.5 0 00.707 0l2.361-2.359a.5.5 0 01.568-.1 1 1 0 001.137-1.61 2 2 0 00-2.828 0l-.6.6a.5.5 0 01-.726-.02L7.427 1.1a1.5 1.5 0 00-.956-.5L1.11.006a1 1 0 00-1.1 1.105L.6 6.472a1.5 1.5 0 00.493.955l11 9.837a.5.5 0 01.02.726l-.6.6a2 2 0 000 2.828 1 1 0 001.61-1.138.5.5 0 01.1-.569l2.359-2.358a.5.5 0 000-.707zM23.488 21.013l-4.37-4.369a.5.5 0 00-.707 0l-1.767 1.767a.5.5 0 000 .707l4.369 4.37a1.75 1.75 0 102.475-2.475z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});