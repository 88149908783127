define("ember-svg-jar/inlined/plane-board-arrival-departure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-board-arrival-departure</title><path d=\"M1.5 22.75h21a1.5 1.5 0 001.5-1.5V2.75a1.5 1.5 0 00-1.5-1.5h-21A1.5 1.5 0 000 2.75v18.5a1.5 1.5 0 001.5 1.5zM8.25 20h-3.5a.75.75 0 010-1.5h3.5a.75.75 0 010 1.5zm0-4h-3.5a.75.75 0 010-1.5h3.5a.75.75 0 010 1.5zm0-4h-3.5a.75.75 0 010-1.5h3.5a.75.75 0 010 1.5zm11 8h-3.5a.75.75 0 010-1.5h3.5a.75.75 0 010 1.5zm0-4h-3.5a.75.75 0 010-1.5h3.5a.75.75 0 010 1.5zm0-4h-3.5a.75.75 0 010-1.5h3.5a.75.75 0 010 1.5zm-5.794-5.69l.919-.585a.256.256 0 01.31.043l.56.567a.256.256 0 00.264.064l.942-.308a.256.256 0 00.169-.178.259.259 0 00-.057-.24l-1.17-1.3a.258.258 0 01.111-.418l.727-.237a.257.257 0 01.228.034l1.884 1.322a.258.258 0 00.257.026l1.217-.565a.961.961 0 011.332 1.173l-.037.108a1.03 1.03 0 01-.612.584h-.012l-5.452 1.9a.258.258 0 01-.271-.073l-1.374-1.52a.257.257 0 01.065-.4zM11.75 10.5h1a.75.75 0 010 1.5h-1a.75.75 0 010-1.5zm0 4h1a.75.75 0 010 1.5h-1a.75.75 0 010-1.5zm0 4h1a.75.75 0 010 1.5h-1a.75.75 0 010-1.5zM3.093 3.762a.256.256 0 01.216-.054l1.078.158a.258.258 0 01.206.236l.052.8a.255.255 0 00.157.221l.914.384a.258.258 0 00.358-.242l-.035-1.752A.258.258 0 016.4 3.27l.7.3a.258.258 0 01.15.175l.56 2.233a.259.259 0 00.179.186l1.29.369h.005a.961.961 0 01.239 1.759l-.1.058a1.032 1.032 0 01-.845.039h-.012L3.2 6.248a.256.256 0 01-.158-.232L3 3.965a.26.26 0 01.093-.203z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});