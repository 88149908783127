define("ember-svg-jar/inlined/cat-mouse-toy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cat-mouse-toy</title><path d=\"M21.19 9.038c-.357-.147-4.273-1.983-5.69-2.574V3.4a3.123 3.123 0 001.484.6 2 2 0 100-4A5.172 5.172 0 0014.5 1.339 5.172 5.172 0 0012.016 0a2 2 0 100 4 3.123 3.123 0 001.484-.6v2.285a10.128 10.128 0 00-3-.685 5 5 0 00-4.9 6H3.75a3.75 3.75 0 000 7.5h2a1.75 1.75 0 010 3.5H3a1 1 0 000 2h2.75a3.75 3.75 0 000-7.5h-2a1.75 1.75 0 010-3.5h2.776a4.977 4.977 0 003.974 2h10.75A2.752 2.752 0 0024 12.25c0-1.842-1.6-2.715-2.81-3.212zm-2.817 1.516a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});