define("ember-svg-jar/inlined/decoration-flowers-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>decoration-flowers-table</title><path d=\"M24 18.5a1.5 1.5 0 00-1.5-1.5h-21A1.5 1.5 0 000 18.5v1A1.5 1.5 0 001.5 21h.477a.25.25 0 01.213.119.248.248 0 01.011.243l-.6 1.191a1 1 0 001.79.894l1.089-2.171A.5.5 0 014.927 21h14.146a.5.5 0 01.447.276l1.085 2.171a1 1 0 101.79-.894l-.6-1.191a.248.248 0 01.011-.243.25.25 0 01.217-.119h.477a1.5 1.5 0 001.5-1.5zM7.824 5.006a1 1 0 001.344.438 4 4 0 014.025.22 5.158 5.158 0 012.024 2.65l.03.089a.25.25 0 01-.116.3 1.469 1.469 0 00-.7.852A16.365 16.365 0 0013.5 15a1 1 0 001 1h4a1 1 0 001-1 16.355 16.355 0 00-.932-5.446A1.448 1.448 0 0018 8.792a.251.251 0 01-.1-.266l.4-1.6a8.548 8.548 0 01.3-.959.249.249 0 01.372-.12l1.443.962a.99.99 0 00.553.168 1 1 0 00.556-1.832l-1.615-1.074a.25.25 0 01-.063-.357 8.546 8.546 0 01.82-.966l1.041-1.041A1 1 0 0020.293.293l-.722.722a.25.25 0 01-.385-.038l-.354-.532a1 1 0 00-1.664 1.11l.759 1.139a.25.25 0 010 .282 9.34 9.34 0 00-.441.677l-.007.014a10.81 10.81 0 00-.9 2.065.251.251 0 01-.443.067A7.016 7.016 0 0014.3 4a5.767 5.767 0 00-.85-.458.252.252 0 01-.122-.117L11.9.553a1 1 0 00-1.79.894l.614 1.228a.248.248 0 01-.006.235.252.252 0 01-.2.126 5.989 5.989 0 00-2.253.625 1 1 0 00-.441 1.345z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});