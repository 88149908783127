define("ember-svg-jar/inlined/single-neutral-decrease", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-decrease</title><path d=\"M5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M23.679 3.908a.5.5 0 00-.551.132l-1.256 1.4a.252.252 0 01-.187.083.25.25 0 01-.186-.084l-2.564-2.89a1.251 1.251 0 00-1.716-.149L15.84 3.5a.25.25 0 01-.344-.03L12.941.552A1.25 1.25 0 0011.059 2.2l3.5 4a1.251 1.251 0 001.722.153l1.387-1.11a.25.25 0 01.343.029l1.843 2.077a.251.251 0 010 .334L18.628 9.04a.5.5 0 00.372.835h4.5a.5.5 0 00.5-.5v-5a.5.5 0 00-.321-.467zM5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M23.679 3.908a.5.5 0 00-.551.132l-1.256 1.4a.252.252 0 01-.187.083.25.25 0 01-.186-.084l-2.564-2.89a1.251 1.251 0 00-1.716-.149L15.84 3.5a.25.25 0 01-.344-.03L12.941.552A1.25 1.25 0 0011.059 2.2l3.5 4a1.251 1.251 0 001.722.153l1.387-1.11a.25.25 0 01.343.029l1.843 2.077a.251.251 0 010 .334L18.628 9.04a.5.5 0 00.372.835h4.5a.5.5 0 00.5-.5v-5a.5.5 0 00-.321-.467z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});