define("ember-svg-jar/inlined/social-music-rdio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-music-rdio</title><path d=\"M23.62 6.38a.71.71 0 00-.54-.17c-2.26.06-3.53-.62-5.82-1.85C16.53 4 15.7 3.52 14.72 3a.5.5 0 00-.72.45v8.65a4.22 4.22 0 01-4.34 3.41c-3.16 0-3.36-2.41-3.38-2.69a.51.51 0 000-.1s-.39-1.69 2-3.39a4.76 4.76 0 013.22-.87.48.48 0 00.38-.13A.5.5 0 0012 8V2.43a.5.5 0 00-.48-.5h-1A10.3 10.3 0 000 12a10.3 10.3 0 0010.48 10.08A10.3 10.3 0 0021 12a9.86 9.86 0 00-.05-1 5.68 5.68 0 002.36-1.82A2.53 2.53 0 0024 7.43a1.28 1.28 0 00-.38-1.05z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});