define("ember-svg-jar/inlined/composition-window-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>composition-window-woman</title><path d=\"M24 5.5a3 3 0 00-3-3H3a3 3 0 00-3 3v13a3 3 0 003 3h18a3 3 0 003-3zm-2 13a1 1 0 01-1 1H3a1 1 0 01-1-1v-13a1 1 0 011-1h18a1 1 0 011 1z\"/><path d=\"M16.876 17.164a6.575 6.575 0 00-9.752 0 .5.5 0 00.37.836h9.012a.5.5 0 00.37-.836z\"/><path d=\"M7.153 13.132a1 1 0 001.682 1.083 4.98 4.98 0 00.395-.748.245.245 0 01.155-.14.25.25 0 01.208.027 4.041 4.041 0 004.3 0 .25.25 0 01.363.113 5 5 0 00.4.749 1 1 0 101.68-1.085 3.9 3.9 0 01-.539-2.119V9.923a4.052 4.052 0 10-8.1 0v1.089a3.86 3.86 0 01-.544 2.12zm4.591-1.158a2.055 2.055 0 01-2.023-1.712.25.25 0 01.152-.272 5 5 0 001.7-1.15.252.252 0 01.18-.075.248.248 0 01.179.076 5 5 0 001.685 1.146.25.25 0 01.151.272 2.054 2.054 0 01-2.024 1.715z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});