define("ember-svg-jar/inlined/multiple-users-network", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-users-network</title><path d=\"M11.984 15a7.5 7.5 0 10.021-15A7.407 7.407 0 004.5 7.358 7.571 7.571 0 0011.984 15zM6.659 8.682a.25.25 0 01.241-.307h1.27a.248.248 0 01.247.215 11.575 11.575 0 00.96 3.19.25.25 0 01-.367.312 5.653 5.653 0 01-2.351-3.41zm5.148-6.411a.25.25 0 01.395 0 8.033 8.033 0 011.7 4.076.251.251 0 01-.249.278h-3.3a.249.249 0 01-.186-.083.253.253 0 01-.063-.195 8.033 8.033 0 011.703-4.076zm1.757 6.1a.25.25 0 01.246.295 10.86 10.86 0 01-1.6 3.884.251.251 0 01-.422 0A10.876 10.876 0 0110.2 8.67a.25.25 0 01.246-.295zm1.428 3.729a.251.251 0 01-.365-.314 11.572 11.572 0 00.963-3.196.25.25 0 01.247-.215h1.282a.247.247 0 01.2.094.254.254 0 01.049.211 5.536 5.536 0 01-2.376 3.42zm2.38-5.777a.25.25 0 01-.244.3h-1.22a.25.25 0 01-.249-.229 9.384 9.384 0 00-.759-3.012.25.25 0 01.378-.3 5.476 5.476 0 012.094 3.24zM8.78 3a.25.25 0 01.37.308 9.368 9.368 0 00-.8 3.091.25.25 0 01-.249.229H6.866a.251.251 0 01-.245-.3A5.21 5.21 0 018.78 3zM3.5 20.4a2.2 2.2 0 10-2.2-2.2 2.2 2.2 0 002.2 2.2zM3.5 21.2a3.592 3.592 0 00-3.41 2.47.249.249 0 00.034.224.252.252 0 00.2.1h6.349a.252.252 0 00.2-.1.246.246 0 00.034-.224A3.592 3.592 0 003.5 21.2z\"/><circle cx=\"20.5\" cy=\"18.199\" r=\"2.199\"/><path d=\"M20.5 21.2a3.592 3.592 0 00-3.41 2.47.25.25 0 00.237.328h6.346a.249.249 0 00.237-.328 3.592 3.592 0 00-3.41-2.47z\"/><circle cx=\"12\" cy=\"18.199\" r=\"2.199\"/><path d=\"M12 21.2a3.592 3.592 0 00-3.41 2.47.25.25 0 00.237.33h6.346a.249.249 0 00.237-.328A3.592 3.592 0 0012 21.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});