define("ember-svg-jar/inlined/add-tab-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>add-tab-alternate</title><path d=\"M18.408 6a3.825 3.825 0 00-2.414-.972H2.211a2 2 0 00-2 2v9.936a2 2 0 002 2h13.783A3.839 3.839 0 0018.408 18l4.775-4.585a1.956 1.956 0 000-2.828zm-1.385 10.55a1.72 1.72 0 01-1.029.414H2.711a.5.5 0 01-.5-.5V7.532a.5.5 0 01.5-.5h13.283a1.93 1.93 0 011.029.414l4.394 4.163a.5.5 0 010 .724z\"/><path d=\"M10.711 10.718h-1a.25.25 0 01-.25-.25v-1a1.25 1.25 0 00-2.5 0v1a.25.25 0 01-.25.25h-1a1.25 1.25 0 000 2.5h1a.249.249 0 01.25.25v1a1.25 1.25 0 002.5 0v-1a.249.249 0 01.25-.25h1a1.25 1.25 0 100-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});