define("ember-svg-jar/inlined/headphones-customer-support-human", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>headphones-customer-support-human</title><path d=\"M24 14a4.493 4.493 0 00-3-4.239V9A9 9 0 003 9v.761a4.5 4.5 0 000 8.483 1.5 1.5 0 002-1.414V9a7 7 0 1114 0v7.83a1.494 1.494 0 00.5 1.1v.57c0 1.474-1.291 2-2.5 2h-1.778a2 2 0 100 2H17c2.65 0 4.5-1.645 4.5-4v-.478A4.5 4.5 0 0024 14z\"/><path d=\"M15.144 14.045a.833.833 0 01.7-.377.823.823 0 01.454.135.833.833 0 01.243 1.154 5.418 5.418 0 01-9.08 0 .834.834 0 011.4-.912 3.75 3.75 0 006.288 0z\"/><circle cx=\"8.667\" cy=\"9.501\" r=\"1.667\"/><circle cx=\"15.333\" cy=\"9.501\" r=\"1.667\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});