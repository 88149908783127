define("ember-svg-jar/inlined/vectors-pen-flat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vectors-pen-flat</title><path d=\"M24 15.75a3 3 0 00-5.769-1.15.25.25 0 01-.23.154H15.25A.25.25 0 0115 14.5v-1a1 1 0 00-1-1h-4a1 1 0 00-1 1v1a.25.25 0 01-.25.25H6a.25.25 0 01-.23-.154 3 3 0 100 2.308.25.25 0 01.23-.154h1.1a.25.25 0 01.158.444A7.49 7.49 0 004.5 23a1 1 0 002 0 5.5 5.5 0 012.642-4.7.252.252 0 01.276.01.993.993 0 00.582.19h4a.993.993 0 00.582-.187.252.252 0 01.276-.01A5.5 5.5 0 0117.5 23a1 1 0 002 0 7.49 7.49 0 00-2.758-5.806.25.25 0 01.158-.444H18a.25.25 0 01.23.154A3 3 0 0024 15.75zm-4 0a1 1 0 111 1 1 1 0 01-1-1zm-7.25-1.25a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25zM4 15.75a1 1 0 11-1-1 1 1 0 011 1zM11.265 10.9a.749.749 0 001.47 0c.373-1.864 2.867-4.686 3.8-5.617a.749.749 0 00.172-.794l-1.5-4A.75.75 0 0014.5 0h-5a.749.749 0 00-.7.486l-1.5 4a.749.749 0 00.172.794c.928.931 3.42 3.753 3.793 5.62zM12 2.25a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});