define("ember-svg-jar/inlined/restaurant-seat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>restaurant-seat</title><circle cx=\"4.817\" cy=\"2.484\" r=\"2.484\"/><path d=\"M11.274 11.922a1.49 1.49 0 000-2.98H10.5a4.454 4.454 0 01-2.369-.681L5.607 6.684a1.491 1.491 0 00-2.28 1.264v5.961a2.485 2.485 0 002.483 2.483h2.35a.249.249 0 01.244.3l-.578 2.888a1.49 1.49 0 102.922.585l.756-3.778a2.483 2.483 0 00-2.435-2.972H6.555a.248.248 0 01-.248-.248v-2.242a.159.159 0 01.245-.135 7.449 7.449 0 003.948 1.132zM18.228 9.1h-2.483a.5.5 0 00-.492.567l.5 3.477a.5.5 0 00.492.427h1.49a.5.5 0 00.492-.427l.5-3.477a.5.5 0 00-.492-.567zM22.2 5.124h-.5a.5.5 0 00-.482.374l-.978 3.828-.031.246v3.5a.5.5 0 00.5.5H23.2a.5.5 0 00.5-.5v-3.5l-.03-.246-.987-3.826a.5.5 0 00-.483-.376z\"/><path d=\"M1.837 22.013a.994.994 0 100 1.987h3.477a.994.994 0 000-1.987h-.5a.248.248 0 01-.248-.248v-1.987a.249.249 0 01.248-.249h.248a.993.993 0 100-1.986H2.333a.249.249 0 01-.248-.249v-4.222a.994.994 0 00-1.987 0v4.471a1.989 1.989 0 001.987 1.986h.248a.25.25 0 01.249.249v1.987a.249.249 0 01-.249.248zM22.909 14.562h-7.444a.994.994 0 000 1.987h3.509a.248.248 0 01.248.248v6.21a.994.994 0 001.987 0V16.8a.248.248 0 01.248-.248h1.452a.994.994 0 000-1.987z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});