define("ember-svg-jar/inlined/road-sign-slippery-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-slippery-alternate</title><path d=\"M23.707 12.707a1 1 0 000-1.414l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0zm-6.691 3.863a.251.251 0 01-.2.072.248.248 0 01-.183-.113l-.009-.012a1.748 1.748 0 00-1.447-.767.25.25 0 010-.5.75.75 0 100-1.5 1.75 1.75 0 000 3.5.25.25 0 01.207.11.662.662 0 00.21.2.25.25 0 01.046.391l-3.459 3.459a.25.25 0 01-.354 0l-9.236-9.233a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/><path d=\"M8.629 7.171v.012l-.3 1.371a.248.248 0 01-.067.124l-.5.5A1.792 1.792 0 007.25 10.5a1.746 1.746 0 00.875 1.509.25.25 0 01.125.216V13a.75.75 0 001.5 0v-.5a.25.25 0 01.25-.25h4a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.778a.251.251 0 01.123-.216 1.817 1.817 0 00.877-1.591 1.741 1.741 0 00-.512-1.237l-.5-.5a.259.259 0 01-.068-.123l-.29-1.335v-.012a1.744 1.744 0 00-1.727-1.458h-3.306a1.733 1.733 0 00-1.718 1.421zm5.277.32V7.5l.1.447a.25.25 0 01-.244.3h-3.523a.25.25 0 01-.244-.3l.1-.483v-.009a.25.25 0 01.246-.205h3.306a.263.263 0 01.259.241z\"/><path d=\"M9.173 15.75a.25.25 0 010-.5.75.75 0 100-1.5 1.75 1.75 0 000 3.5.251.251 0 01.215.122.75.75 0 001.284-.775 1.761 1.761 0 00-1.499-.847z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});