define("ember-svg-jar/inlined/paginate-filter-android", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paginate-filter-android</title><path d=\"M22 0H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2V2a2 2 0 00-2-2zm-2.5 15a1.5 1.5 0 01-1.5 1.5h-8A1.5 1.5 0 018.5 15v-3a.5.5 0 01.5-.5h10a.5.5 0 01.5.5zm-.5-5H9a.5.5 0 01-.5-.5 5.477 5.477 0 011.549-3.82l-1.1-1.17a.75.75 0 011.094-1.026l1.188 1.267a5.45 5.45 0 015.53 0l1.188-1.267a.751.751 0 011.094 1.028l-1.1 1.168A5.476 5.476 0 0119.5 9.5a.5.5 0 01-.5.5z\"/><path d=\"M19.5 22h-17a.5.5 0 01-.5-.5v-17a1 1 0 00-2 0V22a2 2 0 002 2h17.5a1 1 0 000-2z\"/><circle cx=\"12.25\" cy=\"7.497\" r=\".75\"/><circle cx=\"15.75\" cy=\"7.497\" r=\".75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});