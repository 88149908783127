define("ember-svg-jar/inlined/fireworks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fireworks</title><circle cx=\"16.5\" cy=\"3.5\" r=\"1\"/><circle cx=\"2.5\" cy=\"13.5\" r=\"1\"/><circle cx=\"20\" cy=\"12\" r=\"1\"/><path d=\"M4.25 3h.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.5A.25.25 0 016.75 3h.5a.75.75 0 000-1.5h-.5a.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25h-.5a.75.75 0 000 1.5zM2.318 5.82a.984.984 0 00-1.091.9 1 1 0 00.9 1.09 12.424 12.424 0 016.133 2.382.5.5 0 00.705-.114 3.755 3.755 0 01.593-.659.5.5 0 00-.03-.777 14.449 14.449 0 00-7.21-2.822zM11.566 8.525A3.877 3.877 0 0112 8.5a3.691 3.691 0 01.448.027.5.5 0 00.56-.5V2.5a1 1 0 00-2 0v5.528a.5.5 0 00.558.5zM8.392 13.077a.5.5 0 00-.75-.308A12.288 12.288 0 003.225 18.6a1 1 0 001.888.658 10.63 10.63 0 013.431-4.68.5.5 0 00.167-.647 3.62 3.62 0 01-.319-.854zM16.358 12.769a.5.5 0 00-.75.308 3.685 3.685 0 01-.319.849.5.5 0 00.166.648 10.636 10.636 0 013.432 4.68 1 1 0 001.888-.658 12.288 12.288 0 00-4.417-5.827zM21.682 5.82a14.443 14.443 0 00-7.21 2.822.5.5 0 00-.031.777 3.716 3.716 0 01.593.659.5.5 0 00.706.114 12.421 12.421 0 016.132-2.381 1 1 0 00.9-1.09.989.989 0 00-1.09-.901zM12 10a2.21 2.21 0 00-1.217 4.056.5.5 0 01.225.417V23a1 1 0 002 0v-8.537a.5.5 0 01.221-.416A2.21 2.21 0 0012 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});