define("ember-svg-jar/inlined/browser-idea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>browser-idea</title><path d=\"M16 22.125h-2a.75.75 0 000 1.5h2a.75.75 0 000-1.5zM15.75 11.375v-1.5a.75.75 0 00-1.5 0v1.5a.75.75 0 001.5 0zM9.5 16.125H8a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5zM22 16.125h-1.5a.75.75 0 000 1.5H22a.75.75 0 000-1.5zM9.757 11.571a.75.75 0 10-1.057 1.06l1.27 1.274a.75.75 0 001.06-1.061zM21.3 12.631a.75.75 0 10-1.061-1.06l-1.269 1.273a.75.75 0 101.06 1.061zM15 13.375a3.431 3.431 0 00-3.5 3.5 3.22 3.22 0 001.384 2.943.251.251 0 01.116.211v.346a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-.36a.251.251 0 01.116-.211 3.2 3.2 0 001.384-2.929 3.432 3.432 0 00-3.5-3.5z\"/><path d=\"M20 .375H4a3 3 0 00-3 3v11.5a3 3 0 003 3 1 1 0 000-2 1 1 0 01-1-1v-9.25a.249.249 0 01.25-.25h17.5a.249.249 0 01.25.25v3.25a1 1 0 002 0v-5.5a3 3 0 00-3-3zM6.5 3.125a.75.75 0 11.75.75.749.749 0 01-.75-.75zm2.5 0a.75.75 0 11.75.75.749.749 0 01-.75-.75zm-3.5 0a.75.75 0 11-.75-.75.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});