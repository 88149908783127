define("ember-svg-jar/inlined/virtual-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>virtual-house</title><path d=\"M21 20.5a2.5 2.5 0 00-2.5-2.5h-1.917a.249.249 0 01-.241-.184 4.5 4.5 0 00-8.713.113.093.093 0 01-.091.071H7.5A2.5 2.5 0 005 20.5v2A1.5 1.5 0 006.5 24h13a1.5 1.5 0 001.5-1.5zm-9-4A2.5 2.5 0 119.5 19a2.5 2.5 0 012.5-2.5zM16.793 13.793a1 1 0 001.414 1.414l1.061-1.061a1 1 0 00-1.414-1.414zM22.707 9.293a1 1 0 00-1.414 0l-1.061 1.06a1 1 0 001.414 1.414l1.061-1.06a1 1 0 000-1.414zM7.207 15.207a1 1 0 000-1.414l-1.061-1.061a1 1 0 00-1.414 1.414l1.061 1.061a1 1 0 001.414 0zM2.707 9.293a1 1 0 00-1.414 1.414l1.061 1.06a1 1 0 001.414-1.414zM6 9a1.5 1.5 0 001.5 1.5h9A1.5 1.5 0 0018 9V4a.5.5 0 00-.231-.422l-5.5-3.5a.5.5 0 00-.538 0l-5.5 3.5A.5.5 0 006 4zm4.748-5.434l3.5 2a.5.5 0 010 .868l-3.5 2A.5.5 0 0110 8V4a.5.5 0 01.748-.434z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});