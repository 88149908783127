define("ember-svg-jar/inlined/smiley-decode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-decode</title><path d=\"M1.5 14h21a2.982 2.982 0 011.361.328 12 12 0 10-23.722 0A2.982 2.982 0 011.5 14zm6-1.5A1.5 1.5 0 119 11a1.5 1.5 0 01-1.5 1.5zm1.9-5.053a1 1 0 01-1.347.453l-3-1.5a1 1 0 01.894-1.79l3 1.5A1 1 0 019.4 7.447zm7.1 5.053A1.5 1.5 0 1118 11a1.5 1.5 0 01-1.5 1.5zm2.447-6.1l-3 1.5a1 1 0 11-.894-1.79l3-1.5a1 1 0 01.894 1.79zM3.25 20a.25.25 0 00.5 0v-1a.25.25 0 00-.5 0zM16.75 19a.25.25 0 00-.5 0c0 .276.5.276.5 0z\"/><path d=\"M24 17a1.5 1.5 0 00-1.5-1.5h-21A1.5 1.5 0 000 17v5a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 22zm-1.876 3.584a.75.75 0 11-1.248.832l-.168-.252a.25.25 0 00-.416 0l-.168.252a.75.75 0 01-1.248-.832l.63-.945a.251.251 0 000-.278l-.63-.945a.75.75 0 011.248-.832l.168.252a.25.25 0 00.416 0l.168-.252a.75.75 0 111.248.832l-.63.945a.251.251 0 000 .278zM14.75 19a1.75 1.75 0 112.625 1.515.25.25 0 00-.125.216V21a.75.75 0 01-1.5 0v-.269a.251.251 0 00-.125-.216A1.749 1.749 0 0114.75 19zm-.915 2.671a.741.741 0 01-.334.079.752.752 0 01-.672-.415l-.605-1.211a.251.251 0 00-.448 0l-.605 1.211a.75.75 0 01-1.342-.67l1.5-3a.781.781 0 011.342 0l1.5 3a.75.75 0 01-.336 1.006zM8.5 20.25a.75.75 0 010 1.5h-2a.75.75 0 010-1.5.25.25 0 00.25-.25v-2a.75.75 0 011.5 0v2a.25.25 0 00.25.25zM5.25 18v3a.75.75 0 01-.75.75h-2a.75.75 0 01-.75-.75v-3a.75.75 0 01.75-.75h2a.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});