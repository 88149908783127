define("ember-svg-jar/inlined/clothes-design-needle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>clothes-design-needle</title><path d=\"M21.951 12.809a4.039 4.039 0 00.249-5.692l-1.877-1.788a.99.99 0 00-.688-.276 1 1 0 00-.689 1.725l1.822 1.729a2 2 0 01-.155 2.816l-.78.7a3.981 3.981 0 00-.524 5.373l1.376 1.835a1 1 0 00.8.4 1 1 0 00.8-1.6L20.909 16.2a1.99 1.99 0 01.263-2.687z\"/><path d=\"M19.587 8.995a.5.5 0 00-.156-.376c-.711-.676-1.006-.954-1.151-1.095a.5.5 0 00-.488-.124.811.811 0 01-.225.033 1 1 0 01-.707-1.707l2.85-2.849a1 1 0 111.414 1.414.295.295 0 00.005.423l1.37 1.3a.5.5 0 00.744-.06A3.689 3.689 0 0024 3.894a3.732 3.732 0 00-6.588-2.564l-.4.465a.252.252 0 01-.361.021 3.416 3.416 0 00-.4-.322 4.115 4.115 0 00-5.154.42L6.713 6.3a.984.984 0 01-.752.292 1 1 0 01-.723-.359L4.121 4.9a1 1 0 10-1.536 1.28L3.7 7.517a3.028 3.028 0 004.425.2l4.389-4.389a2.059 2.059 0 012.332-.355 1.729 1.729 0 01.525.388.249.249 0 01.018.343L.364 21.362a1.616 1.616 0 002.273 2.274l.008-.007L19.411 9.362a.5.5 0 00.176-.367z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});