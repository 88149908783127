define("ember-svg-jar/inlined/monitor-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-disable</title><path d=\"M11.9 12.05a.249.249 0 00-.178-.424h-9.1a.5.5 0 01-.5-.5v-8.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v6.849a.249.249 0 00.289.247 7.8 7.8 0 011.211-.1c.079 0 .157 0 .235.008a.251.251 0 00.265-.25V2.126a2 2 0 00-2-2h-14a2 2 0 00-2 2v10.5a2 2 0 002 2h5.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-2.5a1 1 0 000 2h4.261a.251.251 0 00.25-.265c0-.078-.008-.156-.008-.235A7.961 7.961 0 0111.9 12.05z\"/><path d=\"M17.625 11.376a6.25 6.25 0 106.25 6.25 6.257 6.257 0 00-6.25-6.25zm-3.6 8.289a.248.248 0 01-.184-.133 4.16 4.16 0 01-.467-1.906 4.255 4.255 0 014.25-4.25 4.167 4.167 0 011.907.467.251.251 0 01.063.4L14.242 19.6a.248.248 0 01-.216.065zm3.6 2.211a4.16 4.16 0 01-1.906-.467.251.251 0 01-.063-.4l5.353-5.352a.25.25 0 01.4.063 4.16 4.16 0 01.467 1.906 4.255 4.255 0 01-4.251 4.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});