define("ember-svg-jar/inlined/apk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>apk</title><path d=\"M7 9.626a.876.876 0 00-.875.875v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V10.5A.875.875 0 007 9.626z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM9.125 15a.625.625 0 01-1.25 0v-2.124a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0v-4.374a2.213 2.213 0 011.9-2.239 2.128 2.128 0 012.35 2.113zm2.75-2.375h-.5a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0V9a.625.625 0 01.625-.625H12a2.128 2.128 0 012.114 2.347 2.213 2.213 0 01-2.239 1.904zm4.686-.448l2.382 2.381a.625.625 0 01-.885.884s-1.662-1.657-1.74-1.74-.193-.053-.193.041V15a.625.625 0 01-1.25 0V9a.625.625 0 111.25 0v1.27c0 .164.153.069.153.069l1.78-1.78a.625.625 0 11.885.884l-2.382 2.381a.25.25 0 000 .354z\"/><path d=\"M12 9.626h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H12a.875.875 0 000-1.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});