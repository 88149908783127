define("ember-svg-jar/inlined/baby-hold-hands", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baby-hold-hands</title><path d=\"M13.229 6.854a.752.752 0 00-1.027.263.22.22 0 01-.4 0 .75.75 0 10-1.29.766 1.717 1.717 0 002.984 0 .752.752 0 00-.267-1.029z\"/><circle cx=\"10.25\" cy=\"4.75\" r=\"1\"/><circle cx=\"13.75\" cy=\"4.75\" r=\"1\"/><path d=\"M4.124 15.836a1.4 1.4 0 00-.458 1.823L5 19.876a.5.5 0 01-.857.516l-1.352-2.25a2.472 2.472 0 01.125-2.485.5.5 0 00.084-.276V12.5a1.5 1.5 0 00-3 0v5.171a4.5 4.5 0 00.986 2.812l2.664 3.329a.5.5 0 00.39.188H8a.5.5 0 00.5-.5v-3.01a4.492 4.492 0 00-.757-2.5c-1.887-1.965-2.477-2.916-3.619-2.154zM22.5 11a1.5 1.5 0 00-1.5 1.5v2.881a.5.5 0 00.084.276 2.472 2.472 0 01.125 2.485l-1.352 2.25a.5.5 0 01-.857-.516l1.334-2.217a1.4 1.4 0 00-.458-1.823c-1.142-.762-1.732.189-3.619 2.157a4.492 4.492 0 00-.757 2.5V23.5a.5.5 0 00.5.5h3.96a.5.5 0 00.39-.188l2.664-3.329A4.5 4.5 0 0024 17.671V12.5a1.5 1.5 0 00-1.5-1.5zM17.578 8.45A9.835 9.835 0 0018 6 6 6 0 006 6a9.835 9.835 0 00.422 2.45 26.758 26.758 0 01.828 5.05 4.75 4.75 0 009.5 0 26.758 26.758 0 01.828-5.05zm-1.72-1.407a4 4 0 01-7.716 0A3.908 3.908 0 018 6a4 4 0 018 0 3.908 3.908 0 01-.142 1.043z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});