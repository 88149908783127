define("ember-svg-jar/inlined/trip-multiple-destination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trip-multiple-destination</title><path d=\"M16.346 17.165l-9.267 4.974a.989.989 0 10.935 1.743l9.268-4.973a.99.99 0 10-.936-1.744zM11.956 11.838a.989.989 0 00.093 1.4l2.487 2.177a.99.99 0 001.3-1.49l-2.487-2.176a.99.99 0 00-1.393.089zM19.918 6.433a3.963 3.963 0 00-3.959 3.959c0 2.648 3.461 6.14 3.609 6.288a.494.494 0 00.7 0c.148-.148 3.609-3.64 3.609-6.288a3.963 3.963 0 00-3.959-3.959zm0 5.443a1.485 1.485 0 111.482-1.484 1.484 1.484 0 01-1.482 1.484zM11.01 10.392a.5.5 0 00.35-.145c.148-.148 3.609-3.64 3.609-6.288a3.959 3.959 0 10-7.917 0c0 2.648 3.461 6.14 3.608 6.288a.5.5 0 00.35.145zM9.526 3.959a1.485 1.485 0 111.484 1.484 1.484 1.484 0 01-1.484-1.484zM9.031 14.351a4.454 4.454 0 10-8.907 0c0 1.892 1.054 3.342 2.074 4.744.969 1.333 1.884 2.592 1.884 4.163a.5.5 0 10.99 0c0-1.571.915-2.83 1.885-4.163 1.019-1.402 2.074-2.852 2.074-4.744zm-4.454 1.484a1.98 1.98 0 111.98-1.979 1.982 1.982 0 01-1.98 1.979z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});