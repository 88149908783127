define("ember-svg-jar/inlined/boxing-bag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>boxing-bag</title><path d=\"M7.5 16.5A1.5 1.5 0 009 18h1.5a.5.5 0 01.5.5v2a.5.5 0 01-.5.5H9a1.5 1.5 0 00-1.5 1.5v.5a1 1 0 001 1h7a1 1 0 001-1v-.5A1.5 1.5 0 0015 21h-1.5a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5H15a1.5 1.5 0 001.5-1.5v-.75a.5.5 0 00-.5-.5H8a.5.5 0 00-.5.5zM16.5 1.5A1.5 1.5 0 0015 0H9a1.5 1.5 0 00-1.5 1.5v.75a.5.5 0 00.5.5h8a.5.5 0 00.5-.5z\"/><rect x=\"7.5\" y=\"4.25\" width=\"9\" height=\"9.5\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});