define("ember-svg-jar/inlined/tags-minus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-minus</title><path d=\"M18.161 9.369l.938-.957A3.988 3.988 0 0020.085 6V2a2 2 0 00-2-2h-4.26a3.755 3.755 0 00-2.412 1L.585 11.833a2 2 0 000 2.829L5.421 19.5a1.977 1.977 0 001.406.581h.012a1.973 1.973 0 001.41-.6l1.216-1.241a.482.482 0 00.136-.366 7.977 7.977 0 018.2-8.364.486.486 0 00.36-.141zM12.585 5.5a2 2 0 112 2 2 2 0 01-2-2z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.5 7.25h-5a.75.75 0 110-1.5h5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});