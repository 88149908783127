define("ember-svg-jar/inlined/3d-pen-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>3d-pen-box</title><path d=\"M14.5 11.663a.5.5 0 00-.737-.44l-6.157 3.315a.251.251 0 01-.231 0l-4.553-2.273a.25.25 0 01-.007-.444l5.159-2.777a1 1 0 10-.948-1.761l-6.5 3.5a1 1 0 00-.526.894v7.736a.5.5 0 00.264.441l7 3.75a.5.5 0 00.236.059.505.505 0 00.25-.066l6.5-3.75a.5.5 0 00.25-.434z\"/><path d=\"M23.2 11.183a.249.249 0 01-.2-.245V3.163a2 2 0 00-2-2h-.5a.248.248 0 01-.193-.091l-.064-.074a2 2 0 00-2.831-.146l-5.565 5.027a1 1 0 00-.071 1.414l.028.031a.25.25 0 01-.02.355l-2.8 2.477a.751.751 0 001 1.124l2.846-2.516a.21.21 0 01.3.016 1 1 0 001.414.073L20.1 3.826a2.009 2.009 0 00.418-.531.25.25 0 01.22-.132h.014a.25.25 0 01.25.25v7.75a2 2 0 002 2 1 1 0 00.2-1.98z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});