define("ember-svg-jar/inlined/disability-wheelchair-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-wheelchair-1</title><path d=\"M14.008 17.181a1.255 1.255 0 00-1.516.911 4.5 4.5 0 11-7.444-4.374 1.251 1.251 0 00-1.711-1.825 7 7 0 1011.581 6.8 1.251 1.251 0 00-.91-1.512z\"/><path d=\"M21.42 18.516l-1.461.244a.5.5 0 01-.563-.355l-1.43-5a1.248 1.248 0 00-1.406-.89c-.2.033-4.309.71-6.692 1.149a.5.5 0 01-.588-.439l-.153-1.444a.5.5 0 01.412-.545l4.549-.787a1.249 1.249 0 10-.426-2.462l-4.322.748a.5.5 0 01-.583-.441L8.55 6.342a.5.5 0 01.208-.46 3.247 3.247 0 10-3.007.4.5.5 0 01.324.417l.81 7.65a2.251 2.251 0 002.665 1.92c1.357-.266 4.134-.737 5.878-1.03a.5.5 0 01.564.355l1.431 5a1.251 1.251 0 001.2.906c.175 0 3.033-.488 3.206-.517a1.25 1.25 0 00-.41-2.467z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});