define("ember-svg-jar/inlined/auto-pilot-car-wheel-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>auto-pilot-car-wheel-disable</title><path d=\"M22.092 5.518a.25.25 0 00-.387-.041L10 17.179a.25.25 0 00-.071.214 5.761 5.761 0 01.071.857 5.908 5.908 0 01-.119 1.173.254.254 0 01-.119.166.251.251 0 01-.2.023 7.875 7.875 0 01-1.237-.512.251.251 0 00-.292.045l-2.557 2.56a.251.251 0 00.042.387A11.992 11.992 0 0022.092 5.518zm-2.118 7.005a8 8 0 01-5.533 7.088.25.25 0 01-.2-.022.254.254 0 01-.119-.166A5.908 5.908 0 0114 18.25a6.008 6.008 0 015.713-5.993.251.251 0 01.19.075.247.247 0 01.071.191zM23.535 1.525A1 1 0 0021.917.418l-2.151 2.151a.248.248 0 01-.331.019A11.993 11.993 0 002.588 19.435a.251.251 0 01-.018.332l-1.9 1.9a1 1 0 001.415 1.414L23.332 1.832a.989.989 0 00.203-.307zM8.1 14.233l-2.31 2.313a.248.248 0 01-.2.072.25.25 0 01-.183-.107 7.938 7.938 0 01-1.387-4.093.152.152 0 01.044-.118.159.159 0 01.116-.047 5.976 5.976 0 013.92 1.621.25.25 0 01.006.359zm4.291-4.291l-1.952 1.952a.251.251 0 01-.425-.142A1.9 1.9 0 0110 11.5a2 2 0 012-2 1.889 1.889 0 01.251.017.25.25 0 01.143.425zm4.155-4.152L15 7.34a.252.252 0 01-.2.072 28.35 28.35 0 00-9.225.6.251.251 0 01-.275-.38 7.97 7.97 0 0111.21-2.225.248.248 0 01.108.183.252.252 0 01-.072.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});