define("ember-svg-jar/inlined/blood-drop-type-b", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>blood-drop-type-b</title><path d=\"M15.407 14.212A7.971 7.971 0 0110.3 3.028a.25.25 0 000-.228A24.284 24.284 0 008.819.393a1.093 1.093 0 00-1.638 0C5.705 2.328 0 13.3 0 16.5 0 20.425 3.813 24 8 24s8-3.575 8-7.5a8.115 8.115 0 00-.419-2.119.253.253 0 00-.174-.169zm-7.676 6.843a.749.749 0 01-.9.565A5.233 5.233 0 013.3 18.834a.75.75 0 111.343-.668 3.736 3.736 0 002.528 1.991.75.75 0 01.56.898zM17.8 7.25h-1.1a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h1.1a.75.75 0 000-1.5zM17.8 4.25h-1.1a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h1.1a.75.75 0 000-1.5z\"/><path d=\"M17.5 0A6.5 6.5 0 1024 6.5 6.5 6.5 0 0017.5 0zm2.55 8a2.253 2.253 0 01-2.25 2.25h-2.1a.75.75 0 01-.75-.75v-6a.75.75 0 01.75-.75h2.1a2.249 2.249 0 011.676 3.75A2.239 2.239 0 0120.05 8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});