define("ember-svg-jar/inlined/face-id-9", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>face-id-9</title><path d=\"M17.7 17.232a6.129 6.129 0 00-3.83-3.676.25.25 0 01-.028-.465 4.253 4.253 0 10-3.619-.025.251.251 0 01-.033.465 6.123 6.123 0 00-3.892 3.7A.75.75 0 007 18.25h10a.751.751 0 00.7-1.018zM9.807 9.25a2.145 2.145 0 01.126-.72.249.249 0 01.151-.153.252.252 0 01.216.023 5.648 5.648 0 002.944.851 5.382 5.382 0 00.765-.056.249.249 0 01.285.266 2.247 2.247 0 01-4.485-.21zM18.364 18.364a1 1 0 011.414 0l.707.707a1 1 0 01-1.414 1.414l-.707-.707a1 1 0 010-1.414zM12 21.5a1 1 0 011 1v.5a1 1 0 01-2 0v-.5a1 1 0 011-1zM17.3 6.7a1 1 0 010-1.415l1.768-1.767a1 1 0 011.414 1.414L18.718 6.7a1 1 0 01-1.418 0zM12 3.5a1 1 0 01-1-1V1a1 1 0 012 0v1.5a1 1 0 01-1 1zM20.5 12a1 1 0 011-1H23a1 1 0 010 2h-1.5a1 1 0 01-1-1zM5.636 5.636a1 1 0 01-1.414 0l-.707-.707a1 1 0 011.414-1.414l.707.707a1 1 0 010 1.414zM5.282 18.717a1 1 0 010 1.415l-.353.353a1 1 0 01-1.414-1.414l.353-.354a1 1 0 011.414 0zM2.5 12a1 1 0 01-1 1H1a1 1 0 010-2h.5a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});