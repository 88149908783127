define("ember-svg-jar/inlined/medical-specialty-nose", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-specialty-nose</title><path d=\"M21.581 15.228a8.849 8.849 0 01-2.6-3 27.434 27.434 0 01-3-9.909l-.2-1.873a.5.5 0 00-.5-.447H8.714a.5.5 0 00-.5.447L8.02 2.32a27.531 27.531 0 01-3.026 9.964 8.793 8.793 0 01-2.575 2.944C1.175 16.245 0 17.207 0 19.667a5.373 5.373 0 001.877 3.907 1.01 1.01 0 00.821.161.984.984 0 00.647-.507C3.77 22.4 4.68 21 6 21a4.855 4.855 0 012.922 1.316l.4.322A4.72 4.72 0 0012 24a4.72 4.72 0 002.678-1.362l.4-.322A4.855 4.855 0 0118 21c1.32 0 2.23 1.4 2.655 2.228a.984.984 0 00.647.507 1.016 1.016 0 00.82-.16A5.374 5.374 0 0024 19.667c0-2.46-1.175-3.422-2.419-4.439z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});