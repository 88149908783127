define("ember-svg-jar/inlined/design-tool-stamp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-tool-stamp</title><path d=\"M22.5 21h-21a.5.5 0 00-.5.5v1A1.5 1.5 0 002.5 24h19a1.5 1.5 0 001.5-1.5v-1a.5.5 0 00-.5-.5zM21.5 14c-3.72 0-5.955-.579-6.644-1.723-.7-1.16.243-2.939 1.073-4.508A7.035 7.035 0 0017 4.967 4.827 4.827 0 0012 0a4.814 4.814 0 00-5 4.967A7.064 7.064 0 008.083 7.8c.829 1.562 1.768 3.334 1.074 4.485S6.231 14 2.5 14C.944 14 0 16.778 0 18a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18c0-1.222-.944-4-2.5-4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});