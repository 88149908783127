define("ember-svg-jar/inlined/style-two-pin-minus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-two-pin-minus</title><path d=\"M12 0a9.511 9.511 0 00-9.5 9.5c0 6.547 6.538 12.488 8.541 14.153a1.5 1.5 0 001.917 0c2-1.665 8.542-7.608 8.542-14.154A9.511 9.511 0 0012 0zm4.75 11.083h-9.5a1 1 0 110-2h9.5a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});