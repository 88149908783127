define("ember-svg-jar/inlined/style-three-pin-call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-call</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm3.838 14.214a1.412 1.412 0 010 1.993l-.676.674a1.99 1.99 0 01-2.5.251 27.133 27.133 0 01-7.6-7.6 2 2 0 01.251-2.5l.674-.674a1.411 1.411 0 011.993 0l1.225 1.224a1.412 1.412 0 010 1.993.383.383 0 00-.019.52 27.38 27.38 0 002.91 2.91.383.383 0 00.52-.02 1.444 1.444 0 011.992 0zM11 7.5a.5.5 0 01.5-.5 3 3 0 013 3 .5.5 0 01-1 0 2 2 0 00-2-2 .5.5 0 01-.5-.5zm6 3a.5.5 0 01-.5-.5 5.006 5.006 0 00-5-5 .5.5 0 010-1 6.006 6.006 0 016 6 .5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});