define("ember-svg-jar/inlined/people-man-12", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-12</title><path d=\"M4.752 18.514a.248.248 0 00.165-.18.245.245 0 00-.066-.234l-1.5-1.5a.5.5 0 00-.854.353v2.081a.25.25 0 00.356.226 16.528 16.528 0 011.899-.746zM21.144 19.262a.25.25 0 00.356-.226v-2.081a.5.5 0 00-.854-.353l-1.5 1.5a.25.25 0 00.1.415 16.418 16.418 0 011.898.745zM22.761 21.369a15.019 15.019 0 00-6.361-2.537.246.246 0 00-.2.047l-3.9 2.974a.5.5 0 01-.606 0l-3.9-2.974a.246.246 0 00-.2-.047 15.03 15.03 0 00-6.359 2.537 2.449 2.449 0 00-.988 1.98v.106a.5.5 0 00.5.5h22.5a.5.5 0 00.5-.5v-.106a2.447 2.447 0 00-.986-1.98zM14.321 17.552A7.007 7.007 0 0019 10.955v-.172a.249.249 0 01.185-.241l3.944-1.057a.5.5 0 000-.966L18.953 7.4a.251.251 0 01-.178-.18 6.957 6.957 0 00-.4-1.139.248.248 0 01.016-.235l2.537-4.081a.5.5 0 00-.688-.69L16.508 3.4a.249.249 0 01-.283-.013 6.984 6.984 0 00-1.751-.973.25.25 0 00-.33.3l.524 1.956a.249.249 0 00.1.143A4.99 4.99 0 0117 8.955v2a5 5 0 01-10 0v-2a4.99 4.99 0 012.232-4.15.244.244 0 00.1-.143l.523-1.954a.251.251 0 00-.33-.3 7.043 7.043 0 00-1.752.974.249.249 0 01-.283.013L3.762 1.077a.5.5 0 00-.689.689L5.61 5.847a.251.251 0 01.016.235 6.913 6.913 0 00-.4 1.138.251.251 0 01-.178.18L.871 8.519a.5.5 0 000 .966l3.944 1.057a.249.249 0 01.185.241v.172a6.983 6.983 0 009.321 6.6z\"/><circle cx=\"14\" cy=\"10.475\" r=\"1\"/><circle cx=\"10\" cy=\"10.475\" r=\"1\"/><path d=\"M13.077 12.587h-2a.639.639 0 00-.3 1.2 2.374 2.374 0 00.437.182 4.562 4.562 0 00.86.124h.026a2.977 2.977 0 00.85-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2zM10.16 5.439v.014a1.942 1.942 0 103.693.015v-.012L12.483.373a.519.519 0 00-.966 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});