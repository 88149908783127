define("ember-svg-jar/inlined/type-cursor-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>type-cursor-1</title><path d=\"M22.044 6.5H1.956A1.981 1.981 0 000 8.5v7a1.981 1.981 0 001.956 2h20.088a1.981 1.981 0 001.956-2v-7a1.981 1.981 0 00-1.956-2zm-20.05 8l-.032-5a1 1 0 011-1.006H21a1 1 0 011 1v5a1 1 0 01-1 1H2.994a1 1 0 01-1-.994z\"/><path d=\"M5.5 9.5a1 1 0 00-1 1v3a1 1 0 002 0v-3a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});