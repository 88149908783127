define("ember-svg-jar/inlined/messages-bubble-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-information</title><path d=\"M12 1.375C5.682 1.375.541 5.611.541 10.817A8.568 8.568 0 003.7 17.332L1.416 21.9a.5.5 0 00.658.677L8.2 19.726a13.665 13.665 0 003.8.531c6.318 0 11.459-4.235 11.459-9.44S18.318 1.375 12 1.375zm2.5 14.75h-4a.75.75 0 010-1.5h1.25v-5.25a.251.251 0 00-.25-.25h-1a.75.75 0 010-1.5h1a1.752 1.752 0 011.75 1.75v5.25h1.25a.75.75 0 010 1.5zm-2.375-11.75a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});