define("ember-svg-jar/inlined/arrow-up-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-up-1</title><path d=\"M12 4.5a2.3 2.3 0 011.729.78l9.811 11.15a1.847 1.847 0 11-2.773 2.439l-8.579-9.75a.25.25 0 00-.376 0l-8.579 9.752A1.847 1.847 0 11.46 16.432l9.808-11.148A2.31 2.31 0 0112 4.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});