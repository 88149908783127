define("ember-svg-jar/inlined/phone-actions-remove-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-remove-1</title><path d=\"M17.5 0A6.5 6.5 0 1024 6.5 6.508 6.508 0 0017.5 0zm2.651 8.091a.749.749 0 010 1.06.76.76 0 01-1.06 0l-1.414-1.414a.252.252 0 00-.354 0l-1.414 1.414a.758.758 0 01-1.06 0 .749.749 0 010-1.06l1.414-1.414a.252.252 0 000-.354l-1.414-1.414a.75.75 0 011.06-1.06l1.414 1.414a.252.252 0 00.354 0l1.414-1.414a.75.75 0 111.06 1.06l-1.414 1.414a.252.252 0 000 .354zM15.5 15.783a3.116 3.116 0 00-4.4 0l-.827.828a4.249 4.249 0 00-1.346 4.5 11.357 11.357 0 01-6.032-6.031 4.249 4.249 0 004.5-1.346l.83-.829a3.116 3.116 0 000-4.4l-3.59-3.582a2.232 2.232 0 00-3.649.739 13.313 13.313 0 0017.357 17.354 2.233 2.233 0 00.737-3.648z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});