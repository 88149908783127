define("ember-svg-jar/inlined/single-neutral-actions-information.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-information</title><path d=\"M12.631 11.158a.25.25 0 00-.064-.432c-.339-.128-.694-.26-1.069-.4l-.628-.23a.6.6 0 01-.138-.311 2.573 2.573 0 01.058-1.3 5.618 5.618 0 001.466-4.314C12.256 1.756 10.677 0 8.5 0S4.744 1.756 4.744 4.174A5.605 5.605 0 006.2 8.471a2.453 2.453 0 01.073 1.316.615.615 0 01-.135.309l-.631.232c-2.447.9-4.215 1.55-4.754 2.626A9.173 9.173 0 000 16.5a.5.5 0 00.5.5h8.794a.251.251 0 00.249-.227 8 8 0 013.088-5.615z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm.5 3.249a1 1 0 11-1-1 1 1 0 011 1zm-2 6h.5a.25.25 0 00.25-.25V18a.25.25 0 00-.25-.25H16a.75.75 0 110-1.5h1a1.252 1.252 0 011.25 1.25V20a.25.25 0 00.25.25h.5a.75.75 0 010 1.5h-3a.75.75 0 110-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});