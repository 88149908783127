define("ember-svg-jar/inlined/antique-mace-double", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>antique-mace-double</title><path d=\"M20.945 16.546a.5.5 0 01-.419-.447L19.217 2.258A1.239 1.239 0 0019.5 1.5a1.252 1.252 0 00-.366-.884 1.239 1.239 0 00-.925-.358.987.987 0 00-.9.417L.366 17.614a1.25 1.25 0 001.768 1.768L14.675 6.841a.25.25 0 01.414.256l-2.309 6.846a.5.5 0 01-.535.336 3.256 3.256 0 00-.733-.013 3.631 3.631 0 103.97 3.256 3.567 3.567 0 00-.866-1.993.5.5 0 01-.095-.487l2.667-7.911a.25.25 0 01.486.056l.894 9.455a.5.5 0 01-.219.463 3.641 3.641 0 102.6-.559z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});