define("ember-svg-jar/inlined/real-estate-insurance-dollar-hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-insurance-dollar-hand</title><rect y=\"14.029\" width=\"4.001\" height=\"9\" rx=\"1\" ry=\"1\"/><path d=\"M23.854 18.238a3.241 3.241 0 00-3.492-1.127h-.014l-2 .639a.5.5 0 00-.348.485 2.25 2.25 0 01-2.248 2.29H10.5a.5.5 0 010-1h5.25a1.25 1.25 0 100-2.5l-3.685.008a4.268 4.268 0 01-.573-.383A6.833 6.833 0 007.5 15.029h-2a.5.5 0 00-.5.5v5.9a.5.5 0 00.339.474c.705.239 1.341.46 1.917.66 2.623.911 4.087 1.409 5.418 1.409 1.623 0 3.048-.742 6.139-2.386 1.281-.681 2.875-1.529 4.91-2.546a.5.5 0 00.269-.368.5.5 0 00-.138-.434zM22.832 6.655L15.664.281a1 1 0 00-1.328 0L7.168 6.655a.5.5 0 00.332.874h2a.5.5 0 01.5.5v5a1 1 0 001 1h8a1 1 0 001-1v-5a.5.5 0 01.5-.5h2a.5.5 0 00.332-.874zm-6.72 4.532a.5.5 0 00-.362.48v.112a.75.75 0 01-1.5 0 .5.5 0 00-.5-.5h-.25a.75.75 0 010-1.5h2.033a.592.592 0 00.22-1.141l-2.062-.825a2.084 2.084 0 01.2-3.942.5.5 0 00.362-.481v-.111a.75.75 0 011.5 0 .5.5 0 00.5.5h.25a.75.75 0 010 1.5h-2.036a.592.592 0 00-.22 1.141l2.063.826a2.084 2.084 0 01-.2 3.941z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});