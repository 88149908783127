define("ember-svg-jar/inlined/diagram-arrow-dash-up-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-arrow-dash-up-1</title><path d=\"M13.074.469a1.505 1.505 0 00-2.147 0L4.767 7.1A1 1 0 005.5 8.781h3.25a.25.25 0 01.25.25v4.951a1 1 0 001 1h4a1 1 0 001-1V9.031a.25.25 0 01.25-.25h3.25a1 1 0 00.733-1.681z\"/><rect x=\"9\" y=\"16.482\" width=\"6\" height=\"3\" rx=\"1\" ry=\"1\"/><rect x=\"9\" y=\"20.982\" width=\"6\" height=\"3\" rx=\"1\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});