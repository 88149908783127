define("ember-svg-jar/inlined/server-question-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-question-1</title><path d=\"M9.5 17.5a7.939 7.939 0 011.543-4.707.5.5 0 00-.4-.793h-8a2.6 2.6 0 00-2.63 2.239A2.5 2.5 0 002.5 17H8a.25.25 0 01.25.25v2.5A.25.25 0 018 20H5a.75.75 0 000 1.5h4.768a.5.5 0 00.455-.7 8.032 8.032 0 01-.723-3.3zm-6.5-3a1.25 1.25 0 111.25 1.25A1.249 1.249 0 013 14.5zm5 1.25a1.25 1.25 0 111.25-1.25A1.25 1.25 0 018 15.75zM1 20a.75.75 0 000 1.5h1A.75.75 0 002 20zM2.5 5h13a2.5 2.5 0 000-5h-13a2.5 2.5 0 000 5zM3 2.5a1.25 1.25 0 111.25 1.25A1.249 1.249 0 013 2.5zm3.75 0A1.25 1.25 0 118 3.75 1.249 1.249 0 016.75 2.5zM17.917 9.131A2.467 2.467 0 0017.9 7.8 2.6 2.6 0 0015.376 6H2.647A2.6 2.6 0 00.013 8.239 2.5 2.5 0 002.5 11h10.205a.479.479 0 00.271-.086 7.832 7.832 0 014.451-1.4.509.509 0 00.49-.383zM9.25 8.5A1.25 1.25 0 118 7.25 1.25 1.25 0 019.25 8.5zm-3.75 0a1.25 1.25 0 11-1.25-1.25A1.25 1.25 0 015.5 8.5zM11 17.5a6.5 6.5 0 106.5-6.5 6.508 6.508 0 00-6.5 6.5zm6.5 3a1 1 0 11-1 1 1 1 0 011-1zm.913-2.164a.25.25 0 00-.163.234v.18a.75.75 0 01-1.5 0v-.55a1.258 1.258 0 01.986-1.222 1.125 1.125 0 10-1.361-1.1.75.75 0 01-1.5 0 2.625 2.625 0 113.538 2.461z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});