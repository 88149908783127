define("ember-svg-jar/inlined/house-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>house-4</title><path d=\"M2.643 10.59l9.049-7.483a.483.483 0 01.616 0l9.049 7.483a1.448 1.448 0 002.066-.222 1.523 1.523 0 00-.218-2.111L14.156.774a3.389 3.389 0 00-4.312 0L.8 8.257a1.523 1.523 0 00-.218 2.111 1.45 1.45 0 002.061.222z\"/><path d=\"M22.281 22v-7a2.225 2.225 0 00-.733-1.59l-8.3-7a1.931 1.931 0 00-2.494 0l-8.3 7.006A2.224 2.224 0 001.719 15v7a1.981 1.981 0 001.958 2h5.386a.99.99 0 00.979-1v-5.5a.991.991 0 01.979-1h1.958a.991.991 0 01.979 1V23a.99.99 0 00.979 1h5.386a1.981 1.981 0 001.958-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});