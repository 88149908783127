define("ember-svg-jar/inlined/laptop-idea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-idea</title><path d=\"M22.112 21.449l-2.031-1.625a.251.251 0 01-.09-.244.357.357 0 00.009-.08v-8a2 2 0 00-2-2h-1a1 1 0 000 2h.75a.249.249 0 01.25.25v6.5a.25.25 0 01-.25.25H6.25a.25.25 0 01-.25-.25v-6.5a.249.249 0 01.25-.25H7a1 1 0 000-2H6a2 2 0 00-2 2v8a.357.357 0 00.009.08.25.25 0 01-.09.244L1.887 21.45a.994.994 0 00-.369.891A1.776 1.776 0 003.5 24h17a1.776 1.776 0 001.982-1.66 1 1 0 00-.37-.891zm-7.362.051a.75.75 0 01-.75.75h-4a.75.75 0 010-1.5h4a.75.75 0 01.75.75zM9.4 7.5a.249.249 0 01.1.2v.55a.75.75 0 00.75.75h3.5a.75.75 0 00.75-.75V7.7a.249.249 0 01.1-.2 4.129 4.129 0 001.65-3.25A4.361 4.361 0 0012 0a4.361 4.361 0 00-4.25 4.25A4.129 4.129 0 009.4 7.5z\"/><path d=\"M11.917 12.666a.75.75 0 00.75-.75v-.166a.249.249 0 01.25-.25h.333a.75.75 0 000-1.5h-2.5a.75.75 0 000 1.5h.167a.249.249 0 01.25.25v.166a.75.75 0 00.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});