define("ember-svg-jar/inlined/aircraft-military-plane-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>aircraft-military-plane-2</title><path d=\"M21.25 6.631h-4.038a.25.25 0 00-.236.331 5.159 5.159 0 01.244 2.249l-.459 4.143a.249.249 0 00.248.277h4.241a2.5 2.5 0 002.5-2.5v-2a2.5 2.5 0 00-2.5-2.5zM7.177 13.548a.247.247 0 00.062-.194L6.78 9.212a5.158 5.158 0 01.244-2.249.25.25 0 00-.236-.331H2.75a2.5 2.5 0 00-2.5 2.5v2a2.5 2.5 0 002.5 2.5h4.241a.249.249 0 00.186-.084zM8.077 20.861l-.315-2.837a.252.252 0 00-.325-.211A3.485 3.485 0 005.07 20.43a1 1 0 00.98 1.2h1.864a.251.251 0 00.244-.309 3.638 3.638 0 01-.081-.46zM16.563 17.813a.252.252 0 00-.325.211l-.315 2.837a3.638 3.638 0 01-.081.459.251.251 0 00.244.309h1.864a1 1 0 00.98-1.2 3.486 3.486 0 00-2.367-2.616zM15.3 5.964a.253.253 0 00-.194-.093H8.9a.248.248 0 00-.194.093 4.21 4.21 0 00-.93 3.136l1.3 11.652a2.947 2.947 0 005.858 0L16.224 9.1a4.215 4.215 0 00-.924-3.136zm-1.285 3.5c-.385 3.444-.1 3-1.62 5.2a.529.529 0 01-.87 0c-1.425-2.074-1.266-1.777-1.538-5.2a2.025 2.025 0 114.028 0zM7 3.371h2.444a.221.221 0 01.168.079.223.223 0 01.047.179 10.62 10.62 0 00-.129.971.249.249 0 00.25.271h4.44a.252.252 0 00.25-.271 10.529 10.529 0 00-.122-.935.25.25 0 01.246-.294H17a1 1 0 000-2h-3.431a.25.25 0 01-.188-.085 1.78 1.78 0 00-2.762 0 .25.25 0 01-.188.085H7a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});