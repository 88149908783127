define("ember-svg-jar/inlined/expand-diagonal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>expand-diagonal</title><path d=\"M3.5 19.5a1 1 0 001 1h4a1 1 0 00.707-1.707l-.939-.94a.249.249 0 010-.353L17.5 8.267a.25.25 0 01.354 0l.939.939a1 1 0 00.707.294.987.987 0 00.383-.076A1 1 0 0020.5 8.5v-4a1 1 0 00-1-1h-4a1 1 0 00-.707 1.707l.939.939a.25.25 0 010 .354L6.5 15.731a.25.25 0 01-.354 0l-.939-.939A1 1 0 003.5 15.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});