define("ember-svg-jar/inlined/toys-teddy-bear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toys-teddy-bear</title><circle cx=\"12\" cy=\"7.5\" r=\"1\"/><path d=\"M23.75 9.973a2.249 2.249 0 00-3.042-.967l-3.843 2.077a.249.249 0 01-.317-.068.4.4 0 01.025-.51 6.229 6.229 0 001.676-4.1.5.5 0 01.384-.476 2.75 2.75 0 10-2.4-4.785.5.5 0 01-.611.023 6.225 6.225 0 00-7.249 0 .5.5 0 01-.611-.023 2.75 2.75 0 10-2.4 4.785.5.5 0 01.384.476 6.227 6.227 0 001.676 4.1.394.394 0 01.026.509.25.25 0 01-.318.068L3.279 9a2.249 2.249 0 00-3.029.973A2.249 2.249 0 001.211 13l4.317 2.323a.5.5 0 01.261.49l-.024.288a.5.5 0 01-.412.46 3.75 3.75 0 103.282 6.36.5.5 0 01.578-.09 6.111 6.111 0 005.576 0 .5.5 0 01.578.089 3.749 3.749 0 103.282-6.36.5.5 0 01-.412-.46 8.873 8.873 0 00-.025-.289.5.5 0 01.261-.491L22.775 13a2.248 2.248 0 00.975-3.029zM14.5 3.25a.75.75 0 11-.75.75.75.75 0 01.75-.75zm-5 0a.75.75 0 11-.75.75.75.75 0 01.75-.75zm2.5 2A3.25 3.25 0 118.75 8.5 3.25 3.25 0 0112 5.25zm2.259 15.257a.5.5 0 01-.259.473 4.1 4.1 0 01-4 0 .5.5 0 01-.257-.472 3.736 3.736 0 00-1.11-2.923.5.5 0 01-.07-.626A3.875 3.875 0 0112 15a3.887 3.887 0 013.439 1.952.5.5 0 01-.069.628 3.737 3.737 0 00-1.111 2.927z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});