define("ember-svg-jar/inlined/touch-password-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>touch-password-lock</title><path d=\"M18.329 14.779l-5.079-1.823V7.5a2 2 0 00-4 0v8.461l-.827-1.139a1.55 1.55 0 00-1.2-.557l-.991.019a1.475 1.475 0 00-1.3 2.179l3.738 6.773A1.462 1.462 0 009.952 24h6.387a1.478 1.478 0 001.379-.989l1.519-6.68a.456.456 0 00.013-.111 1.435 1.435 0 00-.921-1.441z\"/><path d=\"M6.25 11.546a1 1 0 00-1-1l-2.51-.038a.5.5 0 01-.493-.5L2.209 2.5a.5.5 0 01.5-.5H13.25a1 1 0 000-2H2.206A2.005 2.005 0 00.25 2.046V10.5a2.005 2.005 0 001.956 2.046H5.25a1 1 0 001-1zM22.75 4.9h-.25V3.5a2.75 2.75 0 00-5.5 0v1.4h-.25a1 1 0 00-1 1v4.5a1 1 0 001 1h6a1 1 0 001-1V5.9a1 1 0 00-1-1zm-3 3.75a1 1 0 111-1 1 1 0 01-1 1zM18.5 4.9V3.5a1.25 1.25 0 012.5 0v1.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});