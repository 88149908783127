define("ember-svg-jar/inlined/file-code-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-1-alternate</title><path d=\"M21.707 5.708L16.293.294A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.415a1 1 0 00-.293-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.465a.5.5 0 01.353.147l4.535 4.535a.5.5 0 01.147.354z\"/><path d=\"M8.952 11.137V9.965c0-.169.265-.214.423-.214h.6a1 1 0 100-2h-.6a2.275 2.275 0 00-2.423 2.214v1.027a.245.245 0 01-.107.2c-.354.239-1.462.965-1.462.965a1 1 0 000 1.678l1.455.952a.249.249 0 01.114.213v1.036a2.275 2.275 0 002.423 2.214h.6a1 1 0 100-2h-.6c-.158 0-.423-.045-.423-.214v-1.171a2.077 2.077 0 00-.881-1.673.243.243 0 010-.382 2.077 2.077 0 00.881-1.673zM18.564 12.131l-1.711-.943a.238.238 0 01-.1-.163v-1.06a2.366 2.366 0 00-2.627-2.214h-.686a1 1 0 100 2h.686c.439 0 .627.163.627.214v1.172a2.027 2.027 0 00.933 1.671.241.241 0 010 .392 2.022 2.022 0 00-.936 1.665v1.172c0 .052-.188.214-.627.214h-.686a1 1 0 000 2h.686a2.366 2.366 0 002.627-2.214l-.01-1.018c0-.064.022-.1.107-.155s1.709-.993 1.709-.993a1 1 0 000-1.74z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});