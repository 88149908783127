define("ember-svg-jar/inlined/mouse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mouse</title><path d=\"M23 1.317a1 1 0 00-1 1v5.675C22 10.07 20.8 11 19.6 11s-2.4-.931-2.4-3.009V5.628C17.206 1.463 13.622 0 11.218 0c-2.731 0-5.487 1.638-5.7 4.767A.25.25 0 015.27 5H3.5A3.5 3.5 0 000 8.5v9a6.5 6.5 0 0013 0v-9A3.5 3.5 0 009.5 5H7.79a.249.249 0 01-.19-.085.253.253 0 01-.06-.2C7.786 2.86 9.554 2 11.218 2c.39 0 3.988.142 3.988 3.627v2.365c0 3.288 2.212 5.009 4.4 5.009S24 11.28 24 7.992V2.317a1 1 0 00-1-1zm-15 9.8A1.449 1.449 0 016.5 12.5 1.449 1.449 0 015 11.112V8.889A1.449 1.449 0 016.5 7.5 1.449 1.449 0 018 8.889z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});