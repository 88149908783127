define("ember-svg-jar/inlined/sailing-boat-water-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sailing-boat-water-1</title><path d=\"M15.331 14.847a.5.5 0 00.5.471h3.657a1 1 0 00.946-1.324 16.414 16.414 0 00-5.976-7.825.25.25 0 00-.39.263 45.438 45.438 0 011.263 8.415z\"/><path d=\"M2.626 18.71a.5.5 0 00.7.182 2.58 2.58 0 011.351-.378 2.471 2.471 0 011.9.858c.551.652 1 1.114 1.757 1.114s1.216-.473 1.738-1.09a2.525 2.525 0 011.938-.882 2.47 2.47 0 011.9.858c.551.652 1 1.114 1.758 1.114s1.216-.473 1.738-1.091a2.637 2.637 0 013.838-.023 6.362 6.362 0 00.579.622.5.5 0 00.808-.21l.621-1.822a1 1 0 00-.894-1.448h-7.974a.5.5 0 01-.5-.482 39.314 39.314 0 00-3.4-15.3 1.25 1.25 0 00-2.35.263C7.058 5.849 5.819 9.461 2.979 12.461a1.251 1.251 0 00.9 2.11l7.954.018a.5.5 0 01.5.47q.027.462.045.936a.5.5 0 01-.5.519H3.1a1 1 0 00-.882.529.98.98 0 00.03.993z\"/><path d=\"M23 21.986a3.747 3.747 0 01-2.9-1.645 1.055 1.055 0 00-1.546.022 3.72 3.72 0 01-2.884 1.623 3.745 3.745 0 01-2.9-1.645 1.055 1.055 0 00-1.547.023 3.72 3.72 0 01-2.884 1.622 3.747 3.747 0 01-2.9-1.645 1.055 1.055 0 00-1.547.023A3.718 3.718 0 011 21.986a1 1 0 000 2 5.126 5.126 0 003.346-1.243.5.5 0 01.639 0 5.128 5.128 0 006.694 0 .5.5 0 01.639 0 5.129 5.129 0 006.7 0 .5.5 0 01.639 0A5.131 5.131 0 0023 23.986a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});