define("ember-svg-jar/inlined/earth-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earth-heart</title><path d=\"M13.163 21.9a.5.5 0 00-.4-.151 9.224 9.224 0 01-.765.033 9.821 9.821 0 01-4.456-1.071v-1.292a2.468 2.468 0 01.884-1.9 4.429 4.429 0 001.094-1.4 5.008 5.008 0 01.485-2.14 4.453 4.453 0 00-4.443-4.33h-3.2a9.884 9.884 0 0115.223-5.936h-3.357a2.723 2.723 0 100 5.446 2.577 2.577 0 012.2 1.245c0 .006.45.751.751 1.25a.5.5 0 00.676.171 4.954 4.954 0 015.523.307.314.314 0 00.5-.251 11.881 11.881 0 10-16.491 10.95l.157.077v-.02a11.824 11.824 0 004.456.875 11.476 11.476 0 001.74-.135.495.495 0 00.282-.832z\"/><path d=\"M23.512 14.544a3.489 3.489 0 00-5.59-.907l-.127.127a.5.5 0 01-.7 0l-.127-.127a3.491 3.491 0 00-4.944 4.929l5.064 5.282a.5.5 0 00.714 0l5.056-5.275a3.486 3.486 0 00.654-4.029z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});