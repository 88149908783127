define("ember-svg-jar/inlined/ipod-play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ipod-play</title><path d=\"M16 7H8a2 2 0 00-2 2v13a2 2 0 002 2h8a2 2 0 002-2V9a2 2 0 00-2-2zm-4 15.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm-4-13a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v8a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5z\"/><path d=\"M14.037 12.449l-3.116-1.819a.96.96 0 00-.94-.011.933.933 0 00-.481.812v3.64a.932.932 0 00.482.812.958.958 0 00.939-.012l3.115-1.819a.924.924 0 000-1.6zM7 4a1 1 0 00-1-1 3 3 0 00-3 3 1 1 0 002 0 1 1 0 011-1 1 1 0 001-1z\"/><path d=\"M6 1a1 1 0 00-1-1 5.006 5.006 0 00-5 5 1 1 0 002 0 3 3 0 013-3 1 1 0 001-1zM18 3a1 1 0 000 2 1 1 0 011 1 1 1 0 002 0 3 3 0 00-3-3z\"/><path d=\"M19 0a1 1 0 000 2 3 3 0 013 3 1 1 0 002 0 5.006 5.006 0 00-5-5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});