define("ember-svg-jar/inlined/real-estate-market-calculator-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-market-calculator-house</title><path d=\"M23.832 6.626l-2.248-2a.25.25 0 01-.084-.186V1a.5.5 0 00-.5-.5h-1.5a.5.5 0 00-.5.5v.774a.25.25 0 01-.416.186L16.664.252a1 1 0 00-1.328 0L8.168 6.626A.214.214 0 008.311 7H14.5a3.5 3.5 0 013.5 3.5v3.25a.25.25 0 00.25.25H20a1 1 0 001-1V7.75a.25.25 0 01.25-.25h2.25a.5.5 0 00.332-.874zM7.5 8h-5A2.5 2.5 0 000 10.5V15a.5.5 0 00.5.5h7A.5.5 0 008 15V8.5a.5.5 0 00-.5-.5zm-2 4.5H5a.25.25 0 00-.25.25v.5a.75.75 0 01-1.5 0v-.5A.25.25 0 003 12.5h-.5a.75.75 0 010-1.5H3a.25.25 0 00.25-.25v-.5a.75.75 0 011.5 0v.5A.25.25 0 005 11h.5a.75.75 0 010 1.5zM7.5 16.5h-7a.5.5 0 00-.5.5v4.5A2.5 2.5 0 002.5 24h5a.5.5 0 00.5-.5V17a.5.5 0 00-.5-.5zm-2.263 3.927l.354.353a.75.75 0 01-1.061 1.061l-.353-.354a.252.252 0 00-.354 0l-.354.354a.75.75 0 01-1.06-1.061l.353-.353a.25.25 0 000-.354l-.353-.353a.75.75 0 011.06-1.061l.354.354a.252.252 0 00.354 0l.353-.354a.75.75 0 011.061 1.061l-.354.353a.25.25 0 000 .354z\"/><path d=\"M14.5 8h-5a.5.5 0 00-.5.5V15a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-4.5A2.5 2.5 0 0014.5 8zm0 4.5h-3a.75.75 0 010-1.5h3a.75.75 0 010 1.5zM16.5 16.5h-7a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h5a2.5 2.5 0 002.5-2.5V17a.5.5 0 00-.5-.5zm-2 6h-3a.75.75 0 010-1.5h3a.75.75 0 010 1.5zm0-3h-3a.75.75 0 010-1.5h3a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});