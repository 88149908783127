define("ember-svg-jar/inlined/beacon-bluetooth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beacon-bluetooth</title><path d=\"M11.694 9.836a.25.25 0 00.427.176l.558-.558a.25.25 0 00-.02-.372l-.559-.447a.25.25 0 00-.406.2zM11.694 15.669a.25.25 0 00.406.2l.559-.447a.25.25 0 00.02-.372l-.558-.559a.25.25 0 00-.427.177z\"/><path d=\"M18.5 4.75h-13a1 1 0 00-1 1v12a2 2 0 002 2h11a2 2 0 002-2v-12a1 1 0 00-1-1zm-3.97 10.025a.75.75 0 01-.061 1.116l-3.056 2.444a.75.75 0 01-1.219-.585v-3.086a.25.25 0 00-.426-.177l-.738.738a.75.75 0 01-1.06-1.061l1.737-1.737a.25.25 0 000-.354L7.97 10.336a.75.75 0 011.06-1.061l.738.738a.25.25 0 00.426-.177V6.75a.75.75 0 011.219-.586l3.056 2.445a.75.75 0 01.061 1.116l-2.348 2.348a.25.25 0 000 .354zM17 8.25a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M23.924 4.281l-.293-.707a1 1 0 00-.217-.324L21 .836a1 1 0 00-.324-.217l-.707-.293a1 1 0 00-.383-.076H4.414a1 1 0 00-.383.076l-.707.293A1 1 0 003 .836L.586 3.25a1 1 0 00-.217.324l-.293.707A1.01 1.01 0 000 4.664v14.672a1.01 1.01 0 00.076.383l.293.707a1 1 0 00.217.324L3 23.164a1 1 0 00.324.217l.707.293a1 1 0 00.383.076h15.172a1 1 0 00.383-.076l.707-.293a1 1 0 00.324-.217l2.414-2.414a1 1 0 00.217-.324l.293-.707a1.01 1.01 0 00.076-.383V4.664a1.01 1.01 0 00-.076-.383zM22 19.137l-.141.34-2.132 2.132-.34.141H4.613l-.34-.141-2.132-2.132-.141-.34V4.863l.141-.34 2.132-2.132.34-.141h14.774l.34.141 2.132 2.132.141.34z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});