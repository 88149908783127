define("ember-svg-jar/inlined/smart-watch-square-dumbbell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-dumbbell</title><path d=\"M16.43 2.75a.251.251 0 00.238-.329L15.975.342A.5.5 0 0015.5 0h-7a.5.5 0 00-.475.342l-.693 2.079a.251.251 0 00.238.329zM7.57 21.25a.248.248 0 00-.2.1.251.251 0 00-.035.225l.693 2.079A.5.5 0 008.5 24h7a.5.5 0 00.475-.342l.693-2.079a.251.251 0 00-.035-.225.248.248 0 00-.2-.1zM17.5 4h-11A2.5 2.5 0 004 6.5v11A2.508 2.508 0 006.5 20h11a2.5 2.5 0 002.5-2.5v-11A2.5 2.5 0 0017.5 4zm.75 8a.75.75 0 01-.75.75h-.75a.25.25 0 00-.25.25v.5A1.5 1.5 0 0115 15h-1.5a.5.5 0 01-.5-.5V13a.25.25 0 00-.25-.25h-1.5A.25.25 0 0011 13v1.5a.5.5 0 01-.5.5H9a1.5 1.5 0 01-1.5-1.5V13a.25.25 0 00-.25-.25H6.5a.75.75 0 010-1.5h.75A.25.25 0 007.5 11v-.5A1.5 1.5 0 019 9h1.5a.5.5 0 01.5.5V11a.25.25 0 00.25.25h1.5A.25.25 0 0013 11V9.5a.5.5 0 01.5-.5H15a1.5 1.5 0 011.5 1.5v.5a.25.25 0 00.25.25h.75a.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});