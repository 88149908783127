define("ember-svg-jar/inlined/landmark-pisa-tower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-pisa-tower</title><path d=\"M6.88 11.728a.751.751 0 00.608.87.5.5 0 01.406.579l-.174.984a.5.5 0 00.406.579l8.863 1.56a.512.512 0 00.087.008.5.5 0 00.492-.413l.174-.985a.5.5 0 01.579-.405.732.732 0 00.131.011.75.75 0 00.13-1.489L7.749 11.12a.751.751 0 00-.869.608zM8.356 7.673a.5.5 0 01.406.58l-.173.984a.5.5 0 00.4.579l8.863 1.563a.427.427 0 00.087.008.5.5 0 00.492-.413l.174-.985a.5.5 0 01.579-.406.75.75 0 00.261-1.477L8.617 6.2a.75.75 0 10-.261 1.477zM11.194 3.1a.5.5 0 01.406.579l-.173.985a.5.5 0 00.405.579l4.924.868a.512.512 0 00.087.008.5.5 0 00.492-.413l.173-.985a.5.5 0 01.58-.406.734.734 0 00.131.012.75.75 0 00.129-1.489l-2.215-.391a.5.5 0 01-.406-.579L15.9.88a.75.75 0 00-1.477-.26l-.173.98a.5.5 0 01-.58.406l-2.215-.391a.75.75 0 00-.261 1.485zM24 23a1 1 0 00-1-1h-6.019a.406.406 0 01-.413-.492l.3-1.73a.5.5 0 01.579-.4.85.85 0 00.132.011.75.75 0 00.129-1.489L6.881 15.985a.75.75 0 10-.261 1.477.5.5 0 01.406.58l-.611 3.466a.62.62 0 01-.587.492H1a1 1 0 000 2h8.029a.62.62 0 00.587-.492l.509-2.889a1.5 1.5 0 012.955.521l-.417 2.368a.406.406 0 00.413.492H23a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});