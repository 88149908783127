define("ember-svg-jar/inlined/messaging-icq", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messaging-icq</title><path d=\"M23.07 11.09a3.59 3.59 0 00-2.15-1.51c1.25-1.39 1.42-2.48 1.27-3.21a2.5 2.5 0 00-1.9-1.87 4.08 4.08 0 00-2.62.18c-.11-2.22-1-3.32-1.82-3.86a4 4 0 00-4.16 0 3.58 3.58 0 00-2 3.18v.71A4.73 4.73 0 007.3 3.12a2.15 2.15 0 00-1.69.34A3.23 3.23 0 004.28 6 4.19 4.19 0 005 8.39c-3.18.17-4.65 2.2-4.65 4.12a2.55 2.55 0 001.1 2 4.57 4.57 0 001.61.73 2.9 2.9 0 00-.51 1.22 2.44 2.44 0 00.95 2.41 3 3 0 001.82.55 4.61 4.61 0 00.76-.07 3.26 3.26 0 00.27 2.51A4.19 4.19 0 0010 23.85 3.9 3.9 0 0013.81 21l.12-.4a2.91 2.91 0 002.16 1 3.13 3.13 0 002.61-1.48 3.49 3.49 0 00.49-2.38 3.56 3.56 0 001 .16 3.27 3.27 0 00.65-.06 4.14 4.14 0 002.66-2.11 5.18 5.18 0 00-.43-4.64zm-2.54 5.25c-1.34.27-2.07-.76-5.32-3.33a3.12 3.12 0 00.1-1.13c.93-.24 2.22-.55 4-.94a2.49 2.49 0 012.56 1c.82 1.3.79 3.97-1.34 4.39zm-4.6 3.72c-.66-.08-1.19-.64-1.51-1.56l-.75-3.5a3.25 3.25 0 00.8-.62c.69.55 1.62 1.31 2.75 2.26a2.53 2.53 0 01.22 2.69 1.59 1.59 0 01-1.52.72zm-5.63 2.27a2.76 2.76 0 01-2.66-1.17c-.76-1.33.29-2.3 3.18-6.12a2.85 2.85 0 001.39.29l.71 3.33c-.09.33-.3 1.08-.54 1.89a2.36 2.36 0 01-2.08 1.78zm-5-12.45a2.69 2.69 0 011.59.51c.63.43 1.36 1.22 2.12 1.79H9a3.21 3.21 0 000 .45c-.87.27-2.18.68-3.92 1.24a3.53 3.53 0 01-2.76-.51 1.07 1.07 0 01-.51-.84c.04-1.62 1.6-2.64 3.47-2.64zm1.2-5.19c.69-.49 2.08.72 2.81 1.9a10.43 10.43 0 011.09 3 3.18 3.18 0 00-1 1l-.51-.46a11.08 11.08 0 00-1.15-1c-1.68-1.35-2-2.45-2-3.13a1.72 1.72 0 01.74-1.32zm5.94-2.51A2.58 2.58 0 0115 2.09c.9.56 1.3 1.91 1.16 3.71-.79 1-1.76 2.17-2.83 3.44A3.14 3.14 0 0011.81 9a33.45 33.45 0 01-.62-5.11 2.11 2.11 0 011.23-1.72zm7.46 3.77c.22.06.74.26.84.73.05.26.07 1.21-1.88 2.82-2.26.5-3.7.87-4 1l-.25-.33c.29-.35 1.75-2.09 2.9-3.53a2.52 2.52 0 012.37-.7zm-7.3 7.83a1.71 1.71 0 01-2-.94 1.68 1.68 0 01.89-2.19 1.66 1.66 0 111.11 3.13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});