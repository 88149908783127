define("ember-svg-jar/inlined/tools-glue-gun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-glue-gun</title><path d=\"M4.111 18.252a1.106 1.106 0 00.615-.186L7.7 16.081a.5.5 0 00.077-.77l-1.836-1.838a.5.5 0 00-.769.076l-1.985 2.978a1.108 1.108 0 00.924 1.725zM23.714 9.466L20.9 8.134a1.5 1.5 0 01-.417-2.416.954.954 0 00-.373-1.578l-.54-.18a.25.25 0 01-.164-.18.248.248 0 01.067-.234l1.412-1.412A1.25 1.25 0 0019.119.366l-2.473 2.473a.251.251 0 01-.255.06l-.775-.258a2.487 2.487 0 00-2.558.6L7.646 8.658a6.837 6.837 0 00-1.557 2.425.5.5 0 00.114.53l3.436 3.436a.5.5 0 00.53.115 6.832 6.832 0 002.431-1.557 3.352 3.352 0 01.754-.564.249.249 0 01.351.118 3.969 3.969 0 01.269 2.354 1.5 1.5 0 001.972 1.7 6 6 0 003.262-2.79.25.25 0 01.327-.106l3.751 1.781a.5.5 0 00.214.048.508.508 0 00.268-.077.5.5 0 00.232-.422V9.918a.5.5 0 00-.286-.452zm-10.314-.2a.5.5 0 01-.424.141L11.74 9.23a.5.5 0 01-.283-.848l2.963-2.964a.5.5 0 01.512-.121l1.061.354a.5.5 0 01.195.828zm3.958 4.325a3.713 3.713 0 01-.6.8 4.043 4.043 0 01-.316.285.25.25 0 01-.407-.183 5.977 5.977 0 00-.248-1.441.25.25 0 01.31-.312 3.294 3.294 0 01.469.178l.681.322a.251.251 0 01.11.35zM15.5 22h-13a.5.5 0 010-1H3a1 1 0 000-2h-.5a2.5 2.5 0 000 5h13a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});