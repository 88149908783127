define("ember-svg-jar/inlined/single-neutral-actions-upload.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-upload</title><path d=\"M11.335 12.419a.5.5 0 00-.117-.741 7.475 7.475 0 00-11.2 4.179.5.5 0 00.482.643h8.666a.47.47 0 00.462-.387 7.958 7.958 0 011.707-3.694z\"/><circle cx=\"7\" cy=\"4.75\" r=\"4.75\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-1 9.5v-2.25a.5.5 0 00-.5-.5h-.9a.5.5 0 01-.391-.812l2.4-3a.517.517 0 01.782 0l2.4 3a.5.5 0 01-.391.812H19a.5.5 0 00-.5.5v2.25a1 1 0 01-2 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});