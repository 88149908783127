define("ember-svg-jar/inlined/shopping-bag-barcode-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-bag-barcode-1</title><path d=\"M22.25 6.5H18a.25.25 0 01-.25-.25V5.5a5.5 5.5 0 00-11 0v.75a.25.25 0 01-.25.25H1.75A1.5 1.5 0 00.25 8v14.5a1.5 1.5 0 001.5 1.5h20.5a1.5 1.5 0 001.5-1.5V8a1.5 1.5 0 00-1.5-1.5zm-2.413 5V19a.625.625 0 01-1.25 0v-7.5a.625.625 0 011.25 0zm-6.395 5.24a.625.625 0 01-.625-.625V11.5a.625.625 0 011.25 0v4.615a.625.625 0 01-.625.625zm.625 1.683V19a.625.625 0 01-1.25 0v-.577a.625.625 0 011.25 0zm-3.509-1.683a.625.625 0 01-.625-.625V11.5a.625.625 0 011.25 0v4.615a.625.625 0 01-.625.625zm.625 1.683V19a.625.625 0 01-1.25 0v-.577a.625.625 0 011.25 0zm4.519 0a.625.625 0 011.25 0V19a.625.625 0 11-1.25 0zm.625-1.683a.625.625 0 01-.625-.625V11.5a.625.625 0 111.25 0v4.615a.625.625 0 01-.625.625zm-9.279 1.683a.625.625 0 011.25 0V19a.625.625 0 01-1.25 0zm.625-1.683a.625.625 0 01-.625-.625V11.5a.625.625 0 011.25 0v4.615a.625.625 0 01-.625.625zm-2.26-5.24V19a.625.625 0 01-1.25 0v-7.5a.625.625 0 011.25 0zm3.337-6a3.5 3.5 0 017 0v.75a.25.25 0 01-.25.25H9a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});