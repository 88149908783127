define("ember-svg-jar/inlined/equalizer-stereo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>equalizer-stereo</title><path d=\"M15.5 8h-7A1.5 1.5 0 007 9.5v12A1.5 1.5 0 008.5 23h7a1.5 1.5 0 001.5-1.5v-12A1.5 1.5 0 0015.5 8zM12 14.5a3 3 0 11-3 3 3 3 0 013-3zM10 11a1 1 0 011-1h2a1 1 0 010 2h-2a1 1 0 01-1-1zM4.5 1h-3A1.5 1.5 0 000 2.5v19A1.5 1.5 0 001.5 23h3A1.5 1.5 0 006 21.5v-19A1.5 1.5 0 004.5 1zM3 3.5A1.5 1.5 0 111.5 5 1.5 1.5 0 013 3.5zM4.5 10A1.5 1.5 0 113 8.5 1.5 1.5 0 014.5 10zM3 13.5A1.5 1.5 0 111.5 15 1.5 1.5 0 013 13.5zM22.5 1h-3A1.5 1.5 0 0018 2.5v19a1.5 1.5 0 001.5 1.5h3a1.5 1.5 0 001.5-1.5v-19A1.5 1.5 0 0022.5 1zM21 3.5A1.5 1.5 0 1119.5 5 1.5 1.5 0 0121 3.5zm1.5 6.5A1.5 1.5 0 1121 8.5a1.5 1.5 0 011.5 1.5zM21 13.5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});