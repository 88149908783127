define("ember-svg-jar/inlined/messages-bubble-text-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-text_1</title><path d=\"M12 1.375C5.682 1.375.541 5.61.541 10.816A8.568 8.568 0 003.7 17.332L1.416 21.9a.5.5 0 00.658.677L8.2 19.725a13.655 13.655 0 003.8.532c6.319 0 11.459-4.235 11.459-9.441S18.319 1.375 12 1.375zm5 12.25H7.5a.75.75 0 010-1.5H17a.75.75 0 010 1.5zM6.75 8.375a.75.75 0 01.75-.75H14a.75.75 0 010 1.5H7.5a.75.75 0 01-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});