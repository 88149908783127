define("ember-svg-jar/inlined/shooting-target", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shooting-target</title><path d=\"M14.865 9.567a.25.25 0 01-.046-.437 5 5 0 10-5.638 0 .25.25 0 01-.046.437A7.5 7.5 0 004.5 16.5v7a.5.5 0 00.5.5h14a.5.5 0 00.5-.5v-7a7.5 7.5 0 00-4.635-6.933zM12 22.25a4.75 4.75 0 114.75-4.75A4.756 4.756 0 0112 22.25z\"/><path d=\"M12 14.25a3.25 3.25 0 103.25 3.25A3.254 3.254 0 0012 14.25zM12 19a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0112 19z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});