define("ember-svg-jar/inlined/vintage-car-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-car-2</title><path d=\"M21.5 13.375a3.475 3.475 0 011.441.319.75.75 0 00.618-1.367 5.006 5.006 0 00-1.8-.439.251.251 0 01-.154-.064c-.107-.1-.215-.2-.329-.3a16.9 16.9 0 00-1.346-1.164.249.249 0 01-.087-.122l-1.413-4.3a2.007 2.007 0 00-1.877-1.309h-4.805a2.02 2.02 0 00-1.927 1.464l-.8 3.094a.249.249 0 01-.242.188H4a.25.25 0 01-.25-.25v-.1a.25.25 0 01.171-.237l.645-.216a1 1 0 10-.632-1.9l-1.5.5a1 1 0 00-.684.948v2a.986.986 0 00.28.67.25.25 0 00.183.08h1.258a4.776 4.776 0 012.916 1l4.432 3.448a.247.247 0 00.171.052h7.21a.249.249 0 00.221-.133 3.491 3.491 0 013.079-1.862zM15 9.125a.25.25 0 01-.25.25h-3.387a.251.251 0 01-.243-.312l.58-2.252a.248.248 0 01.24-.187l2.808-.02a.246.246 0 01.178.072.252.252 0 01.074.178z\"/><path d=\"M17.764 16.375h-7.136a.251.251 0 01-.153-.053l-4.7-3.657a3.765 3.765 0 00-2.3-.79H.75a.75.75 0 000 1.5H2.5a3.5 3.5 0 013.5 3.5 3.379 3.379 0 01-.21 1.164.25.25 0 00.235.336H16.5a1.507 1.507 0 001.5-1.5 2.039 2.039 0 01.013-.223.251.251 0 00-.249-.277z\"/><circle cx=\"21.5\" cy=\"16.875\" r=\"2.5\"/><circle cx=\"2.5\" cy=\"16.875\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});