define("ember-svg-jar/inlined/ice-cream", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ice-cream</title><path d=\"M7.505 14.25a.782.782 0 00.544-.215.648.648 0 00.206-.472l-.012-3.074A2.224 2.224 0 0110.491 8.3h.009a2.218 2.218 0 012.252 2.177v.223a.756.756 0 001.506-.006l-.018-4.647a2.217 2.217 0 012.244-2.193.288.288 0 00.235-.117.283.283 0 00.042-.258A5 5 0 007 5v8.745a.5.5 0 00.505.505z\"/><path d=\"M16.494 5.351a.719.719 0 00-.753.687l.018 4.647a2.254 2.254 0 01-4.506.019v-.226A.721.721 0 0010.5 9.8a.723.723 0 00-.751.688l.012 3.073a2.135 2.135 0 01-.666 1.56 2.276 2.276 0 01-1.578.634h-.012a.505.505 0 00-.5.5v.245A1.5 1.5 0 008.5 18h7a1.5 1.5 0 001.5-1.5V5.857a.506.506 0 00-.506-.506zM12.5 19h-1a.751.751 0 00-.75.75v3a1.25 1.25 0 002.5 0v-3a.751.751 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});