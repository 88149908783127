define("ember-svg-jar/inlined/astrology-gemini", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astrology-gemini</title><path d=\"M17.38 18.137a.5.5 0 01-.38-.485V6.349a.5.5 0 01.38-.485c3.06-.757 5.476-2.138 6.483-3.859A1 1 0 0022.137 1C21.129 2.718 17.061 4.5 12 4.5S2.871 2.718 1.863 1A1 1 0 00.137 2.005C1.144 3.726 3.56 5.107 6.62 5.864a.5.5 0 01.38.485v11.3a.5.5 0 01-.38.485C3.56 18.894 1.144 20.275.137 22a1 1 0 101.726 1.01C2.871 21.283 6.939 19.5 12 19.5s9.129 1.783 10.137 3.5a1 1 0 101.726-1c-1.007-1.725-3.423-3.106-6.483-3.863zm-7.828-.51a.5.5 0 01-.552-.5V6.872a.5.5 0 01.552-.5A23.649 23.649 0 0012 6.5a23.649 23.649 0 002.448-.126.5.5 0 01.552.5v10.255a.5.5 0 01-.552.5 23.657 23.657 0 00-4.9 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});