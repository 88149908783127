define("ember-svg-jar/inlined/scanner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>scanner</title><path d=\"M.334 20a.249.249 0 00-.247.29C.458 22.593 2.448 24 5.5 24h13c3.052 0 5.042-1.408 5.413-3.71a.249.249 0 00-.247-.29zM19.5 21a.75.75 0 11-.75.75.75.75 0 01.75-.75zm-3 0a.75.75 0 11-.75.75.75.75 0 01.75-.75zM4.031 9.751a.25.25 0 00-.232.157L.3 18.658A.25.25 0 00.531 19h22.938a.25.25 0 00.232-.343l-3.5-8.75a.25.25 0 00-.232-.157zm15.883 7.53a.5.5 0 01-.414.22h-15a.5.5 0 01-.464-.686l2-5A.5.5 0 016.5 11.5h11a.5.5 0 01.464.314l2 5a.5.5 0 01-.05.467zM20.09 8.251a.251.251 0 00.244-.2L21.988.609A.5.5 0 0021.5 0h-19a.5.5 0 00-.488.608l1.654 7.447a.251.251 0 00.244.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});