define("ember-svg-jar/inlined/navigation-arrows-down-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-arrows-down-1</title><path d=\"M21.971 8.687H20.5a.749.749 0 00-.583.279l-6.946 8.579a1.287 1.287 0 01-1.942 0L4.083 8.966a.752.752 0 00-.583-.279H2.029a1.25 1.25 0 00-.983 2.023l9.971 12.69a1.288 1.288 0 001.966 0l9.971-12.69a1.251 1.251 0 00-.983-2.023z\"/><path d=\"M11.017 14.852a1.288 1.288 0 001.966 0l9.972-12.691a1.251 1.251 0 00-.984-2.022H2.029a1.25 1.25 0 00-.983 2.022z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});