define("ember-svg-jar/inlined/vinyl-record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vinyl-record</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 14a2 2 0 112-2 2 2 0 01-2 2zm.5 3.5a.5.5 0 01-.5.5 6.006 6.006 0 01-6-6 .5.5 0 011 0 5.006 5.006 0 005 5 .5.5 0 01.5.5zM12 7a.5.5 0 010-1 6.006 6.006 0 016 6 .5.5 0 01-1 0 5.006 5.006 0 00-5-5zm0 13.5a.5.5 0 010 1A9.511 9.511 0 012.5 12a.5.5 0 011 0 8.51 8.51 0 008.5 8.5zm0-17a.5.5 0 010-1 9.511 9.511 0 019.5 9.5.5.5 0 01-1 0A8.509 8.509 0 0012 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});