define("ember-svg-jar/inlined/shipment-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-download</title><path d=\"M14.883 9.222a.251.251 0 00.186-.242V5.5a.25.25 0 00-.25-.25H.589a.249.249 0 00-.25.25v8.833a1.473 1.473 0 001.472 1.473h6.807a.249.249 0 00.245-.2 8.362 8.362 0 016.02-6.384zM8.44 3.526a.25.25 0 00.25.25h5.241a.25.25 0 00.177-.427L11.284.525a1.483 1.483 0 00-1.041-.431H8.69a.249.249 0 00-.25.25zM6.717 3.776a.25.25 0 00.25-.25V.344a.249.249 0 00-.25-.25H5.164a1.487 1.487 0 00-1.042.431L1.3 3.349a.25.25 0 00.177.427z\"/><path d=\"M17.033 10.65a6.628 6.628 0 106.628 6.628 6.636 6.636 0 00-6.628-6.628zm-2.741 7.426a.492.492 0 01.384-.8H15.8a.25.25 0 00.25-.25v-2.7a.982.982 0 011.964 0v2.7a.25.25 0 00.25.25h1.126a.492.492 0 01.384.8l-2.357 2.946a.492.492 0 01-.767 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});