define("ember-svg-jar/inlined/monitor-find", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-find</title><path d=\"M9.5 16.53a6.99 6.99 0 011.736-4.615.25.25 0 00-.188-.414H2.5A.5.5 0 012 11V2.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v6.759a.25.25 0 00.264.25 4.66 4.66 0 01.265-.008 6.948 6.948 0 011.179.1.25.25 0 00.292-.246V2a2 2 0 00-2-2H2a2 2 0 00-2 2v10.5a2 2 0 002 2h5.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5H5a1 1 0 000 2h4.354a.253.253 0 00.192-.089.25.25 0 00.055-.2A6.927 6.927 0 019.5 16.53z\"/><path d=\"M23.707 23.708a1 1 0 000-1.414l-2.482-2.483a.25.25 0 01-.034-.311 5.54 5.54 0 10-1.691 1.692.251.251 0 01.312.033l2.482 2.483a1 1 0 001.414 0zM13 16.531a3.53 3.53 0 113.53 3.53 3.529 3.529 0 01-3.53-3.53z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});