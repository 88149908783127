define("ember-svg-jar/inlined/smart-watch-square-graph-line-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-graph-line-alternate</title><path d=\"M21 6a3 3 0 00-3-3h-1.078a.249.249 0 01-.24-.182L15.98.365A.5.5 0 0015.5 0h-7a.5.5 0 00-.481.363l-.7 2.455A.248.248 0 017.077 3H6a3 3 0 00-3 3v12a3 3 0 003 3h1.077a.249.249 0 01.24.181l.7 2.456A.5.5 0 008.5 24h7a.5.5 0 00.48-.363l.7-2.456a.25.25 0 01.24-.181H18a3 3 0 003-3zM5 6a1 1 0 011-1h12a1 1 0 011 1v5a.25.25 0 01-.25.25h-1.684a.248.248 0 01-.214-.122l-.74-1.228a1.249 1.249 0 00-2.213.133l-1.092 2.653a.25.25 0 01-.467-.013L10.552 7.51a1.249 1.249 0 00-1.035-.753 1.27 1.27 0 00-1.178.59l-2.2 3.78a.25.25 0 01-.216.125H5.25A.25.25 0 015 11zm14 12a1 1 0 01-1 1H6a1 1 0 01-1-1v-5a.25.25 0 01.25-.25H6.5a.752.752 0 00.648-.373L9.07 9.072a.25.25 0 01.453.043l1.863 5.38a1.256 1.256 0 001.114.756h.034a1.272 1.272 0 001.141-.741l1.216-2.956a.25.25 0 01.446-.033l.519.865a.75.75 0 00.644.365h2.25A.25.25 0 0119 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});