define("ember-svg-jar/inlined/stairs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>stairs</title><path d=\"M24 7a1 1 0 00-1-1h-.5a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h.5a1 1 0 000-2h-1.7a2.242 2.242 0 00-1.11.336L10.7 6.668a1 1 0 001.11 1.664l2.413-1.612a.5.5 0 01.777.416V9.7a.5.5 0 01-.08.271.978.978 0 000 1.083 1 1 0 001.387.277l4.872-3.247A.5.5 0 0121.454 8H23a1 1 0 001-1zm-4.777-3.614A.5.5 0 0120 3.8v2.4a.5.5 0 01-.223.416l-2 1.334A.5.5 0 0117 7.531V5.136a.5.5 0 01.223-.416zM7.5 24a1 1 0 000-2H7a.5.5 0 01-.5-.5V17a.5.5 0 01.5-.5h1.07a.5.5 0 01.265.076l11.358 7.088A2.233 2.233 0 0020.8 24H23a1 1 0 000-2l-1-.007a.5.5 0 01-.5-.5V18.5a.5.5 0 01.5-.5h1a1 1 0 000-2h-2.07a.5.5 0 01-.265-.076L9.307 8.836A1.99 1.99 0 008.2 8.5H2a2 2 0 00-2 2v4a2 2 0 002 2h1a.5.5 0 01.5.5v4.5a.5.5 0 01-.5.5h-.5a1 1 0 000 2zm9-7.866a.25.25 0 01.382-.212l2.383 1.487a.5.5 0 01.235.424v2.9a.25.25 0 01-.382.212l-2.383-1.487a.5.5 0 01-.235-.424zm-2 1.482a.25.25 0 01-.382.212l-2.383-1.488a.5.5 0 01-.235-.424v-2.9a.25.25 0 01.382-.212l2.383 1.487a.5.5 0 01.235.424zM2 11a.5.5 0 01.5-.5H4a.5.5 0 01.5.5v3a.5.5 0 01-.5.5H2.5A.5.5 0 012 14zm7.029 3.65a1.006 1.006 0 00-.529-.15H7a.5.5 0 01-.5-.5v-2.987a.5.5 0 01.5-.5h1.079a.5.5 0 01.263.076l.922.575a.5.5 0 01.235.424v2.9a.249.249 0 01-.129.219.247.247 0 01-.253-.007z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});