define("ember-svg-jar/inlined/kitchen-counter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchen-counter</title><path d=\"M13.5 9A1.5 1.5 0 0012 7.5h-1.459a.5.5 0 01-.5-.5v-.855a.5.5 0 01.25-.433 1.892 1.892 0 00.753-.791 1.944 1.944 0 00.186-1.15L10.784.644A.751.751 0 0010.041 0h-2A.751.751 0 007.3.644l-.448 3.127a1.934 1.934 0 00.187 1.15 1.89 1.89 0 00.752.791.5.5 0 01.25.433V7a.5.5 0 01-.5.5H.5A.5.5 0 000 8v2.5a.5.5 0 00.5.5h3a.5.5 0 01.5.5V23a1 1 0 001 1h1a1 1 0 001-1V11.5a.5.5 0 01.5-.5H12a1.5 1.5 0 001.5-1.5zM22.666 14.915a.5.5 0 01.275-.532.98.98 0 00.559-.883V12a1.5 1.5 0 00-1.5-1.5h-6a1.5 1.5 0 00-1.5 1.5v1.5a.98.98 0 00.559.883.5.5 0 01.275.532l-1.32 7.921a1 1 0 00.821 1.15A1.053 1.053 0 0015 24a1 1 0 00.985-.836l.625-3.746A.5.5 0 0117.1 19h3.8a.5.5 0 01.493.418l.625 3.746A1 1 0 0023 24a1.053 1.053 0 00.166-.014 1 1 0 00.821-1.15zM17.6 17a.5.5 0 01-.493-.582l.25-1.5a.5.5 0 01.493-.418h2.292a.5.5 0 01.493.418l.25 1.5A.5.5 0 0120.4 17z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});