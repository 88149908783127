define("ember-svg-jar/inlined/smiley-tongue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-tongue</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM9.935 8.644a1 1 0 01-1.87.712A1.207 1.207 0 007 8.5a1.207 1.207 0 00-1.065.856 1 1 0 01-1.87-.712A3.187 3.187 0 017 6.5a3.187 3.187 0 012.935 2.144zM7 14.5h8.5a1 1 0 001-1 1 1 0 012 0 3 3 0 01-1.855 2.772.252.252 0 00-.154.213 3.045 3.045 0 01-.545 1.528A3.563 3.563 0 0113 19.5a3.425 3.425 0 01-3.466-2.787.251.251 0 00-.248-.213H7a1 1 0 010-2zm11.356-4.565a1 1 0 01-1.291-.579A1.207 1.207 0 0016 8.5a1.207 1.207 0 00-1.065.856 1 1 0 01-1.87-.712 3.081 3.081 0 015.87 0 1 1 0 01-.579 1.291z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});