define("ember-svg-jar/inlined/trends-hot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trends-hot</title><path d=\"M12.747 14.5a.75.75 0 00.75-.75v-2.5a.75.75 0 00-1.5 0v2.5a.75.75 0 00.75.75z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM5.75 13.25a.25.25 0 00-.25.25v1.75a.75.75 0 01-1.5 0v-5.5a.75.75 0 011.5 0v1.75a.25.25 0 00.25.25h1A.25.25 0 007 11.5V9.75a.75.75 0 011.5 0v5.5a.75.75 0 01-1.5 0V13.5a.25.25 0 00-.25-.25zm4.747-2a2.25 2.25 0 014.5 0v2.5a2.25 2.25 0 01-4.5 0zm7.5 4v-4.5a.25.25 0 00-.25-.25h-.5a.75.75 0 010-1.5h3a.75.75 0 010 1.5h-.5a.25.25 0 00-.25.25v4.5a.75.75 0 01-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});