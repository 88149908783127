define("ember-svg-jar/inlined/camera-tripod-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camera-tripod-1</title><path d=\"M15.323 17.159a.249.249 0 01-.073-.177V13.25a.25.25 0 01.25-.25h1.75a1 1 0 000-2h-6a1 1 0 000 2H13a.25.25 0 01.25.25v.5A.25.25 0 0113 14h-2.88a.25.25 0 01-.208-.111A2 2 0 008.25 13h-3a2 2 0 000 4h3a2 2 0 001.662-.889.25.25 0 01.208-.111H13a.25.25 0 01.25.25v.732a.249.249 0 01-.073.177l-5.134 5.134a1 1 0 001.414 1.414l3.366-3.366a.25.25 0 01.427.177V23a1 1 0 002 0v-2.482a.25.25 0 01.427-.177l3.366 3.366a1 1 0 001.414-1.414z\"/><circle cx=\"14.25\" cy=\"5.5\" r=\"2\"/><path d=\"M20.75 2.5a1.5 1.5 0 00-1.5-1.5h-2.29a.249.249 0 01-.219-.129A1.449 1.449 0 0015.382 0h-2.264a1.449 1.449 0 00-1.359.871.249.249 0 01-.219.129H9.25a1.5 1.5 0 00-1.5 1.5v6a1.5 1.5 0 001.5 1.5h10a1.5 1.5 0 001.5-1.5zm-6.5 0a3 3 0 11-3 3 3 3 0 013-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});