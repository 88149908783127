define("ember-svg-jar/inlined/vip-crown-queen-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vip-crown-queen-1</title><path d=\"M24 6.25a3 3 0 10-4.258 2.714c-.47 1.376-1.536 3.786-3.242 3.786-2.3 0-2.967-3.142-3.53-6.175a3 3 0 10-1.94 0c-.56 3.1-1.239 6.175-3.53 6.175-1.706 0-2.772-2.41-3.242-3.786a3.008 3.008 0 10-2.129.141l1.635 9.809a1 1 0 00.986.836h14.5a1 1 0 00.986-.836l1.635-9.809A2.992 2.992 0 0024 6.25zM19.25 21.25H4.75a1 1 0 000 2h14.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});