define("ember-svg-jar/inlined/tools-crescent-wench", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-crescent-wench</title><path d=\"M7.846 14.388a1.037 1.037 0 00-1.415 0L.673 20.146a2.25 2.25 0 103.182 3.182l5.758-5.758a1 1 0 000-1.414zM22.164 1.837a6.25 6.25 0 00-9.834 7.542.5.5 0 01-.079.6l-2.992 2.995a.5.5 0 000 .707l1.061 1.061a.5.5 0 00.707 0l2.992-2.992a.5.5 0 01.6-.079 6.25 6.25 0 007.542-9.834zm-6.731 6.777a3.251 3.251 0 01-.733-3.447.251.251 0 01.411-.089l3.863 3.863a.251.251 0 01-.089.411 3.251 3.251 0 01-3.452-.738zm5.361-1.176a.25.25 0 01-.411.089L16.52 3.664a.251.251 0 01.089-.411 3.245 3.245 0 014.185 4.185z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});