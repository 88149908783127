define("ember-svg-jar/inlined/expand-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>expand-3</title><path d=\"M0 23a1 1 0 001 1h5a1 1 0 00.707-1.707l-1.439-1.44a.249.249 0 010-.353l4.116-4.116a1.25 1.25 0 10-1.768-1.768L3.5 18.731a.25.25 0 01-.354 0l-1.439-1.439A1 1 0 000 18zM24 1a1 1 0 00-1-1h-5a1 1 0 00-.707 1.707l1.439 1.439a.25.25 0 010 .354l-4.116 4.115a1.25 1.25 0 001.768 1.768L20.5 5.267a.25.25 0 01.354 0l1.439 1.439A1 1 0 0023 7a.987.987 0 00.383-.076A1 1 0 0024 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});