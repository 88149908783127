define("ember-svg-jar/inlined/famous-people-man-merlin-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-people-man-merlin-2</title><path d=\"M17.258 13.077a.248.248 0 00.2-.338L12.525.31a.492.492 0 00-.407-.31.593.593 0 00-.511.3L6.451 12.725a.247.247 0 00.2.341 46.961 46.961 0 0010.607.011z\"/><path d=\"M4.787 15.781A.248.248 0 015 16.02c.048 1.852.657 4.636-1.345 6.638a.5.5 0 00-.083.591.491.491 0 00.542.243l3.612-.818a.247.247 0 01.2.04 6.885 6.885 0 008.091-.054.248.248 0 01.206-.04l3.522.87a.5.5 0 00.468-.832c-1.989-1.989-1.382-4.278-1.344-6.619a.247.247 0 01.212-.241 49.375 49.375 0 002.592-.44.991.991 0 00.777-1.167 1.013 1.013 0 00-1.166-.777 47.191 47.191 0 01-18.578 0 1.014 1.014 0 00-1.166.777.991.991 0 00.777 1.167c.825.164 1.647.302 2.47.423zm7.213.54q2.312 0 4.621-.22a.251.251 0 01.19.064.245.245 0 01.081.183v.712a4.926 4.926 0 01-1.17 3.187.251.251 0 01-.208.087.248.248 0 01-.193-.118 3.993 3.993 0 00-6.772 0 .248.248 0 01-.4.03 4.922 4.922 0 01-1.171-3.186v-.727a.249.249 0 01.273-.247q2.372.233 4.749.235zm1.227 5.3a2.424 2.424 0 01-.427.177 2.912 2.912 0 01-.854.125h-.013a2.971 2.971 0 01-.857-.125 2.311 2.311 0 01-.433-.179.634.634 0 01.3-1.192h1.983a.634.634 0 01.3 1.193z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});