define("ember-svg-jar/inlined/crime-man-thief-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-man-thief-3</title><path d=\"M13.587 12.505a.626.626 0 00-.309-.7 2.293 2.293 0 00-.427-.177 2.892 2.892 0 00-.845-.123H12a2.945 2.945 0 00-.849.124 2.309 2.309 0 00-.426.176.626.626 0 00.3 1.177h1.955a.627.627 0 00.607-.477z\"/><path d=\"M22.533 18.917a15.02 15.02 0 00-6.821-2.634.25.25 0 01-.212-.247V15.2a.25.25 0 01.12-.213A7 7 0 0019 9V7A7 7 0 005 7v2a6.994 6.994 0 003.38 5.981.253.253 0 01.12.214v.841a.25.25 0 01-.212.247 15.015 15.015 0 00-6.821 2.634A2.467 2.467 0 00.5 20.894V23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-2.606a2.462 2.462 0 00-.967-1.977zM16 8a.5.5 0 01-.5.5h-1a.5.5 0 010-1h1a.5.5 0 01.5.5zm-6 0a.5.5 0 01-.5.5h-1a.5.5 0 010-1h1a.5.5 0 01.5.5zm2-6a4.991 4.991 0 014.72 3.408.253.253 0 01-.032.223.247.247 0 01-.2.107 6.489 6.489 0 00-4.336 1.594.249.249 0 01-.337 0 6.392 6.392 0 00-4.306-1.593.251.251 0 01-.229-.33A4.991 4.991 0 0112 2zm-4.4 9.363a.25.25 0 01.205-.369 6.33 6.33 0 004.058-1.6.219.219 0 01.3 0 6.241 6.241 0 004.031 1.6.253.253 0 01.2.128.25.25 0 010 .24 4.986 4.986 0 01-8.8 0z\"/><circle cx=\"9.25\" cy=\"4.25\" r=\".75\"/><circle cx=\"12\" cy=\"3.25\" r=\".75\"/><circle cx=\"14.75\" cy=\"4.25\" r=\".75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});