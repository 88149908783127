define("ember-svg-jar/inlined/amusement-park-strength-meter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>amusement-park-strength-meter</title><path d=\"M17.305 17.207a1 1 0 001.415 0l1.414-1.414a1 1 0 000-1.414.5.5 0 010-.707l2.828-2.829a1 1 0 10-1.414-1.414l-2.828 2.828a.5.5 0 01-.707 0 1 1 0 00-1.415 0l-1.414 1.415a1 1 0 000 1.414zM11 20.5h2.5a1 1 0 000-2H11a1 1 0 000 2zM12.793 16.707a1 1 0 001.414-1.414l-1.768-1.768a1 1 0 10-1.414 1.414z\"/><path d=\"M23 22h-1.5a.5.5 0 01-.5-.5V21a1.5 1.5 0 00-1.5-1.5h-3A1.5 1.5 0 0015 21v.5a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5V7.659a1 1 0 01.182-.575 4.5 4.5 0 10-7.364 0A1 1 0 012 7.659V21.5a.5.5 0 01-.5.5H1a1 1 0 000 2h22a1 1 0 000-2zM6.5 10H7a.5.5 0 010 1h-.5a.5.5 0 00-.5.5V14a.5.5 0 00.5.5H7a.5.5 0 010 1h-.5a.5.5 0 00-.5.5v2.5a.5.5 0 00.5.5H7a.5.5 0 010 1h-.5a.5.5 0 00-.5.5v1a.5.5 0 01-1 0v-1a.5.5 0 00-.5-.5H4a.5.5 0 010-1h.5a.5.5 0 00.5-.5V16a.5.5 0 00-.5-.5H4a.5.5 0 010-1h.5A.5.5 0 005 14v-2.5a.5.5 0 00-.5-.5H4a.5.5 0 010-1h.5a.5.5 0 00.5-.5V7.329a.5.5 0 00-.333-.472 2.5 2.5 0 111.666 0A.5.5 0 006 7.329V9.5a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});