define("ember-svg-jar/inlined/monetization-mouse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monetization-mouse</title><path d=\"M7.6 20.5a7.568 7.568 0 002.673-.483 1 1 0 00-.7-1.872A5.6 5.6 0 117.6 7.3a5.663 5.663 0 011.2.128 1 1 0 00.426-1.954A7.6 7.6 0 107.6 20.5zM12.25 7.75h4.8a.249.249 0 00.25-.25V2.75a.25.25 0 00-.25-.25H15.5A3.5 3.5 0 0012 6v1.5a.249.249 0 00.25.25zM24 6a3.5 3.5 0 00-3.5-3.5h-1.448a.25.25 0 00-.25.25V7.5a.249.249 0 00.25.25h4.7A.249.249 0 0024 7.5zM24 9.5a.25.25 0 00-.25-.25h-11.5a.25.25 0 00-.25.25v6a6 6 0 0012 0z\"/><path d=\"M7.79 15.092H5.757a.75.75 0 100 1.5h.5a.25.25 0 01.25.25.75.75 0 001.5 0V16.8a.248.248 0 01.2-.244 2.091 2.091 0 00.359-3.992L6.5 11.733a.592.592 0 01.22-1.141h2.037a.75.75 0 000-1.5h-.5a.25.25 0 01-.25-.25.75.75 0 00-1.5 0v.047a.25.25 0 01-.2.245 2.092 2.092 0 00-.361 3.992l2.064.825a.592.592 0 01-.22 1.141z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});