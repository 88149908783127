define("ember-svg-jar/inlined/phone-actions-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-clock</title><path d=\"M17.168 16.162a2.5 2.5 0 00-3.536 0l-.385.385a46.829 46.829 0 01-5.789-5.789l.385-.385a2.505 2.505 0 000-3.535L5.721 4.716a2.5 2.5 0 00-3.536 0L1.022 5.88a3.51 3.51 0 00-.442 4.4 46.945 46.945 0 0013.141 13.145 3.542 3.542 0 004.4-.443l1.164-1.163a2.5 2.5 0 000-3.536zM17.5.006a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm0 11a4.5 4.5 0 114.5-4.5 4.5 4.5 0 01-4.5 4.5z\"/><path d=\"M19.5 5.756h-1.25v-1.75a.75.75 0 00-1.5 0v2.5a.75.75 0 00.75.75h2a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});