define("ember-svg-jar/inlined/business-deal-men-cash-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-deal-men-cash-1</title><path d=\"M.167 23.833A.5.5 0 00.539 24H8.9a.5.5 0 00.495-.557 4.706 4.706 0 00-2.316-3.513.253.253 0 01-.123-.2.248.248 0 01.09-.213 3.75 3.75 0 10-4.717.035.251.251 0 01.094.212.248.248 0 01-.121.2 4.708 4.708 0 00-2.257 3.479.5.5 0 00.122.39zm5.3-1.395a.748.748 0 11-1.495 0v-.984a.748.748 0 111.495 0zm-2.5-6.253a.249.249 0 01.146-.164.245.245 0 01.219.014 4.509 4.509 0 002.243.6 4.336 4.336 0 00.752-.072.078.078 0 01.059.017.072.072 0 01.026.055 1.754 1.754 0 01-3.507 0 1.669 1.669 0 01.066-.45zM9.076 14.435a1 1 0 001.055-.121l2.15-1.719a.251.251 0 01.156-.055h7.536a2 2 0 001.993-1.993V1.993A2 2 0 0019.973 0a1 1 0 00-.2 1.974.25.25 0 01.2.245V10.3a.25.25 0 01-.25.25H12a.993.993 0 00-.623.218l-.575.461a.253.253 0 01-.385-.092.994.994 0 00-.908-.587H8.264a.25.25 0 01-.25-.25V2a1 1 0 000-2 2 2 0 00-1.993 1.993v8.554a2 2 0 001.993 1.993h.248a.249.249 0 01.25.25v.746a1 1 0 00.564.899z\"/><path d=\"M23.955 23.443a4.708 4.708 0 00-2.316-3.513.253.253 0 01-.123-.2.248.248 0 01.09-.213 3.75 3.75 0 10-4.716.035.252.252 0 01.093.212.248.248 0 01-.12.2 4.706 4.706 0 00-2.258 3.478.5.5 0 00.495.558h8.361a.5.5 0 00.372-.167.492.492 0 00.122-.39zm-3.928-1.005a.748.748 0 11-1.5 0v-.984a.748.748 0 111.5 0zm-.8-4.05a1.755 1.755 0 01-1.753-1.753 1.669 1.669 0 01.063-.45.249.249 0 01.364-.15 4.509 4.509 0 002.243.6 4.336 4.336 0 00.752-.072.078.078 0 01.059.017.069.069 0 01.026.055 1.755 1.755 0 01-1.759 1.753zM13.623 3.175h1.788a1 1 0 100-1.993h-.22a.1.1 0 01-.1-.1 1 1 0 10-1.993 0v.008a.219.219 0 01-.15.208 2.17 2.17 0 00-.13 4.081l1.812.721a.183.183 0 01-.069.353h-1.788a1 1 0 100 1.993h.219a.1.1 0 01.1.1 1 1 0 001.993 0v-.003a.219.219 0 01.15-.208 2.17 2.17 0 00.131-4.081l-1.815-.727a.179.179 0 01-.115-.17.184.184 0 01.187-.182z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});