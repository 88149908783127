define("ember-svg-jar/inlined/seat-regular", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seat-regular</title><circle cx=\"6.75\" cy=\"3.784\" r=\"2.5\"/><path d=\"M17.1 17.648a4.521 4.521 0 00-4.455-3.864h-1.552a1.5 1.5 0 01-1.456-1.136L8.705 8.92a1.5 1.5 0 00-2.91.727l.931 3.729a4.5 4.5 0 004.367 3.408h1.555a1.507 1.507 0 011.485 1.288l.632 3.888a1.5 1.5 0 102.97-.423z\"/><path d=\"M11.888 20.135a1 1 0 00-.947-.86 6.961 6.961 0 01-6.652-5.293 385.732 385.732 0 01-.721-2.895l-.144-.581a1 1 0 00-.969-.757h-.7a.5.5 0 00-.5.5v12a1 1 0 001 1h9a1 1 0 00.99-1.143c-.026-.145-.23-1.063-.357-1.971zM19.75.749h-3a3 3 0 00-3 3v5.5a3 3 0 003 3h3a3 3 0 003-3v-5.5a3 3 0 00-3-3zm1 8.5a1 1 0 01-1 1h-3a1 1 0 01-1-1v-5.5a1 1 0 011-1h3a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});