define("ember-svg-jar/inlined/multiple-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-woman</title><path d=\"M14.464 6.319a.5.5 0 00.768.332 6.673 6.673 0 001.159-.974.25.25 0 01.363 0 6.574 6.574 0 002.928 1.79.5.5 0 01.354.549 3.525 3.525 0 01-3.485 3.03 3.484 3.484 0 01-.6-.052.5.5 0 00-.578.584l.084.449a2.376 2.376 0 01.039.479.5.5 0 00.444.506 5.344 5.344 0 00.61.034 5.5 5.5 0 003.614-1.346.25.25 0 01.4.1 6.092 6.092 0 00.6 1.227 1 1 0 001.68-1.084 5.417 5.417 0 01-.765-2.98v-1.44a5.517 5.517 0 00-8.563-4.606.5.5 0 00-.138.7 6.975 6.975 0 011.086 2.702zM16.5 14a7.345 7.345 0 00-1.689.2 7.82 7.82 0 00-.682.193.5.5 0 00-.194.828A8.824 8.824 0 0116.5 21.5a.5.5 0 00.5.5h6.5a.5.5 0 00.5-.5 7.508 7.508 0 00-7.5-7.5zM7.5 14A7.508 7.508 0 000 21.5a.5.5 0 00.5.5h14a.5.5 0 00.5-.5A7.508 7.508 0 007.5 14zM11.164 11.7a.249.249 0 01.4.1 6.092 6.092 0 00.6 1.227 1 1 0 101.68-1.084 5.417 5.417 0 01-.765-2.98v-1.44a5.524 5.524 0 00-11.047 0v1.44a7.285 7.285 0 01-.046.824 4.953 4.953 0 01-.719 2.156 1 1 0 101.68 1.084 6.084 6.084 0 00.6-1.226.249.249 0 01.4-.1 5.5 5.5 0 003.612 1.349 5.572 5.572 0 003.605-1.35zM4.07 8.029a.5.5 0 01.356-.552 6.569 6.569 0 002.965-1.8.25.25 0 01.363 0 6.574 6.574 0 002.928 1.79.5.5 0 01.354.549 3.518 3.518 0 01-6.966.013z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});