define("ember-svg-jar/inlined/gender-gay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gender-gay</title><path d=\"M12.054 8.277a1.25 1.25 0 00-.589 1.667 4.2 4.2 0 01.41 1.824A4.25 4.25 0 114.993 8.43a1.25 1.25 0 10-1.549-1.962A6.744 6.744 0 005.7 18.229a.249.249 0 01.178.239v1.55a.25.25 0 01-.25.25h-1a.5.5 0 00-.354.853l2.5 2.5a.5.5 0 00.708 0l2.5-2.5a.5.5 0 00-.354-.853h-1a.25.25 0 01-.25-.25v-1.324a.251.251 0 01.214-.248 6.759 6.759 0 005.786-6.678 6.677 6.677 0 00-.654-2.9 1.252 1.252 0 00-1.67-.591z\"/><path d=\"M22.625.232H19.09a.5.5 0 00-.354.854l.725.725a.25.25 0 010 .353l-1.888 1.888a.25.25 0 01-.312.033 6.748 6.748 0 00-9.673 8.7 1.25 1.25 0 102.234-1.121 4.2 4.2 0 01-.447-1.9 4.25 4.25 0 116.749 3.437 1.251 1.251 0 001.476 2.026 6.781 6.781 0 002.778-5.459 6.7 6.7 0 00-1.068-3.637.251.251 0 01.034-.312l1.888-1.887a.247.247 0 01.353 0l.689.689a.5.5 0 00.854-.353V.732a.5.5 0 00-.503-.5z\"/><path d=\"M12.054 8.277a1.25 1.25 0 00-.589 1.667 4.2 4.2 0 01.41 1.824A4.25 4.25 0 114.993 8.43a1.25 1.25 0 10-1.549-1.962A6.744 6.744 0 005.7 18.229a.249.249 0 01.178.239v1.55a.25.25 0 01-.25.25h-1a.5.5 0 00-.354.853l2.5 2.5a.5.5 0 00.708 0l2.5-2.5a.5.5 0 00-.354-.853h-1a.25.25 0 01-.25-.25v-1.324a.251.251 0 01.214-.248 6.759 6.759 0 005.786-6.678 6.677 6.677 0 00-.654-2.9 1.252 1.252 0 00-1.67-.591z\"/><path d=\"M22.625.232H19.09a.5.5 0 00-.354.854l.725.725a.25.25 0 010 .353l-1.888 1.888a.25.25 0 01-.312.033 6.748 6.748 0 00-9.673 8.7 1.25 1.25 0 102.234-1.121 4.2 4.2 0 01-.447-1.9 4.25 4.25 0 116.749 3.437 1.251 1.251 0 001.476 2.026 6.781 6.781 0 002.778-5.459 6.7 6.7 0 00-1.068-3.637.251.251 0 01.034-.312l1.888-1.887a.247.247 0 01.353 0l.689.689a.5.5 0 00.854-.353V.732a.5.5 0 00-.503-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});