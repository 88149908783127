define("ember-svg-jar/inlined/list-to-do", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>list-to-do</title><path d=\"M10.414 13.531l-1.375 1.718a.139.139 0 01-.228 0l-.781-.781a.75.75 0 00-1.06 1.062l1.5 1.5a.748.748 0 00.53.219h.041a.75.75 0 00.545-.28l2-2.5a.75.75 0 00-1.172-.937zM10.414 8.031L9.039 9.749a.139.139 0 01-.228 0l-.781-.78a.75.75 0 00-1.06 1.061l1.5 1.5a.748.748 0 00.53.219h.041a.75.75 0 00.545-.28l2-2.5a.75.75 0 00-1.172-.937zM16 9.749h-2.5a.75.75 0 000 1.5H16a.75.75 0 000-1.5zM16 15.249h-2.5a.75.75 0 100 1.5H16a.75.75 0 000-1.5z\"/><path d=\"M21.707 5.706L16.293.292A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.413a1 1 0 00-.293-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});