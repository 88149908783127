define("ember-svg-jar/inlined/style-three-pin-burger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-burger</title><circle cx=\"10.25\" cy=\"6.25\" r=\".75\"/><circle cx=\"13.75\" cy=\"6.25\" r=\".75\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM9.5 4.5h5a3 3 0 013 3 .5.5 0 01-.5.5H7a.5.5 0 01-.5-.5 3 3 0 013-3zM16 15H8a1.5 1.5 0 01-1.5-1.5.5.5 0 01.5-.5h10a.5.5 0 01.5.5A1.5 1.5 0 0116 15zm2-3.5H6a1 1 0 010-2h12a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});