define("ember-svg-jar/inlined/chef-gear-taco-plate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chef-gear-taco-plate</title><path d=\"M22.491 14.708a1 1 0 10-1.27 1.545A1.824 1.824 0 0122 17.481c0 1.461-3.8 3.5-10 3.5s-10-2.039-10-3.5a1.824 1.824 0 01.779-1.228 1 1 0 10-1.27-1.545A3.574 3.574 0 000 17.481c0 3.572 6.183 5.5 12 5.5s12-1.928 12-5.5a3.574 3.574 0 00-1.509-2.773zM10.022 12.294l2.353.706a.5.5 0 00.455-.086 5.338 5.338 0 013.807-1.128.25.25 0 00.267-.3 3.858 3.858 0 00-7.455-.26.858.858 0 00.573 1.068z\"/><path d=\"M12.286 17.124a.857.857 0 00.857.857h6a.857.857 0 00.857-.857 3.857 3.857 0 00-7.714 0zM4 17.124a.857.857 0 00.857.857h6a.857.857 0 00.857-.857 3.857 3.857 0 00-7.714 0zM4.75 9.481h.88c2.5 0 4.25-2.467 4.25-6C9.88.2.5.2.5 3.481c0 3.533 1.748 6 4.25 6zm-2.75-6c0-.257 1.1-1 3.19-1s3.19.743 3.19 1-1.1 1-3.19 1S2 3.738 2 3.481z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});