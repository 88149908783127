define("ember-svg-jar/inlined/real-estate-action-building-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-building-information</title><path d=\"M6.724 6.052l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 000 6.5V14a.5.5 0 00.5.5H2a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5A.5.5 0 007 14V6.5a.5.5 0 00-.276-.448zM5 10.5H2a.5.5 0 010-1h3a.5.5 0 010 1zM5 8H2a.5.5 0 110-1h3a.5.5 0 010 1zM14.677 10.021A.5.5 0 0015 9.554V3a.5.5 0 00-.308-.461l-6-2.5A.5.5 0 008 .5V14a.5.5 0 00.5.5h1.262a.5.5 0 00.456-.293 8.039 8.039 0 014.459-4.186zM10 4.5h3a.5.5 0 010 1h-3a.5.5 0 010-1zM10 7h3a.5.5 0 010 1h-3a.5.5 0 010-1zm0 3.5a.5.5 0 010-1h3a.5.5 0 010 1zM17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-.75 2.25a1 1 0 11-1 1 1 1 0 011-1zm2.25 8.5h-3a.75.75 0 010-1.5h.25a.5.5 0 00.5-.5v-1.5a.5.5 0 00-.5-.5H16a.75.75 0 010-1.5h1a1.252 1.252 0 011.25 1.25v2.25a.5.5 0 00.5.5H19a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});