define("ember-svg-jar/inlined/vectors-square-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vectors-square-link</title><rect x=\"7.5\" y=\"2\" width=\"9\" height=\"2\" rx=\".25\" ry=\".25\"/><rect x=\"2\" y=\"7.5\" width=\"2\" height=\"9\" rx=\".25\" ry=\".25\"/><circle cx=\"21\" cy=\"3\" r=\"3\"/><circle cx=\"3\" cy=\"3\" r=\"3\"/><circle cx=\"3\" cy=\"21\" r=\"3\"/><path d=\"M14.979 20.249l-1.318 1.316a1.485 1.485 0 01-2.1 0l-.877-.877a1.483 1.483 0 010-2.1l3.763-3.764a1.131 1.131 0 011.6 0l.69.69a1 1 0 001.412-1.414l-.69-.69a3.2 3.2 0 00-4.425 0l-3.763 3.764a3.484 3.484 0 000 4.928l.877.878a3.489 3.489 0 004.927 0l1.318-1.316a1 1 0 00-1.414-1.414z\"/><path d=\"M22.1 9.271a3.489 3.489 0 00-4.927 0l-1.317 1.317A1 1 0 0017.271 12l1.317-1.316a1.485 1.485 0 012.1 0l.878.878a1.482 1.482 0 010 2.1L17.8 17.426a1.13 1.13 0 01-1.6 0l-.69-.69a1 1 0 10-1.41 1.414l.69.69a3.129 3.129 0 004.424 0l3.764-3.764a3.483 3.483 0 000-4.927z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});