define("ember-svg-jar/inlined/study-light-idea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>study-light-idea</title><path d=\"M12 4a8 8 0 00-3.521 15.184.5.5 0 01.28.438 3.232 3.232 0 001.869 2.8A.645.645 0 0111 23a1 1 0 102 0 .646.646 0 01.373-.585 3.253 3.253 0 001.874-2.806.5.5 0 01.279-.429A8 8 0 0012 4zm-2 8.5a.25.25 0 01-.25.25h-.5A.75.75 0 1110 12zm2.5 4.979a.5.5 0 01-1 0v-2.728a.5.5 0 111 0zm1.848.043a.254.254 0 01-.236-.022.248.248 0 01-.112-.207v-2.542a.5.5 0 01.5-.5h.25A2.25 2.25 0 1012.5 12v.25a.5.5 0 01-1 0V12a2.25 2.25 0 10-2.25 2.25h.25a.5.5 0 01.5.5v2.542a.248.248 0 01-.112.208.251.251 0 01-.236.022 6 6 0 114.7 0zM14 12.5V12a.75.75 0 11.75.75h-.5a.25.25 0 01-.25-.25zM2 11H1a1 1 0 000 2h1a1 1 0 000-2zM4.222 5.637a1 1 0 001.414-1.414l-.707-.707A1 1 0 003.515 4.93zM12 3a1 1 0 001-1V1a1 1 0 00-2 0v1a1 1 0 001 1zM19.071 3.516l-.707.707a1 1 0 101.414 1.414l.707-.707a1 1 0 00-1.414-1.414z\"/><path d=\"M23 11h-1a1 1 0 000 2h1a1 1 0 000-2zM19.778 18.366a1 1 0 10-1.414 1.414l.707.707a1 1 0 001.414-1.414zM4.222 18.366l-.707.707a1 1 0 101.414 1.414l.707-.707a1 1 0 00-1.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});