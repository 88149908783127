define("ember-svg-jar/inlined/gesture-swipe-vertical-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-vertical-down</title><path d=\"M15.44 7.057a1 1 0 001.95-.443A12.344 12.344 0 0014.333.4a1 1 0 00-1.366 1.461 10.585 10.585 0 012.473 5.196zM16.636 16.424a1 1 0 00-1.2.754 15.31 15.31 0 01-.9 2.767.251.251 0 01-.374.1l-.578-.413a.751.751 0 00-1.176.734l.479 2.87a.75.75 0 00.739.626c.082 0-.167.038 2.994-.489a.75.75 0 00.312-1.35l-.7-.5a.249.249 0 01-.079-.312 16.838 16.838 0 001.228-3.6 1 1 0 00-.745-1.187zM16.5 8.632h-12a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h12a3.5 3.5 0 000-7zm0 5h-1.125a.375.375 0 01-.375-.375v-2.25a.375.375 0 01.375-.375H16.5a1.5 1.5 0 010 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});