define("ember-svg-jar/inlined/money-wallet-open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>money-wallet-open</title><path d=\"M24 3.805a3.5 3.5 0 00-3.5-3.5h-17a3.487 3.487 0 00-2.493 1.046.477.477 0 00-.138.149A3.483 3.483 0 000 3.805v13a4.51 4.51 0 003.306 4.174L14.465 23.6a3.352 3.352 0 00.771.092 2.725 2.725 0 001.716-.582A2.86 2.86 0 0018 20.805v-9.693a4.262 4.262 0 00-3.119-4.1L4.056 4.027a.749.749 0 11.4-1.445l3.5.966a.237.237 0 00.065.009h12.478a.75.75 0 110 1.5h-5.234a.25.25 0 00-.067.491l.086.023a5.725 5.725 0 012.9 1.893.254.254 0 00.2.093H20.5a.75.75 0 010 1.5h-1.038a.252.252 0 00-.2.1.248.248 0 00-.035.224 5.608 5.608 0 01.281 1.73v6.943a.25.25 0 00.25.25h.742a3.5 3.5 0 003.5-3.5zm-8.5 11.5a2 2 0 11-2-2 2 2 0 012 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});