define("ember-svg-jar/inlined/lucide-accessibility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"16\" cy=\"4\" r=\"1\"/><path d=\"M18 19l1-7-5.87.94M5 8l3-3 5.5 3-2.21 3.1M4.24 14.48c-.19.58-.27 1.2-.23 1.84a5 5 0 005.31 4.67c.65-.04 1.25-.2 1.8-.46\"/><path d=\"M13.76 17.52c.19-.58.27-1.2.23-1.84a5 5 0 00-5.31-4.67c-.65.04-1.25.2-1.8.46\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});