define("ember-svg-jar/inlined/renewable-energy-solar-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>renewable-energy-solar-monitor</title><path d=\"M22 0h-9.5a2 2 0 00-2 2v5a2 2 0 002 2h3.25a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-1.5a.75.75 0 000 1.5h6a.75.75 0 000-1.5h-1.5a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5H22a2 2 0 002-2V2a2 2 0 00-2-2zm0 6.5a.5.5 0 01-.5.5H13a.5.5 0 01-.5-.5v-4A.5.5 0 0113 2h8.5a.5.5 0 01.5.5zM17.25 13a1 1 0 00-1 1v5a1 1 0 01-1 1h-.75a.5.5 0 01-.5-.5v-5a1.5 1.5 0 00-1.5-1.5h-.926a.5.5 0 00-.477.349l-.482 1.526a1.016 1.016 0 01-.924.625H4.309a.992.992 0 01-.894-.553l-.51-1.6A.5.5 0 002.429 13H1.5A1.5 1.5 0 000 14.5v8A1.5 1.5 0 001.5 24h1.191a.992.992 0 00.894-.553.81.81 0 01.724-.447h5.382a.81.81 0 01.724.447.992.992 0 00.894.553H12.5a1.5 1.5 0 001.5-1.5.5.5 0 01.5-.5h.75a3 3 0 003-3v-5a1 1 0 00-1-1zM11 20.5a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h7a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});