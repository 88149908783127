define("ember-svg-jar/inlined/layers-grid-magnet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layers-grid-magnet</title><path d=\"M22.25 10.5h-2a1.752 1.752 0 00-1.75 1.75v5a1.25 1.25 0 01-2.5 0v-5a1.752 1.752 0 00-1.75-1.75h-2a1.752 1.752 0 00-1.75 1.75v5a6.75 6.75 0 0013.5 0v-5a1.752 1.752 0 00-1.75-1.75zm-8 4h-2a.25.25 0 01-.25-.25v-2a.25.25 0 01.25-.25h2a.25.25 0 01.25.25v2a.25.25 0 01-.25.25zm8 0h-2a.25.25 0 01-.25-.25v-2a.25.25 0 01.25-.25h2a.25.25 0 01.25.25v2a.25.25 0 01-.25.25z\"/><path d=\"M8.5 14.75a.75.75 0 000-1.5H8a.25.25 0 01-.25-.25V8A.25.25 0 018 7.75h4a.25.25 0 01.25.25v.5a.75.75 0 001.5 0V8a.25.25 0 01.25-.25h3.75A.25.25 0 0118 8v.5a1 1 0 002 0V3a3 3 0 00-3-3H3a3 3 0 00-3 3v15a3 3 0 003 3h4.5a1 1 0 001-1 .978.978 0 00-.615-.9.218.218 0 01-.135-.2V15a.25.25 0 01.25-.25zM6.25 13a.25.25 0 01-.25.25H2.25A.25.25 0 012 13V8a.25.25 0 01.25-.25H6a.25.25 0 01.25.25zM8 6.25A.25.25 0 017.75 6V2.25A.25.25 0 018 2h4a.25.25 0 01.25.25V6a.25.25 0 01-.25.25zM18 3v3a.25.25 0 01-.25.25H14a.25.25 0 01-.25-.25V2.25A.25.25 0 0114 2h3a1 1 0 011 1zM3 2h3a.25.25 0 01.25.25V6a.25.25 0 01-.25.25H2.25A.25.25 0 012 6V3a1 1 0 011-1zm3.25 16.75A.25.25 0 016 19H3a1 1 0 01-1-1v-3a.25.25 0 01.25-.25H6a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});