define("ember-svg-jar/inlined/grid-guides", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>grid-guides</title><path d=\"M23 12.5a1 1 0 000-2h-4.75a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25H23a1 1 0 000-2h-4.75a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v3.75a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v3.75a.25.25 0 01-.25.25h-3A.25.25 0 017 4.75V1a1 1 0 00-2 0v3.75a.25.25 0 01-.25.25H1a1 1 0 000 2h3.75a.25.25 0 01.25.25v3a.25.25 0 01-.25.25H1a1 1 0 000 2h3.75a.25.25 0 01.25.25v3a.25.25 0 01-.25.25H1a1 1 0 000 2h3.75a.25.25 0 01.25.25V23a1 1 0 002 0v-4.75a.25.25 0 01.25-.25h3a.25.25 0 01.25.25V23a1 1 0 002 0v-4.75a.25.25 0 01.25-.25h3a.25.25 0 01.25.25V23a1 1 0 002 0v-4.75a.25.25 0 01.25-.25H23a1 1 0 000-2h-4.75a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25zM15.75 7a.25.25 0 01.25.25v3a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25zM7 7.25A.25.25 0 017.25 7h3a.25.25 0 01.25.25v3a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25zM7.25 16a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25h3a.25.25 0 01.25.25v3a.25.25 0 01-.25.25zm8.75-.25a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25h3a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});