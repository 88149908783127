define("ember-svg-jar/inlined/layers-grid-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layers-grid-add</title><path d=\"M8 19a.25.25 0 01-.25-.25V15a.25.25 0 01.25-.25h1.825a.251.251 0 00.233-.159 8.021 8.021 0 01.456-.97.247.247 0 000-.248.251.251 0 00-.215-.123H8a.25.25 0 01-.25-.25V8A.25.25 0 018 7.75h4a.25.25 0 01.25.25v2.95a.25.25 0 00.4.2 7.976 7.976 0 01.97-.641.249.249 0 00.128-.218V8A.25.25 0 0114 7.75h3.75A.25.25 0 0118 8v1.294a.251.251 0 00.228.249 7.935 7.935 0 011.454.265.25.25 0 00.318-.24V3a3 3 0 00-3-3H3a3 3 0 00-3 3v15a3 3 0 003 3h6.927a.25.25 0 00.229-.349 7.869 7.869 0 01-.475-1.478.219.219 0 00-.214-.173zm-1.75-6a.25.25 0 01-.25.25H2.25A.25.25 0 012 13V8a.25.25 0 01.25-.25H6a.25.25 0 01.25.25zM8 6.25A.25.25 0 017.75 6V2.25A.25.25 0 018 2h4a.25.25 0 01.25.25V6a.25.25 0 01-.25.25zM18 3v3a.25.25 0 01-.25.25H14a.25.25 0 01-.25-.25V2.25A.25.25 0 0114 2h3a1 1 0 011 1zM3 2h3a.25.25 0 01.25.25V6a.25.25 0 01-.25.25H2.25A.25.25 0 012 6V3a1 1 0 011-1zm0 17a1 1 0 01-1-1v-3a.25.25 0 01.25-.25H6a.25.25 0 01.25.25v3.75A.25.25 0 016 19z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm1 7.25a.25.25 0 00-.25.25V20a.75.75 0 01-1.5 0v-1.5a.25.25 0 00-.25-.25H15a.75.75 0 010-1.5h1.5a.25.25 0 00.25-.25V15a.75.75 0 011.5 0v1.5a.25.25 0 00.25.25H20a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});