define("ember-svg-jar/inlined/agriculture-machine-disk-plow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>agriculture-machine-disk-plow</title><path d=\"M10.5 3.5h3A.5.5 0 0014 3V2a2 2 0 00-4 0v1a.5.5 0 00.5.5zM23 11h-.828a1 1 0 01-.6-.2l-7.718-5.847a1 1 0 00-.6-.2h-2.5a1 1 0 00-.6.2L2.432 10.8a1 1 0 01-.6.2H1a1 1 0 00-1 1v.5a1 1 0 001 1h3.7a.247.247 0 01.222.136.251.251 0 01-.019.26c-2.4 3.353-3.4 4.3-3.4 6.1a4 4 0 005.169 3.826.5.5 0 00.268-.758 5.491 5.491 0 010-6.136.5.5 0 00-.268-.758l-.121-.035a.5.5 0 01-.277-.771l1.022-1.445a1 1 0 01.816-.423H10.7a.247.247 0 01.222.136.251.251 0 01-.019.26c-2.4 3.353-3.4 4.3-3.4 6.1a4 4 0 005.169 3.826.5.5 0 00.268-.758 5.491 5.491 0 010-6.136.5.5 0 00-.268-.758l-.121-.035a.5.5 0 01-.277-.771l1.022-1.445a1 1 0 01.816-.423H16.7a.247.247 0 01.222.136.251.251 0 01-.019.26c-2.4 3.353-3.4 4.3-3.4 6.1a4 4 0 105.048-3.861.5.5 0 01-.277-.771l1.021-1.445a1 1 0 01.817-.423H23a1 1 0 001-1V12a1 1 0 00-1-1zm-13-.5a.5.5 0 01-.5.5H6.22a.25.25 0 01-.151-.449L9.6 7.877a.25.25 0 01.4.2zm8.017.33a.25.25 0 01-.237.17H14.5a.5.5 0 01-.5-.5V8.076a.25.25 0 01.4-.2l3.53 2.674a.25.25 0 01.087.28z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});