define("ember-svg-jar/inlined/smiley-shook-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-shook-alternate</title><path d=\"M9.5 12a1 1 0 00.707-1.707L9.091 9.177a.25.25 0 010-.354l1.116-1.116a1 1 0 10-1.414-1.414L7.677 7.409a.25.25 0 01-.354 0L6.207 6.293a1 1 0 00-1.414 1.414l1.116 1.116a.25.25 0 010 .354l-1.116 1.116a1 1 0 101.414 1.414l1.116-1.116a.25.25 0 01.354 0l1.116 1.116A1 1 0 009.5 12zM18.707 6.293a1 1 0 00-1.414 0l-1.116 1.116a.25.25 0 01-.354 0l-1.116-1.116a1 1 0 00-1.414 1.414l1.116 1.116a.25.25 0 010 .354l-1.116 1.116a1 1 0 101.414 1.414l1.116-1.116a.25.25 0 01.354 0l1.116 1.116a1 1 0 001.414-1.414l-1.116-1.116a.25.25 0 010-.354l1.116-1.116a1 1 0 000-1.414z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M12 13a2.5 2.5 0 00-2.5 2.5v2a2.5 2.5 0 005 0v-2A2.5 2.5 0 0012 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});