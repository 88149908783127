define("ember-svg-jar/inlined/list-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>list-edit</title><path d=\"M7.415 12.531L6.04 14.249a.139.139 0 01-.228 0l-.781-.781A.75.75 0 103.97 14.53l1.5 1.5a.75.75 0 00.53.219h.041a.75.75 0 00.545-.28l2-2.5a.75.75 0 00-1.172-.937zM8.469 6.914a.75.75 0 00-1.054.117L6.04 8.749a.139.139 0 01-.228 0l-.781-.781A.75.75 0 103.97 9.03l1.5 1.5a.75.75 0 00.53.219h.041a.75.75 0 00.545-.28l2-2.5a.75.75 0 00-.117-1.055zM13.751 9.5a.75.75 0 00-.75-.75H10.5a.75.75 0 000 1.5H13a.75.75 0 00.751-.75zM10.5 14.249a.75.75 0 000 1.5H12a.75.75 0 000-1.5z\"/><path d=\"M18 7.249v8.328a.249.249 0 00.424.179c.488-.478 1.255-1.227 1.5-1.458a.235.235 0 00.076-.177V6.413a1 1 0 00-.293-.707L14.294.292A1 1 0 0013.587 0H2a2 2 0 00-2 2v20a2 2 0 002 2h11.313a.251.251 0 00.248-.248c.012-.39.1-1.081.159-1.468a.249.249 0 00-.248-.284H2.5a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.249z\"/><path d=\"M23.435 15.559a1.911 1.911 0 00-2.7 0l-5.087 5.087a.506.506 0 00-.137.255l-.5 2.5a.5.5 0 00.491.6.531.531 0 00.1-.009l2.5-.5a.5.5 0 00.256-.137l5.087-5.087a1.913 1.913 0 00-.01-2.709z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});