define("ember-svg-jar/inlined/water-protection-faucet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-protection-faucet</title><path d=\"M21.983 18.623a1 1 0 00-.876.518c-1.141 2.078-1.141 2.652-1.141 2.841a2.018 2.018 0 104.035 0c0-.189 0-.764-1.142-2.841a1 1 0 00-.876-.518zM15.5 7a.5.5 0 00-.5.5v5a.5.5 0 00.5.5h2a1.5 1.5 0 011.5 1.5V16a1 1 0 001 1h3a1 1 0 001-1v-1.5C24 7.73 18.056 7 15.5 7z\"/><rect y=\"7\" width=\"4\" height=\"6\" rx=\".5\" ry=\".5\"/><path d=\"M11.757 4.1a.343.343 0 01-.257-.333V3.75a.5.5 0 01.5-.5h4.75a1 1 0 000-2h-5A.25.25 0 0111.5 1a1 1 0 00-1-1h-2a1 1 0 00-1 1 .25.25 0 01-.25.25h-5a1 1 0 000 2H7a.5.5 0 01.5.5.363.363 0 01-.27.35A3.007 3.007 0 005 7v6a3 3 0 003 3h3a3 3 0 003-3V7a3.006 3.006 0 00-2.243-2.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});