define("ember-svg-jar/inlined/bowling-set", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bowling-set</title><path d=\"M2.758 6.846a.5.5 0 00.49.4H6.7a.5.5 0 00.491-.4l.063-.329C7.627 4.488 8.175 0 4.974 0S2.322 4.492 2.7 6.524c.015.103.037.211.058.322zM6.678 8.75H3.27a.5.5 0 00-.466.32c-.887 2.3-5.922 8.048.142 14.588a1.066 1.066 0 00.782.342h2.493A1.066 1.066 0 007 23.658c5.942-6.409 1.2-11.84.142-14.587a.5.5 0 00-.464-.321z\"/><path d=\"M16 8a7.978 7.978 0 00-5.944 2.646.5.5 0 00-.08.55c1.317 2.753 2.318 6.27.486 10.1a.5.5 0 00.111.583A8 8 0 1016 8zm-2.134 3.733a1.067 1.067 0 111.067 1.067 1.067 1.067 0 01-1.067-1.067zM16 17.066A1.066 1.066 0 1117.066 16 1.067 1.067 0 0116 17.066zm3.2-3.2a1.067 1.067 0 111.066-1.066 1.067 1.067 0 01-1.066 1.067z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});