define("ember-svg-jar/inlined/smiley-silent-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-silent-alternate</title><path d=\"M16.313 12.919a1 1 0 00-1.394-.232l-2.774 1.981a.249.249 0 01-.29 0l-2.774-1.981a1 1 0 00-1.162 1.626L9.995 15.8a.249.249 0 010 .406l-2.076 1.481a1 1 0 101.162 1.626l2.774-1.981a.249.249 0 01.29 0l2.774 1.981a1 1 0 001.162-1.626L14.005 16.2a.249.249 0 010-.406l2.076-1.484a1 1 0 00.232-1.391z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><circle cx=\"7.5\" cy=\"9\" r=\"2\"/><circle cx=\"16.5\" cy=\"9\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});