define("@gavant/ember-shopify-draggable/mixins/base-container", ["exports", "@ember/object/mixin", "@ember/object"], function (_exports, _mixin, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _mixin.default.create({
    setupContainer() {
      if ((0, _object.get)(this, 'group.shopifyInstance')) {
        (0, _object.get)(this, 'group.shopifyInstance').addContainer(this.element);
      }
    },
    destroyContainer() {
      if ((0, _object.get)(this, 'group.shopifyInstance')) {
        (0, _object.get)(this, 'group.shopifyInstance').removeContainer(this.element);
      }
    },
    init() {
      this._super(...arguments);
      if ((0, _object.get)(this, 'group')) {
        (0, _object.get)(this, 'group').on('setupContainers', this, 'setupContainer');
      }
    },
    willDestroyElement() {
      if ((0, _object.get)(this, 'group')) {
        (0, _object.get)(this, 'group').off('setupContainers', this, 'setupContainer');
      }
      this.destroyContainer();
      this._super(...arguments);
    }
  });
});