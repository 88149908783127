define("ember-svg-jar/inlined/social-media-odnoklassniki", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-odnoklassniki</title><path d=\"M12.19 12.24a6.12 6.12 0 10-6.13-6.12 6.13 6.13 0 006.13 6.12zm0-8.62a2.5 2.5 0 11-2.5 2.5 2.5 2.5 0 012.5-2.5z\"/><path d=\"M16 17.21a11 11 0 003.12-1.75 1.9 1.9 0 00.71-1.38 2 2 0 00-.56-1.52l-.12-.12a2 2 0 00-2.68-.14 7 7 0 01-8.61 0 2 2 0 00-2.67.13l-.13.13a2 2 0 00-.57 1.51 1.92 1.92 0 00.7 1.38 11 11 0 003.21 1.78l-2.72 2.71A2.37 2.37 0 109 23.3l3.18-3.18 3.22 3.18a2.37 2.37 0 103.36-3.36z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});