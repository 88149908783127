define("ember-svg-jar/inlined/diagram-arrow-dash-down-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-arrow-dash-down-1</title><path d=\"M9 14.958a.249.249 0 01-.25.25H5.5a1 1 0 00-.733 1.681l6.16 6.634a1.46 1.46 0 002.146 0l6.16-6.634a1 1 0 00-.733-1.681h-3.25a.249.249 0 01-.25-.25v-4.951a1 1 0 00-1-1h-4a1 1 0 00-1 1z\"/><rect x=\"9\" y=\"4.507\" width=\"6\" height=\"3\" rx=\"1\" ry=\"1\"/><rect x=\"9\" y=\".007\" width=\"6\" height=\"3\" rx=\"1\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});