define("ember-svg-jar/inlined/data-file-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-clock</title><path d=\"M6.75 24A6.75 6.75 0 100 17.25 6.758 6.758 0 006.75 24zm0-11.5A4.75 4.75 0 112 17.25a4.756 4.756 0 014.75-4.75z\"/><path d=\"M6.75 18.25h2a1 1 0 000-2h-.818a.176.176 0 01-.182-.2v-1.3a1 1 0 00-2 0v2.5a1 1 0 001 1zM21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.275a.248.248 0 00.242.25c.366.007 1.038.034 1.485.082A.245.245 0 008 9.359V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.245.245 0 00-.241.21 7.956 7.956 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.414A2 2 0 0023.414 3z\"/><path d=\"M12.5 11.8l1.438-2.03a.25.25 0 01.332-.071l1.507.905A1 1 0 0017 10.451a.9.9 0 00.105-.123l2.688-3.734a1 1 0 10-1.624-1.168l-2.015 2.8a.252.252 0 01-.332.068L14.3 7.387a1 1 0 00-1.33.28l-2.042 2.884A.243.243 0 0011 10.9a8.06 8.06 0 011.128.932.243.243 0 00.372-.032z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});