define("ember-svg-jar/inlined/real-estate-action-house-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-house-star</title><path d=\"M10.424 18.282l-.02-.018a2.816 2.816 0 01-.7-3.077 2.741 2.741 0 012.555-1.687h1.351a.5.5 0 00.459-.3l.884-2.047.013-.028A2.815 2.815 0 0117.5 9.5a2.779 2.779 0 012.482 1.51l.014.031.392.906a.318.318 0 00.61-.127.318.318 0 01.319-.318H23.5a.5.5 0 00.344-.864L13.029.409a1.5 1.5 0 00-2.059 0L.157 10.636A.5.5 0 00.5 11.5h2a.5.5 0 01.5.5v10a1 1 0 001 1h6.347a.5.5 0 00.5-.466 2.786 2.786 0 01.225-.923l.718-1.651a.5.5 0 00-.126-.573z\"/><path d=\"M23.909 15.76A1.232 1.232 0 0022.74 15H20.4a.5.5 0 01-.459-.3l-1.275-2.97-.009-.02-.01-.02a1.293 1.293 0 00-2.3.022.249.249 0 00-.016.033L15.055 14.7a.5.5 0 01-.459.3h-2.337a1.229 1.229 0 00-1.166.76 1.268 1.268 0 00.307 1.384l.023.021 2.048 1.826a.5.5 0 01.126.572l-1.151 2.647a1.255 1.255 0 00.315 1.451 1.28 1.28 0 001.506.166l2.989-1.683a.5.5 0 01.491 0l2.989 1.682a1.275 1.275 0 001.505-.166 1.254 1.254 0 00.316-1.45l-1.152-2.65a.5.5 0 01.126-.572l2.069-1.841.024-.022a1.262 1.262 0 00.285-1.365z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});