define("ember-svg-jar/inlined/duck-water", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>duck-water</title><path d=\"M3.248 18.88a2.5 2.5 0 013.825.511 2.111 2.111 0 001.486.874 2.8 2.8 0 001.889-1.016 2.5 2.5 0 014.287.389 1.279 1.279 0 001.051.627 1.863 1.863 0 001.476-.846 2.418 2.418 0 012.138-1.207 2.511 2.511 0 011.2.306 6.47 6.47 0 00.867-3.891A8.525 8.525 0 0019.209 9.3a8.634 8.634 0 01-.6-.765c-.912-1.368-.758-2.617-.336-2.8 1.168.907 2.942 2.1 4.158 2.1a1.341 1.341 0 00.589-.121 1.3 1.3 0 00.711-1.27c0-1.111-1.971-3.7-3.146-5.147a.8.8 0 00-.076-.078A3.392 3.392 0 0017.983.2a4.377 4.377 0 00-3.859 1.87c-1.782 2.631-.607 5.911-.08 7.447a.5.5 0 01-.471.663l-5.594.02a12.75 12.75 0 01-2.671-.273c.247 1.3 1.145 4.273 4.171 4.273h3.5a.75.75 0 010 1.5h-3.5c-4.035 0-5.511-3.879-5.747-6.171a.521.521 0 010-.055c-.423-.153-.78-.307-1.078-.434A2.19 2.19 0 001.3 8.721C.385 8.942.234 10.6.226 11.566a10.015 10.015 0 003.022 7.314zM17.979 2.073a1 1 0 11-1 1 1 1 0 011-1z\"/><path d=\"M24 22.682a1.018 1.018 0 00-1.068-.929c-1.583.135-2.667-1.555-2.675-1.569a1.047 1.047 0 00-1.716.023 3.375 3.375 0 01-2.753 1.557 2.8 2.8 0 01-2.4-1.481 1.044 1.044 0 00-1.717-.157 4.3 4.3 0 01-3.105 1.638 3.554 3.554 0 01-2.766-1.58 1.042 1.042 0 00-1.638-.091c-.014.018-1.487 1.812-3.11 1.7A.979.979 0 000 22.742c-.01.187.042 1.057 1.168 1.057a5.833 5.833 0 003.726-1.607 5.242 5.242 0 003.664 1.572 5.858 5.858 0 003.811-1.5 4.576 4.576 0 003.416 1.5 5.153 5.153 0 003.622-1.5 4.78 4.78 0 003.661 1.483A1 1 0 0024 22.682z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});