define("ember-svg-jar/inlined/adventure-car", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>adventure-car</title><path d=\"M23 8.791a1 1 0 00-1 1v4a1 1 0 002 0v-4a1 1 0 00-1-1z\"/><circle cx=\"4\" cy=\"17.291\" r=\"2\"/><circle cx=\"17\" cy=\"17.291\" r=\"2\"/><path d=\"M21 13.791v-3.25a.25.25 0 00-.25-.25H14.5a1.5 1.5 0 00-1.5 1.5v2a.5.5 0 01-.5.5h-2.146a2.5 2.5 0 01-2.236-1.382L6.88 10.433a.236.236 0 01-.015-.186C7.077 9.624 8.183 6 8.183 6a1 1 0 00-1.916-.574L5.159 9.113a.249.249 0 01-.239.178H1.5a1.5 1.5 0 00-1.5 1.5v5a1.491 1.491 0 00.735 1.283c.086.052.281-.022.289-.1a3 3 0 015.967.079.246.246 0 00.243.235h6.532a.246.246 0 00.243-.235 3 3 0 015.979-.033c.008.088.13.268.512.268a1.5 1.5 0 001.5-1.5v-.278a1.992 1.992 0 01-1-1.719z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});