define("ember-svg-jar/inlined/legal-scale.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>legal-scale</title><path d=\"M16.316 21.169A1.387 1.387 0 0015 20.22H9a1.387 1.387 0 00-1.316.949l-.465 1.393a.5.5 0 00.475.658h8.612a.5.5 0 00.475-.658zM22.842 12.392a.5.5 0 00-.378-.172H20.25a.25.25 0 01-.25-.25v-3.5a.25.25 0 01.25-.25H21a1 1 0 100-2h-7.75a.25.25 0 01-.25-.25v-.75a.825.825 0 00-.025-.194.252.252 0 01.069-.238A1.481 1.481 0 0013.5 3.72a9.337 9.337 0 00-1.056-2.67.5.5 0 00-.888 0A9.367 9.367 0 0010.5 3.72a1.48 1.48 0 00.456 1.068.253.253 0 01.069.238.832.832 0 00-.025.194v.75a.25.25 0 01-.25.25H3a1 1 0 100 2h.75a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25H1.536a.5.5 0 00-.378.172.5.5 0 00-.118.4 4 4 0 007.92 0 .5.5 0 00-.5-.571H6.25A.25.25 0 016 11.97v-3.5a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25v7.8a.252.252 0 01-.136.223A2.5 2.5 0 009.5 18.72a.5.5 0 00.5.5h4a.5.5 0 00.5-.5 2.5 2.5 0 00-1.364-2.22.252.252 0 01-.136-.226V8.47a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25h-2.458a.282.282 0 00-.281.3 4 4 0 007.949.269.5.5 0 00-.118-.397z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});