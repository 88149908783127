define("ember-svg-jar/inlined/history-chinese-lantern-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-chinese-lantern-1</title><path d=\"M14.5 8.75h6a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5H19a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v5.25a.5.5 0 01-.5.5h-1.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5zM21 19.75h-7a.5.5 0 00-.5.5v1a.5.5 0 00.25.433.5.5 0 01.25.433v1.134a.75.75 0 001.5 0v-1a.5.5 0 01.5-.5h.25a.5.5 0 01.5.5v1a.75.75 0 001.5 0v-1a.5.5 0 01.5-.5H19a.5.5 0 01.5.5v1a.75.75 0 001.5 0v-1.134a.5.5 0 01.25-.433.5.5 0 00.25-.433v-1a.5.5 0 00-.5-.5zM2.5 5.75h4a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5H6a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v2.25a.5.5 0 01-.5.5h-.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5zM7 19.75H2a.5.5 0 00-.5.5v1a.5.5 0 00.25.433.5.5 0 01.25.433v1.134a.75.75 0 001.5 0v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.75.75 0 001.5 0v-1.134a.5.5 0 01.25-.433.5.5 0 00.25-.433v-1a.5.5 0 00-.5-.5zM17.311 10.1a4.472 4.472 0 000 8.3.5.5 0 00.378 0 4.472 4.472 0 000-8.3.5.5 0 00-.378 0z\"/><path d=\"M19.5 9.75a.117.117 0 00-.073.207 5.482 5.482 0 010 8.586.117.117 0 00.073.207 4.5 4.5 0 000-9zM15.609 18.673a.117.117 0 00-.036-.13 5.482 5.482 0 010-8.586.117.117 0 00-.073-.207 4.5 4.5 0 000 9 .115.115 0 00.109-.077z\"/><rect y=\"10.75\" width=\"9\" height=\"4\" rx=\".5\" ry=\".5\"/><path d=\"M.687 9.75h7.626a.5.5 0 00.472-.667A3.49 3.49 0 005.5 6.75h-2A3.49 3.49 0 00.215 9.083a.5.5 0 00.472.667zM8.313 15.75H.687a.5.5 0 00-.472.667A3.49 3.49 0 003.5 18.75h2a3.49 3.49 0 003.285-2.333.5.5 0 00-.472-.667z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});