define("ember-svg-jar/inlined/chocolate-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chocolate-bar</title><path d=\"M5.352 4.95H10.8a.5.5 0 00.5-.5V.5a.5.5 0 00-.5-.5H6.6a1.752 1.752 0 00-1.75 1.75v2.7a.5.5 0 00.502.5zM13.4 4.95h5.45a.5.5 0 00.5-.5v-2.7A1.752 1.752 0 0017.6 0h-4.2a.5.5 0 00-.5.5v3.95a.5.5 0 00.5.5zM13.4 11.5h5.2a.75.75 0 00.75-.75v-3.7a.5.5 0 00-.5-.5H13.4a.5.5 0 00-.5.5V11a.5.5 0 00.5.5zM5.6 11.5h5.2a.5.5 0 00.5-.5V7.05a.5.5 0 00-.5-.5H5.352a.5.5 0 00-.5.5v3.7a.75.75 0 00.748.75zM19.689 19.87l-15-2.647a.5.5 0 00-.587.492V22a2 2 0 002 2h12a2 2 0 002-2v-1.638a.5.5 0 00-.413-.492zM20.805 16.466l-.552-2.76a1.5 1.5 0 00-1.471-1.206H4.6a1 1 0 00-.932.636l-.436 1.117A1 1 0 003.99 15.6l15.084 2.637a1.5 1.5 0 001.731-1.772z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});