define("ember-svg-jar/inlined/presentation-projector-screen-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>presentation-projector-screen-chat</title><path d=\"M8.251 13.5a.253.253 0 01.046.289l-.869 1.734a.5.5 0 00.447.725.506.506 0 00.211-.047l2.623-1.219a.244.244 0 01.166-.016 6.6 6.6 0 001.625.2c3.032 0 5.5-2.054 5.5-4.578s-2.468-4.58-5.5-4.58S7 8.065 7 10.591A4.1 4.1 0 008.251 13.5z\"/><path d=\"M24 1.75a1.5 1.5 0 00-1.5-1.5h-21a1.5 1.5 0 00-.214 2.984.25.25 0 01.214.248v14.543a.251.251 0 01-.2.245 1 1 0 00.2 1.98H11a.249.249 0 01.25.25v.314a.249.249 0 01-.107.2 1.5 1.5 0 101.714 0 .249.249 0 01-.107-.2V20.5a.249.249 0 01.25-.25h9.5a1 1 0 00.2-1.98.249.249 0 01-.2-.245V3.482a.251.251 0 01.214-.248A1.5 1.5 0 0024 1.75zM20.5 18a.25.25 0 01-.25.25H3.75A.25.25 0 013.5 18V3.5a.249.249 0 01.25-.25h16.5a.249.249 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});