define("ember-svg-jar/inlined/meeting-camera-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-camera-circle</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm5.5 14.723a.5.5 0 01-.771.419l-1.843-1.194a.25.25 0 00-.386.21V15.5a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5v-7A.5.5 0 017 8h7a.5.5 0 01.5.5v1.342a.251.251 0 00.386.21l1.843-1.194a.5.5 0 01.51-.02.5.5 0 01.261.439z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});