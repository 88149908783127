define("ember-svg-jar/inlined/earth-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earth-refresh</title><path d=\"M22.553 18.709a.981.981 0 00-1.245.61 4.008 4.008 0 01-6.6 1.581.491.491 0 01-.006-.7l1.517-1.516a.491.491 0 00-.346-.837H11.51a.49.49 0 00-.49.49v4.368a.49.49 0 00.836.347l.775-.776a.491.491 0 01.69 0 5.963 5.963 0 009.842-2.32.98.98 0 00-.61-1.247z\"/><path d=\"M9.006 23.125a.434.434 0 00.544-.419v-1.1a.491.491 0 00-.35-.47 9.775 9.775 0 01-1.61-.636v-1.279a2.445 2.445 0 01.875-1.878A4.41 4.41 0 005.63 9.555H2.46a9.783 9.783 0 0115.068-5.88h-3.323a2.7 2.7 0 000 5.39 2.54 2.54 0 011.849.82 7.511 7.511 0 011.186-.1 7.426 7.426 0 014.234 1.331.321.321 0 00.41-.037 1.947 1.947 0 011.386-.574.376.376 0 00.371-.428A11.761 11.761 0 107.435 22.6l.155.078v-.021a11.691 11.691 0 001.416.468z\"/><path d=\"M23.76 12.47a.491.491 0 00-.837-.347l-.831.832a.491.491 0 01-.688.005 5.973 5.973 0 00-9.828 2.347.98.98 0 001.855.634A4.007 4.007 0 0120 14.335a.49.49 0 01.01.7l-1.455 1.454a.491.491 0 00.347.837h4.368a.491.491 0 00.49-.49z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});