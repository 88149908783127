define("ember-svg-jar/inlined/style-three-pin-boat-skating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-boat-skating</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM6 12.251a.75.75 0 01-.181-1.477l1.422-.355a.5.5 0 00.338-.682A4.035 4.035 0 017.6 6.6a.5.5 0 00-.089-.526A1.968 1.968 0 017 4.751 2 2 0 119.422 6.7a.5.5 0 00-.338.257 2.516 2.516 0 00.107 2.633.5.5 0 00.539.21l3.087-.772a.75.75 0 01.361 1.456l-7 1.75a.763.763 0 01-.178.017zM17 15.5a3.627 3.627 0 01-2.192-.693.5.5 0 00-.617 0 3.809 3.809 0 01-4.383 0 .5.5 0 00-.617 0A3.621 3.621 0 017 15.5a.5.5 0 010-1c.827 0 1.812-.354 2.018-1.129a.52.52 0 01.966 0c.206.775 1.19 1.129 2.016 1.129s1.812-.354 2.018-1.129a.52.52 0 01.966 0c.206.775 1.191 1.129 2.016 1.129a.5.5 0 110 1zm3.75-8.249A.75.75 0 0120 8h-8a.75.75 0 010-1.5h8a.75.75 0 01.75.751z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});