define("ember-svg-jar/inlined/seo-search-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seo-search-page</title><path d=\"M9.25 17.5H3a1 1 0 01-1-1v-9a.5.5 0 01.5-.5H19a.5.5 0 01.5.5v2.25a1 1 0 002 0V3a3 3 0 00-3-3H3a3 3 0 00-3 3v13.5a3 3 0 003 3h6.25a1 1 0 000-2zm.75-15a1 1 0 11-1 1 1 1 0 011-1zm-3 0a1 1 0 11-1 1 1 1 0 011-1zm-3 0a1 1 0 11-1 1 1 1 0 011-1z\"/><path d=\"M21.674 20.26a.25.25 0 01-.034-.311 5.5 5.5 0 10-1.691 1.692.251.251 0 01.311.033l2.033 2.034a1 1 0 101.414-1.415zM17 20.5a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5zM10.528 9.059l-3.41 3.558a.25.25 0 01-.361 0l-.535-.558a1 1 0 10-1.444 1.383l1.438 1.5a1 1 0 001.443 0l4.313-4.5a1 1 0 10-1.444-1.383z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});