define("ember-svg-jar/inlined/iris-scan-smartphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>iris-scan-smartphone</title><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/><circle cx=\"12\" cy=\"11\" r=\"1\"/><path d=\"M12 7.5c-2.682 0-4.916 2.73-5.4 3.368a.218.218 0 000 .264c.486.639 2.72 3.368 5.4 3.368s4.916-2.729 5.4-3.368a.216.216 0 000-.264C16.916 10.229 14.682 7.5 12 7.5zm0 5.5a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});