define("ember-svg-jar/inlined/pl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pl</title><path d=\"M10 9.626h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H10a.875.875 0 000-1.75z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM9.875 12.626h-.5a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0V9a.625.625 0 01.625-.624H10a2.128 2.128 0 012.114 2.347 2.213 2.213 0 01-2.239 1.903zm6.625 3h-2A1.627 1.627 0 0112.875 14V9a.625.625 0 111.25 0v5a.376.376 0 00.375.375h2a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});