define("ember-svg-jar/inlined/monitor-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-upload</title><path d=\"M22.5 6.5a2 2 0 00-2-2h-1.583a1 1 0 00-1 1 1 1 0 001 1h1.333a.25.25 0 01.25.25v10a.25.25 0 01-.25.25H3.75a.25.25 0 01-.25-.25v-10a.25.25 0 01.25-.25h1.167a1 1 0 001-1 1 1 0 00-1-1H3.5a2 2 0 00-2 2v12a2 2 0 002 2h7.25a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-5a1 1 0 000 2h12.5a1 1 0 000-2h-5a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h7.25a2 2 0 002-2z\"/><path d=\"M7.493 4.883a1 1 0 00.924.617h1.75a.25.25 0 01.25.25V11a1.5 1.5 0 003 0V5.75a.249.249 0 01.25-.25h1.75a1 1 0 00.707-1.707l-3.5-3.5a1 1 0 00-1.415 0l-3.5 3.5a1 1 0 00-.216 1.09z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});