define("ember-svg-jar/inlined/insect-cricket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>insect-cricket</title><path d=\"M23.689 8.513l-4.443-5.627a2.252 2.252 0 00-3.474.2l-1.67 2.3a4.314 4.314 0 00-4.2 0l-1.671-2.3a2.251 2.251 0 00-3.494-.179L.311 8.513a1.25 1.25 0 001.878 1.651l4-5.092a.25.25 0 01.4.007l1.416 1.953a.247.247 0 01.021.26 4.807 4.807 0 00-.156.343.25.25 0 00.189.341 3.259 3.259 0 01.213 6.384.252.252 0 00-.172.34 4.248 4.248 0 00.339.64.251.251 0 01-.007.28 4.245 4.245 0 00-.888 3.111 4.589 4.589 0 001.9 2.941 1 1 0 001.38-.274 1 1 0 00-.264-1.387 2.642 2.642 0 01-1.04-1.58 2.172 2.172 0 01.38-1.42.25.25 0 01.322-.094 3.982 3.982 0 003.564 0 .25.25 0 01.322.094 2.172 2.172 0 01.375 1.419 2.642 2.642 0 01-1.039 1.58 1 1 0 001.115 1.661 4.589 4.589 0 001.9-2.941 4.245 4.245 0 00-.888-3.111.251.251 0 01-.007-.28 4.145 4.145 0 00.338-.64.25.25 0 00-.008-.209.253.253 0 00-.164-.13 3.259 3.259 0 01.222-6.386.25.25 0 00.189-.341 4.04 4.04 0 00-.159-.348.251.251 0 01.018-.261l1.415-1.944a.251.251 0 01.4-.008l4 5.092a1.25 1.25 0 001.878-1.651z\"/><circle cx=\"7.5\" cy=\"11.188\" r=\"1.776\"/><circle cx=\"16.5\" cy=\"11.188\" r=\"1.776\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});