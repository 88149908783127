define("ember-svg-jar/inlined/single-neutral-actions-graduate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-graduate</title><path d=\"M.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.56a.25.25 0 00.25-.25v-.25a2 2 0 011.212-1.838l.136-.059a.249.249 0 00.15-.229V13.75a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M11 20.714a.75.75 0 001.5 0v-2.153a.249.249 0 01.333-.235l4.5 1.588a.5.5 0 00.167.028.5.5 0 00.166-.028l6-2.118a.5.5 0 00.019-.936l-6-2.382a.5.5 0 00-.37 0l-6 2.382a.5.5 0 00-.315.474z\"/><path d=\"M21.1 22.667a.5.5 0 00.334-.472V20.05a.5.5 0 00-.667-.471L17.5 20.73l-3.262-1.151a.5.5 0 00-.667.471v2.15a.5.5 0 00.334.472l2.572.908a3.075 3.075 0 002.045 0zM.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.56a.25.25 0 00.25-.25v-.25a2 2 0 011.212-1.838l.136-.059a.249.249 0 00.15-.229V13.75a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M11 20.714a.75.75 0 001.5 0v-2.153a.249.249 0 01.333-.235l4.5 1.588a.5.5 0 00.167.028.5.5 0 00.166-.028l6-2.118a.5.5 0 00.019-.936l-6-2.382a.5.5 0 00-.37 0l-6 2.382a.5.5 0 00-.315.474z\"/><path d=\"M21.1 22.667a.5.5 0 00.334-.472V20.05a.5.5 0 00-.667-.471L17.5 20.73l-3.262-1.151a.5.5 0 00-.667.471v2.15a.5.5 0 00.334.472l2.572.908a3.075 3.075 0 002.045 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});