define("ember-svg-jar/inlined/wifi-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-clock</title><path d=\"M17.447 10.642a6.569 6.569 0 106.569 6.569 6.576 6.576 0 00-6.569-6.569zm-4.568 6.569a4.569 4.569 0 114.568 4.568 4.574 4.574 0 01-4.568-4.568z\"/><path d=\"M19.385 16.211H18.7a.25.25 0 01-.25-.25V14.79a1 1 0 10-2 0v2.421a1 1 0 001 1h1.938a1 1 0 100-2zM9.563 6.326a7.616 7.616 0 015.42 2.246A1 1 0 1016.4 7.158a9.6 9.6 0 00-6.837-2.832 9.606 9.606 0 00-6.834 2.832 1 1 0 101.415 1.414 7.615 7.615 0 015.418-2.246z\"/><path d=\"M17.434 5.472a1 1 0 001.414-1.414 13.152 13.152 0 00-18.571 0 1 1 0 101.414 1.414 11.149 11.149 0 0115.743 0zM13.294 10.906a1.014 1.014 0 00-.271-1.388 6.206 6.206 0 00-7.844.761 1 1 0 101.414 1.414 4.208 4.208 0 015.313-.517 1.024 1.024 0 001.388-.27z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});