define("ember-svg-jar/inlined/vide-document-mp4-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vide-document-mp4-1</title><path d=\"M11.5 12.125h-1.125a.25.25 0 00-.25.25v2.25a.25.25 0 00.25.25H11.5a1.375 1.375 0 000-2.75zM19.572 12.225a4.38 4.38 0 00-3.447 4.275v.124a.251.251 0 00.251.251h3.249a.25.25 0 00.25-.25v-4.148a.256.256 0 00-.303-.252z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM8.125 19.5a.625.625 0 01-1.25 0v-4.939c0-.126-.169-.076-.209.005l-.607 1.213a.625.625 0 01-1.118 0s-.568-1.109-.607-1.214-.209-.137-.209-.012V19.5a.625.625 0 01-1.25 0v-8a.625.625 0 011.184-.279l1.217 2.435a.251.251 0 00.448 0l1.217-2.435a.625.625 0 011.184.279zm3.275-3.375h-1.025a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0v-8a.625.625 0 01.625-.625h2a2.629 2.629 0 012.574 3.149 2.7 2.7 0 01-2.674 2.101zm9.725 3.375a.625.625 0 01-1.25 0v-1.125a.25.25 0 00-.25-.25H15.5a.625.625 0 01-.625-.625v-1a5.631 5.631 0 015.605-5.625.633.633 0 01.645.625z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});