define("ember-svg-jar/inlined/tags-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-search</title><path d=\"M19.1 8.412A3.979 3.979 0 0020.086 6V2a2 2 0 00-2-2l-4.258.005a3.95 3.95 0 00-2.416 1L.586 11.834a2 2 0 000 2.828L5.422 19.5a1.977 1.977 0 001.406.581h.012a1.975 1.975 0 001.41-.6l.892-.91a.491.491 0 00.124-.46 6.726 6.726 0 01.5-4.546 7.116 7.116 0 012.663-2.953 6.665 6.665 0 015.12-.958.5.5 0 00.461-.134zM12.586 5.5a2 2 0 112 2 2 2 0 01-2-2z\"/><path d=\"M10.578 16.528a5.523 5.523 0 008.5 4.66.25.25 0 01.311.034l2.484 2.484a1 1 0 101.414-1.414L20.8 19.808a.25.25 0 01-.033-.312 5.528 5.528 0 10-10.19-2.968zm9.059 0A3.53 3.53 0 1116.107 13a3.534 3.534 0 013.53 3.528z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});