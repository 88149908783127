define("ember-svg-jar/inlined/real-estate-favorite-building-choose", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-favorite-building-choose</title><path d=\"M6.724 6.053l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 000 6.5V14a.5.5 0 00.5.5H2a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5A.5.5 0 007 14V6.5a.5.5 0 00-.276-.447zM5 10.5H2a.5.5 0 010-1h3a.5.5 0 010 1zM5 8H2a.5.5 0 010-1h3a.5.5 0 010 1zM8.5 14.5h3.008a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5H10a.5.5 0 010-1h2.152a.5.5 0 00.41-.214 2.992 2.992 0 012.012-1.253.5.5 0 00.426-.495V3a.5.5 0 00-.308-.462l-6-2.5A.5.5 0 008 .5V14a.5.5 0 00.5.5zm1.5-10h3a.5.5 0 010 1h-3a.5.5 0 010-1zM10 7h3a.5.5 0 010 1h-3a.5.5 0 010-1z\"/><path d=\"M24 21.5a4.505 4.505 0 00-4.5-4.5h-2a.5.5 0 01-.5-.5V11a2 2 0 00-4 0v7.061a.5.5 0 01-.884.319l-.578-.694a2 2 0 00-3.1 2.526l.015.02L11.1 23.8a.5.5 0 00.4.2h12a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});