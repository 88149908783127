define("ember-svg-jar/inlined/single-man-actions-mail.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-mail</title><path d=\"M10.965 14.166a.255.255 0 00.045-.147A5.443 5.443 0 00.124 14.1v3.464a.494.494 0 00.5.5H2.41a.246.246 0 01.246.227l.444 5.255a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h1.544a.247.247 0 00.247-.248v-2.226a2.5 2.5 0 01.45-1.422z\"/><path d=\"M1.361 4.206A4.206 4.206 0 105.567 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM13.485 24h8.907a1.486 1.486 0 001.484-1.485V18.32a.246.246 0 00-.388-.2l-4.365 3.036-.01.007a2.238 2.238 0 01-2.349 0l-.011-.007-4.365-3.036a.247.247 0 00-.388.2v4.2A1.486 1.486 0 0013.485 24z\"/><path d=\"M17.54 19.9l.011.007a.749.749 0 00.775 0l.01-.007 5.5-3.749a.071.071 0 00.033-.061 1.486 1.486 0 00-1.477-1.49h-8.907A1.486 1.486 0 0012 16.082a.074.074 0 00.033.062h.005zM10.965 14.166a.255.255 0 00.045-.147A5.443 5.443 0 00.124 14.1v3.464a.494.494 0 00.5.5H2.41a.246.246 0 01.246.227l.444 5.255a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h1.544a.247.247 0 00.247-.248v-2.226a2.5 2.5 0 01.45-1.422z\"/><path d=\"M1.361 4.206A4.206 4.206 0 105.567 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM13.485 24h8.907a1.486 1.486 0 001.484-1.485V18.32a.246.246 0 00-.388-.2l-4.365 3.036-.01.007a2.238 2.238 0 01-2.349 0l-.011-.007-4.365-3.036a.247.247 0 00-.388.2v4.2A1.486 1.486 0 0013.485 24z\"/><path d=\"M17.54 19.9l.011.007a.749.749 0 00.775 0l.01-.007 5.5-3.749a.071.071 0 00.033-.061 1.486 1.486 0 00-1.477-1.49h-8.907A1.486 1.486 0 0012 16.082a.074.074 0 00.033.062h.005z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});