define("ember-svg-jar/inlined/database-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-settings</title><path d=\"M9.046 21.174a8.447 8.447 0 01-1.062-3.03.244.244 0 00-.206-.206 12.617 12.617 0 01-5.986-2.192.25.25 0 00-.408.194v1.716c0 1.91 3.178 3.5 7.425 3.9a.253.253 0 00.237-.382zM1.384 13.156c0 1.769 2.727 3.265 6.5 3.794v-.058a8.452 8.452 0 01.573-3.064.241.241 0 00-.2-.331 13.12 13.12 0 01-6.468-2.251.249.249 0 00-.407.194zM17.709 8.5a8.4 8.4 0 012.283.7.244.244 0 00.346-.163 13.46 13.46 0 00.046-2.1.25.25 0 00-.408-.193 8.529 8.529 0 01-2.318 1.28.246.246 0 00.051.476zM1.384 8.656c0 1.925 3.229 3.53 7.529 3.912a.252.252 0 00.238-.12 8.586 8.586 0 012.9-2.861.245.245 0 00-.129-.459c-4.107.189-8.109-.743-10.127-2.383a.249.249 0 00-.407.194z\"/><ellipse cx=\"10.885\" cy=\"4.156\" rx=\"9.5\" ry=\"4\"/><circle cx=\"16.364\" cy=\"16.875\" r=\"1.5\"/><path d=\"M22.367 13.385a1.749 1.749 0 00-1.935-.84l-1.411.327a.451.451 0 01-.533-.311l-.424-1.392a1.776 1.776 0 00-3.4 0l-.423 1.392a.454.454 0 01-.534.311l-1.41-.327A1.783 1.783 0 0010.6 15.5l.987 1.065a.462.462 0 010 .626l-.987 1.062a1.783 1.783 0 001.7 2.953l1.412-.327a.45.45 0 01.533.311l.423 1.392a1.776 1.776 0 003.4 0l.423-1.392a.448.448 0 01.534-.311l1.411.327a1.783 1.783 0 001.7-2.953l-.988-1.064a.464.464 0 010-.626l.988-1.065a1.757 1.757 0 00.231-2.113zm-9 3.49a3 3 0 113 3 3 3 0 01-3.003-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});