define("ember-svg-jar/inlined/phone-actions-add-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-add-1</title><path d=\"M15.5 15.783a3.116 3.116 0 00-4.4 0l-.827.828a4.249 4.249 0 00-1.346 4.5 11.357 11.357 0 01-6.032-6.031 4.249 4.249 0 004.5-1.346l.83-.829a3.116 3.116 0 000-4.4l-3.59-3.582a2.232 2.232 0 00-3.649.739 13.313 13.313 0 0017.357 17.354 2.233 2.233 0 00.737-3.648zM17.5 0A6.5 6.5 0 1024 6.5 6.508 6.508 0 0017.5 0zM20 7.25h-1.5a.25.25 0 00-.25.25V9a.75.75 0 01-1.5 0V7.5a.25.25 0 00-.25-.25H15a.75.75 0 010-1.5h1.5a.25.25 0 00.25-.25V4a.75.75 0 011.5 0v1.5a.25.25 0 00.25.25H20a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});