define("ember-svg-jar/inlined/folder-disable-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-disable-1-alternate</title><path d=\"M12.075 7.5a6 6 0 106 6 6.007 6.007 0 00-6-6zM8.48 15.219a3.873 3.873 0 01-.247-2.819 4.05 4.05 0 012.738-2.74 3.863 3.863 0 012.823.245.252.252 0 01.059.4l-4.97 4.969a.253.253 0 01-.403-.055zm7.437-.62a4.049 4.049 0 01-2.74 2.742 3.866 3.866 0 01-2.822-.247.252.252 0 01-.058-.4l4.97-4.97a.252.252 0 01.4.059 3.865 3.865 0 01.25 2.817z\"/><path d=\"M24 6.3a1.95 1.95 0 00-1.95-1.95H12a.5.5 0 01-.447-.277l-.747-1.494A1.949 1.949 0 009.061 1.5H1.95A1.95 1.95 0 000 3.45v17.1a1.95 1.95 0 001.95 1.95h20.1A1.95 1.95 0 0024 20.55zM22.048 20a.5.5 0 01-.5.5L2.5 20.549a.5.5 0 01-.5-.5L1.952 4a.5.5 0 01.5-.5l6.256-.025a.5.5 0 01.449.277l.761 1.523A1.941 1.941 0 0011.66 6.35l9.84-.05a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});