define("ember-svg-jar/inlined/insurance-hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>insurance-hand</title><path d=\"M24 6.125A1.127 1.127 0 0022.875 5H19.5a.5.5 0 01-.5-.5V1.125A1.127 1.127 0 0017.875 0h-3.75A1.127 1.127 0 0013 1.125V4.5a.5.5 0 01-.5.5H9.125A1.127 1.127 0 008 6.125v3.75A1.127 1.127 0 009.125 11H12.5a.5.5 0 01.5.5v3.375A1.127 1.127 0 0014.125 16h3.75A1.127 1.127 0 0019 14.875V11.5a.5.5 0 01.5-.5h3.375A1.127 1.127 0 0024 9.875zM1 14.75a1 1 0 00-1 1V23a1 1 0 002 0v-7.25a1 1 0 00-1-1zM15.5 19.5H6.375a.625.625 0 010-1.25H8.9a1 1 0 00.92-1.394A2.234 2.234 0 007.764 15.5H3.5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.764a2.238 2.238 0 002.236-2.236V20.5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});