define("ember-svg-jar/inlined/ceiling-lamp-double", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ceiling-lamp-double</title><path d=\"M20.817 17.755a.489.489 0 00.489-.49V9.429a1.96 1.96 0 00-1.959-1.96h-1.959a1.96 1.96 0 00-1.959 1.96v7.836a.49.49 0 00.49.49zM18.367 20.2a.979.979 0 00-.979.98v.979a.98.98 0 101.959 0v-.979a.98.98 0 00-.98-.98zM21.83 19.343a.98.98 0 00-1.385 1.386l.658.658A.98.98 0 0022.489 20zM14.9 19.343l-.654.657a.98.98 0 001.385 1.386l.659-.658a.98.98 0 00-1.39-1.385zM8.082 17.755a.49.49 0 00.49-.49V9.429a1.961 1.961 0 00-1.96-1.96H4.653a1.96 1.96 0 00-1.959 1.96v7.836a.49.49 0 00.49.49zM5.633 20.2a.98.98 0 00-.98.98v.979a.98.98 0 101.959 0v-.979a.979.979 0 00-.979-.98zM2.17 19.343L1.511 20A.98.98 0 002.9 21.387l.658-.658a.98.98 0 00-1.388-1.386zM9.1 19.343a.98.98 0 00-1.39 1.386l.659.658A.98.98 0 009.754 20zM24 1.837a.982.982 0 00-.98-.98H.98a.98.98 0 100 1.959h3.183a.49.49 0 01.49.49v2.2a.736.736 0 00.735.735h.49a.735.735 0 00.734-.735v-2.2a.491.491 0 01.49-.49h9.8a.491.491 0 01.49.49v2.2a.735.735 0 00.734.735h.49a.736.736 0 00.735-.735v-2.2a.49.49 0 01.49-.49h3.179a.981.981 0 00.98-.979z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});