define("ember-svg-jar/inlined/lock-password-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lock-password-1</title><path d=\"M19.5 9.5h-.75V6.75a6.75 6.75 0 00-13.5 0V9.5H4.5a2 2 0 00-2 2V22a2 2 0 002 2h15a2 2 0 002-2V11.5a2 2 0 00-2-2zm-4 8.5a1 1 0 111-1 1 1 0 01-1 1zm1 2.5a1 1 0 11-1-1 1 1 0 011 1zm-1-6a1 1 0 111-1 1 1 0 01-1 1zM12 18a1 1 0 111-1 1 1 0 01-1 1zm1 2.5a1 1 0 11-1-1 1 1 0 011 1zm-1-6a1 1 0 111-1 1 1 0 01-1 1zM8.5 18a1 1 0 111-1 1 1 0 01-1 1zm1 2.5a1 1 0 11-1-1 1 1 0 011 1zm-1-6a1 1 0 111-1 1 1 0 01-1 1zm-.75-7.75a4.25 4.25 0 018.5 0V9a.5.5 0 01-.5.5h-7.5a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});