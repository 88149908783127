define("ember-svg-jar/inlined/database-check-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-check-alternate</title><path d=\"M11.267.006C6.232.006.884 1.739.872 4.952v12.62c0 2.637 3.594 4.277 7.664 4.784a.984.984 0 001.038-1.372l-.01-.022a1.021 1.021 0 00-.8-.583C5.1 19.933 2.87 18.567 2.87 17.572v-.759a.25.25 0 01.372-.218 14.067 14.067 0 004.471 1.436.934.934 0 001.087-.873v-.085a.933.933 0 00-.762-1c-3.231-.527-5.174-1.775-5.174-2.7v-.763a.25.25 0 01.124-.216.247.247 0 01.248 0A15.473 15.473 0 009.028 14a.932.932 0 00.883-.473l.028-.046a.96.96 0 00-.726-1.467C5.285 11.634 2.87 10.2 2.87 9.165v-.759a.25.25 0 01.372-.218 17.418 17.418 0 008.025 1.73 17.415 17.415 0 008.025-1.731.251.251 0 01.373.215 5.407 5.407 0 01-.025.9 1.054 1.054 0 00.539 1.17l.026.013a1.008 1.008 0 001.456-.906V4.962C21.662 1.743 16.306.006 11.267.006zm8.394 4.956v.006c-.01 1.2-3.278 2.95-8.394 2.95s-8.4-1.751-8.4-2.956 3.271-2.956 8.4-2.956 8.39 1.748 8.4 2.953z\"/><path d=\"M22.966 16.1a6.435 6.435 0 10-7.739 7.735 6.392 6.392 0 007.739-7.735zm-3.027.191l-2.873 3.832a1.482 1.482 0 01-1.083.589h-.111a1.472 1.472 0 01-1.043-.436L13.345 18.8a.742.742 0 011.049-1.049l1.282 1.281a.248.248 0 00.2.073.251.251 0 00.182-.1l2.7-3.6a.742.742 0 111.186.891z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});