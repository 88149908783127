define("ember-svg-jar/inlined/messages-people-person-bubble-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-person-bubble-circle</title><path d=\"M16.5 0a7.676 7.676 0 00-6.162 3.007 1 1 0 001.6 1.195A5.673 5.673 0 0116.5 2 5.274 5.274 0 0122 7a5.006 5.006 0 01-3.358 4.605 1 1 0 00-.32.2c-.2.184-2.068 1.773-2.324 1.991v-.858a1 1 0 00-.861-1 5.75 5.75 0 01-.787-.166 1 1 0 10-.55 1.928l.2.054v2.25a1 1 0 001.707.707c.268-.267 3.543-3.054 3.828-3.305A6.973 6.973 0 0024 7a7.271 7.271 0 00-7.5-7z\"/><circle cx=\"13.116\" cy=\"6.5\" r=\"1\"/><circle cx=\"16.366\" cy=\"6.5\" r=\"1\"/><circle cx=\"19.616\" cy=\"6.5\" r=\"1\"/><path d=\"M11.5 17.329l-.63-.229a2.236 2.236 0 01-.08-1.608 5.621 5.621 0 001.466-4.315C12.256 8.755 10.677 7 8.5 7s-3.756 1.755-3.756 4.174A5.6 5.6 0 006.2 15.47a2.2 2.2 0 01-.067 1.63l-.632.233c-2.446.9-4.214 1.549-4.753 2.626A9.17 9.17 0 000 23.5a.5.5 0 00.5.5h16a.5.5 0 00.5-.5 9.17 9.17 0 00-.748-3.545c-.539-1.077-2.307-1.727-4.752-2.626z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});