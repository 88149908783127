define("@gavant/ember-shopify-draggable/components/swappable-container", ["exports", "@ember/component", "@gavant/ember-shopify-draggable/templates/components/swappable-container", "@ember/object", "@ember/utils", "@ember/array", "@ember/runloop", "@gavant/ember-shopify-draggable/mixins/base-container"], function (_exports, _component, _swappableContainer, _object, _utils, _array, _runloop, _baseContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_baseContainer.default, {
    layout: _swappableContainer.default,
    classNames: ['swappable-container'],
    _dragStop() {
      (0, _runloop.next)(this, () => {
        const scheduleReplaceEvent = (0, _object.get)(this, 'scheduleReplace');
        if (scheduleReplaceEvent) {
          const items = (0, _object.get)(scheduleReplaceEvent, 'items');
          items.replace((0, _object.get)(scheduleReplaceEvent, 'index'), 1, [(0, _object.get)(scheduleReplaceEvent, 'item')]);
          (0, _utils.tryInvoke)(this, 'itemAdded', [items, (0, _object.get)(scheduleReplaceEvent, 'item'), event]);
          (0, _object.trySet)(this, 'scheduleReplace', null);
        }
        (0, _object.trySet)(this, 'group.dragItem', null);
        (0, _object.trySet)(this, 'group.swappedItem', null);
      });
    },
    _swappableStop(event) {
      if (this.element) {
        const items = (0, _array.A)((0, _object.get)(this, 'items').toArray());
        const targetContainer = (0, _object.get)(this, 'group.swappedItem.container');
        const oldContainer = (0, _object.get)(event, 'data.dragEvent.data.sourceContainer');
        const itemMovedIndex = (0, _object.get)(this, 'group.dragItem.index');
        const itemMoved = (0, _object.get)(this, 'group.dragItem.item');
        const itemSwappedIndex = (0, _object.get)(this, 'group.swappedItem.index');
        const itemSwapped = (0, _object.get)(this, 'group.swappedItem.item');
        //Sorted within this container
        if (this.element.isSameNode(targetContainer) && this.element.isSameNode(oldContainer)) {
          items.replace(itemSwappedIndex, 1, [itemMoved]);
          items.replace(itemMovedIndex, 1, [itemSwapped]);
          (0, _utils.tryInvoke)(this, 'itemReordered', [items, itemMoved, event]);
        }
        // else if (this.element.isSameNode(targetContainer)) { // added to this container
        //     set(this, 'scheduleReplace', {
        //         items,
        //         index: itemSwappedIndex,
        //         item: itemMoved
        //     });
        // } else if (this.element.isSameNode(oldContainer)) { // removed from this container
        //     set(this, 'scheduleReplace', {
        //         items,
        //         index: itemMovedIndex,
        //         item: itemSwapped
        //     });
        // }
      }
    },
    init() {
      this._super(...arguments);
      if ((0, _object.get)(this, 'group')) {
        (0, _object.get)(this, 'group').on('drag:stop', this, '_dragStop');
        (0, _object.get)(this, 'group').on('swappable:stop', this, '_swappableStop');
      }
    },
    willDestroyElement() {
      if ((0, _object.get)(this, 'group')) {
        (0, _object.get)(this, 'group').off('drag:stop', this, '_dragStop');
        (0, _object.get)(this, 'group').off('swappable:stop', this, '_swappableStop');
      }
      this._super(...arguments);
    },
    actions: {
      dragStart(item, index) {
        (0, _object.trySet)(this, 'group.dragItem', {
          item,
          index
        });
      },
      swapped(item, index, container) {
        (0, _object.trySet)(this, 'group.swappedItem', {
          item,
          index,
          container
        });
      }
    }
  }).reopenClass({
    positionalParams: ['items']
  });
});