define("ember-svg-jar/inlined/decoration-clock-retro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>decoration-clock-retro</title><path d=\"M12 7.25h1.5a.75.75 0 100-1.5H13a.25.25 0 01-.25-.25V5a.75.75 0 00-1.5 0v1.5a.75.75 0 00.75.75z\"/><path d=\"M22.53 6.15C13.25.31 13.04.22 12.93.18A2.482 2.482 0 0012 0a2.4 2.4 0 00-.92.18c-.11.04-.31.12-9.61 5.97a1.011 1.011 0 00-.43 1.13A.987.987 0 002 8h1a.5.5 0 01.5.5v13a.5.5 0 01-.5.5 1 1 0 100 2h18a1 1 0 000-2 .5.5 0 01-.5-.5v-13A.5.5 0 0121 8h1a.987.987 0 00.96-.72 1.011 1.011 0 00-.43-1.13zM12 3a3.5 3.5 0 11-3.5 3.5A3.5 3.5 0 0112 3zm4.5 18a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5v-9a.5.5 0 01.5-.5h3v4.78A1.968 1.968 0 0010 18a2 2 0 004 0 1.968 1.968 0 00-1-1.72V11.5h3a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});