define("ember-svg-jar/inlined/transportation-ticket-train-transfer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transportation-ticket-train-transfer</title><path d=\"M8.073 10.868L9.26 12.45a.75.75 0 001.2-.9l-.8-1.059a.252.252 0 01.013-.317 1.722 1.722 0 00.446-1.155v-6a1.753 1.753 0 00-1.75-1.75h-6a1.752 1.752 0 00-1.75 1.75v6a1.726 1.726 0 00.444 1.155.25.25 0 01.014.317l-.788 1.055a.75.75 0 001.2.9l1.185-1.579a.249.249 0 01.2-.1h5a.249.249 0 01.199.101zm-5.949-4.85v-3a.253.253 0 01.25-.25h6a.253.253 0 01.25.25v3a.25.25 0 01-.25.25h-6a.249.249 0 01-.25-.25zm1.25 3a.75.75 0 11.75-.75.75.75 0 01-.75.75zm3.25-.75a.75.75 0 11.75.75.75.75 0 01-.75-.75z\"/><path d=\"M11.125 17.25a4.956 4.956 0 01-4.179-2.272.247.247 0 01-.034-.191.252.252 0 01.112-.158.722.722 0 00.293-.342.75.75 0 00-.162-.818l-1.5-1.5a.75.75 0 00-1.061 0l-1.5 1.5a.751.751 0 00.531 1.281h.813a.251.251 0 01.232.155 6.94 6.94 0 006.455 4.345 1 1 0 000-2zM13.526 9.162l2.119-.1a.749.749 0 00.715-.783l-.095-2.119a.75.75 0 00-1.3-.472L14.678 6a.25.25 0 01-.3.052 6.905 6.905 0 00-2.041-.7 1 1 0 00-.345 1.971 4.581 4.581 0 01.868.245.209.209 0 01.079.337.75.75 0 00.554 1.256zM23.711 21.532l-.795-1.061a.249.249 0 01.013-.316A1.724 1.724 0 0023.375 19v-6a1.753 1.753 0 00-1.75-1.75h-6a1.752 1.752 0 00-1.75 1.75v6a1.724 1.724 0 00.445 1.155.249.249 0 01.013.316l-.793 1.058a.75.75 0 101.2.9l1.185-1.579a.249.249 0 01.2-.1h5a.249.249 0 01.2.1l1.187 1.582a.75.75 0 001.2-.9zM16.625 19a.75.75 0 11.75-.75.75.75 0 01-.75.75zm4 0a.75.75 0 11.75-.75.75.75 0 01-.75.75zm1.25-3a.25.25 0 01-.25.25h-6a.249.249 0 01-.25-.25v-3a.253.253 0 01.25-.25h6a.253.253 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});