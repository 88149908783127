define("ember-svg-jar/inlined/phone-selfie-front-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-selfie-front-1</title><path d=\"M19 3a1 1 0 000 2 1 1 0 011 1v2.25a.25.25 0 01-.25.25H4.25A.25.25 0 014 8.25V6a1 1 0 011-1 1 1 0 000-2 3 3 0 00-3 3v17a1 1 0 002 0V10.25a.25.25 0 01.25-.25h15.5a.25.25 0 01.25.25V23a1 1 0 002 0V6a3 3 0 00-3-3z\"/><circle cx=\"12\" cy=\"5.5\" r=\"1.5\"/><path d=\"M12 3a.75.75 0 00.75-.75V.75a.75.75 0 00-1.5 0v1.5A.75.75 0 0012 3zM9.172 3.732a.75.75 0 001.06-1.06l-1.06-1.061a.75.75 0 00-1.061 1.061zM7.25 6.25h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5zM14.828 3.732l1.061-1.06a.75.75 0 00-1.061-1.061l-1.06 1.061a.75.75 0 001.06 1.06zM14.5 5.5a.75.75 0 00.75.75h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 00-.75.75zM13.919 20.582c-.608-.226-.509-1.813-.239-2.11a4.493 4.493 0 001.169-3.482A2.74 2.74 0 0012 12a2.74 2.74 0 00-2.849 2.99 4.493 4.493 0 001.169 3.482c.27.3.369 1.884-.239 2.11-2.251.834-4.043 1.429-4.472 2.287a5.378 5.378 0 00-.323.806.251.251 0 00.239.325h12.95a.251.251 0 00.239-.325 5.378 5.378 0 00-.323-.806c-.429-.858-2.221-1.453-4.472-2.287z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});