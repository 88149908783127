define("ember-svg-jar/inlined/road-tunnel-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-tunnel-1</title><path d=\"M8.186 17.415l-.545 1.5a.247.247 0 00.03.228.25.25 0 00.205.107h8.248a.25.25 0 00.205-.107.247.247 0 00.03-.228l-.545-1.5a.25.25 0 00-.235-.165H8.421a.25.25 0 00-.235.165zM9.33 15.25h5.34a.25.25 0 00.235-.335l-.728-2a.248.248 0 00-.234-.165h-3.886a.248.248 0 00-.234.165l-.728 2a.25.25 0 00.235.335z\"/><path d=\"M12 0A12.021 12.021 0 00.25 12.25v11A.75.75 0 001 24h2.488a.25.25 0 00.235-.165l4.262-11.72A.255.255 0 008 12.029v-2.7C8 6.677 9.682 4.75 12 4.75s4 1.927 4 4.583v2.7a.255.255 0 00.015.086l4.262 11.72a.25.25 0 00.235.165H23a.75.75 0 00.75-.75v-11A12.021 12.021 0 0012 0z\"/><path d=\"M17.268 21.415a.25.25 0 00-.235-.165H6.967a.25.25 0 00-.235.165l-.818 2.25a.25.25 0 00.234.335h11.7a.25.25 0 00.234-.335z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});