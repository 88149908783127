define("ember-svg-jar/inlined/bag-carry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bag-carry</title><path d=\"M21.5 21.25a2.5 2.5 0 01-2.5-2.5v-7.5a1.73 1.73 0 00-.35-1.049.751.751 0 011.2-.9 3.23 3.23 0 01.65 1.951v7.5a1 1 0 002 0v-7.509a5.262 5.262 0 00-3.062-4.77.75.75 0 11.625-1.363A6.763 6.763 0 0124 11.243v7.507a2.5 2.5 0 01-2.5 2.5zM16.5 12h-2.985a.25.25 0 01-.237-.329 4.447 4.447 0 00.2-.947.25.25 0 01.249-.224H16.5a1 1 0 001-1V5.25a2.5 2.5 0 00-2.5-2.5h-3.5a.5.5 0 00-.5.5v4.566a.5.5 0 00.142.349A2.97 2.97 0 0112 10.25a3 3 0 01-6 0 2.97 2.97 0 01.858-2.085A.5.5 0 007 7.816V3.25a.5.5 0 00-.5-.5h-4A2.5 2.5 0 000 5.25V9.5a1 1 0 001 1h3.283a.251.251 0 01.249.224 4.447 4.447 0 00.2.947A.25.25 0 014.5 12H1a2.775 2.775 0 01-.687-.087.246.246 0 00-.216.044.25.25 0 00-.1.2v2.1a2.5 2.5 0 002.5 2.5H15a2.5 2.5 0 002.5-2.5v-2.1a.25.25 0 00-.313-.242A2.775 2.775 0 0116.5 12z\"/><circle cx=\"9.007\" cy=\"10.25\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});