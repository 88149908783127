define("ember-svg-jar/inlined/smart-watch-square-text-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-text-alternate</title><path d=\"M21 6a3 3 0 00-3-3h-1.078a.249.249 0 01-.24-.182L15.98.365A.5.5 0 0015.5 0h-7a.5.5 0 00-.481.363l-.7 2.455A.248.248 0 017.077 3H6a3 3 0 00-3 3v12a3 3 0 003 3h1.077a.249.249 0 01.24.181l.7 2.456A.5.5 0 008.5 24h7a.5.5 0 00.48-.363l.7-2.456a.25.25 0 01.24-.181H18a3 3 0 003-3zm-2 12a1 1 0 01-1 1H6a1 1 0 01-1-1V6a1 1 0 011-1h12a1 1 0 011 1z\"/><path d=\"M16.5 9.085a1.5 1.5 0 00-1.5-1.5H9a1.5 1.5 0 00-1.5 1.5v4a1.5 1.5 0 001.5 1.5h.25a.25.25 0 01.25.25v1.25a.5.5 0 00.812.391l2.3-1.836a.251.251 0 01.156-.055H15a1.5 1.5 0 001.5-1.5zm-6.5 3.5a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.25a.5.5 0 010-1h4a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});