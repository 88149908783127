define("ember-svg-jar/inlined/image-file-png-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-png-1</title><path d=\"M24 6.414a1 1 0 00-.293-.707L18.293.293A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.75V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2z\"/><path d=\"M4 12.125h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H4a.875.875 0 000-1.75z\"/><path d=\"M17 20a1 1 0 001-1v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1zM3.875 15.125h-.5a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-6a.625.625 0 01.625-.625H4a2.128 2.128 0 012.114 2.347 2.213 2.213 0 01-2.239 1.903zm7.25 2.375a.624.624 0 01-.481.608.644.644 0 01-.144.017.627.627 0 01-.559-.346l-1.552-3.1c-.067-.134-.264-.189-.264 0V17.5a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279s1.489 2.989 1.554 3.108.262.16.262-.02V11.5a.625.625 0 011.25 0zm5-4a.625.625 0 01-1.25 0V13a.875.875 0 00-1.75 0v3a.876.876 0 001.68.345c.029-.07.06-.22-.1-.22H14.5a.625.625 0 010-1.25h1a.625.625 0 01.625.625v.5a2.125 2.125 0 01-4.25 0v-3a2.125 2.125 0 014.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});