define("ember-svg-jar/inlined/toilet-seat-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toilet-seat-1</title><path d=\"M18.5 0h-13A2.5 2.5 0 003 2.5V3a1 1 0 001 1h.3a.251.251 0 01.25.232l.282 3.958a.5.5 0 00.631.447A3.926 3.926 0 016.5 8.5h11a3.913 3.913 0 011.036.137.5.5 0 00.632-.447l.285-3.958A.25.25 0 0119.7 4h.3a1 1 0 001-1v-.5A2.5 2.5 0 0018.5 0zM17.7 15.5H6.3a.5.5 0 00-.448.722 4.991 4.991 0 002.1 2.168 2.015 2.015 0 011.02 2.12l-.53 2.9a.467.467 0 00.11.41.494.494 0 00.38.18h6.14a.494.494 0 00.38-.18.467.467 0 00.11-.41l-.53-2.9a2.015 2.015 0 011.02-2.12 4.991 4.991 0 002.1-2.168.5.5 0 00-.452-.722zM17.5 10h-11A2.5 2.5 0 004 12.5v1a.5.5 0 00.5.5h15a.5.5 0 00.5-.5v-1a2.5 2.5 0 00-2.5-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});