define("ember-svg-jar/inlined/phone-actions-record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-record</title><path d=\"M17.166 16.178a2.5 2.5 0 00-3.536 0l-.385.385a47.029 47.029 0 01-5.789-5.788l.386-.386a2.507 2.507 0 000-3.535L5.719 4.733a2.5 2.5 0 00-3.535 0L1.021 5.9a3.506 3.506 0 00-.443 4.4 46.893 46.893 0 0013.141 13.141 3.579 3.579 0 004.4-.443l1.164-1.162a2.5 2.5 0 000-3.535zM17.505 0A6.5 6.5 0 1024 6.5 6.5 6.5 0 0017.505 0zm0 10.99A4.5 4.5 0 1122 6.5a4.5 4.5 0 01-4.495 4.49z\"/><circle cx=\"17.505\" cy=\"6.496\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});