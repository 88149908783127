define("ember-svg-jar/inlined/pet-tracking-dog-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pet-tracking-dog-search</title><path d=\"M18.664 9.842c.06.02.11.04.16.06l.08-.149A2.478 2.478 0 0121.052 8.5 2.945 2.945 0 0024 5.558a2.048 2.048 0 00-2.039-2.048 1.041 1.041 0 01-.939-.579A2.557 2.557 0 0019 1.527a.5.5 0 01-.363-.22L17.925.234a.52.52 0 00-.449-.219.5.5 0 00-.419.279l-2.008 4.015a3.171 3.171 0 01-.471.829.5.5 0 00.041.659zm.592-6.331a.749.749 0 11-.749.749.749.749 0 01.749-.749zM15.223 9.629a.5.5 0 00.264-.844L13.205 6.51a.5.5 0 00-.484-.129 3.508 3.508 0 01-.918.126H5.021a3 3 0 00-.739.1.5.5 0 01-.431-.091C1.658 4.8 2.015 1.162 2.025 1.123A1 1 0 00.037.9c-.019.183-.426 4.156 1.989 6.645a.5.5 0 01.064.615 3.353 3.353 0 00-.534 1.829l-.029 1.774a.505.505 0 01-.052.216l-1.4 2.794a.545.545 0 00-.05.22v2.747a1.748 1.748 0 003.5 0v-1.635a1.553 1.553 0 01.25-.839l1.069-1.6A1.5 1.5 0 016.09 13h4.093a.5.5 0 00.422-.234 6.945 6.945 0 014.618-3.137zM23.634 21.855l-2.327-2.327a.251.251 0 01-.04-.3A5.442 5.442 0 0022 16.5a5.493 5.493 0 10-5.493 5.494 5.442 5.442 0 002.731-.736.25.25 0 01.3.04l2.328 2.327a1.248 1.248 0 001.765-1.765zm-7.125-1.864a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});