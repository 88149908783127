define("ember-svg-jar/inlined/chair-modern", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chair-modern</title><path d=\"M20.746 13.932l-.641-1.5a1.5 1.5 0 00-1.379-.912h-4.781A3.5 3.5 0 0110.6 9.04L8.4 1.793A2.5 2.5 0 006 .02H4.134a1 1 0 00-.555 1.832l.776.518a1.5 1.5 0 01.666 1.189l.314 8.134a4.457 4.457 0 001.554 3.213.244.244 0 01.071.273l-2.909 7.479a1 1 0 00.569 1.294 3.62 3.62 0 001.133-.039l6.117-3.094a.248.248 0 01.225 0l6.117 3.094a3.619 3.619 0 001.133.039 1 1 0 00.569-1.294l-2.449-6.3a.25.25 0 01.233-.341h1.668a1.5 1.5 0 001.38-2.088zM6.951 20.717l.713-1.832a.25.25 0 01.345-.133l1.317.666a.25.25 0 010 .446s-2.032 1.018-2.169 1.1-.27-.082-.206-.247zm9.889.26l-2.2-1.113a.25.25 0 010-.446l1.317-.666a.25.25 0 01.345.133s.681 1.777.736 1.892a.15.15 0 01-.198.2zm-1.553-4.128L12.1 18.463a.248.248 0 01-.225 0s-3.138-1.584-3.22-1.628a.2.2 0 01-.1-.235l.2-.524c.04-.1.219-.145.295-.131a4.262 4.262 0 00.78.075h5.131a.345.345 0 01.295.178l.113.291c.047.12.019.311-.082.36z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});