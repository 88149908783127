define("ember-svg-jar/inlined/data-file-bars-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-bars-refresh</title><rect x=\"14.5\" y=\"5.5\" width=\"2\" height=\"8\" rx=\".5\" ry=\".5\"/><rect x=\"18\" y=\"7.5\" width=\"2\" height=\"6\" rx=\".5\" ry=\".5\"/><path d=\"M21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.8a.246.246 0 00.23.25c.35.019 1.016.068 1.487.14A.248.248 0 008 9.939V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-7.374a.243.243 0 00-.239.2 7.183 7.183 0 01-.408 1.454.244.244 0 00.225.34H22a2 2 0 002-2V4.414A2 2 0 0023.414 3z\"/><path d=\"M7.918 16.125a.5.5 0 00.354.854h4.457a.5.5 0 00.5-.5v-4.458a.5.5 0 00-.854-.353l-1.3 1.3a.247.247 0 01-.343 0 6.312 6.312 0 00-4.078-1.716A6.465 6.465 0 000 17.546 6.375 6.375 0 0011.357 21.6 1 1 0 109.8 20.354a4.376 4.376 0 11-3.42-7.1 4.336 4.336 0 012.92 1.118.256.256 0 01.005.367z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});