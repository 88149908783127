define("ember-svg-jar/inlined/walking-descend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>walking-descend</title><path d=\"M.012 19.6a1 1 0 00.843 1.14l22 3.249A1.039 1.039 0 0023 24a1 1 0 00.145-1.989l-22-3.25a1.006 1.006 0 00-1.133.839zM13.205 5A2.5 2.5 0 0015.057.828a2.479 2.479 0 00-1.726-.822 2.422 2.422 0 00-1.8.64 2.5 2.5 0 00-.183 3.53A2.481 2.481 0 0013.071 5h.134zM8.01 6.373l-.154.067a6.667 6.667 0 00-3.818 4.5 1.5 1.5 0 001.084 1.823 1.425 1.425 0 00.293.045 1.5 1.5 0 001.53-1.129A3.686 3.686 0 019.054 9.19l.155-.068.06-.025a.25.25 0 01.335.3l-1.557 5.379a2.225 2.225 0 01-.166.467.26.26 0 01-.123.1l-1.783.7A1.5 1.5 0 107.383 18.7l1.827-.721a4.034 4.034 0 001.719-2.368l.065-.226a.252.252 0 01.153-.165.246.246 0 01.222.024l1.168.749a.493.493 0 01.224.376l.059 2.3a1.5 1.5 0 001.415 1.368 1.631 1.631 0 00.209 0 1.5 1.5 0 001.365-1.624l-.059-2.3a3.473 3.473 0 00-1.592-2.638l-2.044-1.313a.248.248 0 01-.105-.279l.461-1.592a.25.25 0 01.444-.075 2.61 2.61 0 01.217.364 3.452 3.452 0 002.4 1.886l2.15.46a1.5 1.5 0 10.626-2.934l-2.15-.459a.482.482 0 01-.331-.259 6.013 6.013 0 00-7.821-2.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});