define("ember-svg-jar/inlined/smiley-wrong-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-wrong-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M7.25 10a.25.25 0 01.25.25V11a1 1 0 002 0v-.75a.25.25 0 01.25-.25h.75a1 1 0 000-2h-.75a.25.25 0 01-.25-.25V7a1 1 0 00-2 0v.75a.25.25 0 01-.25.25H6.5a1 1 0 000 2zM17.5 8h-.75a.25.25 0 01-.25-.25V7a1 1 0 00-2 0v.75a.25.25 0 01-.25.25h-.75a1 1 0 000 2h.75a.25.25 0 01.25.25V11a1 1 0 002 0v-.75a.25.25 0 01.25-.25h.75a1 1 0 000-2zM17.573 15.181a1 1 0 00-1.392.246c-1.153 1.645-1.84 1.34-3.657.221-1.614-.993-4.053-2.493-6.343.779a1 1 0 001.638 1.146c1.154-1.645 1.841-1.34 3.657-.221a6.379 6.379 0 003.185 1.221 3.855 3.855 0 003.158-2 1 1 0 00-.246-1.392z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});