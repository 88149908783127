define("ember-svg-jar/inlined/real-estate-location-house-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-location-house-pin</title><path d=\"M6.749 10a.5.5 0 00.386-.183C7.48 9.4 10.5 5.669 10.5 3.75a3.75 3.75 0 00-7.5 0C3 5.669 6.019 9.4 6.363 9.817a.5.5 0 00.386.183zM5 3.75A1.75 1.75 0 116.749 5.5 1.75 1.75 0 015 3.75zM23.822 17.618l-6.5-5.5a.5.5 0 00-.647 0l-3.451 2.921a.5.5 0 000 .763l1.244 1.053A1.5 1.5 0 0113.5 19.5a.5.5 0 00-.5.5v3.5a.5.5 0 00.5.5H15a.5.5 0 00.5-.5v-2.25a1.5 1.5 0 113 0v2.25a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5V19a.5.5 0 01.5-.5h1a.5.5 0 00.322-.882z\"/><path d=\"M13.824 17.618l-6.5-5.5a.5.5 0 00-.647 0l-6.5 5.5A.5.5 0 00.5 18.5h1a.5.5 0 01.5.5v4.5a.5.5 0 00.5.5H5a.5.5 0 00.5-.5v-2.25a1.5 1.5 0 113 0v2.25a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5V19a.5.5 0 01.5-.5h1a.5.5 0 00.324-.882z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});