define("ember-svg-jar/inlined/gardening-tools", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gardening-tools</title><path d=\"M9.267 0a1 1 0 00-1 1v5.5a3 3 0 01-1.2 2.4.5.5 0 01-.8-.4V1a1 1 0 00-2 0v7.5a.5.5 0 01-.8.4 3 3 0 01-1.2-2.4V1a1 1 0 00-2 0v5.5A5.009 5.009 0 003.9 11.311a.5.5 0 01.363.481V15a.5.5 0 01-.2.4 2 2 0 00-.8 1.6v5a2 2 0 004 0v-5a1.993 1.993 0 00-.8-1.6.5.5 0 01-.2-.4v-3.208a.5.5 0 01.364-.481 5.01 5.01 0 003.64-4.811V1a1 1 0 00-1-1zM23.73 10.943C23.52 8.376 22.508 0 18.267 0s-5.238 8.378-5.443 10.947a.972.972 0 00.272.748 1.016 1.016 0 00.726.3h8.911a1.01 1.01 0 00.726-.307.969.969 0 00.271-.745zM19.467 15.4a.5.5 0 01-.2-.4v-1.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5V15a.5.5 0 01-.2.4 2 2 0 00-.8 1.6v5a2 2 0 004 0v-5a2 2 0 00-.8-1.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});