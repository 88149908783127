define("ember-svg-jar/inlined/phone-action-camera-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-camera-1</title><path d=\"M12 11.75v6.75a1 1 0 01-1 1H4a1 1 0 01-1-1v-11a1 1 0 011-1h4.25a.25.25 0 00.25-.25v-2A.25.25 0 008.25 4H3.5A2.5 2.5 0 001 6.5v15A2.5 2.5 0 003.5 24h8a2.5 2.5 0 002.5-2.5v-9.75a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25z\"/><path d=\"M22.805 1.6a.5.5 0 00-.434-.087l-3.121.837V1.5a1.5 1.5 0 00-1.5-1.5H11.5A1.5 1.5 0 0010 1.5v7a1.5 1.5 0 001.5 1.5h6.25a1.5 1.5 0 001.5-1.5v-.85l3.121.833A.5.5 0 0023 8V2a.5.5 0 00-.195-.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});