define("ember-svg-jar/inlined/reflect-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>reflect-right</title><path d=\"M12 8a1 1 0 00-1 1v14a1 1 0 002 0V9a1 1 0 00-1-1zM2.061 8.646A1.175 1.175 0 000 9.5v13a1.175 1.175 0 002.061.854l6.293-6.293a1.5 1.5 0 000-2.122zm.366 11.513A.25.25 0 012 19.982v-7.964a.25.25 0 01.427-.177l3.982 3.982a.25.25 0 010 .354zM21.939 8.646l-6.293 6.293a1.5 1.5 0 000 2.122l6.293 6.293A1.174 1.174 0 0024 22.5v-13a1.173 1.173 0 00-2.061-.854z\"/><path d=\"M18.4 7.447l1.614-3.223A1 1 0 0018.893 2.8l-1.393.317a.245.245 0 01-.254-.1A7 7 0 004.5 7a1 1 0 002 0 5.006 5.006 0 015-5 4.948 4.948 0 013.364 1.326.243.243 0 01-.11.415l-.762.173a1 1 0 00-.318 1.817l3.286 2.111a1.024 1.024 0 001.44-.395z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});