define("ember-svg-jar/inlined/folder-music-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-music-1-alternate</title><path d=\"M14.619 7.562a.823.823 0 00-.121.03L10.2 8.975a2.243 2.243 0 00-1.759 2.189v3.876a.249.249 0 01-.23.243 1.993 1.993 0 102.224 2.028c0-.011.006-.02.006-.031v-6.116a.243.243 0 01.2-.238 1.191 1.191 0 00.121-.031l4.267-1.372a.289.289 0 01.161.055.247.247 0 01.088.188v2.806a.25.25 0 01-.23.244 1.993 1.993 0 10.575 3.943 2.073 2.073 0 001.655-2.079V9.766a2.242 2.242 0 00-2.656-2.2z\"/><path d=\"M24 6.3a1.95 1.95 0 00-1.95-1.95H12a.5.5 0 01-.447-.277l-.747-1.494A1.949 1.949 0 009.061 1.5H1.95A1.95 1.95 0 000 3.45v17.1a1.95 1.95 0 001.95 1.95h20.1A1.95 1.95 0 0024 20.55zM22.048 20a.5.5 0 01-.5.5L2.5 20.549a.5.5 0 01-.5-.5L1.952 4a.5.5 0 01.5-.5l6.256-.025a.5.5 0 01.449.277l.761 1.523A1.941 1.941 0 0011.66 6.35l9.84-.05a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});