define("ember-svg-jar/inlined/smiley-bright", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-bright</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm10.25 8.75a.749.749 0 01-.644.742l-.5.071a3.019 3.019 0 00-2.546 2.546l-.071.5a.749.749 0 01-1.484 0l-.071-.5a3.019 3.019 0 00-2.546-2.546l-.5-.071a.749.749 0 010-1.484l.5-.071a3.019 3.019 0 002.546-2.546l.071-.5a.749.749 0 011.484 0l.071.5a3.019 3.019 0 002.546 2.546l.5.071a.749.749 0 01.644.742zm-9.662 9.625a3.385 3.385 0 003.212-2.316 1 1 0 111.9.632 5.379 5.379 0 01-5.11 3.684 1 1 0 010-2zM2.894 8.008l.5-.071a3.019 3.019 0 002.543-2.546l.071-.5a.749.749 0 011.484 0l.071.5a3.019 3.019 0 002.546 2.546l.5.071a.749.749 0 010 1.484l-.5.071a3.019 3.019 0 00-2.546 2.546l-.071.5a.749.749 0 01-1.484 0l-.071-.5a3.019 3.019 0 00-2.546-2.546l-.5-.071a.749.749 0 010-1.484z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});