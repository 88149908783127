define("ember-svg-jar/inlined/asian-interior-bonsai-tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>asian-interior-bonsai-tree</title><path d=\"M16.779 11.031A.516.516 0 0016.6 11h-3.268a.5.5 0 00-.293.9 2.967 2.967 0 001.02.475l1.924.489h.009a.549.549 0 01.426.549.57.57 0 01-.334.518l-.036.016-4.527 1.691a.508.508 0 01-.4-.021L9.867 15a.5.5 0 00-.681.249 3.741 3.741 0 01-.316.58 1.307 1.307 0 01-.113.15.5.5 0 00.159.774l.791.392a.5.5 0 01.259.584l-.425 1.5a.912.912 0 00-.041.271H5.76a1 1 0 00-.9 1.43C5.522 22.33 6.63 24 8.123 24h5.254c1.493 0 2.6-1.67 3.268-3.072a1 1 0 00-.9-1.428h-4.2l.484-1.687a.5.5 0 01.3-.331l4.432-1.661a2.588 2.588 0 001.658-2.4 2.549 2.549 0 00-1.64-2.39zM20.433 4.5a3.611 3.611 0 00-1.209.208.249.249 0 01-.3-.121 4.345 4.345 0 00-3.857-2.337 4.245 4.245 0 00-1.472.264.251.251 0 01-.3-.1 5.131 5.131 0 00-8.953.453.249.249 0 01-.213.133A4.294 4.294 0 000 7.25 2.778 2.778 0 002.8 10h19.167A2.019 2.019 0 0024 8a3.538 3.538 0 00-3.567-3.5zM8.036 15.278A2.749 2.749 0 003.5 12.172 1.718 1.718 0 002.75 12a1.75 1.75 0 000 3.5h4.87a.5.5 0 00.416-.222z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});