define("ember-svg-jar/inlined/monkey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monkey</title><path d=\"M21.5 7.5c0-5.5-4.5-7-9.5-7S2.5 2 2.5 7.5a2.906 2.906 0 00-2 3 3.972 3.972 0 002 3.5c0 5.5 2 9.5 9.5 9.5s9.5-4 9.5-9.5a3.972 3.972 0 002-3.5 2.906 2.906 0 00-2-3zM12.006 21h-.013c-1.939 0-4.2-.571-4.853-2.691A16.309 16.309 0 0012 19a16.314 16.314 0 004.86-.691C16.211 20.43 13.945 21 12.006 21zm4.742-8.843a1 1 0 00-.606 1.3 10.287 10.287 0 01.849 2.679A12.962 12.962 0 0112 17a12.952 12.952 0 01-4.991-.867 10.287 10.287 0 01.849-2.679 1 1 0 00-.606-1.3 3.484 3.484 0 01-2.127-3.49A3.667 3.667 0 018.792 5h6.416a3.667 3.667 0 013.667 3.667 3.484 3.484 0 01-2.127 3.49z\"/><circle cx=\"9\" cy=\"9\" r=\"1.25\"/><circle cx=\"15\" cy=\"9\" r=\"1.25\"/><path d=\"M11.073 12.93a1 1 0 10-1.9.633l.25.75a1 1 0 001.9-.633zM14.191 12.3a1 1 0 00-1.264.632l-.25.75a1 1 0 101.9.633l.25-.75a1 1 0 00-.636-1.265z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});