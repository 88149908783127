define("ember-svg-jar/inlined/single-man-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-monitor</title><path d=\"M24 2.5A1.5 1.5 0 0022.5 1h-21A1.5 1.5 0 000 2.5V17a1.5 1.5 0 001.5 1.5h9a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5H4a1 1 0 000 2h16a1 1 0 000-2h-6.5a.5.5 0 01-.5-.5V19a.5.5 0 01.5-.5h9A1.5 1.5 0 0024 17zM9.89 4.345a.254.254 0 01-.1-.187.25.25 0 01.078-.2 3.5 3.5 0 015.524.938.25.25 0 01-.146.353 5.936 5.936 0 01-1.791.277A6.169 6.169 0 019.89 4.345zm-1.046 1.4a.233.233 0 01.147-.165.236.236 0 01.22.027 7.362 7.362 0 004.241 1.337 7.38 7.38 0 002-.275.238.238 0 01.219.048.233.233 0 01.081.208 3.513 3.513 0 01-7-.428 3.213 3.213 0 01.092-.749zm9.1 9.455a.751.751 0 01-.689 1.047h-10a.751.751 0 01-.694-1.047 6.305 6.305 0 015.689-3.95 6.3 6.3 0 015.689 3.95z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});