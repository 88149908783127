define("ember-svg-jar/inlined/movies-sit-drink", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>movies-sit-drink</title><path d=\"M4.078 12.219A.25.25 0 003.83 12H2.5a.5.5 0 01-.5-.5v-9a.5.5 0 01.5-.5h17a.5.5 0 01.5.5v6.024a.251.251 0 00.338.234 4.07 4.07 0 011.431-.258.232.232 0 00.231-.231V2a2 2 0 00-2-2H2a2 2 0 00-2 2v10a2 2 0 002 2h2.018a.251.251 0 00.248-.281z\"/><circle cx=\"15.5\" cy=\"7.25\" r=\"2.5\"/><path d=\"M5.594 21.8a1.18 1.18 0 001.058 2.1l2.623-1.647a.249.249 0 00.1-.291l-.724-2.172a.25.25 0 00-.166-.16.248.248 0 00-.227.044zM17.457 12.112a.247.247 0 00-.09-.322 3.5 3.5 0 00-4.967 1.344.246.246 0 00.008.245.249.249 0 00.213.121h3.993a.252.252 0 00.224-.138z\"/><path d=\"M9.279 17a.221.221 0 01.221.221 2.266 2.266 0 00.113.7l1.912 5.735A.5.5 0 0012 24h8a.5.5 0 00.477-.349L23.9 12.907A2.233 2.233 0 0021.769 10a2.542 2.542 0 00-2.284 1.411l-1.725 3.451a.249.249 0 01-.223.138H8.566L9 11.562A.5.5 0 008.5 11h-.281a.251.251 0 01-.243-.311l.009-.037a.53.53 0 01.515-.4h1a.75.75 0 000-1.5h-1a2.028 2.028 0 00-1.97 1.538l-.131.523a.248.248 0 01-.242.187H5.5a.5.5 0 00-.5.562L5.434 15H5a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});