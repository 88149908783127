define("ember-svg-jar/inlined/team-meeting-flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>team-meeting-flash</title><circle cx=\"20.5\" cy=\"10\" r=\"2.5\"/><path d=\"M20.5 13a3.5 3.5 0 00-3.5 3.5v2a.5.5 0 00.5.5h.822a.25.25 0 01.248.225L19 23.55a.5.5 0 00.5.45h2a.5.5 0 00.5-.45l.433-4.325a.25.25 0 01.245-.225h.822a.5.5 0 00.5-.5v-2a3.5 3.5 0 00-3.5-3.5z\"/><circle cx=\"3.5\" cy=\"10\" r=\"2.5\"/><path d=\"M3.5 13A3.5 3.5 0 000 16.5v2a.5.5 0 00.5.5h.822a.25.25 0 01.248.225L2 23.55a.5.5 0 00.5.45h2a.5.5 0 00.5-.45l.433-4.325A.25.25 0 015.678 19H6.5a.5.5 0 00.5-.5v-2A3.5 3.5 0 003.5 13zM15.761 5.65A1 1 0 0015 4h-.75a.25.25 0 01-.25-.25V.5a.5.5 0 00-.893-.31L8.241 6.349A1 1 0 009 8h.75a.25.25 0 01.25.25v3.25a.5.5 0 00.336.472.492.492 0 00.164.028.5.5 0 00.393-.19z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});