define("ember-svg-jar/inlined/compass-south-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>compass-south-1</title><path d=\"M12 15a7.5 7.5 0 10-7.5-7.5A7.508 7.508 0 0012 15zm0-12.5a5 5 0 11-5 5 5.006 5.006 0 015-5zM16.823 17H7.177a1 1 0 00-.72 1.694l4.823 5a1 1 0 001.441 0l4.821-5a1 1 0 00-.719-1.7z\"/><circle cx=\"12\" cy=\"7.5\" r=\"3.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});