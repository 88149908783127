define("ember-svg-jar/inlined/sport-curling-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sport-curling-1</title><path d=\"M5.523 14.435a3.284 3.284 0 00-1.754-.366 3.258 3.258 0 00-1.674.636 1 1 0 001.178 1.617 1.281 1.281 0 01.652-.259 1.325 1.325 0 01.688.153 1 1 0 10.91-1.781zM5.752 11.351a5.511 5.511 0 00-5.145.4 1 1 0 001.059 1.7 3.511 3.511 0 013.308-.261 1 1 0 00.778-1.842zM15.31 5.529a2.455 2.455 0 10-2.455-2.455 2.458 2.458 0 002.455 2.455zM18.564 6.5a3.436 3.436 0 00-2.186.073l-2.6.975a4.389 4.389 0 00-1.768 1.226l-2.935 3.354a1.467 1.467 0 002.207 1.932l2.936-3.36a1.471 1.471 0 01.588-.407l.26-.1v2.155a.5.5 0 01-.124.329l-2.255 2.579a1.452 1.452 0 00-.117.154 4.819 4.819 0 00-.46.873l-1.827 4.568a1.467 1.467 0 102.723 1.09s1.358-3.366 1.814-4.482a2.262 2.262 0 01.4-.64l.78-.884a.25.25 0 01.437.14l.024.242a3.416 3.416 0 001.3 2.358l3.739 2.908a1.467 1.467 0 001.8-2.315l-3.74-2.907a.487.487 0 01-.186-.336l-.35-3.506a9.547 9.547 0 01-.048-.956v-1.539a.25.25 0 01.322-.239l.588.176a1.465 1.465 0 011.045 1.4v1.24a1.467 1.467 0 002.933 0v-1.235a4.4 4.4 0 00-3.136-4.214z\"/><path d=\"M9.467 20.669a1.911 1.911 0 00-.049-.339 2.6 2.6 0 00-1.488-1.776l1.277-1.982a.979.979 0 00-.3-1.367l-.021-.013a.989.989 0 00-1.358.3L5.91 18c-.2-.019-.406-.039-.619-.039-2.381 0-4.177 1.166-4.177 2.71s1.8 2.711 4.177 2.711A4.377 4.377 0 009.2 21.634a1.982 1.982 0 00.267-.965zm-4.176.711a3.556 3.556 0 01-1.986-.5.25.25 0 01-.005-.405 3.454 3.454 0 011.991-.515 3.375 3.375 0 012.022.54.218.218 0 010 .342 3.375 3.375 0 01-2.022.538z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});