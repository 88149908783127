define("ember-svg-jar/inlined/single-man-actions-location.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-location</title><path d=\"M.124 17.567a.494.494 0 00.5.5H2.41a.246.246 0 01.246.227l.444 5.252a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h1.791a.494.494 0 00.495-.5V14.1a5.443 5.443 0 10-10.886 0z\"/><path d=\"M5.567 8.412a4.206 4.206 0 10-4.206-4.206 4.211 4.211 0 004.206 4.206zm0-1.979A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM18.928 11.134a4.954 4.954 0 00-4.949 4.948c0 3.138 4.411 7.586 4.6 7.773a.495.495 0 00.7 0c.188-.187 4.6-4.635 4.6-7.773a4.954 4.954 0 00-4.951-4.948zm0 3.217a1.732 1.732 0 11-1.728 1.731 1.734 1.734 0 011.728-1.731z\"/><path d=\"M.124 17.567a.494.494 0 00.5.5H2.41a.246.246 0 01.246.227l.444 5.252a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h1.791a.494.494 0 00.495-.5V14.1a5.443 5.443 0 10-10.886 0z\"/><path d=\"M5.567 8.412a4.206 4.206 0 10-4.206-4.206 4.211 4.211 0 004.206 4.206zm0-1.979A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM18.928 11.134a4.954 4.954 0 00-4.949 4.948c0 3.138 4.411 7.586 4.6 7.773a.495.495 0 00.7 0c.188-.187 4.6-4.635 4.6-7.773a4.954 4.954 0 00-4.951-4.948zm0 3.217a1.732 1.732 0 11-1.728 1.731 1.734 1.734 0 011.728-1.731z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});