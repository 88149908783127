define("ember-svg-jar/inlined/baggage-roll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baggage-roll</title><path d=\"M22.5 4.75h-2v-2a2 2 0 00-2-2h-3a2 2 0 00-2 2v2h-2a1.5 1.5 0 00-1.5 1.5v14a1.5 1.5 0 001.5 1.5v.5a1 1 0 002 0v-.5h7v.5a1 1 0 002 0v-.5a1.5 1.5 0 001.5-1.5v-14a1.5 1.5 0 00-1.5-1.5zm-8 2.75a.75.75 0 01.75.75v9.5a.75.75 0 01-1.5 0v-9.5a.75.75 0 01.75-.75zm4.25.75a.75.75 0 011.5 0v9.5a.75.75 0 01-1.5 0zM15.5 3a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25zM7.5 19.25H1a1 1 0 000 2h6.5a1 1 0 000-2zM7.5 15.25H3.25a1 1 0 000 2H7.5a1 1 0 000-2zM7.5 11.25H5.75a1 1 0 000 2H7.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});