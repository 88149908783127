define("ember-svg-jar/inlined/video-file-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-refresh</title><path d=\"M23.539 3L21.125.586A2 2 0 0019.71 0H8.125a2 2 0 00-2 2v7.8a.245.245 0 00.23.249c.348.021 1.014.072 1.486.145a.247.247 0 00.284-.247V2.5a.5.5 0 01.5-.5H19.5a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5H14.23a.242.242 0 00-.239.206 7.257 7.257 0 01-.408 1.453.243.243 0 00.224.34h8.318a2 2 0 002-2V4.415A2 2 0 0023.539 3z\"/><path d=\"M14.6 12v.913c0 .153.144.219.3.134l4.322-2.393a.735.735 0 000-1.31l-5.843-3.235a.886.886 0 00-.834-.009.755.755 0 00-.42.665v3.008a.254.254 0 00.292.245 1.954 1.954 0 01.953.135A2 2 0 0114.6 12zM12.8 11.56a.5.5 0 00-.545.108l-1.3 1.3a.246.246 0 01-.343 0 6.31 6.31 0 00-4.082-1.712 6.464 6.464 0 00-6.654 6.287A6.374 6.374 0 0011.232 21.6a1 1 0 10-1.562-1.246 4.376 4.376 0 11-3.42-7.1 4.34 4.34 0 012.925 1.122.256.256 0 010 .367l-1.382 1.382a.5.5 0 00.353.854H12.6a.5.5 0 00.5-.5v-4.458a.5.5 0 00-.3-.461z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});