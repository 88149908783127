define("ember-svg-jar/inlined/lucide-sun-snow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 9a3 3 0 100 6M2 12h1M14 21V3M10 4V3M10 21v-1M3.64 18.36l.7-.7M4.34 6.34l-.7-.7M14 12h8M17 4l-3 3M14 17l3 3M21 15l-3-3 3-3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});