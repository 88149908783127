define("ember-svg-jar/inlined/outdoors-bird-house-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-bird-house-1</title><path d=\"M14 15.5V17a1 1 0 001 1h1.75a.25.25 0 01.25.25V23a1 1 0 002 0v-4.75a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v.574a.753.753 0 00.492.7.75.75 0 00.815-.2l2.44-2.636.013-.014a1.008 1.008 0 00-.066-1.371l-2.413-2.233A.751.751 0 0020 13.6v.646a.25.25 0 01-.25.25H15a1 1 0 00-1 1.004zM4 24h7a1.5 1.5 0 001.5-1.5V7.991a.25.25 0 01.434-.17l.334.36a1 1 0 001.465-1.362L8.968.61a2.069 2.069 0 00-2.932 0L.269 6.819a1 1 0 001.465 1.362l.334-.36a.25.25 0 01.433.17V22.5A1.5 1.5 0 004 24zm1.5-13.5A.5.5 0 016 10h3a.5.5 0 01.5.5v6a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});