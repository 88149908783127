define("ember-svg-jar/inlined/alarm-bell-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>alarm-bell-disable</title><path d=\"M9.625 21.376h-3a.5.5 0 00-.5.5 2 2 0 004 0 .5.5 0 00-.5-.5zM11.422 6.432a6.053 6.053 0 00-1.8-.826v-1.23a1.5 1.5 0 10-3 0v1.23c-2.921.791-5 3.777-5 7.27v.646a13.771 13.771 0 01-1.447 6.13.5.5 0 00.447.724h15a.5.5 0 00.447-.724 13.785 13.785 0 01-1.447-6.13v-.646a8.587 8.587 0 00-.117-1.418 7.006 7.006 0 01-3.083-5.026zM18.375.126a5.5 5.5 0 105.5 5.5 5.5 5.5 0 00-5.5-5.5zm2.25 8.493a.747.747 0 01-.53-.213l-1.543-1.543a.252.252 0 00-.354 0l-1.543 1.543a.75.75 0 11-1.06-1.06L17.138 5.8a.252.252 0 000-.354L15.6 3.906a.75.75 0 111.06-1.06l1.54 1.543a.252.252 0 00.354 0L20.1 2.846a.75.75 0 011.06 1.06l-1.548 1.543a.252.252 0 000 .354l1.543 1.543a.743.743 0 01-.53 1.273z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});