define("ember-svg-jar/inlined/family-add-new-member", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-add-new-member</title><path d=\"M12 9a4.5 4.5 0 10-4.5-4.5A4.505 4.505 0 0012 9zm-2-5.25h.75a.5.5 0 00.5-.5V2.5a.75.75 0 011.5 0v.75a.5.5 0 00.5.5H14a.75.75 0 010 1.5h-.75a.5.5 0 00-.5.5v.75a.75.75 0 01-1.5 0v-.75a.5.5 0 00-.5-.5H10a.75.75 0 010-1.5zM9.347 19.783a.25.25 0 00-.065-.21 3.745 3.745 0 01-.936-3.386.5.5 0 00-.076-.387.232.232 0 01-.039-.117.25.25 0 01.1-.222 4.4 4.4 0 10-5.167 0 .25.25 0 01-.032.425A5.754 5.754 0 000 21a.5.5 0 00.5.5h6.472a1 1 0 00.747-.335 5.7 5.7 0 011.5-1.2.248.248 0 00.128-.182zM5.75 14.3a2.4 2.4 0 01-2.276-3.155.25.25 0 01.372-.132 5.643 5.643 0 004 .8.253.253 0 01.211.061.25.25 0 01.081.2A2.4 2.4 0 015.75 14.3zM20.8 16.169a.5.5 0 00-.629.209l-1.208 2.1a.251.251 0 01-.434 0l-1.208-2.1a.5.5 0 00-.629-.209 5.117 5.117 0 00-.663.341.574.574 0 00-.276.49 3.733 3.733 0 01-1.028 2.576.252.252 0 00.06.391 5.7 5.7 0 011.494 1.2 1 1 0 00.748.336H23.5A.5.5 0 0024 21a5.258 5.258 0 00-3.2-4.831zM12 20.5a4.491 4.491 0 00-4.164 2.813A.5.5 0 008.3 24h7.447a.469.469 0 00.436-.64A4.493 4.493 0 0012 20.5z\"/><circle cx=\"12.007\" cy=\"17\" r=\"2.5\"/><path d=\"M14.5 11.733a4.459 4.459 0 01-.3 1.75.5.5 0 00.146.59 3.784 3.784 0 01.833.933.266.266 0 00.439.014.248.248 0 01.173-.1.256.256 0 01.193.059 4.24 4.24 0 005.526 0 .256.256 0 01.193-.059.25.25 0 01.173.1 3.938 3.938 0 00.418.487 1 1 0 101.406-1.422 3.068 3.068 0 01-.7-2.352 4.25 4.25 0 00-8.5 0zm2.223.027a3.409 3.409 0 00.909-.28 2.471 2.471 0 00.916-.74.251.251 0 01.4 0 2.471 2.471 0 00.916.74 3.409 3.409 0 00.909.28.25.25 0 01.2.278 2.249 2.249 0 01-4.462 0 .251.251 0 01.212-.278z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});