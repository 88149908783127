define("ember-svg-jar/inlined/clean-car-gas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>clean-car-gas</title><path d=\"M22.8 4.1a1 1 0 00-1.6-1.2l-2.25 3a1 1 0 00-.2.6v9a1 1 0 01-1 1h-.25a.5.5 0 01-.5-.5V1.5A1.5 1.5 0 0015.5 0h-13A1.5 1.5 0 001 1.5v21A1.5 1.5 0 002.5 24h13a1.5 1.5 0 001.5-1.5V19a.5.5 0 01.5-.5h.25a3 3 0 003-3v-3.067l.027-.017 1.5-1A.5.5 0 0022.5 11V8.5A.5.5 0 0022 8h-1.25V6.833zM11.117 18.219a3.233 3.233 0 01-2.788 1.156 2.106 2.106 0 01-.721-.285.5.5 0 01-.17-.671 6.905 6.905 0 012.527-2.794.5.5 0 10-.447-.895c-2 .992-3.575 4.006-4.247 6.124a.5.5 0 11-.956-.292A17.123 17.123 0 015.7 17.411a.5.5 0 00-.043-.558 2.176 2.176 0 01-.511-1.375 2.738 2.738 0 011.37-2.187 6.964 6.964 0 012.258-.891 6.506 6.506 0 002.542-1.091.5.5 0 01.755.19 7.3 7.3 0 01-.954 6.72zM15 7.5a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});