define("ember-svg-jar/inlined/paragraph-right-align", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paragraph-right-align</title><path d=\"M22.476 23.248H1.524a1.25 1.25 0 010-2.5h20.952a1.25 1.25 0 010 2.5zM22.476 3.248H4.38a1.25 1.25 0 110-2.5h18.1a1.25 1.25 0 010 2.5zM22.476 9.915H8.19a1.25 1.25 0 110-2.5h14.286a1.25 1.25 0 110 2.5zM22.476 16.581H5.333a1.25 1.25 0 010-2.5h17.143a1.25 1.25 0 010 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});