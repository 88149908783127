define("ember-svg-jar/inlined/upload-square-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>upload-square-1</title><path d=\"M22.286 0H1.714A1.716 1.716 0 000 1.714v20.571A1.716 1.716 0 001.714 24h20.572A1.716 1.716 0 0024 22.285V1.714A1.716 1.716 0 0022.286 0zm-7.837 16.653h-4.9a.49.49 0 01-.49-.49v-.98a.489.489 0 01.49-.489h4.9a.489.489 0 01.49.489v.98a.49.49 0 01-.49.49zm.49 2.449v.979a.49.49 0 01-.49.49h-4.9a.49.49 0 01-.49-.49V19.1a.49.49 0 01.49-.49h4.9a.49.49 0 01.49.49zm-.49-6.368h-4.9a.489.489 0 01-.49-.489v-1.71a.25.25 0 00-.25-.25H7.1a.915.915 0 01-.941-.506.913.913 0 01.308-1.022l4.492-4.493a1.473 1.473 0 012.078 0l4.492 4.493a.913.913 0 01.308 1.022.915.915 0 01-.941.506h-1.707a.25.25 0 00-.25.25v1.71a.489.489 0 01-.49.489z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});