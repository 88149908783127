define("ember-svg-jar/inlined/road-sign-u-turn-left-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-u-turn-left-alternate</title><path d=\"M11.75 6.75a4.505 4.505 0 00-4.5 4.5v.45a.25.25 0 01-.444.159l-.069-.086a.75.75 0 10-1.161.95l2.093 2.562a.752.752 0 00.539.275h.042a.755.755 0 00.524-.212l2.626-2.561a.75.75 0 00-1.048-1.074l-.676.66a.25.25 0 01-.425-.179v-.944a2.5 2.5 0 015 0v6a1 1 0 002 0v-6a4.505 4.505 0 00-4.501-4.5z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});