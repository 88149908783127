define("ember-svg-jar/inlined/car-dashboard-e-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-dashboard-e-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M12.24 16.653a.25.25 0 01-.192-.087L5.507 9a1 1 0 00-1.514 1.308l6.348 7.346a.251.251 0 01.042.26 1.946 1.946 0 00-.153.749 2.01 2.01 0 102.01-2.009zM13.251 6.153h2.5a1 1 0 000-2h-2.5a2.5 2.5 0 00-2.5 2.5v5a2.5 2.5 0 002.5 2.5h2.5a1 1 0 100-2h-2.5a.5.5 0 01-.5-.5V10.4a.25.25 0 01.25-.25h1.25a1 1 0 000-2H13a.25.25 0 01-.25-.25V6.653a.5.5 0 01.501-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});