define("ember-svg-jar/inlined/show-rabbit-hat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>show-rabbit-hat</title><path d=\"M19.5 16h-15a1 1 0 000 2h1a.5.5 0 01.5.5v3A2.5 2.5 0 008.5 24h7a2.5 2.5 0 002.5-2.5v-3a.5.5 0 01.5-.5h1a1 1 0 000-2zM8.234 11.092a.5.5 0 01-.069.58 5.194 5.194 0 00-1.2 2.2.5.5 0 00.484.626h9.011a.5.5 0 00.484-.626 5.174 5.174 0 00-1.144-2.146.5.5 0 01-.064-.576l4-7.194a2.663 2.663 0 10-4.949-1.762l-1.343 7.483a.5.5 0 01-.58.4 5.142 5.142 0 00-.907-.077 5.074 5.074 0 00-.831.068.5.5 0 01-.573-.406L9.217 2.194a2.663 2.663 0 10-4.949 1.762z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});