define("ember-svg-jar/inlined/road-sign-obstruction-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-obstruction-1-alternate</title><path d=\"M16.5 0h-9a1 1 0 00-1 1v22a1 1 0 001 1h9a1 1 0 001-1V1a1 1 0 00-1-1zm-5.26 21.846a.251.251 0 01-.231.154H8.75a.25.25 0 01-.25-.25v-2.259a.25.25 0 01.427-.177l2.259 2.259a.253.253 0 01.054.273zm4.26-.1a.25.25 0 01-.25.25h-1.438a.252.252 0 01-.21-.113.508.508 0 00-.072-.09L8.573 16.84a.251.251 0 01-.073-.177v-2.922a.25.25 0 01.427-.177l6.5 6.5a.251.251 0 01.073.177zm0-4.337a.25.25 0 01-.427.177l-6.5-6.5a.251.251 0 01-.073-.177V7.991a.25.25 0 01.427-.177l6.5 6.5a.249.249 0 01.073.177zm0-5.75a.25.25 0 01-.427.176l-6.5-6.5a.251.251 0 01-.073-.172V2.25A.25.25 0 018.75 2h.009a.251.251 0 01.177.073l6.491 6.491a.251.251 0 01.073.177zm0-5.75a.25.25 0 01-.427.177L11.41 2.427A.25.25 0 0111.587 2h3.663a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});