define("ember-svg-jar/inlined/stop-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>stop-sign</title><path d=\"M.008 16.164A1.988 1.988 0 00.6 17.581l5.849 5.825a1.989 1.989 0 001.409.583l8.207.011a1.987 1.987 0 001.4-.575l5.93-5.84a2.01 2.01 0 00.6-1.424v-8.2a1.984 1.984 0 00-.583-1.41L17.489.6a1.989 1.989 0 00-1.418-.59h-8.2A2.013 2.013 0 006.446.6L.579 6.56A1.989 1.989 0 000 7.964zm8-9.29a.931.931 0 011.82-.39l.946 4.393a.049.049 0 00.054.039.05.05 0 00.045-.05V5.435a.93.93 0 011.86 0v5.446a.051.051 0 00.045.05.049.049 0 00.054-.039l.946-4.186A.931.931 0 0115.6 7.1l-.957 5.219a.051.051 0 00.031.056.052.052 0 00.061-.02l1.55-2.441a.931.931 0 011.587.972l-1.328 2.494a3.347 3.347 0 00-.394 1.579c0 2.093-.586 3.814-2.79 3.815h-2.178a2.8 2.8 0 01-2.019-.862L5.9 13.571v-.006a.931.931 0 011.26-1.371l1.751 1.529a.05.05 0 00.082-.045z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});