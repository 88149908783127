define("ember-svg-jar/inlined/multiple-neutral-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-neutral-circle</title><circle cx=\"8\" cy=\"10.25\" r=\"3.75\"/><circle cx=\"18\" cy=\"12.5\" r=\"3\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 2a9.992 9.992 0 018.876 14.6.251.251 0 01-.35.1A4.925 4.925 0 0018 16a4.993 4.993 0 00-3.928 1.906.251.251 0 01-.4-.008A6.984 6.984 0 003.5 16.652a.249.249 0 01-.21.054.252.252 0 01-.173-.13A9.99 9.99 0 0112 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});