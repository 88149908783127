define("ember-svg-jar/inlined/phone-actions-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-question</title><path d=\"M11.066 15.507a2.493 2.493 0 01-2.342-3.354 44.86 44.86 0 01-1.268-1.432l.386-.384a2.508 2.508 0 000-3.536L5.719 4.679a2.563 2.563 0 00-3.536 0L1.02 5.844a3.508 3.508 0 00-.442 4.4 46.889 46.889 0 0013.141 13.144 3.584 3.584 0 004.4-.442l1.164-1.163a2.5 2.5 0 000-3.535l-2.121-2.122a2.5 2.5 0 00-3.536 0l-.386.385c-.446-.373-.889-.77-1.33-1.169a2.509 2.509 0 01-.844.165z\"/><path d=\"M11.047 14a.991.991 0 00.429-.093l2.972-1.407a6.6 6.6 0 10-2.889-2.89l-1.415 2.972a1 1 0 00.2 1.137 1.015 1.015 0 00.703.281zM22 6.632a4.57 4.57 0 01-6.986 3.887 1.008 1.008 0 00-.528-.15 1 1 0 00-.429.1l-.9.426.43-.895a1 1 0 00-.053-.957A4.576 4.576 0 1122 6.632z\"/><path d=\"M15.273 5.47a.75.75 0 001.5 0 .75.75 0 11.75.75.75.75 0 000 1.5 2.25 2.25 0 10-2.25-2.25z\"/><circle cx=\"17.523\" cy=\"9.243\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});