define("ember-svg-jar/inlined/ecology-globe-hand-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ecology-globe-hand-1</title><path d=\"M14.75 0a9.264 9.264 0 00-8.869 11.893.5.5 0 00.48.357H8.25a4.519 4.519 0 011.271.182.5.5 0 00.386-.044.493.493 0 00.238-.307l.33-1.236a.8.8 0 01.775-.6 1.5 1.5 0 001.5-1.5v-2a1.5 1.5 0 00-1.5-1.5H9.192a.25.25 0 01-.2-.4 7.241 7.241 0 0111.489-.031.5.5 0 01-.1.713 1.5 1.5 0 00-.629 1.22v2a.5.5 0 01-.5.5h-.613a1.5 1.5 0 00-1.423 1.975l.334 1a1.5 1.5 0 001.423 1.025h1.348a.25.25 0 01.2.4 7.276 7.276 0 01-2.506 2.075 1 1 0 00.9 1.786A9.252 9.252 0 0014.75 0zM15.25 18.25h-8.5a.5.5 0 110-1h4a.5.5 0 00.5-.5 3 3 0 00-3-3h-6A.25.25 0 012 13.5a1 1 0 00-2 0V23a1 1 0 002 0 .25.25 0 01.25-.25h16.5a.5.5 0 00.5-.5 4 4 0 00-4-4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});