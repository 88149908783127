define("ember-svg-jar/inlined/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>search</title><path d=\"M26.25 52.5c6.215 0 11.926-2.185 16.43-5.81l22.335 22.45a2.913 2.913 0 004.125.008 2.916 2.916 0 00.011-4.124L46.786 42.548c3.567-4.48 5.714-10.14 5.714-16.298C52.5 11.775 40.725 0 26.25 0S0 11.775 0 26.25C0 40.722 11.775 52.5 26.25 52.5zm0-46.667c11.255 0 20.417 9.159 20.417 20.417 0 11.255-9.162 20.417-20.417 20.417-11.258 0-20.417-9.162-20.417-20.417 0-11.258 9.159-20.417 20.417-20.417z\" fill-rule=\"nonzero\" fill=\"#000\"/>",
    "attrs": {
      "width": "70",
      "height": "70",
      "viewBox": "0 0 70 70",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});