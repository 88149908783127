define("ember-svg-jar/inlined/science-molecule-strucutre", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-molecule-strucutre</title><path d=\"M21.24 5.456l-3.023-1.714a1.5 1.5 0 00-1.49 0l-2.6 1.494a.5.5 0 01-.435.031l-3.378-1.349A.5.5 0 0110 3.454v-1.33a1.244 1.244 0 00-.691-1.118L7.559.131a1.256 1.256 0 00-1.118 0l-1.75.875A1.244 1.244 0 004 2.124v2.167A1.244 1.244 0 004.679 5.4l1.75.9a1.25 1.25 0 001.142 0l1.248-.64a.505.505 0 01.415-.02l3.452 1.381a.5.5 0 01.314.465v2.193a1.5 1.5 0 00.756 1.3l1.617.923a.251.251 0 01.024.419L12.19 14.7a.5.5 0 01-.541.036l-3.42-1.9a1.493 1.493 0 00-1.458 0l-4 2.222A1.5 1.5 0 002 16.365v3.912a1.5 1.5 0 00.771 1.311l4 2.223a1.5 1.5 0 001.458 0l4-2.223a1.5 1.5 0 00.77-1.31v-3.44a.5.5 0 01.2-.4 71.223 71.223 0 018.039-5.453A1.5 1.5 0 0022 9.679V6.761a1.5 1.5 0 00-.76-1.305zM7.228 4.229a.5.5 0 01-.456 0l-.5-.257A.5.5 0 016 3.527V2.9a.5.5 0 01.276-.447l.5-.25a.5.5 0 01.448 0l.5.25A.5.5 0 018 2.9v.63a.5.5 0 01-.272.445zM11 19.689a.5.5 0 01-.257.437l-3 1.666a.5.5 0 01-.486 0l-3-1.666A.5.5 0 014 19.689v-2.735a.5.5 0 01.257-.438l3-1.666a.5.5 0 01.486 0l3 1.666a.5.5 0 01.257.438zM20 9.1a.5.5 0 01-.252.434l-2 1.143a.5.5 0 01-.5 0l-2-1.143A.5.5 0 0115 9.1V7.337a.5.5 0 01.25-.433l1.98-1.14a.5.5 0 01.5 0l2.021 1.146a.5.5 0 01.249.433z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});