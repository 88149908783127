define("ember-svg-jar/inlined/pollution-drop-skull", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pollution-drop-skull</title><circle cx=\"10\" cy=\"14.495\" r=\"1\"/><circle cx=\"14\" cy=\"14.495\" r=\"1\"/><path d=\"M12.4.193a.518.518 0 00-.8 0C11.271.63 3.5 10.96 3.5 15.5a8.5 8.5 0 0017 0c0-4.54-7.771-14.87-8.1-15.307zm2.325 17.578a.5.5 0 00-.224.417V19.5a.5.5 0 11-1 0v-1a.5.5 0 00-1 0v1a.5.5 0 01-1 0v-1a.5.5 0 00-1 0v1a.5.5 0 11-1 0v-1.3a.5.5 0 00-.223-.418A3.772 3.772 0 017.5 14.5a4.5 4.5 0 119 0 3.773 3.773 0 01-1.776 3.271z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});