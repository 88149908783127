define("ember-svg-jar/inlined/app-window-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-question</title><path d=\"M17.577 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm0 11.5a1 1 0 111-1 1 1 0 01-1 1zm.913-4.163a.25.25 0 00-.163.234v.179a.75.75 0 01-1.5 0v-.55a1.255 1.255 0 01.99-1.222 1.125 1.125 0 10-1.365-1.1.75.75 0 11-1.5 0 2.625 2.625 0 113.538 2.462z\"/><path d=\"M9.169 19.222a.968.968 0 00-.95-.721h-5.3a1 1 0 01-1-1V5.251A.25.25 0 012.173 5h19.5a.25.25 0 01.25.25v4.781a1 1 0 00.388.777l.022.018a.983.983 0 001.59-.778V3a3 3 0 00-3-3h-18a3 3 0 00-3 3v14.5a3 3 0 003 3h5.3a1 1 0 00.95-1.256zM9.576 2a.967.967 0 011.693 0 .969.969 0 01.154.5.972.972 0 01-.154.5.967.967 0 01-1.693 0 .98.98 0 01-.153-.5.977.977 0 01.153-.5zm-3.5 0a.967.967 0 011.693 0 .969.969 0 01.154.5.972.972 0 01-.154.5.967.967 0 01-1.693 0 .98.98 0 01-.153-.5.977.977 0 01.153-.5zm-3.561.091a1 1 0 01.908-.591.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5.967.967 0 01-1.693 0 .98.98 0 01-.153-.5.987.987 0 01.092-.408z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});