define("ember-svg-jar/inlined/protection-shield-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>protection-shield-3</title><path d=\"M22.826 10.628a.242.242 0 00.243-.242V3.412a1.616 1.616 0 00-.985-1.482A25.362 25.362 0 0013.223 0a.249.249 0 00-.178.067.243.243 0 00-.075.176v10.143a.242.242 0 00.242.242zM10.788 10.628a.242.242 0 00.242-.242V.243a.24.24 0 00-.075-.175.24.24 0 00-.178-.068c-.5.022-.995.054-1.467.1a.242.242 0 00-.22.241v10.045a.242.242 0 00.243.242zM17.092 12.568a.242.242 0 00-.243.242v9.106a.243.243 0 00.376.2 13.266 13.266 0 001.482-1.128.244.244 0 00.082-.182v-8a.242.242 0 00-.243-.242zM20.729 17.94a.242.242 0 00.448.127 12.563 12.563 0 001.812-5.23.24.24 0 00-.06-.188.245.245 0 00-.181-.081h-1.777a.242.242 0 00-.242.242zM1.252 12.568a.242.242 0 00-.241.269A13.048 13.048 0 009.6 23.553l1.181.435a.187.187 0 00.253-.176v-11a.242.242 0 00-.242-.242zM13.212 12.568a.242.242 0 00-.242.242v11a.187.187 0 00.253.176l1.181-.435c.122-.045.24-.1.358-.146a.244.244 0 00.148-.223V12.81a.242.242 0 00-.243-.242zM6.908 10.628a.242.242 0 00.243-.242V.679a.241.241 0 00-.088-.187.245.245 0 00-.2-.052c-.523.1-1.009.213-1.469.33a.242.242 0 00-.183.23v9.381a.242.242 0 00.243.242zM3.271 1.748a.242.242 0 00-.326-.228c-.4.147-.747.287-1.029.41a1.616 1.616 0 00-.985 1.482v6.974a.242.242 0 00.243.242h1.855a.242.242 0 00.242-.242z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});