define("ember-svg-jar/inlined/ice-clean", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ice-clean</title><circle cx=\"10\" cy=\"18.5\" r=\"3\"/><path d=\"M21.5 19.5a4 4 0 010-8 1 1 0 000-2 6.011 6.011 0 00-5.879 4.8.25.25 0 01-.245.2h-.261a.25.25 0 01-.245-.2l-.22-1.092A1.5 1.5 0 0013.18 12a.18.18 0 01-.18-.18V9.5A1.5 1.5 0 0011.5 8h-3A1.5 1.5 0 007 9.5v2.434a.066.066 0 01-.128.021L3.948 3.184A1 1 0 003 2.5H1a1 1 0 000 2h1.1a.25.25 0 01.237.171l2.651 7.952A.234.234 0 015 12.7v4.8a1.5 1.5 0 00.6 1.2.249.249 0 00.4-.2 4 4 0 018 0c0 .079 0 .157-.007.235a.254.254 0 00.068.187.25.25 0 00.182.078H16.5a.249.249 0 01.2.1 6 6 0 004.8 2.4 1 1 0 000-2z\"/><path d=\"M23.8 16.6l-1.2-.9a.25.25 0 010-.4l1.2-.9a.5.5 0 00-.6-.8l-.8.6a.25.25 0 01-.4-.2v-1a.5.5 0 00-1 0v1a.25.25 0 01-.4.2l-.8-.6a.5.5 0 00-.6.8l1.2.9a.25.25 0 010 .4l-1.2.9a.5.5 0 00.6.8l.8-.6a.25.25 0 01.4.2v1a.5.5 0 001 0v-1a.25.25 0 01.4-.2l.8.6a.5.5 0 10.6-.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});