define("ember-svg-jar/inlined/chef-gear-tea-cookies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chef-gear-tea-cookies</title><path d=\"M18.5 16.25a1 1 0 00-1-1h-5.522a.5.5 0 00-.4.2l-1.944 2.562a.46.46 0 00.366.738h7.5a1 1 0 001-1zM20 11.75a1 1 0 00-1-1h-4.819a.5.5 0 00-.493.587 2.491 2.491 0 01-.468 1.948l-.427.564a.25.25 0 00.2.4H19a1 1 0 001-1z\"/><rect x=\"3.614\" y=\"12.559\" width=\"9.5\" height=\"3.5\" rx=\"1\" ry=\"1\" transform=\"rotate(-52.815 8.364 14.309)\"/><path d=\"M12 23.75c-5.817 0-12-1.928-12-5.5a3.574 3.574 0 011.509-2.773 1 1 0 111.27 1.545A1.824 1.824 0 002 18.25c0 1.461 3.8 3.5 10 3.5s10-2.039 10-3.5a1.824 1.824 0 00-.779-1.228 1 1 0 111.27-1.545A3.574 3.574 0 0124 18.25c0 3.572-6.183 5.5-12 5.5zM0 2.712c0 3.477 1.812 6 4.31 6h.88c2.5 0 4.31-2.523 4.31-6 0-3.283-9.5-3.283-9.5 0zm4.75-1c2.132 0 3.25.743 3.25 1s-1.118 1-3.25 1-3.25-.743-3.25-1 1.118-1 3.25-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});