define("ember-svg-jar/inlined/microchip-board", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>microchip-board</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM18 16a1 1 0 111-1 1 1 0 01-1 1zm1 2.5a1 1 0 11-1-1 1 1 0 011 1zm-1-6a1 1 0 111-1 1 1 0 01-1 1zm-2-5a.5.5 0 010-1h4.5a.5.5 0 010 1zM20.5 4h-6a.5.5 0 110-1h6a.5.5 0 010 1zm-6.75 9.75h-.5a.5.5 0 010-1h.5a.5.5 0 010 1zm.5 2.5a.5.5 0 01-.5.5h-.5a.5.5 0 010-1h.5a.5.5 0 01.499.501zm-8.75 2a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5h6a.5.5 0 01.5.5v6a.5.5 0 01-.5.5zm5 1.25v.5a.5.5 0 01-1 0v-.5a.5.5 0 011 0zm-3 0v.5a.5.5 0 01-1 0v-.5a.5.5 0 011 0zm-3.749-5.75h-.5a.5.5 0 110-1h.5a.5.5 0 110 1zm.5 2.5a.5.5 0 01-.5.5h-.5a.5.5 0 010-1h.5a.5.5 0 01.5.501zM3.5 4a.5.5 0 010-1h6a.5.5 0 010 1zm7 5.5v.5a.5.5 0 01-1 0v-.5a.5.5 0 111 0zm-3 0v.5a.5.5 0 01-1 0v-.5a.5.5 0 111 0z\"/><path d=\"M6.5 12.35a.4.4 0 00-.4.4v4a.4.4 0 00.4.4h4a.4.4 0 00.4-.4v-4a.4.4 0 00-.4-.4zm3.6 3.75a.25.25 0 01-.25.25h-2.7a.25.25 0 01-.25-.25v-2.7a.25.25 0 01.25-.25h2.7a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});