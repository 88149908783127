define("ember-svg-jar/inlined/video-game-smartphone-portrait", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-smartphone-portrait</title><path d=\"M13 0H3a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3v-3a1 1 0 00-2 0v1a.5.5 0 01-.5.5h-11A.5.5 0 012 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5V4a1 1 0 002 0V3a3 3 0 00-3-3z\"/><path d=\"M15.684 8.126A.688.688 0 0015.6 7.08a4.81 4.81 0 100 7.84.688.688 0 00.087-1.046l-2.521-2.52a.5.5 0 010-.708z\"/><circle cx=\"23\" cy=\"11\" r=\"1\"/><circle cx=\"18.5\" cy=\"11\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});