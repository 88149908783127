define("ember-svg-jar/inlined/single-neutral-actions-sync.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-sync</title><path d=\"M11.238 12.369a.5.5 0 00-.107-.767A7.442 7.442 0 007.25 10.5a7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h8.312a.5.5 0 00.491-.406 7.59 7.59 0 011.891-3.725z\"/><circle cx=\"7.044\" cy=\"4.75\" r=\"4.75\"/><path d=\"M23.648 11.56a.5.5 0 00-.545.108l-1.3 1.3a.249.249 0 01-.348.005 6.374 6.374 0 10.629 8.632 1 1 0 10-1.563-1.248 4.376 4.376 0 11-.5-5.982.25.25 0 01.009.362l-1.391 1.391a.5.5 0 00.353.854h4.457a.5.5 0 00.5-.5v-4.461a.5.5 0 00-.301-.461z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});