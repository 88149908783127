define("ember-svg-jar/inlined/road-sign-speed-limit-90-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-speed-limit-90-alternate</title><path d=\"M15.993 7.572a3.48 3.48 0 00-3.476 3.476v2.476a3.476 3.476 0 006.952 0v-2.476a3.481 3.481 0 00-3.476-3.476zm1.476 5.952a1.476 1.476 0 01-2.952 0v-2.476a1.476 1.476 0 012.952 0zM7.945 7.572a3.476 3.476 0 000 6.952.186.186 0 01.061.361 2.07 2.07 0 01-.68.115h-.619a1 1 0 000 2h.619a4.1 4.1 0 004.1-4.1v-1.852a3.48 3.48 0 00-3.481-3.476zm0 4.952a1.476 1.476 0 111.476-1.476 1.478 1.478 0 01-1.476 1.476z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});