define("ember-svg-jar/inlined/arrow-corner-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-corner-right</title><path d=\"M21.5 4a1.5 1.5 0 00-3 0v11.76a.25.25 0 01-.427.176L5.06 2.937A1.5 1.5 0 002.94 5.06l13.029 13.015a.25.25 0 01-.177.427H4a1.5 1.5 0 000 3h15A2.5 2.5 0 0021.5 19z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});