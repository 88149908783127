define("ember-svg-jar/inlined/x-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.924 6.51L5.45 4.035 7.925 1.56a.499.499 0 000-.707L7.217.146a.5.5 0 00-.707 0L4.036 2.621 1.561.146a.5.5 0 00-.707 0L.147.854a.499.499 0 000 .707l2.475 2.475L.147 6.51a.499.499 0 000 .707l.707.707a.5.5 0 00.707 0l2.475-2.475L6.51 7.924a.5.5 0 00.707 0l.707-.707a.497.497 0 000-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "8.071",
      "height": "8.07",
      "viewBox": "0 0 8.071 8.07"
    }
  };
});