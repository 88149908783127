define("ember-svg-jar/inlined/single-neutral-actions-music.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-music</title><path d=\"M12.5 13.83a3.5 3.5 0 01.948-2.393.248.248 0 00.062-.225.251.251 0 00-.151-.178 54.45 54.45 0 00-1.86-.7l-.629-.234a.6.6 0 01-.138-.31 2.632 2.632 0 01.058-1.3 5.618 5.618 0 001.466-4.314C12.256 1.756 10.677 0 8.5 0S4.744 1.756 4.744 4.174A5.605 5.605 0 006.2 8.471a2.505 2.505 0 01.073 1.317.614.614 0 01-.135.308l-.632.233c-2.446.9-4.214 1.549-4.753 2.626A9.167 9.167 0 000 16.5a.5.5 0 00.5.5h11.75a.25.25 0 00.25-.25z\"/><path d=\"M13.5 24a2.5 2.5 0 002.5-2.5v-7.463a.25.25 0 01.2-.246l5.5-1.03a.249.249 0 01.3.245v3.755a.255.255 0 01-.081.185.251.251 0 01-.192.064c-.074-.01-.15-.01-.227-.01a2.5 2.5 0 102.5 2.5v-6.795a2 2 0 00-2.368-1.967l-6 1.125A2 2 0 0014 13.83v4.931a.255.255 0 01-.081.185.251.251 0 01-.192.064c-.074-.01-.15-.01-.227-.01a2.5 2.5 0 000 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});