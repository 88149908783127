define("ember-svg-jar/inlined/history-witch-cauldron", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-witch-cauldron</title><path d=\"M17.75 22a1 1 0 01-1-1 .044.044 0 01.02-.037 5.872 5.872 0 00.962-.815 3.464 3.464 0 001.006-2.665 3.307 3.307 0 00-.738-1.51.5.5 0 00-.416-.223H2.917a.5.5 0 00-.416.223 3.307 3.307 0 00-.739 1.51 3.464 3.464 0 001.006 2.665 5.872 5.872 0 00.962.815.045.045 0 01.02.037 1 1 0 01-1 1 1 1 0 000 2 2.984 2.984 0 002.669-1.661.5.5 0 01.606-.25 13.42 13.42 0 004.225.661 13.42 13.42 0 004.225-.661.5.5 0 01.606.25A2.984 2.984 0 0017.75 24a1 1 0 000-2zM17.25 11.75H15a.5.5 0 01-.5-.5V7.24a1 1 0 00-2 0v4.01a.5.5 0 01-.5.5h-.25a.5.5 0 01-.5-.5V5.74a1 1 0 00-2 0v5.51a.5.5 0 01-.5.5H8.5a.5.5 0 01-.5-.5V6.74a1 1 0 00-2 0v4.51a.5.5 0 01-.5.5H3.25a1.5 1.5 0 000 3h14a1.5 1.5 0 000-3zM18.25 0h-4.5a1.5 1.5 0 00-1.3.751.5.5 0 01-.432.249H8.5a2.253 2.253 0 00-2.25 2.25.75.75 0 001.5 0A.25.25 0 018 3h6.25a1.5 1.5 0 001.3-.75.5.5 0 01.432-.25h2.268a1 1 0 000-2zM21.25 0a1 1 0 101 1 1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});