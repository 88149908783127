define("ember-svg-jar/inlined/army-shield-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-shield-alternate</title><path d=\"M21.541 1.88A24.147 24.147 0 0012 .01a24.167 24.167 0 00-9.542 1.87A2.473 2.473 0 00.966 4.139v7.3a12.838 12.838 0 008.508 11.9l1.072.4a4.214 4.214 0 002.909 0l1.07-.4a12.836 12.836 0 008.509-11.9v-7.3a2.472 2.472 0 00-1.493-2.259zm-.507 9.558c0 7.162-6.155 9.634-8.271 10.416a2.192 2.192 0 01-1.525 0c-2.206-.814-8.272-3.294-8.272-10.416v-7.3a.469.469 0 01.293-.427A22.129 22.129 0 0112 2.01a22.1 22.1 0 018.741 1.7.467.467 0 01.293.426z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});