define("ember-svg-jar/inlined/single-man-actions-flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-flash</title><path d=\"M16.4 12.13a.249.249 0 00-.095-.388L12.8 10.235a.249.249 0 01-.152-.229V8.87a.252.252 0 01.074-.177 3.311 3.311 0 00.778-2.231.251.251 0 01.088-.176 1.873 1.873 0 00.538-1.4 1.58 1.58 0 00-.317-1.015.249.249 0 01-.034-.264 2.531 2.531 0 00.156-1.727C13.586.493 11.884 0 10.531 0a3.751 3.751 0 00-3.163 1.324.252.252 0 01-.213.118 1.074 1.074 0 00-.9.375c-.371.493-.105 1.17.176 1.732a.251.251 0 01-.045.288 1.3 1.3 0 00-.162.2A1.763 1.763 0 005.962 5 1.522 1.522 0 006.6 6.331a.249.249 0 01.117.194A3.167 3.167 0 007.563 8.7a.25.25 0 01.08.183v1.123a.249.249 0 01-.151.229l-3.937 1.687c-1.272.546-1.455 1.7-1.707 3.308-.056.359-.117.751-.2 1.179a.5.5 0 00.495.591h10.2a.251.251 0 00.218-.127 1.19 1.19 0 01.13-.2zM21.536 16H20.25a.249.249 0 01-.25-.25V11.5a.5.5 0 00-.888-.316l-5.274 6.462A.822.822 0 0014.464 19h1.286a.25.25 0 01.25.25v4.25a.5.5 0 00.332.471.507.507 0 00.168.029.5.5 0 00.387-.184l5.272-6.461A.821.821 0 0021.536 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});