define("ember-svg-jar/inlined/video-meeting-skype", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-meeting-skype</title><path d=\"M12 22.35a10.37 10.37 0 001.58-.12.5.5 0 01.31.05A6.21 6.21 0 0023 16.75a6.26 6.26 0 00-.76-3 .5.5 0 01-.06-.31A10.34 10.34 0 0012 1.66a10.35 10.35 0 00-1.54.12.5.5 0 01-.31-.05 6.24 6.24 0 00-8.39 8.48.5.5 0 01.05.31A10.33 10.33 0 0012 22.35zM12.1 5c3.16.19 5 1.58 5.19 2.89a1.33 1.33 0 01-1.45 1.51c-1.43 0-1.58-1.91-4-1.91-1.11 0-2 .46-2 1.47 0 2.1 7.89.88 7.89 5.51 0 2.67-2.13 4.42-5.3 4.42-2.79 0-5.6-1.27-5.58-3.46a1.23 1.23 0 011.05-1.26c1.64 0 1.63 2.45 4.25 2.45 1.84 0 2.46-1 2.46-1.71 0-2.53-7.93-1-7.93-5.74C6.68 6.6 8.79 4.82 12.1 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});