define("ember-svg-jar/inlined/chef-gear-roller-whisk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chef-gear-roller-whisk</title><rect x=\"3.05\" y=\"5.346\" width=\"10.601\" height=\"6\" rx=\"1\" ry=\"1\" transform=\"rotate(-45 8.35 8.346)\"/><path d=\"M3.214 12.668a.5.5 0 00-.644-.054L.439 14.136a1.5 1.5 0 002.122 2.121l1.521-2.13a.5.5 0 00-.053-.644zM12.668 3.214a.5.5 0 01-.054-.644L14.136.439a1.5 1.5 0 012.121 2.122l-2.13 1.521a.5.5 0 01-.644-.053zM23.561 6.473a1.5 1.5 0 010 2.122l-3.536 3.535a.5.5 0 01-.707 0L17.9 10.716a.5.5 0 010-.707l3.535-3.536a1.5 1.5 0 012.126 0zM18.035 13.425a.75.75 0 000-1.061l-.353-.354a.752.752 0 00-1.061 0l-.881.881a.5.5 0 01-.515.12 5.966 5.966 0 00-3.584.148 10.593 10.593 0 00-4.131 2.306 5 5 0 107.071 7.07 10.589 10.589 0 002.306-4.13 5.959 5.959 0 00.148-3.584.5.5 0 01.12-.516zm-9.111 3.454a8.733 8.733 0 013.294-1.806 7.267 7.267 0 01.915-.214.25.25 0 01.216.424l-4.743 4.743a.252.252 0 01-.235.066.249.249 0 01-.18-.166 3 3 0 01.733-3.047zm6.048.948a8.711 8.711 0 01-1.8 3.294 3.072 3.072 0 01-3.025.718.249.249 0 01-.1-.416l4.716-4.723a.25.25 0 01.424.217 7.083 7.083 0 01-.215.91z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});