define("ember-svg-jar/inlined/natural-disaster-earthquake", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>natural-disaster-earthquake</title><path d=\"M1.766 8.613H3.25a.25.25 0 01.25.25v1.75a1 1 0 002 0v-1.75a.25.25 0 01.25-.25h1.484a1 1 0 00.916-1.4L5.415.985a1 1 0 00-1.831 0L.85 7.212a1 1 0 00.916 1.4zM16.766 8.613h1.484a.25.25 0 01.25.25v1.75a1 1 0 002 0v-1.75a.25.25 0 01.25-.25h1.484a1 1 0 00.916-1.4L20.415.985a1 1 0 00-1.831 0L15.85 7.212a1 1 0 00.916 1.4zM23.5 12.613h-8.716a.248.248 0 00-.192.09l-1.272 1.526a.5.5 0 00.064.7l2.136 1.78a.5.5 0 01-.015.781L10.947 21a.5.5 0 01-.769-.583l1.16-2.9a.5.5 0 00-.216-.62l-2.735-1.563a.5.5 0 01-.211-.631l.746-1.742a.249.249 0 00-.23-.348H.5a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-10a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});