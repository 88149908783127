define("ember-svg-jar/inlined/diet-waist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diet-waist</title><path d=\"M3.25 14.25a.5.5 0 00.379-.174l1.5-1.75a.5.5 0 000-.651l-1.5-1.75a.492.492 0 00-.552-.143.5.5 0 00-.327.468v.5a.25.25 0 01-.25.25H1.25a1 1 0 000 2H2.5a.25.25 0 01.25.25v.5a.5.5 0 00.327.469.472.472 0 00.173.031zM23.75 12a1 1 0 00-1-1H21.5a.25.25 0 01-.25-.25v-.5a.5.5 0 00-.327-.468.492.492 0 00-.552.143l-1.5 1.749a.5.5 0 000 .651l1.5 1.75a.5.5 0 00.379.174.472.472 0 00.173-.031.5.5 0 00.327-.469v-.5A.25.25 0 0121.5 13h1.25a1 1 0 001-1z\"/><path d=\"M19.191 12.159a10.76 10.76 0 01-1.464-7.427l.4-2.14A.5.5 0 0017.636 2H6.364a.5.5 0 00-.492.592l.4 2.14a10.76 10.76 0 01-1.462 7.427c-1.584 2.579-2.053 5.761-1.7 9.39a.5.5 0 00.5.451h7.14a.5.5 0 00.5-.5v-2.061a.5.5 0 00-.109-.313L9.04 16.5a.75.75 0 111.171-.937l1.4 1.749a.5.5 0 00.781 0l1.4-1.749a.75.75 0 111.171.937l-2.1 2.626a.5.5 0 00-.11.313V21.5a.5.5 0 00.5.5h7.14a.5.5 0 00.5-.451c.351-3.629-.119-6.811-1.702-9.39z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});