define("ember-svg-jar/inlined/wind-north", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wind-north</title><path d=\"M16.776 13.447A.5.5 0 0017 13.5a.5.5 0 00.472-.667l-3-8.5a.522.522 0 00-.944 0l-2.54 7.2a.249.249 0 00.133.312 4.009 4.009 0 011.226.849.251.251 0 00.29.048l1.251-.626a.252.252 0 01.224 0z\"/><path d=\"M24 10a10 10 0 10-19 4.359.25.25 0 00.225.141h1.536a.255.255 0 00.185-.081.251.251 0 00.064-.192C7 14.153 7 14.077 7 14v-.06a.247.247 0 00-.031-.125A8 8 0 1114 18a.167.167 0 00-.1.3 4.036 4.036 0 011.206 1.467.252.252 0 00.259.139A10.016 10.016 0 0024 10zM11.5 19H1a1 1 0 000 2h10.5a.5.5 0 010 1 1 1 0 000 2 2.5 2.5 0 000-5z\"/><path d=\"M1 18h8.5a2.5 2.5 0 000-5 1 1 0 000 2 .5.5 0 010 1H1a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});