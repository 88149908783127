define("ember-svg-jar/inlined/cloud-subtract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-subtract</title><path d=\"M24 11.328a5.766 5.766 0 00-5.217-5.936.251.251 0 01-.191-.121 7.684 7.684 0 00-14.1 2.3.249.249 0 01-.227.2 4.647 4.647 0 00-3.641 2.234A4.467 4.467 0 000 12.453a4.289 4.289 0 001.235 3.1 4.615 4.615 0 001.78 1.063.963.963 0 001.243-.861v-.026A.986.986 0 003.6 14.7a2.58 2.58 0 01-.96-.572A2.374 2.374 0 012 12.339a2.588 2.588 0 012.586-2.588 2.7 2.7 0 01.535.055 1 1 0 001.2-.963C6.409 3.107 14.617 1.47 17.142 6.8a.973.973 0 00.936.571h.01a3.734 3.734 0 013 1.31 3.878 3.878 0 01.9 2.8 3.427 3.427 0 01-1.474 2.682.972.972 0 00-.4.964l.01.06a.969.969 0 001.525.608A5.4 5.4 0 0024 11.328z\"/><path d=\"M18.5 15.965a6.5 6.5 0 10-6.5 6.5 6.508 6.508 0 006.5-6.5zm-4 .75h-5a.75.75 0 010-1.5h5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});