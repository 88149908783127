define("ember-svg-jar/inlined/composition-focus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>composition-focus</title><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2zm-2 8.75a.25.25 0 01-.25.25h-3.116a.25.25 0 01-.245-.2A6.507 6.507 0 0013.2 5.612a.252.252 0 01-.2-.246V2.25a.25.25 0 01.25-.25h8.25a.5.5 0 01.5.5zM10.75 2a.25.25 0 01.25.25v3.116a.25.25 0 01-.2.245A6.51 6.51 0 005.611 10.8a.25.25 0 01-.245.2H2.25a.25.25 0 01-.25-.25V2.5a.5.5 0 01.5-.5zM2 13.25a.25.25 0 01.25-.25h3.116a.25.25 0 01.245.2 6.507 6.507 0 005.189 5.188.252.252 0 01.2.246v3.116a.25.25 0 01-.25.25H2.5a.5.5 0 01-.5-.5zM13.25 22a.25.25 0 01-.25-.25v-3.116a.25.25 0 01.2-.245 6.51 6.51 0 005.189-5.189.25.25 0 01.245-.2h3.116a.25.25 0 01.25.25v8.25a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});