define("ember-svg-jar/inlined/security-remote-unlock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>security-remote-unlock</title><path d=\"M21.5 11.625a2 2 0 00-2-2h-.25a.5.5 0 01-.5-.5v-2.5a6.751 6.751 0 00-12.9-2.794 1.25 1.25 0 102.279 1.035 4.251 4.251 0 018.121 1.759v2.5a.5.5 0 01-.5.5H4.5a2 2 0 00-2 2v10.5a2 2 0 002 2h15a2 2 0 002-2zm-9.5 9.5a1.41 1.41 0 111.409-1.409A1.408 1.408 0 0112 21.125zm2.917-3.7a.75.75 0 01-1.054.113 2.945 2.945 0 00-3.725 0 .75.75 0 01-.938-1.171 4.436 4.436 0 015.609 0 .75.75 0 01.108 1.055zm1.666-2.41a.75.75 0 01-1.054.113 5.577 5.577 0 00-7.058 0 .75.75 0 01-.942-1.168 7.068 7.068 0 018.942 0 .75.75 0 01.112 1.052z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});