define("ember-svg-jar/inlined/ui-screen-split", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ui-screen-split</title><path d=\"M19 20.75a.5.5 0 01.5-.5H22a2 2 0 002-2v-16a2 2 0 00-2-2H2a2 2 0 00-2 2v16a2 2 0 002 2h2.5a.5.5 0 01.5.5v.5a2.5 2.5 0 002.5 2.5h9a2.5 2.5 0 002.5-2.5zm-17-18a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5zm0 6a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5zm11.916 12.624l-1.5 1a.75.75 0 01-.832 0l-1.5-1A.75.75 0 0110.5 20h3a.75.75 0 01.416 1.374zM2.5 18.25a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v3a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});