define("ember-svg-jar/inlined/single-woman-actions-location.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-location</title><path d=\"M11.956 16a6.413 6.413 0 01.457-2.376.5.5 0 00-.163-.583 7.448 7.448 0 00-4.5-1.541 7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h10.5a.5.5 0 00.493-.587 5.2 5.2 0 01-.081-.913zM18.456 11a5.005 5.005 0 00-5 5c0 3.171 4.457 7.664 4.647 7.854a.5.5 0 00.707 0c.19-.19 4.646-4.683 4.646-7.854a5.005 5.005 0 00-5-5zm1.75 5a1.75 1.75 0 11-1.75-1.75 1.752 1.752 0 011.75 1.75zM1.569 10.442a1 1 0 001.383-.3 5.233 5.233 0 00.454-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .249.249 0 01.411.082 5.233 5.233 0 00.454.9A1 1 0 0013.15 9.06a4.578 4.578 0 01-.643-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248a4.573 4.573 0 01-.642 2.512 1 1 0 00.298 1.382zM7.211 8.6A2.7 2.7 0 014.47 6.248a.5.5 0 01.324-.577 5.711 5.711 0 002.245-1.439.25.25 0 01.361 0 5.7 5.7 0 002.23 1.437.5.5 0 01.323.576A2.708 2.708 0 017.211 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});