define("ember-svg-jar/inlined/email-action-play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-play</title><path d=\"M5.12 13.041a.25.25 0 00.385-.21L5.5 2.25A.25.25 0 015.75 2l12.5-.019a.249.249 0 01.25.25l.01 10.594a.25.25 0 00.385.21l4.053-2.607a.248.248 0 00.115-.206.251.251 0 00-.107-.21L20.5 8.3V2.207a2.114 2.114 0 00-2-2.208h-13a2.115 2.115 0 00-2 2.209V8.3l-2.458 1.71a.249.249 0 00.007.415z\"/><path d=\"M18.409 15.131a.758.758 0 01-.409.119H6a.757.757 0 01-.405-.119l-5.21-3.35a.25.25 0 00-.385.21V22a2 2 0 002 2h20a2 2 0 002-2V11.993a.25.25 0 00-.385-.21z\"/><path d=\"M9.222 10.879a1.119 1.119 0 00.583.978 1.155 1.155 0 001.135-.014l4.5-2.629a1.112 1.112 0 000-1.928l-4.5-2.63a1.159 1.159 0 00-1.135-.013 1.119 1.119 0 00-.583.978z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});