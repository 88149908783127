define("ember-svg-jar/inlined/shipment-truck-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-truck-2</title><path d=\"M11.125 6.375a.966.966 0 00-.2-.579.244.244 0 01-.049-.165 1.231 1.231 0 01.363-.812l.914-.914a.75.75 0 00-1.06-1.06l-.914.914A2.7 2.7 0 009.434 5.2a.218.218 0 01-.215.179H5.125a5.006 5.006 0 00-5 5v8a2 2 0 002 2h1.347a.25.25 0 00.242-.312 2.784 2.784 0 01-.089-.688 3 3 0 016 0 2.784 2.784 0 01-.089.688.251.251 0 00.242.312h.347a.971.971 0 00.582-.2.249.249 0 01.149-.049h5.064a.25.25 0 00.247-.293 2.651 2.651 0 01-.042-.457 3 3 0 016 0 2.651 2.651 0 01-.042.457.249.249 0 00.055.2.252.252 0 00.192.089h.8a.75.75 0 00.75-.75v-3a1.752 1.752 0 00-1.75-1.75h-9.25a.25.25 0 00-.25.25v2a.75.75 0 01-1.5 0zm-9 4a3 3 0 013-3h.75a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25h-3.5a.25.25 0 01-.25-.25z\"/><circle cx=\"6.625\" cy=\"19.375\" r=\"2\"/><circle cx=\"19.125\" cy=\"19.375\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});