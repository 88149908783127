define("ember-svg-jar/inlined/fitness-bicycle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fitness-bicycle</title><path d=\"M19.723 7.483a1.614 1.614 0 01.021 1.084c-.1.286-.215.651-.28.8a1 1 0 001.7 1.043 5.126 5.126 0 00.476-1.21l.105-.31a2.7 2.7 0 00-.2-2.246 1.174 1.174 0 01.127-1.076l2.155-3.159a1 1 0 00-1.654-1.126l-2.616 3.84a2.38 2.38 0 00.119 2.235l.047.125zM21.155 15.339h-.005l-1.821-.532a1 1 0 01-.7-.746c-.282-1.28-.568-2.6-.8-3.663-.284-1.311-.5-2.295-.546-2.487-.215-.859-1.085-1.19-1.853-1.481a1.175 1.175 0 01-.72-.812l-.927-3.707a1 1 0 00-1.941.486L12.975 6.9c.2.808 1.024 1.121 1.75 1.4a1.3 1.3 0 01.811.94c.091.419 1.261 5.81 1.667 7.6a1 1 0 01-1.082 1.216l-10-1.071a1 1 0 10-.213 1.988l1.9.2a.25.25 0 01.139.436A2.939 2.939 0 016 20.348a3 3 0 112.466-4.69 1 1 0 00.72.431l6.158.66a.252.252 0 00.215-.084.249.249 0 00.055-.222c-.147-.621-.333-1.443-.535-2.345a1 1 0 00-.7-.742l-6.7-1.962A6 6 0 106 23.152h14a3.99 3.99 0 001.155-7.813z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});