define("ember-svg-jar/inlined/tennis-racquet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tennis-racquet</title><path d=\"M3.5 7A3.5 3.5 0 100 3.5 3.5 3.5 0 003.5 7zm0-5A1.5 1.5 0 112 3.5 1.5 1.5 0 013.5 2zM22 2.007C19.072-.916 14-.6 10.682 2.713a8.617 8.617 0 00-2.325 8.694.991.991 0 01.039.483 32.453 32.453 0 01-.445 2.183 2.989 2.989 0 01-.794 1.427l-2.22 2.221a.5.5 0 01-.451.137 1.272 1.272 0 00-1.142.329L.516 21.013a1.75 1.75 0 102.477 2.473l2.826-2.826a1.246 1.246 0 00.354-1.06.5.5 0 01.141-.425l2.252-2.253a2.99 2.99 0 011.424-.8c.652-.156 1.43-.321 2.269-.46a1 1 0 01.464.034 8.618 8.618 0 008.566-2.375C24.6 10.006 24.92 4.931 22 2.007zm-4.268 2.765l1.714-1.723A.5.5 0 0120.1 3a4.641 4.641 0 01.873.865.5.5 0 01-.042.658L19.212 6.25a.5.5 0 01-.707 0l-.773-.772a.5.5 0 010-.706zM14.905 2.48a.248.248 0 01.167-.179 6.356 6.356 0 012-.3.25.25 0 01.174.426l-.926.93a.5.5 0 01-.708 0l-.64-.641a.249.249 0 01-.067-.236zm-4.779 6.881a.25.25 0 01-.157-.227 6.218 6.218 0 01.137-1.408.25.25 0 01.421-.125l.432.432a.5.5 0 010 .707l-.564.566a.25.25 0 01-.269.055zm3.719 2.265l-1.337 1.339a.5.5 0 01-.667.037 5.793 5.793 0 01-.839-.84.5.5 0 01.035-.666l1.335-1.341a.5.5 0 01.708 0l.765.765a.5.5 0 010 .706zm-.769-5.013a.5.5 0 01-.708 0l-.968-.968A.5.5 0 0111.35 5a7.586 7.586 0 011.163-1.254.5.5 0 01.679.025L14.2 4.775a.5.5 0 010 .707zm3.389 7.112a.247.247 0 01-.188.173 6.165 6.165 0 01-1.412.136.25.25 0 01-.171-.427l.565-.566a.5.5 0 01.707 0l.437.436a.25.25 0 01.062.248zm-.5-4.222a.5.5 0 01-.708 0l-.768-.768a.5.5 0 010-.706L15.615 6.9a.5.5 0 01.708 0l.77.769a.5.5 0 010 .707zm4.3 1.986a7.743 7.743 0 01-1.252 1.164.5.5 0 01-.651-.048l-.98-.98a.5.5 0 010-.707l1.128-1.13a.5.5 0 01.708 0l1.022 1.022a.5.5 0 01.021.679zM21.523 9.1a.252.252 0 01-.237-.065l-.66-.66a.5.5 0 010-.707l.949-.951a.25.25 0 01.425.168 6.328 6.328 0 01-.3 2.045.248.248 0 01-.177.17z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});