define("ember-svg-jar/inlined/gesture-swipe-horizontal-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-horizontal-2</title><path d=\"M16.428 12.525l-3.741-1.117a.25.25 0 01-.179-.239V6.6a1 1 0 10-2 0v7.064a.25.25 0 01-.455.142c-1.49-2.138-3.855-1.449-2.761.224L10.2 18.4a.5.5 0 00.416.223h5.434a.5.5 0 00.493-.411c.2-1.1.664-3.683.775-4.165a1.282 1.282 0 00-.89-1.522zM23.835 9.1a.752.752 0 000-.938l-2-2.5A.747.747 0 0021 5.421a.688.688 0 00-.233.136.25.25 0 00-.03.344l1.432 1.79a1.5 1.5 0 010 1.874l-1.432 1.79a.251.251 0 00.03.345.722.722 0 00.233.136.749.749 0 00.833-.239zM1.829 9.565a1.5 1.5 0 010-1.874L3.261 5.9a.25.25 0 00-.03-.344A.688.688 0 003 5.421a.747.747 0 00-.833.238l-2 2.5a.752.752 0 000 .938l2 2.5a.75.75 0 00.585.281.735.735 0 00.481-.178.25.25 0 00.031-.344z\"/><path d=\"M5.75 6.128a.75.75 0 00-1.335-.469l-2 2.5a.752.752 0 000 .938l2 2.5a.75.75 0 001.335-.469zM21.585 9.1a.752.752 0 000-.938l-2-2.5a.751.751 0 00-1.335.469v5a.75.75 0 001.335.469z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});