define("ember-svg-jar/inlined/yoga-meditate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>yoga-meditate</title><circle cx=\"12.016\" cy=\"3\" r=\"3\"/><path d=\"M23.461 9.006a1.5 1.5 0 00-2.113.192 2.339 2.339 0 01-1.776.841h-.023a2.332 2.332 0 01-1.767-.809c-.762-.878-.762-.886-.83-.953a2.646 2.646 0 00-1.879-.777H8.927a2.642 2.642 0 00-1.878.777c-.069.067-.08.088-.83.953a2.334 2.334 0 01-1.768.809h-.023A2.338 2.338 0 012.652 9.2a1.5 1.5 0 00-2.3 1.922A5.336 5.336 0 004.4 13.039h.051A5.257 5.257 0 008.087 11.6a.25.25 0 01.421.182l.013 5.258a.5.5 0 00.334.47l2.813.993a1 1 0 00.665 0l2.812-.993a.5.5 0 00.334-.47l.013-5.26a.25.25 0 01.421-.182 5.255 5.255 0 003.636 1.438h.051a5.33 5.33 0 004.052-1.921 1.5 1.5 0 00-.191-2.109zM17.87 17.612l-3.7 1.306a.248.248 0 00-.166.235.251.251 0 00.166.236l2.135.754a2.477 2.477 0 01.924.577.5.5 0 00.518.116l1.12-.4a1.5 1.5 0 00-1-2.828zM15.973 21.086l-9.842-3.474a1.5 1.5 0 10-1 2.828l9.842 3.474a1.481 1.481 0 00.5.086 1.5 1.5 0 00.5-2.914z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});