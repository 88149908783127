define("ember-svg-jar/inlined/smart-watch-square-upload-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-upload-alternate</title><path d=\"M21 6a3 3 0 00-3-3h-1.078a.249.249 0 01-.24-.182L15.98.365A.5.5 0 0015.5 0h-7a.5.5 0 00-.481.363l-.7 2.455A.248.248 0 017.077 3H6a3 3 0 00-3 3v12a3 3 0 003 3h1.077a.249.249 0 01.24.181l.7 2.456A.5.5 0 008.5 24h7a.5.5 0 00.48-.363l.7-2.456a.25.25 0 01.24-.181H18a3 3 0 003-3zm-2 12a1 1 0 01-1 1H6a1 1 0 01-1-1V6a1 1 0 011-1h12a1 1 0 011 1z\"/><path d=\"M12 7.5a4.5 4.5 0 104.5 4.5A4.5 4.5 0 0012 7.5zm1.962 4.191A.5.5 0 0113.5 12h-.75a.25.25 0 00-.25.25V14a.5.5 0 11-1 0v-1.75a.25.25 0 00-.25-.25h-.75a.5.5 0 01-.354-.854l1.5-1.5a.5.5 0 01.707 0l1.5 1.5a.5.5 0 01.109.547z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});