define("ember-svg-jar/inlined/video-game-boxing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-boxing</title><path d=\"M17.5 0h-11A5.506 5.506 0 001 5.5V11a9.436 9.436 0 003.771 7.59 1.992 1.992 0 001.213.41h9.041a2 2 0 001.2-.4 9.513 9.513 0 003.636-6.086A.874.874 0 0019 11.5H8.75A3.5 3.5 0 015.25 8V5.75a.75.75 0 011.5 0V8a2 2 0 002 2H19a4.188 4.188 0 002.552-.875 1.981 1.981 0 00.408-.42A5.49 5.49 0 0017.5 0zM15.5 20.5H5a.5.5 0 00-.5.5v1.5A1.5 1.5 0 006 24h8.5a1.5 1.5 0 001.5-1.5V21a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});