define("ember-svg-jar/inlined/photography-equipment-tripod", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>photography-equipment-tripod</title><path d=\"M23.221 22.505L15.948 9.326A3 3 0 0013.3 7.742h-2.6a3 3 0 00-2.647 1.584L.779 22.507a1 1 0 101.748.97L8.41 12.871a.251.251 0 01.219-.129h2.121a.249.249 0 01.25.25v10a1 1 0 102 0v-10a.25.25 0 01.25-.25h2.122a.251.251 0 01.219.129l5.882 10.6a1 1 0 101.748-.97z\"/><rect x=\"10.5\" y=\"3.992\" width=\"3\" height=\"3\" rx=\".25\" ry=\".25\"/><path d=\"M8.5 1.5A1.5 1.5 0 0010 3h4a1.5 1.5 0 00.279-.026.249.249 0 01.222.067l2.314 2.267a.247.247 0 01.074.172 1.136 1.136 0 00.169.564l.556.905a4.467 4.467 0 00.694.871l1.429 1.4a1.5 1.5 0 102.1-2.142l-1.427-1.4A4.6 4.6 0 0019.522 5l-.916-.537a1.13 1.13 0 00-.567-.156.247.247 0 01-.174-.072l-2.314-2.266a.249.249 0 01-.071-.22 1.569 1.569 0 00.02-.249A1.5 1.5 0 0014 0h-4a1.5 1.5 0 00-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});