define("ember-svg-jar/inlined/app-window-password-correct", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-password-correct</title><path d=\"M14.251 16a.75.75 0 00-.75-.75h-8a.249.249 0 01-.25-.25v-4a.249.249 0 01.25-.25h13a.25.25 0 01.25.25v.637a.75.75 0 101.5 0v-.887a1.5 1.5 0 00-1.5-1.5h-13.5a1.5 1.5 0 00-1.5 1.5v4.5a1.5 1.5 0 001.5 1.5H13.5a.75.75 0 00.751-.75z\"/><circle cx=\"8.501\" cy=\"13.001\" r=\"1\"/><circle cx=\"12.501\" cy=\"13.001\" r=\"1\"/><path d=\"M15 18.251a4.5 4.5 0 104.5-4.5 4.505 4.505 0 00-4.5 4.5zm6.732-1.763a.75.75 0 01.15 1.05l-1.937 2.583a1.238 1.238 0 01-.913.5.91.91 0 01-.091 0 1.245 1.245 0 01-.879-.366l-1-1a.75.75 0 011.06-1.061l.594.594a.249.249 0 00.377-.027l1.591-2.121a.746.746 0 011.048-.152z\"/><path d=\"M24 4.251a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h9.5a1 1 0 001-1 1 1 0 00-1-1H3a1 1 0 01-1-1V6.5a.25.25 0 01.25-.25h19.5a.25.25 0 01.25.25v5.25a1 1 0 001 1 1 1 0 001-1zm-19.654 0a.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.987.987 0 01.092-.409 1 1 0 01.908-.591.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5zm3.5 0a.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5zm3.5 0a.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});