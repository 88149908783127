define("ember-svg-jar/inlined/antenna-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>antenna-1</title><path d=\"M11.609 8.074l-4.7-4.7a2.556 2.556 0 00-3.535 0 2.5 2.5 0 000 3.535l4.7 4.7a.5.5 0 00.707 0l2.828-2.828a.5.5 0 000-.707zM12.516 6.259a1 1 0 001.414 0l1.415-1.415a3 3 0 000-4.242A1 1 0 0013.93.6L11.1 3.43a1 1 0 000 1.414zM4.845 11.1a1 1 0 00-1.415 0L.6 13.93a1 1 0 000 1.414 3 3 0 004.243 0l1.416-1.414a1 1 0 000-1.414zM12.812 14.58a.25.25 0 01.354 0l.53.53a1 1 0 001.414-1.41l-.53-.53a.25.25 0 010-.354l.884-.884a.5.5 0 000-.707 3.075 3.075 0 00-4.243 0 3 3 0 000 4.243.5.5 0 00.707 0z\"/><path d=\"M13.1 17.632a1 1 0 00-.283 1.98 6.226 6.226 0 00.869.062 6.005 6.005 0 005.919-6.884 1 1 0 10-1.978.293 4 4 0 01-4.527 4.549z\"/><path d=\"M23.628 12.523a.991.991 0 00-1.105-.884 1 1 0 00-.884 1.105 8.052 8.052 0 01-8.9 8.9 1 1 0 00-.221 1.989 10.205 10.205 0 001.128.062 10.056 10.056 0 009.984-11.167z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});