define("ember-svg-jar/inlined/file-ym", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-ym</title><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.251A.25.25 0 0014.75 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.251A.25.25 0 0021.75 7zm-9.625 6.5a2.621 2.621 0 01-1.825 2.492.247.247 0 00-.176.233V18.5a.625.625 0 01-1.25 0v-2.275a.247.247 0 00-.177-.233A2.622 2.622 0 016.875 13.5v-1a.625.625 0 111.25 0v1a1.375 1.375 0 002.75 0v-1a.625.625 0 111.25 0zm5 5a.625.625 0 11-1.25 0v-2.975c0-.136-.148-.081-.188 0l-.128.256a.65.65 0 01-1.118 0s-.1-.187-.136-.271-.18-.066-.18.01v2.98a.625.625 0 11-1.25 0v-6a.625.625 0 011.184-.279s.747 1.484.794 1.587a.182.182 0 00.3-.006l.791-1.581a.625.625 0 011.184.279z\"/><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.428A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});