define("ember-svg-jar/inlined/single-neutral-actions-laptop.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-laptop</title><path d=\"M10.5 14a2.967 2.967 0 01.575-1.756.5.5 0 00.086-.4.505.505 0 00-.239-.33A7.4 7.4 0 007.207 10.5a7.543 7.543 0 00-7.185 5.357.5.5 0 00.478.643H10a.5.5 0 00.5-.5z\"/><circle cx=\"7.001\" cy=\"4.75\" r=\"4.75\"/><path d=\"M23.894 22.635a.243.243 0 00-.018-.032l-1.785-2.868a.53.53 0 00-.091-.086V14a1.5 1.5 0 00-1.5-1.5h-7A1.5 1.5 0 0012 14v5.648a.536.536 0 00-.091.087l-1.8 2.9a.92.92 0 00.043.929.957.957 0 00.809.436h12.081a.957.957 0 00.809-.436.92.92 0 00.043-.929zM14 14.75a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25v4a.249.249 0 01-.25.25h-5.5a.25.25 0 01-.25-.25zm1.516 7.631a.242.242 0 010-.239l.273-.476a.232.232 0 01.2-.118h1.855a.231.231 0 01.2.118l.273.476a.242.242 0 010 .239.232.232 0 01-.2.119h-2.4a.232.232 0 01-.201-.119z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});