define("ember-svg-jar/inlined/safety-warning-slippery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-warning-slippery</title><path d=\"M.665 20.918a1.437 1.437 0 001.275 2.1h20.121a1.436 1.436 0 001.273-2.1L13.274 1.752a1.437 1.437 0 00-2.547 0L.666 20.917zm13.556-12.7a1.438 1.438 0 11-1.413 1.462 1.438 1.438 0 011.413-1.462zM6.723 15.6a.718.718 0 01.143-1.006l1.443-1.084a1.67 1.67 0 011.756-.16l1.432.717a.239.239 0 00.2 0 .242.242 0 00.134-.154l.36-1.262a.24.24 0 00-.091-.251 3.576 3.576 0 01-1.457-2.215l-.22-1.1a.718.718 0 111.409-.28l.22 1.1a2.158 2.158 0 001.058 1.456l.864.487a2.161 2.161 0 001.9-.017l.63-.316a.719.719 0 01.644 1.286l-.631.316a3.6 3.6 0 01-1.608.377 3.475 3.475 0 01-1.135-.2.239.239 0 00-.309.16l-.525 1.836c-.25.872-.734.888-2.164 1.813a1.215 1.215 0 00-.269.237l-1.186 1.41a.719.719 0 01-1.1-.923L9.4 16.409a2.583 2.583 0 01.6-.522l.488-.316a.24.24 0 00-.023-.416l-1.038-.519a.242.242 0 00-.251.023l-1.447 1.082a.716.716 0 01-1.006-.141zm-1.91 4.325h14.374a.719.719 0 110 1.437H4.813a.719.719 0 110-1.437z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});