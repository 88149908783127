define("ember-svg-jar/inlined/alarm-bell-timer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>alarm-bell-timer</title><path d=\"M9.625 21.376h-3a.5.5 0 00-.5.5 2 2 0 004 0 .5.5 0 00-.5-.5zM11.135 6.25a5.972 5.972 0 00-1.51-.644v-1.23a1.5 1.5 0 10-3 0v1.23c-2.921.791-5 3.777-5 7.27v.646a13.771 13.771 0 01-1.447 6.13.5.5 0 00.447.724h15a.5.5 0 00.447-.724 13.785 13.785 0 01-1.447-6.13v-.646a8.661 8.661 0 00-.054-.972 7.007 7.007 0 01-3.436-5.654zM18.125.126a5.75 5.75 0 105.75 5.75 5.757 5.757 0 00-5.75-5.75zm0 9.5a3.75 3.75 0 113.75-3.75 3.754 3.754 0 01-3.75 3.75z\"/><path d=\"M19.625 5.126h-.75v-1.25a.75.75 0 00-1.5 0v2a.75.75 0 00.75.75h1.5a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});