define("ember-svg-jar/inlined/road-sign-airport-close-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-airport-close-alternate</title><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/><path d=\"M17.9 13.976l-.009-.008-1.2-1.055a.244.244 0 00-.045-.032.255.255 0 00-.256 0l-1.337.8a.249.249 0 01-.271-.009l-1.476-1.03a.25.25 0 01.011-.418l3.294-2.039a.243.243 0 00.057-.049.329.329 0 000-.431.36.36 0 00-.053-.05L15.731 9a.33.33 0 00-.312-.044l-4.893 1.821a.25.25 0 01-.228-.027L8.085 9.236a1.292 1.292 0 00-1.018-.2 1.381 1.381 0 00-.99.889A1.294 1.294 0 006 10.37a1.364 1.364 0 00.637 1.181l7.852 4.876a.483.483 0 00.532-.015L17.89 14.4a.288.288 0 00.048-.044.267.267 0 00-.038-.379z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});