define("ember-svg-jar/inlined/farming-scarecrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>farming-scarecrow</title><path d=\"M23 10.5h-3.563a.5.5 0 01-.484-.375A1.5 1.5 0 0017.5 9h-1.6a.25.25 0 01-.217-.375A4.224 4.224 0 0016.25 6.5v-1a.5.5 0 01.5-.5H18a1 1 0 001-1V3a1 1 0 00-1-1h-1.671a.5.5 0 01-.472-.333A2.5 2.5 0 0013.5 0h-3a2.5 2.5 0 00-2.357 1.667.5.5 0 01-.472.333H6a1 1 0 00-1 1v1a1 1 0 001 1h1.25a.5.5 0 01.5.5v1a4.224 4.224 0 00.571 2.125A.25.25 0 018.1 9H6.5a1.5 1.5 0 00-1.453 1.125.5.5 0 01-.484.375H1a1 1 0 000 2h3.5a.5.5 0 01.5.5 1 1 0 001 1h.71a1.5 1.5 0 011.49 1.334l.3 2.721a.5.5 0 00.5.445h1.087a.5.5 0 01.5.455l.372 4.093a1.047 1.047 0 002.086 0l.372-4.092a.5.5 0 01.5-.455H15a.5.5 0 00.5-.445l.3-2.721A1.5 1.5 0 0117.29 14H18a1 1 0 001-1 .5.5 0 01.5-.5H23a1 1 0 000-2zm-8.75-4a2.25 2.25 0 01-4.5 0v-1a.5.5 0 01.5-.5h3.5a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});