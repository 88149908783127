define("ember-svg-jar/inlined/adventure-car-convertible", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>adventure-car-convertible</title><path d=\"M22.073 10.567l-4.807-.6a.251.251 0 01-.2-.164l-2.125-5.892A1 1 0 0014 3.25h-1.75a1 1 0 000 2h.872a.25.25 0 01.235.165l1.154 3.208a.25.25 0 01-.324.318l-1.027-.4a.249.249 0 01-.16-.228V8a.75.75 0 00-1.5 0v2.5a.75.75 0 001.5 0v-.044a.253.253 0 01.108-.206.249.249 0 01.232-.027l1.5.577a.25.25 0 01.16.233v1.217a.5.5 0 01-.5.5H11a1.577 1.577 0 01-.48-.078.249.249 0 01-.167-.184L9.164 7.114a1 1 0 00-1.953.432l.42 1.9a.251.251 0 01-.049.21.247.247 0 01-.2.094H6.5a.25.25 0 01-.25-.25V6a1.5 1.5 0 00-1.5-1.5 2.369 2.369 0 00-2.039 1.182L.37 10.275a1.482 1.482 0 00-.37.975v5a1.5 1.5 0 001.21 1.472.25.25 0 00.3-.229 4 4 0 017.985.022.25.25 0 00.249.235h4.515a.25.25 0 00.25-.235 4 4 0 017.985-.022.25.25 0 00.3.229A1.5 1.5 0 0024 16.25v-3.5a2.2 2.2 0 00-1.927-2.183zM4.25 9.5a.25.25 0 01-.25.25h-.711a.25.25 0 01-.223-.363l.711-1.4a.25.25 0 01.473.113z\"/><path d=\"M5.5 14.75a3 3 0 103 3 3 3 0 00-3-3zm0 4a1 1 0 111-1 1 1 0 01-1 1zM18.5 14.75a3 3 0 103 3 3 3 0 00-3-3zm0 4a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});