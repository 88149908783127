define("ember-svg-jar/inlined/army-symbol-non-specific-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-symbol-non-specific-1</title><path d=\"M22 3H2a2 2 0 00-2 2v14a2 2 0 002 2h20a2 2 0 002-2V5a2 2 0 00-2-2zm0 15.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M18.5 6.5h-13a2 2 0 00-2 2v7a2 2 0 002 2h13a2 2 0 002-2v-7a2 2 0 00-2-2zm0 8.5a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5V9a.5.5 0 01.5-.5h12a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});