define("ember-svg-jar/inlined/appliances-slow-cooker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>appliances-slow-cooker</title><circle cx=\"12\" cy=\"18.881\" r=\"1.259\"/><path d=\"M20.353 13.375a.251.251 0 00.206-.392 8.727 8.727 0 00-6.1-3.423.25.25 0 01-.21-.247v-.938a.25.25 0 01.25-.25.75.75 0 000-1.5h-5a.75.75 0 000 1.5.25.25 0 01.25.25v.939a.248.248 0 01-.211.246 8.73 8.73 0 00-6.1 3.423.249.249 0 00.206.392zm-9.1-5a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v.759a.251.251 0 01-.262.25c-.159-.008-.319-.015-.488-.015s-.329.007-.488.015a.251.251 0 01-.262-.25zM7.023 4.308a.75.75 0 00.982 1.134c1.375-1.191.531-2.409.126-2.994-.483-.7-.449-.726-.126-1.006A.75.75 0 007.023.308C5.648 1.5 6.492 2.717 6.9 3.3c.48.7.447.728.123 1.008zM11.509 4.308a.75.75 0 00.982 1.134c1.375-1.191.531-2.409.126-2.994-.483-.7-.45-.726-.126-1.006a.75.75 0 10-.982-1.134c-1.375 1.192-.531 2.409-.126 2.992.483.7.45.728.126 1.008zM16.009 4.308a.75.75 0 00.982 1.134c1.375-1.191.531-2.409.126-2.994-.483-.7-.45-.726-.126-1.006a.75.75 0 10-.982-1.134c-1.375 1.192-.531 2.409-.126 2.992.483.7.45.728.126 1.008zM23.25 14.875H.75a.75.75 0 000 1.5h2a.25.25 0 01.25.25v4.75a2.5 2.5 0 002.5 2.5h13a2.5 2.5 0 002.5-2.5v-4.75a.25.25 0 01.25-.25h2a.75.75 0 000-1.5zM17 16.625h1a.75.75 0 010 1.5h-1a.75.75 0 010-1.5zm0 3h1a.75.75 0 010 1.5h-1a.75.75 0 010-1.5zm-5-3.5a2.759 2.759 0 11-2.759 2.759A2.762 2.762 0 0112 16.122z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});