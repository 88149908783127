define("ember-svg-jar/inlined/payment-stripe-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>payment-stripe-alternate</title><path d=\"M12.87 10.19c-1.77-.69-1.77-1-1.77-1.41S11.43 8 12.38 8a3.87 3.87 0 011.83.45l.61.27a.48.48 0 00.68-.44V5.65a.5.5 0 00-.3-.46 6.79 6.79 0 00-2.77-.53C9.58 4.67 7.51 6.47 7.51 9c0 3 2.91 4 3.85 4.37C13 14 13 14.42 13 14.81c0 .93-1 1.07-1.54 1.07a4.54 4.54 0 01-2.28-.64L8.75 15a.5.5 0 00-.75.43v2.67a.5.5 0 00.3.46 7.85 7.85 0 003.06.62c3.63 0 5.25-2.26 5.25-4.49-.01-2.69-2-3.83-3.74-4.5z\"/><path d=\"M22 .25H2A1.75 1.75 0 00.25 2v20A1.75 1.75 0 002 23.75h20A1.75 1.75 0 0023.75 22V2A1.75 1.75 0 0022 .25zM22.25 22a.25.25 0 01-.25.25H2a.25.25 0 01-.25-.25V2A.25.25 0 012 1.75h20a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});