define("ember-svg-jar/inlined/browser-user-help-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>browser-user-help-message</title><path d=\"M8.929 4.079a.251.251 0 00-.035-.225.249.249 0 00-.2-.1H3.5A3 3 0 00.5 6.7v16.052a1 1 0 002 0V8.251a.5.5 0 01.5-.5h5.282a.25.25 0 00.249-.272 8.694 8.694 0 01-.031-.728 8.493 8.493 0 01.429-2.672zM15 22.751a1 1 0 102 0V15.5a.251.251 0 00-.243-.25 8.518 8.518 0 01-1.457-.17.251.251 0 00-.3.245zM16.934 13.384a6.567 6.567 0 000-13.133A6.432 6.432 0 0010.5 6.818a6.665 6.665 0 00.659 3.106.253.253 0 010 .223l-.87 1.826-.744 1.563a.5.5 0 00.451.715.51.51 0 00.215-.048l1.563-.745 1.826-.868a.25.25 0 01.226.006 6.534 6.534 0 003.108.788zM19.25 5.5A2.253 2.253 0 0117 7.751a.75.75 0 010-1.5.75.75 0 10-.75-.75.75.75 0 11-1.5 0 2.25 2.25 0 014.5 0zM17 8.751a.75.75 0 11-.75.75.75.75 0 01.75-.75z\"/><circle cx=\"8.67\" cy=\"17.251\" r=\"2\"/><path d=\"M8.67 20.251a3.594 3.594 0 00-3.294 2.317.5.5 0 00.47.683h5.647a.5.5 0 00.471-.683 3.594 3.594 0 00-3.294-2.317z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});