define("ember-svg-jar/inlined/color-drop-pick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-drop-pick</title><path d=\"M18 6.372a.248.248 0 00-.044-.141 45.632 45.632 0 00-1.509-2.057.25.25 0 00-.447.154v18.408a.253.253 0 00.115.211.25.25 0 00.24.016 9.224 9.224 0 001.542-.905.249.249 0 00.1-.2zM14.5 1.927a.249.249 0 00-.063-.166 50.436 50.436 0 00-1.22-1.333 1.474 1.474 0 00-.345-.266.25.25 0 00-.372.219v23.238a.25.25 0 00.267.25 10.754 10.754 0 001.537-.218.249.249 0 00.2-.244zM11 .515a.126.126 0 00-.217-.087C8.839 2.468 2.5 9.539 2.5 14.906a8.946 8.946 0 008.221 8.927.251.251 0 00.279-.249zM19.5 19.806a.25.25 0 00.456.142 8.8 8.8 0 001.544-5.042A12.273 12.273 0 0019.973 9.6a.25.25 0 00-.473.112z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});