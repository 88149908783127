define("ember-svg-jar/inlined/network-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>network-clock</title><path d=\"M10.213 22.677a1 1 0 00-.729-1.213 9.848 9.848 0 01-5.574-3.74.25.25 0 01.2-.4h2.059a.252.252 0 01.235.164 13.025 13.025 0 001.151 2.346 1 1 0 101.7-1.063 10.8 10.8 0 01-.642-1.171.25.25 0 01.143-.341.987.987 0 00.663-.931 1 1 0 00-1-1h-.388a.25.25 0 01-.244-.2 15.388 15.388 0 01-.1-6.012.252.252 0 01.25-.2c10.8 0 13.4.011 14.115-.02a.939.939 0 00.9-1.341A11.821 11.821 0 0011.822 0C1.755.424 0 9.183 0 11.875a11.86 11.86 0 009 11.53 1.021 1.021 0 00.243.029 1 1 0 00.97-.757zM5.724 15.03a.252.252 0 01-.246.3H2.8a.249.249 0 01-.236-.167A9.866 9.866 0 012.4 9.1a.251.251 0 01.24-.179h2.75a.251.251 0 01.247.288 17.731 17.731 0 00-.2 2.669 17.26 17.26 0 00.287 3.152zm6.012-12.766a.251.251 0 01.291 0 10 10 0 013.3 4.308.251.251 0 01-.231.346H8.66a.25.25 0 01-.231-.346 9.945 9.945 0 013.307-4.308zM20.2 6.533a.251.251 0 01.008.255.248.248 0 01-.219.13h-2.222a.25.25 0 01-.237-.171 13.049 13.049 0 00-1.73-3.438.25.25 0 01.31-.371 9.781 9.781 0 014.09 3.595zM7.616 2.975a.25.25 0 01.313.369 13.02 13.02 0 00-1.7 3.4.25.25 0 01-.229.174H3.8a.25.25 0 01-.209-.386 9.921 9.921 0 014.025-3.557z\"/><path d=\"M17.317 10.628a6.688 6.688 0 106.688 6.687 6.7 6.7 0 00-6.688-6.687zm-4.688 6.687A4.688 4.688 0 1117.317 22a4.692 4.692 0 01-4.688-4.685z\"/><path d=\"M19.3 16.315h-.728a.25.25 0 01-.25-.25v-1.222a1 1 0 10-2 0v2.472a1 1 0 001 1H19.3a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});