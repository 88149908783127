define("ember-svg-jar/inlined/video-edit-magic-wand-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-edit-magic-wand-1</title><path d=\"M21 5a2 2 0 00-2-2H5a2 2 0 00-2 2v11.508a.25.25 0 00.427.177l1.5-1.5A.251.251 0 005 15.008V5.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v13a.5.5 0 01-.5.5H8.992a.249.249 0 00-.177.073l-1.5 1.5a.25.25 0 00.178.427H19a2 2 0 002-2z\"/><path d=\"M24 3a3 3 0 00-3-3H9a1 1 0 000 2h12a1 1 0 011 1v12a1 1 0 002 0zM8.516 16.9a.247.247 0 00.353 0l1.263-1.262a1 1 0 000-1.414l-.354-.354a1 1 0 00-1.414 0L7.1 15.131a.249.249 0 000 .353zM7.809 17.959a.251.251 0 000-.354L6.4 16.191a.252.252 0 00-.354 0L.293 21.939a1 1 0 000 1.415l.353.353a1 1 0 001.415 0zM7.93 9.995l.264.038a1.508 1.508 0 011.273 1.273l.038.265a.5.5 0 00.99 0l.038-.265a1.508 1.508 0 011.273-1.273l.264-.038a.5.5 0 000-.99l-.264-.038a1.508 1.508 0 01-1.273-1.273l-.033-.265a.5.5 0 00-.99 0l-.038.265a1.508 1.508 0 01-1.278 1.273l-.264.038a.5.5 0 000 .99zM16.507 13.764a1.786 1.786 0 01-1.271-1.271.247.247 0 00-.472 0 1.786 1.786 0 01-1.271 1.271.247.247 0 000 .472 1.786 1.786 0 011.271 1.271.247.247 0 00.472 0 1.786 1.786 0 011.271-1.271.247.247 0 000-.472z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});