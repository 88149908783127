define("ember-svg-jar/inlined/printer-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>printer-view</title><path d=\"M6.5 5a1 1 0 001-1V2.5A.5.5 0 018 2h7.376a.5.5 0 01.353.147l.621.621a.5.5 0 01.146.353V4a1 1 0 002 0V2.5a1 1 0 00-.292-.707L16.7.292A1 1 0 0015.993 0H7a1.5 1.5 0 00-1.5 1.5V4a1 1 0 001 1zM12.187 20.991H8.5a1 1 0 01-1-1v-4a1 1 0 011-1h4.692a.25.25 0 00.2-.105 6.238 6.238 0 0110.222.036.249.249 0 00.271.1.141.141 0 00.1-.137V9a2.5 2.5 0 00-2.5-2.5H2.5A2.5 2.5 0 000 9v6a2.5 2.5 0 002.5 2.5h2.748a.25.25 0 01.25.25v3.749c0 .855.826 1.5 1.922 1.5h4.827a.951.951 0 00.8-1.447l-.016-.029a.973.973 0 00-.844-.532zM4.5 9.495a1 1 0 11-1-1 1 1 0 011 1z\"/><path d=\"M22.429 21.015a.25.25 0 01-.04-.3 4.438 4.438 0 00.6-2.222 4.5 4.5 0 10-4.5 4.5 4.442 4.442 0 002.222-.6.251.251 0 01.3.039l1.278 1.278a1 1 0 001.413-1.413zm-6.436-2.523a2.5 2.5 0 112.5 2.5 2.5 2.5 0 01-2.5-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});