define("ember-svg-jar/inlined/multiple-man-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-man-1</title><path d=\"M.5 18.064h1.79a.248.248 0 01.247.227l.438 5.255a.494.494 0 00.488.454H7.42a.5.5 0 00.493-.454l.438-5.255a.247.247 0 01.246-.227h1.791a.5.5 0 00.495-.5v-3.457a5.442 5.442 0 10-10.883 0v3.462a.494.494 0 00.5.495z\"/><path d=\"M5.441 0a4.2 4.2 0 104.205 4.205A4.209 4.209 0 005.441 0zm0 6.431a2.229 2.229 0 01-2.226-2.226 2.132 2.132 0 01.157-.8.245.245 0 01.152-.143.247.247 0 01.207.024 5.313 5.313 0 002.8.792 5.332 5.332 0 00.9-.076.2.2 0 01.237.2 2.228 2.228 0 01-2.227 2.229zM13.612 18.064H15.4a.247.247 0 01.246.227l.438 5.255a.5.5 0 00.496.454h3.957a.494.494 0 00.493-.454l.438-5.255a.248.248 0 01.247-.227h1.79a.494.494 0 00.5-.5v-3.457a5.442 5.442 0 10-10.883 0v3.462a.5.5 0 00.49.495z\"/><path d=\"M18.559 0a4.2 4.2 0 104.2 4.205A4.209 4.209 0 0018.559 0zm0 6.431a2.228 2.228 0 01-2.226-2.226 2.132 2.132 0 01.156-.8.245.245 0 01.152-.143.249.249 0 01.208.024 5.31 5.31 0 002.8.792 5.353 5.353 0 00.9-.076.2.2 0 01.237.2 2.229 2.229 0 01-2.227 2.229zM.5 18.064h1.79a.248.248 0 01.247.227l.438 5.255a.494.494 0 00.488.454H7.42a.5.5 0 00.493-.454l.438-5.255a.247.247 0 01.246-.227h1.791a.5.5 0 00.495-.5v-3.457a5.442 5.442 0 10-10.883 0v3.462a.494.494 0 00.5.495z\"/><path d=\"M5.441 0a4.2 4.2 0 104.205 4.205A4.209 4.209 0 005.441 0zm0 6.431a2.229 2.229 0 01-2.226-2.226 2.132 2.132 0 01.157-.8.245.245 0 01.152-.143.247.247 0 01.207.024 5.313 5.313 0 002.8.792 5.332 5.332 0 00.9-.076.2.2 0 01.237.2 2.228 2.228 0 01-2.227 2.229zM13.612 18.064H15.4a.247.247 0 01.246.227l.438 5.255a.5.5 0 00.496.454h3.957a.494.494 0 00.493-.454l.438-5.255a.248.248 0 01.247-.227h1.79a.494.494 0 00.5-.5v-3.457a5.442 5.442 0 10-10.883 0v3.462a.5.5 0 00.49.495z\"/><path d=\"M18.559 0a4.2 4.2 0 104.2 4.205A4.209 4.209 0 0018.559 0zm0 6.431a2.228 2.228 0 01-2.226-2.226 2.132 2.132 0 01.156-.8.245.245 0 01.152-.143.249.249 0 01.208.024 5.31 5.31 0 002.8.792 5.353 5.353 0 00.9-.076.2.2 0 01.237.2 2.229 2.229 0 01-2.227 2.229z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});