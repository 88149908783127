define("ember-svg-jar/inlined/multiple-users-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-users-1</title><path d=\"M15.867 10.459a.5.5 0 00.122-.843 6.165 6.165 0 00-1.448-.9.249.249 0 01-.036-.438 4.517 4.517 0 10-4.919 0 .25.25 0 01-.035.438 6.156 6.156 0 00-1.48.924.5.5 0 00.12.839 6.044 6.044 0 012.344 1.859.5.5 0 00.4.2h2.134a.5.5 0 00.4-.2 6.042 6.042 0 012.398-1.879zM9.674 3.7a.25.25 0 01.372-.125 5.8 5.8 0 003.172.943 5.7 5.7 0 001.036-.1.25.25 0 01.295.258 2.512 2.512 0 01-5.02-.161 2.41 2.41 0 01.145-.815zM10.267 15.978a4.517 4.517 0 10-6.976 3.765.251.251 0 01-.035.438 6.059 6.059 0 00-1.087.621A6.141 6.141 0 00.316 22.9.749.749 0 00.977 24h9.546a.749.749 0 00.661-1.1 6.141 6.141 0 00-1.853-2.1 6.059 6.059 0 00-1.087-.621.25.25 0 01-.035-.438 4.491 4.491 0 002.058-3.763zm-6.889-.818a.25.25 0 01.372-.125 5.8 5.8 0 003.172.943 5.7 5.7 0 001.036-.1.255.255 0 01.21.058.25.25 0 01.085.2 2.512 2.512 0 01-5.02-.161 2.41 2.41 0 01.145-.815zM21.831 20.8a6.059 6.059 0 00-1.087-.621.25.25 0 01-.035-.438 4.517 4.517 0 10-4.918 0 .251.251 0 01-.035.438 6.059 6.059 0 00-1.087.621 6.141 6.141 0 00-1.853 2.1.749.749 0 00.661 1.1h9.546a.749.749 0 00.661-1.1 6.141 6.141 0 00-1.853-2.1zm-5.953-5.64a.25.25 0 01.372-.125 5.8 5.8 0 003.172.943 5.7 5.7 0 001.036-.1.255.255 0 01.21.058.25.25 0 01.085.2 2.512 2.512 0 01-5.02-.161 2.41 2.41 0 01.145-.815z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});