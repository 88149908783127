define("ember-svg-jar/inlined/text-input-area", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>text-input-area</title><path d=\"M17.25 21.975h-1.432A2.822 2.822 0 0113 19.157v-2.569a.25.25 0 01.25-.25h1.137a1 1 0 000-2H13.25a.249.249 0 01-.25-.25v-9.25a2.821 2.821 0 012.818-2.817h1.432a1 1 0 000-2h-1.432a4.815 4.815 0 00-3.63 1.652.249.249 0 01-.376 0A4.811 4.811 0 008.182.021H6.75a1 1 0 000 2h1.432A2.821 2.821 0 0111 4.838v9.25a.249.249 0 01-.25.25H9.613a1 1 0 100 2h1.137a.25.25 0 01.25.25v2.569a2.822 2.822 0 01-2.818 2.818H6.75a1 1 0 000 2h1.432a4.807 4.807 0 003.63-1.653.251.251 0 01.188-.086.254.254 0 01.189.086 4.805 4.805 0 003.629 1.653h1.432a1 1 0 000-2zM1.5 15.384a1 1 0 001-1v-1.909a1 1 0 00-2 0v1.909a1 1 0 001 1zM1.5 10.612a1 1 0 00.977-.787.252.252 0 01.191-.191 1 1 0 00-.214-1.977A1.957 1.957 0 00.5 9.612a1 1 0 001 1z\"/><path d=\"M5.318 9.657h1.91a1 1 0 000-2h-1.91a1 1 0 000 2zM2.685 17.264a.249.249 0 01-.2-.2 1 1 0 00-1.985.184A1.957 1.957 0 002.454 19.2a1 1 0 001.024-.978.959.959 0 00-.793-.958zM8.228 18.2a1 1 0 00-1-1h-1.91a1 1 0 000 2h1.91a1 1 0 001-1zM22.5 11.475a1 1 0 00-1 1v1.909a1 1 0 002 0v-1.909a1 1 0 00-1-1zM21.315 9.6a.249.249 0 01.2.2 1 1 0 001.985-.188 1.957 1.957 0 00-1.954-1.955 1 1 0 00-1.024.977.959.959 0 00.793.966zM18.682 7.657h-1.91a1 1 0 000 2h1.91a1 1 0 000-2zM22.5 16.248a1 1 0 00-.977.786.252.252 0 01-.191.192 1 1 0 00.214 1.977 1.957 1.957 0 001.954-1.955 1 1 0 00-1-1zM18.682 17.2h-1.91a1 1 0 100 2h1.91a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});