define("ember-svg-jar/inlined/video-file-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-shield</title><path d=\"M23.414 3L21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.266a.248.248 0 00.244.25c.368 0 1.045.021 1.5.047A.246.246 0 008 9.314V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-7.01a.242.242 0 00-.235.184 8.292 8.292 0 01-.529 1.46.244.244 0 00.217.355H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M12.313 10.525c.136.049.258.1.36.141a3.035 3.035 0 011.759 2.188.25.25 0 00.365.182l4.3-2.381a.735.735 0 000-1.31l-5.843-3.236a.886.886 0 00-.834-.009.753.753 0 00-.42.665V10a.485.485 0 00.313.525zM12.072 12.04A16.386 16.386 0 006.479 11a15.988 15.988 0 00-5.551 1.04A1.545 1.545 0 000 13.456v3.393a7.716 7.716 0 005.19 6.805A9 9 0 006.479 24a9.023 9.023 0 001.288-.346C10.848 22.48 13 19.682 13 16.849v-3.393a1.545 1.545 0 00-.928-1.416zM9.5 17.25h-2a.25.25 0 00-.25.25v2a.75.75 0 01-1.5 0v-2a.25.25 0 00-.25-.25h-2a.75.75 0 010-1.5h2a.25.25 0 00.25-.25v-2a.75.75 0 011.5 0v2a.25.25 0 00.25.25h2a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});