define("ember-svg-jar/inlined/artboard-shrink", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>artboard-shrink</title><path d=\"M23.707.293a1 1 0 00-1.414 0L15.61 6.975a.249.249 0 01-.229.068A1.838 1.838 0 0015 7H8.75a.25.25 0 01-.25-.25V5.5A1.5 1.5 0 007 4H4.5A1.5 1.5 0 003 5.5v1.25a.25.25 0 01-.25.25H1.5A1.5 1.5 0 000 8.5V11a1.5 1.5 0 001.5 1.5h1.25a.25.25 0 01.25.25V19a2 2 0 002 2h6.25a.25.25 0 01.25.25v1.25A1.5 1.5 0 0013 24h2.5a1.5 1.5 0 001.5-1.5v-1.25a.25.25 0 01.25-.25h1.25a1.5 1.5 0 001.5-1.5v-2a1.5 1.5 0 00-1.5-1.5h-1.25a.25.25 0 01-.25-.25V9a1.855 1.855 0 00-.043-.382.249.249 0 01.068-.229l6.682-6.682a1 1 0 000-1.414zM14.5 9a.5.5 0 01.5.5V17a1 1 0 001 1h1.75a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H16a1 1 0 00-1 1v1.75a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25V20a1 1 0 00-1-1h-7a.5.5 0 01-.5-.5v-7a1 1 0 00-1-1H2.25a.25.25 0 01-.25-.25v-1A.25.25 0 012.25 9H4a1 1 0 001-1V6.25A.25.25 0 015.25 6h1a.25.25 0 01.25.25V8a1 1 0 001 1z\"/><rect x=\"6.75\" y=\"10.75\" width=\"6.5\" height=\"6.5\" rx=\"1.25\" ry=\"1.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});