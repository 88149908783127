define("ember-svg-jar/inlined/credit-card-smartphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-smartphone</title><path d=\"M20 2a2 2 0 00-2-2H2a2 2 0 00-2 2v1a.25.25 0 00.25.25h19.5A.25.25 0 0020 3zM14.5 13.509a3 3 0 012.974-3.009h2.276a.25.25 0 00.25-.25V5a.25.25 0 00-.25-.25H.25A.25.25 0 000 5v8a2 2 0 002 2h12.25a.25.25 0 00.25-.25zM6 12.25H3a.75.75 0 010-1.5h3a.75.75 0 010 1.5zm3.5-3.5H3a.75.75 0 010-1.5h6.5a.75.75 0 010 1.5z\"/><path d=\"M22.526 12h-5.052A1.493 1.493 0 0016 13.509v8.982A1.493 1.493 0 0017.474 24h5.052A1.493 1.493 0 0024 22.491v-8.982A1.493 1.493 0 0022.526 12zM22 20.5a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5h3a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});