define("ember-svg-jar/inlined/real-estate-settings-house-wrench", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-settings-house-wrench</title><path d=\"M16.991 8.335a.5.5 0 00.69-.016l.438-.438a.5.5 0 01.523-.117 4 4 0 005.315-4.342.5.5 0 00-.848-.281l-.894.894a1.591 1.591 0 11-2.25-2.25l.894-.894a.5.5 0 00-.281-.849 4 4 0 00-4.342 5.316.5.5 0 01-.117.523l-.5.5a.5.5 0 00.018.723zM20 16a3.99 3.99 0 00-1.357.236.5.5 0 01-.523-.117l-.448-.448a.5.5 0 00-.823.182 2.5 2.5 0 01-.947 1.206.5.5 0 00-.074.768l.292.292a.5.5 0 01.117.523 4 4 0 004.342 5.316.5.5 0 00.281-.849l-.894-.894a1.591 1.591 0 112.25-2.25l.894.894a.5.5 0 00.849-.281A4 4 0 0020 16zM4 8a3.99 3.99 0 001.357-.236.5.5 0 01.523.117l.438.438a.5.5 0 00.69.016L8.363 7.1a.5.5 0 00.017-.723l-.5-.5a.5.5 0 01-.117-.523A4 4 0 003.422.042a.5.5 0 00-.282.849l.894.894a1.591 1.591 0 11-2.249 2.25l-.894-.894a.5.5 0 00-.849.281A4 4 0 004 8zM7.151 15.853a.5.5 0 00-.822-.182l-.448.448a.5.5 0 01-.523.117 4 4 0 00-5.316 4.342.5.5 0 00.849.281l.894-.894a1.591 1.591 0 012.249 2.25l-.893.894a.5.5 0 00.281.849 4 4 0 004.342-5.316.5.5 0 01.117-.523l.292-.292a.5.5 0 00-.074-.768 2.508 2.508 0 01-.948-1.206zM15.5 12.5a.5.5 0 01.5-.5h1.5a.5.5 0 00.336-.87l-5.163-4.694a1 1 0 00-1.345 0L6.164 11.13A.5.5 0 006.5 12H8a.5.5 0 01.5.5V15a1 1 0 001 1h1a.5.5 0 00.5-.5V14a1 1 0 012 0v1.5a.5.5 0 00.5.5h1a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});