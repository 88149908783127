define("ember-svg-jar/inlined/picture-double-landscape", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>picture-double-landscape</title><path d=\"M3 13.5a1 1 0 01-1-1v-8a1 1 0 011-1h12.5a1 1 0 011 1v2.25a.25.25 0 00.25.25h1.5a.25.25 0 00.25-.25V4.5a3 3 0 00-3-3H3a3 3 0 00-3 3v8a3 3 0 003 3h.25a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25z\"/><path d=\"M24 11.5a3 3 0 00-3-3H8a3 3 0 00-3 3v8a3 3 0 003 3h13a3 3 0 003-3zm-2 8a1 1 0 01-1 1H8a1 1 0 01-1-1v-8a1 1 0 011-1h13a1 1 0 011 1z\"/><path d=\"M18.746 15.492a.892.892 0 00-1.513-.131l-1 1.354a.25.25 0 01-.408-.009L13.131 12.7a.442.442 0 00-.756.037L9.4 18.263a.5.5 0 00.44.737h9.854a.5.5 0 00.447-.724z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});