define("ember-svg-jar/inlined/hospital-bedroom-nurse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hospital-bedroom-nurse</title><circle cx=\"6\" cy=\"15.5\" r=\"2.5\"/><path d=\"M23 15a1 1 0 00-1 1v3a.5.5 0 01-.5.5h-19A.5.5 0 012 19v-5.5a1 1 0 00-2 0V23a1 1 0 002 0v-.25a.5.5 0 01.5-.5h19a.5.5 0 01.5.5V23a1 1 0 002 0v-7a1 1 0 00-1-1z\"/><path d=\"M10 17.5a.5.5 0 00.5.5H20a.5.5 0 00.5-.5v-1A2.5 2.5 0 0018 14h-7a1 1 0 00-1 1z\"/><circle cx=\"19\" cy=\"4\" r=\"2.5\"/><path d=\"M15 12.5h8a.5.5 0 00.5-.5v-.5A3.5 3.5 0 0020 8h-2a3.5 3.5 0 00-3.5 3.5v.5a.5.5 0 00.5.5zM.5 6.75h2.75V9.5a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5V6.75H9.5a.5.5 0 00.5-.5v-2.5a.5.5 0 00-.5-.5H6.75V.5a.5.5 0 00-.5-.5h-2.5a.5.5 0 00-.5.5v2.75H.5a.5.5 0 00-.5.5v2.5a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});