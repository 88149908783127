define("ember-svg-jar/inlined/alarm-bell-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>alarm-bell-check</title><path d=\"M9.5 21.5h-3a.5.5 0 00-.5.5 2 2 0 004 0 .5.5 0 00-.5-.5zM11.619 6.789A6.091 6.091 0 009.5 5.731V4.5a1.5 1.5 0 10-3 0v1.23c-2.921.791-5 3.777-5 7.27v.646a13.771 13.771 0 01-1.447 6.13.5.5 0 00.447.724h15a.5.5 0 00.447-.724 13.785 13.785 0 01-1.447-6.13V13a8.541 8.541 0 00-.215-1.915 7.009 7.009 0 01-2.666-4.296zM18.5 0A5.5 5.5 0 1024 5.5 5.5 5.5 0 0018.5 0zm-1.125 8.126a.744.744 0 01-.53-.22l-1.5-1.5a.75.75 0 111.06-1.06l.97.969L20.6 3.1a.75.75 0 011.06 1.06l-3.75 3.75a.744.744 0 01-.535.216z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});