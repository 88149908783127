define("ember-svg-jar/inlined/history-lamp-genie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-lamp-genie</title><path d=\"M13.345 4h6a1 1 0 000-2H18.9a.019.019 0 01-.015-.008.018.018 0 010-.017A1.5 1.5 0 0017.458 0h-2.226a1.5 1.5 0 00-1.422 1.975.018.018 0 010 .017.018.018 0 01-.018.008h-.447a1 1 0 100 2zM3.328 14.491l.253.168A3.047 3.047 0 007.2 9.766L5.536 8.4a1.794 1.794 0 01-.213-2.57 1.8 1.8 0 012.331-.322l2.579 1.681a.25.25 0 01.113.213 1.6 1.6 0 00.818 1.417l1.8 1.043a.25.25 0 00.375-.216V6.522a.25.25 0 00-.12-.213l-.469-.284h-.005a1.6 1.6 0 00-.889-.206.214.214 0 01-.13-.034l-2.981-1.95a3.809 3.809 0 00-4.927.681 3.793 3.793 0 00.452 5.436l1.665 1.362A1.047 1.047 0 014.693 13l-.254-.169a2.6 2.6 0 00-2.879 4.315L3.592 18.5a.251.251 0 01.109.17.254.254 0 01-.052.195l-.262.327a1 1 0 101.561 1.25l.4-.5a.25.25 0 01.334-.051l.433.288a1 1 0 001.109-1.664l-.251-.168a.249.249 0 01-.109-.17.252.252 0 01.052-.194l.034-.043a1 1 0 10-1.561-1.25l-.17.213a.25.25 0 01-.334.052L2.67 15.481a.594.594 0 11.658-.99z\"/><path d=\"M18.345 12.049V5.375a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v6.674a.249.249 0 01-.16.233 6.021 6.021 0 00-3.84 5.593 5.948 5.948 0 00.89 3.131.249.249 0 00.213.119h9.795a.25.25 0 00.213-.119 5.957 5.957 0 00.889-3.131 6.021 6.021 0 00-3.84-5.593.25.25 0 01-.16-.233zM22.6 22H10.1a1 1 0 100 2h12.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});