define("ember-svg-jar/inlined/single-man-actions-share-1.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-share-1</title><path d=\"M15.959 15.284a.248.248 0 00.247-.247V14.1a5.443 5.443 0 00-10.886 0v.95a.248.248 0 00.28.25 1.533 1.533 0 01.215-.015z\"/><path d=\"M10.763 0a4.206 4.206 0 104.206 4.206A4.211 4.211 0 0010.763 0zm0 6.433a2.229 2.229 0 01-2.227-2.227 2.155 2.155 0 01.156-.8.249.249 0 01.36-.119 5.315 5.315 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM6.309 24a.99.99 0 00.99-.99v-5.555a.99.99 0 00-1.979 0v5.555a.989.989 0 00.989.99zM8.436 17.375a.489.489 0 00-.147.351v4.981a.5.5 0 00.5.5H16.7a2.02 2.02 0 001.98-1.907v-.2a.96.96 0 00-.989-.862h-6.928a.351.351 0 01-.32-.383.367.367 0 01.368-.367h1.935a.878.878 0 00.807-1.224 1.884 1.884 0 00-1.794-1l-2.948-.032a.3.3 0 00-.042 0 .612.612 0 00-.333.143z\"/><path d=\"M15.959 15.284a.248.248 0 00.247-.247V14.1a5.443 5.443 0 00-10.886 0v.95a.248.248 0 00.28.25 1.533 1.533 0 01.215-.015z\"/><path d=\"M10.763 0a4.206 4.206 0 104.206 4.206A4.211 4.211 0 0010.763 0zm0 6.433a2.229 2.229 0 01-2.227-2.227 2.155 2.155 0 01.156-.8.249.249 0 01.36-.119 5.315 5.315 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM6.309 24a.99.99 0 00.99-.99v-5.555a.99.99 0 00-1.979 0v5.555a.989.989 0 00.989.99zM8.436 17.375a.489.489 0 00-.147.351v4.981a.5.5 0 00.5.5H16.7a2.02 2.02 0 001.98-1.907v-.2a.96.96 0 00-.989-.862h-6.928a.351.351 0 01-.32-.383.367.367 0 01.368-.367h1.935a.878.878 0 00.807-1.224 1.884 1.884 0 00-1.794-1l-2.948-.032a.3.3 0 00-.042 0 .612.612 0 00-.333.143z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});