define("ember-svg-jar/inlined/pool-player-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pool-player-table</title><circle cx=\"6.5\" cy=\"3.499\" r=\"2.5\"/><path d=\"M17.479 1.2a1 1 0 10-1.958-.4l-2.14 10.46L10.5 8.378A3 3 0 008.377 7.5H4a4.005 4.005 0 00-4 4v3.61a1.25 1.25 0 102.5 0v-3.86a.5.5 0 011 0v11.5a1.25 1.25 0 002.5 0v-6.14a.5.5 0 011 0v6.14a1.25 1.25 0 002.5 0V11.619l3.161 3.161-1.64 8.02a1 1 0 101.958.4l1.6-7.821a1.487 1.487 0 00.495-2.418zM23.5 12H18a1 1 0 00-1 1v1.5a1 1 0 001 1h5.5a.5.5 0 00.5-.5v-2.5a.5.5 0 00-.5-.5zM23.5 16.5h-4a.5.5 0 00-.38.175.5.5 0 00-.114.4l1 6.5A.5.5 0 0020.5 24h3a.5.5 0 00.5-.5V17a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});