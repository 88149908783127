define("ember-svg-jar/inlined/casino-chip-hold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>casino-chip-hold</title><path d=\"M12.75 2.575a.25.25 0 00.2.244 4.25 4.25 0 011.8.9.251.251 0 00.312.009l1.828-1.37a.246.246 0 00.1-.183.25.25 0 00-.074-.195A6.984 6.984 0 0013.037.04a.247.247 0 00-.2.058.249.249 0 00-.087.189zM8.942 3.732a.248.248 0 00.311-.009 4.25 4.25 0 011.8-.9.25.25 0 00.2-.244V.287a.249.249 0 00-.09-.187.247.247 0 00-.2-.058A6.988 6.988 0 007.084 1.98a.256.256 0 00-.074.195.246.246 0 00.1.183zM15.058 10.194a.25.25 0 00-.311.009 4.239 4.239 0 01-1.8.9.25.25 0 00-.2.244v2.288a.249.249 0 00.287.247 6.975 6.975 0 003.878-1.94.249.249 0 00.074-.2.252.252 0 00-.1-.183zM16.25 6.963a4.216 4.216 0 01-.369 1.73.25.25 0 00.079.3l1.831 1.374a.25.25 0 00.37-.081 7.006 7.006 0 000-6.649.25.25 0 00-.161-.124.255.255 0 00-.2.044l-1.84 1.374a.249.249 0 00-.078.3 4.235 4.235 0 01.368 1.732zM11.25 11.351a.25.25 0 00-.2-.244 4.239 4.239 0 01-1.8-.9.25.25 0 00-.311-.009l-1.829 1.37a.248.248 0 00-.025.378 6.975 6.975 0 003.878 1.94.25.25 0 00.287-.247zM7.75 6.963a4.219 4.219 0 01.369-1.73.25.25 0 00-.079-.3L6.209 3.557a.255.255 0 00-.2-.044.25.25 0 00-.166.126 7.006 7.006 0 000 6.649.251.251 0 00.166.125.248.248 0 00.2-.044L8.04 8.995a.25.25 0 00.079-.3 4.216 4.216 0 01-.369-1.732zM4.124 15.8a1.4 1.4 0 00-.458 1.823L5 19.839a.5.5 0 11-.857.516l-1.352-2.25a2.472 2.472 0 01.125-2.485.5.5 0 00.084-.276v-2.881a1.5 1.5 0 00-3 0v5.171a4.5 4.5 0 00.986 2.812l2.664 3.329a.5.5 0 00.39.188H8a.5.5 0 00.5-.5v-3.01a4.492 4.492 0 00-.757-2.5c-1.887-1.965-2.477-2.916-3.619-2.153zM19.876 15.8a1.4 1.4 0 01.458 1.823L19 19.839a.5.5 0 10.857.516l1.352-2.25a2.472 2.472 0 00-.125-2.485.5.5 0 01-.084-.276v-2.881a1.5 1.5 0 013 0v5.171a4.5 4.5 0 01-.986 2.812l-2.664 3.329a.5.5 0 01-.39.188H16a.5.5 0 01-.5-.5v-3.01a4.492 4.492 0 01.757-2.5c1.887-1.965 2.477-2.916 3.619-2.153z\"/><circle cx=\"12\" cy=\"6.963\" r=\"2.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});