define("ember-svg-jar/inlined/eye-idea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>eye-idea</title><path d=\"M13.25 17.416h-2.5a1 1 0 000 2 .167.167 0 01.167.167 1 1 0 002 0 .167.167 0 01.166-.167h.167a1 1 0 000-2zM12 6.833a4.361 4.361 0 00-4.25 4.25 4.126 4.126 0 001.65 3.25.25.25 0 01.1.2v1.051a.75.75 0 00.75.75h3.5a.75.75 0 00.75-.75v-1.052a.25.25 0 01.1-.2 4.126 4.126 0 001.652-3.25A4.361 4.361 0 0012 6.833z\"/><path d=\"M23.836 11.367c-.213-.324-5.3-7.951-11.836-7.951S.377 11.043.164 11.367a1 1 0 00.02 1.126 22.075 22.075 0 006.725 6.025 1 1 0 10.945-1.762 19.668 19.668 0 01-5.487-4.71.249.249 0 01-.006-.309C3.729 9.935 7.6 5.416 12 5.416s8.267 4.519 9.637 6.321a.251.251 0 010 .31 19.675 19.675 0 01-5.486 4.709 1 1 0 10.945 1.762 22.075 22.075 0 006.725-6.025 1 1 0 00.015-1.126z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});