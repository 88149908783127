define("ember-svg-jar/inlined/earpods", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earpods</title><path d=\"M5 0A6.215 6.215 0 00.388 1.986 1.5 1.5 0 000 2.993v5.014a1.5 1.5 0 00.389 1.008A6.214 6.214 0 005 11a6.445 6.445 0 001.392-.15.5.5 0 01.608.488V22.5A1.5 1.5 0 008.5 24h1a1.5 1.5 0 001.5-1.5v-17A5.772 5.772 0 005 0zM4 5.5a1 1 0 11-1-1 1 1 0 011 1zM24 2.993a1.5 1.5 0 00-.389-1.008A6.214 6.214 0 0019 0a5.772 5.772 0 00-6 5.5v17a1.5 1.5 0 001.5 1.5h1a1.5 1.5 0 001.5-1.5V11.338a.5.5 0 01.608-.488A6.445 6.445 0 0019 11a6.215 6.215 0 004.612-1.986A1.5 1.5 0 0024 8.007zM22 5.5a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});