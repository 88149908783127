define("ember-svg-jar/inlined/zip-file-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>zip-file-shield</title><path d=\"M9.5 16.849v-3.393a3.045 3.045 0 011.828-2.791 20.134 20.134 0 016.429-1.141.25.25 0 00.243-.247v-4.07a1.5 1.5 0 00-.439-1.06L13.854.439A1.5 1.5 0 0012.793 0H1.5A1.5 1.5 0 000 1.5v19A1.5 1.5 0 001.5 22h9.438a.246.246 0 00.195-.4A8.153 8.153 0 019.5 16.849zM6 18.5a1 1 0 111-1 1 1 0 01-1 1zM6 15a1 1 0 111-1 1 1 0 01-.995 1zm0-3.5a1 1 0 111-1 1 1 0 01-.995 1zm1.5-4.9a1.968 1.968 0 01-1.479.67 1.975 1.975 0 01-2.005-2.221l.49-3.127A.5.5 0 015 1.5h2a.5.5 0 01.494.423l.485 3.111v.015A1.985 1.985 0 017.508 6.6z\"/><path d=\"M24 13.456a1.543 1.543 0 00-.928-1.415A16.353 16.353 0 0017.479 11a15.983 15.983 0 00-5.551 1.04A1.545 1.545 0 0011 13.456v3.393a7.716 7.716 0 005.19 6.805l.553.211a2.068 2.068 0 001.47 0l.554-.211C21.848 22.48 24 19.682 24 16.849zM21.25 16.5a.75.75 0 01-.75.75h-2a.25.25 0 00-.25.25v2a.75.75 0 01-1.5 0v-2a.25.25 0 00-.25-.25h-2a.75.75 0 010-1.5h2a.25.25 0 00.25-.25v-2a.75.75 0 011.5 0v2a.25.25 0 00.25.25h2a.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});