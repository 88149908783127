define("ember-svg-jar/inlined/road-sign-speed-limit-80", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-speed-limit-80</title><path d=\"M16.274 9.536a1.728 1.728 0 00-1.726 1.726v2.476a1.726 1.726 0 003.452 0v-2.476a1.728 1.728 0 00-1.726-1.726zM8.226 12.012a1.726 1.726 0 101.726 1.726 1.728 1.728 0 00-1.726-1.726zM8.226 10.548a.774.774 0 10-.774-.774.775.775 0 00.774.774z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM8.226 16.964a3.218 3.218 0 01-1.89-5.829.25.25 0 00.081-.306 1.925 1.925 0 01-.179-.806 1.988 1.988 0 013.976 0 1.936 1.936 0 01-.178.806.249.249 0 00.08.306 3.218 3.218 0 01-1.89 5.829zM19.5 13.738a3.226 3.226 0 11-6.452 0v-2.476a3.226 3.226 0 116.452 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});