define("ember-svg-jar/inlined/file-copyright-dollar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-copyright-dollar</title><path d=\"M23.414 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.276a.248.248 0 00.242.249 19.68 19.68 0 011.485.083A.247.247 0 008 9.359V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.859a.245.245 0 00-.24.21 7.935 7.935 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.414A2 2 0 0023.414 3z\"/><path d=\"M6.5 11a6.5 6.5 0 106.5 6.5A6.508 6.508 0 006.5 11zm1.038 10.177c-.094.024-.288.109-.288.294v.279a.75.75 0 01-1.5 0v-.25a.248.248 0 00-.25-.25H5a.75.75 0 010-1.5h2.033a.592.592 0 00.22-1.141l-2.063-.825a2.085 2.085 0 01.344-3.978.333.333 0 00.216-.328v-.228a.75.75 0 011.5 0v.25a.25.25 0 00.25.25H8a.75.75 0 010 1.5H5.967a.592.592 0 00-.22 1.141l2.063.825a2.084 2.084 0 01-.272 3.961z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});