define("ember-svg-jar/inlined/signal-flags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>signal-flags</title><circle cx=\"11\" cy=\"5.625\" r=\"2.5\"/><path d=\"M2.575 5.809l.013-.009a.727.727 0 00.3-.937l-.952-2.139a.75.75 0 10-1.371.609l.953 2.144a.748.748 0 001.057.332zM3.819 5.07a.5.5 0 00.457.3.5.5 0 00.2-.043L8.133 3.7a.5.5 0 00.254-.66L7.167.3a.5.5 0 00-.66-.254L2.853 1.669a.5.5 0 00-.264.277.5.5 0 00.01.383zM19.733 10.628a.732.732 0 00.731.633h2.286a.75.75 0 000-1.5h-2.289a.742.742 0 00-.73.848z\"/><rect x=\"19.5\" y=\"12.261\" width=\"4\" height=\"5\" rx=\".5\" ry=\".5\"/><path d=\"M2.631 8.423l.635 1.254a5.218 5.218 0 004.451 2.468h.033A.249.249 0 018 12.4v3.71a1.01 1.01 0 01-.027.233L6.5 22.458a1.251 1.251 0 00.924 1.508 1.286 1.286 0 00.294.034 1.252 1.252 0 001.215-.958l1.238-5.142a.5.5 0 01.486-.384h.62a.5.5 0 01.486.384L13 23.042a1.252 1.252 0 001.216.958 1.286 1.286 0 00.293-.034 1.251 1.251 0 00.924-1.508l-1.4-5.843a.965.965 0 01-.033-.233V12.4a.249.249 0 01.25-.25h3.25a1.25 1.25 0 000-2.5H7.717a2.738 2.738 0 01-2.332-1.297L4.75 7.1a1.249 1.249 0 10-2.119 1.323z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});