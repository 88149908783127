define("ember-svg-jar/inlined/gauge-dashboard-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gauge-dashboard-1</title><path d=\"M12 16.25a16.79 16.79 0 019.152 2.737.5.5 0 00.683-.12A11.93 11.93 0 0024 11.877 12.111 12.111 0 0012.494.01 11.994 11.994 0 002.165 18.867a.5.5 0 00.683.12A16.792 16.792 0 0112 16.25zM11.25 3.5a.75.75 0 011.5 0V5a.75.75 0 01-1.5 0zM5 12.75H3.5a.75.75 0 010-1.5H5a.75.75 0 010 1.5zm2.58-5.17a.748.748 0 01-1.06 0L5.459 6.52A.75.75 0 016.52 5.46l1.06 1.059a.75.75 0 010 1.061zm4.339 6.866a2.366 2.366 0 01-1.687-4.052c.756-.755 7.042-4.63 7.756-5.07a.5.5 0 01.688.688c-.44.714-4.315 7-5.071 7.755a2.376 2.376 0 01-1.686.679zM19 12.75a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5z\"/><path d=\"M12 17.75a15.051 15.051 0 00-8.031 2.3.508.508 0 00-.076.794 11.975 11.975 0 0016.214 0 .507.507 0 00-.076-.793A15.046 15.046 0 0012 17.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});