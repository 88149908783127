define("ember-svg-jar/inlined/mood-peace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mood-peace</title><path d=\"M16.426 16.718a.249.249 0 00-.352 0 2.23 2.23 0 01-2.517.451.5.5 0 11.419-.909 1.228 1.228 0 00.524.115 1.251 1.251 0 001.25-1.25V13a.5.5 0 011 0v2.125a1.251 1.251 0 001.25 1.25 1.214 1.214 0 00.7-.243 1.734 1.734 0 00.55-1.257v-3a1.749 1.749 0 00-3.013-1.21 1.748 1.748 0 00-2.856-1.134l.719-7.484a1.75 1.75 0 10-3.48-.337L10 8.215l-.61-6.507a1.75 1.75 0 10-3.483.337l.866 8.107a.249.249 0 00.248.223H11a2.5 2.5 0 010 5 .1.1 0 00-.089.057.1.1 0 00.015.105A5 5 0 0112 18.875a.5.5 0 01-1 0c0-.821-.2-3.5-2.75-3.5a.5.5 0 010-1H11a1.5 1.5 0 000-3H6.906a2.253 2.253 0 00-2.156 2.25v4.25a3.255 3.255 0 003 3.241v2.509a.25.25 0 00.25.25h7a.25.25 0 00.25-.25v-3.009a3.286 3.286 0 002.99-2.982.24.24 0 00-.24-.259 2.222 2.222 0 01-1.574-.657z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});