define("ember-svg-jar/inlined/blocks-outline-o", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>blocks-outline-o</title><path d=\"M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm0-1c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C7.716 1 1 7.716 1 16c0 8.284 6.716 15 15 15zM8.143 8.143v5.714h5.714V8.143H8.143zM7.57 7h6.858c.315 0 .571.256.571.571v6.858a.571.571 0 01-.571.571H7.57A.571.571 0 017 14.429V7.57C7 7.256 7.256 7 7.571 7zm10.572 1.143v5.714h5.714V8.143h-5.714zM17.57 7h6.858c.315 0 .571.256.571.571v6.858a.571.571 0 01-.571.571H17.57a.571.571 0 01-.571-.571V7.57c0-.315.256-.571.571-.571zM8.143 18.143v5.714h5.714v-5.714H8.143zM7.57 17h6.858c.315 0 .571.256.571.571v6.858a.571.571 0 01-.571.571H7.57A.571.571 0 017 24.429V17.57c0-.315.256-.571.571-.571zm10.572 1.143v5.714h5.714v-5.714h-5.714zM17.57 17h6.858c.315 0 .571.256.571.571v6.858a.571.571 0 01-.571.571H17.57a.571.571 0 01-.571-.571V17.57c0-.315.256-.571.571-.571z\" fill=\"#29344C\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});