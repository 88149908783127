define("ember-svg-jar/inlined/road-sign-turn-left-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-turn-left-2</title><path d=\"M23.78 11.47L12.53.22a.749.749 0 00-1.06 0L.22 11.47a.749.749 0 000 1.06l11.25 11.25a.748.748 0 001.06 0l11.25-11.25a.749.749 0 000-1.06zm-9.03 5.03a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-4a.5.5 0 00-.5-.5H10a.249.249 0 00-.25.25v.65a.25.25 0 01-.427.177L7.6 11.353a.5.5 0 010-.707l1.719-1.72a.25.25 0 01.431.174v.65A.25.25 0 0010 10h2.75a2 2 0 012 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});