define("ember-svg-jar/inlined/cursor-select-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-select-heart</title><path d=\"M22.488 16.107l-10.377-3.892a.7.7 0 00-.934.938l3.931 10.335a.668.668 0 00.625.509h.034c.137-.008.468-.082.591-.586l1.142-4.688a.25.25 0 01.182-.184l4.733-1.182c.5-.126.575-.458.583-.594a.658.658 0 00-.51-.656zM7.127 13.543a1 1 0 00-1.436 1.394l1.949 2.006a1 1 0 101.435-1.393zM7.511 2.1A1 1 0 007.923.139a6.7 6.7 0 00-3.255.132 1 1 0 10.566 1.918A4.7 4.7 0 017.511 2.1zM2.02 6.054a4.4 4.4 0 01.458-1.577 4.64 4.64 0 01.322-.543A1 1 0 001.159 2.8 6.473 6.473 0 00.03 5.866a1 1 0 001.99.188zM3.328 9.632a4.346 4.346 0 01-.569-.686A1 1 0 001.1 10.059a6.371 6.371 0 00.811.982l1.185 1.221a1 1 0 001.435-1.394zM12 5a1 1 0 00.674-.26l1.108-1.011a1 1 0 10-1.348-1.478l-.266.243a.25.25 0 01-.337 0l-.266-.243a1 1 0 10-1.347 1.478l1.108 1.011A1 1 0 0012 5zM16.483 2.1a4.708 4.708 0 012.279.089 1 1 0 10.562-1.921 6.7 6.7 0 00-3.255-.127 1 1 0 00.414 1.959zM23.97 5.855a6.44 6.44 0 00-.662-2.282 6.663 6.663 0 00-.47-.786A1 1 0 0021.2 3.928a4.583 4.583 0 01.327.548 4.392 4.392 0 01.457 1.572 1 1 0 001.991-.193zM22.637 8.665a1 1 0 00-1.388.274 4.612 4.612 0 01-.591.709l-1.18 1.215a1 1 0 001.436 1.393l1.164-1.2a6.444 6.444 0 00.831-1 1 1 0 00-.272-1.391z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});