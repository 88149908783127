define("ember-svg-jar/inlined/messages-bubble-warning-triangle-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-warning-triangle-alternate</title><path d=\"M8.6 17.328a1 1 0 00-.661.038l-3.648 1.52a.25.25 0 01-.331-.316l.851-2.339a1 1 0 00-.276-1.089A7.043 7.043 0 012 9.9C2 5.546 6.486 2 12 2s10 3.546 10 7.9a6.485 6.485 0 01-.9 3.257.249.249 0 000 .241l.8 1.517a.25.25 0 00.424.03A8.577 8.577 0 0024 9.9C24 4.443 18.617 0 12 0S0 4.443 0 9.9a8.877 8.877 0 002.708 6.26l-1.6 4.4a1 1 0 001.325 1.265l5.959-2.483a14.1 14.1 0 002.023.369.249.249 0 00.249-.132l.755-1.439a.251.251 0 00-.206-.366 12.2 12.2 0 01-2.613-.446z\"/><path d=\"M18.781 12.271a1.45 1.45 0 00-2.562 0l-5.056 9.634a1.433 1.433 0 00.048 1.409 1.457 1.457 0 001.233.686h10.112a1.456 1.456 0 001.233-.687 1.431 1.431 0 00.048-1.408zM17.5 15.25a.75.75 0 01.75.75v2.5a.75.75 0 01-1.5 0V16a.75.75 0 01.75-.75zm0 7a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});