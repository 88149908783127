define("ember-svg-jar/inlined/view-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>view-off</title><path d=\"M23.2 10.549a20.954 20.954 0 00-4.3-3.6l4-3.995a1 1 0 10-1.414-1.414l-.018.018a.737.737 0 01-.173.291l-19.5 19.5c-.008.007-.018.009-.026.017A1 1 0 003.4 22.454l4.146-4.146a11.26 11.26 0 004.31.939h.3c4.256 0 8.489-2.984 11.051-5.8a2.171 2.171 0 00-.007-2.898zm-6.887 2.721a4.581 4.581 0 01-3 3.028 4.3 4.3 0 01-3.1-.19.253.253 0 01-.068-.407l5.56-5.559a.252.252 0 01.407.067 4.3 4.3 0 01.201 3.061zM7.615 13.4a.244.244 0 00.061-.24A4.315 4.315 0 017.5 12 4.5 4.5 0 0112 7.5a4.276 4.276 0 011.16.173.244.244 0 00.24-.062l1.941-1.942a.254.254 0 00-.1-.421A10.413 10.413 0 0012 4.75C7.7 4.692 3.4 7.7.813 10.549a2.15 2.15 0 00-.007 2.9 21.209 21.209 0 003.438 3.03.256.256 0 00.326-.029z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});