define("ember-svg-jar/inlined/delivery-truck-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>delivery-truck-3</title><path d=\"M22.5 3.625H11a1.5 1.5 0 00-1.485 1.286.249.249 0 01-.247.214H5.819a2.006 2.006 0 00-1.961 1.608l-.838 4.2a.088.088 0 01-.052.062 24.955 24.955 0 00-2.34.928A1.5 1.5 0 000 13.14v3.985a1.5 1.5 0 001.5 1.5h2.015a.25.25 0 00.186-.083.256.256 0 00.063-.194 1.842 1.842 0 01-.014-.223 3.75 3.75 0 017.5 0 1.842 1.842 0 01-.014.223.252.252 0 00.063.194.249.249 0 00.186.083h3.03a.25.25 0 00.186-.083.256.256 0 00.063-.194 1.842 1.842 0 01-.014-.223 3.75 3.75 0 017.5 0 1.842 1.842 0 01-.014.223.252.252 0 00.063.194.249.249 0 00.186.083h.015a1.5 1.5 0 001.5-1.5v-12a1.5 1.5 0 00-1.5-1.5zM5.779 7.326a.25.25 0 01.245-.2H8.75a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25H5.525a.25.25 0 01-.246-.3z\"/><circle cx=\"7.5\" cy=\"18.125\" r=\"2.25\"/><circle cx=\"18.5\" cy=\"18.125\" r=\"2.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});