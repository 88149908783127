define("ember-svg-jar/inlined/office-tape-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-tape-1</title><path d=\"M23 12.087a2.5 2.5 0 00-2.869-1.736L7.97 12.7a2.494 2.494 0 01-2.243-.686l-1.495-1.49A3.516 3.516 0 001.758 9.5H1a1 1 0 00-1 1 4.615 4.615 0 00.136 1.107l1.5 6A2.5 2.5 0 004.062 19.5h17.084a2.489 2.489 0 002.321-1.571l.312-.779a3.471 3.471 0 00.056-2.273z\"/><path d=\"M.929 8.5l7.951.564a.25.25 0 01.228.3A6.039 6.039 0 009 10.5v.183a.25.25 0 00.3.238l1.61-.311a.112.112 0 00.09-.11 4 4 0 017.669-1.592.251.251 0 00.277.146l.9-.174a4.126 4.126 0 01.581-.069.247.247 0 00.2-.11.254.254 0 00.028-.224 6 6 0 00-10.543-1.456.25.25 0 01-.221.105L1.071 6.5a1 1 0 00-.141 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});