define("ember-svg-jar/inlined/video-game-flower-enemy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-flower-enemy</title><path d=\"M9.363 18.979l-.9-2.55a2.487 2.487 0 00-1.815-1.608l-2.725-.6a1 1 0 00-1.163 1.304l.9 2.552a2.487 2.487 0 001.815 1.607l2.728.606a1 1 0 001.158-1.311zM21.257 18.6a1 1 0 00-.743-.694l-2.727-.606a2.482 2.482 0 00-2.324.687l-1.9 1.93a1 1 0 00.5 1.678l2.727.605a2.573 2.573 0 00.549.061 2.485 2.485 0 001.776-.748l1.9-1.929a1 1 0 00.242-.984z\"/><path d=\"M14.251 13.837a5.775 5.775 0 004.365-4.769l.372-2.686a1 1 0 01.3-.585l.278-.267A3.11 3.11 0 1015.421.9L13.275 3a.5.5 0 01-.7 0L10.435.9a3.11 3.11 0 00-5.307 2.2 3.053 3.053 0 001.095 2.376l.34.325a1 1 0 01.3.585l.371 2.685a5.778 5.778 0 004.253 4.743.5.5 0 01.37.532A24.536 24.536 0 019.3 22.519a1 1 0 101.753.962 25.887 25.887 0 002.818-9.2.5.5 0 01.38-.444zM11 7.279l-3.451-3.3a1.11 1.11 0 111.479-1.648l3.2 3.133a1 1 0 001.4 0l3.2-3.139a1.109 1.109 0 011.894.784 1.174 1.174 0 01-.479.923l-3.39 3.248a2.941 2.941 0 01-3.857 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});