define("ember-svg-jar/inlined/travel-insurance-cover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>travel-insurance-cover</title><path d=\"M9.368 20.392a.248.248 0 01-.059-.419 2.488 2.488 0 00.868-2.74A2.456 2.456 0 008.5 15.6 2.506 2.506 0 005.3 18a2.475 2.475 0 00.981 1.971.248.248 0 01-.056.42A3.486 3.486 0 004.3 23.483a.507.507 0 00.5.517h6a.507.507 0 00.5-.517 3.485 3.485 0 00-1.932-3.091zM17.868 20.392a.248.248 0 01-.059-.419 2.488 2.488 0 00.868-2.74A2.456 2.456 0 0017 15.6a2.506 2.506 0 00-3.2 2.4 2.475 2.475 0 00.981 1.971.248.248 0 01-.056.42 3.486 3.486 0 00-1.925 3.092.507.507 0 00.5.517h6a.507.507 0 00.5-.517 3.485 3.485 0 00-1.932-3.091zM13.214 2.088A.245.245 0 0113 1.846V1a1 1 0 00-2 0v.846a.245.245 0 01-.214.242 8.506 8.506 0 00-7.339 8.558V11a.5.5 0 001 0c0-.5.586-.917 1.28-.917s1.279.42 1.279.917a.5.5 0 001 0c0-.39.767-.842 1.758-.842a2.872 2.872 0 011.074.2.252.252 0 01.162.234V15.5a1 1 0 002 0v-4.912a.287.287 0 01.174-.239 2.892 2.892 0 011.063-.191c.99 0 1.757.452 1.757.842a.5.5 0 001 0c0-.5.586-.917 1.279-.917s1.28.42 1.28.917a.5.5 0 001 0v-.354a8.506 8.506 0 00-7.339-8.558z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});