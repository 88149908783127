define("ember-svg-jar/inlined/certified-diploma-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>certified-diploma-2</title><path d=\"M24 2.124a2 2 0 00-2-2H2a2 2 0 00-2 2v14.5a2 2 0 002 2h3.5a1 1 0 000-2H2.25a.25.25 0 01-.25-.25V6.685l4.561-4.561H21.75a.25.25 0 01.25.25v9.586a.249.249 0 01-.073.177l-3.957 3.957a.749.749 0 00-.22.53v1a1 1 0 001 1H22a2 2 0 002-2z\"/><path d=\"M12 9.9a4.963 4.963 0 00-3 8.928v4.551a.5.5 0 00.884.321L12 21.156l2.116 2.544a.5.5 0 00.884-.326v-4.551A4.963 4.963 0 0012 9.9zm0 2a2.98 2.98 0 11-2.979 2.979A2.983 2.983 0 0112 11.9z\"/><circle cx=\"12\" cy=\"14.874\" r=\"1.479\"/><path d=\"M14.5 5.374a.75.75 0 100-1.5h-5a.75.75 0 100 1.5zM17 6.645H7a.75.75 0 000 1.5h10a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});