define("ember-svg-jar/inlined/computer-chip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>computer-chip</title><path d=\"M2.435 11H1a1 1 0 000 2h1.435a1 1 0 100-2zM2.435 16H1a1 1 0 000 2h1.435a1 1 0 000-2zM2.435 6.5H1a1 1 0 100 2h1.435a1 1 0 100-2zM23 11h-1.435a1 1 0 000 2H23a1 1 0 000-2zM21.565 8.5H23a1 1 0 000-2h-1.435a1 1 0 000 2zM23 16h-1.435a1 1 0 000 2H23a1 1 0 000-2zM12 3.435a1 1 0 001-1V1a1 1 0 00-2 0v1.435a1 1 0 001 1zM7.5 3.435a1 1 0 001-1V1a1 1 0 10-2 0v1.435a1 1 0 001 1zM16.5 3.435a1 1 0 001-1V1a1 1 0 00-2 0v1.435a1 1 0 001 1zM12 20.566a1 1 0 00-1 1V23a1 1 0 102 0v-1.434a1 1 0 00-1-1zM16.5 20.566a1 1 0 00-1 1V23a1 1 0 102 0v-1.434a1 1 0 00-1-1zM7.5 20.566a1 1 0 00-1 1V23a1 1 0 002 0v-1.434a1 1 0 00-1-1zM18.067 4.5H5.933A1.435 1.435 0 004.5 5.934v12.133A1.436 1.436 0 005.933 19.5h12.134a1.436 1.436 0 001.433-1.433V5.934A1.435 1.435 0 0018.067 4.5zm-1.567 11a1 1 0 01-1 1H13a1 1 0 010-2h2.5a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});