define("ember-svg-jar/inlined/water-protection-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-protection-refresh</title><path d=\"M5.407 11.887a.75.75 0 00-.656-.387h-.436a.5.5 0 01-.5-.565 8.25 8.25 0 0114.656-4.053 1 1 0 001.568-1.241 10.25 10.25 0 00-18.245 5.4.5.5 0 01-.5.454H.75a.75.75 0 00-.636 1.147l2 3.2a.751.751 0 001.273 0l2-3.2a.75.75 0 00.02-.755zM23.886 11.353l-2-3.2a.781.781 0 00-1.273 0l-2 3.2a.75.75 0 00.636 1.147h.436a.5.5 0 01.5.565 8.25 8.25 0 01-14.656 4.053 1 1 0 00-1.568 1.241 10.25 10.25 0 0018.245-5.405.5.5 0 01.5-.454h.547a.75.75 0 00.636-1.147z\"/><path d=\"M12 6.4a.994.994 0 00-.836.452C9.982 8.65 8 11.909 8 13.5a4 4 0 008 0c0-1.592-1.982-4.85-3.164-6.649A.994.994 0 0012 6.4zm1 9.175a.5.5 0 01-.5.5 3 3 0 01-3-3 .5.5 0 111 0 2 2 0 002 2 .5.5 0 01.5.499z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});