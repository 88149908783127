define("ember-svg-jar/inlined/screen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>screen</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v12.25a.25.25 0 00.25.25h23.5a.25.25 0 00.25-.25V1.5A1.5 1.5 0 0022.5 0zM23.75 15.5H.25a.25.25 0 00-.25.25V17a1.5 1.5 0 001.5 1.5h8.25a.25.25 0 01.25.25v2.183a.25.25 0 01-.22.248 14.386 14.386 0 00-3.664.892A1 1 0 006.492 24H17.5a1 1 0 00.377-1.926 14.373 14.373 0 00-3.657-.892.25.25 0 01-.22-.248v-2.183a.25.25 0 01.25-.25h8.25A1.5 1.5 0 0024 17v-1.25a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});