define("ember-svg-jar/inlined/farming-barn-silo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>farming-barn-silo</title><path d=\"M1.809 6.125h10.882a.5.5 0 00.5-.568 5.992 5.992 0 00-11.874 0 .5.5 0 00.5.568zM23 21.625h-.25a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h.5a.75.75 0 00.46-1.342L16.777 9.9a1.286 1.286 0 00-1.547-.005l-6.94 5.388a.75.75 0 00.46 1.342h.5a.5.5 0 01.5.5v4a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-2.688a.5.5 0 00-.318-.466 2.251 2.251 0 01-.563-3.871l5.688-4.419a.5.5 0 00.193-.395V8.125a.5.5 0 00-.5-.5h-11a.5.5 0 00-.5.5v13.5H1a1 1 0 000 2h22a1 1 0 000-2zm-3.5-.5a.5.5 0 01-.5.5h-6a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h6a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});