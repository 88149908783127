define("ember-svg-jar/inlined/tux", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tux</title><path d=\"M3.5 21.5h-3a.5.5 0 00-.5.5v1a1 1 0 001 1h2a1 1 0 001-1v-1a.5.5 0 00-.5-.5zM23.5 21.5h-3a.5.5 0 00-.5.5v1a1 1 0 001 1h2a1 1 0 001-1v-1a.5.5 0 00-.5-.5zM6 21.5a.5.5 0 00-.5.5v1a1.072 1.072 0 00.587.942.5.5 0 00.233.058h11.36a.5.5 0 00.233-.058A1.072 1.072 0 0018.5 23v-1a.5.5 0 00-.5-.5zM20.428 2.243a.5.5 0 00-.175-.173 31.9 31.9 0 00-4.335-2 .855.855 0 00-.484-.059.974.974 0 00-.363.108A6.57 6.57 0 0112 1 6.608 6.608 0 018.912.113a.932.932 0 00-.346-.1.811.811 0 00-.455.048A31.937 31.937 0 003.747 2.07a.5.5 0 00-.175.173C.541 7.294.021 18.984 0 19.479A.5.5 0 00.5 20h3a.5.5 0 00.487-.384l1.02-4.286a.25.25 0 01.493.058V19.5a.5.5 0 00.5.5h4.75a.5.5 0 00.5-.5v-6.03a1.976 1.976 0 00-.143-.74A44.165 44.165 0 018.915 2.416a.253.253 0 01.105-.231.25.25 0 01.252-.021A6.279 6.279 0 0012 2.885a6.256 6.256 0 002.728-.721.25.25 0 01.357.252 44.165 44.165 0 01-2.192 10.314 2 2 0 00-.143.74v6.03a.5.5 0 00.5.5H18a.5.5 0 00.5-.5v-4.112a.25.25 0 01.493-.058l1.02 4.286A.5.5 0 0020.5 20h3a.5.5 0 00.5-.521c-.021-.495-.541-12.185-3.572-17.236z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});