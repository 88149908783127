define("ember-svg-jar/inlined/email-action-reply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-reply</title><path d=\"M19.618 10.679c-3.011-2.67-7.125-2.853-8.859-2.793a.249.249 0 01-.259-.25V5.064A1 1 0 008.858 4.3l-7.494 6.366a1 1 0 00-.364.772.986.986 0 00.351.76l7.513 6.637a1 1 0 001.636-.773v-3.035a.249.249 0 01.2-.244c1.908-.4 8.135-1.158 11.36 4.89a.5.5 0 00.94-.235c0-3.821-1.138-6.767-3.382-8.759z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});