define("ember-svg-jar/inlined/car-tool-battery-bolt.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-tool-battery-bolt</title><path d=\"M24 8a1.5 1.5 0 00-1.5-1.5h-21A1.5 1.5 0 000 8v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 20zM7.523 15a.565.565 0 01-.469-.987l4.418-5.421a.249.249 0 01.194-.092H16.2a.25.25 0 01.183.42l-2.942 3.16a.249.249 0 00.183.42h3.014c.333 0 .44.162.475.259s.055.291-.2.5l-7.478 6.184a.254.254 0 01-.159.057.25.25 0 01-.241-.316l1.053-3.868A.249.249 0 009.847 15zM3.75 5.5h4a.5.5 0 00.5-.5V3.5a1 1 0 00-1-1h-3a1 1 0 00-1 1V5a.5.5 0 00.5.5zM16.25 5.5h4a.5.5 0 00.5-.5V3.5a1 1 0 00-1-1h-3a1 1 0 00-1 1V5a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});