define("ember-svg-jar/inlined/paragraph-right-to-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paragraph-right-to-left</title><path d=\"M6.852 8.846h2.174a.25.25 0 01.25.25v7.555a1 1 0 002 0V2.248a.25.25 0 01.25-.25h2.392a.25.25 0 01.25.25v14.4a1 1 0 002 0v-14.4a.25.25 0 01.25-.25h2.663a1 1 0 000-2H6.852a4.424 4.424 0 000 8.848zm0-6.846h2.174a.25.25 0 01.25.25V6.6a.25.25 0 01-.25.25H6.852a2.424 2.424 0 010-4.85z\"/><path d=\"M22.014 18.585H6.133a.25.25 0 01-.25-.25v-2.663a.5.5 0 00-.854-.353l-3.9 3.914a.5.5 0 000 .7l3.9 3.913a.5.5 0 00.354.154.508.508 0 00.191-.038.5.5 0 00.309-.462v-2.665a.25.25 0 01.25-.25h15.881a1 1 0 100-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});