define("ember-svg-jar/inlined/audio-file-mid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file-mid</title><path d=\"M15.625 13.559v3.882a.249.249 0 00.33.24 2.237 2.237 0 001.42-2.181 2.237 2.237 0 00-1.42-2.181.249.249 0 00-.33.24zM17.5 6h3.9a.25.25 0 00.177-.427L16.427.427A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6z\"/><path d=\"M17.5 7A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.25a.25.25 0 00-.25-.25zM9.625 18.5a.625.625 0 01-1.25 0v-3.033c0-.094-.121-.077-.159 0l-.157.314a.65.65 0 01-1.118 0l-.148-.295c-.048-.095-.168-.085-.168 0V18.5a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279s.718 1.428.779 1.559a.179.179 0 00.324 0l.779-1.557a.625.625 0 011.184.279zM13 17.875a.625.625 0 010 1.25h-2a.625.625 0 010-1.25h.125a.25.25 0 00.25-.25v-4.25a.25.25 0 00-.25-.25H11a.625.625 0 010-1.25h2a.625.625 0 010 1.25h-.125a.25.25 0 00-.25.25v4.25a.25.25 0 00.25.25zm2 1.25a.625.625 0 01-.625-.625v-6a.625.625 0 01.625-.625 3.625 3.625 0 010 7.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});