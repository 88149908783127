define("ember-svg-jar/inlined/electronics-tunnel-diode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>electronics-tunnel-diode</title><path d=\"M23 11h-3.75a.25.25 0 01-.25-.25V4a1 1 0 00-1-1h-2a1 1 0 100 2h.75a.25.25 0 01.25.25v4.94a.25.25 0 01-.376.216L7.7 5.2a1.5 1.5 0 00-1.448-.029A1.4 1.4 0 005.5 6.4v4.353a.25.25 0 01-.25.25H1a1 1 0 000 2h4.25a.25.25 0 01.25.25V17.6a1.4 1.4 0 00.753 1.23A1.5 1.5 0 007.7 18.8l8.924-5.2a.25.25 0 01.376.216v4.94a.25.25 0 01-.25.25H16a1 1 0 000 2h2a1 1 0 001-1v-6.75a.25.25 0 01.25-.25H23a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});