define("ember-svg-jar/inlined/currency-dollar-international", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-dollar-international</title><path d=\"M11.966 2.057a.247.247 0 01.18-.078h.354a1 1 0 000-2C.924-.021 0 10.527 0 12c0 1.448.909 12.021 12.5 12.021a1 1 0 000-2h-.354a.251.251 0 01-.183-.079 16.915 16.915 0 01-2.624-3.3.25.25 0 01.217-.373H11.1a1 1 0 100-2H8.507a.248.248 0 01-.24-.182 13.9 13.9 0 01-.474-2.821.25.25 0 01.249-.266H10.1a1 1 0 100-2H8.042a.248.248 0 01-.249-.267 13.9 13.9 0 01.474-2.822.251.251 0 01.24-.182H11.1a1 1 0 000-2H9.56a.25.25 0 01-.216-.375 17.686 17.686 0 012.622-3.297zM8.392 20.836a.251.251 0 01-.3.376 10.07 10.07 0 01-3.519-2.524.25.25 0 01.186-.417h2.013a.249.249 0 01.228.148 13.575 13.575 0 001.392 2.417zm-2.211-4.872a.251.251 0 01-.244.307H3.129a.25.25 0 01-.228-.147 9.927 9.927 0 01-.818-2.841.251.251 0 01.248-.283h3.21a.251.251 0 01.25.235 16.418 16.418 0 00.39 2.729zm-.39-5.2a.25.25 0 01-.25.234h-3.21a.25.25 0 01-.188-.085.253.253 0 01-.06-.2A9.949 9.949 0 012.9 7.875a.251.251 0 01.228-.146h2.809a.251.251 0 01.244.307 16.43 16.43 0 00-.39 2.73zM4.758 5.729a.25.25 0 01-.186-.417A10.066 10.066 0 018.09 2.788a.25.25 0 01.3.376A13.573 13.573 0 007 5.581a.25.25 0 01-.229.148z\"/><path d=\"M21.324 11.582l-3.546-1.829a2 2 0 01-1.05-1.776A1.928 1.928 0 0118.6 6h3.9a1.5 1.5 0 000-3h-.75a.249.249 0 01-.25-.25v-.977a1.5 1.5 0 00-3 0v1a.25.25 0 01-.232.249 5 5 0 00-1.865 9.4l3.546 1.829A2 2 0 0121 16.024 1.928 1.928 0 0119.129 18h-3.9a1.5 1.5 0 000 3h1.021a.25.25 0 01.25.25v.977a1.5 1.5 0 103 0v-1.039a.25.25 0 01.219-.248 4.988 4.988 0 001.605-9.358z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});