define("ember-svg-jar/inlined/network-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>network-user</title><path d=\"M13.019 16.5a1 1 0 00-1-1H8.105a.247.247 0 01-.243-.19A14.932 14.932 0 017.507 12a15.25 15.25 0 01.254-2.8.253.253 0 01.25-.2c10.873 0 13.555.008 14.28-.018a.936.936 0 00.9-1.338A11.942 11.942 0 0011.947 0 12.238 12.238 0 00.008 11.8a11.973 11.973 0 0011.468 12.187h.044a1 1 0 00.271-1.963.945.945 0 01-.482-.37 35.8 35.8 0 01-2.542-3.79.25.25 0 01.221-.367h3.031a1 1 0 001-.997zM5.8 15.2a.249.249 0 01-.245.3H2.818a.251.251 0 01-.236-.167 9.97 9.97 0 01-.169-6.153.251.251 0 01.24-.18H5.46a.252.252 0 01.247.288 17.891 17.891 0 00-.2 2.712 17.131 17.131 0 00.293 3.2zm6.059-12.93a.251.251 0 01.291 0 10.169 10.169 0 013.36 4.383.25.25 0 01-.231.347h-6.55a.247.247 0 01-.207-.111.25.25 0 01-.022-.236 10.064 10.064 0 013.359-4.383zm8.581 4.344a.25.25 0 01-.211.386h-2.283a.251.251 0 01-.238-.171 13.131 13.131 0 00-1.776-3.521.25.25 0 01.309-.372 9.9 9.9 0 014.199 3.678zM7.74 2.968a.25.25 0 01.311.371 13.12 13.12 0 00-1.746 3.488.25.25 0 01-.237.173H3.805a.25.25 0 01-.21-.385A10.058 10.058 0 017.74 2.968zM8.091 20.7a.25.25 0 01-.309.372 9.978 9.978 0 01-3.86-3.172.25.25 0 01.2-.4h2.124a.251.251 0 01.234.163A13.142 13.142 0 008.091 20.7z\"/><path d=\"M18.769 17.7a3.413 3.413 0 10-3.412-3.412 3.412 3.412 0 003.412 3.412zM13.548 23.445a.5.5 0 00.5.553h9.447a.5.5 0 00.5-.553 5.25 5.25 0 00-10.442 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});