define("ember-svg-jar/inlined/office-clipper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-clipper</title><path d=\"M23.7 22.4l-2.941-10.082A1.918 1.918 0 0019 11h-1.1a.25.25 0 00-.247.287l1.013 6.861a2.5 2.5 0 11-4.946.73l-1.133-7.664a.251.251 0 00-.246-.214h-.683a.25.25 0 00-.247.214l-1.132 7.664a2.5 2.5 0 01-4.947-.73l1.014-6.861A.25.25 0 006.1 11H5a1.915 1.915 0 00-1.759 1.32L.3 22.4a1.258 1.258 0 00.175 1.117A1.261 1.261 0 001.5 24h21a1.208 1.208 0 001.2-1.6z\"/><path d=\"M8.2 7.971a.252.252 0 01.118.251l-1.5 10.142a1 1 0 101.978.293L10.319 8.34a1.993 1.993 0 00-.987-2.015A2.265 2.265 0 018 4.5C8 3.143 9.832 2 12 2s4 1.145 4 2.5a2.263 2.263 0 01-1.333 1.827 2 2 0 00-.984 2.023l1.524 10.309a1 1 0 101.978-.293l-1.5-10.137a.25.25 0 01.118-.25A4.133 4.133 0 0018 4.5C18 1.975 15.364 0 12 0S6 1.975 6 4.5a4.119 4.119 0 002.2 3.471z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});