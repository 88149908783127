define("ember-svg-jar/inlined/tea-pot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tea-pot</title><path d=\"M6.234 9.955a.25.25 0 00.183.42h11.357a.25.25 0 00.183-.42 8 8 0 00-3.286-2.144.5.5 0 01-.294-.68 2.433 2.433 0 00.223-1.006 2.5 2.5 0 00-5 0 2.432 2.432 0 00.218 1.006.5.5 0 01-.294.68 8 8 0 00-3.29 2.144z\"/><path d=\"M19.654 20.375a4.25 4.25 0 000-8.5H4.846A2.253 2.253 0 012.6 9.625v-.25c0-.034-.088-1.5-1.276-1.5A1.224 1.224 0 00.1 9.1a.775.775 0 00.775.775.225.225 0 01.225.225v1.275a3.754 3.754 0 002.706 3.6.407.407 0 01.294.392v.008a7.941 7.941 0 001.446 4.574 1 1 0 00.82.426zm.276-2.149a.251.251 0 01-.267-.329 7.788 7.788 0 00.433-2.522 7.506 7.506 0 00-.067-.978.253.253 0 01.09-.227.25.25 0 01.241-.041 2.116 2.116 0 01-.43 4.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});