define("ember-svg-jar/inlined/chess-rook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chess-rook</title><path d=\"M7.544 6.5h9a2.006 2.006 0 002-2v-4a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v4a2.006 2.006 0 002 2zM7.252 19h9.5a.5.5 0 00.459-.7c-1.156-2.788-1.894-6.291-1.148-9.043A1 1 0 0015.093 8H8.907a1 1 0 00-.966 1.262c.746 2.752.008 6.255-1.148 9.043a.5.5 0 00.459.695zM16.5 20.5h-9a3.5 3.5 0 00-3.448 2.9.509.509 0 00.5.6h14.901a.51.51 0 00.5-.6 3.5 3.5 0 00-3.453-2.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});