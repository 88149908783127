define("ember-svg-jar/inlined/earth-subtract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earth-subtract</title><path d=\"M10.967 22.108a.5.5 0 00-.339-.206 9.975 9.975 0 01-2.987-.914.25.25 0 01-.141-.225v-1.15a2.492 2.492 0 01.893-1.913 4.492 4.492 0 001.266-1.779 7.851 7.851 0 01.264-.965 4.448 4.448 0 00.077-.7 4.505 4.505 0 00-4.5-4.5H2.584a.25.25 0 01-.241-.314 9.982 9.982 0 0114.546-6.16.25.25 0 01-.122.468H14.25a2.75 2.75 0 000 5.5 2.54 2.54 0 011.283.362.5.5 0 00.355.059A7.857 7.857 0 0117.5 9.5a7.972 7.972 0 015.976 2.7A.3.3 0 0024 12 12 12 0 107.341 23.059l.159.078v-.02A11.927 11.927 0 0012 24a.3.3 0 00.2-.524 7.962 7.962 0 01-1.233-1.368z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.5 7.25h-5a.75.75 0 010-1.5h5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});