define("ember-svg-jar/inlined/dressing-hanging-rack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dressing-hanging-rack</title><path d=\"M17 10a1 1 0 00-2 0 2.986 2.986 0 01-1.636 2.658.249.249 0 01-.364-.222V9.291a.5.5 0 01.364-.481A5.008 5.008 0 0017 4a1 1 0 00-2 0 2.986 2.986 0 01-1.636 2.658.249.249 0 01-.364-.222V1a1 1 0 00-2 0v2.436a.25.25 0 01-.364.222A2.986 2.986 0 019 1a1 1 0 00-2 0 5.008 5.008 0 003.636 4.81.5.5 0 01.364.481v3.145a.25.25 0 01-.364.222A2.986 2.986 0 019 7a1 1 0 00-2 0 5.008 5.008 0 003.636 4.81.5.5 0 01.364.481V21.5a.5.5 0 01-.5.5H9a1 1 0 000 2h6a1 1 0 000-2h-1.5a.5.5 0 01-.5-.5v-6.209a.5.5 0 01.364-.481A5.008 5.008 0 0017 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});