define("ember-svg-jar/inlined/resize-expand-sides", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>resize-expand-sides</title><path d=\"M22.7 4.1a.751.751 0 000-1.2L19.036.155a.749.749 0 00-1.2.6v1.5a.25.25 0 01-.25.25H4.5a2 2 0 00-2 2v13.08a.25.25 0 01-.25.25H.752a.75.75 0 00-.6 1.2L2.9 22.7a.751.751 0 001.2 0l2.75-3.666a.749.749 0 00-.6-1.2h-1.5a.25.25 0 01-.25-.25V5a.5.5 0 01.5-.5h12.585a.25.25 0 01.25.25v1.5a.749.749 0 001.2.6zM23.25 20.5a.75.75 0 00-.75.75v1a.253.253 0 01-.25.25h-1a.75.75 0 000 1.5h1A1.752 1.752 0 0024 22.25v-1a.75.75 0 00-.75-.75zM17.751 22.5h-1a.75.75 0 000 1.5h1a.75.75 0 100-1.5zM12.751 22.5h-1a.75.75 0 100 1.5h1a.75.75 0 000-1.5zM7.751 22.5h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM23.25 16a.75.75 0 00-.75.75v1a.75.75 0 001.5 0v-1a.75.75 0 00-.75-.75zM23.25 11a.75.75 0 00-.75.75v1a.75.75 0 101.5 0v-1a.75.75 0 00-.75-.75zM23.25 6a.75.75 0 00-.75.75v1a.75.75 0 001.5 0v-1a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});