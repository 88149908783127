define("ember-svg-jar/inlined/design-file-vector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-vector</title><path d=\"M21.414 4.914L17.086.586A2 2 0 0015.672 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.329a2 2 0 00-.586-1.415zM7.5 20a2 2 0 010-4c.272 0 .658.266.956-.032l4.525-4.526c.192-.192.019-.671.019-.943a2 2 0 112 2c-.281 0-.806-.149-.955 0l-4.47 4.471c-.306.306-.075.749-.075 1.03a2 2 0 01-2 2zm9.417-4.251h-1.168a2.585 2.585 0 00-2.582 2.582V20a.75.75 0 01-1.5 0v-1.668a4.086 4.086 0 014.082-4.082h1.168a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});