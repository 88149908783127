define("ember-svg-jar/inlined/style-three-pin-lighthouse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-lighthouse</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm-.8 5.175a.5.5 0 00.3-.459V4a.5.5 0 011 0v.716a.5.5 0 00.3.459A1.991 1.991 0 0114 7a.462.462 0 01-.459.51h-3.082A.462.462 0 0110 7a1.991 1.991 0 011.2-1.825zm-4.132 4.889l-1.233.617a.75.75 0 11-.67-1.342L6.4 8.723a.75.75 0 11.67 1.341zm1.1-3.718a.751.751 0 01-1.006.335l-2-1a.75.75 0 01.67-1.342l2 1a.751.751 0 01.339 1.007zM14.75 9.51a.438.438 0 00-.435.483l.627 5.955a.5.5 0 01-.5.552H13.5a.5.5 0 01-.5-.5v-.49a1 1 0 10-2 0V16a.5.5 0 01-.5.5h-.945a.5.5 0 01-.5-.552l.627-5.955a.438.438 0 00-.432-.483.5.5 0 010-1h5.5a.5.5 0 010 1zm4.421.836a.751.751 0 01-1.006.335l-1.134-.567a.75.75 0 11.67-1.342l1.134.567a.751.751 0 01.336 1.007zm-.336-4.665l-2 1a.75.75 0 11-.67-1.342l2-1a.75.75 0 01.67 1.342z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});