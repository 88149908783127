define("ember-svg-jar/inlined/warehouse-delivery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>warehouse-delivery</title><path d=\"M9.5 12.25a1 1 0 00-2 0v1a1 1 0 002 0zM8.5 15.25a1 1 0 00-1 1v1a1 1 0 002 0v-1a1 1 0 00-1-1zM9.5 19.563h-1a1 1 0 000 2h1a1 1 0 000-2zM21.912 17.087l-.851-.213a.251.251 0 00-.311.243V19a.5.5 0 01-1 0v-3.5a.5.5 0 00-.5-.5h-7a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h.25a.25.25 0 01.25.25v.25a1.5 1.5 0 003 0v-.25A.25.25 0 0116 22h2.5a.25.25 0 01.25.25v.25a1.5 1.5 0 003 0v-.25A.25.25 0 0122 22h1.083c.369 0 .667-.224.667-.5V19a2.162 2.162 0 00-1.838-1.913z\"/><path d=\"M11.75 11a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V6.208a.25.25 0 01.379-.215l.606.365a1 1 0 101.03-1.715l-7.5-4.5a1 1 0 00-1.03 0l-7.5 4.5a1 1 0 001.03 1.715l.607-.365a.25.25 0 01.379.214V11a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V6.75A.25.25 0 016 6.5h5.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});