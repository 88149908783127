define("ember-svg-jar/inlined/php", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>php</title><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM6.875 12.626h-.5a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0V9a.625.625 0 01.625-.624H7a2.128 2.128 0 012.114 2.347 2.213 2.213 0 01-2.239 1.903zM14.125 15a.625.625 0 01-1.25 0v-2.124a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0V9a.625.625 0 111.25 0v2.125a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V9a.625.625 0 111.25 0zm2.75-2.375h-.5a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0V9a.625.625 0 01.625-.625H17a2.128 2.128 0 012.114 2.347 2.213 2.213 0 01-2.239 1.904z\"/><path d=\"M17 9.626h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H17a.875.875 0 000-1.75zM7 9.626h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H7a.875.875 0 000-1.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});