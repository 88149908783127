define("ember-svg-jar/inlined/landmark-gate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-gate</title><path d=\"M5.5 8h13a1.026 1.026 0 001-1V4.5a1 1 0 00-2 0v.75a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-.5a.5.5 0 00-.5-.5h-.5a.5.5 0 01-.5-.5v-.5a1.984 1.984 0 00-.8-1.589.5.5 0 01-.2-.4V1a1 1 0 00-2 0v.262a.5.5 0 01-.2.4 1.984 1.984 0 00-.8 1.589v.5a.5.5 0 01-.5.5H9a.5.5 0 00-.5.5v.5a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5V4.5a1 1 0 10-2 0V7a1.026 1.026 0 001 1z\"/><path d=\"M21.75 6.751a1 1 0 00-1 1v1a.5.5 0 01-.5.5H3.75a.5.5 0 01-.5-.5v-1a1 1 0 00-2 0v2.5a1 1 0 001 1h.25a.5.5 0 01.5.5V23.5a.5.5 0 00.5.5h1.25a.5.5 0 00.5-.5V19a.75.75 0 011.5 0v4.5a.5.5 0 00.5.5H8.5a.5.5 0 00.5-.5v-3a3 3 0 016 0v3a.5.5 0 00.5.5h1.25a.5.5 0 00.5-.5V19a.75.75 0 011.5 0v4.5a.5.5 0 00.5.5h1.25a.5.5 0 00.5-.5V11.751a.5.5 0 01.5-.5h.25a1 1 0 001-1v-2.5a1 1 0 00-1-1zM8 16.5a.5.5 0 01-1 0V15a.5.5 0 011 0zm6.5-3a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5v-.111a1.567 1.567 0 01.762-1.345l1.481-.889a.5.5 0 01.514 0l1.481.889a1.567 1.567 0 01.762 1.345zm2.5 3a.5.5 0 01-1 0V15a.5.5 0 011 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});