define("ember-svg-jar/inlined/social-profile-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-profile-bubble</title><path d=\"M16.25 18.75a7.75 7.75 0 000-15.5h-8.5a7.736 7.736 0 00-6.33 12.2.5.5 0 01.06.464L.048 19.736a.75.75 0 00.7 1.014.784.784 0 00.2-.026l7.172-1.956a.489.489 0 01.132-.018zM14 9a.75.75 0 01.75-.75h3.5a.75.75 0 010 1.5h-3.5A.75.75 0 0114 9zm-1 3.5a.75.75 0 01.75-.75h5.5a.75.75 0 010 1.5h-5.5a.75.75 0 01-.75-.75zM7.987 7a2.033 2.033 0 11-2.032 2.033A2.033 2.033 0 017.987 7zm0 4.805a3.32 3.32 0 013.159 2.3.218.218 0 01-.208.286H5.08a.251.251 0 01-.237-.331 3.322 3.322 0 013.144-2.255z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});