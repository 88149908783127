define("ember-svg-jar/inlined/smiley-kiss-blush-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-kiss-blush-alternate</title><path d=\"M6.053 8.9A.989.989 0 006.5 9a1 1 0 00.9-.553A2.656 2.656 0 019.5 7.5a1 1 0 000-2c-1.153 0-3.142.547-3.895 2.053A1 1 0 006.053 8.9zM14.5 7.5a2.656 2.656 0 012.105.947A1 1 0 0017.5 9a.989.989 0 00.446-.105 1 1 0 00.454-1.342C17.642 6.047 15.653 5.5 14.5 5.5a1 1 0 000 2zM16.707 10.793a1 1 0 00-1.414 0l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5a1 1 0 000-1.414zM20.207 10.793a1 1 0 00-1.414 0l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5a1 1 0 000-1.414zM6.707 10.793a1 1 0 00-1.414 0l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5a1 1 0 000-1.414zM10.207 10.793a1 1 0 00-1.414 0l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5a1 1 0 000-1.414z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M12.5 14.5h-1a1 1 0 000 2h1a1 1 0 010 2h-1a1 1 0 000 2h1a3 3 0 000-6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});