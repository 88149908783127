define("ember-svg-jar/inlined/diagram-arrow-diagonal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-arrow-diagonal</title><path d=\"M11.624.985a1.011 1.011 0 00-.906-.643L1.539 0A1.479 1.479 0 000 1.54l.34 9.178a1.014 1.014 0 001.731.679L4.4 9.065a.253.253 0 01.358 0l10.176 10.173a.253.253 0 010 .358L12.6 21.928a1.014 1.014 0 00.679 1.731l9.179.34h.053A1.479 1.479 0 0024 22.459l-.34-9.176a1.014 1.014 0 00-1.731-.68L19.6 14.934a.253.253 0 01-.358 0L9.066 4.762a.252.252 0 010-.359L11.4 2.072a1.012 1.012 0 00.224-1.087z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});