define("ember-svg-jar/inlined/modern-architecture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-architecture</title><path d=\"M23.5 4.5h-1A.5.5 0 0122 4v-.5a1 1 0 00-1-1h-2a1 1 0 00-1 1V4a.5.5 0 01-.5.5h-8A.5.5 0 019 4v-.5a.964.964 0 00-.333-.724A.5.5 0 018.5 2.4V1a1 1 0 10-2 0v1a.5.5 0 01-.5.5 1 1 0 00-1 1v.592a.5.5 0 01-.423.493A7.844 7.844 0 00.11 7.188.5.5 0 00.5 8h23a.5.5 0 00.5-.5V5a.5.5 0 00-.5-.5zM8.5 9.5h-5a.5.5 0 00-.5.5v13.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V10a.5.5 0 00-.5-.5zm-3 10a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5V19a.5.5 0 01.5-.5H5a.5.5 0 01.5.5zm0-2.5a.5.5 0 01-.5.5h-.5A.5.5 0 014 17v-.5a.5.5 0 01.5-.5H5a.5.5 0 01.5.5zm0-5a.5.5 0 01-.5.5h-.5A.5.5 0 014 12v-.5a.5.5 0 01.5-.5H5a.5.5 0 01.5.5zM8 22a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5v-.5A.5.5 0 017 21h.5a.5.5 0 01.5.5zM23.5 9.5h-5a.5.5 0 00-.5.5v13.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V10a.5.5 0 00-.5-.5zm-3 12.5a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5zm0-7.5a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5V14a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5zm2.5 5a.5.5 0 01-.5.5H22a.5.5 0 01-.5-.5V19a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5zm0-7.5a.5.5 0 01-.5.5H22a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5zM16 9.5h-5a.5.5 0 00-.5.5v13.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V10a.5.5 0 00-.5-.5zM13 22a.5.5 0 01-.5.5H12a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5zm0-10a.5.5 0 01-.5.5H12a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5zm2.5 5a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5zm0-2.5a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5V14a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});