define("ember-svg-jar/inlined/phone-actions-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-refresh</title><path d=\"M17.169 16.164a2.5 2.5 0 00-3.536 0l-.386.385a47.037 47.037 0 01-5.789-5.789l.386-.386a2.505 2.505 0 000-3.535L5.722 4.718a2.558 2.558 0 00-3.536 0L1.022 5.881a3.507 3.507 0 00-.441 4.4 46.906 46.906 0 0013.141 13.145 3.549 3.549 0 004.4-.443l1.163-1.163a2.5 2.5 0 000-3.535z\"/><path d=\"M23.689.316a.5.5 0 00-.545.109L21.672 1.9a6.374 6.374 0 10-4.528 10.86 6.346 6.346 0 004.983-2.4 1 1 0 10-1.562-1.25 4.375 4.375 0 11-.306-5.8l-1.572 1.572a.5.5 0 00.354.853H23.5a.5.5 0 00.5-.5V.778a.5.5 0 00-.311-.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});