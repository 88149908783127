define("ember-svg-jar/inlined/smiley-shine-big-eyes-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-shine-big-eyes-alternate</title><path d=\"M22.638 9.016a1 1 0 00-.805 1.163 10 10 0 11-19.679.021 1 1 0 00-1.971-.342A12 12 0 1023.8 9.821a1 1 0 00-1.162-.805z\"/><path d=\"M16.56 14.5H7.437a.431.431 0 00-.38.25.56.56 0 000 .5A5.619 5.619 0 0012 18.5a5.621 5.621 0 004.94-3.25.56.56 0 000-.5.433.433 0 00-.38-.25zM1.011 7A4.3 4.3 0 015 11.009a1 1 0 00.979.991H6a1 1 0 001-.95C7.087 9.265 8.973 7 11 7a1 1 0 00.021-2A4.4 4.4 0 017 1.036V1a1 1 0 00-1-1 1 1 0 00-1 1A4.4 4.4 0 01.986 5a1 1 0 00.025 2zM5.8 4.382a.25.25 0 01.4 0 6.928 6.928 0 001.442 1.425.251.251 0 010 .4 7.125 7.125 0 00-1.388 1.441.25.25 0 01-.406 0 6.654 6.654 0 00-1.434-1.48.249.249 0 010-.4A6.932 6.932 0 005.8 4.382z\"/><path d=\"M13.011 7A4.3 4.3 0 0117 11.009a1 1 0 00.979.991H18a1 1 0 001-.95C19.087 9.265 20.973 7 23 7a1 1 0 00.021-2A4.4 4.4 0 0119 1.036V1a1 1 0 00-1-1 1 1 0 00-1 1 4.4 4.4 0 01-4.014 4 1 1 0 00.025 2zM17.8 4.382a.25.25 0 01.4 0 6.928 6.928 0 001.446 1.425.251.251 0 010 .4 7.125 7.125 0 00-1.393 1.441.25.25 0 01-.406 0 6.654 6.654 0 00-1.434-1.478.249.249 0 010-.4A6.932 6.932 0 0017.8 4.382z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});