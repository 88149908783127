define("ember-svg-jar/inlined/single-woman-actions-home.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-home</title><path d=\"M8.884 16.093l4.306-3.644a.251.251 0 00-.074-.426c-.518-.191-1.069-.39-1.642-.6a.78.78 0 01-.513-.73v-.468a.251.251 0 01.25-.25A6.366 6.366 0 0015 9.08a.387.387 0 00.084-.592c-.494-.538-.835-1.186-.981-3.317C13.838 1.345 11.236 0 9 0S4.161 1.345 3.9 5.171C3.755 7.3 3.413 7.95 2.919 8.488A.387.387 0 003 9.08a6.355 6.355 0 003.782.9.249.249 0 01.253.25v.462a.778.778 0 01-.512.73c-2.9 1.065-5.212 1.825-5.764 2.921A7.828 7.828 0 000 17.075a.384.384 0 00.1.3.392.392 0 00.29.127h7.47a.251.251 0 00.24-.182 2.48 2.48 0 01.784-1.227zM21.5 24a.5.5 0 00.5-.5v-4.75a.25.25 0 01.25-.25h1.25a.5.5 0 00.323-.882l-6.5-5.5a.5.5 0 00-.647 0l-6.5 5.5a.5.5 0 00.324.882h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});