define("ember-svg-jar/inlined/gesture-two-finger-tap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-two-finger-tap</title><path d=\"M14.011 15.437a.249.249 0 01-.14-.293l1.247-4.657a.93.93 0 00-1.8-.455l-1.43 4.592a.251.251 0 01-.3.169L11 14.649a.249.249 0 01-.19-.242V9.849a1 1 0 00-2 0v7.063a.25.25 0 01-.455.143c-1.49-2.138-3.856-1.449-2.762.224l2.912 4.367a.5.5 0 00.416.222h5.435a.5.5 0 00.492-.41c.2-1.1.664-3.684.775-4.166.256-1.092-.658-1.429-1.612-1.855zM7.443 5.843a4.143 4.143 0 00-1.88 3.525.75.75 0 001.5 0A2.635 2.635 0 018.267 7.1a2.832 2.832 0 012.647-.26.75.75 0 10.594-1.377 4.352 4.352 0 00-4.065.38z\"/><path d=\"M14.313 6.118a.75.75 0 000 1.5 2.755 2.755 0 011.969 4.67.75.75 0 101.074 1.047 4.254 4.254 0 00-3.043-7.217zM12.46 4.137a.75.75 0 00.608-1.372 7.873 7.873 0 00-7.4.652A7.553 7.553 0 002.2 9.863a.75.75 0 001.5 0 6.077 6.077 0 012.783-5.189 6.355 6.355 0 015.977-.537z\"/><path d=\"M21.338 7.957a7.4 7.4 0 00-4.4-4.538.75.75 0 10-.517 1.408 5.83 5.83 0 013.5 3.628 6.588 6.588 0 01-.518 5.526.75.75 0 001.286.772 8.1 8.1 0 00.649-6.796z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});