define("ember-svg-jar/inlined/setting-slider-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>setting-slider-vertical</title><path d=\"M16 12.5H8a2.5 2.5 0 000 5h8a2.5 2.5 0 000-5zM11.991 11a1.5 1.5 0 001.5-1.5v-8a1.5 1.5 0 10-3 0v8a1.5 1.5 0 001.5 1.5zM11.991 19h-.007a1.5 1.5 0 00-1.493 1.507l.009 2A1.5 1.5 0 0012 24h.007a1.5 1.5 0 001.493-1.506l-.009-2a1.5 1.5 0 00-1.5-1.494z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});