define("ember-svg-jar/inlined/smart-watch-square-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-email</title><path d=\"M16.43 2.75a.251.251 0 00.238-.329L15.975.342A.5.5 0 0015.5 0h-7a.5.5 0 00-.475.342l-.693 2.079a.251.251 0 00.238.329zM7.57 21.25a.248.248 0 00-.2.1.251.251 0 00-.035.225l.693 2.079A.5.5 0 008.5 24h7a.5.5 0 00.475-.342l.693-2.079a.251.251 0 00-.035-.225.248.248 0 00-.2-.1zM17.5 4h-11A2.5 2.5 0 004 6.5v11A2.508 2.508 0 006.5 20h11a2.5 2.5 0 002.5-2.5v-11A2.5 2.5 0 0017.5 4zm-1.521 6.412l-3.734 2.215a.488.488 0 01-.49 0l-3.733-2.214A.042.042 0 018 10.376a.9.9 0 01.923-.876h6.154a.9.9 0 01.923.876.042.042 0 01-.021.036zM16 11.636v2.935a.927.927 0 01-.923.929H8.923A.927.927 0 018 14.571v-2.935a.155.155 0 01.079-.136.153.153 0 01.156 0l3.031 1.907a1.388 1.388 0 001.468 0l3.031-1.907a.151.151 0 01.156 0 .154.154 0 01.079.136z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});