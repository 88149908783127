define("ember-svg-jar/inlined/human-resources-woman-previous-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-woman-previous-1</title><path d=\"M23.992 11.609A10.716 10.716 0 0012.89 1.294a10.843 10.843 0 00-9.732 7.161.25.25 0 01-.254.163L.538 8.436a.5.5 0 00-.454.776l3.069 4.6a.5.5 0 00.383.222h.033a.5.5 0 00.376-.171l3.579-4.088a.5.5 0 00-.338-.827l-1.665-.129a.25.25 0 01-.208-.353 8.811 8.811 0 017.651-5.173 8.716 8.716 0 019.03 8.389 8.929 8.929 0 01-1.483 5.253.249.249 0 01-.341.073 9.787 9.787 0 00-2.693-1.14.25.25 0 01-.186-.241v-.32a.25.25 0 01.208-.246 7.852 7.852 0 001.964-.6.5.5 0 00.228-.7 7.438 7.438 0 01-.713-3.58 4.724 4.724 0 00-5-5 4.724 4.724 0 00-5 5 7.333 7.333 0 01-.736 3.622.5.5 0 00.2.689 6.053 6.053 0 002.008.59.249.249 0 01.215.248v.3a.249.249 0 01-.185.242 9.518 9.518 0 00-2.86 1.265.236.236 0 01-.136.043 1 1 0 00-.878 1.449 7.954 7.954 0 007.036 4.075 10.093 10.093 0 007.317-3.152 10.925 10.925 0 003.033-7.943zM12.665 14.5a.933.933 0 00-.5-.865.46.46 0 01-.037-.023c-.116-.089-.63-.557-.646-1.927a56.464 56.464 0 01-.006-.924.25.25 0 01.153-.23A8.3 8.3 0 0013.823 9.2a.218.218 0 01.289 0 7.841 7.841 0 002.21 1.347.253.253 0 01.155.231v.912a2.471 2.471 0 01-.6 1.9.236.236 0 01-.048.032 1 1 0 00-.53.883v.72a.247.247 0 01-.079.183.252.252 0 01-.188.067 15.244 15.244 0 00-2.091.003.252.252 0 01-.268-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});