define("ember-svg-jar/inlined/paginate-filter-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paginate-filter-5</title><path d=\"M24 2a2 2 0 00-2-2H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2zM12.25 9a.25.25 0 00.25.25h2a2.75 2.75 0 010 5.5h-3a.75.75 0 010-1.5h3a1.25 1.25 0 000-2.5h-2A1.752 1.752 0 0110.75 9V6a.75.75 0 01.75-.75h5a.75.75 0 010 1.5h-4a.25.25 0 00-.25.25z\"/><path d=\"M20.5 23a1 1 0 00-1-1h-17a.5.5 0 01-.5-.5v-17a1 1 0 00-2 0V22a2 2 0 002 2h17.5a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});