define("ember-svg-jar/inlined/human-resources-search-team", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-search-team</title><path d=\"M13.956 2.086A10.019 10.019 0 0119.7 5.514a1 1 0 101.539-1.277A12.024 12.024 0 0014.345.124a1 1 0 00-.389 1.962zM2 11.946a1.022 1.022 0 00-1-.995 1 1 0 00-.995 1 12.021 12.021 0 002.723 7.551 1 1 0 101.545-1.27A10.009 10.009 0 012 11.946zM23.872 10.14a1 1 0 10-1.978.291A10.215 10.215 0 0122 11.9a10.018 10.018 0 01-2.236 6.3 1 1 0 001.552 1.262 12.019 12.019 0 002.556-9.32zM15.37 21.313a10.09 10.09 0 01-6.691.017 1 1 0 10-.664 1.887 12.049 12.049 0 008.029-.021 1 1 0 00-.674-1.883zM2.049 7.094a1 1 0 001.373-.342 10.042 10.042 0 015.163-4.258A1 1 0 007.9.614a12.056 12.056 0 00-6.193 5.108 1 1 0 00.342 1.372z\"/><circle cx=\"15.5\" cy=\"8.895\" r=\"2.5\"/><path d=\"M15.5 12.145a3.98 3.98 0 00-2.361.771.25.25 0 00-.056.346 4.972 4.972 0 01.911 2.645.251.251 0 00.25.238h5a.25.25 0 00.182-.079.248.248 0 00.067-.188 4 4 0 00-3.993-3.733zM9 12.145a4 4 0 00-3.991 3.734.246.246 0 00.067.187.25.25 0 00.182.079h7.484a.25.25 0 00.182-.079.248.248 0 00.067-.188A4 4 0 009 12.145zM9.9 11.227a2.505 2.505 0 10-.9.173 2.5 2.5 0 00.9-.173z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});