define("ember-svg-jar/inlined/famous-people-man-hulk-hogan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-people-man-hulk-hogan</title><path d=\"M22.837 19.823A14.11 14.11 0 0017.8 17.51a.25.25 0 00-.3.252c.02.721.008 1.4 0 2.542a1.5 1.5 0 01-1.974 1.423 5.234 5.234 0 01-.824-.356 4.379 4.379 0 01-5.4 0 5.234 5.234 0 01-.824.356A1.5 1.5 0 016.5 20.3c-.008-1.145-.02-1.824 0-2.542a.249.249 0 00-.3-.252 14.11 14.11 0 00-5.038 2.313A2.964 2.964 0 000 22.2v.8a1 1 0 002 0v-.8a.977.977 0 01.377-.785 9.816 9.816 0 011.51-.932.251.251 0 01.363.224V23a.75.75 0 00.75.75h14a.75.75 0 00.75-.75v-2.3a.251.251 0 01.363-.224 9.816 9.816 0 011.51.932.977.977 0 01.377.792v.8a1 1 0 002 0v-.8a2.964 2.964 0 00-1.163-2.377z\"/><circle cx=\"14\" cy=\"9.5\" r=\"1\"/><circle cx=\"10\" cy=\"9.5\" r=\"1\"/><path d=\"M7.494 19.485l.006.815a.5.5 0 00.658.474c2.173-.724 2.313-2.426 2.416-3.67.11-1.34.21-1.8 1.426-1.8s1.306.459 1.41 1.786c.1 1.246.23 2.954 2.432 3.688A.508.508 0 0016 20.8a.5.5 0 00.5-.5l.006-.824c.07-6.406-1.322-4.579-.182-5.476A7.016 7.016 0 0019 8.5V7a7.008 7.008 0 00-7-7 6.662 6.662 0 00-6.937 5.653.251.251 0 01-.4.169 3.215 3.215 0 00-2.432-.613.286.286 0 00-.162.1.249.249 0 00-.058.171 3.247 3.247 0 001.576 2.642.25.25 0 010 .428 3.246 3.246 0 00-1.574 2.65.284.284 0 00.062.176.251.251 0 00.158.091 3.231 3.231 0 00.5.038 3.263 3.263 0 002.226-.894.249.249 0 01.408.1A7.011 7.011 0 007.674 14c1.143.9-.248-.939-.18 5.485zM7.016 8.811a.251.251 0 01.178-.255A17 17 0 0112 8a17 17 0 014.806.556.249.249 0 01.178.255 5 5 0 01-2.252 3.866.249.249 0 01-.269 0A4.585 4.585 0 0012 12.054a4.582 4.582 0 00-2.463.626.247.247 0 01-.269 0 5 5 0 01-2.252-3.869z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});