define("ember-svg-jar/inlined/hippo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hippo</title><path d=\"M18.074 3.936a2.69 2.69 0 001.235-2.13A1.957 1.957 0 0018.054.284 1.864 1.864 0 0016.21.2a2.915 2.915 0 00-1.1 2.259 9.554 9.554 0 012.964 1.477zM8.9 2.462A2.919 2.919 0 007.8.2a1.859 1.859 0 00-1.845.084A1.955 1.955 0 004.7 1.806a2.677 2.677 0 001.235 2.131A9.807 9.807 0 018.9 2.462zM21.077 17.629c.945-1.878.281-3.97-1.821-5.774l.009-.041a9.875 9.875 0 00.248-2.446c0-3.23-3.368-5.857-7.508-5.857S4.5 6.138 4.5 9.368a10.327 10.327 0 00.222 2.517c-2.1 1.821-2.738 3.852-1.786 5.744a3.993 3.993 0 002.178 2.123v2.176A1.982 1.982 0 007.183 24h2.143a1.982 1.982 0 002.074-2.072V20.02a.5.5 0 01.5-.5h.217a.5.5 0 01.5.5v1.908A1.982 1.982 0 0014.683 24h2.143a1.982 1.982 0 002.074-2.072v-2.175a3.991 3.991 0 002.177-2.124zM15.115 22a.5.5 0 01-.5-.5v-1.016a.5.5 0 01.5-.5H16.4a.5.5 0 01.5.5v1.014a.5.5 0 01-.5.5zM9.4 21.5a.5.5 0 01-.5.5H7.615a.5.5 0 01-.5-.5v-1.016a.5.5 0 01.5-.5H8.9a.5.5 0 01.5.5V21.5zm5.929-13.179A1.34 1.34 0 1113.99 9.66a1.339 1.339 0 011.339-1.339zM14.138 13.5a1.5 1.5 0 011.3.749.5.5 0 01-.432.751h-1.734a.5.5 0 01-.433-.75 1.5 1.5 0 011.299-.75zm-2.967 1.25a.5.5 0 01-.434.25H9a.5.5 0 01-.432-.751 1.5 1.5 0 012.6 0 .5.5 0 01.003.501zM8.365 8.321a1.34 1.34 0 11-1.34 1.339 1.34 1.34 0 011.34-1.339z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});