define("ember-svg-jar/inlined/monitor-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-clock</title><path d=\"M10.381 11.5H2.5A.5.5 0 012 11V2.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v6.06a.5.5 0 00.559.5A7.9 7.9 0 0117.5 9a.5.5 0 00.5-.5V2a2 2 0 00-2-2H2a2 2 0 00-2 2v10.5a2 2 0 002 2h5.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5H5a1 1 0 000 2h3.5a.5.5 0 00.5-.5 8.454 8.454 0 011.776-5.194.5.5 0 00-.395-.806z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm0 11a4.5 4.5 0 114.5-4.5 4.505 4.505 0 01-4.5 4.5z\"/><path d=\"M19.5 16.751h-1a.25.25 0 01-.25-.25v-2a.75.75 0 10-1.5 0v3a.75.75 0 00.75.75h2a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});