define("ember-svg-jar/inlined/smart-watch-circle-power", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-power</title><path d=\"M12 3.5a8.5 8.5 0 108.5 8.5A8.5 8.5 0 0012 3.5zm-.667 4a.75.75 0 111.5 0v3.428a.75.75 0 01-1.5 0zm.75 9.25a4.75 4.75 0 01-3.358-8.109.75.75 0 011.06 1.059 3.25 3.25 0 104.6 0 .75.75 0 111.061-1.06 4.75 4.75 0 01-3.359 8.109zM7.379 3.051a.125.125 0 00.135.014 9.98 9.98 0 018.972 0 .125.125 0 00.177-.144l-.68-2.55A.5.5 0 0015.5 0h-7a.5.5 0 00-.483.371l-.68 2.551a.125.125 0 00.042.129z\"/><path d=\"M16.621 20.949a.125.125 0 00-.135-.015 9.972 9.972 0 01-8.972 0 .125.125 0 00-.177.144l.68 2.551A.5.5 0 008.5 24h7a.5.5 0 00.483-.371l.68-2.551a.125.125 0 00-.042-.129z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});