define("ember-svg-jar/inlined/award-star-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>award-star-1</title><path d=\"M15.6 18.5a.5.5 0 00.469-.676l-.143-.379A3.011 3.011 0 0013.5 15.53v-1.5a.5.5 0 00-.255-.436l-1-.563a.5.5 0 00-.49 0l-1 .564a.5.5 0 00-.254.435v1.5a3.013 3.013 0 00-2.423 1.915l-.142.379a.5.5 0 00.464.676zM15.09 12.334a1.256 1.256 0 001.466-.161 1.223 1.223 0 00.309-1.415l-1.092-2.512a.5.5 0 01.126-.573l1.969-1.754a1.229 1.229 0 00.293-1.343 1.2 1.2 0 00-1.14-.743h-2.212a.5.5 0 01-.459-.3L13.135.714a.182.182 0 00-.013-.028 1.261 1.261 0 00-2.258.027L9.648 3.531a.5.5 0 01-.459.3H6.977a1.2 1.2 0 00-1.138.741 1.244 1.244 0 00.312 1.364L8.1 7.674a.5.5 0 01.126.573l-1.091 2.511a1.226 1.226 0 00.31 1.416 1.25 1.25 0 001.466.16l2.844-1.6a.5.5 0 01.49 0zM7 20a2 2 0 000 4h10a2 2 0 000-4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});