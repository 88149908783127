define("ember-svg-jar/inlined/spa-candle-flower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>spa-candle-flower</title><path d=\"M14.824 12.567a3.126 3.126 0 01-1.081-.5A3.323 3.323 0 0112.414 9.5a4.1 4.1 0 01.18-1.392.5.5 0 00-.177-.546 3.526 3.526 0 01-1.4-3.878 3.329 3.329 0 012.033-2.06 4.069 4.069 0 011.384-.258.5.5 0 00.462-.331c.03-.082.062-.163.1-.242a.5.5 0 00-.205-.628 1.171 1.171 0 00-.6-.165H2.687a1.251 1.251 0 00-1.25 1.25v1.021a1.25 1.25 0 00.432.946l1.789 1.546A2.241 2.241 0 014.185 7.5C.371 14.874-.8 19.858.69 22.312A3.776 3.776 0 004.187 24h8.5a3.778 3.778 0 003.5-1.688c1.1-1.8.753-4.968-1.015-9.443a.5.5 0 00-.348-.302zM8.528 21.8a3.591 3.591 0 01-3.591-3.591 3.2 3.2 0 011.737-2.9.452.452 0 01.63.209 1.793 1.793 0 00.207.341.5.5 0 00.88-.179 3.888 3.888 0 00-.22-2.534.451.451 0 01.633-.582 6.62 6.62 0 013.315 5.649A3.591 3.591 0 018.528 21.8z\"/><path d=\"M22.1 6.821a2.294 2.294 0 001.76-2.675 2.293 2.293 0 00-3-1.129 3.129 3.129 0 00-.594.263.125.125 0 01-.185-.134 3.024 3.024 0 00.068-.646 2.292 2.292 0 00-2-2.5 2.293 2.293 0 00-2 2.5 3.089 3.089 0 00.068.646.125.125 0 01-.185.134 3.12 3.12 0 00-.593-.263 2.292 2.292 0 00-3 1.129 2.293 2.293 0 001.759 2.675 3.115 3.115 0 00.636.135.126.126 0 01.106.086.124.124 0 01-.036.131 3.108 3.108 0 00-.434.483 2.291 2.291 0 00.149 3.2 2.292 2.292 0 003.087-.847 3.074 3.074 0 00.325-.562.125.125 0 01.228 0 3.132 3.132 0 00.325.562 2.293 2.293 0 003.088.847 2.292 2.292 0 00.149-3.2 3.114 3.114 0 00-.435-.483.125.125 0 01.071-.217 3.12 3.12 0 00.643-.135zm-3.948.679a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});