define("ember-svg-jar/inlined/time-clock-file-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-clock-file-refresh</title><path d=\"M23.362 3.053L20.948.638a1.749 1.749 0 00-1.237-.512H8.125a1.749 1.749 0 00-1.75 1.75v7.267a.246.246 0 00.238.25c.26.007.675.023.992.051a.247.247 0 00.27-.248v-7.32a.251.251 0 01.25-.25h11.586l.177.073L22.3 4.113a.249.249 0 01.073.177v14.086a.251.251 0 01-.25.25h-7.4a.241.241 0 00-.237.2 8.044 8.044 0 01-.355 1.3h8a1.75 1.75 0 001.75-1.75V4.29a1.746 1.746 0 00-.519-1.237z\"/><path d=\"M18.875 8.376a3.254 3.254 0 01-3.25 3.25 3.14 3.14 0 01-.964-.156c-.06-.019-.157.013-.148.073.051.314.089 1 .1 1.311a.25.25 0 00.175.194 4.568 4.568 0 00.833.078 4.75 4.75 0 10-4.75-4.75 4.65 4.65 0 00.465 2 .232.232 0 00.24.053 1.965 1.965 0 011.043-.308.173.173 0 00.135-.226 3.208 3.208 0 01-.383-1.519 3.25 3.25 0 016.5 0zM11.9 18.476a1 1 0 00-1.257.584 4.025 4.025 0 01-1.276 1.929 4.163 4.163 0 01-5.657-.472.254.254 0 01.014-.348l1.717-1.717a.5.5 0 00-.354-.854H.625a.5.5 0 00-.5.5v4.458a.5.5 0 00.854.353l.974-.975a.244.244 0 01.353.009 6.085 6.085 0 0010.211-2.2 1 1 0 00-.617-1.267zM12.816 11.648a.5.5 0 00-.545.109l-1.03 1.03a.245.245 0 01-.352-.007 6.1 6.1 0 00-10.2 2.225 1 1 0 001.88.684 4.018 4.018 0 011.272-1.916 4.155 4.155 0 015.637.435.255.255 0 01-.012.35l-1.652 1.656a.5.5 0 00.354.853h4.457a.5.5 0 00.5-.5V12.11a.5.5 0 00-.309-.462z\"/><path d=\"M15.625 5.858a.75.75 0 00-.75.75v1.768a.75.75 0 00.75.75h1.768a.75.75 0 100-1.5h-.767a.252.252 0 01-.251-.252v-.766a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});