define("ember-svg-jar/inlined/keyboard-arrow-top-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-arrow-top-right</title><path d=\"M23.5 0h-7.071a.5.5 0 00-.354.854l2.3 2.3a.252.252 0 010 .354L6.529 15.347a1.5 1.5 0 102.121 2.121L20.5 5.624a.249.249 0 01.353 0l2.3 2.3a.5.5 0 00.354.147.508.508 0 00.191-.038.5.5 0 00.302-.464V.5a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});