define("ember-svg-jar/inlined/swan-couple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>swan-couple</title><path d=\"M8.617 11.393C7.081 10.12 5.754 9.019 5.754 6.6a1.508 1.508 0 01.5-1.118v.6a2.2 2.2 0 002.185 2.08 1.49 1.49 0 001.348-.748.5.5 0 00-.094-.577.825.825 0 01-.275-.9.508.508 0 00.052-.224 2.34 2.34 0 00-2.082-2.33 1.027 1.027 0 00-.832-.211A3.51 3.51 0 003.755 6.6c0 3.358 1.989 5.007 3.586 6.332 1.5 1.24 2.412 2.081 2.412 3.668a2.486 2.486 0 01-.065.565l-1.8-2.379A3.48 3.48 0 004.8 13.093a8.313 8.313 0 00-1.811.226c-.847.177-1.247.238-1.827-.15A.75.75 0 000 13.794v1.351a5.711 5.711 0 005.705 5.706h2.008a4.13 4.13 0 003.859-3 4.39 4.39 0 00.181-1.253c0-2.604-1.666-3.985-3.136-5.205zM23.6 13.132a.748.748 0 00-.77.037c-.579.387-.98.327-1.827.15a8.313 8.313 0 00-1.811-.226 3.461 3.461 0 00-3.084 1.7l-1.795 2.377a2.528 2.528 0 01-.065-.564c0-1.587.917-2.428 2.413-3.668 1.6-1.325 3.586-2.974 3.586-6.332a3.511 3.511 0 00-2.8-3.431.983.983 0 00-.839.213 2.337 2.337 0 00-2.075 2.328.508.508 0 00.052.224.822.822 0 01-.275.9.5.5 0 00-.094.577 1.49 1.49 0 001.347.748 2.163 2.163 0 002.186-2.08v-.6a1.507 1.507 0 01.5 1.118c0 2.418-1.326 3.519-2.862 4.792-1.471 1.22-3.136 2.6-3.136 5.208a4.216 4.216 0 003.463 4.208 3.98 3.98 0 00.577.042h2.252A5.526 5.526 0 0024 15.145v-1.351a.75.75 0 00-.4-.662z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});