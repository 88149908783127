define("ember-svg-jar/inlined/baggage-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baggage-add</title><path d=\"M9.276 15.417a8.019 8.019 0 015.663-5.647 7.938 7.938 0 014.244.038.245.245 0 00.317-.234V7a2.006 2.006 0 00-2-2H15a.25.25 0 01-.25-.25v-2.5A2.25 2.25 0 0012.5 0h-5a2.25 2.25 0 00-2.25 2.25v2.5A.25.25 0 015 5H2.5a2.006 2.006 0 00-2 2v11a2.006 2.006 0 002 2h6.574a.244.244 0 00.234-.316 7.935 7.935 0 01-.032-4.267zM7.75 4.75v-2A.25.25 0 018 2.5h4a.25.25 0 01.25.25v2A.25.25 0 0112 5H8a.25.25 0 01-.25-.25z\"/><path d=\"M17 11a6.5 6.5 0 106.5 6.5A6.508 6.508 0 0017 11zm3 7.25h-2a.25.25 0 00-.25.25v2a.75.75 0 01-1.5 0v-2a.25.25 0 00-.25-.25h-2a.75.75 0 010-1.5h2a.25.25 0 00.25-.25v-2a.75.75 0 011.5 0v2a.25.25 0 00.25.25h2a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});