define("ember-svg-jar/inlined/army-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-badge</title><path d=\"M19.415 11.8l-5.89-4.53a2.5 2.5 0 00-3.049 0L4.585 11.8a1.5 1.5 0 101.83 2.378l5.28-4.062a.5.5 0 01.61 0l5.28 4.062a1.5 1.5 0 101.83-2.378z\"/><path d=\"M6.415 7.677L11.7 3.615a.5.5 0 01.61 0l5.28 4.062A1.5 1.5 0 1019.415 5.3L13.525.769a2.5 2.5 0 00-3.049 0L4.585 5.3a1.5 1.5 0 101.83 2.378zM16.366 16.75h-2.182a.5.5 0 01-.45-.282l-1.145-2.36a.662.662 0 00-1.177 0l-1.148 2.361a.5.5 0 01-.45.281H7.632a.612.612 0 00-.432 1.069l1.936 2.023a.5.5 0 01.1.545l-1.067 2.456a.654.654 0 00.931.818l2.655-1.5a.5.5 0 01.491 0l2.655 1.5a.654.654 0 00.931-.818l-1.068-2.456a.5.5 0 01.1-.545l1.936-2.023a.612.612 0 00-.434-1.069z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});