define("ember-svg-jar/inlined/button-record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-record</title><path d=\"M12 23.88A11.881 11.881 0 10.119 12 11.895 11.895 0 0012 23.88zM16.451 12A4.451 4.451 0 1112 7.548 4.451 4.451 0 0116.451 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});