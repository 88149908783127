define("ember-svg-jar/inlined/folder-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-file</title><path d=\"M11 8h3.5a1 1 0 000-2H11a1 1 0 000 2zM11 12h7.5a1 1 0 000-2H11a1 1 0 000 2z\"/><path d=\"M23.707 5.707L18.293.293A1 1 0 0017.586 0H8a2 2 0 00-2 2v11.166c0 .2.1.334.25.334h1.5a.25.25 0 00.25-.25v-11A.25.25 0 018.25 2h8.818a.249.249 0 01.177.073l4.682 4.682a.249.249 0 01.073.177V19.75a.25.25 0 01-.25.25h-6a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25H22a2 2 0 002-2V6.414a1 1 0 00-.293-.707z\"/><path d=\"M13 15H6a1 1 0 01-.8-.4l-.9-1.2a1 1 0 00-.8-.4H1a1 1 0 00-1 1v9a1 1 0 001 1h12a1 1 0 001-1v-7a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});