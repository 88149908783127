define("ember-svg-jar/inlined/history-piggy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-piggy</title><path d=\"M4.481 5.493c2.83-.284 5.727-.285 7.519-.285s4.7 0 7.519.282a.249.249 0 00.271-.29l-.468-2.809A2.852 2.852 0 0016.5 0h-9a2.852 2.852 0 00-2.822 2.391L4.209 5.2a.251.251 0 00.272.29zm6.635-3.169l-.47-.47a.5.5 0 01.708-.707l.469.47a.252.252 0 00.354 0l.469-.47a.5.5 0 01.708.707l-.47.47a.249.249 0 000 .353l.47.47a.5.5 0 01-.708.707l-.469-.47a.252.252 0 00-.354 0l-.469.47a.5.5 0 01-.708-.707l.47-.47a.249.249 0 000-.353zM12.75 12h-1.5a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5z\"/><path d=\"M22.543 6.953h-.022A55.346 55.346 0 0012 6.208a55.3 55.3 0 00-10.538.744 1.927 1.927 0 00-.668 3.429.252.252 0 00.37-.089 10.328 10.328 0 01.789-1.3l.424-.592a.5.5 0 01.813.582A9.8 9.8 0 001 15.086c0 2.071.721 4.939 2.8 6.184l1.108.667a1 1 0 001.219-.152l.25-.25a.252.252 0 01.333-.018l1.626 1.3a1 1 0 001.332-.074l.153-.154a.252.252 0 01.354 0l1.116 1.117a1 1 0 001.414 0l1.116-1.116a.249.249 0 01.354 0l.153.153a1 1 0 001.331.074l1.626-1.3a.252.252 0 01.333.018l.249.249a1 1 0 001.221.151l1.112-.665c2.069-1.24 2.8-4.1 2.8-6.184a9.8 9.8 0 00-2.19-6.1.5.5 0 01.813-.582l.424.593a10.447 10.447 0 01.789 1.3.25.25 0 00.37.089 1.927 1.927 0 00-.663-3.428zM7 11a1 1 0 111-1 1 1 0 01-1 1zm7.486 8.111a3.922 3.922 0 01-4.974 0A1.152 1.152 0 0110.151 17h3.7a1.152 1.152 0 01.637 2.111zM15.5 15a1.259 1.259 0 01-1 .5h-5a1.256 1.256 0 01-1.207-1.6l.58-2.028a1.694 1.694 0 011.177-1.161A7.615 7.615 0 0112 10.5a7.615 7.615 0 011.95.213 1.691 1.691 0 011.176 1.161l.58 2.027A1.263 1.263 0 0115.5 15zm1.5-4a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});