define("ember-svg-jar/inlined/multiple-actions-block.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-block</title><path d=\"M23.833 16A6.423 6.423 0 0019 11.169 6.514 6.514 0 0011.168 19 6.509 6.509 0 0022.1 22.1a6.537 6.537 0 001.733-6.1zm-9.514-1.684a4.653 4.653 0 014.953-.964.5.5 0 01.152.811l-5.243 5.243a.5.5 0 01-.818-.167 4.366 4.366 0 01.956-4.921zm6.364 6.366a4.635 4.635 0 01-4.956.965.5.5 0 01-.149-.81l5.261-5.262a.5.5 0 01.81.15 4.627 4.627 0 01-.966 4.959zM12.024 8.535a.5.5 0 00-.182.771 7.473 7.473 0 01.787 1.187.5.5 0 00.7.19A7.93 7.93 0 0117.5 9.5a.32.32 0 00.169-.593 6 6 0 00-5.646-.373z\"/><ellipse cx=\"14.014\" cy=\"3.501\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M11.564 11.734a6.026 6.026 0 00-11.559 1.6A.6.6 0 00.6 14h9.411a.5.5 0 00.441-.264 7.962 7.962 0 011.024-1.484.5.5 0 00.088-.518z\"/><ellipse cx=\"5.514\" cy=\"3.501\" rx=\"3.513\" ry=\"3.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});