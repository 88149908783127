define("ember-svg-jar/inlined/single-man-actions-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-block</title><path d=\"M11.845 11.843a7.128 7.128 0 01.7-.607.25.25 0 00.1-.229.254.254 0 00-.15-.2l-1.335-.572a.251.251 0 01-.152-.23V8.871a.25.25 0 01.074-.177 3.306 3.306 0 00.779-2.231.251.251 0 01.088-.176 1.873 1.873 0 00.538-1.4 1.58 1.58 0 00-.317-1.015.252.252 0 01-.034-.265 2.528 2.528 0 00.156-1.726C11.945.493 10.242 0 8.889 0a3.752 3.752 0 00-3.163 1.325.252.252 0 01-.213.118 1.074 1.074 0 00-.9.375c-.371.493-.105 1.171.176 1.732a.251.251 0 01-.045.288 1.3 1.3 0 00-.162.2A1.766 1.766 0 004.32 5a1.524 1.524 0 00.639 1.331.249.249 0 01.117.194A3.167 3.167 0 005.921 8.7.248.248 0 016 8.884v1.122a.25.25 0 01-.151.23l-3.936 1.687c-1.272.546-1.455 1.7-1.707 3.308-.055.359-.117.751-.2 1.179A.5.5 0 00.5 17h8.8a.248.248 0 00.248-.227 7.955 7.955 0 012.297-4.93zM19 11.169A6.513 6.513 0 0011.168 19 6.421 6.421 0 0016 23.832a6.764 6.764 0 001.489.168 6.5 6.5 0 006.343-8A6.422 6.422 0 0019 11.169zm-2.56 10.712a4.111 4.111 0 01-1.051-.4.251.251 0 01-.058-.4l5.753-5.752a.25.25 0 01.4.058 4.125 4.125 0 01.4 1.051 4.513 4.513 0 01-5.441 5.44zM17.517 13a4.761 4.761 0 011.042.116 4.142 4.142 0 011.053.4.251.251 0 01.058.4l-5.753 5.754a.25.25 0 01-.4-.058 4.148 4.148 0 01-.4-1.054 4.5 4.5 0 014.4-5.558z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});