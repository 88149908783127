define("ember-svg-jar/inlined/real-estate-message-person-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-message-person-building</title><path d=\"M22.5 0h-12A1.5 1.5 0 009 1.5v11a1.5 1.5 0 001.5 1.5h.5a.5.5 0 01.5.5v1.146a1.356 1.356 0 002.312.957l2.456-2.457a.5.5 0 01.353-.146H22.5a1.5 1.5 0 001.5-1.5v-11A1.5 1.5 0 0022.5 0zM22 11.5a.5.5 0 01-.5.5H16a1 1 0 00-.707.293l-1.366 1.366a.25.25 0 01-.427-.177V13a1 1 0 00-1-1h-1a.5.5 0 01-.5-.5v-9a.5.5 0 01.5-.5h10a.5.5 0 01.5.5zM11.442 22.648c-.455-.2-2.028-.878-3.2-1.312a.549.549 0 01-.317-.428 1.591 1.591 0 01.255-1.262 4.768 4.768 0 001.388-3.867A3.48 3.48 0 006 12a3.48 3.48 0 00-3.569 3.779 4.764 4.764 0 001.39 3.868 1.593 1.593 0 01.253 1.262.547.547 0 01-.315.427 70.442 70.442 0 00-3.2 1.312A.929.929 0 000 23.5a.5.5 0 00.5.5h11a.5.5 0 00.5-.5.931.931 0 00-.558-.852z\"/><path d=\"M16.757 2.821a.5.5 0 00-.514 0l-2.5 1.5a.5.5 0 00-.243.429v6a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-6a.5.5 0 00-.243-.429zM17.5 8.25h-2a.5.5 0 010-1h2a.5.5 0 010 1zm0-2.5h-2a.5.5 0 010-1h2a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});