define("ember-svg-jar/inlined/monitor-exchange-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-exchange-arrow</title><path d=\"M10.25 11.5h-8a2 2 0 00-2 2v5.693a1.49 1.49 0 00.486 1.1A1.987 1.987 0 002.25 21H5a.25.25 0 01.25.25v.5A.25.25 0 015 22H3.75a1 1 0 000 2h5a1 1 0 000-2H7.5a.25.25 0 01-.25-.25v-.5A.25.25 0 017.5 21h2.75a1.987 1.987 0 001.514-.709 1.49 1.49 0 00.486-1.1V13.5a2 2 0 00-2-2zm-.25 2a.25.25 0 01.25.25v4A.25.25 0 0110 18H2.5a.25.25 0 01-.25-.25v-4a.25.25 0 01.25-.25zM23.75 2a2 2 0 00-2-2h-8a2 2 0 00-2 2v5.693a1.49 1.49 0 00.486 1.1 1.987 1.987 0 001.514.707h2.75a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-1.25a1 1 0 000 2h5a1 1 0 000-2H19a.25.25 0 01-.25-.25v-.5A.25.25 0 0119 9.5h2.75a1.987 1.987 0 001.514-.709 1.49 1.49 0 00.486-1.1zM21.5 2a.25.25 0 01.25.25v4a.25.25 0 01-.25.25H14a.25.25 0 01-.25-.25v-4A.25.25 0 0114 2zM7.126 5.39a.5.5 0 00.224.053.5.5 0 00.3-.1l2-1.5a.5.5 0 000-.8l-2-1.5a.5.5 0 00-.8.4v.25a.25.25 0 01-.25.25H4.85a3 3 0 00-3 3v1.5a.25.25 0 01-.25.25h-.25a.5.5 0 00-.4.8l1.5 2a.5.5 0 00.8 0l1.5-2a.5.5 0 00-.4-.8H4.1a.25.25 0 01-.25-.25v-1.5a1 1 0 011-1H6.6a.25.25 0 01.25.25v.25a.5.5 0 00.276.447z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});