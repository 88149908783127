define("ember-svg-jar/inlined/accounting-coins", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accounting-coins</title><path d=\"M7.673 9.209a.25.25 0 00-.248-.2 7.5 7.5 0 107.569 7.569.25.25 0 00-.2-.248 9.01 9.01 0 01-7.121-7.121zM9.4 14.233a.75.75 0 01-.75.75h-1.9a.5.5 0 00-.187.969l1.926.771a1.995 1.995 0 01-.3 3.8.25.25 0 00-.189.244V21a.75.75 0 01-1.5 0v-.167a.25.25 0 00-.25-.25h-.4a.75.75 0 010-1.5h1.9a.5.5 0 00.5-.5.5.5 0 00-.314-.466l-1.927-.77a2 2 0 01.3-3.8.25.25 0 00.191-.247v-.234a.75.75 0 011.5 0v.167a.25.25 0 00.25.25h.4a.75.75 0 01.75.75z\"/><path d=\"M16.5 0A7.5 7.5 0 1024 7.5 7.508 7.508 0 0016.5 0zm0 13.208a.75.75 0 01-.75-.75V12a.25.25 0 00-.25-.25h-.75a.75.75 0 010-1.5h2.372a.815.815 0 00.3-1.572l-2.406-.963a2.308 2.308 0 01.518-4.43.251.251 0 00.213-.248v-.5a.75.75 0 111.5 0V3a.25.25 0 00.25.25h.75a.75.75 0 010 1.5h-2.369a.815.815 0 00-.3 1.572l2.406.963a2.309 2.309 0 01-.518 4.43.251.251 0 00-.213.248v.5a.75.75 0 01-.753.745z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});