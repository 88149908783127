define("ember-svg-jar/inlined/brain-head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>brain-head</title><path d=\"M1 5a1 1 0 001-1V2.5a.5.5 0 01.5-.5H4a1 1 0 000-2H2a2 2 0 00-2 2v2a1 1 0 001 1zM24 2a2 2 0 00-2-2h-2a1 1 0 000 2h1.5a.5.5 0 01.5.5V4a1 1 0 002 0zM5 23a1 1 0 00-1-1H2.5a.5.5 0 01-.5-.5V20a1 1 0 00-2 0v2a2 2 0 002 2h2a1 1 0 001-1zM23 19a1 1 0 00-1 1v1.5a.5.5 0 01-.5.5H20a1 1 0 000 2h2a2 2 0 002-2v-2a1 1 0 00-1-1zM19.5 13.56a5.058 5.058 0 00-.95-2.54 6.387 6.387 0 01-.44-.86c0-3.54-3.26-5.66-6.41-5.66A6.646 6.646 0 005 11.24a5.923 5.923 0 002.4 5.137.5.5 0 01.21.407V20a.5.5 0 00.5.5h6.52a.5.5 0 00.5-.5v-1.61a.5.5 0 01.5-.5h.63a1.807 1.807 0 001.81-1.8v-.958a.5.5 0 01.476-.5h.044a.991.991 0 00.76-.41 1.085 1.085 0 00.15-.662zm-3.7-3.47a.384.384 0 01-.3.15H7.76a.384.384 0 01-.3-.15.37.37 0 01-.07-.32 4.353 4.353 0 018.49 0 .411.411 0 01-.08.32z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});