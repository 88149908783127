define("ember-svg-jar/inlined/arrow-thick-circle-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-circle-right</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm7.14 12.352l-6.286 6.287a.5.5 0 01-.854-.354V14.5a.25.25 0 00-.25-.25H5.5a.5.5 0 01-.5-.5v-3.496a.5.5 0 01.5-.5h6.25A.25.25 0 0012 9.5V5.713a.5.5 0 01.854-.354l6.286 6.286a.5.5 0 010 .707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});