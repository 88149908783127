define("ember-svg-jar/inlined/elephant-head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>elephant-head</title><path d=\"M23.524 3.087A5.072 5.072 0 0019 .279a7.368 7.368 0 00-3.739 1.06A5.776 5.776 0 0012 .279a5.776 5.776 0 00-3.261 1.06A7.364 7.364 0 005 .279 5.073 5.073 0 00.476 3.086a2.484 2.484 0 00-.107 2.182c1.9 4.455 2.658 4.834 2.907 4.959a2.048 2.048 0 00.92.189A5.45 5.45 0 006 10.034a6.8 6.8 0 01-1.013 1.656 7.942 7.942 0 00-1.374 4.245c0 2.714 2.915 3.748 3.039 3.79a1 1 0 001.326-.944c0-3.29 1.819-3.2 2.006-3.259-.077.856-.477 3.4-1.106 7.023a1 1 0 00.439 1.009c.6.393 1.088 0 1.465-.249a2.489 2.489 0 012.774 0c.374.248.842.591 1.423.2a1 1 0 00.413-1.039c-.779-3.58-1.274-6.092-1.371-6.94.18.051 2-.03 2 3.256a1 1 0 001.326.946c.124-.042 3.038-1.076 3.038-3.79a7.926 7.926 0 00-1.373-4.244A6.81 6.81 0 0118 10.034a5.425 5.425 0 001.8.382 2.064 2.064 0 00.92-.189c.25-.125 1.009-.505 2.907-4.959a2.481 2.481 0 00-.103-2.181zm-10.6 4.692a1.25 1.25 0 111.25 1.25 1.25 1.25 0 01-1.255-1.25zm-4.5 0a1.25 1.25 0 111.25 1.25 1.25 1.25 0 01-1.255-1.25zm-2.256 9.343a1.65 1.65 0 01-.555-1.187 5.963 5.963 0 01.987-3.068l.4-.545a3.812 3.812 0 012.383 1.3c-1.935.21-2.859 1.917-3.215 3.5zm11.665 0c-.356-1.582-1.281-3.289-3.217-3.5A3.82 3.82 0 0117 12.321l.4.546a5.959 5.959 0 01.991 3.068 1.652 1.652 0 01-.558 1.186z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});