define("ember-svg-jar/inlined/analytics-net", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>analytics-net</title><path d=\"M23 20.5v-17a3 3 0 00-3-3H4a3 3 0 00-3 3v17a3 3 0 003 3h16a3 3 0 003-3zm-2-5.75a.249.249 0 01-.25.25h-4a.249.249 0 01-.25-.25V9.18a.251.251 0 01.09-.193A31.579 31.579 0 0120.628 6a.249.249 0 01.372.22zm-13 6.5a.249.249 0 01-.25.25H4a1 1 0 01-1-1v-3.25a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25zm0-6.5a.249.249 0 01-.25.25H3.081A.081.081 0 013 14.919a.084.084 0 01.021-.055L6.4 11.153a1.858 1.858 0 011.36-.52.249.249 0 01.245.25zM4.914 9.808l-1.479 1.627A.251.251 0 013 11.267V3.5a1 1 0 011-1h16a1 1 0 011 1 .218.218 0 01-.149.207c-2.041.693-4.271 2.747-7.913 5.658a2.5 2.5 0 01-2.31.264l-1.393-.667a3.864 3.864 0 00-4.321.846zM14.5 21.25a.249.249 0 01-.25.25h-4a.249.249 0 01-.25-.25v-4a.25.25 0 01.25-.25h4a.25.25 0 01.25.25zm0-6.5a.249.249 0 01-.25.25h-4a.249.249 0 01-.25-.25v-2.869a.251.251 0 01.318-.241 4.56 4.56 0 003.869-.712.193.193 0 01.313.151zm2 6.5v-4a.25.25 0 01.25-.25h4a.25.25 0 01.25.25v3.25a1 1 0 01-1 1h-3.25a.249.249 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});