define("ember-svg-jar/inlined/road-sign-u-turn-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-u-turn-right</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm5.594 11.25a.75.75 0 01.58 1.225l-2.093 2.562c-.011.013-.026.023-.037.036s-.024.024-.037.036a.679.679 0 01-.507.2.745.745 0 01-.306-.069.8.8 0 01-.071-.048.722.722 0 01-.175-.136c-.007-.008-.018-.011-.025-.019L12.8 12.479a.75.75 0 01.577-1.229H14.5a.25.25 0 00.25-.25 2.75 2.75 0 00-5.5 0v6a.75.75 0 01-1.5 0v-6a4.25 4.25 0 018.5 0 .25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});