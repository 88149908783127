define("ember-svg-jar/inlined/cash-user-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-user-message</title><path d=\"M12.5 0A1.5 1.5 0 0011 1.5v8a1.5 1.5 0 001.5 1.5h.25a.25.25 0 01.25.25v2.25a.5.5 0 00.291.454.493.493 0 00.209.046.5.5 0 00.325-.12l3.289-2.819a.246.246 0 01.163-.061H22.5A1.5 1.5 0 0024 9.5v-8A1.5 1.5 0 0022.5 0zm3.833 4.875h2.334a1.792 1.792 0 010 3.584h-.292a.249.249 0 00-.25.25V9a.625.625 0 01-1.25 0v-.291a.249.249 0 00-.25-.25h-1.458a.625.625 0 110-1.25h3.5a.542.542 0 000-1.084h-2.334a1.792 1.792 0 010-3.583h.292a.25.25 0 00.25-.25V2a.625.625 0 011.25 0v.292a.25.25 0 00.25.25h1.458a.625.625 0 010 1.25h-3.5a.542.542 0 000 1.083z\"/><circle cx=\"5\" cy=\"7.75\" r=\"3.75\"/><path d=\"M.5 21h1.385a.25.25 0 01.245.2l.478 2.393a.5.5 0 00.49.4L6.9 24a.5.5 0 00.491-.4l.48-2.4a.25.25 0 01.245-.2H9.5a.5.5 0 00.5-.5V17a5 5 0 00-10 0v3.5a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});