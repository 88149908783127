define("ember-svg-jar/inlined/asian-interior-windows", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>asian-interior-windows</title><path d=\"M24 1.5A1.5 1.5 0 0022.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5zm-13 20a.5.5 0 01-.5.5H3.011a.25.25 0 01-.176-.427 2.719 2.719 0 011.972-.825A4.267 4.267 0 008.638 18.4v-.007L9.8 16.259a.5.5 0 01.44-.261h.26a.5.5 0 01.5.5zm0-9.366a.25.25 0 01-.391.207A1.962 1.962 0 009.5 12a1.986 1.986 0 00-1.983 1.794.249.249 0 01-.283.223A1.642 1.642 0 007 14a2 2 0 00-1.9 1.38.493.493 0 00.486.62h2.23a.25.25 0 01.22.369L7.312 17.7v.007a2.731 2.731 0 01-1.147 1.164.251.251 0 01-.242 0A2.861 2.861 0 014.25 16.5a.75.75 0 00-1.5 0 3.778 3.778 0 00.986 2.522.25.25 0 01.051.235.247.247 0 01-.17.169A4.35 4.35 0 002.39 20a.249.249 0 01-.39-.211V2.5a.5.5 0 01.5-.5h8a.5.5 0 01.5.5zM22 21.5a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5v-5.982a.248.248 0 01.137-.222 9.036 9.036 0 003.19-2.961.248.248 0 01.353-.056l.076.056a.682.682 0 00.127.077 4.354 4.354 0 012.225 2.465.75.75 0 001.414-.5 5.866 5.866 0 00-2.907-3.277.314.314 0 01-.039-.024l-.124-.092a.222.222 0 01-.055-.293 9.582 9.582 0 012.039-2.638.248.248 0 01.144-.047h2.17a.25.25 0 01.25.25zm0-18.068a.25.25 0 01-.4.2 2.375 2.375 0 00-1.417-.468 2.408 2.408 0 00-2.406 2.22.25.25 0 01-.094.177.253.253 0 01-.2.051 2.1 2.1 0 00-.33-.027 2.421 2.421 0 00-2.3 1.67.6.6 0 00.589.75H16.9a.249.249 0 01.2.395c-.334.469-.653.979-.976 1.5a13.2 13.2 0 01-2.717 3.458.25.25 0 01-.406-.2V2.5a.5.5 0 01.5-.5h8a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});