define("ember-svg-jar/inlined/read-email-at-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-email-at-1-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M18.505 6.615a8.638 8.638 0 00-6.511-2.433 7.818 7.818 0 102.3 15.292 1 1 0 10-.586-1.912 5.819 5.819 0 11-1.714-11.38A6.83 6.83 0 0117.064 8a5.248 5.248 0 011.436 3.616c0 1.373-.612 2.793-1.637 2.793a2.263 2.263 0 01-.363-.025V9.078a1 1 0 00-1-1 .986.986 0 00-.848.5A4.305 4.305 0 0012 7.653a4.348 4.348 0 000 8.7 4.305 4.305 0 002.759-1.016 1.8 1.8 0 00.318.443 2.411 2.411 0 001.787.634c2.108 0 3.637-2.015 3.637-4.793a7.269 7.269 0 00-1.996-5.006zM12 14.348A2.348 2.348 0 1114.348 12 2.349 2.349 0 0112 14.348z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});