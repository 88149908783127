define("ember-svg-jar/inlined/shopping-basket-sad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-basket-sad</title><path d=\"M22 8.375h-.7a.25.25 0 01-.22-.131c-.118-.219-.7-1.208-3.5-6a1.25 1.25 0 00-2.16 1.26L18.041 8a.25.25 0 01-.215.376H6.174A.25.25 0 015.959 8L8.58 3.505a1.25 1.25 0 00-2.16-1.26c-2.779 4.764-3.373 5.772-3.5 6a.25.25 0 01-.22.131H2a1.5 1.5 0 00-.13 2.99.251.251 0 01.222.215l1.194 8.757a2.451 2.451 0 002.48 2.038h12.468a2.451 2.451 0 002.48-2.038l1.194-8.757a.251.251 0 01.221-.215A1.5 1.5 0 0022 8.375zm-14.5 5.75a1.25 1.25 0 111.25-1.25 1.25 1.25 0 01-1.25 1.25zM12 16.19a4.956 4.956 0 014.123 2.2.75.75 0 01-1.246.835 3.46 3.46 0 00-5.754 0 .75.75 0 01-1.246-.835A4.955 4.955 0 0112 16.19zm4.5-2.065a1.25 1.25 0 111.25-1.25 1.25 1.25 0 01-1.25 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});