define("ember-svg-jar/inlined/align-portrait", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>align-portrait</title><path d=\"M24 3.5a2 2 0 00-2-2H9.5a1 1 0 100 2H21a1 1 0 011 1v15a1 1 0 01-1 1H9.5a1 1 0 000 2H22a2 2 0 002-2zM4.353 3.145a.5.5 0 00-.707 0l-3.5 3.5A.5.5 0 00.5 7.5h2.25a.25.25 0 01.25.25V20.5a1 1 0 002 0V7.748a.25.25 0 01.25-.25H7.5a.5.5 0 00.353-.853z\"/><path d=\"M17.572 8.784A2.572 2.572 0 1015 11.355a2.571 2.571 0 002.572-2.571zM11.591 17.784h6.819a1 1 0 001-1.145 4.425 4.425 0 00-8.808 0 1 1 0 00.989 1.145z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});