define("ember-svg-jar/inlined/light-mode-flash-on", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-mode-flash-on</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M16.4 10h-2.5a.25.25 0 01-.208-.389l1.964-2.946a.75.75 0 00-.623-1.165h-3.724a1 1 0 00-.895.552l-3.182 6.365A.75.75 0 007.9 13.5h1.56a.25.25 0 01.237.329l-1.672 5.013a.5.5 0 00.829.512l8.074-8.074A.751.751 0 0016.4 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});