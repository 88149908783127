define("ember-svg-jar/inlined/landmark-triumph-gate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-triumph-gate</title><path d=\"M1 14.51a1 1 0 000 2 .249.249 0 01.25.25V22a1.5 1.5 0 001.5 1.5H7.5A1.5 1.5 0 009 22v-5.24a.25.25 0 01.25-.25 1 1 0 000-2zM22.251 4a.5.5 0 00.5-.5V2a1.5 1.5 0 00-1.5-1.5H2.749a1.5 1.5 0 00-1.5 1.5v1.5a.5.5 0 00.5.5zM14.75 14.51a1 1 0 100 2 .25.25 0 01.25.25V22a1.5 1.5 0 001.5 1.5h4.751a1.5 1.5 0 001.5-1.5v-5.24a.249.249 0 01.25-.25 1 1 0 000-2zM1 5.01a1 1 0 000 2 .249.249 0 01.25.25V13a.5.5 0 00.5.5h6.785a.5.5 0 00.495-.428 3 3 0 015.939 0 .5.5 0 00.5.428h6.787a.5.5 0 00.5-.5V7.26A.249.249 0 0123 7.01a1 1 0 000-2zM17 8.5a1 1 0 011-1h1.751a1 1 0 011 1v1a1 1 0 01-1 1H18a1 1 0 01-1-1zm-10 1a1 1 0 01-1 1H4.249a1 1 0 01-1-1v-1a1 1 0 011-1H6a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});