define("ember-svg-jar/inlined/charger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>charger</title><path d=\"M22.206 4.536a1 1 0 00-1.414 0l-1.906 1.906a.25.25 0 01-.354 0l-2.475-2.475a.252.252 0 010-.354l1.906-1.9A1 1 0 1016.549.294L14.643 2.2a.249.249 0 01-.353 0l-.463-.462a1.5 1.5 0 00-2.121 0L8.525 4.919a5.726 5.726 0 00-1.647 4.368 6.592 6.592 0 00.711 2.688.251.251 0 01-.046.289L5.7 14.112a.5.5 0 000 .707l.221.222a.25.25 0 010 .355l-3.542 3.447a3 3 0 000 4.241 3.074 3.074 0 004.242 0L9.8 19.905a.5.5 0 11.708.707l-1.681 1.682a1 1 0 001.414 1.414l1.684-1.683a2.5 2.5 0 00-3.537-3.534l-3.182 3.182a1.025 1.025 0 01-1.414 0 1 1 0 01-.01-1.4l3.549-3.46a.251.251 0 01.351 0l.132.132a.5.5 0 00.707 0l1.848-1.845a.249.249 0 01.289-.046 6.581 6.581 0 002.964.718 5.739 5.739 0 004.094-1.656l3.184-3.187a1.5 1.5 0 000-2.121l-.6-.6a.25.25 0 010-.354l1.906-1.904a1 1 0 000-1.414zm-8.379 4.272a2 2 0 010 2.828 2 2 0 110-2.828z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});