define("ember-svg-jar/inlined/truck-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>truck-empty</title><path d=\"M24 14.75a1.5 1.5 0 00-1.5-1.5H12.25a.25.25 0 00-.25.25v2.25a.75.75 0 01-1.5 0V7.079a1 1 0 01.293-.708l.914-.914a1 1 0 10-1.414-1.414l-.914.914a2.945 2.945 0 00-.817 1.587.251.251 0 01-.247.206H5.254a2.018 2.018 0 00-1.933 1.49l-.672 2.778a.254.254 0 01-.131.164l-1.689.844A1.491 1.491 0 000 13.368v3.382a1.5 1.5 0 001.5 1.5h1.76a.25.25 0 00.249-.231 3 3 0 015.982 0 .251.251 0 00.249.231h6.52a.25.25 0 00.249-.231 3 3 0 015.982 0 .251.251 0 00.249.231h.76a.5.5 0 00.5-.5zM5.208 8.941a.249.249 0 01.243-.191h2.8A.25.25 0 018.5 9v1.5a.25.25 0 01-.25.25H5.088a.25.25 0 01-.243-.309z\"/><circle cx=\"6.5\" cy=\"18.25\" r=\"2\"/><circle cx=\"19.5\" cy=\"18.25\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});