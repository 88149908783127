define("ember-svg-jar/inlined/data-file-bars-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-bars-settings</title><rect x=\"14.501\" y=\"5.5\" width=\"2\" height=\"8\" rx=\".5\" ry=\".5\"/><rect x=\"18.001\" y=\"7.5\" width=\"2\" height=\"6\" rx=\".5\" ry=\".5\"/><path d=\"M21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v6.356a.247.247 0 00.214.248 7.794 7.794 0 011.45.318A.249.249 0 008 8.688V2.5a.5.5 0 01.5-.5h10.88a.5.5 0 01.353.146l2.121 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-7.374a.249.249 0 00-.236.32 3.229 3.229 0 01.047 1.377.249.249 0 00.24.3H22a2 2 0 002-2V4.414A2 2 0 0023.415 3z\"/><path d=\"M12.858 11.959c.049.042.143-.018.143-.1V10.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v.541a.167.167 0 00.129.14 3.214 3.214 0 011.728.778z\"/><circle cx=\"6.251\" cy=\"17.031\" r=\"1.5\"/><path d=\"M.483 18.408a1.782 1.782 0 001.7 2.953l1.41-.326a.453.453 0 01.534.311l.424 1.392a1.775 1.775 0 003.4 0l.424-1.392a.452.452 0 01.533-.311l1.411.326a1.782 1.782 0 001.7-2.953l-.988-1.064a.463.463 0 010-.625l.988-1.065a1.782 1.782 0 00-1.7-2.953l-1.41.326a.45.45 0 01-.534-.31l-.425-1.393a1.775 1.775 0 00-3.4 0l-.424 1.393a.456.456 0 01-.533.31L2.184 12.7a1.782 1.782 0 00-1.7 2.953l.988 1.065a.463.463 0 010 .625zm5.768-4.377a3 3 0 11-3 3 3 3 0 013-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});