define("ember-svg-jar/inlined/award-wall-star-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>award-wall-star-alternate</title><path d=\"M11.839 2.133a.252.252 0 01.321 0l4.008 3.34a.246.246 0 00.16.058h2.343a.25.25 0 00.16-.442L13.28.464a2 2 0 00-2.56 0L5.169 5.089a.25.25 0 00.16.442h2.343a.248.248 0 00.16-.058zM9.291 15.229l-.818 1.876a1 1 0 00.252 1.157 1.02 1.02 0 001.2.133L12 17.227l2.079 1.167a1.016 1.016 0 001.2-.131 1 1 0 00.252-1.158l-.817-1.877 1.486-1.311a1.006 1.006 0 00.239-1.1.985.985 0 00-.935-.613h-1.675l-.9-2.086-.014-.029A1.023 1.023 0 0012 9.531a1.028 1.028 0 00-.925.588l-.9 2.086H8.5a.985.985 0 00-.934.611 1.016 1.016 0 00.259 1.119z\"/><path d=\"M24 7.781a2 2 0 00-2-2 2.025 2.025 0 00-1.851 1.25H3.851A2.034 2.034 0 002 5.781 2 2 0 103.5 9.09v8.515a3.078 3.078 0 001.306 2.407l5.807 3.588a2.54 2.54 0 001.4.4 2.447 2.447 0 001.348-.379l5.837-3.61.029-.019a3.067 3.067 0 001.273-2.387V9.09A1.992 1.992 0 0024 7.781zm-5.5 9.824a1.139 1.139 0 01-.377.717l-5.846 3.617a.673.673 0 01-.583-.018l-5.808-3.592a1.133 1.133 0 01-.386-.724V9.281a.5.5 0 01.5-.5h12a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});