define("ember-svg-jar/inlined/real-estate-dimensions-house-ruler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-dimensions-house-ruler</title><path d=\"M16.706 9.5a1 1 0 00-1.414 0l-7.146 7.146a.5.5 0 00.354.854h1a.5.5 0 01.5.5v5a1 1 0 001 1h2.5a1 1 0 001-1v-2.5a1.5 1.5 0 113 0V23a1 1 0 001 1H21a1 1 0 001-1v-5a.5.5 0 01.5-.5h1a.5.5 0 00.354-.854z\"/><path d=\"M23 0H1a1 1 0 00-1 1v22a1 1 0 001 1h3.5a.5.5 0 00.5-.5v-2.75a.5.5 0 00-.5-.5h-2a.75.75 0 010-1.5h2a.5.5 0 00.5-.5v-2.5a.5.5 0 00-.5-.5h-2a.75.75 0 010-1.5h2a.5.5 0 00.5-.5v-2.5a.5.5 0 00-.5-.5h-2a.75.75 0 010-1.5h2a.5.5 0 00.5-.5V5.5a.5.5 0 01.5-.5h2.75a.5.5 0 00.5-.5v-2a.75.75 0 011.5 0v2a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-2a.75.75 0 011.5 0v2a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-2a.75.75 0 011.5 0v2a.5.5 0 00.5.5h2.75a.5.5 0 00.5-.5V1a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});