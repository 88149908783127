define("ember-svg-jar/inlined/religion-man-muslim-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-man-muslim-1</title><path d=\"M.124 22.763a.99.99 0 101.979 0v-.99a1.2 1.2 0 01.373-.881c1.182-.9 3.816-2.373 8.524-2.56a.246.246 0 01.182.069.25.25 0 01.076.178v4.679a.742.742 0 001.484 0v-4.711a.217.217 0 01.225-.216c4.729.181 7.372 1.657 8.558 2.562a.963.963 0 01.372.775v1.1a.99.99 0 101.979 0v-1.1a2.926 2.926 0 00-1.151-2.349 14.472 14.472 0 00-5.7-2.472.247.247 0 01-.091-.442 4.709 4.709 0 001.985-4.105.115.115 0 010-.019c.022-5.734.012-5.422 0-5.514l-.576-3.457A3.946 3.946 0 0014.433 0H9.567a3.946 3.946 0 00-3.9 3.308l-.581 3.457c-.016.09-.029-.123 0 5.44v.019a4.763 4.763 0 001.982 4.181.247.247 0 01-.091.442 14.467 14.467 0 00-5.7 2.471 3.162 3.162 0 00-1.153 2.455zM9.567 1.979h4.866a1.974 1.974 0 011.953 1.655l.418 2.51a.244.244 0 01-.075.221.249.249 0 01-.227.06A19.244 19.244 0 0012 5.938a19.244 19.244 0 00-4.5.487.247.247 0 01-.3-.281l.418-2.51a1.974 1.974 0 011.949-1.655zM12 7.423a16.644 16.644 0 014.772.613.248.248 0 01.176.237v.98a.245.245 0 01-.069.172l-1.956 2.028a1.008 1.008 0 00-.079.094.889.889 0 01-1 .311 5.884 5.884 0 00-3.7 0 .891.891 0 01-1-.311 1.008 1.008 0 00-.079-.094L7.121 9.425a.245.245 0 01-.069-.172v-.98a.248.248 0 01.176-.237A16.644 16.644 0 0112 7.423zm-1.633 6.785a.743.743 0 01.72-.56h1.826a.743.743 0 01.356 1.394 2.277 2.277 0 01-.428.178 2.842 2.842 0 01-.841.122 2.914 2.914 0 01-.839-.122 2.346 2.346 0 01-.427-.178.742.742 0 01-.367-.834z\"/><circle cx=\"14.474\" cy=\"19.794\" r=\".99\"/><circle cx=\"14.474\" cy=\"22.763\" r=\".99\"/><circle cx=\"13.979\" cy=\"9.258\" r=\".99\"/><circle cx=\"10.021\" cy=\"9.258\" r=\".99\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});