define("ember-svg-jar/inlined/road-sign-keep-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-keep-right</title><path d=\"M18 3.75a.25.25 0 10-.25-.25.25.25 0 00.25.25z\"/><circle cx=\"5\" cy=\"10.75\" r=\".25\"/><path d=\"M11.25 13.5v-.134a.25.25 0 00-.125-.216.752.752 0 01-.375-.65v1c0 .275.5.275.5 0z\"/><path d=\"M23.25 0H.75A.75.75 0 000 .75v22.5a.75.75 0 00.75.75h22.5a.75.75 0 00.75-.75V.75a.75.75 0 00-.75-.75zm-7 2a.75.75 0 01.75-.75h.5a2.244 2.244 0 01.45 4.443.249.249 0 00-.2.245V7a.75.75 0 01-1.5 0zm.5 7.5v5a.75.75 0 01-1.5 0v-1a.25.25 0 00-.5 0v1a.75.75 0 01-1.5 0v-5a.75.75 0 011.5 0v2a.25.25 0 00.5 0v-2a.75.75 0 011.5 0zM12.25 3A1.752 1.752 0 0114 1.25h1a.75.75 0 010 1.5h-1a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h1a.75.75 0 010 1.5h-1a.25.25 0 000 .5h1a.75.75 0 010 1.5h-1A1.752 1.752 0 0112.25 6zm.5 6.5a.75.75 0 01-.75.75h-1a.25.25 0 00-.25.25v2a.75.75 0 01.75-.75h.5a.75.75 0 01.75.75v1a1.75 1.75 0 01-3.5 0v-3A1.752 1.752 0 0111 8.75h1a.75.75 0 01.75.75zM8.25 3A1.752 1.752 0 0110 1.25h1a.75.75 0 010 1.5h-1a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h1a.75.75 0 010 1.5h-1a.25.25 0 000 .5h1a.75.75 0 010 1.5h-1A1.752 1.752 0 018.25 6zm.5 6.5v5a.75.75 0 01-1.5 0v-5a.75.75 0 011.5 0zM4.25 2a.75.75 0 011.5 0 .127.127 0 00.226.079l.439-.548a.75.75 0 111.17.938l-1.5 1.875a.25.25 0 000 .312l1.5 1.875a.75.75 0 11-1.17.938l-.439-.548A.127.127 0 005.75 7a.75.75 0 01-1.5 0zm-1 12.5v-5A.75.75 0 014 8.75h.75a1.992 1.992 0 011.131 3.638.249.249 0 00-.073.334l.835 1.392a.75.75 0 11-1.286.772l-.285-.475a.173.173 0 00-.322.089.75.75 0 01-1.5 0zm17.423 5.819a.612.612 0 01-.054.079.726.726 0 01-.149.173c-.013.011-.019.029-.034.039l-3.5 2.5a.75.75 0 01-1.186-.61V21a.25.25 0 00-.25-.25h-11a.75.75 0 010-1.5h11a.25.25 0 00.25-.25v-1.5a.75.75 0 011.186-.61l3.5 2.5c.015.01.021.028.034.039a.726.726 0 01.149.173.612.612 0 01.054.079.7.7 0 010 .638zM20 10.25a.25.25 0 00-.25.25v4a.75.75 0 01-1.5 0v-4a.25.25 0 00-.25-.25.75.75 0 010-1.5h2a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});