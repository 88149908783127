define("ember-svg-jar/inlined/wedding-altar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wedding-altar</title><path d=\"M12 0A12.013 12.013 0 000 12v11a1 1 0 001 1h22a1 1 0 001-1V12A12.013 12.013 0 0012 0zm9.8 15.9c-1.725.881-3.192 3.03-3.923 5.734a.5.5 0 01-.482.37H6.61a.5.5 0 01-.482-.37c-.731-2.7-2.2-4.853-3.923-5.734A.374.374 0 012 15.562a.288.288 0 01.2-.273C4.933 14.363 7 10.336 7 5.5a15.633 15.633 0 00-.107-1.734.5.5 0 01.252-.494 9.878 9.878 0 013.276-1.13.5.5 0 01.579.494v6.492a.249.249 0 01-.33.237 2.092 2.092 0 00-2.52.965 1.883 1.883 0 00.384 2.254l2.988 2.969a.68.68 0 00.956 0l2.988-2.969a1.883 1.883 0 00.384-2.254 2.091 2.091 0 00-2.52-.965.249.249 0 01-.33-.237V2.636a.5.5 0 01.579-.494 9.87 9.87 0 013.276 1.13.5.5 0 01.253.494A15.4 15.4 0 0017 5.5c0 4.836 2.067 8.863 4.8 9.789a.288.288 0 01.2.273.374.374 0 01-.2.338z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});