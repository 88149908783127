define("ember-svg-jar/inlined/charging-light-idea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>charging-light-idea</title><path d=\"M4.146 11.386a7.849 7.849 0 109.593-7.653.952.952 0 01-.745-.931V1a1 1 0 00-2 0v1.8a2.94 2.94 0 002.3 2.881 5.848 5.848 0 11-4.044.539 1 1 0 10-.939-1.765 7.837 7.837 0 00-4.165 6.931z\"/><path d=\"M12.861 6.575a.485.485 0 00-.533.138l-3.72 4.331a.717.717 0 00.548 1.181h.918a.25.25 0 01.25.25v3.041a.482.482 0 00.848.314l3.719-4.33a.717.717 0 00-.547-1.182h-.918a.25.25 0 01-.25-.25V7.027a.482.482 0 00-.315-.452z\"/><circle cx=\"6.858\" cy=\"2.621\" r=\"1.479\"/><circle cx=\"2.957\" cy=\"6.381\" r=\"1.478\"/><circle cx=\"1.521\" cy=\"11.761\" r=\"1.479\"/><circle cx=\"2.957\" cy=\"16.652\" r=\"1.478\"/><circle cx=\"6.858\" cy=\"20.566\" r=\"1.478\"/><circle cx=\"11.75\" cy=\"22.522\" r=\"1.479\"/><circle cx=\"17.131\" cy=\"20.566\" r=\"1.478\"/><circle cx=\"21.032\" cy=\"16.652\" r=\"1.478\"/><circle cx=\"22.478\" cy=\"11.761\" r=\"1.479\"/><circle cx=\"21.032\" cy=\"6.37\" r=\"1.478\"/><circle cx=\"17.131\" cy=\"2.468\" r=\"1.478\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});