define("ember-svg-jar/inlined/arrow-thick-right-bottom-corner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-right-bottom-corner</title><path d=\"M23.278 6.047a1 1 0 00-1.089.217L17.457 11a.251.251 0 01-.354 0L6.5.4a1 1 0 00-1.411 0L.4 5.089A1 1 0 00.4 6.5L11 17.1a.251.251 0 010 .354l-4.737 4.735A1 1 0 006.97 23.9H22.9a1 1 0 001-1V6.971a1 1 0 00-.622-.924z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});