define("ember-svg-jar/inlined/mobile-shopping-shop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mobile-shopping-shop</title><path d=\"M23.724 2.829L22.671.724A1.3 1.3 0 0021.5 0h-8a1.3 1.3 0 00-1.171.724l-1.053 2.105A2.635 2.635 0 0011 4v1a2 2 0 00.889 1.662.25.25 0 01.111.207V10.5a2 2 0 002 2h7a2 2 0 002-2V6.869a.25.25 0 01.111-.207A2 2 0 0024 5V4a2.635 2.635 0 00-.276-1.171zM19.25 10.5a.25.25 0 01-.25-.25V9.5a1.5 1.5 0 00-3 0v.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V6.87a.25.25 0 01.111-.208 2.064 2.064 0 00.222-.173.251.251 0 01.334 0 1.994 1.994 0 002.666 0 .251.251 0 01.334 0 1.994 1.994 0 002.666 0 .251.251 0 01.334 0 2.064 2.064 0 00.222.173.248.248 0 01.111.208v3.38a.249.249 0 01-.25.25z\"/><path d=\"M13 14a1 1 0 00-1 1v3.5a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5v-14a.5.5 0 01.5-.5H9a.5.5 0 00.5-.5v-3A.5.5 0 009 0H2.5A2.5 2.5 0 000 2.5v19A2.5 2.5 0 002.5 24h9a2.5 2.5 0 002.5-2.5V15a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});