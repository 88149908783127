define("ember-svg-jar/inlined/smiley-happy-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-happy-1-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M7.707 9.707A6 6 0 019.653 8.4a1 1 0 00-.765-1.847 7.951 7.951 0 00-2.595 1.74 1 1 0 101.414 1.414zM15.112 6.557a1 1 0 10-.765 1.843 6 6 0 011.946 1.3 1 1 0 001.414-1.414 7.951 7.951 0 00-2.595-1.729zM16.492 14.13a1 1 0 00-1.362.378A3.656 3.656 0 0112 16.5a3.656 3.656 0 01-3.13-1.992 1 1 0 00-1.74.984A5.624 5.624 0 0012 18.5a5.624 5.624 0 004.87-3.008 1 1 0 00-.378-1.362z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});