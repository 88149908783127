define("ember-svg-jar/inlined/walkman-heaphones-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>walkman-heaphones-1</title><path d=\"M12 0A12.013 12.013 0 000 12v7.5a2 2 0 004 0V15a2 2 0 00-1.778-1.988.249.249 0 01-.222-.248V12a10 10 0 0120 0v.764a.25.25 0 01-.222.248A2 2 0 0020 15v4.5a2 2 0 004 0V12A12.013 12.013 0 0012 0z\"/><path d=\"M16.5 7h-9A1.5 1.5 0 006 8.5v14A1.5 1.5 0 007.5 24h9a1.5 1.5 0 001.5-1.5v-14A1.5 1.5 0 0016.5 7zm-7.72 6.473a.838.838 0 01-.28-.622v-2.518a.834.834 0 01.833-.833h4.334a1.835 1.835 0 011.833 1.833v6.258a.833.833 0 01-1.388.622zM11.5 20.5a.5.5 0 01-.5.5H9a.5.5 0 010-1h2a.5.5 0 01.5.5z\"/><circle cx=\"12\" cy=\"12.333\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});