define("ember-svg-jar/inlined/messages-people-user-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-user-information</title><circle cx=\"15.25\" cy=\"4.25\" r=\"1\"/><path d=\"M22 0H9a2 2 0 00-2 2v11a2 2 0 002 2h.5v2.5a1 1 0 001.707.707L14.414 15H22a2 2 0 002-2V2a2 2 0 00-2-2zm0 12.75a.25.25 0 01-.25.25H14a1 1 0 00-.707.293L11.5 15.086V14a1 1 0 00-1-1H9.25a.25.25 0 01-.25-.25V2.25A.25.25 0 019.25 2h12.5a.25.25 0 01.25.25zM3.505 13a2.75 2.75 0 10-.01 0A3.5 3.5 0 000 16.5v2a.5.5 0 00.5.5h.821a.249.249 0 01.249.225L2 23.55a.5.5 0 00.5.45h2a.5.5 0 00.5-.45l.433-4.325A.249.249 0 015.678 19H6.5a.5.5 0 00.5-.5v-2A3.5 3.5 0 003.505 13z\"/><path d=\"M14.187 11.375h2.688a.75.75 0 000-1.5h-.625V6.75A.75.75 0 0015.5 6h-1a.75.75 0 000 1.5h.25v2.375h-.563a.75.75 0 100 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});