define("ember-svg-jar/inlined/modern-music-mix-touch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-music-mix-touch</title><path d=\"M11.992 18.1a2.74 2.74 0 011.758-.635.249.249 0 00.25-.25V9.594a.244.244 0 00-.036-.129A3.245 3.245 0 0114.041 6 .041.041 0 0114 5.958V2.249A.25.25 0 0114.25 2h1.185a.249.249 0 00.237-.169A3.256 3.256 0 0116.6.437a.25.25 0 00.068-.276.247.247 0 00-.234-.161H2a2 2 0 00-2 2v16a2 2 0 002 2h8.793a.25.25 0 00.247-.211 2.691 2.691 0 01.952-1.689zM8.25 18a.25.25 0 01-.25-.25v-3.496A.249.249 0 018.25 14h3.5a.251.251 0 01.25.25v3.493a.25.25 0 01-.25.25zM12 11.756a.249.249 0 01-.25.25h-3.5a.25.25 0 01-.25-.25v-3.5A.25.25 0 018.25 8h3.5a.25.25 0 01.25.25zM2 8.249A.25.25 0 012.25 8h3.5a.25.25 0 01.25.25v3.5a.248.248 0 01-.073.176.252.252 0 01-.177.074h-3.5a.25.25 0 01-.25-.25zm10-2.5a.25.25 0 01-.25.25h-3.5a.25.25 0 01-.25-.25v-3.5A.25.25 0 018.25 2h3.5a.25.25 0 01.25.25zM5.75 2a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25h-3.5A.25.25 0 012 5.749V2.5a.5.5 0 01.5-.5zM2 14.25a.249.249 0 01.25-.25h3.5a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25H2.5a.5.5 0 01-.5-.5z\"/><path d=\"M17.5 12a1.5 1.5 0 00-1.5 1.5v6.465a.25.25 0 01-.41.192l-.879-.736a1.25 1.25 0 10-1.922 1.6l2.4 2.893a.251.251 0 00.192.09h8.365a.25.25 0 00.25-.25v-2.422A3.333 3.333 0 0020.667 18H19.25a.25.25 0 01-.25-.25V13.5a1.5 1.5 0 00-1.5-1.5zM16.75 9.542a1.752 1.752 0 001.75-1.75v-4.92c0-.118.083-.22.286-.267l.021-.006 3.368-1.088a.249.249 0 01.166 0 .238.238 0 01.159.232v2.081a.225.225 0 01-.065.156.22.22 0 01-.157.062h-.028A1.75 1.75 0 1024 5.792V1.747A1.74 1.74 0 0023.368.4a1.773 1.773 0 00-1.531-.35l-3.409 1.1A1.752 1.752 0 0017 2.872v2.92a.25.25 0 01-.25.25 1.75 1.75 0 000 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});