define("ember-svg-jar/inlined/smiley-drop-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-drop-alternate</title><path d=\"M8.209 9a1.5 1.5 0 011.375.9 1 1 0 00.917.6.973.973 0 00.4-.084 1 1 0 00.515-1.316A3.5 3.5 0 005 9.1a1 1 0 001.832.8A1.5 1.5 0 018.209 9zM13.5 10.5a1 1 0 00.917-.6 1.5 1.5 0 011.2-.89 1 1 0 00-.228-1.987 3.508 3.508 0 00-2.8 2.077 1 1 0 00.516 1.316.976.976 0 00.395.084zM12 18.5a5.622 5.622 0 004.941-3.25.557.557 0 000-.5.431.431 0 00-.38-.25H7.438a.432.432 0 00-.379.25.557.557 0 000 .5A5.62 5.62 0 0012 18.5z\"/><path d=\"M22.948 10.5a1 1 0 00-.958 1.041c.006.151.01.3.01.458a9.992 9.992 0 11-6.364-9.318 1 1 0 00.728-1.863A12 12 0 1024 12c0-.182 0-.361-.012-.541a1.015 1.015 0 00-1.04-.959z\"/><path d=\"M20.5 9.5A3.5 3.5 0 0024 6c0-1.57-2.049-4.659-2.677-5.568a1.038 1.038 0 00-1.646 0C19.049 1.341 17 4.43 17 6a3.5 3.5 0 003.5 3.5zm-.215-6.309a.249.249 0 01.43 0A9.055 9.055 0 0122 6a1.5 1.5 0 01-3 0 9.055 9.055 0 011.285-2.809z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});