define("ember-svg-jar/inlined/safety-warning-sun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-warning-sun</title><path d=\"M23.9 22.163L12.81.974a.948.948 0 00-1.619 0L.105 22.159a.891.891 0 00-.1.421.9.9 0 00.9.9H23.1a.9.9 0 00.9-.9.885.885 0 00-.1-.417zm-9.775-10.01l.707-.707a.5.5 0 11.707.707l-.707.707a.5.5 0 01-.707-.707zM11.5 10.481a.5.5 0 011 0v1a.5.5 0 11-1 0zm-3.036.965a.5.5 0 01.707 0l.707.707a.5.5 0 11-.707.707l-.707-.707a.5.5 0 010-.707zM7 14.981a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5zm2.878 2.829l-.707.707a.5.5 0 11-.707-.707l.707-.707a.5.5 0 11.707.707zm2.622 1.671a.5.5 0 01-1 0v-1a.5.5 0 011 0zm-.5-2.5a2 2 0 112-2 2 2 0 01-2 2zm3.536 1.536a.5.5 0 01-.707 0l-.707-.707a.5.5 0 11.707-.707l.707.707a.5.5 0 010 .707zm.964-3.036h-1a.5.5 0 010-1h1a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});