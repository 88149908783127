define("ember-svg-jar/inlined/technology-pill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>technology-pill</title><rect x=\"4.625\" y=\"4.627\" width=\"6\" height=\"6\" rx=\"1\" ry=\"1\"/><path d=\"M5.625 3.627a1 1 0 001-1v-1.5a1 1 0 00-2 0v1.5a1 1 0 001 1zM9.625 3.627a1 1 0 001-1v-1.5a1 1 0 00-2 0v1.5a1 1 0 001 1zM10.625 14.127v-1.5a1 1 0 00-2 0v1.5a1 1 0 102 0zM5.625 11.627a1 1 0 00-1 1v1.5a1 1 0 102 0v-1.5a1 1 0 00-1-1zM2.625 8.628h-1.5a1 1 0 000 2h1.5a1 1 0 000-2zM2.625 4.628h-1.5a1 1 0 000 2h1.5a1 1 0 000-2zM12.625 6.628h1.5a1 1 0 000-2h-1.5a1 1 0 000 2zM12.625 10.628h1.5a1 1 0 000-2h-1.5a1 1 0 000 2zM22.43 10.873a4.93 4.93 0 00-6.966 0l-2.292 2.3-2.3 2.292a4.925 4.925 0 006.964 6.966l2.3-2.293 2.293-2.3a4.918 4.918 0 000-6.963zm-5.656 10.5a3.5 3.5 0 01-4.843 0 3.419 3.419 0 010-4.843l1.418-1.412a.5.5 0 01.706 0l4.137 4.136a.5.5 0 010 .707zM20.3 12.4a.5.5 0 01-.653.27 1.814 1.814 0 00-1.985.395.5.5 0 01-.707-.707 2.812 2.812 0 013.074-.611.5.5 0 01.271.653z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});