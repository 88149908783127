define("ember-svg-jar/inlined/smiley-zipped", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-zipped</title><path d=\"M24 12a12.014 12.014 0 10-2.651 7.514.249.249 0 01.138-.086A3.436 3.436 0 0024 16a3.814 3.814 0 00-.25-1.366.251.251 0 01-.011-.141A12.056 12.056 0 0024 12zm-1.5 4a1.851 1.851 0 01-1.65 2 6.369 6.369 0 01-3.233-.95.253.253 0 00-.15-.05H14.75a.25.25 0 00-.25.25V18a1 1 0 01-2 0v-.75a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V18a1 1 0 01-2 0v-.75a.25.25 0 00-.25-.25H6a1 1 0 010-2h.25a.25.25 0 00.25-.25V14a1 1 0 012 0v.75a.25.25 0 00.25.25h1.5a.25.25 0 00.25-.25V14a1 1 0 012 0v.75a.25.25 0 00.25.25h1.5a.25.25 0 00.25-.25V14a1 1 0 012 0v.75a.25.25 0 00.25.25h.717a.253.253 0 00.15-.05A6.369 6.369 0 0120.85 14a1.851 1.851 0 011.65 2zM17 10.5A1.5 1.5 0 1118.5 9a1.5 1.5 0 01-1.5 1.5zM8.5 9A1.5 1.5 0 117 7.5 1.5 1.5 0 018.5 9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});