define("ember-svg-jar/inlined/smiley-crying-rainbow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-crying-rainbow</title><path d=\"M21.659 19.1A11.995 11.995 0 100 12a11.927 11.927 0 002.341 7.1A2.494 2.494 0 003 24h6a2.478 2.478 0 00.945-.188A12.1 12.1 0 0012 24a12.1 12.1 0 002.055-.188A2.478 2.478 0 0015 24h6a2.494 2.494 0 00.659-4.9zm-7.738-9.479A3.26 3.26 0 0116.8 8a3.254 3.254 0 012.87 1.623 1 1 0 11-1.749.969A1.242 1.242 0 0016.8 10a1.275 1.275 0 00-1.127.594 1 1 0 01-.875.513.989.989 0 01-.485-.126 1 1 0 01-.392-1.36zm-9.295 0A3.26 3.26 0 017.5 8a3.257 3.257 0 012.872 1.622 1 1 0 01-1.75.971A1.241 1.241 0 007.5 10a1.248 1.248 0 00-1.127.594 1 1 0 01-.875.513.989.989 0 01-.485-.126 1 1 0 01-.387-1.36zM8.348 22.5h-4.7a1.174 1.174 0 01-1.174-1.174 1.243 1.243 0 011.174-1.252 1.136 1.136 0 01.352.055.194.194 0 00.237-.1 1.981 1.981 0 01.59-.727v-4.628A1.174 1.174 0 016 13.5a1.174 1.174 0 011.174 1.174V19.2a.2.2 0 00.07.151 1.968 1.968 0 01.52.671.2.2 0 00.237.1 1.136 1.136 0 01.347-.055 1.243 1.243 0 011.174 1.252A1.174 1.174 0 018.348 22.5zM10.5 18v-2a1.5 1.5 0 013 0v2a1.5 1.5 0 01-3 0zm9.894 4.5h-4.788a1.2 1.2 0 01-1.2-1.2 1.22 1.22 0 011.2-1.223 1.122 1.122 0 01.354.057.2.2 0 00.241-.105 2.022 2.022 0 01.53-.684.2.2 0 00.072-.154V14.7A1.2 1.2 0 0118 13.5a1.2 1.2 0 011.2 1.2v4.5a.2.2 0 00.072.154 2.022 2.022 0 01.53.684.2.2 0 00.241.1 1.151 1.151 0 01.354-.056 1.22 1.22 0 011.2 1.223 1.2 1.2 0 01-1.203 1.195z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});