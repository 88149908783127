define("ember-svg-jar/inlined/messages-bubble-sync-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-sync-alternate</title><path d=\"M9 17.657a.25.25 0 00-.191-.25 5.069 5.069 0 01-.212-.055 1 1 0 00-.661.038l-3.644 1.52a.25.25 0 01-.331-.316l.851-2.339a1 1 0 00-.276-1.089A7.043 7.043 0 012 9.928c0-4.358 4.486-7.9 10-7.9 5.343 0 9.709 3.331 9.976 7.5a.251.251 0 00.135.206 9.125 9.125 0 011.464.945.25.25 0 00.4-.178 7.72 7.72 0 00.021-.571c0-5.461-5.383-9.9-12-9.9S0 4.467 0 9.928a8.877 8.877 0 002.708 6.26l-1.6 4.4a1 1 0 001.325 1.265l5.959-2.483c.141.037.283.071.426.1a.25.25 0 00.226-.062.254.254 0 00.075-.223A8.249 8.249 0 019 17.657z\"/><path d=\"M13.009 21.854a6.088 6.088 0 0010.383-2.007A1 1 0 0021.5 19.2a4.093 4.093 0 01-7.074 1.238l1.886-1.885a.5.5 0 00-.354-.853H11.5a.5.5 0 00-.5.5v4.457a.5.5 0 00.854.353zM23.146 11.857l-1.21 1.21a6.1 6.1 0 00-10.369 2.038 1 1 0 001.893.648 4.094 4.094 0 017.054-1.264l-1.825 1.825a.5.5 0 00.354.853H23.5a.5.5 0 00.5-.5V12.21a.5.5 0 00-.854-.353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});