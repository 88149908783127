define("ember-svg-jar/inlined/shark-swordfish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shark-swordfish</title><path d=\"M8.78 2.832A5.758 5.758 0 003.756.006a.5.5 0 00-.479.642A12.81 12.81 0 013.4 5.005a17.033 17.033 0 015.38-2.173zM23.91 1.555a1 1 0 00-1.218-.719L8.732 4.394C3.686 5.6.058 8.926.058 12.844s3.6 7.223 8.618 8.437c.254 1.375 2.443 2.713 3.231 2.713a.548.548 0 00.1-.01c.675-.139-.581-1.465-.558-3.279.024-1.852 1.64-4.07.628-4.07S9.9 18.7 9.093 18.74c-4.323.169-5.352-6.069-.59-7.7 5.014-1.714 6.23-2.49 6.94-4.618a7.548 7.548 0 00.306-1.406.5.5 0 01.371-.416l7.072-1.824a1 1 0 00.718-1.221zM12.338 7.262a1.116 1.116 0 111.116-1.116 1.116 1.116 0 01-1.116 1.116z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});