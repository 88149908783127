define("ember-svg-jar/inlined/information-desk-question-help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>information-desk-question-help</title><path d=\"M17.864.51A6.506 6.506 0 0011 6.918a2.049 2.049 0 001.616 2.046A2 2 0 0015 7.023a2.469 2.469 0 011.415-2.293 2.517 2.517 0 013.492 2.959A2.628 2.628 0 0117.4 9.5a2 2 0 00-1.9 2v4.887a2.08 2.08 0 001.662 2.085A2 2 0 0019.5 16.5v-2.969a.5.5 0 01.327-.461A6.5 6.5 0 0017.864.51z\"/><circle cx=\"17.5\" cy=\"21.5\" r=\"2\"/><path d=\"M11.383 15.036A2 2 0 009 16.977a2.469 2.469 0 01-1.415 2.293 2.517 2.517 0 01-3.492-2.959A2.628 2.628 0 016.6 14.5a2 2 0 001.9-2V7.613a2.08 2.08 0 00-1.662-2.085A2 2 0 004.5 7.5v2.969a.5.5 0 01-.327.461A6.5 6.5 0 1013 17.082a2.049 2.049 0 00-1.617-2.046z\"/><circle cx=\"6.5\" cy=\"2.5\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});