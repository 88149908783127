define("ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/sanitization-utils", ["exports", "ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/array-utils"], function (_exports, _arrayUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reduceAttributes = reduceAttributes;
  _exports.sanitizeHref = sanitizeHref;
  const PROTOCOL_REGEXP = /.+:/i;
  const badProtocols = ['javascript',
  // jshint ignore:line
  'vbscript' // jshint ignore:line
  ];
  function getProtocol(url) {
    let matches = url && url.match(PROTOCOL_REGEXP);
    let protocol = matches && matches[0] && matches[0].split(':')[0] || '';
    return protocol;
  }
  function sanitizeHref(url) {
    let protocol = getProtocol(url).toLowerCase().replace(/ /g, '');
    if ((0, _arrayUtils.includes)(badProtocols, protocol)) {
      return `unsafe:${url}`;
    }
    return url;
  }

  /**
   * @param attributes array
   * @return obj with normalized attribute names (lowercased)
   */
  function reduceAttributes(attributes) {
    let obj = {};
    for (let i = 0; i < attributes.length; i += 2) {
      let key = attributes[i];
      let val = attributes[i + 1];
      obj[key.toLowerCase()] = val;
    }
    return obj;
  }
});