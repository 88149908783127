define("ember-svg-jar/inlined/button-refresh-arrows", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-refresh-arrows</title><path d=\"M6.177 6.167a8.233 8.233 0 018.351-2.027 1.249 1.249 0 10.76-2.38A10.751 10.751 0 002.046 16.033a.248.248 0 01-.094.3l-1.4.922A1 1 0 00.9 19.071l4.407.908a.99.99 0 00.2.021 1 1 0 00.979-.8l.914-4.406a1 1 0 00-1.529-1.037l-1.339.881a.25.25 0 01-.376-.133 8.269 8.269 0 012.021-8.338zM23.883 5.832a1 1 0 00-.763-.807l-4.388-1a1 1 0 00-1.2.752l-1 4.387a1 1 0 001.507 1.069l1.443-.906A.247.247 0 0119.7 9.3a.252.252 0 01.153.159A8.249 8.249 0 019.568 19.883a1.25 1.25 0 10-.737 2.388A10.75 10.75 0 0021.985 8a.248.248 0 01.1-.3l1.346-.846a1 1 0 00.452-1.022z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});