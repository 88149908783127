define("ember-svg-jar/inlined/professions-woman-nurse-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-woman-nurse-2</title><path d=\"M16.125 20.756h-.75v-.75a.75.75 0 00-1.5 0v.75h-.75a.75.75 0 000 1.5h.75v.75a.75.75 0 001.5 0v-.75h.75a.75.75 0 000-1.5zM20.643 18.77l-2.905-.97a.75.75 0 10-.476 1.422l2.88.966a3.265 3.265 0 012.108 3.043.75.75 0 001.5 0 4.778 4.778 0 00-3.107-4.461zM6.313 17.8l-2.98 1A4.771 4.771 0 00.25 23.244a.75.75 0 001.5 0 3.259 3.259 0 012.084-3.035l2.956-.992a.75.75 0 10-.477-1.417zM10.521 14.143a3.189 3.189 0 001.5.363h.006a3.154 3.154 0 001.5-.364.75.75 0 00-.734-1.308 1.554 1.554 0 01-.764.172 1.629 1.629 0 01-.772-.174.751.751 0 00-.731 1.311zM11.129 10.542a.751.751 0 00-.039-1.06 2.02 2.02 0 00-2.6 0 .75.75 0 001.022 1.1.557.557 0 01.557 0 .751.751 0 001.06-.04zM14.489 10.581a.75.75 0 101.022-1.1 2.02 2.02 0 00-2.6 0 .75.75 0 001.022 1.1.557.557 0 01.556 0z\"/><path d=\"M15.852 16.149a2.2 2.2 0 001.963 1.008 5.117 5.117 0 003.569-1.672.5.5 0 00-.231-.8c-1.227-.394-1.879-1.751-2.224-3a3.138 3.138 0 00.321-1.3 1.886 1.886 0 00-.936-1.81.75.75 0 00-.669 1.342.616.616 0 01.105.439 1.278 1.278 0 01-.559 1.1 1.751 1.751 0 00-1 1.306c-.377 2.248-3.175 3.738-4.208 3.738s-3.83-1.49-4.207-3.737a1.747 1.747 0 00-1-1.307 1.275 1.275 0 01-.559-1.095.628.628 0 01.1-.44.75.75 0 00-.669-1.342A1.886 1.886 0 004.71 10.4a3.15 3.15 0 00.3 1.241c-.341 1.26-.994 2.651-2.239 3.051a.5.5 0 00-.231.8 5.116 5.116 0 003.56 1.665 2.2 2.2 0 001.977-1.032 6.645 6.645 0 003.9 1.881 6.627 6.627 0 003.875-1.857zM6.123 7.5a35.668 35.668 0 0111.754 0 .75.75 0 00.873-.74v-4.5a.752.752 0 00-.3-.6A11.21 11.21 0 0012 .006h-.006a11.211 11.211 0 00-6.444 1.65.752.752 0 00-.3.6v4.5a.75.75 0 00.873.74zM12 1.506a11.281 11.281 0 014.971 1.021.5.5 0 01.279.449v2.333a.5.5 0 01-.564.5 37.116 37.116 0 00-9.372 0 .5.5 0 01-.564-.5V2.974a.5.5 0 01.278-.448A11.293 11.293 0 0112 1.506z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});