define("ember-svg-jar/inlined/optimization-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>optimization-graph</title><path d=\"M24 10.942a1 1 0 00-.876-.992l-1.734-.217a.25.25 0 01-.21-.184 9.4 9.4 0 00-.959-2.3.251.251 0 01.019-.279l1.075-1.383a1 1 0 00-.082-1.321l-1.5-1.5a1 1 0 00-1.321-.082L17.033 3.76a.25.25 0 01-.279.019 9.392 9.392 0 00-2.3-.958.25.25 0 01-.184-.211L14.051.876A1 1 0 0013.059 0h-2.118a1 1 0 00-.992.876L9.732 2.61a.25.25 0 01-.184.211 9.392 9.392 0 00-2.3.958.25.25 0 01-.279-.019L5.584 2.684a1 1 0 00-1.32.083l-1.5 1.5a1 1 0 00-.082 1.322L3.76 6.968a.251.251 0 01.019.279 9.4 9.4 0 00-.959 2.3.25.25 0 01-.21.184L.877 9.95a1 1 0 00-.877.992v2.117a1 1 0 00.876.992l1.734.217a.248.248 0 01.21.183 9.4 9.4 0 00.959 2.3.251.251 0 01-.019.279l-1.075 1.386a1 1 0 00.082 1.321l1.5 1.5a1 1 0 001.321.082l1.383-1.075a.25.25 0 01.279-.019 9.415 9.415 0 002.3.958.249.249 0 01.184.21l.217 1.735a1 1 0 00.992.875h2.118a1 1 0 00.992-.875l.217-1.735a.249.249 0 01.184-.21 9.415 9.415 0 002.3-.958.25.25 0 01.279.019l1.383 1.076a1 1 0 001.32-.083l1.5-1.5a1 1 0 00.082-1.322l-1.078-1.382a.251.251 0 01-.019-.279 9.4 9.4 0 00.959-2.3.248.248 0 01.21-.183l1.734-.217a1 1 0 00.876-.995zm-12 8.031a7 7 0 117-7 7 7 0 01-7 7z\"/><path d=\"M13 13h1.5a.464.464 0 00.5-.416V8.417A.464.464 0 0014.5 8H13a.464.464 0 00-.5.417v4.167A.464.464 0 0013 13z\"/><rect x=\"8.5\" y=\"10\" width=\"2.5\" height=\"3\" rx=\".5\" ry=\".5\"/><path d=\"M15 14H8.5a1 1 0 000 2H15a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});