define("ember-svg-jar/inlined/climbing-sports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>climbing-sports</title><circle cx=\"10.144\" cy=\"4.492\" r=\"2.5\"/><path d=\"M23.064.019a1 1 0 00-1.17.792c-.124.636-.24 1.268-.353 1.892-1.185 6.5-1.99 10.6-8.4 10.8h-2.519a.5.5 0 010-1h2.25a.25.25 0 00.25-.25v-.983a6.583 6.583 0 005-6.425V2.992a1.5 1.5 0 00-3 0v1.85a3.651 3.651 0 01-3.65 3.65H5.548A2.488 2.488 0 013.6 7.555l-.8-1A1.5 1.5 0 10.453 8.429l.8 1a5.479 5.479 0 004.3 2.063h1.572V14l-5.248 7.66a1.5 1.5 0 102.5 1.664l3.461-4.933A2 2 0 019.5 17.5h4.127a1.5 1.5 0 011.5 1.5v1a1.5 1.5 0 003 0v-1A4.487 4.487 0 0016 15.191c5.531-1.3 6.5-6.578 7.514-12.129.112-.617.226-1.242.347-1.873a1 1 0 00-.797-1.17z\"/><path d=\"M12.375 18.984c-.553 0-1 .2-1 .758V23a1 1 0 002 0v-3.258c0-.553-.447-.758-1-.758z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});