define("ember-svg-jar/inlined/compass-north", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>compass-north</title><path d=\"M7.41 8.958l.777-.358a9.1 9.1 0 017.626 0l.777.358a.455.455 0 00.582-.644l-4.78-8.09a.455.455 0 00-.784 0l-4.78 8.09a.455.455 0 00.582.644zM12 9.5a7.25 7.25 0 107.25 7.25A7.258 7.258 0 0012 9.5zm2.5 10.75a.75.75 0 01-.578.73.721.721 0 01-.172.02.75.75 0 01-.671-.415l-1.605-3.211a.25.25 0 00-.474.112v2.764a.75.75 0 01-1.5 0v-7a.75.75 0 011.421-.335l1.605 3.211a.25.25 0 00.474-.112V13.25a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});