define("ember-svg-jar/inlined/bat-fly", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bat-fly</title><path d=\"M21.3 2.6a.517.517 0 00-.449-.077.5.5 0 00-.326.319C20.513 2.881 19.2 6.7 15.9 6.983l-.909-4.092a.5.5 0 00-.348-.372.508.508 0 00-.494.127L12.793 4h-1.586L9.854 2.646a.51.51 0 00-.494-.127.5.5 0 00-.348.372L8.1 6.982C4.81 6.694 3.487 2.88 3.474 2.84a.5.5 0 00-.326-.318.507.507 0 00-.448.078C2.61 2.667.5 4.321.5 10.5c0 6.136 3.018 9.224 3.146 9.353a.5.5 0 00.844-.453c0-.022-.4-2.194.787-2.986 1.089-.726 2.275.818 2.324.884a.5.5 0 00.334.2.494.494 0 00.372-.1 1.989 1.989 0 011.535-.42c.827.276 1.47 2.556 1.662 4.088a.5.5 0 00.992 0c.192-1.532.835-3.812 1.662-4.088a1.971 1.971 0 011.536.421.514.514 0 00.373.1.5.5 0 00.333-.2c.012-.014 1.231-1.611 2.323-.883 1.2.8.791 2.964.787 2.986a.5.5 0 00.844.451c.128-.129 3.146-3.217 3.146-9.353 0-6.179-2.11-7.833-2.2-7.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});