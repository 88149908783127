define("ember-svg-jar/inlined/medical-specialty-ear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-specialty-ear</title><path d=\"M16.912 5.89a1 1 0 00.436-1.345 8.29 8.29 0 00-6.5-4.511 8.166 8.166 0 00-7.9 4.246 1 1 0 001.752.959 6.153 6.153 0 015.958-3.213 6.27 6.27 0 014.909 3.427 1 1 0 001.346.437zM14.467 14.223a1 1 0 00-1.365-.369 5.47 5.47 0 00-2.746 4.525 3.8 3.8 0 01-1.568 2.932 3.6 3.6 0 01-3.15.543A3.785 3.785 0 013 18.624a1 1 0 00-1.99.2 5.746 5.746 0 004.09 4.955A5.81 5.81 0 006.667 24a5.615 5.615 0 003.292-1.064 5.8 5.8 0 002.4-4.464 3.435 3.435 0 011.741-2.884 1 1 0 00.367-1.365z\"/><path d=\"M5.465 8.909a.921.921 0 00.18.017 1 1 0 00.983-.82 2.507 2.507 0 013.041-2.1 2.358 2.358 0 011.567 1.058 1 1 0 001.652-1.129 4.354 4.354 0 00-2.942-1.909 4.457 4.457 0 00-5.286 3.72 1 1 0 00.805 1.163zM23 21.5a1.5 1.5 0 00-1.5-1.5h-.25a.5.5 0 01-.5-.5v-4.25a.751.751 0 00-.75-.75h-1a.751.751 0 00-.75.75v4.25a.5.5 0 01-.5.5h-.25a1.5 1.5 0 00-1.5 1.5v2a.5.5 0 00.5.5h6a.5.5 0 00.5-.5zM11 9.989v1.021a.97.97 0 00.872.987l3.021.662a.5.5 0 00.607-.488V8.83a.5.5 0 00-.608-.488l-2.933.648a1 1 0 00-.959.999zM23 9a1 1 0 00-1-1h-4.5a.5.5 0 00-.5.5v4a.5.5 0 00.5.5H22a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});