define("ember-svg-jar/inlined/police-hat-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-hat-5</title><path d=\"M5.4 10.588a.5.5 0 00.211.348 54.028 54.028 0 015.37 4.35.5.5 0 00.651.017 54.3 54.3 0 016.384-4.5.5.5 0 00.126-.749L12.7 3.521a.947.947 0 00-1.4 0L5.518 10.2a.5.5 0 00-.118.388zM3.949 9.836a.5.5 0 00.644-.1l2.069-2.389a.5.5 0 00-.431-.825c-1.516.162-2.84.391-4.471.675a1 1 0 00-.808.791.157.157 0 00.079.17 40.808 40.808 0 012.918 1.678zM19.131 9.68a.5.5 0 00.626.117 56.644 56.644 0 013.235-1.657.113.113 0 00.063-.123 1 1 0 00-.815-.82c-1.692-.297-3.052-.53-4.64-.697a.5.5 0 00-.435.818zM9.9 16.551a.5.5 0 00-.167-.373 44.6 44.6 0 00-8.269-6.073.5.5 0 00-.743.39L0 18.32a1 1 0 00.754 1.062 46.848 46.848 0 005.3 1.006 86.774 86.774 0 013.671-3.464.5.5 0 00.175-.373zM23.278 10.422a.5.5 0 00-.72-.4A55.055 55.055 0 007.989 20.589c1.337.119 2.673.2 4.011.2a45.986 45.986 0 0011.242-1.407A1 1 0 0024 18.321z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});