define("ember-svg-jar/inlined/responsive-design-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>responsive-design-image</title><path d=\"M13.482 13.75a.556.556 0 00-.466.2L11.3 16.464a.5.5 0 01-.726.109l-1.623-1.306a.641.641 0 00-.423-.119.566.566 0 00-.39.161l-1.89 2.107a.5.5 0 00.372.834h9a.5.5 0 00.43-.756l-2.115-3.534a.537.537 0 00-.453-.21z\"/><circle cx=\"6\" cy=\"12.25\" r=\"1.5\"/><path d=\"M0 19.25a3 3 0 003 3h16a3 3 0 003-3v-4.5a1 1 0 00-2 0v4.5a1 1 0 01-1 1H3a1 1 0 01-1-1v-10a1 1 0 011-1h12a.5.5 0 00.5-.5v-3.5a.5.5 0 00-.5-.5H3a3 3 0 00-3 3zm9.654-13.5a.966.966 0 011.692 0 .969.969 0 01.154.5 1 1 0 01-2 0 .969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0 .969.969 0 01.154.5 1 1 0 01-2 0 .969.969 0 01.154-.5zm-3.562.092A1 1 0 013.5 5.25a.985.985 0 01.846.5.969.969 0 01.154.5 1 1 0 01-2 0 .979.979 0 01.092-.408z\"/><path d=\"M19 1.75a2 2 0 00-2 2v6.5a2 2 0 002 2h3a2 2 0 002-2v-6.5a2 2 0 00-2-2zm3 6.75a.25.25 0 01-.25.25h-2.5A.25.25 0 0119 8.5V4a.25.25 0 01.25-.25h2.5A.25.25 0 0122 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});