define("ember-svg-jar/inlined/common-file-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-lock</title><path d=\"M24 4.415A2 2 0 0023.414 3L21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.163a.241.241 0 00.188.234 4.892 4.892 0 011.423.612A.249.249 0 008 9.805V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-9.25a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25H22a2 2 0 002-2z\"/><path d=\"M8.752 15.529a.3.3 0 01-.252-.3v-.98a3.5 3.5 0 00-7 0v.963a.323.323 0 01-.265.318A1.5 1.5 0 000 17v5.5A1.5 1.5 0 001.5 24h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.248-1.471zM5 21.25a1 1 0 111-1 1 1 0 01-1 1zm1.5-6a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-1a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});