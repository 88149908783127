define("ember-svg-jar/inlined/hammer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hammer</title><path d=\"M23.051 8.569l-6.237-6.237a.5.5 0 00-.619-.07 4.516 4.516 0 01-5.493-.9.516.516 0 00-.707 0L8.776 2.577a1.5 1.5 0 000 2.121l9.073 9.073a1.5 1.5 0 002.121 0l3.081-3.08a1.5 1.5 0 000-2.122zM11.023 9.277a.5.5 0 00-.707 0L1.243 18.35a2.485 2.485 0 00-.733 1.768 2.767 2.767 0 002.668 2.668 2.481 2.481 0 001.768-.733l9.074-9.073a.5.5 0 000-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});