define("ember-svg-jar/inlined/social-video-youtube", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-video-youtube</title><path d=\"M5.9 5.12v4.26a.75.75 0 001.5 0V5.12l2.85-3.8A.75.75 0 109 .42l-2.4 3.2L4.25.42a.75.75 0 10-1.2.9zM9.45 4.88v3a2.25 2.25 0 004.5 0v-3a2.25 2.25 0 10-4.5 0zm1.5 0a.75.75 0 111.5 0v3a.75.75 0 01-1.5 0zM15.81 9.32a2.73 2.73 0 001.94.8 2.72 2.72 0 001.36-.38.74.74 0 00.64.38.75.75 0 00.75-.75v-6a.75.75 0 00-1.5 0v4a1.25 1.25 0 01-1.25 1.25 1.25 1.25 0 01-1.25-1.25v-4a.75.75 0 10-1.5 0v4a2.73 2.73 0 00.81 1.95zM18.5 17.37a.5.5 0 00-1 0v.5h1zM14 16.87a.5.5 0 00-.5.5v2a.5.5 0 001 0v-2a.5.5 0 00-.5-.5z\"/><path d=\"M19 11.87H5a4.5 4.5 0 00-4.5 4.5v3a4.5 4.5 0 004.5 4.5h14a4.5 4.5 0 004.5-4.5v-3a4.5 4.5 0 00-4.5-4.5zm-11 4H6.5v4.48a.5.5 0 01-1 0v-4.48H4a.5.5 0 010-1h4a.5.5 0 110 1zm3.48 3.5v1a.5.5 0 01-.86.35 1.48 1.48 0 01-.64.15 1.5 1.5 0 01-1.5-1.5v-3a.5.5 0 011 0v3a.5.5 0 001 0v-3a.5.5 0 011 0zm4 0a1.5 1.5 0 01-1.5 1.5 1.48 1.48 0 01-.66-.16.5.5 0 01-.84-.36v-6a.5.5 0 011 0V16a1.48 1.48 0 01.5-.09 1.5 1.5 0 011.5 1.5zm2.59 1.5a1.69 1.69 0 01-1.59-1.62v-1.88a1.5 1.5 0 013 0v1a.5.5 0 01-.5.5H17.5v.38a.7.7 0 00.59.62c.23 0 .59-.1.59-.37a.5.5 0 011 0 1.46 1.46 0 01-1.59 1.37z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});