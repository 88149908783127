define("ember-svg-jar/inlined/office-file-module-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-file-module-edit</title><rect x=\"4.5\" y=\"12.498\" width=\"4\" height=\"4\" rx=\"1\" ry=\"1\"/><rect x=\"10.5\" y=\"12.498\" width=\"4\" height=\"4\" rx=\"1\" ry=\"1\"/><rect x=\"7.5\" y=\"6.498\" width=\"4\" height=\"4\" rx=\"1\" ry=\"1\"/><path d=\"M18 7.248v8.327a.25.25 0 00.424.18 207.93 207.93 0 011.5-1.459.233.233 0 00.076-.177V6.412a1 1 0 00-.293-.707L14.293.291A1 1 0 0013.586 0H2a2 2 0 00-2 2v20a2 2 0 002 2h11.312a.251.251 0 00.248-.248c.012-.39.1-1.081.159-1.468a.249.249 0 00-.248-.284H2.5a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.248z\"/><path d=\"M23.435 15.558a1.913 1.913 0 00-2.7 0l-5.087 5.087a.493.493 0 00-.136.255l-.5 2.5a.5.5 0 00.49.6.473.473 0 00.1-.01l2.5-.5a.5.5 0 00.256-.136l5.086-5.087a1.91 1.91 0 00-.005-2.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});