define("ember-svg-jar/inlined/road-sign-banned", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-banned</title><path d=\"M20.96 19.547a.253.253 0 00.19.073.25.25 0 00.181-.093A11.987 11.987 0 004.473 2.668a.251.251 0 00-.019.372zM3.04 4.454a.249.249 0 00-.19-.073.253.253 0 00-.182.092 11.987 11.987 0 0016.858 16.859.247.247 0 00.093-.181.249.249 0 00-.073-.19z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});