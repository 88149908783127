define("ember-svg-jar/inlined/single-man-actions-graduate.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-graduate</title><path d=\"M7.087 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.742a.5.5 0 01.742-.3A6.952 6.952 0 008.547 5a6.214 6.214 0 00.929-.07.5.5 0 01.569.565A3 3 0 014.087 5a2.839 2.839 0 01.1-.742zM10.624 15.662l2.628-1.126a.5.5 0 00.215-.742A7.52 7.52 0 007.293 10.5a7.544 7.544 0 00-7.185 5.358.5.5 0 00.479.642h8.84a.5.5 0 00.4-.2 1.954 1.954 0 01.797-.638zM23.6 17.111l-6-2.383a.5.5 0 00-.369 0l-6 2.383a.5.5 0 00-.315.474v3.38a.75.75 0 001.5 0v-2.153a.25.25 0 01.333-.236l4.5 1.589a.509.509 0 00.333 0l6-2.119a.5.5 0 00.018-.935z\"/><path d=\"M21.008 22.917a.5.5 0 00.333-.471V20.3a.5.5 0 00-.666-.471L17.5 20.951a.252.252 0 01-.167 0l-3.183-1.122a.5.5 0 00-.666.471v2.146a.5.5 0 00.333.471l2.573.908a3.075 3.075 0 002.045 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});