define("ember-svg-jar/inlined/meeting-monitor-webcam", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-monitor-webcam</title><path d=\"M24 1.749a1.5 1.5 0 00-1.5-1.5h-21a1.5 1.5 0 00-1.5 1.5v16a1.5 1.5 0 001.5 1.5h8.2a.25.25 0 01.249.275c-.167 1.7-.788 2.023-2.4 3.438a.5.5 0 00.409.787h8.079a.5.5 0 00.41-.787c-1.611-1.415-2.231-1.738-2.4-3.438a.25.25 0 01.063-.192.254.254 0 01.186-.083h8.2a1.5 1.5 0 001.5-1.5zm-2 13a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M13.737 10.735a1.607 1.607 0 01-.047-.889 4.048 4.048 0 001.048-3.071 2.752 2.752 0 10-5.476 0 4 4 0 001.017 3.03 1.92 1.92 0 01.039.895c-2.136.791-3.411 1.574-3.787 2.326a.5.5 0 00.448.723h10.042a.5.5 0 00.448-.723c-.376-.752-1.651-1.535-3.732-2.291z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});