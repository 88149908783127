define("ember-svg-jar/inlined/audio-book-volume-medium", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-book-volume-medium</title><path d=\"M9.688 8.343L8 9.693H7a1 1 0 00-1 1v2a1 1 0 001 1h1l1.688 1.35a.5.5 0 00.812-.39v-5.92a.5.5 0 00-.812-.39zM13.3 9.4a.75.75 0 00-1.061 1.061 1.749 1.749 0 010 2.475.75.75 0 001.061 1.06 3.25 3.25 0 000-4.6z\"/><path d=\"M14.47 7.627a.75.75 0 000 1.061 4.257 4.257 0 010 6.011.75.75 0 001.06 1.06 5.759 5.759 0 000-8.132.749.749 0 00-1.06 0z\"/><path d=\"M23.385.555A2.031 2.031 0 0021.943 0c-4.931.186-7.813 1.656-9.338 2.815a1 1 0 01-1.21 0C9.871 1.659 6.989.188 2.057 0A2.031 2.031 0 00.615.555 2.024 2.024 0 000 2v16.217a2 2 0 001.945 2c3.235.117 7.149.929 9.223 3.4a1.082 1.082 0 001.664 0c2.075-2.467 5.988-3.279 9.223-3.4a2 2 0 001.945-2V2a2.024 2.024 0 00-.615-1.445zm-1.4 17.184a.5.5 0 01-.473.5c-4.667.253-7.424 1.67-8.9 2.8a1 1 0 01-1.211 0c-1.479-1.124-4.236-2.542-8.9-2.8a.5.5 0 01-.473-.5L2 2.527a.5.5 0 01.53-.5c4.953.3 7.347 2.032 8.227 2.87.5.476.613.889 1.242.889s.743-.413 1.243-.889c.879-.838 3.273-2.574 8.226-2.87a.494.494 0 01.373.135.5.5 0 01.159.365z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});