define("ember-svg-jar/inlined/diving-diver", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diving-diver</title><path d=\"M24 3.927a.494.494 0 00-.185-.387.506.506 0 00-.417-.1c-2.117.448-3.477-1.665-3.534-1.755A.525.525 0 0019 1.7a3.869 3.869 0 01-3.187 1.8 3.3 3.3 0 01-2.862-1.771.523.523 0 00-.86-.078A4.782 4.782 0 018.574 3.5a4.135 4.135 0 01-3.191-1.818.525.525 0 00-.821-.044C4.544 1.661 2.725 3.934.6 3.479a.51.51 0 00-.418.1.5.5 0 00-.182.389v18.071a.5.5 0 00.5.5h23a.5.5 0 00.5-.5zM9.182 13.1a1.507 1.507 0 001.06.44h4.05a4.535 4.535 0 012.012.474l5.367 2.686a1.5 1.5 0 11-1.342 2.683L14.963 16.7a1.5 1.5 0 00-.671-.158h-4.05a4.528 4.528 0 01-3.181-1.322l-2.284-2.283a.251.251 0 01.1-.414l2.651-.884a.25.25 0 01.256.06zM10 11.289v-1.5a.5.5 0 01.5-.5h3.25a1.25 1.25 0 010 2.5H10.5a.5.5 0 01-.5-.5zm6.25-.25a2.25 2.25 0 112.25 2.25 2.253 2.253 0 01-2.25-2.25zm-12.4.772a.25.25 0 01-.256-.06l-1.36-1.36a.5.5 0 01.354-.854h2.825a.5.5 0 01.354.146l.829.829a.252.252 0 01.066.235.249.249 0 01-.164.179z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});