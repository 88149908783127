define("ember-svg-jar/inlined/computer-chip-core-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>computer-chip-core-1</title><rect x=\"9\" y=\"4.501\" width=\"6\" height=\"15\" rx=\".25\" ry=\".25\"/><path d=\"M21 9.5a1 1 0 100-2h-1.75a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H21a1 1 0 000-2h-1.75a.25.25 0 01-.25-.25V1.5A1.5 1.5 0 0017.5 0h-11A1.5 1.5 0 005 1.5v2.25a.25.25 0 01-.25.25H3a1 1 0 000 2h1.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H3a1 1 0 100 2h1.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H3a1 1 0 000 2h1.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H3a1 1 0 100 2h1.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H3a1 1 0 000 2h1.75a.25.25 0 01.25.25v2.25A1.5 1.5 0 006.5 24h11a1.5 1.5 0 001.5-1.5v-2.25a.25.25 0 01.25-.25H21a1 1 0 000-2h-1.75a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H21a1 1 0 000-2h-1.75a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H21a1 1 0 000-2h-1.75a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25zm-4.5-6v17a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5v-17A.5.5 0 018 3h8a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});