define("ember-svg-jar/inlined/cash-bulb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-bulb</title><path d=\"M11.968 23.979h.012a1 1 0 001-.989l.008-.762a.251.251 0 01.25-.249h.783a1 1 0 000-2h-4a1 1 0 000 2h.717a.253.253 0 01.25.253l-.008.738a1 1 0 00.988 1.009zM8.352 17.055a.251.251 0 01.169.236v1.23a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-1.229a.25.25 0 01.169-.236 8.311 8.311 0 005.57-8.262A9.036 9.036 0 0012 .021a9.036 9.036 0 00-9.26 8.773 8.433 8.433 0 005.612 8.261zm4.18-6.653l-1.949-.974a2.853 2.853 0 01.261-5.22.251.251 0 00.156-.234v-.453a1 1 0 112 0v.25a.25.25 0 00.25.25H14a1 1 0 010 2h-2.141a.854.854 0 00-.383 1.618l1.951.974a2.856 2.856 0 01-.262 5.222.249.249 0 00-.161.233v.453a1 1 0 01-2 0v-.25a.25.25 0 00-.25-.25H10a1 1 0 110-2h2.147a.855.855 0 00.381-1.619z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});