define("ember-svg-jar/inlined/shipment-approve-smartphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-approve-smartphone</title><path d=\"M.843 4.276a.249.249 0 00-.343.232v7.441a1 1 0 00.6.916l6.55 2.866A.25.25 0 008 15.5V7.279a.25.25 0 00-.158-.233zM15.228 3.328a.25.25 0 00-.016-.465L8.387.454a1 1 0 00-.755.037h-.007L1.676 3.072a.25.25 0 00.008.462L8.3 6.152a.5.5 0 00.382 0zM11 11.1a3 3 0 013-3h2.25a.25.25 0 00.25-.25v-3.6a.251.251 0 00-.349-.229l-7 3.014A.252.252 0 009 7.263v8.128a.25.25 0 00.367.221l1.5-.795A.251.251 0 0011 14.6z\"/><path d=\"M17.308 11.132a1 1 0 000-2H14.25a2.154 2.154 0 00-1.529.647A2.534 2.534 0 0012 11.568l.007 10.035c0 .018.05.267.071.357a.359.359 0 00.014.043 2.221 2.221 0 002.165 1.6h6.493A2.2 2.2 0 0023 21.456c0-1.922-.008-.008 0-3.851a1 1 0 00-1-1 1 1 0 00-1 1v1.21a.5.5 0 01-.5.5h-5.995a.5.5 0 01-.5-.5v-7.173a.5.5 0 01.5-.5z\"/><path d=\"M18.592 14.388a.254.254 0 01-.182.093.246.246 0 01-.19-.073L17.207 13.4a1 1 0 00-1.414 1.414l2 2a1 1 0 001.489-.083l4-5a1 1 0 00-1.563-1.249z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});