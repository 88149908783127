define("ember-svg-jar/inlined/composition-focus-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>composition-focus-square</title><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2zm-2 8.75a.25.25 0 01-.25.25H19a.25.25 0 01-.25-.25v-4.5a1 1 0 00-1-1h-4.5A.25.25 0 0113 5V2.25a.25.25 0 01.25-.25h8.25a.5.5 0 01.5.5zM10.75 2a.25.25 0 01.25.25V5a.25.25 0 01-.25.25h-4.5a1 1 0 00-1 1v4.5A.25.25 0 015 11H2.25a.25.25 0 01-.25-.25V2.5a.5.5 0 01.5-.5zM2 13.25a.25.25 0 01.25-.25H5a.25.25 0 01.25.25v4.5a1 1 0 001 1h4.5A.25.25 0 0111 19v2.75a.25.25 0 01-.25.25H2.5a.5.5 0 01-.5-.5zM13.25 22a.25.25 0 01-.25-.25V19a.25.25 0 01.25-.25h4.5a1 1 0 001-1v-4.5A.25.25 0 0119 13h2.75a.25.25 0 01.25.25v8.25a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});