define("ember-svg-jar/inlined/messages-bubble-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-warning</title><path d=\"M12 1.337C5.659 1.337.5 5.587.5 10.811a8.593 8.593 0 003.175 6.54l-2.3 4.587a.5.5 0 00.658.677l6.149-2.862a13.733 13.733 0 003.818.532c6.341 0 11.5-4.25 11.5-9.474S18.341 1.337 12 1.337zm0 15.038a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm0-11.538a1 1 0 011 1v5.5a1 1 0 01-2 0v-5.5a1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});