define("ember-svg-jar/inlined/performance-increase-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>performance-increase-1</title><path d=\"M1.25 20.75h3.5A.249.249 0 005 20.5v-2.25a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v2.25a.249.249 0 00.25.25zM7.5 12.75a.5.5 0 00-.5.5v7.25a.249.249 0 00.25.25h3.5a.249.249 0 00.25-.25v-7.25a.5.5 0 00-.5-.5zM13.5 14.75a.5.5 0 00-.5.5v5.25a.249.249 0 00.25.25h3.5a.249.249 0 00.25-.25v-5.25a.5.5 0 00-.5-.5zM19.5 7.75a.5.5 0 00-.5.5V20.5a.249.249 0 00.25.25h3.5a.249.249 0 00.25-.25V8.25a.5.5 0 00-.5-.5zM1 23.75h22a1 1 0 000-2H1a1 1 0 000 2zM20.108 4.174a.252.252 0 01.182.073l.856.857a.5.5 0 00.354.146.489.489 0 00.191-.038A.5.5 0 0022 4.75v-4a.5.5 0 00-.5-.5h-4a.5.5 0 00-.354.854l1.021 1.021a.249.249 0 01.007.346L14.96 5.956a.751.751 0 01-.786.2l-3.722-1.24a3.228 3.228 0 00-3.107.584L2.2 9.79a1.25 1.25 0 001.6 1.921l5.145-4.288a.755.755 0 01.716-.134l3.722 1.24a3.253 3.253 0 003.417-.876l3.134-3.4a.255.255 0 01.174-.079z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});