define("ember-svg-jar/inlined/water-glass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-glass</title><path d=\"M5.5 12.756a2.85 2.85 0 00-1.026.209.315.315 0 00-.2.416l1.25 5.993a5.454 5.454 0 003.4 4.056.255.255 0 00.343-.271S8.25 15.525 8.25 15.506a2.752 2.752 0 00-2.75-2.75zM19.437 12.937a2.833 2.833 0 00-.937-.181 2.752 2.752 0 00-2.75 2.75l-1.015 7.653a.254.254 0 00.342.271 5.449 5.449 0 003.4-4.056s1.185-5.732 1.25-6a.375.375 0 00-.29-.437zM12 13.256a2.253 2.253 0 00-2.25 2.25l1.1 8.237a.243.243 0 00.213.215 11.7 11.7 0 001.863 0 .245.245 0 00.216-.216l1.111-8.334A2.245 2.245 0 0012 13.256zM12 .006c-5.416 0-9.5 2.149-9.5 5a3 3 0 00.284 1.243l1.052 5.043a.251.251 0 00.336.181 3.715 3.715 0 011.328-.217 4.232 4.232 0 013.388 1.7.246.246 0 00.385.017A3.673 3.673 0 0112 11.756a3.712 3.712 0 012.734 1.209.245.245 0 00.383-.018 4.236 4.236 0 013.383-1.691 4.548 4.548 0 011.347.231.246.246 0 00.316-.188l1.052-5.048a2.991 2.991 0 00.285-1.245c0-2.851-4.084-5-9.5-5zm0 8c-4.646 0-7.5-1.747-7.5-3s2.854-3 7.5-3 7.5 1.747 7.5 3-2.854 3-7.5 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});