define("ember-svg-jar/inlined/single-neutral-shield.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-shield</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v7.625A15.287 15.287 0 0011.9 23.99a.5.5 0 00.2 0A15.287 15.287 0 0024 9.125V1.5A1.5 1.5 0 0022.5 0zM11.992 5A3 3 0 118.98 8a3.007 3.007 0 013.012-3zm4.923 11.9a.313.313 0 01-.229.1H7.5a.5.5 0 01-.5-.556 5.021 5.021 0 0110 .223.308.308 0 01-.085.233z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});