define("ember-svg-jar/inlined/wine-glass-bottle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wine-glass-bottle</title><path d=\"M7.5 2.75H10a.25.25 0 00.25-.25v-1a1 1 0 00-1-1h-1a1 1 0 00-1 1v1a.25.25 0 00.25.25zM12.54 20.37a.3.3 0 00.21-.262v-.415a.487.487 0 00-.174-.361 5.168 5.168 0 01-1.826-3.943V13a2.5 2.5 0 011.7-2.365.365.365 0 00.066-.413l-2.034-2.444a1 1 0 01-.232-.64V4.5a.25.25 0 00-.25-.25H7.5a.25.25 0 00-.25.25v2.638a1 1 0 01-.232.64l-2.036 2.444a1 1 0 00-.232.64V22.5a1 1 0 001 1h5.064a.29.29 0 00.287-.354A2.156 2.156 0 0111 22.5a2.249 2.249 0 011.54-2.13z\"/><path d=\"M16.915 18.788A3.594 3.594 0 0019.25 15.5V13a1 1 0 00-1-1h-5a1 1 0 00-1 1v2.389a3.579 3.579 0 002.334 3.4.243.243 0 01.166.228v2.233a.25.25 0 01-.25.25h-1.25a1 1 0 000 2h5a1 1 0 000-2H17a.25.25 0 01-.25-.25v-2.235a.242.242 0 01.165-.227zM16.25 17.5a.5.5 0 010-1 1 1 0 001-1 .5.5 0 011 0 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});