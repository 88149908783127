define("ember-svg-jar/inlined/real-estate-favorite-hands-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-favorite-hands-heart</title><path d=\"M4.124 15.835a1.435 1.435 0 00-.458 1.823L5 19.876a.5.5 0 01-.857.515l-1.352-2.25a2.461 2.461 0 01.126-2.474.5.5 0 00.083-.275V12.5a1.5 1.5 0 00-3 0v5.172a4.5 4.5 0 00.986 2.811l2.664 3.33a.5.5 0 00.39.187H8a.5.5 0 00.5-.5v-3.01a4.5 4.5 0 00-.757-2.5c-1.888-1.966-2.477-2.916-3.619-2.155zM22.5 11a1.5 1.5 0 00-1.5 1.5v2.892a.5.5 0 00.083.276 2.435 2.435 0 01.145 2.441l-1.371 2.283a.5.5 0 11-.857-.516l1.352-2.25a1.4 1.4 0 00-.476-1.79c-1.142-.761-1.731.189-3.619 2.157a4.5 4.5 0 00-.757 2.5V23.5a.5.5 0 00.5.5h3.96a.5.5 0 00.39-.187l2.664-3.33A4.5 4.5 0 0024 17.671V12.5a1.5 1.5 0 00-1.5-1.5zM4.186 9.354l7.471-7.032a.5.5 0 01.686 0l7.471 7.032A1 1 0 0021.186 7.9L13.371.543a2 2 0 00-2.742 0L2.814 7.9a1 1 0 001.372 1.454z\"/><path d=\"M17.178 8.752a2.984 2.984 0 00-2.2-1.585 3.04 3.04 0 00-2.632.818L12 8.323l-.348-.338a3.044 3.044 0 00-2.631-.818 2.99 2.99 0 00-2.2 1.585 2.889 2.889 0 00.562 3.407l3.907 3.959a1 1 0 001.42 0l3.9-3.951a2.889 2.889 0 00.568-3.415z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});