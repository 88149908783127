define("ember-svg-jar/inlined/laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop</title><path d=\"M24 22.958a1 1 0 00-1-1H12.75a.25.25 0 01-.25-.25v-1.75a.25.25 0 01.25-.25H22a1.75 1.75 0 001.512-2.632l-2.333-4a1.75 1.75 0 00-1.512-.869H5.5a.5.5 0 01-.5-.5V2.958a.5.5 0 01.5-.5h6a1 1 0 000-2H5a2 2 0 00-2 2v11c0 .036-2.03 3.618-2.03 3.618a1.749 1.749 0 001.51 2.632h7.77a.25.25 0 01.25.25v1.75a.25.25 0 01-.25.25H1a1 1 0 000 2h22a1 1 0 001-1zm-13.5-5.75a.75.75 0 110-1.5h3a.75.75 0 010 1.5z\"/><path d=\"M13.333 3.541a2.25 2.25 0 100 4.5 2.218 2.218 0 001.38-.485.252.252 0 01.248-.036l1.836.735a.25.25 0 01.155.2 2.256 2.256 0 10.834-2.09.252.252 0 01-.248.036l-1.494-.6a.25.25 0 01-.036-.446l1.821-1.095a.25.25 0 01.251 0 2.224 2.224 0 001.087.285 2.25 2.25 0 10-2.24-2.22.249.249 0 01-.121.22l-2.135 1.278a.25.25 0 01-.251 0 2.224 2.224 0 00-1.087-.282z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});