define("ember-svg-jar/inlined/disability-pregant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-pregant</title><circle cx=\"11.183\" cy=\"2.75\" r=\"2.5\"/><path d=\"M16.806 9.01l-3.953-2.076a1.5 1.5 0 00-2.2 1.048l-.34 1.869c-7.71 1.768-4.449 9.9-3.135 9.9h1.25a.25.25 0 01.25.25v2.25a1.5 1.5 0 003 0V20a.25.25 0 01.25-.25h1.135a.5.5 0 00.5-.561l-.759-6.118a.51.51 0 01.005-.151l.308-1.7a.5.5 0 01.718-.357l1.533.778a.5.5 0 01.208.663l-.627 1.273a1.5 1.5 0 00.908 2.108 1.554 1.554 0 001.814-.844l.588-1.2a3.486 3.486 0 00-1.453-4.631z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});