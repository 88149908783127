define("ember-svg-jar/inlined/road-sign-split-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-split-alternate</title><path d=\"M16.146 7.689a1 1 0 00-1.414 0l-2.555 2.556a.25.25 0 01-.354 0L9.268 7.689a1 1 0 00-1.414 0L6.439 9.1a1 1 0 000 1.414l3.488 3.487a.249.249 0 01.073.177v3.572a1 1 0 001 1h2a1 1 0 001-1v-3.568a.249.249 0 01.073-.177l3.488-3.487a1 1 0 000-1.414z\"/><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});