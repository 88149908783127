define("ember-svg-jar/inlined/hair-dress-chair", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hair-dress-chair</title><path d=\"M13.874 20.752a.5.5 0 01-.374-.484V17.5a.5.5 0 01.5-.5h3a1.491 1.491 0 001.491-1.368.251.251 0 01.192-.219 3.258 3.258 0 002.494-2.813l.261-2.872a.251.251 0 01.249-.227h.751a.5.5 0 00.486-.38 2.5 2.5 0 10-4.848 0 .5.5 0 00.486.38h.614a.249.249 0 01.249.273l-.24 2.645a1.215 1.215 0 01-.691.942.25.25 0 01-.311-.076 1.459 1.459 0 00-.711-.524A17.686 17.686 0 0012 12a17.686 17.686 0 00-5.472.76 1.459 1.459 0 00-.711.524.25.25 0 01-.311.076 1.215 1.215 0 01-.691-.942l-.24-2.645a.249.249 0 01.249-.273h.614a.5.5 0 00.486-.38 2.5 2.5 0 10-4.848 0 .5.5 0 00.486.38h.751a.251.251 0 01.249.227l.261 2.873a3.258 3.258 0 002.494 2.814.251.251 0 01.192.219A1.491 1.491 0 007 17h3a.5.5 0 01.5.5v2.768a.5.5 0 01-.374.484L6.264 21.9a1.074 1.074 0 00.309 2.1h10.854a1.074 1.074 0 00.309-2.1z\"/><path d=\"M7.4 10.31a.5.5 0 00.59.537A21.764 21.764 0 0112 10.5a21.764 21.764 0 014.008.347.5.5 0 00.59-.537 24.81 24.81 0 01.239-6.252A12 12 0 0017 2.5 2.5 2.5 0 0014.5 0h-5A2.5 2.5 0 007 2.5a12 12 0 00.163 1.558A24.81 24.81 0 017.4 10.31z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});