define("ember-svg-jar/inlined/fitness-hand-grip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fitness-hand-grip</title><path d=\"M23.831 20.788l-3.288-10.244a1.238 1.238 0 00-1.559-.8l-.209.067a.5.5 0 01-.632-.331l-1.369-4.527a2.053 2.053 0 01-.083-.485A4.7 4.7 0 0012 0a4.7 4.7 0 00-4.69 4.468 2.044 2.044 0 01-.083.485L5.858 9.478a.5.5 0 01-.632.331l-.21-.067a1.236 1.236 0 00-1.558.8L.169 20.788a1.957 1.957 0 001.265 2.461l2.049.657a1.959 1.959 0 002.461-1.264L9.232 12.4a1.238 1.238 0 00-.8-1.559c-.1-.051-.255-.048-.369-.277a.5.5 0 01-.024-.388l.488-1.4a.5.5 0 01.762-.244 4.7 4.7 0 005.426 0 .5.5 0 01.762.243l.488 1.4a.5.5 0 01-.024.388c-.1.2-.26.221-.369.277a1.236 1.236 0 00-.8 1.558l3.288 10.246a1.959 1.959 0 002.461 1.264l2.05-.657a1.957 1.957 0 001.26-2.463zM12 6.782A2.087 2.087 0 1114.087 4.7 2.087 2.087 0 0112 6.782z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});