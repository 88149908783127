define("ember-svg-jar/inlined/shipment-online-monitor-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-online-monitor-1</title><path d=\"M11.5 6.01a.249.249 0 00-.154-.239l-5-2.085A.249.249 0 006 3.915V9.8a1 1 0 00.611.921l4.542 1.921a.25.25 0 00.347-.231c0-4.862-.007-6.069 0-6.401zM16.8 2.96a.251.251 0 000-.462L12.385.66a1.007 1.007 0 00-.77 0l-.828.34a.25.25 0 00-.014.456L15.1 3.574a.252.252 0 00.205.006zM13.464 4.349a.25.25 0 00.014-.456L9.452 1.929a1 1 0 00-.823-.024L7.2 2.5a.25.25 0 000 .462L11.615 4.8a1 1 0 00.77 0zM12.5 12.417a.25.25 0 00.346.231l4.539-1.892A1 1 0 0018 9.833V3.915a.249.249 0 00-.346-.229l-5.005 2.085a.248.248 0 00-.153.239c.011.332.004 1.541.004 6.407z\"/><path d=\"M23.5 3.5a3 3 0 00-3-3 1 1 0 10.014 2 .99.99 0 01.986 1v10.542a1 1 0 01-1 1h-17a1 1 0 01-1-1V3.5a.99.99 0 01.986-1A1 1 0 103.5.5a3 3 0 00-3 3v13a3 3 0 003 3h7.75a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H8.5a1 1 0 000 2h8a1 1 0 000-2h-2.75a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h6.736a3.006 3.006 0 003-2.94z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});