define("ember-svg-jar/inlined/single-neutral-actions-warning.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-warning</title><path d=\"M13.432 14.272a.5.5 0 00-.032-.518A7.528 7.528 0 007.25 10.5a7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643H11.96a.5.5 0 00.443-.268z\"/><circle cx=\"7.044\" cy=\"4.75\" r=\"4.75\"/><path d=\"M18.738 12.271a1.45 1.45 0 00-2.562 0l-5.056 9.634a1.433 1.433 0 00.048 1.409A1.457 1.457 0 0012.4 24h10.112a1.457 1.457 0 001.234-.687 1.431 1.431 0 00.048-1.408zm-1.281 2.979a.75.75 0 01.75.75v2.5a.75.75 0 01-1.5 0V16a.75.75 0 01.75-.75zm0 7a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});