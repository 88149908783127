define("ember-svg-jar/inlined/biking-learner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>biking-learner</title><path d=\"M18.049 14.821a3.228 3.228 0 00-.328.019.5.5 0 01-.521-.321l-.63-1.681a.25.25 0 01.23-.338H18a1 1 0 000-2h-3a1 1 0 00-.937 1.351l.3.811a.251.251 0 01-.029.23.248.248 0 01-.205.108H9.243a.581.581 0 01-.531-.346.466.466 0 01.426-.654 1 1 0 000-2h-4a1 1 0 100 2h.773a.5.5 0 01.457.3l.571 1.292a.5.5 0 01-.051.494l-.526.732a.5.5 0 01-.546.182 4.36 4.36 0 00-1.227-.183 4.59 4.59 0 104.462 5.572.5.5 0 01.488-.389h.883a1 1 0 00.651-.241l.033-.03 2.188-2.029a.25.25 0 01.407.264 4.486 4.486 0 00-.241 1.45 4.589 4.589 0 104.589-4.59zM7 20.34a2.586 2.586 0 11-2.585-3.507.249.249 0 01.215.4l-.853 1.189A1 1 0 004.589 20h2.178a.251.251 0 01.206.109.247.247 0 01.027.231zm1.712-2.454A.248.248 0 018.5 18H7.025a.25.25 0 01-.2-.4l.915-1.274a.25.25 0 01.432.045l.563 1.274a.247.247 0 01-.023.241zm4.081-2.453l-1.766 1.641a.249.249 0 01-.221.061.247.247 0 01-.178-.143L9.9 15.351a.25.25 0 01.232-.351h2.491a.25.25 0 01.17.433zM18.049 22a2.592 2.592 0 01-2.589-2.589 2.566 2.566 0 01.466-1.479.25.25 0 01.439.055l.7 1.864A1 1 0 0018 20.5a1.029 1.029 0 00.351-.063 1 1 0 00.586-1.288l-.737-1.957a.247.247 0 01.041-.246.252.252 0 01.234-.088A2.589 2.589 0 0118.049 22z\"/><path d=\"M11.291 9.105a1 1 0 00.8-1.168A5.058 5.058 0 0112 7a5 5 0 118.727 3.333 1 1 0 101.49 1.334 7 7 0 10-12.094-3.358 1 1 0 001.168.796z\"/><path d=\"M17 3.75a.75.75 0 00-.75.75v3a.75.75 0 00.75.75h2a.75.75 0 000-1.5h-.75a.5.5 0 01-.5-.5V4.5a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});