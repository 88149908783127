define("ember-svg-jar/inlined/award-trophy-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>award-trophy-person</title><circle cx=\"4.25\" cy=\"6.75\" r=\"3.25\"/><path d=\"M4.25 10a4 4 0 00-4 4v3a.5.5 0 00.5.5h1.123l.379 4.542a.5.5 0 00.5.458h3a.5.5 0 00.5-.458l.375-4.542H7.75a.5.5 0 00.5-.5v-3a4 4 0 00-4-4zM15.774 11.975a.25.25 0 00-.274.249v1.847a5.051 5.051 0 00-2.957 1.429c-.147.151-.4.361-.253.7a.5.5 0 00.46.3h7a.5.5 0 00.46-.3c.143-.337-.1-.541-.253-.7A5.051 5.051 0 0017 14.071v-1.847a.25.25 0 00-.274-.249 4.544 4.544 0 01-.952 0zM21.329 18.69a1.649 1.649 0 00-1.579-1.19h-7a1.65 1.65 0 00-1.581 1.194l-.9 3.169a.5.5 0 00.481.637h11.008a.5.5 0 00.48-.638zM22 2.5h-1.25V2a.5.5 0 00-.5-.5h-8a.5.5 0 00-.5.5v.5H10.5a1.752 1.752 0 00-1.75 1.75v2.586A4.134 4.134 0 009.97 9.78a.75.75 0 001.06-1.06 2.683 2.683 0 01-.78-1.884V4.25A.25.25 0 0110.5 4h1.25v2.5a4.5 4.5 0 009 0V4H22a.25.25 0 01.25.25v2.586a2.683 2.683 0 01-.78 1.884.75.75 0 001.06 1.06 4.134 4.134 0 001.22-2.944V4.25A1.752 1.752 0 0022 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});