define("ember-svg-jar/inlined/ranking-stars-ribbon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ranking-stars-ribbon</title><path d=\"M5.494 22.023a1.993 1.993 0 01-1.873-1.338l-.554-1.522a.248.248 0 00-.343-.139A5.866 5.866 0 01.5 19.5a.5.5 0 00-.257.929l2.1 1.261-.793 1.586A.5.5 0 002 24c.482 0 4.157 0 4.878-1.959a.251.251 0 00-.076-.279.249.249 0 00-.288-.021 1.965 1.965 0 01-1.02.282zM23.5 19.5a5.866 5.866 0 01-2.224-.476.25.25 0 00-.343.139l-.554 1.522A2 2 0 0118.615 22a1.921 1.921 0 01-1.133-.28.25.25 0 00-.365.3C17.824 24.005 21.519 24 22 24a.5.5 0 00.447-.724l-.793-1.586 2.1-1.261a.5.5 0 00-.254-.929zM18.053 20.225a.5.5 0 00.917-.053l1.722-4.736a1.493 1.493 0 00-.671-1.818A16.592 16.592 0 0012 11.5a16.592 16.592 0 00-8.021 2.117 1.5 1.5 0 00-.671 1.818l1.722 4.737a.5.5 0 00.916.055C6 20.116 7.4 17.5 12 17.5s6 2.616 6.053 2.725zM5.719 11.323A.871.871 0 006 10.342l-.472-1.263A.249.249 0 015.6 8.8l.977-.814a.857.857 0 00-.55-1.515H4.883a.251.251 0 01-.238-.171L4.26 5.1a.858.858 0 00-1.633 0l-.386 1.2A.249.249 0 012 6.47H.857a.858.858 0 00-.549 1.516l.977.813a.25.25 0 01.074.28l-.473 1.262a.858.858 0 001.3 1l1.114-.795a.251.251 0 01.291 0l1.11.793a.862.862 0 001.018-.016zM9.473 5.245a.25.25 0 01.074.28L8.908 7.23a.947.947 0 001.437 1.1l1.5-1.072a.251.251 0 01.291 0l1.5 1.073a.948.948 0 001.438-1.1l-.639-1.706a.25.25 0 01.074-.28l1.325-1.1a.947.947 0 00-.606-1.675h-1.571a.249.249 0 01-.238-.174L12.894.658a.942.942 0 00-.9-.658.943.943 0 00-.9.657l-.525 1.635a.251.251 0 01-.238.174H8.754a.947.947 0 00-.607 1.675zM19.3 11.34l1.111-.794a.251.251 0 01.291 0l1.111.794a.857.857 0 001.3-1l-.473-1.262a.25.25 0 01.074-.28l.977-.814a.857.857 0 00-.549-1.515H22a.249.249 0 01-.238-.174l-.386-1.2a.858.858 0 00-1.633 0l-.385 1.2a.251.251 0 01-.238.174h-1.149a.858.858 0 00-.55 1.516l.979.815a.249.249 0 01.074.28L18 10.341a.871.871 0 00.282.981.859.859 0 001.018.018z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});