define("ember-svg-jar/inlined/single-man-actions-up-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-up-down</title><path d=\"M13.274 11.68a.25.25 0 00-.049-.431l-2.073-.889a.251.251 0 01-.152-.23V8.995a.252.252 0 01.074-.177 3.305 3.305 0 00.779-2.23.251.251 0 01.088-.177 1.873 1.873 0 00.538-1.4A1.58 1.58 0 0012.162 4a.25.25 0 01-.033-.265 2.534 2.534 0 00.156-1.726C11.944.617 10.242.125 8.888.125a3.752 3.752 0 00-3.163 1.324.25.25 0 01-.212.118 1.072 1.072 0 00-.9.375c-.372.493-.106 1.171.176 1.732a.251.251 0 01-.046.288 1.3 1.3 0 00-.162.2 1.765 1.765 0 00-.265.961 1.522 1.522 0 00.639 1.331.25.25 0 01.116.194 3.162 3.162 0 00.846 2.175.253.253 0 01.083.185v1.122a.25.25 0 01-.151.23l-3.937 1.687C.64 12.593.457 13.751.205 15.355c-.055.359-.117.751-.2 1.179a.5.5 0 00.491.591h9.355a.249.249 0 00.247-.212 8 8 0 013.176-5.233zM17.944 19.4a.5.5 0 00-.445-.271h-1a.25.25 0 01-.25-.25v-5.25a1.25 1.25 0 10-2.5 0v5.25a.25.25 0 01-.25.25h-1a.5.5 0 00-.407.791l2.5 3.5a.5.5 0 00.815 0l1.126-1.578 1.374-1.922a.5.5 0 00.037-.52zM22.249 17.375a.25.25 0 01.25-.25h1a.5.5 0 00.408-.791l-2.5-3.5a.52.52 0 00-.815 0l-1.127 1.578-1.373 1.922a.5.5 0 00.407.791h1a.25.25 0 01.25.25v5.25a1.25 1.25 0 002.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});