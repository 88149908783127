define("ember-svg-jar/inlined/vectors-anchor-square-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vectors-anchor-square-1-alternate</title><rect x=\"20\" y=\"7.5\" width=\"2\" height=\"9\" rx=\".25\" ry=\".25\"/><rect x=\"7.5\" y=\"2\" width=\"9\" height=\"2\" rx=\".25\" ry=\".25\"/><rect x=\"2\" y=\"7.5\" width=\"2\" height=\"9\" rx=\".25\" ry=\".25\"/><rect x=\"7.5\" y=\"20\" width=\"9\" height=\"2\" rx=\".25\" ry=\".25\"/><circle cx=\"21\" cy=\"3\" r=\"3\"/><circle cx=\"3\" cy=\"3\" r=\"3\"/><circle cx=\"21\" cy=\"21\" r=\"3\"/><circle cx=\"3\" cy=\"21\" r=\"3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});