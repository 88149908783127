define("ember-svg-jar/inlined/data-file-bars-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-bars-sync</title><rect x=\"14.5\" y=\"5.5\" width=\"2\" height=\"8\" rx=\".5\" ry=\".5\"/><rect x=\"18\" y=\"7.5\" width=\"2\" height=\"6\" rx=\".5\" ry=\".5\"/><path d=\"M21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.277a.247.247 0 00.241.249 19.53 19.53 0 011.485.093A.246.246 0 008 9.371V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.245.245 0 00-.241.21 7.956 7.956 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.414A2 2 0 0023.414 3zM.309 23.142a.5.5 0 00.545-.109l.974-.974a.244.244 0 01.353.008 6.085 6.085 0 0010.211-2.2 1 1 0 00-1.879-.686 4.029 4.029 0 01-1.276 1.928 4.164 4.164 0 01-5.657-.471.255.255 0 01.014-.349l1.716-1.713a.5.5 0 00-.353-.853H.5a.5.5 0 00-.5.5v4.457a.5.5 0 00.309.462z\"/><path d=\"M7.69 16.338a.5.5 0 00.353.853H12.5a.5.5 0 00.5-.5v-4.457a.5.5 0 00-.854-.353l-1.03 1.03a.243.243 0 01-.352-.008 6.1 6.1 0 00-10.2 2.226 1 1 0 001.88.683A4.027 4.027 0 013.719 13.9a4.155 4.155 0 015.637.435.256.256 0 01-.012.351z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});