define("ember-svg-jar/inlined/e-learning-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-learning-share</title><path d=\"M19.5 8.751a2.747 2.747 0 00-3.289-2.7.5.5 0 01-.452-.137l-.835-.835a.5.5 0 01-.115-.53 3 3 0 10-5.618 0 .5.5 0 01-.115.53l-.835.835a.5.5 0 01-.452.137 2.752 2.752 0 101.985 1.61.5.5 0 01.105-.553l.645-.645a.5.5 0 01.515-.12 3 3 0 001.922 0 .5.5 0 01.515.12l.645.645a.5.5 0 01.105.553 2.75 2.75 0 105.274 1.09zM4.124 15.337a1.406 1.406 0 00-.458 1.824L5 19.377a.5.5 0 11-.857.516l-1.352-2.25a2.47 2.47 0 01.125-2.484.5.5 0 00.084-.277V12a1.5 1.5 0 00-3 0v5.172a4.5 4.5 0 00.986 2.811l2.664 3.33a.5.5 0 00.39.187H8a.5.5 0 00.5-.5v-3.008a4.5 4.5 0 00-.757-2.5c-1.887-1.966-2.477-2.916-3.619-2.155zM22.5 10.5A1.5 1.5 0 0021 12v2.881a.5.5 0 00.084.277 2.47 2.47 0 01.125 2.484l-1.352 2.25a.5.5 0 11-.857-.515l1.334-2.216a1.406 1.406 0 00-.458-1.824c-1.142-.761-1.732.189-3.619 2.157a4.5 4.5 0 00-.757 2.5V23a.5.5 0 00.5.5h3.96a.5.5 0 00.39-.187l2.664-3.33a4.5 4.5 0 00.986-2.81V12a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});