define("ember-svg-jar/inlined/car-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-4</title><path d=\"M15.606 18.013a.252.252 0 00-.224-.138H8.618a.252.252 0 00-.224.138l-.625 1.25a.25.25 0 00.224.362h8.014a.25.25 0 00.224-.362z\"/><path d=\"M22.384 9.125H23a1 1 0 001-1v-1a1 1 0 00-1-1h-1a1.506 1.506 0 00-1.2.6.153.153 0 01-.272-.062l-.476-2.378a3.007 3.007 0 00-2.942-2.41H6.89a3.007 3.007 0 00-2.942 2.411l-.476 2.378a.153.153 0 01-.272.062 1.506 1.506 0 00-1.2-.6H1a1 1 0 00-1 1v1a1 1 0 001 1h.616a.25.25 0 01.152.449A4.46 4.46 0 000 13.125v5a1.5 1.5 0 00.943 1.386.093.093 0 01.058.1v1.016a1.5 1.5 0 001.5 1.5h2a1.506 1.506 0 001.5-1.5v-1.12a.256.256 0 01.026-.112l1.3-2.605A.749.749 0 018 16.375h8a.749.749 0 01.671.415l1.3 2.605a.256.256 0 01.026.112v1.118a1.506 1.506 0 001.5 1.5h2a1.5 1.5 0 001.5-1.5v-1.016a.093.093 0 01.058-.1A1.5 1.5 0 0024 18.125v-5a4.46 4.46 0 00-1.768-3.551.25.25 0 01.152-.449zm-18.041 4.9a.25.25 0 01-.2.1H3.5a2 2 0 01-1.98-1.714.252.252 0 01.248-.286h2.006a.25.25 0 01.243.19l.371 1.5a.249.249 0 01-.045.214zm13.55-1.594l-.45 1.817a.248.248 0 01-.182.182l-.522.131a1.99 1.99 0 01-.486.06H7.324a1.985 1.985 0 01-.631-.1.145.145 0 01-.094-.1l-.492-1.987a.25.25 0 01.243-.31h11.3a.25.25 0 01.243.31zm.876-3.9a.254.254 0 01-.194.091H5.425a.25.25 0 01-.246-.3l.73-3.647a1 1 0 01.981-.8h10.22a1 1 0 01.981.8l.73 3.647a.256.256 0 01-.052.212zm3.463 3.591a.248.248 0 01.189.086.251.251 0 01.059.2 2 2 0 01-1.98 1.714h-.646a.249.249 0 01-.242-.31l.371-1.5a.25.25 0 01.243-.19z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});