define("ember-svg-jar/inlined/breakfast-bread-toast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>breakfast-bread-toast</title><path d=\"M5.388 5.912a.25.25 0 01.064.344A2.644 2.644 0 005 7.75a.25.25 0 00.25.25h1a.25.25 0 00.25-.25 1.555 1.555 0 011.008-1.3A.75.75 0 007.25 5a1.75 1.75 0 010-3.5h11.5a1.75 1.75 0 010 3.5c-.363 0-.925.261-.988.619s.388.71.73.835A1.546 1.546 0 0119.5 7.75a.25.25 0 00.25.25h1a.25.25 0 00.25-.25 2.644 2.644 0 00-.452-1.494.251.251 0 01.064-.344A3.249 3.249 0 0018.75 0H7.25a3.249 3.249 0 00-1.862 5.912z\"/><path d=\"M10.829 4.165l-1 2a.75.75 0 00.336 1.006.75.75 0 001.006-.335l1-2a.75.75 0 10-1.342-.671zM14.829 4.165l-1 2a.75.75 0 00.336 1.006.75.75 0 001.006-.335l1-2a.75.75 0 10-1.342-.671zM21.5 9h-17a2.5 2.5 0 00-2.358 1.666.5.5 0 01-.472.334H1.5A1.5 1.5 0 000 12.5v1A1.5 1.5 0 001.5 15a.5.5 0 01.5.5v7A1.5 1.5 0 003.5 24h19a1.5 1.5 0 001.5-1.5v-11A2.5 2.5 0 0021.5 9zm-16 11.75a.75.75 0 01-1.5 0v-8.5a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});