define("ember-svg-jar/inlined/diagram-arrow-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-arrow-left</title><path d=\"M24 10.236a1.5 1.5 0 00-1.5-1.5H9.035a.249.249 0 01-.25-.25V5.5A1 1 0 007.1 4.768l-6.63 6.16a1.458 1.458 0 000 2.145l6.63 6.16a1 1 0 001.685-.733v-3.014a.25.25 0 01.25-.25H22.5a1.5 1.5 0 001.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});