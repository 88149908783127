define("ember-svg-jar/inlined/meeting-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-laptop</title><path d=\"M19.641 11.346a.249.249 0 00-.173.237v4.781a1 1 0 002 0v-5.8a.25.25 0 00-.442-.16 3 3 0 01-1.385.942zM5.114 5.5a2.67 2.67 0 00-2.645 2.684V16.5a1 1 0 002 0V8.176a.667.667 0 01.653-.676h4.1a.25.25 0 00.25-.25v-1.5a.249.249 0 00-.25-.25zM23.5 19h-8a.5.5 0 00-.5.5c0 .71-1.232 1.5-3 1.5s-3-.79-3-1.5a.5.5 0 00-.5-.5h-8a.5.5 0 00-.485.622A5.773 5.773 0 005.623 24h12.754a5.77 5.77 0 005.608-4.378A.5.5 0 0023.5 19zM23.34 1.517l-2.807.748a.25.25 0 01-.314-.242V1.5a1.5 1.5 0 00-1.5-1.5h-6.25a1.5 1.5 0 00-1.5 1.5v7a1.5 1.5 0 001.5 1.5h6.25a1.5 1.5 0 001.5-1.5v-.524a.249.249 0 01.314-.242l2.807.748A.5.5 0 0023.969 8V2a.5.5 0 00-.2-.4.492.492 0 00-.429-.083z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});