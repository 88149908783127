define("ember-svg-jar/inlined/email-action-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-sync</title><path d=\"M19.6 16.149a.3.3 0 00-.3.008l-5.872 3.694a2.682 2.682 0 01-2.844 0l-5.878-3.694a.3.3 0 00-.3-.008.3.3 0 00-.154.263V22.1a1.8 1.8 0 001.788 1.8h11.922a1.8 1.8 0 001.788-1.8v-5.689a.3.3 0 00-.15-.262z\"/><path d=\"M11.526 18.325a.9.9 0 00.948 0l7.234-4.551a.089.089 0 00.042-.076 1.8 1.8 0 00-1.788-1.8H6.038A1.8 1.8 0 004.25 13.7a.09.09 0 00.042.077zM7.771 4.666a1 1 0 001.266-.629 2.774 2.774 0 015.072-.5l-.809.809a.321.321 0 00.227.547h2.854a.321.321 0 00.321-.32v-2.86a.32.32 0 00-.547-.226l-.6.6A4.777 4.777 0 007.142 3.4a1 1 0 00.629 1.266zM7 8.942a.323.323 0 00.349-.07l.545-.541A4.77 4.77 0 0016.358 7.1a1 1 0 10-1.9-.638 2.78 2.78 0 01-5.011.621.958.958 0 00-.147-.155l.9-.891a.314.314 0 00.069-.346.319.319 0 00-.295-.2H7.119a.319.319 0 00-.321.318v2.839A.319.319 0 007 8.942z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});