define("ember-svg-jar/inlined/seo-search-graph-first-place", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seo-search-graph-first-place</title><path d=\"M18.25 22.5H.75a.75.75 0 000 1.5h17.5a.75.75 0 000-1.5zM1.5 9.5a.5.5 0 00-.5.5v11.25a.25.25 0 00.25.25h3.5a.25.25 0 00.25-.25V10a.5.5 0 00-.5-.5zM7 21.25a.25.25 0 00.25.25h3.5a.25.25 0 00.25-.25v-6.5c0-.276-.224-.25-.5-.25h-3a.5.5 0 00-.5.5zM16.75 21.5a.25.25 0 00.25-.25V17c0-.276-.224-.25-.5-.25h-3a.5.5 0 00-.5.5v4a.25.25 0 00.25.25zM21.184 11.341a.249.249 0 01-.028-.32 7 7 0 10-1.707 1.707.25.25 0 01.321.027l2.523 2.524a1 1 0 001.414-1.414zM15.429 12a5 5 0 115-5 5.006 5.006 0 01-5 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});