define("ember-svg-jar/inlined/messages-people-man-bubble-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-man-bubble-circle</title><path d=\"M16.5 0a7.678 7.678 0 00-6.162 3.007 1 1 0 001.6 1.195A5.67 5.67 0 0116.5 2 5.274 5.274 0 0122 7a5.005 5.005 0 01-3.359 4.605 1.008 1.008 0 00-.319.2c-.2.184-2.068 1.773-2.324 1.991v-.858a1 1 0 00-.861-1 5.81 5.81 0 01-.789-.166A1 1 0 0013.8 13.7l.2.054v2.25a1 1 0 001.707.707c.267-.267 3.543-3.054 3.828-3.305A6.973 6.973 0 0024 7a7.271 7.271 0 00-7.5-7z\"/><circle cx=\"13.116\" cy=\"6.5\" r=\"1\"/><circle cx=\"16.366\" cy=\"6.5\" r=\"1\"/><circle cx=\"19.616\" cy=\"6.5\" r=\"1\"/><path d=\"M10.5 17.663V16.28a3.072 3.072 0 00.991-2.43 1.826 1.826 0 00.655-1.5 1.612 1.612 0 00-.22-.859 1.092 1.092 0 00-.245-.276 3.7 3.7 0 00.3-2.338C11.638 7.492 9.891 7 8.5 7c-1.239 0-2.761.39-3.323 1.456a1.152 1.152 0 00-1.02.382c-.511.626-.1 1.654.2 2.367a1.093 1.093 0 00-.268.289 1.611 1.611 0 00-.229.875 1.807 1.807 0 00.65 1.481 3.072 3.072 0 00.99 2.43v1.383C3.624 18.427 0 20.03 0 21v2.5a.5.5 0 00.5.5h15a.5.5 0 00.5-.5V21c0-.97-3.624-2.573-5.5-3.337z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});