define("ember-svg-jar/inlined/check-payment-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>check-payment-sign</title><path d=\"M3.793 17.792a1 1 0 001.414 1.415l1.283-1.284a.709.709 0 011.187.325 1.355 1.355 0 002.069.8l.976-.651a.711.711 0 01.894.089l.718.718a1 1 0 00.707.293H17a1 1 0 000-2h-3.441a.25.25 0 01-.177-.074l-.352-.351a2.717 2.717 0 00-3.418-.339l-.138.093a.25.25 0 01-.337-.056 2.707 2.707 0 00-4.061-.264z\"/><path d=\"M24 10.5a1 1 0 00-1-1h-3a1 1 0 000 2h1.75a.249.249 0 01.25.25v10a.25.25 0 01-.25.25H2.25a.25.25 0 01-.25-.25v-10a.249.249 0 01.25-.25H6a1 1 0 000-2H1a1 1 0 00-1 1v12A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5z\"/><path d=\"M21.234 4.472A2.621 2.621 0 0017.527.765l-6.679 6.679a.251.251 0 000 .354l3.352 3.353a.249.249 0 00.353 0zM9.147 12.853A.5.5 0 009.5 13a.466.466 0 00.1-.01l3.143-.629a.251.251 0 00.128-.422L10.06 9.131a.251.251 0 00-.422.128L9.01 12.4a.5.5 0 00.137.453z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});