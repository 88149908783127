define("ember-svg-jar/inlined/coffee-pot-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>coffee-pot-1</title><path d=\"M23.943 15.925l-1.379-7.9a4.986 4.986 0 00-4.153-3.937.221.221 0 01-.125-.372 1 1 0 10-1.425-1.395l-1.585 1.62a.249.249 0 01-.178.075H3.426a1 1 0 100 1.994h14.248A3 3 0 0120.6 8.4l1.379 7.9a1 1 0 01-.978 1.193h-.092a.5.5 0 01-.5-.535c.025-.354.038-.68.038-.961a11.271 11.271 0 00-3.9-8.259.974.974 0 00-.616-.219H1.988a.981.981 0 00-.834.464 1.009 1.009 0 00-.061.954l.529 1.133a.5.5 0 01-.033.48A10.022 10.022 0 000 15.989a17.536 17.536 0 00.562 4.174 2.486 2.486 0 002.4 1.81H17.49a2.484 2.484 0 002.4-1.808c.028-.1.054-.2.079-.3a.5.5 0 01.484-.38h.553a2.991 2.991 0 002.938-3.555zm-6.57-3.539a.249.249 0 01-.223.362H3.553a.252.252 0 01-.212-.118.248.248 0 01-.011-.243 7.612 7.612 0 01.521-.892.745.745 0 00.062-.726l-.336-.746a.205.205 0 01.009-.184.177.177 0 01.148-.083h11.709a.188.188 0 01.118.041 7.427 7.427 0 011.812 2.589z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});