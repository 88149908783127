define("ember-svg-jar/inlined/designer-community-designmoo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>designer-community-designmoo</title><path d=\"M9.83 4.17l1.27.33a.66.66 0 01.43.43l.33 1.27c0 .19.13.19.18 0l.33-1.27a.66.66 0 01.43-.43l1.27-.33c.19 0 .19-.13 0-.18l-1.27-.33a.66.66 0 01-.43-.43L12 2c0-.19-.13-.19-.18 0l-.33 1.27a.66.66 0 01-.43.43L9.83 4c-.18 0-.18.12 0 .17zM23.28 15.08l-2-.53a1 1 0 01-.67-.68l-.53-2c-.08-.29-.2-.29-.28 0l-.53 2a1 1 0 01-.67.68l-2 .53c-.29.08-.29.2 0 .28l2 .53a1 1 0 01.67.68l.53 2c.08.29.2.29.28 0l.53-2a1 1 0 01.67-.68l2-.53c.29-.08.29-.2 0-.28z\"/><path d=\"M16.84 16.57c-.33 0-.6.23-1.59 1.36l-.27.3c-.94 1-1.59 1.43-1.83 1.43a.46.46 0 010-.17c0-.93 1.46-4.13 2.33-6 .29-.63.54-1.2.72-1.62s.32-.72.49-1.08a26.5 26.5 0 001.04-2.59 4.68 4.68 0 00.34-1.6 1.87 1.87 0 00-1.67-1.92.5.5 0 00-.56.37s-.41 1.54-.49 1.78A27.86 27.86 0 0113.5 11a2.51 2.51 0 00-2-.91c-2.78 0-5.23 3.5-6 5.87-.44 1.4-1 4 0 5.45a2.69 2.69 0 002.3 1.09 3.55 3.55 0 002.44-1.26 2 2 0 00.46.75 1.65 1.65 0 001.18.49c1.13 0 2.29-.81 4.25-3 .92-1 1.39-1.73 1.4-2.15a.77.77 0 00-.21-.57.67.67 0 00-.48-.19zm-6.47 1.5c-.32.57-1.24 1.79-1.9 1.79-.45 0-.76-.47-.73-1.16a8.31 8.31 0 012.06-4.93c.36-.4.75-.9 1.33-.9s.75.31.74.87a8.86 8.86 0 01-1.5 4.33z\"/><path d=\"M5.39 10.12l.67 2.55a11.12 11.12 0 011.33-1.61l.25-.94A1.76 1.76 0 018.77 9l3.38-.89c.5-.13.5-.34 0-.47l-3.38-.91a1.76 1.76 0 01-1.14-1.14l-.88-3.4c-.13-.5-.34-.5-.47 0l-.89 3.4a1.76 1.76 0 01-1.14 1.14l-3.38.89c-.5.13-.5.34 0 .47L4.25 9a1.76 1.76 0 011.14 1.12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});