define("ember-svg-jar/inlined/diagram-up-double", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-up-double</title><path d=\"M.422 3.177A1.44 1.44 0 001.44 5.635h1.065a.25.25 0 01.25.25v8.87a9.245 9.245 0 0018.49 0v-8.87a.25.25 0 01.25-.25h1.065a1.44 1.44 0 001.018-2.458L20.825.424a1.439 1.439 0 00-2.04 0l-2.753 2.753a1.44 1.44 0 001.018 2.458h1.065a.25.25 0 01.25.25v8.87a6.365 6.365 0 01-12.73 0v-8.87a.25.25 0 01.25-.25H6.95a1.44 1.44 0 001.018-2.458L5.215.424a1.439 1.439 0 00-2.04 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});