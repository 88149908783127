define("ember-svg-jar/inlined/conversation-chat-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>conversation-chat-alternate</title><path d=\"M22.043 0H1.957A1.981 1.981 0 000 2v6a1.981 1.981 0 001.957 2h2.826v2.5a1 1 0 001.631.776L10.442 10h11.6A1.981 1.981 0 0024 8V2a1.981 1.981 0 00-1.957-2zM10.087 8a1 1 0 00-.631.224L6.783 10.4V9a1 1 0 00-1-1H2.248A.25.25 0 012 7.752l-.04-5.5A.251.251 0 012.208 2H21.75a.25.25 0 01.25.25v5.5a.25.25 0 01-.25.25z\"/><path d=\"M22.043 11H11.065a1 1 0 100 2h10.687a.25.25 0 01.25.248l.039 4.5a.246.246 0 01-.072.178.252.252 0 01-.178.074h-3.574a1 1 0 00-1 1v1.706l-2.623-2.438a1 1 0 00-.681-.268H2.248A.25.25 0 012 17.752l-.039-4.5a.246.246 0 01.072-.178.252.252 0 01.176-.074H3a1 1 0 000-2H1.957A1.981 1.981 0 000 13v5a1.981 1.981 0 001.957 2H13.52l4.017 3.73a1 1 0 001.68-.73v-3h2.826A1.981 1.981 0 0024 18v-5a1.981 1.981 0 00-1.957-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});