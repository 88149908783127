define("ember-svg-jar/inlined/shop-sign-close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shop-sign-close</title><path d=\"M11.125 13.75v3a.875.875 0 001.75 0v-3a.875.875 0 00-1.75 0z\"/><path d=\"M23.678 18.849a.613.613 0 01-.178.026h-2a1.627 1.627 0 01-1.625-1.625v-4a1.627 1.627 0 011.625-1.625h2a.613.613 0 01.178.026.25.25 0 00.322-.24v-.161a2.5 2.5 0 00-2.5-2.5h-3.991a.251.251 0 01-.216-.124l-2.926-5.017a2.74 2.74 0 00-4.734 0L6.706 8.626a.249.249 0 01-.216.124H2.5a2.5 2.5 0 00-2.5 2.5v1.892a.25.25 0 00.46.136 3.625 3.625 0 013.04-1.653.625.625 0 010 1.25 2.375 2.375 0 000 4.75.625.625 0 010 1.25 3.625 3.625 0 01-3.04-1.653.25.25 0 00-.46.136v1.892a2.5 2.5 0 002.5 2.5h19a2.5 2.5 0 002.5-2.5v-.161a.25.25 0 00-.322-.24zm-7.251-4.732l1.84 1.226a1.928 1.928 0 01-1.07 3.532H15.5a.625.625 0 010-1.25h1.7a.678.678 0 00.376-1.242l-1.84-1.226a1.928 1.928 0 011.07-3.532h1.7a.625.625 0 010 1.25H16.8a.678.678 0 00-.376 1.242zm-2.3-.367v3a2.125 2.125 0 01-4.25 0v-3a2.125 2.125 0 014.25 0zm-9.25-1.5a.625.625 0 011.25 0v5a.375.375 0 00.375.375h2a.625.625 0 010 1.25h-2a1.627 1.627 0 01-1.627-1.625zm6.484-7.632a.774.774 0 011.282 0l2.191 3.756a.25.25 0 01-.216.376H9.384a.25.25 0 01-.216-.376z\"/><path d=\"M23.5 12.875h-2a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25H22.5a.625.625 0 010 1.25h-1.125a.25.25 0 00-.25.25v1.125a.375.375 0 00.375.375h2a.613.613 0 01.178.026.25.25 0 00.322-.24v-4.322a.25.25 0 00-.322-.24.613.613 0 01-.178.026z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});