define("ember-svg-jar/inlined/asian-interior-boiler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>asian-interior-boiler</title><path d=\"M20.244 9.647l-1.819-.795a.5.5 0 00-.506.062 11.923 11.923 0 00-1.419 1.334.5.5 0 01-.753-.018l-.25-.3a3.1 3.1 0 00-2.39-1.12H8.47a3.119 3.119 0 00-2.425 1.168L4.7 12.073a3.2 3.2 0 00-.287 3.7 3.248 3.248 0 002.822 1.537h7.414a3.2 3.2 0 003.193-3.193 5.267 5.267 0 00-.235-1.221.5.5 0 01.091-.451c.7-.866 1.716-1.84 2.346-1.84a.5.5 0 00.2-.958zM4.674 19.187a5.92 5.92 0 00-1.218 2.623 2 2 0 004 0 5.925 5.925 0 00-1.218-2.623 1.03 1.03 0 00-1.564 0zM10.674 19.187a5.92 5.92 0 00-1.218 2.623 2 2 0 004 0 5.925 5.925 0 00-1.218-2.623 1.03 1.03 0 00-1.564 0zM16.674 19.187a5.92 5.92 0 00-1.218 2.623 2 2 0 004 0 5.925 5.925 0 00-1.218-2.623 1.03 1.03 0 00-1.564 0zM7.956 8.31a1 1 0 001-1v-.12a1.988 1.988 0 01.916-1.67.054.054 0 01.084.045 1 1 0 102 0 .054.054 0 01.084-.045 1.988 1.988 0 01.916 1.67v.12a1 1 0 002 0v-.12a3.992 3.992 0 00-2.667-3.756.5.5 0 01-.333-.471V1.19a1 1 0 10-2 0v1.773a.5.5 0 01-.333.471A3.992 3.992 0 006.956 7.19v.12a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});