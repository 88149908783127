define("ember-svg-jar/inlined/paralympics-ball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paralympics-ball</title><path d=\"M5.5 22a3.491 3.491 0 01-1.749-6.517A.5.5 0 004 15.05v-1.127a.5.5 0 00-.7-.458 5.5 5.5 0 107.235 7.235.5.5 0 00-.458-.7H8.95a.5.5 0 00-.433.249A3.486 3.486 0 015.5 22z\"/><circle cx=\"7\" cy=\"4.5\" r=\"2.5\"/><path d=\"M16.38 20.2l-1.912-3.06A3.48 3.48 0 0011.5 15.5H9a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h1.521a4.494 4.494 0 004.137-2.728l.721-1.681a1.5 1.5 0 10-2.758-1.182L11.9 7.591a1.5 1.5 0 01-1.379.909H7A1.5 1.5 0 005.5 10v7A1.5 1.5 0 007 18.5h4.5a.505.505 0 01.424.234l1.912 3.066a1.5 1.5 0 002.544-1.6z\"/><circle cx=\"21\" cy=\"3\" r=\"3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});