define("ember-svg-jar/inlined/arrow-thick-circle-bottom-left-corner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-circle-bottom-left-corner</title><path d=\"M20.485 3.515a12 12 0 100 16.97 12.013 12.013 0 000-16.97zm-2.3 5.477l-4.419 4.419a.25.25 0 000 .354l2.679 2.679a.5.5 0 01-.353.854H7.2a.5.5 0 01-.5-.5v-8.89a.5.5 0 01.853-.354l2.678 2.678a.25.25 0 00.354 0l4.422-4.417a.5.5 0 01.707 0l2.469 2.47a.5.5 0 01.001.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});