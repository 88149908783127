define("ember-svg-jar/inlined/phone-hand-typing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-hand-typing</title><path d=\"M16.6 13.25a1 1 0 00-2 0v5.5a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5V3.25a1 1 0 011-1h1a1 1 0 000-2h-1a3 3 0 00-3 3v17.5a3 3 0 003 3h8a3 3 0 003-3z\"/><circle cx=\"12.882\" cy=\"5.184\" r=\"1.25\"/><circle cx=\"16.882\" cy=\"5.184\" r=\"1.25\"/><path d=\"M21.4 5.167a4.5 4.5 0 00-4.5-4.5h-4.5a4.5 4.5 0 000 9h.25a.25.25 0 01.25.25v1a.75.75 0 001.166.624l2.75-1.833a.247.247 0 01.134-.042 4.5 4.5 0 004.45-4.499zm-4.75 3a.748.748 0 00-.416.126l-1.445.963a.25.25 0 01-.389-.208v-.131a.75.75 0 00-.75-.75H12.4a3 3 0 010-6h4.5a3 3 0 010 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});