define("ember-svg-jar/inlined/time-clock-file-timer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-clock-file-timer</title><path d=\"M23.487 2.928L21.073.514A1.76 1.76 0 0019.836 0H8.25A1.752 1.752 0 006.5 1.751v7.513c.084 0 .166-.013.25-.013A7.925 7.925 0 018 9.359V1.751a.25.25 0 01.25-.25h11.586a.257.257 0 01.177.073l2.414 2.414a.251.251 0 01.073.177v14.086a.25.25 0 01-.25.25h-7.608a8 8 0 01-.389 1.5h8A1.752 1.752 0 0024 18.251V4.165a1.736 1.736 0 00-.513-1.237z\"/><path d=\"M6.75 10.5a6.75 6.75 0 106.75 6.75 6.758 6.758 0 00-6.75-6.75zm0 11.5a4.75 4.75 0 114.75-4.75A4.756 4.756 0 016.75 22z\"/><path d=\"M8.75 16.251H8A.25.25 0 017.75 16v-1.25a1 1 0 10-2 0v2.5a1 1 0 001 1h2a1 1 0 000-2zM15.75 13A4.75 4.75 0 1011 8.251 4.756 4.756 0 0015.75 13zm0-8a3.25 3.25 0 11-3.25 3.25A3.254 3.254 0 0115.75 5z\"/><path d=\"M15.75 9h1.768a.75.75 0 100-1.5h-.767a.252.252 0 01-.251-.252v-.765a.75.75 0 00-1.5 0v1.768a.75.75 0 00.75.749z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});