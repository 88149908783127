define("ember-svg-jar/inlined/hair-dress-comb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hair-dress-comb</title><path d=\"M23.167 20.454a2.5 2.5 0 000-3.536L10.086 3.836a3.507 3.507 0 00-4.95 0l-4.6 4.6a1.5 1.5 0 000 2.121 1.535 1.535 0 002.121 0l3.187-3.184a.508.508 0 01.706 0l.354.353a.5.5 0 010 .707L3.9 11.438A.75.75 0 004.959 12.5l3.005-3.006a.5.5 0 01.707 0l.354.353a.5.5 0 010 .707l-3.005 3a.75.75 0 001.061 1.066l3.005-3a.5.5 0 01.707 0l.353.353a.5.5 0 010 .707l-3.005 3.001A.75.75 0 009.2 16.742l3.005-3.006a.5.5 0 01.707 0l.354.353a.5.5 0 01.146.355.5.5 0 01-.146.352l-3.182 3.183a1.5 1.5 0 000 2.121 1.536 1.536 0 002.122 0l3.183-3.182a.51.51 0 01.706 0l3.536 3.536a2.5 2.5 0 003.535 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});