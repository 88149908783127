define("ember-svg-jar/inlined/layout-dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layout-dashboard</title><path d=\"M.793.786a1 1 0 00-.293.707v11.015a1 1 0 001 1h8a1 1 0 001-1v-11a1 1 0 00-1-1l-8-.015a1 1 0 00-.707.293zM22.5.493l-8 .014a1 1 0 00-1 1v5a1 1 0 001 1h8a1 1 0 001-1V1.493a1 1 0 00-1-1zM10.207 23.214a1 1 0 00.293-.707v-5a1 1 0 00-1-1h-8a1 1 0 00-1 1v4.985a1 1 0 001 1l8 .014a1 1 0 00.707-.292zM22.5 10.508h-8a1 1 0 00-1 1v11a1 1 0 001 1l8-.014a1 1 0 001-1V11.508a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});