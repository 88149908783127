define("ember-svg-jar/inlined/plane-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-1</title><path d=\"M21.345 9.25H6.759a.5.5 0 01-.353-.15L4.845 7.543a1 1 0 00-.707-.293H1.154A1 1 0 00.26 8.7l2.5 5a.994.994 0 00.894.553h5.88a.25.25 0 01.177.427l-3.865 3.862a1 1 0 00.706 1.708h3.586a1 1 0 00.707-.293l5.561-5.557a.5.5 0 01.353-.146h4.586a2.5 2.5 0 000-5z\"/><path d=\"M10.492 8.1a.5.5 0 00.353.146h4.6a.25.25 0 00.176-.427l-3.78-3.78a1 1 0 00-.707-.293H8.552a1 1 0 00-.707 1.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});