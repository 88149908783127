define("ember-svg-jar/inlined/medical-notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-notes</title><path d=\"M7 12.714v2.571a.643.643 0 00.643.643h1.929a.5.5 0 01.5.5v1.929a.641.641 0 00.642.642h2.572a.642.642 0 00.643-.642v-1.929a.5.5 0 01.5-.5h1.928a.643.643 0 00.643-.643v-2.571a.643.643 0 00-.643-.643h-1.928a.5.5 0 01-.5-.5V9.642A.643.643 0 0013.286 9h-2.572a.642.642 0 00-.642.643v1.929a.5.5 0 01-.5.5H7.643a.643.643 0 00-.643.642z\"/><path d=\"M19.5 3h-2.75a.25.25 0 00-.25.25v1.5a.249.249 0 00.25.25H19a.5.5 0 01.5.5v16a.5.5 0 01-.5.5H5a.5.5 0 01-.5-.5v-16A.5.5 0 015 5h2.25a.249.249 0 00.25-.25v-1.5A.25.25 0 007.25 3H4.5a2 2 0 00-2 2v17a2 2 0 002 2h15a2 2 0 002-2V5a2 2 0 00-2-2z\"/><path d=\"M10 6.5h4a1 1 0 001-1V3A3.009 3.009 0 0011.192.107 3.046 3.046 0 009 3.087V5.5a1 1 0 001 1zM12 2a.75.75 0 11-.75.75A.75.75 0 0112 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});