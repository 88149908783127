define("ember-svg-jar/inlined/real-estate-settings-house-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-settings-house-1</title><path d=\"M24 11a1 1 0 00-1-1h-1.969a.5.5 0 01-.483-.37A12.932 12.932 0 0020 7.972a.5.5 0 01.108-.549l1.438-1.433a1 1 0 000-1.415l-2.122-2.121a1 1 0 00-1.414 0l-1.435 1.434a.5.5 0 01-.549.112 12.99 12.99 0 00-1.655-.544.5.5 0 01-.371-.488V1a1 1 0 00-1-1h-2a1 1 0 00-1 1v1.968a.5.5 0 01-.371.483A13.112 13.112 0 007.973 4a.5.5 0 01-.549-.107L5.989 2.454a1 1 0 00-1.414 0L2.453 4.575a1 1 0 000 1.415l1.435 1.435A.5.5 0 014 7.973a13.183 13.183 0 00-.549 1.657.5.5 0 01-.483.37H1a1 1 0 00-1 1v2a1 1 0 001 1h1.969a.5.5 0 01.482.37A13.309 13.309 0 004 16.028a.5.5 0 01-.108.547l-1.439 1.436a1 1 0 000 1.414l2.122 2.121a1.03 1.03 0 001.414 0l1.434-1.436a.5.5 0 01.549-.11 13.039 13.039 0 001.658.546.5.5 0 01.37.483V23a1 1 0 001 1h2a1 1 0 001-1v-1.968a.5.5 0 01.37-.483A13.183 13.183 0 0016.027 20a.5.5 0 01.548.107l1.436 1.435a1 1 0 001.414 0l2.121-2.121a1 1 0 000-1.414l-1.435-1.436a.5.5 0 01-.111-.543 13.253 13.253 0 00.545-1.658.5.5 0 01.483-.37H23a1 1 0 001-1zm-6.5 1.5h-1a.5.5 0 00-.5.5v3a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1.5a1 1 0 00-2 0V16a.5.5 0 01-.5.5h-2A.5.5 0 018 16v-3a.5.5 0 00-.5-.5h-1a.5.5 0 01-.336-.87l5.163-4.694a.994.994 0 011.346 0l5.163 4.694a.5.5 0 01-.336.87z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});