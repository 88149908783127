define("ember-svg-jar/inlined/messages-bubble-warning-triangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-warning-triangle</title><path d=\"M14.891 11.574a2.95 2.95 0 015.218 0l.527 1.006a.251.251 0 00.442 0A7.546 7.546 0 0022 9c0-4.962-4.935-9-11-9S0 4.038 0 9a8.076 8.076 0 002.657 5.854l-1.626 4.475a.5.5 0 00.662.632l5.831-2.429A13.224 13.224 0 0011 18c.131 0 .259-.008.388-.015a.251.251 0 00.208-.134z\"/><path d=\"M18.782 12.271a1.45 1.45 0 00-2.562 0l-5.055 9.634a1.433 1.433 0 00.048 1.409 1.457 1.457 0 001.232.686h10.111a1.459 1.459 0 001.234-.687 1.434 1.434 0 00.047-1.408zM17.5 15.25a.75.75 0 01.75.75v2.5a.75.75 0 01-1.5 0V16a.75.75 0 01.75-.75zm0 7a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});