define("ember-svg-jar/inlined/compass-directions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>compass-directions</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm1.07 15.463L7.2 17.774a.752.752 0 01-.286.057.75.75 0 01-.688-1.031l2.311-5.87a.5.5 0 01.815-.163l3.881 3.88a.5.5 0 01-.163.816zm4.7-8.258l-2.311 5.864a.5.5 0 01-.365.3.5.5 0 01-.451-.137l-3.88-3.879a.5.5 0 01.163-.816L16.8 6.226a.75.75 0 01.979.979z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});