define("ember-svg-jar/inlined/casino-777-slot-machine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>casino-777-slot-machine</title><path d=\"M3.265 4a.505.505 0 01-.393-.82 8.5 8.5 0 0113.256 0 .505.505 0 01-.393.82zM4 14.75a.751.751 0 01-.689-1.05l.9-2.105a.25.25 0 00-.229-.349H3.5a.75.75 0 010-1.5h2a.75.75 0 01.689 1.054l-1.5 3.5a.749.749 0 01-.689.45zM9 14.75a.751.751 0 01-.689-1.05l.9-2.105a.25.25 0 00-.229-.349H8.5a.75.75 0 010-1.5h2a.75.75 0 01.689 1.046l-1.5 3.5A.749.749 0 019 14.75zM14 14.75a.751.751 0 01-.689-1.046l.9-2.105a.25.25 0 00-.229-.349H13.5a.75.75 0 010-1.5h2a.75.75 0 01.689 1.046l-1.5 3.5a.749.749 0 01-.689.454z\"/><path d=\"M22 7.5a2 2 0 00-1.111 3.662.25.25 0 01.111.208v5.38a2 2 0 01-1.714 1.98.252.252 0 01-.286-.248V7a1.5 1.5 0 00-1.5-1.5h-16A1.5 1.5 0 000 7v15.5A1.5 1.5 0 001.5 24h16a1.5 1.5 0 001.5-1.5v-1.507a.25.25 0 01.235-.25A4 4 0 0023 16.75v-5.38a.25.25 0 01.111-.208A2 2 0 0022 7.5zM7.5 21.25H3a.75.75 0 010-1.5h4.5a.75.75 0 010 1.5zm10-6.25a1 1 0 01-1 1h-14a1 1 0 01-1-1V9.5a1 1 0 011-1h14a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});