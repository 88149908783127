define("ember-svg-jar/inlined/love-it-ribbon-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it-ribbon-2</title><path d=\"M23.366 19.766l-3-5.18a.25.25 0 00-.439.011 4.663 4.663 0 01-4.318 2.548.247.247 0 00-.19.078 4.53 4.53 0 01-.963.765.251.251 0 00-.086.341l2.942 4.979a1 1 0 001.8-.172l.832-2.287 2.392.405a1 1 0 001.031-1.488zM8.583 17.224a.249.249 0 00-.191-.078A4.666 4.666 0 014.074 14.6a.251.251 0 00-.216-.136.247.247 0 00-.223.125l-3 5.181a1 1 0 001.032 1.487l2.391-.405.833 2.289a1 1 0 001.8.167l3.069-5.194a4.725 4.725 0 01-1.177-.89zM14.813 15.5a3.169 3.169 0 003.978-3.978 3.17 3.17 0 000-5.626 3.169 3.169 0 00-3.978-3.978 3.17 3.17 0 00-5.627 0 3.169 3.169 0 00-3.978 3.976 3.169 3.169 0 000 5.625A3.169 3.169 0 009.187 15.5a3.169 3.169 0 005.626 0zM8.591 9.132a2.017 2.017 0 112.852-2.852l.38.38a.251.251 0 00.354 0l.38-.38a2.017 2.017 0 112.852 2.852l-2.717 2.834a.953.953 0 01-1.384 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});