define("ember-svg-jar/inlined/vide-document-qt-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vide-document-qt-1</title><path d=\"M9.5 12.125A1.377 1.377 0 008.125 13.5V17a1.375 1.375 0 002.75 0v-3.5A1.377 1.377 0 009.5 12.125z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM12.125 17a2.61 2.61 0 01-1.25 2.221c-.052.033-.079.148 0 .185l.942.555a.625.625 0 01.221.856.663.663 0 01-.855.222l-3.061-1.814A2.622 2.622 0 016.875 17v-3.5a2.625 2.625 0 015.25 0zm5.375-4.875h-1.125a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0v-7.125a.25.25 0 00-.25-.25H13.5a.625.625 0 010-1.25h4a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});