define("ember-svg-jar/inlined/monitor-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-chart</title><path d=\"M19 23.751a1 1 0 000-2h-6.25a.25.25 0 01-.25-.25V20a.25.25 0 01.25-.25H21a3 3 0 003-3V2.84A2.548 2.548 0 0021.5.251h-19A2.548 2.548 0 000 2.84v13.911a3 3 0 003 3h7.25a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H5a1 1 0 000 2zM2 2.84a.551.551 0 01.5-.589h19a.551.551 0 01.5.589v11.911a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5z\"/><path d=\"M14 4.51a.249.249 0 00-.11-.372A4.866 4.866 0 0012 3.751 4.983 4.983 0 007.131 7.7a.249.249 0 00.05.21.252.252 0 00.194.09h3.967a.5.5 0 00.412-.217zM15.624 5.325a.251.251 0 00-.388.031l-2.081 3.037a.5.5 0 00.028.6l2.357 2.821a.25.25 0 00.389-.006 4.933 4.933 0 00-.3-6.487zM7.375 9.5a.25.25 0 00-.244.3A4.984 4.984 0 0012 13.751a4.92 4.92 0 002.317-.587.251.251 0 00.075-.382L11.8 9.68a.5.5 0 00-.384-.179z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});