define("ember-svg-jar/inlined/messages-bubble-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-remove</title><path d=\"M17.5 9.5a7.932 7.932 0 013.992 1.077.249.249 0 00.228.012.254.254 0 00.143-.18A7.4 7.4 0 0022 9c0-4.962-4.935-9-11-9S0 4.038 0 9a8.077 8.077 0 002.657 5.854L1.03 19.329a.5.5 0 00.662.632l5.832-2.429a13.174 13.174 0 001.695.34.248.248 0 00.2-.063.251.251 0 00.082-.2v-.113A8.009 8.009 0 0117.5 9.5z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-2.651 8.091l1.414-1.414a.252.252 0 000-.354l-1.414-1.414a.75.75 0 011.06-1.06l1.414 1.414a.252.252 0 00.354 0l1.414-1.414a.75.75 0 111.06 1.06l-1.414 1.414a.252.252 0 000 .354l1.414 1.414a.75.75 0 11-1.06 1.06l-1.414-1.414a.252.252 0 00-.354 0l-1.414 1.414a.75.75 0 11-1.06-1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});