define("ember-svg-jar/inlined/filter-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>filter-2</title><path d=\"M23.894.552A1 1 0 0023 0H1a1 1 0 00-.8 1.6L9 13.423V22a2.015 2.015 0 002 2 1.993 1.993 0 001.2-.4l2-1.5a2.007 2.007 0 00.8-1.6v-7.077L23.8 1.6a1 1 0 00.094-1.048zM5.417 2.2l3.939 5.25a.5.5 0 01.1.3V9a.5.5 0 01-.9.3L3.62 2.8a.5.5 0 01.4-.8h1a.5.5 0 01.397.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});