define("ember-svg-jar/inlined/yoga-cobra", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>yoga-cobra</title><circle cx=\"21\" cy=\"5.75\" r=\"2.5\"/><path d=\"M22 11.25a1.5 1.5 0 00-2.772-.795l-3.238 5.18a4.467 4.467 0 01-1.829 1.651.5.5 0 00-.247.625l.708 1.876a.5.5 0 00.676.278A7.41 7.41 0 0018 17.971V15.75a.5.5 0 011 0v3.5a1.5 1.5 0 003 0zM12.911 18.071a.5.5 0 00-.5-.328c-.08.005-.159.007-.239.007H2a1.5 1.5 0 000 3h10.175a7.217 7.217 0 001.065-.082.5.5 0 00.393-.668c-.216-.573-.544-1.439-.722-1.929z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});