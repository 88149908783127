define("ember-svg-jar/inlined/shopping-basket-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-basket-disable</title><path d=\"M17.448 10.569a.25.25 0 00.209-.184l.211-.8a.5.5 0 00-.483-.627H2.379a.5.5 0 00-.489.6l1.276 5.994A1.956 1.956 0 005.132 17h5.305a.249.249 0 00.244-.2 8.007 8.007 0 016.767-6.231zM10.5 11.614V14a.5.5 0 01-1 0v-2.386a.5.5 0 011 0zm-3.872 3.031a.488.488 0 01-.085.007.5.5 0 01-.492-.417l-.408-2.385a.5.5 0 01.986-.168l.408 2.386a.5.5 0 01-.409.577zM19.763 6.167a1.441 1.441 0 00-1.439-1.439h-1.142a.25.25 0 01-.222-.135L14.848.538a1 1 0 00-1.774.924l1.511 2.9a.251.251 0 01-.222.366H5.4a.249.249 0 01-.213-.121.246.246 0 01-.008-.245l1.51-2.9A1 1 0 104.916.538L2.8 4.593a.249.249 0 01-.221.135h-1.14a1.439 1.439 0 000 2.878h16.885a1.441 1.441 0 001.439-1.439z\"/><path d=\"M18.25 12.5A5.75 5.75 0 1024 18.25a5.756 5.756 0 00-5.75-5.75zm0 2a3.722 3.722 0 011.53.327.25.25 0 01.075.4l-4.623 4.623a.25.25 0 01-.4-.075A3.749 3.749 0 0118.25 14.5zm0 7.5a3.741 3.741 0 01-1.53-.326.25.25 0 01-.074-.405l4.623-4.623a.25.25 0 01.4.074A3.749 3.749 0 0118.25 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});