define("ember-svg-jar/inlined/server-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-disable</title><path d=\"M0 3.5A2.5 2.5 0 002.5 6h17A2.5 2.5 0 0022 3.5v-1A2.5 2.5 0 0019.5 0h-17A2.5 2.5 0 000 2.5zM3.635 3A1.115 1.115 0 114.75 4.114 1.115 1.115 0 013.635 3zM7.5 3a1.115 1.115 0 111.115 1.114A1.114 1.114 0 017.5 3zM17.5 9.5a7.957 7.957 0 013.777.949.493.493 0 00.723-.439v-.508A2.5 2.5 0 0019.5 7h-17A2.5 2.5 0 000 9.5v1A2.5 2.5 0 002.5 13h8.139a.491.491 0 00.4-.206A7.992 7.992 0 0117.5 9.5zm-7.771.5a1.115 1.115 0 11-1.114-1.115A1.115 1.115 0 019.729 10zm-3.864 0A1.115 1.115 0 114.75 8.885 1.115 1.115 0 015.865 10zM2.5 20h6.742a.5.5 0 00.481-.638 7.866 7.866 0 01.3-4.687A.5.5 0 009.56 14H2.5A2.5 2.5 0 000 16.5v1A2.5 2.5 0 002.5 20zm1.135-3a1.115 1.115 0 111.115 1.114A1.115 1.115 0 013.635 17z\"/><path d=\"M11 17.5a6.5 6.5 0 106.5-6.5 6.508 6.508 0 00-6.5 6.5zm6.5 4.5a4.438 4.438 0 01-2.1-.532.255.255 0 01-.129-.184.251.251 0 01.071-.214l5.727-5.726a.249.249 0 01.4.059A4.416 4.416 0 0122 17.5a4.505 4.505 0 01-4.5 4.5zm2.1-8.468a.249.249 0 01.059.4l-5.728 5.727a.249.249 0 01-.213.071.253.253 0 01-.184-.129A4.426 4.426 0 0113 17.5a4.5 4.5 0 014.5-4.5 4.423 4.423 0 012.1.532z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});