define("ember-svg-jar/inlined/single-man-actions-image.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-image</title><path d=\"M7.066 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.743a.5.5 0 01.741-.3A6.959 6.959 0 008.526 5a6.343 6.343 0 00.927-.07.5.5 0 01.568.565A3 3 0 014.066 5a2.841 2.841 0 01.1-.743zM8.934 11a.355.355 0 00-.286-.349 6.831 6.831 0 00-1.376-.151 7.544 7.544 0 00-7.185 5.357.5.5 0 00.479.643h7.868a.5.5 0 00.5-.5zM12.5 10.5a2 2 0 00-2 2V22a2 2 0 002 2H22a2 2 0 002-2v-9.5a2 2 0 00-2-2zm8.775 6.387a1.25 1.25 0 00-2.064-.024l-1.537 2.2a.254.254 0 01-.169.1.25.25 0 01-.192-.053l-.612-.489a1.249 1.249 0 00-1.821.282l-2 3.005a.2.2 0 01-.171.092.206.206 0 01-.206-.206V12.75a.25.25 0 01.25-.25h9a.25.25 0 01.25.25v4.4a.25.25 0 01-.458.139z\"/><circle cx=\"15.5\" cy=\"15\" r=\"1.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});