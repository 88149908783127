define("ember-svg-jar/inlined/credit-card-dollar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-dollar</title><path d=\"M17.75 3.75A.25.25 0 0018 3.5 2.5 2.5 0 0015.5 1h-13A2.5 2.5 0 000 3.5a.25.25 0 00.25.25zM17.735 8.918a.247.247 0 00.107-.177 3 3 0 01.144-.629A.252.252 0 0018 8.03V5.5a.25.25 0 00-.25-.25H.25A.25.25 0 000 5.5v6A2.5 2.5 0 002.5 14h13a.053.053 0 00.041-.019.051.051 0 00.013-.044 5.188 5.188 0 012.181-5.019zM3.5 9.75h3a.75.75 0 010 1.5h-3a.75.75 0 010-1.5zM22.305 15.349l-2.194-1.131a1.168 1.168 0 01-.611-1.036 1.121 1.121 0 011.086-1.151H23a1 1 0 000-2h-.93a.25.25 0 01-.25-.25V9.1a1 1 0 10-2 0v.844a.249.249 0 01-.172.238A3.176 3.176 0 0019.2 16l2.194 1.131A1.168 1.168 0 0122 18.162a1.121 1.121 0 01-1.086 1.151H18.5a1 1 0 000 2h1.07a.249.249 0 01.25.25V22a1 1 0 002 0v-.648a.251.251 0 01.162-.234 3.178 3.178 0 00.323-5.769z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});