define("ember-svg-jar/inlined/delivery-truck-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>delivery-truck-5</title><path d=\"M23.75 6.375A1.752 1.752 0 0022 4.625h-9.5a.75.75 0 00-.75.75v7a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25v-5a.75.75 0 00-.75-.75H4a3.755 3.755 0 00-3.75 3.75v6A1.752 1.752 0 002 18.125h2.3a.25.25 0 00.247-.293 2.651 2.651 0 01-.042-.457 3 3 0 016 0 2.651 2.651 0 01-.042.457.249.249 0 00.055.2.252.252 0 00.192.089h4.09a.25.25 0 00.247-.293 2.651 2.651 0 01-.047-.453 3 3 0 016 0 2.651 2.651 0 01-.042.457.249.249 0 00.055.2.252.252 0 00.192.089H23a.75.75 0 00.75-.75zm-22 4A2.253 2.253 0 014 8.125h2a.25.25 0 01.25.25v3a.25.25 0 01-.25.25H2a.25.25 0 01-.25-.25z\"/><circle cx=\"7.5\" cy=\"17.375\" r=\"2\"/><circle cx=\"18\" cy=\"17.375\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});