define("ember-svg-jar/inlined/agriculture-machine-plow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>agriculture-machine-plow</title><path d=\"M23.821 8.1a1.007 1.007 0 00-.851-.475h-.191a.5.5 0 01-.5-.5v-.25a.75.75 0 00-1.5 0v.25a.5.5 0 01-.5.5H7.072a.5.5 0 01-.45-.281c-.231-.474-.465-.949-.7-1.416-.833-1.665-1.777-3.553-4.9-3.553a1 1 0 000 2c1.882 0 2.317.869 3.105 2.447 1.508 3.016 3.032 6.354 3.67 7.767a.5.5 0 01-.088.545 1 1 0 00-.224.391 1.016 1.016 0 00.08.766l2.484 4.552a1.5 1.5 0 001.317.782h5.379a1 1 0 00.539-1.842 5.389 5.389 0 01-1.6-1.435 5.521 5.521 0 01-.628-1.272 2.982 2.982 0 01-.071-1.7L16.09 11a.5.5 0 01.485-.379h3.7a.5.5 0 01.5.5v.25a.75.75 0 001.5 0v-.282a.5.5 0 01.4-.489.992.992 0 00.687-.532l.5-1a.991.991 0 00-.041-.968zm-9.853 3.146l-.668 2.687a.5.5 0 01-.563.373 12.919 12.919 0 00-1.954-.181 4.3 4.3 0 00-.58.04.5.5 0 01-.523-.29c-.249-.553-.658-1.454-1.161-2.54a.5.5 0 01.454-.71h4.514a.5.5 0 01.485.621z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});