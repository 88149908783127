define("ember-svg-jar/inlined/single-neutral-aim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-aim</title><path d=\"M24 12a1 1 0 00-1-1h-.827a.249.249 0 01-.248-.22 10.021 10.021 0 00-8.706-8.706.25.25 0 01-.219-.248V1a1 1 0 00-2 0v.826a.25.25 0 01-.219.248 10.021 10.021 0 00-8.706 8.707.25.25 0 01-.248.219H1a1 1 0 000 2h.827a.25.25 0 01.248.219 10.019 10.019 0 008.706 8.705.25.25 0 01.219.248V23a1 1 0 002 0v-.828a.25.25 0 01.219-.248 10.019 10.019 0 008.706-8.7.25.25 0 01.248-.22H23A1 1 0 0024 12zm-10.709 7.888a.25.25 0 01-.291-.247V19.5a1 1 0 00-2 0v.141a.25.25 0 01-.291.247 8.01 8.01 0 01-6.6-6.6.253.253 0 01.057-.2.248.248 0 01.192-.088H4.5a1 1 0 000-2h-.142a.25.25 0 01-.247-.291 8.013 8.013 0 016.6-6.6.253.253 0 01.2.057.248.248 0 01.088.19V4.5a1 1 0 002 0v-.143a.252.252 0 01.088-.191.256.256 0 01.2-.056 8.013 8.013 0 016.6 6.6.253.253 0 01-.057.2.248.248 0 01-.19.088h-.14a1 1 0 000 2h.142a.248.248 0 01.19.088.253.253 0 01.057.2 8.01 8.01 0 01-6.598 6.602z\"/><path d=\"M13.457 12.87a.894.894 0 01-.026-.391 4.033 4.033 0 00.869-2.766 2.3 2.3 0 10-4.59 0 3.988 3.988 0 00.833 2.723c.043.089.053.355.076.382-1.543.58-2.39 1-2.738 1.709a4.247 4.247 0 00-.368 1.155.5.5 0 00.109.407.5.5 0 00.378.179h8a.5.5 0 00.383-.179.5.5 0 00.109-.407 4.247 4.247 0 00-.368-1.155c-.348-.705-1.195-1.127-2.667-1.657z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});