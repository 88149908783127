define("ember-svg-jar/inlined/data-file-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-edit</title><path d=\"M14.267 9.948l1.507.9A1 1 0 0017 10.7a.9.9 0 00.105-.123l2.688-3.734a1 1 0 10-1.624-1.168l-2.015 2.8a.252.252 0 01-.332.068L14.3 7.638a1 1 0 00-1.331.279l-2.309 3.265a1 1 0 001.631 1.157l1.644-2.321a.249.249 0 01.332-.07z\"/><path d=\"M14 20.557a.246.246 0 00.244.193H22a2 2 0 002-2V4.664a2 2 0 00-.586-1.414L21 .836A2 2 0 0019.584.25H8a2 2 0 00-2 2v8.568a.249.249 0 00.072.182l1.5 1.5A.25.25 0 008 12.318V2.75a.5.5 0 01.5-.5h10.878a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.147.353V18.25a.5.5 0 01-.5.5h-7.6a.247.247 0 00-.244.3c.102.423.264 1.158.344 1.507zM7.8 21.9l3.354-3.353a.252.252 0 000-.354l-6.58-6.58a2.71 2.71 0 00-3.606-.273 2.622 2.622 0 00-.2 3.881L7.444 21.9a.25.25 0 00.356 0zM9.258 23.111l3.143.629a.466.466 0 00.1.01.5.5 0 00.49-.6l-.628-3.142a.25.25 0 00-.422-.128L9.13 22.689a.25.25 0 00.128.422z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});