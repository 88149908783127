define("ember-svg-jar/inlined/shop-close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shop-close</title><path d=\"M4.606 18.8a.849.849 0 00-.848-.849 2.061 2.061 0 110-4.121.849.849 0 000-1.7 3.758 3.758 0 100 7.515.848.848 0 00.848-.845zM9.7 14.438v2.909a2.3 2.3 0 104.606 0v-2.909a2.3 2.3 0 00-4.606 0zm2.909 0v2.909a.606.606 0 01-1.212 0v-2.909a.606.606 0 011.212 0zM23.151 17.953h-1.939a.121.121 0 01-.121-.121v-.841a.25.25 0 01.25-.25h.841a.849.849 0 000-1.7h-.841a.25.25 0 01-.25-.25v-.841a.121.121 0 01.121-.121h1.939a.849.849 0 100-1.7h-1.939a1.82 1.82 0 00-1.818 1.818v3.879a1.819 1.819 0 001.818 1.818h1.939a.849.849 0 100-1.7zM5.7 12.135a.849.849 0 00-.849.848v4.849a1.82 1.82 0 001.816 1.818h1.939a.849.849 0 000-1.7H6.667a.121.121 0 01-.122-.121v-4.846a.848.848 0 00-.845-.848zM16.242 14.247a.417.417 0 01.416-.415H18.3a.849.849 0 000-1.7h-1.642A2.111 2.111 0 0015.487 16l1.784 1.19a.415.415 0 01-.231.759h-1.646a.849.849 0 100 1.7h1.646a2.111 2.111 0 001.171-3.868l-1.784-1.191a.41.41 0 01-.185-.343zM20.5 22h-17a1 1 0 100 2h17a1 1 0 000-2zM17.75 5.483a.244.244 0 00-.009-.066c-.109-.4-1.188-4.329-1.434-5.233A.249.249 0 0016.066 0H13a.25.25 0 00-.25.25v9.362a.249.249 0 00.14.224 4.729 4.729 0 002.473.46 4.869 4.869 0 002.379-.843.018.018 0 00.008-.015zM23.958 5.073L22.237.967A1.5 1.5 0 0020.883 0h-2.747a.249.249 0 00-.236.316l1.329 4.832a.8.8 0 01.026.2V8.92a.251.251 0 00.159.233A3.349 3.349 0 0024 6.183v-.91a.532.532 0 00-.042-.2zM11.25.25A.25.25 0 0011 0H7.934a.25.25 0 00-.241.184L6.259 5.417a.244.244 0 00-.009.066V9.3a.251.251 0 00.113.209 4.943 4.943 0 002.289.784c.133.011.266.015.4.015a4.68 4.68 0 002.061-.473.249.249 0 00.14-.224zM4.75 5.348a.8.8 0 01.026-.2L6.105.316A.249.249 0 005.864 0H3.121a1.5 1.5 0 00-1.354.967L.046 5.073a.532.532 0 00-.042.2v.91A3.247 3.247 0 001.992 9.1a3.34 3.34 0 002.6.05.25.25 0 00.159-.233z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});