define("ember-svg-jar/inlined/delivery-truck-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>delivery-truck-1</title><path d=\"M7.5 15h1.25a.25.25 0 00.25-.25V5a1 1 0 00-1-1H5.671a1.992 1.992 0 00-1.85 1.24L.5 13.153a.25.25 0 00.23.347h2.02a.75.75 0 010 1.5H.25a.25.25 0 00-.25.25v1A1.752 1.752 0 001.75 18h2.509a.251.251 0 00.25-.231A3 3 0 017.5 15zM5.607 6.155A.251.251 0 015.839 6h.911a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25H4.4a.253.253 0 01-.2-.111.25.25 0 01-.023-.234zM22.65 15a.25.25 0 00.247-.284l-1.013-7.432A.25.25 0 0122.131 7H23a1 1 0 000-2h-1.338a.251.251 0 01-.221-.131A1.644 1.644 0 0020 4h-9.5a.5.5 0 00-.5.5v10.25a.25.25 0 00.25.25zM15 9.5a.5.5 0 01-.5.5H12a.5.5 0 01-.5-.5V6a.5.5 0 01.5-.5h2.5a.5.5 0 01.5.5zM20.391 17.206l.014.051a.948.948 0 00.9.743H23a1 1 0 000-2h-1.738a.926.926 0 00-.871 1.206zM13.738 16h-2.476a.926.926 0 00-.871 1.206l.014.051a.948.948 0 00.9.743h2.386a.948.948 0 00.9-.743l.014-.051A.926.926 0 0013.738 16z\"/><circle cx=\"7.5\" cy=\"18\" r=\"2\"/><circle cx=\"17.5\" cy=\"18\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});