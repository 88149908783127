define("ember-svg-jar/inlined/ambulance-human", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ambulance-human</title><path d=\"M23.25 6.5h-2a.5.5 0 01-.5-.5V4a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v2a.5.5 0 01-.5.5h-2a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h2a.5.5 0 01.5.5v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 00.5-.5V7a.5.5 0 00-.5-.5zM12.125 2.547a.5.5 0 01-.375-.483V1a1 1 0 00-2 0v1a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v1.064a.5.5 0 01-.375.483A1.5 1.5 0 00.25 4v16a1.5 1.5 0 001.125 1.452.5.5 0 01.375.484V23a1 1 0 002 0v-1a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v1a1 1 0 002 0v-1.064a.5.5 0 01.375-.484A1.5 1.5 0 0013.25 20V4a1.5 1.5 0 00-1.125-1.453zM6.75 5A1.75 1.75 0 115 6.75 1.751 1.751 0 016.75 5zm3 9a.5.5 0 01-.5.5H8.7a.5.5 0 00-.5.445l-.4 3.611a.5.5 0 01-.5.444H6.2a.5.5 0 01-.5-.445l-.4-3.611a.5.5 0 00-.5-.445h-.55a.5.5 0 01-.5-.5V12.5a3 3 0 016 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});