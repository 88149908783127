define("ember-svg-jar/inlined/lock-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lock-3</title><path d=\"M15 16.5a.5.5 0 010-1h.449a3.485 3.485 0 00-2.949-2.949V13a.5.5 0 01-1 0v-.449A3.485 3.485 0 008.551 15.5H9a.5.5 0 010 1h-.449a3.485 3.485 0 002.949 2.949V19a.5.5 0 011 0v.449a3.485 3.485 0 002.949-2.949zm-3 .5a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M18.75 11.725V6.75a6.75 6.75 0 00-13.5 0v4.975a8 8 0 1013.5 0zM12.5 21a.5.5 0 01-1 0v-.551A4.474 4.474 0 017.551 16.5H7a.5.5 0 010-1h.551a4.474 4.474 0 013.949-3.949V11a.5.5 0 011 0v.551a4.474 4.474 0 013.949 3.949H17a.5.5 0 010 1h-.551a4.474 4.474 0 01-3.949 3.949zM12 2.5a4.255 4.255 0 014.25 4.25v1.631a.5.5 0 01-.722.448 7.922 7.922 0 00-7.056 0 .5.5 0 01-.722-.448V6.75A4.255 4.255 0 0112 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});