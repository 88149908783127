define("ember-svg-jar/inlined/smart-watch-circle-home-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-home-alternate</title><path d=\"M21.5 12A9.5 9.5 0 0017 3.927a.245.245 0 01-.109-.148L15.983.373A.5.5 0 0015.5 0h-7a.5.5 0 00-.484.371l-.908 3.408A.245.245 0 017 3.927a9.5 9.5 0 000 16.149.25.25 0 01.109.148l.908 3.407A.5.5 0 008.5 24h7a.5.5 0 00.482-.368l.935-3.428a.247.247 0 01.109-.146A9.5 9.5 0 0021.5 12zM12 19.5a7.5 7.5 0 117.5-7.5 7.509 7.509 0 01-7.5 7.5z\"/><path d=\"M17.129 10.914l-4.91-3.833a.343.343 0 00-.439 0l-4.91 3.833a.329.329 0 00-.1.369.341.341 0 00.319.219H8a.25.25 0 01.25.25V15a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-1.25a1.25 1.25 0 012.5 0V15a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-3.25a.25.25 0 01.25-.25h.91a.34.34 0 00.319-.219.329.329 0 00-.1-.367z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});