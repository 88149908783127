define("ember-svg-jar/inlined/phone-action-edit-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-edit-3</title><path d=\"M12.206 14.272v4.22a1 1 0 01-1 1h-7a1 1 0 01-1-1v-11a1 1 0 011-1h6.083a.252.252 0 00.176-.073L12.3 4.585s.2-.175.2-.281c0-.161-.082-.214-.227-.248a2.494 2.494 0 00-.564-.064h-8a2.5 2.5 0 00-2.5 2.5v15a2.5 2.5 0 002.5 2.5h8a2.5 2.5 0 002.5-2.5v-7.553a.25.25 0 00-.3-.244l-1.5.333a.25.25 0 00-.203.244z\"/><path d=\"M10.856 9.113a.25.25 0 00-.422.128l-.628 3.142a.5.5 0 00.137.452.519.519 0 00.451.136l3.143-.628a.25.25 0 00.128-.422zM21.83.572a2.71 2.71 0 00-3.606.274l-6.58 6.58a.252.252 0 000 .354L15 11.133a.249.249 0 00.353 0l6.68-6.679A2.624 2.624 0 0021.83.572z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});