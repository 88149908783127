define("ember-svg-jar/inlined/messages-bubble-square-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-graph</title><path d=\"M22 1.5H2A1.5 1.5 0 00.5 3v13.552A1.459 1.459 0 002 18h3v4a.5.5 0 00.785.411L12.156 18H22a1.5 1.5 0 001.5-1.5V3A1.5 1.5 0 0022 1.5zM6.506 14.25a.75.75 0 01-.75-.75v-8a.75.75 0 011.5 0v7.25h1.5V10a.75.75 0 011.5 0v2.75h1.5V8.5a.75.75 0 011.5 0v4.25h1.5V6.5a.75.75 0 011.5 0v6.25h2.25a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});