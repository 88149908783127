define("ember-svg-jar/inlined/warehouse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>warehouse</title><rect x=\"5.5\" y=\"13.286\" width=\"13\" height=\"2\" rx=\"1\" ry=\"1\"/><rect x=\"5.5\" y=\"17.286\" width=\"13\" height=\"2\" rx=\"1\" ry=\"1\"/><path d=\"M23.5 8.81H.5a.5.5 0 00-.5.5v12.809a1.572 1.572 0 001.546 1.595H3a.5.5 0 00.5-.5V12.286a1 1 0 011-1h15a1 1 0 011 1v10.928a.5.5 0 00.5.5h1.454A1.572 1.572 0 0024 22.119V9.31a.5.5 0 00-.5-.5z\"/><rect x=\"5.5\" y=\"21.286\" width=\"13\" height=\"2\" rx=\"1\" ry=\"1\"/><path d=\"M.5 7.31h23a.5.5 0 00.5-.5c0-.267-.143-6.524-12.522-6.524C.131.286 0 6.543 0 6.81a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});