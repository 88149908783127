define("ember-svg-jar/inlined/police-hat-7", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-hat-7</title><path d=\"M19.25 12A2.252 2.252 0 0017 9.75H7A2.252 2.252 0 004.75 12v7.338a.5.5 0 00.409.491A37.256 37.256 0 0012 20.5a37.265 37.265 0 006.841-.671.5.5 0 00.409-.491zm-4.1 3.321a.751.751 0 01.36 1A3.784 3.784 0 0112 18.25a3.784 3.784 0 01-3.508-1.931.75.75 0 111.358-.638 1.581 1.581 0 00.594.638.25.25 0 00.362-.319 3.64 3.64 0 01-.306-1.5c0-1.381.671-2.5 1.5-2.5s1.5 1.119 1.5 2.5a3.624 3.624 0 01-.307 1.5.251.251 0 00.362.316 1.577 1.577 0 00.6-.638.752.752 0 01.993-.357z\"/><path d=\"M23.984 9.45a6.535 6.535 0 00-6.477-5.95H6.493A6.536 6.536 0 00.015 9.451a4.639 4.639 0 00.016.9l.637 5.419a4.461 4.461 0 001.785 3.071.5.5 0 00.8-.4V12A3.754 3.754 0 017 8.25h10A3.754 3.754 0 0120.75 12v6.444a.5.5 0 00.8.4 4.461 4.461 0 001.785-3.071l.637-5.419a4.639 4.639 0 00.016-.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});