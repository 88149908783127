define("ember-svg-jar/inlined/tea-kettle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tea-kettle</title><path d=\"M21.855 11.5a.25.25 0 00.238-.175A8.555 8.555 0 0022.5 8.75a8.75 8.75 0 10-17.5 0 8.555 8.555 0 00.406 2.576.251.251 0 00.239.175zM13.75 2a6.757 6.757 0 016.75 6.75 6.52 6.52 0 01-.116 1.221.249.249 0 01-.4.152 10.2 10.2 0 00-3.868-1.844.25.25 0 01-.162-.362 2.466 2.466 0 00.295-1.166 2.5 2.5 0 00-5 0 2.446 2.446 0 00.3 1.179.251.251 0 01-.163.363 10.114 10.114 0 00-3.876 1.829.249.249 0 01-.4-.152A6.594 6.594 0 017 8.75 6.758 6.758 0 0113.75 2zM22.678 13.255a.5.5 0 00-.436-.255H5.166a.384.384 0 00-.335.2l-1.7-1.456a1 1 0 00-.649-.239H1a1 1 0 00-.883 1.47L3.441 19.2a.5.5 0 01.059.236V21.5A2.5 2.5 0 006 24h15.5a2.5 2.5 0 002.5-2.5v-3.25a10.122 10.122 0 00-1.322-4.995z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});