define("ember-svg-jar/inlined/martial-arts-helmet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>martial-arts-helmet</title><path d=\"M21.764 18.024a6.957 6.957 0 01-2.841-1.2.5.5 0 00-.641.049A8.963 8.963 0 0112 19.5a8.961 8.961 0 01-6.282-2.631.5.5 0 00-.641-.049 6.957 6.957 0 01-2.841 1.2A1.5 1.5 0 001 19.5v3A1.5 1.5 0 002.5 24a1.565 1.565 0 00.215-.015 12.006 12.006 0 006.367-3.016.25.25 0 01.418.184V22.5A1.5 1.5 0 0011 24h2a1.5 1.5 0 001.5-1.5v-1.347a.25.25 0 01.418-.184 12 12 0 006.367 3.015A1.424 1.424 0 0021.5 24a1.5 1.5 0 001.5-1.5v-3a1.5 1.5 0 00-1.236-1.476z\"/><path d=\"M12 18c4.418 0 8-4.029 8-9s-3.582-9-8-9-8 4.029-8 9 3.582 9 8 9zm1-15.07a.252.252 0 01.1-.2.248.248 0 01.22-.039 5.693 5.693 0 013.38 2.945.25.25 0 01-.218.364H13.5a.5.5 0 01-.5-.5zm0 5.57a.5.5 0 01.5-.5h3.483a.5.5 0 01.5.465 7.96 7.96 0 010 1.07.5.5 0 01-.5.465H13.5a.5.5 0 01-.5-.5zm0 4a.5.5 0 01.5-.5h2.982a.25.25 0 01.222.364 5.693 5.693 0 01-3.384 2.946.248.248 0 01-.22-.039.252.252 0 01-.1-.2zm-2 2.57a.252.252 0 01-.1.2.248.248 0 01-.22.039 5.693 5.693 0 01-3.38-2.945.25.25 0 01.218-.364H10.5a.5.5 0 01.5.5zM7.3 5.636a5.693 5.693 0 013.38-2.946.248.248 0 01.22.039.252.252 0 01.1.2V5.5a.5.5 0 01-.5.5H7.518a.25.25 0 01-.218-.364zm-.782 2.829A.5.5 0 017.017 8H10.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H7.017a.5.5 0 01-.5-.465 7.96 7.96 0 01.001-1.07z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});