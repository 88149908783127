define("ember-svg-jar/inlined/water-protection-drop-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-protection-drop-1</title><path d=\"M4.416 17.725A13.038 13.038 0 0012 19.914a13.038 13.038 0 007.584-2.189 1 1 0 10-1.168-1.625A11.056 11.056 0 0112 17.914 11.056 11.056 0 015.584 16.1a1 1 0 10-1.168 1.622z\"/><path d=\"M23.771 18.528a1 1 0 00-1.407-.136A16.5 16.5 0 0112 21.664a16.5 16.5 0 01-10.364-3.272 1 1 0 00-1.272 1.544A18.516 18.516 0 0012 23.664a18.516 18.516 0 0011.636-3.728 1 1 0 00.135-1.408zM12 15.914a5.506 5.506 0 005.5-5.5c0-2.367-3.264-7.543-4.667-9.658a1.037 1.037 0 00-1.666 0C9.763 2.871 6.5 8.046 6.5 10.414a5.506 5.506 0 005.5 5.5zm-2.75-6a.5.5 0 01.5.5 2.252 2.252 0 002.25 2.25.5.5 0 010 1 3.254 3.254 0 01-3.25-3.25.5.5 0 01.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});