define("ember-svg-jar/inlined/style-three-pin-pull", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-pull</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.749 7.3 12.286 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.245 22.5 9.5 22.5 4.262 17.79 0 12 0zm2.5 3A1.5 1.5 0 1113 4.5 1.5 1.5 0 0114.5 3zm.633 12.236a.387.387 0 00.367.264 2.873 2.873 0 001.97-.78.75.75 0 011.06 1.06A4.356 4.356 0 0115.5 17H8a.75.75 0 010-1.5h.908a.5.5 0 00.471-.332l2.113-5.918a.5.5 0 00-.532-.665 2.184 2.184 0 00-.582.158 2.675 2.675 0 00-1.429 1.573 1.022 1.022 0 01-1.265.633.982.982 0 01-.666-.895.5.5 0 00-.243-.406L4.743 8.429a.5.5 0 11.514-.858l1.751 1.051a.5.5 0 00.665-.14A4.4 4.4 0 0113.429 7.1a1 1 0 01.512 1.238l-.515 1.444a.5.5 0 000 .326z\"/><path d=\"M13.052 15.318l-.546-1.636a.25.25 0 00-.472-.005l-.593 1.659a.124.124 0 00.016.112.121.121 0 00.1.052h1.364a.138.138 0 00.112-.057.14.14 0 00.019-.125z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});