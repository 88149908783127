define("ember-svg-jar/inlined/3d-print-candy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>3d-print-candy</title><path d=\"M17.566 13.482a.75.75 0 10-1.037-1.082.761.761 0 01-.723.185l-1.013-.135a.249.249 0 01-.215-.216l-.143-1.119a.716.716 0 01.2-.642.75.75 0 10-1.084-1.037 2.167 2.167 0 00-.589 1.956l.106.828-.733-.1a2.208 2.208 0 00-2.081.608 2.175 2.175 0 00-.626 2l.108.82L9 15.449a2.212 2.212 0 00-2.057.586.75.75 0 101.041 1.075.767.767 0 01.731-.191l1.036.138a.25.25 0 01.215.215l.147 1.112a.716.716 0 01-.207.649.75.75 0 001.076 1.046 2.176 2.176 0 00.6-1.974l-.109-.819a9.969 9.969 0 001.217.156 2.259 2.259 0 001.586-.67 2.175 2.175 0 00.625-1.994l-.105-.82.723.1a2.215 2.215 0 002.047-.576zm-4.133 1.571a.769.769 0 01-.954.858l-1.016-.135a.25.25 0 01-.215-.215L11.1 14.45a.769.769 0 01.953-.858l1.022.136a.249.249 0 01.214.216z\"/><path d=\"M24 2.54a1 1 0 00-1-1l-8.251-.04a.25.25 0 01-.249-.25V.5A.5.5 0 0014 0h-4a.5.5 0 00-.5.5v.75a.25.25 0 01-.25.25H1a1 1 0 000 2h8.546a.252.252 0 01.223.136 2.475 2.475 0 001.109 1.082.218.218 0 01.122.2v.054a.093.093 0 01-.083.093 9.495 9.495 0 1010.476 8.011 1 1 0 00-1.977.3A7.5 7.5 0 1112 7a1 1 0 001-1V4.933a.25.25 0 01.137-.223 2.468 2.468 0 001.093-1.072.251.251 0 01.224-.136L23 3.536a1 1 0 001-.996z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});