define("ember-svg-jar/inlined/direction-button-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>direction-button-1-alternate</title><path d=\"M21 6.5h-3.5V3a3 3 0 00-3-3h-5a3 3 0 00-3 3v3.5H3a3 3 0 00-3 3v5a3 3 0 003 3h3.5V21a3 3 0 003 3h5a3 3 0 003-3v-3.5H21a3 3 0 003-3v-5a3 3 0 00-3-3zm1 8a1 1 0 01-1 1h-4.5a1 1 0 00-1 1V21a1 1 0 01-1 1h-5a1 1 0 01-1-1v-4.5a1 1 0 00-1-1H3a1 1 0 01-1-1v-5a1 1 0 011-1h4.5a1 1 0 001-1V3a1 1 0 011-1h5a1 1 0 011 1v4.5a1 1 0 001 1H21a1 1 0 011 1z\"/><path d=\"M13 4.084a1.3 1.3 0 00-2 0L9.9 5.55a.75.75 0 00.6 1.2h3a.75.75 0 00.6-1.2zM13.5 17.25h-3a.75.75 0 00-.6 1.2l1.1 1.466a1.249 1.249 0 002 0l1.1-1.467a.75.75 0 00-.6-1.2zM6.335 9.829a.748.748 0 00-.785.071L4.083 11a1.25 1.25 0 000 2l1.467 1.1a.75.75 0 001.2-.6v-3a.751.751 0 00-.415-.671zM19.917 11L18.45 9.9a.75.75 0 00-1.2.6v3a.75.75 0 001.2.6l1.467-1.1a1.25 1.25 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});