define("ember-svg-jar/inlined/medical-room-wait", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-room-wait</title><path d=\"M19.285 12.987a.25.25 0 00.091-.414 4.45 4.45 0 00-3.126-1.292h-1a4.449 4.449 0 00-3.127 1.293.25.25 0 00.092.414l3.451 1.232a.255.255 0 00.168 0z\"/><path d=\"M21.25 20.281a1.5 1.5 0 01-1.5-1.5v-2a1.5 1.5 0 011.5-1.5h1c.077 0 .152 0 .227.01a.249.249 0 00.273-.249V13.49a.5.5 0 00-.664-.471c-.037.027-6.168 2.233-6.168 2.233a.5.5 0 01-.336 0l-6.164-2.233a.5.5 0 00-.668.471v1.552a.255.255 0 00.081.185.251.251 0 00.192.064c.075-.007.15-.01.227-.01h1a1.5 1.5 0 011.5 1.5v2a1.5 1.5 0 01-1.5 1.5h-1c-.077 0-.152 0-.227-.01a.249.249 0 00-.273.249v.409a.5.5 0 00.332.47l6.332 2.262a1.006 1.006 0 00.672 0l6.332-2.261a.5.5 0 00.332-.47v-.41a.255.255 0 00-.081-.185.251.251 0 00-.192-.064 2.83 2.83 0 01-.227.01z\"/><circle cx=\"15.75\" cy=\"7.281\" r=\"2.5\"/><path d=\"M10.25 19.281a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-1a1.5 1.5 0 000 3zM23.494 16.942a1.544 1.544 0 00-1.244-.661h-1a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h1a1.5 1.5 0 001.244-2.339zM10.25 6.531v-2.5a.5.5 0 00-.5-.5h-2.5a.25.25 0 01-.25-.25v-2.5a.5.5 0 00-.5-.5H4a.5.5 0 00-.5.5v2.5a.25.25 0 01-.25.25H.75a.5.5 0 00-.5.5v2.5a.5.5 0 00.5.5h2.5a.25.25 0 01.25.25v2.5a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-2.5a.25.25 0 01.25-.25h2.5a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});