define("ember-svg-jar/inlined/christmas-candle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-candle</title><path d=\"M15.851 19.555l-8 2.5a.5.5 0 00-.351.478V23a1 1 0 001 1h7a1 1 0 001-1v-2.967a.5.5 0 00-.649-.478zM16.093 11.924a1 1 0 00-.892-.149l-7 2.188a1 1 0 00-.7.954v5a.5.5 0 00.649.477l8-2.5a.5.5 0 00.351-.477v-4.688a.989.989 0 00-.408-.805zM12 10.978a4.389 4.389 0 004.4-4.39 7.246 7.246 0 00-4.161-6.47 1.268 1.268 0 00-1.493.32 1.285 1.285 0 00-.121 1.545 2.227 2.227 0 01.207.422.254.254 0 01-.034.234.251.251 0 01-.213.1 1.305 1.305 0 00-.637.134 4.035 4.035 0 00-2.334 3.954A4.4 4.4 0 0012 10.978zm-1.83-5.831a.155.155 0 01.229 0 4.893 4.893 0 00.546.513 1 1 0 001.47-.222 3.773 3.773 0 00.548-1.713.25.25 0 01.452-.124 5.141 5.141 0 01.981 2.982c0 3.026-4.622 3.234-4.78.137a2.03 2.03 0 01.55-1.573z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});