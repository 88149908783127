define("ember-svg-jar/inlined/common-file-text-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-text-search</title><path d=\"M10.168 19.787a.242.242 0 01-.031-.3 5.512 5.512 0 10-1.687 1.684.241.241 0 01.3.03l2.505 2.506a1 1 0 001.415-1.414zm-4.668.246a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5zM20 7.5a1 1 0 00-1-1h-7a1 1 0 000 2h7a1 1 0 001-1zM12.5 11a1 1 0 000 2h4a1 1 0 000-2z\"/><path d=\"M23.414 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.3a.243.243 0 00.222.241 6.864 6.864 0 011.453.309A.243.243 0 008 9.624V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.121 2.122a.5.5 0 01.147.353V18a.5.5 0 01-.5.5h-9.124a.245.245 0 00-.233.172 7.139 7.139 0 01-.372.933.4.4 0 00.055.392l.372.372a.335.335 0 00.261.13H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});