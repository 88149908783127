define("ember-svg-jar/inlined/seo-search-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seo-search-graph</title><path d=\"M.061 10.117a1 1 0 00.992.883 1.122 1.122 0 00.115 0 1 1 0 00.879-1.108A7.4 7.4 0 012 9.068a7.067 7.067 0 0111.579-5.44 1 1 0 101.279-1.538 9.067 9.067 0 00-14.8 8.027zM19.536 16.7a1.487 1.487 0 00-1.06-.44 1.5 1.5 0 00-.505.087.251.251 0 01-.26-.059l-1.4-1.4a.251.251 0 01-.022-.328 9.065 9.065 0 001.4-2.671 1 1 0 00-1.9-.622 7.076 7.076 0 01-9.767 4.176 1 1 0 00-.864 1.8 9.092 9.092 0 009.413-.965.25.25 0 01.328.022l1.4 1.4a.252.252 0 01.059.261 1.5 1.5 0 00.351 1.564l3.879 3.883a2 2 0 002.828-2.829z\"/><path d=\"M23.5.008h-4a.5.5 0 00-.354.853l1.147 1.147a.249.249 0 010 .353l-6.6 6.617a.251.251 0 01-.354 0l-2.409-2.41a2 2 0 00-2.828 0L.3 14.361a1 1 0 101.414 1.414l7.62-7.616a.252.252 0 01.354 0l2.412 2.409a2.047 2.047 0 002.828 0l6.782-6.793a.252.252 0 01.354 0l1.085 1.086a.5.5 0 00.354.147.489.489 0 00.191-.038.5.5 0 00.306-.462v-4a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});