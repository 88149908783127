define("ember-svg-jar/inlined/smiley-head-patch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-head-patch</title><path d=\"M12 0c-.135 0-.27 0-.4.007a.116.116 0 00-.1.071.115.115 0 00.021.122 5.8 5.8 0 010 8.206l-3.115 3.115a5.809 5.809 0 01-8.206 0 .112.112 0 00-.121-.021.113.113 0 00-.072.1C0 11.731 0 11.865 0 12A12 12 0 1012 0zm7 7c-1.153 0-3.142-.547-3.895-2.053a1 1 0 011.79-.894A2.656 2.656 0 0019 5a1 1 0 010 2zm-2.5 1A1.5 1.5 0 1115 9.5 1.5 1.5 0 0116.5 8zm-9.338 9.288a5.774 5.774 0 019.676 0 1 1 0 01-1.676 1.093 3.772 3.772 0 00-6.324 0 1 1 0 01-1.676-1.093z\"/><path d=\"M1.261 10.46a4.308 4.308 0 006.085 0l3.114-3.114a4.3 4.3 0 00-6.085-6.085L1.261 4.375a4.3 4.3 0 000 6.085zm4.528-7.785a2.3 2.3 0 113.257 3.257L5.932 9.046a2.3 2.3 0 11-3.257-3.257z\"/><rect x=\"4.209\" y=\"4.759\" width=\"3.303\" height=\"2.202\" rx=\".5\" ry=\".5\" transform=\"rotate(-45.016 5.861 5.86)\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});