define("ember-svg-jar/inlined/shopping-basket-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-basket-edit</title><path d=\"M18.043 10.438a.262.262 0 00.065-.113.5.5 0 00-.483-.627H2.619a.5.5 0 00-.389.184.506.506 0 00-.1.419l1.276 5.994a1.957 1.957 0 001.966 1.448h5.265a.247.247 0 00.176-.074zm-7.8 1.417a.5.5 0 01.5.5v2.386a.5.5 0 01-1 0v-2.386a.5.5 0 01.497-.5zm-3.372 3.53a.48.48 0 01-.085.007.5.5 0 01-.492-.416l-.411-2.386a.5.5 0 01.986-.168l.408 2.386a.5.5 0 01-.409.577zM20 6.908a1.441 1.441 0 00-1.439-1.44h-1.139a.25.25 0 01-.221-.134l-2.113-4.055a1 1 0 00-1.774.923l1.511 2.9a.246.246 0 01-.008.245.25.25 0 01-.213.12H5.641a.25.25 0 01-.222-.367L6.93 2.2a1 1 0 00-1.774-.923L3.044 5.334a.253.253 0 01-.222.134H1.68a1.439 1.439 0 100 2.878h16.884A1.44 1.44 0 0020 6.908zM15.187 22.034a.5.5 0 00-.131-.479l-2.129-2.13a.5.5 0 00-.834.216l-.853 2.981a.5.5 0 00.618.619l2.982-.852a.5.5 0 00.347-.355zM16.439 21.426a.5.5 0 00.354-.146l4.818-4.819a.5.5 0 000-.707l-2.884-2.885a.5.5 0 00-.707 0l-4.82 4.819a.5.5 0 000 .707l2.885 2.885a.5.5 0 00.354.146zM19.379 11.51a.5.5 0 000 .707l2.885 2.883a.461.461 0 00.329.126.615.615 0 00.423-.171 2.54 2.54 0 10-3.637-3.547z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});