define("ember-svg-jar/inlined/goods-dots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Group 21</title><g fill=\"none\" fill-rule=\"evenodd\"><g transform=\"translate(175.605)\"><circle fill=\"#5B75E8\" cx=\"33.729\" cy=\"337.286\" r=\"33.729\"/><circle fill=\"#698EEA\" cx=\"33.729\" cy=\"236.1\" r=\"33.729\"/><circle fill=\"#698EEA\" cx=\"33.729\" cy=\"134.914\" r=\"33.729\"/><circle fill=\"#698EEA\" cx=\"33.729\" cy=\"33.729\" r=\"33.729\"/><circle fill=\"#435BC8\" cx=\"33.729\" cy=\"438.472\" r=\"33.729\"/></g><g transform=\"translate(87.91 53.966)\"><circle fill=\"#435BC8\" cx=\"33.729\" cy=\"337.286\" r=\"33.729\"/><circle fill=\"#5B75E8\" cx=\"33.729\" cy=\"236.1\" r=\"33.729\"/><circle fill=\"#698EEA\" cx=\"33.729\" cy=\"134.914\" r=\"33.729\"/><circle fill=\"#698EEA\" cx=\"33.729\" cy=\"33.729\" r=\"33.729\"/></g><g transform=\"translate(263.3 53.966)\"><circle fill=\"#435BC8\" cx=\"33.729\" cy=\"337.286\" r=\"33.729\"/><circle fill=\"#5B75E8\" cx=\"33.729\" cy=\"236.1\" r=\"33.729\"/><circle fill=\"#698EEA\" cx=\"33.729\" cy=\"134.914\" r=\"33.729\"/><circle fill=\"#698EEA\" cx=\"33.729\" cy=\"33.729\" r=\"33.729\"/></g><g transform=\"translate(.216 101.186)\"><circle fill=\"#435BC8\" cx=\"33.729\" cy=\"236.1\" r=\"33.729\"/><circle fill=\"#5B75E8\" cx=\"33.729\" cy=\"134.914\" r=\"33.729\"/><circle fill=\"#698EEA\" cx=\"33.729\" cy=\"33.729\" r=\"33.729\"/></g><g transform=\"translate(350.993 101.186)\"><circle fill=\"#435BC8\" cx=\"33.729\" cy=\"236.1\" r=\"33.729\"/><circle fill=\"#5B75E8\" cx=\"33.729\" cy=\"134.914\" r=\"33.729\"/><circle fill=\"#698EEA\" cx=\"33.729\" cy=\"33.729\" r=\"33.729\"/></g></g>",
    "attrs": {
      "width": "419",
      "height": "473",
      "viewBox": "0 0 419 473",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});