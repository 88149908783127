define("ember-svg-jar/inlined/messaging-line-app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messaging-line-app</title><path d=\"M12 1.15C5.38 1.15 0 5.59 0 11a9 9 0 003.18 6.71.25.25 0 01.06.3L1.56 21.4a1 1 0 00.9 1.45 1 1 0 00.42-.09l5-2.31a.5.5 0 01.34 0 14.34 14.34 0 003.81.51c6.62 0 12-4.43 12-9.88S18.62 1.15 12 1.15zM7.25 13.9a.75.75 0 01-.75.75h-3a.75.75 0 01-.75-.75v-6a.75.75 0 011.5 0v5a.25.25 0 00.25.25h2a.75.75 0 01.75.75zm2.5 0a.75.75 0 01-1.5 0v-6a.75.75 0 011.5 0zm6.5 0a.75.75 0 01-.53.72.76.76 0 01-.22 0 .75.75 0 01-.62-.33l-2.17-3.25a.25.25 0 00-.46.14v2.7a.75.75 0 01-1.5 0v-6a.75.75 0 011.37-.42l2.17 3.25a.25.25 0 00.46-.14V7.9a.75.75 0 011.5 0zm4.75-.75a.75.75 0 010 1.5h-3a.75.75 0 01-.75-.75v-6a.75.75 0 01.75-.75h3a.75.75 0 010 1.5h-2a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h2a.75.75 0 010 1.5h-2a.25.25 0 00-.25.25v1a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});