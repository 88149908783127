define("ember-svg-jar/inlined/single-neutral-monitor.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-monitor</title><path d=\"M22.5 1h-21A1.5 1.5 0 000 2.5V17a1.5 1.5 0 001.5 1.5h9a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5H4a1 1 0 000 2h16a1 1 0 000-2h-6.5a.5.5 0 01-.5-.5V19a.5.5 0 01.5-.5h9A1.5 1.5 0 0024 17V2.5A1.5 1.5 0 0022.5 1zM11.992 4a3 3 0 11-3.011 3 3.006 3.006 0 013.011-3zm4.923 11.9a.313.313 0 01-.229.1H7.5a.5.5 0 01-.5-.556 5.021 5.021 0 0110 .223.313.313 0 01-.085.233z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});