define("ember-svg-jar/inlined/single-neutral-actions-music.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-music</title><path d=\"M12.456 13.83a3.318 3.318 0 01.1-.776.5.5 0 00-.142-.48A7.483 7.483 0 00.065 15.857a.5.5 0 00.479.643h11.412a.5.5 0 00.5-.5z\"/><circle cx=\"7.044\" cy=\"4.75\" r=\"4.75\"/><path d=\"M21.456 22a2.5 2.5 0 002.5-2.5v-6.795a2 2 0 00-2.368-1.967l-6 1.125a2 2 0 00-1.631 1.967v4.931a.251.251 0 01-.081.185.248.248 0 01-.191.064 2.856 2.856 0 00-.228-.01 2.5 2.5 0 102.5 2.5v-7.256a.5.5 0 01.408-.491l5-.937a.5.5 0 01.592.491v3.454a.251.251 0 01-.081.185.248.248 0 01-.191.064 2.856 2.856 0 00-.228-.01 2.5 2.5 0 000 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});