define("ember-svg-jar/inlined/road-sign-speed-limit-20", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-speed-limit-20</title><path d=\"M16.245 9.535a1.728 1.728 0 00-1.726 1.727v2.475a1.726 1.726 0 103.452 0v-2.475a1.728 1.728 0 00-1.726-1.727z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM8.484 15.293a.25.25 0 00.237.171h1.952a.75.75 0 010 1.5H5.721a.75.75 0 01-.45-1.35l3.962-2.971a1.727 1.727 0 10-2.762-1.381.75.75 0 01-1.5 0 3.226 3.226 0 115.163 2.58l-1.563 1.172a.25.25 0 00-.087.279zm10.987-1.556a3.226 3.226 0 11-6.452 0v-2.475a3.226 3.226 0 116.452 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});