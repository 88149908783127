define("ember-svg-jar/inlined/business-contract-break", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-contract-break</title><path d=\"M1.926 10.881a.252.252 0 010 .244C.951 12.86.144 14.533.662 15.848l1.555 4.231a.264.264 0 01.014.111L2 22.473a.251.251 0 00.063.192.246.246 0 00.184.083l3.784.025a.249.249 0 00.248-.211 7.972 7.972 0 00.036-2.931L5.8 18.223l1.65-3.665a1.245 1.245 0 00-1.989-1.416.264.264 0 00-.018-.038l-1.279 2.765a.749.749 0 01-.68.435.736.736 0 01-.315-.07.749.749 0 01-.366-1l1.633-3.524a.692.692 0 01.043-.081.249.249 0 00.007-.254L2.094 7.058a.5.5 0 01.163-.658l3.856-2.52a.25.25 0 01.387.209v2.186a1 1 0 001 1h1v3a1 1 0 00.168.555l3 4.5a1 1 0 00.832.445.962.962 0 00.29-.043 1 1 0 00.71-.957v-4.206a.243.243 0 01.027-.112l1.59-3.182H16.5a1 1 0 001-1V4.086a.25.25 0 01.387-.209L21.742 6.4a.5.5 0 01.164.662l-2.781 5.018a.249.249 0 00.039.3.755.755 0 01.142.206l1.633 3.529a.75.75 0 11-1.362.63l-1.231-2.663a1.245 1.245 0 00-2.053 1.353L17.9 19a.248.248 0 01.007.189l-.478 1.311a4.334 4.334 0 000 2.1.251.251 0 00.247.2l3.788-.025a.25.25 0 00.246-.283l-.191-1.431a.254.254 0 01.013-.119l1.553-4.227c.538-1.365-.352-3.116-1.375-4.922a.249.249 0 010-.244l2.177-3.93a2.008 2.008 0 00-.656-2.643l-5.4-3.533A1.5 1.5 0 0015.5 2.7v2.325a.25.25 0 01-.25.25h-.441a1.494 1.494 0 00-1.342.829L11.659 9.72a1.5 1.5 0 00-.159.673v.253a.25.25 0 01-.458.139l-.542-.812v-3.2A1.5 1.5 0 009 5.275h-.25a.25.25 0 01-.25-.25V2.7a1.5 1.5 0 00-2.32-1.256L.774 4.979a2.007 2.007 0 00-.655 2.642z\"/><path d=\"M8.743 21.775a.99.99 0 00.545-.162l2.952-1.925a.252.252 0 01.268 0l2.1 1.3a1 1 0 101.054-1.7l-2.692-1.67a1.014 1.014 0 00-1.237.019L8.2 19.938a1 1 0 00.547 1.837z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});