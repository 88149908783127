define("ember-svg-jar/inlined/common-file-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-remove</title><path d=\"M23.414 3L21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.275a.248.248 0 00.242.25c.366.006 1.038.033 1.485.083A.246.246 0 008 9.36V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.245.245 0 00-.241.21 7.956 7.956 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M6.5 11a6.5 6.5 0 106.5 6.5A6.508 6.508 0 006.5 11zm1.237 6.677l1.414 1.414a.75.75 0 11-1.06 1.06l-1.414-1.414a.252.252 0 00-.354 0l-1.414 1.414a.75.75 0 11-1.06-1.06l1.414-1.414a.252.252 0 000-.354l-1.414-1.414a.75.75 0 011.06-1.06l1.414 1.414a.252.252 0 00.354 0l1.414-1.414a.75.75 0 011.06 1.06l-1.414 1.414a.252.252 0 000 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});