define("ember-svg-jar/inlined/design-file-indd-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-indd-1</title><path d=\"M18.456 12.319a.249.249 0 00-.331.24v3.882a.249.249 0 00.331.24 2.238 2.238 0 001.419-2.181 2.238 2.238 0 00-1.419-2.181zM13.456 12.319a.249.249 0 00-.331.24v3.882a.249.249 0 00.331.24 2.238 2.238 0 001.419-2.181 2.238 2.238 0 00-1.419-2.181z\"/><path d=\"M24 6.415a1 1 0 00-.293-.708L18.293.293A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.75V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H1.2A1.114 1.114 0 000 10v9a1.114 1.114 0 001.2 1h20.55a.25.25 0 01.25.25v1.25a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2zM5.377 16.875H5.5a.625.625 0 010 1.25h-2a.625.625 0 010-1.25h.126a.249.249 0 00.249-.249v-4.249a.252.252 0 00-.252-.252H3.5a.625.625 0 010-1.25h2a.625.625 0 010 1.25h-.126a.249.249 0 00-.249.249v4.249a.252.252 0 00.252.252zm5.748.625a.624.624 0 01-.481.608.59.59 0 01-.144.017.625.625 0 01-.559-.346s-1.492-2.974-1.563-3.127-.253-.116-.253.048v2.8a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279s1.53 3.049 1.586 3.166a.122.122 0 00.23-.071V11.5a.625.625 0 011.25 0zm1.375.625a.625.625 0 01-.625-.625v-6a.625.625 0 01.625-.625 3.625 3.625 0 010 7.25zm5 0a.625.625 0 01-.625-.625v-6a.625.625 0 01.625-.625 3.625 3.625 0 010 7.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});