define("ember-svg-jar/inlined/pollution-o2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pollution-o2</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M13 7a3 3 0 00-6 0v4a3 3 0 006 0zm-2 4a1 1 0 01-2 0V7a1 1 0 012 0zM16.823 16.594a2.307 2.307 0 00.679-1.641 2.452 2.452 0 00-4.779-.776 1 1 0 001.9.633.453.453 0 01.882.143.321.321 0 01-.093.227L12.8 17.793a1 1 0 00.7 1.707h3a1 1 0 000-2 .242.242 0 01-.171-.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});