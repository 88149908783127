define("ember-svg-jar/inlined/road-sign-railway-ahead", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-railway-ahead</title><rect x=\"10.75\" y=\"11.75\" width=\"2.5\" height=\"1.5\" rx=\".25\" ry=\".25\"/><rect x=\"10.75\" y=\"14.75\" width=\"2.5\" height=\"1.5\" rx=\".25\" ry=\".25\"/><rect x=\"10.75\" y=\"8.75\" width=\"2.5\" height=\"1.5\" rx=\".25\" ry=\".25\"/><path d=\"M23.53 11.47l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0l11-11a.749.749 0 000-1.06zM15 10.25a.75.75 0 010 1.5.25.25 0 00-.25.25v1a.25.25 0 00.25.25.75.75 0 010 1.5.25.25 0 00-.25.25v1a.25.25 0 00.25.25.75.75 0 010 1.5.25.25 0 00-.25.25.75.75 0 01-1.5 0 .25.25 0 00-.25-.25h-2a.25.25 0 00-.25.25.75.75 0 01-1.5 0 .25.25 0 00-.25-.25.75.75 0 010-1.5.25.25 0 00.25-.25v-1a.25.25 0 00-.25-.25.75.75 0 010-1.5.25.25 0 00.25-.25v-1a.25.25 0 00-.25-.25.75.75 0 010-1.5.25.25 0 00.25-.25V9A.25.25 0 009 8.75a.75.75 0 010-1.5.25.25 0 00.25-.25v-.5a.75.75 0 011.5 0V7a.25.25 0 00.25.25h2a.25.25 0 00.25-.25v-.5a.75.75 0 011.5 0V7a.25.25 0 00.25.25.75.75 0 010 1.5.25.25 0 00-.25.25v1a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});