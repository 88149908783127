define("ember-svg-jar/inlined/single-neutral-actions-shield.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-shield</title><path d=\"M9.5 13.456a3.015 3.015 0 01.539-1.718.5.5 0 00-.234-.751 7.3 7.3 0 00-2.6-.487 7.543 7.543 0 00-7.184 5.357.5.5 0 00.479.643H9a.5.5 0 00.5-.5z\"/><circle cx=\"7\" cy=\"4.75\" r=\"4.75\"/><path d=\"M23.072 12.04A16.393 16.393 0 0017.478 11a15.992 15.992 0 00-5.55 1.04A1.545 1.545 0 0011 13.456v3.393a7.716 7.716 0 005.19 6.805 3.2 3.2 0 001.288.346 3.269 3.269 0 001.289-.346C21.848 22.48 24 19.682 24 16.849v-3.393a1.545 1.545 0 00-.928-1.416zm-4.822 3.21a.5.5 0 00.5.5h1.75a.75.75 0 010 1.5h-1.75a.5.5 0 00-.5.5v1.75a.75.75 0 01-1.5 0v-1.75a.5.5 0 00-.5-.5H14.5a.75.75 0 010-1.5h1.75a.5.5 0 00.5-.5V13.5a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});