define("ember-svg-jar/inlined/shark-fin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shark-fin</title><path d=\"M10.449 10.887a2.5 2.5 0 014.287.389 1.276 1.276 0 001.05.627 1.863 1.863 0 001.476-.845 2.369 2.369 0 011.422-1.108l.054-.77c.132-1.864.255-5.1-.4-5.8a.708.708 0 00-.516-.232c-4.391 0-7.2 5.541-7.315 5.777l-1.447 2.91a3.119 3.119 0 001.389-.948zM22.931 18.81c-1.582.141-2.669-1.556-2.677-1.57a1.047 1.047 0 00-1.715.023 3.375 3.375 0 01-2.753 1.557 2.8 2.8 0 01-2.4-1.481 1.043 1.043 0 00-1.717-.156 4.308 4.308 0 01-3.1 1.637A3.555 3.555 0 015.8 17.24a1.042 1.042 0 00-1.638-.091c-.014.018-1.464 1.811-3.11 1.7A.99.99 0 000 19.8c-.01.189.043 1.057 1.168 1.057A5.833 5.833 0 004.9 19.248a5.24 5.24 0 003.66 1.572 5.855 5.855 0 003.81-1.5 4.578 4.578 0 003.416 1.5 5.155 5.155 0 003.622-1.5 4.806 4.806 0 003.661 1.483 1 1 0 00-.138-2z\"/><path d=\"M8.56 15.4a5.855 5.855 0 003.81-1.5 4.578 4.578 0 003.416 1.5 5.155 5.155 0 003.622-1.5 4.784 4.784 0 003.661 1.483 1 1 0 00-.138-2c-1.582.128-2.669-1.556-2.677-1.57a1.047 1.047 0 00-1.715.023 3.375 3.375 0 01-2.753 1.564 2.8 2.8 0 01-2.4-1.481 1.042 1.042 0 00-1.717-.156A4.308 4.308 0 018.56 13.4a3.555 3.555 0 01-2.76-1.58 1.042 1.042 0 00-1.638-.091c-.015.019-1.467 1.83-3.11 1.7A1 1 0 000 14.381c-.01.189.043 1.057 1.168 1.057A5.833 5.833 0 004.9 13.831 5.24 5.24 0 008.56 15.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});