define("ember-svg-jar/inlined/data-file-bars-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-bars-home</title><rect x=\"14.5\" y=\"5.5\" width=\"2\" height=\"8\" rx=\".5\" ry=\".5\"/><rect x=\"18\" y=\"7.5\" width=\"2\" height=\"6\" rx=\".5\" ry=\".5\"/><path d=\"M21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.868a.251.251 0 00.33.233 2.489 2.489 0 011.34 0A.25.25 0 008 9.868V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-5.4a.237.237 0 00-.21.117 12.43 12.43 0 01-1.415 1.449.25.25 0 00.171.433H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M12.161 13.5h.339a.5.5 0 00.5-.5v-2.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v2a.25.25 0 00.089.191l.84.711c-.049-.047.122.098.232.098zM7.324 12.119a.5.5 0 00-.647 0l-6.5 5.5A.5.5 0 00.5 18.5h1.25a.249.249 0 01.25.25v4.75a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-4.75a.249.249 0 01.25-.25h1.25a.5.5 0 00.324-.882z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});