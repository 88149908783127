define("ember-svg-jar/inlined/cd-album-note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cd-album-note</title><path d=\"M24 3.5a2 2 0 00-2-2H2a2 2 0 00-2 2v17a2 2 0 002 2h20a2 2 0 002-2zM22 20a.5.5 0 01-.5.5h-16A.5.5 0 015 20V4a.5.5 0 01.5-.5h16a.5.5 0 01.5.5z\"/><path d=\"M9.5 18.5a2.5 2.5 0 002.5-2.448V9.04a.5.5 0 01.408-.491l5-.938A.5.5 0 0118 8.1v3.159a.255.255 0 01-.081.185.251.251 0 01-.192.064 2.83 2.83 0 00-.227-.01 2.5 2.5 0 102.5 2.554V7.5a2 2 0 00-2.368-1.966l-6 1.125A2 2 0 0010 8.625v4.636a.255.255 0 01-.081.185.251.251 0 01-.192.064 2.83 2.83 0 00-.227-.01 2.5 2.5 0 000 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});