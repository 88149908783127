define("ember-svg-jar/inlined/insect-head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>insect-head</title><path d=\"M17 9.38a2.844 2.844 0 00-.964.188c.053-.337-.351-5.686 1.569-7.222A1.25 1.25 0 1016.043.393c-2.107 1.686-2.488 5.281-2.514 7.429a.249.249 0 01-.1.2.246.246 0 01-.214.043 5.439 5.439 0 00-2.783 0 .247.247 0 01-.215-.043.251.251 0 01-.1-.2c-.024-2.148-.405-5.743-2.512-7.429a1.25 1.25 0 00-1.562 1.953C7.953 3.873 7.59 9.317 7.6 9.455A2.9 2.9 0 007 9.38c-1.963 0-3.5 1.977-3.5 4.5 0 2.446 1.448 4.361 3.324 4.478v1.522c0 2.206 2.243 4 5 4s5-1.794 5-4v-1.522c.06 0 .116.022.176.022 1.963 0 3.5-1.976 3.5-4.5s-1.537-4.5-3.5-4.5zm0 7c-.71 0-1.5-1.026-1.5-2.5s.79-2.5 1.5-2.5 1.5 1.026 1.5 2.5-.79 2.5-1.5 2.5zm-10-5c.71 0 1.5 1.026 1.5 2.5s-.79 2.5-1.5 2.5-1.5-1.026-1.5-2.5.79-2.5 1.5-2.5zm5.574 5.239a.25.25 0 01.25-.25h1.1a.251.251 0 01.218.129 4.149 4.149 0 00.616.835.251.251 0 01.068.172v2.375c0 .835-.818 1.564-1.936 1.857a.253.253 0 01-.216-.044.249.249 0 01-.1-.2zm-3.75 1.223a.252.252 0 01.091-.193A3.958 3.958 0 009.86 16.5a.25.25 0 01.219-.13h.745a.25.25 0 01.25.25v4.88a.25.25 0 01-.313.242c-1.118-.293-1.937-1.022-1.937-1.857z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});