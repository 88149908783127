define("ember-svg-jar/inlined/love-it-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it-add</title><path d=\"M9.5 17.144a.244.244 0 00-.066-.178l-6.1-6.609a4.42 4.42 0 01-.85-5.16 4.493 4.493 0 013.326-2.42A4.575 4.575 0 019.8 4.06l1.531 1.4a1 1 0 001.347 0l1.561-1.424a4.546 4.546 0 013.965-1.256 4.5 4.5 0 013.333 2.42 4.173 4.173 0 01-.137 4.052.254.254 0 00-.022.2.248.248 0 00.129.151 8.019 8.019 0 011.241.754.247.247 0 00.2.043.251.251 0 00.166-.119 6.194 6.194 0 00.205-5.986 6.587 6.587 0 00-10.452-1.713l-.858.783-.829-.755A6.524 6.524 0 005.489.8 6.476 6.476 0 00.7 4.293a6.4 6.4 0 001.2 7.454L9 19.433a.25.25 0 00.433-.16z\"/><path d=\"M17.5 10.286a6.5 6.5 0 106.5 6.5 6.507 6.507 0 00-6.5-6.5zm2.5 5.75a.75.75 0 010 1.5h-1.5a.25.25 0 00-.25.25v1.5a.75.75 0 01-1.5 0v-1.5a.25.25 0 00-.25-.25H15a.75.75 0 110-1.5h1.5a.25.25 0 00.25-.25v-1.5a.75.75 0 111.5 0v1.5a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});