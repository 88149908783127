define("ember-svg-jar/inlined/shipment-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-upload</title><path d=\"M8.413 3.466a.247.247 0 00.247.247h5.3a.247.247 0 00.175-.422L11.279.437A1.5 1.5 0 0010.229 0H8.66a.246.246 0 00-.247.25zM6.681 3.713a.247.247 0 00.247-.247V.25A.246.246 0 006.681 0H5.112a1.5 1.5 0 00-1.05.434L1.209 3.291a.247.247 0 00.175.422zM14.908 9.2a.247.247 0 00.184-.239V5.445a.248.248 0 00-.248-.248H.5a.247.247 0 00-.247.248v8.9a1.485 1.485 0 001.484 1.484H8.6a.246.246 0 00.242-.2A8.427 8.427 0 0114.908 9.2z\"/><path d=\"M17.072 10.639a6.679 6.679 0 106.678 6.679 6.686 6.686 0 00-6.678-6.679zm-.99 9.9v-2.726a.248.248 0 00-.247-.247H14.7a.5.5 0 01-.386-.8l2.375-2.969a.513.513 0 01.773 0l2.375 2.969a.5.5 0 01-.386.8h-1.143a.248.248 0 00-.247.247v2.721a.99.99 0 11-1.979 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});