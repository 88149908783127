define("ember-svg-jar/inlined/ecology-globe-message-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ecology-globe-message-1</title><path d=\"M12 3.875A7.125 7.125 0 1019.125 11 7.133 7.133 0 0012 3.875zm-1.238 4.149a8.493 8.493 0 011.043-1.757.251.251 0 01.4 0 8.4 8.4 0 011.044 1.758.25.25 0 01-.229.35h-2.029a.25.25 0 01-.229-.351zm-3.209-.04a5.425 5.425 0 011.454-1.447.25.25 0 01.363.32 9.575 9.575 0 00-.5 1.18.5.5 0 01-.473.338H7.76a.251.251 0 01-.207-.391zm-.912 3.43a5.449 5.449 0 010-.828.5.5 0 01.5-.461h.743a.5.5 0 01.361.153.5.5 0 01.139.367 9.312 9.312 0 000 .709.5.5 0 01-.5.521h-.745a.5.5 0 01-.498-.461zm2.67 4.028a.25.25 0 01-.3.02 5.425 5.425 0 01-1.454-1.447.25.25 0 01.207-.39H8.4a.5.5 0 01.473.337 9.575 9.575 0 00.5 1.18.249.249 0 01-.062.3zm3.928-1.467a8.537 8.537 0 01-1.039 1.757.251.251 0 01-.4 0 8.4 8.4 0 01-1.044-1.758.249.249 0 01.23-.349h2.024a.249.249 0 01.209.113.253.253 0 01.02.237zm.625-2.572a.5.5 0 01-.5.472h-2.729a.5.5 0 01-.5-.472 7.008 7.008 0 010-.807.5.5 0 01.5-.471h2.729a.5.5 0 01.5.471 7.242 7.242 0 010 .804zm.825-4.846a.251.251 0 01.3-.02 5.435 5.435 0 011.453 1.447.251.251 0 01-.207.391H15.6a.5.5 0 01-.473-.338 9.575 9.575 0 00-.5-1.18.249.249 0 01.062-.3zm1.758 7.458a5.435 5.435 0 01-1.453 1.447.251.251 0 01-.364-.32 9.575 9.575 0 00.5-1.18.5.5 0 01.473-.337h.64a.25.25 0 01.207.39zm.912-3.429a5.323 5.323 0 010 .828.5.5 0 01-.5.461h-.743a.5.5 0 01-.5-.521q.014-.354 0-.71a.5.5 0 01.5-.519h.743a.5.5 0 01.5.461z\"/><path d=\"M12 .75C5.383.75 0 5.348 0 11a9.308 9.308 0 002.783 6.57.5.5 0 01.076.6L1.2 21.012a1.488 1.488 0 001.873 2.117l5.267-2.257a.507.507 0 01.317-.025 13.962 13.962 0 003.343.4c6.617 0 12-4.6 12-10.25S18.617.75 12 .75zm0 18.5a11.87 11.87 0 01-3.3-.462 1 1 0 00-.672.042l-3.621 1.552a.249.249 0 01-.286-.064.252.252 0 01-.029-.292l1.1-1.891a1 1 0 00-.231-1.278A7.554 7.554 0 012 11c0-4.55 4.486-8.25 10-8.25S22 6.45 22 11s-4.486 8.25-10 8.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});