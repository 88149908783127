define("ember-svg-jar/inlined/scroll-horizontal-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>scroll-horizontal-1</title><circle cx=\"12.086\" cy=\"11.997\" r=\"2.5\"/><path d=\"M23.669 11.254l-5-4.5A1 1 0 0017 7.5v9a1 1 0 001.669.743l5-4.5a1 1 0 000-1.486zM6.407 6.584a1 1 0 00-1.076.17l-5 4.5a1 1 0 000 1.486l5 4.5A1 1 0 007 16.5v-9a1 1 0 00-.593-.916z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});