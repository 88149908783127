define("ember-svg-jar/inlined/office-stamp-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-stamp-document</title><path d=\"M19.5 6h3.9a.25.25 0 00.177-.427L18.427.425A.25.25 0 0018 .6v3.9A1.5 1.5 0 0019.5 6z\"/><path d=\"M17 4.5V.248A.25.25 0 0016.75 0H6a2 2 0 00-2 2v5.082a.251.251 0 00.342.233A4.5 4.5 0 018.7 15.1a.506.506 0 00-.2.39v.069A.491.491 0 009 16h1a3.5 3.5 0 013.5 3.5 2.5 2.5 0 01-.941 1.953.247.247 0 00-.089.245 1.586 1.586 0 01.03.3v1a2.48 2.48 0 01-.1.682.25.25 0 00.241.318H22a2 2 0 002-2V7.248A.25.25 0 0023.75 7H19.5A2.5 2.5 0 0117 4.5zM11 23v-.75a.25.25 0 00-.25-.25h-9.5a.25.25 0 00-.25.25V23a1 1 0 001 1h8a1 1 0 001-1z\"/><path d=\"M1 20.5h10a1 1 0 001-1 2 2 0 00-2-2H9a2 2 0 01-2-1.942 2.006 2.006 0 01.8-1.658 3 3 0 10-3.6 0 2.008 2.008 0 01.8 1.652A2 2 0 013 17.5H2a2 2 0 00-2 2 1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});