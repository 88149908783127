define("ember-svg-jar/inlined/artboard-image-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>artboard-image-alternate</title><path d=\"M23 17h-1.75a.25.25 0 01-.25-.25V4.5A1.5 1.5 0 0019.5 3H7.25A.25.25 0 017 2.75V1a1 1 0 00-1-1H4a1 1 0 00-1 1v1.75a.25.25 0 01-.25.25H1a1 1 0 00-1 1v2a1 1 0 001 1h1.75a.25.25 0 01.25.25V19.5A1.5 1.5 0 004.5 21h12.25a.25.25 0 01.25.25V23a1 1 0 001 1h2a1 1 0 001-1v-1.75a.25.25 0 01.25-.25H23a1 1 0 001-1v-2a1 1 0 00-1-1zm-5.5-3.568a.25.25 0 01-.482.093L15.2 8.972a.751.751 0 00-.671-.472.74.74 0 00-.7.426l-2.358 4.911a.25.25 0 01-.41.06l-1.5-1.651a.749.749 0 00-1.206.132L6.967 14.8a.25.25 0 01-.467-.124V7a.5.5 0 01.5-.5h10a.5.5 0 01.5.5zm-.645 3.725a.25.25 0 01-.232.343H7.58a.25.25 0 01-.217-.374l1.6-2.809a.25.25 0 01.4-.044L10.945 16a.749.749 0 001.231-.18l2.015-4.2a.251.251 0 01.458.016z\"/><circle cx=\"10\" cy=\"9\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});