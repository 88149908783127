define("ember-svg-jar/inlined/computer-bug-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>computer-bug-search</title><path d=\"M14.312 5.52a.75.75 0 00-1.5 0 1.493 1.493 0 01-.512 1.12.252.252 0 01-.251.05 3.013 3.013 0 00-.985-.17 2.913 2.913 0 00-.956.165.251.251 0 01-.245-.047 1.489 1.489 0 01-.551-1.118.75.75 0 10-1.5 0 2.967 2.967 0 00.782 1.98.25.25 0 01.018.311 2.973 2.973 0 00-.537 1.435.25.25 0 00.25.274H13.8a.25.25 0 00.249-.274 2.968 2.968 0 00-.538-1.436.249.249 0 01.019-.31 2.968 2.968 0 00.782-1.98zM6.562 14.27a.75.75 0 000 1.5h1.625a.251.251 0 01.222.134 2.987 2.987 0 005.307 0 .251.251 0 01.222-.134h1.624a.75.75 0 000-1.5h-1.25a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h1.25a.75.75 0 000-1.5h-1.25a.25.25 0 01-.25-.25v-.25a.249.249 0 00-.25-.25h-5.5a.25.25 0 00-.25.25v.25a.249.249 0 01-.25.25h-1.25a.75.75 0 000 1.5h1.25a.249.249 0 01.25.25v1a.249.249 0 01-.25.25z\"/><path d=\"M0 11.005a10.979 10.979 0 0017.32 8.984.5.5 0 01.644.056l3.475 3.511a1.5 1.5 0 002.122-2.121l-3.488-3.523a.5.5 0 01-.054-.638A10.993 10.993 0 100 11.005zm11-9a9 9 0 11-9 9 9.01 9.01 0 019-9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});