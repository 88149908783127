define("ember-svg-jar/inlined/dancing-ballet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dancing-ballet</title><path d=\"M7.944 3.168A6.832 6.832 0 017.5.961a1 1 0 00-2 .078 8.722 8.722 0 00.648 3.012.571.571 0 01-.114.663 22.72 22.72 0 00-1.16 1.23.5.5 0 01-.75 0 22.65 22.65 0 00-1.16-1.229.572.572 0 01-.114-.664 8.722 8.722 0 00.65-3.012A1 1 0 002.54 0 1.009 1.009 0 001.5.961a6.832 6.832 0 01-.445 2.207c-.088.178-.175.342-.258.5A5.369 5.369 0 000 6.5V20c0 2.355 1.851 4 4.5 4S9 22.355 9 20V6.5a5.369 5.369 0 00-.8-2.834c-.081-.156-.168-.32-.256-.498zM2 7.212a.25.25 0 01.439-.164c.168.194.341.4.516.607a.5.5 0 01.015.623q-.264.347-.518.7A.25.25 0 012 8.828zM7 15a2.5 2.5 0 01-5 0v-1.4a12.686 12.686 0 012.1-3.473.5.5 0 01.8 0A12.686 12.686 0 017 13.6zm0-6.172a.25.25 0 01-.452.147q-.254-.349-.518-.7a.5.5 0 01.015-.623c.175-.21.348-.413.516-.607A.25.25 0 017 7.212zM23.892 5a3.01 3.01 0 00-1.828-2l-.507-.2a2.929 2.929 0 00-1.057-.3c-.793 0-1.809-1.016-2.3-1.959a1 1 0 00-1.777.918 6.8 6.8 0 001.127 1.564.5.5 0 01-.043.733A5.833 5.833 0 0016.122 5.5a.5.5 0 01-.782.125c-1.272-1.2-2.329-2.5-2.339-3.144V1a1 1 0 00-2 0v3.1c0 1.007-.157 12.405 1.2 17.555A3.036 3.036 0 0015.128 24H16.5a2.547 2.547 0 002.5-2.75c0-7.625 1.591-10.09 4.358-13.591A3.012 3.012 0 0023.892 5zm-8.881 3.39c-.133.462-.254.922-.37 1.367A20.411 20.411 0 0113.612 13a.25.25 0 01-.477-.092 131.538 131.538 0 01-.153-6.182.25.25 0 01.427-.176c.524.527 1.041.986 1.443 1.325a.5.5 0 01.159.515z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});