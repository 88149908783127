define("ember-svg-jar/inlined/tools-flattener-triangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-flattener-triangle</title><path d=\"M8.338 12.01a2.041 2.041 0 000 2.891l.226.226a.5.5 0 00.708 0L10 14.4a.5.5 0 00.006-.7l-.087-.09a.25.25 0 010-.351l3.509-3.509a2.028 2.028 0 000-2.88l-1.206-1.206a.25.25 0 01-.066-.237A1.752 1.752 0 0012.208 5a2.11 2.11 0 00-.6-1.46L8.668.6a2.076 2.076 0 00-2.92 0 2.11 2.11 0 00-.6 1.46 2.049 2.049 0 00.6 1.46L8.7 6.46a2.009 2.009 0 001.45.6 2.031 2.031 0 00.427-.047.252.252 0 01.233.071l.869.9a.5.5 0 01-.007.7zM18.783 22.61l-3.193-8.2a1.819 1.819 0 00-1.308-1.349 2.2 2.2 0 00-2.13.6L8.513 17.3a2.193 2.193 0 00-.595 2.109 1.835 1.835 0 001.311 1.319l8.233 3.2a1.021 1.021 0 001.321-1.321z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});