define("ember-svg-jar/inlined/school-exam-f-fail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>school-exam-f-fail</title><path d=\"M19 0H5a2 2 0 00-2 2v20a2 2 0 002 2h8.638a2 2 0 001.281-.464l5.362-4.469A1.993 1.993 0 0021 17.532V2a2 2 0 00-2-2zm-8.5 19h-3a.75.75 0 010-1.5h3a.75.75 0 010 1.5zm4.5-4H7.5a.75.75 0 010-1.5H15a.75.75 0 010 1.5zm1.25-9.5H14.5a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h.75a.75.75 0 010 1.5h-.75a.5.5 0 00-.5.5v1a.75.75 0 01-1.5 0V5.75A1.752 1.752 0 0114.25 4h2a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});