define("ember-svg-jar/inlined/smiley-crying", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-crying</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM6.081 8.121a7.566 7.566 0 012.3-2.392A1 1 0 019.483 7.4a5.521 5.521 0 00-1.689 1.755 1 1 0 01-1.713-1.034zm1.081 9.332a5.774 5.774 0 019.676 0 1 1 0 01-1.676 1.094 3.771 3.771 0 00-6.324 0 1 1 0 01-1.676-1.094zm9.044-8.3A5.521 5.521 0 0014.517 7.4a1 1 0 011.1-1.673 7.552 7.552 0 012.3 2.392 1 1 0 01-1.713 1.034zM19.75 16a2.25 2.25 0 01-2.25-2.25c0-1.009 1.484-3.347 2.041-4.19a.252.252 0 01.209-.112.248.248 0 01.208.112c.558.84 2.042 3.181 2.042 4.19A2.25 2.25 0 0119.75 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});