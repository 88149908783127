define("ember-svg-jar/inlined/rating-star-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rating-star-remove</title><path d=\"M22.1 12.906a6.491 6.491 0 00-1.389-1.051l2.066-1.713A1.5 1.5 0 0021.8 7.5h-6.088a.5.5 0 01-.471-.333L13.056.976a1.5 1.5 0 00-2.816.017L8.061 7.168a.5.5 0 01-.472.333H1.5a1.5 1.5 0 00-.964 2.651l5.184 4.3a.5.5 0 01.156.543L3.7 21.525a1.5 1.5 0 002.309 1.686l5.263-3.858A6.5 6.5 0 1022.1 12.906zm-7.778 7.777a4.5 4.5 0 116.364 0 4.5 4.5 0 01-6.367 0z\"/><path d=\"M19.8 15.2a.75.75 0 00-1.061 0l-1.06 1.06a.25.25 0 01-.354 0l-1.06-1.06a.75.75 0 00-1.061 1.06l1.061 1.061a.25.25 0 010 .353L15.2 18.739a.75.75 0 001.061 1.06l1.06-1.06a.25.25 0 01.354 0l1.06 1.06a.75.75 0 001.061-1.06l-1.061-1.061a.25.25 0 010-.353l1.065-1.061a.749.749 0 000-1.064z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});