define("ember-svg-jar/inlined/single-neutral.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral</title><path d=\"M12 8.5A5.506 5.506 0 006.5 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229l.441 5.313A.5.5 0 0010 24h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 0115.19 18H17a.5.5 0 00.5-.5V14A5.506 5.506 0 0012 8.5z\"/><circle cx=\"12\" cy=\"3.5\" r=\"3.5\"/><path d=\"M12 8.5A5.506 5.506 0 006.5 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229l.441 5.313A.5.5 0 0010 24h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 0115.19 18H17a.5.5 0 00.5-.5V14A5.506 5.506 0 0012 8.5z\"/><circle cx=\"12\" cy=\"3.5\" r=\"3.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});