define("ember-svg-jar/inlined/style-three-pin-train-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-train-alternate</title><path d=\"M16.614 13.857a.75.75 0 011.029.257l1.095 1.826a.5.5 0 00.865-.012A13.257 13.257 0 0021.5 9.5a9.5 9.5 0 00-19 0 13.257 13.257 0 001.9 6.428.5.5 0 00.864.012l1.1-1.826a.75.75 0 111.286.772L5.924 17.75a.5.5 0 00.028.556 32.476 32.476 0 005.089 5.347 1.5 1.5 0 001.917 0 32.53 32.53 0 005.09-5.348.5.5 0 00.027-.556l-1.718-2.864a.749.749 0 01.257-1.028zM16.5 11.5A1.5 1.5 0 0115 13H9a1.5 1.5 0 01-1.5-1.5v-4a4.5 4.5 0 019 0z\"/><circle cx=\"9.5\" cy=\"11\" r=\"1\"/><circle cx=\"14.5\" cy=\"11\" r=\"1\"/><ellipse cx=\"12\" cy=\"7\" rx=\"2.5\" ry=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});