define("ember-svg-jar/inlined/single-neutral-actions-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-home</title><path d=\"M.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.351-4.213a.249.249 0 00-.034-.148 1.9 1.9 0 01-.163-.321 2.51 2.51 0 01.731-2.768l2.028-1.716a.246.246 0 00.087-.191v-.185a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M10.176 17.368a.5.5 0 00.324.882h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5H15a.5.5 0 00.5-.5V21a1.5 1.5 0 013 0v2.25a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5V18.5a.25.25 0 01.25-.25h1.25a.5.5 0 00.323-.882l-6.5-5.5a.5.5 0 00-.647 0zM.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.351-4.213a.249.249 0 00-.034-.148 1.9 1.9 0 01-.163-.321 2.51 2.51 0 01.731-2.768l2.028-1.716a.246.246 0 00.087-.191v-.185a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M10.176 17.368a.5.5 0 00.324.882h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5H15a.5.5 0 00.5-.5V21a1.5 1.5 0 013 0v2.25a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5V18.5a.25.25 0 01.25-.25h1.25a.5.5 0 00.323-.882l-6.5-5.5a.5.5 0 00-.647 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});