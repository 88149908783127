define("ember-svg-jar/inlined/weather-sun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-sun</title><path d=\"M12 4.645A7.355 7.355 0 1019.355 12 7.363 7.363 0 0012 4.645zm0 12.71A5.355 5.355 0 1117.355 12 5.361 5.361 0 0112 17.355zM12 3.467a1 1 0 001-1V1a1 1 0 00-2 0v1.467a1 1 0 001 1zM4.552 5.966a1 1 0 101.414-1.414L4.929 3.515a1 1 0 00-1.414 1.414zM2.467 11H1a1 1 0 000 2h1.467a1 1 0 100-2zM4.552 18.034l-1.037 1.037a1 1 0 101.414 1.414l1.037-1.037a1 1 0 10-1.414-1.414z\"/><path d=\"M12 20.533a1 1 0 00-1 1V23a1 1 0 002 0v-1.467a1 1 0 00-1-1zM19.448 18.034a1 1 0 00-1.414 1.414l1.037 1.037a1 1 0 001.414-1.414zM23 11h-1.467a1 1 0 000 2H23a1 1 0 000-2zM18.741 6.259a1 1 0 00.707-.293l1.037-1.037a1 1 0 00-1.414-1.414l-1.037 1.037a1 1 0 00.707 1.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});