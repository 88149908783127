define("ember-svg-jar/inlined/table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>table</title><path d=\"M23.45 10.91A1 1 0 0023 9.017H1a1 1 0 00-.449 1.894 19.819 19.819 0 007.312 2.078.25.25 0 01.227.184 2.508 2.508 0 001.728 1.749.249.249 0 01.182.24v4.355a1.506 1.506 0 01-.749 1.3A2.508 2.508 0 008 22.983v.5a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-.5a2.508 2.508 0 00-1.251-2.166 1.506 1.506 0 01-.749-1.3v-4.378a.218.218 0 01.161-.211 2.51 2.51 0 001.75-1.756.249.249 0 01.226-.184 20.09 20.09 0 007.313-2.078zM11.519 7.38a.5.5 0 00.481.637h5a.5.5 0 00.481-.363l.563-1.969a.249.249 0 01.454-.06l1.144 1.907A1 1 0 1021.357 6.5l-2.4-4 .524-1.846A.5.5 0 0019 .017h-5a.5.5 0 00-.481.363z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});