define("ember-svg-jar/inlined/video-game-console", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-console</title><path d=\"M22 0H2a2 2 0 00-2 2v5.5a.973.973 0 00.542.872.994.994 0 01.489.554L2 11.621a1 1 0 00.928.629h11.7a.5.5 0 01.422.767 2.136 2.136 0 01-1.8.733h-2a2.476 2.476 0 00-2.5 2.5v.25a.5.5 0 01-.5.5h-4.5a1.5 1.5 0 00-1.5 1.5v4a1.5 1.5 0 001.5 1.5h12a1.5 1.5 0 001.5-1.5v-4a1.5 1.5 0 00-1.5-1.5h-4.5a.5.5 0 01-.5-.5v-.25a.485.485 0 01.5-.5h2c2.22 0 3.764-1.211 3.975-3.057a.5.5 0 01.5-.443h3.351a1 1 0 00.924-.629l.968-2.695a1 1 0 01.489-.554A.973.973 0 0024 7.5V2a2 2 0 00-2-2zM6.25 10h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zm4.5 0h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zm4-4a.5.5 0 01-.5.5H2.5A.5.5 0 012 6V2.5a.5.5 0 01.5-.5h.75a.5.5 0 01.5.5v.75A1.752 1.752 0 005.5 5h8.75a.5.5 0 01.5.5zm0-3a.5.5 0 01-.5.5H5.5a.25.25 0 01-.25-.25V2.5a.5.5 0 01.5-.5h8.5a.5.5 0 01.5.5zm-8 18H6.5a.25.25 0 00-.25.25v.25a.5.5 0 01-1 0v-.25A.25.25 0 005 21h-.25a.5.5 0 010-1H5a.25.25 0 00.25-.25v-.25a.5.5 0 011 0v.25a.25.25 0 00.25.25h.25a.5.5 0 010 1zm7.5-1.5a1 1 0 11-1 1 1 1 0 011-1zm-2.5 1a1 1 0 11-1-1 1 1 0 011 1zm7-10.25a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5zM22 6a.5.5 0 01-.5.5h-.75a.5.5 0 01-.5-.5V2.5a.5.5 0 01.5-.5h.75a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});