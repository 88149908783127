define("ember-svg-jar/inlined/global-warming-globe-fire-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>global-warming-globe-fire-1</title><path d=\"M23.7 7.042a1 1 0 00-.725-.72.984.984 0 00-.981.295.25.25 0 01-.417-.157A9.7 9.7 0 0018.006.232a1 1 0 00-1.641.781 1.984 1.984 0 01-.8 1.622 10.346 10.346 0 00-1.479 1.326.5.5 0 01-.742-.007A3.5 3.5 0 0011.907 3a1 1 0 00-1.337 1.024 4.254 4.254 0 01-.424 1.607.5.5 0 00.361.641 9.729 9.729 0 017.482 9.3.5.5 0 00.659.466 7.959 7.959 0 004.316-3.036 7.529 7.529 0 00.736-5.96z\"/><path d=\"M8.25 7.5a8.25 8.25 0 108.25 8.25A8.259 8.259 0 008.25 7.5zm5.071 4.733a.247.247 0 01-.11.165 1.378 1.378 0 00-.628 1.154v1.758a.379.379 0 01-.378.379h-1.326A1.379 1.379 0 009.571 17.5l.293.879a1.375 1.375 0 001.308.942h1.692a.25.25 0 01.192.41 6.227 6.227 0 01-8.806.809 1 1 0 01-.325-1.026l.628-2.351a.642.642 0 01.621-.478 1.38 1.38 0 001.379-1.375v-1.758a1.38 1.38 0 00-1.379-1.378H3.636a.25.25 0 01-.192-.41 6.238 6.238 0 019.832.276.25.25 0 01.045.193z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});