define("ember-svg-jar/inlined/file-dmg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-dmg</title><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.428A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6zM6.456 13.32a.249.249 0 00-.331.24v3.882a.249.249 0 00.331.24A2.238 2.238 0 007.875 15.5a2.237 2.237 0 00-1.419-2.18z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.251A.25.25 0 0014.75 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.251A.25.25 0 0021.75 7zM5.5 19.126a.625.625 0 01-.625-.625v-6a.625.625 0 01.625-.625 3.625 3.625 0 010 7.25zm8.625-.625a.625.625 0 11-1.25 0v-2.927c0-.154-.15-.134-.224.021l-.092.185a.65.65 0 01-1.118 0l-.107-.214c-.06-.122-.209-.117-.209.008V18.5a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279s.72 1.429.786 1.571a.173.173 0 00.311 0l.785-1.569a.625.625 0 011.184.279zm5-4a.625.625 0 01-1.25 0V14a.875.875 0 10-1.75 0v3a.875.875 0 001.717.237c.011-.036.034-.112-.084-.112H17.5a.625.625 0 010-1.25h1a.625.625 0 01.625.625v.5a2.125 2.125 0 11-4.25 0v-3a2.125 2.125 0 114.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});