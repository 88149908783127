define("ember-svg-jar/inlined/phone-action-play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-play</title><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/><path d=\"M15.22 10.286l-4.5-2.629a1.157 1.157 0 00-1.138-.014A1.122 1.122 0 009 8.621v5.258a1.121 1.121 0 00.582.978 1.2 1.2 0 001.136-.013l4.5-2.629a1.114 1.114 0 000-1.929z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});