define("ember-svg-jar/inlined/casino-lucky-clover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>casino-lucky-clover</title><path d=\"M5.763 7.086l4.93.564h.056a.5.5 0 00.5-.55l-.5-4.982a2.347 2.347 0 00-.86-1.587A2.318 2.318 0 008.165.014a2.354 2.354 0 00-2.114 2.172.218.218 0 01-.21.2 2.2 2.2 0 00-1.278.517 2.327 2.327 0 00-.869 1.573 2.359 2.359 0 002.069 2.61zM18.238 7.086l-4.929.564h-.057a.5.5 0 01-.5-.55l.5-4.982a2.343 2.343 0 01.86-1.587 2.317 2.317 0 011.724-.517 2.354 2.354 0 012.113 2.172.218.218 0 00.21.2 2.2 2.2 0 011.278.517 2.323 2.323 0 01.869 1.573 2.358 2.358 0 01-2.068 2.61zM18.238 9.916l-4.929-.563a.505.505 0 00-.408.142.5.5 0 00-.146.406l.5 4.98A2.358 2.358 0 0015.593 17a2.18 2.18 0 00.242-.013 2.356 2.356 0 002.113-2.159.252.252 0 01.227-.23 2.377 2.377 0 001.262-.5 2.318 2.318 0 00.869-1.571 2.358 2.358 0 00-2.068-2.611zM11.1 9.495a.508.508 0 00-.407-.142l-4.93.563a2.358 2.358 0 00-2.069 2.605 2.318 2.318 0 00.869 1.579 2.389 2.389 0 001.266.495.249.249 0 01.222.231 2.357 2.357 0 002.113 2.167 2.193 2.193 0 00.242.007 2.358 2.358 0 002.338-2.12l.5-4.98a.5.5 0 00-.144-.405zM15.75 22a2.5 2.5 0 01-2.5-2.5V18a1 1 0 00-2 0v1.5a4.505 4.505 0 004.5 4.5 1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});