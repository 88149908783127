define("ember-svg-jar/inlined/design-file-pen-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-pen-alternate</title><path d=\"M14.992 13.811a4.705 4.705 0 01-3.307-3.3.251.251 0 00-.27-.186 5.5 5.5 0 00-4.54 3.822l-1.353 4.42a1.126 1.126 0 001.068 1.466 1.085 1.085 0 00.341-.054l4.424-1.354a5.5 5.5 0 003.823-4.544.25.25 0 00-.186-.27zM11.4 15.862a1.246 1.246 0 110-1.762 1.246 1.246 0 010 1.762zM14.668 6.866a.5.5 0 00-.707 0 3.3 3.3 0 004.674 4.673.5.5 0 000-.707z\"/><path d=\"M21.707 5.707L16.293.293A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.465a.5.5 0 01.353.146l4.536 4.536a.5.5 0 01.146.353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});