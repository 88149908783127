define("ember-svg-jar/inlined/audio-file-wav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file-wav</title><path d=\"M17.5 7A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.25a.25.25 0 00-.25-.25zm-7.875 7.264a10.143 10.143 0 01-1.066 4.515.626.626 0 01-.559.346.527.527 0 01-.068 0 .624.624 0 01-.538-.47s-.237-.924-.272-1.088-.208-.143-.243 0l-.273 1.089a.624.624 0 01-.538.47.613.613 0 01-.627-.342 10.143 10.143 0 01-1.066-4.515V12.5a.625.625 0 011.25 0v1.764a8.852 8.852 0 00.13 1.506c.036.206.21.234.272.047l.367-1.468a.624.624 0 011.212 0l.356 1.424c.05.207.247.2.283 0a8.852 8.852 0 00.13-1.506V12.5a.625.625 0 011.25 0zm5 4.236a.625.625 0 01-1.25 0v-2.125a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V18.5a.625.625 0 01-1.25 0v-4.375a2.213 2.213 0 011.9-2.239A2.128 2.128 0 0114.625 14zm5-4.5A8.176 8.176 0 0118 18.875a.625.625 0 01-1 0A8.176 8.176 0 0115.375 14v-1.5a.625.625 0 011.25 0V14a6.919 6.919 0 00.674 2.965.25.25 0 00.4.012A6.916 6.916 0 0018.375 14v-1.5a.625.625 0 011.25 0z\"/><path d=\"M12.5 13.125a.877.877 0 00-.875.875v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V14a.877.877 0 00-.875-.875zM17.5 6h3.9a.25.25 0 00.177-.427L16.427.427A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});