define("ember-svg-jar/inlined/wedding-car", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wedding-car</title><path d=\"M4.5 21.5h-3a.5.5 0 00-.5.5v1a1 1 0 001 1h2a1 1 0 001-1v-1a.5.5 0 00-.5-.5zM22.5 21.5h-3a.5.5 0 00-.5.5v1a1 1 0 001 1h2a1 1 0 001-1v-1a.5.5 0 00-.5-.5zM23.864 16l-1.344-2.3a5.971 5.971 0 01-.552-1.258l-1.762-5.736A1 1 0 0019.25 6H16.9a1 1 0 00-.721.308l-1.217 1.269a.25.25 0 00.18.423h2.631a1 1 0 01.955.706l.969 3.147a.5.5 0 01-.478.647H4.781a.5.5 0 01-.478-.647l.969-3.147A1 1 0 016.227 8h2.63a.25.25 0 00.181-.423L7.821 6.308A1 1 0 007.1 6H4.75a1 1 0 00-.956.706l-1.762 5.728a5.971 5.971 0 01-.552 1.258L.136 16a1 1 0 00-.136.5v3a1 1 0 001 1h22a1 1 0 001-1v-3a1 1 0 00-.136-.5z\"/><path d=\"M11.639 8.123a.5.5 0 00.722 0l3.779-3.942A2.449 2.449 0 0012.676.718l-.322.322a.5.5 0 01-.708 0l-.322-.322a2.449 2.449 0 10-3.463 3.463z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});