define("ember-svg-jar/inlined/social-media-facebook-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-facebook-1</title><path d=\"M19.55 14.56a.5.5 0 01-.5.44H17a.5.5 0 00-.5.5v8a.5.5 0 00.5.5h6a1 1 0 001-1V1a1 1 0 00-1-1H1a1 1 0 00-1 1v22a1 1 0 001 1h11a.5.5 0 00.5-.5v-8a.5.5 0 00-.5-.5h-2a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h2a.5.5 0 00.5-.5V9.19a5.69 5.69 0 015.69-5.69h1.31a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-1.31a1.69 1.69 0 00-1.69 1.69v1.31a.5.5 0 00.5.5h2.43a.5.5 0 01.5.56z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});