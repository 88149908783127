define("ember-svg-jar/inlined/exotic-food-samosa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>exotic-food-samosa</title><path d=\"M14.01 11.642l3.824 5.606a2.424 2.424 0 01.238.442.5.5 0 00.463.31h.551a1.415 1.415 0 001.177-2.2l-3.776-5.67a1.465 1.465 0 00-2.353 0l-.4.606a.5.5 0 00.05.618 2.4 2.4 0 01.226.288zM7.513 10.13L3.737 15.8A1.415 1.415 0 004.914 18h.551a.5.5 0 00.463-.31 2.424 2.424 0 01.238-.442l3.824-5.606a2.4 2.4 0 01.23-.288.5.5 0 00.05-.618l-.4-.606a1.465 1.465 0 00-2.357 0zM6.921 19.263A1.429 1.429 0 008.176 20h7.648a1.428 1.428 0 001.255-.738 1.383 1.383 0 00-.071-1.45L13.183 12.2a1.492 1.492 0 00-2.366 0l-3.825 5.612a1.385 1.385 0 00-.071 1.451z\"/><path d=\"M23.228 16.462a1 1 0 00-1.558 1.255 1.276 1.276 0 01.33.783c0 1.461-3.8 3.5-10 3.5S2 19.961 2 18.5a1.276 1.276 0 01.33-.783 1 1 0 00-1.558-1.255A3.213 3.213 0 000 18.5C0 22.072 6.183 24 12 24s12-1.928 12-5.5a3.213 3.213 0 00-.772-2.038zM4.25 8.462h.88c2.5 0 4.25-2.467 4.25-6C9.38-.821 0-.821 0 2.462c0 3.538 1.748 6 4.25 6zm-2.75-6c0-.257 1.1-1 3.19-1s3.19.743 3.19 1-1.1 1-3.19 1-3.19-.743-3.19-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});