define("ember-svg-jar/inlined/love-boat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-boat</title><circle cx=\"5\" cy=\"14.5\" r=\"2\"/><path d=\"M23 15.5a1 1 0 00-1 1v1a.5.5 0 01-.5.5h-.522a.5.5 0 01-.5-.455A5 5 0 0015.5 13h-6a1 1 0 00-1 1v3.5a.5.5 0 01-.5.5H2.5a.5.5 0 01-.5-.5V11a1 1 0 00-2 0v12a1 1 0 002 0v-1.5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5V23a1 1 0 002 0v-6.5a1 1 0 00-1-1zM17.011 6.864a.452.452 0 00.646 0l3.228-3.324a2.055 2.055 0 00.393-2.394 2.115 2.115 0 00-3.364-.538l-.243.221a.5.5 0 01-.674 0l-.243-.221a2.115 2.115 0 00-3.364.538 2.054 2.054 0 00.393 2.394zM11.1 10.938a.292.292 0 00.418 0L13.6 8.789a1.328 1.328 0 00.255-1.548 1.369 1.369 0 00-2.176-.348.557.557 0 01-.75 0 1.368 1.368 0 00-2.175.348 1.328 1.328 0 00.256 1.548z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});