define("ember-svg-jar/inlined/material-tile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>material-tile</title><path d=\"M23.259 20.354L20.623 2.777A1.492 1.492 0 0019.139 1.5h-3.15a.251.251 0 00-.25.262l.938 18.767a.25.25 0 00.117.2A1.5 1.5 0 0117.5 22a.5.5 0 00.5.5h4a.5.5 0 00.5-.5 1.494 1.494 0 01.573-1.179.5.5 0 00.186-.467zM8.261 1.762a.251.251 0 00-.069-.184.247.247 0 00-.181-.078h-3.15a1.492 1.492 0 00-1.484 1.277L.741 20.354a.5.5 0 00.185.467A1.492 1.492 0 011.5 22a.5.5 0 00.5.5h4a.5.5 0 00.5-.5 1.5 1.5 0 01.706-1.272.249.249 0 00.117-.2zM14.236 1.738a.25.25 0 00-.25-.238h-3.972a.25.25 0 00-.25.238L8.821 20.6a.251.251 0 00.1.21A1.5 1.5 0 019.5 22a.5.5 0 00.5.5h4a.5.5 0 00.5-.5 1.5 1.5 0 01.583-1.186.251.251 0 00.1-.21z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});