define("ember-svg-jar/inlined/video-file-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-home</title><path d=\"M13.255 13.891l5.845-3.236a.735.735 0 000-1.311l-5.845-3.235a.888.888 0 00-.835-.01.758.758 0 00-.42.665v6.472a.758.758 0 00.42.665.894.894 0 00.835-.01z\"/><path d=\"M23.415 3L21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.868a.25.25 0 00.331.232 2.485 2.485 0 011.339 0A.249.249 0 008 9.867V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.354.146l2.121 2.121a.5.5 0 01.146.354V18a.5.5 0 01-.5.5h-5.327a.256.256 0 00-.245.224 2.226 2.226 0 01-1.055 1.318.249.249 0 00.138.457H22a2 2 0 002-2V4.414A2 2 0 0023.415 3z\"/><path d=\"M7.323 12.118a.5.5 0 00-.646 0l-6.5 5.5A.5.5 0 00.5 18.5h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-4.75a.25.25 0 01.25-.25h1.25a.5.5 0 00.323-.882z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});