define("ember-svg-jar/inlined/shirt-male", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shirt-male</title><path d=\"M18.952 2.668a13.161 13.161 0 00-1.778-.738.979.979 0 01-.39-.242L15.535.439A1.5 1.5 0 0014.475 0H9.526a1.5 1.5 0 00-1.061.439L7.218 1.687a1 1 0 01-.394.241A12.82 12.82 0 004.5 2.953 8.363 8.363 0 000 10.5v1.2a2 2 0 001.571 1.956l2.537.56a.5.5 0 01.392.489v7.775a1.5 1.5 0 00.427 1.064A1.482 1.482 0 006 24h12a1.475 1.475 0 001.064-.447 1.507 1.507 0 00.436-1.073v-7.775a.5.5 0 01.392-.488l2.537-.561A2 2 0 0024 11.7v-1.2a8.412 8.412 0 00-5.048-7.832zM4.5 11.943a.248.248 0 01-.094.2.251.251 0 01-.21.049l-2-.443A.25.25 0 012 11.5v-1a6.184 6.184 0 012.083-4.8.25.25 0 01.417.186zM13 10.5a1 1 0 01-2 0V7.76a.249.249 0 01.107-.205c.119-.083.248-.192.74-.572a.249.249 0 01.306 0c.5.387.626.493.752.58a.217.217 0 01.1.18zm2.166-4.469a1 1 0 01-1.441.417c-.745-.473-1.33-.9-1.585-1.092a.228.228 0 00-.28 0c-.255.192-.84.619-1.585 1.092a1 1 0 01-1.441-.417 10.635 10.635 0 01-.776-2.922.249.249 0 01.071-.209l1.093-1.1a.25.25 0 01.411.09 6.859 6.859 0 002.205 2.98.267.267 0 00.324 0 6.859 6.859 0 002.205-2.98.25.25 0 01.411-.09l1.093 1.1a.249.249 0 01.071.212 10.635 10.635 0 01-.776 2.919zM22 11.5a.249.249 0 01-.2.243l-2 .441a.252.252 0 01-.211-.05.248.248 0 01-.093-.194V5.887a.25.25 0 01.417-.186A6.159 6.159 0 0122 10.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});