define("ember-svg-jar/inlined/content-pen-6", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-pen-6</title><path d=\"M5.49 13.617a.5.5 0 00-.828.195l-1.414 4.242a.5.5 0 00.121.512L6.2 21.394a.5.5 0 00.353.147.486.486 0 00.159-.026l4.239-1.415a.5.5 0 00.2-.828zM3.369 19.98a.5.5 0 00-.707 0L.187 22.455a.5.5 0 00.353.853h.007l2.865-.036a.5.5 0 00.347-.147L4.783 22.1a.5.5 0 000-.707zM23.521 5.484l-4.243-4.242a1.5 1.5 0 00-2.121 0l-.706.707-.262-.286a2.97 2.97 0 00-2.146-.97 2.917 2.917 0 00-2.185.878L8.676 4.752a1 1 0 101.414 1.414l3.182-3.181A1.009 1.009 0 0114 2.692a.992.992 0 01.715.323l.291.318.03.03L6.2 12.2a.5.5 0 000 .707l5.657 5.657a.5.5 0 00.707 0l10.96-10.96a1.5 1.5 0 00-.003-2.12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});