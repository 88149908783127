define("ember-svg-jar/inlined/goods-mark-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>brand-circle</title><defs><linearGradient x1=\"0%\" y1=\"0%\" x2=\"102%\" y2=\"101%\" id=\"b\"><stop stop-color=\"#6E3ED9\" offset=\"0%\"/><stop stop-color=\"#A635CD\" offset=\"100%\"/></linearGradient><circle id=\"a\" cx=\"16\" cy=\"16\" r=\"16\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><mask id=\"c\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><use fill=\"#E6DFF5\" xlink:href=\"#a\"/><path d=\"M6.75 16.965v5.014l4 2 4-2v-5.014l-4-2-4 2zm10.5 0v5.014l4 2 4-2v-5.014l-4-2-4 2zM12 7.715v5.014l4 2 4-2V7.715l-4-2-4 2z\" stroke=\"url(#b)\" stroke-width=\"2\" mask=\"url(#c)\"/></g>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
});