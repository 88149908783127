define("ember-svg-jar/inlined/wildlife-protection-tree-shield-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wildlife-protection-tree-shield-1</title><path d=\"M21.319 1.915A22.6 22.6 0 0012 0a22.6 22.6 0 00-9.319 1.915A2.044 2.044 0 001.5 3.774v7.637c0 8.486 7.43 11.963 9.269 12.369a3.559 3.559 0 002.514-.021c.044-.017.064-.009.1-.038 2.138-.752 9.114-3.859 9.114-12.311V3.774a2.044 2.044 0 00-1.178-1.859zM5.25 12.5a.75.75 0 11.75-.75.75.75 0 01-.75.75zM17 17H7a.5.5 0 01-.5-.5c0-1.331 1.9-2.16 4.059-2.416a.5.5 0 00.441-.495.6.6 0 00-.372-.553l-1.5-.609a1 1 0 11.75-1.854l.781.316a.249.249 0 00.341-.231V9.427a.5.5 0 00-.276-.447l-1.066-.533A2.984 2.984 0 018 5.763V5a1 1 0 012 0v.763a.994.994 0 00.553.9l.085.043A.25.25 0 0011 6.477V6a1 1 0 012 0v2.7a.25.25 0 00.338.234l1.013-.38a1 1 0 00.649-.94V6a1 1 0 012 0v1.614a3.014 3.014 0 01-1.947 2.809l-1.729.648a.5.5 0 00-.324.468v2.05a.5.5 0 00.441.495C15.6 14.34 17.5 15.169 17.5 16.5a.5.5 0 01-.5.5zm2.25-4a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});