define("ember-svg-jar/inlined/construction-brick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>construction-brick</title><path d=\"M12.146 9.431a.5.5 0 00-.466-.681H7.75a.5.5 0 00-.5.5v2.5a.5.5 0 00.5.5h2.96a.5.5 0 00.466-.319z\"/><rect y=\"2.5\" width=\"12\" height=\"4.75\" rx=\".5\" ry=\".5\"/><path d=\"M9.107 17.25l1.1-2.819a.5.5 0 00-.466-.681H.5a.5.5 0 00-.5.5v3z\"/><rect y=\"18.75\" width=\"5.75\" height=\"4.75\" rx=\".5\" ry=\".5\"/><rect x=\"17.25\" y=\"18.75\" width=\"6.75\" height=\"4.75\" rx=\".5\" ry=\".5\"/><path d=\"M15.75 19.713a.5.5 0 00-.681-.466l-3.236 1.259a2.243 2.243 0 01-2.415-.458 2.138 2.138 0 01-.546-.933.5.5 0 00-.481-.365H7.75a.5.5 0 00-.5.5V23a.5.5 0 00.5.5h7.5a.5.5 0 00.5-.5z\"/><rect y=\"8.75\" width=\"5.75\" height=\"3.5\" rx=\".5\" ry=\".5\"/><path d=\"M17.083 12.563A.729.729 0 0116 12.54a.751.751 0 01.066-1.059l1.434-1.262a.5.5 0 00.023-.728L17 8.969a2.13 2.13 0 00-2.05-.576 1.781 1.781 0 00-1.279 1.272l-3.31 8.511a.689.689 0 00.932.931l8.475-3.3a1.772 1.772 0 001.312-1.271 2.14 2.14 0 00-.58-2.067l-.487-.487 1.442-1.272a1.255 1.255 0 00.117-1.762l-2.17-2.5a1.364 1.364 0 00.582-.3l3.552-3.227A1.38 1.38 0 1021.707.845l-3.553 3.226a1.383 1.383 0 00-.34 1.614 1.752 1.752 0 00.356 1.635l1.78 2.052a.5.5 0 01-.046.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});