define("ember-svg-jar/inlined/light-mode-bright", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-mode-bright</title><path d=\"M23.25 12a3.5 3.5 0 00-3.5-3.5H4.25a3.5 3.5 0 000 7h15.5a3.5 3.5 0 003.5-3.5zM5.5 13.5A.5.5 0 015 13v-2a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v2a.5.5 0 01-.5.5zM12 6a1 1 0 001-1V1a1 1 0 00-2 0v4a1 1 0 001 1zM6.132 6.5a1 1 0 001.736-1l-2-3.5a1 1 0 10-1.736 1zM16.5 6.868a1 1 0 001.368-.368l2-3.5a1 1 0 10-1.736-1l-2 3.5a1 1 0 00.368 1.368zM12 18a1 1 0 00-1 1v4a1 1 0 002 0v-4a1 1 0 00-1-1zM7.5 17.132a1 1 0 00-1.364.372l-2 3.5A1 1 0 005.868 22l2-3.5a1 1 0 00-.368-1.368zM17.868 17.5a1 1 0 00-1.736.992l2 3.5A1 1 0 1019.868 21z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});