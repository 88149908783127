define("ember-svg-jar/inlined/phone-actions-record-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-record-1</title><path d=\"M17.5.023a6.5 6.5 0 106.5 6.5 6.5 6.5 0 00-6.5-6.5zm0 10.991a4.5 4.5 0 114.5-4.5 4.5 4.5 0 01-4.5 4.5z\"/><circle cx=\"17.5\" cy=\"6.518\" r=\"2\"/><path d=\"M15.5 15.76a3.116 3.116 0 00-4.4 0l-.827.828a4.249 4.249 0 00-1.346 4.5 11.355 11.355 0 01-6.034-6.028 4.248 4.248 0 004.5-1.345l.83-.829a3.117 3.117 0 000-4.4L4.639 4.9a2.233 2.233 0 00-3.649.74 13.313 13.313 0 0017.358 17.353 2.233 2.233 0 00.737-3.648z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});