define("ember-svg-jar/inlined/share-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>share-1</title><path d=\"M18.5 7h-3.25a.25.25 0 00-.25.25V11a3 3 0 01-6 0V7.248A.25.25 0 008.75 7H5.5a2 2 0 00-2 2v13a2 2 0 002 2h13a2 2 0 002-2V9a2 2 0 00-2-2z\"/><path d=\"M11 11a1 1 0 002 0V5.748a.25.25 0 01.25-.25h2.25a1 1 0 00.707-1.707l-3.5-3.5a1 1 0 00-1.414 0l-3.5 3.5A1 1 0 008.5 5.5h2.25a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});