define("ember-svg-jar/inlined/room-service-clean", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>room-service-clean</title><path d=\"M12.25 0A6.257 6.257 0 006 6.25a1.25 1.25 0 002.5 0 3.75 3.75 0 017.5 0v2.16a.491.491 0 01-.074.261c-.722 1.18-2.083 1.449-3.839 1.794C9.307 11.011 5.5 11.758 5.5 18v4a2 2 0 002 2h9a2 2 0 002-2V6.25A6.268 6.268 0 0012.25 0zm2.25 21.25h-5a.75.75 0 010-1.5h5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});