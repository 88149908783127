define("ember-svg-jar/inlined/ab-testing-smartphones", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ab-testing-smartphones</title><path d=\"M10.75 0h-7a2 2 0 00-2 2v14a2 2 0 002 2h5.5a1 1 0 001-1v-4a1 1 0 00-1-1H4a.25.25 0 01-.25-.25v-9.5A.25.25 0 014 2h6.5a.25.25 0 01.25.25V4a1 1 0 002 0V2a2 2 0 00-2-2zm-3.5 14a1 1 0 11-1 1 1 1 0 011-1z\"/><path d=\"M11.25 8v14a2 2 0 002 2h7a2 2 0 002-2V8a2 2 0 00-2-2h-7a2 2 0 00-2 2zm5.5 14a1 1 0 111-1 1 1 0 01-1 1zm-3.5-13.75A.25.25 0 0113.5 8H20a.249.249 0 01.25.25v9.5A.25.25 0 0120 18h-6.5a.25.25 0 01-.25-.25zM8.75 10.75A.75.75 0 009.5 10V5.5a2.25 2.25 0 00-4.5 0V10a.75.75 0 001.5 0V8a.25.25 0 01.25-.25h1A.25.25 0 018 8v2a.75.75 0 00.75.75zm-1-4.5h-1A.25.25 0 016.5 6v-.5a.75.75 0 011.5 0V6a.25.25 0 01-.25.25z\"/><path d=\"M15.25 16.75h1.5A2.253 2.253 0 0019 14.5a2.219 2.219 0 00-.46-1.348.251.251 0 010-.3A2.219 2.219 0 0019 11.5a2.253 2.253 0 00-2.25-2.25h-1.5a.75.75 0 00-.75.75v6a.75.75 0 00.75.75zM16 11a.25.25 0 01.25-.25h.5a.75.75 0 010 1.5h-.5A.25.25 0 0116 12zm0 3a.25.25 0 01.25-.25h.5a.75.75 0 010 1.5h-.5A.25.25 0 0116 15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});