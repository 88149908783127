define("ember-svg-jar/inlined/pollution-rain-skull", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pollution-rain-skull</title><circle cx=\"14.5\" cy=\"9.5\" r=\"1\"/><circle cx=\"10.5\" cy=\"9.5\" r=\"1\"/><path d=\"M22.127 9.106a.5.5 0 01-.179-.47 3.978 3.978 0 00-.81-3.114 3.918 3.918 0 00-2.847-1.507.5.5 0 01-.417-.286A6.5 6.5 0 005.557 5.634a.5.5 0 01-.423.429A6.019 6.019 0 000 12.015a5.956 5.956 0 001.778 4.266A5.752 5.752 0 006 18h13.054a4.9 4.9 0 003.461-1.425A4.969 4.969 0 0024 13.015a5.046 5.046 0 00-1.873-3.909zm-6.9 3.671a.5.5 0 00-.224.417V14.5a.5.5 0 01-1 0v-1a.5.5 0 00-1 0v1a.5.5 0 01-1 0v-1a.5.5 0 00-1 0v1a.5.5 0 11-1 0v-1.3a.5.5 0 00-.223-.418A3.774 3.774 0 018 9.5a4.5 4.5 0 019 0 3.775 3.775 0 01-1.776 3.277zM4.642 21.762a3.272 3.272 0 00-1.349.531 1 1 0 001.414 1.414 3.248 3.248 0 00.531-1.348.5.5 0 00-.6-.6zM7.392 19.012a3.272 3.272 0 00-1.349.531 1 1 0 001.414 1.414 3.248 3.248 0 00.531-1.348.5.5 0 00-.6-.6zM9.642 21.762a3.272 3.272 0 00-1.349.531 1 1 0 001.414 1.414 3.248 3.248 0 00.531-1.348.5.5 0 00-.6-.6zM12.392 19.012a3.272 3.272 0 00-1.349.531 1 1 0 001.414 1.414 3.248 3.248 0 00.531-1.348.5.5 0 00-.6-.6zM14.642 21.762a3.272 3.272 0 00-1.349.531 1 1 0 001.414 1.414 3.248 3.248 0 00.531-1.348.5.5 0 00-.6-.6zM17.392 19.012a3.272 3.272 0 00-1.349.531 1 1 0 001.414 1.414 3.248 3.248 0 00.531-1.348.5.5 0 00-.6-.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});