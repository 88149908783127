define("ember-svg-jar/inlined/email-action-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-heart</title><path d=\"M5.12 13.041a.25.25 0 00.385-.21L5.5 2.25A.249.249 0 015.75 2h12.5a.25.25 0 01.25.25l.009 10.575a.251.251 0 00.131.219.248.248 0 00.255-.009l4.053-2.607a.25.25 0 00.008-.415L20.5 8.3V2.206a2.114 2.114 0 00-2-2.208h-13a2.115 2.115 0 00-2 2.209V8.3l-2.458 1.71a.251.251 0 00.007.416z\"/><path d=\"M18.409 15.131a.754.754 0 01-.4.119H6a.754.754 0 01-.4-.119l-5.215-3.35a.25.25 0 00-.385.21V22a2 2 0 002 2h20a2 2 0 002-2V11.993a.25.25 0 00-.385-.211z\"/><path d=\"M8.229 9.29l3.41 3.556a.5.5 0 00.722 0l3.4-3.549a2.515 2.515 0 00.471-2.9 2.517 2.517 0 00-4.032-.659l-.2.2-.2-.2A2.517 2.517 0 008.229 9.29z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});