define("ember-svg-jar/inlined/smiley-grumpy-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-grumpy-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M12 14a6.483 6.483 0 00-5.448 2.954 1 1 0 101.676 1.092 4.5 4.5 0 017.544 0 1 1 0 101.676-1.092A6.483 6.483 0 0012 14zM9 12a2 2 0 00.052-4H5.5a1 1 0 000 2h1.264a.25.25 0 01.248.222A2 2 0 009 12zM17.552 8H14a1 1 0 000 2h1.264a.25.25 0 01.248.222A2 2 0 1017.552 8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});