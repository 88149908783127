define("ember-svg-jar/inlined/gold-bars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gold-bars</title><path d=\"M9.427 16.783A1 1 0 008.451 16H3.056a1 1 0 00-.977.783l-1.555 6A1 1 0 001.5 24h8.507a1 1 0 00.976-1.217zM21.427 16.783a1 1 0 00-.976-.783h-5.4a1 1 0 00-.977.783l-1.555 6A1 1 0 0013.5 24h8.507a1 1 0 00.976-1.217zM8.079 7.283l-1.555 6A1 1 0 007.5 14.5h8.507a1 1 0 00.976-1.217l-1.556-6a1 1 0 00-.976-.783h-5.4a1 1 0 00-.972.783zM3.853 8.471a.749.749 0 00.734.743H4.6a.751.751 0 00.752-.714A3.57 3.57 0 018.46 5.357a.75.75 0 00.016-1.5A3.42 3.42 0 015.353.777V.75A.749.749 0 004.6 0a.749.749 0 00-.75.748A3.421 3.421 0 01.734 3.857.745.745 0 000 4.612a.753.753 0 00.754.745 3.344 3.344 0 013.099 3.114zM23.571 9.005l-.265-.038a1.507 1.507 0 01-1.273-1.273L22 7.429a.5.5 0 00-.99 0l-.038.265a1.507 1.507 0 01-1.274 1.273l-.264.038a.5.5 0 000 .99l.265.038a1.507 1.507 0 011.273 1.273l.038.265a.5.5 0 00.99 0l.038-.265a1.507 1.507 0 011.274-1.273l.264-.038a.5.5 0 000-.99z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});