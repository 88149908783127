define("ember-svg-jar/inlined/landmark-eiffel-tower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-eiffel-tower</title><path d=\"M10.5 6h3a.5.5 0 00.5-.5V4a1.984 1.984 0 00-.8-1.589.5.5 0 01-.2-.4V1a1 1 0 00-2 0v1.011a.5.5 0 01-.2.4A1.984 1.984 0 0010 4v1.5a.5.5 0 00.5.5zM23.381 23.176c-1.589-1.867-3.045-3.649-4.373-5.47a.5.5 0 00-.4-.206H5.4a.5.5 0 00-.4.206c-1.328 1.821-2.784 3.6-4.373 5.47A.5.5 0 001 24h5.009a.5.5 0 00.5-.5c0-2.083 2.4-4.5 5.5-4.5s5.5 2.42 5.5 4.5a.5.5 0 00.5.5H23a.5.5 0 00.381-.824zM7.5 11h9a1 1 0 000-2h-2a.5.5 0 01-.467-.321 28.904 28.904 0 01-.481-1.334.5.5 0 00-.478-.345h-2.148a.5.5 0 00-.475.345c-.15.46-.315.9-.481 1.334A.5.5 0 019.5 9h-2a1 1 0 000 2zM4.25 16.5h15.5a1 1 0 000-2h-2.615a.5.5 0 01-.43-.246c-.379-.641-.734-1.3-1.076-1.98a.5.5 0 00-.447-.274H8.818a.5.5 0 00-.447.274c-.342.676-.7 1.339-1.076 1.98a.5.5 0 01-.431.246H4.25a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});