define("ember-svg-jar/inlined/ambulance-call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ambulance-call</title><path d=\"M24 4.777a1.112 1.112 0 00-1.111-1.11h-2.056a.5.5 0 01-.5-.5V1.111A1.112 1.112 0 0019.222 0h-2.444a1.112 1.112 0 00-1.111 1.111v2.056a.5.5 0 01-.5.5h-2.056A1.112 1.112 0 0012 4.777v2.446a1.112 1.112 0 001.111 1.11h2.056a.5.5 0 01.5.5v2.056A1.112 1.112 0 0016.778 12h2.444a1.112 1.112 0 001.111-1.111V8.833a.5.5 0 01.5-.5h2.056A1.112 1.112 0 0024 7.223zM17.167 16.16a2.56 2.56 0 00-3.536 0l-.06.061a.5.5 0 01-.678.025 47.111 47.111 0 01-5.137-5.138.5.5 0 01.027-.678l.059-.06a2.5 2.5 0 000-3.535L5.719 4.714a2.5 2.5 0 00-3.535 0L1.02 5.877a3.507 3.507 0 00-.441 4.4A46.906 46.906 0 0013.72 23.422a3.509 3.509 0 004.4-.443l1.163-1.163a2.5 2.5 0 000-3.535z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});