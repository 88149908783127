define("ember-svg-jar/inlined/style-two-pin-plane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-two-pin-plane</title><path d=\"M12 0a9.511 9.511 0 00-9.5 9.5c0 6.547 6.538 12.488 8.541 14.153a1.5 1.5 0 001.917 0c2-1.665 8.542-7.608 8.542-14.154A9.511 9.511 0 0012 0zm6.257 9.556L9.1 15.244a.561.561 0 01-.621-.018l-3.363-2.357a.31.31 0 01-.047-.437.327.327 0 01.053-.052l1.031-.864a.31.31 0 01.3-.052l1.479.882a.5.5 0 00.554-.028l1.759-1.3a.5.5 0 00-.035-.827L6.592 7.947a.385.385 0 01-.044-.547.378.378 0 01.062-.058l1.038-.769a.386.386 0 01.364-.052l5.607 2.088a.5.5 0 00.456-.056l2.493-1.7a1.508 1.508 0 011.187-.228 1.611 1.611 0 011.155 1.04 1.512 1.512 0 01.09.512 1.594 1.594 0 01-.743 1.379z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});