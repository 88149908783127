define("ember-svg-jar/inlined/mouse-remote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mouse-remote</title><path d=\"M12.5 6.986h-1a5.574 5.574 0 00-5.5 5.5v6a5.573 5.573 0 005.5 5.5h1a5.573 5.573 0 005.5-5.5v-6a5.574 5.574 0 00-5.5-5.5zm.5 6.5a1 1 0 01-2 0v-2a1 1 0 012 0zM8.067 3.213a7.025 7.025 0 017.866 0 1 1 0 001.134-1.647 9.052 9.052 0 00-10.134 0 1 1 0 001.134 1.647z\"/><path d=\"M8.317 4.364A1 1 0 009.45 6.013a4.564 4.564 0 015.1 0 1 1 0 001.131-1.649 6.59 6.59 0 00-7.364 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});