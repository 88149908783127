define("ember-svg-jar/inlined/navigation-left-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-left-4</title><path d=\"M14.75 13.5a.25.25 0 00-.25.25v2.75a1 1 0 01-1.748.664l-4-4.5a1 1 0 010-1.329l4-4.5A1 1 0 0114.5 7.5v2.75a.25.25 0 00.25.25h9a.25.25 0 00.25-.25V3a3 3 0 00-3-3h-9a12 12 0 000 24h9a3 3 0 003-3v-7.25a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});