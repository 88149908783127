define("ember-svg-jar/inlined/barbecue-steak-shrimp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>barbecue-steak-shrimp</title><path d=\"M21.288 5.447l1.982 1.416a.256.256 0 00.385-.109 5.034 5.034 0 00.254-2.771.258.258 0 00-.374-.171l-2.222 1.212a.25.25 0 00-.025.423zM20.57 3.721l2.4-1.311a.255.255 0 00.089-.368A4.924 4.924 0 0020.053.057a.257.257 0 00-.3.288l.447 3.191a.25.25 0 00.37.185zM19.855 6.547A4.879 4.879 0 0018.6 9.138a1.139 1.139 0 00.4.889 1.466 1.466 0 001.534-.1l1.9-1.425a.248.248 0 00-.008-.4l-2.235-1.6a.248.248 0 00-.336.045zM14.885 5.509l2.615.373a.976.976 0 001.046-.736.98.98 0 01.215-.362.251.251 0 00.072-.207L18.235.3a.257.257 0 00-.3-.218 4.867 4.867 0 00-1.883.83 5 5 0 00-2.023 3.528 1 1 0 00.856 1.069zM17.248 10.948H15.97a.519.519 0 01-.426-.245 8.464 8.464 0 00-8.405-3.648 8.57 8.57 0 00-7.085 7.427A8.5 8.5 0 008.5 23.948h9a6.5 6.5 0 006.5-6.7 6.681 6.681 0 00-6.752-6.3zm3.144 8.888A3.741 3.741 0 0117.5 21.2h-9a5.751 5.751 0 115.427-7.652.234.234 0 00.225.152h3.237a3.75 3.75 0 013 6.138z\"/><path d=\"M17.389 15.2h-3.237a1.739 1.739 0 01-1.641-1.155A4.251 4.251 0 008.484 11.2a4.4 4.4 0 00-.737.063 4.213 4.213 0 00-3.4 3.261 4.291 4.291 0 00.853 3.6A4.237 4.237 0 008.5 19.7h9a2.254 2.254 0 002.207-2.7 2.337 2.337 0 00-2.318-1.8zM8.25 16.948A1.75 1.75 0 1110 15.2a1.753 1.753 0 01-1.75 1.748z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});