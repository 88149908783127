define("ember-svg-jar/inlined/lucide-air-vent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6 12H4a2 2 0 01-2-2V5a2 2 0 012-2h16a2 2 0 012 2v5a2 2 0 01-2 2h-2M6 8h12M18.3 17.7a2.5 2.5 0 01-3.16 3.83 2.53 2.53 0 01-1.14-2V12M6.6 15.6A2 2 0 1010 17v-5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});