define("ember-svg-jar/inlined/coffee-read", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>coffee-read</title><path d=\"M12.937 13.5a.5.5 0 00-.5.5v5.5a2 2 0 002 2h4a1.993 1.993 0 001.723-1h.34a3.5 3.5 0 000-7zM22 17a1.491 1.491 0 01-1.464 1.5.091.091 0 01-.07-.025.1.1 0 01-.029-.068V15.77a.249.249 0 01.29-.247A1.483 1.483 0 0122 17zM23.937 23.25a.75.75 0 00-.75-.75h-12.5a.75.75 0 100 1.5h12.5a.75.75 0 00.75-.75z\"/><path d=\"M10.887 18.332a.981.981 0 00.432-.8V4.065a.5.5 0 01.171-.376c.617-.54 2.8-1.45 6.619-1.659a.5.5 0 01.528.5v9.22a1 1 0 002 0V1.828a1.841 1.841 0 00-.556-1.315A1.876 1.876 0 0018.765 0c-2.555.076-6.4.593-8.3 1.967a.248.248 0 01-.293 0C8.273.593 4.428.076 1.872 0A1.866 1.866 0 00.557.513 1.842 1.842 0 000 1.828v11.56a1.815 1.815 0 001.5 1.789V18a1 1 0 001 1 1.02 1.02 0 00.219-.024c.043-.009 4.451-.983 7.075-.233a1 1 0 001.093-.411zM2 2.529a.5.5 0 01.528-.5c3.805.208 5.99 1.113 6.613 1.637a.5.5 0 01.178.383v10.127a.5.5 0 01-.708.455 18 18 0 00-6.141-1.39.5.5 0 01-.47-.5zm1.788 14.223a.251.251 0 01-.288-.247v-.881a.247.247 0 01.086-.188.25.25 0 01.2-.06A16.537 16.537 0 016.6 16a.25.25 0 01-.066.488 25.256 25.256 0 00-2.746.264z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});