define("ember-svg-jar/inlined/virtual-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>virtual-box</title><path d=\"M21 20.5a2.5 2.5 0 00-2.5-2.5h-1.917a.249.249 0 01-.241-.184 4.5 4.5 0 00-8.713.113.093.093 0 01-.091.071H7.5A2.5 2.5 0 005 20.5v2A1.5 1.5 0 006.5 24h13a1.5 1.5 0 001.5-1.5zm-9-4A2.5 2.5 0 119.5 19a2.5 2.5 0 012.5-2.5zM17.015 8.48V4.395a.25.25 0 00-.38-.213L12.87 6.476a.248.248 0 00-.12.213v4.779a.25.25 0 00.38.214l3.162-1.92a1.509 1.509 0 00.723-1.282zM11.871 5.169a.25.25 0 00.26 0l3.838-2.34a.25.25 0 00.011-.419L12.852.263a1.511 1.511 0 00-1.7 0l-3.11 2.15a.25.25 0 00.012.419zM11.25 6.689a.25.25 0 00-.12-.214L7.4 4.192a.249.249 0 00-.38.213v4.076a1.511 1.511 0 00.717 1.28l3.138 1.917a.249.249 0 00.38-.213zM16.793 13.792a1 1 0 001.414 1.414l1.061-1.06a1 1 0 00-1.414-1.415zM22.707 9.292a1 1 0 00-1.414 0l-1.061 1.061a1 1 0 001.414 1.414l1.061-1.061a1 1 0 000-1.414zM7.207 15.206a1 1 0 000-1.414l-1.061-1.061a1 1 0 00-1.414 1.415l1.061 1.06a1 1 0 001.414 0zM2.707 9.292a1 1 0 10-1.414 1.414l1.061 1.061a1 1 0 001.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});