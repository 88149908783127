define("ember-svg-jar/inlined/style-three-pin-printer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-printer</title><path d=\"M14 14h-4a.5.5 0 000 1h4a.5.5 0 000-1z\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM8.5 4a.5.5 0 01.5-.5h4a.5.5 0 01.354.146l2 2A.5.5 0 0115.5 6v1a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5zm8.765 11.341a1.5 1.5 0 01-1.242.659H7.977a1.5 1.5 0 01-1.392-2.057l.851-2.129A.5.5 0 017.9 11.5h8.2a.5.5 0 01.464.314l.851 2.129a1.5 1.5 0 01-.15 1.398zM18.5 9.5a.5.5 0 01-.5.5h-.793a.249.249 0 00-.235.167.5.5 0 01-.472.333h-9a.5.5 0 01-.472-.333.249.249 0 00-.235-.167H6a.5.5 0 010-1h.793a.249.249 0 00.235-.167A.5.5 0 017.5 8.5h9a.5.5 0 01.472.333.249.249 0 00.235.167H18a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});