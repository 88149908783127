define("ember-svg-jar/inlined/smart-watch-circle-mail-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-mail-alternate</title><path d=\"M21.5 12A9.5 9.5 0 0017 3.927a.245.245 0 01-.109-.148L15.983.373A.5.5 0 0015.5 0h-7a.5.5 0 00-.484.371l-.908 3.408A.245.245 0 017 3.927a9.5 9.5 0 000 16.149.25.25 0 01.109.148l.908 3.407A.5.5 0 008.5 24h7a.5.5 0 00.482-.368l.935-3.428a.247.247 0 01.109-.146A9.5 9.5 0 0021.5 12zM12 19.5a7.5 7.5 0 117.5-7.5 7.509 7.509 0 01-7.5 7.5z\"/><path d=\"M16.574 10.835a.177.177 0 00-.182 0l-3.536 2.224a1.612 1.612 0 01-1.712 0L7.608 10.84a.18.18 0 00-.275.153v3.425A1.082 1.082 0 008.41 15.5h7.179a1.082 1.082 0 001.077-1.084v-3.423a.18.18 0 00-.092-.158z\"/><path d=\"M15.589 8.5H8.41a1.051 1.051 0 00-1.077 1.024.053.053 0 00.025.043l4.356 2.583a.566.566 0 00.571 0l4.356-2.584a.048.048 0 00.025-.042A1.051 1.051 0 0015.589 8.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});