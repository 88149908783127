define("ember-svg-jar/inlined/athletics-jumping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>athletics-jumping</title><path d=\"M6 2a.5.5 0 01.5.5V3a1 1 0 002 0v-.5A.5.5 0 019 2h6a.5.5 0 01.5.5V3a1 1 0 002 0v-.5A.5.5 0 0118 2a1 1 0 000-2H6a1 1 0 000 2zM4 9a.5.5 0 01.5.5v1a1 1 0 002 0v-1A.5.5 0 017 9h10a.5.5 0 01.5.5v1a1 1 0 002 0v-1A.5.5 0 0120 9a1 1 0 001-1V6a1 1 0 00-1-1H4a1 1 0 00-1 1v2a1 1 0 001 1zM23.062 17.39A1.5 1.5 0 0024 16v-2a1.5 1.5 0 00-1.5-1.5h-21A1.5 1.5 0 000 14v2a1.5 1.5 0 00.938 1.39.5.5 0 01.312.464v3.9A.25.25 0 011 22a1 1 0 000 2h2.5a1 1 0 000-2 .25.25 0 01-.25-.25V18a.5.5 0 01.5-.5h16.5a.5.5 0 01.5.5v3.75a.25.25 0 01-.25.25 1 1 0 000 2H23a1 1 0 000-2 .25.25 0 01-.25-.25v-3.9a.5.5 0 01.312-.46z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});