define("ember-svg-jar/inlined/app-window-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-user</title><path d=\"M11.275 9.084a.75.75 0 00-.75-.75h-5a.75.75 0 000 1.5h5a.75.75 0 00.75-.75zM5.525 12.834a.75.75 0 000 1.5h3a.75.75 0 100-1.5z\"/><circle cx=\"18.665\" cy=\"14.737\" r=\"3.64\"/><path d=\"M18.525 19.088a5.6 5.6 0 00-5.487 4.385.5.5 0 00.487.611h10a.5.5 0 00.487-.611 5.6 5.6 0 00-5.487-4.385zM11.975 18.417h-9a1 1 0 01-1-1V5.167a.25.25 0 01.25-.25h19.5a.25.25 0 01.25.25v3.75a1 1 0 001 1 1 1 0 001-1v-6a3 3 0 00-3-3h-18a3 3 0 00-3 3v14.5a3 3 0 003 3h9a1 1 0 001-1 1 1 0 00-1-1zm-2.346-16.5a.967.967 0 011.693 0 .98.98 0 01.153.5.974.974 0 01-.153.5.967.967 0 01-1.693 0 .966.966 0 01-.154-.5.972.972 0 01.154-.5zm-3.5 0a.967.967 0 011.693 0 .98.98 0 01.153.5.974.974 0 01-.153.5.967.967 0 01-1.693 0 .966.966 0 01-.154-.5.972.972 0 01.154-.5zm-3.562.092a1 1 0 01.908-.592.988.988 0 01.847.5.98.98 0 01.153.5.974.974 0 01-.153.5.967.967 0 01-1.693 0 .966.966 0 01-.154-.5.983.983 0 01.092-.408z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});