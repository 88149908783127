define("ember-svg-jar/inlined/outdoors-camp-fire-guitar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-camp-fire-guitar</title><path d=\"M19.425 12.075a.3.3 0 00-.378.438 5 5 0 01.281 3.959 1 1 0 01-1.62.42 2.016 2.016 0 01-.523-.756.29.29 0 00-.414-.16 4.186 4.186 0 00-1.713 3.756 4.469 4.469 0 008.934-.2 7.3 7.3 0 00-4.567-7.457zM13.013 7.815l2.1-2.1a.5.5 0 01.347-.146 1.749 1.749 0 001.214-.512l2.062-2.062A1.754 1.754 0 1016.257.512L14.2 2.574a1.752 1.752 0 00-.513 1.215.5.5 0 01-.146.347l-2.1 2.1a.5.5 0 01-.6.081 4.737 4.737 0 00-1.707-.588 3.225 3.225 0 00-2.774.843A2.961 2.961 0 005.538 8.2a.629.629 0 01-.7.526A4.3 4.3 0 001.2 9.878a4.341 4.341 0 00-1.168 3.628 6.45 6.45 0 001.9 3.81 6.316 6.316 0 004.386 1.928 4.24 4.24 0 003.052-1.2 4.314 4.314 0 001.155-3.632.636.636 0 01.527-.7 2.975 2.975 0 001.623-.817 3.718 3.718 0 00.253-4.482.5.5 0 01.085-.598zM6.3 15.822a.837.837 0 01-1.183 0L3.429 14.13a.837.837 0 111.183-1.183L6.3 14.638a.837.837 0 010 1.184zm3.608-4a1.754 1.754 0 11-2.48-2.481 1.8 1.8 0 012.48 0 1.754 1.754 0 010 2.481z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});