define("ember-svg-jar/inlined/season-winter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>season-winter</title><path d=\"M11.344 19.538a1 1 0 00-.36-1.368L6.1 15.321a.5.5 0 01-.248-.432V9.612A.5.5 0 016.1 9.18l4.5-2.625a.5.5 0 01.5 0l4.5 2.625a.5.5 0 01.248.432V11.5a1 1 0 002 0V9.508a.311.311 0 01.463-.273l1.9 1.053a1 1 0 10.969-1.749l-.613-.339a.251.251 0 010-.436l.467-.27a1 1 0 10-1-1.732l-.467.27a.25.25 0 01-.375-.221L19.2 5.1a1 1 0 00-.982-1.018 1.022 1.022 0 00-1.018.982l-.038 2.177a.309.309 0 01-.462.259l-4.693-2.733a.316.316 0 010-.543l1.862-1.119a1 1 0 00-1.03-1.715l-.606.365a.25.25 0 01-.379-.215V1a1 1 0 00-2 0v.54a.25.25 0 01-.379.215l-.609-.365a1 1 0 00-1.03 1.715L9.7 4.224a.315.315 0 010 .543L5 7.5a.309.309 0 01-.465-.262L4.5 5.066a1 1 0 00-2 .035l.013.707a.253.253 0 01-.123.22.25.25 0 01-.252 0l-.468-.27a1 1 0 00-1 1.732l.468.27a.25.25 0 010 .435l-.619.343a1 1 0 10.97 1.749l1.9-1.053a.312.312 0 01.464.273v5.485a.313.313 0 01-.464.273l-1.9-1.054a1 1 0 00-.97 1.749l.619.343a.25.25 0 010 .435l-.468.27a1 1 0 101 1.732l.468-.27a.25.25 0 01.375.221L2.5 19.4a1 1 0 00.983 1.017H3.5a1 1 0 001-.983l.038-2.176A.309.309 0 015 17l4.973 2.9a1 1 0 001.368-.36z\"/><path d=\"M23.942 13.753a.5.5 0 00-.674-.391 8.863 8.863 0 01-3.724.388 6.673 6.673 0 00-3.9.612 3.793 3.793 0 00-2.158 2.706 2.782 2.782 0 00.369 1.654.5.5 0 01-.112.618 20.491 20.491 0 00-3.274 3.484.75.75 0 001.233.854 24.287 24.287 0 016.89-5.98.75.75 0 01.756 1.295 23.331 23.331 0 00-3.553 2.556.5.5 0 00-.031.724 2.273 2.273 0 00.6.446 2.52 2.52 0 001.147.265 5.405 5.405 0 002.7-.864c2.001-1.183 4.175-5.452 3.731-8.367z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});