define("ember-svg-jar/inlined/navigation-arrows-left-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-arrows-left-1</title><path d=\"M15.31 21.969V20.5a.751.751 0 00-.278-.583l-8.579-6.948a1.288 1.288 0 010-1.943l8.579-6.946a.75.75 0 00.278-.58V2.026a1.25 1.25 0 00-2.022-.983L.6 11.014a1.289 1.289 0 000 1.966l12.691 9.972a1.25 1.25 0 002.022-.983z\"/><path d=\"M9.145 11.014a1.289 1.289 0 000 1.966l12.692 9.972a1.249 1.249 0 002.021-.983V2.026a1.249 1.249 0 00-2.021-.983z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});