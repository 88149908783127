define("ember-svg-jar/inlined/road-sign-look-both-ways", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-look-both-ways</title><path d=\"M24 7a.75.75 0 00-.75-.75H.75A.75.75 0 000 7v10a.75.75 0 00.75.75h22.5A.75.75 0 0024 17zm-8 4.25a.25.25 0 00.25-.25V9.5a.75.75 0 011.186-.61l3.5 2.5a.757.757 0 010 1.22l-3.5 2.5a.75.75 0 01-1.186-.61V13a.25.25 0 00-.25-.25H8a.25.25 0 00-.25.25v1.5a.75.75 0 01-1.186.61l-3.5-2.5a.757.757 0 010-1.22l3.5-2.5a.75.75 0 011.186.61V11a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});