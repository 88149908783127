define("ember-svg-jar/inlined/people-man-6", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-6</title><path d=\"M12.87 13.014a2.441 2.441 0 00.435-.181.639.639 0 00-.3-1.2H11a.639.639 0 00-.306 1.2 2.423 2.423 0 00.438.181 2.532 2.532 0 00.868.126h.02a3.022 3.022 0 00.85-.126z\"/><path d=\"M22.836 19.02a15.7 15.7 0 00-7.6-2.818.25.25 0 01-.083-.47 8.57 8.57 0 00.267-.145.251.251 0 01.259.007A2.45 2.45 0 0017 16a2.5 2.5 0 002.5-2.5v-6a7.5 7.5 0 00-15 0v6A2.5 2.5 0 007 16a2.45 2.45 0 001.326-.406.251.251 0 01.259-.007c.088.05.177.1.267.145a.25.25 0 01-.083.47 15.707 15.707 0 00-7.606 2.818A2.961 2.961 0 000 21.394V23a1 1 0 002 0v-1.606a.98.98 0 01.376-.785 11.026 11.026 0 012.786-1.5.25.25 0 01.338.234v4.4a.25.25 0 00.25.25h12.5a.25.25 0 00.25-.25v-4.4a.25.25 0 01.338-.234 11 11 0 012.785 1.5.978.978 0 01.377.785V23a1 1 0 002 0v-1.606a2.959 2.959 0 00-1.164-2.374zM7.791 12.2a.249.249 0 01.056-.332A2.992 2.992 0 009 9.5v-.84a.249.249 0 01.3-.243 3.158 3.158 0 002.543-.556.249.249 0 01.3 0 3.154 3.154 0 002.543.555.251.251 0 01.3.244v.84a2.992 2.992 0 001.153 2.368.248.248 0 01.057.332 5 5 0 01-8.419 0zM12 20a3.832 3.832 0 01-2.949-1.43.251.251 0 01.163-.409 24.111 24.111 0 015.572 0 .249.249 0 01.162.408A3.833 3.833 0 0112 20z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});