define("ember-svg-jar/inlined/road-sign-two-way", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-two-way</title><path d=\"M23.26 23.235a.751.751 0 00.661-1.105l-11.26-21a.782.782 0 00-1.322 0l-11.26 21a.751.751 0 00.661 1.105zM8.209 12.443l1.5-1.5a.752.752 0 011.061 0l1.5 1.5a.75.75 0 01-.53 1.28h-.5a.25.25 0 00-.25.25v5a.75.75 0 01-1.5 0v-5a.25.25 0 00-.25-.25h-.5a.75.75 0 01-.531-1.28zm8.061 5.572l-1.5 1.5a.752.752 0 01-1.061 0l-1.5-1.5a.75.75 0 01.531-1.28h.5a.25.25 0 00.25-.25v-5a.75.75 0 011.5 0v5a.25.25 0 00.25.25h.5a.75.75 0 01.53 1.28z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});