define("ember-svg-jar/inlined/lucide-align-center-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 2v20M8 10H4a2 2 0 01-2-2V6c0-1.1.9-2 2-2h4M16 10h4a2 2 0 002-2V6a2 2 0 00-2-2h-4M8 20H7a2 2 0 01-2-2v-2c0-1.1.9-2 2-2h1M16 14h1a2 2 0 012 2v2a2 2 0 01-2 2h-1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});