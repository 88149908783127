define("ember-svg-jar/inlined/align-middle-move-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>align-middle-move-vertical</title><path d=\"M23 11h-3.25a.25.25 0 01-.25-.25V6.5a1 1 0 00-1-1h-4a1 1 0 00-1 1v4.25a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25V3.5a1 1 0 00-1-1h-4a1 1 0 00-1 1v7.25a.25.25 0 01-.25.25H1a1 1 0 000 2h3.25a.25.25 0 01.25.25v7.25a1 1 0 001 1h4a1 1 0 001-1v-7.25a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25v4.25a1 1 0 001 1h4a1 1 0 001-1v-4.25a.25.25 0 01.25-.25H23a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});