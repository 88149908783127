define("ember-svg-jar/inlined/phone-dial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-dial</title><path d=\"M21.1.921a45.417 45.417 0 00-18.2 0A3.7 3.7 0 000 4.411v2a2.5 2.5 0 002.5 2.5h3a2.5 2.5 0 002.5-2.5V5.6a.254.254 0 01.232-.253 45.366 45.366 0 017.536 0A.254.254 0 0116 5.6v.812a2.5 2.5 0 002.5 2.5h3a2.5 2.5 0 002.5-2.5v-2A3.7 3.7 0 0021.1.921z\"/><circle cx=\"6.5\" cy=\"12.251\" r=\"1.75\"/><circle cx=\"6.5\" cy=\"17.251\" r=\"1.75\"/><circle cx=\"6.5\" cy=\"22.25\" r=\"1.75\"/><circle cx=\"12\" cy=\"12.251\" r=\"1.75\"/><circle cx=\"12\" cy=\"17.251\" r=\"1.75\"/><circle cx=\"12\" cy=\"22.25\" r=\"1.75\"/><circle cx=\"17.5\" cy=\"12.251\" r=\"1.75\"/><circle cx=\"17.5\" cy=\"17.251\" r=\"1.75\"/><circle cx=\"17.5\" cy=\"22.25\" r=\"1.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});