define("ember-svg-jar/inlined/bench", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bench</title><path d=\"M23.773 15.281L22.7 13.352a1.5 1.5 0 00-1.2-.6h-.25a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h.25A1.5 1.5 0 0023 9.25v-5.5a1.5 1.5 0 00-1.5-1.5h-.75a.5.5 0 01-.5-.5v-.5a1.25 1.25 0 00-2.5 0v.5a.5.5 0 01-.5.5H6.75a.5.5 0 01-.5-.5v-.5a1.25 1.25 0 00-2.5 0v.5a.5.5 0 01-.5.5H2.5A1.5 1.5 0 001 3.75v5.5a1.5 1.5 0 001.5 1.5h.25a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H2.5a1.526 1.526 0 00-1.237.658L.25 15.25a1.25 1.25 0 001 2h1.016a.248.248 0 01.2.1.251.251 0 01.046.215l-1.225 4.882a1.251 1.251 0 00.91 1.516A1.27 1.27 0 002.5 24a1.251 1.251 0 001.212-.946l1.356-5.425a.5.5 0 01.485-.379h12.893a.5.5 0 01.485.379l1.356 5.425A1.251 1.251 0 0021.5 24a1.264 1.264 0 00.3-.037 1.251 1.251 0 00.91-1.516l-1.222-4.886a.251.251 0 01.046-.215.248.248 0 01.2-.1h1.016A1.252 1.252 0 0024 16a1.267 1.267 0 00-.227-.719zM5.75 11.25a.5.5 0 01.5-.5h11.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H6.25a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});