define("ember-svg-jar/inlined/single-woman-actions-text.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-text</title><path d=\"M9.5 12.5c0-.074 0-.148.008-.221a.5.5 0 00-.367-.519 7.373 7.373 0 00-1.935-.26 7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643H9a.5.5 0 00.5-.5zM24 13a2 2 0 00-2-2h-9a2 2 0 00-2 2v9a2 2 0 002 2h9a2 2 0 002-2zm-11 .25a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v8.5a.249.249 0 01-.25.25h-8.5a.25.25 0 01-.25-.25z\"/><path d=\"M20 15h-4.75a.75.75 0 000 1.5H20a.75.75 0 000-1.5zM17.5 19.5a.75.75 0 000-1.5h-2.25a.75.75 0 000 1.5zM1.566 10.442a1 1 0 001.383-.3 5.233 5.233 0 00.454-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .249.249 0 01.411.082 5.233 5.233 0 00.454.9 1 1 0 001.681-1.082 4.569 4.569 0 01-.647-2.511V5.3a5.3 5.3 0 10-10.59 0v1.248a4.573 4.573 0 01-.642 2.512 1 1 0 00.298 1.382zM7.208 8.6a2.706 2.706 0 01-2.742-2.352.5.5 0 01.324-.577 5.708 5.708 0 002.246-1.439.249.249 0 01.36 0 5.719 5.719 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.208 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});