define("ember-svg-jar/inlined/smiley-petrified", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-petrified</title><path d=\"M16.5 17.916a8.921 8.921 0 014.722-7.672 2.108 2.108 0 012.449.286.133.133 0 00.225-.111 12 12 0 00-23.792 0 .134.134 0 00.073.136.132.132 0 00.152-.025 2.11 2.11 0 012.449-.286A8.921 8.921 0 017.5 17.916a31.347 31.347 0 01-.33 4.864.25.25 0 00.15.269 11.991 11.991 0 009.36 0 .25.25 0 00.15-.269 31.347 31.347 0 01-.33-4.864zM12 13a2.5 2.5 0 012.5 2.5v2a2.5 2.5 0 01-5 0v-2A2.5 2.5 0 0112 13zm4.5-2.5a2.292 2.292 0 01-2-2.5 2.292 2.292 0 012-2.5 2.293 2.293 0 012 2.5 2.293 2.293 0 01-2 2.5zM5.5 8a2.292 2.292 0 012-2.5 2.293 2.293 0 012 2.5 2.293 2.293 0 01-2 2.5 2.292 2.292 0 01-2-2.5z\"/><path d=\"M4.944 23.67a21.426 21.426 0 00.556-5.754 7.313 7.313 0 00-2.511-5.188 2.7 2.7 0 00-1.3-.728.417.417 0 00-.071.1 3.509 3.509 0 00.215.8 14.3 14.3 0 01.81 5.022 7.5 7.5 0 01-2.185 5.635.25.25 0 00.174.43h4.036a.25.25 0 00.245-.2 1.011 1.011 0 01.031-.117zM21.357 17.916a14.3 14.3 0 01.81-5.022 3.466 3.466 0 00.214-.8.43.43 0 00-.07-.1 2.7 2.7 0 00-1.3.728 7.313 7.313 0 00-2.511 5.194 21.359 21.359 0 00.558 5.759 1.127 1.127 0 01.028.111.251.251 0 00.246.2h4.036a.25.25 0 00.173-.43 7.5 7.5 0 01-2.184-5.64z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});