define("ember-svg-jar/inlined/seo-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seo-search</title><path d=\"M2.25 3.74H11a1 1 0 000-2H1.58A1.544 1.544 0 000 3.24v3a1.544 1.544 0 001.58 1.5H11a1 1 0 000-2H2.25A.25.25 0 012 5.49v-1.5a.25.25 0 01.25-.25zM21.412 7.237a.249.249 0 01-.043-.3A4.182 4.182 0 1019.7 8.609a.251.251 0 01.3.042l2.295 2.3a1 1 0 001.414-1.414zM17.7 7.12a2.18 2.18 0 112.18-2.18 2.182 2.182 0 01-2.18 2.18z\"/><circle cx=\"1.5\" cy=\"16.74\" r=\"1.5\"/><circle cx=\"1.5\" cy=\"21.74\" r=\"1.5\"/><path d=\"M14.5 15.74H5a1 1 0 100 2h9.5a1 1 0 100-2z\"/><circle cx=\"1.5\" cy=\"11.74\" r=\"1.5\"/><path d=\"M14.5 10.74H5a1 1 0 000 2h9.5a1 1 0 000-2zM14.5 20.74H5a1 1 0 000 2h9.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});