define("ember-svg-jar/inlined/safety-warning-radioactive-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-warning-radioactive-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M20.538 9.782c-.081-.052-.163-.1-.244-.148a6.128 6.128 0 00-2.426-.788.25.25 0 01-.2-.35A6.135 6.135 0 0018.2 6q0-.3-.028-.595a.989.989 0 00-1.09-.9 1 1 0 00-.9 1.09c.012.133.019.268.019.405a4.054 4.054 0 01-.353 1.64.25.25 0 01-.386.093 5.461 5.461 0 00-6.913 0 .253.253 0 01-.217.049.247.247 0 01-.17-.143A4.062 4.062 0 017.8 6c0-.137.007-.272.019-.405a1 1 0 00-1.991-.19Q5.8 5.7 5.8 6a6.131 6.131 0 00.529 2.491.25.25 0 01-.2.35 6.147 6.147 0 00-2.423.792c-.083.048-.165.1-.246.149a1 1 0 001.074 1.687l.164-.1A4.071 4.071 0 016.3 10.85a.25.25 0 01.275.289A5.427 5.427 0 006.5 12a5.509 5.509 0 003.53 5.134.25.25 0 01.112.382A4.1 4.1 0 018.9 18.634a1 1 0 101 1.732 6.058 6.058 0 001.9-1.738.25.25 0 01.407 0 6.061 6.061 0 001.9 1.738 1 1 0 101-1.732 4.1 4.1 0 01-1.24-1.118.252.252 0 01-.039-.219.249.249 0 01.15-.163A5.509 5.509 0 0017.5 12a5.389 5.389 0 00-.069-.858.249.249 0 01.066-.213.254.254 0 01.209-.076 4.075 4.075 0 011.588.513l.161.1a1 1 0 001.073-1.687zm-6.427-.56a.248.248 0 01.1.214.251.251 0 01-.125.2 4.164 4.164 0 01-4.168 0 .253.253 0 01-.125-.2.251.251 0 01.1-.214 3.447 3.447 0 014.222 0zM8.5 12a3.121 3.121 0 01.033-.446.25.25 0 01.372-.182A4.167 4.167 0 0111 14.98a.25.25 0 01-.347.231A3.488 3.488 0 018.5 12zm4.851 3.211A.25.25 0 0113 14.98a4.161 4.161 0 012.1-3.606.248.248 0 01.235-.008.251.251 0 01.138.189A3.271 3.271 0 0115.5 12a3.489 3.489 0 01-2.149 3.211z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});