define("ember-svg-jar/inlined/road-sign-speed-limit-30", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-speed-limit-30</title><path d=\"M16.286 9.535a1.728 1.728 0 00-1.726 1.727v2.475a1.726 1.726 0 103.452 0v-2.475a1.729 1.729 0 00-1.726-1.727z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm-1.163 12.662a2.606 2.606 0 01-1.98 4.3H5.762a.75.75 0 010-1.5h3.1a1.108 1.108 0 000-2.215H7a.75.75 0 010-1.5h1.857a1.107 1.107 0 100-2.214H5.762a.75.75 0 010-1.5h3.1a2.606 2.606 0 011.98 4.3.249.249 0 00-.005.329zm8.675 1.075a3.226 3.226 0 11-6.452 0v-2.475a3.226 3.226 0 116.452 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});