define("ember-svg-jar/inlined/circus-clown-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>circus-clown-1</title><path d=\"M23.367 4.846A3 3 0 0021 0a2.975 2.975 0 00-1.846.633.249.249 0 01-.308 0A3 3 0 0014 2.9a.246.246 0 01-.091.185.249.249 0 01-.2.054 10.525 10.525 0 00-3.422 0A.249.249 0 0110 2.9 3 3 0 005.154.633a.249.249 0 01-.308 0A3 3 0 000 3a2.975 2.975 0 00.633 1.846.249.249 0 010 .308A2.975 2.975 0 000 7a3.009 3.009 0 001.916 2.8.249.249 0 01.146.313 10.5 10.5 0 1019.876 0 .249.249 0 01.146-.313A3.009 3.009 0 0024 7a2.975 2.975 0 00-.633-1.846.249.249 0 010-.308zM12 22a8.5 8.5 0 118.5-8.5A8.51 8.51 0 0112 22z\"/><circle cx=\"12\" cy=\"14\" r=\"2.5\"/><path d=\"M17.25 14A5.025 5.025 0 0112 18.75 5.025 5.025 0 016.75 14a.75.75 0 00-1.5 0A6.522 6.522 0 0012 20.25 6.522 6.522 0 0018.75 14a.75.75 0 00-1.5 0zM7 10.25h.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.5a.25.25 0 01.25-.25h.5a.75.75 0 000-1.5h-.5a.25.25 0 01-.25-.25V8a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25H7a.75.75 0 000 1.5zM14 10.25h.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.5a.25.25 0 01.25-.25h.5a.75.75 0 000-1.5h-.5a.25.25 0 01-.25-.25V8a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25H14a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});