define("ember-svg-jar/inlined/allowances-no-sitting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>allowances-no-sitting</title><path d=\"M12.245 17.878a.979.979 0 00.979.979h1.064a.245.245 0 00.173-.418l-1.38-1.38a.244.244 0 00-.277-.048.955.955 0 00-.559.867zM6.467 15.062l-1.519 1.774a1.225 1.225 0 001.86 1.594l1.529-1.787a.133.133 0 01.235.084v.906a1.225 1.225 0 002.449 0v-1.762a.2.2 0 01.2-.2.2.2 0 00.183-.122.2.2 0 00-.043-.215l-1.92-1.916a.215.215 0 00-.2-.057 5.1 5.1 0 00-2.774 1.701zM6.612 12.49c.276 0 .859 0 1.3-.006a.245.245 0 00.171-.418L6.28 10.257a.246.246 0 00-.3-.036 1.222 1.222 0 00.632 2.269zM12.493 7.1a2.2 2.2 0 10-.006 0h.006z\"/><path d=\"M0 12A12 12 0 1012 0 12.013 12.013 0 000 12zM12 1.959a10.025 10.025 0 017.6 16.581.107.107 0 01-.078.037.106.106 0 01-.081-.031l-.006-.006a.109.109 0 01-.013-.137.928.928 0 00.17-.525.979.979 0 00-.98-.98H17.9a.245.245 0 01-.173-.072l-3.936-3.935a.247.247 0 01-.072-.174V9.306a1.224 1.224 0 00-1.9-1.019l-1.41.94a.245.245 0 01-.309-.03L5.559 4.664a.248.248 0 01-.071-.184.242.242 0 01.087-.18A9.982 9.982 0 0112 1.959zM3.2 7.176a9.575 9.575 0 01.666-1.037.245.245 0 01.371-.029L17.89 19.765a.245.245 0 01-.029.371 9.575 9.575 0 01-1.037.666 10 10 0 01-11.1-.979 10.125 10.125 0 01-2.036-2.2A10.014 10.014 0 013.2 7.176z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});