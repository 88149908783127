define("ember-svg-jar/inlined/responsive-design-cursor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>responsive-design-cursor</title><path d=\"M17.75 18.113a3 3 0 003-3v-1.5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v3.5a1 1 0 001 1zM6.25 18.113a1 1 0 000-2h-.5a.5.5 0 01-.5-.5v-9a.5.5 0 01.5-.5h7.5a1 1 0 000-2h-9.5a3 3 0 00-3 3v8a3 3 0 003 3zM23.25 3.113a2 2 0 00-2-2h-3a2 2 0 00-2 2v6.5a2 2 0 002 2h3a2 2 0 002-2zm-5 .25a.25.25 0 01.25-.25H21a.25.25 0 01.25.25v4.5a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25zM16.458 22.594a1 1 0 000-1.414l-2.748-2.748a.247.247 0 01-.069-.224.25.25 0 01.147-.182l.963-.413a.5.5 0 00-.039-.933l-5.3-1.769a.5.5 0 00-.632.633l1.768 5.3a.5.5 0 00.933.039l.413-.962a.249.249 0 01.406-.078l2.748 2.747a1 1 0 001.414 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});