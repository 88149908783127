define("ember-svg-jar/inlined/accounting-invoice-mail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accounting-invoice-mail</title><path d=\"M23.742 11.063a.5.5 0 00-.506.013l-2.352 1.47a.25.25 0 01-.382-.212V1.963A1.983 1.983 0 0018.5 0h-13a1.983 1.983 0 00-2 1.963v10.372a.25.25 0 01-.382.212L.765 11.076A.5.5 0 000 11.5V22a2 2 0 002 2h20a2 2 0 002-2V11.5a.5.5 0 00-.258-.437zM5.5 13.9V3a1 1 0 011-1l11-.031a1 1 0 011 1V13.9a.25.25 0 01-.117.212l-2 1.247a.252.252 0 00-.117.191.248.248 0 00.083.208l4.151 3.681a.75.75 0 01-1 1.122l-4.216-3.748a.249.249 0 00-.166-.063H8.88a.249.249 0 00-.166.063L4.5 20.561a.75.75 0 11-1-1.122l4.145-3.684a.248.248 0 00.083-.208.252.252 0 00-.117-.191l-2-1.247A.25.25 0 015.5 13.9z\"/><path d=\"M10.351 9.217l2.306.923a.5.5 0 01-.186.963H10.2a1 1 0 000 2h.427a.25.25 0 01.25.25v.147a1 1 0 002 0v-.234a.251.251 0 01.19-.243 2.493 2.493 0 00.334-4.74l-2.308-.923a.5.5 0 01.186-.963h2.273a1 1 0 000-2h-.427a.25.25 0 01-.25-.25V4a1 1 0 00-2 0v.234a.251.251 0 01-.19.243 2.493 2.493 0 00-.334 4.74z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});