define("ember-svg-jar/inlined/data-file-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-share</title><path d=\"M21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v9.619a.248.248 0 00.469.114 3.59 3.59 0 011.4-1.51.243.243 0 00.131-.21V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-7.518a.247.247 0 00-.212.38 4.231 4.231 0 01.616 1.424.246.246 0 00.238.2H22a2 2 0 002-2V4.414A2 2 0 0023.414 3z\"/><path d=\"M13.063 10.568a.232.232 0 00.3 0l.569-.8a.252.252 0 01.333-.071l1.507.905A1 1 0 0017 10.451a.9.9 0 00.105-.123l2.688-3.734a1 1 0 10-1.624-1.168l-2.015 2.8a.252.252 0 01-.332.068L14.3 7.387a1 1 0 00-1.33.28l-1.261 1.785a.245.245 0 00.118.372 4.27 4.27 0 011.236.744zM7.537 14.059a.245.245 0 01-.115.221L4.558 16a.245.245 0 01-.266-.012 2.71 2.71 0 00-2.406-.349 2.706 2.706 0 00-1.762 1.774A2.759 2.759 0 002.75 21a2.729 2.729 0 001.887-.761.245.245 0 01.26-.053l2.463 1a.239.239 0 01.14.214 2.753 2.753 0 105.361-1.025 2.683 2.683 0 00-1.736-1.74 2.74 2.74 0 00-2.767.623.24.24 0 01-.258.053L5.553 18.3a.114.114 0 01-.06-.084l-.03-.353a.118.118 0 01.056-.1L8.442 16a.245.245 0 01.266.012 2.741 2.741 0 004.168-1.425A2.759 2.759 0 0010.25 11a2.808 2.808 0 00-2.713 3.059z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});