define("ember-svg-jar/inlined/wifi-signal-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-signal-5</title><path d=\"M13.25 13.137V9.873a1.25 1.25 0 10-2.5 0v3.264l-4.645 9.289A1 1 0 007 23.873h10a1 1 0 00.895-1.447zM12 5.123a1.25 1.25 0 001.25-1.25v-2.5a1.25 1.25 0 10-2.5 0v2.5A1.25 1.25 0 0012 5.123zM17.48 6.661l1.768-1.768a1.25 1.25 0 00-1.768-1.768l-1.768 1.768a1.25 1.25 0 001.768 1.768zM21 9.123h-2.5a1.25 1.25 0 000 2.5H21a1.25 1.25 0 000-2.5zM6.52 6.661a1.25 1.25 0 001.768-1.768L6.52 3.125a1.25 1.25 0 00-1.768 1.768zM6.75 10.373a1.25 1.25 0 00-1.25-1.25H3a1.25 1.25 0 000 2.5h2.5a1.25 1.25 0 001.25-1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});