define("ember-svg-jar/inlined/bin-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g stroke-linecap=\"round\" stroke-width=\"1.5\" stroke=\"#000\" fill=\"none\" stroke-linejoin=\"round\"><path d=\"M17.25 21H6.75h0a1.5 1.5 0 01-1.5-1.5s0 0 0 0V6h13.5v13.5a1.5 1.5 0 01-1.5 1.5zM9.75 16.5v-6M14.25 16.5v-6M2.25 6h19.5M14.25 3h-4.5 0a1.5 1.5 0 00-1.5 1.5s0 0 0 0V6h7.5V4.5h0a1.5 1.5 0 00-1.5-1.5s0 0 0 0z\"/></g>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});