define("ember-svg-jar/inlined/tags-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-lock</title><path d=\"M17.924 9.613l1.176-1.2A3.979 3.979 0 0020.086 6V2a2 2 0 00-2-2l-4.258.005a3.95 3.95 0 00-2.416 1L.586 11.834a2 2 0 000 2.828L5.422 19.5a1.972 1.972 0 001.406.582h.012a1.975 1.975 0 001.41-.6l6-6.118a.463.463 0 00.116-.2 4.986 4.986 0 013.361-3.429.468.468 0 00.197-.122zM16.586 5.5a2 2 0 11-2-2 2 2 0 012 2zM22.714 15.514a.25.25 0 01-.214-.247v-1.018a3.5 3.5 0 00-7 0v1.018a.249.249 0 01-.214.247A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.286-1.486zM20 20.249a1 1 0 11-1-1 1 1 0 011 1zm-2.5-6a1.5 1.5 0 013 0v1a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});