define("ember-svg-jar/inlined/project-blueprint-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>project-blueprint-home</title><path d=\"M4 16a4 4 0 104 4 4 4 0 00-4-4zm0 6.5A2.5 2.5 0 116.5 20 2.5 2.5 0 014 22.5z\"/><circle cx=\"4\" cy=\"20\" r=\"1.5\"/><path d=\"M9.25 13.75h1a.5.5 0 01.5.5v2.5a1 1 0 001 1h1a1 1 0 001-1v-2a1 1 0 012 0v2a1 1 0 001 1h1a1 1 0 001-1v-2.5a.5.5 0 01.5-.5h1a.5.5 0 00.336-.87l-5.163-4.693a.991.991 0 00-1.345 0L8.914 12.88a.5.5 0 00.336.87z\"/><path d=\"M22 2.5H10.5a1 1 0 000 2h11a.5.5 0 01.5.5v16.5a.5.5 0 01-.5.5h-12a1 1 0 000 2H22a2 2 0 002-2V4.5a2 2 0 00-2-2zM4 0a4 4 0 00-4 4v11.778a.5.5 0 00.821.383 4.933 4.933 0 016.358 0A.5.5 0 008 15.778V4a4 4 0 00-4-4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});