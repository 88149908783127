define("ember-svg-jar/inlined/farming-milk-cow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>farming-milk-cow</title><path d=\"M18.5 18h-13a1.75 1.75 0 00-1.708 2.13l.729 3.283A.749.749 0 005.25 24h13.5a.749.749 0 00.732-.587l.729-3.283A1.748 1.748 0 0018.5 18zM14.5 17a1 1 0 001-1 2.909 2.909 0 00-.588-1.284.519.519 0 00-.824 0A2.909 2.909 0 0013.5 16a1 1 0 001 1zM15.5 12.5a2.909 2.909 0 00-.588-1.284.519.519 0 00-.824 0A2.909 2.909 0 0013.5 12.5a1 1 0 002 0zM9.5 17a1 1 0 001-1 2.909 2.909 0 00-.588-1.284.519.519 0 00-.824 0A2.909 2.909 0 008.5 16a1 1 0 001 1zM10.5 12.5a2.909 2.909 0 00-.588-1.284.519.519 0 00-.824 0A2.909 2.909 0 008.5 12.5a1 1 0 002 0zM23.5 0H.5a.5.5 0 00-.5.5 4.928 4.928 0 002.3 3.87.5.5 0 01.2.4v2.15a1.5 1.5 0 103 0v-.241a.5.5 0 01.642-.48q.7.209 1.46.366a.5.5 0 01.4.489V8.5a1.5 1.5 0 003 0v-1a.5.5 0 01.511-.5q.486.01.979 0a.5.5 0 01.51.5v1a1.5 1.5 0 003 0V7.054a.5.5 0 01.4-.489q.757-.158 1.46-.366a.5.5 0 01.642.48v.243a1.5 1.5 0 103 0v-2.15a.5.5 0 01.2-.4A4.928 4.928 0 0024 .5a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});