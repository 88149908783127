define("ember-svg-jar/inlined/monetization-browser-bag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monetization-browser-bag</title><path d=\"M9 17.75a6.153 6.153 0 006.417 6c3.63 0 6.583-2.691 6.583-6 0-3.567-2.783-5.231-4.12-6.031a.251.251 0 01-.089-.339l1.218-2.132a1 1 0 00-.867-1.5h-5.281a1 1 0 00-.868 1.5l1.2 2.095a.249.249 0 01.023.191.254.254 0 01-.12.152C11.7 12.445 9 14.107 9 17.75zm5.55-4.45a.249.249 0 00.2-.245v-.305a.75.75 0 011.5 0V13a.249.249 0 00.25.25h.5a.75.75 0 010 1.5h-2.033a.592.592 0 00-.22 1.142l2.063.825a2.084 2.084 0 01-.36 3.98.25.25 0 00-.2.245v.308a.75.75 0 01-1.5 0V21a.25.25 0 00-.25-.25H14a.75.75 0 010-1.5h2.033a.592.592 0 00.22-1.141l-2.063-.825a2.084 2.084 0 01.36-3.981z\"/><path d=\"M20 .25H4a3 3 0 00-3 3v11.5a3 3 0 003 3h3a1 1 0 000-2H4a1 1 0 01-1-1V5.5a.25.25 0 01.25-.25h17.5a.25.25 0 01.25.25v7.25a1 1 0 002 0v-9.5a3 3 0 00-3-3zM4.75 3.75A.75.75 0 115.5 3a.749.749 0 01-.75.75zm2.5 0A.75.75 0 118 3a.749.749 0 01-.75.75zm2.5 0A.75.75 0 1110.5 3a.749.749 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});