define("ember-svg-jar/inlined/diagram-split-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-split-vertical</title><path d=\"M23.576 3.3L20.7.422a1.44 1.44 0 00-2.46 1.018v1.19a.25.25 0 01-.25.25h-.05a7.359 7.359 0 00-5.74 2.766.249.249 0 01-.39 0A7.359 7.359 0 006.06 2.88h-.05a.25.25 0 01-.25-.25V1.44A1.44 1.44 0 003.3.422L.424 3.3a1.439 1.439 0 000 2.04L3.3 8.218A1.439 1.439 0 005.76 7.2V6.01a.25.25 0 01.25-.25h.05a4.506 4.506 0 014.5 4.5v12.3a1.44 1.44 0 002.88 0v-12.3a4.506 4.506 0 014.5-4.5h.05a.25.25 0 01.25.25V7.2a1.44 1.44 0 002.46 1.018l2.876-2.878a1.439 1.439 0 000-2.04z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});