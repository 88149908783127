define("ember-svg-jar/inlined/paralympics-swimming", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paralympics-swimming</title><path d=\"M7.073 11.731a2.115 2.115 0 001.488.874 2.8 2.8 0 001.911-1.045 2.579 2.579 0 012-1.006 2.506 2.506 0 012.261 1.425 1.281 1.281 0 001.051.626 1.863 1.863 0 001.475-.845 2.342 2.342 0 01.287-.394.249.249 0 00.018-.312l-4.285-6a1.491 1.491 0 01-.247-.561 1.583 1.583 0 00-.965-1.207 1.507 1.507 0 00-2 1.7 4.483 4.483 0 00.774 1.814l.295.414-4.849 3.333a.249.249 0 00.009.418 2.418 2.418 0 01.777.766z\"/><circle cx=\"18.5\" cy=\"6.092\" r=\"2.375\"/><path d=\"M1.169 16.141A5.834 5.834 0 004.9 14.533a5.24 5.24 0 003.66 1.572 5.865 5.865 0 003.811-1.5 4.667 4.667 0 003.416 1.5 5.146 5.146 0 003.621-1.5 4.763 4.763 0 003.661 1.483A1 1 0 0024 15.023a1.022 1.022 0 00-1.07-.923c-1.566.144-2.668-1.556-2.675-1.57a1.049 1.049 0 00-1.716.023 3.374 3.374 0 01-2.752 1.557 2.8 2.8 0 01-2.405-1.481 1.044 1.044 0 00-1.713-.161 4.31 4.31 0 01-3.109 1.637 3.557 3.557 0 01-2.76-1.58 1.04 1.04 0 00-1.638-.09c-.014.018-1.438 1.831-3.11 1.7A.981.981 0 000 15.083c-.009.187.043 1.058 1.169 1.058zM22.93 18.761c-1.585.136-2.667-1.556-2.675-1.57a1.043 1.043 0 00-1.72.03 3.362 3.362 0 01-2.748 1.551 2.8 2.8 0 01-2.4-1.482 1.046 1.046 0 00-1.715-.16 4.307 4.307 0 01-3.112 1.642 3.557 3.557 0 01-2.76-1.581 1.041 1.041 0 00-1.638-.09c-.022.028-1.51 1.823-3.11 1.7A.983.983 0 000 19.75c-.01.187.042 1.058 1.168 1.058A5.844 5.844 0 004.9 19.2a5.24 5.24 0 003.66 1.572 5.86 5.86 0 003.811-1.5 4.576 4.576 0 003.416 1.5 5.151 5.151 0 003.622-1.5 4.791 4.791 0 003.66 1.483 1 1 0 10-.139-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});