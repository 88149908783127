define("ember-svg-jar/inlined/gmo-food-apple-dna", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gmo-food-apple-dna</title><path d=\"M13.829 16.944A9.28 9.28 0 0112 11.251a2.251 2.251 0 011.645-2.167.251.251 0 00.174-.176.247.247 0 00-.061-.239 8 8 0 00-2.7-1.832.5.5 0 00-.678.345 8.646 8.646 0 00-.248 1.918.75.75 0 01-.758.742h-.008a.75.75 0 01-.734-.757 12.253 12.253 0 011.745-5.852.5.5 0 01.593-.224l.9.305a1 1 0 00.642-1.894L10.489.735 10.475.73 8.481.053a1 1 0 00-.643 1.9l.246.084a.5.5 0 01.282.707 16.472 16.472 0 00-.7 1.515.25.25 0 01-.454.023 5.511 5.511 0 00-5.785-2.89.5.5 0 00-.411.576 5.506 5.506 0 003.933 4.384.251.251 0 01.027.473 8 8 0 106.874 14.419 1 1 0 00.486-.619 8.643 8.643 0 011.493-3.068.5.5 0 000-.613z\"/><path d=\"M21.655 17.63a.5.5 0 010-.76A7.409 7.409 0 0024 11.25a.75.75 0 00-1.5 0 .25.25 0 01-.25.25h-7a.25.25 0 01-.25-.25.75.75 0 00-1.5 0 7.408 7.408 0 002.345 5.62.5.5 0 010 .76 7.408 7.408 0 00-2.345 5.62.75.75 0 001.5 0 .25.25 0 01.25-.25h7a.25.25 0 01.25.25.75.75 0 001.5 0 7.409 7.409 0 00-2.345-5.62zM15.894 14a.5.5 0 01-.469-.325 6.289 6.289 0 01-.12-.351.251.251 0 01.239-.324h6.412a.251.251 0 01.239.324 6.289 6.289 0 01-.12.351.5.5 0 01-.468.325zm4.518 1.955a2.807 2.807 0 01-3.324 0 .25.25 0 01.143-.455h3.038a.25.25 0 01.143.455zm.1 2.869a.249.249 0 01-.239.176h-3.042a.25.25 0 01-.143-.455 2.807 2.807 0 013.324 0 .248.248 0 01.096.279zm1.567 2c.043.114.083.232.12.351a.251.251 0 01-.239.324h-6.416a.251.251 0 01-.239-.324c.037-.119.077-.237.12-.351a.5.5 0 01.469-.325h5.713a.5.5 0 01.468.326z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});