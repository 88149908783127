define("ember-svg-jar/inlined/pathfinder-divide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pathfinder-divide</title><path d=\"M22 6h-3.75a.25.25 0 01-.25-.25V2a2 2 0 00-2-2H2a2 2 0 00-2 2v14a2 2 0 002 2h3.75a.25.25 0 01.25.25V22a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2zM2 2.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v13a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});