define("ember-svg-jar/inlined/love-heart-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-heart-arrow</title><path d=\"M7.862 7.2a.5.5 0 00.038-.741l-.792-.792a.5.5 0 01-.146-.327L6.861 3.5a2.5 2.5 0 00-.695-1.6L4.451.15A.5.5 0 003.6.438l-.3 2.423a.5.5 0 01-.434.435L.439 3.6a.5.5 0 00-.283.86l1.78 1.686a2.5 2.5 0 001.594.691l1.836.1a.5.5 0 01.327.146l.778.778a.5.5 0 00.729-.024 5.6 5.6 0 01.662-.637zM23.32 20.053a.5.5 0 00-.843-.256l-.266.266a.5.5 0 01-.707 0l-2.253-2.254a.5.5 0 01-.014-.692c1.421-1.544 2.633-3.353 2.633-5.045a4.311 4.311 0 00-3.358-4.5 3.741 3.741 0 00-3.623 1.292.5.5 0 01-.758 0 3.745 3.745 0 00-3.622-1.291 4.311 4.311 0 00-3.358 4.5c0 4.006 6.789 8.689 7.077 8.887a.507.507 0 00.565 0c.066-.046.478-.333 1.07-.791a.5.5 0 00.047-.75l-1.849-1.849a.75.75 0 011.06-1.061l4.969 4.969a.5.5 0 010 .707l-.292.292a.5.5 0 00.255.844l3.349.67a.5.5 0 00.588-.589z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});