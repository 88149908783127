define("ember-svg-jar/inlined/messages-bubble-lock-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-lock-1</title><path d=\"M13.885 14.472a.249.249 0 00.115-.21v-.512c0-2.575 2.64-4.5 5-4.5a5.429 5.429 0 013.631 1.512.25.25 0 00.409-.1A7.993 7.993 0 0015.5 0H8a7.992 7.992 0 00-3 15.4v4.1a.5.5 0 00.854.354L9.707 16h2.815a.251.251 0 00.231-.154 3 3 0 011.132-1.374z\"/><path d=\"M22.5 15.5v-1.25a3.5 3.5 0 00-7 0v1.25A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.5-1.5zM19 12.75a1.5 1.5 0 011.5 1.5v1.25h-3v-1.25a1.5 1.5 0 011.5-1.5zm1 7.5a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});