define("ember-svg-jar/inlined/people-woman-glasses-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-glasses-2</title><path d=\"M22.534 18.667a14.255 14.255 0 00-5.908-2.481.5.5 0 00-.552.279A4.557 4.557 0 0112 19.25a4.3 4.3 0 01-2.761-1.056.25.25 0 00-.4.116 3.172 3.172 0 01-.325.716.249.249 0 00.01.277A2.456 2.456 0 019 20.75a5.053 5.053 0 01-1.272 2.594.25.25 0 00.2.406H23a.5.5 0 00.5-.5v-2.606a2.466 2.466 0 00-.966-1.977zM4 20.75a2.456 2.456 0 01.476-1.45.249.249 0 00.01-.277A3.385 3.385 0 014 17.25a.018.018 0 00-.008-.015.021.021 0 00-.017 0 12.007 12.007 0 00-2.509 1.434A2.466 2.466 0 00.5 20.644v2.606a.5.5 0 00.5.5h4.077a.25.25 0 00.2-.406A5.053 5.053 0 014 20.75zM5 20.75a5.424 5.424 0 001.308 2.277.252.252 0 00.384 0A5.424 5.424 0 008 20.75a1.5 1.5 0 00-3 0z\"/><ellipse cx=\"6.5\" cy=\"17.25\" rx=\"1.5\" ry=\"2\"/><path d=\"M6.5 15.25a1.572 1.572 0 001.371-1.2.25.25 0 01.393-.115 5.974 5.974 0 008.845-1.554.245.245 0 01.091-.087A3.486 3.486 0 0019 9.25v-2a7 7 0 00-14 0v2a1.874 1.874 0 001.324 1.976.029.029 0 01.024.02.017.017 0 010 .017.021.021 0 01-.014.01A1.87 1.87 0 005 13.25c0 .993.616 2 1.5 2zm9.5-7A1.25 1.25 0 1114.75 7 1.252 1.252 0 0116 8.25zm-3.83-.917a.249.249 0 01-.235.167h-.029a.031.031 0 01-.03-.022 2.744 2.744 0 00-.453-.9.25.25 0 01.035-.342c.126-.107.248-.218.365-.336A.249.249 0 0112 5.829a.246.246 0 01.177.074c.117.117.239.228.365.335a.25.25 0 01.035.342 2.754 2.754 0 00-.407.753zm-1.67.917A1.25 1.25 0 119.25 7a1.252 1.252 0 011.25 1.25zm-1.9 3.1a.25.25 0 01.253-.378 2.523 2.523 0 00.4.033 2.742 2.742 0 002.58-1.834.249.249 0 01.232-.171h.029a.031.031 0 01.03.022A2.742 2.742 0 0014.75 11a2.523 2.523 0 00.4-.033.25.25 0 01.253.378 3.994 3.994 0 01-6.808 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});