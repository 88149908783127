define("ember-svg-jar/inlined/circus-elephant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>circus-elephant</title><path d=\"M16.09 11.8a4.609 4.609 0 01-.674.283 1.292 1.292 0 00-.969 1.167v3.214a.99.99 0 01-.571.895 1.065 1.065 0 01-1-.043l-.87-.513-.873.514a2.007 2.007 0 01-.988.047.994.994 0 01-.581-.9v-3.218a1.3 1.3 0 00-1-1.177 4.707 4.707 0 01-.677-.264c-.274-.128-.531.237-.531.237a5.723 5.723 0 00-.99 5.382l.878 2.755a.247.247 0 00.359.136A2.981 2.981 0 019 19.968h6a2.949 2.949 0 011.334.32.248.248 0 00.355-.139l.867-2.722a5.733 5.733 0 00-1.034-5.433s-.228-.294-.432-.194zM15 20.968H9a2 2 0 00-2 2 1 1 0 001 1h8a1 1 0 001-1 2 2 0 00-2-2z\"/><path d=\"M22.194 1.086a4.241 4.241 0 00-4-.9 4.629 4.629 0 00-3.13 2.609 4.5 4.5 0 00-3.041-1.276H12A4.63 4.63 0 008.941 2.8 4.63 4.63 0 005.81.183a4.24 4.24 0 00-4 .9 2.494 2.494 0 00-.584 2.52 18.941 18.941 0 01.614 8.407 2.667 2.667 0 00.333 1.754 1.369 1.369 0 001.132.705c.107 0 1.721-.4 1.721-.4a6.942 6.942 0 011.363-2.812s.25-.24.084-.459a5.15 5.15 0 01-1.217-2.115.75.75 0 111.437-.43 3.371 3.371 0 002.3 2.38 2.781 2.781 0 012.068 2.613v2.075a.171.171 0 00.249.154 1.344 1.344 0 011.387 0c.14.073.248-.005.248-.163v-2.058a2.78 2.78 0 012.012-2.595 3.532 3.532 0 002.265-2.411.75.75 0 111.433.44 5.347 5.347 0 01-1.163 2.053.423.423 0 00.043.513 6.944 6.944 0 011.365 2.792l1.459.371a1.3 1.3 0 001.477-.65 2.667 2.667 0 00.333-1.754 18.941 18.941 0 01.614-8.407 2.494 2.494 0 00-.589-2.52zM9.75 7.718a.75.75 0 11.75-.75.75.75 0 01-.75.75zm4.5 0a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});