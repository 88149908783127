define("ember-svg-jar/inlined/single-neutral-profile-picture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-profile-picture</title><path d=\"M21 0H3a1.5 1.5 0 00-1.5 1.5v21A1.5 1.5 0 003 24h18a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0021 0zm-2 4v16a.5.5 0 01-.5.5h-13A.5.5 0 015 20V4a.5.5 0 01.5-.5h13a.5.5 0 01.5.5z\"/><path d=\"M13.847 13.049a.977.977 0 01-.027-.7 5 5 0 001.149-3.529A2.881 2.881 0 0012 5.768 2.881 2.881 0 009.031 8.82a4.979 4.979 0 001.13 3.511 1 1 0 01.007.71c-2.076.744-3.214 1.283-3.668 2.159a5.282 5.282 0 00-.492 1.477.5.5 0 00.106.41.5.5 0 00.385.181h11a.5.5 0 00.385-.181.5.5 0 00.106-.41 5.282 5.282 0 00-.49-1.477c-.454-.876-1.592-1.415-3.653-2.151z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});