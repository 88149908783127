define("ember-svg-jar/inlined/cloud-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-off</title><path d=\"M15.276 6.988a5.5 5.5 0 011.878 2.3.954.954 0 00.929.545 3.767 3.767 0 013.26 1.634A3.683 3.683 0 0122 13.616a3.541 3.541 0 01-.938 2.531.994.994 0 00.012 1.391 1.007 1.007 0 001.448-.035A5.422 5.422 0 0024 13.774a5.765 5.765 0 00-5.217-5.917.252.252 0 01-.191-.12A7.733 7.733 0 0012 4a7.577 7.577 0 00-2.244.339c-.651.2-.807.845-.377 1.5a.92.92 0 001.082.373 5.882 5.882 0 014.815.776zM4.811 17.391a3.527 3.527 0 01-2.172-.8A2.375 2.375 0 012 14.8a2.588 2.588 0 012.586-2.587 2.7 2.7 0 01.535.054.988.988 0 00.811-.184 1 1 0 00.39-.735 9.8 9.8 0 00.015-.725c-.009-.5.022-.733-.261-1.016a.946.946 0 00-1.594.469v.019a.154.154 0 01-.144.123A4.646 4.646 0 00.624 12.47 4.467 4.467 0 000 14.918a4.285 4.285 0 001.235 3.1 5.472 5.472 0 003.571 1.377h8.635a1 1 0 00.707-1.707 1 1 0 00-.707-.293zM22.293 23.707a1 1 0 001.414-1.414l-22-22A1 1 0 00.293 1.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});