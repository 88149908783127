define("ember-svg-jar/inlined/goods-brand-mark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>goods-brand-mark</title><g fill=\"none\" fill-rule=\"evenodd\"><circle fill=\"#7986FF\" cx=\"14\" cy=\"14\" r=\"14\"/><path d=\"M11.698 11.267c0 2.192.886 3.288 2.657 3.288.403 0 .78-.053 1.131-.158.351-.105.65-.228.895-.368v-6.26a8.75 8.75 0 00-1.605-.132c-1.035 0-1.806.342-2.315 1.025-.508.684-.763 1.552-.763 2.605zm8.602 5.629c0 2.262-.574 3.941-1.723 5.037s-2.924 1.644-5.327 1.644c-.841 0-1.683-.074-2.525-.223a15.736 15.736 0 01-2.341-.592l.684-3.288c.614.245 1.258.438 1.933.578.675.14 1.443.21 2.302.21 1.122 0 1.916-.245 2.38-.736.465-.49.698-1.122.698-1.894v-.5a6.31 6.31 0 01-1.303.435 6.925 6.925 0 01-1.46.144c-1.91 0-3.375-.565-4.392-1.696C8.209 14.884 7.7 13.3 7.7 11.267c0-1.017.158-1.943.473-2.776a5.905 5.905 0 011.381-2.143c.606-.597 1.346-1.057 2.223-1.381.877-.325 1.868-.487 2.973-.487.473 0 .96.022 1.46.066.5.044.995.1 1.486.17.49.07.96.154 1.407.25.447.097.846.198 1.197.303v11.627z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});