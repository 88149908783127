define("ember-svg-jar/inlined/history-man-chinese-elder.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-man-chinese-elder</title><path d=\"M7.5 18.5a.3.3 0 00-.367-.288c-.716.176-2.1.516-2.324.562a7.107 7.107 0 00-3.057 1.157A4.531 4.531 0 000 23.5a.5.5 0 00.5.5h6.505a.5.5 0 00.5-.5zM24 23.5a4.529 4.529 0 00-1.753-3.565 7.1 7.1 0 00-3.056-1.157c-.223-.046-1.608-.386-2.323-.562a.3.3 0 00-.368.288v5a.5.5 0 00.5.5h6.5a.5.5 0 00.5-.504zM10.068 12.827a.75.75 0 101.022-1.1 2.017 2.017 0 00-2.6 0 .75.75 0 001.022 1.1.562.562 0 01.556 0zM13.932 12.827a.562.562 0 01.557 0 .75.75 0 101.022-1.1 2.017 2.017 0 00-2.6 0 .75.75 0 001.022 1.1z\"/><path d=\"M8.572 18.293A3.523 3.523 0 008.5 19v4.413a.5.5 0 00.647.478A1.817 1.817 0 0010.5 22a13.828 13.828 0 01.021-2.579 4.217 4.217 0 001.458.329 4.268 4.268 0 001.5-.342A13.481 13.481 0 0113.5 22a1.817 1.817 0 001.353 1.891.5.5 0 00.647-.478V19a3.559 3.559 0 00-.08-.735 7.5 7.5 0 00.754-.688 5.428 5.428 0 001.492-2.816.254.254 0 01.145-.189 2.73 2.73 0 001.439-2.433 2.113 2.113 0 00-.465-1.443c0-.014.092-.72.119-1.36a7.046 7.046 0 00-1.394-4.992 5.7 5.7 0 00-3.014-1.8 1.468 1.468 0 00-.021-.292H16a.75.75 0 000-1.5h-2.219a2.49 2.49 0 00-3.562 0H8a.75.75 0 000 1.5h1.525a1.482 1.482 0 00-.022.284c-1.388.351-4.672 1.538-4.447 6.813.027.636.116 1.335.118 1.349a2.11 2.11 0 00-.464 1.444 2.728 2.728 0 001.439 2.432.25.25 0 01.144.188A5.437 5.437 0 007.807 17.6a7.517 7.517 0 00.765.693zm-1.8-5.085a1.275 1.275 0 01-.559-1.1.765.765 0 01.061-.361h.347a1.753 1.753 0 001.716-1.406l.185-.922a.251.251 0 01.227-.2 5.1 5.1 0 003.07-1.05.251.251 0 01.328 0 5.1 5.1 0 003.07 1.05.25.25 0 01.227.2l.184.921a1.754 1.754 0 001.716 1.406h.351a.866.866 0 01.058.36 1.278 1.278 0 01-.559 1.1 1.753 1.753 0 00-1 1.306 4.015 4.015 0 01-1.238 2.166.249.249 0 01-.36-.012 3.459 3.459 0 00-5.2.019.247.247 0 01-.177.084.251.251 0 01-.184-.069 4.035 4.035 0 01-1.26-2.187 1.747 1.747 0 00-1.006-1.305z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});