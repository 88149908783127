define("ember-svg-jar/inlined/public-service-ambulance-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>public-service-ambulance-1</title><path d=\"M22 17.743a1 1 0 001-1V1.25a1 1 0 00-1-1H2a1 1 0 00-1 1v15.493a1 1 0 001 1 .146.146 0 01.092.257 2.244 2.244 0 001.183 3.985.249.249 0 01.225.249v.512a1 1 0 001 1h2a1 1 0 001-1v-.5A.25.25 0 017.75 22h8.5a.25.25 0 01.25.25v.5a1 1 0 001 1h2a1 1 0 001-1v-.512a.249.249 0 01.225-.249A2.247 2.247 0 0021.909 18a.145.145 0 01.091-.257zM18 2.75a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5zm2 12.493V17a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1.757a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zM8 2.75a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5zm9.755 3.993a.436.436 0 01.416.3l1.288 4.561a.5.5 0 01-.481.636H5.029a.5.5 0 01-.481-.636l1.281-4.558a.437.437 0 01.416-.3zm-11.255 8a.5.5 0 01.5.5V17a.5.5 0 01-.5.5h-2A.5.5 0 014 17v-1.757a.5.5 0 01.5-.5zM3 2.75a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5zM20.5 20.5h-17a.749.749 0 01-.012-1.5h17.024a.749.749 0 01-.012 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});