define("ember-svg-jar/inlined/boxing-boxer-bag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>boxing-boxer-bag</title><circle cx=\"6.5\" cy=\"4\" r=\"2.5\"/><path d=\"M13 6.5a2.939 2.939 0 00-1.293.3 1.991 1.991 0 01-.872.2H9.5a1 1 0 00-1 1H5a1.5 1.5 0 00-1.447 1.105l-1.5 5.5v.032A1.5 1.5 0 002 15v2.681l-1.871 4.21a1.5 1.5 0 002.743 1.218l2-4.5A1.5 1.5 0 005 18a.163.163 0 01.249-.138A1.6 1.6 0 016 19.218V22.5a1.5 1.5 0 003 0v-5a1.5 1.5 0 00-.7-1.272l-2.9-1.81a.251.251 0 01-.108-.278l.808-2.956A.249.249 0 016.337 11H8.5a1 1 0 001 1h1.335a1.977 1.977 0 01.872.2 2.939 2.939 0 001.293.3 3 3 0 000-6zm-.419 3.9a3.972 3.972 0 00-1.746-.4H10.5V9h.335a4.026 4.026 0 001.747-.4 1 1 0 110 1.806z\"/><rect x=\"17\" y=\"7.5\" width=\"7\" height=\"6\" rx=\".25\" ry=\".25\"/><path d=\"M23.75 6.5a.25.25 0 00.25-.25V6a2.5 2.5 0 00-2.5-2.5V1a1 1 0 00-2 0v2.5A2.5 2.5 0 0017 6v.25a.25.25 0 00.25.25zM17.25 14.5a.25.25 0 00-.25.25V15a2.5 2.5 0 002.5 2.5h2A2.5 2.5 0 0024 15v-.25a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});