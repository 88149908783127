define("ember-svg-jar/inlined/multiple-actions-shield.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-shield</title><path d=\"M17.478 9.5a.326.326 0 00.171-.6 5.993 5.993 0 00-5.625-.362.5.5 0 00-.182.771 7.5 7.5 0 01.464.636.5.5 0 00.551.209 18.1 18.1 0 014.621-.654z\"/><ellipse cx=\"14.013\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M10.68 11.059a.5.5 0 00.091-.7A6.025 6.025 0 000 13.333.6.6 0 00.6 14H9a.5.5 0 00.5-.5v-.044a3.031 3.031 0 011.18-2.397z\"/><ellipse cx=\"5.513\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M23.072 12.04A16.4 16.4 0 0017.478 11a15.992 15.992 0 00-5.55 1.04A1.545 1.545 0 0011 13.456v3.393a7.716 7.716 0 005.19 6.805l.553.211a2.071 2.071 0 001.471 0l.553-.211C21.848 22.48 24 19.682 24 16.849v-3.393a1.545 1.545 0 00-.928-1.416zm-4.822 3.21a.5.5 0 00.5.5h1.75a.75.75 0 010 1.5h-1.75a.5.5 0 00-.5.5v1.75a.75.75 0 01-1.5 0v-1.75a.5.5 0 00-.5-.5H14.5a.75.75 0 010-1.5h1.75a.5.5 0 00.5-.5V13.5a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});