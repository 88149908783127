define("ember-svg-jar/inlined/file-rb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-rb</title><path d=\"M14.5 13.126h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25h.625a.875.875 0 000-1.75zM14.5 16.126h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25h.625a.875.875 0 000-1.75zM10.375 14a.876.876 0 00-.875-.875h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H9.5a.877.877 0 00.875-.875z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.251A.25.25 0 0014.75 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.251A.25.25 0 0021.75 7zM11.442 18.943a.669.669 0 01-.884 0l-1.506-1.506a.25.25 0 00-.427.177v.886a.625.625 0 01-1.25 0v-6A.625.625 0 018 11.876h1.375a2.21 2.21 0 012.238 1.9 2.127 2.127 0 01-1.628 2.293.246.246 0 00-.119.414l1.576 1.576a.625.625 0 010 .884zm4.707-3.281a2.13 2.13 0 01.412 1.867 2.174 2.174 0 01-2.137 1.6H13a.625.625 0 01-.625-.625v-6a.625.625 0 01.625-.628h1.424a2.174 2.174 0 012.137 1.6 2.13 2.13 0 01-.412 1.867.25.25 0 000 .319z\"/><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.428A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});