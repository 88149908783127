define("ember-svg-jar/inlined/road-sign-sharp-turn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-sharp-turn</title><path d=\"M23.651 12.47a1.245 1.245 0 00-.033-1.762L12.863.348a1.243 1.243 0 00-1.727 0L.38 10.709a1.246 1.246 0 00-.031 1.761L11.1 23.619a1.245 1.245 0 001.792 0zm-9.8 2.472a1.5 1.5 0 010 2.114l-1.276 1.275a.253.253 0 00-.073.177v.978a1 1 0 11-1.992 0v-1.494a1 1 0 01.292-.705l1.112-1.112a.249.249 0 000-.352l-2.259-2.255a1.5 1.5 0 010-2.112l1.277-1.277A.248.248 0 0011 10V8.526a.25.25 0 00-.249-.249H9.509A.747.747 0 018.98 7l2.489-2.49A.747.747 0 0112 4.291a.747.747 0 01.528.219L15.019 7a.747.747 0 01-.528 1.276h-1.245a.249.249 0 00-.246.25v1.993a1 1 0 01-.292.7l-1.112 1.112a.249.249 0 000 .353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});