define("ember-svg-jar/inlined/gas-can", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gas-can</title><path d=\"M2.46 5.507a2.718 2.718 0 01.84-.132h3.9a.25.25 0 00.2-.395L4.446.846a1.847 1.847 0 00-1.4-.721h-1.5A1.074 1.074 0 00.625.6a1.072 1.072 0 00-.072 1.03l1.6 3.737a.25.25 0 00.307.14z\"/><path d=\"M20.8.875h-7.5a2.521 2.521 0 00-2.118 1.309L9.072 6.4a1.046 1.046 0 01-.777.48h-5a1.252 1.252 0 00-1.25 1.25v14a1.753 1.753 0 001.75 1.75h18a1.752 1.752 0 001.75-1.75V3.625A2.752 2.752 0 0020.8.875zm-6.363 14.977L18 19.418a1 1 0 11-1.414 1.414l-3.565-3.566a.25.25 0 00-.353 0L9.1 20.832a1 1 0 01-1.41-1.414l3.566-3.566a.25.25 0 000-.354L7.69 11.932a1 1 0 011.41-1.414l3.566 3.566a.25.25 0 00.353 0l3.565-3.566A1 1 0 1118 11.932L14.437 15.5a.25.25 0 000 .352zM20.8 6.625a1 1 0 01-1 1h-6.5l-.3-.293a1.487 1.487 0 01-.259-1.6l.605-1.21a1.775 1.775 0 011.454-.897h5a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});