define("ember-svg-jar/inlined/real-estate-app-building-laptop-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-app-building-laptop-1</title><path d=\"M23.5 17.25h-.25a.5.5 0 01-.5-.5V5.25a2.5 2.5 0 00-2.5-2.5H3.75a2.5 2.5 0 00-2.5 2.5v11.5a.5.5 0 01-.5.5H.5a.5.5 0 00-.5.5 3.5 3.5 0 003.5 3.5h17a3.5 3.5 0 003.5-3.5.5.5 0 00-.5-.5zm-2.75-.5a.5.5 0 01-.5.5H3.75a.5.5 0 01-.5-.5V5.25a.5.5 0 01.5-.5h16.5a.5.5 0 01.5.5z\"/><path d=\"M15.224 7.3l-3-1.5a.5.5 0 00-.448 0l-3 1.5a.5.5 0 00-.276.45v7.5a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5H15a.5.5 0 00.5-.5v-7.5a.5.5 0 00-.276-.45zM13.5 11.75h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});