define("ember-svg-jar/inlined/diagram-arrow-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-arrow-up</title><path d=\"M9 22.5a1.5 1.5 0 001.5 1.5h3a1.5 1.5 0 001.5-1.5V9.013a.25.25 0 01.25-.25h3.25a1 1 0 00.733-1.681L13.073.448a1.51 1.51 0 00-2.146 0l-6.16 6.634A1 1 0 005.5 8.763h3.25a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});