define("ember-svg-jar/inlined/style-three-pin-police-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-police-badge</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm4.142 10.248l1.25 2.188a.25.25 0 00.3.114A1.016 1.016 0 0118 12.5a1 1 0 11-.98 1.2.249.249 0 00-.245-.2h-2.507a.5.5 0 00-.416.223l-1.173 1.758a.25.25 0 00.031.315 1 1 0 11-1.42 0 .25.25 0 00.031-.315l-1.173-1.758a.5.5 0 00-.416-.223H7.225a.25.25 0 00-.245.2A1 1 0 116 12.5a1.016 1.016 0 01.313.05.249.249 0 00.295-.114l1.25-2.188a.5.5 0 000-.5l-1.25-2.184a.25.25 0 00-.295-.114A1.016 1.016 0 016 7.5a1 1 0 11.98-1.2.249.249 0 00.245.2h2.507a.5.5 0 00.416-.223l1.173-1.758a.251.251 0 00-.031-.319 1 1 0 111.42 0 .25.25 0 00-.031.315l1.173 1.758a.5.5 0 00.416.223h2.507a.25.25 0 00.245-.2A1 1 0 1118 7.5a1.016 1.016 0 01-.313-.05.249.249 0 00-.3.114l-1.25 2.188a.5.5 0 00.005.496z\"/><circle cx=\"12\" cy=\"10\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});