define("ember-svg-jar/inlined/smiley-sad-crying-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-sad-crying_1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm7 7c-1.153 0-3.142-.547-3.895-2.053a1 1 0 011.79-.894A2.656 2.656 0 0019 5a1 1 0 010 2zM7.059 17.352a5.706 5.706 0 019.882 0 .439.439 0 01-.38.659 21.073 21.073 0 00-9.122 0 .44.44 0 01-.38-.659zM6 9.5A1.5 1.5 0 117.5 11 1.5 1.5 0 016 9.5zM16.5 11A1.5 1.5 0 1118 9.5a1.5 1.5 0 01-1.5 1.5zM5 5a2.656 2.656 0 002.105-.947 1 1 0 111.79.894C8.142 6.453 6.153 7 5 7a1 1 0 010-2zm15.25 11.75A2.25 2.25 0 0118 14.5c0-1.009 1.484-3.347 2.042-4.19a.248.248 0 01.208-.112.252.252 0 01.209.112c.557.843 2.041 3.181 2.041 4.19a2.25 2.25 0 01-2.25 2.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});