define("ember-svg-jar/inlined/surveillance-cctv-wifi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>surveillance-cctv-wifi</title><path d=\"M16.25 11a1 1 0 001-1V5.754a.5.5 0 00-.6-.491l-1.705.34a.5.5 0 00-.368.308A2.506 2.506 0 0112.25 7.5h-8.5a.5.5 0 00-.5.5v.5a2.5 2.5 0 002.5 2.5h1.024a.5.5 0 01.5.494l.033 2.734a.5.5 0 01-.433.5l-4.057.549a.5.5 0 01-.567-.5V13.5a1 1 0 00-2 0v6a1 1 0 002 0v-1.69a.5.5 0 01.433-.495l4.96-.673a2.007 2.007 0 001.607-1.961V11.5a.5.5 0 01.5-.5z\"/><path d=\"M2.25 5a1 1 0 001 1h9a1 1 0 001-1v-.18a.5.5 0 01.4-.49l6.38-1.269a.952.952 0 00.675-.641l.513-1.177a.994.994 0 00-.182-.858A1.007 1.007 0 0020.25 0h-15a3 3 0 00-3 3zM19.25 5a1 1 0 00-1 1v2a1 1 0 002 0V6a1 1 0 00-1-1z\"/><circle cx=\"17.25\" cy=\"22.25\" r=\"1.75\"/><path d=\"M23.371 15.626a9.821 9.821 0 00-12.242 0 1 1 0 101.242 1.568 7.823 7.823 0 019.758 0 1 1 0 101.242-1.568z\"/><path d=\"M13.6 18.451a1 1 0 001.3 1.526 3.586 3.586 0 014.707 0 1 1 0 101.293-1.526 5.621 5.621 0 00-7.3 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});