define("ember-svg-jar/inlined/earth-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earth-time</title><path d=\"M10.967 22.108a.5.5 0 00-.339-.206 9.983 9.983 0 01-3.128-.984v-1.3a2.492 2.492 0 01.893-1.918 4.482 4.482 0 001.264-1.775 7.868 7.868 0 01.266-.972 4.423 4.423 0 00.077-.7 4.505 4.505 0 00-4.5-4.5H2.265a9.982 9.982 0 0115.375-6h-3.39a2.75 2.75 0 000 5.5 2.527 2.527 0 011.282.362.5.5 0 00.354.058A7.874 7.874 0 0117.5 9.5a7.97 7.97 0 015.977 2.7A.3.3 0 0024 12 12 12 0 107.341 23.059l.159.078v-.02A11.927 11.927 0 0012 24a.3.3 0 00.2-.524 7.968 7.968 0 01-1.233-1.368z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm0 11a4.5 4.5 0 114.5-4.5 4.5 4.5 0 01-4.5 4.5z\"/><path d=\"M19.5 16.5h-.75a.249.249 0 01-.25-.25V15a1 1 0 00-2 0v2.5a1 1 0 001 1h2a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});