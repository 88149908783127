define("ember-svg-jar/inlined/video-game-pc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-pc</title><path d=\"M12.874 8.354a.5.5 0 010-.708l1.738-1.737a.5.5 0 00-.064-.762 3.5 3.5 0 100 5.706.5.5 0 00.064-.762z\"/><path d=\"M22 0H2a2 2 0 00-2 2v12a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zm0 13.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5h19a.5.5 0 01.5.5zM22.347 22.745L21.1 18.258a1 1 0 00-.97-.758H3.911a1 1 0 00-.967.745l-1.253 4.512a.99.99 0 00.182.858 1.006 1.006 0 00.788.385H21.38a1.009 1.009 0 00.788-.385.994.994 0 00.182-.858zm-7.576-4h.5a.5.5 0 010 1h-.5a.5.5 0 010-1zm-3 0h.5a.5.5 0 010 1h-.5a.5.5 0 010-1zm-3 0h.5a.5.5 0 010 1h-.5a.5.5 0 010-1zm-3.75 3.5h-.5a.5.5 0 010-1h.5a.5.5 0 110 1zm1.25-2.5h-.5a.5.5 0 010-1h.5a.5.5 0 110 1zm9.75 2.5h-8a.5.5 0 010-1h8a.5.5 0 010 1zm1.751-2.5a.5.5 0 010-1h.5a.5.5 0 010 1zm1.746 2.5h-.5a.5.5 0 010-1h.5a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});