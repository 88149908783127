define("ember-svg-jar/inlined/disability-blind-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-blind-alternate</title><path d=\"M23.4 10.833c-1.359-1.819-5.192-6.05-11.4-6.05S1.956 9.014.6 10.832a1.938 1.938 0 000 2.335A15.862 15.862 0 006.706 18.1a.987.987 0 00.64.258 12.991 12.991 0 004.654.859c6.208 0 10.043-4.231 11.4-6.05a1.937 1.937 0 000-2.334zM2.221 12C3.42 10.406 6.727 6.783 12 6.783a11.044 11.044 0 013.843.689l-8.649 8.649A13.929 13.929 0 012.221 12zM12 17.217a11.075 11.075 0 01-2.734-.34l8.5-8.5A14.613 14.613 0 0121.8 11.97c-1.178 1.577-4.5 5.247-9.8 5.247z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});