define("ember-svg-jar/inlined/wifi-signal-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-signal-3</title><path d=\"M13 14.226V11.5a1 1 0 10-2 0v2.728l-4.862 8.263A1 1 0 007 24h10a1 1 0 00.862-1.507z\"/><path d=\"M12 0a12 12 0 00-8.668 20.293 1 1 0 001.445-1.383 10 10 0 1114.446 0 1 1 0 001.445 1.383A12 12 0 0012 0z\"/><path d=\"M12 5.184a6.508 6.508 0 00-6.5 6.5 6.442 6.442 0 001.221 3.783A1 1 0 108.345 14.3a4.456 4.456 0 01-.845-2.616 4.5 4.5 0 019 0 4.456 4.456 0 01-.845 2.616 1 1 0 101.624 1.167 6.442 6.442 0 001.221-3.783 6.508 6.508 0 00-6.5-6.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});