define("ember-svg-jar/inlined/presentation-analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>presentation-analytics</title><path d=\"M22.525 3H16.25a.25.25 0 01-.25-.25V2a1 1 0 00-2 0v.75a.25.25 0 01-.25.25H9.5a1 1 0 000 2h12.25a.249.249 0 01.25.25v8.5a.25.25 0 01-.25.25H11a1 1 0 000 2h2.75a.249.249 0 01.25.25v2.821a.25.25 0 01-.105.2l-1.976 1.412a1 1 0 101.162 1.627l1.74-1.242a.249.249 0 01.29 0l1.74 1.242a1 1 0 001.394-.232 1 1 0 00-.232-1.395l-1.908-1.363a.25.25 0 01-.105-.2v-2.87a.249.249 0 01.25-.25h6.275C23.38 16 24 15.158 24 14V5c0-1.159-.62-2-1.475-2z\"/><path d=\"M12.207 12.368l3-3a.25.25 0 01.354 0l.7.7a1.5 1.5 0 002.121 0l2.112-2.112a1 1 0 10-1.414-1.415L17.5 8.125a.25.25 0 01-.353 0l-.7-.7a1.5 1.5 0 00-2.12 0l-3.529 3.53a1 1 0 101.414 1.414zM4.274 8.532a3.016 3.016 0 10-3.016-3.016 3.019 3.019 0 003.016 3.016zM4.274 9.419A4.279 4.279 0 000 13.693v2.517a.5.5 0 00.5.5h1.208a.25.25 0 01.249.225l.563 5.614a.5.5 0 00.5.451h2.512a.5.5 0 00.5-.451l.562-5.614a.25.25 0 01.249-.225h1.206a.5.5 0 00.353-.146.5.5 0 00.147-.354v-2.517a4.279 4.279 0 00-4.275-4.274z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});