define("ember-svg-jar/inlined/notes-subtract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-subtract</title><path d=\"M12 4.25v.5a.75.75 0 001.5 0v-.5a.25.25 0 01.25-.25h1.5a.251.251 0 01.25.25v4.9a.241.241 0 00.29.238 7.852 7.852 0 01.977-.117.243.243 0 00.233-.24V4.25a1.749 1.749 0 00-1.75-1.75h-1.5a.25.25 0 01-.25-.25V.75a.75.75 0 00-1.5 0v1.5a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25V.75a.75.75 0 00-1.5 0v1.5a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25V.75a.75.75 0 00-1.5 0v1.5a.25.25 0 01-.25.25h-2A1.749 1.749 0 000 4.25v13A1.749 1.749 0 001.75 19h7.4a.241.241 0 00.238-.29 7.852 7.852 0 01-.117-.977.243.243 0 00-.242-.233H1.75a.251.251 0 01-.25-.25v-13A.251.251 0 011.75 4h2a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.5A.25.25 0 015.75 4h2a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.5A.25.25 0 019.75 4h2a.25.25 0 01.25.25z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.5 7.25h-5a.75.75 0 010-1.5h5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});