define("ember-svg-jar/inlined/pets-paw-hospital", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pets-paw-hospital</title><path d=\"M22.5 7H17V1.5A1.5 1.5 0 0015.5 0h-7A1.5 1.5 0 007 1.5V7H1.5A1.5 1.5 0 000 8.5v7A1.5 1.5 0 001.5 17H7v5.5A1.5 1.5 0 008.5 24h7a1.5 1.5 0 001.5-1.5V17h5.5a1.5 1.5 0 001.5-1.5v-7A1.5 1.5 0 0022.5 7zm-8 4a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5zm-8 0A1.5 1.5 0 118 12.5 1.5 1.5 0 016.5 11zm2.715 3.043l1.861-2.556a1.194 1.194 0 011.924 0l1.785 2.571A1.227 1.227 0 0113.824 16h-3.648a1.219 1.219 0 01-1.064-.683 1.246 1.246 0 01.103-1.274zM10.484 8.5a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});