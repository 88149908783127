define("ember-svg-jar/inlined/folder-bookmark-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-bookmark-1</title><path d=\"M22.5 4.5h-1.75a.25.25 0 00-.25.25v8.994a.548.548 0 01-.936.388l-1.63-1.632a.25.25 0 00-.354 0l-1.629 1.629a.549.549 0 01-.937-.388V4.75a.25.25 0 00-.25-.25h-3.028a.5.5 0 01-.447-.277l-.947-1.895A1.5 1.5 0 009 1.5H1.5A1.5 1.5 0 000 3v18a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 21V6a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});