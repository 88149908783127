define("ember-svg-jar/inlined/single-neutral-actions-refresh.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-refresh</title><path d=\"M12.631 11.158a.25.25 0 00-.064-.432c-.339-.128-.694-.26-1.069-.4l-.628-.23a.6.6 0 01-.138-.311 2.573 2.573 0 01.058-1.3 5.618 5.618 0 001.466-4.314C12.256 1.756 10.677 0 8.5 0S4.744 1.756 4.744 4.174A5.605 5.605 0 006.2 8.471a2.453 2.453 0 01.073 1.316.615.615 0 01-.135.309l-.631.232c-2.447.9-4.215 1.55-4.754 2.626A9.173 9.173 0 000 16.5a.5.5 0 00.5.5h8.794a.251.251 0 00.249-.227 8 8 0 013.088-5.615zM23.392 19.871a1 1 0 00-1.893-.648 4.091 4.091 0 01-6.918 1.419.25.25 0 01.008-.345l1.721-1.721a.5.5 0 00-.353-.853H11.5a.5.5 0 00-.5.5v4.457a.5.5 0 00.854.353l.969-.969a.249.249 0 01.359 0 6.086 6.086 0 0010.21-2.2zM24 12.234a.5.5 0 00-.854-.353l-1.025 1.025a.249.249 0 01-.358 0 6.1 6.1 0 00-10.2 2.227 1 1 0 001.893.648 4.091 4.091 0 016.9-1.445.25.25 0 01-.006.347l-1.66 1.655a.5.5 0 00.353.853H23.5a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});