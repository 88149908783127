define("ember-svg-jar/inlined/browser-page-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>browser-page-media</title><path d=\"M24 4.75a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h18a3 3 0 003-3zm-14.346-1a.966.966 0 011.692 0 .969.969 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0 .969.969 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0A.969.969 0 016 4.25a.969.969 0 01.154-.5zm-3.562.092A1 1 0 013.5 3.25a.985.985 0 01.846.5.969.969 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.979.979 0 01.092-.408zM22 19.25a1 1 0 01-1 1H3a1 1 0 01-1-1V7a.25.25 0 01.25-.25h19.5A.25.25 0 0122 7z\"/><path d=\"M3 16.5a1.251 1.251 0 001.25 1.25h6a1.251 1.251 0 001.25-1.25v-6a1.252 1.252 0 00-1.25-1.25h-6A1.252 1.252 0 003 10.5zM4.5 11a.25.25 0 01.25-.25h5A.25.25 0 0110 11v3.839a.25.25 0 01-.465.128l-1.387-2.322a.5.5 0 00-.817-.058l-2.387 2.941a.25.25 0 01-.444-.157zM12.5 16.5a1.251 1.251 0 001.25 1.25h6A1.251 1.251 0 0021 16.5v-6a1.252 1.252 0 00-1.25-1.25h-6a1.252 1.252 0 00-1.25 1.25zM14 11a.25.25 0 01.25-.25h5a.25.25 0 01.25.25v3.839a.25.25 0 01-.465.128l-1.387-2.322a.5.5 0 00-.817-.058l-2.387 2.941a.25.25 0 01-.444-.157z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});