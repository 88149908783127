define("ember-svg-jar/inlined/road-sign-lane-crossing-right-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-lane-crossing-right -alternate</title><path d=\"M14.707 6.71a1 1 0 00-1.414 0l-1.647 1.646A.5.5 0 0012 9.21h.25a.25.25 0 01.25.25v.457a.249.249 0 01-.1.2l-3.5 2.625a1.007 1.007 0 00-.4.8V16.5a1 1 0 001 1h1a1 1 0 001-1v-1.832a.249.249 0 01.1-.2l3.5-2.625a1 1 0 00.4-.8V9.46a.25.25 0 01.25-.25H16a.5.5 0 00.353-.854zM9.351 10.092l1-.749a1 1 0 00.4-.8v-.75a1 1 0 00-1-1h-1a1 1 0 00-1 1v1.5a1 1 0 00.552.9 1.012 1.012 0 00.449.1 1 1 0 00.599-.201zM15.7 13.4a1 1 0 00-1.046.1l-1 .75a1.007 1.007 0 00-.4.8V16a1 1 0 001 1h1a1 1 0 001-1v-1.707a.994.994 0 00-.554-.893z\"/><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});