define("ember-svg-jar/inlined/table-lamp-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>table-lamp-2</title><path d=\"M13.632 19.937a.165.165 0 01-.048-.279 2.144 2.144 0 00.328-.325 2.454 2.454 0 00.481-2.066l-.293-1.321a.25.25 0 00-.244-.2h-3.71a.25.25 0 00-.244.2l-.294 1.321a2.454 2.454 0 00.481 2.066 2.209 2.209 0 00.356.348.149.149 0 01-.058.252c-2.418.542-3.928 2.211-3.887 3.367a.49.49 0 00.5.453h10a.49.49 0 00.5-.453c.041-1.154-1.461-2.817-3.868-3.363zM19.671 11.191L18.59 1.15a1 1 0 00-.63-.831.987.987 0 00-.36-.069H6.405a.987.987 0 00-.365.069 1 1 0 00-.63.831L4.329 11.191l-.21 1.952a1 1 0 00.994 1.107h13.774a1 1 0 00.994-1.107zm-10.56.122a1 1 0 01-1 .937h-.063a1 1 0 01-.936-1.06l.5-8a1 1 0 112 .125z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});