define("ember-svg-jar/inlined/time-clock-file-favorite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-clock-file-favorite</title><path d=\"M23.413 3L21 .587A2 2 0 0019.585 0H8a2 2 0 00-2 2v6.792a.252.252 0 00.232.25 8.455 8.455 0 011.452.212.248.248 0 00.316-.24V2.5a.5.5 0 01.5-.5h10.878a.5.5 0 01.353.146l2.122 2.121a.5.5 0 01.147.355V18a.5.5 0 01-.5.5h-7.338a.232.232 0 00-.163.06c-.21.191-.87.785-1.174 1.059-.095.086-.14.175-.106.252l.212.489a.252.252 0 00.215.139H22a2 2 0 002-2V4.415A2 2 0 0023.413 3z\"/><path d=\"M12.608 17.139a1.264 1.264 0 00.3-1.378A1.234 1.234 0 0011.739 15h-2.5a.249.249 0 01-.229-.15l-1.345-3.118-.013-.032a1.295 1.295 0 00-2.3 0l-.012.028-1.349 3.121a.251.251 0 01-.23.15h-2.5a1.232 1.232 0 00-1.169.762 1.262 1.262 0 00.3 1.378L2.6 19.11a.251.251 0 01.063.287l-1.22 2.815a1.253 1.253 0 00.316 1.45 1.273 1.273 0 001.505.166l3.113-1.752a.251.251 0 01.245 0l3.113 1.752a1.275 1.275 0 001.506-.166 1.254 1.254 0 00.315-1.45L10.333 19.4a.249.249 0 01.063-.287zM15.5 13.251a4.75 4.75 0 10-4.75-4.75 4.756 4.756 0 004.75 4.75zm0-8a3.25 3.25 0 11-3.25 3.25 3.254 3.254 0 013.25-3.25z\"/><path d=\"M15.5 9.251h1.768a.75.75 0 100-1.5H16.5a.252.252 0 01-.252-.252v-.766a.75.75 0 00-1.5 0V8.5a.75.75 0 00.752.751z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});