define("ember-svg-jar/inlined/multiple-actions-address.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-address</title><path d=\"M14.471 8.4l-.485-.173a1.8 1.8 0 01-.056-1.172 4.644 4.644 0 001.216-3.568A3.218 3.218 0 0012 0a3 3 0 00-1.341.313.25.25 0 00-.131.16.253.253 0 00.034.2 5 5 0 01.838 2.812 6.263 6.263 0 01-.922 3.624.25.25 0 00.122.372A8.15 8.15 0 0114.156 9.5a.25.25 0 00.284.076 8.208 8.208 0 011.02-.318.25.25 0 00.031-.476 38.43 38.43 0 00-1.02-.382z\"/><path d=\"M12.867 10.387a.25.25 0 00.025-.4A12.507 12.507 0 009.471 8.4l-.485-.173a1.8 1.8 0 01-.056-1.174 4.644 4.644 0 001.216-3.568A3.218 3.218 0 007 0a3.218 3.218 0 00-3.145 3.485 4.611 4.611 0 001.192 3.538 1.877 1.877 0 010 1.185L4.53 8.4c-2.007.736-3.457 1.269-3.911 2.177A7.531 7.531 0 000 13.5a.5.5 0 00.5.5h9.1a.252.252 0 00.226-.143 8.545 8.545 0 013.041-3.47zM17.424 10.5a6.754 6.754 0 00-1.76 13.317 7.079 7.079 0 001.587.183 6.758 6.758 0 001.736-.226 1 1 0 00.71-1.223.988.988 0 00-1.041-.721.942.942 0 00-.183.011 4.744 4.744 0 01-1.053.151 5.047 5.047 0 01-1.309-.123 4.723 4.723 0 01-3.483-3.5 4.8 4.8 0 01.946-4.133 4.711 4.711 0 013.8-1.74 4.892 4.892 0 014.6 4.721c.006.1.027.188.028.286v.82a.8.8 0 01-1.594 0V17.25a3.157 3.157 0 10-3.156 3.156 3.106 3.106 0 001.476-.377.25.25 0 01.312.061 2.815 2.815 0 001.29.881 2.759 2.759 0 00.875.154 2.8 2.8 0 002.8-2.8v-.812a6.934 6.934 0 00-6.581-7.013zm-.174 7.9a1.157 1.157 0 111.156-1.156 1.158 1.158 0 01-1.156 1.162z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});