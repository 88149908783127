define("ember-svg-jar/inlined/video-game-the-sims", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-the-sims</title><path d=\"M22.75 19h-.5a.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25h-.5a.75.75 0 000 1.5h.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.5a.25.25 0 01.25-.25h.5a.75.75 0 000-1.5zM4.25 3a.75.75 0 000-1.5h-.5a.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25h-.5a.75.75 0 000 1.5h.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.5A.25.25 0 013.75 3zM16.726 11.25h2.731a.3.3 0 00.25-.466l-4.78-7.17a.25.25 0 00-.451.2l1.765 7.058a.5.5 0 00.485.378zM19.722 12.908a.3.3 0 00-.265-.158h-2.731a.5.5 0 00-.485.379l-1.765 7.058a.25.25 0 00.451.2l4.78-7.17a.3.3 0 00.015-.309zM3.778 11.092a.3.3 0 00.265.158h2.731a.5.5 0 00.485-.379l1.765-7.058a.25.25 0 00-.451-.2l-4.78 7.17a.3.3 0 00-.015.309zM6.774 12.75H4.043a.3.3 0 00-.25.466l4.78 7.17a.25.25 0 00.451-.2l-1.765-7.057a.5.5 0 00-.485-.379zM8.956 11.058a.5.5 0 00.394.192h4.8a.5.5 0 00.485-.621L12.044.23a.3.3 0 00-.588 0l-2.591 10.4a.5.5 0 00.091.428zM14.544 12.942a.5.5 0 00-.394-.192h-4.8a.5.5 0 00-.485.621l2.591 10.4a.3.3 0 00.588 0l2.591-10.4a.5.5 0 00-.091-.429z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});