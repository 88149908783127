define("ember-svg-jar/inlined/house-3-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>house-3-alternate</title><path d=\"M12.707.293a1 1 0 00-1.414 0l-11 11A1 1 0 001 13h1.25a.25.25 0 01.25.25V23a1 1 0 001 1H9a1 1 0 001-1v-5a2 2 0 014 0v5a1 1 0 001 1h5.5a1 1 0 001-1v-9.75a.25.25 0 01.25-.25H23a1 1 0 00.707-1.707zm7.538 10.366a.25.25 0 01-.092.412.972.972 0 00-.653.929v9.75a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25V18a4 4 0 00-8 0v3.75a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25V12a.97.97 0 00-.653-.929.25.25 0 01-.092-.412l8.068-8.068a.25.25 0 01.354 0z\"/><circle cx=\"12\" cy=\"9\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});