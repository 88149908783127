define("ember-svg-jar/inlined/signal-strong", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>signal-strong</title><rect x=\".375\" y=\"13.496\" width=\"3\" height=\"6\" rx=\".5\" ry=\".5\"/><rect x=\"5.375\" y=\"10.496\" width=\"3\" height=\"9\" rx=\".5\" ry=\".5\"/><rect x=\"10.375\" y=\"7.496\" width=\"3\" height=\"12\" rx=\".5\" ry=\".5\"/><rect x=\"15.375\" y=\"4.496\" width=\"3\" height=\"15\" rx=\".5\" ry=\".5\"/><path d=\"M22.625 19.5h-2a1 1 0 010-2h2a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});