define("ember-svg-jar/inlined/hyperlink-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hyperlink-3</title><path d=\"M10.458 18.374L7.721 21.11a2.853 2.853 0 01-3.942 0l-.892-.891a2.787 2.787 0 010-3.941l5.8-5.8a2.789 2.789 0 013.942 0l.893.892a1 1 0 001.418-1.418l-.893-.892a4.791 4.791 0 00-6.771 0l-5.8 5.8a4.787 4.787 0 000 6.77l.892.891a4.785 4.785 0 006.771 0l2.736-2.735a1 1 0 10-1.414-1.415z\"/><path d=\"M22.526 2.363l-.892-.892a4.8 4.8 0 00-6.77 0l-2.905 2.9a1 1 0 001.414 1.414l2.9-2.9a2.79 2.79 0 013.941 0l.893.893a2.786 2.786 0 010 3.942l-5.8 5.8a2.769 2.769 0 01-1.971.817 2.766 2.766 0 01-1.969-.816 1 1 0 10-1.415 1.412 4.751 4.751 0 003.384 1.4 4.752 4.752 0 003.385-1.4l5.8-5.8a4.786 4.786 0 000-6.771z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});