define("ember-svg-jar/inlined/cable-car", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cable-car</title><path d=\"M19.024 10.041H4.973A1.5 1.5 0 003.8 10.6c-2.736 3.418-2.734 9.544.006 12.852A1.5 1.5 0 004.962 24H19.04a1.5 1.5 0 001.153-.539c2.741-3.281 2.745-9.407.008-12.854a1.5 1.5 0 00-1.177-.566zm-7.774 8.226a.5.5 0 01-.5.5h-5.5a.5.5 0 01-.5-.5v-3.989a.5.5 0 01.5-.5h5.5a.5.5 0 01.5.5zm8 0a.5.5 0 01-.5.5h-5.5a.5.5 0 01-.5-.5v-3.989a.5.5 0 01.5-.5h5.5a.5.5 0 01.5.5zM23.963.73a1 1 0 00-1.231-.693L13.6 2.5a.08.08 0 01-.07-.013.082.082 0 01-.032-.064 1.5 1.5 0 00-3 0v.771a.25.25 0 01-.187.241L.746 5.945A1 1 0 001 7.907a.968.968 0 00.254-.033l8.932-2.343a.249.249 0 01.314.241V6.3a.25.25 0 01-.25.249H9.5A1.5 1.5 0 008 8.047v.5a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-.5a1.5 1.5 0 00-1.5-1.5h-.75a.25.25 0 01-.25-.247V4.788a.251.251 0 01.183-.241l9.585-2.589A1 1 0 0023.963.73z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});