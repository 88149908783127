define("ember-svg-jar/inlined/messages-bubble-square-star-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-star-alternate</title><path d=\"M9.577 14.394a.25.25 0 00-.2-.394H8.5a1 1 0 00-.664.252L5 16.773V15a1 1 0 00-1-1H2.25a.251.251 0 01-.25-.25V2.25A.25.25 0 012.25 2h18a.25.25 0 01.25.25v8.636a.257.257 0 00.02.1l.8 1.864a.25.25 0 00.23.151h.7a.25.25 0 00.25-.25V2a2 2 0 00-2-2H2a2 2 0 00-2 2v12.053A1.953 1.953 0 002 16h1v3a1 1 0 001.664.748L8.88 16a.147.147 0 00.146-.132A3.139 3.139 0 019.243 15a3.035 3.035 0 01.334-.606z\"/><path d=\"M20.737 23.827a1.273 1.273 0 001.505-.166 1.256 1.256 0 00.316-1.45l-1.3-2.981 2.348-2.092a1.262 1.262 0 00.3-1.378A1.232 1.232 0 0022.74 15h-2.665l-1.409-3.267-.013-.028a1.293 1.293 0 00-2.317.028L14.925 15h-2.666a1.229 1.229 0 00-1.166.76 1.271 1.271 0 00.319 1.4l2.329 2.076-1.295 2.98a1.255 1.255 0 00.315 1.451 1.286 1.286 0 001.506.166l3.233-1.827z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});