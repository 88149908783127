define("ember-svg-jar/inlined/responsive-design-phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>responsive-design-phone</title><path d=\"M.875 22.25A1.752 1.752 0 002.625 24h5a1.752 1.752 0 001.75-1.75v-2a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25h-5a.25.25 0 01-.25-.25v-5a.25.25 0 01.25-.25h2.5a.75.75 0 00.75-.749v-1.5a.751.751 0 00-.75-.751h-2.5a1.752 1.752 0 00-1.75 1.75z\"/><path d=\"M14.271 13.854a.5.5 0 00.854-.354v-3a.5.5 0 00-.5-.5h-3a.5.5 0 00-.354.854l.617.616a.25.25 0 010 .353l-2.94 2.94a.25.25 0 01-.353 0l-.616-.616a.5.5 0 00-.854.353v3a.5.5 0 00.5.5h3a.5.5 0 00.354-.853l-.617-.617a.25.25 0 010-.353l2.94-2.94a.25.25 0 01.353 0z\"/><path d=\"M9.125 7.5a1 1 0 002 0V4.25a.25.25 0 01.25-.25h9.5a.25.25 0 01.25.25v9.5a.25.25 0 01-.25.25h-4.75a1 1 0 00-1 1v2a1 1 0 001 1h5a2 2 0 002-2V2a2 2 0 00-2-2h-10a2 2 0 00-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});