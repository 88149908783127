define("ember-svg-jar/inlined/road-sign-road-narrows-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-road-narrows-alternate</title><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/><path d=\"M11.354 8.692l-1.6-1.846a1 1 0 10-1.51 1.308l1.445 1.667a.254.254 0 01.061.164v4.026a.247.247 0 01-.061.164l-1.445 1.671a1 1 0 101.512 1.308l1.549-1.794.011-.012a1.51 1.51 0 00.434-1.055V9.707a1.448 1.448 0 00-.384-1zm-1.526 5.323a.155.155 0 01.019-.023zm.023-4.007l.045.053a.742.742 0 01-.045-.053zM14.311 9.825l1.445-1.671a1 1 0 10-1.512-1.308L12.7 8.64l-.011.012a1.49 1.49 0 00-.434 1.055v4.586a1.467 1.467 0 00.384 1l.012.013 1.6 1.846a1 1 0 101.512-1.308l-1.445-1.667a.254.254 0 01-.061-.164V9.989a.247.247 0 01.054-.164zm-.211 4.114a.6.6 0 01.047.055zm.049-3.931l.018-.022a.142.142 0 01-.014.022z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});