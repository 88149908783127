define("ember-svg-jar/inlined/hotel-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hotel-information</title><circle cx=\"6\" cy=\"15.25\" r=\"2.5\"/><path d=\"M23 14.75a1 1 0 00-1 1v3a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-5.5a1 1 0 00-2 0V23a1 1 0 002 0v-.25a.5.5 0 01.5-.5h19a.5.5 0 01.5.5V23a1 1 0 002 0v-7.25a1 1 0 00-1-1z\"/><path d=\"M10.5 17.75H20a.5.5 0 00.5-.5v-1a2.5 2.5 0 00-2.5-2.5h-7a1 1 0 00-1 1v2.5a.5.5 0 00.5.5zM18.5 11A5.5 5.5 0 1013 5.5a5.507 5.507 0 005.5 5.5zm-.25-9.25a1 1 0 11-1 1 1 1 0 011-1zM17.75 6a.75.75 0 010-1.5h.5a1.252 1.252 0 011.25 1.25V7.5h.25a.75.75 0 010 1.5h-2a.75.75 0 010-1.5H18V6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});