define("ember-svg-jar/inlined/server-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-sync</title><path d=\"M0 3.5A2.5 2.5 0 002.5 6h17A2.5 2.5 0 0022 3.5v-1A2.5 2.5 0 0019.5 0h-17A2.5 2.5 0 000 2.5zM3.635 3A1.115 1.115 0 114.75 4.114 1.115 1.115 0 013.635 3zM7.5 3a1.115 1.115 0 111.115 1.114A1.114 1.114 0 017.5 3zM17.5 9.5a8.17 8.17 0 013.783.926.493.493 0 00.717-.444V9.5A2.5 2.5 0 0019.5 7h-17A2.5 2.5 0 000 9.5v1A2.5 2.5 0 002.5 13h8.019a.491.491 0 00.393-.2A8.233 8.233 0 0117.5 9.5zm-7.771.5a1.115 1.115 0 11-1.114-1.115A1.115 1.115 0 019.729 10zm-3.864 0A1.115 1.115 0 114.75 8.885 1.115 1.115 0 015.865 10zM2.5 20h6.425a.5.5 0 00.491-.593 8.24 8.24 0 01.434-4.73.5.5 0 00-.464-.677H2.5A2.5 2.5 0 000 16.5v1A2.5 2.5 0 002.5 20zm1.135-3a1.115 1.115 0 111.115 1.114A1.115 1.115 0 013.635 17zM11.854 23.033l.969-.97a.25.25 0 01.359.006 6.086 6.086 0 0010.21-2.2 1 1 0 00-1.893-.649 4.09 4.09 0 01-6.918 1.42.249.249 0 01.008-.344l1.721-1.722a.5.5 0 00-.353-.854H11.5a.5.5 0 00-.5.5v4.457a.485.485 0 00.068.224.45.45 0 00.087.112.47.47 0 00.154.126.5.5 0 00.545-.106z\"/><path d=\"M22.121 12.906a.251.251 0 01-.358 0 6.1 6.1 0 00-10.2 2.227 1 1 0 001.893.648 4.092 4.092 0 016.9-1.445.249.249 0 01-.007.346l-1.659 1.66a.5.5 0 00.353.853H23.5a.5.5 0 00.5-.5v-4.461a.5.5 0 00-.309-.462.485.485 0 00-.455.061c-.029.018-.064.022-.09.048z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});