define("ember-svg-jar/inlined/road-sign-hospital-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-hospital-alternate</title><path d=\"M13.936 7a1 1 0 00-1 1v2.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V8a1 1 0 00-2 0v8a1 1 0 002 0v-2.75a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V16a1 1 0 002 0V8a1 1 0 00-1-1z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});