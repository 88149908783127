define("ember-svg-jar/inlined/single-man-actions-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-monitor</title><path d=\"M9.437 13a3.5 3.5 0 011.517-2.874.247.247 0 00.108-.205V8.87a.252.252 0 01.074-.177 3.311 3.311 0 00.779-2.231.251.251 0 01.085-.176 1.873 1.873 0 00.538-1.4 1.58 1.58 0 00-.317-1.015.251.251 0 01-.034-.264 2.531 2.531 0 00.157-1.727C12.006.493 10.3 0 8.95 0a3.752 3.752 0 00-3.163 1.324.25.25 0 01-.212.118 1.072 1.072 0 00-.9.375c-.372.493-.106 1.17.176 1.732a.251.251 0 01-.046.288 1.3 1.3 0 00-.162.2A1.763 1.763 0 004.382 5a1.521 1.521 0 00.638 1.331.249.249 0 01.117.194A3.162 3.162 0 005.983 8.7a.253.253 0 01.079.183v1.123a.248.248 0 01-.151.229l-3.937 1.687C.7 12.468.519 13.626.267 15.23c-.055.359-.117.751-.2 1.179A.5.5 0 00.562 17h8.625a.25.25 0 00.25-.25zM19.937 22h-1.25a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h3.25a2 2 0 002-2v-6a2 2 0 00-2-2h-9a2 2 0 00-2 2v6a2 2 0 002 2h3.25a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-1.25a.985.985 0 00-.846.5.969.969 0 00-.154.5 1 1 0 001 1h5a1 1 0 001-1 .968.968 0 00-.153-.5.986.986 0 00-.847-.5zm1.75-9a.25.25 0 01.25.25v5.5a.249.249 0 01-.25.25h-8.5a.25.25 0 01-.25-.25v-5.5a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});