define("ember-svg-jar/inlined/shipment-fragile-chip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-fragile-chip</title><path d=\"M21.215 7.951v-.034L20.272.868A1 1 0 0019.281 0h-6.562a1 1 0 00-1 1v.75a.25.25 0 01-.25.25h-.75a1 1 0 00-1 1v1.75a.25.25 0 01-.25.25h-.841a1 1 0 00-.98.8l-.419 2.1a.466.466 0 00-.01.1 7.005 7.005 0 005.793 6.887.25.25 0 01.207.246v6.617a.25.25 0 01-.25.25h-1.75a1 1 0 000 2h6a1 1 0 000-2h-1.75a.25.25 0 01-.25-.25v-6.617a.249.249 0 01.207-.246 7.022 7.022 0 005.789-6.936zM8.719 2V1a1 1 0 00-1-1H4.161a1 1 0 00-.994.876l-.375 3A1 1 0 003.786 5h1.933a1 1 0 001-1v-.75a.25.25 0 01.25-.25h.75a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});