define("ember-svg-jar/inlined/single-man-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-question</title><path d=\"M17.5.008a6.5 6.5 0 106.5 6.5 6.506 6.506 0 00-6.5-6.5zm0 11.5a1 1 0 111-1 1 1 0 01-1 .998zm.75-4.108v.359a.75.75 0 11-1.5 0V7.2a1.256 1.256 0 01.988-1.222 1.125 1.125 0 10-1.363-1.1.75.75 0 01-1.5 0A2.625 2.625 0 1118.251 7.4zM5.5 8.994a5.505 5.505 0 00-5.5 5.5v3a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.534a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.312a.25.25 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3a5.506 5.506 0 00-5.5-5.499zm.75 5.948a.75.75 0 01-1.5 0v-3.948a.75.75 0 011.5 0zM5.5 8.144a4.068 4.068 0 10-4.066-4.068A4.073 4.073 0 005.5 8.144zm0-2a2.07 2.07 0 01-2.066-2.068 1.968 1.968 0 01.137-.718.249.249 0 01.362-.124 5.058 5.058 0 002.608.725 4.985 4.985 0 00.737-.059.249.249 0 01.286.247A2.028 2.028 0 015.5 6.144z\"/><path d=\"M17.5.008a6.5 6.5 0 106.5 6.5 6.506 6.506 0 00-6.5-6.5zm0 11.5a1 1 0 111-1 1 1 0 01-1 .998zm.75-4.108v.359a.75.75 0 11-1.5 0V7.2a1.256 1.256 0 01.988-1.222 1.125 1.125 0 10-1.363-1.1.75.75 0 01-1.5 0A2.625 2.625 0 1118.251 7.4zM5.5 8.994a5.505 5.505 0 00-5.5 5.5v3a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.534a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.312a.25.25 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3a5.506 5.506 0 00-5.5-5.499zm.75 5.948a.75.75 0 01-1.5 0v-3.948a.75.75 0 011.5 0zM5.5 8.144a4.068 4.068 0 10-4.066-4.068A4.073 4.073 0 005.5 8.144zm0-2a2.07 2.07 0 01-2.066-2.068 1.968 1.968 0 01.137-.718.249.249 0 01.362-.124 5.058 5.058 0 002.608.725 4.985 4.985 0 00.737-.059.249.249 0 01.286.247A2.028 2.028 0 015.5 6.144z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});