define("ember-svg-jar/inlined/folder-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-text</title><path d=\"M21.5 5.25a.25.25 0 00.25-.25v-.75A.75.75 0 0021 3.5H8l-.18-.076-1.342-1.389a1.751 1.751 0 00-1.26-.535H2.286A2.037 2.037 0 00.25 3.536V16.25A1.749 1.749 0 002 18h.25a.25.25 0 00.25-.25v-9.5a3 3 0 013-3z\"/><path d=\"M22 7H6a1.752 1.752 0 00-1.75 1.75v12A1.752 1.752 0 006 22.5h16a1.752 1.752 0 001.75-1.75v-12A1.752 1.752 0 0022 7zM8 10.5h12a.75.75 0 010 1.5H8a.75.75 0 010-1.5zm4.5 8.5H8a.75.75 0 010-1.5h4.5a.75.75 0 010 1.5zm0-3.5H8A.75.75 0 018 14h4.5a.75.75 0 010 1.5zm8.75-.25a.75.75 0 01-1.5 0v-.125a.125.125 0 00-.125-.125h-.75a.125.125 0 00-.125.125v2.752a.123.123 0 00.123.123H19a.75.75 0 010 1.5h-2a.75.75 0 010-1.5h.125a.125.125 0 00.125-.125v-2.75a.125.125 0 00-.125-.125h-.75a.125.125 0 00-.125.125v.125a.75.75 0 01-1.5 0v-1a.75.75 0 01.75-.75h5a.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});