define("ember-svg-jar/inlined/expand-8", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>expand-8</title><path d=\"M1.967 13.41h7.167a1.455 1.455 0 011.455 1.455v7.167a1.456 1.456 0 01-1.456 1.456H1.967a1.456 1.456 0 01-1.456-1.456v-7.167a1.456 1.456 0 011.456-1.455zM1.489 9.426a1.25 1.25 0 001.25-1.25V2.988a.25.25 0 01.25-.25h5.189a1.25 1.25 0 000-2.5H2.444a2.207 2.207 0 00-2.2 2.205v5.733a1.249 1.249 0 001.245 1.25zM23.761 2.443a2.207 2.207 0 00-2.2-2.2h-5.739a1.25 1.25 0 000 2.5h5.189a.25.25 0 01.25.25v5.183a1.25 1.25 0 002.5 0zM23.761 21.554v-5.733a1.25 1.25 0 00-2.5 0v5.189a.25.25 0 01-.25.25H16.3a1.25 1.25 0 000 2.5h5.256a2.208 2.208 0 002.205-2.206z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});