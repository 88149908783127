define("ember-svg-jar/inlined/smart-watch-circle-star-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-star-alternate</title><path d=\"M21.5 12A9.5 9.5 0 0017 3.927a.245.245 0 01-.109-.148L15.983.373A.5.5 0 0015.5 0h-7a.5.5 0 00-.484.371l-.908 3.408A.245.245 0 017 3.927a9.5 9.5 0 000 16.149.25.25 0 01.109.148l.908 3.407A.5.5 0 008.5 24h7a.5.5 0 00.482-.368l.935-3.428a.247.247 0 01.109-.146A9.5 9.5 0 0021.5 12zM12 19.5a7.5 7.5 0 117.5-7.5 7.509 7.509 0 01-7.5 7.5z\"/><path d=\"M15.492 10H14.01a.251.251 0 01-.234-.162l-.844-2.229a.431.431 0 00-.022-.049 1.054 1.054 0 00-1.857.049l-.845 2.231a.251.251 0 01-.234.16H8.492a.986.986 0 00-.934.611 1.03 1.03 0 00.3 1.154l1.232.859a.251.251 0 01.087.3l-.717 1.645a1 1 0 00.251 1.158 1.022 1.022 0 001.2.133l1.956-1.1a.254.254 0 01.245 0l1.957 1.1a1.027 1.027 0 001.2-.133 1 1 0 00.251-1.158l-.72-1.639a.25.25 0 01.085-.3l1.3-.912a1 1 0 00.238-1.1.984.984 0 00-.931-.618z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});