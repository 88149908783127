define("ember-svg-jar/inlined/school-book-trophy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>school-book-trophy</title><path d=\"M7.519 8.983a.5.5 0 01.321.149 4.618 4.618 0 001.582 1.02C10 10.4 10 10.4 10 11a2.374 2.374 0 01-1.451 2.435 1.7 1.7 0 00-.893.855.5.5 0 00.454.71h7.78a.5.5 0 00.454-.71 1.693 1.693 0 00-.892-.854A2.376 2.376 0 0114 11c0-.6 0-.6.578-.848a4.629 4.629 0 001.582-1.019.5.5 0 01.321-.15c4.395-.329 4.857-5.415 5.012-7.126A1.7 1.7 0 0019.8 0H4.2a1.7 1.7 0 00-1.693 1.858c.155 1.71.617 6.795 5.012 7.125zm10.463-6.74a.25.25 0 01.25-.243h.959a.253.253 0 01.187.084.248.248 0 01.061.195 6.678 6.678 0 01-1.426 3.958.25.25 0 01-.42-.23 21.8 21.8 0 00.389-3.764zm-13.36-.159A.253.253 0 014.809 2h.959a.25.25 0 01.25.242 21.926 21.926 0 00.388 3.765.25.25 0 01-.42.229 6.684 6.684 0 01-1.425-3.957.251.251 0 01.061-.195zM20 16H1a1 1 0 000 2h6a2 2 0 010 4H1a1 1 0 000 2h19a4 4 0 000-8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});