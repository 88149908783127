define("ember-svg-jar/inlined/history-sphinx", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-sphinx</title><path d=\"M7.65 7.846a.494.494 0 00.859-.285l.432-4.538a.5.5 0 00-.051-.271l-.053-.106a.5.5 0 00-.585-.256 10.067 10.067 0 00-2.413 1.055.5.5 0 00-.185.66L7.5 7.643l.012.022a1.087 1.087 0 00.138.181z\"/><path d=\"M22.41 8.54a9.919 9.919 0 00-2.156-3.425.5.5 0 00-.8.115l-1.447 2.763a.5.5 0 00.012.486A6.883 6.883 0 0119 12a7 7 0 01-14 0 6.9 6.9 0 01.977-3.53.5.5 0 00.012-.486L4.549 5.23a.5.5 0 00-.8-.114A9.913 9.913 0 001.59 8.54 42.454 42.454 0 000 17.61 2.39 2.39 0 002.39 20H3.5a.5.5 0 01.5.5v2A1.5 1.5 0 005.5 24h1A1.5 1.5 0 008 22.5v-2.206a.5.5 0 01.7-.46 7.983 7.983 0 001.111.378.5.5 0 01.373.43l.271 2.466a1.006 1.006 0 001 .887h1.1a1.006 1.006 0 001-.887l.271-2.466a.5.5 0 01.373-.43 7.983 7.983 0 001.111-.378.5.5 0 01.7.46V22.5A1.5 1.5 0 0017.5 24h1a1.5 1.5 0 001.5-1.5v-2a.5.5 0 01.5-.5h1.11A2.39 2.39 0 0024 17.61a42.028 42.028 0 00-1.59-9.07z\"/><path d=\"M15.491 7.561a.493.493 0 00.853.287 1.217 1.217 0 00.156-.208l.012-.022 1.836-3.513a.5.5 0 00-.185-.66 10.084 10.084 0 00-2.415-1.055.5.5 0 00-.585.256l-.053.106a.5.5 0 00-.051.271zM10.423 2.469a.494.494 0 01.051.27L10 7.7a.5.5 0 00.5.547h3A.5.5 0 0014 7.7l-.473-4.966a.5.5 0 01.051-.272l.511-1.019a1 1 0 00-.045-.97A1.006 1.006 0 0013.191 0h-2.382a1.006 1.006 0 00-.851.476.994.994 0 00-.044.971zM7 10.75a.75.75 0 000 1.5h.993a.5.5 0 01.2.042 2.646 2.646 0 001.057.208c.966 0 1.75-.448 1.75-1s-.784-1-1.75-1a2.646 2.646 0 00-1.056.208.513.513 0 01-.2.042zM13 11.5c0 .552.784 1 1.75 1a2.646 2.646 0 001.056-.208.513.513 0 01.2-.042H17a.75.75 0 000-1.5h-.992a.5.5 0 01-.2-.042 2.654 2.654 0 00-1.058-.208c-.966 0-1.75.448-1.75 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});