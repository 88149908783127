define("ember-svg-jar/inlined/engagement-ring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>engagement-ring</title><path d=\"M11.7 10.011a.5.5 0 00.6 0c1.221-.908 5.2-4.077 5.2-6.727A3.147 3.147 0 0014.921.033a2.877 2.877 0 00-2.747 1.151.219.219 0 01-.348 0A2.879 2.879 0 009.079.033 3.147 3.147 0 006.5 3.284c0 2.65 3.981 5.816 5.2 6.727z\"/><path d=\"M17.949 9.429a1.5 1.5 0 10-2.1 2.142 5.5 5.5 0 11-7.7 0 1.5 1.5 0 00-2.1-2.142A8.5 8.5 0 1020.5 15.5a8.436 8.436 0 00-2.551-6.071z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});