define("ember-svg-jar/inlined/lighter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lighter</title><path d=\"M15.83 12.2a4.835 4.835 0 004.83-4.829A8.016 8.016 0 0015.881.1 1.277 1.277 0 0014.33 2a2.723 2.723 0 01.449 1.157.061.061 0 01-.023.056.057.057 0 01-.059.007 1.283 1.283 0 00-1.054.026A4.446 4.446 0 0011 7.368a4.835 4.835 0 004.83 4.832zm-2.117-6.6a.248.248 0 01.364.015 5.162 5.162 0 00.64.607l.429.352a.5.5 0 00.734-.111L16.186 6a4.32 4.32 0 00.628-2.259.249.249 0 01.442-.155 6 6 0 011.4 3.779 2.83 2.83 0 01-5.66 0 2.381 2.381 0 01.717-1.765z\"/><path d=\"M22 14.5a1.5 1.5 0 00-1.5-1.5H9.25a.25.25 0 01-.25-.25V1.5A1.5 1.5 0 007.5 0h-1A4.505 4.505 0 002 4.5v5A4.505 4.505 0 006.5 14h1.25a.25.25 0 01.25.25v7.25a2.5 2.5 0 002.5 2.5h9a2.5 2.5 0 002.5-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});