define("ember-svg-jar/inlined/expand-7", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>expand-7</title><path d=\"M13.25 12a1.246 1.246 0 00.884-.366L20.6 5.163a.25.25 0 01.354 0L22.9 7.1a.5.5 0 00.354.147.512.512 0 00.191-.038.5.5 0 00.309-.462v-6a.5.5 0 00-.5-.5h-6a.5.5 0 00-.354.853l1.94 1.94a.249.249 0 010 .353l-6.47 6.47A1.25 1.25 0 0013.25 12z\"/><rect x=\".25\" y=\"12.749\" width=\"11\" height=\"11\" rx=\"2\" ry=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});