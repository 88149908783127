define("ember-svg-jar/inlined/web-service-squarespace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>web-service-squarespace</title><path d=\"M4.68 16.33A4.5 4.5 0 011.5 8.65L7.16 3a4.15 4.15 0 013.7-1.24.25.25 0 01.12.43c-.62.56-2 1.82-2.4 2.22l-5.66 5.66a2.5 2.5 0 103.54 3.54l7.36-7.36a1 1 0 011.41 1.41L7.86 15a4.49 4.49 0 01-3.18 1.33z\"/><path d=\"M4.49 13.08a1 1 0 01-.71-1.71L11.16 4a4.47 4.47 0 013.18-1.32A4.47 4.47 0 0117.52 4a1 1 0 11-1.42 1.41 2.48 2.48 0 00-1.77-.73 2.48 2.48 0 00-1.77.73L5.2 12.78a1 1 0 01-.71.3zM13.33 22.22a.25.25 0 01-.11-.42l2.2-2.19 5.66-5.66a2.5 2.5 0 000-3.53 2.56 2.56 0 00-3.54 0l-7.36 7.36a1 1 0 01-1.41-1.41L16.13 9a4.5 4.5 0 116.36 6.36L16.84 21a3.71 3.71 0 01-3.51 1.22z\"/><path d=\"M9.66 21.33A4.49 4.49 0 016.48 20a1 1 0 011.41-1.42 2.5 2.5 0 003.54 0l7.31-7.31a1 1 0 011.41 1.41L12.84 20a4.49 4.49 0 01-3.18 1.33z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});