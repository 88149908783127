define("ember-svg-jar/inlined/instrument-tuba", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>instrument-tuba</title><path d=\"M2.962 14.962c-1.5-1.5-4 1-2.5 2.5S5 22.038 6.5 23.538s4-1 2.5-2.5-4.538-4.576-6.038-6.076z\"/><circle cx=\"8.5\" cy=\"10.5\" r=\"2\"/><circle cx=\"13\" cy=\"6.5\" r=\"2\"/><path d=\"M22.1 3.689a.251.251 0 010-.353L23.712 1.7A1 1 0 0022.288.3L20.69 1.92a.249.249 0 01-.354 0L18.707.293a1 1 0 00-1.414 1.414l.41.41a.249.249 0 01-.013.365l-1.837 1.607a.249.249 0 00-.036.336 3.5 3.5 0 01-3.53 5.5.25.25 0 00-.3.266c.008.1.013.2.013.307a3.5 3.5 0 01-5.962 2.485.249.249 0 00-.34-.01l-1.564 1.37a.25.25 0 00-.085.18.247.247 0 00.073.185c.717.72 1.643 1.654 2.569 2.588s1.865 1.88 2.583 2.6a.252.252 0 00.366-.012L21.518 6.31a.249.249 0 01.365-.013l.41.41a1 1 0 001.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});