define("ember-svg-jar/inlined/crypto-currency-bitcoin-network", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-network</title><path d=\"M11.966 2.057a.247.247 0 01.18-.078h.354a1 1 0 000-2C1.72-.021 0 9.3 0 12s1.72 12.021 12.5 12.021a1 1 0 000-2h-.357a.245.245 0 01-.178-.077 17.691 17.691 0 01-2.621-3.3.25.25 0 01.216-.375h1.54a1 1 0 100-2H8.507a.248.248 0 01-.24-.182 13.9 13.9 0 01-.474-2.821.25.25 0 01.249-.266H10.1a1 1 0 100-2H8.042a.248.248 0 01-.249-.267 13.9 13.9 0 01.474-2.822.251.251 0 01.24-.182H11.1a1 1 0 000-2H9.56a.25.25 0 01-.216-.375 17.686 17.686 0 012.622-3.297zM8.392 20.836a.251.251 0 01-.3.376 10.07 10.07 0 01-3.519-2.524.25.25 0 01.186-.417h2.013a.249.249 0 01.228.148 13.575 13.575 0 001.392 2.417zm-2.211-4.872a.251.251 0 01-.244.307H3.129a.25.25 0 01-.228-.147 9.927 9.927 0 01-.818-2.841.251.251 0 01.248-.283h3.21a.251.251 0 01.25.235 16.418 16.418 0 00.39 2.729zm-.39-5.2a.25.25 0 01-.25.234h-3.21a.25.25 0 01-.188-.085.253.253 0 01-.06-.2A9.949 9.949 0 012.9 7.875a.251.251 0 01.228-.146h2.809a.251.251 0 01.244.307 16.43 16.43 0 00-.39 2.73zM4.758 5.729a.25.25 0 01-.186-.417A10.066 10.066 0 018.09 2.788a.25.25 0 01.3.376A13.573 13.573 0 007 5.581a.25.25 0 01-.229.148zM14 18.021a1 1 0 001 1h.75a.25.25 0 01.25.25v.75a1 1 0 002 0v-.75a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v.75a1 1 0 002 0v-.953a.249.249 0 01.176-.239 3.965 3.965 0 001.633-6.63.251.251 0 010-.356 3.965 3.965 0 00-1.633-6.63.25.25 0 01-.176-.239v-.953a1 1 0 00-2 0v.75a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25v-.75a1 1 0 00-2 0v.75a.25.25 0 01-.25.25H15a1 1 0 00-1 1zm2-10.75a.25.25 0 01.25-.25H20a2 2 0 010 4h-3.75a.25.25 0 01-.25-.25zm4 9.75h-3.75a.25.25 0 01-.25-.25v-3.5a.25.25 0 01.25-.25H20a2 2 0 010 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});