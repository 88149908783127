define("ember-svg-jar/inlined/team-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>team-chat</title><path d=\"M11 10.5V9.249A.25.25 0 0111.25 9h.25a2 2 0 002-2V2a2 2 0 00-2-2H2a2 2 0 00-2 2v5a2 2 0 002 2h4.982a.25.25 0 01.177.074l2.134 2.133A1 1 0 0011 10.5zM10 7a1 1 0 00-1 1 .036.036 0 01-.061.026l-.732-.733A1 1 0 007.5 7h-5a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5H11a.5.5 0 01.5.5v4a.5.5 0 01-.5.5zM22 12.5h-9.5a2 2 0 00-2 2v5a2 2 0 002 2h.25a.25.25 0 01.25.25V23a1 1 0 00.617.924 1 1 0 001.09-.217l2.134-2.133a.25.25 0 01.177-.074H22a2 2 0 002-2v-5a2 2 0 00-2-2zm0 6.5a.5.5 0 01-.5.5h-5a1 1 0 00-.707.293l-.732.733A.036.036 0 0115 20.5a1 1 0 00-1-1h-1a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h8.5a.5.5 0 01.5.5zM14 11a.5.5 0 00.5.5h9a.5.5 0 00.5-.5 3.608 3.608 0 00-2.344-3.532.25.25 0 01-.066-.421 4 4 0 10-5.18 0 .25.25 0 01-.066.421A3.608 3.608 0 0014 11zm3.08-7.56a.246.246 0 01.148-.163.25.25 0 01.22.018A4.949 4.949 0 0020 4a4.95 4.95 0 00.9-.082.089.089 0 01.069.018A.085.085 0 0121 4a2 2 0 11-3.92-.559zM7.656 19.967a.25.25 0 01-.066-.421 4 4 0 10-5.18 0 .25.25 0 01-.066.421A3.608 3.608 0 000 23.5a.5.5 0 00.5.5h9a.5.5 0 00.5-.5 3.608 3.608 0 00-2.344-3.533zM3.08 15.94a.246.246 0 01.148-.163.25.25 0 01.22.018 4.981 4.981 0 003.453.622.089.089 0 01.069.018.085.085 0 01.03.065 2 2 0 11-3.92-.559z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});