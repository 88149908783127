define("ember-svg-jar/inlined/crime-man-riot-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-man-riot-1</title><path d=\"M5.474 15.878a.2.2 0 01-.122.127 12.681 12.681 0 00-3.562 1.809 2.606 2.606 0 00-1.04 2.1V23a.75.75 0 00.75.75h21a.75.75 0 00.75-.75v-3.086a2.606 2.606 0 00-1.04-2.1 12.674 12.674 0 00-3.56-1.809.218.218 0 01-.069-.041.187.187 0 01-.063-.179 47.593 47.593 0 00.86-8.131 7.392 7.392 0 00-7.385-7.4h-.008a7.387 7.387 0 00-7.361 7.4 47.2 47.2 0 00.858 8.108.219.219 0 01-.008.116zm2.9-8.123a1.719 1.719 0 011.244-1.494 8.377 8.377 0 012.358-.294 8.377 8.377 0 012.358.294 1.72 1.72 0 011.241 1.492l.244 2.355a.252.252 0 00.089.166 6.764 6.764 0 01.714.685 1.5 1.5 0 01.378 1c0 7.134-4.292 8.876-4.474 8.947a1.5 1.5 0 01-1.087 0C11.258 20.829 7 19.086 7 11.955a1.5 1.5 0 01.379-1 6.517 6.517 0 01.7-.673.249.249 0 00.088-.169c.133-1.446.208-2.337.21-2.358z\"/><path d=\"M13.267 8.956l.034.007A.989.989 0 1012.5 8a.986.986 0 00.767.956zM11.5 8a1 1 0 10-1 1 .969.969 0 00.2-.041l.03-.006A.985.985 0 0011.5 8zM11.8 19.968a.5.5 0 00.182.035.509.509 0 00.18-.034c.16-.061 3.838-1.558 3.838-8.014a.5.5 0 00-.126-.332 5.062 5.062 0 00-7.748 0 .5.5 0 00-.126.332c0 6.455 3.648 7.953 3.8 8.013z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});