define("ember-svg-jar/inlined/paragraph-indent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paragraph-indent</title><path d=\"M2.2 8.348a1 1 0 001.4.2l3.465-2.6a1.5 1.5 0 000-2.4L3.6.948a1 1 0 00-1.2 1.6l2.666 2a.25.25 0 010 .4l-2.666 2a1 1 0 00-.2 1.4zM23 21.248H1a1 1 0 000 2h22a1 1 0 000-2zM23 16.248H1a1 1 0 000 2h22a1 1 0 000-2zM23 11.248H1a1 1 0 000 2h22a1 1 0 000-2zM24 7.248a1 1 0 00-1-1H12a1 1 0 000 2h11a1 1 0 001-1zM12 3.248h11a1 1 0 000-2H12a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});