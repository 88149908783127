define("ember-svg-jar/inlined/office-chair-table-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-chair-table-1</title><path d=\"M9.822 16.132a20.752 20.752 0 01-.975-.108.5.5 0 00-.564.452L8 19.655v.011a1.013 1.013 0 00.873 1.079.458.458 0 00.064 0H9a1.007 1.007 0 001-.906v-.006l.276-3.163a.5.5 0 00-.454-.538zM15.717 16.476a.5.5 0 00-.564-.452c-.322.043-.648.08-.975.108a.5.5 0 00-.454.542L14 19.837v.006a1.008 1.008 0 001 .906h.063a.463.463 0 00.065 0A1.013 1.013 0 0016 19.666v-.011z\"/><path d=\"M23.561 9.1l-1.414-1.411a1.492 1.492 0 00-1.061-.44H16a.5.5 0 01-.5-.5 3.5 3.5 0 00-7 0 .5.5 0 01-.5.5H2.914a1.492 1.492 0 00-1.061.44L.439 9.1A1.49 1.49 0 000 10.163v9.586a1 1 0 002 0v-8a.5.5 0 01.5-.5h5.75a.5.5 0 01.5.5v.624a.5.5 0 01-.6.491 16.05 16.05 0 01-.436-.091 1 1 0 00-.434 1.953 21.771 21.771 0 004.72.514 21.771 21.771 0 004.717-.514 1 1 0 00-.434-1.953c-.145.032-.29.063-.436.091a.5.5 0 01-.6-.491v-.624a.5.5 0 01.5-.5H21.5a.5.5 0 01.5.5v8a1 1 0 002 0v-9.586a1.49 1.49 0 00-.439-1.063zM12 5.249a1.5 1.5 0 011.5 1.5.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5 1.5 1.5 0 011.5-1.5zm1.75 7.445a.5.5 0 01-.468.5 19.942 19.942 0 01-2.564 0 .5.5 0 01-.468-.5v-.945a.5.5 0 01.5-.5h2.5a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});