define("ember-svg-jar/inlined/app-window-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-add</title><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.5 7.25h-1.5a.25.25 0 00-.25.25V20a.75.75 0 11-1.5 0v-1.5a.25.25 0 00-.25-.25H15a.75.75 0 010-1.5h1.5a.25.25 0 00.25-.25V15a.75.75 0 111.5 0v1.5a.25.25 0 00.25.25H20a.75.75 0 010 1.5z\"/><path d=\"M24 3a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h5a1 1 0 001-1 1 1 0 00-1-1H3a1 1 0 01-1-1V5.251A.25.25 0 012.25 5h19.5a.25.25 0 01.25.25V9.5a1 1 0 001 1 1 1 0 001-1zM4.346 3a.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.987.987 0 01.092-.409A1 1 0 013.5 1.5a.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5zm3.5 0a.966.966 0 01-1.692 0A.972.972 0 016 2.5a.969.969 0 01.154-.5.966.966 0 011.692 0A.969.969 0 018 2.5a.972.972 0 01-.154.5zm3.5 0a.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});