define("ember-svg-jar/inlined/road-sign-no-pedestrian-crossing-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-no-pedestrian-crossing-alternate</title><circle cx=\"9.019\" cy=\"5.75\" r=\"2.5\"/><circle cx=\"17.019\" cy=\"12.25\" r=\"1.5\"/><path d=\"M8.115 19.12a.249.249 0 00-.273.054l-.632.632a.25.25 0 00-.064.24.247.247 0 00.175.177.717.717 0 00.2.027.75.75 0 00.75-.75v-.149a.25.25 0 00-.156-.231zM11.943 15.654a.249.249 0 00-.425-.155l-1.676 1.675a.249.249 0 00-.073.177V19.5a.75.75 0 001.5 0v-2.526a.224.224 0 01.224-.224.5.5 0 00.5-.544z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM4.267 18.2a.248.248 0 01-.182-.1A9.993 9.993 0 0118.1 4.085a.25.25 0 01.024.375L12 10.59a.247.247 0 01-.184.073.252.252 0 01-.179-.083l-.142-.158a.261.261 0 01-.05-.085A2.638 2.638 0 009.019 8.75a2.5 2.5 0 00-2.5 2.489v.022l-.45 4.939a.4.4 0 00.01.134.249.249 0 01-.067.235L4.46 18.126a.249.249 0 01-.193.074zm15.3.319a.248.248 0 01-.2.086.251.251 0 01-.193-.111l-.647-.97V15.75a1.5 1.5 0 00-2.686-.909.248.248 0 01-.31.072l-.669-.334a.75.75 0 10-.67 1.342l1.2.6a.248.248 0 01.138.223V19.5a.75.75 0 001.5 0v-.7a.25.25 0 01.458-.139l.668 1a.18.18 0 01-.04.243 9.944 9.944 0 01-11.5.5c-.243-.156-.48-.319-.708-.493a.249.249 0 01-.025-.375L19.54 5.875a.245.245 0 01.193-.073.25.25 0 01.182.1c.174.228.337.465.493.708a9.932 9.932 0 01-.845 11.91z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});