define("ember-svg-jar/inlined/vr-monitor-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vr-monitor-box</title><path d=\"M8.373 7.553a2.487 2.487 0 013.254 0l.986.845A2.5 2.5 0 0014.24 9h4.26A2.5 2.5 0 0021 6.5v-5A1.5 1.5 0 0019.5 0h-18A1.5 1.5 0 000 1.5v5A2.5 2.5 0 002.5 9h3.26a2.5 2.5 0 001.627-.6zM18.943 11.789l-3-1.25a.492.492 0 00-.384 0l-3 1.25a.546.546 0 00-.307.461v3.5a.5.5 0 00.308.461l3 1.25a.492.492 0 00.384 0l3-1.25a.5.5 0 00.308-.461v-3.5a.546.546 0 00-.309-.461zm-1.493.461l-1.7.708-1.7-.708 1.7-.709z\"/><path d=\"M24 11.75A1.752 1.752 0 0022.25 10h-1a.75.75 0 000 1.5h1a.25.25 0 01.25.25v6.5a.25.25 0 01-.25.25h-13a.25.25 0 01-.25-.25v-6.5a.25.25 0 01.25-.25h2a.75.75 0 000-1.5h-2a1.752 1.752 0 00-1.75 1.75v7.75a1.752 1.752 0 001.75 1.75h5.5a.25.25 0 01.25.25v.75a.25.25 0 01-.25.25h-1.5a.75.75 0 000 1.5h5a.75.75 0 000-1.5h-1.5a.25.25 0 01-.25-.25v-.75a.25.25 0 01.25-.25h5.5A1.752 1.752 0 0024 19.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});