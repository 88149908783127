define("ember-svg-jar/inlined/microphone-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>microphone-2</title><path d=\"M13.679 11.567a.251.251 0 00-.179.24v2.386a.251.251 0 00.179.24l2.5.74a.249.249 0 00.321-.239v-3.868a.249.249 0 00-.321-.239zM10.321 14.433a.251.251 0 00.179-.24v-2.386a.251.251 0 00-.179-.24l-2.5-.74a.249.249 0 00-.321.239v3.868a.249.249 0 00.321.239zM11.929 8.915a.263.263 0 00.142 0l4.25-1.259a.251.251 0 00.179-.24V4.75a.25.25 0 00-.25-.25h-8.5a.25.25 0 00-.25.25v2.666a.251.251 0 00.179.24zM12.071 17.085a.263.263 0 00-.142 0L8.2 18.192a.249.249 0 00-.122.4A2.5 2.5 0 0010 19.5h.25a.25.25 0 01.25.25v2.75a1.5 1.5 0 003 0v-2.75a.25.25 0 01.25-.25H14a2.5 2.5 0 001.927-.909.249.249 0 00-.122-.4zM16.25 3a.25.25 0 00.25-.25V2.5A2.5 2.5 0 0014 0h-4a2.5 2.5 0 00-2.5 2.5v.25a.25.25 0 00.25.25z\"/><path d=\"M19.42 9.961a1.75 1.75 0 00-2.244-1.776L12.071 9.7a.237.237 0 01-.142 0L6.824 8.185A1.75 1.75 0 004.58 9.959L4.747 13a.122.122 0 010 .027l-.167 3.012a1.75 1.75 0 002.244 1.776l5.105-1.515a.237.237 0 01.142 0l5.1 1.512a1.746 1.746 0 002.244-1.775L19.253 13a.122.122 0 010-.027zM6.4 16.378a.25.25 0 01-.32-.254l.166-3.024v-.193l-.168-3.032a.247.247 0 01.065-.181.251.251 0 01.257-.072l4.672 1.385a.251.251 0 01.179.24v3.507a.25.25 0 01-.179.239zM17.754 13.1l.168 3.028a.25.25 0 01-.32.253l-4.673-1.385a.251.251 0 01-.179-.24v-3.51a.25.25 0 01.179-.239L17.6 9.622a.25.25 0 01.32.254l-.166 3.024a2.223 2.223 0 000 .2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});