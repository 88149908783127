define("ember-svg-jar/inlined/breakup-divorce", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>breakup-divorce</title><path d=\"M23.161 7.672L13.142.354a2.018 2.018 0 00-2.3.014L.859 7.657A2 2 0 000 9.3V23a1 1 0 002 0l.018-13.207a1 1 0 01.409-.8l8.964-6.572a1 1 0 011.181 0L21.59 9a1 1 0 01.41.808V23a1 1 0 002 0V9.3a1.987 1.987 0 00-.839-1.628z\"/><path d=\"M13.048 10.6a2.809 2.809 0 00-.794 1.251l-.539 1.749a.5.5 0 00.478.647H14.5a.75.75 0 01.707 1L13.4 20.376a.25.25 0 00.415.257l4.6-4.738a3.711 3.711 0 00.711-4.324 3.822 3.822 0 00-6.078-.971z\"/><path d=\"M12.733 15.75H10.5a.75.75 0 01-.717-.971l1.169-3.8a.5.5 0 00-.144-.519 3.822 3.822 0 00-5.933 1.109 3.709 3.709 0 00.71 4.324l5.443 5.6a.25.25 0 00.415-.091l1.762-4.992a.5.5 0 00-.472-.666z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});