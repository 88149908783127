define("ember-svg-jar/inlined/single-man-actions-graduate.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-graduate</title><path d=\"M.619 18.062H2.41a.246.246 0 01.246.227l.444 5.257a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h.554a.248.248 0 00.248-.248v-.247a1.979 1.979 0 011.2-1.819l.134-.058a.247.247 0 00.15-.227V14.1a5.443 5.443 0 10-10.886 0v3.464a.494.494 0 00.495.498z\"/><path d=\"M1.361 4.206A4.206 4.206 0 105.567 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM11.01 21a.743.743 0 001.49 0v-2.135a.247.247 0 01.33-.233l4.448 1.568a.525.525 0 00.165.028.515.515 0 00.165-.028l5.938-2.1a.5.5 0 00.018-.927l-5.938-2.358a.5.5 0 00-.365 0l-5.939 2.358a.5.5 0 00-.312.47z\"/><path d=\"M21 22.928a.5.5 0 00.33-.467v-2.123a.494.494 0 00-.659-.466l-3.146 1.11a.246.246 0 01-.165 0l-3.146-1.11a.494.494 0 00-.66.466v2.123a.5.5 0 00.33.467l2.546.9a3.046 3.046 0 002.024 0zM.619 18.062H2.41a.246.246 0 01.246.227l.444 5.257a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h.554a.248.248 0 00.248-.248v-.247a1.979 1.979 0 011.2-1.819l.134-.058a.247.247 0 00.15-.227V14.1a5.443 5.443 0 10-10.886 0v3.464a.494.494 0 00.495.498z\"/><path d=\"M1.361 4.206A4.206 4.206 0 105.567 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM11.01 21a.743.743 0 001.49 0v-2.135a.247.247 0 01.33-.233l4.448 1.568a.525.525 0 00.165.028.515.515 0 00.165-.028l5.938-2.1a.5.5 0 00.018-.927l-5.938-2.358a.5.5 0 00-.365 0l-5.939 2.358a.5.5 0 00-.312.47z\"/><path d=\"M21 22.928a.5.5 0 00.33-.467v-2.123a.494.494 0 00-.659-.466l-3.146 1.11a.246.246 0 01-.165 0l-3.146-1.11a.494.494 0 00-.66.466v2.123a.5.5 0 00.33.467l2.546.9a3.046 3.046 0 002.024 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});