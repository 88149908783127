define("ember-svg-jar/inlined/police-rotating-light-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-rotating-light-1</title><path d=\"M12 5.75a1 1 0 001-1v-3a1 1 0 00-2 0v3a1 1 0 001 1zM18.379 7.871a1 1 0 00.707-.293l2.121-2.121a1 1 0 10-1.414-1.414l-2.121 2.121a1 1 0 00.707 1.707zM23 11.75h-2.5a1 1 0 000 2H23a1 1 0 000-2zM4.914 7.578a1 1 0 101.414-1.414L4.207 4.043a1 1 0 00-1.414 1.414zM4.5 12.75a1 1 0 00-1-1H1a1 1 0 000 2h2.5a1 1 0 001-1zM17.327 17.25a.5.5 0 00.5-.543L17.2 9.463a2.5 2.5 0 00-2.483-2.213H9.283A2.5 2.5 0 006.8 9.478l-.623 7.229a.5.5 0 00.129.381.5.5 0 00.369.162zM17 18.75H7a2.5 2.5 0 00-2.5 2.5v.5a1.5 1.5 0 001.5 1.5h12a1.5 1.5 0 001.5-1.5v-.5a2.5 2.5 0 00-2.5-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});