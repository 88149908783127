define("ember-svg-jar/inlined/beer-opener-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beer-opener-1</title><path d=\"M5.772 15.063a.252.252 0 00-.354 0l-4.379 4.379a2.5 2.5 0 000 3.535 2.589 2.589 0 003.535 0L8.953 18.6a.25.25 0 000-.354zM22.959 5.3l-4.242-4.242a2.5 2.5 0 00-3.536 0L13.06 3.179a5.5 5.5 0 00-.744 6.835.242.242 0 01-.03.3l-2.761 2.764a1.493 1.493 0 00-1.061-.439 1.485 1.485 0 00-1.059.439s-.41.4-.536.536a.308.308 0 00.009.433l3.106 3.107a.327.327 0 00.428-.013l.528-.528a1.5 1.5 0 000-2.121l2.76-2.76a.243.243 0 01.3-.03 5.49 5.49 0 006.834-.745l2.121-2.121a2.5 2.5 0 00.004-3.536zM19.07 9.189l-.707.707a.5.5 0 01-.707 0l-.772-.772a.47.47 0 01-.105-.54 1 1 0 00-1.346-1.346.472.472 0 01-.54-.105l-.772-.772a.5.5 0 010-.708l.587-.587a3.114 3.114 0 014.245-.231 3 3 0 01.117 4.354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});