define("ember-svg-jar/inlined/goods-mark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>goods-mark</title><path d=\"M1.658 46.17l17-8.5a3 3 0 012.684 0l17 8.5A3 3 0 0140 48.855v21.292a3 3 0 01-1.658 2.683l-17 8.5a3 3 0 01-2.684 0l-17-8.5A3 3 0 010 70.146V48.854a3 3 0 011.658-2.683zm42 0l17-8.5a3 3 0 012.684 0l17 8.5A3 3 0 0182 48.855v21.292a3 3 0 01-1.658 2.683l-17 8.5a3 3 0 01-2.684 0l-17-8.5A3 3 0 0142 70.146V48.854a3 3 0 011.658-2.683zm-21-37l17-8.5a3 3 0 012.684 0l17 8.5A3 3 0 0161 11.855v21.292a3 3 0 01-1.658 2.683l-17 8.5a3 3 0 01-2.684 0l-17-8.5A3 3 0 0121 33.146V11.854a3 3 0 011.658-2.683z\" fill=\"#9271E4\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "82",
      "height": "82",
      "viewBox": "0 0 82 82",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});