define("ember-svg-jar/inlined/folder-stand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-stand</title><path d=\"M3.72 22h-2a1 1 0 100 2h2a1 1 0 000-2zM21.72 22h-2a1 1 0 000 2h2a1 1 0 000-2zM23.439 7.354a1.489 1.489 0 00-1.16-.55H10.418a.5.5 0 01-.489-.4L9.65 5.008A1.5 1.5 0 008.182 3.8H1.72A1.5 1.5 0 00.25 5.6l2.6 12.19A1.5 1.5 0 004.319 19h6.151a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25H6.72a1 1 0 100 2h10a1 1 0 000-2h-3.75a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h7.31a1.506 1.506 0 001.471-1.2c.2-.989.554-2.586.925-4.247.4-1.8.821-3.68 1.075-4.95a1.489 1.489 0 00-.312-1.249z\"/><path d=\"M3.22 3a1 1 0 00.98-.778A.263.263 0 014.443 2H19.22a.5.5 0 01.5.5V5a1 1 0 002 0V2a2 2 0 00-2-2H4.22a2 2 0 00-2 2 1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});