define("ember-svg-jar/inlined/content-typing-machine-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-typing-machine-1</title><path d=\"M23 12.5h-.5v-1.25a.75.75 0 00-.75-.75H19.5V6.414A1.986 1.986 0 0018.914 5L14.5.586A2.015 2.015 0 0013.086 0H6.5a2 2 0 00-2 2v8.5H2.25a.75.75 0 00-.75.75v1.25H1A.75.75 0 001 14h.5v1.5a.75.75 0 00.75.75h.65l-.757 6.062A1.5 1.5 0 003.633 24h16.734a1.5 1.5 0 001.488-1.686l-.7-5.564h.59A.75.75 0 0022.5 16v-2h.5a.75.75 0 000-1.5zm-5.75 8a1 1 0 11-1-1 1 1 0 011 1zm-4.25 0a1 1 0 11-1-1 1 1 0 011 1zm-4.25 0a1 1 0 11-1-1 1 1 0 011 1zm8.086-14.75H14.25V3.164zM6.75 2h5.75a.25.25 0 01.25.25V6.5a.75.75 0 00.75.75h3.75a.25.25 0 01.25.25V13h-.633a1.517 1.517 0 00-1.448 1.231A3.241 3.241 0 0112 16.5a3.241 3.241 0 01-3.419-2.269A1.573 1.573 0 007.133 13H6.5V2.25A.25.25 0 016.75 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});