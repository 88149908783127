define("ember-svg-jar/inlined/artboard-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>artboard-circle</title><path d=\"M24 18a1 1 0 00-1-1h-1.75a.25.25 0 01-.25-.25V4.5A1.5 1.5 0 0019.5 3H7.25A.25.25 0 017 2.75V1a1 1 0 00-1-1H4a1 1 0 00-1 1v1.75a.25.25 0 01-.25.25H1a1 1 0 00-1 1v2a1 1 0 001 1h1.75a.25.25 0 01.25.25V19.5A1.5 1.5 0 004.5 21h12.25a.25.25 0 01.25.25V23a1 1 0 001 1h2a1 1 0 001-1v-1.75a.25.25 0 01.25-.25H23a1 1 0 001-1zm-6.5-1a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5V7a.5.5 0 01.5-.5h10a.5.5 0 01.5.5z\"/><circle cx=\"12\" cy=\"12\" r=\"3.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});