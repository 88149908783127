define("ember-svg-jar/inlined/team-meeting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>team-meeting</title><path d=\"M15.927 18.543a1 1 0 00-1.3-.552 7.064 7.064 0 01-5.25 0 1 1 0 10-.752 1.853 9.034 9.034 0 006.752 0 1 1 0 00.55-1.301zM15.939 5.712A7 7 0 0119 11.5a7.279 7.279 0 01-.054.879 1 1 0 101.985.242A9.216 9.216 0 0021 11.5a9 9 0 00-3.934-7.44 1 1 0 00-1.127 1.652zM4.061 13.5a1 1 0 00.993-1.121A7.279 7.279 0 015 11.5a7 7 0 013.061-5.788A1 1 0 006.934 4.06 9 9 0 003 11.5a9.216 9.216 0 00.069 1.121 1 1 0 00.992.879zM15.674 9.5a.249.249 0 00.244-.3 4.062 4.062 0 00-7.937 0 .249.249 0 00.244.3z\"/><circle cx=\"12\" cy=\"2.499\" r=\"2.5\"/><path d=\"M19.976 20.5a4.069 4.069 0 00-3.969 3.2.249.249 0 00.244.3H23.7a.249.249 0 00.244-.3 4.069 4.069 0 00-3.968-3.2z\"/><circle cx=\"20.026\" cy=\"16.999\" r=\"2.5\"/><path d=\"M4.025 20.5a4.069 4.069 0 00-3.969 3.2.249.249 0 00.244.3h7.449a.249.249 0 00.244-.3 4.069 4.069 0 00-3.968-3.2z\"/><circle cx=\"4.075\" cy=\"16.999\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});