define("ember-svg-jar/inlined/arrow-double-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-double-right</title><path d=\"M23.306 11.281l-5.661-5.969A1.006 1.006 0 0016.919 5h-4.755a1 1 0 00-.725 1.688l4.845 5.11a.25.25 0 010 .343l-4.858 5.175A1 1 0 0012.155 19h4.763a1 1 0 00.729-.316l5.662-6.032a1 1 0 00-.003-1.371z\"/><path d=\"M12.571 11.281L6.91 5.312A1 1 0 006.185 5H1.431A1 1 0 00.7 6.688L5.55 11.8a.251.251 0 010 .343L.693 17.316A1 1 0 001.422 19h4.762a1 1 0 00.728-.315l5.663-6.033a1 1 0 00-.004-1.371z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});