define("ember-svg-jar/inlined/design-file-dxf-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-dxf-1</title><path d=\"M23.707 5.708L18.293.293A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.75V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.706z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zm-15.5-.875a.625.625 0 01-.625-.625v-6a.625.625 0 01.625-.625 3.625 3.625 0 010 7.25zm8.559-.9a.625.625 0 11-1.118.558s-.858-1.7-.879-1.759-.1-.043-.127.006l-.876 1.753a.625.625 0 11-1.118-.558l1.305-2.609a.252.252 0 000-.224l-1.305-2.613a.625.625 0 111.118-.558s.826 1.644.854 1.709a.1.1 0 00.177-.006l.851-1.7a.625.625 0 111.118.558l-1.3 2.609a.252.252 0 000 .224zm4.441-5.1h-2a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25H14.5a.625.625 0 010 1.25h-1.125a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-5a1.626 1.626 0 011.625-1.625h2a.625.625 0 010 1.25z\"/><path d=\"M3.456 12.319a.249.249 0 00-.331.24v3.882a.249.249 0 00.331.24A2.238 2.238 0 004.875 14.5a2.238 2.238 0 00-1.419-2.181z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});