define("ember-svg-jar/inlined/glasses-retro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>glasses-retro</title><path d=\"M20.609 1.435a1 1 0 000 2A1.393 1.393 0 0122 4.826v7.432a.251.251 0 01-.392.206 5.535 5.535 0 00-7.769 1.494.5.5 0 01-.625.177 2.9 2.9 0 00-2.428 0 .5.5 0 01-.625-.177 5.535 5.535 0 00-7.769-1.494.25.25 0 01-.392-.206V4.826a1.393 1.393 0 011.391-1.391 1 1 0 000-2A3.4 3.4 0 000 4.826v12.2a5.544 5.544 0 0011.087 0v-.24a.913.913 0 111.826 0v.24a5.544 5.544 0 0011.087 0v-12.2a3.4 3.4 0 00-3.391-3.391zM5.543 20.565a3.544 3.544 0 113.544-3.543 3.547 3.547 0 01-3.544 3.543zm12.914 0A3.544 3.544 0 1122 17.022a3.547 3.547 0 01-3.543 3.543z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});