define("ember-svg-jar/inlined/science-physics-law-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-physics-law_1</title><path d=\"M23.5 0H.5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h8a.5.5 0 01.5.5v.876a.5.5 0 01-.318.466A5.008 5.008 0 005.5 9.5v7a.5.5 0 01-.5.5h-.5a2 2 0 00-2 2v3a2 2 0 002 2h4a2 2 0 002-2v-3a2 2 0 00-2-2H8a.5.5 0 01-.5-.5v-2.53a.249.249 0 01.381-.212 5 5 0 007.185-2.221.252.252 0 01.206-.147.249.249 0 01.228.108l3.12 4.564a.5.5 0 01-.131.7l-.826.565a1 1 0 00.38 1.808l3.475.654a1.061 1.061 0 00.185.017 1 1 0 00.982-.816l.653-3.474a1 1 0 00-1.547-1.016l-.825.565a.5.5 0 01-.7-.131c-1.182-1.728-5.672-8.3-5.685-8.312a5.025 5.025 0 00-2.268-1.78.5.5 0 01-.313-.466V3.5a.5.5 0 01.5-.5h11a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5zM12.144 7.155a.249.249 0 01.265.034 3 3 0 11-3.818 0A.25.25 0 019 7.381V9a1.5 1.5 0 003 0V7.381a.249.249 0 01.144-.226z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});