define("ember-svg-jar/inlined/image-file-eps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-eps</title><path d=\"M12 13.625h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H12a.875.875 0 000-1.75zM17.5 6h3.9a.25.25 0 00.177-.427L16.427.427A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.25a.25.25 0 00-.25-.25zM5.875 15.375H7a.625.625 0 010 1.25H5.875a.25.25 0 00-.25.25V18a.375.375 0 00.375.375h2a.625.625 0 010 1.25H6A1.626 1.626 0 014.375 18v-4A1.626 1.626 0 016 12.375h2a.625.625 0 010 1.25H6a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25zm6 1.25h-.5a.25.25 0 00-.25.25V19a.625.625 0 01-1.25 0v-6a.625.625 0 01.625-.625H12a2.128 2.128 0 012.114 2.347 2.213 2.213 0 01-2.239 1.903zm4.552-1.758l1.84 1.227a1.928 1.928 0 01-1.07 3.532H15.5a.625.625 0 010-1.25h1.7a.678.678 0 00.376-1.242l-1.84-1.227a1.928 1.928 0 011.07-3.532h1.7a.625.625 0 010 1.25H16.8a.678.678 0 00-.376 1.242z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});