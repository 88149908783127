define("ember-svg-jar/inlined/shark-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shark-1</title><path d=\"M17.66 8.131A1.5 1.5 0 0116.526 7a5.512 5.512 0 00-3.212-3.852 1 1 0 00-1.372 1.119 5.324 5.324 0 01-.256 2.717 4.485 4.485 0 01-2.152 2.35A20.259 20.259 0 005.609 11.3c-.729-2-1.936-4.353-4.166-4.58a.986.986 0 00-.969.5 1 1 0 00.061 1.09 8.669 8.669 0 010 8.8 1 1 0 00.909 1.585c1.835-.186 3.234-1.5 4.165-3.9a23.868 23.868 0 007.157 2.165l.849.166a8.18 8.18 0 01-.13 2.561 1 1 0 001.367 1.167 5.5 5.5 0 003.127-3.654c3.36-.835 5.68-2.661 5.68-4.5 0-1.89-2.467-3.771-5.999-4.569zm-2.554 4.149l-1 2a.5.5 0 01-.895-.447l1-2a.5.5 0 11.895.447zm2.5 0l-1 2a.5.5 0 01-.9-.447l1-2a.5.5 0 11.9.447zm2.553 1.276a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});