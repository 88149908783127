define("ember-svg-jar/inlined/shopping-bag-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-bag-download</title><path d=\"M23.823 19.66L22.245 7.032v-.007a.766.766 0 00-.028-.124v-.01l-.005-.017-1.045-2.934a.744.744 0 00-.041-.091 1.729 1.729 0 00-1.526-.974h-1a.75.75 0 000 1.5h1a.234.234 0 01.172.126l.547 1.54a.251.251 0 01-.236.334h-2.731a.25.25 0 01-.25-.25v-1a5 5 0 00-10 0v1a.25.25 0 01-.25.25H4.01a.25.25 0 01-.231-.347l.633-1.494a.255.255 0 01.19-.159h1a.75.75 0 000-1.5h-1A1.752 1.752 0 003.054 3.9L1.811 6.83v.008a.604.604 0 00-.019.051v.014a.7.7 0 00-.027.127L.177 19.66A3.75 3.75 0 003.9 23.875h16.2a3.75 3.75 0 003.721-4.215zm-15.1-4.535h1.485a.25.25 0 00.25-.25V11.3a.294.294 0 01.294-.294H13.1a.294.294 0 01.294.294v3.573a.25.25 0 00.25.25h1.485a.294.294 0 01.228.479l-3.2 3.946a.3.3 0 01-.457 0L8.494 15.6a.294.294 0 01.228-.475zm.38-10a3 3 0 016 0v1a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});