define("ember-svg-jar/inlined/style-three-pin-restaurant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-restaurant</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm.5 8.75a3.248 3.248 0 01-2.167 3.056.5.5 0 00-.333.471v3.473a.75.75 0 01-1.5 0v-3.473a.5.5 0 00-.333-.471A3.248 3.248 0 016 8.75v-3a.75.75 0 011.5 0V9a.5.5 0 001 0V5.75a.75.75 0 011.5 0V9a.5.5 0 001 0V5.75a.75.75 0 011.5 0zm4.75 5h-.75a.5.5 0 00-.5.5v1.5a.75.75 0 01-1.5 0v-10a.749.749 0 011.084-.671C17.813 6.188 18 9.749 18 13a.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});