define("ember-svg-jar/inlined/move-left-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>move-left-right</title><path d=\"M19.455 9.613a1.25 1.25 0 00-1.25-1.25H5.336a.249.249 0 01-.169-.433l3.385-3.125a1.25 1.25 0 00-1.695-1.838L.652 8.694a1.25 1.25 0 00.848 2.169h16.7a1.25 1.25 0 001.255-1.25zM15.377 20.961a1.25 1.25 0 001.766.071l6.2-5.727a1.25 1.25 0 00-.848-2.169H5.8a1.25 1.25 0 100 2.5h12.864a.249.249 0 01.169.433l-3.385 3.125a1.25 1.25 0 00-.071 1.767z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});