define("ember-svg-jar/inlined/family-child-hold-hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-child-hold-hand</title><path d=\"M6.1.379A.5.5 0 005.61 0H.5a.5.5 0 00-.5.5v22a1.5 1.5 0 003 0v-8a.5.5 0 011 0v8a1.5 1.5 0 003 0V14h1.86a.5.5 0 00.485-.621zM11.5 6.25a2.287 2.287 0 01.332.025.25.25 0 00.278-.311c-.3-1.146-1.2-4.476-1.5-5.594a.5.5 0 00-.477-.37H7.641a.5.5 0 00-.485.621l1.608 6.424a1.744 1.744 0 00.1.274.251.251 0 00.2.144.248.248 0 00.226-.1 1.63 1.63 0 01.1-.123 2.742 2.742 0 012.11-.99zM24 16a4 4 0 00-4-4h-3.323L12.3 8.04a1.25 1.25 0 10-1.6 1.92l3.8 3.44v9.35a1.25 1.25 0 002.5 0V18.5a.5.5 0 011 0v4.25a1.25 1.25 0 002.5 0V16a.5.5 0 011 0v2.75a1.25 1.25 0 002.5 0z\"/><path d=\"M16.958 10.232A2.225 2.225 0 0018 10.5a2.281 2.281 0 10-1.042-.268z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});