define("ember-svg-jar/inlined/style-three-pin-cicyling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-cicyling</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM7 16a3 3 0 113-3 3 3 0 01-3 3zm6-2.5a.75.75 0 01-1.5 0v-1.955a.25.25 0 00-.115-.211L8.56 9.512a1.7 1.7 0 01-.822-1.105A1.746 1.746 0 018.015 7a6.551 6.551 0 013.742-2.191A.316.316 0 0012 4.5a2 2 0 112 2 2.057 2.057 0 01-1.009-.273.5.5 0 00-.333-.06c-.221.038-.576.1-.768.155a.5.5 0 00-.188.865A3.543 3.543 0 0014 7.75a.75.75 0 010 1.5c-2.194 0-3.28-.7-3.8-1.521a.5.5 0 00-.79-.069 2.38 2.38 0 00-.171.21.239.239 0 00.081.349l2.88 1.855a1.745 1.745 0 01.8 1.471zm4 2.5a3 3 0 113-3 3 3 0 01-3 3z\"/><circle cx=\"17\" cy=\"13\" r=\"2\"/><circle cx=\"7\" cy=\"13\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});