define("ember-svg-jar/inlined/shopping-basket-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-basket-3</title><path d=\"M21.5 14.125h-19a.5.5 0 00-.49.6l1.281 6.4a2.355 2.355 0 002.475 1.5h12.468a2.355 2.355 0 002.475-1.5l1.281-6.4a.5.5 0 00-.49-.6zM7.394 15.883a.746.746 0 01.848.637l.5 3.5a.748.748 0 01-.636.847.651.651 0 01-.106.008.75.75 0 01-.741-.645l-.5-3.5a.748.748 0 01.635-.847zm3.856 4.242v-3.5a.75.75 0 011.5 0v3.5a.75.75 0 01-1.5 0zm5.992-3.394l-.5 3.5a.75.75 0 01-.741.645.651.651 0 01-.107-.008.748.748 0 01-.636-.847l.5-3.5a.749.749 0 111.484.211zM22 8.625h-.828a.04.04 0 01-.037-.023l-3-6.5a1.25 1.25 0 00-2.27 1.046l2.364 5.122a.25.25 0 01-.229.355H6a.25.25 0 01-.227-.355l2.362-5.122A1.25 1.25 0 105.865 2.1l-3 6.5a.04.04 0 01-.037.023H2a2 2 0 000 4h20a2 2 0 000-4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});