define("ember-svg-jar/inlined/diagram-dash-down-fast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-dash-down-fast</title><path d=\"M3.045 7.6A1.44 1.44 0 00.366 8.655 5.3 5.3 0 001.9 10.772a1.44 1.44 0 001.841-2.211 2.4 2.4 0 01-.696-.961zM16.124 9.128a5.354 5.354 0 00-.284-.008h-1.714a1.44 1.44 0 000 2.88h1.921a1.44 1.44 0 00.077-2.878zM19.68 16.732a1.44 1.44 0 001.44-1.44V14.4a5.275 5.275 0 00-.187-1.4 1.44 1.44 0 00-2.777.761 2.412 2.412 0 01.084.635v.892a1.44 1.44 0 001.44 1.444zM9.326 9.12h-1.92a1.44 1.44 0 000 2.88h1.92a1.44 1.44 0 000-2.88zM1.44 0A1.44 1.44 0 000 1.44v1.92a1.44 1.44 0 002.88 0V1.44A1.44 1.44 0 001.44 0zM23.89 19.129a1.438 1.438 0 00-1.33-.889H16.8a1.44 1.44 0 00-1.018 2.46l2.88 2.88a1.439 1.439 0 002.036 0l2.88-2.88a1.439 1.439 0 00.312-1.571z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});