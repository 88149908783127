define("ember-svg-jar/inlined/smiley-lol", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-lol</title><path d=\"M24 12A12 12 0 10.34 14.839a.246.246 0 01-.009.147A4.493 4.493 0 000 16.5a4.007 4.007 0 003.427 3.959.247.247 0 01.139.07 11.982 11.982 0 0016.868 0 .25.25 0 01.139-.07A4.007 4.007 0 0024 16.5a4.493 4.493 0 00-.331-1.514.246.246 0 01-.009-.147A11.974 11.974 0 0024 12zM2 16.5c0-.883 1.279-2.912 1.791-3.689a.251.251 0 01.418 0C4.721 13.589 6 15.617 6 16.5a2 2 0 01-4 0zm14.561-2a.439.439 0 01.38.658 5.705 5.705 0 01-9.882 0 .439.439 0 01.379-.658zM7.291 7a3.185 3.185 0 012.935 2.145 1 1 0 01-1.87.71A1.207 1.207 0 007.291 9a1.207 1.207 0 00-1.065.856 1 1 0 01-1.87-.712A3.188 3.188 0 017.291 7zm6.483 2.145a3.081 3.081 0 015.87 0 1 1 0 11-1.87.712A1.208 1.208 0 0016.708 9a1.206 1.206 0 00-1.064.855 1 1 0 11-1.87-.71zM20 18.5a2 2 0 01-2-2c0-.883 1.279-2.911 1.791-3.689a.251.251 0 01.418 0C20.721 13.588 22 15.617 22 16.5a2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});