define("ember-svg-jar/inlined/smiley-kiss-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-kiss-heart</title><path d=\"M23.488 15.733a.253.253 0 01-.021-.2 11.993 11.993 0 10-8.279 8.033.25.25 0 01.215.04 2.031 2.031 0 00.625.31 2 2 0 001.559-.187l4.305-2.477a4.048 4.048 0 001.591-5.521zm-6.672 6.137a.251.251 0 01-.346-.1l-2.192-4.212a2.052 2.052 0 013.6-1.969l.265.484a.248.248 0 00.149.12.252.252 0 00.19-.021l.483-.265a2.052 2.052 0 011.973 3.6zM9.6 16.233l1.648 1.535a1 1 0 01-1.363 1.464l-1.6-1.491-.017-.014a2 2 0 01.332-3.359l.021-.011 1.922-1.206a1 1 0 011.059 1.7l-1.932 1.2a.16.16 0 00-.072.111c-.008.028-.014.056.002.071zM7.5 7a2 2 0 11-2 2 2 2 0 012-2zm6 2.1a3.5 3.5 0 016.415 0 1 1 0 01-.516 1.316.976.976 0 01-.4.084 1 1 0 01-.917-.6 1.5 1.5 0 00-2.751 0 1 1 0 11-1.831-.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});