define("ember-svg-jar/inlined/database-warning-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-warning-alternate</title><path d=\"M.742 4.958v12.615c0 2.545 3.349 4.16 7.242 4.727a1.013 1.013 0 001.105-.687L9.1 21.6a.979.979 0 00-.814-1.265c-3.45-.488-5.539-1.795-5.539-2.758v-.759a.249.249 0 01.372-.218 16.619 16.619 0 007.038 1.7 1 1 0 00.919-.536 1 1 0 00-.858-1.457c-4.767-.189-7.471-1.885-7.471-2.937v-.759a.249.249 0 01.372-.218 17.436 17.436 0 008.027 1.729c.44 0 .882-.013 1.323-.039a1 1 0 00.82-.538.993.993 0 00-.936-1.457q-.584.033-1.207.034c-5.127 0-8.4-1.751-8.4-2.957V8.4a.251.251 0 01.123-.216.254.254 0 01.249 0 17.428 17.428 0 008.028 1.73 17.442 17.442 0 008.027-1.729.249.249 0 01.248 0 .247.247 0 01.124.215v.759c0 .37-.313.792-.89 1.2a.252.252 0 00-.011.411c.585.441.856 1.074 2.425 4.089a.252.252 0 00.476-.109V4.958C21.542 1.737 16.184 0 11.142 0S.755 1.734.744 4.948zM11.142 2c5.124 0 8.4 1.749 8.4 2.955v.009c-.011 1.206-3.28 2.951-8.4 2.951s-8.4-1.751-8.4-2.957S6.015 2 11.142 2z\"/><path d=\"M18.1 12.4a1.434 1.434 0 00-2.535 0l-5 9.531a1.415 1.415 0 00.047 1.393 1.441 1.441 0 001.22.681h10a1.439 1.439 0 001.219-.681 1.411 1.411 0 00.048-1.392zm-2.257 8.883a.99.99 0 11.989.99.989.989 0 01-.993-.99zm.247-5.689a.742.742 0 111.484 0v2.969a.742.742 0 01-1.484 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});