define("ember-svg-jar/inlined/wifi-laptop-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-laptop-1</title><path d=\"M20.5 16a1 1 0 001-1V8a1 1 0 00-2 0v7a1 1 0 001 1zM3.5 7a1 1 0 00-1 1v7a1 1 0 002 0V8a1 1 0 00-1-1zM23.5 17.5H16a.5.5 0 00-.5.5c0 .708-1.5 1.5-3.5 1.5s-3.5-.8-3.5-1.5a.5.5 0 00-.5-.5H.5a.5.5 0 00-.474.658A7.075 7.075 0 006.74 23h10.52a7.075 7.075 0 006.714-4.842.5.5 0 00-.474-.658zM10.5 11A1.5 1.5 0 1012 9.5a1.5 1.5 0 00-1.5 1.5zM12 5a5.96 5.96 0 00-4.243 1.755 1 1 0 101.414 1.414 4.1 4.1 0 015.658 0 1 1 0 001.414-1.414A5.96 5.96 0 0012 5z\"/><path d=\"M17.657 5.34a1 1 0 001.414-1.414 10.013 10.013 0 00-14.142 0A1 1 0 006.343 5.34a8.01 8.01 0 0111.314 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});