define("ember-svg-jar/inlined/tools-pickaxe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-pickaxe</title><path d=\"M20.048 5.719a1 1 0 000-1.415l-.348-.353a1 1 0 00-1.414 0l-.122.122a.5.5 0 01-.671.032A17.4 17.4 0 007.013.126 1.009 1.009 0 006 .888a.989.989 0 00.54 1.136 32.528 32.528 0 016.582 4.186.5.5 0 01.037.741l-.183.183a1 1 0 000 1.413l2.474 2.475a1 1 0 001.414 0l.183-.182a.5.5 0 01.741.036 32.55 32.55 0 014.186 6.583.99.99 0 00.9.572 1.051 1.051 0 00.238-.031 1 1 0 00.762-1.016 17.414 17.414 0 00-3.98-10.475.5.5 0 01.032-.671zM12.8 10.137a.75.75 0 00-1.06 0L.491 21.387a1.252 1.252 0 000 1.768l.353.354a1.251 1.251 0 001.768 0l11.249-11.25a.752.752 0 000-1.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});