define("ember-svg-jar/inlined/amphibian-frog-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>amphibian-frog-1</title><path d=\"M23.25 14.949a4.265 4.265 0 00-1.442-3.213 9.564 9.564 0 00-.938-3.422 4.237 4.237 0 10-6.622-5.285 9.893 9.893 0 00-4.5 0A4.238 4.238 0 103.12 8.313a9.464 9.464 0 00-.93 3.422 4.189 4.189 0 00-1.44 3.214c0 3.341 3.755 7.682 11.25 7.682 7.543 0 11.25-4.349 11.25-7.682zM9.262 5.9a2.491 2.491 0 11-2.491-2.491A2.491 2.491 0 019.262 5.9zm1.484 7.517a.75.75 0 01-1.5.012c-.012-.262-.139-.262-.25-.262a.75.75 0 010-1.5 1.7 1.7 0 011.75 1.75zm6.543-5.026A2.491 2.491 0 1119.78 5.9a2.491 2.491 0 01-2.491 2.491zM15 11.667a.75.75 0 010 1.5c-.112 0-.238 0-.25.265a.76.76 0 01-.75.727h-.02a.742.742 0 01-.734-.741A1.7 1.7 0 0115 11.667zm-3 8.964a11.5 11.5 0 01-7.6-2.525.5.5 0 01.44-.85 35.031 35.031 0 007.159.7 34.98 34.98 0 007.162-.7.5.5 0 01.44.85A11.5 11.5 0 0112 20.631z\"/><circle cx=\"6.771\" cy=\"5.9\" r=\"1.25\"/><circle cx=\"17.271\" cy=\"5.9\" r=\"1.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});