define("ember-svg-jar/inlined/audio-file-aac-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file-aac-1</title><path d=\"M23.707 5.707L18.293.293A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.75V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707z\"/><path d=\"M9 12.125a.877.877 0 00-.875.875v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V13A.877.877 0 009 12.125zM4 12.125a.877.877 0 00-.875.875v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V13A.877.877 0 004 12.125z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zM6.125 17.5a.625.625 0 01-1.25 0v-2.125a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-4.375a2.211 2.211 0 011.9-2.238A2.127 2.127 0 016.125 13zm5 0a.625.625 0 01-1.25 0v-2.125a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-4.375a2.211 2.211 0 011.9-2.238A2.127 2.127 0 0111.125 13zm4.375-.625a.625.625 0 010 1.25 3.625 3.625 0 010-7.25.625.625 0 010 1.25 2.375 2.375 0 000 4.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});