define("ember-svg-jar/inlined/perspective-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>perspective-grid</title><path d=\"M22 0H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zM6.708 22a.25.25 0 01-.2-.4 16.05 16.05 0 002.747-6.01.251.251 0 01.29-.191 6.664 6.664 0 012.094.757 8.662 8.662 0 003.284 1.079.25.25 0 01.2.346 14.249 14.249 0 01-2.885 4.343.253.253 0 01-.18.076zM2 11.112a.25.25 0 01.08-.183L3.734 9.4a6.682 6.682 0 013.054-1.6.25.25 0 01.3.174A14.408 14.408 0 017.652 12c0 .369-.018.735-.047 1.1a.249.249 0 01-.223.229 8.693 8.693 0 00-5 2.257.225.225 0 01-.382-.167zM12.06 2a.253.253 0 01.18.076 14.223 14.223 0 013.218 5.187.252.252 0 01-.041.239.248.248 0 01-.223.092 6.645 6.645 0 01-2.563-.826 8.692 8.692 0 00-4.1-1.137.247.247 0 01-.222-.15A16.026 16.026 0 006.508 2.4a.25.25 0 01.2-.4zm.571 12.42a8.664 8.664 0 00-2.823-1 .249.249 0 01-.206-.265c.029-.382.05-.766.05-1.154a16.318 16.318 0 00-.5-3.963.249.249 0 01.283-.308 6.651 6.651 0 012.2.775 8.68 8.68 0 004.2 1.138.25.25 0 01.244.212A14.131 14.131 0 0116.261 12a14.329 14.329 0 01-.348 3.1.251.251 0 01-.255.2 6.7 6.7 0 01-3.027-.88zm5.439-4.8a.251.251 0 01.182-.278A8.678 8.678 0 0021.589 7.6a.249.249 0 01.411.188v4.172a.251.251 0 01-.073.177l-1.2 1.2a6.649 6.649 0 01-2.344 1.516.249.249 0 01-.336-.273 16.187 16.187 0 00.214-2.58 16.092 16.092 0 00-.191-2.383zM22 4.308a.251.251 0 01-.073.177l-1.2 1.2a6.645 6.645 0 01-2.885 1.683.249.249 0 01-.308-.167 16.039 16.039 0 00-2.42-4.8.25.25 0 01.2-.4H21.5a.5.5 0 01.5.5zM3.451 2a.253.253 0 01.18.076 14.24 14.24 0 012.5 3.515.251.251 0 01-.156.353 8.688 8.688 0 00-3.598 1.988.223.223 0 01-.242.041A.225.225 0 012 7.767V2.5a.5.5 0 01.5-.5zM2 18.765a.25.25 0 01.08-.184l1.654-1.527a6.68 6.68 0 013.115-1.615.249.249 0 01.295.309 14.158 14.158 0 01-3.513 6.176.254.254 0 01-.18.076H2.5a.5.5 0 01-.5-.5zM15.317 22a.25.25 0 01-.2-.4 16.032 16.032 0 002.253-4.289.251.251 0 01.191-.165 8.708 8.708 0 004.031-1.9.249.249 0 01.266-.036.251.251 0 01.142.23v6.06a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});