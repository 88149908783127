define("ember-svg-jar/inlined/layout-corners-dashboard-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layout-corners-dashboard-1</title><circle cx=\"5\" cy=\"5\" r=\"5\"/><circle cx=\"19\" cy=\"5\" r=\"5\"/><circle cx=\"5\" cy=\"19\" r=\"5\"/><circle cx=\"19\" cy=\"19\" r=\"5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});