define("ember-svg-jar/inlined/single-neutral-news", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-news</title><path d=\"M24 2.5A1.5 1.5 0 0022.5 1h-21A1.5 1.5 0 000 2.5V17a1.5 1.5 0 001.5 1.5h9a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5H4a1 1 0 000 2h16a1 1 0 000-2h-6.5a.5.5 0 01-.5-.5V19a.5.5 0 01.5-.5h9A1.5 1.5 0 0024 17zm-15 7a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5zm12.4 6.088a.5.5 0 01-.384.18h-11a.5.5 0 01-.492-.591 5.326 5.326 0 01.493-1.477c.453-.876 1.59-1.415 3.667-2.159a1.009 1.009 0 00-.007-.71 4.978 4.978 0 01-1.13-3.511 2.881 2.881 0 012.969-3.052 2.88 2.88 0 012.968 3.052 5 5 0 01-1.148 3.534.984.984 0 00.026.7c2.062.735 3.2 1.274 3.653 2.151a5.34 5.34 0 01.492 1.476.5.5 0 01-.107.407z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});