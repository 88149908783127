define("ember-svg-jar/inlined/focus-auto-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>focus-auto-1</title><path d=\"M12 5.75a3.5 3.5 0 00-3.5 3.5v8a1 1 0 002 0v-3.5a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25v3.5a1 1 0 002 0v-8a3.5 3.5 0 00-3.5-3.5zm-1.25 5.75a.25.25 0 01-.25-.25v-2a1.5 1.5 0 013 0v2a.25.25 0 01-.25.25zM5 2.5a1 1 0 00-1-1H2a2 2 0 00-2 2v2a1 1 0 002 0V4a.5.5 0 01.5-.5H4a1 1 0 001-1zM4 20.5H2.5A.5.5 0 012 20v-1.5a1 1 0 00-2 0v2a2 2 0 002 2h2a1 1 0 000-2zM22 1.5h-2a1 1 0 000 2h1.5a.5.5 0 01.5.5v1.5a1 1 0 002 0v-2a2 2 0 00-2-2zM23 17.5a1 1 0 00-1 1V20a.5.5 0 01-.5.5H20a1 1 0 000 2h2a2 2 0 002-2v-2a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});