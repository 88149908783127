define("ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/cards/image", ["exports", "ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/render-type"], function (_exports, _renderType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'image',
    type: _renderType.default,
    render({
      payload,
      env: {
        dom
      }
    }) {
      let img = dom.createElement('img');
      img.src = payload.src;
      return img;
    }
  };
});