define("ember-svg-jar/inlined/plane-ticket-return", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-ticket-return</title><path d=\"M10.276 13.256a.251.251 0 01-.271.011l-1.071-.635a.482.482 0 00-.469.087l-1.023.9a.949.949 0 00-.086.082.474.474 0 00-.1.353.485.485 0 00.189.332l2.475 1.743a.666.666 0 00.384.122.644.644 0 00.35-.1l6.763-4.2a1.56 1.56 0 00.583-1.69 1.443 1.443 0 00-2.1-.721l-1.784 1.217a.25.25 0 01-.228.028L9.811 9.268a.531.531 0 00-.505.071l-.771.57a1.548 1.548 0 00-.083.079.528.528 0 00-.126.39.549.549 0 00.217.388s2.534 1.558 2.634 1.63.13.141.037.206z\"/><path d=\"M3.854 12.354l2.5-2.5A.5.5 0 006 9H4.8a.139.139 0 01-.14-.187c2.023-4.62 8.3-6.877 13.326-2.343a5.945 5.945 0 011.539 2.818 1 1 0 101.9-.618 10 10 0 00-18.919.163.244.244 0 01-.223.167H1a.5.5 0 00-.354.854l2.5 2.5a.607.607 0 00.708 0zM23.354 14.146l-2.5-2.5a.5.5 0 00-.708 0l-2.5 2.5A.5.5 0 0018 15h1.187c.17 0 .178.122.152.182a8.071 8.071 0 01-10.72 4.127 8.284 8.284 0 01-4.188-4.7 1 1 0 00-1.861.73 10 10 0 0018.909-.169.243.243 0 01.231-.17H23a.5.5 0 00.354-.854z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});