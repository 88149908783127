define("ember-svg-jar/inlined/human-resources-offer-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-offer-woman</title><path d=\"M1 14.75a1 1 0 00-1 1V23a1 1 0 002 0v-7.25a1 1 0 00-1-1zM15.5 19.5H6.375a.625.625 0 010-1.25H8.9a1 1 0 00.92-1.394A2.233 2.233 0 007.764 15.5H3.5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.764a2.239 2.239 0 002.236-2.237V20.5a1 1 0 00-1-1zM23.926 13.4a.317.317 0 00.073-.23 6.4 6.4 0 00-.549-2.107c-.4-.846-2.068-1.432-4.163-2.253a.6.6 0 01-.37-.563v-.534a.018.018 0 01.018-.018A4.479 4.479 0 0021.832 7a.3.3 0 00.131-.213.319.319 0 00-.071-.244c-.357-.414-.6-.914-.709-2.558C21 1.038 19.115 0 17.5 0s-3.494 1.038-3.683 3.989c-.105 1.644-.352 2.144-.709 2.558a.319.319 0 00-.071.244.3.3 0 00.132.209 4.311 4.311 0 002.662.7.251.251 0 01.178.073.247.247 0 01.074.177v.3a.6.6 0 01-.37.563c-2.095.821-3.764 1.407-4.163 2.253a6.4 6.4 0 00-.55 2.106.313.313 0 00.073.23.279.279 0 00.21.1h12.433a.279.279 0 00.21-.102z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});