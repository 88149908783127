define("ember-svg-jar/inlined/recycling-trash-bin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>recycling-trash-bin</title><path d=\"M3.5 6h2.342a.25.25 0 00.248-.219l.383-3.062a.25.25 0 01.248-.219h10.558a.25.25 0 01.248.219l.383 3.062a.25.25 0 00.248.219H20.5a.5.5 0 00.49-.6l-.838-4.14A1.518 1.518 0 0018.68 0H5.32a1.516 1.516 0 00-1.471 1.253L3.01 5.4a.5.5 0 00.49.6zM21.5 7h-19a1.5 1.5 0 000 3h.91l1.209 12.658A1.486 1.486 0 006.112 24h11.707a1.5 1.5 0 001.488-1.3L20.925 10h.575a1.5 1.5 0 000-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});