define("ember-svg-jar/inlined/task-list-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-list-sync</title><path d=\"M6 7.749a.75.75 0 000 1.5h6a.75.75 0 000-1.5z\"/><path d=\"M9.25 19H3.5a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v4.08a.246.246 0 00.3.245 17.768 17.768 0 012.468-.281.249.249 0 00.232-.25V3.5A1.5 1.5 0 0016.5 2h-4a.241.241 0 01-.208-.121 3.827 3.827 0 00-6.588 0A.241.241 0 015.5 2h-4A1.5 1.5 0 000 3.5v17A1.5 1.5 0 001.5 22h7.75a.25.25 0 00.25-.25v-2.5a.25.25 0 00-.25-.25z\"/><path d=\"M12.2 11.516c.057-.052.015-.267-.2-.267H6a.75.75 0 000 1.5h4.958a.242.242 0 00.192-.1 8.081 8.081 0 011.05-1.133zM9.776 14.749H6a.75.75 0 100 1.5h3.4a.241.241 0 00.238-.2 7.964 7.964 0 01.283-1.093.147.147 0 00-.145-.207zM22.77 18.6a1 1 0 00-1.256.582 4.048 4.048 0 01-1.278 1.931 4.164 4.164 0 01-5.656-.472.255.255 0 01.014-.349l1.717-1.716a.5.5 0 00-.354-.854H11.5a.5.5 0 00-.5.5v4.457a.5.5 0 00.854.354l.974-.975a.244.244 0 01.353.009 6.086 6.086 0 0010.211-2.2 1 1 0 00-.622-1.267zM23.691 11.772a.5.5 0 00-.545.108l-1.03 1.031a.244.244 0 01-.352-.008 6.1 6.1 0 00-10.2 2.226 1 1 0 001.879.684 4.032 4.032 0 011.274-1.913 4.154 4.154 0 015.639.434.256.256 0 01-.011.35l-1.655 1.653a.5.5 0 00.353.854H23.5a.5.5 0 00.5-.5v-4.457a.5.5 0 00-.309-.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});