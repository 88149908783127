define("ember-svg-jar/inlined/body-care-water-hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>body-care-water-hand</title><rect y=\"13.344\" width=\"4.001\" height=\"9\" rx=\"1\" ry=\"1\"/><path d=\"M23.854 17.553a3.243 3.243 0 00-3.492-1.127h-.014l-2 .64a.5.5 0 00-.348.484 2.25 2.25 0 01-2.248 2.29H10.5a.5.5 0 110-1h5.25a1.25 1.25 0 100-2.5l-3.685.008a4.17 4.17 0 01-.573-.383A6.839 6.839 0 007.5 14.344h-2a.5.5 0 00-.5.5v5.9a.5.5 0 00.339.474c.705.239 1.341.46 1.917.66 2.623.911 4.087 1.409 5.418 1.409 1.623 0 3.048-.742 6.139-2.385 1.281-.682 2.875-1.53 4.91-2.547a.5.5 0 00.13-.8zM16 14.344a4.89 4.89 0 005-5c0-2.127-2.625-6.077-4.189-8.242a1.04 1.04 0 00-1.621 0C13.625 3.267 11 7.217 11 9.344a4.89 4.89 0 005 5zm-2.75-5.5a.5.5 0 01.5.5 2.253 2.253 0 002.25 2.25.5.5 0 010 1 3.254 3.254 0 01-3.25-3.25.5.5 0 01.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});