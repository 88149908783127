define("ember-svg-jar/inlined/single-woman-actions-mobilephone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-mobilephone</title><path d=\"M11.9 18.359a.5.5 0 00.084-.431c-1.964-7.161-1.879-7.545-3.007-8.6a.494.494 0 00-.756.1l-1.738 2.73a.495.495 0 01-.837 0L3.924 9.41a.5.5 0 00-.755-.1C2.016 10.371 2.1 10.779.143 17.928a.494.494 0 00.478.625h2.3a.249.249 0 01.243.2l.92 4.847a.5.5 0 00.484.4h2.969a.5.5 0 00.484-.393l.921-4.848a.248.248 0 01.243-.2h2.32a.5.5 0 00.395-.2zM6.042 8.411a4.206 4.206 0 10-4.206-4.206 4.211 4.211 0 004.206 4.206zm0-1.979a2.224 2.224 0 01-2.137-1.623.248.248 0 01.158-.3 4.611 4.611 0 001.8-1.128.247.247 0 01.353 0 4.632 4.632 0 001.8 1.129.246.246 0 01.158.3 2.223 2.223 0 01-2.132 1.622zM22.416 11.136h-5.989a1.478 1.478 0 00-1.458 1.493v9.878A1.478 1.478 0 0016.427 24h5.989a1.478 1.478 0 001.458-1.493v-9.878a1.478 1.478 0 00-1.458-1.493zm-5.468 10.39V13.61a.5.5 0 01.494-.495H21.4a.5.5 0 01.5.495v7.916a.5.5 0 01-.5.5h-3.958a.5.5 0 01-.494-.5z\"/><path d=\"M11.9 18.359a.5.5 0 00.084-.431c-1.964-7.161-1.879-7.545-3.007-8.6a.494.494 0 00-.756.1l-1.738 2.73a.495.495 0 01-.837 0L3.924 9.41a.5.5 0 00-.755-.1C2.016 10.371 2.1 10.779.143 17.928a.494.494 0 00.478.625h2.3a.249.249 0 01.243.2l.92 4.847a.5.5 0 00.484.4h2.969a.5.5 0 00.484-.393l.921-4.848a.248.248 0 01.243-.2h2.32a.5.5 0 00.395-.2zM6.042 8.411a4.206 4.206 0 10-4.206-4.206 4.211 4.211 0 004.206 4.206zm0-1.979a2.224 2.224 0 01-2.137-1.623.248.248 0 01.158-.3 4.611 4.611 0 001.8-1.128.247.247 0 01.353 0 4.632 4.632 0 001.8 1.129.246.246 0 01.158.3 2.223 2.223 0 01-2.132 1.622zM22.416 11.136h-5.989a1.478 1.478 0 00-1.458 1.493v9.878A1.478 1.478 0 0016.427 24h5.989a1.478 1.478 0 001.458-1.493v-9.878a1.478 1.478 0 00-1.458-1.493zm-5.468 10.39V13.61a.5.5 0 01.494-.495H21.4a.5.5 0 01.5.495v7.916a.5.5 0 01-.5.5h-3.958a.5.5 0 01-.494-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});