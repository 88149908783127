define("ember-svg-jar/inlined/deer-body-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>deer-body-1</title><path d=\"M18.25 4H21a3 3 0 003-3 1 1 0 00-2 0 1 1 0 01-1 1h-2.75a3.984 3.984 0 00-2.643 1h-.357a2 2 0 01-2-2 1 1 0 00-2 0 4.006 4.006 0 003.15 3.909 3.992 3.992 0 00-.12.591h-3.03a.5.5 0 00-.5.5 2.824 2.824 0 002.479 2.953c-.252 2.605-2.69 3.547-4.979 3.547H4a4 4 0 00-.889 7.9.5.5 0 01.389.487V23a1 1 0 002 0v-2a.5.5 0 01.5-.5h3.485A.5.5 0 0110 21v2a1 1 0 002 0v-2.625a.5.5 0 01.309-.462c1.934-.8 3.941-2.654 3.941-6.913a1.344 1.344 0 011.5-1.5h2c1.428 0 1.5-2.5 1.5-3a.5.5 0 00-.209-.407l-3.5-2.5a.5.5 0 00-.291-.093h-.937A2 2 0 0118.25 4zm-1.5 3a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});