define("ember-svg-jar/inlined/monetization-tablet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monetization-tablet</title><circle cx=\"9.045\" cy=\"7.125\" r=\"1.625\"/><path d=\"M6.369 11.445a.408.408 0 00.382.555h4.589a.408.408 0 00.382-.555 2.921 2.921 0 00-2.677-1.883 2.921 2.921 0 00-2.676 1.883z\"/><path d=\"M13.933 11.8l1.5 1.2a.251.251 0 00.407-.194V2.5a2.5 2.5 0 00-2.5-2.5h-9a2.5 2.5 0 00-2.5 2.5v19a2.5 2.5 0 002.5 2.5h9a2.489 2.489 0 00.771-.122.249.249 0 00.149-.343 4.033 4.033 0 01-.42-1.645V20a.251.251 0 00-.063-.165l-.661-.747a.251.251 0 00-.188-.088H4.34a.5.5 0 01-.5-.5v-14a.5.5 0 01.5-.5h9a.5.5 0 01.5.5v7.1a.249.249 0 00.093.2zM8.84 20.5a1 1 0 11-1 1 1 1 0 011-1z\"/><path d=\"M18.363 15.347a.751.751 0 01-.469 1.336.741.741 0 01-.468-.165l-4.1-3.289a1.376 1.376 0 00-1.579.261 1.354 1.354 0 00-.214.279 1.375 1.375 0 00.214 1.666l3.666 4.142a.249.249 0 01.063.166v2.544a2.63 2.63 0 00.306 1.071.251.251 0 00.218.142h5.91a.25.25 0 00.207-.391L20.679 21a.253.253 0 01-.044-.141V15.03c0-1.478-1.453-2.921-2.888-4.077a.252.252 0 00-.265-.031.25.25 0 00-.142.225v3.259a.251.251 0 00.093.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});