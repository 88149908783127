define("ember-svg-jar/inlined/hierarchy.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hierarchy</title><path d=\"M22.13 18.233a.248.248 0 01-.155-.214A7 7 0 0015 11.5h-1.75a.25.25 0 01-.25-.25V5.986a.251.251 0 01.153-.231 3 3 0 10-2.306 0 .251.251 0 01.153.231v5.263a.25.25 0 01-.25.25H8.5a6.557 6.557 0 00-6.478 6.519.252.252 0 01-.155.217 3 3 0 102.32.021.252.252 0 01-.15-.249A4.527 4.527 0 018.5 13.5h2.25a.25.25 0 01.25.25v4.263a.251.251 0 01-.153.231 3 3 0 102.306 0 .251.251 0 01-.153-.232v-4.263a.25.25 0 01.25-.25H15a4.992 4.992 0 014.954 4.506.25.25 0 01-.149.254 3 3 0 102.325-.026z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});