define("ember-svg-jar/inlined/disability-lift", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-lift</title><path d=\"M9.973 16.4l-.093-.781a.249.249 0 00-.385-.18 2.722 2.722 0 00-1.242 2.228 2.594 2.594 0 002.529 2.59 2.69 2.69 0 002.651-2.59.083.083 0 00-.1-.079l-1.263.318a1.787 1.787 0 01-1.4-.307 1.755 1.755 0 01-.697-1.199z\"/><path d=\"M21.5 7.5A1.5 1.5 0 0020 6H4a1.5 1.5 0 00-1.5 1.5v15A1.5 1.5 0 004 24h16a1.5 1.5 0 001.5-1.5zM7.614 10.156a2.407 2.407 0 113.54 2.118.249.249 0 00-.13.25l.063.536a.253.253 0 00.107.176.247.247 0 00.2.036l2.3-.584a.75.75 0 11.371 1.453l-2.552.65a.25.25 0 00-.187.272l.138 1.159a.247.247 0 00.292.216l3.816-.965a.755.755 0 01.878.444l1.136 2.783a.25.25 0 00.292.148l.374-.093a.75.75 0 11.363 1.455l-1.215.3a.752.752 0 01-.876-.445l-1.141-2.8a.234.234 0 00-.45.089v.3a4.176 4.176 0 01-4.151 4.09 4.065 4.065 0 01-4.029-4.09 4.228 4.228 0 012.712-3.887.252.252 0 00.165-.265l-.1-.838a.25.25 0 00-.179-.21 2.409 2.409 0 01-1.737-2.298zM5 3h1v1a1 1 0 002 0V3h1a.5.5 0 00.354-.854l-2-2a.5.5 0 00-.708 0l-2 2A.5.5 0 005 3zM16.646 4.854a.5.5 0 00.708 0l2-2A.5.5 0 0019 2h-1V1a1 1 0 00-2 0v1h-1a.5.5 0 00-.354.854z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});