define("ember-svg-jar/inlined/navigation-left-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-left-1</title><path d=\"M.4 11.22l7.991-9.987A1.232 1.232 0 019.774.817 1.245 1.245 0 0110.627 2v1.5a.25.25 0 00.25.25h11.75A1.251 1.251 0 0123.877 5v8.752a.25.25 0 01-.25.25h-4a2.5 2.5 0 00-2.5 2.5V20a.25.25 0 01-.25.25h-6a.25.25 0 00-.25.25V22a1.259 1.259 0 01-1.257 1.247 1.227 1.227 0 01-.972-.475L.412 12.79A1.229 1.229 0 01.4 11.22z\"/><path d=\"M18.124 16.5a1.5 1.5 0 011.5-1.5h3.4a.249.249 0 01.168.434l-4.649 4.238a.25.25 0 01-.418-.185z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});