define("ember-svg-jar/inlined/ranking-star-top", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ranking-star-top</title><rect x=\"8.5\" y=\"11.5\" width=\"7\" height=\"12.5\" rx=\"1\" ry=\"1\"/><rect y=\"18\" width=\"6\" height=\"6\" rx=\"1\" ry=\"1\"/><rect x=\"18\" y=\"21\" width=\"6\" height=\"3\" rx=\"1\" ry=\"1\"/><path d=\"M15.739 3.28H14.5a.5.5 0 01-.467-.32L13.21.832a1.3 1.3 0 00-2.42 0L9.968 2.96a.5.5 0 01-.467.32h-1.24A1.3 1.3 0 007.5 5.627l.94.68a.5.5 0 01.165.6l-.678 1.562a1.3 1.3 0 001.825 1.645l2-1.126a.5.5 0 01.49 0l2 1.126a1.3 1.3 0 001.825-1.647L15.4 6.912a.5.5 0 01.165-.6l.939-.68a1.3 1.3 0 00-.76-2.347zm-1.253 1.953l-.62.449a.751.751 0 00-.248.907l.54 1.239a.251.251 0 01-.352.318l-1.438-.809a.748.748 0 00-.736 0l-1.438.809a.251.251 0 01-.352-.318l.54-1.239a.751.751 0 00-.248-.907l-.62-.449a.25.25 0 01.147-.453h.7a.75.75 0 00.7-.48l.707-1.833a.25.25 0 01.467 0l.704 1.833a.75.75 0 00.7.48h.7a.25.25 0 01.147.453z\"/><circle cx=\"3\" cy=\"13.5\" r=\"2\"/><circle cx=\"21\" cy=\"16.5\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});