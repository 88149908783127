define("ember-svg-jar/inlined/server-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-lock</title><path d=\"M0 3.5A2.5 2.5 0 002.5 6h17A2.5 2.5 0 0022 3.5v-1A2.5 2.5 0 0019.5 0h-17A2.5 2.5 0 000 2.5zM3.635 3A1.115 1.115 0 114.75 4.115 1.115 1.115 0 013.635 3zM7.5 3a1.115 1.115 0 111.115 1.115A1.114 1.114 0 017.5 3zM19 9a4.9 4.9 0 012.567.729A.279.279 0 0022 9.5 2.5 2.5 0 0019.5 7h-17A2.5 2.5 0 000 9.5v1A2.5 2.5 0 002.5 13h11.213a.5.5 0 00.48-.378A5 5 0 0119 9zm-9.271 1a1.115 1.115 0 11-1.114-1.115A1.114 1.114 0 019.729 10zm-3.864 0A1.115 1.115 0 114.75 8.885 1.115 1.115 0 015.865 10zM2.5 20H12a.5.5 0 00.5-.5V16a1.813 1.813 0 01.75-1.551.253.253 0 00-.16-.449H2.5A2.5 2.5 0 000 16.5v1A2.5 2.5 0 002.5 20zm1.135-3a1.115 1.115 0 111.115 1.114A1.115 1.115 0 013.635 17zM7.5 17a1.115 1.115 0 111.115 1.114A1.114 1.114 0 017.5 17z\"/><path d=\"M15.5 15.268a.25.25 0 01-.214.247A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.286-1.485.25.25 0 01-.214-.247V14.25a3.5 3.5 0 00-7 0zM20 20.25a1 1 0 11-1-1 1 1 0 011 1zm-1-7.5a1.5 1.5 0 011.5 1.5v1a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-1a1.5 1.5 0 011.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});