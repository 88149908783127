define("ember-svg-jar/inlined/mark-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mark</title><path d=\"M1.598 13.412c-.45.225-.604.328-.748.475-.12.123-.204.259-.26.42-.067.196-.09.38-.09.882v5.049c0 .259.146.496.378.612l4.042 2.02c.4.2.55.258.722.291.144.028.279.028.423 0 .173-.033.323-.09.722-.29l4.042-2.021a.684.684 0 00.378-.612v-5.049c0-.503-.022-.686-.09-.881a1.102 1.102 0 00-.26-.42c-.144-.148-.298-.25-.748-.476l-3.322-1.66c-.399-.2-.549-.258-.722-.291a1.084 1.084 0 00-.423 0c-.173.033-.323.09-.722.29l-3.322 1.661zm12.293 0c-.45.225-.604.328-.748.475-.12.123-.204.259-.26.42-.068.196-.09.38-.09.882v5.049c0 .259.146.496.378.612l4.042 2.02c.399.2.549.258.722.291.144.028.279.028.423 0 .173-.033.323-.09.722-.29l4.042-2.021a.684.684 0 00.378-.612v-5.049c0-.503-.023-.686-.09-.881a1.102 1.102 0 00-.26-.42c-.144-.148-.298-.25-.748-.476l-3.322-1.66c-.4-.2-.55-.258-.722-.291a1.084 1.084 0 00-.423 0c-.173.033-.323.09-.722.29l-3.322 1.661zM7.744 2.583c-.45.225-.604.327-.748.475-.12.123-.203.258-.26.42-.067.195-.09.379-.09.882v5.049c0 .259.147.495.378.611l4.043 2.021c.399.2.549.258.722.29.144.029.278.029.422 0 .173-.032.323-.09.722-.29l4.043-2.02a.684.684 0 00.378-.612v-5.05c0-.502-.023-.686-.09-.88a1.102 1.102 0 00-.26-.421c-.144-.148-.299-.25-.748-.475L12.933.922c-.399-.2-.549-.257-.722-.29a1.084 1.084 0 00-.422 0c-.173.033-.323.09-.722.29L7.744 2.583z\" stroke=\"#D0DAE2\" fill=\"none\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});