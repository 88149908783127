define("ember-svg-jar/inlined/safety-fire-alarm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-fire-alarm</title><path d=\"M10.575 8h2.85a.5.5 0 00.491-.4l.55-2.75a.5.5 0 00-.491-.6h-3.95a.5.5 0 00-.491.6l.55 2.75a.5.5 0 00.491.4zM19.5 0h-15a1 1 0 00-1 1v1a3.116 3.116 0 00.059.546.249.249 0 00.246.2H20.2a.249.249 0 00.245-.2A3.116 3.116 0 0020.5 2V1a1 1 0 00-1-1zM6.5 8h1.525a.5.5 0 00.491-.6l-.55-2.75a.5.5 0 00-.491-.4H4.9a.5.5 0 00-.464.686l.914 2.286A1.3 1.3 0 006.5 8zM19.1 4.25h-2.575a.5.5 0 00-.491.4l-.55 2.75a.5.5 0 00.491.6H17.5a1.3 1.3 0 001.15-.778l.914-2.286a.5.5 0 00-.464-.686zM7.751 18.716a1.68 1.68 0 01-.361-2.333 1 1 0 10-1.627-1.162 3.667 3.667 0 00.788 5.094A1.693 1.693 0 017 22.5a1 1 0 101.74 1 3.693 3.693 0 00-.989-4.784zM12.6 18.716a1.68 1.68 0 01-.361-2.333 1 1 0 10-1.628-1.162 3.668 3.668 0 00.789 5.094 1.692 1.692 0 01.453 2.189 1 1 0 101.736.992 3.693 3.693 0 00-.989-4.78zM17.449 18.716a1.679 1.679 0 01-.361-2.333 1 1 0 10-1.627-1.162 3.666 3.666 0 00.788 5.094A1.693 1.693 0 0116.7 22.5a1 1 0 00.372 1.364.988.988 0 00.495.132 1 1 0 00.869-.5 3.693 3.693 0 00-.987-4.78zM6.9 9.76a.5.5 0 00.017.509A5.959 5.959 0 0012 12.5a5.961 5.961 0 005.083-2.232.5.5 0 00-.422-.768H7.339a.5.5 0 00-.439.26z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});