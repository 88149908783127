define("ember-svg-jar/inlined/professions-woman-nurse-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-woman-nurse-1</title><circle cx=\"13.75\" cy=\"9\" r=\"1\"/><circle cx=\"10\" cy=\"9\" r=\"1\"/><path d=\"M10.457 11.613a.639.639 0 00.315.719 2.3 2.3 0 00.437.181 2.967 2.967 0 00.868.126 3.016 3.016 0 00.868-.125 2.3 2.3 0 00.439-.182.639.639 0 00-.307-1.2h-2a.639.639 0 00-.62.481z\"/><path d=\"M1 24a1 1 0 001-1v-2.607a.972.972 0 01.377-.784c1.194-.912 3.856-2.4 8.613-2.587a.253.253 0 01.183.07.25.25 0 01.077.18V23a.75.75 0 001.5 0v-5.728a.25.25 0 01.077-.18.253.253 0 01.183-.07c4.757.189 7.419 1.675 8.614 2.588a.971.971 0 01.376.783V23a1 1 0 002 0v-2.607a2.957 2.957 0 00-1.163-2.374 11.624 11.624 0 00-1.533-.978.25.25 0 01-.019-.429c.366-.241.571-.4.589-.414a.5.5 0 00.031-.76c-2.927-2.723-2.4-6.59-2.405-7.954a7.5 7.5 0 10-14.993.08c0 .768.7 4.984-2.412 7.874a.5.5 0 00.031.76c.017.013.223.173.589.415a.25.25 0 01-.02.429 11.489 11.489 0 00-1.531.977A2.955 2.955 0 000 20.393V23a1 1 0 001 1zM11.786 4.576a.25.25 0 01.428 0 5 5 0 004.524 2.418.251.251 0 01.262.25V9.5a5 5 0 11-10 0V7.244a.251.251 0 01.262-.25 5 5 0 004.524-2.418z\"/><path d=\"M8.5 22.249a.75.75 0 000-1.5H8a.25.25 0 01-.25-.25V20a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25h-.5a.75.75 0 000 1.5H6a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.5a.25.25 0 01.25-.25z\"/><circle cx=\"14.75\" cy=\"19.25\" r=\"1\"/><path d=\"M15.75 22.25a1 1 0 00-2 0 1 1 0 002 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});