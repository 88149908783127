define("ember-svg-jar/inlined/single-neutral-actions-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-sync</title><path d=\"M.011 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229l.443 5.313a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.244-.229h.546a.247.247 0 00.182-.079.25.25 0 00.068-.186c0-.078-.008-.156-.008-.235a7.251 7.251 0 011.39-4.256.253.253 0 00.044-.189 5.5 5.5 0 00-10.911.945z\"/><circle cx=\"5.511\" cy=\"3.75\" r=\"3.5\"/><path d=\"M17.136 23.75a6.347 6.347 0 004.982-2.4 1 1 0 10-1.562-1.25 4.376 4.376 0 11-.5-5.981.25.25 0 01.083.179.254.254 0 01-.073.183l-1.391 1.391a.5.5 0 00.353.854h4.457a.5.5 0 00.5-.5v-4.455a.5.5 0 00-.853-.353l-1.3 1.3a.251.251 0 01-.348.005A6.319 6.319 0 0017.136 11a6.375 6.375 0 100 12.75z\"/><path d=\"M.011 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229l.443 5.313a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.244-.229h.546a.247.247 0 00.182-.079.25.25 0 00.068-.186c0-.078-.008-.156-.008-.235a7.251 7.251 0 011.39-4.256.253.253 0 00.044-.189 5.5 5.5 0 00-10.911.945z\"/><circle cx=\"5.511\" cy=\"3.75\" r=\"3.5\"/><path d=\"M17.136 23.75a6.347 6.347 0 004.982-2.4 1 1 0 10-1.562-1.25 4.376 4.376 0 11-.5-5.981.25.25 0 01.083.179.254.254 0 01-.073.183l-1.391 1.391a.5.5 0 00.353.854h4.457a.5.5 0 00.5-.5v-4.455a.5.5 0 00-.853-.353l-1.3 1.3a.251.251 0 01-.348.005A6.319 6.319 0 0017.136 11a6.375 6.375 0 100 12.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});