define("ember-svg-jar/inlined/disability-hearing-t", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-hearing-t</title><path d=\"M8.892 4.927A6.335 6.335 0 003.379 9.07a.98.98 0 001.841.672 4.369 4.369 0 013.806-2.86 4.443 4.443 0 014.6 3.68 4.319 4.319 0 01-1.6 4.079 4.245 4.245 0 00-1.578 3.85 2.52 2.52 0 01-2.813 2.739 2.422 2.422 0 01-1.135-.438.98.98 0 00-1.135 1.6 4.387 4.387 0 002.055.787 4.632 4.632 0 00.511.028 4.472 4.472 0 004.461-4.979 2.246 2.246 0 01.856-2.05A6.317 6.317 0 008.892 4.927zM13.459 3.726a.982.982 0 00.714 1.189 3.765 3.765 0 012.749 2.749.981.981 0 00.95.742 1.018 1.018 0 00.238-.028.981.981 0 00.714-1.189 5.727 5.727 0 00-4.177-4.176.98.98 0 00-1.188.713z\"/><path d=\"M15.078 1.93a6.625 6.625 0 014.829 4.829.979.979 0 101.9-.475A8.576 8.576 0 0015.552.03a.979.979 0 10-.474 1.9zM8.3 10.561a1.291 1.291 0 012.1.314 1.216 1.216 0 01.118.556.979.979 0 00.952 1.007h.03a.979.979 0 00.979-.951 3.217 3.217 0 00-.3-1.447 3.252 3.252 0 00-5.31-.815.98.98 0 001.431 1.336zM.491 17.119a.98.98 0 000 1.385l1.127 1.127A.98.98 0 003 18.246l-1.123-1.127a.98.98 0 00-1.386 0zM5.241 12.369a.978.978 0 000 1.385l1.128 1.128A.98.98 0 007.754 13.5l-1.128-1.131a.978.978 0 00-1.385 0zM1.739 13.617a.978.978 0 000 1.385l3.382 3.382A.979.979 0 006.506 17l-3.382-3.383a.978.978 0 00-1.385 0zM22.816 17.143h-4.9a.98.98 0 000 1.959h1.225a.245.245 0 01.244.245v3.673a.98.98 0 001.96 0v-3.673a.245.245 0 01.244-.245h1.225a.98.98 0 100-1.959z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});