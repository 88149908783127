define("ember-svg-jar/inlined/pet-tracking-dog-signal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pet-tracking-dog-signal</title><path d=\"M23.816 20.113l-5.4-4.415a.5.5 0 00-.669.033l-7.69 7.689a.34.34 0 00.24.58H23.5a.5.5 0 00.5-.5v-3a.5.5 0 00-.184-.387zM14.252 12.3a.5.5 0 01-.175-.3 8.05 8.05 0 00-3.793-5.882.943.943 0 00-1.406.823v1.871a.5.5 0 01-.5.5c-2.889.03-4.757.33-4.815 3.341a.5.5 0 01-.177.372 15.055 15.055 0 01-3.085 1.65.5.5 0 00-.301.46v1.329a3.167 3.167 0 003.164 3.164h3.073a1.278 1.278 0 011.045.543L8.309 21.6a.5.5 0 00.759.062l7.092-7.092a.5.5 0 00-.037-.741zM7 13.5a1 1 0 111-1 1 1 0 01-1 1z\"/><circle cx=\"17.5\" cy=\"8.765\" r=\"1.75\"/><path d=\"M12.621 3.709a7.823 7.823 0 019.758 0 1 1 0 101.242-1.568 9.821 9.821 0 00-12.242 0 1 1 0 101.242 1.568z\"/><path d=\"M20.5 6.728a1 1 0 00.647-1.762 5.621 5.621 0 00-7.293 0 1 1 0 001.293 1.525 3.586 3.586 0 014.707 0 1 1 0 00.646.237z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});