define("ember-svg-jar/inlined/construction-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>construction-sign</title><path d=\"M17.375 4.188a.25.25 0 01.125.212V5a.25.25 0 01-.25.25h-.134a.18.18 0 00-.124.049l-7.524 7.524a.25.25 0 00.177.427h4.739a.18.18 0 00.124-.049l7.524-7.524a.25.25 0 00-.177-.427H19.75A.25.25 0 0119.5 5v-.6a.25.25 0 01.125-.216 2.25 2.25 0 10-2.25 0zM17.006 12.823a.25.25 0 00.177.427H19.5a.25.25 0 01.25.25v9.25a1.25 1.25 0 002.5 0V13.5a.25.25 0 01.25-.25 1.5 1.5 0 001.5-1.5v-5a1.432 1.432 0 00-.042-.343.25.25 0 00-.42-.116zM7.119 5.677a.25.25 0 00-.177-.427H6.75A.25.25 0 016.5 5v-.6a.25.25 0 01.125-.216 2.25 2.25 0 10-2.25 0A.25.25 0 014.5 4.4V5a.25.25 0 01-.25.25H1.5A1.5 1.5 0 000 6.75v5a1.452 1.452 0 00.074.459.249.249 0 00.414.1z\"/><path d=\"M6.969 13.2l7.522-7.522a.25.25 0 00-.177-.427H9.741a.18.18 0 00-.124.049l-7.909 7.908a.025.025 0 000 .027.025.025 0 00.023.015.024.024 0 01.024.024v9.476a1.25 1.25 0 002.5 0V13.5a.25.25 0 01.25-.25H6.85a.171.171 0 00.119-.05z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});