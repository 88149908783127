define("ember-svg-jar/inlined/cog-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cog-3</title><path d=\"M24 10.942a1 1 0 00-.876-.992l-1.734-.216a.25.25 0 01-.21-.184 9.4 9.4 0 00-.959-2.3.251.251 0 01.019-.279l1.075-1.383a1 1 0 00-.082-1.322l-1.5-1.5a1 1 0 00-1.321-.083l-1.379 1.078a.251.251 0 01-.279.019 9.392 9.392 0 00-2.3-.958.252.252 0 01-.184-.211L14.051.877A1 1 0 0013.059 0h-2.118a1 1 0 00-.992.876l-.217 1.735a.252.252 0 01-.184.211 9.392 9.392 0 00-2.3.958.251.251 0 01-.279-.019L5.584 2.685a1 1 0 00-1.32.082l-1.5 1.5a1 1 0 00-.082 1.322l1.078 1.38a.251.251 0 01.019.279 9.4 9.4 0 00-.959 2.3.25.25 0 01-.21.184L.877 9.95a1 1 0 00-.877.992v2.118a1 1 0 00.876.992l1.734.217a.248.248 0 01.21.183 9.4 9.4 0 00.959 2.3.251.251 0 01-.019.279l-1.075 1.385a1 1 0 00.082 1.322l1.5 1.5a1 1 0 001.321.083l1.383-1.076a.25.25 0 01.279-.018 9.373 9.373 0 002.3.957.252.252 0 01.184.211l.217 1.734a1 1 0 00.992.876h2.118a1 1 0 00.992-.876l.217-1.734a.252.252 0 01.184-.211 9.373 9.373 0 002.3-.957.248.248 0 01.279.019l1.383 1.075a1 1 0 001.32-.082l1.5-1.5a1 1 0 00.082-1.321l-1.078-1.384a.251.251 0 01-.019-.279 9.4 9.4 0 00.959-2.3.248.248 0 01.21-.183l1.734-.217A1 1 0 0024 13.06zM11 9.837a.252.252 0 01-.125.217 2.245 2.245 0 00-1.1 2.266.254.254 0 01-.093.233L6.97 14.682a.25.25 0 01-.38-.088 6 6 0 014.11-8.449.247.247 0 01.211.049.25.25 0 01.094.2zm-.085 4.288a.25.25 0 01.248-.035 2.256 2.256 0 001.674 0 .25.25 0 01.248.035l2.708 2.129a.249.249 0 010 .39 5.991 5.991 0 01-7.594 0 .252.252 0 01-.092-.2.248.248 0 01.1-.194zm3.405-1.572a.254.254 0 01-.093-.233 2.245 2.245 0 00-1.1-2.266.252.252 0 01-.127-.217V6.389a.249.249 0 01.3-.244 6 6 0 014.105 8.449.25.25 0 01-.38.088z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});