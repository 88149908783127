define("ember-svg-jar/inlined/fishing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fishing</title><path d=\"M7.105 13.983a.5.5 0 00.443.267h3.8a.5.5 0 00.41-.786L3.616 1.806A1.3 1.3 0 001.4 3.153zM18 3.75a.749.749 0 00.6-.3l.338-.45a.637.637 0 01.931-.066l.2.2a2.187 2.187 0 003.19-.234l.34-.453a.75.75 0 00-1.2-.9l-.34.453a.636.636 0 01-.931.066l-.2-.2a2.187 2.187 0 00-3.191.227l-.34.453a.75.75 0 00.6 1.2zM15 14.25h6a.75.75 0 00.75-.75V9.75A3.744 3.744 0 0019 6.153V5.5a1 1 0 00-2 0v.653a3.745 3.745 0 00-2.75 3.6V13.5a.75.75 0 00.75.75zM17.748 7a.5.5 0 010 1 1.5 1.5 0 00-1.5 1.5.5.5 0 01-1 0 2.5 2.5 0 012.5-2.5zM22.942 20.707a3.41 3.41 0 01-2.654-1.576 1.044 1.044 0 00-1.72.029 3.364 3.364 0 01-2.754 1.555 2.807 2.807 0 01-2.414-1.486 1.043 1.043 0 00-1.717-.155 4.318 4.318 0 01-3.112 1.641 3.565 3.565 0 01-2.766-1.584 1.042 1.042 0 00-1.635-.091c-.014.018-1.463 1.785-3.117 1.707a1 1 0 00-.1 2c.075 0 .148.006.222.006a5.61 5.61 0 003.408-1.338.5.5 0 01.635.011 5.145 5.145 0 003.36 1.292 5.739 5.739 0 003.482-1.235.5.5 0 01.639.027 4.535 4.535 0 003.119 1.208A5.091 5.091 0 0019.12 21.5a.5.5 0 01.643 0 4.663 4.663 0 003.292 1.2 1 1 0 10-.113-2z\"/><path d=\"M16.959 18.507a.292.292 0 00.327-.124 2.483 2.483 0 012.154-1.19 2.518 2.518 0 011.448.465.5.5 0 01.36-.158H23a1 1 0 100-2H6a.993.993 0 00-.672 1.731 2.544 2.544 0 01.867.269h2.053a.5.5 0 01.5.5v.53a.5.5 0 00.723.448 3.37 3.37 0 001.021-.813 2.464 2.464 0 011.991-.972 2.536 2.536 0 011.093.259.5.5 0 00.214.048h2.458a.5.5 0 01.5.5v.227a.291.291 0 00.211.28z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});