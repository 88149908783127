define("ember-svg-jar/inlined/single-woman-actions-alarm.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-alarm</title><path d=\"M12 15.5a5.948 5.948 0 01.234-1.644.5.5 0 00-.154-.517A7.457 7.457 0 00.021 16.857.5.5 0 00.5 17.5h11.167a.333.333 0 00.333-.333zM23.659 19.884a4.082 4.082 0 01-1.159-2.717V15.5a4.5 4.5 0 00-9 0v1.667a4.08 4.08 0 01-1.158 2.715l-.242.318a.5.5 0 00.4.8h11a.5.5 0 00.4-.8zM19.681 22.194a.5.5 0 00-.395-.194h-2.572a.5.5 0 00-.484.625 1.827 1.827 0 003.54 0 .5.5 0 00-.089-.431z\"/><path d=\"M1.564 10.442a1 1 0 001.383-.3 5.153 5.153 0 00.454-.9.25.25 0 01.411-.083 4.728 4.728 0 006.787 0 .25.25 0 01.411.082 5.2 5.2 0 00.454.9 1 1 0 001.681-1.081 4.561 4.561 0 01-.645-2.511V5.3a5.3 5.3 0 10-10.593 0v1.248a4.57 4.57 0 01-.64 2.512 1 1 0 00.297 1.382zM7.206 8.6a2.7 2.7 0 01-2.741-2.35.5.5 0 01.324-.578 5.693 5.693 0 002.245-1.44.25.25 0 01.361 0 5.708 5.708 0 002.229 1.437.5.5 0 01.323.576A2.707 2.707 0 017.206 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});