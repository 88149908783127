define("ember-svg-jar/inlined/road-sign-sharp-turn-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-sharp-turn-alternate</title><path d=\"M23.583 12.647a1.481 1.481 0 00.417-1.06 1.5 1.5 0 00-.455-1.043L13.032.418a1.484 1.484 0 00-2.064 0L.455 10.544A1.5 1.5 0 000 11.587a1.481 1.481 0 00.417 1.06l10.513 10.9a1.486 1.486 0 002.14 0zm-11.4 8.941a.251.251 0 01-.36 0l-9.434-9.78a.252.252 0 01-.07-.178.249.249 0 01.077-.176l9.433-9.086a.251.251 0 01.347 0l9.434 9.086a.25.25 0 01.007.354z\"/><path d=\"M9.262 7.972a.75.75 0 00.531 1.28h1.164a.25.25 0 01.25.25v.442a.251.251 0 01-.073.177L9.854 11.4a1.5 1.5 0 000 2.121l2.263 2.262a.252.252 0 010 .354L11 17.254a1 1 0 00-.293.707v.54a1 1 0 002 0v-.022a.25.25 0 01.074-.177l1.28-1.28a1.5 1.5 0 000-2.122L11.8 12.638a.25.25 0 010-.353l1.116-1.116a1 1 0 00.293-.707V9.5a.25.25 0 01.25-.25h1.336a.75.75 0 00.53-1.281l-2.5-2.5a.754.754 0 00-.531-.219.747.747 0 00-.53.22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});