define("ember-svg-jar/inlined/single-neutral-actions-credit-card.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-credit-card</title><path d=\"M20.125 19.875h-1.25a.75.75 0 000 1.5h1.25a.75.75 0 000-1.5zM14.492 12.125a.25.25 0 00.11-.475c-.8-.39-1.806-.765-2.978-1.2L11 10.223a.6.6 0 01-.138-.31 2.632 2.632 0 01.058-1.3A5.618 5.618 0 0012.381 4.3c0-2.419-1.581-4.175-3.756-4.175S4.869 1.881 4.869 4.3A5.605 5.605 0 006.32 8.6a2.505 2.505 0 01.073 1.317.614.614 0 01-.135.308l-.632.233C3.18 11.354 1.412 12 .873 13.08a9.167 9.167 0 00-.748 3.545.5.5 0 00.5.5h8.25a.25.25 0 00.25-.25v-1.75a3 3 0 013-3z\"/><path d=\"M22.125 13.375h-10a1.752 1.752 0 00-1.75 1.75v7a1.752 1.752 0 001.75 1.75h10a1.752 1.752 0 001.75-1.75v-7a1.752 1.752 0 00-1.75-1.75zm.25 8.75a.25.25 0 01-.25.25h-10a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25h10a.25.25 0 01.25.25zm0-6a.25.25 0 01-.25.25h-10a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h10a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});