define("ember-svg-jar/inlined/history-sphinx-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-sphinx-1</title><path d=\"M2.415 19.75h4.646a.5.5 0 00.494-.583A6.48 6.48 0 017.5 18a.5.5 0 00-.5-.5 4.456 4.456 0 01-3.27-1.422.5.5 0 00-.839.183l-.95 2.83a.5.5 0 00.474.659zM8.748 21.386a.5.5 0 00-.348-.136H1.58a.5.5 0 00-.474.342A1.884 1.884 0 001 22.21 1.788 1.788 0 002.79 24H9.5a1.5 1.5 0 001.5-1.5.063.063 0 00-.055-.062 3.925 3.925 0 01-2.197-1.052zM16.5 18a6.48 6.48 0 01-.055 1.167.5.5 0 00.493.583h4.647a.5.5 0 00.474-.659l-.95-2.83a.5.5 0 00-.84-.183A4.455 4.455 0 0117 17.5a.5.5 0 00-.5.5zM22.894 21.592a.5.5 0 00-.475-.342H15.6a.5.5 0 00-.343.136 3.924 3.924 0 01-2.2 1.047.063.063 0 00-.057.067 1.5 1.5 0 001.5 1.5h6.71A1.788 1.788 0 0023 22.21a1.893 1.893 0 00-.106-.618zM5.826 5.789a9.364 9.364 0 012.763-1.653.5.5 0 00.3-.615L8.15 1.14A1.59 1.59 0 006.61 0 1.609 1.609 0 005 1.61v3.8a.5.5 0 00.826.379zM15.411 4.136a9.364 9.364 0 012.763 1.653A.5.5 0 0019 5.41v-3.8A1.609 1.609 0 0017.39 0a1.59 1.59 0 00-1.54 1.14l-.738 2.381a.5.5 0 00.299.615z\"/><path d=\"M15 18.5v-2a.5.5 0 01.5-.5H17a3 3 0 003-3 8 8 0 00-16 0 3 3 0 003 3h1.5a.5.5 0 01.5.5v2a2.464 2.464 0 002.229 2.459 7.79 7.79 0 001.542 0A2.464 2.464 0 0015 18.5zm.5-8.5a1 1 0 11-1 1 1 1 0 011-1zm-7 2a1 1 0 111-1 1 1 0 01-1 1zm3.5 7a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});