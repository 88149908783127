define("ember-svg-jar/inlined/reptile-rattlesnake-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>reptile-rattlesnake-1</title><path d=\"M22.048 18.287A12.654 12.654 0 0024 12.5c0-2.556-1.327-3.52-2-3.844V6.5a6.5 6.5 0 00-13 0V17a2.025 2.025 0 01-2.208 1.99A2.1 2.1 0 015 16.856v-2.37a.5.5 0 00-.5-.5h-4a.5.5 0 00-.5.5V17a7.069 7.069 0 007 7c4.371 0 7-4.017 7-7.273V6.5a1.5 1.5 0 113 0v2.158c-.673.324-2 1.288-2 3.844a12.654 12.654 0 001.952 5.785A2.485 2.485 0 0018.5 19.42v1.663l-1.207 1.208a1 1 0 001.414 1.414l.793-.793.793.793a1 1 0 001.414-1.414L20.5 21.083V19.42a2.485 2.485 0 001.548-1.133zM17.5 11.5a1 1 0 11-1 1 1 1 0 011-1zm4 0a1 1 0 11-1 1 1 1 0 011-1z\"/><path d=\"M1 12.5h3a1 1 0 000-2H1a1 1 0 000 2zM1.5 9.491h2a1 1 0 000-2h-2a1 1 0 000 2zM2 6.5h1a1 1 0 000-2H2a1 1 0 000 2zM2.5 3.5a1 1 0 001-1v-1a1 1 0 00-2 0v1a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});