define("ember-svg-jar/inlined/mood-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mood-question</title><path d=\"M24 12a4.8 4.8 0 00-2.207-4.057 4.831 4.831 0 00-5.736-5.736 4.831 4.831 0 00-8.112 0 4.831 4.831 0 00-5.737 5.736 4.832 4.832 0 000 8.114 4.831 4.831 0 005.736 5.736 4.831 4.831 0 008.112 0 4.832 4.832 0 005.736-5.737A4.8 4.8 0 0024 12zm-11 1.373V14a1 1 0 01-2 0v-1.5a1 1 0 011-1 2 2 0 10-2-2 1 1 0 01-2 0 4 4 0 115 3.873zm-1 5.877a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});