define("ember-svg-jar/inlined/police-officer-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-officer-badge</title><path d=\"M4.238 4.991a.218.218 0 00-.218.191A2.612 2.612 0 004 5.49a2.5 2.5 0 105 0v-.241A.25.25 0 008.751 5zM15.1 10.5l-.784.915a.776.776 0 01-1.139 0l-.882-1.03A3.249 3.249 0 009.83 9.25h-1.5a.5.5 0 00-.43.25l-.915 1.6a.5.5 0 01-.867 0l-.929-1.6a.5.5 0 00-.433-.25H4a4.005 4.005 0 00-4 4v3.61a1.25 1.25 0 002.5 0V13a.5.5 0 011 0v10.5a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-5.14a.5.5 0 011 0v5.14a.5.5 0 00.5.5H9a.5.5 0 00.5-.5V11.75c.353 0 .641-.039.9.262l.883 1.03a3.251 3.251 0 004.936 0l.366-.428a.25.25 0 00.021-.3l-1.083-1.7a.25.25 0 00-.211-.116zM3.98 3.852a.25.25 0 00.22.138h4.22a.249.249 0 00.2-.1L9.668 2.5a.505.505 0 00.083-.43.5.5 0 00-.286-.33L6.746.575a.505.505 0 00-.394 0L3.537 1.781a.5.5 0 00-.25.684z\"/><path d=\"M22.619 6.777a.5.5 0 010-.554l1.3-1.946A.5.5 0 0023.5 3.5h-2.223a.5.5 0 01-.424-.235L18.924.235a.5.5 0 00-.848 0l-1.929 3.03a.5.5 0 01-.424.235H13.5a.5.5 0 00-.416.777l1.3 1.946a.5.5 0 010 .554l-1.3 1.946a.5.5 0 00.416.777h2.223a.5.5 0 01.424.235l1.929 3.03a.5.5 0 00.848 0l1.929-3.03a.5.5 0 01.424-.235H23.5a.5.5 0 00.416-.777zM18.5 8A1.5 1.5 0 1120 6.5 1.5 1.5 0 0118.5 8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});