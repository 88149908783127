define("ember-svg-jar/inlined/phone-action-home-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-home-1</title><path d=\"M12 13.75v4.75a1 1 0 01-1 1H4a1 1 0 01-1-1v-11a1 1 0 011-1h3.276a.253.253 0 00.244-.287 2.237 2.237 0 01.708-1.784A.249.249 0 008.055 4H3.5A2.5 2.5 0 001 6.5v15A2.5 2.5 0 003.5 24h8a2.5 2.5 0 002.5-2.5v-7.75a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25z\"/><path d=\"M22.823 5.619l-6.5-5.5a.5.5 0 00-.646 0l-6.5 5.5A.5.5 0 009.5 6.5H11v5a.5.5 0 00.5.5H14a.5.5 0 00.5-.5V9.25a1.5 1.5 0 013 0v2.25a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-5h1.5a.5.5 0 00.323-.881z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});