define("ember-svg-jar/inlined/farming-wind-cock-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>farming-wind-cock-1</title><path d=\"M21.164 18.852a.5.5 0 010-.707l.439-.439A1 1 0 0020.9 16h-2.383a1.49 1.49 0 00-1.06.439l-.768.768a1 1 0 01-.707.293H13.4a.5.5 0 01-.5-.5v-3.42a.5.5 0 01.364-.48 6.019 6.019 0 004.386-5.749c.095-1.887.764-2.963 1.838-2.963.786 0 1.412.564 1.412.941a.5.5 0 101 0v-.5a3.5 3.5 0 00-7 0v.5a1.5 1.5 0 01-1.5 1.5h-1.3a2.928 2.928 0 01-2.029-.774.918.918 0 01-.271-.727c.272-3.023-.666-4.315-1.5-4.866a3.417 3.417 0 00-3.482.032 3.179 3.179 0 00-1.654 1.872 1 1 0 01-.26.456l-.654.654a.5.5 0 00.353.853h2a1 1 0 011 1v2a6.01 6.01 0 004.886 5.9.5.5 0 01.407.491V17a.5.5 0 01-.5.5H6.1a.5.5 0 01-.5-.5v-.5a.5.5 0 00-.853-.354l-2 2a.5.5 0 000 .707l2 2A.5.5 0 005.1 21a.513.513 0 00.192-.038.5.5 0 00.308-.462V20a.5.5 0 01.5-.5h4.3a.5.5 0 01.5.5v3a1 1 0 002 0v-3a.5.5 0 01.5-.5h2.586a1 1 0 01.707.293l.768.768a1.49 1.49 0 001.06.439H20.9a1 1 0 00.707-1.707zm-9.531-7.523a3.254 3.254 0 01-3.25-3.25.25.25 0 01.426-.179 6.433 6.433 0 005.425 1.35.251.251 0 01.246.1.253.253 0 01.017.265 3.25 3.25 0 01-2.864 1.714z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});