define("ember-svg-jar/inlined/army-symbol-company", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-symbol-company</title><path d=\"M12 6a1 1 0 001-1V1a1 1 0 00-2 0v4a1 1 0 001 1zM22 7H2a2 2 0 00-2 2v13a2 2 0 002 2h20a2 2 0 002-2V9a2 2 0 00-2-2zm0 14.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});