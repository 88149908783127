define("ember-svg-jar/inlined/athletics-discus-throwing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>athletics-discus-throwing</title><path d=\"M5.919 4.544A2.5 2.5 0 018.148 3a3.5 3.5 0 10-1.967 3.935 2.539 2.539 0 01-.262-.478 2.479 2.479 0 010-1.913z\"/><circle cx=\"18.228\" cy=\"3.25\" r=\"2.5\"/><path d=\"M22.441 11.162A4.528 4.528 0 0020 8.7L8.8 4.115a1.5 1.5 0 10-1.145 2.771l5.363 2.178a.251.251 0 01.118.365l-2.728 4.321a1.738 1.738 0 00-.18.706v3.678a1.507 1.507 0 01-.121.592l-1.258 2.933a1.5 1.5 0 102.758 1.182l1.259-2.941a4.49 4.49 0 00.362-1.769v-.956a.25.25 0 01.354-.227l1.266.575a1.507 1.507 0 01.88 1.367v3.36a1.5 1.5 0 103 0v-3.357a4.509 4.509 0 00-2.638-4.1l-2.014-.915a.247.247 0 01-.137-.159.252.252 0 01.032-.207l2-3.014a.252.252 0 01.3-.093l2.577 1.067a1.5 1.5 0 01.8 1.975l-.3.708a1.5 1.5 0 102.758 1.184l.3-.71a4.526 4.526 0 00.035-3.467z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});