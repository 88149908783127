define("ember-svg-jar/inlined/setting-slider-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>setting-slider-horizontal</title><path d=\"M9 5.5A2.5 2.5 0 006.5 8v8a2.5 2.5 0 005 0V8A2.5 2.5 0 009 5.5zM22.5 10.492h-8a1.5 1.5 0 000 3h8a1.5 1.5 0 000-3zM3.5 10.492h-.007l-2 .009a1.5 1.5 0 00.007 3h.007l2-.009a1.5 1.5 0 00-.007-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});