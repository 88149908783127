define("ember-svg-jar/inlined/radio-antenna-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>radio-antenna-2</title><path d=\"M23.75 12a.25.25 0 00.25-.25V10.5A1.5 1.5 0 0022.5 9h-.737a.25.25 0 01-.1-.021L1.4.084a1 1 0 10-.8 1.832l15.044 6.605a.25.25 0 01-.1.479H8.75a.25.25 0 01-.25-.25V8a1 1 0 00-2 0v.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V8a1 1 0 00-2 0v.75a.25.25 0 01-.25.25H1.5A1.5 1.5 0 000 10.5v1.25a.25.25 0 00.25.25zM.25 13.5a.25.25 0 00-.25.25v8.75A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-8.75a.25.25 0 00-.25-.25zM19 16a3 3 0 11-3 3 3 3 0 013-3zM5 16a3 3 0 11-3 3 3 3 0 013-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});