define("ember-svg-jar/inlined/toys-duck", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toys-duck</title><circle cx=\"12.5\" cy=\"21\" r=\"3\"/><path d=\"M23.841 1.541A1 1 0 1022.159.459L13.441 14.02a.5.5 0 01-.42.23h-.939a.253.253 0 01-.21-.113.25.25 0 01-.019-.237 4.75 4.75 0 10-8.745-3.709.5.5 0 01-.462.309H1a1 1 0 000 2h1.348a.5.5 0 01.49.4 4.767 4.767 0 001.97 3.009 7.191 7.191 0 002.931 4.961.5.5 0 00.789-.346 4 4 0 017.809-.656.5.5 0 00.836.208 7.271 7.271 0 002.052-4.476 1.249 1.249 0 00-1.245-1.35h-1.852a.25.25 0 01-.21-.385zM7.229 12.152a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});