define("ember-svg-jar/inlined/conversation-question-warning-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>conversation-question-warning-1</title><path d=\"M7.731 16.436c0-4.177 3.964-7.575 8.836-7.575a9.445 9.445 0 016.78 2.736.245.245 0 00.249.065.251.251 0 00.173-.192 8.377 8.377 0 00.144-1.542C23.913 4.454 18.569 0 12 0S.087 4.454.087 9.928a8.977 8.977 0 002.745 6.33l-1.409 4.7a.5.5 0 00.677.6l5.43-2.42c.13.044.26.085.392.123a.248.248 0 00.3-.332 6.57 6.57 0 01-.491-2.493zm4.683-9.179a.249.249 0 00-.166.233v.171a.745.745 0 11-1.489 0V7.49a1.739 1.739 0 011.158-1.637 1.241 1.241 0 10-1.654-1.17.745.745 0 01-1.489 0 2.73 2.73 0 113.64 2.574z\"/><path d=\"M21.981 20.552a5.505 5.505 0 001.932-4.116c0-3.355-3.3-6.086-7.346-6.086S9.22 13.081 9.22 16.436s3.3 6.085 7.347 6.085a8.767 8.767 0 002.36-.32l3.767 1.752a.5.5 0 00.653-.671zm-4.769-1.59a.745.745 0 11-.745-.745.744.744 0 01.745.745zm0-5.709v3.475a.745.745 0 01-1.489 0v-3.475a.745.745 0 011.489 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});