define("ember-svg-jar/inlined/online-reading-wattpad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>online-reading-wattpad</title><path d=\"M22.72 2.16h-5.35a1.28 1.28 0 00-1.27 1.18 1.28 1.28 0 00-1.27-1.18H9.17A1.28 1.28 0 007.9 3.34a1.28 1.28 0 00-1.27-1.18H1.28A1.28 1.28 0 000 3.44v12.19a6.21 6.21 0 006.2 6.2h16.52A1.28 1.28 0 0024 20.56V3.44a1.28 1.28 0 00-1.28-1.28zm-1.27 17.12H6.2a3.65 3.65 0 01-3.65-3.65V4.72h2.8v9.42a3.25 3.25 0 003.13 3.42h.69a1.28 1.28 0 001.28-1.28V4.72h3.1v11.56a1.28 1.28 0 001.28 1.28h2.55a1.28 1.28 0 001.28-1.28V4.72h2.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});