define("ember-svg-jar/inlined/synchronize-arrows-triangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>synchronize-arrows-triangle</title><path d=\"M17.844 4.563l-1.424.7a.249.249 0 01-.33-.1l-2.108-3.836a2.251 2.251 0 00-3.944 0L6.93 6.972a1.25 1.25 0 102.19 1.2l2.67-4.85a.25.25 0 01.438 0l1.609 2.928a.25.25 0 01-.109.344l-.975.482a.5.5 0 00.056.92l3.724 1.306a.481.481 0 00.165.028.5.5 0 00.471-.33l1.367-3.824a.5.5 0 00-.692-.616zM19.791 11.9a1.25 1.25 0 10-2.191 1.2l3.082 5.611a.249.249 0 01-.219.37H17.1a.25.25 0 01-.25-.25v-1.5a.5.5 0 00-.306-.461.505.505 0 00-.544.1l-3.063 3a.5.5 0 000 .715l3.063 3a.5.5 0 00.35.142.484.484 0 00.194-.039.5.5 0 00.306-.461v-1.5a.25.25 0 01.25-.25h4.209a2.25 2.25 0 001.972-3.333zM.754 20.481a2.263 2.263 0 001.937 1.1H9a1.25 1.25 0 100-2.5H3.537a.25.25 0 01-.219-.37l2.133-3.874a.249.249 0 01.352-.09l.9.568a.507.507 0 00.267.077.5.5 0 00.488-.609L6.6 10.928a.5.5 0 00-.592-.381l-3.941.838a.5.5 0 00-.163.912l1.352.852a.25.25 0 01.086.332L.72 18.25a2.264 2.264 0 00.034 2.231z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});