define("ember-svg-jar/inlined/love-it-phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it-phone</title><path d=\"M17.5 0A5.5 5.5 0 1023 5.5 5.5 5.5 0 0017.5 0zm2.983 5.951l-2.8 2.923a.249.249 0 01-.36 0l-2.8-2.923a1.765 1.765 0 112.5-2.5l.31.31a.25.25 0 00.354 0l.31-.31a1.765 1.765 0 112.5 2.5z\"/><path d=\"M15 13.5a1 1 0 00-2 0V19a.5.5 0 01-.5.5h-9A.5.5 0 013 19V3.5a1 1 0 011-1h6a1 1 0 000-2H4a3 3 0 00-3 3V21a3 3 0 003 3h8a3 3 0 003-3z\"/><path d=\"M10.6 9.271a2.363 2.363 0 10-2.363 2.363A2.363 2.363 0 0010.6 9.271zM4.564 15.173a.25.25 0 00.236.327h6.87a.25.25 0 00.238-.327 3.857 3.857 0 00-7.346 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});