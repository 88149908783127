define("ember-svg-jar/inlined/baggage-leave", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baggage-leave</title><circle cx=\"8.5\" cy=\"2.75\" r=\"2.5\"/><path d=\"M12.442 12.662a1.5 1.5 0 00-1.03-1.854L8.7 10.031a.5.5 0 01-.3-.231L6.8 7.006a1.5 1.5 0 00-2.626.038l-4 7.5A1.5 1.5 0 000 15.25v7a1.5 1.5 0 003 0v-6.625l2.545-4.772.249.436a3.488 3.488 0 002.077 1.627l2.717.776a1.5 1.5 0 001.854-1.03zM23 10.25h-1.25v-1a2.75 2.75 0 00-5.5 0v1H15a1 1 0 00-1 1v5a1 1 0 001 1h8a1 1 0 001-1v-5a1 1 0 00-1-1zm-4.75-1a.75.75 0 011.5 0v1h-1.5zM23.5 18.75h-11a2.5 2.5 0 000 5h11a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5zM13 21.25a.75.75 0 11.75.75.75.75 0 01-.75-.75zm3.5 0a.75.75 0 11.75.75.75.75 0 01-.75-.75zm3.5 0a.75.75 0 11.75.75.75.75 0 01-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});