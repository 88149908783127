define("ember-svg-jar/inlined/astrology-libra", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astrology-libra</title><path d=\"M23 18.25H1a1 1 0 000 2h22a1 1 0 000-2zM1 15.25h5a1.3 1.3 0 00.7-.113 1 1 0 00.424-1.349A5.437 5.437 0 016.5 11.25a5.5 5.5 0 0111 0 5.437 5.437 0 01-.619 2.538 1 1 0 00.424 1.349 1.275 1.275 0 00.7.113h5a1 1 0 000-2H19.54a.25.25 0 01-.243-.307 7.417 7.417 0 00.2-1.693 7.5 7.5 0 00-15 0 7.417 7.417 0 00.2 1.693.25.25 0 01-.243.307H1a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});