define("ember-svg-jar/inlined/iso", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>iso</title><path d=\"M16 9.626a.876.876 0 00-.875.875v3a.875.875 0 001.75 0v-3A.876.876 0 0016 9.626z\"/><path d=\"M22.478 4.5H1.522A1.524 1.524 0 000 6.023v11.955A1.524 1.524 0 001.522 19.5h20.956A1.524 1.524 0 0024 17.978V6.023A1.524 1.524 0 0022.478 4.5zm-15.1 9.875H7.5a.625.625 0 010 1.25h-2a.625.625 0 110-1.25h.125a.25.25 0 00.25-.25V9.876a.25.25 0 00-.25-.25H5.5a.625.625 0 010-1.25h2a.625.625 0 010 1.25h-.125a.25.25 0 00-.25.25v4.25a.25.25 0 00.25.25zm3.052-3.508l1.84 1.226a1.928 1.928 0 01-1.07 3.532H9.5a.625.625 0 010-1.25h1.7a.678.678 0 00.376-1.242l-1.84-1.226A1.928 1.928 0 0110.8 8.376h1.7a.625.625 0 010 1.25h-1.7a.678.678 0 00-.376 1.242zm7.7 2.633a2.125 2.125 0 01-4.25 0v-3a2.125 2.125 0 114.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});