define("ember-svg-jar/inlined/earth-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earth-search</title><path d=\"M12 22a9.924 9.924 0 01-4.36-1.012.251.251 0 01-.14-.225v-1.15a2.492 2.492 0 01.893-1.913 4.516 4.516 0 001.119-1.435 6.969 6.969 0 01.461-2.277A4.492 4.492 0 005.5 9.75H2.584a.25.25 0 01-.241-.314 9.982 9.982 0 0114.546-6.154.25.25 0 01-.122.468H14.25a2.75 2.75 0 000 5.5 2.588 2.588 0 011.253.329 6.922 6.922 0 017.431 4.174.5.5 0 00.955-.128 12 12 0 10-16.548 9.434l.159.078v-.02a11.919 11.919 0 006.125.772.5.5 0 00.128-.955 7.047 7.047 0 01-1.445-.834.492.492 0 00-.3-.1z\"/><path d=\"M21.362 19.949a.5.5 0 01-.076-.609 5.539 5.539 0 10-1.946 1.946.5.5 0 01.609.077l2.344 2.344a1 1 0 001.414-1.414zM13 16.529a3.529 3.529 0 113.529 3.529A3.533 3.533 0 0113 16.529z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});