define("ember-svg-jar/inlined/hospital-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hospital-shield</title><path d=\"M24 1.953A1.959 1.959 0 0022.043.006H1.96A1.96 1.96 0 00.012 1.964L0 9.306a15.144 15.144 0 0011.861 14.668.976.976 0 00.4 0A15.144 15.144 0 0023.988 9.2zm-5.5 10.5a.65.65 0 01-.65.65H15.1a.5.5 0 00-.5.5v2.75a.65.65 0 01-.65.65h-3.9a.65.65 0 01-.65-.65v-2.75a.5.5 0 00-.5-.5H6.15a.65.65 0 01-.65-.65v-3.9a.65.65 0 01.65-.65H8.9a.5.5 0 00.5-.5V4.656a.65.65 0 01.65-.65h3.9a.65.65 0 01.65.65v2.75a.5.5 0 00.5.5h2.75a.65.65 0 01.65.65z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});