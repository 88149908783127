define("ember-svg-jar/inlined/ticket-person-pass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ticket-person-pass</title><circle cx=\"11.75\" cy=\"3\" r=\"2.5\"/><path d=\"M15.75 9a2 2 0 00-2-2h-4a2 2 0 00-2 2v5a1 1 0 001 1h.75a.25.25 0 01.25.25V22a1.5 1.5 0 001.5 1.5h1a1.5 1.5 0 001.5-1.5v-6.75A.25.25 0 0114 15h.75a1 1 0 001-1zM2.25 15a.5.5 0 01.309-.462.507.507 0 01.545.108l1.75 1.75a.5.5 0 010 .708L3.1 18.854a.5.5 0 01-.85-.354V18a.25.25 0 00-.25-.25H.25A.25.25 0 000 18v5a.5.5 0 00.5.5H6a.5.5 0 00.5-.5V10.5A.5.5 0 006 10H.5a.5.5 0 00-.5.5v5a.25.25 0 00.25.25H2a.25.25 0 00.25-.25zM21.75 18.5a.5.5 0 01-.309.462.5.5 0 01-.545-.108l-1.75-1.75a.5.5 0 010-.708l1.75-1.75a.505.505 0 01.545-.108.5.5 0 01.309.462v.5a.25.25 0 00.25.25h1.75a.25.25 0 00.25-.25v-5a.5.5 0 00-.5-.5H18a.5.5 0 00-.5.5V23a.5.5 0 00.5.5h5.5a.5.5 0 00.5-.5v-5a.25.25 0 00-.25-.25H22a.25.25 0 00-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});