define("ember-svg-jar/inlined/credit-card-dollar-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-dollar-1</title><path d=\"M20 2a2 2 0 00-2-2H2a2 2 0 00-2 2v1a.25.25 0 00.25.25h19.5A.25.25 0 0020 3zM17.5 9.5a7.976 7.976 0 012.182.306.25.25 0 00.318-.24V5a.25.25 0 00-.25-.25H.25A.25.25 0 000 5v8a2 2 0 002 2h7.725a.25.25 0 00.236-.167A8 8 0 0117.5 9.5zM3 7.25h6.5a.75.75 0 010 1.5H3a.75.75 0 010-1.5zm3 5H3a.75.75 0 010-1.5h3a.75.75 0 010 1.5z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.5 6.5 0 00-6.5-6.5zm-1.084 5.294l2.71 1.015a2.079 2.079 0 01-.665 3.906.249.249 0 00-.211.247V22a.75.75 0 01-1.5 0v-.5a.25.25 0 00-.25-.25h-1a.75.75 0 010-1.5H18c.5 0 .75-.173.75-.514a.745.745 0 00-.166-.53l-2.71-1.015a1.99 1.99 0 01-1.124-1.927 2.057 2.057 0 011.794-1.974.25.25 0 00.206-.246V13a.75.75 0 011.5 0v.5a.25.25 0 00.25.25h1a.75.75 0 010 1.5H17c-.406 0-.75.235-.75.514a.745.745 0 00.166.53z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});