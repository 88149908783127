define("ember-svg-jar/inlined/style-three-pin-charging-cable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-charging-cable</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM7.5 7a.5.5 0 01.5-.5h.75A.25.25 0 009 6.25V4a.75.75 0 011.5 0v2.25a.25.25 0 00.25.25h2.5a.25.25 0 00.25-.25V4A.75.75 0 0115 4v2.25a.25.25 0 00.25.25H16a.5.5 0 01.5.5v1.25a.25.25 0 01-.25.25h-8.5a.25.25 0 01-.25-.25zm9 3a4.5 4.5 0 01-3.375 4.344.5.5 0 00-.375.484V17a.75.75 0 01-1.5 0v-2.172a.5.5 0 00-.375-.484A4.5 4.5 0 017.5 10v-.25a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});