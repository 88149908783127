define("ember-svg-jar/inlined/chef-gear-hat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chef-gear-hat</title><path d=\"M6.208 20a.5.5 0 00-.5.549l.205 2.1A1.494 1.494 0 007.407 24h9.186a1.494 1.494 0 001.492-1.354l.2-2.1a.5.5 0 00-.5-.549zM22.846 8.19a4.3 4.3 0 00-2.335-2.768 4.432 4.432 0 00-1.767-.431.5.5 0 01-.466-.353 6.568 6.568 0 00-12.556 0 .5.5 0 01-.466.353 4.428 4.428 0 00-1.767.431 4.3 4.3 0 00-2.335 2.769 4.389 4.389 0 00.746 3.824 4.448 4.448 0 002.77 1.663.5.5 0 01.411.444l.383 3.927a.5.5 0 00.5.451h12.072a.5.5 0 00.5-.451l.383-3.927a.5.5 0 01.411-.444 4.448 4.448 0 002.77-1.663 4.39 4.39 0 00.746-3.825z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});