define("ember-svg-jar/inlined/tennis-net", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tennis-net</title><path d=\"M7.889 15.75h8.225a.5.5 0 00.49-.6l-.342-1.75a.5.5 0 00-.49-.4h-7.54a.5.5 0 00-.491.4L7.4 15.154a.5.5 0 00.491.6zM10.754 17.25H7.4a.5.5 0 00-.49.4L5.881 22.9a.5.5 0 00.49.6h4.383a.5.5 0 00.5-.5v-5.25a.5.5 0 00-.5-.5zM6.07 13.181A.5.5 0 005.685 13H3.907a.5.5 0 00-.486.384L1.3 22.267a1 1 0 00.967 1.233h1.557a.5.5 0 00.491-.4l1.86-9.5a.5.5 0 00-.105-.419zM17.091 17.654a.5.5 0 00-.49-.4h-3.355a.5.5 0 00-.5.5V23a.5.5 0 00.5.5h4.383a.5.5 0 00.49-.6zM20.579 13.383a.5.5 0 00-.486-.383h-1.778a.5.5 0 00-.49.6l1.86 9.5a.5.5 0 00.491.4h1.557a1 1 0 00.972-1.233zM5.87 5.5h1.408a.5.5 0 00.491-.4l.783-4a.5.5 0 00-.49-.6h-.84a1 1 0 00-.972.767l-.866 3.617a.5.5 0 00.486.616zM16.231 5.1a.5.5 0 00.491.4h1.408a.5.5 0 00.486-.616l-.866-3.617A1 1 0 0016.778.5h-.84a.5.5 0 00-.49.6zM22.75 6.75H1.25A1.252 1.252 0 000 8v4a.75.75 0 001.5 0v-.5A.5.5 0 012 11h20a.5.5 0 01.5.5v.5a.75.75 0 001.5 0V8a1.252 1.252 0 00-1.25-1.25zM10.462 2.25h3.076a.5.5 0 00.491-.6L13.882.9a.5.5 0 00-.491-.4h-2.782a.5.5 0 00-.491.4l-.147.75a.5.5 0 00.491.6zM9.825 5.5h4.35a.5.5 0 00.491-.6l-.147-.752a.5.5 0 00-.491-.4H9.972a.5.5 0 00-.491.4l-.147.752a.5.5 0 00.491.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});