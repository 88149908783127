define("ember-svg-jar/inlined/mobile-shopping-shop-basket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mobile-shopping-shop-basket</title><path d=\"M20.947 4.276L19.224.83A1.491 1.491 0 0017.882 0H6.118a1.492 1.492 0 00-1.342.829L3.053 4.276A.512.512 0 003 4.5v1a3.538 3.538 0 002.151 3.241 3.158 3.158 0 003.082-.369.249.249 0 01.328.039 4.678 4.678 0 003.445 1.581 4.669 4.669 0 003.433-1.581.249.249 0 01.328-.039 3.152 3.152 0 003.082.369A3.538 3.538 0 0021 5.5v-1a.512.512 0 00-.053-.224zM10.987 11.539a.746.746 0 00-.949.474L9.6 13.329a.25.25 0 01-.237.171H9.13a.5.5 0 00-.46.7l1.072 3a.5.5 0 00.459.3h3.578a.5.5 0 00.47-.329l1.091-3a.5.5 0 00-.47-.671h-.232a.25.25 0 01-.237-.171l-.439-1.316a.75.75 0 00-1.424.474l.228.684a.25.25 0 01-.237.329h-1.058a.25.25 0 01-.237-.329l.228-.684a.749.749 0 00-.475-.948z\"/><path d=\"M18 10a1 1 0 00-1 1v7.5a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5V11a1 1 0 00-2 0v10.5A2.5 2.5 0 007.5 24h9a2.5 2.5 0 002.5-2.5V11a1 1 0 00-1-1zm-5 11.5a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});