define("ember-svg-jar/inlined/natural-disaster-volcano-smoke", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>natural-disaster-volcano-smoke</title><path d=\"M23.21 22.8c-2.825-1.307-7.3-3.867-7.684-7.1a.5.5 0 00-.5-.441H8.971a.5.5 0 00-.5.441c-.38 3.238-4.859 5.8-7.684 7.1a.5.5 0 00.213.95h22a.5.5 0 00.21-.954zM1.316 9.449a2.507 2.507 0 001.895.2.251.251 0 01.273.092c1.227 1.679 4.359 1.825 5.941 1.117a.251.251 0 01.192 0 5.33 5.33 0 00.6.186.25.25 0 01.168.144 1.451 1.451 0 01.119.571v1.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1.5a1.451 1.451 0 01.119-.571.25.25 0 01.168-.144 5.2 5.2 0 00.6-.186.251.251 0 01.192 0c1.586.71 4.716.559 5.941-1.117a.251.251 0 01.273-.092 2.508 2.508 0 001.895-.2 2.491 2.491 0 00-1.173-4.688h-.011a2.244 2.244 0 00-2.186 1.714.75.75 0 01-1.457-.354 3.73 3.73 0 011.536-2.212.25.25 0 00-.022-.427 4.967 4.967 0 00-2.1-.561.249.249 0 01-.194-.109A6.13 6.13 0 0012 .25a6.131 6.131 0 00-5.074 2.564.249.249 0 01-.194.109 4.967 4.967 0 00-2.1.561.251.251 0 00-.022.428 3.731 3.731 0 011.537 2.21.75.75 0 01-1.457.356A2.245 2.245 0 002.5 4.763h-.011a2.491 2.491 0 00-1.173 4.686z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});