define("ember-svg-jar/inlined/smiley-petrified-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-petrified-alternate</title><path d=\"M13.365 21.908a10.179 10.179 0 01-3.184-.073 1 1 0 00-.362 1.965A12.045 12.045 0 0012 24a12.224 12.224 0 001.635-.11 1 1 0 00-.27-1.982zM1 13a1 1 0 001-1 10 10 0 0120 0 1 1 0 002 0 12 12 0 00-24 0 1 1 0 001 1zM12 19a2 2 0 002-2v-1.6a2 2 0 10-4 0V17a2 2 0 002 2z\"/><path d=\"M5 11.519a1.91 1.91 0 00-2.21.274 2.234 2.234 0 00-.366 2.648A10.023 10.023 0 013 18C3 21.174.787 22.014.676 22.054a1 1 0 00.64 1.9A5.186 5.186 0 002.9 23 6.262 6.262 0 005 18a11.9 11.9 0 00-.677-4.191.2.2 0 01.3-.23 4.244 4.244 0 01.621.514A5.78 5.78 0 017 18a17.663 17.663 0 01-.448 4.684 1 1 0 00.632 1.265A1.028 1.028 0 007.5 24a1 1 0 00.948-.684A18.538 18.538 0 009 18a7.541 7.541 0 00-4-6.481zM23.316 22.051C23.293 22.044 21 21.242 21 18a10.023 10.023 0 01.573-3.559 2.234 2.234 0 00-.366-2.648A1.905 1.905 0 0019 11.519 7.541 7.541 0 0015 18a18.538 18.538 0 00.552 5.316A1 1 0 0017.5 23a1.012 1.012 0 00-.048-.31A17.524 17.524 0 0117 18a5.78 5.78 0 011.755-3.907 4.244 4.244 0 01.621-.514.2.2 0 01.3.23A11.9 11.9 0 0019 18a6.262 6.262 0 002.105 5 5.186 5.186 0 001.579.949A1.028 1.028 0 0023 24a1 1 0 00.316-1.949zM11.5 8a3.283 3.283 0 00-3-3.5 3.283 3.283 0 00-3 3.5 2.841 2.841 0 003 3 2.841 2.841 0 003-3zm-3 1c-.887 0-1-.534-1-1 0-.8.468-1.5 1-1.5s1 .7 1 1.5c0 .466-.113 1-1 1zM18.5 8a3.283 3.283 0 00-3-3.5 3.283 3.283 0 00-3 3.5 2.841 2.841 0 003 3 2.841 2.841 0 003-3zm-4 0c0-.8.468-1.5 1-1.5s1 .7 1 1.5c0 .466-.113 1-1 1s-1-.534-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});