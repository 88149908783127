define("ember-svg-jar/inlined/glasses-retro-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>glasses-retro-1</title><path d=\"M18.75 6.75a5.22 5.22 0 00-3.117 1.028.5.5 0 01-.6 0 5 5 0 00-6.064 0 .5.5 0 01-.6 0 5.246 5.246 0 101.7 2.147.5.5 0 01.165-.6 3 3 0 013.526 0 .5.5 0 01.165.6A5.25 5.25 0 1018.75 6.75zm-13.5 8.5A3.25 3.25 0 118.5 12a3.254 3.254 0 01-3.25 3.25zm13.5 0A3.25 3.25 0 1122 12a3.254 3.254 0 01-3.25 3.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});