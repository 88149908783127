define("ember-svg-jar/inlined/police-woman-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-woman-2</title><path d=\"M.5 23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-2.106a2.464 2.464 0 00-.967-1.977C21.105 18.327 17.838 16.5 12 16.5s-9.1 1.827-10.534 2.917A2.466 2.466 0 00.5 21.394zm14.25-3.07v-.93a.25.25 0 01.25-.25h3a.25.25 0 01.25.25v.93a2.246 2.246 0 01-1 1.872l-.609.406a.251.251 0 01-.278 0l-.609-.406a2.246 2.246 0 01-1.004-1.872zM19.345 5.75a.014.014 0 00.012-.006.016.016 0 000-.013 7.593 7.593 0 00-14.64-.3.25.25 0 00.24.32zm-7.187-.775a.512.512 0 01-.316 0A2.978 2.978 0 0110 2a.5.5 0 01.5-.5h3a.5.5 0 01.5.5 2.978 2.978 0 01-1.842 2.975zM13 11.861h-2a.64.64 0 00-.3 1.2 2.4 2.4 0 00.436.18 2.255 2.255 0 00.864.129h.019a2.966 2.966 0 00.852-.126 2.47 2.47 0 00.431-.179.64.64 0 00-.3-1.2z\"/><path d=\"M17.146 15.838a5.4 5.4 0 001.317.162 3.8 3.8 0 002.921-1.12.5.5 0 00-.231-.8c-2.411-.774-2.209-4.143-2.16-4.887A.247.247 0 0119.11 9a1.392 1.392 0 00.454-.44 1.493 1.493 0 00.178-1.154.206.206 0 00-.2-.156H4.458a.206.206 0 00-.2.156 1.493 1.493 0 00.178 1.154 1.366 1.366 0 00.455.44.252.252 0 01.117.2c.052.731.265 4.048-2.161 4.828a.5.5 0 00-.231.8 3.8 3.8 0 002.921 1.12 5.351 5.351 0 001.317-.161 3.461 3.461 0 001.029-.436.25.25 0 01.273 0 6.97 6.97 0 007.643.032.252.252 0 01.275 0 3.478 3.478 0 001.072.455zM7.018 9.866a.251.251 0 01.081-.2.248.248 0 01.208-.066A29.94 29.94 0 0012 10a29.667 29.667 0 004.692-.4.251.251 0 01.29.265 5 5 0 01-9.964 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});