define("ember-svg-jar/inlined/bear-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bear-body</title><path d=\"M.182 18.792a1.742 1.742 0 001.726 1.458H3.6a.748.748 0 00.673-.422 24.973 24.973 0 01-3.861-6.866 15.043 15.043 0 00-.23 5.83zM22.534 10.224a41.199 41.199 0 01-.367-.269C21.669 7.453 18.092 3.5 5.6 3.5a4.951 4.951 0 00-5 5c0 5.819 5.045 11.437 5.26 11.673a1 1 0 00.74.327h4a1 1 0 001-1v-.719a2 2 0 00-1.515-1.94l-.2-.049.43-1.292h2.727l1.5 3.744A1.992 1.992 0 0016.4 20.5h3.823a1 1 0 001-1v-.719a2 2 0 00-1.515-1.94l-.606-.153L18.9 15.5h1.643a2.3 2.3 0 002.108-1.17c.074-.1.54-.687.54-.687.491-.6.88-1.075.794-1.739-.085-.689-.6-1.062-1.451-1.68zM19 11a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});