define("ember-svg-jar/inlined/hierarchy-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hierarchy-2</title><path d=\"M22.5 12.5h-3.251a.25.25 0 01-.25-.25V10a2.5 2.5 0 00-2.5-2.5h-8A.5.5 0 018 7V4.249A.25.25 0 018.249 4H12a1.5 1.5 0 001.5-1.5v-1A1.5 1.5 0 0012 0H1.5A1.5 1.5 0 000 1.5v1A1.5 1.5 0 001.5 4h4.249a.25.25 0 01.25.25V7A2.5 2.5 0 008.5 9.5h8a.5.5 0 01.5.5v2.25a.25.25 0 01-.25.25H12a1.5 1.5 0 00-1.5 1.5v1a1.5 1.5 0 001.5 1.5h4.75a.25.25 0 01.25.25v3a.25.25 0 01-.25.25H12a1.5 1.5 0 00-1.5 1.5v1A1.5 1.5 0 0012 24h10.5a1.5 1.5 0 001.5-1.5v-1a1.5 1.5 0 00-1.5-1.5h-3.251a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25H22.5A1.5 1.5 0 0024 15v-1a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});