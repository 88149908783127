define("ember-svg-jar/inlined/candle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>candle</title><path d=\"M16.14 13.083a.986.986 0 00-.824-.213l-7 1.312a1 1 0 00-.816.983v7.585a1 1 0 001 1h7a1 1 0 001-1v-8.9a1 1 0 00-.36-.767zM12 11.25a4.112 4.112 0 004.112-4.111 7.555 7.555 0 00-4.623-6.87.257.257 0 00-.3.393 4.287 4.287 0 01.454 4.138.25.25 0 01-.393.079 2.307 2.307 0 01-.734-1.05.255.255 0 00-.141-.163.264.264 0 00-.215 0 3.708 3.708 0 00-2.272 3.47A4.112 4.112 0 0012 11.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});