define("ember-svg-jar/inlined/religion-cao-dai", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-cao-dai</title><path d=\"M23.712 22.185L12.674 1.07a.744.744 0 00-1.349 0L.289 22.184a.806.806 0 000 .8.807.807 0 00.711.376h22a.774.774 0 00.712-1.175zm-5.193-6.066A7.687 7.687 0 0112 19.859a7.687 7.687 0 01-6.519-3.74.5.5 0 010-.52A7.689 7.689 0 0112 11.859a7.685 7.685 0 016.518 3.74.5.5 0 01.001.52z\"/><circle cx=\"12\" cy=\"15.859\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});