define("ember-svg-jar/inlined/remove-bold-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>remove-bold-alternate</title><path d=\"M18.186 12.176a.25.25 0 010-.353l3.889-3.888a2.255 2.255 0 000-3.183l-2.829-2.83a2.255 2.255 0 00-3.182 0l-3.889 3.89a.251.251 0 01-.354 0L7.933 1.923a2.253 2.253 0 00-3.182 0L1.923 4.752a2.252 2.252 0 000 3.182l3.889 3.889a.249.249 0 010 .353l-3.889 3.89a2.252 2.252 0 000 3.182l2.827 2.827a2.309 2.309 0 003.183 0l3.889-3.889a.25.25 0 01.354 0l3.888 3.889a2.254 2.254 0 003.183 0l2.828-2.828a2.252 2.252 0 000-3.182zm1.591 5.834l-1.768 1.768a.5.5 0 01-.707 0l-4.419-4.419a1.251 1.251 0 00-1.768 0L6.7 19.778a.5.5 0 01-.707 0L4.221 18.01a.5.5 0 010-.707l4.419-4.42a1.249 1.249 0 000-1.767L4.221 6.7a.5.5 0 010-.707l1.768-1.772a.5.5 0 01.707 0l4.419 4.42a1.254 1.254 0 001.768 0l4.417-4.42a.5.5 0 01.707 0l1.77 1.769a.5.5 0 010 .707l-4.419 4.419a1.249 1.249 0 000 1.767l4.419 4.42a.5.5 0 010 .707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});