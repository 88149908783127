define("ember-svg-jar/inlined/business-deal-women-cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-deal-women-cash</title><path d=\"M.747 18.605A1 1 0 001.2 20.5a.989.989 0 00.446-.105 3.208 3.208 0 001.065-.825.25.25 0 01.352-.035c.049.041.1.08.149.118a.25.25 0 01-.041.425 5.922 5.922 0 00-2.944 3.244.5.5 0 00.467.678h10a.5.5 0 00.467-.679 5.937 5.937 0 00-2.876-3.21.25.25 0 01-.036-.425c.066-.048.131-.1.194-.15a.25.25 0 01.352.034 3.18 3.18 0 001.064.825.989.989 0 00.446.105 1 1 0 00.448-1.895C9.949 18.2 10 17.25 10 16.25a4.25 4.25 0 00-8.5 0c0 1 .052 1.95-.753 2.355zm2.969-2.247c1.092-.294 1.978-.973 1.978-1.358 0 .4.942 1.107 2.079 1.385a.249.249 0 01.187.289 2.249 2.249 0 01-4.424-.03.251.251 0 01.18-.286zM18.25 12A4.255 4.255 0 0014 16.25c0 1 .052 1.953-.753 2.355A1 1 0 0013.7 20.5a.989.989 0 00.446-.105 3.208 3.208 0 001.065-.825.25.25 0 01.352-.035c.049.041.1.08.149.118a.25.25 0 01-.041.425 5.922 5.922 0 00-2.939 3.244.5.5 0 00.467.678h10a.5.5 0 00.467-.679 5.937 5.937 0 00-2.876-3.21.25.25 0 01-.036-.425c.066-.048.131-.1.194-.15a.25.25 0 01.352.034 3.18 3.18 0 001.064.825.989.989 0 00.446.105 1 1 0 00.448-1.895c-.8-.4-.753-1.355-.753-2.355A4.255 4.255 0 0018.25 12zm0 6.5a2.252 2.252 0 01-2.217-1.874.249.249 0 01.178-.282c1.068-.3 1.927-.965 1.927-1.344 0 .4.97 1.128 2.13 1.4a.251.251 0 01.189.292A2.254 2.254 0 0118.25 18.5z\"/><path d=\"M18.694 0h-14a1.5 1.5 0 00-1.5 1.5v8a1.5 1.5 0 001.5 1.5h6.25a.25.25 0 01.25.25v2.25a.5.5 0 00.853.354l2.781-2.784a.249.249 0 01.177-.07h3.689a1.5 1.5 0 001.5-1.5v-8a1.5 1.5 0 00-1.5-1.5zm-8.167 4.875h2.334a1.792 1.792 0 010 3.584h-.292a.249.249 0 00-.25.25V9a.625.625 0 01-1.25 0v-.291a.249.249 0 00-.25-.25H9.361a.625.625 0 010-1.25h3.5a.542.542 0 000-1.084h-2.334a1.792 1.792 0 010-3.583h.292a.25.25 0 00.25-.25V2a.625.625 0 011.25 0v.292a.25.25 0 00.25.25h1.458a.625.625 0 010 1.25h-3.5a.542.542 0 000 1.083z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});