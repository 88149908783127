define("ember-svg-jar/inlined/server-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-2</title><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v2.5A1.5 1.5 0 001.5 10h21A1.5 1.5 0 0024 8.5V6a1.5 1.5 0 00-1.5-1.5zM3.25 7.25A1.25 1.25 0 114.5 8.5a1.251 1.251 0 01-1.25-1.25zM8.5 8.5a1.25 1.25 0 111.25-1.25A1.252 1.252 0 018.5 8.5zM22.724 2.052L18.829.105a1 1 0 00-.447-.1H5.618a1 1 0 00-.447.105l-3.9 1.947A.5.5 0 001.5 3h21a.5.5 0 00.224-.948zM22.5 11.5h-21A1.5 1.5 0 000 13v2.5A1.5 1.5 0 001.5 17h21a1.5 1.5 0 001.5-1.5V13a1.5 1.5 0 00-1.5-1.5zM3.25 14.25A1.25 1.25 0 114.5 15.5a1.251 1.251 0 01-1.25-1.25zM8.5 15.5a1.25 1.25 0 111.25-1.25A1.252 1.252 0 018.5 15.5zM22.5 18.5h-21A1.5 1.5 0 000 20v2.5A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5V20a1.5 1.5 0 00-1.5-1.5zM3.25 21.25A1.25 1.25 0 114.5 22.5a1.251 1.251 0 01-1.25-1.25zM8.5 22.5a1.25 1.25 0 111.25-1.25A1.252 1.252 0 018.5 22.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});