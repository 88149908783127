define("ember-svg-jar/inlined/crypto-currency-dogecoin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-dogecoin</title><path d=\"M13 7.75h-3a.25.25 0 00-.25.25v8a.25.25 0 00.25.25h3a4.25 4.25 0 000-8.5z\"/><path d=\"M12 1a11 11 0 1011 11A11 11 0 0012 1zM6.5 17.75a.75.75 0 010-1.5H8a.25.25 0 00.25-.25V8A.25.25 0 008 7.75H6.5a.75.75 0 010-1.5H13a5.75 5.75 0 010 11.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});