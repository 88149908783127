define("ember-svg-jar/inlined/messages-people-user-bubble-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-user-bubble-square</title><path d=\"M22 0H9a2 2 0 00-2 2v8a2 2 0 002 2h1v2.5a1 1 0 001.707.707L14.915 12H22a2 2 0 002-2V2a2 2 0 00-2-2zm0 9.75a.25.25 0 01-.25.25H14.5a1 1 0 00-.707.293L12 12.086V11a1 1 0 00-1-1H9.251A.25.25 0 019 9.75v-7.5A.25.25 0 019.251 2h12.5a.25.25 0 01.25.25z\"/><path d=\"M18 6.75h-7a.75.75 0 000 1.5h7a.75.75 0 000-1.5zM20 3.745h-9a.75.75 0 000 1.5h9a.75.75 0 000-1.5zM3.505 13a2.75 2.75 0 10-.01 0A3.5 3.5 0 000 16.5v2a.5.5 0 00.5.5h.821a.25.25 0 01.249.225L2 23.55a.5.5 0 00.5.45h2a.5.5 0 00.5-.45l.433-4.325A.25.25 0 015.679 19H6.5a.5.5 0 00.5-.5v-2A3.5 3.5 0 003.505 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});