define("ember-svg-jar/inlined/science-perspective-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-perspective-alternate</title><path d=\"M23.967 21.643l-1.636-9A2 2 0 0020.364 11h-4.071a.25.25 0 01-.235-.334A1 1 0 0117 10h4.148a2 2 0 001.923-2.55l-1.429-5A2.007 2.007 0 0019.719 1h-1.568a1 1 0 000 2h1.19a.5.5 0 01.481.363l1.144 4a.5.5 0 01-.481.637H3.6a.5.5 0 01-.471-.668l1.429-4A.5.5 0 015.031 3h1.12a1 1 0 000-2H4.678a2.006 2.006 0 00-1.884 1.327l-1.785 5A2 2 0 002.892 10H7a1 1 0 01.943.666.25.25 0 01-.235.334H4.046A1.991 1.991 0 002.1 12.557l-2.044 9A2 2 0 002 24h20a2 2 0 001.967-2.357zM9.966 10.1a.252.252 0 01.2-.1h3.675a.25.25 0 01.243.308 3.035 3.035 0 00-.081.708.249.249 0 01-.17.236A5.9 5.9 0 0112 11.5a5.906 5.906 0 01-1.83-.248.249.249 0 01-.17-.236 2.993 2.993 0 00-.082-.708.254.254 0 01.048-.208zm-.057 3.437a.25.25 0 01.3-.2 9.612 9.612 0 003.589 0 .25.25 0 01.3.2 5.97 5.97 0 00.414 1.355.246.246 0 010 .209.25.25 0 01-.16.134A9.3 9.3 0 0112 15.5a9.307 9.307 0 01-2.342-.27.251.251 0 01-.163-.343 5.97 5.97 0 00.414-1.355zm-1.437 3.39A9.73 9.73 0 0012 17.5a9.718 9.718 0 003.534-.575.5.5 0 01.534.079 6.068 6.068 0 001.3.864.25.25 0 01-.037.463A19.3 19.3 0 0112 19a19.245 19.245 0 01-5.312-.666.25.25 0 01-.038-.463 6.023 6.023 0 001.287-.863.5.5 0 01.535-.081zm13.313 4.893a.5.5 0 01-.385.18H2.628a.5.5 0 01-.488-.611l1.818-8A.5.5 0 014.445 13h3.227a.25.25 0 01.244.3 4.052 4.052 0 01-2.541 2.946 2 2 0 00-1.309 1.865 1.97 1.97 0 001.252 1.863A19.271 19.271 0 0012 21a19.277 19.277 0 006.683-1.024 1.97 1.97 0 001.252-1.863 2 2 0 00-1.31-1.865 4.054 4.054 0 01-2.541-2.948.253.253 0 01.051-.209.248.248 0 01.194-.091h3.618a.5.5 0 01.492.411l1.454 8a.5.5 0 01-.108.409z\"/><path d=\"M10.514 4.961a17.139 17.139 0 01-2.477-.288 1.989 1.989 0 01-.889-.435A1 1 0 005.5 5c0 .5 0 2 6.5 2s6.5-1.5 6.5-2a1 1 0 00-1.647-.762 2 2 0 01-.889.435 17.139 17.139 0 01-2.477.288A.463.463 0 0113 4.5a.5.5 0 01.5-.5h.5a1 1 0 00.707-1.707l-2-2a1 1 0 00-1.414 0l-2 2A1 1 0 0010 4h.5a.5.5 0 01.5.5.462.462 0 01-.486.461z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});