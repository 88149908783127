define("ember-svg-jar/inlined/performance-user-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>performance-user-graph</title><path d=\"M24 2a2 2 0 00-2-2H10a1 1 0 000 2h11.75a.25.25 0 01.25.25v10a.249.249 0 01-.25.25H12a1 1 0 000 2h.75a.25.25 0 01.25.25V18a.206.206 0 01-.061.146l-2.146 2.147a1 1 0 001.414 1.414l1.616-1.616a.25.25 0 01.354 0l1.616 1.616a1 1 0 001.414-1.414l-2.146-2.147A.206.206 0 0115 18v-3.25a.25.25 0 01.25-.25H22a2 2 0 002-2z\"/><path d=\"M15.177 7.409a.25.25 0 01-.354 0l-.616-.616a1 1 0 00-1.414 0l-2 2a1 1 0 101.414 1.414l1.116-1.116a.25.25 0 01.354 0l.616.616a1 1 0 001.414 0l2.47-2.47a.25.25 0 01.353 0l.616.617A.5.5 0 0019.5 8a.489.489 0 00.191-.038A.5.5 0 0020 7.5v-3a.5.5 0 00-.5-.5h-3a.5.5 0 00-.354.854l.617.616a.25.25 0 010 .353zM0 16.378a.5.5 0 00.5.5h1.458a.251.251 0 01.249.226l.646 6.447a.5.5 0 00.5.45H6.2a.5.5 0 00.5-.45l.641-6.451a.251.251 0 01.249-.226h1.459a.5.5 0 00.5-.5v-2.847a4.776 4.776 0 00-2.482-4.185.251.251 0 01-.011-.432 4.081 4.081 0 10-4.372-.077.25.25 0 01-.027.432A4.761 4.761 0 000 13.527zm5.524-3.66a.75.75 0 01-1.5 0v-1.692a.75.75 0 011.5 0zM2.943 4.853a.25.25 0 01.368-.139 5.073 5.073 0 002.639.737 5.061 5.061 0 00.944-.088.1.1 0 01.074.02.088.088 0 01.032.068 2.075 2.075 0 01-4.15 0 1.969 1.969 0 01.093-.598z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});