define("ember-svg-jar/inlined/gorilla-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gorilla-2</title><path d=\"M22.579 7.444A2.271 2.271 0 0023 6a1.943 1.943 0 00-2-2h-.007c-.06-1.99-.537-3-2.493-3a3.939 3.939 0 00-3.03 1.249 6.967 6.967 0 00-1.478 4.3c3.5.517 5.815 5.654 6.172 6.45H21.5a3.152 3.152 0 002.44-2.4A3 3 0 0024 10a2.968 2.968 0 00-1.421-2.556zM18.509 13H18.5c-.976-1.99-2.917-5-5.12-5-2.5 0-4 4-4 4h-4c-3.82 0-5 4.45-5.36 7.69A2.988 2.988 0 003 23h3.88a1 1 0 000-2h-1a10.145 10.145 0 00.25-2 17.3 17.3 0 006.77-1.034c-.178-.726-.4-1.845-.638-1.924a.75.75 0 01.47-1.424c1.124.371 1.325 1.747 1.606 2.915A2.058 2.058 0 0016.379 19a.979.979 0 011 1c0 1-1.5 1-1.5 1a1 1 0 00-1 1v.5a.5.5 0 00.5.5h3.38a2.018 2.018 0 001.99-2.26 24.44 24.44 0 00-2.24-7.74z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});