define("ember-svg-jar/inlined/wifi-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-question</title><path d=\"M17.623 11.024a6.361 6.361 0 106.362 6.361 6.368 6.368 0 00-6.362-6.361zm.977 10.268a.977.977 0 11-.977-.976.977.977 0 01.977.976zm1.6-5.5a2.6 2.6 0 01-1.689 2.423.218.218 0 00-.143.205v.181a.75.75 0 01-1.5 0v-.54a1.246 1.246 0 01.978-1.211 1.082 1.082 0 10-1.304-1.05.75.75 0 01-1.5 0 2.582 2.582 0 115.163 0zM15.143 8.663a1 1 0 001.414-1.414 9.75 9.75 0 00-13.775 0A1 1 0 004.2 8.663a7.751 7.751 0 0110.943 0z\"/><path d=\"M17.615 5.537a1 1 0 001.414-1.414 13.255 13.255 0 00-18.721 0 1 1 0 001.414 1.414 11.254 11.254 0 0115.893 0zM13.425 11.02a1.012 1.012 0 00.151-.362 1 1 0 00-.421-1.026 6.253 6.253 0 00-7.9.767 1 1 0 001.415 1.414 4.251 4.251 0 015.371-.523.963.963 0 00.453.152.986.986 0 00.931-.422z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});