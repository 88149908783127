define("ember-svg-jar/inlined/warehouse-cart-packages-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>warehouse-cart-packages-1</title><circle cx=\"6\" cy=\"22.125\" r=\"1.75\"/><circle cx=\"19.5\" cy=\"22.125\" r=\"1.75\"/><path d=\"M22.75 4.875a2 2 0 00-2-2H10.229a.249.249 0 01-.249-.241A2.6 2.6 0 008.537.4a2.621 2.621 0 00-2.623.164l-.714.479a1 1 0 001.11 1.664l.717-.479a.619.619 0 01.944.663l-.174.695v.018L7.253 5.2a.25.25 0 01-.237.171H5.75a1 1 0 000 2h.433a.25.25 0 01.237.329l-.833 2.5a.25.25 0 01-.237.171h-1.6a1 1 0 000 2h.766a.25.25 0 01.2.1.248.248 0 01.034.225L3.92 15.2a.25.25 0 01-.237.171H2.25a1 1 0 000 2h.6a.25.25 0 01.237.329l-.066.2a1.5 1.5 0 001.422 1.974H20.75a2 2 0 002-2zm-2 12.75a.25.25 0 01-.25.25H20a.25.25 0 01-.25-.25v-6.25a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v6.25a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25v-4.25a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5v4.25a.25.25 0 01-.25.25H5.485a.248.248 0 01-.2-.1.251.251 0 01-.035-.225l4.167-12.5a.25.25 0 01.237-.171H20.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});