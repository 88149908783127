define("ember-svg-jar/inlined/phone-action-data-transfer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-data-transfer</title><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/><path d=\"M11.5 11.5h-1V8a1 1 0 00-2 0v3.5h-1a.5.5 0 00-.354.854l2 2a.5.5 0 00.708 0l2-2a.5.5 0 00-.354-.854zM14.854 7.646a.5.5 0 00-.708 0l-2 2a.5.5 0 00.354.854h1V14a1 1 0 002 0v-3.5h1a.5.5 0 00.354-.854z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});