define("ember-svg-jar/inlined/people-man-moustache-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-moustache-1</title><path d=\"M.517 23.5a.5.5 0 00.5.5h10.109a.25.25 0 00.25-.25v-4.279a.592.592 0 01.057-.254.247.247 0 000-.214.25.25 0 00-.165-.136 3 3 0 01-2.121-1.994.248.248 0 00-.267-.173 15.2 15.2 0 00-7.4 2.722A2.463 2.463 0 00.517 21.4zM12.731 18.867a.249.249 0 00-.164.35.592.592 0 01.057.254v4.279a.25.25 0 00.25.25h10.109a.5.5 0 00.5-.5v-2.1a2.461 2.461 0 00-.966-1.974 15.189 15.189 0 00-7.4-2.722.249.249 0 00-.268.171 3 3 0 01-2.118 1.992z\"/><circle cx=\"13.997\" cy=\"9.023\" r=\".998\"/><circle cx=\"10.003\" cy=\"9.023\" r=\".998\"/><path d=\"M4.933 13.862a.5.5 0 00.077.536c.625.719 1.5 1.614 4.494 1.614a3.478 3.478 0 002.312-.965.249.249 0 01.368 0 3.476 3.476 0 002.312.965c2.99 0 3.869-.895 4.494-1.614a.5.5 0 00-.381-.826 5.509 5.509 0 01-.82-.051.25.25 0 01-.166-.4 6.952 6.952 0 001.366-4.139v-2a6.989 6.989 0 00-13.978 0v2a6.956 6.956 0 001.365 4.139.251.251 0 01-.165.4 5.518 5.518 0 01-.82.051.5.5 0 00-.458.29zM13.5 11.02a1.988 1.988 0 00-1.331.508.25.25 0 01-.333 0 1.988 1.988 0 00-1.336-.508c-1.181 0-1.581.67-2.149 1.323a.036.036 0 01-.023.011.031.031 0 01-.024-.01 4.976 4.976 0 01-1.3-3.358V7.525a.249.249 0 01.249-.25h1.752a.749.749 0 000-1.5h-1.5a.25.25 0 01-.237-.328 5.071 5.071 0 01.208-.525.251.251 0 01.224-.143h.8a.749.749 0 00.749-.749.72.72 0 00-.291-.579.251.251 0 01-.1-.2.255.255 0 01.1-.2 4.992 4.992 0 011.086-.635.25.25 0 01.345.192c.663 4.359 3.64 4.671 6.3 4.671h.047a.249.249 0 01.25.25v1.457a4.984 4.984 0 01-1.3 3.359.033.033 0 01-.023.01.03.03 0 01-.023-.011c-.561-.654-.961-1.324-2.14-1.324z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});