define("ember-svg-jar/inlined/arrow-thick-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-down</title><path d=\"M21.718 13.716a1 1 0 00-.924-.616H15.75a.25.25 0 01-.25-.25V1.1a1 1 0 00-1-1h-5a1 1 0 00-1 1v11.75a.25.25 0 01-.25.25H3.206a1 1 0 00-.706 1.707l8.794 8.8a1 1 0 001.415 0l8.793-8.8a1 1 0 00.216-1.091z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});