define("ember-svg-jar/inlined/weather-snow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-snow</title><path d=\"M14.085 2.417a1 1 0 00-1.415 0l-.708.707a.992.992 0 00-.292.671.247.247 0 00.1.211.251.251 0 00.234.032 8.492 8.492 0 012.125-.493.252.252 0 00.219-.192 1 1 0 00-.263-.936zM8.25 3a1 1 0 001-1V1a1 1 0 00-2 0v1a1 1 0 001 1zM3.124 4.538a1 1 0 101.414-1.415l-.708-.707a1 1 0 10-1.414 1.415zM3 8.25a1 1 0 00-1-1H1a1 1 0 000 2h1a1 1 0 001-1z\"/><path d=\"M5.229 9.313a.249.249 0 00.124-.285 3 3 0 014-3.569.248.248 0 00.247-.039 8.379 8.379 0 011.375-.92.251.251 0 00.02-.43 5 5 0 00-7.407 5.985.25.25 0 00.4.1 7.069 7.069 0 011.241-.842zM22.094 12.074a.252.252 0 01-.1-.193A7 7 0 008.338 9.84a.249.249 0 01-.22.173 5.5 5.5 0 00-1.568 10.63.25.25 0 00.3-.362A2.481 2.481 0 016.5 19a2.514 2.514 0 01.037-.428.25.25 0 00-.1-.245 3.5 3.5 0 012.509-6.3 1 1 0 001.116-.833A5 5 0 0120 12c0 .146-.007.289-.02.433a1 1 0 00.5.959 2.994 2.994 0 01.882 4.452.251.251 0 00-.035.246 2.5 2.5 0 010 1.816.25.25 0 00.368.3 5 5 0 00.4-8.134z\"/><circle cx=\"9\" cy=\"23\" r=\"1\"/><circle cx=\"11.5\" cy=\"21\" r=\"1\"/><circle cx=\"14\" cy=\"23\" r=\"1\"/><circle cx=\"16.5\" cy=\"21\" r=\"1\"/><circle cx=\"19\" cy=\"23\" r=\"1\"/><circle cx=\"9\" cy=\"19\" r=\"1\"/><circle cx=\"11.5\" cy=\"17\" r=\"1\"/><circle cx=\"14\" cy=\"19\" r=\"1\"/><circle cx=\"16.5\" cy=\"17\" r=\"1\"/><circle cx=\"19\" cy=\"19\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});