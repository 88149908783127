define("ember-svg-jar/inlined/style-one-pin-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-warning</title><path d=\"M12 0a9.5 9.5 0 00-4 18.119 2.509 2.509 0 011.218 1.222l1.872 4.076a1 1 0 001.817 0l1.876-4.079A2.5 2.5 0 0116 18.118 9.5 9.5 0 0012 0zm5.863 13.552a.948.948 0 01-.807.448H6.944a.948.948 0 01-.807-.448.923.923 0 01-.031-.914L11.162 3a.95.95 0 011.676 0l5.056 9.635a.923.923 0 01-.031.917z\"/><circle cx=\"12\" cy=\"11.75\" r=\"1.25\"/><path d=\"M12 10a1 1 0 001-1V6a1 1 0 00-2 0v3a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});