define("ember-svg-jar/inlined/real-estate-action-building-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-building-settings</title><path d=\"M6.724 6.053l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 000 6.5V14a.5.5 0 00.5.5H2a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5A.5.5 0 007 14V6.5a.5.5 0 00-.276-.447zM5 10.5H2a.5.5 0 010-1h3a.5.5 0 010 1zM5 8H2a.5.5 0 010-1h3a.5.5 0 010 1zM14.021 11.24a.5.5 0 00.591-.341v-.011a3.185 3.185 0 01.314-.7.493.493 0 00.074-.257V3a.5.5 0 00-.308-.462l-6-2.5A.5.5 0 008 .5V14a.5.5 0 00.5.5h1.035a.5.5 0 00.5-.443 3.221 3.221 0 01.417-1.268 3.256 3.256 0 013.569-1.549zM10 4.5h3a.5.5 0 010 1h-3a.5.5 0 010-1zM10 7h3a.5.5 0 010 1h-3a.5.5 0 010-1zm3 3.5h-3a.5.5 0 010-1h3a.5.5 0 010 1z\"/><circle cx=\"17.748\" cy=\"17.031\" r=\"1.5\"/><path d=\"M23.516 18.409l-.988-1.065a.462.462 0 010-.625l.986-1.066a1.782 1.782 0 00-1.7-2.953l-1.407.326a.454.454 0 01-.537-.309l-.424-1.392a1.774 1.774 0 00-3.4 0l-.424 1.392a.455.455 0 01-.537.31L13.68 12.7a1.783 1.783 0 00-1.7 2.953l.989 1.066a.462.462 0 010 .625l-.987 1.065a1.783 1.783 0 001.7 2.953l1.408-.326a.453.453 0 01.532.311l.424 1.391a1.774 1.774 0 003.4 0l.424-1.391a.45.45 0 01.533-.311l1.412.327a1.783 1.783 0 001.7-2.953zm-5.768 1.622a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});