define("ember-svg-jar/inlined/spa-hand-flower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>spa-hand-flower</title><rect y=\"13.279\" width=\"4.001\" height=\"9\" rx=\"1\" ry=\"1\"/><path d=\"M23.854 17.488a3.239 3.239 0 00-3.492-1.127h-.014l-2 .638a.5.5 0 00-.348.485 2.25 2.25 0 01-2.248 2.291H10.5a.5.5 0 010-1h5.25a1.25 1.25 0 100-2.5l-3.685.008a4.268 4.268 0 01-.573-.383A6.833 6.833 0 007.5 14.279h-2a.5.5 0 00-.5.5v5.9a.5.5 0 00.339.474c.705.239 1.341.46 1.917.66 2.623.911 4.087 1.409 5.418 1.409 1.623 0 3.048-.742 6.139-2.386 1.281-.681 2.875-1.529 4.91-2.546a.5.5 0 00.269-.368.5.5 0 00-.138-.434zM11.929 8.384a.5.5 0 01.224.687 3.21 3.21 0 00-.393 1.661 2.3 2.3 0 00.92 1.805 2.184 2.184 0 001.3.414 2.7 2.7 0 00.7-.1 3.194 3.194 0 001.458-.888.5.5 0 01.724 0 3.194 3.194 0 001.458.888 2.7 2.7 0 00.7.1 2.185 2.185 0 001.3-.414 2.3 2.3 0 00.92-1.805 3.209 3.209 0 00-.392-1.661.5.5 0 01.223-.687 3.208 3.208 0 001.293-1.113 2.3 2.3 0 00.318-2 2.306 2.306 0 00-1.433-1.433 3.16 3.16 0 00-1.7-.138.5.5 0 01-.586-.424A2.678 2.678 0 0016.5.779a2.679 2.679 0 00-2.462 2.5.5.5 0 01-.2.337.5.5 0 01-.383.088 3.17 3.17 0 00-1.7.137 2.306 2.306 0 00-1.437 1.429 2.3 2.3 0 00.318 2 3.214 3.214 0 001.293 1.114zm1.852-.748l.385-.125a.5.5 0 00.344-.428 1.964 1.964 0 01.265-.817.5.5 0 00-.027-.548l-.238-.327a.5.5 0 11.809-.588l.238.328a.5.5 0 00.513.194 2.011 2.011 0 01.86 0 .5.5 0 00.513-.194l.238-.328a.5.5 0 11.809.588l-.238.327a.5.5 0 00-.027.548 1.964 1.964 0 01.265.817.5.5 0 00.344.428l.385.125a.5.5 0 01-.154.976.487.487 0 01-.149-.023H18.9l-.379-.123a.5.5 0 00-.521.139 1.98 1.98 0 01-.7.506.5.5 0 00-.3.459v.406a.5.5 0 01-1 0V9.57a.5.5 0 00-.3-.459 2 2 0 01-.7-.506.5.5 0 00-.528-.143l-.379.123a.482.482 0 01-.149.023.5.5 0 01-.154-.976z\"/><circle cx=\"16.5\" cy=\"7.278\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});