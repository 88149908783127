define("ember-svg-jar/inlined/astrology-book", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astrology-book</title><path d=\"M7.125 16.209a.25.25 0 00.213.381h1.491a.25.25 0 00.213-.381L8.3 14.99a.251.251 0 00-.214-.12.248.248 0 00-.213.12zM10.374 16.471a.249.249 0 00.213.119h3.326a.249.249 0 00.213-.119l1.625-2.659a.248.248 0 000-.26l-1.625-2.66a.253.253 0 00-.214-.12h-3.324a.25.25 0 00-.213.12l-1.626 2.659a.25.25 0 000 .261zM7.337 10.772a.25.25 0 00-.213.381l.746 1.221a.251.251 0 00.427 0l.746-1.221a.25.25 0 00-.213-.381zM12.464 8.172a.251.251 0 00-.427 0l-.745 1.22a.249.249 0 00.213.38H13a.249.249 0 00.213-.38zM12.037 19.192a.249.249 0 00.426 0l.746-1.221a.25.25 0 00-.213-.38H11.5a.25.25 0 00-.213.38zM16.2 12.374a.251.251 0 00.427 0l.746-1.221a.25.25 0 00-.213-.381h-1.49a.25.25 0 00-.213.381zM17.162 16.591a.25.25 0 00.213-.381l-.745-1.22a.251.251 0 00-.427 0l-.745 1.22a.25.25 0 00.213.381z\"/><path d=\"M19.972 4.012a.249.249 0 01-.222-.248V2a2 2 0 00-2-2H5.25a3 3 0 00-3 3v18a3 3 0 003 3h14.5a2 2 0 002-2V6a2 2 0 00-1.778-1.988zM18.63 16.346a.784.784 0 01-.7 1.245h-3.176a.249.249 0 00-.213.119l-1.864 3.051a.5.5 0 01-.854 0L9.959 17.71a.249.249 0 00-.213-.119H6.568a.784.784 0 01-.7-1.245l1.548-2.534a.248.248 0 000-.26l-1.843-3.019A.5.5 0 016 9.772h3.746a.249.249 0 00.213-.119l1.568-2.565a.833.833 0 011.446 0l1.568 2.565a.249.249 0 00.213.119H18.5a.5.5 0 01.427.761l-1.845 3.019a.248.248 0 000 .26zM5.25 2h12a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-12a1 1 0 010-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});