define("ember-svg-jar/inlined/messages-people-man-bubble-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-man-bubble-square</title><path d=\"M10.5 17.663v-1.4a3.189 3.189 0 00.912-2.412 1.783 1.783 0 00.632-1.466 1.439 1.439 0 00-.486-1.175 3.745 3.745 0 00.287-2.333C11.5 7.492 9.771 7 8.4 7c-1.225 0-2.73.39-3.29 1.456a1.129 1.129 0 00-1.01.382c-.5.624-.07 1.652.247 2.363a1.539 1.539 0 00-.6 1.3 1.473 1.473 0 00.761 1.394A3.046 3.046 0 005.5 16.28v1.383C3.624 18.427 0 20.03 0 21v2.5a.5.5 0 00.5.5h15a.5.5 0 00.5-.5V21c0-.97-3.623-2.573-5.5-3.337z\"/><path d=\"M24 2a2 2 0 00-2-2H9a2 2 0 00-2 2v3a1 1 0 002 0V2.25A.25.25 0 019.25 2h12.5a.25.25 0 01.25.25v6.5a.25.25 0 01-.25.25H19.5a1.006 1.006 0 00-.665.252L16.416 11.4a.25.25 0 01-.416-.184V10a1 1 0 00-1-1h-1a1 1 0 000 2v3a1 1 0 001.665.748L19.88 11H22a2 2 0 002-2z\"/><circle cx=\"12.502\" cy=\"5.75\" r=\"1\"/><circle cx=\"15.752\" cy=\"5.75\" r=\"1\"/><circle cx=\"19.003\" cy=\"5.75\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});