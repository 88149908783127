define("ember-svg-jar/inlined/medical-specialty-eye", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-specialty-eye</title><path d=\"M23.41 11.579A13.965 13.965 0 0017.859 5.5a.5.5 0 01-.244-.548l.855-3.728a.995.995 0 10-1.94-.44l-.751 3.248a.5.5 0 01-.614.371q-.274-.072-.559-.133a.5.5 0 00-.606.484v1.794a.5.5 0 00.292.452 5.5 5.5 0 11-4.584 0A.5.5 0 0010 6.548V4.754a.5.5 0 00-.6-.489c-.191.041-.377.085-.56.133a.5.5 0 01-.613-.371L7.47.779a.995.995 0 10-1.94.44l.855 3.728a.5.5 0 01-.244.548A13.965 13.965 0 00.59 11.579a1.025 1.025 0 000 .84A13.983 13.983 0 006.134 18.5a.5.5 0 01.244.545l-.828 3.734a1.006 1.006 0 00.76 1.2 1.635 1.635 0 00.22.02.994.994 0 00.97-.78l.724-3.242a.5.5 0 01.616-.377A12.291 12.291 0 0012 20a12.3 12.3 0 003.161-.4.5.5 0 01.615.375l.724 3.242a.994.994 0 00.97.78 1.635 1.635 0 00.22-.02 1.006 1.006 0 00.76-1.2l-.828-3.731a.5.5 0 01.245-.545 13.92 13.92 0 005.543-6.084 1.025 1.025 0 000-.84z\"/><path d=\"M9.76 10.882a2.5 2.5 0 104.48 0 .25.25 0 00-.448 0 2 2 0 01-3.584 0 .25.25 0 00-.448 0zM12 3.249a.75.75 0 00.75-.75v-1a.75.75 0 00-1.5 0v1a.75.75 0 00.75.75zM12 6.749A.75.75 0 0012.75 6V5a.75.75 0 10-1.5 0v1a.75.75 0 00.75.749zM12 10.249a.75.75 0 00.75-.75v-1a.75.75 0 10-1.5 0v1a.75.75 0 00.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});