define("ember-svg-jar/inlined/network-browser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>network-browser</title><path d=\"M23.938 16.062a7.875 7.875 0 10-7.877 7.875 7.883 7.883 0 007.877-7.875zm-7.688 5.623a.25.25 0 01-.188.084.253.253 0 01-.187-.084 6.973 6.973 0 01-1.17-1.9.249.249 0 01.23-.348h2.255a.25.25 0 01.23.348 6.973 6.973 0 01-1.17 1.9zm-1.949-4a.249.249 0 01-.247-.211 8.676 8.676 0 010-2.828.249.249 0 01.247-.211h3.523a.25.25 0 01.247.211 8.6 8.6 0 010 2.828.249.249 0 01-.247.211zm-4.363-1.623a6 6 0 01.176-1.434.249.249 0 01.243-.191h1.68a.25.25 0 01.248.283 9.339 9.339 0 000 2.685.245.245 0 01-.06.2.25.25 0 01-.188.085h-1.68a.249.249 0 01-.243-.19 6.012 6.012 0 01-.176-1.438zm5.937-5.622a.25.25 0 01.187-.084.253.253 0 01.188.083 6.963 6.963 0 011.17 1.9.249.249 0 01-.021.235.252.252 0 01-.209.112h-2.255a.25.25 0 01-.23-.347 6.958 6.958 0 011.17-1.899zm3.965 4.28a.25.25 0 01.248-.283h1.68a.25.25 0 01.243.191 5.9 5.9 0 010 2.869.249.249 0 01-.243.19h-1.68a.25.25 0 01-.188-.087.245.245 0 01-.06-.2 10.362 10.362 0 00.1-1.343 10.347 10.347 0 00-.1-1.337zm1.036-2.437a.25.25 0 01-.2.4h-1.1a.25.25 0 01-.237-.171 10.467 10.467 0 00-.6-1.44.25.25 0 01.347-.33 6.17 6.17 0 011.79 1.541zm-7.846-1.537a.25.25 0 01.347.33 10.506 10.506 0 00-.6 1.439.249.249 0 01-.237.172h-1.1a.25.25 0 01-.2-.4 6.17 6.17 0 011.79-1.541zm-1.781 9.1a.251.251 0 01.2-.4h1.1a.249.249 0 01.237.172 10.571 10.571 0 00.6 1.44.249.249 0 01-.347.329 6.151 6.151 0 01-1.79-1.545zm7.851 1.532a.249.249 0 01-.347-.329 10.571 10.571 0 00.6-1.44.25.25 0 01.237-.172h1.1a.251.251 0 01.2.4 6.151 6.151 0 01-1.79 1.541z\"/><path d=\"M6.062 15.562h-3a1 1 0 01-1-1v-8.5a1 1 0 011-1h15.5a1 1 0 011 1v.5a1 1 0 102 0v-3.5a3 3 0 00-3-3h-15.5a3 3 0 00-3 3v11.5a3 3 0 003 3h3a1 1 0 000-2zm0-13a1 1 0 111 1 1 1 0 01-1-1zm4.5 1a1 1 0 111-1 1 1 0 01-1 1zm-6-1a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});