define("ember-svg-jar/inlined/disability-blind", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-blind</title><path d=\"M23.4 10.833c-1.359-1.819-5.192-6.05-11.4-6.05S1.956 9.014.6 10.832a1.934 1.934 0 000 2.335A15.852 15.852 0 006.7 18.1a.99.99 0 00.645.26 13 13 0 004.655.857c6.211 0 10.044-4.231 11.4-6.049a1.934 1.934 0 000-2.335zM2.221 12C3.42 10.406 6.727 6.783 12 6.783a11.054 11.054 0 013.844.689l-.859.859-7 7-.792.791A13.908 13.908 0 012.221 12zM12 17.217a11.067 11.067 0 01-2.733-.34l.819-.819A4.451 4.451 0 0012 16.5a4.5 4.5 0 004.5-4.5 4.447 4.447 0 00-.442-1.914l1.707-1.708A14.629 14.629 0 0121.8 11.97c-1.177 1.577-4.493 5.247-9.8 5.247z\"/><path d=\"M12 7.5A4.5 4.5 0 007.5 12a4.444 4.444 0 00.253 1.427l5.674-5.674A4.444 4.444 0 0012 7.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});