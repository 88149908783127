define("ember-svg-jar/inlined/transportation-ticket-car-transfer-plane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transportation-ticket-car-transfer-plane</title><path d=\"M3.824 8.579a.975.975 0 00.518-.148L12.2 3.553a1.867 1.867 0 00.869-1.6 1.809 1.809 0 00-.1-.6 1.868 1.868 0 00-1.356-1.22A1.793 1.793 0 0010.2.4l-2 1.366a.25.25 0 01-.228.028L3.324.063a.824.824 0 00-.784.111l-.888.659-.01.007c-.033.028-.1.1-.126.12a.841.841 0 00.137 1.2.28.28 0 00.033.024l2.653 1.645a.25.25 0 01.011.418l-.59.411a.251.251 0 01-.27.011l-1.161-.685a.208.208 0 00-.053-.023.782.782 0 00-.726.149L.355 5.165a1.448 1.448 0 00-.118.117.766.766 0 00-.164.564.774.774 0 00.3.527l.013.01L3.257 8.4a.971.971 0 00.567.179zM9.181 7.74a1 1 0 001 1 4.962 4.962 0 013.654 1.6.253.253 0 01.066.193c-.007.071-.545.136-.6.178a.738.738 0 00-.289.464.752.752 0 00.336.763l2.29 1.141a.752.752 0 001.036-.229l1.141-1.788a.75.75 0 00-.018-.833.759.759 0 00-.774-.3l-.692.153a.249.249 0 01-.264-.109 6.956 6.956 0 00-5.88-3.23 1 1 0 00-1.006.997zM10.181 18.74a5.03 5.03 0 01-4.607-3.089.116.116 0 01.107-.161.75.75 0 00.531-1.28l-1.5-1.5a.75.75 0 00-1.061 0l-1.5 1.5a.75.75 0 00.53 1.28h.545a.248.248 0 01.24.182 6.948 6.948 0 006.715 5.068 1 1 0 100-2zM23.747 19.194a.753.753 0 00-.1-.154l-.7-.81a.241.241 0 01-.059-.132l-.328-2.645a1.743 1.743 0 00-1.726-1.463h-5.306a1.751 1.751 0 00-1.73 1.494l-.323 2.616a.257.257 0 01-.059.132l-.7.811a.791.791 0 00-.1.154 1.757 1.757 0 00-.185.783v.513A1.744 1.744 0 0013.307 22a.25.25 0 01.124.216v1.024a.75.75 0 001.5 0v-.75a.25.25 0 01.25-.25h6a.249.249 0 01.25.25v.75a.75.75 0 001.5 0v-1.025a.251.251 0 01.125-.215 1.743 1.743 0 00.875-1.509v-.513a1.77 1.77 0 00-.184-.784zm-8.682-1.734l.216-1.75a.251.251 0 01.245-.22h5.308a.249.249 0 01.247.219l.219 1.751a.247.247 0 01-.061.2.25.25 0 01-.187.084h-5.739a.254.254 0 01-.188-.084.25.25 0 01-.06-.2zm-.025 3.448a.75.75 0 11.75-.75.75.75 0 01-.75.75zm6.25 0a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});