define("ember-svg-jar/inlined/messages-people-woman-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-woman-bubble</title><path d=\"M11.174 17.386a.254.254 0 01-.174-.224v-.678a5.251 5.251 0 003.015-.768.5.5 0 00.132-.744c-.421-.5-.713-1.153-.842-3.059C13.059 8.278 10.607 7 8.5 7s-4.557 1.278-4.8 4.913c-.129 1.9-.421 2.563-.842 3.059a.5.5 0 00.131.744A5.267 5.267 0 006 16.484v.679a.256.256 0 01-.174.224c-2.868 1.061-4.434 1.82-5.034 3.021A7.587 7.587 0 000 23.5a.5.5 0 00.5.5h6.9a.25.25 0 00.231-.346L6.14 20.049a.248.248 0 01.06-.279.251.251 0 01.284-.039 4.679 4.679 0 004.21.037.25.25 0 01.338.327l-1.591 3.553A.251.251 0 009.67 24h6.83a.5.5 0 00.5-.5 7.581 7.581 0 00-.792-3.091c-.601-1.209-2.166-1.963-5.034-3.023z\"/><circle cx=\"12.87\" cy=\"6\" r=\"1\"/><circle cx=\"16.12\" cy=\"6\" r=\"1\"/><circle cx=\"19.37\" cy=\"6\" r=\"1\"/><path d=\"M17.894 0a5.434 5.434 0 00-3.6 1.273 5.558 5.558 0 00-3.871.116 5.472 5.472 0 00-3.1 3.093 1 1 0 101.869.71 3.466 3.466 0 011.975-1.947 3.575 3.575 0 012.9.092 1 1 0 001.133-.191A3.56 3.56 0 0117.894 2 3.91 3.91 0 0122 5.976a3.53 3.53 0 01-.945 2.472 4.129 4.129 0 01-3.061 1.117 1 1 0 00-.813.418 2.369 2.369 0 01-1.535 1.008 1 1 0 00.213 1.988 4.212 4.212 0 002.625-1.429 5.9 5.9 0 004.01-1.714A5.512 5.512 0 0024 5.976 5.913 5.913 0 0017.894 0z\"/><circle cx=\"17.254\" cy=\"15.749\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});