define("ember-svg-jar/inlined/bridge-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bridge-2</title><path d=\"M23.5 0H.5a.5.5 0 00-.5.5v2.542a.5.5 0 00.583.494A2.495 2.495 0 013.5 6v2.5A.5.5 0 004 9h5a.5.5 0 00.5-.5V6a2.5 2.5 0 015 0v2.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V6a2.495 2.495 0 012.917-2.464.5.5 0 00.583-.494V.5a.5.5 0 00-.5-.5zM23.5 10.5H.5a.5.5 0 00-.5.5v2.258a.5.5 0 00.222.415A4 4 0 012 17v2.5a.5.5 0 00.5.5 4.092 4.092 0 00.666-.609 2.551 2.551 0 011.949-.934 2.5 2.5 0 012.126 1.178c.215 0 .007.41.513.22A.38.38 0 008 19.5V17a4 4 0 018 0v2.5a.5.5 0 00.5.5.577.577 0 00.6-.292 2.5 2.5 0 014.293-.072.327.327 0 00.392.161A.314.314 0 0022 19.5V17a4 4 0 011.778-3.327.5.5 0 00.222-.415V11a.5.5 0 00-.5-.5z\"/><path d=\"M22.867 21.941c-1.63.216-2.736-1.5-2.746-1.513a1.043 1.043 0 00-1.719.029 3.263 3.263 0 01-2.673 1.509 2.723 2.723 0 01-2.336-1.44 1.046 1.046 0 00-1.714-.16 4.191 4.191 0 01-3.026 1.6 3.462 3.462 0 01-2.685-1.538 1.043 1.043 0 00-1.636-.094c-.015.02-1.527 1.872-3.2 1.647a1 1 0 00-.264 1.983 4.1 4.1 0 00.548.036A5.6 5.6 0 004.9 22.577a.249.249 0 01.327.006 5.086 5.086 0 003.429 1.383 5.687 5.687 0 003.556-1.321.25.25 0 01.331.015 4.468 4.468 0 003.189 1.306 5.029 5.029 0 003.38-1.3.249.249 0 01.332 0 4.561 4.561 0 003.692 1.26 1 1 0 00-.266-1.982z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});