define("ember-svg-jar/inlined/gaming-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gaming-5</title><path d=\"M21.919 8.2a.5.5 0 01-.22-.532 4.505 4.505 0 00-5.37-5.368.5.5 0 01-.532-.22 4.5 4.5 0 00-7.594 0 .5.5 0 01-.531.221A4.5 4.5 0 002.3 7.67a.5.5 0 01-.22.532 4.506 4.506 0 000 7.6.5.5 0 01.22.532A4.505 4.505 0 007.671 21.7a.5.5 0 01.532.22 4.505 4.505 0 007.594 0 .5.5 0 01.532-.22 4.506 4.506 0 005.37-5.37.5.5 0 01.22-.532 4.506 4.506 0 000-7.6zM11.5 11.75H13a2.25 2.25 0 010 4.5h-2.5a.75.75 0 010-1.5H13a.75.75 0 000-1.5h-1.5a1.752 1.752 0 01-1.75-1.75v-2a.75.75 0 01.75-.75h4a.75.75 0 010 1.5h-3a.25.25 0 00-.25.25v1a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});