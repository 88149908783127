define("ember-svg-jar/inlined/video-edit-flip-screen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-edit-flip-screen</title><path d=\"M7.773 14.864a.25.25 0 00-.223-.364H3.5A.5.5 0 013 14v-4a.5.5 0 01.5-.5h8a.5.5 0 01.5.5v3.25a.25.25 0 00.25.25h2.5a.25.25 0 00.25-.25V1a1 1 0 00-1-1H1a1 1 0 00-1 1v14.5a1 1 0 001 1h6.25a.25.25 0 00.25-.25V16a2.48 2.48 0 01.273-1.136zM12 7a.5.5 0 01-.5.5h-8A.5.5 0 013 7V2.5a.5.5 0 01.5-.5h8a.5.5 0 01.5.5z\"/><path d=\"M23 15H10a1 1 0 00-1 1v7a1 1 0 001 1h13a1 1 0 001-1v-7a1 1 0 00-1-1zm-2.5 1.5a.5.5 0 01.5.5v5a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5zM22.4 13.3l1.5-2a.5.5 0 00-.4-.8h-.25a.25.25 0 01-.25-.25v-2a3 3 0 00-3-3h-2.5a1 1 0 000 2H20a1 1 0 011 1v2a.25.25 0 01-.25.25h-.25a.5.5 0 00-.4.8l1.5 2a.5.5 0 00.8 0zM6.5 22H4a1 1 0 01-1-1v-.5a.25.25 0 01.25-.25h.25a.5.5 0 00.4-.8l-1.5-2a.518.518 0 00-.8 0l-1.5 2a.5.5 0 00.4.8h.25a.25.25 0 01.25.25v.5a3 3 0 003 3h2.5a1 1 0 100-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});