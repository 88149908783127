define("ember-svg-jar/inlined/instrument-tambourine-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>instrument-tambourine-1</title><path d=\"M15.243 2.988a.25.25 0 00.295-.356 4 4 0 00-7.076 0 .25.25 0 00.295.356 11.02 11.02 0 016.486 0zM22.846 11.663a.249.249 0 00.452.1A4 4 0 0020 5.5a.188.188 0 00-.173.115.186.186 0 00.039.2 10.988 10.988 0 012.98 5.848zM23.126 16.005a.249.249 0 00-.249-.088.253.253 0 00-.189.185 11.015 11.015 0 01-3.673 5.864.25.25 0 00.105.437 4.05 4.05 0 00.88.1 4 4 0 003.126-6.5zM4.134 5.818a.186.186 0 00.039-.2A.188.188 0 004 5.5a4 4 0 00-3.3 6.263.249.249 0 00.452-.1 10.988 10.988 0 012.982-5.845zM1.312 16.1a.25.25 0 00-.438-.1A4 4 0 004 22.5a4.05 4.05 0 00.88-.1.25.25 0 00.105-.437A11.015 11.015 0 011.312 16.1z\"/><path d=\"M22 13.5a10 10 0 10-10 10 10.011 10.011 0 0010-10zm-6 5a3.979 3.979 0 00.293 1.5.251.251 0 01-.1.307 7.984 7.984 0 01-8.384 0 .252.252 0 01-.1-.307 4 4 0 00-3.436-5.5.25.25 0 01-.233-.224 8.58 8.58 0 01-.034-.535.25.25 0 01.235-.257A4 4 0 007.34 7.3a.249.249 0 01.06-.344 8.032 8.032 0 01.822-.508.25.25 0 01.333.093 4 4 0 006.882 0 .249.249 0 01.333-.093 8.032 8.032 0 01.822.508.249.249 0 01.064.342 4 4 0 003.1 6.2.25.25 0 01.244.252 8.58 8.58 0 01-.034.535.249.249 0 01-.233.224A4 4 0 0016 18.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});