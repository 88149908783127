define("ember-svg-jar/inlined/sync-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sync-location</title><path d=\"M20.607 11.458a.959.959 0 10-.894 1.7c1.5.792 2.367 1.775 2.367 2.7 0 1.821-3.148 3.6-7.323 4.14a.96.96 0 00.122 1.912c.04 0 .083 0 .124-.007 5.381-.7 9-3.125 9-6.045-.003-1.688-1.203-3.25-3.396-4.4zM10.419 18.875a.48.48 0 00-.819.34v.653c0 .173-.121.162-.181.155-4.663-.555-7.5-2.51-7.5-4.168 0-.924.863-1.907 2.367-2.7a.959.959 0 00-.894-1.7C1.205 12.608 0 14.17 0 15.855c0 3 3.875 5.476 9.363 6.091a.247.247 0 01.237.248v.861a.479.479 0 00.3.443.9.9 0 00.523-.1l1.92-1.92a.479.479 0 000-.679zM12 .5a6.247 6.247 0 00-6.24 6.24c0 2.757 3.829 8.155 5.476 10.348a.952.952 0 001.528 0c1.647-2.198 5.476-7.596 5.476-10.353A6.247 6.247 0 0012 .5zm0 8.64a2.4 2.4 0 112.4-2.4A2.4 2.4 0 0112 9.135z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});