define("ember-svg-jar/inlined/single-woman-actions-coding.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-coding</title><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-2.513 6.677l1.293 1.293a.75.75 0 11-1.061 1.06l-2-2a.751.751 0 010-1.06l2-2a.75.75 0 111.061 1.06l-1.293 1.293a.25.25 0 000 .354zm6.793.353l-2 2a.75.75 0 01-1.061-1.06l1.293-1.293a.25.25 0 000-.354l-1.293-1.293a.75.75 0 111.061-1.06l2 2a.749.749 0 010 1.06zM10.654 12.375a7.368 7.368 0 00-3.448-.875 7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h8.515a.5.5 0 00.5-.47 7.93 7.93 0 011.321-3.93.5.5 0 00-.182-.72zM1.565 10.442a1 1 0 001.383-.3 5.233 5.233 0 00.454-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .249.249 0 01.411.082 5.233 5.233 0 00.454.9 1 1 0 001.681-1.082 4.569 4.569 0 01-.646-2.511V5.3a5.3 5.3 0 10-10.59 0v1.248a4.564 4.564 0 01-.643 2.512 1 1 0 00.298 1.382zM7.207 8.6a2.7 2.7 0 01-2.741-2.352.5.5 0 01.324-.577 5.7 5.7 0 002.245-1.439.25.25 0 01.361 0 5.7 5.7 0 002.23 1.437.5.5 0 01.323.576A2.708 2.708 0 017.207 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});