define("ember-svg-jar/inlined/accounting-bills-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accounting-bills-1</title><path d=\"M22.5 8.5h-21a1 1 0 00-1 1v12a1 1 0 001 1h21a1 1 0 001-1v-12a1 1 0 00-1-1zM12 19a3.5 3.5 0 113.5-3.5A3.5 3.5 0 0112 19zM3.5 7h17a1 1 0 000-2h-17a1 1 0 000 2zM5.5 3.5h13a1 1 0 000-2h-13a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});