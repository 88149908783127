define("ember-svg-jar/inlined/conversation-browser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>conversation-browser</title><path d=\"M24 2.5c0-.8-.7-1.5-1.5-1.5h-21C.7 1 0 1.7 0 2.5v14.6c0 .8.7 1.5 1.5 1.4H12c.3 0 .5.2.5.5v3.5c0 .3.2.5.5.5.1 0 .2 0 .3-.1l6.1-4.2c.2-.1.4-.2.6-.2h2.5c.8 0 1.5-.7 1.5-1.5V2.5zm-15.7 2c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm3.9 9.7c-.1.2-.3.2-.4.2h-7c-.3 0-.5-.2-.5-.5 0-.1 0-.2.1-.2 1.2-2.1 4-2.9 6.1-1.7.7.4 1.3 1 1.7 1.7v.5zM17 6.5c1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2-2.2-1-2.2-2.2c0-1.2 1-2.2 2.2-2.2zm3.3 7.3c.1.3 0 .5-.2.7h-5.8c-.3 0-.5-.2-.5-.5v-.2c.8-1.8 3-2.6 4.8-1.8.7.4 1.3 1 1.7 1.8z\"/><path d=\"M22 1H2C.9 1 0 1.9 0 3v13.6c0 1.1.9 2 2 1.9h10V22c0 .6.4 1 1 1 .2 0 .4-.1.6-.2l6.2-4.3H22c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zm0 15c0 .3-.2.5-.5.5h-2c-.2 0-.4.1-.6.2l-4.5 3.1c-.1.1-.3 0-.3-.1v-2.2c0-.6-.4-1-1-1H2.5c-.3 0-.5-.2-.5-.5V3.5c0-.3.2-.5.5-.5h19c.3 0 .5.2.5.5V16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});