define("ember-svg-jar/inlined/target-miss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>target-miss</title><path d=\"M18.191.039a.5.5 0 00-.545.107l-2.707 2.707a1.491 1.491 0 00-.439 1.061V5.5a.249.249 0 01-.1.2 1.168 1.168 0 00-.107.093l-5 5a1 1 0 101.414 1.414l5-5A.938.938 0 0015.8 7.1.251.251 0 0116 7h1.586a1.507 1.507 0 001.06-.44l2.708-2.707A.5.5 0 0021 3h-2.25a.25.25 0 01-.25-.25V.5a.5.5 0 00-.309-.461z\"/><path d=\"M10 5.5a6 6 0 000 12 1 1 0 000-2 4 4 0 010-8 1 1 0 000-2z\"/><path d=\"M10 21.5a1 1 0 000-2 8 8 0 112.225-15.687 1 1 0 10.554-1.921A10 10 0 1010 21.5z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm1.237 6.677l1.414 1.415a.75.75 0 01-1.06 1.06l-1.414-1.414a.25.25 0 00-.354 0l-1.414 1.414a.75.75 0 01-1.06-1.06l1.414-1.415a.25.25 0 000-.353l-1.414-1.414a.75.75 0 011.06-1.061l1.414 1.414a.252.252 0 00.354 0l1.414-1.414a.75.75 0 111.06 1.061l-1.414 1.414a.25.25 0 000 .353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});