define("ember-svg-jar/inlined/business-big-small-fish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-big-small-fish</title><path d=\"M15.55 9.4a.5.5 0 00.138-.641C14.443 6.505 12.612 5.5 9.75 5.5a6.475 6.475 0 00-6.018 4.048.249.249 0 01-.42.07l-.806-.93a1 1 0 00-1.756.654v5.316a1 1 0 001.756.654l.806-.93a.249.249 0 01.42.07A6.473 6.473 0 009.75 18.5a6.34 6.34 0 005.928-3.241.5.5 0 00-.128-.658l-3.2-2.4a.249.249 0 010-.4zm-3.8-.15a.75.75 0 11.75-.75.75.75 0 01-.75.75zM17.514 13.507a.5.5 0 00.513-.026l1.073-.711a.248.248 0 01.205-.033.255.255 0 01.159.132 2 2 0 100-1.738.255.255 0 01-.159.132.248.248 0 01-.205-.033l-1.068-.711a.5.5 0 00-.777.416v2.13a.5.5 0 00.259.442z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});