define("ember-svg-jar/inlined/insurance-umbrella-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>insurance-umbrella-heart</title><path d=\"M6.154 10a.5.5 0 001 0c0-.344.622-.769 1.467-1.039a.5.5 0 01.652.476V13a.251.251 0 00.454.146 4.848 4.848 0 011.318-1.26.5.5 0 00.228-.42V9.437a.5.5 0 01.652-.476c.846.27 1.467.7 1.467 1.039a.5.5 0 001 0c0-.759.932-1.31 2.215-1.31 1.117 0 2.214.649 2.214 1.31a.5.5 0 001 0v-.452a9.548 9.548 0 10-19.1 0V10a.5.5 0 001 0c0-.661 1.1-1.31 2.214-1.31 1.288 0 2.219.551 2.219 1.31zM10.221 19.591a4.579 4.579 0 01-.495-.579.25.25 0 00-.453.147v2.1a.75.75 0 01-1.5 0V21a1 1 0 00-2 0v.262a2.75 2.75 0 005.5 0v-.379a.5.5 0 00-.14-.347z\"/><path d=\"M22.9 14.509a3.543 3.543 0 00-5.776-.781.5.5 0 01-.7 0A3.518 3.518 0 1011.3 18.55l5.113 5.3a.5.5 0 00.72 0l5.107-5.291a3.488 3.488 0 00.66-4.05z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});