define("ember-svg-jar/inlined/multiple-actions-alarm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-alarm</title><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.316 12.426a.25.25 0 00.461.067 6 6 0 014.952-3.049.251.251 0 00.177-.416 4.557 4.557 0 00-7 .232.25.25 0 00.005.311 6.977 6.977 0 011.405 2.855zM11 17.5V14a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M23.659 19.883a4.085 4.085 0 01-1.159-2.716V15.5a4.5 4.5 0 00-9 0v1.667a4.079 4.079 0 01-1.159 2.716l-.241.317a.5.5 0 00.4.8h11a.5.5 0 00.4-.8zM19.682 22.194a.5.5 0 00-.4-.194h-2.568a.5.5 0 00-.484.625 1.827 1.827 0 003.54 0 .5.5 0 00-.088-.431z\"/><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.316 12.426a.25.25 0 00.461.067 6 6 0 014.952-3.049.251.251 0 00.177-.416 4.557 4.557 0 00-7 .232.25.25 0 00.005.311 6.977 6.977 0 011.405 2.855zM11 17.5V14a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M23.659 19.883a4.085 4.085 0 01-1.159-2.716V15.5a4.5 4.5 0 00-9 0v1.667a4.079 4.079 0 01-1.159 2.716l-.241.317a.5.5 0 00.4.8h11a.5.5 0 00.4-.8zM19.682 22.194a.5.5 0 00-.4-.194h-2.568a.5.5 0 00-.484.625 1.827 1.827 0 003.54 0 .5.5 0 00-.088-.431z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});