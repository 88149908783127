define("ember-svg-jar/inlined/arrow-thick-right-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-right-3</title><path d=\"M23.661 11.334l-8.053-9A1 1 0 0014.862 2h-5.69a1 1 0 00-.76 1.65l4.233 4.937a.251.251 0 01-.19.413H1.085a1 1 0 00-1 1v4a1 1 0 001 1h11.37a.251.251 0 01.19.413L8.412 20.35a1 1 0 00.76 1.65h5.69a1 1 0 00.746-.334l8.053-9a1 1 0 000-1.332zM1.085 9.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});