define("ember-svg-jar/inlined/desktop-monitor-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>desktop-monitor-settings</title><path d=\"M16.356 15.122a.249.249 0 00-.357.225v1.4a.25.25 0 01-.25.25h-13.5a.25.25 0 01-.25-.25v-8.5A.25.25 0 012.249 8h8.013a.252.252 0 00.217-.125 3.664 3.664 0 01.577-.737.25.25 0 000-.339 3.7 3.7 0 01-.541-.679A.248.248 0 0010.3 6H2a2 2 0 00-2 2v10.5a2 2 0 002 2h5.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H5a1 1 0 100 2h8a1 1 0 000-2h-2.75a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H16a2 2 0 002-2v-2.815a.251.251 0 00-.254-.25 3.155 3.155 0 01-1.39-.313z\"/><circle cx=\"17.749\" cy=\"6.969\" r=\"1.5\"/><path d=\"M22.529 7.282a.462.462 0 010-.625l.987-1.066a1.757 1.757 0 00.236-2.111 1.744 1.744 0 00-1.937-.842l-1.411.328a.457.457 0 01-.533-.311l-.424-1.392a1.775 1.775 0 00-3.4 0l-.424 1.392a.459.459 0 01-.533.311l-1.412-.328a1.745 1.745 0 00-1.936.842 1.757 1.757 0 00.235 2.111l.989 1.066a.462.462 0 010 .625l-.987 1.065a1.783 1.783 0 001.7 2.953l1.411-.327a.453.453 0 01.533.311l.424 1.391a1.774 1.774 0 003.4 0l.424-1.391a.45.45 0 01.533-.311l1.412.327a1.783 1.783 0 001.7-2.953zm-7.78-.313a3 3 0 113 3 3 3 0 01-3-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});