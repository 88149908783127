define("ember-svg-jar/inlined/video-player-pc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-player-pc</title><path d=\"M3.75 11.5a2 2 0 002 2h5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-2.5a1 1 0 000 2h7.5a1 1 0 000-2h-2.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h5a2 2 0 002-2V2a2 2 0 00-2-2H5.75a2 2 0 00-2 2zm14-9.5a.5.5 0 01.5.5v7a.5.5 0 01-.5.5H6.25a.5.5 0 01-.5-.5v-7a.5.5 0 01.5-.5zM20.52 21.728l-1.8-3A1.507 1.507 0 0017.434 18H6.566a1.507 1.507 0 00-1.286.729l-1.8 3A1.5 1.5 0 004.767 24h14.466a1.5 1.5 0 001.287-2.272zM17.25 21a.75.75 0 01-.75.75h-1a.75.75 0 010-1.5h1a.75.75 0 01.75.75zm-4 0a.75.75 0 01-.75.75h-1a.75.75 0 010-1.5h1a.75.75 0 01.75.75zm-5.75-.75h1a.75.75 0 010 1.5h-1a.75.75 0 010-1.5z\"/><path d=\"M10.538 8.455l3.317-1.415a1.149 1.149 0 000-2.065C11.15 3.82 9.5 2.79 9.5 4.3v3.414a.76.76 0 001.038.741z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});