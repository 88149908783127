define("ember-svg-jar/inlined/vintage-tv-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-tv-1</title><path d=\"M21.5 4.989h-3a.25.25 0 01-.2-.389l2.035-3.046A1 1 0 1018.668.442l-1.96 2.936a.25.25 0 01-.416 0L14.332.442a1 1 0 00-1.664 1.112L14.7 4.6a.25.25 0 01-.208.389H2.5A1.472 1.472 0 001 6.428v13.133A1.471 1.471 0 002.5 21h1.831a.25.25 0 01.192.41l-.792.95a1 1 0 001.538 1.281l2.125-2.551a.249.249 0 01.192-.09h8.828a.249.249 0 01.192.09l2.125 2.551a1 1 0 001.538-1.281l-.792-.95a.25.25 0 01.192-.41H21.5a1.471 1.471 0 001.5-1.439V6.428a1.472 1.472 0 00-1.5-1.439zM12 18a60.686 60.686 0 01-7.062-.442.5.5 0 01-.438-.5v-8.12a.5.5 0 01.438-.5A60.62 60.62 0 0112 8a60.62 60.62 0 017.062.443.5.5 0 01.438.5v8.121a.5.5 0 01-.438.5A60.686 60.686 0 0112 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});