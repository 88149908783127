define("ember-svg-jar/inlined/color-palette-sample-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-palette-sample-1</title><path d=\"M23.907 16.286l-1.348-4.815a2.5 2.5 0 00-3.081-1.734l-.754.211.527-.653a2.5 2.5 0 00-.377-3.515l-3.892-3.138a2.5 2.5 0 00-3.516.376l-1.021 1.267A.251.251 0 0110 4.128V2.5A2.5 2.5 0 007.5 0h-5A2.5 2.5 0 000 2.5v16.307a5.145 5.145 0 004.511 5.17c.166.015.332.023.5.023.989 0 .489 0 17.163-4.633a2.5 2.5 0 001.733-3.081zM13.023 4.273a.5.5 0 01.7-.075l3.893 3.139a.5.5 0 01.074.7l-7.248 8.988a.25.25 0 01-.442-.154V8.11a.249.249 0 01.056-.157zM5 9a2 2 0 11-2 2 2 2 0 012-2zM3 4.5a2 2 0 112 2 2 2 0 01-2-2zm2 11a2 2 0 11-2 2 2 2 0 012-2zm16.634 1.941l-10.812 3.027a.25.25 0 01-.262-.4l5.95-7.377a.255.255 0 01.127-.084l3.38-.946a.5.5 0 01.616.347l1.348 4.815a.5.5 0 01-.347.618z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});