define("ember-svg-jar/inlined/single-neutral-actions-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-refresh</title><path d=\"M0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.567a.25.25 0 00.181-.077.255.255 0 00.069-.185c-.007-.144-.007-.217-.007-.238a7.948 7.948 0 011.345-4.43.25.25 0 00.036-.19A5.5 5.5 0 000 13.75z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M23.391 19.621a1 1 0 10-1.892-.648 4.091 4.091 0 01-6.918 1.42.25.25 0 01.008-.345l1.722-1.722a.5.5 0 00-.354-.853H11.5a.5.5 0 00-.5.5v4.457a.5.5 0 00.854.353l.969-.969a.249.249 0 01.359 0 6.085 6.085 0 0010.209-2.2zM11.567 14.879a1 1 0 001.893.648 4.092 4.092 0 016.9-1.445.249.249 0 01-.007.346l-1.66 1.66a.5.5 0 00.354.853H23.5a.5.5 0 00.5-.5v-4.457a.5.5 0 00-.854-.353l-1.025 1.025a.249.249 0 01-.358 0 6.1 6.1 0 00-10.2 2.227zM0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.567a.25.25 0 00.181-.077.255.255 0 00.069-.185c-.007-.144-.007-.217-.007-.238a7.948 7.948 0 011.345-4.43.25.25 0 00.036-.19A5.5 5.5 0 000 13.75z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M23.391 19.621a1 1 0 10-1.892-.648 4.091 4.091 0 01-6.918 1.42.25.25 0 01.008-.345l1.722-1.722a.5.5 0 00-.354-.853H11.5a.5.5 0 00-.5.5v4.457a.5.5 0 00.854.353l.969-.969a.249.249 0 01.359 0 6.085 6.085 0 0010.209-2.2zM11.567 14.879a1 1 0 001.893.648 4.092 4.092 0 016.9-1.445.249.249 0 01-.007.346l-1.66 1.66a.5.5 0 00.354.853H23.5a.5.5 0 00.5-.5v-4.457a.5.5 0 00-.854-.353l-1.025 1.025a.249.249 0 01-.358 0 6.1 6.1 0 00-10.2 2.227z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});