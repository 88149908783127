define("ember-svg-jar/inlined/navigation-previous", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-previous</title><path d=\"M11.249 11.5a.25.25 0 01.25.25v1.75a2.5 2.5 0 105 0v-2a5.006 5.006 0 00-5-5h-4.25A.25.25 0 017 6.248V5.5a2 2 0 012-2h13a2 2 0 012 2v13a2 2 0 01-2 2H9a2 2 0 01-2-2v-6.75a.25.25 0 01.25-.25z\"/><path d=\"M.292 8.291l3.5-3.5A1 1 0 015.5 5.5v2.25a.25.25 0 00.25.25h5.75a3.5 3.5 0 013.5 3.5v2a1 1 0 01-2 0v-2a1.5 1.5 0 00-1.5-1.5H5.749a.25.25 0 00-.25.25v2.25a1 1 0 01-1.707.707l-3.5-3.5a1 1 0 010-1.416z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});