define("ember-svg-jar/inlined/party-confetti", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>party-confetti</title><path d=\"M23.21 11.807a.748.748 0 01-.53-.22 3.152 3.152 0 00-4.352 0 .75.75 0 01-1.061-1.061 4.579 4.579 0 016.473 0 .751.751 0 01-.53 1.281z\"/><circle cx=\"20.764\" cy=\"14.563\" r=\".809\"/><path d=\"M12.943 6.953a.75.75 0 01-.53-1.28 3.084 3.084 0 000-4.353.75.75 0 011.06-1.06 4.583 4.583 0 010 6.473.744.744 0 01-.53.22z\"/><circle cx=\"9.437\" cy=\"3.236\" r=\".809\"/><circle cx=\"23.191\" cy=\".809\" r=\".809\"/><circle cx=\"22.921\" cy=\"6.472\" r=\".809\"/><path d=\"M16.179 8.571a.75.75 0 01-.53-1.28l5.124-5.124a.75.75 0 011.06 1.061l-5.124 5.124a.747.747 0 01-.53.219zM14.016 9.979c-2.227-2.216-5.322-3.619-6.93-2.013a1.9 1.9 0 00-.358.485C6.691 8.514.28 20.519.28 20.519a2.364 2.364 0 003.194 3.2l12-6.417a1.807 1.807 0 00.564-.4c1.602-1.602.198-4.692-2.022-6.923zm.491 5.409zm.037-.027l.008-.005zm-.033.024c-.016.016-.061.074-.251.065-.908 0-2.6-1.083-3.614-2.1-1.519-1.52-2.373-3.522-2.028-3.868a.371.371 0 01.248-.062 6.312 6.312 0 013.625 2.08c1.899 1.913 2.249 3.655 2.02 3.885z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});