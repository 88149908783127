define("ember-svg-jar/inlined/single-neutral-actions-graduate.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-graduate</title><path d=\"M10.712 15.663l5.433-2.329a.251.251 0 00.129-.334l-.022-.046c-.539-1.077-2.307-1.726-4.753-2.625l-.629-.229a.6.6 0 01-.138-.309 2.629 2.629 0 01.058-1.3 5.621 5.621 0 001.466-4.315C12.256 1.756 10.677 0 8.5 0S4.744 1.756 4.744 4.174A5.6 5.6 0 006.2 8.471a2.508 2.508 0 01.073 1.318.614.614 0 01-.135.307l-.638.234c-2.446.9-4.214 1.548-4.753 2.626A9.163 9.163 0 000 16.5a.5.5 0 00.5.5h8.889a.249.249 0 00.236-.166 1.978 1.978 0 011.087-1.171zM11 20.965a.75.75 0 101.5 0v-2.153a.251.251 0 01.333-.236l4.5 1.589a.506.506 0 00.332 0l6-2.119a.5.5 0 00.019-.935l-6-2.383a.5.5 0 00-.37 0l-6 2.383a.5.5 0 00-.315.472v3.382z\"/><path d=\"M21.1 22.917a.5.5 0 00.334-.471V20.3a.5.5 0 00-.666-.471l-3.18 1.123a.249.249 0 01-.166 0l-3.18-1.123a.5.5 0 00-.666.471v2.146a.5.5 0 00.334.471l2.572.908a3.072 3.072 0 002.044 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});