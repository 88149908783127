define("ember-svg-jar/inlined/remove-circle-bold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>remove-circle-bold</title><path d=\"M12 23.5A11.5 11.5 0 10.5 12 11.513 11.513 0 0012 23.5zM7.05 9.172a1 1 0 010-1.415l.707-.707a1 1 0 011.415 0l2.651 2.65a.25.25 0 00.354 0l2.651-2.65a1 1 0 011.415 0l.707.707a1 1 0 010 1.415l-2.65 2.651a.25.25 0 000 .354l2.652 2.651a1 1 0 010 1.415l-.707.707a1 1 0 01-1.415 0l-2.653-2.65a.252.252 0 00-.354 0l-2.651 2.65a1 1 0 01-1.415 0l-.707-.707a1 1 0 010-1.415l2.65-2.651a.25.25 0 000-.354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});