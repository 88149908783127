define("ember-svg-jar/inlined/drone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drone</title><path d=\"M21.88 8.348a.5.5 0 00-.49-.6h-2.78a.5.5 0 00-.49.6l.09.616a.252.252 0 01-.248.286H14.9a.25.25 0 01-.242-.188 2.749 2.749 0 00-5.324 0 .25.25 0 01-.234.188H6.038a.252.252 0 01-.248-.286l.09-.616a.5.5 0 00-.49-.6H2.61a.5.5 0 00-.49.6l.719 4.915a1.176 1.176 0 002.322 0l.065-.447a.251.251 0 01.437-.128 7.869 7.869 0 004.629 2.418.25.25 0 01.208.246V16a.25.25 0 01-.25.25H9a3 3 0 00-3 3v1.5a1 1 0 002 0v-1.5a1 1 0 011-1h1.268a.249.249 0 01.247.214 1.5 1.5 0 002.97 0 .249.249 0 01.247-.214H15a1 1 0 011 1v1.5a1 1 0 002 0v-1.5a3 3 0 00-3-3h-1.25a.25.25 0 01-.25-.25v-.648a.25.25 0 01.208-.246 7.869 7.869 0 004.629-2.418.251.251 0 01.437.128l.065.447a1.176 1.176 0 002.322 0zM12 11a1.25 1.25 0 111.25-1.25A1.252 1.252 0 0112 11zM23 4.25h-1.75A.25.25 0 0121 4v-.75a1 1 0 00-2 0V4a.25.25 0 01-.25.25H17a1 1 0 000 2h6a1 1 0 000-2zM8 5.25a1 1 0 00-1-1H5.25A.25.25 0 015 4v-.75a1 1 0 00-2 0V4a.25.25 0 01-.25.25H1a1 1 0 000 2h6a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});