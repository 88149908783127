define("ember-svg-jar/inlined/smart-house-play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-house-play</title><path d=\"M23.773 6.882L15.416.15a.65.65 0 00-.831 0L6.228 6.882a.628.628 0 00-.188.7.641.641 0 00.6.416H8.5a.5.5 0 01.5.5v5.866a.639.639 0 00.643.636h10.714a.638.638 0 00.643-.634V8.5a.5.5 0 01.5-.5h1.857a.643.643 0 00.6-.416.628.628 0 00-.184-.702zM15 12a3.5 3.5 0 113.5-3.5A3.5 3.5 0 0115 12zM2.414 14.057a1.281 1.281 0 011.672 0 .75.75 0 101-1.114 2.819 2.819 0 00-3.68 0 .75.75 0 101 1.114z\"/><path d=\"M6.219 10.793a4.871 4.871 0 00-5.938 0 .75.75 0 10.938 1.17 3.3 3.3 0 014.062 0 .75.75 0 10.938-1.17zM4.75 15.25h-3A1.752 1.752 0 000 17v5.25A1.752 1.752 0 001.75 24h3a1.752 1.752 0 001.75-1.75V17a1.752 1.752 0 00-1.75-1.75zm.25 7a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25V17a.25.25 0 01.25-.25h3A.25.25 0 015 17zM13.952 8.5V7.287a.287.287 0 01.434-.246l2.023 1.213a.287.287 0 010 .492l-2.023 1.213a.287.287 0 01-.434-.246z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});