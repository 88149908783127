define("ember-svg-jar/inlined/natural-disaster-water-level-rise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>natural-disaster-water-level-rise</title><path d=\"M23 12.576a2.772 2.772 0 01-2.43-1.427 1 1 0 00-.807-.566 1.013 1.013 0 00-.9.4 4.184 4.184 0 01-3.027 1.59 4.18 4.18 0 01-3.022-1.585 1 1 0 00-.814-.41 1.006 1.006 0 00-.8.408 4.183 4.183 0 01-3.026 1.59 4.181 4.181 0 01-3.023-1.585 1.011 1.011 0 00-.9-.411 1 1 0 00-.811.569A2.75 2.75 0 011 12.576a1 1 0 00-1 1v9a1 1 0 001 1h22a1 1 0 001-1v-9a1 1 0 00-1-1zm-18.721.7a.249.249 0 01.329-.014 5.692 5.692 0 003.559 1.316 5.734 5.734 0 003.673-1.41.249.249 0 01.321 0 5.729 5.729 0 003.672 1.41 5.692 5.692 0 003.559-1.316.251.251 0 01.329.014 4.606 4.606 0 002.089 1.161.251.251 0 01.19.243V17a.25.25 0 01-.374.217 2.836 2.836 0 01-1.056-1.07 1 1 0 00-.807-.566 1.009 1.009 0 00-.9.4 4.184 4.184 0 01-3.027 1.59 4.18 4.18 0 01-3.022-1.585 1 1 0 00-.814-.408 1.006 1.006 0 00-.8.408 4.183 4.183 0 01-3.026 1.59 4.181 4.181 0 01-3.023-1.585 1 1 0 00-1.714.158 2.781 2.781 0 01-1.056 1.075.253.253 0 01-.25 0A.25.25 0 012 17.006v-2.328a.249.249 0 01.19-.243 4.606 4.606 0 002.089-1.161zm-2.029 8.3a.249.249 0 01-.25-.25v-1.649a.249.249 0 01.19-.242 4.621 4.621 0 002.089-1.161.25.25 0 01.329-.014 5.692 5.692 0 003.559 1.316 5.734 5.734 0 003.673-1.41.249.249 0 01.321 0 5.729 5.729 0 003.672 1.41 5.692 5.692 0 003.559-1.316.251.251 0 01.329.014 4.621 4.621 0 002.089 1.161.25.25 0 01.19.242v1.649a.249.249 0 01-.25.25zM4.25 5.576H5.5a.25.25 0 01.25.25v2.75a1.25 1.25 0 002.5 0v-2.75a.25.25 0 01.25-.25h1.25a.75.75 0 00.6-1.2L7.6.707a.773.773 0 00-1.2 0L3.649 4.376a.75.75 0 00.6 1.2zM14.25 5.576h1.25a.25.25 0 01.25.25v2.75a1.25 1.25 0 002.5 0v-2.75a.25.25 0 01.25-.25h1.25a.75.75 0 00.6-1.2L17.6.707a.773.773 0 00-1.2 0l-2.75 3.667a.75.75 0 00.6 1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});