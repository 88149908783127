define("ember-svg-jar/inlined/single-woman-actions-monitor.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-monitor</title><path d=\"M12.958 9.5h1.216a.246.246 0 00.1-.021 6.054 6.054 0 00.765-.4.393.393 0 00.182-.277.387.387 0 00-.1-.316c-.494-.537-.835-1.185-.981-3.317C13.88 1.346 11.278 0 9.041 0S4.2 1.346 3.942 5.171C3.8 7.3 3.455 7.951 2.961 8.488a.387.387 0 00.083.593 6.35 6.35 0 003.782.9.251.251 0 01.254.25v.462a.778.778 0 01-.512.73C3.667 12.488 1.356 13.248.8 14.345a7.845 7.845 0 00-.76 2.731.389.389 0 00.1.3.4.4 0 00.29.126h8.778a.249.249 0 00.25-.25V13a3.505 3.505 0 013.5-3.5z\"/><path d=\"M19.958 22h-1.25a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h3.25a2 2 0 002-2v-6a2 2 0 00-2-2h-9a2 2 0 00-2 2v6a2 2 0 002 2h3.25a.249.249 0 01.25.25v.5a.249.249 0 01-.25.25h-1.25a.985.985 0 00-.846.5.969.969 0 00-.154.5 1 1 0 001 1h5a1 1 0 001-1 .968.968 0 00-.153-.5.986.986 0 00-.847-.5zm-7-8.75a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v5.5a.249.249 0 01-.25.25h-8.5a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});