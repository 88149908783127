define("ember-svg-jar/inlined/gesture-flip-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-flip-right</title><path d=\"M12.369 5.154a.751.751 0 001.358-.271l.066-.328a.25.25 0 01.428-.122 4.892 4.892 0 011.005 1.874 1 1 0 101.9-.615 5.777 5.777 0 00-2.67-3.566.25.25 0 01-.128-.27l.141-.71a.75.75 0 00-1.149-.77l-2.243 1.5a.75.75 0 00-.206 1.041zM10.75 15.321v-4.072a3 3 0 00-6 0v4.072a.429.429 0 00.429.428h5.142a.429.429 0 00.429-.428zm-1.5-3.072a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1a1.5 1.5 0 013 0zM16.25 8.249a3 3 0 00-3 3v4.072a.429.429 0 00.429.428h5.142a.429.429 0 00.429-.428v-4.072a3 3 0 00-3-3zm1.5 4a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1a1.5 1.5 0 013 0zM11.632 18.845a.75.75 0 00-1.359.271l-.066.333a.25.25 0 01-.429.121 4.947 4.947 0 01-1-1.878 1 1 0 10-1.9.615 5.72 5.72 0 002.665 3.577.249.249 0 01.129.27l-.139.7a.75.75 0 001.152.77l2.244-1.5a.752.752 0 00.207-1.041z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});