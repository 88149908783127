define("ember-svg-jar/inlined/modern-architecture-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-architecture-building</title><path d=\"M23 24a1 1 0 000-2h-3.25a.5.5 0 01-.5-.5V1a1 1 0 00-1.04-1A13.435 13.435 0 008.1 5.188.5.5 0 008.489 6h7.261a.75.75 0 010 1.5H6.969a.5.5 0 00-.453.288q-.239.508-.437 1.038a.5.5 0 00.469.674h6.7a.75.75 0 010 1.5H5.909a.5.5 0 00-.494.426c-.051.342-.088.689-.112 1.039A.5.5 0 005.8 13h7.45a.75.75 0 010 1.5h-7.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h5.5a.75.75 0 010 1.5h-5.5a.5.5 0 00-.5.5v3a.5.5 0 01-.5.5H1a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});