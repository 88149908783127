define("ember-svg-jar/inlined/headphones-customer-support-human-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>headphones-customer-support-human-1</title><path d=\"M12.643 11.341a1.689 1.689 0 101.7 1.689 1.7 1.7 0 00-1.7-1.689z\"/><path d=\"M16.53 2.178a.5.5 0 01-.289-.293A2.892 2.892 0 0013.532 0h-1.778A2.892 2.892 0 009 2.013a.5.5 0 01-.286.311A8.212 8.212 0 003.3 9.6a2.013 2.013 0 01-.215.832c-.147.289-.347.638-.512.928-.7 1.235-1.5 2.633-1.423 3.75v.137a1.223 1.223 0 00.189.742 1.247 1.247 0 00.965.521c.173.007.364.01.551.01a.5.5 0 01.5.5V19a2.5 2.5 0 002.5 2.5h1.5a.5.5 0 01.5.5v1.5a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-3.708a.5.5 0 01.22-.415c2.509-1.69 3.78-4.314 3.78-7.814a9.939 9.939 0 00-6.325-9.385zm-3.552 14.809a1 1 0 00-.559.229 10.559 10.559 0 01-3.8 2.1.5.5 0 00-.265.181 1.249 1.249 0 01-1 .5h-.5a1.25 1.25 0 010-2.5h.5a1.236 1.236 0 01.706.22.5.5 0 00.441.063 8.48 8.48 0 001.995-.99.25.25 0 000-.419 3.94 3.94 0 01.131-6.751.5.5 0 00.247-.431V2.883a.886.886 0 01.88-.883h1.778a.886.886 0 01.889.882v6.31a.5.5 0 00.248.431 3.952 3.952 0 01-1.691 7.363z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});