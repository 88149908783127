define("ember-svg-jar/inlined/pin-shine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin-shine</title><path d=\"M12 6a7.183 7.183 0 00-7.15 7.52c0 4.44 4.56 8.65 6.52 10.25A.94.94 0 0012 24a.922.922 0 00.63-.23c1.96-1.6 6.52-5.81 6.52-10.25A7.183 7.183 0 0012 6zm0 10.5a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5zM4.314 6.5l-2.5-1.115A1.25 1.25 0 10.686 7.616l2.5 1.115A1.25 1.25 0 104.314 6.5zM7.324 4.685a1.25 1.25 0 002.344-.869l-1.246-3a1.25 1.25 0 00-2.344.869zM23.865 5.936a1.25 1.25 0 00-1.68-.55L19.686 6.5a1.25 1.25 0 101.129 2.23l2.5-1.115a1.25 1.25 0 00.55-1.679zM15.069 5.422a1.247 1.247 0 001.607-.737l1.246-3a1.25 1.25 0 00-2.344-.869l-1.246 3a1.249 1.249 0 00.737 1.606z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});