define("ember-svg-jar/inlined/modern-architecture-high-cloud-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-architecture-high-cloud-building</title><path d=\"M11.5 4.5H9.356a.25.25 0 00-.245.3l2.242 10.314a.25.25 0 00.494-.036l.653-9.519a1 1 0 00-1-1.055zM1.268 14.553L3.387 4.8a.249.249 0 00-.244-.3H1a1 1 0 00-1 1.067v.009l.772 8.946a.25.25 0 00.494.031zM3.147 13.407a.25.25 0 00.2.093h5.81a.25.25 0 00.244-.3l-.544-2.5a.249.249 0 00-.244-.2H3.886a.25.25 0 00-.245.2L3.1 13.2a.251.251 0 00.047.207zM4.32 9h3.858a.249.249 0 00.195-.093.252.252 0 00.05-.21L7.879 6.2a.25.25 0 00-.244-.2H4.864a.249.249 0 00-.244.2l-.544 2.5a.25.25 0 00.244.3zM2.663 15.2l-.543 2.5a.249.249 0 00.244.3h7.771a.25.25 0 00.244-.3l-.543-2.5a.25.25 0 00-.245-.2H2.907a.25.25 0 00-.244.2zM10.57 19.5H1.929a.249.249 0 00-.244.2l-.8 3.7a.5.5 0 00.485.6H5a.25.25 0 00.25-.25V22.5a1 1 0 012 0v1.25a.25.25 0 00.25.25h3.63a.5.5 0 00.489-.606l-.8-3.7a.25.25 0 00-.249-.194zM5.3 4.5h1.9a.249.249 0 00.244-.3l-.588-2.711a.621.621 0 00-1.213 0L5.054 4.2a.25.25 0 00.245.3zM20.75 0a3.231 3.231 0 00-2.941 1.877.25.25 0 01-.261.143 2.25 2.25 0 10-.3 4.48h3.5a3.25 3.25 0 000-6.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});