define("ember-svg-jar/inlined/outdoors-flashlight-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-flashlight-1</title><path d=\"M14.2 4.862a1.143 1.143 0 00-1.614 0L10.045 7.4a1.784 1.784 0 000 2.522l4.033 4.034a1.785 1.785 0 002.523 0l2.538-2.538a1.144 1.144 0 000-1.615zM9.076 11.026a.5.5 0 00-.707 0l-.608.608a.5.5 0 01-.708 0l-.267-.268a1 1 0 00-1.414 1.414l.267.268a.5.5 0 010 .707L.521 18.871a1.787 1.787 0 000 2.523l2.084 2.084a1.785 1.785 0 002.523 0l7.847-7.846a.5.5 0 000-.707zM17.533 6.468a1 1 0 001.414 0l2.034-2.034a1 1 0 00-1.414-1.414l-2.034 2.034a1 1 0 000 1.414zM23.894 6.28a1 1 0 00-1.342-.448L20.023 7.1a1 1 0 00.895 1.789l2.529-1.265a1 1 0 00.447-1.344zM15.562 4.425a1 1 0 001.338-.448l1.265-2.529a1 1 0 10-1.786-.895l-1.265 2.53a1 1 0 00.448 1.342z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});