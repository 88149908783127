define("ember-svg-jar/inlined/file-bin-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-bin-1</title><path d=\"M23.707 5.708L18.293.294A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.751V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.415a1 1 0 00-.293-.707z\"/><path d=\"M5.5 12.126h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H5.5a.875.875 0 000-1.75zM5.5 15.126h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H5.5a.875.875 0 100-1.75z\"/><path d=\"M20 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h18a1 1 0 001-1zM7.149 14.662a2.13 2.13 0 01.412 1.867 2.174 2.174 0 01-2.137 1.6H4a.625.625 0 01-.625-.625v-6A.625.625 0 014 10.876h1.424a2.174 2.174 0 012.137 1.6 2.128 2.128 0 01-.412 1.866.25.25 0 000 .32zm3.726 2.214H11a.625.625 0 010 1.25H9a.625.625 0 010-1.25h.125a.25.25 0 00.25-.25v-4.25a.25.25 0 00-.25-.25H9a.625.625 0 010-1.25h2a.625.625 0 010 1.25h-.125a.25.25 0 00-.25.25v4.25a.25.25 0 00.25.25zm5.75.625a.625.625 0 01-.481.608.644.644 0 01-.144.017.627.627 0 01-.559-.346s-1.631-3.245-1.667-3.334-.149-.067-.149 0V17.5a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279s1.583 3.187 1.645 3.29.171.094.171-.007v-3a.625.625 0 111.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});