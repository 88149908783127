define("ember-svg-jar/inlined/baby-boy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baby-boy</title><circle cx=\"8.75\" cy=\"12.36\" r=\"1.25\"/><circle cx=\"15.25\" cy=\"12.36\" r=\"1.25\"/><path d=\"M15.5 16a1 1 0 00-1.366.363 2.45 2.45 0 01-4.27 0 1 1 0 00-1.73 1 4.449 4.449 0 007.73 0A1 1 0 0015.5 16z\"/><path d=\"M22.887 11.42a10.929 10.929 0 00-5.438-7.994 1 1 0 01-.5-.731A4.93 4.93 0 0016 .4a1 1 0 10-1.6 1.2 2.863 2.863 0 01.179.265.25.25 0 01-.266.373A11.214 11.214 0 0012 2a10.941 10.941 0 00-10.887 9.42 2.5 2.5 0 00.225 4.294 11 11 0 0021.324 0 2.5 2.5 0 00.225-4.294zM21.6 13.99a1 1 0 00-.778.784 9 9 0 01-17.65 0 1 1 0 00-.772-.784.5.5 0 01-.05-.965 1 1 0 00.692-.861 8.951 8.951 0 014.043-6.721.5.5 0 01.715.185 4.756 4.756 0 008.4 0 .5.5 0 01.316-.25.5.5 0 01.4.064 8.951 8.951 0 014.047 6.722 1 1 0 00.692.861.5.5 0 01-.05.965z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});