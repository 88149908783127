define("ember-svg-jar/inlined/historical-building-pillar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>historical-building-pillar</title><path d=\"M8.5 9.51a1 1 0 00-1 1V23a1 1 0 002 0V10.51a1 1 0 00-1-1zM12 9.51a1 1 0 00-1 1V23a1 1 0 002 0V10.51a1 1 0 00-1-1zM15.5 9.51a1 1 0 00-1 1V23a1 1 0 002 0V10.51a1 1 0 00-1-1zM19.5 0h-15a4.482 4.482 0 00-2.756 8.031 3.924 3.924 0 002.1.625 2.539 2.539 0 002.728-2.51A1.433 1.433 0 005.484 4.77a.808.808 0 00-.948.586.75.75 0 11-1.376-.6A2.284 2.284 0 015.789 3.3a2.94 2.94 0 012.287 2.846 3.856 3.856 0 01-.19 1.2.5.5 0 00.475.654h7.278a.5.5 0 00.475-.655 3.856 3.856 0 01-.19-1.2A2.94 2.94 0 0118.211 3.3a2.284 2.284 0 012.629 1.46.75.75 0 11-1.376.6.808.808 0 00-.948-.586 1.433 1.433 0 00-1.092 1.376 2.539 2.539 0 002.728 2.51 3.924 3.924 0 002.1-.625A4.482 4.482 0 0019.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});