define("ember-svg-jar/inlined/book-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-edit</title><path d=\"M8.543 23.1l.749-3.746a1.985 1.985 0 01.554-1.03l7.623-7.623a4.079 4.079 0 012.572-1.18.5.5 0 00.46-.5V6a2 2 0 00-1.6-1.96.5.5 0 01-.4-.489V2a2 2 0 00-2-2H4a3 3 0 00-3 3v18a3 3 0 003 3h4a.5.5 0 00.5-.5 1.851 1.851 0 01.043-.4zM3 3a1 1 0 011-1h12.25a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H4a1 1 0 01-1-1z\"/><path d=\"M10.638 23.98l3.1-.884a.5.5 0 00.217-.834l-2.215-2.215a.5.5 0 00-.835.216l-.885 3.1a.5.5 0 00.618.617zM12.054 18.24a.5.5 0 000 .707l3 3a.5.5 0 00.707 0l5.011-5.01a.5.5 0 000-.708l-3-3a.5.5 0 00-.707 0zM18.333 12.168a.5.5 0 00.146.354l3 3a.536.536 0 00.377.124.584.584 0 00.377-.17 2.624 2.624 0 000-3.707 2.654 2.654 0 00-3.753.045.5.5 0 00-.147.354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});