define("ember-svg-jar/inlined/laptop-bug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-bug</title><path d=\"M12.319 16.468a.25.25 0 00-.084-.269 8.482 8.482 0 01-.616-.523.247.247 0 00-.206-.072 3.564 3.564 0 01-.412.021 3.1 3.1 0 01-2.95-1.649.493.493 0 00-.47-.361H.5a.478.478 0 00-.41.22.467.467 0 00-.05.47 5.43 5.43 0 005 3.31h5.211a.251.251 0 00.2-.1 2.046 2.046 0 011.646-.87.249.249 0 00.222-.177zM3.5 12.125a1 1 0 001-1v-8a1 1 0 011-1h12a1 1 0 011 1v8a1 1 0 002 0v-8a3 3 0 00-3-3h-12a3 3 0 00-3 3v8a1 1 0 001 1z\"/><path d=\"M23.251 18.125H21a.249.249 0 01-.25-.25v-.75a2.7 2.7 0 00-.075-.607.25.25 0 01.088-.251l1.954-1.554a.75.75 0 10-.934-1.174L20.07 14.9a.249.249 0 01-.322-.01 2.985 2.985 0 00-4 0 .25.25 0 01-.322.01l-1.713-1.363a.75.75 0 00-.934 1.174l1.953 1.553a.25.25 0 01.088.252 2.7 2.7 0 00-.074.607v.75a.249.249 0 01-.25.25h-2.25a.75.75 0 100 1.5H14.5a.25.25 0 01.25.25v.25a2.7 2.7 0 00.075.608.251.251 0 01-.089.252l-1.953 1.554a.75.75 0 00.934 1.174l1.713-1.363a.251.251 0 01.323.009 2.986 2.986 0 003.994 0 .25.25 0 01.322-.009l1.714 1.363a.75.75 0 10.934-1.174l-1.954-1.554a.248.248 0 01-.088-.252 2.7 2.7 0 00.075-.608v-.25a.25.25 0 01.25-.25h2.25a.75.75 0 100-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});