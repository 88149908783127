define("ember-svg-jar/inlined/office-drawer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-drawer</title><path d=\"M22.5 13.5h-21A1.5 1.5 0 000 15v7a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 22v-7a1.5 1.5 0 00-1.5-1.5zm-7 3.25a.75.75 0 01-.75.75h-5.5a.75.75 0 110-1.5h5.5a.75.75 0 01.75.748zM3 12a1 1 0 001-1V8a.5.5 0 01.5-.5h7.375a.249.249 0 01.2.1L13.2 9.1a1 1 0 00.8.4h5.5a.5.5 0 01.5.5v1a1 1 0 002 0V9.5a2 2 0 00-2-2h-5.5l-.9-1.2a2.009 2.009 0 00-1.6-.8H4a2 2 0 00-2 2V11a1 1 0 001 1zM3 4.5a1 1 0 001-1V3a.5.5 0 01.5-.5h7.375a.249.249 0 01.2.1L13.2 4.1a1 1 0 00.8.4h6a1 1 0 102 0 2 2 0 00-2-2h-5.5l-.9-1.2A2.009 2.009 0 0012 .5H4a2 2 0 00-2 2v1a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});