define("ember-svg-jar/inlined/jellyfish-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>jellyfish-box</title><path d=\"M7.722 15.5a7.031 7.031 0 01.791-3.1c.107-.243.2-.485.278-.725a.5.5 0 00-.405-.653A2.814 2.814 0 008 11a1.876 1.876 0 00-1.041.256.5.5 0 00-.242.265 9.917 9.917 0 00-.995 3.979 8.927 8.927 0 00.959 3.9 7.806 7.806 0 01.578 3.494 1 1 0 00.886 1.1 1.014 1.014 0 00.11.006 1 1 0 00.993-.892 9.738 9.738 0 00-.735-4.508 7.031 7.031 0 01-.791-3.1zM16.722 15.5a8.844 8.844 0 01.929-3.435.5.5 0 00-.251-.632 3.191 3.191 0 00-1.089-.422.5.5 0 00-.52.319 10.525 10.525 0 00-1.069 4.17 8.927 8.927 0 00.959 3.9 7.806 7.806 0 01.578 3.494 1 1 0 00.886 1.1 1.014 1.014 0 00.11.006 1 1 0 00.993-.892 9.738 9.738 0 00-.735-4.508 7.031 7.031 0 01-.791-3.1zM12.222 15.5a7.031 7.031 0 01.791-3.1.31.31 0 00-.315-.433 7.183 7.183 0 01-1.3.009.5.5 0 00-.508.318 8.159 8.159 0 00-.673 3.206 8.927 8.927 0 00.959 3.9 7.806 7.806 0 01.578 3.494 1 1 0 00.886 1.1 1.014 1.014 0 00.11.006 1 1 0 00.993-.892 9.738 9.738 0 00-.735-4.508 7.031 7.031 0 01-.786-3.1zM18.563 2.843a9 9 0 00-13.118-.01 8.933 8.933 0 00-2.428 6.728 1 1 0 001 .939 4.364 4.364 0 002.206-.553A3.381 3.381 0 018 9.5a3.381 3.381 0 011.776.447A4.37 4.37 0 0012 10.5a4.37 4.37 0 002.224-.553A3.381 3.381 0 0116 9.5a3.381 3.381 0 011.776.447A4.374 4.374 0 0020 10.5h.016a.972.972 0 00.964-.907 8.933 8.933 0 00-2.417-6.75zm-9.271.8a8.04 8.04 0 00-2.556 3.108.5.5 0 01-.454.29.505.505 0 01-.21-.047.5.5 0 01-.243-.663 9.04 9.04 0 012.871-3.5.5.5 0 01.589.808z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});