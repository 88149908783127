define("ember-svg-jar/inlined/religion-cao-dai-hands-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-cao-dai-hands-2</title><path d=\"M19.18 6.984a1.511 1.511 0 000-1.469 8.284 8.284 0 00-14.487 0 1.51 1.51 0 000 1.471 8.284 8.284 0 0014.488 0zM11.936 9.25a6.246 6.246 0 01-5.264-2.861.251.251 0 010-.278 6.273 6.273 0 0110.528 0 .251.251 0 010 .278 6.246 6.246 0 01-5.264 2.861z\"/><circle cx=\"11.936\" cy=\"6.25\" r=\"2\"/><path d=\"M6.867 15.284a1.376 1.376 0 10-2.251 1.583L5.4 17.95a.5.5 0 01-.8.6l-1.1-1.467a2.52 2.52 0 01-.5-1.5V11.25a1.5 1.5 0 00-3 0v5.672a5.014 5.014 0 001.1 3.123l2.013 2.517a.5.5 0 00.391.188H8a.5.5 0 00.5-.5v-3.137a5.061 5.061 0 00-.93-2.774zM22.5 9.75a1.5 1.5 0 00-1.5 1.5v4.333a2.52 2.52 0 01-.5 1.5l-1.1 1.467a.5.5 0 11-.8-.6l.784-1.083a1.376 1.376 0 10-2.251-1.583l-.7 1.055a5.061 5.061 0 00-.93 2.774v3.137a.5.5 0 00.5.5h4.5a.5.5 0 00.391-.188l2.006-2.517a5.014 5.014 0 001.1-3.123V11.25a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});