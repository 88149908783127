define("ember-svg-jar/inlined/earpods-bluetooth-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earpods-bluetooth-2</title><path d=\"M4.1 0A5.06 5.06 0 00.335 1.631 1.307 1.307 0 000 2.505v4.033a1.306 1.306 0 00.336.875A5.059 5.059 0 004.1 9.043a5.237 5.237 0 00.82-.064.5.5 0 01.58.494V18.2a1.466 1.466 0 001.4 1.3h.8A1.3 1.3 0 009 18.2V4.521A4.727 4.727 0 004.1 0zM2.511 5.326a.8.8 0 11.8-.8.8.8 0 01-.8.8zM11.75 17.25a8.465 8.465 0 012.186-5.684A.251.251 0 0014 11.4V9.473a.5.5 0 01.58-.494 5.237 5.237 0 00.82.064 5.06 5.06 0 003.765-1.631 1.305 1.305 0 00.335-.874V2.505a1.308 1.308 0 00-.336-.875A5.059 5.059 0 0015.4 0a4.727 4.727 0 00-4.9 4.521V18.2a1.3 1.3 0 001.3 1.3.2.2 0 00.19-.242 8.466 8.466 0 01-.24-2.008zm5.239-13.533a.8.8 0 11-.8.8.8.8 0 01.8-.8z\"/><path d=\"M15.928 21.636L17.77 20.2a.25.25 0 01.4.2V23a1 1 0 001.6.8l3.827-2.87a1 1 0 00.015-1.588L21.5 17.693a.25.25 0 010-.394l2.11-1.641a1 1 0 00-.014-1.589L19.773 11.2a1 1 0 00-1.6.8v2.584a.252.252 0 01-.141.225.249.249 0 01-.263-.028l-1.816-1.417a1 1 0 00-1.23 1.577l3.02 2.359a.251.251 0 010 .395L14.7 20.058a1 1 0 101.229 1.578zm5.172-1.723a.249.249 0 01.1.2.253.253 0 01-.1.2l-.519.389a.25.25 0 01-.4-.2V19.7a.25.25 0 01.4-.2zm-.52-4.427a.25.25 0 01-.4-.2V14.5a.25.25 0 01.4-.2l.517.388a.249.249 0 010 .4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});