define("ember-svg-jar/inlined/pets-nail-clipper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pets-nail-clipper</title><path d=\"M10.424 9.113a.25.25 0 00.187.075.248.248 0 00.182-.087l.937-1.093h.54l.937 1.092a.252.252 0 00.182.088.248.248 0 00.187-.077c.5-.518 1.8-1.822 2.312-2.3a.25.25 0 00.012-.351l-.347-.392 1.413-3.889A.5.5 0 0017 2.008a1.911 1.911 0 00-.494-1.425A3.37 3.37 0 0014.026 0a1 1 0 00-.847 1.524 1.614 1.614 0 01.2 1.545 1.765 1.765 0 01-1.37.891 1.818 1.818 0 01-1.348-.813 1.579 1.579 0 01.159-1.623A1 1 0 009.974 0a3.384 3.384 0 00-2.48.583A1.913 1.913 0 007 2.008a.5.5 0 00.03.171l1.414 3.889-.344.392a.249.249 0 00.016.348c.512.484 1.809 1.792 2.308 2.305zM12 6.758a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/><path d=\"M19.008 21.707a2.5 2.5 0 004.983-.414c-.552-6.634-5.844-13.118-6.07-13.393a.667.667 0 00-.478-.239.629.629 0 00-.5.194l-2.409 2.409a1 1 0 00-.091 1.308c.105.14.238.32.39.533a.253.253 0 01.019.26.251.251 0 01-.223.135H9.368a.25.25 0 01-.2-.395c.152-.213.285-.393.39-.533a1 1 0 00-.092-1.307l-2.412-2.41a.631.631 0 00-.5-.194.665.665 0 00-.478.24C5.853 8.175.561 14.659.009 21.292a2.5 2.5 0 004.983.416c.226-2.719 2.164-6.353 2.565-7.079a.251.251 0 01.219-.129h8.451a.249.249 0 01.217.126c.401.7 2.323 4.188 2.564 7.081z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});