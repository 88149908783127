define("ember-svg-jar/inlined/fitness-bicycle-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fitness-bicycle-3</title><path d=\"M20.88 20.45a.246.246 0 01-.057-.2.251.251 0 01.11-.174 3.977 3.977 0 001.817-3.326 4.027 4.027 0 00-2.921-3.85l-1.059-.223a.249.249 0 01-.158-.38 11.113 11.113 0 001.352-8.748.5.5 0 01.131-.478l1.362-1.362A1 1 0 0020.043.293l-4 4a1 1 0 101.414 1.414l.4-.4a.249.249 0 01.425.152 8.861 8.861 0 01-1.851 6.441.5.5 0 01-.5.181L7.968 10.4a.5.5 0 01-.378-.352l-.423-1.479a.251.251 0 01.041-.219.249.249 0 01.2-.1h.842a1 1 0 00.242-1.97l-6-1.5a1 1 0 00-1.074 1.525l.7 1.054a2 2 0 001.664.891h.833a.5.5 0 01.481.363l.433 1.517a.244.244 0 01-.023.192.248.248 0 01-.154.118 5.492 5.492 0 00-2.49 9.2 5.376 5.376 0 001.256.919.249.249 0 01.128.174.251.251 0 01-.056.209l-1.2 1.4a1 1 0 001.52 1.3l1.907-2.226a.5.5 0 01.359-.174l11.5-.48a.5.5 0 01.4.175l2.319 2.7a1 1 0 101.518-1.3zm-7.567-4.927a.5.5 0 01-.364.606l-2.917.73a3.013 3.013 0 11-.35-2.841.25.25 0 01-.144.387l-2.47.617a.75.75 0 00.181 1.478l5.457-1.341a.5.5 0 01.607.364z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});