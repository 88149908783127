define("ember-svg-jar/inlined/email-action-send-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-send-2</title><path d=\"M17.75 10.5a7.2 7.2 0 013.75 1.057V2.25a.158.158 0 00-.1-.147.16.16 0 00-.173.034L12.7 10.664a2.407 2.407 0 01-3.4 0L.771 2.137A.159.159 0 00.5 2.25v10.5a1.749 1.749 0 001.75 1.75h9.028a7.254 7.254 0 016.472-4z\"/><path d=\"M10.226 9.736a1.094 1.094 0 001.547 0l8.475-8.475A.437.437 0 0020 .519 1.62 1.62 0 0019.749.5h-17.5A1.62 1.62 0 002 .519a.437.437 0 00-.352.295.441.441 0 00.106.447zM17.75 12a5.75 5.75 0 105.75 5.75A5.735 5.735 0 0017.75 12zm3.312 6.141l-2.5 2a.5.5 0 01-.812-.391V18.5h-3a.75.75 0 010-1.5h3v-1.25a.5.5 0 01.812-.391l2.5 2a.5.5 0 010 .782z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});