define("ember-svg-jar/inlined/matches-fire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>matches-fire</title><path d=\"M21.578 11.135a.5.5 0 00-.8-.237 8.621 8.621 0 01-1.947 1.248.249.249 0 01-.354-.211A13.149 13.149 0 007.758.006a.5.5 0 00-.509.748A9.609 9.609 0 018.5 5.5a9.07 9.07 0 01-.629 3.322.251.251 0 01-.443.045A8.774 8.774 0 016.38 6.579a.5.5 0 00-.8-.242A9.964 9.964 0 002 14c0 .18 0 .359.01.537A10 10 0 0022 14a9.851 9.851 0 00-.422-2.865zM12 21.25a4.019 4.019 0 01-4-4.113 7.56 7.56 0 014.65-6.884.251.251 0 01.292.385 4.28 4.28 0 00-.47 4.161.254.254 0 00.177.138.251.251 0 00.216-.06 2.3 2.3 0 00.735-1.055.245.245 0 01.138-.158.251.251 0 01.211 0A3.7 3.7 0 0116 17.25a3.922 3.922 0 01-4 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});