define("ember-svg-jar/inlined/touch-id-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>touch-id-lock</title><path d=\"M12.038 21.26a7.628 7.628 0 01-2.663.476.75.75 0 000 1.5 9.29 9.29 0 002.826-.442.249.249 0 00.174-.238V21.5a.25.25 0 00-.337-.235zM17.125 9.267v.076a.251.251 0 00.3.245 4.373 4.373 0 01.946-.1h.03a.22.22 0 00.22-.219A9.154 9.154 0 0016.7 3.616a.748.748 0 00-1.05-.139.739.739 0 00-.13 1.049 7.736 7.736 0 011.605 4.741zM6.361 21.129a7.74 7.74 0 01-4.736-7.143V12.1a.75.75 0 00-1.5 0v1.888a9.235 9.235 0 005.652 8.522.74.74 0 00.292.059.75.75 0 00.292-1.44zM.847 9.542h.042a.75.75 0 00.748-.709 7.751 7.751 0 0111.615-6.28A.75.75 0 0014 1.254 9.252 9.252 0 00.139 8.752a.75.75 0 00.708.79z\"/><path d=\"M14.467 11.411a.251.251 0 00.28-.091 4.256 4.256 0 01.944-.941.252.252 0 00.1-.2v-.912a6.419 6.419 0 00-8.42-6.1.747.747 0 00-.48.95.756.756 0 00.94.48 4.733 4.733 0 011.54-.25 4.93 4.93 0 014.929 4.92v1.906a.25.25 0 00.167.238zM12.245 18.138a.249.249 0 00-.254.008 4.88 4.88 0 01-2.616.76 4.929 4.929 0 01-4.92-4.92V9.267a4.95 4.95 0 011.25-3.281.746.746 0 00-.05-1.06.755.755 0 00-1.06.06 6.4 6.4 0 00-1.64 4.281v4.719a6.427 6.427 0 006.42 6.42 6.279 6.279 0 002.864-.688.251.251 0 00.136-.222v-1.139a.25.25 0 00-.13-.219z\"/><path d=\"M9.375 5.676A3.585 3.585 0 005.8 9.267v1.409a.75.75 0 001.5 0V9.267a2.08 2.08 0 114.16 0v4.719a2.08 2.08 0 11-4.16 0 .75.75 0 00-1.5 0 3.58 3.58 0 006.55 2 .253.253 0 00.042-.127 1.842 1.842 0 01.46-1.143.233.233 0 00.061-.127 3.886 3.886 0 00.047-.6V9.267a3.585 3.585 0 00-3.585-3.591z\"/><path d=\"M10.125 13.514v-2.833a.75.75 0 10-1.5 0v2.833a.75.75 0 001.5 0zM22.589 15.5a.25.25 0 01-.214-.248v-1.016a3.5 3.5 0 00-7 0v1.018a.25.25 0 01-.214.248 1.5 1.5 0 00-1.286 1.484v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5v-5.5a1.5 1.5 0 00-1.286-1.486zm-3.714 5.734a1 1 0 111-1 1 1 0 01-1 1.002zm1.5-6a.249.249 0 01-.25.25h-2.5a.249.249 0 01-.25-.25v-1a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});