define("ember-svg-jar/inlined/road-sign-side-road-angle-left-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-side-road-angle-left-alternate</title><path d=\"M11.47 23.53a.748.748 0 001.06 0l11-11a.749.749 0 000-1.06l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06zM2.237 12.177a.252.252 0 010-.354l9.586-9.586a.252.252 0 01.354 0l9.586 9.586a.252.252 0 010 .354l-9.586 9.586a.252.252 0 01-.354 0z\"/><path d=\"M12 8.5a1 1 0 012 0v8a1 1 0 01-2 0v-2.982a.249.249 0 00-.073-.177l-3.134-3.134a1 1 0 011.414-1.414l1.366 1.366A.25.25 0 0012 9.982z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});