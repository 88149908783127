define("ember-svg-jar/inlined/single-woman-actions-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-download</title><path d=\"M.225 18.362a.5.5 0 00.394.2h2.3a.247.247 0 01.243.2l.921 4.847v.007a.5.5 0 00.484.384h2.969a.5.5 0 00.483-.388v-.007l.921-4.847a.247.247 0 01.243-.2h.132a.249.249 0 00.184-.082.245.245 0 00.063-.19 7.085 7.085 0 01-.038-.718 7.857 7.857 0 011.153-4.1.249.249 0 00.028-.194c-.526-1.935-.655-2.941-1.731-3.948a.5.5 0 00-.757.1L6.482 12.16a.5.5 0 01-.837 0L3.923 9.411a.5.5 0 00-.755-.1C2.014 10.373 2.1 10.78.141 17.931a.5.5 0 00.084.431zM6.041 0a4.206 4.206 0 104.206 4.206A4.212 4.212 0 006.041 0zm0 6.433A2.224 2.224 0 013.9 4.81a.247.247 0 01.158-.3 4.63 4.63 0 001.8-1.128.247.247 0 01.353 0 4.627 4.627 0 001.8 1.128.247.247 0 01.157.3 2.222 2.222 0 01-2.127 1.623zM11.01 17.567a6.433 6.433 0 106.433-6.433 6.441 6.441 0 00-6.433 6.433zm7.423-2.967v2.474a.248.248 0 00.247.248h1.139a.494.494 0 01.386.8l-2.375 2.971a.5.5 0 01-.773 0l-2.375-2.969a.494.494 0 01.386-.8h1.138a.248.248 0 00.248-.248V14.6a.99.99 0 111.979 0z\"/><path d=\"M.225 18.362a.5.5 0 00.394.2h2.3a.247.247 0 01.243.2l.921 4.847v.007a.5.5 0 00.484.384h2.969a.5.5 0 00.483-.388v-.007l.921-4.847a.247.247 0 01.243-.2h.132a.249.249 0 00.184-.082.245.245 0 00.063-.19 7.085 7.085 0 01-.038-.718 7.857 7.857 0 011.153-4.1.249.249 0 00.028-.194c-.526-1.935-.655-2.941-1.731-3.948a.5.5 0 00-.757.1L6.482 12.16a.5.5 0 01-.837 0L3.923 9.411a.5.5 0 00-.755-.1C2.014 10.373 2.1 10.78.141 17.931a.5.5 0 00.084.431zM6.041 0a4.206 4.206 0 104.206 4.206A4.212 4.212 0 006.041 0zm0 6.433A2.224 2.224 0 013.9 4.81a.247.247 0 01.158-.3 4.63 4.63 0 001.8-1.128.247.247 0 01.353 0 4.627 4.627 0 001.8 1.128.247.247 0 01.157.3 2.222 2.222 0 01-2.127 1.623zM11.01 17.567a6.433 6.433 0 106.433-6.433 6.441 6.441 0 00-6.433 6.433zm7.423-2.967v2.474a.248.248 0 00.247.248h1.139a.494.494 0 01.386.8l-2.375 2.971a.5.5 0 01-.773 0l-2.375-2.969a.494.494 0 01.386-.8h1.138a.248.248 0 00.248-.248V14.6a.99.99 0 111.979 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});