define("ember-svg-jar/inlined/fast-food-tacos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fast-food-tacos</title><path d=\"M22.551 10.566a2.986 2.986 0 000-4.224 10.465 10.465 0 00-4.494-2.664.5.5 0 01-.359-.431A3.61 3.61 0 0012.29.491a.5.5 0 01-.4.046 3.616 3.616 0 00-3.635.888 3.554 3.554 0 00-.645.888.5.5 0 01-.451.273 3.573 3.573 0 00-3.632 3.628.5.5 0 01-.273.451 3.528 3.528 0 00-.889.648 3.592 3.592 0 00-.915 3.53.5.5 0 01-.09.45 3.605 3.605 0 00.209 4.734.5.5 0 01.136.407 3.595 3.595 0 002.269 3.776.5.5 0 01.317.406 3.581 3.581 0 001.018 2.1l.4.4a3.044 3.044 0 004.3 0l1.134-1.135zM7.121 21.7l-.4-.4a1.6 1.6 0 01-.395-1.577 1 1 0 00-.172-.89 1.023 1.023 0 00-.822-.385 1.587 1.587 0 01-1.512-2.241 1 1 0 00-.42-1.28 1.628 1.628 0 01-.353-.252 1.6 1.6 0 010-2.268 1.525 1.525 0 01.276-.217 1 1 0 00.277-1.417 1.587 1.587 0 011.22-2.5 1 1 0 00.855-1.423 1.589 1.589 0 012.116-2.116 1 1 0 001.42-.851 1.577 1.577 0 01.456-1.044 1.608 1.608 0 012.019-.2 1 1 0 001.261-.125L13 2.447a1.64 1.64 0 012.242.023.47.47 0 01-.32.8 9.691 9.691 0 00-6.17 2.394 9.862 9.862 0 00-3.387 7.155 9.986 9.986 0 002.9 7.351l.567.566a.5.5 0 010 .708l-.249.249a1.041 1.041 0 01-1.462.007zM9.634 8.467a1 1 0 111 1 1 1 0 01-1-1zm.166 4.391a1 1 0 111 1 1 1 0 01-1-1zm1.509 5.752a1 1 0 111-1 1 1 0 01-1.003 1zm2.731-9.995a1 1 0 111 1 1 1 0 01-1-1zm1.353 5.6a1 1 0 111-1 1 1 0 01-1 .997zm4.243-4.243a1 1 0 111-1 1 1 0 01-1 .997z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});