define("ember-svg-jar/inlined/flash-drive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flash-drive</title><path d=\"M11 3a.5.5 0 00-.5.5v1a.5.5 0 101 0v-1A.5.5 0 0011 3zM13 3a.5.5 0 00-.5.5v1a.5.5 0 101 0v-1A.5.5 0 0013 3z\"/><path d=\"M16.643 6.652a.25.25 0 01-.143-.226V2a2 2 0 00-2-2h-5a2 2 0 00-2 2v4.426a.252.252 0 01-.143.226A1.5 1.5 0 006.5 8v11.5A4.505 4.505 0 0011 24h2a4.505 4.505 0 004.5-4.5V8a1.5 1.5 0 00-.857-1.348zM13.5 11.5h-3a1 1 0 010-2h3a1 1 0 010 2zm-4-9.25A.25.25 0 019.75 2h4.5a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25h-4.5a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});