define("ember-svg-jar/inlined/tags-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-check</title><path d=\"M18.2 9.373l.941-.96A3.985 3.985 0 0020.127 6V2a2 2 0 00-2-2l-4.258.005a3.95 3.95 0 00-2.416 1L.628 11.833a2 2 0 000 2.829L5.463 19.5a1.977 1.977 0 001.406.581h.012a1.975 1.975 0 001.41-.6l1.052-1.071a.484.484 0 00.135-.376 7.99 7.99 0 018.349-8.518.493.493 0 00.373-.143zM12.627 5.5a2 2 0 112 2 2 2 0 01-2-2z\"/><path d=\"M17.458 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-.727 9.675h-.112a1.487 1.487 0 01-1.055-.444l-1.5-1.5a.75.75 0 111.061-1.06l1.3 1.3a.251.251 0 00.377-.027l2.729-3.644a.75.75 0 011.2.9l-2.9 3.874a1.5 1.5 0 01-1.1.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});