define("ember-svg-jar/inlined/car-smart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-smart</title><circle cx=\"5.75\" cy=\"17.375\" r=\"2\"/><path d=\"M23.385 15.471a.218.218 0 01-.135-.2v-3.646a4.611 4.611 0 00-.657-2.372L21.243 7a.252.252 0 010-.252.249.249 0 01.218-.127h.292a1 1 0 000-2h-7.791a6.012 6.012 0 00-4.44 1.964l-3.14 3.454a.249.249 0 01-.185.082h-2.66a3 3 0 00-2.9 2.227l-.6 2.265a.978.978 0 00-.037.262 2.752 2.752 0 002.75 2.75.021.021 0 00.017-.008.023.023 0 00.006-.018 2.047 2.047 0 01-.023-.224 3 3 0 016 0 .249.249 0 00.25.25h6.25a.25.25 0 00.25-.25 3 3 0 016 0 .249.249 0 00.25.25h.5a.97.97 0 00.582-.2.243.243 0 01.149-.05H23a1 1 0 001-1 .981.981 0 00-.615-.904zM3.75 13.375h-1.5a.5.5 0 01-.5-.5 1.5 1.5 0 011.5-1.5h1.5a.5.5 0 01.5.5 1.5 1.5 0 01-1.5 1.5zm12-4.25a1 1 0 01-1 1H9.576a.25.25 0 01-.185-.418L11 7.935a4.008 4.008 0 012.96-1.31h1.54a.25.25 0 01.25.25z\"/><circle cx=\"18.5\" cy=\"17.375\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});