define("ember-svg-jar/inlined/camera-carry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camera-carry</title><path d=\"M11.633 2.347a.5.5 0 01.733 0L18.441 8.9a.25.25 0 00.183.08h2.045a.25.25 0 00.183-.42L13.535.666a2.141 2.141 0 00-3.061-.01L3.147 8.563a.251.251 0 00.184.42h2.044a.252.252 0 00.184-.08z\"/><circle cx=\"16\" cy=\"16.983\" r=\"3\"/><path d=\"M24 11.483a1.5 1.5 0 00-1.5-1.5h-6.382a.5.5 0 01-.447-.277L15 8.365a2.486 2.486 0 00-2.236-1.382h-2.528A2.486 2.486 0 008 8.365l-.671 1.342a.5.5 0 01-.447.276H1.5a1.5 1.5 0 00-1.5 1.5v11a1.5 1.5 0 001.5 1.5h21a1.5 1.5 0 001.5-1.5zm-18.5 2.5a1.5 1.5 0 11-1.5-1.5 1.5 1.5 0 011.5 1.5zm5.5 3a5 5 0 115 5 5.006 5.006 0 01-5-5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});