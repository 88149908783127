define("ember-svg-jar/inlined/peacock-feathers-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>peacock-feathers-up</title><path d=\"M12 .262A11.961 11.961 0 002.134 18.99a1 1 0 00.824.433h5.069a3.909 3.909 0 00.544.81 4.45 4.45 0 00.511.5l-.248 1.007h-.171a1 1 0 000 2h1.907a1 1 0 00.311-1.951l.033-.134a4.468 4.468 0 002.172 0l.033.134a1 1 0 00.311 1.951h1.907a1 1 0 100-2h-.171l-.248-1.007a4.45 4.45 0 00.511-.5 3.909 3.909 0 00.544-.81h5.069a1 1 0 00.824-.433A11.961 11.961 0 0012 .262zm0 8.621a.954.954 0 11.953-.953.953.953 0 01-.953.953zm8.5 8.54s-4.188 0-4.19-.02A27.693 27.693 0 0014 10.346c1.07-1.809.955-2.391.614-3.225a2.886 2.886 0 00-4.294-1.287L7.174 8.542a.5.5 0 00.162.852l2.678.929a27.7 27.7 0 00-2.325 7.1H3.5a9.875 9.875 0 01-1.42-4.244l3.438.626c.552.1 1.357.285 1.5-.491a.75.75 0 00-.6-.872l-4.361-.794A9.907 9.907 0 013.8 6.579l1.765.882a.75.75 0 00.666-1.341l-1.469-.735a10.016 10.016 0 013.493-2.392L8.9 4.069a.764.764 0 001.029.258.751.751 0 00.258-1.03l-.464-.772a9.988 9.988 0 014.554 0l-.464.772a.751.751 0 00.258 1.03.761.761 0 001.029-.258l.645-1.076A10 10 0 0119.3 5.448l-2.4 1.436a.75.75 0 00-.257 1.03.762.762 0 001.028.257l2.567-1.54a9.9 9.9 0 011.706 5.017l-4.361.794a.75.75 0 00-.6.872.766.766 0 00.872.6l4.064-.739a9.875 9.875 0 01-1.419 4.248z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});