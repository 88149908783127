define("ember-svg-jar/inlined/heavy-equipment-excavator-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>heavy-equipment-excavator-1</title><path d=\"M14.131 6.855a.25.25 0 00.064-.437L5.732.2a1.5 1.5 0 00-2.127 1.854L1.632 6H.5a.5.5 0 00-.5.5 2.5 2.5 0 001.5 2.29v2.841a1 1 0 10-.947 1.763l.947.474v1.763l-.053-.026a1 1 0 00-.894 1.789l.947.474v1.263a1 1 0 10-.947 1.763l.947.474V23a1 1 0 002 0v-.632a1.042 1.042 0 00.5.132 1 1 0 00.448-1.895l-.948-.474v-1.263A1.042 1.042 0 004 19a1 1 0 00.448-1.895l-.948-.474v-1.763A1.042 1.042 0 004 15a1 1 0 00.448-1.895l-.948-.474V8.79A2.5 2.5 0 005 6.5a.5.5 0 00-.5-.5h-.632l1.16-2.319 5.565 5.831a.25.25 0 00.4-.047 5.95 5.95 0 013.138-2.61z\"/><path d=\"M24 14.5a.5.5 0 00-.5-.5H19V9.5A1.5 1.5 0 0017.5 8h-1.318a4.473 4.473 0 00-4.38 3.469L11.117 14H10a2.5 2.5 0 00-2.5 2.5v1.184a.25.25 0 00.367.221 3.5 3.5 0 015.125 2.861.251.251 0 00.25.234h3.516a.25.25 0 00.25-.234 3.509 3.509 0 016.527-1.534A.25.25 0 0024 19.1zm-10.209-1a.252.252 0 01-.247-.288l.208-1.363A2.3 2.3 0 0116.009 10h.741a.249.249 0 01.25.25v3a.25.25 0 01-.25.25z\"/><path d=\"M23 21a2.5 2.5 0 10-2.5 2.5A2.5 2.5 0 0023 21zm-3.25 0a.75.75 0 11.75.75.75.75 0 01-.75-.75zM9.5 18.5A2.5 2.5 0 1012 21a2.5 2.5 0 00-2.5-2.5zm0 3.25a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});