define("ember-svg-jar/inlined/history-caveman-symbols", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-caveman-symbols</title><path d=\"M23.64 5.243l-6-5A1 1 0 0017 .011h-2a1 1 0 00-1 1v11a1 1 0 002 0v-9.75a.25.25 0 01.25-.25h.3a.248.248 0 01.16.058l5.2 4.331a.251.251 0 01.09.2v5.415a1 1 0 002 0v-6a1 1 0 00-.36-.772zM6.207 7.3a1 1 0 00-1.414 0L2.427 9.67A.25.25 0 012 9.493V7.029a.249.249 0 01.073-.177l4.134-4.134A1 1 0 004.793 1.3L2.427 3.67A.25.25 0 012 3.493V1.011a1 1 0 00-2 0v18a1 1 0 002 0v-5.982a.249.249 0 01.073-.177l4.134-4.134a1 1 0 000-1.418zM10.3 12.389a1.036 1.036 0 00-1.6 0l-3 4a1 1 0 00.063 1.276l2.226 2.428a.251.251 0 010 .338l-1.726 1.882a1 1 0 101.474 1.352l1.579-1.722a.247.247 0 01.368 0l1.579 1.722a1 1 0 001.474-1.352l-1.726-1.882a.251.251 0 010-.338l2.226-2.428a1 1 0 00.063-1.276zm.762 4.689l-1.378 1.5a.249.249 0 01-.368 0l-1.378-1.5a.251.251 0 01-.016-.319L9.3 14.922a.25.25 0 01.4 0l1.378 1.837a.251.251 0 01-.016.319z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});