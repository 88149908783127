define("ember-svg-jar/inlined/task-list-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-list-text</title><path d=\"M18.487 18.237a.253.253 0 00-.25.25v5.089a.251.251 0 00.305.244 6.993 6.993 0 005.278-5.279.251.251 0 00-.049-.21.248.248 0 00-.2-.094z\"/><path d=\"M16.737 18.487a1.752 1.752 0 011.75-1.75h5.25a.25.25 0 00.25-.25V2.012a2 2 0 00-2-2H2.013a2 2 0 00-2 2v19.975a2 2 0 002 2h14.474a.25.25 0 00.25-.25zm4.026-7.225a.75.75 0 110 1.5h-13a.25.25 0 00-.25.25v3.25a.25.25 0 00.25.25h6.5a.75.75 0 010 1.5h-6.5a.25.25 0 00-.25.25v2.5a.75.75 0 01-1.5 0v-2.5a.25.25 0 00-.25-.25h-2.5a.75.75 0 110-1.5h2.5a.25.25 0 00.25-.25v-3.25a.25.25 0 00-.25-.25h-2.5a.75.75 0 010-1.5h2.5a.25.25 0 00.25-.25v-3.25a.25.25 0 00-.25-.25h-2.5a.75.75 0 110-1.5h2.5a.25.25 0 00.25-.25v-2.5a.75.75 0 011.5 0v2.5a.25.25 0 00.25.25h13a.75.75 0 010 1.5h-13a.25.25 0 00-.25.25v3.25a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});