define("ember-svg-jar/inlined/single-man-home.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-home</title><path d=\"M23.5 8.943a1.456 1.456 0 00-.625-1.2L12.831.76a1.46 1.46 0 00-1.662 0L1.124 7.747A1.458 1.458 0 00.5 8.943v13.1A1.457 1.457 0 001.956 23.5h20.088a1.457 1.457 0 001.456-1.457zM9.261 12.669a.251.251 0 00-.052-.123A1.373 1.373 0 018.963 12a1.579 1.579 0 01.15-1.177.253.253 0 00.029-.206A3.061 3.061 0 019.16 8.1a1.248 1.248 0 01.734-.471.247.247 0 00.137-.086 3.3 3.3 0 012.495-.772c1.21.028 2.578.437 2.79 1.5a4.55 4.55 0 01-.428 2.372.25.25 0 00.018.218A1.592 1.592 0 0115.037 12a1.373 1.373 0 01-.246.543.245.245 0 00-.052.123 2.622 2.622 0 01-.753 1.512.248.248 0 00-.073.176v.834a.25.25 0 00.163.234l.393.146c2.008.738 3.459 1.271 3.913 2.179a6.216 6.216 0 01.618 2.52.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5 6.216 6.216 0 01.618-2.517c.453-.907 1.9-1.44 3.911-2.178l.395-.147a.25.25 0 00.163-.234v-.834a.248.248 0 00-.073-.176 2.622 2.622 0 01-.753-1.512z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});