define("ember-svg-jar/inlined/expand-vertical-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>expand-vertical-1</title><path d=\"M7 17a1 1 0 00-.707 1.707l5 5a1 1 0 001.414 0l5-5A1 1 0 0017 17h-3.5a.25.25 0 01-.25-.25v-9.5A.25.25 0 0113.5 7H17a1 1 0 00.707-1.707l-5-5a1 1 0 00-1.414 0l-5 5A1 1 0 007 7h3.5a.25.25 0 01.25.25v9.5a.25.25 0 01-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});