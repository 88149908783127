define("ember-svg-jar/inlined/road-sign-stop-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-stop-arrow</title><path d=\"M23.53 11.47l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0l11-11a.749.749 0 000-1.06zM9.97 6.22l1.5-1.5a.751.751 0 011.06 0l1.5 1.5a.75.75 0 01-.53 1.28H13a.25.25 0 00-.25.25v.5a.75.75 0 01-1.5 0v-.5A.25.25 0 0011 7.5h-.5a.75.75 0 01-.53-1.28zM16 15.449a.5.5 0 01-.146.354l-2.054 2.05a.5.5 0 01-.35.147h-2.9a.5.5 0 01-.353-.147L8.146 15.8A.5.5 0 018 15.449v-2.9a.5.5 0 01.146-.353l2.054-2.05a.5.5 0 01.35-.146h2.9a.5.5 0 01.353.146l2.051 2.054a.5.5 0 01.146.35z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});