define("ember-svg-jar/inlined/smiley-blessed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-blessed</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM6.435 8.144A1.207 1.207 0 007.5 9a1.207 1.207 0 001.065-.856 1 1 0 011.87.712A3.187 3.187 0 017.5 11a3.187 3.187 0 01-2.935-2.144 1 1 0 011.87-.712zm.624 7.565a.438.438 0 01.379-.22h9.123a.439.439 0 01.38.658 5.706 5.706 0 01-9.882 0 .436.436 0 010-.438zM16.5 11a3.187 3.187 0 01-2.935-2.144 1 1 0 111.87-.712A1.207 1.207 0 0016.5 9a1.207 1.207 0 001.065-.856 1 1 0 111.87.712A3.187 3.187 0 0116.5 11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});