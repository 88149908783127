define("ember-svg-jar/inlined/currency-yuan-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-yuan-bubble</title><path d=\"M21.526 8.25a.249.249 0 01-.134-.324 4.079 4.079 0 00-5.3-5.3.249.249 0 01-.324-.134 4.1 4.1 0 00-7.53 0 .249.249 0 01-.324.134 4.079 4.079 0 00-5.3 5.3.25.25 0 01-.134.325 4.077 4.077 0 000 7.5.25.25 0 01.134.325 4.079 4.079 0 005.3 5.3.249.249 0 01.324.134 4.1 4.1 0 007.53 0 .249.249 0 01.324-.134 4.079 4.079 0 005.3-5.3.25.25 0 01.134-.325 4.078 4.078 0 000-7.501zm-4.561-1.168L13.8 11.609A.25.25 0 0014 12h.966a1 1 0 010 2h-1.7a.249.249 0 00-.25.249v1a.25.25 0 00.25.25h1.7a1 1 0 010 2h-1.7a.25.25 0 00-.25.25v1.748a1 1 0 01-2 0v-1.752a.249.249 0 00-.249-.25h-1.7a1 1 0 110-2h1.7a.249.249 0 00.249-.25v-1a.248.248 0 00-.249-.245h-1.7a1 1 0 110-2h.965a.25.25 0 00.205-.393L7.065 7.082A1 1 0 018.7 5.937l3.109 4.44a.25.25 0 00.409 0l3.108-4.44a1 1 0 011.637 1.145z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});