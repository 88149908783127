define("ember-svg-jar/inlined/network-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>network-disable</title><path d=\"M22.267 8.995a.939.939 0 00.9-1.375A11.941 11.941 0 0011.938.014 12.253 12.253 0 00.034 11.092a12.012 12.012 0 0011.214 12.892.91.91 0 00.908-.632.924.924 0 00-.272-.982 18.993 18.993 0 01-3.2-4.576.251.251 0 01.144-.347.988.988 0 00.672-.936 1 1 0 00-1-1h-.4a.251.251 0 01-.244-.2 15.606 15.606 0 01-.1-6.1.248.248 0 01.244-.2c10.542 0 13.453.003 14.267-.016zM2.819 15.511a.25.25 0 01-.235-.167 9.883 9.883 0 01-.177-6.154.249.249 0 01.239-.179h2.807A.25.25 0 015.7 9.3a17.9 17.9 0 00-.2 2.713 17.27 17.27 0 00.295 3.2.248.248 0 01-.053.206.25.25 0 01-.192.091zm5.222 5.157a.251.251 0 01-.311.372 10.048 10.048 0 01-3.8-3.131.25.25 0 01.2-.4h2.094a.25.25 0 01.234.162 12.84 12.84 0 001.583 2.997zM6.3 6.839a.25.25 0 01-.237.172H3.8a.25.25 0 01-.21-.386 10.04 10.04 0 014.143-3.644.25.25 0 01.311.37A13.15 13.15 0 006.3 6.839zm2.425.172a.25.25 0 01-.231-.346 10.064 10.064 0 013.36-4.383.249.249 0 01.291 0A10.157 10.157 0 0115.5 6.665a.25.25 0 01-.231.346zm9.216 0a.249.249 0 01-.237-.172 13.189 13.189 0 00-1.776-3.521.249.249 0 01.308-.372 9.892 9.892 0 014.2 3.681.25.25 0 01-.211.384z\"/><path d=\"M18.905 10.677a6.635 6.635 0 00-7.976 7.974 6.538 6.538 0 004.922 4.926 6.837 6.837 0 001.517.17A6.624 6.624 0 0023.83 15.6a6.542 6.542 0 00-4.925-4.923zM15.177 21.2a.25.25 0 01-.056-.4l5.932-5.932a.25.25 0 01.4.056 4.629 4.629 0 01-6.276 6.276zm-1.066-7.338a4.646 4.646 0 015.472-.8.25.25 0 01.056.4l-5.933 5.932a.249.249 0 01-.395-.056 4.654 4.654 0 01.8-5.479z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});