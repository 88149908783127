define("ember-svg-jar/inlined/safety-flame-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-flame-right</title><path d=\"M14.707 14.031a.989.989 0 00-.946.1 6.448 6.448 0 01-.672.4.25.25 0 01-.364-.2A9.7 9.7 0 0010.6 9.06a9.762 9.762 0 00-5.03-3.33 1 1 0 00-1.193 1.348 6.814 6.814 0 01.512 2.247c.007.119.007.239.007.359a6.8 6.8 0 01-.343 2.133.25.25 0 01-.455.045 6.967 6.967 0 01-.362-.742 1 1 0 00-1.644-.312A7.693 7.693 0 00-.065 16.17c0 .14 0 .28.008.416a7.752 7.752 0 1015.365-1.815 1 1 0 00-.601-.74zM7.685 22.57a3.067 3.067 0 01-3.053-3.139 5.776 5.776 0 013.347-5.167.25.25 0 01.312.365 3.308 3.308 0 00-.284 2.933.251.251 0 00.4.085 1.738 1.738 0 00.478-.689.249.249 0 01.351-.142 2.834 2.834 0 011.5 2.7 2.994 2.994 0 01-3.051 3.054zM19.919.225a.5.5 0 00-.854.354v2.5a.249.249 0 01-.25.25h-5.25a1.25 1.25 0 000 2.5h5.25a.249.249 0 01.25.25v2.5a.5.5 0 00.309.462.512.512 0 00.191.038.5.5 0 00.354-.147l4-4a.5.5 0 000-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});