define("ember-svg-jar/inlined/earpods-charge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earpods-charge</title><path d=\"M10.5 4.5A4.743 4.743 0 005.556 0 5.173 5.173 0 001.9 1.485a1.385 1.385 0 00-.4.979v4.072a1.382 1.382 0 00.4.979 5.357 5.357 0 005.182 1.257.5.5 0 01.641.48v1.5a.25.25 0 00.25.25h2.277a.25.25 0 00.25-.25zM4 5.333a1 1 0 111-1 1 1 0 01-1 1zM21.5 12.5h-19a.75.75 0 00-.75.75v6A4.756 4.756 0 006.5 24h11a4.756 4.756 0 004.75-4.75v-6.005a.75.75 0 00-.75-.745zm-7.186 6.044L11.359 21.6a.5.5 0 01-.859-.348v-1.919a.25.25 0 00-.25-.25h-.563a.686.686 0 01-.615-.368.648.648 0 01.115-.754l2.954-3.061a.5.5 0 01.859.35v1.917a.25.25 0 00.25.25h.562a.687.687 0 01.616.368.649.649 0 01-.114.754zM22.1 7.515a1.385 1.385 0 00.4-.979V2.464a1.382 1.382 0 00-.4-.979A5.169 5.169 0 0018.444 0 4.743 4.743 0 0013.5 4.5v6.25a.25.25 0 00.25.25h2.277a.25.25 0 00.25-.25v-1.5a.5.5 0 01.641-.479A5.362 5.362 0 0022.1 7.515zM20 3.333a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});