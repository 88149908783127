define("ember-svg-jar/inlined/renewable-energy-water-dam-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>renewable-energy-water-dam-1</title><path d=\"M.758 2.4C2.611 3.194 3 5.213 3 6.767V13.6a6.33 6.33 0 001.866 4.5 1.25 1.25 0 001.768-1.768A3.9 3.9 0 015.5 13.6V6.767C5.5 3.507 4.166 1.14 1.742.1a1.249 1.249 0 00-.984 2.3zM11.491 18.1a1.25 1.25 0 001.768-1.768 3.9 3.9 0 01-1.134-2.732V6.767c0-3.26-1.334-5.627-3.758-6.665a1.249 1.249 0 00-.984 2.3c1.853.8 2.242 2.814 2.242 4.368v6.83a6.33 6.33 0 001.866 4.5zM18.116 18.1a1.25 1.25 0 001.768-1.768A3.9 3.9 0 0118.75 13.6V6.767c0-3.26-1.334-5.627-3.758-6.665a1.249 1.249 0 00-.984 2.3c1.853.8 2.242 2.814 2.242 4.368v6.83a6.33 6.33 0 001.866 4.5zM22.93 21.953c-1.568.13-2.668-1.556-2.676-1.569a1.043 1.043 0 00-1.719.029 3.359 3.359 0 01-2.749 1.552 2.8 2.8 0 01-2.4-1.483 1.045 1.045 0 00-1.717-.156 4.3 4.3 0 01-3.105 1.639A3.562 3.562 0 015.8 20.384a1.041 1.041 0 00-1.637-.091c-.014.018-1.466 1.824-3.109 1.7A.983.983 0 000 22.942a1 1 0 00.947 1.052c.075 0 .148.006.221.006a5.711 5.711 0 003.559-1.463.249.249 0 01.328.007 5.184 5.184 0 003.5 1.421 5.787 5.787 0 003.645-1.359.249.249 0 01.332.016 4.549 4.549 0 003.259 1.343 5.116 5.116 0 003.455-1.343.25.25 0 01.334 0 4.707 4.707 0 003.494 1.325A1 1 0 0024 22.882a1.015 1.015 0 00-1.07-.929z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});