define("ember-svg-jar/inlined/public-service-police-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>public-service-police-1</title><path d=\"M10.5 6.555h4a.5.5 0 00.5-.5v-.25a2.5 2.5 0 00-5 0v.25a.5.5 0 00.5.5zM23 16.555a.25.25 0 01-.25-.25v-2.25a2 2 0 00-2-2h-.477a.251.251 0 01-.224-.138l-1.625-3.259a1.992 1.992 0 00-1.794-1.1H8.62a1.993 1.993 0 00-1.795 1.1L5.2 11.917a.251.251 0 01-.224.138H2a2 2 0 00-2 2v2.5a1.49 1.49 0 001.351 1.487.249.249 0 00.27-.178 3 3 0 015.761.01.251.251 0 00.24.181h7.506a.251.251 0 00.24-.181 2.992 2.992 0 015.85.461.249.249 0 00.248.22H23a1 1 0 000-2zm-11.25-5.25a.25.25 0 01-.25.25H8.024a.25.25 0 01-.224-.362l.751-1.5a.249.249 0 01.223-.138H11.5a.249.249 0 01.25.25zm5.692.132a.25.25 0 01-.212.118H14a.25.25 0 01-.25-.25V9.8a.25.25 0 01.25-.25h2.48a.249.249 0 01.224.138l.75 1.5a.252.252 0 01-.012.249z\"/><circle cx=\"4.5\" cy=\"18.695\" r=\"2\"/><circle cx=\"18.25\" cy=\"18.695\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});