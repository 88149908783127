define("ember-svg-jar/inlined/shooting-rifle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shooting-rifle</title><path d=\"M23.817.793a1 1 0 00-1.395-.239l-.417.3a.554.554 0 01-.764-.121 1 1 0 10-1.6 1.2.482.482 0 01.089.358.474.474 0 01-.2.313l-5.7 4.037-.01.007-.011.008-1.309.977a.5.5 0 01-.7-.1l-.355-.474a1 1 0 10-1.6 1.2l.356.475a.5.5 0 01-.1.7l-2.779 2.072a.317.317 0 01-.388-.5l.447-.358a1 1 0 00-1.249-1.563l-2.617 2.093a1 1 0 001.249 1.561.458.458 0 01.342-.1.453.453 0 01.306.179l.382.51a.5.5 0 01-.1.7l-5.082 3.8A1.523 1.523 0 00.3 19.969l2.318 3.091a1.531 1.531 0 001.356.606 1.512 1.512 0 001.226-.835l3.286-6.556a4.01 4.01 0 011.179-1.41l6.2-4.638a1.5 1.5 0 00.3-2.1.552.552 0 01.123-.781l7.292-5.16a1 1 0 00.237-1.393z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});