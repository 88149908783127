define("ember-svg-jar/inlined/swimming-pool-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>swimming-pool-person</title><path d=\"M7.073 14.075a2.112 2.112 0 001.487.874 2.792 2.792 0 001.888-1.017 2.5 2.5 0 014.287.39 1.279 1.279 0 001.051.627 1.868 1.868 0 001.477-.846 2.276 2.276 0 01.579-.659.25.25 0 00.049-.342l-4.609-6.454a1.5 1.5 0 01.857-2.327l3.924-.982a1.5 1.5 0 00-.728-2.91l-3.925.981a4.5 4.5 0 00-2.569 6.982l.295.413-5.5 3.78a.25.25 0 00.068.445 2.451 2.451 0 011.369 1.045z\"/><circle cx=\"18.5\" cy=\"7.684\" r=\"2.375\"/><path d=\"M8.56 18.448a5.86 5.86 0 003.81-1.5 4.576 4.576 0 003.416 1.5 5.153 5.153 0 003.622-1.5 4.779 4.779 0 003.661 1.482A1 1 0 0024 17.366a1.022 1.022 0 00-1.068-.929 3.362 3.362 0 01-2.675-1.569 1.049 1.049 0 00-1.716.022 3.372 3.372 0 01-2.753 1.558 2.806 2.806 0 01-2.4-1.481 1.043 1.043 0 00-1.717-.157 4.3 4.3 0 01-3.1 1.638 3.555 3.555 0 01-2.76-1.58 1.042 1.042 0 00-1.638-.091c-.013.018-1.467 1.82-3.109 1.7A.981.981 0 000 17.426a1 1 0 00.946 1.051c.076 0 .149.006.222.006A5.836 5.836 0 004.9 16.876a5.233 5.233 0 003.66 1.572zM22.93 21.569A3.387 3.387 0 0120.255 20a1.048 1.048 0 00-1.716.022 3.372 3.372 0 01-2.753 1.558 2.8 2.8 0 01-2.4-1.482 1.044 1.044 0 00-1.717-.156 4.3 4.3 0 01-3.1 1.638A3.558 3.558 0 015.8 20a1.041 1.041 0 00-1.638-.091c-.013.018-1.39 1.706-2.992 1.706h-.117A.978.978 0 000 22.557a1 1 0 00.946 1.052c.076 0 .149.006.222.006A5.841 5.841 0 004.9 22.008a5.238 5.238 0 003.66 1.572 5.86 5.86 0 003.81-1.5 4.576 4.576 0 003.416 1.5 5.153 5.153 0 003.622-1.5 4.818 4.818 0 003.661 1.482A1 1 0 0024 22.5a1.015 1.015 0 00-1.07-.931z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});