define("ember-svg-jar/inlined/spa-stone-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>spa-stone-1</title><path d=\"M12 15C1.537 15-.006 19.535.772 22.234a2.4 2.4 0 002.3 1.737L20.909 24a2.4 2.4 0 002.3-1.7C24.031 19.61 22.548 15 12 15zM12.75 13.5c6.733 0 8.528-.692 8.528-2.625S19.483 6.5 12.75 6.5s-8.528 2.442-8.528 4.375S6.017 13.5 12.75 13.5zM11.525 5c4.687 0 5.937-.494 5.937-1.875S16.212 0 11.525 0 5.587 1.744 5.587 3.125 6.837 5 11.525 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});