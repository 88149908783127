define("ember-svg-jar/inlined/school-book-apple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>school-book-apple</title><path d=\"M9.945 14.943a2.282 2.282 0 001.778-.495.445.445 0 01.554 0 2.282 2.282 0 001.778.495c2.18 0 4.021-3.912 4.021-6.369 0-2.585-1.638-4.61-3.728-4.61a3.6 3.6 0 00-2.075.476c-.223.129-.223.187-.82-.062a4.985 4.985 0 00-1.318-.392 1.01 1.01 0 00-.777.219 1.028 1.028 0 00-.372.732C8.84 7.458 7.57 7.98 6.827 8.066a.987.987 0 00-.876 1.063c.221 2.315 1.927 5.814 3.994 5.814z\"/><ellipse cx=\"13.679\" cy=\"1.821\" rx=\"2.392\" ry=\".954\" transform=\"rotate(-45 13.68 1.82)\"/><path d=\"M20 16H1a1 1 0 000 2h6a2 2 0 010 4H1a1 1 0 000 2h19a4 4 0 000-8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});