define("ember-svg-jar/inlined/single-woman-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-time</title><path d=\"M17.5 0A6.5 6.5 0 1024 6.5 6.508 6.508 0 0017.5 0zm0 11A4.5 4.5 0 1122 6.5a4.505 4.505 0 01-4.5 4.5z\"/><path d=\"M20.5 6.5a1 1 0 00-1-1h-.75a.25.25 0 01-.25-.25v-1a1 1 0 00-2 0V6.5a1 1 0 001 1h2a1 1 0 001-1zM11.982 17.868l-1.8-6.285a4.255 4.255 0 00-1.238-2.125.5.5 0 00-.764.1l-1.755 2.76a.5.5 0 01-.846 0L3.839 9.54a.5.5 0 00-.358-.23.5.5 0 00-.4.128 4.219 4.219 0 00-1.254 2.129l-1.8 6.3A.5.5 0 00.5 18.5h2.323a.251.251 0 01.246.2L4 23.6a.5.5 0 00.489.4h3a.5.5 0 00.49-.4l.931-4.9a.249.249 0 01.245-.2H11.5a.5.5 0 00.482-.632zM5.979 8.531a4.25 4.25 0 10-4.25-4.25 4.255 4.255 0 004.25 4.25zm0-2a2.249 2.249 0 01-2.16-1.64.249.249 0 01.16-.3A4.67 4.67 0 005.8 3.447a.25.25 0 01.357 0 4.67 4.67 0 001.821 1.14.249.249 0 01.159.3 2.247 2.247 0 01-2.158 1.644z\"/><path d=\"M17.5 0A6.5 6.5 0 1024 6.5 6.508 6.508 0 0017.5 0zm0 11A4.5 4.5 0 1122 6.5a4.505 4.505 0 01-4.5 4.5z\"/><path d=\"M20.5 6.5a1 1 0 00-1-1h-.75a.25.25 0 01-.25-.25v-1a1 1 0 00-2 0V6.5a1 1 0 001 1h2a1 1 0 001-1zM11.982 17.868l-1.8-6.285a4.255 4.255 0 00-1.238-2.125.5.5 0 00-.764.1l-1.755 2.76a.5.5 0 01-.846 0L3.839 9.54a.5.5 0 00-.358-.23.5.5 0 00-.4.128 4.219 4.219 0 00-1.254 2.129l-1.8 6.3A.5.5 0 00.5 18.5h2.323a.251.251 0 01.246.2L4 23.6a.5.5 0 00.489.4h3a.5.5 0 00.49-.4l.931-4.9a.249.249 0 01.245-.2H11.5a.5.5 0 00.482-.632zM5.979 8.531a4.25 4.25 0 10-4.25-4.25 4.255 4.255 0 004.25 4.25zm0-2a2.249 2.249 0 01-2.16-1.64.249.249 0 01.16-.3A4.67 4.67 0 005.8 3.447a.25.25 0 01.357 0 4.67 4.67 0 001.821 1.14.249.249 0 01.159.3 2.247 2.247 0 01-2.158 1.644z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});