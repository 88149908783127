define("ember-svg-jar/inlined/multiple-actions-setting.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-setting</title><path d=\"M15.721 9.273a.25.25 0 00-.065-.429 71.482 71.482 0 00-1.187-.445l-.485-.173a1.8 1.8 0 01-.055-1.172 4.648 4.648 0 001.215-3.568A3.219 3.219 0 0012 0a2.994 2.994 0 00-1.342.314.25.25 0 00-.1.363 5 5 0 01.842 2.809 6.271 6.271 0 01-.922 3.624.248.248 0 00-.029.215.254.254 0 00.15.157c1.529.57 3.27 1.21 3.941 2.611a.251.251 0 00.439.023 3.228 3.228 0 01.742-.843z\"/><path d=\"M10.447 12.789a3.244 3.244 0 012.811-1.627.252.252 0 00.208-.112.25.25 0 00.023-.234 3.422 3.422 0 00-.108-.239c-.454-.907-1.9-1.44-3.911-2.178l-.485-.173a1.791 1.791 0 01-.056-1.172 4.644 4.644 0 001.216-3.568A3.219 3.219 0 007 0a3.219 3.219 0 00-3.146 3.486 4.611 4.611 0 001.192 3.538 1.885 1.885 0 010 1.185l-.515.19C2.522 9.137 1.072 9.67.618 10.577A7.542 7.542 0 000 13.5a.5.5 0 00.5.5h9.329a.25.25 0 00.245-.2 3.27 3.27 0 01.373-1.011z\"/><circle cx=\"17.749\" cy=\"17.031\" r=\"1.5\"/><path d=\"M23.516 15.654a1.782 1.782 0 00-1.7-2.953l-1.411.327a.454.454 0 01-.534-.311l-.423-1.392a1.775 1.775 0 00-3.4 0l-.423 1.392a.457.457 0 01-.534.311l-1.41-.328a1.782 1.782 0 00-1.7 2.952l.988 1.065a.463.463 0 010 .625l-.988 1.065a1.783 1.783 0 001.7 2.953l1.411-.327a.456.456 0 01.534.311l.423 1.391a1.775 1.775 0 003.4 0l.423-1.392a.453.453 0 01.534-.311l1.411.327a1.782 1.782 0 001.7-2.952l-.988-1.066a.463.463 0 010-.625zm-5.767 4.377a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});