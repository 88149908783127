define("ember-svg-jar/inlined/saving-bank-cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>saving-bank-cash</title><path d=\"M12 6.5V2a2 2 0 00-2-2H2a2 2 0 00-2 2v4.5a2 2 0 002 2h8a2 2 0 002-2zM2 2.25A.25.25 0 012.25 2h7.5a.25.25 0 01.25.25v4a.25.25 0 01-.25.25h-7.5A.25.25 0 012 6.25zM23 22h-.75a.25.25 0 01-.25-.25v-3.5a.25.25 0 01.25-.25h.25a1 1 0 000-2h-5a1 1 0 000 2h.25a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25h-3.5a.25.25 0 01-.25-.25v-3.5a.25.25 0 01.25-.25h.25a1 1 0 000-2h-5a1 1 0 000 2h.25a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25H9a1 1 0 000 2h14a1 1 0 000-2zM8.5 13.768v.732a.5.5 0 00.5.5h14a.5.5 0 00.5-.5v-.732a.5.5 0 00-.223-.416l-7-4.667a.5.5 0 00-.554 0l-7 4.667a.5.5 0 00-.223.416z\"/><circle cx=\"6\" cy=\"4.25\" r=\"1.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});