define("ember-svg-jar/inlined/responsive-design-expand-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>responsive-design-expand-1</title><path d=\"M19.687 18.962a.5.5 0 00.544-.108l2.123-2.121a.5.5 0 000-.708L20.231 13.9a.5.5 0 00-.853.354v.871a.25.25 0 01-.25.25h-2.785a1 1 0 000 2h2.785a.25.25 0 01.25.25v.875a.5.5 0 00.309.462zM3.121 13a1 1 0 001-1V9.215a.25.25 0 01.25-.25h.871a.5.5 0 00.358-.854L3.475 5.989a.516.516 0 00-.707 0L.646 8.111A.5.5 0 001 8.965h.871a.25.25 0 01.25.25V12a1 1 0 001 1zM24 3a3 3 0 00-3-3H5a3 3 0 00-3 3v1a1 1 0 001 1h18.5a.5.5 0 01.5.5v7a1 1 0 002 0zM6.346 3a.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.979.979 0 01.092-.408A1 1 0 015.5 1.5a.985.985 0 01.846.5.969.969 0 01.154.5.969.969 0 01-.154.5zm3.5 0a.966.966 0 01-1.692 0A.969.969 0 018 2.5a.969.969 0 01.154-.5.966.966 0 011.692 0 .969.969 0 01.154.5.969.969 0 01-.154.5zm3.5 0a.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.969.969 0 01.154-.5.966.966 0 011.692 0 .969.969 0 01.154.5.969.969 0 01-.154.5zM13 16.75A2.753 2.753 0 0010.25 14h-7.5A2.753 2.753 0 000 16.75v4.5A2.752 2.752 0 002.75 24h7.5A2.752 2.752 0 0013 21.25zm-11.5 4.5v-3a.5.5 0 01.5-.5h9a.5.5 0 01.5.5v3a1.251 1.251 0 01-1.25 1.25h-7.5a1.251 1.251 0 01-1.25-1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});