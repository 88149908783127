define("ember-svg-jar/inlined/read-glasses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-glasses</title><path d=\"M5 16.751a1 1 0 000 2h16a1 1 0 000-2z\"/><path d=\"M24 14.251a1 1 0 00-1-1H4.5a4.5 4.5 0 000 9H23a1 1 0 000-2H4.5a2.5 2.5 0 010-5H23a1 1 0 001-1zM1 7.751h.209a.5.5 0 01.481.364 5 5 0 009.81-1.364.5.5 0 011 0 5 5 0 009.81 1.364.5.5 0 01.481-.364H23a1 1 0 000-2h-.209a.5.5 0 01-.481-.363 4.979 4.979 0 00-9.046-1.25.5.5 0 01-.57.217 2.368 2.368 0 00-1.388 0 .5.5 0 01-.57-.217 4.979 4.979 0 00-9.046 1.25.5.5 0 01-.481.363H1a1 1 0 000 2zm16.5-4a3 3 0 11-3 3 3 3 0 013-3zm-11 0a3 3 0 11-3 3 3 3 0 013-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});