define("ember-svg-jar/inlined/lens-shade-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lens-shade-1</title><path d=\"M16 18H8a.5.5 0 00-.5.5v1A1.5 1.5 0 009 21h6a1.5 1.5 0 001.5-1.5v-1a.5.5 0 00-.5-.5zM15 22H9a.5.5 0 00-.5.5A1.5 1.5 0 0010 24h4a1.5 1.5 0 001.5-1.5.5.5 0 00-.5-.5zM17.5 10.5a.5.5 0 00-.5-.5H7a.5.5 0 00-.5.5v5A1.5 1.5 0 008 17h8a1.5 1.5 0 001.5-1.5zm-1.5 4a.75.75 0 01-1.5 0v-2a.75.75 0 011.5 0zm-4-2.75a.75.75 0 01.75.75v2a.75.75 0 01-1.5 0v-2a.75.75 0 01.75-.75zm-2.5.75v2a.75.75 0 01-1.5 0v-2a.75.75 0 011.5 0zM20.522 2.481a1.413 1.413 0 00-2.2.173L17.2 4.5a.35.35 0 01-.638-.1L15.8 1.349A1.779 1.779 0 0014.07 0H9.93A1.779 1.779 0 008.2 1.349L7.438 4.4a.35.35 0 01-.638.1L5.679 2.655a1.412 1.412 0 00-2.2-.175 1.351 1.351 0 00-.305 1.1l.669 4.02A1.675 1.675 0 005.5 9h13a1.675 1.675 0 001.657-1.4l.669-4.011a1.352 1.352 0 00-.304-1.108z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});