define("ember-svg-jar/inlined/harddrive-download-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>harddrive-download-1</title><path d=\"M10.549 5.646a.25.25 0 01-.25.25H8.6a.976.976 0 00-.69 1.667l3.416 3.416a.977.977 0 001.381 0l3.413-3.416a.976.976 0 00-.69-1.663h-1.7a.25.25 0 01-.25-.25V1.469a1.465 1.465 0 00-2.929 0zM20.812 14.2H3.187A2.94 2.94 0 00.25 17.14v3.917A2.941 2.941 0 003.187 24h17.625a2.941 2.941 0 002.938-2.938V17.14a2.94 2.94 0 00-2.938-2.94zm-15.911 5a.735.735 0 01.734-.735h3.617a.735.735 0 110 1.469H5.635a.734.734 0 01-.735-.727zm13.953 1.361a1.469 1.469 0 111.469-1.461 1.471 1.471 0 01-1.469 1.468z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});