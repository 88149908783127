define("ember-svg-jar/inlined/boxing-boxer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>boxing-boxer</title><circle cx=\"10.566\" cy=\"2.5\" r=\"2.5\"/><path d=\"M18.73 5.75h-3.663a1 1 0 00-1 1h-5a1.5 1.5 0 00-1.382.916l-6.133 14.25a1.5 1.5 0 102.764 1.168l2.36-5.675 2.154.783a1.5 1.5 0 01.986 1.408v2.9a1.5 1.5 0 003 0v-2.9a4.511 4.511 0 00-2.962-4.228L8.1 14.734a.25.25 0 01-.142-.338L9.994 9.9a.248.248 0 01.228-.147h3.845a1 1 0 001 1h1.381l.126.253a3.164 3.164 0 005.993-1.416 3.842 3.842 0 00-3.837-3.84zm.673 5a1.155 1.155 0 01-1.04-.642l-.4-.8a1 1 0 00-.894-.554h-1v-1h2.661a1.84 1.84 0 011.837 1.837A1.166 1.166 0 0119.4 10.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});