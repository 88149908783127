define("ember-svg-jar/inlined/single-neutral-actions-cart.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-cart</title><path d=\"M11.06 15.126a2.008 2.008 0 01.273-1.8 1.92 1.92 0 01.45-.445.5.5 0 00.015-.8A7.452 7.452 0 007.25 10.5a7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h10.282a.5.5 0 00.474-.659z\"/><circle cx=\"7.044\" cy=\"4.75\" r=\"4.75\"/><path d=\"M15.725 23.989a1.423 1.423 0 10-1.425-1.423 1.424 1.424 0 001.425 1.423zM20.851 22.577A1.423 1.423 0 1019.428 24a1.424 1.424 0 001.423-1.423zM23.443 10.789a.746.746 0 00-.948.474l-.8 2.384a.5.5 0 01-.475.342h-8.264a.5.5 0 00-.474.659l1.846 5.5a.5.5 0 00.474.341h6.067a.5.5 0 00.481-.365l2.568-8.387a.75.75 0 00-.475-.948z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});