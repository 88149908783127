define("ember-svg-jar/inlined/indent-decrease", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>indent-decrease</title><path d=\"M22.874 1h-17.5a1 1 0 100 2h17.5a1 1 0 000-2zM22.874 21h-17.5a1 1 0 100 2h17.5a1 1 0 000-2zM22.874 16h-11.5a1 1 0 000 2h11.5a1 1 0 000-2zM22.874 11h-11.5a1 1 0 000 2h11.5a1 1 0 000-2zM22.874 6h-11.5a1 1 0 000 2h11.5a1 1 0 000-2zM6.375 17.5a1 1 0 001-1V7.169a1 1 0 00-1.665-.747L.463 11.085a1 1 0 000 1.5L5.71 17.25a1.007 1.007 0 00.665.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});