define("ember-svg-jar/inlined/file-rtf-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-rtf-1</title><path d=\"M22 0H2A1.5 1.5 0 00.5 1.5v21A1.5 1.5 0 002 24h20a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022 0zM5.775 16.389l2.667 2.668a.625.625 0 01-.884.883l-2.8-2.8s-.134-.056-.134.091V19.5a.625.625 0 01-1.25 0v-8A.625.625 0 014 10.873h2a2.625 2.625 0 010 5.25h-.188c-.077 0-.159.144-.037.266zM14 12.123h-1.126a.249.249 0 00-.249.249V19.5a.625.625 0 01-1.25 0v-7.128a.249.249 0 00-.249-.249H10a.625.625 0 010-1.25h4a.625.625 0 010 1.25zm6 0h-2.5a.877.877 0 00-.875.875v1.626a.249.249 0 00.249.249H18.5a.625.625 0 010 1.25h-1.626a.249.249 0 00-.249.249V19.5a.625.625 0 11-1.25 0V13a2.127 2.127 0 012.125-2.127H20a.625.625 0 010 1.25z\"/><path d=\"M7.375 13.5A1.377 1.377 0 006 12.123H4.874a.249.249 0 00-.249.249v2.249a.252.252 0 00.252.252H6A1.377 1.377 0 007.375 13.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});