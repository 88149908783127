define("ember-svg-jar/inlined/volume-control-medium-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>volume-control-medium-1</title><path d=\"M13.189 3.017a9.725 9.725 0 00-7.924 4.85.251.251 0 01-.221.133H4.75a3 3 0 00-3 3v2a3 3 0 003 3h.294a.251.251 0 01.221.133 9.728 9.728 0 007.924 4.85h.06a1 1 0 001-1V4.015a1 1 0 00-1.06-.998zM19.335 5.452a1 1 0 00-1.414 1.415 7.944 7.944 0 010 11.239 1 1 0 101.414 1.414 9.944 9.944 0 000-14.068z\"/><path d=\"M16.886 7.982A1 1 0 0015.47 9.4a4.38 4.38 0 010 6.18 1 1 0 001.414 1.414 6.381 6.381 0 00.002-9.012z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});