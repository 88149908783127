define("ember-svg-jar/inlined/electronics-crystal-diode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>electronics-crystal-diode</title><path d=\"M6.978 11.282a.252.252 0 00-.213-.071.255.255 0 00-.184.129 4.445 4.445 0 00.063 4.341.248.248 0 01-.04.3L.293 22.294a1 1 0 101.414 1.414L8.018 17.4a.25.25 0 01.3-.04 4.44 4.44 0 004.342.063.249.249 0 00.058-.4zM23.707.294a1 1 0 00-1.414 0L17.67 4.917a.251.251 0 01-.3.04 4.476 4.476 0 00-5.409.719L7.9 9.733a.25.25 0 000 .354l6.01 6.01a.252.252 0 00.354 0l4.056-4.056a4.5 4.5 0 00.719-5.409.251.251 0 01.041-.3l4.623-4.623a1 1 0 00.004-1.415zM11.97 8.849a2.227 2.227 0 011.83-.638.249.249 0 01.149.425l-1.1 1.1a1 1 0 101.414 1.414l1.1-1.1a.254.254 0 01.26-.059.25.25 0 01.165.208 2.246 2.246 0 11-3.819-1.35z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});