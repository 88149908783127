define("ember-svg-jar/inlined/beanie-winter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beanie-winter</title><path d=\"M13.841 4.189a2.5 2.5 0 10-3.682 0A9.017 9.017 0 003 13v1.5a1 1 0 001 1h16a1 1 0 001-1V13a9.017 9.017 0 00-7.159-8.811z\"/><rect x=\"6.5\" y=\"17\" width=\"4.5\" height=\"7\" rx=\".5\" ry=\".5\"/><rect x=\"12.5\" y=\"17\" width=\"5\" height=\"7\" rx=\".5\" ry=\".5\"/><path d=\"M21.5 17h-2a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h2a2.5 2.5 0 002.5-2.5v-2a2.5 2.5 0 00-2.5-2.5zM5 17.5a.5.5 0 00-.5-.5h-2A2.5 2.5 0 000 19.5v2A2.5 2.5 0 002.5 24h2a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});