define("ember-svg-jar/inlined/paginate-filter-music", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paginate-filter-music</title><path d=\"M22 0H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2V2a2 2 0 00-2-2zm-2.75 11.5a2.5 2.5 0 11-2.5-2.5 2.475 2.475 0 011 .212V6a.251.251 0 00-.3-.245l-5 1.072a.25.25 0 00-.2.244V13.5a2.5 2.5 0 11-2.5-2.5 2.475 2.475 0 011 .212V7.069a1.76 1.76 0 011.383-1.711l5-1.071A1.75 1.75 0 0119.25 6z\"/><path d=\"M19.5 22h-17a.5.5 0 01-.5-.5v-17a1 1 0 00-2 0V22a2 2 0 002 2h17.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});