define("ember-svg-jar/inlined/retouch-face", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>retouch-face</title><path d=\"M17.606 12.231a7.594 7.594 0 00-15.187 0c0 .048-.024 4.764-2.172 7.791A1.332 1.332 0 00.46 21.8c1.94 1.688 6.232 2.244 9.546 2.2 3.856.047 7.778-.649 9.559-2.2a1.33 1.33 0 00.213-1.778c-2.143-3.022-2.172-7.743-2.172-7.791zM4.588 16.1v-2.5a.251.251 0 01.262-.25c.081 0 .163.006.245.006A5.737 5.737 0 009.8 10.883a.25.25 0 01.423 0 5.738 5.738 0 004.706 2.476c.082 0 .164 0 .245-.006a.255.255 0 01.185.069.252.252 0 01.077.181v2.5a5.591 5.591 0 01-5.424 5.308A5.591 5.591 0 014.588 16.1z\"/><path d=\"M12.047 18.368a.75.75 0 10-.918-1.187 1.879 1.879 0 01-2.124 0 .75.75 0 10-.918 1.187 3.288 3.288 0 003.96 0zM23.266 3.643a3.185 3.185 0 01-2.91-2.867V.75a.75.75 0 00-.749-.75.75.75 0 00-.75.748 3.184 3.184 0 01-2.9 2.895.746.746 0 00-.735.755.754.754 0 00.754.745 3.111 3.111 0 012.884 2.9.752.752 0 00.735.744h.015a.75.75 0 00.749-.713 3.326 3.326 0 012.895-2.93.75.75 0 00.016-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});