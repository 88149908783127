define("ember-svg-jar/inlined/gardening-seed-hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gardening-seed-hand</title><rect x=\"20\" y=\"1.253\" width=\"4\" height=\"9\" rx=\"1\" ry=\"1\"/><path d=\"M7.707 14.509a1 1 0 10-1.414-1.415L4.34 15.046a1 1 0 101.414 1.415zM13.7 11.531a1 1 0 00-1.383 1.445l2 1.91a1 1 0 001.382-1.445zM17.646 14.268a1 1 0 00-1.157.814l-.474 2.72a1 1 0 101.97.344l.475-2.721a1 1 0 00-.814-1.157zM13.122 18.951a1 1 0 00.02-1.414l-1.925-1.98a1 1 0 10-1.434 1.393l1.925 1.981a1 1 0 001.414.02zM14.151 20.324l-2.237 1.619a1 1 0 101.172 1.62l2.238-1.619a1 1 0 00-1.173-1.62zM9.42 19.782l-2.684-.653a1 1 0 10-.472 1.944l2.684.652a1 1 0 10.472-1.943zM3.672 19.181a1 1 0 00-1.394.239L.684 21.676a1 1 0 101.632 1.155l1.6-2.256a1 1 0 00-.244-1.394zM2.645 14.474a1 1 0 00.844-1.134l-.4-2.731a1 1 0 10-1.978.289l.4 2.732a1 1 0 001.134.844zM18.661 2.386c-.863-.295-1.62-.56-2.292-.8C10.9-.335 10.793-.327 5.535 2.5c-1.35.727-3.03 1.631-5.259 2.745a.5.5 0 00-.13.8A3.247 3.247 0 003.67 7.166L9.387 5.1a.5.5 0 01.288-.016l1.912.462a.284.284 0 01.1.506C9.963 7.313 8.18 8.619 8.811 9.784a1.129 1.129 0 001.043.739 9.357 9.357 0 002.9-1.007c.647-.283 1.435-.627 2.4-1.033a.5.5 0 01.351-.013l2.832.945A.5.5 0 0019 8.941V2.859a.5.5 0 00-.339-.473z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});