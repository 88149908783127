define("ember-svg-jar/inlined/non-gmo-spray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>non-gmo-spray</title><path d=\"M1.25 6h2.5a.75.75 0 000-1.5h-2.5a.75.75 0 000 1.5zM1.834 2.376l1.5 1a.75.75 0 00.832-1.248l-1.5-1a.75.75 0 00-.832 1.248zM5.75 6.752h2a.962.962 0 00.723-.332.5.5 0 01.374-.168h.81a.249.249 0 01.242.311L9.081 9.83a1 1 0 101.94.485l.276-1.1a.279.279 0 01.154-.185 1.012 1.012 0 00.293-.2l4.745-4.745a.338.338 0 00-.239-.585h-1a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v2a.5.5 0 01-.5.5h-1a.751.751 0 00-.728.568.239.239 0 01-.232.182H8.847a.5.5 0 01-.373-.168.966.966 0 00-.724-.332h-2a.5.5 0 00-.5.5v2a.5.5 0 00.5.502zM3.334 7.128l-1.5 1a.75.75 0 10.832 1.248l1.5-1a.75.75 0 10-.832-1.248zM23.97 11.51L22.5 5.646a2.49 2.49 0 00-.934-1.4.25.25 0 01-.028-.377l2.165-2.165a1 1 0 00-1.41-1.411l-22 22a1 1 0 101.414 1.414l5.866-5.866a.25.25 0 01.427.177V21.5a2.5 2.5 0 002.5 2.5H17a2.5 2.5 0 002.5-2.5v-8.748a1 1 0 00-.051-.316l-1.52-4.56a.5.5 0 01.12-.511l.466-.467a.5.5 0 01.354-.146h.49a.5.5 0 01.486.379L21.061 12a1 1 0 00.97.757H23a1 1 0 00.97-1.242zm-6.47 3.24a.5.5 0 01-.5.5h-6.233a.25.25 0 01-.176-.427l5.087-5.087a.5.5 0 01.828.2l.943 2.829a1.025 1.025 0 01.051.316z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});