define("ember-svg-jar/inlined/wifi-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-star</title><path d=\"M20.786 23.416a1.257 1.257 0 001.483-.164 1.236 1.236 0 00.311-1.428l-1.2-2.77a.249.249 0 01.063-.286l2.177-1.94a1.245 1.245 0 00.3-1.357 1.215 1.215 0 00-1.151-.75H20.3a.25.25 0 01-.23-.151l-1.323-3.07-.012-.026a1.274 1.274 0 00-2.282.026l-1.324 3.07a.25.25 0 01-.23.151h-2.461a1.211 1.211 0 00-1.148.748 1.252 1.252 0 00.314 1.376l2.159 1.923a.251.251 0 01.063.286l-1.2 2.769a1.235 1.235 0 00.309 1.429 1.269 1.269 0 001.483.164l3.063-1.725a.249.249 0 01.245 0zM16.661 8.878a1 1 0 000-1.414 9.823 9.823 0 00-13.875 0A1 1 0 004.2 8.878a7.821 7.821 0 0111.047 0 .993.993 0 00.707.293 1.055 1.055 0 00.183-.035.972.972 0 00.378-.161.861.861 0 00.146-.097z\"/><path d=\"M17.738 5.728a1 1 0 001.414-1.414 13.349 13.349 0 00-18.859 0 1 1 0 001.414 1.414 11.35 11.35 0 0116.031 0zM12.115 11.526a1 1 0 101.117-1.658 6.3 6.3 0 00-7.955.771 1 1 0 101.414 1.414 4.3 4.3 0 015.424-.527z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});