define("ember-svg-jar/inlined/discount-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>discount-1</title><path d=\"M22.255 1.973a1.749 1.749 0 00-2.475-.035l-18 17.5a1.75 1.75 0 002.44 2.509l18-17.5a1.749 1.749 0 00.035-2.474z\"/><circle cx=\"5\" cy=\"5.058\" r=\"3.5\"/><circle cx=\"19\" cy=\"19.058\" r=\"3.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});