define("ember-svg-jar/inlined/style-three-pin-judaism", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-judaism</title><path d=\"M12.217 4.387a.251.251 0 00-.434 0l-.579 1a.25.25 0 00.216.375h1.16a.25.25 0 00.216-.375zM6.6 13.368a.25.25 0 00.217.375h1.14a.25.25 0 00.216-.375L7.6 12.38a.25.25 0 00-.217-.125.247.247 0 00-.216.125zM6.816 7.266a.25.25 0 00-.217.375l.571.988a.25.25 0 00.216.125.252.252 0 00.214-.125l.57-.988a.248.248 0 000-.25.251.251 0 00-.217-.125zM8.324 10.379a.253.253 0 000 .25l1.725 2.989a.252.252 0 00.217.125h3.468a.252.252 0 00.217-.125l1.725-2.989a.253.253 0 000-.25l-1.725-2.988a.252.252 0 00-.217-.125h-3.468a.252.252 0 00-.217.125z\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm7.073 13.264a1.32 1.32 0 01-1.142 1.979h-3.042a.25.25 0 00-.217.125l-1.529 2.65a1.32 1.32 0 01-2.286 0l-1.529-2.65a.249.249 0 00-.217-.125H6.069a1.32 1.32 0 01-1.142-1.979l1.521-2.635a.248.248 0 000-.25L4.927 7.745a1.32 1.32 0 011.142-1.979h3.042a.252.252 0 00.217-.125l1.529-2.65a1.321 1.321 0 012.286 0l1.529 2.65a.252.252 0 00.217.125h3.042a1.32 1.32 0 011.142 1.979l-1.521 2.634a.248.248 0 000 .25z\"/><path d=\"M17.4 7.641a.25.25 0 00-.217-.375h-1.14a.251.251 0 00-.217.125.248.248 0 000 .25l.57.988a.252.252 0 00.217.125.25.25 0 00.216-.125zM15.827 13.368a.248.248 0 000 .25.251.251 0 00.217.125h1.14a.25.25 0 00.217-.375l-.571-.988a.247.247 0 00-.216-.125.25.25 0 00-.217.125zM11.783 16.622a.251.251 0 00.434 0l.579-1a.25.25 0 00-.216-.375h-1.16a.25.25 0 00-.216.375z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});