define("ember-svg-jar/inlined/navigation-menu-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-menu-horizontal</title><circle cx=\"3.25\" cy=\"12\" r=\"3.25\"/><circle cx=\"12\" cy=\"12\" r=\"3.25\"/><circle cx=\"20.75\" cy=\"12\" r=\"3.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});