define("ember-svg-jar/inlined/feed-news-vine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>feed-news-vine</title><path d=\"M13 15.5l2.54-1.82a2 2 0 00.87.21 2 2 0 10-2-2v.13L13 13V9l2.54-1.81a2 2 0 00.87.21 2 2 0 10-2-2v.14L13 6.62V1a1 1 0 00-2 0v2.62l-1.42-1v-.14a2 2 0 10-2 2 2 2 0 00.87-.21L11 6.08v4L9.58 9v-.14a2 2 0 10-2 2 2 2 0 00.87-.21L11 12.5V17l-1.42-1v-.14a2 2 0 10-2 2 2 2 0 00.87-.21L11 19.5V23a1 1 0 002 0v-.5l2.54-1.81a2 2 0 00.87.21 2 2 0 10-2-2v.14L13 20z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});