define("ember-svg-jar/inlined/phone-action-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-shield</title><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/><path d=\"M15.781 7.707A10.956 10.956 0 0011.985 7a10.864 10.864 0 00-3.766.707A1.2 1.2 0 007.5 8.8v2.261a5.316 5.316 0 003.567 4.694l.37.139a1.528 1.528 0 001.1 0l.367-.139a5.336 5.336 0 003.6-4.694V8.8a1.2 1.2 0 00-.723-1.093zM10.5 12.25a.75.75 0 010-1.5h.75V10a.75.75 0 011.5 0v.75h.75a.75.75 0 010 1.5h-.75V13a.75.75 0 01-1.5 0v-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});