define("ember-svg-jar/inlined/golf-hole-ball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>golf-hole-ball</title><circle cx=\"18.5\" cy=\"22.25\" r=\"1.5\"/><path d=\"M3.5 15v-1.5a.25.25 0 00-.25-.25H1a1 1 0 000 2h2.25A.25.25 0 003.5 15z\"/><path d=\"M23 13.25H7.25A.25.25 0 017 13V6.53a.251.251 0 01.146-.23l4.665-2.12a.75.75 0 000-1.365S6.186.25 6 .25a.975.975 0 00-1 1v17.5a.75.75 0 01-1.5 0v-.882c0-.228-.123-.187-.183-.165-1.1.409-1.817 1.059-1.817 1.8 0 1.243 2.015 2.25 4.5 2.25s4.5-1.007 4.5-2.25c0-1.031-1.391-1.9-3.283-2.162A.248.248 0 017 17.1v-1.6a.25.25 0 01.25-.25H23a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});