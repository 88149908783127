define("ember-svg-jar/inlined/smiley-crying-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-crying-alternate</title><path d=\"M15.1 21.539a10 10 0 116.608-12.267 1 1 0 101.923-.544A12 12 0 1015.7 23.445a1 1 0 10-.6-1.906z\"/><path d=\"M6.927 18.338a1 1 0 001.384-.291 4.505 4.505 0 015.443-1.727 1 1 0 10.745-1.856 6.507 6.507 0 00-7.864 2.489 1 1 0 00.292 1.385zM21.074 11.932a1 1 0 00-1.647 0c-.667.965-2.844 4.246-2.844 5.9a3.667 3.667 0 007.334 0c0-1.654-2.176-4.932-2.843-5.9zM20.25 19.5a1.67 1.67 0 01-1.667-1.667 10.18 10.18 0 011.453-3.149.25.25 0 01.429 0 10.182 10.182 0 011.452 3.15A1.669 1.669 0 0120.25 19.5zM7.083 10.935a1 1 0 00.923-.617 5.493 5.493 0 012.062-2.482 1 1 0 10-1.1-1.672 7.48 7.48 0 00-2.807 3.385 1 1 0 00.922 1.386zM17.083 10.935a1 1 0 00.923-1.386A7.48 7.48 0 0015.2 6.164a1 1 0 00-1.1 1.672 5.493 5.493 0 012.062 2.482 1 1 0 00.921.617z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});