define("ember-svg-jar/inlined/sign-badge-rectangular-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sign-badge-rectangular-alternate</title><path d=\"M21.75 0H2.25A2.252 2.252 0 000 2.25v19.5A2.252 2.252 0 002.25 24h19.5A2.252 2.252 0 0024 21.75V2.25A2.252 2.252 0 0021.75 0zm-.25 21.5h-19v-19h19z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});