define("ember-svg-jar/inlined/single-man-actions-share-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-share-1</title><path d=\"M7.044 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.743a.5.5 0 01.741-.3A6.958 6.958 0 008.5 5a6.35 6.35 0 00.929-.07.5.5 0 01.571.564A3 3 0 014.044 5a2.8 2.8 0 01.1-.743zM10.956 15.193h2.432a.5.5 0 00.439-.738A7.522 7.522 0 007.25 10.5a7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h8.223a.5.5 0 00.439-.261 1.99 1.99 0 011.75-1.046zM11.456 16.387a1 1 0 00-1 1V23a1 1 0 002 0v-5.613a1 1 0 00-1-1zM22.956 20.193h-7a.353.353 0 01-.322-.386.371.371 0 01.371-.371h1.955a.889.889 0 00.816-1.237 1.907 1.907 0 00-1.814-1.006l-3-.032a.55.55 0 00-.357.145.5.5 0 00-.149.355v5.032a.5.5 0 00.5.5h8a2.04 2.04 0 002-1.924v-.2a.971.971 0 00-1-.876z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});