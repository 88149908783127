define("ember-svg-jar/inlined/baggage-weight-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baggage-weight-2</title><path d=\"M19.25 23V11.156a.252.252 0 01.212-.247 5.5 5.5 0 10-2.4-.106.251.251 0 01.189.243v9.2a.5.5 0 01-.5.5H1a1 1 0 00-1 1V23a1 1 0 001 1h17.25a1 1 0 001-1zM18.031 4.75L19.4 2.925a.75.75 0 111.2.9L19.23 5.65a.75.75 0 11-1.2-.9z\"/><path d=\"M8 6.75H7a3 3 0 00-3 3V10a.25.25 0 01-.25.25H2.5a1.5 1.5 0 00-1.5 1.5v6a1.5 1.5 0 001.5 1.5h10a1.5 1.5 0 001.5-1.5v-6a1.5 1.5 0 00-1.5-1.5h-1.25A.25.25 0 0111 10v-.25a3 3 0 00-3-3zM9 10a.25.25 0 01-.25.25h-2.5A.25.25 0 016 10v-.25a1 1 0 011-1h1a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});