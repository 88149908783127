define("ember-svg-jar/inlined/light-mode-flash-auto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-mode-flash-auto</title><path d=\"M11 15a4 4 0 015.622-3.656.25.25 0 00.3-.077A.74.74 0 0016.4 10h-2.5a.25.25 0 01-.208-.389l1.964-2.946a.75.75 0 00-.623-1.165h-3.724a1 1 0 00-.895.552l-3.182 6.365A.75.75 0 007.9 13.5h1.56a.25.25 0 01.237.329l-1.672 5.013a.5.5 0 00.829.512l2.073-2.074A.249.249 0 0011 17.1z\"/><path d=\"M15 12.25A2.752 2.752 0 0012.25 15v3.5a.75.75 0 001.5 0v-1.25A.25.25 0 0114 17h2a.25.25 0 01.25.25v1.25a.75.75 0 001.5 0V15A2.752 2.752 0 0015 12.25zm-1 3.25a.25.25 0 01-.25-.25V15a1.25 1.25 0 012.5 0v.25a.25.25 0 01-.25.25z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm6.838 19.29a10.115 10.115 0 01-1.538 1.188 10.024 10.024 0 111.541-1.188z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});