define("ember-svg-jar/inlined/data-file-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-home</title><path d=\"M17 10.452a.968.968 0 00.105-.124l2.688-3.734a1 1 0 10-1.624-1.168l-2.015 2.8a.251.251 0 01-.332.068L14.3 7.388a1 1 0 00-1.331.28l-2.311 3.264a1 1 0 001.631 1.157l1.644-2.321a.249.249 0 01.332-.07l1.507.9A1 1 0 0017 10.452z\"/><path d=\"M21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.868a.25.25 0 00.33.232 2.479 2.479 0 011.34 0A.25.25 0 008 9.867V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-5.38a.248.248 0 00-.229.165 3.088 3.088 0 01-1.07 1.39.248.248 0 00.154.444H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M7.323 12.118a.5.5 0 00-.646 0l-6.5 5.5A.5.5 0 00.5 18.5h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-4.75a.25.25 0 01.25-.25h1.25a.5.5 0 00.323-.882z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});