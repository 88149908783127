define("ember-svg-jar/inlined/hard-drive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hard-drive</title><path d=\"M.5 15.251v4a2.007 2.007 0 002 2h19a2.006 2.006 0 002-2v-4a2.005 2.005 0 00-2-2h-19a2.006 2.006 0 00-2 2zm19.5-1a1 1 0 11-1 1 1 1 0 011-1zM3.5 17.5h17a.75.75 0 010 1.5h-17a.75.75 0 010-1.5zM2.5 11.751h19a3.4 3.4 0 011.34.276.249.249 0 00.34-.291l-1.69-6.673a3.213 3.213 0 00-2.99-2.312h-13a3.213 3.213 0 00-2.989 2.312L.82 11.736a.249.249 0 00.34.291 3.4 3.4 0 011.34-.276z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});