define("ember-svg-jar/inlined/board-game-ludo-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>board-game-ludo-1</title><path d=\"M9 .25A.25.25 0 008.75 0H1.5A1.5 1.5 0 000 1.5v7.25A.25.25 0 00.25 9H8.5a.5.5 0 00.5-.5zM4.5 6A1.5 1.5 0 116 4.5 1.5 1.5 0 014.5 6zM15 8.5a.5.5 0 00.5.5h8.25a.25.25 0 00.25-.25V1.5A1.5 1.5 0 0022.5 0h-7.25a.25.25 0 00-.25.25zm3-4A1.5 1.5 0 1119.5 6 1.5 1.5 0 0118 4.5zM9 15.5a.5.5 0 00-.5-.5H.25a.25.25 0 00-.25.25v7.25A1.5 1.5 0 001.5 24h7.25a.25.25 0 00.25-.25zm-3 4A1.5 1.5 0 114.5 18 1.5 1.5 0 016 19.5zM15 23.75a.25.25 0 00.25.25h7.25a1.5 1.5 0 001.5-1.5v-7.25a.25.25 0 00-.25-.25H15.5a.5.5 0 00-.5.5zM19.5 18a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5z\"/><rect x=\"10.5\" y=\"5.5\" width=\"3\" height=\"3.5\" rx=\".25\" ry=\".25\"/><rect x=\"10.5\" width=\"3\" height=\"3.5\" rx=\".25\" ry=\".25\"/><rect x=\"10.5\" y=\"15\" width=\"3\" height=\"3.5\" rx=\".25\" ry=\".25\"/><rect x=\"10.5\" y=\"20.5\" width=\"3\" height=\"3.5\" rx=\".25\" ry=\".25\"/><rect x=\"5.75\" y=\"10.25\" width=\"3\" height=\"3.5\" rx=\".25\" ry=\".25\" transform=\"rotate(-90 7.25 12)\"/><rect x=\".25\" y=\"10.25\" width=\"3\" height=\"3.5\" rx=\".25\" ry=\".25\" transform=\"rotate(-90 1.75 12)\"/><rect x=\"15.25\" y=\"10.25\" width=\"3\" height=\"3.5\" rx=\".25\" ry=\".25\" transform=\"rotate(-90 16.75 12)\"/><rect x=\"20.75\" y=\"10.25\" width=\"3\" height=\"3.5\" rx=\".25\" ry=\".25\" transform=\"rotate(-90 22.25 12)\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});