define("ember-svg-jar/inlined/real-estate-insurance-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-insurance-building</title><rect y=\"14.029\" width=\"4\" height=\"9\" rx=\"1\" ry=\"1\"/><path d=\"M20.363 17.111h-.015l-2 .639a.5.5 0 00-.348.484 2.25 2.25 0 01-2.249 2.291H10.5a.5.5 0 010-1h5.25a1.25 1.25 0 000-2.5l-3.685.008a4.339 4.339 0 01-.572-.383A6.842 6.842 0 007.5 15.029h-2a.5.5 0 00-.5.5v5.9a.5.5 0 00.339.474c3.741 1.269 5.667 2.069 7.335 2.069 2.329 0 4.255-1.534 11.049-4.932a.5.5 0 00.13-.8 3.237 3.237 0 00-3.49-1.129zM15.724 5.082l-3-1.5a.5.5 0 00-.447 0l-3 1.5A.5.5 0 009 5.529v7.5a.5.5 0 00.5.5H11a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-7.5a.5.5 0 00-.276-.447zM14 9.529h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zM23.693 2.567l-6-2.5A.5.5 0 0017 .529v12.5a.5.5 0 00.5.5H19a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-10a.5.5 0 00-.307-.462zM22 9.529h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});