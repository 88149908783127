define("ember-svg-jar/inlined/style-three-pin-fire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-fire</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm5.5 10.5a6.632 6.632 0 01-5.49 6.5 6.676 6.676 0 01-5.51-6.5V6.137a1.273 1.273 0 01.74-1.157A11.686 11.686 0 0112 4a11.71 11.71 0 014.761.979 1.273 1.273 0 01.74 1.157z\"/><path d=\"M11.992 6.191c-.453-.133-.643.081-.7.177-.173.276.042.563.268.867a2.374 2.374 0 01.47.851 2.254 2.254 0 01-.118 1.556.266.266 0 01-.22.108c-.252 0-.342-.207-.448-.67-.065-.281-.145-.63-.469-.63-.948 0-1.525 1.65-1.525 2.55a2.75 2.75 0 005.5 0c0-2.8-1.826-4.541-2.758-4.809z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});