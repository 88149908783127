define("ember-svg-jar/inlined/outdoors-camp-flame", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-camp-flame</title><path d=\"M18.95 2.276l-.45-.894A2.5 2.5 0 0016.266 0H7.739A2.5 2.5 0 005.5 1.382l-.448.894A.49.49 0 005.5 3H6v8a4 4 0 004 4h.75a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H9a2 2 0 00-2 2v4a1 1 0 001 1h8a1 1 0 001-1v-4a2 2 0 00-2-2h-1.75a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25H14a4 4 0 004-4V3h.5a.491.491 0 00.45-.724zM16 11a2 2 0 01-2 2h-4a2 2 0 01-2-2V3.25A.25.25 0 018.253 3h7.5a.25.25 0 01.25.25z\"/><path d=\"M11.775 4.514a.174.174 0 00-.212.26 2.563 2.563 0 01.256 2.334.251.251 0 01-.291.085 1.361 1.361 0 01-.4-.576.171.171 0 00-.1-.108.175.175 0 00-.145 0A2.2 2.2 0 009.562 8.56a2.441 2.441 0 004.881 0 4.488 4.488 0 00-2.668-4.046z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});