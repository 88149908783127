define("ember-svg-jar/inlined/lucide-file-volume", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4 22h14a2 2 0 002-2V7.5L14.5 2H6a2 2 0 00-2 2v3\"/><path d=\"M14 2v6h6M7 10l-3 2H2v4h2l3 2v-8zM11 11c.64.8 1 1.87 1 3s-.36 2.2-1 3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});