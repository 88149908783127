define("ember-svg-jar/inlined/halloween-figure-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>halloween-figure-1</title><circle cx=\"8.5\" cy=\"14.245\" r=\"1.5\"/><circle cx=\"15.5\" cy=\"14.245\" r=\"1.5\"/><path d=\"M15.5 17.745h-7a1 1 0 000 2h7a1 1 0 100-2z\"/><path d=\"M21.75 19.5a1 1 0 00-.943.667.5.5 0 01-.471.333h-.985a.351.351 0 01-.219-.626l1.987-1.594a.994.994 0 00.37-.706l1.069-14.605a2.767 2.767 0 00-.711-2.082A2.728 2.728 0 0019.82 0H4.18a2.728 2.728 0 00-2.027.887 2.767 2.767 0 00-.711 2.082l1.069 14.605a.994.994 0 00.37.706l1.988 1.594a.352.352 0 01-.22.626h-.984a.5.5 0 01-.472-.333 1 1 0 00-1.943.333v2a1 1 0 001.943.333.5.5 0 01.472-.333h1.052a.5.5 0 01.458.3A1.993 1.993 0 007 24h10a1.993 1.993 0 001.825-1.2.5.5 0 01.458-.3h1.053a.5.5 0 01.471.333 1 1 0 001.943-.333v-2a1 1 0 00-1-1zM3.817 8.037l-.2-2.694A.25.25 0 014 5.114l.976.623a1.741 1.741 0 002.064-.13l1.315-1.1a1 1 0 011.281 0l1.244 1.037a1.748 1.748 0 002.24 0l1.241-1.034a1 1 0 011.281 0l1.315 1.1a1.741 1.741 0 002.064.13L20 5.114a.25.25 0 01.384.229l-.2 2.694a.5.5 0 01-.5.463H18a.5.5 0 01-.5-.5 1 1 0 00-2 0 .5.5 0 01-.5.5h-1.5A.5.5 0 0113 8a1 1 0 00-2 0 .5.5 0 01-.5.5H9a.5.5 0 01-.5-.5 1 1 0 00-2 0 .5.5 0 01-.5.5H4.316a.5.5 0 01-.499-.463zm13.936 10.384A1.979 1.979 0 0017 19.98v1.52a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5v-1.52a1.979 1.979 0 00-.753-1.559l-1.435-1.154a1 1 0 01-.37-.706l-.405-5.524a.5.5 0 01.5-.537H6a.5.5 0 01.5.5 1 1 0 002 0 .5.5 0 01.5-.5h1.5a.5.5 0 01.5.5 1 1 0 002 0 .5.5 0 01.5-.5H15a.5.5 0 01.5.5 1 1 0 002 0 .5.5 0 01.5-.5h1.465a.5.5 0 01.5.537l-.405 5.524a1 1 0 01-.37.706z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});