define("ember-svg-jar/inlined/religion-buddhism", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-buddhism</title><path d=\"M24 12a12 12 0 10-12 12 12.017 12.017 0 0012-12zM2.083 13.282A.25.25 0 012.331 13h6.652a.25.25 0 01.177.427l-4.7 4.7a.249.249 0 01-.374-.024 9.946 9.946 0 01-2.003-4.821zm2-7.385a.249.249 0 01.374-.024l4.7 4.7a.25.25 0 01-.174.427H2.331a.25.25 0 01-.188-.085.253.253 0 01-.06-.2A9.946 9.946 0 014.086 5.9zm17.831 4.821a.25.25 0 01-.248.282h-6.649a.25.25 0 01-.177-.427l4.7-4.7a.249.249 0 01.374.024 9.946 9.946 0 012.003 4.821zm-8.49-1.559A.25.25 0 0113 8.982V2.331a.25.25 0 01.282-.248 9.95 9.95 0 014.819 2 .25.25 0 01.024.375zM11 8.982a.25.25 0 01-.427.177l-4.7-4.7a.249.249 0 01-.073-.193.251.251 0 01.1-.182 9.95 9.95 0 014.819-2 .25.25 0 01.281.247zm-.427 5.859a.25.25 0 01.427.177v6.651a.25.25 0 01-.282.248 9.95 9.95 0 01-4.819-2 .25.25 0 01-.024-.375zm2.427.177a.25.25 0 01.427-.177l4.7 4.7a.249.249 0 01.073.193.251.251 0 01-.1.182 9.95 9.95 0 01-4.819 2 .25.25 0 01-.281-.247zm1.84-1.591a.25.25 0 01.177-.427h6.652a.25.25 0 01.188.085.253.253 0 01.06.2 9.946 9.946 0 01-2 4.821.249.249 0 01-.374.024z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});