define("ember-svg-jar/inlined/temperature-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>temperature-down</title><path d=\"M10.5 4.5a4.5 4.5 0 00-9 0v9.438a.248.248 0 01-.06.162A5.941 5.941 0 000 18a6 6 0 0012 0 5.941 5.941 0 00-1.44-3.9.248.248 0 01-.06-.162zM6 22a4 4 0 01-2.8-6.856 1 1 0 00.3-.714V4.5a2.5 2.5 0 015 0v9.93a1 1 0 00.3.714A4 4 0 016 22z\"/><path d=\"M7.111 16.337A.25.25 0 017 16.129V6.5a1 1 0 00-2 0v9.629a.25.25 0 01-.111.208 2 2 0 102.222 0zM15.293 7.207a1 1 0 001.262.125l2.2-1.469a.248.248 0 01.3.02l3.28 2.87a1 1 0 001.316-1.506l-4-3.5a1 1 0 00-1.213-.079L16.3 5.1a.25.25 0 01-.315-.031l-2.278-2.276a1 1 0 00-1.414 1.414zM24 12.5a1 1 0 00-1-1H13a1 1 0 000 2h10a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});