define("ember-svg-jar/inlined/exotic-food-oyster", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>exotic-food-oyster</title><path d=\"M12 22.5c-5.817 0-12-1.928-12-5.5a3.255 3.255 0 01.869-2.154 1 1 0 011.514 1.308A1.331 1.331 0 002 17c0 1.461 3.8 3.5 10 3.5s10-2.039 10-3.5a1.335 1.335 0 00-.383-.847 1 1 0 011.514-1.307A3.257 3.257 0 0124 17c0 3.572-6.183 5.5-12 5.5z\"/><path d=\"M12 15.5c-3.414 0-6.3-.94-7.964-2.42a.252.252 0 00-.231-.054.249.249 0 00-.173.163A2.589 2.589 0 003.5 14c0 2.523 3.733 4.5 8.5 4.5s8.5-1.977 8.5-4.5a2.589 2.589 0 00-.132-.811.251.251 0 00-.4-.109C18.3 14.56 15.414 15.5 12 15.5z\"/><path d=\"M12 11.5c-3.414 0-6.3-.94-7.964-2.42a.252.252 0 00-.231-.054.249.249 0 00-.173.163A2.589 2.589 0 003.5 10c0 2.523 3.733 4.5 8.5 4.5s8.5-1.977 8.5-4.5a2.589 2.589 0 00-.132-.811.251.251 0 00-.4-.109C18.3 10.56 15.414 11.5 12 11.5z\"/><path d=\"M12 1.5C7.233 1.5 3.5 3.477 3.5 6s3.733 4.5 8.5 4.5 8.5-1.977 8.5-4.5-3.733-4.5-8.5-4.5zM6.25 7A.75.75 0 117 6.25a.75.75 0 01-.75.75zM7.5 4.25a.75.75 0 11.75.75.75.75 0 01-.75-.75zM9.25 8a.75.75 0 11.75-.75.75.75 0 01-.75.75zm2.5-2a.75.75 0 11.75-.75.75.75 0 01-.75.75zm1 3a.75.75 0 11.75-.75.75.75 0 01-.75.75zm1.5-4.5a.75.75 0 11.75-.75.75.75 0 01-.75.75zm1.5 3a.75.75 0 11.75-.75.75.75 0 01-.75.75zm2-1.5a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});