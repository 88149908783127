define("ember-svg-jar/inlined/smiley-shook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-shook</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm7.582 10.793a1 1 0 11-1.414 1.414L16.8 10.841a.25.25 0 00-.354 0l-1.366 1.366a1 1 0 01-1.414-1.414l1.366-1.366a.25.25 0 000-.354l-1.364-1.366a1 1 0 011.414-1.414l1.366 1.366a.25.25 0 00.354 0l1.366-1.366a1 1 0 111.414 1.414l-1.366 1.366a.25.25 0 000 .354zM4.418 7.707a1 1 0 011.414-1.414L7.2 7.659a.25.25 0 00.354 0l1.364-1.366a1 1 0 111.414 1.414L8.966 9.073a.25.25 0 000 .354l1.366 1.366a1 1 0 11-1.414 1.414l-1.366-1.366a.25.25 0 00-.354 0l-1.366 1.366a1 1 0 01-1.414-1.414l1.366-1.366a.25.25 0 000-.354zM9.5 17a2.5 2.5 0 015 0v1.25a2.5 2.5 0 01-5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});