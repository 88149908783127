define("ember-svg-jar/inlined/crime-man-thief-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-man-thief-2</title><path d=\"M1.481 21.033A2.3 2.3 0 00.5 22.9v.6a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-.6a2.3 2.3 0 00-.981-1.871 15.687 15.687 0 00-6.8-2.435.249.249 0 01-.214-.247V16.4a.248.248 0 01.116-.21 6.746 6.746 0 003.129-5.69v-2a6.76 6.76 0 00-4.484-6.356.247.247 0 01-.151-.152.245.245 0 01.022-.214A2.444 2.444 0 0014.5.5.5.5 0 0014 0h-4a.5.5 0 00-.5.5 2.444 2.444 0 00.363 1.278.248.248 0 01.023.214.251.251 0 01-.152.152A6.76 6.76 0 005.25 8.5v2a6.746 6.746 0 003.134 5.689.248.248 0 01.116.21v1.952a.249.249 0 01-.214.247 15.687 15.687 0 00-6.805 2.435zM12 14.75c-1.283 0-2.25-.538-2.25-1.25a2.321 2.321 0 014.5 0c0 .712-.967 1.25-2.25 1.25zm.75-6.25c0-.748 1.164-1.25 2.25-1.25a1.283 1.283 0 011.25 1.25 1.832 1.832 0 01-1.75 1.75 1.8 1.8 0 01-1.75-1.75zm-1.5 0a1.8 1.8 0 01-1.75 1.75A1.832 1.832 0 017.75 8.5 1.283 1.283 0 019 7.25c1.086 0 2.25.502 2.25 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});