define("ember-svg-jar/inlined/religion-hexagram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-hexagram</title><path d=\"M22.6 16.408l-2.76-4.553L22.6 7.3a1.25 1.25 0 00-1.1-1.947h-5.6L13.115.764a1.313 1.313 0 00-2.229 0L8.1 5.354H2.5A1.25 1.25 0 001.4 7.3l2.76 4.553-2.76 4.555a1.249 1.249 0 001.1 1.946h5.6l3.041 5.019a1 1 0 001.71 0l3.049-5.019h5.6a1.249 1.249 0 001.1-1.946zm-2.819-9.054a.251.251 0 01.214.38l-1.114 1.838a.25.25 0 01-.428 0l-1.114-1.838a.251.251 0 01.214-.38zm-2.362 4.371a.251.251 0 010 .259l-2.576 4.25a.248.248 0 01-.214.12H9.371a.248.248 0 01-.214-.12l-2.576-4.25a.251.251 0 010-.259l2.576-4.25a.249.249 0 01.214-.121h5.258a.249.249 0 01.214.121zm-5.633-8.588a.251.251 0 01.428 0l1.114 1.838a.25.25 0 01-.214.379h-2.228a.25.25 0 01-.214-.379zm-7.781 4.6a.251.251 0 01.214-.38h2.228a.251.251 0 01.214.38L5.547 9.572a.251.251 0 01-.428 0zm.214 8.62a.25.25 0 01-.214-.379l1.115-1.842a.25.25 0 01.427 0l1.114 1.839a.25.25 0 01-.213.379zm8 4.218a.251.251 0 01-.428 0l-1.114-1.838a.251.251 0 01.214-.38h2.228a.251.251 0 01.214.38zm5.339-4.218a.25.25 0 01-.214-.379l1.114-1.838a.251.251 0 01.428 0L20 15.975a.25.25 0 01-.214.379z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});