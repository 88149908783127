define("ember-svg-jar/inlined/medical-instrument", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-instrument</title><path d=\"M20.314 9.459a.5.5 0 00-.011-.714l-.048-.045-.65-.65a.5.5 0 00-.709 0l-2.372 2.393a.5.5 0 01-.709 0l-2.228-2.228a.5.5 0 010-.709l2.4-2.362a.5.5 0 000-.71l-.687-.688-.016-.016a.5.5 0 00-.707-.006L8.47 9.739a2.019 2.019 0 00-.6 1.425V14.5a.5.5 0 01-.147.353l-3.849 3.858a.249.249 0 01-.246.064 1.72 1.72 0 00-1.248.1l-1.562.781a1.251 1.251 0 00-.327 2L2.34 23.51a1.251 1.251 0 002-.326l.781-1.564a1.707 1.707 0 00.1-1.249.249.249 0 01.064-.246l3.853-3.854a.5.5 0 01.354-.146h3.377a1.984 1.984 0 001.42-.592zm-7.295 4.518a.5.5 0 01-.355.148h-2.289a.5.5 0 01-.5-.5v-2.252a.5.5 0 01.149-.356l1.429-1.407a.5.5 0 01.7 0l2.253 2.254a.5.5 0 010 .7zM16.364.564a1.5 1.5 0 000 2.121l4.95 4.951a1.5 1.5 0 002.121-2.122l-4.95-4.95a1.5 1.5 0 00-2.121 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});