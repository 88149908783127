define("ember-svg-jar/inlined/space-rocket-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>space-rocket-1</title><path d=\"M16.75 15.426V10.5a4.809 4.809 0 00-2.25-4v-2C14.5 3.424 13.012 0 12 0S9.5 3.424 9.5 4.5v2.019A4.745 4.745 0 007.25 10.5v4.926A3.283 3.283 0 005.5 18v5.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2.75a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v2.75a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2.75a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v2.75a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V18a3.283 3.283 0 00-1.75-2.574zm-2 2.824a.25.25 0 01-.25.25h-.75a.25.25 0 01-.25-.25V14.5c0-.828-1-2-1.5-2s-1.5 1.172-1.5 2v3.75a.25.25 0 01-.25.25H9.5a.25.25 0 01-.25-.25V10.5a2.777 2.777 0 01.924-2h3.653a2.78 2.78 0 01.923 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});