define("ember-svg-jar/inlined/multiple-actions-check-1.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions--check-1</title><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm.558 9.378a1.808 1.808 0 01-1.324.721h-.13a1.805 1.805 0 01-1.28-.534l-1.624-1.62a1 1 0 011.414-1.415l1.27 1.27a.249.249 0 00.377-.027l2.844-3.794a1 1 0 111.6 1.2zM14.471 8.4l-.4-.141a.255.255 0 01-.155-.162 2.28 2.28 0 01-.013-.975.246.246 0 01.055-.1 4.652 4.652 0 001.186-3.535A3.22 3.22 0 0012 0a2.994 2.994 0 00-1.342.314.25.25 0 00-.1.363 5 5 0 01.842 2.808 6.263 6.263 0 01-.922 3.624.248.248 0 00-.028.215.246.246 0 00.151.157c1.511.557 3.11 1.172 3.83 2.411a.249.249 0 00.3.109 7.864 7.864 0 011.558-.4.25.25 0 00.061-.477 40.604 40.604 0 00-1.879-.724z\"/><path d=\"M13.207 10.763a.251.251 0 00.11-.159.255.255 0 00-.037-.19c-.5-.759-1.622-1.211-3.809-2.016l-.4-.141a.25.25 0 01-.153-.157 2.28 2.28 0 01-.018-.98.246.246 0 01.055-.1 4.652 4.652 0 001.186-3.535A3.22 3.22 0 007 0a3.22 3.22 0 00-3.146 3.485A4.619 4.619 0 005.021 7a.247.247 0 01.05.084 2.329 2.329 0 01.032.987.249.249 0 01-.157.179l-.417.15c-2.007.737-3.457 1.27-3.911 2.177A7.542 7.542 0 000 13.5a.5.5 0 00.5.5h9.663a.252.252 0 00.223-.136 8.031 8.031 0 012.821-3.101z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});