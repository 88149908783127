define("ember-svg-jar/inlined/construction-cone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>construction-cone</title><path d=\"M16.011 9.364A.5.5 0 0015.53 9H8.457a.5.5 0 00-.482.364l-1.412 5a.5.5 0 00.481.636h9.9a.5.5 0 00.481-.636zM23 22h-3.028a.5.5 0 01-.481-.364l-.928-3.272a.5.5 0 00-.481-.364H5.913a.5.5 0 00-.481.364l-.925 3.272a.5.5 0 01-.481.364H1a1 1 0 000 2h22a1 1 0 000-2zM14.4 6a.5.5 0 00.481-.636l-1.156-4.059A1.8 1.8 0 0012 0a1.828 1.828 0 00-1.753 1.326L9.106 5.364A.5.5 0 009.587 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});