define("ember-svg-jar/inlined/desktop-monitor-lock-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>desktop-monitor-lock-2</title><path d=\"M14.857 6.778a.251.251 0 01-.107-.2V6a2.75 2.75 0 10-5.5 0v.572a.251.251 0 01-.107.2A1.49 1.49 0 008.5 8v3a1.5 1.5 0 001.5 1.5h4a1.5 1.5 0 001.5-1.5V8a1.49 1.49 0 00-.643-1.222zM13 9.52a1 1 0 11-1-1 1 1 0 011 1zM10.75 6a1.25 1.25 0 012.5 0 .25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v15A1.5 1.5 0 001.5 18h8.25a.25.25 0 01.25.25v2.683a.249.249 0 01-.22.248 14.345 14.345 0 00-3.66.893A1 1 0 006.5 24h11a1 1 0 00.377-1.926 14.363 14.363 0 00-3.661-.893.249.249 0 01-.22-.248v-2.682a.25.25 0 01.25-.25H22.5A1.5 1.5 0 0024 16.5v-15A1.5 1.5 0 0022.5 0zM22 13.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});