define("ember-svg-jar/inlined/maps-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>maps-search</title><path d=\"M10.824 19.868a1 1 0 00-1.394-.233l-.03.025a.25.25 0 01-.395-.2V6.657a.5.5 0 01.209-.407l4.4-3.139a.25.25 0 01.395.2V9a1 1 0 002 0V3.314a.25.25 0 01.395-.2l4.4 3.139a.5.5 0 01.196.404v4.479a1 1 0 002 0V6.143a1.506 1.506 0 00-.628-1.221l-6.5-4.643a1.5 1.5 0 00-1.744 0l-5.8 4.145a.5.5 0 01-.6-.011L2.427.321A1.5 1.5 0 000 1.5v14.886a1.505 1.505 0 00.6 1.2l6.506 4.882a1.5 1.5 0 001.772.019l1.713-1.223a1 1 0 00.233-1.396zM2 3.027a.25.25 0 01.4-.2l4.4 3.4a.5.5 0 01.2.4v12.759a.25.25 0 01-.4.2l-4.4-3.3a.5.5 0 01-.2-.4z\"/><path d=\"M23.707 23.707a1 1 0 000-1.414L21.313 19.9a.5.5 0 01-.077-.608 5.509 5.509 0 10-1.946 1.946.5.5 0 01.608.076l2.395 2.395a1 1 0 001.414 0zM13 16.5a3.5 3.5 0 113.5 3.5 3.5 3.5 0 01-3.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});