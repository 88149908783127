define("ember-svg-jar/inlined/arrow-button-down-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-down-3</title><path d=\"M23.381 2.018a1 1 0 00-1.09.219l-10.1 10.127a.25.25 0 01-.354 0L1.708 2.24A1 1 0 000 2.948V10.1a1.008 1.008 0 00.3.709l11.011 10.958a1 1 0 001.411 0L23.7 10.805a.99.99 0 00.3-.705V2.943a1 1 0 00-.619-.925z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});