define("ember-svg-jar/inlined/single-neutral-home.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-home</title><path d=\"M24 8.661a1.5 1.5 0 00-.655-1.239L12.845.263a1.488 1.488 0 00-1.69 0L.655 7.422A1.5 1.5 0 000 8.661V22.5A1.5 1.5 0 001.5 24h6a1 1 0 000-2h-5a.5.5 0 01-.5-.5V9.19a.5.5 0 01.218-.413l9.5-6.478a.5.5 0 01.564 0l9.5 6.478A.5.5 0 0122 9.19V21.5a.5.5 0 01-.5.5h-5a1 1 0 000 2h6a1.5 1.5 0 001.5-1.5z\"/><circle cx=\"12\" cy=\"8.5\" r=\"2.5\"/><path d=\"M15.5 19a.5.5 0 00.5-.5v-2.4a4 4 0 10-8 0v2.4a.5.5 0 00.5.5h1.082a.251.251 0 01.249.221l.506 4.337a.5.5 0 00.5.442h2.334a.5.5 0 00.5-.442l.506-4.337a.251.251 0 01.241-.221z\"/><path d=\"M24 8.661a1.5 1.5 0 00-.655-1.239L12.845.263a1.488 1.488 0 00-1.69 0L.655 7.422A1.5 1.5 0 000 8.661V22.5A1.5 1.5 0 001.5 24h6a1 1 0 000-2h-5a.5.5 0 01-.5-.5V9.19a.5.5 0 01.218-.413l9.5-6.478a.5.5 0 01.564 0l9.5 6.478A.5.5 0 0122 9.19V21.5a.5.5 0 01-.5.5h-5a1 1 0 000 2h6a1.5 1.5 0 001.5-1.5z\"/><circle cx=\"12\" cy=\"8.5\" r=\"2.5\"/><path d=\"M15.5 19a.5.5 0 00.5-.5v-2.4a4 4 0 10-8 0v2.4a.5.5 0 00.5.5h1.082a.251.251 0 01.249.221l.506 4.337a.5.5 0 00.5.442h2.334a.5.5 0 00.5-.442l.506-4.337a.251.251 0 01.241-.221z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});