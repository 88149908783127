define("ember-svg-jar/inlined/calendar-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>calendar-share</title><path d=\"M9.887 16.5H2.5A.5.5 0 012 16V7.5a.5.5 0 01.5-.5H16a.5.5 0 01.5.5v5.893l.556-.334a4.248 4.248 0 011.444-2.544V3a1 1 0 00-1-1H15a.25.25 0 01-.25-.25v-1a.75.75 0 10-1.5 0V4.5a.75.75 0 11-1.5 0v-2a.5.5 0 00-.5-.5H7a.25.25 0 01-.25-.25v-1a.75.75 0 00-1.5 0V4.5a.75.75 0 11-1.5 0v-2a.5.5 0 00-.5-.5H1a1 1 0 00-1 1v13.5a2 2 0 002 2h7.525c-.005-.085-.025-.165-.025-.25a4.215 4.215 0 01.387-1.75z\"/><path d=\"M21.25 18.5a2.734 2.734 0 00-2.166 1.076l-2.612-1.045c.009-.094.028-.185.028-.281a2.755 2.755 0 00-.105-.713l2.979-1.787a2.858 2.858 0 10-.769-1.288l-2.979 1.788a2.752 2.752 0 10.29 3.674l2.612 1.045c-.009.094-.028.185-.028.281a2.75 2.75 0 102.75-2.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});