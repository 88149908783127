define("ember-svg-jar/inlined/paragraph-right-align-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paragraph-right-align-alternate</title><path d=\"M23 22H1a1 1 0 100 2h22a1 1 0 000-2zM23 16.5H4.5a1 1 0 100 2H23a1 1 0 000-2zM23 11H1a1 1 0 000 2h22a1 1 0 000-2zM23 5.5H4.5a1 1 0 100 2H23a1 1 0 000-2zM1 2h22a1 1 0 000-2H1a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});