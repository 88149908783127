define("ember-svg-jar/inlined/real-estate-sign-for-sale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-sign-for-sale</title><rect x=\"4\" y=\"19.5\" width=\"4\" height=\"4.5\" rx=\".5\" ry=\".5\"/><rect x=\"16\" y=\"19.5\" width=\"4\" height=\"4.5\" rx=\".5\" ry=\".5\"/><path d=\"M16.375 3.75a.625.625 0 00-.625-.625.125.125 0 00-.125.125v1a.125.125 0 00.125.125.625.625 0 00.625-.625zM9.5 11.125a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25h.25a.25.25 0 00.25-.25V11.5a.375.375 0 00-.375-.375z\"/><path d=\"M21.5 0h-19A1.5 1.5 0 001 1.5v15A1.5 1.5 0 002.5 18h19a1.5 1.5 0 001.5-1.5v-15A1.5 1.5 0 0021.5 0zM10.375 3.5a1.625 1.625 0 013.25 0v3a1.625 1.625 0 01-3.25 0zm-4.5 9a2.451 2.451 0 011.25 2A1.627 1.627 0 015.5 16.125h-1a.625.625 0 010-1.25h1a.375.375 0 00.375-.375c0-.418-.257-.631-.75-1a2.451 2.451 0 01-1.25-2A1.627 1.627 0 015.5 9.875h1a.625.625 0 010 1.25h-1a.375.375 0 00-.375.375c0 .418.257.631.75 1zm1.75-6.125V7.5a.625.625 0 01-1.25 0v-4A1.627 1.627 0 018 1.875h1a.625.625 0 010 1.25H8a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25H8.5a.625.625 0 010 1.25h-.625a.25.25 0 00-.25.25zm3.5 9.125a.625.625 0 01-1.25 0v-1.125a.25.25 0 00-.25-.25h-.25a.25.25 0 00-.25.25V15.5a.625.625 0 01-1.25 0v-4a1.625 1.625 0 013.25 0zm3.375.625h-1a1.627 1.627 0 01-1.625-1.625v-4a.625.625 0 011.25 0v4a.375.375 0 00.375.375h1a.625.625 0 010 1.25zm1.125-8.8V7.5a.625.625 0 01-1.25 0v-5A.625.625 0 0115 1.875h.75a1.872 1.872 0 01.976 3.472.251.251 0 00-.084.342l.894 1.49a.625.625 0 11-1.072.642l-.375-.621a.25.25 0 00-.464.129zm1.75 5.549H18.5a.625.625 0 010 1.25h-1.125a.25.25 0 00-.25.25v.126a.375.375 0 00.375.375h1a.625.625 0 010 1.25h-1a1.627 1.627 0 01-1.625-1.625v-3A1.627 1.627 0 0117.5 9.875h1a.625.625 0 010 1.25h-1a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25z\"/><path d=\"M12 6.875a.375.375 0 00.375-.375v-3a.375.375 0 00-.75 0v3a.375.375 0 00.375.375z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});