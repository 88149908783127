define("ember-svg-jar/inlined/video-player-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-player-laptop</title><path d=\"M22 16h-.75a.25.25 0 01-.25-.25V5.667A2.67 2.67 0 0018.333 3H5.667A2.669 2.669 0 003 5.667V15.75a.25.25 0 01-.25.25H2a1.5 1.5 0 00-1.27 2.3A5.75 5.75 0 005.623 21h12.754a5.748 5.748 0 004.892-2.7A1.5 1.5 0 0022 16zM5.667 5h12.666a.667.667 0 01.667.667V15.75a.25.25 0 01-.25.25h-2.49a1.51 1.51 0 00-1.342.828A3.335 3.335 0 0112 18a3.337 3.337 0 01-2.918-1.171A1.49 1.49 0 007.74 16H5.25a.25.25 0 01-.25-.25V5.667A.667.667 0 015.667 5z\"/><path d=\"M10.15 13.444l4.561-1.764a1.279 1.279 0 000-2.36L10.15 7.556A.854.854 0 009 8.373v4.255a.853.853 0 001.15.816z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});