define("ember-svg-jar/inlined/notes-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-calendar</title><path d=\"M23 4.579a2 2 0 00-2-2h-1.75a.25.25 0 01-.25-.25v-.908a1 1 0 10-2 0v4.158a1 1 0 102 0v-.225a.251.251 0 01.3-.245 1.5 1.5 0 011.2 1.47v13a1.5 1.5 0 01-1.5 1.5H5a1.5 1.5 0 01-1.5-1.5v-13a1.5 1.5 0 011.286-1.485A.249.249 0 005 4.847V2.829a.25.25 0 00-.25-.25H3a2 2 0 00-2 2v17a2 2 0 002 2l18.005-.087a2.005 2.005 0 001.99-2z\"/><path d=\"M12.5 6.579a1 1 0 001-1v-.25a.25.25 0 01.25-.25h2a.25.25 0 00.25-.25v-2a.25.25 0 00-.25-.25h-2a.25.25 0 01-.25-.25v-.908a1 1 0 10-2 0v4.158a1 1 0 001 1zM7 6.579a1 1 0 001-1v-.25a.25.25 0 01.25-.25h2a.25.25 0 00.25-.25v-2a.25.25 0 00-.25-.25h-2a.25.25 0 01-.25-.25v-.908a1 1 0 00-2 0v4.158a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});