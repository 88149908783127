define("ember-svg-jar/inlined/boxing-glove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>boxing-glove</title><path d=\"M23.421 7.339A6.7 6.7 0 0016.761 0l-5.712.007a4.5 4.5 0 00-4.5 4.5L6.537 9.5a1.251 1.251 0 001.25 1.25h9.761A1.251 1.251 0 0018.8 9.5V7a.75.75 0 011.5 0v2.5a2.753 2.753 0 01-2.75 2.75H7.787a2.752 2.752 0 01-2.75-2.75V5.743a.5.5 0 00-.291-.454 3.092 3.092 0 00-1.14-.277 2.692 2.692 0 00-2.053.7 3.791 3.791 0 00-1 2.8c0 5.825 3.2 7.741 4.68 8.316a.5.5 0 01.32.466V21.5a2.5 2.5 0 002.5 2.5l10-.007a2.5 2.5 0 002.5-2.5v-6.229a1 1 0 01.289-.7c.774-.791 2.158-2.784 2.579-7.225z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});