define("ember-svg-jar/inlined/travel-crossroad-direction-board", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>travel-crossroad-direction-board</title><path d=\"M24 23a11.565 11.565 0 00-10.774-11.686.25.25 0 01-.226-.249V10.5a.25.25 0 01.25-.25H16a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-2.75A.25.25 0 0113 6v-.5a.25.25 0 01.25-.25h2.043a1 1 0 00.591-.193l1.363-1a1 1 0 000-1.612l-1.363-1a1 1 0 00-.591-.194h-2.044A.249.249 0 0113 1a1 1 0 00-2 0 .249.249 0 01-.25.25H8.5a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h2.25a.249.249 0 01.25.25V6a.249.249 0 01-.25.25H9.207a1.01 1.01 0 00-.592.194l-1.363 1a1 1 0 000 1.612l1.362 1a1 1 0 00.591.193h1.542a.249.249 0 01.25.25v.566a.25.25 0 01-.226.249A11.564 11.564 0 000 23a1 1 0 002 0 9.634 9.634 0 0110-9.751 10.234 10.234 0 016.622 2.309.25.25 0 01-.16.442h-4.357a2.652 2.652 0 100 5.3h.414a2.366 2.366 0 011.971 1.055l.946 1.418a.5.5 0 00.416.223H23A.979.979 0 0024 23z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});