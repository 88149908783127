define("ember-svg-jar/inlined/audio-file-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file-refresh</title><path d=\"M12.92 11.56a.5.5 0 00-.545.108l-1.3 1.3a.246.246 0 01-.343 0 6.31 6.31 0 00-4.075-1.716A6.464 6.464 0 000 17.543 6.374 6.374 0 0011.357 21.6 1 1 0 109.8 20.354a4.388 4.388 0 01-4.611 1.488 4.378 4.378 0 114.111-7.47.256.256 0 010 .367l-1.382 1.386a.5.5 0 00.353.854h4.458a.5.5 0 00.5-.5v-4.458a.5.5 0 00-.309-.461z\"/><path d=\"M23.414 3L21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.277a.247.247 0 00.241.249c.364.009 1.037.041 1.485.093A.246.246 0 008 9.371V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.244.244 0 00-.241.21 7.935 7.935 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M14.5 13.325c0 .181.153.161.227.135a2.247 2.247 0 001.506-1.96.734.734 0 00.017-.084v-5.1c0-.2.164-.264.282-.19l1.069.673a.75.75 0 10.8-1.269l-1.818-1.135A1.25 1.25 0 0014.75 5.5v3.625c0 .118-.074.075-.112.063a2.167 2.167 0 00-.638-.1 2.232 2.232 0 00-1.83.959c-.072.114.034.2.226.2a1.938 1.938 0 01.871.141 1.994 1.994 0 011.233 1.846z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});