define("ember-svg-jar/inlined/discount-coupon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>discount-coupon</title><path d=\"M23.5 9.5A.5.5 0 0024 9V5.5A1.5 1.5 0 0022.5 4h-21A1.5 1.5 0 000 5.5V9a.5.5 0 00.5.5 2.5 2.5 0 010 5 .5.5 0 00-.5.5v3.5A1.5 1.5 0 001.5 20h21a1.5 1.5 0 001.5-1.5V15a.5.5 0 00-.5-.5 2.5 2.5 0 010-5zM7.47 15.47l8-8a.75.75 0 011.06 1.06l-8 8a.75.75 0 01-1.06-1.06zM7 9a2 2 0 112 2 2 2 0 01-2-2zm8 4a2 2 0 11-2 2 2 2 0 012-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});