define("ember-svg-jar/inlined/style-three-pin-navigation-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-navigation-location</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm0 16a6.5 6.5 0 116.5-6.5A6.508 6.508 0 0112 16z\"/><path d=\"M14.52 5.75L9.87 7.02a.5.5 0 00-.35.35l-1.27 4.65a1 1 0 001.23 1.23l4.65-1.27a.5.5 0 00.35-.35l1.27-4.65a1 1 0 00-1.23-1.23zm.154 1.383l-.97 3.542a.25.25 0 01-.418.111L12 9.5l-1.286-1.286a.25.25 0 01.111-.418l3.542-.97a.249.249 0 01.307.307z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});