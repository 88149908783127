define("ember-svg-jar/inlined/toilet-need-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toilet-need-1</title><circle cx=\"12.25\" cy=\"2.747\" r=\"2.5\"/><path d=\"M8.16 14.851s.552.313 1.567.781a1.285 1.285 0 001.658-.61 1.253 1.253 0 00-.611-1.659l-.926-.428a2.757 2.757 0 01-1.6-2.5V10a.5.5 0 011 0v.441a1.755 1.755 0 001.017 1.589l.927.427a2.158 2.158 0 01.628.445.251.251 0 00.356 0 2.176 2.176 0 01.628-.445l.926-.427a1.754 1.754 0 001.018-1.589V10a.5.5 0 011 0v.441a2.756 2.756 0 01-1.6 2.5l-.925.428a1.253 1.253 0 00-.611 1.659 1.279 1.279 0 001.658.61c1.013-.467 1.566-.781 1.566-.781a5.046 5.046 0 002.411-4.413V10A3.254 3.254 0 0015 6.747H9A3.254 3.254 0 005.75 10v.441a5.1 5.1 0 002.41 4.41z\"/><path d=\"M14.693 16.54a2.222 2.222 0 01-2.517-.453.249.249 0 00-.352 0 2.222 2.222 0 01-2.517.453 12.315 12.315 0 01-1.624-.812.25.25 0 00-.324.047l-1.14 1.314a.5.5 0 00.475.658h2.313l.179.833-1.554 3.359a1.25 1.25 0 002.236 1.117l1.75-3.75a1.257 1.257 0 00.105-.82l-.159-.739h.872l-.159.739a1.257 1.257 0 00.105.82l1.75 3.75a1.25 1.25 0 002.236-1.117l-1.554-3.359.179-.833h2.313a.5.5 0 00.475-.658l-1.14-1.314a.25.25 0 00-.324-.047 12.091 12.091 0 01-1.624.812z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});