define("ember-svg-jar/inlined/video-game-flappy-bird", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-flappy-bird</title><path d=\"M21 11.25a.858.858 0 01-.85-.735A8.482 8.482 0 005.126 6.442a1 1 0 01-.917.365l-3.073-.422A1 1 0 000 7.375v.563a4.946 4.946 0 001.687 3.716v.784a4.383 4.383 0 002.271 3.836 4.938 4.938 0 004.917 4.476 6.572 6.572 0 002.366-.438 1.009 1.009 0 01.4-.065 8.421 8.421 0 006.275-2.683 1 1 0 01.728-.314H21a3 3 0 000-6zm-12.125 7.5a2.925 2.925 0 01-2.6-1.571.249.249 0 01.222-.366h2.94a1 1 0 000-2H6.062a2.4 2.4 0 01-.788-.134A2.371 2.371 0 013.787 13.1a.251.251 0 01.29-.314 4.321 4.321 0 00.86.092h2.875a1 1 0 000-2H4.937a3 3 0 01-1.678-.526 2.944 2.944 0 01-1.092-1.439.25.25 0 01.27-.331L9.8 9.593a4.625 4.625 0 01-.926 9.157zm7.375-8.25a.75.75 0 01-.75-.75v-1.5a.75.75 0 011.5 0v1.5a.75.75 0 01-.75.75zM21 15.25h-3a1 1 0 010-2h3a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});