define("ember-svg-jar/inlined/smiley-lol-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-lol-alternate</title><path d=\"M8.375 9.9a1 1 0 00.917.6.973.973 0 00.4-.084 1 1 0 00.515-1.316 3.5 3.5 0 00-6.414 0 1 1 0 001.832.8 1.5 1.5 0 012.75 0zM18.375 9.9a1 1 0 101.832-.8 3.5 3.5 0 00-6.415 0 1 1 0 101.832.8 1.5 1.5 0 012.751 0zM12 18.5a5.7 5.7 0 004.941-2.853.439.439 0 00-.38-.658H7.438a.44.44 0 00-.379.659A5.7 5.7 0 0012 18.5zM21.823 12.432a1.038 1.038 0 00-1.646 0C19.522 13.379 18 15.722 18 17a3 3 0 006 0c0-1.278-1.522-3.621-2.177-4.568zM21 18a1 1 0 01-1-1 6.06 6.06 0 01.782-1.772.251.251 0 01.436 0A6.08 6.08 0 0122 17a1 1 0 01-1 1z\"/><path d=\"M1.142 9.962a1 1 0 001.234-.69 10 10 0 0119.248 0 1 1 0 00.961.728.983.983 0 00.273-.038 1 1 0 00.69-1.234 12 12 0 00-23.1 0 1 1 0 00.694 1.234zM17 20.662a10.176 10.176 0 01-10 0A1 1 0 006 22.4a12.021 12.021 0 0012 0 1 1 0 00-1-1.733zM3.823 12.432a1.038 1.038 0 00-1.646 0C1.522 13.379 0 15.722 0 17a3 3 0 006 0c0-1.278-1.522-3.621-2.177-4.568zM3 18a1 1 0 01-1-1 6.1 6.1 0 01.783-1.772.25.25 0 01.435 0A6.047 6.047 0 014 17a1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});