define("ember-svg-jar/inlined/dog-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dog-small</title><path d=\"M23.485 12.515c-.626-2.5-2.573-3.879-5.485-3.879h-5.5a.5.5 0 01-.5-.5v-6a.744.744 0 00-.387-.682c-.535-.287-1.633.112-3.263 1.182h-4.7C2.02 1.566.92 1.167.387 1.454A.744.744 0 000 2.136v10c0 3.988 1.146 6.1 1.5 6.652v2.128a1.72 1.72 0 003.281.722l.946-2.044a.5.5 0 01.609-.266 3.757 3.757 0 012.528 2.4 1.353 1.353 0 001.3.905 1.513 1.513 0 001.436-1.34l.2-1.778a.5.5 0 01.485-.379h4.44a.5.5 0 01.473.315l.179 1.588a1.8 1.8 0 001.788 1.6 1.71 1.71 0 001.6-1.128 15.039 15.039 0 00.973-5.772c.716.86 1.357 1.692 1.367 1.7a.5.5 0 00.9-.3 22.241 22.241 0 00-.52-4.624zM2.5 6.884a1 1 0 111 1 1 1 0 01-1-1zm1.5 4a1.181 1.181 0 001.25-1.255.75.75 0 011.5-.015A1.179 1.179 0 008 10.884a.75.75 0 010 1.5 2.616 2.616 0 01-2-.808 2.617 2.617 0 01-2 .808.75.75 0 010-1.5zm4.5-3a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});