define("ember-svg-jar/inlined/face-id-7", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>face-id-7</title><path d=\"M11.287 4.263A6.286 6.286 0 005 10.549a5.676 5.676 0 002.432 5.035.33.33 0 01.147.271v3.56a.322.322 0 00.322.322h6.448a.323.323 0 00.323-.322v-2.136a.322.322 0 01.322-.322h.967a1.612 1.612 0 001.612-1.612v-1.279a.323.323 0 01.322-.323c.12 0 .243 0 .355-.006a.806.806 0 00.622-.336.788.788 0 00.122-.478v-.089a5.048 5.048 0 00-.917-2.417 14.866 14.866 0 01-.33-.6 1.293 1.293 0 01-.139-.537c.002-2.77-2.157-5.017-6.321-5.017zM6.5 22H2.248A.25.25 0 012 21.75V17.5a1 1 0 00-2 0V22a2 2 0 002 2h4.5a1 1 0 100-2zM1 7.5a1 1 0 001-1V2.25A.25.25 0 012.248 2H6.5a1 1 0 000-2H2a2 2 0 00-2 2v4.5a1 1 0 001 1zM23 16.5a1 1 0 00-1 1v4.25a.25.25 0 01-.25.25H17.5a1 1 0 000 2H22a2 2 0 002-2v-4.5a1 1 0 00-1-1zM22 0h-4.5a1 1 0 000 2h4.25a.25.25 0 01.25.25V6.5a1 1 0 002 0V2a2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});