define("ember-svg-jar/inlined/party-water-gun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>party-water-gun</title><path d=\"M12 9a.5.5 0 00.5-.5v-8A.5.5 0 0012 0H4.5a4.5 4.5 0 000 9zM14.5 9h.5a4.5 4.5 0 000-9h-.5a.5.5 0 00-.5.5v8a.5.5 0 00.5.5zM23 10.5H4.731a3.25 3.25 0 000 6.5.153.153 0 01.137.223l-2.616 5.159A1 1 0 003.039 24h3.283a1 1 0 00.919-.606l3.171-6.257a.25.25 0 01.223-.137h2.846a1 1 0 001-1v-3.25a.25.25 0 01.25-.25H23a1 1 0 000-2z\"/><path d=\"M20.5 14h-4a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h4a1.5 1.5 0 000-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});