define("ember-svg-jar/inlined/single-man-actions-wifi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-wifi</title><path d=\"M10.447 14.451a11.365 11.365 0 014.393-2.146.25.25 0 00.04-.472l-3.728-1.6a.251.251 0 01-.152-.23V8.87a.252.252 0 01.074-.177 3.305 3.305 0 00.779-2.23.251.251 0 01.088-.177 1.873 1.873 0 00.538-1.4 1.58 1.58 0 00-.317-1.015.252.252 0 01-.034-.265 2.522 2.522 0 00.156-1.726C11.944.492 10.242 0 8.888 0a3.752 3.752 0 00-3.163 1.324.252.252 0 01-.213.118 1.071 1.071 0 00-.895.375c-.372.493-.106 1.171.176 1.732a.253.253 0 01-.046.288 1.3 1.3 0 00-.162.2A1.765 1.765 0 004.32 5a1.524 1.524 0 00.638 1.331.249.249 0 01.117.194A3.167 3.167 0 005.92 8.7a.25.25 0 01.08.183v1.122a.25.25 0 01-.151.23l-3.937 1.687C.64 12.468.457 13.626.205 15.23c-.055.359-.117.751-.2 1.179A.5.5 0 00.5 17h8.776a.249.249 0 00.187-.085.246.246 0 00.061-.2 2.488 2.488 0 01.923-2.264z\"/><circle cx=\"17.5\" cy=\"22.25\" r=\"1.75\"/><path d=\"M11.378 15.627a1 1 0 001.242 1.567 7.826 7.826 0 019.758 0 1 1 0 101.242-1.567 9.815 9.815 0 00-12.242 0z\"/><path d=\"M13.853 18.451a1 1 0 001.293 1.526 3.587 3.587 0 014.707 0 1 1 0 001.293-1.526 5.622 5.622 0 00-7.293 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});