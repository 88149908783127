define("ember-svg-jar/inlined/office-file-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-file-text</title><path d=\"M21.707 5.705L16.293.291A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.412a1 1 0 00-.293-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25z\"/><path d=\"M16 9.5H8a1 1 0 00-1 1V12a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v5a.25.25 0 01-.25.25H9.5a1 1 0 000 2h5a1 1 0 100-2h-1.25a.25.25 0 01-.25-.25v-5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V12a1 1 0 002 0v-1.5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});