define("ember-svg-jar/inlined/style-three-pin-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-star</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.749 7.3 12.286 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm6.108 9.139L15.9 11.111a.249.249 0 00-.063.286l1.224 2.815a1.257 1.257 0 01-.317 1.452 1.282 1.282 0 01-1.5.166l-3.113-1.754a.249.249 0 00-.245 0l-3.12 1.754a1.313 1.313 0 01-.642.17 1.284 1.284 0 01-.864-.337 1.255 1.255 0 01-.316-1.451L8.168 11.4a.25.25 0 00-.063-.286L5.912 9.157a1.27 1.27 0 01-.32-1.4A1.229 1.229 0 016.758 7h2.5a.248.248 0 00.229-.151l1.346-3.117a1.294 1.294 0 012.319-.032l.014.028 1.343 3.117a.25.25 0 00.23.151h2.5a1.233 1.233 0 011.168.761 1.262 1.262 0 01-.299 1.382z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});