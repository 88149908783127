define("ember-svg-jar/inlined/shop-street-sign-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shop-street-sign-1</title><path d=\"M22.983 22.818l-4.006-21.7a1 1 0 00-.508-.7A1.5 1.5 0 0017.431 0H5.878A2 2 0 003.9 1.735L1.009 22.864a1 1 0 00.855 1.126A.912.912 0 002 24a1 1 0 00.99-.865l.6-4.419a.25.25 0 01.248-.216h10.481a.249.249 0 01.247.284l-.557 4.08a1 1 0 001.982.271l1.922-14.08a.25.25 0 01.494-.012l1.414 7.657a.25.25 0 01-.246.3H19a1 1 0 000 2h1.037a.249.249 0 01.245.2l.735 3.977A1 1 0 0022 24a1.039 1.039 0 00.183-.017 1 1 0 00.8-1.165zm-17.9-9.46l1.357-9.5A1.007 1.007 0 017.434 3h7.49a1 1 0 01.99 1.141l-1.357 9.5a1.006 1.006 0 01-.991.859h-7.49a1 1 0 01-.99-1.142z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});