define("ember-svg-jar/inlined/drawer-envelope", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drawer-envelope</title><path d=\"M4 4.119l.722 1.01A3.3 3.3 0 007.047 6h5.908a3.283 3.283 0 002.345-.919l.685-.96v2.517L9.542 8.275a2.066 2.066 0 00-1.493 2.51l1.275 5.027a.25.25 0 00.242.188h1.547a.25.25 0 00.243-.309l-.721-2.991a.25.25 0 01.288-.305l3.72.684a3.078 3.078 0 002.954-.708l3.021-2.441a.249.249 0 01.4.136l.935 3.849-6.266 1.593a.25.25 0 00.061.492h6.118l.578-.147a2.066 2.066 0 001.493-2.51l-1.572-6.2a2.067 2.067 0 00-2.507-1.494l-1.878.478V1a.848.848 0 00-.225-.606A1.085 1.085 0 0016.98 0H3a.935.935 0 00-.635.245A.971.971 0 002 1v14.75a.249.249 0 00.249.25h1.5A.249.249 0 004 15.75zm16.2 3.574l-3.927 3.187a1.186 1.186 0 01-1.179.257l-5.036-.93zM14.554 2a.25.25 0 01.2.4c-1.075 1.507-.942 1.57-1.825 1.6H6.992c-.8-.021-.7-.108-1.772-1.6a.25.25 0 01.203-.4zM1 17.5a1 1 0 000 2v2A2.5 2.5 0 003.5 24h16.98a2.5 2.5 0 002.5-2.5v-2a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});