define("ember-svg-jar/inlined/cloud-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-star</title><path d=\"M2.192 16.287a.959.959 0 001.415-.753 1.1 1.1 0 00-.538-1.049 2.216 2.216 0 01-.43-.326A2.374 2.374 0 012 12.374a2.588 2.588 0 012.585-2.587 2.643 2.643 0 01.536.055 1.011 1.011 0 00.811-.185 1 1 0 00.392-.779C6.4 3.14 14.617 1.506 17.141 6.832a1.024 1.024 0 00.978.569 3.7 3.7 0 013.014 1.364 3.862 3.862 0 01.857 2.735 3.429 3.429 0 01-1.2 2.48.976.976 0 00-.329.906l.006.033a.979.979 0 001.589.6A5.4 5.4 0 0024 11.316a5.766 5.766 0 00-5.219-5.89.248.248 0 01-.19-.12 7.685 7.685 0 00-14.1 2.3.251.251 0 01-.227.2 4.642 4.642 0 00-3.644 2.24A4.483 4.483 0 000 12.494a4.286 4.286 0 001.235 3.089 4.237 4.237 0 00.957.704z\"/><path d=\"M16.742 22.091a1.254 1.254 0 00.316-1.45l-1.224-2.815a.251.251 0 01.063-.287l2.213-1.971a1.262 1.262 0 00.3-1.378 1.232 1.232 0 00-1.169-.762h-2.5a.25.25 0 01-.23-.151l-1.344-3.116-.012-.028a1.294 1.294 0 00-2.318.028L9.49 13.277a.249.249 0 01-.229.151h-2.5a1.229 1.229 0 00-1.166.76 1.271 1.271 0 00.319 1.4l2.194 1.955a.251.251 0 01.063.287l-1.224 2.81a1.253 1.253 0 00.314 1.451 1.278 1.278 0 001.506.166l3.112-1.752a.249.249 0 01.245 0l3.113 1.752a1.273 1.273 0 001.505-.166z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});