define("ember-svg-jar/inlined/toys-rocking-horse-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toys-rocking-horse-1</title><path d=\"M15.5 21.048l-4-1a1 1 0 10-.484 1.94l4 1a1 1 0 00.484-1.94zM15.5 18.048l-4-1a1 1 0 10-.484 1.94l4 1a1 1 0 00.484-1.94z\"/><path d=\"M20 6.907h-1.7a1 1 0 01-.439-.1 5.131 5.131 0 00-2.251-.531h-2.26a.5.5 0 01-.484-.378 6.054 6.054 0 00-2.278-3.449A7.92 7.92 0 006.6.988a2.175 2.175 0 00-1.82.761A3.916 3.916 0 013.4 2.781l-1.251.563A3.472 3.472 0 00.158 7.535a3.3 3.3 0 004.079 2.284.486.486 0 01.622.334l.615 2.275a.5.5 0 01-.128.483 13.7 13.7 0 00-2.488 3.423 2.215 2.215 0 001.288 3.085c.244.077.419.137.554.183a2.583 2.583 0 00.925.192 2.28 2.28 0 001.986-1.2 6.567 6.567 0 015.163-3.026 5.6 5.6 0 014.926 3.019 2.19 2.19 0 001.95 1.247 2.125 2.125 0 00.689-.116l.91-.31a2.192 2.192 0 001.34-1.205 2.216 2.216 0 00-.027-1.824 15.542 15.542 0 00-1.548-2.768v-1.27a6.8 6.8 0 00-.633-2.966.251.251 0 01.313-.343A2 2 0 0122 10.907a1 1 0 002 0 4 4 0 00-4-4zm.713 10.271a.25.25 0 01.008.207.247.247 0 01-.151.142l-.788.268a.25.25 0 01-.3-.125 7.536 7.536 0 00-6.708-4.1 8.565 8.565 0 00-6.923 4.078.252.252 0 01-.3.122l-.737-.23a.251.251 0 01-.148-.352 11.656 11.656 0 012.655-3.39 1 1 0 00.315-1.019L6.38 8.112a1 1 0 00-1.28-.688l-1.46.487h-.009a1.343 1.343 0 01-1.565-.978A1.51 1.51 0 013 5.15l.013-.006 1.2-.539A3.2 3.2 0 018.46 6.221l.007.015.7 1.468a1 1 0 00.637.534.5.5 0 01.35.356l.531 2.025a1 1 0 00.968.747h3.745a1 1 0 00.971-.758l.35-1.4a.5.5 0 01.787-.277 4.129 4.129 0 011.517 3.414v1.618a1 1 0 00.282.7.2.2 0 01.028.035 26.01 26.01 0 011.384 2.48z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});