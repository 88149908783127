define("ember-svg-jar/inlined/rabbit-running", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rabbit-running</title><path d=\"M8.066 19.077a.25.25 0 00-.18-.077H6.75a1.5 1.5 0 000 3h2.874a.25.25 0 00.213-.38 12.379 12.379 0 00-1.771-2.543zM22.566 11.188L12.31.939a1.5 1.5 0 00-2.122 2.122l6 6.017a1.5 1.5 0 01-2.122 2.122l-3.849-3.85a5.833 5.833 0 00-4.153-1.632 5.217 5.217 0 00-1.866.33 2 2 0 10-2.48 2.381c-.823 2.084.424 4.545 2.157 5.737 2 1.375 4.875 2.834 6.417 5.334.76 1.231 1.908 3.1 2.312 3.761a.5.5 0 00.426.239h5.719a1 1 0 000-2h-3.642a.5.5 0 01-.473-.338c-.393-1.151-1.678-4.911-2.1-6.177a.75.75 0 011.424-.47c.261.79.8 2.41 1.255 3.768a.249.249 0 00.16.159.252.252 0 00.223-.035l2.653-1.907h2.5c2 0 3-1 3-2.5a3.444 3.444 0 00-1.183-2.812zM20.5 14.25a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});