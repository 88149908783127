define("ember-svg-jar/inlined/discount-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>discount-arrow</title><circle cx=\"4\" cy=\"6\" r=\"3.5\"/><circle cx=\"18\" cy=\"20\" r=\"3.5\"/><path d=\"M22.75.5h-8a.75.75 0 00-.53 1.28l2.762 2.763a.25.25 0 010 .354l-15.11 15.11a1.5 1.5 0 002.121 2.121L19.1 7.018a.25.25 0 01.354 0L22.22 9.78a.746.746 0 00.53.22.75.75 0 00.75-.75v-8a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});