define("ember-svg-jar/inlined/award-medal-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>award-medal-2</title><path d=\"M9 10.189c.021-.048.043-.1.067-.142a3.295 3.295 0 015.87 0c.023.047.046.095.067.143l.822 1.908a.25.25 0 00.23.151h.229a.249.249 0 00.242-.186l2.166-8.243a1.744 1.744 0 00.059-.451V2A1.752 1.752 0 0017 .248H7A1.752 1.752 0 005.252 2v1.369a1.759 1.759 0 00.058.445l2.171 8.249a.249.249 0 00.242.186h.221a.249.249 0 00.229-.15z\"/><path d=\"M17.239 13.749h-2.173a.248.248 0 01-.229-.151l-1.214-2.814a1.792 1.792 0 00-3.248 0L9.16 13.6a.251.251 0 01-.23.15H6.757a1.742 1.742 0 00-1.628 1.07 1.778 1.778 0 00.449 1.961L7.5 18.493a.249.249 0 01.063.287l-1.078 2.483a1.75 1.75 0 00.441 2.023 1.791 1.791 0 002.085.229l2.865-1.615a.251.251 0 01.246 0l2.866 1.614a1.772 1.772 0 002.085-.228 1.748 1.748 0 00.441-2.023l-1.079-2.484a.251.251 0 01.063-.287l1.959-1.746a1.735 1.735 0 00-1.218-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});