define("ember-svg-jar/inlined/real-estate-favorite-house-choose", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-favorite-house-choose</title><path d=\"M12.1 15.913a.265.265 0 00.407-.224v-5.2a3 3 0 013-3 .428.428 0 00.311-.72c-2.072-2.2-5.2-5.522-6.081-6.463a1.032 1.032 0 00-1.46 0l-8 8.5A1 1 0 001 10.486h1.5a.5.5 0 01.5.5v4a1 1 0 001 1h4.668a.5.5 0 00.263-.075 2.948 2.948 0 013.169.002z\"/><path d=\"M9.359 19.44l-.024-.032a1.5 1.5 0 012.319-1.9l1.462 1.758a.5.5 0 00.884-.322V10.5a1.5 1.5 0 013 0v6a.5.5 0 00.5.5H20a4 4 0 014 4v2.5a.5.5 0 01-.5.5H12.763a.5.5 0 01-.412-.216z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});