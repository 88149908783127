define("ember-svg-jar/inlined/cocktail-martini", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cocktail-martini</title><path d=\"M4.061 2.58a.5.5 0 00.375.17h1.322a.5.5 0 00.376-.829L4.9.51A1.5 1.5 0 003.77 0H1a1 1 0 000 2h2.55zM24 4.5a4.5 4.5 0 00-8.218-2.533.5.5 0 00.412.783h1.319a.5.5 0 00.329-.123A2.5 2.5 0 1120.99 6.51a2.823 2.823 0 01-.42.62L19.3 8.567a.261.261 0 00.2.433A4.507 4.507 0 0024 4.5z\"/><path d=\"M16.543 23a1 1 0 00-1-1H12.5a.5.5 0 01-.5-.5v-6.719a.5.5 0 01.125-.33l7.334-8.323a1.184 1.184 0 00.215-1.2 1 1 0 00-.92-.68H3.246a1 1 0 00-.92.68 1.184 1.184 0 00.215 1.2l7.333 8.323a.5.5 0 01.125.33V21.5a.5.5 0 01-.5.5H6.543a1 1 0 100 2h9a1 1 0 001-1zM5.263 6.143a.184.184 0 01.171-.129h11.132a.184.184 0 01.171.129.215.215 0 01-.046.222l-2.117 2.229a.5.5 0 01-.363.156H7.789a.5.5 0 01-.363-.156L5.309 6.365a.215.215 0 01-.046-.222z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});