define("ember-svg-jar/inlined/people-woman-6", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-6</title><path d=\"M6.122 18.735a1.959 1.959 0 00-1.959 1.959c0 .883 1.306 2.283 1.785 2.767a.244.244 0 00.348 0c.48-.484 1.786-1.884 1.786-2.767a1.96 1.96 0 00-1.96-1.959zM17.877 18.735a1.959 1.959 0 00-1.959 1.959c0 .883 1.306 2.283 1.785 2.767a.244.244 0 00.348 0c.48-.484 1.785-1.884 1.785-2.767a1.959 1.959 0 00-1.959-1.959zM11.044 12.331a.735.735 0 00-1.027 1.052 2.644 2.644 0 001.844.7h.278a2.644 2.644 0 001.843-.7.734.734 0 10-1.025-1.052 1.207 1.207 0 01-.818.281h-.278a1.2 1.2 0 01-.817-.281zM10.389 9.314a.734.734 0 101.263-.75 1.783 1.783 0 00-1.611-.85 1.784 1.784 0 00-1.612.85.735.735 0 001.265.749c.021-.035.076-.129.347-.129s.327.094.348.13zM12.605 9.571a.736.736 0 001.007-.258c.021-.035.076-.129.347-.129s.327.094.348.13a.735.735 0 001.264-.75 1.786 1.786 0 00-1.612-.85 1.785 1.785 0 00-1.612.85.736.736 0 00.258 1.007zM3.02 17.807A4.671 4.671 0 000 22.163v.98a.735.735 0 101.469 0v-.98a3.2 3.2 0 012.066-2.981.734.734 0 00-.515-1.375zM20.98 17.807a.734.734 0 10-.515 1.375 3.2 3.2 0 012.066 2.981v.98a.735.735 0 101.469 0v-.98a4.671 4.671 0 00-3.02-4.356z\"/><path d=\"M4.653 17.265a1.47 1.47 0 102.939 0v-2.109a.245.245 0 01.419-.171 6.591 6.591 0 004.009 2.035 6.571 6.571 0 003.97-1.994.245.245 0 01.418.173v2.066a1.47 1.47 0 102.939 0c0-11.492.03-10.042-.08-10.824a7.339 7.339 0 00-14.549.1c-.118.896-.065-1.082-.065 10.724zm11.464-5.344a.344.344 0 01-.005.045c-.417 2.159-3.1 3.585-4.092 3.585s-3.678-1.427-4.094-3.589a.313.313 0 010-.043l-.071-4.5a.244.244 0 01.221-.247 6.4 6.4 0 003.805-1.63.183.183 0 01.247 0 6.5 6.5 0 003.811 1.634.245.245 0 01.22.245z\"/><path d=\"M13.585 18.6a3.24 3.24 0 01-3.184 0 .735.735 0 00-.736 1.273 4.659 4.659 0 004.655 0 .735.735 0 10-.735-1.272z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});