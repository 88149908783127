define("ember-svg-jar/inlined/style-three-pin-repair-tool", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-repair-tool</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm1.811 7.982L15.189 6.6a.5.5 0 000-.708l-.043-.042a.506.506 0 01-.144-.4.5.5 0 01.221-.367l1.5-1a.5.5 0 01.631.062l1 1a.5.5 0 01.062.631l-1 1.5a.5.5 0 01-.367.221L17 7.5a.5.5 0 01-.354-.146l-.046-.043a.5.5 0 00-.708 0l-1.374 1.378a.5.5 0 01-.707-.707zm-2.872 5.786l-1.775 1.775a1.561 1.561 0 01-2.207-2.207l1.775-1.775a.5.5 0 01.707 0l1.5 1.5a.5.5 0 010 .707zm7.461 1.008a.5.5 0 01-.836.225L16.28 13.72a.768.768 0 00-1.06 0 .748.748 0 000 1.06l1.28 1.282a.5.5 0 01-.225.836A3 3 0 0112.5 14a2.83 2.83 0 01.038-.458.5.5 0 00-.139-.436l-3-3a.5.5 0 00-.437-.14A3 3 0 015.6 6.224.5.5 0 016.438 6L7.72 7.28a.768.768 0 001.06 0 .748.748 0 000-1.06L7.5 4.938a.5.5 0 01.225-.838A3 3 0 0111.5 7a2.83 2.83 0 01-.038.458.5.5 0 00.139.436l3 3a.5.5 0 00.437.139 3 3 0 013.362 3.743z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});