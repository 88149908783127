define("ember-svg-jar/inlined/heavy-equipment-cleaner-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>heavy-equipment-cleaner-2</title><path d=\"M8.9 16h12.7a2.867 2.867 0 011.217.272.25.25 0 00.355-.239l-.272-5.327a1.5 1.5 0 00-1.15-1.171V4.167A2.119 2.119 0 0019.688 2h-5.476a2.086 2.086 0 00-2.036 1.824l-.83 5.464a.249.249 0 01-.246.212H10a.25.25 0 01-.25-.25V7c0-.882-.759-2.5-2-2.5a1 1 0 00-.24 1.971A1.4 1.4 0 017.75 7v2.684a1.5 1.5 0 00-.7.841l-.772 2.317a.5.5 0 00-.023.214l.27 2.444H3.7a7.249 7.249 0 00-1.741-4.207 1 1 0 00-1.4 0A1 1 0 00.536 12.7a5.633 5.633 0 011.214 3.8 5.641 5.641 0 01-1.207 3.793 1 1 0 001.414 1.414A7.249 7.249 0 003.7 17.5h2.616A3.068 3.068 0 018.9 16zm5.253-11.875A.212.212 0 0114.212 4h5.468a.192.192 0 01.07.168V9.5h-1.5a.5.5 0 00-.485.379l-.358 1.432a.25.25 0 01-.243.189h-3.328a.25.25 0 01-.243-.189l-.33-1.32z\"/><path d=\"M21.6 17H8.9a2.346 2.346 0 00-2.15 2.5A2.346 2.346 0 008.9 22h12.7a2.346 2.346 0 002.147-2.5A2.346 2.346 0 0021.6 17zM9.25 20.25a.75.75 0 11.75-.75.75.75 0 01-.75.75zm12 0a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});