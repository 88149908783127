define("ember-svg-jar/inlined/disability-hearing-aid-t", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-hearing-aid-t</title><path d=\"M3.779 21.993a5.419 5.419 0 001.443.2 5.239 5.239 0 005.234-4.893l.007-.106a2.938 2.938 0 011.53-2.469A7.494 7.494 0 101.778 4.535a.989.989 0 101.727.965 5.5 5.5 0 015.33-2.8 5.612 5.612 0 015.008 5.52A5.532 5.532 0 0111.025 13a4.974 4.974 0 00-2.542 4.158 3.268 3.268 0 01-6.51.157.989.989 0 10-1.968.2 5.222 5.222 0 003.774 4.478z\"/><circle cx=\"5.381\" cy=\"12.138\" r=\"2.719\"/><path d=\"M6.538 6.27A2.467 2.467 0 017.669 6c1.68 0 2.579 1.5 2.725 2.987a.989.989 0 00.983.892c.032 0 .065 0 .1-.005a.988.988 0 00.887-1.08c-.274-2.809-2.2-4.771-4.693-4.771a4.5 4.5 0 00-2.024.481.989.989 0 00.891 1.766zM17.633 13.279a.988.988 0 001.356-.339 5.783 5.783 0 000-5.961A.988.988 0 1017.294 8a3.812 3.812 0 010 3.927.987.987 0 00.339 1.352zM21.177 14.762a.988.988 0 001.356-.339 8.661 8.661 0 000-8.928.989.989 0 10-1.7 1.018 6.689 6.689 0 010 6.893.987.987 0 00.344 1.356zM22.022 22.319v-3.213a.247.247 0 01.248-.247h.741a.989.989 0 000-1.978h-3.955a.989.989 0 000 1.978h.744a.247.247 0 01.247.247v3.213a.989.989 0 101.977 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});