define("ember-svg-jar/inlined/audio-book-headphones-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-book-headphones-person</title><path d=\"M20.029 12.613a1.754 1.754 0 00-1.57-.249l-6.15 2a.989.989 0 01-.618 0l-6.15-2a1.753 1.753 0 00-1.57.249 1.731 1.731 0 00-.721 1.415v5.9a1.745 1.745 0 001.209 1.664l7.31 2.376a.759.759 0 00.462 0l7.311-2.376a1.745 1.745 0 001.208-1.664v-5.9a1.731 1.731 0 00-.721-1.415zM18 16.624a.5.5 0 01-.362.481l-2.5.714a.5.5 0 01-.638-.48v-.71a.5.5 0 01.363-.481l2.5-.714a.5.5 0 01.637.48z\"/><circle cx=\"12\" cy=\"7.501\" r=\"3.5\"/><path d=\"M7 11a.75.75 0 00.75-.75v-4.5a4.25 4.25 0 018.5 0v4.5A.75.75 0 0017 11a2.75 2.75 0 001.035-5.3.5.5 0 01-.31-.423 5.744 5.744 0 00-11.45 0 .5.5 0 01-.31.423A2.75 2.75 0 007 11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});