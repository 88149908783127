define("ember-svg-jar/inlined/style-three-pin-snow-jumping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-snow-jumping</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm2 2.5a2 2 0 11-2 2 2 2 0 012-2zM8.135 13.379l2.5-5.5a1.5 1.5 0 112.73 1.242l-2.5 5.5a1.5 1.5 0 11-2.73-1.242zm9.308-.523l-7.463 6.22a.75.75 0 01-.96-1.152l7.463-6.22A3.509 3.509 0 0017.75 9a.75.75 0 011.5 0 5 5 0 01-1.807 3.856z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});