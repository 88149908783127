define("ember-svg-jar/inlined/dressing-mirror-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dressing-mirror-1</title><path d=\"M19 16.5a.5.5 0 00-.5-.5h-13a.5.5 0 00-.5.5v.5a1 1 0 00.8.98.249.249 0 01.2.245V23a1 1 0 002 0v-.75a.25.25 0 01.25-.25h7.5a.25.25 0 01.25.25V23a1 1 0 002 0v-4.775a.25.25 0 01.2-.245A1 1 0 0019 17zm-3 3.25a.25.25 0 01-.25.25h-7.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h7.5a.25.25 0 01.25.25zM21 13a1 1 0 00-1-1h-1.978a.25.25 0 01-.235-.335A10.446 10.446 0 0018.5 8c0-4.411-2.916-8-6.5-8S5.5 3.589 5.5 8a10.433 10.433 0 00.713 3.665.25.25 0 01-.235.335H4a1 1 0 00-1 1v.5a1 1 0 001 1h16a1 1 0 001-1zM7.5 8c0-3.252 2.061-6 4.5-6s4.5 2.748 4.5 6a9.511 9.511 0 01-.865 3.707.469.469 0 01-.435.293H8.82a.5.5 0 01-.464-.313A9.487 9.487 0 017.5 8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});