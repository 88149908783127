define("ember-svg-jar/inlined/single-man-shield.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-shield</title><path d=\"M23.072 1.047A16.389 16.389 0 0017.478.006a16.01 16.01 0 00-5.55 1.04A1.545 1.545 0 0011 2.462v3.393a7.716 7.716 0 005.19 6.806 3.208 3.208 0 001.288.345 3.26 3.26 0 001.289-.345C21.848 11.487 24 8.688 24 5.855V2.462a1.543 1.543 0 00-.928-1.415zM20.5 6.256h-1.75a.5.5 0 00-.5.5v1.75a.75.75 0 01-1.5 0v-1.75a.5.5 0 00-.5-.5H14.5a.75.75 0 010-1.5h1.75a.5.5 0 00.5-.5v-1.75a.75.75 0 011.5 0v1.75a.5.5 0 00.5.5h1.75a.75.75 0 010 1.5zM5.5 8.994a5.506 5.506 0 00-5.5 5.5v3a.5.5 0 00.5.5h1.81a.249.249 0 01.249.229L3 23.535a.5.5 0 00.5.459h4a.5.5 0 00.5-.459l.443-5.312a.249.249 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3a5.506 5.506 0 00-5.5-5.5zm.75 5.948a.75.75 0 01-1.5 0v-3.948a.75.75 0 011.5 0zM5.5 8.144a4.069 4.069 0 10-4.065-4.069A4.074 4.074 0 005.5 8.144zm0-2a2.07 2.07 0 01-2.065-2.069 1.989 1.989 0 01.136-.718.249.249 0 01.362-.124 5.061 5.061 0 002.609.725 4.961 4.961 0 00.736-.058.249.249 0 01.287.247A2.028 2.028 0 015.5 6.144z\"/><path d=\"M23.072 1.047A16.389 16.389 0 0017.478.006a16.01 16.01 0 00-5.55 1.04A1.545 1.545 0 0011 2.462v3.393a7.716 7.716 0 005.19 6.806 3.208 3.208 0 001.288.345 3.26 3.26 0 001.289-.345C21.848 11.487 24 8.688 24 5.855V2.462a1.543 1.543 0 00-.928-1.415zM20.5 6.256h-1.75a.5.5 0 00-.5.5v1.75a.75.75 0 01-1.5 0v-1.75a.5.5 0 00-.5-.5H14.5a.75.75 0 010-1.5h1.75a.5.5 0 00.5-.5v-1.75a.75.75 0 011.5 0v1.75a.5.5 0 00.5.5h1.75a.75.75 0 010 1.5zM5.5 8.994a5.506 5.506 0 00-5.5 5.5v3a.5.5 0 00.5.5h1.81a.249.249 0 01.249.229L3 23.535a.5.5 0 00.5.459h4a.5.5 0 00.5-.459l.443-5.312a.249.249 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3a5.506 5.506 0 00-5.5-5.5zm.75 5.948a.75.75 0 01-1.5 0v-3.948a.75.75 0 011.5 0zM5.5 8.144a4.069 4.069 0 10-4.065-4.069A4.074 4.074 0 005.5 8.144zm0-2a2.07 2.07 0 01-2.065-2.069 1.989 1.989 0 01.136-.718.249.249 0 01.362-.124 5.061 5.061 0 002.609.725 4.961 4.961 0 00.736-.058.249.249 0 01.287.247A2.028 2.028 0 015.5 6.144z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});