define("ember-svg-jar/inlined/webpage-live", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>webpage-live</title><path d=\"M10.25 5.875v-5a.75.75 0 00-1.5 0v5a.75.75 0 001.5 0zM12 .125a.75.75 0 00-.75.75v1.7a6.593 6.593 0 001.457 3.465l.169.251a.75.75 0 001.248 0l.169-.251a6.593 6.593 0 001.457-3.468V.875a.75.75 0 00-1.5 0v1.7a3.355 3.355 0 01-.533 1.583.25.25 0 01-.434 0 3.355 3.355 0 01-.533-1.583v-1.7a.75.75 0 00-.75-.75zM5 .125a.75.75 0 00-.75.75v4A1.752 1.752 0 006 6.625h1a.75.75 0 000-1.5H6a.25.25 0 01-.25-.25v-4A.75.75 0 005 .125zM18.25 1.875a.25.25 0 01.25-.25h1a.75.75 0 000-1.5h-1a1.752 1.752 0 00-1.75 1.75v3a1.752 1.752 0 001.75 1.75h1a.75.75 0 000-1.5h-1a.25.25 0 010-.5h1a.75.75 0 000-1.5h-1a.25.25 0 01-.25-.25zM21 7.375a1 1 0 00-1 1v13a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-13a1 1 0 00-2 0v13.5a2 2 0 002 2h16a2 2 0 002-2v-13.5a1 1 0 00-1-1z\"/><circle cx=\"7\" cy=\"12.375\" r=\"1.5\"/><path d=\"M17.75 12.375a.75.75 0 00-.75-.75h-6.5a.75.75 0 000 1.5H17a.75.75 0 00.75-.75z\"/><circle cx=\"7\" cy=\"17.875\" r=\"1.5\"/><path d=\"M10.5 17.125a.75.75 0 000 1.5H16a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});