define("ember-svg-jar/inlined/style-three-pin-islam", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-islam</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm.093 15.39a5.5 5.5 0 110-10.77.954.954 0 01.244 1.785 4.042 4.042 0 000 7.2.954.954 0 01-.244 1.785zm6.358-6.169l-.864.843a.5.5 0 00-.144.443l.2 1.189a.5.5 0 01-.725.527l-1.068-.562a.5.5 0 00-.465 0l-1.07.562a.5.5 0 01-.725-.527l.2-1.19a.5.5 0 00-.143-.442l-.864-.843a.5.5 0 01.276-.852l1.2-.174a.5.5 0 00.377-.274l.534-1.082a.52.52 0 01.9 0l.53 1.082a.5.5 0 00.377.274l1.2.174a.5.5 0 01.276.852z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});