define("ember-svg-jar/inlined/water-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-container</title><path d=\"M19 12.5a1.5 1.5 0 00-1.5-1.5h-.927a.25.25 0 01-.16-.442A3.011 3.011 0 0017.5 8.25V2a2 2 0 00-2-2h-7a2 2 0 00-2 2v6.25a3.011 3.011 0 001.087 2.308.25.25 0 01-.16.442H6.5A1.5 1.5 0 005 12.5v10A1.5 1.5 0 006.5 24h11a1.5 1.5 0 001.5-1.5v-10zm-3.25.25a.75.75 0 11-.75.75.75.75 0 01.75-.75zM15 2a.5.5 0 01.5.5v5.75a1 1 0 01-1 1 1 1 0 00-1 1v.25a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-.25a1 1 0 00-1-1 1 1 0 01-1-1 .5.5 0 01.5-.5h1a.75.75 0 000-1.5H9a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h1a.75.75 0 000-1.5H9a.5.5 0 01-.5-.5V2.5A.5.5 0 019 2zm-1.5 11.5a.75.75 0 11-.75-.75.75.75 0 01.75.75zm3.5 7.247A1.253 1.253 0 0115.746 22h-1.54a.25.25 0 01-.248-.281l.412-3.3a.594.594 0 00-.589-.667h-3.562a.594.594 0 00-.589.667l.412 3.3a.25.25 0 01-.248.281h-1.54A1.253 1.253 0 017 20.747V16.5a.5.5 0 01.5-.5h9a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});