define("ember-svg-jar/inlined/road-sign-traffic-priority-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-traffic-priority-right</title><path d=\"M11.47 23.78a.748.748 0 001.06 0l11.25-11.25a.749.749 0 000-1.06L12.53.22a.749.749 0 00-1.06 0L.22 11.47a.749.749 0 000 1.06zM15.679 9a.25.25 0 00-.25.25v7.25a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V9.25a.25.25 0 00-.25-.25h-.646a.25.25 0 01-.177-.427l1.72-1.719a.5.5 0 01.707 0L16.5 8.573a.25.25 0 01-.174.427zm-6.75 8h-1a.5.5 0 01-.5-.5V14a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v2.5a.5.5 0 01-.5.5zm-2.25-5.5a.75.75 0 01.75-.75h2a.75.75 0 110 1.5h-2a.75.75 0 01-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});