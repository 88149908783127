define("ember-svg-jar/inlined/shield-unlock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shield-unlock</title><circle cx=\"11.5\" cy=\"12.244\" r=\"1.5\"/><path d=\"M24 1.954A1.96 1.96 0 0022.043.006H1.96A1.961 1.961 0 00.012 1.964L0 9.306a15.144 15.144 0 0011.861 14.668.976.976 0 00.4 0A15.144 15.144 0 0023.988 9.2zm-4.25 6.789a.75.75 0 11-1.5 0v-2a1.75 1.75 0 00-3.5 0v1.5a.25.25 0 00.25.25 1 1 0 011 1v5.5a1 1 0 01-1 1H8a1 1 0 01-1-1v-5.5a1 1 0 011-1h5a.25.25 0 00.25-.25v-1.5a3.25 3.25 0 016.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});