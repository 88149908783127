define("ember-svg-jar/inlined/style-three-pin-swim-compete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-swim-compete</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm3.25 3.5a.75.75 0 11-.75.75.75.75 0 01.75-.75zm-8.5.5a.75.75 0 11-.75.75.75.75 0 01.75-.75zm-2.5 6A.75.75 0 115 9.25a.75.75 0 01-.75.75zm13.676 2.941a1.49 1.49 0 01-1.167.559H15.75a.25.25 0 00-.25.25v.5a.25.25 0 00.25.25H17a.5.5 0 010 1H7a.5.5 0 010-1h1.25a.25.25 0 00.25-.25v-.5a.25.25 0 00-.25-.25H7.241a1.5 1.5 0 01-1.466-1.819l.777-3.565.005-.02A1.5 1.5 0 018 7h1.568a.25.25 0 00.221-.368A2.391 2.391 0 019.5 5.5v-2a.5.5 0 011 0v2A1.5 1.5 0 0012 7h4a1.489 1.489 0 011.433 1.059l.006.02.786 3.6a1.491 1.491 0 01-.299 1.262zM19.25 8.5a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/><path d=\"M16.461 8.294A.5.5 0 0016 8H8a.5.5 0 00-.474.34l-.008.028-.766 3.526a.5.5 0 00.489.606h9.518a.5.5 0 00.489-.606l-.775-3.561a.291.291 0 00-.012-.039z\"/><rect x=\"9.5\" y=\"13.5\" width=\"5\" height=\"1\" rx=\".25\" ry=\".25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});