define("ember-svg-jar/inlined/photography-equipment-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>photography-equipment-light</title><path d=\"M4.5 16h15a1.5 1.5 0 001.5-1.5v-13A1.5 1.5 0 0019.5 0h-15A1.5 1.5 0 003 1.5v13A1.5 1.5 0 004.5 16zM7 9.5A1.5 1.5 0 115.5 11 1.5 1.5 0 017 9.5zM5.5 5A1.5 1.5 0 117 6.5 1.5 1.5 0 015.5 5zM12 9.5a1.5 1.5 0 11-1.5 1.5A1.5 1.5 0 0112 9.5zM10.5 5A1.5 1.5 0 1112 6.5 1.5 1.5 0 0110.5 5zM17 9.5a1.5 1.5 0 11-1.5 1.5A1.5 1.5 0 0117 9.5zM15.5 5A1.5 1.5 0 1117 6.5 1.5 1.5 0 0115.5 5z\"/><path d=\"M22.75 7a.75.75 0 00-.75.75v8A1.252 1.252 0 0120.75 17H3.25A1.252 1.252 0 012 15.75v-8a.75.75 0 00-1.5 0v8a2.752 2.752 0 002.75 2.75h6.5a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-1.25a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25.75.75 0 001.5 0v-2a.75.75 0 00-1.5 0 .25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h6.5a2.752 2.752 0 002.75-2.75v-8a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});