define("ember-svg-jar/inlined/construction-pipe-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>construction-pipe-1</title><rect y=\"16.5\" width=\"4\" height=\"7\" rx=\"1\" ry=\"1\"/><rect x=\"20\" y=\".5\" width=\"4\" height=\"7\" rx=\"1\" ry=\"1\"/><path d=\"M13.5 15.5h-4a.5.5 0 00-.5.5 1.5 1.5 0 01-1.5 1.5H6a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h1.5A6.508 6.508 0 0014 16a.5.5 0 00-.5-.5zM18 1.5h-2.5A6.508 6.508 0 009 8a.5.5 0 00.5.5h4A.5.5 0 0014 8a1.5 1.5 0 011.5-1.5H18a.5.5 0 00.5-.5V2a.5.5 0 00-.5-.5z\"/><rect x=\"8\" y=\"10\" width=\"7\" height=\"4\" rx=\"1\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});