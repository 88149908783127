define("ember-svg-jar/inlined/navigation-right-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-right-4</title><path d=\"M9.25 10.5a.25.25 0 00.25-.25V7.5a1 1 0 011.748-.665l4 4.5a1 1 0 010 1.329l-4 4.5A1 1 0 019.5 16.5v-2.75a.25.25 0 00-.25-.25h-9a.25.25 0 00-.25.25V21a3 3 0 003 3h9a12 12 0 000-24H3a3 3 0 00-3 3v7.25a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});