define("ember-svg-jar/inlined/single-man-actions-up-down.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-up-down</title><path d=\"M7.044 10.125a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.743a.5.5 0 01.741-.3A6.958 6.958 0 008.5 5.125a6.35 6.35 0 00.929-.07.5.5 0 01.571.564 3 3 0 01-5.957-.494 2.8 2.8 0 01.101-.743zM11.956 12.567a.5.5 0 00-.2-.4 7.467 7.467 0 00-4.506-1.542 7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h10.912a.5.5 0 00.5-.5zM17.206 19.125h-1a.25.25 0 01-.25-.25v-5.25a1.25 1.25 0 00-2.5 0v5.25a.25.25 0 01-.25.25h-1a.5.5 0 00-.406.791l2.5 3.5a.5.5 0 00.813 0l2.5-3.5a.5.5 0 00-.407-.791zM23.863 16.334l-2.5-3.5a.517.517 0 00-.813 0l-2.5 3.5a.5.5 0 00.406.791h1a.25.25 0 01.25.25v5.25a1.25 1.25 0 002.5 0v-5.25a.25.25 0 01.25-.25h1a.5.5 0 00.407-.791z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});