define("ember-svg-jar/inlined/antique-sword", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>antique-sword</title><path d=\"M23.994.572a.5.5 0 00-.141-.425.493.493 0 00-.425-.141c-6.51.938-6.538.946-6.585.96a1.021 1.021 0 00-.474.306L7.415 12.021a.25.25 0 01-.369.017L4.908 9.9a1.5 1.5 0 10-2.121 2.121l9.193 9.192a1.5 1.5 0 102.12-2.121l-2.138-2.137a.25.25 0 01.017-.369l10.762-8.964a1.028 1.028 0 00.309-.535zm-3.963 3.4a.751.751 0 010 1.06l-9.643 9.642a.5.5 0 01-.707 0l-.353-.353a.5.5 0 010-.708l9.642-9.642a.75.75 0 011.061 0zM.586 20.586a2 2 0 002.829 2.828l3.822-3.822a.5.5 0 000-.707l-2.121-2.121a.5.5 0 00-.707 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});