define("ember-svg-jar/inlined/computer-chip-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>computer-chip-search</title><path d=\"M-.03 10.975a10.979 10.979 0 0017.32 8.984.5.5 0 01.643.056l3.537 3.572a1.5 1.5 0 002.121-2.121l-3.548-3.584a.5.5 0 01-.054-.637A10.993 10.993 0 10-.03 10.975zm11-9a9 9 0 11-9 9 9.01 9.01 0 019-9z\"/><path d=\"M5.97 11.715a.75.75 0 100 1.5h.75a.25.25 0 01.25.25v.5a1 1 0 001 1h.5a.25.25 0 01.25.25v.75a.75.75 0 001.5 0v-.75a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v.75a.75.75 0 001.5 0v-.75a.25.25 0 01.25-.25h.5a1 1 0 001-1v-.5a.25.25 0 01.25-.25h.75a.75.75 0 100-1.5h-.75a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h.75a.75.75 0 100-1.5h-.75a.25.25 0 01-.25-.25v-.5a1 1 0 00-1-1h-.5a.25.25 0 01-.25-.25v-.75a.75.75 0 00-1.5 0v.75a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25v-.75a.75.75 0 00-1.5 0v.75a.25.25 0 01-.25.25h-.5a1 1 0 00-1 1v.5a.25.25 0 01-.25.25h-.75a.75.75 0 100 1.5h.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});