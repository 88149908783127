define("ember-svg-jar/inlined/folder-zip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-zip</title><path d=\"M22.5 4.5H11.736a.5.5 0 01-.447-.276l-.947-1.894A1.491 1.491 0 009 1.5H1.5A1.5 1.5 0 000 3v18a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 21V6a1.5 1.5 0 00-1.5-1.5zm-5 15.5a1 1 0 111-1 1 1 0 01-1 1zm0-4a1 1 0 111-1 1 1 0 01-1 1zm1.346-4.321a1.758 1.758 0 01-1.319.595h-.072a1.76 1.76 0 01-1.746-1.974l.384-3.846a.5.5 0 01.5-.45h1.664a.5.5 0 01.5.433l.523 3.863a1.762 1.762 0 01-.434 1.379z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});