define("ember-svg-jar/inlined/phone-actions-wait-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-wait-1</title><circle cx=\"13.75\" cy=\"5.525\" r=\"1.5\"/><circle cx=\"17.75\" cy=\"5.525\" r=\"1.5\"/><circle cx=\"21.75\" cy=\"5.525\" r=\"1.5\"/><path d=\"M16.245 13.649a3.116 3.116 0 00-4.4 0l-.827.828a4.249 4.249 0 00-1.346 4.5 11.355 11.355 0 01-6.032-6.032 4.248 4.248 0 004.5-1.345l.83-.829a3.117 3.117 0 000-4.4L5.385 2.788a2.234 2.234 0 00-3.649.739A13.322 13.322 0 0011.389 21.6a13.408 13.408 0 007.7-.716 2.233 2.233 0 00.737-3.648z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});