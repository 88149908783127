define("ember-svg-jar/inlined/shirt-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5.406 0h-.268c-.148 0-.462.109-.704.242L.59 2.379c-.492.289-.722.886-.512 1.418l1.31 3.275c.209.532.875.738 1.387.482L4 6.966v8.277c0 .571.299.757.871.757h8.277c.573 0 .852-.186.852-.757V6.966l1.235.572c.512.255 1.142.032 1.351-.5l1.333-3.311c.21-.532-.009-1.06-.501-1.349L13.585.242C13.344.109 13.028 0 12.881 0h-.268c-.276 0-.496.231-.604.485-.353.833-1.559 1.584-2.999 1.584S6.363 1.318 6.011.485C5.902.231 5.683 0 5.406 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17.998",
      "height": "16",
      "viewBox": "0 0 17.998 16"
    }
  };
});