define("ember-svg-jar/inlined/lab-bottle-experiment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lab-bottle-experiment</title><path d=\"M12.8 7.459a.5.5 0 01-.251.435A6.435 6.435 0 009.3 13.5a6.5 6.5 0 0013 0 6.434 6.434 0 00-3.248-5.606.5.5 0 01-.252-.435V6.5a.5.5 0 01.5-.5h1a1 1 0 000-2h-1a.5.5 0 01-.5-.5v-2A1.5 1.5 0 0017.3 0h-3a1.5 1.5 0 00-1.5 1.5v2a.5.5 0 01-.5.5h-1.585a.5.5 0 01-.475-.669 1 1 0 00-1.888-.662L1.816 21.341A2 2 0 003.705 24H17.2a1 1 0 000-2H4.408a.5.5 0 01-.472-.665L9.071 6.67a1 1 0 01.944-.67h2.277a.5.5 0 01.5.5zm.5 7.291a.75.75 0 11.75-.75.75.75 0 01-.75.75zm2.75 2.5a.75.75 0 11.75-.75.75.75 0 01-.755.75zm2.25-1.807a1.25 1.25 0 111.25-1.25 1.252 1.252 0 01-1.25 1.25zM14.8 2.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5zM16.3 6a.5.5 0 01.5.5v1.9a1 1 0 00.635.932 4.494 4.494 0 012.465 2.317.25.25 0 01-.227.354h-7.75a.251.251 0 01-.228-.354 4.5 4.5 0 012.466-2.318A1 1 0 0014.8 8.4V6.5a.5.5 0 01.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});