define("ember-svg-jar/inlined/gmo-food-lab-bag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gmo-food-lab-bag</title><path d=\"M20.692 20.765a1 1 0 01-.078-.683l.253-1.022A28.884 28.884 0 0022 12a28.884 28.884 0 00-1.133-7.06q-.128-.509-.253-1.022a1 1 0 01.078-.683l.532-1.064A1.5 1.5 0 0019.882 0H4.118a1.5 1.5 0 00-1.342 2.171l.533 1.064a1 1 0 01.077.683l-.251 1.017A28.877 28.877 0 002 12a28.884 28.884 0 001.133 7.06l.253 1.022a1 1 0 01-.078.683l-.532 1.064A1.5 1.5 0 004.118 24h15.764a1.5 1.5 0 001.342-2.171zM14.5 2.591a.75.75 0 11-.75.75.75.75 0 01.75-.75zm-2.3 2.09a.955.955 0 11-.954.955.954.954 0 01.954-.955zM15.25 9.5H15a.5.5 0 00-.5.5v6a2.5 2.5 0 01-5 0v-6a.5.5 0 00-.5-.5h-.25a.75.75 0 010-1.5h6.5a.75.75 0 010 1.5z\"/><rect x=\"11\" y=\"9.5\" width=\"2\" height=\"4.091\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});