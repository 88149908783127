define("ember-svg-jar/inlined/currency-pound", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-pound</title><path d=\"M20.113 21H10.62a.25.25 0 01-.221-.366 6.237 6.237 0 00.714-2.906v-3.5a.249.249 0 01.25-.25H18.2a1.5 1.5 0 000-3h-6.837a.25.25 0 01-.25-.25V6.75a3.75 3.75 0 017.5 0 1.5 1.5 0 003 0 6.75 6.75 0 00-13.5 0v3.978a.25.25 0 01-.25.25H3.887a1.5 1.5 0 000 3h3.976a.249.249 0 01.25.25v3.5A3.275 3.275 0 014.841 21a1.5 1.5 0 000 3h15.272a1.5 1.5 0 000-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});