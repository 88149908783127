define("ember-svg-jar/inlined/cleaning-robot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cleaning-robot</title><path d=\"M23.694 14.024a.5.5 0 00-.543.1C20.741 16.481 16.688 18 12 18S3.259 16.481.849 14.127a.5.5 0 00-.849.358V15c0 4.136 5.383 7.5 12 7.5s12-3.364 12-7.5v-.515a.5.5 0 00-.306-.461z\"/><ellipse cx=\"12\" cy=\"9\" rx=\"2\" ry=\"1.25\"/><path d=\"M8.46 16.16a.512.512 0 00.38-.08.535.535 0 00.21-.32 2.175 2.175 0 01.47-1.01A3.206 3.206 0 0112 13.66a3.206 3.206 0 012.48 1.09 2.175 2.175 0 01.47 1.01.535.535 0 00.21.32.563.563 0 00.38.08C20.43 15.21 24 12.36 24 9c0-4.14-5.38-7.5-12-7.5S0 4.86 0 9c0 3.37 3.57 6.21 8.46 7.16zM12 11.5c-3.037 0-5.5-1.119-5.5-2.5S8.963 6.5 12 6.5s5.5 1.119 5.5 2.5-2.462 2.5-5.5 2.5zM6.16 4.4a15.934 15.934 0 0111.61-.03.75.75 0 11-.61 1.37 14.3 14.3 0 00-10.38.03.756.756 0 01-.99-.38.743.743 0 01.37-.99z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});