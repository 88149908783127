define("ember-svg-jar/inlined/smiley-tongue-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-tongue-1-alternate</title><path d=\"M17.93 15.194a.25.25 0 01-.017-.316 3.363 3.363 0 00.557-1.136 1 1 0 10-1.94-.484A1.922 1.922 0 0114.5 14.5h-5a1.9 1.9 0 01-2.033-1.256 1 1 0 00-1.937.5A3.924 3.924 0 009.5 16.5h1.154a.249.249 0 01.177.073l2.815 2.816a3 3 0 005.122-2.121 2.941 2.941 0 00-.838-2.074zm-1.455 2.781a1 1 0 01-1.414 0l-1.048-1.048a.25.25 0 01.177-.427c.46 0 .468 0 .664-.012a4.49 4.49 0 001.139-.215.251.251 0 01.255.061l.227.227a1 1 0 010 1.414zM6.333 9.9a1.5 1.5 0 012.751 0 1 1 0 00.917.6.973.973 0 00.4-.084 1 1 0 00.515-1.316 3.5 3.5 0 00-6.416 0 1 1 0 101.832.8zM19.5 9.1a3.5 3.5 0 00-6.415 0 1 1 0 101.832.8 1.5 1.5 0 012.751 0 1 1 0 00.917.6.973.973 0 00.4-.084A1 1 0 0019.5 9.1z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});