define("ember-svg-jar/inlined/style-three-pin-paw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-paw</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm2.25 4.5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm-4.5 0A1.5 1.5 0 118.25 6a1.5 1.5 0 011.5-1.5zM7 11a1.5 1.5 0 111.5-1.5A1.5 1.5 0 017 11zm5 5.25c-2.209 0-4-1.119-4-2.5a2.059 2.059 0 01.964-1.627 4 4 0 001.291-1.595A1.981 1.981 0 0112 9.25a1.981 1.981 0 011.745 1.278 3.976 3.976 0 001.291 1.595A2.059 2.059 0 0116 13.75c0 1.381-1.791 2.5-4 2.5zM17 11a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0117 11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});