define("ember-svg-jar/inlined/gesture-two-finger-zoom-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-two-finger-zoom-in</title><path d=\"M22.145 6.779c-1.186-1.558-3.662-2.879-6.531-3.532a.25.25 0 01-.192-.279l.07-.487a.75.75 0 00-1.273-.636l-1.5 1.5a.749.749 0 00-.141.865l1 2a.751.751 0 001.414-.229l.075-.527a.252.252 0 01.107-.172.255.255 0 01.2-.036c2.315.56 4.3 1.59 5.181 2.745a1 1 0 101.591-1.212zM9.006 5.981a.751.751 0 001.414.229l1-2a.749.749 0 00-.141-.865l-1.5-1.5a.75.75 0 00-1.273.636l.069.48a.25.25 0 01-.193.279c-2.857.646-5.331 1.966-6.527 3.537a1 1 0 001.591 1.212 9.583 9.583 0 015.178-2.75.251.251 0 01.2.037.248.248 0 01.107.171zM14.7 15.943a.249.249 0 01-.14-.292l1.24-4.658a.93.93 0 00-1.8-.454l-1.43 4.592a.249.249 0 01-.3.168l-.584-.143a.25.25 0 01-.191-.243v-4.558a1 1 0 00-2 0v7.064a.25.25 0 01-.455.142c-1.49-2.138-3.856-1.449-2.762.224L9.2 22.152a.5.5 0 00.416.223h5.434a.5.5 0 00.492-.411c.2-1.1.664-3.683.776-4.165.247-1.099-.667-1.43-1.618-1.856z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});