define("ember-svg-jar/inlined/question-help-message-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>question-help-message-alternate</title><path d=\"M20.5 0h-17A3.5 3.5 0 000 3.5V23a1 1 0 001.588.809l5.171-3.761A.253.253 0 016.906 20H20.5a3.5 3.5 0 003.5-3.5v-13A3.5 3.5 0 0020.5 0zM22 16.5a1.5 1.5 0 01-1.5 1.5h-14a1 1 0 00-.588.191L2.4 20.747a.247.247 0 01-.26.021.251.251 0 01-.14-.223V3.5A1.5 1.5 0 013.5 2h17A1.5 1.5 0 0122 3.5z\"/><circle cx=\"12\" cy=\"15.5\" r=\"1.5\"/><path d=\"M12 3.254a4 4 0 00-4 4 1 1 0 002 0 2 2 0 112.8 1.833 3 3 0 00-1.8 2.75 1 1 0 002 0 1 1 0 01.6-.917A4 4 0 0012 3.254z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});