define("ember-svg-jar/inlined/charging-wireless-charging", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>charging-wireless-charging</title><path d=\"M12.086 23.986a8.71 8.71 0 008.469-8.29.255.255 0 00-.435-.179c-5.784 5.672-7.556 7.428-8.207 8.038a.25.25 0 00.173.431zM12.158 5.649a3.15 3.15 0 01.473-.378.252.252 0 00.111-.229 1.976 1.976 0 00-1.965-1.813.474.474 0 01-.474-.473V1.041a.749.749 0 10-1.5 0v1.715a1.974 1.974 0 001.972 1.973.475.475 0 01.474.475v1.325a8.738 8.738 0 00-8.027 7.247.25.25 0 00.423.224zM19.935 13.6a1.855 1.855 0 00.027-2.618l-4.14-4.233a1.848 1.848 0 00-2.615-.028l-9.049 8.873a1.856 1.856 0 00-.028 2.618l4.141 4.233a1.85 1.85 0 002.615.027zM6.768 16.144a.741.741 0 01.01-1.047l6.935-6.8a.738.738 0 011.046.01l3.623 3.7a.742.742 0 01-.011 1.048l-6.935 6.8a.739.739 0 01-1.046-.011z\"/><path d=\"M2.762 8.438a.749.749 0 101.5 0A2.189 2.189 0 016.446 6.25a.75.75 0 000-1.5 3.689 3.689 0 00-3.684 3.688z\"/><path d=\"M6.13 3.362a.75.75 0 000-1.5A6.141 6.141 0 000 8a.749.749 0 101.5 0 4.64 4.64 0 014.63-4.638zM17.4 6.219a.7.7 0 00.152.031 2.189 2.189 0 012.188 2.188.716.716 0 00.044.217.731.731 0 00.477.487.723.723 0 00.228.046.749.749 0 00.749-.75 3.689 3.689 0 00-3.684-3.688.749.749 0 00-.749.75.777.777 0 00.03.151.73.73 0 00.565.568z\"/><path d=\"M17.87 1.862a.75.75 0 000 1.5A4.64 4.64 0 0122.5 8 .749.749 0 1024 8a6.141 6.141 0 00-6.13-6.138z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});