define("ember-svg-jar/inlined/ice-cream-stick-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ice-cream-stick-2</title><path d=\"M13.5 22.5a1.5 1.5 0 01-3 0V19a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zM12 0a6.006 6.006 0 00-6 6v9.5A1.5 1.5 0 007.5 17h9a1.5 1.5 0 001.5-1.5V6a6.006 6.006 0 00-6-6zM9.5 13.5a.75.75 0 01-1.5 0V7a.75.75 0 011.5 0zm3.25 0a.75.75 0 01-1.5 0V7a.75.75 0 011.5 0zm3.25 0a.75.75 0 01-1.5 0V7A.75.75 0 0116 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});