define("ember-svg-jar/inlined/eject-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>eject-button</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm5 16a1 1 0 01-1 1H8a1 1 0 01-1-1v-1a1 1 0 011-1h8a1 1 0 011 1zm-4.258-8.925l3.379 3.755a1 1 0 01-.744 1.669H8.623a1 1 0 01-.744-1.669l3.377-3.753a1.034 1.034 0 011.486-.002z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});