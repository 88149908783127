define("ember-svg-jar/inlined/email-action-reply-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-reply-1</title><path d=\"M16.152 12.149A1.5 1.5 0 0117 13.5v.5h2.25A1.749 1.749 0 0021 12.25V1.75a.158.158 0 00-.1-.147.16.16 0 00-.173.034L12.2 10.164a2.407 2.407 0 01-3.4 0L.271 1.637A.159.159 0 000 1.75v10.5A1.749 1.749 0 001.75 14h10.712l2.1-1.674a1.5 1.5 0 011.59-.177z\"/><path d=\"M9.726 9.236a1.094 1.094 0 001.547 0L19.748.761A.437.437 0 0019.5.019 1.62 1.62 0 0019.249 0h-17.5A1.62 1.62 0 001.5.019a.434.434 0 00-.351.3.439.439 0 00.1.447zM16 14.829V13.5a.5.5 0 00-.811-.391l-4 3.182a.5.5 0 00-.025.761l4 3.636a.5.5 0 00.836-.37v-1.763c6.123.238 6.973 4.827 7.007 5.027A.5.5 0 0023.5 24h.042a.5.5 0 00.458-.5c0-.085-.094-8.337-8-8.671z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});