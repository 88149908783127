define("ember-svg-jar/inlined/expand-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>expand-4</title><path d=\"M24 1a1 1 0 00-1-1h-6.5a1 1 0 00-.707 1.707L17.982 3.9a.25.25 0 010 .354l-4.366 4.361a1.25 1.25 0 001.768 1.768l4.366-4.366a.25.25 0 01.354 0l2.189 2.189A1 1 0 0023 8.5a.987.987 0 00.383-.076A1 1 0 0024 7.5zM1 24h6.5a1 1 0 00.707-1.707L6.018 20.1a.249.249 0 010-.353l4.366-4.366a1.25 1.25 0 10-1.768-1.768L4.25 17.981a.25.25 0 01-.354 0l-2.189-2.189A1 1 0 000 16.5V23a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});