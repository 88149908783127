define("ember-svg-jar/inlined/laptop-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-add</title><path d=\"M9.594 15.238a2.428 2.428 0 01-1.543-1.387.492.492 0 00-.47-.36H.5a.478.478 0 00-.41.22.466.466 0 00-.05.469 5.431 5.431 0 005 3.311h4.215a.25.25 0 00.249-.243 7.83 7.83 0 01.245-1.713.25.25 0 00-.155-.297zM3.5 12a1 1 0 001-1V3a1 1 0 011-1h12a1 1 0 011 1v6.344a.249.249 0 00.211.247 7.905 7.905 0 011.454.37.247.247 0 00.228-.03.25.25 0 00.107-.2V3a3.009 3.009 0 00-3-3h-12a3.009 3.009 0 00-3 3v8a1 1 0 001 1zM17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.5 7.5h-1.25a.25.25 0 00-.25.25V20a1 1 0 01-2 0v-1.25a.25.25 0 00-.25-.25H15a1 1 0 010-2h1.25a.25.25 0 00.25-.25V15a1 1 0 012 0v1.25a.25.25 0 00.25.25H20a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});