define("ember-svg-jar/inlined/controls-eject", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>controls-eject</title><path d=\"M2.521 13.488h18.958a1.5 1.5 0 001.135-2.5L13.136.491a1.588 1.588 0 00-2.272 0L1.385 10.99a1.5 1.5 0 001.136 2.498z\"/><rect y=\"16.988\" width=\"24\" height=\"7\" rx=\"1.5\" ry=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});