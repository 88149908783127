define("ember-svg-jar/inlined/content-pen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-pen</title><path d=\"M2.617 17.658c-.983.982-1.81 4.8-2.051 6a.249.249 0 00.068.226.251.251 0 00.226.068c1.2-.241 5.016-1.068 6-2.051a3 3 0 10-4.242-4.242zm2.675 2.628a.75.75 0 110-1.061.75.75 0 010 1.061zM22.56 1.955a2.99 2.99 0 00-3.437-.561 1 1 0 00-.1-.146l-.352-.354a3.072 3.072 0 00-4.241 0L9.152 6.171a1 1 0 001.415 1.415l5.277-5.278a1.023 1.023 0 011.413 0l.354.354-2.122 2.121a.5.5 0 000 .707l3.536 3.536a.5.5 0 00.707 0L22.56 6.2a3 3 0 000-4.245z\"/><path d=\"M14.427 6.552a.5.5 0 00-.707 0l-6.952 6.954a1.5 1.5 0 000 2.121l2.121 2.121a1.5 1.5 0 002.121 0l6.953-6.954a.5.5 0 000-.706z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});