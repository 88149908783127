define("ember-svg-jar/inlined/dog-carrier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dog-carrier</title><path d=\"M11.519 19.218a.249.249 0 00.187.085h1.029a.25.25 0 00.19-.088l1.218-1.421.076-.118 2.34-5.149.025-.061a.25.25 0 00-.229-.349H2.343a.734.734 0 010-1.468h15.365a.435.435 0 01.1.012.252.252 0 00.2-.041 3.728 3.728 0 011.033-.52.249.249 0 00.085-.428 2.172 2.172 0 00-.613-.367l-1.632-4.713a1.489 1.489 0 00-1.445-1.128h-1.469a.249.249 0 01-.248-.226A3.517 3.517 0 0010.272 0a3.515 3.515 0 00-3.445 3.238.25.25 0 01-.248.226H4.615a1.488 1.488 0 00-1.439 1.1L1.54 9.306a2.233 2.233 0 000 4.155L3.176 18.2a1.488 1.488 0 001.439 1.1h3.727a.254.254 0 00.189-.086 1.962 1.962 0 01.749-.539 2 2 0 012.239.543zm1.466-3.878a.75.75 0 011.5 0v.989a.75.75 0 01-1.5 0zm-3.463 0a.75.75 0 011.5 0v.989a.75.75 0 01-1.5 0zm3.463-8.9a.75.75 0 011.5 0v.989a.75.75 0 11-1.5 0zm-1.963.989a.75.75 0 01-1.5 0v-.992a.75.75 0 111.5 0zM10.272 2a1.491 1.491 0 011.409 1.15.25.25 0 01-.242.314H9.106a.25.25 0 01-.241-.314A1.488 1.488 0 0110.272 2zM6.061 6.437a.75.75 0 011.5 0v.989a.75.75 0 01-1.5 0zm1.5 9.892a.75.75 0 01-1.5 0v-.989a.75.75 0 011.5 0zM22.756 12.867a1.05 1.05 0 01-.981-.583 1.5 1.5 0 00-1.369-.9 2.473 2.473 0 00-2.466 1.731l-.572 1.258a.251.251 0 00.085.309l2.76 1.912a.252.252 0 00.337-.048 1.831 1.831 0 01.647-.51 2.1 2.1 0 01.9-.2 1.8 1.8 0 001.781-1.822 1.135 1.135 0 00-1.122-1.147z\"/><path d=\"M20.151 18.446a2.26 2.26 0 01-.065-.589.251.251 0 00-.108-.21l-2.871-1.989a.251.251 0 00-.37.1l-1.159 2.55a1.911 1.911 0 01-.287.45l-1.8 2.1a1.7 1.7 0 00-.323.593.5.5 0 01-.355.331 1.6 1.6 0 01-1.858-.951l-.232-.579a.75.75 0 00-1.393.558l.233.579a3.1 3.1 0 002.872 1.94 3.046 3.046 0 00.742-.092l.051-.013a.253.253 0 01.255.085 1.867 1.867 0 001.45.684h1.313a.251.251 0 00.237-.169c.038-.112.127-.323.331-.834a2.889 2.889 0 00-.279-2.677.5.5 0 01.832-.554 3.887 3.887 0 01.375 3.6l-.115.293a.25.25 0 00.232.343H21.9a.748.748 0 100-1.5h-.43a.5.5 0 01-.488-.39z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});