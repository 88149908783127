define("ember-svg-jar/inlined/task-list-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-list-star</title><path d=\"M12 7.718H6a.75.75 0 000 1.5h6a.75.75 0 000-1.5zM12.75 11.968a.75.75 0 00-.75-.75H6a.75.75 0 000 1.5h6a.75.75 0 00.75-.75z\"/><path d=\"M10.548 19.031a.25.25 0 00-.167-.063H3.5a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v4.056a.249.249 0 00.419.182 2.69 2.69 0 012.288-.806.244.244 0 00.293-.248V3.468a1.5 1.5 0 00-1.5-1.5h-4a.241.241 0 01-.208-.121 3.827 3.827 0 00-6.588 0 .241.241 0 01-.208.121h-4A1.5 1.5 0 000 3.468v17a1.5 1.5 0 001.5 1.5h8.747a.248.248 0 00.234-.161c.142-.382.519-1.289.719-1.768a.425.425 0 00-.088-.505z\"/><path d=\"M6 14.718a.75.75 0 000 1.5h2.783a.245.245 0 00.246-.21 6.679 6.679 0 01.228-.965.25.25 0 00-.238-.325zM23.909 15.791a1.232 1.232 0 00-1.169-.762h-2.5a.25.25 0 01-.23-.151l-1.344-3.117-.013-.027a1.294 1.294 0 00-2.3 0l-.013.027-1.344 3.117a.249.249 0 01-.229.151h-2.5a1.234 1.234 0 00-1.169.762 1.264 1.264 0 00.3 1.378l2.213 1.971a.249.249 0 01.062.286l-1.223 2.816a1.254 1.254 0 00.315 1.45 1.277 1.277 0 001.506.166l3.113-1.752a.249.249 0 01.245 0l3.113 1.752a1.275 1.275 0 001.505-.166 1.256 1.256 0 00.316-1.45l-1.224-2.816a.249.249 0 01.063-.286l2.213-1.971a1.262 1.262 0 00.294-1.378z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});