define("ember-svg-jar/inlined/touch-password-approved", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>touch-password-approved</title><path d=\"M22 0H2a2 2 0 00-2 2v8.5a2 2 0 002 2h1a1 1 0 000-2h-.5A.5.5 0 012 10V2.5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5V10a.5.5 0 01-.5.5h-9a1 1 0 000 2H22a2 2 0 002-2V2a2 2 0 00-2-2z\"/><path d=\"M16 9.495a.993.993 0 00.752-.337l3.5-4a1 1 0 10-1.5-1.316l-2.8 3.2-1.245-1.249a1 1 0 00-1.414 1.414l2 2a.991.991 0 00.707.288zM14.574 14.779l-5.079-1.823V7.5a2 2 0 00-4 0v8.461l-.827-1.138a1.546 1.546 0 00-1.2-.558l-.99.019a1.476 1.476 0 00-1.3 2.179l3.735 6.773A1.463 1.463 0 006.2 24h6.386a1.478 1.478 0 001.379-.989l1.52-6.68a.511.511 0 00.013-.111 1.435 1.435 0 00-.924-1.441z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});