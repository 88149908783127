define("ember-svg-jar/inlined/real-estate-sign-house-sold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-sign-house-sold</title><path d=\"M23.844 10.636L13.03.409a1.5 1.5 0 00-2.06 0L.156 10.636A.5.5 0 00.5 11.5h2a.5.5 0 01.5.5v2.5a.5.5 0 00.5.5h17a.5.5 0 00.5-.5V12a.5.5 0 01.5-.5h2a.5.5 0 00.344-.863zM18 16.5a.75.75 0 00-.75.75v6A.75.75 0 0018 24a3.75 3.75 0 000-7.5zm1.125 5.7a.25.25 0 01-.375-.216v-3.465a.25.25 0 01.375-.217 2.248 2.248 0 010 3.895zM9.5 16.5a2.253 2.253 0 00-2.25 2.25v3a2.25 2.25 0 004.5 0v-3A2.253 2.253 0 009.5 16.5zm.75 5.25a.75.75 0 01-1.5 0v-3a.75.75 0 011.5 0zM16 22.5h-2a.25.25 0 01-.25-.25v-5a.75.75 0 00-1.5 0v5A1.752 1.752 0 0014 24h2a.75.75 0 000-1.5zM4.3 18H6a.75.75 0 000-1.5H4.3a2.053 2.053 0 00-1.139 3.761L5 21.487a.553.553 0 01-.3 1.013H3A.75.75 0 003 24h1.7a2.053 2.053 0 001.139-3.761L4 19.013A.553.553 0 014.3 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});