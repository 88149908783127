define("ember-svg-jar/inlined/multiple-actions-flight.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-flight</title><path d=\"M19 14.5a.5.5 0 00.5-.5 7.542 7.542 0 00-.618-2.923c-.454-.907-1.9-1.441-3.911-2.179l-.485-.173a1.79 1.79 0 01-.056-1.172 4.643 4.643 0 001.216-3.568A3.219 3.219 0 0012.5.5a3 3 0 00-1.341.313.25.25 0 00-.1.364 5 5 0 01.841 2.808 6.263 6.263 0 01-.922 3.624.25.25 0 00.122.372c1.917.716 3.3 1.328 3.9 2.537a8.2 8.2 0 01.737 3.427.251.251 0 00.163.237l.812.3a.258.258 0 00.087.016z\"/><path d=\"M12.077 13.89a2.253 2.253 0 012.065-.347.25.25 0 00.315-.266 6.623 6.623 0 00-.575-2.2c-.454-.907-1.9-1.441-3.911-2.179l-.485-.173a1.79 1.79 0 01-.056-1.172 4.643 4.643 0 001.216-3.568A3.219 3.219 0 007.5.5a3.219 3.219 0 00-3.145 3.485 4.609 4.609 0 001.192 3.538 1.877 1.877 0 010 1.185L5.03 8.9c-2.007.738-3.457 1.272-3.912 2.179A7.542 7.542 0 00.5 14a.5.5 0 00.5.5h10.174a.25.25 0 00.149-.05zM22.046 15.054a1.794 1.794 0 00-1.411.27l-2 1.365a.252.252 0 01-.229.027l-4.65-1.731a.829.829 0 00-.784.111l-.9.669a1.349 1.349 0 00-.122.117.824.824 0 00-.2.605.855.855 0 00.352.611l2.7 1.672a.249.249 0 01.018.413l-.957.709a.25.25 0 01-.276.015l-1.173-.69a.773.773 0 00-.746.132l-.87.73a1.472 1.472 0 00-.126.124.762.762 0 00-.166.562.782.782 0 00.308.537l2.876 2.018a.98.98 0 001.084.032l7.854-4.878a1.864 1.864 0 00.871-1.6 1.821 1.821 0 00-.1-.606 1.872 1.872 0 00-1.353-1.214z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});