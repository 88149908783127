define("ember-svg-jar/inlined/smart-watch-wrist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-wrist</title><path d=\"M5.248 7.635a1 1 0 101.385 1.442.753.753 0 011.016 0 1 1 0 101.384-1.442 2.759 2.759 0 00-3.785 0z\"/><path d=\"M11.461 6.743a1 1 0 00-.028-1.414 6.253 6.253 0 00-8.584 0 1 1 0 001.385 1.442 4.236 4.236 0 015.814 0 1 1 0 001.413-.028z\"/><path d=\"M1.833 4.465a7.733 7.733 0 0110.615 0 1 1 0 101.385-1.442 9.75 9.75 0 00-13.385 0 1 1 0 001.385 1.442zM4.284 19.836a.25.25 0 00-.245-.2h-.148a1 1 0 01-1-1v-5a1 1 0 011-1h.148a.25.25 0 00.245-.2l.1-.5a.25.25 0 00-.245-.3H.641a.5.5 0 00-.5.5v8a.5.5 0 00.5.5h3.5a.249.249 0 00.245-.3zM23.838 18.809l-.819-6.192a3.479 3.479 0 00-3.462-2.981h-4.088a5.5 5.5 0 00-2.729.725l-2.116 1.209a.5.5 0 01-.249.066H8.64a.251.251 0 00-.245.3l.1.5a.25.25 0 00.245.2h.148a1 1 0 011 1v5a1 1 0 01-1 1h-.145a.25.25 0 00-.245.2l-.1.5a.251.251 0 00.052.209.248.248 0 00.193.091h1.533a.5.5 0 01.384.181l.7.841a5.483 5.483 0 004.225 1.978h2.158a2.5 2.5 0 002.406-1.817.251.251 0 01.241-.183h1.07a2.5 2.5 0 002.478-2.827z\"/><rect x=\"4.359\" y=\"14.136\" width=\"4\" height=\"4\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});