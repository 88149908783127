define("ember-svg-jar/inlined/notes-flip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-flip</title><path d=\"M23 4.75a2 2 0 00-2-2h-2a.25.25 0 01-.25-.25V1.25a1 1 0 00-2 0v4.158a1 1 0 102 0V5a.25.25 0 01.25-.25h1.5a.5.5 0 01.5.5v10.5a.5.5 0 01-.5.5h-3a2 2 0 00-2 2v3a.5.5 0 01-.5.5H3.5a.5.5 0 01-.5-.5v-16a.5.5 0 01.5-.5h1a.25.25 0 00.25-.25V3a.25.25 0 00-.25-.25H3a2 2 0 00-2 2v17a2 2 0 002 2h13.5a1 1 0 00.737-.324l5.5-6A1 1 0 0023 16.75z\"/><path d=\"M12.25 6.408a1 1 0 001-1V5a.25.25 0 01.25-.25h2a.25.25 0 00.25-.25V3a.25.25 0 00-.25-.25h-2a.25.25 0 01-.25-.25V1.25a1 1 0 00-2 0v4.158a1 1 0 001 1zM6.75 6.408a1 1 0 001-1V5A.25.25 0 018 4.75h2a.25.25 0 00.25-.25V3a.25.25 0 00-.25-.25H8a.25.25 0 01-.25-.25V1.25a1 1 0 00-2 0v4.158a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});