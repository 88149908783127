define("ember-svg-jar/inlined/messages-people-man-bubble-square-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-man-bubble-square-1</title><path d=\"M22 0H9a2 2 0 00-2 2v3a1 1 0 002 0V2.25A.25.25 0 019.25 2h12.5a.25.25 0 01.25.25v6.5a.25.25 0 01-.25.25H19.5a1 1 0 00-.664.252l-2.42 2.148a.25.25 0 01-.416-.184V10a1 1 0 00-1-1h-1.5a1 1 0 000 2h.5v3a1 1 0 001.664.748L19.88 11H22a2 2 0 002-2V2a2 2 0 00-2-2z\"/><circle cx=\"12.502\" cy=\"5.75\" r=\"1\"/><circle cx=\"15.752\" cy=\"5.75\" r=\"1\"/><circle cx=\"19.003\" cy=\"5.75\" r=\"1\"/><path d=\"M7 17.125a4.75 4.75 0 10-4.75-4.75A4.756 4.756 0 007 17.125zm-2.451-5.971a7.043 7.043 0 003.911 1.221 6.6 6.6 0 001.277-.125c0 .042.013.082.013.125a2.75 2.75 0 01-5.5 0 2.713 2.713 0 01.299-1.221zM7.206 18a7.544 7.544 0 00-7.185 5.357A.5.5 0 00.5 24h13.412a.5.5 0 00.48-.643A7.544 7.544 0 007.206 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});