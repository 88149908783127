define("ember-svg-jar/inlined/cursor-move-target-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-move-target-right</title><path d=\"M18.5 17.3a.251.251 0 00-.168-.387 4.971 4.971 0 010-9.824.25.25 0 00.168-.389 9.5 9.5 0 100 10.6zM7.875 13.5a.5.5 0 00-.5-.5h-2.75a1 1 0 110-2h2.75a.5.5 0 00.5-.5v-1a.5.5 0 01.854-.353l2.5 2.5a.5.5 0 010 .707l-2.5 2.5a.5.5 0 01-.854-.354z\"/><path d=\"M19.125 15.748A3.75 3.75 0 1015.375 12a3.755 3.755 0 003.75 3.748zm0-1.5A2.25 2.25 0 1121.375 12a2.253 2.253 0 01-2.25 2.248z\"/><circle cx=\"19.125\" cy=\"11.998\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});