define("ember-svg-jar/inlined/pin-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin-map</title><path d=\"M18.7 4a.25.25 0 00-.245.3 6.439 6.439 0 01.133 1.288c0 3.193-4.033 6.976-5.27 8.059a2 2 0 01-2.636 0c-1.237-1.083-5.27-4.866-5.27-8.059A6.439 6.439 0 015.545 4.3.25.25 0 005.3 4H4a1.5 1.5 0 00-1.5 1.5V22A1.5 1.5 0 004 23.5h16a1.5 1.5 0 001.5-1.5V5.5A1.5 1.5 0 0020 4z\"/><path d=\"M12 .5a5.094 5.094 0 00-5.088 5.088c0 2.683 4.272 6.5 4.758 6.931a.5.5 0 00.66 0c.486-.427 4.758-4.248 4.758-6.931A5.094 5.094 0 0012 .5zM12 4a1.25 1.25 0 11-1.25 1.25A1.25 1.25 0 0112 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});