define("ember-svg-jar/inlined/instrument-french-horn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>instrument-french-horn</title><path d=\"M23.75 1a1 1 0 00-1.906-.424C20.343 3.791 14.631 4.364 9.237 4.5A9 9 0 002.7 19.675a.25.25 0 00.422-.1 14.42 14.42 0 00.4-1.933.249.249 0 00-.041-.176 6.961 6.961 0 01-.622-1.112.251.251 0 01.223-.354h.369a.249.249 0 00.249-.235c.019-.327.033-.664.041-1.009a.249.249 0 00-.25-.256h-.95a.249.249 0 01-.248-.222 7.159 7.159 0 01-.043-.778 7.009 7.009 0 016.995-7c5.392.136 11.1.71 12.6 3.923A1 1 0 0023.75 10z\"/><path d=\"M5.25 13.994V14c0 3.869-.64 6.853-1.8 8.4a1 1 0 00.8 1.6h4a1 1 0 00.8-1.6c-1.156-1.541-1.8-4.507-1.8-8.355V13.5a4 4 0 014-4h1a4 4 0 014 4 7.131 7.131 0 01-.043.778.25.25 0 01-.249.222H13a.25.25 0 01-.25-.25v-1a.75.75 0 00-1.5 0v1a.25.25 0 01-.25.25H9.009a.251.251 0 00-.25.256c.008.345.022.682.041 1.009a.249.249 0 00.249.235H11a.25.25 0 01.25.25v3.77a.249.249 0 01-.185.241 6.988 6.988 0 01-1.028.195.248.248 0 00-.191.127.252.252 0 00-.006.23 4.213 4.213 0 00.41.687 2.509 2.509 0 01.363.685.249.249 0 00.282.164A9.014 9.014 0 0018.25 13.5a6.007 6.007 0 00-6-6h-1a6 6 0 00-6 6zm7.889 5.322a.25.25 0 01-.389-.207v-2.86A.25.25 0 0113 16h2.414a.25.25 0 01.228.352 7.039 7.039 0 01-2.503 2.964z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});