define("ember-svg-jar/inlined/email-action-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-search</title><path d=\"M16.5 9.5a6.96 6.96 0 014.1 1.335.249.249 0 00.261.021.252.252 0 00.139-.223V1.75a.158.158 0 00-.1-.147.16.16 0 00-.173.034L12.2 10.164a2.407 2.407 0 01-3.4 0L.271 1.637A.159.159 0 000 1.75v10.5A1.749 1.749 0 001.75 14H9.8a.249.249 0 00.231-.155A7.006 7.006 0 0116.5 9.5z\"/><path d=\"M9.726 9.236a1.094 1.094 0 001.547 0L19.748.761a.437.437 0 00.106-.447C19.758.027 19.489 0 19.249 0h-17.5c-.24 0-.51.027-.6.314a.439.439 0 00.1.447zM23.707 22.293l-2.534-2.534a.25.25 0 01-.034-.311 5.509 5.509 0 10-1.691 1.691.25.25 0 01.311.034l2.534 2.534a1 1 0 001.414-1.414zM16.5 20a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});