define("ember-svg-jar/inlined/single-neutral-actions-mobilephone.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-mobilephone</title><path d=\"M13.375 12.5a2.958 2.958 0 01.235-1.142.25.25 0 00-.137-.328 65.299 65.299 0 00-1.849-.7L11 10.1a.6.6 0 01-.138-.31 2.632 2.632 0 01.058-1.3 5.618 5.618 0 001.466-4.314C12.381 1.756 10.8 0 8.625 0S4.869 1.756 4.869 4.174a5.605 5.605 0 001.451 4.3 2.505 2.505 0 01.073 1.317.614.614 0 01-.135.308l-.632.233c-2.446.9-4.214 1.549-4.753 2.626A9.167 9.167 0 00.125 16.5a.5.5 0 00.5.5h12.5a.25.25 0 00.25-.25zM22.4 11h-6.051a1.493 1.493 0 00-1.474 1.509v9.982A1.493 1.493 0 0016.349 24H22.4a1.493 1.493 0 001.474-1.509v-9.982A1.493 1.493 0 0022.4 11zm-5.526 2.5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v8a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});