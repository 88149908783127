define("ember-svg-jar/inlined/vintage-tv-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-tv-3</title><path d=\"M24 2.5A1.5 1.5 0 0022.5 1h-21A1.5 1.5 0 000 2.5v17A1.5 1.5 0 001.5 21h.537a.252.252 0 01.224.138l.515 1.032a1.491 1.491 0 001.342.83h15.764a1.492 1.492 0 001.342-.829l.516-1.033a.249.249 0 01.223-.138h.537a1.5 1.5 0 001.5-1.5zm-5.25 18.25h-1a.75.75 0 010-1.5h1a.75.75 0 010 1.5zM12 17a54.694 54.694 0 01-8.577-.423.5.5 0 01-.423-.494V4.917a.5.5 0 01.423-.494A54.694 54.694 0 0112 4a54.694 54.694 0 018.577.423.5.5 0 01.423.494v11.166a.5.5 0 01-.423.494A54.694 54.694 0 0112 17z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});