define("ember-svg-jar/inlined/couple-home-man-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>couple-home-man-woman</title><path d=\"M23.161 7.672L13.142.354a2.018 2.018 0 00-2.3.014L.859 7.657A2 2 0 000 9.3V23a1 1 0 002 0l.018-13.207a1 1 0 01.409-.8l8.964-6.572a1 1 0 011.181 0L21.59 9a1 1 0 01.41.808V23a1 1 0 002 0V9.3a1.987 1.987 0 00-.839-1.628z\"/><circle cx=\"17.25\" cy=\"11.25\" r=\"2.25\"/><path d=\"M17.25 14a3.231 3.231 0 00-1.171.218.25.25 0 00-.054.437 3.514 3.514 0 01.444 5.35l-.739.771a.5.5 0 00-.136.4l.242 2.379a.5.5 0 00.5.449h1.834a.5.5 0 00.5-.449l.367-3.6a.5.5 0 01.5-.45H20a.5.5 0 00.5-.5V17.25A3.254 3.254 0 0017.25 14z\"/><circle cx=\"6.75\" cy=\"11.25\" r=\"2.25\"/><path d=\"M7.531 20.005a3.514 3.514 0 01.444-5.35.25.25 0 00-.054-.437A3.251 3.251 0 003.5 17.25V19a.5.5 0 00.5.5h.471a.5.5 0 01.5.45l.367 3.6a.5.5 0 00.5.449h1.829a.5.5 0 00.5-.449l.242-2.379a.5.5 0 00-.136-.4z\"/><path d=\"M15.787 16.615a2.017 2.017 0 00-3.23-.524.789.789 0 01-1.114 0 2.017 2.017 0 10-2.852 2.852l3.048 3.181a.5.5 0 00.722 0l3.048-3.181a2.017 2.017 0 00.378-2.328z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});