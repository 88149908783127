define("ember-svg-jar/inlined/style-three-pin-user-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-user-man</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm0 3a2.5 2.5 0 11-2.5 2.5A2.5 2.5 0 0112 3zm3.5 10.5a.5.5 0 01-.5.5h-.835l-.674 3.592A.5.5 0 0113 18h-2a.5.5 0 01-.491-.408L9.835 14H9a.5.5 0 01-.5-.5v-1a3.5 3.5 0 017 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});