define("ember-svg-jar/inlined/vr-phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vr-phone</title><path d=\"M6 8.5a.5.5 0 01-.5-.5V2.5A.5.5 0 016 2h12a.5.5 0 01.5.5V8a.5.5 0 01-.5.5h-3.25a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25h3.75a2 2 0 002-2V2a2 2 0 00-2-2h-13a2 2 0 00-2 2v6.5a2 2 0 002 2h3.75a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25zM21.5 15h-5.416a.252.252 0 00-.19.087c-.444.517-2.124 2.178-2.124 2.178a2.5 2.5 0 01-3.54 0S8.55 15.6 8.106 15.087a.252.252 0 00-.19-.087H2.5a2 2 0 00-2 2v4a3 3 0 003 3h4.26a2 2 0 001.3-.482l.986-.845a3 3 0 013.9 0l.986.845a2 2 0 001.3.482H20.5a3 3 0 003-3v-4a2 2 0 00-2-2z\"/><path d=\"M11.47 16.03a.748.748 0 001.06 0l2-2a.75.75 0 00-.53-1.28h-.75a.25.25 0 01-.25-.25v-5a1 1 0 00-2 0v5a.25.25 0 01-.25.25H10a.75.75 0 00-.53 1.28z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});