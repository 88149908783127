define("ember-svg-jar/inlined/style-one-pin-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-question</title><path d=\"M12 0a9.5 9.5 0 00-4 18.119 2.509 2.509 0 011.218 1.222l1.872 4.076a1 1 0 001.817 0l1.876-4.079A2.5 2.5 0 0116 18.118 9.5 9.5 0 0012 0zm0 15.5a6 6 0 116-6 6.006 6.006 0 01-6 6z\"/><path d=\"M12 5a2.879 2.879 0 00-2.875 2.875 1 1 0 002 0 .875.875 0 111.062.855A1.507 1.507 0 0011 10.2v.3a1 1 0 002 .072A2.875 2.875 0 0012 5z\"/><circle cx=\"12\" cy=\"13.25\" r=\"1.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});