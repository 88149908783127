define("ember-svg-jar/inlined/whale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>whale</title><path d=\"M12 5.5A10.849 10.849 0 001.294 16.206C1.294 21.232 5.1 24 12 24s10.706-2.768 10.706-7.794A10.849 10.849 0 0012 5.5zM3.5 17.845a22.526 22.526 0 002.666.685 5.757 5.757 0 00.909 2.77A5.107 5.107 0 013.5 17.845zM7.25 15a1.25 1.25 0 111.25-1.25A1.25 1.25 0 017.25 15zM11 21.85a3.845 3.845 0 01-1.834-1.093 3.8 3.8 0 01-.935-1.89c.91.113 1.835.181 2.769.211zm3.834-1.093A3.845 3.845 0 0113 21.85v-2.772c.934-.03 1.859-.1 2.769-.211a3.8 3.8 0 01-.935 1.89zm.666-7.007A1.25 1.25 0 1116.75 15a1.25 1.25 0 01-1.25-1.25zm1.425 7.55a5.757 5.757 0 00.909-2.77 22.526 22.526 0 002.666-.685 5.107 5.107 0 01-3.575 3.455zM7 4.5a1 1 0 001-1 1.5 1.5 0 013 0 1 1 0 002 0 1.5 1.5 0 013 0 1 1 0 002 0 3.5 3.5 0 00-6-2.447A3.5 3.5 0 006 3.5a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});