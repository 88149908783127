define("ember-svg-jar/inlined/thunderbolt-cable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>thunderbolt-cable</title><path d=\"M24 15a1.5 1.5 0 00-1.5-1.5h-1.25a.25.25 0 01-.25-.25V10a.5.5 0 00-.333-.472.25.25 0 01-.167-.236V4.348a4.5 4.5 0 00-9 0v14.827A2.921 2.921 0 018.5 22a2.921 2.921 0 01-3-2.826v-4.966a.251.251 0 01.167-.236A.5.5 0 006 13.5v-2.75a.25.25 0 01.25-.25H7.5A1.5 1.5 0 009 9V6.5a1.486 1.486 0 00-.429-1.042.252.252 0 01-.071-.173V1.5A1.5 1.5 0 007 0H2A1.5 1.5 0 00.5 1.5v3.785a.252.252 0 01-.071.174A1.486 1.486 0 000 6.5V9a1.5 1.5 0 001.5 1.5h1.25a.25.25 0 01.25.25v2.75a.5.5 0 00.333.471.251.251 0 01.167.236v4.967A4.921 4.921 0 008.5 24a4.921 4.921 0 005-4.826V4.348a2.5 2.5 0 015 0v4.945a.25.25 0 01-.167.236A.5.5 0 0018 10v3.25a.25.25 0 01-.25.25H16.5A1.5 1.5 0 0015 15v2.5a1.483 1.483 0 00.429 1.041.254.254 0 01.071.175V22.5A1.5 1.5 0 0017 24h5a1.5 1.5 0 001.5-1.5v-3.783a.254.254 0 01.071-.175A1.483 1.483 0 0024 17.5zM2.75 2h3.5a.25.25 0 01.25.25v2a.25.25 0 01-.25.25h-3.5a.25.25 0 01-.25-.25v-2A.25.25 0 012.75 2zm18.5 20h-3.5a.25.25 0 01-.25-.25v-2a.25.25 0 01.25-.25h3.5a.25.25 0 01.25.25v2a.25.25 0 01-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});