define("ember-svg-jar/inlined/composition-4-to-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>composition-4-to-3</title><path d=\"M8 9.127a.25.25 0 00-.313-.242A4.256 4.256 0 004.5 13a.25.25 0 00.25.25h3A.25.25 0 008 13z\"/><path d=\"M20.25 4.25H3.75A3.755 3.755 0 000 8v8a3.755 3.755 0 003.75 3.75h16.5A3.755 3.755 0 0024 16V8a3.755 3.755 0 00-3.75-3.75zM21 14a2.752 2.752 0 01-2.75 2.75h-3a.75.75 0 010-1.5h3a1.25 1.25 0 000-2.5h-1.5a.75.75 0 010-1.5h1.5a1.25 1.25 0 000-2.5h-3a.75.75 0 010-1.5h3a2.748 2.748 0 012.05 4.583.251.251 0 000 .334A2.734 2.734 0 0121 14zm-8.75-3.75A1.25 1.25 0 1113.5 9a1.25 1.25 0 01-1.25 1.25zm1.25 4.25a1.25 1.25 0 11-1.25-1.25 1.25 1.25 0 011.25 1.25zm-9.75.25A.75.75 0 013 14v-1a5.757 5.757 0 015.75-5.75.75.75 0 01.75.75v8A.75.75 0 018 16v-1a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});