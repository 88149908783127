define("ember-svg-jar/inlined/single-man-actions-warning.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-warning</title><path d=\"M7.066 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.743a.5.5 0 01.741-.3A6.956 6.956 0 008.526 5a6.346 6.346 0 00.928-.07.5.5 0 01.568.565A3 3 0 014.066 5a2.8 2.8 0 01.101-.743zM13.43 14.233a.5.5 0 00-.034-.521A7.528 7.528 0 007.273 10.5a7.545 7.545 0 00-7.186 5.357.5.5 0 00.479.643h11.372a.5.5 0 00.443-.268zM18.716 12.271a1.45 1.45 0 00-2.562 0L11.1 21.905a1.433 1.433 0 00.048 1.409 1.457 1.457 0 001.231.686H22.49a1.459 1.459 0 001.234-.687 1.436 1.436 0 00.048-1.408zm-1.281 2.979a.75.75 0 01.75.75v3a.75.75 0 01-1.5 0v-3a.75.75 0 01.75-.75zm0 7a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});