define("ember-svg-jar/inlined/audio-file-aac", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file-aac</title><path d=\"M7 13.625a.877.877 0 00-.875.875v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V14.5A.877.877 0 007 13.625zM17.5 6h3.9a.25.25 0 00.177-.427L16.427.427A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6zM12 13.625a.877.877 0 00-.875.875v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V14.5a.877.877 0 00-.875-.875z\"/><path d=\"M17.5 7A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.25a.25.25 0 00-.25-.25zM9.125 19a.625.625 0 01-1.25 0v-2.125a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V19a.625.625 0 01-1.25 0v-4.375a2.213 2.213 0 011.9-2.239 2.128 2.128 0 012.35 2.114zm5 0a.625.625 0 01-1.25 0v-2.125a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V19a.625.625 0 01-1.25 0v-4.375a2.213 2.213 0 011.9-2.239 2.128 2.128 0 012.35 2.114zm4.375-.625a.625.625 0 010 1.25 3.625 3.625 0 010-7.25.625.625 0 010 1.25 2.375 2.375 0 000 4.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});