define("ember-svg-jar/inlined/code-write-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>code-write-1</title><path d=\"M13.6 20.652l-.5 2.5a.5.5 0 00.491.6.457.457 0 00.1-.01l2.5-.5a.489.489 0 00.256-.136l6.5-6.5a1.914 1.914 0 00-2.713-2.706l-6.5 6.5a.5.5 0 00-.134.252zM1.5 23.25h9.647a.489.489 0 00.479-.392l.5-2.5a1.991 1.991 0 01.547-1.022l4.68-4.681a.5.5 0 00.147-.355V4.664a1 1 0 00-.293-.707L13.794.543a1 1 0 00-.707-.293H1.5a1 1 0 00-1 1v21a1 1 0 001 1zm2-11.5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v1a.25.25 0 00.25.25h1.75a.251.251 0 00.25-.25v-6a.251.251 0 00-.25-.25H8.751a.25.25 0 00-.25.25v1a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v1a.25.25 0 00.25.25h1.75a1.752 1.752 0 011.75 1.75v2a.25.25 0 00.25.25H14a.75.75 0 010 1.5h-1.5a.25.25 0 00-.25.25v2a1.752 1.752 0 01-1.75 1.75H8.751a.25.25 0 00-.25.25v1a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});