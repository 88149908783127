define("ember-svg-jar/inlined/shipment-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-lock</title><path d=\"M9.25 4.5a.25.25 0 00.25.25h5.353a.25.25 0 00.177-.427l-2.884-2.884A1.494 1.494 0 0011.085 1H9.5a.25.25 0 00-.25.25zM7.5 4.75a.25.25 0 00.25-.25V1.25A.25.25 0 007.5 1H5.914a1.49 1.49 0 00-1.06.439L1.969 4.323a.25.25 0 00.177.427zM11.5 16a3 3 0 011.853-2.772.251.251 0 00.154-.218 4.489 4.489 0 012.378-3.71.218.218 0 00.115-.193V6.5a.25.25 0 00-.25-.25H1.25A.25.25 0 001 6.5v9A1.5 1.5 0 002.5 17h8.75a.25.25 0 00.25-.25zM21.714 14.515a.249.249 0 01-.214-.247V13.25a3.5 3.5 0 00-7 0v1.018a.249.249 0 01-.214.247A1.5 1.5 0 0013 16v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V16a1.5 1.5 0 00-1.286-1.485zM16.5 13.25a1.5 1.5 0 013 0v1a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25zm2.5 6a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});