define("ember-svg-jar/inlined/saving-piggy-bank", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>saving-piggy-bank</title><path d=\"M24 9.284a1 1 0 00-.919-1.075A1.011 1.011 0 0022 9.128a.142.142 0 01-.279.027 8.223 8.223 0 00-2.871-4.224 11.158 11.158 0 00-8.912-2.115.252.252 0 01-.245-.1A3.992 3.992 0 005.5 1.255a1 1 0 00-.436 1.693 3 3 0 01.8 1.261.249.249 0 01-.1.284A8.334 8.334 0 002.2 9.433a.249.249 0 01-.243.192H1.5a1.5 1.5 0 00-1.5 1.5v2a1.5 1.5 0 001.5 1.5h1.241a.251.251 0 01.219.129 8.935 8.935 0 003.112 3.2.25.25 0 01.107.281L5.3 21.277a1.251 1.251 0 00.853 1.549 1.278 1.278 0 00.349.049 1.251 1.251 0 001.2-.9l.767-2.649a.251.251 0 01.31-.17 11.344 11.344 0 006.446.007.251.251 0 01.19.021.246.246 0 01.12.15l.765 2.641a1.251 1.251 0 001.2.9 1.284 1.284 0 00.349-.049 1.251 1.251 0 00.853-1.549l-.88-3.039a.251.251 0 01.107-.281 8.335 8.335 0 004.009-5.915.249.249 0 01.158-.2A2.974 2.974 0 0024 9.284zm-6.9-1.709a.75.75 0 01-1.05.15 6.768 6.768 0 00-4.5-1.334.75.75 0 01-.1-1.5 8.209 8.209 0 015.5 1.63.75.75 0 01.15 1.054zM6 10.625a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});