define("ember-svg-jar/inlined/microphone-sing-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>microphone-sing-man</title><path d=\"M18.041 18.907a1.863 1.863 0 01-2.082 0 .75.75 0 10-.918 1.187 3.253 3.253 0 003.918 0 .75.75 0 10-.918-1.187z\"/><circle cx=\"19\" cy=\"17\" r=\".75\"/><circle cx=\"15\" cy=\"17\" r=\".75\"/><path d=\"M18.766 10.344a.246.246 0 00-.073.047 3.241 3.241 0 01-5.005-.762.248.248 0 00-.332-.095A7.148 7.148 0 0010 16v1a7 7 0 0014 0v-1a8.587 8.587 0 00-2.855-6.5.25.25 0 00-.257-.039zM17 22a5.006 5.006 0 01-5-5v-.561a.25.25 0 01.146-.227 5.291 5.291 0 001.713-1.543.249.249 0 01.323-.051 4.943 4.943 0 005.636 0 .249.249 0 01.323.051 5.291 5.291 0 001.713 1.543.25.25 0 01.146.227V17a5.006 5.006 0 01-5 5zM7.75 12.25a.5.5 0 010-1h1.5A.25.25 0 009.5 11V9.5a.25.25 0 00-.25-.25h-1.5a.5.5 0 010-1h1.5A.25.25 0 009.5 8a3 3 0 00-6 0 .25.25 0 00.25.25h2a.5.5 0 010 1h-2a.25.25 0 00-.25.25V11a.25.25 0 00.25.25h2a.5.5 0 010 1h-2a.25.25 0 00-.25.25 3 3 0 006 0 .25.25 0 00-.25-.25z\"/><path d=\"M6.5 3.5A4.505 4.505 0 0111 8a1 1 0 002 0 6.511 6.511 0 00-5.3-6.388.25.25 0 01-.2-.245V1a1 1 0 00-2 0v.367a.25.25 0 01-.2.245A6.511 6.511 0 000 8a1 1 0 002 0 4.505 4.505 0 014.5-4.5zM24 2A2 2 0 0021.632.034c-.041.008-3.572 1.14-3.572 1.14a2 2 0 00-1.56 1.952V6.11a.25.25 0 01-.188.242A1.75 1.75 0 1018.5 8.046V3.308a.249.249 0 01.174-.238l3-.957a.25.25 0 01.326.238V4.11a.25.25 0 01-.188.242A1.75 1.75 0 1024 6.046z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});