define("ember-svg-jar/inlined/garbage-throw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>garbage-throw</title><path d=\"M8.754 16.347l-.747 4.805a1 1 0 01-.99.846l-3.172-.008a1 1 0 01-.986-.846l-.746-4.8a1 1 0 00-1.976.306l.875 5.63A2.01 2.01 0 002.992 24h4.883a2.007 2.007 0 001.979-1.7l.877-5.642a.922.922 0 00-.8-1.087.96.96 0 00-1.177.776z\"/><path d=\"M5.042 19.448A1 1 0 105.876 19a1 1 0 00-.834.448z\"/><circle cx=\"4.375\" cy=\"17\" r=\"1\"/><path d=\"M6.376 15a.988.988 0 00.381-.076 1 1 0 10-.381.076z\"/><circle cx=\"17.375\" cy=\"2.5\" r=\"2.5\"/><path d=\"M19.875 6.39h-5a4 4 0 00-3.99 3.76.34.34 0 00-.01.1 2 2 0 01-1.41 1.91 1.253 1.253 0 00.37 2.45 1.133 1.133 0 00.36-.06 4.062 4.062 0 00.71-.29 4.464 4.464 0 002.47-4.01v-.11a.5.5 0 011 0v12.61a1.25 1.25 0 002.5 0V16.5a.5.5 0 011 0v6.25a1.25 1.25 0 002.5 0V10.14a.5.5 0 011 0V14a1.25 1.25 0 002.5 0v-3.61a4.005 4.005 0 00-4-4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});