define("ember-svg-jar/inlined/bridge-golden-gate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bridge-golden-gate</title><path d=\"M.258 12.785A1.006 1.006 0 001 14.455h4.25a.25.25 0 01.25.25V17.3a.249.249 0 00.193.243 2.511 2.511 0 011.464.961.187.187 0 00.142.078.189.189 0 00.15-.062.172.172 0 00.051-.111v-3.7a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v4.041a.25.25 0 00.436.167 1.744 1.744 0 00.181-.235 2.353 2.353 0 011.223-1.045.25.25 0 00.16-.233v-2.7a.25.25 0 01.25-.25H23a1 1 0 00.742-1.671c-.047-.052-4.714-5.273-4.714-10.329a1.522 1.522 0 00-3.043 0c0 3.318-2.25 6.728-3.788 8.671a.25.25 0 01-.393 0c-1.539-1.942-3.789-5.352-3.789-8.67a1.522 1.522 0 00-3.044 0C4.971 7.5.305 12.732.258 12.785zM18.5 9.432a.251.251 0 01.472-.117 25.378 25.378 0 001.676 2.746.251.251 0 01-.2.394h-1.699a.25.25 0 01-.25-.25zm-4.481 2.62A24.721 24.721 0 0016.03 9a.25.25 0 01.469.121v3.087a.25.25 0 01-.25.25h-2.033a.251.251 0 01-.198-.406zM7.5 9.118A.25.25 0 017.968 9a24.834 24.834 0 002.013 3.055.251.251 0 01-.2.4H7.75a.25.25 0 01-.25-.25zm-4.148 2.943a25.378 25.378 0 001.676-2.746.251.251 0 01.472.116v2.774a.25.25 0 01-.25.25H3.557a.251.251 0 01-.205-.394z\"/><path d=\"M1.417 23.045a4.161 4.161 0 01-.552-.037 1 1 0 01.27-1.982c1.673.229 3.173-1.663 3.188-1.682a1.041 1.041 0 011.65.091 3.525 3.525 0 002.679 1.575 4.173 4.173 0 003.021-1.637 1.046 1.046 0 011.723.159 2.728 2.728 0 002.333 1.478 3.273 3.273 0 002.671-1.553 1.046 1.046 0 011.725-.022c.008.013 1.125 1.772 2.738 1.55a1 1 0 11.27 1.981 4.584 4.584 0 01-3.859-1.441 5.013 5.013 0 01-3.546 1.485 4.461 4.461 0 01-3.348-1.492 5.69 5.69 0 01-3.728 1.492 5.1 5.1 0 01-3.588-1.561 5.666 5.666 0 01-3.647 1.596z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});