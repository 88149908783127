define("ember-svg-jar/inlined/remove-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>remove-tab</title><path d=\"M15.707 19a3.3 3.3 0 002.061-.853l5.086-5.086a1.5 1.5 0 000-2.121l-5.086-5.086A3.3 3.3 0 0015.707 5h-13.5a1.5 1.5 0 00-1.5 1.5v11a1.5 1.5 0 001.5 1.5zM5.525 10.232a1 1 0 111.414-1.414l1.592 1.591a.249.249 0 00.353 0l1.591-1.591a1 1 0 111.415 1.414l-1.59 1.591a.251.251 0 000 .354l1.592 1.591a1 1 0 01-1.415 1.414l-1.593-1.591a.249.249 0 00-.353 0l-1.592 1.591a1 1 0 11-1.414-1.414l1.591-1.591a.249.249 0 000-.354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});