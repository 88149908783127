define("ember-svg-jar/inlined/controller-360", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>controller-360</title><path d=\"M2.023 8h19.954a2.511 2.511 0 01.907.169.25.25 0 00.324-.323L21.467 3.32A.5.5 0 0021 3h-2a.492.492 0 00-.257.071A10.027 10.027 0 0113.585 4.5h-3.17a10.027 10.027 0 01-5.158-1.429A.492.492 0 005 3H3a.5.5 0 00-.467.32L.792 7.846a.25.25 0 00.324.323A2.511 2.511 0 012.023 8zM10.958 16.25a.625.625 0 00.625.625h.834a.625.625 0 100-1.25h-1.209a.25.25 0 00-.25.25zM17.833 13.125a1.042 1.042 0 00-1.041 1.041v1.667a1.042 1.042 0 102.083 0v-1.667a1.043 1.043 0 00-1.042-1.041z\"/><path d=\"M23.5 10.523A1.523 1.523 0 0021.977 9H2.023A1.523 1.523 0 00.5 10.523v8.954A1.523 1.523 0 002.023 21h19.954a1.523 1.523 0 001.523-1.523zm-16.917 5.1H4.5a.625.625 0 010-1.25h2.083a.625.625 0 000-1.25H4.5a.625.625 0 010-1.25h2.083A1.873 1.873 0 018.1 14.852a.251.251 0 000 .295 1.874 1.874 0 01-1.515 2.978H4.5a.625.625 0 010-1.25h2.083a.625.625 0 000-1.25zm5.834-1.25a1.875 1.875 0 110 3.75h-.834a1.877 1.877 0 01-1.875-1.873v-2.5a1.877 1.877 0 011.875-1.875h2.084a.625.625 0 110 1.25h-2.084a.625.625 0 00-.625.625v.375a.25.25 0 00.25.25zm7.708 1.458a2.292 2.292 0 11-4.583 0v-1.665a2.292 2.292 0 014.583 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});