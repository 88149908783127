define("ember-svg-jar/inlined/railroad-wagon-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>railroad-wagon-1</title><path d=\"M5 15.927v-8.95a.25.25 0 00-.092-.193.247.247 0 00-.208-.052 5.485 5.485 0 00-3.11 8.979.25.25 0 01-.189.414H1a1 1 0 000 2h1.089a.249.249 0 00.226-.142 3.248 3.248 0 012.47-1.809.25.25 0 00.215-.247zM9.25 14.875h-2.5a.249.249 0 00-.25.25v1.089a.249.249 0 00.142.226 3.271 3.271 0 011.543 1.542.25.25 0 00.226.143h.839a.25.25 0 00.25-.25v-2.75a.25.25 0 00-.25-.25zM23 16.125h-.4a.25.25 0 01-.189-.414 5.491 5.491 0 00-4.161-9.086h-.5a.25.25 0 01-.25-.25v-2.25a.25.25 0 01.25-.25h1.515a.75.75 0 000-1.5H12.25a.75.75 0 000 1.5h1.5a.25.25 0 01.25.25v2.25a.25.25 0 01-.25.25h-2.5a.249.249 0 00-.25.25v11a.249.249 0 00.25.25h4.339a.25.25 0 00.226-.143 3.248 3.248 0 015.87 0 .25.25 0 00.226.143H23a1 1 0 000-2z\"/><rect x=\"6.5\" y=\"10.875\" width=\"3\" height=\"2.5\" rx=\".25\" ry=\".25\"/><rect x=\"6.5\" y=\"6.625\" width=\"3\" height=\"2.75\" rx=\".25\" ry=\".25\"/><circle cx=\"5.25\" cy=\"19.375\" r=\"2.25\"/><circle cx=\"18.75\" cy=\"19.375\" r=\"2.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});