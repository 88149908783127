define("ember-svg-jar/inlined/road-sign-right-curve-ahead", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-right-curve-ahead</title><path d=\"M23.53 12.53a.749.749 0 000-1.06l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0zM15.75 10a.75.75 0 01-1.28.53l-.405-.4a.251.251 0 00-.342-.011l-1.546 1.353a1.252 1.252 0 00-.427.941V16.5a.75.75 0 01-1.5 0v-4.092a2.75 2.75 0 01.939-2.07l1.446-1.265a.25.25 0 00.085-.18.247.247 0 00-.073-.185l-.177-.178A.75.75 0 0113 7.25h2a.777.777 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});