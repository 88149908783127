define("ember-svg-jar/inlined/design-file-pen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-pen</title><path d=\"M9.458 14.7a1.5 1.5 0 102.122 0 1.5 1.5 0 00-2.122 0z\"/><path d=\"M21.414 4.914L17.086.586A2 2 0 0015.672 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.329a2 2 0 00-.586-1.415zM11.355 19.406L6.931 20.76a1.123 1.123 0 01-1.409-1.414l1.353-4.42a5.5 5.5 0 014.54-3.822.249.249 0 01.27.186 4.707 4.707 0 003.306 3.3.252.252 0 01.187.271 5.5 5.5 0 01-3.823 4.545zm7.28-7.086a3.305 3.305 0 01-4.674-4.674.5.5 0 01.707 0l3.967 3.967a.5.5 0 010 .707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});