define("ember-svg-jar/inlined/shelf-tv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shelf-tv</title><path d=\"M24 13.5a1.5 1.5 0 00-1.5-1.5h-9a.5.5 0 01-.5-.5V11a.5.5 0 01.5-.5H18a2.006 2.006 0 002-2V2a2.005 2.005 0 00-2-2H6a2.005 2.005 0 00-2 2v6.5a2.006 2.006 0 002 2h4.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-9A1.5 1.5 0 000 13.5v10a.5.5 0 00.5.5H4a.484.484 0 00.48-.37l.54-2.05A1.289 1.289 0 016 21h12a1.308 1.308 0 01.99.58l.57 2.06a.5.5 0 00.48.36h3.46a.5.5 0 00.5-.5zm-13.5 4a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5v-2A.5.5 0 013 15h7a.5.5 0 01.5.5zm-4-9A.5.5 0 016 8V2.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5V8a.5.5 0 01-.5.5zm15 9a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h7a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});