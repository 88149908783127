define("ember-svg-jar/inlined/bookmarks-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bookmarks-document</title><path d=\"M24 2.5A1.75 1.75 0 0022.25.749H12a1 1 0 00-1 1v8.5a.5.5 0 01-.9.3L8.2 8.015a.25.25 0 00-.4 0l-1.9 2.532a.5.5 0 01-.9-.3v-8.5a1 1 0 00-1-1H1.75A1.75 1.75 0 000 2.5v19a1.751 1.751 0 001.75 1.75h20.5A1.751 1.751 0 0024 21.5zM4.25 17.749A.75.75 0 015 17h11.5a.75.75 0 010 1.5H5a.75.75 0 01-.75-.751zM19.5 6.5h-5a.75.75 0 010-1.5h5a.75.75 0 010 1.5zm.75 3.25a.75.75 0 01-.75.75h-5a.75.75 0 010-1.5h5a.75.75 0 01.75.749zM19.5 13a.75.75 0 010 1.5H5A.75.75 0 015 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});