define("ember-svg-jar/inlined/style-three-pin-shark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-shark</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm2.641 7.65a.284.284 0 01.39-.144 4.256 4.256 0 001.887.512.529.529 0 01.462.806 3.92 3.92 0 01-3.092 2.066.5.5 0 01-.4-.178.506.506 0 01-.105-.43 15.5 15.5 0 01.858-2.632zm-.99-.9a.5.5 0 01.069.5 11.947 11.947 0 00-1.109 5.421.5.5 0 01-.45.562c-.052 0-.106.008-.161.008a1.864 1.864 0 01-.549-.084.5.5 0 01-.319-.3 1.748 1.748 0 00-1.622-1.125.414.414 0 01-.409-.478c.36-2.289 1.716-4.283 4.075-4.681a.5.5 0 01.475.182zm-2.994-2.932a.53.53 0 01.743.443 1.9 1.9 0 00.426 1.008.5.5 0 01-.193.762A5.54 5.54 0 009.226 8.1a.253.253 0 01-.447-.049c-.469-1.242-.632-3.118 1.878-4.233zM17 15.5a3.627 3.627 0 01-2.192-.693.5.5 0 00-.617 0 3.809 3.809 0 01-4.383 0 .5.5 0 00-.617 0A3.621 3.621 0 017 15.5a.5.5 0 010-1c.827 0 1.812-.354 2.018-1.129a.52.52 0 01.966 0c.206.775 1.19 1.129 2.016 1.129s1.812-.354 2.018-1.129a.52.52 0 01.966 0c.206.775 1.191 1.129 2.016 1.129a.5.5 0 110 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});