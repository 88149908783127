define("ember-svg-jar/inlined/phone-action-edit-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-edit-2</title><path d=\"M11.955 14.629V18.5a1 1 0 01-1 1h-7a1 1 0 01-1-1v-11a1 1 0 011-1h6.582a.25.25 0 00.177-.074L12.15 4.99a2.226 2.226 0 00.456-.516c.058-.17-.179-.287-.365-.349A2.489 2.489 0 0011.455 4h-8a2.5 2.5 0 00-2.5 2.5v15a2.5 2.5 0 002.5 2.5h8a2.5 2.5 0 002.5-2.5v-7.32a.25.25 0 00-.322-.24l-1.5.451a.249.249 0 00-.178.238z\"/><path d=\"M11.786 9.034a.5.5 0 00-.834.216l-.886 3.1a.5.5 0 00.127.491.53.53 0 00.491.127l3.1-.885a.5.5 0 00.216-.834z\"/><rect x=\"12.416\" y=\"3.954\" width=\"8.086\" height=\"5.243\" rx=\".5\" ry=\".5\" transform=\"rotate(-45 16.459 6.576)\"/><path d=\"M22.279.755A2.655 2.655 0 0018.526.8a.5.5 0 000 .708l3 3a.536.536 0 00.377.123.583.583 0 00.377-.169 2.625 2.625 0 00-.001-3.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});