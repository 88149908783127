define("ember-svg-jar/inlined/messages-bubble-share-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-share-alternate</title><path d=\"M9.531 17.789a.25.25 0 00-.194-.277 11.81 11.81 0 01-.736-.184 1 1 0 00-.661.038l-4.174 1.739 1.046-2.874a1 1 0 00-.276-1.089A7.043 7.043 0 012 9.9C2 5.546 6.486 2 12 2c5.273 0 9.593 3.245 9.962 7.339a.248.248 0 00.2.222 4.183 4.183 0 011.451.629.251.251 0 00.391-.2v-.088C24 4.443 18.617 0 12 0S0 4.443 0 9.9a8.877 8.877 0 002.708 6.26l-1.6 4.4a1 1 0 001.325 1.265l5.959-2.483c.32.084.642.152.965.211a.249.249 0 00.286-.311A3.977 3.977 0 019.5 18.2a3.137 3.137 0 01.031-.411z\"/><path d=\"M21.25 18.5a2.733 2.733 0 00-2 .874l-2.753-1.1v-.022a2.742 2.742 0 00-.046-.456l3.125-1.875a2.724 2.724 0 001.671.581 2.8 2.8 0 10-2.7-2.294l-3.125 1.875a2.724 2.724 0 00-1.672-.583 2.75 2.75 0 102 4.626l2.753 1.1v.022a2.75 2.75 0 102.75-2.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});