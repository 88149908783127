define("ember-svg-jar/inlined/read-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-home</title><path d=\"M19.462 9.508c-4.11.1-6.375 1.4-7.462 2.308-1.087-.9-3.352-2.2-7.462-2.308a1.471 1.471 0 00-1.083.424A1.506 1.506 0 003 11.008v8.765a1.508 1.508 0 001.464 1.5c4.964.119 6.609 2.161 6.708 2.29a1 1 0 001.655 0c.088-.115 1.725-2.171 6.708-2.291A1.508 1.508 0 0021 19.773v-8.765a1.5 1.5 0 00-.453-1.074 1.461 1.461 0 00-1.085-.426zM13 13.711a.25.25 0 01.078-.182c.563-.532 2.128-1.679 5.378-1.963a.5.5 0 01.544.5v6.757a.5.5 0 01-.464.5 12.947 12.947 0 00-5.169 1.357.25.25 0 01-.367-.225zm-8-1.647a.5.5 0 01.544-.5c3.259.285 4.823 1.436 5.378 1.961a.249.249 0 01.078.181v6.747a.25.25 0 01-.367.221 12.947 12.947 0 00-5.169-1.357.5.5 0 01-.464-.5zM23.843 8.539a1 1 0 00-.306-1.381l-11-7a1 1 0 00-1.074 0l-11 7a1 1 0 001.074 1.687l10.2-6.487a.5.5 0 01.536 0l10.2 6.487a1 1 0 001.38-.306zM17 1a1 1 0 001 1h1.75a.25.25 0 01.25.25V3a1 1 0 102 0V1a1 1 0 00-1-1h-3a1 1 0 00-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});