define("ember-svg-jar/inlined/color-palette-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-palette-2</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm10 12a10 10 0 01-.346 2.611.252.252 0 01-.132.159.247.247 0 01-.206.006l-5.667-2.347a.25.25 0 01-.154-.244c0-.061.005-.123.005-.185s0-.124-.005-.185a.25.25 0 01.154-.244l5.667-2.347a.247.247 0 01.206.006.252.252 0 01.132.159A10 10 0 0122 12zM2 12a10 10 0 01.346-2.611.252.252 0 01.132-.159.247.247 0 01.206-.006l5.667 2.347a.25.25 0 01.154.244c0 .061-.005.123-.005.185s0 .124.005.185a.25.25 0 01-.154.244l-5.667 2.347a.247.247 0 01-.206-.006.252.252 0 01-.132-.159A10 10 0 012 12zm18.669-4.979a.25.25 0 01.021.205.256.256 0 01-.141.151l-5.667 2.347a.248.248 0 01-.282-.064 3.765 3.765 0 00-.261-.26.251.251 0 01-.064-.282l2.348-5.666a.246.246 0 01.15-.141.252.252 0 01.206.02 10.075 10.075 0 013.69 3.69zm-6.058-4.674a.247.247 0 01.16.131.255.255 0 01.006.206l-2.356 5.687a.219.219 0 01-.215.135c-.068 0-.137-.006-.206-.006s-.124 0-.185.005a.248.248 0 01-.244-.154L9.223 2.684a.251.251 0 01.007-.206.243.243 0 01.159-.131 10 10 0 015.222 0zm-7.591.984a.252.252 0 01.206-.02.246.246 0 01.15.141l2.348 5.666a.251.251 0 01-.064.282 3.765 3.765 0 00-.261.26.248.248 0 01-.281.064L3.451 7.377a.249.249 0 01-.12-.356 10.075 10.075 0 013.689-3.69zM3.331 16.979a.25.25 0 01-.021-.2.256.256 0 01.141-.151l5.771-2.39a.093.093 0 01.107.026 3.553 3.553 0 00.331.341.251.251 0 01.064.282l-2.348 5.661a.246.246 0 01-.15.141.252.252 0 01-.206-.02 10.075 10.075 0 01-3.689-3.69zm6.058 4.674a.247.247 0 01-.16-.131.255.255 0 01-.006-.206l2.348-5.667a.248.248 0 01.244-.154c.061 0 .123.005.185.005s.138 0 .206-.006a.219.219 0 01.215.135l2.356 5.687a.251.251 0 01-.007.206.246.246 0 01-.159.131 10 10 0 01-5.222 0zm7.591-.984a.252.252 0 01-.206.02.246.246 0 01-.15-.141l-2.348-5.666a.251.251 0 01.064-.282c.091-.082.178-.169.261-.26a.248.248 0 01.281-.064l5.667 2.347a.249.249 0 01.12.356 10.075 10.075 0 01-3.689 3.69z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});