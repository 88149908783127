define("ember-svg-jar/inlined/social-media-facebook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-facebook</title><path d=\"M18.14 7.17a.5.5 0 00-.37-.17H14V5.59c0-.28.06-.6.51-.6h3a.44.44 0 00.35-.15.5.5 0 00.14-.34v-4a.5.5 0 00-.5-.5h-4.33C8.37 0 8 4.1 8 5.35V7H5.5a.5.5 0 00-.5.5v4a.5.5 0 00.5.5H8v11.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V12h3.35a.5.5 0 00.5-.45l.42-4a.5.5 0 00-.13-.38z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});