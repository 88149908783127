define("ember-svg-jar/inlined/voice-mail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>voice-mail</title><path d=\"M17.39 6.313a5.746 5.746 0 00-4.118 8.559.247.247 0 01-.208.378h-2.128a.247.247 0 01-.208-.378A5.749 5.749 0 10.005 12.235a5.908 5.908 0 005.983 5.515h12.024A5.908 5.908 0 0024 12.235a5.756 5.756 0 00-6.61-5.922zM2.5 12a3.25 3.25 0 113.25 3.25A3.254 3.254 0 012.5 12zm15.75 3.25A3.25 3.25 0 1121.5 12a3.254 3.254 0 01-3.25 3.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});