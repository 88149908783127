define("ember-svg-jar/inlined/external-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>external-download</title><path d=\"M18.5 13.118a1.472 1.472 0 00-.989-.382H6.5a1.475 1.475 0 00-.99.382l-3.564 3.24a.25.25 0 00.226.429 5.794 5.794 0 011.006-.128h17.649a5.913 5.913 0 011.007.128.25.25 0 00.226-.429zM20.827 18.13H3.173a2.942 2.942 0 000 5.884h17.654a2.942 2.942 0 000-5.884zM7.1 21.808H4.154a.736.736 0 010-1.471H7.1a.736.736 0 110 1.471zm11.771-.735A1.472 1.472 0 1117.4 19.6a1.485 1.485 0 011.467 1.473zM11.289 10.96a.977.977 0 001.381 0l3.417-3.417a.976.976 0 00-.687-1.666h-1.7a.25.25 0 01-.25-.25V1.45a1.465 1.465 0 00-2.929 0v4.177a.25.25 0 01-.25.25h-1.7a.976.976 0 00-.69 1.666z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});