define("ember-svg-jar/inlined/glasses-monocle-moustache", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>glasses-monocle-moustache</title><path d=\"M23 24a1 1 0 01-1-1V6.5a1 1 0 012 0V23a1 1 0 01-1 1zM17.914 21.5a1 1 0 00-1.05-.583 2.277 2.277 0 01-2.6-2.253 3.094 3.094 0 00-1.955-3.015 3.469 3.469 0 00-3.049.28.5.5 0 01-.529 0 3.468 3.468 0 00-3.05-.279 3.093 3.093 0 00-1.954 3.015 2.277 2.277 0 01-2.594 2.253 1 1 0 00-.783 1.754A5.681 5.681 0 004.061 24a6.461 6.461 0 00.954-.071 6.592 6.592 0 003.635-1.774.5.5 0 01.7 0 6.592 6.592 0 003.635 1.774 5.794 5.794 0 004.659-1.257 1 1 0 00.27-1.172z\"/><circle cx=\"22.999\" cy=\"3.5\" r=\"1\"/><path d=\"M14.5 12a6 6 0 116-6 6.006 6.006 0 01-6 6zm0-10a4 4 0 104 4 4 4 0 00-4-4z\"/><path d=\"M12.226 6.75a.75.75 0 01-.75-.75A3.026 3.026 0 0114.5 2.977a.75.75 0 010 1.5A1.524 1.524 0 0012.976 6a.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});