define("ember-svg-jar/inlined/livestock-cow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>livestock-cow</title><path d=\"M21.716 7.685a4.894 4.894 0 00-1.225-.529 7.532 7.532 0 001.333-2.667A5.375 5.375 0 0021.3.659 1.163 1.163 0 0020.269.04h-.034a1.152 1.152 0 00-1.022.675c-.75 1.643-2.613 2.551-2.754 2.4a2 2 0 00-1.851-.615 2.978 2.978 0 00-5.216 0 2 2 0 00-1.851.614A4.373 4.373 0 014.784.716a1.149 1.149 0 00-1.02-.675A1.1 1.1 0 002.7.66a5.369 5.369 0 00-.523 3.829 7.522 7.522 0 001.33 2.667 4.955 4.955 0 00-1.223.529C-.5 9.356.032 11.639.056 11.735a.5.5 0 00.364.365 9.592 9.592 0 004.353-.029 29.919 29.919 0 00.76 3.713 1.009 1.009 0 00-.575 1.261 39.015 39.015 0 001.765 4.3 4.687 4.687 0 004.192 2.623H13.1a4.687 4.687 0 004.192-2.626 36.879 36.879 0 001.754-4.3 1.026 1.026 0 00-.577-1.253 30.117 30.117 0 00.76-3.714 9.576 9.576 0 004.353.029.5.5 0 00.364-.364c.022-.101.554-2.384-2.23-4.055zm-14.57 9.543a14.321 14.321 0 019.711.007 35.024 35.024 0 01-1.367 3.231 2.675 2.675 0 01-2.392 1.5h-2.183a2.677 2.677 0 01-2.392-1.5 37.126 37.126 0 01-1.377-3.238zm2.1-6.886A1.25 1.25 0 118 11.592a1.25 1.25 0 011.25-1.25zM16 11.592a1.25 1.25 0 11-1.25-1.25 1.25 1.25 0 011.25 1.25z\"/><path d=\"M10.5 17.842a.75.75 0 00-.75.75v1a.75.75 0 001.5 0v-1a.75.75 0 00-.75-.75zM13.5 17.842a.75.75 0 00-.75.75v1a.75.75 0 001.5 0v-1a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});