define("ember-svg-jar/inlined/airport-stairs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>airport-stairs</title><path d=\"M.833 2.75a2.5 2.5 0 002.5 2.5h6.341a1 1 0 01.919.606l5.1 10.119a.5.5 0 00.446.275H21.5a.5.5 0 00.441-.735L14.1.822a1 1 0 00-.9-.572H3.333a2.5 2.5 0 00-2.5 2.5z\"/><path d=\"M6 9.75a.5.5 0 00.5.5h2a.5.5 0 00.5-.5 1.5 1.5 0 00-3 0z\"/><circle cx=\"5\" cy=\"21.75\" r=\"2\"/><path d=\"M24 18.25a.5.5 0 00-.5-.5H11a.5.5 0 01-.5-.5v-5.5a.5.5 0 00-.5-.5H4.5a.5.5 0 00-.426.238l-4 6.5A.492.492 0 000 18.25v3a.5.5 0 00.5.5h1.036a.5.5 0 00.494-.428 3 3 0 015.94 0 .5.5 0 00.494.428h7.072a.5.5 0 00.494-.428 3 3 0 015.94 0 .5.5 0 00.494.428H23.5a.5.5 0 00.5-.5zM8.561 15.087a.419.419 0 01-.419.418H4.479a.418.418 0 01-.361-.63L5.1 13.2a.418.418 0 01.357-.2h2.685a.42.42 0 01.419.418z\"/><circle cx=\"19\" cy=\"21.75\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});