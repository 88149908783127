define("ember-svg-jar/inlined/hardware-drill-carbide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hardware-drill-carbide</title><path d=\"M15.346 13.571a.249.249 0 00-.273.054l-3.5 3.5a.249.249 0 00-.073.177v2.879a.25.25 0 00.427.176l3.5-3.5a.248.248 0 00.073-.176V13.8a.25.25 0 00-.154-.229zM15.346 8.571a.249.249 0 00-.273.054l-3.5 3.5a.249.249 0 00-.073.177v2.879a.25.25 0 00.427.176l3.5-3.5a.248.248 0 00.073-.176V8.8a.25.25 0 00-.154-.229zM15.346 18.571a.249.249 0 00-.273.054l-3.5 3.5a.249.249 0 00-.073.177v.689a1 1 0 001 1h2a1 1 0 001-1V18.8a.25.25 0 00-.154-.229zM14.358 3.478a1.043 1.043 0 00-1.715 0l-1 1.665a1 1 0 00-.143.516v4.523a.25.25 0 00.427.176l3.5-3.5a.248.248 0 00.073-.176V5.658a.983.983 0 00-.143-.514zM21.346 10.571a.249.249 0 00-.273.054l-1.5 1.5a.249.249 0 00-.073.177v2.379a.25.25 0 00.427.176l1.5-1.5a.248.248 0 00.073-.176V10.8a.25.25 0 00-.154-.229zM21.346 15.071a.249.249 0 00-.273.054l-1.5 1.5a.249.249 0 00-.073.177v2.379a.25.25 0 00.427.176l1.5-1.5a.248.248 0 00.073-.176V15.3a.25.25 0 00-.154-.229zM21.464 7.556l-.5-1.25a.5.5 0 00-.928 0l-.5 1.25a.491.491 0 00-.036.185v2.44a.25.25 0 00.427.176l1.5-1.5a.248.248 0 00.073-.176v-.94a.491.491 0 00-.036-.185zM21.346 19.571a.249.249 0 00-.273.054l-1.5 1.5a.249.249 0 00-.073.177v2.189a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V19.8a.25.25 0 00-.154-.229zM7.346 11.424a.253.253 0 00-.273.054l-4.5 4.5a.251.251 0 00-.073.177v2.672a.25.25 0 00.427.173l4.5-4.5a.251.251 0 00.073-.177v-2.668a.251.251 0 00-.154-.231zM7.346 5.924a.253.253 0 00-.273.054l-4.5 4.5a.251.251 0 00-.073.177v2.672a.25.25 0 00.427.177L7.427 9a.251.251 0 00.073-.173V6.155a.251.251 0 00-.154-.231zM7.346 16.924a.253.253 0 00-.273.054l-4.5 4.5a.251.251 0 00-.073.177v1.336a1 1 0 001 1h3a1 1 0 001-1v-5.836a.251.251 0 00-.154-.231zM5.781.366a1.031 1.031 0 00-1.562 0l-1.5 1.875a1 1 0 00-.219.624v4.962A.25.25 0 002.927 8l4.5-4.5a.251.251 0 00.073-.173v-.461a1 1 0 00-.219-.624z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});