define("ember-svg-jar/inlined/speaker-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>speaker-1</title><path d=\"M22.663 17.854a.249.249 0 01-.045-.293 11.928 11.928 0 000-11.146.251.251 0 01.045-.294l.344-.344a3.4 3.4 0 000-4.8 3.474 3.474 0 00-4.8 0l-.344.344a.251.251 0 01-.294.045 12.118 12.118 0 00-11.146 0 .251.251 0 01-.294-.045l-.34-.34a3.474 3.474 0 00-4.8 0 3.4 3.4 0 000 4.8l.344.344a.251.251 0 01.045.294 11.928 11.928 0 000 11.146.249.249 0 01-.045.293l-.34.342a3.391 3.391 0 004.8 4.8l.344-.345a.252.252 0 01.294-.044 11.928 11.928 0 0011.146 0 .252.252 0 01.294.044l.344.345a3.391 3.391 0 104.8-4.8zm-1.913 3.884a1 1 0 111-1 1 1 0 01-1 1zm1-18.5a1 1 0 11-1-1 1 1 0 011 1zM12 19.488a7.5 7.5 0 117.5-7.5 7.5 7.5 0 01-7.5 7.5zm-9.75 1.25a1 1 0 111 1 1 1 0 01-1-1zm1-16.5a1 1 0 111-1 1 1 0 01-1 1z\"/><circle cx=\"12\" cy=\"11.988\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});