define("ember-svg-jar/inlined/surveillance-camera-moving", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>surveillance-camera-moving</title><path d=\"M5.328 16.936a.25.25 0 00-.369-.015L.385 21.5a.994.994 0 000 1.41l.71.71a1 1 0 001.41 0l4.574-4.58a.246.246 0 00.073-.187.252.252 0 00-.088-.181 72.86 72.86 0 01-1.736-1.736zM23.524 4.045l-.959-1.93a1.848 1.848 0 00-.68-.68l-1.931-.96a.764.764 0 00-.819.11L6.818 11.522a1 1 0 00-.257 1.12.249.249 0 00.388.1l.145-.116a2.234 2.234 0 012.97.16l1.15 1.15a2.235 2.235 0 01.16 2.97l-.117.146a.25.25 0 00.1.388 1 1 0 001.119-.257L23.415 4.865a.763.763 0 00.109-.82z\"/><path d=\"M9.361 13.5a1.233 1.233 0 00-1.639-.1l-1.436 1.154a1.229 1.229 0 00-.1 1.832l1.43 1.43a1.232 1.232 0 00.87.36h.069a1.241 1.241 0 00.893-.46l1.152-1.439a1.23 1.23 0 00-.092-1.639zM19.538 10.944a2.91 2.91 0 01-1.885 1.885.75.75 0 00.228 1.465.789.789 0 00.228-.035 4.419 4.419 0 002.859-2.86.75.75 0 00-1.43-.455z\"/><path d=\"M23.141 9.415a.75.75 0 00-.734.766 5.363 5.363 0 01-1.582 3.933 5.307 5.307 0 01-3.934 1.586.732.732 0 00-.765.735.749.749 0 00.734.765h.147a6.906 6.906 0 006.9-7.048.745.745 0 00-.766-.737zM12.6 3.031a4.436 4.436 0 00-1.776 1.084 4.36 4.36 0 00-1.082 1.777.749.749 0 00.486.942.789.789 0 00.228.035.748.748 0 00.714-.522 2.913 2.913 0 011.886-1.886.75.75 0 00-.456-1.43z\"/><path d=\"M7.553 7.874h.016a.751.751 0 00.731-.766 5.36 5.36 0 011.585-3.933 5.422 5.422 0 013.934-1.581.777.777 0 00.766-.735.751.751 0 00-.735-.765A6.9 6.9 0 006.8 7.14a.749.749 0 00.753.734z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});