define("ember-svg-jar/inlined/truck-rv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>truck-rv</title><path d=\"M5.251 18.127A2.248 2.248 0 107.5 20.375a2.249 2.249 0 00-2.249-2.248z\"/><path d=\"M22.5 1.377H5.08a1.491 1.491 0 00-1.343.829L1.194 6.41a1.482 1.482 0 00.054 1.527 1.514 1.514 0 001.262.69h1.542a.253.253 0 01.211.115.25.25 0 01.016.24l-2.385 5.169a.281.281 0 01-.05.072L.4 15.671a1.22 1.22 0 00-.4.906v3.8a1.251 1.251 0 001.25 1.25h.65a.249.249 0 00.2-.1.252.252 0 00.039-.221A3.214 3.214 0 012 20.375a3.248 3.248 0 116.36.93.249.249 0 00.239.322h6.551a.249.249 0 00.2-.1.252.252 0 00.039-.221 3.214 3.214 0 01-.137-.93 3.248 3.248 0 116.36.93.249.249 0 00.239.322h.649a1.5 1.5 0 001.5-1.5V2.877a1.5 1.5 0 00-1.5-1.5zM4.288 6.42a.5.5 0 01-.069-.451l.667-2a.5.5 0 01.474-.342h5.14a.5.5 0 01.5.5v2a.5.5 0 01-.5.5H4.694a.5.5 0 01-.406-.207zm7.012 6.015a.692.692 0 01-.692.692H5.5a.5.5 0 01-.454-.709l1.615-3.5a.5.5 0 01.454-.291H10.6a.692.692 0 01.692.692zm10.45-6.308a.5.5 0 01-.5.5H14a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h7.25a.5.5 0 01.5.5z\"/><path d=\"M18.5 18.127a2.248 2.248 0 102.248 2.248 2.249 2.249 0 00-2.248-2.248z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});