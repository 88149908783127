define("ember-svg-jar/inlined/mobile-phone-blackberry-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mobile-phone-blackberry-2</title><path d=\"M19 0H5a2.5 2.5 0 00-2.5 2.5v19A2.5 2.5 0 005 24h14a2.5 2.5 0 002.5-2.5v-19A2.5 2.5 0 0019 0zm.5 12a1 1 0 01-1 1h-13a1 1 0 01-1-1V3a1 1 0 011-1h13a1 1 0 011 1zm-3 5.5a1 1 0 111-1 1 1 0 01-1 1zm1 2.5a1 1 0 11-1-1 1 1 0 011 1zM12 17.5a1 1 0 111-1 1 1 0 01-1 1zm1 2.5a1 1 0 11-1-1 1 1 0 011 1zm-5.5-2.5a1 1 0 111-1 1 1 0 01-1 1zm1 2.5a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});