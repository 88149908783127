define("ember-svg-jar/inlined/book-flip-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-flip-page</title><path d=\"M11.278 6.134a.25.25 0 00-.116-.247C9.9 5.1 7.033 3.859 1.507 3.787a.5.5 0 00-.507.5v15.765a.5.5 0 00.493.5c5.151.066 7.994 1.15 9.385 1.932a.247.247 0 00.248 0 .25.25 0 00.124-.216V6.446a2.534 2.534 0 01.028-.312zM19.25 17.446a.751.751 0 01-.624.739 8.419 8.419 0 00-4.292 2.258 8.007 8.007 0 00-1.027 1.429.25.25 0 00.328.346 20.992 20.992 0 018.872-1.666.5.5 0 00.493-.5V4.287a.5.5 0 00-.507-.5 32.06 32.06 0 00-3.02.176.25.25 0 00-.223.249z\"/><path d=\"M17.568 16.9a.251.251 0 00.182-.241V1.734a.25.25 0 00-.336-.235 7.679 7.679 0 00-3.08 1.945 5.455 5.455 0 00-1.584 3.014V19.31a.25.25 0 00.438.165l.023-.026a9.322 9.322 0 014.357-2.549z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});