define("ember-svg-jar/inlined/cursor-target", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-target</title><path d=\"M23 11h-2.059A9.014 9.014 0 0013 3.056V1a1 1 0 10-2 0v2.056A9.014 9.014 0 003.059 11H1a1 1 0 000 2h2.059A9.013 9.013 0 0011 20.939V23a1 1 0 102 0v-2.061A9.013 9.013 0 0020.941 13H23a1 1 0 000-2zm-4.129-.3a.251.251 0 01-.246.3h-2.051a.249.249 0 01-.241-.184 4.506 4.506 0 00-3.149-3.149.251.251 0 01-.184-.243V5.373a.251.251 0 01.3-.246 7.008 7.008 0 015.571 5.573zM12 14.5a2.5 2.5 0 112.5-2.5 2.5 2.5 0 01-2.5 2.5zm-1.3-9.373a.251.251 0 01.3.246v2.051a.249.249 0 01-.184.241 4.506 4.506 0 00-3.149 3.149.251.251 0 01-.241.186H5.375a.251.251 0 01-.246-.3A7.008 7.008 0 0110.7 5.127zm-5.571 8.167a.25.25 0 01.246-.3h2.051a.249.249 0 01.241.184 4.506 4.506 0 003.149 3.149.25.25 0 01.184.241v2.051a.247.247 0 01-.091.192.251.251 0 01-.206.054 7.008 7.008 0 01-5.574-5.571zm8.171 5.575a.251.251 0 01-.206-.054.247.247 0 01-.094-.192v-2.051a.249.249 0 01.184-.241 4.506 4.506 0 003.149-3.149.249.249 0 01.241-.182h2.051a.249.249 0 01.246.3 7.008 7.008 0 01-5.571 5.569z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});