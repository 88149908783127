define("ember-svg-jar/inlined/gesture-tap-all-direction-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-tap-all-direction-1</title><path d=\"M12 1.5a1.5 1.5 0 01.937.329l1.791 1.432a.25.25 0 00.344-.03.722.722 0 00.136-.231.749.749 0 00-.239-.833l-2.5-2a.748.748 0 00-.937 0l-2.5 2A.747.747 0 008.793 3a.715.715 0 00.135.232.25.25 0 00.345.031l1.79-1.432A1.5 1.5 0 0112 1.5zM12 22.5a1.5 1.5 0 01-.937-.329l-1.79-1.432a.25.25 0 00-.345.031.7.7 0 00-.135.23.747.747 0 00.239.833l2.5 2a.75.75 0 00.937 0l2.5-2a.749.749 0 00.239-.833.722.722 0 00-.136-.233.249.249 0 00-.344-.03l-1.791 1.432A1.5 1.5 0 0112 22.5zM23.835 11.031l-2-2.5A.752.752 0 0021 8.292a.728.728 0 00-.232.136.251.251 0 00-.031.345l1.433 1.79a1.5 1.5 0 010 1.874l-1.433 1.79a.251.251 0 00.031.345.707.707 0 00.232.135.749.749 0 00.834-.238l2-2.5a.752.752 0 00.001-.938zM1.829 10.563l1.432-1.79a.25.25 0 00-.03-.345A.728.728 0 003 8.292a.751.751 0 00-.834.239l-2 2.5a.752.752 0 000 .938l2 2.5a.75.75 0 00.585.281.739.739 0 00.481-.178.25.25 0 00.031-.344l-1.434-1.791a1.5 1.5 0 010-1.874z\"/><path d=\"M9.5 5.75h5a.75.75 0 00.469-1.335l-2.5-2a.748.748 0 00-.937 0l-2.5 2A.75.75 0 009.5 5.75zM14.5 18.25h-5a.75.75 0 00-.468 1.335l2.5 2a.75.75 0 00.937 0l2.5-2a.75.75 0 00-.469-1.335zM4.415 8.531l-2 2.5a.752.752 0 000 .938l2 2.5A.751.751 0 005.75 14V9a.75.75 0 00-1.335-.469zM19.585 8.531A.751.751 0 0018.25 9v5a.75.75 0 001.335.469l2-2.5a.752.752 0 000-.938zM12 8.5A3.5 3.5 0 008.5 12v3.5a.5.5 0 00.5.5h6a.5.5 0 00.5-.5V12A3.5 3.5 0 0012 8.5zm1.5 4.625a.375.375 0 01-.375.375h-2.25a.375.375 0 01-.375-.375V12a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});