define("ember-svg-jar/inlined/asian-food-kiosk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>asian-food-kiosk</title><path d=\"M12.249 17.963a1 1 0 100-2H7.26a1 1 0 100 2s.224-.021.178.163l-1.146 4.585a1 1 0 00.726 1.209.968.968 0 00.243.03 1 1 0 00.967-.756.323.323 0 01.29-.242h2.454a.3.3 0 01.31.242 1 1 0 00.967.756.966.966 0 00.242-.03 1 1 0 00.726-1.209s-1.085-4.306-1.13-4.519a.177.177 0 01.162-.229zM10.4 20.957H9.1a.25.25 0 01-.243-.31l.626-2.5a.247.247 0 01.265-.18c.23 0 .261.168.261.168l.629 2.516a.25.25 0 01-.238.306z\"/><path d=\"M22.478 11.975h-.249a.239.239 0 01-.25-.24V8.482a1 1 0 00-2 0v3.244a.248.248 0 01-.249.249h-1.243a.249.249 0 01-.25-.249V9.48a1 1 0 00-1-1h-1a1 1 0 00-1 1v2.246a.248.248 0 01-.249.249h-2.519c-.293 0-.148-.273-.148-.273l.264-.528a1 1 0 00-.893-1.444H7.318a1 1 0 00-.892 1.444l.247.494s.208.307-.1.307H4.266a.248.248 0 01-.249-.249V8.482a1 1 0 00-2 0v3.242a.259.259 0 01-.25.251h-.245a1 1 0 000 2h.249a.275.275 0 01.25.259v8.722a1 1 0 002 0V14.22a.249.249 0 01.249-.249h15.464a.249.249 0 01.249.249V23a1 1 0 002 0v-8.78a.232.232 0 01.238-.251h.261a1 1 0 000-2zM4.516 6.487a2.994 2.994 0 002.993-2.994V.5a.5.5 0 00-.5-.5H2.021a.5.5 0 00-.5.5v2.993a2.994 2.994 0 002.995 2.994zM12 6.487a3 3 0 002.994-2.994V.5a.5.5 0 00-.5-.5H9.505a.5.5 0 00-.5.5v2.993A3 3 0 0012 6.487zM19.484 6.487a2.994 2.994 0 002.994-2.994V.5a.5.5 0 00-.5-.5H16.99a.5.5 0 00-.5.5v2.993a2.994 2.994 0 002.994 2.994z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});