define("ember-svg-jar/inlined/chef-gear-gloves", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chef-gear-gloves</title><path d=\"M9.708 17.128a.25.25 0 00.131-.14.255.255 0 00-.007-.192l-2.779-6.087C5.5 7.311 6.035 4.08 8.313 2.141a.5.5 0 00-.055-.8 5.176 5.176 0 00-5.094-.313C.118 2.417-.84 5.737.78 9.287l4.293 9.406a.5.5 0 00.663.247zM6.358 20.305a.5.5 0 00-.247.662l.428.939a1 1 0 001.326.5l3.29-1.5a.5.5 0 00.261-.28.5.5 0 00-.014-.383l-.74-1.621a.25.25 0 00-.331-.123z\"/><path d=\"M22.2 15.712a.5.5 0 00.247-.663l-.238-.522a1.508 1.508 0 01-.1-.964l1.375-5.9a2.521 2.521 0 00-3.593-2.653 2.429 2.429 0 00-1.107 1.053.249.249 0 01-.228.132.252.252 0 01-.22-.146l-.186-.408c-1.62-3.55-4.757-5-7.8-3.612s-4 4.712-2.384 8.262l4.291 9.409a.5.5 0 00.662.248zM23.416 19.587a1 1 0 00.494-1.325l-.428-.939a.5.5 0 00-.663-.247l-9.277 4.235a.5.5 0 00-.247.662l.428.938a1 1 0 001.325.495z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});