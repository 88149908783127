define("ember-svg-jar/inlined/shipment-cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-cash</title><path d=\"M8.75 4a.249.249 0 00.25.25h5.353a.25.25 0 00.177-.427L11.646.939A1.511 1.511 0 0010.585.5H9a.249.249 0 00-.25.25zM15.3 9.186a.249.249 0 00.2-.244V6a.25.25 0 00-.25-.25H.75A.249.249 0 00.5 6v9A1.5 1.5 0 002 16.5h6.8a.249.249 0 00.248-.227A8.009 8.009 0 0115.3 9.186zM7 4.25A.25.25 0 007.25 4V.75A.25.25 0 007 .5H5.414a1.511 1.511 0 00-1.061.439L1.469 3.823a.25.25 0 00.177.427z\"/><path d=\"M17 10.5a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zM16.25 21a.249.249 0 00-.25-.25h-.5a.75.75 0 010-1.5h2.034a.592.592 0 00.219-1.141l-2.062-.825a2.084 2.084 0 01.36-3.981.25.25 0 00.2-.245v-.308a.75.75 0 011.5 0V13a.25.25 0 00.25.25h.5a.75.75 0 010 1.5h-2.034a.591.591 0 00-.219 1.141l2.062.825a2.084 2.084 0 01-.36 3.981.25.25 0 00-.2.245v.308a.75.75 0 01-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});