define("ember-svg-jar/inlined/halloween-graveyard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>halloween-graveyard</title><path d=\"M2.029 16.577a.488.488 0 00.262.378.372.372 0 00.294.021 8.44 8.44 0 012.748-.444 8.5 8.5 0 012.607.4.5.5 0 00.5-.111A4.644 4.644 0 019.667 16a.5.5 0 00.286-.512l-.485-4.131a4 4 0 00-7.134-1.936 3.965 3.965 0 00-.8 2.95zm2.16-4.884l.129-.018a.5.5 0 00.425-.555l-.025-.21a.749.749 0 111.488-.181l.022.183a.477.477 0 00.54.414.746.746 0 01.848.636.766.766 0 01-.663.852.5.5 0 00-.426.555l.106.867a.749.749 0 11-1.488.181l-.1-.815a.5.5 0 00-.567-.435 1.125 1.125 0 01-.183.019.75.75 0 01-.106-1.493zM23 5.385h-2.75v-2.75a1 1 0 00-1-1h-2a1 1 0 00-1 1v2.75H13.5a1 1 0 00-1 1v1.98a1 1 0 001 1h2.75v5.705a.5.5 0 00.645.479 11.95 11.95 0 012.88-.495.5.5 0 00.475-.5V9.365H23a1 1 0 001-1v-1.98a1 1 0 00-1-1zM23.666 17.134a9.982 9.982 0 00-3.291-.6 9.416 9.416 0 00-5.6 1.681.5.5 0 01-.681-.076 3.2 3.2 0 00-5.122.384.5.5 0 01-.656.183 6.6 6.6 0 00-2.98-.672 6.679 6.679 0 00-2.813.59.5.5 0 01-.541-.075 2.127 2.127 0 00-1.4-.515l-.108.009a.5.5 0 00-.475.5v3.325a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-4.262a.5.5 0 00-.333-.472zM3.447 3.728A8.775 8.775 0 015.905 4.8a1 1 0 001.19 0 8.775 8.775 0 012.458-1.072 1 1 0 00-.606-1.907 13.651 13.651 0 00-2.447.994 13.651 13.651 0 00-2.447-.994 1 1 0 00-.606 1.907z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});