define("ember-svg-jar/inlined/diving-oxygen-tank", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diving-oxygen-tank</title><path d=\"M9.146 3.146l-1 1a.5.5 0 00.708.708l1-1a.5.5 0 00-.708-.708z\"/><rect y=\"15.25\" width=\"8\" height=\"4.25\" rx=\".5\" ry=\".5\"/><path d=\"M4 8.75a4 4 0 00-4 4v.5a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-.5a4 4 0 00-4-4zM7.5 21h-7a.5.5 0 00-.5.5V23a1 1 0 001 1h6a1 1 0 001-1v-1.5a.5.5 0 00-.5-.5z\"/><rect x=\"10\" y=\"15.25\" width=\"8\" height=\"4.25\" rx=\".5\" ry=\".5\"/><path d=\"M14 8.75a4 4 0 00-4 4v.5a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-.5a4 4 0 00-4-4zM17.5 21h-7a.5.5 0 00-.5.5V23a1 1 0 001 1h6a1 1 0 001-1v-1.5a.5.5 0 00-.5-.5z\"/><path d=\"M23.2 14.4a.5.5 0 01-.2-.4V8.5a5 5 0 00-5-5h-4.229a.5.5 0 01-.477-.35 4.5 4.5 0 100 2.7.5.5 0 01.477-.349H18a3 3 0 013 3V14a.5.5 0 01-.2.4 2 2 0 102.4 0zM9 7a2.5 2.5 0 112.5-2.5A2.5 2.5 0 019 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});