define("ember-svg-jar/inlined/read-world", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-world</title><path d=\"M3.974 9.026a19.206 19.206 0 016.454 1.2.251.251 0 00.339-.292l-.606-2.41A1.993 1.993 0 008.22 6H6.8a.5.5 0 01-.433-.75 6.522 6.522 0 018.4-2.626.251.251 0 01-.045.469 1.825 1.825 0 00-1.382 1.422l-.5 2.017a2.038 2.038 0 00.371 1.706 1.873 1.873 0 001.4.74.5.5 0 01.444.415.29.29 0 00.358.233 21.811 21.811 0 014.613-.608.5.5 0 00.483-.5 8.5 8.5 0 10-17 .013.5.5 0 00.465.495z\"/><path d=\"M21.562 10.437A1.653 1.653 0 0020.478 10h-.025c-3.6.07-6.554.862-8.153 2.062a.5.5 0 01-.6 0c-1.6-1.2-4.552-1.992-8.153-2.062h-.025a1.653 1.653 0 00-1.084.435A1.429 1.429 0 002 11.467v7.846a1.49 1.49 0 001.479 1.458c4.8.087 6.951 1.425 7.421 2.032a.5.5 0 01.1.269 1 1 0 001.994 0 .5.5 0 01.1-.269c.47-.607 2.619-1.945 7.421-2.032A1.49 1.49 0 0022 19.313v-7.846a1.429 1.429 0 00-.438-1.03zM11 19.967a.249.249 0 01-.361.224 16.4 16.4 0 00-6.167-1.385.5.5 0 01-.472-.5v-5.765a.5.5 0 01.531-.5c3.519.214 5.392 1.13 6.114 1.739a1 1 0 01.355.764zm9-1.66a.5.5 0 01-.472.5 16.4 16.4 0 00-6.167 1.385.249.249 0 01-.361-.225v-5.422a1 1 0 01.355-.764c.722-.609 2.595-1.525 6.114-1.739a.5.5 0 01.531.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});