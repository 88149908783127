define("ember-svg-jar/inlined/beard-style-mustache", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beard-style-mustache</title><path d=\"M21.076 11.4c-1.853-.25-2.412-3.606-5.219-4.23-2-.444-3 .21-3.474.774a.5.5 0 01-.766 0c-.473-.565-1.475-1.219-3.474-.775-2.807.624-3.366 3.98-5.219 4.23C.508 11.722-.391 8.977.153 12.75c.438 3.039 2.958 4.23 6.193 4.23a8.054 8.054 0 005.3-2.5.5.5 0 01.7 0 8.054 8.054 0 005.3 2.5c3.235 0 5.755-1.191 6.193-4.23.552-3.773-.347-1.028-2.763-1.35z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});