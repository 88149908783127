define("ember-svg-jar/inlined/road-sign-board-left-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-board-left-alternate</title><path d=\"M23 2.039H1a1 1 0 00-1 1v12a1 1 0 001 1h10a.25.25 0 01.25.25v4.672a1 1 0 002 0v-4.672a.25.25 0 01.25-.25H23a1 1 0 001-1v-12a1 1 0 00-1-1zm-1 11.75a.25.25 0 01-.25.25H2.25a.25.25 0 01-.25-.25v-9.5a.25.25 0 01.25-.25h19.5a.25.25 0 01.25.25z\"/><path d=\"M18.75 7.961H9a.25.25 0 01-.25-.25v-1.25a.75.75 0 00-1.186-.61l-3.5 2.5a.75.75 0 000 1.221l3.5 2.5a.751.751 0 001.186-.611v-1.25a.25.25 0 01.25-.25h9.75a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});