define("ember-svg-jar/inlined/toys-car", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toys-car</title><path d=\"M22.25 6h-1.5a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v4.5a.5.5 0 01-.5.5h-1.5a1.752 1.752 0 00-1.75 1.75v6.9a.5.5 0 00.357.479A3 3 0 0117 18v2a.5.5 0 00.5.5h4.75A1.752 1.752 0 0024 18.75v-11A1.752 1.752 0 0022.25 6zm-3 6.708a1.979 1.979 0 111.979-1.979 1.979 1.979 0 01-1.979 1.979z\"/><circle cx=\"3.75\" cy=\"21.75\" r=\"2.25\"/><path d=\"M14 16h-.5a.5.5 0 01-.5-.5V15a3 3 0 00-3-3H6a3 3 0 00-3 3v.5a.5.5 0 01-.5.5H2a2 2 0 00-2 2v1.5a1.988 1.988 0 00.3 1.053.25.25 0 00.443-.037 3.25 3.25 0 016.228 1.667.5.5 0 00.5.567h1.062a.5.5 0 00.5-.567 3.25 3.25 0 016.228-1.667.25.25 0 00.443.037A1.988 1.988 0 0016 19.5V18a2 2 0 00-2-2zm-7-.5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V15a1 1 0 011-1h.5a.5.5 0 01.5.5zm4 0a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h.5a1 1 0 011 1z\"/><circle cx=\"12.25\" cy=\"21.75\" r=\"2.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});