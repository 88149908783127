define("ember-svg-jar/inlined/meeting-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-monitor</title><path d=\"M19.211 11.455A.249.249 0 0019 11.7v5.047a.249.249 0 01-.25.25H2.25a.249.249 0 01-.25-.25V5.724a.208.208 0 01.166-.224H9.25a.249.249 0 00.25-.25v-1.5a.25.25 0 00-.25-.25H2.166A2.2 2.2 0 000 5.724v12.551A2.2 2.2 0 002.166 20.5H9.25a.25.25 0 01.25.25v1a.249.249 0 01-.25.25H5.5a1 1 0 000 2h10a1 1 0 000-2h-3.75a.249.249 0 01-.25-.25v-1a.25.25 0 01.25-.25h7.084A2.2 2.2 0 0021 18.275v-7.226a.252.252 0 00-.141-.225.249.249 0 00-.263.029 2.989 2.989 0 01-1.385.602z\"/><path d=\"M23.371 1.517l-2.807.748a.251.251 0 01-.314-.242V1.5a1.5 1.5 0 00-1.5-1.5H12.5A1.5 1.5 0 0011 1.5v7a1.5 1.5 0 001.5 1.5h6.25a1.5 1.5 0 001.5-1.5v-.524a.25.25 0 01.314-.242l2.807.748A.5.5 0 0024 8V2a.5.5 0 00-.2-.4.493.493 0 00-.429-.083z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});