define("ember-svg-jar/inlined/shipment-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-search</title><path d=\"M9 4a.25.25 0 00.25.25h5.35a.25.25 0 00.177-.427L11.9.939A1.494 1.494 0 0010.835.5H9.25A.25.25 0 009 .75zM15.509 9a.25.25 0 00.241-.25V6a.25.25 0 00-.25-.25H1A.25.25 0 00.75 6v9a1.5 1.5 0 001.5 1.5h6.258a.25.25 0 00.249-.264c0-.078-.007-.157-.007-.236a7.009 7.009 0 016.759-7zM7.25 4.25A.25.25 0 007.5 4V.75A.25.25 0 007.25.5H5.664A1.49 1.49 0 004.6.939L1.719 3.823a.25.25 0 00.181.427z\"/><path d=\"M20.423 19.259a.25.25 0 01-.034-.311 5.513 5.513 0 10-1.689 1.691.25.25 0 01.311.034l2.535 2.534a1 1 0 001.414-1.414zM12.25 16a3.5 3.5 0 113.5 3.5 3.5 3.5 0 01-3.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});