define("ember-svg-jar/inlined/credit-card-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-sync</title><path d=\"M17.027 4.125a.028.028 0 00.028-.027v-.306a2.406 2.406 0 00-2.389-2.417H2.389A2.4 2.4 0 000 3.792V4.1a.027.027 0 00.027.027zM16.826 7.906a.25.25 0 00.229-.249v-2a.028.028 0 00-.028-.027h-17A.027.027 0 000 5.652v5.806a2.4 2.4 0 002.389 2.417h7.2a.251.251 0 00.241-.182 8 8 0 016.996-5.787zM3.333 9.625h2.833a.75.75 0 010 1.5H3.333a.75.75 0 010-1.5zM17.612 22.625a6.108 6.108 0 005.78-4.129 1 1 0 00-1.893-.648 4.106 4.106 0 01-6.927 1.429.249.249 0 01.008-.345l1.731-1.732a.5.5 0 00-.354-.853H11.5a.5.5 0 00-.5.5v4.457a.5.5 0 00.854.353l.958-.958a.251.251 0 01.359 0 6.119 6.119 0 004.441 1.926z\"/><path d=\"M22.122 11.53a.249.249 0 01-.358 0 6.1 6.1 0 00-10.2 2.228 1 1 0 001.893.648 4.1 4.1 0 016.9-1.452.251.251 0 01-.006.347l-1.661 1.662a.5.5 0 00.353.853H23.5a.5.5 0 00.5-.5v-4.457a.5.5 0 00-.676-.467.493.493 0 00-.178.114z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});