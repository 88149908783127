define("ember-svg-jar/inlined/hair-skin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hair-skin</title><path d=\"M12.024 14.913A3.95 3.95 0 0016 10.9a5.134 5.134 0 00-.688-2.4c-.67-1.368-1.364-2.782.364-7.04A1 1 0 0014.154.283C12.446 1.55 7.249 5.408 8 10.97a4.208 4.208 0 004.024 3.943z\"/><path d=\"M22.5 10.913h-4.02a1.018 1.018 0 00-1 .942 5.5 5.5 0 01-10.964 0 1.018 1.018 0 00-1-.941H1.5a1.5 1.5 0 00-1.5 1.5v10a1.5 1.5 0 001.5 1.5h21a1.5 1.5 0 001.5-1.5v-10a1.5 1.5 0 00-1.5-1.501zm-21 4.492a1 1 0 011-.992.994.994 0 110 1.987 1 1 0 01-1-.995zm2 7.008a.993.993 0 110-1.986.993.993 0 110 1.986zM7 18.9a.994.994 0 110-1.987.994.994 0 110 1.987zm2 3.513a.993.993 0 110-1.986.993.993 0 110 1.986zm5.5 0a.993.993 0 110-1.986.993.993 0 110 1.986zM17 18.9a.994.994 0 110-1.987.994.994 0 110 1.987zm3 3.513a.993.993 0 110-1.986.993.993 0 110 1.986zm1.5-6.013a.994.994 0 110-1.987.994.994 0 110 1.987z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});