define("ember-svg-jar/inlined/advertising-billboard-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>advertising-billboard-building</title><path d=\"M9.5 8.75h-3a.75.75 0 000 1.5h3a.75.75 0 000-1.5zM9.5 11.25h-3a.75.75 0 000 1.5h3a.75.75 0 000-1.5z\"/><path d=\"M21.5 0h-19A1.5 1.5 0 001 1.5v16A1.5 1.5 0 002.5 19a.5.5 0 01.5.5V23a1 1 0 001 1h2a1 1 0 001-1v-3.5a.5.5 0 01.5-.5h9a.5.5 0 01.5.5V23a1 1 0 001 1h2a1 1 0 001-1v-3.5a.5.5 0 01.5-.5 1.5 1.5 0 001.5-1.5v-16A1.5 1.5 0 0021.5 0zm-10 16a.5.5 0 01-.5.5H9.5A.5.5 0 019 16v-1a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v1a.5.5 0 01-.5.5H5a.5.5 0 01-.5-.5V8.5a.5.5 0 01.276-.447l3-1.5a.5.5 0 01.448 0l3 1.5a.5.5 0 01.276.447zm8 0a.5.5 0 01-.5.5h-5.5a.5.5 0 01-.5-.5V3a.5.5 0 01.707-.455l5.5 2.5a.5.5 0 01.293.455z\"/><path d=\"M17.5 8.75H15a.75.75 0 000 1.5h2.5a.75.75 0 000-1.5zM17.5 6.25H15a.75.75 0 000 1.5h2.5a.75.75 0 000-1.5zM17.5 11.25H15a.75.75 0 000 1.5h2.5a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});