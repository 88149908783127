define("ember-svg-jar/inlined/messages-bubble-square-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-menu</title><path d=\"M22 1.5H2A1.5 1.5 0 00.5 3v13.552A1.459 1.459 0 002 18h3v4a.5.5 0 00.785.411L12.156 18H22a1.5 1.5 0 001.5-1.5V3A1.5 1.5 0 0022 1.5zm-16 9A1.5 1.5 0 114.5 12 1.5 1.5 0 016 10.5zm-1.5-3A1.5 1.5 0 116 9a1.5 1.5 0 01-1.5-1.5zm5.75 4.5a.75.75 0 01.75-.75h8a.75.75 0 010 1.5h-8a.75.75 0 01-.75-.75zM19 8.25h-8a.75.75 0 010-1.5h8a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});