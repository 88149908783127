define("ember-svg-jar/inlined/video-game-disk-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-disk-controller</title><path d=\"M11.33 15.459h7.8a1 1 0 00.909-.582 10.5 10.5 0 10-14.353 4.952.5.5 0 00.721-.346 5.02 5.02 0 014.92-4.024zm3.1-6.45l3.77-1.618a.5.5 0 01.678.317 9.032 9.032 0 010 5.1.5.5 0 01-.677.318l-3.683-1.581a.5.5 0 01-.3-.528 3.793 3.793 0 00.035-.514 3.737 3.737 0 00-.112-.91.5.5 0 01.285-.584zm-7.145-.437a.5.5 0 01-.614.206L2.859 7.257a.5.5 0 01-.253-.7 9.038 9.038 0 013.6-3.6.5.5 0 01.7.255L8.4 6.928a.5.5 0 01-.162.583 3.789 3.789 0 00-.953 1.061zM8.25 10.5a2.25 2.25 0 112.25 2.25 2.253 2.253 0 01-2.25-2.25z\"/><path d=\"M20.481 16.96h-9.152a3.52 3.52 0 000 7.04 3.393 3.393 0 002.256-.843 1 1 0 01.659-.247h3.321a1 1 0 01.659.248 3.394 3.394 0 002.255.842 3.52 3.52 0 100-7.04zm-8.22 4.019h-.215a.5.5 0 00-.5.5v.214a.5.5 0 01-1 0v-.213a.5.5 0 00-.5-.5h-.213a.5.5 0 110-1h.214a.5.5 0 00.5-.5v-.215a.5.5 0 011 0v.214a.5.5 0 00.5.5h.214a.5.5 0 010 1zm6.679.251a.75.75 0 11.75-.75.75.75 0 01-.75.75zm1.619 1.619a.75.75 0 11.75-.75.75.75 0 01-.75.75zm0-3.238a.75.75 0 11.75-.75.75.75 0 01-.749.75zm1.618 1.619a.75.75 0 11.75-.75.75.75 0 01-.749.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});