define("ember-svg-jar/inlined/time-clock-file-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-clock-file-search</title><path d=\"M23.363 3.069L20.948.655a1.75 1.75 0 00-1.237-.513H8.125a1.75 1.75 0 00-1.75 1.75v7.326a.247.247 0 00.215.243 6.8 6.8 0 011.01.222.221.221 0 00.273-.225V1.892a.251.251 0 01.25-.25h11.588l.177.074L22.3 4.13a.249.249 0 01.073.177v14.085a.251.251 0 01-.25.25h-9.704a.244.244 0 00-.229.163 7.191 7.191 0 01-.307.721.312.312 0 00.041.337l.21.21a.173.173 0 00.14.069h9.851a1.749 1.749 0 001.75-1.75V4.307a1.753 1.753 0 00-.512-1.238z\"/><path d=\"M10.293 19.647a.243.243 0 01-.031-.3 5.512 5.512 0 10-1.687 1.683.243.243 0 01.3.03l2.505 2.506a1 1 0 101.42-1.414zm-4.668.245a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5zM15.625 13.142a4.75 4.75 0 10-4.75-4.75 4.755 4.755 0 004.75 4.75zm0-8a3.25 3.25 0 11-3.25 3.25 3.254 3.254 0 013.25-3.25z\"/><path d=\"M15.625 9.142h1.768a.75.75 0 100-1.5h-.767a.251.251 0 01-.251-.251v-.766a.75.75 0 00-1.5 0v1.767a.75.75 0 00.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});