define("ember-svg-jar/inlined/diagram-dash-up-steady", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-dash-up-steady</title><path d=\"M9.12 16.594a1.44 1.44 0 002.88 0v-1.92a1.44 1.44 0 00-2.88 0zM10.644 6.516a1.459 1.459 0 00-1.516 1.36 5.362 5.362 0 00-.008.285v1.713a1.44 1.44 0 102.88 0V8.033a1.44 1.44 0 00-1.356-1.517zM7.6 20.954a1.44 1.44 0 00.528 2.78 1.421 1.421 0 00.527-.1 5.275 5.275 0 002.117-1.528 1.439 1.439 0 10-2.209-1.846 2.411 2.411 0 01-.963.694zM15.292 2.881h-.9a5.317 5.317 0 00-1.39.186 1.44 1.44 0 00.378 2.83 1.422 1.422 0 00.379-.051 2.476 2.476 0 01.637-.085h.892a1.44 1.44 0 000-2.88zM3.36 21.12H1.44a1.44 1.44 0 000 2.88h1.92a1.44 1.44 0 100-2.88zM23.578 3.3L20.7.423a1.439 1.439 0 00-2.46 1.018V7.2a1.44 1.44 0 002.46 1.019l2.88-2.88a1.44 1.44 0 00-.002-2.039z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});