define("ember-svg-jar/inlined/science-projection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-projection</title><path d=\"M12 16.75a8 8 0 10-8-8 8.009 8.009 0 008 8zM6.252 9.69a.5.5 0 01.391-.19h.284a.75.75 0 000-1.5h-.284a.5.5 0 01-.486-.614A5.861 5.861 0 016.526 6.3a.5.5 0 01.456-.3h2.19a.75.75 0 000-1.5h-.747a.25.25 0 01-.156-.446 5.989 5.989 0 017.717.215.25.25 0 01-.014.386A.749.749 0 0016.427 6h.591a.5.5 0 01.456.295 5.989 5.989 0 01.37 1.091.5.5 0 01-.488.614h-1.929a.75.75 0 000 1.5h1.929a.5.5 0 01.488.614 5.964 5.964 0 01-.37 1.091.5.5 0 01-.456.295h-3.091a.75.75 0 000 1.5h1.648a.25.25 0 01.156.446 6 6 0 01-9.575-3.332.5.5 0 01.096-.424z\"/><path d=\"M12.427 6h1a.75.75 0 000-1.5h-1a.75.75 0 000 1.5zM10 9.5h2.5a.75.75 0 000-1.5H10a.75.75 0 000 1.5zM8.927 13h2a.75.75 0 000-1.5h-2a.75.75 0 000 1.5z\"/><path d=\"M23.832 11.805a1 1 0 00-1.664-1.11l-4.888 7.332a.5.5 0 01-.417.223H7.136a.5.5 0 01-.416-.223L1.832 10.7a1 1 0 00-1.664 1.11l4.082 6.117a.25.25 0 01-.153.383 2.508 2.508 0 00-1.8 1.562l-.507 1.352a1.5 1.5 0 001.404 2.026h17.612a1.5 1.5 0 001.4-2.027l-.506-1.35a2.511 2.511 0 00-1.8-1.563.25.25 0 01-.153-.383zM8.75 21.75a1 1 0 111-1 1 1 0 01-1 1zm5.25-.5a.5.5 0 01-1 0v-1a.5.5 0 011 0zm2 0a.5.5 0 01-1 0v-1a.5.5 0 011 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});