define("ember-svg-jar/inlined/vectors-pen-draw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vectors-pen-draw</title><path d=\"M23.5 0h-1a.5.5 0 00-.431.247l-4.16 7.072a.5.5 0 00.077.607l3.921 3.921a.5.5 0 00.666.036l1.239-.992A.5.5 0 0024 10.5V.5a.5.5 0 00-.5-.5zM16.868 8.929a.5.5 0 00-.491-.127l-2.9.827a2.484 2.484 0 00-1.717 1.717l-2.272 7.966a.069.069 0 00.116.068l5.63-5.631A.75.75 0 1116.3 14.81l-5.632 5.632a.062.062 0 00-.01.077.064.064 0 00.072.028l7.965-2.276a2.488 2.488 0 001.72-1.726l.778-2.945a.5.5 0 00-.13-.481z\"/><path d=\"M8.3 21.283A3.464 3.464 0 016 22a3.175 3.175 0 01-2.644-1.045c-.636-.965-.441-2.714.564-5.059 2.017-4.709 4.134-8.436 2.8-11.62C5.969 2.5 4.264 1.156 1.351.065a1 1 0 10-.7 1.871C2.978 2.81 4.36 3.828 4.872 5.05c.955 2.277-.9 5.646-2.791 10.059-1.3 3.036-1.434 5.373-.4 6.947A5.057 5.057 0 006 24a5.3 5.3 0 003.7-1.293.991.991 0 00.006-1.395 1.009 1.009 0 00-1.406-.029z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});