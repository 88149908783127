define("ember-svg-jar/inlined/shorts-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shorts-1</title><path d=\"M22.107 2a.5.5 0 00.5-.534L22.57.934a1 1 0 00-1-.934H2.428a1 1 0 00-1 .933l-.036.533a.5.5 0 00.5.534zM24 22.026l-1.225-18.06a.5.5 0 00-.5-.466h-9.021a.25.25 0 00-.24.319l1.707 5.975a.75.75 0 01-1.442.412L12.24 6.571a.25.25 0 00-.48 0l-1.039 3.635a.75.75 0 01-1.442-.412l1.707-5.975a.25.25 0 00-.24-.319H1.724a.5.5 0 00-.5.466L0 22.027a1 1 0 00.9 1.06l8.605.907a1.006 1.006 0 001.08-.765c.074-.316.167-1.111.312-2.381.18-1.581.405-3.549.621-4.463a.519.519 0 01.974 0c.216.915.441 2.884.621 4.466.145 1.269.238 2.063.312 2.38a1 1 0 00.973.77.955.955 0 00.106-.006l8.606-.907a1 1 0 00.89-1.062z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});