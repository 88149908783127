define("ember-svg-jar/inlined/toys-balloon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toys-balloon</title><path d=\"M21.707 22.293l-1.262-1.262a4.722 4.722 0 00-5.1-1.047 2.726 2.726 0 01-2.681-.371.251.251 0 01.127-.447l.465-.049a1 1 0 00.642-1.658l-1.274-1.437a.5.5 0 01.15-.779 7.427 7.427 0 002.612-2.2A8.231 8.231 0 0016.965 7.2 7.77 7.77 0 008.663.039a7.772 7.772 0 00-6.615 8.746 8.227 8.227 0 002.767 5.383 7.429 7.429 0 003.02 1.6.5.5 0 01.311.73L7.2 18.169a1 1 0 00.977 1.486l1.495-.155a.5.5 0 01.459.206l.354.5a4.72 4.72 0 005.6 1.642 2.724 2.724 0 012.942.6l1.262 1.262a1 1 0 001.414-1.414zM11.618 4.007a1.25 1.25 0 11-1.111 1.375 1.25 1.25 0 011.111-1.375zM6.3 4.586a1.249 1.249 0 11-.709 1.619A1.25 1.25 0 016.3 4.586zm3.659 7.636a4.853 4.853 0 01-4.588-1.935.75.75 0 011.249-.822A3.394 3.394 0 009.8 10.73a3.387 3.387 0 002.84-1.9.75.75 0 111.4.541 4.843 4.843 0 01-4.086 2.851z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});