define("ember-svg-jar/inlined/real-estate-sign-board-sale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-sign-board-sale</title><path d=\"M9.711 13.128a.519.519 0 00-.518.517v1.41a.25.25 0 00.25.25h.536a.25.25 0 00.25-.25v-1.41a.518.518 0 00-.518-.517z\"/><path d=\"M23 11.359a2.5 2.5 0 00-2.5-2.5h-.881a.5.5 0 01-.361-.154l-5.689-5.926a2.247 2.247 0 00-3.134 0L4.742 8.705a.5.5 0 01-.361.154H3.5a2.5 2.5 0 00-2.5 2.5v8a2.5 2.5 0 002.5 2.5h17a2.5 2.5 0 002.5-2.5zm-17.485 3.5a2.693 2.693 0 011.393 2.215 1.77 1.77 0 01-1.768 1.768H4a.625.625 0 010-1.25h1.14a.519.519 0 00.518-.518c0-.52-.323-.787-.893-1.215a2.69 2.69 0 01-1.393-2.214 1.77 1.77 0 011.768-1.767h1.143a.625.625 0 110 1.25H5.14a.518.518 0 00-.518.517c0 .52.323.786.893 1.214zm5.964 3.358a.625.625 0 01-1.25 0v-1.412a.25.25 0 00-.25-.25h-.536a.25.25 0 00-.25.25v1.412a.625.625 0 01-1.25 0v-4.572a1.768 1.768 0 013.536 0zm4.572 0a.626.626 0 01-.625.625h-1.143a1.77 1.77 0 01-1.768-1.768V12.5a.625.625 0 011.25 0v4.571a.519.519 0 00.518.518h1.143a.625.625 0 01.625.628zm-8.1-9.358a.25.25 0 01-.181-.423l4.108-4.276a.176.176 0 01.246 0l4.1 4.274a.25.25 0 01-.181.423zM20 15.305a.625.625 0 010 1.25h-1.414a.25.25 0 00-.25.25v.269a.519.519 0 00.518.518H20a.625.625 0 010 1.25h-1.146a1.77 1.77 0 01-1.768-1.768v-3.429a1.77 1.77 0 011.768-1.767H20a.625.625 0 010 1.25h-1.146a.519.519 0 00-.518.517v1.41a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});