define("ember-svg-jar/inlined/keyboard-arrow-top-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-arrow-top-left</title><path d=\"M17.971 15.847L6.48 4.356a.249.249 0 010-.356l2.3-2.3A1 1 0 008.071 0H1a1 1 0 00-1 1v7.069a1 1 0 001.707.707l2.3-2.3a.252.252 0 01.354 0L15.85 17.968a1.5 1.5 0 002.121-2.121z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});