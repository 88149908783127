define("ember-svg-jar/inlined/optimization-rocket-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>optimization-rocket-graph</title><path d=\"M22.366.345a.75.75 0 10-1.06 1.061.25.25 0 010 .353L20.2 2.865a.254.254 0 01-.221.07 1.44 1.44 0 00-.264-.024 1.488 1.488 0 00-1.06.44l-1.711 1.71a.251.251 0 01-.26.059 1.5 1.5 0 00-1.565.352l-1.046 1.045a.247.247 0 00-.073.176v6.387a.241.241 0 00.411.17l.637-.638a.251.251 0 01.211-.07.247.247 0 01.184.125 4.527 4.527 0 01-.739 5.391 4.5 4.5 0 01-6.364 0 .09.09 0 01.06-.158h.4a.75.75 0 00.281-1.445H3.419A.75.75 0 003.7 17.9h.8a.25.25 0 01.227.144 7.441 7.441 0 001.5 2.131 7.518 7.518 0 001.603 1.233.25.25 0 01-.123.467H6.25a1 1 0 000 2h11a1 1 0 000-2h-1.913a.25.25 0 01-.123-.467 7.515 7.515 0 002.336-11 .251.251 0 01.024-.326l1.079-1.079a1.5 1.5 0 00.352-1.564.252.252 0 01.059-.261l1.711-1.71a1.489 1.489 0 00.44-1.061 1.451 1.451 0 00-.024-.265.251.251 0 01.07-.221l1.105-1.1a.251.251 0 01.354 0 .75.75 0 101.06-1.061z\"/><path d=\"M0 12.459a1 1 0 001 1h11a1 1 0 000-2H1a1 1 0 00-1 1z\"/><rect y=\"6.959\" width=\"3\" height=\"3.5\" rx=\".493\" ry=\".493\"/><rect x=\"4.5\" y=\"4.459\" width=\"3\" height=\"6\" rx=\".5\" ry=\".5\"/><rect x=\"9\" y=\"1.459\" width=\"3\" height=\"8.9\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});