define("ember-svg-jar/inlined/multiple-actions-image.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-image</title><path d=\"M14.471 8.4l-.485-.172a1.8 1.8 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 0012 0a2.986 2.986 0 00-1.341.314.25.25 0 00-.1.363 5 5 0 01.841 2.808 6.265 6.265 0 01-.922 3.625.249.249 0 00.122.371 11.4 11.4 0 012.99 1.466.252.252 0 00.15.053h.96a.25.25 0 00.087-.485zM9 11a1.985 1.985 0 011.246-1.849.251.251 0 000-.467L9.471 8.4l-.485-.172a1.8 1.8 0 01-.056-1.174 4.647 4.647 0 001.216-3.569A3.219 3.219 0 007 0a3.219 3.219 0 00-3.145 3.485 4.609 4.609 0 001.192 3.538 1.877 1.877 0 010 1.185L4.53 8.4c-2.007.737-3.457 1.27-3.911 2.177A7.531 7.531 0 000 13.5a.5.5 0 00.5.5h8.25a.25.25 0 00.25-.25zM24 22v-9.5a2 2 0 00-2-2h-9.5a2 2 0 00-2 2V22a2 2 0 002 2H22a2 2 0 002-2zm-11.5-9.25a.249.249 0 01.25-.25h9a.25.25 0 01.25.25v4.4a.25.25 0 01-.458.138l-.267-.4a1.248 1.248 0 00-1.025-.556 1.285 1.285 0 00-1.039.532l-1.537 2.2a.25.25 0 01-.361.052l-.612-.49a1.25 1.25 0 00-1.821.283l-2 3.005a.2.2 0 01-.171.092.205.205 0 01-.205-.206z\"/><circle cx=\"15.5\" cy=\"15\" r=\"1.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});