define("ember-svg-jar/inlined/accessories-watch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accessories-watch</title><path d=\"M14.74 4h-9a.5.5 0 00-.384.179C3.239 6.714 1.74 8.758 1.74 12c0 3.259 1.171 5.124 3.63 7.836a.5.5 0 00.37.164h9a.5.5 0 00.371-.164C17.57 17.124 18.74 15.259 18.74 12c0-3.242-1.5-5.286-3.616-7.821A.5.5 0 0014.74 4zm1 8a5.5 5.5 0 11-5.5-5.5 5.506 5.506 0 015.5 5.5zM21.76 10.5h-1.268a.249.249 0 00-.25.252l.016 2.5a.25.25 0 00.25.248h1.252a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5z\"/><rect x=\"5.24\" y=\"21.5\" width=\"10\" height=\"2.5\" rx=\".5\" ry=\".5\"/><rect x=\"5.24\" width=\"10\" height=\"2.5\" rx=\".5\" ry=\".5\"/><path d=\"M11.793 10.605l-1.211.606-1.51-2.266a1 1 0 10-1.664 1.11l2 3a1 1 0 001.279.34l2-1a1 1 0 00-.894-1.79z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});