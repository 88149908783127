define("ember-svg-jar/inlined/answer-machine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>answer-machine</title><path d=\"M22.25 6.25H12a.249.249 0 00-.25.25V22a.249.249 0 00.25.25h10.25a1.5 1.5 0 001.5-1.5v-13a1.5 1.5 0 00-1.5-1.5zM18 16a1 1 0 111-1 1 1 0 01-1 1zm1 2.75a1 1 0 11-1-1 1 1 0 011 1zm-4.25-7a.522.522 0 01-.542-.5v-2a.522.522 0 01.542-.5h5.959a.523.523 0 01.542.5v2a.523.523 0 01-.542.5zm0 4.25a1 1 0 111-1 1 1 0 01-1 1zm1 2.75a1 1 0 11-1-1 1 1 0 011 1zm5.5-2.75a1 1 0 111-1 1 1 0 01-1 1zm1 2.75a1 1 0 11-1-1 1 1 0 011 1zM2.5 6.25h-.75a1.5 1.5 0 00-1.5 1.5v13a1.5 1.5 0 001.5 1.5h.75a.25.25 0 00.25-.25V6.5a.25.25 0 00-.25-.25zM8.25 3.75h-1v-2.5a1 1 0 00-2 0v2.778a1.99 1.99 0 00-1 1.722v16a2 2 0 002 2h2a2 2 0 002-2v-16a2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});