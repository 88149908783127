define("ember-svg-jar/inlined/arrow-thick-circle-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-circle-left</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm7 13.746a.5.5 0 01-.5.5h-6.25a.25.25 0 00-.25.25v3.789a.5.5 0 01-.854.354L4.86 12.352a.5.5 0 010-.707l6.286-6.286a.5.5 0 01.854.354V9.5a.25.25 0 00.25.25h6.25a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});