define("ember-svg-jar/inlined/astronomy-telescope", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astronomy-telescope</title><path d=\"M10.833 7.9a.566.566 0 00.686.283 3.5 3.5 0 014 1.352.5.5 0 00.637.165l3.2-1.609a.5.5 0 00.187-.732c-.163-.235-2.056-3.965-2.183-4.345a.5.5 0 00-.7-.288l-4.938 2.486a2 2 0 00-.889 2.688z\"/><path d=\"M14.94 12.9a.5.5 0 01-.012-.418 2.5 2.5 0 10-4.6 0 .5.5 0 01-.012.418l-4.777 9.656a1 1 0 001.793.888l3.823-7.722a.25.25 0 01.474.111V23a1 1 0 002 0v-7.167a.25.25 0 01.474-.111l3.823 7.722a1 1 0 001.793-.888zM23.576 6.53L20.433.275a.5.5 0 00-.672-.222l-.446.225a2 2 0 00-.889 2.685l1.8 3.574a2 2 0 002.685.889l.446-.225a.5.5 0 00.219-.671zM8.864 12.244a.5.5 0 00.275-.484 3.21 3.21 0 01-.01-.26 3.485 3.485 0 01.879-2.319.5.5 0 00.071-.558 4.562 4.562 0 01-.308-.681.5.5 0 00-.284-.3.5.5 0 00-.416.016l-4.942 2.489a2 2 0 00-1.086 1.54.253.253 0 01-.136.193l-1.985 1a1 1 0 00.9 1.788l1.985-1a.253.253 0 01.236.006 2 2 0 001.884.048z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});