define("ember-svg-jar/inlined/delivery-truck-packages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>delivery-truck-packages</title><rect x=\"15.5\" y=\"7\" width=\"4\" height=\"3.5\" rx=\".5\" ry=\".5\"/><rect x=\"14\" y=\"11.5\" width=\"8\" height=\"5\" rx=\".5\" ry=\".5\"/><path d=\"M24 1.5a1 1 0 00-1-1H7.25a.512.512 0 00-.438.387 1.081 1.081 0 00.015.762 4.067 4.067 0 00.371.693.248.248 0 01.027.226l-3.25 8.82a.251.251 0 01-.1.121C2.371 12.52 1.5 13.481 1.5 15.315v1.435a.25.25 0 00.25.25H3.2a.249.249 0 00.178-.075 4.722 4.722 0 018.081 2.85.25.25 0 00.249.225H23a1 1 0 001-1 1 1 0 00-1-1h-9.5a1 1 0 01-1-1V4.5a1 1 0 011-1H23a1 1 0 001-1zm-13.5 8.75a.25.25 0 01-.25.25H6.793a.249.249 0 01-.235-.336l2.4-6.5A.252.252 0 019.19 3.5h1.061a.25.25 0 01.249.25zM.734 18a.742.742 0 00-.734.75v1a.25.25 0 00.25.25h1.544a.25.25 0 00.249-.225 4.658 4.658 0 01.377-1.424.249.249 0 00-.229-.351z\"/><circle cx=\"6.75\" cy=\"20.25\" r=\"3.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});