define("ember-svg-jar/inlined/space-explore", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>space-explore</title><path d=\"M8.753 4H9a.5.5 0 01.5.5v.25a.75.75 0 001.5 0V4.5a.5.5 0 01.5-.5h.25a.75.75 0 000-1.5h-.25A.5.5 0 0111 2v-.25a.75.75 0 00-1.5 0V2a.5.5 0 01-.5.5h-.25a.75.75 0 000 1.5zM20.753 11H20.5a.5.5 0 01-.5-.5v-.25a.75.75 0 00-1.5 0v.25a.5.5 0 01-.5.5h-.25a.75.75 0 000 1.5H18a.5.5 0 01.5.5v.25a.75.75 0 001.5 0V13a.5.5 0 01.5-.5h.25a.75.75 0 000-1.5zM23.25 3A2.253 2.253 0 0121 .75a.75.75 0 00-1.5 0A2.253 2.253 0 0117.25 3a.75.75 0 000 1.5 2.253 2.253 0 012.25 2.25.75.75 0 001.5 0 2.253 2.253 0 012.25-2.25.75.75 0 000-1.5zm-2.478.938a3.76 3.76 0 00-.334.334.25.25 0 01-.376 0 3.76 3.76 0 00-.334-.334.25.25 0 010-.376 3.76 3.76 0 00.334-.334.25.25 0 01.376 0 3.76 3.76 0 00.334.334.25.25 0 010 .376zM.564 17.75h11.487a.395.395 0 00.391-.448.748.748 0 00-.878-.64 1.079 1.079 0 01-.205.008 4.684 4.684 0 01-4.654-4.128.763.763 0 00-.781-.661A6.221 6.221 0 00.07 17.175a.5.5 0 00.494.575zM12 18.75H.5a.5.5 0 00-.5.5v4a.75.75 0 00.75.75h3.62a.75.75 0 00.75-.75v-1.12a1.135 1.135 0 012.27 0v1.12a.75.75 0 00.75.75h3.61a.75.75 0 00.75-.75v-4a.5.5 0 00-.5-.5z\"/><path d=\"M11.425 8.863a.751.751 0 00-1.061 0 2.252 2.252 0 00-.47 2.5.5.5 0 01-.1.555l-1.281 1.271a.5.5 0 00-.069.619 3.448 3.448 0 001.861 1.447.5.5 0 00.507-.123l1.1-1.1a.5.5 0 01.555-.105 2.251 2.251 0 002.494-.47.75.75 0 000-1.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});