define("ember-svg-jar/inlined/landmarks-stone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmarks-stone</title><circle cx=\"6.85\" cy=\"12.001\" r=\"3\"/><path d=\"M19.05 10.025l-6.061.606a.755.755 0 00-.51.277.745.745 0 00-.16.551 5.089 5.089 0 01.031.541 5.219 5.219 0 01-.031.537.742.742 0 00.165.563.754.754 0 00.505.269l6.062.607h.1a1 1 0 001-1V11.02a1 1 0 00-1.1-.995zM15.427 21.913l1.156-1.156a1 1 0 000-1.414l-4.169-4.17a.725.725 0 00-.587-.213.748.748 0 00-.546.3 5.437 5.437 0 01-.772.85.752.752 0 00-.1 1.01l3.516 4.688a1 1 0 00.727.4h.073a1 1 0 00.702-.295zM8.7 17.922a.75.75 0 00-.854-.515 5.374 5.374 0 01-1.453.071.736.736 0 00-.624.235.755.755 0 00-.189.642l.828 4.687a1 1 0 00.984.826.981.981 0 00.274-.039l1.66-.474a1 1 0 00.678-1.26zM15.427 2.088a.984.984 0 00-.776-.291 1 1 0 00-.731.4l-3.514 4.684a.752.752 0 00.1 1.01 5.446 5.446 0 01.773.851.741.741 0 00.546.3h.06a.751.751 0 00.53-.219l4.169-4.17a1 1 0 000-1.414zM9.933 1.133a1.006 1.006 0 00-.6-.487L7.671.172a1 1 0 00-1.259.787l-.828 4.687a.76.76 0 00.189.642.769.769 0 00.62.236 5.211 5.211 0 011.456.07.716.716 0 00.138.013.753.753 0 00.713-.525L10 1.905a1 1 0 00-.067-.772z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});