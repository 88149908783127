define("ember-svg-jar/inlined/messages-bubble-square-heart-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-heart-alternate</title><path d=\"M24 3a2 2 0 00-2-2H2a2 2 0 00-2 2v13.552A1.953 1.953 0 002 18.5h2.5V22a1 1 0 001.569.823l6.243-4.323H22a2 2 0 002-2zm-2 13.25a.25.25 0 01-.25.25H12a1 1 0 00-.569.178L6.5 20.091V17.5a1 1 0 00-1-1H2.25a.25.25 0 01-.25-.25V3h19.75a.25.25 0 01.25.25z\"/><path d=\"M11.639 13.346a.5.5 0 00.722 0l3.4-3.55A2.517 2.517 0 1012.2 6.237l-.2.2-.2-.2a2.517 2.517 0 00-3.57 3.552z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});