define("ember-svg-jar/inlined/cloud-monitor-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-monitor-upload</title><path d=\"M13.1 9h.019c5.239 0 6.548 0 7.628-.008A3.438 3.438 0 0023.95 5.5a3.436 3.436 0 00-1.066-2.49 3.563 3.563 0 00-1.919-.96.251.251 0 01-.164-.1A4.683 4.683 0 0017 0a4.612 4.612 0 00-4.4 3.136.252.252 0 01-.19.168 2.956 2.956 0 00-1.306.616 2.84 2.84 0 00-1.053 2.2 2.736 2.736 0 00.785 2A3.426 3.426 0 0013.1 9zm-.73-3.532c.612-.5 1.007.1 1.629-.385a1 1 0 00.389-.733A2.569 2.569 0 0117 2a2.641 2.641 0 012.372 1.443.986.986 0 00.934.557 1.628 1.628 0 011.189.445A1.45 1.45 0 0121.95 5.5a1.557 1.557 0 01-1.4 1.5h-7.434c-.4 0-1.066-.12-1.066-.877a.843.843 0 01.32-.655zM7.55 14.5a1 1 0 001-1v-3.25A.25.25 0 018.8 10h1.25a.5.5 0 00.354-.854l-2.5-2.5a.5.5 0 00-.707 0l-2.5 2.5A.5.5 0 005.05 10H6.3a.25.25 0 01.25.25v3.25a1 1 0 001 1z\"/><path d=\"M.05 18.916a2 2 0 002 2H6.3a.25.25 0 01.25.25v.583A.25.25 0 016.3 22H3.55a1 1 0 000 2h8a1 1 0 000-2H8.8a.25.25 0 01-.25-.25v-.583a.25.25 0 01.25-.25h4.25a2 2 0 002-2V12.5a2 2 0 00-2-2h-1.5a1 1 0 000 2h1.25a.25.25 0 01.25.25v5a.25.25 0 01-.25.25H2.3a.25.25 0 01-.25-.25v-5a.25.25 0 01.25-.25h1.25a1 1 0 000-2h-1.5a2 2 0 00-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});