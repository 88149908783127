define("ember-svg-jar/inlined/style-three-pin-water-scooter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-water-scooter</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM3.881 6.988a.5.5 0 01-.161-.624 8.025 8.025 0 011.735-2.349.5.5 0 01.654-.026l2.985 2.387a.249.249 0 00.406-.2V2.5a.75.75 0 011.5 0v5.161a.5.5 0 00.188.39l1.161.929a.5.5 0 01-.116.85l-1.7.729a2 2 0 01-1.95-.211zM7 15.5a.5.5 0 010-1 2.9 2.9 0 00.369-.027.251.251 0 01.18.046l.548.4a.25.25 0 01-.081.443A3.893 3.893 0 017 15.5zm3.931-1.612a.749.749 0 01-.431 1.363.741.741 0 01-.431-.136L3.21 10.3a.75.75 0 01.863-1.228zM17 15.5a3.513 3.513 0 01-2.35-.83.219.219 0 00-.3 0 3.157 3.157 0 01-1.55.746.5.5 0 01-.2-.98 1.7 1.7 0 001.413-1.065.519.519 0 01.965 0c.212.775 1.196 1.129 2.022 1.129a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});