define("ember-svg-jar/inlined/conversation-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>conversation-sync</title><path d=\"M24 6.259a4.505 4.505 0 00-4.5-4.5h-15a4.505 4.505 0 00-4.5 4.5v8a4.505 4.505 0 004.5 4.5h.269a.25.25 0 01.25.244l.059 2.265a1 1 0 001.682.7l3.365-3.145a.254.254 0 01.171-.067h3.418a.255.255 0 01.167.063l3.53 3.147a1 1 0 001.666-.772l-.057-2.181a.251.251 0 01.25-.257h.23a4.505 4.505 0 004.5-4.5zm-12.4 7.25a2.964 2.964 0 002.805-2 .75.75 0 111.419.485 4.441 4.441 0 01-7.107 1.942.25.25 0 00-.339.013l-.345.344a.746.746 0 01-.53.22.75.75 0 01-.75-.75v-2.5a.75.75 0 01.75-.75H10a.75.75 0 01.53 1.28l-.738.739a.249.249 0 00.026.376 2.934 2.934 0 001.782.601zM14 9.491a.75.75 0 01-.53-1.28l.738-.739a.249.249 0 00-.026-.376A2.95 2.95 0 009.6 8.495a.75.75 0 11-1.421-.485 4.441 4.441 0 017.107-1.942.25.25 0 00.339-.013l.345-.344a.75.75 0 011.28.53v2.5a.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});