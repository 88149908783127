define("ember-svg-jar/inlined/landmark-cologne-cathedral", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-cologne-cathedral</title><path d=\"M.5 8.5h2A.5.5 0 003 8V7a.5.5 0 011 0v1a.5.5 0 00.5.5h2a.5.5 0 00.416-.777L4.577 4.214A.459.459 0 014.96 3.5h.54a1 1 0 000-2H5a.5.5 0 01-.5-.5 1 1 0 00-2 0 .5.5 0 01-.5.5h-.5a1 1 0 000 2h.541a.459.459 0 01.382.714L.084 7.723A.5.5 0 00.5 8.5zM23.743 18.063l-4.5-2.5A.5.5 0 0018.5 16v6a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-8a.5.5 0 00-.165-.372l-5-4.5a.5.5 0 00-.669 0l-5 4.5A.5.5 0 006 14v8a.5.5 0 01-.5.5H5a.5.5 0 01-.5-.5v-8a2 2 0 01.662-1.487l1.673-1.505A.5.5 0 007 10.636V10.5a.5.5 0 00-.5-.5h-6a.5.5 0 00-.5.5v12.75a.75.75 0 00.75.75H9.5a.5.5 0 00.5-.5v-2a1 1 0 011-1h1a1 1 0 011 1v2a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-5a.5.5 0 00-.257-.437zM9.25 17.5a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zm3 0a.75.75 0 01-1.5 0v-3a.75.75 0 011.5 0zm3 0a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zM21.5 21a.5.5 0 01-1 0v-2a.5.5 0 011 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});