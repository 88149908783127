define("ember-svg-jar/inlined/candy-jar-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>candy-jar-1</title><rect x=\"3.5\" width=\"17\" height=\"3.5\" rx=\"1\" ry=\"1\"/><path d=\"M13.221 15.493a.5.5 0 00-.429-.243h-1.581a.5.5 0 00-.429.243l-.75 1.25a.5.5 0 000 .514l.75 1.25a.5.5 0 00.429.243H12.8a.5.5 0 00.429-.243l.749-1.247a.5.5 0 000-.515zM10.779 13.507a.5.5 0 00.429.243h1.584a.5.5 0 00.429-.243l.75-1.25a.5.5 0 000-.514l-.75-1.25a.5.5 0 00-.429-.243h-1.584a.5.5 0 00-.429.243l-.75 1.25a.5.5 0 000 .514zM8.721 12.993a.5.5 0 00-.429-.243H6.708a.5.5 0 00-.429.243l-.75 1.25a.5.5 0 000 .514l.75 1.25a.5.5 0 00.429.243h1.584a.5.5 0 00.429-.243l.75-1.25a.5.5 0 000-.514z\"/><path d=\"M19.106 5.2a1 1 0 00-.6-.2H5.494a.994.994 0 00-.6.2 10.968 10.968 0 001.893 18.7.985.985 0 00.436.1h9.554a.985.985 0 00.436-.1 10.968 10.968 0 001.893-18.7zm.883 9.941l-1.2 2a1.258 1.258 0 01-1.072.606h-1.723a1 1 0 00-.858.485l-.844 1.408a1.257 1.257 0 01-1.072.607h-2.434a1.256 1.256 0 01-1.072-.607l-.844-1.405a1 1 0 00-.858-.485H6.283a1.256 1.256 0 01-1.072-.607l-1.2-2a1.252 1.252 0 010-1.286l1.2-2a1.258 1.258 0 011.072-.606h1.726a1 1 0 00.858-.486l.844-1.408a1.258 1.258 0 011.072-.606h2.434a1.256 1.256 0 011.072.607l.844 1.408a1 1 0 00.858.485h1.726a1.256 1.256 0 011.072.607l1.2 2a1.252 1.252 0 010 1.284z\"/><path d=\"M17.721 12.993a.5.5 0 00-.429-.243h-1.584a.5.5 0 00-.429.243l-.748 1.247a.5.5 0 000 .515l.752 1.252a.5.5 0 00.429.243h1.581a.5.5 0 00.429-.243l.75-1.25a.5.5 0 000-.514z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});