define("ember-svg-jar/inlined/religion-cao-dai-hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-cao-dai-hand</title><rect y=\"14.029\" width=\"4\" height=\"9\" rx=\"1\" ry=\"1\"/><path d=\"M20.363 17.111h-.016l-2 .639a.5.5 0 00-.348.485 2.25 2.25 0 01-2.249 2.29H10.5a.5.5 0 010-1h5.25a1.25 1.25 0 000-2.5l-3.628.008A.259.259 0 0112 17a6.742 6.742 0 01-.5-.35 6.836 6.836 0 00-4-1.621h-2a.5.5 0 00-.5.5v5.9a.5.5 0 00.339.474c.706.239 1.34.46 1.917.66 2.624.911 4.088 1.409 5.418 1.409 1.624 0 3.048-.742 6.139-2.386 1.281-.681 2.876-1.529 4.91-2.546a.5.5 0 00.13-.8 3.243 3.243 0 00-3.49-1.129zM11.117 12.771h12.068a.767.767 0 00.7-1.179L17.8.469a.741.741 0 00-1.356 0l-6.028 11.124a.768.768 0 00.7 1.178zm6-6.5a3.869 3.869 0 013.334 2 3.779 3.779 0 01-6.668 0 3.869 3.869 0 013.334-2z\"/><circle cx=\"17.117\" cy=\"8.271\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});