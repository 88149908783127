define("ember-svg-jar/inlined/style-three-pin-sale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-sale</title><path d=\"M10.5 7a1 1 0 00-1 1v.75a.25.25 0 00.25.25h1.5a.25.25 0 00.25-.25V8a1 1 0 00-1-1z\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM5.761 8.07l1.107 1.083a1.516 1.516 0 01.632 1.259A1.588 1.588 0 015.914 12H5a.5.5 0 110-1h.914a.586.586 0 00.325-1.073L5.132 8.842A1.516 1.516 0 014.5 7.583 1.588 1.588 0 016.086 6H7a.5.5 0 010 1h-.914a.585.585 0 00-.325 1.07zM12.5 11.5a.5.5 0 01-1 0v-1.25a.249.249 0 00-.25-.25h-1.5a.249.249 0 00-.25.25v1.25a.5.5 0 01-1 0V8a2 2 0 014 0zm3.5.5h-1a1.5 1.5 0 01-1.5-1.5v-4a.5.5 0 011 0v4a.5.5 0 00.5.5h1a.5.5 0 010 1zm3-3a.5.5 0 010 1h-1.25a.249.249 0 00-.25.25v.25a.5.5 0 00.5.5h1a.5.5 0 010 1h-1a1.5 1.5 0 01-1.5-1.5v-3A1.5 1.5 0 0118 6h1a.5.5 0 010 1h-1a.5.5 0 00-.5.5v1.25a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});