define("ember-svg-jar/inlined/famous-character-wonder-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character-wonder-woman</title><circle cx=\"9.505\" cy=\"9.002\" r=\".75\"/><circle cx=\"14.505\" cy=\"9.002\" r=\".75\"/><path d=\"M12.889 10.971a1.252 1.252 0 01-1.768 0 .75.75 0 00-1.06 1.061 2.752 2.752 0 003.888 0 .75.75 0 10-1.06-1.061z\"/><path d=\"M21.939 18.4a9.035 9.035 0 00-1.3-.8.262.262 0 01-.131-.223V16.34a5.686 5.686 0 00-.933-3.053A9.5 9.5 0 0118.505 9V6.686a6.733 6.733 0 00-1.241-4.011 6.479 6.479 0 00-5.458-2.67 6.679 6.679 0 00-6.3 6.749V9a9.463 9.463 0 01-1.068 4.286 5.687 5.687 0 00-.932 3.052v1a.276.276 0 01-.129.236 9.052 9.052 0 00-1.314.81A2.728 2.728 0 001 20.55V23a1 1 0 002 0v-2.45a.714.714 0 01.274-.574 9.081 9.081 0 012.554-1.286.267.267 0 01.216.019A2.607 2.607 0 017.505 21a.168.168 0 01-.1.1c-1.688.292-2.9 1.236-2.9 2.4a.5.5 0 00.5.5h14a.5.5 0 00.5-.5c0-1.16-1.208-2.1-2.894-2.4-.041-.007-.106-.074-.106-.1a2.6 2.6 0 011.45-2.287.262.262 0 01.217-.019 9.179 9.179 0 012.554 1.29.712.712 0 01.274.573V23a1 1 0 002 0v-2.441a2.7 2.7 0 00-1.061-2.159zM8.755 5a1.25 1.25 0 011.25-1.25h4A1.251 1.251 0 0115.255 5a.226.226 0 01-.243.25h-1.5a1.24 1.24 0 00-.952.441s-.3.34-.378.445a.233.233 0 01-.36-.015l-.367-.421a1.243 1.243 0 00-.954-.443H8.99A.215.215 0 018.755 5zm6.75 15.76a.247.247 0 01-.243.244 5.417 5.417 0 00-2.323.58c-.063.034-.184-.047-.184-.206V20a.75.75 0 00-1.5 0v1.424a.152.152 0 01-.206.149 5.429 5.429 0 00-2.3-.569.247.247 0 01-.243-.244v-2.505a.252.252 0 01.212-.25 21.538 21.538 0 013.288-.253 21.3 21.3 0 013.288.256.252.252 0 01.212.25zm0-5.028a.244.244 0 01-.278.244 23.367 23.367 0 00-3.222-.226 23.667 23.667 0 00-3.222.223.243.243 0 01-.278-.243v-1.15c0-.212.181-.2.274-.151a6.748 6.748 0 006.459 0c.09-.049.267-.006.267.215zm0-3.438a.261.261 0 01-.091.2 5.252 5.252 0 01-6.819 0 .264.264 0 01-.09-.2V7a.25.25 0 01.25-.25h1.491a.244.244 0 01.221.1l.585.689a1.248 1.248 0 001.905 0s.524-.62.6-.7a.279.279 0 01.2-.082h1.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});