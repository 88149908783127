define("ember-svg-jar/inlined/style-three-pin-arrow-diagonal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-arrow-diagonal</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm-.793 7.793a1 1 0 11-1.414 1.414L7.927 7.341a.25.25 0 00-.427.177V8.5a1 1 0 01-2 0v-4a1 1 0 011-1h4a1 1 0 010 2h-.982a.25.25 0 00-.177.427zM18.5 15.5a1 1 0 01-1 1h-4a1 1 0 010-2h.982a.25.25 0 00.177-.427l-1.866-1.866a1 1 0 011.414-1.414l1.866 1.866a.25.25 0 00.427-.177V11.5a1 1 0 012 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});