define("ember-svg-jar/inlined/toys-racquet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toys-racquet</title><path d=\"M13.691 21.731a5.474 5.474 0 01-3.382 0 1 1 0 10-.618 1.9 7.447 7.447 0 004.618 0 1 1 0 00-.618-1.9zM12 17.25a6.25 6.25 0 001.389-12.344.5.5 0 01-.389-.487V1a1 1 0 00-2 0v3.419a.5.5 0 01-.389.487A6.25 6.25 0 0012 17.25zm0-8.75A2.5 2.5 0 119.5 11 2.5 2.5 0 0112 8.5zM16 17.68a8.14 8.14 0 01-8 0 1 1 0 00-1 1.731 10.009 10.009 0 0010 0 1 1 0 00-1-1.731z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});