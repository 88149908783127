define("ember-svg-jar/inlined/insect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>insect</title><path d=\"M11.43 6.468a.5.5 0 00-.5-.5H6.033C3.18 5.968 0 8.2 0 11.4a3.339 3.339 0 00.9 2.489 4.012 4.012 0 002.3.978 5.7 5.7 0 00-1.822 4.1 1.006 1.006 0 002.011 0c0-2.239 2.236-3.506 2.9-3.923a.506.506 0 01.268-.077H6.8a.5.5 0 01.409.79 5.43 5.43 0 00-1.056 3.21 1.006 1.006 0 002.011 0c0-2.329 2.438-3.6 2.464-3.608l.522-.255a.5.5 0 00.282-.45zM23.983 9.012a1 1 0 00-.5-1.057 1.01 1.01 0 00-1.166.137c-.771.7-2.279 1.876-3.713 1.876a2.033 2.033 0 01-1.733-1.138 1.519 1.519 0 010-1.593c.3-.424 1.172-1.089 3.683-.773a.5.5 0 00.521-.291.5.5 0 00-.128-.582c-3.574-3.109-7.157-.764-8.322.171a.5.5 0 00-.187.39v8.816s.288.212.395.295a4.782 4.782 0 011.616 3.705 1.006 1.006 0 002.011 0 6.967 6.967 0 00-.787-3.269.5.5 0 01.445-.731H18.1c3.524 0 5.349-3.077 5.883-5.956z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});