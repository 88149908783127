define("ember-svg-jar/inlined/legal-scale-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>legal-scale-2</title><path d=\"M9 17H1a1 1 0 00-1 1 5 5 0 0010 0 1 1 0 00-1-1zM23 17h-8a1 1 0 00-1 1 5 5 0 0010 0 1 1 0 00-1-1zM17.6 6.6a.255.255 0 01.046.215l-2.017 8.068a.5.5 0 00.485.622h1.03a.5.5 0 00.485-.379l1.132-4.529a.25.25 0 01.485 0l1.133 4.529a.5.5 0 00.485.379h1.03a.5.5 0 00.485-.622L20.358 6.81a.249.249 0 01.242-.31h1.9a1 1 0 000-2h-9A.5.5 0 0113 4V2a1 1 0 00-2 0v2a.5.5 0 01-.5.5h-9a1 1 0 000 2h1.9a.25.25 0 01.2.095.255.255 0 01.046.215l-2.021 8.068a.5.5 0 00.485.622h1.03a.5.5 0 00.485-.379l1.132-4.529a.25.25 0 01.485 0l1.133 4.529a.5.5 0 00.485.379h1.03a.5.5 0 00.485-.622L6.358 6.81A.249.249 0 016.6 6.5h3.9a.5.5 0 01.5.5v.5a1 1 0 002 0V7a.5.5 0 01.5-.5h3.9a.25.25 0 01.2.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});