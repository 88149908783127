define("ember-svg-jar/inlined/single-neutral-process", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-process</title><path d=\"M14.543 4.707a1 1 0 001.414-1.414l-.616-.616a.25.25 0 010-.354l.616-.616A1 1 0 0014.543.293l-.616.616a.25.25 0 01-.354 0l-.616-.616a1 1 0 00-1.414 1.414l.616.616a.25.25 0 010 .354l-.616.616a1 1 0 001.414 1.414l.616-.616a.25.25 0 01.354 0zM21 8.25a3 3 0 103 3 3 3 0 00-3-3zm0 4a1 1 0 111-1 1 1 0 01-1 1zM20.948 4.665a.255.255 0 01.2-.032.249.249 0 01.157.121l.311.559a.5.5 0 00.437.257h.019a.5.5 0 00.435-.291l1.2-2.614a.5.5 0 00-.353-.7l-3-.62a.5.5 0 00-.538.733l.271.487A.248.248 0 0120 2.9a13.96 13.96 0 00-5.683 6.93 1 1 0 001.872.707 11.953 11.953 0 014.759-5.872zM5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M14.543 4.707a1 1 0 001.414-1.414l-.616-.616a.25.25 0 010-.354l.616-.616A1 1 0 0014.543.293l-.616.616a.25.25 0 01-.354 0l-.616-.616a1 1 0 00-1.414 1.414l.616.616a.25.25 0 010 .354l-.616.616a1 1 0 001.414 1.414l.616-.616a.25.25 0 01.354 0zM21 8.25a3 3 0 103 3 3 3 0 00-3-3zm0 4a1 1 0 111-1 1 1 0 01-1 1zM20.948 4.665a.255.255 0 01.2-.032.249.249 0 01.157.121l.311.559a.5.5 0 00.437.257h.019a.5.5 0 00.435-.291l1.2-2.614a.5.5 0 00-.353-.7l-3-.62a.5.5 0 00-.538.733l.271.487A.248.248 0 0120 2.9a13.96 13.96 0 00-5.683 6.93 1 1 0 001.872.707 11.953 11.953 0 014.759-5.872zM5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});