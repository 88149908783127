define("ember-svg-jar/inlined/pie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pie</title><path d=\"M19.871 20.922a9.057 9.057 0 01-2.2-.632 3.305 3.305 0 00-1.239-.337 3.609 3.609 0 00-1.312.359A7.832 7.832 0 0112 21a7.832 7.832 0 01-3.116-.688 3.609 3.609 0 00-1.312-.359 3.314 3.314 0 00-1.24.337 9.034 9.034 0 01-2.2.632.5.5 0 00-.355.76L4.782 23.3a1.5 1.5 0 001.272.7h11.892a1.5 1.5 0 001.272-.7l1.007-1.613a.5.5 0 00-.354-.76zM23.451 17.9c-1.5-4.656-6.381-7.4-11.451-7.4S2.054 13.243.549 17.9a1 1 0 00.462 1.18A3.924 3.924 0 003 19.5c2.281 0 3.062-1.047 4.572-1.047S9.958 19.5 12 19.5s2.917-1.047 4.428-1.047S18.719 19.5 21 19.5a3.924 3.924 0 001.989-.421 1 1 0 00.462-1.179zM9.624 13.416l-1 1.5a.75.75 0 01-1.248-.832l1-1.5a.75.75 0 011.248.832zM12.75 15a.75.75 0 01-1.5 0v-2a.75.75 0 011.5 0zm3.666.124a.75.75 0 01-1.04-.208l-1-1.5a.75.75 0 111.248-.832l1 1.5a.749.749 0 01-.208 1.04zM5.357 7.394a.345.345 0 01-.064.4 1 1 0 101.414 1.413 2.37 2.37 0 00.15-3.221c-.06-.1-.15-.252-.214-.38a.345.345 0 01.064-.4 1 1 0 00-1.414-1.413 2.37 2.37 0 00-.15 3.221c.057.101.15.252.214.38zM17.143 5.986a2.37 2.37 0 00.15 3.221 1 1 0 001.414-1.414.347.347 0 01-.065-.4c.065-.128.155-.279.215-.38a2.37 2.37 0 00-.15-3.221 1 1 0 00-1.414 1.414.345.345 0 01.064.4c-.064.128-.157.279-.214.38zM11.292 7.293a1 1 0 001.415 1.414c2.109-2.112.846-3.8.092-4.805s-.98-1.307-.092-2.2A1 1 0 0011.293.293c-2.113 2.113-.85 3.8-.095 4.808s.979 1.307.094 2.192z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});