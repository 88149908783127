define("ember-svg-jar/inlined/multiple-actions-graduate.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-graduate</title><path d=\"M13.5 13.527A.5.5 0 0014 14h.49a.492.492 0 00.2-.04l2.029-.87a2.006 2.006 0 011.576 0l1.828.784a.276.276 0 00.377-.274 6.013 6.013 0 00-8.476-5.067.5.5 0 00-.181.771A7.42 7.42 0 0113.5 13.5z\"/><ellipse cx=\"14.013\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M11.623 14a.376.376 0 00.377-.4 6.025 6.025 0 00-12-.267.6.6 0 00.6.667z\"/><ellipse cx=\"5.513\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M23.684 17.111l-6-2.383a.5.5 0 00-.369 0l-6 2.383a.5.5 0 00-.315.474v3.38a.75.75 0 101.5 0v-2.153a.251.251 0 01.333-.236l4.5 1.589a.532.532 0 00.167.028.521.521 0 00.166-.028l6-2.119a.5.5 0 00.018-.935z\"/><path d=\"M21.1 22.917a.5.5 0 00.334-.471V20.3a.5.5 0 00-.667-.471l-3.179 1.122a.249.249 0 01-.166 0l-3.179-1.122a.5.5 0 00-.667.471v2.146a.5.5 0 00.334.471l2.572.908a3.075 3.075 0 002.045 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});