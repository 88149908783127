define("ember-svg-jar/inlined/car-insurance-hands", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-insurance-hands</title><path d=\"M17.072 13.361a1.734 1.734 0 001.728-1.732v-1.555a.246.246 0 01.186-.239 1.729 1.729 0 001.3-1.67V6.928a2.8 2.8 0 00-.639-1.743.247.247 0 01.063-.37l.838-.5a.99.99 0 00-1.019-1.7l-.846.508a.245.245 0 01-.235.01.249.249 0 01-.138-.192l-.087-.7-.064-.511A1.982 1.982 0 0016.2 0H8.049a1.982 1.982 0 00-1.964 1.734l-.151 1.207a.248.248 0 01-.373.182l-.845-.508A.99.99 0 003.7 4.313l.837.5a.248.248 0 01.062.371 2.7 2.7 0 00-.637 1.742v1.239A1.734 1.734 0 005.691 9.9a.248.248 0 01.247.247v1.485a1.732 1.732 0 103.464 0v-1.488a.248.248 0 01.247-.244h5.444a.247.247 0 01.247.247v1.485a1.734 1.734 0 001.732 1.729zM8.051 4.206a.249.249 0 01-.186-.083.252.252 0 01-.06-.2L8.022 2.2a.247.247 0 01.245-.217h7.713a.248.248 0 01.246.217l.216 1.732a.246.246 0 01-.245.278zM7.748 18.014l-1.861-1.9a1.348 1.348 0 00-2.135 1.609l1.32 2.193a.5.5 0 11-.848.511L2.885 18.2a2.439 2.439 0 01.163-2.5.249.249 0 00.045-.142v-2.939a1.485 1.485 0 10-2.969 0v5.118a4.453 4.453 0 00.976 2.782l2.636 3.3a.493.493 0 00.386.181h3.919a.5.5 0 00.495-.5v-2.976a4.455 4.455 0 00-.788-2.51zM22.392 11.134a1.485 1.485 0 00-1.485 1.485v2.938a.249.249 0 00.045.142 2.439 2.439 0 01.163 2.5l-1.339 2.231a.5.5 0 11-.848-.511l1.32-2.193a1.348 1.348 0 00-2.135-1.609l-1.861 1.9a4.455 4.455 0 00-.788 2.51v2.981a.5.5 0 00.495.5h3.919a.493.493 0 00.386-.186l2.636-3.3a4.453 4.453 0 00.976-2.782v-5.121a1.484 1.484 0 00-1.484-1.485z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});