define("ember-svg-jar/inlined/road-sign-lane-crossing-left-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-lane-crossing-left-alternate</title><path d=\"M23.53 11.47l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0l11-11a.749.749 0 000-1.06zM12.177 21.763a.252.252 0 01-.354 0l-9.586-9.586a.252.252 0 010-.354l9.586-9.586a.252.252 0 01.354 0l9.586 9.586a.252.252 0 010 .354z\"/><path d=\"M9.47 6.47l-1.5 1.5a.75.75 0 00.53 1.28h.25A.25.25 0 019 9.5v1.293a1.494 1.494 0 00.439 1.061l3.488 3.487a.249.249 0 01.073.177v1.732a1 1 0 002 0v-2.043a1.494 1.494 0 00-.439-1.061l-3.488-3.487a.249.249 0 01-.073-.177V9.5a.25.25 0 01.25-.25h.25a.75.75 0 00.53-1.28l-1.5-1.5a.749.749 0 00-1.06 0z\"/><path d=\"M14 11.5a1 1 0 001-1V7a1 1 0 00-2 0v3.5a1 1 0 001 1zM10 14a1 1 0 00-1 1v2.5a1 1 0 002 0V15a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});