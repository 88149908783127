define("ember-svg-jar/inlined/smart-watch-circle-music", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-music</title><path d=\"M12 3.5a8.5 8.5 0 108.5 8.5A8.5 8.5 0 0012 3.5zm3.75 9.363a1.75 1.75 0 11-1.75-1.75.252.252 0 00.177-.074.248.248 0 00.073-.176V8.5a.251.251 0 00-.329-.238l-3.3.849a.252.252 0 00-.12.222v5.03a1.75 1.75 0 11-1.75-1.75.252.252 0 00.177-.074.248.248 0 00.072-.176v-3.03a1.747 1.747 0 011.2-1.66l3.3-.849a1.727 1.727 0 011.526.256 1.753 1.753 0 01.724 1.42zM7.379 3.051a.125.125 0 00.135.014 9.98 9.98 0 018.972 0 .125.125 0 00.177-.144l-.68-2.55A.5.5 0 0015.5 0h-7a.5.5 0 00-.483.371l-.68 2.551a.125.125 0 00.042.129z\"/><path d=\"M16.621 20.949a.125.125 0 00-.135-.015 9.972 9.972 0 01-8.972 0 .125.125 0 00-.177.144l.68 2.551A.5.5 0 008.5 24h7a.5.5 0 00.483-.371l.68-2.551a.125.125 0 00-.042-.129z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});