define("ember-svg-jar/inlined/saving-bank", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>saving-bank</title><path d=\"M9.5 11.5a1 1 0 000-2h-6a1 1 0 000 2h.75a.25.25 0 01.25.25v5a.25.25 0 01-.25.25H3.5a1 1 0 000 2h6a1 1 0 000-2h-.75a.25.25 0 01-.25-.25v-5a.25.25 0 01.25-.25zM20.5 11.5a1 1 0 000-2h-6a1 1 0 000 2h.75a.25.25 0 01.25.25v5a.25.25 0 01-.25.25h-.75a1 1 0 000 2h6a1 1 0 000-2h-.75a.25.25 0 01-.25-.25v-5a.25.25 0 01.25-.25zM1.838 6.07a1 1 0 00.615 1.788h19.2a1 1 0 00.938-.651 1.022 1.022 0 00-.342-1.149L13.168.246a.981.981 0 00-1.211-.068L1.9 6.032a.333.333 0 00-.062.038z\"/><rect x=\"1\" y=\"20.5\" width=\"22\" height=\"3.5\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});