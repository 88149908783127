define("ember-svg-jar/inlined/safety-warning-heat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-warning-heat</title><path d=\"M23.9 22.163L12.81.974a.948.948 0 00-1.619 0L.105 22.159a.891.891 0 00-.1.421.9.9 0 00.9.9H23.1a.9.9 0 00.9-.9.885.885 0 00-.1-.417zM13.037 11.548a.75.75 0 011.225.867c-.512.724-.295 1.137.458 2.135.663.879 1.667 2.209.473 3.869a.749.749 0 11-1.217-.875c.5-.7.288-1.107-.454-2.091-.668-.885-1.676-2.222-.485-3.905zm-4 0a.75.75 0 111.225.867c-.512.724-.295 1.137.458 2.135.663.879 1.667 2.209.473 3.869a.749.749 0 11-1.217-.875c.5-.7.288-1.107-.454-2.091-.668-.885-1.676-2.222-.485-3.905zm8.463 9.683h-11a.75.75 0 010-1.5h11a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});