define("ember-svg-jar/inlined/social-video-meta-cafe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-video-meta-cafe</title><path d=\"M23.23 16.85a.5.5 0 00-.47-.34h-3.42l-1.05-3.14a.52.52 0 00-.95 0l-1.06 3.13h-3.41a.5.5 0 00-.3.9l2.77 2.09-1.06 3.24a.5.5 0 00.77.56l2.76-2 2.76 2a.5.5 0 00.77-.56l-1.06-3.24 2.77-2.09a.5.5 0 00.18-.55z\"/><path d=\"M14.05 20.2a.5.5 0 00-.17-.55l-4.3-3.25a.5.5 0 01.3-.9h5.32a.5.5 0 00.47-.34l1.66-5a.52.52 0 01.95 0l1.66 5a.5.5 0 00.47.34h1.72a.5.5 0 00.48-.65L18.31 1a.5.5 0 00-.48-.35.5.5 0 00-.48.35l-3.8 12.11H1.25A.5.5 0 001 14l11.78 8.11a.5.5 0 00.76-.26z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});