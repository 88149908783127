define("ember-svg-jar/inlined/credit-card-amex-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-amex</title><path d=\"M21.913 3.5H2.087A2.051 2.051 0 000 5.5v13a2.051 2.051 0 002.087 2h19.826a2.05 2.05 0 002.087-2v-13a2.05 2.05 0 00-2.087-2zM6.025 15a.625.625 0 01-1.25 0v-1.525a.25.25 0 00-.25-.25h-.65a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0v-4.8a1.825 1.825 0 013.65 0zm6 0a.625.625 0 01-1.25 0v-2.842a.25.25 0 00-.464-.129l-.175.292a.625.625 0 01-1.073 0l-.174-.29a.25.25 0 00-.464.128V15a.625.625 0 01-1.25 0V9a.626.626 0 01.458-.6.633.633 0 01.7.282l1.049 1.749a.251.251 0 00.429 0l1.05-1.749A.625.625 0 0112.025 9zm4.175-3.025a.625.625 0 010 1.25h-1.525a.25.25 0 00-.25.25v.325a.576.576 0 00.575.575h1.2a.625.625 0 010 1.25H15a1.826 1.826 0 01-1.825-1.825v-3.6A1.826 1.826 0 0115 8.375h1.2a.625.625 0 010 1.25H15a.576.576 0 00-.575.575v1.525a.25.25 0 00.25.25zm5.38 2.793a.624.624 0 01-.348.812.633.633 0 01-.232.045.624.624 0 01-.58-.393l-.388-.969a.249.249 0 00-.464 0l-.388.969a.624.624 0 01-.58.393.635.635 0 01-.233-.045.625.625 0 01-.347-.812l1.07-2.675a.259.259 0 000-.186l-1.07-2.675a.625.625 0 111.16-.464l.388.969a.249.249 0 00.464 0l.388-.969a.625.625 0 011.16.464l-1.07 2.675a.249.249 0 000 .186z\"/><path d=\"M4.2 9.625a.576.576 0 00-.575.575v1.525a.25.25 0 00.25.25h.65a.25.25 0 00.25-.25V10.2a.575.575 0 00-.575-.575z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});