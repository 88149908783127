define("ember-svg-jar/inlined/hierarchy-8", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hierarchy-8</title><path d=\"M20.5 15.5h-.038a.5.5 0 01-.451-.247L15.154 7a.5.5 0 01-.018-.476A3.41 3.41 0 0015.5 5a3.5 3.5 0 00-7 0 3.41 3.41 0 00.364 1.52.5.5 0 01-.018.48l-4.858 8.253a.5.5 0 01-.45.247H3.5a3.5 3.5 0 103.137 5.03.5.5 0 01.449-.28h9.828a.5.5 0 01.449.28A3.5 3.5 0 1020.5 15.5zM7.085 17.749a.5.5 0 01-.448-.277 3.37 3.37 0 00-.357-.572.5.5 0 01-.034-.558l4.525-7.693a.5.5 0 01.541-.235 2.962 2.962 0 001.376 0 .5.5 0 01.541.235l4.524 7.691a.5.5 0 01-.034.558 3.48 3.48 0 00-.356.569.5.5 0 01-.448.278z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});