define("ember-svg-jar/inlined/messages-people-user-bubble-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-user-bubble-circle</title><path d=\"M15.5 0C10.813 0 7 3.141 7 7c0 3.412 3.006 6.3 7 6.885V16.5a1 1 0 001.707.707l4.249-4.249A6.863 6.863 0 0024 7c0-3.859-3.813-7-8.5-7zm3.43 11.234a1.007 1.007 0 00-.272.194L16 14.086V12.98a1 1 0 00-.947-1C11.659 11.8 9 9.614 9 7c0-2.757 2.916-5 6.5-5S22 4.243 22 7a4.868 4.868 0 01-3.07 4.234z\"/><path d=\"M19 4.745h-7a.75.75 0 000 1.5h7a.75.75 0 000-1.5zM17 7.745h-5a.75.75 0 000 1.5h5a.75.75 0 100-1.5zM3.505 13a2.75 2.75 0 10-.01 0A3.5 3.5 0 000 16.5v2a.5.5 0 00.5.5h.821a.25.25 0 01.249.225L2 23.55a.5.5 0 00.5.45h2a.5.5 0 00.5-.45l.432-4.325A.25.25 0 015.679 19H6.5a.5.5 0 00.5-.5v-2A3.5 3.5 0 003.505 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});