define("ember-svg-jar/inlined/headphones-human-music", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>headphones-human-music</title><path d=\"M15.644 11.25a.251.251 0 00-.287.266c.012.159.018.321.018.484a1 1 0 00.331.744 4.879 4.879 0 011.4 1.923.251.251 0 01-.236.333h-.492a1 1 0 00-1 1c0 1.351-.107 2.358-.632 2.879a3.312 3.312 0 01-2.325.621h-.039a1 1 0 00-1 1V23a1 1 0 002 0v-1.325a.251.251 0 01.217-.248 4.3 4.3 0 002.563-1.13 4.687 4.687 0 001.176-3.097.22.22 0 01.218-.2h.091a1.569 1.569 0 001.433-1.08c.422-1.179-.236-2.858-1.651-4.294a.253.253 0 01-.071-.16l-.006-.088a.25.25 0 00-.32-.222 3.2 3.2 0 01-.907.131 3.31 3.31 0 01-.481-.037zM13.987 5.591a.251.251 0 00-.015-.389 8.455 8.455 0 00-3.521-1.555.093.093 0 01-.076-.092V3.5a1.5 1.5 0 00-1.5-1.5h-1a1.5 1.5 0 00-1.5 1.5v.193a.25.25 0 01-.175.237A8.516 8.516 0 00.375 12a8.426 8.426 0 002.914 6.408.247.247 0 01.086.188V23a1 1 0 002 0v-5a1 1 0 00-.4-.8 6.5 6.5 0 011.04-11.036.25.25 0 01.36.225V9a.251.251 0 01-.1.2 3.5 3.5 0 104.2 0 .251.251 0 01-.1-.2V6a.252.252 0 01.1-.2.248.248 0 01.22-.039 6.482 6.482 0 012.085 1.046.248.248 0 00.212.043.251.251 0 00.166-.14 3.27 3.27 0 01.829-1.119zM23.625 2A2 2 0 0021.256.035c-.041.007-3.57 1.14-3.57 1.14a2 2 0 00-1.561 1.951V6.11a.25.25 0 01-.188.242 1.75 1.75 0 102.188 1.694V3.308a.249.249 0 01.175-.238l3-.957a.25.25 0 01.326.238V4.11a.25.25 0 01-.188.242 1.75 1.75 0 102.188 1.694z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});