define("ember-svg-jar/inlined/move-back", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>move-back</title><path d=\"M5.418 16.414a1 1 0 001.707-.707v-3.291a.25.25 0 01.25-.25h13.542a.25.25 0 01.25.25v5.208a1.25 1.25 0 002.5 0v-6.708a1.25 1.25 0 00-1.25-1.25H7.375a.25.25 0 01-.25-.25V6.124a1 1 0 00-1.707-.707L.626 10.209a1 1 0 000 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});