define("ember-svg-jar/inlined/spa-therapist-robe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>spa-therapist-robe</title><path d=\"M7.25.5c0 4.471 2.175 8.25 4.75 8.25S16.75 4.971 16.75.5V.25A.25.25 0 0016.5 0h-9a.25.25 0 00-.25.25z\"/><path d=\"M18.5.32a.191.191 0 00-.169.027.189.189 0 00-.081.153c0 3.959-1.442 7.288-3.568 8.827a.5.5 0 00-.1.71A3.207 3.207 0 0115.25 12v11.5a.5.5 0 00.5.5h4.75a2.5 2.5 0 002.5-2.5v-15A6.506 6.506 0 0018.5.32zM13.75 12A1.752 1.752 0 0012 10.25c-3.505 0-6.25-4.282-6.25-9.75a.189.189 0 00-.078-.153A.191.191 0 005.5.32 6.506 6.506 0 001 6.5v15A2.5 2.5 0 003.5 24h9.75a.5.5 0 00.5-.5zm-2.181 4.464a.5.5 0 01.159.759 3.354 3.354 0 01-1.8 1.227 3.548 3.548 0 01-2.379-.527.5.5 0 00-.508 0 3.548 3.548 0 01-2.379.527 3.358 3.358 0 01-1.8-1.227.5.5 0 01.159-.759 3.351 3.351 0 012.143-.4 2.979 2.979 0 011.426.823 2.975 2.975 0 01-.512-1.565 3.357 3.357 0 01.829-2.014.5.5 0 01.776 0 3.357 3.357 0 01.829 2.014A2.978 2.978 0 018 16.891a2.981 2.981 0 011.426-.823 3.351 3.351 0 012.143.396z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});