define("ember-svg-jar/inlined/wild-bird-flock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wild-bird-flock</title><path d=\"M3.433 9.971A4.523 4.523 0 015.414 6.8c-.6-1.566-1.347-1.759-1.662-1.759a.5.5 0 00-.353.147 7.509 7.509 0 00-1.621 2.7 3.725 3.725 0 00.043 2.313 3.732 3.732 0 011.612-.23z\"/><path d=\"M10.628 12.055a6.741 6.741 0 01-2.533 0 7.663 7.663 0 002.652-5.462.5.5 0 00-.186-.44.514.514 0 00-.47-.084 20.881 20.881 0 00-4.064 1.523C4.733 8.234 4.372 10 4.279 11.161a3.329 3.329 0 00-3 .437 4.526 4.526 0 00-1.2 1.654.75.75 0 001.342.67 5.241 5.241 0 01.289-.556 5.923 5.923 0 004.66 2.41 5.6 5.6 0 004.814-2.988.5.5 0 00-.556-.733zM16.183 4.933a4.526 4.526 0 011.981-3.174C17.567.193 16.817 0 16.5 0a.5.5 0 00-.354.146 7.55 7.55 0 00-1.621 2.7 3.74 3.74 0 00.043 2.313 3.735 3.735 0 011.615-.226z\"/><path d=\"M13.166 9.22a.754.754 0 001.006-.336 5.241 5.241 0 01.289-.556 5.923 5.923 0 004.659 2.41 5.6 5.6 0 004.815-2.988.5.5 0 00-.556-.733 6.741 6.741 0 01-2.533 0A7.663 7.663 0 0023.5 1.551a.5.5 0 00-.186-.44.514.514 0 00-.47-.084 20.91 20.91 0 00-4.065 1.527c-1.294.642-1.654 2.409-1.748 3.569a3.324 3.324 0 00-3 .437 4.521 4.521 0 00-1.2 1.654.75.75 0 00.335 1.006zM16.149 13.408a7.55 7.55 0 00-1.621 2.7 3.74 3.74 0 00.043 2.313 3.732 3.732 0 011.612-.223 4.522 4.522 0 011.981-3.173c-.6-1.566-1.347-1.759-1.661-1.759a.5.5 0 00-.354.142z\"/><path d=\"M23.379 20.278a6.72 6.72 0 01-2.533 0 7.66 7.66 0 002.654-5.465.5.5 0 00-.186-.44.511.511 0 00-.47-.084 20.962 20.962 0 00-4.065 1.526c-1.294.643-1.654 2.41-1.748 3.57a3.329 3.329 0 00-3 .436 4.515 4.515 0 00-1.2 1.655.75.75 0 001.342.67 5.5 5.5 0 01.289-.557A5.921 5.921 0 0019.12 24a5.6 5.6 0 004.815-2.988.5.5 0 00-.556-.734z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});