define("ember-svg-jar/inlined/style-three-pin-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-badge</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM8.223 5.084a.506.506 0 01.4-.067.5.5 0 01.319.259A1.361 1.361 0 0010 6c.554 0 1.306-.86 1.584-1.277a.5.5 0 01.832 0C12.7 5.139 13.45 6 14 6a1.364 1.364 0 001.055-.727.5.5 0 01.722-.189 4.29 4.29 0 011.555 2.285.5.5 0 01-.482.631h-9.7a.5.5 0 01-.482-.632 4.29 4.29 0 011.555-2.284zm3.921 11.9a.5.5 0 01-.288 0c-.218-.07-5.356-1.656-5.356-5.484V10a.5.5 0 01.5-.5h10a.5.5 0 01.5.5v1.5c0 3.828-5.138 5.414-5.356 5.479z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});