define("ember-svg-jar/inlined/pin-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin-3</title><circle cx=\"12\" cy=\"6.875\" r=\"5\"/><path d=\"M12 13.125a1.25 1.25 0 00-1.25 1.25v6.5a1.25 1.25 0 002.5 0v-6.5a1.25 1.25 0 00-1.25-1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});