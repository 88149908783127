define("ember-svg-jar/inlined/phone-charger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-charger</title><path d=\"M22.75 13a1 1 0 00-1-1H21a6.257 6.257 0 00-6.25 6.25V19a3 3 0 01-6 0v-1.767a.251.251 0 01.214-.248A1.5 1.5 0 0010.25 15.5v-1.74a.25.25 0 01.161-.234A5.78 5.78 0 0014.25 8V5a1.5 1.5 0 00-1.5-1.5H12a.25.25 0 01-.25-.25V1a1 1 0 10-2 0v2.25a.25.25 0 01-.25.25H6a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v2.25a.25.25 0 01-.25.25h-.75A1.5 1.5 0 001.25 5v3a5.78 5.78 0 003.839 5.525.25.25 0 01.161.234V15.5a1.5 1.5 0 001.286 1.484.25.25 0 01.214.248V19a5 5 0 0010 0v-.75A4.255 4.255 0 0121 14h.75a1 1 0 001-1zM10.5 7a.75.75 0 01-.75.75h-4a.75.75 0 010-1.5h4a.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});