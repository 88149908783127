define("ember-svg-jar/inlined/crafts-pottery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crafts-pottery</title><path d=\"M4.124 15.836a1.4 1.4 0 00-.458 1.823L5 19.876a.5.5 0 01-.857.516l-1.352-2.25a2.472 2.472 0 01.125-2.485.5.5 0 00.084-.276V12.5a1.5 1.5 0 00-3 0v5.171a4.5 4.5 0 00.986 2.812l2.664 3.329a.5.5 0 00.39.188H8a.5.5 0 00.5-.5v-3.01a4.492 4.492 0 00-.757-2.5c-1.887-1.965-2.477-2.916-3.619-2.154zM19.876 15.836a1.4 1.4 0 01.458 1.823L19 19.876a.5.5 0 10.857.516l1.352-2.25a2.472 2.472 0 00-.125-2.485.5.5 0 01-.084-.276V12.5a1.5 1.5 0 013 0v5.171a4.5 4.5 0 01-.986 2.812l-2.664 3.329a.5.5 0 01-.39.188H16a.5.5 0 01-.5-.5v-3.01a4.492 4.492 0 01.757-2.5c1.887-1.965 2.477-2.916 3.619-2.154zM17.845 5.889a.878.878 0 01-.1-.814 6.6 6.6 0 00.408-1.825C18.156 1.116 15.059 0 12 0S5.844 1.116 5.844 3.25a6.6 6.6 0 00.408 1.825.881.881 0 01-.1.814 7.094 7.094 0 1011.689 0zM12 4.5c-2.819 0-4.156-1-4.156-1.25S9.181 2 12 2s4.156 1 4.156 1.25S14.819 4.5 12 4.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});