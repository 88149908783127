define("ember-svg-jar/inlined/professional-tool-dropbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professional-tool-dropbox</title><path d=\"M23.57 13.32a1 1 0 000-1.61l-3-2.32a.5.5 0 010-.79l3-2.32a1 1 0 00-.09-1.64L17 .65a1 1 0 00-1.14.06l-3.55 2.78a.5.5 0 01-.61 0L8.11.71A1 1 0 007 .65l-6.5 4a1 1 0 00-.11 1.64l3 2.32a.5.5 0 010 .79l-3 2.32a1 1 0 000 1.61l3 2.09a.1.1 0 010 .08v3a1 1 0 00.53.88l7.5 4a1 1 0 00.94 0l7.5-4a1 1 0 00.53-.88v-3a.1.1 0 010-.08zM16.29 2.93a.5.5 0 01.57 0l3.77 2.32a.5.5 0 010 .82l-2 1.53a.5.5 0 01-.57 0l-3.69-2.32a.5.5 0 010-.82zm-.39 5.64a.5.5 0 010 .85l-3.64 2.24a.5.5 0 01-.52 0L8.1 9.43a.5.5 0 010-.85l3.64-2.24a.5.5 0 01.52 0zM3.32 6a.5.5 0 010-.82L7.14 2.9a.5.5 0 01.57 0l2 1.53a.5.5 0 010 .82L5.86 7.6a.5.5 0 01-.57 0zm-.07 6.8a.5.5 0 010-.81l2.07-1.61a.5.5 0 01.57 0l3.9 2.4a.5.5 0 01.07.8l-2.14 1.89a.5.5 0 01-.62 0zm15.25 4.8a.43.43 0 01-.23.38l-6 3.22a.5.5 0 01-.47 0L5.73 18a.43.43 0 11.44-.73l.76.53a1 1 0 001.23-.08l3.5-3.11a.5.5 0 01.66 0l3.5 3.11a1 1 0 001.23.08l.76-.53a.43.43 0 01.67.35zm-1.6-2.14a.5.5 0 01-.62 0l-2.11-1.88a.5.5 0 01.07-.8l3.9-2.4a.5.5 0 01.57 0L20.77 12a.5.5 0 010 .81z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});