define("ember-svg-jar/inlined/picture-polaroid-album-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>picture-polaroid-album-1</title><path d=\"M21 24H3a3 3 0 01-3-3V3a3 3 0 013-3h18a3 3 0 013 3v18a3 3 0 01-3 3zM3 2a1 1 0 00-1 1v18a1 1 0 001 1h18a1 1 0 001-1V3a1 1 0 00-1-1z\"/><rect x=\"4\" y=\"4\" width=\"4\" height=\"4\" rx=\".5\" ry=\".5\"/><rect x=\"10\" y=\"4\" width=\"4\" height=\"4\" rx=\".5\" ry=\".5\"/><rect x=\"16\" y=\"4\" width=\"4\" height=\"4\" rx=\".5\" ry=\".5\"/><rect x=\"4\" y=\"10\" width=\"4\" height=\"4\" rx=\".5\" ry=\".5\"/><rect x=\"10\" y=\"10\" width=\"4\" height=\"4\" rx=\".5\" ry=\".5\"/><rect x=\"16\" y=\"10\" width=\"4\" height=\"4\" rx=\".5\" ry=\".5\"/><rect x=\"4\" y=\"16\" width=\"4\" height=\"4\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});