define("ember-svg-jar/inlined/microphone-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>microphone-1</title><path d=\"M13.5 19h-3a.5.5 0 00-.5.562l.445 3.562a1 1 0 00.992.876h1.118a1 1 0 00.992-.876L14 19.562a.5.5 0 00-.5-.562zM16.25 8.5a.25.25 0 00.25-.25V2.5A2 2 0 0015.176.618 9.824 9.824 0 0012 0a9.824 9.824 0 00-3.176.618A2 2 0 007.5 2.5v5.75a.25.25 0 00.25.25zM13.25 10a.25.25 0 00-.25.25V13a1 1 0 01-2 0v-2.75a.25.25 0 00-.25-.25h-3a.25.25 0 00-.25.25V14a1.928 1.928 0 00.422 1.2L9.3 16.923a1.536 1.536 0 001.2.577h3a1.534 1.534 0 001.2-.578l1.378-1.722A1.928 1.928 0 0016.5 14v-3.75a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});