define("ember-svg-jar/inlined/camera-display-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camera-display-1</title><path d=\"M0 18.625a2.5 2.5 0 002.5 2.5h19a2.5 2.5 0 002.5-2.5v-10a2.5 2.5 0 00-2.5-2.5h-5.319a1.5 1.5 0 01-1.257-.681l-.771-1.183a2.491 2.491 0 00-2.094-1.136H9.134a2.493 2.493 0 00-2.086 1.122l-.8 1.205A1.5 1.5 0 015 6.125H2.5a2.5 2.5 0 00-2.5 2.5zm17.75-5.5h.75a.5.5 0 00.5-.5v-.75a.75.75 0 011.5 0v.75a.5.5 0 00.5.5h.75a.75.75 0 010 1.5H21a.5.5 0 00-.5.5v.734a.75.75 0 01-1.5 0v-.734a.5.5 0 00-.5-.5h-.75a.75.75 0 010-1.5zM6.5 9.625a.5.5 0 01.5-.5h8a.5.5 0 01.5.5v8a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5zm-4 7.5a1 1 0 111 1 1 1 0 01-1-1zm0-3.5a1 1 0 111 1 1 1 0 01-1-1zm0-3.5a1 1 0 111 1 1 1 0 01-1-1zM18 5.109l3 .016a.5.5 0 00.5-.5v-.25a1.5 1.5 0 00-1.5-1.5h-1a1.5 1.5 0 00-1.5 1.5v.234a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});