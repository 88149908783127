define("ember-svg-jar/inlined/shopping-bag-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-bag-tag</title><path d=\"M18.5 9h-2.75a.25.25 0 01-.25-.25V5.5a5.5 5.5 0 00-11 0v3.25a.25.25 0 01-.25.25H1.5A1.5 1.5 0 000 10.5v12A1.5 1.5 0 001.5 24h17a1.5 1.5 0 001.5-1.5v-12A1.5 1.5 0 0018.5 9zm-12 5a.5.5 0 01.5-.5h6a.5.5 0 01.5.5v5a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5zm0-8.5a3.5 3.5 0 017 0v3.25a.25.25 0 01-.25.25h-6.5a.25.25 0 01-.25-.25z\"/><path d=\"M23.484 1.229l-.707-.708A1.789 1.789 0 0020.3.52l-2.534 2.536a1.738 1.738 0 00-.513 1.237V6a.75.75 0 00.75.75h1.708a1.737 1.737 0 001.237-.512L23.484 3.7a1.748 1.748 0 000-2.474z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});