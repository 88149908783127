define("ember-svg-jar/inlined/video-game-friend-ster", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-friend-ster</title><path d=\"M12.41 11.23c-2.51.09-2.47-4.52-.87-5 2.46-.7 2.81 4.93.87 5z\"/><path d=\"M13.91 19.75C4.29 19.75 9.55 10 1 9.62a.5.5 0 01-.48-.5V7.05a.5.5 0 01.48-.5c11.65.26 5.17 9.62 13 10.05 5 .27 5.5-5.52 6-7.73.26-1.06.75-2 2-1.82s1.36 1.19 1.39 2.17c.27 5.37-2.65 10.53-9.48 10.53z\"/><path d=\"M16.63 4.25c2.4.17 2.34 5.88.29 5.81s-2.19-5.95-.29-5.81z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});