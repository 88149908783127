define("ember-svg-jar/inlined/color-brush-paint-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-brush-paint-1-alternate</title><path d=\"M12.95 16.758a2.415 2.415 0 01-.756-4.428.251.251 0 00.118-.178 9.186 9.186 0 01.359-1.91A.182.182 0 0012.5 10a5.51 5.51 0 00-4.831 2.869.25.25 0 01-.22.131H2a2 2 0 00-2 2v7a2 2 0 002 2h11a2 2 0 002-2v-1.45a.248.248 0 01.131-.219 5.513 5.513 0 002.808-4.022.25.25 0 00-.362-.259 6.283 6.283 0 01-4.627.708zM23.807 2A1.317 1.317 0 0021.569.614l-4.481 6.655a.25.25 0 00.159.384 4.023 4.023 0 012.192 1.24.25.25 0 00.4-.044z\"/><path d=\"M17.022 8.9a2.792 2.792 0 00-3.293 2.184c-.066.328-.1.633-.137.914-.1.842-.154 1.1-.676 1.364a1.166 1.166 0 00.292 2.178 5.766 5.766 0 001.176.123 5.228 5.228 0 002.885-.852 4.428 4.428 0 001.939-2.618A2.8 2.8 0 0017.022 8.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});