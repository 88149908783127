define("ember-svg-jar/inlined/office-shelf-corner-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-shelf-corner-1</title><path d=\"M23.359 14.932a1 1 0 10-.719-1.867L18.66 14.6a.249.249 0 00-.16.233v1.608a.25.25 0 00.108.206.255.255 0 00.232.027zM22.64 18.565L18.66 20.1a.249.249 0 00-.16.233v1.608a.25.25 0 00.108.206.255.255 0 00.232.027l4.519-1.738a1 1 0 00-.719-1.867zM.25 19a.25.25 0 00-.25.25v4.25a.5.5 0 00.5.5h16a.5.5 0 00.5-.5v-4.25a.25.25 0 00-.25-.25z\"/><rect y=\"12.998\" width=\"17\" height=\"4.5\" rx=\".25\" ry=\".25\"/><path d=\"M0 7.5v3.75a.25.25 0 00.25.25h16.5a.25.25 0 00.25-.25V9.5A3.505 3.505 0 0013.5 6H6.8a.25.25 0 01-.112-.473l7.264-3.632A1 1 0 0013.053.1L1.205 6.028A1.5 1.5 0 000 7.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});