define("ember-svg-jar/inlined/single-man-actions-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-warning</title><path d=\"M14.653 12.028a.251.251 0 00.014-.2.247.247 0 00-.137-.146l-3.378-1.448a.25.25 0 01-.151-.23V8.87a.251.251 0 01.073-.177 3.3 3.3 0 00.779-2.23.248.248 0 01.089-.177 1.876 1.876 0 00.537-1.4 1.58 1.58 0 00-.316-1.015.248.248 0 01-.034-.265 2.528 2.528 0 00.156-1.726C11.944.492 10.242 0 8.888 0a3.75 3.75 0 00-3.162 1.324.253.253 0 01-.213.118 1.072 1.072 0 00-.9.375c-.371.493-.105 1.171.176 1.732a.251.251 0 01-.045.288 1.2 1.2 0 00-.162.2A1.766 1.766 0 004.32 5a1.524 1.524 0 00.639 1.331.249.249 0 01.117.194A3.158 3.158 0 005.921 8.7.25.25 0 016 8.883v1.122a.251.251 0 01-.152.23l-3.935 1.687C.64 12.468.458 13.626.206 15.23c-.056.359-.117.751-.2 1.179a.5.5 0 00.106.411A.5.5 0 00.5 17h11.393a.251.251 0 00.221-.134zM18.781 12.271a1.449 1.449 0 00-2.561 0l-5.057 9.635a1.429 1.429 0 00.049 1.407 1.455 1.455 0 001.232.687h10.112a1.458 1.458 0 001.233-.687 1.431 1.431 0 00.048-1.408zM17.5 15.25a.75.75 0 01.75.75v2.5a.75.75 0 01-1.5 0V16a.75.75 0 01.75-.75zm-1 6a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});