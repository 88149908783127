define("ember-svg-jar/inlined/notes-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-sync</title><path d=\"M11.75 4.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v4.7a.243.243 0 00.3.238 7.954 7.954 0 011.46-.188.244.244 0 00.24-.243V4.5a2 2 0 00-2-2h-1.25a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-1.5A.25.25 0 016 2.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25H2a2 2 0 00-2 2v13a2 2 0 002 2h7.25a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25h-7a.25.25 0 01-.25-.25V4.75a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25zM22.77 18.6a1 1 0 00-1.256.582 4.046 4.046 0 01-1.278 1.931 4.164 4.164 0 01-5.656-.472.256.256 0 01.014-.349l1.717-1.717a.5.5 0 00-.354-.853H11.5a.5.5 0 00-.5.5v4.458a.5.5 0 00.854.353l.974-.974a.244.244 0 01.353.008 6.085 6.085 0 0010.211-2.2 1 1 0 00-.622-1.267z\"/><path d=\"M23.691 11.772a.5.5 0 00-.545.109l-1.03 1.03a.244.244 0 01-.352-.007 6.1 6.1 0 00-10.2 2.225 1 1 0 001.88.684 4.025 4.025 0 011.275-1.913 4.154 4.154 0 015.637.436.255.255 0 01-.012.35l-1.654 1.652a.5.5 0 00.353.853H23.5a.5.5 0 00.5-.5v-4.457a.5.5 0 00-.309-.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});