define("ember-svg-jar/inlined/style-three-pin-ice-skating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-ice-skating</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM7 5a.5.5 0 01.324-.468l3-1.125a.5.5 0 01.676.468v.411a.252.252 0 01-.138.224l-1.586.79a.5.5 0 00.448.9l.914-.458a.25.25 0 01.362.221 5.054 5.054 0 00.013.538.25.25 0 01-.137.252l-1.6.8a.5.5 0 10.448.894l1.591-.795a.249.249 0 01.285.048 1.98 1.98 0 001.4.581 3 3 0 013 3 1 1 0 01-1 1H9a2 2 0 01-2-2zm9.75 10.25h-10a.75.75 0 010-1.5h10a.75.75 0 00.75-.75.75.75 0 011.5 0 2.252 2.252 0 01-2.25 2.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});