define("ember-svg-jar/inlined/heavy-equipment-flattener", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>heavy-equipment-flattener</title><path d=\"M16.5 17.5a4 4 0 016.516-3.107.25.25 0 00.4-.241A4.508 4.508 0 0019 10.5h-.5V5a1.513 1.513 0 00-.439-1.061A1.494 1.494 0 0017 3.5h-6.536a1.5 1.5 0 00-1.443 1.093L7.42 9.9a.25.25 0 00.021.193.247.247 0 00.152.12A6 6 0 0111.6 18.16a.25.25 0 00.233.34h4.484a.25.25 0 00.246-.294 4 4 0 01-.063-.706zm-5.974-7.607a.438.438 0 01-.421-.565l1.054-3.515a.44.44 0 01.421-.313h4.066a.44.44 0 01.44.439v3.515a.439.439 0 01-.44.439z\"/><path d=\"M23.5 17.5a3 3 0 10-3 3 3 3 0 003-3zm-3 1a1 1 0 111-1 1 1 0 01-1 1zM2.34 18a.219.219 0 00-.187.333 4.5 4.5 0 007.663.05A.25.25 0 009.6 18zM10.5 17a1 1 0 000-2h-.112a4.5 4.5 0 00-8.776 0H1.5a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});