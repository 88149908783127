define("ember-svg-jar/inlined/giraffe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>giraffe</title><path d=\"M22.666 1.3a1 1 0 00-.8-.255c-2.857.362-4.137 3.1-4.606 4.481A4.52 4.52 0 0016.5 5V1.5a1 1 0 00-2 0V4A2.362 2.362 0 0112 6.5 2.362 2.362 0 019.5 4V1.5a1 1 0 00-2 0V5a4.472 4.472 0 00-.758.521C6.273 4.137 4.993 1.4 2.136 1.04a1.016 1.016 0 00-.8.255.993.993 0 00-.324.774C1.19 6.935 3.686 8.114 4.81 8.4a6.966 6.966 0 00-.31 2.1c0 2.5.793 3.293 1.5 4 1 1 2.5 2.5 2.5 4.5s.5 4.5 3.5 4.5 3.5-2.5 3.5-4.5 1.5-3.5 2.5-4.5c.707-.707 1.5-1.5 1.5-4a6.964 6.964 0 00-.311-2.1c1.125-.283 3.621-1.463 3.8-6.33a1 1 0 00-.323-.77zM9.25 12.246A1.25 1.25 0 1110.5 11a1.249 1.249 0 01-1.25 1.246zm5.5 0A1.25 1.25 0 1116 11a1.249 1.249 0 01-1.25 1.246z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});