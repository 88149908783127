define("ember-svg-jar/inlined/outdoors-woodchopping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-woodchopping</title><path d=\"M13.471 9.378a.5.5 0 00.461.323.611.611 0 00.073-.005A5.028 5.028 0 0016.8 8.282a6.715 6.715 0 001.651-3.054.5.5 0 00-.308-.58l-3.277-1.259a.512.512 0 01-.174-.113L12.157.74A.821.821 0 0011 .74L9.546 2.19a.822.822 0 000 1.161l2.536 2.535a.512.512 0 01.113.174zM19.525 24a4.621 4.621 0 00.549-.033 4.5 4.5 0 003.879-3.785 4.429 4.429 0 00-3.5-5.01.744.744 0 00-.579.121.768.768 0 00-.318.514l-.429 3.007a.249.249 0 01-.247.214h-3.057a.761.761 0 00-.552.24.752.752 0 00-.2.56 4.489 4.489 0 001.606 3.14A4.43 4.43 0 0019.525 24zM9.237 23.273l3.846-.533a.5.5 0 00.43-.533 4.385 4.385 0 00-2.928-3.842.5.5 0 00-.631.277l-1.513 3.6a.751.751 0 00.693 1.039.726.726 0 00.103-.008zM17.513 16.938a.752.752 0 00.735-.91l-.825-3.8a.5.5 0 00-.565-.387 4.379 4.379 0 00-3.607 3.212.5.5 0 00.323.608l3.705 1.239a.727.727 0 00.234.038zM9.619 5.372a.247.247 0 00-.353 0L.24 14.4a.821.821 0 000 1.16l.58.58a.82.82 0 001.161 0l9.025-9.025a.249.249 0 000-.353zM15.364 2.4a.25.25 0 00.354 0l.419-.419a.82.82 0 000-1.161l-.58-.58a.821.821 0 00-1.16 0l-.421.421a.251.251 0 000 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});