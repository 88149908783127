define("ember-svg-jar/inlined/blood-drop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>blood-drop</title><path d=\"M12.842.447a1.036 1.036 0 00-1.684 0C11.086.558 4 11.735 4 15.986a8 8 0 0016 0C20 11.736 12.914.558 12.842.447zM12 21.236a5.256 5.256 0 01-5.25-5.25.75.75 0 011.5 0 3.755 3.755 0 003.75 3.75.75.75 0 110 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});