define("ember-svg-jar/inlined/credit-card-monitor-payment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-monitor-payment</title><path d=\"M9 15a3 3 0 013-3h7.233a.249.249 0 00.248-.217 2.117 2.117 0 00.019-.241.113.113 0 000-.018.053.053 0 010-.025V3a3 3 0 00-3-3h-2a1 1 0 000 2h2a1 1 0 011 1v6.472a1 1 0 01-.993 1c-2.951.011-8.71.02-13.512.026a.994.994 0 01-1-1V3A1 1 0 013 2h2.425a1 1 0 000-2H3a3 3 0 00-3 3v8.5a3 3 0 003 3h4.25a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H5a1 1 0 000 2h3.75a.25.25 0 00.25-.25z\"/><path d=\"M8.82 4.9l1.788.716a.409.409 0 01.26.383.413.413 0 01-.413.413H8.693a.75.75 0 000 1.5h.3a.25.25 0 01.25.25v.083a.75.75 0 001.5 0v-.161a.249.249 0 01.188-.242 1.908 1.908 0 00.235-3.622l-1.789-.715a.413.413 0 01.154-.8h1.762a.75.75 0 000-1.5h-.3a.25.25 0 01-.25-.25v-.08a.75.75 0 00-1.5 0v.159a.252.252 0 01-.187.243A1.907 1.907 0 008.82 4.9zM22 13H12a2 2 0 00-2 2v7a2 2 0 002 2h10a2 2 0 002-2v-7a2 2 0 00-2-2zm-.25 2a.25.25 0 01.25.25V16a.25.25 0 01-.25.25h-9.5A.25.25 0 0112 16v-.75a.25.25 0 01.25-.25zM12 19a.25.25 0 01.25-.25h9.5A.25.25 0 0122 19v2.75a.25.25 0 01-.25.25h-9.5a.25.25 0 01-.25-.25z\"/><path d=\"M20.75 20.5a.75.75 0 00-.75-.75h-1.25a.75.75 0 000 1.5H20a.75.75 0 00.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});