define("ember-svg-jar/inlined/messages-people-person-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-person-bubble</title><circle cx=\"12.87\" cy=\"6\" r=\"1\"/><circle cx=\"16.12\" cy=\"6\" r=\"1\"/><circle cx=\"19.37\" cy=\"6\" r=\"1\"/><path d=\"M17.894 0a5.427 5.427 0 00-3.6 1.274 5.552 5.552 0 00-3.871.115 5.466 5.466 0 00-3.1 3.093 1 1 0 101.869.71 3.464 3.464 0 011.975-1.946 3.57 3.57 0 012.9.091 1 1 0 001.133-.191A3.562 3.562 0 0117.894 2 3.91 3.91 0 0122 5.976a3.527 3.527 0 01-.946 2.473 4.125 4.125 0 01-3.06 1.116 1 1 0 00-.813.418A2.288 2.288 0 0115.494 11a1.986 1.986 0 01-1.067-.3 1 1 0 10-1.087 1.678 3.946 3.946 0 002.154.622 4.069 4.069 0 002.992-1.449 5.9 5.9 0 004.008-1.715A5.513 5.513 0 0024 5.976 5.913 5.913 0 0017.894 0z\"/><circle cx=\"15.754\" cy=\"15.75\" r=\"1.5\"/><path d=\"M11.5 17.329l-.63-.232c-.218-.133-.252-1.32-.08-1.608a5.621 5.621 0 001.466-4.315C12.256 8.755 10.676 7 8.5 7s-3.756 1.755-3.756 4.174a5.6 5.6 0 001.452 4.3 2.209 2.209 0 01-.063 1.626l-.633.233c-2.446.9-4.213 1.549-4.751 2.626A9.135 9.135 0 000 23.5a.5.5 0 00.5.5h16a.5.5 0 00.5-.5 9.135 9.135 0 00-.749-3.545c-.538-1.077-2.305-1.726-4.751-2.626z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});