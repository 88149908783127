define("ember-svg-jar/inlined/seafood-sushi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seafood-sushi</title><path d=\"M20.328 17.073a11.493 11.493 0 00-16.655 0 2.905 2.905 0 01-.383.339.5.5 0 00-.189.512C3.52 19.773 5.212 21 7.5 21h9c2.288 0 3.979-1.226 4.4-3.077a.5.5 0 00-.189-.512 3.04 3.04 0 01-.383-.338zM12.383 11.779a.5.5 0 00.4.244 13.012 13.012 0 012.883.5.5.5 0 00.592-.267l2.98-6.327a.5.5 0 00-.156-.616A11.857 11.857 0 0016.4 3.828a.5.5 0 00-.635.253l-3.405 7.23a.5.5 0 00.023.468zM5.507 13.528a.248.248 0 00.291.038 12.79 12.79 0 014.266-1.424.5.5 0 00.379-.282l3.774-8.013a.5.5 0 00-.377-.707 12.177 12.177 0 00-3.352-.046.5.5 0 00-.391.283L5.452 13.24a.249.249 0 00.055.288zM21.089 7.166a.5.5 0 00-.83.114l-2.578 5.471a.5.5 0 00.225.658 13.06 13.06 0 013.508 2.628A1.5 1.5 0 0024 15.008a11.87 11.87 0 00-2.911-7.842zM8.057 3.822a.25.25 0 00-.272-.064A12 12 0 000 15.009a1.5 1.5 0 002.264 1.285.5.5 0 00.2-.217C2.994 14.942 7.041 6.346 8.1 4.1a.251.251 0 00-.043-.278z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});