define("ember-svg-jar/inlined/seo-eye", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seo-eye</title><path d=\"M21.5 10a2 2 0 00-1.791 1.112.251.251 0 01-.224.138h-.81a.25.25 0 01-.247-.213 6.516 6.516 0 00-5.465-5.465.249.249 0 01-.213-.247v-.81a.25.25 0 01.139-.224 2 2 0 10-1.778 0 .25.25 0 01.139.224v.81a.25.25 0 01-.213.247 6.514 6.514 0 00-5.465 5.466.251.251 0 01-.248.212h-.809a.251.251 0 01-.224-.138 2 2 0 100 1.777.25.25 0 01.224-.139h.81a.25.25 0 01.247.213 6.516 6.516 0 005.465 5.466.249.249 0 01.213.247v.809a.25.25 0 01-.139.224 2 2 0 101.778 0 .25.25 0 01-.139-.224v-.809a.25.25 0 01.213-.247 6.514 6.514 0 005.465-5.466.251.251 0 01.248-.213h.809a.25.25 0 01.224.139A2 2 0 1021.5 10zm-3.573 2.261A7.233 7.233 0 0112 15.5a7.233 7.233 0 01-5.927-3.239.5.5 0 010-.521A7.233 7.233 0 0112 8.5a7.233 7.233 0 015.927 3.24.5.5 0 010 .521z\"/><circle cx=\"5\" cy=\"5.5\" r=\"2\"/><circle cx=\"5.5\" cy=\"19\" r=\"2\"/><circle cx=\"19\" cy=\"18.5\" r=\"2\"/><circle cx=\"18.5\" cy=\"5\" r=\"2\"/><circle cx=\"12\" cy=\"12\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});