define("ember-svg-jar/inlined/focus-e", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>focus-e</title><path d=\"M24 2.75A2.752 2.752 0 0021.25 0H2.75A2.752 2.752 0 000 2.75v18.5A2.752 2.752 0 002.75 24h18.5A2.752 2.752 0 0024 21.25zM13 10.5a1.5 1.5 0 010 3h-2a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h4a1.5 1.5 0 010 3h-4A3.5 3.5 0 017.5 16V8A3.5 3.5 0 0111 4.5h4a1.5 1.5 0 010 3h-4a.5.5 0 00-.5.5v2a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});