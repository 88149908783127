define("ember-svg-jar/inlined/single-woman-actions-information.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-information</title><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-.5 2.25a1 1 0 11-1 1 1 1 0 011-1zm2 8.5h-3a.75.75 0 010-1.5h.5a.25.25 0 00.25-.25v-2a.249.249 0 00-.25-.25H16a.75.75 0 010-1.5h1a1.252 1.252 0 011.25 1.25V20a.25.25 0 00.25.25h.5a.75.75 0 010 1.5zM10.654 12.375a7.368 7.368 0 00-3.448-.875 7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h8.515a.5.5 0 00.5-.47 7.93 7.93 0 011.321-3.93.5.5 0 00-.182-.72zM1.568 10.442a1 1 0 001.383-.3 5.162 5.162 0 00.454-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .251.251 0 01.228-.071.255.255 0 01.184.153 5.093 5.093 0 00.454.9 1 1 0 001.68-1.085 4.569 4.569 0 01-.642-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248A4.564 4.564 0 011.27 9.06a1 1 0 00.298 1.382zM7.21 8.6a2.7 2.7 0 01-2.741-2.352.5.5 0 01.324-.577 5.7 5.7 0 002.245-1.439.25.25 0 01.361 0 5.712 5.712 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.21 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});