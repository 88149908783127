define("ember-svg-jar/inlined/single-woman-actions-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-location</title><path d=\"M11.505 18.557a.5.5 0 00.478-.626c-1.965-7.162-1.88-7.546-3.007-8.6a.5.5 0 00-.757.1L6.482 12.16a.5.5 0 01-.837 0L3.923 9.411a.5.5 0 00-.755-.1C2.014 10.373 2.1 10.78.141 17.931a.5.5 0 00.478.626h2.3a.247.247 0 01.243.2l.922 4.85a.5.5 0 00.483.393h2.969a.5.5 0 00.484-.392l.922-4.85a.247.247 0 01.243-.2zM6.041 8.412a4.206 4.206 0 10-4.207-4.206 4.211 4.211 0 004.207 4.206zm0-1.979A2.223 2.223 0 013.9 4.81a.247.247 0 01.158-.3 4.63 4.63 0 001.8-1.128.247.247 0 01.353 0 4.627 4.627 0 001.8 1.128.247.247 0 01.157.3 2.222 2.222 0 01-2.127 1.623zM18.928 11.134a4.954 4.954 0 00-4.949 4.948c0 3.138 4.411 7.586 4.6 7.773a.495.495 0 00.7 0c.188-.187 4.6-4.635 4.6-7.773a4.954 4.954 0 00-4.951-4.948zm0 3.217a1.732 1.732 0 11-1.728 1.731 1.734 1.734 0 011.728-1.731z\"/><path d=\"M11.505 18.557a.5.5 0 00.478-.626c-1.965-7.162-1.88-7.546-3.007-8.6a.5.5 0 00-.757.1L6.482 12.16a.5.5 0 01-.837 0L3.923 9.411a.5.5 0 00-.755-.1C2.014 10.373 2.1 10.78.141 17.931a.5.5 0 00.478.626h2.3a.247.247 0 01.243.2l.922 4.85a.5.5 0 00.483.393h2.969a.5.5 0 00.484-.392l.922-4.85a.247.247 0 01.243-.2zM6.041 8.412a4.206 4.206 0 10-4.207-4.206 4.211 4.211 0 004.207 4.206zm0-1.979A2.223 2.223 0 013.9 4.81a.247.247 0 01.158-.3 4.63 4.63 0 001.8-1.128.247.247 0 01.353 0 4.627 4.627 0 001.8 1.128.247.247 0 01.157.3 2.222 2.222 0 01-2.127 1.623zM18.928 11.134a4.954 4.954 0 00-4.949 4.948c0 3.138 4.411 7.586 4.6 7.773a.495.495 0 00.7 0c.188-.187 4.6-4.635 4.6-7.773a4.954 4.954 0 00-4.951-4.948zm0 3.217a1.732 1.732 0 11-1.728 1.731 1.734 1.734 0 011.728-1.731z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});