define("ember-svg-jar/inlined/ab-testing-chemistry-monitor-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ab-testing-chemistry-monitor-1</title><path d=\"M19.95 11h-2.9a1.5 1.5 0 00-1.5 1.5v3.167a.253.253 0 01-.018.094l-2.362 5.873A1.643 1.643 0 0014.642 24h7.713a1.644 1.644 0 001.476-2.366l-2.363-5.868a.248.248 0 01-.018-.093V12.5a1.5 1.5 0 00-1.5-1.5zm.7 8.39a.249.249 0 01-.207.11h-3.9a.25.25 0 01-.232-.343l1.08-2.688.006-.013a1.507 1.507 0 00.143-.641V13.25a.25.25 0 01.26-.25h1.4a.25.25 0 01.25.25v2.565a1.437 1.437 0 00.117.581l.007.016 1.1 2.745a.248.248 0 01-.021.233z\"/><path d=\"M14 0H2.5A2.5 2.5 0 000 2.5v7.052A2.5 2.5 0 002.5 12h4.25a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H3.625a1 1 0 000 2h9a1 1 0 000-2H9.25a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H14a2.5 2.5 0 002.5-2.448V2.5A2.5 2.5 0 0014 0zm.5 8a.5.5 0 01-.5.5H2.5A.5.5 0 012 8V2.5a.5.5 0 01.5-.5H14a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});