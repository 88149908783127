define("ember-svg-jar/inlined/e-learning-book-smartphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-learning-book-smartphone</title><path d=\"M5 19.756V16.5a.5.5 0 00-.5-.5h-2a.5.5 0 01-.5-.5v-10a.5.5 0 01.5-.5h8a.5.5 0 01.5.5 1 1 0 102 0V4a4 4 0 00-4-4H4a4 4 0 00-4 4v13a4 4 0 004 4h.941a.249.249 0 00.232-.342A2.455 2.455 0 015 19.756z\"/><path d=\"M23.545 7.7a1.554 1.554 0 00-1.089-.429 12.59 12.59 0 00-6.893 1.885 1 1 0 01-1.126 0 12.593 12.593 0 00-6.893-1.882 1.554 1.554 0 00-1.089.426A1.512 1.512 0 006 8.778v11a1.51 1.51 0 001.474 1.494c5.093.125 6.687 2.275 6.7 2.3a1 1 0 001.646 0c.016-.022 1.61-2.172 6.7-2.3A1.51 1.51 0 0024 19.778v-11a1.512 1.512 0 00-.455-1.078zM14 20.049a.5.5 0 01-.716.451 13.329 13.329 0 00-4.821-1.181.5.5 0 01-.463-.5v-8.99a.5.5 0 01.544-.5 8.957 8.957 0 015.288 1.882.5.5 0 01.168.373zm8-1.228a.5.5 0 01-.463.5 13.329 13.329 0 00-4.821 1.179.5.5 0 01-.716-.451v-8.463a.5.5 0 01.168-.373 8.954 8.954 0 015.288-1.882.5.5 0 01.544.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});