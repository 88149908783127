define("ember-svg-jar/inlined/baby-mother", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baby-mother</title><path d=\"M9.435 10.906a1.863 1.863 0 01-2.082 0 .751.751 0 00-.918 1.188 3.253 3.253 0 003.918 0 .751.751 0 00-.918-1.188z\"/><circle cx=\"10.394\" cy=\"8.5\" r=\"1\"/><circle cx=\"6.394\" cy=\"8.5\" r=\"1\"/><path d=\"M14.791 12.167a.5.5 0 00.244-.523 2.252 2.252 0 01.689-2.044.5.5 0 00.161-.372c0-.55 0-1.12.009-1.725a7.5 7.5 0 10-15 .007 23.65 23.65 0 01-.852 7.936 1.008 1.008 0 00.526 1.2A19.46 19.46 0 008.4 18c.847 0 1.666-.035 2.447-.1a.5.5 0 00.454-.442 7.01 7.01 0 013.49-5.291zM13.394 9.5a5 5 0 01-10 0v-2a.5.5 0 01.5-.5 4.992 4.992 0 004.091-2.125.5.5 0 01.818 0A5 5 0 0012.894 7a.5.5 0 01.5.5zM19.465 19.212a.627.627 0 00-.857.22.343.343 0 01-.617 0 .625.625 0 00-1.076.636 1.591 1.591 0 002.769 0 .625.625 0 00-.219-.856z\"/><circle cx=\"16.8\" cy=\"17.25\" r=\"1\"/><circle cx=\"19.8\" cy=\"17.25\" r=\"1\"/><path d=\"M20.249 12.859a.251.251 0 01-.121-.374l.454-.68a1 1 0 00-1.664-1.11l-.24.361a.251.251 0 01-.437-.037l-.078-.175a1 1 0 10-1.827.812l.342.769a.251.251 0 01-.154.34 5.751 5.751 0 103.725.094zM18.25 22A3.75 3.75 0 1122 18.25 3.755 3.755 0 0118.25 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});