define("ember-svg-jar/inlined/phone-actions-pause", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-pause</title><path d=\"M17.919 15.414a2.5 2.5 0 00-3.536 0L14 15.8a46.984 46.984 0 01-5.789-5.791l.386-.384a2.505 2.505 0 000-3.535L6.472 3.967a2.56 2.56 0 00-3.536 0L1.772 5.131a3.507 3.507 0 00-.441 4.4 46.889 46.889 0 0013.141 13.144 3.546 3.546 0 004.4-.442l1.163-1.163a2.5 2.5 0 000-3.535z\"/><rect x=\"15.248\" y=\".757\" width=\"3\" height=\"9\" rx=\"1\" ry=\"1\"/><rect x=\"20.248\" y=\".757\" width=\"3\" height=\"9\" rx=\"1\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});