define("ember-svg-jar/inlined/modern-weapon-die-cast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-weapon-die-cast</title><path d=\"M10.448 20.673A.5.5 0 0010 20.4H1a.5.5 0 00-.447.277l-.5 1A.5.5 0 00.5 22.4h10a.5.5 0 00.448-.723zM10.677 11.427a.5.5 0 00-.707 0l-6.616 6.616a.5.5 0 00.353.853h7a.5.5 0 00.354-.146l3.116-3.116a.5.5 0 000-.707z\"/><path d=\"M20.627 9.309a.186.186 0 01.05-.175.5.5 0 000-.708l-3.5-3.5a.5.5 0 00-.707 0l-4.732 4.733a.5.5 0 000 .707l3.5 3.5a.5.5 0 00.707 0l1.129-1.13a.25.25 0 01.42.235l-1.966 8.192a1 1 0 00.739 1.2.947.947 0 00.234.028 1 1 0 00.972-.766l1.784-7.437a.25.25 0 01.486 0l1.785 7.437a1 1 0 00.971.766.949.949 0 00.235-.028 1 1 0 00.739-1.2zM23.707 4.689L20.914 1.9a1 1 0 00-1.414 0l-1.262 1.259a.5.5 0 000 .707l3.5 3.5a.5.5 0 00.707 0L23.707 6.1a1 1 0 000-1.411z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});