define("ember-svg-jar/inlined/photography-equipment-bag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>photography-equipment-bag</title><path d=\"M15.5 19.5h-7c-1.945 0-4.316-1.465-7.069-4.362A.25.25 0 001 15.31V20a3.5 3.5 0 003.5 3.5h15A3.5 3.5 0 0023 20v-4.69a.25.25 0 00-.431-.172C19.816 18.035 17.445 19.5 15.5 19.5z\"/><path d=\"M24 8a1.5 1.5 0 00-1.5-1.5h-21A1.5 1.5 0 000 8v2.3a2.5 2.5 0 00.567 1.584C2.44 14.168 5.963 18 8.5 18h7c2.537 0 6.06-3.832 7.933-6.116A2.5 2.5 0 0024 10.3zm-4.25 2.75a.75.75 0 01-.75.75H5.25a.75.75 0 010-1.5H19a.75.75 0 01.75.75zM8.326 5.5a.249.249 0 00.244-.2c.021-.1.047-.2.076-.3a3.5 3.5 0 016.708 0c.029.1.055.2.076.3a.249.249 0 00.244.2l1.523.006a.25.25 0 00.248-.286 7.829 7.829 0 00-.036-.221A5.5 5.5 0 006.591 5a5.02 5.02 0 00-.035.214.252.252 0 00.244.286z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});