define("ember-svg-jar/inlined/expand-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>expand-2</title><path d=\"M20.5 5.267a.25.25 0 01.354 0l1.439 1.439A1 1 0 0023 7a.987.987 0 00.383-.076A1 1 0 0024 6V1a1 1 0 00-1-1h-5a1 1 0 00-.707 1.707l1.439 1.439a.25.25 0 010 .354l-6.555 6.556a.252.252 0 01-.354 0L5.268 3.5a.25.25 0 010-.354l1.439-1.44A1 1 0 006 0H1a1 1 0 00-1 1v5a1 1 0 00.617.924A.987.987 0 001 7a1 1 0 00.707-.293l1.439-1.44a.25.25 0 01.354 0l6.556 6.555a.252.252 0 010 .354L3.5 18.731a.25.25 0 01-.354 0l-1.439-1.439A1 1 0 000 18v5a1 1 0 001 1h5a1 1 0 00.707-1.707l-1.439-1.44a.249.249 0 010-.353l6.555-6.556a.252.252 0 01.354 0l6.555 6.556a.249.249 0 010 .353l-1.439 1.44A1 1 0 0018 24h5a1 1 0 001-1v-5a1 1 0 00-1.707-.707l-1.439 1.439a.25.25 0 01-.354 0l-6.556-6.555a.252.252 0 010-.354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});