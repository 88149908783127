define("ember-svg-jar/inlined/csv-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>csv-1</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-15 18.876a.625.625 0 010 1.25 4.625 4.625 0 010-9.25.625.625 0 010 1.25 3.375 3.375 0 000 6.75zm3.3-4.409l1.95.975a2.479 2.479 0 01-1.108 4.7H9.5a.625.625 0 010-1.25h2.146a1.229 1.229 0 00.554-2.332l-1.95-.975a2.479 2.479 0 011.109-4.7H13.5a.625.625 0 010 1.25h-2.146a1.229 1.229 0 00-.55 2.328zm9.321-.668a9.79 9.79 0 01-2.137 6.092.624.624 0 01-.976 0 9.784 9.784 0 01-2.133-6.091v-2.3a.625.625 0 111.25 0v2.3a8.53 8.53 0 001.154 4.275.26.26 0 00.442 0 8.535 8.535 0 001.154-4.275v-2.3a.625.625 0 111.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});