define("ember-svg-jar/inlined/livestock-cow-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>livestock-cow-body</title><path d=\"M19.374 6H12.59a4.253 4.253 0 00-1.739-.907c-.158-.269-.336-.572-.5-.86a6.717 6.717 0 00.273-1.733 1 1 0 00-2 0 3.8 3.8 0 01-.092.75H5.717a3.728 3.728 0 01-.093-.75 1 1 0 10-2 0A7.146 7.146 0 003.9 4.244c-.161.277-.338.58-.5.849A4.219 4.219 0 00.747 7.111a3.762 3.762 0 00-.2 3.668A2.388 2.388 0 002.624 12a3.561 3.561 0 00.385-.021l.49 1.469A3 3 0 006.345 15.5h.529v6a1 1 0 001 1h2.75a1 1 0 00.984-.821l.828-4.55a5.012 5.012 0 001.972 1.492l-.213.639a.751.751 0 00.474.949.758.758 0 00.949-.474l.253-.757s.277.024.578.021l.246.736a.761.761 0 00.949.474.751.751 0 00.474-.949l-.159-.478a3.825 3.825 0 00.72-.3l.719 3.235a1 1 0 00.976.783h2a1 1 0 001-.9c0-.035.475-10.234.475-10.87A4.789 4.789 0 0019.374 6zM5.4 12.815l-.96-2.868a.507.507 0 00-.718-.28 2.318 2.318 0 01-1.1.332c-.933 0-.559-3.048 1.934-3.048h.055c.113-.17.476-.787.735-1.229a1.121 1.121 0 00.146-.046 1 1 0 00.5-.428h2.27a1 1 0 00.495.428 1.185 1.185 0 00.147.046c.259.442.622 1.059.734 1.229h.052c2.493 0 2.868 3.048 1.934 3.048a2.324 2.324 0 01-1.1-.332.508.508 0 00-.718.28l-.956 2.868a1 1 0 01-.95.685H6.345a1 1 0 01-.945-.685zM21.459 20.5h-.283l-.825-3.716a1 1 0 00-.977-.784h-.5a.911.911 0 00-.865.5c-.014.021-.356.5-1.635.5a2.969 2.969 0 01-2.6-1.447 1 1 0 00-.9-.553h-1.25a1 1 0 00-.983.822L9.789 20.5h-.915v-5.163a3 3 0 001.876-1.889l.49-1.47a3.543 3.543 0 00.384.021 2.56 2.56 0 002.468-2.754A4.129 4.129 0 0013.9 8h1.3a2.982 2.982 0 001.417 3.082 1.014 1.014 0 00.275.112l2.66.638.163.652A2.009 2.009 0 0021.8 14a119.598 119.598 0 01-.341 6.5z\"/><circle cx=\"8.624\" cy=\"7.999\" r=\".75\"/><circle cx=\"5.624\" cy=\"7.999\" r=\".75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});