define("ember-svg-jar/inlined/disability-cc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-cc</title><path d=\"M10.148 17.488a2 2 0 00-1.9-3.523 2.136 2.136 0 01-1.026.262 2.227 2.227 0 010-4.454 2.161 2.161 0 01.984.239 2 2 0 001.8-3.571 6.135 6.135 0 00-2.789-.668 6.227 6.227 0 102.922 11.715zM21.948 17.488a2 2 0 00-1.9-3.523 2.139 2.139 0 01-1.026.262 2.227 2.227 0 010-4.454 2.161 2.161 0 01.984.239 2 2 0 001.8-3.571 6.138 6.138 0 00-2.789-.668 6.227 6.227 0 102.922 11.715z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});