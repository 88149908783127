define("ember-svg-jar/inlined/tv-flat-screen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tv-flat-screen</title><path d=\"M22.5 1.5h-21A1.5 1.5 0 000 3v13.5A1.5 1.5 0 001.5 18h8.75a.25.25 0 01.25.25v2a.25.25 0 01-.25.25H7a1 1 0 000 2h10a1 1 0 000-2h-3.25a.25.25 0 01-.25-.25v-2a.25.25 0 01.25-.25h8.75a1.5 1.5 0 001.5-1.5V3a1.5 1.5 0 00-1.5-1.5zm-1.5 14H3a.5.5 0 01-.5-.5V4.5A.5.5 0 013 4h18a.5.5 0 01.5.5V15a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});