define("ember-svg-jar/inlined/settings-vertical-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>settings-vertical-1</title><path d=\"M21.75.251H2.25a2 2 0 00-2 2v19.5a2 2 0 002 2h19.5a2 2 0 002-2v-19.5a2 2 0 00-2-2zM19 13.5v2.25a.75.75 0 01-1.5 0V13.5h-.75a.75.75 0 010-1.5h3a.75.75 0 010 1.5zm.75 6.25a1.5 1.5 0 11-1.5-1.5 1.5 1.5 0 011.5 1.501zM17.5 10V3.5a.75.75 0 011.5 0V10a.75.75 0 01-1.5 0zM13 8v7.75a.75.75 0 11-1.5 0V8h-.75a.75.75 0 010-1.5h3a.75.75 0 110 1.5zm.75 11.75a1.5 1.5 0 11-1.5-1.5 1.5 1.5 0 011.5 1.501zM11.5 4.5v-1a.75.75 0 111.5 0v1a.75.75 0 11-1.5 0zm-5 7v4.25a.75.75 0 11-1.5 0V11.5h-.75a.75.75 0 010-1.5h3a.75.75 0 010 1.5zm.75 8.25a1.5 1.5 0 11-1.5-1.5 1.5 1.5 0 011.5 1.501zM5 8V3.5a.75.75 0 111.5 0V8A.75.75 0 015 8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});