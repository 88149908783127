define("ember-svg-jar/inlined/common-file-double-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-double-horizontal</title><path d=\"M22.192 6.362L19.2 3.538A1.818 1.818 0 0017.905 3H6.583A1.835 1.835 0 004.75 4.833V6.5H3.083A1.835 1.835 0 001.25 8.333v10.834A1.835 1.835 0 003.083 21h14.334a1.835 1.835 0 001.833-1.833V17.5h1.667a1.835 1.835 0 001.833-1.833V7.678a1.856 1.856 0 00-.558-1.316zM16.75 19h-13a.5.5 0 01-.5-.5V9a.5.5 0 01.5-.5h.75a.25.25 0 01.25.25v6.917A1.833 1.833 0 006.583 17.5H17a.25.25 0 01.25.25v.75a.5.5 0 01-.5.5zm3.5-3.5h-13a.5.5 0 01-.5-.5V5.5a.5.5 0 01.5-.5h10.389a.5.5 0 01.343.136L20.593 7.6a.5.5 0 01.157.364V15a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});