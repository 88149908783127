define("ember-svg-jar/inlined/audio-file-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file-check</title><path d=\"M23.414 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.275a.247.247 0 00.242.25c.366.007 1.038.034 1.485.082A.245.245 0 008 9.359V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.244.244 0 00-.241.21 7.935 7.935 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M16.233 11.5a.734.734 0 00.017-.084v-5.11a.182.182 0 01.293-.166l1.058.665a.75.75 0 10.8-1.269l-1.818-1.141A1.25 1.25 0 0014.75 5.5v3.59c0 .134-.171.079-.26.058a2.081 2.081 0 00-.49-.06 2.253 2.253 0 00-2.25 2.249.565.565 0 00.092.224 8.041 8.041 0 011.524 1.851.482.482 0 00.158.117 2.139 2.139 0 00.476.059 2.246 2.246 0 002.233-2.088zM6.5 11a6.5 6.5 0 106.5 6.5A6.508 6.508 0 006.5 11zm3.273 5.2l-2.9 3.874a1.491 1.491 0 01-1.1.6h-.108a1.5 1.5 0 01-1.058-.442l-1.5-1.5a.75.75 0 011.061-1.06l1.3 1.3a.25.25 0 00.377-.027l2.732-3.643a.75.75 0 111.2.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});