define("ember-svg-jar/inlined/farming-grow-crops", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>farming-grow-crops</title><path d=\"M6.5 19a6.614 6.614 0 00-3.733 1.15.5.5 0 01-.583-.012L.8 19.1a.5.5 0 00-.8.4v4a.5.5 0 00.276.447A.508.508 0 00.5 24a.5.5 0 00.3-.1l1.384-1.038a.5.5 0 01.583-.012A6.614 6.614 0 006.5 24c2.849 0 4-1.764 4-2.5S9.349 19 6.5 19zM20.5 18a4.194 4.194 0 00-2.694.962.5.5 0 01-.67-.033l-.782-.783a.5.5 0 00-.854.354v3a.5.5 0 00.309.462A.493.493 0 0016 22a.5.5 0 00.354-.146l.782-.783a.5.5 0 01.67-.033A4.194 4.194 0 0020.5 22c2.253 0 3.5-1.183 3.5-2s-1.247-2-3.5-2zM12.809 13.52a3.513 3.513 0 01.233-1.709c.693-1.724 1.983-3.937 3.5-4.695a.5.5 0 11.447.895A6.045 6.045 0 0014.74 10.6a.5.5 0 00.3.722 2.86 2.86 0 002.7-1.018 6.618 6.618 0 00.865-6.089.5.5 0 00-.332-.276.506.506 0 00-.423.085 5.8 5.8 0 01-2.264.972 6.27 6.27 0 00-2.042.807A2.506 2.506 0 0012.3 7.8a1.93 1.93 0 00.3 1.023.5.5 0 01.006.513l-.109.192a.25.25 0 01-.452-.034 13.381 13.381 0 00-.971-2.022.5.5 0 01.049-.576 2.454 2.454 0 00.572-1.194 3.451 3.451 0 00-1.229-2.9 6.113 6.113 0 00-3.258-1.481A7.912 7.912 0 014.1.1a.5.5 0 00-.754.2c-1.093 2.429-.233 6.674 1.23 8.191a4.15 4.15 0 002.8 1.5 2.1 2.1 0 00.429-.044 2.144 2.144 0 001.012-.523.5.5 0 00.112-.6 12.983 12.983 0 00-2.56-3.471.5.5 0 01.7-.718 14.2 14.2 0 014.181 7.885 8.864 8.864 0 01-1.836 4.511.75.75 0 001.171.937 11.571 11.571 0 00.964-1.471.25.25 0 01.451.033 7.337 7.337 0 001.469 2.5.75.75 0 101.06-1.06 8.351 8.351 0 01-1.72-4.45z\"/><path d=\"M1 15.5a5.116 5.116 0 003.345-1.243.5.5 0 01.64 0A5.11 5.11 0 008.5 15.5a1.03 1.03 0 001-1.03.971.971 0 00-1.015-.97 3.622 3.622 0 01-3.055-1.646 1.055 1.055 0 00-1.547.024A3.718 3.718 0 011 13.5a1 1 0 000 2zM19.015 14.257a.5.5 0 01.64 0A5.116 5.116 0 0023 15.5a1 1 0 000-2 3.718 3.718 0 01-2.883-1.622 1.055 1.055 0 00-1.547-.024 3.622 3.622 0 01-3.055 1.646.971.971 0 00-1.015.97 1.03 1.03 0 001 1.03 5.11 5.11 0 003.515-1.243z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});