define("ember-svg-jar/inlined/smiley-yawn-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-yawn-alternate</title><path d=\"M10.5 8a1 1 0 00-1-1c-1.153 0-3.142.547-3.895 2.053a1 1 0 00.448 1.347.989.989 0 00.447.1 1 1 0 00.9-.553A2.656 2.656 0 019.5 9a1 1 0 001-1zM14.5 7a1 1 0 000 2 2.656 2.656 0 012.105.947 1 1 0 00.9.553.989.989 0 00.446-.105 1 1 0 00.449-1.342C17.642 7.547 15.653 7 14.5 7z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M12 13a2.5 2.5 0 00-2.5 2.5v2a2.5 2.5 0 005 0v-2A2.5 2.5 0 0012 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});