define("ember-svg-jar/inlined/landmark-japan-castle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-japan-castle</title><path d=\"M23.5 24a.5.5 0 00.416-.777l-.7-1.055a1.5 1.5 0 00-1.248-.668H19.75a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25h1.159l.155-.054c.387-.326 2.411-2.03 2.6-2.2a1 1 0 10-1.33-1.5l-1.541 1.371a.252.252 0 01-.354-.022A4.5 4.5 0 0017 14h-.75a.25.25 0 01-.25-.25v-2.677a.255.255 0 01.19-.242c2.146-.607 3.227-2.484 4.31-4.175a1 1 0 10-1.685-1.078l-1.083 1.691a.5.5 0 01-.421.231H6.689a.5.5 0 01-.421-.231L5.185 5.578A1 1 0 103.5 6.656c1.01 1.579 2.121 3.555 4.309 4.175a.254.254 0 01.19.242v2.677a.25.25 0 01-.25.25H7a4.592 4.592 0 00-3.337 1.694.25.25 0 01-.353.021l-1.645-1.463a1 1 0 10-1.33 1.5c.188.166 2.194 1.855 2.6 2.193a.25.25 0 00.156.055H4.25a.25.25 0 01.25.25v3a.25.25 0 01-.25.25H2.036a1.5 1.5 0 00-1.248.668l-.7 1.055A.5.5 0 00.5 24zM8.75 21.25a.25.25 0 01-.25.25H6.75a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25H8.5a.25.25 0 01.25.25zm4.5 0a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25v-3A.25.25 0 0111 18h2a.25.25 0 01.25.25zm.75-7.5a.25.25 0 01-.25.25h-3.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h3.5a.25.25 0 01.25.25zm3.5 7.5a.25.25 0 01-.25.25H15.5a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25h1.75a.25.25 0 01.25.25z\"/><path d=\"M7.567 5.4a.5.5 0 00.491.6h7.884a.5.5 0 00.491-.595 3.646 3.646 0 00-3.018-2.745.517.517 0 01-.415-.5V1a1 1 0 00-2 0v1.162a.517.517 0 01-.415.5A3.647 3.647 0 007.567 5.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});