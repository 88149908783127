define("ember-svg-jar/inlined/lion-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lion-1</title><path d=\"M12 0A10.639 10.639 0 001 11c0 6.925 5.14 13 11 13s11-6.075 11-13A10.639 10.639 0 0012 0zm0 21.554a3.3 3.3 0 01-2.941-1.8.25.25 0 01.221-.363 4.216 4.216 0 002.71-1h.019a4.22 4.22 0 002.711 1 .25.25 0 01.221.363 3.3 3.3 0 01-2.941 1.8zm5.5-6.572a2.848 2.848 0 01-2.75 2.907 2.742 2.742 0 01-2-.863V15.06l1.1-1.644a.75.75 0 00-.624-1.166h-2.448a.75.75 0 00-.624 1.166l1.1 1.644v1.965a2.739 2.739 0 01-2 .864A2.845 2.845 0 016.5 14.982V6.23a1.211 1.211 0 01.474-.967 3.541 3.541 0 011.97-.818c1.223 0 1.223 1.222 3.056 1.222s1.833-1.222 3.055-1.222a3.544 3.544 0 011.971.818 1.211 1.211 0 01.474.967z\"/><circle cx=\"9.305\" cy=\"9.695\" r=\"1.222\"/><circle cx=\"14.694\" cy=\"9.695\" r=\"1.222\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});