define("ember-svg-jar/inlined/landmark-chinese-wall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-chinese-wall</title><path d=\"M8.093 16.481a.252.252 0 00.273.095 34.616 34.616 0 013.429-.791.25.25 0 00.205-.245V13.8a.252.252 0 00-.074-.178C9 10.734 6.862 9.652 2.169 8.037A.251.251 0 012 7.8V6a1.5 1.5 0 013 0v1.8a.249.249 0 00.166.235A20.581 20.581 0 0111.6 11.23a.25.25 0 00.4-.2V8.774a.25.25 0 00-.141-.225A39.034 39.034 0 007.17 6.7.25.25 0 017 6.461V2a.5.5 0 00-.5-.5H6a.5.5 0 00-.5.5v.25a.25.25 0 01-.25.25H4.5a.25.25 0 01-.25-.25V2a.5.5 0 00-.5-.5h-.5a.5.5 0 00-.5.5v.25a.25.25 0 01-.25.25h-.75a.25.25 0 01-.25-.25V2a.5.5 0 00-.5-.5H.5A.5.5 0 000 2v9a.523.523 0 00.4.49 12.61 12.61 0 017.693 4.991z\"/><path d=\"M23 5.5h-1a1 1 0 00-1 1v.75a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25V6.5a1 1 0 00-1-1h-1a1 1 0 00-1 1v.75a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25V6.5a1 1 0 00-1-1h-1a1 1 0 00-1 1v9.874a.249.249 0 01-.205.246C8.52 17.4 4.843 18.377.693 21.605A.5.5 0 001 22.5h7a.492.492 0 00.257-.071 14.908 14.908 0 015.272-1.929.2.2 0 01.042 0H15.5a.5.5 0 00.5-.5v-6.5a2.5 2.5 0 015 0v3.1a.25.25 0 00.416.187 8.179 8.179 0 012.421-1.587.25.25 0 00.163-.236V6.5a1 1 0 00-1-1zM23.18 16.616a18.7 18.7 0 00-4.609 5.127.5.5 0 00.429.757h4.5a.5.5 0 00.5-.5v-5a.5.5 0 00-.82-.384z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});