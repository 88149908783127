define("ember-svg-jar/inlined/coffee-cold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>coffee-cold</title><path d=\"M6.947 22.741v.009A1.5 1.5 0 008.433 24h7.134a1.505 1.505 0 001.486-1.263l1.732-11.295a.5.5 0 01.493-.424h1.212a1 1 0 000-2h-1.155a.5.5 0 01-.489-.4 6.938 6.938 0 00-2.573-4.135 1 1 0 10-1.222 1.58 4.98 4.98 0 011.662 2.287.5.5 0 01-.472.665h-2.316a.5.5 0 01-.49-.6l1.148-5.779a.5.5 0 01.717-.346l3.25 1.625a1 1 0 10.894-1.787L15.6.21a2 2 0 00-2.848 1.39v.007l-1.389 7.006a.5.5 0 01-.49.4H7.761a.5.5 0 01-.471-.666 4.988 4.988 0 013.46-3.172 1 1 0 00-.5-1.935 6.978 6.978 0 00-5.09 5.375.5.5 0 01-.489.4H3.509a1 1 0 100 2h1.212a.5.5 0 01.493.424zm9.3-11.728a.5.5 0 01.494.576l-.308 2a.5.5 0 01-.494.423h-2.975a.5.5 0 01-.491-.593l.383-2a.5.5 0 01.491-.406zM10.4 13.605a.5.5 0 01-.49.4H8.061a.5.5 0 01-.494-.423l-.308-2a.5.5 0 01.494-.576H10.3a.5.5 0 01.49.594z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});