define("ember-svg-jar/inlined/pets-paw-bag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pets-paw-bag</title><path d=\"M24 9.25a2.5 2.5 0 00-2.5-2.5H20a.5.5 0 00-.5.5v2.5a.5.5 0 01-1 0v-6.5a3 3 0 00-3-3h-7a3 3 0 00-3 3v6.5a.5.5 0 01-1 0v-2.5a.5.5 0 00-.5-.5H2.5A2.5 2.5 0 000 9.25v12a2.5 2.5 0 002.5 2.5h19a2.5 2.5 0 002.5-2.5zm-16.5-6a1 1 0 011-1h7a1 1 0 011 1v3a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5zm3.537 12.557a1.192 1.192 0 011.924 0l1.824 2.5a1.227 1.227 0 01-.961 1.942h-3.648a1.219 1.219 0 01-1.064-.683 1.246 1.246 0 01.1-1.274zm-.3-3.657a1.246 1.246 0 111.247 1.25 1.246 1.246 0 01-1.246-1.25zm3.77 2.493a1.246 1.246 0 111.246 1.246 1.247 1.247 0 01-1.245-1.246zm-6.261 1.246a1.247 1.247 0 111.246-1.246 1.247 1.247 0 01-1.246 1.246z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});