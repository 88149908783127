define("ember-svg-jar/inlined/social-media-students-circle-network", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-students-circle-network</title><path d=\"M14.58 13.9c-1-.54-2-1.05-3-1.56-2.11-1.08-2.34-1.21-3-1.57a10.07 10.07 0 01-2.46-1.59 6.7 6.7 0 01-1.7-3.37 12.6 12.6 0 01-.08-1.71.5.5 0 00-.82-.38A10 10 0 00.9 7.64a11.6 11.6 0 00-.9 4.88 12.14 12.14 0 003.37 7.82A12 12 0 0011.11 24h1.6a6 6 0 003.47-1.81c.55-.58 2.28-2.64 1.6-5.1-.4-1.53-1.78-2.44-3.2-3.19z\"/><path d=\"M14.68 10.75l2 1.06a8.46 8.46 0 012.38 1.65 6.61 6.61 0 011.71 5.2.5.5 0 00.85.39 5.22 5.22 0 00.9-1.24A12.25 12.25 0 0024 11C23.16 4.65 18.87.52 12.37 0h-.67a5.54 5.54 0 00-3.3 1.47 4.77 4.77 0 00-1 2.4C7.14 6.82 9.31 8 11 8.86z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});