define("ember-svg-jar/inlined/style-three-pin-water-scooter-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-water-scooter-1</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm5 15a3.515 3.515 0 01-2.351-.83.219.219 0 00-.3 0A3.52 3.52 0 0112 15a3.513 3.513 0 01-2.35-.83.219.219 0 00-.3 0A3.52 3.52 0 017 15a.5.5 0 010-1c.827 0 1.812-.353 2.018-1.128a.519.519 0 01.966 0C10.191 13.647 11.174 14 12 14s1.812-.353 2.018-1.128a.519.519 0 01.966 0C15.191 13.647 16.175 14 17 14a.5.5 0 010 1zm1.06-2.133a.178.178 0 01-.172.133 1.731 1.731 0 00-.888-.25 1.812 1.812 0 01-.7-.138.252.252 0 01-.138-.153 1.752 1.752 0 00-1.655-1.227 1.725 1.725 0 00-1.662 1.209.248.248 0 01-.118.144 1.6 1.6 0 01-.73.165 1.811 1.811 0 01-.7-.138.252.252 0 01-.138-.153 1.752 1.752 0 00-1.649-1.227 1.725 1.725 0 00-1.662 1.209.248.248 0 01-.118.144 1.6 1.6 0 01-.73.165 1.679 1.679 0 00-.188.01.248.248 0 01-.25-.137l-1.131-2.261A.25.25 0 015.654 10h12.885a.251.251 0 01.241.318c-.255.912-.605 2.103-.719 2.549zM5.456 9a.25.25 0 01-.24-.322C5.625 7.33 6.661 4.766 8.682 4.52a.5.5 0 01.477.219l.189.283a.246.246 0 00.183.11.249.249 0 00.2-.072l.913-.913A.5.5 0 0111 4h2a.5.5 0 010 1h-1.689a.25.25 0 00-.177.074l-.981.98a.122.122 0 00-.015.154l.788 1.181a.25.25 0 00.208.111H17a1.991 1.991 0 011.8 1.141.252.252 0 01-.015.241.249.249 0 01-.212.118z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});