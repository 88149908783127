define("ember-svg-jar/inlined/credit-card-smartphone-exchange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-smartphone-exchange</title><path d=\"M5.728 5.25H3.87a.75.75 0 000 1.5h1.858a.75.75 0 000-1.5z\"/><path d=\"M11.013.25H2.728A2.364 2.364 0 00.12 2.333V7.5a2.684 2.684 0 002.608 2.75h8.285A2.684 2.684 0 0013.62 7.5V2.333A2.363 2.363 0 0011.013.25zm0 8.5H2.728A1.186 1.186 0 011.62 7.5V4a.25.25 0 01.25-.25h10a.25.25 0 01.25.25v3.5a1.186 1.186 0 01-1.107 1.25zM10.287 20.377l-2.99-2A.749.749 0 006.13 19v.171a.25.25 0 01-.384.211A4 4 0 013.88 16a1 1 0 00-2 0 6.011 6.011 0 004.08 5.685.25.25 0 01.17.236V23a.75.75 0 001.167.623l2.99-2a.749.749 0 000-1.246zM15.456 3.625l3.01 2A.75.75 0 0019.631 5v-.17a.25.25 0 01.384-.211A4 4 0 0121.88 8a1 1 0 002 0 6.009 6.009 0 00-4.08-5.684.251.251 0 01-.17-.237V1a.75.75 0 00-1.164-.625l-3.01 2a.751.751 0 000 1.25zM21.87 9.75h-5a1.752 1.752 0 00-1.75 1.75V22a1.693 1.693 0 001.625 1.75H22A1.692 1.692 0 0023.62 22V11.5a1.752 1.752 0 00-1.75-1.75zm-2.5 13a.75.75 0 11.75-.75.75.75 0 01-.75.75zm-2.5-11.5h5a.25.25 0 01.25.25V20a.25.25 0 01-.25.25h-5a.25.25 0 01-.25-.25v-8.5a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});