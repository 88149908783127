define("ember-svg-jar/inlined/smiley-bright-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-bright-alternate</title><path d=\"M15.052 15.182a3.384 3.384 0 01-3.214 2.318 1 1 0 100 2 5.379 5.379 0 005.11-3.683 1 1 0 10-1.9-.633z\"/><path d=\"M23 11a1 1 0 00-1 1 10 10 0 01-20 0 1 1 0 00-2 0 12 12 0 0024 0 1 1 0 00-1-1zM8.7 2.555a10.1 10.1 0 016.6 0 1 1 0 00.66-1.888 12.113 12.113 0 00-7.918 0A1 1 0 108.7 2.555z\"/><path d=\"M.858 8.489l.63.089A3.771 3.771 0 014.67 11.76l.09.63a1 1 0 001.98 0l.09-.63a3.429 3.429 0 012.861-3.182l.63-.089a1 1 0 000-1.981l-.63-.09A3.427 3.427 0 016.83 3.237l-.09-.63a1 1 0 00-1.98 0l-.09.63a3.77 3.77 0 01-3.182 3.181l-.63.09a1 1 0 000 1.981zM7.126 7.5A5.257 5.257 0 005.7 9.045 5.843 5.843 0 004.125 7.5 5.825 5.825 0 005.7 5.952 5.241 5.241 0 007.126 7.5zM13.988 6.418l-.63.09a1 1 0 000 1.981l.63.089a3.771 3.771 0 013.182 3.182l.09.63a1 1 0 001.98 0l.09-.63a3.771 3.771 0 013.182-3.182l.63-.089a1 1 0 000-1.981l-.63-.09a3.77 3.77 0 01-3.182-3.181l-.09-.63a1 1 0 00-1.98 0l-.09.63a3.77 3.77 0 01-3.182 3.181zM16.625 7.5a5.807 5.807 0 001.625-1.627A5.807 5.807 0 0019.875 7.5a5.833 5.833 0 00-1.625 1.623A5.833 5.833 0 0016.625 7.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});