define("ember-svg-jar/inlined/focus-auto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>focus-auto</title><path d=\"M12 7.5a1.753 1.753 0 00-1.75 1.75v2a.25.25 0 00.25.25h3a.249.249 0 00.25-.25v-2A1.752 1.752 0 0012 7.5z\"/><path d=\"M22.783 11.116l-2.46-2.46a.249.249 0 01-.074-.176V5A1.251 1.251 0 0019 3.75h-3.48a.251.251 0 01-.177-.073l-2.46-2.46a1.251 1.251 0 00-1.767 0l-2.46 2.46a.251.251 0 01-.177.073H5A1.252 1.252 0 003.749 5v3.48a.248.248 0 01-.073.176l-2.46 2.46a1.252 1.252 0 000 1.768l2.46 2.46a.249.249 0 01.073.177V19A1.251 1.251 0 005 20.25h3.48a.251.251 0 01.177.073l2.46 2.46a1.25 1.25 0 001.767 0l2.46-2.46a.251.251 0 01.177-.073H19A1.25 1.25 0 0020.249 19v-3.479a.25.25 0 01.074-.177l2.46-2.46a1.25 1.25 0 000-1.768zM10.5 13a.25.25 0 00-.25.25v4a.75.75 0 01-1.5 0v-8a3.25 3.25 0 016.5 0v8a.75.75 0 01-1.5 0v-4a.249.249 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});