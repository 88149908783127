define("ember-svg-jar/inlined/bathroom-shower-head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bathroom-shower-head</title><path d=\"M11.589 15.716A4.082 4.082 0 0010.8 17.4a1.2 1.2 0 102.4 0 4.082 4.082 0 00-.789-1.684.518.518 0 00-.822 0zM5.289 15.716A4.082 4.082 0 004.5 17.4a1.2 1.2 0 102.4 0 4.082 4.082 0 00-.789-1.684.518.518 0 00-.822 0zM17.889 15.716A4.082 4.082 0 0017.1 17.4a1.2 1.2 0 002.4 0 4.082 4.082 0 00-.789-1.684.518.518 0 00-.822 0zM1.089 20.616A4.065 4.065 0 00.3 22.3a1.2 1.2 0 002.4 0 4.065 4.065 0 00-.789-1.684.52.52 0 00-.822 0zM8.089 20.616A4.065 4.065 0 007.3 22.3a1.2 1.2 0 002.4 0 4.065 4.065 0 00-.789-1.684.52.52 0 00-.822 0zM22.911 20.616a.52.52 0 00-.822 0A4.065 4.065 0 0021.3 22.3a1.2 1.2 0 002.4 0 4.065 4.065 0 00-.789-1.684zM15.089 20.616A4.065 4.065 0 0014.3 22.3a1.2 1.2 0 002.4 0 4.065 4.065 0 00-.789-1.684.52.52 0 00-.822 0zM13.674 6.369a.5.5 0 00.524-.7l-.609-1.331a.975.975 0 00-.361-.416A.5.5 0 0113 3.5v-2a1 1 0 00-2 0v2a.5.5 0 01-.228.42.977.977 0 00-.361.414L9.8 5.666a.5.5 0 00.525.7 11.809 11.809 0 013.349.003zM12 7.25a10.876 10.876 0 00-8.742 4.377 1 1 0 00.782 1.623h15.92a1 1 0 00.781-1.625A10.883 10.883 0 0012 7.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});