define("ember-svg-jar/inlined/smart-watch-circle-brightness", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-brightness</title><path d=\"M12 3.5a8.5 8.5 0 108.5 8.5A8.5 8.5 0 0012 3.5zM9.865 7a1 1 0 11-1 1 1 1 0 011-1zm-2.5 6a1 1 0 111-1 1 1 0 01-1 1zm2.5 4a1 1 0 111-1 1 1 0 01-1 1zm.25-4.75a2 2 0 112 2 2 2 0 01-2-2zm4.25 4.75a1 1 0 111-1 1 1 0 01-1 1zm0-8a1 1 0 111-1 1 1 0 01-1 1zm2.5 4a1 1 0 111-1 1 1 0 01-1 1zM7.379 3.051a.125.125 0 00.135.014 9.98 9.98 0 018.972 0 .125.125 0 00.177-.144l-.68-2.55A.5.5 0 0015.5 0h-7a.5.5 0 00-.483.371l-.68 2.551a.125.125 0 00.042.129z\"/><path d=\"M16.621 20.949a.125.125 0 00-.135-.015 9.972 9.972 0 01-8.972 0 .125.125 0 00-.177.144l.68 2.551A.5.5 0 008.5 24h7a.5.5 0 00.483-.371l.68-2.551a.125.125 0 00-.042-.129z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});