define("ember-svg-jar/inlined/construction-drill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>construction-drill</title><path d=\"M9.75 13.375a.25.25 0 00-.25.25v2.5a1.5 1.5 0 001.5 1.5v5a1 1 0 001 1 1 1 0 001-1v-5a1.5 1.5 0 001.5-1.5v-2.5a.25.25 0 00-.25-.25zM17.252 3.958v1.417a.25.25 0 00.25.25H21a1.5 1.5 0 000-3h-3.612a.25.25 0 00-.244.3 4.941 4.941 0 01.108 1.033zM6.752 3.958a4.941 4.941 0 01.109-1.031.247.247 0 00-.05-.209.249.249 0 00-.2-.093H3a1.5 1.5 0 000 3h3.5a.249.249 0 00.25-.25zM22.055 17.793a1 1 0 00-1.387.277L19.5 19.822l-.891-1.336a1.341 1.341 0 00-2.218 0l-1.723 2.584a1 1 0 101.664 1.11l1.168-1.752.891 1.336a1.341 1.341 0 002.218 0l1.723-2.584a1 1 0 00-.277-1.387zM7.609 18.486a1.341 1.341 0 00-2.218 0L4.5 19.822 3.332 18.07a1 1 0 00-1.664 1.11l1.723 2.584a1.341 1.341 0 002.218 0l.891-1.336 1.168 1.752a1 1 0 001.664-1.11zM15.5 11.875a.249.249 0 00.25-.25V3.958A3.673 3.673 0 0012 .375a3.674 3.674 0 00-3.75 3.583v7.667a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});