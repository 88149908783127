define("ember-svg-jar/inlined/arrow-thick-circle-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-circle-up</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm6.287 12H14.5a.25.25 0 00-.25.25v6.25a.5.5 0 01-.5.5h-3.496a.5.5 0 01-.5-.5v-6.25A.25.25 0 009.5 12H5.715a.5.5 0 01-.354-.854l6.287-6.286a.5.5 0 01.707 0l6.286 6.286a.5.5 0 01-.354.854z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});