define("ember-svg-jar/inlined/audio-file-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file-alternate</title><path d=\"M21.707 5.707L16.293.293A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.465a.5.5 0 01.353.146l4.536 4.536a.5.5 0 01.146.353z\"/><path d=\"M14.645 6.733a.99.99 0 00-.122.031L10.374 8.1a2.2 2.2 0 00-1.724 2.146V13.8c0 .149-.131.15-.2.15a2.2 2.2 0 102.2 2.2v-5.9a.2.2 0 01.163-.2 1.207 1.207 0 00.122-.03L15.05 8.7a.2.2 0 01.2.2v2.527c0 .165-.132.128-.2.128a2.2 2.2 0 102.2 2.2V8.9a2.2 2.2 0 00-2.605-2.163z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});