define("ember-svg-jar/inlined/school-book", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>school-book</title><path d=\"M12.177 7.4a.417.417 0 00-.417.416v1.17a.417.417 0 10.833 0V7.819a.417.417 0 00-.416-.419zM7.51 18.486a.416.416 0 00-.416.416v.585a.417.417 0 10.833 0V18.9a.418.418 0 00-.417-.414z\"/><path d=\"M20.15 4.04a.5.5 0 01-.4-.489V2a2 2 0 00-2-2H5.25a3 3 0 00-3 2.948V21a3 3 0 003 3h14.5a2 2 0 002-2V6a2 2 0 00-1.6-1.96zM4.25 3a1 1 0 011-1h12a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-12a1 1 0 01-1-1zm5.177 13.569a1.906 1.906 0 01-.4 1.167 1.908 1.908 0 01.4 1.167v.583A1.92 1.92 0 017.51 21.4H6.344a.75.75 0 01-.75-.75v-5.831a.75.75 0 01.75-.75H7.51a1.92 1.92 0 011.917 1.917zm4.666-4.083a.75.75 0 01-1.5 0v-1.167a.417.417 0 00-.833 0v1.167a.75.75 0 01-1.5 0V7.819a1.917 1.917 0 013.833 0zM18.01 19.9a.75.75 0 010 1.5 3.087 3.087 0 01-3.083-3.084v-1.163a3.087 3.087 0 013.083-3.084.75.75 0 010 1.5 1.585 1.585 0 00-1.583 1.584v1.166A1.585 1.585 0 0018.01 19.9z\"/><path d=\"M7.51 15.569a.416.416 0 00-.416.416v.585a.417.417 0 10.833 0v-.583a.418.418 0 00-.417-.418z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});