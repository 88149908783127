define("ember-svg-jar/inlined/podcast-live", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>podcast-live</title><path d=\"M24 9a2.5 2.5 0 00-2.5-2.5h-19A2.5 2.5 0 000 9v6a2.5 2.5 0 002.5 2.5h19A2.5 2.5 0 0024 15zM3.5 13a1 1 0 111-1 1 1 0 01-1 1zM9 15.25H8a1.752 1.752 0 01-1.75-1.75v-4a.75.75 0 011.5 0v4a.25.25 0 00.25.25h1a.75.75 0 010 1.5zm3.25-.75a.75.75 0 01-1.5 0v-5a.75.75 0 011.5 0zm5.25-3.3a6.993 6.993 0 01-1.376 3.719.75.75 0 01-1.248 0A6.993 6.993 0 0113.5 11.2V9.5a.75.75 0 011.5 0v1.7a3.612 3.612 0 00.267 1.3.249.249 0 00.466 0A3.6 3.6 0 0016 11.2V9.5a.75.75 0 011.5 0zm4 .053a.75.75 0 010 1.5h-1a.25.25 0 00-.25.25v.5a.25.25 0 00.25.25h1a.75.75 0 010 1.5h-1a1.752 1.752 0 01-1.75-1.75v-3A1.752 1.752 0 0120.5 8.75h1a.75.75 0 010 1.5h-1a.25.25 0 00-.25.25v.5a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});