define("ember-svg-jar/inlined/sport-paragliding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sport-paragliding</title><path d=\"M18.148 19.574l2.158-.42a1.5 1.5 0 00-.243-2.98 1.546 1.546 0 00-.251.021l-2.157.42a1.47 1.47 0 01-.247.02 1.5 1.5 0 01-.961-.348l-.8-.665a4.525 4.525 0 00-2.89-1.042 4.428 4.428 0 00-1 .114l-3.84.886A5.472 5.472 0 005.1 17.222l-3.623 3.946a1.5 1.5 0 102.21 2.032l3.624-3.95a2.493 2.493 0 011.28-.748l3.84-.885a1.45 1.45 0 01.334-.038 1.506 1.506 0 01.963.347l.8.666a4.509 4.509 0 002.884 1.042 4.579 4.579 0 00.736-.06zM.615 1.737a9.667 9.667 0 011.534 2.207.247.247 0 00.2.143c4.829.535 13.857 2.378 14.933 2.6a.5.5 0 00.21 0l.221-.05a.251.251 0 01.3.177l1.8 6.465a1 1 0 00.963.733 1.04 1.04 0 00.268-.036 1 1 0 00.7-1.232L19.969 6.4a.249.249 0 01.186-.311l3.454-.777a.5.5 0 00.025-.97A119.036 119.036 0 00.545.322H.53A.48.48 0 00.057.59a.5.5 0 00.056.547c.171.21.339.408.502.6z\"/><path d=\"M3 9.32a.5.5 0 00.187.391.507.507 0 00.313.109.576.576 0 00.109-.011l6.179-1.39a.25.25 0 01.3.285l-.474 2.844a1 1 0 00.822 1.15.938.938 0 00.166.014 1 1 0 00.985-.836l.641-3.847a.25.25 0 01.192-.2l1.6-.359a.25.25 0 000-.489c-3.7-.79-7.364-1.392-11.086-1.817a.252.252 0 00-.215.082.249.249 0 00-.057.222A17.542 17.542 0 013 9.32z\"/><circle cx=\"15.5\" cy=\"11.82\" r=\"2.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});