define("ember-svg-jar/inlined/arrow-dot-corner-down-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-dot-corner-down-right</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-3.252 16.748a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm1.5 2.5a1.5 1.5 0 11-1.5-1.5 1.5 1.5 0 011.5 1.5zm-1.5-6.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm0-4a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm-4 8a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm1.5 2.5a1.5 1.5 0 11-1.5-1.5 1.5 1.5 0 011.5 1.5zm-1.5-6.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm-4 4a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm1.5 2.5a1.5 1.5 0 11-1.5-1.5 1.5 1.5 0 011.5 1.5zm-1.5-6.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm0-4a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm-4 4a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm1.5 6.5a1.5 1.5 0 11-1.5-1.5 1.5 1.5 0 011.5 1.5zm-1.5-10.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm0-4a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm-2.5 2.5a1.5 1.5 0 11-1.5-1.5 1.5 1.5 0 011.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});