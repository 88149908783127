define("ember-svg-jar/inlined/temperature-fahrenheit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>temperature-fahrenheit</title><path d=\"M12 0a12 12 0 1012 12A12 12 0 0012 0zm1.5 7H8.75a.25.25 0 00-.25.25v2.5a.25.25 0 00.25.25h3.75a1 1 0 010 2H8.75a.25.25 0 00-.25.25v5.25a1 1 0 01-2 0V7a2 2 0 012-2h5a1 1 0 010 2zm4.5 4a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});