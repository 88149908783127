define("ember-svg-jar/inlined/single-woman-actions-block.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-block</title><path d=\"M9.268 17.5a.249.249 0 00.25-.242 7.968 7.968 0 012.327-5.416.188.188 0 00.05-.173.185.185 0 00-.118-.136l-.3-.11a.778.778 0 01-.512-.73v-.467a.249.249 0 01.249-.25A6.377 6.377 0 0015 9.082a.389.389 0 00.083-.593c-.494-.537-.835-1.185-.981-3.317C13.84 1.347 11.237 0 9 0S4.163 1.347 3.9 5.172c-.145 2.132-.487 2.78-.98 3.317a.387.387 0 00.08.593 6.35 6.35 0 003.782.9.251.251 0 01.254.25v.462a.779.779 0 01-.513.73c-2.9 1.065-5.212 1.825-5.764 2.922A7.822 7.822 0 000 17.077a.389.389 0 00.1.3.4.4 0 00.29.126z\"/><path d=\"M19 11.169A6.513 6.513 0 0011.169 19 6.418 6.418 0 0016 23.832a6.76 6.76 0 001.49.167 6.5 6.5 0 006.341-8A6.422 6.422 0 0019 11.169zm-2.559 10.712a4.111 4.111 0 01-1.051-.4.251.251 0 01-.058-.4l5.752-5.751a.252.252 0 01.213-.071.246.246 0 01.184.129 4.119 4.119 0 01.4 1.05 4.513 4.513 0 01-5.44 5.44zm-2.121-7.563A4.5 4.5 0 0117.517 13a4.761 4.761 0 011.042.116 4.111 4.111 0 011.054.4.251.251 0 01.058.4l-5.753 5.754a.252.252 0 01-.213.071.248.248 0 01-.184-.129 4.111 4.111 0 01-.4-1.054 4.488 4.488 0 011.199-4.24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});