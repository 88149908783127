define("ember-svg-jar/inlined/playlist-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>playlist-home</title><path d=\"M8.884 16.092s7.053-6.053 7.389-6.083a.25.25 0 00.227-.249V2.5A2.5 2.5 0 0014 0H2.5A2.5 2.5 0 000 2.5v12A2.5 2.5 0 002.5 17h5.552a.251.251 0 00.223-.137 2.482 2.482 0 01.609-.771zM11.5 8a1 1 0 01-1-1 1.77 1.77 0 00-1.151-1.729A.25.25 0 009 5.5v5.552A2.5 2.5 0 116.5 8.5c.077 0 .152 0 .227.01A.249.249 0 007 8.261V4a1 1 0 011-1c1.557 0 4.5.836 4.5 4a1 1 0 01-1 1zM23.823 17.618l-6.5-5.5a.5.5 0 00-.647 0l-6.5 5.5a.5.5 0 00.324.882h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5H15a.5.5 0 00.5-.5v-2.25a1.5 1.5 0 013 0v2.25a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-4.75a.25.25 0 01.25-.25h1.25a.5.5 0 00.323-.882z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});