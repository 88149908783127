define("ember-svg-jar/inlined/messaging-kik", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messaging-kik</title><path d=\"M2.25 21.14V2.86A2.23 2.23 0 014.32.5C5.47.5 6.25 1.69 6.25 3v10.28l5.33-5.72a2.45 2.45 0 011.83-1.05 2 2 0 011.83 2.11 3 3 0 01-1 2L11 13.86l3.9 6a2.69 2.69 0 01.52 1.52 2 2 0 01-1.94 2.14 1.92 1.92 0 01-1.8-1.08l-3.62-5.7-1.81 1.84v2.7a2 2 0 01-1.93 2.22 2.23 2.23 0 01-2.07-2.36M21.75 16a2.5 2.5 0 11-2.5-2.5 2.5 2.5 0 012.5 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});