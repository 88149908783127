define("ember-svg-jar/inlined/clean-car-cable-charge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>clean-car-cable-charge</title><path d=\"M10.658 8.033a.5.5 0 01.342.474V12a1 1 0 002 0V8.507a.5.5 0 01.342-.474A4.259 4.259 0 0016.25 4a.75.75 0 00-.75-.75.5.5 0 01-.5-.5V1a1 1 0 00-2 0v1.75a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v1.75a.5.5 0 01-.5.5.75.75 0 00-.75.75 4.258 4.258 0 002.908 4.033zM6.5 22h-3a.5.5 0 00-.5.5v.5a1 1 0 001 1h2a1 1 0 001-1v-.5a.5.5 0 00-.5-.5zM20.5 22h-3a.5.5 0 00-.5.5v.5a1 1 0 001 1h2a1 1 0 001-1v-.5a.5.5 0 00-.5-.5z\"/><path d=\"M21.634 17.073l-.719-.719a3.973 3.973 0 01-.883-1.341L18.3 10.689v.005a1.49 1.49 0 00-1.392-.944H15.5a1 1 0 000 2h.734a.5.5 0 01.464.314l.8 2a.5.5 0 01-.465.686H6.966a.5.5 0 01-.465-.686l.8-2a.5.5 0 01.464-.314H8.5a1 1 0 000-2H7.088a1.49 1.49 0 00-1.392.943l-1.729 4.323a3.993 3.993 0 01-.885 1.341l-.716.716a1.244 1.244 0 00-.366.884v1.793A1.252 1.252 0 003.25 21h17.5A1.252 1.252 0 0022 19.75v-1.793a1.244 1.244 0 00-.366-.884z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});