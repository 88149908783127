define("ember-svg-jar/inlined/image-file-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-search</title><path d=\"M10.168 19.787a.242.242 0 01-.031-.3 5.512 5.512 0 10-1.687 1.684.241.241 0 01.3.03l2.505 2.506a1 1 0 001.415-1.414zm-4.668.246a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5zM14.68 5.538a1.493 1.493 0 10-1.493 1.492 1.493 1.493 0 001.493-1.492z\"/><path d=\"M23.415 3L21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.262a.242.242 0 00.24.242 6.945 6.945 0 011.452.206A.243.243 0 008 9.475V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-8.618a.243.243 0 00-.23.169 6.938 6.938 0 01-.413 1 .274.274 0 00.061.3s.294.307.406.406A.425.425 0 0013 20.5h9a2 2 0 002-2V4.414A2 2 0 0023.415 3z\"/><path d=\"M11.588 12.3c.119.158.23.321.335.489a.429.429 0 00.371.214h8.019a.186.186 0 00.158-.286l-3.236-5.181a.373.373 0 00-.633 0l-1.81 2.9a.187.187 0 01-.317 0L13.831 9.4a.375.375 0 00-.633 0l-1.629 2.606a.261.261 0 00.019.294z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});