define("ember-svg-jar/inlined/lucide-thermometer-sun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 9a4 4 0 00-2 7.5M12 3v2M6.6 18.4l-1.4 1.4M20 4v10.54a4 4 0 11-4 0V4a2 2 0 014 0zM4 13H2M6.34 7.34L4.93 5.93\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});