define("ember-svg-jar/inlined/file-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-html</title><path d=\"M15.5 6h3.9a.25.25 0 00.177-.427L14.427.428A.25.25 0 0014 .6v3.9A1.5 1.5 0 0015.5 6z\"/><path d=\"M22.8 10H3.25A.25.25 0 013 9.751V9a.25.25 0 01.25-.25h16.5A.25.25 0 0020 8.5V7.251A.25.25 0 0019.75 7H15.5A2.5 2.5 0 0113 4.5V.251A.25.25 0 0012.75 0H2a2 2 0 00-2 2v20a2 2 0 002 2h16a1.99 1.99 0 001.936-1.537c.017-.07-.019-.213-.128-.213H3.25A.25.25 0 013 22v-.75a.25.25 0 01.25-.25H22.8a1.114 1.114 0 001.2-1v-9a1.114 1.114 0 00-1.2-1zM6.625 18.5a.625.625 0 01-1.25 0v-2.124a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V18.5a.625.625 0 01-1.25 0v-6a.625.625 0 011.25 0v2.125a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V12.5a.625.625 0 111.25 0zM12 13.126h-1.125a.25.25 0 00-.25.25V18.5a.625.625 0 01-1.25 0v-5.124a.25.25 0 00-.25-.25H8a.625.625 0 110-1.25h4a.625.625 0 010 1.25zm5.625 5.374a.625.625 0 11-1.25 0v-2.934c0-.13-.155-.108-.206-.007l-.11.221a.65.65 0 01-1.118 0s-.07-.144-.117-.235-.2-.073-.2.009V18.5a.625.625 0 11-1.25 0v-6a.625.625 0 011.184-.279s.781 1.558.826 1.651a.133.133 0 00.229 0l.827-1.653a.625.625 0 011.184.279zm4.375.626h-2a1.627 1.627 0 01-1.625-1.626v-5a.625.625 0 111.25 0v5a.376.376 0 00.375.375h2a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});