define("ember-svg-jar/inlined/soccer-ball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>soccer-ball</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm1.013 4.163a.5.5 0 01.3-.46l2.777-1.186a.5.5 0 01.412.009 10.548 10.548 0 013.08 2.238.5.5 0 01.137.389l-.279 3.152a.5.5 0 01-.262.4l-1.934 1.037a.5.5 0 01-.531-.037l-3.5-2.546a.5.5 0 01-.205-.405zm-8.574.576A10.533 10.533 0 017.508 2.52a.5.5 0 01.412-.009L10.709 3.7a.5.5 0 01.3.46v2.595a.5.5 0 01-.206.4l-3.4 2.478a.5.5 0 01-.515.046l-2.029-.991a.5.5 0 01-.279-.405L4.3 5.129a.5.5 0 01.139-.39zm3.329 11.775l-1.127 1.258a.5.5 0 01-.485.153l-3.068-.707a.5.5 0 01-.328-.249 10.416 10.416 0 01-1.16-3.58.5.5 0 01.114-.389l1.925-2.216a.5.5 0 01.614-.112l2.1 1.127a.5.5 0 01.24.286l1.277 3.946a.5.5 0 01-.102.483zm8 3l-1.539 2.577a.5.5 0 01-.34.236 10.528 10.528 0 01-3.741.005.5.5 0 01-.341-.236l-1.545-2.578a.5.5 0 01.057-.591l1.126-1.257a.5.5 0 01.373-.166h4.393a.5.5 0 01.373.166l1.125 1.257a.5.5 0 01.057.59zm5.474-2.548a.5.5 0 01-.328.249l-3.068.707a.5.5 0 01-.485-.153l-1.127-1.258a.5.5 0 01-.1-.488l1.277-3.946a.5.5 0 01.24-.286l2.1-1.127a.5.5 0 01.613.112L22.286 13a.5.5 0 01.118.394 10.416 10.416 0 01-1.164 3.575z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});