define("ember-svg-jar/inlined/single-neutral-actions-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-block</title><path d=\"M.5 17.692h1.787a.247.247 0 01.246.226l.438 5.259a.5.5 0 00.493.454h3.96a.5.5 0 00.493-.454l.438-5.259a.247.247 0 01.247-.226h.573a.251.251 0 00.177-.075.246.246 0 00.07-.18 7.912 7.912 0 011.315-4.611.242.242 0 00.036-.187A5.442 5.442 0 000 13.732V17.2a.5.5 0 00.5.492z\"/><circle cx=\"5.444\" cy=\"3.834\" r=\"3.464\"/><path d=\"M10.815 18.685a6.482 6.482 0 004.973 4.78 7.233 7.233 0 001.532.165 6.77 6.77 0 004.859-2.015 6.324 6.324 0 001.649-5.915 6.483 6.483 0 00-4.975-4.776 6.85 6.85 0 00-6.388 1.85 6.327 6.327 0 00-1.65 5.911zm7.621-5.822a4.442 4.442 0 011.1.4.247.247 0 01.058.4l-6 5.743a.247.247 0 01-.384-.052 3.952 3.952 0 01-.458-1.116 4.3 4.3 0 011.135-4.069 4.806 4.806 0 013.45-1.42 5.254 5.254 0 011.099.114zm-2.23 8.667a4.485 4.485 0 01-1.093-.395.248.248 0 01-.058-.4l6-5.743a.249.249 0 01.385.053 4.027 4.027 0 01.457 1.112 4.3 4.3 0 01-1.134 4.071 4.893 4.893 0 01-4.557 1.302zM.5 17.692h1.787a.247.247 0 01.246.226l.438 5.259a.5.5 0 00.493.454h3.96a.5.5 0 00.493-.454l.438-5.259a.247.247 0 01.247-.226h.573a.251.251 0 00.177-.075.246.246 0 00.07-.18 7.912 7.912 0 011.315-4.611.242.242 0 00.036-.187A5.442 5.442 0 000 13.732V17.2a.5.5 0 00.5.492z\"/><circle cx=\"5.444\" cy=\"3.834\" r=\"3.464\"/><path d=\"M10.815 18.685a6.482 6.482 0 004.973 4.78 7.233 7.233 0 001.532.165 6.77 6.77 0 004.859-2.015 6.324 6.324 0 001.649-5.915 6.483 6.483 0 00-4.975-4.776 6.85 6.85 0 00-6.388 1.85 6.327 6.327 0 00-1.65 5.911zm7.621-5.822a4.442 4.442 0 011.1.4.247.247 0 01.058.4l-6 5.743a.247.247 0 01-.384-.052 3.952 3.952 0 01-.458-1.116 4.3 4.3 0 011.135-4.069 4.806 4.806 0 013.45-1.42 5.254 5.254 0 011.099.114zm-2.23 8.667a4.485 4.485 0 01-1.093-.395.248.248 0 01-.058-.4l6-5.743a.249.249 0 01.385.053 4.027 4.027 0 01.457 1.112 4.3 4.3 0 01-1.134 4.071 4.893 4.893 0 01-4.557 1.302z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});