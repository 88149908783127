define("ember-svg-jar/inlined/dentistry-tooth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dentistry-tooth</title><path d=\"M21.249 6.366C21.249 2.8 18.948 0 16.011 0a5.2 5.2 0 00-3.661 1.494.5.5 0 01-.7 0A5.2 5.2 0 007.989 0C5.052 0 2.751 2.8 2.751 6.366a10.615 10.615 0 002.31 7.156.5.5 0 01.091.5 15.859 15.859 0 00-.944 5.466 16.512 16.512 0 00.339 3.351A1.463 1.463 0 005.973 24a1.5 1.5 0 001.489-1.335c.371-3.395 2.321-5.956 4.538-5.956s4.167 2.561 4.538 5.957A1.5 1.5 0 0018.027 24a1.465 1.465 0 001.427-1.161 16.566 16.566 0 00.338-3.35 15.859 15.859 0 00-.944-5.466.5.5 0 01.091-.5 10.615 10.615 0 002.31-7.157zm-4.749 3.92a.643.643 0 01-.643.643h-1.428a.5.5 0 00-.5.5v1.428a.643.643 0 01-.643.643h-2.572a.642.642 0 01-.642-.643v-1.428a.5.5 0 00-.5-.5H8.143a.643.643 0 01-.643-.643V7.714a.643.643 0 01.643-.643h1.429a.5.5 0 00.5-.5V5.143a.642.642 0 01.642-.643h2.572a.643.643 0 01.643.643v1.428a.5.5 0 00.5.5h1.428a.643.643 0 01.643.643z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});