define("ember-svg-jar/inlined/road-sign-deadend-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-deadend_1</title><path d=\"M19 7a.25.25 0 00-.25.25v1a.25.25 0 00.5 0v-1A.25.25 0 0019 7zM17 15.5a.25.25 0 00-.25.25v1a.25.25 0 00.5 0v-1a.25.25 0 00-.25-.25zM6 7a.25.25 0 00-.25.25v1a.25.25 0 00.5 0v-1A.25.25 0 006 7zM14.25 6.25v1a.25.25 0 00.5 0v-1c0-.275-.5-.275-.5 0z\"/><path d=\"M23.25 0H.75A.75.75 0 000 .75v22.5a.75.75 0 00.75.75h22.5a.75.75 0 00.75-.75V.75a.75.75 0 00-.75-.75zm-19 10.25v-5A.75.75 0 015 4.5a2.752 2.752 0 012.75 2.75v1A2.752 2.752 0 015 11a.75.75 0 01-.75-.75zm4 5.75h1a.75.75 0 010 1.5h-1a.25.25 0 000 .5h1a.75.75 0 010 1.5h-1a1.752 1.752 0 01-1.75-1.75v-3A1.752 1.752 0 018.25 13h1a.75.75 0 010 1.5h-1a.25.25 0 00-.25.25v1a.25.25 0 00.25.25zm.25-6.75v-3a1.752 1.752 0 011.75-1.75h1a.75.75 0 010 1.5h-1a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h1a.75.75 0 010 1.5h-1a.25.25 0 000 .5h1a.75.75 0 010 1.5h-1A1.752 1.752 0 018.5 9.25zm5.75 9.5a.749.749 0 01-.608.736.691.691 0 01-.142.014.749.749 0 01-.7-.472l-.143-.357a.213.213 0 00-.411.079.75.75 0 01-1.5 0v-5a.75.75 0 011.446-.278l.143.357a.213.213 0 00.411-.079.75.75 0 011.5 0zm.5-8.5v-1a.25.25 0 00-.5 0v1a.75.75 0 01-1.5 0v-4a1.75 1.75 0 013.5 0v4a.75.75 0 01-1.5 0zm4 6.5A2.752 2.752 0 0116 19.5a.75.75 0 01-.75-.75v-5A.75.75 0 0116 13a2.752 2.752 0 012.75 2.75zm2-8.5A2.752 2.752 0 0118 11a.75.75 0 01-.75-.75v-5A.75.75 0 0118 4.5a2.752 2.752 0 012.75 2.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});