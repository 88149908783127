define("ember-svg-jar/inlined/religion-cross", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-cross</title><path d=\"M16.5 8.65h-2.25a.5.5 0 01-.5-.5V5.9a1.252 1.252 0 00-1.25-1.25h-1a1.252 1.252 0 00-1.25 1.25v2.25a.5.5 0 01-.5.5H7.5A1.252 1.252 0 006.25 9.9v1a1.251 1.251 0 001.25 1.25h2.25a.5.5 0 01.5.5v5.75a1.251 1.251 0 001.25 1.25h1a1.251 1.251 0 001.25-1.25v-5.75a.5.5 0 01.5-.5h2.25a1.251 1.251 0 001.25-1.25v-1a1.252 1.252 0 00-1.25-1.25zM6.792 4.956a1 1 0 00.8-1.6l-1.2-1.6a1 1 0 00-1.6 1.2l1.2 1.6a1 1 0 00.8.4zM2.72 9.694a.973.973 0 00.316.052A1 1 0 003.352 7.8l-1.9-.632a1 1 0 10-.632 1.9zM2.788 14.861L1.058 15.9a1 1 0 101.029 1.714l1.73-1.038a1 1 0 10-1.029-1.715zM7.642 19.2a1 1 0 00-1.4.233l-1.161 1.634a1 1 0 101.627 1.162L7.874 20.6a1 1 0 00-.232-1.4zM19.011 1.552a1 1 0 00-1.4.2l-1.2 1.6a1 1 0 101.6 1.2l1.2-1.6a1 1 0 00-.2-1.4zM23.811 7.8a1 1 0 00-1.266-.632l-1.93.643a1 1 0 00.633 1.9l1.93-.644a1 1 0 00.633-1.267zM22.942 15.9l-1.73-1.039a1 1 0 00-1.029 1.715l1.73 1.038a.991.991 0 00.513.143 1 1 0 00.516-1.857zM17.753 19.435a1 1 0 10-1.627 1.165l1.166 1.632a1 1 0 101.627-1.162z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});