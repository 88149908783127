define("ember-svg-jar/inlined/paralympics-discus-throwing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paralympics-discus-throwing</title><path d=\"M20.5 4.944a1.5 1.5 0 00-1.949-.836L6.837 8.792a.316.316 0 01-.314-.042.326.326 0 01-.13-.287A1.5 1.5 0 103.4 8.235a3.327 3.327 0 004.536 3.348l2.4-.96a.5.5 0 01.685.464v1.585a.5.5 0 01-.146.354L8.963 14.94a1.5 1.5 0 002.121 2.121l1.516-1.518 2.445 2.1A1.5 1.5 0 0017 15.362l-2.8-2.4a.5.5 0 01-.175-.38V9.487a.5.5 0 01.315-.464l5.327-2.13a1.5 1.5 0 00.833-1.949z\"/><circle cx=\"5.641\" cy=\"3.75\" r=\"1.75\"/><circle cx=\"11.273\" cy=\"2.5\" r=\"2.5\"/><path d=\"M6.718 17.758l-1.929 1.761a2.629 2.629 0 002.374 4.427 1 1 0 00-.393-1.961.62.62 0 01-.57-.185.614.614 0 01-.031-.83l1.895-1.731a1 1 0 00-1.347-1.477zM16.645 21.885a.628.628 0 01-.946-.73l.5-1.3a1 1 0 00-1.869-.713l-.5 1.3a2.628 2.628 0 003.97 3.076 1 1 0 00-1.155-1.633z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});