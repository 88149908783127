define("ember-svg-jar/inlined/real-estate-message-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-message-building</title><path d=\"M12 .67C5.245.67 0 5.268 0 10.92a9.27 9.27 0 003.055 6.78l-1.98 3.4a1.487 1.487 0 001.873 2.116l5.779-2.477A12.5 12.5 0 0012 21.17c6.755 0 12-4.6 12-10.25S18.755.67 12 .67zm0 18.5a10.669 10.669 0 01-3.083-.457 1 1 0 00-.662.045l-3.975 1.7a.251.251 0 01-.286-.065.249.249 0 01-.028-.291L5.2 17.993a1 1 0 00-.233-1.279A7.477 7.477 0 012 10.92c0-4.549 4.348-8.25 10-8.25s10 3.7 10 8.25-4.348 8.25-10 8.25z\"/><path d=\"M12.265 4.746a.5.5 0 00-.53 0l-4 2.5a.5.5 0 00-.235.424v9a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-2a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v2a.5.5 0 00.5.5H16a.5.5 0 00.5-.5v-9a.5.5 0 00-.235-.424zM13.5 12.67h-3a.75.75 0 010-1.5h3a.75.75 0 010 1.5zm0-3h-3a.75.75 0 010-1.5h3a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});