define("ember-svg-jar/inlined/clothes-design-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>clothes-design-pin</title><path d=\"M16.875 0a5.5 5.5 0 00-5.5 5.5v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2a2.5 2.5 0 015 0v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2a5.5 5.5 0 00-5.5-5.5z\"/><path d=\"M22.125 10a.5.5 0 00-.5-.5h-1.5a.5.5 0 00-.5.5v5.516a.25.25 0 01-.335.235 4.153 4.153 0 00-1.415-.251 4.25 4.25 0 00-3.744 2.246.25.25 0 01-.4.05L3.8 6.907a1.25 1.25 0 00-1.849 1.685l12.783 14.021A4.315 4.315 0 0017.875 24a4.255 4.255 0 004.25-4.25zm-4.25 11.5a1.75 1.75 0 111.75-1.751 1.752 1.752 0 01-1.75 1.751z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});