define("ember-svg-jar/inlined/pathfinder-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pathfinder-outline</title><path d=\"M23 11a1 1 0 00-1 1v9a1 1 0 01-1 1h-9a1 1 0 000 2h9a3 3 0 003-3v-9a1 1 0 00-1-1zM13 1a1 1 0 00-1-1H3a3 3 0 00-3 3v9a1 1 0 002 0V3a1 1 0 011-1h9a1 1 0 001-1z\"/><rect x=\"6\" y=\"6\" width=\"12\" height=\"12\" rx=\"1.5\" ry=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});