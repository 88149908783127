define("ember-svg-jar/inlined/button-rewind", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-rewind</title><path d=\"M12 24A12 12 0 100 12a12.013 12.013 0 0012 12zM5.721 11.845l5.085-2.739a.743.743 0 011.135.679v.727a.251.251 0 00.122.215.248.248 0 00.246 0l3.02-1.626a.743.743 0 011.135.679v5.428a.77.77 0 01-.36.686.715.715 0 01-.368.1.852.852 0 01-.407-.109l-3.02-1.625a.249.249 0 00-.368.22v.727a.768.768 0 01-.361.686.777.777 0 01-.775-.008l-5.083-2.731a.715.715 0 010-1.309z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});