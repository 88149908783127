define("ember-svg-jar/inlined/marine-mammal-seal-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>marine-mammal-seal-body</title><path d=\"M15.384 9.493h-3.509A22.8 22.8 0 0010.6 3.556C9.691 1.3 8.352.243 6.384.243a4.107 4.107 0 00-3.91 3.162A2.48 2.48 0 00.847 5.574a.99.99 0 00-.25.47c-1.553 7.219 1.145 10.584 1.26 10.723a1 1 0 001.68-.22l.553-1.206a4.626 4.626 0 00.747 1.2 4.745 4.745 0 00-2.946 3.623.5.5 0 00.269.529c3.627 1.813 6.746-.909 7.659-1.837a.252.252 0 01.2-.074 32.4 32.4 0 004.617.111.253.253 0 00.187-.1 6.1 6.1 0 013.465-2.214.749.749 0 01.277 1.473c-1.882.377-3.756 2.644-2.9 3.4.4.357 1.084.358 2.149 0a6.6 6.6 0 002.116-1.4c-.074 2.019.627 3.211 1.291 3.561a1.253 1.253 0 001.241-.054c.939-.677 1.415-3.307 1.415-7.821.007-4.138-2.853-6.245-8.493-6.245zM5.914 5a1 1 0 111-1 1 1 0 01-1 1zM2.236 8.352a.25.25 0 01.388-.178l1.77 1.2a.25.25 0 01.087.311L2.9 13.147a.25.25 0 01-.471-.047 13.749 13.749 0 01-.193-4.748z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});