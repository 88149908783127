define("ember-svg-jar/inlined/database-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-alternate</title><path d=\"M22.279 12.733V4.915C22.28 1.727 16.982.006 12 .006S1.725 1.723 1.713 4.905V12.733c0 2.926 4.464 4.615 9.053 4.873a.25.25 0 01.236.249v.245a.25.25 0 01-.152.23 2.956 2.956 0 00-1.557 1.557.25.25 0 01-.23.152H5.648a1 1 0 000 2h3.413a.25.25 0 01.23.152 2.937 2.937 0 005.417 0 .25.25 0 01.23-.152h3.414a1 1 0 000-2h-3.434a.218.218 0 01-.2-.134 2.951 2.951 0 00-1.564-1.575.25.25 0 01-.154-.23v-.246a.25.25 0 01.236-.25c4.585-.258 9.043-1.947 9.043-4.871zM3.711 8.342a.251.251 0 01.123-.216.254.254 0 01.249 0A17.268 17.268 0 0012 9.823a17.264 17.264 0 007.912-1.7.25.25 0 01.371.218v.48c0 1.187-3.227 2.91-8.284 2.91S3.711 10.01 3.711 8.823zM12 2.006c5.054 0 8.279 1.72 8.284 2.906v.009c-.01 1.185-3.234 2.9-8.283 2.9S3.712 6.1 3.712 4.915 6.939 2.006 12 2.006zM3.711 12.733v-.482a.248.248 0 01.123-.215.251.251 0 01.249 0A17.264 17.264 0 0012 13.733a17.268 17.268 0 007.913-1.7.25.25 0 01.371.218v.482c0 1.186-3.227 2.909-8.284 2.909s-8.289-1.723-8.289-2.909zM12 21.994a.955.955 0 11.954-.954.955.955 0 01-.954.954zM23.75 21.04a1 1 0 00-1-1h-.977a1 1 0 000 2h.977a1 1 0 001-1zM1.25 20.04a1 1 0 000 2h.977a1 1 0 100-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});