define("ember-svg-jar/inlined/gift-electronic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gift-electronic</title><path d=\"M15 18a1 1 0 00-1 1 .5.5 0 01-.5.5h-11A.5.5 0 012 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5 1 1 0 002 0V3a3 3 0 00-3-3H3a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3v-2a1 1 0 00-1-1z\"/><path d=\"M16.768 5.859a1 1 0 10-1.536 1.282l.376.451a.25.25 0 01-.18.41A1.5 1.5 0 0014 9.5v2a.5.5 0 00.5.5H18a.5.5 0 00.5-.5V8.3a1 1 0 00-.232-.641zM22.572 8a.25.25 0 01-.18-.41l.376-.451a1 1 0 10-1.536-1.282l-1.5 1.8a1 1 0 00-.232.643v3.2a.5.5 0 00.5.5h3.5a.5.5 0 00.5-.5v-2A1.5 1.5 0 0022.572 8zM23.5 13H20a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h2.375A1.636 1.636 0 0024 15.357V13.5a.5.5 0 00-.5-.5zM18 13h-3.5a.5.5 0 00-.5.5v1.857A1.636 1.636 0 0015.625 17H18a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5zM11.5 13.5H8a1 1 0 000 2h3.5a1 1 0 000-2zM11.5 10h-5a1 1 0 000 2h5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});