define("ember-svg-jar/inlined/phone-action-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-refresh</title><path d=\"M16.191 6.538a.5.5 0 00-.545.108l-.486.487a5 5 0 10.748 6.986 1 1 0 10-1.562-1.248 3.01 3.01 0 11-.615-4.309l-1.085 1.084A.5.5 0 0013 10.5h3a.5.5 0 00.5-.5V7a.5.5 0 00-.309-.462z\"/><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});