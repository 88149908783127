define("ember-svg-jar/inlined/send-email-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>send-email-2-alternate</title><path d=\"M23.456.244a1.049 1.049 0 00-1.123-.136L1.271 11.1a1.981 1.981 0 00-.139 3.434l5.939 3.736v4.121a1.491 1.491 0 002.545 1.054l2.193-2.194 3.882 2.442a1.983 1.983 0 002.983-1.215l5.094-21.25a1.063 1.063 0 00-.312-.984zM17.3 4.989a.25.25 0 01.3.387L8.023 16.255a.251.251 0 01-.323.045l-5.11-3.215a.25.25 0 01.018-.433zm-8.23 16.17v-1.634l1 .631zm7.766.5a.251.251 0 01-.377.153l-6.614-4.16a.25.25 0 01-.114-.176.246.246 0 01.06-.2L20.226 5.425a.25.25 0 01.431.224z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});