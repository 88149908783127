define("ember-svg-jar/inlined/settings-toggle-horizontal-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>settings-toggle-horizontal-alternate</title><path d=\"M22.044 0H1.956A1.959 1.959 0 000 1.958v20.086A1.959 1.959 0 001.956 24h20.088A1.959 1.959 0 0024 22.044V1.958A1.959 1.959 0 0022.044 0zM21.5 21.959L2.544 22a.5.5 0 01-.5-.5L2 2.5a.5.5 0 01.5-.5l19-.041a.5.5 0 01.5.5v19a.5.5 0 01-.5.5z\"/><path d=\"M7.967 10.779h8.066a3.422 3.422 0 100-6.844H7.967a3.422 3.422 0 100 6.844zm0-5.162a1.711 1.711 0 11-1.711 1.711 1.712 1.712 0 011.711-1.711zM7.967 20.067h8.066a3.422 3.422 0 100-6.844H7.967a3.422 3.422 0 100 6.844zm8.066-5.1a1.711 1.711 0 11-1.711 1.711 1.712 1.712 0 011.711-1.715z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});