define("ember-svg-jar/inlined/microphone-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>microphone-off</title><path d=\"M12 20a7.508 7.508 0 01-7.5-7.5V10a1 1 0 00-2 0v2.5a9.514 9.514 0 008.282 9.422.249.249 0 01.218.248V23a1 1 0 002 0v-.83a.249.249 0 01.218-.248 9.433 9.433 0 003.859-1.4.25.25 0 00.043-.388l-1.091-1.091a.25.25 0 00-.3-.04A7.448 7.448 0 0112 20z\"/><path d=\"M6.427 9.447A.25.25 0 006 9.623V12.5a6 6 0 008.471 5.467.25.25 0 00.074-.4zM23.707 22.3l-3.952-3.952a.248.248 0 01-.026-.322A9.453 9.453 0 0021.5 12.5V10a1 1 0 00-2 0v2.5a7.459 7.459 0 01-1.157 4 .249.249 0 01-.184.114.246.246 0 01-.2-.071l-.728-.727a.251.251 0 01-.039-.3A5.963 5.963 0 0018 12.5V6A6 6 0 006.244 4.309a.251.251 0 01-.417.106L1.707.3A1.013 1.013 0 00.293.3a1 1 0 000 1.414l22 22 .007.007a1 1 0 001.4-1.425z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});