define("ember-svg-jar/inlined/car-tool-lug-wrench.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-tool-lug-wrench</title><path d=\"M10 2.943v4.826a.5.5 0 00.672.47 3.809 3.809 0 012.656 0 .5.5 0 00.672-.47V2.943a3.3 3.3 0 001.337-1.593A1 1 0 0014.4 0H9.6a1 1 0 00-.937 1.35A3.3 3.3 0 0010 2.943zM8 12a3.851 3.851 0 01.239-1.328.5.5 0 00-.47-.672H2.943A3.307 3.307 0 001.35 8.663 1 1 0 000 9.6v4.8a1 1 0 001 1 1.033 1.033 0 00.35-.062A3.309 3.309 0 002.943 14h4.826a.5.5 0 00.47-.672A3.851 3.851 0 018 12zM23.57 8.778a1.007 1.007 0 00-.92-.116A3.309 3.309 0 0021.057 10h-4.826a.5.5 0 00-.47.672 3.809 3.809 0 010 2.656.5.5 0 00.47.672h4.826a3.307 3.307 0 001.593 1.337 1.01 1.01 0 00.35.063 1 1 0 001-1V9.6a1 1 0 00-.43-.822zM14 21.057v-4.826a.5.5 0 00-.672-.47 3.809 3.809 0 01-2.656 0 .5.5 0 00-.672.47v4.826a3.3 3.3 0 00-1.337 1.593A1 1 0 009.6 24h4.8a1 1 0 00.937-1.35A3.3 3.3 0 0014 21.057z\"/><circle cx=\"12\" cy=\"12\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});