define("ember-svg-jar/inlined/single-man-actions-key.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-key</title><path d=\"M.619 18.062H2.41a.246.246 0 01.246.227l.444 5.257a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h.844a.249.249 0 00.237-.176 5.835 5.835 0 011.146-2.1.245.245 0 00.059-.16V14.1a5.443 5.443 0 10-10.886 0v3.464a.494.494 0 00.495.498z\"/><path d=\"M5.567 0a4.206 4.206 0 104.206 4.206A4.211 4.211 0 005.567 0zm0 6.433A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM15.421 24a4.4 4.4 0 004.223-5.669.245.245 0 01.062-.247l4.025-4.016a.5.5 0 00.145-.35v-1.96a.494.494 0 00-.5-.495h-1.953a.491.491 0 00-.339.135l-4.146 3.916a.248.248 0 01-.242.057A4.408 4.408 0 1015.421 24zm-1.569-4.56a1.717 1.717 0 11.5 1.215 1.708 1.708 0 01-.5-1.215z\"/><path d=\"M.619 18.062H2.41a.246.246 0 01.246.227l.444 5.257a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h.844a.249.249 0 00.237-.176 5.835 5.835 0 011.146-2.1.245.245 0 00.059-.16V14.1a5.443 5.443 0 10-10.886 0v3.464a.494.494 0 00.495.498z\"/><path d=\"M5.567 0a4.206 4.206 0 104.206 4.206A4.211 4.211 0 005.567 0zm0 6.433A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM15.421 24a4.4 4.4 0 004.223-5.669.245.245 0 01.062-.247l4.025-4.016a.5.5 0 00.145-.35v-1.96a.494.494 0 00-.5-.495h-1.953a.491.491 0 00-.339.135l-4.146 3.916a.248.248 0 01-.242.057A4.408 4.408 0 1015.421 24zm-1.569-4.56a1.717 1.717 0 11.5 1.215 1.708 1.708 0 01-.5-1.215z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});