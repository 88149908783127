define("ember-svg-jar/inlined/fruit-kiwi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fruit-kiwi</title><path d=\"M12 3.75A8.25 8.25 0 1020.25 12 8.259 8.259 0 0012 3.75zM7.485 7.583a1.827 1.827 0 013.07.8l.6 2.247a.451.451 0 01-.544.555l-2.421-.6a1.828 1.828 0 01-.706-3zm3.694 5.8l-.607 2.42a1.827 1.827 0 11-2.195-2.372l2.25-.594a.45.45 0 01.552.546zm1.649-2.76l.625-2.416a1.827 1.827 0 112.178 2.393l-2.254.578a.451.451 0 01-.549-.55zm3.653 5.826a1.828 1.828 0 01-3.065-.823l-.585-2.252a.451.451 0 01.548-.551l2.417.617a1.828 1.828 0 01.685 3.009z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 21.75A9.75 9.75 0 1121.75 12 9.761 9.761 0 0112 21.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});