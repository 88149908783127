define("ember-svg-jar/inlined/appliances-blender", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>appliances-blender</title><path d=\"M16.155 21a.25.25 0 00.223-.362l-1.4-2.809A1.493 1.493 0 0013.632 17H7.868a1.493 1.493 0 00-1.342.829l-1.4 2.809a.25.25 0 00.219.362zM5.25 22a.5.5 0 00-.5.5 1.5 1.5 0 001.5 1.5h9a1.5 1.5 0 001.5-1.5.5.5 0 00-.5-.5zM19.25 8a3 3 0 00-3-3h-.3a.25.25 0 01-.249-.274A7.251 7.251 0 0015.75 4a2 2 0 00-2-2h-1.5a.25.25 0 01-.25-.25v-.5a1.25 1.25 0 00-2.5 0v.5a.25.25 0 01-.25.25h-1.5a2 2 0 00-2 2c-.036.162.9 9.383.9 9.383a2.6 2.6 0 002.58 2.367h3.04a2.6 2.6 0 002.49-1.886.5.5 0 01.481-.364h1.009a3 3 0 003-3zM7.379 5.558A.5.5 0 017.875 5h5.748a.5.5 0 01.5.557l-.259 2.25a.5.5 0 01-.5.443H8.136a.5.5 0 01-.5-.442zM17.25 10.5a1 1 0 01-1 1h-.95a.252.252 0 01-.185-.082.25.25 0 01-.063-.192l.393-4A.251.251 0 0115.691 7h.559a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});