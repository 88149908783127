define("ember-svg-jar/inlined/audio-file-wav-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file-wav-1</title><path d=\"M23.707 5.707L18.293.293A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.75V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707z\"/><path d=\"M9.5 12.125a.877.877 0 00-.875.875v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V13a.877.877 0 00-.875-.875z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zM6.625 13.264a10.143 10.143 0 01-1.066 4.515.626.626 0 01-.559.346.527.527 0 01-.068 0 .624.624 0 01-.538-.47s-.237-.924-.272-1.088-.208-.143-.243 0l-.273 1.089a.624.624 0 01-.538.47.613.613 0 01-.627-.342 10.143 10.143 0 01-1.066-4.515V11.5a.625.625 0 011.25 0v1.764a8.852 8.852 0 00.13 1.506c.036.207.21.234.271.047l.368-1.468a.624.624 0 011.212 0l.356 1.424c.05.207.247.2.283 0a8.852 8.852 0 00.13-1.506V11.5a.625.625 0 011.25 0zm5 4.236a.625.625 0 01-1.25 0v-2.125a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-4.375a2.21 2.21 0 011.9-2.238A2.127 2.127 0 0111.625 13zm5-4.5A8.176 8.176 0 0115 17.875a.625.625 0 01-1 0A8.176 8.176 0 0112.375 13v-1.5a.625.625 0 011.25 0V13a6.916 6.916 0 00.674 2.966.251.251 0 00.4.011A6.916 6.916 0 0015.375 13v-1.5a.625.625 0 011.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});