define("ember-svg-jar/inlined/crafts-necklace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crafts-necklace</title><path d=\"M17.438 13.4v-2.8a2.068 2.068 0 00-.864-1.678l-.492-.351a.5.5 0 01-.1-.723 36.443 36.443 0 003.92-6.424A1 1 0 0018.1.575a34.665 34.665 0 01-3.782 6.167.5.5 0 01-.674.086l-.767-.548a1.5 1.5 0 00-1.744 0l-.767.548a.5.5 0 01-.674-.086A34.665 34.665 0 015.905.575a1 1 0 00-1.81.851A36.475 36.475 0 008.016 7.85a.5.5 0 01-.1.723l-.494.352a2.065 2.065 0 00-.859 1.675v2.8a2.067 2.067 0 00.863 1.678l3.7 2.644a1.5 1.5 0 001.744 0l3.7-2.645a2.066 2.066 0 00.868-1.677zm-3-.741a.5.5 0 01-.21.407l-1.937 1.384a.5.5 0 01-.582 0l-1.937-1.385a.5.5 0 01-.209-.407v-1.315a.5.5 0 01.209-.407l1.937-1.384a.5.5 0 01.582 0l1.937 1.384a.5.5 0 01.21.407zM4.124 15.836a1.4 1.4 0 00-.458 1.823L5 19.876a.5.5 0 11-.857.516l-1.352-2.25a2.472 2.472 0 01.125-2.485.5.5 0 00.084-.276V12.5a1.5 1.5 0 00-3 0v5.171a4.5 4.5 0 00.986 2.812l2.664 3.329a.5.5 0 00.39.188H8a.5.5 0 00.5-.5v-3.01a4.492 4.492 0 00-.757-2.5c-1.887-1.965-2.477-2.916-3.619-2.154zM22.5 11a1.5 1.5 0 00-1.5 1.5v2.881a.5.5 0 00.084.276 2.472 2.472 0 01.125 2.485l-1.352 2.25a.5.5 0 11-.857-.516l1.334-2.217a1.4 1.4 0 00-.458-1.823c-1.142-.762-1.732.189-3.619 2.157a4.492 4.492 0 00-.757 2.5V23.5a.5.5 0 00.5.5h3.96a.5.5 0 00.39-.188l2.664-3.329A4.5 4.5 0 0024 17.671V12.5a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});