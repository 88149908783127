define("ember-svg-jar/inlined/hair-dress-bobby-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hair-dress-bobby-pin</title><path d=\"M4.668 23.924a1.25 1.25 0 01-.884-2.134L19.462 6.112a2.073 2.073 0 000-2.932 2.075 2.075 0 00-2.931 0l-.6.6v2.18a1.553 1.553 0 01-1.551 1.552h-1.449V8.96a1.553 1.553 0 01-1.551 1.552H9.931v1.448a1.553 1.553 0 01-1.551 1.552H6.2l-2.635 2.634A1.25 1.25 0 011.8 14.378l2.854-2.853a1.736 1.736 0 011.237-.513h1.54v-1.25a1.752 1.752 0 011.75-1.75h1.25v-1.25a1.752 1.752 0 011.75-1.75h1.25V3.469a1.734 1.734 0 01.513-1.237l.818-.819a4.573 4.573 0 116.467 6.467L5.552 23.558a1.246 1.246 0 01-.884.366z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});