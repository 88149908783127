define("ember-svg-jar/inlined/bridge-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bridge-1</title><path d=\"M23.3 9.045c-.354-.41-.711-.849-1.083-1.323C20.119 5.046 17.5 1.717 12 1.717S3.875 5.061 1.76 7.749c-.366.464-.715.893-1.061 1.3A1.519 1.519 0 000 10.308v8.074a.5.5 0 00.545.5l.535.012c.648.079 1.635-.775 1.917-1.132a2.491 2.491 0 011.229-.837.5.5 0 00.313-.28 6.5 6.5 0 015.961-3.928h3a6.5 6.5 0 015.924 3.839.5.5 0 00.383.289 2.491 2.491 0 011.759 1.142s.662.96 1.359.864l.531-.012a.5.5 0 00.544-.5v-8.031a1.519 1.519 0 00-.7-1.263zM5.25 8.308a.5.5 0 01-.5.5h-1.4a.251.251 0 01-.2-.406c.534-.674 1.072-1.337 1.665-1.961a.25.25 0 01.431.173zm4 0a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5V5.043a.5.5 0 01.232-.422 8.727 8.727 0 011.59-.8.5.5 0 01.678.468zm4 0a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5V3.749a.5.5 0 01.459-.5c.255-.02.518-.033.791-.033s.535.013.791.033a.5.5 0 01.459.5zm4 0a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5V4.285a.5.5 0 01.677-.468 8.724 8.724 0 011.591.8.5.5 0 01.232.423zm3.628.358a.251.251 0 01-.225.142h-1.4a.5.5 0 01-.5-.5v-1.7a.25.25 0 01.431-.172c.591.623 1.134 1.293 1.668 1.969a.251.251 0 01.026.261z\"/><path d=\"M22.943 20.239a3.41 3.41 0 01-2.654-1.575 1.043 1.043 0 00-1.719.029 3.371 3.371 0 01-2.755 1.555 2.809 2.809 0 01-2.415-1.486 1.043 1.043 0 00-1.717-.156 4.311 4.311 0 01-3.111 1.642 3.568 3.568 0 01-2.767-1.584 1.04 1.04 0 00-1.638-.091c-.013.018-1.463 1.785-3.116 1.707a1 1 0 10-.1 2c.075 0 .148.006.222.006a5.61 5.61 0 003.407-1.341.5.5 0 01.635.01 5.141 5.141 0 003.361 1.293 5.736 5.736 0 003.481-1.235.5.5 0 01.639.027 4.535 4.535 0 003.119 1.208 5.1 5.1 0 003.306-1.212.5.5 0 01.643 0 4.664 4.664 0 003.292 1.2 1 1 0 10-.113-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});