define("ember-svg-jar/inlined/multiple-actions-up-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-up-down</title><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.183 11.943a.249.249 0 00.408.11A7.961 7.961 0 0118 9.938h.069a.25.25 0 00.215-.386A4.533 4.533 0 0010.9 9.26a.25.25 0 00.005.311 6.987 6.987 0 011.278 2.372zM10.97 14.129a.247.247 0 00.03-.119V14a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.065a.249.249 0 00.245-.245 7.9 7.9 0 01.97-3.626z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M14.594 23.791a.5.5 0 00.813 0l2.5-3.5a.5.5 0 00-.407-.791h-1a.25.25 0 01-.25-.25V14a1.25 1.25 0 00-2.5 0v5.25a.25.25 0 01-.25.25h-1a.5.5 0 00-.406.791zM21.407 12.959a.519.519 0 00-.813 0l-2.5 3.5a.5.5 0 00.406.791h1a.25.25 0 01.25.25v5.25a1.25 1.25 0 002.5 0V17.5a.25.25 0 01.25-.25h1a.5.5 0 00.407-.791z\"/><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.183 11.943a.249.249 0 00.408.11A7.961 7.961 0 0118 9.938h.069a.25.25 0 00.215-.386A4.533 4.533 0 0010.9 9.26a.25.25 0 00.005.311 6.987 6.987 0 011.278 2.372zM10.97 14.129a.247.247 0 00.03-.119V14a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.065a.249.249 0 00.245-.245 7.9 7.9 0 01.97-3.626z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M14.594 23.791a.5.5 0 00.813 0l2.5-3.5a.5.5 0 00-.407-.791h-1a.25.25 0 01-.25-.25V14a1.25 1.25 0 00-2.5 0v5.25a.25.25 0 01-.25.25h-1a.5.5 0 00-.406.791zM21.407 12.959a.519.519 0 00-.813 0l-2.5 3.5a.5.5 0 00.406.791h1a.25.25 0 01.25.25v5.25a1.25 1.25 0 002.5 0V17.5a.25.25 0 01.25-.25h1a.5.5 0 00.407-.791z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});