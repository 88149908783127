define("ember-svg-jar/inlined/landmark-chrysler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-chrysler</title><path d=\"M7.75 10.5h1.5a.25.25 0 00.25-.25V10a2.5 2.5 0 015 0v.25a.25.25 0 00.25.25h1.5a.25.25 0 00.25-.25V10a4.486 4.486 0 00-2.369-3.942.249.249 0 01-.131-.22V4a1.987 1.987 0 00-.889-1.652A.253.253 0 0113 2.14V1a1 1 0 00-2 0v1.14a.253.253 0 01-.111.208A1.987 1.987 0 0010 4v1.838a.249.249 0 01-.131.22A4.486 4.486 0 007.5 10v.25a.25.25 0 00.25.25zM6 14.5H4A1.5 1.5 0 002.5 16v7.5a.5.5 0 00.5.5h4a.5.5 0 00.5-.5V16A1.5 1.5 0 006 14.5zm-.5 6a.5.5 0 01-1 0v-4a.5.5 0 011 0z\"/><path d=\"M16.5 11.75a.25.25 0 00-.25-.25H14a.5.5 0 01-.5-.5v-1a1.5 1.5 0 00-3 0v1a.5.5 0 01-.5.5H7.75a.25.25 0 00-.25.25v2.142a.249.249 0 00.09.192A2.484 2.484 0 018.5 16v7.711a.25.25 0 00.25.289h6.5a.25.25 0 00.247-.289L15.5 16a2.484 2.484 0 01.91-1.916.249.249 0 00.09-.192zM11 21a.5.5 0 01-1 0v-7a.5.5 0 011 0zm3 0a.5.5 0 01-1 0v-7a.5.5 0 011 0zM20 14.5h-2a1.5 1.5 0 00-1.5 1.5v7.5a.5.5 0 00.5.5h4a.5.5 0 00.5-.5V16a1.5 1.5 0 00-1.5-1.5zm-.5 6a.5.5 0 01-1 0v-4a.5.5 0 011 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});