define("ember-svg-jar/inlined/cap-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cap-4</title><path d=\"M23.972 17.206A1.514 1.514 0 0023 16.08l-4.726-1.48a.491.491 0 00-.289 0l-9.193 2.66a.5.5 0 00-.047.945l4.84 1.935a9.838 9.838 0 003.635.671 9.461 9.461 0 006.3-2.211 1.508 1.508 0 00.452-1.394zM17.906 12.255A9.14 9.14 0 007.638 5.774a1.982 1.982 0 00.088-.585 2 2 0 10-3.114 1.66A9.156 9.156 0 00.113 16.2a1.663 1.663 0 00.74 1.151 1.7 1.7 0 001.39.212l15.151-4.38a.749.749 0 00.512-.928zM11.1 13.281a.5.5 0 01-.3.25l-4.3 1.243a.5.5 0 01-.621-.348 10.4 10.4 0 01.1-6.435.5.5 0 01.274-.275c.226-.092.464-.176.714-.25a.505.505 0 01.421.066 10.635 10.635 0 013.742 5.356.5.5 0 01-.03.393z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});