define("ember-svg-jar/inlined/keyboard-eject", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-eject</title><path d=\"M19.5.5h-15a4.012 4.012 0 00-4 4v15a4.011 4.011 0 004 4h15a4.011 4.011 0 004-4v-15a4.012 4.012 0 00-4-4zM5.289 11.155l6.346-6.768a.5.5 0 01.73 0l6.346 6.768a.5.5 0 01-.365.842H5.654a.5.5 0 01-.365-.842zM19.5 18a.5.5 0 01-.5.5H5a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h14a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});