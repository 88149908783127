define("ember-svg-jar/inlined/content-pen-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-pen-1</title><path d=\"M22.573 2.2a3.082 3.082 0 00-3.485-.552.909.909 0 00-.111-.164l-.359-.351a3.07 3.07 0 00-4.26 0L9.016 6.35A1 1 0 009 7.764a1.012 1.012 0 001.414.018l5.343-5.213a1.058 1.058 0 011.467 0l.34.332L15.414 5a.484.484 0 000 .7l3.58 3.493a.515.515 0 00.716 0l2.863-2.794a2.91 2.91 0 000-4.199z\"/><path d=\"M14.338 6.744a.512.512 0 00-.715 0l-8.829 8.615a1.456 1.456 0 000 2.1l2.147 2.1a1.547 1.547 0 002.148 0l8.83-8.616a.486.486 0 000-.7zM4.758 19.584L3.309 18.17a1 1 0 10-1.4 1.431l.7.682-1.77 1.728a1 1 0 00-.018 1.414 1.013 1.013 0 001.414.018l1.808-1.764.733.716a1 1 0 101.4-1.431z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});