define("ember-svg-jar/inlined/home-to-cloud-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>home-to-cloud-sync</title><path d=\"M23.792 17.771a.986.986 0 00-.206-1.379L17.6 11.958a.986.986 0 00-1.183.008L10.579 16.4a.985.985 0 001.192 1.568l.16-.121a.251.251 0 01.4.2V23.1a.739.739 0 00.739.74h1.971a.739.739 0 00.739-.74v-3.453a1.232 1.232 0 012.463 0V23.1a.739.739 0 00.739.74h1.971a.739.739 0 00.739-.74v-5.161a.251.251 0 01.4-.2l.322.238a.984.984 0 001.378-.2zM3.378 10.533A3.746 3.746 0 01.9 9.565 3.093 3.093 0 01.015 7.3a3.235 3.235 0 012.7-3.192.249.249 0 00.2-.178 5.175 5.175 0 019.307-1.43.251.251 0 00.173.109 3.935 3.935 0 012.165 1.068 4 4 0 01-2.336 6.848 1.083 1.083 0 01-.127.008H3.378zm-.13-4.5a1.268 1.268 0 10.145 2.528h8.626a2.019 2.019 0 00-.294-4.024.927.927 0 01-.927-.563A3.206 3.206 0 004.7 5.154a.985.985 0 01-1.186.907 1.3 1.3 0 00-.266-.027zM10.361 20.88a.986.986 0 00-.985-.986H7.405a.987.987 0 01-.986-.985V16.2a.25.25 0 01.25-.25h.736a.986.986 0 00.7-1.683L6.131 12.3a.986.986 0 00-1.394 0l-1.97 1.97a.986.986 0 00.7 1.683H4.2a.25.25 0 01.25.25v2.707a2.959 2.959 0 002.956 2.956h1.97a.985.985 0 00.985-.986z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});