define("ember-svg-jar/inlined/folder-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-1</title><path d=\"M18 8h-7a.5.5 0 01-.4-.2l-.9-1.2A1.508 1.508 0 008.5 6H6a1.5 1.5 0 00-1.5 1.5v9A1.5 1.5 0 006 18h12a1.5 1.5 0 001.5-1.5v-7A1.5 1.5 0 0018 8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});