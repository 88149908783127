define("ember-svg-jar/inlined/video-game-logo-creeper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-logo-creeper</title><path d=\"M13.75 11.5V10a.25.25 0 00-.25-.25h-3a.25.25 0 00-.25.25v1.5A1.252 1.252 0 019 12.75H7.5a.25.25 0 00-.25.25v5.5a.25.25 0 00.25.25h1a.25.25 0 00.25-.25v-1A1.252 1.252 0 0110 16.25h4a1.252 1.252 0 011.25 1.25v1a.25.25 0 00.25.25h1a.25.25 0 00.25-.25V13a.25.25 0 00-.25-.25H15a1.252 1.252 0 01-1.25-1.25z\"/><rect x=\"5.25\" y=\"5.25\" width=\"3.5\" height=\"3\" rx=\".25\" ry=\".25\"/><rect x=\"15.25\" y=\"5.25\" width=\"3.5\" height=\"3\" rx=\".25\" ry=\".25\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-2.25 8.5A1.252 1.252 0 0119 9.75h-3.5a.25.25 0 00-.25.25v1a.25.25 0 00.25.25H17a1.252 1.252 0 011.25 1.25V19A1.252 1.252 0 0117 20.25h-2A1.252 1.252 0 0113.75 19v-1a.25.25 0 00-.25-.25h-3a.25.25 0 00-.25.25v1A1.252 1.252 0 019 20.25H7A1.252 1.252 0 015.75 19v-6.5A1.252 1.252 0 017 11.25h1.5a.25.25 0 00.25-.25v-1a.25.25 0 00-.25-.25H5A1.252 1.252 0 013.75 8.5V5A1.252 1.252 0 015 3.75h4A1.252 1.252 0 0110.25 5v3a.25.25 0 00.25.25h3a.25.25 0 00.25-.25V5A1.252 1.252 0 0115 3.75h4A1.252 1.252 0 0120.25 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});