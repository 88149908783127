define("ember-svg-jar/inlined/toys-train", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toys-train</title><path d=\"M5.75 16a4 4 0 104 4 4 4 0 00-4-4zm0 5.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/><path d=\"M22.75 0h-10a1.252 1.252 0 00-1.25 1.25v2a1.249 1.249 0 00.631 1.086 1.235 1.235 0 00.441.151.5.5 0 01.428.5v5.763a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5V9.62a.5.5 0 01.222-.42A1.747 1.747 0 007.5 7.75v-1A1.752 1.752 0 005.75 5h-4A1.752 1.752 0 000 6.75v1A1.747 1.747 0 00.778 9.2.5.5 0 011 9.62v1.51a.5.5 0 01-.222.416A1.747 1.747 0 000 13v6a1.74 1.74 0 00.294.971.25.25 0 00.458-.132 5 5 0 119.993.388.5.5 0 00.5.523h1.511a.5.5 0 00.5-.523 5 5 0 018.8-3.461.25.25 0 00.44-.163V4.982a.5.5 0 01.428-.5 1.23 1.23 0 00.44-.151A1.25 1.25 0 0024 3.25v-2A1.252 1.252 0 0022.75 0zm-3 10.75a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V7.5a2 2 0 014 0z\"/><path d=\"M18.25 16a4 4 0 104 4 4 4 0 00-4-4zm0 5.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});