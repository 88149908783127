define("ember-svg-jar/inlined/messages-bubble-square-forward-all-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-forward-all-alternate</title><path d=\"M10.576 14.472a.251.251 0 00-.117-.472H7.5a1 1 0 00-.752.342L5 16.339V15a1 1 0 00-1-1H2.25a.25.25 0 01-.25-.25V2.25A.25.25 0 012.25 2h18a.25.25 0 01.25.25v9.732a.249.249 0 00.106.205l1.5 1.06a.25.25 0 00.394-.2V2a2 2 0 00-2-2H2a2 2 0 00-2 2v12.053A1.953 1.953 0 002 16h1v3a1 1 0 001.753.659L7.954 16H8.4a.248.248 0 00.175-.071 8.626 8.626 0 012.001-1.457z\"/><path d=\"M19.289 13.092a.5.5 0 00-.789.408v1.748l-2.689-2.139A.5.5 0 0015 13.5v1.329c-7.906.335-8 8.587-8 8.671a.5.5 0 00.993.083c.034-.2.884-4.79 7.007-5.027v1.763a.5.5 0 00.837.37l2.663-2.422v2.052a.5.5 0 00.815.389l4.5-3.637a.5.5 0 00-.026-.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});