define("ember-svg-jar/inlined/bed-bunk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bed-bunk</title><path d=\"M23 2a1 1 0 00-1 1v1.25a.25.25 0 01-.25.25h-3.5a.25.25 0 01-.25-.25V3a1 1 0 00-2 0v20a1 1 0 002 0v-1.25a.25.25 0 01.25-.25h3.5a.25.25 0 01.25.25V23a1 1 0 002 0V3a1 1 0 00-1-1zm-5 9.75a.25.25 0 01.25-.25h3.5a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25h-3.5a.25.25 0 01-.25-.25zm3.75-5.25a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25h-3.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25zm-3.5 13a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h3.5a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25zM13.5 12.5a1 1 0 00-1 1v5h-3a2 2 0 00-2 2V23a1 1 0 002 0v-2.25a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25V23a1 1 0 002 0v-9.5a1 1 0 00-1-1z\"/><path d=\"M14 4.5H2.25A.25.25 0 012 4.25V1a1 1 0 00-2 0v22a.987.987 0 00.805.961A.506.506 0 001 24h4a1 1 0 001-1v-5.5h1.5a1 1 0 000-2H2.25a.25.25 0 01-.25-.25v-6A.25.25 0 012.25 9H14a.5.5 0 00.5-.5V5a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});