define("ember-svg-jar/inlined/arrow-thick-left-bottom-corner-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-left-bottom-corner-2</title><path d=\"M21.908 2.131C20.6.822 19.279 1.21 18.72 1.77L6.663 13.635a.251.251 0 01-.426-.178V8.109a2.427 2.427 0 00-2.466-2.518 2.475 2.475 0 00-2.533 2.517V21.75a1 1 0 001 1h13.838a2.5 2.5 0 100-5h-5.539a.25.25 0 01-.177-.426l11.881-11.99a2.293 2.293 0 00-.333-3.203z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});