define("ember-svg-jar/inlined/christmas-tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-tree</title><path d=\"M8.67 6.954a.251.251 0 01.158.384L8.007 8.57a.25.25 0 00.279.378l6.488-1.919a1.177 1.177 0 01.328-.047 1 1 0 00.849-1.527l-3.1-5a1.045 1.045 0 00-1.7 0l-3.1 5a1 1 0 00-.025 1.012.922.922 0 00.644.487zM12 4.232a.75.75 0 11-.75.75.75.75 0 01.75-.75zM6.935 12.982H7.5a.25.25 0 01.2.4l-1.458 1.944a.25.25 0 00.269.39l9.353-2.695a1 1 0 01.277-.039h.924a1 1 0 00.832-1.555l-2.178-3.268a.5.5 0 00-.558-.2L7.106 10.34a1 1 0 00-.548.4l-.455.683a1 1 0 00.832 1.555zm7.565-2.75a.75.75 0 11-.75.75.75.75 0 01.75-.75zm-4 1a.75.75 0 11-.75.75.75.75 0 01.75-.75zM19.3 17.382l-2.444-3.258a.5.5 0 00-.538-.181L4.954 17.207a.489.489 0 00-.255.175 1 1 0 00.8 1.6h5a.5.5 0 01.5.5v3.5a1 1 0 002 0v-3.5a.5.5 0 01.5-.5h5a1 1 0 00.8-1.6zm-7.8.35a.75.75 0 11.75-.75.75.75 0 01-.75.75zm5-.5a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});