define("ember-svg-jar/inlined/fish-bowl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fish-bowl</title><path d=\"M23.179 4.107a1.47 1.47 0 00-1.379-.933H2.2a1.47 1.47 0 00-1.376.933A14.7 14.7 0 000 8.936a12 12 0 0024 0 14.7 14.7 0 00-.821-4.829zM7.1 13.527a.5.5 0 01.708-.707c.28.281 1.014 1.039 1.127 1.155a4.2 4.2 0 013.019-1.3c2.678 0 3.431 1.733 3.461 1.807a.5.5 0 01.007.369c-.028.074-.716 1.824-3.468 1.824a4.3 4.3 0 01-3.019-1.3c-.031.031-.847.873-1.127 1.153a.5.5 0 01-.708-.708c.276-.276 1.121-1.146 1.121-1.146S7.38 13.8 7.1 13.527zM2.5 5.352a.25.25 0 01.24-.178h18.514a.25.25 0 01.24.178 12.456 12.456 0 01.4 2.042.25.25 0 01-.061.2.253.253 0 01-.187.084H2.353a.25.25 0 01-.248-.28 12.435 12.435 0 01.401-2.046z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});