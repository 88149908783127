define("ember-svg-jar/inlined/cloud-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-text</title><path d=\"M2.587 14.912A1 1 0 004 14.007a.965.965 0 00-.563-.9 2.559 2.559 0 01-.8-.517A2.375 2.375 0 012 10.8a2.588 2.588 0 012.586-2.583 2.7 2.7 0 01.535.054 1 1 0 001.2-.963 4.5 4.5 0 01.928-2.972c3.358-3.848 8.256-2.521 9.89.927a.968.968 0 00.941.571 3.7 3.7 0 013.014 1.316 3.87 3.87 0 01.9 2.781 3.434 3.434 0 01-1.567 2.753.964.964 0 00-.423.809 1 1 0 001.56.825A5.406 5.406 0 0024 9.854a5.766 5.766 0 00-5.215-6 .252.252 0 01-.191-.12 7.684 7.684 0 00-14.1 2.294.251.251 0 01-.227.2A4.643 4.643 0 00.624 8.47 4.467 4.467 0 000 10.918a4.289 4.289 0 001.235 3.1 4.441 4.441 0 001.352.894z\"/><path d=\"M18.5 13a2 2 0 00-2-2h-9a2 2 0 00-2 2v9a2 2 0 002 2h9a2 2 0 002-2zm-3 9h-7a1 1 0 01-1-1v-7a1 1 0 011-1h7a1 1 0 011 1v7a1 1 0 01-1 1z\"/><path d=\"M14.5 15H9.75a.75.75 0 000 1.5h4.75a.75.75 0 000-1.5zM12 18H9.75a.75.75 0 000 1.5H12a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});