define("ember-svg-jar/inlined/electronics-npn-bipolar-transistor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>electronics-NPN-bipolar-transistor</title><path d=\"M24 12a11 11 0 00-5.378-9.44.25.25 0 01-.122-.215V1a1 1 0 00-2 0v.238a.251.251 0 01-.323.239 10.961 10.961 0 00-14.031 8.812.25.25 0 01-.246.211H1a1 1 0 000 2h.789a.249.249 0 01.249.233 10.962 10.962 0 0014.139 9.79.248.248 0 01.222.038.252.252 0 01.1.2V23a1 1 0 102 0v-1.345a.248.248 0 01.122-.214A11 11 0 0024 12zM13 3a8.954 8.954 0 013.343.643.25.25 0 01.157.232v.931a.248.248 0 01-.117.212l-5.5 3.439a.251.251 0 01-.383-.212V6a1 1 0 00-2 0v4.25a.25.25 0 01-.25.25H4.436a.251.251 0 01-.245-.3A9.008 9.008 0 0113 3zm0 18a9 9 0 01-8.955-8.229.249.249 0 01.249-.271H8.25a.25.25 0 01.25.25V17a1 1 0 002 0v-2.188a.25.25 0 01.131-.22.253.253 0 01.256.011l2.488 1.628a.251.251 0 01.109.162.257.257 0 01-.042.192l-1.053 1.481a.75.75 0 00.736 1.174l3.081-.525a.25.25 0 01.292.246v1.164a.25.25 0 01-.157.232A8.955 8.955 0 0113 21zm5.915-2.234a.25.25 0 01-.415-.188v-.845a.993.993 0 00-.3-.709.248.248 0 01-.073-.139l-.616-3.8a.749.749 0 00-1.351-.315l-1.264 1.778a.251.251 0 01-.341.065l-3.937-2.576a.251.251 0 01-.113-.209v-.632a.249.249 0 01.118-.212l7.407-4.636a1 1 0 00.47-.848v-.078a.248.248 0 01.147-.227.25.25 0 01.268.039 8.963 8.963 0 010 13.533z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});