define("ember-svg-jar/inlined/martial-arts-fencing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>martial-arts-fencing</title><circle cx=\"15.504\" cy=\"4\" r=\"2.5\"/><path d=\"M23.236 19.688l-6.273-4.212a1 1 0 01-.459-.841v-2.9a.249.249 0 01.278-.248l1.667.185a4.871 4.871 0 00.51.028A4.48 4.48 0 0023.4 7.834l.074-.52a1.567 1.567 0 00-1.192-1.789 1.5 1.5 0 00-1.764 1.262l-.089.622a1.5 1.5 0 01-1.649 1.279l-8.69-1.122a.251.251 0 00-.277.2l-.5 2.463a.252.252 0 00.041.194.246.246 0 00.167.1l3.767.564a.249.249 0 01.213.247v3.192A4.506 4.506 0 009.5 19v2a1.5 1.5 0 003 0v-2a1.5 1.5 0 011.5-1.5h.559l7.13 4.762a1.5 1.5 0 002.176-.638 1.565 1.565 0 00-.629-1.936zM5.66 9.092A2.456 2.456 0 007 10.941a.5.5 0 00.711-.35l.747-3.7a.5.5 0 00-.52-.6 2.455 2.455 0 00-1.724.864l-5.05-.833a.988.988 0 00-1.149.823A1 1 0 00.84 8.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});