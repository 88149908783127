define("ember-svg-jar/inlined/smart-light-signal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-light-signal</title><path d=\"M18.01 7.484A8.5 8.5 0 005.99 19.5a1 1 0 101.41-1.41 6.5 6.5 0 119.192 0 1 1 0 101.418 1.41 8.5 8.5 0 000-12.02z\"/><path d=\"M20.457 5A11.96 11.96 0 003.543 21.917 1 1 0 104.957 20.5 9.96 9.96 0 0119.043 6.417a9.961 9.961 0 010 14.086 1 1 0 101.414 1.414 11.96 11.96 0 000-16.917z\"/><path d=\"M14 18.5h-4a.5.5 0 00-.5.5 2.493 2.493 0 001.592 2.317.25.25 0 01.158.233v.2a.75.75 0 001.5 0v-.2a.251.251 0 01.159-.233A2.492 2.492 0 0014.5 19a.5.5 0 00-.5-.5zM12 8.4a5.005 5.005 0 00-5 5 4.869 4.869 0 001.354 3.49.653.653 0 00.475.2H11a.25.25 0 00.25-.25v-2.305a.253.253 0 00-.111-.208l-1.8-1.2a.75.75 0 01.832-1.248l1.7 1.131a.251.251 0 00.278 0l1.7-1.131a.75.75 0 11.832 1.248l-1.8 1.2a.253.253 0 00-.111.208v2.313a.25.25 0 00.25.25h2.171a.653.653 0 00.475-.2A4.869 4.869 0 0017 13.4a5 5 0 00-5-5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});