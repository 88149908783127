define("ember-svg-jar/inlined/style-three-pin-dam", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-dam</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm-.266 14.441a1 1 0 01-.9.559H6.5a1 1 0 01-1-1V6a1 1 0 011-1h2.006a1 1 0 011 .979c.091 4.182 1.369 6.437 2.122 7.409a1.006 1.006 0 01.106 1.053zm7.772-4.42a2.857 2.857 0 01-1.354-.659.219.219 0 00-.288 0 2.947 2.947 0 01-3.83.016.249.249 0 00-.325 0 2.945 2.945 0 01-2.115.692.75.75 0 11.114-1.5h.1a1.322 1.322 0 001.332-1.051.75.75 0 011.471 0 1.37 1.37 0 002.665 0 .75.75 0 011.471 0A1.283 1.283 0 0019.8 8.55a.75.75 0 11-.289 1.471z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});