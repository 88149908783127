define("ember-svg-jar/inlined/app-window-hammer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-hammer</title><path d=\"M8.979 16.669a1.146 1.146 0 001.615 0l1.026-1.026a.249.249 0 01.354 0l2.318 2.323a1 1 0 001.416-1.412l-2.321-2.326a.25.25 0 010-.353l1.027-1.027a1.144 1.144 0 000-1.615l-2.639-2.64a.961.961 0 00-.809-.335 1.137 1.137 0 00-.806.335l-3.82 3.819a1.142 1.142 0 000 1.617z\"/><path d=\"M24 4.751a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h18a3 3 0 003-3zm-14.346-1a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.562.091a1 1 0 01.908-.591.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.987.987 0 01.092-.409zM22 19.251a1 1 0 01-1 1H3a1 1 0 01-1-1V7a.25.25 0 01.25-.25h19.5A.25.25 0 0122 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});