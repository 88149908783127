define("ember-svg-jar/inlined/modern-tv-uhd", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-tv-uhd</title><path d=\"M24 1.5A1.5 1.5 0 0022.5 0h-21A1.5 1.5 0 000 1.5v16A1.5 1.5 0 001.5 19h21a1.5 1.5 0 001.5-1.5zm-2 13a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5zM14.35 20.681a.5.5 0 00-.385-.181h-3.93a.5.5 0 00-.385.181l-2.1 2.532a.5.5 0 00.41.787h8.079a.5.5 0 00.41-.787z\"/><path d=\"M16 3.75a.75.75 0 00-.75.75v8a.75.75 0 00.75.75 4.75 4.75 0 000-9.5zm1.084 7.814a.25.25 0 01-.228-.032.247.247 0 01-.106-.2v-5.66a.251.251 0 01.334-.236 3.25 3.25 0 010 6.128zM14 3.75a.75.75 0 00-.75.75v3a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25v-3a.75.75 0 00-1.5 0v8a.75.75 0 001.5 0v-3a.25.25 0 01.25-.25h2a.25.25 0 01.25.25v3a.75.75 0 001.5 0v-8a.75.75 0 00-.75-.75zM8 3.75a.75.75 0 00-.75.75v6a1.25 1.25 0 01-2.5 0v-6a.75.75 0 00-1.5 0v6a2.75 2.75 0 005.5 0v-6A.75.75 0 008 3.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});