define("ember-svg-jar/inlined/card-game-cards-spade-diamond", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>card-game-cards-spade-diamond</title><path d=\"M14.425 16.538l-.608-2.447a.5.5 0 01.183-.523l2.026-1.5a.5.5 0 01.783.281l.608 2.448a.5.5 0 01-.188.522l-2.025 1.5a.5.5 0 01-.779-.281zM9.666 8.715c-.187-.808-1.788-1.641-2.332-1.9a.249.249 0 00-.3.07c-.374.474-1.448 1.925-1.263 2.731a1.438 1.438 0 001.36 1.073.251.251 0 01.216.193l.205.891a.75.75 0 101.462-.337l-.214-.895a.252.252 0 01.11-.267 1.439 1.439 0 00.756-1.559z\"/><path d=\"M23.912 10.167a1.486 1.486 0 00-.9-1.9l-9.173-3.294a1.473 1.473 0 00-.434-.086.249.249 0 01-.232-.193l-.818-3.543A1.484 1.484 0 0010.576.038L1.15 2.205A1.486 1.486 0 00.038 3.984l3.04 13.2a1.48 1.48 0 001.78 1.111l2.409-.555a.251.251 0 01.292.328l-.233.649a1.488 1.488 0 00.9 1.9l9.174 3.296a1.49 1.49 0 001.9-.9zM5.155 16.172a.249.249 0 01-.3-.187l-2.7-11.7a.246.246 0 01.032-.189.251.251 0 01.155-.111l7.933-1.827a.249.249 0 01.3.188l1.01 4.381a.251.251 0 01-.008.141l-3.017 8.4a.25.25 0 01-.179.159zm12.521 5.45a.252.252 0 01-.129.141.244.244 0 01-.191.009l-7.74-2.778a.25.25 0 01-.15-.32l4.1-11.41a.25.25 0 01.32-.151l7.735 2.777a.25.25 0 01.151.32z\"/><path d=\"M4.273 4.22a1 1 0 101 1 1 1 0 00-1-1zM16.5 18.22a1 1 0 101 1 1 1 0 00-1-1zM14.5 8.22a1 1 0 101 1 1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});