define("ember-svg-jar/inlined/christmas-door-wreath-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-door-wreath-1</title><path d=\"M23 11a11 11 0 10-15.868 9.85.5.5 0 01.257.589l-.144.49a1.259 1.259 0 00.368 1.282 1.223 1.223 0 001.288.23.5.5 0 01.538.138 1.14 1.14 0 001.081.408 1.262 1.262 0 001.018-.845l.246-.738a.228.228 0 01.433 0l.246.739a1.26 1.26 0 001.018.844 1.155 1.155 0 001.082-.412.5.5 0 01.535-.135 1.217 1.217 0 001.288-.228 1.259 1.259 0 00.368-1.285l-.143-.488a.5.5 0 01.257-.589A11 11 0 0023 11zM12 2.5a1 1 0 11-1 1 1 1 0 011-1.007zm0 4a4.476 4.476 0 014.471 4.1.5.5 0 01-.478.541 4.206 4.206 0 00-2.752 1.49.5.5 0 01-.476.107 2.672 2.672 0 00-1.531 0 .5.5 0 01-.475-.107 4.191 4.191 0 00-2.748-1.492.5.5 0 01-.357-.167.5.5 0 01-.125-.374A4.476 4.476 0 0112 6.5zm1.25 8.882A1.25 1.25 0 1112 14.129a1.252 1.252 0 011.25 1.25zm-9-3.632a1 1 0 111-1 1 1 0 01-1.004.997zm1.667-6.94a1 1 0 110 1.414 1 1 0 01-.004-1.417zm1.9 12.873a1.48 1.48 0 01-1.567-1.371v-2.318a1.48 1.48 0 011.568-1.365 1.856 1.856 0 011.15.417l.5.445a.5.5 0 01.107.609 2.691 2.691 0 00-.323 1.279 2.637 2.637 0 00.2 1.006.5.5 0 01-.13.562l-.353.316a1.765 1.765 0 01-1.151.417zm7.2 3.667a.252.252 0 01-.311.311.737.737 0 00-.727.172.25.25 0 01-.41-.1l-.861-2.589a.75.75 0 00-1.424 0l-.861 2.592a.251.251 0 01-.411.1.735.735 0 00-.726-.176.248.248 0 01-.247-.063.25.25 0 01-.064-.247l.768-2.619a.741.741 0 01.22-.346l.439-.392a.5.5 0 01.533-.086 2.614 2.614 0 002.122 0 .5.5 0 01.533.086l.439.392a.748.748 0 01.221.351zm2.729-5.031a1.479 1.479 0 01-1.568 1.364h-.01a1.721 1.721 0 01-1.14-.417l-.354-.316a.5.5 0 01-.13-.563 2.628 2.628 0 00.2-1.005 2.688 2.688 0 00-.323-1.279.5.5 0 01.107-.609l.5-.444a1.73 1.73 0 011.141-.418h.01A1.48 1.48 0 0117.75 14zm.337-10.095a1 1 0 110-1.414 1 1 0 01.004 1.411zm.667 4.526a1 1 0 111 1 1 1 0 01-.996-1.003z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});