define("ember-svg-jar/inlined/multiple-actions-edit-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-edit-2</title><circle cx=\"14.501\" cy=\"3.5\" r=\"3\"/><path d=\"M12.5 14v2a.25.25 0 00.426.177l5.541-5.54a.966.966 0 01.109-.094.252.252 0 00.078-.294 4.528 4.528 0 00-7.75-.994.25.25 0 00.005.311A6.96 6.96 0 0112.5 14zM.5 18h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.691 18H10.5a.5.5 0 00.5-.5V14a5.5 5.5 0 10-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.501\" cy=\"3.5\" r=\"3.5\"/><path d=\"M12.393 20.462a.5.5 0 00-.844.255l-.537 2.683a.5.5 0 00.49.6.466.466 0 00.1-.01l2.685-.537a.5.5 0 00.255-.844zM16.027 21.975a.5.5 0 00.707 0l6.5-6.5a2.623 2.623 0 00-.2-3.882 2.71 2.71 0 00-3.606.274l-6.4 6.4a.5.5 0 000 .707z\"/><circle cx=\"14.501\" cy=\"3.5\" r=\"3\"/><path d=\"M12.5 14v2a.25.25 0 00.426.177l5.541-5.54a.966.966 0 01.109-.094.252.252 0 00.078-.294 4.528 4.528 0 00-7.75-.994.25.25 0 00.005.311A6.96 6.96 0 0112.5 14zM.5 18h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.691 18H10.5a.5.5 0 00.5-.5V14a5.5 5.5 0 10-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.501\" cy=\"3.5\" r=\"3.5\"/><path d=\"M12.393 20.462a.5.5 0 00-.844.255l-.537 2.683a.5.5 0 00.49.6.466.466 0 00.1-.01l2.685-.537a.5.5 0 00.255-.844zM16.027 21.975a.5.5 0 00.707 0l6.5-6.5a2.623 2.623 0 00-.2-3.882 2.71 2.71 0 00-3.606.274l-6.4 6.4a.5.5 0 000 .707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});