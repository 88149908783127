define("ember-svg-jar/inlined/match-fire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>match-fire</title><path d=\"M14.313 16.1a.5.5 0 00-.205.284 3.618 3.618 0 11-2.777-4.505h.024a.5.5 0 00.378-.073l3.938-2.62a.5.5 0 00.117-.726 8.509 8.509 0 00-2.7-2.279.779.779 0 00-1.127.526 6.441 6.441 0 01-1.058 2.392.251.251 0 01-.4 0 5.409 5.409 0 01-1.1-3.25 5.249 5.249 0 011.843-3.684.78.78 0 00-.73-1.329A14.246 14.246 0 000 14.27a8.911 8.911 0 0017.822 0 .269.269 0 00-.419-.224z\"/><path d=\"M23.833 6.767a1 1 0 00-1.387-.279l-10.6 7.05a.249.249 0 01-.255.013 2.037 2.037 0 00-.554-.2 2.119 2.119 0 101.663 2.494 1.944 1.944 0 00.037-.363.249.249 0 01.111-.205l10.706-7.124a1 1 0 00.279-1.386z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});