define("ember-svg-jar/inlined/security-officer-scanner-beep", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>security-officer-scanner-beep</title><path d=\"M4.484 22.25a1 1 0 002 0V5.75a.5.5 0 01.5-.5h9.5a.5.5 0 01.5.5v16.5a1 1 0 002 0V3.5a2.752 2.752 0 00-2.75-2.75h-9a2.753 2.753 0 00-2.75 2.75zM2.5 13.25H1.016a1 1 0 000 2H2.5a1 1 0 000-2zM1.86 11.269a1 1 0 001.28-1.538l-1.5-1.25a1 1 0 00-1.28 1.538zM1.86 17.231l-1.5 1.25a1 1 0 001.28 1.538l1.5-1.25a1 1 0 00-1.28-1.538zM20.5 14.25a1 1 0 001 1h1.484a1 1 0 000-2H21.5a1 1 0 00-1 1zM22.14 11.269l1.5-1.25a1 1 0 00-1.28-1.538l-1.5 1.25a1 1 0 001.28 1.538zM22.14 17.231a1 1 0 00-1.28 1.538l1.5 1.25a1 1 0 001.28-1.538z\"/><path d=\"M11.734 12.5a3.5 3.5 0 00-3.5 3.5v2a.5.5 0 00.5.5h.605a.5.5 0 01.5.445l.4 3.611a.5.5 0 00.5.444h2a.5.5 0 00.5-.444l.4-3.611a.5.5 0 01.5-.445h.6a.5.5 0 00.5-.5v-2a3.5 3.5 0 00-3.505-3.5z\"/><circle cx=\"11.734\" cy=\"9\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});