define("ember-svg-jar/inlined/cash-payment-sign-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-payment-sign-1</title><path d=\"M9.613 16.534a.954.954 0 00.955.954h7.16a.955.955 0 000-1.909h-7.16a.954.954 0 00-.955.955z\"/><path d=\"M22.023 7.465H18.2a.955.955 0 100 1.909h2.818a1 1 0 011 1v8.5a1 1 0 01-1 1H2.977a1 1 0 01-1-1v-8.5a1 1 0 011-1h1.386a.955.955 0 100-1.909H1.977a1.91 1.91 0 00-1.91 1.909v10.5a1.91 1.91 0 001.91 1.909h20.046a1.91 1.91 0 001.91-1.909v-10.5a1.91 1.91 0 00-1.91-1.909z\"/><path d=\"M7.025 10.829a.25.25 0 00-.421.128l-.336 1.679a.475.475 0 00.13.431.482.482 0 00.338.14c.1 0 1.873-.366 1.772-.346a.25.25 0 00.128-.422zM13.4 3.837a.25.25 0 00-.353 0L7.39 9.491a.25.25 0 000 .353l2.231 2.231a.25.25 0 00.353 0l5.654-5.654a.25.25 0 000-.353zM16.3 5.393a.25.25 0 00.353 0l.06-.06a1.827 1.827 0 00-2.584-2.584l-.06.059a.252.252 0 000 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});