define("ember-svg-jar/inlined/phone-expand-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-expand-1</title><path d=\"M9.084 3.5h5.832a.5.5 0 00.342-.865L12.754.3a1.106 1.106 0 00-1.508 0l-2.5 2.34a.5.5 0 00.342.865zM14.916 20.5H9.084a.5.5 0 00-.342.865l2.5 2.339a1.107 1.107 0 001.508 0l2.5-2.34a.5.5 0 00-.342-.865zM23.7 11.246l-2.34-2.5a.5.5 0 00-.865.341v5.833a.5.5 0 00.865.34l2.339-2.5a1.106 1.106 0 00.001-1.514zM3.185 8.619a.5.5 0 00-.548.124L.3 11.245a1.106 1.106 0 000 1.509l2.34 2.5a.5.5 0 00.865-.34v-5.83a.5.5 0 00-.32-.465zM14.7 5H9.3A2.285 2.285 0 007 7.266v9.469A2.284 2.284 0 009.3 19h5.4a2.284 2.284 0 002.3-2.265V7.266A2.285 2.285 0 0014.7 5zm.9 10a1 1 0 01-1 1H9.4a1 1 0 01-1-1V7.325c0-.552.447-.5 1-.5h5.2c.553 0 1-.052 1 .5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});