define("ember-svg-jar/inlined/single-neutral-actions-flag.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-flag</title><path d=\"M12.5 13a1.955 1.955 0 01.667-1.466.25.25 0 00-.077-.421 94.244 94.244 0 00-1.091-.405l-.629-.231a.592.592 0 01-.137-.31 2.623 2.623 0 01.057-1.3 5.618 5.618 0 001.466-4.314C12.752 2.13 11.173.374 9 .374S5.24 2.13 5.24 4.548a5.605 5.605 0 001.451 4.3 2.505 2.505 0 01.073 1.317.6.6 0 01-.135.308L6 10.7c-2.446.9-4.214 1.549-4.753 2.626A9.167 9.167 0 00.5 16.874a.5.5 0 00.5.5h11.25a.25.25 0 00.25-.25zM23.5 13a.5.5 0 00-.71-.454l-.882.41a3.054 3.054 0 01-2.718-.37 4.046 4.046 0 00-3.6-.489l-1.269.429a.5.5 0 00-.34.474L14 22.626a1 1 0 002 0v-3.914a.249.249 0 01.191-.243 3.044 3.044 0 012.447.44 4.064 4.064 0 002.285.7 4.213 4.213 0 001.363-.227l.932-.43a.5.5 0 00.29-.454z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});