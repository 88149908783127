define("ember-svg-jar/inlined/hospital-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hospital-sign</title><path d=\"M23.5 3A1.5 1.5 0 0022 1.5H7A1.5 1.5 0 005.5 3a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5V1.5a1.5 1.5 0 00-3 0v21a1.5 1.5 0 003 0V18a.5.5 0 01.5-.5h1a.5.5 0 01.5.5A1.5 1.5 0 007 19.5h15a1.5 1.5 0 001.5-1.5zm-18 11a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5V7a.5.5 0 01.5-.5h1a.5.5 0 01.5.5zM20 12a.5.5 0 01-.5.5H17a.5.5 0 00-.5.5v2.5a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V13a.5.5 0 00-.5-.5H9.5A.5.5 0 019 12V9a.5.5 0 01.5-.5H12a.5.5 0 00.5-.5V5.5A.5.5 0 0113 5h3a.5.5 0 01.5.5V8a.5.5 0 00.5.5h2.5a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});