define("ember-svg-jar/inlined/phone-translate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-translate</title><path d=\"M5.5 3.5A.5.5 0 016 3h11a.5.5 0 01.5.5v.75a.25.25 0 00.25.25h1.5a.25.25 0 00.25-.25V3a3 3 0 00-3-3h-10a3 3 0 00-3 3v1.25a.25.25 0 00.25.25h1.5a.25.25 0 00.25-.25zM17.5 19a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5v-1.5h-2V21a3 3 0 003 3h10a3 3 0 003-3v-3.25a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25zM23.25 9.5a.752.752 0 00-.75-.75h-2a.25.25 0 01-.25-.25v-1a.75.75 0 00-1.5 0v1a.25.25 0 01-.25.25h-2a.75.75 0 000 1.5h.134a.249.249 0 01.242.189 5.311 5.311 0 001.108 2.121.252.252 0 01.051.224.248.248 0 01-.152.171 7.309 7.309 0 01-1.047.313.75.75 0 00.326 1.464 7.954 7.954 0 002.214-.829.248.248 0 01.247 0 7.986 7.986 0 002.214.829.75.75 0 00.326-1.464 7.309 7.309 0 01-1.047-.313.248.248 0 01-.152-.171.252.252 0 01.051-.224 5.311 5.311 0 001.108-2.121.249.249 0 01.242-.189h.135a.752.752 0 00.75-.75zm-3.571 2.31a.25.25 0 01-.359 0 3.893 3.893 0 01-.807-1.213.251.251 0 01.023-.235.248.248 0 01.208-.112h1.511a.253.253 0 01.208.111.249.249 0 01.022.235 3.88 3.88 0 01-.806 1.214zM8.884 8.621a.75.75 0 10-.768 1.289 1.25 1.25 0 010 2.15.75.75 0 00.77 1.288 2.751 2.751 0 000-4.727z\"/><path d=\"M11.635 5.916a.75.75 0 10-.879 1.216 4.749 4.749 0 01.261 7.5.75.75 0 00.961 1.153 6.251 6.251 0 00-.343-9.872zM3 7.749A2.253 2.253 0 00.75 10v4.5a.75.75 0 001.5 0v-2a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v2a.75.75 0 101.5 0V10A2.253 2.253 0 003 7.749zm-.5 3a.249.249 0 01-.25-.25V10a.75.75 0 011.5 0v.5a.249.249 0 01-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});