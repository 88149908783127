define("ember-svg-jar/inlined/mailbox-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mailbox-in</title><path d=\"M8.248 3.5H10.5a1.75 1.75 0 000-3.5H7a.75.75 0 00-.75.75v4h2zM20.324 6.25H8.735a.251.251 0 00-.2.4A4.692 4.692 0 019.5 9.5v7a.25.25 0 00.25.25h2.75a.25.25 0 01.25.25v6a1 1 0 002 0v-6a.25.25 0 01.25-.25h7.278a.239.239 0 00.225-.25v-8a2.216 2.216 0 00-2.179-2.25zM1.5 9.5v7a.25.25 0 00.25.25h6A.25.25 0 008 16.5v-7a3.25 3.25 0 00-6.5 0zM6.25 14a.5.5 0 01-.5.5h-2a.5.5 0 010-1h2a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});