define("ember-svg-jar/inlined/school-board-chemistry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>school-board-chemistry</title><path d=\"M23 14.5H1a1 1 0 00-1 1v.5a1.5 1.5 0 001.125 1.453.5.5 0 01.375.484V23a1 1 0 002 0v-1a.5.5 0 01.5-.5h16a.5.5 0 01.5.5v1a1 1 0 002 0v-5.063a.5.5 0 01.375-.484A1.5 1.5 0 0024 16v-.5a1 1 0 00-1-1zM20.5 19a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h16a.5.5 0 01.5.5zM7.522 5.717l.75.385a.5.5 0 00.456 0l.75-.385a.5.5 0 00.272-.445v-.938a.5.5 0 00-.276-.447l-.75-.375a.5.5 0 00-.448 0l-.75.375a.5.5 0 00-.276.447v.938a.5.5 0 00.272.445zM14.522 9.217l.75.385a.5.5 0 00.456 0l.75-.385a.5.5 0 00.272-.445v-.938a.5.5 0 00-.276-.447l-.75-.375a.5.5 0 00-.448 0l-.75.375a.5.5 0 00-.276.447v.938a.5.5 0 00.272.445z\"/><path d=\"M2.5 13h19a.5.5 0 00.5-.5v-11A1.5 1.5 0 0020.5 0h-17A1.5 1.5 0 002 1.5v11a.5.5 0 00.5.5zm3.25-9.129a1.244 1.244 0 01.691-1.118L7.941 2a1.256 1.256 0 011.118 0l1.5.75a1.244 1.244 0 01.691 1.118v1.4a.5.5 0 00.273.445l1.275.649a.5.5 0 00.505-.031c.107-.071.118-.068 1.171-.6a.5.5 0 00.276-.447V3.5a.75.75 0 011.5 0v1.79a.5.5 0 00.276.447l1.033.516a1.244 1.244 0 01.691 1.118V9.23a1.243 1.243 0 01-.68 1.112l-1.5.77a1.25 1.25 0 01-1.142 0l-1.5-.77a1.244 1.244 0 01-.678-1.112v-.9a.5.5 0 00-.273-.445l-1.748-.889a.5.5 0 00-.455 0l-.752.387a.5.5 0 00-.272.444v2.237a.75.75 0 01-1.5 0V7.825a.5.5 0 00-.272-.444l-1.049-.539A1.244 1.244 0 015.75 5.73z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});