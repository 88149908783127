define("ember-svg-jar/inlined/non-gmo-dna", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>non-gmo-dna</title><path d=\"M12.514 18.143a15.913 15.913 0 01-1.425-.953 1 1 0 10-1.21 1.591 17.667 17.667 0 001.606 1.077c.4.242.771.467 1.109.681a.25.25 0 01-.133.461h-1.89a.5.5 0 01-.483-.371.982.982 0 00-.175-.353 1 1 0 00-1.4-.174 9.2 9.2 0 00-2.2 2.364A1 1 0 008 23.534c.071-.113.146-.222.224-.329a.5.5 0 01.4-.2h6.75a.5.5 0 01.4.2c.079.106.154.216.225.329a1 1 0 001.701-1.068c-1.152-1.816-2.876-2.938-5.186-4.323zM13.717 16.512a1 1 0 001.366 1.461A7.831 7.831 0 0017.75 12a1 1 0 00-2 0 6.215 6.215 0 01-.145 1.359.5.5 0 01-.488.391H13.5a1 1 0 000 2h.446a.219.219 0 01.162.366q-.183.201-.391.396zM23.707.293a1 1 0 00-1.414 0l-6.116 6.116a.5.5 0 01-.713-.007 14.21 14.21 0 00-2.949-2.259c-.4-.242-.771-.468-1.109-.681A.25.25 0 0111.539 3h1.89a.5.5 0 01.483.371.982.982 0 00.175.353 1 1 0 001.4.175 9.217 9.217 0 002.2-2.365A1 1 0 0016 .466a6.167 6.167 0 01-.224.33.5.5 0 01-.4.2H8.625a.5.5 0 01-.4-.2Q8.1.636 8 .466a1 1 0 00-1.695 1.068c1.147 1.816 2.871 2.938 5.18 4.324a11.683 11.683 0 012.655 2.07.382.382 0 01-.011.529 1 1 0 01-.707.293H9.691a.25.25 0 01-.2-.4 6.265 6.265 0 01.418-.489 1 1 0 00-1.454-1.374 8 8 0 00-1.533 8.724.5.5 0 01-.1.557L.293 22.293a1 1 0 001.414 1.414l22-22a1 1 0 000-1.414zm-12.3 10.884L8.88 13.706a.249.249 0 01-.417-.111A5.961 5.961 0 018.25 12a5.9 5.9 0 01.057-.818.5.5 0 01.493-.432h2.43a.25.25 0 01.177.427z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});