define("ember-svg-jar/inlined/road-sign-hairpin-turn-right-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-hairpin-turn-right-alternate</title><path d=\"M23.707 12.707a1 1 0 000-1.414l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0zm-11.53 8.7a.25.25 0 01-.354 0l-9.232-9.23a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/><path d=\"M8 10.5v6a1 1 0 002 0v-6a2.5 2.5 0 015 0 .25.25 0 01-.25.25h-.875a.75.75 0 00-.577 1.229l2.125 2.562a.749.749 0 00.577.272.75.75 0 00.578-.276l2.093-2.562a.75.75 0 00-.58-1.225h-.841a.25.25 0 01-.25-.25 4.5 4.5 0 00-9 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});