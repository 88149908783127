define("ember-svg-jar/inlined/pets-hospital", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pets-hospital</title><path d=\"M23.984 11.837a.251.251 0 00-.1-.295L21 9.616V7.75a.25.25 0 00-.378-.215l-.16.1a6.966 6.966 0 00-3.8 4.813.743.743 0 01-.722.553H8.75a2.239 2.239 0 00-.949.217 2.666 2.666 0 00-2.461-.238 2.767 2.767 0 00-1.577 1.6l-.715 1.906a.75.75 0 101.4.528l.715-1.907a1.258 1.258 0 011.53-.768 2.22 2.22 0 00-.2.911v1.027a1.744 1.744 0 01-.185.783l-.578 1.156a2.268 2.268 0 00-.23 1.006v3.528a1.25 1.25 0 002.5 0v-2.172a1.76 1.76 0 01.513-1.237L9 18.854v.47a2.375 2.375 0 00.132.985l1.5 3a1.279 1.279 0 001.677.558 1.249 1.249 0 00.559-1.677L11.6 19.652l.307-.951a.251.251 0 01.238-.174h3.647a.25.25 0 01.249.23l.347 4.157A1.317 1.317 0 0019 22.75v-.735c0-3.871 0-4.981 1.827-8.383a.252.252 0 01.221-.132h1.2a1.418 1.418 0 001.341-.607zM3.25 9.5a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5V7A.25.25 0 017 6.75h2.5a.5.5 0 00.5-.5v-2.5a.5.5 0 00-.5-.5H7A.25.25 0 016.75 3V.5a.5.5 0 00-.5-.5h-2.5a.5.5 0 00-.5.5V3a.25.25 0 01-.25.25H.5a.5.5 0 00-.5.5v2.5a.5.5 0 00.5.5H3a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});