define("ember-svg-jar/inlined/drawer-send", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drawer-send</title><path d=\"M9.608 12.75a.5.5 0 00.491-.408 7.47 7.47 0 017.4-5.785V7.75a.5.5 0 00.807.395l4.5-3.5a.5.5 0 000-.789l-4.5-3.5A.5.5 0 0017.5.75v1.215a7.891 7.891 0 00-5.462 2.291c-2.96 3-2.933 7.8-2.931 8a.5.5 0 00.501.494z\"/><path d=\"M23.884 15.776l-2.966-5.5A1.913 1.913 0 0019.24 9.25H17a1 1 0 000 2h2.009a.25.25 0 01.22.131l2.169 4a.25.25 0 01-.22.369H17.5a1.5 1.5 0 00-1.5 1.5 1.5 1.5 0 01-1.5 1.5h-5a1.5 1.5 0 01-1.5-1.5 1.5 1.5 0 00-1.5-1.5H2.8a.25.25 0 01-.221-.366l2.084-4a.25.25 0 01.221-.134H6.5a1 1 0 000-2H4.736a1.907 1.907 0 00-1.674 1.025l-2.94 5.5A1.359 1.359 0 000 16.25v6a1.5 1.5 0 001.5 1.5h21a1.5 1.5 0 001.5-1.5v-6a1.208 1.208 0 00-.116-.474z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});