define("ember-svg-jar/inlined/video-player-album", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-player-album</title><path d=\"M24 12a9 9 0 00-7.247-8.83.25.25 0 00-.3.284A3.518 3.518 0 0116.5 4v6.016a.251.251 0 00.219.248 1.75 1.75 0 010 3.472.25.25 0 00-.219.248V20a3.518 3.518 0 01-.043.546.25.25 0 00.3.284A9 9 0 0024 12zM13 22a2 2 0 002-2V4a2 2 0 00-2-2H2a2 2 0 00-2 2v16a2 2 0 002 2zm-7.11-7.088a.984.984 0 01-1.39-.9V9.988a.987.987 0 011.391-.9l3.859 1.747a1.281 1.281 0 010 2.33z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});