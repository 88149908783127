define("ember-svg-jar/inlined/folder-media-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-media-1</title><path d=\"M22.5 4.5H11.736a.5.5 0 01-.447-.276l-.947-1.9A1.494 1.494 0 009 1.5H1.5A1.5 1.5 0 000 3v18a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 21V6a1.5 1.5 0 00-1.5-1.5zm-5.788 10.419l-6.233 3.641a1.405 1.405 0 01-1.383.016 1.355 1.355 0 01-.707-1.185v-7.282A1.355 1.355 0 019.1 8.924a1.408 1.408 0 011.383.017l6.233 3.64a1.348 1.348 0 010 2.338z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});