define("ember-svg-jar/inlined/keyboard-return", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-return</title><path d=\"M19.5.5h-15a4.012 4.012 0 00-4 4v15a4.011 4.011 0 004 4h15a4.011 4.011 0 004-4v-15a4.012 4.012 0 00-4-4zm-1 11a2 2 0 01-2 2h-5.75a.25.25 0 00-.25.25v1.75a1 1 0 01-.617.924 1 1 0 01-1.09-.217l-3-3a1 1 0 010-1.414l3-3A1 1 0 0110.5 9.5v1.75a.249.249 0 00.25.25h5.5a.249.249 0 00.25-.25V8.5a1 1 0 012 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});