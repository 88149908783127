define("ember-svg-jar/inlined/style-three-pin-bowling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-bowling</title><path d=\"M7.534 15.5a1 1 0 01-.851-.474A7.26 7.26 0 015.5 11.5a4.219 4.219 0 011.109-2.633A1.649 1.649 0 007 7.8a2.008 2.008 0 00-.286-1.03 1.5 1.5 0 112.572 0A2.008 2.008 0 009 7.8a1.6 1.6 0 00.225.828.5.5 0 01-.042.564A4.216 4.216 0 008.25 12a10.461 10.461 0 00.55 3.049.346.346 0 01-.329.451zm9.783-.474a1 1 0 01-.851.474h-.932a.345.345 0 01-.328-.451A10.491 10.491 0 0015.75 12a4.216 4.216 0 00-.933-2.807.5.5 0 01-.042-.564A1.6 1.6 0 0015 7.8a2.008 2.008 0 00-.286-1.03 1.5 1.5 0 112.572 0A2.008 2.008 0 0017 7.8a1.649 1.649 0 00.391 1.066A4.219 4.219 0 0118.5 11.5a7.26 7.26 0 01-1.183 3.526zM12 0C6.21 0 1.5 4.26 1.5 9.5c0 5.75 7.3 12.29 9.54 14.15a1.492 1.492 0 001.92 0c2.24-1.87 9.54-8.4 9.54-14.15C22.5 4.26 17.79 0 12 0zm1.81 15.92a1.016 1.016 0 01-.91.58h-1.8a1.016 1.016 0 01-.91-.58A10.5 10.5 0 019.25 12C9.25 9.5 11 9.5 11 8a2.808 2.808 0 00-.32-1.3 1.4 1.4 0 01-.18-.7 1.5 1.5 0 013 0 1.4 1.4 0 01-.18.7A2.808 2.808 0 0013 8c0 1.5 1.75 1.5 1.75 4a10.5 10.5 0 01-.94 3.92z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});