define("ember-svg-jar/inlined/target-path", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>target-path</title><path d=\"M10.5 23.02a.98.98 0 00-.98-.979H7.562a.98.98 0 100 1.959h1.959a.98.98 0 00.979-.98zM4.294 21.4a4.434 4.434 0 01-1.625-1.736.98.98 0 10-1.738.906 6.4 6.4 0 002.343 2.5.968.968 0 00.509.143.979.979 0 00.511-1.813zM2.115 13.1a6.361 6.361 0 00-1.707 2.972.979.979 0 00.711 1.189.945.945 0 00.24.03.98.98 0 00.949-.74 4.4 4.4 0 011.182-2.059.98.98 0 10-1.375-1.4zM17.358 13.224a.98.98 0 000-1.959h-1.47a.98.98 0 000 1.959zM22.5 8.74a.979.979 0 00-1.342.347 4.383 4.383 0 01-1.171 1.307.98.98 0 101.173 1.57 6.387 6.387 0 001.688-1.883.981.981 0 00-.348-1.341zM23.622 5.807a1.075 1.075 0 00-.018-.193 6.362 6.362 0 00-1.554-3.06.98.98 0 10-1.444 1.324 4.393 4.393 0 011.075 2.116.981.981 0 00.96.79.9.9 0 00.191-.019.979.979 0 00.79-.958zM18.823 1.578a.978.978 0 00-.888-1.062 6.883 6.883 0 00-.577-.026h-.98a.98.98 0 000 1.959h.98c.136 0 .27.007.4.018h.088a.979.979 0 00.977-.889z\"/><path d=\"M8.579 9a.487.487 0 00.452.3h1.225a.245.245 0 01.245.245v9.8a1.469 1.469 0 002.938 0v-9.8a.245.245 0 01.245-.245h1.225a.49.49 0 00.346-.836l-2.939-2.933a.489.489 0 00-.692 0L8.685 8.47a.49.49 0 00-.106.53z\"/><circle cx=\"11.97\" cy=\"1.959\" r=\"1.959\"/><path d=\"M6.582 11.265a.98.98 0 000 1.959h1.47a.98.98 0 000-1.959z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});