define("ember-svg-jar/inlined/keyhole-square-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyhole-square-alternate</title><path d=\"M21.429 0H2.571A2.573 2.573 0 000 2.571v18.858A2.573 2.573 0 002.571 24h18.858A2.573 2.573 0 0024 21.429V2.571A2.573 2.573 0 0021.429 0zM22 21.429a.579.579 0 01-.571.571H2.571A.579.579 0 012 21.429V2.571A.579.579 0 012.571 2h18.858a.579.579 0 01.571.571z\"/><path d=\"M16.167 9.135a4.167 4.167 0 00-8.334 0 4.092 4.092 0 002 3.526l-1.172 5a1.5 1.5 0 001.458 1.839h3.762a1.5 1.5 0 001.46-1.843l-1.172-5a4.092 4.092 0 001.998-3.522z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});