define("ember-svg-jar/inlined/idea-settings-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>idea-settings-1</title><path d=\"M22.253 9.761l-1.265-.451a1.882 1.882 0 01-1.065-2.579l.577-1.216A1.878 1.878 0 0018 3.009l-1.213.578a1.873 1.873 0 01-2.571-1.068l-.45-1.269a1.874 1.874 0 00-3.534 0l-.45 1.269a1.873 1.873 0 01-2.57 1.068L6 3.009a1.878 1.878 0 00-2.5 2.506l.576 1.216A1.882 1.882 0 013.012 9.31l-1.265.451a1.87 1.87 0 000 3.525l1.265.451a1.883 1.883 0 011.065 2.58L3.5 17.533A1.878 1.878 0 006 20.039l1.213-.579h.511A2 2 0 009.5 20.55h.75a.249.249 0 01.25.25v1.7a1.5 1.5 0 103 0v-1.7a.25.25 0 01.25-.25h.75a2 2 0 001.779-1.09h.508l1.213.579a1.878 1.878 0 002.5-2.506l-.576-1.216a1.883 1.883 0 011.065-2.58l1.265-.451a1.87 1.87 0 000-3.525zM14.5 15.892v2.152a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5v-2.152a.489.489 0 00-.3-.459A5.043 5.043 0 016 10.6 5.816 5.816 0 0112 5a5.816 5.816 0 016 5.6 5.042 5.042 0 01-3.2 4.83.49.49 0 00-.3.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});