define("ember-svg-jar/inlined/navigation-menu-horizontal-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-menu-horizontal-2-alternate</title><circle cx=\"12.001\" cy=\"12\" r=\"2\"/><circle cx=\"17.501\" cy=\"12\" r=\"2\"/><circle cx=\"6.501\" cy=\"12\" r=\"2\"/><path d=\"M21 24H3a3 3 0 01-3-3V3a3 3 0 013-3h18a3 3 0 013 3v18a3 3 0 01-3 3zM3 2a1 1 0 00-1 1v18a1 1 0 001 1h18a1 1 0 001-1V3a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});