define("ember-svg-jar/inlined/baggage-take", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baggage-take</title><circle cx=\"4\" cy=\"2.954\" r=\"2.5\"/><path d=\"M1 14.954h.75A.25.25 0 012 15.2v7.25a1 1 0 001 1h2a1 1 0 001-1V15.2a.25.25 0 01.25-.25H7a1 1 0 001-1v-5a2 2 0 00-2-2H2a2 2 0 00-2 2v5a1 1 0 001 1.004zM15.941 2.084a.5.5 0 00-.545.108l-3 3a.5.5 0 000 .707l3 3a.5.5 0 00.354.147.508.508 0 00.191-.038.5.5 0 00.309-.462v-1.5a.25.25 0 01.25-.25H20a1.25 1.25 0 000-2.5h-3.5a.25.25 0 01-.25-.25v-1.5a.5.5 0 00-.309-.462zM24 16.046a1.5 1.5 0 00-1.5-1.5h-.25a.25.25 0 00-.25.25v8.5a.25.25 0 00.25.25h.25a1.5 1.5 0 001.5-1.5zM12.5 14.546a1.5 1.5 0 00-1.5 1.5v6a1.5 1.5 0 001.5 1.5h.25A.25.25 0 0013 23.3v-8.5a.25.25 0 00-.25-.25zM21 14.046a3 3 0 00-3-3h-1a3 3 0 00-3 3V23.3a.25.25 0 00.25.25h6.5a.25.25 0 00.25-.25zm-2 .25a.25.25 0 01-.25.25h-2.5A.25.25 0 0116 14.3v-.25a1 1 0 011-1h1a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});