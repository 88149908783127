define("ember-svg-jar/inlined/phone-book", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-book</title><path d=\"M19.75 4V2a2 2 0 00-2-2H5.25a3 3 0 00-3 3v18a3 3 0 003 3h14.5a2 2 0 002-2V6a2 2 0 00-2-2zm-5.437 12.035l.445-.445a1.261 1.261 0 011.784 0l1.338 1.338a1.261 1.261 0 010 1.784l-.733.733a1.892 1.892 0 01-2.381.239 29.277 29.277 0 01-8.2-8.2A1.888 1.888 0 016.8 9.1l.734-.735a1.265 1.265 0 011.786 0l1.34 1.343a1.258 1.258 0 010 1.783l-.446.446a29.959 29.959 0 004.099 4.098zM5.25 2H17.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H5.25a1 1 0 010-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});