define("ember-svg-jar/inlined/paginate-filter-10-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paginate-filter-10-alternate</title><path d=\"M24 1.982a2 2 0 00-2-2H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2zm-2 16a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5v-15.5a.5.5 0 01.5-.5h15.5a.5.5 0 01.5.5z\"/><path d=\"M20.5 22.982a1 1 0 00-1-1h-17a.5.5 0 01-.5-.5v-17a1 1 0 00-2 0v17.5a2 2 0 002 2h17.5a1 1 0 001-1z\"/><path d=\"M12 13.482h-.25a.25.25 0 01-.25-.25v-6.25a1.5 1.5 0 00-2.437-1.171L7.375 7.16a1 1 0 001.249 1.563l.875-.7v5.21a.25.25 0 01-.25.25H8a1 1 0 100 2h4a1 1 0 100-2zM17 5.482a3.505 3.505 0 00-3.5 3.5v3a3.5 3.5 0 007 0v-3a3.5 3.5 0 00-3.5-3.5zm1.5 6.5a1.5 1.5 0 01-3 0v-3a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});