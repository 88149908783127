define("ember-svg-jar/inlined/style-three-pin-photographer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-photographer</title><path d=\"M16.5 17l.001-.5H16.5v.5z\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm2.5 3.5a2 2 0 11-2 2 2 2 0 012-2zM9.25 17.25a.75.75 0 01-.75-.75V7.56a.249.249 0 00-.083-.186.252.252 0 00-.2-.062L6.556 7.5a.525.525 0 01-.056 0A.5.5 0 016 7V4.5a.5.5 0 01.167-.373A.516.516 0 016.556 4l4.5.5a.5.5 0 01.444.5v1.5a.5.5 0 01-.444.5l-.834.093a.25.25 0 00-.222.245V16.5a.75.75 0 01-.75.75zm8.25-.75a1 1 0 01-2 0v-3.325a.226.226 0 00-.01-.069l-.345-1.21a.25.25 0 00-.219-.18 4.681 4.681 0 01-3.82-2.316.75.75 0 111.3-.746 3.007 3.007 0 001.837 1.41.238.238 0 00.295-.294 1 1 0 011.445-1.15 1 1 0 01.478.6l1 3.5a1 1 0 01.039.28z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});