define("ember-svg-jar/inlined/download-arrow-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>download-arrow-1</title><path d=\"M10.561 13.8a1.119 1.119 0 00.09.1 1.91 1.91 0 002.7 0 1.119 1.119 0 00.09-.1L20.4 4.463a.954.954 0 00-.766-1.523H4.371a.954.954 0 00-.766 1.523zM22.491 18.2H1.509a1.431 1.431 0 000 2.861h20.982a1.431 1.431 0 000-2.861z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});