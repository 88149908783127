define("ember-svg-jar/inlined/like-plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>like-plus</title><path d=\"M21 .5H3a3 3 0 00-3 3v17a3 3 0 003 3h18a3 3 0 003-3v-17a3 3 0 00-3-3zm-19 3a1 1 0 011-1h4.25a.5.5 0 01.5.5v2a.5.5 0 01-.5.5H2.5A.5.5 0 012 5zm20 0V5a.5.5 0 01-.5.5h-4.75a.5.5 0 01-.5-.5V3a.5.5 0 01.5-.5H21a1 1 0 011 1zM14.75 5a.5.5 0 01-.5.5h-4.5a.5.5 0 01-.5-.5V3a.5.5 0 01.5-.5h4.5a.5.5 0 01.5.5zm-6.5 11a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2.25a.25.25 0 00.25-.25V10.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2.25A.25.25 0 0014 13h2.25a.5.5 0 01.5.5v2a.5.5 0 01-.5.5H14a.25.25 0 00-.25.25v2.25a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2.25a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});