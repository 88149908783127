define("ember-svg-jar/inlined/security-officer-animal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>security-officer-animal</title><path d=\"M18.451 6.5a.25.25 0 00-.25.25v.75a2.5 2.5 0 005 .008V6.75a.25.25 0 00-.25-.25zM16.744 5.5h6.372a.5.5 0 00.475-.342l.329-.988a1 1 0 00-.707-1.286l-5.034-1.258a.742.742 0 00-.735.218.751.751 0 00-.161.746l.3.91h-.842a1 1 0 000 2zM23.5 12h-1.411a.5.5 0 00-.47.31l-.64 1.61a.262.262 0 01-.231.16.251.251 0 01-.23-.16l-.65-1.61a.489.489 0 00-.46-.31h-1.742a2 2 0 00-1.416.587l-4.6 4.6a1.245 1.245 0 001.761 1.76l3.905-3.892a.25.25 0 01.427.177V21.5a.5.5 0 01-.5.5H1a1 1 0 000 2h22.5a.5.5 0 00.5-.5v-11a.5.5 0 00-.5-.5zM1.493 8h1v1.5a1 1 0 002 0V8h1v1.5a1 1 0 002 0V7.53A4.5 4.5 0 0010 3.5h1.251a1 1 0 000-2H9.788A2.5 2.5 0 005 2.5v.793L1.14 7.146A.5.5 0 001.493 8z\"/><path d=\"M3.156 19.426a1.009 1.009 0 00.906.574h2.866a1 1 0 00.772-1.641l-1.436-1.717a1.034 1.034 0 00-1.537 0L3.293 18.36a1.008 1.008 0 00-.137 1.066z\"/><circle cx=\"5.495\" cy=\"13.5\" r=\"1.5\"/><circle cx=\"1.993\" cy=\"16\" r=\"1.5\"/><circle cx=\"8.997\" cy=\"16\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});