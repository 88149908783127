define("ember-svg-jar/inlined/video-player-movie-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-player-movie-2</title><path d=\"M22.5 4h-21A1.5 1.5 0 000 5.5v13A1.5 1.5 0 001.5 20h21a1.5 1.5 0 001.5-1.5v-13A1.5 1.5 0 0022.5 4zm-5 8A5.5 5.5 0 1112 6.5a5.5 5.5 0 015.5 5.5z\"/><path d=\"M9.75 9.341v5.318a.424.424 0 00.634.367l4.653-2.658a.424.424 0 000-.736l-4.653-2.658a.423.423 0 00-.634.367zM1.25 2.5h21.5a1.25 1.25 0 000-2.5H1.25a1.25 1.25 0 000 2.5zM22.75 21.5H1.25a1.25 1.25 0 000 2.5h21.5a1.25 1.25 0 000-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});