define("ember-svg-jar/inlined/artificial-arm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>artificial-arm</title><path d=\"M13.322 14.651a.247.247 0 00-.185-.073.244.244 0 00-.18.086l-2.3 2.649a1.709 1.709 0 01-1.228.588 1.666 1.666 0 01-1.268-.5.709.709 0 00-1 0l-3.05 3.054a1.262 1.262 0 00-.372.9v1.672a.25.25 0 00.25.25h4.75a.5.5 0 00.382-.177l5.5-6.5a.5.5 0 00-.028-.677z\"/><path d=\"M2.739 21.353a2.259 2.259 0 01.661-1.605L6.457 16.7a1.71 1.71 0 012.414 0 .765.765 0 00.529.2.708.708 0 00.508-.243l2.367-2.731a.251.251 0 00-.012-.341l-2.67-2.664a.5.5 0 00-.685-.021L.416 18.412a.5.5 0 00-.169.375v3.988a.5.5 0 00.5.5h1.742a.25.25 0 00.25-.25zm6.781-7.3a.75.75 0 110-1.06.748.748 0 010 1.062zM23.753 7.587a1.243 1.243 0 00-.364-.884l-3.91-3.91a1.252 1.252 0 00-1.768 0L15.59 4.915a.75.75 0 01-1.061-1.061l.536-.568a1.5 1.5 0 00-2.122-2.122l-1.072 1.073a6 6 0 00.026 8.511c.631.623 1.935 1.836 3.22 3.122a2.007 2.007 0 002.834.005l5.438-5.4a1.245 1.245 0 00.364-.888z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});