define("ember-svg-jar/inlined/keyboard-wireless-gaming", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-wireless-gaming</title><path d=\"M6.821 4.819a9.484 9.484 0 0110.358 0 1 1 0 101.112-1.662 11.335 11.335 0 00-12.582 0 1 1 0 001.112 1.662z\"/><path d=\"M7.474 6.621a1 1 0 001.11 1.664 6.255 6.255 0 016.833 0 1 1 0 101.108-1.664 8.287 8.287 0 00-9.051 0zM24 14.251a2.5 2.5 0 00-2.5-2.5h-3.689a.25.25 0 01-.177-.074l-.78-.78a.5.5 0 00-.354-.146h-9a.5.5 0 00-.354.146l-.78.78a.25.25 0 01-.177.074H2.5a2.5 2.5 0 00-2.5 2.5v5a2.5 2.5 0 002.5 2.5h3.689a.249.249 0 01.177.073l.78.78a.5.5 0 00.354.147h9a.5.5 0 00.354-.147l.78-.78a.249.249 0 01.177-.073H21.5a2.5 2.5 0 002.5-2.5zm-5.75.5h-.5a.5.5 0 010-1h.5a.5.5 0 010 1zm.5 1.5a.5.5 0 01-.5.5h-.5a.5.5 0 010-1h.5a.5.5 0 01.5.5zm-3-2a.5.5 0 01-.5.5h-.5a.5.5 0 010-1h.5a.5.5 0 01.5.5zm0 2a.5.5 0 01-.5.5h-.5a.5.5 0 010-1h.5a.5.5 0 01.5.5zm-3.5-1.5h-.5a.5.5 0 010-1h.5a.5.5 0 010 1zm.5 1.5a.5.5 0 01-.5.5h-.5a.5.5 0 010-1h.5a.5.5 0 01.5.5zm-3-2a.5.5 0 01-.5.5h-.5a.5.5 0 010-1h.5a.5.5 0 01.5.5zm-1 1.5h.5a.5.5 0 010 1h-.5a.5.5 0 010-1zm7.75 3a.5.5 0 010 1h-9a.5.5 0 010-1zm-10.25-2h-.5a.5.5 0 010-1h.5a.5.5 0 010 1zm0-2h-.5a.5.5 0 110-1h.5a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});