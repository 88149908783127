define("ember-svg-jar/inlined/crime-woman-inmate.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-woman-inmate</title><path d=\"M10.47 12.208a.75.75 0 00-.3 1.017c.394.725 1.233.211 1.253.205a2.032 2.032 0 01.58-.083 2.009 2.009 0 01.576.082c.146.045.872.485 1.25-.2a.751.751 0 00-.3-1.018 3.251 3.251 0 00-1.527-.361H12a3.208 3.208 0 00-1.53.358zM9.5 10.634h1a.75.75 0 000-1.5h-1a.75.75 0 000 1.5zM13.5 10.634h1a.75.75 0 000-1.5h-1a.75.75 0 000 1.5zM.5 21.867a.5.5 0 00-.5.495v1.022a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-1.022a.5.5 0 00-.5-.495zM22.594 20.367a.5.5 0 00.387-.816 4.576 4.576 0 00-1.955-1.4l-4.887-1.235a.5.5 0 00-.409.075 7.06 7.06 0 01-3.751 1.39A7.054 7.054 0 018.234 17a.5.5 0 00-.409-.075L2.92 18.17a4.475 4.475 0 00-1.91 1.383.5.5 0 00.389.814z\"/><path d=\"M7.908 15.09a6.742 6.742 0 004.071 2.044 6.753 6.753 0 004.1-2.068 2.2 2.2 0 001.774.8 5.057 5.057 0 003.54-1.7.5.5 0 00-.232-.788 2.85 2.85 0 01-1.509-1.361 6.619 6.619 0 01-.605-1.447 3.147 3.147 0 00.206-1.042 2.081 2.081 0 00-.5-1.47V3.482A2.745 2.745 0 0016.809.845 16.2 16.2 0 007.2.846a2.746 2.746 0 00-1.95 2.638V8a2.066 2.066 0 00-.54 1.523 3.136 3.136 0 00.229 1.1 6.494 6.494 0 01-.607 1.423 2.832 2.832 0 01-1.49 1.331.5.5 0 00-.232.788 5.057 5.057 0 003.54 1.705 2.235 2.235 0 001.758-.78zm9.026-10.821a.248.248 0 01.217.042.252.252 0 01.1.2v1.032a.25.25 0 01-.187.242A20.084 20.084 0 0112 6.444a20.084 20.084 0 01-5.063-.659.25.25 0 01-.187-.242V4.51a.25.25 0 01.316-.241 18.569 18.569 0 009.868 0zm-4.955 11.365c-1.032 0-3.83-1.491-4.207-3.738a1.747 1.747 0 00-1-1.307 1.273 1.273 0 01-.559-1.1.632.632 0 01.1-.432.747.747 0 00.44-.678v-.29a.25.25 0 01.1-.2.253.253 0 01.224-.037 16.6 16.6 0 004.916.785 16.691 16.691 0 004.932-.789.25.25 0 01.326.239v.179a.741.741 0 00.395.79c.024.012.11.147.105.438a1.278 1.278 0 01-.559 1.1 1.75 1.75 0 00-1 1.306c-.382 2.243-3.18 3.734-4.213 3.734z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});