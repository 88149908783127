define("ember-svg-jar/inlined/modern-architecture-building-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-architecture-building-1</title><path d=\"M23 22a.5.5 0 01-.5-.5v-20A1.5 1.5 0 0021 0H3a1.5 1.5 0 00-1.5 1.5v5.25a.5.5 0 00.5.5h13a.5.5 0 01.5.5V21.5a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-4.25a.5.5 0 00-.5-.5H9a.5.5 0 01-.354-.146.512.512 0 01-.146-.354v-7a.5.5 0 00-.5-.5H2a.5.5 0 00-.5.5V21.5a.5.5 0 01-.5.5 1 1 0 000 2h3.5a.5.5 0 00.5-.5v-2.75a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v2.75a.5.5 0 00.5.5H23a1 1 0 000-2zM6 16.5H4A.75.75 0 014 15h2a.75.75 0 010 1.5zM6 13H4a.75.75 0 010-1.5h2A.75.75 0 016 13zm14 6h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm0-3.5h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm0-3.5h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm0-3.5h-2A.75.75 0 0118 7h2a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});