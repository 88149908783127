define("ember-svg-jar/inlined/people-man-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-1</title><path d=\"M7.47 16.1a13.971 13.971 0 00-5.788 2.431 2.413 2.413 0 00-.947 1.936v3.043a.489.489 0 00.489.49h21.552a.489.489 0 00.489-.49v-3.043a2.413 2.413 0 00-.947-1.936A13.971 13.971 0 0016.53 16.1a.483.483 0 00-.469.171c-.113.135-1.192 2.829-4.061 2.829-2.782 0-3.975-2.728-4.06-2.83a.493.493 0 00-.47-.17z\"/><path d=\"M14.53 15.184a6.864 6.864 0 004.327-6.368V6.857a6.857 6.857 0 00-13.714 0v1.959a6.846 6.846 0 009.387 6.368zM7.1 8.816V7.1a.245.245 0 01.245-.245h.735c1.259 0 3.809.31 5.7-1.752a.243.243 0 01.18-.08.248.248 0 01.181.08 5.18 5.18 0 002.6 1.544.214.214 0 01.161.205v1.964a4.9 4.9 0 11-9.8 0z\"/><circle cx=\"13.959\" cy=\"9.061\" r=\".98\"/><circle cx=\"10.041\" cy=\"9.061\" r=\".98\"/><path d=\"M12.08 12.607a2.933 2.933 0 00.846-.123 2.293 2.293 0 00.427-.177.626.626 0 00-.3-1.177H11.1a.626.626 0 00-.3 1.178 2.309 2.309 0 00.426.176 2.945 2.945 0 00.849.124z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});