define("ember-svg-jar/inlined/style-three-pin-pill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-pill</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm4.77 12.53l-4.24 4.243a3.749 3.749 0 11-5.3-5.3l4.24-4.243a3.748 3.748 0 115.3 5.3z\"/><path d=\"M15.71 11.47l-1.616 1.616a.5.5 0 01-.708 0l-2.472-2.472a.5.5 0 010-.708L12.53 8.29a2.249 2.249 0 113.18 3.18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});