define("ember-svg-jar/inlined/3d-print-ball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>3d-print-ball</title><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2zM10 2a.5.5 0 01.5.5v1.333a.5.5 0 00.1.3l1 1.334a.5.5 0 00.8 0l1-1.334a.5.5 0 00.1-.3V2.5A.5.5 0 0114 2h7.5a.5.5 0 01.5.5v17a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-17a.5.5 0 01.5-.5zm3.25 20.75A.75.75 0 1114 22a.75.75 0 01-.75.75zm3.5 0a.75.75 0 11.75-.75.75.75 0 01-.75.75zm3.5 0A.75.75 0 1121 22a.75.75 0 01-.75.75z\"/><path d=\"M7 14a5 5 0 105-5 5.006 5.006 0 00-5 5zm4.944-3.056a.5.5 0 010 1 2.169 2.169 0 00-2.167 2.167.5.5 0 01-1 0 3.171 3.171 0 013.167-3.167zM8.47 6.53a.75.75 0 001.06-1.06L8.28 4.22a.75.75 0 00-1.06 1.06zM6 8.75h1.5a.75.75 0 000-1.5H6a.75.75 0 000 1.5zM15.53 6.53l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a.75.75 0 001.06 1.06zM15.75 8a.75.75 0 00.75.75H18a.75.75 0 000-1.5h-1.5a.75.75 0 00-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});