define("ember-svg-jar/inlined/tiger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tiger</title><path d=\"M22.08 10.08a10.514 10.514 0 00-.073-1.238A5.794 5.794 0 0024 4.482C24 .3 19.8 0 19.2 0a5.269 5.269 0 00-2.956.905 10.4 10.4 0 00-8.488 0A5.269 5.269 0 004.8 0C4.2 0 0 .3 0 4.482a5.794 5.794 0 001.993 4.36 10.514 10.514 0 00-.073 1.238 9.636 9.636 0 01-1.076 5A6.432 6.432 0 000 18.24 5.633 5.633 0 005.76 24h12.48A5.633 5.633 0 0024 18.24a6.432 6.432 0 00-.844-3.16 9.636 9.636 0 01-1.076-5zm-.611-3.523A9.889 9.889 0 0020.4 4.428a6.174 6.174 0 011.644-.508 3.849 3.849 0 01-.575 2.637zM1.961 3.92a6.174 6.174 0 011.644.508 9.889 9.889 0 00-1.074 2.129 3.849 3.849 0 01-.57-2.637zM18.24 22.08H5.76a3.712 3.712 0 01-3.449-2.066 3.829 3.829 0 001.049.146H4.8a.96.96 0 000-1.92H3.36a1.916 1.916 0 01-1.4-.608 5.31 5.31 0 01.621-1.732 3.836 3.836 0 002.475.9.96.96 0 000-1.92 1.916 1.916 0 01-1.671-.974 13.826 13.826 0 00.455-3.826A8.074 8.074 0 0112 1.92a7.949 7.949 0 018.16 8.16 13.826 13.826 0 00.455 3.826 1.916 1.916 0 01-1.671.974.96.96 0 000 1.92 3.836 3.836 0 002.475-.9 5.352 5.352 0 01.622 1.734 1.916 1.916 0 01-1.4.608H19.2a.96.96 0 000 1.92h1.44a3.829 3.829 0 001.049-.146 3.712 3.712 0 01-3.449 2.064z\"/><circle cx=\"8.4\" cy=\"12\" r=\"1.2\"/><circle cx=\"15.6\" cy=\"12\" r=\"1.2\"/><path d=\"M15.84 16.08a.96.96 0 00-.96.96.961.961 0 01-.96.96c-.989 0-.956-1.066-.99-1.2a1.926 1.926 0 00.881-1.04.481.481 0 00-.453-.64h-2.716a.481.481 0 00-.453.64 1.926 1.926 0 00.881 1.04c-.034.134 0 1.2-.99 1.2a.961.961 0 01-.96-.96.96.96 0 00-1.92 0 2.879 2.879 0 004.8 2.145 2.879 2.879 0 004.8-2.145.96.96 0 00-.96-.96zM9.087 8.817a6.982 6.982 0 015.826 0 .96.96 0 10.894-1.7 8.826 8.826 0 00-7.614 0 .96.96 0 10.894 1.7zM8.91 5.708a11.4 11.4 0 016.18 0 .961.961 0 00.54-1.844 13.343 13.343 0 00-7.26 0 .961.961 0 00.54 1.844z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});