define("ember-svg-jar/inlined/single-neutral-shopping-bag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-shopping-bag</title><path d=\"M5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M23.972 21.242l-.832-5.406a2.152 2.152 0 00-1.916-1.825.249.249 0 01-.224-.248V13.5a3 3 0 00-6 0v.263a.249.249 0 01-.224.248 2.152 2.152 0 00-1.916 1.825l-.833 5.408a2.44 2.44 0 00-.027.362A2.4 2.4 0 0014.394 24h7.212A2.4 2.4 0 0024 21.606a2.358 2.358 0 00-.028-.364zM18 12.5a1 1 0 011 1v.25a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-.25a1 1 0 011-1zM5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M23.972 21.242l-.832-5.406a2.152 2.152 0 00-1.916-1.825.249.249 0 01-.224-.248V13.5a3 3 0 00-6 0v.263a.249.249 0 01-.224.248 2.152 2.152 0 00-1.916 1.825l-.833 5.408a2.44 2.44 0 00-.027.362A2.4 2.4 0 0014.394 24h7.212A2.4 2.4 0 0024 21.606a2.358 2.358 0 00-.028-.364zM18 12.5a1 1 0 011 1v.25a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-.25a1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});