define("ember-svg-jar/inlined/day-sunset-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>day-sunset-2</title><path d=\"M5.994 16.25a1 1 0 001-1 5 5 0 0110 0 1 1 0 002 0 7 7 0 00-14 0 1 1 0 001 1zM1.744 16.25h1.5a1 1 0 000-2h-1.5a1 1 0 000 2zM5.1 9.77a1 1 0 001.414-1.414L5.453 7.3a1 1 0 10-1.414 1.409zM11.994 7.5a1 1 0 001-1V5a1 1 0 00-2 0v1.5a1 1 0 001 1zM19.744 15.25a1 1 0 001 1h1.5a1 1 0 000-2h-1.5a1 1 0 00-1 1zM18.182 10.063a1 1 0 00.707-.293l1.06-1.061A1 1 0 1018.535 7.3l-1.06 1.061a1 1 0 00.707 1.707zM22.994 18H1.006a1 1 0 000 2h21.988a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});