define("ember-svg-jar/inlined/road-sign-bike-lane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-bike-lane</title><path d=\"M9.75 13.75v1a.25.25 0 00.5 0v-1c0-.275-.5-.275-.5 0zM6.5 6a.25.25 0 00-.25.25v.5a.25.25 0 00.5 0v-.5A.25.25 0 006.5 6zM6.5 8.5a.25.25 0 00-.25.25v.5a.25.25 0 00.5 0v-.5a.25.25 0 00-.25-.25z\"/><path d=\"M23.25 0H.75A.75.75 0 000 .75v22.5a.75.75 0 00.75.75h22.5a.75.75 0 00.75-.75V.75a.75.75 0 00-.75-.75zm-14 5.25a.75.75 0 011.5 0v5a.75.75 0 01-1.5 0zM6.5 18.5h-1a1.752 1.752 0 01-1.75-1.75v-4a.75.75 0 011.5 0v4a.25.25 0 00.25.25h1a.75.75 0 010 1.5zm0-7.5h-1a.75.75 0 01-.75-.75v-5a.75.75 0 01.75-.75h1a1.752 1.752 0 011.75 1.75v.5a1.728 1.728 0 01-.237.874.252.252 0 000 .252 1.728 1.728 0 01.237.874v.5A1.752 1.752 0 016.5 11zm5.25 6.75a.75.75 0 01-1.5 0v-1a.25.25 0 00-.5 0v1a.75.75 0 01-1.5 0v-4a1.75 1.75 0 013.5 0zm0-7.5v-5a.75.75 0 011.5 0 .127.127 0 00.226.079l.439-.548a.75.75 0 111.17.938l-1.5 1.875a.25.25 0 000 .312l1.5 1.875a.75.75 0 11-1.17.938l-.439-.548a.127.127 0 00-.226.079.75.75 0 01-1.5 0zm4.5 7.5a.749.749 0 01-.608.736.691.691 0 01-.142.014.749.749 0 01-.7-.472l-.143-.357a.213.213 0 00-.411.079.75.75 0 01-1.5 0v-5a.75.75 0 011.446-.278l.143.357a.213.213 0 00.411-.079.75.75 0 011.5 0zm0-8.5v-3A1.752 1.752 0 0118 4.5h1A.75.75 0 0119 6h-1a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h1A.75.75 0 0119 9h-1a.25.25 0 000 .5h1a.75.75 0 010 1.5h-1a1.752 1.752 0 01-1.75-1.75zM19 15h1a.75.75 0 010 1.5h-1a.25.25 0 000 .5h1a.75.75 0 010 1.5h-1a1.752 1.752 0 01-1.75-1.75v-3A1.752 1.752 0 0119 12h1a.75.75 0 010 1.5h-1a.25.25 0 00-.25.25v1A.25.25 0 0019 15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});