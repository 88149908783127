define("ember-svg-jar/inlined/single-man-actions-edit-2.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-edit-2</title><path d=\"M.62 18.062h1.791a.246.246 0 01.246.227l.443 5.257a.494.494 0 00.489.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h1.791a.494.494 0 00.495-.5V14.1a5.443 5.443 0 00-10.886 0v3.464a.494.494 0 00.495.498z\"/><path d=\"M1.362 4.206A4.206 4.206 0 105.568 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227a2.229 2.229 0 01-2.227-2.227 2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM15.985 21.969a.494.494 0 00.7 0l6.436-6.435a2.6 2.6 0 00-.2-3.842 2.681 2.681 0 00-3.568.271L13.015 18.3a.5.5 0 000 .7zM11.6 23.963l2.658-.531a.5.5 0 00.252-.835l-2.125-2.126a.5.5 0 00-.835.253l-.532 2.658a.494.494 0 00.485.591.457.457 0 00.097-.01z\"/><path d=\"M.62 18.062h1.791a.246.246 0 01.246.227l.443 5.257a.494.494 0 00.489.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h1.791a.494.494 0 00.495-.5V14.1a5.443 5.443 0 00-10.886 0v3.464a.494.494 0 00.495.498z\"/><path d=\"M1.362 4.206A4.206 4.206 0 105.568 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227a2.229 2.229 0 01-2.227-2.227 2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM15.985 21.969a.494.494 0 00.7 0l6.436-6.435a2.6 2.6 0 00-.2-3.842 2.681 2.681 0 00-3.568.271L13.015 18.3a.5.5 0 000 .7zM11.6 23.963l2.658-.531a.5.5 0 00.252-.835l-2.125-2.126a.5.5 0 00-.835.253l-.532 2.658a.494.494 0 00.485.591.457.457 0 00.097-.01z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});