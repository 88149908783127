define("ember-svg-jar/inlined/single-woman-actions-upload.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-upload</title><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.85 6.467a.5.5 0 01-.45.283H19a.5.5 0 00-.5.5v2.25a1 1 0 01-2 0v-2.25a.5.5 0 00-.5-.5h-.9a.5.5 0 01-.391-.812l2.4-3a.516.516 0 01.781 0l2.4 3a.5.5 0 01.06.529zM10.654 12.375a7.368 7.368 0 00-3.448-.875 7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h8.515a.5.5 0 00.5-.47 7.93 7.93 0 011.321-3.93.5.5 0 00-.182-.72zM1.564 10.442a1 1 0 001.383-.3 5.153 5.153 0 00.454-.9.25.25 0 01.411-.083 4.728 4.728 0 006.787 0 .25.25 0 01.411.082 5.2 5.2 0 00.454.9 1 1 0 001.681-1.081 4.561 4.561 0 01-.645-2.511V5.3a5.3 5.3 0 10-10.593 0v1.248a4.57 4.57 0 01-.64 2.512 1 1 0 00.297 1.382zM7.206 8.6a2.7 2.7 0 01-2.741-2.35.5.5 0 01.324-.578 5.693 5.693 0 002.245-1.44.25.25 0 01.361 0 5.708 5.708 0 002.229 1.437.5.5 0 01.323.576A2.707 2.707 0 017.206 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});