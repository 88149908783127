define("ember-svg-jar/inlined/real-estate-action-building-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-building-shield</title><path d=\"M6.724 6.053l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 000 6.5V14a.5.5 0 00.5.5H2a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5A.5.5 0 007 14V6.5a.5.5 0 00-.276-.447zM5 10.5H2a.5.5 0 010-1h3a.5.5 0 010 1zM5 8H2a.5.5 0 010-1h3a.5.5 0 010 1zM11.379 10.569a.086.086 0 00-.079-.069H10a.5.5 0 010-1h3a.5.5 0 01.4.2.5.5 0 00.507.195q.329-.07.677-.13A.5.5 0 0015 9.276V3a.5.5 0 00-.308-.462l-6-2.5A.5.5 0 008 .5V14a.5.5 0 00.5.5H9a.5.5 0 00.5-.5v-.544a3.044 3.044 0 011.83-2.791.084.084 0 00.049-.096zM10 4.5h3a.5.5 0 010 1h-3a.5.5 0 010-1zM10 7h3a.5.5 0 010 1h-3a.5.5 0 010-1z\"/><path d=\"M23.072 12.04A16.443 16.443 0 0017.478 11a16.018 16.018 0 00-5.55 1.04A1.545 1.545 0 0011 13.456v3.393c0 2.593 1.678 5.464 5.743 7.015a2.057 2.057 0 001.471 0C22.289 22.309 24 19.44 24 16.849v-3.393a1.545 1.545 0 00-.928-1.416zM20.5 17.25h-1.75a.5.5 0 00-.5.5v1.75a.75.75 0 01-1.5 0v-1.75a.5.5 0 00-.5-.5H14.5a.75.75 0 010-1.5h1.75a.5.5 0 00.5-.5V13.5a.75.75 0 011.5 0v1.75a.5.5 0 00.5.5h1.75a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});