define("ember-svg-jar/inlined/road-sign-turn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-turn</title><path d=\"M22.823 10.762l-9.586-9.585a1.752 1.752 0 00-2.474 0l-9.586 9.585a1.753 1.753 0 000 2.475l9.586 9.586a1.75 1.75 0 002.474 0l9.586-9.586a1.753 1.753 0 000-2.475zM9.5 15a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1.25a.25.25 0 00.25-.25V6.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1.25a.25.25 0 00.25.25h1.25a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1.25a.25.25 0 00-.25.25v6.25a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1.25a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});