define("ember-svg-jar/inlined/primitive-symbols-animal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>primitive-symbols-animal</title><path d=\"M21.1 3.458a.754.754 0 00-.541-.315l-4.123-.392a.238.238 0 01-.134-.062.968.968 0 00-.56-.255L14.2 2.287a.252.252 0 01-.223-.207L13.766.832a1 1 0 10-1.973.334s.052.368.092.542-.185.189-.295.134a2.9 2.9 0 01-1.3-1.311.993.993 0 00-1.338-.413 1.008 1.008 0 00-.432 1.345 4.82 4.82 0 003.6 2.62.238.238 0 01.2.191c.051.278.152.827.2 1.074a.242.242 0 01-.069.219L6.03 11.988a1.125 1.125 0 01-.743-.028.441.441 0 01-.182-.36 1 1 0 10-2 0 2.43 2.43 0 002.311 2.436c.375 0 .164.256.058.407s-2.183 2.745-2.183 2.745a2.933 2.933 0 00-.46 2.319L3.3 21.6a2.783 2.783 0 002.735 2.194 1 1 0 00.027-2 .863.863 0 01-.809-.629l-.467-2.1a.93.93 0 01.147-.734s.627-.917.746-1.065.242-.094.269.1l.309 2.16a2.456 2.456 0 00.477.75l2.314 2.7A2.926 2.926 0 0011.265 24h.561a1 1 0 000-2h-.558a.928.928 0 01-.7-.323l-2.315-2.7c-.05-.059-.206-.174-.07-.369l.977-1.826a6.3 6.3 0 011.074-1.656s2.432-2.72 2.589-2.877.557.022.557.022a.933.933 0 01.353 1.21l-.648 1.3a1 1 0 00.447 1.342 1.113 1.113 0 001.342-.447l.648-1.295a2.926 2.926 0 00-.257-3.013c-.071-.1-.109-.308.06-.338l1.991-.362a.25.25 0 01.291.205l.2 1.209a.928.928 0 01-.259.807l-.263.263a1 1 0 000 1.414 1.107 1.107 0 001.414 0l.264-.263a2.927 2.927 0 00.817-2.55l-.32-1.914a1.1 1.1 0 00-.632-.864s-3.71-1.391-3.848-1.446-.149-.23-.036-.372.222-.292.323-.4a.629.629 0 01.427-.185h2.327a3.289 3.289 0 003.2-2.5.747.747 0 00-.121-.614z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});