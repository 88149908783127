define("ember-svg-jar/inlined/archive-books", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>archive-books</title><path d=\"M5.5 0h-4A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h4A1.5 1.5 0 007 22.5v-21A1.5 1.5 0 005.5 0zm-1 7.75a.25.25 0 01.25.25v7a1.25 1.25 0 01-2.5 0V8a.25.25 0 01.25-.25zm-2-2a.25.25 0 01-.25-.25V4a1.25 1.25 0 012.5 0v1.5a.25.25 0 01-.25.25zm1 12.75A1.5 1.5 0 112 20a1.5 1.5 0 011.5-1.5zM14 0h-4a1.5 1.5 0 00-1.5 1.5v21A1.5 1.5 0 0010 24h4a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0014 0zm-1 7.75a.25.25 0 01.25.25v7a1.25 1.25 0 01-2.5 0V8a.25.25 0 01.25-.25zm-2-2a.25.25 0 01-.25-.25V4a1.25 1.25 0 012.5 0v1.5a.25.25 0 01-.25.25zm1 12.75a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zM22.5 0h-4A1.5 1.5 0 0017 1.5v21a1.5 1.5 0 001.5 1.5h4a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-1 7.75a.25.25 0 01.25.25v7a1.25 1.25 0 01-2.5 0V8a.25.25 0 01.25-.25zm-2-2a.25.25 0 01-.25-.25V4a1.25 1.25 0 012.5 0v1.5a.25.25 0 01-.25.25zm1 12.75A1.5 1.5 0 1119 20a1.5 1.5 0 011.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});