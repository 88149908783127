define("ember-svg-jar/inlined/electronics-variable-capacitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>electronics-variable-capacitor</title><path d=\"M20 12.5h-4.482a.25.25 0 01-.177-.074l-1-1a.25.25 0 01.177-.426H20a1 1 0 000-2h-6.75a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v6.483a.25.25 0 01-.427.177L8.164 5.251a.25.25 0 010-.354l.866-.866a.75.75 0 00-.53-1.28H5a.75.75 0 00-.75.75V7a.75.75 0 001.28.53l.87-.865a.25.25 0 01.354 0l1.905 1.909A.25.25 0 018.482 9H4a1 1 0 000 2h6.982a.249.249 0 01.177.073l1 1a.25.25 0 01-.177.427H4a1 1 0 100 2h6.75a.25.25 0 01.25.25V23a1 1 0 102 0v-8.25a.25.25 0 01.25-.25h1.232a.249.249 0 01.177.073l3.134 3.134a1 1 0 001.414-1.414l-1.366-1.367a.25.25 0 01.177-.426H20a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});