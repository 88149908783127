define("ember-svg-jar/inlined/accessories-necklace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accessories-necklace</title><path d=\"M12 2.5C5.383 2.5 0 6.762 0 12s5.383 9.5 12 9.5 12-4.262 12-9.5-5.383-9.5-12-9.5zm-6.5 13A1.5 1.5 0 117 14a1.5 1.5 0 01-1.5 1.5zm8.759 1.216l-1.931 1.93a.5.5 0 01-.707 0l-1.767-1.767a.5.5 0 010-.707l1.767-1.772a.5.5 0 01.707 0l1.931 1.93a.271.271 0 010 .386zM12 11.5c-3.252 0-6-1.374-6-3s2.748-3 6-3 6 1.374 6 3-2.748 3-6 3zm7 4a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});