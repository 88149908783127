define("ember-svg-jar/inlined/smart-light-wifi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-light-wifi</title><path d=\"M9.226 19.5h-5a.5.5 0 00-.5.5 2.988 2.988 0 001.863 2.761.22.22 0 01.136.2V23a1 1 0 002 0v-.013a.251.251 0 01.153-.231A2.989 2.989 0 009.726 20a.5.5 0 00-.5-.5z\"/><circle cx=\"16.996\" cy=\"9.76\" r=\"1.749\"/><path d=\"M23.619 2.36a10.474 10.474 0 00-13.246 0 1 1 0 101.255 1.555 8.489 8.489 0 0110.737 0 1 1 0 101.254-1.555z\"/><path d=\"M12.871 5.472a1 1 0 001.255 1.556 4.537 4.537 0 015.74 0 1 1 0 101.255-1.556 6.525 6.525 0 00-8.25 0zM6.728 5.014a6.719 6.719 0 00-6.719 6.719 6.621 6.621 0 004.4 6.242.5.5 0 00.164.025h.906a.5.5 0 00.5-.5v-4.457a.25.25 0 00-.111-.208l-1.8-1.2a.749.749 0 11.831-1.247l1.694 1.129a.249.249 0 00.277 0l1.69-1.131a.75.75 0 11.832 1.247l-1.8 1.2a.251.251 0 00-.112.208v4.462a.5.5 0 00.5.5h.906a.489.489 0 00.169-.029 6.622 6.622 0 004.395-6.242 6.719 6.719 0 00-6.722-6.718z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});