define("ember-svg-jar/inlined/car-dashboard-window-rear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-dashboard-window-rear</title><path d=\"M23.461 4.466C15.505.33 8.435.33.536 4.467a1 1 0 00-.508 1.119l3 12.5a1 1 0 001.553.581q.786-.561 1.542-1.01a.25.25 0 01.377.215v3.763a1 1 0 002 0v-9.782a.25.25 0 01.25-.25H10a.749.749 0 00.592-1.21l-2.5-3.217a.776.776 0 00-1.184 0l-2.5 3.217A.749.749 0 005 11.6h1.25a.25.25 0 01.25.25v3.181a.252.252 0 01-.14.225q-.71.348-1.431.785a.249.249 0 01-.225.016.245.245 0 01-.147-.172L2.2 6.081a.251.251 0 01.136-.281c6.7-3.264 12.579-3.263 19.326 0a.248.248 0 01.134.283L19.44 15.9a.251.251 0 01-.371.156l-.194-.113a.25.25 0 00-.375.216v1.761a.249.249 0 00.115.21c.268.172.538.352.812.543a1 1 0 00.573.18.982.982 0 00.354-.065 1 1 0 00.618-.7l3-12.5a1 1 0 00-.511-1.122z\"/><path d=\"M19.674 11.183a.751.751 0 00-.082-.79l-2.5-3.217a.776.776 0 00-1.184 0l-2.5 3.217A.749.749 0 0014 11.6h1.25a.25.25 0 01.25.25v2.232a.25.25 0 01-.319.24 11.609 11.609 0 00-3.224-.471 11.137 11.137 0 00-2.258.239.25.25 0 00-.2.245v1.525a.25.25 0 00.308.244 9.408 9.408 0 015.526.358.249.249 0 01.166.235v4.936a1 1 0 002 0v-9.78a.25.25 0 01.25-.25H19a.75.75 0 00.674-.42z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});