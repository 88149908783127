define("ember-svg-jar/inlined/video-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-player</title><path d=\"M19.5 2h-15A4.505 4.505 0 000 6.5v11A4.505 4.505 0 004.5 22h15a4.505 4.505 0 004.5-4.5v-11A4.505 4.505 0 0019.5 2zm-3.565 11.342l-5.764 2.882A1.5 1.5 0 018 14.882V9.118a1.5 1.5 0 012.171-1.342l5.764 2.882a1.5 1.5 0 010 2.684z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});