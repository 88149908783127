define("ember-svg-jar/inlined/real-estate-action-house-star-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-house-star-1</title><path d=\"M23.722 11.8L12.724.3a1.038 1.038 0 00-1.446 0L.28 11.8A1 1 0 001 13.492h1.5a.5.5 0 01.5.5v8.5a1.5 1.5 0 001.5 1.5h15a1.5 1.5 0 001.5-1.5v-8.5a.5.5 0 01.5-.5H23a1 1 0 00.722-1.692zm-5.945.957l-2.348 2.093a.5.5 0 00-.126.573L16.6 18.4a.751.751 0 01-.192.88.774.774 0 01-.925.1l-3.236-1.822a.5.5 0 00-.49 0L8.52 19.386a.793.793 0 01-.924-.1.751.751 0 01-.191-.881L8.7 15.423a.5.5 0 00-.126-.573l-2.311-2.058a.774.774 0 01-.208-.85.73.73 0 01.7-.45h2.67a.5.5 0 00.459-.3l1.4-3.24a.82.82 0 011.425-.027l1.409 3.267a.5.5 0 00.459.3h2.663a.731.731 0 01.706.451.756.756 0 01-.169.815z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});