define("ember-svg-jar/inlined/renewable-energy-wind-turbine-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>renewable-energy-wind-turbine-1</title><path d=\"M.936 16.934a.8.8 0 00.461.141A1.032 1.032 0 001.761 17l6.612-2.425a.5.5 0 00.326-.431l.272-3.517a.5.5 0 00-.44-.534 8.383 8.383 0 00-5.968 1.2 5.464 5.464 0 00-2.036 4.639 1.137 1.137 0 00.409 1.002zM15.12 11.821l.367 4.743a.5.5 0 00.233.385 6.023 6.023 0 003.2 1.017 5.486 5.486 0 003.932-1.83 1.136 1.136 0 00.414-1 .863.863 0 00-.536-.63l-6.9-3.179a.5.5 0 00-.708.493zM10.13 7.8a.248.248 0 00.255-.009 3 3 0 014.105.855.249.249 0 00.442-.055 7.557 7.557 0 00.264-5.2A5.82 5.82 0 0011.426.11a1.145 1.145 0 00-1.082.046A.86.86 0 0010 .9v6.679a.248.248 0 00.13.221z\"/><path d=\"M23 22h-7.633a.5.5 0 01-.5-.462L14 10.316a2 2 0 00-4-.021l-.869 11.243a.5.5 0 01-.5.462H1a1 1 0 000 2h22a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});