define("ember-svg-jar/inlined/tags-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-share</title><path d=\"M13.537 14.01a.492.492 0 00.343-.143l5.347-5.456A3.978 3.978 0 0020.212 6V2a2 2 0 00-2-2l-4.26.006a3.933 3.933 0 00-2.411 1L.712 11.834a2 2 0 000 2.828L5.548 19.5a1.972 1.972 0 001.406.582h.012a1.972 1.972 0 001.41-.6l.877-.895a.491.491 0 00.136-.345 4.207 4.207 0 014.148-4.232zm3.175-8.51a2 2 0 11-2-2 2 2 0 012 2z\"/><path d=\"M23.874 13.749a2.746 2.746 0 10-5.437.539.252.252 0 01-.116.268l-2.666 1.6a.252.252 0 01-.288-.022 2.72 2.72 0 00-1.743-.634 2.75 2.75 0 102.055 4.559.249.249 0 01.28-.067l2.272.909a.25.25 0 01.157.246 2.758 2.758 0 10.681-1.706.252.252 0 01-.281.067l-2.264-.908a.251.251 0 01-.157-.253 2.122 2.122 0 00-.056-.638.249.249 0 01.116-.268l2.665-1.6a.25.25 0 01.288.022 2.741 2.741 0 004.494-2.116z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});