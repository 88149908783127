define("ember-svg-jar/inlined/charging-flash-wifi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>charging-flash-wifi</title><path d=\"M21.18 4.082a13.581 13.581 0 00-18.359 0 1 1 0 101.357 1.466 11.573 11.573 0 0115.643 0 1 1 0 101.359-1.466z\"/><path d=\"M18.189 6.955a9 9 0 00-12.378 0 1 1 0 001.375 1.451 7 7 0 019.628 0 1 1 0 001.375-1.453z\"/><path d=\"M15.1 14.49h-1.871a.25.25 0 01-.25-.25V8.99a.5.5 0 00-.891-.313l-5.9 7.371a.875.875 0 00.667 1.442h1.874a.25.25 0 01.25.25v5.25a.5.5 0 00.891.312l5.9-7.371a.874.874 0 00-.67-1.441z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});