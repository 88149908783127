define("ember-svg-jar/inlined/single-neutral-actions-video.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-video</title><path d=\"M9.5 15.5a2.986 2.986 0 011.867-2.764.5.5 0 00.1-.873A7.426 7.426 0 007.206 10.5a7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643H9a.5.5 0 00.5-.5z\"/><circle cx=\"7\" cy=\"4.75\" r=\"4.75\"/><path d=\"M23.371 15.517l-2.807.749a.251.251 0 01-.314-.242V15.5a1.5 1.5 0 00-1.5-1.5H12.5a1.5 1.5 0 00-1.5 1.5v7a1.5 1.5 0 001.5 1.5h6.25a1.5 1.5 0 001.5-1.5v-.524a.251.251 0 01.314-.242l2.807.749A.5.5 0 0024 22v-6a.5.5 0 00-.629-.483z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});