define("ember-svg-jar/inlined/car-dashboard-f", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-dashboard-f</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm2.519 18.51A2.51 2.51 0 1112.01 16a2.43 2.43 0 01.374.028.25.25 0 00.227-.084l6.132-7.1a1 1 0 011.514 1.308l-5.991 6.934a.249.249 0 00-.032.279 2.459 2.459 0 01.285 1.145zM8.5 6.75a2.252 2.252 0 012.25-2.25h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 00-.75.75v1.5a.25.25 0 00.25.25h1.5a.75.75 0 010 1.5h-1.5a.25.25 0 00-.25.25v3a.75.75 0 01-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});