define("ember-svg-jar/inlined/style-three-pin-barn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-barn</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm6.407 9.23a.5.5 0 01-.407.791h-.5a.5.5 0 00-.5.5V14a1 1 0 01-1 1h-1.5a.5.5 0 01-.5-.5v-.99a2 2 0 10-4 0v.99a.5.5 0 01-.5.5H8a1 1 0 01-1-1v-3.479a.5.5 0 00-.5-.5H6a.5.5 0 01-.407-.791l2.5-3.5a.5.5 0 01.207-.168l3.5-1.511a.5.5 0 01.4 0l3.5 1.511a.5.5 0 01.209.168z\"/><rect x=\"11\" y=\"7.021\" width=\"2\" height=\"2\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});