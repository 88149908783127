define("ember-svg-jar/inlined/alarm-clock-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>alarm-clock-1-alternate</title><path d=\"M21.5 12.252a9.5 9.5 0 10-15.375 7.461l-2.332 2.332a1 1 0 000 1.414 1.015 1.015 0 001.414 0L7.863 20.8a9.492 9.492 0 008.274 0l2.656 2.656a1.011 1.011 0 001.414 0 1 1 0 000-1.414l-2.332-2.332a9.489 9.489 0 003.625-7.458zm-17 0a7.5 7.5 0 117.5 7.5 7.509 7.509 0 01-7.5-7.5zM7.4 2.269a.5.5 0 00.129-.823 4.486 4.486 0 00-6.339 6.34.507.507 0 00.445.155.5.5 0 00.377-.284A11.019 11.019 0 017.4 2.269z\"/><path d=\"M19.5.252a4.485 4.485 0 00-3.033 1.194.5.5 0 00.129.823 11.019 11.019 0 015.388 5.388.5.5 0 00.376.284.51.51 0 00.446-.155A4.486 4.486 0 0019.5.252zM15 11.252h-2v-3.5a1 1 0 10-2 0v4.5a1 1 0 001 1h3a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});