define("ember-svg-jar/inlined/dmg-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dmg-1</title><path d=\"M22.478 0H1.522A1.524 1.524 0 000 1.523v20.955A1.524 1.524 0 001.522 24h20.956A1.524 1.524 0 0024 22.478V1.523A1.524 1.524 0 0022.478 0zM3.5 20.126a.625.625 0 01-.625-.625v-8a.625.625 0 01.625-.625 4.625 4.625 0 010 9.25zm10.625-.626a.625.625 0 11-1.25 0v-4.723c0-.153-.255-.119-.307-.015l-.509 1.018a.625.625 0 01-1.118 0s-.452-.909-.526-1.052-.29-.121-.29.014V19.5a.625.625 0 01-1.25 0v-8a.625.625 0 011.184-.279l1.217 2.434a.25.25 0 00.448 0l1.217-2.434a.625.625 0 011.184.279zm3.375-.625a1.377 1.377 0 001.375-1.375s.036-.375-.258-.375H18a.625.625 0 010-1.25h1.5a.625.625 0 01.625.625v1a2.625 2.625 0 11-5.25 0v-4a2.625 2.625 0 115.25 0 .625.625 0 01-1.25 0 1.375 1.375 0 10-2.75 0v4a1.377 1.377 0 001.375 1.376z\"/><path d=\"M4.125 12.5v6a.251.251 0 00.312.245 3.375 3.375 0 000-6.485.251.251 0 00-.312.24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});