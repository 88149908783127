define("ember-svg-jar/inlined/medical-specialty-stomach", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-specialty-stomach</title><path d=\"M.5 23.75a.25.25 0 00.25.25h3.5a.25.25 0 00.25-.25V22a1.977 1.977 0 01.576-1.4.5.5 0 01.705-.006 10.274 10.274 0 0012.282 1.538 10.909 10.909 0 005.437-9.567V12.5a10 10 0 00-9.985-10A2.011 2.011 0 0111.5.5V.25a.25.25 0 00-.25-.25h-3.5a.25.25 0 00-.25.25v.794A4.956 4.956 0 0012.456 6h2.294a.75.75 0 010 1.5h-1.767a.5.5 0 00-.5.467C11.9 16.926.5 15.443.5 21z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});