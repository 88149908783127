define("@gavant/ember-shopify-draggable/templates/components/swappable-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "xB/PnXjq",
    "block": "[[[18,1,[[28,[37,1],null,[[\"items\",\"item\"],[[33,2],[50,\"swappable-item\",0,null,[[\"container\",\"dragStart\",\"swapped\"],[[30,0],[28,[37,4],[[30,0],\"dragStart\"],null],[28,[37,4],[[30,0],\"swapped\"],null]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"items\",\"component\",\"action\"]]",
    "moduleName": "@gavant/ember-shopify-draggable/templates/components/swappable-container.hbs",
    "isStrictMode": false
  });
});