define("ember-svg-jar/inlined/email-action-reply-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-reply-all</title><path d=\"M20.821 10.737a10.586 10.586 0 00-6.584-2.722.25.25 0 01-.237-.249v-2.2a.988.988 0 00-1.63-.75l-6.932 5.926a1 1 0 000 1.52l6.952 5.946a.976.976 0 001.61-.742v-2.419a.249.249 0 01.186-.241c1.473-.392 5.847-.954 8.866 4.925A.5.5 0 0024 19.5c0-3.809-1.07-6.757-3.179-8.763z\"/><path d=\"M2.289 11.5l6.96-5.73a1 1 0 00-1.271-1.544L.549 10.344a1.5 1.5 0 000 2.316L7.8 18.633a1 1 0 001.271-1.544z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});