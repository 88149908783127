define("ember-svg-jar/inlined/browser-page-text-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>browser-page-text-1</title><path d=\"M24 3a3 3 0 00-3-3H3a3 3 0 00-3 3v18a3 3 0 003 3h18a3 3 0 003-3zM9.654 2a.966.966 0 011.692 0 .969.969 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0A.969.969 0 018 2.5a.969.969 0 01-.154.5.966.966 0 01-1.692 0A.969.969 0 016 2.5a.969.969 0 01.154-.5zm-3.562.092A1 1 0 013.5 1.5a.985.985 0 01.846.5.969.969 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.979.979 0 01.092-.408zM3 22a1 1 0 01-1-1V5.25A.25.25 0 012.25 5h6a.25.25 0 01.25.25v16.5a.25.25 0 01-.25.25zm19-1a1 1 0 01-1 1H10.25a.25.25 0 01-.25-.25V5.25a.25.25 0 01.25-.25h11.5a.25.25 0 01.25.25z\"/><rect x=\"12.5\" y=\"7.25\" width=\"7\" height=\"5.5\" rx=\"1\" ry=\"1\"/><path d=\"M18.75 15.5h-5.5a.75.75 0 000 1.5h5.5a.75.75 0 000-1.5zM3.5 7.25a.75.75 0 00.75.75h2a.75.75 0 000-1.5h-2a.75.75 0 00-.75.75zM4.25 12h2a.75.75 0 000-1.5h-2a.75.75 0 000 1.5zM4.25 16h2a.75.75 0 000-1.5h-2a.75.75 0 000 1.5zM4.25 20h2a.75.75 0 000-1.5h-2a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});