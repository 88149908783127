define("ember-svg-jar/inlined/cat-bed-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cat-bed-1</title><path d=\"M12 3C5.271 3 0 5.2 0 8v8c0 2.8 5.271 5 12 5s12-2.2 12-5V8c0-2.8-5.271-5-12-5zm5.773 8.379a1 1 0 00-.773.974V13.5a1.5 1.5 0 01-1.5 1.5h-7A1.5 1.5 0 017 13.5v-1.147a1 1 0 00-.772-.974 13.315 13.315 0 01-3.253-1.162.251.251 0 01-.131-.217.247.247 0 01.133-.22A20.613 20.613 0 0112 8a20.624 20.624 0 019.024 1.776.251.251 0 010 .441 13.307 13.307 0 01-3.251 1.162z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});