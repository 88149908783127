define("ember-svg-jar/inlined/soccer-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>soccer-player</title><path d=\"M14.416 5.03a2.507 2.507 0 002.45-2 2.5 2.5 0 10-2.45 2z\"/><path d=\"M20.5 1.476a1.249 1.249 0 00-1.475.974l-.355 1.741a2.766 2.766 0 01-2.66 2.2l-5.32.068a5.279 5.279 0 00-5.078 4.2l-.155.761a1.25 1.25 0 102.449.5l.156-.761a2.758 2.758 0 011.957-2.089l-1.694 8.291a1.25 1.25 0 102.45.5l.4-1.98a.5.5 0 01.98.2l-1.301 6.389a1.25 1.25 0 002.45.5l2.878-14.1a5.273 5.273 0 004.937-4.184l.355-1.741a1.249 1.249 0 00-.974-1.469z\"/><circle cx=\"5\" cy=\"21.471\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});