define("ember-svg-jar/inlined/database-flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-flash</title><path d=\"M10.879 21.625a21.61 21.61 0 003.028-.212.251.251 0 00.215-.248v-1.54a.25.25 0 00-.25-.25H13.5a2.37 2.37 0 01-2.01-1.14.25.25 0 00-.217-.119c-1.28.018-6.669-.067-9.482-2.4a.249.249 0 00-.409.193v1.712c-.003 2.214 4.251 4.004 9.497 4.004z\"/><path d=\"M1.379 13.125c0 2.315 4.49 4 9.517 4a.251.251 0 00.25-.239 2.327 2.327 0 01.542-1.421l1.206-1.507a.25.25 0 00-.215-.406c-.6.05-1.2.077-1.8.077-3.5 0-7.155-.833-9.093-2.41a.249.249 0 00-.407.194z\"/><path d=\"M17.128 8.663a.25.25 0 00-.271-.394 20.45 20.45 0 01-5.977.856c-3.505 0-7.157-.833-9.093-2.411a.25.25 0 00-.408.194v1.717c0 2.21 4.254 4 9.5 4a21.415 21.415 0 003.182-.235.249.249 0 00.158-.091c1.625-2.03 2.465-3.083 2.909-3.636z\"/><ellipse cx=\"10.88\" cy=\"4.125\" rx=\"9.5\" ry=\"4\"/><path d=\"M19.872 14.875a.25.25 0 01-.25-.25v-5.25a.5.5 0 00-.335-.472.493.493 0 00-.555.16l-5.9 7.37a.876.876 0 00.667 1.442h1.875a.25.25 0 01.25.25v5.25a.5.5 0 00.89.313l5.9-7.371a.875.875 0 00-.666-1.442z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});