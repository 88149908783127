define("ember-svg-jar/inlined/parking-p-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>parking-p-1</title><path d=\"M6.512 21.25h-4a.249.249 0 00-.25.25v.25a2.25 2.25 0 004.5 0v-.25a.251.251 0 00-.25-.25zM18.512 21.25h-4a.249.249 0 00-.25.25v.25a2.25 2.25 0 004.5 0v-.25a.251.251 0 00-.25-.25zM18.489 3.25h-1.25v2.5h1.25a1.25 1.25 0 000-2.5z\"/><path d=\"M17.989 0a6 6 0 106 6 6 6 0 00-6-6zm.5 7.25h-1.25V9.5a.75.75 0 01-1.5 0v-7a.75.75 0 01.75-.75h2a2.75 2.75 0 010 5.5z\"/><path d=\"M19.715 14.135a.252.252 0 01-.09-.2.245.245 0 01.093-.193l.065-.052a.25.25 0 00-.157-.445H4.883a.249.249 0 01-.248-.277l.2-1.806a1.007 1.007 0 011-.917h3.66a1 1 0 000-2H5.829a3.011 3.011 0 00-2.987 2.725l-.1.889a.25.25 0 01-.405.168l-.7-.563a1 1 0 00-1.25 1.567l.884.707a.25.25 0 010 .389A2.737 2.737 0 00.262 16.25v2.25a1.75 1.75 0 001.75 1.75h16.954a1.751 1.751 0 001.75-1.75v-2.25a2.739 2.739 0 00-1.001-2.115zM4.012 18.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm13 0a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});