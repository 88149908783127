define("ember-svg-jar/inlined/performance-increase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>performance-increase</title><path d=\"M2 17.865a1 1 0 00-1 1v1.5a.249.249 0 00.25.25h3.5a.249.249 0 00.25-.25v-1.5a1 1 0 00-1-1zM8 14.865a1 1 0 00-1 1v4.5a.249.249 0 00.25.25h3.5a.249.249 0 00.25-.25v-4.5a1 1 0 00-1-1zM14 11.365a1 1 0 00-1 1v8a.249.249 0 00.25.25h3.5a.249.249 0 00.25-.25v-8a1 1 0 00-1-1zM19.25 20.615h3.5a.249.249 0 00.25-.25v-12a1 1 0 00-1-1h-2a1 1 0 00-1 1v12a.249.249 0 00.25.25zM18.069 4.207l.307.46A.751.751 0 0019 5h.025a.75.75 0 00.626-.377l2-3.5a.751.751 0 00-.027-.788.742.742 0 00-.717-.328l-4 .5a.749.749 0 00-.531 1.16l.307.46a.251.251 0 01-.07.347L2.445 11.919a1 1 0 001.11 1.664l14.168-9.445a.249.249 0 01.346.069zM1 24h22a1 1 0 000-2H1a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});