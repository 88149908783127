define("ember-svg-jar/inlined/like-plus-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>like-plus-bubble</title><path d=\"M24 12a4.8 4.8 0 00-2.207-4.057 4.831 4.831 0 00-5.736-5.736 4.831 4.831 0 00-8.112 0 4.831 4.831 0 00-5.737 5.736 4.832 4.832 0 000 8.114 4.831 4.831 0 005.736 5.736 4.831 4.831 0 008.113 0 4.831 4.831 0 005.735-5.737A4.8 4.8 0 0024 12zm-7.75 1h-3a.25.25 0 00-.25.25v3a1 1 0 01-2 0v-3a.25.25 0 00-.25-.25h-3a1 1 0 010-2h3a.25.25 0 00.25-.25v-3a1 1 0 012 0v3a.25.25 0 00.25.25h3a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});