define("ember-svg-jar/inlined/real-estate-action-house-percent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-house-percent</title><path d=\"M17.5 9.5a7.954 7.954 0 015.137 1.883.5.5 0 00.321.117h.542a.5.5 0 00.344-.863L13.03.409a1.5 1.5 0 00-2.06 0L.157 10.636A.5.5 0 00.5 11.5h2a.5.5 0 01.5.5v10a1 1 0 001 1h6.638a.5.5 0 00.4-.795A7.987 7.987 0 0117.5 9.5zM19.5 19.999v-.001.001zM15.5 14.998v.001-.001z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-2 3a1 1 0 11-1 1 1 1 0 011-1zm4 7a1 1 0 111-1 1 1 0 01-1 1zm1.53-5.97l-6 6a.75.75 0 11-1.06-1.06l6-6a.75.75 0 111.061 1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});