define("ember-svg-jar/inlined/shirt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shirt</title><path d=\"M16.537 0a1 1 0 00-1.059.884 3.512 3.512 0 01-2.384 2.938.5.5 0 00-.344.475V7a.75.75 0 01-1.5 0V4.3a.5.5 0 00-.344-.475A3.512 3.512 0 018.522.886 1 1 0 007.463 0 8.017 8.017 0 000 7.984V11a1 1 0 001 1h3a.5.5 0 01.5.5V23a1 1 0 001 1h13a1 1 0 001-1V12.5a.5.5 0 01.5-.5h3a1 1 0 001-1V7.984A8.017 8.017 0 0016.537 0zm.713 10.5h-2.5a.75.75 0 010-1.5h2.5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});