define("ember-svg-jar/inlined/e-commerce-gdgt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-gdgt</title><path d=\"M23 2.8a.5.5 0 00-.95-.23L19 8.63v-7a.5.5 0 00-.25-.43l-2-1.15a.5.5 0 00-.22-.05H3.44a.5.5 0 00-.26.07L1.24 1.22a.5.5 0 00-.24.43V15a.5.5 0 00.1.3l1.15 1.5a.5.5 0 00.4.2H15v2H2.5a.5.5 0 00-.5.5v2.85a.5.5 0 00.17.37l1.3 1.15a.5.5 0 00.33.13h12.73a.5.5 0 00.25-.07l2-1.15a.5.5 0 00.25-.43v-5.62l3.9-6.73a.5.5 0 00.07-.3zM5.5 13a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5h9a.5.5 0 01.5.5v8a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});