define("ember-svg-jar/inlined/primitive-symbols-animal-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>primitive-symbols-animal-1</title><path d=\"M23.892 3.443a1.488 1.488 0 00-.8-.822l-1.6-.685a3.506 3.506 0 00-1.986-.223.251.251 0 01-.231-.081L18.062.254a.75.75 0 00-1.125.991l.858.975a.249.249 0 01-.021.351 3.472 3.472 0 00-.923 1.314l-1.858 4.777A.531.531 0 0114.5 9h-7a4.444 4.444 0 00-2.728.948.245.245 0 01-.152.052H.75a.75.75 0 000 1.5H3.1a.249.249 0 01.231.344A4.351 4.351 0 003 13.5a4.464 4.464 0 001.119 2.952.251.251 0 01.021.3l-3.727 5.7a1 1 0 001.674 1.1l3.719-5.687a.25.25 0 01.288-.1 4.451 4.451 0 00.91.2.25.25 0 01.175.392l-2.5 3.57a1 1 0 001.639 1.146l3.5-5A.172.172 0 019.96 18h1.78a.25.25 0 01.159.057l5.714 4.714a1 1 0 101.273-1.543l-3.532-2.914a.251.251 0 01.108-.438 4.216 4.216 0 00.775-.237.25.25 0 01.241.027l4.444 3.149a1 1 0 001.156-1.631l-3.868-2.741a.251.251 0 01-.059-.35 4.41 4.41 0 00-.029-5.242 1.5 1.5 0 01-.2-1.453l1.72-4.426a.5.5 0 01.272-.28.492.492 0 01.391 0l1.6.685a1.5 1.5 0 001.984-1.936z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});