define("ember-svg-jar/inlined/color-triangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-triangle</title><path d=\"M20.8 4.279a.252.252 0 00.1-.178.248.248 0 00-.065-.2A11.913 11.913 0 007.183 1.024a.25.25 0 00-.118.35L8.525 4a.249.249 0 00.311.11A8.481 8.481 0 0118 5.986a.252.252 0 00.32.028zM13.683 20.556a.249.249 0 00-.28-.175A8.5 8.5 0 017.129 5.037a.25.25 0 00.071-.326L5.748 2.09a.253.253 0 00-.16-.122.249.249 0 00-.2.035A11.988 11.988 0 0012 24a11.834 11.834 0 002.359-.242.247.247 0 00.165-.116.253.253 0 00.025-.2zM15.989 23.02a.252.252 0 00.33.161 11.979 11.979 0 005.775-17.665.251.251 0 00-.162-.109.247.247 0 00-.192.04l-2.459 1.721a.251.251 0 00-.069.338 8.488 8.488 0 01-3.943 12.333.25.25 0 00-.143.3z\"/><path d=\"M17.052 16.194a.949.949 0 00.835-1.4l-5.052-9.38a.948.948 0 00-1.67 0L6.113 14.8a.949.949 0 00.835 1.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});