define("ember-svg-jar/inlined/discount-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>discount-square</title><path d=\"M15.116 7.116l-8 8a1.25 1.25 0 101.767 1.768l8-8a1.25 1.25 0 00-1.767-1.768z\"/><circle cx=\"8\" cy=\"8.135\" r=\"2.25\"/><circle cx=\"16\" cy=\"16.135\" r=\"2.25\"/><path d=\"M2.913 22l-.652.031a.25.25 0 01-.261-.25v-.694a1 1 0 00-2 0v.956A1.958 1.958 0 001.956 24h.957a1 1 0 100-2zM23 20.087a1 1 0 00-1 1l.031.651a.251.251 0 01-.069.184.247.247 0 01-.181.078h-.7a1 1 0 000 2h.957A1.959 1.959 0 0024 22.043v-.956a1 1 0 00-1-1zM2.913 0h-.957A1.958 1.958 0 000 1.957v.956a1 1 0 002 0l-.032-.651a.251.251 0 01.069-.184A.247.247 0 012.218 2h.695a1 1 0 000-2zM21.086 2l.652-.031a.251.251 0 01.184.069.247.247 0 01.078.181v.694a1 1 0 102 0v-.956A1.959 1.959 0 0022.043 0h-.957a1 1 0 000 2zM23 5.5a1 1 0 00-1 1V10a1 1 0 002 0V6.5a1 1 0 00-1-1zM23 13a1 1 0 00-1 1v3.5a1 1 0 002 0V14a1 1 0 00-1-1zM1 11a1 1 0 001-1V6.5a1 1 0 00-2 0V10a1 1 0 001 1zM0 17.5a1 1 0 002 0V14a1 1 0 00-2 0zM6.5 2H10a1 1 0 000-2H6.5a1 1 0 000 2zM17.5 0H14a1 1 0 000 2h3.5a1 1 0 000-2zM10 22H6.5a1 1 0 000 2H10a1 1 0 000-2zM17.5 22H14a1 1 0 000 2h3.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});