define("ember-svg-jar/inlined/room-service-champagne", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>room-service-champagne</title><circle cx=\"7.25\" cy=\"3.5\" r=\"2.5\"/><path d=\"M22.75 10a1 1 0 00-1-1H21a.25.25 0 01-.25-.25V7.36a.253.253 0 01.111-.208A1.99 1.99 0 0021.75 5.5v-1a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v1a1.99 1.99 0 00.889 1.652.253.253 0 01.111.208v1.39a.25.25 0 01-.25.25H16a.25.25 0 01-.25-.25V7.36a.253.253 0 01.111-.208A1.99 1.99 0 0016.75 5.5v-1a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v1a1.99 1.99 0 00.889 1.652.253.253 0 01.111.208v1.39a.25.25 0 01-.25.25h-.25a.994.994 0 00-.962.744.249.249 0 01-.3.18 2.483 2.483 0 01-.9-.424l-1.296-.969a5.633 5.633 0 00-4.931-.908 4.511 4.511 0 00-3.036 2.91l-.5 1.492a1.5 1.5 0 002.846.95l.5-1.5a1.444 1.444 0 01.71-.84.249.249 0 01.367.221v4.114a.245.245 0 01-.042.139l-3.646 5.47a1.576 1.576 0 00.24 2.079 1.5 1.5 0 002.2-.326l2.544-3.816a.253.253 0 01.208-.111.25.25 0 01.208.111L10 22.332a1.5 1.5 0 002.2.326 1.575 1.575 0 00.235-2.079l-3.647-5.47a.252.252 0 01-.042-.139V12a.25.25 0 01.4-.2l.133.1a5.494 5.494 0 003.3 1.1h2.171a1.5 1.5 0 001.5-1.5 1.335 1.335 0 00-.017-.211.251.251 0 01.248-.289h5.269a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});