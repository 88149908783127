define("ember-svg-jar/inlined/vectors-pen-anchor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vectors-pen-anchor</title><path d=\"M22.5 8h-3.747a.251.251 0 01-.174-.07l-3.5-3.362a.25.25 0 01-.079-.18V1a1 1 0 00-1-1h-4a1 1 0 00-1 1v3.388a.25.25 0 01-.077.18L5.421 7.93a.251.251 0 01-.174.07H1.5a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1V9.773a.25.25 0 01.077-.181l3.669-3.522a.25.25 0 01.173-.07h3.162a.25.25 0 01.173.07l3.669 3.522a.25.25 0 01.077.181V13a1 1 0 001 1h4a1 1 0 001-1V9a1 1 0 00-1-1zm-1 3.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25zM11 2.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25zm-8.5 8a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25z\"/><path d=\"M12.735 11.1a.749.749 0 00-1.47 0c-.373 1.865-2.867 4.687-3.8 5.617a.748.748 0 00-.172.793l1.294 3.452a.25.25 0 01-.034.239 3.574 3.574 0 00-.803 2.049.75.75 0 00.75.75h7a.75.75 0 00.75-.75 3.574 3.574 0 00-.808-2.046.247.247 0 01-.034-.239l1.292-3.452a.748.748 0 00-.172-.793c-.928-.93-3.42-3.752-3.793-5.62zM12 19.25a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});