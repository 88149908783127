define("ember-svg-jar/inlined/plane-trip-cloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-trip-cloud</title><path d=\"M9.566 3.427a.249.249 0 00.177.073h4.707a.25.25 0 00.176-.427l-2.78-2.78A1 1 0 0011.139 0H8.553a1 1 0 00-.707 1.707zM16.657 10h4.689a2.477 2.477 0 002.466-2.916A2.58 2.58 0 0021.239 5H5.553L3.846 3.293A1 1 0 003.139 3H1.155A1 1 0 00.26 4.447l2.5 5a1 1 0 00.895.553h5.881a.25.25 0 01.176.427l-3.866 3.866A1 1 0 006.553 16h3.586a1 1 0 00.707-.293l5.634-5.634a.249.249 0 01.177-.073zM21.346 19a2.5 2.5 0 00-1.022.218c-.129.06-.219-.038-.3-.189A3.5 3.5 0 1016.846 24h4.5a2.5 2.5 0 100-5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});