define("ember-svg-jar/inlined/style-three-pin-cocktail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-cocktail</title><path d=\"M12 0C6.21 0 1.5 4.26 1.5 9.5c0 5.75 7.3 12.29 9.54 14.15a1.492 1.492 0 001.92 0c2.24-1.87 9.54-8.4 9.54-14.15C22.5 4.26 17.79 0 12 0zM7.248 4.294a.752.752 0 01.958.454l.685 1.918A.25.25 0 018.656 7H7.594a.249.249 0 01-.235-.166l-.565-1.581a.749.749 0 01.454-.959zm5.128 8.557a.5.5 0 00-.376.484V15.5a.5.5 0 00.5.5h1a.75.75 0 010 1.5H9A.75.75 0 019 16h1a.5.5 0 00.5-.5v-2.165a.5.5 0 00-.376-.484A4.5 4.5 0 016.75 8.5a.5.5 0 01.5-.5h8a.5.5 0 01.5.5 4.5 4.5 0 01-3.374 4.351zM16.5 11a.227.227 0 01-.19-.1.231.231 0 01-.018-.215A5.432 5.432 0 0016.75 8.5a1.5 1.5 0 00-1.5-1.5h-1.186a.249.249 0 01-.222-.364A2.995 2.995 0 1116.5 11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});