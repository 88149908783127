define("ember-svg-jar/inlined/exe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>exe</title><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM6.375 11.376H7.5a.625.625 0 010 1.25H6.375a.25.25 0 00-.25.25V14a.375.375 0 00.375.375h2a.625.625 0 010 1.25h-2A1.626 1.626 0 014.875 14v-4A1.625 1.625 0 016.5 8.376h2a.625.625 0 010 1.25h-2a.375.375 0 00-.375.374v1.125a.25.25 0 00.25.251zm7.684 3.346a.625.625 0 11-1.118.558l-.854-1.709a.094.094 0 00-.175 0l-.853 1.706a.625.625 0 01-.559.346.625.625 0 01-.559-.9l1.3-2.609a.252.252 0 000-.224l-1.3-2.61a.625.625 0 111.118-.558s.806 1.616.84 1.681a.122.122 0 00.2.006l.843-1.687a.625.625 0 111.118.558l-1.3 2.609a.252.252 0 000 .224zm2.316-3.346H17.5a.625.625 0 010 1.25h-1.125a.25.25 0 00-.25.25V14a.375.375 0 00.375.375h2a.625.625 0 010 1.25h-2A1.626 1.626 0 0114.875 14v-4A1.625 1.625 0 0116.5 8.376h2a.625.625 0 010 1.25h-2a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});