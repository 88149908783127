define("ember-svg-jar/inlined/pregnancy-ultrasound-baby", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pregnancy-ultrasound-baby</title><path d=\"M23.542 15.111a.751.751 0 00.028-1.006l-1.249-1.461a.5.5 0 00-.366-.175.5.5 0 00-.377.155 13.867 13.867 0 01-1.16 1.082.75.75 0 11-.959-1.154 12.5 12.5 0 001.035-.965.8.8 0 01.114-.1.5.5 0 00.213-.347.5.5 0 00-.116-.39l-1.491-1.743a.5.5 0 00-.73-.034 9.984 9.984 0 01-2.3 1.625.737.737 0 01-.338.081.75.75 0 01-.336-1.419 10.4 10.4 0 002.038-1.447.5.5 0 00.048-.7L15.07 4.159a.75.75 0 00-.549-.263.724.724 0 00-.563.231A3.44 3.44 0 0112 4.54a3.44 3.44 0 01-1.958-.413.75.75 0 00-1.112.032L6.409 7.108a.5.5 0 00.051.7 13.649 13.649 0 001.231.985.749.749 0 01-.414 1.375.743.743 0 01-.414-.125 10.883 10.883 0 01-1.348-1.074.505.505 0 00-.371-.139.5.5 0 00-.356.175L3.3 10.752a.5.5 0 00.1.738.792.792 0 01.114.1 12.5 12.5 0 001.035.965.75.75 0 01-.959 1.154 13.867 13.867 0 01-1.16-1.082.5.5 0 00-.743.02L.43 14.105a.751.751 0 00.028 1.006 15.835 15.835 0 0023.084 0zm-13.261 1.537a.741.741 0 01-.105-.007 13.047 13.047 0 01-3.5-1.009.75.75 0 01.611-1.369 11.562 11.562 0 003.1.892.75.75 0 01-.1 1.493zm-.444-5.3a.75.75 0 01.35-1.459 9.345 9.345 0 001.834.215 7.189 7.189 0 00.882-.053.76.76 0 01.833.657.75.75 0 01-.656.834 9.1 9.1 0 01-1.056.062 7.228 7.228 0 01-2.187-.261zm5.413 6.3A2.25 2.25 0 1117.5 15.4a2.25 2.25 0 01-2.25 2.246z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});