define("ember-svg-jar/inlined/multiple-actions-key.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-key</title><path d=\"M13.5 13.5a.329.329 0 00.415.295 5.89 5.89 0 012.306-.16.5.5 0 00.408-.132l2.605-2.461a.5.5 0 00.054-.666A6.015 6.015 0 0014.489 8a5.95 5.95 0 00-2.465.534.5.5 0 00-.182.771A7.42 7.42 0 0113.5 13.5z\"/><ellipse cx=\"14.013\" cy=\"3.501\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M11.9 13.882a.372.372 0 00.1-.28 6.025 6.025 0 00-12-.268.6.6 0 00.6.666h11.023a.377.377 0 00.277-.118z\"/><ellipse cx=\"5.513\" cy=\"3.501\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M23.5 11.131h-1.979a.5.5 0 00-.343.137l-4.189 3.957a.251.251 0 01-.244.057 4.456 4.456 0 102.978 2.988.252.252 0 01.063-.249l4.067-4.057a.5.5 0 00.147-.355v-1.978a.5.5 0 00-.5-.5zm-6.665 9.49a1.736 1.736 0 110-2.456 1.738 1.738 0 010 2.456z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});