define("ember-svg-jar/inlined/focus-eye", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>focus-eye</title><path d=\"M17.419 12.159a.249.249 0 000-.318C15.744 9.817 13.877 8.75 12 8.75s-3.745 1.067-5.418 3.091a.248.248 0 000 .318c1.674 2.025 3.54 3.091 5.418 3.091s3.745-1.067 5.419-3.091zM10 12a2 2 0 112 2 2 2 0 01-2-2z\"/><path d=\"M21.25 2H2.75A2.752 2.752 0 000 4.75v14.5A2.752 2.752 0 002.75 22h18.5A2.752 2.752 0 0024 19.25V4.75A2.752 2.752 0 0021.25 2zM2.5 6.25A1.752 1.752 0 014.25 4.5h2a.75.75 0 010 1.5h-2a.25.25 0 00-.25.25v2a.75.75 0 01-1.5 0zm0 9.5a.75.75 0 011.5 0v2a.25.25 0 00.25.25h2a.75.75 0 010 1.5h-2a1.752 1.752 0 01-1.75-1.75zm2.647-2.981a1.251 1.251 0 010-1.537C7.714 7.94 10.29 7.25 12 7.25s4.285.69 6.853 3.981a1.252 1.252 0 010 1.536C16.286 16.06 13.709 16.75 12 16.75s-4.286-.69-6.853-3.981zM19.75 6h-2a.75.75 0 010-1.5h2a1.752 1.752 0 011.75 1.75v2a.75.75 0 01-1.5 0v-2a.25.25 0 00-.25-.25zm-2 12h2a.25.25 0 00.25-.25v-2a.75.75 0 011.5 0v2a1.752 1.752 0 01-1.75 1.75h-2a.75.75 0 010-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});