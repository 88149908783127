define("ember-svg-jar/inlined/electricity-tower-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>electricity-tower-1</title><path d=\"M19.371 10a1 1 0 00-.871-.51h-.855a.249.249 0 01-.249-.23l-.214-2.741a.25.25 0 01.25-.269h1.318a.75.75 0 000-1.5h-1.474a.25.25 0 01-.249-.231l-.175-2.25A.25.25 0 0117.1 2h2.65a1 1 0 000-2h-15a1 1 0 000 2H6.9a.25.25 0 01.249.269l-.175 2.25a.25.25 0 01-.249.231H5.25a.75.75 0 000 1.5h1.318a.25.25 0 01.25.269L6.6 9.26a.249.249 0 01-.249.23H5.5a1 1 0 00-.871.51L.13 18.51A1 1 0 001 20h4.494a.249.249 0 01.248.274l-.145 1.5a.251.251 0 01-.249.226H1a1 1 0 000 2h22a1 1 0 000-2h-4.348a.251.251 0 01-.249-.226l-.145-1.5a.249.249 0 01.248-.274H23a1 1 0 00.874-1.485zM9.038 3.771a.249.249 0 01.44-.141l1.408 1.676a.251.251 0 010 .322L9.191 7.647a.25.25 0 01-.441-.18zm6.212 3.7a.25.25 0 01-.441.18l-1.695-2.023a.251.251 0 010-.322l1.408-1.676a.249.249 0 01.44.141zM13.5 11.49a.25.25 0 01.174.429l-1.5 1.467a.251.251 0 01-.35 0l-1.5-1.467a.25.25 0 01.174-.429zm-3.366-2a.25.25 0 01-.191-.411l1.862-2.217a.249.249 0 01.382 0l1.862 2.217a.25.25 0 01-.191.411zm5.096 3.01a.25.25 0 01.424.159l.324 4.166a.25.25 0 01-.424.2l-2.3-2.241a.251.251 0 010-.358zm-3.039-8.427a.25.25 0 01-.382 0l-1.4-1.662A.251.251 0 0110.605 2H13.4a.251.251 0 01.192.411zm-1.448 10.352a.251.251 0 010 .358l-2.3 2.241a.25.25 0 01-.424-.2l.324-4.166a.25.25 0 01.427-.158zm1.082 1.4a.252.252 0 01.35 0l1.792 1.749a.25.25 0 01-.175.429h-3.584a.25.25 0 01-.175-.429zM17.97 11.6l3.137 6.035a.25.25 0 01-.222.365h-2.578a.251.251 0 01-.25-.231l-.471-6.059a.2.2 0 01.384-.11zM3.115 18a.25.25 0 01-.222-.365L6.03 11.6a.2.2 0 01.384.11l-.471 6.059a.25.25 0 01-.25.231zm4.638 2.226A.251.251 0 018 20h8a.251.251 0 01.249.226l.145 1.5a.249.249 0 01-.248.274h-8.29a.249.249 0 01-.248-.274z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});