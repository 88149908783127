define("ember-svg-jar/inlined/safety-exit-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-exit-right</title><path d=\"M19.445 1.316a2.5 2.5 0 00-3 1.864 2.5 2.5 0 104.867 1.141 2.5 2.5 0 00-1.867-3.005zM23.1 14.294a1.5 1.5 0 00-1.25-1.751l-2.9-.44a1 1 0 01-.814-.86l-.03-.24a6.907 6.907 0 00-.5-1.908 7.216 7.216 0 00-9.223-3.916l-2.97 1.142a1.556 1.556 0 00-1.007 1.731 1.506 1.506 0 002.008 1.1L9.46 7.979a4.157 4.157 0 011.491-.279 4.219 4.219 0 011.581.308.248.248 0 01.151.18.252.252 0 01-.064.225L1.294 20.211a1.5 1.5 0 102.164 2.079l6.407-6.674a.251.251 0 01.347-.014l1.848 1.65a.249.249 0 01.081.224.252.252 0 01-.144.19l-3.034 1.379a1.5 1.5 0 001.243 2.731l4.087-1.859a2.5 2.5 0 00.612-4.157l-2.6-2.311a.251.251 0 01-.014-.36l2.58-2.688a4.173 4.173 0 01.259.976l.03.24a3.983 3.983 0 003.254 3.437l2.986.454a1.574 1.574 0 00.226.017 1.5 1.5 0 001.474-1.231z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});