define("ember-svg-jar/inlined/fireworks-people-watch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fireworks-people-watch</title><path d=\"M6.272 20.458A.5.5 0 006 19.749a4.5 4.5 0 00-5.95 3.689.5.5 0 00.5.562h4.465a.5.5 0 00.5-.5 6.452 6.452 0 01.757-3.042zM16.015 10.5a.989.989 0 01-.371-.071 1 1 0 01-.557-1.3 9.878 9.878 0 012.561-3.664 1 1 0 011.336 1.488 7.878 7.878 0 00-2.04 2.918 1 1 0 01-.929.629zM8.016 10.5a1 1 0 01-.929-.629 7.878 7.878 0 00-2.04-2.918 1 1 0 011.336-1.488 9.878 9.878 0 012.561 3.664 1 1 0 01-.928 1.371z\"/><circle cx=\"19.515\" cy=\"16\" r=\"2.5\"/><path d=\"M18.516 23.5a.5.5 0 00.5.5h4.469a.5.5 0 00.5-.562 4.5 4.5 0 00-5.946-3.689.5.5 0 00-.276.708 6.49 6.49 0 01.753 3.043z\"/><circle cx=\"4.515\" cy=\"16\" r=\"2.5\"/><path d=\"M13.983 18.9a.25.25 0 01-.031-.444 3.751 3.751 0 10-3.873 0 .25.25 0 01-.031.444 4.95 4.95 0 00-3.03 4.578.5.5 0 00.5.518h9a.5.5 0 00.5-.517 4.947 4.947 0 00-3.035-4.579zm-1.968-1.4a2.247 2.247 0 01-2.135-2.957.246.246 0 01.156-.157.252.252 0 01.22.028 4.954 4.954 0 002.759.836 5.01 5.01 0 00.947-.09.25.25 0 01.3.264 2.252 2.252 0 01-2.247 2.076zM5.585 2.505l-.33-.047a2.011 2.011 0 01-1.7-1.7L3.511.429a.5.5 0 00-.991 0l-.047.332a2.011 2.011 0 01-1.7 1.7l-.331.047a.5.5 0 000 .99l.333.048a2.008 2.008 0 011.7 1.7l.047.331a.5.5 0 00.991 0l.047-.332a2.008 2.008 0 011.7-1.7l.327-.045a.5.5 0 000-.99zM14.087 2.005l-.265-.038A1.506 1.506 0 0112.549.694l-.038-.265a.5.5 0 00-.991 0L11.482.7a1.506 1.506 0 01-1.273 1.272l-.265.038a.5.5 0 000 .99l.266.038a1.506 1.506 0 011.272 1.274l.038.264a.5.5 0 00.991 0l.038-.265a1.506 1.506 0 011.273-1.273L14.087 3a.5.5 0 000-.99zM12.015 10a1 1 0 01-1-1V7a1 1 0 012 0v2a1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});