define("ember-svg-jar/inlined/like-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>like-bubble</title><path d=\"M23.446 10.593L22.2 9.267l.422-1.773a2.075 2.075 0 00-1.408-2.437l-1.746-.519-.52-1.746a2.07 2.07 0 00-2.437-1.409l-1.778.417L13.407.553a2.077 2.077 0 00-2.814 0L9.267 1.8l-1.773-.417a2.072 2.072 0 00-2.437 1.408l-.519 1.745-1.748.521a2.076 2.076 0 00-1.407 2.437L1.8 9.268.553 10.594a2.08 2.08 0 000 2.815L1.8 14.734l-.422 1.774a2.077 2.077 0 001.409 2.437l1.746.52.519 1.744a2.074 2.074 0 002.437 1.409l1.778-.418 1.326 1.251a2.08 2.08 0 002.815 0l1.325-1.25 1.773.421a2.075 2.075 0 002.436-1.408l.52-1.745 1.747-.52a2.075 2.075 0 001.408-2.438l-.417-1.778 1.252-1.327a2.078 2.078 0 00-.006-2.813zM13.9 7.458L13.555 9a.893.893 0 00.863 1.12h2.188a.892.892 0 01.854 1.152l-.9 2.8v.007c-.344 1.122-.937 1.887-2.11 1.887-2.723 0-3.124.306-6.142-1.2a.5.5 0 01-.277-.448V10.5a.5.5 0 01.5-.5h1.1a.889.889 0 00.729-.378l2.182-2.8a.764.764 0 011.364.634z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});