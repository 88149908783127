define("ember-svg-jar/inlined/cloud-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-file</title><path d=\"M24 11.623a5.766 5.766 0 00-5.216-5.962.249.249 0 01-.191-.12 7.684 7.684 0 00-14.1 2.294.251.251 0 01-.227.2 4.643 4.643 0 00-3.642 2.239A4.47 4.47 0 000 12.723a4.293 4.293 0 001.235 3.1A5.478 5.478 0 004.807 17.2h.711a.982.982 0 00.982-.982v-.037a.981.981 0 00-.982-.981h-.707a3.571 3.571 0 01-2.172-.8A2.374 2.374 0 012 12.609a2.588 2.588 0 012.586-2.588 2.7 2.7 0 01.535.054.988.988 0 00.811-.184 1 1 0 00.392-.779c.085-5.736 8.293-7.372 10.818-2.044a.985.985 0 00.941.57 3.775 3.775 0 013.325 1.731 3.8 3.8 0 01-2.2 5.7.983.983 0 00-.711.942.977.977 0 001.248.945A5.559 5.559 0 0024 11.623z\"/><path d=\"M13.5 15.2a.5.5 0 01-.5-.5v-3a.5.5 0 00-.5-.5h-4a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h8a.5.5 0 00.5-.5v-6a.5.5 0 00-.5-.5z\"/><path d=\"M14.25 14.2h1.85a.25.25 0 00.176-.427l-1.853-1.854a.25.25 0 00-.427.177v1.854a.25.25 0 00.254.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});