define("ember-svg-jar/inlined/wedding-couple-dance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wedding-couple-dance</title><path d=\"M15.787 5.262a2.018 2.018 0 00-2.678-.917.251.251 0 01-.242-.014.247.247 0 01-.117-.211V.75a.75.75 0 00-1.5 0v3.37a.25.25 0 01-.117.212.254.254 0 01-.242.013 2.017 2.017 0 00-2.3 3.245l2.831 2.954a.8.8 0 001.156 0l2.831-2.954a2.017 2.017 0 00.378-2.328zM3.532 6.05a.649.649 0 00.936 0l1.663-1.735a1.261 1.261 0 00-1.092-2.136.251.251 0 01-.289-.247V.75a.75.75 0 00-1.5 0v1.182a.248.248 0 01-.088.19.245.245 0 01-.2.057 1.261 1.261 0 00-1.093 2.136zM19.532 6.05a.649.649 0 00.936 0l1.663-1.735a1.261 1.261 0 00-1.092-2.136.245.245 0 01-.2-.057.248.248 0 01-.088-.19V.75a.75.75 0 00-1.5 0v1.182a.251.251 0 01-.289.247 1.261 1.261 0 00-1.092 2.136zM5.347 13.47a.247.247 0 01-.119-.2.251.251 0 01.094-.211 2.918 2.918 0 10-3.616 0 .251.251 0 01-.024.409 3.514 3.514 0 00-1.682 3v2.011a.5.5 0 00.5.5h.6a.5.5 0 01.5.45l.413 4.123a.5.5 0 00.5.45h2.01a.5.5 0 00.5-.45l.412-4.123a.5.5 0 01.5-.45h.6a.5.5 0 00.5-.5v-2.012a3.513 3.513 0 00-1.688-2.997zm-1.833-1.28A1.419 1.419 0 012.1 10.772a1.478 1.478 0 01.028-.284.25.25 0 01.367-.168 3.576 3.576 0 001.746.452 3.453 3.453 0 00.4-.022.252.252 0 01.212.079.255.255 0 01.061.218 1.42 1.42 0 01-1.4 1.143zM23.978 19.854l-1.191-3.921a2.95 2.95 0 00-1.334-1.7.25.25 0 010-.433 3.042 3.042 0 10-3.006 0 .25.25 0 010 .433 2.95 2.95 0 00-1.334 1.7l-1.192 3.921A.5.5 0 0016 20.3a.594.594 0 00.407.2l.867-.012a.5.5 0 01.505.45l.261 2.612a.5.5 0 00.5.45h2.821a.5.5 0 00.5-.45l.261-2.612a.5.5 0 01.5-.45l.868.012a.605.605 0 00.406-.2.5.5 0 00.082-.446zM19.952 12.7a1.543 1.543 0 01-1.447-1.011.248.248 0 01.152-.322 3.056 3.056 0 001.12-.7.251.251 0 01.349 0 3.06 3.06 0 001.121.7.251.251 0 01.152.322 1.543 1.543 0 01-1.447 1.011z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});