define("ember-svg-jar/inlined/gesture-swipe-horizontal-right-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-horizontal-right-1</title><path d=\"M23.848 10.182L22.9 7.348a.749.749 0 00-.659-.511.742.742 0 00-.411.093.249.249 0 00-.116.3l.4 1.19a1.749 1.749 0 01-1.107 2.213l-1.272.424a.251.251 0 00-.168.2.738.738 0 00.169.6.754.754 0 00.8.22l2.834-.945a.75.75 0 00.475-.948zM6.025 6.663A10.515 10.515 0 00.384 9.686a1 1 0 101.461 1.366 8.5 8.5 0 014.624-2.439 1 1 0 10-.444-1.95z\"/><path d=\"M17.025 8.759a.25.25 0 01.157.353l-.234.468a.751.751 0 00.908 1.047l2.834-.944a.751.751 0 00.475-.949L20.22 5.9a.752.752 0 00-1.383-.1l-.513 1.026a.25.25 0 01-.291.13 16.877 16.877 0 00-.79-.2c-.116-.029-.222-.063-.341-.09a.99.99 0 00-.562 1.9.85.85 0 00.119.054c.196.038.382.088.566.139zM11.385 5a3.5 3.5 0 00-3.5 3.5v10a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-10a3.5 3.5 0 00-3.5-3.5zm1.5 4.625a.374.374 0 01-.375.375h-2.25a.375.375 0 01-.375-.375V8.5a1.5 1.5 0 113 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});