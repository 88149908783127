define("ember-svg-jar/inlined/safety-danger-mudslide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-danger-mudslide</title><path d=\"M1.875 24h11.3a1.5 1.5 0 001.476-1.768l-.7-3.85a2.513 2.513 0 00-.471-1.066L5.3 6.566a1.5 1.5 0 01-.279-.616L4.294 2.3A2.5 2.5 0 003.083.615l-.96-.548a.5.5 0 00-.5 0A.5.5 0 001.375.5v23a.5.5 0 00.5.5zM19.371 8.648l.5-1.856a.752.752 0 00-.191-.724L18.327 4.7a.743.743 0 00-.724-.2l-1.859.5a.751.751 0 00-.531.529l-.5 1.856a.757.757 0 00.191.724l1.356 1.363a.75.75 0 00.533.222.718.718 0 00.19-.025l1.861-.492a.749.749 0 00.527-.529zM17.718 15.067l.2-.757a.752.752 0 00-.193-.726l-.551-.554a.751.751 0 00-.724-.2l-.759.2a.749.749 0 00-.531.529l-.206.758a.753.753 0 00.192.724l.552.555a.756.756 0 00.533.222.731.731 0 00.191-.025l.759-.2a.756.756 0 00.537-.526zM11.679 4.9a.755.755 0 00-.724-.2l-.759.2a.748.748 0 00-.531.528l-.206.758a.753.753 0 00.192.724l.552.555a.752.752 0 00.533.222.731.731 0 00.191-.025l.759-.2a.751.751 0 00.532-.528l.205-.757a.752.752 0 00-.193-.726zM19.005 22.273a.753.753 0 00.649.375h1.1a.75.75 0 00.649-.375l.547-.949a.749.749 0 000-.751l-.548-.948a.75.75 0 00-.649-.375h-1.1a.753.753 0 00-.649.375l-.548.948a.75.75 0 000 .75z\"/><circle cx=\"13.125\" cy=\"1.75\" r=\".75\"/><circle cx=\"21.875\" cy=\"11.5\" r=\".75\"/><circle cx=\"21.375\" cy=\"16.25\" r=\".75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});