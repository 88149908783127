define("ember-svg-jar/inlined/livestock-ram-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>livestock-ram-1</title><path d=\"M11.5 0C6.875 0 3.691 3.292 3.494 6.93A6.008 6.008 0 006.1 12.238C3.038 16.123 3 22.675 3 23a1 1 0 001 1h7.917a.5.5 0 00.363-.844 8.077 8.077 0 01-2.063-6.485.742.742 0 01.811-.682.75.75 0 01.683.81 6.739 6.739 0 001.916 5.491A4.508 4.508 0 0017 24c4 0 4-4.066 4-5.4V9.315C21 4.812 18.5 0 11.5 0zM17 14.737a1 1 0 111-1 1 1 0 01-1 1zm-1.929-5.184a.5.5 0 01-.33-.544c.016-.113.028-.227.036-.341a4.281 4.281 0 00-3.988-4.576A3.645 3.645 0 006.843 7.41a1 1 0 00.921 1.072 1.01 1.01 0 001.073-.92 1.648 1.648 0 011.8-1.477 2.28 2.28 0 012.146 2.438 3.134 3.134 0 01-3.413 2.828A4.167 4.167 0 015.5 6.948C5.722 4.126 8.3 2 11.5 2c6.093 0 7.294 4.146 7.473 6.579a.5.5 0 01-.216.449 3.974 3.974 0 01-3.686.525z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});