define("ember-svg-jar/inlined/gymnastics-ribbon-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gymnastics-ribbon-person</title><path d=\"M9.648 20.952a.25.25 0 00-.273.249V24h3v-2.862a.25.25 0 00-.285-.248 10.4 10.4 0 01-1.465.11c-.325 0-.655-.018-.977-.048zM14.947 11.024a5.506 5.506 0 00-3.52-1.274H8.875a2.5 2.5 0 01-2.5-2.5v-2.5a1.5 1.5 0 00-3 0v2.5a5.507 5.507 0 005.5 5.5h.5v3.92a.249.249 0 00.212.247 6.71 6.71 0 001.038.083 7 7 0 001.556-.175.25.25 0 00.194-.244v-3.636a2.487 2.487 0 01.651.384 7.177 7.177 0 002.387 1.571.252.252 0 00.268-.062 7.024 7.024 0 001.454-2.279.251.251 0 00-.2-.34 3.939 3.939 0 01-1.988-1.195zM21.875 12.25h-.282a.251.251 0 00-.244.194 10.908 10.908 0 01-.872 2.445.25.25 0 00.224.361h1.174a1.5 1.5 0 000-3zM13.535 20.826a5.473 5.473 0 003.051.924h3.789a1.5 1.5 0 000-3h-3.028a.243.243 0 00-.149.05 10.949 10.949 0 01-3.624 1.785.134.134 0 00-.039.241z\"/><path d=\"M20.625 10A9.989 9.989 0 004.764 1.908a.189.189 0 00.111.342 2.477 2.477 0 011.689.667.251.251 0 00.289.036 7.98 7.98 0 11-4.186 6.225 6.465 6.465 0 01-.292-1.928V5.241a.25.25 0 00-.468-.122A9.908 9.908 0 00.625 10a10 10 0 0020 0z\"/><circle cx=\"11.375\" cy=\"5.25\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});