define("ember-svg-jar/inlined/halloween-web", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>halloween-web</title><path d=\"M23.407 15.685l-.867-.359a10.079 10.079 0 010-6.653l.867-.359a.96.96 0 10-.735-1.774l-.866.359a10.083 10.083 0 01-4.705-4.7l.359-.867a.96.96 0 10-1.774-.739l-.359.867a10.079 10.079 0 01-6.653 0l-.36-.867a.96.96 0 10-1.773.735l.359.867a10.083 10.083 0 01-4.7 4.7l-.872-.355a.96.96 0 10-.735 1.774l.868.359a10.092 10.092 0 010 6.653l-.868.359a.96.96 0 00.735 1.774l.867-.359A10.09 10.09 0 016.9 21.8l-.359.867a.96.96 0 00.519 1.255.972.972 0 00.367.078.959.959 0 00.887-.593l.359-.867a10.082 10.082 0 016.654 0l.359.867a.96.96 0 00.887.593.977.977 0 00.368-.073.961.961 0 00.519-1.255l-.359-.867a10.081 10.081 0 014.705-4.7l.866.359a.96.96 0 10.735-1.774zm-2.574-1.066l-1.653-.685a6.5 6.5 0 010-3.869l1.653-.685a11.5 11.5 0 000 5.239zm-5.8-7.43a7.9 7.9 0 001.779 1.779l-3.037 1.258zM12 9.491l-1.258-3.037a7.866 7.866 0 002.516 0zm-1.774.735L7.189 8.968a7.882 7.882 0 001.779-1.779zM9.491 12l-3.037 1.257a7.866 7.866 0 000-2.516zm.736 1.773l-1.259 3.038a7.911 7.911 0 00-1.78-1.78zm1.773.736l1.258 3.037a7.66 7.66 0 00-2.516 0zm1.774-.736l3.038 1.259a7.887 7.887 0 00-1.78 1.779zM14.509 12l3.038-1.259a7.9 7.9 0 000 2.517zM20.1 7.606l-1.654.685a6.483 6.483 0 01-2.736-2.736l.684-1.655A11.5 11.5 0 0020.1 7.606zm-5.48-4.439l-.685 1.653a6.467 6.467 0 01-3.87 0l-.684-1.653a11.5 11.5 0 005.239 0zM7.607 3.9l.685 1.653a6.489 6.489 0 01-2.736 2.738L3.9 7.606A11.5 11.5 0 007.607 3.9zm-4.44 5.48l1.654.685a6.5 6.5 0 010 3.869l-1.654.685a11.5 11.5 0 000-5.239zm.733 7.013l1.653-.685a6.5 6.5 0 012.737 2.737L7.607 20.1A11.5 11.5 0 003.9 16.393zm5.478 4.44l.685-1.653a6.467 6.467 0 013.87 0l.685 1.653a11.507 11.507 0 00-5.238 0zm7.014-.735l-.685-1.653a6.48 6.48 0 012.737-2.736l1.652.684a11.491 11.491 0 00-3.702 3.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});