define("ember-svg-jar/inlined/bicycle-city-guide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bicycle-city-guide</title><path d=\"M21.1 14.015a1.5 1.5 0 001.405-1.5V10A2.5 2.5 0 0020 7.5h-1.5a1 1 0 000 2H20a.5.5 0 01.5.5v1.851a.25.25 0 01-.206.246 10.424 10.424 0 00-7.208 6.244.251.251 0 01-.233.159h-.443a.25.25 0 01-.248-.216 7.346 7.346 0 00-.443-1.713.251.251 0 01.046-.258l3.3-3.733a.25.25 0 01.187-.084h.248a1 1 0 000-2h-3a1 1 0 00-.393 1.915.252.252 0 01.146.175.247.247 0 01-.057.221l-1.466 1.659a.25.25 0 01-.365.01 6.5 6.5 0 00-2.773-1.626.247.247 0 01-.159-.145.25.25 0 01.012-.215l4.661-8.48a1 1 0 00-.122-1.139A6.94 6.94 0 006.75.5 6.8 6.8 0 000 7.5v6a1 1 0 001 1h4.25c.143 0 .277.008.411.017 3.99.325 4.588 3.257 4.589 4.979v.004a1 1 0 001 1h1.775a2.006 2.006 0 001.875-1.309c.942-2.559 3.762-5.026 6.2-5.176z\"/><path d=\"M20 15.5a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 112-2 2 2 0 01-2 2zM5.25 15.5a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});