define("ember-svg-jar/inlined/trekking-stick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trekking-stick</title><circle cx=\"8.499\" cy=\"2.875\" r=\"2.5\"/><path d=\"M3.135 23.33a1.54 1.54 0 00.366.045 1.5 1.5 0 001.454-1.137l1.107-4.427a.25.25 0 01.456-.069 14.569 14.569 0 011.524 4.485A1.5 1.5 0 009.5 23.375a1.533 1.533 0 00.353-.042 1.5 1.5 0 001.106-1.811c-1.025-4.234-1.382-4.851-3.739-7.954a.252.252 0 01-.044-.212l.351-1.4a.25.25 0 01.41-.125 8.819 8.819 0 004.852 2.031 1.466 1.466 0 00.208.015 1.5 1.5 0 00.216-2.984c-3.13-.45-3.796-1.693-4.64-3.265l-.262-.482A1.493 1.493 0 007 6.375a1.624 1.624 0 00-.2.012 1.5 1.5 0 00-1.255 1.124l-3.5 14a1.5 1.5 0 001.09 1.819zM3.177 4.4a1.505 1.505 0 00-1.877 1L.065 9.483A1.5 1.5 0 001.5 11.419h.1a1.509 1.509 0 001.34-1.061L4.18 6.27A1.5 1.5 0 003.177 4.4zM18.307.428a.5.5 0 00-.523.047l-3.333 2.5a.5.5 0 000 .8l3.333 2.5a.5.5 0 00.3.1.5.5 0 00.5-.5v-5a.5.5 0 00-.277-.447z\"/><path d=\"M17.342 12.622l-4 3.5a1 1 0 001.317 1.506l.427-.374a.25.25 0 01.414.188v4.933a1 1 0 002 0v-7.12a.247.247 0 01.086-.188l1.073-.939a1 1 0 00-1.317-1.506zM20.5 23.625h2a1.5 1.5 0 001.5-1.5v-2a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25H20.5a1 1 0 000 2zM24 3.848a1.5 1.5 0 00-1.5-1.5h-2a1 1 0 000 2h1.25A.25.25 0 0122 4.6v1.25a1 1 0 002 0zM24 9.348a1 1 0 10-2 0v2.027a1 1 0 002 0zM23 13.875a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});