define("ember-svg-jar/inlined/card-game-cards-hold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>card-game-cards-hold</title><path d=\"M14.114 9.449a.368.368 0 000-.443l-1.755-2.34a.369.369 0 00-.591 0l-1.755 2.34a.368.368 0 000 .443l.3.4a.243.243 0 00.127.089 3.117 3.117 0 011.792 1.442.25.25 0 00.418.027z\"/><path d=\"M23.889 7.7a2.1 2.1 0 00-1.562-2.537l-3.75-.892a.249.249 0 01-.192-.243V2.906A2.109 2.109 0 0016.278.8H7.849a2.109 2.109 0 00-2.107 2.106v4.742a.25.25 0 01-.059.161c-1.327 1.568-3.627 3.041-3.627 4.683v4.8L.067 22.867A.25.25 0 00.3 23.2h5.98a.249.249 0 00.25-.25v-2.106a.246.246 0 01.076-.179C6.907 20.371 8 19.239 8 18.352V16.81a.251.251 0 01.071-.176l2.633-2.673.007-.007a1.584 1.584 0 00-2.231-2.243c-.1.09-.355.217-.255.157a.25.25 0 01-.378-.215v-8.5a.25.25 0 01.25-.25h7.929a.25.25 0 01.25.25V15.3a.25.25 0 01-.25.25h-5.282a1.054 1.054 0 100 2.107h5.534a2.109 2.109 0 002.107-2.107V6.708a.25.25 0 01.308-.243l2.9.69a.25.25 0 01.185.3L18.975 19.27a.25.25 0 01-.3.185l-3.859-.917a1.054 1.054 0 00-.488 2.051l4.1.974a2.1 2.1 0 001.593-.256A2.089 2.089 0 0020.965 20z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});