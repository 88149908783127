define("ember-svg-jar/inlined/arrow-button-down-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-down-2-alternate</title><path d=\"M12 22.405a2.8 2.8 0 01-2.42-1.364L.409 5.888A2.828 2.828 0 012.829 1.6h18.343a2.829 2.829 0 012.42 4.294L14.42 21.041A2.807 2.807 0 0112 22.405zM4.937 5.367a.244.244 0 00-.21.371l7.064 11.672a.245.245 0 00.419 0l7.063-11.672a.244.244 0 00-.209-.371z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});