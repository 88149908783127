define("ember-svg-jar/inlined/gymnastics-rhythmic-ribbon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gymnastics-rhythmic-ribbon</title><path d=\"M13.518 0A12.82 12.82 0 001.63 7.956a1 1 0 001.849.763A10.825 10.825 0 0113.518 2 8.492 8.492 0 0122 10.482a6.593 6.593 0 01-6.586 6.586A5.075 5.075 0 0110.345 12 3.86 3.86 0 0114.2 8.144a2.887 2.887 0 012.884 2.884 1 1 0 102 0A4.89 4.89 0 0014.2 6.144 5.862 5.862 0 008.345 12a7.077 7.077 0 007.069 7.069A8.6 8.6 0 0024 10.482 10.5 10.5 0 0013.518 0zM3.206 11.78a1.679 1.679 0 00-3.053 1.4l4.505 9.841a1.678 1.678 0 103.052-1.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});