define("ember-svg-jar/inlined/disability-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-information</title><path d=\"M5.5 22a3.492 3.492 0 01-.875-6.874.5.5 0 00.375-.484V13.6a.5.5 0 00-.6-.489 5.5 5.5 0 106.135 7.589.5.5 0 00-.458-.7H8.95a.5.5 0 00-.433.249A3.486 3.486 0 015.5 22z\"/><circle cx=\"8\" cy=\"3.5\" r=\"2.5\"/><path d=\"M17.772 20.705l-2.3-3.56A3.48 3.48 0 0012.5 15.5H10a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h3a1.5 1.5 0 000-3h-3a.5.5 0 01-.5-.5V9a1.5 1.5 0 00-3 0v8A1.5 1.5 0 008 18.5h4.5a.5.5 0 01.424.235l2.3 3.56a1.5 1.5 0 002.544-1.59zM18.5 0A5.5 5.5 0 1024 5.5 5.507 5.507 0 0018.5 0zm1.25 9h-2a.75.75 0 010-1.5H18V6h-.25a.75.75 0 010-1.5h.5a1.252 1.252 0 011.25 1.25V7.5h.25a.75.75 0 010 1.5zm-1.5-7.25a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});