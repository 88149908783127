define("ember-svg-jar/inlined/indent-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>indent-left</title><path d=\"M5.207 8.791A1 1 0 003.5 9.5v.75a.25.25 0 01-.25.25H1a1 1 0 000 2h2.25a.25.25 0 01.25.25v.75a1 1 0 00.617.924 1 1 0 001.09-.217l2-2a1 1 0 000-1.414zM9 0a1 1 0 00-1 1v22a1 1 0 002 0V1a1 1 0 00-1-1zM22.5 20.5h-10a1 1 0 000 2h10a1 1 0 000-2zM12.5 2.5H23a1 1 0 000-2H12.5a1 1 0 000 2zM12.5 7.5h8a1 1 0 000-2h-8a1 1 0 000 2zM23 10.5H12.5a1 1 0 000 2H23a1 1 0 000-2zM12.5 17.5h8a1 1 0 000-2h-8a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});