define("ember-svg-jar/inlined/monetization-user-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monetization-user-monitor</title><path d=\"M8 10.5a1 1 0 001-1v-7c0-.3.289-.5.719-.5a1 1 0 000-2A2.559 2.559 0 007 2.5v7a1 1 0 001 1zM10 12a1 1 0 001 1h3.25a.25.25 0 01.25.25v1a.249.249 0 01-.25.25H11a1 1 0 000 2h9a1 1 0 000-2h-3.25a.249.249 0 01-.25-.25v-1a.25.25 0 01.25-.25h4.375A2.71 2.71 0 0024 10.5v-8A2.711 2.711 0 0021.125 0a1 1 0 000 2c.534 0 .875.3.875.5v8c0 .2-.341.5-.875.5H11a1 1 0 00-1 1z\"/><path d=\"M2.722 18.913A5.24 5.24 0 000 23.5a.5.5 0 00.5.5H10a.5.5 0 00.5-.5 5.251 5.251 0 00-2.785-4.629.249.249 0 01-.035-.418 4.091 4.091 0 10-4.93.04.251.251 0 01-.028.42zM6 21.746a.75.75 0 01-1.5 0v-1.105a.75.75 0 011.5 0zm-2.808-7.119a.251.251 0 01.369-.139 5.084 5.084 0 002.653.742 5.235 5.235 0 00.952-.087.088.088 0 01.106.087 2.087 2.087 0 11-4.174 0 2 2 0 01.094-.603zM14.315 5.284l2.063.825a.592.592 0 01-.22 1.141h-2.033a.75.75 0 000 1.5h.5a.25.25 0 01.25.25v.25a.75.75 0 001.5 0v-.308a.252.252 0 01.2-.245 2.084 2.084 0 00.36-3.98l-2.063-.825a.592.592 0 01.22-1.142h2.033a.75.75 0 000-1.5h-.5a.249.249 0 01-.25-.25V.75a.75.75 0 00-1.5 0v.308a.25.25 0 01-.2.245 2.084 2.084 0 00-.36 3.981z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});