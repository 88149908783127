define("ember-svg-jar/inlined/smiley-sick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-sick</title><path d=\"M24 12a11.994 11.994 0 10-8.517 11.485.256.256 0 01.155 0 9.137 9.137 0 003.021.515 5.34 5.34 0 004.7-7.878.251.251 0 01-.016-.2A11.954 11.954 0 0024 12zm-4.084-3.1a3.5 3.5 0 01-6.415 0 1 1 0 111.832-.8 1.5 1.5 0 002.751 0 1 1 0 111.832.8zM7.5 11a3.187 3.187 0 01-2.935-2.144 1 1 0 011.87-.712A1.207 1.207 0 007.5 9a1.207 1.207 0 001.065-.856 1 1 0 011.87.712A3.187 3.187 0 017.5 11zm-.7 7.5a.5.5 0 01-.433-.75 6.528 6.528 0 012.786-2.6.253.253 0 01.236.009.25.25 0 01.123.2 9.115 9.115 0 00.608 2.794.251.251 0 01-.233.341zm15.2.159A3.341 3.341 0 0118.659 22a7.159 7.159 0 01-7.159-7.159.955.955 0 011.909 0 1.808 1.808 0 003.023 1.339 3.3 3.3 0 012.227-.862A3.341 3.341 0 0122 18.659z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});