define("ember-svg-jar/inlined/water-protection-drop-hold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-protection-drop-hold</title><path d=\"M17 8.643C17 6.516 14.375 2.566 12.811.4a1.041 1.041 0 00-1.622 0C9.625 2.566 7 6.516 7 8.643a4.89 4.89 0 005 5 4.89 4.89 0 005-5zm-4.75 3A3.254 3.254 0 019 8.393a.5.5 0 011 0 2.252 2.252 0 002.25 2.25.5.5 0 010 1z\"/><rect x=\"3\" y=\"20.987\" width=\"6\" height=\"3\" rx=\".5\" ry=\".5\"/><path d=\"M4 18.857v.63a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3.553a3 3 0 00-.138-.9 19.614 19.614 0 00-1.393-3.513c-.717-1.115-2.032-.5-1.688.938l.383 1.669a.393.393 0 01-.66.366L2.79 12.777a1 1 0 01-.293-.693C2.435 7.809 1.384 6.706.666 6.454A.5.5 0 000 6.926v7.105a1 1 0 00.329.741c.959.868 2.714 2.685 3.393 3.393a1 1 0 01.278.692z\"/><rect x=\"15\" y=\"20.987\" width=\"6\" height=\"3\" rx=\".5\" ry=\".5\"/><path d=\"M23.789 6.518a.5.5 0 00-.455-.064c-.718.252-1.769 1.355-1.831 5.63a1 1 0 01-.293.693l-1.71 1.714a.393.393 0 01-.66-.366l.383-1.669c.343-1.438-.971-2.053-1.688-.938a19.614 19.614 0 00-1.393 3.513 3 3 0 00-.138.9v3.553a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-.63a1 1 0 01.278-.692c.679-.708 2.434-2.525 3.393-3.393a1 1 0 00.325-.738v-7.1a.5.5 0 00-.211-.413z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});