define("ember-svg-jar/inlined/fox-tail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fox-tail</title><path d=\"M19.193 6.248a1 1 0 00-1.466 1.12A8.376 8.376 0 0118 9.5c0 1.121-.134 8.709-8.925 8.44a6.932 6.932 0 00-2.753.237.75.75 0 01-.644-1.354 7.728 7.728 0 013.444-.383 11.532 11.532 0 002.318-.1L9.744 11H10c1.9 0 3.165-1.414 3.455-3.869A1 1 0 0012.46 6h-1.986A4.009 4.009 0 007.5 2.6V.5A.5.5 0 007 0C1.286 0 0 11.479 0 15.5v4.01A4.5 4.5 0 004.5 24h10a9.5 9.5 0 004.693-17.752zM7.5 7.005a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});