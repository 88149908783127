define("ember-svg-jar/inlined/e-learning-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-learning-monitor</title><path d=\"M14.348 20.182a.5.5 0 00-.385-.182h-3.93a.5.5 0 00-.385.181l-2.1 2.532a.5.5 0 00.409.787h8.079a.5.5 0 00.41-.787zM22.5.5h-21A1.5 1.5 0 000 2v15.5A1.5 1.5 0 001.5 19h21a1.5 1.5 0 001.5-1.5V2A1.5 1.5 0 0022.5.5zM22 15a.5.5 0 01-.5.5h-19A.5.5 0 012 15V3a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M19.654 6.373l-7-2.779a.5.5 0 00-.369 0l-7 2.779a.5.5 0 00-.315.475v3.9a.75.75 0 001.5 0v-2.32a.5.5 0 01.666-.471L12.3 9.78a.468.468 0 00.166.029.476.476 0 00.166-.029l7-2.47a.5.5 0 00.018-.937z\"/><path d=\"M16.3 9.546l-3.668 1.3a.5.5 0 01-.333 0l-3.663-1.3a.5.5 0 00-.666.472v2.5a.5.5 0 00.334.471l3 1.06a3.507 3.507 0 002.329 0l3-1.06a.5.5 0 00.334-.471v-2.5a.5.5 0 00-.666-.472z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});