define("ember-svg-jar/inlined/car-key-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-key-2</title><path d=\"M9 9a4.48 4.48 0 003.9-2.249.5.5 0 01.434-.251H19a.5.5 0 00.354-.146l1.5-1.5a.5.5 0 000-.708l-1.5-1.5A.5.5 0 0019 2.5h-5.668a.5.5 0 01-.434-.251A4.5 4.5 0 109 9zm0-6.512A2.012 2.012 0 116.989 4.5 2.014 2.014 0 019 2.488zM19.485 13.643l-.509.3a.5.5 0 01-.754-.37l-.122-1.057a2 2 0 00-1.986-1.766H7.889A2 2 0 005.9 12.516l-.125 1.062a.5.5 0 01-.754.37l-.509-.3a1 1 0 10-1.03 1.714l.823.494a.5.5 0 01.15.719A2.462 2.462 0 004 18v1a1.5 1.5 0 001.5 1.5h13A1.5 1.5 0 0020 19v-1a2.462 2.462 0 00-.458-1.43.5.5 0 01.15-.719l.823-.494a1 1 0 10-1.03-1.714zm-3.819-.893a.5.5 0 01.5.442l.206 1.75a.5.5 0 01-.5.558H8.128a.5.5 0 01-.5-.558l.206-1.75a.5.5 0 01.5-.442zM7.5 21.5h-2a.5.5 0 00-.5.5v.5a1.5 1.5 0 003 0V22a.5.5 0 00-.5-.5zM18.5 21.5h-2a.5.5 0 00-.5.5v.5a1.5 1.5 0 003 0V22a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});