define("ember-svg-jar/inlined/task-list-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-list-check</title><path d=\"M12 19.874a5.5 5.5 0 005.324-4.117 1 1 0 10-1.935-.5A3.5 3.5 0 1112 10.874a1 1 0 000-2 5.5 5.5 0 000 11z\"/><path d=\"M15.792 10.292l-3.615 3.616a.252.252 0 01-.354 0l-.344-.344a1 1 0 00-1.414 1.414l.831.831a1.564 1.564 0 002.208 0l4.1-4.1a1 1 0 00-1.414-1.414zM7.5 6.124a.249.249 0 00.25.25h8.5a.249.249 0 00.25-.25v-3a.25.25 0 00-.25-.25H14.5v-.25A2.508 2.508 0 0011.321.215 2.559 2.559 0 009.5 2.712v.162H7.75a.25.25 0 00-.25.25zm4.5-4.5a.75.75 0 11-.75.75.75.75 0 01.75-.75z\"/><path d=\"M21.5 4.874a2 2 0 00-2-2h-1.25a.25.25 0 00-.25.25v1.5a.249.249 0 00.25.25H19a.5.5 0 01.5.5v16a.5.5 0 01-.5.5H5a.5.5 0 01-.5-.5v-16a.5.5 0 01.5-.5h.75a.249.249 0 00.25-.25v-1.5a.25.25 0 00-.25-.25H4.5a2 2 0 00-2 2v17a2 2 0 002 2h15a2 2 0 002-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});