define("ember-svg-jar/inlined/warehouse-cart-packages-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>warehouse-cart-packages-3</title><path d=\"M10.75 19h8a1 1 0 001-1v-7a1 1 0 00-1-1h-8a1 1 0 00-1 1v7a1 1 0 001 1zm5-2.75h2a.75.75 0 010 1.5h-2a.75.75 0 010-1.5z\"/><rect x=\"9.75\" y=\"3.5\" width=\"7\" height=\"5\" rx=\"1\" ry=\"1\"/><path d=\"M20.457 22.293l-1.207-1.207a2 2 0 00-1.415-.586H9.4a.268.268 0 01-.246-.2 2.985 2.985 0 00-.828-1.446.254.254 0 01-.08-.183V2a2 2 0 00-2-2h-2a1 1 0 000 2H6a.25.25 0 01.25.25v15.509a.259.259 0 01-.242.251 3 3 0 102.752 4.61.254.254 0 01.214-.12h8.448a1 1 0 01.707.293l.914.914a1 1 0 001.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});