define("ember-svg-jar/inlined/seasoning-pepper-ground", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seasoning-pepper-ground</title><path d=\"M18 22a1.5 1.5 0 00-1.5-1.5h-9A1.5 1.5 0 006 22v1a1 1 0 001 1h10a1 1 0 001-1zM8.5 19a.5.5 0 01-.5-.5v-.9a2.015 2.015 0 01.115-.666c1.18-3.342 1.18-4.268 0-7.61A1.989 1.989 0 018 8.654V8a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v.654a1.989 1.989 0 01-.115.666c-1.18 3.342-1.18 4.268 0 7.61a2.015 2.015 0 01.115.67v.9a.5.5 0 01-.5.5zM18 4.5A1.5 1.5 0 0016.5 3h-2.378a.251.251 0 01-.236-.333 2 2 0 10-3.772 0A.251.251 0 019.878 3H7.5a1.5 1.5 0 000 3h9A1.5 1.5 0 0018 4.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});