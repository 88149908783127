define("ember-svg-jar/inlined/arrow-thick-left-bottom-corner-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-left-bottom-corner-3</title><path d=\"M21.294 5.523L18.466 2.7a1 1 0 00-1.416 0l-8.216 8.256a.25.25 0 01-.427-.176v-6.6a1 1 0 00-1.71-.7L2.7 7.507a1 1 0 00-.29.7V20.6a1 1 0 001 1h12.38a1.006 1.006 0 00.711-.3l3.959-4a1 1 0 00-.711-1.7h-6.533a.25.25 0 01-.177-.427l8.254-8.233a1 1 0 00.001-1.417z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});