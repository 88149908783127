define("ember-svg-jar/inlined/single-neutral-id-card-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-id-card-1</title><path d=\"M19.5 3H17a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h2a.5.5 0 01.5.5v16a.5.5 0 01-.5.5H5a.5.5 0 01-.5-.5v-16A.5.5 0 015 5h2a.5.5 0 00.5-.5v-1A.5.5 0 007 3H4.5a2 2 0 00-2 2v17a2 2 0 002 2h15a2 2 0 002-2V5a2 2 0 00-2-2z\"/><path d=\"M9 6a1.5 1.5 0 001.5 1.5h3A1.5 1.5 0 0015 6V3a3 3 0 00-6 0zm2-3.5a1 1 0 111 1 1 1 0 01-1-1zM9.118 11.876a5.183 5.183 0 001.114 3.576 1.076 1.076 0 01.008.748c-2.054.761-3.18 1.312-3.628 2.205a5.579 5.579 0 00-.485 1.5.5.5 0 00.492.588H17.5a.5.5 0 00.384-.18.494.494 0 00.108-.408 5.544 5.544 0 00-.485-1.5c-.447-.893-1.573-1.444-3.611-2.2a1.047 1.047 0 01-.027-.737A5.194 5.194 0 0015 11.876 2.969 2.969 0 0013.258 9a3.209 3.209 0 00-2.4 0 2.968 2.968 0 00-1.74 2.876z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});