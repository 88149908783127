define("ember-svg-jar/inlined/global-warming-dead-plant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>global-warming-dead-plant</title><path d=\"M15.25 7.5a2.436 2.436 0 00.566 1.648 1.862 1.862 0 01.434 1.352 3.175 3.175 0 01-.351 1.526 1 1 0 00.881 1.474h2.94a1 1 0 00.88-1.475 3.179 3.179 0 01-.35-1.525 1.862 1.862 0 01.434-1.352A2.436 2.436 0 0021.25 7.5c0-1.579-1.886-2-3-2s-3 .421-3 2z\"/><path d=\"M23 22h-9.75a.5.5 0 01-.5-.5v-6.341a.25.25 0 01.341-.233 1.176 1.176 0 01.717.55 1.223 1.223 0 01.043.67 2.366 2.366 0 00.567 2.369 3.291 3.291 0 01.751 1.69 1 1 0 001.378.712 2.926 2.926 0 001.7-3.345 3.4 3.4 0 00-1.309-2.443 2.831 2.831 0 00-1.081-.459.5.5 0 01-.336-.245 3.7 3.7 0 00-2.393-1.582.5.5 0 01-.377-.485V4a2 2 0 013.972-.335 1 1 0 001.973-.33A4 4 0 0010.75 4v3.468a.5.5 0 01-.376.484 3.7 3.7 0 00-2.393 1.582.5.5 0 01-.337.246 2.843 2.843 0 00-1.081.458 3.4 3.4 0 00-1.309 2.444 2.924 2.924 0 001.7 3.344 1 1 0 001.378-.712 3.3 3.3 0 01.751-1.69 2.364 2.364 0 00.567-2.368 1.227 1.227 0 01.043-.671 1.183 1.183 0 01.717-.55.25.25 0 01.341.233V21.5a.5.5 0 01-.5.5H1a1 1 0 000 2h22a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});