define("ember-svg-jar/inlined/gardening-tools-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gardening-tools-1</title><path d=\"M19.833 10.73a.5.5 0 01-.333-.471V1.5a1.5 1.5 0 00-3 0v8.759a.5.5 0 01-.333.471A4.022 4.022 0 0013.5 14.5V23a1 1 0 002 0v-6.249a.75.75 0 011.5 0V23a1 1 0 002 0v-6.249a.75.75 0 011.5 0V23a1 1 0 002 0v-8.5a4.022 4.022 0 00-2.667-3.77zM5.826.274C2.875 3.131 1.5 7.1 1.5 12.76V15a1 1 0 001 1h1a.5.5 0 00.5-.5v-2a.5.5 0 011 0v9a1.5 1.5 0 003 0v-9a.5.5 0 011 0v2a.5.5 0 00.5.5h1a1 1 0 001-1v-2.172C11.5 7.2 10.085 3.094 7.174.275A.965.965 0 005.826.274z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});