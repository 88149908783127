define("ember-svg-jar/inlined/designer-community-design-bump", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>designer-community-design-bump</title><path d=\"M13 12.18a.5.5 0 00-.13-.55l-5.47-5a.5.5 0 00-.67 0l-5.5 5a.5.5 0 00.34.87H4v11a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-11h2.47a.5.5 0 00.53-.32z\"/><path d=\"M22.82 18.13l-5.47-5a.5.5 0 00-.67 0l-5.5 5a.5.5 0 00.34.87H14v4.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V19h2.47a.5.5 0 00.34-.87z\"/><path d=\"M11 23.5a.5.5 0 00.5.5h1.25v-3.75H11zM8.52 6H11v2.29l4.55 4.16.94-.86a.75.75 0 01.5-.19V6h2.47a.5.5 0 00.34-.87l-5.47-5a.5.5 0 00-.67 0l-5.5 5a.5.5 0 00.36.87zM11.02 16.59l3.12-2.84h-3.12v2.84z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});