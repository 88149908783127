define("ember-svg-jar/inlined/reptile-crocodile-water", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>reptile-crocodile-water</title><path d=\"M1.131 15h.041a3.176 3.176 0 001.811-1.136 2.5 2.5 0 014.09.226 2.109 2.109 0 001.487.875 2.789 2.789 0 001.91-1.047 2.582 2.582 0 012-1 2.5 2.5 0 012.26 1.424 1.277 1.277 0 001.051.628 1.662 1.662 0 00.219-.021V13.5a1.1 1.1 0 011-1h2.881l.895.448a.5.5 0 00.447 0l.814-.407 1.3.434a.507.507 0 00.45-.069A.5.5 0 0024 12.5V9a1.5 1.5 0 00-2.914-.5h-3.121a3.5 3.5 0 00-6.928 0H9.208l-.853-.853a.5.5 0 00-.707 0L7 8.293l-.647-.646a.5.5 0 00-.707 0L4.8 8.5H.5A.5.5 0 000 9v6.194A2.413 2.413 0 011.131 15zM13.5 9a1 1 0 111 1 1 1 0 01-1-1z\"/><path d=\"M22.929 16.453c-1.581.137-2.667-1.556-2.676-1.57a1.043 1.043 0 00-1.719.03 3.362 3.362 0 01-2.748 1.551 2.8 2.8 0 01-2.4-1.482 1.046 1.046 0 00-1.715-.16 4.308 4.308 0 01-3.111 1.642 3.557 3.557 0 01-2.76-1.581 1.041 1.041 0 00-1.638-.091c-.014.018-1.39 1.708-2.992 1.708h-.117A.976.976 0 000 17.442a1 1 0 00.947 1.052c.075 0 .148.006.221.006A5.841 5.841 0 004.9 16.892a5.24 5.24 0 003.66 1.572 5.85 5.85 0 003.81-1.5 4.575 4.575 0 003.416 1.5 5.155 5.155 0 003.622-1.5 4.809 4.809 0 003.659 1.483A1 1 0 0024 17.381a1.018 1.018 0 00-1.071-.928z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});