define("ember-svg-jar/inlined/ui-webpage-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ui-webpage-slider</title><path d=\"M2 4.065a.5.5 0 00-.5.5v13a.5.5 0 00.5.5h2.25a.25.25 0 00.25-.25v-13.5a.249.249 0 00-.25-.25zM22 4.065h-2.25a.249.249 0 00-.25.25V16a.249.249 0 00.162.234l.619.232a1.494 1.494 0 01.939 1.087 1.314 1.314 0 01.028.263.251.251 0 00.25.249H22a.5.5 0 00.5-.5v-13a.5.5 0 00-.5-.5zM5.5 18.565a1 1 0 001 1h8.575a.25.25 0 00.234-.338l-.859-2.292A1.5 1.5 0 0116.381 15l1.781.668a.251.251 0 00.338-.234V3.565a1 1 0 00-1-1h-11a1 1 0 00-1 1zm7.5-6.25H8.5a.75.75 0 010-1.5H13a.75.75 0 010 1.5zm2.5-4.5h-7a.75.75 0 010-1.5h7a.75.75 0 010 1.5z\"/><path d=\"M19.591 19.1a.25.25 0 010-.354l.517-.516a.5.5 0 00-.178-.822l-3.9-1.463a.5.5 0 00-.644.644l1.463 3.9a.5.5 0 00.821.178l.507-.507a.25.25 0 01.353 0l1.061 1.061a.75.75 0 101.06-1.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});