define("ember-svg-jar/inlined/smart-watch-square-brightness", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-brightness</title><path d=\"M16.43 2.75a.251.251 0 00.238-.329L15.975.342A.5.5 0 0015.5 0h-7a.5.5 0 00-.475.342l-.693 2.079a.251.251 0 00.238.329zM7.57 21.25a.248.248 0 00-.2.1.251.251 0 00-.035.225l.693 2.079A.5.5 0 008.5 24h7a.5.5 0 00.475-.342l.693-2.079a.251.251 0 00-.035-.225.248.248 0 00-.2-.1zM17.5 4h-11A2.5 2.5 0 004 6.5v11A2.508 2.508 0 006.5 20h11a2.5 2.5 0 002.5-2.5v-11A2.5 2.5 0 0017.5 4zM9.75 15a1 1 0 11-1 1 1 1 0 011-1zm-1-7a1 1 0 111 1 1 1 0 01-1-1zM10 12.25a2 2 0 112 2 2 2 0 01-2-2zM14.25 15a1 1 0 11-1 1 1 1 0 011-1zm0-6a1 1 0 111-1 1 1 0 01-1 1zm-7 2a1 1 0 11-1 1 1 1 0 011-1zm9.5 2a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});