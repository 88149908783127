define("ember-svg-jar/inlined/laundry-retro-iron", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laundry-retro-iron</title><path d=\"M21.58 6.851A5.239 5.239 0 0016.408 2.5H8.522a1.25 1.25 0 000 2.5h7.886a2.745 2.745 0 012.709 2.278l.459 2.636a.5.5 0 01-.493.586h-8.535A10.512 10.512 0 00.048 21a.5.5 0 00.5.5h22.4a1 1 0 00.984-1.174zM7.952 17.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm5 0a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm5 0a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});