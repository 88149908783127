define("ember-svg-jar/inlined/heavy-equipment-mortar-truck-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>heavy-equipment-mortar-truck-1</title><path d=\"M23.5 16h-13a.5.5 0 01-.5-.5v-7a1 1 0 00-1-1H5.5a1.426 1.426 0 00-1.18.694L2.1 12.2.5 13.8A1.872 1.872 0 000 15v3.5a1 1 0 001 1h.536a.5.5 0 00.494-.428 3 3 0 015.94 0 .5.5 0 00.494.428h7.374a.5.5 0 00.487-.384 2.749 2.749 0 015.35 0 .5.5 0 00.486.385H23.5A.5.5 0 0024 19v-2.5a.5.5 0 00-.5-.5zM8.25 9.678v2.143a.429.429 0 01-.428.429H4.7a.428.428 0 01-.372-.641l1.227-2.143a.427.427 0 01.372-.216h1.9a.428.428 0 01.423.428zM23.5 4.705a.473.473 0 00-.137-.372l-1.695-1.7a.471.471 0 00-.373-.136 23.549 23.549 0 00-4.721.875.5.5 0 00-.2.83L21.8 9.634a.5.5 0 00.83-.2 23.272 23.272 0 00.87-4.729z\"/><path d=\"M13.176 12.823c2.026 2.026 4.669 2.668 8-.667.06-.06.119-.123.176-.189a.5.5 0 00-.026-.679L14.712 4.67a.5.5 0 00-.678-.027 3.237 3.237 0 00-.191.176c-3.336 3.335-2.693 5.981-.667 8.004z\"/><circle cx=\"5\" cy=\"19.5\" r=\"2\"/><circle cx=\"19\" cy=\"19.75\" r=\"1.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});