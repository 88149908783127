define("ember-svg-jar/inlined/single-man-actions-information.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-information</title><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-.5 2.249a1 1 0 11-1 1 1 1 0 011-1zm2 8.5h-3a.75.75 0 010-1.5h.5a.25.25 0 00.25-.25V18a.25.25 0 00-.25-.25H16a.75.75 0 010-1.5h1a1.252 1.252 0 011.25 1.25V20a.25.25 0 00.25.25h.5a.75.75 0 010 1.5zM7 10a5 5 0 10-5-5 5.006 5.006 0 005 5zM4.1 4.258a.5.5 0 01.741-.3A6.959 6.959 0 008.46 5a6.36 6.36 0 00.929-.07.5.5 0 01.425.139.5.5 0 01.144.425A3 3 0 014 5a2.828 2.828 0 01.1-.742zM11.443 12.021a.5.5 0 00-.225-.343 7.475 7.475 0 00-11.2 4.179.5.5 0 00.482.643h8.64a.5.5 0 00.492-.411 7.96 7.96 0 011.7-3.67.5.5 0 00.111-.398z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});