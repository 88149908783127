define("ember-svg-jar/inlined/security-unlock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>security-unlock</title><path d=\"M22 12.126a2.5 2.5 0 00-2.5-2.5H19a.25.25 0 01-.25-.25V6.625a6.75 6.75 0 00-13.5 0 1.25 1.25 0 002.5 0 4.25 4.25 0 018.5 0v2.751a.25.25 0 01-.25.25H4.5a2.5 2.5 0 00-2.5 2.5v1.5a2.5 2.5 0 002.5 2.5h15a2.5 2.5 0 002.5-2.5zm-16.5 1.5a1 1 0 111-1 1 1 0 01-1 .999zm4 0a1 1 0 111-1 1 1 0 01-1 .999zM19.5 17.625h-15a2.5 2.5 0 00-2.5 2.5v1.5a2.5 2.5 0 002.5 2.5h15a2.5 2.5 0 002.5-2.5v-1.5a2.5 2.5 0 00-2.5-2.5zm-14 4a1 1 0 111-1 1 1 0 01-1 1zm4 0a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});