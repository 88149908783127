define("ember-svg-jar/inlined/ee-icon-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>picture-landscape</title><g fill=\"none\" fill-rule=\"evenodd\"><rect fill=\"#7470F7\" width=\"54\" height=\"54\" rx=\"5\"/><path d=\"M9 18a4.5 4.5 0 004.5 4.5h6.75a6.75 6.75 0 10-6.214-9.349.567.567 0 01-.536.349 4.376 4.376 0 00-2.552.787A4.5 4.5 0 009 18z\" fill=\"#FFF\"/><path d=\"M39.337 31.167a4.275 4.275 0 00-7.533-.248l-4.45 7.63a.56.56 0 01-.9.097 11.475 11.475 0 00-7.844-4.196c-5.324 0-9.115 9.096-9.529 10.125a1.125 1.125 0 001.044 1.55H45a1.125 1.125 0 001.006-1.629l-6.67-13.329z\" fill=\"#403CC6\"/></g>",
    "attrs": {
      "width": "54",
      "height": "54",
      "viewBox": "0 0 54 54",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});