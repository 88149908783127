define("ember-svg-jar/inlined/medical-instrument-walking-aid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-instrument-walking-aid</title><path d=\"M8.355 21.025A1.5 1.5 0 006.932 20H5.374a1.5 1.5 0 00-1.423 1.025L3.4 22.683A1 1 0 004.347 24H7.96a1 1 0 00.948-1.316zM19.489 19.4a.251.251 0 01-.113-.173l-.471-3.047a2.276 2.276 0 00-2.255-1.93H8.979a.5.5 0 01-.495-.566l1.023-7.679A3.75 3.75 0 0112.4 2.857a.5.5 0 00.323-.239.5.5 0 00.044-.4 2.37 2.37 0 01-.05-1.251.5.5 0 00-.575-.6 6.258 6.258 0 00-5.11 5.312L5.291 18.717a.25.25 0 00.248.283h1.393a2.44 2.44 0 01.333.023.5.5 0 00.564-.429l.188-1.41a.5.5 0 01.5-.434h7.522a.5.5 0 01.5.424l.313 2.033a.249.249 0 01-.105.244 2.5 2.5 0 102.751-.049zM13.653 1.5a1.5 1.5 0 001.5 1.5h4a1.5 1.5 0 000-3h-4a1.5 1.5 0 00-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});