define("ember-svg-jar/inlined/video-player-device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-player-device</title><path d=\"M19.934 14.5a.249.249 0 00.214-.121 9.5 9.5 0 10-16.3 0 .249.249 0 00.214.121zM12 11.5a2 2 0 112-2 2 2 0 01-2 2zM21.5 16h-19A2.5 2.5 0 000 18.5v2a2.5 2.5 0 002.294 2.492.251.251 0 01.225.2 1 1 0 001.961.008.249.249 0 01.245-.2h14.55a.25.25 0 01.245.2 1 1 0 001.961-.007.251.251 0 01.225-.2A2.5 2.5 0 0024 20.5v-2a2.5 2.5 0 00-2.5-2.5zM4 21a.5.5 0 01-.5-.5v-2A.5.5 0 014 18h10a.5.5 0 01.5.5v2a.5.5 0 01-.5.5zm16.5-3a1 1 0 11-1 1 1 1 0 011-1zm-2 1a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});