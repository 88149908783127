define("ember-svg-jar/inlined/real-estate-app-house-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-app-house-laptop</title><path d=\"M24 20.5a.5.5 0 00-.5-.5h-.25a.5.5 0 01-.5-.5v-1.75a1 1 0 00-2 0v1.75a.5.5 0 01-.5.5H3.75a.5.5 0 01-.5-.5V9.25a.5.5 0 01.5-.5h1a1 1 0 000-2h-1a2.5 2.5 0 00-2.5 2.5V19.5a.5.5 0 01-.5.5H.5a.5.5 0 00-.5.5A3.5 3.5 0 003.5 24h17a3.5 3.5 0 003.5-3.5z\"/><path d=\"M10.75 9.25a.5.5 0 01.5.5v2.5a1 1 0 001 1h1a1 1 0 001-1v-2a1 1 0 012 0v2a1 1 0 001 1h1a1 1 0 001-1v-2.5a.5.5 0 01.5-.5h1a.5.5 0 00.336-.87l-5.163-4.694a1 1 0 00-1.345 0L9.414 8.38a.5.5 0 00.336.87z\"/><path d=\"M8.785 17.905l2.668-1.1a.5.5 0 01.385 0 8.745 8.745 0 10-4.564-4.457.5.5 0 010 .417l-1.129 2.42a2.016 2.016 0 00.4 2.269 2.053 2.053 0 002.24.451zm.572-5.86A6.751 6.751 0 1115.25 15.5a6.675 6.675 0 01-3.107-.757 1.013 1.013 0 00-.841-.038L8.54 15.8a.25.25 0 01-.319-.338l1.17-2.506a1 1 0 00-.034-.911z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});