define("ember-svg-jar/inlined/style-three-pin-binoculars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-binoculars</title><path d=\"M7.5 10.5a2 2 0 00-2 2 .5.5 0 001 0 1 1 0 011-1 .5.5 0 000-1z\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM7.5 15.5a3 3 0 113-3 3 3 0 01-3 3zm5.665-5.477A.25.25 0 0113 9.788V8.479a.5.5 0 00-.4-.489 2.981 2.981 0 00-1.2 0 .5.5 0 00-.4.489v1.309a.25.25 0 01-.443.159 3.942 3.942 0 00-5.057-.9.25.25 0 01-.36-.3L6.6 4.961a2.278 2.278 0 014.4.817v.249a.5.5 0 00.556.5 3.955 3.955 0 01.888 0 .5.5 0 00.556-.5v-.249a2.279 2.279 0 014.405-.818l1.455 3.783a.25.25 0 01-.36.3 3.942 3.942 0 00-5.057.9.251.251 0 01-.278.08zM16.5 15.5a3 3 0 113-3 3 3 0 01-3 3z\"/><path d=\"M17 10.5a2 2 0 00-2 2 .5.5 0 001 0 1 1 0 011-1 .5.5 0 000-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});