define("ember-svg-jar/inlined/shopping-cart-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-cart-man</title><path d=\"M4.055 20.342a1.421 1.421 0 00-1.268.792 1.4 1.4 0 00-.155.631 1.421 1.421 0 101.423-1.423zM7.748 20.342a1.428 1.428 0 101.3.846 1.425 1.425 0 00-1.3-.846zM1.286 13.188a.5.5 0 00-.474.66l1.847 5.5a.5.5 0 00.473.34h6.231a.5.5 0 00.481-.365 71.22 71.22 0 012.193-7.266l.351-1.052a1 1 0 10-1.9-.633l-.439 1.316-.443 1.33a.25.25 0 01-.237.17zM20.733 8.653a.251.251 0 01-.011-.432 4.08 4.08 0 10-4.373-.077.25.25 0 01-.026.432 4.761 4.761 0 00-2.657 4.263v2.851a.5.5 0 00.146.353.5.5 0 00.354.147h1.459a.25.25 0 01.249.225l.644 6.448a.5.5 0 00.5.45h2.85a.5.5 0 00.5-.45l.644-6.448a.25.25 0 01.249-.225h1.459a.5.5 0 00.354-.147.5.5 0 00.146-.353v-2.851a4.776 4.776 0 00-2.487-4.186zM19.19 12.03a.75.75 0 01-1.5 0v-1.692a.75.75 0 111.5 0zm-2.581-7.865a.251.251 0 01.369-.139 5.076 5.076 0 002.638.737 5.149 5.149 0 00.749-.055.251.251 0 01.286.268 2.073 2.073 0 01-4.135-.213 2 2 0 01.093-.598z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});