define("ember-svg-jar/inlined/earth-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earth-star</title><path d=\"M10.862 22.417a.5.5 0 00-.419-.548 9.967 9.967 0 01-2.8-.881.25.25 0 01-.141-.225v-1.15a2.492 2.492 0 01.891-1.913 4.484 4.484 0 001.118-1.434 2.556 2.556 0 01.435-1.523A4.423 4.423 0 0010 14.25a4.505 4.505 0 00-4.5-4.5H2.584a.25.25 0 01-.241-.314 9.982 9.982 0 0114.546-6.154.25.25 0 01-.122.468H14.25a2.75 2.75 0 000 5.5 2.581 2.581 0 011.511.5.5.5 0 00.518.042A2.7 2.7 0 0117.5 9.5a2.778 2.778 0 012.491 1.526l.045.1.895 2.075a.5.5 0 00.459.3h1.35a2.77 2.77 0 01.593.064.5.5 0 00.6-.436A10.76 10.76 0 0024 12 12 12 0 107.341 23.059l.159.078v-.02a11.894 11.894 0 002.846.757.5.5 0 00.558-.6 2.754 2.754 0 01-.042-.857z\"/><path d=\"M22.74 15H20.4a.5.5 0 01-.459-.3l-1.279-2.966-.013-.027a1.293 1.293 0 00-2.317.027L15.055 14.7a.5.5 0 01-.459.3h-2.338a1.231 1.231 0 00-1.166.759 1.272 1.272 0 00.32 1.4l2.059 1.834a.5.5 0 01.126.573l-1.151 2.644a1.255 1.255 0 00.314 1.452 1.278 1.278 0 001.506.166l2.989-1.684a.5.5 0 01.491 0l2.99 1.684a1.277 1.277 0 001.506-.166 1.255 1.255 0 00.315-1.451l-1.151-2.649a.5.5 0 01.125-.573l2.078-1.851a1.264 1.264 0 00.3-1.378A1.234 1.234 0 0022.74 15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});