define("ember-svg-jar/inlined/transportation-ticket-plane-transfer-bus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transportation-ticket-plane-transfer-bus</title><path d=\"M23.68 16.625a1.871 1.871 0 00-1.349-1.211 1.785 1.785 0 00-1.41.269l-2 1.365a.251.251 0 01-.229.027l-4.65-1.731a.829.829 0 00-.784.112l-.889.658-.01.007a1.47 1.47 0 00-.126.12.841.841 0 00.137 1.2.343.343 0 00.033.024l2.653 1.643a.25.25 0 01.011.418l-.587.409a.249.249 0 01-.271.01l-1.1-.655a.51.51 0 00-.092-.042.777.777 0 00-.747.138l-.008.007-1.171 1.037-.01.008a1.254 1.254 0 00-.127.125.774.774 0 00.135 1.091l.016.012 2.87 2.015a.986.986 0 00.565.179.973.973 0 00.519-.149l7.854-4.876a1.865 1.865 0 00.87-1.6 1.8 1.8 0 00-.103-.61zM12.093 8.982a4.978 4.978 0 012.6 1.307.253.253 0 01.077.19c0 .071-.446.138-.5.184a.736.736 0 00-.264.485.748.748 0 00.38.741l2.266 1.031a.744.744 0 00.364.1.8.8 0 00.206-.028.755.755 0 00.45-.358l1.028-1.859a.75.75 0 00-.86-1.086l-.676.193a.25.25 0 01-.271-.093 6.968 6.968 0 00-4.463-2.778 1 1 0 00-.337 1.971zM9.435 18.573a5.007 5.007 0 01-2.146-1.626.251.251 0 01.11-.387l.2-.073a.751.751 0 00.04-1.387l-1.933-.874a.749.749 0 00-.993.375l-.873 1.935a.749.749 0 00.683 1.058.739.739 0 00.265-.048l.194-.073a.25.25 0 01.3.1 7.068 7.068 0 003.428 2.862 1 1 0 00.724-1.865zM6.215 9.89v.245a.75.75 0 101.5 0v-.52a.25.25 0 01.124-.215 1.744 1.744 0 00.876-1.51v-6A1.752 1.752 0 006.965.14h-5a1.752 1.752 0 00-1.75 1.75v6A1.743 1.743 0 001.09 9.4a.252.252 0 01.125.217v.52a.75.75 0 101.5 0V9.89a.249.249 0 01.25-.25h3a.25.25 0 01.25.25zm-4.5-8a.248.248 0 01.25-.25h5a.248.248 0 01.25.25v2.75a.25.25 0 01-.25.25h-5a.25.25 0 01-.25-.25zm.75 5.745a.75.75 0 11.75-.75.749.749 0 01-.75.75zm3.25-.75a.75.75 0 11.75.75.75.75 0 01-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});