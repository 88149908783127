define("ember-svg-jar/inlined/human-resources-employee-previous-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-employee-previous-1</title><path d=\"M23.992 11.609A10.71 10.71 0 0012.89 1.294a10.84 10.84 0 00-9.732 7.16.252.252 0 01-.254.164L.538 8.436a.5.5 0 00-.454.776l3.068 4.6a.5.5 0 00.384.222h.033a.5.5 0 00.375-.171l3.58-4.091a.5.5 0 00-.338-.828l-1.665-.125a.248.248 0 01-.2-.122.251.251 0 01-.012-.232 8.808 8.808 0 017.651-5.172 8.716 8.716 0 01.641 17.42 1 1 0 00.036 2h.038a10.707 10.707 0 0010.317-11.104z\"/><path d=\"M18.96 17.286a.5.5 0 00.185-.965l-3.358-1.333a.5.5 0 01-.324-.467v-.931a.482.482 0 01.109-.31 5.907 5.907 0 001.388-3.568c0-2.446-1.324-3.979-3.455-4A3.361 3.361 0 0010.6 7.4c-1.566 2.709.183 5.186.757 5.88a.476.476 0 01.105.307v.93a.505.505 0 01-.331.471l-3.349 1.33a.5.5 0 00.184.965z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});