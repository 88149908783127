define("ember-svg-jar/inlined/armchair-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>armchair-2</title><path d=\"M20.762 14.182a.252.252 0 00-.232.049.25.25 0 00-.087.22A8.864 8.864 0 0120.5 15.5a10.094 10.094 0 01-.442 2.937 1.428 1.428 0 00-.06.5v1.064a1 1 0 001 1h.5A2.5 2.5 0 0024 18.5a4.512 4.512 0 00-3.238-4.318zM8.42 7.74l-1.739.675A.5.5 0 016 7.949V5a5 5 0 00-5-5 1 1 0 00-1 1v2a1 1 0 001 1 1 1 0 011 1v6a7.008 7.008 0 007 7h5.533a.5.5 0 00.447-.276 5 5 0 00.394-3.335.5.5 0 00-.488-.389H9a3.009 3.009 0 01-3-3 .25.25 0 01.158-.232L9.14 9.6a5.516 5.516 0 015.48.87 6.5 6.5 0 01-1.04 10.75 6.431 6.431 0 01-3.08.78H1a1 1 0 000 2h9.5a8.4 8.4 0 004.33-1.19A8.5 8.5 0 0019 15.5c0-5.548-5.32-9.807-10.58-7.76z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});