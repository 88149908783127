define("ember-svg-jar/inlined/kangaroo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kangaroo</title><path d=\"M22.625 21.25c-2.015 0-5.155-3.445-6.252-6.125a5.027 5.027 0 00-3.186-4.771C10.675 9.243 7.875 6.36 7.875 4c0-1.864-.341-4-3-4a.51.51 0 00-.5.518v1a11.117 11.117 0 00-3.4.883 1.006 1.006 0 00-.6.918v.93a1.008 1.008 0 00.4.8 10.175 10.175 0 004.1 1.912q0 .548.006 1.072a4.688 4.688 0 00-3.338 1.912 1 1 0 001.664 1.11 2.62 2.62 0 011.755-1.009A10.861 10.861 0 005.4 12.5H2.875a.507.507 0 00-.372.165.5.5 0 00-.125.388c.6 5.712 5.847 6.739 8.155 6.913a.5.5 0 01.441.383l.27 1.151H8.5c-1.46 0-3.113 0-3.125 1.979a.509.509 0 00.5.521h7a1.035 1.035 0 00.724-1.23l-.608-2.58a.5.5 0 01.311-.577 5.032 5.032 0 002.215-1.813c1.712 2.77 4.58 5.452 7.108 5.452a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});