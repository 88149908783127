define("ember-svg-jar/inlined/buildings-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>buildings-2</title><path d=\"M10.75 2.5h2.5a.5.5 0 00.5-.5V1a1 1 0 00-1-1h-1.5a1 1 0 00-1 1v1a.5.5 0 00.5.5zM23 22a.25.25 0 01-.25-.25v-8a1 1 0 00-1-1h-1.5V12a.75.75 0 00-1.5 0v.75a1 1 0 00-1 1v7.75a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5V5a1 1 0 00-1-1h-6.5a1 1 0 00-1 1v16.5a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-7.75a1 1 0 00-1-1V12a.75.75 0 00-1.5 0v.75h-1.5a1 1 0 00-1 1v8A.25.25 0 011 22a1 1 0 000 2h22a1 1 0 000-2zM10 6.5h4A.75.75 0 0114 8h-4a.75.75 0 010-1.5zm0 4h4a.75.75 0 010 1.5h-4a.75.75 0 010-1.5zm0 4h4a.75.75 0 010 1.5h-4a.75.75 0 010-1.5zm4.25 5.5h-4.5a.75.75 0 010-1.5h4.5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});