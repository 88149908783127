define("ember-svg-jar/inlined/computer-bug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>computer-bug</title><path d=\"M7.613 8.5h8.812a1.218 1.218 0 001.191-1.481A5.7 5.7 0 0016.053 4.2a.5.5 0 01-.027-.68A3.386 3.386 0 0017 1.359a3.2 3.2 0 000-.326C16.914.109 16.287 0 16.007 0s-.907.105-.992 1.03l-.007.092v.118c0 .423-.262.726-.76 1.268a2.886 2.886 0 00-.073.083.5.5 0 01-.525.151 5.569 5.569 0 00-3.29 0 .5.5 0 01-.529-.142 2.492 2.492 0 00-.068-.077c-.5-.544-.757-.847-.759-1.271v-.215h-.01C8.908.111 8.282.006 8 .006s-.9.105-.99 1.03a1.659 1.659 0 000 .323 3.462 3.462 0 00.966 2.167.5.5 0 01-.027.681A5.691 5.691 0 006.4 6.968 1.25 1.25 0 007.613 8.5zM2.5 13.843a1 1 0 00.707-.293l.239-.241c.778-.786 1.45-1.466 2.3-1.466a.5.5 0 01.5.5v1.281a.5.5 0 01-.441.5 5.681 5.681 0 00-3.286 2.035l-.23.233A1 1 0 103.707 17.8l.239-.241A5.312 5.312 0 015.685 16.2a.422.422 0 01.565.4 4.018 4.018 0 00.291 1.4.5.5 0 01-.327.657 6.523 6.523 0 00-2.691 1.905l-.23.232a1 1 0 101.414 1.414l.239-.241c.778-.786 1.45-1.466 2.3-1.466h.518a.5.5 0 01.394.191 25.871 25.871 0 002.646 2.834 1.753 1.753 0 002.383 0 25.877 25.877 0 002.652-2.826.5.5 0 01.393-.191h.518c.854 0 1.526.68 2.3 1.466l.239.241a1 1 0 001.418-1.416l-.23-.232a6.526 6.526 0 00-2.69-1.905.5.5 0 01-.328-.663 4.013 4.013 0 00.291-1.41.422.422 0 01.565-.4 5.312 5.312 0 011.739 1.365l.239.241a1 1 0 001.414-1.414l-.23-.233a5.681 5.681 0 00-3.286-2.035.5.5 0 01-.441-.5v-1.271a.5.5 0 01.5-.5c.854 0 1.526.68 2.3 1.466l.239.241a1 1 0 001.414-1.414l-.23-.233c-.956-.965-2.038-2.06-3.727-2.06H5.75c-1.689 0-2.771 1.1-3.727 2.06l-.23.233a1 1 0 00.707 1.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});