define("ember-svg-jar/inlined/chicken-footstep", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chicken-footstep</title><path d=\"M22.691 3.317a1.5 1.5 0 00-2.045.567l-.205.363c-5.369 1.772-6.714-1.511-6.941-1.93V2a1.5 1.5 0 00-3 0v.32c-.251.466-1.583 3.691-6.941 1.925l-.205-.361a1.5 1.5 0 10-2.61 1.478L10 22s.812 1.5 2 1.5 2-1.5 2-1.5l9.256-16.638a1.5 1.5 0 00-.565-2.045zM10.5 15.55a.251.251 0 01-.468.124L5.157 7.067A.25.25 0 015.4 6.7a9.239 9.239 0 00.973.053 6.826 6.826 0 003.742-1.031.25.25 0 01.384.211zm3.468.124a.251.251 0 01-.468-.124V5.927a.251.251 0 01.385-.211 6.821 6.821 0 003.743 1.032A8.928 8.928 0 0018.6 6.7a.252.252 0 01.235.112.248.248 0 01.009.26z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});