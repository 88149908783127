define("ember-svg-jar/inlined/phone-actions-voice-mail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-voice-mail</title><path d=\"M23.132 17.973l-4.245-4.245a4 4 0 00-5.655 0l-.983.983a5.952 5.952 0 00-1.911 3.718 4.811 4.811 0 00.069 1.278 13.145 13.145 0 01-6.122-6.128 5.493 5.493 0 005.006-1.826l.981-.981a4 4 0 000-5.657L6.027.868a2.96 2.96 0 00-4.836.979 16.062 16.062 0 003.522 17.44A16.308 16.308 0 0016.075 24a16.176 16.176 0 006.077-1.191 2.96 2.96 0 00.98-4.836z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});