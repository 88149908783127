define("ember-svg-jar/inlined/dat-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dat-1</title><path d=\"M12.5 12.126a1.377 1.377 0 00-1.375 1.374v1.125a.25.25 0 00.25.25h2.25a.25.25 0 00.25-.25V13.5a1.377 1.377 0 00-1.375-1.374zM5.125 12.5v6a.251.251 0 00.312.245 3.375 3.375 0 000-6.485.251.251 0 00-.312.24z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-18 20.126a.625.625 0 01-.625-.625v-8a.625.625 0 01.625-.625 4.625 4.625 0 010 9.25zm10.625-.626a.625.625 0 11-1.25 0v-3.124a.25.25 0 00-.25-.25h-2.25a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0v-5.9a2.7 2.7 0 012.1-2.678 2.629 2.629 0 013.15 2.578zm5.375-7.374h-1.125a.25.25 0 00-.25.25V19.5a.625.625 0 11-1.25 0v-7.124a.25.25 0 00-.25-.25H16.5a.625.625 0 010-1.25h4a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});