define("ember-svg-jar/inlined/phone-action-navigation-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-navigation-pin</title><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/><path d=\"M12 6.55a3.5 3.5 0 00-3.5 3.5c0 1.319 1.475 3.483 2.712 5.066A.992.992 0 0012 15.5a1.006 1.006 0 00.787-.384c1.237-1.582 2.713-3.745 2.713-5.066a3.5 3.5 0 00-3.5-3.5zm0 2.5a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});