define("ember-svg-jar/inlined/global-warming-globe-fire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>global-warming-globe-fire</title><path d=\"M23.7 7.042a1 1 0 00-.725-.72.982.982 0 00-.981.3.251.251 0 01-.417-.158A9.7 9.7 0 0018.006.232a1 1 0 00-1.641.782 1.986 1.986 0 01-.8 1.621 10.406 10.406 0 00-1.479 1.326.5.5 0 01-.742-.007A3.5 3.5 0 0011.907 3a1 1 0 00-1.337 1.024 2.654 2.654 0 01-.278 1.19c-.02.053-.039.1-.057.157a.5.5 0 00.354.655 10.017 10.017 0 017.66 9.475.5.5 0 00.67.458 7.758 7.758 0 004.093-3.026 7.564 7.564 0 00.688-5.891z\"/><path d=\"M8.25 7.5a8.25 8.25 0 108.25 8.25A8.259 8.259 0 008.25 7.5zm-1.623 4.773a10.027 10.027 0 011.43-2.3.25.25 0 01.386 0 9.98 9.98 0 011.43 2.3.251.251 0 01-.228.352h-2.79a.25.25 0 01-.209-.114.246.246 0 01-.019-.238zm-3.576.011a6.3 6.3 0 012.057-1.935.25.25 0 01.345.336 11.036 11.036 0 00-.726 1.608.5.5 0 01-.471.332H3.233a.219.219 0 01-.182-.341zM2.073 16.7a6.244 6.244 0 010-1.9.5.5 0 01.494-.423h1.094a.5.5 0 01.5.541 10.32 10.32 0 000 1.667.5.5 0 01-.5.542H2.567a.5.5 0 01-.494-.427zm3.336 4.412a.25.25 0 01-.3.037 6.31 6.31 0 01-2.023-1.885.25.25 0 01.207-.391h.964a.5.5 0 01.471.332 11.071 11.071 0 00.726 1.607.251.251 0 01-.045.302zm4.464-1.888a9.968 9.968 0 01-1.43 2.3.25.25 0 01-.386 0 9.968 9.968 0 01-1.43-2.3.25.25 0 01.228-.351h2.79a.248.248 0 01.209.114.244.244 0 01.019.239zm.7-2.544a.5.5 0 01-.5.443H6.425a.5.5 0 01-.5-.443 8.221 8.221 0 010-1.864.5.5 0 01.5-.443h3.65a.5.5 0 01.5.443 8.221 8.221 0 010 1.864zm.519-6.3a.25.25 0 01.3-.037 6.3 6.3 0 012.023 1.885.25.25 0 01-.207.391h-.964a.5.5 0 01-.471-.332 11.036 11.036 0 00-.726-1.608.25.25 0 01.044-.293zm2.358 8.829a6.3 6.3 0 01-2.057 1.936.25.25 0 01-.345-.337 11.071 11.071 0 00.726-1.607.5.5 0 01.471-.332h1.023a.219.219 0 01.182.34zm.978-4.417a6.244 6.244 0 010 1.9.5.5 0 01-.494.423h-1.095a.5.5 0 01-.5-.542 10.295 10.295 0 000-1.667.5.5 0 01.5-.541h1.094a.5.5 0 01.494.435z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});