define("ember-svg-jar/inlined/famous-character-cat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character-cat</title><ellipse cx=\"12\" cy=\"17.269\" rx=\"1.5\" ry=\"1\"/><path d=\"M9 13.269a.75.75 0 00.75-.75v-1.5a.75.75 0 10-1.5 0v1.5a.75.75 0 00.75.75zM15 13.269a.75.75 0 01-.75-.75v-1.5a.75.75 0 111.5 0v1.5a.75.75 0 01-.75.75z\"/><path d=\"M12 23.769c7.056 0 9.588-6.677 9.736-7.657a8.37 8.37 0 011.619-.965.995.995 0 00.371-1.616 4.812 4.812 0 00-.815-.753c-.186-.129.015-.362.114-.5a1 1 0 00.158-.818.988.988 0 00-.5-.643 11.954 11.954 0 01-1.965-1.289 4.963 4.963 0 01-1.766-2.354l-.375-6a1 1 0 00-1.743-.617l-3.48 3.83a.241.241 0 01-.212.076 8.686 8.686 0 00-2.284 0 .241.241 0 01-.212-.076L7.166.557a1 1 0 00-1.739.611l-.375 6a4.963 4.963 0 01-1.766 2.357 11.954 11.954 0 01-1.965 1.289.988.988 0 00-.5.643 1 1 0 00.158.818c.1.141.3.374.114.5a4.812 4.812 0 00-.815.753.995.995 0 00.371 1.616 8.37 8.37 0 011.619.965c.144.983 2.676 7.66 9.732 7.66zm3.114-15.5c1.219 0 2.25 1.489 2.25 3.25s-1.031 3.25-2.25 3.25-2.25-1.488-2.25-3.25 1.03-3.25 2.25-3.25zm-6.228 0c1.22 0 2.25 1.489 2.25 3.25s-1.03 3.25-2.25 3.25-2.25-1.488-2.25-3.25 1.031-3.25 2.25-3.25zM20.2 16.213c-.4 1.222-2.8 6.056-8.2 6.056s-7.795-4.834-8.2-6.056a1.555 1.555 0 01-.043-.389 1.758 1.758 0 01.87-1.324 1.581 1.581 0 011.051-.221.712.712 0 01.272.166 3.527 3.527 0 002.936 1.822 3.345 3.345 0 002.8-1.757.373.373 0 01.628 0 3.345 3.345 0 002.8 1.757 3.527 3.527 0 002.936-1.822.712.712 0 01.272-.166 1.581 1.581 0 011.051.221 1.758 1.758 0 01.866 1.322 1.555 1.555 0 01-.039.391z\"/><path d=\"M17.24 17.744a.753.753 0 00-1.06-.06 6.284 6.284 0 01-8.36 0 .751.751 0 10-1 1.12 7.783 7.783 0 0010.36 0 .752.752 0 00.06-1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});