define("ember-svg-jar/inlined/renewable-energy-battery-leaf-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>renewable-energy-battery-leaf-1</title><path d=\"M4.5 6h15a.5.5 0 00.5-.5A2.5 2.5 0 0017.5 3h-1a.5.5 0 01-.5-.5v-.75A1.752 1.752 0 0014.25 0h-4.5A1.752 1.752 0 008 1.75v.75a.5.5 0 01-.5.5h-1A2.5 2.5 0 004 5.5a.5.5 0 00.5.5zM19.5 7h-15a.5.5 0 00-.5.5v14A2.5 2.5 0 006.5 24h11a2.5 2.5 0 002.5-2.5v-14a.5.5 0 00-.5-.5zm-4.393 10.34a3.614 3.614 0 01-3.127 1.272 2.337 2.337 0 01-.8-.324.56.56 0 01-.184-.752 7.714 7.714 0 012.847-3.107.559.559 0 00-.494-1c-2.245 1.095-4.029 4.455-4.8 6.819a.56.56 0 01-1.069-.338 19.247 19.247 0 011.574-3.51.562.562 0 00-.044-.625 2.432 2.432 0 01-.562-1.542A3.061 3.061 0 0110 11.793a7.774 7.774 0 012.532-.98 7.264 7.264 0 002.852-1.2.567.567 0 01.474-.093.558.558 0 01.369.312c1.03 2.282.233 6.101-1.12 7.508z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});