define("ember-svg-jar/inlined/deer-antlers-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>deer-antlers-1</title><path d=\"M8 14.548c-2.5-2.5-2.5-3.5-2.5-9.5v-2.5a1 1 0 00-2 0v3.107l-1.085-1.1A1 1 0 00.991 5.962L3.5 8.5c.009.776.034 1.458.092 2.068l-1.279-.545A1 1 0 001 10.548a.989.989 0 00.517 1.308l2.631 1.121a7.146 7.146 0 001.208 1.873L3.6 14.661a1 1 0 00-.226 1.988l3.866.418a7 7 0 011.26 3.481V22.2a.25.25 0 00.339.234c.571-.219 1.661-.781 1.661-1.888A8.215 8.215 0 008 14.548zM16 14.548c2.5-2.5 2.5-3.5 2.5-9.5v-2.5a1 1 0 012 0v3.107l1.085-1.1a1 1 0 011.423 1.406L20.5 8.5a25.279 25.279 0 01-.092 2.068l1.28-.545a1 1 0 01.794 1.837l-2.631 1.121a7.141 7.141 0 01-1.207 1.873l1.753-.189a1 1 0 01.226 1.988l-3.866.418a7 7 0 00-1.257 3.477V22.2a.25.25 0 01-.339.234c-.571-.219-1.661-.781-1.661-1.888a8.215 8.215 0 012.5-5.998z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});