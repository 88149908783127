define("ember-svg-jar/inlined/animal-print", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>animal-print</title><path d=\"M19.189 16.771a3.4 3.4 0 01-2.006-1.612c-.278-.555-.5-1.088-.7-1.583C15.691 11.657 15.009 10 12 10s-3.691 1.657-4.482 3.576c-.2.495-.423 1.028-.7 1.583a3.4 3.4 0 01-2.006 1.612A3.056 3.056 0 002.5 20c0 3.61 2.447 4 3.5 4a4.112 4.112 0 002.947-1.265A4.087 4.087 0 0112 21.5a4.087 4.087 0 013.053 1.235A4.112 4.112 0 0018 24c1.053 0 3.5-.39 3.5-4a3.056 3.056 0 00-2.311-3.229zM5 11V9a2.5 2.5 0 00-5 0v2a2.5 2.5 0 005 0zM8.5 7A2.5 2.5 0 0011 4.5v-2a2.5 2.5 0 00-5 0v2A2.5 2.5 0 008.5 7zM21.5 6.5A2.5 2.5 0 0019 9v2a2.5 2.5 0 005 0V9a2.5 2.5 0 00-2.5-2.5zM15.5 7A2.5 2.5 0 0018 4.5v-2a2.5 2.5 0 00-5 0v2A2.5 2.5 0 0015.5 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});