define("ember-svg-jar/inlined/camera-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camera-2</title><path d=\"M22.061 6.682l-3.919-1.161A.488.488 0 0018 5.5h-7a.489.489 0 00-.132.018L6.622 6.676a.25.25 0 00-.179.294 2.5 2.5 0 01.057.53v12c0 .077 0 .153-.01.227a.249.249 0 00.249.273h2.382a.244.244 0 01.163.061 7.985 7.985 0 0010.432 0 .249.249 0 01.163-.061H21.5a2.5 2.5 0 002.5-2.5V9.117a2.5 2.5 0 00-1.939-2.435zM8.5 14a6 6 0 116 6 6.006 6.006 0 01-6-6zM10.605 3.813a.5.5 0 00.481.638h6.828a.5.5 0 00.481-.638A2.511 2.511 0 0015.991 2h-2.982a2.511 2.511 0 00-2.404 1.813z\"/><circle cx=\"14.5\" cy=\"14\" r=\"4\"/><rect y=\"6\" width=\"5.5\" height=\"15\" rx=\"1.5\" ry=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});