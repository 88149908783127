define("ember-svg-jar/inlined/messages-bubble-square-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-download</title><path d=\"M17.5 9.5a7.932 7.932 0 013.636.883.25.25 0 00.364-.222V1.5A1.5 1.5 0 0020 0H1.5A1.5 1.5 0 000 1.5v12.053A1.459 1.459 0 001.5 15H3v3.5a.5.5 0 00.832.374L8.19 15h1.537a.252.252 0 00.236-.166A8.008 8.008 0 0117.5 9.5z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-2.45 6.783a.5.5 0 01.45-.283h1a.25.25 0 00.25-.25V14.5a.75.75 0 011.5 0v2.75a.25.25 0 00.25.25h1a.5.5 0 01.391.813l-2 2.5a.5.5 0 01-.782 0l-2-2.5a.5.5 0 01-.059-.53z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});