define("ember-svg-jar/inlined/laptop-lock-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-lock-1</title><path d=\"M23.985 16.872a.5.5 0 00-.485-.621h-8a.5.5 0 00-.5.5c0 .71-1.232 1.5-3 1.5s-3-.79-3-1.5a.5.5 0 00-.5-.5h-8a.5.5 0 00-.485.621 5.772 5.772 0 005.608 4.379h12.754a5.773 5.773 0 005.608-4.379zM11.968 5.5a2.753 2.753 0 00-2.75 2.75v.572a.251.251 0 01-.107.205 1.49 1.49 0 00-.643 1.223v3a1.5 1.5 0 001.5 1.5h4a1.5 1.5 0 001.5-1.5v-3a1.49 1.49 0 00-.643-1.223.251.251 0 01-.107-.205v-.571a2.752 2.752 0 00-2.75-2.751zm1 6.269a1 1 0 11-1-1 1 1 0 011 1.001zm-2.25-3.519a1.25 1.25 0 012.5 0 .25.25 0 01-.25.25h-2a.25.25 0 01-.25-.249z\"/><path d=\"M3.468 14.751a1 1 0 001-1v-8a1 1 0 011-1h13a1 1 0 011 1v8a1 1 0 102 0v-8a3 3 0 00-3-3h-13a3 3 0 00-3 3v8a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});