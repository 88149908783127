define("ember-svg-jar/inlined/file-doc-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-doc-1</title><path d=\"M4.625 12.394v6.165c0 .251.236.2.351.17a3.375 3.375 0 00-.118-6.495.179.179 0 00-.233.16z\"/><path d=\"M22 0H2A1.5 1.5 0 00.5 1.5v21A1.5 1.5 0 002 24h20a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022 0zM4 20.123a.625.625 0 01-.625-.625v-8A.625.625 0 014 10.873a4.625 4.625 0 010 9.25zM14.625 17.5a2.625 2.625 0 01-5.25 0v-4a2.625 2.625 0 115.25 0zM20 18.873a.625.625 0 010 1.25 4.625 4.625 0 110-9.25.625.625 0 010 1.25 3.375 3.375 0 100 6.75z\"/><path d=\"M12 12.123a1.377 1.377 0 00-1.375 1.377v4a1.375 1.375 0 002.75 0v-4A1.377 1.377 0 0012 12.123z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});