define("ember-svg-jar/inlined/smart-watch-square-dumbbell-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-dumbbell-alternate</title><path d=\"M18 3h-1.078a.249.249 0 01-.24-.182L15.98.365A.5.5 0 0015.5 0h-7a.5.5 0 00-.481.363l-.7 2.455A.248.248 0 017.077 3H6a3 3 0 00-3 3v12a3 3 0 003 3h1.077a.249.249 0 01.24.181l.7 2.456A.5.5 0 008.5 24h7a.5.5 0 00.48-.363l.7-2.456a.25.25 0 01.24-.181H18a3 3 0 003-3V6a3 3 0 00-3-3zm1 15a1 1 0 01-1 1H6a1 1 0 01-1-1V6a1 1 0 011-1h12a1 1 0 011 1z\"/><path d=\"M18.25 12a.75.75 0 00-.75-.75h-.75a.25.25 0 01-.25-.25v-.5A1.5 1.5 0 0015 9h-1.5a.5.5 0 00-.5.5V11a.25.25 0 01-.25.25h-1.5A.25.25 0 0111 11V9.5a.5.5 0 00-.5-.5H9a1.5 1.5 0 00-1.5 1.5v.5a.25.25 0 01-.25.25H6.5a.75.75 0 000 1.5h.75a.25.25 0 01.25.25v.5A1.5 1.5 0 009 15h1.5a.5.5 0 00.5-.5V13a.25.25 0 01.25-.25h1.5A.25.25 0 0113 13v1.5a.5.5 0 00.5.5H15a1.5 1.5 0 001.5-1.5V13a.25.25 0 01.25-.25h.75a.75.75 0 00.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});