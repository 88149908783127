define("ember-svg-jar/inlined/radioactive-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>radioactive-circle</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 10.005A1.995 1.995 0 1110.005 12 2 2 0 0112 10.006zM7.533 12H4.021a.486.486 0 01-.363-.152.529.529 0 01-.138-.379A8.472 8.472 0 017.3 4.922a.564.564 0 01.4-.07.533.533 0 01.313.238l1.755 3.042a.487.487 0 01-.121.638 3.963 3.963 0 00-1.621 2.809.489.489 0 01-.493.421zm8.507 7.3a.533.533 0 01-.26.31 8.472 8.472 0 01-7.56 0 .565.565 0 01-.26-.31.532.532 0 01.05-.39l1.755-3.042a.487.487 0 01.613-.215 3.964 3.964 0 003.244 0 .487.487 0 01.613.215l1.755 3.042a.488.488 0 01.05.39zm4.3-7.452a.527.527 0 01-.363.152h-3.512a.489.489 0 01-.493-.424 3.963 3.963 0 00-1.619-2.806.487.487 0 01-.121-.638l1.757-3.042a.491.491 0 01.313-.238.533.533 0 01.4.07 8.472 8.472 0 013.78 6.548.557.557 0 01-.14.379z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});