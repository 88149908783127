define("ember-svg-jar/inlined/car-dashboard-lights-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-dashboard-lights-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm0 22a10 10 0 1110-10 10.012 10.012 0 01-10 10z\"/><path d=\"M11.033 10.751H6a1 1 0 100 2h5.032a1 1 0 000-2zM11.033 7.251H6a1 1 0 000 2h5.032a1 1 0 100-2zM11.033 14.251H6a1 1 0 000 2h5.032a1 1 0 000-2zM15 6.751a1.5 1.5 0 00-1.5 1.5v7a1.5 1.5 0 001.5 1.5 5 5 0 100-10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});