define("ember-svg-jar/inlined/allowances-no-skates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>allowances-no-skates</title><path d=\"M7.231 11.846a3.29 3.29 0 00-.73 2.2A1.494 1.494 0 008 15.5h3.08a.25.25 0 00.177-.427l-3.463-3.468a.25.25 0 00-.357 0 2.94 2.94 0 00-.206.241z\"/><circle cx=\"8\" cy=\"17.5\" r=\"1.5\"/><path d=\"M10.5 17.5a1.5 1.5 0 003 0 1.479 1.479 0 00-.021-.211l-1.268-1.268A1.479 1.479 0 0012 16a1.5 1.5 0 00-1.5 1.5z\"/><path d=\"M24 12A12.013 12.013 0 0012 0C8.545 0 0 2.858 0 12a12.013 12.013 0 0012 12c9.148 0 12-8.546 12-12zm-4.5 6.589l-3.1-3.1h.2a.5.5 0 00.494-.421 5.009 5.009 0 00-.705-3.14.5.5 0 01-.06-.31l.8-6.374A1.119 1.119 0 0016.029 4h-4.795a1.119 1.119 0 00-1.112 1.228l.051.545a.251.251 0 00.249.227H12.5a.5.5 0 01.5.5V7a.5.5 0 01-.5.5h-1.889a.249.249 0 00-.185.082.245.245 0 00-.064.191l.174 1.849L5.411 4.5A9.984 9.984 0 0119.5 18.589zm-1.465 1.363A9.981 9.981 0 014.048 5.962z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});