define("ember-svg-jar/inlined/pet-tracking-cat-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pet-tracking-cat-search</title><path d=\"M8.391 13.541a8.082 8.082 0 00-2.048.353.5.5 0 00-.295.681l1.045 2.294a1.052 1.052 0 00.965.631 1.145 1.145 0 00.288-.038 1.058 1.058 0 00.794-1.136l-.218-2.332a.5.5 0 00-.531-.453z\"/><path d=\"M16.249 9.25a6.977 6.977 0 011.652.2.5.5 0 00.565-.263l.206-.41a.5.5 0 01.447-.276h1.07A1.814 1.814 0 0022 6.688V6.5a.5.5 0 00-.5-.5c-.336 0-.573-.459-.923-1.214a3.651 3.651 0 00-1.847-2.17.5.5 0 01-.333-.405L18.2.743a.51.51 0 00-.032-.122A1 1 0 0017.243 0a1.015 1.015 0 00-.943.677l-.987 3.669A3.493 3.493 0 0112.077 6.5H5.39a2.467 2.467 0 00-1.047.238.5.5 0 01-.424 0A3.313 3.313 0 012 3.75 1.562 1.562 0 013.5 2a.485.485 0 01.5.5c0 .352-.449.605-.448.605a1 1 0 00.9 1.79A2.808 2.808 0 006 2.5 2.476 2.476 0 003.5 0 3.551 3.551 0 000 3.75a5.323 5.323 0 002.559 4.527.5.5 0 01.207.6C2.018 10.885.535 14.845.091 15.941a1.089 1.089 0 00.536 1.429 1.3 1.3 0 00.572.13 1.424 1.424 0 001.231-.731c.145-.243.281-.479.412-.707C4.12 13.841 4.891 12.5 9.743 12.5h.324a.5.5 0 00.423-.217 6.99 6.99 0 015.759-3.033zm2-4.75a.75.75 0 11-.75.75.75.75 0 01.752-.75z\"/><path d=\"M23.633 21.866l-2.58-2.58a.251.251 0 01-.04-.3 5.443 5.443 0 00.736-2.734 5.519 5.519 0 10-2.765 4.764.25.25 0 01.3.039l2.581 2.581a1.25 1.25 0 001.767-1.768zm-7.384-2.116a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});