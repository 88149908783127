define("ember-svg-jar/inlined/smiley-silent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-silent</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM7.25 6.5a2 2 0 11-2 2 2 2 0 012-2zm1.109 7.769a1 1 0 011.282-1.538l2.2 1.834a.251.251 0 00.32 0l2.2-1.834a1 1 0 011.282 1.538l-1.848 1.539a.25.25 0 000 .384l1.848 1.539a1 1 0 01-1.282 1.538l-2.2-1.834a.251.251 0 00-.32 0l-2.2 1.834a1 1 0 01-1.282-1.538l1.848-1.539a.25.25 0 000-.384zM16.75 10.5a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});