define("ember-svg-jar/inlined/audio-file-volume-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file-volume-alternate</title><path d=\"M21.707 5.707L16.293.293A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.465a.5.5 0 01.353.146l4.536 4.536a.5.5 0 01.146.353z\"/><path d=\"M12.007 10.138a1 1 0 00-.993-.012l-2.878 2.267a.5.5 0 01-.309.107H6.5a1 1 0 00-1 1v2a1 1 0 001 1h1.327a.5.5 0 01.309.107l2.878 2.267A1 1 0 0012.5 18v-7a1 1 0 00-.493-.862zM14.03 12.712a.75.75 0 000 1.061.75.75 0 010 1.061.75.75 0 001.061 1.066 2.251 2.251 0 000-3.183.751.751 0 00-1.061-.005z\"/><path d=\"M16.151 10.591a.75.75 0 000 1.061 3.748 3.748 0 010 5.3.75.75 0 001.061 1.061 5.25 5.25 0 000-7.425.75.75 0 00-1.061.003z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});