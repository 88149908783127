define("ember-svg-jar/inlined/drugs-sheet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drugs-sheet</title><path d=\"M6.25 18.75h2.5a2.5 2.5 0 000-5h-2.5a2.5 2.5 0 000 5zm0-3.5H7a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-.75a1 1 0 010-2zM6.25 10.25h2.5a2.5 2.5 0 000-5h-2.5a2.5 2.5 0 000 5zm0-3.5H7a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-.75a1 1 0 010-2zM15.25 10.25h2.5a2.5 2.5 0 000-5h-2.5a2.5 2.5 0 000 5zm0-3.5H16a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-.75a1 1 0 010-2zM15.25 18.75h2.5a2.5 2.5 0 000-5h-2.5a2.5 2.5 0 000 5z\"/><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2zm-2 19.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});