define("ember-svg-jar/inlined/shopping-basket-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-basket-1</title><path d=\"M22.5 12.875h-21a.5.5 0 00-.488.608l1.841 8.324a1.459 1.459 0 001.466 1.068h15.362a1.459 1.459 0 001.466-1.068V21.8l1.839-8.317a.5.5 0 00-.488-.608zm-4.75 3v4a.75.75 0 01-1.5 0v-4a.75.75 0 011.5 0zm-5 0v4a.75.75 0 01-1.5 0v-4a.75.75 0 011.5 0zm-4.5 0v4a.75.75 0 01-1.5 0v-4a.75.75 0 011.5 0zM22.5 7.875h-1.524a.251.251 0 01-.19-.087l-5.337-6.226a1.25 1.25 0 10-1.9 1.627l3.663 4.273a.25.25 0 01-.19.413H6.976a.25.25 0 01-.19-.413l3.663-4.273a1.25 1.25 0 10-1.9-1.627L3.214 7.788a.251.251 0 01-.19.087H1.5a1.5 1.5 0 00-1.5 1.5v1a1.5 1.5 0 001.5 1.5h21a1.5 1.5 0 001.5-1.5v-1a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});