define("ember-svg-jar/inlined/volume", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>volume</title><path d=\"M4.5 14.251a7.5 7.5 0 107.5-7.5 7.5 7.5 0 00-7.5 7.5zm9.917-4.917a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5z\"/><circle cx=\"12\" cy=\"3.751\" r=\"1.5\"/><circle cx=\"3\" cy=\"9.251\" r=\"1.5\"/><circle cx=\"21\" cy=\"9.251\" r=\"1.5\"/><circle cx=\"21\" cy=\"19.251\" r=\"1.5\"/><circle cx=\"3\" cy=\"19.251\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});