define("ember-svg-jar/inlined/earpods-charging-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earpods-charging-1</title><path d=\"M4.475 0A5.06 5.06 0 00.71 1.631a1.307 1.307 0 00-.335.874v4.033a1.306 1.306 0 00.336.875 5.059 5.059 0 003.764 1.63 5.237 5.237 0 00.82-.064.5.5 0 01.58.494V18.2a1.466 1.466 0 001.4 1.3h.8a1.3 1.3 0 001.3-1.3V4.521A4.727 4.727 0 004.475 0zM2.886 5.326a.8.8 0 11.8-.8.8.8 0 01-.8.8zM13.375 15.25a3.487 3.487 0 01.933-2.376.251.251 0 00.067-.17V9.473a.5.5 0 01.58-.494 5.237 5.237 0 00.82.064 5.06 5.06 0 003.765-1.631 1.305 1.305 0 00.335-.874V2.505a1.308 1.308 0 00-.336-.875A5.059 5.059 0 0015.775 0a4.727 4.727 0 00-4.9 4.521V18.2a1.3 1.3 0 001.3 1.3h.95a.25.25 0 00.25-.25zm3.989-11.533a.8.8 0 11-.8.8.8.8 0 01.8-.8z\"/><path d=\"M23.625 15.25a1.752 1.752 0 00-1.75-1.75.25.25 0 01-.25-.25v-.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75v.5a.25.25 0 01-.25.25 1.752 1.752 0 00-1.75 1.75v7a1.752 1.752 0 001.75 1.75h5a1.752 1.752 0 001.75-1.75zm-7 0a.25.25 0 01.25-.25h1a.75.75 0 00.75-.75v-.5a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v.5a.75.75 0 00.75.75h1a.25.25 0 01.25.25V18a.25.25 0 01-.25.25h-5a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});