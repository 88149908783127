define("ember-svg-jar/inlined/smiley-throw-up-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-throw-up-alternate</title><path d=\"M12 0A12 12 0 001.606 18a1 1 0 101.731-1 10 10 0 1117.326 0 1 1 0 101.731 1A12 12 0 0012 0z\"/><path d=\"M6.014 9.164A1 1 0 007 10a.927.927 0 00.165-.014l3-.5a1 1 0 00.543-1.693l-2.5-2.5a1 1 0 10-1.415 1.414l.782.783a.249.249 0 01-.135.423l-.6.1a1 1 0 00-.826 1.151zM17.207 5.293a1 1 0 00-1.414 0l-2.5 2.5a1 1 0 00.565 1.7l3.5.5a1 1 0 10.284-1.98l-1.026-.146a.25.25 0 01-.141-.425l.732-.732a1 1 0 000-1.417zM6.072 16.127a1 1 0 001.307-.541 5 5 0 019.242 0 1 1 0 001.848-.767 7 7 0 00-12.938 0 1 1 0 00.541 1.308z\"/><path d=\"M18 19a1 1 0 01-1-1 3 3 0 00-3-3h-4a3 3 0 00-3 3 1 1 0 01-1 1 2.5 2.5 0 000 5 5.834 5.834 0 003.008-.771.643.643 0 01.706.035 4.027 4.027 0 004.572 0 .641.641 0 01.706-.035A5.834 5.834 0 0018 24a2.5 2.5 0 000-5zm0 3a3.865 3.865 0 01-1.992-.494 2.642 2.642 0 00-2.9.146 1.975 1.975 0 01-2.206 0 2.636 2.636 0 00-2.9-.146A3.865 3.865 0 016 22a.5.5 0 010-1 3 3 0 003-3 1 1 0 011-1h4a1 1 0 011 1 3 3 0 003 3 .5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});