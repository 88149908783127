define("ember-svg-jar/inlined/phone-action-navigation-pin-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-navigation-pin-1</title><path d=\"M14 13.938V18.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-11a1 1 0 011-1h3.4a.246.246 0 00.244-.3 5.782 5.782 0 01-.1-1.918A.249.249 0 009.292 4H5.5A2.5 2.5 0 003 6.5v15A2.5 2.5 0 005.5 24h8a2.5 2.5 0 002.5-2.5v-6.786a.247.247 0 00-.212-.237 3.248 3.248 0 01-1.368-.719.249.249 0 00-.42.18z\"/><path d=\"M16 0a5.006 5.006 0 00-5 5c0 3.171 4.457 7.664 4.646 7.854a.5.5 0 00.708 0C16.543 12.664 21 8.171 21 5a5.006 5.006 0 00-5-5zm-1.75 5A1.75 1.75 0 1116 6.75 1.752 1.752 0 0114.25 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});