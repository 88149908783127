define("ember-svg-jar/inlined/weather-cloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-cloud</title><path d=\"M9.25 3a1 1 0 001-1V1a1 1 0 00-2 0v1a1 1 0 001 1zM3.416 4.83A1 1 0 104.83 3.416l-.707-.707a1 1 0 00-1.414 1.414zM3 9.25a1 1 0 00-1-1H1a1 1 0 000 2h1a1 1 0 001-1zM14.377 5.123a1 1 0 00.707-.293l.707-.707a1 1 0 00-1.414-1.414l-.707.707a1 1 0 00.707 1.707z\"/><path d=\"M5.916 11.994a.249.249 0 00.109-.38 4 4 0 016.343-4.867.253.253 0 00.264.084 8.548 8.548 0 011.568-.294.25.25 0 00.191-.378 6 6 0 10-10.112 6.447.25.25 0 00.346.068 6.939 6.939 0 011.291-.68zM22.094 15.074a.252.252 0 01-.1-.193A7 7 0 008.338 12.84a.249.249 0 01-.22.173A5.5 5.5 0 008.5 24H19a5 5 0 003.094-8.926zM22 19a3 3 0 01-3 3H8.5a3.5 3.5 0 11.448-6.971.994.994 0 001.116-.833A5 5 0 0120 15c0 .146-.007.289-.02.433a1 1 0 00.5.959A3.007 3.007 0 0122 19z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});