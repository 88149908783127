define("ember-svg-jar/inlined/folder-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-home</title><path d=\"M18.613 10.59l1.975 1.671A.25.25 0 0021 12.07V4.5A1.5 1.5 0 0019.5 3H9.618a.5.5 0 01-.447-.276L8.224.83A1.5 1.5 0 006.88 0H1.5A1.5 1.5 0 000 1.5v15A1.5 1.5 0 001.5 18h6.274a.248.248 0 00.244-.233 2.488 2.488 0 01.866-1.674l6.5-5.5a2.5 2.5 0 013.229-.003z\"/><path d=\"M23.823 17.618l-6.5-5.5a.5.5 0 00-.647 0l-6.5 5.5a.5.5 0 00.324.882h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5H15a.5.5 0 00.5-.5v-2.25a1.5 1.5 0 013 0v2.25a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-4.75a.25.25 0 01.25-.25h1.25a.5.5 0 00.323-.882z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});