define("ember-svg-jar/inlined/smiley-evil-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-evil-alternate</title><path d=\"M9.5 11.368A1 1 0 0010.868 11a1 1 0 00-.368-1.368l-3.5-2a1 1 0 00-1 1.736zM17 7.632l-3.5 2A1 1 0 0014 11.5a.988.988 0 00.5-.132l3.5-2a1 1 0 10-1-1.736z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M16.152 14.421a2 2 0 00-3.232.22l-.708 1.133a.251.251 0 01-.424 0l-.708-1.133a2 2 0 00-3.232-.22l-1.617 1.938a1 1 0 001.538 1.282l1.395-1.675a.25.25 0 01.4.028l.737 1.18a2 2 0 003.39 0l.737-1.18a.25.25 0 01.4-.028l1.4 1.675a1 1 0 001.538-1.282z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});