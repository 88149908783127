define("ember-svg-jar/inlined/single-woman-actions-alarm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-alarm</title><path d=\"M8.976 9.33a.5.5 0 00-.757.1l-1.737 2.73a.5.5 0 01-.837 0L3.923 9.411a.5.5 0 00-.755-.1C2.015 10.373 2.1 10.78.141 17.931a.5.5 0 00.478.626h2.3a.247.247 0 01.243.2l.921 4.847v.007a.5.5 0 00.484.389h2.969a.5.5 0 00.483-.388v-.007l.921-4.847a.247.247 0 01.243-.2h2.191a.251.251 0 00.207-.11 2.466 2.466 0 00.327-.628.242.242 0 000-.141C10.029 10.8 10.1 10.383 8.976 9.33zM6.041 8.412a4.206 4.206 0 10-4.207-4.206 4.211 4.211 0 004.207 4.206zm0-1.979A2.223 2.223 0 013.9 4.81a.248.248 0 01.158-.3 4.63 4.63 0 001.8-1.128.247.247 0 01.353 0 4.615 4.615 0 001.8 1.128.248.248 0 01.157.3 2.222 2.222 0 01-2.127 1.623zM23.382 21.031a.495.495 0 00.395-.792l-.238-.312a4.042 4.042 0 01-1.147-2.689v-1.65a4.454 4.454 0 10-8.907 0v1.649a4.046 4.046 0 01-1.147 2.689l-.238.312a.495.495 0 00.4.792zM19.6 22.212a.5.5 0 00-.392-.191h-2.543a.494.494 0 00-.479.618 1.808 1.808 0 003.5 0 .5.5 0 00-.086-.427z\"/><path d=\"M8.976 9.33a.5.5 0 00-.757.1l-1.737 2.73a.5.5 0 01-.837 0L3.923 9.411a.5.5 0 00-.755-.1C2.015 10.373 2.1 10.78.141 17.931a.5.5 0 00.478.626h2.3a.247.247 0 01.243.2l.921 4.847v.007a.5.5 0 00.484.389h2.969a.5.5 0 00.483-.388v-.007l.921-4.847a.247.247 0 01.243-.2h2.191a.251.251 0 00.207-.11 2.466 2.466 0 00.327-.628.242.242 0 000-.141C10.029 10.8 10.1 10.383 8.976 9.33zM6.041 8.412a4.206 4.206 0 10-4.207-4.206 4.211 4.211 0 004.207 4.206zm0-1.979A2.223 2.223 0 013.9 4.81a.248.248 0 01.158-.3 4.63 4.63 0 001.8-1.128.247.247 0 01.353 0 4.615 4.615 0 001.8 1.128.248.248 0 01.157.3 2.222 2.222 0 01-2.127 1.623zM23.382 21.031a.495.495 0 00.395-.792l-.238-.312a4.042 4.042 0 01-1.147-2.689v-1.65a4.454 4.454 0 10-8.907 0v1.649a4.046 4.046 0 01-1.147 2.689l-.238.312a.495.495 0 00.4.792zM19.6 22.212a.5.5 0 00-.392-.191h-2.543a.494.494 0 00-.479.618 1.808 1.808 0 003.5 0 .5.5 0 00-.086-.427z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});