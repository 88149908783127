define("ember-svg-jar/inlined/single-man-actions-flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-flag</title><path d=\"M12.5 13a1.975 1.975 0 01.584-1.4.25.25 0 00-.084-.411l-1.352-.58a.249.249 0 01-.152-.229V9.244a.247.247 0 01.074-.177 3.309 3.309 0 00.779-2.231.251.251 0 01.088-.176 1.872 1.872 0 00.537-1.4 1.58 1.58 0 00-.316-1.015.249.249 0 01-.034-.264 2.531 2.531 0 00.156-1.727C12.439.867 10.737.374 9.384.374A3.751 3.751 0 006.221 1.7a.253.253 0 01-.213.118 1.073 1.073 0 00-.9.375c-.371.493-.105 1.17.176 1.732a.251.251 0 01-.045.288 1.247 1.247 0 00-.162.2 1.763 1.763 0 00-.266.961 1.522 1.522 0 00.639 1.331.249.249 0 01.117.194 3.167 3.167 0 00.845 2.175.25.25 0 01.08.183v1.123a.249.249 0 01-.152.229L2.408 12.3C1.136 12.842.953 14 .7 15.6c-.056.359-.117.751-.2 1.179a.5.5 0 00.106.411.5.5 0 00.385.18h11.254a.25.25 0 00.25-.25zM23.273 12.574a.5.5 0 00-.478-.033l-.884.41a3.052 3.052 0 01-2.717-.37 4.048 4.048 0 00-3.6-.489l-1.269.429a.5.5 0 00-.34.474L14 22.626a1 1 0 002 0v-3.914a.25.25 0 01.191-.243 3.048 3.048 0 012.447.44 4.011 4.011 0 002.27.7 4.256 4.256 0 001.376-.232l.933-.43a.5.5 0 00.283-.447V13a.5.5 0 00-.227-.426z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});