define("ember-svg-jar/inlined/notes-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-disable</title><path d=\"M12 4.75V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v4.708a.244.244 0 00.3.238 8.14 8.14 0 011.459-.179.244.244 0 00.24-.244V4.5a2 2 0 00-2-2H14.25a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-1.5A.25.25 0 016 2.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25H2a2 2 0 00-2 2v13a2 2 0 002 2h7.458a.245.245 0 00.238-.3 8.175 8.175 0 01-.176-1.46.243.243 0 00-.244-.239H2.25A.25.25 0 012 17.25V4.75a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.5 6.5 0 00-6.5-6.5zm-3.967 8.6a4.484 4.484 0 016.07-6.069.252.252 0 01.052.4l-5.724 5.722a.252.252 0 01-.398-.053zm8.3-.887a4.492 4.492 0 01-3.112 3.116 4.439 4.439 0 01-3.325-.366.252.252 0 01-.052-.4l5.724-5.722a.252.252 0 01.4.051 4.448 4.448 0 01.366 3.324z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});