define("ember-svg-jar/inlined/shipment-hook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-hook</title><path d=\"M22.844 13.04a.264.264 0 01-.061-.023l-7.312-3.9a1 1 0 00-.941 1.766l3.088 1.646A.25.25 0 0117.5 13h-11a.25.25 0 01-.118-.471l3.088-1.646a1 1 0 00-.94-1.766l-7.312 3.9a.264.264 0 01-.061.023A1.5 1.5 0 000 14.5v8A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-8a1.5 1.5 0 00-1.156-1.46zM20.25 16v5a.75.75 0 01-1.5 0v-5a.75.75 0 011.5 0zm-5 0v5a.75.75 0 01-1.5 0v-5a.75.75 0 011.5 0zm-5 0v5a.75.75 0 01-1.5 0v-5a.75.75 0 011.5 0zm-5 0v5a.75.75 0 01-1.5 0v-5a.75.75 0 011.5 0z\"/><path d=\"M12 9a3 3 0 003-3 1 1 0 00-2 0 1 1 0 01-2 0 1.074 1.074 0 01.708-.942A2.013 2.013 0 0013 3.187V1a1 1 0 00-2 0v2.018a.251.251 0 01-.15.23A3.043 3.043 0 009 6a3 3 0 003 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});