define("ember-svg-jar/inlined/single-neutral-id-card-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-id-card-3</title><path d=\"M24 6.25a2 2 0 00-2-2h-6a.5.5 0 00-.5.5v1.5a.5.5 0 01-1 0v-2.5a3 3 0 00-6 0v2.5a.5.5 0 01-1 0v-1.5a.5.5 0 00-.5-.5H2a2 2 0 00-2 2v15a2 2 0 002 2h20a2 2 0 002-2zM10.5 3.5a1 1 0 111 1 1 1 0 01-1-1zm-8 17.75a.5.5 0 01-.5-.5v-10.5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v10.5a.5.5 0 01-.5.5z\"/><path d=\"M19.25 13.5H14.5a.75.75 0 000 1.5h4.75a.75.75 0 000-1.5zM18 16.5h-3.5a.75.75 0 000 1.5H18a.75.75 0 000-1.5zM9.457 16.62a.894.894 0 01-.026-.391 4.033 4.033 0 00.864-2.766 2.3 2.3 0 10-4.59 0 3.988 3.988 0 00.833 2.723c.043.089.053.355.076.382-1.543.58-2.39 1-2.738 1.709a4.247 4.247 0 00-.368 1.155.5.5 0 00.109.407.5.5 0 00.383.179h8a.5.5 0 00.383-.179.5.5 0 00.109-.407 4.247 4.247 0 00-.368-1.155c-.348-.705-1.195-1.129-2.667-1.657z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});