define("ember-svg-jar/inlined/trip-road", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trip-road</title><path d=\"M19.012 14.552a.239.239 0 00-.148-.137 1.969 1.969 0 01-.824-.52c-1.7-1.815-4.54-5.286-4.54-8.145 0-.171.009-.339.024-.506s.08-.494-.249-.494H12a.25.25 0 00-.25.25v2.75a.75.75 0 01-1.5 0V5a.25.25 0 00-.25-.25H7.181a.5.5 0 00-.465.317l-6.682 17a.5.5 0 00.466.683H10a.25.25 0 00.25-.25v-2.75a.75.75 0 011.5 0v2.75a.25.25 0 00.25.25h9.5a.5.5 0 00.466-.683zm-7.262 1.2a.75.75 0 01-1.5 0v-4a.75.75 0 011.5 0zM19.864 12.87C20.935 11.723 24 8.226 24 5.75a4.5 4.5 0 00-9 0c0 2.476 3.065 5.973 4.136 7.12a.5.5 0 00.728 0zM18 5.75a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});