define("ember-svg-jar/inlined/outdoors-sun-plants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-sun-plants</title><path d=\"M10.005 14h-.482a.25.25 0 01-.177-.427l.341-.341a1 1 0 10-1.414-1.414l-.341.341a.25.25 0 01-.427-.177V11.5a1 1 0 00-2 0v.482a.25.25 0 01-.427.177l-.34-.341a1 1 0 10-1.415 1.414l.341.341a.25.25 0 01-.177.427h-.482a1 1 0 100 2h.482a.25.25 0 01.177.427l-.341.341a1 1 0 101.415 1.414l.34-.341a.25.25 0 01.427.177v.482a1 1 0 002 0v-.482a.25.25 0 01.427-.177l.341.341a1 1 0 101.414-1.414l-.341-.341A.25.25 0 019.523 16h.482a1 1 0 100-2zm-3.5 2.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM6.505 20.5a1 1 0 00-1 1V23a1 1 0 002 0v-1.5a1 1 0 00-1-1zM1 20.5a.5.5 0 00-.492.59A3.547 3.547 0 004 24h.008a.5.5 0 00.49-.6A3.544 3.544 0 001 20.5zM18.005 19a1 1 0 00-1 1v3a1 1 0 002 0v-3a1 1 0 00-1-1zM23.5 18.5a3.545 3.545 0 00-3.49 2.9.5.5 0 00.49.6h.008a3.547 3.547 0 003.484-2.91.5.5 0 00-.492-.59z\"/><ellipse cx=\"15.005\" cy=\"22.75\" rx=\".791\" ry=\"1.581\" transform=\"rotate(-45 15.006 22.75)\"/><path d=\"M22.243 16.243a1.5 1.5 0 000-2.122l-.2-.194a.25.25 0 01.177-.427h.28a1.5 1.5 0 000-3h-.275a.25.25 0 01-.177-.427l.2-.194a1.5 1.5 0 000-2.122 1.536 1.536 0 00-2.121 0l-.2.2a.25.25 0 01-.427-.177V7.5a1.5 1.5 0 00-3 0v.275a.25.25 0 01-.427.177l-.2-.2a1.536 1.536 0 00-2.121 0 1.5 1.5 0 000 2.122l.2.194a.25.25 0 01-.177.427H13.5a1.5 1.5 0 000 3h.275a.25.25 0 01.177.427l-.2.194a1.5 1.5 0 002.121 2.122l.2-.2a.25.25 0 01.427.177v.285a1.5 1.5 0 003 0v-.275a.25.25 0 01.427-.177l.2.2a1.5 1.5 0 002.116-.005zM18 14a2 2 0 112-2 2 2 0 01-2 2zM9.495 3a1 1 0 001-1V1a1 1 0 00-2 0v1a1 1 0 001 1zM3.485 4.9A1 1 0 104.9 3.49l-.708-.708A1 1 0 002.777 4.2zM15.505 4.9l.707-.707A1 1 0 0014.8 2.782l-.707.708a1 1 0 001.412 1.41zM2 10.5a1 1 0 000-2H1a1 1 0 000 2zM13.063 6.582a1 1 0 00-.166-1.4A5.444 5.444 0 009.495 4a5.518 5.518 0 00-5.45 4.752A1 1 0 004.9 9.878a1.047 1.047 0 00.136.009 1 1 0 00.99-.865A3.51 3.51 0 019.495 6a3.458 3.458 0 012.163.749 1 1 0 001.405-.167z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});