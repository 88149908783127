define("ember-svg-jar/inlined/file-doc-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-doc</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M56 67.232C56 68.76 54.865 70 53.464 70H2.539C1.136 70 0 68.76 0 67.232V2.766C0 1.237 1.136 0 2.539 0H42.79L56 14.64v52.592z\" fill=\"#2A387E\"/><path d=\"M42 0v12.375c0 .032 0 .064.002.095.053 1.67 1.463 2.981 3.15 2.928H56L42 0z\" fill=\"#18235C\"/><text font-family=\"ProximaNova-Semibold, Proxima Nova\" font-size=\"28\" font-weight=\"500\" fill=\"#FFF\"><tspan x=\"14.858\" y=\"46\">W</tspan></text></g>",
    "attrs": {
      "width": "56",
      "height": "70",
      "viewBox": "0 0 56 70",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});