define("ember-svg-jar/inlined/performance-money-increase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>performance-money-increase</title><path d=\"M15.421 6.372a.5.5 0 01-.68.051l-2.855-2.285a2.5 2.5 0 00-3.329.184l-6.868 6.867a1.5 1.5 0 002.122 2.122l6.234-6.235a.5.5 0 01.666-.036L13.6 9.348a2.521 2.521 0 003.384-.248l3.454-3.734a.249.249 0 01.179-.08.246.246 0 01.181.073L22.22 6.78a.746.746 0 00.53.22.75.75 0 00.75-.75V.75a.75.75 0 00-.75-.75h-5.5a.75.75 0 00-.53 1.28l1.6 1.6a.251.251 0 01.007.347zM21 21h-7a1.5 1.5 0 000 3h7a1.5 1.5 0 000-3zM3 24h7a1.5 1.5 0 000-3H3a1.5 1.5 0 000 3zM9 19.5a1.5 1.5 0 000-3H2a1.5 1.5 0 000 3zM23.5 18a1.5 1.5 0 00-1.5-1.5h-7a1.5 1.5 0 000 3h7a1.5 1.5 0 001.5-1.5zM14 15h7a1.5 1.5 0 000-3h-7a1.5 1.5 0 000 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});