define("ember-svg-jar/inlined/auto-pilot-car-signal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>auto-pilot-car-signal</title><path d=\"M10.25 2.749h.5a1 1 0 000-2h-.5a1 1 0 000 2zM13.75 2.749h.5a1 1 0 000-2h-.5a1 1 0 000 2zM17.25 2.749h.5a1 1 0 000-2h-.5a1 1 0 000 2zM20.75 2.749H21a.25.25 0 01.25.25v.25a1 1 0 002 0v-.5a2 2 0 00-2-2h-.5a1 1 0 000 2zM23.25 6.249a1 1 0 10-2 0v.5a1 1 0 002 0zM22.25 8.749a1 1 0 00-1 1v.5a1 1 0 102 0v-.5a1 1 0 00-1-1zM6.975 1.525a.5.5 0 00-.146-.354 4 4 0 00-5.658 5.656.5.5 0 00.707 0l4.951-4.949a.5.5 0 00.146-.353zM6 5.249A.752.752 0 015.25 6a.75.75 0 000 1.5A2.252 2.252 0 007.5 5.249a.75.75 0 10-1.5 0z\"/><path d=\"M4.53 9.959a.75.75 0 00.719.54.732.732 0 00.211-.03c4.165-1.217 5.04-3.511 5.04-5.22a.75.75 0 10-1.5 0C9 6.99 7.667 8.261 5.039 9.03a.749.749 0 00-.509.929zM23.984 20.5a9.933 9.933 0 00-6.563-8.508.25.25 0 01-.171-.237v-.591a3 3 0 00-6 0v.595a.248.248 0 01-.178.239c-4.063 1.232-7.4 4.614-8.045 8.587a2.078 2.078 0 00.464 1.679 2.032 2.032 0 00.669.521.247.247 0 00.255-.021.249.249 0 00.1-.234 2.205 2.205 0 01-.018-.283 2.75 2.75 0 015.5 0 2.408 2.408 0 01-.045.453.248.248 0 00.246.3h6.6a.249.249 0 00.246-.3 2.408 2.408 0 01-.044-.451 2.75 2.75 0 115.5 0c0 .074 0 .147-.011.22a.25.25 0 00.368.243 2.1 2.1 0 00.587-.465 2.235 2.235 0 00.54-1.747zm-12.8-5.825l-1.249 2.496A1.494 1.494 0 018.594 18h-1.6a.5.5 0 01-.414-.78 9.914 9.914 0 013.933-3.212.5.5 0 01.673.67zm2.066-3.511a1 1 0 012 0v.1a.251.251 0 01-.266.25 9.535 9.535 0 00-1.464.016.25.25 0 01-.27-.249zm5.026 6.121A1.49 1.49 0 0117 18h-3.9a.8.8 0 01-.678-.378.79.79 0 01-.035-.775l1.008-2.018A1.493 1.493 0 0114.736 14H16.5a1.493 1.493 0 011.342.829l.5 1a1.491 1.491 0 01-.066 1.458z\"/><circle cx=\"7.25\" cy=\"22.249\" r=\"1.75\"/><circle cx=\"19.75\" cy=\"22.249\" r=\"1.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});