define("ember-svg-jar/inlined/science-axis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-axis</title><path d=\"M24 20a1 1 0 00-1-1h-5.227a.251.251 0 01-.185-.419c2.5-2.745 3.307-7.334 3.469-11.1a.5.5 0 01.5-.478h.943a1 1 0 00.707-1.707l-2.5-2.5a1 1 0 00-1.414 0l-2.5 2.5A1 1 0 0017.5 7h1.049a.5.5 0 01.5.523c-.144 3.119-.769 7.336-2.938 9.71a5.173 5.173 0 01-2.477 1.555A.5.5 0 0113 18.3V16a.5.5 0 01.5-.5h.177a1 1 0 000-2H13.5a.5.5 0 01-.5-.5v-2.5a.5.5 0 01.5-.5h.177a1 1 0 000-2H13.5a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v6.5a.5.5 0 01-.5.5h-.323a1 1 0 000 2h.323a.5.5 0 01.5.5V13a.5.5 0 01-.5.5h-.323a1 1 0 000 2h.323a.5.5 0 01.5.5v2.3a.5.5 0 01-.634.481 5.183 5.183 0 01-2.476-1.55c-2.166-2.37-2.792-6.59-2.937-9.712a.5.5 0 01.5-.519H6.5a1 1 0 00.707-1.707l-2.5-2.5a1 1 0 00-1.414 0l-2.5 2.5A1 1 0 001.5 7h.944a.5.5 0 01.5.478c.162 3.771.964 8.359 3.469 11.1a.251.251 0 01-.186.422H1a1 1 0 000 2h9.5a.5.5 0 01.5.5V23a1 1 0 002 0v-1.5a.5.5 0 01.5-.5H23a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});