define("ember-svg-jar/inlined/tools-hammer-hold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-hammer-hold</title><path d=\"M19.481 1.706L18.067.292a1 1 0 00-1.414 0l-4.242 4.242a1 1 0 000 1.414l1.414 1.414a1 1 0 001.414 0l4.242-4.242a1 1 0 000-1.414zM22.49 14.761a7.017 7.017 0 00-.89-8.813l-1.123-1.125a.5.5 0 00-.707 0l-2.828 2.828a.5.5 0 000 .707L18.4 9.811a8.084 8.084 0 012.272 4.539 1 1 0 00.77.834.993.993 0 00.216.024 1.007 1.007 0 00.832-.447zM5.255 17.971a1.123 1.123 0 01-1.59 0l-.94-.93a1.123 1.123 0 010-1.59 1.1 1.1 0 01.8-.33 1.073 1.073 0 01.79.33l.94.93a1.123 1.123 0 010 1.59zM4.145 14.212a1.124 1.124 0 111.59-1.59l1.639 1.639a1.115 1.115 0 01.331.79 1.136 1.136 0 01-.331.8 1.159 1.159 0 01-1.589 0zM9.494 13.731a1.121 1.121 0 01-1.59 0l-1.639-1.639a1.123 1.123 0 010-1.59 1.1 1.1 0 01.789-.33 1.128 1.128 0 01.8.33l1.64 1.64a1.121 1.121 0 010 1.589zM11.584 11.232v.118a.5.5 0 01-.3.484 1.168 1.168 0 01-1.259-.222l-.93-.94a1.073 1.073 0 01-.33-.79 1.1 1.1 0 01.33-.8 1.161 1.161 0 011.59 0l.891.9a.5.5 0 01.121.5 2.438 2.438 0 00-.113.75zM13.368 14.785a.5.5 0 000-.708l-1.055-1.056a2.348 2.348 0 01-.238-.28.5.5 0 00-.585-.174 1.879 1.879 0 01-.484.115.5.5 0 00-.306.145l-.008.008a.493.493 0 00-.141.3 1.854 1.854 0 01-.528 1.126 1.917 1.917 0 01-1.118.534.5.5 0 00-.3.145l-.029.029a.5.5 0 00-.142.3 1.869 1.869 0 01-.534 1.112 1.9 1.9 0 01-1.1.53.5.5 0 00-.294.144l-.062.063a.5.5 0 00-.14.29 1.841 1.841 0 01-1.6 1.62.49.49 0 00-.29.144L.5 23.13a.514.514 0 00-.11.54.5.5 0 00.46.309L3.905 24a.5.5 0 00.35-.14z\"/><path d=\"M16.912 22.559L18.2 24H9.36a.5.5 0 01-.353-.147l-1.29-1.289a.5.5 0 010-.707l6.718-6.719a1 1 0 000-1.414l-1.413-1.412a1.5 1.5 0 012.12-2.12l2.12 2.12a4 4 0 010 5.66l-.348.348a3 3 0 00-.002 4.239z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});