define("ember-svg-jar/inlined/calendar-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>calendar-3</title><path d=\"M7.5 10.5h-1a1 1 0 100 2h1a1 1 0 000-2zM12.5 10.5h-1a1 1 0 000 2h1a1 1 0 000-2zM17.5 10.5h-1a1 1 0 000 2h1a1 1 0 000-2zM7.5 14.5h-1a1 1 0 100 2h1a1 1 0 000-2zM12.5 14.5h-1a1 1 0 100 2h1a1 1 0 000-2zM17.5 14.5h-1a1 1 0 100 2h1a1 1 0 000-2zM7.5 18.5h-1a1 1 0 000 2h1a1 1 0 000-2zM12.5 18.5h-1a1 1 0 000 2h1a1 1 0 100-2zM17.5 18.5h-1a1 1 0 000 2h1a1 1 0 000-2z\"/><path d=\"M21.5 3h-2.75a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v4.75a.75.75 0 11-1.5 0V3.5a.5.5 0 00-.5-.5H8.25A.25.25 0 018 2.751V1a1 1 0 10-2 0v4.75a.75.75 0 11-1.5 0V3.5A.5.5 0 004 3H2.5a2 2 0 00-2 2v17a2 2 0 002 2h19a2 2 0 002-2V5a2 2 0 00-2-2zm0 18.5a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5v-12A.5.5 0 013 9h18a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});