define("ember-svg-jar/inlined/crypto-currency-bitcoin-exchange-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-exchange-1</title><path d=\"M6.5 16.642a1 1 0 00-1.006.133l-.726.569a.251.251 0 01-.359-.054 9.255 9.255 0 019.547-14.332 1.25 1.25 0 00.523-2.445A11.749 11.749 0 002.457 18.851a.249.249 0 01-.048.342l-.024.019a1 1 0 00.357 1.753l3.471.929a1 1 0 001.253-1.072l-.359-3.365a1 1 0 00-.607-.815zM21.651 5.308a.25.25 0 01.038-.329l.437-.392a1 1 0 00-.475-1.726l-3.919-.77a1 1 0 00-1.156 1.252l.962 3.427a1 1 0 00.684.689.985.985 0 00.279.04 1 1 0 00.668-.256l.262-.235a.25.25 0 01.378.053 9.248 9.248 0 01-9.782 13.977 1.25 1.25 0 00-.533 2.443A11.745 11.745 0 0021.651 5.308z\"/><path d=\"M8.751 16.888A1.25 1.25 0 0010 18.138h.333a.25.25 0 01.25.25v.112a1.25 1.25 0 002.5 0v-.124a.249.249 0 01.233-.249 3.7 3.7 0 003.434-3.683 3.65 3.65 0 00-.814-2.288.25.25 0 010-.312 3.65 3.65 0 00.814-2.288 3.7 3.7 0 00-3.434-3.683.25.25 0 01-.233-.249V5.5a1.25 1.25 0 10-2.5 0v.11a.25.25 0 01-.25.25H10a1.25 1.25 0 00-1.25 1.25zm5.5-7.333a1.2 1.2 0 01-1.194 1.194H11.5a.25.25 0 01-.25-.25V8.61a.25.25 0 01.25-.25h1.556a1.2 1.2 0 011.195 1.195zm-1.194 6.083H11.5a.25.25 0 01-.25-.25V13.5a.25.25 0 01.25-.25h1.556a1.195 1.195 0 010 2.389z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});