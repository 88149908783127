define("ember-svg-jar/inlined/ping-pong-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ping-pong-table</title><rect x=\"5.75\" y=\"7.25\" width=\"5.5\" height=\"4\" rx=\".5\" ry=\".5\"/><rect x=\"5.75\" y=\"12.75\" width=\"5.5\" height=\"4\" rx=\".5\" ry=\".5\"/><rect x=\"12.75\" y=\"12.75\" width=\"5.5\" height=\"4\" rx=\".5\" ry=\".5\"/><rect y=\"7.25\" width=\"4.25\" height=\"9.5\" rx=\".5\" ry=\".5\"/><rect x=\"12.75\" y=\"7.25\" width=\"5.5\" height=\"4\" rx=\".5\" ry=\".5\"/><rect x=\"19.75\" y=\"7.25\" width=\"4.25\" height=\"9.5\" rx=\".5\" ry=\".5\"/><path d=\"M10.75 18.25H.5a.5.5 0 00-.5.5V19a1.5 1.5 0 001.5 1.5h9.25a.5.5 0 00.5-.5v-1.25a.5.5 0 00-.5-.5zM23.5 18.25H13.25a.5.5 0 00-.5.5V20a.5.5 0 00.5.5h9.25A1.5 1.5 0 0024 19v-.25a.5.5 0 00-.5-.5zM10.75 3.5H1.5A1.5 1.5 0 000 5v.25a.5.5 0 00.5.5h10.25a.5.5 0 00.5-.5V4a.5.5 0 00-.5-.5zM22.5 3.5h-9.25a.5.5 0 00-.5.5v1.25a.5.5 0 00.5.5H23.5a.5.5 0 00.5-.5V5a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});