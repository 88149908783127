define("ember-svg-jar/inlined/robot-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>robot-1</title><path d=\"M20.5 7.431a2.022 2.022 0 00-.483.059.25.25 0 01-.3-.176 8 8 0 00-2.027-3.5.251.251 0 010-.35 2 2 0 10-3.171-2.394 1.968 1.968 0 00-.191.448.248.248 0 01-.3.173 8.016 8.016 0 00-4.05 0 .248.248 0 01-.3-.173 1.968 1.968 0 00-.191-.448 2 2 0 10-3.173 2.392.251.251 0 010 .35 8 8 0 00-2.027 3.5.25.25 0 01-.3.176 2 2 0 100 3.882.25.25 0 01.3.176 8 8 0 002.027 3.5.25.25 0 010 .35 1.948 1.948 0 00-.295.391 2 2 0 103.462 2 1.9 1.9 0 00.19-.447.25.25 0 01.306-.172c.108.029.216.056.327.081a.249.249 0 01.2.244v1.79a1.72 1.72 0 00.435 1.156.249.249 0 01.065.168v1.069a.25.25 0 01-.25.25H9a1 1 0 000 2h6a1 1 0 000-2h-1.75a.25.25 0 01-.25-.25v-1.064a.247.247 0 01.065-.168 1.72 1.72 0 00.435-1.156V17.5a.249.249 0 01.2-.244c.111-.025.219-.052.327-.081a.25.25 0 01.306.172 1.9 1.9 0 00.19.447 2 2 0 103.462-2 1.948 1.948 0 00-.3-.391.25.25 0 010-.35 8.008 8.008 0 002.027-3.5.25.25 0 01.3-.176 2 2 0 10.488-3.946zm-5 4a2 2 0 112-2 2 2 0 01-2 2zm-7 0a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});