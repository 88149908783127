define("ember-svg-jar/inlined/java", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>java</title><path d=\"M19 9.626a.876.876 0 00-.875.875v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V10.5A.876.876 0 0019 9.626zM9 9.626a.876.876 0 00-.875.875v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V10.5A.876.876 0 009 9.626z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zm-16.375 9a2.125 2.125 0 01-4.25 0 .625.625 0 111.25 0 .875.875 0 001.75 0V9a.625.625 0 011.25 0zm5 1.5a.625.625 0 01-1.25 0v-2.124a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0v-4.374a2.213 2.213 0 011.9-2.239 2.128 2.128 0 012.35 2.113zm5-4.5a8.178 8.178 0 01-1.625 4.876.625.625 0 01-1 0 8.178 8.178 0 01-1.625-4.876V9a.625.625 0 111.25 0v1.5a6.931 6.931 0 00.771 3.161.138.138 0 00.213-.01 6.926 6.926 0 00.766-3.151V9a.625.625 0 111.25 0zm5 4.5a.625.625 0 01-1.25 0v-2.124a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0v-4.374a2.213 2.213 0 011.9-2.239 2.128 2.128 0 012.35 2.113z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});