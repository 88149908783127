define("ember-svg-jar/inlined/single-neutral-actions-key.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-key</title><path d=\"M12.939 14.149a.5.5 0 00.186-.765 7.516 7.516 0 00-5.88-2.884A7.543 7.543 0 00.06 15.857a.5.5 0 00.479.643h9.489a.5.5 0 00.417-.223 5.871 5.871 0 012.494-2.128z\"/><circle cx=\"7.039\" cy=\"4.75\" r=\"4.75\"/><path d=\"M23.961 11.63a.5.5 0 00-.5-.5h-1.978a.5.5 0 00-.344.137l-4.189 3.957a.251.251 0 01-.244.057A4.456 4.456 0 1015.424 24a4.453 4.453 0 004.26-5.731.25.25 0 01.063-.249l4.067-4.057a.5.5 0 00.147-.355zM16.8 20.62a1.737 1.737 0 110-2.456 1.738 1.738 0 010 2.456z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});