define("ember-svg-jar/inlined/couple-frame", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>couple-frame</title><path d=\"M23.87 16.479a1.605 1.605 0 000-1.261l-1.284-2.981a.6.6 0 010-.473l1.284-2.981a1.605 1.605 0 000-1.261L22.586 4.54a.591.591 0 010-.47l.556-1.29A1.6 1.6 0 0021.227.611L19.9.989a.617.617 0 01-.333 0l-3.26-.926a1.572 1.572 0 00-.881 0l-3.259.926a.6.6 0 01-.334 0L8.575.063a1.572 1.572 0 00-.881 0L4.435.989a.617.617 0 01-.333 0L2.774.612A1.6 1.6 0 00.859 2.781l.555 1.289a.593.593 0 010 .471L.13 7.522a1.6 1.6 0 000 1.26l1.284 2.982a.6.6 0 010 .473L.13 15.217a1.608 1.608 0 000 1.262l1.284 2.982a.591.591 0 010 .47l-.556 1.29a1.6 1.6 0 001.915 2.169l1.33-.378a.572.572 0 01.333 0l3.258.925a1.627 1.627 0 00.881 0l3.259-.927a.572.572 0 01.333 0l3.258.925a1.608 1.608 0 00.881 0l3.259-.927a.608.608 0 01.333 0l1.328.376a1.6 1.6 0 001.915-2.169l-.555-1.289a.593.593 0 010-.471zM20 19.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-15a.5.5 0 01.5-.5h15a.5.5 0 01.5.5z\"/><path d=\"M15.31 6.413a1.818 1.818 0 00-2.836-.439.709.709 0 01-.978 0 1.818 1.818 0 00-2.836.439 1.626 1.626 0 00.332 1.947l2.64 2.624a.5.5 0 00.705 0l2.641-2.624a1.628 1.628 0 00.332-1.947z\"/><circle cx=\"8.25\" cy=\"13.25\" r=\"1.75\"/><path d=\"M5.046 17.763a.506.506 0 00.45.737H11a.506.506 0 00.45-.737 3.582 3.582 0 00-6.404 0z\"/><circle cx=\"15.75\" cy=\"13.25\" r=\"1.75\"/><path d=\"M12.546 17.763A.506.506 0 0013 18.5h5.5a.506.506 0 00.45-.737 3.582 3.582 0 00-6.404 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});