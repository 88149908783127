define("ember-svg-jar/inlined/modern-weapon-rifle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-weapon-rifle</title><path d=\"M23.1 2.665a.5.5 0 00.116-.7l-.58-.814a.5.5 0 00-.7-.116l-11.807 8.42-2.245 1.6A3.007 3.007 0 006.667 13l-.056.339a3 3 0 01-1.218 1.947l-4.28 3.052a1 1 0 00-.234 1.395L2.99 22.7a1.107 1.107 0 001.613.021l3.158-4.193a.246.246 0 01.323-.061 3.438 3.438 0 001.722.481 3.5 3.5 0 003.5-3.5 3.328 3.328 0 00-.145-.946.246.246 0 01.091-.272l7.687-5.48a2.073 2.073 0 00.573-2.865L20.671 4.7a.25.25 0 01.059-.349zM9.806 16.944a1.466 1.466 0 01-.548-.107c-.175-.083-.084-.19-.084-.19a1.363 1.363 0 00.112-1.466.178.178 0 01.029-.232l1.067-.761a.215.215 0 01.252.013 1.495 1.495 0 01-.828 2.743zm9.863-9.8c-.993.721-5.464 3.938-6.742 4.858-.15.107-.266-.025-.241-.112l.616-2.171a.25.25 0 01.1-.136L18.7 5.8a.25.25 0 01.348.057l.675.936a.246.246 0 01-.054.349z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});