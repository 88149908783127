define("ember-svg-jar/inlined/like-shine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>like-shine</title><path d=\"M17 23.875a1.5 1.5 0 000-3h1a1.5 1.5 0 000-3h1a1.5 1.5 0 000-3h1a1.5 1.5 0 000-3h-7.358a.5.5 0 01-.487-.618A27.409 27.409 0 0013 6.094c0-3-3-2.8-3-1.219a7.949 7.949 0 01-7.5 8H1a.5.5 0 00-.5.5v8a.5.5 0 00.5.5c7.423.081 5.59 2 9.505 2zM23.75 7.875a.75.75 0 00-.75-.75h-.5a.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25H20a.75.75 0 000 1.5h.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.5a.25.25 0 01.25-.25h.5a.75.75 0 00.75-.75zM1.5 6.625a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.5a.25.25 0 01.25-.25H4a.75.75 0 000-1.5h-.5a.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25H1a.75.75 0 000 1.5zM16 .125a.75.75 0 00-.75.75v.5a.25.25 0 01-.25.25h-.5a.75.75 0 000 1.5h.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.5a.25.25 0 01.25-.25h.5a.75.75 0 000-1.5H17a.25.25 0 01-.25-.25v-.5a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});