define("ember-svg-jar/inlined/layers-show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layers-show</title><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.5 7.25h-1.5a.25.25 0 00-.25.25V20a.75.75 0 01-1.5 0v-1.5a.25.25 0 00-.25-.25H15a.75.75 0 010-1.5h1.5a.25.25 0 00.25-.25V15a.75.75 0 011.5 0v1.5a.25.25 0 00.25.25H20a.75.75 0 010 1.5zM7.5 7.5A4.5 4.5 0 1012 3a4.505 4.505 0 00-4.5 4.5zM12 6a1.5 1.5 0 00-1.5 1.5.5.5 0 01-1 0A2.5 2.5 0 0112 5a.5.5 0 010 1z\"/><path d=\"M9.27 12.618a15.316 15.316 0 01-6.984-4.785.5.5 0 010-.661C5.338 3.74 8.6 2 12 2s6.661 1.738 9.709 5.167a.5.5 0 010 .666c-.343.382-.7.752-1.068 1.1a1 1 0 001.384 1.444c.519-.5 1.018-1.028 1.491-1.579a1.989 1.989 0 000-2.6C19.119 1.075 14.828 0 12 0S4.881 1.076.486 6.2a1.99 1.99 0 000 2.6c.958 1.116 3.87 4.512 8.245 5.738a.972.972 0 00.27.038 1 1 0 00.27-1.963z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});