define("ember-svg-jar/inlined/office-file-rtf-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-file-rtf-1</title><path d=\"M23.707 5.705L18.293.291A1 1 0 0017.585 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.748V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.248.248 0 00-.249-.25H4.25a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.412a1 1 0 00-.293-.707z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zM4.118 15.232l1.824 1.825a.625.625 0 01-.884.883s-1.816-1.809-1.845-1.84-.088-.037-.088.02v1.38a.625.625 0 01-1.25 0v-6a.625.625 0 01.625-.625H4a2.124 2.124 0 01.205 4.239c-.096-.002-.129.076-.087.118zm6.382-3.109h-.626a.249.249 0 00-.249.249V17.5a.625.625 0 01-1.25 0v-5.128a.249.249 0 00-.249-.249H7.5a.625.625 0 110-1.25h3a.625.625 0 010 1.25zm5 0h-2a.375.375 0 00-.375.375v1.126a.249.249 0 00.249.249H14.5a.625.625 0 010 1.25h-1.126a.249.249 0 00-.249.249V17.5a.625.625 0 01-1.25 0v-5a1.627 1.627 0 011.625-1.627h2a.625.625 0 010 1.25z\"/><path d=\"M4.875 13A.877.877 0 004 12.123h-.626a.249.249 0 00-.249.249v1.252a.249.249 0 00.249.249H4A.876.876 0 004.875 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});