define("ember-svg-jar/inlined/database-monitor-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-monitor-sync</title><path d=\"M10.9 1.312a.5.5 0 00-.8.4v.25a.25.25 0 01-.25.25H8.1a3 3 0 00-3 3v1.5a.25.25 0 01-.25.25H4.6a.5.5 0 00-.4.8l1.5 2a.5.5 0 00.8 0l1.5-2a.5.5 0 00-.4-.8h-.25a.25.25 0 01-.25-.25v-1.5a1 1 0 011-1h1.75a.249.249 0 01.25.25v.25a.5.5 0 00.5.5.507.507 0 00.3-.1l2-1.5a.5.5 0 000-.8zM.442 13.269v5.694a1.492 1.492 0 00.485 1.1 1.987 1.987 0 001.515.709h2.75a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-1.25a1 1 0 000 2h5a1 1 0 000-2h-1.25a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h2.75a1.987 1.987 0 001.514-.709 1.489 1.489 0 00.486-1.1v-5.694a2 2 0 00-2-2h-8a2 2 0 00-2 2zm9.75 4.5h-7.5a.25.25 0 01-.25-.25v-4a.25.25 0 01.25-.25h7.5a.25.25 0 01.25.25v4a.25.25 0 01-.25.25zM23.558 8.017a.249.249 0 00-.383-.211 6.631 6.631 0 01-3.5.9 6.631 6.631 0 01-3.5-.9.249.249 0 00-.383.211v1.212c0 .939 1.594 1.984 3.883 1.984s3.883-1.045 3.883-1.984zM15.792 5.722c0 .939 1.594 1.984 3.883 1.984s3.883-1.045 3.883-1.984V4.51a.249.249 0 00-.383-.211 6.631 6.631 0 01-3.5.9 6.631 6.631 0 01-3.5-.9.251.251 0 00-.254-.007.248.248 0 00-.129.218zM15.792 2.215c0 .939 1.594 1.984 3.883 1.984s3.883-1.045 3.883-1.984S21.964.231 19.675.231s-3.883 1.046-3.883 1.984z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});