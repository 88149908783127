define("ember-svg-jar/inlined/maps-mark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>maps-mark</title><path d=\"M23.109 3.21a1.5 1.5 0 00-1.891-1.367l-4.142 1.119a.255.255 0 01-.137 0l-4.795-1.441a.5.5 0 00-.288 0L7.061 2.96a.255.255 0 01-.137 0l-4.14-1.117A1.5 1.5 0 00.891 3.21L0 19.649a1.5 1.5 0 001.135 1.537l5.243 1.3a.5.5 0 00.251 0l5.3-1.447a.247.247 0 01.132 0l5.3 1.447a.5.5 0 00.251 0l5.243-1.3A1.5 1.5 0 0024 19.649zM3.777 13.88a4.411 4.411 0 00-.806.291.763.763 0 01-.32.071.75.75 0 01-.321-1.428 5.982 5.982 0 011.078-.388.75.75 0 01.369 1.454zm5.008-.048a.75.75 0 01-1.033 1.088 4.282 4.282 0 00-1.624-.964.75.75 0 11.46-1.428 5.786 5.786 0 012.197 1.304zm3.747 3.568a3.366 3.366 0 01-2.78-.318.751.751 0 01.776-1.285 1.861 1.861 0 001.538.177.75.75 0 01.466 1.426zm3.044-2.851l-.64.769a.75.75 0 01-1.152-.96l.64-.769a.75.75 0 111.152.96zm5.454-4.079a.75.75 0 11-1.06 1.06l-1.293-1.293a.252.252 0 00-.354 0L17.03 11.53a.75.75 0 11-1.06-1.06l1.293-1.293a.252.252 0 000-.354L15.97 7.53a.75.75 0 111.06-1.06l1.293 1.293a.252.252 0 00.354 0L19.97 6.47a.75.75 0 011.06 1.06l-1.293 1.293a.252.252 0 000 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});