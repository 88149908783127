define("ember-svg-jar/inlined/newspaper-give", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>newspaper-give</title><circle cx=\"3.5\" cy=\"4.25\" r=\"3.5\"/><path d=\"M1 23.25h5.367a1 1 0 001-1v-5.375a.25.25 0 01.455-.143l.637.92A4.526 4.526 0 0014.433 19l1.612-.921a1.78 1.78 0 00.819-2.2 1.7 1.7 0 00-2.408-.809l-1.688.965a1.133 1.133 0 01-1.509-.362l-2.885-4.389A3.4 3.4 0 005.532 9.75H1a1 1 0 00-1 1v11.5a1 1 0 001 1zM24 11.25v-8a1 1 0 00-.359-.768.982.982 0 00-.82-.215l-5.321.967-5.321-.967A1 1 0 0011 3.25v8a1 1 0 00.821.983l5.5 1a.951.951 0 00.358 0l5.5-1A1 1 0 0024 11.25zm-7.5-.5a.25.25 0 01-.3.246l-3-.546a.249.249 0 01-.2-.244V4.748a.25.25 0 01.3-.248l3 .546a.249.249 0 01.2.246zm5.5-.546a.249.249 0 01-.2.246l-3 .55a.25.25 0 01-.3-.246v-5.46a.249.249 0 01.2-.246l3-.546a.25.25 0 01.3.246z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});