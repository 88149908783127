define("ember-svg-jar/inlined/chair-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chair-2</title><path d=\"M18.74.58A34.76 34.76 0 0012.01 0C8.09 0 1.5.42 1.5 3.25c0 2.21 1.29 3 2.5 3a1 1 0 000-2c-.19 0-.48 0-.5-.91a6.041 6.041 0 012.579-.871.248.248 0 01.213.059.251.251 0 01.083.206l-.649 8.4a.25.25 0 00.364.242 8.024 8.024 0 011.353-.541.5.5 0 00.353-.44l.263-3.456A.5.5 0 018.5 6.48a30.017 30.017 0 017 0 .5.5 0 01.441.458l.263 3.456a.5.5 0 00.353.44 8.024 8.024 0 011.353.541.25.25 0 00.364-.242l-.649-8.4a.251.251 0 01.083-.206.247.247 0 01.213-.059 5.974 5.974 0 012.579.872c-.02.91-.31.91-.5.91a1 1 0 000 2c1.21 0 2.5-.79 2.5-3 0-1.87-2.83-2.469-3.76-2.67zM18.5 13.248h-.267a.5.5 0 01-.306-.1c-.759-.589-2.433-1.4-5.927-1.4s-5.168.806-5.927 1.4a.5.5 0 01-.306.1H5.5a1.5 1.5 0 00-1.5 1.5v1A1.5 1.5 0 005.125 17.2a.5.5 0 01.375.484v1.078a8.5 8.5 0 01-.895 3.789 1 1 0 00.448 1.341A.979.979 0 005.5 24a1 1 0 00.9-.554 10.523 10.523 0 001.1-4.683v-1.015a.5.5 0 01.5-.5h8a.5.5 0 01.5.5v1.015a10.523 10.523 0 001.105 4.683 1 1 0 00.9.554.979.979 0 00.446-.107 1 1 0 00.448-1.341 8.5 8.5 0 01-.895-3.789v-1.078a.5.5 0 01.375-.484A1.5 1.5 0 0020 15.748v-1a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});