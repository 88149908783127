define("ember-svg-jar/inlined/developer-community-github", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>developer-community-github</title><path d=\"M22.13 9.48c.52-1.06 2.21-5.1-.86-7.61a.5.5 0 00-.27-.12c-2 0-4.49 2.45-5.26 3.25A14.61 14.61 0 008.3 5C7.51 4.19 5 1.75 3.08 1.75a.5.5 0 00-.32.11C.12 4 .92 7.52 1.89 9.44a9.41 9.41 0 00-1.31 5.8c.26 3.31 3 7 6.48 7h9.89c3.5 0 6.23-3.69 6.48-7a9.4 9.4 0 00-1.3-5.76zM16 19.75H8a3.5 3.5 0 01-3.5-3.5c0-5.91 4.13-3.53 7.5-3.53s7.5-2.44 7.5 3.53a3.5 3.5 0 01-3.5 3.5z\"/><ellipse cx=\"15.52\" cy=\"16.25\" rx=\"1\" ry=\"1.5\"/><ellipse cx=\"8.52\" cy=\"16.25\" rx=\"1\" ry=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});