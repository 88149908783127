define("ember-svg-jar/inlined/hospital-bedroom-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hospital-bedroom-graph</title><path d=\"M20.207 6L18.8 7.409a1 1 0 01-1.555-.178l-1.589-2.539a.25.25 0 00-.412-.017l-.693.925a1.008 1.008 0 01-.8.4h-2.5a.25.25 0 00-.25.25V10a1 1 0 001 1H23a1 1 0 001-1V6.25a.25.25 0 00-.25-.25z\"/><path d=\"M13.75 5l.912-1.217a1.064 1.064 0 01.844-.4 1 1 0 01.8.469l1.613 2.583a.254.254 0 00.184.116.252.252 0 00.2-.072L19.5 5.293A.991.991 0 0120.207 5h3.543a.25.25 0 00.25-.25V1a1 1 0 00-1-1H12a1 1 0 00-1 1v3.75a.25.25 0 00.25.25z\"/><circle cx=\"6\" cy=\"15.5\" r=\"2.5\"/><path d=\"M23 15a1 1 0 00-1 1v3.25H2V13.5a1 1 0 00-2 0V23a1 1 0 002 0v-.75h20V23a1 1 0 002 0v-7a1 1 0 00-1-1z\"/><path d=\"M10.5 18H20a.5.5 0 00.5-.5v-1A2.5 2.5 0 0018 14h-7a1 1 0 00-1 1v2.5a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});