define("ember-svg-jar/inlined/superscript", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>superscript</title><path d=\"M9.076 16.651a.25.25 0 010-.354l5.591-5.592a1 1 0 00-1.414-1.414l-5.591 5.592a.25.25 0 01-.354 0L1.716 9.291A1 1 0 00.3 10.705L5.894 16.3a.25.25 0 010 .354L.3 22.242a1 1 0 101.414 1.414l5.592-5.591a.25.25 0 01.354 0l5.64 5.64a1 1 0 001.414-1.414zM22.959 10.351H20.41a.25.25 0 01-.2-.394l3.137-4.48a3.472 3.472 0 10-6.3-2.157A1 1 0 0018 4.361a.984.984 0 001.04-.961 1.476 1.476 0 112.656.94l-4.506 6.437a1 1 0 00.819 1.574h4.95a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});