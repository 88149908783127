define("ember-svg-jar/inlined/keyboard-arrow-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-arrow-left</title><path d=\"M22.5 10.5H7.027a.25.25 0 01-.25-.25V7.222a1 1 0 00-1.707-.707L.292 11.293a1 1 0 000 1.414l4.778 4.778a1 1 0 00.707.293 1 1 0 001-1V13.75a.25.25 0 01.25-.25H22.5a1.5 1.5 0 000-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});