define("ember-svg-jar/inlined/religion-devil-head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-devil-head</title><path d=\"M23.533 10.7a1.5 1.5 0 00-2.286-1.437l-1.909 1.193a7.444 7.444 0 00-.421-1.481 6.188 6.188 0 00-.02-7.922.749.749 0 00-1.35.419 5.918 5.918 0 01-2.029 3.686 7.472 7.472 0 00-7.064-.027 5.881 5.881 0 01-2-3.66.756.756 0 00-.529-.69.747.747 0 00-.825.269 6.189 6.189 0 00-.075 7.85 7.508 7.508 0 00-.439 1.5L2.753 9.259A1.5 1.5 0 00.467 10.7 7.436 7.436 0 004.5 16.539a7.5 7.5 0 0014.908.053 7.473 7.473 0 004.125-5.892zm-7.875 7.291c-.434.827-1.42 1.766-3.658 1.766s-3.223-.939-3.658-1.766a.5.5 0 01.62-.7 8.591 8.591 0 003.038.462 8.591 8.591 0 003.038-.466.5.5 0 01.62.7zm-8.609-6.3l.348-.3a1.749 1.749 0 012.653.421l.635 1.063a.749.749 0 01-.921 1.081l-2.5-1a.75.75 0 01-.215-1.261zm7.271 2.261a.75.75 0 01-.922-1.081l.636-1.063a1.748 1.748 0 012.653-.42l.348.3a.75.75 0 01-.215 1.261z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});