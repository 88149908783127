define("ember-svg-jar/inlined/flag-cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flag-cash</title><path d=\"M23.382 2.174a1.586 1.586 0 00-1.616-.1 12.136 12.136 0 01-4.126.885 18.636 18.636 0 01-4.74-.979C9.543 1 8.38.768 5.806 1.1A1.5 1.5 0 004.5 2.59v15.02a1.5 1.5 0 001.672 1.49c2.255-.259 3.305-.039 6.442.879A19.314 19.314 0 0017.64 21a14.09 14.09 0 005.08-1.152c.672-.256 1.28-.6 1.28-1.528V3.4a1.5 1.5 0 00-.618-1.226zm-7.757 12.3V15a.75.75 0 01-1.5 0v-.5h-.75a.75.75 0 010-1.5h2.033a.593.593 0 00.592-.592.587.587 0 00-.372-.548l-2.064-.826a2.085 2.085 0 01.561-4.012V6.5a.75.75 0 011.5 0V7h.75a.75.75 0 010 1.5h-2.033a.592.592 0 00-.22 1.142l2.062.825a2.084 2.084 0 01-.559 4.011zM1.5 0A1.5 1.5 0 000 1.5v21a1.5 1.5 0 003 0v-21A1.5 1.5 0 001.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});