define("ember-svg-jar/inlined/remove-square-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>remove-square-alternate</title><path d=\"M22 0H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zm-.25 22H2.249a.25.25 0 01-.25-.25V2.249A.25.25 0 012.249 2h19.5a.25.25 0 01.25.25v19.5a.25.25 0 01-.25.25z\"/><path d=\"M6.115 17.883a1.251 1.251 0 001.768 0L12 13.767l4.116 4.116a1.25 1.25 0 001.768-1.768L13.767 12l4.116-4.116a1.25 1.25 0 00-1.768-1.768L12 10.232 7.883 6.115a1.25 1.25 0 00-1.768 1.768L10.232 12l-4.117 4.115a1.251 1.251 0 000 1.768z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});