define("ember-svg-jar/inlined/peacock-head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>peacock-head</title><path d=\"M16.875 8.5c-.339 0-.832-.5-1.309-.99a5.147 5.147 0 00-3.691-2.01 9.336 9.336 0 00-1.75.141V2.328A1.5 1.5 0 008.875 0a8.009 8.009 0 00-8 8 1.5 1.5 0 002.234 1.308h3.67a11.115 11.115 0 00-.154 1.941c0 8.506-1.879 10.828-3.456 11.279A.75.75 0 003.375 24h9a.75.75 0 00.75-.75v-4.5c0-1.729.365-5.75 3.75-5.75a12.8 12.8 0 015.165.921.758.758 0 001.085-.671c0-3.757-4.088-4.75-6.25-4.75zm-2.5 1a1 1 0 11-1-1 1 1 0 011 1zM6.119 3.83a4.974 4.974 0 012.006-.774v2.78zm-2.2 3.479a4.971 4.971 0 01.782-2.064l2.068 2.064z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});