define("ember-svg-jar/inlined/disability-hearing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-hearing</title><path d=\"M16.888 10.636A6.451 6.451 0 004.45 9.415a1 1 0 101.879.685 4.461 4.461 0 013.885-2.919 4.538 4.538 0 014.7 3.757 4.41 4.41 0 01-1.633 4.162 4.334 4.334 0 00-1.612 3.93 2.572 2.572 0 01-2.875 2.8 2.487 2.487 0 01-1.156-.444 1 1 0 00-1.152 1.634 4.485 4.485 0 002.09.8 4.569 4.569 0 005.075-5.055 2.29 2.29 0 01.874-2.092 6.47 6.47 0 002.363-6.037zM20.216 7.5a5.845 5.845 0 00-4.263-4.264 1 1 0 00-.485 1.942 3.846 3.846 0 012.807 2.806 1 1 0 00.97.758 1.068 1.068 0 00.243-.029 1 1 0 00.728-1.213z\"/><path d=\"M16.876.186a1 1 0 10-.484 1.94 6.764 6.764 0 014.93 4.929 1 1 0 00.968.758 1.019 1.019 0 00.244-.03 1 1 0 00.727-1.212A8.755 8.755 0 0016.876.186zM9.275 10.989a1.5 1.5 0 012.252.528 1.445 1.445 0 01.137.648 1 1 0 00.973 1.026h.027a1 1 0 001-.973 3.41 3.41 0 00-.328-1.552 3.492 3.492 0 00-5.284-1.259 1 1 0 001.223 1.582zM1 17.882A1 1 0 001 19.3l1.151 1.15a1 1 0 101.414-1.414l-1.151-1.15A1 1 0 001 17.882zM5.85 13.032a1 1 0 000 1.414L7 15.6a1 1 0 101.414-1.414l-1.15-1.154a1 1 0 00-1.414 0zM2.275 14.306a1 1 0 000 1.414l3.452 3.453a1 1 0 101.414-1.414l-3.452-3.453a1 1 0 00-1.414 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});