define("ember-svg-jar/inlined/board-game-dice-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>board-game-dice-1</title><path d=\"M21.906 3.461L12.8.123a2.686 2.686 0 00-1.633.008l-9.073 3.33a.545.545 0 00-.012 1.017L11.8 8.384a.55.55 0 00.407 0l9.715-3.906a.544.544 0 00-.016-1.017zM12 5.454a1.091 1.091 0 111.091-1.091A1.09 1.09 0 0112 5.454zM23.251 5.582L13.16 9.639a.545.545 0 00-.342.506v13.306a.542.542 0 00.756.5c2.4-1.038 9.67-4.206 9.67-4.206A1.26 1.26 0 0024 18.594V6.088a.546.546 0 00-.749-.506zm-6.887 13.931a1.091 1.091 0 111.091-1.091 1.09 1.09 0 01-1.091 1.091zm2.181-3.813a1.091 1.091 0 111.091-1.1 1.09 1.09 0 01-1.091 1.1zm2.182-3.818a1.091 1.091 0 111.091-1.091 1.091 1.091 0 01-1.091 1.086zM10.84 9.639L.749 5.582A.545.545 0 000 6.088v12.506a1.26 1.26 0 00.756 1.155s7.273 3.168 9.67 4.206a.542.542 0 00.756-.5v-13.31a.547.547 0 00-.342-.506zm-7.022 3.874a1.091 1.091 0 111.091-1.091 1.091 1.091 0 01-1.091 1.091zm3.273 4.909a1.091 1.091 0 111.091-1.091 1.09 1.09 0 01-1.091 1.091z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});