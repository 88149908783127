define("ember-svg-jar/inlined/professional-tool-mobile-me", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professional-tool-mobile-me</title><path d=\"M18.68 20.46H5.32A5.36 5.36 0 013.61 10a3.76 3.76 0 01.88-2.13 3.8 3.8 0 014.18-1.04 5.62 5.62 0 015.43-3.29c4.28 0 6.31 3.33 6.45 6.55a5.36 5.36 0 01-1.87 10.36zM7.33 8.58A1.73 1.73 0 006 9.2a1.77 1.77 0 00-.39 1.44 1 1 0 01-.81 1.16 3.35 3.35 0 00.52 6.66h13.36a3.35 3.35 0 00.67-6.64 1 1 0 01-.79-1.05v-.3c0-2.27-1.17-4.92-4.46-4.92-2.28 0-3.2 1.09-4.08 3.09A1 1 0 018.45 9a1.71 1.71 0 00-1.12-.42z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});