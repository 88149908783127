define("ember-svg-jar/inlined/cash-user-man-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-user-man-message</title><path d=\"M24 1.5A1.5 1.5 0 0022.5 0h-10A1.5 1.5 0 0011 1.5v8a1.5 1.5 0 001.5 1.5h.25a.25.25 0 01.25.25v2.25a.5.5 0 00.291.454.493.493 0 00.209.046.5.5 0 00.325-.12l3.289-2.819a.246.246 0 01.163-.061H22.5A1.5 1.5 0 0024 9.5zm-7.667 3.375h2.334a1.792 1.792 0 010 3.584h-.292a.249.249 0 00-.25.25V9a.625.625 0 01-1.25 0v-.291a.249.249 0 00-.25-.25h-1.458a.625.625 0 110-1.25h3.5a.542.542 0 000-1.084h-2.334a1.792 1.792 0 010-3.583h.292a.25.25 0 00.25-.25V2a.625.625 0 011.25 0v.292a.25.25 0 00.25.25h1.458a.625.625 0 010 1.25h-3.5a.542.542 0 000 1.083zM.5 20.5h1.85a.251.251 0 01.247.215l.408 2.856A.5.5 0 003.5 24h4.091a.5.5 0 00.5-.441l.331-2.837a.252.252 0 01.249-.222H10.5a.5.5 0 00.5-.5v-3a5.51 5.51 0 00-3.409-5.087.247.247 0 01-.154-.216.251.251 0 01.127-.233 4.25 4.25 0 10-4.128 0 .249.249 0 01.127.233.247.247 0 01-.154.216A5.51 5.51 0 000 17v3a.5.5 0 00.5.5zm5.75-3.125a.75.75 0 01-1.5 0v-3a.75.75 0 011.5 0zm-3-9.625a2.234 2.234 0 01.106-.683.255.255 0 01.152-.159.252.252 0 01.219.024 5.377 5.377 0 002.856.818 5.347 5.347 0 001.043-.1.106.106 0 01.086.022.1.1 0 01.038.08 2.25 2.25 0 01-4.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});