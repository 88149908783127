define("ember-svg-jar/inlined/direction-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>direction-button</title><circle cx=\"12\" cy=\"11.998\" r=\"3\"/><path d=\"M5 7.814a1.249 1.249 0 00-1.767 0L.67 10.379a2.3 2.3 0 000 3.239l2.567 2.565A1.25 1.25 0 105 14.415l-2.059-2.063a.5.5 0 010-.707L5 9.581a1.248 1.248 0 000-1.767zM13.619.668a2.294 2.294 0 00-3.238 0L7.815 3.235A1.25 1.25 0 009.583 5l2.063-2.064a.5.5 0 01.708 0L14.417 5a1.25 1.25 0 001.768-1.768zM23.33 10.379l-2.567-2.565A1.249 1.249 0 0019 9.581l2.063 2.064a.5.5 0 010 .707L19 14.415a1.25 1.25 0 001.767 1.768l2.567-2.565a2.3 2.3 0 00-.004-3.239zM14.417 18.994l-2.063 2.064a.5.5 0 01-.708 0l-2.063-2.064a1.25 1.25 0 00-1.768 1.768l2.566 2.567a2.294 2.294 0 003.238 0l2.566-2.567a1.25 1.25 0 00-1.768-1.768z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});