define("ember-svg-jar/inlined/shipment-lift", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-lift</title><path d=\"M23.717 5.8L18.58.51a1.9 1.9 0 00-1.091-.491h-.016a1.7 1.7 0 00-.725.067L1.169 5.551A1 1 0 001.5 7.494h3.75a.25.25 0 01.25.25v4.566a.248.248 0 01-.1.2l-4.014 3.122C.642 16.211 0 16.545 0 17.422v5a1.5 1.5 0 001.5 1.5h10a1.5 1.5 0 001.5-1.5v-5c0-.877-.642-1.211-1.386-1.79L7.6 12.507a.249.249 0 01-.1-.2V7.744a.25.25 0 01.25-.25h4.1a.249.249 0 01.243.194 4.952 4.952 0 004.2 3.626.25.25 0 01.2.245v11.435a1 1 0 002 0V7.744a.25.25 0 01.25-.25H23a1 1 0 00.717-1.7zM9.75 18.422v3a.75.75 0 01-1.5 0v-3a.75.75 0 011.5 0zm-5 0v3a.75.75 0 01-1.5 0v-3a.75.75 0 011.5 0zm.25-2.5a.25.25 0 01-.154-.448l1.5-1.166a.251.251 0 01.307 0l1.5 1.166a.25.25 0 01-.153.448zM16.167 2.407a.25.25 0 01.333.236v2.6a.25.25 0 01-.25.25H8.837a.25.25 0 01-.083-.486zm.333 6.629a.218.218 0 01-.279.21 3.054 3.054 0 01-1.931-1.379.25.25 0 01.218-.373h1.742a.25.25 0 01.25.25zm2.25-3.542a.25.25 0 01-.25-.25v-1.33a.25.25 0 01.429-.174l1.293 1.33a.25.25 0 01-.179.424z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});