define("ember-svg-jar/inlined/road-sign-obstruction-horizontal-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-obstruction-horizontal-alternate</title><path d=\"M24 7.5a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h22a1 1 0 001-1zm-2 3.491a.176.176 0 01-.3.125l-2.19-2.189a.25.25 0 01.177-.427h2.063a.25.25 0 01.25.25zM15.164 15.5a.25.25 0 01-.177-.427l2.19-2.189a1.252 1.252 0 000-1.768l-2.19-2.189a.25.25 0 01.177-.427h1.693a.251.251 0 01.177.073l3.25 3.25a.252.252 0 010 .354l-3.25 3.25a.251.251 0 01-.177.073zm-3.5 0a.251.251 0 01-.177-.073l-3.25-3.25a.252.252 0 010-.354l3.25-3.25a.251.251 0 01.177-.073h.672a.251.251 0 01.177.073l3.25 3.25a.252.252 0 010 .354l-3.25 3.25a.251.251 0 01-.177.073zm-4.686 0a.251.251 0 01-.177-.073l-3.25-3.25a.252.252 0 010-.354L6.8 8.573a.251.251 0 01.178-.073h1.858a.25.25 0 01.177.427l-2.19 2.189a1.252 1.252 0 000 1.768l2.19 2.189a.25.25 0 01-.177.427zm-2.828-7a.25.25 0 01.177.427l-2.19 2.189a.08.08 0 01-.087.018.082.082 0 01-.05-.075V8.75a.25.25 0 01.25-.25zM2 12.941a.082.082 0 01.05-.075.08.08 0 01.087.018l2.19 2.189a.25.25 0 01-.177.427h-1.9a.25.25 0 01-.25-.25zM19.685 15.5a.25.25 0 01-.177-.427l2.19-2.189a.176.176 0 01.3.125v2.241a.25.25 0 01-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});