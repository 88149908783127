define("ember-svg-jar/inlined/weather-app-rain-umbrella", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-app-rain-umbrella</title><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 21a1 1 0 01-1 1H7a1 1 0 01-1-1V3a1 1 0 011-1h10a1 1 0 011 1z\"/><path d=\"M14 19h-4a.75.75 0 000 1.5h4a.75.75 0 000-1.5zM14 13.5a.75.75 0 00-.75.75c0 .276-.5.276-.5 0v-2a.25.25 0 00-.25-.25h-1a.25.25 0 00-.25.25v2a1.75 1.75 0 003.5 0 .75.75 0 00-.75-.75zM11.25 5.25v.1a.25.25 0 01-.2.245A5.009 5.009 0 007 10.5a.5.5 0 00.5.5h9a.5.5 0 00.5-.5 5.009 5.009 0 00-4.047-4.909.25.25 0 01-.2-.245v-.1a.75.75 0 00-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});