define("ember-svg-jar/inlined/arrow-rectangle-right-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-rectangle-right-2</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM12.344 11.831L7.275 6.343a.5.5 0 01.367-.843h3.151a.5.5 0 01.353.146l6 6a.5.5 0 010 .706l-5.955 6a.5.5 0 01-.355.148h-3.2a.5.5 0 01-.368-.839l5.07-5.495a.251.251 0 00.006-.335z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});