define("ember-svg-jar/inlined/badminton-shuttlecock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>badminton-shuttlecock</title><path d=\"M2.419 16.707a.5.5 0 00-.707 0l-.557.558a3.944 3.944 0 005.577 5.579l.559-.557a.5.5 0 000-.708zM22.7 9.4l-1.6-.346a.5.5 0 00-.434.112L8.824 19.531a.5.5 0 00-.024.729l.249.25a.5.5 0 00.532.113l13.063-4.98a1.637 1.637 0 001.056-1.384l.293-3.094A1.647 1.647 0 0022.7 9.4zM19.814 7.566l-.42-1.745A1.643 1.643 0 0018.177 4.6l-2.14-.514a.5.5 0 00-.507.174L5.709 16.54a.5.5 0 00.036.666l1.283 1.283a.5.5 0 00.683.023L19.657 8.059a.5.5 0 00.157-.493zM3.967 15.427a.5.5 0 00.744-.041L14.745 2.843a.5.5 0 00.1-.417L14.6 1.3A1.648 1.648 0 0012.834.007L9.74.3a1.638 1.638 0 00-1.385 1.055l-4.98 13.062a.5.5 0 00.114.532z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});