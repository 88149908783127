define("ember-svg-jar/inlined/tic-tac-toe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tic-tac-toe</title><path d=\"M11 11.25a.25.25 0 00.25-.25V.25A.25.25 0 0011 0H2.5A2.5 2.5 0 000 2.5V11a.25.25 0 00.25.25zM2.97 4.03a.75.75 0 111.06-1.06l1.512 1.511a.25.25 0 00.354 0L7.407 2.97a.75.75 0 111.061 1.06L6.956 5.542a.25.25 0 000 .354l1.512 1.511a.75.75 0 01-1.061 1.061L5.9 6.956a.25.25 0 00-.354 0L4.03 8.468a.75.75 0 01-1.06-1.061L4.481 5.9a.25.25 0 000-.354zM12.75 11a.25.25 0 00.25.25h10.75A.25.25 0 0024 11V2.5A2.5 2.5 0 0021.5 0H13a.25.25 0 00-.25.25zM13 12.75a.25.25 0 00-.25.25v10.75A.25.25 0 0013 24h8.5a2.5 2.5 0 002.5-2.5V13a.25.25 0 00-.25-.25zm5.25 8.75A2.75 2.75 0 1121 18.75a2.75 2.75 0 01-2.75 2.75zM11.25 13a.25.25 0 00-.25-.25H.25A.25.25 0 000 13v8.5A2.5 2.5 0 002.5 24H11a.25.25 0 00.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});