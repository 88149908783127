define("ember-svg-jar/inlined/content-notebook-pencil-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-notebook-pencil-alternate</title><path d=\"M19.75 6h3.5a.5.5 0 00.475-.658l-1.75-5a.5.5 0 00-.95 0l-1.75 5A.5.5 0 0019.75 6zM16 2H1.5A1.5 1.5 0 000 3.5v17A1.5 1.5 0 001.5 22H16a1 1 0 001-1V3a1 1 0 00-1-1zm-1.25 18h-11a.25.25 0 01-.25-.25V4.25A.25.25 0 013.751 4h11a.25.25 0 01.25.25v15.5a.25.25 0 01-.251.25z\"/><rect x=\"6.501\" y=\"6\" width=\"5.5\" height=\"4\" rx=\".5\" ry=\".5\"/><rect x=\"19\" y=\"7\" width=\"5\" height=\"12.5\" rx=\".5\" ry=\".5\"/><path d=\"M23.5 20.5h-4a.5.5 0 00-.5.5v.5a2.5 2.5 0 105 0V21a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});