define("ember-svg-jar/inlined/shield-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shield-settings</title><path d=\"M21.11 2.369A22.143 22.143 0 0012 .5a22.143 22.143 0 00-9.11 1.869A1.537 1.537 0 002 3.775v7.637A12.285 12.285 0 009.828 22.88l1.067.414a3.063 3.063 0 002.21 0l1.067-.414A12.285 12.285 0 0022 11.412V3.775a1.537 1.537 0 00-.89-1.406zm-4.888 9.38l.945 1.019A1.227 1.227 0 0116 14.8l-1.35-.313a.911.911 0 00-1.076.625l-.405 1.331a1.22 1.22 0 01-2.336 0l-.405-1.331a.911.911 0 00-1.076-.625L8 14.8a1.227 1.227 0 01-1.168-2.033l.945-1.019a.919.919 0 000-1.248l-.944-1.018A1.227 1.227 0 018 7.449l1.35.313a.911.911 0 001.076-.625l.405-1.331a1.22 1.22 0 012.336 0l.405 1.331a.911.911 0 001.076.625L16 7.449a1.227 1.227 0 011.168 2.033l-.946 1.018a.919.919 0 000 1.249z\"/><circle cx=\"12\" cy=\"11.125\" r=\"1.375\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});