define("ember-svg-jar/inlined/style-three-pin-bill-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-bill_1</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm5.354 7.146a.208.208 0 01.044.226.206.206 0 01-.191.128H15a.5.5 0 01-.5-.5V4.793a.206.206 0 01.128-.191.208.208 0 01.226.044zM17.5 15.5a1 1 0 01-1 1h-8a1 1 0 01-1-1v-10a1 1 0 011-1h4.75a.25.25 0 01.25.25V7A1.5 1.5 0 0015 8.5h2.25a.25.25 0 01.25.25z\"/><path d=\"M11.342 10.21H13a.5.5 0 000-1h-.45a.249.249 0 01-.25-.25v-.45a.5.5 0 00-1 0V9a.249.249 0 01-.2.245 1.236 1.236 0 00-.313 2.32l1.58.791a.241.241 0 01-.107.457H10.6a.5.5 0 000 1h.45a.25.25 0 01.25.25v.45a.5.5 0 101 0v-.486a.25.25 0 01.2-.245 1.236 1.236 0 00.312-2.321l-1.58-.79a.238.238 0 01-.133-.216.242.242 0 01.243-.245z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});