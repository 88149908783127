define("ember-svg-jar/inlined/single-man-actions-cart.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-cart</title><path d=\"M7.066 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.743a.5.5 0 01.741-.3A6.959 6.959 0 008.526 5a6.319 6.319 0 00.926-.07.5.5 0 01.569.565A3 3 0 014.066 5a2.841 2.841 0 01.1-.743zM10.874 15.126a2 2 0 01.273-1.8 1.94 1.94 0 01.536-.506.5.5 0 00.029-.823 7.444 7.444 0 00-4.44-1.497 7.544 7.544 0 00-7.185 5.357.5.5 0 00.479.643H10.64a.5.5 0 00.474-.659zM15.539 23.989a1.423 1.423 0 10-1.423-1.423 1.424 1.424 0 001.423 1.423zM20.664 22.577A1.423 1.423 0 1019.242 24a1.424 1.424 0 001.422-1.423zM23.421 10.789a.747.747 0 00-.948.474l-.795 2.384a.5.5 0 01-.475.342h-8.434a.5.5 0 00-.474.659l1.847 5.5a.5.5 0 00.474.341h6.231a.5.5 0 00.481-.365l2.572-8.387a.749.749 0 00-.479-.948z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});