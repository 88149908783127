define("ember-svg-jar/inlined/clean-bicycle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>clean-bicycle</title><path d=\"M19.25 14.5h-.189a.5.5 0 01-.493-.338l-1.093-3.195a.5.5 0 01.352-.647l.416-.1a1 1 0 00-.486-1.942l-2 .5a1 1 0 00-.7 1.3l.234.685a.5.5 0 01-.335.643L10.427 12.7a.405.405 0 01-.485-.233.334.334 0 01.308-.467 1 1 0 000-2H7a1 1 0 000 2h.25a.5.5 0 01.462.307l.5 1.2a.5.5 0 01-.09.528l-.737.815a.5.5 0 01-.588.115 4.755 4.755 0 102.013 1.822.5.5 0 01.056-.595l.992-1.1a.992.992 0 01.467-.291l5.325-1.521a.5.5 0 01.611.319l.415 1.214a.5.5 0 01-.183.568 4.748 4.748 0 102.757-.881zM4.75 22a2.75 2.75 0 11.578-5.439.25.25 0 01.133.412l-1.453 1.606a1 1 0 001.484 1.342l1.452-1.605a.25.25 0 01.423.09A2.749 2.749 0 014.75 22zm14.5 0a2.748 2.748 0 01-2.071-4.557.25.25 0 01.425.084l.7 2.047a1 1 0 00.946.676 1.024 1.024 0 00.324-.054 1 1 0 00.622-1.27l-.7-2.048a.25.25 0 01.284-.327A2.75 2.75 0 0119.25 22zM1 7.5h2.274a.5.5 0 01.49.6l-.676 3.3a.5.5 0 00.872.424l4.8-5.678A1 1 0 008 4.5H5.726a.5.5 0 01-.49-.6L5.912.6A.5.5 0 005.04.177L.236 5.854A1 1 0 001 7.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});