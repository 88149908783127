define("ember-svg-jar/inlined/family-baby-change-diaper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-baby-change-diaper</title><circle cx=\"13.107\" cy=\"3\" r=\"2.5\"/><path d=\"M.137 14.829a.5.5 0 00.47.671h1.037a.25.25 0 01.25.25V22a1.5 1.5 0 003 0v-6.25a.249.249 0 01.25-.25h1.48a.5.5 0 00.457-.7l-1.414-3.184a1 1 0 01.174-1.078l1.28-1.408a.25.25 0 01.431.123v.01A4.472 4.472 0 0011.1 12.87c.119.024 1.228.133 1.324.133a1.5 1.5 0 001.466-1.624A1.562 1.562 0 0012.455 10l-.828-.084a1.491 1.491 0 01-1.122-1.188l-.636-3.5a1.5 1.5 0 00-2.586-.74L2.5 9.382a4.01 4.01 0 00-.9 1.43zM23.893 22.5a1 1 0 00-1-1h-10a1 1 0 000 2h10a1 1 0 001-1zM16.607 20.5a2 2 0 002-2V16a1 1 0 00-2 0v2.25a.25.25 0 01-.25.25h-1.225a.248.248 0 01-.245-.3l.069-.347A1.992 1.992 0 0014.1 15.8l-.942-.629a1 1 0 00-1.11 1.664l.952.625-.129.648a2 2 0 001.956 2.392z\"/><circle cx=\"21.393\" cy=\"18.75\" r=\"1.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});