define("ember-svg-jar/inlined/heavy-equipment-cleaner-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>heavy-equipment-cleaner-1</title><path d=\"M10.5 16h11a3.972 3.972 0 012.117.607.25.25 0 00.383-.215V8.99a1.069 1.069 0 00-.022-.2l-1.291-6.026A2.306 2.306 0 0020.5 1H16a3.816 3.816 0 00-3.511 2.648l-1.4 4.89a3.427 3.427 0 00-1.658.8L7.511 11.2A1.842 1.842 0 007 12.409v.338H5.5V11.4a14.051 14.051 0 00-2.848-7.863 1.5 1.5 0 10-2.3 1.919A11.164 11.164 0 012.5 11.4v3.189a11.164 11.164 0 01-2.152 5.948 1.5 1.5 0 102.3 1.919 14.308 14.308 0 002.769-6.709H7v1.514a.25.25 0 00.441.161A3.992 3.992 0 0110.5 16zm9.143-13a.925.925 0 01.864.7l.792 3.7a.5.5 0 01-.489.6h-6.576a.5.5 0 01-.48-.638l.854-2.989A2.009 2.009 0 0116.429 3z\"/><path d=\"M21.5 17.5h-11a2.5 2.5 0 000 5h11a2.5 2.5 0 000-5zm-11 2.5a1 1 0 111 1 1 1 0 01-1-1zm4.5 0a1 1 0 111 1 1 1 0 01-1-1zm4.5 0a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});