define("ember-svg-jar/inlined/trees-log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trees-log</title><circle cx=\"8\" cy=\"7\" r=\"1.25\"/><path d=\"M8 11.5h9a4.5 4.5 0 000-9H8a4.5 4.5 0 000 9zM17.5 7a.5.5 0 010 1h-1a.5.5 0 010-1zm-4-2h3a.5.5 0 010 1h-3a.5.5 0 010-1zm0 3h1a.5.5 0 010 1h-1a.5.5 0 010-1zM8 4.25A2.75 2.75 0 115.25 7 2.752 2.752 0 018 4.25z\"/><circle cx=\"4.5\" cy=\"17\" r=\"1.25\"/><circle cx=\"11.25\" cy=\"17\" r=\"1.25\"/><path d=\"M19.5 12.5h-15a4.5 4.5 0 000 9h15a4.5 4.5 0 000-9zm-15 7.25A2.75 2.75 0 117.25 17a2.752 2.752 0 01-2.75 2.75zm6.75 0A2.75 2.75 0 1114 17a2.752 2.752 0 01-2.75 2.75zM18 19h-1.5a.5.5 0 010-1H18a.5.5 0 010 1zm-1.5-3a.5.5 0 010-1H20a.5.5 0 010 1zm4.5 2h-1a.5.5 0 010-1h1a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});