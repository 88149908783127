define("ember-svg-jar/inlined/police-man-3.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-man-3</title><path d=\"M22.248 19.936a7.1 7.1 0 00-3.056-1.157c-.8-.167-.4-.073-4.416-1.089a6.3 6.3 0 01-2.8.81 6.287 6.287 0 01-2.776-.8c-3.956 1-3.588.916-4.389 1.082a7.107 7.107 0 00-3.057 1.157A4.529 4.529 0 000 23.5a.5.5 0 00.5.5h23a.5.5 0 00.5-.5 4.529 4.529 0 00-1.752-3.564zm-4.357 3.158A2 2 0 0117 21.43v-.18a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25v.18a2 2 0 01-.891 1.664l-.609.406zM18.955 6a.312.312 0 00.3-.391 7.5 7.5 0 00-14.443-.252.5.5 0 00.48.643zM14 2.93a2.5 2.5 0 01-1.113 2.08l-.61.406a.5.5 0 01-.554 0l-.61-.406A2.5 2.5 0 0110 2.93V2a.5.5 0 01.5-.5h3a.5.5 0 01.5.5zM17.666 12.261a.252.252 0 01.145-.189A2.5 2.5 0 0019.1 10.52a2.71 2.71 0 00-.028-1.794A2.063 2.063 0 0019.5 7.5.5.5 0 0019 7H5a.5.5 0 00-.5.5 2.046 2.046 0 00.4 1.188 2.676 2.676 0 00-.055 1.788 2.506 2.506 0 001.3 1.6.25.25 0 01.144.187c.511 3.048 4.021 4.991 5.686 4.991s5.18-1.947 5.691-4.993zm-9.894-.249a1.747 1.747 0 00-1-1.306 1.04 1.04 0 01-.485-.663 1.605 1.605 0 01-.049-.232 8.817 8.817 0 002.3.829A15.875 15.875 0 0012 11a15.83 15.83 0 003.42-.351 8.923 8.923 0 002.3-.815 1.586 1.586 0 01-.053.233 1.024 1.024 0 01-.476.639 1.746 1.746 0 00-1 1.3c-.378 2.249-3.176 3.739-4.209 3.739s-3.833-1.485-4.21-3.733z\"/><path d=\"M10.47 13.667a3.336 3.336 0 001.535.36 3.251 3.251 0 001.527-.36.75.75 0 10-.719-1.317 1.753 1.753 0 01-.808.177 1.776 1.776 0 01-.816-.178.75.75 0 10-.715 1.318z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});