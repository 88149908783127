define("ember-svg-jar/inlined/party-decoration-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>party-decoration-1</title><path d=\"M5.25 11a.75.75 0 00-.75.75V12a.5.5 0 01-.5.5h-.25a.75.75 0 000 1.5H4a.5.5 0 01.5.5v.25a.75.75 0 001.5 0v-.25a.5.5 0 01.5-.5h.25a.75.75 0 000-1.5H6.5A.5.5 0 016 12v-.25a.75.75 0 00-.75-.75zM21.75 20h-.25a.5.5 0 01-.5-.5v-.25a.75.75 0 00-1.5 0v.25a.5.5 0 01-.5.5h-.25a.75.75 0 000 1.5H19a.5.5 0 01.5.5v.25a.75.75 0 001.5 0V22a.5.5 0 01.5-.5h.25a.75.75 0 000-1.5zM15.25 3.5H16a.5.5 0 01.5.5v.75a.75.75 0 001.5 0V4a.5.5 0 01.5-.5h.75a.75.75 0 000-1.5h-.75a.5.5 0 01-.5-.5V.75a.75.75 0 00-1.5 0v.75a.5.5 0 01-.5.5h-.75a.75.75 0 000 1.5z\"/><path d=\"M18.9 15.677l3.6-3.6a.711.711 0 01.5-.208 1 1 0 00.142-1.99C11.079 8.143 1.74.316 1.648.237A1 1 0 00.353 1.762c.108.092.886.748 2.221 1.691A1 1 0 013 4.27v4.8a1 1 0 001.552.83L8.4 7.331a.545.545 0 01.553-.031.5.5 0 01.265.349l1.116 5.583a.986.986 0 00.667.754.962.962 0 00.312.051 1.006 1.006 0 00.674-.26l3.781-3.437a.324.324 0 01.318-.068.282.282 0 01.191.213l.936 4.682a1 1 0 001.687.511z\"/><path d=\"M14.7 14.869a42.716 42.716 0 01-13.6 2.378c-.032 0-.068-.015-.095 0a1 1 0 000 2 .248.248 0 01.2.1L4.359 23.6a1 1 0 00.8.4 1.081 1.081 0 00.15-.011 1 1 0 00.793-.653L7.7 18.86a.329.329 0 01.253-.212.282.282 0 01.266.1l3.26 3.971a.991.991 0 00.769.366.95.95 0 00.175-.016 1 1 0 00.771-.649l1.952-5.465a.311.311 0 01.2-.191 1 1 0 00-.639-1.895z\"/><circle cx=\"21.5\" cy=\"7\" r=\"1\"/><circle cx=\"23\" cy=\"17\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});