define("ember-svg-jar/inlined/show-theater", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>show-theater</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v2a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-2A1.5 1.5 0 0022.5 0z\"/><rect x=\"6.5\" y=\"18.5\" width=\"11\" height=\"2.5\" rx=\".5\" ry=\".5\"/><path d=\"M8.464 5.5h-7.4A1.061 1.061 0 000 6.561v7.4a.532.532 0 00.553.535A9.017 9.017 0 009 6.053a.532.532 0 00-.536-.553zM2.5 18.5h-2a.5.5 0 00-.5.5v4a1 1 0 001 1h3.987a.5.5 0 00.5-.516C5.29 19 2.5 18.5 2.5 18.5zM.5 17.5H3a1 1 0 000-2H.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5zM22.939 5.5h-7.4a.532.532 0 00-.539.553 9.017 9.017 0 008.447 8.447.532.532 0 00.553-.536v-7.4A1.061 1.061 0 0022.939 5.5zM23.5 18.5h-2s-2.79.5-2.989 4.984a.5.5 0 00.5.516H23a1 1 0 001-1v-4a.5.5 0 00-.5-.5zM23.5 15.5H21a1 1 0 000 2h2.5a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});