define("ember-svg-jar/inlined/maps-pin-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>maps-pin-1</title><path d=\"M19 11a5.006 5.006 0 00-5 5c0 3.171 4.457 7.664 4.646 7.854a.5.5 0 00.708 0C19.543 23.664 24 19.171 24 16a5.006 5.006 0 00-5-5zm0 6.75A1.75 1.75 0 1120.75 16 1.752 1.752 0 0119 17.75z\"/><path d=\"M14 9a1 1 0 002 0V3.314a.25.25 0 01.395-.2l4.4 3.14a.5.5 0 01.205.404V9a1 1 0 002 0V6.143a1.506 1.506 0 00-.628-1.221L15.873.28a1.5 1.5 0 00-1.745 0l-5.8 4.144a.5.5 0 01-.6-.011L2.427.321A1.5 1.5 0 000 1.5v14.886a1.508 1.508 0 00.6 1.2l6.507 4.881a1.5 1.5 0 001.771.02l4.375-3.126a1 1 0 00-1.162-1.627L9.4 19.661a.251.251 0 01-.395-.2V6.657a.5.5 0 01.209-.407l4.4-3.139a.25.25 0 01.395.2zM7 19.386a.25.25 0 01-.4.2l-4.4-3.3a.5.5 0 01-.2-.4V3.027a.25.25 0 01.4-.2l4.4 3.4a.5.5 0 01.2.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});