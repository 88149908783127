define("ember-svg-jar/inlined/spa-lotus-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>spa-lotus-1</title><path d=\"M23 16.491a1 1 0 00-1 1c0 2.129-4.107 4.5-10 4.5s-10-2.371-10-4.5a1 1 0 00-2 0c0 3.645 5.271 6.5 12 6.5s12-2.855 12-6.5a1 1 0 00-1-1z\"/><path d=\"M14 16.15a.25.25 0 00.04.487 11.683 11.683 0 001.543.1 8.759 8.759 0 003.688-.755 10.552 10.552 0 004.629-4.94.954.954 0 00-.619-1.335 12.455 12.455 0 00-1.478-.288.5.5 0 00-.537.329 9.326 9.326 0 01-1.255 2.427A11.724 11.724 0 0114 16.15zM4.967 15.986a8.759 8.759 0 003.688.755 11.651 11.651 0 001.205-.064.25.25 0 00.044-.488 11.987 11.987 0 01-6.081-4.014 9.285 9.285 0 01-1.234-2.368.5.5 0 00-.551-.325c-.442.073-.817.155-1.087.225a.955.955 0 00-.619 1.334 10.563 10.563 0 004.635 4.945zM8.978 14.251a.25.25 0 00.3-.369 12.263 12.263 0 01-2.213-6.628 9.476 9.476 0 01.211-1.937.5.5 0 00-.268-.548 12.644 12.644 0 00-2.463-.919.954.954 0 00-1.185.871 10.558 10.558 0 001.672 6.567 9.381 9.381 0 003.946 2.963zM17.171 7.254a12.074 12.074 0 01-2.053 6.373.25.25 0 00.32.36 9.033 9.033 0 003.367-2.7 10.554 10.554 0 001.673-6.567.955.955 0 00-1.186-.87 12.724 12.724 0 00-2.078.737.5.5 0 00-.282.562 9.745 9.745 0 01.239 2.105zM11.368 14.36v4.131a.75.75 0 001.5 0v-4.132a.5.5 0 01.112-.314 11.4 11.4 0 002.691-6.791 11.6 11.6 0 00-2.783-6.9 1.034 1.034 0 00-1.542 0 11.589 11.589 0 00-2.781 6.9 11.4 11.4 0 002.692 6.792.5.5 0 01.111.314z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});