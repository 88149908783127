define("ember-svg-jar/inlined/single-man-actions-lock.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-lock</title><path d=\"M7.044 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.743a.5.5 0 01.741-.3A6.958 6.958 0 008.5 5a6.35 6.35 0 00.929-.07.5.5 0 01.571.564A3 3 0 014.044 5a2.8 2.8 0 01.1-.743zM13.516 14.724a.5.5 0 00.1-.644A7.533 7.533 0 007.25 10.5a7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643H12.1a.5.5 0 00.479-.356 3 3 0 01.937-1.42zM22.5 14.25a3.5 3.5 0 00-7 0v1.018a.249.249 0 01-.214.247A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.286-1.485.249.249 0 01-.214-.247zm-2.5 6a1 1 0 11-1-1 1 1 0 011 1zm-2.25-4.75a.25.25 0 01-.25-.25v-1a1.5 1.5 0 013 0v1a.25.25 0 01-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});