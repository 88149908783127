define("ember-svg-jar/inlined/camera-flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camera-flash</title><path d=\"M11.414 8h3.672a2.475 2.475 0 01.8.134.251.251 0 00.321-.169l.272-.95A.02.02 0 0116.5 7a2 2 0 002-2V2a2 2 0 00-2-2H10a2 2 0 00-2 2v3a2 2 0 002 2 .02.02 0 01.02.015l.272.95a.251.251 0 00.321.169A2.475 2.475 0 0111.414 8zM10 2.5a.5.5 0 01.5-.5H16a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-5.5a.5.5 0 01-.5-.5z\"/><circle cx=\"13.25\" cy=\"17.25\" r=\"3.25\"/><path d=\"M20.5 11h-2.586a.5.5 0 01-.353-.146l-1.415-1.415A1.511 1.511 0 0015.086 9h-3.672a1.511 1.511 0 00-1.06.439l-1.415 1.415a.5.5 0 01-.353.146H3.5A2.5 2.5 0 001 13.5v8A2.5 2.5 0 003.5 24h17a2.5 2.5 0 002.5-2.5v-8a2.5 2.5 0 00-2.5-2.5zM6 14.75a1.25 1.25 0 11-1.25-1.25A1.25 1.25 0 016 14.75zm2 2.5a5.25 5.25 0 115.25 5.25A5.256 5.256 0 018 17.25zM7 9.5A1.5 1.5 0 005.5 8h-1A1.5 1.5 0 003 9.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});