define("ember-svg-jar/inlined/knives-set", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>knives-set</title><path d=\"M12.905 7.011a.5.5 0 00.707 0l4.45-4.45A1.5 1.5 0 0015.94.44l-4.45 4.45a.5.5 0 000 .707zM23.561 6.458a1.5 1.5 0 00-2.121 0l-4.59 4.59a.5.5 0 000 .707l1.414 1.414a.5.5 0 00.707 0l4.59-4.59a1.5 1.5 0 000-2.121zM11.1 11.488a.5.5 0 00.662-.024c.662-.632 1.291-1.235 1.56-1.484a.5.5 0 00.015-.721l-2.818-2.815a.505.505 0 00-.354-.146.5.5 0 00-.354.148L7.462 8.811a.25.25 0 00.069.4 17.624 17.624 0 013.569 2.277zM15.856 12.464a.5.5 0 00-.708 0l-1.23 1.236a.5.5 0 00-.042.658 17.587 17.587 0 012.165 3.634.25.25 0 00.4.082c.951-.886 1.765-1.65 2.217-2.067a.5.5 0 00.161-.358.5.5 0 00-.147-.363zM1.067 9.035a1 1 0 00-1.067 1V23a1 1 0 001 1h13.891a1 1 0 00.991-1.138A16.1 16.1 0 001.067 9.035z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});