define("ember-svg-jar/inlined/file-rtf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-rtf</title><path d=\"M7.375 10.5a.877.877 0 00-.875-.877h-.626a.249.249 0 00-.249.249v1.252a.249.249 0 00.249.249H6.5a.876.876 0 00.875-.873z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM6.618 12.732l1.824 1.825a.625.625 0 01-.884.883s-1.816-1.809-1.845-1.84-.088-.037-.088.02V15a.625.625 0 01-1.25 0V9A.625.625 0 015 8.373h1.5a2.124 2.124 0 01.206 4.239c-.097 0-.13.079-.088.12zM13 9.623h-.626a.249.249 0 00-.249.249V15a.625.625 0 01-1.25 0V9.872a.249.249 0 00-.249-.249H10a.625.625 0 010-1.25h3a.625.625 0 010 1.25zm5 0h-2a.375.375 0 00-.375.375v1.126a.249.249 0 00.249.249H17a.625.625 0 010 1.25h-1.126a.249.249 0 00-.249.249V15a.625.625 0 01-1.25 0v-5A1.627 1.627 0 0116 8.373h2a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});