define("ember-svg-jar/inlined/view-square-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>view-square-alternate</title><path d=\"M19.449 10.857l-.3-.319c-1.325-1.421-3.545-3.8-7.024-3.8-3.35 0-5.4 2.27-6.62 3.627-.161.178-.312.345-.453.494a2 2 0 000 2.758c.141.148.291.315.452.493 1.223 1.357 3.27 3.628 6.62 3.628 3.479 0 5.7-2.381 7.025-3.8l.3-.318a2 2 0 000-2.763zm-7.325 4.879c-2.708 0-4.282-2.063-5.464-3.33a.25.25 0 010-.341c1.21-1.3 2.778-3.329 5.465-3.329 2.5 0 4.13 1.642 5.715 3.328a.248.248 0 010 .342c-1.566 1.667-3.19 3.33-5.716 3.33z\"/><circle cx=\"12.124\" cy=\"12.236\" r=\"2.5\"/><path d=\"M22 0H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zm0 21.75a.251.251 0 01-.25.25H2.25a.249.249 0 01-.25-.25V2.25A.249.249 0 012.25 2h19.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});