define("ember-svg-jar/inlined/social-deviant-art", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-deviant-art</title><path d=\"M1.73 19.57A1 1 0 01.52 19a8.41 8.41 0 01.38-6.68 9.48 9.48 0 014.44-4.2L4.21 6A1 1 0 116 5.06l1.2 2.29q.66-.22 1.38-.41c10.28-2.61 15 4.16 15.25 4.45a1 1 0 01-.53 1.52l-8.75 2.72a1 1 0 01-1.16-.46l-2.46-4.26a1 1 0 111.73-1l2.06 3.57 6.51-2c-1.68-1.6-5.6-4.24-12.16-2.58l-.87.25 3.47 6.17a1 1 0 01-.58 1.45zm4.59-9.71a7.59 7.59 0 00-3.64 3.35 6.52 6.52 0 00-.56 4.15l7.19-2.18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});