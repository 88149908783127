define("ember-svg-jar/inlined/file-code-download-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-download-1</title><path d=\"M6.5 11a6.5 6.5 0 106.5 6.5A6.508 6.508 0 006.5 11zm2.391 7.312l-2 2.5a.517.517 0 01-.782 0l-2-2.5A.5.5 0 014.5 17.5h.876a.125.125 0 00.124-.12V14.5a1 1 0 112 0v2.875a.127.127 0 00.124.125H8.5a.5.5 0 01.391.812zM14.679 12.2l.018-.017a.751.751 0 00.018-1.043l-2.029-2.174a.249.249 0 010-.341l2.028-2.174a.75.75 0 00-1.078-1.043l-.018.019-2.666 2.856a.752.752 0 000 1.025l2.666 2.857a.752.752 0 001.061.035zM16.286 11.14a.75.75 0 001.078 1.043l.018-.018 2.666-2.857a.752.752 0 000-1.025l-2.666-2.856a.751.751 0 00-1.061-.036.731.731 0 00-.036 1.06l2.029 2.174a.249.249 0 010 .341z\"/><path d=\"M23.414 3L21 .587A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.214a.248.248 0 00.242.25 19.14 19.14 0 011.485.082A.246.246 0 008 9.3V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.121a.5.5 0 01.146.355V18a.5.5 0 01-.5.5h-6.869a.244.244 0 00-.241.208 7.983 7.983 0 01-.373 1.459.243.243 0 00.228.332H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});