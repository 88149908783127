define("ember-svg-jar/inlined/renewable-energy-water-power", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>renewable-energy-water-power</title><path d=\"M23.919 15.493a.879.879 0 00-.794-.507H21.5a.5.5 0 01-.5-.5v-5a.5.5 0 00-.89-.312l-5.9 7.371a.874.874 0 00.666 1.441H16.5a.5.5 0 01.5.5v5a.5.5 0 00.89.313l5.9-7.371a.879.879 0 00.129-.935zM9 8.486C9 6.434 6.175 1.8 5.308.432a.99.99 0 00-1.616 0C2.826 1.8 0 6.434 0 8.486a4.5 4.5 0 009 0zm-7.25 0a.5.5 0 011 0 1.752 1.752 0 001.75 1.75.5.5 0 010 1 2.753 2.753 0 01-2.75-2.75zM14.246 5.8a.5.5 0 00.854-.353.356.356 0 01.51-.322 7.01 7.01 0 012.672 2.233A1 1 0 1019.9 6.183 8.991 8.991 0 0015.442 2.9a.5.5 0 01-.342-.474v-.984a.5.5 0 00-.854-.354l-2 2a.5.5 0 000 .707zM10.953 17.088a.5.5 0 00-.853.354.357.357 0 01-.511.322 6.985 6.985 0 01-3.2-3.095 1 1 0 10-1.774.924 8.976 8.976 0 005.144 4.39.5.5 0 01.343.474v.985a.5.5 0 00.853.353l2-2a.5.5 0 000-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});