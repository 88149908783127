define("ember-svg-jar/inlined/warehouse-storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>warehouse-storage</title><rect x=\"8\" y=\"7.5\" width=\"7\" height=\"6\" rx=\".5\" ry=\".5\"/><rect x=\"12\" y=\"14.5\" width=\"7\" height=\"6\" rx=\".5\" ry=\".5\"/><rect x=\"4\" y=\"14.5\" width=\"7\" height=\"6\" rx=\".5\" ry=\".5\"/><path d=\"M12 0A12.013 12.013 0 000 12v11a1 1 0 001 1h22a1 1 0 001-1V12A12.013 12.013 0 0012 0zm9 22H3a1 1 0 01-1-1v-8.647a10.237 10.237 0 019.314-10.33A10.011 10.011 0 0122 12v9a1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});