define("ember-svg-jar/inlined/arrow-dot-left-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-dot-left-1</title><circle cx=\"21.75\" cy=\"14\" r=\"1.5\"/><circle cx=\"17.75\" cy=\"14\" r=\"1.5\"/><circle cx=\"2.25\" cy=\"12\" r=\"1.5\"/><circle cx=\"21.75\" cy=\"10\" r=\"1.5\"/><circle cx=\"17.75\" cy=\"10\" r=\"1.5\"/><circle cx=\"13.75\" cy=\"10\" r=\"1.5\"/><circle cx=\"13.75\" cy=\"14\" r=\"1.5\"/><circle cx=\"13.75\" cy=\"18\" r=\"1.5\"/><circle cx=\"13.75\" cy=\"22\" r=\"1.5\"/><circle cx=\"9.75\" cy=\"10\" r=\"1.5\"/><circle cx=\"9.75\" cy=\"14\" r=\"1.5\"/><circle cx=\"9.75\" cy=\"18\" r=\"1.5\"/><circle cx=\"5.75\" cy=\"14\" r=\"1.5\"/><circle cx=\"13.75\" cy=\"2\" r=\"1.5\"/><circle cx=\"13.75\" cy=\"6\" r=\"1.5\"/><circle cx=\"9.75\" cy=\"6\" r=\"1.5\"/><circle cx=\"5.75\" cy=\"10\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});