define("ember-svg-jar/inlined/love-heart-balloons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-heart-balloons</title><path d=\"M9.614 17.936a1.856 1.856 0 01-.616.11 1.906 1.906 0 01-.711-.142.25.25 0 00-.342.212c-.14 1.754-.5 3.52-1.154 4.177a1 1 0 101.414 1.414c1.181-1.181 1.6-3.6 1.742-5.517a.25.25 0 00-.333-.254zM22.881 12.042a2.725 2.725 0 00-2.546-.463.469.469 0 01-.584-.3 2.717 2.717 0 00-1.057-1.394.5.5 0 00-.7.149 22.448 22.448 0 01-3.725 4.34.5.5 0 00-.146.481 19.62 19.62 0 001.652 4.277.5.5 0 00.439.262.444.444 0 00.063 0c.244-.03 5.99-.78 7.3-3.321a3.14 3.14 0 00-.696-4.031zM13.936.087a4.549 4.549 0 00-4.542 1.765.5.5 0 01-.788 0A4.55 4.55 0 004.064.087 5.227 5.227 0 000 5.549c0 4.9 8.362 10.67 8.718 10.913a.5.5 0 00.564 0C9.638 16.219 18 10.452 18 5.549A5.227 5.227 0 0013.936.087z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});