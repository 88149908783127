define("ember-svg-jar/inlined/smiley-blushing-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-blushing-alternate</title><path d=\"M15 16a1 1 0 00-1 1 .5.5 0 01-1 0 1 1 0 00-2 0 .5.5 0 01-1 0 1 1 0 00-2 0 2.5 2.5 0 004 2 2.5 2.5 0 004-2 1 1 0 00-1-1zM7 9.5c1.153 0 3.142-.547 3.895-2.053a1 1 0 10-1.79-.894A2.656 2.656 0 017 7.5a1 1 0 000 2zM17 9.5a1 1 0 000-2 2.656 2.656 0 01-2.1-.947 1 1 0 10-1.79.894C13.858 8.953 15.847 9.5 17 9.5zM16.707 11.293a1 1 0 00-1.414 0l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5a1 1 0 000-1.414zM20.207 11.293a1 1 0 00-1.414 0l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5a1 1 0 000-1.414zM6.707 11.293a1 1 0 00-1.414 0l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5a1 1 0 000-1.414zM8.707 14.207l1.5-1.5a1 1 0 00-1.414-1.414l-1.5 1.5a1 1 0 101.414 1.414z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});