define("ember-svg-jar/inlined/people-man-cap-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-cap-1</title><path d=\"M7.375 16.042a14.264 14.264 0 00-5.908 2.481A2.467 2.467 0 00.5 20.5v3a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-3a2.467 2.467 0 00-.967-1.977 14.264 14.264 0 00-5.908-2.481.5.5 0 00-.48.174c-.083.1-1.251 2.877-4.132 2.889h-.026c-2.893-.012-4.049-2.791-4.133-2.892a.5.5 0 00-.479-.171z\"/><path d=\"M19 9a17.087 17.087 0 00-.108-3.186A6.993 6.993 0 005.121 5.73 24.3 24.3 0 005 9a7 7 0 0014 0zm-7-7a5.02 5.02 0 014.623 3.158.251.251 0 01-.233.342H7.61a.25.25 0 01-.232-.343A5.07 5.07 0 0112 2zM7 9V7.75a.25.25 0 01.25-.25h4.259a.25.25 0 01.227.145 3.451 3.451 0 002.04 1.54 6.088 6.088 0 012.651 1.781.249.249 0 01.029.27A4.99 4.99 0 017 9z\"/><circle cx=\"10\" cy=\"9.25\" r=\"1\"/><path d=\"M12.082 12.869a2.976 2.976 0 00.863-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2h-2a.639.639 0 00-.3 1.2 2.31 2.31 0 00.435.18 2.99 2.99 0 00.867.126z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});