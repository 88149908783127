define("ember-svg-jar/inlined/user-polaroid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>user-polaroid</title><circle cx=\"16.5\" cy=\"6.112\" r=\"1\"/><circle cx=\"17.5\" cy=\"11.5\" r=\"1\"/><circle cx=\"7.177\" cy=\"12\" r=\"1\"/><circle cx=\"6.5\" cy=\"6.5\" r=\"1\"/><circle cx=\"12\" cy=\"8.25\" r=\"2.75\"/><path d=\"M21.875 21.047a.5.5 0 01-.375-.484V3.437a.5.5 0 01.375-.484A1.5 1.5 0 0021.5 0h-19a1.5 1.5 0 00-.375 2.953.5.5 0 01.375.484v17.126a.5.5 0 01-.375.484A1.5 1.5 0 002.5 24h19a1.5 1.5 0 00.375-2.953zM19.5 20.5a.5.5 0 01-.5.5h-4.707a.5.5 0 01-.5-.524l.1-2.111a.5.5 0 01.5-.477H15a.5.5 0 00.5-.5V15.5a3.5 3.5 0 00-7 0v1.888a.5.5 0 00.5.5h.607a.5.5 0 01.5.477l.1 2.111a.5.5 0 01-.5.524H5a.5.5 0 01-.5-.5v-17A.5.5 0 015 3h14a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});