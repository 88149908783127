define("ember-svg-jar/inlined/phone-retro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-retro</title><circle cx=\"12\" cy=\"13.785\" r=\"2\"/><path d=\"M18.5 10.411a4.005 4.005 0 01-3.961-3.442.249.249 0 00-.23-.218 45.021 45.021 0 00-4.618 0 .249.249 0 00-.23.218A4.005 4.005 0 015.5 10.411H2.678a9.874 9.874 0 00-.378 6.013 10.054 10.054 0 008.332 7.484A9.952 9.952 0 0017.981 22a.255.255 0 00.025-.383l-2.838-2.837a.748.748 0 011.045-1.071l4.257 4.256a.75.75 0 001.06-1.06l-1.274-1.274a9.918 9.918 0 001.066-9.221zM8.207 17.078a1 1 0 11-1.414 0 1 1 0 011.414 0zM11 19.785a1 1 0 111 1 1 1 0 01-1-1zm-5-7a1 1 0 11-1 1 1 1 0 011-1zm6 4a3 3 0 113-3 3 3 0 01-3 3zm6-4a1 1 0 11-1 1 1 1 0 011-1z\"/><path d=\"M21.1.921a45.417 45.417 0 00-18.2 0A3.7 3.7 0 000 4.411v2a2.5 2.5 0 002.5 2.5h3a2.5 2.5 0 002.5-2.5V5.6a.254.254 0 01.232-.253 45.366 45.366 0 017.536 0A.254.254 0 0116 5.6v.812a2.5 2.5 0 002.5 2.5h3a2.5 2.5 0 002.5-2.5v-2A3.7 3.7 0 0021.1.921z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});