define("ember-svg-jar/inlined/style-three-pin-tennis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-tennis</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM6.5 6a2 2 0 112 2 2 2 0 01-2-2zm11.805 5.912a4.831 4.831 0 01-3.057 1.435c-.1.008-.21.011-.312.011a3.883 3.883 0 01-1.8-.43.5.5 0 00-.585.09L9.03 16.534a.75.75 0 01-1.06-1.06l3.522-3.523a.5.5 0 00.09-.583A4.5 4.5 0 0112.591 6.2a4.83 4.83 0 013.056-1.434 3.739 3.739 0 014.092 4.091 4.835 4.835 0 01-1.434 3.055z\"/><path d=\"M15.956 6.254c-.067 0-.134 0-.2.007a3.333 3.333 0 00-2.1 1 2.833 2.833 0 00-.357 3.951 2.319 2.319 0 001.848.641 3.472 3.472 0 003.1-3.1A2.326 2.326 0 0017.6 6.9a2.285 2.285 0 00-1.644-.646z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});