define("ember-svg-jar/inlined/dress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dress</title><path d=\"M17.58 13.447a4.892 4.892 0 00-1.055-.817.249.249 0 01-.015-.424 5.871 5.871 0 002.354-3.415 4.021 4.021 0 00-1.044-3.986 1 1 0 01-.31-.723V1.008a1 1 0 00-2 0v4.068a1 1 0 01-.426.82 7.35 7.35 0 01-2.924 1.067 1 1 0 01-.318 0 7.335 7.335 0 01-2.928-1.071 1 1 0 01-.424-.819V1.008a1 1 0 00-2 0v3.078a1 1 0 01-.309.722A4.022 4.022 0 005.14 8.791a5.87 5.87 0 002.353 3.414.25.25 0 01-.015.424 4.908 4.908 0 00-1.057.818C4.1 15.8 3.974 20.334 3.991 21.668a1 1 0 00.621.913A20.492 20.492 0 0012 23.992a20.509 20.509 0 007.389-1.411 1 1 0 00.62-.913c.017-1.333-.109-5.868-2.429-8.221z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});