define("ember-svg-jar/inlined/color-brush-paint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-brush-paint</title><path d=\"M23.817 2.979a1.659 1.659 0 00-2.942-1.535l-6 10.5a.25.25 0 00.194.373 5.151 5.151 0 013.141 1.453.251.251 0 00.4-.069zM15.06 13.821a3.654 3.654 0 00-4.081 3.16c-.059.463-.08.889-.1 1.284-.054 1.176-.079 1.713-.964 2.252a1.339 1.339 0 00.517 2.471 7.856 7.856 0 001.055.071 7.143 7.143 0 004.385-1.459 5.956 5.956 0 002.349-3.7 3.654 3.654 0 00-3.161-4.079zM2.417 15.106c.453-.11.464-.105.95.522.7.906 1.767 2.275 4.95 2.592a1 1 0 00.2-1.991A4.269 4.269 0 014.946 14.4a2.609 2.609 0 00-3-1.237A2.5 2.5 0 00.32 14.436a3.237 3.237 0 00-.1 2.581c.752 2.207 3.563 4.809 6.837 6.329a1 1 0 10.726-1.869C5.1 20.2 2.673 18.023 2.11 16.372c-.338-.992.162-1.23.307-1.266z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});