define("ember-svg-jar/inlined/cat-toy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cat-toy</title><path d=\"M14.8 14.773a1 1 0 001.374 1.454l3.184-3.014a3.008 3.008 0 00-.323-4.307 2.99 2.99 0 00-4.051.183l-.763.763a1.746 1.746 0 11-2.394-2.539l.55-.489A3.58 3.58 0 007.8 1.323L6.489 2.345V1.5a1.5 1.5 0 00-3 0v21a1.5 1.5 0 003 0V4.846C6.526 4.824 9.03 2.9 9.03 2.9a1.6 1.6 0 011.18-.318 1.58 1.58 0 01.84 2.747l-.55.488a3.746 3.746 0 105.137 5.448l.763-.765a1 1 0 011.357-.061 1.028 1.028 0 01.169 1.38zM16.459 19.8c-.534-2.134-1.266-2.105-1.628.065l-.137.822a1.027 1.027 0 001.045 1.233.931.931 0 00.946-1.213zM20.057 18.211l-.771-.529c-1.813-1.245-2.343-.737-1.176 1.128l.442.706a1.027 1.027 0 001.6.231.931.931 0 00-.095-1.536z\"/><path d=\"M11.736 17.682l-.77.529a.931.931 0 00-.094 1.536 1.027 1.027 0 001.6-.231l.442-.706c1.166-1.865.636-2.373-1.178-1.128z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});