define("ember-svg-jar/inlined/dentistry-tooth-exchange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dentistry-tooth-exchange</title><path d=\"M13.616 7.8a1.881 1.881 0 00-1.269.49.5.5 0 01-.671 0 1.881 1.881 0 00-1.268-.49 2.147 2.147 0 00-1.9 2.347 4.052 4.052 0 00.843 2.693.5.5 0 01.087.489A6.146 6.146 0 009.1 15.4a6.436 6.436 0 00.131 1.3.384.384 0 00.369.3.4.4 0 00.4-.356c.158-1.453 1-2.561 2.013-2.561s1.861 1.114 2.016 2.573a.383.383 0 00.383.344.4.4 0 00.39-.319 6.436 6.436 0 00.128-1.285 6.171 6.171 0 00-.346-2.068.5.5 0 01.087-.489 4.053 4.053 0 00.842-2.693A2.147 2.147 0 0013.616 7.8z\"/><path d=\"M6.91 11.776a.5.5 0 00-.41-.786H4.637a.5.5 0 01-.487-.617 8.343 8.343 0 014.991-5.834A7.878 7.878 0 0117.13 5.9a1 1 0 101.3-1.519A9.874 9.874 0 008.417 2.675a10.415 10.415 0 00-6.366 7.908.5.5 0 01-.491.417H.5a.5.5 0 00-.4.794l3.058 4.213a.5.5 0 00.4.206h.005a.5.5 0 00.4-.214zM23.5 13a.5.5 0 00.4-.8l-3.19-4.211a.531.531 0 00-.414-.2.5.5 0 00-.4.222l-2.81 4.222a.5.5 0 00.416.777h1.731a.5.5 0 01.487.617 8.347 8.347 0 01-4.992 5.838 7.885 7.885 0 01-7.99-1.365 1 1 0 00-1.3 1.52 9.877 9.877 0 0010.014 1.707 10.418 10.418 0 006.366-7.909.5.5 0 01.49-.418z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});