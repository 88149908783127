define("ember-svg-jar/inlined/social-media-snapchat-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-snapchat-alternate</title><path d=\"M24 2.5A2.5 2.5 0 0021.5 0h-19A2.5 2.5 0 000 2.5v19A2.5 2.5 0 002.5 24h19a2.5 2.5 0 002.5-2.5zm-4.45 13.7l-1.48.3a.62.62 0 00-.43.74 1 1 0 01-1.09 1.18l-.47-.08a2.14 2.14 0 00-1.86.6A3.52 3.52 0 0112 20.16a3.52 3.52 0 01-2.22-1.22 2.14 2.14 0 00-1.86-.6l-.47.08a1 1 0 01-1.09-1.18.62.62 0 00-.43-.74l-1.48-.3a.62.62 0 01-.08-1.2 11.31 11.31 0 002.07-1.24 1 1 0 00-.07-1.64l-.9-.57a1 1 0 011.06-1.66l.58.37A.25.25 0 007.5 10V8.16a4.5 4.5 0 119 0V10a.25.25 0 00.38.21l.58-.37a1 1 0 011.07 1.69l-.9.57a1 1 0 00-.07 1.64A11.31 11.31 0 0019.63 15a.62.62 0 01-.08 1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});