define("ember-svg-jar/inlined/receipt-register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>receipt-register</title><path d=\"M19.914 17.75a.25.25 0 00.246-.3 12.674 12.674 0 00-.745-2.553.249.249 0 01.094-.3l1.042-.688a1 1 0 10-1.1-1.669l-.839.554a.25.25 0 01-.346-.071 12.7 12.7 0 00-.784-1.059.25.25 0 010-.317l.919-1.12a1 1 0 10-1.545-1.27l-.761.928a.249.249 0 01-.169.09.253.253 0 01-.184-.056 12.673 12.673 0 00-1.178-.864.25.25 0 01-.082-.331l.394-.712a1 1 0 00-1.75-.97l-.427.772a.25.25 0 01-.312.11A12.7 12.7 0 0010.2 7.26a.25.25 0 01-.2-.245V1.5A1.5 1.5 0 008.5 0h-5A1.5 1.5 0 002 1.5v16a.25.25 0 00.25.25zM20.522 19.25H2.25a.25.25 0 00-.25.25v3.062A1.472 1.472 0 003.5 24h17a1.472 1.472 0 001.5-1.438v-1.876a1.469 1.469 0 00-1.478-1.436z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});