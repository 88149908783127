define("ember-svg-jar/inlined/love-bird", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-bird</title><path d=\"M18.646 13.548l-2.388-1.043a.5.5 0 01-.3-.4A5.086 5.086 0 0010.4 7.61a5.251 5.251 0 00-4.563 5.273.58.58 0 01-.622.579 5.49 5.49 0 01-3.492-1.743 1 1 0 00-1.156-.2.978.978 0 00-.558.991 8.421 8.421 0 003.579 6.59.5.5 0 01.076.776c-.354.345-.94.863-2.571 2.391A1 1 0 001.773 24h6.006a8.225 8.225 0 008.192-7.651.5.5 0 01.259-.4l2.476-1.353a.584.584 0 00-.06-1.048zM12 12.5a1 1 0 111-1 1 1 0 01-1 1zM23.76 1.234a2.29 2.29 0 00-3.634-.58l-.276.272a.5.5 0 01-.7 0l-.276-.272a2.289 2.289 0 00-3.634.58 2.2 2.2 0 00.425 2.577l3.429 3.519a.569.569 0 00.812 0l3.429-3.519a2.2 2.2 0 00.425-2.577z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});