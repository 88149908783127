define("ember-svg-jar/inlined/people-man-moustache-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-moustache-2</title><path d=\"M22.248 18.814a7.1 7.1 0 00-3.056-1.156c-.189-.04-.993-.234-1.572-.375a.5.5 0 00-.618.486v1.61a3.005 3.005 0 01-2.129 2.871 2.92 2.92 0 01-.412.84.5.5 0 00.408.789H23.5a.5.5 0 00.5-.5v-1a4.532 4.532 0 00-1.752-3.565zM9.13 22.25A3.005 3.005 0 017 19.379v-1.611a.5.5 0 00-.619-.486c-.579.141-1.384.336-1.573.376a7.106 7.106 0 00-3.057 1.156A4.532 4.532 0 000 22.379v1a.5.5 0 00.5.5h8.634a.5.5 0 00.408-.789 2.92 2.92 0 01-.412-.84z\"/><path d=\"M8 19.379a2 2 0 002 2 2 2 0 104 0 2 2 0 002-2v-3.166a.5.5 0 01.4-.49c.916-.181 1.831-.575 2.048-1.439a.247.247 0 00-.065-.237.253.253 0 00-.237-.067 2.96 2.96 0 01-.974.088 4.832 4.832 0 00.491-1.428.252.252 0 01.145-.189 2.731 2.731 0 001.439-2.434 2.114 2.114 0 00-.465-1.443C20.052-.453 12.154.129 11.979.129s-8.067-.58-6.8 8.445a2.112 2.112 0 00-.464 1.444 2.73 2.73 0 001.439 2.433.25.25 0 01.144.187 4.849 4.849 0 00.492 1.432 3.043 3.043 0 01-.936-.09.249.249 0 00-.3.3c.216.864 1.132 1.258 2.049 1.439a.5.5 0 01.4.49zm5.5-3.846a3.149 3.149 0 01-1.5.365 3.008 3.008 0 01-1.507-.364.75.75 0 11.73-1.31A1.6 1.6 0 0012 14.4a1.553 1.553 0 00.764-.173.75.75 0 01.734 1.308zm-5.728-3.142a1.749 1.749 0 00-1-1.307 1.272 1.272 0 01-.562-1.095.76.76 0 01.061-.36h.347a1.754 1.754 0 001.716-1.406l.185-.923a.25.25 0 01.227-.2 5.114 5.114 0 003.07-1.05.249.249 0 01.328 0 5.114 5.114 0 003.07 1.05.25.25 0 01.227.2l.184.922a1.755 1.755 0 001.716 1.406h.351a.859.859 0 01.058.36 1.276 1.276 0 01-.559 1.095 1.753 1.753 0 00-1 1.306 3 3 0 01-.247.767.247.247 0 01-.177.14.252.252 0 01-.218-.06A3.562 3.562 0 0013 11.879a1.53 1.53 0 00-1 .322 1.527 1.527 0 00-1-.322 3.618 3.618 0 00-2.573 1.384.25.25 0 01-.394-.078 3.043 3.043 0 01-.261-.794z\"/><path d=\"M11.09 9.105a2.019 2.019 0 00-2.6 0 .75.75 0 001.022 1.1.555.555 0 01.557 0 .75.75 0 101.022-1.1zM13.932 10.2a.555.555 0 01.557 0 .75.75 0 101.022-1.1 2.019 2.019 0 00-2.6 0 .75.75 0 001.022 1.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});