define("ember-svg-jar/inlined/single-woman-actions-share-2.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-share-2</title><path d=\"M12.122 14.306a.5.5 0 00.149-.833A7.457 7.457 0 00.065 16.86a.5.5 0 00.479.642h8.588a.5.5 0 00.482-.368 4.262 4.262 0 012.508-2.828zM11 18.25a2.741 2.741 0 004.637 1.989.253.253 0 01.266-.051l2.448.98a.251.251 0 01.158.224 2.42 2.42 0 00.183.867 2.761 2.761 0 10.671-3 .253.253 0 01-.266.051l-2.445-.978a.253.253 0 01-.157-.219c0-.041-.007-.1-.019-.178a.22.22 0 01.1-.219L19.437 16a.251.251 0 01.271.009 2.708 2.708 0 001.542.487 2.75 2.75 0 10-2.75-2.75 2.4 2.4 0 00.021.295.247.247 0 01-.119.247L15.563 16a.249.249 0 01-.27-.009 2.714 2.714 0 00-1.543-.491A2.752 2.752 0 0011 18.25z\"/><path d=\"M1.57 10.442a1 1 0 001.382-.3 5.166 5.166 0 00.455-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .249.249 0 01.411.082 5.233 5.233 0 00.454.9 1 1 0 001.681-1.082 4.561 4.561 0 01-.643-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248a4.573 4.573 0 01-.642 2.512 1 1 0 00.298 1.382zM7.211 8.6A2.706 2.706 0 014.47 6.248a.5.5 0 01.324-.577A5.708 5.708 0 007.04 4.232a.249.249 0 01.36 0 5.719 5.719 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.211 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});