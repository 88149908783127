define("ember-svg-jar/inlined/shellfish-shrimp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shellfish-shrimp</title><path d=\"M22.291 13.007a5.746 5.746 0 01-6.032 3.455 3.759 3.759 0 01-1.377 2.816 3.582 3.582 0 004.594 2.409 9.026 9.026 0 002.815-8.68zM13.533 19.991a6.423 6.423 0 01-2.012.152.5.5 0 00-.416.222L9.2 23.223a.5.5 0 00.417.777h3.81a9.066 9.066 0 003.659-.775 5.069 5.069 0 01-3.553-3.234z\"/><path d=\"M21.229 10.358a9.425 9.425 0 00-7.4-4.475 5.77 5.77 0 01-.442 6.838 3.962 3.962 0 012.558 2.155 4.252 4.252 0 005.284-4.518z\"/><path d=\"M13.248 9a4.39 4.39 0 00-1.366-3.143h-3.34c-.189 0-.294-.472-.294-.607A.752.752 0 019 4.5h5.5a.75.75 0 000-1.5H9a2.245 2.245 0 00-2.167 2.857h-1.5a3.042 3.042 0 01-1.1-2.232A2.127 2.127 0 016.36 1.5h12.651a.75.75 0 000-1.5H6.36a3.629 3.629 0 00-3.625 3.625 4.44 4.44 0 00.642 2.232h-.132a.977.977 0 00-.874 1.413l.67 1.339a7.13 7.13 0 006.41 3.962h1.856A4.23 4.23 0 0013.248 9zm-5.3-.5a.953.953 0 11.953.952.953.953 0 01-.951-.952zM6.47 16.369a.492.492 0 00-.44.033.5.5 0 00-.242.369l-.439 3.509-3.527 1.32a.494.494 0 00-.312.362.5.5 0 00.134.459C1.709 22.489 3.26 24 6.76 24a.5.5 0 00.416-.223l1.9-2.857a.5.5 0 00.084-.277 4.573 4.573 0 00-2.69-4.274z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});