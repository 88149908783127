define("ember-svg-jar/inlined/messaging-msn-messenger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messaging-msn-messenger</title><path d=\"M.5 12.57c0 1 .55 1.22.55 1.22 1.07 1.42 1.41 1.61 2.9 1.73a.5.5 0 00.53-.39 11.42 11.42 0 014.21-6 .5.5 0 000-.84 4.27 4.27 0 00-.87-.43A3 3 0 009 5.54a3 3 0 00-6.07 0 3 3 0 001.12 2.33 5.72 5.72 0 00-3.55 4.7zM19.74 6.56a4.53 4.53 0 00-9.06 0A4.39 4.39 0 0012.37 10a8.48 8.48 0 00-5.27 7c0 1.5.81 1.81.81 1.81a2.11 2.11 0 00.9.46c1.14 3.59 12.08 3.39 13.16 0 1.92-.38 1.7-2.66 1.13-4.41a8 8 0 00-5-4.86 4.36 4.36 0 001.64-3.44z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});