define("ember-svg-jar/inlined/wedding-dress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wedding-dress</title><path d=\"M19.905 16.7a.5.5 0 00-.646-.4c-2.852.9-5.244.41-6.887-1.414a.5.5 0 00-.744 0 5.561 5.561 0 01-4.28 1.877 8.535 8.535 0 01-2.607-.448.5.5 0 00-.648.4c-.049.317-.1.64-.154.974a1 1 0 00.589 1.071 8.773 8.773 0 003.375.738 5.926 5.926 0 003.783-1.3.5.5 0 01.628 0c1.1.887 3.456 2.1 7.158.558a1 1 0 00.589-1.07c-.056-.34-.107-.668-.156-.986z\"/><path d=\"M4.792 14.751c2.256.837 4.96.959 6.543-2.078a.78.78 0 011.33 0c1.583 3.034 4.288 2.914 6.544 2.077a.5.5 0 00.32-.544c-.641-4.169-1.28-6.869-4.4-8.263a.5.5 0 01-.256-.654 10.3 10.3 0 001.2-3.446 1.882 1.882 0 00-1.138-1.66 2.692 2.692 0 00-2.657.422.5.5 0 01-.556 0A2.687 2.687 0 009.065.183a1.882 1.882 0 00-1.139 1.66 10.3 10.3 0 001.2 3.446.5.5 0 01-.256.654c-3.119 1.394-3.758 4.1-4.4 8.264a.5.5 0 00.322.544zM14 22h-.5a.5.5 0 01-.5-.5v-1.25a.751.751 0 00-.75-.75h-.5a.751.751 0 00-.75.75v1.25a.5.5 0 01-.5.5H10a1 1 0 000 2h4a1 1 0 100-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});