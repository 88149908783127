define("ember-svg-jar/inlined/audio-book-sports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-book-sports</title><path d=\"M23.55 6.437a1.522 1.522 0 00-1.075-.431c-.354.006-.7.018-1.027.035a1 1 0 10.1 2 .427.427 0 01.452.424v9.573a.5.5 0 01-.475.5 18.339 18.339 0 00-7.79 1.895.5.5 0 01-.735-.442V13a1 1 0 10-2 0v6.99a.5.5 0 01-.735.441 18.334 18.334 0 00-7.789-1.895.5.5 0 01-.476-.5v-9.5a.5.5 0 01.528-.5c.635.036 1.25.093 1.837.172a1 1 0 00.268-1.982A26.649 26.649 0 001.533 6h-.026a1.518 1.518 0 00-1.057.439A1.492 1.492 0 000 7.509v11.5a1.508 1.508 0 001.482 1.495c4.681.078 7.32 1.284 8.629 2.163a6.015 6.015 0 011.143 1 1 1 0 001.493 0 6.009 6.009 0 011.142-1c1.31-.879 3.948-2.085 8.629-2.163A1.508 1.508 0 0024 19.01V7.508a1.51 1.51 0 00-.45-1.071z\"/><path d=\"M12.47 9.973l.217-.217a.506.506 0 01.255-.137l3.758-.751a2.489 2.489 0 001.713-.956 1.985 1.985 0 00.265-1.6 1.976 1.976 0 00-.984-1.412.38.38 0 01-.2-.407 2.647 2.647 0 00-.824-2.429 2.647 2.647 0 00-2.429-.824.383.383 0 01-.407-.2 1.976 1.976 0 00-1.408-.98 1.978 1.978 0 00-1.6.266 2.485 2.485 0 00-.956 1.712L9.12 5.8a.506.506 0 01-.137.255l-.217.217a2.619 2.619 0 003.7 3.7zm4.743-3.34c.077.349.019.64-.771.757l-1.313.263a.25.25 0 01-.183-.456l1.4-.892c.291-.185.748-.236.867.328zm-2.657-3.879a1.3 1.3 0 01.837 2.38l-3.066 1.951a.488.488 0 01-.673-.673l1.951-3.066a1.291 1.291 0 01.951-.592zM11.349 2.3c.117-.79.408-.848.757-.771.564.119.513.576.328.865l-.892 1.4a.25.25 0 01-.456-.183z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});