define("ember-svg-jar/inlined/touch-up-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>touch-up-1</title><path d=\"M12.266 23.745A9.1 9.1 0 0021 14.871a9 9 0 00-5.134-8.241.973.973 0 00-1.366.9v.049a1.01 1.01 0 00.586.9A7 7 0 0119 14.813a7.1 7.1 0 01-6.893 6.935 6.994 6.994 0 01-3.158-13.29.942.942 0 00.551-.84v-.084a.987.987 0 00-1.437-.87 9 9 0 004.2 17.081z\"/><path d=\"M10.75 10.249a1.25 1.25 0 102.5 0V4.5a.25.25 0 01.25-.25H15a.5.5 0 00.354-.854l-.854-.854L12.354.4a.5.5 0 00-.708 0L9.5 2.542l-.854.858A.5.5 0 009 4.249h1.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});