define("ember-svg-jar/inlined/car-tool-steering-wheel-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-tool-steering-wheel-1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 8.015a4.473 4.473 0 01-3.274-1.427.25.25 0 01.063-.39 6.679 6.679 0 016.422 0 .25.25 0 01.127.18.247.247 0 01-.064.211A4.473 4.473 0 0112 8.015zm1.414 5.4a2 2 0 110-2.828 2 2 0 010 2.827zM8.015 12a4.479 4.479 0 01-1.562 3.4.249.249 0 01-.208.057.253.253 0 01-.175-.126 6.689 6.689 0 01.094-6.584.249.249 0 01.178-.122.253.253 0 01.207.063A4.475 4.475 0 018.015 12zM12 15.985a4.484 4.484 0 013.437 1.6.25.25 0 01-.065.378 6.7 6.7 0 01-6.744 0 .251.251 0 01-.066-.378 4.486 4.486 0 013.438-1.6zm5.93-.653a.25.25 0 01-.175.126.247.247 0 01-.208-.057 4.478 4.478 0 01-.1-6.712.249.249 0 01.385.059 6.689 6.689 0 01.094 6.584z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});