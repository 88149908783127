define("ember-svg-jar/inlined/reptile-snake-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>reptile-snake-1</title><path d=\"M19.75 5h-2.512A5.581 5.581 0 0019.25.5a.5.5 0 00-.438-.5.483.483 0 00-.547.375C17.891 1.877 16.625 2 15.25 2H9.415a3.628 3.628 0 00-3.66 3.318A3.485 3.485 0 006.8 8H5a4.25 4.25 0 100 8.5s1.924.019 2.459 0c5.007-.164 3.929.934 3.686 1.444a3.6 3.6 0 00.452 3.9L13 23.478a1.5 1.5 0 001.136.522h1.08a1.5 1.5 0 001.138-.522l1.4-1.632a3.544 3.544 0 00.46-3.886 4.128 4.128 0 01-.545-2.075 2.54 2.54 0 00-2.511-2.385H11.5a.75.75 0 010-1.5h8.25a3.5 3.5 0 100-7zm-7.074 13.5a1 1 0 11-1 1 1 1 0 011-1zm4 2a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});