define("ember-svg-jar/inlined/shipment-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-check</title><path d=\"M8.5 3.75a.249.249 0 00.25.25h5.35a.25.25 0 00.177-.427L11.4.689A1.511 1.511 0 0010.335.25H8.75A.249.249 0 008.5.5zM6.75 4A.25.25 0 007 3.75V.5a.25.25 0 00-.25-.25H5.164A1.511 1.511 0 004.1.689L1.219 3.573A.25.25 0 001.4 4zM15.068 9.563a.251.251 0 00.182-.241V5.75A.25.25 0 0015 5.5H.5a.249.249 0 00-.25.25v9a1.5 1.5 0 001.5 1.5H9.1a.249.249 0 00.246-.212 8.012 8.012 0 015.722-6.475z\"/><path d=\"M17.25 10.75a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-3.393 6.677a.749.749 0 011.06 0l1.3 1.3a.25.25 0 00.376-.027l2.732-3.643a.75.75 0 011.2.9l-2.905 3.874a1.488 1.488 0 01-1.094.6h-.107a1.5 1.5 0 01-1.06-.441l-1.5-1.5a.749.749 0 01-.002-1.063z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});