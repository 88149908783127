define("ember-svg-jar/inlined/messages-bubble-heart-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-heart-2</title><path d=\"M10.03 13.779a5.024 5.024 0 017.329-1.9.249.249 0 00.281 0 5.068 5.068 0 013.638-.811 4.908 4.908 0 011.175.352.251.251 0 00.328-.126A7.927 7.927 0 0023.5 8a8.008 8.008 0 00-8-8H8a7.992 7.992 0 00-3 15.4v4.1a.5.5 0 00.853.354l3.58-3.58a.253.253 0 00.073-.18 5.015 5.015 0 01.524-2.315z\"/><path d=\"M23.627 14.449a3.526 3.526 0 00-5.646-.916l-.482.482-.481-.482a3.525 3.525 0 00-4.993 4.978l5.114 5.335a.5.5 0 00.721 0l5.107-5.327a3.524 3.524 0 00.66-4.07z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});