define("ember-svg-jar/inlined/smart-watch-circle-graph-line-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-graph-line-alternate</title><path d=\"M21.5 12A9.5 9.5 0 0017 3.927a.245.245 0 01-.109-.148L15.983.373A.5.5 0 0015.5 0h-7a.5.5 0 00-.484.371l-.908 3.408A.245.245 0 017 3.927a9.5 9.5 0 000 16.149.25.25 0 01.109.148l.908 3.407A.5.5 0 008.5 24h7a.5.5 0 00.482-.368l.935-3.428a.247.247 0 01.109-.146A9.5 9.5 0 0021.5 12zM12 19.5a7.512 7.512 0 01-7.338-5.948.25.25 0 01.244-.3H6.5a.752.752 0 00.648-.373L9.07 9.572a.25.25 0 01.453.043L11.386 15a1.249 1.249 0 002.289.016l1.216-2.956a.25.25 0 01.446-.033l.519.865a.75.75 0 00.644.365h2.593a.25.25 0 01.245.3A7.513 7.513 0 0112 19.5zm0-15a7.508 7.508 0 017.481 6.982.25.25 0 01-.249.268h-2.166a.248.248 0 01-.214-.122l-.74-1.232a1.249 1.249 0 00-2.213.133l-1.092 2.653a.25.25 0 01-.467-.013L10.552 8.01a1.249 1.249 0 00-1.035-.753 1.274 1.274 0 00-1.178.59l-2.2 3.78a.25.25 0 01-.216.125H4.767a.251.251 0 01-.183-.08.248.248 0 01-.066-.188A7.508 7.508 0 0112 4.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});