define("ember-svg-jar/inlined/link-broken-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>link-broken-2</title><path d=\"M12 .5A4.505 4.505 0 007.5 5v3.134a1 1 0 002 0V5a2.5 2.5 0 015 0v5.5A2.5 2.5 0 0112 13a1 1 0 000 2 4.5 4.5 0 004.5-4.5V5A4.505 4.505 0 0012 .5z\"/><path d=\"M15.5 15.634a1 1 0 00-1 1V19a2.5 2.5 0 01-5 0v-5a2.5 2.5 0 012.5-2.5 1 1 0 000-2A4.505 4.505 0 007.5 14v5a4.5 4.5 0 009 0v-2.366a1 1 0 00-1-1zM5.237 6.883l-1.3-.75a1 1 0 00-1 1.733l1.3.75a1 1 0 101-1.733zM4 12.5a1 1 0 000-2H2.5a1 1 0 000 2zM4.237 14.383l-1.3.75a1 1 0 001 1.733l1.3-.75a1 1 0 10-1-1.733zM19.264 8.749a1 1 0 00.5-.133l1.3-.75a1 1 0 10-1-1.733l-1.3.75a1 1 0 00.5 1.866zM21.5 10.5H20a1 1 0 000 2h1.5a1 1 0 000-2zM21.062 15.133l-1.3-.75a1 1 0 10-1 1.733l1.3.75a1 1 0 101-1.733z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});