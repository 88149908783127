define("ember-svg-jar/inlined/plant-pot-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plant-pot-2</title><path d=\"M7.325 9.741A1 1 0 008 10h2.569A.5.5 0 0011 9.741l.294-.535a.5.5 0 000-.482L7.4 1.646a.962.962 0 00-.9-.508.979.979 0 00-.857.578 6.974 6.974 0 001.682 8.025zM11.781 7.493a.249.249 0 00.438 0l2.143-3.9a.5.5 0 000-.474A54.328 54.328 0 0012.853.445a1.043 1.043 0 00-1.715 0A55.117 55.117 0 009.63 3.12a.5.5 0 000 .474zM19.867 11.6a1.508 1.508 0 00-1.2-.6H5.333a1.5 1.5 0 00-1.439 1.92L6.6 22.2A2.513 2.513 0 009 24h6a2.513 2.513 0 002.4-1.8l2.706-9.28a1.508 1.508 0 00-.239-1.32zM12.21 9.877a.249.249 0 00.215.123H16a1 1 0 00.672-.259 7.033 7.033 0 001.178-1.384 6.938 6.938 0 00.5-6.641.981.981 0 00-1.755-.07L12.206 9.63a.248.248 0 00.004.247z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});