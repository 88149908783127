define("ember-svg-jar/inlined/style-three-pin-skate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-skate</title><circle cx=\"10.5\" cy=\"14\" r=\"1\"/><circle cx=\"15.5\" cy=\"9\" r=\"1\"/><path d=\"M12 0C6.21 0 1.5 4.26 1.5 9.5c0 5.75 7.3 12.29 9.54 14.15a1.492 1.492 0 001.92 0c2.24-1.87 9.54-8.4 9.54-14.15C22.5 4.26 17.79 0 12 0zm3.5 11a2 2 0 01-1.931-1.475.25.25 0 00-.418-.111l-2.238 2.237a.252.252 0 00-.065.241.25.25 0 00.176.177 2 2 0 11-2.455 2.456.25.25 0 00-.418-.111l-.621.62a.75.75 0 11-1.06-1.06l6.649-6.65a5.732 5.732 0 001.636-3.4.75.75 0 111.49.166A7.191 7.191 0 0115.229 7v.012a.011.011 0 00.011 0A2.112 2.112 0 0115.5 7a2 2 0 010 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});