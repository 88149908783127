define("ember-svg-jar/inlined/subtract-circle-bold-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>subtract-circle-bold-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 21a9 9 0 119-9 9.01 9.01 0 01-9 9z\"/><rect x=\"5.795\" y=\"10.568\" width=\"12.409\" height=\"2.864\" rx=\".955\" ry=\".955\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});