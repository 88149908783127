define("ember-svg-jar/inlined/road-sign-bicycles-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-bicycles-alternate</title><path d=\"M16.686 11a2.974 2.974 0 00-.413.03.248.248 0 01-.262-.146l-1.5-3.373a.251.251 0 01.163-.343l.71-.194a.75.75 0 00-.394-1.448l-1.268.348a1.25 1.25 0 00-.822 1.715l.6 1.348a.254.254 0 010 .214.251.251 0 01-.169.132l-2.387.53a.25.25 0 01-.295-.175l-.122-.426a.169.169 0 01.027-.146.167.167 0 01.132-.066.75.75 0 000-1.5h-2.5a.75.75 0 000 1.5h.531a.251.251 0 01.241.181l.374 1.312a.248.248 0 01-.037.214l-.269.377a.25.25 0 01-.281.093 3.252 3.252 0 102.047 4 .251.251 0 01.24-.178h.154a.749.749 0 00.592-.289l2.475-3.182a.247.247 0 01.227-.1.25.25 0 01.2.147l.023.053a.062.062 0 01-.018.074 3.233 3.233 0 102-.708zm-7.7 4.417a1.734 1.734 0 01-1.3.583 1.75 1.75 0 01-.863-3.272 1.61 1.61 0 01.669-.205.25.25 0 01.225.394l-.641.9A.75.75 0 007.686 15H8.8a.25.25 0 01.187.417zm3.406-3.934L10.9 13.4a.255.255 0 01-.2.1H9.629a.25.25 0 01-.2-.395l1.145-1.605a.255.255 0 01.149-.1l1.414-.314a.25.25 0 01.252.4zM16.686 16a1.752 1.752 0 01-1.75-1.75 1.685 1.685 0 01.158-.714.25.25 0 01.229-.145.247.247 0 01.226.148L16 14.555a.752.752 0 00.687.445.765.765 0 00.3-.064.752.752 0 00.381-.991l-.453-1.017a.25.25 0 01.306-.339A1.748 1.748 0 0116.686 16z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});