define("ember-svg-jar/inlined/power-tools-wood-cutter-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>power-tools-wood-cutter-1</title><path d=\"M3.193 3.366h1.04a.5.5 0 01.48.361 2.931 2.931 0 00.464.934l.021.032a.092.092 0 010 .105 3.216 3.216 0 00.265 3.826 1.093 1.093 0 001.672.132l.789-.809a.5.5 0 01.569-.105 9.468 9.468 0 001.085.416.5.5 0 01.332.348 3.461 3.461 0 003.111 2.381 1.093 1.093 0 001.262-1.078V8.866a.5.5 0 01.361-.48 2.9 2.9 0 00.934-.464l.014-.01a.125.125 0 01.14 0 3.221 3.221 0 003.81-.276 1.094 1.094 0 00.13-1.673l-.808-.788a.5.5 0 01-.105-.569 9.456 9.456 0 00.415-1.084.5.5 0 01.349-.331A3.26 3.26 0 0021.789.625.5.5 0 0021.3 0H3.221a.5.5 0 00-.416.223 4.831 4.831 0 00-.69 1.88 1.092 1.092 0 001.078 1.263zm5.97-1.681a.5.5 0 01.389-.185h4.9a.5.5 0 01.489.6 3.01 3.01 0 01-5.875 0 .5.5 0 01.097-.415zM20 16H4a4 4 0 000 8h16a4 4 0 000-8zM4 22.5A2.5 2.5 0 116.5 20 2.5 2.5 0 014 22.5zm6.5-3.5H9a.5.5 0 010-1h1.5a.5.5 0 010 1zm4.5 3h-1.5a.5.5 0 010-1H15a.5.5 0 010 1zm4-3h-1.5a.5.5 0 010-1H19a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});