define("ember-svg-jar/inlined/programming-hold-code-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-hold-code-1</title><path d=\"M0 15.542v7.25a1 1 0 002 0v-7.25a1 1 0 00-2 0zM3 15.792v6.5a.5.5 0 00.5.5h10.764a2.239 2.239 0 002.236-2.237v-.263a1 1 0 00-1-1H6.375a.625.625 0 010-1.25H8.9a1 1 0 00.92-1.393 2.234 2.234 0 00-2.056-1.357H3.5a.5.5 0 00-.5.5zM17.866 10.842a1.251 1.251 0 001.768 0l4-4a1.249 1.249 0 000-1.767l-4-4a1.25 1.25 0 10-1.768 1.767l2.763 2.763a.5.5 0 010 .707l-2.763 2.763a1.249 1.249 0 000 1.767zM10.634 1.075a1.249 1.249 0 00-1.768 0l-4 4a1.249 1.249 0 000 1.767l4 4a1.25 1.25 0 101.768-1.767L7.871 6.312a.5.5 0 010-.707l2.763-2.763a1.249 1.249 0 000-1.767zM13.251 11.708a1.252 1.252 0 001.22-.978l2-9a1.251 1.251 0 00-2.442-.543l-2 9a1.252 1.252 0 00.95 1.492 1.264 1.264 0 00.272.029z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});