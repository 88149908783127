define("ember-svg-jar/inlined/color-picker-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-picker-alternate</title><path d=\"M17 9a1 1 0 000-2h-.75a.25.25 0 01-.25-.25V4.5A4.277 4.277 0 0012 0a4.277 4.277 0 00-4 4.5v2.25a.25.25 0 01-.25.25H7a1 1 0 000 2zM8.25 10.5a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h2.25a.5.5 0 010 1H8.25a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25h2.25a.5.5 0 010 1H8.25a.25.25 0 00-.25.25v1.25a5.138 5.138 0 002 4.113V23a1 1 0 001 1h2a1 1 0 001-1v-1.387a5.138 5.138 0 002-4.113v-6.75a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});