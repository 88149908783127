define("ember-svg-jar/inlined/phone-booth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-booth</title><path d=\"M4.954 5.065a.238.238 0 00-.154.224V21.84a.239.239 0 01-.24.24h-.72a.96.96 0 100 1.92h16.32a.96.96 0 100-1.92h-.72a.239.239 0 01-.24-.24V5.29a.241.241 0 00-.154-.225 20.7 20.7 0 00-14.092 0zM10.76 16.34a.239.239 0 01.24.24v3.68a.239.239 0 01-.24.24H7.88a.239.239 0 01-.24-.24v-3.68a.239.239 0 01.24-.24zm-2.88-1.76a.239.239 0 01-.24-.24v-3.68a.239.239 0 01.24-.24h2.88a.239.239 0 01.24.24v3.68a.239.239 0 01-.24.24zm8.24 1.76a.239.239 0 01.24.24v3.68a.239.239 0 01-.24.24h-2.88a.239.239 0 01-.24-.24v-3.68a.239.239 0 01.24-.24zm-2.88-1.76a.239.239 0 01-.24-.24v-3.68a.239.239 0 01.24-.24h2.88a.239.239 0 01.24.24v3.68a.239.239 0 01-.24.24zM16.8 8.4a.239.239 0 01-.24.24H7.44a.239.239 0 01-.24-.24V6.48a.239.239 0 01.24-.24h9.12a.239.239 0 01.24.24z\"/><path d=\"M21.384 4.519a.481.481 0 00.7-.429V2.771a.482.482 0 00-.254-.424A19.936 19.936 0 0012.48 0h-.96a19.936 19.936 0 00-9.346 2.347.482.482 0 00-.254.424V4.09a.481.481 0 00.7.429 19.905 19.905 0 018.9-2.119h.96a19.905 19.905 0 018.904 2.119z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});