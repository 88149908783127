define("ember-svg-jar/inlined/kitchenware-hammer-meat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchenware-hammer-meat</title><path d=\"M12.988 12.427a.5.5 0 010 .707L2.56 23.56a1.5 1.5 0 01-2.121-2.12l10.426-10.427a.5.5 0 01.707 0zM22.614 8.954a1.093 1.093 0 00-.587-.8C18.1 6.188 17.812 5.9 15.845 1.974a1.088 1.088 0 00-1.745-.282L9.32 6.476A1.09 1.09 0 009.6 8.22c3.924 1.961 4.213 2.249 6.18 6.18a1.089 1.089 0 00.8.588 1.017 1.017 0 00.173.014 1.094 1.094 0 00.772-.319L22.309 9.9a1.092 1.092 0 00.305-.946zM21.8 8.6zm-7.345-6.555zm2.72 12.284zM8.25 5a.75.75 0 01-.75-.75v-1.5A.75.75 0 018.25 2H9a.5.5 0 00.5-.5V.75a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5h-.25a.5.5 0 00-.5.5v.75a.75.75 0 01-.75.75H9.5A.5.5 0 009 4v.25a.75.75 0 01-.75.75zM21.25 16h-1.5a.75.75 0 010-1.5H20a.5.5 0 00.5-.5v-.75a.75.75 0 01.75-.75H22a.5.5 0 00.5-.5v-.25a.75.75 0 011.5 0v1.5a.75.75 0 01-.75.75h-.75a.5.5 0 00-.5.5v.75a.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});