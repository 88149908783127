define("ember-svg-jar/inlined/cleaning-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cleaning-man</title><path d=\"M11.97 18.014a.252.252 0 000-.213.248.248 0 00-.165-.134 2.2 2.2 0 01-1.17-.727 2.175 2.175 0 01-.517-1.438.252.252 0 00-.075-.181.249.249 0 00-.181-.072H8.245a.249.249 0 00-.25.25V23a1 1 0 001 1h3a.981.981 0 00.479-.124.252.252 0 00.122-.158.255.255 0 00-.032-.2 5.486 5.486 0 01-.594-5.504z\"/><circle cx=\"10.493\" cy=\"2.5\" r=\"2.5\"/><path d=\"M6.465 7.239a.249.249 0 01-.043.328A5.211 5.211 0 004.611 11.5a2.752 2.752 0 002.75 2.75h2.5a1.332 1.332 0 001.164-.823 1.24 1.24 0 00.086-.427 1.226 1.226 0 00-.092-.454 1.321 1.321 0 00-1.158-.8h-2.5a.25.25 0 01-.25-.25 2.942 2.942 0 01.412-1.24.25.25 0 01.47.119v.125a.25.25 0 00.25.25h1.618a2.242 2.242 0 012.157 1.65.251.251 0 00.4.125l.423-.352a.327.327 0 01.037-.028.25.25 0 00.114-.21v-1.349a.077.077 0 01.15-.026l.563 1.547a.754.754 0 01-.224.832l-.489.408-1.432 1.192a1.254 1.254 0 00-.16 1.761h.005a1.245 1.245 0 00.956.446 1.228 1.228 0 00.611-.183 17.941 17.941 0 002.11-1.708 3.258 3.258 0 00.972-3.606l-.562-1.547a5.225 5.225 0 00-1.5-2.157A5.307 5.307 0 0013.228 7a5.219 5.219 0 00-2.669-.75h-.7a4.984 4.984 0 00-1.317.192.249.249 0 01-.273-.1l-2.966-4.4a1 1 0 00-1.657 1.114zM14.425 17.257a3.893 3.893 0 00-.731 5.264l.44.654a1 1 0 001.388.271l.4-.268a.249.249 0 00.078-.331l-1.256-2.2a.5.5 0 01.869-.5l1.2 2.1a.254.254 0 00.16.119.25.25 0 00.2-.036l1.176-.792a.251.251 0 00.077-.332l-1.18-2.065a.5.5 0 01.869-.5l1.125 1.969a.254.254 0 00.16.119.25.25 0 00.2-.036l.492-.331a1 1 0 00.271-1.389l-.559-.829a3.751 3.751 0 00-5.379-.887z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});