define("ember-svg-jar/inlined/tiger-head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tiger-head</title><path d=\"M23.508 5.284l-1.39-.837a3.757 3.757 0 00-.089-1.915 3.445 3.445 0 00-2.6-2.305 3.349 3.349 0 00-2.639.341 3.62 3.62 0 00-1.37 1.674A35.406 35.406 0 001.035 9.454 2.813 2.813 0 00.184 12.6l3 8.136a1.967 1.967 0 001.4 1.235c1.32.319 2.13-.741 2.674-1.164a10.171 10.171 0 003.477 2.972 1 1 0 001.447-1.07c-.252-1.344-.456-2.871-.614-4.264 3.352-1.213 4.816.32 4.869.378a1 1 0 001.4.126 6.425 6.425 0 00.571-.558 11.834 11.834 0 013.642 4.829 1 1 0 001.942-.335V6.141a1 1 0 00-.484-.857zm-6.267 11.354a.25.25 0 01-.331.077 6.7 6.7 0 00-5.27-.391.249.249 0 01-.317-.218c-.045-.49-.08-.914-.107-1.248a.249.249 0 01.206-.265 1.066 1.066 0 01.57.011 1 1 0 002 0 2.076 2.076 0 00-.768-1.567 2.782 2.782 0 00-2.463-.349 26.4 26.4 0 00-6.851 3.373.25.25 0 01-.367-.125l-.822-2.227a.251.251 0 01.091-.291 50.717 50.717 0 019.007-5.177c2.425-.956 5.56-1.783 6.721-.294a3.234 3.234 0 01.572 1.8.25.25 0 01-.272.265l-2.26-.2a1 1 0 00-.175 1.993l2.241.2a.249.249 0 01.179.1.246.246 0 01.044.2c-.094.447-.208.888-.332 1.308a.251.251 0 01-.349.155l-1.261-.61a1 1 0 00-.87 1.8l1.461.706a.249.249 0 01.109.347 9.275 9.275 0 01-.386.627zm-7.512 3.333a.25.25 0 01-.435.2 8.98 8.98 0 01-1.931-3.622.249.249 0 01.119-.283q.8-.448 1.44-.755a.249.249 0 01.357.2c.093 1.089.242 2.643.45 4.26zm-4.461-2.4a.249.249 0 01.216-.023.254.254 0 01.152.155 11.477 11.477 0 00.484 1.2.251.251 0 01-.074.3c-.25.194-.489.39-.714.589a.249.249 0 01-.4-.1l-.5-1.367a.249.249 0 01.1-.3c.211-.125.46-.281.736-.451z\"/><circle cx=\"12.242\" cy=\"10.551\" r=\"1.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});