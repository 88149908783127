define("ember-svg-jar/inlined/socket-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>socket-box</title><path d=\"M24 3.5A2.5 2.5 0 0021.5 1h-14A2.5 2.5 0 005 3.5v14.813a.188.188 0 01-.188.188 1 1 0 01-1-1v-8A2.846 2.846 0 001 6.5a1 1 0 100 2c.6 0 .812.539.812 1v8a3 3 0 003 3h.036a.156.156 0 01.152.148A2.5 2.5 0 007.5 23h14a2.5 2.5 0 002.5-2.5zM18.5 14a3 3 0 11-3 3 3 3 0 013-3zm-3-7a3 3 0 113 3 3 3 0 01-3-3zm-5 3a3 3 0 113-3 3 3 0 01-3 3zm3 7a3 3 0 11-3-3 3 3 0 013 3z\"/><path d=\"M17.5 8a.5.5 0 00.5-.5v-.952a.5.5 0 00-1 0V7.5a.5.5 0 00.5.5zM19.5 8a.5.5 0 00.5-.5v-.952a.5.5 0 00-1 0V7.5a.5.5 0 00.5.5zM9.5 6.048a.5.5 0 00-.5.5V7.5a.5.5 0 001 0v-.952a.5.5 0 00-.5-.5zM11.5 6.048a.5.5 0 00-.5.5V7.5a.5.5 0 101 0v-.952a.5.5 0 00-.5-.5zM17.5 18a.5.5 0 00.5-.5v-.953a.5.5 0 00-1 0v.953a.5.5 0 00.5.5zM19.5 18a.5.5 0 00.5-.5v-.953a.5.5 0 00-1 0v.953a.5.5 0 00.5.5zM9.5 16.048a.5.5 0 00-.5.5v.952a.5.5 0 001 0v-.953a.5.5 0 00-.5-.499zM11.5 16.048a.5.5 0 00-.5.5v.952a.5.5 0 001 0v-.953a.5.5 0 00-.5-.499z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});