define("ember-svg-jar/inlined/temperature-thermometer-low", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>temperature-thermometer-low</title><path d=\"M12.524 16.424a1 1 0 01-.274-.687V3a3 3 0 00-6 0v12.736a1 1 0 01-.274.688A4.455 4.455 0 004.75 19.5a4.5 4.5 0 107.774-3.076zM9.25 21.5a2 2 0 01-1-3.733.5.5 0 00.25-.433V12a.75.75 0 011.5 0v5.335a.5.5 0 00.25.433 2 2 0 01-1 3.732zM19.25 12a1 1 0 01-1 1h-3.5a1 1 0 010-2h3.5a1 1 0 011 1zM15.75 4h-1a1 1 0 010-2h1a1 1 0 010 2zM15.75 8.5h-1a1 1 0 010-2h1a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});