define("ember-svg-jar/inlined/show-theater-show-masks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>show-theater-show-masks</title><path d=\"M8.814 11.51a13.6 13.6 0 002.994-.356 13.612 13.612 0 002.849-1 .5.5 0 01.71.335l.215.926a1 1 0 101.948-.451l-.739-3.194a1 1 0 00-1.828-.295c-.315.517-1.654 1.276-3.606 1.727s-3.485.357-3.995.031a1 1 0 00-1.512 1.073l.934 4.042a7.336 7.336 0 002.159 3.614 1 1 0 101.379-1.449 5.422 5.422 0 01-1.59-2.613l-.41-1.773a.5.5 0 01.492-.613z\"/><path d=\"M22.189 12.152a.5.5 0 00-.546.114c-.631.643-2.431 1.076-4.481 1.076-2.048 0-3.847-.433-4.477-1.076a.5.5 0 00-.857.349v4.349c0 3.916 3.833 7.036 5.338 7.036s5.334-3.12 5.334-7.036v-4.349a.5.5 0 00-.311-.463zm-7.143 5.3a.907.907 0 11.907-.907.907.907 0 01-.907.908zm3.377 3.312a.5.5 0 01-.409.212h-1.7a.5.5 0 01-.471-.667 1.4 1.4 0 012.645 0 .5.5 0 01-.065.456zm.856-3.312a.907.907 0 11.907-.907.907.907 0 01-.907.908zM3.067 15.5H.5a.5.5 0 00-.5.5v6.692A1.308 1.308 0 001.308 24h3.923a.656.656 0 00.656-.675c-.219-4.932-1.922-7.1-2.457-7.669a.5.5 0 00-.363-.156zM.5 14H2a1 1 0 000-2H.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5zM23 0h-6.993a.576.576 0 00-.507.646c.259 5.294 3.69 9.532 7.977 9.853.284.021.522-.273.522-.625V1.237A1.138 1.138 0 0023 0zM.523 10.5C4.809 10.178 8.24 5.94 8.5.646A.576.576 0 007.993 0H1a1.139 1.139 0 00-1 1.237v8.637c0 .352.238.646.523.626z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});