define("ember-svg-jar/inlined/audio-file-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file-edit</title><path d=\"M14 13.588a2.246 2.246 0 002.233-2.088.734.734 0 00.017-.084V6.247c0-.141.178-.18.271-.122l1.08.68a.75.75 0 10.8-1.269l-1.818-1.141A1.25 1.25 0 0014.75 5.5v3.4a.246.246 0 01-.294.244 2.029 2.029 0 00-1.47.221 2.314 2.314 0 00-1.229 1.8A2.253 2.253 0 0014 13.588z\"/><path d=\"M23.414 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v8.988a.249.249 0 00.073.177l1.5 1.5A.25.25 0 008 12.488V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-7.777a.248.248 0 00-.237.327c.149.434.337 1.154.423 1.489a.245.245 0 00.241.183H22a2 2 0 002-2V4.415A2 2 0 0023.414 3zM11.15 18.444l-6.58-6.579a2.71 2.71 0 00-3.606-.274 2.623 2.623 0 00-.2 3.882l6.679 6.678a.249.249 0 00.353 0L11.15 18.8a.252.252 0 000-.356zM11.938 20.131l-2.809 2.808a.25.25 0 00.128.422l3.143.629a.473.473 0 00.1.01.5.5 0 00.354-.146.5.5 0 00.136-.452l-.628-3.143a.25.25 0 00-.424-.128z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});