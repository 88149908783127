define("ember-svg-jar/inlined/single-woman-actions-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-player</title><path d=\"M8.962 9.315a.5.5 0 00-.756.1l-1.734 2.726a.494.494 0 01-.836 0L3.917 9.4a.5.5 0 00-.754-.1C2.011 10.356 2.1 10.763.141 17.9a.494.494 0 00.477.624h2.3a.248.248 0 01.243.2l.919 4.839v.007a.494.494 0 00.482.388h2.962a.493.493 0 00.482-.388v-.007l.919-4.839a.248.248 0 01.243-.2h1.082a.247.247 0 00.247-.247V12.6a.237.237 0 00-.009-.065 5.823 5.823 0 00-1.526-3.22zM6.031 8.4a4.2 4.2 0 10-4.2-4.2 4.205 4.205 0 004.2 4.2zm0-1.976A2.22 2.22 0 013.9 4.8a.245.245 0 01.157-.3 4.613 4.613 0 001.8-1.127.247.247 0 01.174-.073.243.243 0 01.176.074 4.623 4.623 0 001.8 1.126.247.247 0 01.157.3 2.22 2.22 0 01-2.133 1.623zM22.014 12.066h-8.209a1.865 1.865 0 00-1.862 1.863v8.209A1.865 1.865 0 0013.805 24h8.209a1.865 1.865 0 001.863-1.862v-8.209a1.865 1.865 0 00-1.863-1.863zm-6.574 7.766v-3.6a.918.918 0 011.391-.787l3 1.8a.919.919 0 010 1.576l-3 1.8a.919.919 0 01-1.391-.788z\"/><path d=\"M8.962 9.315a.5.5 0 00-.756.1l-1.734 2.726a.494.494 0 01-.836 0L3.917 9.4a.5.5 0 00-.754-.1C2.011 10.356 2.1 10.763.141 17.9a.494.494 0 00.477.624h2.3a.248.248 0 01.243.2l.919 4.839v.007a.494.494 0 00.482.388h2.962a.493.493 0 00.482-.388v-.007l.919-4.839a.248.248 0 01.243-.2h1.082a.247.247 0 00.247-.247V12.6a.237.237 0 00-.009-.065 5.823 5.823 0 00-1.526-3.22zM6.031 8.4a4.2 4.2 0 10-4.2-4.2 4.205 4.205 0 004.2 4.2zm0-1.976A2.22 2.22 0 013.9 4.8a.245.245 0 01.157-.3 4.613 4.613 0 001.8-1.127.247.247 0 01.174-.073.243.243 0 01.176.074 4.623 4.623 0 001.8 1.126.247.247 0 01.157.3 2.22 2.22 0 01-2.133 1.623zM22.014 12.066h-8.209a1.865 1.865 0 00-1.862 1.863v8.209A1.865 1.865 0 0013.805 24h8.209a1.865 1.865 0 001.863-1.862v-8.209a1.865 1.865 0 00-1.863-1.863zm-6.574 7.766v-3.6a.918.918 0 011.391-.787l3 1.8a.919.919 0 010 1.576l-3 1.8a.919.919 0 01-1.391-.788z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});