define("ember-svg-jar/inlined/webcam-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>webcam-1</title><path d=\"M24 8.5A5.506 5.506 0 0018.5 3h-13a5.5 5.5 0 000 11h4.75a.25.25 0 01.25.25v2.654a.251.251 0 01-.208.246 9.884 9.884 0 00-3.783 1.487 1.389 1.389 0 00-.5 1.316A1.054 1.054 0 006.958 21h10.084a1.054 1.054 0 00.946-1.047 1.387 1.387 0 00-.5-1.315 9.892 9.892 0 00-3.784-1.489.249.249 0 01-.204-.249v-2.649a.25.25 0 01.25-.25h4.75A5.506 5.506 0 0024 8.5zm-12 3a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});