define("ember-svg-jar/inlined/recycling-bag-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>recycling-bag-1</title><path d=\"M24 22.421l-.894-17A1.5 1.5 0 0021.605 4H17.1a.5.5 0 01-.485-.381 4.751 4.751 0 00-9.228 0A.5.5 0 016.9 4H2.4A1.5 1.5 0 00.9 5.421l-.9 17A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.579zM9.716 3.222a2.745 2.745 0 014.568 0 .5.5 0 01-.415.778h-3.738a.5.5 0 01-.415-.778zM10 20.25H6.214A1.75 1.75 0 014.7 17.626l1.2-2.09a.5.5 0 00-.184-.683l-.649-.374a.5.5 0 01.119-.916l2.732-.734a.5.5 0 01.613.353l.734 2.732a.5.5 0 01-.179.525.489.489 0 01-.3.1.508.508 0 01-.25-.066l-.65-.375a.5.5 0 00-.683.183L6 18.375a.25.25 0 00.216.375H10a.75.75 0 010 1.5zm1.783-11.909l-1.775 3.078a.75.75 0 01-1.3-.749l1.775-3.078A1.752 1.752 0 0112 6.716a1.751 1.751 0 011.516.88L14.554 9.4a.5.5 0 00.683.185l.65-.373a.5.5 0 01.732.564l-.74 2.731a.5.5 0 01-.233.3.512.512 0 01-.249.066.487.487 0 01-.131-.018l-2.73-.739a.5.5 0 01-.118-.916l.651-.373a.5.5 0 00.184-.683l-1.037-1.802a.249.249 0 00-.433 0zm7.488 11.036a1.756 1.756 0 01-1.514.873H15.5a.5.5 0 00-.5.5v.75a.5.5 0 01-.309.462.512.512 0 01-.191.038.5.5 0 01-.354-.146l-2-2a.5.5 0 010-.708l2-2A.5.5 0 0115 17.5v.75a.5.5 0 00.5.5h2.257a.251.251 0 00.216-.125.247.247 0 000-.249l-1.827-3.185a.75.75 0 011.3-.747l1.827 3.185a1.754 1.754 0 01-.002 1.748z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});