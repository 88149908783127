define("ember-svg-jar/inlined/pasta-plate-warm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pasta-plate-warm</title><path d=\"M5.357 8.144a.345.345 0 01-.064.4 1 1 0 101.414 1.413 2.37 2.37 0 00.15-3.221c-.06-.1-.15-.252-.214-.38a.345.345 0 01.064-.4 1 1 0 00-1.414-1.413 2.37 2.37 0 00-.15 3.221c.057.101.15.252.214.38zM17.143 6.736a2.37 2.37 0 00.15 3.221 1 1 0 001.414-1.414.345.345 0 01-.064-.4c.064-.128.154-.279.214-.38a2.37 2.37 0 00-.15-3.221 1 1 0 00-1.414 1.414.345.345 0 01.064.4c-.063.128-.157.279-.214.38zM11.292 8.043a1 1 0 001.416 1.414c2.108-2.112.845-3.8.091-4.805s-.98-1.307-.092-2.2a1 1 0 00-1.414-1.414c-2.113 2.113-.85 3.8-.095 4.808s.979 1.312.094 2.197zM2.662 17.25h18.676a.5.5 0 00.471-.668 2.9 2.9 0 00-.8-1.192 5.8 5.8 0 00-3.627-1.163 1 1 0 01-.825-.473A5.163 5.163 0 0012 11.25a5.163 5.163 0 00-4.555 2.5 1 1 0 01-.825.473 5.793 5.793 0 00-3.627 1.167 2.9 2.9 0 00-.8 1.192.5.5 0 00.471.668zM23.25 18.5H.75a.75.75 0 000 1.5h1.016a1 1 0 01.947.679l.59 1.742a1.491 1.491 0 001.342.829h14.71a1.51 1.51 0 001.369-.893l.565-1.677a1 1 0 01.948-.68h1.013a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});