define("ember-svg-jar/inlined/video-file-mp4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-mp4</title><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.427A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6zM17.712 14.273a4.9 4.9 0 00-1.353 1.946.114.114 0 00.116.156h1.137a.25.25 0 00.25-.25v-1.784c0-.121-.101-.111-.15-.068zM12 13.625h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H12a.875.875 0 000-1.75z\"/><path d=\"M22 7.25a.25.25 0 00-.25-.25H17.5A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2zM9.125 19a.625.625 0 01-1.25 0v-3.13c0-.07-.084-.055-.111 0l-.205.409a.65.65 0 01-1.118 0s-.183-.356-.205-.409-.111-.052-.111 0V19a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279l.8 1.609a.166.166 0 00.272 0l.8-1.609A.625.625 0 019.125 13zm2.75-2.375h-.5a.25.25 0 00-.25.25V19a.625.625 0 01-1.25 0v-6a.625.625 0 01.625-.625H12a2.128 2.128 0 012.114 2.347 2.213 2.213 0 01-2.239 1.903zm7.237.375v2a.625.625 0 01-1.25 0v-1.127a.248.248 0 00-.248-.248H15.5a.624.624 0 01-.616-.731 6.146 6.146 0 013.327-4.445.625.625 0 01.9.561z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});