define("ember-svg-jar/inlined/pl-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pl-1</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM9.4 16.126H8.375a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0v-8a.625.625 0 01.625-.625h2a2.629 2.629 0 012.574 3.148A2.7 2.7 0 019.4 16.126zm8.1 4H15A2.127 2.127 0 0112.875 18v-6.5a.625.625 0 111.25 0V18a.877.877 0 00.875.875h2.5a.625.625 0 010 1.25z\"/><path d=\"M9.5 12.126H8.375a.25.25 0 00-.25.25v2.25a.25.25 0 00.25.25H9.5a1.375 1.375 0 000-2.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});