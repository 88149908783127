define("ember-svg-jar/inlined/safety-do-not-touch-fire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-do-not-touch-fire</title><path d=\"M8.415 14.419a.734.734 0 00-1.038 1.039.305.305 0 010 .431 1.774 1.774 0 000 2.508.734.734 0 001.038-1.039.307.307 0 010-.43 1.776 1.776 0 000-2.509zM10.315 15.458a.305.305 0 010 .431 1.776 1.776 0 000 2.508.735.735 0 001.039-1.039.3.3 0 010-.43 1.744 1.744 0 00.487-.966.246.246 0 00-.068-.212l-1.332-1.331a.091.091 0 00-.126 0 .735.735 0 000 1.039zM13.136 18.4a.734.734 0 001.038 0 .173.173 0 00.051-.123.169.169 0 00-.051-.122l-1.132-1.135a.245.245 0 00-.418.179 1.748 1.748 0 00.512 1.201zM4.7 10.668a.987.987 0 00.921.666 1.205 1.205 0 00.264-.04l.694-.236a.246.246 0 00.094-.4L5.532 9.51a.244.244 0 00-.279-.048.973.973 0 00-.553 1.206z\"/><path d=\"M22.36 5.957a11.382 11.382 0 00-2.42-2.935 11.946 11.946 0 00-16.61.7c-.017.015-.04.021-.056.037a.861.861 0 00-.061.092c-.124.133-.249.264-.366.4a12.109 12.109 0 00-1.073 1.5 11.993 11.993 0 0017.969 15.4c.138-.117.269-.242.4-.365a1.059 1.059 0 00.092-.062c.016-.016.022-.039.037-.057a11.95 11.95 0 002.088-14.71zM12 1.959a9.951 9.951 0 016.389 2.314.246.246 0 01.087.177.242.242 0 01-.071.185l-2.147 2.147a.245.245 0 01-.2.07L13.146 6.5a4.61 4.61 0 00-2.351.313l-1.934.926a.244.244 0 01-.279-.048L5.559 4.664a.246.246 0 01.016-.364A9.976 9.976 0 0112 1.959zm.146 7.32c-.264.206-.642.563-.859.772a.244.244 0 01-.343 0l-.353-.351a.244.244 0 010-.345 9.635 9.635 0 011.1-.964.49.49 0 11.589.782c-.049.038-.087.073-.134.11zm3.468 10.313H5.64a.505.505 0 01-.336-.127A10.005 10.005 0 013.2 7.176a9.575 9.575 0 01.666-1.037.245.245 0 01.371-.029L17.3 19.174a.245.245 0 01-.173.418zm4.086-1.167a.247.247 0 01-.362.016l-6.321-6.322a.244.244 0 010-.342c2.477-2.594 3.864-.719 6.346-3.176l1.187-1.194a.246.246 0 01.211-.068.241.241 0 01.18.13A9.941 9.941 0 0119.7 18.425z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});