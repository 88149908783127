define("ember-svg-jar/inlined/video-player-interface", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-player-interface</title><path d=\"M20.5 0h-17A3.5 3.5 0 000 3.5v17A3.5 3.5 0 003.5 24h17a3.5 3.5 0 003.5-3.5v-17A3.5 3.5 0 0020.5 0zM20 4.5v15a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-15a.5.5 0 01.5-.5h15a.5.5 0 01.5.5z\"/><path d=\"M12 6a6 6 0 106 6 6.006 6.006 0 00-6-6zm-2 4a.5.5 0 01.724-.447l4 2a.5.5 0 010 .894l-4 2A.5.5 0 0110 14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});