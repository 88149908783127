define("ember-svg-jar/inlined/wifi-signal-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-signal-1</title><circle cx=\"12\" cy=\"11.998\" r=\"1.5\"/><path d=\"M15.536 8.462a1 1 0 00-1.414 1.414 3.005 3.005 0 010 4.243 1 1 0 001.414 1.415 5.008 5.008 0 000-7.072zM9.878 8.462a1 1 0 00-1.414 0 5.008 5.008 0 000 7.072 1 1 0 001.414-1.415 3.005 3.005 0 010-4.243 1 1 0 000-1.414z\"/><path d=\"M18.01 5.988A1 1 0 0016.6 7.4a6.5 6.5 0 010 9.192 1 1 0 101.414 1.414 8.5 8.5 0 000-12.02zM5.5 12a6.455 6.455 0 011.9-4.6 1 1 0 00-1.41-1.412 8.5 8.5 0 000 12.02 1 1 0 101.41-1.414A6.455 6.455 0 015.5 12z\"/><path d=\"M20.485 3.513a1 1 0 10-1.414 1.414 10.011 10.011 0 010 14.142 1 1 0 101.414 1.414 12.013 12.013 0 000-16.97zM4.929 4.927a1 1 0 10-1.414-1.414 12.013 12.013 0 000 16.97 1 1 0 101.414-1.414 10.011 10.011 0 010-14.142z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});