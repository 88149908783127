define("ember-svg-jar/inlined/app-window-flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-flash</title><path d=\"M22.392 16h-1.285a.25.25 0 01-.25-.25V11.5a.5.5 0 00-.332-.47.5.5 0 00-.556.154L14.7 17.646A.821.821 0 0015.321 19h1.286a.25.25 0 01.25.25v4.25a.5.5 0 00.332.471.487.487 0 00.168.029.5.5 0 00.388-.183l5.272-6.462A.822.822 0 0022.392 16z\"/><path d=\"M12.451 19.223a.97.97 0 00-.951-.723H3a1 1 0 01-1-1V5.251A.25.25 0 012.25 5h19.5a.25.25 0 01.25.25v4.781a1 1 0 00.388.777l.022.018A.982.982 0 0024 10.05V3a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h8.506a1 1 0 00.95-1.255zM9.654 2a.966.966 0 011.692 0 .972.972 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.972.972 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0A.972.972 0 018 2.5a.969.969 0 01-.154.5.966.966 0 01-1.692 0A.969.969 0 016 2.5a.972.972 0 01.154-.5zm-3.562.092A1 1 0 013.5 1.5a.985.985 0 01.846.5.972.972 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.979.979 0 01.092-.407z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});