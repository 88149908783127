define("ember-svg-jar/inlined/dresser-double-door", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dresser-double-door</title><path d=\"M2.75 15h8a.5.5 0 00.5-.5V5a.5.5 0 00-.5-.5h-8a.5.5 0 00-.5.5v9.5a.5.5 0 00.5.5zm6-6.75a1 1 0 11-1 1 1 1 0 011-1zM21.25 4.5h-8a.5.5 0 00-.5.5v9.5a.5.5 0 00.5.5h8a.5.5 0 00.5-.5V5a.5.5 0 00-.5-.5zm-6 5.75a1 1 0 111-1 1 1 0 01-1 1z\"/><rect x=\".25\" width=\"23.5\" height=\"3\" rx=\"1\" ry=\"1\"/><path d=\"M21.25 16.5H2.75a.5.5 0 00-.5.5v6a1 1 0 002 0v-1.02a.5.5 0 01.5-.5h14.5a.5.5 0 01.5.5V23a1 1 0 002 0v-6a.5.5 0 00-.5-.5zM12 20a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});