define("ember-svg-jar/inlined/style-three-pin-note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-note</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.541 14.153a1.5 1.5 0 001.92 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm5 12.748a2.25 2.25 0 11-2.25-2.25 2.116 2.116 0 01.446.05.249.249 0 00.3-.244v-4.73a.246.246 0 00-.1-.2.24.24 0 00-.219-.04l-5 1.428A.252.252 0 0010 7v6.745A2.25 2.25 0 117.75 11.5a2.116 2.116 0 01.446.05.249.249 0 00.3-.244V7a1.757 1.757 0 011.273-1.68l5-1.429A1.75 1.75 0 0117 5.574z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});