define("ember-svg-jar/inlined/responsive-design", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>responsive-design</title><path d=\"M20.5 12a1 1 0 001-1V7.088A2.548 2.548 0 0019 4.5H8.5a1 1 0 000 2H19a.551.551 0 01.5.588V11a1 1 0 001 1zM10.75 18.5a.25.25 0 01.25.25v2a.25.25 0 01-.25.25H9a1 1 0 000 2h6a1 1 0 000-2h-1.75a.25.25 0 01-.25-.25v-2a.25.25 0 01.25-.25h2.25a.5.5 0 00.5-.5v-2.111a2.757 2.757 0 01.105-.754.5.5 0 00-.482-.635H5a.5.5 0 01-.5-.5v-1.5a1 1 0 00-2 0v3.412A2.548 2.548 0 005 18.5zM1.833 9.75h3.334A1.613 1.613 0 006.75 8.111V1.889A1.613 1.613 0 005.167.25H1.833A1.613 1.613 0 00.25 1.889v6.222A1.613 1.613 0 001.833 9.75zM1.75 1.889c0-.085.055-.139.083-.139h3.334c.028 0 .083.054.083.139v6.222c0 .085-.055.139-.083.139H1.833c-.028 0-.083-.054-.083-.139zM18.833 14.25a1.613 1.613 0 00-1.583 1.639v6.361a1.545 1.545 0 001.583 1.5h3.334a1.545 1.545 0 001.583-1.5v-6.361a1.613 1.613 0 00-1.583-1.639zm3.417 1.639V21a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25v-5.111c0-.085.055-.139.083-.139h3.334c.033 0 .083.05.083.139z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});