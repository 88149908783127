define("ember-svg-jar/inlined/single-man-actions-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-key</title><path d=\"M15.082 11.922l-3.935-1.687a.248.248 0 01-.147-.229V8.871a.251.251 0 01.073-.177 3.3 3.3 0 00.779-2.23.248.248 0 01.089-.176 1.876 1.876 0 00.537-1.4 1.577 1.577 0 00-.317-1.014.25.25 0 01-.033-.265 2.534 2.534 0 00.156-1.726C11.939.5 10.237 0 8.884 0a3.753 3.753 0 00-3.162 1.328.25.25 0 01-.212.118 1.073 1.073 0 00-.9.375c-.371.492-.1 1.17.176 1.731a.251.251 0 01-.045.288 1.3 1.3 0 00-.162.2 1.762 1.762 0 00-.261.96 1.521 1.521 0 00.638 1.331.249.249 0 01.117.194A3.159 3.159 0 005.918 8.7.25.25 0 016 8.884v1.122a.249.249 0 01-.152.229l-3.937 1.687c-1.272.546-1.454 1.7-1.706 3.307-.056.359-.117.751-.2 1.179a.5.5 0 00.106.411A.5.5 0 00.5 17h9.416a.249.249 0 00.223-.138 5.29 5.29 0 01.3-.522 5.985 5.985 0 015.661-2.708.25.25 0 00.261-.337 2.249 2.249 0 00-1.279-1.373z\"/><path d=\"M24 11.631a.5.5 0 00-.5-.5h-1.978a.5.5 0 00-.343.136l-4.187 3.956a.253.253 0 01-.245.057 4.454 4.454 0 10-1.791 8.687 4.355 4.355 0 00.509.029 4.451 4.451 0 004.259-5.729.252.252 0 01.063-.249l4.067-4.056a.5.5 0 00.146-.354zm-7.162 8.985a1.736 1.736 0 01-2.455-2.455 1.778 1.778 0 012.455 0 1.736 1.736 0 010 2.454z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});