define("ember-svg-jar/inlined/picture-landscape", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>picture-landscape</title><path d=\"M24 3a3 3 0 00-3-3H3a3 3 0 00-3 3v18a3 3 0 003 3h18a3 3 0 003-3zm-2 18a1 1 0 01-1 1H3a1 1 0 01-1-1V3a1 1 0 011-1h18a1 1 0 011 1z\"/><path d=\"M4 8a2 2 0 002 2h3a3 3 0 10-2.762-4.155A.252.252 0 016 6a1.945 1.945 0 00-1.134.35A2 2 0 004 8zM17.483 13.852a1.9 1.9 0 00-3.348-.11l-1.978 3.391a.249.249 0 01-.4.043 5.1 5.1 0 00-3.486-1.865c-2.366 0-4.051 4.043-4.235 4.5a.5.5 0 00.464.689H20a.5.5 0 00.447-.724z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});