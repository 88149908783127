define("ember-svg-jar/inlined/data-file-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-information</title><path d=\"M21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.275a.247.247 0 00.242.25c.366.007 1.038.034 1.485.082A.245.245 0 008 9.359V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.245.245 0 00-.241.21 7.966 7.966 0 01-.364 1.459.243.243 0 00.228.33H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M14.268 9.7l1.507.9A1 1 0 0017 10.452a.968.968 0 00.105-.124l2.688-3.734a1 1 0 10-1.624-1.168l-2.015 2.8a.251.251 0 01-.332.068L14.3 7.388a1 1 0 00-1.33.279l-2.042 2.884A.243.243 0 0011 10.9a8.06 8.06 0 011.128.932.243.243 0 00.372-.032l1.438-2.03a.249.249 0 01.33-.07zM6.5 11a6.5 6.5 0 106.5 6.5A6.508 6.508 0 006.5 11zM6 13.249a1 1 0 11-1 1 1 1 0 011-1zm2 8.5H5a.75.75 0 010-1.5h.627a.123.123 0 00.123-.123v-2.251a.125.125 0 00-.125-.125H5a.75.75 0 010-1.5h1a1.252 1.252 0 011.25 1.25v2.623a.127.127 0 00.126.127H8a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});