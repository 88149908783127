define("ember-svg-jar/inlined/video-game-controller-team", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-controller-team</title><path d=\"M19.5 9.5h-15a4 4 0 00-4 4v8.919A1.586 1.586 0 002.124 24C3.964 23.975 6 19.492 6 19.492A3.01 3.01 0 018.593 18h6.817A3 3 0 0118 19.482s1.995 4.512 3.86 4.516a1.627 1.627 0 001.64-1.62V13.5a4 4 0 00-4-4zM7.5 15h-.75a.5.5 0 00-.5.5v.75a.75.75 0 01-1.5 0v-.75a.5.5 0 00-.5-.5H3.5a.75.75 0 110-1.5h.75a.5.5 0 00.5-.5v-.75a.75.75 0 011.5 0V13a.5.5 0 00.5.5h.75a.75.75 0 010 1.5zm8.5 0a1 1 0 111-1 1 1 0 01-1 1zm2.5 2a1 1 0 111-1 1 1 0 01-1 1zm0-4a1 1 0 111-1 1 1 0 01-1 1zm2.5 2a1 1 0 111-1 1 1 0 01-1 1z\"/><circle cx=\"3.5\" cy=\"2.203\" r=\"2.199\"/><path d=\"M6.307 8a.5.5 0 00.453-.71 3.587 3.587 0 00-6.519 0A.5.5 0 00.7 8z\"/><circle cx=\"20.499\" cy=\"2.199\" r=\"2.199\"/><path d=\"M23.759 7.285a3.588 3.588 0 00-6.519 0 .5.5 0 00.454.71h5.612a.5.5 0 00.453-.71z\"/><circle cx=\"12\" cy=\"2.199\" r=\"2.199\"/><path d=\"M9.194 8h5.612a.5.5 0 00.453-.71 3.588 3.588 0 00-6.519 0 .5.5 0 00.454.71z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});