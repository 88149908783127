define("ember-svg-jar/inlined/time-clock-file-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-clock-file-block</title><path d=\"M23.414 3L21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.276a.244.244 0 00.255.244 8.165 8.165 0 011.46.086A.245.245 0 008 9.365V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.121a.5.5 0 01.146.355V18a.5.5 0 01-.5.5h-6.865a.242.242 0 00-.24.209 8 8 0 01-.363 1.459.244.244 0 00.229.331H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M6.5 11a6.5 6.5 0 106.5 6.5A6.5 6.5 0 006.5 11zm-3.967 8.6a4.45 4.45 0 01-.367-3.32 4.5 4.5 0 013.112-3.116 4.444 4.444 0 013.325.367.252.252 0 01.052.4l-5.724 5.724a.252.252 0 01-.398-.055zm8.3-.887a4.494 4.494 0 01-3.112 3.116 4.438 4.438 0 01-3.321-.362.252.252 0 01-.052-.4l5.724-5.722a.252.252 0 01.4.051 4.448 4.448 0 01.362 3.321zM15.5 13.251A4.75 4.75 0 1010.75 8.5a4.756 4.756 0 004.75 4.751zm0-8A3.25 3.25 0 1112.25 8.5a3.254 3.254 0 013.25-3.249z\"/><path d=\"M15.5 9.251h1.768a.75.75 0 100-1.5H16.5a.252.252 0 01-.25-.251v-.767a.75.75 0 00-1.5 0V8.5a.75.75 0 00.75.751z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});