define("ember-svg-jar/inlined/social-media-snapchat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-snapchat</title><path d=\"M20.83 18.67l2.17-.44a.9.9 0 00.12-1.69 16.45 16.45 0 01-3-1.8 1.45 1.45 0 01.1-2.39l1.32-.83a1.45 1.45 0 00-1.56-2.46l-.85.54a.36.36 0 01-.56-.31V6.55a6.55 6.55 0 00-13.09 0v2.73a.36.36 0 01-.56.31l-.85-.54a1.45 1.45 0 00-1.56 2.46l1.32.83a1.45 1.45 0 01.1 2.39 16.45 16.45 0 01-3 1.8.9.9 0 00.07 1.7l2.15.44a.9.9 0 01.62 1.08 1.4 1.4 0 001.59 1.72l.68-.11a3.11 3.11 0 012.71.87A5.12 5.12 0 0012 24a5.12 5.12 0 003.23-1.78 3.11 3.11 0 012.71-.87l.68.11a1.4 1.4 0 001.59-1.72.9.9 0 01.62-1.07z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});