define("ember-svg-jar/inlined/landmark-mesoamerican-pyramid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-mesoamerican-pyramid</title><rect x=\"10\" y=\"9.5\" width=\"4\" height=\"14.5\" rx=\".5\" ry=\".5\"/><path d=\"M8.5 21.5H1a1 1 0 00-1 1v1a.5.5 0 00.5.5h8a.5.5 0 00.5-.5V22a.5.5 0 00-.5-.5zM8.5 17.5H3a1 1 0 00-1 1v1a.5.5 0 00.5.5h6a.5.5 0 00.5-.5V18a.5.5 0 00-.5-.5zM8.5 13.5H5a1 1 0 00-1 1v1a.5.5 0 00.5.5h4a.5.5 0 00.5-.5V14a.5.5 0 00-.5-.5zM8.5 9.5H7a1 1 0 00-1 1v1a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V10a.5.5 0 00-.5-.5zM23 21.5h-7.5a.5.5 0 00-.5.5v1.5a.5.5 0 00.5.5h8a.5.5 0 00.5-.5v-1a1 1 0 00-1-1zM15 18v1.5a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-1a1 1 0 00-1-1h-5.5a.5.5 0 00-.5.5zM15 14v1.5a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-1a1 1 0 00-1-1h-3.5a.5.5 0 00-.5.5zM15.5 12h2a.5.5 0 00.5-.5v-1a1 1 0 00-1-1h-1.5a.5.5 0 00-.5.5v1.5a.5.5 0 00.5.5zM7 2h10a1 1 0 000-2H7a1 1 0 000 2zM17 7.5v-4a.5.5 0 00-.5-.5h-9a.5.5 0 00-.5.5v4a.5.5 0 00.5.5H10a.5.5 0 00.5-.5v-1a1.5 1.5 0 013 0v1a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});