define("ember-svg-jar/inlined/fruit-strawberry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fruit-strawberry</title><path d=\"M11.492 4.847A5.545 5.545 0 006.653.008a1.026 1.026 0 00-1.145 1.144 5.543 5.543 0 004.839 4.84 1.025 1.025 0 001.145-1.145zM18.2.3a1.022 1.022 0 00-.852-.293 5.545 5.545 0 00-4.84 4.839 1.025 1.025 0 001.145 1.145 5.543 5.543 0 004.839-4.84A1.02 1.02 0 0018.2.3zM16 7H7.944c-3.132.351-4.825 2.413-4.645 5.66C3.563 17.4 8 24 12 24s8.437-6.6 8.7-11.337C20.881 9.416 19.188 7.354 16 7zm-4 3a1 1 0 11-1 1 1 1 0 011-1zm-4.5 2a1 1 0 111-1 1 1 0 01-1 1zm2.5 4.5a1 1 0 111-1 1 1 0 01-1 1zm2 4.5a1 1 0 111-1 1 1 0 01-1 1zm2-4.5a1 1 0 111-1 1 1 0 01-1 1zm2.5-4.5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});