define("ember-svg-jar/inlined/vintage-car", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-car</title><path d=\"M4.75 14.309a3.5 3.5 0 103.5 3.5 3.5 3.5 0 00-3.5-3.5zm0 5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM19.25 14.309a3.5 3.5 0 103.5 3.5 3.5 3.5 0 00-3.5-3.5zm0 5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/><path d=\"M23.729 12.949a6.253 6.253 0 00-1.95-1.36.249.249 0 01-.136-.15 2 2 0 00-1.893-1.38h-1.5a2.5 2.5 0 00-2.435 1.957.251.251 0 01-.106.153.134.134 0 01-.209-.112V5.809a3.119 3.119 0 00-3.63-3.076l-2.055.342A2.764 2.764 0 007.5 5.809v4.441a.25.25 0 01-.333.236 7.281 7.281 0 00-2.417-.427H4a.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0v2.109a.25.25 0 01-.144.227 6.255 6.255 0 00-1.834 1.3.954.954 0 00-.272.672 1 1 0 00.622.93 1 1 0 001.1-.223 4.216 4.216 0 013.028-1.265 4.2 4.2 0 014.232 3.863 1.246 1.246 0 001.245 1.137h3.546a1.246 1.246 0 001.245-1.138 4.254 4.254 0 017.258-2.6A1.007 1.007 0 0024 13.617a.957.957 0 00-.271-.668zM13.5 9.826a.25.25 0 01-.25.25h-3.5a.25.25 0 01-.25-.25V7.059a.25.25 0 01.25-.25h3.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});