define("ember-svg-jar/inlined/monetization-structure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monetization-structure</title><path d=\"M4 18a1 1 0 001-1v-1.75a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25V17a1 1 0 002 0v-1.75a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25V17a1 1 0 002 0v-2.5a1.5 1.5 0 00-1.5-1.5h-15A1.5 1.5 0 003 14.5V17a1 1 0 001 1zM12 0a6 6 0 106 6 6.006 6.006 0 00-6-6zm-1.167 5.25h2.334a1.917 1.917 0 010 3.834H13a.249.249 0 00-.25.25V9.5a.75.75 0 01-1.5 0v-.166a.249.249 0 00-.25-.25H9.667a.75.75 0 110-1.5h3.5a.417.417 0 000-.834h-2.334a1.917 1.917 0 010-3.833H11a.25.25 0 00.25-.25V2.5a.75.75 0 011.5 0v.167a.25.25 0 00.25.25h1.333a.75.75 0 010 1.5h-3.5a.417.417 0 000 .833z\"/><circle cx=\"4\" cy=\"21.5\" r=\"2.5\"/><rect x=\"9.5\" y=\"19\" width=\"5\" height=\"5\" rx=\".5\" ry=\".5\"/><path d=\"M20 19a.57.57 0 00-.51.315l-1.93 3.86a.57.57 0 00.51.825h3.86a.57.57 0 00.51-.825l-1.93-3.86A.57.57 0 0020 19z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});