define("ember-svg-jar/inlined/messages-bubble-warning-triangle-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-warning-triangle-1-alternate</title><path d=\"M10 15a1 1 0 00-.707.293L7 17.586V15.56a1 1 0 00-.664-.942A6.493 6.493 0 018.5 2h7a6.49 6.49 0 015.2 10.389.249.249 0 00-.021.266l.756 1.442a.25.25 0 00.408.05A8.49 8.49 0 0015.5 0h-7A8.492 8.492 0 005 16.232V20a1 1 0 001.707.707L10.414 17h1.478a.251.251 0 00.222-.134l.787-1.5a.246.246 0 00-.008-.245.249.249 0 00-.214-.121z\"/><path d=\"M18.781 12.271a1.45 1.45 0 00-2.562 0l-5.056 9.634a1.431 1.431 0 00.048 1.408 1.456 1.456 0 001.233.687h10.112a1.456 1.456 0 001.233-.687 1.431 1.431 0 00.048-1.408zM17.5 15.25a.75.75 0 01.75.75v2.5a.75.75 0 01-1.5 0V16a.75.75 0 01.75-.75zm0 7a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});