define("ember-svg-jar/inlined/road-sign-road-narrowing-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-road-narrowing-alternate</title><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/><path d=\"M10.75 5.5a1 1 0 00-1 1V9a.249.249 0 01-.065.168l-2.242 2.474-.007.008A1.49 1.49 0 007 12.707V16a1 1 0 002 0v-3a.249.249 0 01.065-.168l2.242-2.472.007-.008a1.511 1.511 0 00.436-1.057V6.5a1 1 0 00-1-1zM16.59 11.677l-2.275-2.5A.249.249 0 0114.25 9V6.5a1 1 0 00-2 0v2.793a1.481 1.481 0 00.4 1.022l.008.008 2.275 2.5A.249.249 0 0115 13v3a1 1 0 002 0v-3.293a1.461 1.461 0 00-.4-1.021z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});