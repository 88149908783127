define("ember-svg-jar/inlined/file-code-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-heart</title><path d=\"M17.751 11.5V15a.75.75 0 101.5 0v-3.5a.75.75 0 00-1.5 0zM13.751 8.5V5a.75.75 0 10-1.5 0v3.5a.75.75 0 001.5 0zM20.251 8V5.5A1.251 1.251 0 0019 4.251h-1.5a1.25 1.25 0 00-1.25 1.25V8a1.251 1.251 0 001.25 1.25H19A1.252 1.252 0 0020.251 8zm-1.5-.5a.25.25 0 01-.25.25H18a.249.249 0 01-.25-.25V6a.249.249 0 01.25-.25h.5a.25.25 0 01.25.25z\"/><path d=\"M23.415 3L21 .586A2 2 0 0019.587 0H8a2 2 0 00-2 2v9.572a.177.177 0 00.089.158l.267.15a.413.413 0 00.261.012 5.01 5.01 0 011.216-.592.243.243 0 00.167-.229V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.354.146l2.121 2.121a.5.5 0 01.146.355V18a.5.5 0 01-.5.5h-7.53a.251.251 0 00-.207.111c-.238.334-.845 1.027-1.244 1.474a.249.249 0 00.189.414H22a2 2 0 002-2V4.415A2 2 0 0023.415 3z\"/><path d=\"M12.629 14.45a3.508 3.508 0 00-3.045-1.95 3.661 3.661 0 00-2.695 1.126l-.212.212a.25.25 0 01-.353 0l-.212-.212A3.661 3.661 0 003.417 12.5a3.528 3.528 0 00-2.391 6.012l5.113 5.334a.5.5 0 00.723 0l5.106-5.327a3.524 3.524 0 00.661-4.069z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});