define("ember-svg-jar/inlined/outdoors-water-flask", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-water-flask</title><path d=\"M12.5 1.5A1.5 1.5 0 0011 0H7a1.5 1.5 0 00-1.5 1.5V4a.5.5 0 00.5.5h6a.5.5 0 00.5-.5zM21.753.5a1.3 1.3 0 00-2 0c-1.492 2-2.248 3.491-2.248 4.426a3.25 3.25 0 006.5 0C24 3.991 23.244 2.5 21.753.5zM10.237 14.737a.252.252 0 000 .354l6.88 6.879a.249.249 0 00.4-.057A3.982 3.982 0 0018 20v-5a8.96 8.96 0 00-1.99-5.644.25.25 0 00-.181-.093.257.257 0 00-.191.073zM8.823 13.677a.252.252 0 00.354 0L14.6 8.25a.25.25 0 00.074-.18.253.253 0 00-.079-.179 11.53 11.53 0 00-2.364-1.76A1 1 0 0011.739 6H6.26a.986.986 0 00-.5.132A11.548 11.548 0 003.4 7.891a.253.253 0 00-.079.179.25.25 0 00.074.18zM9.177 16.151a.252.252 0 00-.354 0l-6.9 6.9a.25.25 0 00.05.392A3.983 3.983 0 004 24h10a3.983 3.983 0 002.03-.553.25.25 0 00.05-.392zM7.763 15.091a.252.252 0 000-.354l-5.4-5.4a.253.253 0 00-.191-.073.25.25 0 00-.181.093A8.96 8.96 0 000 15v5a3.982 3.982 0 00.487 1.913.249.249 0 00.4.057z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});