define("ember-svg-jar/inlined/zip-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>zip-file</title><path d=\"M21.561 4.146L17.854.439A1.494 1.494 0 0016.793 0H3.5A1.5 1.5 0 002 1.5v21A1.5 1.5 0 003.5 24h17a1.5 1.5 0 001.5-1.5V5.207a1.494 1.494 0 00-.439-1.061zM9.5 22a1 1 0 111-1 1 1 0 01-1 1zm0-3.5a1 1 0 111-1 1 1 0 01-1 1zm.005-3.5a1 1 0 111-1 1 1 0 01-1 1zm0-3.5a1 1 0 111-1 1 1 0 01-1 1zm1.5-4.9a1.967 1.967 0 01-1.478.67 1.975 1.975 0 01-2.011-2.221l.49-3.127A.5.5 0 018.5 1.5h2a.5.5 0 01.5.423l.486 3.111v.016a1.986 1.986 0 01-.478 1.55z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});