define("ember-svg-jar/inlined/single-neutral-megaphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-megaphone</title><path d=\"M5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M23 0a1 1 0 00-1 1v.547a.251.251 0 01-.192.243L12.77 3.936a.25.25 0 01-.3-.182A1 1 0 0010.5 4v4a1 1 0 001.969.246.25.25 0 01.3-.182l1.038.247a.25.25 0 01.193.243V9.5a2.992 2.992 0 005.937.537.248.248 0 01.109-.165.251.251 0 01.194-.034l1.568.372a.251.251 0 01.192.243V11a1 1 0 002 0V1a1 1 0 00-1-1zm-5 9.5a1 1 0 01-2 0v-.353a.25.25 0 01.308-.243l1.5.357A.245.245 0 0118 9.5zM5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M23 0a1 1 0 00-1 1v.547a.251.251 0 01-.192.243L12.77 3.936a.25.25 0 01-.3-.182A1 1 0 0010.5 4v4a1 1 0 001.969.246.25.25 0 01.3-.182l1.038.247a.25.25 0 01.193.243V9.5a2.992 2.992 0 005.937.537.248.248 0 01.109-.165.251.251 0 01.194-.034l1.568.372a.251.251 0 01.192.243V11a1 1 0 002 0V1a1 1 0 00-1-1zm-5 9.5a1 1 0 01-2 0v-.353a.25.25 0 01.308-.243l1.5.357A.245.245 0 0118 9.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});