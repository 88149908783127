define("ember-svg-jar/inlined/movies-set-equipment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>movies-set-equipment</title><path d=\"M17.737 6.323a.25.25 0 00.177.427h5.836A.25.25 0 0024 6.5v-3A1.5 1.5 0 0022.5 2h-.336a.251.251 0 00-.177.073zM.25 8.25A.25.25 0 000 8.5v12A1.5 1.5 0 001.5 22h21a1.5 1.5 0 001.5-1.5v-12a.25.25 0 00-.25-.25zm11 3.25a.75.75 0 011.5 0v3.25a.75.75 0 01-1.5 0zM3.5 17.25h17a.75.75 0 010 1.5h-17a.75.75 0 010-1.5zM2.586 6.75a.251.251 0 00.177-.073l4.25-4.25A.25.25 0 006.836 2H1.5A1.5 1.5 0 000 3.5v3a.25.25 0 00.25.25zM5.237 6.323a.25.25 0 00.177.427h3.422a.251.251 0 00.177-.073l4.25-4.25A.25.25 0 0013.086 2H9.664a.251.251 0 00-.177.073zM11.487 6.323a.25.25 0 00.177.427h3.422a.251.251 0 00.177-.073l4.25-4.25A.25.25 0 0019.336 2h-3.422a.251.251 0 00-.177.073z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});