define("ember-svg-jar/inlined/align-left-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>align-left-right</title><path d=\"M22 4.5h-.5V1a1 1 0 00-2 0v3.5H19a1 1 0 00-1 1v13a1 1 0 001 1h.5V23a1 1 0 002 0v-3.5h.5a1 1 0 001-1v-13a1 1 0 00-1-1zM5 6.5h-.5V3a1 1 0 00-2 0v3.5H2a1 1 0 00-1 1v9a1 1 0 001 1h.5V21a1 1 0 002 0v-3.5H5a1 1 0 001-1v-9a1 1 0 00-1-1zM14.207 9.293a1 1 0 00-1.414 1.414L14.086 12l-1.293 1.293a1 1 0 001.414 1.414l2-2a1 1 0 000-1.414zM11.207 9.293a1 1 0 00-1.414 0l-2 2a1 1 0 000 1.414l2 2a1 1 0 001.414-1.414L9.914 12l1.293-1.293a1 1 0 000-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});