define("ember-svg-jar/inlined/dating-chat-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dating-chat-man</title><path d=\"M16.75 0c-4 0-7.25 2.7-7.25 6.008a5.385 5.385 0 001.691 3.861.5.5 0 01.115.538l-.647 1.656a.5.5 0 00.623.656l2.829-.935a1 1 0 01.554-.021 8.643 8.643 0 002.085.251c4 0 7.25-2.695 7.25-6.006S20.748 0 16.75 0zm3.059 6.2L17.33 8.788a.689.689 0 01-.995 0L13.856 6.2a1.761 1.761 0 012.49-2.491.687.687 0 00.973 0 1.761 1.761 0 012.49 2.491zM8.366 18.381a.251.251 0 01-.033-.425 4.4 4.4 0 10-5.166 0 .251.251 0 01-.033.425A5.754 5.754 0 000 23.5a.5.5 0 00.5.5H11a.5.5 0 00.5-.5 5.754 5.754 0 00-3.134-5.119zM5.75 16.8a2.4 2.4 0 01-2.276-3.155.25.25 0 01.372-.132 5.643 5.643 0 004 .8.253.253 0 01.211.061.25.25 0 01.081.2A2.4 2.4 0 015.75 16.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});