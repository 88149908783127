define("ember-svg-jar/inlined/insect-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>insect-1</title><path d=\"M23.087 8.4a.75.75 0 00-.832.5 2.785 2.785 0 01-1.176 1.476 1.42 1.42 0 01-1.171-.027C18.031 9.6 18 6.779 18 6.753v-.688a.5.5 0 01.327-.469 3.753 3.753 0 012.449-.033 1 1 0 10.577-1.914 5.565 5.565 0 00-4.637.518 9.88 9.88 0 00-3.075 3.368A8.5 8.5 0 008.186 6c-4.653 0-7.9 3.445-7.9 8.379 0 1.763.413 2.657 1.227 2.657h.506L1.7 18.477a.2.2 0 01-.2.148 1 1 0 00.014 2 2.174 2.174 0 002.136-1.714l.329-1.48a.5.5 0 01.488-.392h2.027a.5.5 0 01.5.571l-.133.929a.1.1 0 01-.1.086 1 1 0 100 2 2.11 2.11 0 002.078-1.8l.194-1.354a.5.5 0 01.495-.429h5.723a.5.5 0 01.488.392l.329 1.481a2.176 2.176 0 002.132 1.71 1 1 0 00.014-2 .2.2 0 01-.2-.148l-.184-.83a.5.5 0 01.488-.608h.832c2.857 0 4.565-1.836 4.565-3.611V9.137a.75.75 0 00-.628-.737zM4.094 12.215a.75.75 0 01-.417-.975A4.59 4.59 0 017.71 8.385a.75.75 0 010 1.5A3.107 3.107 0 005.069 11.8a.75.75 0 01-.975.415zm14.345.973a.954.954 0 11-.953.954.954.954 0 01.953-.954z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});