define("ember-svg-jar/inlined/single-neutral-briefcase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-briefcase</title><path d=\"M9.5 15.5a2.984 2.984 0 011.149-2.351.5.5 0 00.175-.517A5.5 5.5 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18H9a.5.5 0 00.5-.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M11 17.5v.25a.25.25 0 00.25.25h12.5a.25.25 0 00.25-.25V15.5a1.5 1.5 0 00-1.5-1.5h-1.4a.251.251 0 01-.232-.158l-.568-1.427a1.937 1.937 0 00-1.743-1.078h-2.116a1.958 1.958 0 00-1.77 1.138l-.54 1.367A.251.251 0 0113.9 14h-1.4a1.5 1.5 0 00-1.5 1.5zm5.04-4.415a.453.453 0 01.4-.248h2.118a.45.45 0 01.375.188l.249.633a.249.249 0 01-.232.342h-2.9a.25.25 0 01-.233-.341zM24 19.25a.25.25 0 00-.25-.25h-5a.25.25 0 00-.25.25V20a1 1 0 01-2 0v-.75a.25.25 0 00-.25-.25h-5a.25.25 0 00-.25.25v3.25a1.5 1.5 0 001.5 1.5h10a1.5 1.5 0 001.5-1.5zM9.5 15.5a2.984 2.984 0 011.149-2.351.5.5 0 00.175-.517A5.5 5.5 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18H9a.5.5 0 00.5-.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M11 17.5v.25a.25.25 0 00.25.25h12.5a.25.25 0 00.25-.25V15.5a1.5 1.5 0 00-1.5-1.5h-1.4a.251.251 0 01-.232-.158l-.568-1.427a1.937 1.937 0 00-1.743-1.078h-2.116a1.958 1.958 0 00-1.77 1.138l-.54 1.367A.251.251 0 0113.9 14h-1.4a1.5 1.5 0 00-1.5 1.5zm5.04-4.415a.453.453 0 01.4-.248h2.118a.45.45 0 01.375.188l.249.633a.249.249 0 01-.232.342h-2.9a.25.25 0 01-.233-.341zM24 19.25a.25.25 0 00-.25-.25h-5a.25.25 0 00-.25.25V20a1 1 0 01-2 0v-.75a.25.25 0 00-.25-.25h-5a.25.25 0 00-.25.25v3.25a1.5 1.5 0 001.5 1.5h10a1.5 1.5 0 001.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});