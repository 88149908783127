define("ember-svg-jar/inlined/e-commerce-cart-vr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-cart-vr</title><path d=\"M12.254 21.276a3.966 3.966 0 01-1.392-.251l-2.9-1.081a.251.251 0 00-.175 0L4.9 21.025a3.951 3.951 0 01-1.7.232.25.25 0 00-.2.423 6.79 6.79 0 009.77 0 .25.25 0 00-.2-.423 2.687 2.687 0 01-.316.019zM21.211 10.667a.97.97 0 10-.97.969.97.97 0 00.97-.969zM16.363 9.7a.969.969 0 10.969.969.969.969 0 00-.969-.969zM23.38.282a.726.726 0 00-.921.459L21.874 2.5a.25.25 0 01-.237.171h-7.213a.727.727 0 00-.7.936l1.454 4.848a.728.728 0 00.7.518h4.848a.727.727 0 00.69-.5L23.84 1.2a.727.727 0 00-.46-.918z\"/><path d=\"M15.636 14.675a1.891 1.891 0 00-1.626-1.841.25.25 0 01-.179-.124 6.814 6.814 0 00-11.9 0 .25.25 0 01-.179.124 1.891 1.891 0 00-1.629 1.841v2.417A2.739 2.739 0 001 19.081l.467.436a3.019 3.019 0 003.093.6l2.3-.86a2.9 2.9 0 012.036 0l2.3.86a3.022 3.022 0 003.092-.6l.467-.436a2.736 2.736 0 00.876-1.989zM9.575 17.44a4.853 4.853 0 00-3.392 0l-2.3.86a1.077 1.077 0 01-1.091-.2l-.467-.436a.781.781 0 01-.26-.571v-2.1a.249.249 0 01.25-.25H13.45a.249.249 0 01.249.25v2.1a.778.778 0 01-.26.571l-.467.436a1.076 1.076 0 01-1.09.2zM22.855 18.913l-.257-.037a1.461 1.461 0 01-1.234-1.234l-.037-.257a.485.485 0 00-.96 0l-.037.257a1.461 1.461 0 01-1.23 1.234l-.256.037a.485.485 0 000 .96l.257.037a1.461 1.461 0 011.234 1.234l.037.257a.485.485 0 00.96 0l.037-.257A1.461 1.461 0 0122.6 19.91l.256-.037a.485.485 0 000-.96zM.317 4.491a.73.73 0 00.731.722 2.8 2.8 0 012.589 2.6.728.728 0 00.712.721h.016a.727.727 0 00.726-.691 2.987 2.987 0 012.6-2.634.727.727 0 00.009-1.45 2.858 2.858 0 01-2.608-2.573v-.024a.727.727 0 00-.727-.727.727.727 0 00-.728.724 2.859 2.859 0 01-2.607 2.6.724.724 0 00-.713.732z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});