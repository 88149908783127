define("ember-svg-jar/inlined/programming-flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-flag</title><path d=\"M1.5 24A1.5 1.5 0 003 22.5v-3.83a.25.25 0 01.165-.235L4.371 18a8.766 8.766 0 017.381.971A10.33 10.33 0 0020 20.1l2.8-.873a1.655 1.655 0 001.2-1.583V4.485A1.652 1.652 0 0021.9 2.9l-2.172.692a9.36 9.36 0 01-7.45-1.025A9.779 9.779 0 004.052 1.5l-.717.261A.25.25 0 013 1.525V1.5a1.5 1.5 0 00-3 0v21A1.5 1.5 0 001.5 24zM15.22 8.031a.75.75 0 111.06-1.06l3 3a.749.749 0 010 1.06l-3 3a.75.75 0 11-1.06-1.06l2.293-2.293a.252.252 0 000-.354zm-4.207 6.182a.751.751 0 01-.475-.949l2-6a.75.75 0 111.424.474l-2 6a.751.751 0 01-.712.513.787.787 0 01-.237-.038zM5.22 11.031a.749.749 0 010-1.06l3-3a.75.75 0 011.06 1.06l-2.293 2.293a.252.252 0 000 .354l2.293 2.293a.75.75 0 11-1.06 1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});