define("ember-svg-jar/inlined/laptop-smiley-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-smiley-1</title><path d=\"M3.468 14.734a1 1 0 001-1V5.418a.667.667 0 01.667-.667H18.8a.667.667 0 01.667.667v8.316a1 1 0 002 0V5.418A2.67 2.67 0 0018.8 2.751H5.135a2.669 2.669 0 00-2.667 2.667v8.316a1 1 0 001 1zM23.9 16.443a.5.5 0 00-.395-.192h-8a.5.5 0 00-.5.5c0 .71-1.232 1.5-3 1.5s-3-.79-3-1.5a.5.5 0 00-.5-.5H.5a.5.5 0 00-.485.621 5.773 5.773 0 005.608 4.379h12.754a5.773 5.773 0 005.608-4.379.5.5 0 00-.085-.429z\"/><path d=\"M9.469 9.4a.75.75 0 00.75-.75V7.5a.75.75 0 10-1.5 0v1.145a.75.75 0 00.75.755zM15.219 8.645V7.5a.75.75 0 10-1.5 0v1.145a.75.75 0 001.5 0zM7.66 11.359a.75.75 0 000 1.061 6.249 6.249 0 008.839 0 .75.75 0 00-1.061-1.061 4.748 4.748 0 01-6.717 0 .75.75 0 00-1.061 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});