define("ember-svg-jar/inlined/settings-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>settings-user</title><path d=\"M15.75 6.5a2.5 2.5 0 01-2.5 2.5 1.872 1.872 0 01-.25-.024.219.219 0 00-.251.217V12.5a.5.5 0 00.5.5 6.53 6.53 0 001.083-.1.5.5 0 00.417-.493v-.969a.25.25 0 01.166-.236 5.011 5.011 0 00.48-.2.25.25 0 01.285.048l.688.687a.5.5 0 00.644.054 6.493 6.493 0 001.531-1.532.5.5 0 00-.054-.643l-.689-.685a.25.25 0 01-.048-.285q.113-.236.2-.48A.249.249 0 0118.191 8h.969a.5.5 0 00.493-.417 6.094 6.094 0 000-2.166A.5.5 0 0019.16 5h-.969a.25.25 0 01-.235-.166 5.602 5.602 0 00-.2-.481.25.25 0 01.048-.285l.687-.687a.5.5 0 00.054-.644 6.488 6.488 0 00-1.531-1.531.5.5 0 00-.644.053l-.688.687A.25.25 0 0115.4 2a4.774 4.774 0 00-.48-.2.25.25 0 01-.166-.235V.59a.5.5 0 00-.421-.49A6.524 6.524 0 0013.25 0a.5.5 0 00-.5.5v3.267a.25.25 0 00.283.248A1.745 1.745 0 0113.25 4a2.5 2.5 0 012.5 2.5zM10.75 0a6.5 6.5 0 000 13 .5.5 0 00.5-.5V.5a.5.5 0 00-.5-.5zM10.75 13.5a10.011 10.011 0 00-10 10 .5.5 0 00.5.5h9.5a.5.5 0 00.5-.5V14a.5.5 0 00-.5-.5zM23.115 21.923a.506.506 0 00-.495-.423h-1.432a.251.251 0 01-.241-.181 7.868 7.868 0 00-.331-.929.249.249 0 01.054-.275l1.1-1.1a.5.5 0 00.08-.6 9.965 9.965 0 00-2.873-3.1.5.5 0 00-.633.061L17.309 16.4a.249.249 0 01-.294.044 8.063 8.063 0 00-1.1-.484.25.25 0 01-.167-.236v-1.509a.5.5 0 00-.387-.488 10.054 10.054 0 00-2.127-.227.5.5 0 00-.486.5v4.8a.244.244 0 00.224.241 5.29 5.29 0 014.733 4.733.244.244 0 00.241.224h4.8a.5.5 0 00.5-.482 10.143 10.143 0 00-.131-1.593z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});