define("ember-svg-jar/inlined/style-three-pin-scale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-scale</title><path d=\"M15.6 9.623a.25.25 0 00.215.377h2.378a.25.25 0 00.215-.377L17.215 7.6a.248.248 0 00-.43 0zM5.6 9.623a.25.25 0 00.211.377h2.378a.25.25 0 00.211-.377L7.215 7.6a.248.248 0 00-.43 0z\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm5 13.5a3 3 0 01-3-3c0-.011.006-.021.006-.031a.786.786 0 01.052-.192.265.265 0 01.011-.03l1.688-2.87A.251.251 0 0015.541 7h-2.113a.253.253 0 00-.205.106 1.488 1.488 0 01-.367.367.252.252 0 00-.106.205V13.1a.259.259 0 00.009.068 2.717 2.717 0 001.488 1.872.754.754 0 01.486.836.745.745 0 01-.733.624h-4a.75.75 0 01-.242-1.46h.012a2.737 2.737 0 001.471-1.866.259.259 0 00.009-.068V7.678a.252.252 0 00-.106-.205 1.488 1.488 0 01-.367-.367.252.252 0 00-.205-.106H8.459a.251.251 0 00-.216.377l1.688 2.87a.265.265 0 01.011.03.786.786 0 01.052.192c0 .01.006.02.006.031a3 3 0 01-6 0c0-.011.006-.021.006-.031a.786.786 0 01.052-.192.265.265 0 01.011-.03l1.688-2.87A.251.251 0 005.541 7H5.25a.75.75 0 010-1.5h5.322a.251.251 0 00.205-.107 1.485 1.485 0 012.446 0 .25.25 0 00.205.107h5.322a.75.75 0 010 1.5h-.291a.251.251 0 00-.216.377l1.688 2.87a.265.265 0 01.011.03.786.786 0 01.052.192c0 .01.006.02.006.031a3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});