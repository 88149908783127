define("ember-svg-jar/inlined/video-game-logo-league-of-legends", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-logo-league-of-legends</title><path d=\"M13.9 5.353a7.995 7.995 0 013.814 12.738.249.249 0 00-.034.265.252.252 0 00.227.144h1.574a.748.748 0 00.643-.364 9.993 9.993 0 00-5.942-14.784.5.5 0 00-.631.482v1.041a.5.5 0 00.349.478zM5.406 5.3a.25.25 0 00-.266.035 9.988 9.988 0 000 15.338.25.25 0 00.411-.192V18.65a1 1 0 00-.223-.628 7.985 7.985 0 010-10.044 1 1 0 00.223-.628V5.523a.25.25 0 00-.145-.223z\"/><path d=\"M21.948 20h-9.4a.5.5 0 01-.5-.5V.5a.5.5 0 00-.5-.5H5.757A.5.5 0 005.4.854l1.357 1.353a1 1 0 01.293.707v18.672a1 1 0 01-.293.707l-.853.853a.5.5 0 00.353.854h13.066a.5.5 0 00.377-.171l2.625-3a.5.5 0 00-.377-.829z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});