define("ember-svg-jar/inlined/single-woman-actions-chat.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-chat</title><path d=\"M13 8.5h1.571a.25.25 0 00.22-.131.253.253 0 00-.011-.256 6.064 6.064 0 01-.68-2.942C13.838 1.346 11.236 0 9 0S4.161 1.346 3.9 5.171c-.145 2.132-.487 2.78-.981 3.317A.388.388 0 003 9.081a6.348 6.348 0 003.782.9.249.249 0 01.253.25v.462a.778.778 0 01-.512.73c-2.9 1.065-5.212 1.825-5.764 2.922A7.822 7.822 0 000 17.076a.386.386 0 00.1.3.4.4 0 00.29.126h8.86a.25.25 0 00.25-.25V12A3.5 3.5 0 0113 8.5z\"/><path d=\"M22 10h-9a2 2 0 00-2 2v7a2 2 0 002 2h.75a.25.25 0 01.25.25V23a1 1 0 001.625.781l3.407-2.726a.247.247 0 01.156-.055H22a2 2 0 002-2v-7a2 2 0 00-2-2zm-3.875 9.219l-1.719 1.375A.249.249 0 0116 20.4V20a1 1 0 00-1-1h-1.75a.25.25 0 01-.25-.25v-6.5a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v6.5a.249.249 0 01-.25.25h-3a1 1 0 00-.625.219z\"/><path d=\"M14.25 14.25A.75.75 0 0015 15h5a.75.75 0 000-1.5h-5a.75.75 0 00-.75.75zM18 17.5a.75.75 0 000-1.5h-3a.75.75 0 000 1.5h3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});