define("ember-svg-jar/inlined/multiple-actions-text.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-text</title><path d=\"M12.5 9.5h4.284a.5.5 0 00.209-.955 5.992 5.992 0 00-4.965-.013.5.5 0 00-.18.774l.019.023a.5.5 0 00.437.181c.065-.01.13-.01.196-.01z\"/><ellipse cx=\"14.013\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M10.293 10.488a.5.5 0 00-.012-.689A6.023 6.023 0 000 13.333.6.6 0 00.6 14H9a.5.5 0 00.5-.5v-1a2.959 2.959 0 01.793-2.012z\"/><ellipse cx=\"5.513\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M24 13a2 2 0 00-2-2h-9a2 2 0 00-2 2v9a2 2 0 002 2h9a2 2 0 002-2zm-11 .25a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v8.5a.249.249 0 01-.25.25h-8.5a.25.25 0 01-.25-.25z\"/><path d=\"M20 15h-4.75a.75.75 0 000 1.5H20a.75.75 0 000-1.5zM17.5 19.5a.75.75 0 000-1.5h-2.25a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});