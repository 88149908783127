define("ember-svg-jar/inlined/shelf-books-round", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shelf-books-round</title><path d=\"M21 23a1 1 0 00-1-1h-1.381a12 12 0 10-13.238 0H4a1 1 0 000 2h16a1 1 0 001-1zM3.206 7.262A.5.5 0 013.646 7h16.708a.5.5 0 01.44.262 9.9 9.9 0 011.183 4.225.25.25 0 01-.249.263H20a.5.5 0 01-.5-.5V8.5A.5.5 0 0019 8h-1a.5.5 0 00-.5.5v2.75a.5.5 0 01-.5.5h-1.282a.5.5 0 01-.485-.379l-.653-2.613A1 1 0 0013.609 8H12.5a.5.5 0 00-.485.621l.705 2.818a.251.251 0 01-.046.215.248.248 0 01-.2.1H11.5a.5.5 0 01-.5-.5V8.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v2.75a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5V8.5A.5.5 0 007 8H6a.5.5 0 00-.5.5v2.75a.5.5 0 01-.5.5H2.272a.25.25 0 01-.249-.263 9.9 9.9 0 011.183-4.225zm18.419 5.988a.251.251 0 01.248.288A9.94 9.94 0 0119.981 18a.481.481 0 01-.481-.481V14.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v3a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-3a.5.5 0 00-.5-.5H14a.5.5 0 00-.5.5v3a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5v-3A.5.5 0 007 14H6a.5.5 0 00-.5.5v3a.5.5 0 01-.5.5h-.728a.5.5 0 01-.407-.21 9.932 9.932 0 01-1.738-4.252.249.249 0 01.248-.288z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});