define("ember-svg-jar/inlined/list-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>list-add</title><path d=\"M6 2.5h13.5a1 1 0 000-2H6a1 1 0 000 2zM6 8.5h13.5a1 1 0 000-2H6a1 1 0 000 2zM10 13.5a1 1 0 00-1-1H6a1 1 0 100 2h3a1 1 0 001-1zM2.5.5h-1a1 1 0 000 2h1a1 1 0 000-2zM2.5 6.5h-1a1 1 0 000 2h1a1 1 0 100-2zM2.5 12.5h-1a1 1 0 000 2h1a1 1 0 000-2zM17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm.75 9a.75.75 0 11-1.5 0v-1.5a.25.25 0 00-.25-.25H15a.75.75 0 010-1.5h1.5a.25.25 0 00.25-.25V15a.75.75 0 111.5 0v1.5a.25.25 0 00.25.25H20a.75.75 0 010 1.5h-1.5a.25.25 0 00-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});