define("ember-svg-jar/inlined/single-woman-actions-upload.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-upload</title><path d=\"M11.79 11.915a.252.252 0 00.065-.231.249.249 0 00-.157-.179l-.224-.082a.779.779 0 01-.513-.73v-.717A6.675 6.675 0 0015 9.081a.388.388 0 00.084-.593c-.494-.537-.835-1.185-.981-3.317C13.838 1.346 11.236 0 9 0S4.161 1.346 3.9 5.171c-.145 2.132-.487 2.78-.981 3.317A.388.388 0 003 9.081a6.558 6.558 0 004.035.894v.718a.778.778 0 01-.512.73c-2.9 1.065-5.212 1.825-5.764 2.922A7.822 7.822 0 000 17.076a.386.386 0 00.1.3.4.4 0 00.29.126h8.864a.25.25 0 00.25-.242 7.958 7.958 0 012.286-5.345z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.851 6.467a.5.5 0 01-.451.283h-1.15a.25.25 0 00-.25.25v2.5a1 1 0 01-2 0V18a.25.25 0 00-.25-.25H15.1a.5.5 0 01-.391-.812l2.4-3a.519.519 0 01.782 0l2.4 3a.5.5 0 01.06.529z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});