define("ember-svg-jar/inlined/read-email-letter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-email-letter</title><path d=\"M21 10.5H3a1.974 1.974 0 00-2 1.942v9.616A1.974 1.974 0 003 24h18a1.974 1.974 0 002-1.942v-9.616a1.974 1.974 0 00-2-1.942zm-.5 4v2a1 1 0 01-1 1h-2a1 1 0 01-1-1v-2a1 1 0 011-1h2a1 1 0 011 1zm-16 4.75H10a.75.75 0 010 1.5H4.5a.75.75 0 010-1.5zm-.75-2.75a.75.75 0 01.75-.75h2a.75.75 0 010 1.5h-2a.75.75 0 01-.75-.75zM4 9a1 1 0 001-1V2h10.586L19 5.414V8a1 1 0 002 0V5a1 1 0 00-.293-.707l-4-4A1 1 0 0016 0H5a2 2 0 00-2 2v6a1 1 0 001 1z\"/><path d=\"M7.5 4.75a.75.75 0 000 1.5h6a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});