define("ember-svg-jar/inlined/allowances-smoking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>allowances-smoking</title><path d=\"M22.5 19.5A1.5 1.5 0 0024 18v-4a1.5 1.5 0 00-1.5-1.5h-18A1.5 1.5 0 003 14v4a1.5 1.5 0 001.5 1.5zM5 15a.5.5 0 01.5-.5H17a.5.5 0 01.5.5v2a.5.5 0 01-.5.5H5.5A.5.5 0 015 17zM1.5 13A1.5 1.5 0 000 14.5v3A1.5 1.5 0 001.5 19a.5.5 0 00.5-.5v-5a.5.5 0 00-.5-.5zM8 10a2 2 0 001.833-1.2.5.5 0 01.458-.3H13.5a1.5 1.5 0 000-3h-5a2 2 0 00-1.833 1.2.5.5 0 01-.458.3H4.5A3.5 3.5 0 001 10.5v.75a.75.75 0 001.5 0v-.75A.5.5 0 013 10zM18 7.5h1.5a1.5 1.5 0 000-3H18a1.5 1.5 0 000 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});