define("ember-svg-jar/inlined/plane-trip-take-off-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-trip-take-off-time</title><path d=\"M17.061 15.388a.249.249 0 00.04-.444l-2.36-1.478a.5.5 0 00-.265-.076.5.5 0 00-.23.055l-1.026.53a.5.5 0 00-.047.861l1.868 1.243a.252.252 0 00.231.024z\"/><path d=\"M22.721 14.3a1.271 1.271 0 00-.473.092l-9.931 3.964a.254.254 0 01-.2 0L9.8 17.314a.5.5 0 00-.454.023l-1.072.618a.5.5 0 00-.022.853l3.656 2.36a.5.5 0 00.271.08.493.493 0 00.184-.035l3.774-1.488a.25.25 0 01.325.323l-1.246 3.236a.5.5 0 00.467.68.51.51 0 00.23-.056l.966-.5.208-.2 2.657-4.854a.252.252 0 01.119-.109l3.378-1.488a1.278 1.278 0 00-.516-2.448zM0 6.526A6.49 6.49 0 106.49.036 6.5 6.5 0 000 6.526zm10.98 0a4.49 4.49 0 11-4.49-4.49 4.5 4.5 0 014.49 4.49z\"/><path d=\"M5.74 3.747v2.779a.75.75 0 00.75.75h2.495a.75.75 0 000-1.5H7.49a.25.25 0 01-.25-.25V3.747a.75.75 0 00-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});