define("ember-svg-jar/inlined/road-traffic-lights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-traffic-lights</title><path d=\"M5.25 2H2.707A1 1 0 002 3.707L5.074 6.78A.25.25 0 005.5 6.6V2.25A.25.25 0 005.25 2zM5.25 9H2.707A1 1 0 002 10.707l3.074 3.073a.25.25 0 00.426-.18V9.25A.25.25 0 005.25 9zM5.25 16H2.707A1 1 0 002 17.707l3.074 3.073a.25.25 0 00.426-.18v-4.35a.25.25 0 00-.25-.25zM18.655 6.834a.248.248 0 00.272-.054L22 3.707A1 1 0 0021.293 2H18.75a.25.25 0 00-.25.25V6.6a.248.248 0 00.155.234zM21.293 9H18.75a.25.25 0 00-.25.25v4.35a.25.25 0 00.427.177L22 10.707A1 1 0 0021.293 9zM21.293 16H18.75a.25.25 0 00-.25.25v4.35a.25.25 0 00.427.177L22 17.707A1 1 0 0021.293 16zM15.5 0h-7a2 2 0 00-2 2v20a2 2 0 002 2h7a2 2 0 002-2V2a2 2 0 00-2-2zM12 22a2.5 2.5 0 112.5-2.5A2.5 2.5 0 0112 22zm0-7.25a2.5 2.5 0 112.5-2.5 2.5 2.5 0 01-2.5 2.5zm0-7.25A2.5 2.5 0 1114.5 5 2.5 2.5 0 0112 7.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});