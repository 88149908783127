define("ember-svg-jar/inlined/trees-chop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trees-chop</title><path d=\"M22.166 15.7a1 1 0 10-1.421-1.4l-1.412 1.426a.249.249 0 01-.418-.108 1.453 1.453 0 00-1.433-1.118H5.429a1.5 1.5 0 00-1.5 1.422A11.29 11.29 0 011.746 22.4a1 1 0 00.8 1.6H5.8a.5.5 0 00.5-.5v-3a.75.75 0 011.5 0v3a.5.5 0 00.5.5h1.911a.5.5 0 00.5-.5v-3a.75.75 0 011.5 0v3a.5.5 0 00.5.5H14.8a.5.5 0 00.5-.5v-3a.75.75 0 011.5 0v3a.5.5 0 00.5.5h3.071a1 1 0 00.8-1.6 10.453 10.453 0 01-1.741-3.567.5.5 0 01.128-.486c.396-.401 1.342-1.365 2.608-2.647zM4.457 10.75a1.249 1.249 0 00.585-.146l.5-.267a.5.5 0 01.727.353 10.13 10.13 0 01.111 2.285.5.5 0 00.5.525h5.908a.51.51 0 00.212-.048l2.236-1.059a1 1 0 00.05-1.783 10.551 10.551 0 01-3.585-2.9.5.5 0 01.169-.738l8.7-4.62A1.25 1.25 0 1019.4.147l-8.978 4.767a.5.5 0 01-.541-.047 1.006 1.006 0 00-1.063-.107L5.644 6.347A1 1 0 005.1 7.372a.5.5 0 01-.26.51l-.971.518a1.25 1.25 0 00.588 2.353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});