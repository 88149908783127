define("ember-svg-jar/inlined/design-file-ai-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-ai-1</title><path d=\"M23.707 5.708L18.293.293A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.75V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.706z\"/><path d=\"M7 12.125a.876.876 0 00-.872.875v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V13A.877.877 0 007 12.125z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zm-8.872-1.5a.625.625 0 01-1.25 0v-2.125a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-4.375a2.212 2.212 0 011.9-2.239A2.128 2.128 0 019.128 13zm3.247-.625h.125a.625.625 0 010 1.25h-2a.625.625 0 110-1.25h.125a.249.249 0 00.25-.249v-4.249a.252.252 0 00-.253-.252H10.5a.625.625 0 110-1.25h2a.625.625 0 010 1.25h-.126a.248.248 0 00-.249.249v4.249a.252.252 0 00.25.252z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});