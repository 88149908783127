define("ember-svg-jar/inlined/coffee-delivery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>coffee-delivery</title><circle cx=\"14.214\" cy=\"3.5\" r=\"3.5\"/><path d=\"M16.344 15.064c-.1-.14-.242-.316-.189-.488s.2-.108.291-.016l1.254 1.254a2.1 2.1 0 003 .026 2 2 0 00-.075-2.758l-3.8-3.722a.25.25 0 00-.367.018l-4.07 4.883a.251.251 0 00.007.319 1.486 1.486 0 01.318.92v3a1.5 1.5 0 01-1.5 1.5h-2.49a.25.25 0 00-.218.128l-.661 1.186c-.168.29.021.669.567 1.991a.911.911 0 00.411.483 2.057 2.057 0 002.565-.692l2.622-3.787a.25.25 0 01.411 0l2.613 3.773a2 2 0 103.354-2.182s-3.943-5.7-4.043-5.836z\"/><rect x=\"7.714\" y=\"15\" width=\"4\" height=\"4\" rx=\".5\" ry=\".5\"/><path d=\"M5.393 10.5H3.036a.25.25 0 00-.249.273l.363 4A.251.251 0 003.4 15h1.63a.251.251 0 00.249-.227l.363-4a.249.249 0 00-.249-.273zM3.7 9.5h1.014a.238.238 0 00.25-.215V9a.252.252 0 01.093-.194l1.45-.616a.75.75 0 10-.586-1.38s-1.578.671-1.607.69a1.756 1.756 0 00-.85 1.5v.285A.221.221 0 003.7 9.5zM10.537 14a.249.249 0 00.192-.09l4.454-5.344a.252.252 0 00-.108-.4 3.3 3.3 0 00-3.132.661L10.03 10.5a1.993 1.993 0 01-1.316.5h-1.5a1.819 1.819 0 00-.349.036.364.364 0 00-.272.329l-.289 3.167c-.012.144 0 .239.222.342a.309.309 0 00.389-.116A1.5 1.5 0 018.214 14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});