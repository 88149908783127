define("ember-svg-jar/inlined/diagram-arrow-diagonal-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-arrow-diagonal-1</title><path d=\"M22.459 0l-9.177.342a1.014 1.014 0 00-.682 1.731L14.934 4.4a.251.251 0 010 .358L4.762 14.934a.251.251 0 01-.358 0L2.072 12.6a1.015 1.015 0 00-1.731.68L0 22.46A1.479 1.479 0 001.487 24h.054l9.177-.34a1.014 1.014 0 00.679-1.73L9.066 19.6a.254.254 0 010-.359L19.238 9.066a.253.253 0 01.358 0l2.332 2.334a1.014 1.014 0 001.731-.68L24 1.541A1.479 1.479 0 0022.459 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});