define("ember-svg-jar/inlined/office-glue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-glue</title><path d=\"M12.606.577C12.477.055 12.139 0 12 0s-.477.057-.606.579v.017L9.98 7.2a.25.25 0 00.244.3h3.551a.25.25 0 00.245-.3zM16.676 12.1a.251.251 0 01-.176-.239V10.5A1.5 1.5 0 0015 9H9a1.5 1.5 0 00-1.5 1.5v1.358a.251.251 0 01-.176.239A2.49 2.49 0 005.5 14.5v7A2.5 2.5 0 008 24h8a2.5 2.5 0 002.5-2.5v-7a2.49 2.49 0 00-1.824-2.4zM16 20a.5.5 0 01-.5.5h-7A.5.5 0 018 20v-4a.5.5 0 01.5-.5h7a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});