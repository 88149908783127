define("ember-svg-jar/inlined/send-email-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>send-email-2</title><path d=\"M22.985.638a.5.5 0 00-.719-.559L1.783 10.818a1.462 1.462 0 00-.1 2.528l4.56 2.882a.248.248 0 00.3-.025L18.7 5.335a.25.25 0 01.362.343L9.346 17.864a.249.249 0 00-.051.2.252.252 0 00.113.17L15.84 22.3a1.453 1.453 0 002.19-.895zM7.885 19.182a.25.25 0 00-.384.211v3.584a1 1 0 001.707.708l2.017-2.017a.251.251 0 00-.043-.388z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});