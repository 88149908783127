define("ember-svg-jar/inlined/single-woman-actions-mobilephone.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-mobilephone</title><path d=\"M13.5 15.112a.507.507 0 00-.085-.28A7.533 7.533 0 007.206 11.5a7.542 7.542 0 00-7.185 5.351.5.5 0 00.479.642H13a.5.5 0 00.5-.5zM22.526 11.005h-6.052A1.493 1.493 0 0015 12.513v9.979A1.493 1.493 0 0016.474 24h6.052A1.493 1.493 0 0024 22.492v-9.979a1.493 1.493 0 00-1.474-1.508zM17 21.5v-8a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v8a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5zM1.566 10.442a1 1 0 001.383-.3 5.233 5.233 0 00.454-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .249.249 0 01.411.082 5.233 5.233 0 00.454.9 1 1 0 001.681-1.082 4.569 4.569 0 01-.647-2.511V5.3a5.3 5.3 0 10-10.59 0v1.248a4.573 4.573 0 01-.642 2.512 1 1 0 00.298 1.382zM7.208 8.6a2.706 2.706 0 01-2.742-2.352.5.5 0 01.324-.577 5.708 5.708 0 002.246-1.439.249.249 0 01.36 0 5.719 5.719 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.208 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});