define("ember-svg-jar/inlined/cash-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-user</title><path d=\"M17.159 8.145a.249.249 0 01-.027.432 4.762 4.762 0 00-2.656 4.262v2.851a.5.5 0 00.5.5h1.458a.251.251 0 01.249.225l.645 6.448a.5.5 0 00.5.45h2.85a.5.5 0 00.5-.45l.644-6.448a.251.251 0 01.249-.225h1.459a.5.5 0 00.5-.5v-2.851a4.777 4.777 0 00-2.481-4.186.252.252 0 01-.13-.213.25.25 0 01.119-.218 4.081 4.081 0 10-4.373-.077zM20 12.03a.75.75 0 11-1.5 0v-1.692a.75.75 0 011.5 0zm-2.537-7.989a.251.251 0 01.361-.125 5.056 5.056 0 002.6.722 5.1 5.1 0 00.756-.058.252.252 0 01.288.247 2.073 2.073 0 01-4.145-.063 2 2 0 01.14-.723z\"/><path d=\"M1.476 15.812h8.694a1.5 1.5 0 001.5-1.5V13.2a.252.252 0 01.122-.215 1.448 1.448 0 00.564-.593l2.465-4.881a1.5 1.5 0 00-.663-2.014L6.4 1.574a1.5 1.5 0 00-1.145-.085 1.484 1.484 0 00-.869.749L1.917 7.12c-.011.024-.022.046-.031.069a.251.251 0 01-.232.156h-.178a1.5 1.5 0 00-1.5 1.5v5.467a1.5 1.5 0 001.5 1.5zm2.752-8.831l1.6-3.172a.251.251 0 01.338-.109l6.421 3.24a.25.25 0 01.111.336L11.881 8.9a.112.112 0 01-.211-.05 1.5 1.5 0 00-1.5-1.5H4.451a.25.25 0 01-.223-.364zM1.976 9.6a.249.249 0 01.25-.25H9.42a.249.249 0 01.25.25v3.967a.25.25 0 01-.25.25H2.226a.25.25 0 01-.25-.25z\"/><circle cx=\"5.929\" cy=\"11.677\" r=\"1.212\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});