define("ember-svg-jar/inlined/appliances-stand-alone-mixer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>appliances-stand-alone-mixer</title><path d=\"M24 2a2 2 0 00-2-2h-.75a.5.5 0 00-.5.5V4a.75.75 0 01-1.5 0V.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5V4a.75.75 0 01-1.5 0V.5a.5.5 0 00-.5-.5H7.5A4.505 4.505 0 003 4.5V7a1 1 0 001 1h2a.5.5 0 01.5.5v.577a.5.5 0 01-.349.477 1.158 1.158 0 00-.469.283c-.439.436-.83.768-1.157 1.048-.985.844-1.917 1.64-1.367 3.371C4.144 17.359 5.645 19 7.5 19s3.356-1.641 4.342-4.744c.552-1.738-.382-2.534-1.37-3.376-.339-.289-.723-.616-1.154-1.043a1.158 1.158 0 00-.469-.283.5.5 0 01-.349-.477V8.5A.5.5 0 019 8h4a1 1 0 011 1v7.5a3.5 3.5 0 01-3.5 3.5H2a2 2 0 000 4h20.5a1.5 1.5 0 001.5-1.5zM6.5 15.713a.25.25 0 01-.461.134 9.46 9.46 0 01-.975-2.2c-.148-.468-.148-.468.761-1.246l.261-.224a.25.25 0 01.414.189zM9.175 12.4c.91.776.91.776.761 1.249a9.46 9.46 0 01-.975 2.2.25.25 0 01-.461-.134v-3.347a.25.25 0 01.413-.189zM19 20a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});