define("ember-svg-jar/inlined/analytics-bars-3d", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>analytics-bars-3d</title><path d=\"M6.277 13.218l3.6-2.4a.137.137 0 00.062-.115.138.138 0 00-.062-.116L6.624 8.419a1.219 1.219 0 00-1.248 0l-3.252 2.168a.138.138 0 00-.062.116.137.137 0 00.062.115l3.6 2.4a.5.5 0 00.553 0z\"/><path d=\"M1.542 11.683a.078.078 0 00-.042.07v8.612a1.618 1.618 0 00.615 1.18l3.27 2.281a1.157 1.157 0 001.23 0l3.27-2.281a1.618 1.618 0 00.615-1.18v-8.612a.08.08 0 00-.124-.066L6.832 14.05a1.5 1.5 0 01-1.664 0l-3.544-2.363a.078.078 0 00-.082-.004zM13.542 3.433a.078.078 0 00-.042.07v16.862a1.618 1.618 0 00.615 1.18l3.27 2.281a1.157 1.157 0 001.23 0l3.27-2.281a1.618 1.618 0 00.615-1.18V3.5a.08.08 0 00-.124-.066L18.832 5.8a1.5 1.5 0 01-1.664 0l-3.544-2.363a.078.078 0 00-.082-.004z\"/><path d=\"M18.277 4.968l3.6-2.4a.137.137 0 00.062-.115.138.138 0 00-.062-.116L18.624.169a1.219 1.219 0 00-1.248 0l-3.252 2.168a.138.138 0 00-.062.116.137.137 0 00.062.115l3.6 2.4a.5.5 0 00.553 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});