define("ember-svg-jar/inlined/people-woman-cap-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-cap-2</title><path d=\"M2.847 13.524a.5.5 0 00-.231.8 3.8 3.8 0 002.921 1.116 5.351 5.351 0 001.317-.161 3.461 3.461 0 001.029-.437.25.25 0 01.274 0 6.972 6.972 0 007.641.032.252.252 0 01.275 0 3.46 3.46 0 001.073.461 5.351 5.351 0 001.317.161 3.8 3.8 0 002.921-1.119.5.5 0 00-.231-.8C18.682 12.79 18.961 9.348 19 7.744a.25.25 0 01.25-.244H22a1 1 0 000-2h-2.968a.248.248 0 01-.242-.19A7 7 0 005 7c0 1.953.468 5.682-2.153 6.524zM7 9V7.75a.25.25 0 01.25-.25h9.5a.25.25 0 01.25.25V9A5 5 0 017 9z\"/><path d=\"M.5 20.394V23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-3.106a2.464 2.464 0 00-.967-1.977 14.264 14.264 0 00-5.908-2.481.5.5 0 00-.552.279A4.557 4.557 0 0112 19a4.557 4.557 0 01-4.073-2.785.5.5 0 00-.552-.279 14.264 14.264 0 00-5.908 2.481A2.464 2.464 0 00.5 20.394z\"/><circle cx=\"14\" cy=\"9.25\" r=\"1\"/><circle cx=\"10\" cy=\"9.25\" r=\"1\"/><path d=\"M13.077 11.362h-2a.639.639 0 00-.3 1.2 2.31 2.31 0 00.435.18 2.99 2.99 0 00.867.126h.006a2.976 2.976 0 00.863-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});