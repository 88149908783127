define("ember-svg-jar/inlined/deer-target", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>deer-target</title><path d=\"M24 12a1 1 0 00-1-1h-.1a.5.5 0 01-.495-.432 10.522 10.522 0 00-8.97-8.97A.5.5 0 0113 1.1V1a1 1 0 00-2 0v.1a.5.5 0 01-.432.5 10.522 10.522 0 00-8.97 8.97.5.5 0 01-.498.43H1a1 1 0 000 2h.1a.5.5 0 01.5.432 10.522 10.522 0 008.97 8.97.5.5 0 01.43.498v.1a1 1 0 002 0v-.1a.5.5 0 01.432-.495 10.522 10.522 0 008.97-8.97A.5.5 0 0122.9 13h.1a1 1 0 001-1zm-10.406 8.349a.5.5 0 01-.594-.491v-.108a1 1 0 00-2 0v.108a.5.5 0 01-.594.491 8.52 8.52 0 01-6.755-6.755.5.5 0 01.491-.594h.108a1 1 0 000-2h-.108a.5.5 0 01-.491-.594 8.52 8.52 0 016.755-6.755.5.5 0 01.594.491v.108a1 1 0 002 0v-.108a.5.5 0 01.594-.491 8.52 8.52 0 016.755 6.755.5.5 0 01-.491.594h-.108a1 1 0 000 2h.108a.5.5 0 01.491.594 8.52 8.52 0 01-6.755 6.755z\"/><path d=\"M17 7a1 1 0 00-1 1v.25a.25.25 0 01-.25.25H15a1 1 0 000 2h.75a.25.25 0 01.25.25 1 1 0 01-1 1 .741.741 0 01-.53-.224A1.737 1.737 0 0013.219 11h-2.438a1.735 1.735 0 00-1.251.526.739.739 0 01-.53.224 1 1 0 01-1-1 .25.25 0 01.25-.25H9a1 1 0 000-2h-.75A.25.25 0 018 8.25V8a1 1 0 00-2 0v2.75a3 3 0 003 3 .387.387 0 01.366.262l.664 1.951a2.031 2.031 0 003.932.037l.671-1.982A.388.388 0 0115 13.75a3 3 0 003-3V8a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});