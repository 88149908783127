define("ember-svg-jar/inlined/mobile-shopping-cart-exchange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mobile-shopping-cart-exchange</title><path d=\"M9.787 20.377l-2.99-2A.75.75 0 005.63 19v.171a.25.25 0 01-.384.211A4 4 0 013.38 16a1 1 0 00-2 0 6.01 6.01 0 004.08 5.685.251.251 0 01.17.237V23a.75.75 0 00.4.662.757.757 0 00.354.088.746.746 0 00.417-.127l2.99-2a.749.749 0 000-1.246zM14.956 3.625l3.01 2A.75.75 0 0019.131 5v-.17a.25.25 0 01.384-.211A4 4 0 0121.38 8a1 1 0 002 0 6.01 6.01 0 00-4.08-5.684.251.251 0 01-.17-.237V1a.75.75 0 00-1.164-.625l-3.01 2a.751.751 0 000 1.25z\"/><circle cx=\"7.361\" cy=\"11\" r=\"1\"/><circle cx=\"3.361\" cy=\"11\" r=\"1\"/><path d=\"M10.607.289a.747.747 0 00-.949.474l-.6 1.816a.25.25 0 01-.237.171H1.37a.752.752 0 00-.719.966l1.5 5a.752.752 0 00.719.534h5a.751.751 0 00.712-.512l2.5-7.5a.751.751 0 00-.475-.949zM21.37 9.75h-5a1.752 1.752 0 00-1.75 1.75V22a1.693 1.693 0 001.625 1.75H21.5A1.692 1.692 0 0023.12 22V11.5a1.752 1.752 0 00-1.75-1.75zm-2.5 13a.75.75 0 11.75-.75.749.749 0 01-.75.75zm-2.5-11.5h5a.251.251 0 01.25.25V20a.25.25 0 01-.25.25h-5a.25.25 0 01-.25-.25v-8.5a.251.251 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});