define("ember-svg-jar/inlined/monetization-touch-coin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monetization-touch-coin</title><path d=\"M8.5 17a8.45 8.45 0 003.864-.93.25.25 0 00.136-.222V14a3 3 0 013-3 2.875 2.875 0 01.823.123.25.25 0 00.31-.168A8.5 8.5 0 108.5 17zm1.75-8H7.083a2.333 2.333 0 010-4.666h.584a.25.25 0 00.25-.25V3.5a.75.75 0 011.5 0v.584a.25.25 0 00.25.25h2.166a.75.75 0 110 1.5h-4.75a.833.833 0 000 1.666h3.167a2.334 2.334 0 010 4.667h-.583a.249.249 0 00-.25.25V13a.75.75 0 01-1.5 0v-.583a.249.249 0 00-.25-.25H5.5a.75.75 0 010-1.5h4.75a.834.834 0 000-1.667z\"/><path d=\"M14 21.16a.25.25 0 01-.438.165L12 19.538a1.5 1.5 0 00-2.309 1.915c.006.007-.1-.108 2.237 2.465a.246.246 0 00.185.082H23.75a.249.249 0 00.25-.25V22.5a4 4 0 00-4-4h-2.75a.25.25 0 01-.25-.25V14a1.5 1.5 0 00-3 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});