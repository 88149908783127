define("ember-svg-jar/inlined/cryptho-currency-megacoin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cryptho-currency-megacoin</title><path d=\"M12 1a11 11 0 1011 11A11 11 0 0012 1zM8 14.5a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V11a2 2 0 014 0zm6 0a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V11a2 2 0 014 0zm5.5.5h-3a.5.5 0 01-.5-.5V11a2 2 0 014 0v3.5a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});