define("ember-svg-jar/inlined/cursor-hand-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-hand-1</title><path d=\"M19.7 9.141a.251.251 0 00-.334.236V11.5a.5.5 0 11-1 0V9a1.617 1.617 0 00-1.5-1h-.25a.25.25 0 00-.25.25v2.25a.5.5 0 11-1 0V8a1.617 1.617 0 00-1.5-1h-.25a.25.25 0 00-.25.25v3.25a.5.5 0 11-1 0V3a2 2 0 00-4 0v11.078a.25.25 0 01-.454.145l-1.673-2.348a1.984 1.984 0 00-3.233 2.3l5.951 8.4a1 1 0 00.816.425h8.714a1 1 0 00.962-.725l1.517-5.292a10.738 10.738 0 00.4-2.883v-2.6A2.5 2.5 0 0019.7 9.141z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});