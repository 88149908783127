define("ember-svg-jar/inlined/real-estate-settings-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-settings-building</title><path d=\"M22.421 9.763l-1.266-.451a1.375 1.375 0 01-.781-1.885l.576-1.213a2.375 2.375 0 00-3.164-3.165l-1.213.576a1.376 1.376 0 01-1.886-.781l-.45-1.266a2.375 2.375 0 00-4.474 0l-.451 1.267a1.377 1.377 0 01-1.885.781l-1.214-.578A2.375 2.375 0 003.05 6.214l.576 1.213a1.375 1.375 0 01-.782 1.885l-1.264.451a2.374 2.374 0 000 4.474l1.265.45a1.377 1.377 0 01.781 1.886l-.576 1.213a2.375 2.375 0 003.164 3.164l1.213-.576a1.375 1.375 0 011.885.781l.451 1.266a2.374 2.374 0 004.474 0l.45-1.267a1.377 1.377 0 011.886-.78l1.213.576a2.375 2.375 0 003.164-3.164l-.575-1.213a1.375 1.375 0 01.78-1.886l1.266-.45a2.374 2.374 0 000-4.474zM16.5 17.5a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5v-2a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v2a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5v-9a.5.5 0 01.235-.424l4-2.5a.5.5 0 01.53 0l4 2.5a.5.5 0 01.235.424z\"/><path d=\"M13.5 9h-3a.75.75 0 000 1.5h3a.75.75 0 000-1.5zM13.5 12h-3a.75.75 0 000 1.5h3a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});