define("ember-svg-jar/inlined/beach-palm-sunbed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beach-palm-sunbed</title><path d=\"M23 19h-9.885a.5.5 0 01-.479-.356l-.909-3.036a1 1 0 00-1.916.575l1.124 3.748a1.482 1.482 0 00.592.808.5.5 0 01.167.637l-.589 1.177a1 1 0 001.79.894l1.085-2.171a.5.5 0 01.447-.276h5.146a.5.5 0 01.447.276l1.085 2.171a1 1 0 001.79-.894l-.415-.829a.5.5 0 01.447-.724H23a1 1 0 000-2zM7.521 15.5H3.7a.5.5 0 00-.481.361 25.818 25.818 0 00-.594 2.549.5.5 0 00.493.59h4.17a.5.5 0 00.5-.483 20.6 20.6 0 01.227-2.441.5.5 0 00-.494-.576zM7.807 20.472a.5.5 0 00-.5-.472H2.813a.5.5 0 00-.5.433c-.039.294-.077.59-.108.9L2 23.45a.5.5 0 00.5.55h5a.5.5 0 00.5-.531L7.809 20.5zM8.4 10.464l-3.475 1.584a.5.5 0 00-.227.207 15.054 15.054 0 00-.766 1.553.5.5 0 00.46.7h3.529a.5.5 0 00.485-.377 20.578 20.578 0 011.035-3.059l-.473-.493a.5.5 0 00-.568-.115z\"/><path d=\"M9.2 9.369l5.762 6.013a1.007 1.007 0 00.723.307.929.929 0 00.222-.026 1 1 0 00.728-.663 5.7 5.7 0 00-1.7-5.428A6.428 6.428 0 0010.673 7.5a.5.5 0 01-.491-.5.512.512 0 01.509-.491 6 6 0 011.785.315.5.5 0 00.4-.036l4.993-2.748a1 1 0 00.063-1.716 5.7 5.7 0 00-5.684.24 7.08 7.08 0 00-1.931 1.542.5.5 0 01-.829-.116A7.1 7.1 0 007.734 1.7a5.694 5.694 0 00-5.5-1.46 1 1 0 00-.307 1.69l3.964 3.475a.5.5 0 00.35.123 6.443 6.443 0 011.686.131.5.5 0 01-.221.975 6.914 6.914 0 00-4.113.526A5.7 5.7 0 000 11.572a1 1 0 001 1.067.992.992 0 00.413-.09L8.631 9.26a.5.5 0 01.569.109z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});