define("ember-svg-jar/inlined/bowling-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bowling-player</title><path d=\"M3.323 10.768a2.5 2.5 0 01-2.544.608.25.25 0 00-.3.133 2.424 2.424 0 00-.233 1.021 2.5 2.5 0 103.737-2.162.248.248 0 00-.3.04z\"/><circle cx=\"12.805\" cy=\"3\" r=\"2.5\"/><path d=\"M18.334 9L6.121 5.5a4.509 4.509 0 00-4.353 1.166L.5 7.939a1.5 1.5 0 002.121 2.122l1.268-1.274A1.505 1.505 0 015.34 8.4l3.369.907a.249.249 0 01.161.136.252.252 0 010 .21l-2.177 4.718a1.512 1.512 0 00-.138.629v2.758a1.511 1.511 0 01-.439 1.06L4 20.939a1.5 1.5 0 002.121 2.122l2.121-2.122a4.527 4.527 0 001.318-3.181V16.8l1.269.23a1.5 1.5 0 011.226 1.47V22a1.5 1.5 0 003 0v-3.5a4.5 4.5 0 00-3.694-4.428l-.815-.148a.25.25 0 01-.177-.131.253.253 0 010-.22l1.479-3.2a.249.249 0 01.3-.134l5.407 1.661a1.5 1.5 0 10.779-2.9zM23.083 17.03a.251.251 0 00.227-.145A3.252 3.252 0 0023.716 15a2.021 2.021 0 00-4.041 0 3.264 3.264 0 00.4 1.885.25.25 0 00.227.145zM20.061 18.03a.249.249 0 00-.238.174 7.72 7.72 0 00-.276 1.078 5.644 5.644 0 00.688 3.945.5.5 0 00.446.273h2.028a.5.5 0 00.446-.273 5.649 5.649 0 00.689-3.944 8.023 8.023 0 00-.276-1.079.25.25 0 00-.238-.174z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});