define("ember-svg-jar/inlined/ac-cool", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ac-cool</title><path d=\"M22.5 1.25h-21A1.5 1.5 0 000 2.75v9a1.5 1.5 0 001.5 1.5H3a.5.5 0 00.5-.5v-1a1 1 0 011-1h15a1 1 0 011 1v1a.5.5 0 00.5.5h1.5a1.5 1.5 0 001.5-1.5v-9a1.5 1.5 0 00-1.5-1.5zm-2 7.5h-17a.75.75 0 010-1.5h17a.75.75 0 010 1.5zm0-3.5h-17a.75.75 0 010-1.5h17a.75.75 0 010 1.5zM15.1 18.116a1 1 0 10-1-1.732l-.723.417a.25.25 0 01-.377-.216v-.835a1 1 0 00-2 0v.835a.25.25 0 01-.375.216l-.725-.417a1 1 0 00-1 1.732l.723.418a.249.249 0 010 .432l-.723.418a1 1 0 101 1.732l.723-.417a.25.25 0 01.375.216v.835a1 1 0 002 0v-.835a.25.25 0 01.375-.216l.723.417a1 1 0 101-1.732l-.723-.418a.249.249 0 010-.432zM4.5 14.25a1 1 0 00-1 1 2.567 2.567 0 01-1.14 2.482A4.465 4.465 0 00.5 21.75a1 1 0 002 0 2.567 2.567 0 011.14-2.482A4.465 4.465 0 005.5 15.25a1 1 0 00-1-1zM21.64 17.732a2.567 2.567 0 01-1.14-2.482 1 1 0 00-2 0 4.465 4.465 0 001.86 4.018 2.567 2.567 0 011.14 2.482 1 1 0 002 0 4.465 4.465 0 00-1.86-4.018z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});