define("ember-svg-jar/inlined/transportation-ticket-boat-train-transfer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transportation-ticket-boat-train-transfer</title><path d=\"M1.907 8.281a.212.212 0 00.136.185.209.209 0 00.224-.048 2 2 0 012.821 0 .814.814 0 00.6.307.814.814 0 00.6-.307 1.994 1.994 0 012.82 0 .735.735 0 001.2 0 2 2 0 012.821 0 .835.835 0 00.445.262.251.251 0 00.264-.11l.09-.142a1.194 1.194 0 00-1-1.84H8.979a.25.25 0 01-.25-.25V.748A.748.748 0 007.418.255l-3.49 3.989a.748.748 0 00.563 1.241h2.493a.249.249 0 01.249.249v.6a.249.249 0 01-.249.25H3.546a1.649 1.649 0 00-1.64 1.675v.018zM7.557 10.9a.249.249 0 01.272 0 3.364 3.364 0 003.747 0 .249.249 0 01.27 0 4.377 4.377 0 002.54.572.748.748 0 000-1.5 2.554 2.554 0 01-2.148-.672.748.748 0 00-1.058 0 1.969 1.969 0 01-2.958 0 .748.748 0 00-1.058 0 1.969 1.969 0 01-2.958 0 .747.747 0 00-1.057 0A2.557 2.557 0 011 9.973a.748.748 0 000 1.5 4.377 4.377 0 002.54-.573.251.251 0 01.271 0 3.362 3.362 0 003.746 0zM16.546 5.375q.178.141.344.3a.093.093 0 01-.028.154.748.748 0 000 1.382l1.955.81a.746.746 0 00.977-.4l.81-1.955a.747.747 0 00-.976-.977l-.516.213a.249.249 0 01-.287-.071 7.078 7.078 0 00-1.045-1.017 1 1 0 10-1.233 1.568zM11.523 19.06a5 5 0 01-3.147-2.873.113.113 0 01.1-.157.748.748 0 00.529-1.277l-1.5-1.5a.748.748 0 00-1.058 0l-1.5 1.5a.748.748 0 00.529 1.277h.557a.25.25 0 01.24.18 7.013 7.013 0 004.662 4.759 1 1 0 00.576-1.909zM23.6 22.8l-.792-1.057a.248.248 0 01.013-.315 1.725 1.725 0 00.444-1.152V14.3a1.748 1.748 0 00-1.746-1.746h-5.986a1.747 1.747 0 00-1.745 1.746v5.984a1.725 1.725 0 00.444 1.152.248.248 0 01.013.315l-.79 1.054a.748.748 0 001.2.9l1.181-1.574a.249.249 0 01.2-.1h4.987a.249.249 0 01.2.1L22.4 23.7a.748.748 0 001.2-.9zm-7.067-2.525a.748.748 0 11.748-.748.748.748 0 01-.75.751zm3.989 0a.748.748 0 11.748-.748.748.748 0 01-.75.751zm1.247-2.991a.25.25 0 01-.25.249h-5.986a.25.25 0 01-.249-.249V14.3a.253.253 0 01.249-.25h5.984a.253.253 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});