define("ember-svg-jar/inlined/single-neutral-focus.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-focus</title><path d=\"M7.7 22l-5.2.04a.5.5 0 01-.5-.5v-5.235a1 1 0 00-2 0v5.738A1.959 1.959 0 001.957 24H7.7a1 1 0 000-2zM1 8.7a1 1 0 001-1l-.04-5.2a.5.5 0 01.5-.5H7.7a1 1 0 000-2H1.957A1.959 1.959 0 000 1.957V7.7a1 1 0 001 1zM23 15.305a1 1 0 00-1 1l.04 5.191a.5.5 0 01-.5.5H16.3a1 1 0 100 2h5.739A1.959 1.959 0 0024 22.043v-5.738a1 1 0 00-1-1zM24 1.957A1.959 1.959 0 0022.043 0H16.3a1 1 0 000 2l5.2-.04a.5.5 0 01.5.5V7.7a1 1 0 002 0z\"/><circle cx=\"12\" cy=\"6.5\" r=\"2.5\"/><path d=\"M15 15.5a.5.5 0 00.5-.5v-2a3.5 3.5 0 00-7 0v2a.5.5 0 00.5.5h.821a.25.25 0 01.249.225l.43 4.325a.5.5 0 00.5.45h2a.5.5 0 00.5-.45l.432-4.325a.25.25 0 01.249-.225zM7.7 22l-5.2.04a.5.5 0 01-.5-.5v-5.235a1 1 0 00-2 0v5.738A1.959 1.959 0 001.957 24H7.7a1 1 0 000-2zM1 8.7a1 1 0 001-1l-.04-5.2a.5.5 0 01.5-.5H7.7a1 1 0 000-2H1.957A1.959 1.959 0 000 1.957V7.7a1 1 0 001 1zM23 15.305a1 1 0 00-1 1l.04 5.191a.5.5 0 01-.5.5H16.3a1 1 0 100 2h5.739A1.959 1.959 0 0024 22.043v-5.738a1 1 0 00-1-1zM24 1.957A1.959 1.959 0 0022.043 0H16.3a1 1 0 000 2l5.2-.04a.5.5 0 01.5.5V7.7a1 1 0 002 0z\"/><circle cx=\"12\" cy=\"6.5\" r=\"2.5\"/><path d=\"M15 15.5a.5.5 0 00.5-.5v-2a3.5 3.5 0 00-7 0v2a.5.5 0 00.5.5h.821a.25.25 0 01.249.225l.43 4.325a.5.5 0 00.5.45h2a.5.5 0 00.5-.45l.432-4.325a.25.25 0 01.249-.225z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});