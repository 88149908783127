define("ember-svg-jar/inlined/button-play-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-play-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 21.43A9.43 9.43 0 1121.429 12 9.44 9.44 0 0112 21.429z\"/><path d=\"M16.609 10.712l-5.926-2.963a1.488 1.488 0 00-2.224 1.373v5.754a1.532 1.532 0 00.7 1.348 1.44 1.44 0 00.759.214 1.709 1.709 0 00.761-.188l5.926-2.963a1.386 1.386 0 000-2.575z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});