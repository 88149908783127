define("ember-svg-jar/inlined/flying-insect-moth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flying-insect-moth</title><path d=\"M10.34 20.4a1 1 0 00-1.348.428l-.885 1.709a1 1 0 001.776.92l.885-1.709a1 1 0 00-.428-1.348zM7.96 18.769a1 1 0 00-1.358-.4l-1.639.9a1 1 0 10.963 1.754l1.638-.9a1 1 0 00.396-1.354zM4.5 4.5a1 1 0 001-1A1.412 1.412 0 017 2c1.123 0 1.935 1.529 2.32 2.554A3.646 3.646 0 009 6.324.749.749 0 009.75 7h4.495a.749.749 0 00.746-.676 3.649 3.649 0 00-.32-1.778C15.054 3.521 15.861 2 17 2a1.4 1.4 0 011.5 1.508 1 1 0 002-.008A3.4 3.4 0 0017 0c-2.055 0-3.28 1.806-3.914 3.176a3.417 3.417 0 00-2.171 0C10.276 1.807 9.051 0 7 0a3.4 3.4 0 00-3.5 3.5 1 1 0 001 1zM7.5 9.7a.5.5 0 00-.694-.461l-5.573 2.34a2.085 2.085 0 00-1.164 2.464 4.209 4.209 0 004 2.957 6.031 6.031 0 003.213-1.334.5.5 0 00.219-.413zM22.733 11.563L17.2 9.209a.5.5 0 00-.7.461v5.53a.5.5 0 00.2.4 5.765 5.765 0 003.232 1.4 4.209 4.209 0 004-2.957 2.1 2.1 0 00-1.199-2.48zM15.007 20.827a1 1 0 00-1.776.92l.885 1.709a1 1 0 001.776-.92zM19.036 19.272l-1.639-.9a1 1 0 10-.962 1.754l1.638.9a1 1 0 10.963-1.754zM15 16.25v-7a.75.75 0 00-.75-.75h-4.5a.75.75 0 00-.75.75v7a3 3 0 006 0zm-1.5 0a1.5 1.5 0 01-3 0v-1h3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});