define("ember-svg-jar/inlined/sailing-boat-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sailing-boat-3</title><path d=\"M16.1.551a1 1 0 00-1.4-.168c-3.487 2.744-4.181 3.283-4.327 3.409a.251.251 0 01-.223.052l-3.217-.8a.983.983 0 00-1.216.856c-.293 2.692 1.825 5.3 4.929 6.073L22.9 13.009a.487.487 0 00.12.015.5.5 0 00.308-.106.5.5 0 00.192-.394v-4.97a.5.5 0 00-.38-.485l-9.924-2.463a.25.25 0 01-.095-.439l2.811-2.211A1 1 0 0016.1.551zM13.9 13.561a.982.982 0 00-.586-1.494L1.359 9.1a1 1 0 00-.483 1.942l11.952 2.964a.983.983 0 001.062-.426zM20.086 15.2a.979.979 0 00.733.79l1.822.452a1.06 1.06 0 00.242.029 1 1 0 00.241-1.97l-1.835-.456a.987.987 0 00-1.208 1.126zM21.267 19.5c.1.141.641.822 1.174.794a2.53 2.53 0 011.147.213v-.51a.5.5 0 00-.38-.486l-1.689-.419a.25.25 0 00-.27.379zM22.544 21.787a3.148 3.148 0 01-2.55-1.5 1.042 1.042 0 00-1.719.029 3.2 3.2 0 01-2.62 1.478 2.666 2.666 0 01-2.288-1.41 1.045 1.045 0 00-1.717-.155A4.112 4.112 0 018.684 21.8a3.4 3.4 0 01-2.633-1.508 1.042 1.042 0 00-1.635-.092c-.014.017-1.376 1.749-2.971 1.632a.977.977 0 00-1.052.946 1 1 0 00.946 1.052h.216a5.524 5.524 0 003.427-1.395.249.249 0 01.326.006A5.026 5.026 0 008.684 23.8a5.613 5.613 0 003.5-1.294.25.25 0 01.33.015 4.417 4.417 0 003.141 1.279 4.967 4.967 0 003.328-1.279.249.249 0 01.331 0 4.657 4.657 0 003.367 1.264 1 1 0 00.93-1.067 1.032 1.032 0 00-1.067-.931z\"/><path d=\"M19.158 15.524a2.5 2.5 0 10-4 1.986l-.507-.125-.838-.208-11.659-2.892a1.44 1.44 0 00-1.254.268 1.456 1.456 0 00-.562 1.154v4.5a.249.249 0 00.1.2.317.317 0 00.224.036 6.763 6.763 0 00.859-.11 3.762 3.762 0 001.729-1.081 2.549 2.549 0 011.95-.934A2.507 2.507 0 017.325 19.5a1.949 1.949 0 001.36.8 2.6 2.6 0 001.751-.944 2.5 2.5 0 014.286.39 1.126 1.126 0 00.934.554 1.718 1.718 0 001.32-.727 2.428 2.428 0 01.952-.924.25.25 0 00-.059-.463l-.807-.2a2.5 2.5 0 002.096-2.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});