define("ember-svg-jar/inlined/saving-bank-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>saving-bank-1</title><path d=\"M7 12a1 1 0 000-2H3a1 1 0 000 2h.25a.249.249 0 01.25.25v4a.25.25 0 01-.25.25H3a1 1 0 000 2h4a1 1 0 000-2h-.25a.25.25 0 01-.25-.25v-4a.249.249 0 01.25-.25zM14 12a1 1 0 000-2h-4a1 1 0 000 2h.25a.249.249 0 01.25.25v4a.25.25 0 01-.25.25H10a1 1 0 000 2h4a1 1 0 000-2h-.25a.25.25 0 01-.25-.25v-4a.249.249 0 01.25-.25zM21.5 12a1 1 0 000-2h-4a1 1 0 000 2h.25a.249.249 0 01.25.25v4a.25.25 0 01-.25.25h-.25a1 1 0 000 2h4a1 1 0 000-2h-.25a.25.25 0 01-.25-.25v-4a.249.249 0 01.25-.25zM2.453 8.5h19.2a1 1 0 00.926-.574.884.884 0 00-.306-1.075L13.137.2a1.073 1.073 0 00-1.2-.026L1.87 6.825A.886.886 0 001.518 7.9a1 1 0 00.935.6zM23.916 23.223l-2-3A.5.5 0 0021.5 20h-19a.5.5 0 00-.416.223l-2 3A.5.5 0 00.5 24h23a.5.5 0 00.416-.777z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});