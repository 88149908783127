define("ember-svg-jar/inlined/video-player-movie-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-player-movie-alternate</title><path d=\"M24 4.5A2.5 2.5 0 0021.5 2h-19A2.5 2.5 0 000 4.5v15A2.5 2.5 0 002.5 22h19a2.5 2.5 0 002.5-2.5zM20.5 6a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-17a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5z\"/><path d=\"M10.343 15.437l5.283-3.019a.482.482 0 000-.836l-5.283-3.019a.481.481 0 00-.719.417v6.04a.481.481 0 00.719.417z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});