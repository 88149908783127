define("ember-svg-jar/inlined/conversation-speak", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>conversation-speak</title><circle cx=\"2.25\" cy=\"2.667\" r=\"2.25\"/><path d=\"M6.332 5.712a.751.751 0 001.041-.205 2.77 2.77 0 000-3.095.75.75 0 00-1.246.836 1.287 1.287 0 010 1.423.75.75 0 00.205 1.041zM17.251 5.839a.75.75 0 00.622-1.168 1.287 1.287 0 010-1.423.75.75 0 00-1.246-.836 2.77 2.77 0 000 3.1.748.748 0 00.624.327zM9.65 7.367a4.824 4.824 0 000-6.815.75.75 0 00-1.06 1.061 3.322 3.322 0 010 4.693.75.75 0 001.06 1.061zM15.41.552a.749.749 0 00-1.06 0 4.824 4.824 0 000 6.815.75.75 0 001.06-1.061 3.322 3.322 0 010-4.693.75.75 0 000-1.061z\"/><circle cx=\"21.75\" cy=\"2.667\" r=\"2.25\"/><path d=\"M21.25 23.667h1a1.5 1.5 0 001.5-1.5v-14.5a1 1 0 00-1-1h-2a3 3 0 00-3 3v5a1 1 0 001 1h.75a.249.249 0 01.25.25v6.25a1.5 1.5 0 001.5 1.5zM3.25 6.667h-2a1 1 0 00-1 1v14.5a1.5 1.5 0 001.5 1.5h1a1.5 1.5 0 001.5-1.5v-3.75a.25.25 0 01.25-.25h2.036a.5.5 0 00.47-.67l-1.575-4.333a2.987 2.987 0 01-.181-1.025V8.667a2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});