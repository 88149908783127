define("ember-svg-jar/inlined/cursor-select-frame", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-select-frame</title><path d=\"M9 0H2a2 2 0 00-2 2v7a1 1 0 001 1h1a1 1 0 001-1V3.5a.5.5 0 01.5-.5H9a1 1 0 001-1V1a1 1 0 00-1-1zM22 0h-7a1 1 0 00-1 1v1a1 1 0 001 1h5.5a.5.5 0 01.5.5V9a1 1 0 001 1h1a1 1 0 001-1V2a2 2 0 00-2-2zM9 21H3.5a.5.5 0 01-.5-.5V15a1 1 0 00-1-1H1a1 1 0 00-1 1v7a2 2 0 002 2h7a1 1 0 001-1v-1a1 1 0 00-1-1zM23 14h-1a1 1 0 00-1 1v5.5a.5.5 0 01-.5.5H15a1 1 0 00-1 1v1a1 1 0 001 1h7a2 2 0 002-2v-7a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});