define("ember-svg-jar/inlined/paginate-filter-9", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paginate-filter-9</title><path d=\"M22 0H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2V2a2 2 0 00-2-2zm-5.25 11.5A3.754 3.754 0 0113 15.247h-.5a.75.75 0 010-1.5h.5a2.245 2.245 0 002.225-2.007 3.215 3.215 0 01-1.725.507A3.25 3.25 0 1116.75 9z\"/><circle cx=\"13.5\" cy=\"8.997\" r=\"1.75\"/><path d=\"M19.5 22h-17a.5.5 0 01-.5-.5v-17a1 1 0 00-2 0V22a2 2 0 002 2h17.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});