define("ember-svg-jar/inlined/astrology-scorpio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astrology-scorpio</title><path d=\"M23.207 17.793l-2-2a1 1 0 00-1.414 0l-2 2a1 1 0 001.414 1.414.172.172 0 01.293.121v.172a1.5 1.5 0 01-3 0v-14A4.505 4.505 0 0012 1a4.456 4.456 0 00-3.314 1.489.251.251 0 01-.371 0A4.46 4.46 0 005 1 4.505 4.505 0 00.5 5.5v13a1 1 0 002 0v-13a2.5 2.5 0 015 0v13a1 1 0 002 0v-13a2.5 2.5 0 015 0v14a3.5 3.5 0 007 0v-.172a.172.172 0 01.293-.121 1 1 0 001.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});