define("ember-svg-jar/inlined/gymnastics-ribbon-person-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gymnastics-ribbon-person-2</title><path d=\"M7.914 14.338H5a2.991 2.991 0 01-2.33-1.117.251.251 0 00-.339-.046 5.5 5.5 0 107.424 1.014.252.252 0 00-.261-.083 5.84 5.84 0 01-1.58.232z\"/><circle cx=\"9.5\" cy=\"3.338\" r=\"2.5\"/><path d=\"M24 4.838a1.5 1.5 0 00-3 0v4a1.5 1.5 0 01-1.5 1.5h-2.226a1 1 0 01-.553-.167L12.86 7.609a1.5 1.5 0 00-1.82.077L8.874 9.49a1.5 1.5 0 01-.96.348H5a1.5 1.5 0 000 3h2.914a4.5 4.5 0 002.88-1.043l1.144-.953a.249.249 0 01.3-.017l2.31 1.5a1 1 0 01.456.839v8.5a1.5 1.5 0 003 0v-8.076a.25.25 0 01.25-.25H19.5a4.505 4.505 0 004.5-4.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});