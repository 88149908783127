define("ember-svg-jar/inlined/multiple-actions-wifi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-wifi</title><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.39 12.8a.252.252 0 00.131.179.249.249 0 00.221.005A11.28 11.28 0 0118.719 12a.25.25 0 00.281-.261A4.46 4.46 0 0014.5 7.5a4.53 4.53 0 00-3.6 1.76.25.25 0 00.005.311A6.96 6.96 0 0112.39 12.8zM0 17.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.541a.5.5 0 00.5.459h4a.5.5 0 00.5-.459l.443-5.312A.25.25 0 018.69 18h.945a.25.25 0 00.214-.379 2.5 2.5 0 01.6-3.232c.144-.115.293-.22.443-.325a.247.247 0 00.108-.211A5.5 5.5 0 000 14z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><circle cx=\"17.5\" cy=\"22.25\" r=\"1.75\"/><path d=\"M11.379 15.627a1 1 0 001.242 1.567 7.826 7.826 0 019.758 0 1 1 0 101.242-1.567 9.819 9.819 0 00-12.242 0z\"/><path d=\"M13.854 18.452a1 1 0 101.293 1.525 3.586 3.586 0 014.707 0 1 1 0 101.293-1.525 5.621 5.621 0 00-7.293 0z\"/><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.39 12.8a.252.252 0 00.131.179.249.249 0 00.221.005A11.28 11.28 0 0118.719 12a.25.25 0 00.281-.261A4.46 4.46 0 0014.5 7.5a4.53 4.53 0 00-3.6 1.76.25.25 0 00.005.311A6.96 6.96 0 0112.39 12.8zM0 17.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.541a.5.5 0 00.5.459h4a.5.5 0 00.5-.459l.443-5.312A.25.25 0 018.69 18h.945a.25.25 0 00.214-.379 2.5 2.5 0 01.6-3.232c.144-.115.293-.22.443-.325a.247.247 0 00.108-.211A5.5 5.5 0 000 14z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><circle cx=\"17.5\" cy=\"22.25\" r=\"1.75\"/><path d=\"M11.379 15.627a1 1 0 001.242 1.567 7.826 7.826 0 019.758 0 1 1 0 101.242-1.567 9.819 9.819 0 00-12.242 0z\"/><path d=\"M13.854 18.452a1 1 0 101.293 1.525 3.586 3.586 0 014.707 0 1 1 0 101.293-1.525 5.621 5.621 0 00-7.293 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});