define("ember-svg-jar/inlined/add-circle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>add-circle-1</title><path d=\"M8.75 20.5c.146 0 .291 0 .435-.014a.249.249 0 00.133-.45 9.97 9.97 0 010-16.071.25.25 0 00-.133-.451A6.873 6.873 0 008.75 3.5a8.5 8.5 0 000 17z\"/><path d=\"M15.25 3.5a8.5 8.5 0 108.5 8.5 8.5 8.5 0 00-8.5-8.5zm5 8.5a1 1 0 01-1 1H16.5a.25.25 0 00-.25.25V16a1 1 0 01-2 0v-2.75A.25.25 0 0014 13h-2.75a1 1 0 010-2H14a.25.25 0 00.25-.25V8a1 1 0 012 0v2.75a.25.25 0 00.25.25h2.75a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});