define("ember-svg-jar/inlined/skateboard-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skateboard-person</title><circle cx=\"13\" cy=\"3\" r=\"2.5\"/><path d=\"M14.123 15.852a3.522 3.522 0 00-1.8-2.037l-1.854-.89 1.825-2.811A.25.25 0 0112.5 10h7a1.5 1.5 0 000-3h-16a1.5 1.5 0 000 3h4.963a.249.249 0 01.215.378L2.2 21.25a1.5 1.5 0 102.6 1.5l4.049-7.019a.251.251 0 01.325-.1l1.849.89a.488.488 0 01.258.291.5.5 0 01-.029.386l-.225.439a.25.25 0 00.223.364h2.7a.25.25 0 00.243-.19 3.5 3.5 0 00-.07-1.959z\"/><path d=\"M21 19H9a1 1 0 000 2h12a1 1 0 000-2z\"/><circle cx=\"19\" cy=\"22.5\" r=\"1\"/><circle cx=\"10.999\" cy=\"22.5\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});