define("ember-svg-jar/inlined/scissors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>scissors</title><path d=\"M20.745 14.125a3.94 3.94 0 00-3.423.717 11.907 11.907 0 00-1.122-2.726.231.231 0 010-.233 11.925 11.925 0 001.118-2.726 3.943 3.943 0 003.423.717 4 4 0 00-1.236-7.868 4.068 4.068 0 00-3.755 4.026 9.917 9.917 0 01-1 4.274.251.251 0 01-.414.05A11.452 11.452 0 005.75 6.5c-1.057 0-4.287.338-5.3 1.1a.5.5 0 00.165.881l11.629 3.28a.25.25 0 010 .481L.614 15.52a.5.5 0 00-.165.881c1.014.76 4.244 1.1 5.3 1.1a11.451 11.451 0 008.587-3.856.25.25 0 01.414.05 9.91 9.91 0 011 4.274 4.067 4.067 0 003.759 4.025 4 4 0 001.236-7.867zM19.75 20a2 2 0 112-2 2 2 0 01-2 2zm0-16a2 2 0 11-2 2 2 2 0 012-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});