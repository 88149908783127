define("ember-svg-jar/inlined/professions-man-surgeon.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-surgeon</title><path d=\"M12 0h-.006a11.684 11.684 0 00-6.526 1.683.986.986 0 00-.4.791v6.434A6.942 6.942 0 0012 15.836a7.025 7.025 0 005.3-2.47l.006-.007a6.891 6.891 0 001.627-4.451V2.474a.986.986 0 00-.4-.791A11.351 11.351 0 0012 0zm3.046 12.808a5.091 5.091 0 01-6.092 0 5.558 5.558 0 016.092 0zm1.9-3.9a4.823 4.823 0 01-.505 2.125.248.248 0 01-.359.1 7.381 7.381 0 00-8.17 0 .249.249 0 01-.2.033.252.252 0 01-.158-.131 4.832 4.832 0 01-.5-2.124v-.746a.248.248 0 01.246-.247h9.4a.248.248 0 01.248.247zm-.136-5.944a.249.249 0 01.139.223v2.5a.247.247 0 01-.248.247H7.3a.247.247 0 01-.248-.247v-2.5a.25.25 0 01.137-.222 12.278 12.278 0 019.621-.001zM.123 23.01a.99.99 0 001.98 0v-2.084a.964.964 0 01.372-.777 11.6 11.6 0 013.721-1.8.249.249 0 01.241.058A8.2 8.2 0 0012 20.4a8.2 8.2 0 005.563-2 .249.249 0 01.241-.058 11.623 11.623 0 013.721 1.8.964.964 0 01.372.777v2.091a.99.99 0 101.98 0v-2.084a2.967 2.967 0 00-1.152-2.35 14.083 14.083 0 00-5.151-2.346.985.985 0 00-1.023.353A5.8 5.8 0 0112 18.415a5.8 5.8 0 01-4.551-1.832.991.991 0 00-1.023-.353 14.1 14.1 0 00-5.151 2.345 2.93 2.93 0 00-1.152 2.351z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});