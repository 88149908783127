define("ember-svg-jar/inlined/share-3-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>share-3-alternate</title><path d=\"M15.25 10.249a.25.25 0 00-.25.25v11.25H2v-13h3.253A.25.25 0 005.5 8.5a6.929 6.929 0 01.173-1.447.251.251 0 00-.244-.305H2a2 2 0 00-2 2v13a2 2 0 002 2h13a2 2 0 002-2V10.5a.25.25 0 00-.25-.25z\"/><path d=\"M19.372.415a.5.5 0 00-.872.334V2.5a.249.249 0 01-.25.25h-5.37A5.882 5.882 0 007 8.62a5.737 5.737 0 00.921 3.142.5.5 0 00.9-.124 4.154 4.154 0 013.939-2.889h5.49A.25.25 0 0118.5 9v1.75a.5.5 0 00.872.335l4.5-5a.5.5 0 000-.669z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});