define("ember-svg-jar/inlined/road-sign-2-5m-wide-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-2.5m-wide-alternate</title><path d=\"M4.53 10.47a.75.75 0 00-1.28.53v2a.75.75 0 001.28.53l1-1a.749.749 0 000-1.06zM20.287 10.307a.749.749 0 00-.817.163l-1 1a.749.749 0 000 1.06l1 1a.746.746 0 00.53.22.75.75 0 00.75-.75v-2a.75.75 0 00-.463-.693zM10.25 12a.75.75 0 00-.75-.75.211.211 0 01-.19-.12.208.208 0 01.025-.223l.438-.549A2.175 2.175 0 0010.25 9a1.75 1.75 0 00-3.5 0 .75.75 0 001.5 0c0-.275.5-.275.5 0a.682.682 0 01-.148.422l-1.687 2.109A.75.75 0 007.5 12.75h2a.75.75 0 00.75-.75zM15.5 8.75h1a.75.75 0 000-1.5h-2a.75.75 0 00-.75.75v1.5A1.252 1.252 0 0015 10.75h.5a.25.25 0 010 .5h-1a.75.75 0 000 1.5h1a1.75 1.75 0 000-3.5.25.25 0 010-.5z\"/><circle cx=\"12\" cy=\"12\" r=\".75\"/><path d=\"M13.7 13.777a.749.749 0 00-.843.337l-.643 1.071a.25.25 0 01-.428 0l-.643-1.071a.75.75 0 00-1.393.386v4a.75.75 0 001.5 0v-1.085a.058.058 0 01.107-.029.78.78 0 001.286 0 .058.058 0 01.107.029V18.5a.75.75 0 001.5 0v-4a.75.75 0 00-.55-.723z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});