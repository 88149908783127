define("ember-svg-jar/inlined/multiple-actions-mobilephone.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-mobilephone</title><path d=\"M16.184 9.511a.225.225 0 00.064-.432 42.413 42.413 0 00-1.778-.684l-.485-.172a1.8 1.8 0 01-.056-1.172 4.642 4.642 0 001.215-3.567A3.217 3.217 0 0012 0a2.986 2.986 0 00-1.341.314.251.251 0 00-.131.159.253.253 0 00.034.2 4.993 4.993 0 01.838 2.811 6.26 6.26 0 01-.922 3.623.25.25 0 00.122.371c1.886.705 3.253 1.308 3.873 2.478a.226.226 0 00.147.114.222.222 0 00.182-.035 2.922 2.922 0 011.382-.524z\"/><path d=\"M13.5 12.5a2.99 2.99 0 01.177-1 .249.249 0 000-.155 4.777 4.777 0 00-.3-.779c-.454-.907-1.9-1.44-3.909-2.178l-.486-.172a1.8 1.8 0 01-.055-1.172 4.646 4.646 0 001.215-3.567A3.218 3.218 0 007 0a3.218 3.218 0 00-3.143 3.484 4.608 4.608 0 001.192 3.537 1.874 1.874 0 010 1.184l-.514.19c-2.006.738-3.456 1.271-3.91 2.178A7.538 7.538 0 000 13.5a.5.5 0 00.5.5h12.75a.249.249 0 00.249-.25zM22.522 11.005h-6.05A1.492 1.492 0 0015 12.513v9.979A1.493 1.493 0 0016.472 24h6.05A1.493 1.493 0 0024 22.492v-9.979a1.493 1.493 0 00-1.478-1.508zM17 13.5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v8a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});