define("ember-svg-jar/inlined/phone-incoming-call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-incoming-call</title><path d=\"M21.1 13.777a45.4 45.4 0 00-18.2 0 3.694 3.694 0 00-2.9 3.49v1.5a2.5 2.5 0 002.5 2.5h3a2.5 2.5 0 002.5-2.5v-.545a45.286 45.286 0 018 0v.545a2.5 2.5 0 002.5 2.5h3a2.5 2.5 0 002.5-2.5v-1.5a3.694 3.694 0 00-2.9-3.49zM11.5 10.017a1.25 1.25 0 001.25-1.25V3.983a1.25 1.25 0 00-2.5 0v4.784a1.25 1.25 0 001.25 1.25zM16.159 11.6a1.245 1.245 0 00.888-.37l3.341-3.369A1.25 1.25 0 0018.612 6.1l-3.341 3.372a1.25 1.25 0 00.888 2.13zM5.953 11.232a1.25 1.25 0 101.776-1.76L4.388 6.1a1.25 1.25 0 00-1.776 1.76z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});