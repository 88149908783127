define("ember-svg-jar/inlined/design-monitor-pencil", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-monitor-pencil</title><path d=\"M24 1.5A1.5 1.5 0 0022.5 0h-21A1.5 1.5 0 000 1.5v16A1.5 1.5 0 001.5 19h21a1.5 1.5 0 001.5-1.5v-16zM2.5 15a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v12a.5.5 0 01-.5.5zM16.449 23.213l-2.1-2.532a.5.5 0 00-.385-.181h-3.93a.5.5 0 00-.385.181l-2.1 2.532A.5.5 0 007.96 24h8.079a.5.5 0 00.41-.787z\"/><path d=\"M9.5 9h4a.5.5 0 00.469-.672l-1.546-4.213a1 1 0 00-1.854.021L9.031 8.328A.5.5 0 009.5 9zM10.75 10.75a.25.25 0 00-.25-.25H9a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h1.5a.25.25 0 00.25-.25zM14.5 11a.5.5 0 00-.5-.5h-1.5a.25.25 0 00-.25.25v2.5a.25.25 0 00.25.25H14a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});