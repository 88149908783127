define("ember-svg-jar/inlined/crime-woman-riot.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-woman-riot</title><path d=\"M8.53 9.1a.75.75 0 101.02 1.1.563.563 0 01.559 0 .75.75 0 101.022-1.1 2.02 2.02 0 00-2.601 0zM13.972 10.2a.557.557 0 01.557 0 .75.75 0 101.022-1.1 2.019 2.019 0 00-2.6 0 .75.75 0 001.022 1.1zM22.248 18.811a7.1 7.1 0 00-3.056-1.157 47.894 47.894 0 01-1.257-.3.5.5 0 00-.6.347A7.632 7.632 0 0112 23.125 7.643 7.643 0 016.663 17.7a.5.5 0 00-.6-.347c-.445.108-1.069.257-1.257.3a7.107 7.107 0 00-3.057 1.157A4.529 4.529 0 000 22.375v1a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-1a4.529 4.529 0 00-1.752-3.564z\"/><path d=\"M7.805 15.716A6.512 6.512 0 0012 21.593c.778-.259 3.771-1.536 4.194-5.876a2.082 2.082 0 001.025.585c1.681.4 3.562-.883 4.169-1.634a.5.5 0 00-.232-.788 3.87 3.87 0 01-2.1-2.763 3.166 3.166 0 00.232-1.1 2.112 2.112 0 00-.464-1.444c.387-2.76-.042-4.9-1.276-6.354C16.374.829 14.514.125 12.021.125S7.666.829 6.49 2.216C5.256 3.674 4.827 5.811 5.215 8.57a2.116 2.116 0 00-.465 1.443 3.165 3.165 0 00.208 1.05 3.911 3.911 0 01-2.116 2.817.5.5 0 00-.232.788c.6.743 2.491 2.038 4.169 1.634a2.079 2.079 0 001.026-.586zM12 12.625c-1.884 0-3 .1-3.616.531a.25.25 0 01-.373-.107c-.25-.583-.128-.7-.384-1.182a1.738 1.738 0 00-.818-.786 1.279 1.279 0 01-.559-1.1.616.616 0 01.105-.439.752.752 0 00.406-.795 13.236 13.236 0 01-.137-1.116.25.25 0 01.247-.27 6.6 6.6 0 004.788-1.777.217.217 0 01.3 0 6.791 6.791 0 005.2 1.786.252.252 0 01.184.08.255.255 0 01.066.19c-.029.354-.071.72-.136 1.107a.752.752 0 00.406.794.626.626 0 01.1.439 1.275 1.275 0 01-.559 1.1 1.733 1.733 0 00-.828.8c-.237.467-.128.605-.384 1.19a.254.254 0 01-.164.141.249.249 0 01-.213-.04c-.596-.439-1.719-.546-3.631-.546z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});