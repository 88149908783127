define("ember-svg-jar/inlined/canoe-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>canoe-person</title><circle cx=\"9\" cy=\"3.106\" r=\"2.5\"/><path d=\"M1.131 19.893a3.213 3.213 0 001.853-1.134 2.5 2.5 0 011.963-.952 2.276 2.276 0 01.612.09.249.249 0 00.288-.124l1.744-3.3a.25.25 0 00-.221-.367H.5a.5.5 0 00-.5.5v5.137a.25.25 0 00.319.24 2.4 2.4 0 01.812-.09z\"/><path d=\"M7.564 10.831a.25.25 0 01.319-.119 5.672 5.672 0 001.5.376 5.484 5.484 0 00.64.04.25.25 0 01.22.366l-3.538 6.794a.251.251 0 00.044.291 2.3 2.3 0 01.324.407 2.111 2.111 0 001.486.874 2.792 2.792 0 001.889-1.017 2.5 2.5 0 014.287.39 1.279 1.279 0 001.051.627 1.863 1.863 0 001.476-.846 2.416 2.416 0 012.138-1.207 2.506 2.506 0 012.127 1.18 2.552 2.552 0 00.969.807.251.251 0 00.291-.09A5.817 5.817 0 0024 16.106a4.99 4.99 0 00-4.459-4.972.981.981 0 00-1.067.828A2.509 2.509 0 0116 14.106h-4.451a.25.25 0 01-.222-.365l1.881-3.618a5.5 5.5 0 001.863-2.233l.3-.675a1.5 1.5 0 00-.011-1.229l1.777-3.419a1 1 0 10-1.775-.922L13.52 5.188a1.494 1.494 0 00-.891.812l-.3.675a2.5 2.5 0 01-3.4 1.221l-1.258-.632a1.5 1.5 0 00-2.013.671l-2.405 4.809a.25.25 0 00.224.362h2.8a.252.252 0 00.224-.138z\"/><path d=\"M22.93 21.348c-1.578.144-2.667-1.556-2.675-1.569a1.048 1.048 0 00-1.716.022 3.372 3.372 0 01-2.753 1.558 2.8 2.8 0 01-2.4-1.482 1.044 1.044 0 00-1.717-.156 4.3 4.3 0 01-3.105 1.638 3.557 3.557 0 01-2.764-1.58 1.041 1.041 0 00-1.638-.091c-.014.019-1.455 1.831-3.109 1.7A.971.971 0 000 22.336c-.01.188.042 1.058 1.168 1.058A5.831 5.831 0 004.9 21.787a5.242 5.242 0 003.663 1.572 5.863 5.863 0 003.811-1.5 4.576 4.576 0 003.416 1.5 5.155 5.155 0 003.622-1.5 4.789 4.789 0 003.661 1.482A1 1 0 0024 22.277a1.011 1.011 0 00-1.07-.929z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});