define("ember-svg-jar/inlined/social-icon-dock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-icon-dock</title><path d=\"M23.85 11.65a.5.5 0 00-.45-.14l-5.3 1.06a.5.5 0 00-.26.84l.71.72L14 16.65V9.57a5 5 0 10-4 0v7.1l-4.61-2.49.77-.77a.5.5 0 00-.26-.84L.6 11.51a.51.51 0 00-.45.14.5.5 0 00-.15.45l1.06 5.3a.5.5 0 00.84.26L3 16.54l8.64 7.34a.5.5 0 00.65 0L21 16.54l1.12 1.12a.5.5 0 00.84-.26L24 12.1a.5.5 0 00-.15-.45zM12 7a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});