define("ember-svg-jar/inlined/rollerblades", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rollerblades</title><circle cx=\"4.5\" cy=\"21.5\" r=\"2.5\"/><circle cx=\"12\" cy=\"21.5\" r=\"2.5\"/><circle cx=\"19.5\" cy=\"21.5\" r=\"2.5\"/><path d=\"M4.112 1.677l1.3 10.349a3.026 3.026 0 01-.026.916l-.585 3.2A1.5 1.5 0 006.261 18h10.778a2.94 2.94 0 00.411-5.851l-2.166-.417a1.5 1.5 0 01-1.307-1.432l.051-.495a.5.5 0 00-.5-.551H10a.75.75 0 010-1.5h3.787a.5.5 0 00.5-.449l.154-1.5a.5.5 0 00-.5-.551h-2.962a.75.75 0 010-1.5H14.2a.5.5 0 00.5-.449l.169-1.647A1.5 1.5 0 0013.372 0H5.6a1.5 1.5 0 00-1.49 1.672z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});