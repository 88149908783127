define("ember-svg-jar/inlined/meeting-headphones-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-headphones-3</title><path d=\"M7 11.5l-.45-.338a.5.5 0 00-.8.4v7.875a.5.5 0 00.8.4L7 19.5a2.5 2.5 0 001-2v-4a2.5 2.5 0 00-1-2zM17.45 19.837a.5.5 0 00.8-.4v-7.875a.5.5 0 00-.8-.4L17 11.5a2.5 2.5 0 00-1 2v4a2.5 2.5 0 001 2z\"/><path d=\"M22 10.049a.306.306 0 01-.245-.3 9.75 9.75 0 00-19.5 0 .306.306 0 01-.245.3A2.5 2.5 0 000 12.5v6A2.5 2.5 0 002.5 21h1.25a.5.5 0 00.5-.5V9.749a7.75 7.75 0 0115.5 0V20a1.5 1.5 0 01-1.5 1.5h-2.928a.5.5 0 01-.331-.125A1.5 1.5 0 0014 21h-1a1.5 1.5 0 100 3h1a1.49 1.49 0 00.991-.375.5.5 0 01.331-.125h2.928a3.506 3.506 0 003.258-2.22.5.5 0 01.386-.311A2.5 2.5 0 0024 18.5v-6a2.5 2.5 0 00-2-2.451z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});