define("ember-svg-jar/inlined/phone-actions-merge-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-merge-1</title><path d=\"M21.543 4.8a1 1 0 00.707-1.7L19.457.3a1 1 0 00-1.414 0L15.25 3.1a1 1 0 00.707 1.7H17.5v2.467l-3.636 3.654a1.249 1.249 0 000 1.767 1.263 1.263 0 001.768 0l4-4.021A1.252 1.252 0 0020 7.782V4.8zM23.642 10.927l-1.486-1.514a1.25 1.25 0 00-1.784 1.752l1.486 1.514a1.269 1.269 0 001.768.015 1.249 1.249 0 00.016-1.767zM15.5 15.773a3.116 3.116 0 00-4.4 0l-.827.828a4.25 4.25 0 00-1.346 4.5 11.348 11.348 0 01-6.032-6.031 4.247 4.247 0 004.5-1.346l.83-.829a3.116 3.116 0 000-4.4l-3.59-3.582a2.234 2.234 0 00-3.649.739 13.314 13.314 0 0017.357 17.355 2.234 2.234 0 00.737-3.649z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});