define("ember-svg-jar/inlined/task-list-pin-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-list-pin-1</title><path d=\"M14.75 18.487a1.752 1.752 0 011.75-1.75h5.25a.25.25 0 00.25-.25V7.012a2 2 0 00-2-2h-.762a.25.25 0 00-.191.088A3.988 3.988 0 0116 6.512a3.962 3.962 0 01-.9-.1.251.251 0 00-.233.067l-2.95 2.95A2 2 0 019.086 6.6l1.159-1.159a.25.25 0 00-.177-.427H4a2 2 0 00-2 2v14.973a2 2 0 002 2h10.5a.25.25 0 00.25-.25zm-9.25-.475a.75.75 0 01.75-.75h5a.75.75 0 010 1.5h-5a.75.75 0 01-.75-.75zm12.25-5.75a.75.75 0 010 1.5H6.25a.75.75 0 010-1.5z\"/><path d=\"M16.5 18.237a.253.253 0 00-.25.25v5.083a.25.25 0 00.306.244 7.023 7.023 0 003.4-1.87 6.923 6.923 0 001.875-3.4.248.248 0 00-.048-.21.25.25 0 00-.2-.094zM9.793 7.305a1 1 0 101.414 1.414l3.768-3.768a.249.249 0 01.254-.061 2.5 2.5 0 10-1.607-1.607.249.249 0 01-.061.254z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});