define("ember-svg-jar/inlined/face-id-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>face-id-1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm2.5 7.5a1 1 0 012 0v3a1 1 0 01-2 0zm-7 0a1 1 0 012 0v3a1 1 0 01-2 0zm9.948 8.047a6.5 6.5 0 01-10.9 0 1 1 0 011.676-1.094 4.5 4.5 0 007.545 0 1 1 0 011.675 1.094z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});