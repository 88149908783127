define("ember-svg-jar/inlined/locker-room-suitcase-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>locker-room-suitcase-key</title><path d=\"M24 7.875a2.5 2.5 0 00-2.5-2.5h-4.25a.5.5 0 01-.5-.5v-.75a2.5 2.5 0 00-2.5-2.5h-4.5a2.5 2.5 0 00-2.5 2.5v.75a.5.5 0 01-.5.5H2.5a2.5 2.5 0 00-2.5 2.5v12a2.5 2.5 0 002.5 2.5h19a2.5 2.5 0 002.5-2.5zM9.25 4.125a.507.507 0 01.5-.5h4.5a.507.507 0 01.5.5v.75a.5.5 0 01-.5.5h-4.5a.5.5 0 01-.5-.5zM15 17.875a3.513 3.513 0 01-2.729-1.313.5.5 0 00-.39-.187H6.5a.5.5 0 01-.354-.146l-1.5-1.5a.5.5 0 010-.708l1.5-1.5a.5.5 0 01.354-.146h5.381a.5.5 0 00.39-.187A3.5 3.5 0 1115 17.875z\"/><path d=\"M15 12.88a1.495 1.495 0 101.5 1.495A1.5 1.5 0 0015 12.88z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});