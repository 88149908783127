define("ember-svg-jar/inlined/statistics-feed-burner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>statistics-feed-burner</title><path d=\"M20.59 15.55c.13-5.42-3-7-5.09-8.07l-.28-.14a.5.5 0 00-.73.49c.22 2.49-1 3-2.43 3.26a6.79 6.79 0 00-.47-6.68A7.65 7.65 0 0110.44.5a.5.5 0 00-.79-.41C3.52 4.3 3.33 12 3.61 15.45 1.6 16.36.5 17.58.5 18.91c0 2.8 5.05 5 11.5 5s11.5-2.2 11.5-5c0-1.27-1.03-2.46-2.91-3.36zM12 20.5a5.69 5.69 0 01-5.59-5.77 5.89 5.89 0 01.8-3 .25.25 0 01.46.09c.29 2.13 1.53 3.71 3.16 4 2.22.44 4.51-1.52 5.23-4.46a.25.25 0 01.2-.19.25.25 0 01.25.1 5.86 5.86 0 011.07 3.38A5.69 5.69 0 0112 20.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});