define("ember-svg-jar/inlined/knife-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>knife-1</title><path d=\"M5.336 14.769a.5.5 0 00-.707 0L.587 18.813a2 2 0 102.827 2.828L7.458 17.6a.5.5 0 000-.707zM23.707 7.005l-4.948-4.95a1.029 1.029 0 00-1.415 0l-10.96 10.96a.5.5 0 000 .707l5.3 5.3a1 1 0 001.414 0L23.707 8.419a1 1 0 000-1.414zm-5.5-.536a1 1 0 110-1.414 1 1 0 010 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});