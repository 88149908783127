define("ember-svg-jar/inlined/arrow-thick-dot-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-dot-left</title><circle cx=\"2\" cy=\"12\" r=\"2\"/><circle cx=\"6\" cy=\"17\" r=\"2\"/><circle cx=\"10\" cy=\"21\" r=\"2\"/><circle cx=\"6\" cy=\"7\" r=\"2\"/><circle cx=\"10\" cy=\"3\" r=\"2\"/><circle cx=\"22\" cy=\"12\" r=\"2\"/><circle cx=\"17\" cy=\"12\" r=\"2\"/><circle cx=\"12\" cy=\"12\" r=\"2\"/><circle cx=\"7\" cy=\"12\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});