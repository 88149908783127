define("ember-svg-jar/inlined/vinyl-record-album", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vinyl-record-album</title><path d=\"M24 12a9 9 0 00-7.247-8.829.249.249 0 00-.222.065.252.252 0 00-.074.219A3.505 3.505 0 0116.5 4v5.616a.25.25 0 00.175.238 2.25 2.25 0 010 4.292.25.25 0 00-.175.238V20a3.505 3.505 0 01-.043.545.25.25 0 00.3.284A9 9 0 0024 12zM15 20V4a2 2 0 00-2-2H2a2 2 0 00-2 2v16a2 2 0 002 2h11a2 2 0 002-2zM4.25 13.3h.028a.221.221 0 00.157-.063.217.217 0 00.065-.156v-2.956A1.753 1.753 0 015.928 8.4l3.409-1.1a1.778 1.778 0 011.531.35A1.743 1.743 0 0111.5 9v4.046A1.75 1.75 0 119.75 11.3h.028a.221.221 0 00.157-.063.217.217 0 00.065-.16V9a.25.25 0 00-.295-.246L6.3 9.856c-.21.045-.3.149-.3.269v4.921A1.75 1.75 0 114.25 13.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});