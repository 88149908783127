define("ember-svg-jar/inlined/single-neutral-circle.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-circle</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm6.134 17.9a.749.749 0 01-.634.35h-11a.75.75 0 01-.677-1.072 6.834 6.834 0 0112.354 0 .748.748 0 01-.043.722zM12 4.35a3.7 3.7 0 11-3.7 3.7 3.7 3.7 0 013.7-3.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});