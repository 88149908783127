define("ember-svg-jar/inlined/glasses-ski-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>glasses-ski-1</title><path d=\"M20.626 8.375h-2.063a.25.25 0 01-.16-.442l.194-.161A.9.9 0 0018 6.125H6a.9.9 0 00-.6 1.647l.194.161a.25.25 0 01-.16.442h-2.06c-2.789 0-3.374 1.632-3.374 3 0 4.013 2.71 6.162 5.228 6.461a5.413 5.413 0 006-4.414.779.779 0 011.54 0 5.416 5.416 0 005.346 4.453 5.489 5.489 0 00.656-.039c2.52-.299 5.23-2.448 5.23-6.461 0-1.368-.585-3-3.374-3zm-5.788.576a.745.745 0 01-.48.174H9.642a.745.745 0 01-.48-.174L8.1 8.067a.25.25 0 01.16-.442h7.478a.25.25 0 01.16.442z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});