define("ember-svg-jar/inlined/messages-people-man-bubble-oval-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-man-bubble-oval-1</title><circle cx=\"13.116\" cy=\"5.5\" r=\"1\"/><circle cx=\"16.366\" cy=\"5.5\" r=\"1\"/><circle cx=\"19.616\" cy=\"5.5\" r=\"1\"/><path d=\"M18.5 0h-6a5.482 5.482 0 00-5.213 3.764 1 1 0 001.9.629A3.485 3.485 0 0112.5 2h6a3.5 3.5 0 010 7h-1a1 1 0 00-.625.219l-.878.7A1 1 0 0015 9h-1a1 1 0 000 2v1a1 1 0 001.625.781L17.851 11h.649a5.5 5.5 0 000-11zM7 17.125a4.75 4.75 0 10-4.75-4.75A4.756 4.756 0 007 17.125zm-2.451-5.971a7.043 7.043 0 003.911 1.221 6.6 6.6 0 001.277-.125c0 .042.013.082.013.125a2.75 2.75 0 01-5.5 0 2.713 2.713 0 01.299-1.221zM7.206 18a7.544 7.544 0 00-7.185 5.357A.5.5 0 00.5 24h13.412a.5.5 0 00.48-.643A7.544 7.544 0 007.206 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});