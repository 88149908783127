define("ember-svg-jar/inlined/dentistry-tooth-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dentistry-tooth-shield</title><path d=\"M24 1.953A1.959 1.959 0 0022.043.006H1.96A1.96 1.96 0 00.012 1.964L0 9.306a15.144 15.144 0 0011.861 14.668.976.976 0 00.4 0A15.144 15.144 0 0023.988 9.2zM16.167 14.7a9.094 9.094 0 01-.183 1.836.57.57 0 01-.558.455.55.55 0 01-.547-.491c-.222-2.084-1.427-3.675-2.879-3.675s-2.65 1.585-2.877 3.661a.568.568 0 01-.567.508.546.546 0 01-.535-.436 9.059 9.059 0 01-.187-1.858 8.752 8.752 0 01.534-3.063.5.5 0 00-.092-.506A5.727 5.727 0 017 7.2c0-1.872 1.213-3.351 2.709-3.351a2.693 2.693 0 011.933.816.5.5 0 00.716 0 2.693 2.693 0 011.934-.816C15.787 3.849 17 5.328 17 7.2a5.722 5.722 0 01-1.276 3.931.5.5 0 00-.092.506 8.752 8.752 0 01.535 3.063z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});