define("ember-svg-jar/inlined/pets-tick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pets-tick</title><path d=\"M20.894 17.419a1 1 0 00.525-1.313L21 15.119a5.94 5.94 0 00-4.275-3.487c2.272-.838 3.527-1.31 4.708-4.261a1 1 0 00-1.858-.743c-.854 2.137-1.434 2.345-3.514 3.117a.213.213 0 01-.268-.111c1.044-1.538.98-1.439 1.024-1.525a5.9 5.9 0 00-1.106-6.816 1 1 0 00-1.414 1.414 3.9 3.9 0 01.759 4.452l-.441.65a4.841 4.841 0 00-.682-.609c-.1-.3-.177-.567-.246-.8-.311-1.06-.557-1.9-1.683-1.9s-1.372.836-1.683 1.9c-.069.237-.151.508-.246.8a4.841 4.841 0 00-.682.609l-.441-.65a3.9 3.9 0 01.759-4.452 1 1 0 00-1.418-1.414 5.9 5.9 0 00-1.106 6.816c.042.084-.017-.008.943 1.407.082.121.039.313-.057.277-2.186-.812-2.773-.984-3.644-3.165a1 1 0 00-1.858.743c1.181 2.951 2.436 3.423 4.708 4.261A5.944 5.944 0 003 15.119l-.422.987a1 1 0 101.838.787l.423-.987a3.851 3.851 0 012.3-2.135.139.139 0 01.125.016.137.137 0 01.06.111c-.282.187-.172.071-1.7 2.737a5.969 5.969 0 00-.744 2.233l-.376 3.008a1 1 0 001.984.248l.376-3.008a3.974 3.974 0 01.5-1.488l.521-.909A4.245 4.245 0 0012 19.5a4.245 4.245 0 004.115-2.781l.521.909a3.974 3.974 0 01.5 1.488l.376 3.008a1 1 0 001.984-.248l-.376-3.008a5.98 5.98 0 00-.744-2.234c-1.894-3.3-1.7-2.7-1.7-2.869a.04.04 0 01.018-.033.037.037 0 01.037 0 3.868 3.868 0 012.428 2.179l.423.987a1 1 0 001.312.521z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});