define("ember-svg-jar/inlined/shipment-boat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-boat</title><path d=\"M9.421 12.881c0-.683.406-1.238.908-1.238h4.288a.249.249 0 00.25-.25V5.705a.25.25 0 00-.25-.25H.263a.25.25 0 00-.25.25v8.909A1.486 1.486 0 001.5 16.1h7.671a.25.25 0 00.25-.25zM8.167 3.7a.25.25 0 00.25.25h5.293a.25.25 0 00.177-.427L11.035.676a1.492 1.492 0 00-1.05-.435H8.417a.25.25 0 00-.25.25zM6.447 3.969a.25.25 0 00.25-.25V.506a.25.25 0 00-.25-.25H4.879a1.5 1.5 0 00-1.05.435L.978 3.542a.25.25 0 00.176.427zM14.583 19.027a.768.768 0 011.045-.012 5.8 5.8 0 004.61 1.922 3.588 3.588 0 00.595-.125.248.248 0 00.168-.17l.8-2.772a.495.495 0 00-.476-.632H9.7a.989.989 0 00-.906 1.39v.01l.995 2.129a.25.25 0 00.164.137 1.594 1.594 0 00.21.043 5.582 5.582 0 004.42-1.92zM21.763 11.99a.773.773 0 00-.676-.842h-.276a.25.25 0 01-.25-.25V9.827a.743.743 0 00-1.486 0V10.9a.25.25 0 01-.25.25h-1.8a.773.773 0 00-.677.842v3.859a.25.25 0 00.25.25h4.911a.25.25 0 00.25-.25z\"/><path d=\"M9.985 23.729a5.37 5.37 0 00.567.03A6.881 6.881 0 0014.939 22a.25.25 0 01.318 0 6.841 6.841 0 005.141 1.72 4.736 4.736 0 003.477-2.181.742.742 0 00-1.261-.783 3.22 3.22 0 01-2.4 1.49 5.813 5.813 0 01-4.61-1.921.767.767 0 00-1.045.011 5.579 5.579 0 01-4.419 1.92 3.451 3.451 0 01-2.586-1.584.741.741 0 00-1.354.538.725.725 0 00.1.264 4.97 4.97 0 003.685 2.255zM11.566 13.128a.706.706 0 00-.66.743v1.978a.25.25 0 00.25.25h3.461a.25.25 0 00.25-.25v-2.471a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});