define("ember-svg-jar/inlined/camping-shelter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camping-shelter</title><path d=\"M.5 22.463a1 1 0 001 1h6a1 1 0 001-1v-2a.5.5 0 00-.5-.5H1a.5.5 0 00-.5.5zM23.5 20.463a.5.5 0 00-.5-.5h-7a.5.5 0 00-.5.5v2a1 1 0 001 1h6a1 1 0 001-1z\"/><rect x=\".5\" y=\"14.463\" width=\"8\" height=\"3.5\" rx=\".5\" ry=\".5\"/><rect x=\".5\" y=\"8.963\" width=\"23\" height=\"3.5\" rx=\".5\" ry=\".5\"/><rect x=\"15.5\" y=\"14.463\" width=\"8\" height=\"3.5\" rx=\".5\" ry=\".5\"/><path d=\"M1 6.963h22a.5.5 0 00.246-.936L12.246.6a.5.5 0 00-.492 0l-11 5.425A.5.5 0 001 6.963z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});