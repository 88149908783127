define("ember-svg-jar/inlined/design-tool-glue-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-tool-glue-1</title><path d=\"M2.838 20.216A5.888 5.888 0 001.75 22.5a1.5 1.5 0 003 0 5.888 5.888 0 00-1.088-2.284.519.519 0 00-.824 0zM6.111 13.145a.252.252 0 00-.212-.07.249.249 0 00-.183.126l-2.192 3.944a1.163 1.163 0 001.581 1.581l3.945-2.192a.25.25 0 00.055-.395zM15.7.815a2.783 2.783 0 00-4.233.351L6.848 7.634a.5.5 0 00-.094.291v3.535a.5.5 0 00.147.353l3.536 3.537a.5.5 0 00.353.146h3.535a.5.5 0 00.291-.094l6.468-4.62a2.783 2.783 0 00.351-4.233z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});