define("ember-svg-jar/inlined/indent-decrease-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>indent-decrease-1</title><path d=\"M10 3h13a1 1 0 000-2H10a1 1 0 000 2zM23 21H10a1 1 0 000 2h13a1 1 0 000-2zM23 16h-6a1 1 0 000 2h6a1 1 0 000-2zM23 11h-6a1 1 0 000 2h6a1 1 0 000-2zM23 6h-6a1 1 0 000 2h6a1 1 0 000-2zM13 13a1 1 0 000-2H6.75a.25.25 0 01-.25-.25V8a1 1 0 00-1.664-.747l-4.5 4a1 1 0 000 1.494l4.5 4A1 1 0 005.5 17a.978.978 0 00.409-.088A1 1 0 006.5 16v-2.75a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});