define("ember-svg-jar/inlined/layers-grid-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layers-grid-settings</title><path d=\"M10.029 19.277a.254.254 0 00-.063-.194A.25.25 0 009.78 19H8a.25.25 0 01-.25-.25V15a.25.25 0 01.25-.25h1.754A.249.249 0 0010 14.5a3.263 3.263 0 01.118-.929.25.25 0 00-.042-.218.248.248 0 00-.2-.1H8a.25.25 0 01-.25-.25V8A.25.25 0 018 7.75h4a.25.25 0 01.25.25v3a.25.25 0 00.094.2.247.247 0 00.211.049 3.306 3.306 0 01.929-.072.248.248 0 00.187-.067.251.251 0 00.079-.183V8a.25.25 0 01.25-.25h3.75A.25.25 0 0118 8v.359a.249.249 0 00.215.247 3.246 3.246 0 011.393.54.254.254 0 00.259.015A.249.249 0 0020 8.94V3a3 3 0 00-3-3H3a3 3 0 00-3 3v15a3 3 0 003 3h6.948a.249.249 0 00.236-.331 3.246 3.246 0 01-.155-1.392zM6.249 13a.25.25 0 01-.25.25h-3.75A.25.25 0 012 13V8a.25.25 0 01.25-.25H6a.25.25 0 01.25.25zM8 6.25A.25.25 0 017.749 6V2.25A.25.25 0 018 2h4a.25.25 0 01.25.25V6a.25.25 0 01-.25.25zM17 2a1 1 0 011 1v3a.25.25 0 01-.25.25H14a.25.25 0 01-.25-.25V2.25A.25.25 0 0114 2zM3 2h3a.25.25 0 01.25.25V6a.25.25 0 01-.25.25H2.249A.25.25 0 012 6V3a1 1 0 011-1zM2 18v-3a.25.25 0 01.25-.25H6a.25.25 0 01.25.25v3.75A.25.25 0 016 19H3a1 1 0 01-1-1z\"/><circle cx=\"17.749\" cy=\"17.031\" r=\"1.5\"/><path d=\"M23.517 15.654a1.782 1.782 0 00-1.7-2.953l-1.411.326a.452.452 0 01-.533-.31l-.424-1.393a1.775 1.775 0 00-3.4 0l-.424 1.393a.453.453 0 01-.533.31l-1.41-.327a1.782 1.782 0 00-1.7 2.952l.988 1.066a.463.463 0 010 .625l-.988 1.064a1.782 1.782 0 001.7 2.953l1.411-.326a.454.454 0 01.533.311l.424 1.391a1.774 1.774 0 003.4 0l.424-1.392a.451.451 0 01.533-.311l1.411.326a1.782 1.782 0 001.7-2.952l-.988-1.065a.463.463 0 010-.625zm-5.768 4.377a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});