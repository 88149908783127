define("ember-svg-jar/inlined/reorder-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>reorder-up</title><path d=\"M22.85 5.8L20.1 2.131a.772.772 0 00-1.2 0L16.148 5.8a.749.749 0 00.6 1.2h1.5a.25.25 0 01.25.25V21a1 1 0 002 0V7.25a.25.25 0 01.25-.25h1.5a.75.75 0 00.602-1.2z\"/><rect x=\".999\" y=\"13\" width=\"11\" height=\"11\" rx=\"2\" ry=\"2\"/><path d=\"M11 6.5a1 1 0 00-1 1v1a.5.5 0 01-.5.5h-1a1 1 0 000 2H10a2 2 0 002-2V7.5a1 1 0 00-1-1zM10 0H8.5a1 1 0 100 2h1a.5.5 0 01.5.5v1a1 1 0 002 0V2a2 2 0 00-2-2zM2 4.5a1 1 0 001-1v-1a.5.5 0 01.5-.5h1a1 1 0 000-2H3a2 2 0 00-2 2v1.5a1 1 0 001 1zM3 11h1.5a1 1 0 000-2h-1a.5.5 0 01-.5-.5v-1a1 1 0 00-2 0V9a2 2 0 002 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});