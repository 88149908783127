define("ember-svg-jar/inlined/products-briefcase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>products-briefcase</title><path d=\"M21.5 5.5h-3.75a.25.25 0 01-.25-.25V5a4 4 0 00-4-4h-3a4 4 0 00-4 4v.25a.25.25 0 01-.25.25H2.5A2.5 2.5 0 000 8v4.5A1.5 1.5 0 001.5 14h8.2a.252.252 0 00.2-.1 2.486 2.486 0 014.188 0 .252.252 0 00.2.1h8.2A1.5 1.5 0 0024 12.5V8a2.5 2.5 0 00-2.5-2.5zM10.5 3h3a2 2 0 011.98 1.714.25.25 0 01-.248.286H8.768a.25.25 0 01-.248-.286A2 2 0 0110.5 3zM12 19.25A3.035 3.035 0 019.25 16c0-.079 0-.157.007-.235a.25.25 0 00-.25-.265H2a.5.5 0 00-.5.429c-.061.425-.135.857-.208 1.288-.344 2.012-.668 3.913.25 5A2.473 2.473 0 003.541 23h16.918a2.473 2.473 0 001.994-.781c.919-1.089.594-2.99.25-5-.073-.431-.147-.863-.208-1.288A.5.5 0 0022 15.5h-7.007a.251.251 0 00-.25.265c0 .078.007.156.007.235A3.035 3.035 0 0112 19.25z\"/><ellipse cx=\"12\" cy=\"16\" rx=\"1.25\" ry=\"1.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});