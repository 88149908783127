define("ember-svg-jar/inlined/gymnastics-acrobatic-hanging", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gymnastics-acrobatic-hanging</title><path d=\"M18.975 13.588a.5.5 0 00-.725.447v2.483a.5.5 0 00.125.331 2.5 2.5 0 01-3.693 3.365.25.25 0 00-.423.107 6.942 6.942 0 01-.94 2.066.5.5 0 00.138.7 5.5 5.5 0 105.518-9.494zM14.259 16.679a.25.25 0 00.423.107 2.5 2.5 0 011.61-.778.5.5 0 00.458-.5V1a1 1 0 00-2 0v11.936a.5.5 0 01-.312.464 5.464 5.464 0 00-.981.518.5.5 0 00-.139.7 6.92 6.92 0 01.941 2.061zM9.975 13.588a.5.5 0 00-.725.446v2.484a.5.5 0 00.125.331 2.5 2.5 0 11-2.083-.841.5.5 0 00.458-.5V1a1 1 0 00-2 0v11.936a.5.5 0 01-.312.464 5.5 5.5 0 104.537.188z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});