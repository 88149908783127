define("ember-svg-jar/inlined/shelf-drawers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shelf-drawers</title><path d=\"M17.75 22a.25.25 0 01.25.25V23a1 1 0 002 0v-.768a.249.249 0 01.214-.247A1.5 1.5 0 0021.5 20.5v-5A1.5 1.5 0 0020 14h-.25a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25h.75a1.13 1.13 0 00.8-.4l.6-.8a.5.5 0 00-.4-.8h-1.75a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h.75a1.13 1.13 0 00.8-.4l.6-.8a.5.5 0 00-.4-.8h-1.75a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v2.25a.25.25 0 01-.25.25h-11A.25.25 0 016 3.25V1a1 1 0 00-2 0v2.25a.25.25 0 01-.25.25H2.5a.5.5 0 00-.4.8l.6.8a1.13 1.13 0 00.8.4h.25a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25H2.5a.5.5 0 00-.4.8l.6.8a1.13 1.13 0 00.8.4h.25a.25.25 0 01.25.25v3a.25.25 0 01-.25.25H3.5A1.5 1.5 0 002 15.5v5a1.5 1.5 0 001.286 1.485.249.249 0 01.214.247V23a1 1 0 002 0v-.75a.25.25 0 01.25-.25zM8.5 18.744a1 1 0 111-1 1 1 0 01-1 1zm6.5 0a1 1 0 111-1 1 1 0 01-1 1zM6.25 14a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25h11a.25.25 0 01.25.25v3a.25.25 0 01-.25.25zM17.5 8.25a.25.25 0 01-.25.25h-11A.25.25 0 016 8.25v-2.5a.25.25 0 01.25-.25h11a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});