define("ember-svg-jar/inlined/people-woman-cap-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-cap-4</title><path d=\"M10.521 14.373a3.189 3.189 0 001.5.363h.006a3.154 3.154 0 001.5-.364.75.75 0 00-.734-1.308 1.554 1.554 0 01-.764.172 1.629 1.629 0 01-.772-.174.751.751 0 00-.731 1.311zM21.959 19.026a7.132 7.132 0 00-3.03-1.123 55.377 55.377 0 01-1.647-.391.5.5 0 00-.624.293 5 5 0 01-9.316 0 .5.5 0 00-.624-.293s-1.188.3-1.647.391a7.132 7.132 0 00-3.03 1.123A4.46 4.46 0 00.25 22.571v.679a.75.75 0 001.5 0v-.679a2.95 2.95 0 011.189-2.344A5.1 5.1 0 015 19.451v4.035a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-4.035a5.1 5.1 0 012.061.776 2.95 2.95 0 011.189 2.344v.679a.75.75 0 001.5 0v-.679a4.46 4.46 0 00-1.791-3.545z\"/><path d=\"M21.812 5.1h-3.388A6 6 0 0012.5 0h-1a6 6 0 00-6 5.946 4.793 4.793 0 00-.125 2.593 2 2 0 00-.628 1.586 3.138 3.138 0 00.324 1.3c-.345 1.247-1 2.609-2.227 3a.5.5 0 00-.327.338.5.5 0 00.1.459A5.116 5.116 0 006.185 16.9a2.2 2.2 0 001.968-1.016 6.625 6.625 0 003.868 1.852 6.613 6.613 0 003.84-1.827 2.208 2.208 0 001.954.991 5.116 5.116 0 003.569-1.671.5.5 0 00.1-.459.5.5 0 00-.327-.338c-1.213-.39-1.862-1.719-2.21-2.952a3.139 3.139 0 00.347-1.355 1.982 1.982 0 00-.664-1.613 3.375 3.375 0 00.124-.867 4.769 4.769 0 00-.138-1.083h3.2a.732.732 0 100-1.463zM6.8 6.945a.249.249 0 01.247-.209h9.869a.25.25 0 01.245.2 3.7 3.7 0 01.091.707 2.291 2.291 0 01-2.408 2.144 2.445 2.445 0 01-2.181-1.258.753.753 0 00-.667-.406.751.751 0 00-.666.408 2.1 2.1 0 01-.448.606 2.6 2.6 0 01-3.452 0 2.286 2.286 0 01-.63-2.192zm10.433 4.246a1.749 1.749 0 00-1 1.307c-.378 2.248-3.176 3.738-4.208 3.738S8.19 14.746 7.812 12.5a1.752 1.752 0 00-1-1.3 1.274 1.274 0 01-.559-1.1v-.019c.054.056.108.116.162.167a4.132 4.132 0 005.479 0l.108-.1a4.064 4.064 0 005.78-.12.54.54 0 01.01.076 1.27 1.27 0 01-.561 1.087z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});