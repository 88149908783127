define("ember-svg-jar/inlined/business-message-bottle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-message-bottle</title><path d=\"M20.936 2.6a.941.941 0 00-.39-1.3L18.4.133a.962.962 0 00-1.2.55c-.63 1.35-1.94 4.07-2.26 4.75-.93 1.969-5.311 1.31-7.2 5.33l-3.529 7.263a.251.251 0 00.275.354 2.362 2.362 0 01.469-.048 2.507 2.507 0 012.136 1.194 2.112 2.112 0 001.483.9 1.9 1.9 0 001.445-.822 2.5 2.5 0 014.31-.075 2.118 2.118 0 001.484.9 1.9 1.9 0 001.447-.829 2.436 2.436 0 01.68-.747.245.245 0 00.079-.1l1.086-2.43c2-4.28-1.559-6.7-.51-8.94.381-.8 1.681-3.41 2.341-4.783zM14.03 16.581a.25.25 0 01-.368.1 3.644 3.644 0 00-3.63.074.25.25 0 01-.352-.322l2.258-4.863a.251.251 0 01.194-.142.247.247 0 01.224.087 3.919 3.919 0 001.35 1.015 3.969 3.969 0 001.69.39.206.206 0 01.186.291zm2.47-5.688c-.224.5-1.158.744-2.151.28-.9-.423-1.418-1.289-1.159-1.83.312-.627 1.379-.645 2.15-.27 1.109.517 1.36 1.411 1.16 1.82z\"/><path d=\"M19.607 22.563a4.746 4.746 0 003.208 1.365c.079 0 .16 0 .241-.007a1 1 0 10-.113-2 3.42 3.42 0 01-2.651-1.612 1.042 1.042 0 00-1.727.027 3.368 3.368 0 01-2.752 1.583 3.633 3.633 0 01-2.762-1.612 1.044 1.044 0 00-1.727.029 3.361 3.361 0 01-2.75 1.583 3.634 3.634 0 01-2.763-1.609 1.04 1.04 0 00-1.641-.1c-.015.017-1.464 1.777-3.117 1.706a1 1 0 00-.1 2 5.623 5.623 0 003.779-1.453.251.251 0 01.33.009 5.157 5.157 0 003.517 1.45 5.082 5.082 0 003.471-1.373.251.251 0 01.335 0 5.023 5.023 0 006.89.015.25.25 0 01.332-.001z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});