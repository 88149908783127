define("ember-svg-jar/inlined/diagram-snake-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-snake-arrow</title><path d=\"M21.205 10.547a2.426 2.426 0 002.423-2.423V4.733a2.426 2.426 0 00-2.423-2.423H12.25a.25.25 0 01-.25-.25v-.476a1.454 1.454 0 10-2.907 0V2.8a2.425 2.425 0 002.423 2.422h8.955a.25.25 0 01.25.25V7.39a.25.25 0 01-.25.25H2.8a2.425 2.425 0 00-2.428 2.422v3.391A2.425 2.425 0 002.8 15.876h8.236v1.93a.25.25 0 01-.25.25h-1.2a1.453 1.453 0 00-1.036 2.481l2.906 2.906a1.452 1.452 0 002.057 0l2.906-2.906a1.453 1.453 0 00-1.028-2.481h-1.2a.25.25 0 01-.25-.25v-2.415a2.425 2.425 0 00-2.422-2.422h-7.99a.25.25 0 01-.25-.25V10.8a.249.249 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});