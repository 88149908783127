define("ember-svg-jar/inlined/agriculture-machine-rottary-tiller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>agriculture-machine-rottary-tiller</title><path d=\"M16.669 7.436a7.117 7.117 0 012.215 1.979.25.25 0 00.378.03l1.8-1.8a1.49 1.49 0 00.439-1.06V4A1.5 1.5 0 0020 2.5h-3.5a.5.5 0 00-.5.5v3.283a1.33 1.33 0 00.669 1.153zM4.745 9.445a.25.25 0 00.378-.03 7.125 7.125 0 012.214-1.979 1.329 1.329 0 00.67-1.153V3a.5.5 0 00-.5-.5h-3.5a1.5 1.5 0 00-1.5 1.5v2.586a1.49 1.49 0 00.439 1.06zM23.25 15.7a.5.5 0 01-.25-.433V13a2 2 0 00-2-2h-.539a.5.5 0 00-.48.641 6.558 6.558 0 01.2.928.5.5 0 00.494.431.324.324 0 01.324.324v5.926a.5.5 0 01-.5.5h-.354a.25.25 0 01-.231-.347 1 1 0 00-1.81-.85l-.46.921a.5.5 0 01-.447.276h-1.218a.251.251 0 01-.23-.349 1 1 0 00-1.81-.848l-.46.921a.5.5 0 01-.448.276h-1.219a.25.25 0 01-.209-.112.254.254 0 01-.022-.236 1 1 0 00-1.809-.849l-.46.921a.5.5 0 01-.448.276H7.646a.25.25 0 01-.23-.347 1 1 0 00-1.811-.85l-.46.921a.5.5 0 01-.447.276H3.5a.5.5 0 01-.5-.5v-5.926A.324.324 0 013.324 13a.5.5 0 00.494-.431 6.558 6.558 0 01.2-.928.5.5 0 00-.479-.641H3a2 2 0 00-2 2v2.268a.5.5 0 01-.25.433A1.5 1.5 0 000 17v4a1.5 1.5 0 002.67.937.5.5 0 01.39-.187h.947l-.4.8a1 1 0 001.79.894l.71-1.421a.5.5 0 01.447-.276h1.215a.25.25 0 01.223.362l-.22.441a1 1 0 101.789.894l.71-1.421a.5.5 0 01.448-.276h1.217a.25.25 0 01.223.362l-.22.441a1 1 0 101.789.894l.71-1.421a.5.5 0 01.448-.276H16.1a.25.25 0 01.224.362l-.221.441a1 1 0 001.79.894l.71-1.421a.5.5 0 01.447-.276h1.89a.5.5 0 01.39.187A1.5 1.5 0 0024 21v-4a1.5 1.5 0 00-.75-1.3z\"/><path d=\"M4.762 13.075L4.5 15.91a1 1 0 001.992.18l.258-2.835a4.139 4.139 0 012.069-3.215 4.346 4.346 0 001.828-2.047A4.3 4.3 0 0011 6.283a.533.533 0 01.533-.533h.934a.533.533 0 01.533.533 4.325 4.325 0 002.181 3.757 4.139 4.139 0 012.065 3.215l.254 2.835a1 1 0 001 .91h.091a1 1 0 00.909-1.09l-.258-2.835A6.138 6.138 0 0016.173 8.3 2.324 2.324 0 0115 6.283V1.75A1.752 1.752 0 0013.25 0h-2.5A1.752 1.752 0 009 1.75v4.533a2.311 2.311 0 01-.19.92 2.338 2.338 0 01-.983 1.1 6.138 6.138 0 00-3.065 4.772z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});