define("ember-svg-jar/inlined/design-tool-pen-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-tool-pen_1</title><path d=\"M21.5 9.5a9.5 9.5 0 10-13.154 8.761.248.248 0 01.154.23V20a2 2 0 002 2h.25a.25.25 0 01.25.25V23a1 1 0 002 0v-.75a.25.25 0 01.25-.25h.25a2 2 0 002-2v-1.509a.248.248 0 01.154-.23A9.508 9.508 0 0021.5 9.5zm-8.5 6a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V11a.5.5 0 01.5-.5h1a.5.5 0 01.5.5zm-4.5-6a.5.5 0 01-.429-.757l3.5-5.834a.52.52 0 01.858 0l3.5 5.834a.5.5 0 01-.429.757zM8 11a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v3.5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5zm8 3.5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V11a.5.5 0 01.5-.5h1a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});