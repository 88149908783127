define("ember-svg-jar/inlined/crime-man-ninja.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-man-ninja</title><path d=\"M11.979 17.125c4.052 0 6.875-5.1 6.875-9.67a10.134 10.134 0 00-.4-2.808A6.3 6.3 0 0011.979.125 6.322 6.322 0 005.51 4.654v.009a10.38 10.38 0 00-.4 2.8c-.005 4.572 2.819 9.662 6.869 9.662zm4.638-5.844a.25.25 0 01-.312.148 13.712 13.712 0 00-4.3-.554 13.886 13.886 0 00-4.346.559.248.248 0 01-.312-.147 10.963 10.963 0 01-.722-3.4.244.244 0 01.064-.187.25.25 0 01.18-.077H17.09a.25.25 0 01.25.262 11.031 11.031 0 01-.723 3.396z\"/><path d=\"M21.632 18.127a.251.251 0 00.368-.221v-1.531a.25.25 0 01.25-.25H23a.75.75 0 000-1.5h-.75a.25.25 0 01-.25-.25v-3a1.5 1.5 0 00-3 0v3a.25.25 0 01-.25.25H18a.75.75 0 000 1.5h.75a.25.25 0 01.25.25v.2a.25.25 0 00.132.22zM23.033 19.792a11.994 11.994 0 00-2.45-1.4.25.25 0 00-.32.109l-1.181 1.667-2.367 3.338a.25.25 0 00.219.37H23.5a.5.5 0 00.5-.5V21.77a2.463 2.463 0 00-.967-1.978zM11.079 21.4l-3.527-3.968a.5.5 0 00-.467-.16 14.53 14.53 0 00-6.118 2.52A2.461 2.461 0 000 21.77v1.605a.5.5 0 00.5.5h7.326a.252.252 0 00.19-.087l3.066-2.06a.251.251 0 00-.003-.328zM18.31 17.837a.252.252 0 00-.161-.145 23.11 23.11 0 00-.76-.2.252.252 0 00-.249.08l-.633.586-5.731 5.305a.25.25 0 00.189.413h3.191a.251.251 0 00.22-.13l3.068-4.452.855-1.24a.252.252 0 00.011-.217zM8.489 8.826a.75.75 0 001.022 1.1.567.567 0 01.557 0 .75.75 0 101.022-1.1 2.017 2.017 0 00-2.601 0zM13.421 10.125a.746.746 0 00.511-.2.567.567 0 01.557 0 .75.75 0 101.022-1.1 2.017 2.017 0 00-2.6 0 .75.75 0 00.511 1.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});