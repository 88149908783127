define("ember-svg-jar/inlined/single-man-actions-wifi.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-wifi</title><path d=\"M.124 17.567a.494.494 0 00.5.5H2.41a.246.246 0 01.246.227l.444 5.252a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227l.9-.005a.246.246 0 00.212-.121.249.249 0 000-.244 2.505 2.505 0 01.627-3.142c.166-.131.337-.25.508-.37a.093.093 0 00.039-.077 5.443 5.443 0 10-10.886 0z\"/><path d=\"M1.361 4.206A4.206 4.206 0 105.567 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23z\"/><circle cx=\"17.443\" cy=\"22.268\" r=\"1.732\"/><path d=\"M12.615 17.264a7.746 7.746 0 019.657 0 .989.989 0 101.228-1.551 9.715 9.715 0 00-12.116 0 .99.99 0 101.23 1.551z\"/><path d=\"M13.834 18.509a.99.99 0 001.28 1.51 3.549 3.549 0 014.659 0 .989.989 0 001.279-1.51 5.562 5.562 0 00-7.218 0zM.124 17.567a.494.494 0 00.5.5H2.41a.246.246 0 01.246.227l.444 5.252a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227l.9-.005a.246.246 0 00.212-.121.249.249 0 000-.244 2.505 2.505 0 01.627-3.142c.166-.131.337-.25.508-.37a.093.093 0 00.039-.077 5.443 5.443 0 10-10.886 0z\"/><path d=\"M1.361 4.206A4.206 4.206 0 105.567 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23z\"/><circle cx=\"17.443\" cy=\"22.268\" r=\"1.732\"/><path d=\"M12.615 17.264a7.746 7.746 0 019.657 0 .989.989 0 101.228-1.551 9.715 9.715 0 00-12.116 0 .99.99 0 101.23 1.551z\"/><path d=\"M13.834 18.509a.99.99 0 001.28 1.51 3.549 3.549 0 014.659 0 .989.989 0 001.279-1.51 5.562 5.562 0 00-7.218 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});