define("ember-svg-jar/inlined/multiple-actions-lock.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-lock</title><path d=\"M17.353 10.082a.25.25 0 00.158-.212.253.253 0 00-.121-.235A19.06 19.06 0 0014.471 8.4l-.485-.172a1.8 1.8 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 0012 0a2.986 2.986 0 00-1.341.314.25.25 0 00-.1.363 5 5 0 01.841 2.808 6.259 6.259 0 01-.922 3.624.254.254 0 00-.029.216.249.249 0 00.151.156c1.917.716 3.3 1.328 3.9 2.537a6.537 6.537 0 01.5 1.421.25.25 0 00.442.1 4.488 4.488 0 011.911-1.457z\"/><path d=\"M14 13.5a7.522 7.522 0 00-.618-2.923c-.454-.907-1.9-1.44-3.911-2.179l-.485-.172a1.8 1.8 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 007 0a3.219 3.219 0 00-3.145 3.485 4.609 4.609 0 001.192 3.538 1.877 1.877 0 010 1.185L4.53 8.4c-2.007.737-3.457 1.27-3.911 2.177A7.531 7.531 0 000 13.5a.5.5 0 00.5.5h13a.5.5 0 00.5-.5zM22.5 24a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.31-1.488.218.218 0 01-.19-.217V14.25a3.5 3.5 0 00-7 0v1.018a.25.25 0 01-.215.247A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7zm-5-9.75a1.5 1.5 0 013 0v1a.25.25 0 01-.25.25h-2.5a.249.249 0 01-.25-.25zm2.5 6a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});