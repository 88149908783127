define("ember-svg-jar/inlined/volume-control-off-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>volume-control-off-1</title><path d=\"M14.927 6.2A.249.249 0 0015 6.027v-.82a1 1 0 00-1.707-.707L9.366 8.427a.249.249 0 01-.177.073H7a2 2 0 00-2 2v3a2 2 0 00.754 1.562.25.25 0 00.333-.018z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM2 12a9.995 9.995 0 0116.388-7.688.254.254 0 01.09.181.252.252 0 01-.074.189L4.681 18.4a.25.25 0 01-.369-.017A9.965 9.965 0 012 12zm10 10a9.942 9.942 0 01-5.809-1.866.247.247 0 01-.1-.182.254.254 0 01.072-.2l3.522-3.521a.25.25 0 01.353 0l3.258 3.259A1 1 0 0015 18.785v-7.767a.249.249 0 01.073-.177l4.682-4.681a.249.249 0 01.2-.072.253.253 0 01.183.1A9.995 9.995 0 0112 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});