define("ember-svg-jar/inlined/programming-keyboard-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-keyboard-type</title><path d=\"M22.93 11.42a1.071 1.071 0 001.038-1.329l-1.6-6.418a1.07 1.07 0 00-1.038-.81H2.674a1.07 1.07 0 00-1.038.81l-1.6 6.418A1.071 1.071 0 001.07 11.42h2.063a.5.5 0 00.305-.1 1.717 1.717 0 011.051-.371.466.466 0 00.4-.219 1.76 1.76 0 011.689-.83.5.5 0 00.387-.123 1.764 1.764 0 012.869.822.5.5 0 00.254.3 1.724 1.724 0 01.47.358.5.5 0 00.363.156h2.908a.5.5 0 00.363-.156 1.74 1.74 0 01.469-.358.5.5 0 00.254-.3 1.764 1.764 0 012.869-.822.5.5 0 00.387.123 1.8 1.8 0 01.194-.011 1.778 1.778 0 011.5.835.467.467 0 00.4.219 1.717 1.717 0 011.051.371.5.5 0 00.3.1zM4 7.891H3a.75.75 0 010-1.5h1a.75.75 0 010 1.5zm1.5-2.5h-1a.75.75 0 010-1.5h1a.75.75 0 010 1.5zm2.5 2.5H7a.75.75 0 010-1.5h1a.75.75 0 010 1.5zm2.75-3.25a.75.75 0 01-.75.75H9a.75.75 0 010-1.5h1a.75.75 0 01.75.75zm2.5 2.5a.75.75 0 01-.75.75h-1a.75.75 0 010-1.5h1a.75.75 0 01.75.75zm.25-1.75a.75.75 0 010-1.5h1a.75.75 0 010 1.5zm3.75 1.75a.75.75 0 01-.75.75h-1a.75.75 0 010-1.5h1a.75.75 0 01.75.75zm.5-2.5a.75.75 0 01.75-.75h1a.75.75 0 010 1.5h-1a.75.75 0 01-.75-.75zm3.5 2.5a.75.75 0 01-.75.75h-1a.75.75 0 010-1.5h1a.75.75 0 01.75.75z\"/><path d=\"M19.523 12.922l-.42-1.474a.767.767 0 10-1.475.421l-.28-.984a.793.793 0 00-.95-.526.77.77 0 00-.525.948l.281.982a.791.791 0 00-.948-.526.767.767 0 00-.526.948l1.259 4.409a.251.251 0 01-.339.3l-.73-.312a.613.613 0 00-.638 1.036s1.15 1.262 2.376 2.352a2.616 2.616 0 002.4.548l1.639-.469a2.553 2.553 0 001.753-3.159L21 12.5a.79.79 0 00-.948-.527.768.768 0 00-.529.949zM5.247 12.905l.42-1.475a.768.768 0 011.476.422l.28-.984a.792.792 0 01.949-.526.769.769 0 01.526.948l-.282.982a.792.792 0 01.948-.526.767.767 0 01.527.948L8.832 17.1a.25.25 0 00.338.3l.73-.31a.613.613 0 01.638 1.035s-1.149 1.263-2.376 2.353a2.617 2.617 0 01-2.4.548l-1.64-.469A2.553 2.553 0 012.37 17.4l1.4-4.914a.79.79 0 01.948-.527.767.767 0 01.529.946z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});