define("ember-svg-jar/inlined/smart-watch-square-star-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-star-alternate</title><path d=\"M21 6a3 3 0 00-3-3h-1.078a.249.249 0 01-.24-.182L15.98.365A.5.5 0 0015.5 0h-7a.5.5 0 00-.481.363l-.7 2.455A.248.248 0 017.077 3H6a3 3 0 00-3 3v12a3 3 0 003 3h1.077a.249.249 0 01.24.181l.7 2.456A.5.5 0 008.5 24h7a.5.5 0 00.48-.363l.7-2.456a.25.25 0 01.24-.181H18a3 3 0 003-3zm-2 12a1 1 0 01-1 1H6a1 1 0 01-1-1V6a1 1 0 011-1h12a1 1 0 011 1z\"/><path d=\"M15.506 10.5h-1.482a.251.251 0 01-.234-.162l-.844-2.229a.437.437 0 00-.023-.049 1.053 1.053 0 00-1.855.049l-.845 2.229a.251.251 0 01-.234.162H8.506a.986.986 0 00-.933.611 1.029 1.029 0 00.3 1.154l1.231.859a.249.249 0 01.086.3l-.716 1.645a1 1 0 00.251 1.158 1.027 1.027 0 001.2.133l1.958-1.1a.254.254 0 01.245 0l1.957 1.1a1.017 1.017 0 001.2-.132 1 1 0 00.251-1.158l-.718-1.646a.252.252 0 01.086-.3l1.3-.912a1.006 1.006 0 00.238-1.1.986.986 0 00-.936-.612z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});