define("ember-svg-jar/inlined/download-thick-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>download-thick-box</title><path d=\"M.964 4.941a.251.251 0 00.047.213.248.248 0 00.2.1h9.777A.251.251 0 0011.235 5L11.164.246a.249.249 0 00-.25-.246H4.653a2.588 2.588 0 00-2.286 1.526L1.192 4.2a5.731 5.731 0 00-.228.741zM12.985 5.25h9.807a.248.248 0 00.2-.1.251.251 0 00.047-.213 5.731 5.731 0 00-.228-.741 1.1 1.1 0 00-.039-.085l-1.316-2.56A2.6 2.6 0 0019.153 0h-6.238a.249.249 0 00-.25.253L12.735 5a.251.251 0 00.25.25zM.75 7v15a2 2 0 002 2h18.5a2 2 0 002-2V7a.25.25 0 00-.25-.25H1A.25.25 0 00.75 7zm5.793 8.048A.5.5 0 017 14.75h1.75A.25.25 0 009 14.5V9.75a.5.5 0 01.5-.5h4.75a.5.5 0 01.5.5v4.75a.25.25 0 00.25.25h1.75a.5.5 0 01.378.827l-4.75 5.5a.5.5 0 01-.372.173H12a.5.5 0 01-.37-.164l-5-5.5a.5.5 0 01-.087-.538z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});