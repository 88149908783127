define("ember-svg-jar/inlined/arrow-button-circle-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-circle-right</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM8.253 17.571A.5.5 0 018 17.138V6.862a.5.5 0 01.748-.435l8.993 5.139a.5.5 0 010 .868l-8.989 5.139a.5.5 0 01-.499-.002z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});