define("ember-svg-jar/inlined/pregnancy-condom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pregnancy-condom</title><path d=\"M1.708 14.513a1 1 0 10-1.414 1.414l7.779 7.778a1 1 0 001.414-1.414zM23.56.44a1.5 1.5 0 00-2.122 0l-.577.579a1.5 1.5 0 01-1.451.387 4.5 4.5 0 00-4.334 1.156L3.646 13.99a.5.5 0 000 .707L9.3 20.354a.5.5 0 00.707 0L21.439 8.926a4.5 4.5 0 001.155-4.334 1.509 1.509 0 01.388-1.452l.578-.578a1.5 1.5 0 000-2.122zm-4.394 4.225a3.255 3.255 0 00-2.489.936l-.93.93a.75.75 0 01-1.061-1.061l.93-.93a4.7 4.7 0 013.649-1.371.75.75 0 11-.1 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});