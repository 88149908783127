define("ember-svg-jar/inlined/car-repair-wash-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-repair-wash-2</title><circle cx=\"12.25\" cy=\".999\" r=\".999\"/><circle cx=\"10.502\" cy=\"3.995\" r=\".999\"/><circle cx=\"12.25\" cy=\"6.992\" r=\".999\"/><circle cx=\"9.254\" cy=\".999\" r=\".999\"/><circle cx=\"7.256\" cy=\"3.995\" r=\".999\"/><circle cx=\"8.255\" cy=\"6.992\" r=\".999\"/><circle cx=\"4.26\" cy=\"6.992\" r=\".999\"/><circle cx=\"15.247\" cy=\".999\" r=\".999\"/><circle cx=\"13.998\" cy=\"3.995\" r=\".999\"/><circle cx=\"16.245\" cy=\"6.992\" r=\".999\"/><circle cx=\"17.244\" cy=\"3.995\" r=\".999\"/><circle cx=\"20.241\" cy=\"6.992\" r=\".999\"/><path d=\"M7.006 22H3.011a.25.25 0 00-.25.25v.5A1.25 1.25 0 004.01 24h2a1.25 1.25 0 001.249-1.248v-.5A.25.25 0 007.006 22zM20.989 22h-4a.25.25 0 00-.25.25v.5A1.25 1.25 0 0017.993 24h2a1.25 1.25 0 001.249-1.248v-.5a.25.25 0 00-.253-.252zM21.872 16.582L20.788 15.5a.481.481 0 01-.11-.167l-2.048-5.12a1.491 1.491 0 00-1.392-.943H6.928a1.493 1.493 0 00-1.392.942L3.6 15.055a.51.51 0 01-.11.168l-1.508 1.506a.746.746 0 00-.22.529v2.5A1.25 1.25 0 003.011 21h17.978a1.25 1.25 0 001.249-1.249v-2.29a1.258 1.258 0 00-.366-.879zm-3.935-2.43a.25.25 0 01-.207.11H6.436a.25.25 0 01-.207-.11.254.254 0 01-.025-.233l1-2.5a.251.251 0 01.232-.157h9.3a.251.251 0 01.232.157l1 2.5a.254.254 0 01-.031.233z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});