define("ember-svg-jar/inlined/user-man-stress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>user-man-stress</title><path d=\"M12 15.5a5.084 5.084 0 10-5.083-5.084A5.089 5.089 0 0012 15.5zm0-2a3.085 3.085 0 01-3.006-3.77.251.251 0 01.378-.155 7.228 7.228 0 005.385.957.25.25 0 01.3.277A3.087 3.087 0 0112 13.5zM12 16a7.508 7.508 0 00-7.5 7.5.5.5 0 00.5.5h14a.5.5 0 00.5-.5A7.508 7.508 0 0012 16zm-.75 2a.75.75 0 011.5 0v3a.75.75 0 01-1.5 0zM22.707 1.793a1 1 0 00-1.414 0l-2 2a1 1 0 00-.125 1.261l.344.517a.249.249 0 01-.058.338L18.4 6.7a1 1 0 101.2 1.6l2-1.5a1 1 0 00.232-1.355L21.4 4.8a.251.251 0 01.031-.316l1.275-1.275a1 1 0 00.001-1.416zM5.8 8.1a1 1 0 00-.2-1.4l-1.054-.791a.249.249 0 01-.058-.338l.344-.517a1 1 0 00-.125-1.261l-2-2a1 1 0 00-1.414 1.414l1.275 1.275A.251.251 0 012.6 4.8l-.431.647A1 1 0 002.4 6.8l2 1.5a1 1 0 001.4-.2z\"/><path d=\"M11.293 4.207a1 1 0 001.414-1.414l-.275-.276A.25.25 0 0112.4 2.2l.431-.648A1 1 0 0011.168.445l-1 1.5a1 1 0 00.125 1.262z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});