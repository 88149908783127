define("ember-svg-jar/inlined/flash-drive-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flash-drive-lock</title><path d=\"M11.5 22.5V17a3 3 0 011.385-2.528.251.251 0 00.115-.21v-.512a4.8 4.8 0 013.765-4.33.249.249 0 00.177-.306A1.494 1.494 0 0015.5 8a.25.25 0 01-.25-.25v-6A1.752 1.752 0 0013.5 0h-9a1.752 1.752 0 00-1.75 1.75v6A.25.25 0 012.5 8 1.5 1.5 0 001 9.5v12A2.5 2.5 0 003.5 24h8.011a.251.251 0 00.23-.349A2.906 2.906 0 0111.5 22.5zM4.25 1.751A.25.25 0 014.5 1.5h9a.25.25 0 01.25.25v6a.25.25 0 01-.25.25h-9a.25.25 0 01-.25-.25z\"/><rect x=\"6\" y=\"3.501\" width=\"2\" height=\"2\" rx=\".5\" ry=\".5\"/><rect x=\"10\" y=\"3.501\" width=\"2\" height=\"2\" rx=\".5\" ry=\".5\"/><path d=\"M21.714 15.516a.25.25 0 01-.214-.248v-1.017a3.5 3.5 0 10-7 0v1.017a.25.25 0 01-.214.248A1.5 1.5 0 0013 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.286-1.484zM18 12.751a1.5 1.5 0 011.5 1.5v1a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-1a1.5 1.5 0 011.5-1.5zm1 7.5a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});