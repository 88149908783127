define("ember-svg-jar/inlined/plane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane</title><path d=\"M23.369 8.86a1.059 1.059 0 00-1.05-.66.367.367 0 00-.13.017l-.027.008-6.2 1.817a.5.5 0 00-.35.576l.785 4.017a.5.5 0 00.8.295c2.085-1.651 5.319-4.207 5.706-4.55a1.409 1.409 0 00.466-1.52zM8.041 10.042l-6.2-1.817-.028-.009a.358.358 0 00-.13-.016 1.069 1.069 0 00-1.05.66 1.427 1.427 0 00.47 1.52c.4.343 3.623 2.9 5.707 4.543a.5.5 0 00.8-.3l.785-4.009a.5.5 0 00-.354-.572z\"/><path d=\"M15.83 16.952l-1.875-9.566a.5.5 0 01.115-.426l1.579-1.8A1 1 0 0014.9 3.5h-1.169a.5.5 0 01-.433-.25 1.5 1.5 0 00-2.593 0 .5.5 0 01-.433.25H9.106a1 1 0 00-.752 1.66l1.579 1.8a.5.5 0 01.115.426l-1.878 9.58v.016a3.886 3.886 0 107.666-.015zM13.252 18h-2.5a.75.75 0 010-1.5h2.5a.75.75 0 110 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});