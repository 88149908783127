define("ember-svg-jar/inlined/fish-bowl-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fish-bowl-bubble</title><path d=\"M19.327 8.3a.5.5 0 00-.458-.3H5.255a.5.5 0 00-.454.291C.029 18.654.005 19.892 1.859 22.126a4.489 4.489 0 003.463 1.626h13.36a4.489 4.489 0 003.463-1.626c2.139-2.579 1.3-4.426-2.818-13.826zm-4.678 9.352l2.25-1.9a.7.7 0 011.2.559v4a.7.7 0 01-1.2.559l-2.25-1.9-2.006 1.7a2.491 2.491 0 01-3.114.092L7.2 19.008a.855.855 0 010-1.4l2.325-1.743a2.487 2.487 0 013.114.092zm-4.56-7.4a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm-2.5 3.25a.75.75 0 11-.75.75.75.75 0 01.75-.752zM18.785 6.5a.5.5 0 00.469-.327L21.1 1.191a.652.652 0 00-.145-.821.638.638 0 00-.81.046L15.77 3.239 12.777.6A.966.966 0 0011.4.563L8.652 3.236 4 .418A.648.648 0 003.188.37a.646.646 0 00-.151.811l1.852 4.994a.5.5 0 00.469.327zM3.726.836zm-.314.39l.119-.148.007.018-.1.141z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});