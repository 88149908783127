define("ember-svg-jar/inlined/presentation-statistics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>presentation-statistics</title><path d=\"M11.5 2h10.25a.249.249 0 01.25.25V12.5a.25.25 0 01-.25.25H13.5a1 1 0 000 2H22a2 2 0 002-2V2a2 2 0 00-2-2H11.5a1 1 0 000 2z\"/><rect x=\"17.997\" y=\"4\" width=\"2.5\" height=\"7.5\" rx=\".75\" ry=\".75\"/><circle cx=\"5.503\" cy=\"4\" r=\"4\"/><rect x=\"13.997\" y=\"6.5\" width=\"2.5\" height=\"5\" rx=\".75\" ry=\".75\"/><path d=\"M8.239 18.349a4.315 4.315 0 012.48-3.908.5.5 0 00.281-.451A5.5 5.5 0 000 14v3.5a.5.5 0 00.5.5h2.04l.462 5.541A.5.5 0 003.5 24h4a.5.5 0 00.5-.459l.345-4.14a.477.477 0 00-.009-.146 4.382 4.382 0 01-.097-.906zM23.513 24a.485.485 0 00.377-.789 4.979 4.979 0 00-2.443-1.645.251.251 0 01-.058-.453 2.759 2.759 0 10-2.865.016.25.25 0 01-.054.453 4.954 4.954 0 00-1.926 1.131.251.251 0 01-.344 0 4.964 4.964 0 00-1.951-1.139.25.25 0 01-.055-.453 2.76 2.76 0 10-2.865 0 .251.251 0 01-.056.453 4.977 4.977 0 00-2.419 1.637.485.485 0 00.377.789z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});