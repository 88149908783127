define("ember-svg-jar/inlined/road-sign-hill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-hill</title><path d=\"M12.53.47a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0l11-11a.749.749 0 000-1.06zm3.454 13.345a.626.626 0 01-.608.482l-6.75.011A.625.625 0 018 13.683v-3.365a.625.625 0 01.9-.56l6.75 3.355a.625.625 0 01.334.702z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});