define("ember-svg-jar/inlined/ipod-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ipod-1</title><path d=\"M18 20a2 2 0 01-4 0V5A5 5 0 004 5v.75a.25.25 0 01-.25.25H1.5A1.5 1.5 0 000 7.5v15A1.5 1.5 0 001.5 24h8a1.5 1.5 0 001.5-1.5v-15A1.5 1.5 0 009.5 6H6.25A.25.25 0 016 5.75V5a3 3 0 016 0v15a4 4 0 008 0v-1.25a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25zM2.5 13a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h6a.5.5 0 01.5.5v4a.5.5 0 01-.5.5zm6 6a3 3 0 11-3-3 3 3 0 013 3z\"/><path d=\"M21.5 13a1.5 1.5 0 00-1.355.857.25.25 0 01-.226.143H18.5a1.5 1.5 0 000 3h1.419a.25.25 0 01.226.143A1.5 1.5 0 0021.5 18a2.5 2.5 0 000-5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});