define("ember-svg-jar/inlined/power-adapter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>power-adapter</title><path d=\"M14.608.153a.491.491 0 00-.42.1.5.5 0 00-.188.388v1.97a.25.25 0 01-.25.25h-3.5a.25.25 0 01-.25-.25V.641a.5.5 0 00-.608-.488A12 12 0 1024 11.861 12.07 12.07 0 0014.608.153zM6.75 12.861A1.75 1.75 0 115 11.111a1.751 1.751 0 011.75 1.75zM9 16.111a1.75 1.75 0 11-1.75 1.75A1.751 1.751 0 019 16.111zm1.5-3.25a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v6a.5.5 0 01-.5.5zm4.5 3.25a1.75 1.75 0 11-1.75 1.75 1.751 1.751 0 011.75-1.75zm2.25-3.25a1.75 1.75 0 111.75 1.75 1.751 1.751 0 01-1.75-1.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});