define("ember-svg-jar/inlined/style-three-pin-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-information</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.749 7.3 12.286 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm0 3a1.5 1.5 0 11-1.5 1.5A1.5 1.5 0 0112 3zm3.75 12.25a1 1 0 01-1 1h-4a1 1 0 010-2h.5a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5h-.5a1 1 0 010-2h1a2 2 0 012 2v4.5a.5.5 0 00.5.5h.5a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});