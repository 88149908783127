define("ember-svg-jar/inlined/accessories-cane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accessories-cane</title><path d=\"M17.356 23.363l-1-3.5a.5.5 0 00-.481-.363h-2a.5.5 0 00-.481.363l-1 3.5a.5.5 0 00.481.637h4a.5.5 0 00.481-.637zM13.625 4.75V17.5a.75.75 0 00.75.75h1a.75.75 0 00.75-.75V4.75a4.75 4.75 0 00-9.5 0 1.25 1.25 0 002.5 0 2.25 2.25 0 014.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});