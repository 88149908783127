define("ember-svg-jar/inlined/video-file-m4v", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-m4v</title><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.427A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6zM12.652 14.327a4.888 4.888 0 00-1.268 1.828.158.158 0 00.145.22h1.083a.25.25 0 00.25-.25v-1.7c0-.178-.142-.16-.21-.098z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.25a.25.25 0 00-.25-.25zM9.125 19a.625.625 0 01-1.25 0v-3.018c0-.111-.132-.071-.167 0l-.149.3a.65.65 0 01-1.118 0s-.093-.181-.148-.3-.168-.078-.168 0V19a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279s.711 1.443.786 1.571a.179.179 0 00.308.005l.788-1.576A.625.625 0 019.125 13zm4.987 0a.625.625 0 01-1.25 0v-1.123a.252.252 0 00-.252-.252H10.5a.624.624 0 01-.616-.731 6.146 6.146 0 013.327-4.445.625.625 0 01.9.561zm5.013-4.5a8.176 8.176 0 01-1.625 4.875.625.625 0 01-1 0 8.176 8.176 0 01-1.625-4.875V13a.625.625 0 011.25 0v1.5a6.923 6.923 0 00.674 2.965c.063.132.328.153.387.033a6.927 6.927 0 00.689-3V13a.625.625 0 011.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});