define("ember-svg-jar/inlined/programming-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-user</title><path d=\"M11 0a1 1 0 000 2h10.5a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-8a1 1 0 000 2H22a2 2 0 002-2V2a2 2 0 00-2-2z\"/><path d=\"M11.97 9.47a.75.75 0 101.06 1.06l2.5-2.5a.749.749 0 000-1.06l-2.5-2.5a.75.75 0 00-1.06 1.06l1.793 1.793a.252.252 0 010 .354zM16.5 10.75H20a.75.75 0 000-1.5h-3.5a.75.75 0 000 1.5zM5.08 7.733A5.086 5.086 0 000 12.813v3.055a.5.5 0 00.5.5h1.611a.248.248 0 01.249.225l.7 6.958a.5.5 0 00.5.45h3.047a.5.5 0 00.5-.45l.7-6.958a.249.249 0 01.249-.225H9.66a.5.5 0 00.5-.5v-3.055a5.086 5.086 0 00-5.08-5.08zM8.537 3.609a3.3 3.3 0 10-3.3 3.3 3.3 3.3 0 003.3-3.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});