define("ember-svg-jar/inlined/technology-fitbit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>technology-fitbit</title><circle cx=\"21\" cy=\"12\" r=\"2.5\"/><circle cx=\"10.5\" cy=\"12\" r=\"2\"/><circle cx=\"6\" cy=\"12\" r=\"1.5\"/><circle cx=\"2\" cy=\"12\" r=\"1.5\"/><circle cx=\"10.5\" cy=\"17\" r=\"2\"/><circle cx=\"6\" cy=\"17\" r=\"1.5\"/><circle cx=\"10.5\" cy=\"7\" r=\"2\"/><circle cx=\"15.5\" cy=\"12\" r=\"2\"/><circle cx=\"15.5\" cy=\"17\" r=\"2\"/><circle cx=\"15.5\" cy=\"7\" r=\"2\"/><circle cx=\"10.5\" cy=\"2\" r=\"2\"/><circle cx=\"10.5\" cy=\"22\" r=\"2\"/><circle cx=\"6\" cy=\"7\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});