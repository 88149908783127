define("ember-svg-jar/inlined/flying-insect-ladybug-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flying-insect-ladybug-1</title><path d=\"M22.75 19.946a1.429 1.429 0 01-1.334-.9l-.287-.716a.251.251 0 01.131-.33c.881-.39 1.439-.875 1.439-1.459a6.558 6.558 0 00-2.274-4.784 19 19 0 01-4.148 3.492c-.5.32-1.436 1.141-1.938.3a.75.75 0 01-.042-.682q.625-.357 1.29-.78c4.212-2.681 6.737-6.393 7.055-8.97a1 1 0 00-.368-.9 11.463 11.463 0 00-13.279-.054 10.231 10.231 0 00-4.814 8.046A3.84 3.84 0 00.25 16c0 2.151 2.019 2.151 3.8 2.151 1.531 0 2.152-.008 2.43 0a.249.249 0 01.225.342l-.222.554a1.43 1.43 0 01-1.334.9 1 1 0 000 2 3.423 3.423 0 003.192-2.162L8.657 19a.248.248 0 01.281-.152c.476.095.98.173 1.5.235a.248.248 0 01.211.314l-.052.193a.529.529 0 01-.5.361 1 1 0 100 2 2.539 2.539 0 002.412-1.779l.2-.742a.249.249 0 01.245-.183c.753.013 1.434-.011 1.956-.039a.25.25 0 01.255.182l.231.833a2.524 2.524 0 002.4 1.728 1 1 0 000-2 .519.519 0 01-.485-.311l-.1-.358a.255.255 0 01.032-.205.251.251 0 01.175-.11 22.869 22.869 0 001.483-.254.251.251 0 01.283.152l.37.923a3.417 3.417 0 003.191 2.163 1 1 0 000-2zM3.225 14.171a1 1 0 11-1 1 1 1 0 011-1zm17-7.93a3.25 3.25 0 00-3.653 4.659 22.96 22.96 0 01-3.511 2.369A3.248 3.248 0 007.4 15.445v.008a8.549 8.549 0 01-.958.11 9.231 9.231 0 01.484-6.193 4.357 4.357 0 006.264-4.993 9.047 9.047 0 012.456-.343 9.408 9.408 0 014.876 1.4 7.46 7.46 0 01-.295.807z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});