define("ember-svg-jar/inlined/multiple-man-woman-2.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-man-woman-2</title><path d=\"M13.976 6.063a.5.5 0 00.219.309A7 7 0 0017.96 7.5a6.36 6.36 0 00.929-.07.5.5 0 01.569.564A3.022 3.022 0 0116.5 10.5a2.929 2.929 0 01-1.445-.38.36.36 0 00-.48.509 2.457 2.457 0 01.388 1.248.5.5 0 00.374.466 4.6 4.6 0 001.163.157 5 5 0 10-3.128-8.889.5.5 0 00-.117.641 6.527 6.527 0 01.721 1.811zM24 21a7.508 7.508 0 00-7.5-7.5 7.379 7.379 0 00-2.478.432.437.437 0 00-.164.72A8.955 8.955 0 0116.5 21v.024a.469.469 0 00.469.476H23.5a.5.5 0 00.5-.5zM0 21a.5.5 0 00.5.5h14a.5.5 0 00.5-.5 7.5 7.5 0 00-15 0zM13.017 12.825a1 1 0 00.3-1.382 4.744 4.744 0 01-.668-2.608V7.546a5.046 5.046 0 00-10.092 0v1.289a4.736 4.736 0 01-.668 2.608 1 1 0 001.681 1.084 5.349 5.349 0 00.482-.963.249.249 0 01.39-.1 4.976 4.976 0 006.321 0 .25.25 0 01.391.1 5.293 5.293 0 00.482.964 1 1 0 001.381.297zm-8.424-4.8a.5.5 0 01.344-.555 5.88 5.88 0 002.5-1.552.25.25 0 01.362 0 5.89 5.89 0 002.468 1.541.5.5 0 01.342.552 3.044 3.044 0 01-6.017.013z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});