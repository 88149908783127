define("ember-svg-jar/inlined/maps-pin-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>maps-pin-2</title><path d=\"M24 12.1a1 1 0 00-.713-.958l-7-2.1a1 1 0 00-1.245.671.964.964 0 00-.026.287.016.016 0 00-.016.016v9.814a.5.5 0 01-.378.485l-5 1.252A.5.5 0 019 21.082v-9.065A.018.018 0 008.983 12a.988.988 0 00-.708-.962l-7-2A1 1 0 000 10v10.9a1 1 0 00.713.958l7 2.1a1.068 1.068 0 00.516.015l7.617-1.908a.5.5 0 01.259 0l6.62 1.891A1 1 0 0024 23zm-22-.111a.5.5 0 01.637-.481l4 1.142a.5.5 0 01.363.481v7.853a.5.5 0 01-.644.479l-4-1.2A.5.5 0 012 19.786zm20 9.022a.5.5 0 01-.637.481l-4-1.143a.5.5 0 01-.363-.481v-7.853a.5.5 0 01.644-.479l4 1.2a.5.5 0 01.356.479z\"/><path d=\"M11 6.837V17.25a1 1 0 002 0V6.837a3.5 3.5 0 10-2 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});