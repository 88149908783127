define("ember-svg-jar/inlined/social-media-slide-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-slide-share</title><path d=\"M22.7 13.25a.5.5 0 00-.53-.1l-.13.05V3.26a3 3 0 00-3-3H5a3 3 0 00-3 3v10l-.22-.08a.5.5 0 00-.65.64C2 16 5.69 16.07 5.05 17.21a4.2 4.2 0 003.49 6.53c4 0 2-5.47 3.55-5.47s-.45 5.47 3.55 5.47a4.2 4.2 0 003.49-6.53c-.64-1.14 2.95-1 3.71-3.43a.5.5 0 00-.14-.53zm-7.84 1.39c-2.3 0-2.77 1.09-2.77 1.09s-.47-1.09-2.77-1.09A19.44 19.44 0 014 13.88V3.26a1 1 0 011-1h14a1 1 0 011 1V13.9a18.71 18.71 0 01-5.14.74z\"/><circle cx=\"15.54\" cy=\"9.76\" r=\"2.5\"/><circle cx=\"8.54\" cy=\"9.76\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});