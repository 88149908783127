define("ember-svg-jar/inlined/single-neutral-actions-heart.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-heart</title><path d=\"M10.03 13.779a5.025 5.025 0 012.807-2.479.25.25 0 00.006-.469 61.306 61.306 0 00-1.345-.5l-.628-.23a.59.59 0 01-.138-.311 2.561 2.561 0 01.058-1.3 5.618 5.618 0 001.466-4.314C12.256 1.756 10.676 0 8.5 0S4.744 1.756 4.744 4.174A5.605 5.605 0 006.2 8.471a2.456 2.456 0 01.072 1.316.609.609 0 01-.134.309l-.631.232c-2.447.9-4.215 1.55-4.754 2.626A9.173 9.173 0 000 16.5a.5.5 0 00.5.5h8.806a.25.25 0 00.248-.285 5.015 5.015 0 01.476-2.936zM23.627 14.449a3.525 3.525 0 00-5.646-.916l-.305.3a.25.25 0 01-.353 0l-.3-.3a3.526 3.526 0 00-5.646.916 3.521 3.521 0 00.653 4.063l5.113 5.333a.5.5 0 00.723 0l5.106-5.327a3.522 3.522 0 00.655-4.069z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});