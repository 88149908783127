define("ember-svg-jar/inlined/database-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-question</title><path d=\"M8.844 21.528a.25.25 0 00.243-.367 7.915 7.915 0 01-.918-3.007.25.25 0 00-.216-.223 12.819 12.819 0 01-6.168-2.216.25.25 0 00-.408.194v1.716c0 1.915 3.199 3.511 7.467 3.903zM1.377 13.125c0 1.768 2.725 3.266 6.5 3.8a.251.251 0 00.284-.227 7.959 7.959 0 01.767-2.815.25.25 0 00-.006-.232.254.254 0 00-.2-.127 13.6 13.6 0 01-6.943-2.3.25.25 0 00-.408.194zM10.878 9.125c-3.5 0-7.156-.833-9.093-2.411a.25.25 0 00-.408.194v1.717c0 2.026 3.578 3.7 8.214 3.961a.254.254 0 00.213-.1 7.945 7.945 0 019.656-2.374.251.251 0 00.29-.058 2.131 2.131 0 00.627-1.432V6.91a.249.249 0 00-.407-.194c-1.938 1.577-5.588 2.409-9.092 2.409z\"/><ellipse cx=\"10.878\" cy=\"4.125\" rx=\"9.5\" ry=\"4\"/><path d=\"M16.123 10.875a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-1 10.5a1 1 0 111 1 1 1 0 01-1-1zm.25-3.3a1.256 1.256 0 01.986-1.221A1.125 1.125 0 1015 15.75a.75.75 0 01-1.5 0 2.625 2.625 0 113.537 2.461.25.25 0 00-.162.234v.18a.75.75 0 11-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});