define("ember-svg-jar/inlined/shopping-bag-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-bag-woman</title><path d=\"M7.64 8.63a1 1 0 001.381-.3 4.188 4.188 0 00.231-.4.251.251 0 01.384-.078 4 4 0 005.141 0 .25.25 0 01.384.078 4.447 4.447 0 00.231.4 1 1 0 00.84.458.987.987 0 00.541-.161 1 1 0 00.3-1.382A3.4 3.4 0 0116.6 5.38v-.986a4.392 4.392 0 10-8.784 0v.986a3.408 3.408 0 01-.472 1.868A1 1 0 007.64 8.63zm4.388-5.038a.25.25 0 01.357 0 4.511 4.511 0 001.67 1.083.216.216 0 01.141.214 1.992 1.992 0 01-3.98 0 .216.216 0 01.141-.213 4.538 4.538 0 001.671-1.084z\"/><path d=\"M16.83 11.4a.253.253 0 00.1-.177.249.249 0 00-.063-.2 6.255 6.255 0 00-9.5.2.25.25 0 00.032.352 3.745 3.745 0 011.336 2.577.25.25 0 00.249.23h6.026a.249.249 0 00.249-.23A3.75 3.75 0 0116.83 11.4zM7.885 16a.134.134 0 01-.135-.135v-1.412a2.75 2.75 0 00-5.5 0v1.412a.134.134 0 01-.135.135 1.157 1.157 0 00-1.146 1.013L.259 22.7A1.156 1.156 0 001.405 24H8.6a1.156 1.156 0 001.146-1.3l-.71-5.687A1.157 1.157 0 007.885 16zm-1.635-.25A.25.25 0 016 16H4a.25.25 0 01-.25-.25v-1.3a1.25 1.25 0 012.5 0zM22.6 24a1.156 1.156 0 001.146-1.3l-.71-5.687A1.157 1.157 0 0021.885 16a.134.134 0 01-.135-.135v-1.412a2.75 2.75 0 00-5.5 0v1.412a.134.134 0 01-.135.135 1.157 1.157 0 00-1.146 1.013l-.71 5.687a1.156 1.156 0 001.146 1.3zm-2.35-8.25A.25.25 0 0120 16h-2a.25.25 0 01-.25-.25v-1.3a1.25 1.25 0 012.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});