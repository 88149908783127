define("ember-svg-jar/inlined/outdoors-bubble-ball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-bubble-ball</title><path d=\"M14.191 7.69A2.191 2.191 0 1012 9.881a2.191 2.191 0 002.191-2.191z\"/><path d=\"M13.488 12.948A5.324 5.324 0 0017.31 9.7a1 1 0 10-1.858-.742 3.339 3.339 0 01-3.115 2.109h-.674a3.339 3.339 0 01-3.115-2.105A1 1 0 106.69 9.7a5.328 5.328 0 003.822 3.244.618.618 0 01.488.6.715.715 0 01-.209.505l-2.236 2.244a1 1 0 101.414 1.414l1.677-1.678a.5.5 0 01.708 0l1.677 1.678a1 1 0 001.414-1.414l-2.236-2.236a.715.715 0 01-.209-.505.617.617 0 01.488-.604z\"/><path d=\"M3.288 18.346a3 3 0 011.256-.332.25.25 0 00.182-.41 9.5 9.5 0 1114.548 0 .25.25 0 00.181.41 3.046 3.046 0 011.26.33.5.5 0 00.633-.154A11.5 11.5 0 10.5 11.5a11.432 11.432 0 002.154 6.692.5.5 0 00.634.154zM23 22a3.745 3.745 0 01-2.9-1.646 1.056 1.056 0 00-1.546.023A3.72 3.72 0 0115.667 22a3.743 3.743 0 01-2.9-1.646 1.055 1.055 0 00-1.547.024A3.72 3.72 0 018.333 22a3.745 3.745 0 01-2.9-1.646 1.055 1.055 0 00-1.547.024A3.718 3.718 0 011 22a1 1 0 000 2 5.126 5.126 0 003.346-1.243.5.5 0 01.639 0 5.128 5.128 0 006.694 0 .5.5 0 01.639 0 5.129 5.129 0 006.7 0 .5.5 0 01.639 0A5.131 5.131 0 0023 24a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});