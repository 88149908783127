define("ember-svg-jar/inlined/gardening-lawn-mower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gardening-lawn-mower</title><path d=\"M10.5 13.75H18a2 2 0 000-4h-2.5a.5.5 0 01-.5-.5v-.5a1 1 0 00-2 0v.5a.5.5 0 01-.5.5h-2a1.5 1.5 0 00-1.5 1.5v1a1.5 1.5 0 001.5 1.5z\"/><path d=\"M20.952 16.539a.5.5 0 00-.111-.478A2.493 2.493 0 0019 15.25H8.137a.5.5 0 01-.481-.365L4.607 3.987A3.757 3.757 0 001 1.25a1 1 0 000 2 1.751 1.751 0 011.681 1.275L6 16.391a.374.374 0 00.339.373 3.507 3.507 0 013.134 3.047.5.5 0 00.5.437h7.563a.5.5 0 00.5-.437 3.494 3.494 0 012.564-2.929.5.5 0 00.352-.343z\"/><circle cx=\"21.5\" cy=\"20.25\" r=\"2.5\"/><circle cx=\"6\" cy=\"20.25\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});