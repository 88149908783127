define("ember-svg-jar/inlined/pollution-fish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pollution-fish</title><path d=\"M23.236.757C22.677.2 21.136 0 19.877 0a13.843 13.843 0 00-9.134 3.351.994.994 0 01-.663.254A8.063 8.063 0 002.687 8.6a1.3 1.3 0 00.293 1.374l2.56 2.567a.5.5 0 01.139.438c-.1.584-.153 1.068-.182 1.415a.5.5 0 01-.274.406L.577 17.125A1.052 1.052 0 000 18.07a1.038 1.038 0 00.593.93l2.878 1.379a1 1 0 01.484.5l1.085 2.493a1.048 1.048 0 00.93.628H6a1.047 1.047 0 00.933-.579l2.3-4.6a.5.5 0 01.41-.277 16.983 16.983 0 002.547-.4.5.5 0 01.47.136l2.386 2.414a1.3 1.3 0 00.919.383h.008a1.3 1.3 0 00.924-.4 22.66 22.66 0 003.486-4.577 10.8 10.8 0 001.406-4.021 1 1 0 01.147-.516c2.713-4.434 2.289-9.813 1.3-10.806zm-4.025 4.748l.747.748A.75.75 0 1118.9 7.314l-.748-.748a.5.5 0 00-.707 0l-.748.748a.75.75 0 11-1.06-1.061l.747-.748a.5.5 0 000-.707l-.747-.747a.75.75 0 111.06-1.06l.748.747a.5.5 0 00.707 0l.748-.747a.75.75 0 011.06 1.06l-.747.747a.5.5 0 00-.002.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});