define("ember-svg-jar/inlined/folder-image-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-image-alternate</title><path d=\"M5.365 3.4l1.192 1.238A2 2 0 008 5.25h13a1 1 0 000-2H8.208a.5.5 0 01-.359-.15L6.657 1.862a2 2 0 00-1.441-.612h-2.93A2.285 2.285 0 000 3.536V15.25a1 1 0 002 0V3.536a.286.286 0 01.286-.286h2.719a.5.5 0 01.36.15z\"/><path d=\"M22 6.75H6a2 2 0 00-2 2v12a2 2 0 002 2h16a2 2 0 002-2v-12a2 2 0 00-2-2zm0 13.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5h15a.5.5 0 01.5.5z\"/><path d=\"M17.353 15.177a1 1 0 00-1.587-.067l-1.511 1.944a.249.249 0 01-.349.045l-1.579-1.206a1 1 0 00-1.273.206l-1.478 2.388a.5.5 0 00.424.763h8.343a.5.5 0 00.46-.7z\"/><circle cx=\"11.103\" cy=\"11.75\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});