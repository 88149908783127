define("ember-svg-jar/inlined/graphic-tablet-drawing-pen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>graphic-tablet-drawing-pen</title><path d=\"M3.194 16.562a.5.5 0 00-.816.164L.756 20.665a1.972 1.972 0 00-.085 1.25l-.378.378a1 1 0 001.414 1.414l.378-.378a1.973 1.973 0 001.25-.086l3.939-1.622a.5.5 0 00.163-.815zM23.267 2.145L21.853.731a2.5 2.5 0 00-3.536 0l-1.06 1.061a.5.5 0 000 .707L21.5 6.741a.5.5 0 00.707 0l1.06-1.061a2.5 2.5 0 000-3.535zM16.3 3.457a.516.516 0 00-.707 0L4.152 14.9a.5.5 0 000 .707L8.4 19.849a.5.5 0 00.707 0L20.543 8.406a.5.5 0 000-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});