define("ember-svg-jar/inlined/protection-mask-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>protection-mask-1</title><path d=\"M12 0A10.011 10.011 0 002 10c0 2.388 1.115 4.251 2.544 6.237a.5.5 0 00.875-.121 7 7 0 0113.162 0 .5.5 0 00.875.121C20.885 14.251 22 12.388 22 10A10.011 10.011 0 0012 0zM8 9.5a2 2 0 112-2 2 2 0 01-2 2zm8 0a2 2 0 112-2 2 2 0 01-2 2z\"/><path d=\"M12 13a5.5 5.5 0 105.5 5.5A5.5 5.5 0 0012 13zm-3 6.5a1 1 0 111-1 1 1 0 01-1 1zm3 3a1 1 0 111-1 1 1 0 01-1 1zm0-3a1 1 0 111-1 1 1 0 01-1 1zm0-3a1 1 0 111-1 1 1 0 01-1 1zm3 3a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});