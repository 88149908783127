define("ember-svg-jar/inlined/heavy-equipment-mortar-truck-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>heavy-equipment-mortar-truck-2</title><path d=\"M1.993 7.405l8.987-.063a.751.751 0 00.534-1.273l-2.29-2.361a4.016 4.016 0 00-5.546.06L1.442 6.14a.751.751 0 00.551 1.265z\"/><path d=\"M20.518 14.775a3.982 3.982 0 012.595.935.25.25 0 00.41-.194l-.023-2.762a2.5 2.5 0 00-2.517-2.482l-2 .014a.5.5 0 00-.353.149L17 12.033l-.017-2.368a1 1 0 001-1.73l-2.509-1.482a1 1 0 00-1.017 1.722l.517.3.039 5.584a.249.249 0 01-.248.251l-4.125.029 1.476-1.2a1.179 1.179 0 00.385-.815l-.025-3.5a.5.5 0 00-.5-.5l-11 .077a.5.5 0 00-.5.5l.007 1a1.849 1.849 0 00.293.908L2.86 13.26a4.324 4.324 0 003.159 1.617h.5a4.006 4.006 0 014.028 3.972c0 .079 0 .158-.006.235a.255.255 0 00.069.186.249.249 0 00.183.077l5.514-.038a.251.251 0 00.248-.267c0-.078-.008-.156-.009-.235a4 4 0 013.972-4.032z\"/><circle cx=\"6.547\" cy=\"18.873\" r=\"2.5\"/><circle cx=\"20.546\" cy=\"18.775\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});