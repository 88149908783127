define("ember-svg-jar/inlined/police-woman-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-woman-3</title><path d=\"M23.5 23.5v-2.1a2.461 2.461 0 00-.967-1.978C21.105 18.327 17.838 16.5 12 16.5s-9.1 1.827-10.533 2.917A2.461 2.461 0 00.5 21.4v2.1a.5.5 0 00.5.5h22a.5.5 0 00.5-.5zm-6.639-.541l-.609-.405a2.249 2.249 0 01-1-1.872v-.931a.25.25 0 01.25-.25h3a.25.25 0 01.25.25v.931a2.246 2.246 0 01-1 1.872l-.609.405a.251.251 0 01-.278 0zM17.854 5.363a.249.249 0 00.211-.3l-.541-2.346A3.484 3.484 0 0014.113 0H9.887a3.484 3.484 0 00-3.411 2.713l-.541 2.346a.249.249 0 00.211.3 45.429 45.429 0 0011.708.004zm-5.742-.912a.252.252 0 01-.224 0 2.9 2.9 0 01-1.382-2.69.252.252 0 01.069-.184.25.25 0 01.18-.077h2.49a.25.25 0 01.18.077.252.252 0 01.069.184 2.9 2.9 0 01-1.382 2.69zM13.077 12.111h-2a.64.64 0 00-.307 1.2 2.355 2.355 0 00.438.182 3.017 3.017 0 00.869.127 2.977 2.977 0 00.866-.125 2.375 2.375 0 00.441-.183.64.64 0 00-.307-1.2z\"/><path d=\"M21.337 5.768a44.591 44.591 0 01-18.674 0 .777.777 0 00-.895.569.75.75 0 00.569.9c.883.2 1.788.354 2.7.492a.249.249 0 01.212.243c.023 1.365.162 5.233-2.4 6.057a.5.5 0 00-.231.8 3.8 3.8 0 002.921 1.12 4.156 4.156 0 002.539-.74.251.251 0 01.291-.014 6.734 6.734 0 007.228.024.251.251 0 01.294.019 4.27 4.27 0 002.572.762 3.8 3.8 0 002.921-1.12.5.5 0 00-.231-.8c-2.55-.819-2.434-4.927-2.406-6.118a.25.25 0 01.212-.242 42.15 42.15 0 002.7-.492.75.75 0 00.569-.9.781.781 0 00-.891-.56zM6.75 9.25c0-.451 0-.75 1.75-.75s1.75.3 1.75.75a1.75 1.75 0 01-3.5 0zm8.75-.75c1.75 0 1.75.3 1.75.75a1.75 1.75 0 01-3.5 0c0-.45 0-.75 1.75-.75zm-7.492 4.4a.251.251 0 01.217-.411 2.493 2.493 0 00.275.011 3.243 3.243 0 003.2-2.76.248.248 0 01.147-.191.363.363 0 01.312 0 .248.248 0 01.147.191 3.243 3.243 0 003.2 2.76 2.493 2.493 0 00.275-.015.251.251 0 01.217.411 5.225 5.225 0 01-7.984 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});