define("ember-svg-jar/inlined/smiley-rich", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-rich</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM5.625 12.75a1 1 0 010-2h2.033a.342.342 0 00.127-.659l-2.062-.825a2.342 2.342 0 01.221-4.425.251.251 0 00.181-.24V4.5a1 1 0 012 0 .25.25 0 00.25.25h.25a1 1 0 010 2H6.592a.342.342 0 00-.127.659l2.062.825a2.342 2.342 0 01-.221 4.425.251.251 0 00-.181.24V13a1 1 0 01-2 0 .25.25 0 00-.25-.25zm1.829 3.243a1 1 0 011.384.291 3.771 3.771 0 006.324 0 1 1 0 011.676 1.093 5.773 5.773 0 01-9.676 0 1 1 0 01.292-1.384zm9.852-3.334a.251.251 0 00-.181.24V13a1 1 0 01-2 0 .25.25 0 00-.25-.25h-.25a1 1 0 010-2h2.033a.342.342 0 00.127-.659l-2.062-.825a2.342 2.342 0 01.242-4.431.217.217 0 00.16-.21V4.5a1 1 0 012 0 .25.25 0 00.25.25h.25a1 1 0 010 2h-2.033a.342.342 0 00-.127.659l2.062.825a2.342 2.342 0 01-.221 4.425z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});