define("ember-svg-jar/inlined/squirrel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>squirrel</title><path d=\"M19.055 10.484A.75.75 0 0119.5 9.05a6.766 6.766 0 012.949 1.858 5.5 5.5 0 00-8.458-6.648 3.471 3.471 0 01-1.017 2.214 5.5 5.5 0 01-.113 1.7A10.6 10.6 0 0115 15a10.33 10.33 0 01-2.3 7H16a6.5 6.5 0 005.647-9.719 5.252 5.252 0 00-2.592-1.797z\"/><path d=\"M9.5 22c2.243 0 4-3.076 4-7 0-2.816-.93-5.3-2.353-6.4a3.967 3.967 0 00.253-2.531 2.937 2.937 0 00-.07-.254 2 2 0 10-2.642-2.64 3.886 3.886 0 00-.348-.091 4.01 4.01 0 00-3.44.884c-.066.056-.129.093-.122.1L1.436 4.5A.5.5 0 001 5v1.5a4.507 4.507 0 004 4.472v.053a6.644 6.644 0 00-3.707 1.767 1 1 0 000 1.415 1.014 1.014 0 001.414 0A4.785 4.785 0 015 13.041v1.266A3.982 3.982 0 003.038 20H2a1.011 1.011 0 00-1 1 1 1 0 001 1zM7.251 7.25a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});