define("ember-svg-jar/inlined/picture-polaroid-human", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>picture-polaroid-human</title><path d=\"M17 19a2 2 0 002-2V2a2 2 0 00-2-2H2a2 2 0 00-2 2v15a2 2 0 002 2zM2 2.5a.5.5 0 01.5-.5h14a.5.5 0 01.5.5v14a.5.5 0 01-.5.5h-14a.5.5 0 01-.5-.5z\"/><path d=\"M23.992 9.235a2 2 0 00-1.812-2.172l-1-.091a1 1 0 10-.179 1.992l.5.044a.5.5 0 01.453.544L20.863 21.5a.5.5 0 01-.543.452L6.877 20.728a1 1 0 10-.177 1.991l13.941 1.274a2 2 0 002.174-1.811z\"/><path d=\"M15.29 14.476c-.389-.778-2.013-1.317-4.051-2.073-.552-.2-.461-1.642-.217-1.911a4.066 4.066 0 001.059-3.154A2.482 2.482 0 009.5 4.63a2.482 2.482 0 00-2.581 2.708 4.066 4.066 0 001.059 3.154c.244.269.335 1.707-.217 1.911-2.038.756-3.662 1.3-4.051 2.073a4.832 4.832 0 00-.18.408.453.453 0 00.423.616h11.094a.453.453 0 00.423-.616 4.832 4.832 0 00-.18-.408z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});