define("ember-svg-jar/inlined/history-man-philosopher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-man-philosopher</title><circle cx=\"14\" cy=\"8.856\" r=\"1\"/><circle cx=\"10\" cy=\"8.856\" r=\"1\"/><path d=\"M0 23a1 1 0 001 1h22a1 1 0 001-1v-.615a2.958 2.958 0 00-1.163-2.373 15.99 15.99 0 00-7.55-2.809.25.25 0 01-.072-.475l.891-.411a4.55 4.55 0 002.635-4.39.121.121 0 010-.019c0-.167.009-1.792.01-3.506a.25.25 0 00-.2-.245q-.5-.105-1.008-.2a.25.25 0 00-.293.247v.743a.245.245 0 01-.07.175l-2.1 2.181-.018.021a1.149 1.149 0 01-1.276.391 5.686 5.686 0 00-3.578 0 1.2 1.2 0 01-1.336-.462l-.018-.02-2.041-2.115a.245.245 0 01-.07-.176c0-.159 0-.475.006-.732a.25.25 0 00-.294-.247c-.336.059-.672.125-1.006.194a.25.25 0 00-.2.245c0 1.466.01 3.192.012 3.449v.019a4.574 4.574 0 002.633 4.447l.891.411a.25.25 0 01-.073.475 15.669 15.669 0 00-7.548 2.809A2.956 2.956 0 000 22.385zm10.35-9.142a.75.75 0 01.727-.566h1.846a.75.75 0 01.358 1.409 2.3 2.3 0 01-.431.18 2.98 2.98 0 01-1.7 0 2.28 2.28 0 01-.431-.179.748.748 0 01-.369-.844zM12.075 22a.25.25 0 01-.112-.473l4.2-2.107a.249.249 0 01.161-.021 16.315 16.315 0 012.362.655.25.25 0 01.023.46l-3.035 1.461a.242.242 0 01-.109.025z\"/><path d=\"M5.353 7.711a4.066 4.066 0 00.2-.473.249.249 0 01.266-.161 1.467 1.467 0 00.443-.012 32.832 32.832 0 0111.474 0 1.48 1.48 0 00.264.023 1.515 1.515 0 001.5-1.5V2.062A1.5 1.5 0 0018.278.589a33.761 33.761 0 00-12.555 0A1.5 1.5 0 004.5 2.062v1.669a.25.25 0 01-.131.22A5.064 5.064 0 002.277 6.06l-.01.017L.746 8.514l-.011.018a6.645 6.645 0 00-.406 4.285.5.5 0 00.251.307c.4.205 2.273-.592 3.249-2.474l1.253-2.417z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});