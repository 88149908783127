define("ember-svg-jar/inlined/hierarchy-6", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hierarchy-6</title><path d=\"M24 16.5a1.5 1.5 0 00-1.5-1.5h-1a.25.25 0 01-.25-.25V13.5a1.752 1.752 0 00-1.75-1.75h-5a.25.25 0 01-.25-.25V9.249A.25.25 0 0114.5 9h2A1.5 1.5 0 0018 7.5v-6A1.5 1.5 0 0016.5 0h-7A1.5 1.5 0 008 1.5v6A1.5 1.5 0 009.5 9h3a.25.25 0 01.25.25v2.25a.25.25 0 01-.25.25h-8a1.752 1.752 0 00-1.75 1.75v1.25a.25.25 0 01-.25.25h-1A1.5 1.5 0 000 16.5v6A1.5 1.5 0 001.5 24h7a1.5 1.5 0 001.5-1.5v-6A1.5 1.5 0 008.5 15h-4a.25.25 0 01-.25-.25V13.5a.253.253 0 01.25-.25h15a.253.253 0 01.25.25v1.25a.25.25 0 01-.25.25h-4a1.5 1.5 0 00-1.5 1.5v6a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5zM11.5 4.249a.75.75 0 010-1.5h3a.75.75 0 110 1.5zm-8 13.5h3a.75.75 0 010 1.5h-3a.75.75 0 010-1.5zm17 0a.75.75 0 010 1.5h-3a.75.75 0 010-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});