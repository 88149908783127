define("ember-svg-jar/inlined/flying-insect-fly", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flying-insect-fly</title><path d=\"M21.388 9a1 1 0 10-.488-1.938l-1.088.272c-.625.156-1.394.361-2.746 2.051a19.907 19.907 0 011.258 1.63C19.667 9.336 19.418 9.5 21.388 9zM22.752 11.318H21.9a5.623 5.623 0 00-2.791.9c.188.3.861 1.479 1 1.739a3.649 3.649 0 012.645-.636 1 1 0 000-2zM11.957 15.4A9.949 9.949 0 019.3 20.554a3.048 3.048 0 002.658 1.407 3.045 3.045 0 002.659-1.409 9.959 9.959 0 01-2.66-5.152zM6.456 8.789C5.782 7.947 5.235 7.6 3.1 7.062A1 1 0 002.612 9c1.96.493 1.726.342 3.018 1.957a19.626 19.626 0 011.261-1.623zM4.822 12.183l-.84-.42a5.287 5.287 0 00-2.734-.445 1 1 0 100 2 3.568 3.568 0 012.577.6c.129-.246.803-1.418.997-1.735zM8.679 18.972a10.385 10.385 0 002.129-6.162 4.481 4.481 0 01-2.6-2.7 21.556 21.556 0 00-2.682 3.819c-2.024 3.536-2.678 7.5-1.607 8.035.614.299 2.205.114 4.76-2.992zM18.385 13.926a21.635 21.635 0 00-2.675-3.813 4.482 4.482 0 01-2.6 2.7 10.382 10.382 0 002.128 6.159c2.574 3.111 4.147 3.291 4.757 2.989 1.068-.535.415-4.499-1.61-8.035zM9.662 5.832a4.919 4.919 0 00-.379 1.924c0 2.071 1.2 3.749 2.678 3.749s2.679-1.678 2.679-3.749a4.918 4.918 0 00-.36-1.878 5.48 5.48 0 00.471-2.062 2.93 2.93 0 00-.288-1.328 1 1 0 00-1.789.894 1.377 1.377 0 01.048.778 1.956 1.956 0 00-1.485-.015 1.36 1.36 0 01.051-.763A1 1 0 109.5 2.488a2.909 2.909 0 00-.288 1.285 5.413 5.413 0 00.45 2.059z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});