define("ember-svg-jar/inlined/coupon-cut", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>coupon-cut</title><path d=\"M10.5 17.435H10a1 1 0 000 2h.5a1 1 0 000-2zM15 17.435h-1.5a1 1 0 000 2H15a1 1 0 100-2zM19.5 17.435H18a1 1 0 000 2h1.5a1 1 0 000-2zM23 17.435h-.5a1 1 0 000 2h.5a1 1 0 000-2z\"/><path d=\"M17.923 15.935a.254.254 0 00.207-.11.6.6 0 00.064-.12 1 1 0 00-.576-1.291l-5.724-2.192a.25.25 0 010-.466l11.469-4.479a1 1 0 00.054-1.84 11.021 11.021 0 00-10.872.64 14.039 14.039 0 00-3.618 3.432.25.25 0 01-.436-.067A12.559 12.559 0 017.739 5.3a3.873 3.873 0 10-1.9 3.333.25.25 0 01.369.153 16.588 16.588 0 001.149 3.089.25.25 0 010 .22A16.28 16.28 0 006.2 15.207a.252.252 0 01-.37.153 3.868 3.868 0 101.909 3.34 12.594 12.594 0 01.753-4.157.25.25 0 01.2-.165.253.253 0 01.238.1 15.156 15.156 0 001.148 1.384.25.25 0 00.182.079zM3.869 7.174A1.87 1.87 0 115.739 5.3a1.872 1.872 0 01-1.87 1.874zm0 13.391a1.87 1.87 0 111.87-1.87 1.871 1.871 0 01-1.87 1.87z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});