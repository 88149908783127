define("ember-svg-jar/inlined/credit-card-visa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Slice 1</title><g fill=\"none\" fill-rule=\"evenodd\"><rect fill=\"#0E4595\" width=\"750\" height=\"471\" rx=\"40\"/><path d=\"M278.197 334.228l33.361-195.763h53.36l-33.385 195.763h-53.336zM524.308 142.688c-10.572-3.966-27.137-8.222-47.823-8.222-52.725 0-89.865 26.55-90.18 64.603-.298 28.13 26.513 43.822 46.753 53.186 20.77 9.594 27.752 15.714 27.654 24.283-.132 13.121-16.587 19.116-31.923 19.116-21.357 0-32.703-2.966-50.226-10.276l-6.876-3.111-7.49 43.824c12.464 5.464 35.51 10.198 59.438 10.443 56.09 0 92.501-26.246 92.916-66.882.2-22.268-14.016-39.216-44.8-53.188-18.65-9.055-30.072-15.099-29.951-24.268 0-8.137 9.667-16.839 30.556-16.839 17.45-.27 30.089 3.535 39.937 7.5l4.781 2.26 7.234-42.43M661.615 138.465h-41.231c-12.774 0-22.332 3.487-27.942 16.234l-79.245 179.404h56.032s9.161-24.123 11.233-29.418c6.124 0 60.554.084 68.337.084 1.596 6.853 6.491 29.334 6.491 29.334h49.513l-43.188-195.638zm-65.418 126.407c4.413-11.279 21.26-54.723 21.26-54.723-.316.522 4.38-11.334 7.075-18.684l3.606 16.879s10.217 46.728 12.352 56.528h-44.293zM232.903 138.465l-52.24 133.496-5.567-27.13c-9.725-31.273-40.025-65.155-73.898-82.118l47.766 171.203 56.456-.065 84.004-195.386h-56.521\" fill=\"#FFF\"/><path d=\"M131.92 138.465H45.879l-.681 4.073c66.938 16.204 111.231 55.363 129.618 102.414l-18.71-89.96c-3.23-12.395-12.597-16.094-24.186-16.526\" fill=\"#F2AE14\"/></g>",
    "attrs": {
      "width": "750",
      "height": "471",
      "viewBox": "0 0 750 471",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});