define("ember-svg-jar/inlined/aircraft-propeller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>aircraft-propeller</title><path d=\"M21.913.5a2.073 2.073 0 00-1.769.981l-1.482 2.367a1.288 1.288 0 01-1.095.606h-.2a.25.25 0 01-.181-.079A10.1 10.1 0 009.558.76c-1.8 0-3.1 1.659-3.483 3.5a.248.248 0 01-.242.2H5.2A2.477 2.477 0 002.732 6.7a.248.248 0 01-.247.225H.99a.99.99 0 100 1.979h1.5a.249.249 0 01.247.225A2.477 2.477 0 005.2 11.381h4.57a29.637 29.637 0 0013.049-3.047A2.107 2.107 0 0024 6.433V2.582A2.089 2.089 0 0021.913.5zM8.2 4.347a.249.249 0 01-.028-.229c.3-.782.844-1.379 1.384-1.379A7.488 7.488 0 0113.753 4a.248.248 0 01-.136.455H8.4a.246.246 0 01-.2-.108zm6.4 4.56H9.649a.99.99 0 010-1.979H14.6a.99.99 0 110 1.979z\"/><path d=\"M.99 6.433a.989.989 0 00.989-.99V2.722a.99.99 0 10-1.979 0v2.721a.99.99 0 00.99.99zM1.979 13.113v-2.721a.99.99 0 10-1.979 0v2.721a.99.99 0 101.979 0zM11.617 17.814a.249.249 0 01-.209-.111 4.168 4.168 0 00-3.49-1.868 4.218 4.218 0 00-4 2.947.25.25 0 01-.164.162 3.221 3.221 0 00-2.272 3.077 1.485 1.485 0 001.484 1.484h11.385a1.486 1.486 0 001.484-1.484 4.2 4.2 0 00-4.218-4.207zm2.052 3.6a.248.248 0 01-.205.109H3.986a.223.223 0 01-.158-.382 1.226 1.226 0 01.874-.36h.01c.024 0 .1 0 .133-.007a.991.991 0 00.868-.906 2.212 2.212 0 014.275-.627 1 1 0 001.147.606 2.266 2.266 0 01.494-.054 2.229 2.229 0 012.064 1.391.249.249 0 01-.024.233z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});