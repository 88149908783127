define("ember-svg-jar/inlined/like-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>like-2</title><path d=\"M23.756 12.174a2.749 2.749 0 00-2.63-3.551h-5.58a1.249 1.249 0 01-1.127-1.788l1.428-2.993a2.425 2.425 0 00-.42-2.308 2.464 2.464 0 00-3.926.132l-4.89 6.928a1.253 1.253 0 01-1.021.529.464.464 0 00-.464.464V20.9a.5.5 0 00.282.45c3.7 1.792 4.929 2.029 7.942 2.029.347 0 2.32-.02 3.041-.02 2.68 0 4.463-1.555 5.456-4.779l1.9-6.369zM3.626 9.123H3.6a1.5 1.5 0 00-1.474-1.25h-1.5a.5.5 0 00-.5.5v14a.5.5 0 00.5.5h1.5a1.5 1.5 0 001.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});