define("ember-svg-jar/inlined/data-file-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-settings</title><path d=\"M21 .617a2 2 0 00-1.414-.586H8a2 2 0 00-2 2v6c0 .344.082.5.25.5a3.229 3.229 0 011.382.312A.251.251 0 008 8.625V2.531a.5.5 0 01.5-.5h10.88a.5.5 0 01.353.147L21.854 4.3a.5.5 0 01.146.352v13.379a.5.5 0 01-.5.5h-7.36a.25.25 0 00-.237.316 3.228 3.228 0 01.022 1.377.249.249 0 00.239.306H22a2 2 0 002-2V4.445a2 2 0 00-.586-1.414z\"/><path d=\"M12.4 11.6a.252.252 0 00.319-.083L13.937 9.8a.249.249 0 01.332-.07l1.507.9A1 1 0 0017 10.482a.9.9 0 00.105-.123l2.685-3.734a1 1 0 10-1.624-1.168l-2.015 2.8a.251.251 0 01-.332.068L14.3 7.418a1 1 0 00-1.33.28l-2.126 3a.248.248 0 00.126.382 9.337 9.337 0 011.43.52z\"/><circle cx=\"6.251\" cy=\"17\" r=\"1.5\"/><path d=\"M8.908 13a.449.449 0 01-.534-.31l-.424-1.397a1.775 1.775 0 00-3.4 0l-.424 1.393A.457.457 0 013.6 13l-1.416-.33a1.782 1.782 0 00-1.7 2.953l.988 1.065a.461.461 0 010 .624l-.989 1.065a1.782 1.782 0 001.7 2.953L3.594 21a.452.452 0 01.534.31l.424 1.393a1.775 1.775 0 003.4 0l.424-1.393a.45.45 0 01.531-.31l1.411.326a1.782 1.782 0 001.7-2.953l-.988-1.065a.461.461 0 010-.624l.988-1.065a1.782 1.782 0 00-1.7-2.953zm-2.657 7a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});