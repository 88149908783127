define("ember-svg-jar/inlined/car-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-insurance</title><path d=\"M19.419 10.365l.846-.508a1 1 0 00-1.03-1.714l-.854.512a.249.249 0 01-.238.011.252.252 0 01-.143-.194l-.152-1.22A2 2 0 0015.867 5.5H8.133a2 2 0 00-1.985 1.752L6 8.472a.252.252 0 01-.139.194.249.249 0 01-.238-.011l-.854-.512a1 1 0 10-1.03 1.714l.846.508a.248.248 0 01.118.173.25.25 0 01-.055.2A2.726 2.726 0 004 12.5v1.25a1.752 1.752 0 001.75 1.75.25.25 0 01.25.25v1.5a1.75 1.75 0 003.5 0v-1.5a.25.25 0 01.25-.25h5a.25.25 0 01.25.25v1.5a1.75 1.75 0 003.5 0v-1.571a.25.25 0 01.188-.242A1.746 1.746 0 0020 13.75V12.5a2.726 2.726 0 00-.644-1.76.25.25 0 01-.055-.2.248.248 0 01.118-.175zm-3.366-.7a.251.251 0 01-.188.085h-7.73a.25.25 0 01-.248-.281l.218-1.75a.251.251 0 01.249-.219h7.292a.251.251 0 01.249.219l.218 1.75a.249.249 0 01-.06.196z\"/><path d=\"M22 .25h-5.5a1 1 0 000 2h5.25a.25.25 0 01.25.25v12.25a7.008 7.008 0 01-7 7H9a7.008 7.008 0 01-7-7V2.5a.25.25 0 01.25-.25H7.5a1 1 0 000-2H2a2 2 0 00-2 2v12.5a9.01 9.01 0 009 9h6a9.01 9.01 0 009-9V2.25a2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});