define("ember-svg-jar/inlined/meeting-team-monitor-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-team-monitor-woman</title><circle cx=\"14.58\" cy=\"4.749\" r=\"2\"/><path d=\"M11.286 10.066a.5.5 0 00.471.683H17.4a.5.5 0 00.47-.683 3.5 3.5 0 00-6.588 0z\"/><path d=\"M22.25 0h-16A1.753 1.753 0 004.5 1.749v4.5a.75.75 0 001.5 0v-4.5a.253.253 0 01.25-.25h16a.253.253 0 01.25.25v12a.253.253 0 01-.25.25h-8.5a.75.75 0 00-.375 1.4.252.252 0 01.125.217v2.134a.75.75 0 00.75.75h3.5a.75.75 0 000-1.5h-2.5a.249.249 0 01-.25-.25v-1a.25.25 0 01.25-.25h7A1.752 1.752 0 0024 13.749v-12A1.753 1.753 0 0022.25 0z\"/><path d=\"M12.716 24a.278.278 0 00.21-.1.313.313 0 00.073-.23 6.4 6.4 0 00-.549-2.107c-.4-.846-2.068-1.432-4.163-2.254a.6.6 0 01-.37-.563v-.3a.246.246 0 01.074-.177.251.251 0 01.178-.073 4.311 4.311 0 002.662-.7.3.3 0 00.132-.213.319.319 0 00-.071-.244c-.357-.414-.6-.914-.709-2.558C9.994 11.538 8.115 10.5 6.5 10.5s-3.494 1.039-3.683 3.99c-.1 1.644-.352 2.144-.709 2.558a.319.319 0 00-.071.244.3.3 0 00.132.213 4.311 4.311 0 002.662.7.251.251 0 01.178.073.246.246 0 01.074.177v.3a.6.6 0 01-.37.563c-2.1.822-3.764 1.408-4.163 2.254a6.4 6.4 0 00-.55 2.1.313.313 0 00.073.23.278.278 0 00.21.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});