define("ember-svg-jar/inlined/road-sign-side-road-left-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-side-road-left-1</title><path d=\"M23.53 11.47l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0l11-11a.749.749 0 000-1.06zm-8.837-1.183a.751.751 0 01-.693.463h-1a.25.25 0 00-.25.25v6a.75.75 0 01-1.5 0v-2.586a.25.25 0 00-.427-.177L9.53 15.53a.75.75 0 01-1.06-1.06l2.707-2.707a.251.251 0 00.073-.177V11a.25.25 0 00-.25-.25h-1a.75.75 0 01-.53-1.28l2-2a.751.751 0 011.06 0l2 2a.749.749 0 01.163.817z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});