define("ember-svg-jar/inlined/smiley-tongue-3-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-tongue-3-alternate</title><path d=\"M12 0a12 12 0 00-7.594 21.292 1 1 0 001.266-1.548 10 10 0 1112.627.024 1 1 0 101.26 1.552A12 12 0 0012 0z\"/><path d=\"M17.2 14.5H6.8a.5.5 0 00-.432.75 6.528 6.528 0 001.537 1.8.25.25 0 01.093.2V20a4 4 0 008 0v-2.756a.25.25 0 01.093-.2 6.528 6.528 0 001.537-1.8.5.5 0 00-.433-.75zM12 22a2 2 0 01-2-2v-2.45a.252.252 0 01.138-.224A4.2 4.2 0 0112 17a4.424 4.424 0 011.855.311.251.251 0 01.145.227V20a2 2 0 01-2 2z\"/><circle cx=\"8\" cy=\"9\" r=\"2\"/><circle cx=\"16\" cy=\"9\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});