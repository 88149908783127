define("ember-svg-jar/inlined/style-three-pin-paint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-paint</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm2.71 16.11a1 1 0 01-.994.89h-3.432a1 1 0 01-.994-.89l-.728-6.555A.5.5 0 019.059 9h2.478a.5.5 0 01.432.25 3.507 3.507 0 002.5 1.71.032.032 0 01.027.031V11a2.456 2.456 0 00.484 1.458.5.5 0 01.093.35zM18 11a1 1 0 01-2 0v-1a.5.5 0 00-.5-.5H15a2 2 0 01-1.96-1.6.5.5 0 00-.49-.4H8a1 1 0 010-2h7a3 3 0 013 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});