define("ember-svg-jar/inlined/stove-induction-pan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>stove-induction-pan</title><path d=\"M23 3.125H1a1 1 0 000 2h6.5a.5.5 0 01.5.5v2.5a2 2 0 002 2h12a2 2 0 002-2v-4a1 1 0 00-1-1zM8.577 15.614a6.487 6.487 0 00-1.327 3.011 2.176 2.176 0 002.25 2.25 2.176 2.176 0 002.25-2.25 6.479 6.479 0 00-1.326-3.01 1.134 1.134 0 00-1.847-.001zM14.456 13.95c-1.2 1.819-1.815 3.187-1.815 4.066a2.736 2.736 0 002.859 2.859 2.736 2.736 0 002.859-2.859c0-.879-.61-2.247-1.815-4.066a1.279 1.279 0 00-2.088 0zM20.577 15.614a6.487 6.487 0 00-1.327 3.011 2.25 2.25 0 004.5 0 6.479 6.479 0 00-1.326-3.01 1.134 1.134 0 00-1.847-.001z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});