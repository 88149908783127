define("ember-svg-jar/inlined/library-music", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>library-music</title><path d=\"M17.25 0A6.75 6.75 0 1024 6.751 6.758 6.758 0 0017.25 0zm0 12a5.25 5.25 0 115.25-5.25A5.256 5.256 0 0117.25 12z\"/><path d=\"M17.969 3.416A.75.75 0 0016.75 4v2.5a.25.25 0 01-.25.25 1.75 1.75 0 101.75 1.75V6.082a.25.25 0 01.406-.2l.875.7a.75.75 0 10.938-1.171zM14.5 15.251H2a.75.75 0 110-1.5h.5a.5.5 0 00.5-.5V8.5a.5.5 0 01.5-.5H5a.5.5 0 01.5.5v4.75a.5.5 0 00.5.5h4.938a.249.249 0 00.17-.432 8.951 8.951 0 01-2.778-7.75A.5.5 0 007.833 5H1.5A1.5 1.5 0 000 6.5v16A1.5 1.5 0 001.5 24h12a1.5 1.5 0 001.5-1.5v-6.75a.5.5 0 00-.5-.499zM5 22a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5zm4.828.276l-1.428.469a.5.5 0 01-.631-.319l-1.247-3.8a.5.5 0 01.319-.631l1.426-.468a.5.5 0 01.631.32l1.246 3.8a.5.5 0 01-.316.63z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});