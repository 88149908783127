define("ember-svg-jar/inlined/mobile-shopping-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mobile-shopping-cart</title><path d=\"M21.256 4.3a.751.751 0 00-.961.449l-.306.841a.25.25 0 01-.235.165H14a1.242 1.242 0 00-1.043.562 1.228 1.228 0 00-.126 1.131c.008.018 1.086 3.036 1.094 3.055a1.247 1.247 0 001.146.752h3.578a1.256 1.256 0 001.176-.823l1.88-5.171a.751.751 0 00-.449-.961z\"/><circle cx=\"15.5\" cy=\"13\" r=\"1\"/><circle cx=\"18.75\" cy=\"13\" r=\"1\"/><path d=\"M15 15.25a.75.75 0 00-.75.75v2.5a.25.25 0 01-.25.25H4.75a1 1 0 01-1-1V5.25a1 1 0 011-1H15a.75.75 0 00.75-.75V3A2.75 2.75 0 0013 .25H5A2.75 2.75 0 002.25 3v18A2.75 2.75 0 005 23.75h8A2.75 2.75 0 0015.75 21v-5a.75.75 0 00-.75-.75zM10 21a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});