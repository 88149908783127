define("ember-svg-jar/inlined/meeting-smartphone-hold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-smartphone-hold</title><circle cx=\"9.045\" cy=\"7.124\" r=\"1.625\"/><path d=\"M11.722 11.444a2.92 2.92 0 00-2.677-1.882 2.92 2.92 0 00-2.676 1.882.408.408 0 00.382.556h4.589a.408.408 0 00.382-.556z\"/><path d=\"M13.84 21.889v-1.64a.25.25 0 00-.25-.25H4.34a.5.5 0 01-.5-.5V3.5a.5.5 0 01.5-.5h9a.5.5 0 01.5.5v8.1a.249.249 0 00.093.195l1.5 1.2a.25.25 0 00.407-.195V2.5v-.051A2.5 2.5 0 0013.34 0h-9a2.5 2.5 0 00-2.5 2.449V21.552A2.5 2.5 0 004.34 24h9a2.489 2.489 0 00.771-.122.249.249 0 00.149-.343 4.033 4.033 0 01-.42-1.646z\"/><path d=\"M18.363 15.346a.75.75 0 01-.469 1.336.741.741 0 01-.468-.165l-4.1-3.289a1.375 1.375 0 00-1.579.262 1.373 1.373 0 000 1.944l3.666 4.142a.249.249 0 01.063.166v2.545a2.634 2.634 0 00.306 1.071.252.252 0 00.218.142h5.91a.25.25 0 00.207-.391L20.679 21a.253.253 0 01-.044-.141V15.03c0-1.48-1.453-2.922-2.888-4.078a.25.25 0 00-.407.2v3.259a.25.25 0 00.093.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});