define("ember-svg-jar/inlined/library-art", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>library-art</title><path d=\"M13.96 8.292a.75.75 0 00.01-1.061 3.09 3.09 0 01-.945-2.186c0-1.954 1.975-3.544 4.4-3.544a.75.75 0 100-1.5c-3.255 0-5.9 2.263-5.9 5.044A4.631 4.631 0 0012.9 8.282a.75.75 0 001.06.01zM22.609 5.568a.749.749 0 00-.951.47 4.338 4.338 0 01-.673 1.228.429.429 0 01-.645.043.75.75 0 10-1.061 1.061l.861.861a.749.749 0 001.033.026 6.6 6.6 0 001.905-2.738.75.75 0 00-.469-.951z\"/><circle cx=\"14.93\" cy=\"5.944\" r=\".75\"/><circle cx=\"15.68\" cy=\"3.444\" r=\".75\"/><path d=\"M15.381 11.705a1.967 1.967 0 01-1.793-1.254.5.5 0 00-.207-.245 6.119 6.119 0 01-3.088-4.742A.5.5 0 009.794 5H2.382a1.5 1.5 0 00-1.5 1.5v16a1.5 1.5 0 001.5 1.5h12a1.5 1.5 0 001.5-1.5V12.2a.5.5 0 00-.5-.5zM5.882 22a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5zm4.828.276l-1.425.468a.5.5 0 01-.631-.319l-1.247-3.8a.5.5 0 01.319-.631l1.425-.468a.5.5 0 01.631.32l1.247 3.8a.5.5 0 01-.319.631zm3.172-7.026h-11a.75.75 0 110-1.5h.5a.5.5 0 00.5-.5V8.5a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5v4.75a.5.5 0 00.5.5h.5a.5.5 0 00.5-.5V10.5a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5v2.75a.5.5 0 00.5.5h3a.75.75 0 010 1.5zM19.777 5.19a2.221 2.221 0 001.323-.631 2.836 2.836 0 00.782-2.046A3.371 3.371 0 0021.1.151a.5.5 0 00-.842.233 1.738 1.738 0 01-1.068 1.329 3.488 3.488 0 00-.9.649 1.587 1.587 0 00-.206 1.848.5.5 0 01-.037.519l-3.221 4.547a.749.749 0 00.179 1.046.762.762 0 00.318.129.75.75 0 00.727-.308L19.412 5.4a.5.5 0 01.365-.21z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});