define("ember-svg-jar/inlined/social-media-nexopia", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-nexopia</title><path d=\"M14.49.5H.5A.5.5 0 000 1v22a.5.5 0 00.5.5h23a.5.5 0 00.5-.5V10A9.52 9.52 0 0014.49.5zm-4.56 19a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5v-15A.5.5 0 014 4h5.43a.5.5 0 01.5.5zm10.57 0a.5.5 0 01-.5.5h-6a.5.5 0 01-.5-.5v-15A.5.5 0 0114 4a6.64 6.64 0 016.47 6.35z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});