define("ember-svg-jar/inlined/ambulance-cross", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ambulance-cross</title><path d=\"M18.767.125a1 1 0 00-1 1v.941a.25.25 0 01-.25.25H6.483a.25.25 0 01-.25-.25v-.941a1 1 0 00-2 0v21.75a1 1 0 002 0v-.941a.25.25 0 01.25-.25h11.034a.25.25 0 01.25.25v.941a1 1 0 002 0V1.125a1 1 0 00-1-1zm-1.934 12.842a.483.483 0 01-.483.483H13.7a.25.25 0 00-.25.25v2.65a.483.483 0 01-.483.483h-1.934a.483.483 0 01-.483-.483V13.7a.25.25 0 00-.25-.25H7.65a.483.483 0 01-.483-.483v-1.934a.483.483 0 01.483-.483h2.65a.25.25 0 00.25-.25V7.65a.483.483 0 01.483-.483h1.934a.483.483 0 01.483.483v2.65a.25.25 0 00.25.25h2.65a.483.483 0 01.483.483z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});