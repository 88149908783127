define("ember-svg-jar/inlined/road-straight-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-straight-1</title><path d=\"M20.75.75A.75.75 0 0020 0H4a.75.75 0 00-.75.75v22.5A.75.75 0 004 24h16a.75.75 0 00.75-.75zM11 21.5v-3a1 1 0 012 0v3a1 1 0 01-2 0zm0-8v-3a1 1 0 012 0v3a1 1 0 01-2 0zm2-11v3a1 1 0 01-2 0v-3a1 1 0 012 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});