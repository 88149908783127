define("ember-svg-jar/inlined/multiple-actions-information.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-information</title><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-.5 2.25a1 1 0 11-1 1 1 1 0 011-1zm2 8.5h-3a.75.75 0 010-1.5h.5a.25.25 0 00.25-.25v-2a.249.249 0 00-.25-.25H16a.75.75 0 010-1.5h1a1.252 1.252 0 011.25 1.25V20a.25.25 0 00.25.25h.5a.75.75 0 010 1.5zM12.023 8.534a.5.5 0 00-.182.771 7.473 7.473 0 01.787 1.187.5.5 0 00.7.19A7.93 7.93 0 0117.5 9.5a.32.32 0 00.169-.593 6 6 0 00-5.646-.373z\"/><ellipse cx=\"14.013\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M11.563 11.733A6.026 6.026 0 000 13.333.6.6 0 00.6 14h9.411a.5.5 0 00.441-.264 7.962 7.962 0 011.024-1.484.5.5 0 00.087-.519z\"/><ellipse cx=\"5.513\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});