define("ember-svg-jar/inlined/style-three-pin-target-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-target-arrow</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm5.03 14.47a.75.75 0 11-1.06 1.06l-1.04-1.039a.5.5 0 00-.59-.087 4.892 4.892 0 01-4.68 0 .5.5 0 00-.59.087L8.03 15.53a.75.75 0 01-1.06-1.06l.93-.93a.5.5 0 00.051-.646A4.912 4.912 0 017 10a5.006 5.006 0 015-5 1 1 0 010 2 3 3 0 103 3 1 1 0 012 0 4.912 4.912 0 01-.951 2.894.5.5 0 00.051.646zm1.88-8.911l-1.58 1.579a1.235 1.235 0 01-.873.362h-.689a.5.5 0 00-.354.146L12.53 10.53a.75.75 0 01-1.06-1.06l2.884-2.884a.5.5 0 00.146-.354v-.689a1.238 1.238 0 01.361-.873l1.58-1.58a.309.309 0 01.527.219v1.223a.5.5 0 00.5.5h1.223a.309.309 0 01.219.527z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});