define("ember-svg-jar/inlined/religion-islam-hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-islam-hand</title><path d=\"M10.281 9.005l-.26 1.511a.6.6 0 00.869.631l1.357-.714 1.359.714a.6.6 0 00.868-.63l-.259-1.512 1.1-1.071a.6.6 0 00-.331-1.021l-1.519-.221-.679-1.376a.6.6 0 00-1.073 0l-.679 1.376-1.518.221a.6.6 0 00-.332 1.021z\"/><path d=\"M23.842 6.606A8.2 8.2 0 0017.214.125a8.289 8.289 0 00-5.139.739.8.8 0 00.431 1.51A5.918 5.918 0 0118.867 7.2a5.908 5.908 0 01-5.812 6.954.917.917 0 00-.337 1.77 8.254 8.254 0 009.4-2.426 8.3 8.3 0 001.724-6.892zM15.5 19.5H6.376a.625.625 0 010-1.25H8.9a1 1 0 00.919-1.394A2.236 2.236 0 007.765 15.5H3.5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.765a2.238 2.238 0 002.235-2.236V20.5a1 1 0 00-1-1zM1 14.75a1 1 0 00-1 1V23a1 1 0 002 0v-7.25a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});