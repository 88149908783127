define("ember-svg-jar/inlined/monetization-sponsor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monetization-sponsor</title><path d=\"M24 5.5A2.5 2.5 0 0021.5 3h-1.75a.249.249 0 01-.25-.25V2a2 2 0 00-2-2h-4a2 2 0 00-2 2v.75a.249.249 0 01-.25.25H9.5A2.5 2.5 0 007 5.5v7A2.5 2.5 0 009.5 15h12a2.5 2.5 0 002.5-2.5zM13.5 2.25a.25.25 0 01.25-.25h3.5a.25.25 0 01.25.25v.5a.249.249 0 01-.25.25h-3.5a.249.249 0 01-.25-.25zm.75 6h2.5a2 2 0 010 4h-.25a.25.25 0 00-.25.25v.25a.75.75 0 01-1.5 0v-.25a.25.25 0 00-.25-.25H13a.75.75 0 010-1.5h3.75a.5.5 0 000-1h-2.5a2 2 0 010-4h.25a.249.249 0 00.25-.25v-.25a.75.75 0 011.5 0v.25a.249.249 0 00.25.25H18a.75.75 0 010 1.5h-3.75a.5.5 0 000 1zM2 23v-7.25a1 1 0 00-2 0V23a1 1 0 002 0zM6.375 18.25H8.9a1 1 0 00.92-1.393A2.234 2.234 0 007.764 15.5H3.5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.764a2.238 2.238 0 002.236-2.236V20.5a1 1 0 00-1-1H6.375a.625.625 0 010-1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});