define("ember-svg-jar/inlined/smiley-kiss-heart-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-kiss-heart-alternate</title><path d=\"M14.273 21.74a9.988 9.988 0 117.21-6.557 1 1 0 001.9.634 11.993 11.993 0 10-8.654 7.871 1 1 0 00-.453-1.948z\"/><circle cx=\"8\" cy=\"9\" r=\"2\"/><path d=\"M17.167 9.9a1 1 0 00.917.6.98.98 0 00.4-.084A1 1 0 0019 9.1a3.5 3.5 0 00-6.415 0 1 1 0 101.832.8 1.5 1.5 0 012.751 0zM13 11.5h-2a1 1 0 000 2h2a.5.5 0 010 1 1 1 0 000 2 .5.5 0 010 1h-2a1 1 0 000 2h2a2.5 2.5 0 002-4 2.5 2.5 0 00-2-4zM23.959 19.347a2.264 2.264 0 00-3.332-1.547l-.166.093-.092-.165a2.265 2.265 0 00-3.956 2.206l2.029 3.8a.5.5 0 00.3.246.491.491 0 00.136.019.5.5 0 00.254-.069l3.706-2.183a2.261 2.261 0 001.121-2.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});