define("ember-svg-jar/inlined/monitor-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-shield</title><path d=\"M24 2.59A2.548 2.548 0 0021.5 0h-19A2.548 2.548 0 000 2.59V16.5a3 3 0 003 3h7.25a.25.25 0 01.25.25v2a.25.25 0 01-.25.25H5a1 1 0 000 2h14a1 1 0 000-2h-6.25a.25.25 0 01-.25-.25v-2a.25.25 0 01.25-.25H21a3 3 0 003-3zM2.5 15a.5.5 0 01-.5-.5V2.59A.551.551 0 012.5 2h19a.551.551 0 01.5.589V14.5a.5.5 0 01-.5.5z\"/><path d=\"M15.511 5.375a10.921 10.921 0 00-3.8-.708 10.81 10.81 0 00-3.764.708 1.194 1.194 0 00-.721 1.1v2.258a5.311 5.311 0 003.574 4.692l.369.142a1.545 1.545 0 001.1 0l.367-.141a5.331 5.331 0 003.6-4.692V6.471a1.2 1.2 0 00-.725-1.096zM13.98 9.168a.75.75 0 01-.75.75h-.5a.249.249 0 00-.25.25v.5a.75.75 0 01-1.5 0v-.5a.249.249 0 00-.25-.25h-.5a.75.75 0 010-1.5h.5a.25.25 0 00.25-.25v-.5a.75.75 0 111.5 0v.5a.25.25 0 00.25.25h.5a.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});