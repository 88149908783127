define("ember-svg-jar/inlined/audio-book-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-book-file</title><path d=\"M6 20H2.5a.5.5 0 01-.5-.5v-17a.5.5 0 01.5-.5h8.672a1 1 0 01.707.293l2.828 2.829A1 1 0 0115 5.83v4.131a1 1 0 002 0V5.415A1.986 1.986 0 0016.414 4L13 .587A2.015 2.015 0 0011.586 0H2a2 2 0 00-2 2v18a2 2 0 002 2h4a1 1 0 000-2z\"/><circle cx=\"16.25\" cy=\"20.501\" r=\"3.5\"/><path d=\"M22.285 18.7a.5.5 0 01-.31-.423 5.744 5.744 0 00-11.45 0 .5.5 0 01-.31.423A2.75 2.75 0 0011.25 24a.75.75 0 00.75-.75v-4.5a4.25 4.25 0 118.5 0v4.5a.75.75 0 00.75.75 2.75 2.75 0 001.035-5.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});