define("ember-svg-jar/inlined/lama", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lama</title><path d=\"M17.367 10h-4a.5.5 0 01-.5-.5v-1a9.828 9.828 0 00-2.005-6.158A2.735 2.735 0 009.025.025.5.5 0 008.367.5v1.506c-1.593.043-3.212.344-3.451 1.661a.984.984 0 00-.227.108L1.653 6.052a.991.991 0 00-.348.483 3.13 3.13 0 00.273 2.622 2.525 2.525 0 002.193.971h1.6a.5.5 0 01.5.51V11c0 2.7.545 4.845 1.5 5.946.006 1.111.1 3.53 1.5 4.305V22a1 1 0 000 2h1a1 1 0 001-1v-1.692A2.585 2.585 0 0012.3 19.17a.5.5 0 01.493-.42h2.651a.5.5 0 01.493.42 2.586 2.586 0 001.431 2.138V22a1 1 0 000 2h1a1 1 0 001-1v-1.716c1.279-.568 1.472-2.2 1.5-3.148.863-.321 2-1.333 2-3.636-.001-2.986-1.851-4.5-5.501-4.5zM3.771 8.128c-.56 0-.7-.11-.624-.7L5.7 5.519a2.742 2.742 0 00.951.4 12.175 12.175 0 00-.554 2.206z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});