define("ember-svg-jar/inlined/insurance-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>insurance-card</title><path d=\"M9.75 12h-1a.25.25 0 01-.25-.25v-1a.751.751 0 00-.75-.75h-1.5a.751.751 0 00-.75.75v1a.25.25 0 01-.25.25h-1a.751.751 0 00-.75.75v1.5a.751.751 0 00.75.75h1a.25.25 0 01.25.25v1a.751.751 0 00.75.75h1.5a.751.751 0 00.75-.75v-1a.25.25 0 01.25-.25h1a.751.751 0 00.75-.75v-1.5a.751.751 0 00-.75-.75z\"/><path d=\"M24 4.5a2 2 0 00-2-2H2a2 2 0 00-2 2v15a2 2 0 002 2h20a2 2 0 002-2zm-21.5 15A.5.5 0 012 19V8.5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5V19a.5.5 0 01-.5.5z\"/><path d=\"M19.25 11.75H14.5a.75.75 0 000 1.5h4.75a.75.75 0 000-1.5zM18 14.75h-3.5a.75.75 0 000 1.5H18a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});