define("ember-svg-jar/inlined/layout-content-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layout-content-alternate</title><path d=\"M8 0H3a3 3 0 00-3 3v5a3 3 0 003 3h5a3 3 0 003-3V3a3 3 0 00-3-3zm1 8a1 1 0 01-1 1H3a1 1 0 01-1-1V3a1 1 0 011-1h5a1 1 0 011 1zM8 13H3a3 3 0 00-3 3v5a3 3 0 003 3h5a3 3 0 003-3v-5a3 3 0 00-3-3zm1 8a1 1 0 01-1 1H3a1 1 0 01-1-1v-5a1 1 0 011-1h5a1 1 0 011 1zM14.246 3h9a.75.75 0 000-1.5h-9a.75.75 0 100 1.5zM23.249 4.5h-9a.75.75 0 000 1.5h9a.75.75 0 000-1.5zM23.249 7.5h-9a.75.75 0 000 1.5h9a.75.75 0 100-1.5zM23.249 14.5h-9a.75.75 0 000 1.5h9a.75.75 0 000-1.5zM23.249 17.5h-9a.75.75 0 100 1.5h9a.75.75 0 000-1.5zM23.249 20.5h-9a.75.75 0 100 1.5h9a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});