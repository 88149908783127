define("ember-svg-jar/inlined/reptile-crocodile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>reptile-crocodile</title><path d=\"M24 5.025a.75.75 0 10-1.5 0v.751h-5.051a2.5 2.5 0 00-4.9 0h-.343l-.854-.853a.5.5 0 00-.707 0L10 5.569l-.647-.646a.5.5 0 00-.707 0l-.853.853H7a7.008 7.008 0 00-7 7c0 4.392 2.859 5.883 6.6 7.375A1 1 0 007.656 18.5C5.783 16.716 5 15.805 5 14.776a2 2 0 012-2 .5.5 0 01.5.5v1.749a1 1 0 001 1H10a1 1 0 000-2h-.5v-.749a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v1.749a1 1 0 001 1H15a1 1 0 000-2h-.5v-.749a.5.5 0 01.5-.5h7.32a.5.5 0 00.5-.5 1.5 1.5 0 00-1.5-1.5H17.5a1.1 1.1 0 01-1-1 1.1 1.1 0 011-1h2.382l.894.448a.5.5 0 00.447 0l.815-.408 1.3.435A.5.5 0 0024 8.776V5.025zm-10 1.25a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});