define("ember-svg-jar/inlined/buildings-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>buildings-1</title><path d=\"M1.5 5.5h6A.5.5 0 008 5V4a1 1 0 00-1-1H5.25a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v1.5a.5.5 0 01-.5.5H2a1 1 0 00-1 1v1a.5.5 0 00.5.5zM17.5 8h5a.5.5 0 00.5-.5V7a1 1 0 00-1-1h-4a1 1 0 00-1 1v.5a.5.5 0 00.5.5zM8 7H1a1 1 0 00-1 1v15a1 1 0 001 1h1.5a.5.5 0 00.5-.5v-2a1 1 0 011-1h1a1 1 0 011 1v2a.5.5 0 00.5.5H8a1 1 0 001-1V8a1 1 0 00-1-1zM6.75 17.75h-4.5a.75.75 0 010-1.5h4.5a.75.75 0 010 1.5zm0-3.5h-4.5a.75.75 0 010-1.5h4.5a.75.75 0 010 1.5zm0-3.5h-4.5a.75.75 0 010-1.5h4.5a.75.75 0 010 1.5zM23 9.5h-6a1 1 0 00-1 1V23a1 1 0 001 1h1a.5.5 0 00.5-.5v-2a1 1 0 011-1h1a1 1 0 011 1v2a.5.5 0 00.5.5h1a1 1 0 001-1V10.5a1 1 0 00-1-1zm-1.25 8.25h-3.5a.75.75 0 010-1.5h3.5a.75.75 0 010 1.5zm0-4h-3.5a.75.75 0 010-1.5h3.5a.75.75 0 010 1.5z\"/><rect x=\"10.5\" y=\"14\" width=\"4\" height=\"10\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});