define("ember-svg-jar/inlined/compass-west", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>compass-west</title><path d=\"M8.958 7.41a.455.455 0 00-.644-.582l-8.09 4.78a.455.455 0 000 .784l8.09 4.78a.455.455 0 00.644-.582l-.358-.777a9.1 9.1 0 010-7.626zM16.75 4.75A7.25 7.25 0 1024 12a7.258 7.258 0 00-7.25-7.25zm2.992 11.106a.75.75 0 01-.643.638.847.847 0 01-.1.006.749.749 0 01-.689-.455l-1.08-2.521a.25.25 0 00-.46 0l-1.081 2.521a.749.749 0 01-1.431-.189l-1-7a.75.75 0 011.484-.212l.5 3.47a.25.25 0 00.477.063l.6-1.389a.78.78 0 011.378 0l.6 1.389a.25.25 0 00.477-.063l.5-3.47a.75.75 0 111.484.212z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});