define("ember-svg-jar/inlined/plane-trip-land-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-trip-land-time</title><path d=\"M17.681 14.176a.5.5 0 00-.49-.6.529.529 0 00-.152.023l-1.1.351a.5.5 0 00-.336.365l-.623 2.715a.25.25 0 00.342.286l1.771-.76a.249.249 0 00.146-.181z\"/><path d=\"M23.483 12.9a.527.527 0 00-.13.017l-1.2.322a.5.5 0 00-.337.3l-.909 2.38a.248.248 0 01-.134.141l-9.826 4.219a1.278 1.278 0 00.967 2.366l3.362-1.306a.5.5 0 01.321-.014l5.229 1.53a.5.5 0 00.291 0l1.039-.328a.5.5 0 00.052-.934l-3.169-1.407a.25.25 0 010-.458l3.721-1.617a.5.5 0 00.29-.353l.916-4.255a.5.5 0 00-.489-.605zM6.507 14.1A6.49 6.49 0 10.016 7.613 6.5 6.5 0 006.507 14.1zm0-10.98a4.49 4.49 0 11-4.491 4.49 4.5 4.5 0 014.491-4.487z\"/><path d=\"M6.507 8.363H9a.75.75 0 100-1.5H7.5a.25.25 0 01-.25-.25V4.834a.75.75 0 00-1.5 0v2.779a.75.75 0 00.757.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});