define("ember-svg-jar/inlined/water-bottle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-bottle</title><path d=\"M18.722 10.25a.5.5 0 00.5-.439 4.225 4.225 0 00-.8-3.087 6.782 6.782 0 00-3.723-2.458.484.484 0 00-.126-.016H9.5a.489.489 0 00-.126.016 6.981 6.981 0 00-3.79 2.458 4.225 4.225 0 00-.8 3.087.5.5 0 00.5.439zM14 2.75a.5.5 0 00.5-.5V1.5A1.5 1.5 0 0013 0h-2a1.5 1.5 0 00-1.5 1.5v.75a.5.5 0 00.5.5zM5.784 11.75a.5.5 0 00-.493.588 13.358 13.358 0 01.209 2.17c.006.362-.01.761-.046 1.2a.5.5 0 00.5.542h12.1a.5.5 0 00.5-.542 12.297 12.297 0 01-.046-1.2 13.358 13.358 0 01.209-2.17.5.5 0 00-.493-.588zM18.885 18.161a.5.5 0 00-.492-.411H5.607a.5.5 0 00-.492.411c-.152.848-.35 1.8-.6 2.894a2.113 2.113 0 00.445 1.817A3.136 3.136 0 007.333 24a2.832 2.832 0 002.334-1.228 2.83 2.83 0 004.666 0A2.832 2.832 0 0016.667 24a3.136 3.136 0 002.375-1.128 2.113 2.113 0 00.445-1.817 60.412 60.412 0 01-.602-2.894z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});