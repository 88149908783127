define("ember-svg-jar/inlined/smiley-happy-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-happy-1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM5.793 7.879a8.638 8.638 0 012.821-1.888 1 1 0 11.766 1.847 6.683 6.683 0 00-2.173 1.455 1 1 0 01-1.414-1.414zm1.661 7.114a1 1 0 011.384.291 3.771 3.771 0 006.324 0 1 1 0 011.676 1.093 5.773 5.773 0 01-9.676 0 1 1 0 01.292-1.384zm10.753-5.7a1 1 0 01-1.414 0 6.683 6.683 0 00-2.173-1.455 1 1 0 11.766-1.847 8.638 8.638 0 012.821 1.888 1 1 0 010 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});