define("ember-svg-jar/inlined/sd-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sd-card</title><path d=\"M20.561 4.854L16.146.44a1.511 1.511 0 00-1.06-.44H4.5A1.5 1.5 0 003 1.5v7a.5.5 0 00.5.5h1.25a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25H3.5a.5.5 0 00-.5.5v10A1.5 1.5 0 004.5 24h15a1.5 1.5 0 001.5-1.5V5.915a1.511 1.511 0 00-.439-1.061zM17.75 5.5v4a.75.75 0 01-1.5 0v-4a.75.75 0 011.5 0zM9.584 20.223l2-3a.52.52 0 01.832 0l2 3A.5.5 0 0114 21h-4a.5.5 0 01-.416-.778zM9 9.251a.75.75 0 01-.75-.75v-5a.75.75 0 111.5 0v5a.75.75 0 01-.75.75zm3.25-.75v-5a.75.75 0 011.5 0v5a.75.75 0 01-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});