define("ember-svg-jar/inlined/css-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>css-1</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-15 18.876a.625.625 0 010 1.25 4.625 4.625 0 010-9.25.625.625 0 010 1.25 3.375 3.375 0 000 6.75zm3.305-4.422l1.949.975a2.479 2.479 0 01-1.108 4.7H9.5a.625.625 0 010-1.25h2.146a1.229 1.229 0 00.549-2.329l-1.95-.974a2.48 2.48 0 011.109-4.7H13.5a.625.625 0 010 1.25h-2.146a1.229 1.229 0 00-.549 2.328zm6 0l1.949.975a2.479 2.479 0 01-1.108 4.7H15.5a.625.625 0 010-1.25h2.146a1.229 1.229 0 00.549-2.329l-1.95-.974a2.48 2.48 0 011.109-4.7H19.5a.625.625 0 010 1.25h-2.146a1.229 1.229 0 00-.549 2.328z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});