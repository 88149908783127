define("ember-svg-jar/inlined/indent-increase-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>indent-increase-1</title><path d=\"M14 21H1a1 1 0 000 2h13a1 1 0 100-2zM1 3h13a1 1 0 000-2H1a1 1 0 000 2zM1 8h6a1 1 0 000-2H1a1 1 0 000 2zM1 13h6a1 1 0 000-2H1a1 1 0 000 2zM1 18h6a1 1 0 100-2H1a1 1 0 000 2zM23.664 11.251l-4.5-4A1 1 0 0017.5 8v2.75a.25.25 0 01-.25.25H11a1 1 0 000 2h6.25a.25.25 0 01.25.25V16a1 1 0 00.591.912.978.978 0 00.409.088 1 1 0 00.664-.253l4.5-4a1 1 0 000-1.494z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});