define("ember-svg-jar/inlined/fruit-acorn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fruit-acorn</title><path d=\"M24 2.347a1.57 1.57 0 00-.463-1.117L22.77.466A1.566 1.566 0 0021.653 0a1.568 1.568 0 00-1.116.463L18.806 2.2a.5.5 0 01-.632.061 10.176 10.176 0 00-11.3-.046 1.5 1.5 0 00-.234 2.312l12.835 12.834a1.489 1.489 0 001.058.44c.051 0 .1 0 .154-.008a1.494 1.494 0 001.1-.667 10.171 10.171 0 00-.047-11.3.5.5 0 01.06-.626l1.73-1.732A1.57 1.57 0 0024 2.347zm-7 8.408a.75.75 0 11.75.75.75.75 0 01-.75-.75zm1.75 3.75a.75.75 0 11.75-.75.75.75 0 01-.753.75zm2-2a.75.75 0 11.75-.75.75.75 0 01-.753.75zM18.458 18.468L5.532 5.542a.465.465 0 00-.364-.142.5.5 0 00-.358.162C1.372 9.32-1.751 14.207 1.147 21.929a1.583 1.583 0 00.925.924A16.454 16.454 0 007.873 24c4.542 0 7.849-2.322 10.569-4.807a.5.5 0 00.016-.722z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});