define("ember-svg-jar/inlined/crypto-currency-bitcoin-dollar-unequal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-dollar-unequal</title><path d=\"M11.97 18.307a.51.51 0 00-.766 0l-3.919 4.9a.49.49 0 00.383.8h7.838a.49.49 0 00.383-.8zM23.672 13.887a.978.978 0 00-1.166-.747L1.075 17.832a.979.979 0 00.208 1.936.953.953 0 00.211-.023l21.431-4.692a.979.979 0 00.747-1.166zM1.3 13.715a.979.979 0 00.979.979h.27a.244.244 0 01.24.2.98.98 0 001.921-.007.247.247 0 01.224-.2 2.943 2.943 0 002.734-2.932 2.9 2.9 0 00-.636-1.807.246.246 0 010-.3 2.9 2.9 0 00.636-1.808A2.943 2.943 0 004.934 4.9a.248.248 0 01-.224-.2.98.98 0 00-1.921 0 .244.244 0 01-.24.2h-.27a.979.979 0 00-.979.98zm3.429-.98H3.5a.245.245 0 01-.245-.245v-1.47a.245.245 0 01.245-.245h1.229a.98.98 0 010 1.96zm.98-4.9a.981.981 0 01-.98.98H3.5a.245.245 0 01-.245-.245V7.1a.245.245 0 01.245-.244h1.229a.981.981 0 01.98.98zM20.058 9.306h-2.1a.98.98 0 100 1.959h.242a.245.245 0 01.245.245v.245a.98.98 0 101.96 0v-.317a.244.244 0 01.2-.24 2.787 2.787 0 00.706-5.231L19.4 5.013a.837.837 0 01.375-1.586h2.1a.98.98 0 000-1.959h-.245a.245.245 0 01-.245-.245V.978a.98.98 0 00-1.96 0V1.3a.244.244 0 01-.2.24 2.787 2.787 0 00-.707 5.23l1.911.955a.837.837 0 01-.374 1.586z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});