define("ember-svg-jar/inlined/file-copyright-c-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-copyright-c-1</title><path d=\"M24 4.414A2 2 0 0023.414 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.276a.248.248 0 00.242.249 19.68 19.68 0 011.485.083A.247.247 0 008 9.359V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.245.245 0 00-.241.21 7.935 7.935 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2z\"/><path d=\"M6.5 11a6.452 6.452 0 00-3.669 1.146.212.212 0 00-.05.324l.874.875a.749.749 0 010 1.06.8.8 0 01-1.06 0l-.916-.916a.216.216 0 00-.327.051 6.494 6.494 0 009.08 9.13.26.26 0 00.03-.4l-.862-.865a.75.75 0 011.06-1.06l.87.87a.208.208 0 00.327-.043A6.487 6.487 0 006.5 11zm1 9a.5.5 0 010 1 3.5 3.5 0 010-7 .5.5 0 010 1 2.5 2.5 0 000 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});