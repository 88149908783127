define("ember-svg-jar/inlined/animal-products-turkey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>animal-products-turkey</title><path d=\"M23 23.25H1a1 1 0 010-2h22a1 1 0 010 2zM10 7.75a1 1 0 01-.707-.293 2.771 2.771 0 010-3.914.77.77 0 000-1.086 1 1 0 011.414-1.414 2.771 2.771 0 010 3.914.77.77 0 000 1.086A1 1 0 0110 7.75zM14.5 7.75a1 1 0 01-.707-.293 2.771 2.771 0 010-3.914.77.77 0 000-1.086 1 1 0 011.414-1.414 2.771 2.771 0 010 3.914.77.77 0 000 1.086A1 1 0 0114.5 7.75z\"/><path d=\"M23.328 5.968a2.132 2.132 0 00-.651-.444.5.5 0 01-.255-.256 2.156 2.156 0 00-.444-.651 2.336 2.336 0 00-3.223 0 2.217 2.217 0 00-.605 2.04.5.5 0 01-.136.458l-1.795 1.8a.993.993 0 01-.551.28 3.883 3.883 0 00-1.2.4c-2.78 1.452-4.327 3.921-1.914 6.334A.75.75 0 1111.5 16.98a4.593 4.593 0 01-.283-6.762A.25.25 0 0011 9.8a9.514 9.514 0 00-8.5 9.45.5.5 0 00.5.5h18a.5.5 0 00.5-.5 9.43 9.43 0 00-2.4-6.3.5.5 0 01-.122-.4q.042-.325.051-.626a.5.5 0 01.146-.339l1.658-1.657a.5.5 0 01.454-.136 2.262 2.262 0 002.038-3.823z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});