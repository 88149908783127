define("ember-svg-jar/inlined/compass-direction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>compass-direction</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm5.74 7.069l-6.108 11.746a.6.6 0 01-.531.323.577.577 0 01-.143-.017.6.6 0 01-.458-.583V13.6a.1.1 0 00-.1-.1H5.462a.6.6 0 01-.277-1.132l11.747-6.109a.6.6 0 01.808.81z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});