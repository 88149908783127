define("ember-svg-jar/inlined/database-star-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-star-alternate</title><path d=\"M10.212 21.913a.95.95 0 00-.812-1.244c-4.109-.356-6.648-1.846-6.648-2.916v-.789a.249.249 0 01.373-.217 14.287 14.287 0 004.641 1.478.972.972 0 001.105-1.212l-.01-.034a1 1 0 00-.806-.723c-3.31-.528-5.3-1.8-5.3-2.753v-.789a.249.249 0 01.373-.217 16.144 16.144 0 006.438 1.687.257.257 0 00.182-.059 2.776 2.776 0 011.762-.624h1.171a.775.775 0 00.711-.468.611.611 0 00-.606-.851c-.5.036-1.1.071-1.531.071-5.189 0-8.5-1.777-8.5-3v-.789a.25.25 0 01.373-.218A17.574 17.574 0 0011.253 10c3.171 0 6.465-.681 8.5-1.979v1.232a1.527 1.527 0 01-.7 1.064.249.249 0 00-.041.348 12.278 12.278 0 011.067 2.305.875.875 0 00.8.531.865.865 0 00.869-.864V5.003c0-3.248-5.41-5-10.5-5S.765 1.751.754 4.993v12.76c0 2.8 4.034 4.491 8.413 4.9a.992.992 0 001.042-.721zM11.253 2c5.185 0 8.5 1.773 8.5 3v.009C19.741 6.232 16.434 8 11.253 8s-8.5-1.777-8.5-3 3.311-3 8.5-3z\"/><path d=\"M21.988 15h-2.5a.25.25 0 01-.23-.151l-1.344-3.116-.012-.028a1.294 1.294 0 00-2.318.028l-1.346 3.117a.249.249 0 01-.229.151h-2.5a1.229 1.229 0 00-1.166.76 1.271 1.271 0 00.319 1.4l2.194 1.955a.249.249 0 01.063.286L11.7 22.213a1.253 1.253 0 00.314 1.451 1.287 1.287 0 00.864.338 1.3 1.3 0 00.642-.172l3.112-1.752a.249.249 0 01.245 0l3.113 1.752a1.272 1.272 0 001.5-.166 1.254 1.254 0 00.316-1.45L20.582 19.4a.25.25 0 01.063-.287l2.213-1.971a1.262 1.262 0 00.3-1.378 1.232 1.232 0 00-1.17-.764z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});