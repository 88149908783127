define("ember-svg-jar/inlined/time-clock-hand-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-clock-hand-1</title><path d=\"M24 10a10 10 0 10-19.291 3.686.5.5 0 00.465.314h2.59a3.733 3.733 0 013.436 2.267 2.486 2.486 0 01.194 1.192.5.5 0 00.5.542H15.5a2.476 2.476 0 011.966.975.5.5 0 00.6.151A10.005 10.005 0 0024 10zm-11 .5v-6a1 1 0 112 0V9a.5.5 0 00.5.5H18a1 1 0 010 2h-4a1 1 0 01-1-1z\"/><path d=\"M6.375 19.5a.625.625 0 010-1.25H8.9a1 1 0 00.919-1.394A2.234 2.234 0 007.764 15.5H3.5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.764a2.239 2.239 0 002.236-2.235V20.5a1 1 0 00-1-1zM2 15.751a.992.992 0 00-.267-.669.99.99 0 00-.733-.331 1 1 0 00-1 1V23a1 1 0 001 1 .98.98 0 00.808-.444A.967.967 0 002 23z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});