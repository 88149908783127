define("ember-svg-jar/inlined/phone-action-data-transfer-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-data-transfer-1</title><path d=\"M12 12.75v5.75a1 1 0 01-1 1H4a1 1 0 01-1-1v-11a1 1 0 011-1h5.25a.25.25 0 00.25-.25v-2A.25.25 0 009.25 4H3.5A2.5 2.5 0 001 6.5v15A2.5 2.5 0 003.5 24h8a2.5 2.5 0 002.5-2.5v-8.75a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25z\"/><path d=\"M13.907 10.791l2.5-3.5A.5.5 0 0016 6.5h-1.25V1.25a1.25 1.25 0 00-2.5 0V6.5H11a.5.5 0 00-.407.791l2.5 3.5a.5.5 0 00.814 0zM22.907 3.709l-2.5-3.5a.519.519 0 00-.814 0l-2.5 3.5a.5.5 0 00.407.791h1.25v5.25a1.25 1.25 0 002.5 0V4.5h1.25a.5.5 0 00.407-.791z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});