define("ember-svg-jar/inlined/arrow-button-circle-up-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-circle-up-1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm6.817 14.4a.5.5 0 01-.385.82h-2.687a.5.5 0 01-.395-.193l-3.15-4.05a.249.249 0 00-.394 0L8.65 15.03a.5.5 0 01-.395.193H5.568a.5.5 0 01-.385-.82l6.433-7.719a.5.5 0 01.768 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});