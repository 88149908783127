define("ember-svg-jar/inlined/multiple-actions-check-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-check-2</title><path d=\"M23.781 12.657a1.14 1.14 0 00-1.487.01l-4.617 4.617a.25.25 0 01-.353 0l-1.179-1.179a1 1 0 10-1.414 1.414l1.576 1.581a1.691 1.691 0 002.387 0l5.014-5.014a1.133 1.133 0 00.073-1.429z\"/><path d=\"M23.049 18.19a1 1 0 00-1.253.656A4.5 4.5 0 1117.5 13a1 1 0 000-2 6.5 6.5 0 106.2 8.443 1 1 0 00-.651-1.253zM12.024 8.534a.5.5 0 00-.183.771 7.411 7.411 0 01.788 1.187.5.5 0 00.7.19A7.933 7.933 0 0117.5 9.5a.321.321 0 00.169-.593 6 6 0 00-5.646-.373z\"/><ellipse cx=\"14.014\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M11.564 11.733a6.026 6.026 0 00-11.559 1.6A.6.6 0 00.6 14h9.41a.5.5 0 00.441-.264 8.015 8.015 0 011.025-1.484.5.5 0 00.088-.519z\"/><ellipse cx=\"5.514\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});