define("ember-svg-jar/inlined/road-sign-turn-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-turn-1-alternate</title><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/><path d=\"M14.5 12.5h-.75a.25.25 0 01-.25-.25V6.5a1 1 0 00-1-1h-1a1 1 0 00-1 1v.75a.25.25 0 01-.25.25H9.5a1 1 0 00-1 1v1a1 1 0 001 1h.75a.25.25 0 01.25.25v5.75a1 1 0 001 1h1a1 1 0 001-1v-.75a.25.25 0 01.25-.25h.75a1 1 0 001-1v-1a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});