define("ember-svg-jar/inlined/align-bottom-move", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>align-bottom-move</title><path d=\"M23 22H1a1 1 0 000 2h22a1 1 0 000-2z\"/><rect x=\"13.5\" width=\"6\" height=\"19\" rx=\"1\" ry=\"1\"/><rect x=\"4.5\" y=\"6\" width=\"6\" height=\"13\" rx=\"1\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});