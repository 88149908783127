define("ember-svg-jar/inlined/light-bulb-shine-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-bulb-shine-alternate</title><path d=\"M13.875 21.5h-3.75a.5.5 0 00-.447.724l.335.67A1.99 1.99 0 0011.8 24h.4a1.99 1.99 0 001.789-1.106l.335-.67a.5.5 0 00-.447-.724zM12 4.5a1 1 0 001-1V1a1 1 0 00-2 0v2.5a1 1 0 001 1zM21.192 3.808a1 1 0 00-1.414 0L18.01 5.575a1 1 0 101.414 1.414l1.768-1.767a1 1 0 000-1.414zM24 13a1 1 0 00-1-1h-2a1 1 0 000 2h2a1 1 0 001-1zM5.99 5.575L4.222 3.808a1 1 0 00-1.414 1.414l1.768 1.767A1 1 0 005.99 5.575zM3 12H1a1 1 0 000 2h2a1 1 0 000-2zM12 6a6.546 6.546 0 00-6.539 6.538 6.406 6.406 0 002.566 5.153.5.5 0 01.2.4v.444A1.464 1.464 0 009.692 20h4.616a1.463 1.463 0 001.461-1.445v-.031l-.018-.357a.5.5 0 01.2-.424A6.534 6.534 0 0012 6zm2.594 10.256a1.924 1.924 0 00-.824 1.578.166.166 0 01-.17.166h-3.2a.165.165 0 01-.165-.166 1.924 1.924 0 00-.825-1.578 4.539 4.539 0 115.188 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});