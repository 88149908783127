define("ember-svg-jar/inlined/focus-frame-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>focus-frame-alternate</title><path d=\"M21 2H3a3 3 0 00-3 3v14a3 3 0 003 3h18a3 3 0 003-3V5a3 3 0 00-3-3zm1 17a1 1 0 01-1 1H3a1 1 0 01-1-1V5a1 1 0 011-1h18a1 1 0 011 1z\"/><path d=\"M10 6H6.25a2 2 0 00-2 2v4a1 1 0 002 0V8.5a.5.5 0 01.5-.5H10a1 1 0 000-2zM18.5 10.5a1 1 0 00-1 1V15a.5.5 0 01-.5.5h-3.5a1 1 0 000 2h4a2 2 0 002-2v-4a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});