define("ember-svg-jar/inlined/room-service-give-plate-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>room-service-give-plate-1</title><path d=\"M13.5 19.5H6a.5.5 0 010-1h3a.5.5 0 00.5-.5 2 2 0 00-2-2H2.25a.25.25 0 01-.25-.25.75.75 0 00-1.5 0v7.5a.75.75 0 001.5 0 .25.25 0 01.25-.25H16a.5.5 0 00.5-.5 3 3 0 00-3-3zM4.243 11h17.514a.5.5 0 00.488-.612 9.51 9.51 0 00-6.994-7.108.5.5 0 01-.356-.647A1.95 1.95 0 0015 2a2 2 0 00-4 0 1.95 1.95 0 00.105.633.5.5 0 01-.356.647 9.51 9.51 0 00-6.994 7.108.5.5 0 00.488.612zM8 6.922a8.246 8.246 0 013.777-1.759.75.75 0 01.267 1.477 6.742 6.742 0 00-3.087 1.438A.75.75 0 118 6.922zM23.441 12.264A.5.5 0 0023 12H3a.5.5 0 00-.416.777l1.333 2a.5.5 0 00.416.223h17.334a.5.5 0 00.416-.223l1.333-2a.5.5 0 00.025-.513z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});