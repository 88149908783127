define("ember-svg-jar/inlined/diving-gun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diving-gun</title><path d=\"M21.826 1.707A1 1 0 0021.119 0h-3a1 1 0 000 2 .243.243 0 01.172.414l-4.838 4.838a.25.25 0 00-.008.345 4.95 4.95 0 01.811 1.208.253.253 0 00.184.139.247.247 0 00.219-.07l3.288-3.288a.25.25 0 01.391.046 5.5 5.5 0 01-6.007 8.2.238.238 0 01-.172-.167.234.234 0 01.061-.23 3.507 3.507 0 000-4.951.5.5 0 00-.707 0L2.32 17.678a1.5 1.5 0 000 2.122l.708.707a1.5 1.5 0 002.12 0l.435-.435a.25.25 0 01.38.032L8.3 23.371a1.5 1.5 0 001.1.623c.041 0 .083.006.125.006a1.5 1.5 0 001.059-.439l.687-.686a1.5 1.5 0 00.161-1.935l-2.556-3.571a.5.5 0 01.053-.643l1.348-1.348a.251.251 0 01.279-.051 7.5 7.5 0 009.168-11.22.249.249 0 01.026-.323z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});