define("ember-svg-jar/inlined/file-apk-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-apk-1</title><path d=\"M23.707 5.708L18.293.294A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.751V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.415a1 1 0 00-.293-.707z\"/><path d=\"M10 12.126h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H10a.875.875 0 000-1.75zM5 12.126a.876.876 0 00-.875.874v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V13A.875.875 0 005 12.126z\"/><path d=\"M20 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h18a1 1 0 001-1zM7.125 17.5a.625.625 0 01-1.25 0v-2.124a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-4.374a2.213 2.213 0 011.9-2.239A2.128 2.128 0 017.125 13zm2.75-2.375h-.5a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-6a.625.625 0 01.625-.625H10a2.128 2.128 0 012.114 2.347 2.213 2.213 0 01-2.239 1.904zm4.686-.448l2.382 2.381a.625.625 0 01-.885.884S14.4 16.286 14.317 16.2s-.192-.053-.192.041V17.5a.625.625 0 01-1.25 0v-6a.625.625 0 111.25 0v1.27c0 .164.153.069.153.069l1.78-1.78a.625.625 0 11.885.884l-2.382 2.381a.25.25 0 000 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});