define("ember-svg-jar/inlined/real-estate-settings-building-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-settings-building-1</title><path d=\"M24 11a1 1 0 00-1-1h-1.969a.5.5 0 01-.483-.37A12.932 12.932 0 0020 7.972a.5.5 0 01.108-.548l1.434-1.435a1 1 0 000-1.414l-2.119-2.123a1 1 0 00-1.413 0l-1.435 1.436a.5.5 0 01-.549.112 13.058 13.058 0 00-1.656-.543.5.5 0 01-.37-.488V1a1 1 0 00-1-1h-2a1 1 0 00-1 1v1.969a.5.5 0 01-.37.483A12.866 12.866 0 007.973 4a.5.5 0 01-.549-.108l-1.436-1.44a1 1 0 00-1.413 0L2.453 4.575a1 1 0 000 1.414l1.435 1.436A.5.5 0 014 7.973a13.183 13.183 0 00-.549 1.657.5.5 0 01-.483.37H1a1 1 0 00-1 1v2a1 1 0 001 1h1.968a.5.5 0 01.483.37A13.183 13.183 0 004 16.027a.5.5 0 01-.108.548l-1.439 1.436a1 1 0 00-.293.707 1.009 1.009 0 00.293.707l2.122 2.121a1.016 1.016 0 00.707.292 1 1 0 00.707-.293l1.434-1.435a.5.5 0 01.549-.11 13.039 13.039 0 001.658.546.5.5 0 01.37.483V23a1 1 0 001 1h2a1 1 0 001-1v-1.968a.5.5 0 01.37-.483A13.023 13.023 0 0016.027 20a.5.5 0 01.548.107l1.436 1.435a1 1 0 001.414 0l2.121-2.121a1 1 0 000-1.414l-1.435-1.436a.5.5 0 01-.111-.543 13.253 13.253 0 00.545-1.658.5.5 0 01.483-.37H23a1 1 0 001-1zm-7.5 6.5a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5v-2a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v2a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5v-9a.5.5 0 01.235-.424l4-2.5a.5.5 0 01.53 0l4 2.5a.5.5 0 01.235.424z\"/><path d=\"M13.5 9h-3a.75.75 0 000 1.5h3a.75.75 0 000-1.5zM13.5 12h-3a.75.75 0 000 1.5h3a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});