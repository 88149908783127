define("ember-svg-jar/inlined/footwear-open-heels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>footwear-open-heels</title><path d=\"M12.528 20.683A4 4 0 0015.81 22.4h6.69a1.5 1.5 0 00.814-2.76l-4.285-2.68a1.023 1.023 0 01-.329-.335l-2.507-4.178a2 2 0 00-2.824-.635l-2.23 1.488a.5.5 0 01-.678-.117l-1.5-2.008a5.246 5.246 0 00-1.254-1.286.5.5 0 01-.207-.406V6.1a.5.5 0 01.391-.488l3.326-.739a1 1 0 10-.434-1.952l-4.036.9a.508.508 0 01-.3-.026L1.385 1.682a1 1 0 00-.77 1.846l4.577 1.906A.5.5 0 015.5 5.9v3a.5.5 0 01-.5.5H2.283a1 1 0 00-.906 1.423c.967 2.075 1.6 6.862 1.876 10.648a1 1 0 001 .929h1.5a1 1 0 001-1v-7.418a.5.5 0 01.91-.285z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});