define("ember-svg-jar/inlined/settings-on", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>settings-on</title><path d=\"M6 17.5h12a5.5 5.5 0 000-11H6a5.5 5.5 0 000 11zm5.5-4v-3a1 1 0 112 0v3a1 1 0 01-2 0zm4 0v-3a1 1 0 112 0v3a1 1 0 01-2 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});