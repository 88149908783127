define("ember-svg-jar/inlined/camping-tent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camping-tent</title><path d=\"M23 23.25a.75.75 0 00.625-1.164L13.233 6.026a.5.5 0 010-.552l2.1-3.171a1 1 0 00-1.668-1.1l-1.248 1.881a.5.5 0 01-.834 0L10.334 1.2a1 1 0 00-1.668 1.1l2.1 3.171a.5.5 0 010 .552L.375 22.086A.75.75 0 001 23.25h5.934a.5.5 0 00.429-.242l4.208-7.015a.52.52 0 01.858 0l4.208 7.015a.5.5 0 00.429.242z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});