define("ember-svg-jar/inlined/car-wagon-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-wagon-1</title><path d=\"M23.328 9.314l-1.9-2.853a2.993 2.993 0 00-2.5-1.336h-5.6a3.511 3.511 0 00-2.545 1.1l-3.21 3.4a.249.249 0 01-.146.075l-4.589.656A3.31 3.31 0 00.438 12a.253.253 0 000 .25.249.249 0 00.216.124H2.5a.75.75 0 010 1.5H.25a.25.25 0 00-.25.25v1a2 2 0 002 2h.5a.25.25 0 00.25-.25 3 3 0 016 0 .249.249 0 00.25.25h7a.25.25 0 00.25-.25 3 3 0 015.966-.423.25.25 0 00.386.173 2.8 2.8 0 00.785-.777A3.642 3.642 0 0024 13.822v-2.289a3.989 3.989 0 00-.672-2.219zM14 9.375a.25.25 0 01-.25.25H10.9a.25.25 0 01-.182-.422L12.237 7.6a1.506 1.506 0 011.091-.47h.422a.25.25 0 01.25.25zm4.305.151a.252.252 0 01-.2.1H15.75a.25.25 0 01-.25-.25v-2a.25.25 0 01.25-.25h1.8a.251.251 0 01.241.183l.556 2a.254.254 0 01-.042.217z\"/><circle cx=\"5.75\" cy=\"16.875\" r=\"2\"/><circle cx=\"19.25\" cy=\"16.875\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});