define("ember-svg-jar/inlined/phone-action-flash-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-flash-1</title><path d=\"M14.5 16.6v1.9a1 1 0 01-1 1h-7a1 1 0 01-1-1v-11a1 1 0 011-1h3.015a.253.253 0 00.2-.094l1.6-2a.25.25 0 00-.2-.406H6a2.5 2.5 0 00-2.5 2.5v15A2.5 2.5 0 006 24h8a2.5 2.5 0 002.5-2.5v-6.125a.25.25 0 00-.441-.159 11.193 11.193 0 01-1.408 1.159c-.079.06-.151.125-.151.225z\"/><path d=\"M19.625 6H17.75a.25.25 0 01-.25-.25V.5a.5.5 0 00-.89-.313l-5.9 7.371A.875.875 0 0011.375 9h1.875a.249.249 0 01.25.25v5.25a.507.507 0 00.5.5.5.5 0 00.391-.188l5.9-7.371A.875.875 0 0019.625 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});