define("ember-svg-jar/inlined/tools-slip-joint-pliers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-slip-joint-pliers</title><path d=\"M23.5 2.75h-1a.5.5 0 00-.5.5v.5a.5.5 0 01-.5.5h-1.086a.5.5 0 01-.471-.333A1 1 0 0019 3.25h-4.495a35.742 35.742 0 01-7.8-.867A5.5 5.5 0 000 7.75a5.435 5.435 0 00.073.9 1 1 0 001.694.544L3.7 7.259a.5.5 0 01.648-.05l1.194.87a.5.5 0 00.583 0 7.262 7.262 0 014.2-1.333h4.3a.25.25 0 01.177.427l-.69.689A1.25 1.25 0 0015.88 9.63l2.888-2.88H19a.965.965 0 00.724-.332.5.5 0 01.376-.168h1.4a.5.5 0 01.5.5v.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5zm-17 3.5a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M19.719 17.177a18.688 18.688 0 00-7.583-7.358A.252.252 0 0112 9.6v-.85a.5.5 0 00-.5-.5H10a5.08 5.08 0 00-4.954 3.921.5.5 0 00.494.579h1.378a12.622 12.622 0 0110.524 5.814 5.705 5.705 0 003.958 3.177.5.5 0 00.533-.741zM9 11.75a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});