define("ember-svg-jar/inlined/marine-mammal-turtle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>marine-mammal-turtle-1</title><path d=\"M23.985 12.129c-.065-.261-1.639-6.379-5.485-6.379a4.427 4.427 0 00-2.9 1.019 6.521 6.521 0 00-1.827-.891 5.014 5.014 0 00.727-2.333A3.267 3.267 0 0012 .25a3.267 3.267 0 00-2.5 3.3 5.029 5.029 0 00.726 2.333 6.521 6.521 0 00-1.826.886A4.427 4.427 0 005.5 5.75c-3.846 0-5.42 6.118-5.485 6.379a.5.5 0 00.194.528.508.508 0 00.564.013 18.781 18.781 0 014.777-2.109A9.145 9.145 0 005 13.705a9.438 9.438 0 00.494 3.038A3.352 3.352 0 003.5 19.75a4.452 4.452 0 002.276 3.95.5.5 0 00.714-.348 11.813 11.813 0 011.2-3A7.5 7.5 0 0012 22.887a7.5 7.5 0 004.313-2.535 11.813 11.813 0 011.2 3 .5.5 0 00.714.348 4.452 4.452 0 002.273-3.95 3.352 3.352 0 00-1.994-3.007A9.438 9.438 0 0019 13.705a9.121 9.121 0 00-.546-3.131 19.255 19.255 0 014.774 2.1.5.5 0 00.757-.541zM7 13.705a7.065 7.065 0 01.75-3.2l1.68 1.259-2.415 2.411a7.26 7.26 0 01-.015-.47zm5-6.091a4.546 4.546 0 013.41 1.641L12.149 11.7a.246.246 0 01-.3 0L8.59 9.255A4.546 4.546 0 0112 7.614zM15.711 17.9l-1.68-1.68 1.679-1.2s.887.866.958.9a7.529 7.529 0 01-.957 1.98zm-3.565-2.176a.252.252 0 01-.292 0l-2.489-1.778 1.277-1.278.308.231a1.758 1.758 0 002.1 0l.308-.231 1.277 1.278zM8.29 17.9a7.524 7.524 0 01-.958-1.978c.072-.035.958-.9.958-.9l1.679 1.2zm.955 1.165l1.977-1.977a1.754 1.754 0 001.556 0l1.979 1.98a6.618 6.618 0 01-2.739 1.82 6.557 6.557 0 01-2.773-1.823zm7.74-4.89l-2.415-2.416 1.68-1.259a7.065 7.065 0 01.75 3.205c0 .159 0 .316-.015.47z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});