define("ember-svg-jar/inlined/hair-dress-brush", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hair-dress-brush</title><path d=\"M21.348 7.028a2.5 2.5 0 000-3.535 2.441 2.441 0 00-1.255-.663.5.5 0 01-.253-.137L17.458.312a1 1 0 10-1.414 1.414l1.414 1.414a.5.5 0 11-.707.707l-1.414-1.414a1 1 0 10-1.414 1.414l1.414 1.414a.5.5 0 01-.707.707l-1.414-1.414A1 1 0 0011.8 5.968l1.414 1.414a.5.5 0 11-.707.707L11.1 6.675a1 1 0 10-1.419 1.414L12 10.4a.5.5 0 01.144.407 1.5 1.5 0 01-.425.9l-9.286 9.287a1.75 1.75 0 102.475 2.474z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});