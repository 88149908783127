define("ember-svg-jar/inlined/vinyl-record-gramophone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vinyl-record-gramophone</title><path d=\"M19.5 15.961c.061 0 .122 0 .182.006a.249.249 0 00.262-.192c.487-2.1-1.062-3.879-2.681-5.74-2.1-2.417-4.487-5.156-3.38-9.384A.5.5 0 0013.018.2L3.987 10.9a.5.5 0 00.569.786c3.822-1.534 10.077.509 11.786 3.1a2.146 2.146 0 01.376.953.249.249 0 00.248.225z\"/><path d=\"M18.5 22.711a.25.25 0 01.25-.25h.75a1.5 1.5 0 001.5-1.5v-2.5a1.5 1.5 0 00-1.5-1.5h-8.75a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h3a1 1 0 000-2h-8.5a1 1 0 100 2h3a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H4.5a1.5 1.5 0 00-1.5 1.5v2.5a1.5 1.5 0 001.5 1.5h.75a.25.25 0 01.25.25v.265a1 1 0 102 0v-.265a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v.265a1 1 0 102 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});