define("ember-svg-jar/inlined/content-ink-pen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-ink-pen</title><path d=\"M23.229.772a1.073 1.073 0 00-1.408-.094L10.2 9.564a.511.511 0 00-.113.119L7.26 13.926a.5.5 0 00.063.631l2.121 2.121a.5.5 0 00.353.147.506.506 0 00.278-.084l4.242-2.828a.515.515 0 00.12-.113l8.886-11.621a1.074 1.074 0 00-.094-1.407zM6.616 15.264a.516.516 0 00-.707 0l-.561.561H3.5a1.5 1.5 0 00-1.45 1.119L.475 22.916a.5.5 0 00.13.48.509.509 0 00.48.13l5.972-1.571A1.5 1.5 0 008.176 20.5v-1.846l.561-.561a.5.5 0 000-.707zm-.706 3.891a.75.75 0 110-1.061.75.75 0 010 1.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});