define("ember-svg-jar/inlined/multiple-neutral-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-neutral-1</title><path d=\"M.5 18h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M24 14a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L16 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 0121.69 18h1.81a.5.5 0 00.5-.5z\"/><circle cx=\"18.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M.5 18h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M24 14a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L16 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 0121.69 18h1.81a.5.5 0 00.5-.5z\"/><circle cx=\"18.5\" cy=\"3.5\" r=\"3.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});