define("ember-svg-jar/inlined/road-sign-railway-ahead-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-railway-ahead-alternate</title><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/><path d=\"M15 8.75a.75.75 0 000-1.5.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0V7a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0V7a.25.25 0 01-.25.25.75.75 0 000 1.5.25.25 0 01.25.25v1a.25.25 0 01-.25.25.75.75 0 000 1.5.25.25 0 01.25.25v1a.25.25 0 01-.25.25.75.75 0 000 1.5.25.25 0 01.25.25v1a.25.25 0 01-.25.25.75.75 0 000 1.5.25.25 0 01.25.25.75.75 0 001.5 0 .25.25 0 01.25-.25h2a.25.25 0 01.25.25.75.75 0 001.5 0 .25.25 0 01.25-.25.75.75 0 000-1.5.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25.75.75 0 000-1.5.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25.75.75 0 000-1.5.25.25 0 01-.25-.25V9a.25.25 0 01.25-.25zM13.25 16a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h2a.25.25 0 01.25.25zm0-3a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h2a.25.25 0 01.25.25zm0-3a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25V9a.25.25 0 01.25-.25h2a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});