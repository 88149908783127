define("ember-svg-jar/inlined/bathroom-tub-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bathroom-tub-person</title><circle cx=\"5\" cy=\"4\" r=\"2.5\"/><path d=\"M23.465 12.818a1.017 1.017 0 00-.736-.318H13.48l-1.647 1.029a5.484 5.484 0 01-2.911.829 5.543 5.543 0 01-3.248-1.052.5.5 0 01.59-.807 4.636 4.636 0 005.038.183s2.316-1.437 2.441-1.546a1.5 1.5 0 00-1.773-2.407l-2.257 1.409a1.516 1.516 0 01-1.7-.074L5.665 8.3a1.5 1.5 0 00-2.4 1.2v3H1.271a1.017 1.017 0 00-.736.318.987.987 0 00-.269.732A9.5 9.5 0 009.75 22.5h4.5a9.5 9.5 0 009.484-8.949.988.988 0 00-.269-.733z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});