define("ember-svg-jar/inlined/outdoors-water-birds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-water-birds</title><path d=\"M1.707 4.242a.629.629 0 01.609-.212 1.17 1.17 0 01.735.821 1 1 0 001.9 0 1.17 1.17 0 01.735-.821.629.629 0 01.609.212 1 1 0 001.412-1.414 2.614 2.614 0 00-2.482-.745 2.752 2.752 0 00-1.07.521.248.248 0 01-.309 0 2.757 2.757 0 00-1.071-.521 2.61 2.61 0 00-2.482.745 1 1 0 001.414 1.414zM7.793 8.221a1 1 0 001.414 0 .633.633 0 01.609-.212 1.173 1.173 0 01.735.822 1 1 0 001.9 0 1.173 1.173 0 01.735-.822.633.633 0 01.609.212 1 1 0 001.414-1.414 2.616 2.616 0 00-2.482-.744 2.752 2.752 0 00-1.07.521.249.249 0 01-.309 0 2.757 2.757 0 00-1.071-.521 2.61 2.61 0 00-2.482.744 1 1 0 00-.002 1.414zM15.207 2.742a.63.63 0 01.609-.211 1.168 1.168 0 01.735.821 1 1 0 001.9 0 1.168 1.168 0 01.735-.821.63.63 0 01.609.211 1 1 0 101.414-1.414 2.616 2.616 0 00-2.484-.744 2.752 2.752 0 00-1.07.521.249.249 0 01-.309 0 2.757 2.757 0 00-1.071-.521 2.612 2.612 0 00-2.482.744 1 1 0 001.414 1.414zM23 21.486a3.745 3.745 0 01-2.9-1.646 1.056 1.056 0 00-1.546.023 3.72 3.72 0 01-2.884 1.623 3.743 3.743 0 01-2.9-1.646 1.055 1.055 0 00-1.547.024 3.72 3.72 0 01-2.884 1.622 3.745 3.745 0 01-2.9-1.646 1.055 1.055 0 00-1.547.024A3.718 3.718 0 011 21.486a1 1 0 000 2 5.121 5.121 0 003.345-1.243.5.5 0 01.64 0 5.128 5.128 0 006.694 0 .5.5 0 01.639 0 5.128 5.128 0 006.694 0 .5.5 0 01.64 0A5.126 5.126 0 0023 23.486a1 1 0 000-2zM1 17.986a5.121 5.121 0 003.345-1.243.5.5 0 01.64 0 5.128 5.128 0 006.694 0 .5.5 0 01.639 0 5.128 5.128 0 006.694 0 .5.5 0 01.64 0A5.126 5.126 0 0023 17.986a1 1 0 000-2 3.745 3.745 0 01-2.9-1.646 1.056 1.056 0 00-1.546.023 3.72 3.72 0 01-2.884 1.623 3.743 3.743 0 01-2.9-1.646 1.055 1.055 0 00-1.547.024 3.72 3.72 0 01-2.884 1.622 3.745 3.745 0 01-2.9-1.646 1.055 1.055 0 00-1.547.024A3.718 3.718 0 011 15.986a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});