define("ember-svg-jar/inlined/skiing-cable-car", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skiing-cable-car</title><circle cx=\"14.25\" cy=\"10.251\" r=\"2.5\"/><path d=\"M17.25 17h-1a.5.5 0 01-.5-.5v-1.25a1.5 1.5 0 00-3 0v1.25a3.5 3.5 0 003.5 3.5h1a1.5 1.5 0 011.5 1.5v1a1.5 1.5 0 003 0v-1a4.505 4.505 0 00-4.5-4.5z\"/><path d=\"M22.719.753A1 1 0 0021.5.032l-19.5 5a1 1 0 10.5 1.938l9.752-2.5v.13a5.008 5.008 0 00-4 4.9v7.75a6.006 6.006 0 006 6H15.5a1 1 0 000-2h-1.25a4 4 0 01-4-4V9.5a3 3 0 013-3 1 1 0 001-1V3.957L22 1.97a1 1 0 00.719-1.217z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});