define("ember-svg-jar/inlined/cloud-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-shield</title><path d=\"M1.942 15.517a1.016 1.016 0 001.558-.861.946.946 0 00-.455-.809 2.225 2.225 0 01-.406-.313A2.371 2.371 0 012 11.749a2.588 2.588 0 012.586-2.587 2.643 2.643 0 01.535.056 1 1 0 001.2-.965C6.405 2.5 14.621.89 17.142 6.208a.974.974 0 00.941.571 3.7 3.7 0 012.992 1.294 3.868 3.868 0 01.916 2.777 3.439 3.439 0 01-1.179 2.484.953.953 0 00-.312.722.993.993 0 001.619.777A5.393 5.393 0 0024 10.7a5.763 5.763 0 00-5.22-5.9.25.25 0 01-.19-.12 7.685 7.685 0 00-14.1 2.3.251.251 0 01-.227.2A4.642 4.642 0 00.62 9.422 4.479 4.479 0 000 11.869a4.287 4.287 0 001.235 3.09 4.2 4.2 0 00.707.558z\"/><path d=\"M10.69 22.707l.553.211a2.057 2.057 0 001.471 0l.553-.211c3.081-1.174 5.233-3.971 5.233-6.8V12.51a1.545 1.545 0 00-.928-1.416 16.419 16.419 0 00-5.594-1.04 16.018 16.018 0 00-5.55 1.04A1.545 1.545 0 005.5 12.51v3.39a7.715 7.715 0 005.19 6.807zM9 16.3a.75.75 0 110-1.5h2a.25.25 0 00.25-.25v-2a.75.75 0 011.5 0v2a.25.25 0 00.25.25h2a.75.75 0 110 1.5h-2a.25.25 0 00-.25.25v2a.75.75 0 01-1.5 0v-2a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});