define("ember-svg-jar/inlined/single-neutral-statics-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-statics-2</title><path d=\"M5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M24 2a2 2 0 00-2-2H11.5a1 1 0 000 2h10a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-7.75a1 1 0 000 2H22a2 2 0 002-2z\"/><rect x=\"13\" y=\"7.5\" width=\"2.5\" height=\"4.5\" rx=\".5\" ry=\".5\"/><rect x=\"17\" y=\"4\" width=\"2.5\" height=\"8\" rx=\".5\" ry=\".5\"/><path d=\"M5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M24 2a2 2 0 00-2-2H11.5a1 1 0 000 2h10a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-7.75a1 1 0 000 2H22a2 2 0 002-2z\"/><rect x=\"13\" y=\"7.5\" width=\"2.5\" height=\"4.5\" rx=\".5\" ry=\".5\"/><rect x=\"17\" y=\"4\" width=\"2.5\" height=\"8\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});