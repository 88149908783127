define("ember-svg-jar/inlined/design-tool-selection-wand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-tool-selection-wand</title><path d=\"M11.616 10.616L.366 21.866a1.25 1.25 0 001.768 1.768l11.25-11.25a1.25 1.25 0 00-1.768-1.768zM23 7h-3a1 1 0 000 2h3a1 1 0 000-2zM19.707 5.707l2-2a1 1 0 10-1.414-1.414l-2 2a1 1 0 001.414 1.414zM16 0a1 1 0 00-1 1v3a1 1 0 002 0V1a1 1 0 00-1-1zM11.707 2.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414-1.414zM9 9h3a1 1 0 000-2H9a1 1 0 000 2zM16 11a1 1 0 00-1 1v3a1 1 0 002 0v-3a1 1 0 00-1-1zM19.707 10.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});