define("ember-svg-jar/inlined/floppy-disk-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>floppy-disk-2</title><path d=\"M22 0H4.5A2 2 0 003 .587L.586 3A2.009 2.009 0 000 4.415V22a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zM5.7 7.5a1.238 1.238 0 01-1.2-1.272V2.251A.25.25 0 014.75 2h14.5a.249.249 0 01.25.25v3.978A1.238 1.238 0 0118.3 7.5zm9.8 5.5A3.5 3.5 0 1112 9.5a3.5 3.5 0 013.5 3.5zm-11 6.5a1.113 1.113 0 011.2-1h12.6a1.113 1.113 0 011.2 1v2.25a.25.25 0 01-.25.25H4.75a.25.25 0 01-.25-.25z\"/><rect x=\"7\" y=\"2.501\" width=\"2\" height=\"4\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});