define("ember-svg-jar/inlined/trousers-calves-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trousers-calves-1</title><path d=\"M10 21.5H6a.5.5 0 00-.5.5v.5A1.5 1.5 0 007 24h2a1.5 1.5 0 001.5-1.5V22a.5.5 0 00-.5-.5zM18 21.5h-4a.5.5 0 00-.5.5v.5A1.5 1.5 0 0015 24h2a1.5 1.5 0 001.5-1.5V22a.5.5 0 00-.5-.5zM13.438 3.5a.25.25 0 00-.2.4l.862 1.15a.75.75 0 11-1.2.9l-.7-.933a.25.25 0 00-.4 0l-.7.933a.75.75 0 01-1.2-.9l.862-1.15a.25.25 0 00-.2-.4H5.07a.5.5 0 00-.5.514L5 19.043A1.493 1.493 0 006.5 20.5h2.931a1.5 1.5 0 001.5-1.425l.5-9.013a.6.6 0 011.145-.034l.5 9.043a1.5 1.5 0 001.5 1.429H17.5a1.493 1.493 0 001.5-1.457l.426-15.029a.5.5 0 00-.5-.514zM4.5 1.541A.474.474 0 004.973 2h14.053a.474.474 0 00.474-.46A1.5 1.5 0 0018 0H6a1.5 1.5 0 00-1.5 1.541z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});