define("ember-svg-jar/inlined/video-file-mp4-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-mp4-1</title><path d=\"M23.707 5.707L18.293.293A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.75V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707z\"/><path d=\"M14.718 12.773a4.908 4.908 0 00-1.353 1.946.115.115 0 00.116.156h1.138a.25.25 0 00.25-.25v-1.784c0-.121-.101-.111-.151-.068z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zM6.131 17.5a.625.625 0 01-1.25 0v-3.13c0-.07-.084-.055-.111 0l-.2.409a.649.649 0 01-1.117 0s-.183-.356-.205-.409-.112-.052-.112 0v3.13a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279s.757 1.521.805 1.609a.167.167 0 00.273 0l.805-1.609a.624.624 0 011.183.279zm2.75-2.375h-.5a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-6a.625.625 0 01.625-.625h1.5a2.127 2.127 0 012.114 2.347 2.212 2.212 0 01-2.239 1.903zm7.238.375v2a.625.625 0 01-1.25 0v-1.127a.249.249 0 00-.249-.248h-2.114a.626.626 0 01-.616-.731 6.146 6.146 0 013.327-4.445.625.625 0 01.9.561z\"/><path d=\"M9.006 12.125h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25h.625a.875.875 0 000-1.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});