define("ember-svg-jar/inlined/design-file-3ds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-3ds</title><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.427A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6zM11.456 13.319a.249.249 0 00-.331.24v3.882a.25.25 0 00.331.241 2.239 2.239 0 001.419-2.182 2.238 2.238 0 00-1.419-2.181z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.25a.25.25 0 00-.25-.25zM8.7 15.647A2.29 2.29 0 019.125 17a2.014 2.014 0 01-1.865 2.121h-.757A1.736 1.736 0 014.875 17.3a.625.625 0 011.25 0c0 .339.2.575.375.575h.754c.337 0 .621-.4.621-.873s-.285-.873-.623-.873a.625.625 0 110-1.25c.338 0 .623-.4.623-.873s-.284-.872-.621-.873h-.757c-.175 0-.372.236-.372.575a.625.625 0 01-1.25 0A1.736 1.736 0 016.5 11.875h.76A2.014 2.014 0 019.125 14a2.3 2.3 0 01-.425 1.354.242.242 0 000 .293zm1.8 3.478a.625.625 0 01-.625-.625v-6a.625.625 0 01.625-.625 3.625 3.625 0 010 7.25zm5.927-4.758l1.84 1.226a1.928 1.928 0 01-1.07 3.532H15.5a.625.625 0 010-1.25h1.7a.678.678 0 00.376-1.242l-1.84-1.226a1.928 1.928 0 011.07-3.532h1.7a.625.625 0 010 1.25H16.8a.678.678 0 00-.376 1.242z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});