define("ember-svg-jar/inlined/lens-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lens-vertical</title><path d=\"M8.275 22.5a.251.251 0 00-.245.3A1.5 1.5 0 009.5 24h5a1.5 1.5 0 001.47-1.2.25.25 0 00-.245-.3zM7.25 16.5a.25.25 0 00-.25.25v3.75a1.49 1.49 0 00.3.9.249.249 0 00.2.1h9a.251.251 0 00.2-.1 1.49 1.49 0 00.3-.9v-3.75a.25.25 0 00-.25-.25zM5.25 4.5a.25.25 0 00-.25.25v5.336a3.887 3.887 0 00.94 2.545.248.248 0 01.06.162V14a1.7 1.7 0 00.5 1.207l.22.22a.249.249 0 00.18.073h10.2a.249.249 0 00.177-.073l.22-.22A1.7 1.7 0 0018 14v-1.207a.248.248 0 01.06-.162 3.887 3.887 0 00.94-2.545V4.75a.25.25 0 00-.25-.25zm4 6a.75.75 0 01-1.5 0v-4a.75.75 0 011.5 0zm3.5 0a.75.75 0 01-1.5 0v-4a.75.75 0 011.5 0zm2.75.75a.75.75 0 01-.75-.75v-4a.75.75 0 011.5 0v4a.75.75 0 01-.75.75zM20 2.75a.75.75 0 00-.75-.75h-.1a.248.248 0 01-.24-.182A2.5 2.5 0 0016.5 0h-9a2.5 2.5 0 00-2.406 1.818.248.248 0 01-.24.182h-.1a.75.75 0 000 1.5h14.5A.75.75 0 0020 2.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});