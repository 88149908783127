define("ember-svg-jar/inlined/vr-laptop-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vr-laptop-1</title><path d=\"M10.312 3.833a.25.25 0 00.272.023A2.98 2.98 0 0112 3.5h10a2.98 2.98 0 011.416.356.25.25 0 00.362-.279c-.268-1.088-.793-3.2-.793-3.2A.5.5 0 0022.5 0H21a.5.5 0 00-.354.146 2.892 2.892 0 01-2.06.854h-3.172a2.892 2.892 0 01-2.06-.854A.5.5 0 0013 0h-1.5a.5.5 0 00-.485.379s-.525 2.11-.793 3.2a.251.251 0 00.09.254zM22 4.5H12a2 2 0 00-2 2v3a2 2 0 002 2h8v9a.5.5 0 01-1 0v-2a2.5 2.5 0 00-2.5-2.5H14a1 1 0 000 2h2.5a.5.5 0 01.5.5v2a2.5 2.5 0 005 0v-9a2 2 0 002-2v-3a2 2 0 00-2-2zm-8 5A1.5 1.5 0 1115.5 8 1.5 1.5 0 0114 9.5zm6 0A1.5 1.5 0 1121.5 8 1.5 1.5 0 0120 9.5zM11.5 21h-.25v-4.5a2 2 0 00-2-2h-6.5a2 2 0 00-2 2V21H.5a.5.5 0 00-.5.5A2.41 2.41 0 002.5 24h7a2.41 2.41 0 002.5-2.5.5.5 0 00-.5-.5zm-2.25-.25A.25.25 0 019 21H3a.25.25 0 01-.25-.25v-4A.25.25 0 013 16.5h6a.252.252 0 01.177.074.248.248 0 01.073.176z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});