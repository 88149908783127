define("ember-svg-jar/inlined/tools-wood-saw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-wood-saw</title><path d=\"M24 4.576a2.569 2.569 0 00-.758-1.83l-.923-.923a5.466 5.466 0 00-7.72 0L4.057 12.366a.5.5 0 00-.075.61l1.107 1.846a.5.5 0 00.782.1L16.783 4.006a2.374 2.374 0 013.353 0l.816.817a.249.249 0 01-.141.424l-.936.134a1 1 0 00-.848.849l-.237 1.655a.25.25 0 01-.212.212l-1.656.237a1 1 0 00-.848.848l-.237 1.656a.249.249 0 01-.212.212l-1.655.236a1 1 0 00-.849.849l-.236 1.655a.25.25 0 01-.212.212l-1.656.237a1 1 0 00-.848.848l-.237 1.656a.25.25 0 01-.212.212l-1.656.236a1 1 0 00-.848.849l-.025.175a.248.248 0 00.033.164l1.526 2.543a.042.042 0 00.044.02.041.041 0 00.032-.035l.237-1.656a.249.249 0 01.212-.212l1.462-.209a1.266 1.266 0 001.07-1.069l.209-1.462a.25.25 0 01.212-.212l1.461-.209a1.269 1.269 0 001.071-1.069l.208-1.462a.25.25 0 01.213-.212l1.46-.209a1.268 1.268 0 001.071-1.07l.209-1.462a.249.249 0 01.212-.212l1.467-.21a1.265 1.265 0 001.07-1.07l.209-1.462a.249.249 0 01.212-.212l1.656-.237a.984.984 0 00.84-.947.259.259 0 01.037-.121A2.564 2.564 0 0024 4.576zM7.371 22.276a.5.5 0 00.075-.611l-4.429-7.381a.493.493 0 00-.367-.239.509.509 0 00-.415.142L.759 15.664a2.584 2.584 0 00-.389 3.161l2.214 3.691a2.587 2.587 0 004.049.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});