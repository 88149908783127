define("ember-svg-jar/inlined/laptop-lock-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-lock-2</title><path d=\"M12.221 15.3a3.981 3.981 0 01-1.22.2 3.082 3.082 0 01-2.95-1.65.492.492 0 00-.47-.36H.5a.478.478 0 00-.41.22.467.467 0 00-.05.47 5.43 5.43 0 005 3.31l7.209.009a.251.251 0 00.251-.25V16a1.95 1.95 0 01.042-.409.25.25 0 00-.322-.289zM3.5 12a1 1 0 001-1V3a1 1 0 011-1h12a1 1 0 011 1v5.757a.25.25 0 00.264.25C18.843 9 18.922 9 19 9a5.227 5.227 0 011.193.139.251.251 0 00.307-.239V3a3.009 3.009 0 00-3-3h-12a3.009 3.009 0 00-3 3v8a1 1 0 001 1zM22.715 15.516a.25.25 0 01-.214-.248v-1.017a3.5 3.5 0 00-7 0v1.017a.251.251 0 01-.214.248A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.285-1.484zM19 21.251a1 1 0 111-1 1 1 0 01-1 1zm1.5-6a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-1a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});