define("ember-svg-jar/inlined/toy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toy</title><path d=\"M3.811 4.35A1.08 1.08 0 104.89 5.43a1.08 1.08 0 00-1.079-1.08zM15.305 5.964a1.08 1.08 0 10-1.079-1.08 1.079 1.079 0 001.079 1.08z\"/><path d=\"M8.347 17.678a3.48 3.48 0 003.727-2.018.5.5 0 01.341-.284A7.292 7.292 0 0018 8.365.5.5 0 0118.164 8a4.612 4.612 0 00-5.97-7.019.5.5 0 01-.383.1 7.3 7.3 0 00-4.085.558.5.5 0 01-.466-.028 4.778 4.778 0 10-3.789 8.679.5.5 0 01.337.321 7.286 7.286 0 001.557 2.622.5.5 0 01.129.4 3.468 3.468 0 002.853 4.045zM15.036 2a2.615 2.615 0 11-2.615 2.614A2.617 2.617 0 0115.036 2zM7.822 9.89a4.112 4.112 0 006.256-1.09.75.75 0 111.3.739 5.627 5.627 0 01-4.308 2.817.5.5 0 00-.442.416l-.281 1.721a1.468 1.468 0 11-2.9-.472l.32-1.973a.5.5 0 00-.23-.505 5.446 5.446 0 01-.7-.514.749.749 0 11.985-1.139zM4.779 8.473a2.779 2.779 0 112.778-2.779 2.782 2.782 0 01-2.778 2.779zM23.832 19.2a1 1 0 00-1.387-.277l-2.871 1.914a1 1 0 01-.555.168H10.48a1 1 0 01-.555-.168l-2.87-1.914a1 1 0 10-1.11 1.664L8.527 22.3a.5.5 0 01.223.416v.53A.75.75 0 009.5 24H20a.75.75 0 00.75-.75v-.531a.5.5 0 01.222-.416l2.583-1.721a1 1 0 00.277-1.382z\"/><path d=\"M17.841 16a12.569 12.569 0 01-6.688 2.1 1 1 0 00.026 2h.021a14.539 14.539 0 007.74-2.43 1 1 0 00-1.1-1.67z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});