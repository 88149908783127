define("ember-svg-jar/inlined/printer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>printer</title><path d=\"M22.967 10.333l-1.751-4.389A1.49 1.49 0 0019.824 5h-2.073a.249.249 0 01-.25-.25V2.858a1 1 0 00-.32-.732l-2-1.858A1 1 0 0014.5 0h-7a1 1 0 00-1 1v3.75a.25.25 0 01-.25.25H4.177a1.488 1.488 0 00-1.391.943l-1.752 4.39a.724.724 0 00-.03.23l.896 7.124A1.5 1.5 0 003.383 19h1.324a.249.249 0 01.242.314l-.914 3.428A1 1 0 005 24h13.5a1 1 0 00.965-1.258l-.914-3.428a.25.25 0 01.243-.314h1.824a1.5 1.5 0 001.488-1.314L23 10.558a1.01 1.01 0 00-.033-.225zM15.251 7h-6.5a.249.249 0 01-.25-.25v-4.5a.249.249 0 01.25-.25h5.259a.253.253 0 01.17.066l1.241 1.152a.252.252 0 01.08.184v3.349a.25.25 0 01-.25.249zm5.25 6a1 1 0 11-1-1 1 1 0 01.999 1zM7.72 16.686a.249.249 0 01.241-.185h7.579a.249.249 0 01.241.185l1.334 5a.25.25 0 01-.242.315H6.628a.25.25 0 01-.242-.315z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});