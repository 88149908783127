define("ember-svg-jar/inlined/love-heart-hands-hold-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-heart-hands-hold-3</title><path d=\"M20.455 2.49a4.5 4.5 0 00-7.212-1.17l-.889.889a.5.5 0 01-.707 0l-.89-.889a4.5 4.5 0 10-6.368 6.368l6.69 6.979a1.276 1.276 0 001.842 0l6.69-6.979a4.5 4.5 0 00.844-5.198zM4.124 15.836a1.4 1.4 0 00-.458 1.823L5 19.876a.5.5 0 01-.857.516l-1.352-2.25a2.472 2.472 0 01.125-2.485.5.5 0 00.084-.276V12.5a1.5 1.5 0 00-3 0v5.171a4.5 4.5 0 00.986 2.812l2.664 3.329a.5.5 0 00.39.188H8a.5.5 0 00.5-.5v-3.01a4.492 4.492 0 00-.757-2.5c-1.887-1.965-2.477-2.916-3.619-2.154zM22.5 11a1.5 1.5 0 00-1.5 1.5v2.881a.5.5 0 00.084.276 2.472 2.472 0 01.125 2.485l-1.352 2.25a.5.5 0 01-.857-.516l1.334-2.217a1.4 1.4 0 00-.458-1.823c-1.142-.762-1.732.189-3.619 2.157a4.492 4.492 0 00-.757 2.5V23.5a.5.5 0 00.5.5h3.96a.5.5 0 00.39-.188l2.664-3.329A4.5 4.5 0 0024 17.671V12.5a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});