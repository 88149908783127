define("ember-svg-jar/inlined/dating-lips", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dating-lips</title><path d=\"M.5 10.5h23a.5.5 0 00.311-.892C20.8 7.214 17.636 6 15.96 6a4.426 4.426 0 00-3.411 1.17c-.281.249-.387.33-.549.33s-.268-.081-.549-.33A4.426 4.426 0 008.04 6C6.364 6 3.2 7.214.189 9.608A.5.5 0 00.5 10.5zM23.958 12.3a.5.5 0 00-.458-.3H.5a.5.5 0 00-.367.84A18.882 18.882 0 0012 18a18.882 18.882 0 0011.867-5.16.5.5 0 00.091-.54z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});