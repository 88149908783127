define("ember-svg-jar/inlined/giraffe-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>giraffe-1</title><path d=\"M22.367 10.919a36.794 36.794 0 00-5.943-5.906V2.742a1.5 1.5 0 10-2 0V3.8a4.743 4.743 0 00-1.913-.5v-.56a1.5 1.5 0 10-2 0v.941a4.447 4.447 0 00-.931.543C9.134 2.7 7.637.128 4.747.128a.5.5 0 00-.5.5c0 4.176 2.368 5.717 3.34 6.172C7.427 7.1.3 22.308.07 22.812a.75.75 0 00.682 1.063h8.8a.751.751 0 00.717-.529s2.282-7.213 2.81-8.545a.249.249 0 01.295-.149c1.359.356 7.414 2.376 7.915 2.376A2.536 2.536 0 0024 14.322a5.615 5.615 0 00-1.633-3.403zm-19.3 11.2a.25.25 0 01-.227-.354l.831-1.807a.249.249 0 01.392-.084 4.7 4.7 0 011.308 1.909.25.25 0 01-.235.336zm3.431-7.607a.249.249 0 01-.268-.352c.485-1.04.951-2.034 1.357-2.894a.25.25 0 01.441-.02 2.186 2.186 0 01-1.53 3.266zm2.751 5.428a.249.249 0 01-.152.161.252.252 0 01-.22-.022 2.184 2.184 0 011.159-4.034h.085a.25.25 0 01.23.324c-.246.778-.874 2.818-1.107 3.573zm4.5-10.063A1.25 1.25 0 1115 8.629a1.249 1.249 0 01-1.252 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});