define("ember-svg-jar/inlined/drone-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drone-add</title><path d=\"M6 2a1 1 0 000-2H1a1 1 0 000 2zM3.5 2.5a1 1 0 00-1 1v.065A1.75 1.75 0 001.75 5a.75.75 0 00.75.75h2A.75.75 0 005.25 5a1.75 1.75 0 00-.75-1.435V3.5a1 1 0 00-1-1zM23 0h-5a1 1 0 000 2h5a1 1 0 000-2zM20.5 2.5a1 1 0 00-1 1v.065A1.75 1.75 0 0018.75 5a.75.75 0 00.75.75h2a.75.75 0 00.75-.75 1.75 1.75 0 00-.75-1.435V3.5a1 1 0 00-1-1zM21.565 7h-3.348C15.706 7 14.81 5 12 5S8.293 7 5.782 7H2.435a1.5 1.5 0 000 3S4.913 9.977 6 9.986a.5.5 0 01.5.5v1.379a.248.248 0 01-.095.2A7.866 7.866 0 003.5 18.5a2 2 0 002 2h2.25a.25.25 0 01.25.25v1.75A1.5 1.5 0 009.5 24h5a1.5 1.5 0 001.5-1.5v-1.75a.25.25 0 01.25-.25h2.25a2 2 0 002-2 7.87 7.87 0 00-2.9-6.44.248.248 0 01-.095-.2v-1.374a.5.5 0 01.5-.5c1.091-.009 3.569.014 3.569.014a1.5 1.5 0 000-3zM8.5 11.27a.25.25 0 01.372-.218A5.968 5.968 0 0012 12a5.971 5.971 0 003.128-.948.249.249 0 01.248 0 .247.247 0 01.124.215V12.5a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5zm5.25 9.48h-1a.25.25 0 00-.25.25v1a.5.5 0 01-1 0v-1a.25.25 0 00-.25-.25h-1a.5.5 0 010-1h1a.25.25 0 00.25-.25v-1a.5.5 0 011 0v1a.25.25 0 00.25.25h1a.5.5 0 010 1zM18.486 18a.47.47 0 01-.468.5H16.25a.25.25 0 01-.25-.25V18a1.5 1.5 0 00-1.5-1.5h-5A1.5 1.5 0 008 18v.25a.25.25 0 01-.25.25H5.982a.47.47 0 01-.468-.5 5.913 5.913 0 011.414-3.7.25.25 0 01.372 0 2.056 2.056 0 001.45.7h6.45a2.183 2.183 0 001.489-.721.245.245 0 01.184-.075.254.254 0 01.181.083A5.9 5.9 0 0118.486 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});