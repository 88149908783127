define("ember-svg-jar/inlined/shipment-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-star</title><path d=\"M8.494 14a3.236 3.236 0 013.015-2H12.7a.25.25 0 00.23-.151l.824-1.909.05-.108a3.315 3.315 0 011.788-1.614.251.251 0 00.162-.234V6.5a.25.25 0 00-.25-.25H1a.249.249 0 00-.25.25v9a1.5 1.5 0 001.5 1.5h6.131a.25.25 0 00.224-.36A3.173 3.173 0 018.494 14zM9 4.5a.249.249 0 00.25.25h5.35a.25.25 0 00.177-.426L11.9 1.439A1.508 1.508 0 0010.835 1H9.25a.249.249 0 00-.25.25zM7.25 4.749A.25.25 0 007.5 4.5V1.249A.25.25 0 007.25 1H5.664a1.508 1.508 0 00-1.061.44L1.719 4.323a.25.25 0 00.177.426z\"/><path d=\"M19.987 22.828a1.277 1.277 0 001.506-.167 1.254 1.254 0 00.315-1.45L20.585 18.4a.25.25 0 01.062-.287l2.208-1.967.01-.009a1.264 1.264 0 00.3-1.373A1.234 1.234 0 0021.991 14h-2.5a.249.249 0 01-.229-.151l-1.344-3.116-.011-.024V10.7a1.293 1.293 0 00-2.31.025l-.008.015-1.342 3.109a.25.25 0 01-.23.151h-2.5a1.23 1.23 0 00-1.166.76 1.271 1.271 0 00.315 1.392l.01.009 2.189 1.95a.252.252 0 01.063.287L11.7 21.211a1.253 1.253 0 00.314 1.451 1.278 1.278 0 001.506.166l3.112-1.752a.249.249 0 01.245 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});