define("ember-svg-jar/inlined/search-engine-bing-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>search-engine-bing-2</title><path d=\"M19.59 12.36l-9-3.5a.25.25 0 00-.33.29l1 4.5a.25.25 0 00.16.18l3.46 1.3-6.13 3.06V2.6a.25.25 0 00-.14-.22l-4-2a.25.25 0 00-.24 0 .25.25 0 00-.12.22v20a.25.25 0 00.13.22l5 2.8a.25.25 0 00.12 0 .25.25 0 00.12 0l10-5.3a.25.25 0 00.13-.22v-5.5a.25.25 0 00-.16-.24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});