define("ember-svg-jar/inlined/webcam-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>webcam-2</title><circle cx=\"12\" cy=\"8.551\" r=\"2.5\"/><path d=\"M16.158 18.07a.508.508 0 00-.536-.07 8.532 8.532 0 01-7.244 0 .5.5 0 00-.536.072 6.423 6.423 0 00-2.154 3.716 1.409 1.409 0 001.388 1.665h9.848a1.411 1.411 0 001.388-1.665 6.423 6.423 0 00-2.154-3.718zM12 17.55a8.5 8.5 0 10-8.5-8.5 8.51 8.51 0 008.5 8.5zm4.5-8.5a4.5 4.5 0 11-9 0V4.479a.248.248 0 01.08-.179 6.468 6.468 0 018.84 0 .248.248 0 01.08.183z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});