define("ember-svg-jar/inlined/social-music-spotify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-music-spotify</title><path d=\"M4.11 5.48a16.25 16.25 0 0115.8 0 1 1 0 101-1.74 18.28 18.28 0 00-17.77 0 1 1 0 001 1.74z\"/><path d=\"M18.73 8.75a1 1 0 00.56-1.83 13.33 13.33 0 00-14.42-.1A1 1 0 006 8.49a11.29 11.29 0 0112.21.09 1 1 0 00.52.17z\"/><path d=\"M17.41 10a8.32 8.32 0 00-10.82 0 1 1 0 101.33 1.49 6.32 6.32 0 018.15 0A1 1 0 1017.41 10zM12 12.6A10.47 10.47 0 001.55 22a.5.5 0 00.5.55h4.82a.5.5 0 00.49-.4A4.75 4.75 0 0112 18.36a5.2 5.2 0 014.64 3.79.5.5 0 00.49.38h4.82a.5.5 0 00.5-.55A10.44 10.44 0 0012 12.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});