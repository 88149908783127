define("ember-svg-jar/inlined/pin-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin-add</title><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.5 7.5h-1a.5.5 0 00-.5.5v1a1 1 0 01-2 0v-1a.5.5 0 00-.5-.5h-1a1 1 0 010-2h1a.5.5 0 00.5-.5v-1a1 1 0 012 0v1a.5.5 0 00.5.5h1a1 1 0 010 2zM6.5 0A6.508 6.508 0 000 6.5c0 4 4.372 9.9 5.712 11.616a1 1 0 001.576 0C8.628 16.4 13 10.506 13 6.5A6.508 6.508 0 006.5 0zm0 9A2.5 2.5 0 119 6.5 2.5 2.5 0 016.5 9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});