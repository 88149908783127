define("ember-svg-jar/inlined/single-neutral-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-time</title><path d=\"M5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M17.5 0A6.5 6.5 0 1024 6.5 6.508 6.508 0 0017.5 0zm0 11A4.5 4.5 0 1122 6.5a4.505 4.505 0 01-4.5 4.5z\"/><path d=\"M20.5 6.5a1 1 0 00-1-1h-.75a.25.25 0 01-.25-.25v-1a1 1 0 00-2 0V6.5a1 1 0 001 1h2a1 1 0 001-1zM5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M17.5 0A6.5 6.5 0 1024 6.5 6.508 6.508 0 0017.5 0zm0 11A4.5 4.5 0 1122 6.5a4.505 4.505 0 01-4.5 4.5z\"/><path d=\"M20.5 6.5a1 1 0 00-1-1h-.75a.25.25 0 01-.25-.25v-1a1 1 0 00-2 0V6.5a1 1 0 001 1h2a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});