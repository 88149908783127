define("ember-svg-jar/inlined/torch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>torch</title><path d=\"M12 13.25a4.556 4.556 0 004.556-4.557A9.444 9.444 0 0010.969.269a.254.254 0 00-.269.046.251.251 0 00-.072.261c.492 1.636 1.14 4.162.035 6.276a.251.251 0 01-.419.038c-.316-.4-.8-1.065-1.072-1.445a.253.253 0 00-.418.011 7.463 7.463 0 00-1.31 3.237A4.558 4.558 0 0012 13.25zM14.36 14.75H9.64a1 1 0 00-.97 1.242l1.6 6.409a1.78 1.78 0 003.454 0l1.6-6.408a1 1 0 00-.969-1.243z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});