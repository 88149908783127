define("ember-svg-jar/inlined/squeeze-sides-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>squeeze-sides-1</title><path d=\"M16.376 15.048A5.04 5.04 0 0114 10.5a5.04 5.04 0 012.376-4.548.75.75 0 00-.752-1.3A6.5 6.5 0 0012.5 10.5a6.5 6.5 0 003.124 5.847.75.75 0 00.752-1.3z\"/><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/><path d=\"M16.921 7.688a.75.75 0 00-1.059.065 4.153 4.153 0 000 5.493.75.75 0 001.125-.993 2.65 2.65 0 010-3.507.75.75 0 00-.066-1.058zM8.376 4.653a.75.75 0 00-.752 1.3A5.04 5.04 0 0110 10.5a5.04 5.04 0 01-2.376 4.548.75.75 0 00.752 1.3A6.5 6.5 0 0011.5 10.5a6.5 6.5 0 00-3.124-5.847z\"/><path d=\"M8.138 13.246a4.153 4.153 0 000-5.493.75.75 0 00-1.125.993 2.65 2.65 0 010 3.507.75.75 0 001.125.993z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});