define("ember-svg-jar/inlined/video-game-cat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-cat</title><path d=\"M23.106 9.95A1 1 0 0123 9.5V2.75a1 1 0 00-1.79-.614l-2.548 3.125a.5.5 0 01-.639.116 11.992 11.992 0 00-11.576-.268.5.5 0 01-.574-.08L2.709 2.045A.986.986 0 002 1.75a1 1 0 00-1 1v6.769a1 1 0 01-.1.446A8.491 8.491 0 008.5 22.25h7a8.5 8.5 0 007.606-12.3zm-9.856 3.3a.75.75 0 01-1.5 0v-2a.75.75 0 011.5 0zm5.25 4.5a2 2 0 01-4 0v-1.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5zm1.75-4.5a.75.75 0 01-1.5 0v-2a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});