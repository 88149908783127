define("ember-svg-jar/inlined/temperature-fahrenheit-low-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>temperature-fahrenheit-low-alternate</title><path d=\"M6.25 18.417H5a.25.25 0 01-.25-.25v-3.25a1.25 1.25 0 00-2.5 0v3.25a.25.25 0 01-.25.25H.75a.749.749 0 00-.6 1.2l2.75 3.669a.751.751 0 001.2 0l2.75-3.667a.75.75 0 00-.6-1.2zM14.5.417a9.5 9.5 0 109.5 9.5 9.511 9.511 0 00-9.5-9.5zm0 17a7.5 7.5 0 117.5-7.5 7.508 7.508 0 01-7.5 7.5z\"/><circle cx=\"11.5\" cy=\"6.601\" r=\"1.5\"/><path d=\"M18.75 5.851h-2.5a2.5 2.5 0 00-2.5 2.5v6.5a1 1 0 002 0V12.1a.25.25 0 01.25-.25h1.25a1 1 0 000-2H16a.25.25 0 01-.25-.25V8.351a.5.5 0 01.5-.5h2.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});