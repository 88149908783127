define("ember-svg-jar/inlined/road-sign-left-curve-ahead-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-left-curve-ahead-alternate</title><path d=\"M11.293 23.707a1 1 0 001.414 0l11-11a1 1 0 000-1.414l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414zm-8.7-11.53a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354l-9.232 9.232a.25.25 0 01-.354 0z\"/><path d=\"M11.492 8.568a.25.25 0 00.018.37l1.466 1.211A3.006 3.006 0 0114 12.407V16.5a1 1 0 01-2 0v-4.093a.991.991 0 00-.32-.734l-1.627-1.345a.25.25 0 00-.336.016l-.187.186a.746.746 0 01-.53.22.75.75 0 01-.75-.75V8A.75.75 0 019 7.25h2a.75.75 0 01.53 1.28z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});