define("ember-svg-jar/inlined/bathroom-person-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bathroom-person-1</title><path d=\"M12 6.493a1.251 1.251 0 001.25-1.25 4.335 4.335 0 00-.839-1.784.519.519 0 00-.823 0 4.345 4.345 0 00-.838 1.784A1.251 1.251 0 0012 6.493zM6.088 3.459a4.345 4.345 0 00-.838 1.784 1.25 1.25 0 002.5 0 4.345 4.345 0 00-.838-1.784.52.52 0 00-.824 0zM17.911 3.459a.518.518 0 00-.822 0 4.335 4.335 0 00-.839 1.784 1.25 1.25 0 102.5 0 4.335 4.335 0 00-.839-1.784zM9.25 3.243a1.251 1.251 0 001.25-1.25A4.345 4.345 0 009.662.209a.52.52 0 00-.824 0A4.345 4.345 0 008 1.993a1.251 1.251 0 001.25 1.25zM14.339.209a4.335 4.335 0 00-.839 1.784 1.25 1.25 0 002.5 0 4.335 4.335 0 00-.839-1.784.518.518 0 00-.822 0zM11.75 13.493a3.5 3.5 0 00-3.5 3.5v2a.5.5 0 00.5.5h.605a.5.5 0 01.5.445l.4 3.611a.5.5 0 00.5.444h2a.5.5 0 00.5-.444l.4-3.611a.5.5 0 01.5-.445h.605a.5.5 0 00.5-.5v-2a3.5 3.5 0 00-3.51-3.5z\"/><circle cx=\"11.75\" cy=\"9.993\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});