define("ember-svg-jar/inlined/phone-vibrate-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-vibrate-1</title><path d=\"M15.5 3h-7a2 2 0 00-2 2v14a2 2 0 002 2h7a2 2 0 002-2V5a2 2 0 00-2-2zm-7 3a1 1 0 011-1h5a1 1 0 011 1v10.5a1 1 0 01-1 1h-5a1 1 0 01-1-1zM4 6.75a.75.75 0 00-.75.75v12a.75.75 0 001.5 0v-12A.75.75 0 004 6.75zM1 7.75a.75.75 0 00-.75.75v10a.75.75 0 001.5 0v-10A.75.75 0 001 7.75zM20 4.75a.75.75 0 00-.75.75v12a.75.75 0 001.5 0v-12a.75.75 0 00-.75-.75zM23 5.75a.75.75 0 00-.75.75v10a.75.75 0 001.5 0v-10a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});