define("ember-svg-jar/inlined/tag-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tag-new</title><path d=\"M1 4.5h22a1 1 0 000-2H1a1 1 0 000 2zM23 19.5H1a1 1 0 000 2h22a1 1 0 000-2zM5.708 8.5v1.705a.25.25 0 01-.474.111L4.1 8.053a1 1 0 00-1.892.447v7a1 1 0 002 0v-1.7a.25.25 0 01.474-.111l1.131 2.263a1 1 0 001.9-.447v-7a1 1 0 00-2 0zM11.375 9.5h2.333a1 1 0 000-2h-2.333a2.169 2.169 0 00-2.167 2.167v4.666a2.169 2.169 0 002.167 2.167h2.333a1 1 0 000-2h-2.333a.167.167 0 01-.167-.167V13.25a.25.25 0 01.25-.25h1.084a1 1 0 100-2h-1.084a.25.25 0 01-.25-.25V9.667a.167.167 0 01.167-.167zM20.3 8.429l-.208 2.91a.124.124 0 01-.1.113.122.122 0 01-.134-.066 1.042 1.042 0 00-1.79 0 .123.123 0 01-.233-.047l-.208-2.911a1 1 0 10-1.994.142l.5 7a1 1 0 001.892.376l.714-1.43a.25.25 0 01.448 0l.715 1.43a1 1 0 00.9.553.929.929 0 00.195-.02 1 1 0 00.8-.909l.5-7a1 1 0 10-1.997-.141z\"/><path d=\"M1 4.528h22a1 1 0 100-2H1a1 1 0 100 2zM23 19.528H1a1 1 0 000 2h22a1 1 0 000-2zM5.708 8.528v1.705a.25.25 0 01-.474.111L4.1 8.081a1 1 0 00-1.895.447v7a1 1 0 002 0v-1.7a.25.25 0 01.474-.111L5.81 15.98a1 1 0 001.9-.447v-7a1 1 0 10-2 0zM11.375 9.528h2.333a1 1 0 000-2h-2.333A2.169 2.169 0 009.208 9.7v4.666a2.169 2.169 0 002.167 2.167h2.333a1 1 0 000-2h-2.333a.167.167 0 01-.167-.167v-1.088a.25.25 0 01.25-.25h1.084a1 1 0 000-2h-1.084a.25.25 0 01-.25-.25V9.7a.167.167 0 01.167-.172zM20.3 8.457l-.208 2.91a.124.124 0 01-.1.113.122.122 0 01-.134-.066 1.042 1.042 0 00-1.79 0 .123.123 0 01-.233-.047l-.208-2.911a1 1 0 10-1.994.142l.5 7a1 1 0 001.892.376l.714-1.43a.25.25 0 01.448 0l.715 1.431a1 1 0 00.9.553.929.929 0 00.195-.02 1 1 0 00.8-.909l.5-7a1 1 0 10-1.997-.142z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});