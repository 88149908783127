define("ember-svg-jar/inlined/single-neutral.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral</title><circle cx=\"12\" cy=\"6.25\" r=\"5.5\"/><path d=\"M12 13.25a9.511 9.511 0 00-9.5 9.5.5.5 0 00.5.5h18a.5.5 0 00.5-.5 9.511 9.511 0 00-9.5-9.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});