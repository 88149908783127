define("ember-svg-jar/inlined/agriculture-machine-harvester", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>agriculture-machine-harvester</title><path d=\"M3.47 14.6a7.155 7.155 0 00-.765.929.25.25 0 01-.41 0 7.155 7.155 0 00-.765-.929.75.75 0 00-1.061 1.06 4.4 4.4 0 011.281 2.47.75.75 0 001.5 0 4.4 4.4 0 011.281-2.47.75.75 0 00-1.061-1.06zM9.47 14.6a7.155 7.155 0 00-.765.929.25.25 0 01-.41 0 7.155 7.155 0 00-.765-.929.75.75 0 00-1.061 1.06 4.4 4.4 0 011.281 2.47.75.75 0 001.5 0 4.4 4.4 0 011.281-2.47.75.75 0 00-1.061-1.06z\"/><path d=\"M23.921 12.8a2.787 2.787 0 00-1.939-1.343.5.5 0 01-.375-.332l-.4-1.214a1.5 1.5 0 00-1.428-1.031h-2.527a1 1 0 01-.636-.228L13.394 6a4 4 0 00-3.156-.822L.418 6.811A.5.5 0 000 7.3v.982a.5.5 0 00.586.492l9.963-1.733a2 2 0 011.672.476.254.254 0 01-.119.438L1.056 10.149a1 1 0 10.388 1.962l12.788-2.537a.5.5 0 01.43.117l1.222 1.087a.344.344 0 01-.229.6H14.7a1.5 1.5 0 00-1.409.988l-1.274 3.5a1.5 1.5 0 001.411 2.012h2.2a.5.5 0 00.4-.193.507.507 0 00.089-.432A3.457 3.457 0 0116 16.38a3.5 3.5 0 013.5-3.5h1.75a1.459 1.459 0 011.336.6.75.75 0 001.335-.684z\"/><circle cx=\"19.5\" cy=\"16.38\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});