define("ember-svg-jar/inlined/accounting-invoice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accounting-invoice</title><path d=\"M21.207 4.793l-4.5-4.5A1 1 0 0016 0H4.5a2 2 0 00-2 2v20a2 2 0 002 2h15a2 2 0 002-2V5.5a1 1 0 00-.293-.707zM18.5 22h-13a1 1 0 01-1-1V3a1 1 0 011-1h9.672a1 1 0 01.707.293l3.328 3.328a1 1 0 01.293.707V21a1 1 0 01-1 1z\"/><path d=\"M12.344 9.908a2.329 2.329 0 00-1.473-2.174l-2.063-.825a.342.342 0 01.127-.659h2.034a1 1 0 100-2h-.25a.25.25 0 01-.25-.25 1 1 0 00-2 0v.1a.251.251 0 01-.181.24 2.342 2.342 0 00-.223 4.425l2.064.825a.342.342 0 01-.129.66H7.969a1 1 0 000 2h.25a.25.25 0 01.25.25 1 1 0 002 0v-.1a.25.25 0 01.18-.241 2.344 2.344 0 001.695-2.251zM6 15.5v1a.5.5 0 00.5.5h3.25a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25H6.5a.5.5 0 00-.5.5zM6 18.5v1a.5.5 0 00.5.5h3.25a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25H6.5a.5.5 0 00-.5.5zM11 16.75a.25.25 0 00.25.25H17a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-5.75a.25.25 0 00-.25.25zM17 18h-5.75a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25H17a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});