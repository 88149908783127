define("ember-svg-jar/inlined/night-stand-retro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>night-stand-retro</title><circle cx=\"12\" cy=\"10.25\" r=\"1\"/><circle cx=\"12\" cy=\"15.25\" r=\"1\"/><path d=\"M21.5 19c0-2.28 2.36-9.36-.33-13.76a.52.52 0 00-.43-.24H3.26a.52.52 0 00-.43.24C.12 9.66 2.5 16.68 2.5 19a6.56 6.56 0 01-1.35 3.71.48.48 0 00-.092.271.505.505 0 00.5.519h.757a.5.5 0 00.336-.13 5.812 5.812 0 001.734-3.176.5.5 0 01.3-.367C7.9 18.479 8.384 21.5 12 21.5s4.106-3.021 7.315-1.673a.5.5 0 01.3.367 5.813 5.813 0 001.735 3.177.5.5 0 00.335.129h.757a.522.522 0 00.45-.27.511.511 0 00-.04-.52A6.56 6.56 0 0121.5 19zm-3-3a1 1 0 01-1 1h-11a1 1 0 01-1-1v-2a1 1 0 011-1h11a1 1 0 011 1zm0-5a1 1 0 01-1 1h-11a1 1 0 01-1-1V9a1 1 0 011-1h11a1 1 0 011 1zM22.5.5h-21a1.5 1.5 0 000 3h21a1.5 1.5 0 000-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});