define("ember-svg-jar/inlined/action-camera", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>action-camera</title><path d=\"M18.5 3.5H10a.751.751 0 00-.75.75v8.5a.751.751 0 00.75.75h8.5a.751.751 0 00.75-.75v-8.5a.751.751 0 00-.75-.75zm-4.25 8a3 3 0 113-3 3 3 0 01-3 3z\"/><path d=\"M22.75 4a4 4 0 00-4-4h-9a4 4 0 00-4 4v9a4 4 0 004 4h9a4 4 0 004-4zm-2 8.75A2.253 2.253 0 0118.5 15H10a2.253 2.253 0 01-2.25-2.25v-8.5A2.253 2.253 0 0110 2h8.5a2.253 2.253 0 012.25 2.25zM20.25 23a1 1 0 00-1-1H16.5a.25.25 0 01-.25-.25V18.5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v.25A.25.25 0 0112 19H5.307a.058.058 0 01-.057-.057 1.442 1.442 0 00-.936-1.351l-2.128-.8a.693.693 0 00-.936.649v4.614a.693.693 0 00.936.649l2.128-.8a1.44 1.44 0 00.809-.76.252.252 0 01.229-.144H12a.25.25 0 01.25.25v.5A.25.25 0 0112 22H9.25a1 1 0 000 2h10a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});