define("ember-svg-jar/inlined/fitness-dumbbell-disk-weight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fitness-dumbbell-disk-weight</title><path d=\"M20.144 16.085a1 1 0 00-1.223.711c-.674 2.545-1.727 3.7-2.421 3.7-1.223 0-3-3.311-3-8.5s1.777-8.5 3-8.5c.716 0 1.793 1.21 2.463 3.867a1 1 0 101.94-.49C20.04 3.46 18.436 1.5 16.5 1.5h-7c-2.728 0-4.4 3.817-4.866 8.055a.5.5 0 01-.5.445H2a2 2 0 000 4h2.137a.5.5 0 01.5.445C5.1 18.683 6.772 22.5 9.5 22.5h7c1.894 0 3.48-1.893 4.355-5.192a1 1 0 00-.711-1.223zM7.55 9.25H10a.75.75 0 010 1.5H7.55a.75.75 0 010-1.5zM6.8 14.5a.75.75 0 01.75-.75h2.36a.75.75 0 010 1.5H7.55a.75.75 0 01-.75-.75zm4.167 4.75H8.55a.75.75 0 010-1.5h2.417a.75.75 0 010 1.5zm.583-12.5H8.788a.75.75 0 010-1.5h2.762a.75.75 0 010 1.5z\"/><path d=\"M22 10h-5.5a.5.5 0 00-.354.146A2.659 2.659 0 0015.5 12a2.659 2.659 0 00.646 1.854.5.5 0 00.354.146H22a2 2 0 000-4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});