define("ember-svg-jar/inlined/messages-bubble-square-smile-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-smile-alternate</title><path d=\"M6.254 9a1 1 0 001-1 1 1 0 012 0 1 1 0 002 0 3 3 0 00-6 0 1 1 0 001 1zM13.754 9a1 1 0 001-1 1 1 0 012 0 1 1 0 002 0 3 3 0 00-6 0 1 1 0 001 1zM8.293 11.805a1 1 0 000 1.414 5.244 5.244 0 007.414 0 1 1 0 00-1.414-1.414 3.323 3.323 0 01-4.586 0 1 1 0 00-1.414 0z\"/><path d=\"M22 1H2a2 2 0 00-2 2v13.552A1.953 1.953 0 002 18.5h2.5V22a1 1 0 001.569.822l6.243-4.322H22a2 2 0 002-2V3a2 2 0 00-2-2zm0 15.25a.25.25 0 01-.25.25H12a1 1 0 00-.569.178L6.5 20.09V17.5a1 1 0 00-1-1H2.25a.25.25 0 01-.25-.25v-13A.25.25 0 012.25 3h19.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});