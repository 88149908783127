define("ember-svg-jar/inlined/gesture-tap-swipe-left-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-tap-swipe-left-1</title><path d=\"M9 8.179H3.749a.25.25 0 01-.25-.25v-1.25a.75.75 0 00-1.335-.469l-2 2.5a.75.75 0 000 .937l2 2.5a.75.75 0 001.336-.468v-1.25a.25.25 0 01.25-.25H9a1 1 0 000-2zM23.143 6.3a6.728 6.728 0 00-11.781-.008 6.66 6.66 0 00.174 6.84.75.75 0 101.271-.795 5.237 5.237 0 118.879.011.75.75 0 101.269.8 6.655 6.655 0 00.188-6.848z\"/><path d=\"M21.67 15.083l-3.741-1.117a.249.249 0 01-.178-.239V9.159a1 1 0 00-2 0v7.063a.25.25 0 01-.455.143c-1.489-2.138-3.855-1.449-2.761.224l2.912 4.367a.5.5 0 00.416.223H21.3a.5.5 0 00.492-.411c.2-1.1.664-3.683.775-4.166a1.281 1.281 0 00-.897-1.519z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});