define("ember-svg-jar/inlined/croquet-ball-hoop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>croquet-ball-hoop</title><path d=\"M16.286.174A1 1 0 0014.9.437L5.5 14.22a.5.5 0 01-.7.131l-1.125-.767a1.5 1.5 0 00-2.085.394L.262 15.929a1.5 1.5 0 00.393 2.085l4.73 3.225a1.5 1.5 0 002.084-.393L8.8 18.892a1.5 1.5 0 00-.395-2.083l-1.126-.768a.5.5 0 01-.132-.694l9.4-13.783a1 1 0 00-.261-1.39z\"/><circle cx=\"12.875\" cy=\"20.875\" r=\"2.625\"/><path d=\"M20.5 16a3.5 3.5 0 00-3.5 3.5V23a1 1 0 002 0v-3.5a1.5 1.5 0 013 0V23a1 1 0 002 0v-3.5a3.5 3.5 0 00-3.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});