define("ember-svg-jar/inlined/shopping-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-cart</title><circle cx=\"6\" cy=\"21.75\" r=\"2.25\"/><circle cx=\"15.25\" cy=\"21.75\" r=\"2.25\"/><path d=\"M23 0h-2.316A1.5 1.5 0 0019.2 1.252l-2.763 15.293a.252.252 0 01-.246.2H5a.5.5 0 010-1h7.758a2.5 2.5 0 000-5H3.5a.5.5 0 010-1h10.75a2.5 2.5 0 000-5H1a1 1 0 000 2h13.25a.5.5 0 010 1H3.5a2.5 2.5 0 000 5h9.258a.5.5 0 010 1H5a2.5 2.5 0 000 5h11.816A1.5 1.5 0 0018.3 17.5l2.763-15.295A.252.252 0 0121.309 2H23a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});