define("ember-svg-jar/inlined/table-candles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>table-candles</title><path d=\"M7.188 4.57c0-.862-1.116-2.946-1.754-4.068a.5.5 0 00-.869 0C3.927 1.625 2.813 3.708 2.813 4.57a2.188 2.188 0 104.375 0zM12 6.758a2.189 2.189 0 002.188-2.188c0-.862-1.116-2.946-1.754-4.068a.5.5 0 00-.869 0c-.638 1.123-1.752 3.206-1.752 4.068A2.188 2.188 0 0012 6.758zM19.434.5a.5.5 0 00-.869 0c-.638 1.122-1.752 3.205-1.752 4.067a2.188 2.188 0 104.375 0c0-.859-1.116-2.943-1.754-4.067zM19.5 8.25h-1a.5.5 0 00-.5.5v.5a6.006 6.006 0 01-4.695 5.85.251.251 0 01-.3-.244V8.75a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v6.107a.25.25 0 01-.094.2.254.254 0 01-.211.049A6.006 6.006 0 016 9.25v-.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v.5a8.007 8.007 0 006.788 7.9.25.25 0 01.212.25v4.1a.25.25 0 01-.25.25H7.5a1 1 0 000 2h9a1 1 0 000-2h-3.25a.25.25 0 01-.25-.25v-4.1a.25.25 0 01.212-.247A8.007 8.007 0 0020 9.25v-.5a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});