define("ember-svg-jar/inlined/rating-star-add-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rating-star-add-alternate</title><path d=\"M23.123 10.39a1.5 1.5 0 00-.973-2.641h-6.089a.5.5 0 01-.471-.334l-2.185-6.191a1.5 1.5 0 00-2.809 0l-.007.019L8.41 7.415a.5.5 0 01-.471.334H1.85A1.5 1.5 0 00.887 10.4l5.183 4.3a.5.5 0 01.156.543l-2.178 6.529a1.5 1.5 0 002.309 1.687l5.348-3.921a.5.5 0 01.591 0l5.346 3.921a1.5 1.5 0 002.31-1.685l-2.178-6.535a.5.5 0 01.155-.543zM11 15.749V14a.25.25 0 00-.25-.25H9a1 1 0 010-2h1.75a.25.25 0 00.25-.25V9.749a1 1 0 012 0V11.5a.25.25 0 00.25.25H15a1 1 0 010 2h-1.75A.25.25 0 0013 14v1.75a1 1 0 01-2 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});