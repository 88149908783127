define("ember-svg-jar/inlined/smart-watch-square-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-text</title><path d=\"M16.43 2.75a.251.251 0 00.238-.329L15.975.342A.5.5 0 0015.5 0h-7a.5.5 0 00-.475.342l-.693 2.079a.251.251 0 00.238.329zM7.57 21.25a.248.248 0 00-.2.1.251.251 0 00-.035.225l.693 2.079A.5.5 0 008.5 24h7a.5.5 0 00.475-.342l.693-2.079a.251.251 0 00-.035-.225.248.248 0 00-.2-.1zM17.5 4h-11A2.5 2.5 0 004 6.5v11A2.508 2.508 0 006.5 20h11a2.5 2.5 0 002.5-2.5v-11A2.5 2.5 0 0017.5 4zM9 15a1.5 1.5 0 01-1.5-1.5v-4A1.5 1.5 0 019 8h6a1.5 1.5 0 011.5 1.5v4A1.5 1.5 0 0115 15h-2.237a.25.25 0 00-.156.055l-2.295 1.835a.5.5 0 01-.812-.39v-1.25a.25.25 0 00-.25-.25z\"/><path d=\"M10 11h4a.5.5 0 000-1h-4a.5.5 0 000 1zM10 13h3a.5.5 0 000-1h-3a.5.5 0 000 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});