define("ember-svg-jar/inlined/multiple-actions-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-lock</title><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M14.353 14.165a.25.25 0 00.153-.217 4.493 4.493 0 013.535-4.137.25.25 0 00.152-.388 4.529 4.529 0 00-7.293-.162.248.248 0 00.005.31A6.96 6.96 0 0112.5 14v1.214a.25.25 0 00.461.134 2.946 2.946 0 011.392-1.183zM11 14a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M22.5 24a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.286-1.485.249.249 0 01-.214-.247V14.25a3.5 3.5 0 00-7 0v1.018a.249.249 0 01-.214.247A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7zM19 12.75a1.5 1.5 0 011.5 1.5v1a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-1a1.5 1.5 0 011.5-1.5zm1 7.5a1 1 0 11-1-1 1 1 0 011 1z\"/><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M14.353 14.165a.25.25 0 00.153-.217 4.493 4.493 0 013.535-4.137.25.25 0 00.152-.388 4.529 4.529 0 00-7.293-.162.248.248 0 00.005.31A6.96 6.96 0 0112.5 14v1.214a.25.25 0 00.461.134 2.946 2.946 0 011.392-1.183zM11 14a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M22.5 24a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.286-1.485.249.249 0 01-.214-.247V14.25a3.5 3.5 0 00-7 0v1.018a.249.249 0 01-.214.247A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7zM19 12.75a1.5 1.5 0 011.5 1.5v1a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-1a1.5 1.5 0 011.5-1.5zm1 7.5a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});