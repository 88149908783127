define("ember-svg-jar/inlined/mouse-tail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mouse-tail</title><path d=\"M18.749 8.75a5.506 5.506 0 00-6.231-5.457 3.756 3.756 0 01-.124 2.982.76.76 0 01-1 .349.749.749 0 01-.349-1 2.262 2.262 0 00.055-1.838A3.572 3.572 0 007.749 1.25 3.5 3.5 0 004.27 4.368l-3.159.9a.5.5 0 00-.362.48v.8a4.7 4.7 0 004.7 4.7h.163L5.4 12.5h-.649a1 1 0 000 2H5.4a1.993 1.993 0 001.973-1.67l.264-1.58h2.609a2.98 2.98 0 00.172 1h-.67a1 1 0 100 2H13.5a1.027 1.027 0 00.185-.017 5.513 5.513 0 004.686-3.481 3 3 0 01-.121 6h-9a3 3 0 100 6h6a1 1 0 100-2h-6a1 1 0 110-2h9a5.013 5.013 0 00.5-10zM6 7a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});