define("ember-svg-jar/inlined/hardware-nut-round", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hardware-nut-round</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 17.5a5.5 5.5 0 115.5-5.5 5.5 5.5 0 01-5.5 5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});