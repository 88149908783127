define("ember-svg-jar/inlined/composition-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>composition-horizontal</title><path d=\"M24 5.2a1.752 1.752 0 00-2.313-1.657 27.846 27.846 0 01-19.38-.005A1.751 1.751 0 000 5.2v13.6a1.751 1.751 0 002.286 1.67 29.031 29.031 0 0119.425 0A1.752 1.752 0 0024 18.8zm-2.5 11.915a.5.5 0 01-.637.481 30.534 30.534 0 00-17.727.004.5.5 0 01-.636-.481V6.9a.5.5 0 01.641-.479 29.373 29.373 0 0017.717.009.5.5 0 01.642.479z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});