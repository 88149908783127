define("ember-svg-jar/inlined/expand-diagonal-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>expand-diagonal-1</title><path d=\"M2.75 20.249a1 1 0 001 1h7a1 1 0 00.707-1.707L9.018 17.1a.249.249 0 010-.353l7.732-7.73a.25.25 0 01.354 0l2.439 2.439a1 1 0 00.707.293.987.987 0 00.383-.076 1 1 0 00.617-.924v-7a1 1 0 00-1-1h-7a1 1 0 00-.707 1.707L14.982 6.9a.25.25 0 010 .354L7.25 14.981a.25.25 0 01-.354 0l-2.439-2.439a1 1 0 00-1.707.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});