define("ember-svg-jar/inlined/measure-ruler-corner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>measure-ruler-corner</title><path d=\"M23.994 3.483a.98.98 0 00-.116-.462A1 1 0 0023 2.5H2a2 2 0 00-2 2v5a2 2 0 002 2h16V20a1.5 1.5 0 001.5 1.5h3A1.5 1.5 0 0024 20V3.5c0-.006 0-.011-.006-.017zM2.5 9.5A.5.5 0 012 9V5a.5.5 0 01.5-.5h3.25a.5.5 0 01.5.5v1a.75.75 0 001.5 0V5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.75.75 0 001.5 0V5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.75.75 0 001.5 0V5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.75.75 0 001.5 0V5a.5.5 0 01.5-.5h1.5a.5.5 0 01.42.77l-2.571 4a.5.5 0 01-.421.23z\"/><circle cx=\"4\" cy=\"7\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});