define("ember-svg-jar/inlined/dresser-drawers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dresser-drawers</title><path d=\"M21.5 8.75h-19a.5.5 0 00-.5.5v4.5a.5.5 0 00.5.5h19a.5.5 0 00.5-.5v-4.5a.5.5 0 00-.5-.5zm-9.43 3.64a1 1 0 111-1 1 1 0 01-1 1zM20.5.25h-17A1.5 1.5 0 002 1.75v5a.5.5 0 00.5.5h19a.5.5 0 00.5-.5v-5a1.5 1.5 0 00-1.5-1.5zm-8.43 4.64a1 1 0 111-1 1 1 0 01-1 1zM21.5 15.75h-19a.5.5 0 00-.5.5v4a1.506 1.506 0 001.286 1.485.249.249 0 01.214.247v.768a1 1 0 002 0V22a.25.25 0 01.25-.25h12.5a.25.25 0 01.25.25v.75a1 1 0 002 0v-.768a.249.249 0 01.214-.247A1.5 1.5 0 0022 20.25v-4a.5.5 0 00-.5-.5zm-9.43 3.64a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});