define("ember-svg-jar/inlined/programming-user-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-user-woman</title><path d=\"M11 .02a1 1 0 000 2h10.75a.25.25 0 01.25.25v11.5a.25.25 0 01-.25.25H13.5a1 1 0 000 2H22a2 2 0 002-2v-12a2 2 0 00-2-2z\"/><path d=\"M13.03 10.55l2.5-2.5a.75.75 0 000-1.061l-2.5-2.5a.75.75 0 10-1.06 1.061l1.793 1.793a.25.25 0 010 .353L11.97 9.489a.75.75 0 101.06 1.061zM16.5 10.77H20a.75.75 0 000-1.5h-3.5a.75.75 0 000 1.5zM1.8 12.412L.021 18.323a.5.5 0 00.479.644h2.1a.25.25 0 01.249.222l.485 4.379a.5.5 0 00.5.451h4.44a.5.5 0 00.5-.451l.486-4.379a.252.252 0 01.249-.222h2.1a.5.5 0 00.477-.65l-1.858-5.876a4.326 4.326 0 00-2.163-2.819.251.251 0 00-.337.1l-1.5 2.641a.249.249 0 01-.434 0c-1.328-2.336-.97-1.706-1.5-2.646a.25.25 0 00-.336-.1A4.288 4.288 0 001.8 12.412zM10.592 4.59a4.609 4.609 0 00-9.217 0A4.65 4.65 0 005.983 9.2a4.639 4.639 0 004.609-4.61zm-7.109.728a.249.249 0 01.173-.311A4.815 4.815 0 005.8 3.726a.249.249 0 01.358 0 4.819 4.819 0 002.153 1.281.25.25 0 01.151.119.257.257 0 01.022.192 2.6 2.6 0 01-5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});