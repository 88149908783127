define("ember-svg-jar/inlined/social-media-renren", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-renren</title><path d=\"M22.82 19.8a.5.5 0 00-.23-.32C14.86 14.72 14.86 8.55 14.86.72a.5.5 0 00-.41-.49 13 13 0 00-4.9 0 .5.5 0 00-.41.49c0 7.82 0 14-7.74 18.76a.5.5 0 00-.15.71 13 13 0 003.87 3.73.5.5 0 00.53 0A17.85 17.85 0 0012 17.21a18 18 0 006.35 6.72.5.5 0 00.53 0 13 13 0 003.87-3.73.5.5 0 00.07-.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});