define("ember-svg-jar/inlined/seo-search-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seo-search-settings</title><path d=\"M19.345 16.517a.251.251 0 01-.033-.313 10.508 10.508 0 10-3.112 3.108.25.25 0 01.313.034l4.069 4.068a2 2 0 102.828-2.828zM10.5 19a8.5 8.5 0 118.5-8.5 8.509 8.509 0 01-8.5 8.5z\"/><path d=\"M15.167 9.017a1.109 1.109 0 00-1.055-1.837l-1.219.283a.822.822 0 01-.972-.564l-.366-1.2a1.1 1.1 0 00-2.11 0L9.079 6.9a.821.821 0 01-.971.564L6.889 7.18a1.109 1.109 0 00-1.055 1.837l.853.92a.83.83 0 010 1.127l-.853.92a1.109 1.109 0 001.055 1.837l1.219-.283a.821.821 0 01.971.564l.366 1.2a1.1 1.1 0 002.11 0l.366-1.2a.822.822 0 01.972-.564l1.219.283a1.109 1.109 0 001.055-1.837l-.853-.92a.828.828 0 010-1.127zM10.5 11.742a1.242 1.242 0 111.242-1.242 1.241 1.241 0 01-1.242 1.242z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});