define("ember-svg-jar/inlined/people-man-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-3</title><path d=\"M16.734 2.507a.253.253 0 01-.192-.109 5.5 5.5 0 00-9.085 0 .253.253 0 01-.192.109 4 4 0 00-3 6.346.251.251 0 01.027.247 3.487 3.487 0 002.076 4.7.247.247 0 01.148.135 6 6 0 0010.968 0 .247.247 0 01.148-.135 3.487 3.487 0 002.076-4.7.249.249 0 01.027-.247 4 4 0 00-3-6.346zM12 15.5a4 4 0 01-4-4V9.74a.25.25 0 01.231-.249 2.991 2.991 0 001.546-.572.252.252 0 01.286-.008 3.635 3.635 0 003.874 0 .252.252 0 01.286.008 2.991 2.991 0 001.546.572.251.251 0 01.231.249v1.76a4 4 0 01-4 4z\"/><path d=\"M23.5 21.894a2.464 2.464 0 00-.967-1.977 14.264 14.264 0 00-5.908-2.481.5.5 0 00-.552.279A4.557 4.557 0 0112 20.5a4.557 4.557 0 01-4.073-2.785.5.5 0 00-.552-.279 14.264 14.264 0 00-5.908 2.481A2.464 2.464 0 00.5 21.894V23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5z\"/><circle cx=\"14\" cy=\"11\" r=\"1\"/><circle cx=\"10\" cy=\"11\" r=\"1\"/><path d=\"M13.077 13.112h-2a.639.639 0 00-.3 1.2 2.31 2.31 0 00.435.18 2.99 2.99 0 00.867.126h.006a2.976 2.976 0 00.863-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});