define("ember-svg-jar/inlined/dislike-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dislike-2-alternate</title><path d=\"M.244 11.826a2.749 2.749 0 002.63 3.551h5.58a1.249 1.249 0 011.127 1.788l-1.428 2.993a2.425 2.425 0 00.42 2.308 2.464 2.464 0 003.926-.132l4.89-6.928a1.253 1.253 0 011.021-.529.464.464 0 00.464-.464V3.1a.5.5 0 00-.282-.45C14.891.86 13.663.623 10.65.623c-.347 0-2.32.02-3.041.02-2.68 0-4.463 1.557-5.456 4.779l-1.9 6.369zM20.374 14.877h.026a1.5 1.5 0 001.474 1.25h1.5a.5.5 0 00.5-.5v-14a.5.5 0 00-.5-.5h-1.5a1.5 1.5 0 00-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});