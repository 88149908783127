define("ember-svg-jar/inlined/meeting-team-monitor-woman-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-team-monitor-woman-1</title><circle cx=\"14.33\" cy=\"4.999\" r=\"2\"/><path d=\"M11.507 11h5.647a.5.5 0 00.47-.683 3.494 3.494 0 00-6.515-.168.253.253 0 00.01.229 5.865 5.865 0 01.276.549.12.12 0 00.112.073z\"/><path d=\"M22 0H6a2 2 0 00-2 2v4.5a1 1 0 002 0v-4a.5.5 0 01.5-.5h15a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-8a1 1 0 00-.6 1.8.249.249 0 01.1.2v2a1 1 0 001 1h3.5a1 1 0 000-2h-2.25a.249.249 0 01-.25-.25v-.5a.25.25 0 01.25-.25H22a2 2 0 002-2V2a2 2 0 00-2-2zM9.257 18.022a6.351 6.351 0 00-.51-.252.25.25 0 00-.311.1l-2.012 3.173a.25.25 0 01-.422 0l-1.995-3.152a.251.251 0 00-.314-.094A6.267 6.267 0 000 23.5a.5.5 0 00.5.5H12a.5.5 0 00.5-.5 6.255 6.255 0 00-3.243-5.478z\"/><path d=\"M3.562 17.189a4.725 4.725 0 005.43-.2c.094-.072.185-.146.274-.224a.248.248 0 01.205-.06.251.251 0 01.176.121 4.132 4.132 0 00.406.573c.078.092.159.18.245.265a1 1 0 101.4-1.424 2.1 2.1 0 01-.4-.545 5.446 5.446 0 01-.435-2.481 4.737 4.737 0 00-9.474 0 5.429 5.429 0 01-.429 2.479 2.058 2.058 0 01-.4.545 1 1 0 001.405 1.424c.085-.085.167-.173.245-.265a4.132 4.132 0 00.406-.573.249.249 0 01.384-.061 4.769 4.769 0 00.562.426zm2.571-1.239a2.74 2.74 0 01-2.7-2.3.249.249 0 01.206-.286 5.155 5.155 0 00.759-.182 2.977 2.977 0 00.41-.166 2.874 2.874 0 001.147-.965.247.247 0 01.2-.113.25.25 0 01.208.095 4.656 4.656 0 001.1.983 4.014 4.014 0 00.409.239 2.937 2.937 0 00.74.265.254.254 0 01.159.11.247.247 0 01.034.191 2.742 2.742 0 01-2.672 2.129z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});