define("ember-svg-jar/inlined/camping-tent-forest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camping-tent-forest</title><path d=\"M23.5 17h-5a.5.5 0 01-.5-.5v-2.16a.5.5 0 01.4-.489 7 7 0 10-2.8 0 .5.5 0 01.4.489v2.16a.5.5 0 01-.5.5H14a.5.5 0 00-.419.772l2.519 3.865a2.437 2.437 0 01.354 1.773.5.5 0 00.493.588H23.5a.5.5 0 00.5-.5v-6a.5.5 0 00-.5-.498zM17 4.5a1 1 0 00-1 1v.975a.25.25 0 01-.336.235A3.379 3.379 0 0014.5 6.5a1 1 0 000 2A1.5 1.5 0 0116 10v1.583a.249.249 0 01-.316.241 5.012 5.012 0 112.878-.081.25.25 0 01-.306-.341A2.641 2.641 0 0119.8 9.955a1 1 0 10-.594-1.91 4.059 4.059 0 00-.828.366A.25.25 0 0118 8.2V5.5a1 1 0 00-1-1zM2.26 17.772A.5.5 0 001.84 17H.5a.5.5 0 00-.5.5v2.063a.5.5 0 00.919.272z\"/><path d=\"M14.837 22.454l-5.844-8.992a.5.5 0 010-.545l1.038-1.6a.75.75 0 00-1.257-.817l-.644.991a.251.251 0 01-.419 0l-.643-.99a.749.749 0 10-1.257.816l1.039 1.6a.5.5 0 010 .545l-5.844 8.99A1 1 0 001.843 24h2.4a.5.5 0 00.426-.238l2.83-4.6a.5.5 0 01.852 0l2.831 4.6A.5.5 0 0011.6 24H14a1 1 0 00.838-1.544z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});