define("ember-svg-jar/inlined/messages-people-user-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-user-bubble</title><path d=\"M18.109 0a5.634 5.634 0 00-4.56 2.329 4.616 4.616 0 00-1.823-.373 4.808 4.808 0 00-.365 9.6 4.666 4.666 0 006.185.834 6.1 6.1 0 00.779.05C21.561 12.435 24 9.762 24 6.217A6 6 0 0018.109 0zm.216 10.435a4.016 4.016 0 01-.828-.085.986.986 0 00-.853.213A2.745 2.745 0 0112.686 10a1.008 1.008 0 00-.96-.432A2.767 2.767 0 019 6.761a2.769 2.769 0 012.725-2.8 2.646 2.646 0 011.563.512 1 1 0 001.463-.326A3.771 3.771 0 0118.109 2 3.975 3.975 0 0122 6.217c0 2.096-1.262 4.218-3.675 4.218zM3.505 13a2.75 2.75 0 10-.01 0A3.5 3.5 0 000 16.5v2a.5.5 0 00.5.5h.821a.25.25 0 01.249.225L2 23.55a.5.5 0 00.5.45h2a.5.5 0 00.5-.45l.433-4.325A.25.25 0 015.679 19H6.5a.5.5 0 00.5-.5v-2A3.5 3.5 0 003.505 13z\"/><circle cx=\"12.25\" cy=\"7.245\" r=\"1\"/><circle cx=\"15.499\" cy=\"7.245\" r=\"1\"/><circle cx=\"18.749\" cy=\"7.245\" r=\"1\"/><circle cx=\"9\" cy=\"13.75\" r=\"1.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});