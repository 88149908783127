define("ember-svg-jar/inlined/safety-911", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-911</title><path d=\"M1.764 13.767a1.593 1.593 0 002.236.19l1.644-1.541A1.594 1.594 0 005.6 10.17l-.418-.446a.251.251 0 01-.006-.334 32.183 32.183 0 014.713-4.416.249.249 0 01.332.026l.417.445a1.594 1.594 0 002.24.191L14.522 4.1a1.593 1.593 0 00-.046-2.246l-.939-1a2.449 2.449 0 00-2.993-.56A31.519 31.519 0 00.465 9.742a2.453 2.453 0 00.359 3.023zM11.276 22.006a1 1 0 100 1.994h4.653a1 1 0 000-1.994h-1.08a.248.248 0 01-.249-.249v-5.442a1.288 1.288 0 00-1.288-1.287 1.705 1.705 0 00-1.2.5l-1.54 1.539a1 1 0 001.41 1.409l.2-.2a.249.249 0 01.425.176v3.306a.249.249 0 01-.249.249zM23.9 23a1 1 0 00-1-1h-1.08a.249.249 0 01-.249-.249v-5.436a1.288 1.288 0 00-1.288-1.287 1.705 1.705 0 00-1.2.5l-1.533 1.537a1 1 0 001.409 1.409l.2-.2a.249.249 0 01.425.176v3.306a.248.248 0 01-.249.249h-1.081a1 1 0 000 1.994h4.653A1 1 0 0023.9 23zM5.461 22.006a1 1 0 000 1.994h.582a3.908 3.908 0 003.9-3.905v-1.744a3.323 3.323 0 10-3.322 3.323.116.116 0 01.038.225 1.844 1.844 0 01-.62.107zm1.164-2.326a1.329 1.329 0 111.329-1.329 1.331 1.331 0 01-1.329 1.329z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});