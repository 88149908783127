define("ember-svg-jar/inlined/smiley-tongue-1-alternate-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-tongue-1-alternate-1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM5.625 9.9a1 1 0 01-1.832-.8 3.5 3.5 0 016.414 0 1 1 0 01-.516 1.316.973.973 0 01-.4.084 1 1 0 01-.917-.6 1.5 1.5 0 00-2.75 0zm8.167-.8a3.5 3.5 0 016.415 0 1 1 0 11-1.832.8 1.5 1.5 0 00-2.751 0 1 1 0 01-1.832-.8zM13.429 23a3.577 3.577 0 01-3.572-3.571 6.336 6.336 0 00-.343-2.269.252.252 0 00-.234-.16H7a1 1 0 010-2h10a1 1 0 010 2 .229.229 0 00-.222.278A9.584 9.584 0 0117 19.429 3.576 3.576 0 0113.429 23z\"/><path d=\"M12.75 19.432a.751.751 0 00.744.662.677.677 0 00.089-.006.75.75 0 00.657-.832 9.272 9.272 0 00-.474-2.091.25.25 0 00-.235-.165h-1.079a.25.25 0 00-.209.114.247.247 0 00-.019.238 7.115 7.115 0 01.526 2.08z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});