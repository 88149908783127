define("ember-svg-jar/inlined/wild-bird-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wild-bird-1</title><path d=\"M12.16 20.773l-.371 1.113a.75.75 0 001.423.475l.357-1.072 2.554-.425a.751.751 0 10-.246-1.481l-2.566.428-.426-.533a8.97 8.97 0 01-1.284.8zM24 13.061a10.815 10.815 0 00-3.047-5.876 5.843 5.843 0 01-1.413 2.551 12.887 12.887 0 013.535 3.645.5.5 0 00.925-.32zM7.18 19.551c.114.006.228.008.341.008a7.5 7.5 0 007.47-6.963 3.34 3.34 0 011.95-2.837 4.5 4.5 0 10-4.67-7.58 4.591 4.591 0 00-.8.883 3.652 3.652 0 01-3.35 1.524A7.5 7.5 0 00.917 15.64a7.567 7.567 0 004.777 3.694l-.343 1.715-1.087.363a.75.75 0 10.474 1.423l1.323-.441 2.817.47a.761.761 0 00.863-.618.752.752 0 00-.617-.863l-2.236-.372zm9.07-14.178a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});