define("ember-svg-jar/inlined/navigation-up-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-up-1</title><path d=\"M23.382 9.744l-9.463-6.582a3.371 3.371 0 00-3.838 0L.618 9.744a1.44 1.44 0 001.644 2.365l9.464-6.584a.482.482 0 01.548 0l9.464 6.583a1.44 1.44 0 001.644-2.365z\"/><path d=\"M1.988 21.261l9.464-6.584a.964.964 0 011.1 0l9.464 6.584a.958.958 0 00.548.172.944.944 0 00.444-.11.959.959 0 00.516-.85v-3.215a.961.961 0 00-.412-.788l-9.468-6.583a2.892 2.892 0 00-3.289 0L.892 16.47a.961.961 0 00-.412.788v3.215a.961.961 0 001.508.788z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});