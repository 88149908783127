define("ember-keyboard/services/keyboard", ["exports", "@ember/service", "@ember/array", "@ember/application", "@ember/object", "@ember/object/computed", "@ember/runloop", "ember-keyboard/listeners/key-events", "ember-keyboard/utils/handle-key-event"], function (_exports, _service, _array, _application, _object, _computed, _runloop, _keyEvents, _handleKeyEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _service.default.extend({
    isPropagationEnabled: false,
    registeredResponders: (0, _object.computed)(() => (0, _array.A)()),
    activeResponders: (0, _computed.filterBy)('registeredResponders', 'keyboardActivated'),
    sortedRespondersSortDefinition: (0, _object.computed)('isPropagationEnabled', function () {
      return (0, _object.get)(this, 'isPropagationEnabled') ? ['keyboardPriority:desc'] : ['keyboardFirstResponder:desc', 'keyboardPriority:desc'];
    }),
    sortedResponders: (0, _computed.sort)('activeResponders', 'sortedRespondersSortDefinition'),
    firstResponders: (0, _computed.filterBy)('sortedResponders', 'keyboardFirstResponder'),
    normalResponders: (0, _computed.filter)('sortedResponders.@each.keyboardFirstResponder', responder => !(0, _object.get)(responder, 'keyboardFirstResponder')),
    init(...args) {
      this._super(...args);
      if (typeof FastBoot !== 'undefined') {
        return;
      }
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment') || {};
      const isPropagationEnabled = Boolean((0, _object.get)(config, 'emberKeyboard.propagation'));
      (0, _object.set)(this, 'isPropagationEnabled', isPropagationEnabled);
      this._boundRespond = (0, _runloop.bind)(this, this._respond);
      this._listeners = (0, _object.get)(config, 'emberKeyboard.listeners') || ['keyUp', 'keyDown', 'keyPress'];
      this._listeners = this._listeners.map(listener => listener.toLowerCase());
      this._listeners.forEach(type => {
        document.addEventListener(type, this._boundRespond);
      });
    },
    willDestroy(...args) {
      this._super(...args);
      if (typeof FastBoot !== 'undefined') {
        return;
      }
      this._listeners.forEach(type => {
        document.removeEventListener(type, this._boundRespond);
      });
    },
    _respond(event) {
      (0, _runloop.run)(() => {
        if ((0, _object.get)(this, 'isPropagationEnabled')) {
          (0, _handleKeyEvent.handleKeyEventWithPropagation)(event, (0, _object.getProperties)(this, 'firstResponders', 'normalResponders'));
        } else {
          (0, _handleKeyEvent.handleKeyEventWithLaxPriorities)(event, (0, _object.get)(this, 'sortedResponders'));
        }
      });
    },
    register(responder) {
      (0, _object.get)(this, 'registeredResponders').pushObject(responder);
    },
    unregister(responder) {
      (0, _object.get)(this, 'registeredResponders').removeObject(responder);
    },
    keyDown(...args) {
      return (0, _keyEvents.keyDown)(...args);
    },
    keyPress(...args) {
      return (0, _keyEvents.keyPress)(...args);
    },
    keyUp(...args) {
      return (0, _keyEvents.keyUp)(...args);
    }
  });
});