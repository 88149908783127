define("ember-svg-jar/inlined/fish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fish</title><path d=\"M22 10.5c-1.111 0-2.186-2.915-3.6-4.8C17.084 3.944 14.243 1 10 1H5a1 1 0 000 2h1.905C5.518 5.636 5.068 8.753 4.771 8.882c-.153.056-.626-.209-.912-.371A2.917 2.917 0 002.5 8C1.1 8 0 9.757 0 12s1.1 4 2.5 4a2.917 2.917 0 001.359-.511c.286-.162.76-.427.912-.371.265.115.794 3.334 2.134 5.882H5a1 1 0 000 2h5c4.241 0 7.084-2.944 8.4-4.7 1.384-1.845 2.5-4.8 3.6-4.8 1.582 0 2-.981 2-1.5s-.418-1.5-2-1.5zm-10.237 3.69l2.321-1.481a.75.75 0 11.832 1.248l-2 1.333a1.7 1.7 0 01-1.86.223C9.844 14.864 9.75 12.66 9.75 12s.094-2.865 1.306-3.514a1.724 1.724 0 011.86.223l2 1.333a.75.75 0 11-.832 1.248l-2.327-1.478A3.713 3.713 0 0011.25 12a3.669 3.669 0 00.513 2.19zM18 12a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});