define("ember-svg-jar/inlined/temperature-thermometer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>temperature-thermometer</title><path d=\"M16.56 14.1a.248.248 0 01-.06-.162V4.5a4.5 4.5 0 00-9 0v9.438a.248.248 0 01-.06.162A5.941 5.941 0 006 18a6 6 0 0012 0 5.941 5.941 0 00-1.44-3.9zM12 22a4 4 0 01-2.8-6.856 1 1 0 00.3-.714V4.5a2.5 2.5 0 015 0v9.93a1 1 0 00.3.714A4 4 0 0112 22z\"/><path d=\"M13.111 16.338A.253.253 0 0113 16.13V7a1 1 0 00-2 0v9.13a.253.253 0 01-.111.208 2 2 0 102.222 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});