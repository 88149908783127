define("ember-svg-jar/inlined/tools-axe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-axe</title><path d=\"M15.473 11.554a14.914 14.914 0 00-2.069.253.5.5 0 00-.4.49V22.5a1.5 1.5 0 003 0V12.054a.5.5 0 00-.527-.5zM8.514.305a1 1 0 00-1.3-.118A7.789 7.789 0 004 6.524 7.236 7.236 0 007.006 12.5a1 1 0 001.284-.1 4.172 4.172 0 01.652-.489.5.5 0 00.156-.689 9.362 9.362 0 01.093-9.8.5.5 0 00-.139-.69 3.519 3.519 0 01-.538-.427z\"/><path d=\"M19.659 2.719a1 1 0 00-.791-.239 16.166 16.166 0 01-7.658-.689.5.5 0 00-.575.178 7.864 7.864 0 00-.082 8.728.5.5 0 00.575.185 16.649 16.649 0 017.741-.685A1 1 0 0020 9.205V3.471a1 1 0 00-.341-.752z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});