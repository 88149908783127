define("ember-svg-jar/inlined/gardening-watering-pipe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gardening-watering-pipe</title><path d=\"M23.11.954L19.055.5a.493.493 0 00-.388.124A.5.5 0 0018.5 1v3a.5.5 0 00.5.5.493.493 0 00.055 0l4.055-.451a1 1 0 00.89-.996V1.947a1 1 0 00-.89-.993z\"/><path d=\"M15.25 6.5H11a7 7 0 000 14h3.75a5.25 5.25 0 000-10.5H11.5a3.5 3.5 0 000 7h3a1 1 0 000-2h-3a1.5 1.5 0 010-3h3.25a3.25 3.25 0 010 6.5H11a5 5 0 010-10h4.25a6.75 6.75 0 010 13.5h-4A9.25 9.25 0 019.887 3.6a.5.5 0 01.5.228A2.5 2.5 0 0012.5 5h4a1 1 0 001-1V1a1 1 0 00-1-1h-4a2.5 2.5 0 00-2.2 1.32.5.5 0 01-.382.259A11.25 11.25 0 0011.25 24h4a8.75 8.75 0 000-17.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});