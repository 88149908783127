define("ember-svg-jar/inlined/treasure-chest-open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>treasure-chest-open</title><path d=\"M5.25 14.005H.5a.5.5 0 00-.5.5v7a2.5 2.5 0 002.5 2.5h2.75a.5.5 0 00.5-.5v-9a.5.5 0 00-.5-.5zM16.25 14.005H15a.5.5 0 00-.5.5v1a2.5 2.5 0 11-5 0v-1a.5.5 0 00-.5-.5H7.75a.5.5 0 00-.5.5v9a.5.5 0 00.5.5h8.5a.5.5 0 00.5-.5v-9a.5.5 0 00-.5-.5zM23.5 14.005h-4.75a.5.5 0 00-.5.5v9a.5.5 0 00.5.5h2.75a2.5 2.5 0 002.5-2.5v-7a.5.5 0 00-.5-.5z\"/><circle cx=\"12\" cy=\"15.508\" r=\"1\"/><path d=\"M12 3.5a1 1 0 001-1V1a1 1 0 10-2 0v1.5a1 1 0 001 1zM20.5 6.172a1 1 0 00.757-.346l.981-1.135a1 1 0 00-1.514-1.308l-.981 1.135a1 1 0 00.757 1.654zM2.744 5.826a1 1 0 001.513-1.308l-.981-1.135a1 1 0 00-1.513 1.308zM2 12.007a.5.5 0 00.5.5h19a.5.5 0 00.5-.5 3.23 3.23 0 00-.9-2.419c-.346-.336-1.144-1.019-3.381-1.082a.5.5 0 01-.449-.311c-.72-1.762-2.525-2.688-5.27-2.688S7.451 6.433 6.732 8.2a.5.5 0 01-.45.311c-2.236.062-3.031.742-3.38 1.081A3.231 3.231 0 002 12.007z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});