define("ember-svg-jar/inlined/love-it", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it</title><path d=\"M23.3 5.076a6.582 6.582 0 00-10.446-1.71L12 4.147l-.827-.753a6.522 6.522 0 00-5.688-1.806A6.472 6.472 0 00.7 5.075a6.4 6.4 0 001.21 7.469l9.373 9.656a1 1 0 001.434 0l9.36-9.638A6.414 6.414 0 0023.3 5.076z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});