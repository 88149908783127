define("ember-svg-jar/inlined/expand-horizontal-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>expand-horizontal-4</title><path d=\"M.25 5.794V18.2a1.25 1.25 0 002.5 0V5.794a1.25 1.25 0 00-2.5 0zM22.5 19.454a1.25 1.25 0 001.25-1.25V5.794a1.25 1.25 0 00-2.5 0V18.2a1.25 1.25 0 001.25 1.254zM16.39 15.787a1 1 0 001.09-.217l2.863-2.865a1 1 0 000-1.414L17.48 8.428a1 1 0 00-1.707.707V10.5a.249.249 0 01-.25.25H8.477a.249.249 0 01-.25-.25V9.135a1 1 0 00-1.707-.707l-2.863 2.863a1 1 0 000 1.414L6.52 15.57a1 1 0 001.707-.707V13.5a.25.25 0 01.25-.25h7.046a.25.25 0 01.25.25v1.365a1 1 0 00.617.922z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});