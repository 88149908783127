define("ember-svg-jar/inlined/make-up-hand-mirror", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>make-up-hand-mirror</title><path d=\"M21.222 2.777A9.493 9.493 0 005.776 13.208a.5.5 0 01-.136.578l-4.322 3.657a3.717 3.717 0 105.238 5.239l3.657-4.322a.5.5 0 01.579-.137 9.493 9.493 0 0010.43-15.446zM4.976 21.144a1.5 1.5 0 11-1.061-2.56 1.5 1.5 0 011.06 2.56zM19.1 14.08a6.493 6.493 0 110-9.183 6.493 6.493 0 010 9.183z\"/><path d=\"M15.938 4.854A5.64 5.64 0 0010.893 6.4a1 1 0 101.414 1.414 3.662 3.662 0 013.258-1 1 1 0 10.373-1.964z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});