define("ember-svg-jar/inlined/real-estate-action-building-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-building-pin</title><path d=\"M6.724 6.053l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 000 6.5V14a.5.5 0 00.5.5H2a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5A.5.5 0 007 14V6.5a.5.5 0 00-.276-.447zM5 10.5H2a.5.5 0 010-1h3a.5.5 0 010 1zM5 8H2a.5.5 0 010-1h3a.5.5 0 010 1zM14.822 11.032a.5.5 0 00.178-.383V3a.5.5 0 00-.308-.462l-6-2.5A.5.5 0 008 .5V14a.5.5 0 00.5.5h3.8a.5.5 0 00.479-.357 6.512 6.512 0 012.043-3.111zM13 10.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zM13 8h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zM19 11a5.006 5.006 0 00-5 5c0 3.171 4.457 7.664 4.646 7.853a.5.5 0 00.708 0C19.543 23.664 24 19.171 24 16a5.006 5.006 0 00-5-5zm0 6.75A1.75 1.75 0 1120.75 16 1.752 1.752 0 0119 17.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});