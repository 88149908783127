define("ember-svg-jar/inlined/archive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>archive</title><path d=\"M21.5 2.5h-19a2 2 0 00-2 2v3a1 1 0 001 1h21a1 1 0 001-1v-3a2 2 0 00-2-2zM21.5 10h-19a1 1 0 00-1 1v8.5a2 2 0 002 2h17a2 2 0 002-2V11a1 1 0 00-1-1zm-6.25 3.5A1.252 1.252 0 0114 14.75h-4a1.25 1.25 0 010-2.5h4a1.252 1.252 0 011.25 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});