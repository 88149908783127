define("ember-svg-jar/inlined/laptop-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-share</title><path d=\"M10.142 15.4a2.737 2.737 0 01-2.091-1.552.492.492 0 00-.47-.36H.5a.478.478 0 00-.41.22.467.467 0 00-.05.47 5.43 5.43 0 005 3.31h4.327a.249.249 0 00.244-.194 4.179 4.179 0 01.68-1.5.25.25 0 00-.149-.391zM3.5 12a1 1 0 001-1V3a1 1 0 011-1h12a1 1 0 011 1v7.02a.25.25 0 00.391.206 4.082 4.082 0 011.414-.617.251.251 0 00.2-.244V3a3.009 3.009 0 00-3-3H5.5a3.009 3.009 0 00-3 3v8a1 1 0 001 1zM21.251 18.5a2.725 2.725 0 00-1.887.76.251.251 0 01-.266.052l-2.445-.979a.25.25 0 01-.157-.214c0-.044-.008-.1-.017-.165a.25.25 0 01.119-.247l2.84-1.7a.251.251 0 01.27.008 2.7 2.7 0 001.543.488 2.75 2.75 0 10-2.75-2.75 2.214 2.214 0 00.021.295.25.25 0 01-.119.247L15.564 16a.251.251 0 01-.271-.009 2.708 2.708 0 00-1.542-.487 2.75 2.75 0 101.887 4.739.251.251 0 01.265-.051l2.577 1.028a.034.034 0 01.021.031 2.75 2.75 0 102.75-2.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});