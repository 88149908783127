define("ember-svg-jar/inlined/casino-diamond-bag-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>casino-diamond-bag_1</title><path d=\"M13.311 2.229A1.5 1.5 0 0012 0H6.4a1.5 1.5 0 00-1.29 2.263l1.673 2.514A.5.5 0 007.2 5h4a.5.5 0 00.416-.223zM6.824 17.319a3.056 3.056 0 01-.258-3.866C6.615 13.384 9.9 9.624 9.9 9.624A3.016 3.016 0 0112.245 8.5H13.7a.25.25 0 00.165-.438 8.545 8.545 0 00-2.311-1.484 1 1 0 00-.388-.078H6.837a1 1 0 00-.393.08C3.3 7.921 0 12.239 0 17c0 4.448 3.28 7 9 7a15.073 15.073 0 003.232-.323.249.249 0 00.129-.414z\"/><path d=\"M23.569 16.307a1.564 1.564 0 00.142-1.985c-.02-.027-3.229-3.687-3.229-3.687A1.516 1.516 0 0019.255 10h-7.011a1.462 1.462 0 00-1.167.559l-3.289 3.763A1.561 1.561 0 007.92 16.3l6.756 7.251a1.5 1.5 0 002.147 0l.018-.02zm-11.176-4.222a.251.251 0 01.188-.085h6.331a.25.25 0 01.188.085l1.752 2a.251.251 0 01-.189.415h-9.83a.251.251 0 01-.189-.415zm3.539 9.485a.248.248 0 01-.365 0l-4.332-4.65a.25.25 0 01.183-.42h8.663a.249.249 0 01.183.42z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});