define("ember-svg-jar/inlined/single-woman-monitor.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-monitor</title><path d=\"M24 2.5A1.5 1.5 0 0022.5 1h-21A1.5 1.5 0 000 2.5V17a1.5 1.5 0 001.5 1.5h9a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5H4a1 1 0 000 2h16a1 1 0 000-2h-6.5a.5.5 0 01-.5-.5V19a.5.5 0 01.5-.5h9A1.5 1.5 0 0024 17zM12 15.268H6.74a.24.24 0 01-.177-.08A.245.245 0 016.5 15a5.047 5.047 0 01.465-1.717c.337-.689 1.749-1.167 3.522-1.837a.488.488 0 00.313-.458v-.2a.249.249 0 00-.251-.25 3.737 3.737 0 01-2.215-.567.247.247 0 01-.05-.371c.3-.338.51-.746.6-2.086a3.12 3.12 0 116.234 0c.089 1.34.3 1.748.6 2.086a.247.247 0 01-.051.372 3.7 3.7 0 01-2.216.556.25.25 0 00-.25.25v.207a.488.488 0 00.313.458c1.773.67 3.184 1.148 3.522 1.837A5.047 5.047 0 0117.5 15a.251.251 0 01-.062.187.24.24 0 01-.177.08z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});