define("ember-svg-jar/inlined/messages-bubble-search-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-search-1-alternate</title><path d=\"M9.581 15.439a.17.17 0 00-.288-.146L7 17.586V15.56a1 1 0 00-.664-.942A6.493 6.493 0 018.5 2h7A6.508 6.508 0 0122 8.5a6.435 6.435 0 01-.622 2.758.252.252 0 00.05.285 6.982 6.982 0 01.99 1.228.254.254 0 00.216.116.25.25 0 00.211-.125A8.487 8.487 0 0015.5 0h-7A8.492 8.492 0 005 16.232V20a1 1 0 001.707.707l2.825-2.825a.253.253 0 00.07-.219A6.853 6.853 0 019.5 16.5a7 7 0 01.081-1.061z\"/><path d=\"M21.173 19.758a.25.25 0 01-.034-.311 5.507 5.507 0 10-1.691 1.692.25.25 0 01.311.034l2.534 2.534a1 1 0 001.414-1.414zM13 16.5a3.5 3.5 0 113.5 3.5 3.5 3.5 0 01-3.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});