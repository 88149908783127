define("ember-svg-jar/inlined/legal-judge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>legal-judge</title><path d=\"M11.25 17.462v2a.75.75 0 001.5 0v-2a.75.75 0 00-1.5 0z\"/><path d=\"M22.482 18.878a16.488 16.488 0 00-8.282-2.886.5.5 0 00-.522.653l1.283 3.928a1.155 1.155 0 01-.247 1.091.863.863 0 01-1.2.1 2.633 2.633 0 00-3.008 0 .864.864 0 01-1.2-.1 1.147 1.147 0 01-.253-1.071l1.288-3.948a.5.5 0 00-.521-.653 16.477 16.477 0 00-8.287 2.886A2.532 2.532 0 00.5 20.918v2.544a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-2.544a2.532 2.532 0 00-1.018-2.04zM10.959 11.368a.75.75 0 00-.918 1.187 3.249 3.249 0 003.918 0 .75.75 0 00-.918-1.187 1.863 1.863 0 01-2.082 0z\"/><path d=\"M3 10.962a2 2 0 00.755 1.564.249.249 0 01.058.324 2.17 2.17 0 00-.313 1.112 2 2 0 004 0 .161.161 0 01.28-.109 5.719 5.719 0 008.44 0 .161.161 0 01.28.109 2 2 0 104 0 2.17 2.17 0 00-.313-1.112.249.249 0 01.058-.324 2 2 0 00.244-2.9.249.249 0 010-.333 2 2 0 00-.918-3.25.251.251 0 01-.173-.186A7.576 7.576 0 0013.071.043a.5.5 0 00-.4.118.5.5 0 00-.171.377v5.324a.5.5 0 00.167.374 5.777 5.777 0 003.354 1.451.25.25 0 01.229.25v2.025a4.25 4.25 0 01-8.5 0V7.937a.251.251 0 01.229-.25 5.777 5.777 0 003.354-1.451.5.5 0 00.167-.374V.538a.5.5 0 00-.571-.5A7.576 7.576 0 004.6 5.859a.251.251 0 01-.173.186A2 2 0 003.511 9.3a.249.249 0 010 .333A1.992 1.992 0 003 10.962z\"/><circle cx=\"14\" cy=\"8.962\" r=\"1\"/><circle cx=\"10\" cy=\"8.962\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});