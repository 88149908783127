define("ember-svg-jar/inlined/standing-lamp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>standing-lamp</title><path d=\"M14.938 23a.99.99 0 00-.98-1h-.734a.248.248 0 01-.245-.25v-11.5a.248.248 0 01.245-.25h.245a.99.99 0 00.979-1v-.5a.5.5 0 00-.49-.5H10.04a.5.5 0 00-.49.5V9a.991.991 0 00.98 1h.245a.248.248 0 01.245.25v11.5a.248.248 0 01-.245.25h-.735a1 1 0 000 2h3.918a.99.99 0 00.98-1zM16.032.414A.978.978 0 0015.238 0H8.761a.978.978 0 00-.8.416 1.028 1.028 0 00-.129.915L9.58 6.173a.492.492 0 00.46.327h3.918a.491.491 0 00.46-.327l1.745-4.842v-.015a1.02 1.02 0 00-.131-.902z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});