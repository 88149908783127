define("ember-svg-jar/inlined/diagram-steady-and-fall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-steady-and-fall</title><path d=\"M0 9.5A5.506 5.506 0 005.5 15h2.25a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25H6.5a1.5 1.5 0 00-1.061 2.561l3 3a1.515 1.515 0 00.492.326.209.209 0 00.023.008 1.464 1.464 0 001.094 0 .209.209 0 00.023-.008 1.515 1.515 0 00.492-.326l3-3A1.5 1.5 0 0012.5 18h-1.25a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h6.5a.25.25 0 01.25.25v1.25a1.5 1.5 0 002.561 1.061l3-3a1.515 1.515 0 00.326-.492l.007-.023a1.452 1.452 0 000-1.094l-.007-.023a1.515 1.515 0 00-.326-.492l-3-3A1.5 1.5 0 0018 10.5v1.25a.25.25 0 01-.25.25H5.5A2.5 2.5 0 013 9.5v-8a1.5 1.5 0 00-3 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});