define("ember-svg-jar/inlined/picture-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M23.5 0H.5a.5.5 0 00-.5.5v23a.5.5 0 00.5.5h23a.5.5 0 00.5-.5V.5a.5.5 0 00-.5-.5zM23 1v16h-.691l-3.398-6.795a2.144 2.144 0 00-1.876-1.203 2.13 2.13 0 00-1.952 1.078l-2.638 4.522C11.668 13.664 10.007 12 8 12c-2.465 0-4.311 3.801-4.832 5H1V1h22zm-1.809 16H4.268c.726-1.531 2.216-4 3.732-4 2.199 0 4.065 2.75 4.084 2.778.095.143.246.21.431.222a.5.5 0 00.417-.248l3.015-5.168c.221-.379.618-.6 1.055-.582.439.014.819.257 1.014.649L21.191 17zM1 23v-5h22v5H1z\"/><path d=\"M6.5 9h3c1.654 0 3-1.346 3-3s-1.346-3-3-3a2.98 2.98 0 00-2.825 2.009C5.519 4.896 4.5 5.834 4.5 7c0 1.103.897 2 2 2zm-.01-3c.124 0 .249.024.37.071a.503.503 0 00.674-.387A1.986 1.986 0 019.5 4c1.103 0 2 .897 2 2s-.897 2-2 2h-3c-.551 0-1-.449-1-1s.444-1 .99-1z\"/><g><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});