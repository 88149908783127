define("ember-svg-jar/inlined/measure-caliber-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>measure-caliber-1</title><path d=\"M18.5 0h-13A2.5 2.5 0 003 2.5v1a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-1A2.5 2.5 0 0018.5 0zM20.5 5.5h-17A.5.5 0 003 6v17a1 1 0 001 1h16a1 1 0 001-1V6a.5.5 0 00-.5-.5zm-5.42 3.856a.5.5 0 01.66-.04 5.486 5.486 0 01.5.442 6.08 6.08 0 01.442.5.5.5 0 01-.038.665L15.166 12.4a.5.5 0 01-.707 0l-.857-.857a.5.5 0 010-.707zm-1.68 4.81l-1.21 1.21a.5.5 0 01-.707 0l-.857-.857a.5.5 0 010-.707l1.21-1.21a.5.5 0 01.707 0l.857.857a.5.5 0 010 .707zm-6.932 1.3a.5.5 0 01-.344-.386 5.948 5.948 0 016.963-6.963.5.5 0 01.262.845l-1.69 1.69-4.689 4.69a.5.5 0 01-.504.129zm2.455 3.173a.5.5 0 01-.66.041 5.5 5.5 0 01-.5-.442 5.621 5.621 0 01-.442-.5.5.5 0 01.042-.661l1.5-1.5a.5.5 0 01.707 0l.858.857a.5.5 0 010 .707zm7.322-.4A5.984 5.984 0 0112 20a5.506 5.506 0 01-1.1-.114.5.5 0 01-.254-.844l1.718-1.718 4.684-4.685a.5.5 0 01.845.26A5.871 5.871 0 0118 14a5.963 5.963 0 01-1.757 4.243z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});