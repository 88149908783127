define("ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/section-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MARKUP_SECTION_TYPE = _exports.LIST_SECTION_TYPE = _exports.IMAGE_SECTION_TYPE = _exports.CARD_SECTION_TYPE = void 0;
  const MARKUP_SECTION_TYPE = _exports.MARKUP_SECTION_TYPE = 1;
  const IMAGE_SECTION_TYPE = _exports.IMAGE_SECTION_TYPE = 2;
  const LIST_SECTION_TYPE = _exports.LIST_SECTION_TYPE = 3;
  const CARD_SECTION_TYPE = _exports.CARD_SECTION_TYPE = 10;
});