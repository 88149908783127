define("ember-svg-jar/inlined/navigation-down-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-down-circle</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm5.207 13.707l-4.5 4.5a1 1 0 01-1.414 0l-4.5-4.5a1 1 0 011.414-1.414l2.366 2.366A.25.25 0 0011 14.48V6a1 1 0 012 0v8.48a.25.25 0 00.427.177l2.366-2.366a1 1 0 011.414 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});