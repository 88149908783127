define("ember-svg-jar/inlined/project-blueprint-create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>project-blueprint-create</title><path d=\"M24 5.249a1 1 0 00-1-1h-.242a.251.251 0 00-.217.125 3.365 3.365 0 01-.54.7l-2.173 2.175H20a1 1 0 011 1v3a.25.25 0 01-.25.25H16.5a.75.75 0 000 1.5h4.25a.25.25 0 01.25.25v6a1 1 0 01-1 1h-1.5a.25.25 0 01-.25-.25v-4.25A.75.75 0 0017.5 15h-5a.75.75 0 00-.75.75V20a.25.25 0 01-.25.25H9a.25.25 0 01-.25-.25v-3.75A1.277 1.277 0 007.45 15h-4.2a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25H8a.75.75 0 000-1.5H3.25a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H8a.75.75 0 000-1.5H3.25A.25.25 0 013 8.249a1 1 0 011-1h7.156a.25.25 0 00.245-.2l.138-.691a2 2 0 01.547-1.022l.659-.659a.25.25 0 00-.177-.427H1a1 1 0 00-1 1v17a1 1 0 001 1h22a1 1 0 001-1zM16.75 16.5v1h-3.5v-1zm-3.5 2.5h3.5v1.25h-3.5zM7 16.5a.25.25 0 01.25.25V20a.25.25 0 01-.25.25H4a1 1 0 01-1-1v-2.5a.25.25 0 01.25-.25z\"/><path d=\"M13.146 6.4a.5.5 0 00-.136.256l-.5 2.5a.5.5 0 00.136.451.5.5 0 00.452.137l2.5-.5a.505.505 0 00.256-.137l5.086-5.091a1.914 1.914 0 00-2.707-2.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});