define("ember-svg-jar/inlined/office-file-ppt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-file-ppt</title><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.425A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.248A.25.25 0 0014.75 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.248A.25.25 0 0021.75 7zM7 16.119h-.623a.252.252 0 00-.252.252v2.123a.625.625 0 01-1.25 0v-6a.625.625 0 01.625-.625H7a2.125 2.125 0 010 4.25zm5 0h-.623a.252.252 0 00-.252.252v2.123a.625.625 0 01-1.25 0v-6a.625.625 0 01.625-.625H12a2.125 2.125 0 010 4.25zm7.5-3h-1.126a.249.249 0 00-.249.249V18.5a.625.625 0 11-1.25 0v-5.128a.249.249 0 00-.249-.249H15.5a.625.625 0 010-1.25h4a.625.625 0 010 1.25z\"/><path d=\"M7 13.123h-.623a.252.252 0 00-.252.252v1.246a.252.252 0 00.252.252H7a.875.875 0 000-1.75zM12 13.123h-.623a.252.252 0 00-.252.252v1.246a.252.252 0 00.252.252H12a.875.875 0 000-1.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});