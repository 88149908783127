define("ember-svg-jar/inlined/outdoors-bird-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-bird-house</title><path d=\"M19 12a3 3 0 00-2.981 2.671.5.5 0 01-.263.387 8.967 8.967 0 00-2.324 1.775.25.25 0 01-.432-.171V11.1a.5.5 0 01.5-.5H15a1.1 1.1 0 001.1-1.1V6.3a.5.5 0 01.5-.5H18a.5.5 0 00.312-.89l-6-4.8a.5.5 0 00-.625 0l-6 4.8A.5.5 0 006 5.8h1.4a.5.5 0 01.5.5v3.2A1.1 1.1 0 009 10.6h1.5a.5.5 0 01.5.5v3.693a.25.25 0 01-.419.183 7.438 7.438 0 00-1.826-1.237.5.5 0 01-.28-.39 3.249 3.249 0 10-.775 2.534.5.5 0 01.634-.1A5.494 5.494 0 0111 20.5V23a1 1 0 002 0 6.974 6.974 0 013.277-5.934.5.5 0 01.616.068A3 3 0 1019 12zm-6.25-3h-1.5a.5.5 0 01-.5-.5V6.25a1.25 1.25 0 012.5 0V8.5a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});