define("ember-svg-jar/inlined/flower-pot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flower-pot</title><path d=\"M17.68 18H6.18a.5.5 0 00-.485.621l.871 3.485A2.494 2.494 0 008.991 24h5.877a2.5 2.5 0 002.425-1.894l.872-3.485A.5.5 0 0017.68 18zM17.789 15h-4.351a.5.5 0 01-.5-.509c.016-.895.055-1.779.14-2.716a.25.25 0 01.395-.18 3.12 3.12 0 00.992.48 2.609 2.609 0 00.7.1 2.193 2.193 0 001.3-.414 2.725 2.725 0 00.4-3.686.249.249 0 01.12-.363 3.281 3.281 0 001.526-1.218 2.3 2.3 0 00.317-2 2.3 2.3 0 00-1.435-1.434 3.192 3.192 0 00-1.951-.081.255.255 0 01-.207-.042.251.251 0 01-.1-.185A2.732 2.732 0 0012.643 0a2.731 2.731 0 00-2.49 2.752.251.251 0 01-.1.185.254.254 0 01-.207.042 3.181 3.181 0 00-1.95.081 2.294 2.294 0 00-1.434 1.431 2.739 2.739 0 001.844 3.218.255.255 0 01.144.154.25.25 0 01-.026.21 2.725 2.725 0 00.4 3.686 2.193 2.193 0 001.3.414 2.618 2.618 0 00.6-.071.256.256 0 01.22.052.251.251 0 01.087.208 40.054 40.054 0 00-.088 2.146.5.5 0 01-.5.492H6.07a1 1 0 00-.97 1.242l.1.379a.5.5 0 00.48.379h12.5a.5.5 0 00.485-.379l.094-.379a1 1 0 00-.97-1.242zm-6.012-6.451a.363.363 0 00-.172-.309 1.908 1.908 0 01-.232-.169A.361.361 0 0011.03 8a.885.885 0 01-1.094-.55.871.871 0 01.556-1.095.361.361 0 00.24-.257 2.023 2.023 0 01.089-.273.361.361 0 00-.042-.348.867.867 0 111.4-1.019.357.357 0 00.316.148 1.859 1.859 0 01.289 0 .361.361 0 00.319-.149.865.865 0 011.21-.192.87.87 0 01.193 1.212.359.359 0 00-.043.348 1.892 1.892 0 01.089.273.358.358 0 00.24.257.868.868 0 01.555 1.094.878.878 0 01-1.091.551.356.356 0 00-.342.067 2.033 2.033 0 01-.233.169.365.365 0 00-.171.309.867.867 0 11-1.733 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});