define("ember-svg-jar/inlined/database-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-heart</title><path d=\"M1.377 17.625c0 2.333 4.583 4.042 9.709 4a.249.249 0 00.178-.423c-1.906-1.981-2.226-2.256-2.617-3.069a.254.254 0 00-.2-.141 13.327 13.327 0 01-6.664-2.273.249.249 0 00-.407.194zM1.377 13.125c0 1.772 2.738 3.271 6.526 3.8a.25.25 0 00.282-.285 4.969 4.969 0 01.39-2.8.25.25 0 00-.2-.352 13.24 13.24 0 01-6.591-2.266.249.249 0 00-.407.194zM10.878 9.125c-3.5 0-7.157-.833-9.093-2.411a.25.25 0 00-.408.194v1.717c0 1.979 3.414 3.616 7.893 3.938a.252.252 0 00.205-.082 5.012 5.012 0 016.507-.73.247.247 0 00.282 0A4.971 4.971 0 0118 11.015a3.309 3.309 0 001.788-1.006 2.1 2.1 0 00.588-1.384V6.91a.25.25 0 00-.408-.194c-1.937 1.577-5.587 2.409-9.09 2.409z\"/><ellipse cx=\"10.878\" cy=\"4.125\" rx=\"9.5\" ry=\"4\"/><path d=\"M19.662 12.421a3.5 3.5 0 00-3.057.987l-.306.3a.249.249 0 01-.353 0l-.3-.3a3.525 3.525 0 00-4.993 4.978l5.114 5.335a.5.5 0 00.722 0l5.106-5.327a3.525 3.525 0 00-1.928-5.973z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});