define("ember-svg-jar/inlined/museum-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>museum-person</title><path d=\"M16.5 10V1a1 1 0 00-1-1h-7a1 1 0 00-1 1v9a1 1 0 001 1h7a1 1 0 001-1zm-2-2a1 1 0 01-1 1h-3a1 1 0 01-1-1V3a1 1 0 011-1h3a1 1 0 011 1z\"/><circle cx=\"3.5\" cy=\"10\" r=\"2.5\"/><path d=\"M3.5 13A3.5 3.5 0 000 16.5v2a.5.5 0 00.5.5h.594a.5.5 0 01.5.45L2 23.55a.5.5 0 00.5.45h2a.5.5 0 00.5-.45l.409-4.1a.5.5 0 01.5-.45H6.5a.5.5 0 00.5-.5v-2A3.5 3.5 0 003.5 13zM21.75 13.25a2.248 2.248 0 00-1.622 3.805.25.25 0 010 .351 5.5 5.5 0 01-7.747 0 .25.25 0 010-.351 2.249 2.249 0 10-2.849.329.5.5 0 01.226.419V23a1 1 0 002 0v-3.016a.25.25 0 01.388-.208 7.521 7.521 0 008.225 0 .253.253 0 01.256-.011.25.25 0 01.131.22V23a1 1 0 002 0v-5.2a.5.5 0 01.227-.419 2.249 2.249 0 00-1.235-4.131z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});