define("ember-svg-jar/inlined/hardware-nail-round-head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hardware-nail-round-head</title><path d=\"M22.625 2.789l-1.414-1.414a4.5 4.5 0 00-6.364 0 .5.5 0 000 .707l7.071 7.071a.5.5 0 00.707 0 4.5 4.5 0 000-6.364zM9.094 17.941h-7.07a.5.5 0 00-.354.147L.352 19.406a1 1 0 000 1.414l2.828 2.828a1 1 0 001.414 0L9.448 18.8a.5.5 0 00-.354-.854zM13.1 13.941H6.023a.5.5 0 00-.353.147l-1.5 1.5a.5.5 0 00.353.853h7.071a.5.5 0 00.354-.146l1.5-1.5a.5.5 0 00-.353-.854zM15.554 4.91a.5.5 0 00-.707 0L8.17 11.588a.5.5 0 00.353.853H15.6a.5.5 0 00.353-.146l3.137-3.142a.5.5 0 000-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});