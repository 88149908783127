define("ember-svg-jar/inlined/kitchen-shelf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchen-shelf</title><path d=\"M15.581 19.41a.219.219 0 01-.081-.17v-2.49a1 1 0 00-2 0v2.49a.219.219 0 01-.081.17 2.68 2.68 0 00-.919 2.09 2.3 2.3 0 002 2.5 2.3 2.3 0 002-2.5 2.68 2.68 0 00-.919-2.09zM0 4.5a1 1 0 001 1h22a1 1 0 000-2H11.029a.25.25 0 01-.168-.435A3.464 3.464 0 0012 .5a.5.5 0 00-.5-.5h-7a.5.5 0 00-.5.5 3.464 3.464 0 001.139 2.565.25.25 0 01-.168.435H1a1 1 0 00-1 1zM24 13.5a1 1 0 00-1-1h-2.286a.25.25 0 01-.086-.485l.2-.075a1.873 1.873 0 00-.455-3.69h-.989a.249.249 0 01-.235-.167A1.225 1.225 0 0018 7.25h-4a1.252 1.252 0 00-1.25 1.25v3.75a.25.25 0 01-.25.25H9.029a.25.25 0 01-.168-.435A3.464 3.464 0 0010 9.5a.5.5 0 00-.5-.5h-7a.5.5 0 00-.5.5 3.464 3.464 0 001.139 2.565.25.25 0 01-.168.435H1a1 1 0 000 2h22a1 1 0 001-1zm-3.25-3.377c0 .172-.116.32-.358.386l-.806.295a.25.25 0 01-.336-.235V10a.25.25 0 01.25-.25h.877a.373.373 0 01.373.373zM21.581 19.41a.219.219 0 01-.081-.17v-2.49a1 1 0 00-2 0v2.49a.219.219 0 01-.081.17 2.68 2.68 0 00-.919 2.09 2.3 2.3 0 002 2.5 2.3 2.3 0 002-2.5 2.68 2.68 0 00-.919-2.09zM10 16.75a1 1 0 00-2 0v3a.25.25 0 01-.25.25h-2a1.252 1.252 0 00-1.25 1.25 2.748 2.748 0 105.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});