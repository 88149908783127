define("ember-svg-jar/inlined/pets-paw-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pets-paw-bubble</title><path d=\"M24 12a4.475 4.475 0 00-2.395-3.979A4.5 4.5 0 0015.979 2.4a4.5 4.5 0 00-7.958 0A4.5 4.5 0 002.4 8.021a4.5 4.5 0 000 7.958 4.5 4.5 0 005.626 5.626 4.5 4.5 0 007.958 0 4.5 4.5 0 005.626-5.626A4.475 4.475 0 0024 12zm-7.5.5A1.5 1.5 0 1118 11a1.5 1.5 0 01-1.5 1.5zm-9 0A1.5 1.5 0 119 11a1.5 1.5 0 01-1.5 1.5zm1.433 2.347l2.231-2.9a1 1 0 011.671 0l2.232 2.9a1 1 0 01-.836 1.549H9.768a1 1 0 01-.835-1.549zM12 6.5A1.5 1.5 0 1110.5 8 1.5 1.5 0 0112 6.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});