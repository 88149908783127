define("ember-svg-jar/inlined/road-sign-2-5m-high-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-2.5m-high-1</title><path d=\"M23.53 11.47l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0l11-11a.749.749 0 000-1.06zM10.307 4.213A.751.751 0 0111 3.75h2a.75.75 0 01.53 1.28l-1 1a.748.748 0 01-1.06 0l-1-1a.749.749 0 01-.163-.817zM12.75 12a.75.75 0 11-.75-.75.75.75 0 01.75.75zm-5.426.325a.749.749 0 01.091-.794L9.1 9.422A.685.685 0 009.25 9c0-.275-.5-.275-.5 0a.75.75 0 01-1.5 0 1.75 1.75 0 013.5 0 2.18 2.18 0 01-.476 1.358l-.439.549a.208.208 0 00-.025.223.211.211 0 00.19.12.75.75 0 010 1.5H8a.75.75 0 01-.676-.425zm6.369 8.462a.751.751 0 01-.693.463h-2a.75.75 0 01-.53-1.28l1-1a.749.749 0 011.06 0l1 1a.749.749 0 01.163.817zm.557-3.287a.75.75 0 01-1.5 0v-.585a.058.058 0 00-.107-.029.78.78 0 01-1.286 0 .058.058 0 00-.107.029v.585a.75.75 0 01-1.5 0V14a.75.75 0 011.393-.386l.643 1.071a.25.25 0 00.428 0l.643-1.071A.75.75 0 0114.25 14zM15 9.25a1.75 1.75 0 010 3.5h-1a.75.75 0 010-1.5h1a.25.25 0 000-.5h-.5a1.252 1.252 0 01-1.25-1.25V8a.75.75 0 01.75-.75h2a.75.75 0 010 1.5h-1a.25.25 0 000 .5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});