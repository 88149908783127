define("ember-svg-jar/inlined/bread-herb-flour", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bread-herb-flour</title><path d=\"M5.5 4.5h13A.5.5 0 0019 4V1.5A1.5 1.5 0 0017.5 0h-11A1.5 1.5 0 005 1.5V4a.5.5 0 00.5.5zM18.5 6h-13A4.505 4.505 0 001 10.5v9A4.505 4.505 0 005.5 24h13a4.505 4.505 0 004.5-4.5v-9A4.505 4.505 0 0018.5 6zm-.25 7.5h-2.775a1 1 0 00-.707.293l-.781.78a.25.25 0 00.177.427h1.086a.75.75 0 010 1.5h-2.775a1 1 0 00-.707.293l-.781.78a.25.25 0 00.177.427h1.086a.75.75 0 010 1.5H9.475a1 1 0 00-.707.293L7.28 21.28a.75.75 0 01-1.06-1.06l1.487-1.488A1 1 0 008 18.025V15.25a.75.75 0 011.5 0v1.086a.25.25 0 00.427.177l.78-.781a1 1 0 00.293-.707V12.25a.75.75 0 011.5 0v1.086a.25.25 0 00.427.177l.78-.781a1 1 0 00.293-.707V9.25a.75.75 0 011.5 0v1.086a.25.25 0 00.427.177l.293-.293a.75.75 0 011.06 1.06l-.293.293a.25.25 0 00.177.427h1.086a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});