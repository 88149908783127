define("ember-svg-jar/inlined/single-neutral-actions-chat.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-chat</title><path d=\"M9.5 12a3.059 3.059 0 01.068-.632.5.5 0 00-.353-.582 7.268 7.268 0 00-2.009-.286 7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643H9a.5.5 0 00.5-.5z\"/><circle cx=\"7\" cy=\"4.75\" r=\"4.75\"/><path d=\"M24 12a2 2 0 00-2-2h-9a2 2 0 00-2 2v7a2 2 0 002 2h.75a.25.25 0 01.25.25V23a1 1 0 001.625.781l3.407-2.726a.247.247 0 01.156-.055H22a2 2 0 002-2zm-5.875 7.219L16.406 20.6a.25.25 0 01-.406-.2V20a1 1 0 00-1-1h-1.75a.25.25 0 01-.25-.25v-6.5a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v6.5a.249.249 0 01-.25.25h-3a1 1 0 00-.625.219z\"/><path d=\"M14.25 14.25A.75.75 0 0015 15h5a.75.75 0 000-1.5h-5a.75.75 0 00-.75.75zM18.75 16.75A.75.75 0 0018 16h-3a.75.75 0 000 1.5h3a.75.75 0 00.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});