define("ember-svg-jar/inlined/smiley-tongue-1-alternate-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-tongue-1-alternate-2</title><path d=\"M7.709 9a1.5 1.5 0 011.375.9 1 1 0 00.917.6.973.973 0 00.4-.084 1 1 0 00.515-1.316 3.5 3.5 0 00-6.416 0 1 1 0 101.832.8A1.5 1.5 0 017.709 9zM14.916 9.9a1.5 1.5 0 012.751 0 1 1 0 00.917.6.973.973 0 00.4-.084A1 1 0 0019.5 9.1a3.5 3.5 0 00-6.415 0 1 1 0 101.832.8z\"/><path d=\"M12 0a12 12 0 00-3.818 23.379.982.982 0 00.318.053 1 1 0 00.318-1.949 10 10 0 1110.9-3.12 1 1 0 101.541 1.274A12 12 0 0012 0z\"/><path d=\"M19.5 15a1 1 0 00-1-1H7a1 1 0 000 2h2.315a.25.25 0 01.227.144 8.087 8.087 0 01.6 3.427 4.429 4.429 0 108.857 0 11.211 11.211 0 00-.506-3.562V16a1 1 0 001.007-1zM17 19.571a2.429 2.429 0 11-4.857 0 11.722 11.722 0 00-.409-3.251.25.25 0 01.24-.32h.926a.25.25 0 01.226.143 6.525 6.525 0 01.523 2.016 1 1 0 00.992.883.945.945 0 00.118-.007 1 1 0 00.876-1.109 10.048 10.048 0 00-.315-1.607.251.251 0 01.24-.319h.617a.252.252 0 01.227.144A8.109 8.109 0 0117 19.571z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});