define("ember-svg-jar/inlined/road-curvy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-curvy</title><path d=\"M20.5 13h3.25a.25.25 0 00.25-.25v-2a.75.75 0 00-.75-.75h-4.5a.25.25 0 00-.25.25v7a.25.25 0 00.25.25h4.5a.75.75 0 00.75-.75v-2a.25.25 0 00-.25-.25H20.5a.75.75 0 010-1.5zM8.75 0a8.75 8.75 0 000 17.5.25.25 0 00.25-.25v-2.5a.25.25 0 00-.25-.25 6.018 6.018 0 01-1.316-.146.749.749 0 11.33-1.462A4.618 4.618 0 008.75 13a.249.249 0 00.25-.25v-2.5a.25.25 0 00-.25-.25A1.25 1.25 0 1110 8.75v14.5a.749.749 0 00.75.75l6-.016a.749.749 0 00.748-.75V8.75A8.76 8.76 0 008.75 0zM4.582 11.611a.748.748 0 01-1-.344 5.765 5.765 0 01-.358-4.1.75.75 0 111.442.413A4.253 4.253 0 004.5 8.75a4.2 4.2 0 00.427 1.858.751.751 0 01-.345 1.003zm6.135-7.521a.748.748 0 01-.911.541 4.29 4.29 0 00-2.936.306.75.75 0 01-.664-1.345 5.784 5.784 0 013.97-.414.749.749 0 01.541.912zm3.783 17.9a.75.75 0 11-1.5 0v-4.029a.75.75 0 011.5 0zm0-9.211a.75.75 0 01-1.5 0V8.992a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});