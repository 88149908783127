define("ember-svg-jar/inlined/disability-blind-read-paper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-blind-read-paper</title><circle cx=\"6.001\" cy=\"7.75\" r=\"1\"/><circle cx=\"10.001\" cy=\"7.75\" r=\"1\"/><circle cx=\"6.001\" cy=\"11.25\" r=\"1\"/><circle cx=\"10.001\" cy=\"11.25\" r=\"1\"/><circle cx=\"6.001\" cy=\"14.75\" r=\"1\"/><path d=\"M23.712 18.94a7.686 7.686 0 00-6.079-3.3 7.686 7.686 0 00-6.08 3.3 1.493 1.493 0 000 1.763 7.687 7.687 0 006.08 3.3 7.687 7.687 0 006.078-3.3 1.493 1.493 0 00.001-1.763zm-9.159 1.988a6.611 6.611 0 01-.855-.767.5.5 0 010-.682 5.317 5.317 0 014.069-1.832.25.25 0 01.172.424l-2.727 2.805a.5.5 0 01-.659.052zm7.014-.766a5.319 5.319 0 01-4.067 1.831.25.25 0 01-.172-.424l2.727-2.8a.5.5 0 01.659-.052 6.817 6.817 0 01.854.767.5.5 0 01-.001.678z\"/><path d=\"M14.708 5.121a1 1 0 01.292.707V13.5a1 1 0 002 0V5.414A1.986 1.986 0 0016.415 4L13 .586A2.011 2.011 0 0011.587 0H2a2 2 0 00-2 2v18a2 2 0 002 2h7a1 1 0 000-2H2.5a.5.5 0 01-.5-.5v-17a.5.5 0 01.5-.5h8.672a1 1 0 01.707.293z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});