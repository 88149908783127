define("ember-svg-jar/inlined/phone-actions-clock-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-clock-1</title><path d=\"M17.5.01a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm0 11a4.5 4.5 0 114.5-4.5 4.505 4.505 0 01-4.5 4.5z\"/><path d=\"M19.5 5.76h-1.25V4.01a.75.75 0 10-1.5 0v2.5a.75.75 0 00.75.75h2a.75.75 0 000-1.5zM15.5 15.773a3.116 3.116 0 00-4.4 0l-.827.828a4.25 4.25 0 00-1.346 4.5 11.348 11.348 0 01-6.032-6.031 4.247 4.247 0 004.5-1.346l.83-.829a3.116 3.116 0 000-4.4l-3.59-3.582a2.234 2.234 0 00-3.649.739 13.314 13.314 0 0017.357 17.355 2.234 2.234 0 00.737-3.649z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});