define("ember-svg-jar/inlined/shopping-bag-carry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-bag-carry</title><circle cx=\"12\" cy=\"2.5\" r=\"2.5\"/><path d=\"M15.5 9a3.5 3.5 0 00-7 0v2.75a.25.25 0 00.25.25h1.024a.25.25 0 01.249.225l.454 4.55a.25.25 0 00.249.225h2.548a.25.25 0 00.249-.225l.454-4.55a.25.25 0 01.249-.225h1.024a.25.25 0 00.25-.25zM9.031 17.013A1.157 1.157 0 007.885 16a.134.134 0 01-.135-.135v-1.412a2.75 2.75 0 00-5.5 0v1.412a.134.134 0 01-.135.135 1.157 1.157 0 00-1.146 1.013L.259 22.7A1.156 1.156 0 001.405 24H8.6a1.156 1.156 0 001.146-1.3zm-5.281-2.56a1.25 1.25 0 012.5 0v1.3A.25.25 0 016 16H4a.25.25 0 01-.25-.25zM22.6 24a1.156 1.156 0 001.146-1.3l-.71-5.687A1.157 1.157 0 0021.885 16a.134.134 0 01-.135-.135v-1.412a2.75 2.75 0 00-5.5 0v1.412a.134.134 0 01-.135.135 1.157 1.157 0 00-1.146 1.013l-.71 5.687a1.156 1.156 0 001.146 1.3zm-4.85-9.547a1.25 1.25 0 012.5 0v1.3A.25.25 0 0120 16h-2a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});