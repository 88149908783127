define("ember-svg-jar/inlined/sauna-heat-stone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sauna-heat-stone</title><path d=\"M6.307 11.775a2.767 2.767 0 01-.379 1.28 1 1 0 101.756.955 4.671 4.671 0 00.623-2.235 9.223 9.223 0 00-.878-3.4 7.7 7.7 0 01-.735-2.653 4.1 4.1 0 011.321-2.515A1 1 0 106.6 1.793a6.069 6.069 0 00-1.906 3.933 9.228 9.228 0 00.878 3.4 7.715 7.715 0 01.735 2.649zM17.306 11.775a2.765 2.765 0 01-.378 1.281 1 1 0 00.4 1.356.986.986 0 00.476.122 1 1 0 00.879-.523 4.675 4.675 0 00.623-2.236 9.218 9.218 0 00-.878-3.395 7.725 7.725 0 01-.735-2.654 4.1 4.1 0 011.32-2.518A1 1 0 0017.6 1.794a6.056 6.056 0 00-1.906 3.932 9.223 9.223 0 00.878 3.4 7.7 7.7 0 01.734 2.649zM10.293 17.207a1 1 0 001.414 0A7.275 7.275 0 0014 12.5a11.276 11.276 0 00-1.071-4.12A9.692 9.692 0 0112 5a5.128 5.128 0 011.707-3.293A1 1 0 0012.293.293 7.274 7.274 0 0010 5a11.287 11.287 0 001.071 4.122A9.676 9.676 0 0112 12.5a5.128 5.128 0 01-1.707 3.294 1 1 0 000 1.413z\"/><path d=\"M22.182 13.255a1 1 0 10-1.365 1.461A3.185 3.185 0 0122 17c0 2.71-4.579 5-10 5S2 19.71 2 17a3.182 3.182 0 011.184-2.284 1 1 0 10-1.367-1.461A5.153 5.153 0 000 17c0 3.925 5.272 7 12 7s12-3.075 12-7a5.144 5.144 0 00-1.818-3.745z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});