define("ember-svg-jar/inlined/touch-id-denied", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>touch-id-denied</title><path d=\"M10.732 21.908a.5.5 0 00-.481-.226 7.2 7.2 0 01-.876.054.75.75 0 000 1.5 9.344 9.344 0 001.764-.167.252.252 0 00.189-.162.248.248 0 00-.045-.245 7.209 7.209 0 01-.551-.754zM17.125 9.267a.22.22 0 00.22.219h.03a7.711 7.711 0 01.969.059.251.251 0 00.281-.249v-.029A9.154 9.154 0 0016.7 3.616a.748.748 0 00-1.05-.139.739.739 0 00-.13 1.049 7.736 7.736 0 011.605 4.741zM6.361 21.129a7.74 7.74 0 01-4.736-7.143V12.1a.75.75 0 00-1.5 0v1.888a9.235 9.235 0 005.652 8.522.74.74 0 00.292.059.75.75 0 00.292-1.44zM.847 9.542h.042a.75.75 0 00.748-.709 7.751 7.751 0 0111.615-6.28A.75.75 0 0014 1.254 9.252 9.252 0 00.139 8.752a.75.75 0 00.708.79z\"/><path d=\"M14.4 9.944a.248.248 0 00.23.03 8.456 8.456 0 01.994-.3.218.218 0 00.169-.213v-.194a6.419 6.419 0 00-8.42-6.1.747.747 0 00-.48.95.756.756 0 00.94.48 4.733 4.733 0 011.54-.25 4.93 4.93 0 014.927 4.92v.472a.249.249 0 00.1.205zM5.705 5.986a.746.746 0 00-.05-1.06.755.755 0 00-1.06.06 6.4 6.4 0 00-1.64 4.281v4.719a6.427 6.427 0 006.42 6.42h.209a.25.25 0 00.225-.333 7.323 7.323 0 01-.278-1.034.159.159 0 00-.156-.129 4.929 4.929 0 01-4.92-4.92V9.267a4.95 4.95 0 011.25-3.281z\"/><path d=\"M9.294 17.566a.08.08 0 00.081-.08 7.656 7.656 0 01.105-1.3.107.107 0 00-.105-.124A2.079 2.079 0 017.3 13.986a.75.75 0 00-1.5 0 3.576 3.576 0 003.494 3.58zM11.606 11.731a.251.251 0 00.271-.049 7.832 7.832 0 01.969-.782.252.252 0 00.109-.207V9.267a3.58 3.58 0 10-7.16 0v1.409a.75.75 0 001.5 0V9.267a2.08 2.08 0 114.16 0V11.5a.252.252 0 00.151.231z\"/><path d=\"M10.125 13.514v-2.833a.75.75 0 10-1.5 0v2.833a.75.75 0 001.5 0zM17.375 10.986a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm1.591 6.677L20.2 18.9a1 1 0 11-1.414 1.414l-1.237-1.238a.252.252 0 00-.354 0l-1.237 1.238a1 1 0 01-1.411-1.414l1.237-1.238a.249.249 0 000-.353l-1.237-1.238a1 1 0 011.414-1.414L17.2 15.9a.252.252 0 00.354 0l1.237-1.238a1 1 0 111.409 1.41l-1.234 1.238a.249.249 0 000 .353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});