define("ember-svg-jar/inlined/smart-watch-circle-sleep", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-sleep</title><path d=\"M12 3.5a8.5 8.5 0 108.5 8.5A8.5 8.5 0 0012 3.5zm1.986 11.75a.75.75 0 010 1.5h-3.6a1.251 1.251 0 01-1.062-1.911l3.567-5.707a.25.25 0 00-.212-.382H9.986a.75.75 0 010-1.5h3.6a1.25 1.25 0 011.06 1.913l-3.566 5.7a.251.251 0 00.212.383zM7.379 3.051a.125.125 0 00.135.014 9.98 9.98 0 018.972 0 .125.125 0 00.177-.144l-.68-2.55A.5.5 0 0015.5 0h-7a.5.5 0 00-.483.371l-.68 2.551a.125.125 0 00.042.129z\"/><path d=\"M16.621 20.949a.125.125 0 00-.135-.015 9.972 9.972 0 01-8.972 0 .125.125 0 00-.177.144l.68 2.551A.5.5 0 008.5 24h7a.5.5 0 00.483-.371l.68-2.551a.125.125 0 00-.042-.129z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});