define("ember-svg-jar/inlined/table-lamp-hanging-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>table-lamp-hanging-1</title><path d=\"M7.986 12.5v-1a.75.75 0 00-1.5 0v1a.75.75 0 001.5 0zM10.737 12.75a.749.749 0 00.67-1.085l-.5-1a.75.75 0 10-1.342.67l.5 1a.752.752 0 00.672.415zM4.572 10.329a.753.753 0 00-1.007.336l-.5 1a.75.75 0 101.342.67l.5-1a.751.751 0 00-.335-1.006zM13.236 20h-4a3 3 0 00-3 3 1 1 0 001 1h8a1 1 0 001-1 3 3 0 00-3-3z\"/><path d=\"M13.236 0h-4a3 3 0 00-3 3v.389a.249.249 0 01-.2.244A5.486 5.486 0 001.77 8.39a1 1 0 00.994 1.11h9.183a.783.783 0 00.78-.827 5.5 5.5 0 00-4.295-5.036.249.249 0 01-.2-.244V3a1 1 0 011-1h4a7 7 0 010 14 3 3 0 00-2.877 2.182.25.25 0 00.24.318h1.658a.249.249 0 00.2-.1.973.973 0 01.779-.4 9 9 0 000-18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});