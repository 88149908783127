define("ember-svg-jar/inlined/religion-peace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-peace</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm1.8 20.3a.249.249 0 01-.3-.244V14.2a.25.25 0 01.426-.177l3.863 3.85a.249.249 0 01-.006.36A8.523 8.523 0 0113.8 20.3zm-.226-10.873a.252.252 0 01-.074-.177v-5.3a.25.25 0 01.3-.244 8.5 8.5 0 016.083 11.473.246.246 0 01-.181.151.251.251 0 01-.227-.067zm-9.022 5.9a.249.249 0 01-.215.071.253.253 0 01-.184-.133A8.728 8.728 0 013.5 12a8.518 8.518 0 016.7-8.3.254.254 0 01.211.049.25.25 0 01.093.195v5.307a.248.248 0 01-.073.176zm5.521-1.3a.25.25 0 01.427.177v5.861a.25.25 0 01-.093.2.254.254 0 01-.211.049 8.548 8.548 0 01-3.971-2.066.251.251 0 01-.007-.361z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});