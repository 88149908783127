define("ember-svg-jar/inlined/earth-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earth-edit</title><path d=\"M8.429 17.522a4.455 4.455 0 00-2.864-7.868H2.679a.249.249 0 01-.2-.1.253.253 0 01-.043-.215 9.883 9.883 0 0114.4-6.092.247.247 0 01-.121.463h-2.486a2.723 2.723 0 100 5.446 2.579 2.579 0 012.2 1.245l.576.961a.5.5 0 00.775.1l.579-.579a4.09 4.09 0 012.928-1.238 3.957 3.957 0 011.65.361.5.5 0 00.69-.552A11.879 11.879 0 107.389 22.831l.157.077v-.02a11.653 11.653 0 001.439.475.5.5 0 00.6-.355l.013-.047.257-.9a.494.494 0 00-.352-.615 9.74 9.74 0 01-1.822-.666.247.247 0 01-.139-.222v-1.139a2.47 2.47 0 01.887-1.897z\"/><path d=\"M12.733 20.085a.5.5 0 00-.826.214l-.878 3.07a.5.5 0 00.613.612l3.069-.877a.494.494 0 00.214-.826zM23.122 15.559a2.6 2.6 0 000-3.67 2.629 2.629 0 00-3.716.045L13.044 18.3a.494.494 0 000 .7l2.97 2.971a.495.495 0 00.7 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});