define("ember-svg-jar/inlined/module-hand-puzzle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>module-hand-puzzle-1</title><path d=\"M11.633 5.846a2.016 2.016 0 01-.255-.311.5.5 0 00-.771-.077L9.158 6.907a1 1 0 000 1.414l5.657 5.657a1 1 0 001.414 0l1.562-1.562a.5.5 0 00-.171-.819A2 2 0 1120.212 9a.5.5 0 00.82.171l1.561-1.561a1 1 0 000-1.414l-1.328-1.325a.247.247 0 01-.07-.219.251.251 0 01.139-.183 1.966 1.966 0 00.552-.391 2 2 0 10-2.828-2.828 1.966 1.966 0 00-.391.552.25.25 0 01-.4.069L16.936.543a1 1 0 00-1.414 0l-1.449 1.449a.5.5 0 00.077.772 1.953 1.953 0 01.311.254 2 2 0 11-2.828 2.828zM2.114 14.5a1 1 0 00-1 1v7.25a1 1 0 002 0V15.5a1 1 0 00-1-1zM4.114 15.75v6.5a.5.5 0 00.5.5h10.764a2.238 2.238 0 002.236-2.236v-.264a1 1 0 00-1-1H7.489a.625.625 0 010-1.25h2.525a1 1 0 00.92-1.392 2.233 2.233 0 00-2.056-1.358H4.614a.5.5 0 00-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});