define("ember-svg-jar/inlined/receipt-slip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>receipt-slip</title><path d=\"M19.883.784a.994.994 0 00-1.09.216l-.616.617a.252.252 0 01-.354 0L16.707.5a1 1 0 00-1.414 0l-1.116 1.117a.252.252 0 01-.354 0L12.707.5a1 1 0 00-1.414 0l-1.116 1.117a.252.252 0 01-.354 0L8.707.5a1 1 0 00-1.414 0L6.177 1.616a.249.249 0 01-.354 0L5.208 1a1 1 0 00-1.708.707v20.586A1 1 0 005.207 23l.616-.616a.252.252 0 01.354 0L7.293 23.5a1 1 0 001.414 0l1.116-1.116a.252.252 0 01.354 0l1.116 1.116a1 1 0 001.414 0l1.116-1.116a.252.252 0 01.354 0l1.116 1.116a1 1 0 001.414 0l1.116-1.116a.252.252 0 01.354 0l.616.616a1 1 0 001.707-.707V1.707a1 1 0 00-.617-.923zM17 13.75h-1a.75.75 0 010-1.5h1a.75.75 0 010 1.5zm.75 3.25a.75.75 0 01-.75.75h-1a.75.75 0 010-1.5h1a.75.75 0 01.75.75zM17 9.75h-1a.75.75 0 010-1.5h1a.75.75 0 010 1.5zm-10 4a.75.75 0 010-1.5h5a.75.75 0 010 1.5zM10.75 17a.75.75 0 01-.75.75H7a.75.75 0 010-1.5h3a.75.75 0 01.75.75zm-4.5-8A.75.75 0 017 8.25h4a.75.75 0 010 1.5H7A.75.75 0 016.25 9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});