define("ember-svg-jar/inlined/video-game-logo-xsplit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-logo-xsplit</title><path d=\"M23 0H1a1 1 0 00-1 1v22a1 1 0 001 1h22a1 1 0 001-1V1a1 1 0 00-1-1zm-3.5 15.91a.5.5 0 01-.582.493l-1.736-.289a.5.5 0 00-.435.139l-1.893 3.1A.5.5 0 0114 19v-2.914a.5.5 0 00-.413-.492L4.918 14.07a.5.5 0 01-.418-.494V6.59a.5.5 0 01.582-.49l14 2.333a.5.5 0 01.418.494z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});