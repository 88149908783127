define("ember-svg-jar/inlined/multiple-actions-upload.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-upload</title><path d=\"M14.471 8.4l-.486-.172a1.8 1.8 0 01-.055-1.172 4.647 4.647 0 001.216-3.569A3.22 3.22 0 0012 0a2.994 2.994 0 00-1.342.314.25.25 0 00-.1.363 5 5 0 01.842 2.808 6.263 6.263 0 01-.922 3.624.248.248 0 00-.028.215.246.246 0 00.151.157c1.511.557 3.11 1.172 3.83 2.411a.249.249 0 00.3.109 7.864 7.864 0 011.558-.4.25.25 0 00.061-.477 40.604 40.604 0 00-1.879-.724z\"/><path d=\"M13.207 10.763a.251.251 0 00.11-.159.255.255 0 00-.037-.19c-.5-.759-1.622-1.211-3.809-2.016l-.486-.172a1.8 1.8 0 01-.055-1.172 4.647 4.647 0 001.216-3.569A3.22 3.22 0 007 0a3.22 3.22 0 00-3.146 3.485 4.605 4.605 0 001.193 3.538 1.877 1.877 0 010 1.185l-.515.19c-2.01.739-3.46 1.272-3.914 2.179A7.542 7.542 0 000 13.5a.5.5 0 00.5.5h9.663a.252.252 0 00.223-.136 8.031 8.031 0 012.821-3.101zM17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-1 9.5V18a.25.25 0 00-.25-.25H15.1a.5.5 0 01-.391-.812l2.4-3a.519.519 0 01.782 0l2.4 3a.5.5 0 01-.391.812h-1.15a.25.25 0 00-.25.25v2.5a1 1 0 01-2 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});