define("ember-svg-jar/inlined/professions-man-clerk.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-clerk</title><path d=\"M.5 23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-3.106a2.464 2.464 0 00-.967-1.977 11.707 11.707 0 00-2.168-1.279.249.249 0 01-.138-.283.63.63 0 000-.308.752.752 0 00-.49-.521 21.023 21.023 0 00-3.079-.765.756.756 0 00-.594.153l-3.906 3.118a.25.25 0 01-.312 0l-3.908-3.118a.754.754 0 00-.594-.153 21.023 21.023 0 00-3.079.765.752.752 0 00-.49.521.63.63 0 000 .308.25.25 0 01-.138.283 11.707 11.707 0 00-2.168 1.279A2.464 2.464 0 00.5 20.394zm5.63-6.074a.249.249 0 01.126-.41c.3-.072.6-.138.9-.2a.249.249 0 01.2.05l3.2 2.551a.247.247 0 01.094.2.25.25 0 01-.094.2l-1.137.907a.248.248 0 01-.341-.028zm8.8 3.265a.248.248 0 01-.341.028l-1.137-.907a.25.25 0 01-.094-.2.247.247 0 01.094-.2l3.2-2.551a.249.249 0 01.2-.05c.3.058.6.124.9.2a.249.249 0 01.126.41z\"/><path d=\"M12 16a7.008 7.008 0 007-7V7.5a.25.25 0 01.25-.25H20a1 1 0 000-2h-.388a.249.249 0 01-.248-.221l-.13-1.1A2.06 2.06 0 0017.25 2h-.365a.249.249 0 01-.175-.072 6.717 6.717 0 00-9.42 0A.249.249 0 017.115 2H6.75a2.057 2.057 0 00-1.983 1.912l-.13 1.114a.25.25 0 01-.248.221H4a1 1 0 000 2h.75A.25.25 0 015 7.5V9a7.008 7.008 0 007 7zM6.772 4.2a.249.249 0 01.245-.2l9.991-.015a.25.25 0 01.248.212c.022.15.045.393.088.775a.251.251 0 01-.248.278H6.87A.219.219 0 016.653 5c.047-.4.091-.65.119-.8zM12 14a5.006 5.006 0 01-5-5V7.5a.25.25 0 01.25-.25h9.5a.25.25 0 01.25.25V9a5.006 5.006 0 01-5 5z\"/><circle cx=\"14\" cy=\"9.25\" r=\"1\"/><circle cx=\"10\" cy=\"9.25\" r=\"1\"/><path d=\"M13.077 11.362h-2a.639.639 0 00-.3 1.2 2.31 2.31 0 00.435.18 2.99 2.99 0 00.867.126h.006a2.976 2.976 0 00.863-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});