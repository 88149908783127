define("ember-svg-jar/inlined/data-transfer-square-diagonal-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-transfer-square-diagonal-alternate</title><path d=\"M15.182 11.646L11.824 15a.25.25 0 01-.353 0l-.71-.71a.75.75 0 00-1.271.428l-.648 4.536a.749.749 0 00.746.856.889.889 0 00.1-.006l4.539-.649a.749.749 0 00.422-1.272l-.707-.708a.249.249 0 010-.353l3.358-3.355a1.5 1.5 0 00-2.121-2.121z\"/><path d=\"M22.044 0H1.957A1.959 1.959 0 000 1.955v20.088A1.959 1.959 0 001.957 24h20.087A1.959 1.959 0 0024 22.043V1.955A1.959 1.959 0 0022.044 0zM2 21.041V3a1 1 0 011-1l18-.04a1 1 0 011 1L22.041 21a1 1 0 01-1 1L3 22.041a1 1 0 01-1-1z\"/><path d=\"M13.239 9.7a.75.75 0 00.528.216.765.765 0 00.239-.038.746.746 0 00.5-.606l.648-4.536a.751.751 0 00-.849-.849l-4.538.648a.751.751 0 00-.423 1.273l.708.707a.252.252 0 010 .354L6.7 10.232a1.5 1.5 0 002.121 2.121l3.358-3.359a.251.251 0 01.354 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});