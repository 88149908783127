define("ember-svg-jar/inlined/paginate-filter-picture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paginate-filter-picture</title><path d=\"M22 0H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2V2a2 2 0 00-2-2zM9.667 12.433a1 1 0 011.727-.108l.83 1.246a.25.25 0 00.428-.021l2.437-4.527A.977.977 0 0116 8.5a1 1 0 01.878.574l3.578 7.6a.5.5 0 01-.453.713H8a.5.5 0 01-.447-.724zM7.75 6.247a2 2 0 112 2 2 2 0 01-2-2z\"/><path d=\"M19.5 22h-17a.5.5 0 01-.5-.5v-17a1 1 0 00-2 0V22a2 2 0 002 2h17.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});