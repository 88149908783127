define("ember-svg-jar/inlined/dentistry-tooth-hook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dentistry-tooth-hook</title><path d=\"M12.917 8.233a.5.5 0 00-.708 0l-1.491 1.491a1 1 0 000 1.414l2.121 2.121a1 1 0 001.414 0l1.492-1.491a.5.5 0 000-.707zM23.019.964a2.558 2.558 0 00-3.535 0l-5.506 5.5a.5.5 0 000 .708L16.805 10a.5.5 0 00.707 0l5.507-5.5a2.5 2.5 0 000-3.536zM10.719 12.905a.75.75 0 00-1.061 0L6.282 16.3a2 2 0 00-.308 2.431 2 2 0 11-3.724 1.015 1.979 1.979 0 01.586-1.413 1 1 0 10-1.414-1.415 4 4 0 106.275.8l3.376-3.394a.748.748 0 00.219-.53.758.758 0 00-.219-.531z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});