define("ember-svg-jar/inlined/single-man-actions-flash.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-flash</title><path d=\"M8.687 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.743a.5.5 0 01.741-.3A6.958 6.958 0 0010.146 5a6.363 6.363 0 00.93-.07.5.5 0 01.568.564A3 3 0 015.687 5a2.8 2.8 0 01.1-.743z\"/><path d=\"M14.805 14.037a.5.5 0 00.007-.623 7.486 7.486 0 00-13.1 2.443.5.5 0 00.479.643h10.367a.5.5 0 00.387-.184zM21.492 16h-1.036a.5.5 0 01-.5-.5v-4a.5.5 0 00-.887-.316L13.8 17.646A.822.822 0 0014.421 19h1.035a.5.5 0 01.5.5v4a.5.5 0 00.332.471.516.516 0 00.168.029.5.5 0 00.387-.184l5.275-6.462A.822.822 0 0021.492 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});