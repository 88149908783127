define("ember-svg-jar/inlined/headphones-wave", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>headphones-wave</title><path d=\"M21.158 11.705a.251.251 0 01-.158-.233v-.1a9 9 0 00-18 0v.1a.251.251 0 01-.158.233 4.5 4.5 0 001.017 8.638A1 1 0 005 19.352v-7.977a7 7 0 0114 0v8.187a.815.815 0 00.881.81 4.5 4.5 0 001.277-8.667z\"/><path d=\"M17.75 16.638a1 1 0 00-1-1H15a1 1 0 00-.894.551l-.488.974a.25.25 0 01-.466-.052l-1.182-4.728a1 1 0 00-1.865-.2l-1.657 3.31a.249.249 0 01-.221.138l-.985.007a1 1 0 00.008 2h.008l1.75-.013a1 1 0 00.887-.553l.488-.977a.25.25 0 01.466.051l1.181 4.721a1 1 0 001.864.206l1.654-3.3a.249.249 0 01.223-.138h.979a1 1 0 001-.997z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});