define("ember-svg-jar/inlined/religion-shinto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-shinto</title><path d=\"M17.061 7.181a.25.25 0 00-.25.25v2.5a.25.25 0 01-.25.25H15.13a.25.25 0 01-.25-.25v-2.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25v2.5a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25v-2.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25v2.5a.25.25 0 01-.25.25H7.7a.25.25 0 01-.25-.25v-2.5a.25.25 0 00-.25-.25H6a6.557 6.557 0 01-1.253-.13.251.251 0 00-.3.246v2.634a.25.25 0 01-.25.25H2.63a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h1.542a.25.25 0 01.249.261C4.2 18.5 2.7 21.171 1.739 22.369a.5.5 0 00.391.812h2.116a.5.5 0 00.364-.158c.586-.624 2.578-3.213 2.812-9.6a.25.25 0 01.25-.241h8.916a.25.25 0 01.25.241c.233 6.388 2.226 8.977 2.812 9.6a.5.5 0 00.364.158h2.116a.5.5 0 00.391-.812c-.956-1.2-2.464-3.868-2.683-8.927a.256.256 0 01.07-.184.25.25 0 01.18-.077h1.542a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-1.569a.25.25 0 01-.25-.25v-2.69A.252.252 0 0019.5 7a6.672 6.672 0 01-1.5.184zM22.6.9a5.123 5.123 0 01-2.725.785H4.121A5.123 5.123 0 011.4.9a.5.5 0 00-.753.536A5.5 5.5 0 006 5.681h12a5.5 5.5 0 005.357-4.249A.5.5 0 0022.6.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});