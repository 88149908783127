define("ember-svg-jar/inlined/wild-bird-owl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wild-bird-owl</title><circle cx=\"18.597\" cy=\"14.985\" r=\"1.257\"/><circle cx=\"5.403\" cy=\"14.985\" r=\"1.257\"/><path d=\"M23.321 12.717a11.226 11.226 0 00-1.744-5.261.251.251 0 00-.386-.043 12.206 12.206 0 01-5.215 2.959.75.75 0 01-.413-1.442c3.553-1.016 6.1-3.466 6.727-6.329a1 1 0 00-1.969-.264 5.545 5.545 0 01-1.27 2.323 11.334 11.334 0 00-14.1 0 5.53 5.53 0 01-1.272-2.323A1 1 0 001.71 2.6c.629 2.864 3.174 5.314 6.727 6.33a.75.75 0 01-.413 1.442 12.217 12.217 0 01-5.211-2.955.249.249 0 00-.2-.07.245.245 0 00-.182.114 11.274 11.274 0 00-1.752 5.255 5.237 5.237 0 00-.031 4.472v4.478a.752.752 0 00.671.747.74.74 0 00.813-.589 7.349 7.349 0 01.835-2.2 5.234 5.234 0 006.686-1.576l1.592 1.833a1 1 0 001.51 0l1.593-1.833a5.233 5.233 0 006.685 1.575 7.349 7.349 0 01.835 2.2.75.75 0 001.484-.158v-4.477a5.235 5.235 0 00-.031-4.471zM5.4 18.226a3.241 3.241 0 113.241-3.241A3.245 3.245 0 015.4 18.226zM12 17.7l-1.222-1.407A2.246 2.246 0 0112 15.985a2.264 2.264 0 011.224.308zm6.6.525a3.241 3.241 0 113.241-3.241 3.245 3.245 0 01-3.241 3.242z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});