define("ember-svg-jar/inlined/baggage-plane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baggage-plane</title><path d=\"M20.5 4h-1a.25.25 0 00-.25.25v9.118a.25.25 0 00.391.206 3.631 3.631 0 013.988-.371.251.251 0 00.371-.219V7.5A3.5 3.5 0 0020.5 4zM9.8 17.743a.065.065 0 00.023-.052.062.062 0 00-.028-.05 2.391 2.391 0 01.148-3.749l1.018-.748a2.376 2.376 0 012.24-.32l4.213 1.565a.25.25 0 00.337-.234V4.25A.25.25 0 0017.5 4H17a.249.249 0 01-.25-.25V3a3.009 3.009 0 00-3-3h-3.5a3.009 3.009 0 00-3 3v.75A.249.249 0 017 4h-.5a.25.25 0 00-.25.25v16.5a.249.249 0 00.25.25h.752a.25.25 0 00.25-.245 2.272 2.272 0 01.508-1.4zM9.25 3a1 1 0 011-1h3.5a1 1 0 011 1v.75a.249.249 0 01-.25.25h-5a.249.249 0 01-.25-.25zM0 7.5v10A3.5 3.5 0 003.5 21h1a.249.249 0 00.25-.25V4.25A.25.25 0 004.5 4h-1A3.5 3.5 0 000 7.5z\"/><path d=\"M9.179 20.289a.806.806 0 00-.179.595.817.817 0 00.318.558l3.367 2.358a1.055 1.055 0 001.174.034l9.163-5.689a2.093 2.093 0 00.976-1.8 2.014 2.014 0 00-.117-.678 2.1 2.1 0 00-1.514-1.359 2.005 2.005 0 00-1.58.3L18.4 16.24a.25.25 0 01-.228.028l-5.487-2.043a.886.886 0 00-.834.12l-1.033.766a1.394 1.394 0 00-.149.139.9.9 0 00.144 1.28l.032.023L14.1 18.57a.25.25 0 01.011.418l-.909.634a.251.251 0 01-.27.011 78.918 78.918 0 00-1.469-.859.815.815 0 00-.762.154z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});