define("ember-svg-jar/inlined/mailbox-post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mailbox-post</title><path d=\"M17 0H7a4.505 4.505 0 00-4.5 4.5V23a1 1 0 002 0v-1.25a.25.25 0 01.25-.25h14.5a.25.25 0 01.25.25V23a1 1 0 002 0V4.5A4.505 4.505 0 0017 0zM9.171 11.808a.253.253 0 01.042-.284.25.25 0 01.281-.058l5.605 2.4a.251.251 0 010 .46l-5.6 2.4a.251.251 0 01-.323-.342l1.146-2.29zM6.75 2.5h10.5a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25H6.75a.25.25 0 01-.25-.25v-3.5a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});