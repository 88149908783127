define("ember-svg-jar/inlined/landmark-louvre", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-louvre</title><path d=\"M23.5 24a.5.5 0 00.5-.5v-11a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5v5.346a.25.25 0 01-.427.177l-.5-.5a.249.249 0 01-.073-.177V6a.487.487 0 00-.337-.469h-.006l-4.494-1.5h-.006a.436.436 0 00-.314 0h-.006l-4.494 1.5h-.006A.487.487 0 007 6v11.346a.249.249 0 01-.073.177l-.5.5A.25.25 0 016 17.846V12.5a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5v11a.5.5 0 00.5.5zm-12.65-1.5a.25.25 0 01-.169-.434l1.15-1.06a.249.249 0 01.338 0l1.15 1.06a.25.25 0 01-.169.434zm-4.534-.44a.25.25 0 01-.163.44H4.674a.25.25 0 01-.177-.427l.687-.687a.251.251 0 01.342-.012zM8.57 18a.251.251 0 01.347-.007l1.8 1.665a.231.231 0 010 .341L8.7 21.84a.249.249 0 01-.332 0l-1.753-1.504a.25.25 0 01-.014-.367zm8.839 1.979a.251.251 0 01-.014.367l-1.766 1.509a.249.249 0 01-.332-.006L13.3 20a.243.243 0 010-.356L15.094 18a.249.249 0 01.345.007zm-3.393-3.393a.25.25 0 01-.006.361l-1.831 1.7a.25.25 0 01-.339 0l-1.848-1.7a.249.249 0 01-.008-.36l1.839-1.839a.25.25 0 01.354 0zm4.468 4.8a.25.25 0 01.34.012l.679.679a.25.25 0 01-.177.427h-1.472a.25.25 0 01-.161-.441zM10 9.61a2.072 2.072 0 011.66-2.08A2 2 0 0114 9.5V12a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5zM1.5 15.5a1.5 1.5 0 013 0v2a.5.5 0 01-.5.5H2a.5.5 0 01-.5-.5zm21 2a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a1.5 1.5 0 013 0z\"/><path d=\"M22.5 8h-4a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-1A1.5 1.5 0 0022.5 8zM0 10.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-4A1.5 1.5 0 000 9.5zM16 0a1 1 0 00-1 1 .144.144 0 01-.218.124A4.469 4.469 0 0012.5.5h-1a4.469 4.469 0 00-2.282.624A.144.144 0 019 1a1 1 0 00-2 0v3.239a.25.25 0 00.329.237l4.2-1.4a1.507 1.507 0 01.948 0l4.2 1.4A.25.25 0 0017 4.239V1a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});