define("ember-svg-jar/inlined/single-neutral-home.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-home</title><path d=\"M22.875 7.747L12.832.76a1.464 1.464 0 00-1.664 0L1.125 7.747A1.455 1.455 0 00.5 8.943v13.1A1.458 1.458 0 001.957 23.5h20.086a1.458 1.458 0 001.457-1.458V8.943a1.455 1.455 0 00-.625-1.196zM11.992 8a3 3 0 11-3.012 3 3.007 3.007 0 013.012-3zm4.923 11.9a.316.316 0 01-.229.1H7.5a.5.5 0 01-.5-.555 5.021 5.021 0 0110 .222.312.312 0 01-.085.233z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});