define("ember-svg-jar/inlined/single-neutral-actions-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-view</title><path d=\"M0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.672a.251.251 0 00.246-.294 6.847 6.847 0 01-.11-1.206 6.954 6.954 0 011.213-3.926.248.248 0 00.032-.215A5.5 5.5 0 000 13.75z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M11 16.25a5.495 5.495 0 008.448 4.639.25.25 0 01.311.034l2.534 2.534a1 1 0 001.414-1.414l-2.534-2.534a.25.25 0 01-.034-.311A5.5 5.5 0 1011 16.25zm5.5-3.5a3.5 3.5 0 11-3.5 3.5 3.5 3.5 0 013.5-3.5zM0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.672a.251.251 0 00.246-.294 6.847 6.847 0 01-.11-1.206 6.954 6.954 0 011.213-3.926.248.248 0 00.032-.215A5.5 5.5 0 000 13.75z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M11 16.25a5.495 5.495 0 008.448 4.639.25.25 0 01.311.034l2.534 2.534a1 1 0 001.414-1.414l-2.534-2.534a.25.25 0 01-.034-.311A5.5 5.5 0 1011 16.25zm5.5-3.5a3.5 3.5 0 11-3.5 3.5 3.5 3.5 0 013.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});