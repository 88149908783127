define("ember-svg-jar/inlined/trees-camp-fire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trees-camp-fire</title><path d=\"M23.819 14.91L21.781 12a.328.328 0 01.268-.516 1 1 0 00.844-1.537L20.5 6.193A.253.253 0 0120.471 6a.256.256 0 01.116-.157 1 1 0 00.347-1.374L18.388.226a.52.52 0 00-.857 0L15.48 3.645a.5.5 0 00-.037.44 2.506 2.506 0 01-.021 1.882.5.5 0 00.04.462L17.2 9.157a2.5 2.5 0 01.079 2.55 2.373 2.373 0 01-.2.309.5.5 0 00-.011.589l1.022 1.46a2.505 2.505 0 01-.865 3.639.5.5 0 00-.263.441v1.838a1 1 0 002 0v-3a.5.5 0 01.5-.5H23a1 1 0 00.82-1.573z\"/><path d=\"M16.859 14.91L14.821 12a.328.328 0 01.268-.516 1 1 0 00.844-1.537l-2.389-3.754a.252.252 0 01.083-.35 1 1 0 00.348-1.374L11.429.226a.52.52 0 00-.858 0L8.026 4.469a1 1 0 00-.013 1.007.991.991 0 00.36.367.252.252 0 01.083.35L6.067 9.946a1 1 0 00.844 1.537.327.327 0 01.268.517l-.5.709a.5.5 0 00.048.632 10.21 10.21 0 011.92 2.85.5.5 0 00.456.294H9.5a.5.5 0 01.5.5v3a1 1 0 002 0v-3a.5.5 0 01.5-.5h3.54a1 1 0 00.819-1.573zM4 13.055a.642.642 0 00-.95.708 7 7 0 01-.007 4.152.5.5 0 01-.867.122 37.995 37.995 0 01-.284-.383.642.642 0 00-1.075.062A5.346 5.346 0 000 20a4 4 0 008 0 8.366 8.366 0 00-4-6.945z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});