define("ember-svg-jar/inlined/os-system-microsoft", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>os-system-microsoft</title><path d=\"M20.77 14.22a11 11 0 01-3.68.7A6.39 6.39 0 0113 13.6a.5.5 0 00-.79.25l-2 7a.5.5 0 000 .32c.18.45 3 1.78 4.62 1.78a11.52 11.52 0 004.35-1 .5.5 0 00.29-.32l2-6.84a.5.5 0 00-.65-.61zM10.74 12.67a7.72 7.72 0 00-4.17-1.42 12.11 12.11 0 00-4.22 1 .5.5 0 00-.29.32L0 19.6a.5.5 0 00.67.6 9.87 9.87 0 013.64-.83 6.45 6.45 0 013.78 1.33.5.5 0 00.28.08.49.49 0 00.17 0 .5.5 0 00.31-.33L11 13.24a.5.5 0 00-.26-.57zM3.19 10.16a10 10 0 013.75-.88 6.41 6.41 0 013.64 1.32.5.5 0 00.28.08.49.49 0 00.17 0 .5.5 0 00.31-.33l2.07-7.09a.5.5 0 000-.14.5.5 0 00-.24-.43 7.62 7.62 0 00-4.4-1.39 11.09 11.09 0 00-4 .93.5.5 0 00-.29.33l-2 7a.5.5 0 00.67.6zM23.83 4.18a.5.5 0 00-.53-.08 9.37 9.37 0 01-3.73.9 6.48 6.48 0 01-4-1.35.49.49 0 00-.46-.08.5.5 0 00-.33.34L12.68 11a.62.62 0 00.22.57A8.27 8.27 0 0017.56 13a9.64 9.64 0 004.11-1 .51.51 0 00.17-.13.51.51 0 00.11-.19l2-7a.5.5 0 00-.12-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});