define("ember-svg-jar/inlined/boxing-head-guard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>boxing-head-guard</title><path d=\"M19.894 1.9a17.359 17.359 0 00-15.779 0 4.373 4.373 0 00-2.479 3.936v11.619A6.546 6.546 0 008.182 24h7.636a6.546 6.546 0 006.546-6.545V5.829a4.367 4.367 0 00-2.47-3.929zM7.091 3.818a.545.545 0 01.545-.545h8.728a.545.545 0 01.545.545V6a.545.545 0 01-.545.545H7.636A.545.545 0 017.091 6zm12 6.744a4.336 4.336 0 01-1.5 3.28c-3.906 3.371-.145 4.568.946 5.4a1.092 1.092 0 01-1.017 1.487H6.5a1.091 1.091 0 01-1.019-1.483c1.091-.84 4.839-2.04.934-5.387a4.306 4.306 0 01-1.506-3.273V9.273a.545.545 0 01.546-.545h13.09a.545.545 0 01.546.545z\"/><path d=\"M10.273 12.818v-1.636a1 1 0 10-2 0v1.636a1 1 0 102 0zM14.727 10.182a1 1 0 00-1 1v1.636a1 1 0 102 0v-1.636a1 1 0 00-1-1zM13.636 16.455h-3.272a1 1 0 000 2h3.272a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});