define("ember-svg-jar/inlined/color-brush", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-brush</title><path d=\"M19.764 10h-4.671a.249.249 0 01-.249-.275l.619-6.19a3.215 3.215 0 10-6.4 0l.619 6.19a.251.251 0 01-.247.275H4.764a2.5 2.5 0 00-2.5 2.5v1a1 1 0 001 1h18a1 1 0 001-1v-1a2.5 2.5 0 00-2.5-2.5zm-6.5-6.25a1 1 0 11-1-1 1 1 0 011 1zM21.264 16.5a.5.5 0 00-.5-.5h-17a.5.5 0 00-.5.5v1.3A8.607 8.607 0 011.9 22.46 1 1 0 002.737 24h1.5a3.493 3.493 0 002.911-1.558c.213-.317.439-.662.661-1a.25.25 0 01.46.136V23a1 1 0 001 1h4.25a.25.25 0 00.25-.25v-2.5a.5.5 0 011 0v2.5a.249.249 0 00.25.25h2a.25.25 0 00.25-.25V19.5a.5.5 0 011 0v4.25a.249.249 0 00.25.25h1.75a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});