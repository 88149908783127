define("ember-svg-jar/inlined/disability-sit-walking-aid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-sit-walking-aid</title><circle cx=\"5.747\" cy=\"2.754\" r=\"2.5\"/><path d=\"M9.625 18.493h-6.5a1 1 0 000 2h6.5a1 1 0 000-2z\"/><path d=\"M14.406 17.438a3.483 3.483 0 00-3.485-3.184H8.5A.507.507 0 018 13.8l-.476-3.26a.249.249 0 01.247-.286h3.854a1.5 1.5 0 000-3H5.747a1.5 1.5 0 00-1.484 1.717l.766 5.247A3.512 3.512 0 008.5 17.254h2.423a.493.493 0 01.5.439l.335 4.67a1.5 1.5 0 102.99-.24zM20.875 4.246h-6.5a1 1 0 000 2h.253V12a4.03 4.03 0 001.168 2.829l.832.834v7.083a1 1 0 002 0v-7.089l.825-.824a3.968 3.968 0 001.175-2.827v-5.76h.247a1 1 0 000-2zM18.625 12a2.41 2.41 0 01-1 1.828 2.4 2.4 0 01-1-1.831V6.491a.249.249 0 01.25-.249h1.5a.249.249 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});