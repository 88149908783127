define("ember-svg-jar/inlined/cellular-network-lte", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cellular-network-lte</title><path d=\"M8.5 22H6a.5.5 0 01-.5-.5V15a1 1 0 00-2 0v6.5A2.5 2.5 0 006 24h2.5a1 1 0 000-2zM19.5 22H17a.5.5 0 01-.5-.5V20H18a1 1 0 000-2h-1.5v-1.5a.5.5 0 01.5-.5h2.5a1 1 0 000-2H17a2.5 2.5 0 00-2.5 2.5v5A2.5 2.5 0 0017 24h2.5a1 1 0 000-2zM13.5 14h-4a1 1 0 000 2h1v7a1 1 0 002 0v-7h1a1 1 0 000-2zM15.59 11.041a.5.5 0 00.814 0l2.5-3.5a.5.5 0 00-.404-.791h-1.25v-5.5a1.25 1.25 0 00-2.5 0v5.5H13.5a.5.5 0 00-.407.791zM6 4.5h1.25V10a1.25 1.25 0 002.5 0V4.5H11a.5.5 0 00.407-.791L8.9.209a.519.519 0 00-.814 0l-2.5 3.5A.5.5 0 006 4.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});