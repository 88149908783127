define("ember-svg-jar/inlined/watch-time-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>watch-time-alternate</title><path d=\"M12 7a5 5 0 105 5 5.006 5.006 0 00-5-5zm2.53 7.531a.755.755 0 01-1.06 0l-2-2a.751.751 0 01-.22-.531v-2a.75.75 0 111.5 0v1.69l1.78 1.78a.75.75 0 010 1.062z\"/><path d=\"M20 12a8 8 0 00-3.5-6.61V2a2 2 0 00-2-2h-5a2 2 0 00-2 2v3.39a7.993 7.993 0 000 13.221V22a2 2 0 002 2h5a2 2 0 002-2v-3.388A8 8 0 0020 12zm-8 6a6 6 0 116-6 6.006 6.006 0 01-6 6zM9.5 2h5v2.4a7.994 7.994 0 00-5 0zm5 20h-5v-2.4a8.012 8.012 0 005 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});