define("ember-svg-jar/inlined/phone-action-flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-flash</title><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/><path d=\"M14.75 10h-1a.249.249 0 01-.247-.285V6.5a.5.5 0 00-.879-.326l-3.944 4.59A.75.75 0 009.25 12h1a.247.247 0 01.247.283V15.5a.514.514 0 00.5.5.5.5 0 00.379-.174l3.941-4.59A.75.75 0 0014.75 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});