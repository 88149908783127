define("ember-svg-jar/inlined/navigation-arrows-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-arrows-down</title><path d=\"M21.709 13.085h-1.4a.5.5 0 00-.337.13l-6.964 6.333a1.494 1.494 0 01-2.018 0l-6.965-6.333a.5.5 0 00-.336-.13h-1.4a.986.986 0 00-.921.626.975.975 0 00.254 1.114l9.693 8.814a.99.99 0 00.679.271 1.019 1.019 0 00.681-.264l9.692-8.814a.971.971 0 00.271-1.1.994.994 0 00-.929-.647z\"/><path d=\"M11.315 17.139a.99.99 0 00.679.271 1.019 1.019 0 00.681-.264l9.692-8.814a.971.971 0 00.271-1.1.994.994 0 00-.929-.648h-1.4a.5.5 0 00-.337.13l-6.964 6.333a1.494 1.494 0 01-2.018 0L4.025 6.715a.5.5 0 00-.336-.13h-1.4a.986.986 0 00-.921.626.975.975 0 00.254 1.114z\"/><path d=\"M11.325 10.649a1 1 0 001.345 0l9.707-8.823a1 1 0 00-.672-1.74H2.292a1 1 0 00-.673 1.739z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});