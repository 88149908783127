define("ember-svg-jar/inlined/monitor-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-lock</title><path d=\"M17.25 20.708h-2.7a.251.251 0 01-.233-.158 2.481 2.481 0 00-4.632 0 .251.251 0 01-.233.158H6.75a.75.75 0 000 1.5h2.7a.251.251 0 01.233.159 2.481 2.481 0 004.632 0 .251.251 0 01.233-.159h2.7a.75.75 0 000-1.5zM12 22.458a1 1 0 111-1 1 1 0 01-1 1zM1.25 20.708h-.5a.75.75 0 000 1.5h.5a.75.75 0 000-1.5zM4.25 20.708h-.5a.75.75 0 000 1.5h.5a.75.75 0 000-1.5zM20.25 20.708h-.5a.75.75 0 000 1.5h.5a.75.75 0 000-1.5zM23.25 20.708h-.5a.75.75 0 000 1.5h.5a.75.75 0 000-1.5zM22 2.041a2 2 0 00-2-2h-2.5a1 1 0 000 2h2.25a.25.25 0 01.25.25v8.5a.25.25 0 01-.25.25H4.25a.25.25 0 01-.25-.25v-8.5a.25.25 0 01.25-.25H7a1 1 0 100-2H4a2 2 0 00-2 2v10.5a2 2 0 002 2h6.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-5a1 1 0 000 2h12.5a1 1 0 000-2h-5a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H20a2 2 0 002-2z\"/><path d=\"M8.5 8.041a1.5 1.5 0 001.5 1.5h4a1.5 1.5 0 001.5-1.5v-3a1.491 1.491 0 00-.643-1.223.249.249 0 01-.107-.205v-.572a2.75 2.75 0 00-5.5 0v.572a.249.249 0 01-.107.205A1.491 1.491 0 008.5 5.041zm4.5-1.48a1 1 0 11-1-1 1 1 0 011 1zm-1-4.77a1.252 1.252 0 011.25 1.25v.25a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25v-.25A1.252 1.252 0 0112 1.791z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});