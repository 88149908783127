define("ember-svg-jar/inlined/vectors-path-flat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vectors-path-flat</title><path d=\"M22.5 17.25h-.326a.25.25 0 01-.248-.219 10.026 10.026 0 00-7.776-8.548A.19.19 0 0114 8.3a.2.2 0 01.115-.179A1.5 1.5 0 0015 6.75V6.5a.25.25 0 01.25-.25h1.127a.25.25 0 01.242.188 3.751 3.751 0 10.155-2.344.251.251 0 01-.232.156H15.25A.25.25 0 0115 4v-.25a1.5 1.5 0 00-1.5-1.5h-3A1.5 1.5 0 009 3.75V4a.25.25 0 01-.25.25H7.458a.251.251 0 01-.232-.156 3.749 3.749 0 10.155 2.344.25.25 0 01.242-.188H8.75A.25.25 0 019 6.5v.25a1.5 1.5 0 00.885 1.368A.2.2 0 0110 8.3a.19.19 0 01-.15.186 10.026 10.026 0 00-7.776 8.548.25.25 0 01-.248.219H1.5a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1v-3a1 1 0 00-1-1h-.149a.25.25 0 01-.247-.291 8 8 0 0115.792 0 .25.25 0 01-.247.291H19.5a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1v-3a1 1 0 00-1-1.003zm-2.25-13.5A1.75 1.75 0 1118.5 5.5a1.752 1.752 0 011.75-1.75zm-16.5 3.5A1.75 1.75 0 115.5 5.5a1.752 1.752 0 01-1.75 1.75zM13 6a.25.25 0 01-.25.25h-1.5A.25.25 0 0111 6V4.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});