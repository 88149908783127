define("ember-svg-jar/inlined/style-three-pin-stationary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-stationary</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm1.584 5.471c.154-.527 1.026-1.2 1.605-1.6a.541.541 0 01.779.227 4.413 4.413 0 01.5 2.208 1.5 1.5 0 11-2.88-.839zm-.92 3.465a.5.5 0 01.635-.411l1.767.514a.5.5 0 01.314.689l-.726 1.625a.25.25 0 01-.228.148h-1.85a.25.25 0 01-.247-.287zm-1.746 2.279a.251.251 0 01-.247.285H9.155a.25.25 0 01-.247-.215L8.459 8.12a.25.25 0 01.213-.282l1.485-.212a.249.249 0 01.282.212zm-.677-4.78a.168.168 0 01-.142.189l-1.648.235a.17.17 0 01-.191-.143l.211-2.335a.5.5 0 01.919-.13zM16.5 16a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h8a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});