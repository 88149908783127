define("ember-svg-jar/inlined/single-neutral-actions-refresh.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-refresh</title><path d=\"M11.335 12.419a.5.5 0 00-.117-.741 7.475 7.475 0 00-11.2 4.179.5.5 0 00.482.643h8.64a.5.5 0 00.492-.411 7.96 7.96 0 011.703-3.67z\"/><circle cx=\"7\" cy=\"4.75\" r=\"4.75\"/><path d=\"M23.391 19.871a1 1 0 10-1.892-.648 4.091 4.091 0 01-6.918 1.419.25.25 0 01.008-.345l1.722-1.721a.5.5 0 00-.354-.853H11.5a.5.5 0 00-.5.5v4.457a.5.5 0 00.854.353l.969-.969a.249.249 0 01.359 0 6.085 6.085 0 0010.209-2.2zM24 12.234a.5.5 0 00-.854-.353l-1.025 1.025a.249.249 0 01-.358 0 6.1 6.1 0 00-10.2 2.227 1 1 0 001.893.648 4.092 4.092 0 016.9-1.445.251.251 0 01-.007.347l-1.66 1.659a.5.5 0 00.354.853H23.5a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});