define("ember-svg-jar/inlined/design-document-3ds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-document-3ds</title><path d=\"M11.125 10.059v3.882a.249.249 0 00.331.24A2.237 2.237 0 0012.875 12a2.237 2.237 0 00-1.419-2.181.249.249 0 00-.331.24z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zm-13.375 9a2.014 2.014 0 01-1.865 2.121h-.757A1.736 1.736 0 014.875 13.8a.625.625 0 011.25 0c0 .339.2.575.375.575h.754c.337 0 .621-.4.621-.873s-.285-.873-.623-.873a.625.625 0 110-1.25c.338 0 .623-.4.623-.873s-.284-.872-.621-.873h-.757c-.175 0-.372.236-.372.575a.625.625 0 01-1.25 0A1.736 1.736 0 016.5 8.375h.76A2.014 2.014 0 019.125 10.5a2.29 2.29 0 01-.425 1.353.243.243 0 000 .294 2.29 2.29 0 01.425 1.353zm1.375 2.125A.625.625 0 019.875 15V9a.625.625 0 01.625-.625 3.625 3.625 0 010 7.25zm5.927-4.758l1.84 1.226a1.928 1.928 0 01-1.07 3.532H15.5a.625.625 0 010-1.25h1.7a.678.678 0 00.376-1.242l-1.84-1.226A1.928 1.928 0 0116.8 8.375h1.7a.625.625 0 010 1.25h-1.7a.678.678 0 00-.376 1.242z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});