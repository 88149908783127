define("ember-svg-jar/inlined/file-hqx-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-hqx-1</title><path d=\"M23.707 5.708L18.293.294A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.751V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.415a1 1 0 00-.293-.707z\"/><path d=\"M10 11.626a.876.876 0 00-.875.875V15a.875.875 0 001.75 0v-2.5a.876.876 0 00-.875-.874z\"/><path d=\"M20 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h18a1 1 0 001-1zM7.125 17a.625.625 0 01-1.25 0v-2.124a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V17a.625.625 0 01-1.25 0v-6a.625.625 0 011.25 0v2.125a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V11a.625.625 0 111.25 0zm5-2a2.112 2.112 0 01-.993 1.786c-.029.019-.044.088.029.147l.727.577a.634.634 0 01.1.879.627.627 0 01-.878.1l-1.93-1.53A2.126 2.126 0 017.875 15v-2.5a2.125 2.125 0 114.25 0zm4.934 1.721a.625.625 0 11-1.118.558s-.752-1.5-.814-1.629-.194-.12-.243-.023l-.825 1.652a.625.625 0 01-.559.346.625.625 0 01-.559-.9l1.3-2.609a.252.252 0 000-.224l-1.3-2.609a.625.625 0 111.118-.558s.778 1.564.833 1.667a.122.122 0 00.22-.008l.829-1.659a.625.625 0 111.118.558l-1.305 2.609a.252.252 0 000 .224z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});