define("ember-svg-jar/inlined/single-neutral-id-card-4.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-id-card-4</title><path d=\"M24 4.5a2 2 0 00-2-2H2a2 2 0 00-2 2v15a2 2 0 002 2h3a1 1 0 001-1v-.75a.75.75 0 01.75-.75.76.76 0 01.75.75v.75a1 1 0 001 1h7a1 1 0 001-1v-.75a.75.75 0 01.75-.75.76.76 0 01.75.75v.75a1 1 0 001 1h3a2 2 0 002-2zM22 19a.5.5 0 01-.5.5h-1.088a.5.5 0 01-.486-.385 2.75 2.75 0 00-5.352 0 .5.5 0 01-.486.385H9.912a.5.5 0 01-.486-.385 2.75 2.75 0 00-5.352 0 .5.5 0 01-.486.385H2.5A.5.5 0 012 19V5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M14.25 11.5a.75.75 0 00.75.75h4.783a.75.75 0 000-1.5H15a.75.75 0 00-.75.75zM14.25 14.5a.75.75 0 00.75.75h2.609a.75.75 0 000-1.5H15a.75.75 0 00-.75.75zM15 9.25h5a.75.75 0 000-1.5h-5a.75.75 0 000 1.5z\"/><circle cx=\"7.83\" cy=\"9\" r=\"2\"/><path d=\"M5.007 15h5.647a.5.5 0 00.471-.684A3.6 3.6 0 007.83 12a3.6 3.6 0 00-3.294 2.316.5.5 0 00.471.684z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});