define("ember-svg-jar/inlined/fishing-catch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fishing-catch</title><path d=\"M3.5 6.25a1 1 0 000 2h13a1 1 0 000-2zM21.5 4.25a1 1 0 00-1-1h-17a1 1 0 000 2h17a1 1 0 001-1zM20.5.25h-17a1 1 0 000 2h17a1 1 0 000-2zM20.828 23.492a.262.262 0 00.244.167h.017a.263.263 0 00.237-.2l.7-2.905a.255.255 0 01.093-.142c.519-.389.929-.734 1.208-.981a.519.519 0 00-.006-.779 19.417 19.417 0 00-5.012-3.2.245.245 0 01-.1-.084l-2.521-3.527a.513.513 0 00-.421-.219.521.521 0 00-.526.48l-.173 2.066a.25.25 0 01-.273.228c-.33-.032-.664-.053-1-.053s-.658.027-.982.059a.25.25 0 01-.225-.1l-1.173-1.564a.511.511 0 00-.414-.21.522.522 0 00-.522.45l-.257 1.8a.248.248 0 01-.17.2 15.679 15.679 0 00-2.056.838.25.25 0 01-.273-.035L5.977 14.72a.515.515 0 00-.337-.127.523.523 0 00-.521.6l.241 1.685a.253.253 0 01-.108.243c-.3.2-.578.4-.827.584a.251.251 0 01-.273.017L1.283 16.1a.52.52 0 00-.259-.071.525.525 0 00-.458.775l.678 1.247a2.093 2.093 0 010 2L.566 21.29a.525.525 0 00.458.775.531.531 0 00.259-.065l2.868-1.63a.25.25 0 01.274.018 15.405 15.405 0 008.863 3.362 13.764 13.764 0 006.6-1.891.25.25 0 01.355.128zM18.75 20a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});