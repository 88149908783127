define("ember-svg-jar/inlined/board-game-dice-pawn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>board-game-dice-pawn</title><path d=\"M20.967 15.2h-.607a.5.5 0 01-.5-.439l-.771-6.268A.5.5 0 0119.331 8a4.208 4.208 0 002.136-3.7 4.3 4.3 0 10-8.6 0A4.206 4.206 0 0015 8a.5.5 0 01.238.489l-.771 6.269a.5.5 0 01-.5.439h-.607a3.012 3.012 0 00-1.638.482.5.5 0 00-.229.421v3.4a.5.5 0 00.5.5H23.5a.5.5 0 00.5-.5v-1.267a3.037 3.037 0 00-3.033-3.033zM8.5 14h-7A1.5 1.5 0 000 15.5v7A1.5 1.5 0 001.5 24h7a1.5 1.5 0 001.5-1.5v-7A1.5 1.5 0 008.5 14zm-6 8.5a1 1 0 111-1 1 1 0 01-1 1zM5 20a1 1 0 111-1 1 1 0 01-1 1zm2.5-2.5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});