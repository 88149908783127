define("ember-svg-jar/inlined/shopping-basket-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-basket-sync</title><path d=\"M17.6 9.934a.251.251 0 00.23-.186l.043-.166a.5.5 0 00-.483-.627H2.379a.5.5 0 00-.489.6l1.276 5.994A1.956 1.956 0 005.132 17h4.7a.25.25 0 00.247-.214A8 8 0 0117.6 9.934zM6.628 14.645a.488.488 0 01-.085.007.5.5 0 01-.492-.416l-.408-2.386a.5.5 0 01.986-.168l.408 2.386a.5.5 0 01-.409.577zM10.5 14a.5.5 0 01-1 0v-2.385a.5.5 0 011 0zM19.763 6.167a1.441 1.441 0 00-1.439-1.439h-1.142a.25.25 0 01-.222-.135L14.848.538a1 1 0 00-1.774.924l1.511 2.9a.253.253 0 01-.008.246.251.251 0 01-.214.12H5.4a.25.25 0 01-.221-.366l1.51-2.9A1 1 0 004.916.538L2.8 4.593a.249.249 0 01-.221.135h-1.14a1.439 1.439 0 000 2.878h16.885a1.441 1.441 0 001.439-1.439zM22.889 18.867a1 1 0 00-1.271.622 3.713 3.713 0 01-6.253 1.3.249.249 0 01.007-.346l1.53-1.531a.461.461 0 00-.326-.787h-4.114a.461.461 0 00-.462.465v4.11a.462.462 0 00.788.327l.816-.816a.249.249 0 01.18-.073.245.245 0 01.178.077 5.712 5.712 0 009.549-2.082 1 1 0 00-.622-1.266z\"/><path d=\"M24 13.063a.461.461 0 00-.788-.326l-.877.877a.251.251 0 01-.357 0 5.706 5.706 0 00-9.527 2.1 1 1 0 001.893.648 3.709 3.709 0 016.23-1.325.25.25 0 01-.005.347L19.1 16.851a.462.462 0 00.326.788h4.114a.462.462 0 00.46-.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});