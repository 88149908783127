define("ember-svg-jar/inlined/safety-exit-door", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-exit-door</title><path d=\"M8.644 4.709a2.445 2.445 0 00-1.112 4.762 2.482 2.482 0 00.56.065 2.446 2.446 0 00.552-4.827zM23 22.044h-2.443a.245.245 0 01-.245-.244v-.012a.978.978 0 00-.978-.978.978.978 0 00-.978.978v.745A1.467 1.467 0 0019.823 24H23a.978.978 0 000-1.956z\"/><path d=\"M4.666 2.445a.489.489 0 01.489-.489h12.713a.487.487 0 01.489.486c0 2.11 0 10.487.007 13.24a.955.955 0 00.8.938h.021a.958.958 0 001.13-.945V1.956A1.956 1.956 0 0018.356 0H4.666A1.956 1.956 0 002.71 1.956v19.6a.489.489 0 01-.489.489H1A.978.978 0 001 24h2.2a1.467 1.467 0 001.467-1.467z\"/><path d=\"M18.366 17.259a1.369 1.369 0 00-.143.007l-3.13.24h-.089a.978.978 0 01-.874-.541l-1.949-3.9a.246.246 0 01.02-.251.243.243 0 01.231-.1 2.336 2.336 0 011.064.412l1.108.708a1.429 1.429 0 002.02-.514 1.428 1.428 0 00-.411-1.845l-1.013-.73a5.406 5.406 0 00-3.139-1 5.262 5.262 0 00-2.224.49l-2.581 1.11c-.1.036-.864.318-1.38.5a.243.243 0 00-.163.23v2.105a.249.249 0 00.248.245 5.873 5.873 0 002.371-.352L9 13.785a.244.244 0 01.315.115l.873 1.745-2.453 1.227a1.957 1.957 0 00-1.073 1.946l.357 3.575a1.467 1.467 0 102.92-.292l-.291-2.9 1.633-.817a.243.243 0 01.187-.013.24.24 0 01.141.122l.046.092a3.4 3.4 0 003.053 1.892q.159 0 .318-.015l3.463-.27a1.467 1.467 0 00-.123-2.928z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});