define("ember-svg-jar/inlined/book-download-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-download-1</title><path d=\"M19.25 4.271a.5.5 0 01-.25-.433V2a2 2 0 00-2-2H6.75a3 3 0 00-3 3v15a2.988 2.988 0 002.185 2.872.249.249 0 00.3-.344A2.489 2.489 0 019.182 17.1a.25.25 0 00.318-.241V13a2.5 2.5 0 015 0v3.859a.25.25 0 00.318.241 2.488 2.488 0 012.9 3.536.25.25 0 00.222.364h.312a2 2 0 002-2V6a2 2 0 00-1.002-1.729zM5.75 3a1 1 0 011-1h10a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25h-10a1 1 0 01-1-1z\"/><path d=\"M16.207 18.793a1 1 0 00-1.414 0l-1.366 1.366a.25.25 0 01-.427-.177V13a1 1 0 00-2 0v6.982a.25.25 0 01-.427.177l-1.366-1.366a1 1 0 10-1.414 1.414l3.5 3.5a1 1 0 001.414 0l3.5-3.5a1 1 0 000-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});