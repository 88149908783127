define("ember-svg-jar/inlined/road-sign-look-both-ways-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-look-both-ways-1-alternate</title><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/><path d=\"M10.053 8.533l-3.1 3.013a.25.25 0 01-.351 0l-.072-.076a.75.75 0 00-1.28.53v2a.75.75 0 00.75.75h2a.75.75 0 00.53-1.28l-.158-.159a.25.25 0 01-.074-.179.254.254 0 01.076-.177l3.073-2.988a1 1 0 10-1.394-1.434zM18.287 11.307a.749.749 0 00-.817.163l-.074.073a.25.25 0 01-.351 0l-3.1-3.013a1 1 0 00-1.394 1.434l3.073 2.988a.254.254 0 01.076.177.25.25 0 01-.074.179l-.158.159A.75.75 0 0016 14.75h2a.75.75 0 00.75-.75v-2a.75.75 0 00-.463-.693z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});