define("ember-svg-jar/inlined/design-file-ai", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-ai</title><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.427A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6zM10 13.125a.877.877 0 00-.875.875v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V14a.877.877 0 00-.875-.875z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.25a.25.25 0 00-.25-.25zm-9.625 11.5a.625.625 0 01-1.25 0v-2.125a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V18.5a.625.625 0 01-1.25 0v-4.375a2.213 2.213 0 011.9-2.239A2.128 2.128 0 0112.125 14zm3.247-.625h.128a.625.625 0 110 1.25h-2a.625.625 0 110-1.25h.126a.249.249 0 00.249-.249v-4.249a.252.252 0 00-.252-.252H13.5a.625.625 0 110-1.25h2a.625.625 0 010 1.25h-.126a.248.248 0 00-.249.249v4.249a.252.252 0 00.247.252z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});