define("ember-svg-jar/inlined/style-three-pin-snowboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-snowboard</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm1.75 3a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm2.962 13.513a.749.749 0 01-.475.948 4.854 4.854 0 01-3.556-.032l-7.016-3.508a.75.75 0 11.67-1.342l7 3.5a3.478 3.478 0 002.428-.04.746.746 0 01.949.474zm-.376-8.2a1 1 0 11-.672 1.882l-1.888-.674a.25.25 0 00-.333.208 5.251 5.251 0 00.892 3.474 1 1 0 01-1.67 1.1 7.7 7.7 0 01-.965-2.063.25.25 0 00-.448-.068l-.422.633A1 1 0 019.167 11.7l1.882-2.822a.25.25 0 00-.124-.374l-2.261-.813a1 1 0 11.672-1.882z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});