define("ember-svg-jar/inlined/smart-watch-circle-dumbbell-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-dumbbell-alternate</title><path d=\"M21.5 12A9.5 9.5 0 0017 3.927a.245.245 0 01-.109-.148L15.983.373A.5.5 0 0015.5 0h-7a.5.5 0 00-.484.371l-.908 3.408A.245.245 0 017 3.927a9.5 9.5 0 000 16.149.25.25 0 01.109.148l.908 3.407A.5.5 0 008.5 24h7a.5.5 0 00.482-.368l.935-3.428a.247.247 0 01.109-.146A9.5 9.5 0 0021.5 12zM12 19.5a7.5 7.5 0 117.5-7.5 7.509 7.509 0 01-7.5 7.5z\"/><path d=\"M17.5 11.252h-.75A.25.25 0 0116.5 11v-.5A1.5 1.5 0 0015 9h-1.5a.5.5 0 00-.5.5V11a.25.25 0 01-.25.25h-1.5A.25.25 0 0111 11V9.5a.5.5 0 00-.5-.5H9a1.5 1.5 0 00-1.5 1.5v.5a.25.25 0 01-.25.25H6.5a.75.75 0 000 1.5h.75a.25.25 0 01.25.25v.5A1.5 1.5 0 009 15h1.5a.5.5 0 00.5-.5V13a.25.25 0 01.25-.25h1.5A.25.25 0 0113 13v1.5a.5.5 0 00.5.5H15a1.5 1.5 0 001.5-1.5V13a.25.25 0 01.25-.25h.75a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});