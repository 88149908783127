define("ember-svg-jar/inlined/volleyball-net", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>volleyball-net</title><path d=\"M2 20.5a1 1 0 001-1V18a.5.5 0 01.5-.5h17a.5.5 0 01.5.5v1a1 1 0 002 0V7.5a1 1 0 00-1-1H2a1 1 0 00-1 1v12a1 1 0 001 1zM18 9a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5zm0 4.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5V15a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5zM13 9a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5zm0 4.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5V15a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5zM8 9a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5zm0 4.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5V15a.5.5 0 01-.5.5h-2A.5.5 0 018 15zM3 9a.5.5 0 01.5-.5h2A.5.5 0 016 9v1.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5zm0 4.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5V15a.5.5 0 01-.5.5h-2A.5.5 0 013 15zM23.719 22.665A11.782 11.782 0 0016.75 20.5a10.79 10.79 0 00-4.35.859 1 1 0 01-.8 0 10.787 10.787 0 00-4.35-.859 11.782 11.782 0 00-6.969 2.165A.75.75 0 00.75 24h22.5a.75.75 0 00.469-1.335z\"/><circle cx=\"18.75\" cy=\"2.75\" r=\"2.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});