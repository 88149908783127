define("ember-svg-jar/inlined/social-bookmark-stumble-upon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-bookmark-stumble-upon</title><path d=\"M22.5 1A1.5 1.5 0 0021 2.5V18a1.85 1.85 0 01-1.8 2 1.78 1.78 0 01-1.7-2v-6.5A3.5 3.5 0 0014 8H8.5a4.5 4.5 0 000 9 1.5 1.5 0 010 3h-7a1.5 1.5 0 000 3h7a4.5 4.5 0 000-9 1.5 1.5 0 010-3H14a.5.5 0 01.5.5V18a4.75 4.75 0 004.7 5 4.85 4.85 0 004.8-5V2.5A1.5 1.5 0 0022.5 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});