define("ember-svg-jar/inlined/armchair-modern-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>armchair-modern-1</title><path d=\"M22 10a10 10 0 10-11.463 9.891.25.25 0 01.213.247v1.112a.25.25 0 01-.25.25h-4a1.25 1.25 0 000 2.5h11a1.25 1.25 0 000-2.5h-4a.25.25 0 01-.25-.25v-1.112a.25.25 0 01.213-.247A10.022 10.022 0 0022 10zM8.19 14.741a.524.524 0 01-.47-.05.51.51 0 01-.22-.421V8.531a3 3 0 00-.66-1.87l-.951-1.2a.5.5 0 01.011-.632 7.992 7.992 0 0112.2 0 .5.5 0 01.009.636l-.949 1.186a3.047 3.047 0 00-.66 1.88v5.739a.51.51 0 01-.22.421.524.524 0 01-.47.05 10.137 10.137 0 00-7.62 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});