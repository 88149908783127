define("ember-svg-jar/inlined/design-file-dxf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-dxf</title><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.427A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6zM6.456 13.319a.249.249 0 00-.331.24v3.882a.25.25 0 00.331.241A2.239 2.239 0 007.875 15.5a2.238 2.238 0 00-1.419-2.181z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.25a.25.25 0 00-.25-.25zM5.5 19.125a.625.625 0 01-.625-.625v-6a.625.625 0 01.625-.625 3.625 3.625 0 010 7.25zm8.559-.9a.625.625 0 11-1.118.558s-.859-1.7-.879-1.759-.1-.043-.127.007-.876 1.752-.876 1.752a.625.625 0 11-1.118-.558l1.3-2.609a.252.252 0 000-.224l-1.3-2.609a.625.625 0 011.118-.558s.826 1.645.854 1.709a.1.1 0 00.177-.006l.851-1.7a.625.625 0 011.118.558l-1.3 2.609a.252.252 0 000 .224zm4.441-5.1h-2a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25H17.5a.625.625 0 010 1.25h-1.125a.25.25 0 00-.25.25V18.5a.625.625 0 01-1.25 0v-5a1.626 1.626 0 011.625-1.625h2a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});