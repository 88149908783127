define("ember-svg-jar/inlined/design-tool-wireframe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-tool-wireframe</title><path d=\"M12.062 20.131a.251.251 0 00-.422.128l-.628 3.141a.5.5 0 00.49.6.473.473 0 00.1-.01l3.143-.629a.25.25 0 00.127-.422zM23.228 11.765a2.62 2.62 0 00-3.7 0l-6.5 6.5a.5.5 0 000 .707l3 3a.5.5 0 00.707 0l6.5-6.5a2.621 2.621 0 00-.007-3.707z\"/><rect x=\"3.5\" y=\"3.5\" width=\"11.5\" height=\"4.5\" rx=\".5\" ry=\".5\"/><path d=\"M8.123 9.5H4a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h5.837a.5.5 0 00.481-.637l-1.714-6a.5.5 0 00-.481-.363zM12.433 16.5a.577.577 0 00.406-.167l1.868-1.869a1 1 0 00.293-.707V10a.5.5 0 00-.5-.5h-3.837a.5.5 0 00-.481.638l1.7 5.946a.573.573 0 00.551.416z\"/><path d=\"M10.577 18H2.5a.5.5 0 01-.5-.5v-15a.5.5 0 01.5-.5H16a.5.5 0 01.5.5v9.567a.25.25 0 00.427.177l1.279-1.279a1 1 0 00.293-.707V2a2 2 0 00-2-2H2a2 2 0 00-2 2v16a2 2 0 002 2h7.751a.5.5 0 00.49-.4l.048-.241a2.009 2.009 0 01.477-.943.251.251 0 00-.189-.416z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});