define("ember-svg-jar/inlined/accessories-winter-gloves", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accessories-winter-gloves</title><path d=\"M16.686 7.968a.25.25 0 01-.394-.2V6c0-3.2-3.038-6-6.5-6-3.342 0-6.5 2.673-6.5 5.5v13a1.5 1.5 0 001.5 1.5h10a1.5 1.5 0 001.5-1.5v-1.26a2 2 0 01.413-1.212l3.595-4.674a2.524 2.524 0 00-.884-3.584 2.589 2.589 0 00-2.73.198zM14.792 21h-10a.5.5 0 00-.5.5V23a1 1 0 001 1h9a1 1 0 001-1v-1.5a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});