define("ember-svg-jar/inlined/dresser-wardrobe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dresser-wardrobe</title><path d=\"M10.75 0h-7.5a1.5 1.5 0 00-1.5 1.5v19a1.5 1.5 0 001.286 1.485.249.249 0 01.214.247V23a1 1 0 002 0v-.75A.25.25 0 015.5 22h5.25a.5.5 0 00.5-.5V.5a.5.5 0 00-.5-.5zm-2 11.75a1 1 0 111-1 1 1 0 01-1 1zM22.25 1.5a1.5 1.5 0 00-1.5-1.5h-7.5a.5.5 0 00-.5.5v21a.5.5 0 00.5.5h5.25a.25.25 0 01.25.25V23a1 1 0 002 0v-.768a.249.249 0 01.214-.247A1.5 1.5 0 0022.25 20.5zm-7 10.25a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});