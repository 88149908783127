define("ember-svg-jar/inlined/profile-man-cash-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>profile-man-cash-message</title><path d=\"M10.75 1.5v8a1.5 1.5 0 001.5 1.5h.25a.25.25 0 01.25.25v2.25a.5.5 0 00.825.38l3.289-2.819a.246.246 0 01.163-.061h5.223a1.5 1.5 0 001.5-1.5v-8a1.5 1.5 0 00-1.5-1.5h-10a1.5 1.5 0 00-1.5 1.5zm7.667 4.625h-2.334a1.792 1.792 0 010-3.583h.292a.25.25 0 00.25-.25V2a.625.625 0 011.25 0v.292a.25.25 0 00.25.25h1.458a.625.625 0 010 1.25h-3.5a.542.542 0 000 1.083h2.334a1.792 1.792 0 010 3.584h-.292a.249.249 0 00-.25.25V9a.625.625 0 01-1.25 0v-.291a.249.249 0 00-.25-.25h-1.458a.625.625 0 110-1.25h3.5a.542.542 0 000-1.084zM.75 24h11a.5.5 0 00.2-.959L8.9 21.735a.25.25 0 01-.152-.229v-1.065a.249.249 0 01.082-.185 1.281 1.281 0 00.1-.105 3.8 3.8 0 00.7-2.158.25.25 0 01.1-.194 1.741 1.741 0 00.628-1.395 1.436 1.436 0 00-.458-1.123.249.249 0 01-.077-.262 2.6 2.6 0 00-.192-2.232A3.5 3.5 0 006.75 11.5c-.93 0-2.971 0-3.43 1.4a.25.25 0 01-.17.163.984.984 0 00-.559.38 1.8 1.8 0 00.023 1.593.252.252 0 01-.069.262 1.659 1.659 0 00-.49 1.24 1.678 1.678 0 00.271.945 1.187 1.187 0 00.379.356.25.25 0 01.123.194 3.327 3.327 0 00.849 2.141.248.248 0 01.073.176v1.152a.251.251 0 01-.151.229l-3.046 1.31A.5.5 0 00.75 24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});