define("ember-svg-jar/inlined/phone-actions-call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-call</title><path d=\"M17.168 16.162a2.5 2.5 0 00-3.536 0l-.385.385a46.906 46.906 0 01-5.789-5.79l.386-.385a2.5 2.5 0 000-3.535L5.722 4.716a2.5 2.5 0 00-3.537 0L1.022 5.879a3.511 3.511 0 00-.441 4.4 46.906 46.906 0 0013.141 13.146 3.54 3.54 0 004.4-.443l1.165-1.165a2.5 2.5 0 000-3.534zM23 .007h-4.8a1 1 0 00-.7 1.707l1.334 1.336-5.9 5.894a1.5 1.5 0 002.122 2.121l5.9-5.894 1.334 1.335A1 1 0 0023 6.8a1.011 1.011 0 00.383-.077A1 1 0 0024 5.8V1.007a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});