define("ember-svg-jar/inlined/swimming-diving", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>swimming-diving</title><path d=\"M7.343 12.9a2.5 2.5 0 10-3.4-.955 2.5 2.5 0 003.4.955zM7.724 18.8a1.5 1.5 0 002.058-.515l2.5-4.162a2.515 2.515 0 01.833-.843l2.785-1.715a.248.248 0 00.112-.155.245.245 0 00-.03-.189l-1.31-2.129a.25.25 0 00-.344-.082l-2.784 1.713a5.518 5.518 0 00-1.833 1.854l-2.5 4.164a1.5 1.5 0 00.513 2.059zM17.586 10.526A5.432 5.432 0 0018.957 9.3a.255.255 0 00.053-.188.251.251 0 00-.1-.169l-2-1.5a.249.249 0 00-.339.037 2.43 2.43 0 01-.564.485l-.41.252a.251.251 0 00-.082.344L16.83 10.7a.251.251 0 00.344.082zM22.39.668a.5.5 0 00-.74-.589l-2.318 1.479a2 2 0 00-.807 1.013l-1.3 3.64a.249.249 0 00.086.284l2.058 1.543a.25.25 0 00.384-.113C20.285 6.493 22.39.668 22.39.668zM22.93 21.954c-1.588.144-2.667-1.556-2.675-1.569a1.048 1.048 0 00-1.716.022 3.372 3.372 0 01-2.753 1.558 2.8 2.8 0 01-2.4-1.483 1.045 1.045 0 00-1.717-.155 4.3 4.3 0 01-3.105 1.638 3.559 3.559 0 01-2.764-1.58 1.04 1.04 0 00-1.638-.091c-.014.019-1.442 1.831-3.11 1.7A.979.979 0 000 22.942C-.009 23.13.043 24 1.169 24A5.838 5.838 0 004.9 22.393a5.242 5.242 0 003.663 1.572 5.863 5.863 0 003.811-1.5 4.576 4.576 0 003.416 1.5 5.155 5.155 0 003.622-1.5 4.789 4.789 0 003.661 1.482 1 1 0 10-.139-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});