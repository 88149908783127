define("ember-svg-jar/inlined/button-fast-forward-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-fast-forward-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm0 21.439A9.439 9.439 0 1121.438 12 9.449 9.449 0 0112 21.438z\"/><path d=\"M18.13 11.826l-4.954-2.668a.749.749 0 00-1.143.684v.646a.257.257 0 01-.378.225L8.768 9.158a.75.75 0 00-1.144.684v5.289a.775.775 0 00.364.69.715.715 0 00.37.1.867.867 0 00.41-.108l2.887-1.554a.257.257 0 01.378.226v.645a.749.749 0 001.143.683l4.954-2.667a.722.722 0 000-1.321z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});