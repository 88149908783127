define("ember-svg-jar/inlined/gift-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gift-circle</title><path d=\"M16.719 7.28a1.726 1.726 0 00-2.439 0 10.117 10.117 0 00-1.225 3.364.249.249 0 00.067.235.246.246 0 00.235.066 10.092 10.092 0 003.362-1.225 1.727 1.727 0 000-2.44zM10.643 10.943a.252.252 0 00.235-.066.249.249 0 00.066-.235A10.083 10.083 0 009.72 7.28a1.725 1.725 0 00-2.44 2.44 10.26 10.26 0 003.363 1.223zM14.661 12.75a.25.25 0 00-.164.438l2 1.748a.75.75 0 11-.988 1.128l-2.341-2.049a.25.25 0 00-.268-.039.248.248 0 00-.147.227v9.239a.249.249 0 00.272.249 11.75 11.75 0 0010.67-10.669.25.25 0 00-.25-.272z\"/><path d=\"M12.75 6.09a.25.25 0 00.439.165 3.24 3.24 0 014.591-.035c.792.793 1.646 3.138-.032 4.591a.25.25 0 00.164.439h5.53a.25.25 0 00.185-.081.253.253 0 00.065-.191A11.75 11.75 0 0013.022.309a.248.248 0 00-.191.064.255.255 0 00-.081.185zM11.25 14.2a.248.248 0 00-.147-.227.25.25 0 00-.268.039l-2.341 2.052a.75.75 0 01-.988-1.128l2-1.748a.25.25 0 00-.164-.438H.558a.25.25 0 00-.185.081.253.253 0 00-.065.191 11.75 11.75 0 0010.67 10.669.248.248 0 00.191-.064.255.255 0 00.081-.185zM6.086 11.25a.25.25 0 00.163-.439c-1.68-1.448-.849-3.768-.03-4.591a3.275 3.275 0 014.593.035.25.25 0 00.438-.164V.558a.249.249 0 00-.272-.249A11.75 11.75 0 00.308 10.978a.25.25 0 00.25.272z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});