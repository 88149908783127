define("ember-svg-jar/inlined/fitness-jumping-rope-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fitness-jumping-rope-3</title><path d=\"M23.5 5.008a.5.5 0 00.5-.5V2a2 2 0 00-4 0v2.508a.5.5 0 00.5.5z\"/><path d=\"M20.5 6.508a.5.5 0 00-.5.5V8a1 1 0 00.667.943.5.5 0 01.333.471V18a4 4 0 01-8 0V6A6 6 0 001 6v8.586a.5.5 0 01-.333.471A1 1 0 000 16v1.008a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V16a1 1 0 00-.667-.943.5.5 0 01-.333-.471V6a4 4 0 018 0v12a6 6 0 0012 0V9.414a.5.5 0 01.333-.471A1 1 0 0024 8v-.992a.5.5 0 00-.5-.5z\"/><path d=\"M.5 19.008a.5.5 0 00-.5.5V22a2 2 0 004 0v-2.492a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});