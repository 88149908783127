define("ember-svg-jar/inlined/phone-action-disable-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-disable-1</title><path d=\"M11.751 14.642V18.5a1 1 0 01-1 1h-7a1 1 0 01-1-1v-11a1 1 0 011-1h3.8a.245.245 0 00.249-.228 8.554 8.554 0 01.4-1.946A.245.245 0 007.968 4H3.251a2.5 2.5 0 00-2.5 2.5v15a2.5 2.5 0 002.5 2.5h8a2.5 2.5 0 002.5-2.5v-6.209a.242.242 0 00-.17-.231 8.33 8.33 0 01-1.462-.63.245.245 0 00-.368.212z\"/><path d=\"M23.075 5.408A6.913 6.913 0 0017.84.175a7.015 7.015 0 00-8.414 8.41A7.008 7.008 0 0021.2 11.949a7.038 7.038 0 001.875-6.541zm-7.983 6.462a4.82 4.82 0 01-1.6-.7l6.932-6.93a4.772 4.772 0 01.7 1.6 5.05 5.05 0 01-1.336 4.694 4.983 4.983 0 01-4.696 1.336zm3.918-9.044l-6.932 6.932a5 5 0 016.932-6.932z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});