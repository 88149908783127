define("ember-svg-jar/inlined/single-neutral-actions-flash.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-flash</title><path d=\"M16.474 12.044a.249.249 0 00.051-.207.247.247 0 00-.127-.172 28.568 28.568 0 00-3.256-1.336l-.629-.231a.6.6 0 01-.138-.31 2.632 2.632 0 01.058-1.3A5.618 5.618 0 0013.9 4.174C13.9 1.756 12.32 0 10.143 0S6.387 1.756 6.387 4.174a5.605 5.605 0 001.451 4.3 2.505 2.505 0 01.073 1.317.614.614 0 01-.135.308l-.632.233c-2.446.9-4.214 1.549-4.753 2.626a9.167 9.167 0 00-.748 3.542.5.5 0 00.5.5h10.2a.251.251 0 00.218-.127 1.272 1.272 0 01.131-.2zM22.162 17.354A.822.822 0 0021.536 16H20.25a.25.25 0 01-.25-.25V11.5a.5.5 0 00-.887-.316l-5.275 6.462A.822.822 0 0014.464 19h1.286a.25.25 0 01.25.25v4.25a.5.5 0 00.332.471.516.516 0 00.168.029.5.5 0 00.388-.184z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});