define("ember-svg-jar/inlined/single-woman-actions-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-key</title><path d=\"M.141 17.931a.5.5 0 00.478.626h2.3a.247.247 0 01.243.2l.921 4.847v.007a.5.5 0 00.484.389h2.969a.5.5 0 00.483-.388v-.007l.921-4.847a.247.247 0 01.243-.2h.238a.247.247 0 00.242-.2 5.839 5.839 0 011.52-2.872.25.25 0 00.062-.24c-1.168-4.274-1.187-4.905-2.271-5.919a.5.5 0 00-.757.1L6.482 12.16a.5.5 0 01-.837 0L3.923 9.411a.5.5 0 00-.755-.1C2.014 10.373 2.1 10.78.141 17.931zM6.041 0a4.206 4.206 0 104.206 4.206A4.212 4.212 0 006.041 0zm0 6.433A2.223 2.223 0 013.9 4.81a.247.247 0 01.158-.3 4.63 4.63 0 001.8-1.128.247.247 0 01.353 0 4.615 4.615 0 001.8 1.128.248.248 0 01.157.3 2.223 2.223 0 01-2.127 1.623zM12.3 16.471a4.411 4.411 0 107.342 1.858.247.247 0 01.062-.247l4.025-4.016a.5.5 0 00.145-.35v-1.958a.494.494 0 00-.5-.495h-1.951a.491.491 0 00-.339.135l-4.146 3.916a.248.248 0 01-.242.057 4.391 4.391 0 00-4.396 1.1zm1.55 2.969a1.717 1.717 0 11.5 1.215 1.708 1.708 0 01-.498-1.215z\"/><path d=\"M.141 17.931a.5.5 0 00.478.626h2.3a.247.247 0 01.243.2l.921 4.847v.007a.5.5 0 00.484.389h2.969a.5.5 0 00.483-.388v-.007l.921-4.847a.247.247 0 01.243-.2h.238a.247.247 0 00.242-.2 5.839 5.839 0 011.52-2.872.25.25 0 00.062-.24c-1.168-4.274-1.187-4.905-2.271-5.919a.5.5 0 00-.757.1L6.482 12.16a.5.5 0 01-.837 0L3.923 9.411a.5.5 0 00-.755-.1C2.014 10.373 2.1 10.78.141 17.931zM6.041 0a4.206 4.206 0 104.206 4.206A4.212 4.212 0 006.041 0zm0 6.433A2.223 2.223 0 013.9 4.81a.247.247 0 01.158-.3 4.63 4.63 0 001.8-1.128.247.247 0 01.353 0 4.615 4.615 0 001.8 1.128.248.248 0 01.157.3 2.223 2.223 0 01-2.127 1.623zM12.3 16.471a4.411 4.411 0 107.342 1.858.247.247 0 01.062-.247l4.025-4.016a.5.5 0 00.145-.35v-1.958a.494.494 0 00-.5-.495h-1.951a.491.491 0 00-.339.135l-4.146 3.916a.248.248 0 01-.242.057 4.391 4.391 0 00-4.396 1.1zm1.55 2.969a1.717 1.717 0 11.5 1.215 1.708 1.708 0 01-.498-1.215z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});