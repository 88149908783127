define("ember-svg-jar/inlined/e-commerce-add-basket-cloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-add-basket-cloud</title><path d=\"M12 5a1 1 0 00-1 1v3a.25.25 0 01-.25.25H10a.75.75 0 00-.586 1.219l2 2.5a.752.752 0 001.172 0l2-2.5A.75.75 0 0014 9.25h-.75A.25.25 0 0113 9V6a1 1 0 00-1-1z\"/><path d=\"M6.631 10.935a1.092 1.092 0 00.179.016 1 1 0 00.176-1.985A1.243 1.243 0 015.8 7.589a1.423 1.423 0 01.524-1.116 1.281 1.281 0 011.1-.266 1 1 0 001.208-.921A3.431 3.431 0 0112 2a3.39 3.39 0 013.048 2 1 1 0 00.946.58 2.078 2.078 0 011.523.585 2.262 2.262 0 01.683 1.619 2.039 2.039 0 01-1.381 2.053 1 1 0 10.764 1.849 4.057 4.057 0 002.617-3.9 4.269 4.269 0 00-1.3-3.066 4.053 4.053 0 00-2.221-1.1.253.253 0 01-.174-.113 5.331 5.331 0 00-9.69 1.532.252.252 0 01-.2.182 3.3 3.3 0 00-1.547.7A3.416 3.416 0 003.8 7.589a3.222 3.222 0 002.831 3.346zM18 15.25a.142.142 0 01-.131-.088l-.944-2.293a1 1 0 00-1.85.762l.525 1.274a.251.251 0 01-.231.345h-6.6a.25.25 0 01-.238-.324l.429-1.378a1 1 0 00-1.91-.6l-.662 2.122a.249.249 0 01-.238.176H6a1.5 1.5 0 00-1.5 1.5v.5a.5.5 0 00.5.5h.125a.25.25 0 01.245.2l.912 4.573A1.92 1.92 0 008.2 24h7.461a2.119 2.119 0 002.093-1.66l.874-4.389a.25.25 0 01.245-.2H19a.5.5 0 00.5-.5v-.5A1.5 1.5 0 0018 15.25zm-2.966 4.15l-.306 1.5a.751.751 0 01-.734.6.781.781 0 01-.151-.015.749.749 0 01-.584-.885l.306-1.5a.75.75 0 111.469.3zm-4.777 2.087a.791.791 0 01-.152.015.75.75 0 01-.733-.6l-.307-1.5a.75.75 0 111.469-.3l.307 1.5a.75.75 0 01-.584.883z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});