define("ember-svg-jar/inlined/landmark-shrine-of-itsukushima", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-shrine-of-itsukushima</title><path d=\"M7.5 20h-5a.5.5 0 00-.493.418l-.5 3A.5.5 0 002 24h6a.5.5 0 00.493-.582l-.5-3A.5.5 0 007.5 20zM21.5 20h-5a.5.5 0 00-.493.418l-.5 3A.5.5 0 0016 24h6a.5.5 0 00.493-.582l-.5-3A.5.5 0 0021.5 20zM24 12a1 1 0 00-1-1h-1.75a.25.25 0 01-.25-.25v-3.5a.25.25 0 01.25-.25h.417a1.5 1.5 0 001.475-1.23l.839-4.583a1 1 0 00-1.643-.934C20.267 2.078 16.79 3 12 3S3.733 2.077 1.663.252a1 1 0 00-1.645.933l.84 4.587A1.5 1.5 0 002.334 7h.416a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25H1a1 1 0 000 2h1.75a.25.25 0 01.25.25V18a.5.5 0 00.5.5h3A.5.5 0 007 18v-4.75a.25.25 0 01.25-.25h9.5a.25.25 0 01.25.25V18a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-4.75a.25.25 0 01.25-.25H23a1 1 0 001-1zM7.25 11a.25.25 0 01-.25-.25v-3.5A.25.25 0 017.25 7h1.5a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25zm4 0a.25.25 0 01-.25-.25v-3.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25zM15 7.252A.25.25 0 0115.25 7h1.5a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25zM23.5 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});