define("ember-svg-jar/inlined/grid-artboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>grid-artboard</title><path d=\"M7 4.5a1 1 0 001-1V1a1 1 0 00-2 0v2.5a1 1 0 001 1zM4.5 7a1 1 0 00-1-1H1a1 1 0 000 2h2.5a1 1 0 001-1zM3.5 16H1a1 1 0 000 2h2.5a1 1 0 000-2zM17 0a1 1 0 00-1 1v2.5a1 1 0 002 0V1a1 1 0 00-1-1zM7 19.5a1 1 0 00-1 1V23a1 1 0 002 0v-2.5a1 1 0 00-1-1zM17 19.5a1 1 0 00-1 1V23a1 1 0 002 0v-2.5a1 1 0 00-1-1zM20.5 8H23a1 1 0 000-2h-2.5a1 1 0 000 2zM23 16h-2.5a1 1 0 000 2H23a1 1 0 000-2zM18 7a1 1 0 00-1-1H7a1 1 0 00-1 1v10a1 1 0 001 1h10a1 1 0 001-1zm-2 8.5a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-7a.5.5 0 01.5-.5h7a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});