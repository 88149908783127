define("ember-svg-jar/inlined/road-sign-side-road-left-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-side-road-left-alternate</title><path d=\"M14.5 7h-2a1 1 0 00-1 1v1.959a.251.251 0 01-.171.238l-3.4 1.131a1 1 0 00-.542 1.463l.973 1.622a1 1 0 001.174.435l1.632-.545a.25.25 0 01.329.237V16a1 1 0 001 1h2a1 1 0 001-1V8a1 1 0 00-.995-1z\"/><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});