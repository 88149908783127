define("ember-svg-jar/inlined/shorts-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shorts-2</title><path d=\"M22.422 3a.5.5 0 00.5-.526L22.841.948a1 1 0 00-1-.948H2.158a1 1 0 00-1 .947l-.08 1.527a.5.5 0 00.5.526zM24 22.947l-.947-17.973a.5.5 0 00-.5-.474H1.447a.5.5 0 00-.5.474L0 22.949A1 1 0 001 24h7.538a1 1 0 00.991-.862l1.26-9.083a1.223 1.223 0 012.422 0l1.26 9.083a1.006 1.006 0 00.991.862H23a1 1 0 001-1.053zM7.493 7.12A4.963 4.963 0 013.151 11a.535.535 0 01-.594-.558l.179-3.433a.533.533 0 01.532-.509h3.7a.534.534 0 01.527.62zm13.838 3.735a.536.536 0 01-.425.142 4.963 4.963 0 01-4.342-3.877.534.534 0 01.526-.62h3.7a.534.534 0 01.533.506l.178 3.433a.532.532 0 01-.17.416z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});