define("ember-svg-jar/inlined/renewable-energy-solar-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>renewable-energy-solar-panel</title><rect x=\"11.225\" y=\"20.25\" width=\"4\" height=\"3.75\" rx=\".5\" ry=\".5\"/><path d=\"M13.725 13.25v1.5a.5.5 0 00.5.5h7.7a.25.25 0 00.218-.371l-1.039-1.872a.5.5 0 00-.438-.257h-6.441a.5.5 0 00-.5.5zM12.225 12.75H5.781a.5.5 0 00-.437.257L4.3 14.879a.25.25 0 00.219.371h7.7a.5.5 0 00.5-.5v-1.5a.5.5 0 00-.494-.5zM13.725 9.75v1.5a.5.5 0 00.5.5h5.758a.25.25 0 00.219-.371l-.9-1.615a1 1 0 00-.874-.514h-4.203a.5.5 0 00-.5.5zM12.725 9.75a.5.5 0 00-.5-.5H8.019a1 1 0 00-.874.515l-.9 1.614a.25.25 0 00.218.371h5.758a.5.5 0 00.5-.5zM23.749 17.765l-.7-1.258a.5.5 0 00-.437-.257h-8.387a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h8.65a1 1 0 00.874-1.485zM12.725 18.75v-2a.5.5 0 00-.5-.5H3.836a.5.5 0 00-.437.257l-.7 1.258a1 1 0 00.875 1.485h8.65a.5.5 0 00.501-.5zM4.581 9.75a.75.75 0 101.117-1 2.25 2.25 0 113.927-1.5.75.75 0 001.5 0 3.75 3.75 0 10-6.544 2.5zM7.375 2.5a.75.75 0 00.75-.75v-1a.75.75 0 00-1.5 0v1a.75.75 0 00.75.75zM11.8 3.891l.707-.707a.75.75 0 10-1.061-1.06l-.707.707a.75.75 0 001.061 1.06zM12.125 7.25a.75.75 0 00.75.75h1a.75.75 0 000-1.5h-1a.75.75 0 00-.75.75zM2.956 3.891a.75.75 0 001.06-1.06l-.707-.707a.75.75 0 10-1.06 1.06zM3.309 12.376l.707-.707a.75.75 0 10-1.06-1.06l-.707.707a.75.75 0 101.06 1.06zM2.625 7.25a.75.75 0 00-.75-.75h-1a.75.75 0 000 1.5h1a.75.75 0 00.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});