define("ember-svg-jar/inlined/road-sign-66-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-66-1-alternate</title><path d=\"M23 3.539a2 2 0 00-2.942-1.764l-1.947 1.039a.249.249 0 01-.234 0L12.927.228a2 2 0 00-1.854 0L6.122 2.815a.247.247 0 01-.233 0l-1.947-1.04A2 2 0 001 3.54v7.371a13.365 13.365 0 008.466 12.4l1.069.414a4.056 4.056 0 002.931 0l1.067-.414A13.364 13.364 0 0023 10.911zM5.063 4.641a2 2 0 001.869.008l4.952-2.588a.25.25 0 01.232 0l4.951 2.588a2 2 0 001.869-.008l1.7-.906a.251.251 0 01.364.221v1.416a.25.25 0 01-.25.25H3.25a.25.25 0 01-.25-.25V3.956a.25.25 0 01.368-.22zm8.748 16.806l-1.067.413a2.051 2.051 0 01-1.487 0l-1.069-.413A11.353 11.353 0 013 10.911v-3.04a.25.25 0 01.25-.25h17.5a.25.25 0 01.25.25v3.04a11.353 11.353 0 01-7.189 10.536z\"/><path d=\"M8.5 11.246a3.218 3.218 0 00-1.039.175.25.25 0 01-.3-.362A2.121 2.121 0 019 10h.5a.875.875 0 000-1.75H9a3.88 3.88 0 00-3.875 3.875v2.5A3.375 3.375 0 108.5 11.246zm0 5a1.625 1.625 0 111.625-1.625A1.627 1.627 0 018.5 16.246z\"/><path d=\"M15.5 11.246a3.218 3.218 0 00-1.039.175.25.25 0 01-.3-.362A2.121 2.121 0 0116 10h.5a.875.875 0 000-1.75H16a3.88 3.88 0 00-3.875 3.875v2.5a3.375 3.375 0 103.375-3.379zm0 5a1.625 1.625 0 111.625-1.625 1.627 1.627 0 01-1.625 1.625z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});