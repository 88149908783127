define("ember-svg-jar/inlined/real-estate-dimensions-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-dimensions-block</title><path d=\"M4.25 8h.5a.75.75 0 00.531-1.28l-2-2a.75.75 0 00-1.061 0l-2 2A.75.75 0 00.75 8h.5a.5.5 0 01.5.5V20a.5.5 0 01-.5.5h-.5a.75.75 0 00-.53 1.28l2 2a.75.75 0 001.061 0l2-2a.75.75 0 00-.531-1.28h-.5a.5.5 0 01-.5-.5V8.5a.5.5 0 01.5-.5zM8 4.75v-.5a.5.5 0 01.5-.5H20a.5.5 0 01.5.5v.5a.75.75 0 001.28.53l2-2a.75.75 0 000-1.061l-2-2A.75.75 0 0020.5.75v.5a.5.5 0 01-.5.5H8.5a.5.5 0 01-.5-.5v-.5A.75.75 0 006.72.22l-2 2a.75.75 0 000 1.061l2 2A.75.75 0 008 4.75zM22.5 7h-14A1.5 1.5 0 007 8.5v6a.5.5 0 00.5.5h1.75A1.752 1.752 0 0111 16.75v2.5a.75.75 0 01-1.5 0v-2.5a.25.25 0 00-.25-.25H7.5a.5.5 0 00-.5.5v5.5A1.5 1.5 0 008.5 24H13a.5.5 0 00.5-.5v-5.75a.75.75 0 011.5 0v5.75a.5.5 0 00.5.5H17a.5.5 0 00.5-.5v-3.25a1.752 1.752 0 011.75-1.75h1.5a.75.75 0 010 1.5h-1.5a.25.25 0 00-.25.25v3.25a.5.5 0 00.5.5h3a1.5 1.5 0 001.5-1.5v-6a.5.5 0 00-.5-.5h-3.75a.75.75 0 010-1.5h3.75a.5.5 0 00.5-.5v-1.5a.5.5 0 00-.5-.5h-3.75a.75.75 0 010-1.5h3.75a.5.5 0 00.5-.5V8.5A1.5 1.5 0 0022.5 7zm-6 5.5a1 1 0 01-1 1h-3a1 1 0 01-1-1v-3a1 1 0 011-1h3a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});