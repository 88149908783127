define("ember-svg-jar/inlined/pin-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin-1</title><path d=\"M22.283 5.314l-3.637-3.637a1.5 1.5 0 00-2.326.256L13.226 7.4a7.631 7.631 0 00-1.766-.2 6.32 6.32 0 00-3.513.755 1.5 1.5 0 00-.3 2.355l6.008 6.009a1.576 1.576 0 001.252.427 1.5 1.5 0 001.1-.729 6.783 6.783 0 00.548-5.278l5.472-3.099a1.5 1.5 0 00.256-2.326zM17.875 4.3l-1.414 2.124a.5.5 0 01-.417.223.507.507 0 01-.277-.084.5.5 0 01-.138-.694l1.414-2.121a.5.5 0 01.832.555zM8.071 14.559l-6.5 6.5a1 1 0 000 1.414 1.015 1.015 0 001.414 0l6.5-6.5a1 1 0 00-1.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});