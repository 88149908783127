define("ember-svg-jar/inlined/settings-slider-desktop-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>settings-slider-desktop-horizontal</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v13.75a.25.25 0 00.25.25h23.5a.25.25 0 00.25-.25V1.5A1.5 1.5 0 0022.5 0zM4.766 4.5h1.718a.75.75 0 010 1.5H4.766a.75.75 0 010-1.5zm5.25 7h-5.25a.75.75 0 010-1.5h5.25a.75.75 0 010 1.5zm.3-4.5a1.75 1.75 0 111.661-1.835A1.75 1.75 0 0110.321 7zm3.532 5.5a1.75 1.75 0 111.66-1.835 1.75 1.75 0 01-1.655 1.835zm5.381-1h-1.713a.75.75 0 010-1.5h1.718a.75.75 0 010 1.5zm0-5.5h-5.25a.75.75 0 010-1.5h5.25a.75.75 0 010 1.5zM23.75 17H.25a.25.25 0 00-.25.25v1.25A1.5 1.5 0 001.5 20h8.3l-2.249 3.214A.5.5 0 007.96 24h8.08a.5.5 0 00.409-.787L14.2 20h8.3a1.5 1.5 0 001.5-1.5v-1.25a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});