define("ember-svg-jar/inlined/accounting-bill-stack-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accounting-bill-stack-1</title><path d=\"M22.858 7.593l-5.441 3.627a1 1 0 01-.555.168H11.02a.735.735 0 000 1.469h6.368a.735.735 0 00.407-.123l5.878-3.919a.734.734 0 10-.815-1.222z\"/><path d=\"M22.858 10.776L17.417 14.4a1 1 0 01-.555.168H11.02a.735.735 0 000 1.47h6.368a.737.737 0 00.407-.123L23.673 12a.735.735 0 10-.815-1.223z\"/><path d=\"M22.858 13.96l-5.441 3.627a1 1 0 01-.555.168H11.02a.735.735 0 000 1.469h6.368a.727.727 0 00.412-.124l5.878-3.918a.735.735 0 10-.815-1.223zM5.633 11.388H.735a.735.735 0 100 1.469h4.9a.735.735 0 000-1.469zM5.633 14.571H.735a.735.735 0 000 1.47h4.9a.735.735 0 000-1.47zM5.633 17.755H.735a.735.735 0 100 1.469h4.9a.735.735 0 000-1.469zM11.516 9.184a.489.489 0 00.49.489h5.382a.485.485 0 00.271-.082l5.878-3.918a.49.49 0 00-.272-.9h-5.387a.486.486 0 00-.272.082l-5.872 3.917a.5.5 0 00-.218.412zM12.469 5.124A.489.489 0 0012 4.776H6.653a.485.485 0 00-.271.082L.509 8.772a.492.492 0 00.273.9h5.34a.486.486 0 00.272-.082l5.878-3.918a.49.49 0 00.197-.548z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});