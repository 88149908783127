define("ember-svg-jar/inlined/certified-ribbon-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>certified-ribbon-2</title><path d=\"M19.5 0h-15a2 2 0 00-2 2v20.4a1.55 1.55 0 00.769 1.425 1.58 1.58 0 001.614-.125l6.845-4.429a.5.5 0 01.544 0l6.846 4.43A1.483 1.483 0 0021.5 22.4V2a2 2 0 00-2-2zM9.586 13.719L6.78 10.657a1 1 0 111.474-1.351l2.662 2.9 5.342-7.258a1 1 0 011.611 1.186L12.4 13.569a1.792 1.792 0 01-2.814.15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});