define("ember-svg-jar/inlined/smiley-sad-crying-alternate-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-sad-crying-alternate_1</title><path d=\"M21.323 8.929a1.039 1.039 0 00-1.646 0c-.628.909-2.677 4-2.677 5.568a3.5 3.5 0 007 0c0-1.57-2.049-4.659-2.677-5.568zM20.5 16a1.5 1.5 0 01-1.5-1.5 9.066 9.066 0 011.285-2.809.25.25 0 01.43 0A9.066 9.066 0 0122 14.5a1.5 1.5 0 01-1.5 1.5zM17 20.66A10.006 10.006 0 012.27 9.68a1 1 0 00-1.946-.462A12 12 0 0018 22.391a1 1 0 00-1-1.731z\"/><circle cx=\"8.5\" cy=\"9.997\" r=\"1.5\"/><circle cx=\"15.5\" cy=\"9.997\" r=\"1.5\"/><path d=\"M7.9 2.875a10.069 10.069 0 018.576.176 1.011 1.011 0 00.447.106 1 1 0 00.449-1.894 12.076 12.076 0 00-10.299-.211A1 1 0 107.9 2.875zM5 8c1.754 0 4.064-.9 4.9-2.555a1 1 0 00-1.79-.9C7.679 5.4 6.126 6 5 6a1 1 0 000 2zM15.9 4.55a1 1 0 00-1.79.9C14.936 7.1 17.246 8 19 8a1 1 0 000-2c-1.126 0-2.679-.6-3.1-1.45zM7.9 17.832a18.979 18.979 0 018.21 0 .395.395 0 00.342-.592 5.135 5.135 0 00-8.894 0 .395.395 0 00.342.592z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});