define("ember-svg-jar/inlined/dentistry-tooth-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dentistry-tooth-search</title><path d=\"M13.437 12.177a.5.5 0 00-.259-.479A7.04 7.04 0 019.663 7a.5.5 0 00-.552-.391A3.846 3.846 0 006.75 7.833a3.859 3.859 0 00-2.865-1.264C1.707 6.569 0 8.63 0 11.26a7.659 7.659 0 001.769 5.3 11.437 11.437 0 00-.728 4.074 11.973 11.973 0 00.245 2.422A1.187 1.187 0 002.444 24a1.211 1.211 0 001.206-1.081c.257-2.353 1.589-4.127 3.1-4.127s2.843 1.774 3.1 4.128A1.211 1.211 0 0011.056 24a1.187 1.187 0 001.158-.943 11.973 11.973 0 00.245-2.422 11.437 11.437 0 00-.728-4.074 7.246 7.246 0 001.706-4.384zM23.707 12.707a1 1 0 000-1.414l-2.342-2.342a.5.5 0 01-.077-.609 5.539 5.539 0 10-1.946 1.946.5.5 0 01.609.077l2.342 2.342a1 1 0 001.414 0zM13 5.53a3.53 3.53 0 113.53 3.53A3.529 3.529 0 0113 5.53z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});