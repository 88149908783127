define("ember-svg-jar/inlined/rating-star-winner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rating-star-winner</title><path d=\"M16.023 12.8L14.9 10.2a.5.5 0 01.1-.545l2.044-2.136a.643.643 0 00-.459-1.119h-2.308a.5.5 0 01-.449-.282l-1.21-2.492a.7.7 0 00-1.235 0l-1.212 2.493a.5.5 0 01-.45.281H7.414a.643.643 0 00-.451 1.123l2.045 2.136a.5.5 0 01.1.545L7.978 12.8a.686.686 0 00.977.859l2.8-1.577a.5.5 0 01.491 0l2.8 1.577a.686.686 0 00.977-.859zM23.838 16.005a.49.49 0 00-.345-.855H21.81a.5.5 0 01-.45-.282l-.889-1.831a.529.529 0 00-.941 0l-.891 1.832a.5.5 0 01-.45.281h-1.683a.489.489 0 00-.343.855l1.5 1.571a.5.5 0 01.1.545l-.828 1.9a.523.523 0 00.745.654l2.075-1.169a.5.5 0 01.491 0l2.075 1.169a.523.523 0 00.744-.654l-.827-1.9a.5.5 0 01.1-.545zM7.493 15.15H5.81a.5.5 0 01-.45-.282l-.889-1.831a.529.529 0 00-.941 0l-.891 1.832a.5.5 0 01-.45.281H.506a.489.489 0 00-.343.855l1.5 1.571a.5.5 0 01.1.545l-.828 1.9a.523.523 0 00.745.654l2.075-1.165a.5.5 0 01.491 0l2.075 1.169a.523.523 0 00.744-.654l-.827-1.9a.5.5 0 01.1-.545l1.5-1.571a.49.49 0 00-.345-.859z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});