define("ember-svg-jar/inlined/safety-fire-exit-stairs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-fire-exit-stairs</title><path d=\"M20.5 7.972a3 3 0 003-3A5.523 5.523 0 0020.338.051a.25.25 0 00-.315.362 3.17 3.17 0 01.259 2.756.247.247 0 01-.179.15.251.251 0 01-.225-.063 1.64 1.64 0 01-.442-.64.252.252 0 00-.142-.149.247.247 0 00-.206.007 2.694 2.694 0 00-1.588 2.5 3 3 0 003 2.998zM15.5 16.972v2.75a.25.25 0 01-.25.25H11.5a1 1 0 00-1 1v2.5a.5.5 0 00.5.5h12a.5.5 0 00.5-.5v-11a.5.5 0 00-.5-.5h-1.5a1 1 0 00-1 1v2.75a.25.25 0 01-.25.25H16.5a1 1 0 00-1 1z\"/><circle cx=\"5\" cy=\"3.472\" r=\"2.5\"/><path d=\"M2 12.972h1.16a5.5 5.5 0 004.177-1.921l.179-.21a.25.25 0 01.4.03l.236.377a2.486 2.486 0 01-.239 2.971l-.555.634A5.5 5.5 0 006 18.475v1.5a1.5 1.5 0 003 0v-1.5a2.483 2.483 0 01.438-1.4.251.251 0 01.3-.091l2.206.882a1.479 1.479 0 00.557.108 1.5 1.5 0 00.557-2.893l-1.612-.645a.251.251 0 01-.147-.3 5.4 5.4 0 00-.385-4.087.25.25 0 01.321-.344 2.442 2.442 0 011.145.988l.335.557a1.5 1.5 0 102.571-1.55l-.335-.556a5.524 5.524 0 00-4.716-2.671H9.84a5.492 5.492 0 00-4.177 1.92l-.6.707a2.5 2.5 0 01-1.9.872H2a1.5 1.5 0 000 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});