define("ember-svg-jar/inlined/folder-heart-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-heart-alternate</title><path d=\"M23.628 14.449a3.508 3.508 0 00-3.047-1.949 3.667 3.667 0 00-2.694 1.127l-.211.211a.249.249 0 01-.353 0l-.211-.211a3.665 3.665 0 00-2.693-1.127 3.528 3.528 0 00-2.394 6.012l5.113 5.334a.5.5 0 00.723 0l5.106-5.327a3.524 3.524 0 00.661-4.07z\"/><path d=\"M2.5 2h4.573a.5.5 0 01.447.276L8.33 3.9A2 2 0 0010.118 5H19a.5.5 0 01.5.5v5.332a.246.246 0 00.266.248 11.128 11.128 0 011.473.013.245.245 0 00.261-.249V5a2 2 0 00-2-2h-9.073a.5.5 0 01-.447-.276l-.81-1.619A2 2 0 007.382 0H2a2 2 0 00-2 2v14.5a2 2 0 002 2h7.755a.248.248 0 00.219-.36 4.966 4.966 0 01-.416-1.425.236.236 0 00-.233-.215H2.5A.5.5 0 012 16V2.5a.5.5 0 01.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});