define("ember-svg-jar/inlined/navigation-menu-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-menu-1</title><rect x=\"3\" y=\"3\" width=\"18\" height=\"4\" rx=\"1.5\" ry=\"1.5\"/><rect x=\"3\" y=\"10\" width=\"18\" height=\"4\" rx=\"1.5\" ry=\"1.5\"/><rect x=\"3\" y=\"17\" width=\"18\" height=\"4\" rx=\"1.5\" ry=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});