define("ember-svg-jar/inlined/plane-trip-return-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-trip-return-1</title><path d=\"M8.46 11.5h1.481a.521.521 0 00.419-.2.389.389 0 00.038-.416L9.023 8.615a.391.391 0 01.038-.415.521.521 0 01.419-.2h1.92a.513.513 0 01.447.242l.691 1.209a.526.526 0 00.545.234L14.6 9.42a.5.5 0 00.346-.228.388.388 0 00.013-.379L14 6.5l.957-2.313a.388.388 0 00-.013-.379.5.5 0 00-.344-.228l-1.513-.265a.526.526 0 00-.545.234l-.691 1.209A.513.513 0 0111.4 5H9.48a.521.521 0 01-.419-.2.391.391 0 01-.038-.416L10.4 2.115a.389.389 0 00-.04-.415.521.521 0 00-.419-.2H8.46a.525.525 0 00-.4.175L5.384 4.8a.658.658 0 01-.457.2H2a1.5 1.5 0 000 3h2.927a.658.658 0 01.457.2l2.677 3.123a.525.525 0 00.399.177zM23.5 17.5A1.5 1.5 0 0022 16h-2.927a.658.658 0 01-.457-.2l-2.677-3.123a.526.526 0 00-.4-.175h-1.48a.521.521 0 00-.419.2.389.389 0 00-.038.416l1.375 2.27a.391.391 0 01-.038.416.521.521 0 01-.419.2H12.6a.513.513 0 01-.447-.242l-.691-1.209a.526.526 0 00-.545-.234L9.4 14.58a.5.5 0 00-.346.228.388.388 0 00-.013.379L10 17.5l-.957 2.313a.388.388 0 00.013.379.5.5 0 00.346.228l1.513.265a.526.526 0 00.545-.234l.691-1.209A.513.513 0 0112.6 19h1.92a.521.521 0 01.419.2.391.391 0 01.038.416L13.6 21.885a.389.389 0 00.038.416.521.521 0 00.419.2h1.48a.526.526 0 00.4-.175l2.679-3.126a.658.658 0 01.457-.2H22a1.5 1.5 0 001.5-1.5z\"/><circle cx=\"17\" cy=\"6.5\" r=\"1\"/><circle cx=\"21\" cy=\"6.5\" r=\"1\"/><circle cx=\"3\" cy=\"17.5\" r=\"1\"/><circle cx=\"7\" cy=\"17.5\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});