define("ember-svg-jar/inlined/pelican", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pelican</title><path d=\"M7.076 18.34a2.118 2.118 0 001.488.874 2.793 2.793 0 001.911-1.046 2.582 2.582 0 012-1.006 2.506 2.506 0 012.26 1.425 1.279 1.279 0 001.051.627 1.863 1.863 0 001.475-.846 2.415 2.415 0 012.139-1.206 2.505 2.505 0 011.932.91 15.444 15.444 0 002.51-4.673.5.5 0 00-.477-.648h-1.139a.5.5 0 01-.472-.666 14.457 14.457 0 00.607-2.253.5.5 0 00-.14-.436.493.493 0 00-.437-.138l-6.517 1.109a.5.5 0 01-.532-.716l2.581-5.177a.5.5 0 00-.057-.536c-.088-.109-2.181-2.687-4.889-2.687a4.43 4.43 0 00-3.4 1.505h-.008C2.316 2.156.3 6.13.1 6.575a.989.989 0 00-.076.62c.36 2.114 2.14 6.056 6.349 6.056.169 0 .334 0 .5-.015a9.131 9.131 0 00-.5 3.015 5.678 5.678 0 00.189 1.494 2.484 2.484 0 01.514.595zM11.865 5a1 1 0 111-1 1 1 0 01-1 1zm-3.087-.253a1.03 1.03 0 00.2 0L9.89 6H3.12a7.761 7.761 0 015.658-1.253zM2.318 8h7.006l-.365 1.15a3.686 3.686 0 01-1.343 1.958 5.21 5.21 0 01-1.246.142A4.415 4.415 0 012.318 8z\"/><path d=\"M22.931 20.7c-1.567.146-2.667-1.556-2.675-1.569a1.048 1.048 0 00-1.716.022 3.371 3.371 0 01-2.751 1.558 2.8 2.8 0 01-2.4-1.482 1.045 1.045 0 00-1.713-.161 4.3 4.3 0 01-3.108 1.643 3.56 3.56 0 01-2.76-1.58 1.039 1.039 0 00-1.637-.091c-.014.018-1.476 1.825-3.109 1.7a1 1 0 10-.106 2c.076 0 .149.006.222.006A5.838 5.838 0 004.9 21.142a5.24 5.24 0 003.663 1.572 5.86 5.86 0 003.81-1.5 4.578 4.578 0 003.416 1.5 5.145 5.145 0 003.62-1.5A4.78 4.78 0 0023.07 22.7a1 1 0 00-.139-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});