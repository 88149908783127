define("ember-svg-jar/inlined/equalizer-stereo-play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>equalizer-stereo-play</title><path d=\"M19.465 5.706a1.023 1.023 0 011.414 0 1 1 0 001.414-1.414 3.071 3.071 0 00-4.242 0 1 1 0 101.414 1.414z\"/><path d=\"M22.293 2.878a1 1 0 101.414-1.414 5 5 0 00-7.071 0 1 1 0 001.414 1.414 3 3 0 014.243 0zM1.707 4.292a1 1 0 101.414 1.414 1.024 1.024 0 011.414 0 1 1 0 101.414-1.414 3.071 3.071 0 00-4.242 0z\"/><path d=\"M7.364 1.463a5 5 0 00-7.071 0 1 1 0 001.414 1.415 3 3 0 014.243 0 1 1 0 001.414-1.415zM4.5 9h-3A1.5 1.5 0 000 10.5v12A1.5 1.5 0 001.5 24h4a.5.5 0 00.5-.5v-13A1.5 1.5 0 004.5 9zM3 18a1.5 1.5 0 11-1.5 1.5A1.5 1.5 0 013 18zm-1.5-4A1.5 1.5 0 113 15.5 1.5 1.5 0 011.5 14zM16.5 13h-9a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-10a.5.5 0 00-.5-.5zM9 16.5a1 1 0 011-1h4a1 1 0 110 2h-4a1 1 0 01-1-1zM22.5 9h-3a1.5 1.5 0 00-1.5 1.5v13a.5.5 0 00.5.5h4a1.5 1.5 0 001.5-1.5v-12A1.5 1.5 0 0022.5 9zM21 18a1.5 1.5 0 11-1.5 1.5A1.5 1.5 0 0121 18zm-1.5-4a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});