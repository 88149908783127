define("ember-svg-jar/inlined/smiley-throw-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-throw-up</title><path d=\"M5.773 18.509a.249.249 0 00.233-.235 3.984 3.984 0 01.463-1.65.25.25 0 00-.036-.287.609.609 0 01-.064-.087.5.5 0 010-.5 6.5 6.5 0 0111.26 0 .5.5 0 010 .5.461.461 0 01-.063.087.25.25 0 00-.036.287 3.984 3.984 0 01.463 1.65.248.248 0 00.233.235 3.5 3.5 0 012.495 1.291.25.25 0 00.191.093.248.248 0 00.193-.088 12 12 0 10-18.21 0 .251.251 0 00.384 0 3.5 3.5 0 012.494-1.296zm4.679-8.7a1 1 0 01-.788.681l-3 .5A.927.927 0 016.5 11a1 1 0 01-.163-1.986l.6-.1a.249.249 0 00.135-.423L5.793 7.207a1 1 0 011.414-1.414l3 3a1 1 0 01.245 1.012zm8.538.337a1 1 0 01-1.132.848l-3.5-.5a1 1 0 01-.565-1.7l3-3a1 1 0 111.414 1.414l-1.232 1.231a.25.25 0 00.141.425l1.026.146a1 1 0 01.848 1.132z\"/><path d=\"M18 20a1.5 1.5 0 01-1.5-1.5A2.5 2.5 0 0014 16h-4a2.5 2.5 0 00-2.5 2.5A1.5 1.5 0 016 20a2 2 0 000 4 5.343 5.343 0 002.754-.7 1.141 1.141 0 011.256.063 3.513 3.513 0 003.98 0 1.141 1.141 0 011.256-.063A5.343 5.343 0 0018 24a2 2 0 000-4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});