define("ember-svg-jar/inlined/e-learning-laptop-math", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-learning-laptop-math</title><path d=\"M12.25 13.751A.75.75 0 0013 13v-.25a.5.5 0 01.5-.5h.25a.75.75 0 000-1.5h-.25a.5.5 0 01-.5-.5V10a.75.75 0 10-1.5 0v.25a.5.5 0 01-.5.5h-.25a.75.75 0 000 1.5H11a.5.5 0 01.5.5V13a.75.75 0 00.75.751z\"/><path d=\"M15.25 15.251h4a.75.75 0 000-1.5h-.75a.5.5 0 01-.5-.5v-4.5a1 1 0 00-1-1 1.345 1.345 0 00-.957.4l-1.323 1.32a.75.75 0 001.06 1.061l.293-.293a.25.25 0 01.427.177v2.835a.5.5 0 01-.5.5h-.75a.75.75 0 000 1.5zM8.4 12.7A2.75 2.75 0 104 10.5a.75.75 0 001.5 0 1.25 1.25 0 112 1l-3.2 2.4a.75.75 0 00.45 1.35h4a.75.75 0 000-1.5h-1a.249.249 0 01-.237-.17.251.251 0 01.087-.28z\"/><path d=\"M2 16.5a1 1 0 001-1V6a1 1 0 011-1h16a1 1 0 011 1v9.5a1 1 0 002 0V6a3 3 0 00-3-3H4a3 3 0 00-3 3v9.5a1 1 0 001 1zM23.5 18H.5a.5.5 0 00-.5.5A2.5 2.5 0 002.5 21h19a2.5 2.5 0 002.5-2.5.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});