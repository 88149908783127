define("ember-svg-jar/inlined/coffee-filter-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>coffee-filter-1</title><path d=\"M17.75 0a2.5 2.5 0 00-2.223 1.363.243.243 0 01-.216.137H12.25a2 2 0 00-2 2v2.75a.25.25 0 01-.25.25H4.666a.5.5 0 00-.421.771l2.806 4.364a.249.249 0 00.21.115h7.978a.249.249 0 00.21-.115l2.806-4.364a.5.5 0 00-.421-.771H12.5a.25.25 0 01-.25-.25V3.5h3.062a.241.241 0 01.214.136 2.507 2.507 0 002.962 1.258 2.464 2.464 0 001.669-1.7A2.506 2.506 0 0017.75 0zM16.25 13h-10a2.5 2.5 0 00-2.5 2.5v8a.5.5 0 00.5.5h14a.5.5 0 00.5-.5v-8a2.5 2.5 0 00-2.5-2.5zm.5 8.551a.949.949 0 01-.949.949H6.7a.949.949 0 01-.949-.949v-2.6A.949.949 0 016.7 18h9.1a.949.949 0 01.949.949z\"/><path d=\"M12.25 19.75h-2a.75.75 0 000 1.5h2a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});