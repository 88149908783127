define("ember-svg-jar/inlined/lens-shade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lens-shade</title><path d=\"M18.5 12.5A1.5 1.5 0 0017 11H7a1.5 1.5 0 00-1.5 1.5v7A1.5 1.5 0 007 21h10a1.5 1.5 0 001.5-1.5zm-2.25 1.75v3.5a.75.75 0 11-1.5 0v-3.5a.75.75 0 011.5 0zm-3.5 0v3.5a.75.75 0 01-1.5 0v-3.5a.75.75 0 011.5 0zm-3.5 0v3.5a.75.75 0 11-1.5 0v-3.5a.75.75 0 011.5 0zM18.5 0h-13a1.5 1.5 0 00-1.4 2.009l1.684 6.567a1.5 1.5 0 001.385.924h9.666a1.513 1.513 0 001.409-.991l1.642-6.432A1.5 1.5 0 0018.5 0zM16 22.5a.5.5 0 00-.5-.5h-7a.5.5 0 00-.5.5A1.5 1.5 0 009.5 24h5a1.5 1.5 0 001.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});