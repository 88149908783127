define("ember-svg-jar/inlined/gas-f", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gas-f</title><path d=\"M23.682 8.644a1 1 0 00-1.414.048c-1.838 1.964-2.768 3.372-2.768 4.183v7.5a1 1 0 01-2 0v-13.5a2.5 2.5 0 00-2.5-2.5h-.248a.25.25 0 01-.25-.25v-1.75a1.752 1.752 0 00-1.75-1.75h-11A1.753 1.753 0 000 2.375v19a1.753 1.753 0 001.75 1.75h11a1.752 1.752 0 001.75-1.75V6.625a.25.25 0 01.25-.25H15a.5.5 0 01.5.5v13.5a3 3 0 006 0v-2.228a.249.249 0 01.138-.223l1.033-.517a1.493 1.493 0 00.829-1.341v-1.191a1.5 1.5 0 00-1.5-1.5h-.26a.251.251 0 01-.25-.249v-.156a13.874 13.874 0 012.24-2.912 1 1 0 00-.048-1.414zM9.75 12.625h-2.5a.751.751 0 00-.75.75v1.75h1.75a.75.75 0 010 1.5H6.5v3.25a.75.75 0 01-1.5 0v-6.5a2.253 2.253 0 012.25-2.25h2.5a.75.75 0 010 1.5zm2-5.75a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h8a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});