define("ember-svg-jar/inlined/lighthouse-bird", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lighthouse-bird</title><path d=\"M7.47 2.78a.748.748 0 001.06 0l1.5-1.5A.75.75 0 008.97.22l-.617.616a.5.5 0 01-.707 0L7.03.22a.75.75 0 00-1.06 1.06zM2.72 6.28a.748.748 0 001.06 0l1.5-1.5a.75.75 0 10-1.06-1.06l-.62.616a.5.5 0 01-.707 0L2.28 3.72a.75.75 0 00-1.06 1.06zM9.5 6.75a.75.75 0 00.75.75h9.5a.75.75 0 000-1.5H19a.5.5 0 01-.5-.5 3.487 3.487 0 00-2.188-3.227A.5.5 0 0116 1.81V1a1 1 0 00-2 0v.81a.5.5 0 01-.312.463A3.487 3.487 0 0011.5 5.5a.5.5 0 01-.5.5h-.75a.75.75 0 00-.75.75zM9.842 15.705L9.1 19.733a.5.5 0 00.784.5 3.341 3.341 0 00.595-.545 2.5 2.5 0 014.287.39 1.288 1.288 0 001.057.631 1.872 1.872 0 001.482-.85 2.415 2.415 0 012.135-1.209 2.349 2.349 0 01.558.068.5.5 0 00.611-.577l-.452-2.436a.25.25 0 00-.246-.205h-9.823a.251.251 0 00-.246.205zM19.541 14a.25.25 0 00.246-.295l-.795-4.3A.5.5 0 0018.5 9h-7a.5.5 0 00-.492.409l-.795 4.3a.249.249 0 00.246.295z\"/><path d=\"M22.867 21.941c-1.63.216-2.736-1.5-2.746-1.513a1.043 1.043 0 00-1.719.029 3.263 3.263 0 01-2.673 1.509 2.723 2.723 0 01-2.336-1.44 1.046 1.046 0 00-1.714-.16 4.191 4.191 0 01-3.026 1.6 3.462 3.462 0 01-2.685-1.538 1.043 1.043 0 00-1.636-.094c-.015.02-1.527 1.872-3.2 1.647a1 1 0 00-.264 1.983 4.1 4.1 0 00.548.036A5.6 5.6 0 004.9 22.577a.249.249 0 01.327.006 5.086 5.086 0 003.429 1.383 5.684 5.684 0 003.556-1.321.25.25 0 01.331.015 4.468 4.468 0 003.189 1.306 5.029 5.029 0 003.38-1.3.249.249 0 01.332 0 4.561 4.561 0 003.692 1.26 1 1 0 00-.266-1.982z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});