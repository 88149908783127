define("ember-svg-jar/inlined/rabbit-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rabbit-body</title><path d=\"M8.25 6.6A3.974 3.974 0 008 8v1.01a2.486 2.486 0 00.379 4.219 6.992 6.992 0 00-1 3.166A4.141 4.141 0 005.5 20c0 2.206 1.467 4 3.27 4h6.46c1.8 0 3.27-1.794 3.27-4a4.141 4.141 0 00-1.875-3.605 6.985 6.985 0 00-1.009-3.163A2.487 2.487 0 0016 9.007V8a3.974 3.974 0 00-.25-1.4V1.5a1.5 1.5 0 00-3 0v2.567a4.053 4.053 0 00-1.5 0V1.5a1.5 1.5 0 00-3 0zm5.25.65a1 1 0 11-1 1 1 1 0 011-.996zm-.989 3.7l.038.038a.9.9 0 00.451.26.75.75 0 010 1.5 1.948 1.948 0 01-1-.3 1.948 1.948 0 01-1 .3.75.75 0 010-1.5.919.919 0 00.451-.26.758.758 0 011.06-.033zM10.5 9.254a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});