define("ember-svg-jar/inlined/drone-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drone-video</title><path d=\"M23.625 1.625a.5.5 0 00-.657-.475l-1.049.35a.251.251 0 01-.2-.021.248.248 0 01-.12-.165A1.5 1.5 0 0020.125.125h-4a1.5 1.5 0 00-1.5 1.5v4a1.5 1.5 0 001.5 1.5h4a1.5 1.5 0 001.468-1.19.248.248 0 01.12-.165.251.251 0 01.2-.021l1.05.35a.5.5 0 00.658-.474zM19.875 11.125a.75.75 0 00-.75-.75h-5a.75.75 0 000 1.5h5a.75.75 0 00.75-.75zM6.125 11.875a.75.75 0 000-1.5h-5a.75.75 0 000 1.5zM18.125 14.625h-.5a.25.25 0 01-.25-.25v-.75a.75.75 0 00-1.5 0v.75a.25.25 0 01-.25.25h-1a2.561 2.561 0 01-1.9-.845 3.5 3.5 0 00-5.2 0 2.565 2.565 0 01-1.9.845h-1a.25.25 0 01-.25-.25v-.75a.75.75 0 00-1.5 0v.75a.25.25 0 01-.25.25h-.5a1.5 1.5 0 000 3h3.5a2.557 2.557 0 01.637.081.251.251 0 01.182.193.248.248 0 01-.094.248 6.243 6.243 0 00-2.475 4.978.75.75 0 001.5 0 4.753 4.753 0 012.459-4.16.249.249 0 01.266.015l.082.056a.251.251 0 01.069.346 2.25 2.25 0 103.748 0 .251.251 0 01.069-.346l.082-.056a.249.249 0 01.266-.015 4.753 4.753 0 012.459 4.16.75.75 0 001.5 0 6.243 6.243 0 00-2.475-4.978.25.25 0 01.089-.441 2.549 2.549 0 01.636-.081h3.5a1.5 1.5 0 000-3zm-8 6.75a.75.75 0 11.75-.75.751.751 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});