define("ember-svg-jar/inlined/sports-car-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sports-car-2</title><path d=\"M23.5 9.254a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-2.727a.25.25 0 01-.224-.139l-2.016-4.032a1.5 1.5 0 00-1.342-.829H6.809a1.5 1.5 0 00-1.342.829L3.451 7.115a.25.25 0 01-.224.139H.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1.116a.25.25 0 01.152.448 4.448 4.448 0 00-.112 7.013.251.251 0 01.094.2v3.836a1 1 0 001 1h2a1 1 0 001-1v-2.497a.5.5 0 01.5-.5h11.5a.5.5 0 01.5.5v2.492a1 1 0 001 1h2a1 1 0 001-1V16.91a.251.251 0 01.094-.2 4.448 4.448 0 00-.112-7.01.25.25 0 01.152-.448zm-7.8.86a.25.25 0 01.22-.368h1.45a.248.248 0 01.208.112l1 1.5a.249.249 0 01-.208.388h-1.655a.251.251 0 01-.221-.132zM3.243 13.5A.759.759 0 014 12.746h16a.758.758 0 010 1.515H4a.758.758 0 01-.757-.761zm10.257-2a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25V10a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25zm-5.994.118a.251.251 0 01-.221.132H5.636a.249.249 0 01-.208-.388l1-1.5a.248.248 0 01.208-.112h1.45a.25.25 0 01.22.368zm6.088 3.647a.251.251 0 01.2.1.248.248 0 01.041.22l-.282.985a.251.251 0 01-.24.181h-2.625a.251.251 0 01-.24-.181l-.282-.985a.248.248 0 01.041-.22.251.251 0 01.2-.1zM7.049 4.392a.249.249 0 01.224-.138h9.454a.249.249 0 01.224.138l1.75 3.5a.248.248 0 01-.011.243.25.25 0 01-.213.119H5.523a.25.25 0 01-.213-.119.248.248 0 01-.01-.243z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});