define("ember-svg-jar/inlined/e-commerce-cart-browser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-cart-browser</title><path d=\"M8.922 14.74a1.245 1.245 0 001.149.76h3.578a1.255 1.255 0 001.176-.823L16.7 9.506a.75.75 0 10-1.41-.512l-.306.841a.248.248 0 01-.235.165H9a1.243 1.243 0 00-1.043.562 1.228 1.228 0 00-.123 1.138z\"/><circle cx=\"10.25\" cy=\"17.25\" r=\"1\"/><circle cx=\"13.5\" cy=\"17.25\" r=\"1\"/><path d=\"M21 1.75H3a3 3 0 00-3 3v14.5a3 3 0 003 3h18a3 3 0 003-3V4.75a3 3 0 00-3-3zM8 4.25a1 1 0 11-1-1 1 1 0 011 1zm-3.5 0a1 1 0 11-1-1 1 1 0 011 1zm6 1a1 1 0 111-1 1 1 0 01-1 1zm11.5 14a1 1 0 01-1 1H3a1 1 0 01-1-1V7.75a1 1 0 011-1h18a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});