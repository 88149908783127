define("ember-svg-jar/inlined/conversation-spy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>conversation-spy</title><path d=\"M1.479 6.066h2.869a1 1 0 000-2H1.479a1 1 0 000 2zM1.162 2.188l2.869.956a1 1 0 10.633-1.9L1.8.291a1 1 0 00-.633 1.9zM4.031 6.986l-2.869.958A1 1 0 101.8 9.84l2.869-.957a1 1 0 10-.633-1.9z\"/><path d=\"M16.2 15.15l-6.375.708a2.4 2.4 0 00-2.14 2.321.249.249 0 01-.249.234H4.348a2.348 2.348 0 110-4.695h14.826a4.826 4.826 0 000-9.652h-2.606a.25.25 0 01-.249-.234A2.4 2.4 0 0014.18 1.51L7.8.8a.978.978 0 00-1.084.973v6.585a.978.978 0 00.975.977 1.032 1.032 0 00.109-.006l6.375-.708A2.4 2.4 0 0016.319 6.3a.249.249 0 01.249-.234h2.606a2.826 2.826 0 010 5.652H4.348a4.348 4.348 0 100 8.7h3.084a.25.25 0 01.249.234 2.4 2.4 0 002.139 2.317l6.378.709a.942.942 0 00.106.006.978.978 0 00.978-.979v-6.584a.978.978 0 00-1.082-.971z\"/><path d=\"M22.521 18.174h-2.869a1 1 0 000 2h2.869a1 1 0 000-2zM22.838 21.813l-2.869-.956a1 1 0 10-.633 1.9l2.869.956a1 1 0 10.633-1.9zM22.205 14.639l-2.869.957a1 1 0 10.633 1.9l2.869-.957a1 1 0 10-.633-1.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});