define("ember-svg-jar/inlined/slide-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>slide-right</title><path d=\"M18.58 11.868a.5.5 0 000-.707l-3-3a.5.5 0 00-.853.354V10.5H13.25a.25.25 0 01-.25-.25V4.5A2.5 2.5 0 0010.5 2h-8A2.5 2.5 0 000 4.5v15A2.5 2.5 0 002.5 22h8a2.5 2.5 0 002.5-2.5v-5.25a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25v2.25a1 1 0 01-1 1H3a1 1 0 01-1-1v-11a1 1 0 011-1h7a1 1 0 011 1v4.75a.25.25 0 01-.25.25H7.727a1 1 0 000 2h7v2.015a.5.5 0 00.853.353z\"/><path d=\"M20 7.445a3.974 3.974 0 00-1.532.3 1 1 0 00.767 1.855A1.974 1.974 0 0120 9.445a2 2 0 010 4 1.973 1.973 0 01-.765-.151 1 1 0 00-.767 1.848A4 4 0 1020 7.445z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});