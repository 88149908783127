define("ember-svg-jar/inlined/navigation-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-left</title><path d=\"M19.035 7.569c-3.466-2.039-8.23-1.757-13.182.754a.251.251 0 01-.29-.046L2.557 5.283A1.5 1.5 0 000 6.348v11.8a1 1 0 001 1h11.792a1.5 1.5 0 001.061-2.561l-2.5-2.5a.249.249 0 01-.071-.215.252.252 0 01.133-.184c2.847-1.457 5.857-1.7 7.972-.565C21.1 14.034 22 15.773 22 18.146a1 1 0 002 0c0-4.935-1.763-8.692-4.965-10.577z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});