define("ember-svg-jar/inlined/robot-head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>robot-head</title><path d=\"M23 13h-.339a.5.5 0 01-.451-.285 3.012 3.012 0 00-1.819-1.579.5.5 0 01-.326-.319 8.528 8.528 0 00-6.648-5.7.5.5 0 01-.417-.493.626.626 0 01.626-.624A2.374 2.374 0 0016 1.624V1a1 1 0 10-2 0v.626a.373.373 0 01-.374.374A2.626 2.626 0 0011 4.624a.5.5 0 01-.417.493 8.528 8.528 0 00-6.648 5.7.5.5 0 01-.326.319 3.012 3.012 0 00-1.819 1.577.5.5 0 01-.451.287H1a1 1 0 000 2h.338a.5.5 0 01.452.285 3.007 3.007 0 002.3 1.686.5.5 0 01.286.143 2.982 2.982 0 00.867.608.5.5 0 01.287.406 6.5 6.5 0 0012.94 0 .5.5 0 01.288-.406 3.022 3.022 0 00.87-.61.5.5 0 01.286-.143 3.007 3.007 0 002.3-1.686.5.5 0 01.448-.283H23a1 1 0 000-2zm-15.5-.5A1.5 1.5 0 119 14a1.5 1.5 0 01-1.5-1.5zm8.856 6.128a4.5 4.5 0 01-1.229 2.106.5.5 0 01-.811-.172 2.5 2.5 0 00-4.635 0 .5.5 0 01-.811.172 4.505 4.505 0 01-1.23-2.107.5.5 0 01.485-.627h7.746a.5.5 0 01.485.625zM15 14a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0115 14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});