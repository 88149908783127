define("ember-svg-jar/inlined/pet-tracking-cat-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pet-tracking-cat-location</title><path d=\"M8.39 13.536a8.047 8.047 0 00-2.049.353.5.5 0 00-.295.681l1.047 2.3a1.052 1.052 0 00.965.631 1.145 1.145 0 00.288-.038 1.058 1.058 0 00.794-1.136l-.219-2.338a.5.5 0 00-.531-.453z\"/><path d=\"M18.035 9.574a.5.5 0 00.373-.271l.263-.527a.5.5 0 01.448-.276h1.07A1.814 1.814 0 0022 6.688V6.5a.5.5 0 00-.5-.5c-.336 0-.573-.459-.923-1.214a3.651 3.651 0 00-1.847-2.17.5.5 0 01-.333-.405 11.777 11.777 0 00-.233-1.59A1 1 0 0017.243 0a1.015 1.015 0 00-.943.677l-.987 3.669A3.493 3.493 0 0112.077 6.5H5.39a2.462 2.462 0 00-1.138.284.253.253 0 01-.212.009A3.314 3.314 0 012 3.75 1.562 1.562 0 013.5 2a.485.485 0 01.5.5c0 .352-.449.605-.448.605a1 1 0 00.9 1.79A2.808 2.808 0 006 2.5 2.476 2.476 0 003.5 0 3.551 3.551 0 000 3.75a5.326 5.326 0 002.728 4.626.25.25 0 01.112.306C2.119 10.614.55 14.808.091 15.941a1.089 1.089 0 00.536 1.429 1.3 1.3 0 00.572.13 1.424 1.424 0 001.231-.731c.145-.243.281-.479.412-.707C4.12 13.841 4.891 12.5 9.743 12.5h.015a13.836 13.836 0 013.18.3.5.5 0 00.54-.222 6.512 6.512 0 014.557-3.004zm.216-5.074a.75.75 0 11-.75.75.75.75 0 01.75-.75zM19 11a5 5 0 00-5 5c0 3.17 4.46 7.66 4.65 7.85A.487.487 0 0019 24a.469.469 0 00.35-.15C19.539 23.66 24 19.17 24 16a5 5 0 00-5-5zm0 6.75A1.75 1.75 0 1120.749 16 1.758 1.758 0 0119 17.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});