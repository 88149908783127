define("ember-svg-jar/inlined/skiing-snow-scooter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skiing-snow-scooter</title><path d=\"M22.373 14.908L19.057 13.7a.25.25 0 01-.057-.439l4.19-2.958A1.818 1.818 0 0022.141 7h-4.183a2.145 2.145 0 00-1.2.422L15.037 8.8a1.2 1.2 0 01-.579.2h-2.5a1.2 1.2 0 01-.578-.2l-1.34-1.075a.25.25 0 01-.02-.372l1.009-1.01a.277.277 0 01.062-.046l.063-.029a.259.259 0 01.1-.018h1.959a1 1 0 000-2h-2a2.381 2.381 0 00-1.56.647l-1.9 1.9a.538.538 0 00-.069.09A.25.25 0 017.47 7H5.958a1.667 1.667 0 00-1.2.578l-2.015 2.516a.25.25 0 00.2.406h2.52a1 1 0 010 2H1.271a.251.251 0 00-.244.2A2.706 2.706 0 003.749 16h.1a.25.25 0 01.223.362l-.6 1.207a.25.25 0 01-.272.131 1.355 1.355 0 01-.7-.366l-.792-.794a1 1 0 00-1.416 1.417l.794.794a3.39 3.39 0 002.414 1H8a1 1 0 000-2H6.023a.25.25 0 01-.224-.362l.626-1.25A.248.248 0 016.648 16H8.8a.25.25 0 01.248.22A3.993 3.993 0 0013 19.75h8.5a2.5 2.5 0 00.873-4.842zm-7.441 1.331a1.989 1.989 0 01-3.839.082.25.25 0 01.24-.321h2.625a3.621 3.621 0 00.684-.067.25.25 0 01.29.306zm4.544.036a2.215 2.215 0 00-.226.975 1.813 1.813 0 00.013.222.251.251 0 01-.249.278h-2.158a.25.25 0 01-.227-.355A3.877 3.877 0 0017 15.75c0-.1 0-.2-.014-.3a.25.25 0 01.334-.259l2.017.735a.252.252 0 01.146.142.248.248 0 01-.007.207z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});