define("ember-svg-jar/inlined/help-wheel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>help-wheel</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm9.428 8.666a.25.25 0 01-.235.334h-3.856a.253.253 0 01-.214-.12 6.05 6.05 0 00-2-2A.253.253 0 0115 6.664V2.808a.251.251 0 01.334-.236 10.051 10.051 0 016.094 6.095zM12 16a4 4 0 114-4 4 4 0 01-4 4zM8.666 2.572A.251.251 0 019 2.808v3.856a.251.251 0 01-.12.214 6.05 6.05 0 00-2 2A.253.253 0 016.663 9H2.807a.251.251 0 01-.207-.1.247.247 0 01-.031-.228 10.051 10.051 0 016.097-6.1zM2.572 15.334A.249.249 0 012.807 15h3.856a.251.251 0 01.214.12 6.05 6.05 0 002 2 .252.252 0 01.12.214v3.856a.25.25 0 01-.334.235 10.051 10.051 0 01-6.091-6.091zm12.762 6.095a.25.25 0 01-.334-.235v-3.856a.251.251 0 01.12-.214 6.05 6.05 0 002-2 .251.251 0 01.214-.12h3.856a.249.249 0 01.2.106.245.245 0 01.031.227 10.051 10.051 0 01-6.087 6.092z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});