define("ember-svg-jar/inlined/school-test-musical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>school-test-musical</title><path d=\"M8.5 20h-6a.5.5 0 01-.5-.5v-17a.5.5 0 01.5-.5h8.672a1 1 0 01.707.293l2.828 2.828a1 1 0 01.293.707V12a1 1 0 002 0V5.414A1.986 1.986 0 0016.414 4L13 .586A2.011 2.011 0 0011.586 0H2a2 2 0 00-2 2v18a2 2 0 002 2h6.5a1 1 0 000-2z\"/><path d=\"M7.774 4.954A1.494 1.494 0 006.946 6.3v4.259a.5.5 0 01-.538.5 2.981 2.981 0 00-.212-.008 2.75 2.75 0 102.75 2.75v-6a.25.25 0 01.4-.2l2 1.5a1 1 0 001.2-1.6l-3.2-2.4a1.5 1.5 0 00-1.572-.147zM23.685 16.61l-6-2.382a.5.5 0 00-.369 0l-6 2.382a.5.5 0 00-.316.475v3.13a.5.5 0 001 0v-2.08a.249.249 0 01.333-.235l5 1.765a.506.506 0 00.332 0l6-2.118a.5.5 0 00.019-.937z\"/><path d=\"M20.763 19.829l-2.93 1.034a1 1 0 01-.666 0l-2.93-1.034a.5.5 0 00-.666.472v2.145a.5.5 0 00.334.471l2.574.909a3.085 3.085 0 002.043 0l2.573-.909a.5.5 0 00.334-.471V20.3a.5.5 0 00-.666-.472z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});