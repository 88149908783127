define("ember-svg-jar/inlined/professions-man-astronaut.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-astronaut</title><path d=\"M5.006 14.027a.244.244 0 00.162-.168.249.249 0 00-.048-.229A7.109 7.109 0 013.5 9V8a8.444 8.444 0 011.187-4.328.251.251 0 00-.253-.372A3.752 3.752 0 001.25 7v8.374a.25.25 0 00.384.211 14.864 14.864 0 013.372-1.558zM20.5 8v1a7.109 7.109 0 01-1.62 4.63.25.25 0 00.114.4 14.867 14.867 0 013.372 1.557.249.249 0 00.384-.211V7a3.744 3.744 0 00-3.171-3.685.25.25 0 00-.256.374A8.448 8.448 0 0120.5 8zM22.533 16.917a13.476 13.476 0 00-4.655-2.186.249.249 0 00-.216.042A9.347 9.347 0 0112 16.5a9.341 9.341 0 01-5.662-1.728.252.252 0 00-.216-.041 13.473 13.473 0 00-4.656 2.186A2.466 2.466 0 00.5 18.894V23a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-4.106a2.464 2.464 0 00-.967-1.977zM20.25 19a.75.75 0 01-.75.75h-2a.75.75 0 010-1.5h2a.75.75 0 01.75.75zm-13 0a.75.75 0 01-.75.75h-2a.75.75 0 010-1.5h2a.75.75 0 01.75.75zm1.25 0a.5.5 0 01.5-.5h6a.5.5 0 01.5.5v3.25a.25.25 0 01-.25.25h-6.5a.25.25 0 01-.25-.25z\"/><path d=\"M12 15.5c3.733 0 7.5-2.01 7.5-6.5V8a7.5 7.5 0 00-15 0v1c0 4.49 3.767 6.5 7.5 6.5zm0-13a5.451 5.451 0 015.5 5.4c0 2.982-2.462 3.6-5.5 3.6s-5.5-.618-5.5-3.6A5.451 5.451 0 0112 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});