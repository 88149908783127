define("ember-svg-jar/inlined/focus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>focus</title><path d=\"M21.25 2H2.75A2.752 2.752 0 000 4.75v14.5A2.752 2.752 0 002.75 22h18.5A2.752 2.752 0 0024 19.25V4.75A2.752 2.752 0 0021.25 2zm-4.477 5.227a.75.75 0 011.061-1.06 8.257 8.257 0 010 11.666.75.75 0 01-1.061-1.06 6.757 6.757 0 000-9.546zM14.5 12A2.5 2.5 0 1112 9.5a2.5 2.5 0 012.5 2.5zm-7.273 4.773a.75.75 0 01-1.061 1.06 8.257 8.257 0 010-11.666.75.75 0 011.061 1.06 6.757 6.757 0 000 9.546z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});