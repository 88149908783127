define("ember-svg-jar/inlined/army-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-woman</title><circle cx=\"13.979\" cy=\"10.639\" r=\".99\"/><circle cx=\"10.021\" cy=\"10.639\" r=\".99\"/><path d=\"M12.933 14.1a2.332 2.332 0 00.437-.181.633.633 0 00-.3-1.188h-1.98a.633.633 0 00-.3 1.188 2.368 2.368 0 00.434.18 2.989 2.989 0 00.86.126 2.949 2.949 0 00.849-.125z\"/><path d=\"M22.725 19.318a15.143 15.143 0 00-6.825-2.687.248.248 0 01-.1-.452l.028-.017a.122.122 0 01.14 0 4.518 4.518 0 002.428.66 3.76 3.76 0 002.891-1.109.493.493 0 00-.229-.787 3.45 3.45 0 01-1.924-2.156.243.243 0 010-.2.247.247 0 01.146-.132l.8-.268a1.486 1.486 0 00.92-1.93l-.291-.777-.489-1.3a2.456 2.456 0 00-1.681-1.523A26.914 26.914 0 0012 5.938a26.9 26.9 0 00-6.539.7A2.454 2.454 0 003.77 8.164l-.489 1.3-.291.777a1.487 1.487 0 00.92 1.93l.782.261a.24.24 0 01.147.132.248.248 0 010 .2 3.415 3.415 0 01-1.9 2.1.491.491 0 00-.325.333.5.5 0 00.1.454A3.76 3.76 0 005.6 16.765a4.575 4.575 0 002.322-.592.247.247 0 01.27 0h.006a.247.247 0 01-.1.451 15.138 15.138 0 00-6.83 2.687 2.93 2.93 0 00-1.144 2.358v1.341a.99.99 0 101.979 0v-1.341a.964.964 0 01.373-.777 9.158 9.158 0 01.988-.652.247.247 0 01.371.214v2.8a.5.5 0 00.5.5H19.67a.5.5 0 00.495-.5v-2.8a.248.248 0 01.371-.215 9.275 9.275 0 01.989.653.962.962 0 01.372.776v1.342a.99.99 0 101.979 0v-1.341a2.929 2.929 0 00-1.151-2.351zm-6.748-.669a.248.248 0 01.185.337 3.669 3.669 0 01-3.39 2.292.029.029 0 01-.03-.029v-2.67a.25.25 0 01.076-.178.246.246 0 01.182-.069 21.365 21.365 0 012.977.317zm-4.719 2.6a.029.029 0 01-.03.029 3.668 3.668 0 01-3.39-2.292.248.248 0 01.185-.337A21.365 21.365 0 0111 18.332a.246.246 0 01.182.069.25.25 0 01.076.178zM7.052 10.392v-.6a.248.248 0 01.181-.239 66.677 66.677 0 019.534 0 .246.246 0 01.181.238v.6a4.948 4.948 0 01-9.9 0zM12 4.948a26.676 26.676 0 016.83.764.247.247 0 00.311-.3 7.419 7.419 0 00-14.277-.025.248.248 0 00.308.306l.047-.013A27.923 27.923 0 0112 4.948z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});