define("ember-svg-jar/inlined/police-hat-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-hat-4</title><path d=\"M23.841 9.689a1 1 0 00-.241-1.334l-.656-.485c-1.344-.989-2.412-1.77-2.921-3.187a.994.994 0 00-.866-.654 10.793 10.793 0 01-6.489-2.7 1.018 1.018 0 00-1.336 0 10.786 10.786 0 01-6.489 2.7 1 1 0 00-.866.655C3.468 6.1 2.4 6.881 1.052 7.87L.4 8.354a1 1 0 00-.241 1.335c.494.763 1.7 2.672 2.49 3.912a.249.249 0 01-.209.382h-.377a1 1 0 00-.993.993v.994a1 1 0 00.993.994h19.874a1 1 0 00.993-.994v-.994a1 1 0 00-.993-.993h-.377a.249.249 0 01-.209-.382c.786-1.24 1.996-3.149 2.49-3.912zM15.82 7.88l-1.557 1.084a.246.246 0 00-.086.3l.874 2.008a.52.52 0 01-.741.651L12.122 10.7a.252.252 0 00-.244 0L9.69 11.926a.52.52 0 01-.741-.651l.875-2.008a.249.249 0 00-.086-.3L8.18 7.88a.486.486 0 01.341-.85h1.829a.247.247 0 00.225-.144l.957-2.057a.527.527 0 01.937 0l.954 2.057a.25.25 0 00.225.144h1.829a.487.487 0 01.343.85zM19.424 18.454H4.576a.5.5 0 00-.381.815A9.941 9.941 0 0012 22.926a9.939 9.939 0 007.805-3.657.5.5 0 00-.381-.815z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});