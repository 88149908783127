define("ember-svg-jar/inlined/office-file-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-file-key</title><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.425A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.248A.25.25 0 0014.75 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.248A.25.25 0 0021.75 7zM6.561 15.675l2.381 2.382a.625.625 0 01-.884.883l-1.806-1.8c-.04-.04-.127-.028-.127.076V18.5a.625.625 0 01-1.25 0v-6a.625.625 0 111.25 0v1.241c0 .114.12.129.184.066l1.749-1.748a.625.625 0 01.884.883l-2.381 2.379a.25.25 0 000 .354zm4.815-.8H12.5a.625.625 0 010 1.25h-1.127a.248.248 0 00-.248.248V17.5a.375.375 0 00.375.375h2a.625.625 0 010 1.25h-2A1.626 1.626 0 019.875 17.5v-4a1.627 1.627 0 011.625-1.627h2a.625.625 0 010 1.25h-2a.375.375 0 00-.375.375v1.124a.251.251 0 00.251.251zm8.749-1.375a2.622 2.622 0 01-1.825 2.489.247.247 0 00-.177.233V18.5a.625.625 0 11-1.25 0v-2.278a.247.247 0 00-.177-.233 2.622 2.622 0 01-1.821-2.489v-1a.625.625 0 111.25 0v1a1.375 1.375 0 002.75 0v-1a.625.625 0 111.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});