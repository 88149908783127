define("ember-svg-jar/inlined/footwear-slippers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>footwear-slippers</title><path d=\"M0 1.5A1.5 1.5 0 001.5 3H3a.5.5 0 01.5.5v2.938a.868.868 0 00.451.715l1.657.554a.51.51 0 00.673-.232l1.943-3.919A1 1 0 019.12 3h1.812a.5.5 0 01.449.72L9 8.574a1 1 0 00.566 1.384l1.265.445a.5.5 0 00.55-.072 5.5 5.5 0 017.459.227.5.5 0 00.761-.073l1.93-2.8a.5.5 0 00-.3-.772A3.5 3.5 0 0118.5 3.5.5.5 0 0119 3h3.5a1.5 1.5 0 000-3h-21A1.5 1.5 0 000 1.5zM24 22.5a1.5 1.5 0 00-1.5-1.5H21a.5.5 0 01-.5-.5v-2.937a.868.868 0 00-.451-.716l-1.657-.554a.51.51 0 00-.673.232l-1.943 3.919a1 1 0 01-.9.556h-1.808a.5.5 0 01-.449-.72L15 15.426a1 1 0 00-.566-1.384l-1.262-.442a.5.5 0 00-.55.072 5.5 5.5 0 01-7.459-.227.5.5 0 00-.761.073l-1.93 2.8a.5.5 0 00.3.772A3.5 3.5 0 015.5 20.5a.5.5 0 01-.5.5H1.5a1.5 1.5 0 000 3h21a1.5 1.5 0 001.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});