define("ember-svg-jar/inlined/messaging-google-buzz", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messaging-google-buzz</title><path d=\"M14 8.57a.5.5 0 000 .73l6.91 7.21a.5.5 0 00.72 0A8.14 8.14 0 0024 10.82a8.63 8.63 0 00-3.92-6.94.5.5 0 00-.61 0zM7.86 11.15a.5.5 0 00.68 0l9.24-7.8a.5.5 0 00-.13-.84A14.55 14.55 0 0012 1.42a13.35 13.35 0 00-9.5 3.67.5.5 0 000 .7zM6.62 12.8a.5.5 0 000-.74L1.71 7.13a.5.5 0 00-.8.12A7.59 7.59 0 000 10.82a8.08 8.08 0 002.35 5.59zM12.72 10.22a.5.5 0 00-.68 0l-9 7.58-2.12 4.05a.5.5 0 00.44.73.49.49 0 00.2 0L8 19.69a15 15 0 004 .53 14.09 14.09 0 007.49-2.08.5.5 0 00.09-.77z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});