define("ember-svg-jar/inlined/arrow-thick-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-left</title><path d=\"M22.9 8.49H11.151a.25.25 0 01-.25-.25V3.215A1 1 0 009.2 2.507L.4 11.278a1 1 0 000 1.415l8.8 8.8a1 1 0 001.707-.707V15.74a.25.25 0 01.25-.25H22.9a1 1 0 001-1v-5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});