define("ember-svg-jar/inlined/shield-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shield-home</title><path d=\"M24 1.953A1.96 1.96 0 0022.044.006H1.959A1.958 1.958 0 00.012 1.965L0 9.306a15.147 15.147 0 0011.861 14.669 1 1 0 00.4 0A15.145 15.145 0 0023.988 9.2zm-5.177 7.672a.5.5 0 01-.323.882h-1a.5.5 0 00-.5.5v4.5a.5.5 0 01-.5.5H14a.5.5 0 01-.5-.5v-2.25a1.5 1.5 0 10-3 0v2.25a.5.5 0 01-.5.5H7.5a.5.5 0 01-.5-.5v-4.5a.5.5 0 00-.5-.5h-1a.5.5 0 01-.323-.882l6.5-5.5a.5.5 0 01.646 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});