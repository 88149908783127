define("ember-svg-jar/inlined/earpods-double-tap-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earpods-double-tap-1</title><path d=\"M8.25 6.5a7.953 7.953 0 011.5-4.654.25.25 0 00-.068-.357A6.347 6.347 0 006.25.5a6.215 6.215 0 00-4.612 1.986 1.5 1.5 0 00-.388 1.007v5.014a1.5 1.5 0 00.389 1.008A6.214 6.214 0 006.25 11.5a6.445 6.445 0 001.392-.15.5.5 0 01.608.488V22.5a1.5 1.5 0 001.5 1.5h1a1.5 1.5 0 001.5-1.5v-8.932a.25.25 0 00-.121-.214A8 8 0 018.25 6.5zm-3-.5a1 1 0 11-1-1 1 1 0 011 1z\"/><circle cx=\"16.25\" cy=\"6.5\" r=\"3\"/><path d=\"M16.25 0a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm0 11a4.5 4.5 0 114.5-4.5 4.505 4.505 0 01-4.5 4.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});