define("ember-svg-jar/inlined/ecology-globe-hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ecology-globe-hand</title><path d=\"M9.052 13.511a1 1 0 00.361-1.368A6.753 6.753 0 0114.5 2.041a.45.45 0 01.5.416c.021.3.315.318.415.378a1.391 1.391 0 01.874 1.292v.8a.347.347 0 01-.347.347h-2.4a.307.307 0 01-.068-.006l-.9-.181a1.389 1.389 0 00-1.332.461l-1.2 1.408a.348.348 0 00-.069.326L10.43 8.8a1.391 1.391 0 001.332.991h1.186a.348.348 0 01.311.191l.542 1.084a.353.353 0 01.027.241l-.146.581a1.392 1.392 0 00.281 1.228l1.064 1.284a.348.348 0 00.49.044l1.319-1.1a1.391 1.391 0 00.5-1.069v-1.7a.354.354 0 01.037-.156l1.1-2.2a.348.348 0 00-.311-.5H17.9a.346.346 0 01-.311-.192l-.38-.759a.349.349 0 010-.319.352.352 0 01.264-.182l1.48-.185a1.39 1.39 0 001.12-.864.486.486 0 01.86-.082 6.739 6.739 0 01-1.451 8.883 1 1 0 001.256 1.557A8.748 8.748 0 107.684 13.15a1 1 0 001.368.361z\"/><rect y=\"14.057\" width=\"4\" height=\"9\" rx=\"1\" ry=\"1\"/><path d=\"M20.363 17.139h-.015l-2 .639a.5.5 0 00-.348.484 2.25 2.25 0 01-2.249 2.291H10.5a.5.5 0 010-1h5.25a1.25 1.25 0 100-2.5l-3.685.007a4.207 4.207 0 01-.572-.382A6.836 6.836 0 007.5 15.057h-2a.5.5 0 00-.5.5v5.9a.5.5 0 00.339.474c.706.24 1.341.46 1.917.66C9.88 23.5 11.344 24 12.674 24c1.624 0 3.049-.743 6.139-2.387 1.281-.681 2.876-1.529 4.911-2.547a.5.5 0 00.129-.8 3.236 3.236 0 00-3.49-1.127z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});