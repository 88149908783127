define("ember-svg-jar/inlined/shopping-bag-side", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-bag-side</title><path d=\"M23 5.947A1.929 1.929 0 0021.1 4h-1.849A.25.25 0 0119 3.75V3a3 3 0 00-3-3h-3a3 3 0 00-3 3v.75a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25V3a1 1 0 011-1 1 1 0 000-2 3 3 0 00-3 3v.75a.25.25 0 01-.25.25H3.5a2 2 0 00-2.066 1.859L0 22.053A2.014 2.014 0 002.072 24H22.1a1.941 1.941 0 001.9-2.01zM12 3a1 1 0 011-1h3a1 1 0 011 1v.75a.25.25 0 01-.25.25h-4.5a.25.25 0 01-.25-.25zM2.559 21.975a.5.5 0 01-.5-.547l1.4-14.974A.5.5 0 013.957 6h2.625a.5.5 0 01.5.472l.833 14.994a.5.5 0 01-.5.528z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});