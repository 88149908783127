define("ember-svg-jar/inlined/family-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-home</title><path d=\"M23.488 6.127L12.926.226a2.009 2.009 0 00-1.876.013L.512 6.125a1 1 0 00.976 1.746l10.365-5.818a.252.252 0 01.244 0l10.415 5.82a1 1 0 00.976-1.746z\"/><circle cx=\"12\" cy=\"10\" r=\"2.5\"/><path d=\"M12 13a3.5 3.5 0 00-3.5 3.5v2a.5.5 0 00.5.5h.594a.5.5 0 01.5.451l.41 4.1A.5.5 0 0011 24h2a.5.5 0 00.5-.45l.41-4.1a.5.5 0 01.5-.451H15a.5.5 0 00.5-.5v-2A3.5 3.5 0 0012 13z\"/><circle cx=\"20.25\" cy=\"11.25\" r=\"2.25\"/><path d=\"M20.25 14A3.254 3.254 0 0017 17.25V19a.5.5 0 00.5.5h.471a.5.5 0 01.5.45l.367 3.6a.5.5 0 00.5.449h1.834a.5.5 0 00.5-.449l.367-3.6a.5.5 0 01.5-.45H23a.5.5 0 00.5-.5v-1.75A3.254 3.254 0 0020.25 14z\"/><circle cx=\"3.75\" cy=\"11.25\" r=\"2.25\"/><path d=\"M3.75 14A3.254 3.254 0 00.5 17.25V19a.5.5 0 00.5.5h.471a.5.5 0 01.5.45l.367 3.6a.5.5 0 00.5.449h1.829a.5.5 0 00.5-.449l.367-3.6a.5.5 0 01.5-.45H6.5A.5.5 0 007 19v-1.75A3.254 3.254 0 003.75 14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});