define("ember-svg-jar/inlined/ceiling-lamp-three", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ceiling-lamp-three</title><path d=\"M14.037 20.819v-1.537a2.021 2.021 0 00-.833-1.631.5.5 0 01-.2-.4v-5.126a1 1 0 00-2 0v5.123a.5.5 0 01-.2.4 2.021 2.021 0 00-.833 1.631v1.537A1.837 1.837 0 019.521 22a1 1 0 00.777 1.63h3.4a1 1 0 00.781-1.63 1.838 1.838 0 01-.442-1.181zM7.7 11.268a1 1 0 00-1.373.341l-1.949 3.245a.5.5 0 01-.383.24 2.019 2.019 0 00-1.554.969l-.793 1.317a1.84 1.84 0 01-.985.78 1 1 0 00-.174 1.8l2.917 1.754a1 1 0 001.066-.022 1.006 1.006 0 00.44-.974 1.833 1.833 0 01.227-1.235l.791-1.317a2.018 2.018 0 00.127-1.827.5.5 0 01.034-.451l1.952-3.244a1 1 0 00-.343-1.376zM23.336 18.16a1.848 1.848 0 01-.985-.78l-.792-1.317a2.022 2.022 0 00-1.554-.968.5.5 0 01-.383-.24l-1.952-3.246a1 1 0 10-1.714 1.032l1.952 3.244a.5.5 0 01.033.451 2.022 2.022 0 00.128 1.827l.792 1.317a1.84 1.84 0 01.228 1.236 1 1 0 001.506.995l2.916-1.754a1 1 0 00-.175-1.8zM5.55 10.144h12.9a1 1 0 00.981-1.2A3.51 3.51 0 0016 6.144H8a3.509 3.509 0 00-3.43 2.8 1 1 0 00.98 1.2zm13.39-1.1zM11.25 5.125h1.5a.5.5 0 00.5-.5v-3a1.25 1.25 0 00-2.5 0v3a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});