define("ember-svg-jar/inlined/file-code-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-share</title><path d=\"M17.75 11.5V15a.75.75 0 001.5 0v-3.5a.75.75 0 10-1.5 0zM13.75 5a.75.75 0 00-1.5 0v3.5a.75.75 0 001.5 0zM20.25 8V5.5A1.251 1.251 0 0019 4.251h-1.5A1.251 1.251 0 0016.25 5.5V8a1.252 1.252 0 001.25 1.25H19A1.252 1.252 0 0020.25 8zm-1.5-.5a.25.25 0 01-.25.25H18a.25.25 0 01-.25-.25V6a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25z\"/><path d=\"M23.414 3L21 .587A2 2 0 0019.585 0H8a2 2 0 00-2 2v9.563a.25.25 0 00.474.11 3.466 3.466 0 011.394-1.451A.245.245 0 008 10.01V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.121a.5.5 0 01.146.355V18a.5.5 0 01-.5.5h-7.519a.246.246 0 00-.211.379 4.245 4.245 0 01.616 1.425.246.246 0 00.238.2H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M8.442 16a.245.245 0 01.266.012 2.74 2.74 0 004.167-1.425A2.758 2.758 0 0010.25 11a2.807 2.807 0 00-2.713 3.058.245.245 0 01-.115.222L4.558 16a.245.245 0 01-.266-.012 2.707 2.707 0 00-2.406-.349 2.706 2.706 0 00-1.762 1.775A2.759 2.759 0 002.75 21a2.729 2.729 0 001.887-.761.246.246 0 01.259-.054s1.924.782 2.464 1a.237.237 0 01.144.214 2.753 2.753 0 105.361-1.025 2.681 2.681 0 00-1.736-1.74 2.74 2.74 0 00-2.767.623.242.242 0 01-.258.053L5.553 18.3a.114.114 0 01-.06-.084l-.03-.353a.118.118 0 01.056-.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});