define("ember-svg-jar/inlined/phone-write", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-write</title><path d=\"M15.529 12.413a.248.248 0 00-.073.176v5.9a1.5 1.5 0 01-1.5 1.5h-9a1.5 1.5 0 01-1.5-1.5v-13a1.5 1.5 0 011.5-1.5h6.876a.248.248 0 00.176-.073l1.5-1.5a.25.25 0 00-.176-.427H3.956a2.5 2.5 0 00-2.5 2.5v17a2.5 2.5 0 002.5 2.5h11a2.5 2.5 0 002.5-2.5v-10.4a.25.25 0 00-.427-.176z\"/><path d=\"M10.606 9.107a.251.251 0 00-.422.128l-.628 3.142a.5.5 0 00.588.588l3.143-.628a.251.251 0 00.19-.173.248.248 0 00-.063-.249zM21.772.779a2.618 2.618 0 00-3.7 0L11.4 7.42a.256.256 0 00-.074.177.25.25 0 00.074.177l3.353 3.354a.25.25 0 00.353 0l6.68-6.642a2.622 2.622 0 00-.014-3.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});