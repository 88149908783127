define("ember-svg-jar/inlined/profile-user-cash-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>profile-user-cash-message</title><path d=\"M3 18h1.932a.249.249 0 01.189.086l3.126 3.573A1 1 0 009 22a1.026 1.026 0 00.352-.063A1 1 0 0010 21v-2.75a.25.25 0 01.25-.25H11a1 1 0 000-2H9a1 1 0 00-1 1v.673a.25.25 0 01-.438.165l-1.309-1.5A1 1 0 005.5 16H3c-.38 0-1-.9-1-1.614V3a1 1 0 011-1h11a1 1 0 011 1v1a1 1 0 002 0V3a3 3 0 00-3-3H3a3 3 0 00-3 3v11.386C0 16.057 1.311 18 3 18z\"/><path d=\"M23 4c-3.75 0-8.135 2.5-8.135 6.875 0 .7-1.96 3.093-1.86 4.49.032.443.043.789.606.815.358.014.8.008 1.069 0a.247.247 0 01.179.069.253.253 0 01.077.177c.01.788.1 3.173.746 3.822.581.581 1.485.724 3.069.729a.249.249 0 01.249.25v2.523a.249.249 0 00.25.25h4.5a.249.249 0 00.25-.25V4.25a.25.25 0 00-.25-.25zM8.125 14.5a1 1 0 001-1v-.176a.251.251 0 01.186-.242 2.427 2.427 0 00.286-4.6L7.4 7.6a.431.431 0 01.16-.832h2.165a1 1 0 000-2h-.35a.25.25 0 01-.25-.25v-.084a1 1 0 00-2 0v.176a.251.251 0 01-.186.242 2.427 2.427 0 00-.286 4.6l2.2.88a.431.431 0 01-.16.832H6.525a1 1 0 000 2h.35a.249.249 0 01.25.25v.086a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});