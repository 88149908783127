define("ember-svg-jar/inlined/electronics-fuse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>electronics-fuse</title><path d=\"M23.49 6.514L17.48.5a1.537 1.537 0 00-2.121 0L14.3 1.564a1.464 1.464 0 00-.394 1.3.252.252 0 01-.069.22L3.094 13.828a.247.247 0 01-.219.07 1.468 1.468 0 00-1.3.393L.51 15.352a1.5 1.5 0 000 2.121l6.01 6.01a1.5 1.5 0 002.121 0l1.059-1.06a1.467 1.467 0 00.393-1.3.25.25 0 01.07-.22l10.724-10.726a.249.249 0 01.225-.069 1.288 1.288 0 00.257.027 1.484 1.484 0 001.06-.44l1.061-1.06a1.5 1.5 0 000-2.121zM4.4 15a.25.25 0 010-.354L14.652 4.392a.25.25 0 01.353 0l4.6 4.6a.25.25 0 010 .354L9.348 19.594a.249.249 0 01-.353 0z\"/><path d=\"M16.24 7.753a.749.749 0 00-1.06 0l-.943.942a.247.247 0 01-.176.074H12a.75.75 0 00-.75.75v4.149a.254.254 0 01-.086.189.25.25 0 01-.2.058l-1.331-.189a.764.764 0 00-.633.213l-1.24 1.24a.75.75 0 001.061 1.06l.886-.886a.248.248 0 01.212-.071l1.98.283a.751.751 0 00.856-.743v-4.3a.25.25 0 01.25-.25h1.475a.753.753 0 00.53-.22l1.23-1.238a.75.75 0 000-1.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});