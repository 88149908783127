define("ember-svg-jar/inlined/cursor-target-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-target-1</title><path d=\"M24 12a1.25 1.25 0 00-1.25-1.25h-1.595a9.254 9.254 0 00-7.905-7.9V1.248a1.25 1.25 0 10-2.5 0v1.6a9.254 9.254 0 00-7.9 7.9h-1.6a1.25 1.25 0 000 2.5h1.6a9.254 9.254 0 007.9 7.9v1.6a1.25 1.25 0 102.5 0v-1.6a9.254 9.254 0 007.905-7.9h1.595A1.25 1.25 0 0024 12zm-10.442 6.561a.25.25 0 01-.308-.243v-1.07a1.25 1.25 0 10-2.5 0v1.07a.25.25 0 01-.308.243 6.764 6.764 0 01-5.006-5.005.25.25 0 01.244-.308h1.07a1.25 1.25 0 000-2.5H5.68a.252.252 0 01-.2-.095.254.254 0 01-.047-.213 6.767 6.767 0 015.006-5.006.251.251 0 01.213.048.248.248 0 01.1.2v1.07a1.25 1.25 0 102.5 0V5.678a.251.251 0 01.308-.244 6.767 6.767 0 015.006 5.006.254.254 0 01-.047.213.252.252 0 01-.2.095H17.25a1.25 1.25 0 000 2.5h1.07a.25.25 0 01.244.308 6.764 6.764 0 01-5.006 5.005z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});