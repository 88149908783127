define("ember-svg-jar/inlined/car-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-3</title><path d=\"M22.936 9.625H23a1 1 0 001-1v-1a1 1 0 00-1-1h-1.35a1.508 1.508 0 00-1.2.6.095.095 0 01-.168-.038l-.48-2.4a3.007 3.007 0 00-2.942-2.412H7.14A3.007 3.007 0 004.2 4.786l-.48 2.4a.095.095 0 01-.168.038 1.508 1.508 0 00-1.2-.6H1a1 1 0 00-1 1v1a1 1 0 001 1h.064a.25.25 0 01.181.423A4.438 4.438 0 000 13.125v4a1.5 1.5 0 00.857 1.349A.25.25 0 011 18.7v1.425a1.5 1.5 0 001.5 1.5h2a1.5 1.5 0 001.5-1.5v-1.25a.25.25 0 01.25-.25h11.5a.25.25 0 01.25.25v1.25a1.5 1.5 0 001.5 1.5h2a1.5 1.5 0 001.5-1.5V18.7a.25.25 0 01.143-.226A1.5 1.5 0 0024 17.125v-4a4.438 4.438 0 00-1.245-3.077.25.25 0 01.181-.423zm-19.686 8H1.5a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h1.75a.75.75 0 010 1.5zm2.122-4.1a.248.248 0 01-.2.1H4a2 2 0 01-1.98-1.714.252.252 0 01.248-.286H4.5a.675.675 0 01.621.485l.3 1.2a.248.248 0 01-.049.219zm10.6-.246l-.544 1.633a2.5 2.5 0 01-2.372 1.709h-2.115a2.5 2.5 0 01-2.371-1.705l-.545-1.633a.5.5 0 01.475-.658h7a.5.5 0 01.475.658zm2.444-4.749a.254.254 0 01-.194.091H5.775a.254.254 0 01-.194-.091.252.252 0 01-.051-.208l.629-3.147a1 1 0 01.981-.8h9.72a1 1 0 01.981.8l.63 3.147a.256.256 0 01-.052.212zm.406 5.091a.251.251 0 01-.243-.311l.3-1.2a.675.675 0 01.621-.485h2.228a.248.248 0 01.189.086.251.251 0 01.059.2A2 2 0 0120 13.625zm4.175 3v.5a.5.5 0 01-.5.5H20.75a.75.75 0 010-1.5h1.75a.5.5 0 01.5.504z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});