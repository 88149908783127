define("ember-svg-jar/inlined/multiple-woman-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-woman-1</title><path d=\"M22.269 16.967a21.7 21.7 0 00-3.915-1.858c-.355-.124-.729-.442-.729-.8v-.531a.254.254 0 01.075-.178.259.259 0 01.179-.072 7.466 7.466 0 004.86-1.367.519.519 0 00.194-.384.556.556 0 00-.162-.414c-.661-.66-1.059-1.058-1.234-3.636C21.23 3.2 18.094 1.6 15.5 1.6a6.354 6.354 0 00-1.61.208.251.251 0 00-.113.419 8.19 8.19 0 012.256 5.4c.136 2.017.329 2.21.8 2.677a2.043 2.043 0 01.6 1.6 2 2 0 01-.833 1.486 8.94 8.94 0 01-1.936 1.055.25.25 0 00-.025.456q.7.36 1.4.778a5.22 5.22 0 012.46 4.705v1.767a.25.25 0 00.25.25h5a.25.25 0 00.25-.25v-1.766a3.761 3.761 0 00-1.73-3.418z\"/><path d=\"M17 20.385a3.761 3.761 0 00-1.731-3.418 21.7 21.7 0 00-3.915-1.858c-.355-.124-.729-.442-.729-.8v-.531a.254.254 0 01.075-.178.259.259 0 01.179-.072 7.477 7.477 0 004.86-1.367.519.519 0 00.194-.384.556.556 0 00-.162-.414 3.027 3.027 0 01-.8-1.1 8.561 8.561 0 01-.435-2.537 6.212 6.212 0 00-3.067-5.384 6.281 6.281 0 00-5.938 0 6.212 6.212 0 00-3.067 5.386 8.471 8.471 0 01-.439 2.542 2.594 2.594 0 01-.169.342 3.839 3.839 0 01-.656.788.5.5 0 00.067.763 7.351 7.351 0 004.671 1.37h.188a.251.251 0 01.254.25v.53c0 .357-.374.675-.729.8a21.7 21.7 0 00-3.915 1.858A3.764 3.764 0 000 20.385v1.767a.25.25 0 00.25.25h16.5a.25.25 0 00.25-.25v-1.767z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});