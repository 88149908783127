define("ember-svg-jar/inlined/police-hat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-hat</title><path d=\"M19.252 14.259c.285.069.57.134.862.2a.5.5 0 01.388.488V16a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5v-1.055a.5.5 0 01.389-.487c.289-.066.575-.13.851-.2A28.538 28.538 0 0112 13.249a28.522 28.522 0 017.252 1.01zM23.172 8.079a2.409 2.409 0 00-1.1-1.53l-8.62-6.09a2.527 2.527 0 00-2.9 0l-8.6 6.07a2.509 2.509 0 00-1.13 1.55 2.545 2.545 0 00.33 1.94l2.115 2.746a.5.5 0 00.511.181c.21-.049.417-.1.624-.147a29.683 29.683 0 017.6-1.05 29.815 29.815 0 017.6 1.05c.208.053.416.1.627.15a.5.5 0 00.507-.183l2.146-2.8a2.5 2.5 0 00.29-1.887zm-8.61-.62a1.686 1.686 0 01-.76 1.42l-1.57 1.05a.443.443 0 01-.47 0l-1.57-1.05a1.711 1.711 0 01-.76-1.42v-2.27a.408.408 0 01.21-.36 4.192 4.192 0 014.72 0 .42.42 0 01.2.36zM19.7 18.044a.49.49 0 00-.2-.044h-15a.5.5 0 00-.207.045 1.947 1.947 0 00-1.161 1.537 1.877 1.877 0 00.789 1.744 13.542 13.542 0 0016.158 0 1.88 1.88 0 00.789-1.744 1.946 1.946 0 00-1.168-1.538z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});