define("ember-svg-jar/inlined/lens-shutter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lens-shutter</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm8.5 12a8.453 8.453 0 01-1.214 4.379.249.249 0 01-.43 0l-3.32-5.75a.248.248 0 010-.25.251.251 0 01.217-.125h4.361a.249.249 0 01.245.205A8.456 8.456 0 0120.5 12zm-17 0a8.453 8.453 0 011.214-4.379.249.249 0 01.43 0l3.32 5.75a.248.248 0 010 .25.251.251 0 01-.217.125H3.886a.249.249 0 01-.245-.205A8.456 8.456 0 013.5 12zm15.935-4.121a.25.25 0 01-.218.371h-6.64a.25.25 0 01-.216-.375l2.18-3.775a.25.25 0 01.3-.111 8.522 8.522 0 014.594 3.89zM9.041 9.624a.25.25 0 01-.433 0L6.429 5.849a.248.248 0 01.054-.315A8.466 8.466 0 0112 3.5h.148a.252.252 0 01.213.127.249.249 0 010 .248zm2.381 6.126a.25.25 0 01.217.375L9.458 19.9a.25.25 0 01-.3.111 8.527 8.527 0 01-4.593-3.892.25.25 0 01.218-.371zm3.537-1.375a.25.25 0 01.433 0l2.179 3.776a.248.248 0 01-.054.315A8.466 8.466 0 0112 20.5h-.148a.25.25 0 01-.212-.375z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});