define("ember-svg-jar/inlined/warehouse-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>warehouse-1</title><path d=\"M23.861 3.9C21.709 1.651 17.16.254 11.988.254 6.831.254 2.29 1.651.139 3.9A.5.5 0 000 4.246v1a1 1 0 001 1h22a1 1 0 001-1v-1a.5.5 0 00-.139-.346zM23.2 21.766a.249.249 0 01-.2-.245V8.246a.5.5 0 00-.5-.5h-21a.5.5 0 00-.5.5v13.275a.25.25 0 01-.2.245 1 1 0 00.2 1.98h22a1 1 0 00.2-1.98zm-4.7-9.02h-13a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v1a.5.5 0 01-.5.5zm-13 2h13a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5zm0 4h13a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});