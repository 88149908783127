define("ember-svg-jar/inlined/products-give-gift", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>products-give-gift</title><path d=\"M16.5 11.886a.25.25 0 00-.25-.25h-4.5a.25.25 0 00-.25.25v2.751a1.5 1.5 0 001.5 1.5h3.25a.25.25 0 00.25-.25zM18.75 11.636a.25.25 0 00-.25.25v4a.25.25 0 00.25.25H22a1.5 1.5 0 001.5-1.5v-2.75a.25.25 0 00-.25-.25zM16.25 9.636a.25.25 0 00.25-.25V5.434a.249.249 0 01.25-.25h1.5a.249.249 0 01.25.25v3.952a.25.25 0 00.25.25h4.5a.25.25 0 00.25-.25v-2.7a1.5 1.5 0 00-.946-1.394.249.249 0 01-.1-.39 2.764 2.764 0 00-4.112-3.681 3.542 3.542 0 00-.615.965.25.25 0 01-.452 0 3.542 3.542 0 00-.615-.965A2.764 2.764 0 0012.547 4.9a.25.25 0 01.048.221.245.245 0 01-.149.169 1.5 1.5 0 00-.946 1.394v2.7a.25.25 0 00.25.25zm3.5-7a.761.761 0 011.078 1.074 5.547 5.547 0 01-1.358.6.25.25 0 01-.318-.319 5.51 5.51 0 01.603-1.358zm-5.586 0a.761.761 0 011.072 0 5.448 5.448 0 01.6 1.36.25.25 0 01-.318.318 5.552 5.552 0 01-1.357-.6.761.761 0 01.008-1.081zM1.5 14.339a1 1 0 00-1 1v7.25a1 1 0 002 0v-7.25a1 1 0 00-1-1zM17 20.089a1 1 0 00-1-1H6.875a.625.625 0 010-1.25H9.4a1 1 0 00.92-1.393 2.234 2.234 0 00-2.056-1.357H4a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.764A2.239 2.239 0 0017 20.352z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});