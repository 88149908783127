define("ember-svg-jar/inlined/attachment-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>attachment-1</title><path d=\"M12 0a6.97 6.97 0 00-6.962 6.961v11.91a5.128 5.128 0 1010.255 0V9.713a3.293 3.293 0 00-6.586 0V17.5a1 1 0 002 0V9.713a1.293 1.293 0 012.586 0v9.158a3.128 3.128 0 11-6.255 0V6.961a4.962 4.962 0 019.924 0V14.5a1 1 0 002 0V6.961A6.97 6.97 0 0012 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});