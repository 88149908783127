define("ember-svg-jar/inlined/marine-mammal-penguin-mother", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>marine-mammal-penguin-mother</title><path d=\"M5.412 20.5a.75.75 0 011.5 0 .688.688 0 00.75.75h1.386A7.037 7.037 0 009.412 19a2.3 2.3 0 00-.223-1.057 2.708 2.708 0 00.473-1.568v-1.42a2 2 0 00.78-.425.776.776 0 00.024-1.082.722.722 0 00-1.037-.021 3.968 3.968 0 01-2.017.323 2.462 2.462 0 00-2.25 2.625 2.969 2.969 0 00.159.918A4.891 4.891 0 002.912 21.5a2.324 2.324 0 002.5 2.5A3.709 3.709 0 008.2 22.75h-.538a2.187 2.187 0 01-2.25-2.25z\"/><path d=\"M20.316 22.026c-.529-.6-1.355-3.662-1.21-6.231a22.476 22.476 0 00.482-4.916C19.588 3.4 15.364.028 11.178 0 6.3 0 5.159 3.57 5.11 3.735 4.129 7 5.439 8.918 6.633 9.927a8.511 8.511 0 00.237 2.2 1 1 0 001.938-.492 6.749 6.749 0 01-.18-1.484c.4-2.01 1.043-2.78 2-2.783a2.989 2.989 0 012.331 1.111.5.5 0 01.092.435 16.521 16.521 0 00-.459 3.966c0 2.863 2.837 4.2 4.4 4.713a.249.249 0 01.17.216 16.418 16.418 0 00.782 3.859.251.251 0 01-.244.332h-6.612a1 1 0 000 2h9a1 1 0 00.228-1.974zM7.124 8.367a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});