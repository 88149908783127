define("ember-svg-jar/inlined/dog-poop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dog-poop</title><path d=\"M21.75 15.989a2.5 2.5 0 00-2.5-2.5H17.8c2.432-4.2-.209-6.7-3.033-6.975a.5.5 0 00-.543.427c-.551 3.918-8.47.5-8.47 4.461a2.087 2.087 0 002.087 2.087H5.25a2.5 2.5 0 000 5 2.5 2.5 0 000 5h14a2.5 2.5 0 000-5 2.5 2.5 0 002.5-2.5zM4.75 6.011a1 1 0 00.707-.293l1.5-1.5A1 1 0 005.543 2.8l-.793.8-.793-.8a1 1 0 00-1.414 1.418l1.5 1.5a1 1 0 00.707.293zM18.543 3.718a1 1 0 001.414 0l1.5-1.5A1 1 0 0020.043.8l-.793.8-.793-.8a1 1 0 00-1.414 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});