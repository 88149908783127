define("ember-svg-jar/inlined/plane-land", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-land</title><path d=\"M11.381 9.421a.5.5 0 00.287.288l4.137 1.607a.25.25 0 00.322-.327L14.014 5.8a1 1 0 00-.532-.541l-2.228-.955a1 1 0 00-1.33 1.276z\"/><path d=\"M22.335 15.753a2.483 2.483 0 00-1.353-1.353h-.011L7.093 9.006A.5.5 0 016.8 8.7l-.78-2.341a1 1 0 00-.554-.6l-2.6-1.112a1 1 0 00-1.395.918v5.4a.994.994 0 00.629.928l5.577 2.231a.25.25 0 01.013.459l-4.85 2.262a1 1 0 00.072 1.842l2.729 1.024a1.011 1.011 0 00.633.023l8.023-2.36a.5.5 0 01.314.011l4.458 1.634a2.5 2.5 0 003.266-3.266z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});