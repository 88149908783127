define("ember-svg-jar/inlined/single-neutral-shield.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-shield</title><path d=\"M5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M24 2.456a1.541 1.541 0 00-.928-1.415A16.365 16.365 0 0017.478 0a15.986 15.986 0 00-5.55 1.04A1.544 1.544 0 0011 2.456v3.393a7.716 7.716 0 005.19 6.805 3.213 3.213 0 001.288.346 3.247 3.247 0 001.289-.346C21.848 11.48 24 8.682 24 5.849zM20.5 6.25h-1.75a.5.5 0 00-.5.5V8.5a.75.75 0 01-1.5 0V6.75a.5.5 0 00-.5-.5H14.5a.75.75 0 010-1.5h1.75a.5.5 0 00.5-.5V2.5a.75.75 0 011.5 0v1.75a.5.5 0 00.5.5h1.75a.75.75 0 010 1.5zM5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M24 2.456a1.541 1.541 0 00-.928-1.415A16.365 16.365 0 0017.478 0a15.986 15.986 0 00-5.55 1.04A1.544 1.544 0 0011 2.456v3.393a7.716 7.716 0 005.19 6.805 3.213 3.213 0 001.288.346 3.247 3.247 0 001.289-.346C21.848 11.48 24 8.682 24 5.849zM20.5 6.25h-1.75a.5.5 0 00-.5.5V8.5a.75.75 0 01-1.5 0V6.75a.5.5 0 00-.5-.5H14.5a.75.75 0 010-1.5h1.75a.5.5 0 00.5-.5V2.5a.75.75 0 011.5 0v1.75a.5.5 0 00.5.5h1.75a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});