define("ember-svg-jar/inlined/contactless-payment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>contactless-payment</title><path d=\"M20.269 8.869a3.3 3.3 0 00-2.027-.7 2.993 2.993 0 00-.6.064.251.251 0 00-.189.174.248.248 0 00.063.248l3.9 3.9a3.153 3.153 0 01.907 1.638 3 3 0 01-.823 2.687l-.383.384a.247.247 0 00-.073.194.251.251 0 00.1.183l.185.139a.75.75 0 11-.9 1.2l-3.276-2.457-.011-.008-.2-.135a1.563 1.563 0 00-.413-.182 1.552 1.552 0 00-1.548 2.575l.194.193a.251.251 0 010 .354l-.7.7a.249.249 0 01-.353 0l-8.596-8.584a.25.25 0 010-.353L10.612 6a.249.249 0 01.354 0l8.586 8.586a.25.25 0 010 .353l-.6.594a.736.736 0 00.028 1.07.737.737 0 001-.011l.629-.592.006-.007a1.748 1.748 0 000-2.471l-8.589-8.586a1.75 1.75 0 00-2.474 0l-5.086 5.086a1.749 1.749 0 000 2.475l8.586 8.586a1.753 1.753 0 002.474 0l.7-.7a.255.255 0 01.177-.073.25.25 0 01.177.074l.37.369c.116.116.241.221.367.326a5.506 5.506 0 001.8 1.011l4.432 1.477a.251.251 0 00.329-.237V11.8a.253.253 0 00-.1-.2zM7.875 1.923a.75.75 0 000-1.5 7.759 7.759 0 00-7.75 7.75.75.75 0 001.5 0 6.257 6.257 0 016.25-6.25z\"/><path d=\"M4.822 7.9a.736.736 0 00.032-.1A3.036 3.036 0 017.5 5.153a.627.627 0 00.1-.035.736.736 0 00.525-.525.726.726 0 00.021-.276.747.747 0 00-.836-.652A4.531 4.531 0 003.366 7.61a.75.75 0 00.653.837.711.711 0 00.092.006.725.725 0 00.189-.036.736.736 0 00.522-.517zM8.685 9.691a.738.738 0 00.185.709l.364.365a.25.25 0 01.037.307 1.641 1.641 0 001.921 2.417l2.03-.676a.149.149 0 01.152.246l-1.421 1.42a.75.75 0 001.061 1.061l.321-.321a.249.249 0 01.353 0l.321.321a.75.75 0 101.06-1.061l-.363-.364a.25.25 0 01-.037-.307 1.642 1.642 0 00-1.922-2.418l-2.029.676a.149.149 0 01-.153-.035.15.15 0 010-.21l1.421-1.421a.75.75 0 00-1.06-1.06l-.321.321a.252.252 0 01-.354 0l-.32-.321a.741.741 0 00-.709-.185.67.67 0 00-.537.536z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});