define("ember-svg-jar/inlined/recycling-paper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>recycling-paper</title><path d=\"M9 20.5H2.5A.5.5 0 012 20V2.5a.5.5 0 01.5-.5h8.25a.5.5 0 01.5.5v2.25a1.5 1.5 0 001.5 1.5H15a.5.5 0 01.5.5V11a1 1 0 002 0V5.75a1 1 0 00-.293-.707l-4.75-4.75A1 1 0 0011.75 0H2a2 2 0 00-2 2v18.5a2 2 0 002 2h7a1 1 0 000-2z\"/><path d=\"M23.935 13.654a.5.5 0 00-.677-.386 8.853 8.853 0 01-3.72.417 6.656 6.656 0 00-3.9.643A3.793 3.793 0 0013.5 17.05a2.791 2.791 0 00.382 1.651.5.5 0 01-.107.619 20.461 20.461 0 00-3.247 3.509.75.75 0 001.24.844 24.289 24.289 0 016.843-6.033.75.75 0 01.767 1.289 23.261 23.261 0 00-3.534 2.584.5.5 0 00-.026.724 2.279 2.279 0 00.6.441 2.514 2.514 0 001.148.257 5.417 5.417 0 002.694-.886c2.001-1.198 4.14-5.484 3.675-8.395z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});