define("ember-svg-jar/inlined/video-game-potion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-potion</title><path d=\"M8.87 15a.5.5 0 00-.5.55 5.5 5.5 0 0010.945 0 .5.5 0 00-.5-.549z\"/><circle cx=\"12.346\" cy=\"10.5\" r=\"1\"/><circle cx=\"13.346\" cy=\"2\" r=\"1\"/><circle cx=\"1.846\" cy=\"13\" r=\"1\"/><circle cx=\"15.846\" cy=\"12\" r=\"1\"/><path d=\"M23.25 1.5H23a.5.5 0 01-.5-.5V.75a.75.75 0 00-1.5 0V1a.5.5 0 01-.5.5h-.25a.75.75 0 000 1.5h.25a.5.5 0 01.5.5v.25a.75.75 0 001.5 0V3.5A.5.5 0 0123 3h.25a.75.75 0 000-1.5zM13.846 5a9.457 9.457 0 00-3.239.566.5.5 0 01-.561-.157L8.963 4.056A1.5 1.5 0 007.876 3.5a1.459 1.459 0 00-1.143.437L3.281 7.388A1.5 1.5 0 003.4 9.62l1.355 1.08a.5.5 0 01.157.561A9.5 9.5 0 1013.846 5zm0 17a7.5 7.5 0 01-6.952-10.319 2.014 2.014 0 00-.6-2.315l-.764-.61a.5.5 0 01-.041-.744l1.868-1.873a.5.5 0 01.382-.145.493.493 0 01.361.186l.611.763a2.015 2.015 0 002.314.605A7.5 7.5 0 1113.846 22zM2.8 5.754l2.3-2.295a.5.5 0 00.084-.6l-.942-1.69A1 1 0 002.661.947L.293 3.315A1 1 0 00.514 4.9l1.694.941a.5.5 0 00.592-.087z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});