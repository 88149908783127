define("ember-svg-jar/inlined/expand-horizontal-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>expand-horizontal-3</title><path d=\"M10.25 7.5v9a1.25 1.25 0 002.5 0v-9a1.25 1.25 0 10-2.5 0zM23.707 11.292l-3-3A1 1 0 0019 9v1.5a.25.25 0 01-.25.25H16a1.25 1.25 0 000 2.5h2.75a.25.25 0 01.25.25V15a1 1 0 00.617.924A.987.987 0 0020 16a1 1 0 00.707-.293l3-3a1 1 0 000-1.415zM3.293 15.706A1 1 0 004 16a.987.987 0 00.383-.076A1 1 0 005 15v-1.5a.25.25 0 01.25-.25H7a1.25 1.25 0 100-2.5H5.25A.25.25 0 015 10.5V9a1 1 0 00-1.707-.707l-3 3a1 1 0 000 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});