define("ember-svg-jar/inlined/photography-equipment-film", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>photography-equipment-film</title><path d=\"M22.5 4H16a.5.5 0 00-.5.5v18a.5.5 0 00.5.5h.5a1.5 1.5 0 001.5-1.5v-3a5.533 5.533 0 014.658-5.436A1.573 1.573 0 0024 11.5v-6A1.5 1.5 0 0022.5 4zM13.75 7a.25.25 0 00.25-.25V5.5A1.5 1.5 0 0012.5 4h-11A1.5 1.5 0 000 5.5v1.25A.25.25 0 00.25 7zM4.75 8.5a.25.25 0 00-.25.25v15a.25.25 0 00.25.25h7.75a1.5 1.5 0 001.5-1.5V8.75a.25.25 0 00-.25-.25zM3 8.75a.25.25 0 00-.25-.25H.25a.25.25 0 00-.25.25V22.5A1.5 1.5 0 001.5 24h1.25a.25.25 0 00.25-.25zM4.5 2.5h5A.5.5 0 0010 2v-.5A1.5 1.5 0 008.5 0h-3A1.5 1.5 0 004 1.5V2a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});