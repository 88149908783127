define("ember-svg-jar/inlined/single-man-actions-coding.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-coding</title><path d=\"M.619 18.062H2.41a.246.246 0 01.246.227l.444 5.257a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h.562a.245.245 0 00.18-.078.25.25 0 00.068-.184 3.602 3.602 0 01-.008-.233 7.866 7.866 0 011.33-4.384.25.25 0 00.037-.183A5.441 5.441 0 00.124 14.1v3.464a.494.494 0 00.495.498z\"/><path d=\"M1.361 4.206A4.206 4.206 0 105.567 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM17.443 24a6.433 6.433 0 10-6.433-6.433A6.441 6.441 0 0017.443 24zm2.257-3.929a.742.742 0 11-1.05-1.049l1.28-1.28a.247.247 0 000-.35l-1.28-1.28a.742.742 0 111.05-1.049l1.979 1.979a.742.742 0 010 1.05zm-4.513-5.008a.742.742 0 111.049 1.049l-1.279 1.28a.247.247 0 000 .35l1.279 1.28a.742.742 0 11-1.049 1.049l-1.98-1.979a.742.742 0 010-1.05z\"/><path d=\"M.619 18.062H2.41a.246.246 0 01.246.227l.444 5.257a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h.562a.245.245 0 00.18-.078.25.25 0 00.068-.184 3.602 3.602 0 01-.008-.233 7.866 7.866 0 011.33-4.384.25.25 0 00.037-.183A5.441 5.441 0 00.124 14.1v3.464a.494.494 0 00.495.498z\"/><path d=\"M1.361 4.206A4.206 4.206 0 105.567 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM17.443 24a6.433 6.433 0 10-6.433-6.433A6.441 6.441 0 0017.443 24zm2.257-3.929a.742.742 0 11-1.05-1.049l1.28-1.28a.247.247 0 000-.35l-1.28-1.28a.742.742 0 111.05-1.049l1.979 1.979a.742.742 0 010 1.05zm-4.513-5.008a.742.742 0 111.049 1.049l-1.279 1.28a.247.247 0 000 .35l1.279 1.28a.742.742 0 11-1.049 1.049l-1.98-1.979a.742.742 0 010-1.05z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});