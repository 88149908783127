define("ember-svg-jar/inlined/keyboard-power-off-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-power-off-alternate</title><path d=\"M19.174 0H4.826A4.832 4.832 0 000 4.822V19.17A4.832 4.832 0 004.826 24h14.348A4.832 4.832 0 0024 19.17V4.822A4.832 4.832 0 0019.174 0zM22 19.17A2.83 2.83 0 0119.174 22H4.826A2.829 2.829 0 012 19.17V4.822A2.828 2.828 0 014.826 2h14.348A2.829 2.829 0 0122 4.822z\"/><path d=\"M13.122 3.57a1 1 0 00-.262 1.982 6.5 6.5 0 11-7.235 7.72 1 1 0 10-1.961.391A8.5 8.5 0 1013.122 3.57z\"/><path d=\"M4.809 9.458A.513.513 0 005 9.5a.5.5 0 00.353-.147l1.117-1.12a.251.251 0 01.354 0l4.469 4.47a1 1 0 001.414-1.414l-4.469-4.47a.249.249 0 010-.353l1.116-1.117A.5.5 0 009 4.5H5a.5.5 0 00-.5.5v4a.5.5 0 00.309.458z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});