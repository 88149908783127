define("ember-svg-jar/inlined/read-music-instrument", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-music-instrument</title><path d=\"M20.75 0H4.25a2 2 0 00-2 2v19a3 3 0 003 3h13.5a2 2 0 002-2v-1.584a.5.5 0 01.333-.471A1 1 0 0021.75 19V1a1 1 0 00-1-1zM6.592 10.027l4.5-1.5a.5.5 0 11.316.949l-.481.16a.26.26 0 00-.177.246V11.5a1 1 0 002 0l.379-4.939a2.5 2.5 0 012.138-2.283l1.913-.273a.5.5 0 01.14.995l-1.22.173a1 1 0 00-.854.99V11.5a4 4 0 01-8 0v-.279a.259.259 0 00-.342-.246.5.5 0 11-.316-.949zM18.75 21.5a.5.5 0 01-.5.5h-13a1 1 0 010-2h13a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});