define("ember-svg-jar/inlined/earth-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earth-warning</title><path d=\"M8.393 17.7A4.5 4.5 0 005.5 9.75H2.265a9.982 9.982 0 0115.375-6h-3.39a2.75 2.75 0 000 5.5 2.565 2.565 0 011.83.788.5.5 0 00.523.123A2.717 2.717 0 0117.5 10a2.938 2.938 0 012.609 1.573l2.313 4.406a.5.5 0 00.914-.068A11.843 11.843 0 0024 12 12 12 0 107.341 23.059l.159.078v-.02a11.751 11.751 0 001.441.478.5.5 0 00.62-.564 2.924 2.924 0 01-.009-.886.5.5 0 00-.353-.551 10.059 10.059 0 01-1.7-.676v-1.3a2.492 2.492 0 01.894-1.918z\"/><path d=\"M18.781 12.271a1.45 1.45 0 00-2.562 0l-5.055 9.634a1.433 1.433 0 00.048 1.409 1.457 1.457 0 001.232.686h10.111a1.459 1.459 0 001.234-.687 1.434 1.434 0 00.047-1.408zM16.749 15.5a.75.75 0 111.5 0v3a.75.75 0 01-1.5 0zm.75 6.75a1 1 0 111-1 1 1 0 01-.999 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});