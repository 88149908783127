define("ember-svg-jar/inlined/self-payment-touch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>self-payment-touch</title><path d=\"M16.8 5.635a.721.721 0 000-1.441h-.471a.25.25 0 01-.25-.25v-.111a.721.721 0 00-1.441 0v.123a.249.249 0 01-.226.248A1.289 1.289 0 0013.2 5.635c0 .855.855 1.43 1.759 2.038.163.11.371.25.564.393a.25.25 0 01-.149.451h-1.453a.721.721 0 000 1.441h.471a.25.25 0 01.25.25v.111a.721.721 0 001.441 0V10.2a.252.252 0 01.222-.249 1.317 1.317 0 001.219-1.429c0-.854-.854-1.43-1.759-2.039-.164-.11-.374-.252-.569-.389a.25.25 0 01.145-.454zM9.049 18.188a2.894 2.894 0 013.075-.391.252.252 0 00.241-.016.248.248 0 00.115-.211v-1.237a.25.25 0 00-.25-.25H1.912a.963.963 0 00-.961.961v3.843a.962.962 0 00.961.961H8a.25.25 0 00.231-.345 2.88 2.88 0 01.818-3.315z\"/><path d=\"M23.529 22.327a3.842 3.842 0 00-3.843-3.843h-2.632a.25.25 0 01-.25-.25v-4.073a1.442 1.442 0 10-2.883 0v6.854a.25.25 0 01-.438.165L12 19.487a1.441 1.441 0 00-2.219 1.84l.008.009 2.138 2.357a.253.253 0 00.186.082h11.166a.25.25 0 00.25-.25z\"/><rect x=\"4.794\" y=\"3.593\" width=\"2.882\" height=\"2.882\" rx=\".48\" ry=\".48\"/><rect x=\"8.637\" y=\"3.593\" width=\"2.882\" height=\"2.882\" rx=\".48\" ry=\".48\"/><rect x=\"4.794\" y=\"7.437\" width=\"2.882\" height=\"2.882\" rx=\".48\" ry=\".48\"/><rect x=\"8.637\" y=\"7.437\" width=\"2.882\" height=\"2.882\" rx=\".48\" ry=\".48\"/><path d=\"M20.57 1.84A2 2 0 0018.577 0H3.423A2 2 0 001.43 1.84l-.841 10.5a2 2 0 001.994 2.16H11.5a1 1 0 001-1 1 1 0 00-1-1H2.854a.249.249 0 01-.249-.27l.8-10A.251.251 0 013.654 2h14.691a.251.251 0 01.25.23l.8 10a.249.249 0 01-.249.27H19a1 1 0 00-1 1 1 1 0 001 1h.417a2 2 0 001.994-2.157z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});