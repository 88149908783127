define("ember-svg-jar/inlined/mortar-pestle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mortar-pestle</title><path d=\"M24 10.25a1 1 0 00-1-1H1a1 1 0 000 2h.92a.249.249 0 01.249.244 9.776 9.776 0 005.718 8.69.25.25 0 01.011.45 9 9 0 00-2.832 2.3.5.5 0 00.387.816h13.094a.5.5 0 00.387-.816 8.992 8.992 0 00-2.833-2.3.25.25 0 01.011-.45 9.776 9.776 0 005.719-8.69.249.249 0 01.249-.244H23a1 1 0 001-1zM14.005 6.872a.5.5 0 00.327.878h4.124a.5.5 0 00.378-.173l4.395-5.071A1.381 1.381 0 0021.286.562z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});