define("ember-svg-jar/inlined/crafts-sewing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crafts-sewing</title><path d=\"M6.554 21.556a4.345 4.345 0 01-2.409-.741 5.609 5.609 0 01-2.361-4.675 6.32 6.32 0 01.257-1.791.25.25 0 00-.066-.25A6.862 6.862 0 010 9.182a7.026 7.026 0 012.839-5.763 5.3 5.3 0 015.97-.107 1 1 0 01-1.083 1.682 3.283 3.283 0 00-3.749.069A5.01 5.01 0 002 9.182a5.1 5.1 0 00.883 2.9.248.248 0 00.189.107.252.252 0 00.2-.083 5.184 5.184 0 01.577-.552 4.47 4.47 0 014.673-.692l.884.381a.248.248 0 00.263-.042l6.01-5.276a4.684 4.684 0 015.521-.572 5.987 5.987 0 012.8 5.221 6.885 6.885 0 01-1.322 4.1.25.25 0 00-.041.218 4.613 4.613 0 01.174 1.247 4.257 4.257 0 01-3.973 4.486 1 1 0 010-2 2.117 2.117 0 001.908-1.852.25.25 0 00-.342-.281 4.908 4.908 0 01-3.908-.011l-.709-.3a.251.251 0 00-.243.025l-6.475 4.537a4.362 4.362 0 01-2.515.813zm-2.416-6.139a.252.252 0 00-.325.21 4.588 4.588 0 00-.029.513 3.594 3.594 0 001.466 3.008 2.3 2.3 0 002.669-.042l5.217-3.664a.246.246 0 00.105-.23.249.249 0 00-.149-.2l-3.025-1.3a.25.25 0 00-.264.042l-.652.572a5.163 5.163 0 01-5.013 1.091zm13.937-1a.251.251 0 00.122.454 2.986 2.986 0 001.678-.349.171.171 0 00.015-.292 1.369 1.369 0 00-1.585.026zm-6.192-2.5a.251.251 0 00.066.418l3.351 1.446a.249.249 0 00.243-.025l1.616-1.135a3.347 3.347 0 013.837-.058c.07.046.139.095.2.146a.25.25 0 00.381-.1A5.05 5.05 0 0022 10.574a4.039 4.039 0 00-1.806-3.494A2.675 2.675 0 0017 7.428zm-6.9 1.3a.25.25 0 00.1.426 3.1 3.1 0 002.544-.61.251.251 0 00-.068-.429 2.414 2.414 0 00-2.449.5l-.125.111z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});