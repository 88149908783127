define("ember-svg-jar/inlined/road-sign-66", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-66</title><circle cx=\"8\" cy=\"13\" r=\"1.75\"/><circle cx=\"16\" cy=\"13\" r=\"1.75\"/><path d=\"M21.319 1.914A22.624 22.624 0 0012 0a22.624 22.624 0 00-9.319 1.914A2.047 2.047 0 001.5 3.774v7.637a12.862 12.862 0 008.147 11.935l1.068.414a3.56 3.56 0 002.57 0l1.068-.414A12.862 12.862 0 0022.5 11.411V3.774a2.047 2.047 0 00-1.181-1.86zM8 16.25A3.254 3.254 0 014.75 13v-2.5A3.754 3.754 0 018.5 6.75H9a.75.75 0 010 1.5h-.5a2.239 2.239 0 00-2.088 1.44.25.25 0 00.332.319A3.188 3.188 0 018 9.75a3.25 3.25 0 010 6.5zm8 0A3.254 3.254 0 0112.75 13v-2.5a3.754 3.754 0 013.75-3.75h.5a.75.75 0 010 1.5h-.5a2.239 2.239 0 00-2.088 1.44.25.25 0 00.332.319A3.188 3.188 0 0116 9.75a3.25 3.25 0 010 6.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});