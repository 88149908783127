define("ember-svg-jar/inlined/picture-flower-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>picture-flower-1</title><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2zm-3.5 20h-17A1.5 1.5 0 012 20.5v-2.25a.25.25 0 01.25-.25h19.5a.25.25 0 01.25.25v2.25a1.5 1.5 0 01-1.5 1.5zM7.7 6.312a1.492 1.492 0 01-.1-1.495c.435-.75 1.619-.87 2.8-.33a.247.247 0 00.229-.011.25.25 0 00.124-.193c.123-1.3.821-2.262 1.687-2.262s1.564.965 1.688 2.262a.249.249 0 00.353.2 3.356 3.356 0 011.459-.33 1.49 1.49 0 011.344.661 1.491 1.491 0 01-.1 1.5 3.436 3.436 0 01-1.017 1.1.25.25 0 000 .407 3.439 3.439 0 011.017 1.1 1.489 1.489 0 01.1 1.494 1.49 1.49 0 01-1.344.661 3.384 3.384 0 01-1.459-.331.25.25 0 00-.353.2c-.124 1.3-.821 2.262-1.688 2.262s-1.564-.965-1.687-2.262a.25.25 0 00-.353-.2c-1.186.541-2.37.421-2.8-.33a1.49 1.49 0 01.1-1.494 3.458 3.458 0 011.019-1.1.25.25 0 000-.407A3.455 3.455 0 017.7 6.312zm-1.773 7.5a.35.35 0 01.2-.225 3.791 3.791 0 014.786 1.647v.008a3.791 3.791 0 01-4.934-1.134.349.349 0 01-.054-.298z\"/><circle cx=\"12.443\" cy=\"7.613\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});