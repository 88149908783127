define("ember-svg-jar/inlined/single-neutral-actions-wifi.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-wifi</title><path d=\"M10.447 14.451a11.376 11.376 0 014.513-2.174.25.25 0 00.07-.46 23.875 23.875 0 00-3.53-1.489l-.628-.23a.6.6 0 01-.138-.311 2.573 2.573 0 01.058-1.3 5.618 5.618 0 001.466-4.314C12.256 1.756 10.677 0 8.5 0S4.744 1.756 4.744 4.174A5.605 5.605 0 006.2 8.471a2.453 2.453 0 01.073 1.316.615.615 0 01-.135.309l-.631.232c-2.447.9-4.215 1.55-4.754 2.626A9.173 9.173 0 000 16.5a.5.5 0 00.5.5h8.777a.249.249 0 00.187-.085.246.246 0 00.061-.2 2.49 2.49 0 01.922-2.264z\"/><circle cx=\"17.5\" cy=\"22.25\" r=\"1.75\"/><path d=\"M11.379 15.627a1 1 0 101.242 1.567 7.826 7.826 0 019.758 0 1 1 0 101.242-1.567 9.817 9.817 0 00-12.242 0z\"/><path d=\"M13.853 18.451a1 1 0 001.293 1.527 3.583 3.583 0 014.707 0 1 1 0 001.293-1.527 5.624 5.624 0 00-7.293 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});