define("ember-svg-jar/inlined/paralympics-football", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paralympics-football</title><circle cx=\"11.658\" cy=\"2.846\" r=\"2.5\"/><circle cx=\"11.158\" cy=\"21.126\" r=\"2.28\"/><path d=\"M15.207 17.847a1.42 1.42 0 001.849.983l.362-.128a1.168 1.168 0 00.74-1.392 4.511 4.511 0 00-3.337-3.561l-.907-.226a.5.5 0 01-.373-.41l-.41-2.691a.5.5 0 01.495-.575h1.032a4.478 4.478 0 004.178-2.828l.246-.614A1.5 1.5 0 1016.3 5.29l-.247.615a1.492 1.492 0 01-1.392.942h-7.5a4.525 4.525 0 00-3.6 1.8l-.3.3a1.5 1.5 0 002.4 1.8c.421-.422.733-.9 1.5-.9h2.376a.5.5 0 01.494.423l.46 2.94a.5.5 0 01-.35.556l-1.967.591a1.507 1.507 0 01-1.2-.151l-1.364-.818a1.5 1.5 0 10-1.546 2.572l1.363.819a4.513 4.513 0 003.609.451l2.546-.764a3 3 0 011.59-.037l.921.231a1.5 1.5 0 011.114 1.187zM20.368 20.621a1 1 0 00-1.271.619.622.622 0 01-.452.4.61.61 0 01-.745-.44l-.235-.91a1 1 0 00-1.936.5l.236.911a2.59 2.59 0 001.2 1.594 2.64 2.64 0 003.816-1.4 1 1 0 00-.613-1.274z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});