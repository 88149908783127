define("ember-svg-jar/inlined/music-clef-sheet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-clef-sheet</title><path d=\"M1 3.5a1 1 0 000 2h7.75A.25.25 0 009 5.25v-1.5a.25.25 0 00-.25-.25zM23 5.5a1 1 0 000-2h-4.8a.25.25 0 00-.248.218 5.7 5.7 0 01-.377 1.438.253.253 0 00.024.234.25.25 0 00.208.11zM5.771 8.921a.25.25 0 00-.182-.421H1a1 1 0 000 2h3.525a.251.251 0 00.222-.134 6.546 6.546 0 011.024-1.445zM14 9.257a.252.252 0 00.143.226 5 5 0 011.352.946.254.254 0 00.175.071H23a1 1 0 000-2h-8.446a.253.253 0 00-.15.05l-.3.23a.254.254 0 00-.1.2zM1 13.5a1 1 0 000 2h3a.25.25 0 00.241-.317 6.568 6.568 0 01-.234-1.445.249.249 0 00-.249-.238zM16.879 15.2a.25.25 0 00.245.3H23a1 1 0 000-2h-5.756a.247.247 0 00-.181.078.251.251 0 00-.069.184c0 .079.006.158.006.238a6.008 6.008 0 01-.121 1.2zM14 20.25a.25.25 0 00.25.25H23a1 1 0 000-2h-7.943a.248.248 0 00-.162.06 6.235 6.235 0 01-.775.564.25.25 0 00-.12.213zM5.273 18.864a.25.25 0 00-.223-.364H1a1 1 0 000 2h3.75a.25.25 0 00.25-.25V20a2.48 2.48 0 01.273-1.136z\"/><path d=\"M12.7 10.57a.249.249 0 01-.2-.244V8.232a.254.254 0 01.1-.2l2.388-1.8A4.19 4.19 0 0016.5 3a3 3 0 00-6 0v3.989a.251.251 0 01-.1.2L7.335 9.5l-.01.008a5.086 5.086 0 002.941 8.979.25.25 0 01.234.25V21a1 1 0 01-2 0v-1a1 1 0 00-2 0v1a3 3 0 006 0v-2.584a.249.249 0 01.158-.232A4.509 4.509 0 0015.5 14a3.506 3.506 0 00-2.8-3.43zM12.5 3a1 1 0 012 0 2.182 2.182 0 01-.755 1.663L12.9 5.3a.251.251 0 01-.4-.2zm-2 13.23a.251.251 0 01-.28.249 3.088 3.088 0 01-1.647-5.406L10.1 9.92a.251.251 0 01.4.2v.564a.251.251 0 01-.133.221A3.5 3.5 0 008.5 14a1 1 0 001.671.741.27.27 0 01.184-.071.145.145 0 01.145.145zm2.444-.66a.25.25 0 01-.444-.158V13a.25.25 0 01.4-.2 1.5 1.5 0 01.6 1.2 2.487 2.487 0 01-.556 1.57z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});