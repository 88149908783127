define("ember-svg-jar/inlined/recycling-bottle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>recycling-bottle</title><path d=\"M5 3.5h4a.5.5 0 00.5-.5V1.5A1.5 1.5 0 008 0H6a1.5 1.5 0 00-1.5 1.5V3a.5.5 0 00.5.5zM1.162 17.25h6.357a.251.251 0 00.228-.352 2 2 0 011.284-2.748l1.7-.478a.531.531 0 00.383-.444.5.5 0 00-.5-.564H.775a.5.5 0 00-.364.157A1.438 1.438 0 000 13.848v2.241a1.161 1.161 0 001.162 1.161zM8.607 23.544a3.249 3.249 0 01-1.073-4.089.5.5 0 00-.456-.7H.79a.5.5 0 00-.359.152A1.449 1.449 0 000 19.95v1.55A2.5 2.5 0 002.5 24h5.965a.25.25 0 00.142-.456zM.758 11.164h11.064a.5.5 0 00.432-.248c.977-1.671 1.161-2.075 1.423-2.411a.5.5 0 00.078-.471A4.506 4.506 0 009.5 5h-5A4.5 4.5 0 000 9.5v.5a1.431 1.431 0 00.387 1 .5.5 0 00.371.164zM23.735 19.5l-2.081-3.626a1 1 0 10-1.735 1l1.652 2.877a.5.5 0 01-.434.749h-1.26a.5.5 0 01-.5-.5v-.5a.5.5 0 00-.854-.354l-2 2a.5.5 0 000 .707l2 2a.5.5 0 00.854-.353V23a.5.5 0 01.5-.5H22a2 2 0 001.734-3zM14.764 20.5h-3.441a.5.5 0 01-.433-.75l.781-1.354a.5.5 0 01.678-.187l.466.262a.5.5 0 00.726-.572l-.765-2.722a.5.5 0 00-.617-.347l-2.723.77a.5.5 0 00-.109.918l.406.228a.5.5 0 01.188.685L8.725 19.5a2 2 0 001.733 3h4.306a1 1 0 000-2zM16.675 11.254l.616 1.073a.5.5 0 01-.179.678l-.467.278a.5.5 0 00.131.914l2.741.7a.5.5 0 00.608-.361l.7-2.741a.5.5 0 00-.739-.553l-.393.233a.5.5 0 01-.693-.182l-1.022-1.788A2 2 0 0016.244 8.5a2.007 2.007 0 00-1.733 1l-2.028 3.517a1 1 0 101.733 1l1.594-2.764a.5.5 0 01.866 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});