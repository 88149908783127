define("ember-svg-jar/inlined/study-owl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>study-owl</title><circle cx=\"9.003\" cy=\"10.497\" r=\"1\"/><circle cx=\"15.003\" cy=\"10.497\" r=\"1\"/><path d=\"M19.007 11.568a.5.5 0 01-.293-.529 3.75 3.75 0 00-1.285-3.4.5.5 0 01-.176-.381V5.85a.25.25 0 00-.342-.232l-4.006 1.575a2.49 2.49 0 01-1.749.03l-4.07-1.442a.249.249 0 00-.333.235v1.243a.5.5 0 01-.176.381 3.743 3.743 0 00-1.324 2.86 3.687 3.687 0 00.039.539.5.5 0 01-.294.53A3.755 3.755 0 002.75 15v2.25a5.76 5.76 0 003.988 5.474.25.25 0 00.284-.378A5.964 5.964 0 016 19a.5.5 0 011 0 5 5 0 0010 0 .5.5 0 011 0 5.964 5.964 0 01-1.022 3.346.25.25 0 00.284.378 5.76 5.76 0 003.988-5.474V15a3.755 3.755 0 00-2.243-3.432zM9 12.747a2.25 2.25 0 112.25-2.25A2.253 2.253 0 019 12.747zm4.172 1.334l-.634.929a.677.677 0 01-1.076 0l-.633-.928a.25.25 0 01.078-.356 3.758 3.758 0 00.9-.75.25.25 0 01.376 0 3.778 3.778 0 00.9.75.251.251 0 01.079.356zM15 12.747a2.25 2.25 0 112.25-2.25 2.253 2.253 0 01-2.25 2.25z\"/><path d=\"M4.75 7.434a1 1 0 001-1V4.6a.247.247 0 01.106-.2.25.25 0 01.228-.032l5.405 1.912a1.494 1.494 0 001.05-.017l6.052-2.381a.75.75 0 000-1.4L12.53.105a1.5 1.5 0 00-1.05-.017l-7.4 2.625a.5.5 0 00-.333.471v3.25a1 1 0 001.003 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});