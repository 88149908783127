define("ember-svg-jar/inlined/crafts-bottle-art-plant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crafts-bottle-art-plant</title><path d=\"M22.5 8.25H20v-1a3 3 0 00-3-3H3a3 3 0 00-3 3v9.5a3 3 0 003 3h14a3 3 0 003-3v-1h2.5a1.5 1.5 0 001.5-1.5v-4.5a1.5 1.5 0 00-1.5-1.5zm-.5 5a.5.5 0 01-.5.5H20a2 2 0 00-2 2v1a1 1 0 01-1 1H3a1 1 0 01-1-1v-9.5a1 1 0 011-1h14a1 1 0 011 1v1a2 2 0 002 2h1.5a.5.5 0 01.5.5z\"/><path d=\"M14 14.75h-2.25a.5.5 0 01-.5-.5v-.533a.5.5 0 01.433-.5A3.25 3.25 0 0014.5 10v-.25a.5.5 0 00-.5-.5h-.5a3 3 0 00-2.8 1.93c-.037.1-.226.1-.247-.021A3.5 3.5 0 007 8.25a.5.5 0 00-.5.5V9a3.25 3.25 0 002.817 3.221.5.5 0 01.433.5v1.529a.5.5 0 01-.5.5H7a.75.75 0 000 1.5h7a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});