define("ember-svg-jar/inlined/single-woman-shopping-bag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-shopping-bag</title><path d=\"M23.972 21.229l-.832-5.4A2.151 2.151 0 0021.224 14a.25.25 0 01-.224-.25v-.263a3 3 0 00-6 0v.263a.25.25 0 01-.224.249 2.151 2.151 0 00-1.916 1.824l-.833 5.408a2.454 2.454 0 00-.027.363 2.4 2.4 0 002.394 2.393h7.212A2.4 2.4 0 0024 21.594a2.371 2.371 0 00-.028-.365zM18 12.487a1 1 0 011 1v.25a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-.25a1 1 0 011-1zM11.982 17.853l-1.8-6.286a4.263 4.263 0 00-1.238-2.125.5.5 0 00-.764.1L6.425 12.3a.5.5 0 01-.846 0l-1.74-2.775a.5.5 0 00-.763-.1 4.226 4.226 0 00-1.254 2.13l-1.8 6.3a.5.5 0 00.482.632h2.214a.375.375 0 01.37.306L4 23.588a.5.5 0 00.489.4h3a.5.5 0 00.49-.4l.911-4.8a.376.376 0 01.37-.306h2.24a.5.5 0 00.482-.632zM5.979 8.515a4.25 4.25 0 10-4.25-4.25 4.255 4.255 0 004.25 4.25zm0-2a2.248 2.248 0 01-2.16-1.64.25.25 0 01.16-.3A4.68 4.68 0 005.8 3.432a.25.25 0 01.357 0 4.693 4.693 0 001.821 1.14.249.249 0 01.159.3 2.246 2.246 0 01-2.158 1.643z\"/><path d=\"M23.972 21.229l-.832-5.4A2.151 2.151 0 0021.224 14a.25.25 0 01-.224-.25v-.263a3 3 0 00-6 0v.263a.25.25 0 01-.224.249 2.151 2.151 0 00-1.916 1.824l-.833 5.408a2.454 2.454 0 00-.027.363 2.4 2.4 0 002.394 2.393h7.212A2.4 2.4 0 0024 21.594a2.371 2.371 0 00-.028-.365zM18 12.487a1 1 0 011 1v.25a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-.25a1 1 0 011-1zM11.982 17.853l-1.8-6.286a4.263 4.263 0 00-1.238-2.125.5.5 0 00-.764.1L6.425 12.3a.5.5 0 01-.846 0l-1.74-2.775a.5.5 0 00-.763-.1 4.226 4.226 0 00-1.254 2.13l-1.8 6.3a.5.5 0 00.482.632h2.214a.375.375 0 01.37.306L4 23.588a.5.5 0 00.489.4h3a.5.5 0 00.49-.4l.911-4.8a.376.376 0 01.37-.306h2.24a.5.5 0 00.482-.632zM5.979 8.515a4.25 4.25 0 10-4.25-4.25 4.255 4.255 0 004.25 4.25zm0-2a2.248 2.248 0 01-2.16-1.64.25.25 0 01.16-.3A4.68 4.68 0 005.8 3.432a.25.25 0 01.357 0 4.693 4.693 0 001.821 1.14.249.249 0 01.159.3 2.246 2.246 0 01-2.158 1.643z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});