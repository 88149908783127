define("ember-svg-jar/inlined/design-tool-magnet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-tool-magnet</title><path d=\"M23.411 14.027a2 2 0 000-2.828L22 9.784a2 2 0 00-2.827 0l-2.887 2.887-3.594 3.594a3.5 3.5 0 01-4.951-4.949l3.589-3.594 2.888-2.888a2 2 0 000-2.828L12.8.591a2 2 0 00-2.827 0L7.089 3.479l-4.3 4.3a9.5 9.5 0 0013.433 13.436l4.3-4.3zM12.628 3.243a.251.251 0 010 .354L10.8 5.423a.25.25 0 01-.354 0l-1.06-1.06a.252.252 0 010-.354l1.827-1.826a.25.25 0 01.353 0zm7.365 11.373a.252.252 0 01-.354 0l-1.06-1.061a.249.249 0 010-.353l1.826-1.827a.251.251 0 01.354 0l1.061 1.061a.251.251 0 010 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});