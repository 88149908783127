define("ember-svg-jar/inlined/farming-barn-sun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>farming-barn-sun</title><path d=\"M10.759 13.349A1 1 0 0010 13H4a1 1 0 00-.759.349l-3 3.5A1 1 0 000 17.5V23a1 1 0 001 1h12a1 1 0 001-1v-5.5a1 1 0 00-.241-.651zM12 21.5a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-3.445a.5.5 0 01.12-.325l1.69-1.972a.251.251 0 01.38 0l1.69 1.972a.5.5 0 01.12.325zM16.5 20a1 1 0 00-1 1v2.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V21a1 1 0 00-1-1zM19.5 20a1 1 0 00-1 1v2.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V21a1 1 0 00-1-1zM22.5 20a1 1 0 00-1 1v2.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V21a1 1 0 00-1-1zM17 3a1 1 0 001-1V1a1 1 0 00-2 0v1a1 1 0 001 1zM22.3 4.611l.71-.711a1 1 0 10-1.41-1.41l-.707.707A1 1 0 0022.3 4.611zM11.7 4.611A1 1 0 0013.111 3.2l-.711-.71a1 1 0 10-1.41 1.41z\"/><path d=\"M1 10h22a1 1 0 000-2h-.9a.5.5 0 01-.485-.381 4.751 4.751 0 00-9.228 0A.5.5 0 0111.9 8H1a1 1 0 000 2zm13.716-2.778a2.746 2.746 0 014.568 0 .5.5 0 01-.415.778h-3.738a.5.5 0 01-.415-.778zM23 12.5h-7.5a1 1 0 000 2H23a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});