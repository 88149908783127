define("ember-svg-jar/inlined/color-brush-paint-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-brush-paint-1</title><path d=\"M16 16.71a.5.5 0 00-.331.273 3.506 3.506 0 01-2 1.818 1 1 0 00-.667.942V21.5a.5.5 0 01-.5.5H2.5a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5h5.756a1 1 0 00.943-.667 3.508 3.508 0 012.762-2.29.5.5 0 00.419-.431 7.9 7.9 0 01.168-.97.5.5 0 00-.529-.621 5.517 5.517 0 00-4.283 2.729.5.5 0 01-.436.25H2a2 2 0 00-2 2v7a2 2 0 002 2h11a2 2 0 002-2v-1.3a.5.5 0 01.25-.434 5.511 5.511 0 002.684-3.922.25.25 0 00-.371-.255A6.013 6.013 0 0116 16.71zM23.807 2A1.317 1.317 0 0021.569.614l-4.48 6.655a.249.249 0 00.158.384 4.023 4.023 0 012.192 1.24.25.25 0 00.4-.044z\"/><path d=\"M17.022 8.9a2.791 2.791 0 00-3.293 2.184c-.066.328-.1.633-.137.914-.1.842-.154 1.1-.676 1.364a1.166 1.166 0 00.292 2.178 5.766 5.766 0 001.176.123 5.228 5.228 0 002.885-.852 4.428 4.428 0 001.939-2.618A2.8 2.8 0 0017.022 8.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});