define("ember-svg-jar/inlined/button-loop-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-loop-1</title><path d=\"M23.716 11.354A5.125 5.125 0 0020.474 7.1a5.481 5.481 0 00-5.574.86l-2.74 2.278a.248.248 0 01-.32 0L9.123 7.974a5.428 5.428 0 00-5.785-.755A5.218 5.218 0 00.25 11.9v.1a6.284 6.284 0 00.034.644 5.125 5.125 0 003.242 4.25 5.523 5.523 0 002.084.406 5.449 5.449 0 003.5-1.263l2.735-2.279a.251.251 0 01.32 0l2.735 2.28a5.478 5.478 0 005.579.855 5.125 5.125 0 003.242-4.253 6.181 6.181 0 00.029-.64 6.289 6.289 0 00-.034-.646zM7.5 14.118a2.987 2.987 0 01-3.039.459 2.632 2.632 0 01-1.695-2.2 4.038 4.038 0 01-.02-.44A2.7 2.7 0 014.38 9.492a3.1 3.1 0 011.279-.278 2.868 2.868 0 011.863.68l2.3 1.913a.248.248 0 01.09.192.251.251 0 01-.09.192zm13.726-1.74a2.632 2.632 0 01-1.7 2.2 2.987 2.987 0 01-3.039-.459l-2.313-1.927a.251.251 0 01-.09-.192.248.248 0 01.09-.192L16.5 9.879a2.991 2.991 0 013.039-.459 2.632 2.632 0 011.7 2.2 3.7 3.7 0 01.02.382 3.613 3.613 0 01-.029.376z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});