define("ember-svg-jar/inlined/circus-tent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>circus-tent</title><path d=\"M12.248 4.781l3.672-2.1a.5.5 0 00-.033-.885L12.215.049A.5.5 0 0011.5.5v3.846a.5.5 0 00.748.435zM6.246 10.5h11.509a.25.25 0 00.161-.441l-5.593-4.733a.5.5 0 00-.646 0l-5.593 4.733a.25.25 0 00.162.441zM22.853 22.478c-1.3-2.116-3.45-6.5-3.8-10.251A.25.25 0 0018.8 12H5.2a.25.25 0 00-.249.227c-.353 3.751-2.506 8.136-3.8 10.251A1 1 0 002 24h5.2a.5.5 0 00.442-.266l3.917-7.4a.5.5 0 01.884 0l3.917 7.4a.5.5 0 00.44.266H22a1 1 0 00.853-1.522z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});