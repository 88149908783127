define("ember-svg-jar/inlined/saving-dog-guard-decrease", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>saving-dog-guard-decrease</title><path d=\"M24 9a.5.5 0 00-.854-.354l-1.437 1.438a.25.25 0 01-.181.073.245.245 0 01-.178-.079l-4.564-4.871a3.5 3.5 0 00-2.978-1.069l-6.023.752a.494.494 0 01-.416-.142L3.061.439A1.5 1.5 0 00.939 2.561l4.309 4.308a3.48 3.48 0 002.909 1l6.024-.753a.5.5 0 01.421.148l4.637 4.951a.25.25 0 01-.006.347l-1.587 1.586A.5.5 0 0018 15h5.5a.5.5 0 00.5-.5z\"/><path d=\"M13.424 13.4a.252.252 0 01-.045-.143v-1.819a2.29 2.29 0 00-2.192-2.188A2.42 2.42 0 009.23 10.8a.25.25 0 01-.224.139H5.494a.25.25 0 01-.224-.139 2.42 2.42 0 00-1.958-1.55 2.29 2.29 0 00-2.187 2.188v1.733a.248.248 0 01-.04.135l-.064.1A5.721 5.721 0 001.8 20.83a.266.266 0 00.031.026c.093.064.522.354 1.138.71a.254.254 0 01.1.1A5.148 5.148 0 007.25 24a5.147 5.147 0 004.186-2.336.256.256 0 01.077-.087 15.951 15.951 0 001.715-1.377l.024-.027a5.731 5.731 0 00.172-6.765zM7.25 22.5a3.928 3.928 0 01-2.274-.869.249.249 0 01.158-.443h.991a1.8 1.8 0 00.987-.31.251.251 0 01.276 0 1.8 1.8 0 00.987.31h.991a.25.25 0 01.158.443 3.928 3.928 0 01-2.274.869zM5 14.188a.75.75 0 11-.75-.75.75.75 0 01.75.75zm2.25 3a.75.75 0 00-.75.75v1.375a.491.491 0 01-.375.375H4.832a.251.251 0 01-.236-.335c.548-1.533 1.619-3.79 2.654-3.79s2.114 2.242 2.665 3.791a.251.251 0 01-.236.334h-1.3A.491.491 0 018 19.313v-1.375a.75.75 0 00-.75-.75zm3.531-2.25a.719.719 0 11.719-.719.719.719 0 01-.719.719z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});