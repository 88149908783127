define("ember-svg-jar/inlined/style-two-pin-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-two-pin-user</title><path d=\"M12 0a9.511 9.511 0 00-9.5 9.5c0 6.543 6.537 12.487 8.541 14.153a1.5 1.5 0 001.917 0c2-1.666 8.542-7.609 8.542-14.154A9.511 9.511 0 0012 0zm0 4a3 3 0 11-3 3 3 3 0 013-3zm4 12H8a.5.5 0 01-.5-.5 4.5 4.5 0 019 0 .5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});