define("ember-svg-jar/inlined/currency-user-exchange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-user-exchange</title><path d=\"M2.554 16.188a.813.813 0 01.812.812.875.875 0 001.75 0 2.557 2.557 0 00-1.538-2.339.248.248 0 01-.149-.229v-.244a.875.875 0 00-1.75 0v.244a.249.249 0 01-.15.229 2.557 2.557 0 001.025 4.9.813.813 0 11-.813.812.875.875 0 00-1.75 0 2.557 2.557 0 001.538 2.339.249.249 0 01.15.229v.245a.875.875 0 001.75 0v-.245a.248.248 0 01.149-.229 2.556 2.556 0 00-1.024-4.9.813.813 0 110-1.625zM13.147 14.452l-1.2 1.8a.25.25 0 01-.208.111.253.253 0 01-.208-.111l-1.2-1.8a.875.875 0 10-1.455.971l1.944 2.916a.245.245 0 01.042.139v.682a.25.25 0 01-.219.248.863.863 0 000 1.725.249.249 0 01.219.248v1.557a.875.875 0 101.75 0v-1.557a.25.25 0 01.22-.248.863.863 0 000-1.725.249.249 0 01-.22-.248v-.682a.252.252 0 01.042-.139l1.946-2.916a.875.875 0 00-1.456-.971zM23.134 22.063h-1.913a.25.25 0 01-.233-.34 2.283 2.283 0 00.164-.8v-.288a.25.25 0 01.25-.25h1.732a.875.875 0 000-1.75H21.4a.25.25 0 01-.25-.25v-2.114a.435.435 0 11.869 0 .875.875 0 001.75 0 2.185 2.185 0 10-4.369 0v2.113a.25.25 0 01-.25.25h-1.159a.875.875 0 000 1.75h1.161a.25.25 0 01.25.25v.287a2.133 2.133 0 01-.764 1.107.229.229 0 01-.117.035.876.876 0 00.041 1.75h4.572a.875.875 0 000-1.75zM11.741 4.215a3.5 3.5 0 00-3.49 3.231.25.25 0 00.25.269h6.481a.25.25 0 00.249-.269 3.5 3.5 0 00-3.49-3.231z\"/><path d=\"M13.88 2.076a2.139 2.139 0 10-2.139 2.139 2.139 2.139 0 002.139-2.139zM12.741 11.938v-2a1 1 0 00-2 0v2a1 1 0 102 0zM18.242 12.938a1 1 0 00.8-1.6l-1.5-2a1 1 0 00-1.6 1.2l1.5 2a1 1 0 00.8.4zM5.941 9.338l-1.5 2a1 1 0 101.6 1.2l1.5-2a1 1 0 00-1.6-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});