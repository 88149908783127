define("ember-svg-jar/inlined/medical-specialty-breathing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-specialty-breathing</title><path d=\"M21.428 9.856a7.235 7.235 0 01-.721-1.45C20.707 3.145 15.845 0 11.143 0a9.925 9.925 0 00-10 10.063c0 3.5 1.271 6.124 3.78 7.813a.5.5 0 01.22.415V23.5a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-9.381A6.054 6.054 0 0113.2 8.152a5.912 5.912 0 016.264 2.479l.562.844a.5.5 0 01-.138.693l-2.5 1.664a.5.5 0 01-.693-.139l-.562-.843a1.909 1.909 0 00-1.972-.812 2 2 0 00-1.525 2V23.5a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-3a.5.5 0 01.5-.5h1.5a2.5 2.5 0 002.5-2.5v-1.984a.5.5 0 01.5-.5c.187 0 .378 0 .552-.01a1.245 1.245 0 00.963-.52 1.222 1.222 0 00.19-.743v-.138c.09-1.116-.708-2.514-1.413-3.749z\"/><circle cx=\"16.893\" cy=\"10.75\" r=\"1\"/><circle cx=\"13.393\" cy=\"10.25\" r=\"1\"/><circle cx=\"10.893\" cy=\"12.75\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});