define("ember-svg-jar/inlined/medical-instrument-tweezers-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-instrument-tweezers-1</title><path d=\"M22.922 4a8.995 8.995 0 00-5.768 1.04.251.251 0 01-.307-.384A6.685 6.685 0 0121.536 2a1 1 0 00.955-1.041A.983.983 0 0021.447 0c-5.728.254-8.7 7.143-9.486 9.286a.492.492 0 01-.186.24l-2.626 1.8a3.544 3.544 0 00-.663.587l-2.258 2.16a.5.5 0 01-.724-.034A2.973 2.973 0 003.25 13a3 3 0 102.782 4.089.5.5 0 01.119-.178l3.781-3.62a1.95 1.95 0 01.355-.321l.7-.477a.25.25 0 01.389.238l-.138 1.089a2 2 0 01-.295.821L7.847 18.33a.478.478 0 01-.126.107 2.995 2.995 0 103.254.12.5.5 0 01-.095-.73l1.631-1.945a.71.71 0 00.066-.087 4.043 4.043 0 00.643-1.721l.424-3.351a.488.488 0 01.093-.234c.894-1.212 4.677-5.25 8.841-4.524A1 1 0 1022.922 4zM3.25 17a1 1 0 111-1 1 1 0 01-1 1zm6 5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});