define("ember-svg-jar/inlined/wild-bird-parrot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wild-bird-parrot</title><path d=\"M17.666 13.04a.75.75 0 111.4-.529 8.173 8.173 0 004.621 4.705c.163-4.694-.066-9.872-1.448-13.177A6.909 6.909 0 0015.938 0C11.32 0 4.9.945.4 8.816a1 1 0 001.085 1.473 65.5 65.5 0 0110.758-.939 33.552 33.552 0 00-.729 6.308 6.356 6.356 0 001.6 4.765c.1.094.2.17.3.254l-.423 2.123a1 1 0 001.961.392l.259-1.294a.247.247 0 01.112-.162.25.25 0 01.2-.031q.2.051.4.093a.251.251 0 01.192.183l.324 1.264a1 1 0 001.938-.5l-.108-.436a.252.252 0 01.046-.214.248.248 0 01.2-.1h2.257a.25.25 0 01.25.25v.743a1 1 0 002 0v-1.13a1 1 0 00.453-.753c.058-.745.109-1.529.152-2.335a9.659 9.659 0 01-5.961-5.73zm-6.7-9.063a1 1 0 10-1.789.9A3.939 3.939 0 019.4 7.258a.253.253 0 01-.231.19c-.187.008-.376.018-.568.028a.253.253 0 01-.19-.073.245.245 0 01-.073-.188 6.541 6.541 0 00-.053-1.178 1 1 0 00-1.981.274 4.325 4.325 0 01.016 1.117.25.25 0 01-.227.217c-.726.06-1.456.132-2.165.218a.25.25 0 01-.227-.4 13.543 13.543 0 019.192-5.249.248.248 0 01.21.069.252.252 0 01.074.208l-.548 4.636a.251.251 0 01-.244.221c-.23 0-.491.009-.776.015a.157.157 0 01-.12-.052.154.154 0 01-.039-.125 6.016 6.016 0 00-.486-3.209zm3.11 6.22a.25.25 0 01-.065-.21c.23-1.461.812-5.548.916-6.28a.252.252 0 01.114-.176 2.462 2.462 0 012.459-.417c1 .512 1.7 2.212 1.7 4.134 0 1.621-.371 3.615-2.13 3.9a3.83 3.83 0 01-2.996-.948z\"/><path d=\"M17.651 6.38a.979.979 0 10-.979.979.978.978 0 00.979-.979z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});