define("ember-svg-jar/inlined/flower-rose", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flower-rose</title><path d=\"M20.194 4.275A.992.992 0 0019.463 4 14.407 14.407 0 0013.8 5.385a.249.249 0 00-.045.424 16.076 16.076 0 015.378 7.47.249.249 0 00.459.031 8.432 8.432 0 00.908-3.824V4.994a1.006 1.006 0 00-.306-.719zM13.786 1.1a11.546 11.546 0 011.7 1.723.5.5 0 00.514.169 16.053 16.053 0 011.573-.323.5.5 0 00.427-.494V1a1 1 0 00-1.055-1 9.966 9.966 0 00-3.091.67.25.25 0 00-.068.426zM4.538 4a.988.988 0 00-.731.28 1 1 0 00-.307.719v4.487a8.513 8.513 0 007.083 8.381.5.5 0 01.417.493V23a1 1 0 002 0v-4.64a.5.5 0 01.418-.492 8.45 8.45 0 004.542-2.323.5.5 0 00.135-.473A14.537 14.537 0 004.538 4zM6.425 2.67a15.861 15.861 0 015.331 1.83.5.5 0 00.487 0 15.956 15.956 0 011.938-.914A9.969 9.969 0 007.055 0a1.01 1.01 0 00-.741.272A1 1 0 006 1v1.176a.5.5 0 00.425.494z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});