define("ember-svg-jar/inlined/skiing-snowboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skiing-snowboard</title><path d=\"M2.015 4.455L.321 5.634a.751.751 0 10.858 1.232l1.3-.906a.25.25 0 01.393.205V7.75a.75.75 0 001.5 0V6.165a.25.25 0 01.393-.205l1.3.906a.75.75 0 00.857-1.232L5.23 4.455a.25.25 0 010-.41l1.693-1.179a.75.75 0 00-.857-1.232l-1.3.906a.25.25 0 01-.393-.205V.75a.75.75 0 00-1.5 0v1.585a.25.25 0 01-.393.205l-1.3-.906a.751.751 0 00-.859 1.232l1.694 1.179a.25.25 0 010 .41zM8.346 10.216a.251.251 0 00-.273.054l-6.9 6.9a4 4 0 005.656 5.658l1.378-1.378a1 1 0 00.293-.707v-10.3a.251.251 0 00-.154-.227zM13.346 5.216a.251.251 0 00-.273.054l-2.28 2.28a1 1 0 00-.293.707v10.3a.25.25 0 00.427.177l2.28-2.28a1 1 0 00.293-.707v-10.3a.251.251 0 00-.154-.231zM22.828 1.171a4 4 0 00-5.656 0L15.793 2.55a1 1 0 00-.293.707v10.3a.25.25 0 00.427.177l6.9-6.9a4 4 0 00.001-5.663z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});