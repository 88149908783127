define("ember-svg-jar/inlined/style-one-pin-train", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-train</title><circle cx=\"9.5\" cy=\"11\" r=\"1\"/><circle cx=\"14.5\" cy=\"11\" r=\"1\"/><path d=\"M12 0a9.469 9.469 0 00-7.284 15.556.5.5 0 00.813-.063l.827-1.379a.751.751 0 011.288.772l-1.085 1.808a.5.5 0 00.143.667 8.63 8.63 0 001.3.758 2.509 2.509 0 011.218 1.222l1.872 4.076a1 1 0 001.817 0l1.876-4.079A2.5 2.5 0 0116 18.118a8.61 8.61 0 001.3-.758.5.5 0 00.143-.667l-1.085-1.807a.751.751 0 011.288-.772l.827 1.379a.5.5 0 00.813.063A9.469 9.469 0 0012 0zm-1 1.5h2A.75.75 0 0113 3h-2a.75.75 0 010-1.5zm-3.5 4A1.5 1.5 0 019 4h6a1.5 1.5 0 011.5 1.5v2a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5zm9 6A1.5 1.5 0 0115 13H9a1.5 1.5 0 01-1.5-1.5v-2A.5.5 0 018 9h8a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});