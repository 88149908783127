define("ember-svg-jar/inlined/single-woman-actions-share-1.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-share-1</title><path d=\"M10.956 15.191h1.769a.5.5 0 00.392-.81A7.5 7.5 0 007.25 11.5a7.542 7.542 0 00-7.185 5.36.5.5 0 00.479.643h8.1a.312.312 0 00.311-.312 2 2 0 012.001-2zM12.456 17.385a1 1 0 00-2 0V23a1 1 0 002 0zM22.956 20.191h-7a.354.354 0 01-.323-.387.372.372 0 01.367-.371h1.955a.887.887 0 00.816-1.236 1.9 1.9 0 00-1.814-1.006l-3-.032a.572.572 0 00-.357.144.5.5 0 00-.148.356v5.032a.5.5 0 00.5.5h8a2.041 2.041 0 002-1.925v-.2a.971.971 0 00-.996-.875zM1.569 10.442a1 1 0 001.383-.3 5.233 5.233 0 00.454-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .249.249 0 01.411.082 5.233 5.233 0 00.454.9A1 1 0 0013.15 9.06a4.578 4.578 0 01-.643-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248a4.573 4.573 0 01-.642 2.512 1 1 0 00.298 1.382zM7.211 8.6A2.7 2.7 0 014.47 6.248a.5.5 0 01.324-.577 5.711 5.711 0 002.245-1.439.25.25 0 01.361 0 5.7 5.7 0 002.23 1.437.5.5 0 01.323.576A2.708 2.708 0 017.211 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});