define("ember-svg-jar/inlined/social-media-yammer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-yammer</title><path d=\"M12.86 2.05a1.45 1.45 0 00-.76-.18 1.67 1.67 0 00-1.67 1.26C9.86 4.76 9.22 6.41 8.6 8l-.78 2-.77 2.08C5.88 9.07 4.64 6 3.45 3a2 2 0 00-1-1.16A1.77 1.77 0 001 1.85a1.59 1.59 0 00-.91.84 2 2 0 00.09 1.52l5 12.35c-.66 1.75-1.13 2.65-2.87 2.53a1.73 1.73 0 00-1.17.22 1.59 1.59 0 00-.74 1.36A1.53 1.53 0 001.15 22a3.73 3.73 0 001.78.3h.27c3.31-.06 4.26-2.5 5.18-4.86L13.57 4.2a1.62 1.62 0 00-.71-2.15zM13.39 9.57a.74.74 0 00.65.3 1.62 1.62 0 00.45-.07c.8-.15 5.78-2.1 6.51-2.8a1.72 1.72 0 00.31-2.06 1.68 1.68 0 00-1.89-.76c-1 .23-5.28 3.72-5.76 4.27s-.39.92-.27 1.12zM22.81 10.64c-.79-.27-4.76.16-6.49.62-.47.12-1.19.31-1.25 1s.65.74 1.25.91a29.9 29.9 0 005.64.69 3.3 3.3 0 00.84-.07 1.63 1.63 0 000-3.15zM14.47 14.69c-.29-.05-.84-.15-1.09.28s.07.9.25 1.12c.51.56 4.76 4 5.76 4.27a2 2 0 00.44.05 1.63 1.63 0 001.45-.83 1.7 1.7 0 00-.31-2c-.7-.78-5.65-2.72-6.5-2.89z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});