define("ember-svg-jar/inlined/christmas-postcard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-postcard</title><path d=\"M23.134 10.138L19.421 7.5A1 1 0 0119 6.685V1.5A1.5 1.5 0 0017.5 0h-11A1.5 1.5 0 005 1.5v5.185a1 1 0 01-.421.815L.891 10.121A2 2 0 000 11.785V21.5A2.5 2.5 0 002.5 24h19a2.5 2.5 0 002.5-2.5v-9.715a1.982 1.982 0 00-.866-1.647zm-4.134 0a.25.25 0 01.395-.2l1.461 1.038a.25.25 0 01-.015.417l-1.461.885a.25.25 0 01-.38-.213zM8.506 9.569l1.415-1.583a.31.31 0 00-.232-.517.692.692 0 01-.544-1.122l2.46-3.164a.518.518 0 01.79 0l2.462 3.167a.692.692 0 01-.546 1.119.309.309 0 00-.231.515l1.37 1.529a.9.9 0 01.089.966.885.885 0 01-.782.469H13.25a.5.5 0 00-.5.5v1.046a.75.75 0 01-1.5 0v-1.046a.5.5 0 00-.5-.5H9.243a.886.886 0 01-.737-1.379zm-5.362 1.4l1.461-1.034a.251.251 0 01.26-.018.248.248 0 01.135.222v1.923a.25.25 0 01-.38.214l-1.461-.885a.251.251 0 01-.015-.418zM22 21.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5l.02-8.018a.251.251 0 01.38-.213l4.661 2.824a.25.25 0 01.01.421L4.6 18.182a1 1 0 001.12 1.658l3.58-2.419a1 1 0 01.56-.171h4.253a1 1 0 01.577.183l3.391 2.395a1 1 0 101.154-1.634l-2.35-1.66a.25.25 0 01.014-.418l4.719-2.859a.25.25 0 01.38.214z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});