define("ember-svg-jar/inlined/kitchenware-scooper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchenware-scooper</title><path d=\"M22.664 1.336A4.563 4.563 0 0015.4 6.688a.5.5 0 01-.089.587L14.1 8.489a.5.5 0 01-.509.122 2.826 2.826 0 00-1.849.032L.982 12.607a1.5 1.5 0 001.037 2.815L6.6 13.735a.5.5 0 01.626.258 5.55 5.55 0 001.155 1.629 5.794 5.794 0 001.632 1.157.5.5 0 01.255.624l-1.69 4.578a1.5 1.5 0 002.815 1.037l3.964-10.758a2.823 2.823 0 00.032-1.848.5.5 0 01.122-.509l1.214-1.214a.5.5 0 01.587-.089 4.563 4.563 0 005.352-7.264zm-11.455 13.5a.25.25 0 01-.344.139 4.037 4.037 0 01-1.843-1.843.25.25 0 01.139-.344L11.314 12a.535.535 0 01.687.685zm8.228-12.055a1.783 1.783 0 00-1.781 1.781.5.5 0 11-1 0 2.785 2.785 0 012.781-2.781.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});