define("ember-svg-jar/inlined/analytics-graph-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>analytics-graph-line</title><path d=\"M3.5 19.873a1 1 0 00.772-1.635A10.009 10.009 0 012 11.947a1 1 0 00-2 .01 12.023 12.023 0 002.723 7.551 1 1 0 00.777.365zM22.737 9.3a1 1 0 00-.843 1.135A10.2 10.2 0 0122 11.9a10.018 10.018 0 01-2.236 6.3 1 1 0 001.552 1.262 12.019 12.019 0 002.556-9.32.993.993 0 00-1.135-.842zM15.37 21.314a10.09 10.09 0 01-6.691.017 1 1 0 10-.664 1.887 12.049 12.049 0 008.029-.021 1 1 0 00-.674-1.883zM2.049 7.1a1 1 0 001.373-.342 10.049 10.049 0 015.163-4.264A1 1 0 007.9.614a12.058 12.058 0 00-6.193 5.109A1 1 0 002.049 7.1zM20.471 5.876a1 1 0 00.768-1.638A12.018 12.018 0 0014.345.125a1 1 0 00-.389 1.962A10.014 10.014 0 0119.7 5.515a1 1 0 00.771.361zM9.776 11.186l1.692 2.09a1.6 1.6 0 001.243.62h.072a1.582 1.582 0 001.237-.729l1.94-2.967 2.657-.313a1 1 0 00-.234-1.987l-2.657.314a1.89 1.89 0 00-1.369.813l-1.687 2.555-1.339-1.654a1.992 1.992 0 00-2.379-.505L8 9.871V7.55a1 1 0 00-2 0v8.85a1 1 0 001 1h10a1 1 0 000-2H8.25a.25.25 0 01-.25-.25v-3.082z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});