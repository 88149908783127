define("ember-svg-jar/inlined/office-stationery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-stationery</title><rect x=\"7.5\" y=\"11.508\" width=\"4.5\" height=\"4\" rx=\".25\" ry=\".25\"/><path d=\"M11.75 10.008a.25.25 0 00.25-.25v-5.25a2.25 2.25 0 10-4.5 0v5.25a.25.25 0 00.25.25zM17.393 11.117l.231-.283a.25.25 0 01.223-.09.253.253 0 01.2.141l.113.236-.445 4.11a.251.251 0 00.248.277h1.509a.249.249 0 00.248-.223l.459-4.231a1 1 0 00-.092-.539l-.866-1.81a.248.248 0 01-.011-.187l.58-1.731a.252.252 0 01.344-.147 2.62 2.62 0 003.724-2.089l.116-1.075a2.622 2.622 0 10-5.213-.564l-.105.966a.084.084 0 01-.086.056.083.083 0 01-.073-.072v-.011l.105-.958a2.622 2.622 0 10-5.214-.564L13.266 3.4a2.618 2.618 0 002.323 2.889 2.573 2.573 0 00.907-.06.249.249 0 01.308.211l.233 1.829a.251.251 0 01-.055.19l-1.325 1.617a1 1 0 00-.22.526l-.5 4.626a.252.252 0 00.249.277h1.509a.249.249 0 00.248-.223zm4.037-8.541a.62.62 0 01.551.685l-.116 1.075a.612.612 0 01-.684.551.62.62 0 01-.552-.68c0-.035 0-.029.116-1.08a.621.621 0 01.685-.551zM15.805 4.3a.621.621 0 01-.551-.685l.116-1.075a.622.622 0 011.237.133L16.49 3.75a.619.619 0 01-.685.55z\"/><rect y=\"17.008\" width=\"24\" height=\"7\" rx=\"1.5\" ry=\"1.5\"/><path d=\"M5.5 15.008v-10.5a.509.509 0 00-.042-.2l-1.75-4a.521.521 0 00-.916 0l-1.75 4a.509.509 0 00-.042.2v10.5a.5.5 0 00.5.5H5a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});