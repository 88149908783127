define("ember-svg-jar/inlined/database-cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-cash</title><path d=\"M8.846 21.529a.251.251 0 00.243-.368 7.915 7.915 0 01-.918-3.006.251.251 0 00-.216-.224 12.821 12.821 0 01-6.172-2.216.25.25 0 00-.408.194v1.716c0 1.916 3.201 3.512 7.471 3.904zM1.375 13.125c0 1.769 2.727 3.267 6.505 3.8a.245.245 0 00.19-.052.251.251 0 00.094-.174 7.938 7.938 0 01.767-2.816.25.25 0 00-.2-.358c-2.777-.267-5.4-1.049-6.947-2.3a.25.25 0 00-.408.194zM10.876 9.125c-3.5 0-7.156-.833-9.093-2.411a.25.25 0 00-.408.194v1.717c0 2.027 3.58 3.7 8.218 3.961a.25.25 0 00.212-.1 7.947 7.947 0 019.655-2.375.248.248 0 00.289-.059 2.13 2.13 0 00.626-1.43V6.91a.249.249 0 00-.407-.194c-1.938 1.577-5.588 2.409-9.092 2.409z\"/><ellipse cx=\"10.876\" cy=\"4.125\" rx=\"9.5\" ry=\"4\"/><path d=\"M16.125 10.875a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm0 11.5a.75.75 0 01-.75-.75v-.25a.25.25 0 00-.25-.25h-.5a.75.75 0 010-1.5h2.033a.592.592 0 00.22-1.141l-2.064-.826a2.084 2.084 0 01.361-3.98.25.25 0 00.2-.245v-.308a.75.75 0 011.5 0v.25a.249.249 0 00.25.25h.5a.75.75 0 010 1.5h-2.034a.592.592 0 00-.22 1.141l2.064.826a2.084 2.084 0 01-.361 3.98.25.25 0 00-.2.245v.308a.75.75 0 01-.749.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});