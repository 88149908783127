define("ember-svg-jar/inlined/pets-paw-can", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pets-paw-can</title><path d=\"M12 6.75A17.678 17.678 0 014.12 5.2a.249.249 0 00-.37.219v15.142c0 2.191 4.276 3.189 8.25 3.189s8.25-1 8.25-3.189V5.415a.251.251 0 00-.123-.215.248.248 0 00-.247 0A17.678 17.678 0 0112 6.75zm2.888 12.067a1.219 1.219 0 01-1.064.683h-3.648a1.219 1.219 0 01-1.064-.683 1.246 1.246 0 01.1-1.274l1.861-2.556a1.194 1.194 0 011.924 0l1.785 2.571a1.218 1.218 0 01.106 1.259zM16 12.5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zM11.984 10a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zM8 12.5A1.5 1.5 0 116.5 14 1.5 1.5 0 018 12.5z\"/><ellipse cx=\"12\" cy=\"2.75\" rx=\"8.25\" ry=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});