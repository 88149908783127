define("ember-svg-jar/inlined/accessories-umbrella", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accessories-umbrella</title><path d=\"M22.98 1.93a3.336 3.336 0 00-4.6.133l-3.357 3.553a.5.5 0 00-.03.651 3.775 3.775 0 00.391.423 3.832 3.832 0 00.445.367.5.5 0 00.648-.068l3.354-3.553a1.285 1.285 0 011.776-.051 1.258 1.258 0 01.052 1.776l-.388.411a1 1 0 001.455 1.372l.387-.41a3.26 3.26 0 00-.133-4.604zM21 13.118a4.4 4.4 0 01-3.47-3.67.5.5 0 00-.456-.448 4.057 4.057 0 01-2.72-1.215 4.057 4.057 0 01-1.37-2.646.5.5 0 00-.477-.433 4.4 4.4 0 01-3.865-3.257.5.5 0 00-.9-.091C4.533 6.716.463 14.308 1.627 19.484a.5.5 0 01-.124.453l-1.23 1.3a1 1 0 101.454 1.373l1.23-1.3a.5.5 0 01.446-.15c5.234.864 12.579-3.636 17.743-7.148a.5.5 0 00-.146-.894z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});