define("ember-svg-jar/inlined/house-fire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>house-fire</title><path d=\"M14.775 12.31a.5.5 0 00-.745.284 5.084 5.084 0 01-.811 1.632.5.5 0 01-.779.016 4.028 4.028 0 01-.162-4.934.5.5 0 00-.6-.752 10.143 10.143 0 00-6.4 9.2 6.223 6.223 0 0012.446 0 6.471 6.471 0 00-2.949-5.446z\"/><path d=\"M23.507 9.907l-10.5-9.5a1.5 1.5 0 00-2.014 0l-10.5 9.5a1.5 1.5 0 002.014 2.225l9.158-8.286a.5.5 0 01.67 0l9.158 8.286a1.5 1.5 0 002.014-2.225z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});