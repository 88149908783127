define("ember-svg-jar/inlined/book-open-bookmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-open-bookmark</title><path d=\"M24 3.517a2.018 2.018 0 00-.609-1.441 1.915 1.915 0 00-1.438-.56c-.29.008-.58.021-.87.037A.554.554 0 0120.5 1a.5.5 0 00-.547-.5 25.167 25.167 0 00-4.081.7.5.5 0 00-.372.484V8a.5.5 0 00.854.354l1.292-1.293a.5.5 0 01.708 0l1.293 1.293A.5.5 0 0020.5 8V4.065a.5.5 0 01.467-.5c.168-.011.337-.02.51-.029a.5.5 0 01.525.5l-.013 13.5a.5.5 0 01-.479.5 22.909 22.909 0 00-7.81 1.507.5.5 0 01-.7-.458V6c0-.19.256-.46.753-.752A.5.5 0 0014 4.816V3.688a.5.5 0 00-.722-.448 6.686 6.686 0 00-.982.585.5.5 0 01-.592 0C9.755 2.383 5.889 1.63 2.047 1.516a1.927 1.927 0 00-1.438.56A2.018 2.018 0 000 3.517v14.5a2 2 0 001.956 2C7.923 20.186 11 21.834 11 22.5a1 1 0 002 0c0-.666 3.077-2.314 9.044-2.486a2 2 0 001.956-2zM2.478 18.033a.5.5 0 01-.478-.5l-.012-13.5a.5.5 0 01.523-.5C8.113 3.789 11 5.357 11 6v13.086a.5.5 0 01-.7.457 22.937 22.937 0 00-7.822-1.51z\"/><path d=\"M9.257 7.443a24.06 24.06 0 00-5.184-1.02.75.75 0 10-.146 1.493 22.661 22.661 0 014.853.949.75.75 0 10.477-1.422zM9.257 11.616A24.036 24.036 0 004.073 10.6a.75.75 0 10-.146 1.493 22.635 22.635 0 014.853.949.75.75 0 00.477-1.422zM9.257 15.789a24.06 24.06 0 00-5.184-1.02.75.75 0 10-.146 1.493 22.538 22.538 0 014.853.949.75.75 0 00.477-1.422zM15.22 13.038a22.635 22.635 0 014.853-.949.75.75 0 10-.146-1.493 24.036 24.036 0 00-5.184 1.02.75.75 0 00.477 1.422zM15.22 17.211a22.538 22.538 0 014.853-.949.75.75 0 10-.146-1.493 24.06 24.06 0 00-5.184 1.02.75.75 0 00.477 1.422z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});