define("ember-svg-jar/inlined/multiple-actions-star.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-star</title><path d=\"M15.947 9.4a.251.251 0 00-.026-.453c-.441-.175-.927-.353-1.45-.546l-.486-.173a1.8 1.8 0 01-.055-1.172 4.647 4.647 0 001.215-3.568A3.219 3.219 0 0012 0a2.994 2.994 0 00-1.342.314.25.25 0 00-.1.363 5 5 0 01.842 2.808 6.271 6.271 0 01-.922 3.624.248.248 0 00-.029.215.251.251 0 00.15.157c1.918.716 3.3 1.327 3.9 2.536q.03.06.057.12a.25.25 0 00.419.055 3.3 3.3 0 01.972-.792z\"/><path d=\"M12.259 13h1.187a.248.248 0 00.229-.151l.194-.448a.246.246 0 00.017-.139 5.945 5.945 0 00-.5-1.684c-.454-.908-1.9-1.441-3.911-2.179l-.485-.173a1.791 1.791 0 01-.06-1.173 4.643 4.643 0 001.216-3.568A3.22 3.22 0 007 0a3.219 3.219 0 00-3.145 3.485 4.609 4.609 0 001.192 3.538 1.877 1.877 0 010 1.185L4.53 8.4c-2.007.736-3.457 1.269-3.911 2.177A7.538 7.538 0 000 13.5a.5.5 0 00.5.5h9.3a.248.248 0 00.175-.071A3.26 3.26 0 0112.259 13zM20.736 23.828a1.277 1.277 0 001.506-.166 1.254 1.254 0 00.315-1.45L21.333 19.4a.251.251 0 01.063-.286l2.213-1.971a1.264 1.264 0 00.3-1.378A1.234 1.234 0 0022.74 15h-2.5a.249.249 0 01-.229-.151l-1.344-3.116-.013-.028a1.293 1.293 0 00-2.317.028l-1.347 3.115a.25.25 0 01-.23.151h-2.5a1.229 1.229 0 00-1.166.76 1.271 1.271 0 00.319 1.4l2.194 1.955a.249.249 0 01.063.286l-1.223 2.815a1.255 1.255 0 00.315 1.451 1.278 1.278 0 001.506.166l3.111-1.752a.249.249 0 01.245 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});