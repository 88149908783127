define("ember-svg-jar/inlined/dislike-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dislike-alternate</title><path d=\"M5.5.935H12a8.268 8.268 0 013.787.826c1.28.551 2.733 1.174 7.213 1.174a1 1 0 011 1v9a1 1 0 01-1 1h-3a6.937 6.937 0 00-6.5 7 2.167 2.167 0 01-2.245 2.13 2.6 2.6 0 01-1.935-.884 3.591 3.591 0 01-.82-2.465 24.042 24.042 0 01.657-4.476.249.249 0 00-.244-.3H2.5a2.5 2.5 0 01-1.337-4.612 2.5 2.5 0 011-3 2.5 2.5 0 011-3A2.5 2.5 0 015.5.935zM22 5.167a.249.249 0 00-.243-.249A15.8 15.8 0 0115 3.6a6.34 6.34 0 00-3-.664H5.5a.5.5 0 000 1 1 1 0 110 2h-1a.5.5 0 000 1 1 1 0 010 2h-1a.5.5 0 000 1 1 1 0 010 2h-1a.5.5 0 000 1h8a1 1 0 01.967 1.255 30.852 30.852 0 00-.967 5.526 1.745 1.745 0 00.316 1.138c.28.315.684.221.684.081a8.987 8.987 0 018.5-9h1.75a.25.25 0 00.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});