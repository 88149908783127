define("ember-svg-jar/inlined/team-idea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>team-idea</title><path d=\"M9.9 10.2a.25.25 0 01.1.2v.6a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-.6a.247.247 0 01.1-.2 3.4 3.4 0 001.4-2.7A3.592 3.592 0 0012 4a3.592 3.592 0 00-3.5 3.5 3.4 3.4 0 001.4 2.7zM12 3a1 1 0 001-1V1a1 1 0 00-2 0v1a1 1 0 001 1zM17.414 3.5A1 1 0 0016 2.085l-.707.707a1 1 0 101.414 1.415zM18.5 8.5a1 1 0 000-2h-1a1 1 0 000 2zM8.707 2.792L8 2.085A1 1 0 006.586 3.5l.707.707a1 1 0 001.414-1.415zM6.5 6.5h-1a1 1 0 000 2h1a1 1 0 000-2zM11 14.25h2a.75.75 0 000-1.5h-2a.75.75 0 000 1.5zM3.5 20.4a2.2 2.2 0 10-2.2-2.2 2.2 2.2 0 002.2 2.2zM.325 24a.251.251 0 01-.237-.329A3.592 3.592 0 013.5 21.2a3.592 3.592 0 013.41 2.47.251.251 0 01-.235.33zM12 20.4a2.2 2.2 0 10-2.2-2.2 2.2 2.2 0 002.2 2.2zM20.5 20.4a2.2 2.2 0 10-2.2-2.2 2.2 2.2 0 002.2 2.2zM8.825 24a.251.251 0 01-.237-.329A3.592 3.592 0 0112 21.2a3.592 3.592 0 013.41 2.47.251.251 0 01-.237.329zM17.325 24a.251.251 0 01-.237-.329A3.592 3.592 0 0120.5 21.2a3.592 3.592 0 013.41 2.47.251.251 0 01-.237.329z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});