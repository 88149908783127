define("ember-svg-jar/inlined/hierarchy-9", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hierarchy-9</title><path d=\"M21.5 5A2.5 2.5 0 1019 2.5a2.426 2.426 0 00.126.766.251.251 0 01-.061.255L16.4 6.193a.25.25 0 01-.307.037 4.972 4.972 0 00-6.56 1.24.248.248 0 01-.3.075l-4.159-1.93A.128.128 0 015 5.5 2.5 2.5 0 102.5 8a2.463 2.463 0 001.511-.523.252.252 0 01.259-.029l4.163 1.933a.252.252 0 01.141.27 4.936 4.936 0 00.659 3.449.25.25 0 01-.037.307l-5.674 5.657a.251.251 0 01-.255.061A2.437 2.437 0 002.5 19 2.5 2.5 0 105 21.5a2.448 2.448 0 00-.125-.765.25.25 0 01.061-.256l5.68-5.665a.251.251 0 01.306-.037 4.967 4.967 0 001.387.579.25.25 0 01.19.243v3.474a.221.221 0 01-.12.2 2.5 2.5 0 102.256.007.249.249 0 01-.136-.222V15.6a.25.25 0 01.19-.243 4.982 4.982 0 002.427-1.437.25.25 0 01.291-.055l1.477.709a.254.254 0 01.141.2 2.492 2.492 0 10.809-2.132.249.249 0 01-.275.039l-1.134-.543a.251.251 0 01-.132-.3A4.75 4.75 0 0018.5 10.5a4.958 4.958 0 00-.725-2.582.251.251 0 01.036-.307l2.669-2.676a.252.252 0 01.255-.061A2.431 2.431 0 0021.5 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});