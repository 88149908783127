define("ember-svg-jar/inlined/os-system-vodafone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>os-system-vodafone</title><path d=\"M12 0a12 12 0 1012 12A12 12 0 0012 0zm.95 6.5a.5.5 0 00.42.51 5.68 5.68 0 014.82 5.54 5.76 5.76 0 01-5.85 5.66 5.54 5.54 0 01-4-1.69 6.93 6.93 0 01-1.89-5 9.12 9.12 0 013.26-7.34A9.93 9.93 0 0114.6 2c-.9.83-1.6 2.19-1.68 4.49z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});