define("ember-svg-jar/inlined/messaging-qik", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messaging-qik</title><path d=\"M23.13 20.35a1.5 1.5 0 00-.91-1.06 3 3 0 01-1.34-1 11 11 0 10-4 3.51 4 4 0 002.95 1.2 4 4 0 003-1.3 1.5 1.5 0 00.3-1.35zM11.83 18a6 6 0 116-6 6 6 0 01-6 6z\"/><path d=\"M11.83 8.75A3.25 3.25 0 008.58 12a.75.75 0 101.5 0 1.75 1.75 0 011.75-1.75.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});