define("ember-svg-jar/inlined/army-symbol-mountain-infantry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-symbol-mountain-infantry</title><path d=\"M22 3.5H2a2.017 2.017 0 00-2 2v13a2.018 2.018 0 002 2h20a2.018 2.018 0 002-2v-13a2.017 2.017 0 00-2-2zM2 6.856a.25.25 0 01.391-.206l7.533 5.144a.249.249 0 010 .412L2.391 17.35A.25.25 0 012 17.144zm2.922-.9a.25.25 0 01.141-.456h13.875a.25.25 0 01.141.456l-6.938 4.738a.25.25 0 01-.282 0zm6.937 7.351a.25.25 0 01.282 0l6.937 4.737a.25.25 0 01-.141.456H5.063a.25.25 0 01-.141-.456zm2.217-1.1a.249.249 0 010-.412l7.533-5.145a.25.25 0 01.391.206v10.288a.25.25 0 01-.391.206z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});