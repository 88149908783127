define("ember-svg-jar/inlined/punishment-hanging", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>punishment-hanging</title><path d=\"M21.66 2.25A2.25 2.25 0 0019.41 0h-11a2.25 2.25 0 00-2.25 2.25v4.132a.242.242 0 01-.135.215 2.98 2.98 0 00-.6 4.889.248.248 0 01.012.373l-2.689 2.453a1.25 1.25 0 101.685 1.846l1.539-1.4s.188-.159.188.074V16.8a.255.255 0 01-.042.139L3.37 21.057a1.25 1.25 0 102.08 1.386l1.75-2.627a.249.249 0 01.416 0l1.754 2.627a1.365 1.365 0 001.733.347 1.251 1.251 0 00.347-1.733L8.7 16.935a.247.247 0 01-.04-.135v-1.937c0-.225.189-.107.189-.107l1.749 1.6a1.31 1.31 0 001.767-.081 1.252 1.252 0 00-.081-1.767l-2.9-2.646a.247.247 0 01.011-.372 2.979 2.979 0 00-.6-4.889.241.241 0 01-.134-.215V2.75a.249.249 0 01.25-.25h2a.5.5 0 01.358.151l7.752 7.961a.5.5 0 01.142.349V22.75a1.25 1.25 0 002.5 0zm-2.5 4.309a.25.25 0 01-.43.174l-3.708-3.809A.25.25 0 0115.2 2.5h3.71a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});