define("ember-svg-jar/inlined/diagram-arrow-dash-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-arrow-dash-right</title><path d=\"M23.993 12a1.464 1.464 0 00-.471-1.072l-6.633-6.16a1 1 0 00-1.681.732v3.25a.25.25 0 01-.25.25h-4.951a1 1 0 00-1 1v4a1 1 0 001 1h4.951a.25.25 0 01.25.25v3.25a1 1 0 001.681.733l6.632-6.159A1.469 1.469 0 0023.993 12z\"/><rect x=\"4.507\" y=\"9\" width=\"3\" height=\"6\" rx=\"1\" ry=\"1\"/><rect x=\".007\" y=\"9\" width=\"3\" height=\"6\" rx=\"1\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});