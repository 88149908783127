define("ember-svg-jar/inlined/video-edit-split", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-edit-split</title><path d=\"M24 14a1 1 0 00-1-1h-8.25a.25.25 0 00-.25.25v2a.25.25 0 00.25.25h1.75a.5.5 0 01.5.5v5a.5.5 0 01-.5.5h-1.75a.25.25 0 00-.25.25v2a.25.25 0 00.25.25H23a1 1 0 001-1zm-1.5 2v5a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5zM9.5 15.75a.25.25 0 00-.25-.25H7.5A.5.5 0 017 15v-5a.5.5 0 01.5-.5h1.75a.25.25 0 00.25-.25v-2A.25.25 0 009.25 7H1a1 1 0 00-1 1v9a1 1 0 001 1h8.25a.25.25 0 00.25-.25zm-8-.75v-5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v5a.5.5 0 01-.5.5H2a.5.5 0 01-.5-.5zM13 23v-2a1 1 0 00-2 0v2a1 1 0 002 0zM12 17.5a1 1 0 001-1v-2a1 1 0 00-2 0v2a1 1 0 001 1zM11 8v2a1 1 0 002 0V8a1 1 0 00-2 0zM11.6 4.8a.5.5 0 00.8 0l3-4A.5.5 0 0015 0H9a.5.5 0 00-.4.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});