define("ember-svg-jar/inlined/fish-jar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fish-jar</title><path d=\"M18 4.85v-.455a1.428 1.428 0 00.5-1.081V1.686A1.437 1.437 0 0017.064.25H6.936A1.437 1.437 0 005.5 1.686v1.628A1.428 1.428 0 006 4.395v.455a4.991 4.991 0 00-4 4.9v7a7.008 7.008 0 007 7h6a7.008 7.008 0 007-7v-7a4.991 4.991 0 00-4-4.9zm-.75 6.763v3.274a.457.457 0 01-.229.425.522.522 0 01-.544-.074l-2.432-1.684-2.186 1.513a2.6 2.6 0 01-2.765.069l-2.325-1.428a.511.511 0 010-.916l2.325-1.428a2.6 2.6 0 012.765.069l2.186 1.513 2.431-1.683a.52.52 0 01.545-.075.457.457 0 01.229.425zM6.667 6.769A1.5 1.5 0 008 5.277V5a.25.25 0 01.25-.25h7.5A.25.25 0 0116 5v.277a1.5 1.5 0 001.334 1.492 2.967 2.967 0 011.976 1.072.25.25 0 01-.193.409H4.883a.25.25 0 01-.193-.409 2.969 2.969 0 011.977-1.072zM15 21.75H9a4.983 4.983 0 01-4.622-3.157.25.25 0 01.232-.343h14.78a.25.25 0 01.232.343A4.983 4.983 0 0115 21.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});