define("ember-svg-jar/inlined/single-woman-actions-up-down.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-up-down</title><path d=\"M12 13.641a.5.5 0 00-.191-.393 7.461 7.461 0 00-4.6-1.621 7.544 7.544 0 00-7.188 5.358.5.5 0 00.479.642h11a.5.5 0 00.5-.5zM17.25 19.123h-.75a.5.5 0 01-.5-.5v-5a1.25 1.25 0 00-2.5 0v5a.5.5 0 01-.5.5h-.75a.5.5 0 00-.407.79l2.5 3.5a.5.5 0 00.814 0l2.5-3.5a.5.5 0 00-.407-.79zM23.907 16.332l-2.5-3.5a.519.519 0 00-.814 0l-2.5 3.5a.5.5 0 00.407.791h.75a.5.5 0 01.5.5v5a1.25 1.25 0 102.5 0v-5a.5.5 0 01.5-.5h.75a.5.5 0 00.407-.791zM1.565 10.442a1 1 0 001.383-.3 5.233 5.233 0 00.454-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .249.249 0 01.411.082 5.233 5.233 0 00.454.9 1 1 0 001.681-1.082 4.569 4.569 0 01-.646-2.511V5.3a5.3 5.3 0 10-10.59 0v1.248a4.564 4.564 0 01-.643 2.512 1 1 0 00.298 1.382zM7.207 8.6a2.7 2.7 0 01-2.741-2.352.5.5 0 01.324-.577 5.7 5.7 0 002.245-1.439.25.25 0 01.361 0 5.7 5.7 0 002.23 1.437.5.5 0 01.323.576A2.708 2.708 0 017.207 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});