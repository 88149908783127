define("ember-svg-jar/inlined/laptop-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-home</title><path d=\"M7.611 10.782A.34.34 0 007.93 11h.91a.25.25 0 01.25.25v3.25a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1.25a1.25 1.25 0 012.5 0v1.25a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-3.25a.25.25 0 01.25-.25h.911a.341.341 0 00.319-.219.329.329 0 00-.1-.369L12.56 6.58a.343.343 0 00-.439 0L7.71 10.413a.33.33 0 00-.099.369z\"/><path d=\"M22.41 4.5a2 2 0 00-2-2h-17a2 2 0 00-2 2v12.682a.319.319 0 01-.32.319 1 1 0 00-.99 1.164l.124.747A2.492 2.492 0 002.7 21.5h18.606a2.492 2.492 0 002.466-2.089l.124-.746a1 1 0 00-.986-1.165.5.5 0 01-.5-.5zm-2 12.5a.5.5 0 01-.5.5h-16a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5h16a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});