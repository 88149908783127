define("ember-svg-jar/inlined/cloud-smartphone-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-smartphone-upload</title><path d=\"M24 9.829a5.765 5.765 0 00-5.216-5.972.252.252 0 01-.191-.12 7.684 7.684 0 00-14.1 2.294.251.251 0 01-.227.2A4.643 4.643 0 00.624 8.47 4.467 4.467 0 000 10.918a4.289 4.289 0 001.235 3.1 5.472 5.472 0 003.571 1.377h.261A.932.932 0 006 14.459a1.068 1.068 0 00-1.068-1.068H4.81a3.46 3.46 0 01-2.171-.8A2.375 2.375 0 012 10.8a2.588 2.588 0 012.586-2.583 2.7 2.7 0 01.535.054 1 1 0 001.2-.963c.085-5.737 8.292-7.372 10.818-2.045a1.024 1.024 0 00.977.569 3.686 3.686 0 012.924 1.257A3.776 3.776 0 0122 9.791a3.69 3.69 0 01-2.789 3.469.982.982 0 00-.708.941.986.986 0 001.288.934A5.538 5.538 0 0024 9.829z\"/><path d=\"M9.147 6.646A.5.5 0 009.5 7.5h1.25a.25.25 0 01.25.25V9.5a1 1 0 002 0V7.75a.25.25 0 01.25-.25h1.25a.5.5 0 00.354-.854l-2.5-2.5a.5.5 0 00-.707 0zM17 13.25a1.752 1.752 0 00-1.75-1.75h-6a1.752 1.752 0 00-1.75 1.75v9A1.752 1.752 0 009.25 24h6A1.752 1.752 0 0017 22.25zm-2 7a.25.25 0 01-.25.25h-5a.25.25 0 01-.25-.25v-6.5a.251.251 0 01.25-.25h5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});