define("ember-svg-jar/inlined/gesture-tap-vertical-expand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-tap-vertical-expand</title><path d=\"M12.32 3.5h5a.751.751 0 00.469-1.335l-2.5-2a.749.749 0 00-.938 0l-2.5 2A.75.75 0 0012.32 3.5zM17.32 20.5h-5a.751.751 0 00-.469 1.335l2.5 2a.751.751 0 00.938 0l2.5-2a.751.751 0 00-.469-1.335zM17.707 6.115a6.656 6.656 0 00-6.84.172.75.75 0 00.8 1.272 5.237 5.237 0 115.312 9.022 5.171 5.171 0 01-5.323-.143.75.75 0 00-.8 1.27 6.68 6.68 0 003.57 1.044 6.766 6.766 0 003.277-.858 6.726 6.726 0 00.008-11.779z\"/><path d=\"M15.84 11.5a1 1 0 00-1-1H7.776a.25.25 0 01-.142-.455c2.138-1.49 1.449-3.855-.224-2.761L3.043 10.2a.5.5 0 00-.223.416v5.434a.5.5 0 00.411.491c1.1.2 3.683.665 4.165.776a1.283 1.283 0 001.52-.894l1.117-3.74a.25.25 0 01.239-.179h4.568a1 1 0 001-1.004z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});