define("ember-svg-jar/inlined/real-estate-message-couple-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-message-couple-building</title><path d=\"M19.75 11.5a2 2 0 002-2V2a2 2 0 00-2-2h-2.5a1 1 0 000 2h2a.5.5 0 01.5.5V9a.5.5 0 01-.5.5H14.5a1 1 0 00-.832.445l-1.46 2.19A.25.25 0 0111.75 12v-1.5a1 1 0 00-1-1h-5a.5.5 0 01-.5-.5V2.5a.5.5 0 01.5-.5h1a1 1 0 000-2h-1.5a2 2 0 00-2 2v7.5a2 2 0 002 2h4a.5.5 0 01.5.5v1.436a1.815 1.815 0 003.323 1.006l1.814-2.719a.5.5 0 01.413-.223z\"/><circle cx=\"4.5\" cy=\"15\" r=\"3\"/><path d=\"M4.5 19A4.505 4.505 0 000 23.5a.5.5 0 00.5.5h8a.5.5 0 00.5-.5A4.505 4.505 0 004.5 19z\"/><circle cx=\"19.5\" cy=\"15\" r=\"3\"/><path d=\"M19.5 19a4.505 4.505 0 00-4.5 4.5.5.5 0 00.5.5h8a.5.5 0 00.5-.5 4.505 4.505 0 00-4.5-4.5zM10 8.75h1a.5.5 0 00.5-.5v-.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-6a.5.5 0 00-.243-.429l-2.5-1.5a.5.5 0 00-.514 0l-2.5 1.5a.5.5 0 00-.243.429v6a.5.5 0 00.5.5zm1.5-6.5h2a.5.5 0 010 1h-2a.5.5 0 010-1zm0 2.5h2a.5.5 0 010 1h-2a.5.5 0 010-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});