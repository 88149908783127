define("ember-svg-jar/inlined/charging-flash-wave", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>charging-flash-wave</title><path d=\"M19.4 4.858a1 1 0 10-.77 1.844 5.507 5.507 0 010 10.156 1 1 0 00.77 1.842 7.507 7.507 0 000-13.845zM5.373 16.858a5.507 5.507 0 010-10.156A1 1 0 004.6 4.858a7.507 7.507 0 000 13.842 1 1 0 00.77-1.845zM15.641 11.108h-1.58a.25.25 0 01-.25-.25V6.274a.5.5 0 00-.889-.312l-5.237 6.551a.833.833 0 00.635 1.372H9.9a.25.25 0 01.25.25v4.584a.5.5 0 00.333.471.5.5 0 00.556-.159l5.238-6.551a.834.834 0 00-.636-1.372z\"/><path d=\"M6.4 10.256a1 1 0 00-.889-1.791 3.7 3.7 0 000 6.63A1 1 0 006.4 13.3a1.7 1.7 0 010-3.048zM20.468 11.781a3.683 3.683 0 00-2.052-3.316 1 1 0 00-.889 1.791 1.7 1.7 0 010 3.048 1 1 0 00.889 1.791 3.68 3.68 0 002.052-3.314z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});