define("ember-svg-jar/inlined/login-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>login-2</title><path d=\"M20.25 1.5h-7.5v-1a.5.5 0 00-.608-.488l-9 2a.5.5 0 00-.392.488v19a.5.5 0 00.392.488l9 2a.5.5 0 00.608-.488v-1h7.5a1 1 0 001-1v-19a1 1 0 00-1-1zm-10 11a1.5 1.5 0 11-1.5-1.5 1.5 1.5 0 011.5 1.5zm8.5 8h-5.5a.5.5 0 01-.5-.5V4a.5.5 0 01.5-.5h5.5a.5.5 0 01.5.5v16a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});