define("ember-svg-jar/inlined/dog-food", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dog-food</title><path d=\"M23.5 18.438l-1.394-5.574A1.167 1.167 0 0021 12H3a1.168 1.168 0 00-1.106.864L.5 18.438a2.079 2.079 0 00.336 1.8A2.076 2.076 0 002.5 21h19a1.988 1.988 0 002-2.562zM17.073 16v1a1 1 0 01-1 1h-8a1 1 0 01-1-1v-1a1 1 0 011-1h8a1 1 0 011 1zM3.573 10.523l17-.017a.5.5 0 00.5-.5 3.426 3.426 0 00-3.333-3.721 3.286 3.286 0 00-.969.144 4.934 4.934 0 00-9.4 0 3.334 3.334 0 00-.966-.144c-1.963 0-3.334 1.537-3.334 3.738a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});