define("ember-svg-jar/inlined/style-three-pin-metro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-metro</title><circle cx=\"10.25\" cy=\"12.75\" r=\".75\"/><circle cx=\"13.75\" cy=\"12.75\" r=\".75\"/><path d=\"M15.47 15.47a.749.749 0 011.06 0l1.87 1.867a.5.5 0 00.751-.05C21 14.867 22.5 12.1 22.5 9.5 22.5 4.262 17.79 0 12 0S1.5 4.262 1.5 9.5c0 2.6 1.5 5.367 3.35 7.787a.5.5 0 00.752.051L7.47 15.47a.75.75 0 111.06 1.06L6.5 18.565a.5.5 0 00-.019.687 45.131 45.131 0 004.564 4.4 1.5 1.5 0 001.921 0 45.217 45.217 0 004.563-4.4.5.5 0 00-.019-.687l-2.04-2.035a.749.749 0 010-1.06zm.03-5.97a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5V8A1.5 1.5 0 0110 6.5h4A1.5 1.5 0 0115.5 8zM12 4a6.006 6.006 0 00-6 6v2.75a.75.75 0 01-1.5 0V10a7.5 7.5 0 0115 0v2.75a.75.75 0 01-1.5 0V10a6.006 6.006 0 00-6-6zm2 10.5h-4A1.5 1.5 0 018.5 13v-1.5A.5.5 0 019 11h6a.5.5 0 01.5.5V13a1.5 1.5 0 01-1.5 1.5z\"/><path d=\"M10.5 7.5a1 1 0 00-1 1 .5.5 0 001 0 .5.5 0 000-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});