define("ember-svg-jar/inlined/religion-cross-necklace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-cross-necklace</title><path d=\"M9.3 8a.5.5 0 00.2-.4V6.541a.5.5 0 00-.357-.478A10 10 0 012.872.591a1 1 0 00-1.824.82 12.019 12.019 0 007.821 6.67A.5.5 0 009.3 8zM22.45.089a1 1 0 00-1.322.5 10 10 0 01-6.271 5.471.5.5 0 00-.357.479V7.6a.5.5 0 00.631.483 12.021 12.021 0 007.821-6.673A1 1 0 0022.45.089zM11.5 8.5h1A.5.5 0 0013 8V6a1 1 0 00-2 0v2a.5.5 0 00.5.5zM16.879 13.156l-2.957.739a.253.253 0 01-.241-.069.25.25 0 01-.06-.243l.876-3.028A.5.5 0 0014 10h-4a.5.5 0 00-.5.555l.876 3.028a.25.25 0 01-.06.243.253.253 0 01-.241.069l-2.957-.739a.5.5 0 00-.621.484v3.86a.5.5 0 00.621.485l3.044-.761a.251.251 0 01.31.261l-.442 5.984a.5.5 0 00.5.531h2.936a.5.5 0 00.5-.531l-.442-5.984a.251.251 0 01.31-.261l3.044.761a.5.5 0 00.622-.485v-3.86a.5.5 0 00-.621-.484z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});