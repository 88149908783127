define("ember-svg-jar/inlined/house-entrance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>house-entrance</title><path d=\"M24 9.5a1 1 0 00-.389-.791l-11-8.5a1 1 0 00-1.224 0l-11 8.5A1 1 0 000 9.5V23a1 1 0 001 1h8a.5.5 0 00.5-.5V19a2.5 2.5 0 015 0v4.5a.5.5 0 00.5.5h8a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});