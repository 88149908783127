define("ember-svg-jar/inlined/chef-gear-hat-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chef-gear-hat-1</title><path d=\"M17 2.5a7.925 7.925 0 00-3.246.832.25.25 0 00-.047.424c1.224.951 2.684 2.133 3.935 2.342a.75.75 0 11-.242 1.479c-1.9-.316-3.587-1.9-5.4-3.235A9.6 9.6 0 007 2.5c-4.6 0-7 3.019-7 6a6.6 6.6 0 003.769 5.943A.5.5 0 004 14.5h16a.5.5 0 00.231-.057A6.6 6.6 0 0024 8.5c0-2.981-2.4-6-7-6zM20 16h-2.25a.5.5 0 00-.5.5V19a.75.75 0 01-1.5 0v-2.5a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5V19a.75.75 0 01-1.5 0v-2.5a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5V19a.75.75 0 01-1.5 0v-2.5a.5.5 0 00-.5-.5H4a.5.5 0 00-.5.5V20A1.5 1.5 0 005 21.5h14a1.5 1.5 0 001.5-1.5v-3.5a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});