define("ember-svg-jar/inlined/people-woman-9.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-9</title><path d=\"M22.319 18.531a12.118 12.118 0 00-2.825-1.554.246.246 0 01-.152-.206.37.37 0 00-.01-.058.493.493 0 00-.32-.339 19.114 19.114 0 00-2.787-.694.489.489 0 00-.388.1l-3.683 2.94a.246.246 0 01-.306 0l-3.683-2.94a.479.479 0 00-.388-.1 19.146 19.146 0 00-2.787.694.493.493 0 00-.32.339.28.28 0 00-.009.058.249.249 0 01-.153.206 12.152 12.152 0 00-2.826 1.554 2.416 2.416 0 00-.946 1.936v3.043a.49.49 0 00.49.49h21.55a.489.489 0 00.489-.49v-3.043a2.416 2.416 0 00-.946-1.936zm-11.344 1.13l-1.347 1.076a.246.246 0 01-.335-.028l-3-3.333a.244.244 0 01.12-.4c.4-.1.8-.194 1.207-.271a.242.242 0 01.2.049l3.161 2.524a.244.244 0 010 .383zm6.737-2.285l-3 3.333a.246.246 0 01-.335.028l-1.347-1.076a.245.245 0 010-.383l3.161-2.524a.242.242 0 01.2-.049c.409.077.81.167 1.207.271a.244.244 0 01.12.4z\"/><path d=\"M7.891 14.575a.245.245 0 01.27 0 6.856 6.856 0 007.506.029.243.243 0 01.27 0c1.336.892 4.076.873 5.214-.488a.491.491 0 00-.227-.783c-2.525-.811-2.114-4.412-2.114-4.5V6.874a6.874 6.874 0 10-13.747 0v1.964c0 .087.384 3.639-2.114 4.441a.491.491 0 00-.227.783c1.342 1.604 4.161 1.163 5.169.513zm-.864-6.729a.245.245 0 01.239-.246 6.869 6.869 0 004.609-1.662.092.092 0 01.124 0A6.87 6.87 0 0016.608 7.6a.244.244 0 01.238.245v.99a4.91 4.91 0 11-9.819 0z\"/><path d=\"M13.9 10.311a.982.982 0 10-.982-.982.982.982 0 00.982.982zM9.973 10.311a.982.982 0 10-.982-.982.982.982 0 00.982.982zM10.421 11.9a.63.63 0 00.312.706 2.38 2.38 0 00.426.176 3.008 3.008 0 001.706 0 2.3 2.3 0 00.428-.178.627.627 0 00-.3-1.179H11.03a.629.629 0 00-.609.475z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});