define("ember-svg-jar/inlined/virtual-tap-finger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>virtual-tap-finger</title><path d=\"M21 20.5a2.5 2.5 0 00-2.5-2.5h-1.917a.251.251 0 01-.241-.184 4.5 4.5 0 00-8.707.089.125.125 0 01-.121.095H7.5A2.5 2.5 0 005 20.5v2A1.5 1.5 0 006.5 24h13a1.5 1.5 0 001.5-1.5zm-9-4A2.5 2.5 0 119.5 19a2.5 2.5 0 012.5-2.5zM11.166 4.5a1 1 0 00-1 1v4.13a.25.25 0 01-.41.192l-.449-.376a.833.833 0 00-1.282 1.065l1.578 1.9a.249.249 0 00.197.089h5.45a.25.25 0 00.25-.25v-1.527A2.222 2.222 0 0013.278 8.5h-.861a.249.249 0 01-.25-.25V5.5a1 1 0 00-1.001-1z\"/><path d=\"M4 7.5a1 1 0 001-1v-4a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v4a1 1 0 002 0V2a2 2 0 00-2-2H5a2 2 0 00-2 2v4.5a1 1 0 001 1zM17.854 12.734L16.793 13.8a1 1 0 101.414 1.414l1.061-1.061a1 1 0 10-1.414-1.414zM22.707 9.3a1 1 0 00-1.414 0l-1.061 1.06a1 1 0 101.414 1.414l1.061-1.06a1 1 0 000-1.414zM6.5 15.5a1 1 0 00.707-1.7l-1.061-1.066a1 1 0 10-1.414 1.414l1.061 1.061a1 1 0 00.707.291zM2.707 9.3a1 1 0 10-1.414 1.414l1.061 1.06a1 1 0 101.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});