define("ember-svg-jar/inlined/insect-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>insect-2</title><path d=\"M11.25 17.381a1.276 1.276 0 01-.267-.272L8.777 14.02a.254.254 0 00-.157-.1 4.482 4.482 0 01-3.165-2.312.251.251 0 00-.391-.07 1.238 1.238 0 01-.71.349 1.619 1.619 0 01-1.147-.594 1 1 0 00-1.414 1.414 3.236 3.236 0 003.707.917v1.511c-.277.159-.564.711-1.146.752a1.616 1.616 0 01-1.147-.594 1 1 0 00-1.414 1.414 3.306 3.306 0 003.707.917v1.51c-.276.159-.564.712-1.146.753a1.6 1.6 0 01-1.147-.594 1 1 0 00-1.414 1.414 3.226 3.226 0 004.181.653A5.426 5.426 0 0010.8 24h.45zM22.207 16.707a1 1 0 00-1.414-1.414 1.54 1.54 0 01-1.137.6c-.587-.034-.868-.588-1.156-.754v-1.51c0 .091.7.22.778.233a3.464 3.464 0 002.929-1.15 1 1 0 00-1.414-1.414 1.606 1.606 0 01-1.137.6 1.23 1.23 0 01-.718-.348.246.246 0 00-.212-.061.252.252 0 00-.178.131 4.48 4.48 0 01-3.168 2.31.248.248 0 00-.157.1l-2.2 3.087a1.263 1.263 0 01-.268.273V24h.45a5.423 5.423 0 004.826-2.64c-.041.075 2.064 1.464 4.181-.653a1 1 0 00-1.414-1.414 1.554 1.554 0 01-1.137.6c-.586-.034-.867-.587-1.156-.753v-1.516a3.235 3.235 0 003.702-.917zM7.25 7.3a1.575 1.575 0 00-.75 1.322v1.13a2.886 2.886 0 003 2.75h5a2.886 2.886 0 003-2.75v-1.13a1.575 1.575 0 00-.752-1.329V2.5A2.43 2.43 0 0014.25 0a1 1 0 000 2 .442.442 0 01.5.5V5a.5.5 0 01-.5.5h-4.5a.5.5 0 01-.5-.5V2.5c0-.168 0-.481.5-.5a1 1 0 000-2 2.43 2.43 0 00-2.5 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});