define("ember-svg-jar/inlined/family-child-play-car", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-child-play-car</title><circle cx=\"9\" cy=\"5.125\" r=\"2.5\"/><path d=\"M24 16.125a1.5 1.5 0 00-1.5-1.5h-2.536l.788-2.892a2.485 2.485 0 00-1.068-2.769l-4.378-2.605a1.5 1.5 0 00-2.034.406L10.4 11.39a1.5 1.5 0 002.457 1.721l1.92-3.238a.249.249 0 01.339-.089l2.307 1.321a.5.5 0 01.234.565l-1.107 4.06A1.5 1.5 0 0018 17.625h4.5a1.5 1.5 0 001.5-1.5zM12 15.875H7.5v-1.75a1 1 0 00-1-1H4.309a.99.99 0 00-.886.535L2.2 15.875h-.7a1.5 1.5 0 00-1.5 1.5v2a1 1 0 001 1h.381a2.247 2.247 0 003.738 0h2.762a2.247 2.247 0 003.738 0H12a1 1 0 001-1v-2.5a1 1 0 00-1-1zm-2.25 4.5a1.25 1.25 0 111.25-1.25 1.25 1.25 0 01-1.25 1.25zm-6.5 0a1.25 1.25 0 111.25-1.25 1.25 1.25 0 01-1.25 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});