define("ember-svg-jar/inlined/music-genre-burn-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-genre-burn-alternate</title><path d=\"M20.662 5.245a1.5 1.5 0 00-2.538.269 1.293 1.293 0 01-1.205.762.248.248 0 01-.189-.068 4.531 4.531 0 01-1.008-4.256A1.5 1.5 0 0014.175.005 8.083 8.083 0 006.627 5.9a.249.249 0 01-.369.147 1.547 1.547 0 01-.588-.626 1.5 1.5 0 00-2.456-.332C1.635 6.84 1.378 10.108 2.6 12.858a8.255 8.255 0 003.89 4.082.25.25 0 00.358-.177 3.245 3.245 0 01.545-1.279.252.252 0 00.039-.2.249.249 0 00-.127-.163 6.37 6.37 0 01-2.876-3.074 6.369 6.369 0 01-.268-4.7.25.25 0 01.408-.089 3.613 3.613 0 002.887 1.008 1 1 0 00.893-.855 5.99 5.99 0 014.945-5.269.251.251 0 01.3.272A6.331 6.331 0 0015.9 8.1a1.006 1.006 0 00.5.172 3.653 3.653 0 002.855-.936.25.25 0 01.4.074 5.736 5.736 0 01-.033 4.827 5.932 5.932 0 01-2.851 2.7.249.249 0 00-.128.3 3.259 3.259 0 01.157 1v.5a.249.249 0 00.347.23 8.092 8.092 0 004.271-3.849 7.627 7.627 0 00-.756-7.873z\"/><path d=\"M15.542 16.2a2 2 0 00-2.37-1.966c-.041.008-3.57 1.14-3.57 1.14a2 2 0 00-1.56 1.951v2.984a.25.25 0 01-.188.242 1.75 1.75 0 102.188 1.694v-4.731a.25.25 0 01.174-.239c.554-.174 2.274-.725 3-.957a.25.25 0 01.327.238v1.758a.25.25 0 01-.188.242 1.75 1.75 0 102.188 1.694z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});