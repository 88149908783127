define("ember-svg-jar/inlined/cash-payment-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-payment-person</title><path d=\"M23.894 15.144a1.493 1.493 0 00-.748-.87l-7.114-3.591a1.5 1.5 0 00-2.015.662l-.617 1.223a.251.251 0 01-.395.069 7.715 7.715 0 00-2.482-1.566.25.25 0 01-.035-.449 5.683 5.683 0 10-5.619.046.25.25 0 01-.028.449 7.718 7.718 0 00-4.811 7.14.5.5 0 00.5.5H9.7a.25.25 0 01.25.25v3.472a1.5 1.5 0 001.5 1.5h7.969a1.5 1.5 0 001.5-1.5v-.955a.251.251 0 01.116-.211 1.478 1.478 0 00.529-.575l2.245-4.447a1.49 1.49 0 00.085-1.147zM12.2 21.979a.25.25 0 01-.25-.25v-3.484A.25.25 0 0112.2 18h6.469a.25.25 0 01.25.25v3.484a.25.25 0 01-.25.25zm-3.711-8.061V15.6a.75.75 0 11-1.5 0v-1.682a.75.75 0 011.5 0zM19.417 16h-5.1a.25.25 0 01-.223-.363l1.371-2.716a.248.248 0 01.335-.11l5.775 2.915a.251.251 0 01.11.336l-.73 1.447a.02.02 0 01-.022.01.019.019 0 01-.015-.019 1.5 1.5 0 00-1.501-1.5zM3.955 5.707a3.578 3.578 0 01.291-1.4.252.252 0 01.164-.143.247.247 0 01.214.039A7.729 7.729 0 009.2 5.707a7.66 7.66 0 001.835-.227.233.233 0 01.289.227 3.686 3.686 0 01-7.372 0z\"/><circle cx=\"15.531\" cy=\"20.078\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});