define("ember-svg-jar/inlined/earth-pin-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earth-pin-1</title><path d=\"M12 0a9.5 9.5 0 00-4 18.119 2.5 2.5 0 011.218 1.222l1.873 4.076a1 1 0 001.817 0l1.876-4.08A2.5 2.5 0 0116 18.118 9.5 9.5 0 0012 0zm0 16A6.493 6.493 0 018.414 4.09a.5.5 0 01.777.416v3.432a.814.814 0 01-.488.746A1.219 1.219 0 007.971 9.8v.813a1.22 1.22 0 002.3.564 1.051 1.051 0 01.93-.564h2.46a.814.814 0 00.814-.813l.023-1.66a.812.812 0 00-.813-.813h-.405a.814.814 0 01-.813-.814L12.484 6a1.008 1.008 0 011.008-.992A1.008 1.008 0 0014.5 4a.318.318 0 01.463-.283A6.5 6.5 0 0112 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});