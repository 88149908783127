define("ember-svg-jar/inlined/hair-under-skin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hair-under-skin</title><path d=\"M22.5 9.5h-3.438a.5.5 0 00-.481.363 15.738 15.738 0 00-.355 1.55.5.5 0 00.468.587 3.149 3.149 0 011.946.78 1.755 1.755 0 002.12.07l1.036-.76a.5.5 0 00.2-.4V11A1.5 1.5 0 0022.5 9.5z\"/><path d=\"M19.65 13.91a1.671 1.671 0 00-1.271-.407.5.5 0 00-.452.472c-.048.91-.017 1.5-.017 1.515v1.01a.75.75 0 01-1.5 0v-.97c0-3.917.61-8.927 4.042-11.2a1 1 0 00-1.1-1.668 10.993 10.993 0 00-4.222 6.47.5.5 0 01-.482.368h-4.585a.5.5 0 00-.481.363 16.976 16.976 0 00-.5 2.437.5.5 0 00.769.483 1.12 1.12 0 00.1-.073 3.232 3.232 0 014 .16 1.438 1.438 0 00.218.155.5.5 0 01.248.463c-.011.189-.019.368-.025.534a.5.5 0 01-.71.436 3 3 0 01-.721-.468 1.756 1.756 0 00-2.12-.08 3.635 3.635 0 01-1.538.669.5.5 0 00-.411.491c0 .259.009.406.009.42v1.01a.75.75 0 01-1.5 0v-.97c0-4.035.666-8.964 4.042-11.2a1 1 0 10-1.1-1.668 10.993 10.993 0 00-4.213 6.47.5.5 0 01-.482.368H1.5A1.5 1.5 0 000 11v.688a.5.5 0 00.2.4l1.1.81a1.666 1.666 0 002.01-.08 3.068 3.068 0 011.622-.718.5.5 0 01.562.544 21.89 21.89 0 00-.043.509.5.5 0 01-.418.456 1.448 1.448 0 00-.733.337 3.169 3.169 0 01-3.89.15.258.258 0 00-.41.208V20a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 20v-5.764a.219.219 0 00-.35-.176 3.272 3.272 0 01-4-.15zM2.25 17a.75.75 0 11.75-.75.755.755 0 01-.75.75zm1.5 3a.75.75 0 11.75-.75.755.755 0 01-.75.75zm7.5 0a.75.75 0 11.75-.75.755.755 0 01-.75.75zm8 0a.75.75 0 11.75-.75.755.755 0 01-.75.75zm3-2.5a.75.75 0 11.75-.75.755.755 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});