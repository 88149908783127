define("ember-svg-jar/inlined/medical-specialty-intestine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-specialty-intestine</title><path d=\"M24 14.25a3.734 3.734 0 00-1.72-3.139.5.5 0 01-.164-.666 2.913 2.913 0 00-.075-3.015.5.5 0 01.053-.6A3.457 3.457 0 0023 4.5 3.5 3.5 0 0019.5 1a3.463 3.463 0 00-2.037.662.5.5 0 01-.677-.085 2.975 2.975 0 00-4.428-.172.5.5 0 01-.716 0 2.975 2.975 0 00-4.428.172.5.5 0 01-.677.085A3.463 3.463 0 004.5 1 3.5 3.5 0 001 4.5a3.457 3.457 0 00.906 2.328.5.5 0 01.053.6 2.913 2.913 0 00-.075 3.015.5.5 0 01-.164.666 3.725 3.725 0 00.486 6.54.5.5 0 01.294.455V20.5a1.5 1.5 0 003 0v-2.666a.5.5 0 01.234-.424 3.7 3.7 0 00.846-5.6.5.5 0 01.025-.684A2.982 2.982 0 007.5 9a2.933 2.933 0 00-.458-1.569.5.5 0 01.054-.6 3.638 3.638 0 00.378-.5.5.5 0 01.655-.175A2.922 2.922 0 009.5 6.5a2.983 2.983 0 002.142-.9.5.5 0 01.716 0 2.983 2.983 0 002.142.9 2.922 2.922 0 001.371-.342.5.5 0 01.655.175 3.638 3.638 0 00.378.5.5.5 0 01.054.6A2.933 2.933 0 0016.5 9a2.982 2.982 0 00.895 2.129.5.5 0 01.025.684 3.7 3.7 0 00.766 5.546.5.5 0 01.143.69.988.988 0 01-.829.451h-1.464a3.975 3.975 0 00-2.829 1.172l-1.268 1.267a1.5 1.5 0 002.122 2.122l1.267-1.267a.992.992 0 01.708-.294H17.5a3.992 3.992 0 003.942-3.437.5.5 0 01.3-.388A3.738 3.738 0 0024 14.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});