define("ember-svg-jar/inlined/bus-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bus-1</title><path d=\"M19.7 3.75a.25.25 0 01-.248-.219A4 4 0 0015.5 0h-7a4 4 0 00-3.95 3.531.25.25 0 01-.25.219H1a1 1 0 00-1 1v4a1 1 0 001 1h1.086a.251.251 0 01.177.073l1.293 1.293a.252.252 0 01-.029.379A2.481 2.481 0 002.5 13.5v4.346a.25.25 0 01-.125.216 2.243 2.243 0 00.9 4.177.25.25 0 01.225.249V23a1 1 0 001 1h2a1 1 0 001-1v-.5a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v.5a1 1 0 001 1h2a1 1 0 001-1v-.512a.25.25 0 01.225-.249 2.243 2.243 0 00.9-4.177.25.25 0 01-.125-.216V13.5a2.481 2.481 0 00-1.027-2 .252.252 0 01-.029-.379l1.293-1.293a.251.251 0 01.177-.073H23a1 1 0 001-1v-4a1 1 0 00-1-1zM14.486 18H9.5a.5.5 0 01-.5-.5V15a1 1 0 011-1h4a1 1 0 011 1l-.01 2.5a.5.5 0 01-.504.5zM17.5 5.5V10a.5.5 0 01-.5.5h-3.75a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5h3.25a1 1 0 011 1zm-11 0a1 1 0 011-1h3.25a.5.5 0 01.5.5v5a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5zM6 13.75A1.25 1.25 0 114.75 15 1.25 1.25 0 016 13.75zm12 2.5A1.25 1.25 0 1119.25 15 1.25 1.25 0 0118 16.25zM3.03 8.47A.106.106 0 013 8.4V5.5a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v3.836a.25.25 0 01-.427.177zM20.5 20.75h-17a.75.75 0 010-1.5h17a.75.75 0 010 1.5zm-.573-11.237a.25.25 0 01-.427-.177V5.5a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v2.9a.106.106 0 01-.03.073z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});