define("ember-svg-jar/inlined/hourglass-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hourglass-alternate</title><path d=\"M20.5 22h-1v-3.5a7.505 7.505 0 00-3.761-6.5A7.5 7.5 0 0019.5 5.5V2h1a1 1 0 000-2h-17a1 1 0 000 2h1v3.5A7.5 7.5 0 008.261 12 7.505 7.505 0 004.5 18.5V22h-1a1 1 0 000 2h17a1 1 0 000-2zm-14-3.5a5.507 5.507 0 016.051-5.473 5.668 5.668 0 014.949 5.72V21.5a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5zM7 2h10a.5.5 0 01.5.5v2.756a5.667 5.667 0 01-4.949 5.718A5.506 5.506 0 016.5 5.5v-3A.5.5 0 017 2z\"/><path d=\"M12 9.626a4.042 4.042 0 003.433-2.165A.5.5 0 0015 6.71H9a.5.5 0 00-.433.751A4.042 4.042 0 0012 9.626zM12.748 15.59a1.035 1.035 0 00-1.5 0l-3.323 3.747A1 1 0 008.671 21h6.658a1 1 0 00.747-1.664z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});