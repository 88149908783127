define("ember-svg-jar/inlined/religion-islam", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-islam</title><path d=\"M23 8.388a.749.749 0 00-.647-.492l-2.8-.407L18.3 4.95a.728.728 0 00-1.339 0l-1.254 2.54-2.8.407a.715.715 0 00-.414 1.274l2.027 1.976-.479 2.791a.753.753 0 00.269.768.782.782 0 00.814.018l2.507-1.318 2.506 1.318a.715.715 0 001.083-.787l-.478-2.79 2.027-1.977A.753.753 0 0023 8.388z\"/><path d=\"M16.964 21a9 9 0 110-18c.422 0 1.364.183 1.6-.733a1 1 0 00-.548-1.154A11.868 11.868 0 0012.964 0a12 12 0 000 24 11.882 11.882 0 005.05-1.114 1 1 0 00.547-1.155c-.232-.906-1.167-.731-1.597-.731z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});