define("ember-svg-jar/inlined/people-woman-11", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-11</title><path d=\"M19.917 17.45a.128.128 0 00.112-.017.126.126 0 00.051-.1V8.221a8.139 8.139 0 00-1.153-4.173.25.25 0 00-.372-.066 2.47 2.47 0 01-1.572.564 2.494 2.494 0 01-2.492-2.491A2.457 2.457 0 0114.8.859.249.249 0 0014.671.5 8.2 8.2 0 003.638 8.221v9.032a.249.249 0 00.329.236l.118-.039a25 25 0 0115.832 0zM7.472 11.575a1.488 1.488 0 00-.855-1.116 1.526 1.526 0 01-.7-1.314.729.729 0 01.242-.666.5.5 0 00.268-.527q-.054-.322-.092-.635a.247.247 0 01.06-.2.25.25 0 01.187-.084h10.626a.249.249 0 01.248.279 14.95 14.95 0 01-.092.635.5.5 0 00.268.527.733.733 0 01.242.665 1.528 1.528 0 01-.7 1.315 1.493 1.493 0 00-.855 1.115c-.4 2.407-3.305 3.934-4.423 3.934s-4.021-1.522-4.424-3.928z\"/><path d=\"M12.882 11.983a1.225 1.225 0 01-.832.286h-.282a1.227 1.227 0 01-.831-.285.747.747 0 00-1.045 1.069 2.688 2.688 0 001.876.711h.282a2.693 2.693 0 001.876-.71.748.748 0 00-1.044-1.071zM10.27 9.6a.748.748 0 001.286-.763 1.816 1.816 0 00-1.64-.864 1.813 1.813 0 00-1.639.864.747.747 0 101.286.763c.021-.036.078-.131.353-.131s.333.094.354.131zM15.541 8.836a1.813 1.813 0 00-1.641-.864 1.813 1.813 0 00-1.639.864.747.747 0 101.286.762c.021-.036.078-.131.353-.131s.333.1.354.132a.747.747 0 101.285-.763zM6.75 17.869l.028.3a2.232 2.232 0 002.229 2.032h.132a.76.76 0 01.742.5 2.242 2.242 0 004.23 0 .767.767 0 01.743-.5h.133a2.231 2.231 0 002.228-2.032l.028-.3-1.383-.469a.5.5 0 00-.569.191 3.987 3.987 0 01-6.582 0 .5.5 0 00-.569-.191l-1.39.467z\"/><path d=\"M20.877 19.086h-.008l-1.884-.632a.249.249 0 00-.324.192 3.71 3.71 0 01-3.222 3.031.253.253 0 00-.188.125 3.736 3.736 0 01-6.5 0 .253.253 0 00-.188-.125 3.711 3.711 0 01-3.223-3.034.252.252 0 00-.119-.17.248.248 0 00-.206-.022l-1.884.632h-.008A3.985 3.985 0 00.54 22.817v.934A.25.25 0 00.79 24h22.42a.25.25 0 00.25-.249v-.934a3.985 3.985 0 00-2.583-3.731z\"/><circle cx=\"16.983\" cy=\"2.055\" r=\"1.495\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});