define("ember-svg-jar/inlined/sweater-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sweater-1</title><path d=\"M6.508 18.25a.25.25 0 00-.25.25v3.478a.522.522 0 00.522.522h10.44a.522.522 0 00.522-.522V18.5a.25.25 0 00-.25-.25z\"/><rect x=\"6.258\" y=\"13\" width=\"11.483\" height=\"3.5\" rx=\".25\" ry=\".25\"/><path d=\"M23.987 15.04l-2.262-9.788A3.13 3.13 0 0019.821 3l-3.658-1.463a.522.522 0 00-.542.1A5.461 5.461 0 0112 3.065a5.461 5.461 0 01-3.621-1.431.522.522 0 00-.542-.1L4.178 3a3.131 3.131 0 00-1.9 2.256L.013 15.04a.523.523 0 00.357.617l2.718.827a.52.52 0 00.66-.379L5.684 7.88a.291.291 0 01.574.067V11a.25.25 0 00.25.25h10.984a.25.25 0 00.25-.25V7.947a.291.291 0 01.574-.067l1.935 8.225a.521.521 0 00.661.379l2.718-.827a.523.523 0 00.357-.617z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});