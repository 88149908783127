define("ember-svg-jar/inlined/single-woman-circle-alternate.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-circle-alternate</title><path d=\"M24 12a12 12 0 10-12 12 12.013 12.013 0 0012-12zM2.5 12a9.5 9.5 0 1116.519 6.391.5.5 0 01-.584.115c-1.016-.482-2.345-.957-3.846-1.512a.784.784 0 01-.511-.734v-.482a.25.25 0 01.244-.25 6.424 6.424 0 003.663-.819.5.5 0 00.125-.755c-.451-.527-.764-1.22-.9-3.251-.26-3.849-2.856-5.2-5.087-5.2S7.3 6.854 7.034 10.7c-.137 2.031-.45 2.724-.9 3.251a.5.5 0 00.125.755 6.338 6.338 0 003.653.834.251.251 0 01.254.25v.467a.784.784 0 01-.511.734c-1.582.585-2.975 1.082-4.008 1.591a.5.5 0 01-.586-.108A9.459 9.459 0 012.5 12zm6.523 6.818a.3.3 0 01.421-.386 5.689 5.689 0 005.119.045.3.3 0 01.411.4l-1.04 2.319a.25.25 0 01-.182.144 9.492 9.492 0 01-3.571-.013.251.251 0 01-.183-.15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});