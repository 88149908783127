define("ember-svg-jar/inlined/multiple-actions-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-image</title><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M17.167 8.938a.25.25 0 00.156-.446 4.514 4.514 0 00-5.647 0 .251.251 0 00.157.446zM9 10.938a1.951 1.951 0 01.171-.79.247.247 0 00-.064-.289A5.491 5.491 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.06a.25.25 0 00.25-.25z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M24 22v-9.5a2 2 0 00-2-2h-9.5a2 2 0 00-2 2V22a2 2 0 002 2H22a2 2 0 002-2zm-2.725-5.113a1.245 1.245 0 00-1.02-.557h-.011a1.254 1.254 0 00-1.033.533l-1.537 2.2a.254.254 0 01-.169.1.25.25 0 01-.192-.053l-.612-.489a1.249 1.249 0 00-1.821.282l-2 3.005a.2.2 0 01-.171.092.206.206 0 01-.206-.206V12.75a.25.25 0 01.25-.25h9a.25.25 0 01.25.25v4.4a.25.25 0 01-.458.139z\"/><circle cx=\"15.5\" cy=\"15\" r=\"1.75\"/><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M17.167 8.938a.25.25 0 00.156-.446 4.514 4.514 0 00-5.647 0 .251.251 0 00.157.446zM9 10.938a1.951 1.951 0 01.171-.79.247.247 0 00-.064-.289A5.491 5.491 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.06a.25.25 0 00.25-.25z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M24 22v-9.5a2 2 0 00-2-2h-9.5a2 2 0 00-2 2V22a2 2 0 002 2H22a2 2 0 002-2zm-2.725-5.113a1.245 1.245 0 00-1.02-.557h-.011a1.254 1.254 0 00-1.033.533l-1.537 2.2a.254.254 0 01-.169.1.25.25 0 01-.192-.053l-.612-.489a1.249 1.249 0 00-1.821.282l-2 3.005a.2.2 0 01-.171.092.206.206 0 01-.206-.206V12.75a.25.25 0 01.25-.25h9a.25.25 0 01.25.25v4.4a.25.25 0 01-.458.139z\"/><circle cx=\"15.5\" cy=\"15\" r=\"1.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});