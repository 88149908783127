define("ember-svg-jar/inlined/weather-thunder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-thunder</title><path d=\"M8.25 3a1 1 0 001-1V1a1 1 0 00-2 0v1a1 1 0 001 1zM3.124 4.538a1 1 0 101.414-1.415l-.708-.707a1 1 0 10-1.414 1.415zM3 8.25a1 1 0 00-1-1H1a1 1 0 000 2h1a1 1 0 001-1z\"/><path d=\"M5.229 9.313a.249.249 0 00.124-.285 3 3 0 014-3.569.248.248 0 00.247-.039 8.379 8.379 0 011.375-.92.251.251 0 00.02-.43 5 5 0 00-7.407 5.985.25.25 0 00.4.1 7.069 7.069 0 011.241-.842zM14.085 2.417a1 1 0 00-1.415 0l-.708.707a.992.992 0 00-.292.671.247.247 0 00.1.211.251.251 0 00.234.032 8.492 8.492 0 012.125-.493.252.252 0 00.219-.192 1 1 0 00-.263-.936zM22.094 12.074a.252.252 0 01-.1-.193A7 7 0 008.338 9.84a.249.249 0 01-.22.173 5.5 5.5 0 00-3.783 9.075.25.25 0 00.409-.044 1.98 1.98 0 01.528-.622l.469-.365a.25.25 0 00.041-.355 3.5 3.5 0 013.166-5.673.993.993 0 001.116-.833A5 5 0 0120 12c0 .146-.007.289-.02.433a1 1 0 00.5.959 3 3 0 01.342 4.988.252.252 0 00-.095.162 2 2 0 01-.75 1.286.2.2 0 00-.077.145.207.207 0 00.055.155 2 2 0 01.337.485.25.25 0 00.3.126 5 5 0 001.5-8.665z\"/><path d=\"M8.961 22.092A.75.75 0 008.95 20.9L8 20.186a.25.25 0 010-.4l1.217-.946a.75.75 0 00-.922-1.184l-2.25 1.75A.75.75 0 006.05 20.6l.95.714a.25.25 0 010 .4l-1.217.946a.75.75 0 00.922 1.184zM13.961 22.092a.75.75 0 00-.011-1.192l-.95-.714a.25.25 0 010-.4l1.217-.946a.75.75 0 00-.922-1.184l-2.25 1.75a.75.75 0 00.005 1.194l.953.714a.25.25 0 010 .4l-1.217.946a.75.75 0 00.922 1.184zM18.961 22.092a.75.75 0 00-.011-1.192l-.95-.714a.25.25 0 010-.4l1.217-.946a.75.75 0 10-.922-1.184l-2.25 1.75a.75.75 0 00.005 1.194l.953.714a.25.25 0 010 .4l-1.217.946a.75.75 0 00.922 1.184z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});