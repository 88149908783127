define("ember-svg-jar/inlined/recycling-hand-trash-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>recycling-hand-trash-1</title><path d=\"M14.25 12H.75a.75.75 0 000 1.5h.882a.5.5 0 01.5.455l.789 8.681A1.493 1.493 0 004.413 24h6.174a1.493 1.493 0 001.494-1.364l.789-8.681a.5.5 0 01.5-.455h.882a.75.75 0 000-1.5zM5.5 20.5a.5.5 0 01-1 0v-5a.5.5 0 011 0zm2.5 0a.5.5 0 01-1 0v-5a.5.5 0 011 0zm2.5 0a.5.5 0 01-1 0v-5a.5.5 0 011 0zM23 0a1 1 0 00-1 1 .419.419 0 01-.518.407L16.011.069a4.482 4.482 0 00-2.554.3L4.548 4.182a1 1 0 00-.442 1.471 3.418 3.418 0 003.873 1.363l5.479-1.864a3.518 3.518 0 011.7-.14.25.25 0 01.088.461l-2.968 1.785a2 2 0 002.06 3.429L17.319 8.9a3.5 3.5 0 011.8-.5H21.5a.5.5 0 01.5.5v.6a1 1 0 002 0V1a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});