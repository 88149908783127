define("ember-svg-jar/inlined/human-resources-offer-woman-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-offer-woman-1</title><path d=\"M.971 14.75a1 1 0 00-1 1V23a1 1 0 002 0v-7.25a1 1 0 00-1-1zM15.471 19.5H6.346a.625.625 0 010-1.25h2.526a1 1 0 00.919-1.394A2.233 2.233 0 007.735 15.5H3.471a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.764a2.239 2.239 0 002.236-2.237V20.5a1 1 0 00-1-1zM20.17 9.786a.249.249 0 00-.3.11l-1.431 2.485a.25.25 0 01-.433 0L16.574 9.9a.251.251 0 00-.3-.11 5.76 5.76 0 00-3.8 5.41.5.5 0 00.5.5h10.5a.5.5 0 00.5-.5 5.76 5.76 0 00-3.804-5.414zM21.351 8.044a.25.25 0 01.377.059 3.923 3.923 0 00.6.758 1 1 0 101.4-1.423c-.336-.332-.783-.99-.783-2.863a4.575 4.575 0 10-9.15 0c0 1.873-.447 2.531-.783 2.863a1 1 0 001.4 1.423 3.916 3.916 0 00.6-.757.25.25 0 01.376-.06 4.567 4.567 0 005.957 0zM17.1 4.384a2.734 2.734 0 001.089-.912.251.251 0 01.4-.017 4.508 4.508 0 001.044.929 3.153 3.153 0 001.051.472.251.251 0 01.188.3A2.575 2.575 0 0115.833 5a.25.25 0 01.2-.287 3.967 3.967 0 001.067-.329z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});