define("ember-svg-jar/inlined/router-signal-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>router-signal-1</title><path d=\"M21 13.872h-3.75a.25.25 0 01-.25-.25v-3.75a1.5 1.5 0 00-3 0v3.75a.25.25 0 01-.25.25H3a3 3 0 00-3 3v2a3 3 0 002.769 2.991.251.251 0 01.231.249v.51a1.5 1.5 0 003 0v-.5a.25.25 0 01.25-.25h11.5a.25.25 0 01.25.25v.5a1.5 1.5 0 003 0v-.509a.248.248 0 01.231-.249A3 3 0 0024 18.872v-2a3 3 0 00-3-3zM8.081 16.7a1.125 1.125 0 11-1.125 1.125A1.125 1.125 0 018.081 16.7zm-3.875 2.33a1.175 1.175 0 01-.718-.262 1.184 1.184 0 01-.488-.94 1.206 1.206 0 112.411 0 1.176 1.176 0 01-.252.7 1.185 1.185 0 01-.953.505zM11.257 5.63a1 1 0 101.414 1.414 4.1 4.1 0 015.658 0 1 1 0 101.414-1.414 6 6 0 00-8.486 0z\"/><path d=\"M9.843 4.215a8.01 8.01 0 0111.314 0A1 1 0 0022.571 2.8a10.013 10.013 0 00-14.142 0 1 1 0 101.414 1.415z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});