define("@gavant/ember-shopify-draggable/components/sortable-item", ["exports", "@ember/component", "@gavant/ember-shopify-draggable/templates/components/sortable-item", "@ember/utils", "@ember/object"], function (_exports, _component, _sortableItem, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _sortableItem.default,
    classNames: ['sortable-item'],
    visible: false,
    _dragStart(event) {
      const source = (0, _object.get)(event, 'data.originalSource');
      if (this.element && this.element.isSameNode(source)) {
        (0, _utils.tryInvoke)(this, 'dragStart', [(0, _object.get)(this, 'item')]);
      }
    },
    init() {
      this._super(...arguments);
      (0, _object.get)(this, 'container.group').on('drag:start', this, '_dragStart');
    },
    willDestroyElement() {
      (0, _object.get)(this, 'container.group').off('drag:start', this, '_dragStart');
    }
  }).reopenClass({
    positionalParams: ['item']
  });
});