define("ember-svg-jar/inlined/shop-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shop-1</title><path d=\"M21.379 11.9a3.7 3.7 0 01-2.928-1.511.25.25 0 00-.4 0 3.592 3.592 0 01-5.856 0 .25.25 0 00-.4 0A3.7 3.7 0 018.874 11.9a3.7 3.7 0 01-2.929-1.511.25.25 0 00-.4 0A3.7 3.7 0 012.621 11.9a3.474 3.474 0 01-.384-.022.249.249 0 00-.278.249v9.023a1.5 1.5 0 001.5 1.5h17a1.5 1.5 0 001.5-1.5v-9.016a.252.252 0 00-.082-.185.248.248 0 00-.191-.064 3.486 3.486 0 01-.307.015zm-6.422 3.11a.382.382 0 01.4-.361h3.2a.382.382 0 01.4.361v5.139a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5zm-9.5-.028a.355.355 0 01.374-.333h5.247a.356.356 0 01.375.333v3.334a.356.356 0 01-.375.333H5.831a.355.355 0 01-.374-.333z\"/><path d=\"M21.379 10.4a2.457 2.457 0 002.228-1.875 2.264 2.264 0 00-.013-1.37l-1.8-5.47a.431.431 0 00-.349-.333H2.551a.431.431 0 00-.349.333L.405 7.153a2.275 2.275 0 00-.012 1.371A2.457 2.457 0 002.621 10.4 2.665 2.665 0 005 7.523a.75.75 0 011.5 0A2.665 2.665 0 008.874 10.4a2.665 2.665 0 002.376-2.877.75.75 0 011.5 0 2.665 2.665 0 002.377 2.877A2.664 2.664 0 0017.5 7.523a.75.75 0 011.5 0 2.666 2.666 0 002.379 2.877z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});