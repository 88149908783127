define("ember-svg-jar/inlined/image-file-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-warning</title><path d=\"M14.68 5.538a1.493 1.493 0 10-1.493 1.492 1.493 1.493 0 001.493-1.492zM11.122 12.9a.187.187 0 00.163.1h9.028a.186.186 0 00.158-.286l-3.236-5.178a.374.374 0 00-.633 0l-1.81 2.9a.188.188 0 01-.317 0L13.831 9.4a.373.373 0 00-.633 0l-2.071 3.312a.187.187 0 00-.005.188z\"/><path d=\"M23.415 3L21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.819a.247.247 0 00.233.249 7.4 7.4 0 011.443.215.248.248 0 00.324-.237V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-8.343a.25.25 0 00-.221.366l.786 1.5a.251.251 0 00.222.134H22a2 2 0 002-2V4.414A2 2 0 0023.415 3z\"/><path d=\"M7.779 12.271a1.448 1.448 0 00-2.56 0L.163 21.9a1.428 1.428 0 00.048 1.409A1.455 1.455 0 001.443 24h10.112a1.456 1.456 0 001.232-.686 1.428 1.428 0 00.048-1.409zM5.749 16a.75.75 0 011.5 0v3a.75.75 0 01-1.5 0zm.75 6.25a1 1 0 111-1 1 1 0 01-.999 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});