define("ember-svg-jar/inlined/human-resources-workflow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-workflow</title><circle cx=\"12.006\" cy=\"1.75\" r=\"1\"/><path d=\"M12.006 3.25a2 2 0 00-1.993 1.83.156.156 0 00.04.119.16.16 0 00.116.051h3.569a.252.252 0 00.189-.087.248.248 0 00.058-.2 2 2 0 00-1.979-1.713z\"/><circle cx=\"21.506\" cy=\"11.75\" r=\"1\"/><path d=\"M19.508 15.151a.1.1 0 00.094.1h3.636a.252.252 0 00.189-.087.248.248 0 00.058-.2 2 2 0 00-3.977.187z\"/><circle cx=\"12.006\" cy=\"19.75\" r=\"1\"/><path d=\"M12.006 21.25a2 2 0 00-1.993 1.83.156.156 0 00.04.119.16.16 0 00.116.051h3.569a.252.252 0 00.189-.087.248.248 0 00.058-.2 2 2 0 00-1.979-1.713z\"/><circle cx=\"2.506\" cy=\"11.75\" r=\"1\"/><path d=\"M2.506 13.25a2 2 0 00-1.993 1.83.156.156 0 00.04.119.16.16 0 00.116.051h3.569a.252.252 0 00.189-.087.248.248 0 00.058-.2 2 2 0 00-1.979-1.713zM16.342 7.95a.5.5 0 00.445.332h.025A.5.5 0 0017.264 8l.105-.222a.252.252 0 01.407-.066 8.092 8.092 0 011.169 1.556 1 1 0 101.732-1A10.057 10.057 0 0018.558 5.7a.249.249 0 01-.062-.3l.483-1.019a.5.5 0 00-.62-.685l-2.664.95a.5.5 0 00-.3.639zM3.479 9.21a.507.507 0 00.168.344.5.5 0 00.331.125h.032L6.833 9.5a.5.5 0 00.3-.874l-.766-.674a.25.25 0 01-.01-.365 8.062 8.062 0 012.089-1.5 1 1 0 10-.891-1.791 10.1 10.1 0 00-2.722 1.987.248.248 0 01-.344.013l-.357-.315a.5.5 0 00-.83.407zM7.7 18.414a.5.5 0 00-.415-.369.493.493 0 00-.5.242l-.225.384a.251.251 0 01-.4.045 8.009 8.009 0 01-1.324-1.9 1 1 0 00-1.786.884 10.03 10.03 0 002.164 2.886.248.248 0 01.045.309l-.492.841a.5.5 0 00.432.753.537.537 0 00.126-.016l2.736-.716a.5.5 0 00.358-.61zM20.527 17.244a.5.5 0 00-.57-.418l-2.8.429a.5.5 0 00-.22.9l.615.45a.251.251 0 01.1.185.248.248 0 01-.075.2 8 8 0 01-2.015 1.431 1 1 0 00.893 1.79 10.054 10.054 0 002.8-2.077.249.249 0 01.329-.03l.574.421a.5.5 0 00.79-.48zM7.544 15.508a.678.678 0 00.641.9h7.642a.678.678 0 00.64-.9 4.725 4.725 0 00-8.923 0z\"/><circle cx=\"12.006\" cy=\"9.624\" r=\"2.037\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});