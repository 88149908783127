define("ember-svg-jar/inlined/scissors-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>scissors-2</title><path d=\"M23.756 17.746l-9.275-5.513a.25.25 0 010-.432l9.265-5.313a.5.5 0 00.182-.689 2.7 2.7 0 00-3.551-.923L9.781 10.8a.25.25 0 01-.24 0L7.117 9.49A4.456 4.456 0 10.552 8.053a.965.965 0 00.389.376l6.207 3.35a.25.25 0 010 .44L.941 15.57a.946.946 0 00-.389.376 4.469 4.469 0 00.733 5.283 4.169 4.169 0 001.856 1.13 4.462 4.462 0 003.976-7.85L9.538 13.2a.254.254 0 01.245 0l10.59 6.143a2.461 2.461 0 001.253.329 2.692 2.692 0 002.3-1.245.5.5 0 00-.17-.681zM4.955 8.324l-2.776-1.5a2.462 2.462 0 114.749-.915 2.482 2.482 0 01-1.973 2.415zm-.489 12.227a2.467 2.467 0 01-2.287-3.377l2.776-1.5a2.463 2.463 0 01-.489 4.876z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});