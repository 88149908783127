define("ember-svg-jar/inlined/livestock-lamb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>livestock-lamb</title><path d=\"M23.688 9.2l-2.12-3.323a6.484 6.484 0 00-5.9-3.753A5.97 5.97 0 0012.4 3.1a4.511 4.511 0 00-6.217-.427 4.525 4.525 0 00-5.116 4.452v.168a4.5 4.5 0 000 5.664 4.462 4.462 0 001.292 3.3 1.227 1.227 0 00-.044.329v4.535a1.25 1.25 0 002.5 0v-3.565a4.473 4.473 0 001.369.026 4.5 4.5 0 005.761 0c.124.017.247.028.37.035v3.508a1.25 1.25 0 002.5 0v-4.1a4.487 4.487 0 001.885-2.132 8.379 8.379 0 002.857-2.15c.52.2.511.283.969.283a3.489 3.489 0 003.039-2.052 1.5 1.5 0 00.123-1.774zM7.894 10.456a.5.5 0 01.054-.591l1.727-1.942a6.672 6.672 0 001.442 4 2.843 2.843 0 01-3.223-1.467zm7.777 2.669c-.8 0-4-2.667-4-5a4 4 0 018 0c0 2.333-3.199 5-4 5zm4.589-1.795a8.586 8.586 0 00.893-2.2l1.108 1.248a2.11 2.11 0 01-2.001.952z\"/><circle cx=\"14.067\" cy=\"8.623\" r=\"1\"/><circle cx=\"17.067\" cy=\"8.623\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});