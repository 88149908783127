define("ember-svg-jar/inlined/multiple-man-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-man-1</title><path d=\"M21.83 15.878l-3.193-1.616a.25.25 0 01-.137-.223v-1.6a.247.247 0 01.108-.205 3.468 3.468 0 001.373-2.744.251.251 0 01.1-.181A1.593 1.593 0 0020.62 8a1.7 1.7 0 00-.412-1.2.25.25 0 01-.059-.25 6.205 6.205 0 00.336-3.174C20.139 1.992 18.256 1.5 16.75 1.5a6.318 6.318 0 00-2.024.322.245.245 0 00-.153.15.249.249 0 00.019.213 3.087 3.087 0 01.348.83 6.811 6.811 0 01-.163 3.317.246.246 0 00.016.173A3.431 3.431 0 0115.12 8a3.278 3.278 0 01-.658 2.031.24.24 0 00-.05.114 5.34 5.34 0 01-.867 2.218.247.247 0 00-.045.142v.664a.251.251 0 00.158.233l2.683 1.063a4.975 4.975 0 013.159 4.649V22a1.551 1.551 0 01-.015.217.251.251 0 00.249.283H23.5a.5.5 0 00.5-.5v-2.886a3.463 3.463 0 00-2.17-3.236z\"/><path d=\"M18 19.114a3.484 3.484 0 00-2.211-3.255l-4.131-1.636a.251.251 0 01-.158-.232V12.44a.247.247 0 01.108-.205 3.468 3.468 0 001.373-2.745.251.251 0 01.1-.181A1.593 1.593 0 0013.62 8a1.7 1.7 0 00-.412-1.2.25.25 0 01-.059-.25 6.205 6.205 0 00.336-3.174C13.139 1.992 11.256 1.5 9.75 1.5c-1.292 0-2.86.362-3.5 1.341a.251.251 0 01-.206.113 1.42 1.42 0 00-1.069.433c-.713.769-.357 2.253-.1 3.161a.253.253 0 01-.065.245 1.184 1.184 0 00-.207.279A1.942 1.942 0 004.38 8a1.593 1.593 0 00.539 1.309.251.251 0 01.1.181 3.468 3.468 0 001.373 2.745.25.25 0 01.108.205v1.552a.249.249 0 01-.158.232L2.206 15.87A3.482 3.482 0 000 19.122V22a.5.5 0 00.5.5h17a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});