define("ember-svg-jar/inlined/arrow-button-left-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-left-1</title><path d=\"M10.416 12a2.643 2.643 0 01.775-1.875L20.732.584a1.768 1.768 0 012.5 2.5l-8.739 8.739a.25.25 0 000 .354l8.739 8.739a1.768 1.768 0 01-2.5 2.5l-9.541-9.541A2.643 2.643 0 0110.416 12z\"/><path d=\"M.25 12a2.643 2.643 0 01.775-1.875L10.566.584a1.768 1.768 0 012.5 2.5l-8.739 8.739a.25.25 0 000 .354l8.739 8.739a1.768 1.768 0 01-2.5 2.5l-9.541-9.541A2.643 2.643 0 01.25 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});