define("ember-svg-jar/inlined/single-woman-actions-music.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-music</title><path d=\"M12.466 13.868a.5.5 0 00-.162-.377A7.467 7.467 0 00.065 16.857a.5.5 0 00.479.643h11.412a.5.5 0 00.5-.5c-.001-.839 0-2.5.01-3.132z\"/><path d=\"M21.456 22a2.5 2.5 0 002.5-2.5v-6.795a2 2 0 00-2.368-1.966l-6 1.125a2 2 0 00-1.631 1.966v4.931a.255.255 0 01-.081.185.251.251 0 01-.192.064 2.83 2.83 0 00-.227-.01 2.5 2.5 0 102.5 2.5v-7.255a.5.5 0 01.408-.492l5-.937a.5.5 0 01.592.491v3.454a.255.255 0 01-.081.185.251.251 0 01-.192.064 2.83 2.83 0 00-.227-.01 2.5 2.5 0 000 5zM1.57 10.442a1 1 0 001.382-.3 5.166 5.166 0 00.455-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .249.249 0 01.411.082 5.233 5.233 0 00.454.9 1 1 0 001.681-1.082 4.561 4.561 0 01-.643-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248a4.573 4.573 0 01-.642 2.512 1 1 0 00.298 1.382zM7.211 8.6A2.706 2.706 0 014.47 6.248a.5.5 0 01.324-.577A5.708 5.708 0 007.04 4.232a.249.249 0 01.36 0 5.719 5.719 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.211 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});