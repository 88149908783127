define("ember-svg-jar/inlined/crime-robber", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-robber</title><circle cx=\"13\" cy=\"6.5\" r=\"2.5\"/><path d=\"M22.5 18.5h-1.739a2.512 2.512 0 01-2.337-1.614l-1.77-4.669a.25.25 0 01.17-.33l2.447-.644a.5.5 0 01.481.13l1.687 1.688a1.5 1.5 0 002.122-2.122l-1.688-1.687a3.513 3.513 0 00-3.366-.91l-7.659 2.015a.489.489 0 01-.352-.037l-.326-.162a1.5 1.5 0 00-1.34 2.684l.325.158a3.494 3.494 0 002.456.255l1.81-.476a.249.249 0 01.3.153l.808 2.131a.25.25 0 01-.09.293l-1 .706A5.49 5.49 0 0011 20.632V22.5a1.5 1.5 0 003 0v-1.868a2.513 2.513 0 011.133-2.094l.326-.23a.25.25 0 01.207-.037.247.247 0 01.162.132 5.52 5.52 0 004.933 3.1H22.5a1.5 1.5 0 000-3zM13 3a1 1 0 001-1V1a1 1 0 00-2 0v1a1 1 0 001 1zM16.182 4.318a1 1 0 00.707-.293l1.061-1.061a1 1 0 10-1.414-1.414l-1.061 1.061a1 1 0 00.707 1.707zM9.111 4.025a1 1 0 101.414-1.414L9.464 1.55A1 1 0 008.05 2.964zM7 7.5h1.5a1 1 0 000-2H7a1 1 0 000 2z\"/><path d=\"M6.447 12.052a.25.25 0 01-.083-.335l1.2-2.093A.418.418 0 007.2 9H2.8a.417.417 0 00-.361.624L3.627 11.7a.25.25 0 01-.1.344C2.584 12.564 0 13.8 0 16.917A4.74 4.74 0 004.931 21.5 4.865 4.865 0 0010 16.917c0-3.032-2.6-4.258-3.553-4.865zM5.5 18.985V19a.5.5 0 01-1 0H4a.5.5 0 010-1h1.355a.394.394 0 00.146-.761l-1.374-.55a1.389 1.389 0 01.373-2.674V14a.5.5 0 011 0H6a.5.5 0 010 1H4.645a.394.394 0 00-.146.761l1.374.55a1.389 1.389 0 01-.373 2.674zM16.5 6.5a1 1 0 001 1H19a1 1 0 000-2h-1.5a1 1 0 00-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});