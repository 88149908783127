define("ember-svg-jar/inlined/disability-blind-disabled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-blind-disabled</title><path d=\"M8.327 13.308a14.094 14.094 0 01-6.082-4.391.5.5 0 010-.628A12.684 12.684 0 0112 3.25a12.57 12.57 0 019.516 4.74 2.981 2.981 0 01.537.942 1 1 0 00.926.671H23a1 1 0 001-.981 1.976 1.976 0 00-.39-1.214C22.223 5.557 18.321 1.25 12 1.25S1.78 5.557.4 7.408a1.956 1.956 0 000 2.356A16.02 16.02 0 007.674 15.2a1 1 0 10.653-1.89z\"/><path d=\"M15.88 7.864a.5.5 0 00.394-.647 4.493 4.493 0 10-6.974 4.96.5.5 0 00.738-.158 8.266 8.266 0 015.842-4.155zM17.25 9.25A6.75 6.75 0 1024 16a6.758 6.758 0 00-6.75-6.75zm-3.956 8.987a.5.5 0 01-.366-.283A4.676 4.676 0 0112.5 16a4.756 4.756 0 014.75-4.75 4.68 4.68 0 011.954.428.5.5 0 01.145.808L13.736 18.1a.5.5 0 01-.442.137zm3.956 2.513a4.666 4.666 0 01-1.955-.429.5.5 0 01-.145-.808l5.613-5.613a.5.5 0 01.808.145A4.666 4.666 0 0122 16a4.756 4.756 0 01-4.75 4.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});