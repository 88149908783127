define("ember-svg-jar/inlined/appliances-aspirator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>appliances-aspirator</title><path d=\"M7.5 4.5h9A.5.5 0 0017 4V1a1 1 0 00-1-1H8a1 1 0 00-1 1v3a.5.5 0 00.5.5zM7.5 24a1 1 0 01-.655-1.756.274.274 0 00.049-.36l-.216-.315a2.24 2.24 0 01.167-3.325 1 1 0 111.31 1.512.274.274 0 00-.049.36l.216.315a2.24 2.24 0 01-.167 3.325A1 1 0 017.5 24zM12 24a1 1 0 01-.655-1.756.274.274 0 00.049-.36l-.216-.315a2.24 2.24 0 01.167-3.325 1 1 0 111.31 1.512.274.274 0 00-.049.36l.216.315a2.24 2.24 0 01-.167 3.325A1 1 0 0112 24zM16.5 24a1 1 0 01-.655-1.756.274.274 0 00.049-.36l-.216-.315a2.24 2.24 0 01.167-3.325 1 1 0 011.31 1.512.274.274 0 00-.049.36l.216.315a2.24 2.24 0 01-.167 3.325A1 1 0 0116.5 24zM24 13.42a2.5 2.5 0 00-.873-1.9l-6.3-5.4A.492.492 0 0016.5 6h-9a.492.492 0 00-.325.121l-6.3 5.4A2.5 2.5 0 000 13.42V16a1 1 0 001 1h22a1 1 0 001-1zM6.7 15.5H2.75a.75.75 0 010-1.5H6.7a.75.75 0 010 1.5zm9.8 0a1 1 0 111-1 1 1 0 01-1 1zm3.5 0a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});