define("ember-svg-jar/inlined/beard-style-razor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beard-style-razor</title><path d=\"M23.972 12.237a3.411 3.411 0 00-.88-1.51l-.3-.36a.267.267 0 00-.466.107 11.25 11.25 0 01-.841 2.249.5.5 0 00.092.569 1.774 1.774 0 001.195.645c.06 0 .11-.01.17-.01a1.326 1.326 0 001.03-1.69zM9.576 9.017a1.652 1.652 0 002.11-.96l.02-.05 3.451 1.281a.5.5 0 00.63-.262c.059-.131.116-.265.169-.4A4.539 4.539 0 0117.042 6.8a.5.5 0 00-.17-.831L2.416.6a1.086 1.086 0 00-1.38.63l-.93 2.5a1.635 1.635 0 00.96 2.11z\"/><path d=\"M19.142 7.467c-1.153 0-1.559.858-1.87 1.721-1.946 4.864-6.731 7.654-13.13 7.654a3.313 3.313 0 000 6.625c9.3 0 16.906-10.64 16.906-14.093a1.909 1.909 0 00-1.906-1.907z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});