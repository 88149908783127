define("ember-svg-jar/inlined/style-three-pin-train-bus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-train-bus</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 2.293 1.163 4.711 2.7 6.908a.5.5 0 00.861-.072l.223-.471a.75.75 0 011.355.642l-.759 1.6a.5.5 0 00.072.539 44.54 44.54 0 005.085 5 1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM5 7a1.5 1.5 0 011.5-1.5h3a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-4A.5.5 0 015 9zm5 7a.5.5 0 01-.5.5h-3A1.5 1.5 0 015 13v-2a.5.5 0 01.5-.5h4a.5.5 0 01.5.5zm2.75 4.75a.75.75 0 01-1.5 0V2.25a.75.75 0 011.5 0zm8-8.75a.75.75 0 01-.75-.75v-.5a.25.25 0 00-.25-.25h-.25a.5.5 0 00-.5.5v5a1.493 1.493 0 01-.75 1.292.5.5 0 00-.25.433v.025a.75.75 0 01-1.5 0 .25.25 0 00-.25-.25H14.5a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h3a.5.5 0 00.5-.5V7.5a.5.5 0 00-.5-.5h-3a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2A2.5 2.5 0 0119 6.5a.5.5 0 00.5.5h.25a1.752 1.752 0 011.75 1.75v.5a.75.75 0 01-.75.75z\"/><circle cx=\"7\" cy=\"12.5\" r=\"1\"/><path d=\"M7.5 6A1.5 1.5 0 006 7.5a.5.5 0 001 0 .5.5 0 01.5-.5.5.5 0 000-1z\"/><circle cx=\"17\" cy=\"13\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});