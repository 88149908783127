define("ember-svg-jar/inlined/spa-towel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>spa-towel</title><path d=\"M23.96 19.68L21.52 3.82A4.471 4.471 0 0017.07 0H6.93a4.471 4.471 0 00-4.45 3.82L.04 19.68a5.115 5.115 0 00-.04.57A3.751 3.751 0 003.75 24h16.5A3.751 3.751 0 0024 20.25a5.115 5.115 0 00-.04-.57zm-1.46.57a2.253 2.253 0 01-2.25 2.25h-15a.75.75 0 010-1.5h14a.75.75 0 000-1.5h-14A2.253 2.253 0 003 21.75a.374.374 0 01-.6.3A2.248 2.248 0 013.75 18h16.5a2.253 2.253 0 012.25 2.25zm-6.043-9.737a4.316 4.316 0 01-.325 1.63.5.5 0 01-.643.279 7.651 7.651 0 01-2.064-1.234.5.5 0 01-.067-.71 19.768 19.768 0 001.577-2.164.5.5 0 01.87.045 5.245 5.245 0 01.652 2.154zM11.138 3.9a1.1 1.1 0 011.663 0 36.9 36.9 0 011.491 1.97.5.5 0 01.03.527 19.443 19.443 0 01-1.96 2.935.5.5 0 01-.774 0A19.757 19.757 0 019.618 6.4a.5.5 0 01.03-.528 33.259 33.259 0 011.49-1.972zm-3 4.462a.5.5 0 01.87-.044 18.436 18.436 0 002.427 3.1l.008.012.015.009a10.807 10.807 0 002.757 2.056.5.5 0 01.034.875 4.4 4.4 0 01-2.279.63 4.492 4.492 0 01-4.488-4.487 5.253 5.253 0 01.654-2.155z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});