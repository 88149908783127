define("ember-svg-jar/inlined/pencil-circle-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pencil-circle-alternate</title><path d=\"M13.862 8.226a.514.514 0 00-.707 0l-5.892 5.892a.523.523 0 00-.111.168l-1.116 2.792a.5.5 0 00.11.539.51.51 0 00.54.11l2.791-1.116a.483.483 0 00.168-.111l5.893-5.892a.5.5 0 00.146-.353.505.505 0 00-.146-.354zM16.58 5.5a1.677 1.677 0 00-1.191.493l-.838.838a.5.5 0 000 .707l1.675 1.675a.5.5 0 00.707 0l.838-.837A1.685 1.685 0 0016.58 5.5z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});