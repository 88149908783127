define("ember-svg-jar/inlined/medical-app-smartphone-touch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-app-smartphone-touch</title><path d=\"M6.42 20.279a3.736 3.736 0 01-.368-.529.5.5 0 00-.433-.251H2.5A.5.5 0 012 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v1.759a.5.5 0 00.5.5 3.934 3.934 0 01.887.1A.5.5 0 0016 5.37V3a3.009 3.009 0 00-3-3H3a3.009 3.009 0 00-3 3v18a3.009 3.009 0 003 3h5.445a.5.5 0 00.384-.82z\"/><path d=\"M6.75 12.5h1.5a.755.755 0 00.75-.75v-1a.25.25 0 01.25-.25h1a.25.25 0 00.25-.25v-.49a3.886 3.886 0 01.315-1.535.5.5 0 00-.23-.64.72.72 0 00-.335-.085h-1A.25.25 0 019 7.249v-1a.755.755 0 00-.75-.75h-1.5a.755.755 0 00-.75.75v1a.25.25 0 01-.25.25h-1a.755.755 0 00-.75.75v1.5a.755.755 0 00.75.75h1a.25.25 0 01.25.25v1a.755.755 0 00.75.751zM19 15h-2V9.5a2.5 2.5 0 00-5 0v7.061l-.578-.7A2.5 2.5 0 007.9 15.54a2.5 2.5 0 00-.324 3.521l3.806 4.579a1 1 0 001.538-1.28l-3.805-4.579a.5.5 0 01.769-.638l2.347 2.822a1 1 0 001.769-.64V9.5a.5.5 0 111 0V16a1 1 0 001 1h3a3 3 0 013 3v3a1 1 0 002 0v-3a5.006 5.006 0 00-5-5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});