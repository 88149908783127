define("ember-svg-jar/inlined/job-choose-cadidate-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>job-choose-cadidate-1</title><path d=\"M17.5 21.332A3.334 3.334 0 0014.167 18H12.75a.25.25 0 01-.25-.25V13.5a1.5 1.5 0 10-3 0v6.465a.25.25 0 01-.411.191l-.878-.735a1.25 1.25 0 00-1.922 1.6l2.329 2.8A.5.5 0 009 24h8a.5.5 0 00.5-.5zM5.5 5h1a.75.75 0 000-1.5h-1a.75.75 0 000 1.5zM7.135 1.932A.75.75 0 007.977.69L7.148.129a.75.75 0 10-.841 1.242zM16.445 2.061a.749.749 0 00.42-.129l.828-.561a.75.75 0 10-.841-1.242l-.829.561a.75.75 0 00.422 1.371zM19.25 4.251a.75.75 0 00-.75-.75h-1a.75.75 0 100 1.5h1a.75.75 0 00.75-.75zM12 5.261A2.381 2.381 0 109.619 2.88 2.381 2.381 0 0012 5.261zM15.749 10.5a.51.51 0 00.5-.588 4.287 4.287 0 00-8.492 0 .51.51 0 00.5.588zM3 11.333a1.667 1.667 0 10-1.667-1.667A1.667 1.667 0 003 11.333zM3 12a3 3 0 00-2.939 2.4.506.506 0 00.5.6h4.882a.506.506 0 00.5-.6A3 3 0 003 12zM21 11.333a1.667 1.667 0 10-1.667-1.667A1.667 1.667 0 0021 11.333zM21 12a3 3 0 00-2.939 2.4.506.506 0 00.5.6h4.886a.506.506 0 00.5-.6A3 3 0 0021 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});