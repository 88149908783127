define("ember-svg-jar/inlined/accounting-coins-bill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accounting-coins-bill</title><path d=\"M22.632.5h-21a1 1 0 00-1 1v6.706a.251.251 0 00.1.2.248.248 0 00.22.039A16.775 16.775 0 015.368 8a22.92 22.92 0 013 .154.25.25 0 00.282-.275 3.385 3.385 0 01-.018-.379 3.5 3.5 0 117 0c0 .626-.544 3.544-3.509 3.485a.252.252 0 00-.18.072.249.249 0 00-.075.178v3.015a.25.25 0 00.25.25h10.514a1 1 0 001-1v-12a1 1 0 00-1-1zM4.132 5a1 1 0 111-1 1 1 0 01-1 1zm16 7a1 1 0 111-1 1 1 0 01-1 1zM5.368 21A15.669 15.669 0 01.7 20.366a.249.249 0 00-.333.235v1.9c0 .553 2.238 1 5 1s5-.447 5-1V20.6a.25.25 0 00-.334-.236A15.667 15.667 0 015.368 21zM.7 16.366a.249.249 0 00-.333.235v2.375A.248.248 0 00.5 19.2a11.807 11.807 0 004.866.8 11.718 11.718 0 004.866-.807.25.25 0 00.134-.221V16.6a.25.25 0 00-.334-.236A15.667 15.667 0 015.368 17 15.669 15.669 0 01.7 16.366z\"/><path d=\"M.368 10.5v.476A.248.248 0 00.5 11.2a11.807 11.807 0 004.866.8 11.718 11.718 0 004.866-.807.25.25 0 00.134-.221V10.5c0-.552-2.239-1-5-1s-4.998.448-4.998 1zM.7 12.366a.249.249 0 00-.333.235v2.375A.248.248 0 00.5 15.2a11.807 11.807 0 004.866.8 11.718 11.718 0 004.866-.807.25.25 0 00.134-.221V12.6a.25.25 0 00-.334-.236A15.667 15.667 0 015.368 13 15.669 15.669 0 01.7 12.366z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});