define("ember-svg-jar/inlined/science-fiction-alien", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-fiction-alien</title><path d=\"M17.652 5.394a2 2 0 10-2.221-.663.252.252 0 01.039.237l-.505 1.445a.248.248 0 01-.137.147.251.251 0 01-.2 0 6.446 6.446 0 00-1.434-.448.25.25 0 01-.2-.242l-.027-1.976a.252.252 0 01.114-.214 2 2 0 10-2.168 0 .252.252 0 01.114.214L11 5.869a.25.25 0 01-.2.242 6.4 6.4 0 00-1.422.443.253.253 0 01-.2 0 .247.247 0 01-.136-.149l-.494-1.45a.25.25 0 01.038-.233 2 2 0 10-2.226.672c.039.013.08.025.12.036a.253.253 0 01.171.161l.731 2.146a.25.25 0 01-.056.255A6.475 6.475 0 005.492 12.5v9.093a2.407 2.407 0 004.764.491l.426-2.046a1.3 1.3 0 012.558.021L13.612 22a2.462 2.462 0 004.88-.464V12.5A6.477 6.477 0 0016.676 8a.249.249 0 01-.056-.256l.752-2.156a.251.251 0 01.17-.158l.11-.036zM14.992 12a3 3 0 11-3-3 3 3 0 013 3z\"/><circle cx=\"11.992\" cy=\"12\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});