define("ember-svg-jar/inlined/real-estate-app-building-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-app-building-laptop</title><path d=\"M23.5 20h-.25a.5.5 0 01-.5-.5v-1.75a1 1 0 00-2 0v1.75a.5.5 0 01-.5.5H3.75a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5h1a1 1 0 000-2h-1a2.5 2.5 0 00-2.5 2.5v11a.5.5 0 01-.5.5H.5a.5.5 0 00-.5.5A3.5 3.5 0 003.5 24h17a3.5 3.5 0 003.5-3.5.5.5 0 00-.5-.5z\"/><path d=\"M8 18.062a2.054 2.054 0 00.787-.157l2.668-1.1a.5.5 0 01.385 0 8.747 8.747 0 10-4.566-4.455.5.5 0 010 .417l-1.132 2.421a2.016 2.016 0 00.4 2.269A2.054 2.054 0 008 18.062zm.223-2.6l1.17-2.507a1 1 0 00-.034-.911A6.751 6.751 0 1115.25 15.5a6.675 6.675 0 01-3.107-.757 1.007 1.007 0 00-.842-.037L8.54 15.8a.251.251 0 01-.319-.339z\"/><path d=\"M12.25 13.25h1.5a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-7.5a.5.5 0 00-.276-.447l-3-1.5a.5.5 0 00-.448 0l-3 1.5a.5.5 0 00-.276.447v7.5a.5.5 0 00.5.5zm1.5-7.5h3a.5.5 0 010 1h-3a.5.5 0 010-1zm0 2.5h3a.5.5 0 010 1h-3a.5.5 0 010-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});