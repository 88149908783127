define("ember-svg-jar/inlined/plant-cacti", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plant-cacti</title><path d=\"M21.5 18.5h-19a1.522 1.522 0 00-.9 2.731C2.7 22.063 5.926 24 12 24s9.3-1.937 10.4-2.769a1.508 1.508 0 00.6-1.2V20a1.5 1.5 0 00-1.5-1.5zM6 15H5a.75.75 0 000 1.5h1a.5.5 0 01.5.5.5.5 0 00.5.5h5a.5.5 0 00.5-.5.5.5 0 01.5-.5h1a.75.75 0 000-1.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1a.75.75 0 000-1.5h-1.146a.5.5 0 01-.479-.357 2.97 2.97 0 00-.144-.386.5.5 0 01.094-.554l.966-1.003a.75.75 0 00-1.082-1.039l-.943.982a.5.5 0 01-.562.112 3 3 0 00-2.408 0 .5.5 0 01-.562-.112l-.943-.982A.75.75 0 005.709 9.2l.966 1a.5.5 0 01.094.554 2.97 2.97 0 00-.144.386.5.5 0 01-.479.357H5A.75.75 0 005 13h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5z\"/><path d=\"M8.5 7h.471a.529.529 0 01.529.529.5.5 0 00.445.5 4.012 4.012 0 01.555.1.5.5 0 00.485-.138l.5-.523a1.75 1.75 0 112.528 2.422l-.179.186a.25.25 0 00.174.423 1.75 1.75 0 01.838 3.282.25.25 0 000 .438 1.748 1.748 0 01.31 2.844.25.25 0 00.166.437H18a.5.5 0 00.5-.5v-1.572a.5.5 0 01.5-.5h.5a.75.75 0 000-1.5H19a.5.5 0 01-.5-.5v-1.5a.5.5 0 01.5-.5h.5a.75.75 0 000-1.5H19a.5.5 0 01-.5-.5V7.5A.5.5 0 0119 7h.5a.75.75 0 000-1.5h-.659a.5.5 0 01-.484-.375 4.448 4.448 0 00-.481-1.159.5.5 0 01.076-.607l.467-.468a.75.75 0 10-1.06-1.061l-.467.467a.5.5 0 01-.608.077 4.485 4.485 0 00-1.159-.482.5.5 0 01-.375-.483V.75a.75.75 0 00-1.5 0v.659a.5.5 0 01-.375.483 4.485 4.485 0 00-1.159.482.5.5 0 01-.608-.077l-.467-.467a.75.75 0 00-1.06 1.061l.467.468a.5.5 0 01.076.607 4.448 4.448 0 00-.481 1.159.5.5 0 01-.484.375H8.5a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});