define("ember-svg-jar/inlined/heavy-equipment-mortar-truck", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>heavy-equipment-mortar-truck</title><path d=\"M20.978 10.363a.25.25 0 00.3.117l1.315-.438a.5.5 0 00.288-.7L21.3 6.21a1.414 1.414 0 00-1.174-.71h-1.4a.251.251 0 00-.216.123.248.248 0 000 .247zM12.572 5.632a.251.251 0 00-.22-.132h-.278L11.3 4.336A2.991 2.991 0 008.8 3H4.872a1 1 0 000 2H8.8a1 1 0 01.832.445l.609.913 3.139 6.3a.5.5 0 00.605.252l1.837-.611a.252.252 0 00.153-.145.248.248 0 00-.012-.211zM16.662 5.63a.25.25 0 00-.219-.13h-1.82a.249.249 0 00-.22.368l3.047 5.667a.249.249 0 00.3.119l1.6-.532a.25.25 0 00.14-.358z\"/><path d=\"M23.21 11.073L12.9 14.521a.251.251 0 01-.306-.132L9.286 7.244A1.36 1.36 0 008.122 6.5h-3a1.278 1.278 0 00-1.144.792L2.69 10.727l-2 2.069A1.7 1.7 0 00.122 14v3.5a1 1 0 001 1h.838a.25.25 0 00.243-.193 3 3 0 015.837 0 .25.25 0 00.243.193h7.678a.25.25 0 00.243-.193 3 3 0 014.657-1.747.249.249 0 00.2.041.251.251 0 00.164-.12l2.475-4.297c.468-.818-.078-1.247-.49-1.111zM5.162 10.51a.419.419 0 01-.39-.576l.5-1.68a.419.419 0 01.39-.264h1.955a.419.419 0 01.39.264l.705 1.68a.42.42 0 01-.39.576z\"/><path d=\"M5.122 17a2 2 0 102 2 2 2 0 00-2-2\"/><circle cx=\"19.122\" cy=\"19\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});