define("ember-svg-jar/inlined/dress-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dress-1</title><path d=\"M7.459 7.8a.5.5 0 00.417.427A25.073 25.073 0 0012 8.581a25.083 25.083 0 004.124-.354.5.5 0 00.417-.427c.045-.336.046-.284 1.142-6.333a1 1 0 00-.748-1.15L15.751.03a1 1 0 00-1.173.626l-.65 2a.5.5 0 01-.471.346l-2.894.029a.5.5 0 01-.48-.345L9.409.621A1 1 0 008.248.03L7.065.317a1 1 0 00-.748 1.15C7.5 8 7.417 7.483 7.459 7.8zM20.92 20.51l-.015-.039-4.027-9.171a7.54 7.54 0 01-.2-.692.5.5 0 00-.566-.378 25.108 25.108 0 01-4.112.354 24.966 24.966 0 01-4.11-.352.5.5 0 00-.566.379 6.81 6.81 0 01-.187.652L3.08 20.51a1.487 1.487 0 00.809 1.839A21.312 21.312 0 0012 24a21.312 21.312 0 008.111-1.651 1.487 1.487 0 00.809-1.839z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});