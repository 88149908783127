define("ember-svg-jar/inlined/farmer-s-market-kiosk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>farmer&amp;apos;s-market-kiosk</title><path d=\"M21.5 9.5a1 1 0 00-1 1v11a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5v-11a1 1 0 00-2 0V22a2 2 0 002 2h17a2 2 0 002-2V10.5a1 1 0 00-1-1z\"/><path d=\"M11.008 8.806a.5.5 0 00.242-.428V.5a.5.5 0 00-.5-.5H7.426a.5.5 0 00-.485.379l-.914 3.654a1.99 1.99 0 00-.06.486v3.465a.5.5 0 00.18.384 4.2 4.2 0 004.861.438zM4.181 8.05a.5.5 0 00.286-.45V4.088L5.334.621A.5.5 0 004.849 0H2.732A1.042 1.042 0 001.8.577L.105 3.963A1 1 0 000 4.41v.807A3 3 0 004.181 8.05zM23.894 3.963L22.2.577A1.042 1.042 0 0021.268 0h-2.117a.5.5 0 00-.485.621l.867 3.467V7.6a.5.5 0 00.285.452A3 3 0 0024 5.217V4.41a.993.993 0 00-.106-.447zM17.853 8.37a.5.5 0 00.18-.384V4.519a1.99 1.99 0 00-.06-.486L17.059.379A.5.5 0 0016.574 0H13.25a.5.5 0 00-.5.5v7.876a.5.5 0 00.242.428 4.2 4.2 0 004.861-.434zM10.737 13.086a2.932 2.932 0 00-2.79.629c-.838.865-.914 2.405-.2 4.119.5 1.218 1.517 2.647 2.723 2.666a1.943 1.943 0 001.193-.423.5.5 0 01.607 0 1.959 1.959 0 001.2.424c1.194-.019 2.207-1.448 2.711-2.666.71-1.714.634-3.254-.2-4.119A2.558 2.558 0 0013.965 13a.25.25 0 01-.162-.447 1.669 1.669 0 01.716-.323 1 1 0 10-.393-1.961 3.769 3.769 0 00-2.831 2.5.5.5 0 01-.558.317z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});