define("ember-svg-jar/inlined/railroad-wagon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>railroad-wagon</title><path d=\"M22.817 11.225A2.224 2.224 0 0122 9.5V4.528a.25.25 0 01.25-.25H23a1 1 0 100-2H11.5a1 1 0 000 2h.25a.25.25 0 01.25.25v6.154a.25.25 0 01-.25.25h-4.5a.25.25 0 01-.25-.25v-2a.25.25 0 01.25-.25h.25a1 1 0 000-2h-4a1 1 0 000 2h.25a.25.25 0 01.25.25v2a.25.25 0 01-.25.25H3c-1.508 0-1.625 3.061-1.625 4a9.618 9.618 0 00.355 2.721.25.25 0 01-.085.277l-1.252.957a1 1 0 101.214 1.589l1.3-.993a.253.253 0 01.152-.051h.029a.124.124 0 00.125-.12A3.285 3.285 0 019.288 17.7a.249.249 0 00.424 0 3.281 3.281 0 015.6.03.25.25 0 00.212.12.253.253 0 00.214-.118 3.238 3.238 0 015.926 1.007.248.248 0 00.244.2H23.5a.5.5 0 00.5-.5v-4.714a3.222 3.222 0 00-1.183-2.5zm-5.317-.793a.5.5 0 01-.5.5h-2.5a.5.5 0 01-.5-.5v-4.9a.5.5 0 01.5-.5H17a.5.5 0 01.5.5z\"/><circle cx=\"6.5\" cy=\"19.432\" r=\"2.29\"/><circle cx=\"12.5\" cy=\"19.432\" r=\"2.29\"/><circle cx=\"18.5\" cy=\"19.432\" r=\"2.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});