define("ember-svg-jar/inlined/family-home-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-home-1</title><path d=\"M1.574 9.3l10.28-7.2a.251.251 0 01.287 0l10.285 7.2a1 1 0 001.148-1.639L13.142.355a2.015 2.015 0 00-2.287 0L.426 7.661A1 1 0 001.574 9.3zM22.966 15.032a3.971 3.971 0 10-1.351 2.98 3.411 3.411 0 00.545.7 1 1 0 001.405-1.424c-.148-.146-.599-.588-.599-2.256zM19 17a1.969 1.969 0 01-1.933-1.6.251.251 0 01.191-.29 2.535 2.535 0 001.541-.913.252.252 0 01.4 0 2.531 2.531 0 001.54.913.25.25 0 01.191.29A1.968 1.968 0 0119 17zM4.5 19a3.948 3.948 0 10-3.947-3.948A3.951 3.951 0 004.5 19zm-1.873-4.482A.251.251 0 013 14.372a4.859 4.859 0 002.487.68 4.912 4.912 0 00.669-.046.252.252 0 01.283.278 1.948 1.948 0 11-3.807-.766zM19 19.633a4.539 4.539 0 00-2.846 1 .5.5 0 00-.089.689q.156.208.294.437a.5.5 0 00.428.242h5.251a.5.5 0 00.374-.832A4.552 4.552 0 0019 19.633zM4.5 19.6a4.521 4.521 0 00-3.431 1.57.5.5 0 00.378.83h5.765a.5.5 0 00.429-.242c.041-.068.083-.134.126-.2a.5.5 0 00-.063-.63A4.521 4.521 0 004.5 19.6zM13.372 20.516a.252.252 0 01-.164-.2.249.249 0 01.1-.238 2.251 2.251 0 10-2.619 0 .251.251 0 01-.063.439 4.227 4.227 0 00-2.607 2.839A.5.5 0 008.5 24h7a.5.5 0 00.479-.644 4.229 4.229 0 00-2.607-2.84z\"/><path d=\"M13.727 6.524a1.835 1.835 0 00-1.431.341.5.5 0 01-.592 0 1.834 1.834 0 00-1.431-.341A1.941 1.941 0 009.2 9.805l2.073 2.163a1 1 0 001.446 0L14.8 9.805a1.941 1.941 0 00-1.069-3.281z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});