define("ember-svg-jar/inlined/elephant-head-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>elephant-head-1</title><path d=\"M23.905 20.5a.5.5 0 00-.508-.2.9.9 0 01-1.126-.646.5.5 0 00-.322-.339.5.5 0 00-.46.074 2.38 2.38 0 01-2.327.594c-.5-.252-.791-.92-.849-1.867a9.148 9.148 0 004.878-2.125 1 1 0 00-.687-1.722 15.508 15.508 0 01-4.215-.916V7.289a4.85 4.85 0 00-1.7-3.554 6.561 6.561 0 00-4.367-1.755A4.784 4.784 0 008.4 0a4.678 4.678 0 00-3.013 1.141A5.773 5.773 0 003.5 5.5a5.655 5.655 0 002.61 4.889.75.75 0 01-.769 1.287A7.158 7.158 0 012 5.5a7.634 7.634 0 01.476-2.663.255.255 0 00-.029-.23.252.252 0 00-.207-.107H.5A.5.5 0 000 3v20.5a.5.5 0 00.5.5H5a.5.5 0 00.5-.5v-1a6.571 6.571 0 014.34-6.485 13.146 13.146 0 005.168 2C15.1 20.156 16.43 23 19.289 23c3.458 0 4.6-1.879 4.643-1.959a.5.5 0 00-.027-.541zM14.289 8.039a1.25 1.25 0 11-1.25-1.25 1.25 1.25 0 011.25 1.25zm-3.23 6.4a1.069 1.069 0 111.1-1.827 22.009 22.009 0 004.026 2.1 25.8 25.8 0 003.333 1.09 8.219 8.219 0 01-3.968.278 11.159 11.159 0 01-4.491-1.638z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});