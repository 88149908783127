define("ember-svg-jar/inlined/love-heart-balloon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-heart-balloon</title><path d=\"M11.544 12.805a.632.632 0 00.913 0l6.012-6.271a3.827 3.827 0 00-5.413-5.412L12 2.178l-1.056-1.056a3.827 3.827 0 10-5.412 5.412z\"/><path d=\"M16.5 13a11.666 11.666 0 00-4.019 1.576 1 1 0 01-.962 0A11.643 11.643 0 007.5 13a3 3 0 000 6 3.452 3.452 0 00.966-.183.25.25 0 01.262.4l-.98 1.121a1 1 0 101.5 1.316l1.31-1.5a.25.25 0 01.438.164V23a1 1 0 002 0v-2.674a.25.25 0 01.438-.164l1.31 1.5a1 1 0 101.5-1.316l-.981-1.121a.251.251 0 01.263-.4A3.452 3.452 0 0016.5 19a3 3 0 000-6zm-7.02 3.224A7.741 7.741 0 017.5 17a1 1 0 010-2 7.729 7.729 0 011.981.776.25.25 0 010 .448zM16.5 17a7.729 7.729 0 01-1.981-.776.249.249 0 010-.448A7.741 7.741 0 0116.5 15a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});