define("ember-svg-jar/inlined/lucide-usb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"4\" cy=\"20\" r=\"1\"/><circle cx=\"10\" cy=\"7\" r=\"1\"/><path d=\"M4 20L19 5M21 3l-3 1 2 2 1-3zM10 7l-5 5 2 5M10 14l5 2 4-4\"/><path d=\"M18 12l1-1 1 1-1 1-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});