define("ember-svg-jar/inlined/kitchenware-whisk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchenware-whisk</title><path d=\"M8.852 16.567a.5.5 0 010 .707l-6.288 6.287a1.5 1.5 0 01-2.119-2.119l6.287-6.289a.5.5 0 01.706 0zM22.459 1.535C19.814-1.11 14.651-.251 10.95 3.45s-4.56 8.864-1.915 11.509 7.808 1.786 11.509-1.915S25.1 4.18 22.459 1.535zM11.3 13.028a.25.25 0 01-.337-.338 23.376 23.376 0 014.077-5.149 23.38 23.38 0 015.15-4.074.249.249 0 01.337.337 23.38 23.38 0 01-4.074 5.15 23.42 23.42 0 01-5.153 4.074zm1.059-8.165A9.533 9.533 0 0117.8 2.024a.25.25 0 01.166.456 32.973 32.973 0 00-7.986 7.984.25.25 0 01-.456-.167 9.536 9.536 0 012.839-5.434zM13.7 14.47a.25.25 0 01-.167-.456 32.008 32.008 0 004.337-3.647 32.048 32.048 0 003.647-4.336.25.25 0 01.456.166 9.533 9.533 0 01-2.839 5.434A9.533 9.533 0 0113.7 14.47z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});