define("ember-svg-jar/inlined/eat-vending-machine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>eat-vending-machine</title><path d=\"M23.951 1.5a1.5 1.5 0 00-1.5-1.5h-9.5a1.494 1.494 0 00-1.132.526 3.94 3.94 0 01-.3 5.293.251.251 0 00-.068.181v.25a.252.252 0 00.119.214 4.3 4.3 0 011.362 1.291l4.334 6.164a2.969 2.969 0 01.417 2.282.25.25 0 00.244.3H20.7a1 1 0 011 1v3a1 1 0 01-1 1h-6a1 1 0 01-1-1v-1.967a.25.25 0 00-.149-.229 3.008 3.008 0 01-1.271-1.053l-.377-.527a.25.25 0 00-.453.145v5.63a1.5 1.5 0 001.5 1.5h9.5a1.5 1.5 0 001.5-1.5zM16.7 8a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5V7a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm0-4a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5V3a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm5 8a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm0-4a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5V7a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm0-4a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5V3a.5.5 0 01.5-.5h2a.5.5 0 01.5.5z\"/><path d=\"M1.05 14.589a1.25 1.25 0 001.474-.976l.77-3.782a.5.5 0 01.49-.4.492.492 0 01.1.01.5.5 0 01.39.59l-.99 4.869v7.85a1.25 1.25 0 002.5 0V16.5a.5.5 0 011 0v6.25a1.25 1.25 0 102.5 0V15.5l.681-3.347a.251.251 0 01.449-.1l3.1 4.34a1.511 1.511 0 00.951.642 1.447 1.447 0 00.295.03 1.518 1.518 0 00.832-.249 1.492 1.492 0 00.423-2.078l-4.31-6.129A3 3 0 009.8 7.329l-4.29-.873A4 4 0 00.8 9.577l-.72 3.538a1.25 1.25 0 00.97 1.474z\"/><circle cx=\"8.701\" cy=\"3\" r=\"2.5\"/><path d=\"M18.865 20.751a1.5 1.5 0 00-.55-2.048l-.866-.5a1.5 1.5 0 10-1.5 2.6l.867.5a1.5 1.5 0 002.048-.55z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});