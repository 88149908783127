define("ember-svg-jar/inlined/tools-hammer-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-hammer-3</title><path d=\"M11.486 11.118a.25.25 0 00-.353 0L1.683 20.5a2 2 0 00-.008 2.828 2.136 2.136 0 002.828.009l9.452-9.388a.251.251 0 000-.354zM22.032 11.157l-5.167-5.2a3.256 3.256 0 00-2.88-.924.241.241 0 01-.217-.064l-.831-.837a.2.2 0 01.008-.306l.577-.573a1 1 0 000-1.415L12.118.423A1 1 0 0010.7.418L7.158 3.942a1 1 0 00-.005 1.415l1.41 1.42a1.1 1.1 0 001.414 0s.5-.5.567-.563a.179.179 0 01.248.014l.846.834a.237.237 0 01.063.225 2.988 2.988 0 00.789 2.781l3.341 3.357a.5.5 0 00.532.106c1.633-.66 2.754-1.05 4.009.212l1.255 1.263a.506.506 0 00.777-.073 3 3 0 00-.372-3.776z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});