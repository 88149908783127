define("ember-svg-jar/inlined/color-bucket-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-bucket-1</title><path d=\"M21.868 14.326a1 1 0 00-1.735 0C19.159 16.025 18 18.273 18 19.334a3 3 0 106 0c0-1.059-1.159-3.308-2.132-5.008zM18.544 10.72a.929.929 0 00-.313-1.044L10.645 2.09a1.539 1.539 0 00-2.121 0L6.6 4.011a.25.25 0 01-.22.07l-4.208-.743a1 1 0 10-.347 1.968l7.94 1.4a.25.25 0 01.2.29l-.143.808a.25.25 0 01-.29.2l-5.76-1.012a.249.249 0 00-.22.07L.731 9.883a2.5 2.5 0 000 3.536L5.4 18.09a2.5 2.5 0 003.535 0l6.296-6.29a2.313 2.313 0 011.353-.561h1a.932.932 0 00.96-.519z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});