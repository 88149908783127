define("ember-svg-jar/inlined/social-media-hyves", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-hyves</title><path d=\"M20.62 18.2a3 3 0 00-.77-.06V7.26c0-.91-.13-2.83.16-3.21a2.76 2.76 0 01.5-.25c.5-.21 1-.43 1.17-.89A1.91 1.91 0 0020.46.26c-2.08-.76-6.52.24-7.21 2-.2.51-.25 1.5 1.69 2.31a8.35 8.35 0 00.85.19 5.9 5.9 0 01-.08 1.34 15.69 15.69 0 000 3.83 4.93 4.93 0 01.11 1.2l-7.4 1.2a18.11 18.11 0 01-.07-3.59c0-.62.07-1.26.07-2v-1.2A2.18 2.18 0 019 5.36a2 2 0 001.35-.72 1.75 1.75 0 00.1-2.13c-1-1.25-4.15-.95-6.08-.33-1.14.37-2.52 1-2.57 2.07 0 .69.52 1.28 1.64 1.76a4.88 4.88 0 00.85.19v1.48c0 1.22 0 2.21.07 3.22 0 .79.06 1.58.07 2.5 0 1.8.08 3.86.28 5.58a8.71 8.71 0 01.11 1.33c-1.87.43-1.91 1.42-1.92 1.48-.12 1.19.72 2 2.26 2.19a5.86 5.86 0 00.66 0c2 0 4.8-.86 5-2.16a2.17 2.17 0 00-1.36-2.06 3.55 3.55 0 00-.86 0 20.58 20.58 0 01-.1-3.27c.5-.59 5.86-1.68 7.2-1.49v1.75c0 .66.16 1.82.12 2a1.8 1.8 0 01-.42.07 2.09 2.09 0 00-.55.11 1.5 1.5 0 00-1 1.09 2 2 0 001.81 2.43c2.12.43 6.11-.86 6.52-2.44.08-.48.04-1.3-1.56-1.81z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});