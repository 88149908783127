define("ember-svg-jar/inlined/social-media-xing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-xing</title><path d=\"M10 9.71L7 4.26A.5.5 0 006.56 4H1.5a.5.5 0 00-.44.74l2.85 5.2-3.84 6.3A.5.5 0 00.5 17h5.07a.5.5 0 00.43-.24l4-6.55a.5.5 0 000-.5zM23.93.25A.5.5 0 0023.5 0h-5.37a.5.5 0 00-.43.25l-8 14a.5.5 0 000 .5l5.14 9a.5.5 0 00.43.25h5.37a.5.5 0 00.43-.75l-5-8.77L23.93.75a.5.5 0 000-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});