define("ember-svg-jar/inlined/canoe-person-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>canoe-person-1</title><circle cx=\"16.235\" cy=\"3.886\" r=\"2.5\"/><path d=\"M4.947 17.027a2.508 2.508 0 012.126 1.179 2.115 2.115 0 001.488.874 2.788 2.788 0 001.909-1.045 2.617 2.617 0 011.282-.886.248.248 0 00.13-.095l2.508-3.583a.25.25 0 00-.205-.394H2.91a1.018 1.018 0 00-.888.537 1 1 0 00.047 1l1.605 2.518a.249.249 0 00.308.1 2.452 2.452 0 01.965-.205z\"/><path d=\"M13.456 11.207a1.51 1.51 0 011.824-.444l1.407.656a.25.25 0 01.1.37l-3.529 5.042a.248.248 0 00-.035.216.253.253 0 00.15.161 2.471 2.471 0 011.363 1.244 1.279 1.279 0 001.051.627 1.863 1.863 0 001.476-.846 2.418 2.418 0 012.137-1.206 2.508 2.508 0 012.127 1.18c.113.17.735.915 1.3.865a2.359 2.359 0 01.625.04.251.251 0 00.3-.246v-5.29a.5.5 0 00-.5-.5H18.3a.25.25 0 01-.2-.393l.376-.536a1.5 1.5 0 00.765-2.847l-2.692-1.256a4.475 4.475 0 00-5.47 1.334l-1.766 2.3a.25.25 0 00.2.4h3.154a.251.251 0 00.2-.1z\"/><path d=\"M22.93 20.568A3.382 3.382 0 0120.255 19a1.048 1.048 0 00-1.716.022 3.372 3.372 0 01-2.753 1.558 2.8 2.8 0 01-2.4-1.481 1.045 1.045 0 00-1.714-.16 4.3 4.3 0 01-3.112 1.64A3.555 3.555 0 015.8 19a1.04 1.04 0 00-1.638-.091c-.014.018-1.451 1.813-3.11 1.7A1.015 1.015 0 000 21.557a1 1 0 00.946 1.051c.075 0 .148.006.222.006A5.835 5.835 0 004.9 21.007a5.238 5.238 0 003.66 1.572 5.857 5.857 0 003.81-1.5 4.576 4.576 0 003.416 1.5 5.155 5.155 0 003.622-1.5 4.788 4.788 0 003.661 1.482 1 1 0 10-.139-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});