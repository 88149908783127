define("ember-svg-jar/inlined/office-file-doc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-file-doc</title><path d=\"M6.451 13.327c-.1-.046-.326-.048-.326.164v3.978a.237.237 0 00.327.209 2.374 2.374 0 000-4.351zM17.5 6h3.9a.25.25 0 00.177-.427L16.427.425A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6zM12 13.123a.877.877 0 00-.875.875v3a.875.875 0 001.75 0V14a.877.877 0 00-.875-.877z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.248A.25.25 0 0014.75 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.248A.25.25 0 0021.75 7zM5.5 19.123a.625.625 0 01-.625-.625v-6a.625.625 0 01.625-.625 3.625 3.625 0 010 7.25zM14.125 17a2.125 2.125 0 01-4.25 0v-3a2.125 2.125 0 114.25 0zm4.375.875a.625.625 0 010 1.25 3.625 3.625 0 110-7.25.625.625 0 010 1.25 2.375 2.375 0 100 4.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});