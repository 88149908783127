define("ember-svg-jar/inlined/delivery-man-head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>delivery-man-head</title><path d=\"M7.111 15.4l1.205-5.809a1.831 1.831 0 011.3-1.382l5.655-1.634a1.906 1.906 0 011.064 0l.432.125a.253.253 0 00.219-.041.247.247 0 00.1-.2l.014-4.717C17.1.777 16.512 0 15.791 0L11.3.022a.25.25 0 00-.249.249l-.022 4.682a.558.558 0 01-.286.494.469.469 0 01-.531-.064L8.664 4.054a.25.25 0 00-.325 0L6.792 5.365a.47.47 0 01-.532.059.558.558 0 01-.282-.5L6 .267a.251.251 0 00-.073-.178.246.246 0 00-.177-.073L1.358.022A1.18 1.18 0 00.427.54a2.1 2.1 0 00-.383 1.244L0 13.986c0 .967.579 1.754 1.3 1.757l5.545.026a.248.248 0 00.18-.075.251.251 0 00.072-.181.5.5 0 01.014-.113zM17.118 19.255a1.973 1.973 0 01-2.236 0 .625.625 0 00-.764.99 3.126 3.126 0 003.764 0 .625.625 0 00-.764-.99z\"/><circle cx=\"18\" cy=\"17.5\" r=\".875\"/><circle cx=\"14\" cy=\"17.5\" r=\".875\"/><path d=\"M23.274 14.289c-.288-.083-.578-.149-.867-.218a.251.251 0 01-.187-.192l-.612-2.886a1.5 1.5 0 00-1.04-1.142l-4.137-1.24a1.489 1.489 0 00-.862 0l-4.138 1.237a1.494 1.494 0 00-1.038 1.136l-.613 2.892a.252.252 0 01-.186.192c-.29.069-.579.135-.868.218a1 1 0 00-.687 1.236 1.09 1.09 0 00.761.688.25.25 0 01.2.245V17a7 7 0 0014 0v-.75a1 1 0 00.73-.335.985.985 0 00.231-.39 1 1 0 00-.687-1.236zM16 22a5.006 5.006 0 01-5-5v-1.012a.249.249 0 01.2-.245 23.942 23.942 0 019.6 0 .25.25 0 01.2.245V17a5.006 5.006 0 01-5 5zm-1.5-9.562a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});