define("ember-svg-jar/inlined/multiple-actions-question.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-question</title><path d=\"M14.471 8.4l-.486-.172a1.8 1.8 0 01-.055-1.172 4.647 4.647 0 001.216-3.569A3.22 3.22 0 0012 0a2.989 2.989 0 00-1.035.182.5.5 0 00-.258.725 5.029 5.029 0 01.693 2.578 6.491 6.491 0 01-.772 3.382.5.5 0 00.258.72 6.88 6.88 0 013.45 2.153.5.5 0 00.578.2c.2-.067.4-.127.6-.179a.5.5 0 00.056-.951 61.747 61.747 0 00-1.099-.41z\"/><path d=\"M12.989 10.908a.5.5 0 00.079-.758c-.58-.609-1.675-1.044-3.6-1.752l-.486-.172a1.8 1.8 0 01-.052-1.172 4.647 4.647 0 001.216-3.569A3.22 3.22 0 007 0a3.22 3.22 0 00-3.146 3.485 4.605 4.605 0 001.193 3.538 1.877 1.877 0 010 1.185l-.515.19c-2.01.739-3.46 1.272-3.914 2.179A7.542 7.542 0 000 13.5a.5.5 0 00.5.5h9.511a.5.5 0 00.442-.264 8.034 8.034 0 012.536-2.828zM17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm0 2.25a2.625 2.625 0 01.894 5.093.22.22 0 00-.144.206v.2a.75.75 0 01-1.5 0V18.2a1.256 1.256 0 01.988-1.221 1.125 1.125 0 10-1.363-1.1.75.75 0 01-1.5 0A2.629 2.629 0 0117.5 13.25zm0 7.25a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});