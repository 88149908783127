define("ember-svg-jar/inlined/lift-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lift-1</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-15 3.75H12a.75.75 0 010 1.5H7.5a.75.75 0 010-1.5zm1 17.75a.5.5 0 01-.5.5H2.5a.5.5 0 01-.5-.5V9a.5.5 0 01.5-.5H8a.5.5 0 01.5.5zm8.5 0a.5.5 0 01-.5.5H11a.5.5 0 01-.5-.5V9a.5.5 0 01.5-.5h5.5a.5.5 0 01.5.5zm3.5-2.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm0-5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});