define("ember-svg-jar/inlined/outdoors-shelter-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-shelter-home</title><path d=\"M15 24h4.5a1.5 1.5 0 001.5-1.5v-9.082a.25.25 0 01.377-.215l1.114.658a1 1 0 101.018-1.722l-6.386-3.774A.251.251 0 0117 8.15V1a1 1 0 00-2 0v5.673a.25.25 0 01-.377.215l-2.114-1.249a1 1 0 00-1.018 0l-11 6.5a1 1 0 001.018 1.722l1.114-.661a.25.25 0 01.377.218V22.5A1.5 1.5 0 004.5 24H9a1 1 0 001-1v-6a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v6a1 1 0 001 1z\"/><path d=\"M24 2.75a.5.5 0 00-.5-.5h-1.925a.25.25 0 01-.226-.143A1.5 1.5 0 0020 1.25h-1.5a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h1.775a.25.25 0 01.245.2 1 1 0 00.98.8h2a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});