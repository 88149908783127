define("ember-svg-jar/inlined/real-estate-person-search-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-person-search-house</title><path d=\"M12.749 6.75a.5.5 0 01.5.5v2.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-2.5a.5.5 0 01.5-.5.5.5 0 00.325-.88l-3.5-3a.5.5 0 00-.65 0l-3.5 3a.5.5 0 00.325.88z\"/><path d=\"M24 2a2 2 0 00-2-2H10.5a2 2 0 00-2 2v9.5a2 2 0 002 2h.25a.5.5 0 01.5.5v1.146a1.356 1.356 0 002.311.958l2.457-2.458a.5.5 0 01.353-.146H22a2 2 0 002-2zm-2 9a.5.5 0 01-.5.5h-5.75a1 1 0 00-.707.293l-1.366 1.366a.25.25 0 01-.427-.177V12.5a1 1 0 00-1-1H11a.5.5 0 01-.5-.5V2.5A.5.5 0 0111 2h10.5a.5.5 0 01.5.5zM10.464 22.685a72.32 72.32 0 00-2.911-1.2c-.047-.017-.19-.071-.259-.356a1.419 1.419 0 01.221-1.107 4.367 4.367 0 001.275-3.541A3.243 3.243 0 005.5 13a3.243 3.243 0 00-3.29 3.481 4.368 4.368 0 001.276 3.547 1.428 1.428 0 01.22 1.106c-.07.285-.213.339-.259.356a57.355 57.355 0 00-2.913 1.2C-.153 22.986-.188 24 .5 24h10c.685 0 .652-1.014-.036-1.315z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});