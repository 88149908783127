define("ember-svg-jar/inlined/table-lamp-retro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>table-lamp-retro</title><path d=\"M21.631 9.5a1 1 0 00.832-1.555l-.529-.794a8.185 8.185 0 00-5.99-3.594.5.5 0 01-.448-.469C15.354.568 12.6 0 12 0S8.71.531 8.514 3.1a.5.5 0 01-.448.459 8.183 8.183 0 00-6 3.6l-.529.794A1 1 0 002.369 9.5zM18.5 22.5A1.5 1.5 0 0017 21h-3.25a.25.25 0 01-.25-.25V11.5a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v9.25a.25.25 0 01-.25.25H9a.25.25 0 01-.25-.25V20a.75.75 0 00-1.5 0v.75A.25.25 0 017 21a1.5 1.5 0 000 3h10a1.5 1.5 0 001.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});