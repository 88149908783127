define("ember-svg-jar/inlined/cursor-select-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-select-4</title><path d=\"M23.487 16.107L13.11 12.216a.7.7 0 00-.934.937l3.931 10.335a.67.67 0 00.625.51h.035c.136-.008.467-.082.59-.585l1.143-4.69a.25.25 0 01.183-.183l4.732-1.183c.5-.125.575-.457.583-.594a.658.658 0 00-.511-.656zM12 1a1 1 0 00-1-1 10.973 10.973 0 00-3.3.5 1 1 0 10.6 1.91A9 9 0 0111 2a1 1 0 001-1zM4.636 4.634A1 1 0 003.222 3.22a11.015 11.015 0 00-1.977 2.689 1 1 0 101.772.926 9.008 9.008 0 011.619-2.201zM2 11a1 1 0 00-2 0 11 11 0 00.5 3.3 1 1 0 001.908-.6A9 9 0 012 11zM6.837 18.98a8.971 8.971 0 01-2.2-1.618 1 1 0 10-1.414 1.415 11.009 11.009 0 002.689 1.976 1 1 0 10.926-1.773zM11.943 19.949A9.172 9.172 0 0111 20a1 1 0 000 2 11.177 11.177 0 001.15-.06 1 1 0 10-.207-1.989zM20 11a1 1 0 002 0 10.994 10.994 0 00-.5-3.3 1 1 0 00-1.908.6A9 9 0 0120 11zM15.163 3.015a9 9 0 012.2 1.619 1 1 0 001.414-1.415 11.009 11.009 0 00-2.689-1.976 1 1 0 10-.926 1.772z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});