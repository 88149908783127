define("ember-svg-jar/inlined/notes-diary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-diary</title><path d=\"M20.25 0H3.75a2 2 0 00-2 2v20a2 2 0 002 2h16.5a2 2 0 002-2V2a2 2 0 00-2-2zm0 21.5a.5.5 0 01-.5.5H7.25a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h12.5a.5.5 0 01.5.5z\"/><rect x=\"9.5\" y=\"4.5\" width=\"8\" height=\"6\" rx=\"2\" ry=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});