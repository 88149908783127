define("ember-svg-jar/inlined/skating-shoes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skating-shoes</title><path d=\"M21 21h-3a.5.5 0 01-.5-.5V20a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v.5a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5V20a.5.5 0 00-.5-.5H7a.5.5 0 00-.5.5v.5a.5.5 0 01-.5.5H3a1.5 1.5 0 000 3h18a1.5 1.5 0 000-3zM12 6.027a.5.5 0 00-.5-.5H9.75a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h1.758a.5.5 0 00.5-.534C12 7.815 12 6.1 12 6.027zM12 6.027zM9.25 10.528v.25a3.254 3.254 0 003.25 3.25h7.749a.25.25 0 00.221-.368 4.506 4.506 0 00-3.97-2.382h-1a3.467 3.467 0 01-2.519-1.094.5.5 0 00-.362-.156H9.75a.5.5 0 00-.5.5z\"/><path d=\"M5.5 18.278h13a2.453 2.453 0 002.457-2.187.5.5 0 00-.5-.563H12.5a4.756 4.756 0 01-4.75-4.75v-5.5A1.252 1.252 0 019 4.028h2.5a.5.5 0 00.5-.5V1a1 1 0 00-1.352-.937l-7 2.625A1.007 1.007 0 003 3.625v12.153a2.5 2.5 0 002.5 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});