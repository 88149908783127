define("ember-svg-jar/inlined/style-three-pin-baseball-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-baseball_1</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM6.907 14.646a.5.5 0 01-.707 0l-.793-.792a.5.5 0 010-.708l1.439-1.439a.5.5 0 01.707 0l.793.793a.5.5 0 010 .707zm2.873-2.792a.5.5 0 01-.686-.02l-.881-.881a.5.5 0 01-.022-.682l5.368-6.142a1.647 1.647 0 112.324 2.3zM15 16a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});