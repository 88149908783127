define("ember-svg-jar/inlined/sea-transport-sailing-boat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sea-transport-sailing-boat</title><path d=\"M1.826 13.4a1.011 1.011 0 00.365.069H9a1 1 0 000-2H3.984a.251.251 0 01-.192-.411l6.281-7.5a.25.25 0 01.442.161v12.19a.25.25 0 01-.25.25l-8.638.005a.5.5 0 00-.486.614 11.813 11.813 0 001.126 2.894.245.245 0 00.172.126.25.25 0 00.206-.052 3.974 3.974 0 00.521-.509 2.552 2.552 0 011.949-.937 2.508 2.508 0 012.126 1.18 2.016 2.016 0 001.412.832 2.681 2.681 0 001.808-.975 2.5 2.5 0 014.286.39 1.179 1.179 0 00.982.585 1.776 1.776 0 001.4-.8 2.414 2.414 0 012.14-1.212 2.507 2.507 0 012.126 1.18l.054.068a.255.255 0 00.219.09.249.249 0 00.2-.133 11.349 11.349 0 001.029-2.741.5.5 0 00-.487-.613l-9.641.005a.251.251 0 01-.25-.25V1.654A1.5 1.5 0 009.865.691l-8.64 10.32a1.5 1.5 0 00-.3 1.335 1.552 1.552 0 00.901 1.054z\"/><path d=\"M17.918 11.081a.251.251 0 01-.208.389h-2.835a1 1 0 000 2h4.24a1.5 1.5 0 001.246-2.335l-4.872-7.272a1 1 0 10-1.661 1.114zM23.991 22.645a1 1 0 00-1.124-.858c-1.62.222-2.737-1.5-2.745-1.513a1.047 1.047 0 00-1.715.022 3.283 3.283 0 01-2.678 1.516 2.723 2.723 0 01-2.336-1.44 1.042 1.042 0 00-1.716-.156 4.2 4.2 0 01-3.024 1.6 3.462 3.462 0 01-2.685-1.539 1.043 1.043 0 00-1.636-.092c-.015.018-1.515 1.871-3.2 1.646a1 1 0 00-.264 1.979 4.284 4.284 0 00.548.036A5.6 5.6 0 004.9 22.423a.249.249 0 01.327.006 5.09 5.09 0 003.428 1.383 5.684 5.684 0 003.556-1.321.25.25 0 01.331.015 4.472 4.472 0 003.189 1.306 5.029 5.029 0 003.38-1.3.25.25 0 01.333 0 4.549 4.549 0 003.691 1.26 1 1 0 00.856-1.127z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});