define("ember-svg-jar/inlined/messages-bubble-square-settings-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-settings-alternate</title><path d=\"M10.015 14.264A.25.25 0 009.766 14H8.5a1 1 0 00-.664.252L5 16.773V15a1 1 0 00-1-1H2.249A.251.251 0 012 13.747V2.25A.25.25 0 012.25 2h18a.25.25 0 01.25.25V10a.252.252 0 00.037.131 3.259 3.259 0 01.347.757l.072.234a.25.25 0 00.295.171l.228-.053a3.213 3.213 0 01.769-.084.251.251 0 00.252-.25V2a2 2 0 00-2-2H2a2 2 0 00-2 2v12.053A1.953 1.953 0 002 16h1v3a1 1 0 001.664.748L8.88 16h1.134a.25.25 0 00.232-.343 3.245 3.245 0 01-.231-1.393z\"/><circle cx=\"17.748\" cy=\"17.031\" r=\"1.5\"/><path d=\"M23.516 15.654a1.783 1.783 0 00-1.7-2.953l-1.411.327a.452.452 0 01-.533-.311l-.424-1.392a1.775 1.775 0 00-3.4 0l-.424 1.392a.453.453 0 01-.533.311l-1.41-.328a1.783 1.783 0 00-1.7 2.953l.988 1.064a.464.464 0 010 .626l-.988 1.065a1.783 1.783 0 001.7 2.953l1.411-.327a.451.451 0 01.533.311l.424 1.392a1.775 1.775 0 003.4 0l.424-1.392a.45.45 0 01.533-.311l1.411.327a1.783 1.783 0 001.7-2.953l-.987-1.065a.464.464 0 010-.626zm-2.768 1.377a3 3 0 11-3-3 3 3 0 013 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});