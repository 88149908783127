define("ember-svg-jar/inlined/messages-bubble-information-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-information-alternate</title><path d=\"M12 .875C5.406.875.041 5.334.041 10.816a8.984 8.984 0 003.045 6.634L.969 21.675a1 1 0 001.316 1.354l5.957-2.772a14.2 14.2 0 003.758.5c6.594 0 11.959-4.459 11.959-9.94S18.594.875 12 .875zm0 17.881a12.107 12.107 0 01-3.548-.521 1.012 1.012 0 00-.714.05l-3.7 1.72 1.182-2.357a1 1 0 00-.27-1.228 7.185 7.185 0 01-2.913-5.6c0-4.379 4.468-7.941 9.959-7.941s9.959 3.562 9.959 7.941-4.464 7.936-9.955 7.936z\"/><path d=\"M14.5 14.125h-.75v-4a2.253 2.253 0 00-2.25-2.25h-1a1.25 1.25 0 000 2.5h.75v3.75h-.75a1.25 1.25 0 000 2.5h4a1.25 1.25 0 000-2.5z\"/><circle cx=\"12.125\" cy=\"5.625\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});