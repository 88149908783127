define("ember-svg-jar/inlined/reception-hotel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>reception-hotel</title><path d=\"M0 14.124v9a.5.5 0 00.5.5h13.572a.25.25 0 00.214-.379A1.975 1.975 0 0114 22.217v-5.764a.251.251 0 00-.222-.249A2 2 0 0112 14.217v-1.343a.25.25 0 00-.25-.25H1.5a1.5 1.5 0 00-1.5 1.5zm3.5.75a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v2a.25.25 0 00.25.25h1.5a.25.25 0 00.25-.25v-2a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v6a.249.249 0 01-.25.25h-1a.249.249 0 01-.25-.25v-2a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25v2a.249.249 0 01-.25.25h-1a.249.249 0 01-.25-.25zM20 16.217v6a1.985 1.985 0 01-.286 1.028.249.249 0 00.214.379H23.5a.5.5 0 00.5-.5v-9a1.5 1.5 0 00-1.5-1.5h-.25a.252.252 0 00-.177.074.248.248 0 00-.073.176v1.343a2 2 0 01-2 2zM10 10.624a2 2 0 00-2-2H4a2 2 0 00-2 2v.75a.25.25 0 00.25.25h7.5a.25.25 0 00.25-.25z\"/><circle cx=\"17\" cy=\"3.124\" r=\"2.5\"/><path d=\"M14 15.217h.75a.25.25 0 01.25.25v6.75a1 1 0 001 1h2a1 1 0 001-1v-6.75a.25.25 0 01.25-.25H20a1 1 0 001-1v-5a2 2 0 00-2-2h-4a2 2 0 00-2 2v5a1 1 0 001 1zM3.5 4.624a2.5 2.5 0 005 0v-.75a.25.25 0 00-.25-.25h-4.5a.25.25 0 00-.25.25zM8.5 1.558A.9.9 0 007.9.7a5.672 5.672 0 00-3.8 0 .9.9 0 00-.6.856v.816a.25.25 0 00.25.25h4.5a.25.25 0 00.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});