define("ember-svg-jar/inlined/image-file-camera", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-camera</title><path d=\"M21.414 4.914L17.086.586A2 2 0 0015.672 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.328a2 2 0 00-.586-1.414zM18 18.5a1 1 0 01-1 1H7a1 1 0 01-1-1v-6a1 1 0 011-1h2.2a.406.406 0 00.409-.225l.111-.222a1 1 0 01.894-.553h2.764a1 1 0 01.894.553l.115.229a.37.37 0 00.36.218H17a1 1 0 011 1z\"/><circle cx=\"12\" cy=\"15.5\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});