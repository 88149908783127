define("ember-svg-jar/inlined/cursor-move-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-move-down</title><path d=\"M19.066 3.424A10 10 0 003.277 15.4a1 1 0 001.744-.981 8 8 0 1114.132-.344 1 1 0 001.789.894 9.989 9.989 0 00-1.876-11.545z\"/><path d=\"M15.3 13.118l-3.176 1.929a.251.251 0 01-.259 0l-3.236-1.941a1 1 0 00-1.423 1.273l3.878 8.53a1 1 0 001.822 0l3.826-8.523a1 1 0 00-1.432-1.268z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});