define("ember-svg-jar/inlined/astrology-cancer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astrology-cancer</title><path d=\"M22.5 11a1 1 0 00-1-1h-3.346a.5.5 0 01-.5-.561c.469-3.832 1.928-6.745 3.348-7.575A1 1 0 0020 .138c-2.241 1.311-3.91 5.011-4.369 9.416a.5.5 0 01-.5.447h-6.26a.5.5 0 01-.5-.447c-.459-4.4-2.128-8.1-4.369-9.416A1 1 0 003 1.865c1.42.83 2.879 3.743 3.348 7.575a.5.5 0 01-.5.561H2.5a1 1 0 000 2h3.494a.5.5 0 01.5.512c-.115 4.822-1.835 8.65-3.5 9.624a1 1 0 001.01 1.727c2.567-1.5 4.383-6.137 4.49-11.374a.5.5 0 01.5-.489h6.01a.5.5 0 01.5.489c.107 5.237 1.923 9.871 4.49 11.374a1 1 0 001.01-1.727c-1.664-.974-3.384-4.8-3.5-9.624a.5.5 0 01.5-.512H21.5a1 1 0 001-1.001z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});