define("ember-svg-jar/inlined/laboratory-test-blood", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laboratory-test-blood</title><path d=\"M20.5 3.93A3.931 3.931 0 0016.57 0H7.43A3.931 3.931 0 003.5 3.93v2.84a39.211 39.211 0 00.61 6.92l.02.14a7.994 7.994 0 003.164 5.055.5.5 0 01.206.4V23a1 1 0 001 1h7a1 1 0 001-1v-3.71a.5.5 0 01.206-.4 7.994 7.994 0 003.164-5.06l.02-.14a39.211 39.211 0 00.61-6.92zm-6 17.57a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5V17a.5.5 0 01.5-.5h4a.5.5 0 01.5.5zm2.5-9.74A1.24 1.24 0 0115.76 13H8.24A1.24 1.24 0 017 11.76V4.24A1.24 1.24 0 018.24 3h7.52A1.24 1.24 0 0117 4.24z\"/><path d=\"M12.787 5.532a1.029 1.029 0 00-1.573 0C10.077 6.977 9.5 8.072 9.5 8.786a2.5 2.5 0 105 0c0-.716-.577-1.811-1.713-3.254z\"/><circle cx=\"12\" cy=\"19.25\" r=\"1.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});