define("ember-svg-jar/inlined/cash-payment-wallet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-payment-wallet</title><path d=\"M1.467 12.231a1.589 1.589 0 00-1.529 1.644v8.213a1.588 1.588 0 001.529 1.643h.471a.249.249 0 00.25-.25v-11a.25.25 0 00-.25-.25zM10.322 20.231a2.254 2.254 0 110-4.5h2.363a.251.251 0 00.178-.073.248.248 0 00.075-.177v-1.606a1.59 1.59 0 00-1.53-1.644h-7.47a.249.249 0 00-.25.25v11a.249.249 0 00.25.25h7.47a1.589 1.589 0 001.53-1.643v-1.607a.253.253 0 00-.253-.25c-.225.003-.785 0-2.363 0z\"/><path d=\"M12.745 16.731h-2.423a1.256 1.256 0 100 2.5h2.423a.661.661 0 00.693-.625v-1.25a.662.662 0 00-.693-.625zM15.938 13.015a1 1 0 001-1v-.088a.093.093 0 01.067-.09 2.158 2.158 0 00.2-4.077l-1.789-.715a.163.163 0 01.062-.314h1.761a1 1 0 000-2h-.216a.083.083 0 01-.083-.083 1 1 0 10-2 0v.042a.158.158 0 01-.11.149 2.156 2.156 0 00-.158 4.061l1.788.715a.164.164 0 01-.061.315h-1.761a1 1 0 000 2h.216a.084.084 0 01.084.083 1 1 0 001 1.002z\"/><path d=\"M16.062.269A8 8 0 008.238 9.94a1 1 0 101.955-.417 6 6 0 115.869 4.746 1 1 0 100 2 8 8 0 100-16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});