define("ember-svg-jar/inlined/deer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>deer</title><path d=\"M10.847 8.572A.5.5 0 0011.2 7.8l-.308-.462A3 3 0 008.394 6H7.5a1 1 0 01-1-1V2a1 1 0 00-2 0v3a1 1 0 01-2 0V1a1 1 0 00-2 0v4C.5 8.472 4.031 9.5 6 9.5h1.988a.5.5 0 00.253-.069 6.788 6.788 0 012.606-.859zM22.5 0a1 1 0 00-1 1v4a1 1 0 01-2 0V2a1 1 0 00-2 0v3a1 1 0 01-1 1h-.894a3 3 0 00-2.5 1.336L12.8 7.8a.5.5 0 00.353.773 6.843 6.843 0 012.622.86.5.5 0 00.252.069H18c1.969 0 5.5-1.028 5.5-4.5V1a1 1 0 00-1-1zM12 10c-8.97 0-5.039 14 0 14 5.007 0 9.03-14 0-14zm-3 5a1 1 0 111 1 1 1 0 01-1-1zm4.354 5.354l-1 1a.5.5 0 01-.708 0l-1-1A.5.5 0 0111 19.5h2a.5.5 0 01.354.854zM14 16a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});