define("ember-svg-jar/inlined/presentation-screen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>presentation-screen</title><path d=\"M3 11.5a3 3 0 003 3h13a3 3 0 003-3V3a3 3 0 00-3-3H6a3 3 0 00-3 3zm17 0a1 1 0 01-1 1H6a1 1 0 01-1-1V5a.249.249 0 01.25-.25h14.5A.249.249 0 0120 5zm-9.75-9a.75.75 0 11-.75-.75.75.75 0 01.75.75zm-3 0a.75.75 0 11-.75-.75.75.75 0 01.75.75zM9.236 18.349a2.85 2.85 0 102.85-2.849 2.853 2.853 0 00-2.85 2.849zM4.694 21.2a2.85 2.85 0 10-2.85-2.851 2.853 2.853 0 002.85 2.851zM19.314 15.5a2.85 2.85 0 102.85 2.849 2.852 2.852 0 00-2.85-2.849z\"/><path d=\"M23.389 23.186a5.155 5.155 0 00-4.033-1.931 5.158 5.158 0 00-3.487 1.345.249.249 0 01-.333 0 5.321 5.321 0 00-3.529-1.345h-.013a5.325 5.325 0 00-3.55 1.363.218.218 0 01-.293 0 5.155 5.155 0 00-3.506-1.364 5.155 5.155 0 00-4.034 1.932A.5.5 0 001 24h22a.5.5 0 00.389-.814z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});