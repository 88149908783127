define("ember-svg-jar/inlined/rain-umbrella-case", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rain-umbrella-case</title><path d=\"M18.75 9a.057.057 0 00.054-.038l.876-2.392a.5.5 0 00-.413-.668l-1.738-.2a.251.251 0 01-.22-.277l.345-2.98a.5.5 0 01.187-.334.519.519 0 01.367-.1.5.5 0 01.44.555 1 1 0 101.987.228 2.5 2.5 0 00-2.2-2.769 2.5 2.5 0 00-2.77 2.2l-.345 2.981a.249.249 0 01-.277.219l-1.738-.2a.5.5 0 00-.554.557l.364 3.012a.25.25 0 00.248.22zM11.256 9a.25.25 0 00.184-.08.253.253 0 00.066-.189l-.321-4.091a.5.5 0 00-.652-.436l-1.664.54a.25.25 0 01-.315-.161l-.926-2.852a2.5 2.5 0 00-4.756 1.541 1 1 0 101.9-.615.5.5 0 01.328-.631.5.5 0 01.627.321L6.651 5.2a.25.25 0 01-.16.315l-1.665.54a.5.5 0 00-.272.737l1.278 2.09A.249.249 0 006.045 9zM21.25 11.5a1 1 0 00-1-1h-16a1 1 0 000 2h.974a.249.249 0 01.248.217L6.789 22.7A1.5 1.5 0 008.276 24h7.948a1.5 1.5 0 001.487-1.3l1.317-9.979a.249.249 0 01.248-.217h.974a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});