define("ember-svg-jar/inlined/weather-app-snow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-app-snow</title><path d=\"M14 17.5V21a1 1 0 01-1 1H3a1 1 0 01-1-1V3a1 1 0 011-1h5a1 1 0 000-2H3a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3v-3.5a1 1 0 10-2 0z\"/><path d=\"M10 19H6a.75.75 0 000 1.5h4a.75.75 0 000-1.5zM23.056 7.885l-1.99.533a.248.248 0 01-.19-.024l-2.039-1.177a.251.251 0 010-.434l2.038-1.177a.248.248 0 01.19-.024l1.99.533a.737.737 0 00.2.026.75.75 0 00.194-1.475l-1.425-.382a.249.249 0 01-.176-.306l.381-1.424a.75.75 0 00-1.449-.389l-.533 1.991a.258.258 0 01-.117.152l-2.042 1.176a.25.25 0 01-.375-.216V2.914a.251.251 0 01.073-.177l1.457-1.457A.75.75 0 0018.182.22l-1.043 1.043a.25.25 0 01-.353 0L15.743.22a.75.75 0 10-1.061 1.06l1.457 1.457a.252.252 0 01.074.177v2.354a.25.25 0 01-.375.217L13.8 4.308a.253.253 0 01-.116-.152l-.534-1.991a.75.75 0 00-1.449.389l.381 1.424a.249.249 0 01-.176.306l-1.425.382a.75.75 0 00.194 1.475.737.737 0 00.2-.026l1.99-.533a.248.248 0 01.19.024l2.038 1.177a.251.251 0 010 .434l-2.044 1.177a.248.248 0 01-.19.024l-1.99-.533a.75.75 0 00-.389 1.449l1.425.382a.249.249 0 01.176.306l-.381 1.424a.75.75 0 00.53.919.735.735 0 00.194.026.751.751 0 00.725-.556l.534-1.991a.253.253 0 01.116-.152l2.039-1.177a.25.25 0 01.375.217v2.354a.252.252 0 01-.074.177l-1.457 1.457a.75.75 0 001.061 1.06l1.043-1.043a.25.25 0 01.353 0l1.043 1.043a.75.75 0 101.061-1.06l-1.457-1.457a.251.251 0 01-.073-.177V8.732a.25.25 0 01.375-.216l2.037 1.176a.258.258 0 01.117.152l.533 1.991a.751.751 0 00.725.556.73.73 0 00.194-.026.75.75 0 00.53-.919l-.381-1.424a.249.249 0 01.176-.306l1.425-.382a.75.75 0 00-.388-1.449z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});