define("ember-svg-jar/inlined/safety-warning-rocky-road", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-warning-rocky-road</title><path d=\"M.173 21.3A1.5 1.5 0 001.5 23.5h21a1.5 1.5 0 001.329-2.2l-10.5-20a1.5 1.5 0 00-2.658 0zm16.6-.22a.747.747 0 01-.692.463h-2.518a.75.75 0 01-.531-1.281l.816-.815a1.386 1.386 0 011.952 0l.815.816a.748.748 0 01.162.821zm-2.8-12.752a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.502-1.496zM6.488 17.44l1.5-.562a1.182 1.182 0 00.754-.937l.068-.481a2.7 2.7 0 01.757-1.516l1.6-1.6a3.535 3.535 0 00-2.543.607l-.474.338a.75.75 0 01-.871-1.221l.473-.338a5.056 5.056 0 014.521-.68 2.685 2.685 0 011.615 1.487l.252.587a1.827 1.827 0 00.86.912l1.085.543A.75.75 0 0115.75 16a.758.758 0 01-.334-.079l-1.086-.543a3.342 3.342 0 01-1.568-1.662l-.246-.575-.008-.016-.938.938a.25.25 0 00-.037.3l.932 1.554a2.675 2.675 0 01-.264 3.121l-1.023 1.2a.75.75 0 01-1.139-.976l1.024-1.2a1.177 1.177 0 00.116-1.373l-.642-1.07a.131.131 0 00-.242.049l-.068.479a2.679 2.679 0 01-1.711 2.13l-1.5.563a.762.762 0 01-.264.047.75.75 0 01-.263-1.452z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});