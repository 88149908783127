define("ember-svg-jar/inlined/shelf-books", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shelf-books</title><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2zm-4.154 17a.25.25 0 01-.232-.343l1.315-3.286A1 1 0 0020 14h-1.5a.5.5 0 00-.464.314l-1.812 4.529a.25.25 0 01-.232.157h-.384a.25.25 0 01-.232-.343l1.588-3.971A.5.5 0 0016.5 14h-2a.5.5 0 00-.464.314l-1.812 4.529a.25.25 0 01-.232.157h-.384a.25.25 0 01-.232-.343l1.588-3.971A.5.5 0 0012.5 14h-1.662a1 1 0 00-.928.629l-1.686 4.214a.25.25 0 01-.232.157H2.5a.5.5 0 01-.5-.5V12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v6.5a.5.5 0 01-.5.5zM21.5 2a.5.5 0 01.5.5v6.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V5a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v4.25a.25.25 0 01-.25.25h-6.5a.25.25 0 01-.25-.25V5a.5.5 0 00-.5-.5h-2A.5.5 0 007 5v4.25a.25.25 0 01-.25.25h-.5A.25.25 0 016 9.25V5a.5.5 0 00-.5-.5h-2A.5.5 0 003 5v4.25a.25.25 0 01-.25.25h-.5A.25.25 0 012 9.25V2.5a.5.5 0 01.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});