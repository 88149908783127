define("ember-svg-jar/inlined/single-neutral-find", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-find</title><path d=\"M23.414 23.414a2 2 0 000-2.828l-3.928-3.928a.5.5 0 01-.07-.618 10.508 10.508 0 10-3.376 3.376.5.5 0 01.618.07l3.928 3.928a2 2 0 002.828 0zM10.5 2A8.5 8.5 0 112 10.5 8.51 8.51 0 0110.5 2z\"/><path d=\"M9.319 9.205a.25.25 0 00.021-.462 2.75 2.75 0 112.32 0 .25.25 0 00.021.462A3.5 3.5 0 0114 12.5V14a.5.5 0 01-.5.5h-.829a.25.25 0 00-.248.222l-.2 1.833a.5.5 0 01-.5.445H9.278a.5.5 0 01-.5-.445l-.2-1.833a.25.25 0 00-.248-.222H7.5A.5.5 0 017 14v-1.5a3.5 3.5 0 012.319-3.295z\"/><path d=\"M23.414 23.414a2 2 0 000-2.828l-3.928-3.928a.5.5 0 01-.07-.618 10.508 10.508 0 10-3.376 3.376.5.5 0 01.618.07l3.928 3.928a2 2 0 002.828 0zM10.5 2A8.5 8.5 0 112 10.5 8.51 8.51 0 0110.5 2z\"/><path d=\"M9.319 9.205a.25.25 0 00.021-.462 2.75 2.75 0 112.32 0 .25.25 0 00.021.462A3.5 3.5 0 0114 12.5V14a.5.5 0 01-.5.5h-.829a.25.25 0 00-.248.222l-.2 1.833a.5.5 0 01-.5.445H9.278a.5.5 0 01-.5-.445l-.2-1.833a.25.25 0 00-.248-.222H7.5A.5.5 0 017 14v-1.5a3.5 3.5 0 012.319-3.295z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});