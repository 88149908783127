define("ember-svg-jar/inlined/conversation-smile-type-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>conversation-smile-type-alternate</title><path d=\"M7.375 18.718l-.875.7V17.5a1 1 0 00-1-1h-3A.5.5 0 012 16V2.5a.5.5 0 01.5-.5H19a.5.5 0 01.5.5v5a1 1 0 002 0V2a2 2 0 00-2-2H2a2 2 0 00-2 2v14.5a2 2 0 002 2h2.5v3a1 1 0 001.625.782l2.5-2a1 1 0 00-1.25-1.563z\"/><path d=\"M24 12a2 2 0 00-2-2H12a2 2 0 00-2 2v7a2 2 0 002 2h3.667l3.733 2.8A1 1 0 0021 23v-2h1a2 2 0 002-2zm-2 6.5a.5.5 0 01-.5.5H20a1 1 0 00-1 1v1l-2.4-1.8a1 1 0 00-.6-.2h-3.5a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5h9a.5.5 0 01.5.5z\"/><circle cx=\"15\" cy=\"15.499\" r=\"1\"/><circle cx=\"19\" cy=\"15.499\" r=\"1\"/><path d=\"M8.25 8V6a.75.75 0 00-1.5 0v2a.75.75 0 001.5 0zM12.75 6v2a.75.75 0 001.5 0V6a.75.75 0 00-1.5 0zM4.47 11.469a.75.75 0 000 1.061 6.708 6.708 0 003.618 1.878.75.75 0 00.256-1.479 5.206 5.206 0 01-2.814-1.46.749.749 0 00-1.06 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});