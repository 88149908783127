define("ember-svg-jar/inlined/laptop-download.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-download</title><path d=\"M9.594 15.238a2.428 2.428 0 01-1.543-1.387.492.492 0 00-.47-.36H.5a.478.478 0 00-.41.22.467.467 0 00-.05.47 5.43 5.43 0 005 3.31h4.215a.249.249 0 00.249-.242 7.839 7.839 0 01.245-1.714.25.25 0 00-.155-.297zM3.5 12a1 1 0 001-1V3a1 1 0 011-1h12a1 1 0 011 1v6.344a.249.249 0 00.211.247 7.828 7.828 0 011.454.371.251.251 0 00.335-.236V3a3.009 3.009 0 00-3-3h-12a3.009 3.009 0 00-3 3v8a1 1 0 001 1zM17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.39 7.062l-2 2.5a.5.5 0 01-.781 0l-2-2.5a.5.5 0 01.391-.812h.75a.25.25 0 00.25-.25v-2.75a1 1 0 012 0V17a.25.25 0 00.25.25h.75a.5.5 0 01.39.812z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});