define("ember-svg-jar/inlined/server-clock-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-clock-1</title><path d=\"M9 17.25a8.2 8.2 0 011.324-4.478A.5.5 0 009.9 12H2.5a2.5 2.5 0 000 5H8a.25.25 0 01.25.25v2.5A.25.25 0 018 20H5a.75.75 0 000 1.5h4.354a.5.5 0 00.451-.716A8.16 8.16 0 019 17.25zM3 14.5a1.25 1.25 0 111.25 1.25A1.249 1.249 0 013 14.5zm5 1.25a1.25 1.25 0 111.25-1.25A1.25 1.25 0 018 15.75zM1 20a.75.75 0 000 1.5h1A.75.75 0 002 20zM2.5 5h13a2.5 2.5 0 000-5h-13a2.5 2.5 0 000 5zM3 2.5a1.25 1.25 0 111.25 1.25A1.249 1.249 0 013 2.5zm3.75 0A1.25 1.25 0 118 3.75 1.249 1.249 0 016.75 2.5zM17.483 9A.5.5 0 0018 8.509 2.507 2.507 0 0015.5 6h-13a2.5 2.5 0 000 5h9.2a.5.5 0 00.318-.115A8.224 8.224 0 0117.483 9zM9.25 8.5A1.25 1.25 0 118 7.25 1.25 1.25 0 019.25 8.5zm-3.75 0a1.25 1.25 0 11-1.25-1.25A1.25 1.25 0 015.5 8.5zM10.5 17.25a6.75 6.75 0 106.75-6.75 6.758 6.758 0 00-6.75 6.75zM17.25 22A4.75 4.75 0 1122 17.25 4.756 4.756 0 0117.25 22z\"/><path d=\"M19.25 16.25h-.75a.25.25 0 01-.25-.25v-1.25a1 1 0 00-2 0v2.5a1 1 0 001 1h2a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});