define("ember-svg-jar/inlined/style-three-pin-snow-scooter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-snow-scooter</title><path d=\"M7.25 11.506a.75.75 0 000 1.5H10a.5.5 0 00.5-.5v-.75a.25.25 0 00-.25-.25z\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM8 7.506h3.866a.248.248 0 00.208-.111l.788-1.182a.121.121 0 00-.015-.153l-.982-.982A.253.253 0 0011.689 5H11a.5.5 0 010-1l1 .006a.5.5 0 01.351.147l.913.914a.249.249 0 00.385-.039l.271-.407a.252.252 0 01.222-.111c2.134.117 3.218 2.787 3.639 4.174a.249.249 0 01-.239.322H6.426a.25.25 0 01-.226-.357A1.99 1.99 0 018 7.506zm11.354 5.354a4.108 4.108 0 01-2.854 1.146H13a.5.5 0 010-1h1.25a.25.25 0 00.25-.25v-1a.25.25 0 00-.25-.25h-2.5a.25.25 0 00-.25.25v.75a1.5 1.5 0 01-1.5 1.5H7.25a1.746 1.746 0 01-.95-3.214.03.03 0 00.012-.038l-.112-.437a.249.249 0 01.242-.311h10.9a.25.25 0 01.223.362l-.016.033a2 2 0 01-1.789 1.105h-.01a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h.75a3.13 3.13 0 002.146-.853.5.5 0 11.708.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});