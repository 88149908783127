define("ember-svg-jar/inlined/smiley-surprised", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-surprised</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM7.5 7a2 2 0 11-2 2 2 2 0 012-2zm-.441 10.352a5.706 5.706 0 019.882 0 .439.439 0 01-.38.659 21.078 21.078 0 00-9.123 0 .44.44 0 01-.379-.659zM16.5 11a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});