define("ember-svg-jar/inlined/gardening-hand-sprinkler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gardening-hand-sprinkler</title><path d=\"M10.5 10h-4a.5.5 0 00-.5.5v7.75a1.5 1.5 0 001.125 1.45.5.5 0 01.375.484V23a1 1 0 002 0v-2.813a.5.5 0 01.375-.484A1.5 1.5 0 0011 18.25V10.5a.5.5 0 00-.5-.5z\"/><path d=\"M15 0a3.768 3.768 0 00-2.891 1.266.994.994 0 01-.54.315l-6.674 1.43A.5.5 0 004.5 3.5v5A.5.5 0 005 9h6.043a1 1 0 01.815.418A3.755 3.755 0 0015 11a.5.5 0 00.5-.5V.5A.5.5 0 0015 0zM18 0h-1a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h1a1.5 1.5 0 001.5-1.5v-8A1.5 1.5 0 0018 0zM21.5 6.5h1.484a1 1 0 000-2H21.5a1 1 0 000 2zM22.055 3.332l1.5-1a1 1 0 10-1.11-1.664l-1.5 1a1 1 0 001.11 1.664zM23.555 8.668l-1.5-1a1 1 0 00-1.11 1.664l1.5 1a1 1 0 101.11-1.664zM3 3.5a3 3 0 00-3 3V17a.5.5 0 00.5.5 3 3 0 003-3V4a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});