define("ember-svg-jar/inlined/settings-slider-desktop-horizontal-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>settings-slider-desktop-horizontal-alternate</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v16A1.5 1.5 0 001.5 19h21a1.5 1.5 0 001.5-1.5v-16A1.5 1.5 0 0022.5 0zM22 14.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5zM14.35 20.682a.5.5 0 00-.385-.181h-3.93a.5.5 0 00-.385.181l-2.1 2.532a.5.5 0 00.41.786h8.079a.5.5 0 00.41-.787z\"/><path d=\"M10.163 4.006A1.75 1.75 0 1012 5.667a1.751 1.751 0 00-1.837-1.661zM4.781 6.5H6.75a.75.75 0 000-1.5H4.781a.75.75 0 000 1.5zM19.25 5h-5.5a.75.75 0 000 1.5h5.5a.75.75 0 000-1.5zM13.694 9.5a1.75 1.75 0 101.835 1.661A1.751 1.751 0 0013.694 9.5zM19.25 10.5h-1.969a.75.75 0 000 1.5h1.969a.75.75 0 000-1.5zM10.281 10.5h-5.5a.75.75 0 000 1.5h5.5a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});