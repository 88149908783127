define("ember-svg-jar/inlined/single-neutral-folder-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-folder-box</title><path d=\"M5 1.25a1 1 0 001 1h3.4a.74.74 0 01.668.426 2.752 2.752 0 002.48 1.574H19a1 1 0 000-2h-6.456a.741.741 0 01-.669-.426A2.753 2.753 0 009.4.25H6a1 1 0 00-1 1z\"/><path d=\"M4 14a.5.5 0 00.5-.5V6.018a.5.5 0 01.5-.5h2.9a.741.741 0 01.668.425 2.755 2.755 0 002.48 1.575H19a.5.5 0 01.5.5V13.5a.5.5 0 00.5.5h2.95a.5.5 0 00.49-.6l-.45-2.25a.5.5 0 00-.49-.4h-.75a.25.25 0 01-.25-.25V6.518a1 1 0 00-1-1h-9.456a.741.741 0 01-.669-.426A2.753 2.753 0 007.9 3.518H3.5a1 1 0 00-1 1V10.5a.25.25 0 01-.25.25H1.5a.5.5 0 00-.49.4L.564 13.4a.5.5 0 00.49.6zM18.5 15a2.5 2.5 0 00-2.5 2.5.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5A2.5 2.5 0 005.5 15h-5a.5.5 0 00-.5.5v7.75a.5.5 0 00.5.5h23a.5.5 0 00.5-.5l-.009-7.75a.5.5 0 00-.5-.5z\"/><path d=\"M13.442 13.172c-.005-.047-.012-.092-.028-.081a3.079 3.079 0 00.683-2.135 1.849 1.849 0 10-3.694 0 2.985 2.985 0 00.637 2.066.457.457 0 01.016.151c-1.119.419-1.741.742-2.01 1.283a3.7 3.7 0 00-.288.974.493.493 0 00.109.409.5.5 0 00.383.179h6a.5.5 0 00.383-.179.493.493 0 00.109-.409 3.682 3.682 0 00-.288-.973c-.269-.542-.892-.865-2.012-1.285z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});