define("ember-svg-jar/inlined/shipment-touch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-touch</title><path d=\"M10.827 10.294a3 3 0 012.9 2.225.249.249 0 00.241.185h2.862a1 1 0 001-1V1.7a1 1 0 00-1-1h-2.75a.25.25 0 00-.25.25V5.2a.5.5 0 01-.83.383l-1.511-1.3a.249.249 0 00-.326 0l-1.511 1.3a.5.5 0 01-.825-.383V.954A.25.25 0 008.577.7h-2.75a1 1 0 00-1 1v10a1 1 0 001 1h1.861a.249.249 0 00.241-.185 3 3 0 012.898-2.221z\"/><path d=\"M9.327 20.456a.251.251 0 01-.162.234.248.248 0 01-.276-.07l-1.56-1.782v-.005a1.5 1.5 0 00-2.309 1.915l.008.009 2.228 2.457a.252.252 0 00.185.082h11.64a.25.25 0 00.25-.25v-1.252a4 4 0 00-4-4h-2.75a.25.25 0 01-.25-.25v-4.25a1.5 1.5 0 00-3 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});