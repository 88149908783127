define("ember-svg-jar/inlined/spa-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>spa-sign</title><path d=\"M10.239 8.831a.22.22 0 00-.2.03.217.217 0 00-.092.177v1.779a.25.25 0 00.341.233 1.177 1.177 0 00-.052-2.219z\"/><path d=\"M24 2a2 2 0 00-2-2H1.75a1 1 0 000 2H3.5a.5.5 0 01.5.5v1.25a.5.5 0 01-.5.5h-2A1.5 1.5 0 000 5.75v11a1.5 1.5 0 001.5 1.5h18a1.5 1.5 0 001.5-1.5v-11a1.5 1.5 0 00-1.5-1.5h-2a.5.5 0 01-.5-.5V2.5a.5.5 0 01.5-.5h4.25a.25.25 0 01.25.25V23a1 1 0 002 0zM6 2.5a.5.5 0 01.5-.5h8a.5.5 0 01.5.5v1.25a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5zm-.25 8.15a3.133 3.133 0 011.6 2.55 2.053 2.053 0 01-2.05 2.05H4a.75.75 0 010-1.5h1.3a.551.551 0 00.55-.55c0-.573-.343-.857-1-1.35a3.133 3.133 0 01-1.6-2.55A2.053 2.053 0 015.3 7.25h1.3a.75.75 0 010 1.5H5.3a.551.551 0 00-.55.55c0 .573.343.857 1 1.35zm4.2 1.99v1.86a.75.75 0 01-1.5 0V8a.75.75 0 01.75-.75h.65a2.7 2.7 0 01.1 5.39zm7.8-3.34v5.2a.75.75 0 01-1.5 0v-1.6a.25.25 0 00-.25-.25h-.6a.25.25 0 00-.25.25v1.6a.75.75 0 01-1.5 0V9.3a2.05 2.05 0 114.1 0z\"/><path d=\"M15.15 9.3v1.6a.25.25 0 00.25.25h.6a.25.25 0 00.25-.25V9.3a.55.55 0 10-1.1 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});