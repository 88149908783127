define("ember-svg-jar/inlined/social-instagram-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-instagram-1</title><path d=\"M18.07 11.25a.25.25 0 00-.24.31 6 6 0 11-11.65 0 .25.25 0 00-.24-.31H.5a.5.5 0 00-.5.5v5.75A6.51 6.51 0 006.5 24h11a6.51 6.51 0 006.5-6.5v-5.75a.5.5 0 00-.5-.5zM23.5 9.75a.5.5 0 00.5-.5V6.5A6.51 6.51 0 0017.5 0h-5.75a.5.5 0 00-.5.5v4a.75.75 0 01-1.5 0v-4a.5.5 0 00-.5-.5h-.5a.5.5 0 00-.5.5v4a.75.75 0 01-1.5 0v-4a.5.5 0 00-.52-.5h-.55a.5.5 0 00-.43.5v4a.75.75 0 01-1.5 0V1a.25.25 0 00-.37-.19A6.5 6.5 0 000 6.5v2.75a.5.5 0 00.5.5h6.2a.5.5 0 00.41-.21 6 6 0 019.79 0 .5.5 0 00.41.21zm-6-5.25a1 1 0 011-1h1a1 1 0 011 1v1a1 1 0 01-1 1h-1a1 1 0 01-1-1z\"/><circle cx=\"12\" cy=\"13\" r=\"3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});