define("ember-svg-jar/inlined/email-action-skull", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-skull</title><path d=\"M18.409 15.131a.754.754 0 01-.4.119H6a.754.754 0 01-.4-.119l-5.215-3.35a.25.25 0 00-.385.21V22a2 2 0 002 2h20a2 2 0 002-2V11.993a.25.25 0 00-.385-.211z\"/><path d=\"M5.12 13.041a.25.25 0 00.385-.21L5.5 2.25A.249.249 0 015.75 2h12.5a.25.25 0 01.25.25v10.575a.25.25 0 00.385.211l4.063-2.608a.25.25 0 00.008-.416L20.5 8.3V2.206a2.114 2.114 0 00-2-2.208h-13a2.115 2.115 0 00-2 2.209V8.3l-2.458 1.71a.251.251 0 00.007.416z\"/><path d=\"M8.825 9.619a1.428 1.428 0 01.3.919v.95a.75.75 0 001.5 0 .188.188 0 01.375 0 .75.75 0 001.5 0 .188.188 0 01.375 0 .75.75 0 001.5 0v-.95a1.428 1.428 0 01.3-.919 3.7 3.7 0 00.825-2.4 3.647 3.647 0 00-.922-2.419 3.861 3.861 0 00-2.828-1.249 3.806 3.806 0 00-2.872 1.3 3.757 3.757 0 00-.053 4.764zM10.5 8.232a1 1 0 111-1 1 1 0 01-1 1zm2.5 0a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});