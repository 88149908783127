define("ember-svg-jar/inlined/navigation-down-circle-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-down-circle-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.012 10.012 0 01-10 10z\"/><path d=\"M15.615 12.116l-1.94 1.939a.25.25 0 01-.427-.177V6a1.25 1.25 0 10-2.5 0v7.878a.25.25 0 01-.426.177l-1.94-1.939a1.249 1.249 0 00-1.767 1.767l4.5 4.5a1.247 1.247 0 001.767 0l4.5-4.5a1.249 1.249 0 00-1.767-1.767z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});