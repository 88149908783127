define("ember-svg-jar/inlined/video-game-logo-directions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-logo-directions</title><path d=\"M22.5 7.5h.5a1 1 0 001-1V1a1 1 0 00-1-1h-5.5a1 1 0 00-1 1v.5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5.5.5 0 00-.5-.5h-4a.5.5 0 00-.5.5.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5V1a1 1 0 00-1-1H1a1 1 0 00-1 1v5.5a1 1 0 001 1h.5A.5.5 0 012 8v1a.5.5 0 01-.5.5.5.5 0 00-.5.5v4a.5.5 0 00.5.5.5.5 0 01.5.5v1a.5.5 0 01-.5.5H1a1 1 0 00-1 1V23a1 1 0 001 1h5.5a1 1 0 001-1v-.5A.5.5 0 018 22h1a.5.5 0 01.5.5.5.5 0 00.5.5h4a.5.5 0 00.5-.5.5.5 0 01.5-.5h1a.5.5 0 01.5.5v.5a1 1 0 001 1H23a1 1 0 001-1v-5.5a1 1 0 00-1-1h-.5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5.5.5 0 01-.5-.5V8a.5.5 0 01.5-.5zM20 9a.5.5 0 01-.5.5.309.309 0 00-.249.124.313.313 0 00-.05.274 7.538 7.538 0 010 4.2.312.312 0 00.3.4.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1.256a.5.5 0 00-.39.188 7.538 7.538 0 01-1.168 1.167.5.5 0 00-.187.391V19.5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5.309.309 0 00-.124-.249.313.313 0 00-.274-.05 7.538 7.538 0 01-4.2 0 .313.313 0 00-.274.05.309.309 0 00-.128.249.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5v-1.255a.5.5 0 00-.188-.39 7.538 7.538 0 01-1.167-1.168.5.5 0 00-.39-.187H4.5A.5.5 0 014 16v-1a.5.5 0 01.5-.5.312.312 0 00.3-.4 7.538 7.538 0 010-4.2.313.313 0 00-.05-.274.309.309 0 00-.25-.126A.5.5 0 014 9V8a.5.5 0 01.5-.5h1.255a.5.5 0 00.39-.187 7.538 7.538 0 011.167-1.168.5.5 0 00.188-.39V4.5A.5.5 0 018 4h1a.5.5 0 01.5.5.309.309 0 00.124.249.313.313 0 00.276.051 7.538 7.538 0 014.2 0 .311.311 0 00.4-.3.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1.255a.5.5 0 00.188.39 7.538 7.538 0 011.167 1.168.5.5 0 00.39.187H19.5a.5.5 0 01.5.5z\"/><path d=\"M12 6.5a5.5 5.5 0 105.5 5.5A5.5 5.5 0 0012 6.5zm.307 8.263a.485.485 0 01-.614 0c-.718-.572-2.443-2.071-2.443-3.243a1.34 1.34 0 012.36-1.02.5.5 0 00.78 0 1.339 1.339 0 012.36 1.016c0 1.176-1.725 2.675-2.443 3.247z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});