define("ember-svg-jar/inlined/vr-user-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vr-user-laptop</title><path d=\"M2.5 10.5h2.75a.25.25 0 01.25.25v1.75a1 1 0 002 0V10A1.5 1.5 0 006 8.5H2.5a.5.5 0 010-1H7a1 1 0 000-2H2.5a2.5 2.5 0 000 5zM11.5 21h-.25v-4.5a2 2 0 00-2-2h-6.5a2 2 0 00-2 2V21H.5a.5.5 0 00-.5.5A2.41 2.41 0 002.5 24h7a2.41 2.41 0 002.5-2.5.5.5 0 00-.5-.5zm-8.75-4.25A.25.25 0 013 16.5h6a.249.249 0 01.25.25v4A.25.25 0 019 21H3a.25.25 0 01-.25-.25zM22 11a2 2 0 002-2V6a1.989 1.989 0 00-.306-1.063 7 7 0 00-13.383-.007A1.983 1.983 0 0010 6v3a2 2 0 002 2h2.5a1 1 0 00.8-.4l.9-1.2a1 1 0 011.6 0l.9 1.2a1 1 0 00.8.4zm-7.5-4h-2a.5.5 0 010-1h2a.5.5 0 010 1zm3 0h-1a.5.5 0 010-1h1a.5.5 0 010 1zm-3.969-3a.25.25 0 01-.182-.421 5.022 5.022 0 017.312 0 .25.25 0 01-.183.421zM21.455 12.639a1 1 0 00-1.4-.181 5.067 5.067 0 01-6.1 0 1 1 0 10-1.222 1.583 6.99 6.99 0 008.548 0 1 1 0 00.174-1.402z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});