define("ember-svg-jar/inlined/notes-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-edit</title><path d=\"M11.394 17.9a.25.25 0 00-.2-.395H2.251A.249.249 0 012 17.25V4.75a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v8.318a.25.25 0 00.427.177l1.5-1.5a.251.251 0 00.073-.177V4.5a2 2 0 00-2-2h-1.25a.249.249 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.249.249 0 01-.25.25h-1.5a.249.249 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.249.249 0 01-.25.25h-1.5A.249.249 0 016 2.25V1a1 1 0 00-2 0v1.25a.249.249 0 01-.25.25H2a2 2 0 00-2 2v13a2 2 0 002 2h8.131a.249.249 0 00.2-.105zM23.035 11.591a2.711 2.711 0 00-3.606.273l-6.58 6.58a.25.25 0 000 .354l3.351 3.353a.25.25 0 00.354 0l6.679-6.679a2.623 2.623 0 00-.198-3.881zM12.061 20.131a.249.249 0 00-.421.128l-.629 3.141a.5.5 0 00.49.6.466.466 0 00.1-.01l3.143-.629a.25.25 0 00.128-.422z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});