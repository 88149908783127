define("ember-svg-jar/inlined/outdoors-bench-sit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-bench-sit</title><circle cx=\"9.5\" cy=\"2.874\" r=\"2.5\"/><path d=\"M24 11.624a1 1 0 00-1-1h-6.013a2.3 2.3 0 01.013.251 2.5 2.5 0 01-1.4 2.247l-2.32 9.26a1 1 0 001.94.485l.825-3.3a.25.25 0 01.243-.19h3.924a.25.25 0 01.243.19l.825 3.3a1 1 0 001.94-.485l-2.44-9.758H23a1 1 0 001-1zm-4.17 5.44a.253.253 0 01-.046.214.248.248 0 01-.2.1h-2.671a.248.248 0 01-.2-.1.253.253 0 01-.046-.214l1.11-4.44h.94zM6 17.624a.25.25 0 01.25-.25h2.193a.25.25 0 01.249.233l.32 4.454a1.5 1.5 0 002.976-.373l-.5-6A1.5 1.5 0 0010 14.374H6.525a.249.249 0 01-.234-.337l1.293-3.449a.25.25 0 01.384-.112l1.6 1.2a3.524 3.524 0 002.1.7H14.5a1.5 1.5 0 000-3h-2.833a.506.506 0 01-.3-.1L7.9 6.674a1.5 1.5 0 00-2.3.674s-3.059 8.183-3.075 8.276H1a1 1 0 100 2h2.75a.25.25 0 01.25.25v4.75a1 1 0 002 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});