define("ember-svg-jar/inlined/history-indian-feather", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-indian-feather</title><path d=\"M22.969 8.852c-.971-.914-2.827.085-3.856 1.177a.342.342 0 01-.566-.364l.938-2.317c.564-1.39.7-3.494-.539-3.995s-2.605 1.1-3.168 2.493l-.815 2.011A.5.5 0 0114 7.67V3.5C14 2 13.333 0 12 0s-2 2-2 3.5v4.17a.5.5 0 01-.963.187l-.815-2.011c-.563-1.39-1.932-2.993-3.168-2.493s-1.1 2.605-.539 3.995l.938 2.317a.342.342 0 01-.566.364C3.858 8.937 2 7.938 1.031 8.852s-.085 2.827.944 3.919l1.9 2.023a.2.2 0 00.168.061.2.2 0 00.15-.1A9.166 9.166 0 0112 10.5a9.166 9.166 0 017.8 4.256.2.2 0 00.15.1.2.2 0 00.168-.061l1.9-2.023c1.036-1.093 1.921-3.006.951-3.92z\"/><path d=\"M17.1 13.885a.5.5 0 01-.12.833 2.525 2.525 0 00-1.07.921.5.5 0 01-.662.163 6.94 6.94 0 00-6.486 0 .5.5 0 01-.662-.163 2.525 2.525 0 00-1.07-.921.5.5 0 01-.12-.833 7.81 7.81 0 0110.19 0z\"/><circle cx=\"18\" cy=\"17\" r=\"1.5\"/><circle cx=\"6\" cy=\"17\" r=\"1.5\"/><path d=\"M18 19.5a2.358 2.358 0 01-.7-.107.5.5 0 00-.617.309 3.237 3.237 0 00-.181 1.1v1.7a1.5 1.5 0 003 0V20.8a3.237 3.237 0 00-.181-1.1.5.5 0 00-.617-.309A2.358 2.358 0 0118 19.5zM6 19.5a2.358 2.358 0 01-.7-.107.5.5 0 00-.617.309A3.237 3.237 0 004.5 20.8v1.7a1.5 1.5 0 003 0v-1.7a3.237 3.237 0 00-.181-1.1.5.5 0 00-.617-.309A2.358 2.358 0 016 19.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});