define("ember-svg-jar/inlined/phone-actions-bluetooth-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-bluetooth-1</title><path d=\"M20.674 7.446l2.94-2.287a1 1 0 00-.014-1.588L19.12.21a1 1 0 00-1.6.8v3.975l-2.875-2.244a1 1 0 10-1.23 1.577l4 3.126-4.029 3.136a1 1 0 101.228 1.578L17.52 9.9v3.99a1 1 0 001.6.8l4.48-3.361a1 1 0 00.015-1.588zM19.52 3.01l1.833 1.375L19.52 5.81zm0 8.88V9.082l1.834 1.432zM15.5 15.774a3.116 3.116 0 00-4.4 0l-.827.828a4.249 4.249 0 00-1.346 4.5 11.355 11.355 0 01-6.032-6.032 4.248 4.248 0 004.5-1.345l.83-.829a3.117 3.117 0 000-4.4L4.634 4.913a2.232 2.232 0 00-3.648.74 13.313 13.313 0 0017.357 17.354 2.233 2.233 0 00.737-3.648z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});