define("ember-svg-jar/inlined/mobile-shopping-shop-hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mobile-shopping-shop-hand</title><path d=\"M23.947 4.276L22.224.83A1.491 1.491 0 0020.882 0h-2.874a.25.25 0 00-.24.322l1.211 4.034A.5.5 0 0119 4.5v3.873a.252.252 0 00.132.221 3.423 3.423 0 00.686.277.25.25 0 01.182.241V18.5a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5v-1.25a.25.25 0 01.25-.25h1.25a2.992 2.992 0 00.872-.13.25.25 0 01.308.155l.062.172a.5.5 0 00.459.3h3.578a.5.5 0 00.47-.329l1.091-3a.5.5 0 00-.47-.671.129.129 0 01-.128-.111.72.72 0 00-.03-.126l-.5-1.5a.75.75 0 00-1.424.474l.311.934a.248.248 0 01-.034.225.25.25 0 01-.2.1h-1.2a.248.248 0 01-.243-.192A3.005 3.005 0 0011.5 11h-1.25a.25.25 0 01-.25-.25V9.112a.251.251 0 01.182-.241 3.423 3.423 0 00.686-.277.252.252 0 00.132-.221V4.5a.5.5 0 01.021-.144L12.232.322a.25.25 0 00-.24-.322H9.118a1.492 1.492 0 00-1.342.829L6.053 4.276A.512.512 0 006 4.5v.858a.248.248 0 01-.207.246A7.013 7.013 0 000 12.5v5.546A3.808 3.808 0 001.887 21.5a.251.251 0 01.113.21V23a1 1 0 002 0v-2a1 1 0 00-.521-.878C2.318 19.489 2 19.042 2 18.046V12.5a5.007 5.007 0 014.494-4.974.249.249 0 01.225.1 3.486 3.486 0 001.148.982.251.251 0 01.133.219v1.923a.25.25 0 01-.25.25H6a1 1 0 000 2h5.5a1 1 0 010 2h-3a1 1 0 00-1 1 2.19 2.19 0 01-1.076 1.893 2.229 2.229 0 01-.3.147 1 1 0 00.376 1.927 1.012 1.012 0 00.375-.073 4.205 4.205 0 00.738-.387.253.253 0 01.256-.011.25.25 0 01.131.22V21.5a2.5 2.5 0 002.5 2.5h9a2.5 2.5 0 002.5-2.5V8.828a.249.249 0 01.133-.221A3.548 3.548 0 0024 5.5v-1a.512.512 0 00-.053-.224zM16 21.5a1 1 0 11-1-1 1 1 0 011 1z\"/><path d=\"M12.011 4.538A.251.251 0 0012 4.61v4.118a.248.248 0 00.091.193 4.488 4.488 0 005.818 0A.251.251 0 0018 8.729V4.61a.251.251 0 00-.011-.072L16.682.178a.25.25 0 00-.24-.178h-2.884a.25.25 0 00-.24.178z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});