define("ember-svg-jar/inlined/monitor-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-user</title><path d=\"M24 2.59A2.548 2.548 0 0021.5 0h-19A2.548 2.548 0 000 2.59V16.5a3 3 0 003 3h7.25a.25.25 0 01.25.25v2a.25.25 0 01-.25.25H5a1 1 0 000 2h14a1 1 0 000-2h-6.25a.25.25 0 01-.25-.25v-2a.25.25 0 01.25-.25H21a3 3 0 003-3zM2.5 15a.5.5 0 01-.5-.5V2.59A.551.551 0 012.5 2h19a.551.551 0 01.5.589V14.5a.5.5 0 01-.5.5z\"/><path d=\"M13.174 10.232a.25.25 0 01-.039-.456 2.863 2.863 0 10-2.744 0 .252.252 0 01.129.241.249.249 0 01-.169.215 4.329 4.329 0 00-2.838 3.158A.5.5 0 008 14h7.525a.5.5 0 00.488-.611 4.332 4.332 0 00-2.839-3.157z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});