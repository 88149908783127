define("ember-svg-jar/inlined/lucide-file-cog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4 6V4a2 2 0 012-2h8.5L20 7.5V20a2 2 0 01-2 2H4\"/><path d=\"M14 2v6h6\"/><circle cx=\"6\" cy=\"14\" r=\"3\"/><path d=\"M6 10v1M6 17v1M10 14H9M3 14H2M9 11l-.88.88M3.88 16.12L3 17M9 17l-.88-.88M3.88 11.88L3 11\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});