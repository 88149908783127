define("ember-svg-jar/inlined/smiley-zipped-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-zipped-alternate</title><path d=\"M17.908 20.068A10 10 0 1112 2a9.943 9.943 0 019.959 9.09 1 1 0 001.992-.18A12 12 0 1012 24a11.919 11.919 0 007.092-2.318 1 1 0 10-1.184-1.614z\"/><circle cx=\"8\" cy=\"9\" r=\"2\"/><circle cx=\"16\" cy=\"9\" r=\"2\"/><path d=\"M21.5 13.5c-.921 0-3.188.286-4.106 1.408A.249.249 0 0117.2 15h-.45a.25.25 0 01-.25-.25V14a1 1 0 00-2 0v.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V14a1 1 0 00-2 0v.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V14a1 1 0 00-2 0v.75a.25.25 0 01-.25.25H6a1 1 0 000 2h2.25a.25.25 0 01.25.25V18a1 1 0 002 0v-.75a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V18a1 1 0 002 0v-.75a.25.25 0 01.25-.25h2.45a.249.249 0 01.193.092c.918 1.122 3.185 1.408 4.106 1.408a2.5 2.5 0 000-5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});