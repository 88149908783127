define("ember-svg-jar/inlined/glasses-ski-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>glasses-ski-2</title><path d=\"M22.686 8C20.292 6.709 15.6 6.25 12 6.25S3.708 6.709 1.314 8a2.5 2.5 0 00-1.308 2.377c.264 3.75.929 5.917 1.978 6.441 1.842.921 5.806.932 5.974.932 1.125 0 1.586-1.135 2.033-2.232.558-1.37 1-2.268 2.009-2.268s1.451.9 2.009 2.268c.447 1.1.908 2.232 2.033 2.232.168 0 4.132-.011 5.973-.932 1.05-.524 1.715-2.691 1.979-6.441A2.5 2.5 0 0022.686 8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});