define("ember-svg-jar/inlined/file-iso", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-iso</title><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.428A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6zM16.5 13.626a.876.876 0 00-.875.875v3a.875.875 0 101.75 0v-3a.876.876 0 00-.875-.875z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.251A.25.25 0 0014.75 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.251A.25.25 0 0021.75 7zM7.875 18.376H8a.625.625 0 010 1.25H6a.625.625 0 010-1.25h.125a.25.25 0 00.25-.25v-4.25a.25.25 0 00-.25-.25H6a.625.625 0 110-1.25h2a.625.625 0 010 1.25h-.125a.25.25 0 00-.25.25v4.25a.25.25 0 00.25.25zm3.052-3.508l1.84 1.226a1.928 1.928 0 01-1.07 3.532H10a.625.625 0 010-1.25h1.7a.678.678 0 00.376-1.242l-1.84-1.226a1.928 1.928 0 011.07-3.532H13a.625.625 0 010 1.25h-1.7a.678.678 0 00-.376 1.242zm7.7 2.633a2.125 2.125 0 11-4.25 0v-3a2.125 2.125 0 114.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});