define("ember-svg-jar/inlined/video-player-movie-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-player-movie-1</title><path d=\"M24 8.25a.5.5 0 00-.5-.5H.5a.5.5 0 00-.5.5v12a2.5 2.5 0 002.5 2.5h19a2.5 2.5 0 002.5-2.5zm-7.765 5.868a1.221 1.221 0 010 2.264l-6.626 2.776A1.152 1.152 0 018 18.123v-5.746a1.151 1.151 0 011.609-1.035zM19.563 1.677a.25.25 0 00-.176-.427H15.6a.106.106 0 00-.073.03l-4.54 4.543a.25.25 0 00.177.427h3.783a.107.107 0 00.074-.03zM22.075 1.318a.047.047 0 00-.045.013l-4.492 4.492a.25.25 0 00.177.427H23.5a.5.5 0 00.5-.5v-2a2.5 2.5 0 00-1.925-2.432zM13.013 1.677a.25.25 0 00-.177-.427H9.1a.106.106 0 00-.073.03l-4.54 4.543a.25.25 0 00.177.427H8.4a.106.106 0 00.073-.03zM6.513 1.677a.25.25 0 00-.177-.427H2.5A2.5 2.5 0 000 3.75v2a.5.5 0 00.5.5h1.4a.106.106 0 00.073-.03z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});