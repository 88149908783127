define("ember-svg-jar/inlined/task-list-edit-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-list-edit-1</title><path d=\"M12 7.75H6a.75.75 0 000 1.5h6a.75.75 0 000-1.5zM12 11.25H6a.75.75 0 000 1.5h6a.75.75 0 000-1.5zM10 14.75H6a.75.75 0 000 1.5h4a.75.75 0 000-1.5z\"/><path d=\"M10.1 19H3.5a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v8.067a.25.25 0 00.427.177l2.5-2.5a.251.251 0 00.073-.177V3.5A1.5 1.5 0 0016.5 2h-4a.243.243 0 01-.208-.121 3.827 3.827 0 00-6.588 0A.243.243 0 015.5 2h-4A1.5 1.5 0 000 3.5v17A1.5 1.5 0 001.5 22h8.059a.243.243 0 00.243-.192c.108-.508.4-1.886.542-2.5A.25.25 0 0010.1 19zM12.061 20.13a.25.25 0 00-.422.128l-.628 3.142a.5.5 0 00.49.6.466.466 0 00.1-.01l3.143-.629a.25.25 0 00.128-.422zM23.035 11.59a2.71 2.71 0 00-3.606.274l-6.58 6.58a.25.25 0 000 .353l3.351 3.354a.25.25 0 00.353 0l6.679-6.679a2.623 2.623 0 00-.197-3.882z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});