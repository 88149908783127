define("ember-svg-jar/inlined/design-file-max-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-max-1</title><path d=\"M23.707 5.708L18.293.293A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.75V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.706z\"/><path d=\"M9 12.125a.877.877 0 00-.875.875v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V13A.877.877 0 009 12.125z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zM6.125 17.5a.625.625 0 01-1.25 0v-3.175c0-.075-.08-.063-.109 0l-.207.457a.65.65 0 01-1.118 0s-.167-.327-.2-.4-.118-.062-.118.009V17.5a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279s.807 1.606.837 1.673a.124.124 0 00.208 0l.837-1.674a.625.625 0 011.184.279zm5 0a.625.625 0 01-1.25 0v-2.125a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-4.375a2.212 2.212 0 011.9-2.239A2.128 2.128 0 0111.125 13zm4.934-.279a.625.625 0 11-1.118.558l-.876-1.753a.069.069 0 00-.129 0l-.877 1.754a.625.625 0 11-1.118-.558l1.3-2.609a.252.252 0 000-.224l-1.3-2.609a.625.625 0 111.118-.558s.851 1.7.876 1.753a.07.07 0 00.129 0l.877-1.754a.625.625 0 111.118.558l-1.305 2.609a.252.252 0 000 .224z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});