define("ember-svg-jar/inlined/diet-health", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diet-health</title><path d=\"M8 5c0-2.757-1.794-5-4-5S0 2.243 0 5a5.107 5.107 0 002.479 4.6.5.5 0 01.271.445V22.75a1.25 1.25 0 002.5 0V10.044a.5.5 0 01.271-.444A5.107 5.107 0 008 5zM16.5 13.75a.5.5 0 00-.5-.5h-2a.5.5 0 01-.5-.5v-2a.5.5 0 00-.5-.5h-2.5a.5.5 0 00-.5.5v2a.5.5 0 01-.5.5h-2a.5.5 0 00-.5.5v2.5a.5.5 0 00.5.5h2a.5.5 0 01.5.5v2a.5.5 0 00.5.5H13a.5.5 0 00.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 00.5-.5z\"/><path d=\"M22.75 0a1.25 1.25 0 00-1.25 1.25v5a.751.751 0 01-.75.75.25.25 0 01-.25-.25v-5.5a1.25 1.25 0 00-2.5 0v5.5a.25.25 0 01-.25.25.751.751 0 01-.75-.75v-5a1.25 1.25 0 00-2.5 0v5a3.254 3.254 0 003.25 3.25.25.25 0 01.25.25v13a1.25 1.25 0 002.5 0v-13a.25.25 0 01.25-.25A3.254 3.254 0 0024 6.25v-5A1.25 1.25 0 0022.75 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});