define("ember-svg-jar/inlined/medical-instrument-tooth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-instrument-tooth</title><path d=\"M14.959 4.113a1.725 1.725 0 01.771.037c.06.016 1.3.355 1.966.529a.5.5 0 00.566-.247 2.457 2.457 0 01.428-.572l2.71-2.71a.5.5 0 00.01-.7A1.73 1.73 0 0020.16 0h-.03c-1.25 0-2.75.88-4.48 2.6a7.154 7.154 0 00-.943 1.13.248.248 0 000 .274.251.251 0 00.252.109zM16.41 12.12l-1.75-.74a.5.5 0 00-.387 0c-.337.14-.759.34-1.255.6a.5.5 0 00-.21.208c-1.79 3.377-4.825 8.261-6.088 10.264A1.009 1.009 0 007.57 24a.842.842 0 00.27-.04c2.155-.585 5.108-4.345 8.8-11.188a.468.468 0 00-.225-.652z\"/><path d=\"M23.552 2.591a.5.5 0 00-.7.007l-2.71 2.71a2.553 2.553 0 01-2.483.659l-2.253-.614a.54.54 0 00-.372.043C5.763 10.035.717 13.657.036 16.162A1.01 1.01 0 001.55 17.28c4.957-3.129 10.754-6.572 12.783-7.256a.5.5 0 01.354.013l2.208.933a.5.5 0 00.334.018A8.345 8.345 0 0021.4 8.352C23.137 6.611 24.013 5.1 24 3.846a1.774 1.774 0 00-.448-1.255zm-7.2 6.309a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});