define("ember-svg-jar/inlined/season-autumn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>season-autumn</title><path d=\"M11.941 18.379a1 1 0 00-1.336-.462 13.739 13.739 0 01-3.407 1.2.25.25 0 01-.224-.421l1.287-1.318A1 1 0 007.8 15.71C3 14.43 2.125 10.357 2.015 8.151a.5.5 0 01.519-.524 7.54 7.54 0 015.244 2.19 1 1 0 001.765-.557A16.892 16.892 0 0111.1 3.189a.5.5 0 01.9 0 16.892 16.892 0 011.549 6.071 1 1 0 001.761.562 7.287 7.287 0 015.25-2.2.5.5 0 01.517.524 10.235 10.235 0 01-.48 2.658 1 1 0 001.9.623 12.528 12.528 0 00.58-4.363 1.5 1.5 0 00-1.406-1.425 10.4 10.4 0 00-5.752 1.23.5.5 0 01-.733-.34A14.914 14.914 0 0012.754.573a1.56 1.56 0 00-2.416 0 14.907 14.907 0 00-2.433 5.96.5.5 0 01-.733.339 10.382 10.382 0 00-5.753-1.229A1.5 1.5 0 00.014 7.07c-.166 3.539 1.146 7.684 5.1 9.74a.5.5 0 01.127.793l-.82.839a1.5 1.5 0 00.587 2.674 5.469 5.469 0 001.106.107 12.812 12.812 0 005.364-1.507 1 1 0 00.463-1.337z\"/><path d=\"M23.942 13.753a.5.5 0 00-.674-.391 8.847 8.847 0 01-3.724.388 6.666 6.666 0 00-3.9.613 3.792 3.792 0 00-2.158 2.706 2.781 2.781 0 00.369 1.653.5.5 0 01-.112.618 20.534 20.534 0 00-3.274 3.484.75.75 0 001.233.854 24.3 24.3 0 016.89-5.98.75.75 0 01.756 1.295 23.331 23.331 0 00-3.553 2.556.5.5 0 00-.031.724 2.273 2.273 0 00.6.446 2.533 2.533 0 001.147.266 5.418 5.418 0 002.7-.865c2.001-1.183 4.175-5.452 3.731-8.367z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});