define("ember-svg-jar/inlined/gesture-two-fingers-swipe-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-two-fingers-swipe-down</title><path d=\"M14.533 9.345H7.47a.25.25 0 01-.143-.456C9.465 7.4 8.776 5.034 7.1 6.128L2.736 9.04a.5.5 0 00-.223.416v5.435a.5.5 0 00.411.492c1.1.2 3.683.664 4.166.776 1.093.252 1.429-.662 1.855-1.616a.249.249 0 01.292-.14l4.663 1.246a.929.929 0 00.454-1.8l-4.597-1.431a.249.249 0 01-.168-.3l.143-.585a.25.25 0 01.243-.19h4.558a1 1 0 000-1.998zM20.61 23.373a.751.751 0 00.212-1.411l-.968-.484a.249.249 0 01-.118-.321 24.013 24.013 0 001.751-9.284A20.153 20.153 0 0018.44.565a1 1 0 10-1.658 1.118 18.178 18.178 0 012.7 10.19 22.07 22.07 0 01-1.531 8.357.255.255 0 01-.144.139.251.251 0 01-.2-.011l-.7-.352a.751.751 0 00-1.032.95l.979 2.445a.752.752 0 00.82.461z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});