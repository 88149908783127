define("ember-svg-jar/inlined/tools-gasket-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-gasket-1</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v1A1.5 1.5 0 001.5 4h8a.5.5 0 01.5.5V15a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V4.5a.5.5 0 01.5-.5h8A1.5 1.5 0 0024 2.5v-1A1.5 1.5 0 0022.5 0zM13.947 17.276A.5.5 0 0013.5 17h-3a.5.5 0 00-.447.276l-.789 1.578A2.511 2.511 0 009 19.972V23a1 1 0 001 1h4a1 1 0 001-1v-3.028a2.511 2.511 0 00-.264-1.118z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});