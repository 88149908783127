define("ember-svg-jar/inlined/time-clock-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-clock-file</title><path d=\"M23.414 3L21 .585A2 2 0 0019.586 0H8.125a2 2 0 00-2 2v7.151a.247.247 0 00.242.249 19.68 19.68 0 011.485.083.247.247 0 00.273-.249V2.5a.5.5 0 01.5-.5h10.754a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353v13.254a.5.5 0 01-.5.5h-6.733a.244.244 0 00-.241.21 7.935 7.935 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.414A2 2 0 0023.414 3z\"/><path d=\"M6.75 10.5a6.75 6.75 0 106.75 6.75 6.758 6.758 0 00-6.75-6.75zm0 11.5a4.75 4.75 0 114.75-4.75A4.756 4.756 0 016.75 22z\"/><path d=\"M8.75 16.25H8a.25.25 0 01-.25-.25v-1.25a1 1 0 00-2 0v2.5a1 1 0 001 1h2a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});