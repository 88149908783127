define("ember-svg-jar/inlined/messages-bubble-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-lock</title><path d=\"M12.5 17a3 3 0 011.385-2.527.252.252 0 00.115-.211v-.512c0-2.576 2.64-4.5 5-4.5a5.245 5.245 0 012.577.725.251.251 0 00.373-.187A6.641 6.641 0 0022 9c0-4.962-4.935-9-11-9S0 4.038 0 9a8.077 8.077 0 002.657 5.854L1.03 19.329a.5.5 0 00.662.632l5.832-2.429A13.211 13.211 0 0011 18a12.751 12.751 0 001.275-.07.249.249 0 00.225-.248z\"/><path d=\"M22.5 15.5v-1.25a3.5 3.5 0 00-7 0v1.25A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.5-1.5zM19 12.75a1.5 1.5 0 011.5 1.5v1.25h-3v-1.25a1.5 1.5 0 011.5-1.5zm1 7.5a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});