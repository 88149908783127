define("ember-svg-jar/inlined/saving-bull-decrease", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>saving-bull-decrease</title><path d=\"M23.691 8.538a.5.5 0 00-.545.108l-1.462 1.463a.25.25 0 01-.36-.007l-4.537-4.9a3.5 3.5 0 00-2.977-1.068l-6.023.753a.506.506 0 01-.416-.143L3.061.439A1.5 1.5 0 00.939 2.561l4.309 4.308a3.485 3.485 0 002.909 1l6.024-.753a.5.5 0 01.413.139l4.617 4.98a.251.251 0 01-.007.347l-1.562 1.561A.5.5 0 0018 15h5.5a.5.5 0 00.5-.5V9a.5.5 0 00-.309-.462z\"/><path d=\"M12.113 11.654H10.7c-.667 0-1.1-.943-1.294-1.5a.251.251 0 00-.422-.084 3.922 3.922 0 00-1.114 2.388 2.846 2.846 0 00.323 1.342.255.255 0 01.016.2.251.251 0 01-.135.147L8 14.18a1.983 1.983 0 01-1 .212 1.487 1.487 0 01-.874-.191 3.863 3.863 0 00-.184-.094.247.247 0 01-.131-.145.25.25 0 01.014-.2 2.866 2.866 0 00.3-1.309 3.922 3.922 0 00-1.114-2.384.251.251 0 00-.422.084c-.2.553-.627 1.5-1.294 1.5H1.887A2.2 2.2 0 000 13.871a2.179 2.179 0 001.415 2.359l.365.183a.249.249 0 01.124.308 4.087 4.087 0 00-.25 1.394.5.5 0 00.11.313l2.051 2.564a.261.261 0 01.047.1l.5 1.995a.5.5 0 00.114.212c.481.536 2.366.7 2.526.7s2.045-.169 2.526-.7a.5.5 0 00.114-.212l.5-1.995a.261.261 0 01.047-.1l2.051-2.564a.5.5 0 00.11-.313 4.7 4.7 0 00-.219-1.42.249.249 0 01.126-.3l.332-.166A2.179 2.179 0 0014 13.871a2.2 2.2 0 00-1.887-2.217zM9.25 16.116a.75.75 0 11-.75.75.75.75 0 01.75-.75zm-4.5 0a.75.75 0 11-.75.75.75.75 0 01.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});