define("ember-svg-jar/inlined/camping-tent-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camping-tent-map</title><path d=\"M23.707 8.793l-5-5.5A1 1 0 0018 3h-5.25a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v1.75a.25.25 0 01-.25.25H1a1 1 0 00-.83 1.555l2.809 4.688a.5.5 0 010 .514L.17 14.445A1 1 0 001 16h17a1 1 0 00.707-.293l5-5.5a1 1 0 000-1.414zm-8.2 4.716a.986.986 0 01-.857.491h-.9a.5.5 0 01-.429-.243L11.964 11.5a.249.249 0 00-.428 0l-1.357 2.261A.5.5 0 019.75 14h-.9a.986.986 0 01-.858-.491 1 1 0 01-.009-1l3.074-4.617a.249.249 0 000-.277L9.834 5.777a.5.5 0 11.832-.554l.876 1.314a.25.25 0 00.416 0l.876-1.314a.5.5 0 01.832.554l-1.223 1.835a.249.249 0 000 .277l3.057 4.584.021.033a1 1 0 01-.013 1.003zM11.5 24a1 1 0 01-1-1v-5.25a.751.751 0 01.75-.75h.5a.751.751 0 01.75.75V23a1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});