define("ember-svg-jar/inlined/road-sign-turn-left-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-turn-left-1-alternate</title><path d=\"M23.707 12.707a1 1 0 000-1.414l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0zm-11.53 8.7a.25.25 0 01-.354 0l-9.232-9.23a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/><path d=\"M11 9a.25.25 0 01-.25-.25V8a.75.75 0 00-1.28-.53l-2 2a.749.749 0 000 1.06l2 2a.746.746 0 00.53.22.75.75 0 00.75-.75v-.75A.25.25 0 0111 11h1a1 1 0 011 1v4a1 1 0 002 0v-4a3 3 0 00-3-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});