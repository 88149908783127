define("ember-svg-jar/inlined/retouch-smile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>retouch-smile</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22.041A10.041 10.041 0 1122.041 12 10.052 10.052 0 0112 22.041z\"/><path d=\"M12 4.5a7.5 7.5 0 107.5 7.5A7.508 7.508 0 0012 4.5zM9.5 9.75a1 1 0 11-1 1 1 1 0 011-1zm-.094 4.886a.75.75 0 011.02-.292 3.312 3.312 0 003.148 0 .75.75 0 11.727 1.311 4.758 4.758 0 01-4.6 0 .75.75 0 01-.295-1.019zM14.5 11.75a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});