define("ember-svg-jar/inlined/time-clock-file-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-clock-file-upload</title><path d=\"M23.414 3L21 .587A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.276a.247.247 0 00.242.25 19.68 19.68 0 011.485.083A.247.247 0 008 9.36V2.251A.25.25 0 018.25 2h11.232a.249.249 0 01.177.073l2.268 2.268a.248.248 0 01.073.177v13.733a.25.25 0 01-.25.25h-7.108a.243.243 0 00-.241.21 7.935 7.935 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M6.5 11a6.5 6.5 0 106.5 6.5A6.508 6.508 0 006.5 11zm2.45 6.217a.5.5 0 01-.45.283h-.748a.252.252 0 00-.252.252V20.5a1 1 0 01-2 0v-2.75a.25.25 0 00-.25-.25H4.5a.5.5 0 01-.391-.813l2-2.5a.5.5 0 01.782 0l2 2.5a.5.5 0 01.059.531zM15.5 13.251A4.75 4.75 0 1010.75 8.5a4.756 4.756 0 004.75 4.751zm0-8A3.25 3.25 0 1112.25 8.5a3.254 3.254 0 013.25-3.249z\"/><path d=\"M15.5 9.251h1.768a.75.75 0 100-1.5H16.5a.252.252 0 01-.25-.251v-.767a.75.75 0 00-1.5 0V8.5a.75.75 0 00.75.751z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});