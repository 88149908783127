define("ember-svg-jar/inlined/fireplace-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fireplace-1</title><rect x=\".25\" y=\"1\" width=\"23.5\" height=\"4\" rx=\"1.5\" ry=\"1.5\"/><path d=\"M12.345 15.031a.306.306 0 00-.39.442c.732 1.117 1.024 2.749-.316 2.749a1.1 1.1 0 01-1.128-.745.305.305 0 00-.511-.1 3.056 3.056 0 105.306 2.067 4.9 4.9 0 00-2.961-4.413z\"/><rect x=\"8.75\" y=\"6.5\" width=\"6.5\" height=\"4.5\" rx=\".5\" ry=\".5\"/><rect x=\"16.75\" y=\"6.5\" width=\"6\" height=\"4.5\" rx=\".5\" ry=\".5\"/><rect x=\"1.25\" y=\"6.5\" width=\"6\" height=\"4.5\" rx=\".5\" ry=\".5\"/><rect x=\"19.75\" y=\"12.5\" width=\"3\" height=\"4.5\" rx=\".5\" ry=\".5\"/><rect x=\"1.25\" y=\"12.5\" width=\"3\" height=\"4.5\" rx=\".5\" ry=\".5\"/><path d=\"M18.25 16.5V13a.5.5 0 00-.5-.5H6.25a.5.5 0 00-.5.5v3.5a.5.5 0 00.5.5h.459a.249.249 0 00.215-.124 5.917 5.917 0 0110.152 0 .249.249 0 00.215.124h.459a.5.5 0 00.5-.5zM22.25 18.5h-4.007a.5.5 0 00-.5.531c.005.073.007.146.007.219v3.25a.5.5 0 00.5.5h4a.5.5 0 00.5-.5V19a.5.5 0 00-.5-.5zM5.757 18.5H1.75a.5.5 0 00-.5.5v3.5a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-3.25c0-.073 0-.146.007-.219a.5.5 0 00-.5-.531z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});