define("ember-svg-jar/inlined/saving-money-flower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>saving-money-flower</title><path d=\"M22.017 13.351a.5.5 0 00-.756-.19 6 6 0 01-2.348 1.008 6.476 6.476 0 00-2.105.831 2.575 2.575 0 00-1.287 2.06 1.885 1.885 0 00.1.617.249.249 0 01-.081.276 15.8 15.8 0 00-1.691 1.573.25.25 0 01-.433-.17v-4.45a.249.249 0 01.209-.247 7.38 7.38 0 10-2.418 0 .25.25 0 01.209.247v3.458a.25.25 0 01-.408.194 10.816 10.816 0 00-1.908-1.24.144.144 0 01-.079-.135 2.785 2.785 0 00-1.371-2.252 6.3 6.3 0 00-5.85.075.5.5 0 00-.071.776 5.455 5.455 0 011.232 1.968 5.956 5.956 0 001.057 1.8 2.52 2.52 0 001.81.908 1.743 1.743 0 00.27-.02 2.186 2.186 0 001.58-1.276.256.256 0 01.083-.09l.036-.024a.249.249 0 01.25-.017 7.744 7.744 0 013.336 2.926.248.248 0 01.035.127V23a1.07 1.07 0 002 .461 14.112 14.112 0 013.4-3.962.095.095 0 01.139.024 2.333 2.333 0 001.552 1.191 2.017 2.017 0 00.374.035 3.23 3.23 0 002.246-1.119 6.828 6.828 0 00.888-6.279zm-11.4-1.5a.875.875 0 110-1.75h2.44a.735.735 0 00.273-1.418L10.855 7.7a2.485 2.485 0 01.482-4.753.25.25 0 00.206-.246V2.58a.875.875 0 011.75 0v.075a.25.25 0 00.25.25h.675a.875.875 0 010 1.75h-2.44a.735.735 0 00-.273 1.417l2.477.99a2.485 2.485 0 01-.483 4.753.251.251 0 00-.206.246v.119a.875.875 0 01-1.75 0v-.075a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});