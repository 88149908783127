define("ember-svg-jar/inlined/wifi-delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-delete</title><path d=\"M15.212 8.663a1 1 0 001.414-1.414 9.75 9.75 0 00-13.775 0 1 1 0 001.414 1.414 7.751 7.751 0 0110.947 0z\"/><path d=\"M17.684 5.537A1 1 0 0019.1 4.123a13.255 13.255 0 00-18.721 0 1 1 0 001.413 1.414 11.253 11.253 0 0115.892 0zM5.322 10.4a1 1 0 101.414 1.414 4.232 4.232 0 015.14-.668 1.034 1.034 0 001.067-.115l.012-.009a1 1 0 00-.012-1.615 6.407 6.407 0 00-7.621.993zM17.554 11.024a6.361 6.361 0 106.362 6.361 6.368 6.368 0 00-6.362-6.361zm-2.6 4.819a.75.75 0 111.06-1.061l1.365 1.366a.252.252 0 00.354 0l1.367-1.366a.75.75 0 011.061 1.061l-1.365 1.365a.25.25 0 000 .354l1.365 1.365a.75.75 0 01-1.061 1.061l-1.365-1.366a.252.252 0 00-.354 0l-1.365 1.366a.75.75 0 11-1.06-1.061l1.365-1.365a.25.25 0 000-.354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});