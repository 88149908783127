define("ember-svg-jar/inlined/network-phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>network-phone</title><path d=\"M12.012 14.687a1 1 0 00-1-1h-3.37a.25.25 0 01-.245-.2 13.251 13.251 0 01-.274-2.712A13.71 13.71 0 017.3 8.564a.25.25 0 01.247-.21c13.454 0 12.783.014 12.979-.059a1 1 0 00.587-1.287C18.512.04 11.431-.021 10.946 0 .628.483.234 10.211.234 10.776c0 10.105 10.245 10.778 10.777 10.778a1.052 1.052 0 00.895-.554c0-.01 0-.021.008-.03a1.01 1.01 0 00-.455-1.312 8.25 8.25 0 01-3.207-3.613.251.251 0 01.225-.36h2.535a1 1 0 001-.998zM5.339 13.4a.248.248 0 01-.055.2.253.253 0 01-.191.089H2.92a.251.251 0 01-.238-.171A8.6 8.6 0 012.53 8.54a.251.251 0 01.242-.186h2.25a.247.247 0 01.188.086.25.25 0 01.06.2 15.82 15.82 0 00.069 4.76zm5.523-11.122a.249.249 0 01.3 0A8.894 8.894 0 0114 6.007a.25.25 0 01-.23.347H8.252a.25.25 0 01-.23-.347 8.9 8.9 0 012.84-3.729zm7.48 3.688a.25.25 0 01-.208.388h-1.7a.248.248 0 01-.237-.172 11.981 11.981 0 00-1.268-2.7.25.25 0 01.332-.353 8.8 8.8 0 013.081 2.837zM6.784 3.09a.25.25 0 01.332.353 11.946 11.946 0 00-1.29 2.739.248.248 0 01-.237.172h-1.7a.25.25 0 01-.209-.387A8.829 8.829 0 016.784 3.09zm.328 15.018a.25.25 0 01-.331.355 8.837 8.837 0 01-2.752-2.374.25.25 0 01.2-.4h1.52a.251.251 0 01.235.163 11.651 11.651 0 001.128 2.256z\"/><path d=\"M23.766 11.286a1.979 1.979 0 00-1.978-1.977h-5.4a1.979 1.979 0 00-1.978 1.977v10.735A1.98 1.98 0 0016.39 24h5.4a1.98 1.98 0 001.978-1.978V11.286zm-2 .5v7.287a.5.5 0 01-.5.5h-4.358a.5.5 0 01-.5-.5l-.017-7.268a.5.5 0 01.5-.5l4.375-.019a.5.5 0 01.5.502z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});