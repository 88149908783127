define("ember-svg-jar/inlined/common-file-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-share</title><path d=\"M23.414 3L21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v9.768a.246.246 0 00.467.111A4.255 4.255 0 017.782 10.3.5.5 0 008 9.9V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.121 2.122a.5.5 0 01.147.353V18a.5.5 0 01-.5.5h-7.519a.247.247 0 00-.212.379 4.222 4.222 0 01.568 1.233.509.509 0 00.484.387H22a2 2 0 002-2V4.414A2 2 0 0023.414 3z\"/><path d=\"M8.442 16a.245.245 0 01.266.012 2.74 2.74 0 004.167-1.425A2.758 2.758 0 0010.25 11a2.808 2.808 0 00-2.713 3.059.245.245 0 01-.115.221L4.558 16a.245.245 0 01-.266-.012 2.71 2.71 0 00-2.406-.349 2.706 2.706 0 00-1.762 1.774A2.759 2.759 0 002.75 21a2.729 2.729 0 001.887-.761.244.244 0 01.259-.053l2.465 1a.24.24 0 01.139.214 2.753 2.753 0 105.361-1.025 2.681 2.681 0 00-1.736-1.74 2.74 2.74 0 00-2.767.623.24.24 0 01-.258.053L5.553 18.3a.114.114 0 01-.06-.084l-.03-.353a.118.118 0 01.056-.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});