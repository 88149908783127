define("ember-svg-jar/inlined/modern-weapon-tank-machine-gun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-weapon-tank-machine-gun</title><path d=\"M20.121 18.879A2.98 2.98 0 0018 18H5.329a3.02 3.02 0 00-2.176.936L.239 22.35A1 1 0 001 24h21.329a1 1 0 00.76-1.65zM23.707 3a1 1 0 000-1.414L22.414.293a1 1 0 00-1.414 0l-1.853 1.853a1.9 1.9 0 00-.547 1.568.5.5 0 01-.143.411l-5.09 5.094a.5.5 0 01-.357.146 2.473 2.473 0 00-1.777.7l-2.412 2.407a.25.25 0 01-.406-.077.994.994 0 01.206-1.1l1.086-1.086a1 1 0 00-1.414-1.416L7.207 9.879a3 3 0 00-.153 4.074.218.218 0 01-.011.3l-.043.04a1 1 0 000 1.414l1.026 1.025a.5.5 0 00.353.147h4.242a.5.5 0 00.354-.147l3.172-3.172a1.5 1.5 0 000-2.121l-.733-.732a.5.5 0 010-.707l4.458-4.459a.5.5 0 01.419-.142 1.618 1.618 0 00.209.014 1.91 1.91 0 001.354-.56z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});