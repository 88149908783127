define("ember-svg-jar/inlined/app-window-move-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-move-up</title><path d=\"M24 4.751a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h18a3 3 0 003-3zm-14.346-1a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.562.091a1 1 0 01.908-.591.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.987.987 0 01.092-.409zM22 19.251a1 1 0 01-1 1H3a1 1 0 01-1-1V7a.25.25 0 01.25-.25h19.5A.25.25 0 0122 7z\"/><path d=\"M16.987 16.753H19a.75.75 0 110 1.5h-2.013a.75.75 0 110-1.5zM10.987 16.753h2a.75.75 0 110 1.5h-2a.75.75 0 010-1.5zM4.987 16.753h2a.75.75 0 110 1.5h-2a.75.75 0 010-1.5zM11.583 8.4a.5.5 0 01.707 0l2.5 2.5a.5.5 0 01-.353.853h-1.25a.25.25 0 00-.25.25v2.727a1 1 0 01-2 0V12a.25.25 0 00-.25-.25h-1.25a.5.5 0 01-.354-.853z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});