define("ember-svg-jar/inlined/people-man-beard-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-beard-1</title><path d=\"M7.4 19.071a14.2 14.2 0 00-5.874 2.467A2.452 2.452 0 00.568 23.5a.5.5 0 00.5.5h21.87a.5.5 0 00.5-.5 2.452 2.452 0 00-.961-1.965 14.2 14.2 0 00-5.877-2.464.527.527 0 00-.548.278 4.856 4.856 0 01-2.18 2.314l-.085.043a4.2 4.2 0 01-.43.173c-.107.037-.215.067-.324.1s-.209.055-.316.074a3.729 3.729 0 01-1.428 0c-.107-.019-.211-.047-.315-.074s-.218-.059-.325-.1a4.2 4.2 0 01-.43-.173l-.086-.044a4.857 4.857 0 01-2.179-2.313.527.527 0 00-.548-.278z\"/><circle cx=\"13.988\" cy=\"9.692\" r=\".994\"/><circle cx=\"10.012\" cy=\"9.692\" r=\".994\"/><path d=\"M12 18.741a4.724 4.724 0 001.979-.435l.356-.165 1.943-.9a4.75 4.75 0 002.729-4.641l-.3-2.928V7.7C18.71 3.917 16.2 0 12 0a.715.715 0 00-.743.667.249.249 0 01-.11.193 1.217 1.217 0 01-.638.134C7.631.994 5.29 4 5.29 7.7v1.968l-.3 2.95v.007a4.748 4.748 0 002.732 4.62l1.944.9.355.165a4.724 4.724 0 001.979.431zM10.39 15a.635.635 0 01.616-.48h1.988a.635.635 0 01.3 1.194 2.391 2.391 0 01-.433.181 3.012 3.012 0 01-.864.124 2.968 2.968 0 01-.864-.126 2.294 2.294 0 01-.431-.178.636.636 0 01-.312-.715zM9.045 7.326a.248.248 0 01.3-.054 5.472 5.472 0 005.312 0 .248.248 0 01.3.054 4.206 4.206 0 002.081 1.441.249.249 0 01.183.24v.353a.253.253 0 01-.05.15l-1.924 2.565a1.24 1.24 0 01-1.386.433 5.917 5.917 0 00-3.718 0 1.241 1.241 0 01-1.386-.434L6.831 9.509a.25.25 0 01-.05-.149v-.353a.249.249 0 01.183-.24 4.206 4.206 0 002.081-1.441z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});