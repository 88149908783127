define("ember-svg-jar/inlined/synchronize-arrows-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>synchronize-arrows-search</title><path d=\"M.639 15.735a.489.489 0 00.171.858l4.712 1.29a.518.518 0 00.129.016.487.487 0 00.489-.5l-.08-4.887a.489.489 0 00-.789-.377l-1.455 1.133a.249.249 0 01-.379-.091 7.519 7.519 0 01.327-7.095 7.053 7.053 0 016.464-3.35 1.222 1.222 0 10.149-2.439A9.551 9.551 0 001.686 4.8a10.028 10.028 0 00-.181 10 .25.25 0 01-.066.318zM15.831 13.538a7.067 7.067 0 01-6.464 3.35 1.222 1.222 0 10-.149 2.439c.2.012.39.018.585.018a9.5 9.5 0 005.637-1.859.249.249 0 01.324.024l5.894 5.854a1.222 1.222 0 001.722-1.734l-5.887-5.847a.249.249 0 01-.024-.327c.155-.205.3-.414.441-.635a10.025 10.025 0 00.176-10.007.249.249 0 01.065-.317l.815-.641A.488.488 0 0018.791 3l-4.722-1.263a.484.484 0 00-.428.088.489.489 0 00-.187.4l.109 4.875a.489.489 0 00.281.431.475.475 0 00.208.047.487.487 0 00.3-.1l1.425-1.119a.254.254 0 01.213-.047.249.249 0 01.168.137 7.51 7.51 0 01-.327 7.089z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});