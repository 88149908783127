define("ember-svg-jar/inlined/single-man-shield.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-shield</title><path d=\"M23.5 1.957A1.458 1.458 0 0022.044.5H1.956A1.458 1.458 0 00.5 1.957V9.25a14.644 14.644 0 0011.4 14.24.5.5 0 00.2 0A14.644 14.644 0 0023.5 9.25zm-6 14.311a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5 4.924 4.924 0 01.487-1.988c.369-.739 1.5-1.156 3.073-1.733l.19-.071a.249.249 0 00.163-.234v-.451a.254.254 0 00-.067-.171 2.127 2.127 0 01-.561-1.139.239.239 0 00-.045-.109 1.218 1.218 0 01-.188-.434A1.322 1.322 0 019.649 9a.252.252 0 00.021-.19 2.356 2.356 0 01.053-1.973 1.061 1.061 0 01.58-.392.253.253 0 00.121-.076 2.654 2.654 0 011.984-.6c.975.023 2.079.362 2.256 1.245a3.515 3.515 0 01-.306 1.816.248.248 0 00.01.2 1.336 1.336 0 01.08.905 1.221 1.221 0 01-.188.433.248.248 0 00-.045.109 2.13 2.13 0 01-.561 1.14.254.254 0 00-.067.171v.451a.249.249 0 00.163.234l.19.071c1.57.577 2.7.994 3.073 1.733a4.924 4.924 0 01.487 1.991z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});