define("ember-svg-jar/inlined/modern-music-monitor-speaker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-music-monitor-speaker</title><path d=\"M10.25 16.5a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h2a.25.25 0 00.25-.25v-3a.25.25 0 00-.25-.25H2.5A.5.5 0 012 11V2.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v2.75a.25.25 0 00.25.25h1.5a.25.25 0 00.25-.25V2a2 2 0 00-2-2H2a2 2 0 00-2 2v11a2 2 0 002 2h5.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-2.5a1 1 0 000 2h7a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25z\"/><path d=\"M24 9a2 2 0 00-2-2h-6a2 2 0 00-2 2v13a2 2 0 002 2h6a2 2 0 002-2zm-5 12.5a3 3 0 113-3 3 3 0 01-3 3zm0-12a2 2 0 11-2 2 2 2 0 012-2zM11.806 4.538A1.531 1.531 0 009.9 3.06l-2.633.852a1.529 1.529 0 00-1.245 1.5v2.217a.083.083 0 01-.083.083 1.528 1.528 0 000 3.056A1.673 1.673 0 007.527 9.24V5.6a.25.25 0 01.173-.242l2.281-.718a.251.251 0 01.325.239v1.2a.083.083 0 01-.083.083 1.528 1.528 0 100 3.055 1.676 1.676 0 001.583-1.527z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});