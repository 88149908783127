define("ember-svg-jar/inlined/rain-umbrella-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rain-umbrella-1</title><path d=\"M11.327 20.886a.25.25 0 00-.333.236V23a1 1 0 002 0v-1.878a.251.251 0 00-.334-.236 2.005 2.005 0 01-1.333 0zM10.994 6v.316a.25.25 0 01-.224.249A11.68 11.68 0 00.494 18.5v.5a.5.5 0 001 0c0-.827.9-1.5 2-1.5s2 .673 2 1.5a.5.5 0 001 0c0-.813 1.144-1.5 2.5-1.5s2.5.687 2.5 1.5a.5.5 0 001 0c0-.813 1.144-1.5 2.5-1.5s2.5.687 2.5 1.5a.5.5 0 001 0c0-.827.9-1.5 2-1.5s2 .673 2 1.5a.5.5 0 001 0v-.5A11.68 11.68 0 0013.217 6.565a.249.249 0 01-.223-.248V6a1 1 0 00-2 0zM.553 4.895A1 1 0 001.9 4.448l1.5-3A1 1 0 101.606.553l-1.5 3a1 1 0 00.447 1.342zM7.384 4.895a1 1 0 001.342-.447l1.5-3A1 1 0 008.437.553l-1.5 3a1 1 0 00.447 1.342zM14.221 4.895a1 1 0 001.342-.447l1.5-3a1 1 0 10-1.789-.895l-1.5 3a1 1 0 00.447 1.342zM21.052 4.895a1 1 0 001.342-.447l1.5-3a1 1 0 00-1.789-.895l-1.5 3a1 1 0 00.447 1.342z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});