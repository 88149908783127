define("@gavant/ember-shopify-draggable/templates/components/droppable-dropzone", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "nfzLDIkJ",
    "block": "[[[18,1,[[28,[37,1],null,[[\"item\"],[[50,\"droppable-item\",0,null,[[\"dropzone\"],[[30,0]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@gavant/ember-shopify-draggable/templates/components/droppable-dropzone.hbs",
    "isStrictMode": false
  });
});