define("ember-svg-jar/inlined/single-man-actions-wifi.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-wifi</title><path d=\"M7 10a5 5 0 10-5-5 5.006 5.006 0 005 5zM4.1 4.257a.5.5 0 01.741-.3A6.959 6.959 0 008.46 5a6.36 6.36 0 00.929-.07.5.5 0 01.425.139.5.5 0 01.143.425A3 3 0 014 5a2.841 2.841 0 01.1-.743z\"/><path d=\"M10.447 14.451a11.335 11.335 0 011.727-1.127.5.5 0 00.105-.809A7.461 7.461 0 00.021 15.857.5.5 0 00.5 16.5h8.535a.5.5 0 00.5-.432 2.473 2.473 0 01.912-1.617z\"/><circle cx=\"17.5\" cy=\"22.25\" r=\"1.75\"/><path d=\"M11.379 15.627a1 1 0 101.242 1.567 7.826 7.826 0 019.758 0 1 1 0 101.242-1.567 9.817 9.817 0 00-12.242 0z\"/><path d=\"M13.853 18.451a1 1 0 001.293 1.526 3.586 3.586 0 014.707 0 1 1 0 101.293-1.526 5.624 5.624 0 00-7.293 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});