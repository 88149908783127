define("ember-svg-jar/inlined/button-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-refresh</title><path d=\"M9.769.359A1 1 0 009.01.008a1 1 0 00-.757.347l-3.016 3.5a1 1 0 00.757 1.653h1.514a.25.25 0 01.25.25v8.772a2.232 2.232 0 002.25 2.21h10.5a1.25 1.25 0 000-2.5h-10a.25.25 0 01-.25-.25V5.758a.25.25 0 01.25-.25h1.484a1 1 0 00.761-1.649z\"/><path d=\"M16.992 13.24a1.25 1.25 0 001.25-1.25v-2.5a2.253 2.253 0 00-2.25-2.25h-3.5a1.25 1.25 0 000 2.5h3a.25.25 0 01.25.25v2a1.25 1.25 0 001.25 1.25zM2.242 8.49a1.25 1.25 0 001.25 1.25h2a1.25 1.25 0 000-2.5h-2a1.25 1.25 0 00-1.25 1.25zM19.992 18.49h-6a1 1 0 00-.759 1.65l3 3.5a1 1 0 001.518 0l3-3.5a1 1 0 00-.759-1.65z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});