define("ember-svg-jar/inlined/presentation-desk-paper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>presentation-desk-paper</title><path d=\"M.41 18.374a.249.249 0 00-.247.288l.164 1.075a1.5 1.5 0 001.455 1.137H9.3a.25.25 0 01.25.25v2.25a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-2.25a.25.25 0 01.25-.25h7.42a1.507 1.507 0 001.464-1.183l.153-1.03a.25.25 0 00-.247-.287zM23.523 17.374a.251.251 0 00.206-.109.247.247 0 00.027-.231l-2.994-7.7a1.49 1.49 0 00-1.4-.957H8.8a.25.25 0 00-.25.25v2.75a.75.75 0 01-1.5 0v-3.26A1.987 1.987 0 017.634 6.7l.507-.5a.25.25 0 01.241-.065 2.131 2.131 0 001.946-.449l2.09-1.81A2.133 2.133 0 109.626.645l-2.091 1.81a2.139 2.139 0 00-.692 2.05.248.248 0 01-.068.228l-.555.555a3.97 3.97 0 00-1.172 2.829v.007a.25.25 0 01-.25.25h-.066a1.49 1.49 0 00-1.4.952c-2.129 5.314-2.844 7.1-3.087 7.7a.25.25 0 00.232.343zm-4.653-1.72a.6.6 0 01-.464.22h-7.758a.6.6 0 01-.588-.718l.96-4.8a.6.6 0 01.588-.482h5.838a.6.6 0 01.589.482l.96 4.8a.6.6 0 01-.125.498z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});