define("ember-svg-jar/inlined/single-man-actions-flash.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-flash</title><path d=\"M.619 18.062H2.41a.247.247 0 01.247.227l.443 5.257a.494.494 0 00.488.454h3.959a.5.5 0 00.493-.454l.438-5.257a.247.247 0 01.246-.227h1.792a.5.5 0 00.495-.5V14.1a5.444 5.444 0 00-10.887 0v3.464a.494.494 0 00.495.498z\"/><path d=\"M1.361 4.206A4.206 4.206 0 105.567 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227a2.229 2.229 0 01-2.226-2.227 2.135 2.135 0 01.156-.8.248.248 0 01.36-.119 5.314 5.314 0 002.8.793 5.353 5.353 0 00.9-.077.205.205 0 01.165.045.2.2 0 01.072.155 2.229 2.229 0 01-2.227 2.23zM23.064 16.082h-1.273a.247.247 0 01-.247-.247v-4.206a.495.495 0 00-.878-.313l-5.219 6.395a.813.813 0 00.621 1.338h1.273a.247.247 0 01.247.247v4.206a.5.5 0 00.329.466.476.476 0 00.163.032.494.494 0 00.383-.182l5.219-6.4a.814.814 0 00-.621-1.338z\"/><path d=\"M.619 18.062H2.41a.247.247 0 01.247.227l.443 5.257a.494.494 0 00.488.454h3.959a.5.5 0 00.493-.454l.438-5.257a.247.247 0 01.246-.227h1.792a.5.5 0 00.495-.5V14.1a5.444 5.444 0 00-10.887 0v3.464a.494.494 0 00.495.498z\"/><path d=\"M1.361 4.206A4.206 4.206 0 105.567 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227a2.229 2.229 0 01-2.226-2.227 2.135 2.135 0 01.156-.8.248.248 0 01.36-.119 5.314 5.314 0 002.8.793 5.353 5.353 0 00.9-.077.205.205 0 01.165.045.2.2 0 01.072.155 2.229 2.229 0 01-2.227 2.23zM23.064 16.082h-1.273a.247.247 0 01-.247-.247v-4.206a.495.495 0 00-.878-.313l-5.219 6.395a.813.813 0 00.621 1.338h1.273a.247.247 0 01.247.247v4.206a.5.5 0 00.329.466.476.476 0 00.163.032.494.494 0 00.383-.182l5.219-6.4a.814.814 0 00-.621-1.338z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});