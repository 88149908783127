define("ember-svg-jar/inlined/wolf-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wolf-1</title><path d=\"M23 10.5a.5.5 0 00-.146-.354L20 7.3V.5a.5.5 0 00-.658-.475c-.177.06-3.925 1.337-4.7 3.635a.5.5 0 01-.474.34H9.834a.5.5 0 01-.474-.34C8.583 1.364 4.835.087 4.658.027A.5.5 0 004 .5v6.8l-2.854 2.848A.5.5 0 001 10.5c0 4.262 3.311 6.392 5.5 7.339C6.813 23.355 10.16 24 12 24c2.667 0 5.217-1.468 5.516-6.166C19.707 16.882 23 14.752 23 10.5zM12 22c-4.142 0-3.5-4.5-3.5-8 0 0-3-2-3-3.5A3.754 3.754 0 019 7c1.5 0 3 2 3 2s1.5-2 3-2a3.754 3.754 0 013.5 3.5c0 1.5-3 3.5-3 3.5 0 2.838.776 8-3.5 8z\"/><path d=\"M13.123 18h-2.246a.5.5 0 00-.5.5c0 1.853 1.242 2 1.623 2s1.623-.144 1.623-2a.5.5 0 00-.5-.5z\"/><circle cx=\"9.25\" cy=\"10.749\" r=\"1.25\"/><circle cx=\"14.75\" cy=\"10.749\" r=\"1.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});