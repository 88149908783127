define("ember-svg-jar/inlined/style-three-pin-bottle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-bottle</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm3 15.5a1.5 1.5 0 01-1.5 1.5h-3A1.5 1.5 0 019 15.5V11a3.525 3.525 0 011.745-3.224.5.5 0 00.255-.437V5.25a.25.25 0 00-.25-.25.75.75 0 010-1.5h2.5a.75.75 0 010 1.5.25.25 0 00-.25.25v2.089a.5.5 0 00.255.437A3.525 3.525 0 0115 11z\"/><path d=\"M11.248 8.568A2.374 2.374 0 0010 10.5a.5.5 0 001 0 1.388 1.388 0 01.752-1.068.5.5 0 00-.5-.864z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});