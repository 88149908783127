define("ember-svg-jar/inlined/book-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-search</title><path d=\"M22.957 23.707a1 1 0 000-1.414l-2.344-2.344a.5.5 0 01-.077-.609 5.48 5.48 0 00.772-2.811 5.541 5.541 0 10-2.718 4.757.5.5 0 01.609.077l2.344 2.344a1 1 0 001.414 0zm-7.178-3.648a3.53 3.53 0 113.529-3.53 3.534 3.534 0 01-3.529 3.53z\"/><path d=\"M15.25 23.7a.25.25 0 00-.224-.248 7 7 0 114.827-12.624.247.247 0 00.26.021.25.25 0 00.137-.223V6a2 2 0 00-1.6-1.96.5.5 0 01-.4-.489V2a2 2 0 00-2-2H3.75a3 3 0 00-3 3v18a3 3 0 003 3H15a.25.25 0 00.25-.25zM2.75 3a1 1 0 011-1H16a.25.25 0 01.25.25v1.5A.25.25 0 0116 4H3.75a1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});