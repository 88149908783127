define("ember-svg-jar/inlined/messages-bubble-square-forward-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-forward-all</title><path d=\"M13.286 13.938a.25.25 0 00.214-.248v-.19a1.5 1.5 0 012.434-1.174l1.213.966a.251.251 0 00.4-.139 1.454 1.454 0 01.764-.985 1.5 1.5 0 011.555.107l1.24.877a.25.25 0 00.394-.2V1.5A1.5 1.5 0 0020 0H1.5A1.5 1.5 0 000 1.5v12.053A1.459 1.459 0 001.5 15H3v3.5a.5.5 0 00.877.329L7.227 15h2.824a.252.252 0 00.12-.031 9.029 9.029 0 013.115-1.031z\"/><path d=\"M19.289 13.092a.5.5 0 00-.789.408v.867l1.434 1.141a1.5 1.5 0 01.075 2.283L18.5 19.164v1.154a.5.5 0 00.815.389l4.5-3.637a.5.5 0 00-.026-.8z\"/><path d=\"M19.337 17.052a.5.5 0 00-.026-.762l-4-3.181a.5.5 0 00-.811.391v1.329c-7.906.334-8 8.586-8 8.671a.5.5 0 00.994.081c.008-.05.851-4.782 7.006-5.026v1.763a.5.5 0 00.837.37z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});