define("ember-svg-jar/inlined/flower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flower</title><path d=\"M8.342 14.57a2.974 2.974 0 00-3.121.265L3.471 16a1.5 1.5 0 000 2.5l1.74 1.16a2.969 2.969 0 001.78.592 3 3 0 001.351-5.68zM20.529 18L18.8 16.85a3 3 0 10-3.144 5.078 3.017 3.017 0 001.351.321 2.976 2.976 0 001.78-.592l1.74-1.161a1.5 1.5 0 000-2.5z\"/><path d=\"M18.5 7V1a.89.89 0 00-1.106-.863A2.5 2.5 0 0015.5 2.562V3.5a.5.5 0 01-1 0v-1a2.5 2.5 0 00-5 0v1a.5.5 0 01-1 0v-.938A2.5 2.5 0 006.606.137.89.89 0 005.5 1v6a6.51 6.51 0 005.107 6.349.5.5 0 01.393.488V23a1 1 0 002 0v-9.163a.5.5 0 01.393-.488A6.51 6.51 0 0018.5 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});