define("ember-svg-jar/inlined/monitor-signal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-signal</title><path d=\"M19 20.532a2 2 0 002-2v-9a2 2 0 00-2-2H5a2 2 0 00-2 2v9a2 2 0 002 2h5.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H7.5a1 1 0 000 2h9a1 1 0 000-2h-3.25a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25zm-13.75-2a.252.252 0 01-.177-.074.247.247 0 01-.073-.175v-8.5a.25.25 0 01.25-.25h13.5a.25.25 0 01.25.25v8.5a.25.25 0 01-.25.25zM8.173 4.825a1 1 0 101.414 1.414 3.584 3.584 0 014.951 0 1 1 0 101.414-1.414 5.5 5.5 0 00-7.779 0z\"/><path d=\"M17.366 4.161a1 1 0 00.707-1.707 8.508 8.508 0 00-12.021 0 1 1 0 001.414 1.414 6.509 6.509 0 019.193 0 1 1 0 00.707.293z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});