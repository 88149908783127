define("ember-svg-jar/inlined/cursor-target-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-target-2</title><circle cx=\"12\" cy=\"11.998\" r=\"2.5\"/><path d=\"M12 7.5A1.5 1.5 0 0013.5 6V1.5a1.5 1.5 0 10-3 0V6A1.5 1.5 0 0012 7.5zM22.5 10.5H18a1.5 1.5 0 000 3h4.5a1.5 1.5 0 000-3zM12 16.5a1.5 1.5 0 00-1.5 1.5v4.5a1.5 1.5 0 103 0V18a1.5 1.5 0 00-1.5-1.5zM6 10.5H1.5a1.5 1.5 0 000 3H6a1.5 1.5 0 000-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});