define("ember-svg-jar/inlined/safety-slippery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-slippery</title><path d=\"M15.674 5.838a2.651 2.651 0 00.331.021 2.5 2.5 0 002.474-2.174 2.5 2.5 0 10-2.8 2.153zM1 22.5a1 1 0 001 1h12.5a1 1 0 000-2H2a1 1 0 00-1 1z\"/><path d=\"M8.575 10.776a5.594 5.594 0 00-2.131.127l-2.831.723a1.5 1.5 0 10.741 2.906l2.833-.722a2.613 2.613 0 01.652-.083 2.665 2.665 0 01.345.023l1.257.165a.25.25 0 01.078.472L5.21 16.522a1.5 1.5 0 001.332 2.689l5.558-2.756a3.483 3.483 0 001.9-2.32l1.143-3.52a.25.25 0 01.274-.17 6.9 6.9 0 00.974.067 7.3 7.3 0 004.869-1.858l1.24-1.116a1.5 1.5 0 10-2-2.231l-1.245 1.115A4.281 4.281 0 0116.4 7.514a4.209 4.209 0 01-.893-.1A4.261 4.261 0 0113.424 6.3l-.732-.716a4.245 4.245 0 01-1.281-2.9l-.025-.731A1.5 1.5 0 009.882.5h-.047a1.5 1.5 0 00-1.447 1.55l.025.731a7.234 7.234 0 002.181 4.945l.733.715a6.8 6.8 0 00.94.757.249.249 0 01.1.284L11.869 11a.249.249 0 01-.27.171z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});