define("ember-svg-jar/inlined/style-three-pin-bolt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-bolt</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm4.792 10.441L11.4 17.8a.5.5 0 01-.4.2.488.488 0 01-.155-.025.5.5 0 01-.345-.475v-5a.5.5 0 00-.5-.5H7.875a.876.876 0 01-.667-1.443L12.6 3.2a.5.5 0 01.9.3v5a.5.5 0 00.5.5h2.125a.874.874 0 01.667 1.441z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});