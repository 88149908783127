define("ember-svg-jar/inlined/navigation-button-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-button-right</title><path d=\"M22.75 6h-10a1 1 0 00-1 1v10a1 1 0 001 1h10a1 1 0 001-1V7a1 1 0 00-1-1zm-1.65 6.351l-2.5 2.5a.5.5 0 01-.35.149.508.508 0 01-.191-.038.5.5 0 01-.309-.462v-1a.5.5 0 00-.5-.5h-2.5a1 1 0 010-2h2.5a.5.5 0 00.5-.5v-1a.5.5 0 01.309-.462.507.507 0 01.545.108l2.5 2.5a.5.5 0 01-.004.705zM8.25 12a1 1 0 00-1-1h-3a.5.5 0 01-.5-.5v-1a.5.5 0 00-.309-.462.505.505 0 00-.545.108l-2.5 2.5a.5.5 0 000 .707l2.5 2.5A.5.5 0 003.25 15a.508.508 0 00.191-.038.5.5 0 00.309-.462v-1a.5.5 0 01.5-.5h3a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});