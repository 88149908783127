define("ember-svg-jar/inlined/phone-action-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-star</title><path d=\"M15.507 9.5h-1.655l-.906-2.391a.416.416 0 00-.022-.048 1.053 1.053 0 00-1.856.048L10.162 9.5H8.507a.987.987 0 00-.934.611 1.032 1.032 0 00.3 1.155l1.4.975-.8 1.834a1 1 0 00.25 1.157 1.04 1.04 0 001.2.133l2.08-1.17 2.079 1.17a1.019 1.019 0 001.2-.133 1 1 0 00.251-1.157l-.8-1.835 1.467-1.028a1.006 1.006 0 00.238-1.1.984.984 0 00-.931-.612z\"/><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});