define("ember-svg-jar/inlined/reflect-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>reflect-up</title><path d=\"M23.75 12a1 1 0 00-1-1h-14a1 1 0 000 2h14a1 1 0 001-1zM16.987 16.176a1.793 1.793 0 00-2.474 0l-5.586 5.586a1.107 1.107 0 00.823 1.987h12a1.107 1.107 0 00.823-1.987zM21.75.249h-12A1.169 1.169 0 008.559.92a1.169 1.169 0 00.368 1.316l6.293 6.293a.748.748 0 001.06 0l6.293-6.293A1.169 1.169 0 0022.941.92 1.169 1.169 0 0021.75.249z\"/><path d=\"M7.25 19.5a1 1 0 000-2 4.995 4.995 0 01-3.469-8.594.25.25 0 01.419.154l.048.489a.5.5 0 00.352.428.488.488 0 00.15.023.5.5 0 00.384-.18l2.5-3a.5.5 0 00-.107-.736l-3-2a.5.5 0 00-.775.466l.163 1.627a.248.248 0 01-.125.242A7 7 0 007.25 19.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});