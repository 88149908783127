define("ember-svg-jar/inlined/stairs-person-descend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>stairs-person-descend</title><circle cx=\"8\" cy=\"2\" r=\"2\"/><path d=\"M12.616 17.263a.5.5 0 00.022-.487l-1.585-3.17a.5.5 0 01-.053-.224v-.707a.5.5 0 01.11-.312l1.18-1.475a2 2 0 00.31-1.952L11.987 7.3A2 2 0 0010.114 6H5.648a2 2 0 00-1.727.992L2.587 9.28a.5.5 0 01-.247.213l-1.809.722a1.008 1.008 0 00.794 1.844l2.063-.522a.481.481 0 00.2-.12l1.558-1.563a.5.5 0 01.854.353v4.206a.509.509 0 01-.029.169l-2.232 6.25a.5.5 0 00.47.668h.982a.5.5 0 00.447-.276l2.5-5a.5.5 0 01.847-.077l1.157 1.542a.5.5 0 00.647.135 2.489 2.489 0 011.4-.324.5.5 0 00.427-.237zM23.5 11H20a1 1 0 00-1 1v2.5a.5.5 0 01-.5.5H16a1 1 0 00-1 1v2.5a.5.5 0 01-.5.5H12a1 1 0 00-1 1v3.5a.5.5 0 00.5.5h12a.5.5 0 00.5-.5v-12a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});