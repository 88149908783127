define("ember-svg-jar/inlined/single-man-actions-heart.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-heart</title><path d=\"M7.043 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.743a.5.5 0 01.742-.3A6.953 6.953 0 008.5 5a6.36 6.36 0 00.929-.07.5.5 0 01.571.564A3 3 0 014.043 5a2.841 2.841 0 01.1-.743zM9.986 13.779a4.957 4.957 0 011.1-1.449.5.5 0 00-.085-.8 7.433 7.433 0 00-3.752-1.03 7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h8.423a.5.5 0 00.5-.5 4.986 4.986 0 01.52-2.221zM21 12.546a3.486 3.486 0 00-3.057.987l-.3.3a.25.25 0 01-.354 0l-.3-.3a3.526 3.526 0 00-4.993 4.979l5.104 5.334a.5.5 0 00.722 0l5.106-5.327A3.525 3.525 0 0021 12.546z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});