define("ember-svg-jar/inlined/shark-swordfish-fish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shark-swordfish-fish</title><path d=\"M8.079 3.976a.25.25 0 00.158-.361 5.309 5.309 0 00-4.8-2.99.5.5 0 00-.456.3 23.639 23.639 0 00-1.444 5.763.25.25 0 00.413.223 13.245 13.245 0 015.7-2.824zM23.3 19.938a2.85 2.85 0 00-3.134-.322 10.1 10.1 0 00-4.133 2.944.5.5 0 00.488.8 7.947 7.947 0 013.312-.127 8.857 8.857 0 002.08.136 1.981 1.981 0 001.828-1.247 2.067 2.067 0 00-.441-2.184zM18.194 17.3a8.954 8.954 0 001.474-1.471 1.982 1.982 0 00.27-2.2 2.043 2.043 0 00-1.929-1.106 2.85 2.85 0 00-2.309 2.14 10.106 10.106 0 00-.514 5.048.5.5 0 00.921.157 7.885 7.885 0 012.087-2.568zM12.088 10.586a.25.25 0 00-.326-.1 26.632 26.632 0 01-2.5 1.039.25.25 0 00-.111.382l2.067 2.808a.5.5 0 00.769.043 3.766 3.766 0 00.5-3.259 3.93 3.93 0 00-.399-.913z\"/><path d=\"M23.094 2.175a1 1 0 00-1.218-.719L7.9 5.056C3.376 6.133.126 9.115.126 12.626s3.225 6.47 7.719 7.558a3.929 3.929 0 002.988 2.422c.605-.125-.521-1.313-.5-2.938.022-1.659 1.469-3.645.563-3.645s-1.958 1.853-2.676 1.884a3.186 3.186 0 01-3.344-3.25 3.245 3.245 0 01.6-1.8l.794 2.4c.148.448.884.724 1.611-.68a4.58 4.58 0 00.061-3.587c.7-.3 1.447-.572 2.18-.864 2.5-1 3.281-1.75 3.781-3.25a6.922 6.922 0 00.284-1.37l8.185-2.112a1 1 0 00.722-1.219zM11.126 7.626a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});