define("ember-svg-jar/inlined/hat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hat</title><path d=\"M18.115 13.25a.5.5 0 00.5-.54l-.527-6.579a1.5 1.5 0 00-.58-1.07 1.529 1.529 0 00-1.235-.275L12 6.047 7.676 4.774A1.5 1.5 0 005.914 6.13l-.527 6.58a.5.5 0 00.5.54zM24 18.25a1 1 0 00-1-1h-3.562a.5.5 0 01-.5-.46l-.126-1.58a.5.5 0 00-.5-.46H5.685a.5.5 0 00-.5.46l-.126 1.58a.5.5 0 01-.5.46H1a1 1 0 000 2h22a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});