define("ember-svg-jar/inlined/cash-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-search</title><path d=\"M10.125 19.875a9.688 9.688 0 005.575-1.758.25.25 0 01.32.028l5.041 5.041a1.5 1.5 0 002.122-2.122l-5.042-5.04a.249.249 0 01-.027-.32 9.747 9.747 0 10-7.992 4.171zm1.317-8.192l-3.37-1.259a2.552 2.552 0 01-1.447-2.469 2.528 2.528 0 012.289-2.534.25.25 0 00.211-.247V4.5a1 1 0 012 0v.625a.249.249 0 00.25.25h1.25a1 1 0 010 2H9.5c-.875 0-.875.437-.875.58a.9.9 0 00.183.612l3.37 1.26a2.55 2.55 0 011.447 2.473 2.528 2.528 0 01-2.289 2.534.25.25 0 00-.211.247v.673a1 1 0 01-2 0v-.625a.25.25 0 00-.25-.25h-1.25a1 1 0 010-2h3.125c.875 0 .875-.436.875-.579a.9.9 0 00-.183-.617z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});