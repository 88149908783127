define("ember-svg-jar/inlined/monitor-graph-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-graph-line</title><path d=\"M24 2.84A2.548 2.548 0 0021.5.251h-19A2.548 2.548 0 000 2.84v13.911a3 3 0 003 3h7.25a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H5a1 1 0 000 2h14a1 1 0 000-2h-6.25a.25.25 0 01-.25-.25V20a.25.25 0 01.25-.25H21a3 3 0 003-3zm-2 0v11.911a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-1.836a.251.251 0 01.073-.177l3.75-3.75a.252.252 0 01.354 0l3.293 3.293a.749.749 0 001.06 0l2-2a.75.75 0 00-1.06-1.06l-1.293 1.293a.252.252 0 01-.354 0L6.53 7.221a.749.749 0 00-1.06 0l-3.043 3.043A.25.25 0 012 10.087V2.84a.551.551 0 01.5-.589h19a.551.551 0 01.5.589z\"/><path d=\"M18.394 10.436a.664.664 0 01-.664.664h-1.636a.75.75 0 100 1.5h.143a.25.25 0 01.25.25v.144a.75.75 0 001.5 0v-.208a.248.248 0 01.2-.244A2.156 2.156 0 0018.7 8.5l-1.49-.742a.664.664 0 01.3-1.257h1.636a.75.75 0 000-1.5H19a.25.25 0 01-.25-.25v-.144a.75.75 0 10-1.5 0v.209a.249.249 0 01-.2.244 2.156 2.156 0 00-.511 4.04l1.488.745a.658.658 0 01.367.591z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});