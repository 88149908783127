define("ember-svg-jar/inlined/picture-polaroid-four", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>picture-polaroid-four</title><path d=\"M11.5 2a2 2 0 00-2-2H2a2 2 0 00-2 2v7.5a2 2 0 002 2h7.5a2 2 0 002-2zm-2 5a.5.5 0 01-.5.5H2.5A.5.5 0 012 7V2.5a.5.5 0 01.5-.5H9a.5.5 0 01.5.5zM24 2a2 2 0 00-2-2h-7.5a2 2 0 00-2 2v7.5a2 2 0 002 2H22a2 2 0 002-2zm-2 5a.5.5 0 01-.5.5H15a.5.5 0 01-.5-.5V2.5A.5.5 0 0115 2h6.5a.5.5 0 01.5.5zM11.5 14.5a2 2 0 00-2-2H2a2 2 0 00-2 2V22a2 2 0 002 2h7.5a2 2 0 002-2zm-2 5a.5.5 0 01-.5.5H2.5a.5.5 0 01-.5-.5V15a.5.5 0 01.5-.5H9a.5.5 0 01.5.5zM24 14.5a2 2 0 00-2-2h-7.5a2 2 0 00-2 2V22a2 2 0 002 2H22a2 2 0 002-2zm-2 5a.5.5 0 01-.5.5H15a.5.5 0 01-.5-.5V15a.5.5 0 01.5-.5h6.5a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});