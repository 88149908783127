define("ember-svg-jar/inlined/power-tools-drill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>power-tools-drill</title><path d=\"M24 7.25a5.006 5.006 0 00-5-5h-7.5a2 2 0 00-2 2V9.8a2.442 2.442 0 001.588 2.282.25.25 0 01.162.234v.435a3 3 0 003 3h1.565a.249.249 0 01.242.188l.385 1.5a.251.251 0 01-.242.312H16a2.5 2.5 0 00-2.5 2.5 1.5 1.5 0 001.5 1.5h5.5a1.5 1.5 0 001.5-1.5v-1.36a1.488 1.488 0 00-.058-.413l-1.748-6.12a.252.252 0 01.022-.19.248.248 0 01.15-.119A5 5 0 0024 7.25zm-9.75 6.5a1 1 0 01-1-1v-.25a.25.25 0 01.25-.25h1.418a.249.249 0 01.242.188l.257 1a.251.251 0 01-.243.312zM7.5 4.25a2.5 2.5 0 00-2.408 1.834.249.249 0 01-.241.184H1a1 1 0 000 2h3.861a.25.25 0 01.24.18 2.5 2.5 0 002.4 1.8.5.5 0 00.5-.5v-5A.5.5 0 007.5 4.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});