define("ember-svg-jar/inlined/adventure-car-truck-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>adventure-car-truck-1</title><path d=\"M21.068 8.433A.25.25 0 0121.239 8H22a1 1 0 001-1V4.25a1 1 0 00-1-1h-1.25A.25.25 0 0120.5 3v-.5A1.5 1.5 0 0019 1H5a1.5 1.5 0 00-1.5 1.5V3a.25.25 0 01-.25.25H2a1 1 0 00-1 1V7a1 1 0 001 1h.76a.249.249 0 01.171.432l-2.131 2a1.514 1.514 0 00-.3.9V16A1.5 1.5 0 002 17.5h.25a.25.25 0 01.25.25v3.75A1.5 1.5 0 004 23h2a1.5 1.5 0 001.5-1.5v-3.75a.25.25 0 01.25-.25h8.519a.25.25 0 01.25.25v3.75a1.5 1.5 0 001.5 1.5h2a1.5 1.5 0 001.5-1.5v-3.75a.249.249 0 01.25-.25H22a1.5 1.5 0 001.5-1.5v-4.667a1.45 1.45 0 00-.359-.965zM8.019 15.5a.5.5 0 01-.5-.5v-1a1 1 0 011-1h7a1 1 0 011 1l-.01 1a.5.5 0 01-.5.5zm-2.5-8.031V3.5a.5.5 0 01.5-.5h4.75a.5.5 0 01.5.5v3.969a.5.5 0 01-.5.5h-4.75a.5.5 0 01-.5-.5zm7.25 0V3.5a.5.5 0 01.5-.5h4.75a.5.5 0 01.5.5v3.969a.5.5 0 01-.5.5h-4.75a.5.5 0 01-.5-.5zM4 12.5A1.5 1.5 0 112.5 14 1.5 1.5 0 014 12.5zm16 3a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});