define("ember-svg-jar/inlined/office-work-wireless", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-work-wireless</title><circle cx=\"5.243\" cy=\"2.749\" r=\"2.5\"/><path d=\"M23.993 13a1 1 0 00-1-1h-8.008a.249.249 0 00-.178.075.252.252 0 00-.072.18 2.364 2.364 0 01-.394 1.357.25.25 0 00.208.388h1.694a.249.249 0 01.25.25V23a1 1 0 002 0v-8.75a.25.25 0 01.25-.25h4.25a1 1 0 001-1zM15.993 9a1 1 0 000 2h4.3a2 2 0 001.879-1.316l1.761-4.842a1 1 0 10-1.879-.683l-1.7 4.677a.249.249 0 01-.236.164z\"/><circle cx=\"17.243\" cy=\"6.699\" r=\"1.25\"/><path d=\"M13.714 2.76a5.579 5.579 0 017.059 0 .75.75 0 00.941-1.168 7.072 7.072 0 00-8.941 0 .75.75 0 00.941 1.168z\"/><path d=\"M20.16 4.8a.75.75 0 00-.113-1.054 4.433 4.433 0 00-5.607 0 .75.75 0 10.941 1.168 2.94 2.94 0 013.725 0A.75.75 0 0020.16 4.8zM12.243 13.749a1.5 1.5 0 000-3h-2.171a.505.505 0 01-.354-.146L6.3 7.189a1.5 1.5 0 00-2.561 1.06v6.5a3.389 3.389 0 00.306 1.4.25.25 0 01-.228.352h-.255a.249.249 0 01-.247-.211L1.993 7.843a1 1 0 10-1.974.313l1.445 9.235a.234.234 0 010 .1l-1.4 5.246a1 1 0 001.929.525l1.215-4.577a.251.251 0 01.242-.185h6.26a.248.248 0 01.229.151c.347.794 1.939 4.471 1.939 4.471a1.5 1.5 0 002.731-1.243L12.642 17.3a3.508 3.508 0 00-3.187-2.052H7.243a.5.5 0 01-.5-.5v-2.274a.25.25 0 01.427-.174l.427.427a3.477 3.477 0 002.475 1.025z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});