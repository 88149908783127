define("ember-svg-jar/inlined/tree-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tree-1</title><path d=\"M15.968 16.5h-1.01a.5.5 0 00-.493.415 2.509 2.509 0 01-1.075 1.656.25.25 0 01-.39-.208V17a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v6a1 1 0 002 0v-1.735a.5.5 0 01.35-.477 4.516 4.516 0 003.114-3.725.5.5 0 00-.5-.563zM18.771 11.389L14.224 1.43a2.444 2.444 0 00-4.448 0l-4.547 9.959a2.544 2.544 0 002.315 3.6h8.912a2.544 2.544 0 002.315-3.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});