define("ember-svg-jar/inlined/army-symbol-light-recon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-symbol-light-recon</title><path d=\"M24 5.5a2.018 2.018 0 00-2-2H2a2 2 0 00-2 2v13a2.018 2.018 0 002 2h20a2 2 0 002-2zm-5.062 0a.25.25 0 01.141.456L2.391 17.35A.25.25 0 012 17.144V6a.5.5 0 01.5-.5zm-13.875 13a.25.25 0 01-.141-.456L21.609 6.65a.25.25 0 01.391.206V18a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});