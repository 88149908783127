define("ember-svg-jar/inlined/family-child-play-ball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-child-play-ball</title><circle cx=\"16.75\" cy=\"5.499\" r=\"2.5\"/><circle cx=\"3.75\" cy=\"11.499\" r=\"2\"/><path d=\"M21.688 14.67a1.5 1.5 0 00.233-2.109l-1.7-2.123A2.485 2.485 0 0018.27 9.5h-5.593l-.585-1.171A1.5 1.5 0 009.408 9.67l.723 1.445a2.487 2.487 0 002.237 1.385h1.141a.249.249 0 01.242.31l-.25 1a.25.25 0 01-.242.19h-1.832a2.485 2.485 0 00-2.32 1.571l-.749 1.871a1.5 1.5 0 002.785 1.115L11.766 17h1.1a2.49 2.49 0 00.519 1.323l1.69 2.113a1.5 1.5 0 102.342-1.873l-1.535-1.921.941-3.763a.5.5 0 01.485-.379h.717l1.55 1.937a1.5 1.5 0 002.113.233z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});