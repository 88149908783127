define("ember-svg-jar/inlined/audio-file-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file-disable</title><path d=\"M23.414 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.275a.247.247 0 00.242.25c.366.007 1.037.034 1.485.082A.245.245 0 008 9.359V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.244.244 0 00-.241.21 7.935 7.935 0 01-.364 1.458.244.244 0 00.229.331H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M16.233 11.5a.734.734 0 00.017-.084v-5.11a.182.182 0 01.293-.166l1.058.665a.75.75 0 10.8-1.269l-1.818-1.141A1.25 1.25 0 0014.75 5.5v3.59c0 .134-.171.079-.26.058a2.081 2.081 0 00-.49-.06 2.253 2.253 0 00-2.25 2.249.565.565 0 00.092.224 8.041 8.041 0 011.524 1.851.482.482 0 00.158.117 2.139 2.139 0 00.476.059 2.246 2.246 0 002.233-2.088zM6.5 11a6.5 6.5 0 106.5 6.5A6.5 6.5 0 006.5 11zm-3.967 8.6a4.443 4.443 0 01-.367-3.319 4.5 4.5 0 013.112-3.117 4.447 4.447 0 013.326.367.253.253 0 01.051.4l-5.724 5.723a.252.252 0 01-.398-.054zm8.3-.887a4.5 4.5 0 01-3.112 3.117 4.447 4.447 0 01-3.321-.364.253.253 0 01-.051-.4l5.724-5.722a.252.252 0 01.4.051 4.443 4.443 0 01.361 3.321z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});