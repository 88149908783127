define("ember-svg-jar/inlined/movie-cinema-watch-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>movie-cinema-watch-1</title><path d=\"M21 0H3a3 3 0 00-3 3v9a3 3 0 003 3h18a3 3 0 003-3V3a3 3 0 00-3-3zm1 12a1 1 0 01-1 1H3a1 1 0 01-1-1V3a1 1 0 011-1h18a1 1 0 011 1z\"/><path d=\"M9.542 3.786a.377.377 0 00-.409.058.574.574 0 00-.192.442v6.428a.578.578 0 00.192.443.378.378 0 00.409.057l5.574-2.949a.883.883 0 000-1.53z\"/><circle cx=\"3.502\" cy=\"18.203\" r=\"2.199\"/><path d=\"M3.5 21.2a3.589 3.589 0 00-3.26 2.089A.5.5 0 00.7 24h5.608a.5.5 0 00.453-.71A3.588 3.588 0 003.5 21.2z\"/><circle cx=\"20.502\" cy=\"18.199\" r=\"2.199\"/><path d=\"M20.5 21.2a3.589 3.589 0 00-3.26 2.089.5.5 0 00.46.711h5.612a.5.5 0 00.453-.71A3.588 3.588 0 0020.5 21.2z\"/><circle cx=\"12.002\" cy=\"18.199\" r=\"2.199\"/><path d=\"M12 21.2a3.589 3.589 0 00-3.26 2.089.5.5 0 00.46.711h5.612a.5.5 0 00.453-.71A3.588 3.588 0 0012 21.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});