define("ember-svg-jar/inlined/supermarket-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>supermarket-1</title><path d=\"M24 22.5a1.5 1.5 0 00-1.5-1.5h-.75a.25.25 0 01-.25-.25v-6.5a.5.5 0 00-.5-.5H3a.5.5 0 00-.5.5v6.5a.25.25 0 01-.25.25H1.5a1.5 1.5 0 000 3h21a1.5 1.5 0 001.5-1.5zm-10-2a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-4.25a.5.5 0 01.5-.5h3a.5.5 0 01.5.5zm-9.5 0v-4.25a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v4.25a.5.5 0 01-.5.5H5a.5.5 0 01-.5-.5zM16 21a.5.5 0 01-.5-.5v-4.25a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v4.25a.5.5 0 01-.5.5zM1.489 10.83a.244.244 0 01.019.075A1.5 1.5 0 003 12.25h18a1.5 1.5 0 001.492-1.345.244.244 0 01.019-.075l.852-1.937A1.31 1.31 0 0022.191 7H17.4a.25.25 0 01-.209-.112A6.152 6.152 0 0013.2 4.119a.25.25 0 01-.2-.245V3.25a.25.25 0 01.25-.25h2.25a1 1 0 001-1V1a1 1 0 00-1-1H12a1 1 0 00-1 1v2.874a.249.249 0 01-.2.245 6.147 6.147 0 00-3.993 2.768A.253.253 0 016.6 7H1.809A1.31 1.31 0 00.5 8.309a1.291 1.291 0 00.127.563z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});