define("ember-svg-jar/inlined/earpods-bluetooth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earpods-bluetooth</title><path d=\"M21 0h-.5a1 1 0 00-1 1v4a1 1 0 001 1h.5a3 3 0 000-6zM8.978 16.561a.25.25 0 00-.164-.061H6.905a2.4 2.4 0 01-1.539-4.253A5.916 5.916 0 007.5 7.69V5.121a.249.249 0 01.167-.235A2 2 0 007 1H6a.5.5 0 00-.5.5v6.19a3.925 3.925 0 01-1.415 3.021 4.4 4.4 0 002.82 7.789h.756a.254.254 0 00.164-.061l1.442-1.25a.251.251 0 000-.378z\"/><path d=\"M19.915 10.711A3.925 3.925 0 0118.5 7.69V1.5A.5.5 0 0018 1h-1a2 2 0 00-.667 3.886.249.249 0 01.167.235V7.69a5.916 5.916 0 002.134 4.557A2.4 2.4 0 0117.1 16.5h-.833a.254.254 0 00-.164.061l-.288.25a.251.251 0 000 .378l1.5 1.3a.024.024 0 00.016 0 4.405 4.405 0 002.589-7.783zM3.5 6a1 1 0 001-1V1a1 1 0 00-1-1H3a3 3 0 000 6z\"/><path d=\"M16.655 19.244l-2.372-2.055a.251.251 0 010-.378l2.372-2.055a1 1 0 00-.005-1.515l-3.5-3A1 1 0 0011.5 11v3.229a.25.25 0 01-.414.189l-1.931-1.674a1 1 0 00-1.31 1.512l2.949 2.555a.251.251 0 010 .378l-2.949 2.555a1 1 0 001.31 1.512l1.931-1.674a.25.25 0 01.414.189V23a1 1 0 001.65.759l3.5-3a1 1 0 00.005-1.515zM13.5 13.718a.251.251 0 01.413-.19l.335.288a.246.246 0 01.088.189.25.25 0 01-.087.189l-.335.291a.25.25 0 01-.414-.185zm.413 6.754a.251.251 0 01-.413-.19V19.7a.25.25 0 01.414-.189l.335.291a.248.248 0 010 .378z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});