define("ember-svg-jar/inlined/phone-action-camera", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-camera</title><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/><path d=\"M15.842 9.025l-1.342.448A1.5 1.5 0 0013 8H9a1.5 1.5 0 00-1.5 1.5v4A1.5 1.5 0 009 15h4a1.5 1.5 0 001.5-1.473l1.342.448a.5.5 0 00.45-.07.5.5 0 00.208-.4v-4a.5.5 0 00-.658-.475z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});