define("ember-svg-jar/inlined/single-neutral-actions-money", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-money</title><path d=\"M0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.568a.25.25 0 00.182-.078.254.254 0 00.068-.187c0-.078-.008-.156-.008-.235a7.948 7.948 0 011.345-4.43.25.25 0 00.036-.19A5.5 5.5 0 000 13.75z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M17.5 23.75a6.5 6.5 0 10-6.5-6.5 6.508 6.508 0 006.5 6.5zm.75-10.5a.25.25 0 00.25.25h.5a.75.75 0 010 1.5h-2.033a.592.592 0 00-.22 1.141l2.063.825a2.084 2.084 0 01-.36 3.981.249.249 0 00-.2.245v.308a.75.75 0 01-1.5 0v-.25a.25.25 0 00-.25-.25H16a.75.75 0 010-1.5h2.033a.592.592 0 00.22-1.141l-2.063-.825a2.084 2.084 0 01.36-3.981.249.249 0 00.2-.245V13a.75.75 0 011.5 0zM0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.568a.25.25 0 00.182-.078.254.254 0 00.068-.187c0-.078-.008-.156-.008-.235a7.948 7.948 0 011.345-4.43.25.25 0 00.036-.19A5.5 5.5 0 000 13.75z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M17.5 23.75a6.5 6.5 0 10-6.5-6.5 6.508 6.508 0 006.5 6.5zm.75-10.5a.25.25 0 00.25.25h.5a.75.75 0 010 1.5h-2.033a.592.592 0 00-.22 1.141l2.063.825a2.084 2.084 0 01-.36 3.981.249.249 0 00-.2.245v.308a.75.75 0 01-1.5 0v-.25a.25.25 0 00-.25-.25H16a.75.75 0 010-1.5h2.033a.592.592 0 00.22-1.141l-2.063-.825a2.084 2.084 0 01.36-3.981.249.249 0 00.2-.245V13a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});