define("ember-svg-jar/inlined/single-man-phone-book", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-phone-book</title><path d=\"M24 4a3 3 0 00-3-3h-.774a.5.5 0 01-.433-.25A1.491 1.491 0 0018.5 0H3.75a.25.25 0 00-.25.25v23.5a.25.25 0 00.25.25H18.5a1.491 1.491 0 001.293-.75.5.5 0 01.433-.25H21a3 3 0 003-3zM9.14 7.6a.254.254 0 01-.1-.187.25.25 0 01.078-.2 3.5 3.5 0 015.524.938.25.25 0 01-.146.353 5.936 5.936 0 01-1.791.277A6.169 6.169 0 019.14 7.6zM8.094 9a.233.233 0 01.147-.165.236.236 0 01.22.027 7.391 7.391 0 006.239 1.06.238.238 0 01.219.048.233.233 0 01.081.208 3.513 3.513 0 01-7-.428A3.213 3.213 0 018.094 9zm9.032 10.164a.748.748 0 01-.626.338h-10a.751.751 0 01-.689-1.047A6.305 6.305 0 0111.5 14.5a6.3 6.3 0 015.689 3.953.749.749 0 01-.063.709zM22 10.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25zm-2 2.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25zM21 3a1 1 0 011 1v1.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25zm1 17a1 1 0 01-1 1h-.75a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25zM2.25 0H1.5A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h.75a.25.25 0 00.25-.25V.25A.25.25 0 002.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});