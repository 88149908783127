define("ember-svg-jar/inlined/programming-search-browser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-search-browser</title><path d=\"M0 19.628a3 3 0 003 3h13.5a3 3 0 003-3v-5.5a1 1 0 00-2 0v5.5a1 1 0 01-1 1H3a1 1 0 01-1-1v-9a.5.5 0 01.5-.5h8a.5.5 0 00.442-.733 6.423 6.423 0 01-.623-1.767 6.517 6.517 0 01-.123-1.25c0-.077 0-.153.005-.229a.5.5 0 00-.5-.521H3a3 3 0 00-3 3zm3.5-10.5a1 1 0 01-1-1 .979.979 0 01.092-.408 1 1 0 01.908-.592.985.985 0 01.846.5.969.969 0 01.154.5 1 1 0 01-1 1zm3.5 0a1 1 0 01-1-1 .969.969 0 01.154-.5.966.966 0 011.692 0 .969.969 0 01.154.5 1 1 0 01-1 1z\"/><path d=\"M12.97 18.158a.748.748 0 001.06 0l2-2a.749.749 0 000-1.06l-2-2a.75.75 0 00-1.06 1.06l1.293 1.293a.252.252 0 010 .354L12.97 17.1a.749.749 0 000 1.058zM7.03 13.1a.749.749 0 00-1.06 0l-2 2a.749.749 0 000 1.06l2 2a.75.75 0 001.06-1.06l-1.293-1.295a.252.252 0 010-.354l1.293-1.293a.749.749 0 000-1.058zM10.329 13.293l-2 4a.75.75 0 00.336 1.007.741.741 0 00.335.078.75.75 0 00.672-.415l2-4a.75.75 0 10-1.342-.67zM23.563 12.686a1.5 1.5 0 000-2.121l-1.098-1.088-.682-.677a.5.5 0 01-.113-.537 4.963 4.963 0 00.35-1.817 5.073 5.073 0 10-5.074 5.074 4.964 4.964 0 002.268-.557.5.5 0 01.581.091l.663.66.984.98a1.5 1.5 0 002.121-.006zm-6.617-3.168a3.073 3.073 0 113.074-3.074 3.076 3.076 0 01-3.074 3.074z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});