define("ember-svg-jar/inlined/space-antenna-satelite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>space-antenna-satelite</title><path d=\"M3.567 3.318a4.5 4.5 0 016.366 0A1 1 0 1011.347 1.9a6.5 6.5 0 00-9.194 0 1 1 0 001.414 1.418z\"/><path d=\"M6.75 3.475a4.469 4.469 0 00-3.182 1.318 1 1 0 101.414 1.414 2.562 2.562 0 013.536 0 1 1 0 101.414-1.414A4.469 4.469 0 006.75 3.475z\"/><circle cx=\"6.25\" cy=\"21.5\" r=\"2.5\"/><path d=\"M8.247 14.249a.251.251 0 01.187-.165c2.98-.605 5.066-2.8 5.066-5.584a.75.75 0 00-.75-.75h-12A.75.75 0 000 8.5c0 2.789 2.1 4.994 5.1 5.59a.5.5 0 01.4.49v2.927a.5.5 0 00.531.5C6.1 18 6.177 18 6.25 18a3.484 3.484 0 012.333.893.25.25 0 00.417-.186v-2.378a2.72 2.72 0 00-.7-1.835.249.249 0 01-.053-.245z\"/><circle cx=\"21.5\" cy=\"21.5\" r=\"2.5\"/><path d=\"M21.539 18a.5.5 0 00.5-.417l.41-2.464a2.25 2.25 0 00-4.426-.81l-.558 2.791a.5.5 0 01-.49.4H13.5a2.253 2.253 0 00-2.241 2.045.5.5 0 01-.5.455h-.973a.251.251 0 00-.236.333 3.5 3.5 0 01.2 1.167.5.5 0 00.5.5h7.25a.5.5 0 00.5-.5 3.505 3.505 0 013.539-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});