define("ember-svg-jar/inlined/kitchenware-spatula", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchenware-spatula</title><path d=\"M23.121 12.969a3 3 0 000-4.243L15.272.878a3 3 0 00-4.242 0L4.379 7.529a3 3 0 000 4.243l7.848 7.848a3 3 0 004.243 0zm-7.627 2.854a.75.75 0 11-1.06 1.061L7.115 9.565a.75.75 0 011.061-1.06zm2.66-1.6a.748.748 0 01-1.06 0L9.775 6.906a.75.75 0 111.06-1.061l7.319 7.319a.749.749 0 010 1.06zm2.676-2.676a.748.748 0 01-1.06 0L12.45 4.23a.75.75 0 011.061-1.06l7.319 7.318a.749.749 0 010 1.06zM7.707 17.706a.5.5 0 010 .707L2.56 23.56a1.5 1.5 0 01-2.121-2.121l5.147-5.147a.5.5 0 01.707 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});