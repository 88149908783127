define("ember-svg-jar/inlined/read-email-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-email-laptop</title><path d=\"M8.921 10.25h6.153a.913.913 0 00.926-.9V6.506a.149.149 0 00-.079-.131.158.158 0 00-.156 0l-3.034 1.851a1.421 1.421 0 01-1.467 0L8.233 6.379a.16.16 0 00-.156 0A.151.151 0 008 6.506V9.35a.913.913 0 00.921.9z\"/><path d=\"M11.753 7.463a.472.472 0 00.489 0l3.734-2.275A.045.045 0 0016 5.15a.913.913 0 00-.923-.9H8.921a.913.913 0 00-.921.9.045.045 0 00.022.038z\"/><path d=\"M23.3 20.69l-2.1-6.829a1.486 1.486 0 00-.339-.56 2.647 2.647 0 00.643-1.718V2.917A2.669 2.669 0 0018.831.25H5.164A2.67 2.67 0 002.5 2.917v8.666a2.638 2.638 0 00.641 1.717 1.5 1.5 0 00-.339.561L.728 20.614a2.167 2.167 0 001.938 3.136h18.662a2.155 2.155 0 001.844-1.027 2.1 2.1 0 00.128-2.033zm-7.793.458a.5.5 0 01-.4.207H8.9a.5.5 0 01-.474-.658l.6-1.8a.5.5 0 01.474-.342h5a.5.5 0 01.475.342l.6 1.8a.5.5 0 01-.075.451zM4.5 2.917a.667.667 0 01.667-.667h13.664a.667.667 0 01.666.667v8.666a.667.667 0 01-.666.667H5.164a.667.667 0 01-.667-.667z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});