define("ember-svg-jar/inlined/monkey-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monkey-1</title><path d=\"M17 .5H7C4 .5.5 4 .5 9c0 12 5 14.5 11.5 14.5S23.5 21 23.5 9C23.5 4 20 .5 17 .5zm-5.039 21.746c-2.274 0-5.789-1.034-5.276-3.48A9.271 9.271 0 0012 20.5a9.275 9.275 0 005.247-1.681c.453 2.406-3.027 3.427-5.286 3.427zm7-11.75c-2.212 2.212-3.408 3.715-2.475 6.392A7.248 7.248 0 0112 18.5a7.577 7.577 0 01-4.552-1.644C8.359 14.2 7.163 12.7 4.961 10.5c-3-3-1-8 2.5-8 2 0 2.5 1 4.5 1s2.5-1 4.5-1c3.5 0 5.5 5 2.5 8z\"/><circle cx=\"8\" cy=\"7.496\" r=\"1.25\"/><circle cx=\"16\" cy=\"7.496\" r=\"1.25\"/><path d=\"M14.644 10.876a1 1 0 00-1.987.232L13 14.023c-.018.112-.143.469-1 .469-.6 0-.952-.177-1-.471l.34-2.913a1 1 0 00-1.987-.232l-.349 3a.9.9 0 00-.004.116c0 1 .8 2.5 3 2.5s3-1.5 3-2.5a.9.9 0 00-.007-.116z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});