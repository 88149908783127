define("ember-svg-jar/inlined/candy-jar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>candy-jar</title><rect x=\"3.5\" width=\"17\" height=\"3.5\" rx=\"1\" ry=\"1\"/><path d=\"M10.776 13.507a.5.5 0 00.429.243h1.584a.5.5 0 00.429-.243l.75-1.25a.5.5 0 000-.514l-.75-1.25a.5.5 0 00-.429-.243h-1.584a.5.5 0 00-.429.243l-.75 1.25a.5.5 0 000 .514zM9.468 14.757a.5.5 0 000-.514l-.75-1.25a.5.5 0 00-.429-.243H6.705a.5.5 0 00-.429.243l-.75 1.25a.5.5 0 000 .514l.75 1.25a.5.5 0 00.429.243h1.584a.5.5 0 00.429-.243zM13.218 15.493a.5.5 0 00-.429-.243h-1.581a.5.5 0 00-.429.243l-.75 1.25a.5.5 0 000 .514l.75 1.25a.5.5 0 00.429.243h1.584a.5.5 0 00.429-.243l.748-1.247a.5.5 0 000-.515zM15.705 12.75a.5.5 0 00-.429.243l-.749 1.247a.5.5 0 000 .515l.752 1.252a.5.5 0 00.429.243h1.581a.5.5 0 00.429-.243l.75-1.25a.5.5 0 000-.514l-.75-1.25a.5.5 0 00-.429-.243z\"/><path d=\"M19.963 5.22A1.986 1.986 0 0019.05 5H4.95a2 2 0 00-.913.22A6.227 6.227 0 00.5 11c.006 6 5.024 13 7 13h9c1.973 0 6.991-7 7-13a6.227 6.227 0 00-3.537-5.78zm.023 8.637a1.249 1.249 0 010 1.287l-1.2 2a1.254 1.254 0 01-1.072.607h-1.723a1 1 0 00-.858.486l-.844 1.407a1.256 1.256 0 01-1.072.607h-2.434a1.256 1.256 0 01-1.072-.607l-.845-1.407a1 1 0 00-.857-.486H6.28a1.255 1.255 0 01-1.072-.607l-1.2-2a1.245 1.245 0 010-1.287l1.2-2a1.255 1.255 0 011.072-.607h1.726a1 1 0 00.858-.486l.844-1.408a1.256 1.256 0 011.072-.606h2.434a1.254 1.254 0 011.072.607l.844 1.407a1 1 0 00.858.486h1.726a1.254 1.254 0 011.072.607z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});