define("ember-svg-jar/inlined/messages-bubble-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-question</title><path d=\"M12 1.337C5.659 1.337.5 5.587.5 10.812a8.592 8.592 0 003.175 6.539l-2.3 4.587a.5.5 0 00.658.677l6.149-2.862a13.733 13.733 0 003.818.532c6.341 0 11.5-4.25 11.5-9.473S18.341 1.337 12 1.337zm.5 15.538a1 1 0 111-1 1 1 0 01-1 1zm1.5-5.062a1.252 1.252 0 00-.75 1.146v.416a.75.75 0 01-1.5 0v-.416a2.75 2.75 0 011.65-2.521 2.25 2.25 0 10-3.15-2.063.75.75 0 01-1.5 0A3.75 3.75 0 1114 11.813z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});