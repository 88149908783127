define("ember-svg-jar/inlined/volume-control-up-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>volume-control-up-2</title><path d=\"M9.5 17.5a7.977 7.977 0 012.424-5.731.248.248 0 00.076-.178V1a1 1 0 00-1.707-.706L4.366 6.221a.249.249 0 01-.177.073H2a2 2 0 00-2 2v3.414a2 2 0 002 2h2.189a.253.253 0 01.177.073l4.751 4.752a.25.25 0 00.426-.2A8.1 8.1 0 019.5 17.5z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm.75 9a.75.75 0 01-1.5 0v-1.5a.25.25 0 00-.25-.25H15a.75.75 0 010-1.5h1.5a.25.25 0 00.25-.25V15a.75.75 0 011.5 0v1.5a.25.25 0 00.25.25H20a.75.75 0 010 1.5h-1.5a.25.25 0 00-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});