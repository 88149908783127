define("ember-svg-jar/inlined/smart-watch-square-house-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-house-alternate</title><path d=\"M21 6a3 3 0 00-3-3h-1.078a.249.249 0 01-.24-.182L15.98.365A.5.5 0 0015.5 0h-7a.5.5 0 00-.481.363l-.7 2.455A.248.248 0 017.077 3H6a3 3 0 00-3 3v12a3 3 0 003 3h1.077a.249.249 0 01.24.181l.7 2.456A.5.5 0 008.5 24h7a.5.5 0 00.48-.363l.7-2.456a.25.25 0 01.24-.181H18a3 3 0 003-3zm-2 12a1 1 0 01-1 1H6a1 1 0 01-1-1V6a1 1 0 011-1h12a1 1 0 011 1z\"/><path d=\"M17.129 11.414l-4.91-3.833a.343.343 0 00-.439 0l-4.91 3.833a.329.329 0 00-.1.369.341.341 0 00.319.217H8a.25.25 0 01.25.25v3.25a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-1.25a1.25 1.25 0 012.5 0v1.25a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-3.25A.25.25 0 0116 12h.91a.34.34 0 00.319-.219.329.329 0 00-.1-.367z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});