define("ember-svg-jar/inlined/payment-paypal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>payment-paypal</title><path d=\"M21.71 6.44a.75.75 0 00-1.31.74 4 4 0 01.28 2.88 6 6 0 01-6 4.82h-3.59a.75.75 0 00-.73.57L9 20.88H4.7a.75.75 0 000 1.5h4.88a.75.75 0 00.73-.57l1.37-5.44h3a7.48 7.48 0 007.43-6 5.5 5.5 0 00-.4-3.93z\"/><path d=\"M7.55 19.23l1.4-5.61H12a7.26 7.26 0 007.35-5.82 4.93 4.93 0 00-.81-4.34 5.26 5.26 0 00-4.1-1.84H6.16a.5.5 0 00-.49.38l-4 17a.5.5 0 00.49.61h4.9a.5.5 0 00.49-.38zm1.21-9.41l1-4.48a.5.5 0 01.53-.34h2.39a2.3 2.3 0 011.32.38 2.2 2.2 0 01.77 2.45 3.39 3.39 0 01-1 1.65 3.76 3.76 0 01-.55.43 3.26 3.26 0 01-1.72.52H9.24a.5.5 0 01-.49-.61z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});