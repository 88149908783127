define("ember-svg-jar/inlined/harddrive-upload-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>harddrive-upload-1</title><path d=\"M10.643 5.619a.25.25 0 00-.25-.25H8.69A.977.977 0 018 3.7L11.417.286a.975.975 0 011.38 0L16.214 3.7a.976.976 0 01-.69 1.667h-1.7a.25.25 0 00-.25.25V9.8a1.464 1.464 0 01-2.928 0zM21 14H3a3 3 0 00-3 3v4a3 3 0 003 3h18a3 3 0 003-3v-4a3 3 0 00-3-3zM4.75 19.11a.75.75 0 01.75-.75H9a.75.75 0 010 1.5H5.5a.75.75 0 01-.75-.75zM19 20.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});