define("ember-svg-jar/inlined/fitness-grip-weights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fitness-grip-weights</title><path d=\"M17.911 5.933a1 1 0 01-.411-.808V2.5A2.5 2.5 0 0015 0H9a2.5 2.5 0 00-2.5 2.5v2.625a1 1 0 01-.41.808A10 10 0 1022 14a9.936 9.936 0 00-4.089-8.067zm-6.122 14.11a.752.752 0 01-.74.632.8.8 0 01-.119-.009 6.8 6.8 0 01-5.613-5.708.75.75 0 011.483-.211 5.279 5.279 0 004.363 4.437.751.751 0 01.626.859zM15 3.8a.5.5 0 01-.619.486 10.088 10.088 0 00-4.762 0A.5.5 0 019 3.8V3a1 1 0 011-1h4a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});