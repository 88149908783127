define("ember-svg-jar/inlined/clamps-expand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>clamps-expand</title><path d=\"M24 1a1 1 0 00-1-1H1a1 1 0 00-1 1v4a1 1 0 001 1h22a1 1 0 001-1zM23 24a1 1 0 000-2h-3.56a.5.5 0 01-.27-.079l-4.993-3.211a.249.249 0 010-.42l5.364-3.449a1 1 0 000-1.682L14.177 9.71a.249.249 0 010-.42l1.352-.869a.5.5 0 00-.271-.921h-1.85a.488.488 0 00-.27.08l-1 .644a.249.249 0 01-.27 0l-1-.644a.488.488 0 00-.27-.08H8.742a.5.5 0 00-.271.921l1.352.869a.249.249 0 010 .42l-5.364 3.449a1 1 0 000 1.682l5.364 3.449a.249.249 0 010 .42L4.83 21.921a.5.5 0 01-.27.079H1a1 1 0 000 2zM7.177 14.21a.249.249 0 010-.42l4.688-3.014a.249.249 0 01.27 0l4.688 3.014a.249.249 0 010 .42l-4.688 3.014a.249.249 0 01-.27 0zm4.688 5.566a.249.249 0 01.27 0l2.744 1.764a.25.25 0 01-.136.46H9.257a.25.25 0 01-.136-.46z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});