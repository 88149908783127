define("ember-svg-jar/inlined/room-service-staff", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>room-service-staff</title><path d=\"M21.505 11.905a4 4 0 00-4-4h-5a4 4 0 00-3.978 3.677v3.03a.251.251 0 00.321.24 2.989 2.989 0 002.157-2.868v-.329a.5.5 0 011 0v11.088a1.25 1.25 0 002.5 0v-5.728a.5.5 0 011 0v5.728a1.25 1.25 0 002.5 0V11.655a.5.5 0 111 0v3.86a1.25 1.25 0 002.5 0zM16.891.333a5.672 5.672 0 00-3.792 0 .906.906 0 00-.6.856v.817a.25.25 0 00.25.25h4.5a.249.249 0 00.25-.25v-.817a.9.9 0 00-.608-.856zM12.5 4.256a2.5 2.5 0 005 0v-.75a.249.249 0 00-.25-.25h-4.5a.25.25 0 00-.25.25zM2.5 12.984v4.5a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-4.5a.5.5 0 00-.5-.5H3a.5.5 0 00-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});