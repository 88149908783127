define("ember-svg-jar/inlined/transportation-ticket-boat-plane-transfer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transportation-ticket-boat-plane-transfer</title><path d=\"M1.9 8.393a.211.211 0 00.36.137 2 2 0 012.829 0 .813.813 0 00.6.31.813.813 0 00.6-.31 2 2 0 012.828 0 .736.736 0 001.2 0 2 2 0 012.828 0 .831.831 0 00.447.264.252.252 0 00.265-.11l.091-.144a1.2 1.2 0 00-1.007-1.845H8.985a.25.25 0 01-.25-.25V.839A.75.75 0 007.421.344l-3.5 4a.751.751 0 00.564 1.245h2.5a.249.249 0 01.25.25v.6a.249.249 0 01-.25.25H3.538a1.654 1.654 0 00-1.644 1.68v.019zM7.56 11.018a.25.25 0 01.273 0 3.369 3.369 0 003.756 0 .249.249 0 01.271 0 4.389 4.389 0 002.547.574.75.75 0 000-1.5 2.561 2.561 0 01-2.154-.675.75.75 0 00-1.061 0 1.974 1.974 0 01-2.965 0 .75.75 0 00-1.061 0 1.975 1.975 0 01-2.966 0 .75.75 0 00-1.061 0 2.56 2.56 0 01-2.154.675.75.75 0 100 1.5 4.385 4.385 0 002.547-.574.251.251 0 01.272 0 3.369 3.369 0 003.756 0zM16.072 10.479c.041.032.111.093.183.158a.249.249 0 01-.022.391.678.678 0 00-.307.6.778.778 0 00.463.692l1.96.813a.755.755 0 00.573 0 .755.755 0 00.407-.407l.812-1.96a.751.751 0 00-.981-.98l-.516.214a.25.25 0 01-.288-.071 7.008 7.008 0 00-1.047-1.019 1 1 0 00-1.405.168.962.962 0 00-.148.295.992.992 0 00.316 1.106zM8.379 16.322a.113.113 0 01.011-.108.114.114 0 01.095-.051.751.751 0 00.531-1.281l-1.5-1.5a.752.752 0 00-1.061 0l-1.5 1.5a.751.751 0 00.53 1.281h.537a.25.25 0 01.24.181 6.937 6.937 0 001.878 3.121 1 1 0 101.385-1.443 4.932 4.932 0 01-1.146-1.7zM23.66 16.675a1.872 1.872 0 00-1.349-1.21 1.784 1.784 0 00-1.411.269l-2 1.365a.25.25 0 01-.228.027L14.021 15.4a.828.828 0 00-.785.112l-.891.66a1.311 1.311 0 00-.133.125.843.843 0 00.154 1.216l2.669 1.653a.25.25 0 01.011.417l-.587.41a.249.249 0 01-.271.01l-1.1-.655A.594.594 0 0013 19.3a.778.778 0 00-.751.143l-1.179 1.039-.009.007a1.294 1.294 0 00-.129.127.776.776 0 00.145 1.1l2.878 2.02a.98.98 0 001.084.03l7.854-4.877a1.863 1.863 0 00.871-1.6 1.812 1.812 0 00-.104-.614z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});