define("ember-svg-jar/inlined/real-estate-deal-pay-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-deal-pay-building</title><path d=\"M16.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm1.112 10.158a.5.5 0 00-.362.48v.112a.75.75 0 11-1.5 0 .5.5 0 00-.5-.5H15a.75.75 0 110-1.5h2.033a.592.592 0 00.22-1.142l-2.063-.825a2.084 2.084 0 01.2-3.941.5.5 0 00.362-.48v-.112a.75.75 0 011.5 0 .5.5 0 00.5.5H18a.75.75 0 110 1.5h-2.032a.592.592 0 00-.22 1.141l2.063.825a2.085 2.085 0 01-.2 3.942zM8.5 18.75H7.249a2 2 0 01-2-2V16a.5.5 0 01.5-.5h1a.75.75 0 00.53-1.28l-2.5-2.5a.749.749 0 00-1.06 0l-2.5 2.5a.75.75 0 00.53 1.28h1a.5.5 0 01.5.5v.75a4 4 0 004 4H8.5a1 1 0 000-2zM10.753 2.75a1 1 0 001 1h3a2 2 0 012 2A.25.25 0 0116.5 6h-1.25a.75.75 0 00-.53 1.28l2.5 2.5a.748.748 0 001.06 0l2.5-2.5A.75.75 0 0020.253 6H19a.25.25 0 01-.25-.25 4 4 0 00-4-4h-3a1 1 0 00-.997 1zM1.5 10H3a.5.5 0 00.5-.5v-1A.5.5 0 014 8h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5V2a.5.5 0 00-.276-.447l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 001 2v7.5a.5.5 0 00.5.5zM3 2.5h3a.5.5 0 110 1H3a.5.5 0 110-1zM3 5h3a.5.5 0 110 1H3a.5.5 0 110-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});