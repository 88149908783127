define("ember-svg-jar/inlined/modern-tv-remote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-tv-remote</title><path d=\"M21.565.5H2.435A2.447 2.447 0 000 2.953v12.594A2.447 2.447 0 002.435 18H8a1 1 0 000-2H2.435A.45.45 0 012 15.547V2.953a.45.45 0 01.435-.453h19.13a.45.45 0 01.435.453v12.594a.45.45 0 01-.435.453H21a1 1 0 000 2h.565A2.447 2.447 0 0024 15.547V2.953A2.447 2.447 0 0021.565.5z\"/><path d=\"M17.5 12.5h-6a1 1 0 00-1 1v9a1 1 0 001 1h6a1 1 0 001-1v-9a1 1 0 00-1-1zM16 17a1 1 0 111-1 1 1 0 01-1 1zm1 2.5a1 1 0 11-1-1 1 1 0 011 1zM13 17a1 1 0 111-1 1 1 0 01-1 1zm1 2.5a1 1 0 11-1-1 1 1 0 011 1zM18.328 7.5A1 1 0 0018 6.123a6.739 6.739 0 00-6.789-.146 1 1 0 10.973 1.748 4.735 4.735 0 014.771.1 1 1 0 001.373-.325zM16.664 9.31a4.175 4.175 0 00-4.21-.085 1 1 0 10.971 1.748 2.175 2.175 0 012.192.041 1 1 0 101.047-1.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});