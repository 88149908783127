define("ember-svg-jar/inlined/phone-action-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-download</title><path d=\"M15.746 11.618l-1.5-.013a.25.25 0 01-.248-.25V7.1a.5.5 0 00-.5-.5h-2.487a.513.513 0 00-.511.511v4.239a.25.25 0 01-.25.25H8.756a.256.256 0 00-.237.154.247.247 0 00.056.272l3.475 3.4a.26.26 0 00.36 0l3.514-3.382a.248.248 0 00.057-.273.255.255 0 00-.235-.153z\"/><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});