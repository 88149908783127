define("ember-svg-jar/inlined/octopus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>octopus</title><path d=\"M21.194 18.965a4.071 4.071 0 002.621-5.16 1 1 0 00-.913-.748.981.981 0 00-.991.644A3.042 3.042 0 0119 15.5c-1.633 0-2.527-2.165-2.536-2.186-.515-1.289.151-2.51 1.4-5.315A6.66 6.66 0 0018.5 5.5C18.5 1.893 15.23 0 12 0 8.869 0 5.5 1.725 5.5 5.5A6.654 6.654 0 006.14 8c1.194 2.686 1.928 3.979 1.4 5.311C7.527 13.34 6.643 15.5 5 15.5a3.041 3.041 0 01-2.911-1.8.963.963 0 00-.992-.644.994.994 0 00-.912.749 4.071 4.071 0 002.621 5.159 5.091 5.091 0 004.573-.328 2.308 2.308 0 01-.475.784c-.782.852-2.3 1.236-4.361 1.13a.492.492 0 00-.412.18.5.5 0 00-.1.437c1.258 5.19 8.124 2.5 9.971-2.161 1.839 4.643 8.706 7.354 9.971 2.162a.5.5 0 00-.1-.438.5.5 0 00-.412-.18c-2.054.108-3.564-.274-4.35-1.117a2.342 2.342 0 01-.49-.8 5.092 5.092 0 004.573.332zM10 10.5a1 1 0 111-1 1 1 0 01-1 1zm4 0a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});