define("ember-svg-jar/inlined/chick-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chick-1</title><path d=\"M20.114 6.775A6.5 6.5 0 007.119 7v3a3.5 3.5 0 01-3.5 3.5H.631a.5.5 0 00-.5.526 10 10 0 0019.988-.526v-1.75a.5.5 0 01.5-.5h2.5a.75.75 0 00.75-.75 3.961 3.961 0 00-3.755-3.725zm-8.992 12.973a4.167 4.167 0 01-4.149-3.713.748.748 0 01.663-.827.739.739 0 01.827.663A2.676 2.676 0 0013.8 15.7a.762.762 0 01.783-.716.748.748 0 01.715.782 4.165 4.165 0 01-4.176 3.982zm3.747-12.5A1.25 1.25 0 1116.119 6a1.25 1.25 0 01-1.25 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});