define("ember-svg-jar/inlined/presentation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>presentation</title><path d=\"M8.5 9h7a.5.5 0 00.479-.643 4.229 4.229 0 00-2.607-2.84.251.251 0 01-.062-.439 2.25 2.25 0 10-2.619 0 .25.25 0 01-.063.438 4.227 4.227 0 00-2.607 2.84A.5.5 0 008.5 9zM23.979 17.856a4.229 4.229 0 00-2.607-2.84.251.251 0 01-.062-.439 2.25 2.25 0 10-2.62 0 .25.25 0 01-.062.439 3.97 3.97 0 00-1.008.532.249.249 0 00-.1.253l.521 2.5a.249.249 0 00.244.2H23.5a.5.5 0 00.479-.643zM6.478 15.8a.249.249 0 00-.1-.253 3.939 3.939 0 00-1.008-.532.25.25 0 01-.062-.439 2.25 2.25 0 10-2.619 0 .25.25 0 01-.063.438 4.227 4.227 0 00-2.607 2.84.5.5 0 00.481.646h5.213a.249.249 0 00.244-.2zM15.666 11.8a1 1 0 00-.979-.8H9.313a1 1 0 00-.979.8l-2.083 10A1 1 0 007.23 23h9.54a1 1 0 00.979-1.2zM14.3 20.827a.5.5 0 01-.377.172h-3.846a.5.5 0 01-.495-.57l.357-2.5a.5.5 0 01.495-.43h3.132a.5.5 0 01.495.43l.357 2.5a.5.5 0 01-.118.398z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});