define("ember-svg-jar/inlined/arrow-thick-down-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-down-1</title><path d=\"M22.925 11.975l-2.757-2.758a1 1 0 00-1.385-.029l-3.363 3.1A.25.25 0 0115 12.1v-11a1 1 0 00-1-1h-4a1 1 0 00-1 1v11.016a.251.251 0 01-.42.184L5.22 9.191a1 1 0 00-1.387.026l-2.758 2.758a1 1 0 000 1.414l10.219 10.217a1 1 0 001.414 0l10.217-10.217a1 1 0 000-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});