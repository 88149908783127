define("ember-svg-jar/inlined/phone-action-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-heart</title><path d=\"M14.386 7.533a2.485 2.485 0 00-2.183.7l-.2.2-.2-.2a2.517 2.517 0 00-3.568 3.552l3.41 3.557a.5.5 0 00.722 0l3.4-3.549a2.514 2.514 0 00.471-2.905 2.489 2.489 0 00-1.852-1.355z\"/><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});