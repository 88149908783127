define("ember-svg-jar/inlined/ym-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ym-1</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM11.125 13.428A2.62 2.62 0 019.3 15.919a.247.247 0 00-.176.233v3.276a.625.625 0 01-1.25 0v-3.276a.247.247 0 00-.176-.233 2.62 2.62 0 01-1.824-2.491v-2a.625.625 0 011.25 0v2a1.375 1.375 0 002.75 0v-2a.625.625 0 011.25 0zm6 6a.625.625 0 01-1.25 0v-4.709c0-.181-.237-.17-.313-.017l-.5 1.005a.625.625 0 01-1.118 0l-.524-1.047c-.08-.161-.292-.123-.292.019v4.749a.625.625 0 01-1.25 0v-8a.625.625 0 011.184-.279l1.217 2.435a.251.251 0 00.448 0l1.217-2.435a.625.625 0 011.184.279z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});