define("ember-svg-jar/inlined/shipment-smartphone-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-smartphone-1</title><path d=\"M1.023 4.276a.249.249 0 00-.232.024.252.252 0 00-.11.206v7.441a1 1 0 00.6.916l6.551 2.866a.25.25 0 00.35-.229V7.279a.251.251 0 00-.158-.233zM15.408 3.328a.25.25 0 00-.015-.465L8.568.454a1 1 0 00-.755.037h-.007L1.857 3.072a.25.25 0 00.007.462L8.48 6.152a.5.5 0 00.382 0zM16.514 4.976a.25.25 0 00.167-.235v-.492a.251.251 0 00-.349-.23l-7 3.014a.25.25 0 00-.151.23v8.128a.249.249 0 00.367.221l2.5-1.326a.249.249 0 00.133-.22V11.1a6.5 6.5 0 014.333-6.124z\"/><path d=\"M18.752 6.353a6.007 6.007 0 00-4.276 1.772 1 1 0 001.414 1.414 4.02 4.02 0 012.86-1.186 4.02 4.02 0 012.862 1.186 1 1 0 101.414-1.414 6.007 6.007 0 00-4.274-1.772z\"/><path d=\"M16.505 10.548a1 1 0 001.414 1.415 1.23 1.23 0 011.736 0 1 1 0 101.414-1.414 3.232 3.232 0 00-4.564-.001zM21.181 13.1h-5.006a1.972 1.972 0 00-2 1.939l.006 6.811a1.868 1.868 0 002 1.75h5a1.868 1.868 0 002-1.75v-6.81a1.971 1.971 0 00-2-1.94zm-.5 7h-4a.5.5 0 01-.5-.5v-4a.5.5 0 01.493-.505l4.006-.05a.5.5 0 01.506.5V19.6a.5.5 0 01-.505.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});