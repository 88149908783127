define("ember-svg-jar/inlined/show-theater-mask-happy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>show-theater-mask-happy</title><path d=\"M22.351.267A1.449 1.449 0 0021 .093c-3.955 1.47-14.051 1.47-18 0a1.452 1.452 0 00-1.351.174A1.511 1.511 0 001 1.507V12c0 6.753 8.774 12 11 12s11-5.247 11-12V1.507a1.511 1.511 0 00-.649-1.24zM6.418 8.875a.75.75 0 01-1.3-.754 2.75 2.75 0 014.759 0 .75.75 0 11-1.3.753 1.3 1.3 0 00-2.159.001zM12 18.25c-2.619 0-4.75-1.682-4.75-3.75a.75.75 0 01.75-.75h8a.75.75 0 01.75.75c0 2.068-2.131 3.75-4.75 3.75zm6.607-9.1a.749.749 0 01-1.025-.271 1.3 1.3 0 00-2.165 0 .75.75 0 01-1.3-.753 2.75 2.75 0 014.759 0 .75.75 0 01-.269 1.02z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});