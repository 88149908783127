define("ember-svg-jar/inlined/ab-testing-award", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ab-testing-award</title><path d=\"M10.75 15.75v7.75a.25.25 0 00.25.25h7.5a.25.25 0 00.25-.25v-7.75a1 1 0 00-1-1h-6a1 1 0 00-1 1zm3 2.75a.75.75 0 010-1.5h.5a1.252 1.252 0 011.25 1.25v2.5a.25.25 0 00.25.25.75.75 0 010 1.5h-2a.75.75 0 010-1.5.25.25 0 00.25-.25v-2a.25.25 0 00-.25-.25zM20.5 23.75h3a.25.25 0 00.25-.25v-2.75a1 1 0 00-1-1H20.5a.25.25 0 00-.25.25v3.5a.25.25 0 00.25.25zM6 23.75h3a.25.25 0 00.25-.25V20a.25.25 0 00-.25-.25H6.75a1 1 0 00-1 1v2.75a.25.25 0 00.25.25zM17.722 9.133a.5.5 0 00-.325-.88h-1.658a.249.249 0 01-.235-.167l-.533-1.5a.5.5 0 00-.942 0l-.532 1.5a.251.251 0 01-.236.167H11.6a.5.5 0 00-.325.88l1.249 1.067a.25.25 0 01.07.281l-.742 1.9a.5.5 0 00.724.61L14.37 11.9a.253.253 0 01.26 0l1.794 1.088a.5.5 0 00.724-.61l-.742-1.9a.25.25 0 01.07-.281z\"/><path d=\"M20.75 13.75a1 1 0 001-1v-9.5a3 3 0 00-3-3H3.25a3 3 0 00-3 3v11a3 3 0 003 3h4.5a1 1 0 000-2h-4.5a1 1 0 01-1-1V5.5a.25.25 0 01.25-.25h17a.25.25 0 01.25.25v7.25a1 1 0 001 1zM4 3.75A.75.75 0 114.75 3a.749.749 0 01-.75.75zm2.5 0A.75.75 0 117.25 3a.749.749 0 01-.75.75zm2.5 0A.75.75 0 119.75 3a.749.749 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});