define("ember-svg-jar/inlined/crime-razor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-razor</title><path d=\"M21 20.5a2.9 2.9 0 00-.662.077.249.249 0 01-.273-.115 3.01 3.01 0 00-3.035-1.424.248.248 0 01-.276-.165 4.227 4.227 0 00-8.016.023.219.219 0 01-.245.146A2.9 2.9 0 008 19a3.007 3.007 0 00-2.92 2.307.249.249 0 01-.243.193H1.25a1.25 1.25 0 000 2.5H23.5a.5.5 0 00.5-.5 3 3 0 00-3-3zM1.675 12.852a1 1 0 00.677.7.982.982 0 00.923-.184l1.085-.824a.25.25 0 01.4.215l-.051.83a1 1 0 001 1.062 1.016 1.016 0 00.256-.033l11.221-2.988a1 1 0 00.578-1.517l-.459-.7a.25.25 0 01.241-.385l1.321.17a1 1 0 001.169-1.236L18.4 1.8a1.012 1.012 0 00-.679-.7.98.98 0 00-.922.185l-1.09.827a.25.25 0 01-.4-.215l.051-.832A1 1 0 0014.1.033L2.877 3.021A1 1 0 002.3 4.536l.458.7a.251.251 0 01-.241.386L1.2 5.447a.992.992 0 00-.928.293 1.009 1.009 0 00-.24.944zM5.01 7.886l1.862-.5a.25.25 0 00.178-.306l-.132-.495A.75.75 0 018.367 6.2l.132.5a.251.251 0 00.306.178l1.85-.493a.249.249 0 00.177-.306l-.13-.489a.75.75 0 011.449-.39l.13.49a.25.25 0 00.306.177l2.087-.555a.75.75 0 01.386 1.449l-2.087.555a.249.249 0 00-.177.306l.127.477a.75.75 0 11-1.449.386l-.127-.477a.251.251 0 00-.306-.178l-1.851.495a.249.249 0 00-.177.306l.126.472a.75.75 0 01-.532.917.717.717 0 01-.193.026.752.752 0 01-.725-.558l-.125-.471a.251.251 0 00-.306-.178l-1.862.5a.712.712 0 01-.193.026.75.75 0 01-.193-1.479z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});