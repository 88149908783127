define("ember-svg-jar/inlined/road-sign-divided-highway-ahead-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-divided-highway-ahead-1-alternate</title><path d=\"M13.75 9.423a1.5 1.5 0 11-3 0v-2.75a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25zM15.433 12.1a.249.249 0 00.067-.17v-.789a.25.25 0 00-.187-.242.749.749 0 01-.343-1.256l1-1a.749.749 0 011.06 0l1 1a.749.749 0 01-.343 1.256.25.25 0 00-.187.242v1.282a.991.991 0 01-.27.682l-3.413 3.65a.25.25 0 00-.067.171V18.5a1 1 0 01-2 0v-1.87a1.467 1.467 0 01.416-1.036zM5.821 13.2a.253.253 0 00.189-.218 1.369 1.369 0 01.376-.85L7.69 10.6a.248.248 0 00.06-.162V8.423a1 1 0 012 0v2.293a1.437 1.437 0 01-.386 1l-1.025 1.2a.25.25 0 00.059.376.729.729 0 01.295.343.749.749 0 01-.163.817l-1 1a.748.748 0 01-1.06 0l-1-1a.749.749 0 01.351-1.252z\"/><path d=\"M11.293 23.707a1 1 0 001.414 0l11-11a1 1 0 000-1.414l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414zm.53-21.116a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354l-9.232 9.232a.25.25 0 01-.354 0l-9.232-9.232a.25.25 0 010-.354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});