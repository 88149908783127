define("ember-svg-jar/inlined/presentation-projector-screen-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>presentation-projector-screen-pin</title><path d=\"M11.831 15.6a.25.25 0 00.338 0C12.93 14.9 16 11.94 16 9.749a4 4 0 10-8 0c0 2.19 3.07 5.151 3.831 5.851zM12 8.749a1 1 0 11-1 1 1 1 0 011-1z\"/><path d=\"M24 1.749a1.5 1.5 0 00-1.5-1.5h-21a1.5 1.5 0 00-.214 2.985.25.25 0 01.214.248v14.542a.25.25 0 01-.2.245 1 1 0 00.2 1.98H11a.25.25 0 01.25.25v.315a.249.249 0 01-.107.2 1.5 1.5 0 101.714 0 .249.249 0 01-.107-.2V20.5a.25.25 0 01.25-.25h9.5a1 1 0 00.2-1.98.249.249 0 01-.2-.245V3.482a.25.25 0 01.214-.248A1.5 1.5 0 0024 1.749zM20.5 18a.249.249 0 01-.25.25H3.75A.249.249 0 013.5 18V3.5a.25.25 0 01.25-.25h16.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});