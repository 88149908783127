define("ember-svg-jar/inlined/real-estate-sign-sold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-sign-sold</title><path d=\"M10.874 13.407a.5.5 0 00-.53.469L10.03 19a.5.5 0 00.523.531c.91-.043 1.508-.041 1.966-.027a.5.5 0 00.514-.469l.306-4.979a.5.5 0 00-.469-.53zM23.734 23.058A25.57 25.57 0 0012 20.5 25.57 25.57 0 00.266 23.058.5.5 0 00.5 24h23a.5.5 0 00.234-.942zM10.181 8.215a.378.378 0 00.4-.353l.184-2.993a.376.376 0 00-.353-.4h-.021a.373.373 0 00-.374.351l-.185 3a.377.377 0 00.349.395zM18.162 8.3a1.363 1.363 0 00.461-.946l.062-1a1.366 1.366 0 00-.253-.881.25.25 0 00-.453.13l-.156 2.53a.205.205 0 00.339.167z\"/><path d=\"M3.949 11.98l15.97.98a2.5 2.5 0 002.648-2.343L23 3.631A2.505 2.505 0 0020.652.982L4.684 0a2.5 2.5 0 00-2.649 2.346l-.429 6.987a2.5 2.5 0 002.343 2.647zm12.862-7.742a.637.637 0 01.662-.586 2.63 2.63 0 012.46 2.781l-.062 1A2.627 2.627 0 0117.255 9.9c-.055 0-.11 0-.166-.005a.626.626 0 01-.585-.662zm-3.993-.245a.638.638 0 01.663-.586.626.626 0 01.585.662l-.245 3.993a.37.37 0 00.093.272.377.377 0 00.258.125l1 .062a.625.625 0 11-.076 1.248l-1-.062a1.627 1.627 0 01-1.523-1.722zm-4.053.754a1.623 1.623 0 011.72-1.523 1.628 1.628 0 011.523 1.72l-.184 3a1.626 1.626 0 11-3.245-.2zM5.189 7.908l1 .062a.357.357 0 00.271-.094.372.372 0 00.125-.257c.025-.418-.218-.645-.687-1.045A2.448 2.448 0 014.772 4.5a1.642 1.642 0 011.72-1.523l1 .061a.625.625 0 01.586.662.642.642 0 01-.662.586l-1-.061a.378.378 0 00-.4.352c-.026.416.217.644.687 1.043a2.448 2.448 0 011.129 2.074 1.626 1.626 0 01-1.721 1.524l-1-.062a.626.626 0 01-.586-.662.642.642 0 01.664-.586z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});