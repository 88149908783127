define("ember-svg-jar/inlined/lucide-paintbrush-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14 19.9V16h3a2 2 0 002-2v-2H5v2c0 1.1.9 2 2 2h3v3.9a2 2 0 104 0zM6 12V2h12v10M14 2v4M10 2v2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});