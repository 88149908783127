define("ember-svg-jar/inlined/phone-rotate-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-rotate-1</title><path d=\"M23.843 6.339a9.789 9.789 0 00-6-4.327V.873a.75.75 0 00-1.205-.595l-2.6 2a.75.75 0 000 1.189l2.6 2a.765.765 0 00.789.079.749.749 0 00.419-.673v-.791A7.808 7.808 0 0122.15 7.4a1.014 1.014 0 001.379.315 1 1 0 00.314-1.376zM9.99 20.985c-.033-.03-.068-.057-.1-.084l-2.266-1.755c-.211-.163-.476-.336-.725-.24a.637.637 0 00-.323.626 3.483 3.483 0 01.015.749 7.722 7.722 0 01-4.465-3.043 2.261 2.261 0 00-.672-.738 1 1 0 00-1.043.058 1 1 0 00-.394.966 1.792 1.792 0 00.317.667 9.964 9.964 0 006.257 4.139 6.173 6.173 0 01-.017.921.694.694 0 00.336.589c.273.124.513-.09.717-.246l2.187-1.682c.2-.157.429-.368.383-.62a.553.553 0 00-.207-.307zM10.869 2.537a2.5 2.5 0 00-3.536 0L2.4 7.473a2.5 2.5 0 000 3.535l9.464 9.464a2.5 2.5 0 003.535 0l4.935-4.935a2.5 2.5 0 000-3.536zm-.738 1.911l7 7a1 1 0 010 1.414l-4.409 4.41a1 1 0 01-1.414 0l-7-7a1 1 0 010-1.415l4.409-4.409a1 1 0 011.414 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});