define("ember-svg-jar/inlined/email-action-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-upload</title><path d=\"M19.6 16.24a.3.3 0 00-.3.007l-5.872 3.694a2.682 2.682 0 01-2.844 0l-5.878-3.693a.3.3 0 00-.3-.008.3.3 0 00-.154.262v5.687a1.8 1.8 0 001.788 1.8h11.922a1.8 1.8 0 001.788-1.8V16.5a.3.3 0 00-.15-.26z\"/><path d=\"M11.526 18.416a.9.9 0 00.948 0l7.234-4.551a.091.091 0 00.042-.076 1.8 1.8 0 00-1.788-1.8H6.038a1.8 1.8 0 00-1.788 1.8.089.089 0 00.042.076zM9.25 5.989h1.25a.25.25 0 01.25.25v2.75a1.5 1.5 0 003 0v-2.75a.25.25 0 01.25-.25h1.25a1 1 0 00.8-1.6l-3-4a1.036 1.036 0 00-1.6 0l-3 4a1 1 0 00.8 1.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});