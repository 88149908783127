define("ember-svg-jar/inlined/calendar-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>calendar-shield</title><path d=\"M9.5 16.5h-7A.5.5 0 012 16V7.5a.5.5 0 01.5-.5H16a.5.5 0 01.5.5v2.034c.324-.021.65-.033.978-.033s.683.014 1.022.037V3a1 1 0 00-1-1H15a.25.25 0 01-.25-.25v-1a.75.75 0 10-1.5 0V4.5a.75.75 0 11-1.5 0v-2a.5.5 0 00-.5-.5H7a.25.25 0 01-.25-.25v-1a.75.75 0 00-1.5 0V4.5a.75.75 0 11-1.5 0v-2a.5.5 0 00-.5-.5H1a1 1 0 00-1 1v13.5a2 2 0 002 2h7.7a7.373 7.373 0 01-.2-1.652z\"/><path d=\"M24 13.457a1.543 1.543 0 00-.928-1.416A16.393 16.393 0 0017.478 11a15.986 15.986 0 00-5.55 1.04A1.543 1.543 0 0011 13.457v3.392a7.716 7.716 0 005.19 6.806 3.213 3.213 0 001.288.345 3.247 3.247 0 001.289-.346C21.848 22.481 24 19.682 24 16.849zM18.25 15.5a.25.25 0 00.25.25h2a.75.75 0 010 1.5h-2a.25.25 0 00-.25.25v2a.75.75 0 11-1.5 0v-2a.25.25 0 00-.25-.25h-2a.75.75 0 110-1.5h2a.25.25 0 00.25-.25v-2a.75.75 0 111.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});