define("ember-svg-jar/inlined/server-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-search</title><path d=\"M0 3.5A2.5 2.5 0 002.5 6h17A2.5 2.5 0 0022 3.5v-1A2.5 2.5 0 0019.5 0h-17A2.5 2.5 0 000 2.5zM3.635 3A1.115 1.115 0 114.75 4.115 1.115 1.115 0 013.635 3zM7.5 3a1.115 1.115 0 111.115 1.115A1.114 1.114 0 017.5 3zM16.529 9.5a6.98 6.98 0 014.617 1.743.5.5 0 00.806-.274A8.286 8.286 0 0022 9.5 2.5 2.5 0 0019.5 7h-17A2.5 2.5 0 000 9.5v1A2.5 2.5 0 002.5 13h7.687a.491.491 0 00.415-.234A7.024 7.024 0 0116.529 9.5zm-6.8.5a1.115 1.115 0 11-1.114-1.115A1.114 1.114 0 019.729 10zm-3.864 0A1.115 1.115 0 114.75 8.885 1.115 1.115 0 015.865 10zM2.5 20h7.122a.494.494 0 00.457-.689 6.937 6.937 0 01-.308-4.694.5.5 0 00-.486-.617H2.5A2.5 2.5 0 000 16.5v1A2.5 2.5 0 002.5 20zm1.135-3a1.115 1.115 0 111.115 1.114A1.115 1.115 0 013.635 17z\"/><path d=\"M11 16.529a5.524 5.524 0 008.5 4.66.25.25 0 01.311.034l2.484 2.484a1 1 0 001.414-1.414l-2.484-2.484a.251.251 0 01-.034-.312A5.527 5.527 0 1011 16.529zM16.529 13A3.529 3.529 0 1113 16.529 3.533 3.533 0 0116.529 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});