define("ember-svg-jar/inlined/charging-battery-low-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>charging-battery-low-3</title><path d=\"M22 8.5h-1v-1a2 2 0 00-2-2H2a2 2 0 00-2 2v9a2 2 0 002 2h17a2 2 0 002-2v-1h1a2 2 0 002-2v-3a2 2 0 00-2-2zm0 4.75a.25.25 0 01-.25.25H20a1 1 0 00-1 1V16a.5.5 0 01-.5.5h-16A.5.5 0 012 16V8a.5.5 0 01.5-.5h16a.5.5 0 01.5.5v1.5a1 1 0 001 1h1.75a.25.25 0 01.25.25z\"/><rect x=\"3\" y=\"8.5\" width=\"4.5\" height=\"7\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});