define("ember-svg-jar/inlined/design-document-dwg-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-document-dwg-1</title><path d=\"M4.437 12.257a.252.252 0 00-.312.246V18.5a.251.251 0 00.312.246 3.376 3.376 0 000-6.486z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-19 20.125a.625.625 0 01-.625-.625v-8a.625.625 0 01.625-.625 4.625 4.625 0 010 9.25zm12.625-6.041c0 2.692-.6 4.772-1.683 5.858a.625.625 0 01-1.045-.277s-.675-2.426-.724-2.657-.283-.229-.346 0l-.724 2.657a.625.625 0 01-1.045.277c-1.085-1.086-1.683-3.166-1.683-5.858V11.5a.625.625 0 011.25 0v2.584a13.2 13.2 0 00.354 3.07c.133.3.42.341.513 0l.9-3.319a.625.625 0 011.206 0s.842 3.073.9 3.319.364.377.513 0a12.827 12.827 0 00.354-3.07V11.5a.625.625 0 011.25 0zm3.375 4.791a1.377 1.377 0 001.375-1.375v-.124a.251.251 0 00-.251-.251H20a.625.625 0 010-1.25h1.5a.625.625 0 01.625.625v1a2.625 2.625 0 01-5.25 0v-4a2.625 2.625 0 015.25 0 .625.625 0 01-1.25 0 1.375 1.375 0 00-2.75 0v4a1.377 1.377 0 001.375 1.375z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});