define("ember-svg-jar/inlined/audio-file-mid-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file-mid-1</title><path d=\"M23.707 5.707L18.293.293A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.75V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707z\"/><path d=\"M12.955 12.319a.25.25 0 00-.33.24v3.882a.25.25 0 00.33.24 2.237 2.237 0 001.42-2.181 2.237 2.237 0 00-1.42-2.181z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zM6.625 17.5a.625.625 0 01-1.25 0v-3.032c0-.1-.122-.078-.159 0l-.157.314a.65.65 0 01-1.118 0s-.1-.2-.148-.3-.168-.085-.168 0V17.5a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279s.718 1.428.779 1.558a.178.178 0 00.324 0l.779-1.557a.625.625 0 011.184.279zm3.25-.625H10a.625.625 0 010 1.25H8a.625.625 0 010-1.25h.125a.25.25 0 00.25-.25v-4.25a.25.25 0 00-.25-.25H8a.625.625 0 010-1.25h2a.625.625 0 010 1.25h-.125a.25.25 0 00-.25.25v4.25a.25.25 0 00.25.25zM12 18.125a.625.625 0 01-.625-.625v-6a.625.625 0 01.625-.625 3.625 3.625 0 010 7.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});