define("ember-svg-jar/inlined/aircraft-plane-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>aircraft-plane-1</title><path d=\"M22.874 5.309l.809-2.157a1.5 1.5 0 00-1.4-2.027h-.6a1.5 1.5 0 00-1.129.513L18 4.543a1.7 1.7 0 01-1.281.582h-11A3.465 3.465 0 003.214 6.2a.25.25 0 00.181.423h.327a.75.75 0 110 1.5h-1.75a1.75 1.75 0 000 3.5h5.6a.25.25 0 00.245-.2 3.227 3.227 0 011.01-1.746 3.273 3.273 0 012.563-.792l5.024.628a2.625 2.625 0 012.139 1.72.25.25 0 00.287.159c2.39-.5 4.881-1.775 4.881-3.267a3.639 3.639 0 00-.8-2.557.249.249 0 01-.047-.259zM8.222 8.375a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zm11 0a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0z\"/><path d=\"M16.291 10.505l-5.024-.628a2.266 2.266 0 00-2.545 2.248 1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5 1.636 1.636 0 00-1.431-1.62zM11.709 17.124a.252.252 0 01-.211-.111 4.208 4.208 0 00-3.526-1.888A4.263 4.263 0 003.933 18.1a.251.251 0 01-.165.164 3.254 3.254 0 00-2.3 3.109 1.5 1.5 0 001.5 1.5h11.5a1.5 1.5 0 001.5-1.5 4.255 4.255 0 00-4.259-4.249zm2.074 3.641a.25.25 0 01-.207.11H4a.226.226 0 01-.16-.386 1.244 1.244 0 01.883-.364h.007a.4.4 0 00.137-.01 1 1 0 00.877-.915 2.235 2.235 0 014.319-.634 1.012 1.012 0 001.16.613 2.312 2.312 0 01.5-.055 2.252 2.252 0 012.085 1.406.253.253 0 01-.025.235z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});