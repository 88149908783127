define("ember-svg-jar/inlined/waiting-room-couple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>waiting-room-couple</title><circle cx=\"12.5\" cy=\"7.75\" r=\"2.5\"/><path d=\"M13 11.25a1.5 1.5 0 00-1.5 1.5V17a.25.25 0 01-.25.25H8.529a3.505 3.505 0 00-2.848 1.466l-1.9 2.662a1.5 1.5 0 002.442 1.744l1.326-1.856a.25.25 0 01.451.145v.839a1.5 1.5 0 003 0v-2h1a2.5 2.5 0 002.5-2.5v-5a1.5 1.5 0 00-1.5-1.5z\"/><circle cx=\"18.5\" cy=\"7.75\" r=\"2.5\"/><path d=\"M21.5 17.25h-1.75a.25.25 0 01-.25-.25v-4.25a1.5 1.5 0 00-3 0v5a2.5 2.5 0 002.5 2.5h1.75a.25.25 0 01.25.25v1.75a1.5 1.5 0 003 0v-2.5a2.5 2.5 0 00-2.5-2.5zM0 5A4.75 4.75 0 104.75.25 4.756 4.756 0 000 5zm7.5 0a2.75 2.75 0 11-2.75-2.75A2.752 2.752 0 017.5 5z\"/><path d=\"M4 4v1.518a.75.75 0 00.75.75h1.018a.75.75 0 000-1.5H5.75a.25.25 0 01-.25-.25V4A.75.75 0 004 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});