define("ember-svg-jar/inlined/single-neutral-actions-share-2.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-share-2</title><path d=\"M12.785 14.112a.5.5 0 00.28-.8A7.507 7.507 0 007.25 10.5a7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h8.99a.5.5 0 00.44-.262 4.251 4.251 0 012.811-2.126z\"/><circle cx=\"7.044\" cy=\"4.75\" r=\"4.75\"/><path d=\"M10.956 18.25a2.742 2.742 0 004.638 1.989.251.251 0 01.265-.051l2.449.98a.25.25 0 01.157.224 2.443 2.443 0 00.183.867 2.761 2.761 0 10.671-3 .253.253 0 01-.266.051l-2.444-.978a.249.249 0 01-.157-.219c0-.041-.008-.1-.019-.178a.217.217 0 01.1-.219L19.393 16a.251.251 0 01.271.009 2.708 2.708 0 001.542.487 2.75 2.75 0 10-2.75-2.75 2.214 2.214 0 00.022.295.249.249 0 01-.12.247L15.52 16a.251.251 0 01-.271-.009 2.709 2.709 0 00-1.543-.487 2.753 2.753 0 00-2.75 2.746z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});