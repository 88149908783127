define("ember-svg-jar/inlined/puma", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>puma</title><path d=\"M21.578 3.3a1 1 0 00-1.415 1.414c1.43 1.43 2.129 2.586 1.727 4.6a1.579 1.579 0 01-1.506 1.194 43.238 43.238 0 00-8.66-5.686 13.9 13.9 0 00-4.947-1.539l-.861-1.291a.5.5 0 00-.367-.22.52.52 0 00-.4.144L3.786 3.277A2.645 2.645 0 001.52 5.9l-1.178.4A.5.5 0 000 6.77c0 .468.062 2.84 1.277 3.447a4.1 4.1 0 001.888.251.506.506 0 01.05-.182C3.426 9.864 4.3 8.52 6.732 8.918a.5.5 0 01-.162.982 2.264 2.264 0 00-2.291.57c-.221.382-.656 1.433-.726 1.572a.9.9 0 000 .854c.253.455.921.717 2.107.828a1.523 1.523 0 001.557-1.02l.086-.26a7.339 7.339 0 003.527.666 1.769 1.769 0 001.107-.844 2.307 2.307 0 00-.129-1.772.5.5 0 01.888-.459 3.305 3.305 0 01.163 2.617 5.041 5.041 0 011.987.883 4.152 4.152 0 001.3 2.584 5.509 5.509 0 002.9 1.6l.328 3.159a1.575 1.575 0 003.128-.091c.149-4.405-.063-7.2-.665-8.608a3.331 3.331 0 002.016-2.472c.603-3.025-.737-4.866-2.275-6.407zM4 7.019a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});