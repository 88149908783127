define("ember-svg-jar/inlined/font-size", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>font-size</title><path d=\"M17.567 20.509h-.608a.244.244 0 01-.231-.166L10.643 2.522a1.465 1.465 0 00-1.382-.992h-.007a1.469 1.469 0 00-1.389.994L1.8 20.343a.246.246 0 01-.232.166H.98a.98.98 0 100 1.959h2.947a.979.979 0 00.98-.98.949.949 0 00-.811-.953.241.241 0 01-.165-.118.244.244 0 01-.02-.2l1.5-4.413a.246.246 0 01.232-.166h7.23a.246.246 0 01.232.166l1.507 4.415a.246.246 0 01-.181.319.953.953 0 00-.793.951.979.979 0 00.98.98h2.947a.98.98 0 100-1.959zM6.479 13.678a.245.245 0 01-.232-.324L9.023 5.2a.245.245 0 01.464 0l2.784 8.153a.248.248 0 01-.033.222.245.245 0 01-.2.1z\"/><path d=\"M23.02 20.509h-.22a.246.246 0 01-.232-.166L18.312 7.886a1.53 1.53 0 00-2.9 0l-1.149 3.384a.979.979 0 101.854.632l.516-1.513a.245.245 0 01.463 0l1.466 4.3a.244.244 0 01-.231.324H16.97a.98.98 0 000 1.959h2.2a.246.246 0 01.232.166l1.1 3.212a.244.244 0 01-.117.3.954.954 0 00-.52.848.979.979 0 00.979.98h2.176a.98.98 0 100-1.959z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});