define("ember-svg-jar/inlined/real-estate-sign-board-sold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-sign-board-sold</title><path d=\"M18.745 13.487a.25.25 0 00-.406.2v3.354a.251.251 0 00.406.2 1.662 1.662 0 00.63-1.3v-1.153a1.659 1.659 0 00-.63-1.301zM9.714 13.128a.519.519 0 00-.518.517v3.429a.518.518 0 001.036 0v-3.429a.518.518 0 00-.518-.517z\"/><path d=\"M20.5 8.859h-.881a.5.5 0 01-.361-.154l-5.689-5.926a2.247 2.247 0 00-3.134 0L4.742 8.705a.5.5 0 01-.361.154H3.5a2.5 2.5 0 00-2.5 2.5v8a2.5 2.5 0 002.5 2.5h17a2.5 2.5 0 002.5-2.5v-8a2.5 2.5 0 00-2.5-2.5zm-14.982 6a2.693 2.693 0 011.393 2.215 1.77 1.77 0 01-1.768 1.768H4a.625.625 0 010-1.25h1.143a.519.519 0 00.518-.518c0-.52-.323-.787-.893-1.215a2.69 2.69 0 01-1.393-2.214 1.77 1.77 0 011.768-1.767h1.143a.625.625 0 110 1.25H5.143a.518.518 0 00-.518.517c0 .52.323.786.893 1.214zm5.964 2.215a1.768 1.768 0 01-3.536 0v-3.429a1.768 1.768 0 013.536 0zm3.947 1.768h-1.143a1.77 1.77 0 01-1.768-1.768V12.5a.625.625 0 011.25 0v4.571a.519.519 0 00.518.518h1.143a.625.625 0 010 1.25zM7.953 8.859a.25.25 0 01-.181-.423l4.106-4.276a.177.177 0 01.246 0l4.1 4.274a.25.25 0 01-.181.423zm12.672 7.072a2.914 2.914 0 01-2.911 2.911.626.626 0 01-.625-.625V12.5a.625.625 0 01.625-.625 2.914 2.914 0 012.911 2.91z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});