define("ember-svg-jar/inlined/computer-chip-32", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>computer-chip-32</title><path d=\"M2.435 11H1a1 1 0 000 2h1.435a1 1 0 100-2zM2.435 16H1a1 1 0 000 2h1.435a1 1 0 000-2zM2.435 6.5H1a1 1 0 100 2h1.435a1 1 0 100-2zM23 11h-1.435a1 1 0 000 2H23a1 1 0 000-2zM21.565 8.5H23a1 1 0 000-2h-1.435a1 1 0 000 2zM24 17a1 1 0 00-1-1h-1.435a1 1 0 000 2H23a1 1 0 001-1zM12 3.435a1 1 0 001-1V1a1 1 0 00-2 0v1.435a1 1 0 001 1zM7.5 3.435a1 1 0 001-1V1a1 1 0 10-2 0v1.435a1 1 0 001 1zM16.5 3.435a1 1 0 001-1V1a1 1 0 00-2 0v1.435a1 1 0 001 1zM12 20.566a1 1 0 00-1 1V23a1 1 0 102 0v-1.434a1 1 0 00-1-1zM16.5 20.566a1 1 0 00-1 1V23a1 1 0 102 0v-1.434a1 1 0 00-1-1zM7.5 20.566a1 1 0 00-1 1V23a1 1 0 002 0v-1.434a1 1 0 00-1-1zM18.066 4.5H5.934A1.436 1.436 0 004.5 5.934v12.133A1.437 1.437 0 005.934 19.5h12.132a1.437 1.437 0 001.434-1.433V5.934A1.436 1.436 0 0018.066 4.5zm-8.691 8.25h-.937a.75.75 0 010-1.5h.937a.5.5 0 000-1H7.5a.75.75 0 110-1.5h1.875a2 2 0 011.662 3.111.251.251 0 000 .278 2 2 0 01-1.662 3.111H7.5a.75.75 0 110-1.5h1.875a.5.5 0 000-1zm7.117 1a.75.75 0 010 1.5h-3.125a.751.751 0 01-.5-1.313l2.188-1.93c.66-.583.751-.7.667-1.108a.813.813 0 00-1.609.163.75.75 0 01-1.5 0A2.312 2.312 0 0117.2 10.6a2.39 2.39 0 01-1.087 2.482l-.262.231a.251.251 0 00.166.438z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});