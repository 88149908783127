define("ember-svg-jar/inlined/harddrive-upload-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>harddrive-upload-2</title><path d=\"M19 14H5a5 5 0 000 10h14a5 5 0 000-10zm0 6.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM4.4 19a.75.75 0 01.75-.75H9a.75.75 0 010 1.5H5.154A.75.75 0 014.4 19zM12 11.5a1.5 1.5 0 001.5-1.5V5.75a.25.25 0 01.25-.25h1.75a1 1 0 00.707-1.707l-3.5-3.5a1 1 0 00-1.414 0l-3.5 3.5A1 1 0 008.5 5.5h1.75a.25.25 0 01.25.25V10a1.5 1.5 0 001.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});