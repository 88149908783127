define("ember-svg-jar/inlined/skiing-cross-country-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skiing-cross-country-1</title><circle cx=\"15.167\" cy=\"2.5\" r=\"2.5\"/><path d=\"M9.193 21.676A3.048 3.048 0 017.667 22c-.833 0-2.125-.732-3.567-2h.317a1 1 0 100-2H2.834a1.5 1.5 0 00-1.06 2.561C3.354 22.13 5.658 24 7.667 24a5.006 5.006 0 002.524-.592 1 1 0 00-1-1.732zM18.667 20a1 1 0 00-1 1c0 .589-.822 1-2 1a5.449 5.449 0 01-2.816-1.379 1 1 0 00-1.229 1.579 7.037 7.037 0 004.045 1.8c2.748 0 4-1.555 4-3a1 1 0 00-1-1z\"/><path d=\"M21.379 11.765L19.6 11.51a2.507 2.507 0 01-1.7-1.041l-1.561-2.232a5.469 5.469 0 00-3.522-2.257c-5.1-.925-6.556 2.226-6.833 2.783l-.659 1.316a1.5 1.5 0 002.684 1.342l.658-1.321a2.512 2.512 0 012.684-1.341l-4.519 9.646a1.5 1.5 0 102.729 1.248l1.993-4.124a.252.252 0 01.146-.129.247.247 0 01.192.013l1.144.571a2.137 2.137 0 011.382 1.986V18h-1.25a1 1 0 000 2s1.314-.012 1.338-.014a1.5 1.5 0 002.912-.486v-1.528a5.109 5.109 0 00-3.04-4.669l-1.248-.624a.25.25 0 01-.116-.328l.824-1.8a.25.25 0 01.432-.039l1.175 1.677a5.513 5.513 0 003.728 2.292l1.783.255a1.5 1.5 0 10.424-2.97z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});