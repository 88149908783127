define("ember-svg-jar/inlined/shop-cashier-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shop-cashier-woman</title><path d=\"M6.359 10.442a1 1 0 001.382-.3 5.6 5.6 0 00.459-.869.249.249 0 01.407-.08 4.8 4.8 0 006.788 0 .249.249 0 01.407.08 5.6 5.6 0 00.458.872 1 1 0 001.678-1.085 4.576 4.576 0 01-.638-2.512V5.3a5.3 5.3 0 10-10.6 0v1.248a4.576 4.576 0 01-.638 2.512 1 1 0 00.297 1.382zM12 8.6a2.787 2.787 0 01-2.788-2.662v-.012a.157.157 0 01.112-.159 5.7 5.7 0 002.5-1.531.254.254 0 01.176-.077.249.249 0 01.181.078 5.726 5.726 0 002.5 1.531.156.156 0 01.111.159v.011A2.787 2.787 0 0112 8.6z\"/><path d=\"M5.489 17h13.022a.75.75 0 00.7-1.02 7.713 7.713 0 00-14.422 0 .75.75 0 00.7 1.02zM23 18H1a1 1 0 000 2h.25a.25.25 0 01.25.25V23a1 1 0 001 1h19a1 1 0 001-1v-2.75a.25.25 0 01.25-.25H23a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});