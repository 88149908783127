define("ember-svg-jar/inlined/board-game-ludo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>board-game-ludo</title><path d=\"M6.854 4.051a2.475 2.475 0 102.1 2.8 2.473 2.473 0 00-2.1-2.8zM17.5 15a2.5 2.5 0 102.5 2.5 2.5 2.5 0 00-2.5-2.5z\"/><path d=\"M22 0H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zm-4.5 4A2.5 2.5 0 1115 6.5 2.5 2.5 0 0117.5 4zm-11 16A2.5 2.5 0 119 17.5 2.5 2.5 0 016.5 20zm5-9a.5.5 0 01-.5.5H2.5A.5.5 0 012 11V2.5a.5.5 0 01.5-.5H11a.5.5 0 01.5.5zM22 21.5a.5.5 0 01-.5.5H13a.5.5 0 01-.5-.5V13a.5.5 0 01.5-.5h8.5a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});