define("ember-svg-jar/inlined/single-man-actions-shield.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-shield</title><path d=\"M7.044 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.743a.5.5 0 01.741-.3A6.958 6.958 0 008.5 5a6.35 6.35 0 00.929-.07.5.5 0 01.571.564A3 3 0 014.044 5a2.8 2.8 0 01.1-.743zM9.456 13.456a3.009 3.009 0 01.553-1.738.5.5 0 00-.237-.757A7.265 7.265 0 007.25 10.5a7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h8.412a.5.5 0 00.5-.5zM23.956 13.456a1.546 1.546 0 00-.927-1.416A16.4 16.4 0 0017.434 11a15.98 15.98 0 00-5.549 1.04 1.546 1.546 0 00-.929 1.416v3.393a7.718 7.718 0 005.19 6.805l.553.211a2.071 2.071 0 001.471 0l.553-.211c3.081-1.174 5.233-3.972 5.233-6.8zm-5.75 1.794a.5.5 0 00.5.5h1.75a.75.75 0 110 1.5h-1.75a.5.5 0 00-.5.5v1.75a.75.75 0 01-1.5 0v-1.75a.5.5 0 00-.5-.5h-1.75a.75.75 0 010-1.5h1.75a.5.5 0 00.5-.5V13.5a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});