define("ember-svg-jar/inlined/zip-file-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>zip-file-settings</title><path d=\"M10.977 16.776a3.4 3.4 0 01-.718-3.606 3.218 3.218 0 013.762-1.93c.389.082.5.015.595-.352a3.151 3.151 0 013.1-2.345A.248.248 0 0018 8.3V5.208a1.5 1.5 0 00-.439-1.062L13.853.439A1.5 1.5 0 0012.793 0H1.5A1.5 1.5 0 000 1.5v19A1.5 1.5 0 001.5 22h8.974a.246.246 0 00.2-.4 3 3 0 01-.617-1.957 3.835 3.835 0 011.057-2.5c.136-.149-.137-.367-.137-.367zM6 18.5a1 1 0 111-1 1 1 0 01-1 1zM6 15a1 1 0 111-1 1 1 0 01-.995 1zm0-3.5a1 1 0 111-1 1 1 0 01-.995 1zm1.5-4.9a1.968 1.968 0 01-1.479.67 1.975 1.975 0 01-2.005-2.221l.49-3.127A.5.5 0 015 1.5h2a.5.5 0 01.494.423l.485 3.111v.015A1.985 1.985 0 017.508 6.6z\"/><circle cx=\"17.748\" cy=\"17.031\" r=\"1.5\"/><path d=\"M22.528 17.344a.464.464 0 010-.626l.988-1.064a1.783 1.783 0 00-1.7-2.953l-1.41.327a.45.45 0 01-.534-.311l-.424-1.392a1.774 1.774 0 00-3.4 0l-.424 1.391a.456.456 0 01-.533.312l-1.41-.328a1.783 1.783 0 00-1.7 2.953l.988 1.064a.464.464 0 010 .626l-.988 1.065a1.783 1.783 0 001.7 2.953l1.41-.327a.451.451 0 01.534.311l.424 1.392a1.775 1.775 0 003.4 0l.424-1.392a.451.451 0 01.533-.311l1.411.327a1.783 1.783 0 001.7-2.953zm-4.78 2.687a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});