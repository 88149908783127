define("ember-svg-jar/inlined/single-woman-actions-player.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-player</title><path d=\"M10.5 12.6a.5.5 0 00-.3-.457 7.383 7.383 0 00-3-.647 7.542 7.542 0 00-7.178 5.361.5.5 0 00.478.643H10a.5.5 0 00.5-.5zM22.124 12h-8.249A1.877 1.877 0 0012 13.875v8.25A1.877 1.877 0 0013.875 24h8.249A1.877 1.877 0 0024 22.125v-8.25A1.877 1.877 0 0022.124 12zm-1.731 6a.936.936 0 01-.451.8l-3.034 1.819a.929.929 0 01-1.408-.8V16.18a.93.93 0 011.408-.8l3.034 1.82a.934.934 0 01.451.8zM1.568 10.442a1 1 0 001.383-.3 5.162 5.162 0 00.454-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .251.251 0 01.228-.071.255.255 0 01.184.153 5.093 5.093 0 00.454.9 1 1 0 001.68-1.085 4.569 4.569 0 01-.642-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248A4.564 4.564 0 011.27 9.06a1 1 0 00.298 1.382zM7.21 8.6a2.7 2.7 0 01-2.741-2.352.5.5 0 01.324-.577 5.7 5.7 0 002.245-1.439.25.25 0 01.361 0 5.712 5.712 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.21 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});