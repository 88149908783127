define("ember-svg-jar/inlined/trekking-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trekking-person</title><circle cx=\"12.089\" cy=\"3.124\" r=\"2.5\"/><path d=\"M21.581 7.405a.994.994 0 00-1.212.727l-3.5 14a1 1 0 001.939.485l3.5-14a1 1 0 00-.727-1.212zM6.8 8.792a.25.25 0 00-.181-.3l-3.892-.976a.249.249 0 00-.3.182l-.717 2.853a1.663 1.663 0 00.2 1.273c.527.858 2.4 1.051 2.586 1.051a1.65 1.65 0 001.6-1.258zM6.864 7.518a.251.251 0 00.3-.182l.153-.612A1.662 1.662 0 006.073 4.7l-1.168-.259a1.67 1.67 0 00-1.972 1.217l-.146.584a.252.252 0 00.182.3zM16.724 13.624a1.5 1.5 0 000-3 5.011 5.011 0 01-4.658-3.17 1.5 1.5 0 00-2.794.294l-3.5 13.5a1.5 1.5 0 102.9.752l1.152-4.444a8.034 8.034 0 011.907 4.254 1.5 1.5 0 102.977-.371 11.042 11.042 0 00-4.026-7.193l.692-2.67a7.939 7.939 0 005.35 2.048z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});