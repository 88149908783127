define("ember-svg-jar/inlined/waiting-room-read", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>waiting-room-read</title><path d=\"M11 5.656a5.5 5.5 0 10-5.5 5.5 5.507 5.507 0 005.5-5.5zm-5.5 3.5a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5z\"/><path d=\"M6.25 5.345V4.339a.75.75 0 10-1.5 0v1.317a.747.747 0 00.22.53l.75.75a.75.75 0 101.06-1.06zM15.916 14.345a.255.255 0 00.168 0l3.451-1.233a.25.25 0 00.091-.414 4.45 4.45 0 00-3.126-1.292h-1a4.449 4.449 0 00-3.127 1.294.25.25 0 00.092.414z\"/><path d=\"M21.5 20.406a1.5 1.5 0 01-1.5-1.5v-2a1.5 1.5 0 011.5-1.5h1c.077 0 .152 0 .227.01a.249.249 0 00.273-.249v-1.552a.5.5 0 00-.664-.471c-.037.027-6.168 2.233-6.168 2.233a.5.5 0 01-.336 0l-6.164-2.233a.5.5 0 00-.668.471v1.552a.255.255 0 00.081.185.251.251 0 00.192.064c.075-.007.15-.01.227-.01h1a1.5 1.5 0 011.5 1.5v2a1.5 1.5 0 01-1.5 1.5h-1c-.077 0-.152 0-.227-.01a.249.249 0 00-.273.249v.409a.5.5 0 00.332.47l6.332 2.262a1.006 1.006 0 00.672 0l6.332-2.262a.5.5 0 00.332-.47v-.409a.255.255 0 00-.081-.185.251.251 0 00-.192-.064 2.83 2.83 0 01-.227.01z\"/><circle cx=\"16\" cy=\"7.406\" r=\"2.5\"/><path d=\"M10.5 19.406a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-1a1.5 1.5 0 000 3zM22.5 16.406h-1a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h1a1.5 1.5 0 000-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});