define("ember-svg-jar/inlined/professions-man-clown.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-clown</title><path d=\"M22.533 21.417a12.073 12.073 0 00-2.642-1.5.25.25 0 00-.2 0 .246.246 0 00-.134.147 3.226 3.226 0 01-4.077 2.009.251.251 0 00-.235.041 5.231 5.231 0 01-6.505-.01.222.222 0 00-.208-.036 3.225 3.225 0 01-4.091-2 .251.251 0 00-.334-.149 12.122 12.122 0 00-2.642 1.5A2.478 2.478 0 00.5 23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5 2.475 2.475 0 00-.967-2.083z\"/><path d=\"M7.5 20.75a1.719 1.719 0 00.894-.256.749.749 0 01.923.119 3.819 3.819 0 005.365 0 .75.75 0 01.923-.12 1.717 1.717 0 002.532-.916.25.25 0 00-.169-.322A22.11 22.11 0 0012 18.5a22.123 22.123 0 00-5.969.755.25.25 0 00-.154.125.253.253 0 00-.015.2A1.732 1.732 0 007.5 20.75z\"/><path d=\"M17.484 14.934a.25.25 0 01.148-.135 3.487 3.487 0 002.076-4.7.251.251 0 01.027-.248 3.863 3.863 0 00.741-1.923.25.25 0 00-.448-.176 2.3 2.3 0 01-1.778.867 2.24 2.24 0 01-.752-.13l-8.456-2.98A2.243 2.243 0 017.571 3.7a.25.25 0 00-.327-.2c-1.287.435-6.065 2.281-2.983 6.35a.252.252 0 01.03.252 3.48 3.48 0 002.077 4.7.247.247 0 01.148.135 6 6 0 0010.968 0zM12 16.5a4 4 0 01-4-4v-1.759a.251.251 0 01.231-.25 2.991 2.991 0 001.546-.572.252.252 0 01.286-.008 3.633 3.633 0 003.874 0 .252.252 0 01.286.008 2.991 2.991 0 001.546.572.251.251 0 01.231.249v1.76a4 4 0 01-4 4z\"/><circle cx=\"14\" cy=\"11.5\" r=\".75\"/><circle cx=\"10\" cy=\"11.5\" r=\".75\"/><path d=\"M9.083 3.138a.749.749 0 00.458.956L18 7.077a.75.75 0 10.5-1.414l-1.456-.514a.25.25 0 01-.153-.319l.861-2.441a.9.9 0 00-.552-1.144L13.82.051A.9.9 0 0012.675.6l-.86 2.441a.25.25 0 01-.319.153L10.04 2.68a.749.749 0 00-.957.458z\"/><circle cx=\"12\" cy=\"14\" r=\"1.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});