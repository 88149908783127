define("ember-svg-jar/inlined/allowances-no-climbing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>allowances-no-climbing</title><circle cx=\"10.399\" cy=\"5.521\" r=\"2.204\"/><path d=\"M10.213 14.191a.244.244 0 00-.295-.039 3.157 3.157 0 00-1.266 4.177l.05.1a1.225 1.225 0 002.187-1.1l-.048-.1a.736.736 0 01.367-1l.395-.169a.243.243 0 00.144-.179.24.24 0 00-.067-.219zM9.355 13.77a.245.245 0 00.051-.386l-1.5-1.5a.242.242 0 00-.264-.054l-1.245.5a1.224 1.224 0 10.91 2.273l1.831-.733a1.485 1.485 0 00.217-.1z\"/><path d=\"M12 0a12 12 0 1012 12A12.043 12.043 0 0012 0zm5.861 20.136a9.575 9.575 0 01-1.037.666A10.035 10.035 0 013.2 7.176a9.575 9.575 0 01.666-1.037.245.245 0 01.371-.029L17.89 19.765a.245.245 0 01-.029.371zm1.839-1.711a.248.248 0 01-.177.087.245.245 0 01-.185-.071l-.82-.821a.136.136 0 01.1-.232 1.225 1.225 0 100-2.449H16.89a1.73 1.73 0 01-1.212-.5l-1.462-1.463a.246.246 0 01-.061-.1l-.755-2.418.136-.045a.559.559 0 01.181-.03.587.587 0 01.182.029l.976.325a1.731 1.731 0 011.085 1.085l.206.708a1.22 1.22 0 001.158.838 1.245 1.245 0 00.391-.064 1.224 1.224 0 00.774-1.549l-.207-.708a4.181 4.181 0 00-2.633-2.632l-.977-.327a3.048 3.048 0 00-1.91 0c-2.17.723-2.557.849-2.675.894a.245.245 0 01-.261-.055L5.559 4.664a.248.248 0 01-.071-.184.242.242 0 01.087-.18A10.025 10.025 0 0119.7 18.425z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});