define("ember-svg-jar/inlined/italic-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>italic-off</title><path d=\"M23.707 23.705a1 1 0 000-1.414l-22-22A1 1 0 00.293 1.705l22 22a1 1 0 001.414 0zM6.5 2l6.21-.023a.251.251 0 01.2.1.248.248 0 01.038.222l-1.34 4.408a1 1 0 001.914.582l1.567-5.147a.251.251 0 01.238-.177l5.956-.022a.25.25 0 01.238.329l-.421 1.28a1 1 0 101.9.631l.535-1.608A1.955 1.955 0 0021.673 0H6.5a1 1 0 000 2zM11.044 22H9.383a.25.25 0 01-.239-.323l1.639-5.385a1 1 0 10-1.914-.582l-1.861 6.111a.25.25 0 01-.239.179H4.348a1 1 0 100 2h6.7a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});