define("ember-svg-jar/inlined/sign-badge-badge-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sign-badge-badge-alternate</title><path d=\"M22.588 3.566A2.117 2.117 0 0019.473 1.7l-1.6.854a.5.5 0 01-.467 0L12.981.241a2.119 2.119 0 00-1.962 0L6.593 2.554a.5.5 0 01-.467 0L4.527 1.7a2.117 2.117 0 00-3.115 1.866v7.963C1.412 18.36 10.409 24 12 24s10.588-5.64 10.588-12.471zm-2.444 7.968c0 4.763-6.148 8.979-7.921 9.862a.5.5 0 01-.446 0c-1.772-.883-7.921-5.1-7.921-9.863V4.881a.5.5 0 01.735-.441l.843.45a2.087 2.087 0 001.956.01l4.378-2.288a.5.5 0 01.464 0L16.608 4.9a2.089 2.089 0 001.957-.008l.844-.45a.5.5 0 01.735.441z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});