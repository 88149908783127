define("ember-svg-jar/inlined/tea-cup-herbal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tea-cup-herbal</title><path d=\"M11.532 17.087a2.307 2.307 0 011.688-2.278 2.34 2.34 0 01.972-.028.279.279 0 00.332-.275 2.892 2.892 0 01.759-2 2 2 0 011.466-.753.5.5 0 00.48-.384 8.177 8.177 0 00.226-1.884V5.267a.5.5 0 01.5-.5h.926A3.085 3.085 0 0121.9 6.961a2.964 2.964 0 01-.29 2.275.5.5 0 00.019.529 2.332 2.332 0 01.15.255.5.5 0 00.361.269 2.2 2.2 0 01.543.168.5.5 0 00.636-.2 4.93 4.93 0 00.522-3.76 5.126 5.126 0 00-5.025-3.721h-.86a.5.5 0 01-.5-.5v-.5a1 1 0 00-1-1H8.789a.5.5 0 00-.5.5v2.246a.5.5 0 00.5.5h.748a.748.748 0 01.748.749v3.987a.748.748 0 01-.748.748h-3.99a.748.748 0 01-.747-.748V4.769a.748.748 0 01.748-.749H6.3a.5.5 0 00.5-.5V1.277a.5.5 0 00-.5-.5H2.5a1 1 0 00-1 1v7.98a7.975 7.975 0 004.171 7.012.25.25 0 01-.119.469H1a1 1 0 000 1.994h10.033a.5.5 0 00.5-.5z\"/><path d=\"M24 14.494a.748.748 0 00-.748-.748h-1.083a.25.25 0 01-.177-.426l.293-.292a.748.748 0 00-1.058-1.058l-.292.292a.25.25 0 01-.426-.176V11a.748.748 0 10-1.5 0v2.974a.5.5 0 01-.146.353l-.925.924a.249.249 0 01-.425-.176V14a.749.749 0 00-1.5 0v2.97a.5.5 0 01-.146.352l-.924.925a.25.25 0 01-.426-.177v-1.083a.748.748 0 10-1.5 0v2.975a.5.5 0 01-.146.353l-1.63 1.629A.748.748 0 0012.31 23l1.63-1.63a.5.5 0 01.353-.146h2.974a.748.748 0 000-1.5h-1.083a.25.25 0 01-.176-.426l.924-.924a.5.5 0 01.353-.146h2.975a.748.748 0 100-1.5h-1.084a.25.25 0 01-.176-.416l.925-.924a.5.5 0 01.352-.146h2.975a.748.748 0 00.748-.748z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});