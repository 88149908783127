define("ember-svg-jar/inlined/smart-house-phone-cctv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-house-phone-cctv</title><path d=\"M22.026 11.661h-5.052A1.493 1.493 0 0015.5 13.17v8.983a1.493 1.493 0 001.474 1.508h5.052a1.493 1.493 0 001.474-1.508V13.17a1.493 1.493 0 00-1.474-1.509zm-.526 8.5a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-5.982a.5.5 0 01.5-.5h3a.5.5 0 01.5.5zM12.453 5.757l-2.591.8a.1.1 0 01-.109-.057l-.286-.922a.25.25 0 01.109-.288A2.671 2.671 0 007.4.459L1.083 2.418a.783.783 0 00-.4 1.263l2.3 2.969a1.19 1.19 0 001.234.388l3.053-.945a.25.25 0 01.313.164l.26.84a2.089 2.089 0 002.61 1.374l2.592-.8a1 1 0 10-.592-1.91zM18.5 5.338v1.25a.25.25 0 01-.25.25H17a.5.5 0 00-.353.854l2.5 2.5a.5.5 0 00.707 0l2.5-2.5A.5.5 0 0022 6.838h-1.25a.25.25 0 01-.25-.25v-1.25a4 4 0 00-4-4H14a1 1 0 000 2h2.5a2 2 0 012 2zM12.5 17.338h-4a2 2 0 01-2-2v-1.25a.25.25 0 01.25-.25H8a.5.5 0 00.354-.853l-2.5-2.5a.5.5 0 00-.707 0l-2.5 2.5a.5.5 0 00.353.853h1.25a.25.25 0 01.25.25v1.25a4 4 0 004 4h4a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});