define("ember-svg-jar/inlined/single-woman-actions-edit-2.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-edit-2</title><path d=\"M13.5 15.63a.5.5 0 00.068-.622A7.528 7.528 0 007.25 11.5a7.542 7.542 0 00-7.185 5.358.5.5 0 00.479.642h10.878a.5.5 0 00.353-.146zM23.184 11.765a2.618 2.618 0 00-3.7 0l-6.5 6.5a.5.5 0 000 .707l3 3a.5.5 0 00.707 0l6.5-6.5a2.621 2.621 0 00-.007-3.707zM12.349 20.461a.5.5 0 00-.844.256l-.537 2.683a.5.5 0 00.49.6c.066 0-.16.043 2.783-.547a.5.5 0 00.256-.843zM1.569 10.442a1 1 0 001.383-.3 5.233 5.233 0 00.454-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .249.249 0 01.411.082 5.233 5.233 0 00.454.9A1 1 0 0013.15 9.06a4.578 4.578 0 01-.643-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248a4.573 4.573 0 01-.642 2.512 1 1 0 00.298 1.382zM7.211 8.6A2.7 2.7 0 014.47 6.248a.5.5 0 01.324-.577 5.711 5.711 0 002.245-1.439.25.25 0 01.361 0 5.7 5.7 0 002.23 1.437.5.5 0 01.323.576A2.708 2.708 0 017.211 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});