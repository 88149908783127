define("ember-svg-jar/inlined/wild-bird-footstep", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wild-bird-footstep</title><path d=\"M18.537 10.749a1 1 0 00-1 1v4.5a1 1 0 102 0v-4.5a1 1 0 00-1-1zM14.988 14.133a1 1 0 10-1.576 1.231l2.337 2.993a1 1 0 101.576-1.231zM23.49 13.96a1 1 0 00-1.4.173l-2.338 2.993a1 1 0 101.576 1.231l2.338-2.993a1 1 0 00-.176-1.404zM18.537 18.749a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1zM12.475 10.262c.589 1.117.951 1.877.956 1.888a1 1 0 101.807-.858c-.032-.067-2.088-3.919-2.175-3.994a3.687 3.687 0 01-3.115 1.908A3.5 3.5 0 016.61 6.789a.75.75 0 011.4-.534 2.1 2.1 0 00.753 1.082 2.071 2.071 0 002.97-.743l.3-.5C9.579 2.213 7.51.251 5.875.251a8.642 8.642 0 00-5.531 2.28A.75.75 0 001.4 3.593a5.284 5.284 0 01.6-.485c.235 2.049.815 5.907 1.967 7.443a5.045 5.045 0 00.613.677l-.641 1.661a1 1 0 001.865.719l.514-1.308a5.225 5.225 0 001.426.322l-.3.772a1 1 0 001.865.719l.714-1.851a6.059 6.059 0 002.452-2zm-6.846-6.2a1 1 0 111-1 1 1 0 01-1 .999z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});