define("ember-svg-jar/inlined/programming-user-chat-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-user-chat-woman</title><path d=\"M24 1.75A1.752 1.752 0 0022.25 0h-11A1.752 1.752 0 009.5 1.75v8a1.752 1.752 0 001.75 1.75h1a.25.25 0 01.25.25v2a.749.749 0 00.75.75.742.742 0 00.488-.181l3.219-2.759a.249.249 0 01.163-.06h5.13A1.752 1.752 0 0024 9.75zm-8.22 2.97a.749.749 0 010 1.06l-2 2a.75.75 0 01-1.06-1.06l1.293-1.293a.252.252 0 000-.354L12.72 3.78a.75.75 0 111.06-1.06zm5.72 2.53a.75.75 0 01-.75.75h-3a.75.75 0 010-1.5h3a.75.75 0 01.75.75zM.036 23.314A.5.5 0 00.5 24h11.188a.5.5 0 00.464-.686 4.781 4.781 0 00-.108-.248c-.4-.846-2.068-1.433-4.163-2.254a.6.6 0 01-.37-.563v-.3a.25.25 0 01.248-.25 4.318 4.318 0 002.667-.69.306.306 0 00.131-.214.316.316 0 00-.071-.243c-.357-.415-.6-.915-.709-2.559C9.588 13.038 7.709 12 6.094 12S2.6 13.038 2.411 15.989c-.1 1.644-.352 2.144-.709 2.559a.316.316 0 00-.071.243.3.3 0 00.132.214 4.311 4.311 0 002.662.7.251.251 0 01.178.073.247.247 0 01.074.177v.3a.6.6 0 01-.37.563c-2.1.821-3.764 1.408-4.163 2.254-.039.077-.075.16-.108.242z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});