define("ember-svg-jar/inlined/single-woman-actions-check-2.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-check-2</title><path d=\"M23.049 18.19a1 1 0 00-1.253.656A4.5 4.5 0 1117.5 13a1 1 0 000-2 6.5 6.5 0 106.2 8.443 1 1 0 00-.651-1.253z\"/><path d=\"M16.145 16.105a1 1 0 10-1.414 1.414l1.576 1.581a1.691 1.691 0 002.387 0l5.013-5.014a1.13 1.13 0 00.074-1.425 1.14 1.14 0 00-1.488.01l-4.616 4.617a.25.25 0 01-.353 0zM10.654 12.375a7.361 7.361 0 00-3.447-.875 7.543 7.543 0 00-7.185 5.357.5.5 0 00.478.643h8.515a.5.5 0 00.5-.47 7.929 7.929 0 011.321-3.93.5.5 0 00-.182-.72zM1.567 10.442a1 1 0 001.383-.3 5.233 5.233 0 00.454-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .249.249 0 01.411.082 5.233 5.233 0 00.454.9 1 1 0 001.681-1.082 4.569 4.569 0 01-.642-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248a4.573 4.573 0 01-.643 2.512 1 1 0 00.298 1.382zM7.209 8.6a2.7 2.7 0 01-2.741-2.352.5.5 0 01.324-.577 5.711 5.711 0 002.245-1.439.25.25 0 01.361 0 5.7 5.7 0 002.23 1.437.5.5 0 01.323.576A2.708 2.708 0 017.209 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});