define("ember-svg-jar/inlined/plane-trip-international", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-trip-international</title><path d=\"M7.737 15.55a1 1 0 00-.774-1.184 6.146 6.146 0 01-2.546-1.2.251.251 0 01-.091-.245l.294-1.471a.25.25 0 01.245-.2h.1a1.5 1.5 0 001.46-1.865l-.5-2a1.5 1.5 0 00-1.456-1.136H2.694a.25.25 0 01-.232-.344 6.227 6.227 0 019.146-2.917.142.142 0 01-.077.261 1.5 1.5 0 00-1.455 1.136l-.5 2a1.5 1.5 0 001.455 1.864h.417a.251.251 0 01.247.209l.218 1.311a3.39 3.39 0 00.565 1.372.25.25 0 00.32.081l.03-.016a2.509 2.509 0 012.472.1.474.474 0 00.7-.24A8.248 8.248 0 100 8.249a8.28 8.28 0 006.553 8.074.969.969 0 00.206.022 1 1 0 00.978-.795zM23.842 14.39a1.78 1.78 0 00-2.281-.922l-3.3 1.315a.5.5 0 01-.451-.04l-3.058-1.916a1.008 1.008 0 00-.989-.041l-1.027.53a1 1 0 00-.095 1.72l1.473.98a.251.251 0 01-.046.441l-2.148.857a.5.5 0 01-.39-.008L9.5 16.4a1 1 0 00-.907.046l-1.073.617a1 1 0 00-.043 1.707l3.657 2.361a1 1 0 00.907.089l2.716-1.07a.25.25 0 01.325.323l-.837 2.173a1 1 0 001.395 1.247l.963-.5a1 1 0 00.419-.408l2.534-4.631a.5.5 0 01.237-.217l3.146-1.386a1.778 1.778 0 00.9-2.356z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});