define("ember-svg-jar/inlined/religion-woman-muslim.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-woman-muslim</title><path d=\"M21.182 17.784L19.8 17.25a.25.25 0 00-.337.273A2.961 2.961 0 0119.5 18c0 2.649-3.084 4.5-7.5 4.5S4.5 20.649 4.5 18a2.961 2.961 0 01.04-.477.25.25 0 00-.34-.273l-1.4.541a4.971 4.971 0 00-2.8 4.6V23.5a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-1.106a4.975 4.975 0 00-2.818-4.61z\"/><path d=\"M6.729 16.52A1.873 1.873 0 006 18c0 1.71 2.579 3 6 3s6-1.29 6-3a1.873 1.873 0 00-.729-1.48c1.5-1.679 2.229-4.373 2.229-8.206C19.5 4.389 16.293 0 12 0S4.5 4.389 4.5 8.314c0 3.832.73 6.527 2.229 8.206zm.771-5.245v-2.1C7.5 6.565 9.515 5.5 12 5.5s4.5 1.065 4.5 3.675v2.1A4.617 4.617 0 0112 16a4.617 4.617 0 01-4.5-4.725z\"/><path d=\"M12.051 13.75a3.252 3.252 0 001.525-.36.75.75 0 10-.715-1.318 1.758 1.758 0 01-.81.178 1.8 1.8 0 01-.819-.18.75.75 0 10-.711 1.321 3.332 3.332 0 001.528.359zM11.188 9.269a.753.753 0 00-.054-1.06 2.1 2.1 0 00-2.636 0 .751.751 0 101 1.116.625.625 0 01.626 0 .753.753 0 001.064-.056zM14.067 9.324a.625.625 0 01.626 0A.751.751 0 0015.7 8.209a2.1 2.1 0 00-2.636 0 .751.751 0 001 1.116z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});