define("ember-svg-jar/inlined/chef-gear-apron", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chef-gear-apron</title><path d=\"M24 17.414a4.919 4.919 0 00-1.293-3.121 4.657 4.657 0 00-2.777-1.258.5.5 0 01-.444-.473C19.231 7.352 19 2.7 19 2.5A2.5 2.5 0 0016.5 0H15a1 1 0 00-1 1v4a2 2 0 01-4 0V1a1 1 0 00-1-1H7.5A2.5 2.5 0 005 2.494c0 .2-.231 4.854-.486 10.07a.5.5 0 01-.442.473 4.7 4.7 0 00-2.779 1.256A4.919 4.919 0 000 17.414V18.5a1 1 0 002 0v-1.086a2.971 2.971 0 01.707-1.707 3.031 3.031 0 011.373-.669.249.249 0 01.213.055.256.256 0 01.087.2L4 23a1 1 0 001 1h14a1 1 0 00.707-.293 1.02 1.02 0 00.293-.733l-.379-7.679a.256.256 0 01.087-.2.249.249 0 01.213-.055 3.031 3.031 0 011.373.669A2.971 2.971 0 0122 17.414V18.5a1 1 0 002 0zM12 19.5A4.5 4.5 0 017.5 15a.5.5 0 01.5-.5h8a.5.5 0 01.5.5 4.5 4.5 0 01-4.5 4.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});