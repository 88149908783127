define("ember-svg-jar/inlined/fast-food-hot-dog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fast-food-hot-dog</title><path d=\"M23.63 7.718a.5.5 0 00-.809-.147l-15.25 15.25a.5.5 0 00.148.809 3.865 3.865 0 004.426-.807l10.677-10.677a3.867 3.867 0 00.808-4.428zM16.568.736a.5.5 0 00-.286-.366 3.866 3.866 0 00-4.427.807L1.177 11.855a3.866 3.866 0 00-.807 4.427.5.5 0 00.809.147l15.25-15.25a.5.5 0 00.139-.443zM21.254 2.746a3.021 3.021 0 00-4.271 0L2.747 16.982a3.02 3.02 0 104.27 4.272L21.254 7.017a3.023 3.023 0 000-4.271zm-2.285 2.686a1.91 1.91 0 00-1.424 1.424l-.315 1.368a3.422 3.422 0 01-2.549 2.549l-1.369.316a1.911 1.911 0 00-1.423 1.424l-.316 1.368a3.418 3.418 0 01-2.549 2.548l-1.368.316a1.912 1.912 0 00-1.424 1.425.75.75 0 01-1.462-.338 3.421 3.421 0 012.549-2.548l1.369-.316a1.912 1.912 0 001.423-1.424l.316-1.369a3.42 3.42 0 012.548-2.548l1.369-.316a1.91 1.91 0 001.424-1.424l.315-1.368a3.421 3.421 0 012.549-2.548.75.75 0 11.337 1.461z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});