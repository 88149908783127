define("ember-svg-jar/inlined/love-it-ribbon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it-ribbon</title><path d=\"M19.5.2h-15a2 2 0 00-2 2v20a1.55 1.55 0 00.769 1.425 1.587 1.587 0 001.614-.125l6.981-4.518a.251.251 0 01.272 0l6.982 4.518a1.483 1.483 0 002.382-1.3v-20a2 2 0 00-2-2zM7 8.435c0-2.865 3.548-3.906 4.77-1.015a.249.249 0 00.46 0C13.452 4.529 17 5.57 17 8.435c0 2.388-4.016 5.528-4.849 6.154a.251.251 0 01-.3 0C11.017 13.963 7 10.823 7 8.435z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});