define("ember-svg-jar/inlined/lion-footprint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lion-footprint</title><path d=\"M14.25 7.158a7.6 7.6 0 00-2.97-6.031 4.328 4.328 0 00-4.03-.5 4.331 4.331 0 00-4.03.5A7.6 7.6 0 00.25 7.158c0 4.857 5.2 8 7 8s7-3.143 7-8zM9.788 10.2a2.472 2.472 0 01-2.538 1.958A2.472 2.472 0 014.712 10.2 3.851 3.851 0 012.75 7.1a4.247 4.247 0 014.5-3.937 4.247 4.247 0 014.5 3.937 3.851 3.851 0 01-1.962 3.1zM19.375 19.283c-.438-1.313-.875-2.625-2.625-2.625s-2.188 1.312-2.625 2.625c-.277.83-2.188.875-2.188 2.625a1.6 1.6 0 001.75 1.75c1.313 0 1.75-1.313 3.063-1.313s1.75 1.313 3.062 1.313a1.6 1.6 0 001.75-1.75c0-1.75-1.91-1.795-2.187-2.625zM13.25 13.6c0 .967.588 1.75 1.312 1.75s1.313-.783 1.313-1.75-.588-1.75-1.313-1.75-1.312.779-1.312 1.75zM11.062 15.783c-.724 0-1.312.783-1.312 1.75s.588 1.75 1.312 1.75 1.313-.784 1.313-1.75-.588-1.75-1.313-1.75zM18.937 15.345c.725 0 1.313-.783 1.313-1.75s-.588-1.75-1.313-1.75-1.312.784-1.312 1.75.588 1.75 1.312 1.75zM22.437 15.783c-.724 0-1.312.783-1.312 1.75s.588 1.75 1.312 1.75 1.313-.784 1.313-1.75-.588-1.75-1.313-1.75z\"/><path d=\"M7.75 8.908h-1a.75.75 0 000 1.5h1a.75.75 0 100-1.5z\"/><circle cx=\"5\" cy=\"6.908\" r=\"1\"/><circle cx=\"9.5\" cy=\"6.908\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});