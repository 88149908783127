define("ember-svg-jar/inlined/wind-velocity-measure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wind-velocity-measure</title><path d=\"M22.732 5.863L8.487.215a2.005 2.005 0 00-1.862.206 1.983 1.983 0 00-.871 1.637.25.25 0 01-.117.2L2.383 4.3A.25.25 0 012 4.092V1a1 1 0 00-2 0v22a1 1 0 002 0V11.647a.25.25 0 01.351-.229L5.6 12.859a.249.249 0 01.149.228v.341a2 2 0 002.742 1.857l14.244-5.7a2 2 0 000-3.72zM10.25 3.432a.25.25 0 01.342-.232l1.5.594a.251.251 0 01.158.232v7.435a.25.25 0 01-.157.232l-1.5.6a.25.25 0 01-.343-.232zm6.842 2.341a.251.251 0 01.158.233v3.456a.25.25 0 01-.157.232l-1.5.6a.251.251 0 01-.233-.026.249.249 0 01-.11-.207V5.412a.252.252 0 01.109-.207.254.254 0 01.233-.026zM8.119 1.146zm-5.97 8A.249.249 0 012 8.913V7.046a.247.247 0 01.117-.211l3.251-2.044A.25.25 0 015.751 5v5.351a.249.249 0 01-.351.229z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});