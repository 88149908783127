define("ember-svg-jar/inlined/office-tape", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-tape</title><path d=\"M4.506 11.891a.25.25 0 00.34.212l3.495-1.36a.251.251 0 00.159-.233v-.262A8.5 8.5 0 018.61 8.9a.247.247 0 00-.089-.234.25.25 0 00-.248-.039L4.231 10.2a.251.251 0 00-.112.38 1.981 1.981 0 01.381 1.168c0 .052 0 .096.006.143z\"/><path d=\"M17 3.248a7.009 7.009 0 00-7 7v1.5a3.5 3.5 0 01-7 0 .5.5 0 00-.5-.5h-1a1.5 1.5 0 00-1.5 1.5v3.5a4.5 4.5 0 004.5 4.5h16a3.5 3.5 0 003.5-3.5v-7a7.009 7.009 0 00-7-7zm0 10.5a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});