define("ember-svg-jar/inlined/meeting-team-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-team-monitor</title><circle cx=\"14.58\" cy=\"4.749\" r=\"2\"/><path d=\"M17.4 10.749a.5.5 0 00.47-.683 3.489 3.489 0 00-6.444-.313.251.251 0 00.017.256 4.825 4.825 0 01.352.6.25.25 0 00.224.139z\"/><path d=\"M24 1.749A1.753 1.753 0 0022.25 0h-16A1.753 1.753 0 004.5 1.749v4.5a.75.75 0 001.5 0v-4.5a.253.253 0 01.25-.25h16a.253.253 0 01.25.25v12a.253.253 0 01-.25.25h-8.5a.75.75 0 00-.375 1.4.252.252 0 01.125.217v2.134a.757.757 0 00.75.75h3.5a.75.75 0 000-1.5h-2.5a.249.249 0 01-.25-.25v-1a.25.25 0 01.25-.25h7A1.752 1.752 0 0024 13.749z\"/><path d=\"M10.144 18.44l-.473-.175a.25.25 0 01-.149-.151v-.013a1.526 1.526 0 01.007-.8.243.243 0 01.052-.091 6.084 6.084 0 001.272-4.141A3.314 3.314 0 007.5 9.5a3.314 3.314 0 00-3.349 3.569A6.069 6.069 0 005.416 17.2a.243.243 0 01.048.082 1.482 1.482 0 01.018.82v.013a.248.248 0 01-.149.151l-.473.175c-2.155.8-3.713 1.377-4.2 2.348A6.76 6.76 0 000 23.5a.5.5 0 00.5.5h14a.5.5 0 00.5-.5 6.76 6.76 0 00-.66-2.711c-.483-.972-2.04-1.549-4.196-2.349z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});