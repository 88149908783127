define("ember-svg-jar/inlined/common-file-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-empty</title><path d=\"M22 6.328V22a2 2 0 01-2 2H4a2 2 0 01-2-2V2a2 2 0 012-2h11.672a2 2 0 011.414.586l4.328 4.328A2 2 0 0122 6.328z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});