define("ember-svg-jar/inlined/design-file-text-image-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-text-image-alternate</title><path d=\"M8 13h8.343a.5.5 0 00.46-.7l-1.45-3.373a1 1 0 00-1.587-.067l-1.511 1.94a.25.25 0 01-.349.046l-1.579-1.203a1 1 0 00-1.273.207l-1.478 2.387A.5.5 0 008 13z\"/><circle cx=\"9.103\" cy=\"5.5\" r=\"1.5\"/><path d=\"M17 14.5H7a1 1 0 000 2h10a1 1 0 000-2zM17 17.5H7a1 1 0 000 2h10a1 1 0 000-2z\"/><path d=\"M21.707 5.707L16.293.293A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.465a.5.5 0 01.353.146l4.536 4.536a.5.5 0 01.146.353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});