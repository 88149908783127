define("ember-svg-jar/inlined/lucide-file-scan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20 10V7.5L14.5 2H6a2 2 0 00-2 2v16c0 1.1.9 2 2 2h4.5\"/><path d=\"M14 2v6h6M16 22a2 2 0 01-2-2M20 22a2 2 0 002-2M20 14a2 2 0 012 2M16 14a2 2 0 00-2 2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});