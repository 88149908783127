define("ember-svg-jar/inlined/dating-chat-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dating-chat-woman</title><path d=\"M16.75 0c-4 0-7.25 2.7-7.25 6.008a5.385 5.385 0 001.691 3.861.5.5 0 01.115.538l-.647 1.656a.5.5 0 00.623.656l2.829-.935a1 1 0 01.554-.021 8.643 8.643 0 002.085.251c4 0 7.25-2.695 7.25-6.006S20.748 0 16.75 0zm3.059 6.2L17.33 8.788a.689.689 0 01-.995 0L13.856 6.2a1.761 1.761 0 012.49-2.491.687.687 0 00.973 0 1.761 1.761 0 012.49 2.491zM9.5 14.233a4.25 4.25 0 00-8.5 0 3.068 3.068 0 01-.7 2.352 1 1 0 101.4 1.422 3.938 3.938 0 00.418-.487.25.25 0 01.173-.1.256.256 0 01.193.059 4.24 4.24 0 005.526 0 .256.256 0 01.193-.059.25.25 0 01.173.1 4.025 4.025 0 00.418.487 1 1 0 001.406-1.422 3.068 3.068 0 01-.7-2.352zM5.25 16.5a2.253 2.253 0 01-2.231-1.962.25.25 0 01.2-.278 3.409 3.409 0 00.909-.28 2.471 2.471 0 00.916-.74.251.251 0 01.4 0 2.471 2.471 0 00.916.74 3.409 3.409 0 00.909.28.25.25 0 01.2.278A2.253 2.253 0 015.25 16.5zM7.978 19.017a.5.5 0 00-.477-.025 4.472 4.472 0 00-2.034 1.985.251.251 0 01-.434 0A4.472 4.472 0 003 18.992a.5.5 0 00-.477.025A5.251 5.251 0 000 23.5a.5.5 0 00.5.5H10a.5.5 0 00.5-.5 5.251 5.251 0 00-2.522-4.483z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});