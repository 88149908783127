define("ember-svg-jar/inlined/touch-id-lock-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>touch-id-lock-1</title><path d=\"M19.5 9.5h-.75V6.75a6.75 6.75 0 00-13.5 0V9.5H4.5a2 2 0 00-2 2V22a2 2 0 002 2h15a2 2 0 002-2V11.5a2 2 0 00-2-2zm-6.287 12.575A4.255 4.255 0 017.75 18v-2a4.249 4.249 0 01.176-1.214.75.75 0 011.438.428A2.742 2.742 0 009.25 16v2A2.752 2.752 0 0012 20.75a2.789 2.789 0 00.787-.113.75.75 0 01.426 1.438zM12 15.625a.625.625 0 010-1.25A1.627 1.627 0 0113.625 16v1.5a.625.625 0 01-1.25 0V16a.375.375 0 00-.375-.375zm-.375.875V18a.375.375 0 00.375.375.625.625 0 010 1.25A1.626 1.626 0 0110.375 18v-1.5a.625.625 0 011.25 0zM16.25 18a4.218 4.218 0 01-.41 1.823.75.75 0 11-1.354-.645A2.727 2.727 0 0014.75 18v-2a2.75 2.75 0 00-4.167-2.357.75.75 0 01-.774-1.285A4.25 4.25 0 0116.25 16zm0-9a.5.5 0 01-.5.5h-7.5a.5.5 0 01-.5-.5V6.75a4.25 4.25 0 018.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});