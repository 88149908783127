define("ember-svg-jar/inlined/accounting-withdraw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accounting-withdraw</title><path d=\"M20.261 11.19a1.723 1.723 0 00-1.218-2.94h-8.236a3 3 0 00-2.121.879L.505 17.31a1.722 1.722 0 001.218 2.94h8.235a3 3 0 002.121-.879zm-6.364.192a1.752 1.752 0 01-.451 1.907.751.751 0 11-1.157-.957.147.147 0 00-.137-.24 3.677 3.677 0 00-1.991 1.147.171.171 0 00-.026.171.168.168 0 00.137.106 1.455 1.455 0 011.2.791 1.752 1.752 0 01-.448 1.91 5.079 5.079 0 01-2.963 1.675.252.252 0 00-.157.088l-.541.656a.751.751 0 11-1.119-1 .251.251 0 00.009-.308 1.146 1.146 0 01-.121-.2 1.752 1.752 0 01.447-1.911.75.75 0 111.158.953.147.147 0 00.136.238 3.615 3.615 0 001.997-1.145.171.171 0 00-.112-.279 1.433 1.433 0 01-1.2-.789 1.748 1.748 0 01.448-1.911 5 5 0 013.2-1.7.254.254 0 00.175-.09l.52-.629a.75.75 0 011.156.957l-.172.208a.248.248 0 00-.019.292c.01.02.021.039.034.06z\"/><path d=\"M24 5.75a2 2 0 00-2-2H2a2 2 0 00-2 2v6a2 2 0 002 2 1 1 0 00.2-1.98.25.25 0 01-.2-.245V6.75a1 1 0 011-1h18a1 1 0 011 1v5a1 1 0 000 2 2 2 0 002-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});