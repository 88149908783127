define("ember-svg-jar/inlined/smiley-nauseous-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-nauseous-alternate</title><path d=\"M9.438 14.442a1 1 0 001.214.725 5.589 5.589 0 012.7 0 .984.984 0 00.245.03 1 1 0 00.243-1.969 7.579 7.579 0 00-3.672 0 1 1 0 00-.73 1.214zM6.027 12.115a1 1 0 00-.92 1.776A3.493 3.493 0 017 17a1 1 0 002 0 5.486 5.486 0 00-2.973-4.885zM17.973 12.115A5.486 5.486 0 0015 17a1 1 0 102 0 3.493 3.493 0 011.893-3.11 1 1 0 00-.92-1.776z\"/><circle cx=\"8\" cy=\"7.5\" r=\"1.75\"/><circle cx=\"16\" cy=\"7.5\" r=\"1.75\"/><path d=\"M23.186 7.147a1 1 0 00-1.864.725 10 10 0 11-18.644 0 1 1 0 00-1.864-.725 12 12 0 1022.372 0zM3.5 5c2.118 0 4.9-1.07 5.9-3.053a1 1 0 00-1.79-.894C7.023 2.218 5.013 3 3.5 3a1 1 0 000 2zM20.5 5a1 1 0 000-2c-1.513 0-3.523-.782-4.105-1.947a1 1 0 00-1.79.894C15.6 3.93 18.382 5 20.5 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});