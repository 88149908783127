define("ember-svg-jar/inlined/paralympics-weightlifting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paralympics-weightlifting</title><circle cx=\"12\" cy=\"8.462\" r=\"2.5\"/><path d=\"M9 21.712a1.25 1.25 0 002.5 0v-.25a.5.5 0 01.5-.5.5.5 0 01.5.5v.25a1.25 1.25 0 002.5 0v-2a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5zM17.5 5.462a1.25 1.25 0 00-1.25 1.25v1.5a4.253 4.253 0 01-4.673 4.229 4.394 4.394 0 01-3.829-4.434V6.712a1.25 1.25 0 00-2.5 0v1.26A7.062 7.062 0 009 14.242v2.97a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-2.96a6.76 6.76 0 003.75-6.04v-1.5a1.25 1.25 0 00-1.25-1.25z\"/><path d=\"M23.507 1.378l-1.972-.333a.5.5 0 00-.576.41l-.269 1.588a32.859 32.859 0 00-17.426-.27l-.223-1.318a.5.5 0 00-.576-.41l-1.972.333a.5.5 0 00-.41.577l1 5.916a.5.5 0 00.577.41l1.972-.334a.5.5 0 00.409-.576L3.6 4.749a30.8 30.8 0 0116.756.282l-.395 2.34a.5.5 0 00.409.576l1.972.334a.5.5 0 00.577-.41l1-5.916a.5.5 0 00-.412-.577z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});