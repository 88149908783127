define("ember-svg-jar/inlined/arrow-thick-right-bottom-corner-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-right-bottom-corner-3</title><path d=\"M21.3 7.506l-4-4.03a1 1 0 00-1.709.705v6.6a.25.25 0 01-.428.176L6.944 2.7a1 1 0 00-1.416 0L2.7 5.523a1 1 0 000 1.414l8.253 8.232a.25.25 0 01-.176.427H4.245a1 1 0 00-.711 1.7l3.961 4a1 1 0 00.709.3h12.389a1 1 0 001-1V8.211a1 1 0 00-.293-.705z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});