define("ember-svg-jar/inlined/amusement-park-castle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>amusement-park-castle</title><path d=\"M23.891 23.188A7.561 7.561 0 0122 18.5a.5.5 0 00-.5-.5h-19a.5.5 0 00-.5.5 7.561 7.561 0 01-1.891 4.688A.5.5 0 00.5 24h9a.5.5 0 00.5-.5V22a2 2 0 014 0v1.5a.5.5 0 00.5.5h9a.5.5 0 00.391-.812zM2.5 16.5h19a.5.5 0 00.5-.5v-4.394a1 1 0 00-.086-.406l-1.578-3.55a1 1 0 01-.086-.406v-.536a.5.5 0 01.243-.429l1.461-.879a1 1 0 00-.143-1.787L19.778 2.8a.75.75 0 00-1.028.7v3.744a1 1 0 01-.086.406l-1.578 3.55a1 1 0 00-.086.406V14a.5.5 0 01-.5.5H16a.5.5 0 01-.5-.5V9.652a1 1 0 00-.168-.555l-2.414-3.621a1 1 0 01-.168-.555v-.963a.5.5 0 01.243-.429l1.461-.876a1 1 0 00-.143-1.787L12.278.054a.75.75 0 00-1.028.7v4.167a1 1 0 01-.168.555L8.668 9.1a1 1 0 00-.168.555V14a.5.5 0 01-.5.5h-.5A.5.5 0 017 14v-2.394a1 1 0 00-.086-.406L5.336 7.65a1 1 0 01-.086-.406v-.536a.5.5 0 01.243-.429L6.954 5.4a1 1 0 00-.143-1.787L4.778 2.8a.75.75 0 00-1.028.7v3.744a1 1 0 01-.086.406L2.086 11.2a1 1 0 00-.086.406V16a.5.5 0 00.5.5zm9.5-6a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});