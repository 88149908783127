define("ember-svg-jar/inlined/cog-approved", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cog-approved</title><path d=\"M15.476 15.143a3.032 3.032 0 01-4.465.25l-3.634-3.636a3 3 0 014.241-4.243l1.262 1.262 3.567-4.471a7.355 7.355 0 00-1.947-.939V1.5A1.5 1.5 0 0013 0h-2a1.5 1.5 0 00-1.5 1.5v1.863a8.961 8.961 0 00-1.838.763L6.341 2.809a1.5 1.5 0 00-2.121 0L2.806 4.223a1.5 1.5 0 000 2.122l1.317 1.317A8.988 8.988 0 003.36 9.5H1.5A1.5 1.5 0 000 11v2a1.5 1.5 0 001.5 1.5h1.86a8.988 8.988 0 00.763 1.839l-1.317 1.319a1.5 1.5 0 000 2.121l1.414 1.414a1.543 1.543 0 002.121 0l1.316-1.317a9 9 0 001.84.764v1.86A1.5 1.5 0 0011 24h2a1.5 1.5 0 001.5-1.5v-1.86a8.957 8.957 0 001.838-.763l1.318 1.317a1.5 1.5 0 002.121 0l1.413-1.414a1.5 1.5 0 000-2.121l-1.32-1.318a9.077 9.077 0 00.763-1.84h1.86a1.5 1.5 0 001.5-1.5V11a1.5 1.5 0 00-1.5-1.5h-1.864a7.47 7.47 0 00-.2-.566z\"/><path d=\"M23.435.333a1.5 1.5 0 00-2.107.239l-8.323 10.451-2.448-2.448a1.5 1.5 0 10-2.12 2.125l3.635 3.636a1.532 1.532 0 002.232-.126l9.369-11.769a1.5 1.5 0 00-.238-2.108z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});