define("ember-svg-jar/inlined/keyboard-option-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-option-alternate</title><path d=\"M19.118.082H4.882a4.8 4.8 0 00-4.8 4.8v14.232a4.8 4.8 0 004.8 4.8h14.236a4.8 4.8 0 004.8-4.8V4.878a4.8 4.8 0 00-4.8-4.796zm2.8 19.032a2.8 2.8 0 01-2.8 2.8H4.882a2.8 2.8 0 01-2.8-2.8V4.878a2.8 2.8 0 012.8-2.8h14.236a2.8 2.8 0 012.8 2.8z\"/><path d=\"M19.414 15.5h-3.086a1 1 0 01-.707-.293L9.5 9.081A2 2 0 008.086 8.5H4.414a1 1 0 100 2h3.257a1 1 0 01.708.293L14.5 16.91a2 2 0 001.414.586h3.5a1 1 0 100-2zM19.414 8.5H14.5a1 1 0 000 2h4.914a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});