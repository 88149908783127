define("ember-svg-jar/inlined/single-woman-actions-view.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-view</title><path d=\"M9.3 17.5a.247.247 0 00.187-.084.252.252 0 00.062-.2A6.358 6.358 0 019.5 16.5a6.964 6.964 0 011.885-4.767.25.25 0 00-.06-.388.694.694 0 01-.364-.652v-.467a.25.25 0 01.25-.25A6.374 6.374 0 0015 9.081a.388.388 0 00.084-.593c-.494-.537-.835-1.185-.981-3.317C13.838 1.346 11.236 0 9 0S4.161 1.346 3.9 5.171c-.145 2.132-.487 2.78-.981 3.317A.388.388 0 003 9.081a6.348 6.348 0 003.782.9.249.249 0 01.253.25v.462a.778.778 0 01-.512.73c-2.9 1.065-5.212 1.825-5.764 2.922A7.822 7.822 0 000 17.076a.386.386 0 00.1.3.4.4 0 00.29.126z\"/><path d=\"M21.173 19.759a.25.25 0 01-.034-.311 5.513 5.513 0 10-1.692 1.691.251.251 0 01.312.034l2.534 2.534a1 1 0 001.414-1.414zM13 16.5a3.5 3.5 0 113.5 3.5 3.5 3.5 0 01-3.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});