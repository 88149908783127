define("ember-svg-jar/inlined/network-user.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>network-user</title><path d=\"M12.093 23.877c.183 0 .36 0 .542-.011a.969.969 0 00-.076-1.936c-.092 0-.183.006-.275.007a.251.251 0 01-.208-.107 23.652 23.652 0 01-1.888-3.166.25.25 0 01.225-.359h.731a.97.97 0 000-1.939H9.438a.248.248 0 01-.238-.173 15.851 15.851 0 01-.717-3.429.25.25 0 01.249-.274H10.9a.969.969 0 100-1.938H8.724a.251.251 0 01-.184-.081.246.246 0 01-.065-.191 13.362 13.362 0 01.434-2.452.251.251 0 01.241-.183h2.72a.969.969 0 100-1.938H10.1a.249.249 0 01-.212-.119.246.246 0 01-.011-.242 16 16 0 012.11-3.185.253.253 0 01.182-.089c.175-.006.455-.017.571-.01.537.037.8-.39.823-.927a.968.968 0 00-.925-1.009c-.119-.005-.237 0-.356 0l-.154.005A11.638 11.638 0 00.26 11.611a11.979 11.979 0 0011.833 12.266zm-2.806-2.824a.25.25 0 01-.3.361 9.878 9.878 0 01-4.11-2.69.25.25 0 01.184-.419h2.7a.25.25 0 01.239.153 23.027 23.027 0 001.287 2.595zM4.686 5.288a9.765 9.765 0 013.977-2.623.25.25 0 01.3.368 16.537 16.537 0 00-1.3 2.518.249.249 0 01-.231.156H4.87a.25.25 0 01-.184-.419zm-1.642 2.5a.252.252 0 01.228-.147h3.4a.25.25 0 01.244.306 15.5 15.5 0 00-.356 2.369.249.249 0 01-.249.232H2.56a.249.249 0 01-.19-.087.254.254 0 01-.058-.2 9.562 9.562 0 01.732-2.469zm.156 8.436a10.286 10.286 0 01-.954-3.46.251.251 0 01.064-.192.254.254 0 01.19-.082h3.815a.251.251 0 01.249.231 17.593 17.593 0 00.588 3.329.249.249 0 01-.241.316H3.428a.251.251 0 01-.228-.142z\"/><path d=\"M18.412 8.6a5.337 5.337 0 00-5.33 5.331v3.391a.484.484 0 00.484.485h1.747a.249.249 0 01.249.229l.429 5.142a.484.484 0 00.482.443h3.877a.483.483 0 00.482-.443l.429-5.142a.249.249 0 01.249-.229h1.747a.485.485 0 00.485-.485v-3.387a5.337 5.337 0 00-5.33-5.335zm.726 6.25a.727.727 0 01-1.453 0v-3.823a.727.727 0 011.453 0zM18.412 8.12a3.877 3.877 0 10-3.877-3.876 3.88 3.88 0 003.877 3.876zm-2.234-4.792a.251.251 0 01.371-.109 5.19 5.19 0 002.929.9 5.2 5.2 0 001.066-.114.235.235 0 01.2.048.242.242 0 01.089.187 2.423 2.423 0 01-4.845 0 2.348 2.348 0 01.19-.912z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});