define("ember-svg-jar/inlined/style-three-pin-church-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-church-1</title><circle cx=\"12\" cy=\"9.5\" r=\"1\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM8 14.75a.25.25 0 01-.25.25H6a.5.5 0 01-.5-.5v-2.465a1.5 1.5 0 01.668-1.248l1.443-.962a.25.25 0 01.389.208zm7-.25a.5.5 0 01-.5.5H13a.25.25 0 01-.25-.25v-2a.75.75 0 00-1.5 0v2A.25.25 0 0111 15H9.5a.5.5 0 01-.5-.5V9a3 3 0 012.077-2.843.251.251 0 00.173-.238V5.5a.25.25 0 00-.25-.25h-.25a.75.75 0 010-1.5H11a.25.25 0 00.25-.25v-.25a.75.75 0 011.5 0v.25a.25.25 0 00.25.25h.25a.75.75 0 010 1.5H13a.25.25 0 00-.25.25v.419a.25.25 0 00.173.238A3 3 0 0115 9zm3.5 0a.5.5 0 01-.5.5h-1.75a.25.25 0 01-.25-.25v-4.717a.25.25 0 01.389-.208l1.443.962a1.5 1.5 0 01.668 1.248z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});