define("ember-svg-jar/inlined/multiple-users-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-users-add</title><path d=\"M14.471 8.4l-.4-.141a.25.25 0 01-.155-.162 2.28 2.28 0 01-.013-.975.246.246 0 01.055-.1 4.652 4.652 0 001.186-3.535A3.22 3.22 0 0012 0a2.994 2.994 0 00-1.342.314.25.25 0 00-.1.363 5 5 0 01.842 2.808 6.266 6.266 0 01-.922 3.624.25.25 0 00.121.372c3 1.121 3.653 2 3.84 2.388a.25.25 0 00.311.126 7.866 7.866 0 011.537-.395.25.25 0 00.061-.477 40.793 40.793 0 00-1.877-.723z\"/><path d=\"M13.22 10.774a.248.248 0 00.1-.164.251.251 0 00-.045-.19c-.886-1.2-4.463-2.254-4.207-2.163a.25.25 0 01-.15-.157 2.28 2.28 0 01-.018-.98.246.246 0 01.055-.1 4.652 4.652 0 001.186-3.535A3.22 3.22 0 007 0a3.22 3.22 0 00-3.146 3.485A4.619 4.619 0 005.021 7a.247.247 0 01.05.084 2.329 2.329 0 01.032.987.249.249 0 01-.157.179l-.417.15c-2.007.737-3.457 1.27-3.911 2.177A7.542 7.542 0 000 13.5a.5.5 0 00.5.5h9.663a.252.252 0 00.223-.136 7.989 7.989 0 012.834-3.09zM17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm4.5 6.5a1 1 0 01-1 1h-2.25a.25.25 0 00-.25.25V21a1 1 0 01-2 0v-2.25a.25.25 0 00-.25-.25H14a1 1 0 010-2h2.25a.25.25 0 00.25-.25V14a1 1 0 012 0v2.25a.25.25 0 00.25.25H21a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});