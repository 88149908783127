define("ember-svg-jar/inlined/wine-grapes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wine-grapes</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm1.5 6a2 2 0 012-2h.75a.25.25 0 01.25.25V5a2 2 0 01-2 2h-.75a.25.25 0 01-.25-.25zm-2.75-2.5a.75.75 0 011.5 0V7a.75.75 0 01-1.5 0zm1.25 5a2 2 0 11-2 2 2 2 0 012-2zm-7 2a2 2 0 112 2 2 2 0 01-2-2zM7.5 15a2 2 0 112 2 2 2 0 01-2-2zm4.5 6a2 2 0 112-2 2 2 0 01-2 2zm2.5-4a2 2 0 112-2 2 2 0 01-2 2zm2.5-4.5a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});