define("ember-svg-jar/inlined/cleaning-spray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cleaning-spray</title><path d=\"M12.434 10.54a1.493 1.493 0 00-1.152-.54h-.532a.5.5 0 01-.5-.5V8a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v1.5a.5.5 0 01-.5.5h-.532a1.493 1.493 0 00-1.152.54l-3.509 4.21A5.64 5.64 0 005.889 24h5.722a5.64 5.64 0 004.332-9.25z\"/><circle cx=\"19.25\" cy=\"4\" r=\"1\"/><circle cx=\"22.75\" cy=\"4\" r=\"1\"/><circle cx=\"22.75\" cy=\"1\" r=\"1\"/><circle cx=\"22.75\" cy=\"7\" r=\"1\"/><path d=\"M17.25 3.5a1 1 0 00-1-1h-.5a.5.5 0 01-.5-.5v-.5a1 1 0 00-1-1h-6.5A5.506 5.506 0 002.25 6a.5.5 0 00.5.5h8.132a.5.5 0 01.487.389A3.989 3.989 0 0015.25 10a1 1 0 000-2 1.994 1.994 0 01-1.8-1.143.25.25 0 01.226-.357h.573a1 1 0 001-1V5a.5.5 0 01.5-.5h.5a1 1 0 001.001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});