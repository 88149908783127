define("ember-svg-jar/inlined/phone-action-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-lock</title><path d=\"M15 10h-.25V8.6a2.75 2.75 0 00-5.5 0V10H9a1 1 0 00-1 1v4.5a1 1 0 001 1h6a1 1 0 001-1V11a1 1 0 00-1-1zm-3 3.75a1 1 0 111-1 1 1 0 01-1 1zM10.75 10V8.6a1.25 1.25 0 012.5 0V10z\"/><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});