define("ember-svg-jar/inlined/ticket-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ticket-1</title><path d=\"M23.8 10.962a2.694 2.694 0 010-3.254 1.023 1.023 0 00-.113-1.337L17.629.31A1.027 1.027 0 0016.292.2a2.7 2.7 0 01-3.254 0A1.023 1.023 0 0011.7.311L.146 11.865a.5.5 0 00-.057.638 3.2 3.2 0 01.572 1.827 3.126 3.126 0 01-.447 1.614 1.047 1.047 0 00.143 1.276l6.423 6.423a1.052 1.052 0 001.277.142 3.2 3.2 0 013.44.126.5.5 0 00.638-.058L23.688 12.3a1.022 1.022 0 00.112-1.338zm-4.359.363L11.17 19.6a2.126 2.126 0 01-3.008 0L4.4 15.838a2.128 2.128 0 010-3.009l8.273-8.273a2.128 2.128 0 013.008 0l3.76 3.761a2.126 2.126 0 01.002 3.008z\"/><path d=\"M14.708 7.034a.75.75 0 00-1.061 0L6.879 13.8a.75.75 0 101.061 1.064L14.708 8.1a.75.75 0 000-1.066zM14.2 11.345l-3.008 3.009a.75.75 0 101.06 1.06l3.008-3.008a.75.75 0 00-1.06-1.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});