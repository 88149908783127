define("ember-svg-jar/inlined/server-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-warning</title><path d=\"M2.5 6h17A2.5 2.5 0 0022 3.5v-1A2.5 2.5 0 0019.5 0h-17A2.5 2.5 0 000 2.5v1A2.5 2.5 0 002.5 6zm1.135-3a1.115 1.115 0 111.114 1.114A1.114 1.114 0 013.635 3zM7.5 3a1.115 1.115 0 111.114 1.114A1.115 1.115 0 017.5 3zM14.892 11.574a2.952 2.952 0 013.339-1.486 3.061 3.061 0 011.934 1.59l.26.495a.5.5 0 00.765.154A2.468 2.468 0 0022 10.5v-1A2.5 2.5 0 0019.5 7h-17A2.5 2.5 0 000 9.5v1A2.5 2.5 0 002.5 13h11.342a.5.5 0 00.442-.268zM9.729 10a1.115 1.115 0 11-1.115-1.115A1.115 1.115 0 019.729 10zm-3.865 0a1.115 1.115 0 11-1.115-1.115A1.115 1.115 0 015.864 10zM2.5 20h7.67a.5.5 0 00.442-.268l2.624-5a.5.5 0 00-.444-.732H2.5A2.5 2.5 0 000 16.5v1A2.5 2.5 0 002.5 20zm1.136-3a1.115 1.115 0 111.114 1.115A1.114 1.114 0 013.635 17zM7.5 17a1.115 1.115 0 111.115 1.115A1.115 1.115 0 017.5 17z\"/><path d=\"M11.165 21.905a1.429 1.429 0 00.048 1.408 1.453 1.453 0 001.233.687h10.112a1.455 1.455 0 001.232-.687 1.429 1.429 0 00.048-1.408l-5.055-9.634a1.45 1.45 0 00-2.562 0zM17.5 14.75a.75.75 0 01.75.75v3a.75.75 0 01-1.5 0v-3a.75.75 0 01.75-.75zm1 6.5a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});