define("ember-svg-jar/inlined/fireplace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fireplace</title><path d=\"M23.786 2.135A1 1 0 0023 1.75H1a.991.991 0 00-.788.385 1.015 1.015 0 00-.166.912l.731 1.945a1 1 0 00.97.758h20.5a.979.979 0 00.953-.7l.767-2.054a1 1 0 00-.181-.861z\"/><rect x=\"1.497\" y=\"7.25\" width=\"3\" height=\"4\" rx=\".5\" ry=\".5\"/><rect x=\"1.497\" y=\"12.75\" width=\"3\" height=\"4\" rx=\".5\" ry=\".5\"/><rect x=\"1.497\" y=\"18.25\" width=\"3\" height=\"4\" rx=\".5\" ry=\".5\"/><rect x=\"19.497\" y=\"7.25\" width=\"3\" height=\"4\" rx=\".5\" ry=\".5\"/><rect x=\"19.497\" y=\"12.75\" width=\"3\" height=\"4\" rx=\".5\" ry=\".5\"/><rect x=\"19.497\" y=\"18.25\" width=\"3\" height=\"4\" rx=\".5\" ry=\".5\"/><path d=\"M17.5 7.25h-11a.5.5 0 00-.5.5v14a.5.5 0 00.5.5h11a.5.5 0 00.5-.5v-14a.5.5 0 00-.5-.5zm-5.5 13a3.59 3.59 0 01-3.591-3.591 3.205 3.205 0 011.737-2.9.452.452 0 01.63.209 1.793 1.793 0 00.207.341.5.5 0 00.88-.179 3.894 3.894 0 00-.22-2.534.451.451 0 01.633-.582 6.621 6.621 0 013.315 5.649A3.591 3.591 0 0112 20.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});