define("ember-svg-jar/inlined/astrology-tent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astrology-tent</title><path d=\"M18.562 13.376a.749.749 0 00-.641-1.138h-3.384a.252.252 0 01-.214-.121L12.922 9.8A1.076 1.076 0 0012 9.249a1.074 1.074 0 00-.922.553l-1.4 2.315a.252.252 0 01-.214.121H6.079a.749.749 0 00-.641 1.138L7.1 16.12a.251.251 0 010 .259l-1.38 2.279a1.021 1.021 0 00.9 1.6h2.843a.252.252 0 01.214.121l1.4 2.316a1.076 1.076 0 00.922.551 1.077 1.077 0 00.921-.552l1.4-2.315a.252.252 0 01.214-.121h2.846a1.021 1.021 0 00.9-1.6l-1.38-2.279a.251.251 0 010-.259l1.379-2.279zm-2.415.362a.25.25 0 01.214.379l-.2.33a.25.25 0 01-.428 0l-.2-.33a.25.25 0 01.213-.379zm-2.524 4.9a.249.249 0 01-.213.121h-2.82a.249.249 0 01-.213-.121l-1.369-2.259a.251.251 0 010-.259l1.369-2.262a.248.248 0 01.213-.12h2.82a.248.248 0 01.213.12l1.369 2.262a.251.251 0 010 .259zm-1.837-7.111a.25.25 0 01.428 0l.2.329a.251.251 0 01-.214.38h-.4a.251.251 0 01-.214-.38zm-3.533 2.211a.25.25 0 01.213.379l-.2.33a.25.25 0 01-.428 0l-.2-.33a.25.25 0 01.214-.379zm-.4 5.023a.253.253 0 01-.218-.127.25.25 0 010-.252l.2-.33a.249.249 0 01.214-.121.253.253 0 01.214.12l.2.331a.25.25 0 01-.213.379zm4.361 2.209a.25.25 0 01-.428 0l-.2-.33a.247.247 0 010-.252.25.25 0 01.218-.127h.4a.25.25 0 01.218.127.247.247 0 010 .252zm4.147-2.588a.25.25 0 01-.214.379h-.4a.25.25 0 01-.213-.379l.2-.331a.253.253 0 01.214-.12.251.251 0 01.214.12zM.5 8.749a.5.5 0 00-.5.5V23.5a.5.5 0 00.5.5h2.986a.5.5 0 00.5-.472c.014-.181.014-.361.014-.54A7.785 7.785 0 001.306 16.8a.25.25 0 01.009-.4 10.112 10.112 0 004.051-7.08.5.5 0 00-.495-.569zM19.129 8.749a.5.5 0 00-.5.569 10.108 10.108 0 004.051 7.08.25.25 0 01.009.4A7.785 7.785 0 0020 22.988c0 .179 0 .359.015.536a.5.5 0 00.5.472L23.5 24a.5.5 0 00.5-.5V9.249a.5.5 0 00-.5-.5zM23.714 7.249a.095.095 0 00.05-.175L12.69.184a1.394 1.394 0 00-1.381 0L.236 7.074a.095.095 0 00.05.175z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});