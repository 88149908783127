define("ember-svg-jar/inlined/website-build", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>website-build</title><path d=\"M-.1 19a3 3 0 003 3h17.95a3 3 0 003-3v-5.5a.975.975 0 00-.6-.9.25.25 0 01-.151-.23V10a.25.25 0 01.25-.25.75.75 0 000-1.5A.25.25 0 0123.1 8V5.5a.25.25 0 01.25-.25.75.75 0 000-1.5.25.25 0 01-.25-.25V3a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25H19.4a.25.25 0 01-.25-.25V3a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25h-1.75a.25.25 0 01-.25-.25V3a1 1 0 00-1-1H2.9a3 3 0 00-3 3zM19.15 5.5a.25.25 0 01.25-.25h1.95a.25.25 0 01.25.25V8a.25.25 0 01-.25.25H19.4a.25.25 0 01-.25-.25zm0 4.5a.25.25 0 01.25-.25h1.95a.25.25 0 01.25.25v2.25a.25.25 0 01-.25.25H19.4a.25.25 0 01-.25-.25zM15.4 5.5a.25.25 0 01.25-.25h1.75a.25.25 0 01.25.25V8a.25.25 0 01-.25.25h-1.75A.25.25 0 0115.4 8zm0 4.5a.25.25 0 01.25-.25h1.75a.25.25 0 01.25.25v2.25a.25.25 0 01-.25.25h-1.75a.25.25 0 01-.25-.25zM9.553 4a.967.967 0 011.693 0 .969.969 0 01.154.5.969.969 0 01-.154.5.984.984 0 11-1.693-1zm-3.5 0a.967.967 0 011.693 0 .969.969 0 01.154.5.969.969 0 01-.154.5.984.984 0 11-1.693-1zM2.4 4.5a.979.979 0 01.092-.408A.98.98 0 014.246 4a.969.969 0 01.154.5.969.969 0 01-.154.5A1 1 0 012.4 4.5zm-.5 2.75A.25.25 0 012.15 7h11a.25.25 0 01.25.25v5.25a2 2 0 002 2h6.2a.25.25 0 01.25.25V19a1 1 0 01-1 1H2.9a1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});