define("ember-svg-jar/inlined/read-email-at", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-email-at</title><path d=\"M12 9.236a2.526 2.526 0 102.6 2.525A2.567 2.567 0 0012 9.236z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm5.958 14.286a1.829 1.829 0 001.856-1.8v-.728A7.727 7.727 0 0012 4.142a7.727 7.727 0 00-7.814 7.619A7.727 7.727 0 0012 19.38a7.906 7.906 0 004.423-1.328.75.75 0 01.836 1.246A9.409 9.409 0 0112 20.88a9.228 9.228 0 01-9.314-9.119A9.228 9.228 0 0112 2.642a9.228 9.228 0 019.314 9.119v.728a3.33 3.33 0 01-3.356 3.3 3.369 3.369 0 01-2.8-1.488 4.1 4.1 0 01-7.258-2.54 4.1 4.1 0 018.2 0v.728a1.829 1.829 0 001.858 1.797z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});