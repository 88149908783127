define("ember-svg-jar/inlined/smart-watch-circle-ringing-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-ringing-alternate</title><path d=\"M21.5 12A9.5 9.5 0 0017 3.927a.245.245 0 01-.109-.148L15.983.373A.5.5 0 0015.5 0h-7a.5.5 0 00-.484.371l-.908 3.408A.245.245 0 017 3.927a9.5 9.5 0 000 16.149.25.25 0 01.109.148l.908 3.407A.5.5 0 008.5 24h7a.5.5 0 00.482-.368l.935-3.428a.247.247 0 01.109-.146A9.5 9.5 0 0021.5 12zM12 19.5a7.5 7.5 0 117.5-7.5 7.509 7.509 0 01-7.5 7.5z\"/><path d=\"M15.366 11.175A11.277 11.277 0 0012 10.753a12.057 12.057 0 00-3.189.369C6.872 11.657 6.75 12.6 6.75 13v1a1.819 1.819 0 001.75 1.75h1A1.819 1.819 0 0011.25 14v-1.49a.25.25 0 01.242-.25c.33-.01.685-.01 1.016 0a.25.25 0 01.242.25V14a1.821 1.821 0 001.718 1.749H15.5A1.819 1.819 0 0017.252 14v-1c0-1-.931-1.534-1.886-1.825zM11.25 7v1.5a.75.75 0 101.5 0V7a.75.75 0 10-1.5 0zM14.187 9.728a.749.749 0 00.994-.369L15.806 8a.75.75 0 00-1.363-.625l-.625 1.363a.748.748 0 00.369.99zM8.818 9.359a.75.75 0 101.363-.625l-.625-1.363A.75.75 0 008.193 8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});