define("ember-svg-jar/inlined/lock-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lock-5</title><path d=\"M18.75 11.725V6.75a6.75 6.75 0 00-13.5 0v4.975a8 8 0 1013.5 0zM12 18a2 2 0 112-2 2 2 0 01-2 2zm4.25-9.619a.5.5 0 01-.722.448 7.922 7.922 0 00-7.056 0 .5.5 0 01-.722-.448V6.75a4.25 4.25 0 018.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});