define("ember-svg-jar/inlined/common-file-text-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-text-lock</title><path d=\"M19 8.5a1 1 0 000-2h-7a1 1 0 000 2zM12.5 11a1 1 0 000 2h4a1 1 0 000-2z\"/><path d=\"M23.414 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.682a.244.244 0 00.184.235 4.461 4.461 0 011.429.688A.247.247 0 008 10.4V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-9.75a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M8.611 15.512a.123.123 0 01-.111-.119V14.25a3.5 3.5 0 00-7 0v1.134a.114.114 0 01-.113.128A1.5 1.5 0 000 17v5.5A1.5 1.5 0 001.5 24h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.389-1.488zM5 21.25a1 1 0 111-1 1 1 0 01-1 1zm1.5-5.866a.12.12 0 01-.106.116H3.612a.122.122 0 01-.112-.116V14.25a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});