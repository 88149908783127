define("ember-svg-jar/inlined/shipment-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-warning</title><path d=\"M8.579 4.375a.25.25 0 00.25.25h5.353a.25.25 0 00.177-.427l-2.884-2.884a1.511 1.511 0 00-1.06-.439H8.829a.25.25 0 00-.25.25zM6.829 4.625a.25.25 0 00.25-.25v-3.25a.25.25 0 00-.25-.25H5.243a1.511 1.511 0 00-1.061.439L1.3 4.2a.25.25 0 00.177.427zM13.951 10.117A3.006 3.006 0 0115.2 8.93a.25.25 0 00.134-.221V6.375a.25.25 0 00-.25-.25H.579a.25.25 0 00-.25.25v9a1.5 1.5 0 001.5 1.5h8.19a.252.252 0 00.219-.128zM18.152 11.067a1.75 1.75 0 00-3.054 0L9.8 20.52a1.751 1.751 0 001.527 2.605h10.591a1.751 1.751 0 001.528-2.605zm-1.528 7.808a1 1 0 11-1 1 1 1 0 011-1zm-.75-1.5v-2.5a.75.75 0 111.5 0v2.5a.75.75 0 01-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});