define("ember-svg-jar/inlined/volume-control-off-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>volume-control-off-1_1</title><path d=\"M16.177 4.3a.248.248 0 00.073-.176v-1.1a1 1 0 00-1.061-1 9.728 9.728 0 00-7.924 4.85.249.249 0 01-.221.133H5.25a3 3 0 00-3 3v2a3 3 0 003 3h.114a.251.251 0 00.177-.073zM23.707 1.706A1 1 0 0022.293.292l-22 22a1 1 0 000 1.414l.009.009a1 1 0 001.405-.009l6.63-6.631A.251.251 0 018.515 17a.245.245 0 01.177.075 10.081 10.081 0 006.5 2.92 1 1 0 001.061-1V9.266a.247.247 0 01.073-.176z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});