define("ember-svg-jar/inlined/radio-stereo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>radio-stereo</title><path d=\"M20.25 12a.25.25 0 00.25-.25V10a2 2 0 00-2-2h-13a2 2 0 00-2 2v1.75a.25.25 0 00.25.25h1.5a.25.25 0 00.25-.25v-1.5a.25.25 0 01.25-.25h12.5a.25.25 0 01.25.25v1.5a.25.25 0 00.25.25zM3 6a1 1 0 002 0 1 1 0 011-1 1 1 0 000-2 3 3 0 00-3 3z\"/><path d=\"M1 6a1 1 0 001-1 3 3 0 013-3 1 1 0 000-2 5.006 5.006 0 00-5 5 1 1 0 001 1zM18 5a1 1 0 011 1 1 1 0 002 0 3 3 0 00-3-3 1 1 0 000 2z\"/><path d=\"M19 2a3 3 0 013 3 1 1 0 002 0 5.006 5.006 0 00-5-5 1 1 0 000 2zM21.619 14.742a.249.249 0 01-.119-.213V14.5A1.5 1.5 0 0020 13H4a1.5 1.5 0 00-1.5 1.5v.029a.25.25 0 01-.119.213 5 5 0 00.551 8.81A1.5 1.5 0 004 24h16a1.5 1.5 0 001.068-.448 5 5 0 00.551-8.81zM9.5 17a1 1 0 011-1h3a1 1 0 010 2h-3a1 1 0 01-1-1zM5 16a3 3 0 11-3 3 3 3 0 013-3zm14 6a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});