define("ember-svg-jar/inlined/single-neutral-actions-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-email</title><path d=\"M10.5 15.5A2.5 2.5 0 0113 13h2.813a.25.25 0 00.194-.408c-.657-.8-2.005-1.343-4.508-2.263l-.629-.229a.6.6 0 01-.138-.31 2.632 2.632 0 01.058-1.3 5.618 5.618 0 001.466-4.314C12.256 1.756 10.677 0 8.5 0S4.744 1.756 4.744 4.174A5.605 5.605 0 006.2 8.471a2.505 2.505 0 01.073 1.317.614.614 0 01-.135.308l-.632.233c-2.446.9-4.214 1.549-4.753 2.626A9.167 9.167 0 000 16.5a.5.5 0 00.5.5h9.75a.25.25 0 00.25-.25zM23.871 18.042a.249.249 0 00-.254.007l-4.425 3.078a2.26 2.26 0 01-2.384 0l-4.425-3.078a.25.25 0 00-.383.212V22.5a1.5 1.5 0 001.5 1.5h9a1.5 1.5 0 001.5-1.5v-4.239a.249.249 0 00-.129-.219z\"/><path d=\"M24 16a1.5 1.5 0 00-1.5-1.5h-9A1.5 1.5 0 0012 16a.074.074 0 00.035.063l5.565 3.792a.752.752 0 00.794 0l5.568-3.792A.075.075 0 0024 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});