define("ember-svg-jar/inlined/os-system-apple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>os-system-apple</title><path d=\"M17.58 13.29a4.82 4.82 0 011.94-3.87.5.5 0 00.07-.72A5.16 5.16 0 0016 7c-1.69-.16-3.3 1-4.16 1S9.58 7 8.18 7a5.34 5.34 0 00-4.51 2.76C1.75 13.1 3.18 18 5 20.74c.91 1.32 2 2.81 3.44 2.76s1.9-.89 3.57-.89 2.14.89 3.6.86 2.47-1.35 3.39-2.67a12.23 12.23 0 001.37-2.67.5.5 0 00-.23-.61 4.76 4.76 0 01-2.56-4.23zM15 4.17a5.24 5.24 0 001.22-3.09.5.5 0 00-.6-.51 5.35 5.35 0 00-2.81 1.67 5.07 5.07 0 00-1.25 3 .5.5 0 00.55.51A4.48 4.48 0 0015 4.17z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});