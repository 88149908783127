define("ember-svg-jar/inlined/design-document-dwg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-document-dwg</title><path d=\"M22 4.5H2A1.5 1.5 0 00.5 6v12A1.5 1.5 0 002 19.5h20a1.5 1.5 0 001.5-1.5V6A1.5 1.5 0 0022 4.5zM5 16.125a.625.625 0 01-.625-.625v-6A.625.625 0 015 8.875a3.625 3.625 0 010 7.25zm9.625-4.861a10.158 10.158 0 01-1.065 4.515.629.629 0 01-.56.346.527.527 0 01-.068 0 .625.625 0 01-.538-.47s-.281-1.138-.317-1.265c-.018-.1-.134-.082-.155 0l-.316 1.262a.625.625 0 01-.538.47.61.61 0 01-.628-.342 10.158 10.158 0 01-1.065-4.515V9.5a.625.625 0 011.25 0v1.764a8.837 8.837 0 00.183 1.783c.023.113.145.066.16.006l.426-1.7a.625.625 0 011.212 0s.393 1.622.414 1.693.157.121.183-.01a9.2 9.2 0 00.172-1.767V9.5a.625.625 0 011.25 0zm5 .236a.625.625 0 01-1.25 0V11a.875.875 0 00-1.75 0v3a.875.875 0 001.722.221.076.076 0 00-.069-.1H18a.625.625 0 010-1.25h1a.625.625 0 01.625.625V14a2.125 2.125 0 01-4.25 0v-3a2.125 2.125 0 014.25 0z\"/><path d=\"M5.956 10.318a.25.25 0 00-.331.24v3.883a.25.25 0 00.331.24A2.238 2.238 0 007.375 12.5a2.239 2.239 0 00-1.419-2.182z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});