define("ember-svg-jar/inlined/messages-bubble-settings-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-settings-alternate</title><path d=\"M10.358 18.082a.248.248 0 000-.228.252.252 0 00-.186-.133 12.029 12.029 0 01-1.572-.338 1 1 0 00-.661.038l-3.648 1.52a.25.25 0 01-.331-.316l.85-2.339a1 1 0 00-.275-1.086A7.043 7.043 0 012 9.959c0-4.358 4.486-7.9 10-7.9s10 3.541 10 7.9a5.823 5.823 0 01-.069.86.249.249 0 00.251.287 3.226 3.226 0 011.363.28.252.252 0 00.349-.189A7.794 7.794 0 0024 9.959c0-5.461-5.383-9.9-12-9.9S0 4.5 0 9.959a8.876 8.876 0 002.707 6.26l-1.6 4.4a1 1 0 001.325 1.264L8.391 19.4c.437.114.878.2 1.322.276a.25.25 0 00.287-.238 3.27 3.27 0 01.358-1.356z\"/><circle cx=\"17.749\" cy=\"16.976\" r=\"1.5\"/><path d=\"M23.517 15.6a1.783 1.783 0 00-1.7-2.953l-1.411.327a.454.454 0 01-.534-.311l-.423-1.392a1.776 1.776 0 00-3.4 0l-.424 1.392a.456.456 0 01-.533.311l-1.411-.327a1.783 1.783 0 00-1.7 2.953l.988 1.064a.464.464 0 010 .626l-.988 1.065a1.783 1.783 0 001.7 2.953l1.411-.328a.452.452 0 01.533.312l.424 1.392a1.776 1.776 0 003.4 0l.423-1.392a.45.45 0 01.534-.312l1.411.328a1.783 1.783 0 001.7-2.953l-.987-1.065a.464.464 0 010-.626zm-2.768 1.377a3 3 0 11-3-3 3 3 0 013 2.999z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});