define("ember-svg-jar/inlined/ticket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ticket</title><path d=\"M11.5 9.75h-3a.75.75 0 000 1.5h3a.75.75 0 000-1.5zM11.5 12.75h-3a.75.75 0 000 1.5h3a.75.75 0 000-1.5z\"/><path d=\"M23.159 9.523A.992.992 0 0024 8.529V5a1 1 0 00-1-1h-3.75a.5.5 0 00-.5.5V6a.75.75 0 01-1.5 0V4.5a.5.5 0 00-.5-.5H1a1 1 0 00-1 1v3.536a1 1 0 00.858.989 2.5 2.5 0 010 4.95 1 1 0 00-.858.989V19a1 1 0 001 1h15.75a.5.5 0 00.5-.5V18a.75.75 0 011.5 0v1.5a.5.5 0 00.5.5H23a1 1 0 001-1v-3.529a.993.993 0 00-.84-.994 2.5 2.5 0 010-4.954zM14.5 16a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5V8a.5.5 0 01.5-.5h8a.5.5 0 01.5.5zm4.25-2.5a.75.75 0 01-1.5 0v-3a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});