define("ember-svg-jar/inlined/business-deal-negotiate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-deal-negotiate</title><path d=\"M8.5 12.5h.388a.25.25 0 01.25.25v.75a1 1 0 001.625.781l2.157-1.726a.251.251 0 01.156-.055h2.563a1 1 0 000-2h-3a.992.992 0 00-.625.22l-.579.463a.255.255 0 01-.215.047.251.251 0 01-.169-.14 1 1 0 00-.912-.59H8.75a.249.249 0 01-.25-.25v-8A.25.25 0 018.75 2h1.026a1 1 0 000-2H8.5a2 2 0 00-2 2v8.5a2 2 0 002 2zM21.361 5.724a1 1 0 001-1V2a2 2 0 00-2-2h-1.222a1 1 0 000 2h.972a.25.25 0 01.25.25v2.474a1 1 0 001 1zM19.6 21.2a.5.5 0 00.037.644c.948 1 2.245 2.156 3.863 2.156a.5.5 0 00.5-.5v-16a.5.5 0 00-.5-.5c-2.677 0-5.863 1.251-5.925 7.175a.273.273 0 01-.026.109l-1.135 2.27a.994.994 0 00.044.971 1.007 1.007 0 00.851.475h.441a.25.25 0 01.25.25V19a1 1 0 001 1h1a.25.25 0 01.2.4zm.538-7.424a1 1 0 111 1 1 1 0 01-1-1zM.5 24c1.618 0 2.915-1.152 3.863-2.156A.5.5 0 004.4 21.2l-.6-.8A.25.25 0 014 20h1a1 1 0 001-1v-.75a.25.25 0 01.25-.25h.441a1.007 1.007 0 00.851-.475 1 1 0 00.044-.972l-1.134-2.269a.254.254 0 01-.027-.108C6.363 8.251 3.177 7 .5 7a.5.5 0 00-.5.5v16a.5.5 0 00.5.5zm2.362-11.224a1 1 0 11-1 1 1 1 0 011-1z\"/><path d=\"M14.983 6.424h-1.794a1 1 0 000 2h.222a.1.1 0 01.1.1 1 1 0 002 0v-.01a.217.217 0 01.15-.208 2.179 2.179 0 00.131-4.1l-1.821-.729a.18.18 0 01-.116-.17.184.184 0 01.185-.183h1.794a1 1 0 000-2h-.22a.1.1 0 01-.1-.1 1 1 0 00-2 0v.012a.218.218 0 01-.151.207 2.179 2.179 0 00-.132 4.1l1.822.728a.184.184 0 01-.07.355z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});