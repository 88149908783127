define("ember-svg-jar/inlined/cat-mouse-toy-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cat-mouse-toy-1</title><path d=\"M13.061 10.4a6.977 6.977 0 014.427-5.834l-1.781-1.779a1.025 1.025 0 00-1.414 0l-1.768 1.769a5.472 5.472 0 00-.78 6.8.5.5 0 00.424.235.582.582 0 00.284-.046 4.923 4.923 0 01.729-.246 1.978 1.978 0 01-.121-.899z\"/><path d=\"M22.5 5.6H20a5.47 5.47 0 00-5.447 4.95.5.5 0 00.5.55H16.5a4.458 4.458 0 013.859 2.222.494.494 0 00.268.218l.175.06a.5.5 0 00.458-.071A5.524 5.524 0 0023.5 9.1V6.6a1 1 0 00-1-1zM3.516 16.148a.249.249 0 00.119-.292A2.883 2.883 0 013.5 15a2.933 2.933 0 01.473-1.6.251.251 0 00-.169-.382A1.9 1.9 0 003.5 13a2 2 0 00-1.149 3.634.249.249 0 00.264.015zM20.026 17a4.5 4.5 0 00-4.5-4.5h-2.069a4.766 4.766 0 00-2.295.59l-1.642.9a.25.25 0 00-.121.284A2.786 2.786 0 019.5 15a3 3 0 01-3 3 2.969 2.969 0 01-2.164-.943.249.249 0 00-.3-.047l-2.543 1.4A1.626 1.626 0 00.5 19.89a1.606 1.606 0 001.605 1.61h13.421a4.5 4.5 0 004.5-4.5zM4.25 19.979a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/><circle cx=\"6.5\" cy=\"14.995\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});