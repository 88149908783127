define("ember-svg-jar/inlined/glasses-sun-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>glasses-sun-1</title><path d=\"M20.444 7.006H3.546A3.656 3.656 0 000 10.755v1.786a4.3 4.3 0 004.151 4.425 6.335 6.335 0 005.743-3.9.5.5 0 01.284-.268 5.651 5.651 0 013.607 0 .5.5 0 01.286.269A6.353 6.353 0 0019.838 17 4.312 4.312 0 0024 12.556v-1.79a3.666 3.666 0 00-3.556-3.76zm-9.67 3.585a.251.251 0 01-.288-.252v-.129a3.17 3.17 0 00-.3-1.35.251.251 0 01.224-.36h3.153a.25.25 0 01.211.116.247.247 0 01.015.24 3.189 3.189 0 00-.3 1.358v.122a.251.251 0 01-.289.251 7.931 7.931 0 00-2.426.004z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});