define("ember-svg-jar/inlined/read-home-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-home-2</title><path d=\"M21.75 1a1 1 0 00-1-1H4.25a2 2 0 00-2 2v19a3 3 0 003 3h13.5a2 2 0 002-2v-1.584a.5.5 0 01.333-.471A1 1 0 0021.75 19zM5.774 9.336l5.346-4.359a.992.992 0 011.26 0l5.346 4.359a.751.751 0 01-.952 1.161l-.615-.5a.25.25 0 00-.409.193v4.106a1 1 0 01-1 1H13.6a.5.5 0 01-.5-.5v-2.04a1.35 1.35 0 00-2.7 0v2.04a.5.5 0 01-.5.5H8.75a1 1 0 01-1-1v-4.111a.251.251 0 00-.409-.194l-.615.506a.751.751 0 01-.952-1.161zM18.75 21.5a.5.5 0 01-.5.5h-13a1 1 0 010-2h13a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});