define("ember-svg-jar/inlined/style-three-pin-bin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-bin</title><path d=\"M9.5 9a.5.5 0 00-.5.5v5a.5.5 0 001 0v-5a.5.5 0 00-.5-.5zM12 9a.5.5 0 00-.5.5v5a.5.5 0 001 0v-5A.5.5 0 0012 9zM14.5 9a.5.5 0 00-.5.5v5a.5.5 0 001 0v-5a.5.5 0 00-.5-.5zM13 4.5h-2a.5.5 0 00-.5.5v.25a.25.25 0 00.25.25h2.5a.25.25 0 00.25-.25V5a.5.5 0 00-.5-.5z\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm4.5 14.5A1.5 1.5 0 0115 16H9a1.5 1.5 0 01-1.5-1.5v-6A.5.5 0 018 8h8a.5.5 0 01.5.5zm2-8.25a.75.75 0 01-.75.75H6.25a.75.75 0 010-1.5h3a.25.25 0 00.25-.25V5A1.5 1.5 0 0111 3.5h2A1.5 1.5 0 0114.5 5v.25a.25.25 0 00.25.25h3a.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});