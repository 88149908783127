define("ember-svg-jar/inlined/lab-tube", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lab-tube</title><path d=\"M3.72 17.152c-1.888 2.469-1.888 3.435-1.888 3.753a3.1 3.1 0 106.19 0c0-.318 0-1.284-1.887-3.754a1.565 1.565 0 00-2.415.001zM22.062 3.22A4.145 4.145 0 0016.323.367L6.1 4.961a5.246 5.246 0 01-2.293.458A1.518 1.518 0 002.38 7.56l2.705 6.015a1.517 1.517 0 002.548.355A5.266 5.266 0 019.5 12.518l10.111-4.545a4.206 4.206 0 002.451-4.753zm-3.271 2.929l-.583.262a.5.5 0 01-.661-.251l-.259-.575a1 1 0 10-1.824.82l.258.575a.5.5 0 01-.251.661l-.9.4a.5.5 0 01-.382.012.5.5 0 01-.279-.262l-.287-.64a1 1 0 10-1.824.82l.287.64a.5.5 0 01-.251.661l-1.079.485a.5.5 0 01-.661-.251l-.352-.784a1 1 0 10-1.824.821l.366.814a.5.5 0 01-.21.643 7.284 7.284 0 00-.971.661.5.5 0 01-.77-.185L4.779 8.019a.5.5 0 01.373-.7 7.33 7.33 0 001.769-.535l10.222-4.593a2.142 2.142 0 012.972 1.489 2.234 2.234 0 01-1.324 2.469z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});