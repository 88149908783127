define("ember-svg-jar/inlined/modern-tv-4k", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-tv-4k</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v16A1.5 1.5 0 001.5 19h21a1.5 1.5 0 001.5-1.5v-16A1.5 1.5 0 0022.5 0zM22 14.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5zM14.35 20.681a.5.5 0 00-.385-.181h-3.93a.5.5 0 00-.385.181l-2.1 2.532a.5.5 0 00.41.787h8.079a.5.5 0 00.41-.787z\"/><path d=\"M10.5 4.25A5.756 5.756 0 004.75 10v1a.75.75 0 00.75.75h4a.25.25 0 01.25.25v1a.75.75 0 001.5 0V5a.75.75 0 00-.75-.75zM9.75 10a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25 4.256 4.256 0 013.187-4.115.25.25 0 01.313.242zM18.03 4.47a.749.749 0 00-1.06 0l-2.293 2.293a.25.25 0 01-.427-.177V5a.75.75 0 00-1.5 0v8a.75.75 0 001.5 0v-1.586a.25.25 0 01.427-.177l2.293 2.293a.75.75 0 001.06-1.06l-3.293-3.293a.252.252 0 010-.354L18.03 5.53a.749.749 0 000-1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});