define("@gavant/ember-shopify-draggable/components/sortable-container", ["exports", "@ember/component", "@gavant/ember-shopify-draggable/templates/components/sortable-container", "@ember/object", "@ember/utils", "@ember/array", "@ember/runloop", "@gavant/ember-shopify-draggable/mixins/base-container"], function (_exports, _component, _sortableContainer, _object, _utils, _array, _runloop, _baseContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_baseContainer.default, {
    layout: _sortableContainer.default,
    classNames: ['sortable-container'],
    items: null,
    _dragStop() {
      (0, _runloop.next)(this, () => {
        const items = (0, _object.get)(this, 'items');
        const scheduleAddEvent = (0, _object.get)(this, 'scheduleAdd');
        if (scheduleAddEvent) {
          const element = document.getElementById((0, _object.get)(this, 'scheduleAdd.dragNode.id'));
          element.parentNode.removeChild(element);
          items.insertAt((0, _object.get)(scheduleAddEvent, 'targetIndex'), (0, _object.get)(scheduleAddEvent, 'item'));
          (0, _utils.tryInvoke)(this, 'itemAdded', [items, (0, _object.get)(scheduleAddEvent, 'item'), event]);
          (0, _object.trySet)(this, 'scheduleAdd', null);
        }
        (0, _object.trySet)(this, 'group.dragItem', null);
      });
    },
    _sortableStop(event) {
      if (this.element) {
        const items = (0, _array.A)((0, _object.get)(this, 'items').toArray());
        const targetContainer = (0, _object.get)(event, 'data.newContainer');
        const targetIndex = (0, _object.get)(event, 'data.newIndex');
        const oldContainer = (0, _object.get)(event, 'data.oldContainer');
        const oldIndex = (0, _object.get)(event, 'data.oldIndex');
        const item = (0, _object.get)(this, 'group.dragItem');
        //Sorted within this container
        if (this.element.isSameNode(targetContainer) && this.element.isSameNode(oldContainer)) {
          items.removeAt(oldIndex, 1);
          items.insertAt(targetIndex, item);
          (0, _utils.tryInvoke)(this, 'itemReordered', [items, item, event]);
        } else if (this.element.isSameNode(targetContainer)) {
          // added to this container
          //schedule the update to the items array until after we can remove the dragged node. This gives ember the ability to update correctly
          (0, _object.set)(this, 'scheduleAdd', {
            targetIndex,
            item,
            dragNode: (0, _object.get)(event, 'data.dragEvent.source')
          });
        } else if (this.element.isSameNode(oldContainer)) {
          // removed from this container
          items.removeAt(oldIndex, 1);
          (0, _utils.tryInvoke)(this, 'itemRemoved', [items, item, event]);
        }
      }
    },
    didInsertElement() {
      this._super(...arguments);
      //if the element is inserted into the dom and group sortable already exists, we can just add the container to the sortable group
      this.setupContainer();
    },
    init() {
      this._super(...arguments);
      if ((0, _object.get)(this, 'group')) {
        (0, _object.get)(this, 'group').on('drag:stop', this, '_dragStop');
        (0, _object.get)(this, 'group').on('sortable:stop', this, '_sortableStop');
      }
    },
    willDestroyElement() {
      if ((0, _object.get)(this, 'group')) {
        (0, _object.get)(this, 'group').off('drag:stop', this, '_dragStop');
        (0, _object.get)(this, 'group').off('sortable:stop', this, '_sortableStop');
      }
      this._super(...arguments);
    },
    actions: {
      dragStart(item) {
        (0, _object.trySet)(this, 'group.dragItem', item);
      }
    }
  }).reopenClass({
    positionalParams: ['items']
  });
});