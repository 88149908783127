define("ember-svg-jar/inlined/rabbit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rabbit</title><path d=\"M19.276.1A3.007 3.007 0 0015.6 2.223l-2.13 7.947a6.458 6.458 0 00-2.944 0L8.4 2.223a3 3 0 10-5.8 1.555l2.784 10.378A4.687 4.687 0 005 16v4.25A3.755 3.755 0 008.75 24 3.737 3.737 0 0012 22.3a3.821 3.821 0 007-2.049V16a4.687 4.687 0 00-.384-1.844L21.4 3.778A3 3 0 0019.276.1zM8.25 14.75a1 1 0 111 1 1 1 0 01-1-1zm5.735 3.566l-1.594 1.993a.5.5 0 01-.782 0l-1.594-1.993a.5.5 0 01.39-.812H13.6a.5.5 0 01.39.812zm.765-2.566a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});