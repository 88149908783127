define("ember-svg-jar/inlined/common-file-book", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-book</title><path d=\"M23.475 6.561l-2.857-3.024A1.819 1.819 0 0019.321 3H4.679a1.833 1.833 0 00-1.317.558L.537 6.549A1.817 1.817 0 000 7.845v11.322A1.835 1.835 0 001.833 21h20.334A1.835 1.835 0 0024 19.167V7.845a1.822 1.822 0 00-.525-1.284zM2.136 7.768L4.6 5.157A.5.5 0 014.966 5H10.5a.5.5 0 01.5.5v13a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5V8.111a.5.5 0 01.136-.343zM21.5 19h-8a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5h5.534a.5.5 0 01.364.157l2.466 2.611a.5.5 0 01.136.343V18.5a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});