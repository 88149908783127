define("ember-svg-jar/inlined/e-learning-book-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-learning-book-laptop</title><path d=\"M15.874 5.076a7.474 7.474 0 00-3.687.98.5.5 0 01-.529 0 7.471 7.471 0 00-3.687-.98 1.321 1.321 0 00-.91.345 1.261 1.261 0 00-.388.907v5.3a1.253 1.253 0 001.212 1.247c2.6.106 3.426 1.111 3.427 1.111a.75.75 0 001.214.014c.059-.07.916-1.019 3.436-1.122a1.253 1.253 0 001.211-1.247v-5.3a1.262 1.262 0 00-.389-.907 1.241 1.241 0 00-.91-.348zm-4.7 6.27a.5.5 0 01-.663.473 8.39 8.39 0 00-1.888-.4.5.5 0 01-.449-.5V7.142a.5.5 0 01.567-.5 5.212 5.212 0 012.209.739.5.5 0 01.224.417zm4.5-.422a.5.5 0 01-.449.5 8.388 8.388 0 00-1.889.4.5.5 0 01-.662-.473V7.8a.5.5 0 01.222-.416 5.189 5.189 0 012.211-.741.5.5 0 01.567.5z\"/><path d=\"M23.8 19.554c-.065-.089-1.829-2.5-2.389-3.254a.5.5 0 01-.076-.45 2.007 2.007 0 00.092-.6V3.751a2 2 0 00-2-2h-15a2 2 0 00-2 2v11.5a1.989 1.989 0 00.131.714.5.5 0 01-.062.472c-.589.811-1.675 2.283-2.327 3.176a1.718 1.718 0 00-.019 1.744 1.757 1.757 0 001.546.894h20.612a1.755 1.755 0 001.546-.894 1.737 1.737 0 00-.054-1.803zm-9.373.7h-5a.75.75 0 110-1.5h5a.75.75 0 010 1.5zm4.854-5.146a.5.5 0 01-.353.146h-14a.5.5 0 01-.5-.5V4.251a.5.5 0 01.5-.5h14a.5.5 0 01.5.5v10.5a.5.5 0 01-.151.354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});