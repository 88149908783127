define("ember-svg-jar/inlined/smiley-surprised-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-surprised-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><circle cx=\"8\" cy=\"9\" r=\"2\"/><circle cx=\"16\" cy=\"9\" r=\"2\"/><path d=\"M12 14a5.7 5.7 0 00-4.941 2.852.44.44 0 00.379.659 21.078 21.078 0 019.123 0 .439.439 0 00.38-.659A5.7 5.7 0 0012 14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});