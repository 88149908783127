define("ember-svg-jar/inlined/public-service-police", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>public-service-police</title><path d=\"M23.639 15.583L22.1 13.788a.251.251 0 01.19-.413H23a.5.5 0 00.5-.5v-1.5a.5.5 0 00-.5-.5h-2.637a.25.25 0 01-.228-.148l-1.229-2.749a1.5 1.5 0 00-.858-.861.249.249 0 01-.154-.179A4.324 4.324 0 0014 3.375h-4a4.282 4.282 0 00-3.845 3.4.251.251 0 01-.173.178 1.812 1.812 0 00-1.165 1.039l-1.053 2.723a.25.25 0 01-.233.16H1a.5.5 0 00-.5.5v1.5a.5.5 0 00.5.5h.711a.251.251 0 01.19.413L.364 15.58a1.5 1.5 0 00-.364.98v1.815a2.993 2.993 0 001.847 2.756.251.251 0 01.153.231v1.513a1 1 0 001 1h2a1 1 0 001-1v-1.25a.249.249 0 01.25-.25h11.5a.249.249 0 01.25.25v1.25a1 1 0 001 1h2a1 1 0 001-1v-1.513a.251.251 0 01.153-.231A2.992 2.992 0 0024 18.375V16.56a1.5 1.5 0 00-.361-.977zM13 5.625a.25.25 0 01.25-.25H14A2.009 2.009 0 0115.6 6.5a.25.25 0 01-.216.376H13.25a.25.25 0 01-.25-.25zM8.4 6.5A2.009 2.009 0 0110 5.375h.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H8.615A.251.251 0 018.4 6.5zm-2.809 5.034l.965-2.494a.248.248 0 01.236-.16l10.223.126a.25.25 0 01.226.147l1.059 2.369a.251.251 0 01-.228.353H5.824a.251.251 0 01-.233-.341zm.692 7.735a.251.251 0 01-.2.106H3.25A1.252 1.252 0 012 18.125v-1a.249.249 0 01.25-.25h1a3.254 3.254 0 013.064 2.166.251.251 0 01-.031.228zm9.217-.144a.25.25 0 01-.25.25h-6.5a.25.25 0 01-.25-.25v-.75a1.5 1.5 0 011.5-1.5h4a1.5 1.5 0 011.5 1.5zm6.5-1a1.252 1.252 0 01-1.25 1.25h-2.829a.25.25 0 01-.235-.334 3.254 3.254 0 013.064-2.166h1a.249.249 0 01.25.25zM12 2.625a.75.75 0 00.75-.75v-1a.75.75 0 00-1.5 0v1a.75.75 0 00.75.75zM15.335 2.546l1-.5A.75.75 0 0015.665.7l-1 .5a.75.75 0 00.67 1.342zM7.665 2.046l1 .5a.75.75 0 00.67-1.346l-1-.5a.75.75 0 00-.67 1.342z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});