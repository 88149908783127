define("ember-svg-jar/inlined/certified-certificate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>certified-certificate</title><path d=\"M23.875 3.5a3.5 3.5 0 00-3.5-3.5h-9.5a1 1 0 000 2h6.338a4.23 4.23 0 00-.338 1.5v17a1.5 1.5 0 11-1.5-1.5 1 1 0 000-2h-9.5v-1.5a1 1 0 00-2 0v1.663a3.49 3.49 0 001 6.837h10.5a3.5 3.5 0 003.5-3.5V6.648a3.451 3.451 0 001.5.352 3.5 3.5 0 003.5-3.5zm-11.8 18.166a.25.25 0 01-.032.228.247.247 0 01-.2.106H4.875a1.5 1.5 0 010-3h6.964a.247.247 0 01.2.106.25.25 0 01.032.228 3.5 3.5 0 000 2.332zM20.375 5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/><path d=\"M2.375 9.169V13a.75.75 0 001.2.6l1.55-1.162 1.55 1.162a.75.75 0 001.2-.6V9.169a5 5 0 10-5.5 0zM5.125 1.5a3.5 3.5 0 11-3.5 3.5 3.5 3.5 0 013.5-3.5z\"/><circle cx=\"5.125\" cy=\"5\" r=\"1.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});