define("ember-svg-jar/inlined/car-retro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-retro</title><path d=\"M5.1 9.324a2.034 2.034 0 011.28.483.249.249 0 00.245.036.252.252 0 00.156-.192l.245-1.477a5 5 0 019.955.065l.236 1.414a.251.251 0 00.4.156 2.006 2.006 0 011.283-.482.25.25 0 00.248-.292l-.18-1.064a7 7 0 00-13.921-.062l-.188 1.124a.25.25 0 00.055.2.247.247 0 00.186.091zM16.324 11.166a.251.251 0 00-.171-.149 16.346 16.346 0 00-8.285.031.251.251 0 00-.15.374 33.9 33.9 0 012.772 6.5.528.528 0 01.021.1.25.25 0 00.248.223h2.486a.249.249 0 00.248-.223.528.528 0 01.021-.1 33.875 33.875 0 012.79-6.525.251.251 0 00.02-.231zM5.816 10.627l-.016-.013a1.224 1.224 0 00-.7-.291c-4.824 0-4.646 4.134-4.035 6.423a1.938 1.938 0 001.491 1.4.249.249 0 01.2.244v2.93a1 1 0 001 1H6a1 1 0 001-1v-2.854a.25.25 0 01.25-.25h1.942a.249.249 0 00.237-.328C9 16.6 7.367 11.886 5.816 10.627zM3.9 16.106a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM18.908 10.323a1.227 1.227 0 00-.7.291l-.018.013c-1.553 1.259-3.19 5.973-3.615 7.261a.249.249 0 00.237.328h1.939a.25.25 0 01.25.25v2.856a1 1 0 001 1h2.249a1 1 0 001-1v-2.929a.251.251 0 01.2-.245 1.945 1.945 0 001.5-1.4c.595-2.291.782-6.425-4.042-6.425zm1 5.783a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.508 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});