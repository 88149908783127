define("ember-svg-jar/inlined/image-document-gif", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-document-gif</title><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM10.125 11a.625.625 0 01-1.25 0v-.5a.875.875 0 00-1.75 0v3a.875.875 0 001.664.379c.038-.079.093-.254-.113-.254H8.5a.625.625 0 010-1.25h1a.625.625 0 01.625.625v.5a2.125 2.125 0 01-4.25 0v-3a2.125 2.125 0 014.25 0zm3.25 3.375h.125a.625.625 0 010 1.25h-2a.625.625 0 010-1.25h.125a.25.25 0 00.25-.25v-4.25a.25.25 0 00-.25-.25H11.5a.625.625 0 010-1.25h2a.625.625 0 010 1.25h-.125a.25.25 0 00-.25.25v4.25a.25.25 0 00.25.25zm5.125-4.75h-2a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25H17.5a.625.625 0 010 1.25h-1.125a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0v-5A1.626 1.626 0 0116.5 8.375h2a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});