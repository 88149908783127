define("ember-svg-jar/inlined/currency-dollar-increase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-dollar-increase</title><path d=\"M14 7a7 7 0 10-7 7 7.009 7.009 0 007-7zm-7.75 4.25A.25.25 0 006 11h-.625a.75.75 0 010-1.5h2.2a.7.7 0 00.261-1.356L5.6 7.249a2.2 2.2 0 01.439-4.206.248.248 0 00.211-.243v-.4a.75.75 0 011.5 0v.35A.25.25 0 008 3h.625a.75.75 0 010 1.5h-2.2a.7.7 0 00-.261 1.356l2.235.9a2.2 2.2 0 01-.439 4.206.248.248 0 00-.207.246v.4a.75.75 0 01-1.5 0zM24 11a.5.5 0 00-.5-.5h-5a.5.5 0 00-.354.854l1.415 1.414a.251.251 0 01-.007.361l-4.583 4.225a.249.249 0 01-.325.012l-2.9-2.317a2.154 2.154 0 00-2.771.071l-.219.194-7.314 6.5a1.25 1.25 0 001.66 1.87l7.155-6.36a.251.251 0 01.323-.009l2.9 2.324a2.164 2.164 0 002.805-.1l5.047-4.653a.251.251 0 01.347.007l1.461 1.462A.5.5 0 0024 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});