define("ember-svg-jar/inlined/video-file-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-search</title><path d=\"M10.168 19.771a.242.242 0 01-.03-.3 5.515 5.515 0 10-1.688 1.683.242.242 0 01.3.031l2.51 2.505a1 1 0 001.414-1.414zm-4.668.246a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5zM12.42 13.917a.889.889 0 00.834-.009l5.846-3.236a.735.735 0 000-1.311l-5.846-3.236a.889.889 0 00-.834-.009.756.756 0 00-.42.665v6.471a.756.756 0 00.42.665z\"/><path d=\"M23.415 3.017L21 .6a2 2 0 00-1.414-.583H8a2 2 0 00-2 2v7.3a.243.243 0 00.222.241 6.991 6.991 0 011.453.309A.242.242 0 008 9.641V2.267a.249.249 0 01.25-.25h11.232a.249.249 0 01.177.073l2.268 2.267a.251.251 0 01.073.177v13.733a.25.25 0 01-.25.25h-9.368a.241.241 0 00-.23.169 6.927 6.927 0 01-.417 1.009.3.3 0 00.071.3l.384.383a.475.475 0 00.334.14H22a2 2 0 002-2V4.431a2 2 0 00-.585-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});