define("ember-svg-jar/inlined/medical-instrument-scalpel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-instrument-scalpel</title><path d=\"M23.557 1.05a2.5 2.5 0 00-3.806-.319L9.61 10.881a1 1 0 000 1.414l2.121 2.121a1 1 0 001.415 0L23.211 4.342a2.577 2.577 0 00.346-3.292zM8.738 13.853a.5.5 0 00-.707 0L.294 21.59a1 1 0 00.135 1.529A4.751 4.751 0 003.091 24a4.941 4.941 0 003.526-1.662A5.127 5.127 0 007.824 18.8c0-1.76 2.151-2.713 2.173-2.723a.5.5 0 00.155-.812z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});