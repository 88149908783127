define("ember-svg-jar/inlined/data-file-cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-cash</title><path d=\"M14.258 20.544h7.734a2 2 0 002-2V4.459a2 2 0 00-.586-1.415L20.992.63a2 2 0 00-1.414-.586H7.992a2 2 0 00-2 2v7.275a.249.249 0 00.243.25c.365.007 1.037.034 1.484.082a.245.245 0 00.273-.251V2.544a.5.5 0 01.5-.5h10.879a.5.5 0 01.354.146l2.121 2.122a.5.5 0 01.146.353v13.379a.5.5 0 01-.5.5h-6.858a.245.245 0 00-.241.21 7.856 7.856 0 01-.364 1.458.244.244 0 00.229.332z\"/><path d=\"M14.26 9.741l1.508.9a1 1 0 001.22-.149 1.165 1.165 0 00.106-.125l2.687-3.734a1 1 0 10-1.623-1.163l-2.016 2.8a.252.252 0 01-.332.068l-1.51-.906a1 1 0 00-1.331.279L10.923 10.6a.242.242 0 00.065.343 8.137 8.137 0 011.128.933.244.244 0 00.374-.029l1.438-2.03a.25.25 0 01.332-.076zM.008 17.457a6.5 6.5 0 106.5-6.5 6.508 6.508 0 00-6.5 6.5zM5.6 13.76l-.006-.008a.191.191 0 00.165-.2v-.35a.75.75 0 011.5 0v.335a.17.17 0 00.169.165h.581a.75.75 0 010 1.5H5.974a.592.592 0 00-.219 1.141l2.062.826a2.078 2.078 0 01-.417 3.989.184.184 0 00-.137.2v.353a.75.75 0 01-1.5 0v-.334a.17.17 0 00-.164-.166h-.591a.75.75 0 110-1.5h2.033a.592.592 0 00.22-1.141L5.2 17.74a2.079 2.079 0 01.4-3.98z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});