define("ember-svg-jar/inlined/people-man-glasses-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-glasses-2</title><path d=\"M13.813 13.2a.749.749 0 00-1.021-.287 1.138 1.138 0 01-.22.094 1.867 1.867 0 01-.544.079 1.9 1.9 0 01-.55-.081 1.131 1.131 0 01-.222-.094.744.744 0 00-.982 1.067.725.725 0 00.251.244c.074.041.16.067.239.1a2.662 2.662 0 00.263.112 3.124 3.124 0 001.005.15 3.344 3.344 0 00.961-.141.3.3 0 00.032-.008 2.6 2.6 0 00.5-.216.779.779 0 00.287-1.021z\"/><path d=\"M6.149 12.405a.25.25 0 01.144.187c.511 3.047 4.021 4.991 5.686 4.991s5.176-1.944 5.687-4.989a.252.252 0 01.145-.189 2.732 2.732 0 001.439-2.434 2.114 2.114 0 00-.465-1.443c.388-2.76-.041-4.9-1.275-6.354C16.334.786 14.474.083 11.979.083S7.626.786 6.45 2.174C5.216 3.631 4.787 5.767 5.174 8.528a2.112 2.112 0 00-.464 1.444 2.73 2.73 0 001.439 2.433zm8.693-2.77a2.444 2.444 0 01-2.181-1.257.753.753 0 00-.667-.406.749.749 0 00-.666.408 2.1 2.1 0 01-.448.6 2.6 2.6 0 01-3.452 0A2.285 2.285 0 016.8 6.792a.249.249 0 01.247-.209h9.869a.248.248 0 01.245.2 3.781 3.781 0 01.091.705 2.29 2.29 0 01-2.41 2.147zm2.349 1.4a1.75 1.75 0 00-1 1.306c-.378 2.249-3.176 3.739-4.209 3.739s-3.83-1.49-4.207-3.738a1.749 1.749 0 00-1-1.307.821.821 0 01-.312-.287.25.25 0 01.348-.348A4.186 4.186 0 0012 9.987a4 4 0 002.842 1.148 4.19 4.19 0 00.517-.048 4.033 4.033 0 001.787-.672.252.252 0 01.32.031.249.249 0 01.025.32.8.8 0 01-.3.272zM7.018 5.083a.251.251 0 01-.242-.316A4.192 4.192 0 017.6 3.143c.878-1.035 2.352-1.56 4.384-1.56s3.508.525 4.386 1.561a4.172 4.172 0 01.818 1.623.25.25 0 01-.241.316z\"/><path d=\"M17.282 17.359a.5.5 0 00-.624.293 5 5 0 01-9.316 0 .5.5 0 00-.624-.293l-1.647.391a7.117 7.117 0 00-3.03 1.122A4.46 4.46 0 00.25 22.417v.75a.75.75 0 001.5 0v-.75a2.95 2.95 0 011.189-2.344 4.554 4.554 0 011.755-.709.25.25 0 01.306.244v3.809a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-3.809a.25.25 0 01.306-.244 4.554 4.554 0 011.755.709 2.95 2.95 0 011.189 2.344v.75a.75.75 0 001.5 0v-.75a4.46 4.46 0 00-1.791-3.545 7.117 7.117 0 00-3.03-1.122z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});