define("ember-svg-jar/inlined/folder-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-share</title><path d=\"M15.005 14.194a.24.24 0 00.2-.021l1.763-1.058a.236.236 0 00.11-.162 4.25 4.25 0 013.7-3.411A.246.246 0 0021 9.3V4.5A1.5 1.5 0 0019.5 3H9.618a.5.5 0 01-.447-.276L8.223.829A1.5 1.5 0 006.882 0H1.5A1.5 1.5 0 000 1.5v15A1.5 1.5 0 001.5 18h7.8a.245.245 0 00.242-.223A4.243 4.243 0 0113.75 14a4.154 4.154 0 011.255.194z\"/><path d=\"M22.129 18.639a2.74 2.74 0 00-2.767.623.24.24 0 01-.258.053L16.553 18.3a.114.114 0 01-.06-.084l-.03-.353a.118.118 0 01.056-.1L19.442 16a.245.245 0 01.266.012 2.741 2.741 0 004.168-1.425A2.759 2.759 0 0021.25 11a2.808 2.808 0 00-2.713 3.059.243.243 0 01-.115.221L15.558 16a.245.245 0 01-.266-.012 2.741 2.741 0 00-4.168 1.426A2.759 2.759 0 0013.75 21a2.729 2.729 0 001.887-.761.245.245 0 01.26-.053l2.463 1a.239.239 0 01.144.214 2.753 2.753 0 105.361-1.025 2.681 2.681 0 00-1.736-1.736z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});