define("ember-svg-jar/inlined/navigation-right-circle-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-right-circle-1-alternate</title><path d=\"M0 12A12 12 0 1012 0 12.014 12.014 0 000 12zm2 0a10 10 0 1110 10A10.011 10.011 0 012 12z\"/><path d=\"M8.5 9.5h5.25a.249.249 0 00.25-.25V8a1 1 0 011.7-.7l3 3a1 1 0 010 1.414l-3 3A1 1 0 0114 14v-1.25a.249.249 0 00-.25-.25H8.5a1 1 0 000 2 1.5 1.5 0 110 3 4 4 0 010-8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});