define("ember-svg-jar/inlined/project-book-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>project-book-building</title><path d=\"M22.625 6a1 1 0 00-1-1h-15.5a1.5 1.5 0 010-3h15.5a1 1 0 000-2h-15.5a3.5 3.5 0 00-3.5 3.5v3a.5.5 0 01-.5.5.75.75 0 000 1.5h2.75a.5.5 0 010 1h-1.75a.5.5 0 00-.5.5v2a.5.5 0 01-.5.5.75.75 0 000 1.5h2.75a.5.5 0 010 1h-1.75a.5.5 0 00-.5.5v2a.5.5 0 01-.5.5.75.75 0 000 1.5h2.75a.5.5 0 010 1h-1.75a.5.5 0 00-.5.5 3 3 0 003 3h16a1 1 0 001-1zm-5.75 13.25a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5v-1a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v1a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5v-7.5a.5.5 0 01.276-.447l3-1.5a.5.5 0 01.448 0l3 1.5a.5.5 0 01.276.447z\"/><path d=\"M14.875 12.25h-3a.5.5 0 000 1h3a.5.5 0 000-1zM14.875 14.75h-3a.5.5 0 000 1h3a.5.5 0 000-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});