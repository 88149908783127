define("ember-svg-jar/inlined/single-man-actions-coding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-coding</title><path d=\"M12.543 11.233a.25.25 0 00-.057-.426l-1.334-.572a.249.249 0 01-.152-.229V8.87a.252.252 0 01.074-.177 3.311 3.311 0 00.779-2.231.251.251 0 01.088-.176 1.873 1.873 0 00.538-1.4 1.58 1.58 0 00-.317-1.015.251.251 0 01-.034-.264 2.531 2.531 0 00.157-1.727C11.944.493 10.242 0 8.888 0a3.752 3.752 0 00-3.163 1.324.251.251 0 01-.212.118 1.072 1.072 0 00-.9.375c-.372.493-.106 1.17.176 1.732a.251.251 0 01-.046.288 1.3 1.3 0 00-.162.2A1.763 1.763 0 004.32 5a1.521 1.521 0 00.638 1.331.249.249 0 01.117.194A3.167 3.167 0 005.92 8.7a.25.25 0 01.08.183v1.123a.248.248 0 01-.151.229l-3.937 1.687C.64 12.468.457 13.626.205 15.23c-.055.359-.117.751-.2 1.179A.5.5 0 00.5 17h8.793a.249.249 0 00.249-.227 7.986 7.986 0 013.001-5.54z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-4.28 5.97l2-2a.75.75 0 111.061 1.06l-1.293 1.293a.25.25 0 000 .354l1.292 1.293a.75.75 0 11-1.061 1.06l-2-2a.749.749 0 010-1.06zm6.561 3.06a.75.75 0 01-1.061-1.06l1.293-1.293a.25.25 0 000-.354l-1.294-1.293a.75.75 0 111.061-1.06l2 2a.749.749 0 010 1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});