define("ember-svg-jar/inlined/beer-glass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beer-glass</title><path d=\"M17 1.965a.247.247 0 01.187.084.252.252 0 01.062.2L17 4.28a.25.25 0 01-.248.22h-9.5a.25.25 0 01-.248-.22L6.76 2.245a.247.247 0 01.061-.2.25.25 0 01.187-.084zM18.966.49a1.508 1.508 0 00-1.11-.49H6.155a1.535 1.535 0 00-1.149.53 1.416 1.416 0 00-.321 1.15c.691 5.4-.179-.53 2.881 18.23a.99.99 0 00.98.84h6.92a.988.988 0 00.979-.83c3.011-18.45 2.181-12.76 2.871-18.21a1.5 1.5 0 00-.35-1.22zM12.006 12a2.5 2.5 0 112.5-2.5 2.5 2.5 0 01-2.5 2.5zM18.005 22h-12a1 1 0 100 2h12a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});