define("ember-svg-jar/inlined/gardening-seed-bag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gardening-seed-bag</title><path d=\"M1.137 21.727a1.137 1.137 0 101.137 1.136 1.137 1.137 0 00-1.137-1.136zM4.684 21.727a1.137 1.137 0 101.138 1.136 1.137 1.137 0 00-1.138-1.136zM8.232 21.727a1.137 1.137 0 101.137 1.136 1.137 1.137 0 00-1.137-1.136zM4.639 19.907A1.137 1.137 0 103.5 21.044a1.138 1.138 0 001.139-1.137zM8.186 19.907a1.137 1.137 0 10-1.136 1.137 1.138 1.138 0 001.136-1.137zM7 16.95a1.137 1.137 0 10-1.137 1.138A1.138 1.138 0 007 16.95zM23.908 11.836L19.925.984a1.5 1.5 0 00-2.191-.763l-1.046.651L4.015 5.523a1.5 1.5 0 00-.892 1.925l2.24 6.1a1 1 0 001.283.595l.47-.173a.5.5 0 01.641.3l.345.939a1 1 0 001.283.594l.939-.344a.5.5 0 01.641.3l.345.939a1 1 0 001.283.594l8.824-3.249 1.313-.2a1.5 1.5 0 001.178-2zm-7.1-.915a3.234 3.234 0 01-2.907.813 2.068 2.068 0 01-.681-.37.5.5 0 01-.088-.686 6.893 6.893 0 012.843-2.47.5.5 0 00-.336-.942c-2.105.744-4.03 3.548-4.951 5.569a.5.5 0 01-.915-.405 17.247 17.247 0 011.754-2.961.5.5 0 00.024-.56 2.175 2.175 0 01-.342-1.426 2.734 2.734 0 011.622-2.006 6.965 6.965 0 012.349-.614 6.5 6.5 0 002.654-.778.5.5 0 01.727.279 7.3 7.3 0 01-1.749 6.557z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});