define("ember-svg-jar/inlined/bathroom-shower-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bathroom-shower-person</title><path d=\"M5.251 3.256a1 1 0 00-.235-1.593 2.492 2.492 0 00-1.826-.157L1.635.228a1 1 0 10-1.27 1.545l1.281 1.052a2.5 2.5 0 00.016 2.19 1 1 0 001.594.236zM8.725 8.182a1 1 0 100 1.414 1 1 0 000-1.414zM6.25 12.071a1 1 0 10-1.414 0 1 1 0 001.414 0zM5.543 14.293a1 1 0 101.414 0 1 1 0 00-1.414 0zM4.836 6.414a1 1 0 100 1.415 1 1 0 000-1.415zM5.543 5.707a1 1 0 100-1.414 1 1 0 000 1.414zM9.432 7.475a1 1 0 100-1.414 1 1 0 000 1.414zM13.321 5a1 1 0 10-1.414 0 1 1 0 001.414 0zM16.957 5.707a1 1 0 10-1.414 0 1 1 0 001.414 0zM9.078 3.586a1 1 0 10-1.414 0 1 1 0 001.414 0zM22.747 7.521a1.5 1.5 0 00-1.727 1.232 5.663 5.663 0 01-5.6 4.747H11.5a3 3 0 000 6H13a1.5 1.5 0 000-3h-1.5a.5.5 0 010-1H13a2.5 2.5 0 010 5h-1.25a.25.25 0 00-.25.25v3a.25.25 0 00.25.25h5.5a.25.25 0 00.25-.25v-7.313a.251.251 0 01.185-.242 8.643 8.643 0 006.294-6.948 1.5 1.5 0 00-1.232-1.726z\"/><circle cx=\"14.5\" cy=\"10\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});