define("ember-svg-jar/inlined/chair-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chair-3</title><path d=\"M20 14.5a1 1 0 00-1-1h-.592a.5.5 0 01-.5-.45l-.86-8.6a.5.5 0 01.315-.516A1 1 0 0017 2h-2.551a.5.5 0 01-.489-.4 2 2 0 00-3.92 0 .5.5 0 01-.489.4H7a1 1 0 00-.365 1.93.5.5 0 01.315.516l-.86 8.6a.5.5 0 01-.5.45H5a1 1 0 000 2h.587a.249.249 0 01.246.3l-1.316 7.019a1 1 0 00.8 1.167A.974.974 0 005.5 24a1 1 0 00.982-.815L7 20.408A.5.5 0 017.5 20h9.01a.5.5 0 01.491.408l.521 2.777A1 1 0 0018.5 24a.974.974 0 00.185-.018 1 1 0 00.8-1.167L18.167 15.8a.249.249 0 01.246-.3H19a1 1 0 001-1zM14.651 4a.38.38 0 01.378.342l.861 8.608a.5.5 0 01-.5.55h-.19a.5.5 0 01-.5-.475L14.271 4.4a.38.38 0 01.38-.4zm-2.912.249a.261.261 0 01.522 0l.437 8.726a.5.5 0 01-.5.525h-.4a.5.5 0 01-.5-.525zm-2.768.093A.38.38 0 019.349 4a.38.38 0 01.38.4L9.3 13.025a.5.5 0 01-.5.475h-.19a.5.5 0 01-.5-.55zM7.756 18a.249.249 0 01-.245-.3l.336-1.8a.5.5 0 01.492-.408h7.322a.5.5 0 01.492.408l.336 1.8a.249.249 0 01-.245.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});