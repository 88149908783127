define("ember-svg-jar/inlined/laptop-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-remove</title><path d=\"M9.594 15.238a2.428 2.428 0 01-1.543-1.387.492.492 0 00-.47-.36H.5a.478.478 0 00-.41.22.467.467 0 00-.05.47 5.43 5.43 0 005 3.31h4.215a.249.249 0 00.249-.242 7.839 7.839 0 01.245-1.714.25.25 0 00-.155-.297zM3.5 12a1 1 0 001-1V3a1 1 0 011-1h12a1 1 0 011 1v6.37a.218.218 0 00.185.216 7.829 7.829 0 011.48.375.251.251 0 00.335-.236V3a3.009 3.009 0 00-3-3h-12a3.009 3.009 0 00-3 3v8a1 1 0 001 1zM17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm1.591 6.676l1.237 1.238a1 1 0 11-1.414 1.414l-1.238-1.238a.25.25 0 00-.353 0l-1.237 1.238a1 1 0 11-1.414-1.414l1.237-1.238a.25.25 0 000-.353l-1.237-1.237a1 1 0 111.414-1.414l1.237 1.237a.249.249 0 00.353 0l1.238-1.237a1 1 0 111.414 1.414l-1.237 1.237a.249.249 0 00.001.354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});