define("ember-svg-jar/inlined/hammer-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hammer-1</title><path d=\"M9.738 10.3a.516.516 0 00-.707 0L.768 18.561a2.486 2.486 0 00-.733 1.767 2.564 2.564 0 002.5 2.5A2.48 2.48 0 004.3 22.1l8.263-8.263a.5.5 0 000-.707zM23.525 9.237l-1.06-1.061a1 1 0 00-1.414 0 1.022 1.022 0 01-1.414 0 1 1 0 01-.224-1.076 1.5 1.5 0 00-.335-1.6c-1.432-1.438-2.007-2.015-2.293-2.246h.007A7.745 7.745 0 006.761 2.8a.994.994 0 00-.348 1.137A1.013 1.013 0 007.4 4.6a5.018 5.018 0 013.752 1.455c.517.518.779.917.779 1.186a.413.413 0 01-.151.308l-.982.981a.5.5 0 000 .707l2.828 2.828a.5.5 0 00.707 0l1.061-1.061a1.022 1.022 0 011.413 0 1 1 0 010 1.414 1 1 0 000 1.414l1.061 1.068a1.5 1.5 0 002.121 0l3.536-3.536a1.5 1.5 0 000-2.121z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});