define("ember-svg-jar/inlined/phone-action-image-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-image-1</title><path d=\"M12 15.75v2.75a1 1 0 01-1 1H4a1 1 0 01-1-1v-11a1 1 0 011-1h3.75A.25.25 0 008 6.25v-2A.25.25 0 007.75 4H3.5A2.5 2.5 0 001 6.5v15A2.5 2.5 0 003.5 24h8a2.5 2.5 0 002.5-2.5v-5.75a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25z\"/><path d=\"M21 0h-9.5a2 2 0 00-2 2v9.5a2 2 0 002 2H21a2 2 0 002-2V2a2 2 0 00-2-2zm-.725 6.386a1.247 1.247 0 00-1.026-.556 1.209 1.209 0 00-1.038.533l-1.69 2.413-.82-.655a1.249 1.249 0 00-1.821.279l-2.064 3.1H11.5V2.25a.25.25 0 01.25-.25h9a.25.25 0 01.25.25v5.224z\"/><circle cx=\"14.5\" cy=\"4.5\" r=\"1.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});