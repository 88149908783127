define("ember-svg-jar/inlined/safety-danger-electricity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-danger-electricity</title><path d=\"M11.653 10.4L15.5 9.3a.248.248 0 01.259.08l2.289 2.76a.987.987 0 00.761.358 1 1 0 00.276-.039.993.993 0 00.706-.823l.577-4.454a.99.99 0 00-1.968-.258l-.211 1.635a.247.247 0 01-.436.126l-.99-1.194a1 1 0 00-1.033-.32l-2.267.649a.248.248 0 01-.3-.324l.467-1.254a1 1 0 00-.119-.917 1.058 1.058 0 00-.824-.418l-2.5.037a.1.1 0 01-.1-.067.1.1 0 01.03-.114.99.99 0 10-1.264-1.525L6.462 5.217a.989.989 0 00.631 1.752h.015l3.8-.056a.25.25 0 01.206.1.246.246 0 01.029.229L10.454 9.1a.99.99 0 001.2 1.3zM8.983 11.032a2.5 2.5 0 00-1.83.491 2.474 2.474 0 001.185 4.415 2.532 2.532 0 00.324.022 2.475 2.475 0 00.321-4.928z\"/><path d=\"M2.227 23.845a1.48 1.48 0 001.05-.435l1.688-1.688a3.414 3.414 0 011.179-.768.248.248 0 01.336.258l-.173 1.566a.989.989 0 00.983 1.1h3.21a.988.988 0 00.984-1.093l-.262-2.485a.248.248 0 01.207-.27l.716-.115A4.663 4.663 0 0014.88 18.5a4.438 4.438 0 001.1-3.812l-.447-2.461a1.488 1.488 0 00-1.461-1.219 1.5 1.5 0 00-.594.124 1.547 1.547 0 00-.851 1.71l.432 2.378a1.483 1.483 0 01-1.224 1.731l-5.061.817a6.458 6.458 0 00-3.908 1.852l-1.613 1.615a1.559 1.559 0 00-.16 2.084 1.48 1.48 0 001.134.526zM.99 2.1h14.1a.248.248 0 01.247.248v1.24a1.486 1.486 0 001.485 1.484h6.188a.99.99 0 100-1.979h-5.443a.247.247 0 01-.247-.248V1.608A1.486 1.486 0 0015.835.124H.99a.99.99 0 100 1.976z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});