define("ember-svg-jar/inlined/railroad-cargo-train", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>railroad-cargo-train</title><path d=\"M6.8 14.881a.574.574 0 00-.436-.193 1.672 1.672 0 00-1.5 1.832v1.217c0 .08.1.152.237.127l3.279-.594a.247.247 0 00.111-.439 5.8 5.8 0 01-1.691-1.95zM18.867 17.567V16.52a1.672 1.672 0 00-1.5-1.832.576.576 0 00-.483.279 5.808 5.808 0 01-1.6 1.833.248.248 0 00.113.438s2.939.53 3.149.57.321-.075.321-.241zM5.6 6.591a9.979 9.979 0 011.429-.9c.112-.058.338-.089.338.216v2.065a.247.247 0 00.419.18 5.732 5.732 0 018.162 0 .247.247 0 00.419-.18V5.761a.212.212 0 01.313-.208A10.3 10.3 0 0118.4 6.62a1.055 1.055 0 001.4-.18 1 1 0 00-.18-1.4 12.509 12.509 0 00-5.6-2.441.425.425 0 01-.322-.4c-.015-.109.194-.2.194-.2h.593a1 1 0 100-2h-5a1 1 0 100 2h.669s.228.124.175.262a.521.521 0 01-.379.338 12.521 12.521 0 00-5.567 2.406 1 1 0 00-.186 1.4 1.021 1.021 0 001.403.186z\"/><path d=\"M11.867 7.944a4.247 4.247 0 104.25 4.246 4.253 4.253 0 00-4.25-4.246zm0 5.745a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM7.823 18.894l-2.92.529a1.007 1.007 0 00-.67.446.937.937 0 00-.087.794l.77 1.807a.983.983 0 00.822.7l2.095.292a.25.25 0 00.284-.248V19.14a.25.25 0 00-.294-.246zM13.913 18.491l-1.452-.262a2.687 2.687 0 00-.963 0l-1.675.3a.25.25 0 00-.206.246v4.715a.251.251 0 00.216.248l1.775.247a5.788 5.788 0 00.745 0l1.556-.217a.249.249 0 00.215-.248l-.006-4.786a.251.251 0 00-.205-.243zM19.054 19.423l-3.141-.569a.25.25 0 00-.294.246l.005 4.145a.25.25 0 00.284.247l2.312-.322a.954.954 0 00.8-.637l.815-1.932a.913.913 0 00-.109-.732 1.008 1.008 0 00-.672-.446z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});