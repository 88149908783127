define("ember-svg-jar/inlined/single-woman-actions-block.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-block</title><path d=\"M23.832 16A6.42 6.42 0 0019 11.169 6.513 6.513 0 0011.168 19 6.421 6.421 0 0016 23.832a6.773 6.773 0 001.49.168 6.5 6.5 0 006.342-8zm-9.513-1.684a4.627 4.627 0 014.954-.964.5.5 0 01.151.81l-5.244 5.246a.5.5 0 01-.817-.167 4.366 4.366 0 01.956-4.923zm6.363 6.366a4.64 4.64 0 01-4.956.965.5.5 0 01-.149-.81l5.262-5.262a.5.5 0 01.81.15 4.625 4.625 0 01-.967 4.959zM10.655 12.376a7.368 7.368 0 00-3.448-.876 7.543 7.543 0 00-7.185 5.358.5.5 0 00.478.642h8.516a.5.5 0 00.5-.47 7.93 7.93 0 011.321-3.93.5.5 0 00-.182-.72zM1.568 10.442a1 1 0 001.383-.3 5.233 5.233 0 00.454-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .249.249 0 01.411.082 5.233 5.233 0 00.454.9 1 1 0 001.681-1.082 4.561 4.561 0 01-.643-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248A4.573 4.573 0 011.27 9.06a1 1 0 00.298 1.382zM7.21 8.6a2.706 2.706 0 01-2.742-2.352.5.5 0 01.324-.577 5.708 5.708 0 002.246-1.439.249.249 0 01.36 0 5.719 5.719 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.21 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});