define("ember-svg-jar/inlined/tools-crescent-double", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-crescent-double</title><path d=\"M24 4a3.991 3.991 0 00-7.781-1.257.5.5 0 01-.334.322l-.777.228a5.522 5.522 0 00-3.781 3.931l-2.1 8.324a2.509 2.509 0 01-1.719 1.786l-.136.04a.5.5 0 01-.5-.134A3.955 3.955 0 004 16a4 4 0 103.93 4.634.5.5 0 01.353-.4l.069-.02a5.524 5.524 0 003.782-3.931l2.1-8.324a2.509 2.509 0 011.719-1.786L16.2 6.1a.5.5 0 01.55.192A3.985 3.985 0 0024 4zM4 21.5A1.5 1.5 0 115.5 20 1.5 1.5 0 014 21.5zM20 5.8A1.8 1.8 0 1121.8 4 1.8 1.8 0 0120 5.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});