define("ember-svg-jar/inlined/archive-drawer-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>archive-drawer-table</title><path d=\"M13.5 7.75a.75.75 0 000-1.5h-3a.75.75 0 000 1.5zM13.5 15.75a.75.75 0 000-1.5h-3a.75.75 0 000 1.5z\"/><path d=\"M23 1H1a1 1 0 000 2h1v15.5a2 2 0 002 2h.5V22a1 1 0 002 0v-1.5h11V22a1 1 0 002 0v-1.5h.5a2 2 0 002-2V3h1a1 1 0 000-2zM5 16.5V14a1.5 1.5 0 011.5-1.5h11A1.5 1.5 0 0119 14v2.5a1.5 1.5 0 01-1.5 1.5h-11A1.5 1.5 0 015 16.5zm0-8V6a1.5 1.5 0 011.5-1.5h11A1.5 1.5 0 0119 6v2.5a1.5 1.5 0 01-1.5 1.5h-11A1.5 1.5 0 015 8.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});