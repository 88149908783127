define("ember-svg-jar/inlined/receipt-register-print-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>receipt-register-print-1</title><path d=\"M.25 19.375a.25.25 0 00-.25.25v2.75a1.5 1.5 0 001.5 1.5h21a1.5 1.5 0 001.5-1.5v-2.75a.25.25 0 00-.25-.25zm10.25 1.75h3a.75.75 0 010 1.5h-3a.75.75 0 010-1.5zM23.651 18.375a.25.25 0 00.231-.347 1.71 1.71 0 00-.04-.088l-2.816-5.63A.256.256 0 0121 12.2V7.375a1.5 1.5 0 00-1.5-1.5h-.75a.25.25 0 01-.25-.25v-.75a.25.25 0 01.25-.25h.75a1.752 1.752 0 001.75-1.75v-1A1.752 1.752 0 0019.5.125h-4a1.752 1.752 0 00-1.75 1.75v1a1.752 1.752 0 001.75 1.75h.75a.25.25 0 01.25.25v.75a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25v-3.75A1.5 1.5 0 008.637.419l-3 .75A1.5 1.5 0 004.5 2.625v3.018a.249.249 0 01-.214.247A1.5 1.5 0 003 7.375V12.2a.256.256 0 01-.026.112L.158 17.94a1.71 1.71 0 00-.04.088.249.249 0 00.231.347zM18.5 7.875c.3 0 .545.335.545.75v1.5c0 .414-.244.75-.545.75H5.545c-.3 0-.545-.336-.545-.75v-1.5c0-.415.244-.75.545-.75zm-10 7.257a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zm4 1h-1a1 1 0 110-2h1a1 1 0 010 2zm5.5 0h-1a1 1 0 110-2h1a1 1 0 010 2zM6.5 3.21a.25.25 0 01.189-.243l1.5-.375a.251.251 0 01.215.046.248.248 0 01.1.2v2.79a.25.25 0 01-.25.25H6.75a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});