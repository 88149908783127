define("ember-svg-jar/inlined/fitness-dumbbell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fitness-dumbbell</title><path d=\"M12.6 2.206a1.5 1.5 0 00-2.12 2.122l2.828 2.828a.5.5 0 010 .706l-5.443 5.45a.5.5 0 01-.708 0l-2.828-2.828a1.5 1.5 0 00-2.122 2.122L11.4 21.8a1.5 1.5 0 102.122-2.122l-2.828-2.828a.5.5 0 010-.707l5.443-5.449a.5.5 0 01.707 0l2.829 2.828a1.5 1.5 0 102.12-2.122zM23.561 6.8l-1.414-1.41a.5.5 0 010-.708 2 2 0 00-2.829-2.829.5.5 0 01-.707 0L17.2.439a1.5 1.5 0 10-2.123 2.121l6.362 6.365A1.5 1.5 0 0023.561 6.8zM2.56 15.076A1.5 1.5 0 10.439 17.2l1.415 1.414a.5.5 0 010 .707 2 2 0 102.829 2.829.5.5 0 01.708 0L6.8 23.56a1.5 1.5 0 102.12-2.121z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});