define("ember-svg-jar/inlined/messages-bubble-square-share-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-share-alternate</title><path d=\"M12.3 14.478a.25.25 0 00-.1-.478H8.5a1.006 1.006 0 00-.665.252L5 16.773V15a1 1 0 00-1-1H2.25a.25.25 0 01-.25-.25V2.25A.25.25 0 012.25 2h18a.25.25 0 01.25.25v7.029a.251.251 0 00.279.248 4.3 4.3 0 01.471-.027 3.887 3.887 0 01.94.122.25.25 0 00.31-.243V2a2 2 0 00-2-2H2a2 2 0 00-2 2v12.053A1.953 1.953 0 002 16h1v3a1 1 0 001.665.748L8.88 16h1.147a.251.251 0 00.2-.1 5.568 5.568 0 012.073-1.422zM21.25 18.5a2.734 2.734 0 00-2.166 1.076l-2.612-1.045c.009-.094.028-.185.028-.281a2.741 2.741 0 00-.105-.712l2.979-1.788a2.858 2.858 0 10-.769-1.288l-2.979 1.788a2.752 2.752 0 10.29 3.674l2.612 1.045c-.009.094-.028.185-.028.281a2.75 2.75 0 102.75-2.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});