define("ember-svg-jar/inlined/smiley-unhappy-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-unhappy-1-alternate</title><path d=\"M17.217 14.023a7.754 7.754 0 00-8.065 3.447 1 1 0 001.7 1.06 5.76 5.76 0 015.935-2.553 1 1 0 00.434-1.954z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><circle cx=\"8\" cy=\"9\" r=\"2\"/><circle cx=\"16\" cy=\"9\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});