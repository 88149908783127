define("ember-svg-jar/inlined/smart-watch-square-ringing-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-ringing-alternate</title><path d=\"M21 6a3 3 0 00-3-3h-1.078a.249.249 0 01-.24-.182L15.98.365A.5.5 0 0015.5 0h-7a.5.5 0 00-.481.363l-.7 2.455A.248.248 0 017.077 3H6a3 3 0 00-3 3v12a3 3 0 003 3h1.077a.249.249 0 01.24.181l.7 2.456A.5.5 0 008.5 24h7a.5.5 0 00.48-.363l.7-2.456a.25.25 0 01.24-.181H18a3 3 0 003-3zm-2 12a1 1 0 01-1 1H6a1 1 0 01-1-1V6a1 1 0 011-1h12a1 1 0 011 1z\"/><path d=\"M12 10.753c-1.074 0-5.25.122-5.25 2.249v1a1.819 1.819 0 001.75 1.75h1A1.819 1.819 0 0011.248 14v-1.49a.25.25 0 01.242-.25c.33-.01.685-.01 1.016 0a.25.25 0 01.242.25V14a1.869 1.869 0 001.75 1.75h1a1.819 1.819 0 001.75-1.75v-1c0-2.085-4.019-2.247-5.248-2.247zM12 9.252a.75.75 0 00.75-.75V7a.75.75 0 00-1.5 0v1.5a.75.75 0 00.75.752zM14.185 9.728a.748.748 0 00.994-.369L15.8 8a.75.75 0 10-1.363-.625l-.625 1.363a.748.748 0 00.373.99zM8.816 9.359a.75.75 0 101.363-.625l-.625-1.363A.75.75 0 008.191 8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});