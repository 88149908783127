define("ember-svg-jar/inlined/shopping-basket-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-basket-star</title><path d=\"M11.691 15.708a2.547 2.547 0 012.374-1.573h1.105a.248.248 0 00.229-.151l.738-1.708.018-.041.02-.041a2.617 2.617 0 011.433-1.285.255.255 0 00.155-.171l.107-.406a.5.5 0 00-.483-.627H2.381a.5.5 0 00-.49.6L3.168 16.3a1.956 1.956 0 001.966 1.45h6.246a.25.25 0 00.239-.325 2.52 2.52 0 01.072-1.717zM10.5 12.364v2.386a.5.5 0 01-1 0v-2.386a.5.5 0 011 0zM6.63 15.4a.5.5 0 01-.085.007.5.5 0 01-.492-.417l-.409-2.39a.5.5 0 01.987-.168l.408 2.386a.5.5 0 01-.409.582zM19.765 6.917a1.441 1.441 0 00-1.439-1.439h-1.142a.25.25 0 01-.222-.135l-2.113-4.055a1 1 0 10-1.773.924l1.511 2.9a.251.251 0 01-.222.366H5.4a.251.251 0 01-.222-.366l1.511-2.9a1 1 0 10-1.773-.924L2.805 5.343a.249.249 0 01-.222.135H1.441a1.439 1.439 0 000 2.878h16.885a1.441 1.441 0 001.439-1.439z\"/><path d=\"M22.933 15.635h-2.091a.248.248 0 01-.229-.151l-1.127-2.614-.011-.023a1.094 1.094 0 00-1.961.023l-1.128 2.614a.25.25 0 01-.23.151h-2.091a1.041 1.041 0 00-.987.642 1.079 1.079 0 00.27 1.182l1.836 1.641a.249.249 0 01.063.286l-1.024 2.355a1.063 1.063 0 00.266 1.228 1.081 1.081 0 001.275.14l2.613-1.471a.251.251 0 01.246 0l2.614 1.462a1.079 1.079 0 001.274-.14 1.06 1.06 0 00.267-1.227l-1.024-2.356a.249.249 0 01.063-.286l1.851-1.651a1.067 1.067 0 00.253-1.165 1.041 1.041 0 00-.988-.64z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});