define("ember-svg-jar/inlined/phone-action-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-search</title><path d=\"M15.3 12.886A4.459 4.459 0 0016 10.5a4.5 4.5 0 10-4.5 4.5 4.459 4.459 0 002.386-.7l1.414 1.417a1.013 1.013 0 001.414 0 1 1 0 000-1.414zM9 10.5a2.5 2.5 0 112.5 2.5A2.5 2.5 0 019 10.5z\"/><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});