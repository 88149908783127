define("ember-svg-jar/inlined/decoration-cabin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>decoration-cabin</title><circle cx=\"9\" cy=\"12.5\" r=\"1.25\"/><circle cx=\"15\" cy=\"12.5\" r=\"1.25\"/><path d=\"M2.69 18.5H5.2a.5.5 0 00.5-.5V9.78a1.942 1.942 0 011.94-1.94h.47a.5.5 0 00.5-.5v-.95a3.4 3.4 0 012.04-3.109.25.25 0 01.35.229v13.74a1 1 0 002 0V3.51a.25.25 0 01.35-.229 3.4 3.4 0 012.04 3.109v.95a.5.5 0 00.5.5h.47a1.942 1.942 0 011.94 1.94V18a.5.5 0 00.5.5h2.51a.507.507 0 00.41-.21.5.5 0 00.06-.46 13.388 13.388 0 01-.78-4.52V9a3.513 3.513 0 00-3.02-3.47 6 6 0 00-11.96 0A3.513 3.513 0 003 9v4.31a13.388 13.388 0 01-.78 4.52.5.5 0 00.06.46.507.507 0 00.41.21zM23 20H1a1 1 0 00-.2 1.98.25.25 0 01.2.245V23.5a.5.5 0 00.5.5h21a.5.5 0 00.5-.5v-1.275a.25.25 0 01.2-.245A1 1 0 0023 20z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});