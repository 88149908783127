define("ember-svg-jar/inlined/graph-stats-square-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>graph-stats-square-alternate</title><path d=\"M21 0H3a3 3 0 00-3 3v18a3 3 0 003 3h18a3 3 0 003-3V3a3 3 0 00-3-3zM3 2h18a1 1 0 011 1v9.53h-2.646c-.671 0-.911-.408-1.507-2.03-.106-.286-.214-.582-.335-.883a1.5 1.5 0 00-2.852.208l-1.584 6.6L10.466 3.7A1.507 1.507 0 009 2.5a1.5 1.5 0 00-1.472 1.2 236.39 236.39 0 00-.788 3.982c-.869 4.554-1.044 4.854-1.866 4.854H2V3a1 1 0 011-1zm18 20H3a1 1 0 01-1-1v-6.47h2.874c2.6 0 2.956-1.9 3.831-6.479C8.8 7.577 9 6.513 9 6.513l2.568 12.521a1.493 1.493 0 001.444 1.2h.026a1.491 1.491 0 001.462-1.155l1.73-7.207c.537 1.358 1.28 2.659 3.127 2.659H22V21a1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});