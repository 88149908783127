define("ember-svg-jar/inlined/hippo-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hippo-body</title><path d=\"M21.258 7.681c-1.913-1.151-4.3-.6-5.382-.242-.267-1.325-2.218-2.085-3.793-.673a2.926 2.926 0 00-2.714-.8A4.435 4.435 0 005 2.61a3.334 3.334 0 00-3.485 3.3l-.433.865a.75.75 0 101.342.67.248.248 0 01.376-.084l.373.291a.249.249 0 01.071.309l-.158.315a.75.75 0 101.342.67l.115-.231.426.331a1.721 1.721 0 01.57 1.9 1.689 1.689 0 01-1.289 1.11v-.445a.75.75 0 00-1.5 0v.249a.25.25 0 01-.25.25h-.747a.25.25 0 01-.25-.25v-.249a.75.75 0 00-1.5 0v1.004c0 .864.627 2.5 3 2.5H7c.469 0 1 0 1 1.5v4.28a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V17.9a7.971 7.971 0 002.88.714 7.817 7.817 0 002.12-.3v2.58a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3.413a4.739 4.739 0 003-4.371c0-2.03 0-3.783-2.742-5.429zM10.5 8.86a.75.75 0 11-.75-.75.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});