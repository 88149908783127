define("ember-svg-jar/inlined/yoga-back-stretch-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>yoga-back-stretch-1</title><circle cx=\"5.386\" cy=\"3\" r=\"2.5\"/><path d=\"M11.328 9.613a1.5 1.5 0 01.379-2.088l3.134-2.17A2.006 2.006 0 0115.98 5h2.906a1.5 1.5 0 000-3h-3.219a4 4 0 00-2.276.711L10 5.059a4.5 4.5 0 00-1.139 6.261l1.075 1.553a.5.5 0 00.688.131l1.664-1.104a.5.5 0 00.214-.322.5.5 0 00-.08-.379z\"/><path d=\"M20.525 7l-.758-1.138a1.5 1.5 0 00-2.5 1.664l.759 1.138a.5.5 0 01-.139.693l-6.682 4.456a.5.5 0 00-.134.7l.312.451V22a1.5 1.5 0 003 0v-6.7l5.062-3.375a3.661 3.661 0 001.411-1.667A3.5 3.5 0 0020.525 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});