define("ember-svg-jar/inlined/file-c-plus-plus-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-c-plus-plus-1</title><path d=\"M20 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h18a1 1 0 001-1zM5.5 16.876a.625.625 0 010 1.25 3.625 3.625 0 010-7.25.625.625 0 010 1.25 2.375 2.375 0 000 4.75zm6-1.75h-1.125a.25.25 0 00-.25.25V16.5a.625.625 0 01-1.25 0v-1.124a.25.25 0 00-.25-.25H7.5a.625.625 0 110-1.25h1.125a.25.25 0 00.25-.25V12.5a.625.625 0 111.25 0v1.125a.25.25 0 00.25.25H11.5a.625.625 0 010 1.25zm6 0h-1.125a.25.25 0 00-.25.25V16.5a.625.625 0 11-1.25 0v-1.124a.25.25 0 00-.25-.25H13.5a.625.625 0 010-1.25h1.125a.25.25 0 00.25-.25V12.5a.625.625 0 111.25 0v1.125a.25.25 0 00.25.25H17.5a.625.625 0 010 1.25z\"/><path d=\"M23.707 5.708L18.293.294A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.751V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.415a1 1 0 00-.293-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});