define("ember-svg-jar/inlined/road-sign-no-buses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-no-buses</title><path d=\"M22.043 5.448a.25.25 0 00-.386-.04L20.315 6.75a.252.252 0 00-.028.322A2.452 2.452 0 0120.75 8.5v6a2.5 2.5 0 01-2.5 2.5.061.061 0 00-.061.049 2.5 2.5 0 01-4.844.133A.251.251 0 0013.1 17h-2.7a.251.251 0 00-.241.182 2.5 2.5 0 01-1.978 1.771.251.251 0 00-.134.069l-2.639 2.635a.254.254 0 00-.072.2.249.249 0 00.112.183 11.988 11.988 0 0016.6-16.595z\"/><path d=\"M17.973 15.364a.252.252 0 00.223.136h.054a1 1 0 001-1v-6a.791.791 0 00-.008-.11.25.25 0 00-.424-.143l-6.826 6.826a.25.25 0 00.177.427H13.3a.252.252 0 00.223-.136 2.493 2.493 0 014.446 0zM16.75 10.75a1 1 0 111 1 1 1 0 01-1-1z\"/><circle cx=\"15.75\" cy=\"16.5\" r=\"1.5\"/><path d=\"M20.768 3.835A11.982 11.982 0 103.835 20.769a.251.251 0 00.347-.007l16.58-16.58a.25.25 0 00.006-.347zM10 12a.5.5 0 01-.5.5h-.75a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5H9a1 1 0 011 1zm2.494-1.55l-1.044 1.044a.249.249 0 01-.335.017.98.98 0 01-.365-.761 1 1 0 011-1 .978.978 0 01.761.366.249.249 0 01-.017.334zm4.024-4.023l-1 1a.251.251 0 01-.177.073H8.25a1 1 0 00-1 1v3.75a.25.25 0 01-.25.25H5.25a.987.987 0 00-.389.079 1 1 0 00-.611.921v1a1 1 0 00.611.921.987.987 0 00.389.079h.05a.252.252 0 00.223-.136A2.5 2.5 0 017.75 14a2.462 2.462 0 01.586.071.248.248 0 01.182.175.252.252 0 01-.064.245l-.441.441a.254.254 0 01-.2.072h-.062a1.5 1.5 0 00-1.5 1.5v.062a.251.251 0 01-.072.2l-.439.434a.25.25 0 01-.42-.118l-.009-.038A.063.063 0 005.25 17a2.5 2.5 0 01-2.5-2.5v-1a2.5 2.5 0 012.5-2.5h.25a.25.25 0 00.25-.25V8.5A2.5 2.5 0 018.25 6h8.091a.25.25 0 01.177.427z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});