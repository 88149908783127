define("ember-svg-jar/inlined/show-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>show-person</title><path d=\"M3.067 15.5H.5a.5.5 0 00-.5.5v6.692A1.308 1.308 0 001.308 24h3.923a.656.656 0 00.656-.675c-.219-4.932-1.922-7.1-2.457-7.669a.5.5 0 00-.363-.156zM.5 14H2a1 1 0 000-2H.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5zM23.5 15.5h-2.567a.5.5 0 00-.363.156c-.535.565-2.238 2.737-2.457 7.669a.656.656 0 00.656.675h3.923A1.307 1.307 0 0024 22.692V16a.5.5 0 00-.5-.5zM23.5 12H22a1 1 0 000 2h1.5a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5z\"/><circle cx=\"12\" cy=\"10\" r=\"2.5\"/><path d=\"M12 13a3.5 3.5 0 00-3.5 3.5v2a.5.5 0 00.5.5h.594a.5.5 0 01.5.451l.41 4.1A.5.5 0 0011 24h2a.5.5 0 00.5-.45l.41-4.1a.5.5 0 01.5-.451H15a.5.5 0 00.5-.5v-2A3.5 3.5 0 0012 13zM23 0H1a1.138 1.138 0 00-1 1.237v8.637c0 .352.238.646.523.625 3.8-.284 6.925-3.645 7.767-8.092A.5.5 0 018.781 2h6.438a.5.5 0 01.491.407c.842 4.447 3.969 7.808 7.768 8.092.284.021.522-.273.522-.625V1.237A1.138 1.138 0 0023 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});