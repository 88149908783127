define("ember-svg-jar/inlined/multiple-actions-check-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-check-2</title><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M11.933 11.261a.248.248 0 00.172.146.254.254 0 00.22-.053 7.987 7.987 0 015.385-1.909.25.25 0 00.19-.415 4.552 4.552 0 00-7 .23.25.25 0 00.005.311 7.015 7.015 0 011.028 1.69zM10.839 13.035a.252.252 0 00.036-.191A5.5 5.5 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.579a.253.253 0 00.18-.076.249.249 0 00.07-.183 8 8 0 011.32-4.706z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M23.049 18.19a1 1 0 00-1.254.656A4.5 4.5 0 1117.5 13a1 1 0 000-2 6.5 6.5 0 106.2 8.443 1 1 0 00-.651-1.253z\"/><path d=\"M23.707 12.667a1 1 0 00-1.414 0l-4.616 4.617a.252.252 0 01-.354 0l-1.178-1.179a1 1 0 10-1.415 1.414l1.577 1.581a1.69 1.69 0 002.386 0l5.014-5.014a1 1 0 000-1.419z\"/><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M11.933 11.261a.248.248 0 00.172.146.254.254 0 00.22-.053 7.987 7.987 0 015.385-1.909.25.25 0 00.19-.415 4.552 4.552 0 00-7 .23.25.25 0 00.005.311 7.015 7.015 0 011.028 1.69zM10.839 13.035a.252.252 0 00.036-.191A5.5 5.5 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.579a.253.253 0 00.18-.076.249.249 0 00.07-.183 8 8 0 011.32-4.706z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M23.049 18.19a1 1 0 00-1.254.656A4.5 4.5 0 1117.5 13a1 1 0 000-2 6.5 6.5 0 106.2 8.443 1 1 0 00-.651-1.253z\"/><path d=\"M23.707 12.667a1 1 0 00-1.414 0l-4.616 4.617a.252.252 0 01-.354 0l-1.178-1.179a1 1 0 10-1.415 1.414l1.577 1.581a1.69 1.69 0 002.386 0l5.014-5.014a1 1 0 000-1.419z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});