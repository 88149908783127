define("ember-svg-jar/inlined/console-lamp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>console-lamp</title><path d=\"M7.5 17h-7a.5.5 0 00-.5.5v2A1.5 1.5 0 001.5 21h1.482a.25.25 0 01.177.427l-.866.866a1 1 0 101.414 1.414l2.561-2.561A.5.5 0 016.621 21H7.5a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5zM7.5 12h-6A1.5 1.5 0 000 13.5v2a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5zM14.5 12h-5a.5.5 0 00-.5.5v8a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-8a.5.5 0 00-.5-.5zm-3 5.5a1 1 0 111-1 1 1 0 01-1 1zM22.5 12h-6a.5.5 0 00-.5.5v8a.5.5 0 00.5.5h.879a.5.5 0 01.353.146l2.561 2.561a1 1 0 001.414-1.414l-.866-.866a.25.25 0 01.177-.427H22.5a1.5 1.5 0 001.5-1.5v-6a1.5 1.5 0 00-1.5-1.5zm-1 5.5a1 1 0 111-1 1 1 0 01-1 1zM2.75 5.75a2.708 2.708 0 00.9-.156.249.249 0 01.305.122A5.5 5.5 0 014.379 6.8a.249.249 0 01-.154.3 1.5 1.5 0 00-.975 1.4v2a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-2a1.5 1.5 0 00-1.139-1.449.252.252 0 01-.186-.2A6.848 6.848 0 005.1 4.679a.25.25 0 010-.256A2.725 2.725 0 005.5 3C5.5 1.486 3.464.5 3.057.315A.75.75 0 002.22.47l-2 2a.75.75 0 00-.155.837C.248 3.715 1.236 5.75 2.75 5.75zm.143-3.832C3.477 2.261 4 2.721 4 3a1.252 1.252 0 01-1.25 1.25c-.28 0-.739-.522-1.082-1.107zM23.669 5.972a1.365 1.365 0 00.283-1.243l-1-3.666A1.487 1.487 0 0021.5 0h-5a1.488 1.488 0 00-1.453 1.063l-1 3.666a1.365 1.365 0 00.284 1.243A1.527 1.527 0 0015.5 6.5h7a1.528 1.528 0 001.169-.528zM16.214 10.143a2.576 2.576 0 00.038.442.5.5 0 00.493.415h4.224a.5.5 0 00.492-.415 2.576 2.576 0 00.039-.442 2.643 2.643 0 00-5.286 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});