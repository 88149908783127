define("ember-svg-jar/inlined/medical-instrument-ambulance-bed-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-instrument-ambulance-bed-1</title><path d=\"M3 6h4a1.5 1.5 0 000-3H3a1.5 1.5 0 000 3zM24 9a1.5 1.5 0 00-1.5-1.5h-21a1.5 1.5 0 000 3h21A1.5 1.5 0 0024 9zM22.5 19a2 2 0 00-2.789-1.837L14.774 14.5a.25.25 0 010-.441l2.962-1.592a.25.25 0 00-.119-.47h-3.106a.487.487 0 00-.236.06l-1.783.958a.5.5 0 01-.474 0l-1.778-.955A.5.5 0 0010 12H6.9a.25.25 0 00-.119.47l2.949 1.589a.25.25 0 010 .44l-5.2 2.8a2 2 0 10.97 1.751l6.522-3.506a.5.5 0 01.474 0l6.03 3.249A2 2 0 1022.5 19z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});