define("ember-svg-jar/inlined/shooting-rifle-person-aim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shooting-rifle-person-aim</title><path d=\"M13.69 8.221a1.5 1.5 0 00.773-.214l1.656-.993a.251.251 0 00.053-.387l-2.659-2.811a1 1 0 00-.729-.316H2.5V3a1 1 0 00-2 0v1.5a1 1 0 001 1h2.708a1.5 1.5 0 001.292.75l6.21-.019.847 1.523c.674.482.878.467 1.133.467zM9.75 2.5h4a1 1 0 000-2h-4a1 1 0 000 2z\"/><circle cx=\"18.75\" cy=\"3\" r=\"2.5\"/><path d=\"M23.359 21.366L20 14.168V8.5a1.5 1.5 0 00-2.272-1.287l-2.751 1.652a2.5 2.5 0 01-2.739-.11l-1.366-.975a1.5 1.5 0 10-1.744 2.441l1.366.976a5.508 5.508 0 006.026.24l.48-.287v3.017l-3.36 7.2a1.5 1.5 0 102.719 1.269l1.914-4.1a.25.25 0 01.453 0l1.914 4.1a1.5 1.5 0 102.719-1.269z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});