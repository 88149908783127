define("ember-svg-jar/inlined/web-service-mylife", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>web-service-mylife</title><circle cx=\"7.14\" cy=\"7.97\" r=\"2.92\"/><path d=\"M22.25 13.82c-3-1.59-7.63-2.29-11.92-1.79-3 .36-7.84 2-8.87 4.26a1.82 1.82 0 00.6 2.32 2 2 0 001.13.39A2.48 2.48 0 005 18.12c2.1-2.17 4.81-4.17 10.38-4a22.25 22.25 0 016.18 1c.6.2 1 0 1.14-.34a.76.76 0 00-.45-.96z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});