define("ember-svg-jar/inlined/amusement-park-ferris-wheel-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>amusement-park-ferris-wheel-1</title><path d=\"M16.192 17.264a.251.251 0 00-.343-.086 7.363 7.363 0 01-1 .5.251.251 0 00-.132.337 2.991 2.991 0 11-5.44 0 .251.251 0 00-.132-.337 7.363 7.363 0 01-1-.5.251.251 0 00-.343.086L5.8 20.62a2.069 2.069 0 001.771 3.13h8.868a2.069 2.069 0 001.761-3.129z\"/><path d=\"M5.5 10.75a6.5 6.5 0 106.5-6.5 6.5 6.5 0 00-6.5 6.5zm10.805 1.266a.249.249 0 01-.364.143l-2.066-1.192a.251.251 0 010-.434l2.066-1.192a.249.249 0 01.364.143 4.214 4.214 0 010 2.532zM7.7 9.484a.25.25 0 01.364-.144l2.066 1.193a.251.251 0 010 .434l-2.071 1.192a.249.249 0 01-.364-.143A4.206 4.206 0 017.7 9.484zm1.06 4.359a.25.25 0 01.056-.389l2.059-1.189a.25.25 0 01.375.217v2.386a.25.25 0 01-.309.243 4.46 4.46 0 01-2.181-1.268zm4.3 1.268a.251.251 0 01-.309-.243v-2.386a.25.25 0 01.375-.217l2.059 1.189a.25.25 0 01.056.389 4.46 4.46 0 01-2.182 1.268zm2.18-7.454a.25.25 0 01-.056.389l-2.059 1.189a.25.25 0 01-.375-.217V6.632a.25.25 0 01.309-.243 4.46 4.46 0 012.181 1.268zm-4.3-1.268a.251.251 0 01.309.243v2.386a.25.25 0 01-.375.217L8.816 8.046a.25.25 0 01-.056-.389 4.46 4.46 0 012.181-1.268z\"/><circle cx=\"12\" cy=\"2.25\" r=\"2\"/><circle cx=\"12\" cy=\"19.25\" r=\"1.5\"/><path d=\"M3.639 8.232A2 2 0 102.907 5.5a2 2 0 00.732 2.732zM20.361 13.268A2 2 0 1021.093 16a2 2 0 00-.732-2.732zM6.371 14a2 2 0 10-.732 2.732A2 2 0 006.371 14zM20.361 8.232a2 2 0 10-2.732-.732 2 2 0 002.732.732z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});