define("ember-svg-jar/inlined/baggage-check-in-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baggage-check-in-user</title><path d=\"M15 21.75H1.25a1 1 0 000 2H15a1 1 0 000-2zM.25 1.5v5A1.5 1.5 0 001.75 8h10.5a1.5 1.5 0 001.5-1.5v-5a1.5 1.5 0 00-1.5-1.5H1.75a1.5 1.5 0 00-1.5 1.5zm2.5.25h4.5a.75.75 0 010 1.5h-4.5a.75.75 0 010-1.5zm0 2.85h6a.75.75 0 010 1.5h-6a.75.75 0 010-1.5z\"/><circle cx=\"19.75\" cy=\"3\" r=\"2.5\"/><path d=\"M21.75 7.5h-4a2 2 0 00-2 2v5a1 1 0 001 1h.75a.25.25 0 01.25.25V23a1 1 0 001 1h2a1 1 0 001-1v-7.25a.25.25 0 01.25-.25h.75a1 1 0 001-1v-5a2 2 0 00-2-2zM7.25 9.29h-1a3 3 0 00-3 3v.25a.25.25 0 01-.25.25H1.75a1.5 1.5 0 00-1.5 1.5v4.75a1.5 1.5 0 001.5 1.5h10a1.5 1.5 0 001.5-1.5v-4.75a1.5 1.5 0 00-1.5-1.5H10.5a.25.25 0 01-.25-.25v-.25a3 3 0 00-3-3zm1 3.25a.25.25 0 01-.25.25H5.5a.25.25 0 01-.25-.25v-.25a1 1 0 011-1h1a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});