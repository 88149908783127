define("ember-svg-jar/inlined/soft-drinks-bottle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>soft-drinks-bottle</title><path d=\"M23.121 3L21 .877a1.5 1.5 0 00-2.122 0l-.569.569a1 1 0 00.26 1.6l.515.257a.25.25 0 01.126.145l.176.528a1.007 1.007 0 00.632.633l.529.176a.246.246 0 01.144.126l.258.515a1 1 0 00.733.54 1.106 1.106 0 00.162.013 1 1 0 00.707-.292l.57-.57a1.5 1.5 0 000-2.121zM18.648 6.006a10.846 10.846 0 00-2.272 3.345.25.25 0 01-.406.077l-1.4-1.4a.249.249 0 01-.07-.228.252.252 0 01.146-.183 10.8 10.8 0 003.345-2.272.984.984 0 00.048-1.389 1.009 1.009 0 00-1.388 0 9.741 9.741 0 01-4.417 2.474l-.1.031A9.678 9.678 0 007.668 8.9 11.98 11.98 0 004.9 12.966a5.719 5.719 0 01-1.213 1.941A3.284 3.284 0 012 15.878a2.074 2.074 0 00-1.46 1.377 1.89 1.89 0 00.443 1.935l3.825 3.824a1.859 1.859 0 001.32.547 1.911 1.911 0 00.594-.095 2.1 2.1 0 001.4-1.488 3.273 3.273 0 01.966-1.667 5.7 5.7 0 011.944-1.211 11.951 11.951 0 004.061-2.769 9.68 9.68 0 002.442-4.464l.03-.1v-.005a9.752 9.752 0 012.466-4.417 1.01 1.01 0 00.3-.723.941.941 0 00-.3-.664.985.985 0 00-1.383.048zM6.254 18.451a1 1 0 110-1.415 1 1 0 010 1.415zm4.6-1.768a1 1 0 110-1.414 1 1 0 01-.004 1.414zm1.061-4.6a1 1 0 110-1.414 1 1 0 01-.004 1.418z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});