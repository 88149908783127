define("ember-svg-jar/inlined/shopping-basket-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-basket-heart</title><path d=\"M11.838 14.9a4.246 4.246 0 015.288-2.072.25.25 0 00.33-.171l.663-2.518a.5.5 0 00-.483-.627h-15a.5.5 0 00-.489.6l1.276 5.994a1.957 1.957 0 001.966 1.448h5.781a.249.249 0 00.248-.279 4.239 4.239 0 01.42-2.375zm-1.086-2.73v2.386a.5.5 0 01-1 0V12.17a.5.5 0 011 0zM6.88 15.2a.488.488 0 01-.085.007.5.5 0 01-.492-.416L5.9 12.405a.5.5 0 01.409-.576.505.505 0 01.577.408l.408 2.386a.5.5 0 01-.414.577zM20.015 6.723a1.442 1.442 0 00-1.44-1.44h-1.142a.251.251 0 01-.222-.134L15.1 1.094a1 1 0 00-1.774.924l1.511 2.9a.252.252 0 01-.008.245.248.248 0 01-.214.12H5.652a.248.248 0 01-.214-.12.252.252 0 01-.008-.245l1.511-2.9a1 1 0 10-1.773-.924L3.054 5.149a.25.25 0 01-.221.134H1.691a1.439 1.439 0 100 2.878h16.884a1.44 1.44 0 001.44-1.438z\"/><path d=\"M13.693 18.73l4.446 4.638a.25.25 0 00.361 0l4.446-4.638a2.738 2.738 0 10-3.871-3.872l-.579.579a.25.25 0 01-.353 0l-.579-.579a2.738 2.738 0 10-3.871 3.872z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});