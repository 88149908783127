define("ember-svg-jar/inlined/vectors-square-link-broken", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vectors-square-link-broken</title><rect x=\"7.5\" y=\"2\" width=\"9\" height=\"2\" rx=\".25\" ry=\".25\"/><rect x=\"2\" y=\"7.5\" width=\"2\" height=\"9\" rx=\".25\" ry=\".25\"/><circle cx=\"21\" cy=\"3\" r=\"3\"/><circle cx=\"3\" cy=\"3\" r=\"3\"/><circle cx=\"3\" cy=\"21\" r=\"3\"/><path d=\"M20.221 15.138a3.1 3.1 0 000-4.37l-.74-.738a3.092 3.092 0 00-4.369 0l-1.432 1.433a1 1 0 001.414 1.414l1.432-1.433a1.09 1.09 0 011.541 0l.74.738a1.091 1.091 0 010 1.54l-1.434 1.433a1 1 0 101.414 1.415zM16.571 17.373a1 1 0 00-1.414 0l-1.432 1.432a1.113 1.113 0 01-1.541 0l-.74-.738a1.09 1.09 0 010-1.541l1.433-1.433a1 1 0 10-1.414-1.414l-1.433 1.433a3.09 3.09 0 000 4.37l.74.738a3.089 3.089 0 004.369 0l1.432-1.433a1 1 0 000-1.414zM19.816 18.4a1 1 0 00-1.416 1.417l1.271 1.27a1 1 0 001.414-1.415zM23 16.014h-1.8a1 1 0 000 2H23a1 1 0 000-2zM17.015 20.2a1 1 0 00-1 1V23a1 1 0 002 0v-1.8a1 1 0 00-1-1zM9.164 9.164a1 1 0 000 1.414l1.27 1.269a1 1 0 101.414-1.414l-1.27-1.269a1 1 0 00-1.414 0zM12.424 7.25v1.8a1 1 0 002 0v-1.8a1 1 0 00-2 0zM10.046 13.423a1 1 0 00-1-1H7.25a1 1 0 000 2h1.8a1 1 0 00.996-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});