define("ember-svg-jar/inlined/gesture-zoom-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-zoom-out</title><path d=\"M23.146 3.145L22.7 3.6a.25.25 0 01-.318.03 13.494 13.494 0 00-6.288-2.13 1 1 0 00-.188 2 11.7 11.7 0 014.917 1.53.249.249 0 01.048.391l-.225.224A.5.5 0 0021 6.5h2.5A.5.5 0 0024 6V3.5a.5.5 0 00-.854-.355zM3.112 5.4a.251.251 0 01.048-.391A11.75 11.75 0 018.09 3.5 1 1 0 009 2.409 1.013 1.013 0 007.91 1.5a13.277 13.277 0 00-6.291 2.121.25.25 0 01-.319-.029l-.446-.447A.5.5 0 000 3.5V6a.5.5 0 00.5.5H3a.5.5 0 00.354-.854zM6.491 9.019a3.078 3.078 0 00-2.06 3.3l1.052 9.734a.5.5 0 00.5.446H11a.5.5 0 00.5-.554l-1.12-10.386a3 3 0 00-3.889-2.54zm2.064 4.249l-1.988.214a.5.5 0 01-.551-.443l-.107-.995a1.5 1.5 0 012.983-.321l.108.994a.5.5 0 01-.445.551zM16.366 8.909a3.05 3.05 0 00-2.72 2.77l-1.139 10.265a.5.5 0 00.5.555h5.024a.5.5 0 00.5-.445l1.091-9.835a3 3 0 00-3.256-3.31zm1.76 3.15l-.11.994a.5.5 0 01-.551.442l-1.988-.219a.5.5 0 01-.442-.552l.109-.994a1.5 1.5 0 012.982.329z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});