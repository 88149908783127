define("ember-svg-jar/inlined/multiple-actions-laptop.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-laptop</title><path d=\"M18.415 10.644l-.032-.067c-.454-.907-1.9-1.44-3.911-2.179l-.486-.172a1.8 1.8 0 01-.055-1.172 4.647 4.647 0 001.215-3.569A3.219 3.219 0 0012 0a2.991 2.991 0 00-1.342.314.25.25 0 00-.1.363 5 5 0 01.842 2.808 6.267 6.267 0 01-.921 3.624.25.25 0 00.121.372c1.918.716 3.3 1.328 3.9 2.537a5.707 5.707 0 01.323.809.25.25 0 00.238.173h3.127a.251.251 0 00.211-.116.247.247 0 00.016-.24z\"/><path d=\"M13.218 11.015a.25.25 0 00.205-.355 3.141 3.141 0 00-.04-.083c-.454-.907-1.9-1.44-3.911-2.179l-.486-.172a1.8 1.8 0 01-.055-1.172 4.647 4.647 0 001.215-3.569A3.219 3.219 0 007 0a3.22 3.22 0 00-3.145 3.485 4.605 4.605 0 001.193 3.538 1.877 1.877 0 010 1.185L4.53 8.4c-2.007.737-3.457 1.27-3.911 2.177A7.542 7.542 0 000 13.5a.5.5 0 00.5.5h9.768a.251.251 0 00.248-.221 2.989 2.989 0 012.702-2.764zM23.894 22.634l-.018-.031-1.838-2.953a.247.247 0 01-.038-.132V14a1.5 1.5 0 00-1.5-1.5h-7A1.5 1.5 0 0012 14v5.518a.247.247 0 01-.038.132l-1.856 2.984a.927.927 0 00.043.931.96.96 0 00.809.435h12.084a.96.96 0 00.81-.437.925.925 0 00.042-.929zm-5.575-.254a.229.229 0 01-.2.12h-2.4a.229.229 0 01-.2-.12.241.241 0 010-.238l.273-.476a.23.23 0 01.2-.119h1.855a.231.231 0 01.2.119l.273.476a.241.241 0 01-.001.238zM20 18.75a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25v-4a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});