define("ember-svg-jar/inlined/people-woman-glasses-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-glasses-5</title><path d=\"M20.949 18.146l-4.594-1.541a.75.75 0 10-.476 1.422l.4.132-1.5 4.946a.5.5 0 00.479.645H19.5a.5.5 0 00.5-.55l-.373-3.921.824.277A3.177 3.177 0 0122.5 22.52v.729a.75.75 0 001.5 0v-.729a4.689 4.689 0 00-3.051-4.374zM7.723 18.161l.4-.134a.75.75 0 10-.476-1.422l-4.62 1.551A4.681 4.681 0 000 22.521v.729a.75.75 0 001.5 0v-.729a3.17 3.17 0 012.027-2.952l.848-.285L4 23.2a.5.5 0 00.5.547h4.25a.5.5 0 00.479-.645z\"/><path d=\"M15.946 15.352a4.2 4.2 0 002.175.628 4.524 4.524 0 004.062-2.673.749.749 0 00-.683-1.057c-4.964 0 .426-12.16-9.427-12.244A.435.435 0 0012.02 0H11.962C2.047.054 7.487 12.25 2.5 12.25a.749.749 0 00-.684 1.057 4.526 4.526 0 004.061 2.673 4.2 4.2 0 002.2-.645 6.515 6.515 0 003.943 1.915 6.548 6.548 0 003.926-1.898zM7.192 6.513a5.981 5.981 0 004.616-2.025A.252.252 0 0112 4.4a.245.245 0 01.191.091A5.852 5.852 0 0016.806 6.5a.25.25 0 01.241.224c.019.181.057.376.095.572a.25.25 0 01-.2.3 30.75 30.75 0 01-9.875 0 .252.252 0 01-.206-.3c.038-.191.074-.381.093-.558a.251.251 0 01.238-.225zm4.828 9.237a5.582 5.582 0 01-3.586-2.272 3.311 3.311 0 01-.6-2.516 2.607 2.607 0 003.758-.671.5.5 0 01.819 0 2.606 2.606 0 003.807.634 3.279 3.279 0 01-.54 2.448 5.541 5.541 0 01-3.658 2.377z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});