define("ember-svg-jar/inlined/login-key-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>login-key-2</title><path d=\"M23 .5H10a1 1 0 00-1 1V4a1 1 0 002 0V3a.5.5 0 01.5-.5h10a.5.5 0 01.5.5v18a.5.5 0 01-.5.5h-10a.5.5 0 01-.5-.5v-1a1 1 0 00-2 0v2.5a1 1 0 001 1h13a1 1 0 001-1v-21a1 1 0 00-1-1z\"/><path d=\"M11.652 14h.641l.853.854A.5.5 0 0013.5 15a.505.505 0 00.354-.146l.853-.854h.586l.853.854a.5.5 0 00.708 0l.853-.854H19a.5.5 0 00.354-.146l1.5-1.5a.5.5 0 000-.707l-1.5-1.5A.5.5 0 0019 10h-7.348a6 6 0 100 4zM5.5 12A1.5 1.5 0 114 10.5 1.5 1.5 0 015.5 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});