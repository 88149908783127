define("ember-svg-jar/inlined/tree-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tree-2</title><path d=\"M12.9 17.76a5.593 5.593 0 01-.688-.261.5.5 0 00-.416 0 5.655 5.655 0 01-.688.261.5.5 0 00-.354.478v4.512a1.25 1.25 0 002.5 0v-4.512a.5.5 0 00-.354-.478zM18.882 6.663a1 1 0 01-.421-.673 2.98 2.98 0 00-2.117-2.37 1 1 0 01-.694-.731 3.75 3.75 0 00-7.3 0 1 1 0 01-.694.731 2.98 2.98 0 00-2.117 2.37 1 1 0 01-.421.673A3.754 3.754 0 005.2 12.89a.991.991 0 01.425.6A4.055 4.055 0 0011.514 16a1 1 0 01.972 0 4.056 4.056 0 005.892-2.51.991.991 0 01.425-.6 3.754 3.754 0 00.079-6.227z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});