define("ember-svg-jar/inlined/credit-card-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-1</title><path d=\"M.5 18a2 2 0 002 2h19a2 2 0 002-2V9.5a.25.25 0 00-.25-.25H.75a.25.25 0 00-.25.25zM17 15.75h3a.75.75 0 010 1.5h-3a.75.75 0 010-1.5zm-12.5-4h8a.75.75 0 010 1.5h-8a.75.75 0 010-1.5zm0 4h3a.75.75 0 010 1.5h-3a.75.75 0 010-1.5zM23.5 6a2 2 0 00-2-2h-19a2 2 0 00-2 2v1.5a.25.25 0 00.25.25h22.5a.25.25 0 00.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});