define("ember-svg-jar/inlined/taking-pictures-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>taking-pictures-woman</title><path d=\"M14.5 13.5h-.267a.25.25 0 00-.242.23A3 3 0 0111 16.5H7.688a.251.251 0 00-.185.081A9.465 9.465 0 005 23a.5.5 0 00.5.5h18a.5.5 0 00.5-.5 9.511 9.511 0 00-9.5-9.5zM10.537 4.919a.251.251 0 00.184.081H11a2.987 2.987 0 011.73.551.25.25 0 00.281 0 7.36 7.36 0 001.33-1.116.246.246 0 01.182-.078.25.25 0 01.182.078 7.277 7.277 0 003.593 2.1.248.248 0 01.191.261A4 4 0 0114.5 10.5c-.079 0-.157 0-.235-.007a.254.254 0 00-.187.068.25.25 0 00-.078.182v1.5a.25.25 0 00.238.25c.087 0 .174.006.262.006a5.979 5.979 0 004.051-1.578.251.251 0 01.406.1 6.851 6.851 0 00.727 1.5 1 1 0 101.679-1.085 6.079 6.079 0 01-.863-3.35V6.5A6 6 0 009.154 3.778a.25.25 0 00-.007.212.245.245 0 00.157.141 2.738 2.738 0 011.233.788z\"/><path d=\"M11 15a1.5 1.5 0 001.5-1.5V8A1.5 1.5 0 0011 6.5H9.96a.249.249 0 01-.219-.129A1.45 1.45 0 008.382 5.5H6.118a1.45 1.45 0 00-1.359.871.249.249 0 01-.219.129H1.5A1.5 1.5 0 000 8v5.5A1.5 1.5 0 001.5 15zM7.25 8.25A2.25 2.25 0 115 10.5a2.25 2.25 0 012.25-2.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});