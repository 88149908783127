define("ember-svg-jar/inlined/renewable-energy-accumulator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>renewable-energy-accumulator</title><path d=\"M22 1h-8.5a1 1 0 000 2h8a.5.5 0 01.5.5v18a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-18a.5.5 0 01.5-.5h3a1 1 0 000-2H2a2 2 0 00-2 2v19a2 2 0 002 2h20a2 2 0 002-2V3a2 2 0 00-2-2z\"/><path d=\"M16.25 9.245h-.5a1 1 0 00-1 1v8.5a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-5a1 1 0 00-1-1h-.5a1 1 0 00-1 1v5a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-1.5a1 1 0 00-1-1h-.5a1 1 0 00-1 1v1.5a.5.5 0 01-.5.5h-.5a.75.75 0 000 1.5h12.5a.75.75 0 000-1.5h-.5a.5.5 0 01-.5-.5v-8.5a1 1 0 00-1-1zM4.01 8.5a.878.878 0 00.794.507h1.625a.5.5 0 01.5.5v5a.5.5 0 00.89.313l5.9-7.371a.875.875 0 00-.666-1.442h-1.624a.5.5 0 01-.5-.5v-5a.5.5 0 00-.89-.312l-5.9 7.37a.879.879 0 00-.129.935z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});