define("ember-svg-jar/inlined/programming-user-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-user-1</title><path d=\"M11 .04a1 1 0 000 2h10.5a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-8a1 1 0 000 2H22a2 2 0 002-2v-12a2 2 0 00-2-2z\"/><path d=\"M11.97 9.51a.75.75 0 101.06 1.06l2.5-2.5a.749.749 0 000-1.06l-2.5-2.5a.75.75 0 00-1.06 1.06l1.793 1.793a.252.252 0 010 .354zM15.75 10.04a.75.75 0 00.75.75H20a.75.75 0 000-1.5h-3.5a.75.75 0 00-.75.75zM0 12.948V16a.5.5 0 00.5.5h1.612a.25.25 0 01.249.225l.7 6.823a.5.5 0 00.5.45h3.046a.5.5 0 00.5-.45l.7-6.823a.25.25 0 01.249-.225h1.612a.5.5 0 00.5-.5v-3.052a5.073 5.073 0 00-2.754-4.5.25.25 0 01-.014-.442 4.3 4.3 0 10-4.423-.081.247.247 0 01.117.227.252.252 0 01-.144.211A5.061 5.061 0 000 12.948zm5.83-.867a.75.75 0 01-1.5 0v-1.812a.75.75 0 011.5 0zM2.947 4.3a2.207 2.207 0 01.118-.707.251.251 0 01.371-.131 5.439 5.439 0 002.9.838 5.431 5.431 0 001.067-.107.105.105 0 01.09.023.106.106 0 01.04.084 2.3 2.3 0 01-4.59 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});