define("ember-svg-jar/inlined/image-file-shutter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-shutter</title><path d=\"M21.414 4.914L17.086.586A2 2 0 0015.672 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.328a2 2 0 00-.586-1.414zm-7.386 3.949a6.025 6.025 0 013.4 3.1.255.255 0 01-.234.356h-4.969a.25.25 0 01-.217-.375l1.712-2.965a.26.26 0 01.308-.116zm-5.9 1.071A5.962 5.962 0 0112 8.508c.119 0 .238.005.355.013.151.01.41.111.215.452l-2.4 4.158a.25.25 0 01-.433 0l-1.658-2.876a.255.255 0 01.053-.321zm-2.07 5.382A5.671 5.671 0 016 14.508a5.955 5.955 0 011.032-3.351.253.253 0 01.425.023l2.481 4.3a.25.25 0 01-.217.375h-3.1a.563.563 0 01-.563-.539zM9.829 20.1a6.034 6.034 0 01-3.18-2.875.254.254 0 01.23-.362h4.633a.25.25 0 01.217.375l-1.587 2.749a.258.258 0 01-.313.113zm5.924-.915A5.958 5.958 0 0112 20.508c-.164 0-.325-.008-.485-.024a.311.311 0 01-.23-.484l2.378-4.118a.25.25 0 01.433 0l1.715 2.971a.258.258 0 01-.058.327zM16.877 18a.254.254 0 01-.422-.028l-2.468-4.275a.25.25 0 01.216-.375h3.247a.485.485 0 01.5.45A5.978 5.978 0 0116.877 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});