define("ember-svg-jar/inlined/dat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dat</title><path d=\"M12 9.626a.876.876 0 00-.875.875v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V10.5A.876.876 0 0012 9.626zM6.456 9.82a.249.249 0 00-.331.239v3.883a.249.249 0 00.331.24A2.238 2.238 0 007.875 12a2.237 2.237 0 00-1.419-2.18z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zm-17 11.126A.625.625 0 014.875 15V9a.625.625 0 01.625-.624 3.625 3.625 0 010 7.25zM14.125 15a.625.625 0 01-1.25 0v-2.124a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0v-4.374a2.213 2.213 0 011.9-2.239 2.128 2.128 0 012.35 2.113zM19.5 9.626h-1.125a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0V9.876a.25.25 0 00-.25-.25H15.5a.625.625 0 010-1.25h4a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});