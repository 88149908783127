define("ember-svg-jar/inlined/architecture-fence-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>architecture-fence-1</title><path d=\"M3.5 2A3.5 3.5 0 000 5.5V21a1 1 0 001 1h5a1 1 0 001-1V5.5A3.5 3.5 0 003.5 2zM12 2a3.5 3.5 0 00-3.5 3.5V21a1 1 0 001 1h5a1 1 0 001-1V5.5A3.5 3.5 0 0012 2zM20.5 2A3.5 3.5 0 0017 5.5V21a1 1 0 001 1h5a1 1 0 001-1V5.5A3.5 3.5 0 0020.5 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});