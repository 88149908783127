define("ember-svg-jar/inlined/social-media-foursquare-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-foursquare-1</title><path d=\"M20.17 3a2.41 2.41 0 00-2.35-3H6.18a2.42 2.42 0 00-2.41 2.41V22a2 2 0 001.4 1.88 1.9 1.9 0 00.54.08 2 2 0 001.7-1l4.66-8.17h3.43A2.4 2.4 0 0017.85 13zm-5 8.53a.5.5 0 01-.49.39H11.2a1.5 1.5 0 00-1.3.76l-3.13 5.43V3.5a.5.5 0 01.5-.5h9.18a.5.5 0 01.49.61L16.39 6a.5.5 0 01-.49.39h-3.33a1.5 1.5 0 000 3H15a.5.5 0 01.49.61z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});