define("ember-svg-jar/inlined/saving-safe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>saving-safe</title><path d=\"M9.025 12.915a.25.25 0 00.042-.3 3.344 3.344 0 010-3.234.249.249 0 00-.042-.3L7.467 7.528a.25.25 0 00-.381.033 5.988 5.988 0 000 6.878.249.249 0 00.183.106.254.254 0 00.2-.072z\"/><circle cx=\"12\" cy=\"11\" r=\"1.85\"/><path d=\"M10.085 8.025a.25.25 0 00.3.042 3.344 3.344 0 013.234 0 .25.25 0 00.3-.042l1.557-1.558a.25.25 0 00-.033-.381 5.988 5.988 0 00-6.878 0 .25.25 0 00-.033.381zM14.975 9.086a.248.248 0 00-.042.3 3.342 3.342 0 01-.011 3.253.219.219 0 00.036.262l1.574 1.575a.249.249 0 00.2.072.252.252 0 00.183-.105 5.99 5.99 0 000-6.879.248.248 0 00-.183-.1.251.251 0 00-.2.072zM13.914 13.975a.251.251 0 00-.3-.042 3.34 3.34 0 01-3.232 0 .25.25 0 00-.3.042l-1.554 1.558a.252.252 0 00-.072.2.25.25 0 00.106.183 5.989 5.989 0 006.876 0 .252.252 0 00.1-.183.246.246 0 00-.072-.2z\"/><path d=\"M21.5 0h-19A2.458 2.458 0 000 2.409v17.182a2.4 2.4 0 001.361 2.144.25.25 0 01.139.224V23a1 1 0 002 0v-.75a.25.25 0 01.25-.25h16.5a.25.25 0 01.25.25V23a1 1 0 002 0v-1.041a.25.25 0 01.139-.224A2.4 2.4 0 0024 19.591V2.409A2.458 2.458 0 0021.5 0zM20 11a8 8 0 11-8-8 8.009 8.009 0 018 8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});