define("ember-svg-jar/inlined/shopping-basket-dislike", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-basket-dislike</title><path d=\"M1.714 8.75a.25.25 0 00-.245.3l.358 1.72a.251.251 0 01-.05.208 8 8 0 1013.467 8.415.249.249 0 01.226-.144H20a.5.5 0 00.489-.4l2.042-9.8a.25.25 0 00-.245-.3zM8 22a6 6 0 116-6 6.006 6.006 0 01-6 6zM24 6.75v-1a1.5 1.5 0 00-1.5-1.5h-1.192a.248.248 0 01-.2-.105L18.518.524a1.251 1.251 0 00-2.036 1.453l1.342 1.878a.249.249 0 01-.2.4H6.156a.25.25 0 01-.217-.374L7.085 1.87A1.25 1.25 0 004.915.63l-2 3.494a.251.251 0 01-.215.126H1.5A1.5 1.5 0 000 5.75v1a.5.5 0 00.5.5h23a.5.5 0 00.5-.5z\"/><path d=\"M9.105 18.189a.206.206 0 01.037-.177.21.21 0 01.165-.08h1.735a1.205 1.205 0 001.177-1.476l-.7-2.344a2.206 2.206 0 00-2.153-1.842 35.9 35.9 0 01-.89-.012A5.941 5.941 0 004.8 13.2a.994.994 0 00-.556.9v2.656a1 1 0 001 1h.3a.208.208 0 01.17.088l1.73 2.439a1.106 1.106 0 001.974-.917zm-.286 1.837z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});