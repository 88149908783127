define("ember-svg-jar/inlined/cash-toggles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-toggles</title><path d=\"M18.034 8.723l.327 1.077a.986.986 0 001.888 0l.328-1.076a.735.735 0 01.869-.5l1.091.253a.992.992 0 00.944-1.644l-.764-.823a.744.744 0 010-1.009l.764-.823a.992.992 0 00-.944-1.643l-1.091.253a.735.735 0 01-.869-.5L20.249 1.2a.986.986 0 00-1.888 0l-.327 1.076a.736.736 0 01-.869.5l-1.091-.246a.992.992 0 00-.944 1.643l.763.827a.742.742 0 010 1.009l-.763.823a.992.992 0 00.944 1.644l1.091-.253a.735.735 0 01.869.5zm1.271-4.66A1.438 1.438 0 1117.868 5.5a1.438 1.438 0 011.437-1.437zM15.654 14.275a1.806 1.806 0 00-1.3-3.144l-.933.048a.78.78 0 01-.612-.236.8.8 0 01-.235-.61l.048-.935A1.806 1.806 0 009.475 8.1l-.627.693a.832.832 0 01-1.2 0L7.024 8.1a1.805 1.805 0 00-3.143 1.3l.047.934a.806.806 0 01-.846.847l-.934-.048a1.837 1.837 0 00-1.761 1.111 1.808 1.808 0 00.458 2.031l.7.627a.808.808 0 010 1.2l-.695.627a1.806 1.806 0 001.3 3.144l.934-.048a.763.763 0 01.611.236.806.806 0 01.235.611l-.047.934a1.806 1.806 0 003.143 1.3l.627-.694a.832.832 0 011.2 0l.627.694a1.806 1.806 0 003.144-1.3l-.048-.934a.809.809 0 01.235-.612.782.782 0 01.612-.235l.933.048a1.806 1.806 0 001.3-3.144l-.696-.629a.806.806 0 010-1.2zm-8.488.02l2.71 1.015a2.078 2.078 0 01-.666 3.9.25.25 0 00-.21.247V20a.75.75 0 01-1.5 0v-.5a.249.249 0 00-.25-.25h-1a.75.75 0 010-1.5h2.5c.5 0 .75-.173.75-.514a.745.745 0 00-.166-.53l-2.71-1.015A1.991 1.991 0 015.5 13.765a1.974 1.974 0 011.789-1.979.249.249 0 00.211-.247V11A.75.75 0 119 11v.5a.25.25 0 00.25.25h1a.75.75 0 010 1.5h-2.5c-.5 0-.75.173-.75.514a.747.747 0 00.166.536z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});