define("ember-svg-jar/inlined/single-neutral-actions-key.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-key</title><path d=\"M11.494 10.329l-.628-.231a.587.587 0 01-.138-.31 2.579 2.579 0 01.058-1.3 5.617 5.617 0 001.465-4.313C12.251 1.76 10.673 0 8.5 0S4.742 1.76 4.742 4.177a5.6 5.6 0 001.451 4.3 2.456 2.456 0 01.072 1.316.607.607 0 01-.134.308l-.631.233c-2.446.9-4.213 1.549-4.752 2.625A9.163 9.163 0 000 16.5a.5.5 0 00.5.5h9.417a.249.249 0 00.223-.138 5.654 5.654 0 01.3-.523 5.961 5.961 0 015.706-2.682.25.25 0 00.227-.1.248.248 0 00.035-.243 4.176 4.176 0 00-.159-.362c-.542-1.074-2.309-1.723-4.755-2.623z\"/><path d=\"M24 11.63a.5.5 0 00-.5-.5h-1.978a.5.5 0 00-.343.137l-4.187 3.956a.252.252 0 01-.245.057 4.454 4.454 0 10-1.791 8.687 4.451 4.451 0 004.768-5.7.252.252 0 01.063-.249l4.067-4.056a.5.5 0 00.146-.354zm-7.161 8.987a1.736 1.736 0 110-2.455 1.734 1.734 0 010 2.455z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});