define("ember-svg-jar/inlined/wifi-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-warning</title><path d=\"M15.479 8.562a1 1 0 001.414-1.414A9.878 9.878 0 009.86 4.234a9.88 9.88 0 00-7.034 2.914A1 1 0 004.24 8.562a7.96 7.96 0 0111.239 0z\"/><path d=\"M18.009 5.362a1 1 0 101.414-1.414 13.545 13.545 0 00-19.129 0 1 1 0 101.414 1.414 11.542 11.542 0 0116.301 0zM12.3 11.248a1 1 0 001.114-1.658 6.377 6.377 0 00-8.059.781 1 1 0 101.414 1.414 4.374 4.374 0 015.531-.537zM18.781 12.277a1.449 1.449 0 00-2.561 0l-5.055 9.633a1.428 1.428 0 00.047 1.409 1.454 1.454 0 001.233.687h10.111a1.456 1.456 0 001.233-.686 1.428 1.428 0 00.047-1.409zM17.5 15.256a.75.75 0 01.75.75v3a.75.75 0 01-1.5 0v-3a.75.75 0 01.75-.75zm0 7a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});