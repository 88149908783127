define("ember-svg-jar/inlined/phone-selfie-front", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-selfie-front</title><path d=\"M23.78 17.927a.75.75 0 000-1.061l-.293-.293A1.653 1.653 0 0123 15.4v-2a3.14 3.14 0 00-.927-2.237l-1.5-1.5a.249.249 0 01-.073-.177V4a3 3 0 00-3-3h-9a3 3 0 00-3 3v17a3 3 0 003 3h9a2.992 2.992 0 002.225-.99.25.25 0 01.362-.01l.779.779a.75.75 0 001.061-1.06l-1.793-1.793A2.18 2.18 0 0119.5 19.4v-1.88a.747.747 0 00-.22-.531l-2.217-2.214a.75.75 0 011.061-1.061l1.406 1.406a.75.75 0 001.061-1.06l-.018-.018a.249.249 0 01-.073-.177v-1.55a.25.25 0 01.427-.177l.086.086A1.653 1.653 0 0121.5 13.4v2a3.141 3.141 0 00.927 2.238c.373.368.83.812 1.353.289zM17.5 22h-9a1 1 0 01-1-1v-1.75a.25.25 0 01.25-.25h10a.25.25 0 01.25.25v.15a3.672 3.672 0 00.433 1.723.251.251 0 01.016.2A1 1 0 0117.5 22zM16 12.65a2.236 2.236 0 00-.649 1.4.251.251 0 01-.336.214l-.236-.087c-.565-.21-.472-1.685-.222-1.96a4.173 4.173 0 001.085-3.233A2.725 2.725 0 0013 6a2.725 2.725 0 00-2.645 2.985 4.173 4.173 0 001.085 3.233c.25.275.343 1.75-.222 1.96a23.682 23.682 0 00-3.341 1.38.25.25 0 01-.377-.215V4a1 1 0 011-1h9a1 1 0 011 1v7.83a.249.249 0 01-.1.2.252.252 0 01-.217.043A2.253 2.253 0 0016 12.65zM4.571 2.005l-.265-.038A1.507 1.507 0 013.033.694L3 .429a.5.5 0 00-.99 0l-.043.265A1.507 1.507 0 01.693 1.967l-.264.038a.5.5 0 000 .99l.265.038a1.507 1.507 0 011.273 1.273l.038.265a.5.5 0 00.99 0l.038-.265a1.507 1.507 0 011.274-1.273L4.571 3a.5.5 0 000-.99z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});