define("ember-svg-jar/inlined/single-woman-actions-flag.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-flag</title><path d=\"M12.5 13.513a.5.5 0 00-.192-.394A7.465 7.465 0 007.7 11.5a7.543 7.543 0 00-7.183 5.357A.5.5 0 001 17.5h11a.5.5 0 00.5-.5zM23.5 12.621a.5.5 0 00-.71-.454c-2.177 1.011-3.06.406-3.6.04-2.093-1.419-4.233-.274-4.867-.059a.5.5 0 00-.34.473L14 23a1 1 0 002 0v-4.662a.25.25 0 01.19-.243 3.048 3.048 0 012.447.44 4.025 4.025 0 002.271.7 4.741 4.741 0 002.309-.663.5.5 0 00.291-.454zM1.565 10.442a1 1 0 001.383-.3 5.233 5.233 0 00.454-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .249.249 0 01.411.082 5.233 5.233 0 00.454.9 1 1 0 001.681-1.082 4.569 4.569 0 01-.646-2.511V5.3a5.3 5.3 0 10-10.59 0v1.248a4.564 4.564 0 01-.643 2.512 1 1 0 00.298 1.382zM7.207 8.6a2.7 2.7 0 01-2.741-2.352.5.5 0 01.324-.577 5.7 5.7 0 002.245-1.439.25.25 0 01.361 0 5.7 5.7 0 002.23 1.437.5.5 0 01.323.576A2.708 2.708 0 017.207 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});