define("ember-svg-jar/inlined/hardware-nail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hardware-nail</title><path d=\"M23.521 6.129L17.864.472a1.536 1.536 0 00-2.121 0l-1.415 1.414a1.5 1.5 0 000 2.122l5.657 5.657a1.5 1.5 0 002.121 0l1.414-1.414a1.5 1.5 0 00.001-2.122zM12.5 14.533H5.425a.5.5 0 00-.353.146l-1 1a.5.5 0 00.353.854H11.5a.5.5 0 00.354-.147l1-1a.5.5 0 00-.354-.853zM14.329 6.129a.5.5 0 00-.707 0l-6.051 6.05a.5.5 0 00.354.854H15a.5.5 0 00.354-.147l2.515-2.514a.5.5 0 000-.707zM9 18.033H1.925a.5.5 0 00-.353.146l-.323.324a1.527 1.527 0 00-.386.664c0 .016-.009.033-.012.049l-.8 4.142a.5.5 0 00.586.586l4.183-.813a.47.47 0 00.051-.012 1.5 1.5 0 00.622-.374l3.858-3.859A.5.5 0 009 18.033z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});