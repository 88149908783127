define("ember-svg-jar/inlined/dog-house-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dog-house-1</title><path d=\"M8.192 16.348A3.812 3.812 0 0112 12.541a3.736 3.736 0 011.751.441.251.251 0 00.332-.094 5.572 5.572 0 012.934-2.522 1.475 1.475 0 01.435-.065 1.5 1.5 0 011.5 1.5v.815a.836.836 0 00.759.832h.014a.835.835 0 00.911-.833v-.931a.25.25 0 01.427-.177l1 1a1 1 0 001.414-1.414L13.384 1A1.945 1.945 0 0012 .429 1.941 1.941 0 0010.616 1L.524 11.094a1 1 0 001.414 1.414l1-1a.25.25 0 01.427.176V22.57a.978.978 0 00.979.978h2.87a.831.831 0 00.893-.873c0-1.656.033-2.438.085-6.327z\"/><path d=\"M9.7 19.873a.25.25 0 00-.094.2v3.252a.25.25 0 00.25.25h6.85a.252.252 0 00.2-.106l.159-.227a.251.251 0 00-.027-.321l-4.791-4.792a.251.251 0 00-.333-.019zM23.486 17.836c0-.333-.27-.9-.6-.9h-1.21a.6.6 0 01-.6-.6v-.6a1.208 1.208 0 00-1.207-1.207H17.7a.25.25 0 01-.25-.25v-2.096a.252.252 0 00-.121-.214.249.249 0 00-.245-.007 5.188 5.188 0 00-2.536 4.05l-1.055.841a.249.249 0 00-.021.372l4.384 4.385a.247.247 0 00.2.072.252.252 0 00.183-.1l.362-.514a1.2 1.2 0 01.986-.511h2.089a1.81 1.81 0 001.81-1.811z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});