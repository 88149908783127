define("ember-svg-jar/inlined/download-brackets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>download-brackets</title><path d=\"M21.8 0h-4.412a1.225 1.225 0 100 2.449H21.3a.25.25 0 01.25.25V21.3a.25.25 0 01-.25.25h-3.912a1.225 1.225 0 100 2.449H21.8A2.207 2.207 0 0024 21.8V2.2A2.207 2.207 0 0021.8 0zM2.2 24h4.412a1.225 1.225 0 100-2.449H2.7a.25.25 0 01-.25-.25V2.7a.25.25 0 01.25-.25h3.912a1.225 1.225 0 100-2.45H2.2A2.207 2.207 0 000 2.2v19.6A2.207 2.207 0 002.2 24z\"/><path d=\"M12 4.653a1.469 1.469 0 00-1.469 1.469v5.733a.25.25 0 01-.25.25H7.1a.979.979 0 00-.739 1.622l4.9 5.633a.979.979 0 001.478 0l4.9-5.633a.979.979 0 00-.739-1.622h-3.181a.25.25 0 01-.25-.25V6.122A1.469 1.469 0 0012 4.653z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});