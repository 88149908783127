define("ember-svg-jar/inlined/adventure-car-atv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>adventure-car-atv</title><path d=\"M23.2 14.645a.249.249 0 01-.2-.245v-3.514a1.262 1.262 0 00-1.261-1.261H17a.406.406 0 01-.276-.171l-.606-1.211a1.118 1.118 0 00-2.118.5v5.882a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25v-3.5a1.5 1.5 0 00-.731-1.288.248.248 0 01-.1-.307l.97-2.43A1.14 1.14 0 019.2 6.375h.55a1 1 0 000-2H9.2a3.132 3.132 0 00-2.919 1.979L5.036 9.468a.25.25 0 01-.232.157h-1.06a1.492 1.492 0 00-1.437 1.069L1.165 14.5a.173.173 0 01-.165.125 1 1 0 000 2h.833a.251.251 0 00.236-.167 3.11 3.11 0 016.044.925.25.25 0 00.25.242h7.274a.25.25 0 00.25-.242 3.11 3.11 0 016.044-.925.251.251 0 00.236.167H23a1 1 0 00.2-1.98z\"/><rect x=\"9\" y=\"11.625\" width=\"4\" height=\"2.25\" rx=\".5\" ry=\".5\"/><circle cx=\"5\" cy=\"17.5\" r=\"2.125\"/><circle cx=\"19\" cy=\"17.5\" r=\"2.125\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});