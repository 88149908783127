define("ember-svg-jar/inlined/single-neutral-actions-player.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-player</title><path d=\"M10.5 12a1.366 1.366 0 01.03-.288.5.5 0 00-.286-.557 7.4 7.4 0 00-3.038-.655 7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643H10a.5.5 0 00.5-.5z\"/><circle cx=\"7\" cy=\"4.75\" r=\"4.75\"/><path d=\"M22.125 12h-8.25A1.877 1.877 0 0012 13.875v8.25A1.877 1.877 0 0013.875 24h8.25A1.877 1.877 0 0024 22.125v-8.25A1.877 1.877 0 0022.125 12zm-1.731 6a.932.932 0 01-.452.8l-3.033 1.82a.93.93 0 01-1.409-.8v-3.64a.93.93 0 011.408-.8l3.034 1.82a.932.932 0 01.452.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});