define("ember-svg-jar/inlined/design-drawing-board-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-drawing-board-1</title><path d=\"M11 23a1 1 0 102 0v-3.75a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25zM3.105 22.551a1 1 0 001.79.894l2.042-4.085a.25.25 0 00-.223-.36H5.036a.249.249 0 00-.223.138zM19.105 23.445a1 1 0 001.79-.894l-1.708-3.415a.249.249 0 00-.223-.136h-1.678a.25.25 0 00-.223.362zM20.5 3h-7.25a.25.25 0 01-.25-.25V1a1 1 0 10-2 0v1.75a.25.25 0 01-.25.25H3.5A2.5 2.5 0 001 5.5v9A2.5 2.5 0 003.5 17h17a2.5 2.5 0 002.5-2.5v-9A2.5 2.5 0 0020.5 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});