define("ember-svg-jar/inlined/ym", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ym</title><path d=\"M22 4.5H2A1.5 1.5 0 00.5 6v12A1.5 1.5 0 002 19.5h20a1.5 1.5 0 001.5-1.5V6A1.5 1.5 0 0022 4.5zM11.625 10A2.621 2.621 0 019.8 12.492a.247.247 0 00-.176.233V15a.625.625 0 01-1.25 0v-2.275a.247.247 0 00-.176-.233A2.621 2.621 0 016.375 10V9a.625.625 0 111.25 0v1a1.375 1.375 0 002.75 0V9a.625.625 0 111.25 0zm5 5a.625.625 0 01-1.25 0v-2.975c0-.136-.148-.081-.188 0l-.128.256a.65.65 0 01-1.118 0s-.1-.187-.136-.271-.18-.066-.18.01V15a.625.625 0 01-1.25 0V9a.625.625 0 011.184-.279s.747 1.484.794 1.587a.182.182 0 00.3-.006l.791-1.581A.625.625 0 0116.625 9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});