define("ember-svg-jar/inlined/user-woman-stress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>user-woman-stress</title><path d=\"M14.571 16.471a.251.251 0 00-.292.088L12.2 19.465a.25.25 0 01-.406 0l-2.073-2.906a.251.251 0 00-.292-.088 7.26 7.26 0 00-4.679 6.778.75.75 0 00.75.751h13a.75.75 0 00.75-.75 7.26 7.26 0 00-4.679-6.779zM12 16.2a5.192 5.192 0 003-.951 5.281 5.281 0 00.6-.489.25.25 0 01.393.063 4.486 4.486 0 00.809 1.089 1 1 0 001.4-1.424c-.42-.415-.979-1.231-.979-3.513a5.225 5.225 0 10-10.45 0c0 2.282-.559 3.1-.979 3.513a1 1 0 101.4 1.424 4.486 4.486 0 00.809-1.089.25.25 0 01.397-.063 5.281 5.281 0 00.6.489 5.192 5.192 0 003 .951zm-1.5-5.451a3.2 3.2 0 001.319-1.125.249.249 0 01.41-.019 5.247 5.247 0 001.271 1.144 3.567 3.567 0 001.447.6.251.251 0 01.168.105.246.246 0 01.039.2 3.224 3.224 0 01-6.343-.2.25.25 0 01.212-.284 5.025 5.025 0 001.477-.421zM22.207 1.792a1 1 0 00-1.414 0l-2 2a1 1 0 00-.125 1.262l.344.516a.25.25 0 01-.058.339L17.9 6.7a1 1 0 001.2 1.6l2-1.5a1 1 0 00.232-1.354L20.9 4.8a.25.25 0 01.031-.315l1.275-1.276a1 1 0 00.001-1.417zM5.5 8.5a1 1 0 00.6-1.8l-1.054-.791a.25.25 0 01-.058-.339l.344-.516a1 1 0 00-.125-1.262l-2-2a1 1 0 00-1.414 1.414l1.275 1.276A.25.25 0 013.1 4.8l-.431.648A1 1 0 002.9 6.8l2 1.5a.994.994 0 00.6.2zM11.293 4.207a1 1 0 001.414-1.414l-.275-.276A.25.25 0 0112.4 2.2l.431-.647A1 1 0 1011.168.445l-1 1.5a1 1 0 00.125 1.262z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});