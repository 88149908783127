define("ember-svg-jar/inlined/webcam", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>webcam</title><path d=\"M14.75 8.5A2.75 2.75 0 1012 11.25a2.752 2.752 0 002.75-2.75zM11 8.5a1 1 0 111 1 1 1 0 01-1-1zM12 1.376a1.685 1.685 0 001.694-.915.251.251 0 00-.194-.329 8.6 8.6 0 00-3 0 .251.251 0 00-.193.329A1.685 1.685 0 0012 1.376z\"/><path d=\"M13.65 17.235a.25.25 0 01.125-.421A8.5 8.5 0 0015.472.74a.251.251 0 00-.341.152A3.132 3.132 0 0112 2.876 3.132 3.132 0 018.869.892.251.251 0 008.528.74a8.5 8.5 0 001.7 16.074.25.25 0 01.125.421l-5.06 5.058a1 1 0 001.414 1.414l3.866-3.866a.25.25 0 01.427.177V23a1 1 0 002 0v-2.982a.25.25 0 01.427-.177l3.866 3.866a1 1 0 001.414-1.414zM7.75 8.5A4.25 4.25 0 1112 12.75 4.255 4.255 0 017.75 8.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});