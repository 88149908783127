define("@gavant/ember-shopify-draggable/components/swappable-group", ["exports", "@ember/component", "@gavant/ember-shopify-draggable/templates/components/swappable-group", "@ember/object", "@ember/object/computed", "@ember/array", "@gavant/ember-shopify-draggable/mixins/base-group"], function (_exports, _component, _swappableGroup, _object, _computed, _array, _baseGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_baseGroup.default, {
    layout: _swappableGroup.default,
    classNames: ['swappable-group'],
    swappable: (0, _computed.alias)('shopifyInstance'),
    swappableEvents: (0, _array.A)(['swap', 'swapped', 'start', 'stop']),
    _events: (0, _array.A)(['drag:start', 'drag:stop', 'swappable:stop', 'swappable:swap']),
    initializePublicEventListeners() {
      this._super(...arguments);
      this.bindEventListenersType('swappable');
    },
    async didInsertElement() {
      this._super(...arguments);
      const {
        Swappable,
        Plugins
      } = await emberAutoImportDynamic("@shopify/draggable");
      const plugins = (0, _array.A)();
      if ((0, _object.get)(this, 'resizeMirror')) {
        plugins.pushObject(Plugins.ResizeMirror);
      }
      if ((0, _object.get)(this, 'snappable')) {
        plugins.pushObject(Plugins.Snappable);
      }
      if ((0, _object.get)(this, 'collidable')) {
        plugins.pushObject(Plugins.Collidable);
      }
      const shopifyInstance = new Swappable([], {
        draggable: '.swappable-item',
        delay: (0, _object.get)(this, 'delay'),
        handle: (0, _object.get)(this, 'handle'),
        mirror: (0, _object.get)(this, 'mirrorOptions'),
        collidables: (0, _object.get)(this, 'collidables'),
        plugins
      });
      (0, _object.setProperties)(this, {
        shopifyInstance,
        plugins: Plugins
      });
      //Public Events
      this.initializePublicEventListeners();
      //Private Events
      this.initializePrivateEventListeners();
      this.trigger('setupContainers');
    }
  });
});