define("ember-svg-jar/inlined/police-man-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-man-4</title><path d=\"M.5 23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-2.106a2.462 2.462 0 00-.967-1.977C21.106 18.328 17.839 16.5 12 16.5s-9.106 1.828-10.533 2.917A2.462 2.462 0 00.5 21.394zm14.75-3.07v-.93a.25.25 0 01.25-.25h3a.25.25 0 01.25.25v.93a2.246 2.246 0 01-1 1.872l-.609.406a.251.251 0 01-.278 0l-.609-.406a2.249 2.249 0 01-1.004-1.872zM12 16.5a7.008 7.008 0 007-7V7.939a.249.249 0 01.211-.247 40.647 40.647 0 002.506-.466 1 1 0 10-.434-1.952c-.918.2-1.862.364-2.817.5a.249.249 0 01-.279-.191l-.663-2.873A3.485 3.485 0 0014.113 0H9.887a3.485 3.485 0 00-3.411 2.713l-.663 2.873a.249.249 0 01-.279.191c-.955-.139-1.9-.3-2.817-.5a1 1 0 00-.434 1.952c.82.182 1.658.333 2.506.466A.25.25 0 015 7.939V9.5a7.008 7.008 0 007 7zM10.151 2.641a.532.532 0 01.366-.141h2.965a.532.532 0 01.366.141.414.414 0 01.134.333 3.388 3.388 0 01-1.735 2.968.559.559 0 01-.495 0 3.388 3.388 0 01-1.734-2.968.415.415 0 01.133-.333zM12 14.5a5.006 5.006 0 01-5-5V8.268a.249.249 0 01.275-.249A47.247 47.247 0 0012 8.255a47.247 47.247 0 004.725-.236.25.25 0 01.275.249V9.5a5.006 5.006 0 01-5 5z\"/><circle cx=\"14\" cy=\"9.73\" r=\"1\"/><circle cx=\"10\" cy=\"9.73\" r=\"1\"/><path d=\"M13.077 11.862h-2a.639.639 0 00-.3 1.2 2.31 2.31 0 00.435.18 3.9 3.9 0 00.866.126h.014a2.963 2.963 0 00.856-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});