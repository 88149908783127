define("ember-svg-jar/inlined/layers-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layers-select</title><path d=\"M23 14a1 1 0 00-1 1v.5a.5.5 0 01-.5.5H21a1 1 0 000 2h1a2 2 0 002-2v-1a1 1 0 00-1-1zM22 0h-1a1 1 0 000 2h.5a.5.5 0 01.5.5V3a1 1 0 002 0V2a2 2 0 00-2-2zM7 4a1 1 0 001-1v-.5a.5.5 0 01.5-.5H9a1 1 0 000-2H8a2 2 0 00-2 2v1a1 1 0 001 1zM23 7a1 1 0 00-1 1v2a1 1 0 002 0V8a1 1 0 00-1-1zM16 0h-2a1 1 0 000 2h2a1 1 0 000-2zM8 18h1a1 1 0 000-2h-.5a.5.5 0 01-.5-.5V15a1 1 0 00-2 0v1a2 2 0 002 2zM7 11a1 1 0 001-1V8a1 1 0 00-2 0v2a1 1 0 001 1zM16 16h-2a1 1 0 000 2h2a1 1 0 000-2z\"/><path d=\"M16 22H2.5a.5.5 0 01-.5-.5V8a1 1 0 00-2 0v14a2 2 0 002 2h14a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});