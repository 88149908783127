define("ember-svg-jar/inlined/network-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>network-settings</title><circle cx=\"17.749\" cy=\"17.03\" r=\"1.5\"/><path d=\"M22.529 17.342a.462.462 0 010-.625l.987-1.065a1.783 1.783 0 00-1.7-2.954l-1.411.328a.456.456 0 01-.533-.311l-.424-1.392a1.775 1.775 0 00-3.4 0l-.424 1.392a.458.458 0 01-.533.311l-1.41-.326a1.784 1.784 0 00-1.7 2.954l.989 1.065a.462.462 0 010 .625l-.987 1.065a1.783 1.783 0 001.7 2.954l1.411-.328a.451.451 0 01.533.311l.424 1.392a1.775 1.775 0 003.4 0l.424-1.392a.447.447 0 01.533-.311l1.412.328a1.783 1.783 0 001.7-2.954zm-4.78 2.688a3 3 0 113-3 3 3 0 01-3 3z\"/><path d=\"M23.171 7.634A11.941 11.941 0 0011.938.028 12.253 12.253 0 00.034 11.106 12.011 12.011 0 0011.248 24a.91.91 0 00.908-.632.925.925 0 00-.272-.982 18.993 18.993 0 01-3.2-4.576.251.251 0 01.144-.347.988.988 0 00.672-.936 1 1 0 00-1-1h-.4a.25.25 0 01-.244-.2 15.606 15.606 0 01-.1-6.1A.248.248 0 018 9.025c10.543 0 13.454 0 14.268-.016a.939.939 0 00.903-1.375zM2.819 15.525a.25.25 0 01-.235-.166A9.886 9.886 0 012.407 9.2a.249.249 0 01.239-.179h2.807a.251.251 0 01.247.288 17.872 17.872 0 00-.2 2.712 17.27 17.27 0 00.295 3.2.248.248 0 01-.053.206.25.25 0 01-.192.091zm5.222 5.157a.251.251 0 01-.311.372 10.048 10.048 0 01-3.8-3.131.25.25 0 01.2-.4h2.094a.25.25 0 01.234.163 12.852 12.852 0 001.583 2.996zM6.3 6.853a.249.249 0 01-.237.172H3.8a.25.25 0 01-.22-.131.247.247 0 01.01-.254A10.035 10.035 0 017.733 3a.25.25 0 01.311.37A13.15 13.15 0 006.3 6.853zm2.425.172a.25.25 0 01-.231-.346A10.064 10.064 0 0111.852 2.3a.249.249 0 01.291 0A10.157 10.157 0 0115.5 6.679a.25.25 0 01-.231.346zm9.216 0a.25.25 0 01-.237-.171 13.181 13.181 0 00-1.776-3.522.249.249 0 01.308-.372 9.892 9.892 0 014.2 3.681.25.25 0 01-.211.384z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});