define("ember-svg-jar/inlined/keyboard-command", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-command</title><path d=\"M19.159 14.316h-1.591a.25.25 0 01-.25-.25V9.929a.25.25 0 01.25-.25h1.591a4.841 4.841 0 10-4.841-4.841v1.591a.25.25 0 01-.25.25H9.932a.25.25 0 01-.25-.25V4.838a4.841 4.841 0 10-4.841 4.841h1.591a.25.25 0 01.25.25v4.137a.25.25 0 01-.25.25H4.841a4.841 4.841 0 104.841 4.841v-1.591a.25.25 0 01.25-.25h4.136a.25.25 0 01.25.25v1.591a4.841 4.841 0 104.841-4.841zm-1.841-9.478a1.841 1.841 0 111.841 1.841h-1.591a.25.25 0 01-.25-.25zM3 4.838a1.841 1.841 0 013.682 0v1.591a.25.25 0 01-.25.25H4.841A1.843 1.843 0 013 4.838zm3.682 14.319a1.841 1.841 0 11-1.841-1.841h1.591a.25.25 0 01.25.25zm3-9.228a.25.25 0 01.25-.25h4.136a.25.25 0 01.25.25v4.137a.25.25 0 01-.25.25H9.932a.25.25 0 01-.25-.25zM19.159 21a1.844 1.844 0 01-1.841-1.84v-1.594a.25.25 0 01.25-.25h1.591a1.841 1.841 0 110 3.681z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});