define("ember-svg-jar/inlined/cd-rom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cd-rom</title><path d=\"M2 10.5h20a.5.5 0 00.48-.641l-2.369-8.105A3.076 3.076 0 0017.247 0H6.753a3.068 3.068 0 00-2.869 1.775L1.52 9.86a.5.5 0 00.48.64zM20.5 12h-17A2.5 2.5 0 001 14.5v2.254a2.5 2.5 0 002.5 2.5h.694a.252.252 0 00.249-.276 5.441 5.441 0 01-.033-.587 4.7 4.7 0 01.408-2.026.249.249 0 00-.224-.361h-.078a.75.75 0 110-1.5H19.5a.75.75 0 110 1.5h-.178a.249.249 0 00-.223.362 4.577 4.577 0 01.4 1.989 5.785 5.785 0 01-.036.621.249.249 0 00.249.278h.788a2.5 2.5 0 002.5-2.5V14.5a2.5 2.5 0 00-2.5-2.5z\"/><path d=\"M18 18.6a4.993 4.993 0 00-.687-2.47.249.249 0 00-.218-.127H6.819a.249.249 0 00-.218.128 5.124 5.124 0 00-.691 2.505C5.91 21.56 8.617 24 11.955 24S18 21.523 18 18.6zm-6-1.847a2 2 0 11-2 2 2 2 0 012-1.998z\"/><circle cx=\"12\" cy=\"18.755\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});