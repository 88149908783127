define("ember-svg-jar/inlined/cat-yarn-toy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cat-yarn-toy</title><path d=\"M5.36 11.784a.25.25 0 00-.339-.083 5.382 5.382 0 00-1.091.825 3.64 3.64 0 00-.305.365.249.249 0 00-.019.274 19.38 19.38 0 007.859 7.709.252.252 0 00.271-.023 2.744 2.744 0 00.283-.236 5.455 5.455 0 00.875-1.17.25.25 0 00.021-.2.254.254 0 00-.134-.151 21.817 21.817 0 01-7.421-7.31zM2.923 14.975a.25.25 0 00-.451.066 5.578 5.578 0 00-.217 1.53 5.721 5.721 0 005.72 5.72 5.743 5.743 0 001.669-.247.25.25 0 00.057-.453 20.715 20.715 0 01-6.778-6.616zM20.178 5.114a.494.494 0 00-.478-.046.5.5 0 00-.3.378c-.043.259-1.052 5.872-6.277 6.787 3.936-3.9 2.073-7.022 1.346-7.941C12.584 1.9 8.76.945 6.893 2.392a.5.5 0 00.078.839c.244.126 5.711 3.046 4.395 8.761a5.7 5.7 0 00-4.343-1.042.25.25 0 00-.168.383 21.074 21.074 0 006.388 6.314.251.251 0 00.373-.175 5.488 5.488 0 00.079-.9 5.7 5.7 0 00-.457-2.235 6.027 6.027 0 003.14.774c3.292 0 4.522-1.9 4.971-3.113.977-2.638-.024-6.105-1.171-6.884z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});