define("ember-svg-jar/inlined/hamster-wheel-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hamster-wheel-1</title><path d=\"M19.1 15.238a8.539 8.539 0 10-14.2 0L3.236 20.06a1.476 1.476 0 001.386 1.979h14.756a1.476 1.476 0 001.389-1.971zM12 3.961A6.539 6.539 0 115.461 10.5 6.547 6.547 0 0112 3.961z\"/><path d=\"M6.514 11.058a5.776 5.776 0 005.665 4.624 5.688 5.688 0 005.114-3.146 2.648 2.648 0 00-1.076-2.409 2.563 2.563 0 00-1.949-.415 2.733 2.733 0 01.057 1.762 2.7 2.7 0 01-1.686 1.759.725.725 0 01-.485-1.367 1.258 1.258 0 00.787-.822 1.671 1.671 0 00-2.3-1.874 1.912 1.912 0 00-1.161 1.1 5.381 5.381 0 00-2.446.16c-.268.095-.606.207-.52.628zm2.867 1.883a.883.883 0 11.541-1.125.885.885 0 01-.541 1.125zM2.321 5.029a1 1 0 00-1.312.528 12.243 12.243 0 000 9.552 1 1 0 001.84-.783 10.247 10.247 0 010-7.986 1 1 0 00-.528-1.311zM22.991 5.557a1 1 0 00-1.84.783 10.249 10.249 0 010 7.987 1 1 0 001.84.783 12.245 12.245 0 000-9.553z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});