define("ember-svg-jar/inlined/social-media-renren-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-renren-1</title><path d=\"M12 7.07A7 7 0 008 5v6.57a5.46 5.46 0 004 5.27A7 7 0 0110 12a6.92 6.92 0 012-4.93zM7 15.35a7.61 7.61 0 01-3 2.9 6.91 6.91 0 006.09 0A7.43 7.43 0 017 15.35zM6 11.61V5a7 7 0 00-4 11.87 5.52 5.52 0 004-5.26zM18 5v6.57a5.45 5.45 0 004 5.27A7 7 0 0018 5zM17 15.35a7.61 7.61 0 01-3 2.9 6.91 6.91 0 006.09 0 7.43 7.43 0 01-3.09-2.9z\"/><path d=\"M16 11.61V5a7 7 0 00-4 2 7 7 0 010 9.79 5.53 5.53 0 004-5.18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});