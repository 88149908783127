define("ember-svg-jar/inlined/volume-control-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>volume-control-up</title><path d=\"M22.75 10.748h-2a.25.25 0 01-.25-.25v-2a1.25 1.25 0 00-2.5 0v2a.25.25 0 01-.25.25h-2a1.25 1.25 0 000 2.5h2a.25.25 0 01.25.25v2a1.25 1.25 0 002.5 0v-2a.25.25 0 01.25-.25h2a1.25 1.25 0 000-2.5zM11.293 2.291L5.366 8.218a.249.249 0 01-.177.073H2a2 2 0 00-2 2v3.414a2 2 0 002 2h3.189a.25.25 0 01.177.074l5.926 5.926A1 1 0 0013 21V3a1 1 0 00-1.707-.709z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});