define("ember-svg-jar/inlined/subtract-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>subtract-tab</title><path d=\"M.712 16.968a1.5 1.5 0 001.5 1.5H16a3.382 3.382 0 002.067-.832l4.775-4.584a1.455 1.455 0 000-2.107l-4.78-4.581A3.385 3.385 0 0016 5.532H2.212a1.5 1.5 0 00-1.5 1.5zm3-5a1 1 0 011-1h7a1 1 0 010 2h-7a1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});