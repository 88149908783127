define("ember-svg-jar/inlined/touch-id-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>touch-id-1</title><path d=\"M19.422 3.912a.875.875 0 10-1.384 1.071 7.547 7.547 0 011.587 4.656v4.722A7.633 7.633 0 0112 21.986a.875.875 0 000 1.75 9.385 9.385 0 009.375-9.375V9.639a9.281 9.281 0 00-1.953-5.727zM9.035 21.388a7.613 7.613 0 01-4.66-7.027v-1.889a.875.875 0 00-1.75 0v1.889A9.358 9.358 0 008.354 23a.875.875 0 00.681-1.612zM3.465 10.041h.049a.876.876 0 00.873-.827 7.627 7.627 0 0111.427-6.179.875.875 0 10.877-1.514A9.376 9.376 0 002.64 9.12a.873.873 0 00.825.921z\"/><path d=\"M18.542 14.361V9.639a6.547 6.547 0 00-8.584-6.217.875.875 0 10.542 1.663 4.8 4.8 0 016.289 4.554v4.722a.875.875 0 101.75 0zM8.36 5.209a.874.874 0 00-1.235.069 6.527 6.527 0 00-1.667 4.361v4.722a6.542 6.542 0 0011.417 4.362.875.875 0 10-1.3-1.167 4.792 4.792 0 01-8.364-3.195V9.639a4.786 4.786 0 011.22-3.194.876.876 0 00-.071-1.236z\"/><path d=\"M10.042 14.361a.875.875 0 00-1.75 0 3.708 3.708 0 107.416 0V9.639a3.708 3.708 0 00-7.416 0v1.417a.875.875 0 001.75 0V9.639a1.958 1.958 0 013.916 0v4.722a1.958 1.958 0 11-3.916 0z\"/><path d=\"M12.875 13.889v-2.833a.875.875 0 10-1.75 0v2.833a.875.875 0 001.75 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});