define("ember-svg-jar/inlined/car-repair-bottom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-repair-bottom</title><path d=\"M15.9 19.885l-.409-.235a.25.25 0 01-.121-.262 3.529 3.529 0 00.057-.638 3.4 3.4 0 00-.065-.658.218.218 0 01.106-.231l.423-.245a.5.5 0 00.183-.683l-.5-.859a.5.5 0 00-.69-.179l-.365.219a.252.252 0 01-.292-.025 3.582 3.582 0 00-1.071-.636.251.251 0 01-.156-.234v-.469a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v.469a.249.249 0 01-.165.234 3.942 3.942 0 00-1.144.642.249.249 0 01-.283.026l-.429-.243a.5.5 0 00-.679.183l-.508.872a.5.5 0 00-.051.38.5.5 0 00.233.3l.419.243a.219.219 0 01.1.232 3.4 3.4 0 00-.066.658 3.529 3.529 0 00.059.638.248.248 0 01-.12.262l-.406.234a.5.5 0 00-.183.683l.5.872a.5.5 0 00.677.187l.445-.248a.251.251 0 01.283.026 3.917 3.917 0 001.145.644.248.248 0 01.165.234v.468a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-.468a.251.251 0 01.162-.235 3.574 3.574 0 001.073-.637.25.25 0 01.29-.026l.382.225a.5.5 0 00.688-.182l.492-.86a.5.5 0 00-.179-.678zm-3.9.365a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/><path d=\"M23 22h-.25a.25.25 0 01-.25-.25V5a1 1 0 00-2 0v5.75a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25V9.729a.249.249 0 01.208-.246A1.253 1.253 0 0019.25 8.25V6.707a1.284 1.284 0 00-.406-.921l-1.226-1.061a.5.5 0 01-.156-.248l-.9-3.352A1.5 1.5 0 0015.109 0H8.891a1.494 1.494 0 00-1.452 1.12l-.9 3.358a.5.5 0 01-.156.248l-1.266 1.1a1.258 1.258 0 00-.366.884v1.54a1.253 1.253 0 001.041 1.233.249.249 0 01.208.246v1.021a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25V5a1 1 0 00-2 0v16.75a.25.25 0 01-.25.25H1a1 1 0 000 2h3a1 1 0 000-2h-.25a.25.25 0 01-.25-.25v-8.5a.25.25 0 01.25-.25h16.5a.25.25 0 01.25.25v8.5a.25.25 0 01-.25.25H20a1 1 0 000 2h3a1 1 0 000-2zm-8-11.25a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25zm.068-7.1a.248.248 0 01-.2.1H9.13a.25.25 0 01-.242-.315l.336-1.25A.251.251 0 019.466 2h5.068a.251.251 0 01.242.185l.335 1.25a.252.252 0 01-.043.217z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});