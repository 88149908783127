define("ember-svg-jar/inlined/messages-bubble-edit-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-edit-1-alternate</title><path d=\"M10 15a1 1 0 00-.707.293L7 17.586V15.56a1 1 0 00-.664-.942A6.493 6.493 0 018.5 2h7A6.508 6.508 0 0122 8.5c0 .079 0 .157-.008.234a.25.25 0 00.207.261 4 4 0 011.378.507.25.25 0 00.377-.188A7.821 7.821 0 0024 8.5 8.51 8.51 0 0015.5 0h-7A8.492 8.492 0 005 16.232V20a1 1 0 001.707.707L10.414 17h1.222a.249.249 0 00.177-.073l1.5-1.5a.25.25 0 00-.178-.427z\"/><path d=\"M23.226 11.766a2.618 2.618 0 00-3.7 0l-6.679 6.679a.252.252 0 000 .354l3.353 3.353a.249.249 0 00.353 0l6.679-6.679a2.621 2.621 0 00-.006-3.707zM11.6 23.991l3.143-.629a.251.251 0 00.128-.422l-2.811-2.808a.25.25 0 00-.422.128l-.628 3.14a.5.5 0 00.588.588z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});