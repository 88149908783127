define("ember-svg-jar/inlined/check-payment-give", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>check-payment-give</title><path d=\"M22 2.8H6a2 2 0 00-2 2l.009 7.5a1 1 0 001 1 1 1 0 001-1L6 5.05a.255.255 0 01.073-.177.251.251 0 01.177-.073h15.5a.25.25 0 01.25.25v9.5a.25.25 0 01-.25.25H13.5a1 1 0 000 2H22a2 2 0 002-2v-10a2 2 0 00-2-2z\"/><path d=\"M10.288 15.155a1 1 0 00-.214-1.472 1.231 1.231 0 00-1.2-.094l-4.608 2.127A.89.89 0 013 14.906v-2.638a.252.252 0 00-.116-.212.249.249 0 00-.241-.015l-2.5 1.191a.25.25 0 00-.143.226v8.492a.25.25 0 00.333.236 22.236 22.236 0 009.716-6.745zM15.49 17.847l-5.478-.065a.25.25 0 00-.24.171l-.2.6a1 1 0 00.393 1.147l.114.076a1 1 0 001.325-.2l.983-1.186a.25.25 0 01.442.16v1.1a1 1 0 00.553.894l.276.138a1 1 0 001.425-.683.281.281 0 01.008-.031l.632-1.8a.25.25 0 00-.233-.333zM10.207 11.707l1.283-1.284a.71.71 0 011.187.325 1.356 1.356 0 002.069.8l.976-.651a.712.712 0 01.894.088l.718.718a1 1 0 00.707.293H19.5a1 1 0 000-2h-.941a.249.249 0 01-.177-.073l-.352-.352a2.716 2.716 0 00-3.418-.338l-.138.092a.25.25 0 01-.337-.055 2.707 2.707 0 00-4.061-.264l-1.283 1.287a1 1 0 001.414 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});