define("ember-svg-jar/inlined/organic-worm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>organic-worm</title><path d=\"M6.252 9.057a.5.5 0 00-.4.017 9.5 9.5 0 00-3.333 2.714.251.251 0 00.174.4 6.222 6.222 0 014 2 .253.253 0 00.173.1.25.25 0 00.189-.06 4.681 4.681 0 011.374-.82.5.5 0 00.316-.441 5 5 0 00-2.493-3.91zM1.807 13.209a.5.5 0 00-.354.265 12.777 12.777 0 00-1.019 2.834.25.25 0 00.307.3 6.434 6.434 0 014.319.5.25.25 0 00.359-.149 6.958 6.958 0 01.616-1.459.5.5 0 000-.5 4.959 4.959 0 00-4.228-1.791zM18.548 4.386a.5.5 0 00-.441.277 8.021 8.021 0 01-.895 1.421.251.251 0 00-.048.213.247.247 0 00.136.169 6.4 6.4 0 012.912 3.212.249.249 0 00.417.077 12.591 12.591 0 001.735-2.465.5.5 0 00.022-.433 4.9 4.9 0 00-3.838-2.471zM16.075 7.172a4.6 4.6 0 01-1.808.772.25.25 0 00-.181.347 6.444 6.444 0 01.217 4.337.251.251 0 00.042.239.246.246 0 00.225.091 9.517 9.517 0 004.9-2.1c-.026-1.374-1.97-3.709-3.395-3.686zM22.067.067a2.5 2.5 0 00-3 1.866 14.38 14.38 0 01-.314 1.138.249.249 0 00.209.325 6.347 6.347 0 013.8 2.186.25.25 0 00.427-.067 18.382 18.382 0 00.75-2.444A2.5 2.5 0 0022.067.067zM4.88 18.177a5.083 5.083 0 00-4.539-.337.5.5 0 00-.233.37 16.374 16.374 0 00-.019 3.613A2.5 2.5 0 002.568 24a2.539 2.539 0 00.312-.02 2.506 2.506 0 002.168-2.79 12.339 12.339 0 01.035-2.55.5.5 0 00-.203-.463zM12.527 7.991a14.381 14.381 0 00-4.761.371.25.25 0 00-.1.43 6.249 6.249 0 012.078 3.985.253.253 0 00.089.182.25.25 0 00.193.057 12.743 12.743 0 012.647 0 .5.5 0 00.44-.209 4.95 4.95 0 00-.221-4.614.5.5 0 00-.365-.202z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});