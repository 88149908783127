define("ember-svg-jar/inlined/filter-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>filter-text</title><path d=\"M16.1 3.318A2 2 0 0014.592 0H2A2 2 0 00.494 3.318l5.3 6.06v5.255A2 2 0 008.9 16.3l1-.666a2 2 0 00.892-1.665V9.377l5.3-6.059zM5.371 6.078l-2.81-3.25A.5.5 0 012.94 2h1a.5.5 0 01.37.164l1.81 2a.5.5 0 01.13.336v1.25a.5.5 0 01-.879.327zM18 14.751h2a.75.75 0 000-1.5h-2a.75.75 0 000 1.5zM16 17.751h4a.75.75 0 000-1.5h-4a.75.75 0 000 1.5zM16 20.751h4a.75.75 0 000-1.5h-4a.75.75 0 000 1.5z\"/><path d=\"M22 10h-8a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2V12a2 2 0 00-2-2zm0 11.75a.25.25 0 01-.25.25h-7.5a.25.25 0 01-.25-.25v-9.5a.25.25 0 01.25-.25h7.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});