define("ember-svg-jar/inlined/video-player-movie-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-player-movie-2-alternate</title><path d=\"M24 2.5A2.5 2.5 0 0021.5 0h-19A2.5 2.5 0 000 2.5v19A2.5 2.5 0 002.5 24h19a2.5 2.5 0 002.5-2.5zM20.5 5a.5.5 0 01.5.5v13a.5.5 0 01-.5.5h-17a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5z\"/><path d=\"M12 17a5 5 0 10-5-5 5 5 0 005 5zm-1.5-6.934a.308.308 0 01.461-.267l3.384 1.933a.309.309 0 010 .536L10.961 14.2a.308.308 0 01-.461-.267z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});