define("ember-svg-jar/inlined/style-three-pin-snorkel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-snorkel</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM8.5 7h5a3 3 0 013 3 2 2 0 01-2 2H14a2.968 2.968 0 01-2.1-.878.5.5 0 01-.147-.354V9.5a.75.75 0 00-1.5 0v1.271a.5.5 0 01-.147.354A2.968 2.968 0 018 12h-.5a2 2 0 01-2-2 3 3 0 013-3zM19 11.5a3.755 3.755 0 01-3.75 3.75h-2.087a.5.5 0 00-.434.25 1.995 1.995 0 01-3.458 0 .5.5 0 00-.434-.25H6.75a.75.75 0 010-1.5h2.087a.5.5 0 00.434-.25 1.995 1.995 0 013.458 0 .5.5 0 00.434.25h2.087a2.253 2.253 0 002.25-2.25V5A.75.75 0 0119 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});