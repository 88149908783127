define("ember-svg-jar/inlined/tools-nail-gun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-nail-gun</title><path d=\"M20.376 8.5v-7a1.5 1.5 0 00-1.5-1.5H4.75a.962.962 0 00-.724.333.5.5 0 01-.374.167H1.25a1 1 0 000 2h2a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-2a1 1 0 000 2h2a.5.5 0 01.5.5v16a1.5 1.5 0 001.5 1.5h5.626a4.505 4.505 0 004.5-4.5v-9a.5.5 0 01.5-.5h3a1.5 1.5 0 001.5-1.5zm-14 12.75a1 1 0 111-1 1 1 0 01-1.003 1zm0-10.5a1 1 0 111-1 1 1 0 01-1.003 1zm6 7.75a2 2 0 01-2 2h-.126a.5.5 0 01-.5-.5V10a.5.5 0 01.5-.5h.126a2 2 0 012 2zm5.25-14.75a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M23.424 18.653L20.9 11.826a.5.5 0 00-.468-.326h-3.194a.5.5 0 00-.464.686l4.6 11.5a.5.5 0 00.391.309.6.6 0 00.073 0 .5.5 0 00.391-.187l.535-.669a4.5 4.5 0 00.66-4.486z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});