define("ember-svg-jar/inlined/pets-tick-free", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pets-tick-free</title><path d=\"M17.927 8.652a2.564 2.564 0 01-1.436 1.384l-6.71 6.71a.512.512 0 00.088.793 3.922 3.922 0 002.131.589 3.752 3.752 0 003.4-1.928l.265.408a1.017 1.017 0 01.167.5v2.043a.766.766 0 101.532 0l-.006-2.137a2.632 2.632 0 00-.386-1.2l-.753-1.161a.766.766 0 00-.212-.216 6.828 6.828 0 00.09-.929 2.145 2.145 0 011.074 1.109l.254.593a.766.766 0 101.408-.6l-.254-.594a3.723 3.723 0 00-2.611-2.158c-.007-.037-.017-.074-.025-.112a4.176 4.176 0 003.406-2.526.766.766 0 00-.426-1 .828.828 0 00-.996.432z\"/><path d=\"M12 0a12 12 0 1012 12A12 12 0 0012 0zm10 12a10 10 0 01-16.482 7.614.5.5 0 01-.03-.734L18.88 5.489a.5.5 0 01.734.029A9.957 9.957 0 0122 12zM2 12a10 10 0 0115.629-8.266.5.5 0 01.071.766l-3.724 3.726c-.09-.072-.171-.161-.265-.224a2.042 2.042 0 10-3.422 0 4.206 4.206 0 00-.53.448c-.017-.007-.029-.022-.047-.028-.838-.309-.943-.591-1.334-1.637-.078-.21-.163-.437-.263-.686a.766.766 0 00-1.422.568c.1.238.176.455.25.654A3.475 3.475 0 008.811 9.7c-.1.175-.2.345-.283.533a2.656 2.656 0 01-2.455-1.58.766.766 0 10-1.422.568 4.176 4.176 0 003.408 2.518c-.008.038-.018.075-.025.112a3.723 3.723 0 00-2.611 2.158.766.766 0 001.408.6A2.143 2.143 0 017.905 13.5a6.8 6.8 0 00.051.744L4.5 17.7a.5.5 0 01-.767-.072A9.954 9.954 0 012 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});