define("ember-svg-jar/inlined/analytics-graph-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>analytics-graph-bar</title><path d=\"M1.25 21h3.5a.249.249 0 00.25-.25v-2a1 1 0 00-1-1H2a1 1 0 00-1 1v2a.249.249 0 00.25.25zM8 12.75a1 1 0 00-1 1v7a.249.249 0 00.25.25h3.5a.249.249 0 00.25-.25v-7a1 1 0 00-1-1zM14 14.75a1 1 0 00-1 1v5a.249.249 0 00.25.25h3.5a.249.249 0 00.25-.25v-5a1 1 0 00-1-1zM20 7.75a1 1 0 00-1 1v12a.249.249 0 00.25.25h3.5a.249.249 0 00.25-.25v-12a1 1 0 00-1-1zM21-.25a2 2 0 00-1.946 2.46.254.254 0 01-.054.221l-3.258 3.8a.253.253 0 01-.252.08A1.935 1.935 0 0015 6.25a2 2 0 00-1.417.591.247.247 0 01-.256.06l-2.178-.725a.252.252 0 01-.169-.2A2 2 0 007 6.25a1.971 1.971 0 00.055.464.25.25 0 01-.084.25l-2.9 2.42a.255.255 0 01-.262.037A2 2 0 105 11.25a2.023 2.023 0 00-.054-.463.25.25 0 01.083-.25l2.9-2.42a.25.25 0 01.261-.037 2 2 0 002.224-.421.25.25 0 01.257-.06l2.177.724a.253.253 0 01.168.2A2 2 0 0017 8.25a1.976 1.976 0 00-.16-.781.251.251 0 01.041-.261l3.044-3.552a.25.25 0 01.288-.067A2 2 0 1021-.25zM1 24.25h22a1 1 0 000-2H1a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});