define("ember-svg-jar/inlined/target-center-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>target-center-monitor</title><path d=\"M16.72 7.032a.249.249 0 01.209-.116h1.663a2.451 2.451 0 001.746-.723l2.385-2.385a.494.494 0 00-.349-.843h-2.717a.246.246 0 01-.247-.247V.5a.494.494 0 00-.843-.35l-2.385 2.38a2.456 2.456 0 00-.724 1.748v1.168a.247.247 0 01-.116.209.575.575 0 00-.089.069L11.3 9.676a.988.988 0 001.4 1.4l3.95-3.955a.457.457 0 00.07-.089z\"/><path d=\"M14.429 20.254h6.957a1.978 1.978 0 001.976-1.976V8.4a.988.988 0 00-1.976 0v7.163a.247.247 0 01-.247.247H2.861a.247.247 0 01-.247-.247V4.693a.248.248 0 01.247-.247h9.551a.988.988 0 100-1.975h-9.8A1.978 1.978 0 00.638 4.446v13.832a1.978 1.978 0 001.976 1.976h6.951a.247.247 0 01.236.319 8.409 8.409 0 01-.842 1.82.248.248 0 01-.211.12H7.576a.741.741 0 000 1.482l9.361-.041a.741.741 0 000-1.482l-1.711.008a.245.245 0 01-.213-.121 8.281 8.281 0 01-.82-1.791.248.248 0 01.037-.219.252.252 0 01.199-.095z\"/><path d=\"M11.027 8.1a.988.988 0 10-.779-1.816 4.447 4.447 0 106.028 5.316.988.988 0 00-1.9-.542A2.47 2.47 0 1111.027 8.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});