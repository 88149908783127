define("ember-svg-jar/inlined/elephant-draw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>elephant-draw</title><path d=\"M19.651 20.979a3.9 3.9 0 01-2.851-.049.5.5 0 01.316-.95 2.94 2.94 0 002.1.1c2.009-.984 2.719-4.692 2.719-5.551 0-.834-.864-2.5-2-2.5a5.262 5.262 0 00-1.8.463.5.5 0 01-.651-.654 5.713 5.713 0 00-3.725-.309c-2.515.419-3.5 3-5 4s-4 1.5-5-.5a2.039 2.039 0 011.038-2.761.5.5 0 00.161-.7L3.794 9.786a.5.5 0 00-.7-.139A6.611 6.611 0 00.256 13.53c-.5 2.5 0 4 1.5 5.5s5 1.5 5 1.5H7.4a4.3 4.3 0 01-2.832.914.751.751 0 00-.546 1.365 5.884 5.884 0 003.3.961 11.363 11.363 0 005.242-1.6.5.5 0 01.655.141l1.066 1.479a.5.5 0 00.4.208h8.744a.5.5 0 00.5-.5V14.02h-1.054c.21 1.027-.575 5.663-3.224 6.959zm-7.561-.273a9.954 9.954 0 01-4.7 1.561 15.994 15.994 0 003.676-2.842.823.823 0 111.02 1.282zm1.341-4.176a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M3.772 7.884l3.037 4.478a1.524 1.524 0 002.083.4 1.5 1.5 0 00.4-2.083l-3.052-4.5A2.448 2.448 0 006.1 4.3a3.528 3.528 0 00-2.13-1.868 3.4 3.4 0 01-1.832-.951.75.75 0 00-1.278.376C.148 5.6 1.675 8.059 3.772 7.884zm.609-1.659C2.882 6.956 2.416 5.2 2.416 5.2s1.631-1.068 1.652-1.068c.981.702.988 1.768.313 2.093zM21.931 0h-10a2 2 0 00-2 2v6.24a1 1 0 002 0V2h10v8.5a1 1 0 002 0V2a2 2 0 00-2-2z\"/><path d=\"M15.543 6.311L13.3 8.925a.5.5 0 00.379.825h6a.5.5 0 00.38-.825L17.82 6.311a1.547 1.547 0 00-2.277 0z\"/><circle cx=\"19.681\" cy=\"4.25\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});