define("ember-svg-jar/inlined/style-three-pin-climbing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-climbing</title><path d=\"M12 0C6.177 0 1.44 4.306 1.44 9.6c0 5.725 7.142 12.128 9.331 13.954a1.918 1.918 0 002.459 0c2.189-1.826 9.33-8.229 9.33-13.954C22.56 4.306 17.823 0 12 0zM9.6 2.88A1.92 1.92 0 117.68 4.8 1.922 1.922 0 019.6 2.88zM7 14.119a.965.965 0 01-.252-.912l.96-3.839a.955.955 0 01.7-.7l3.819-.955a.982.982 0 01.562 1.879l-2.844.995a.479.479 0 00-.308.338l-.427 1.732a.48.48 0 00.07.386l1.15 1.687a1.006 1.006 0 01-.119 1.277 1.007 1.007 0 01-1.42 0zm5.31 7.7a.478.478 0 01-.622 0 42.779 42.779 0 01-2.378-2.181.481.481 0 01-.117-.493 1.419 1.419 0 01.348-.564l2.317-2.317a.482.482 0 00.142-.341V13.8a1.444 1.444 0 01.346-.937l2.419-2.821a.479.479 0 00.115-.312V7.118A1.454 1.454 0 0115.3 6.1l2.021-2.02a.481.481 0 01.651-.026A7.238 7.238 0 0120.64 9.6c0 4.761-6.375 10.555-8.329 12.217z\"/><path d=\"M17.251 6.211l-.281.281a3.018 3.018 0 00-.89 2.148.72.72 0 101.44 0 1.611 1.611 0 01.468-1.13l.281-.281a.72.72 0 00-1.018-1.018zM15.84 12.24a.72.72 0 00-.72.72v.367a1.01 1.01 0 01-.562.909.72.72 0 00.644 1.288 2.442 2.442 0 001.358-2.2v-.364a.72.72 0 00-.72-.72zM12.158 18.556l-.96.48a.72.72 0 00.644 1.288l.96-.48a.72.72 0 00-.644-1.288z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});