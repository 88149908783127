define("ember-svg-jar/inlined/messages-people-man-bubble-circle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-man-bubble-circle-1</title><path d=\"M16.5 0a7.676 7.676 0 00-6.162 3.007 1 1 0 001.6 1.195A5.673 5.673 0 0116.5 2 5.274 5.274 0 0122 7a5.006 5.006 0 01-3.358 4.605 1.012 1.012 0 00-.32.2c-.2.184-2.068 1.773-2.324 1.991v-.858a1 1 0 00-.861-1 5.781 5.781 0 01-.787-.166 1 1 0 10-.55 1.928l.2.054v2.25a1 1 0 001.707.707c.268-.267 3.543-3.054 3.828-3.305A6.973 6.973 0 0024 7a7.271 7.271 0 00-7.5-7z\"/><circle cx=\"13.116\" cy=\"6.5\" r=\"1\"/><circle cx=\"16.366\" cy=\"6.5\" r=\"1\"/><circle cx=\"19.616\" cy=\"6.5\" r=\"1\"/><path d=\"M7 17.125a4.75 4.75 0 10-4.75-4.75A4.756 4.756 0 007 17.125zm-2.451-5.971a7.043 7.043 0 003.911 1.221 6.6 6.6 0 001.277-.125c0 .042.013.082.013.125a2.75 2.75 0 01-5.5 0 2.713 2.713 0 01.299-1.221zM7.206 18a7.544 7.544 0 00-7.185 5.357A.5.5 0 00.5 24h13.412a.5.5 0 00.48-.643A7.544 7.544 0 007.206 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});