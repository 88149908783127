define("ember-svg-jar/inlined/messaging-imessage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messaging-imessage</title><path d=\"M12 2.61c-5.79 0-10.5 3.81-10.5 8.49a7.68 7.68 0 003.06 6 2.21 2.21 0 01-1.67 2.67.5.5 0 00-.29.18.8.8 0 00-.12.75 1.08 1.08 0 00.9.61 5.89 5.89 0 001.05.09 8 8 0 005-2.06 12.93 12.93 0 002.56.26c5.79 0 10.5-3.81 10.5-8.49s-4.7-8.5-10.49-8.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});