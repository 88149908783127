define("ember-svg-jar/inlined/car-repair-tire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-repair-tire</title><path d=\"M22.789 13.008l-2.151-2.056-1.587-3.512a2.532 2.532 0 00-2.1-1.482l-5.723-.476a2.524 2.524 0 00-2.138.883L6 9.645l-3.665 1.048a2.583 2.583 0 00-.184 5.012.249.249 0 00.306-.158 2.984 2.984 0 015.75.375.248.248 0 00.231.2l5.537.3a.253.253 0 00.214-.1 1.98 1.98 0 011.6-.789.249.249 0 00.219-.154 3 3 0 015.541 0 .253.253 0 00.221.154 1.976 1.976 0 011.317.492.25.25 0 00.392-.077 2.341 2.341 0 00.229-.828 2.379 2.379 0 00-.919-2.112zM15.9 8.881l-.165 1.993a1 1 0 01-1 .918.763.763 0 01-.082 0l-5.482-.455a.5.5 0 01-.35-.809l1.882-2.372a1.579 1.579 0 011.368-.592l2.912.241a1 1 0 01.914 1.08z\"/><circle cx=\"5.281\" cy=\"16.526\" r=\"2\"/><path d=\"M21.781 16.526h-.171a1 1 0 01-.943-.667 2 2 0 00-3.772 0 1 1 0 01-.943.667h-.171a1 1 0 000 2h6a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});