define("ember-svg-jar/inlined/settings-slider-desktop-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>settings-slider-desktop-vertical</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v13.75a.25.25 0 00.25.25h23.5a.25.25 0 00.25-.25V1.5A1.5 1.5 0 0022.5 0zm-6.25 3.25a.75.75 0 011.5 0V5a.75.75 0 11-1.5 0zm-10 0a.75.75 0 111.5 0V5a.75.75 0 11-1.5 0zm1.5 9a.75.75 0 01-1.5 0v-.75a.75.75 0 111.5 0zm-.631-2.486a1.5 1.5 0 111.424-1.573 1.5 1.5 0 01-1.424 1.573zm5.631 2.487a.75.75 0 01-1.5 0V7.5a.75.75 0 011.5 0zm.794-7.914a1.5 1.5 0 11-1.424-1.573 1.5 1.5 0 011.424 1.573zm4.206 7.914a.75.75 0 01-1.5 0V11.5a.75.75 0 111.5 0zm-.63-2.487a1.5 1.5 0 111.423-1.573 1.5 1.5 0 01-1.423 1.573zM23.75 17H.25a.25.25 0 00-.25.25v1.25A1.5 1.5 0 001.5 20h8.3l-2.249 3.214A.5.5 0 007.96 24h8.08a.5.5 0 00.409-.787L14.2 20h8.3a1.5 1.5 0 001.5-1.5v-1.25a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});