define("ember-svg-jar/inlined/night-moon-half-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>night-moon-half-1</title><path d=\"M6.5 0a1 1 0 00-1 1v22a1 1 0 001 1 12 12 0 000-24zm1.034 21.947a.032.032 0 01-.024-.008.034.034 0 01-.01-.023V2.33a.25.25 0 01.282-.248 10 10 0 01-.248 19.865z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});