define("ember-svg-jar/inlined/real-estate-dimensions-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-dimensions-building</title><path d=\"M15.724 15.553l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 009 16v7.5a.5.5 0 00.5.5H11a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5V16a.5.5 0 00-.276-.447zM14 20h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zM23.692 12.038l-6-2.5A.5.5 0 0017 10v13.5a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-11a.5.5 0 00-.308-.462zM22 20h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zM4.25 8h.5a.75.75 0 00.53-1.28l-2-2a.749.749 0 00-1.06 0l-2 2A.75.75 0 00.75 8h.5a.5.5 0 01.5.5V20a.5.5 0 01-.5.5h-.5a.75.75 0 00-.53 1.28l2 2a.748.748 0 001.06 0l2-2a.75.75 0 00-.53-1.28h-.5a.5.5 0 01-.5-.5V8.5a.5.5 0 01.5-.5zM20.5 4.25v.5a.75.75 0 001.28.53l2-2a.749.749 0 000-1.06l-2-2a.75.75 0 00-1.28.53v.5a.5.5 0 01-.5.5H8.5a.5.5 0 01-.5-.5v-.5A.75.75 0 006.72.22l-2 2a.749.749 0 000 1.06l2 2A.75.75 0 008 4.75v-.5a.5.5 0 01.5-.5H20a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});