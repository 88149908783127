define("ember-svg-jar/inlined/science-fiction-ship", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-fiction-ship</title><path d=\"M19.939 18a2.484 2.484 0 01-.095.678.248.248 0 00.041.22.251.251 0 00.2.1h2.622a.25.25 0 00.25-.25v-7.267c0-2.342-.882-3.753-2.683-4.272a.25.25 0 00-.319.24zM18.928 20.5a.25.25 0 00-.242.189l-.551 2.2A.876.876 0 0019 24h4.038a.876.876 0 00.867-1.11l-.55-2.2a.25.25 0 00-.243-.189zM9.969 20.5a.248.248 0 00-.242.189l-.55 2.2A.875.875 0 0010.044 24h4.037a.874.874 0 00.867-1.11l-.55-2.2a.248.248 0 00-.242-.189zM3.8 19a.251.251 0 00.241-.319 2.475 2.475 0 01-.1-.685l.013-10.514a.249.249 0 00-.1-.2.252.252 0 00-.224-.036C1.89 7.806.956 9.262.956 11.481v7.269a.249.249 0 00.25.25zM.885 20.5a.25.25 0 00-.243.189l-.55 2.2A.876.876 0 00.959 24H5a.875.875 0 00.867-1.11l-.55-2.2a.25.25 0 00-.243-.189zM18.2 6.731a.249.249 0 00.25-.257 6.5 6.5 0 00-12.993 0 .251.251 0 00.25.257zm-6.264-1.75a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.498 1.5zM18.439 18l.014-9.519a.249.249 0 00-.073-.177.252.252 0 00-.177-.074H5.7a.25.25 0 00-.25.25L5.439 18a1 1 0 001 1h11a1 1 0 001-1zm-8.5-7.27h4a.75.75 0 010 1.5h-4a.75.75 0 010-1.5zm0 4.019h4a.75.75 0 110 1.5h-4a.75.75 0 010-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});