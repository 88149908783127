define("ember-svg-jar/inlined/crypto-currency-nxtcoin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-nxtcoin</title><path d=\"M14.5 9.75a.75.75 0 010-1.5h4.691a1.247 1.247 0 011.118.689l1.741 3.484a.5.5 0 00.95-.212V12A11 11 0 001.883 7.7a.5.5 0 00.013.42l2.929 5.859a.5.5 0 00.447.276H9.5a.75.75 0 010 1.5H4.809a1.247 1.247 0 01-1.118-.689L1.95 11.577a.5.5 0 00-.95.212V12a11 11 0 0021.117 4.305.5.5 0 00-.013-.42l-2.929-5.859a.5.5 0 00-.447-.276zm4 6h-5a.749.749 0 01-.671-.415l-2.654-5.309a.5.5 0 00-.447-.276H5.5a.75.75 0 010-1.5h4.691a1.247 1.247 0 011.118.689l2.517 5.035a.5.5 0 00.447.276H18.5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});