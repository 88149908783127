define("ember-svg-jar/inlined/nautic-sports-water-skiing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>nautic-sports-water-skiing</title><path d=\"M13.5 8.979H23a1 1 0 100-2h-9.5a1 1 0 000 2z\"/><circle cx=\"2.504\" cy=\"2.5\" r=\"2.5\"/><path d=\"M9.176 18.254a4.543 4.543 0 00-2.492-3.86l-1.365-.683a1.51 1.51 0 01-.763-.9L3.694 9.8a.25.25 0 01.239-.323h5.631a1.5 1.5 0 000-3h-8a1.541 1.541 0 00-1.5 1.941l1.626 5.273a4.514 4.514 0 002.289 2.7l1.364.682a1.492 1.492 0 01.823 1.2l.036.38a.249.249 0 00.113.186c.673.436 1.128 1.639 2.246 1.639a2.793 2.793 0 001.889-1.016 2.5 2.5 0 012.026-1.035 2.467 2.467 0 011.132.285.248.248 0 00.2.014l1.712-.6c4.7-1.653 4.79-1.685 5.744-2.712.124-.134.269-.29.457-.478a1 1 0 10-1.421-1.414c-.21.21-.371.383-.508.532-.619.665-.619.665-4.943 2.186-1.207.426-5.339 1.894-5.673 2.014z\"/><path d=\"M22.93 21.953c-1.58.119-2.666-1.557-2.675-1.569a1.048 1.048 0 00-1.715.022 3.372 3.372 0 01-2.753 1.558 2.806 2.806 0 01-2.406-1.482 1.043 1.043 0 00-1.716-.156 4.3 4.3 0 01-3.1 1.638 3.56 3.56 0 01-2.76-1.58 1.041 1.041 0 00-1.637-.091c-.014.019-1.464 1.831-3.11 1.7A.974.974 0 000 22.942C-.008 23.13.044 24 1.17 24a5.851 5.851 0 003.73-1.607 5.243 5.243 0 003.66 1.571 5.863 5.863 0 003.811-1.5 4.576 4.576 0 003.416 1.5 5.155 5.155 0 003.622-1.5 4.769 4.769 0 003.66 1.482A1 1 0 0024 22.882a1.016 1.016 0 00-1.07-.929z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});