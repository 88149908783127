define("ember-svg-jar/inlined/image-document-png", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-document-png</title><path d=\"M7 10.15h-.625a.251.251 0 00-.25.25v1.25a.25.25 0 00.25.25H7a.875.875 0 000-1.75z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM6.875 13.15h-.5a.251.251 0 00-.25.25v2.125a.625.625 0 01-1.25 0v-6A.626.626 0 015.5 8.9H7a2.128 2.128 0 012.114 2.347 2.212 2.212 0 01-2.239 1.903zm7.25 2.375a.626.626 0 01-.481.609.642.642 0 01-.144.016.626.626 0 01-.559-.345s-1.485-2.966-1.552-3.105-.264-.188-.264 0v2.824a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279s1.489 2.989 1.554 3.108.262.161.262-.02V9.525a.625.625 0 111.25 0zm5-4a.625.625 0 01-1.25 0v-.5a.875.875 0 10-1.75 0v3a.876.876 0 001.68.345c.029-.069.06-.22-.1-.22H17.5a.625.625 0 010-1.25h1a.625.625 0 01.625.625v.5a2.125 2.125 0 11-4.25 0v-3a2.125 2.125 0 114.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});