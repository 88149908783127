define("ember-svg-jar/inlined/newspaper-fold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>newspaper-fold</title><rect x=\"4.252\" y=\"4.5\" width=\"5.75\" height=\"7.25\" rx=\"1.25\" ry=\"1.25\"/><path d=\"M24 10a3 3 0 00-3-3h-2V2.5a2 2 0 00-2-2H2a2 2 0 00-2 2V20a3.5 3.5 0 003.5 3.5h17A3.5 3.5 0 0024 20zM3.5 21.5A1.5 1.5 0 012 20V3a.5.5 0 01.5-.5h14a.5.5 0 01.5.5v17a3.494 3.494 0 00.11.874.5.5 0 01-.484.625zM22 20a1.5 1.5 0 11-3 0V9.5a.5.5 0 01.5-.5H21a1 1 0 011 1z\"/><path d=\"M12.751 6.047h2a.75.75 0 01.75.75v.5a.75.75 0 01-.75.75h-2A.749.749 0 0112 7.3v-.5a.751.751 0 01.751-.753zM12.751 10.047h2a.75.75 0 01.75.75v.5a.75.75 0 01-.75.75h-2A.75.75 0 0112 11.3v-.5a.751.751 0 01.751-.753zM4.751 14.047h10a.75.75 0 01.75.75v.5a.75.75 0 01-.75.75h-10A.75.75 0 014 15.3v-.5a.751.751 0 01.751-.753zM4.75 18.047h7.5a.75.75 0 01.75.75v.5a.75.75 0 01-.75.75h-7.5A.749.749 0 014 19.3v-.5a.75.75 0 01.75-.753z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});