define("ember-svg-jar/inlined/passport-hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>passport-hand</title><path d=\"M22.892 4.149a.249.249 0 01-.142-.225V1.5a1.5 1.5 0 00-1.5-1.5h-12a3.009 3.009 0 00-3 3v6.257a.25.25 0 01-.094.2c-.4.323-.809.624-1.206.91-1.4 1.007-2.717 1.958-2.717 3.564v4.726a.25.25 0 01-.046.145L.39 21.314a.75.75 0 101.22.871l1.984-2.777a.747.747 0 00.139-.436v-5.046c0-.94 1.344-1.8 2.12-2.366a.251.251 0 01.261-.02.247.247 0 01.136.222v2.184a.251.251 0 01-.073.177l-.657.657a.75.75 0 001.06 1.06l2.19-2.19a.526.526 0 01.72 0 .538.538 0 01-.045.765c-.026.032-3.229 3.816-3.257 3.847a.716.716 0 00-.178.478v1.83a2.786 2.786 0 01-.42 1.33 4.022 4.022 0 01-.62.82.75.75 0 001.058 1.062h.005a6.335 6.335 0 00.945-1.382.247.247 0 01.171-.13.25.25 0 01.209.05A2.984 2.984 0 009.25 23h13a1.5 1.5 0 001.5-1.5v-16a1.5 1.5 0 00-.858-1.351zM12.75 10.75a.25.25 0 01-.25.25h-.25a.75.75 0 000 1.5h.25a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-.25a.75.75 0 000 1.5h.25a.25.25 0 01.25.25v1.514a.25.25 0 01-.382.212 5.056 5.056 0 01-1.825-1.993.058.058 0 01.007-.063 1.991 1.991 0 00-.292-3.06.251.251 0 01-.109-.27 5 5 0 012.219-3.067.25.25 0 01.382.212zm3 7.208a.25.25 0 01-.215.248 3.312 3.312 0 01-1.07 0 .25.25 0 01-.215-.247V15.75a.25.25 0 01.25-.25h1a.25.25 0 01.25.25zm0-4.208a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h1a.25.25 0 01.25.25zm0-3a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25V8.541a.25.25 0 01.215-.247 3.256 3.256 0 011.07 0 .248.248 0 01.215.247zm1.882 6.726a.25.25 0 01-.382-.211V15.75a.25.25 0 01.25-.25h1.514a.25.25 0 01.213.382 4.987 4.987 0 01-1.595 1.594zm2.324-3.691a.25.25 0 01-.247.215H17.5a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h2.209a.249.249 0 01.247.215 3.312 3.312 0 010 1.07zM17.5 11a.25.25 0 01-.25-.25V9.235a.25.25 0 01.382-.212 4.99 4.99 0 011.595 1.6.25.25 0 01-.213.382zm3.25-7.25a.25.25 0 01-.25.25H9.25a1 1 0 010-2H20.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});