define("ember-svg-jar/inlined/server-refresh-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-refresh-1</title><path d=\"M9.272 17.625a7.691 7.691 0 011.785-4.931.5.5 0 00-.385-.819H2.5a2.5 2.5 0 000 5H8a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25H5a.75.75 0 000 1.5h4.441a.5.5 0 00.46-.7 7.691 7.691 0 01-.629-3.05zM3 14.375a1.25 1.25 0 111.25 1.25A1.249 1.249 0 013 14.375zm5 1.25a1.25 1.25 0 111.25-1.25A1.25 1.25 0 018 15.625zM1 19.875a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM2.5 4.875h13a2.5 2.5 0 000-5h-13a2.5 2.5 0 000 5zm.5-2.5a1.25 1.25 0 111.25 1.25A1.249 1.249 0 013 2.375zm3.75 0A1.25 1.25 0 118 3.625a1.249 1.249 0 01-1.25-1.25zM17.2 9.882a.5.5 0 00.448-.243A2.443 2.443 0 0018 8.375a2.5 2.5 0 00-2.5-2.5h-13a2.5 2.5 0 000 5h10.6a.493.493 0 00.238-.061 7.584 7.584 0 013.862-.932zM3 8.375a1.25 1.25 0 111.25 1.25A1.249 1.249 0 013 8.375zm5 1.25a1.25 1.25 0 111.25-1.25A1.25 1.25 0 018 9.625zM21.849 13.09a.249.249 0 01-.348.005 6.375 6.375 0 10.628 8.631 1 1 0 10-1.562-1.248 4.375 4.375 0 11-.5-5.982.252.252 0 01.083.179.257.257 0 01-.073.184L18.69 16.25a.5.5 0 00.353.853H23.5a.5.5 0 00.5-.5v-4.457a.5.5 0 00-.854-.353l-.508.508z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});