define("ember-svg-jar/inlined/arrow-thick-circle-up-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-circle-up-1</title><path d=\"M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm7.133 12.631l-1.289 1.289a.5.5 0 01-.707 0l-3.214-3.213a.25.25 0 00-.426.177v7.393a.5.5 0 01-.5.5H11a.5.5 0 01-.5-.5v-7.392a.249.249 0 00-.426-.176l-3.21 3.211a.5.5 0 01-.707 0l-1.29-1.29a.5.5 0 010-.707l6.78-6.778a.5.5 0 01.707 0l6.779 6.778a.5.5 0 010 .708z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});