define("ember-svg-jar/inlined/halloween-vampire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>halloween-vampire</title><circle cx=\"14\" cy=\"7.75\" r=\"1\"/><circle cx=\"10\" cy=\"7.75\" r=\"1\"/><path d=\"M23.026 18.815A12.887 12.887 0 0019.638 17a.5.5 0 01-.254-.727c1.129-1.876 2.9-4.99 3.754-6.5a1.5 1.5 0 00-.118-1.654 7.35 7.35 0 00-4.27-2.542V3.51A2.755 2.755 0 0017.026.963a13.6 13.6 0 00-10.051 0A2.756 2.756 0 005.25 3.51v2.064A7.356 7.356 0 00.98 8.116 1.5 1.5 0 00.862 9.77c.855 1.512 2.625 4.626 3.754 6.5a.5.5 0 01-.254.73 12.845 12.845 0 00-3.383 1.811A2.489 2.489 0 000 20.79v2.71a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-2.71a2.485 2.485 0 00-.974-1.975zm-7.906-.865l-2.72 3.541a.5.5 0 01-.792 0L8.879 17.95a.5.5 0 01.342-.8 26.342 26.342 0 015.558 0 .5.5 0 01.341.8zm2.13-9.45a5.25 5.25 0 01-10.5 0V5.4a.5.5 0 01.479-.5 7.83 7.83 0 013.91.66 1.806 1.806 0 001.735-.007 7.8 7.8 0 013.9-.653.5.5 0 01.478.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});