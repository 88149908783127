define("ember-svg-jar/inlined/real-estate-action-building-like", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-building-like</title><path d=\"M6.724 6.053l-3-1.5a.5.5 0 00-.447 0l-3 1.5A.5.5 0 000 6.5V14a.5.5 0 00.5.5H2a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5A.5.5 0 007 14V6.5a.5.5 0 00-.276-.447zM5 10.5H2a.5.5 0 010-1h3a.5.5 0 010 1zM5 8H2a.5.5 0 010-1h3a.5.5 0 010 1zM15 14V3a.5.5 0 00-.307-.462l-6-2.5A.5.5 0 008 .5V14a.5.5 0 00.5.5h6a.5.5 0 00.5-.5zm-2-3.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zM13 8h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1z\"/><rect x=\"10\" y=\"16\" width=\"3\" height=\"8\" rx=\"1\" ry=\"1\"/><path d=\"M23.708 17.6a1.5 1.5 0 00-1.2-.6h-2.43a.5.5 0 01-.389-.19.857.857 0 01-.08-.59l.431-2.031A1.341 1.341 0 0019.785 13a1.393 1.393 0 00-2.16.088l-2.412 3.7a.49.49 0 01-.4.208H14.5a.5.5 0 00-.5.5V22.2a.5.5 0 00.273.446C16.564 23.816 17.079 24 18.318 24c.285 0 1.262-.028 1.845-.028 1.392 0 2.315-.8 2.818-2.422l.954-2.633a1.49 1.49 0 00-.227-1.317z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});