define("ember-svg-jar/inlined/insert-image-top", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>insert-image-top</title><path d=\"M3.7.647a.75.75 0 00-1.2.6v1.5A.25.25 0 012.25 3H1a1 1 0 100 2h1.25a.25.25 0 01.25.25v1.5a.749.749 0 001.2.6L7.366 4.6a.751.751 0 000-1.2zM20 22H10a1 1 0 000 2h10a1 1 0 000-2zM23 17H10a1 1 0 000 2h13a1 1 0 000-2zM10 14h10a1 1 0 000-2H10a1 1 0 000 2z\"/><rect x=\"9\" y=\"-.002\" width=\"15\" height=\"8\" rx=\"2\" ry=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});