define("ember-svg-jar/inlined/speech-translate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>speech-translate</title><path d=\"M9.326 4.25a5.458 5.458 0 00-5.4 5.5 1 1 0 102 0 3.456 3.456 0 013.4-3.5 1 1 0 000-2z\"/><path d=\"M9.315 2.25a1 1 0 000-2A9.42 9.42 0 000 9.75a1 1 0 002 0 7.417 7.417 0 017.315-7.5zM18.076 9.75a1 1 0 002 0 5.458 5.458 0 00-5.4-5.5 1 1 0 000 2 3.456 3.456 0 013.4 3.5z\"/><path d=\"M14.685.25a1 1 0 000 2A7.417 7.417 0 0122 9.75a1 1 0 002 0 9.42 9.42 0 00-9.315-9.5zM21.5 22.75a1 1 0 00-1-1h-1.162a.252.252 0 01-.232-.155L13.39 7.687a1.5 1.5 0 00-2.778-.006L4.894 21.6a.252.252 0 01-.232.155H3.5a1 1 0 000 2h4a1 1 0 000-2h-.135a.251.251 0 01-.231-.345L8.5 18.076a.25.25 0 01.231-.155h6.534a.25.25 0 01.231.155l1.368 3.329a.251.251 0 01-.231.345H16.5a1 1 0 000 2h4a1 1 0 001-1zM9.761 15.921a.25.25 0 01-.231-.345L12 9.566l2.47 6.01a.25.25 0 01-.231.345z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});