define("ember-svg-jar/inlined/house-hurricane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>house-hurricane</title><path d=\"M9.729 8.315a1.039 1.039 0 00-1.459 0c-.7.745-8 8.5-7.995 8.5A1 1 0 001 18.5h1.5a.5.5 0 01.5.5v4a1 1 0 001 1h10a1 1 0 001-1v-4a.5.5 0 01.5-.5H17a1 1 0 00.727-1.687S11.08 9.759 9.729 8.315zM10.5 16a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zM10.375 1.75h11a.875.875 0 000-1.75h-11a.875.875 0 000 1.75zM23.125 3h-9a.875.875 0 000 1.75h9a.875.875 0 000-1.75zM19.625 6h-7a.875.875 0 000 1.75h7a.875.875 0 000-1.75zM14.75 9.875a.875.875 0 00.875.875h6a.875.875 0 000-1.75h-6a.875.875 0 00-.875.875zM22.625 12h-5a.875.875 0 000 1.75h5a.875.875 0 000-1.75zM21.375 15h-1a.875.875 0 000 1.75h1a.875.875 0 000-1.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});