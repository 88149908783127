define("ember-svg-jar/inlined/cat-carrier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cat-carrier</title><path d=\"M3.281 21a2 2 0 001.985 1.75h13.468A2 2 0 0020.719 21l.492-3.936a.5.5 0 00-.5-.562H3.285a.5.5 0 00-.5.562zm11.969-2.25a.75.75 0 011.5 0v1.5a.75.75 0 01-1.5 0zm-4 0a.75.75 0 011.5 0v1.5a.75.75 0 01-1.5 0zm-4 0a.75.75 0 011.5 0v1.5a.75.75 0 01-1.5 0zM23 15.25a1.043 1.043 0 001-1 1 1 0 00-1-1h-1.625L20.719 8a2 2 0 00-1.985-1.75H17.5v-2a3 3 0 00-3-3h-5a3 3 0 00-3 3v2H5.266A2 2 0 003.281 8l-.656 5.25H1a1 1 0 00-1 1 1.044 1.044 0 001 1zM13.5 13a.25.25 0 01-.25.25h-2A.25.25 0 0111 13V9.5a.25.25 0 01.25-.25h2a.25.25 0 01.25.25zm4.59-2.76l.249 2.737a.25.25 0 01-.249.273h-2.34a.25.25 0 01-.25-.25V9.5a.25.25 0 01.25-.25h1.35a1.041 1.041 0 01.99.99zM8.5 4.25a1 1 0 011-1h5a1 1 0 011 1v1.5a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5zm-2.59 5.99a1.041 1.041 0 011-.99h1.84A.25.25 0 019 9.5V13a.25.25 0 01-.25.25H5.91a.25.25 0 01-.249-.273z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});