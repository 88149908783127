define("ember-svg-jar/inlined/cog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cog</title><path d=\"M22.5 9.5h-1.862a8.955 8.955 0 00-.763-1.84l1.317-1.316a1.5 1.5 0 000-2.122l-1.415-1.413a1.5 1.5 0 00-2.121 0l-1.318 1.317a8.906 8.906 0 00-1.838-.763V1.5A1.5 1.5 0 0013 0h-2a1.5 1.5 0 00-1.5 1.5v1.863a8.969 8.969 0 00-1.839.763L6.343 2.809a1.5 1.5 0 00-2.121 0L2.807 4.223a1.5 1.5 0 000 2.122l1.318 1.317A8.878 8.878 0 003.362 9.5H1.5A1.5 1.5 0 000 11v2a1.5 1.5 0 001.5 1.5h1.862a8.878 8.878 0 00.763 1.839l-1.319 1.319a1.5 1.5 0 000 2.121l1.414 1.414a1.545 1.545 0 002.122 0l1.318-1.317a8.977 8.977 0 001.84.764v1.86A1.5 1.5 0 0011 24h2a1.5 1.5 0 001.5-1.5v-1.86a8.965 8.965 0 001.839-.763l1.318 1.317a1.5 1.5 0 002.121 0l1.414-1.414a1.5 1.5 0 000-2.121l-1.317-1.317a8.909 8.909 0 00.763-1.84H22.5A1.5 1.5 0 0024 13v-2a1.5 1.5 0 00-1.5-1.5zM12 17a5 5 0 115-5 5.006 5.006 0 01-5 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});