define("ember-svg-jar/inlined/server-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-4</title><path d=\"M3.5 5.979h17a2.5 2.5 0 002.5-2.5v-1a2.5 2.5 0 00-2.5-2.5h-17a2.5 2.5 0 00-2.5 2.5v1a2.5 2.5 0 002.5 2.5zm1.135-3A1.115 1.115 0 115.75 4.093a1.115 1.115 0 01-1.115-1.115zm3.865 0a1.115 1.115 0 111.115 1.114A1.114 1.114 0 018.5 2.978zM1 10.479a2.5 2.5 0 002.5 2.5h17a2.5 2.5 0 002.5-2.5v-1a2.5 2.5 0 00-2.5-2.5h-17a2.5 2.5 0 00-2.5 2.5zm3.635-.5a1.115 1.115 0 111.115 1.114 1.115 1.115 0 01-1.115-1.115zm3.865 0a1.115 1.115 0 111.115 1.115A1.114 1.114 0 018.5 9.978zM20.5 19.979a2.5 2.5 0 002.5-2.5v-1a2.5 2.5 0 00-2.5-2.5h-17a2.5 2.5 0 00-2.5 2.5v1a2.5 2.5 0 002.5 2.5H11a.25.25 0 01.25.25v2.042a.25.25 0 01-.25.25H5.5a.75.75 0 000 1.5h13a.75.75 0 000-1.5H13a.25.25 0 01-.25-.25v-2.042a.25.25 0 01.25-.25zm-9.771-3a1.115 1.115 0 11-1.114-1.114 1.115 1.115 0 011.114 1.113zm-3.864 0a1.115 1.115 0 11-1.115-1.115 1.115 1.115 0 011.115 1.114zM22 24.021h1a.75.75 0 000-1.5h-1a.75.75 0 000 1.5zM1 22.521a.75.75 0 000 1.5h1a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});