define("ember-svg-jar/inlined/antenna-house-connect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>antenna-house-connect</title><path d=\"M6.011 3.676a3.276 3.276 0 00-3.273 3.272 1 1 0 102 0 1.273 1.273 0 012.545 0 1 1 0 102 0 3.276 3.276 0 00-3.272-3.272z\"/><path d=\"M2.011 6.017a4 4 0 118 0 1 1 0 002 0 6 6 0 00-12 0 1 1 0 002 0zM23.489 15.483a.5.5 0 00.339-.868l-4.947-4.562a.5.5 0 00-.676 0l-4.99 4.563a.5.5 0 00.337.869h1.209a.25.25 0 01.25.25v3.25a1 1 0 001 1h1.228a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25h-10a.25.25 0 01-.25-.25v-2a.25.25 0 01.25-.25h1.317a.25.25 0 01.234.161l.265.7a1 1 0 101.869-.713L7.011 9.355a.261.261 0 01-.017-.1l.017-.755a1 1 0 00-.979-1.021h-.021a1 1 0 00-1 .978l-.017.784a.242.242 0 01-.017.084l-3.922 10.3a1 1 0 001.869.713l.265-.7a.25.25 0 01.233-.161h1.317a.25.25 0 01.25.25v2.25a2 2 0 002 2h10.5a2 2 0 002-2v-1.75a.25.25 0 01.25-.25h1.272a1 1 0 001-1v-3.25a.25.25 0 01.25-.25zM5.756 12.905a.25.25 0 01.467 0l.477 1.239a.25.25 0 01-.234.339h-.949a.249.249 0 01-.233-.339zm-1.425 3.739a.25.25 0 01.234-.161h2.848a.25.25 0 01.234.161l.19.5a.249.249 0 01-.233.339h-3.23a.25.25 0 01-.233-.339z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});