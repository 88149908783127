define("ember-svg-jar/inlined/ac-heat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ac-heat</title><path d=\"M24 9.5a.5.5 0 00-.5-.5H.5a.5.5 0 00-.5.5V12a1 1 0 001 1h1.2a.5.5 0 01.447.276l.823 1.644a1.488 1.488 0 001.34.83h14.38a1.488 1.488 0 001.34-.83l.822-1.644A.5.5 0 0121.8 13H23a1 1 0 001-1zm-4.982 3.974a.5.5 0 01-.447.276H5.429a.5.5 0 01-.447-.276l-.5-1a.5.5 0 01.447-.724h14.142a.5.5 0 01.447.724zM12.64 17.231a1 1 0 00-1.28 1.538c.189.157.276.229-.253 1.321-.447.924-1.2 2.471.253 3.679a1 1 0 001.28-1.538c-.165-.137-.248-.206.267-1.27.452-.935 1.21-2.499-.267-3.73zM6.379 17.231A1 1 0 005.1 18.769c.189.157.276.229-.253 1.321-.448.924-1.2 2.471.253 3.679a1 1 0 001.28-1.538c-.165-.137-.248-.206.267-1.27.453-.935 1.209-2.499-.268-3.73zM18.883 17.231a1 1 0 10-1.28 1.538c.189.157.275.229-.253 1.321-.448.924-1.2 2.471.253 3.679a1 1 0 001.28-1.538c-.165-.137-.248-.206.267-1.27.45-.935 1.21-2.499-.267-3.73zM21.5 0h-19A2.5 2.5 0 000 2.5V7a.5.5 0 00.5.5h23A.5.5 0 0024 7V2.5A2.5 2.5 0 0021.5 0zM17 5.5a1 1 0 111-1 1 1 0 01-1 1zm3.5 0a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});