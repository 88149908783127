define("ember-svg-jar/inlined/payment-stripe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>payment-stripe</title><path d=\"M23.5 1.5a1 1 0 00-1-1h-21a1 1 0 00-1 1v21a1 1 0 001 1h21a1 1 0 001-1zM11.35 19.18a7.86 7.86 0 01-3.06-.62.5.5 0 01-.29-.46v-2.67a.5.5 0 01.75-.43l.43.25a4.54 4.54 0 002.28.64c.58 0 1.54-.14 1.54-1.07 0-.4 0-.85-1.65-1.48C10.42 13 7.51 12 7.51 9c0-2.49 2.07-4.29 4.93-4.29a6.79 6.79 0 012.77.53.5.5 0 01.3.46v2.6a.48.48 0 01-.68.44l-.61-.27A3.87 3.87 0 0012.38 8c-.95 0-1.29.42-1.29.82s0 .72 1.77 1.41S16.6 12 16.6 14.69c0 2.23-1.6 4.49-5.25 4.49z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});