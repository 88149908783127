define("ember-svg-jar/inlined/trekking-shelter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trekking-shelter</title><path d=\"M20 12.75l3.321-9.789a1.5 1.5 0 00-.61-2.031 1.474 1.474 0 00-.677-.173C22.022.756 22.012.75 22 .75H5.5c-.013 0-.023.007-.036.007a1.494 1.494 0 00-1.285.782l-3.5 9.622A4.152 4.152 0 00.5 12.372v9.378a1.5 1.5 0 003 0v-8.5h13.25a.25.25 0 01.25.25v8.25a1.5 1.5 0 003 0zm-2.372-2.813a1.994 1.994 0 01-1.878 1.313H5.407a1 1 0 01-.947-1.323L6.447 4.1a2 2 0 011.892-1.35h10.472a1 1 0 01.94 1.341c-.581 1.601-1.551 4.258-2.123 5.846z\"/><path d=\"M16.25 4.5h-7a.75.75 0 000 1.5h7a.75.75 0 000-1.5zM15 8H8a.75.75 0 000 1.5h7A.75.75 0 0015 8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});