define("ember-svg-jar/inlined/app-window-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-1</title><path d=\"M21.5 7.251a3 3 0 00-3-3h-13a3 3 0 00-3 3v9.5a3 3 0 003 3h13a3 3 0 003-3zm-9.346-1a.966.966 0 011.692 0 .889.889 0 010 1 .966.966 0 01-1.692 0 .889.889 0 010-1zm-3.5 0a.966.966 0 011.692 0 .889.889 0 010 1 .966.966 0 01-1.692 0 .889.889 0 010-1zm-3.562.091A1 1 0 016 5.751a.983.983 0 01.846.5.889.889 0 010 1 .966.966 0 01-1.692 0 .972.972 0 01-.154-.5.987.987 0 01.092-.409zM19.5 16.751a1 1 0 01-1 1h-13a1 1 0 01-1-1V9.5a.25.25 0 01.25-.25h14.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});