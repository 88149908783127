define("ember-svg-jar/inlined/single-neutral-actions-address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-address</title><path d=\"M.5 17.75h1.81a.249.249 0 01.249.229L3 23.291a.5.5 0 00.5.459h4a.5.5 0 00.5-.459l.443-5.312a.249.249 0 01.249-.229h.169a.156.156 0 00.114-.05.158.158 0 00.041-.117A4.101 4.101 0 019 17.25a8.436 8.436 0 011.672-5.041.252.252 0 00.035-.23A5.5 5.5 0 000 13.75v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M10.677 18.566a6.671 6.671 0 004.987 5 7.09 7.09 0 001.588.183 6.714 6.714 0 001.735-.227 1 1 0 00-.514-1.932 4.885 4.885 0 01-2.362.027 4.723 4.723 0 01-3.484-3.494 4.8 4.8 0 01.947-4.133 4.72 4.72 0 013.8-1.74A4.922 4.922 0 0122 17.266v.812a.8.8 0 01-1.594 0V17a3.156 3.156 0 10-3.156 3.156 3.106 3.106 0 001.476-.377.249.249 0 01.312.062A2.793 2.793 0 0024 18.078v-.812a6.933 6.933 0 00-6.576-7.014 6.706 6.706 0 00-5.4 2.471 6.786 6.786 0 00-1.347 5.843zm6.573-.41A1.157 1.157 0 1118.406 17a1.157 1.157 0 01-1.156 1.156zM.5 17.75h1.81a.249.249 0 01.249.229L3 23.291a.5.5 0 00.5.459h4a.5.5 0 00.5-.459l.443-5.312a.249.249 0 01.249-.229h.169a.156.156 0 00.114-.05.158.158 0 00.041-.117A4.101 4.101 0 019 17.25a8.436 8.436 0 011.672-5.041.252.252 0 00.035-.23A5.5 5.5 0 000 13.75v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M10.677 18.566a6.671 6.671 0 004.987 5 7.09 7.09 0 001.588.183 6.714 6.714 0 001.735-.227 1 1 0 00-.514-1.932 4.885 4.885 0 01-2.362.027 4.723 4.723 0 01-3.484-3.494 4.8 4.8 0 01.947-4.133 4.72 4.72 0 013.8-1.74A4.922 4.922 0 0122 17.266v.812a.8.8 0 01-1.594 0V17a3.156 3.156 0 10-3.156 3.156 3.106 3.106 0 001.476-.377.249.249 0 01.312.062A2.793 2.793 0 0024 18.078v-.812a6.933 6.933 0 00-6.576-7.014 6.706 6.706 0 00-5.4 2.471 6.786 6.786 0 00-1.347 5.843zm6.573-.41A1.157 1.157 0 1118.406 17a1.157 1.157 0 01-1.156 1.156z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});