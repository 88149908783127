define("ember-svg-jar/inlined/video-player-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-player-1-alternate</title><path d=\"M15.456 11.136L9.534 8.118A1.038 1.038 0 008 8.98v6.039a1.041 1.041 0 001.534.863l5.922-3.019a.958.958 0 000-1.727z\"/><path d=\"M23.363 5.324A2.016 2.016 0 0021.64 3.67 71.877 71.877 0 0012 2.991a71.877 71.877 0 00-9.64.679A2.016 2.016 0 00.637 5.324a35.31 35.31 0 000 13.351A2.017 2.017 0 002.36 20.33a71.656 71.656 0 009.64.678 71.656 71.656 0 009.64-.678 2.017 2.017 0 001.723-1.655 35.31 35.31 0 000-13.351zm-1.905 12.638a.5.5 0 01-.427.411A68.426 68.426 0 0112 19a68.426 68.426 0 01-9.031-.631.5.5 0 01-.427-.411 32.933 32.933 0 01.019-11.944.5.5 0 01.428-.41A68.749 68.749 0 0112 5a68.749 68.749 0 019.011.609.5.5 0 01.428.41 32.933 32.933 0 01.019 11.943z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});