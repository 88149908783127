define("ember-svg-jar/inlined/spa-flame", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>spa-flame</title><path d=\"M7.74 10.431a4 4 0 004-4 7.379 7.379 0 00-3.7-6.3.5.5 0 00-.7.646 4.323 4.323 0 01.215 2.954.5.5 0 01-.858.183 2.048 2.048 0 01-.319-.489.5.5 0 00-.3-.269.5.5 0 00-.4.036A3.571 3.571 0 003.74 6.431a4 4 0 004 4zM12.854 14.384a4.082 4.082 0 011.38-.26.5.5 0 00.466-.334 4.149 4.149 0 01.387-.811.506.506 0 00.044-.432.858.858 0 00-.177-.295 1 1 0 00-.734-.321H1.259a1 1 0 00-1 1.076 7.529 7.529 0 007.481 6.924 7.429 7.429 0 002.929-.6.5.5 0 00.264-.652 3.2 3.2 0 01-.111-2.235 3.326 3.326 0 012.032-2.06z\"/><path d=\"M21.9 19.581a2.293 2.293 0 001.76-2.675 2.293 2.293 0 00-3-1.129 3.07 3.07 0 00-.594.264.129.129 0 01-.136-.007.125.125 0 01-.048-.128 3.09 3.09 0 00.067-.646 2.292 2.292 0 00-2-2.5 2.293 2.293 0 00-2 2.5 3.089 3.089 0 00.068.646.126.126 0 01-.049.128.128.128 0 01-.136.007 3.061 3.061 0 00-.593-.264 2.292 2.292 0 00-3 1.129 2.293 2.293 0 001.759 2.675 3.115 3.115 0 00.636.135.125.125 0 01.07.218 3.051 3.051 0 00-.434.482 2.291 2.291 0 00.149 3.2 2.292 2.292 0 003.087-.846 3.14 3.14 0 00.325-.563.125.125 0 01.228 0 3.14 3.14 0 00.325.563 2.293 2.293 0 003.088.846 2.291 2.291 0 00.148-3.2 3.051 3.051 0 00-.434-.482.126.126 0 01.071-.218 3.12 3.12 0 00.643-.135zm-3.947.679a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.498 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});