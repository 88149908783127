define("ember-svg-jar/inlined/electronics-ground", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>electronics-ground</title><path d=\"M23 11.5h-9.75a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v10.25a.25.25 0 01-.25.25H1a1 1 0 000 2h22a1 1 0 000-2zM20 15H4a1 1 0 000 2h16a1 1 0 000-2zM17 18.5H7a1 1 0 000 2h10a1 1 0 000-2zM14 22h-4a1 1 0 000 2h4a1 1 0 100-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});