define("ember-svg-jar/inlined/family-child-play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-child-play</title><circle cx=\"3.932\" cy=\"2.75\" r=\"2.5\"/><path d=\"M4.312 11.108a12.966 12.966 0 003.97 1.959 1.521 1.521 0 001.872-1.148 1.519 1.519 0 00-1.12-1.807 7.17 7.17 0 01-3.692-2.074 7.542 7.542 0 00-1.572-1.25 1.476 1.476 0 00-.335-.037 1.5 1.5 0 00-1.464 1.162L.665 13.57a3.5 3.5 0 00.5 2.73l1.049 1.573a.493.493 0 01.071.383l-.795 3.571a1.571 1.571 0 00.923 1.83 1.521 1.521 0 00.521.093A1.5 1.5 0 004.4 22.575l.814-3.665a3.5 3.5 0 00-.5-2.7l-1.055-1.575a.5.5 0 01-.071-.389zM12.144 13.567l.994.2a3.186 3.186 0 00.642.065 3.246 3.246 0 002.294-.953l.344-.345a.249.249 0 01.322-.027l1.96 1.4a.252.252 0 01.1.163l.284 1.705a2.264 2.264 0 001.779 1.838 7.7 7.7 0 001.312.237 1.25 1.25 0 00.243-2.475l-.727-.145a.25.25 0 01-.2-.2l-.262-1.571a2.237 2.237 0 00-.911-1.462l-2.474-1.767a2.243 2.243 0 00-2.9.241l-.643.644a.75.75 0 01-.531.22c-.22 0-1.094-.237-1.387-.237a1.25 1.25 0 00-.244 2.475zM15.488 8.992a1.978 1.978 0 00.444-1.242 2 2 0 10-2 2 2.009 2.009 0 001.556-.758z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});