define("ember-svg-jar/inlined/text-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>text-options</title><path d=\"M4 16.49h3a1 1 0 00.3-1.949.248.248 0 01-.152-.135.252.252 0 010-.2l1.11-2.562a.252.252 0 01.229-.151h7.026a.252.252 0 01.229.151l1.11 2.562a.251.251 0 01-.154.338A1 1 0 0017 16.49h3a1 1 0 100-2h-.679a.249.249 0 01-.229-.15L13.377 1.151a1.5 1.5 0 00-2.754 0L4.908 14.34a.249.249 0 01-.229.15H4a1 1 0 100 2zm7.771-12.956a.25.25 0 01.458 0l2.43 5.607a.253.253 0 01-.021.237.25.25 0 01-.209.112H9.571a.25.25 0 01-.209-.112.253.253 0 01-.021-.237zM22.957 17.748H1.043a1.022 1.022 0 00-1.043 1v4a1.022 1.022 0 001.043 1h21.914a1.022 1.022 0 001.043-1v-4a1.022 1.022 0 00-1.043-1zm-1.057 2.3l-1.5 2a.5.5 0 01-.8 0l-1.5-2a.5.5 0 01.4-.8h3a.5.5 0 01.4.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});