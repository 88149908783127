define("ember-svg-jar/inlined/accounting-bill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accounting-bill</title><path d=\"M22.5 4.5h-21a1 1 0 00-1 1v13a1 1 0 001 1h21a1 1 0 001-1v-13a1 1 0 00-1-1zm-19 4a1 1 0 111-1 1 1 0 01-1 1zM12 15a3 3 0 113-3 3 3 0 01-3 3zm8.5 2.5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});