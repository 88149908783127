define("ember-svg-jar/inlined/cd-player.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cd-player</title><path d=\"M18.434 14.5a.25.25 0 00.214-.121 9.433 9.433 0 001.323-4.143.249.249 0 00-.345-.25l-.86.358a.246.246 0 00-.073.047 3.247 3.247 0 11-3.586-5.327.25.25 0 00.143-.226V3.08a3.228 3.228 0 01.3-1.364.25.25 0 00-.1-.318A9.5 9.5 0 002.386 14.436a.136.136 0 00.114.064zm-7.934-3a2 2 0 112-2 2 2 0 01-2 2zM21.5 16h-19A2.5 2.5 0 000 18.5v2a2.5 2.5 0 002.294 2.492.251.251 0 01.225.2 1 1 0 001.961.008.25.25 0 01.245-.2h14.55a.25.25 0 01.245.2 1 1 0 001.961-.007.251.251 0 01.225-.2A2.5 2.5 0 0024 20.5v-2a2.5 2.5 0 00-2.5-2.5zM4 21a.5.5 0 01-.5-.5v-2A.5.5 0 014 18h10a.5.5 0 01.5.5v2a.5.5 0 01-.5.5zm16.5-3a1 1 0 11-1 1 1 1 0 011-1z\"/><path d=\"M18.06 1.174a2 2 0 00-1.56 1.951V6.11a.248.248 0 01-.188.241A1.75 1.75 0 1018.5 8.046V3.309a.25.25 0 01.175-.238c.553-.175 2.273-.726 3-.958a.25.25 0 01.325.238V4.11a.248.248 0 01-.188.241A1.75 1.75 0 1024 6.046V2A2 2 0 0021.632.034c-.041.008-3.572 1.14-3.572 1.14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});