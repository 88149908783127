define("ember-svg-jar/inlined/people-woman-glasses-6", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-glasses-6</title><path d=\"M12 15a21.252 21.252 0 018.894 1.728.253.253 0 00.24-.018c.458-.3.721-.5.74-.513a.5.5 0 00.031-.759c-2.927-2.724-2.4-6.591-2.405-7.955a7.5 7.5 0 10-14.993.081c0 .77.7 4.975-2.413 7.874a.5.5 0 00.032.759c.019.016.282.218.74.514a.253.253 0 00.24.017A21.256 21.256 0 0112 15zm5.286-4.079a2.484 2.484 0 01-1.228.669.253.253 0 00-.164.121 4.457 4.457 0 01-7.791 0 .25.25 0 00-.164-.121 2.459 2.459 0 01-1.225-.665l-.482-.482A2.484 2.484 0 015.5 8.671V7.5A1.5 1.5 0 017 6h3.567a.256.256 0 00.146-.047 5.627 5.627 0 001.092-1.032.249.249 0 01.39 0 5.627 5.627 0 001.092 1.032.245.245 0 00.146.047H17a1.5 1.5 0 011.5 1.5v1.171a2.484 2.484 0 01-.732 1.768z\"/><path d=\"M17.5 7.5A.5.5 0 0017 7H7a.5.5 0 00-.5.5v1.171a1.491 1.491 0 00.439 1.061l.482.482a1.5 1.5 0 001.6.338.2.2 0 00.03-.014l2.708-1.477a.5.5 0 01.478 0l2.76 1.5a.241.241 0 00.033.015 1.5 1.5 0 001.547-.364l.482-.482a1.491 1.491 0 00.441-1.059zM13 11.361h-2a.639.639 0 00-.3 1.2 2.354 2.354 0 00.434.179 2.964 2.964 0 00.869.127 3.024 3.024 0 00.87-.125 2.444 2.444 0 00.435-.182.639.639 0 00-.3-1.2zM21.623 20.609a.978.978 0 01.377.785V23a1 1 0 002 0v-1.606a2.959 2.959 0 00-1.164-2.374C21.358 17.892 17.985 16 12 16s-9.358 1.892-10.837 3.02A2.961 2.961 0 000 21.394V23a1 1 0 002 0v-1.606a.98.98 0 01.376-.785 11.026 11.026 0 012.786-1.5.25.25 0 01.338.234v4.4a.25.25 0 00.25.25h12.5a.25.25 0 00.25-.25v-4.4a.25.25 0 01.338-.234 11 11 0 012.785 1.5zM14.4 19.127a3.709 3.709 0 01-4.782 0 4.15 4.15 0 01-.561-.556.251.251 0 01.162-.409 24.128 24.128 0 015.574 0 .25.25 0 01.163.407 4.073 4.073 0 01-.556.558z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});