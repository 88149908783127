define("ember-svg-jar/inlined/single-neutral-actions-coding.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-coding</title><path d=\"M11.335 12.419a.5.5 0 00-.117-.741 7.475 7.475 0 00-11.2 4.179.5.5 0 00.482.643h8.64a.5.5 0 00.492-.411 7.96 7.96 0 011.703-3.67z\"/><circle cx=\"7\" cy=\"4.75\" r=\"4.75\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-4.28 7.03a.749.749 0 010-1.06l2-2a.75.75 0 011.06 1.06l-1.293 1.293a.252.252 0 000 .354l1.293 1.293a.75.75 0 01-1.06 1.06zm6.56 2a.75.75 0 01-1.06-1.06l1.293-1.293a.252.252 0 000-.354L18.72 16.03a.75.75 0 111.06-1.06l2 2a.749.749 0 010 1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});