define("ember-svg-jar/inlined/family-child", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-child</title><path d=\"M11.091 7.865a1.261 1.261 0 001.818 0l3.14-3.277a2.681 2.681 0 00.5-3.1 2.687 2.687 0 00-4.3-.7.354.354 0 01-.5 0 2.687 2.687 0 00-4.3.7 2.676 2.676 0 00.5 3.092zM4 11a2.444 2.444 0 10-2.444-2.444A2.444 2.444 0 004 11zM4 11.5a4 4 0 00-4 4v2.168a.5.5 0 00.5.5h.845a.5.5 0 01.5.45l.494 4.933a.5.5 0 00.5.45h2.328a.5.5 0 00.5-.45l.494-4.933a.5.5 0 01.5-.45H7.5a.5.5 0 00.5-.5V15.5a4 4 0 00-4-4zM20 11a2.444 2.444 0 10-2.444-2.444A2.444 2.444 0 0020 11zM20 11.5a4 4 0 00-4 4v2.168a.5.5 0 00.5.5h.845a.5.5 0 01.5.45l.494 4.933a.5.5 0 00.5.45h2.334a.5.5 0 00.5-.45l.494-4.933a.5.5 0 01.5-.45h.833a.5.5 0 00.5-.5V15.5a4 4 0 00-4-4zM9.892 11.473a2.027 2.027 0 102.027-2.027 2.028 2.028 0 00-2.027 2.027zM11.919 14a2.9 2.9 0 00-2.9 2.9v1.759a.5.5 0 00.5.5h.5a.5.5 0 01.5.45l.394 3.938a.5.5 0 00.5.45h1.031a.5.5 0 00.5-.45l.394-3.938a.5.5 0 01.5-.45h.5a.5.5 0 00.5-.5V16.9a2.9 2.9 0 00-2.919-2.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});