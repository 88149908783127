define("ember-svg-jar/inlined/app-window-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-star</title><path d=\"M8.83 14.508a.249.249 0 01.086.305l-.837 1.921a1.067 1.067 0 00.268 1.231 1.085 1.085 0 001.276.141l2.247-1.264a.251.251 0 01.246 0l2.246 1.264a1.108 1.108 0 00.543.145 1.089 1.089 0 00.733-.286 1.065 1.065 0 00.267-1.231l-.837-1.923a.249.249 0 01.085-.3l1.516-1.064a1.069 1.069 0 00.254-1.168 1.049 1.049 0 00-.994-.65H14.2a.25.25 0 01-.234-.162L12.991 8.9c-.007-.017-.014-.034-.022-.049a1.124 1.124 0 00-1.975.048l-.974 2.569a.25.25 0 01-.234.162H8.054a1.048 1.048 0 00-.993.648 1.094 1.094 0 00.321 1.222z\"/><path d=\"M24 4.751a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h18a3 3 0 003-3zm-14.346-1a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.562.091a1 1 0 01.908-.591.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.987.987 0 01.092-.409zM22 19.251a1 1 0 01-1 1H3a1 1 0 01-1-1V7a.25.25 0 01.25-.25h19.5A.25.25 0 0122 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});