define("ember-svg-jar/inlined/messages-bubble-star-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-star-1-alternate</title><path d=\"M9.006 16.184a.25.25 0 00-.427-.177L7 17.586V15.56a1 1 0 00-.664-.942A6.493 6.493 0 018.5 2h7a6.49 6.49 0 015.531 9.9.25.25 0 00-.017.23l.31.717a.248.248 0 00.229.151h1a.249.249 0 00.214-.121A8.483 8.483 0 0015.5 0h-7A8.492 8.492 0 005 16.232V20a1 1 0 001.707.707l2.658-2.658a.252.252 0 00.044-.3 3.273 3.273 0 01-.403-1.565z\"/><path d=\"M17.5 22.006l3.235 1.821a1.275 1.275 0 001.506-.166 1.254 1.254 0 00.315-1.45l-1.3-2.981 2.348-2.092a1.264 1.264 0 00.3-1.378 1.234 1.234 0 00-1.166-.76h-2.665l-1.409-3.267-.013-.028a1.293 1.293 0 00-2.317.028L14.922 15h-2.666a1.23 1.23 0 00-1.166.76 1.27 1.27 0 00.32 1.4l2.329 2.076-1.295 2.98a1.253 1.253 0 00.314 1.451 1.286 1.286 0 001.506.166z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});