define("ember-svg-jar/inlined/science-molecule-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-molecule-1</title><path d=\"M19.47 9.118a.5.5 0 01-.321-.475 7.524 7.524 0 00-1.605-4.635 6.958 6.958 0 00-11.075-.026 7.662 7.662 0 00-1.61 4.661.5.5 0 01-.322.473A7.144 7.144 0 000 15.75a7.008 7.008 0 007 7 7.1 7.1 0 004.67-1.633.5.5 0 01.651 0A7.089 7.089 0 0017 22.75a7.008 7.008 0 007-7 7.144 7.144 0 00-4.53-6.632zM8.05 5.207a4.958 4.958 0 017.911.023 5.434 5.434 0 011.168 3.722.5.5 0 01-.2.35L12.3 12.775a.5.5 0 01-.6 0L7.072 9.3a.5.5 0 01-.2-.353 5.561 5.561 0 011.178-3.74zM7 20.75a5.006 5.006 0 01-5-5 5.112 5.112 0 013.51-4.857.5.5 0 01.45.077l4.84 3.63a.5.5 0 01.2.4v3.756a.5.5 0 01-.116.321A4.955 4.955 0 017 20.75zm15-5a5.006 5.006 0 01-5 5 4.845 4.845 0 01-3.887-1.67.5.5 0 01-.113-.316V15a.5.5 0 01.2-.4l4.84-3.631a.5.5 0 01.451-.076A5.111 5.111 0 0122 15.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});