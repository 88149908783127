define("ember-svg-jar/inlined/single-neutral-id-card-3.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-id-card-3</title><path d=\"M24 6.25a2 2 0 00-2-2h-6a.5.5 0 00-.5.5v1.5a.5.5 0 01-1 0v-2.5a3 3 0 00-6 0v2.5a.5.5 0 01-1 0v-1.5a.5.5 0 00-.5-.5H2a2 2 0 00-2 2v15a2 2 0 002 2h20a2 2 0 002-2zM10.5 3.5a1 1 0 111 1 1 1 0 01-1-1zm-8 17.75a.5.5 0 01-.5-.5v-10.5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v10.5a.5.5 0 01-.5.5z\"/><path d=\"M19.25 13.5H14.5a.75.75 0 000 1.5h4.75a.75.75 0 000-1.5zM18 16.5h-3.5a.75.75 0 000 1.5H18a.75.75 0 000-1.5z\"/><circle cx=\"7.996\" cy=\"13.75\" r=\"2\"/><path d=\"M8 16.75a3.6 3.6 0 00-3.3 2.316.5.5 0 00.471.684h5.649a.5.5 0 00.471-.684A3.6 3.6 0 008 16.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});