define("ember-svg-jar/inlined/humidity-none", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>humidity-none</title><path d=\"M17.721 10.046a.251.251 0 00-.046.288A10.923 10.923 0 0119 15a7 7 0 01-11.376 5.459.25.25 0 00-.333.018l-1.066 1.065a.255.255 0 00-.073.189.248.248 0 00.09.18A8.995 8.995 0 0021 15a13.472 13.472 0 00-1.774-6 .25.25 0 00-.4-.061zM23.707 1.707a1 1 0 00.107-1.288 1.05 1.05 0 00-.107-.126 1 1 0 00-1.414 0l-5.016 5.016a.25.25 0 01-.38-.03 44.408 44.408 0 00-4.19-4.986 1 1 0 00-1.414 0C10.954.632 3 8.659 3 15a8.952 8.952 0 00.755 3.606.25.25 0 01-.052.277l-3.41 3.41A1 1 0 001 24a1.024 1.024 0 00.332-.056.994.994 0 00.375-.237zM5 15c0-4.367 4.785-10.106 6.815-12.343a.25.25 0 01.37 0 44.484 44.484 0 013.327 4.123.25.25 0 01-.027.321l-9.828 9.828a.251.251 0 01-.418-.112A6.986 6.986 0 015 15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});