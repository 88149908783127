define("ember-svg-jar/inlined/history-man-caveman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-man-caveman</title><circle cx=\"14\" cy=\"10.375\" r=\"1\"/><circle cx=\"10\" cy=\"10.375\" r=\"1\"/><path d=\"M15.3 23.749h8.095a.253.253 0 00.132-.037.984.984 0 00.473-.837 2.762 2.762 0 00-1.2-2.259 15.769 15.769 0 00-6.464-2.4.126.126 0 01-.1-.109.128.128 0 01.073-.133 4.778 4.778 0 002.745-4.67V13.3a25.714 25.714 0 01-.3-4.921A6.757 6.757 0 0014.4 2.067a.25.25 0 01-.056-.437l.149-.107A.75.75 0 1013.618.3L12.16 1.34a.249.249 0 01-.295 0L10.451.275a.75.75 0 00-.9 1.2l.181.136a.249.249 0 01-.063.434 6.757 6.757 0 00-4.419 6.33 25.76 25.76 0 01-.3 4.943v.008a4.776 4.776 0 002.75 4.646.128.128 0 01.073.133.126.126 0 01-.105.109 15.781 15.781 0 00-6.465 2.4A2.762 2.762 0 000 22.875a1 1 0 002 0 .752.752 0 01.341-.617c1.28-.89 4.238-2.383 9.659-2.383a25.977 25.977 0 012.7.136.25.25 0 01.1.463 22.219 22.219 0 01-5.889 2.536.375.375 0 00.089.74h6.3zm-.824-10.495a.25.25 0 01-.278-.167l-.15-.45a.751.751 0 00-.949-.474.789.789 0 00-.474.949l.463 1.39a1.229 1.229 0 01-.347 1.382 1.271 1.271 0 01-1.476 0 1.229 1.229 0 01-.347-1.382l.463-1.39a.75.75 0 10-1.423-.475l-.149.449a.249.249 0 01-.279.167 1.246 1.246 0 01-.792-.482L6.8 10.191a.253.253 0 01-.05-.15v-.649a.249.249 0 01.25-.25 7.142 7.142 0 004.834-1.533.249.249 0 01.326 0A7.057 7.057 0 0017 9.141a.251.251 0 01.251.25v.65a.253.253 0 01-.05.15l-1.935 2.581a1.249 1.249 0 01-.793.482z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});