define("ember-svg-jar/inlined/server-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-star</title><path d=\"M0 3.484a2.5 2.5 0 002.5 2.5h17a2.5 2.5 0 002.5-2.5v-1a2.5 2.5 0 00-2.5-2.5h-17a2.5 2.5 0 00-2.5 2.5zm3.636-.5A1.115 1.115 0 114.751 4.1a1.114 1.114 0 01-1.114-1.116zm3.864 0A1.115 1.115 0 118.616 4.1 1.114 1.114 0 017.5 2.984zM14.976 11.111a2.833 2.833 0 012.524-1.6c2.067 0 2.671 1.81 3.082 2.871a.248.248 0 00.368.122 2.48 2.48 0 001.05-2.02v-1a2.5 2.5 0 00-2.5-2.5h-17a2.5 2.5 0 00-2.5 2.5v1a2.5 2.5 0 002.5 2.5h11.338a.494.494 0 00.457-.3zM9.73 9.984a1.115 1.115 0 11-1.114-1.115A1.114 1.114 0 019.73 9.984zm-3.864 0a1.115 1.115 0 11-1.115-1.115 1.115 1.115 0 011.115 1.115zM0 17.484a2.5 2.5 0 002.5 2.5h8.407a.5.5 0 00.332-.872C10.21 18.224 9.01 16.893 9.7 15.2a2.706 2.706 0 01.509-.8.25.25 0 00-.184-.419H2.5a2.5 2.5 0 00-2.5 2.5zm3.636-.5A1.115 1.115 0 114.751 18.1a1.114 1.114 0 01-1.114-1.116z\"/><path d=\"M14.989 14.863a.252.252 0 01-.229.151h-2.5a1.228 1.228 0 00-1.166.76 1.27 1.27 0 00.319 1.4l2.194 1.955a.249.249 0 01.063.286l-1.223 2.815a1.255 1.255 0 00.315 1.451 1.287 1.287 0 00.864.338 1.3 1.3 0 00.642-.172l3.111-1.752a.251.251 0 01.246 0l3.112 1.752a1.278 1.278 0 001.506-.166 1.256 1.256 0 00.316-1.45l-1.224-2.816a.249.249 0 01.063-.286l2.212-1.972a1.262 1.262 0 00.3-1.378 1.232 1.232 0 00-1.169-.761h-2.5a.252.252 0 01-.23-.151l-1.344-3.117-.013-.027a1.293 1.293 0 00-2.317.027z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});