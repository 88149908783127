define("ember-svg-jar/inlined/store-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>store-1</title><g fill=\"#FFF\" fill-rule=\"nonzero\"><path d=\"M19.672 5.089L17.843.58c-.13-.32-.518-.58-.868-.58H3.027c-.35 0-.74.26-.87.58L.328 5.089c-.26.641-.498 1.753-.16 2.36A2.543 2.543 0 002.392 8.75c1.4 0 2.536-1.12 2.536-2.5 0 1.38 1.135 2.5 2.534 2.5C8.864 8.75 10 7.63 10 6.25c0 1.38 1.135 2.5 2.535 2.5 1.401 0 2.536-1.12 2.536-2.5 0 1.38 1.135 2.5 2.535 2.5a2.54 2.54 0 002.226-1.301c.34-.606.1-1.719-.16-2.36zM15.075 9.575a5.127 5.127 0 01-2.537.675c-.925 0-1.788-.25-2.538-.675a5.127 5.127 0 01-2.537.675c-.926 0-1.788-.25-2.538-.675a5.092 5.092 0 01-2.537.675c-.388 0-.775-.05-1.138-.137v7.637c0 .688.438 1.25 1.138 1.25h4.487c.345 0 .625-.28.625-.625v-3.75c0-.345.28-.625.625-.625h3.75c.345 0 .625.28.625.625v3.75c0 .345.28.625.625.625H17.6c.712 0 1.15-.563 1.15-1.25v-7.637c-.363.087-.75.137-1.137.137-.925 0-1.8-.25-2.538-.675z\"/></g>",
    "attrs": {
      "width": "20",
      "height": "19",
      "viewBox": "0 0 20 19",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});