define("ember-svg-jar/inlined/navigation-button-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-button-left</title><path d=\"M11.25 6h-10a1 1 0 00-1 1v10a1 1 0 001 1h10a1 1 0 001-1V7a1 1 0 00-1-1zm-2 7h-2.5a.5.5 0 00-.5.5v1a.5.5 0 01-.308.462.5.5 0 01-.545-.108l-2.5-2.5a.5.5 0 010-.707l2.5-2.5a.5.5 0 01.853.353v1a.5.5 0 00.5.5h2.5a1 1 0 010 2zM21.1 9.144a.5.5 0 00-.854.353v1a.5.5 0 01-.5.5h-3a1 1 0 000 2h3a.5.5 0 01.5.5v1a.5.5 0 00.309.462.489.489 0 00.195.041.5.5 0 00.354-.146l2.5-2.5a.5.5 0 000-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});