define("ember-svg-jar/inlined/dog-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dog-house</title><path d=\"M16.931 15.611a.249.249 0 00.348.015 1.978 1.978 0 011.325-.518 1.755 1.755 0 001.707-1.8 1.1 1.1 0 00-1.074-1.133 1.07 1.07 0 01-.961-.627 1.435 1.435 0 00-1.289-.84 2.347 2.347 0 00-2.234 1.822 2.823 2.823 0 01-.211.431.121.121 0 00.017.148z\"/><path d=\"M1.019 5.887A1.979 1.979 0 000 7.617v14.336a1.979 1.979 0 001.978 1.977h20.044A1.979 1.979 0 0024 21.953V7.617a1.977 1.977 0 00-1.018-1.729L12.961.32a1.967 1.967 0 00-1.921 0zm13.034 15.8a.25.25 0 01-.249.25H8.817a.249.249 0 01-.25-.25c0-2.733 1.733-2.919 2.626-2.919 1.178.008 2.86.075 2.86 2.922zM5.172 6.011a.249.249 0 01.128-.218l2.4-1.337a.25.25 0 01.371.218V8.44a.691.691 0 00.784.743.745.745 0 00.716-.743V3.561a.249.249 0 01.129-.219l2.167-1.206a.253.253 0 01.243 0l2.19 1.212a.251.251 0 01.129.219v5.64a.75.75 0 00.822.747.75.75 0 00.678-.747V4.676a.25.25 0 01.371-.219l2.4 1.33a.249.249 0 01.129.219V9.9a.744.744 0 00.683.741.744.744 0 00.8-.738l.011-2.792a.252.252 0 01.127-.211.249.249 0 01.248 0l1.173.651a.248.248 0 01.129.213v13.917a.25.25 0 01-.25.25h-1.172a.251.251 0 01-.177-.073.255.255 0 01-.073-.177v-4.915a.745.745 0 00-.82-.739H19.5a.742.742 0 00-.668.74v4.916a.25.25 0 01-.25.25h-2.189a.258.258 0 01-.251-.257 18.318 18.318 0 01.427-4.727c.008-.051.018-.1.03-.149a.246.246 0 00-.065-.236c-.766-.772-2.126-2.174-2.567-2.628a.24.24 0 00-.313-.022 2.659 2.659 0 01-1.48.457H9.958c-2.614 0-3.109-1.861-1.458-3.926a.244.244 0 00-.191-.4.254.254 0 00-.061.007 2.911 2.911 0 00-2.115 2.849 2.956 2.956 0 001.553 2.633 26.7 26.7 0 00-1.194 6.176.25.25 0 01-.25.238h-.82a.249.249 0 01-.25-.25zM3.3 6.906a.25.25 0 01.372.218V21.7a.25.25 0 01-.25.25H2.25a.255.255 0 01-.177-.073A.251.251 0 012 21.7L1.988 7.784a.249.249 0 01.129-.219z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});