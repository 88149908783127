define("ember-svg-jar/inlined/smiley-nose-blow-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-nose-blow-1</title><path d=\"M12 0A12 12 0 00.358 14.914a.25.25 0 00.367.157 4.667 4.667 0 012.175-.614 3.632 3.632 0 001.942-1.392 3.529 3.529 0 012.7-1.253 3.465 3.465 0 012.563 1.139 3.625 3.625 0 01.312.4.246.246 0 00.07.07 2 2 0 01.592.606l.708 1.133a.251.251 0 00.424 0l.708-1.133a2 2 0 013.232-.22l1.617 1.938a1 1 0 01-1.538 1.281l-1.4-1.674a.25.25 0 00-.4.028l-.73 1.18a2 2 0 01-2.627.711.247.247 0 00-.223-.005.252.252 0 00-.138.176A12.114 12.114 0 018.158 23a.251.251 0 00.111.4A12 12 0 1012 0zm-1.548 9.305a1 1 0 01-.788.681l-3 .5a.927.927 0 01-.164.014 1 1 0 01-.163-1.986l.6-.1a.25.25 0 00.136-.423l-1.28-1.284a1 1 0 011.414-1.414l3 3a1 1 0 01.245 1.012zm7.406 1.185l-3.5-.5a1 1 0 01-.565-1.7l3-3a1 1 0 111.414 1.414l-1.232 1.235a.25.25 0 00.141.425l1.026.146a1 1 0 01-.284 1.98z\"/><path d=\"M9.49 15.467a1.963 1.963 0 00-.5-1.51 2.061 2.061 0 00-3 .077c-.372.44-1.69 1.879-3.039 1.922a2.98 2.98 0 00-2.939 2.621 2.9 2.9 0 00.743 2.215l.074.079a.248.248 0 01.056.261A2.134 2.134 0 002.942 24h.034c4.182-.063 6.124-4.637 6.514-8.533zM2.946 22a.2.2 0 01-.181-.089.136.136 0 01.021-.123.129.129 0 01.107-.054 1 1 0 000-2A.887.887 0 012 18.76a1 1 0 011.011-.805 6.138 6.138 0 003.939-2.017.25.25 0 01.424.219C6.95 18.637 5.668 21.959 2.946 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});