define("ember-svg-jar/inlined/people-man-beard-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-beard-2</title><path d=\"M.5 23.375a.5.5 0 00.5.5h10.125a.25.25 0 00.25-.25v-4.286a.6.6 0 01.057-.255.247.247 0 000-.214.253.253 0 00-.165-.136 3.008 3.008 0 01-2.124-2 .25.25 0 00-.269-.171 15.227 15.227 0 00-7.409 2.726 2.466 2.466 0 00-.965 1.98zM12.732 18.734a.253.253 0 00-.165.136.247.247 0 000 .214.6.6 0 01.057.255v4.286a.25.25 0 00.25.25H23a.5.5 0 00.5-.5v-2.106a2.464 2.464 0 00-.967-1.977 15.218 15.218 0 00-7.408-2.726.25.25 0 00-.269.171 3.008 3.008 0 01-2.124 1.997z\"/><circle cx=\"14\" cy=\"8.375\" r=\"1\"/><circle cx=\"10\" cy=\"8.375\" r=\"1\"/><path d=\"M10.092 16.851a4.549 4.549 0 003.816 0l1.034-.478 1.164-.537a4.552 4.552 0 002.635-4.391.121.121 0 010-.019l.01-4.552a6.75 6.75 0 00-13.5 0l.012 4.505a4.574 4.574 0 002.632 4.457l1.164.537zm1.745-11.242a.25.25 0 01.326 0 7.041 7.041 0 004.775 1.534H17a.246.246 0 01.178.072.252.252 0 01.074.178v1.079a.251.251 0 01-.07.174l-2.1 2.181-.018.021a1.147 1.147 0 01-1.276.391 5.686 5.686 0 00-3.578 0 1.194 1.194 0 01-1.336-.462l-.018-.02L6.82 8.645a.251.251 0 01-.07-.174v-1.08a.247.247 0 01.074-.177A.251.251 0 017 7.141a7.064 7.064 0 004.837-1.532zm-1.487 7.768a.75.75 0 01.727-.566h1.846a.75.75 0 01.359 1.408 2.349 2.349 0 01-.432.18 2.874 2.874 0 01-.845.123H12a2.95 2.95 0 01-.847-.123 2.42 2.42 0 01-.431-.179.75.75 0 01-.372-.843z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});