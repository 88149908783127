define("ember-svg-jar/inlined/clothes-design-needle-yarn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>clothes-design-needle-yarn</title><rect x=\"1.5\" y=\"11.184\" width=\"8\" height=\"9\" rx=\".5\" ry=\".5\"/><path d=\"M1 9.684h9a1 1 0 100-2H1a1 1 0 100 2zM10 21.684H1a1 1 0 000 2h9a1 1 0 000-2zM22.463 18.878a.252.252 0 01-.147-.175.247.247 0 01.057-.221 6.493 6.493 0 00-1.25-9.692.5.5 0 01-.182-.607l.865-2.064a.5.5 0 01.286-.275A1.75 1.75 0 1019.916 5a.5.5 0 01.015.42l-.859 2.049a.5.5 0 01-.54.3 6.31 6.31 0 00-.587-.067.5.5 0 01-.455-.392l-.835-3.82a.5.5 0 01.083-.4 1.75 1.75 0 10-2.279.5.5.5 0 01.242.328l.792 3.621a.5.5 0 01-.31.573A6.5 6.5 0 0017.5 20.684h4a.5.5 0 010 1h-5.25a1 1 0 000 2h5.25a2.5 2.5 0 00.963-4.806zm-4.4-.369a.25.25 0 01-.215.163 4.509 4.509 0 01-4.832-4.832.252.252 0 01.163-.215.249.249 0 01.263.058L18 18.246a.249.249 0 01.059.263zm3.188-1.835a4.552 4.552 0 01-1 1.076.5.5 0 01-.659-.043L13.977 12.1a.5.5 0 01-.043-.659 4.546 4.546 0 011.076-1 .5.5 0 01.63.063l5.544 5.543a.5.5 0 01.063.627zm.6-2.308a.251.251 0 01-.274-.054l-4.2-4.2a.25.25 0 01.179-.427A4.508 4.508 0 0122 14.133a.25.25 0 01-.153.233z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});