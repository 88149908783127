define("ember-svg-jar/inlined/audio-file-disk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file-disk</title><path d=\"M21.414 4.914L17.086.586A2 2 0 0015.672 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.328a2 2 0 00-.586-1.414zM12 20.75a6.25 6.25 0 116.25-6.25A6.257 6.257 0 0112 20.75z\"/><path d=\"M11.677 16.467A2 2 0 0110 14.5a1.927 1.927 0 01.165-.778.288.288 0 00-.1-.362l-1.916-1.126a.255.255 0 00-.353.1A4.648 4.648 0 007.25 14.5a4.743 4.743 0 004.25 4.706.258.258 0 00.278-.243l.118-2.239a.251.251 0 00-.219-.257zM12.513 9.8a.256.256 0 00-.278.242l-.118 2.241a.25.25 0 00.218.256A2 2 0 0114 14.5a1.926 1.926 0 01-.15.741.318.318 0 00.078.385l1.926 1.133a.255.255 0 00.353-.1 4.657 4.657 0 00.543-2.159 4.741 4.741 0 00-4.237-4.7z\"/><circle cx=\"12\" cy=\"14.5\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});