define("ember-svg-jar/inlined/sea-transport-ship-cargo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sea-transport-ship-cargo</title><path d=\"M1.233 16.48A7.7 7.7 0 002.4 17.96a.25.25 0 00.342.013 3.838 3.838 0 00.42-.425 2.552 2.552 0 011.949-.935 2.507 2.507 0 012.126 1.179 2.019 2.019 0 001.413.832 2.678 2.678 0 001.807-.975 2.5 2.5 0 014.287.39 1.179 1.179 0 00.982.585 1.79 1.79 0 001.374-.759 2.5 2.5 0 014.293-.071 2.964 2.964 0 00.74.673.251.251 0 00.317-.058 6.86 6.86 0 001.184-2.061A7.151 7.151 0 0024 14.713v-.021a1.328 1.328 0 00-.348-.963 1.268 1.268 0 00-.9-.379h-1.231a.25.25 0 01-.25-.25v-3.006a2 2 0 00-2-2h-4.443a.25.25 0 01-.239-.177 3.866 3.866 0 00-2.859-2.77.249.249 0 01-.209-.246V2.344a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v2.5a.25.25 0 01-.25.25h-.5a1.5 1.5 0 00-1.5 1.5v4.5a.249.249 0 01-.25.25H1.289a1.3 1.3 0 00-.926.406 1.259 1.259 0 00-.322.93 7.967 7.967 0 001.192 3.8zm6.038-6.136a.25.25 0 01.25-.25h2.25a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-2.25a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h3.75c.715 0 1.5 1.047 1.5 2a1 1 0 001 1h5.25a.249.249 0 01.25.25V13.1a.249.249 0 01-.25.25h-9.41a.252.252 0 01-.237-.17 2.649 2.649 0 00-1.919-1.68.25.25 0 01-.184-.241z\"/><path d=\"M23.991 20.956a1 1 0 00-1.124-.858c-1.621.211-2.737-1.5-2.745-1.513a1.044 1.044 0 00-1.72.029 3.263 3.263 0 01-2.673 1.509 2.727 2.727 0 01-2.336-1.439 1.043 1.043 0 00-1.717-.157 4.189 4.189 0 01-3.023 1.6 3.459 3.459 0 01-2.685-1.538 1.043 1.043 0 00-1.636-.094c-.015.019-1.522 1.868-3.2 1.647a1 1 0 00-.264 1.978 4.113 4.113 0 00.549.036A5.6 5.6 0 004.9 20.734a.25.25 0 01.327.006 5.088 5.088 0 003.429 1.383 5.679 5.679 0 003.553-1.323.25.25 0 01.331.015 4.468 4.468 0 003.189 1.306 5.029 5.029 0 003.38-1.3.249.249 0 01.332 0 4.548 4.548 0 003.692 1.26 1 1 0 00.858-1.125z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});