define("ember-svg-jar/inlined/monitor-code-send", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-code-send</title><path d=\"M2.05 24h8a1.752 1.752 0 001.75-1.75v-8a1.752 1.752 0 00-1.75-1.75h-8A1.752 1.752 0 00.3 14.25v8A1.752 1.752 0 002.05 24zm-.25-9.75a.25.25 0 01.25-.25h8a.251.251 0 01.25.25v8a.251.251 0 01-.25.25h-8a.25.25 0 01-.25-.25z\"/><path d=\"M5.019 20.78a.75.75 0 001.061 0l2-2a.749.749 0 000-1.06l-2-2a.75.75 0 10-1.061 1.06l1.47 1.47-1.47 1.47a.751.751 0 000 1.06zM14.494 20.5H21a3 3 0 003-3V3a3 3 0 00-3-3H3a3 3 0 00-3 3v6.518A1 1 0 002 9.5V5.25A.25.25 0 012.25 5h19.5a.25.25 0 01.25.25V17.5a1 1 0 01-1 1h-6.5a1 1 0 00-.006 2zM9.654 2a.966.966 0 011.692 0 .969.969 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0A.969.969 0 018 2.5a.969.969 0 01-.154.5.966.966 0 01-1.692 0A.969.969 0 016 2.5a.969.969 0 01.154-.5zm-3.562.092A1 1 0 013.5 1.5a.985.985 0 01.846.5.969.969 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.979.979 0 01.092-.408z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});