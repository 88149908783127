define("ember-svg-jar/inlined/lion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lion</title><path d=\"M18.581.887A6.271 6.271 0 0012 1.27 6.271 6.271 0 005.419.887 10.44 10.44 0 00.5 9.615 14.159 14.159 0 0012 23.865a14.159 14.159 0 0011.5-14.25A10.44 10.44 0 0018.581.887zM12 18.687a3.183 3.183 0 002.286.727A2.442 2.442 0 0112 20.608a2.448 2.448 0 01-2.288-1.194A3.221 3.221 0 0012 18.687zm4.889-4.548a.5.5 0 00-.145.636 2.438 2.438 0 01.324 1.212c0 1.381-1.068 1.932-3.068 1.932a1.851 1.851 0 01-1.4-.728.744.744 0 00.147-.444v-1.211a2.732 2.732 0 001.421-1.282.752.752 0 00.057-.517 18.2 18.2 0 01-.478-3.818 1.586 1.586 0 011.75-1.75.75.75 0 000-1.5 3.081 3.081 0 00-3.25 3.25 19.323 19.323 0 00.447 3.855.942.942 0 01-.7.4.944.944 0 01-.7-.394 19.325 19.325 0 00.447-3.856A3.081 3.081 0 008.5 6.669a.75.75 0 000 1.5 1.586 1.586 0 011.75 1.75 18.276 18.276 0 01-.478 3.818.752.752 0 00.057.517 2.732 2.732 0 001.421 1.282v1.211a.744.744 0 00.147.444 1.851 1.851 0 01-1.4.728c-2 0-3.068-.551-3.068-1.932a2.438 2.438 0 01.324-1.212.5.5 0 00-.145-.636C5.31 12.687 5 10 5 8.419a4 4 0 014-4 3.945 3.945 0 012.666 1.039.506.506 0 00.668 0A3.945 3.945 0 0115 4.419a4 4 0 014 4c0 1.581-.31 4.268-2.111 5.72z\"/><circle cx=\"8.218\" cy=\"10.108\" r=\"1\"/><circle cx=\"15.968\" cy=\"10.108\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});