define("ember-svg-jar/inlined/arrow-button-down-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-down-2</title><path d=\"M23.468 2.984a2 2 0 00-1.742-1.018H2.274A2 2 0 00.563 5l9.726 16.07a2 2 0 003.422 0L23.437 5a2 2 0 00.031-2.016z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});