define("ember-svg-jar/inlined/user-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>user-question</title><path d=\"M21.428 9.857a7.243 7.243 0 01-.721-1.451C20.706 3.144 15.844 0 11.143 0a9.925 9.925 0 00-10 10.062c0 3.6 1.346 6.276 4 7.958v5.48a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-2.977c1.739-.018 2.8-.209 3.573-.981.836-.836.955-3.34.962-4.528.3 0 .684.006 1.016-.008a1.24 1.24 0 00.963-.519 1.222 1.222 0 00.19-.743v-.138c.084-1.116-.714-2.515-1.419-3.749zM11.5 17.5A1.5 1.5 0 1113 16a1.5 1.5 0 01-1.5 1.5zm1.6-6.08a1 1 0 00-.6.917 1 1 0 11-2 0 3 3 0 011.8-2.75 2 2 0 10-2.8-1.832 1 1 0 01-2 0 4 4 0 115.6 3.666z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});