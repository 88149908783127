define("ember-svg-jar/inlined/police-nightstick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-nightstick</title><path d=\"M20.273 6.542a.5.5 0 00-.058-.756 2.777 2.777 0 01-.322-.274L18.483 4.1a2.642 2.642 0 01-.272-.321.5.5 0 00-.757-.059l-2.847 2.849a.5.5 0 01-.7 0L11.76 4.427a.5.5 0 00-.76.059 2.66 2.66 0 01-.274.321L9.321 6.216A2.786 2.786 0 019 6.491a.5.5 0 00-.058.755l2.142 2.142a.5.5 0 010 .705L.417 20.759a1.847 1.847 0 00.246 2.573 2.173 2.173 0 001.51.668 1.491 1.491 0 001.064-.421zM7.207 1.287L5.8 2.7a1.993 1.993 0 102.816 2.816l1.41-1.41a2 2 0 000-2.819 2.041 2.041 0 00-2.819 0zM23.417 4.811a2 2 0 000-2.819L22.008.583a1.993 1.993 0 00-2.82 2.817l1.41 1.41a1.993 1.993 0 002.819 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});