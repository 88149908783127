define("ember-svg-jar/inlined/clean-car-charging-cable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>clean-car-charging-cable</title><path d=\"M20.558 3.75h-.5V1a1 1 0 00-2 0v2.75h-3V1a1 1 0 00-2 0v2.75h-.5A1.252 1.252 0 0011.308 5v1a5.261 5.261 0 004.109 5.125.5.5 0 01.391.487v6.638c0 2.661-1.776 4.25-4.75 4.25a4.506 4.506 0 01-3.208-1.384c-1.814-1.834-1.859-4.956-1.2-7.278a.75.75 0 111.444.406 8.759 8.759 0 00-.1 4.3.5.5 0 00.432.39 2.072 2.072 0 00.895-.088A3.093 3.093 0 0011.1 16.35c.537-2.242-.852-6.143-2.859-8.03a1 1 0 00-.844-.257.991.991 0 00-.721.509 10.025 10.025 0 01-2.066 2.445 6.667 6.667 0 00-2.267 3.236 3.064 3.064 0 00.5 2.985 2.487 2.487 0 001.6.676.5.5 0 01.451.427 6.78 6.78 0 001.883 3.83A5.993 5.993 0 0011.058 24c3.8 0 6.25-2.257 6.25-5.75v-6.638a.5.5 0 01.392-.487A5.26 5.26 0 0021.808 6V5a1.251 1.251 0 00-1.25-1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});