define("ember-svg-jar/inlined/appliances-oven", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>appliances-oven</title><path d=\"M22.5 4.5h-21A.5.5 0 001 5v17.5A1.5 1.5 0 002.5 24h19a1.5 1.5 0 001.5-1.5V5a.5.5 0 00-.5-.5zm-7 2.5a1 1 0 11-1 1 1 1 0 011-1zm-12 .5A.5.5 0 014 7h8a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5zm17 13.5a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5v-9a.5.5 0 01.5-.5h16a.5.5 0 01.5.5zm-1-12a1 1 0 111-1 1 1 0 01-1 1zM22.5 0h-21A1.5 1.5 0 000 1.5V2a.989.989 0 001 1h22a.989.989 0 001-1v-.5A1.5 1.5 0 0022.5 0z\"/><path d=\"M17.5 15.5h-11a1 1 0 010-2h11a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});