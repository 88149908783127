define("ember-svg-jar/inlined/casino-777-winner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>casino-777-winner</title><path d=\"M12.691 3.5H10.5a1 1 0 000 2h.8a.25.25 0 01.209.387l-1.346 2.066a1 1 0 101.674 1.094l2.2-3.376a1.491 1.491 0 00-.065-1.458 1.509 1.509 0 00-1.281-.713zM6.5 3.5H4.309a1 1 0 000 2h.8a.25.25 0 01.209.387L3.972 7.953a1 1 0 101.674 1.094l2.2-3.376A1.5 1.5 0 006.5 3.5zM16.809 5.5h.8a.25.25 0 01.209.387l-1.346 2.066a.985.985 0 00-.141.338.172.172 0 00.169.209 16.716 16.716 0 011.779.094.251.251 0 00.236-.112c.368-.564 1.812-2.781 1.827-2.811A1.5 1.5 0 0019 3.5h-2.191a1 1 0 100 2zM16.5 19.25c3 0 5.151-.826 5.929-1.625a.246.246 0 00.071-.174v-1.914a.25.25 0 00-.373-.218A12.124 12.124 0 0116.5 16.5a12.124 12.124 0 01-5.627-1.181.25.25 0 00-.373.218v1.914a.246.246 0 00.071.174c.778.799 2.929 1.625 5.929 1.625zM16.5 20.75a12.861 12.861 0 01-5.637-1.126.251.251 0 00-.363.223V21c0 1.71 2.58 3 6 3s6-1.29 6-3v-1.153a.25.25 0 00-.363-.223A12.861 12.861 0 0116.5 20.75zM10.5 12.5c0 1.642 3.019 2.5 6 2.5s6-.858 6-2.5c0-3.283-12-3.283-12 0z\"/><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v9a2 2 0 002 2h6.759a.252.252 0 00.25-.269 2.891 2.891 0 01.225-1.381.25.25 0 00-.229-.35H2.25a.25.25 0 01-.25-.25v-8.5A.25.25 0 012.25 2h19.5a.25.25 0 01.25.25v7.214a.25.25 0 00.128.218 3.9 3.9 0 011.413 1.25.25.25 0 00.459-.138V2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});