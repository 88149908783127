define("ember-svg-jar/inlined/table-lamp-hanging", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>table-lamp-hanging</title><path d=\"M23.25 23a1 1 0 00-1-1h-1a.5.5 0 01-.5-.5v-9a10.012 10.012 0 00-8.55-9.891.394.394 0 00-.45.391v1.071a7.922 7.922 0 011 .692 8.007 8.007 0 016 7.737v9a.5.5 0 01-.5.5h-4a1 1 0 000 2h8a1 1 0 001-1z\"/><path d=\"M11.017 4.809a.5.5 0 01-.267-.443V3a3 3 0 00-6 0v1.366a.5.5 0 01-.267.443A6.98 6.98 0 00.75 11v1.5a1 1 0 001 1h12a1 1 0 001-1V11a6.982 6.982 0 00-3.733-6.191z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});