define("ember-svg-jar/inlined/newspaper-read", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>newspaper-read</title><path d=\"M9.733 9.638a.5.5 0 00-.023.928A10.621 10.621 0 0012 11.5a10.621 10.621 0 002.29-.934.5.5 0 00.307-.47.494.494 0 00-.323-.455 2.119 2.119 0 01-.072-1.5 5.38 5.38 0 001.406-4.134A3.7 3.7 0 0012 0a3.7 3.7 0 00-3.608 4.007A5.358 5.358 0 009.781 8.12a2.111 2.111 0 01-.048 1.518z\"/><path d=\"M20.871 10.528a1.5 1.5 0 00-1.37-.192L12 12.983l-7.5-2.647a1.5 1.5 0 00-2 1.414v7.9a1.5 1.5 0 001 1.415l8.166 2.882A1.14 1.14 0 0012 24a1.16 1.16 0 00.333-.057l8.167-2.882a1.506 1.506 0 001-1.415v-7.9a1.506 1.506 0 00-.629-1.218zM4.5 12.81a.249.249 0 01.333-.235l6 2.117a.25.25 0 01.167.236v6.305a.249.249 0 01-.333.235l-6-2.116a.25.25 0 01-.167-.236zm15 6.306a.25.25 0 01-.167.236l-6 2.116a.249.249 0 01-.333-.235v-6.3a.25.25 0 01.167-.236l6-2.117a.249.249 0 01.333.235z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});