define("ember-svg-jar/inlined/meeting-camera-circle-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-camera-circle-alternate</title><path d=\"M17.239 8.838a.5.5 0 00-.51.02l-1.843 1.194a.25.25 0 01-.386-.21V8.5A.5.5 0 0014 8H7a.5.5 0 00-.5.5v7a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-1.342a.25.25 0 01.386-.21l1.843 1.194a.5.5 0 00.771-.42V9.278a.5.5 0 00-.261-.44z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});