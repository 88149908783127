define("ember-svg-jar/inlined/school-test-results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>school-test-results</title><path d=\"M7.652 14.05v-.6A1.953 1.953 0 005.7 11.5H4.5a.75.75 0 00-.75.75v6a.75.75 0 00.75.75h1.2a1.953 1.953 0 001.951-1.95v-.6a1.945 1.945 0 00-.251-.95.5.5 0 010-.5 1.938 1.938 0 00.252-.95zm-1.5 3a.451.451 0 01-.9 0v-.6a.451.451 0 01.9 0zm0-3a.451.451 0 01-.9 0v-.6a.451.451 0 01.9 0zM7.652 4.95a1.951 1.951 0 00-3.9 0v4.8a.75.75 0 001.5 0v-1.2a.451.451 0 11.9 0v1.2a.75.75 0 001.5 0zm-1.5 1.2a.451.451 0 01-.9 0v-1.2a.451.451 0 01.9 0zM11.2 4H9.7a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5zM11.951 12.75A.75.75 0 0011.2 12H9.7a.75.75 0 000 1.5h1.5a.75.75 0 00.751-.75z\"/><path d=\"M8.5 20h-6a.5.5 0 01-.5-.5v-17a.5.5 0 01.5-.5h8.672a1 1 0 01.707.293l2.828 2.828a1 1 0 01.293.707V12a1 1 0 002 0V5.414A1.986 1.986 0 0016.414 4L13 .586A2.011 2.011 0 0011.586 0H2a2 2 0 00-2 2v18a2 2 0 002 2h6.5a1 1 0 000-2z\"/><path d=\"M23.685 16.61l-6-2.382a.5.5 0 00-.37 0l-6 2.382a.5.5 0 00-.315.475v3.13a.5.5 0 001 0v-2.08a.249.249 0 01.333-.235l5 1.765a.506.506 0 00.332 0l6-2.118a.5.5 0 00.019-.937z\"/><path d=\"M20.763 19.829l-2.93 1.034a1 1 0 01-.666 0l-2.93-1.034a.5.5 0 00-.666.472v2.145a.5.5 0 00.334.471l2.574.909a3.085 3.085 0 002.043 0l2.573-.909a.5.5 0 00.334-.471V20.3a.5.5 0 00-.666-.472z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});