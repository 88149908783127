define("ember-svg-jar/inlined/modern-music-dj", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-music-dj</title><circle cx=\"12.001\" cy=\"7.75\" r=\"3.75\"/><path d=\"M6.449 11.5a1 1 0 001.052-1v-4a4.5 4.5 0 019 0v4a1 1 0 001.052 1 3 3 0 00.893-5.846 6.5 6.5 0 00-12.89 0 3 3 0 00.893 5.846z\"/><ellipse cx=\"8.251\" cy=\"17.25\" rx=\"2.5\" ry=\"2\"/><ellipse cx=\"15.751\" cy=\"17.25\" rx=\"2.5\" ry=\"2\"/><path d=\"M23.852 20.553l-3.322-6.861A2 2 0 0018.7 12.5H5.155a2 2 0 00-1.827 1.187L.09 20.837a1 1 0 00-.088.4v1.013A1.752 1.752 0 001.751 24h20.48A1.767 1.767 0 0024 22.25v-1a1.144 1.144 0 00-.148-.697zM5.089 14.647a.249.249 0 01.228-.147H18.54a.25.25 0 01.228.147l2.377 5.25a.251.251 0 01-.228.353H2.94a.251.251 0 01-.228-.353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});