define("ember-svg-jar/inlined/monitor-approve", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-approve</title><path d=\"M11.779 11.925a.249.249 0 00-.179-.425H2.5A.5.5 0 012 11V2.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v6.85a.249.249 0 00.289.247A7.8 7.8 0 0117.5 9.5c.079 0 .157 0 .235.008A.251.251 0 0018 9.259V2a2 2 0 00-2-2H2a2 2 0 00-2 2v10.5a2 2 0 002 2h5.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5H5a1 1 0 000 2h4.258a.25.25 0 00.182-.079.247.247 0 00.068-.186c0-.078-.008-.156-.008-.235a7.961 7.961 0 012.279-5.575z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm3.474 5.354l-2.9 3.874a1.749 1.749 0 01-1.28.7h-.13a1.736 1.736 0 01-1.231-.516l-1.5-1.5a1 1 0 111.411-1.412l1.094 1.1a.251.251 0 00.377-.027l2.559-3.412a1 1 0 011.6 1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});