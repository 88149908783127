define("ember-svg-jar/inlined/kitchenware-roller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchenware-roller</title><path d=\"M23.341.659a2.249 2.249 0 00-3.182 0l-2.032 2.657a.5.5 0 00.043.658l1.856 1.856a.5.5 0 00.658.043l2.657-2.032a2.249 2.249 0 000-3.182z\"/><rect x=\"8\" y=\"3.5\" width=\"8\" height=\"17\" rx=\"2\" ry=\"2\" transform=\"rotate(45 12 12)\"/><path d=\"M.659 23.341a2.249 2.249 0 003.182 0l2.032-2.657a.5.5 0 00-.043-.658L3.974 18.17a.5.5 0 00-.658-.043L.659 20.159a2.249 2.249 0 000 3.182z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});