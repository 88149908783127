define("ember-svg-jar/inlined/social-media-food-spotting-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-food-spotting-1</title><path d=\"M12 0a12 12 0 1012 12A12 12 0 0012 0zm0 22a10 10 0 1110-10 10 10 0 01-10 10z\"/><path d=\"M18 11.64A4.25 4.25 0 0112.36 6a.39.39 0 00-.36-.5 6.5 6.5 0 106.5 6.5.39.39 0 00-.55-.36z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});