define("ember-svg-jar/inlined/instrument-saxophone-play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>instrument-saxophone-play</title><path d=\"M23.272 15.679L18.617 4.427a2.113 2.113 0 011.144-2.756c.03-.013-.022.015.5-.272a.5.5 0 00-.478-.878l-.407.226C16.6 1.9 13.4 5.817 14.672 8.882l.283.683a.25.25 0 00.327.136l.825-.341a.75.75 0 01.573 1.387l-.825.34a.25.25 0 00-.135.136.244.244 0 000 .191l.565 1.367a.25.25 0 00.327.135l.978-.4a.749.749 0 11.573 1.385l-.978.4a.25.25 0 00-.136.327l.449 1.085a.8.8 0 11-1.487.616l-2.863-6.921a1 1 0 00-1.848 0l-2.753 6.636a1 1 0 00.767 1.371.8.8 0 01.618.487l1.168 2.813C12.164 23.3 15 24 16.7 24c4.861 0 8.016-4.83 6.572-8.321zM9.374 5.918V2.004A2 2 0 008.545.381 1.975 1.975 0 006.792.092L3.743.94a2 2 0 00-1.367 1.9v2.526a2 2 0 102 2.052l-.045-4.374a.252.252 0 01.181-.244l2.547-.708a.25.25 0 01.317.241v1.533a2 2 0 102 2.052zM6.376 8.961a1 1 0 00-1 1v2.25a2 2 0 102 2.052v-4.3a1 1 0 00-1-1.002z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});