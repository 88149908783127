define("ember-svg-jar/inlined/style-three-pin-printer-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-printer_1</title><circle cx=\"7.75\" cy=\"9.25\" r=\".75\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM8.5 4a1 1 0 011-1H13a.5.5 0 01.354.146l2 2a.5.5 0 01.146.354V6a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5zM15 18a.5.5 0 01-.5.5h-5A.5.5 0 019 18v-5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5zm3-5.5a1 1 0 01-1 1 .5.5 0 01-.5-.5v-1a1 1 0 00-1-1h-7a1 1 0 00-1 1v1a.5.5 0 01-.5.5 1 1 0 01-1-1v-4a1 1 0 011-1h10a1 1 0 011 1z\"/><path d=\"M13 14h-2a.5.5 0 000 1h2a.5.5 0 000-1zM13 16h-2a.5.5 0 000 1h2a.5.5 0 000-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});