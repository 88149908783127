define("ember-svg-jar/inlined/dentistry-tooth-equipment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dentistry-tooth-equipment</title><path d=\"M5.96 13.25a2 2 0 00-2 2V23a1 1 0 001 1h2a1 1 0 001-1v-7.75a2 2 0 00-2-2zM17.96 13.25a2 2 0 00-2 2V23a1 1 0 001 1h2a1 1 0 001-1v-7.75a2 2 0 00-2-2zM4.96 8.559v2.691a.752.752 0 00.75.75h.5a.751.751 0 00.75-.75V8.5a2.008 2.008 0 00-.8-1.6L3.784 5.118a.25.25 0 01-.016-.387l3.356-2.984A1 1 0 007.207.335 1 1 0 006.459 0 .993.993 0 005.8.253L2.211 3.439a2 2 0 00.129 3.1l2.573 1.927zM17.96 0a4.495 4.495 0 00-1.349 8.784.5.5 0 01.349.477v1.989a.752.752 0 00.75.75h.5a.751.751 0 00.75-.75V9.261a.5.5 0 01.35-.477A4.495 4.495 0 0017.96 0zm0 7a2.5 2.5 0 112.5-2.5 2.5 2.5 0 01-2.5 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});