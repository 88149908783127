define("ember-svg-jar/inlined/style-three-pin-boat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-boat</title><path d=\"M12.558 6h-2a.5.5 0 000 1h2a.5.5 0 000-1zM8.558 6h-2a.5.5 0 000 1h2a.5.5 0 000-1z\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM5.538 5h8.63a.5.5 0 01.485.379l.578 2.31a.251.251 0 01-.046.215.248.248 0 01-.2.1H4.192a.25.25 0 01-.236-.331A7.714 7.714 0 015.538 5zM17 15.5a3.517 3.517 0 01-2.351-.83.219.219 0 00-.3 0A3.515 3.515 0 0112 15.5a3.517 3.517 0 01-2.351-.83.218.218 0 00-.3 0A3.52 3.52 0 017 15.5a.5.5 0 010-1c.827 0 1.811-.354 2.018-1.129a.519.519 0 01.965 0c.207.775 1.191 1.129 2.017 1.129s1.811-.354 2.018-1.129a.519.519 0 01.965 0c.207.775 1.191 1.129 2.017 1.129a.5.5 0 010 1zm.648-2.288a.25.25 0 01-.257.088 1.617 1.617 0 00-.391-.05 1.808 1.808 0 01-.7-.139.247.247 0 01-.138-.152 1.754 1.754 0 00-1.656-1.227 1.725 1.725 0 00-1.661 1.209.255.255 0 01-.118.144 1.6 1.6 0 01-.73.165 1.83 1.83 0 01-.7-.138.252.252 0 01-.138-.153 1.754 1.754 0 00-1.656-1.227 1.725 1.725 0 00-1.661 1.209.255.255 0 01-.118.144A1.6 1.6 0 017 13.25a1.739 1.739 0 00-1.354.646.25.25 0 01-.224.09.983.983 0 01-.752-.486 6.606 6.606 0 01-1.049-3.755.25.25 0 01.25-.246H19a.5.5 0 01.489.6 7.762 7.762 0 01-1.841 3.113z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});