define("ember-svg-jar/inlined/ethernet-port", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ethernet-port</title><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2zm-4.5 13a1 1 0 01-1 1h-3.75a.25.25 0 00-.25.25v2.25a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5v-2.25a.25.25 0 00-.25-.25H5.5a1 1 0 01-1-1V7a1 1 0 011-1h1a.25.25 0 01.25.25V9a.75.75 0 001.5 0V6.25A.25.25 0 018.5 6h1a.25.25 0 01.25.25V9a.75.75 0 001.5 0V6.25A.25.25 0 0111.5 6h1a.25.25 0 01.25.25V9a.75.75 0 001.5 0V6.25A.25.25 0 0114.5 6h1a.25.25 0 01.25.25V9a.75.75 0 001.5 0V6.25A.25.25 0 0117.5 6h1a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});