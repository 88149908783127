define("ember-svg-jar/inlined/style-three-pin-bus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-bus</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm7 9.25a.75.75 0 01-1.5 0v-.5a.25.25 0 00-.25-.25h-.5a.25.25 0 00-.25.25V14a1.5 1.5 0 01-.857 1.349.25.25 0 00-.143.226v.175a.75.75 0 01-1.5 0 .25.25 0 00-.25-.25h-3.5a.25.25 0 00-.25.25.75.75 0 01-1.5 0v-.175a.25.25 0 00-.143-.226A1.5 1.5 0 017.5 14V8.75a.25.25 0 00-.25-.25h-.5a.25.25 0 00-.25.25v.5a.75.75 0 01-1.5 0v-.5A1.752 1.752 0 016.75 7h.5a.25.25 0 00.25-.25V6.5A2.5 2.5 0 0110 4h4a2.5 2.5 0 012.5 2.5v.25a.25.25 0 00.25.25h.5A1.752 1.752 0 0119 8.75z\"/><rect x=\"8.5\" y=\"7\" width=\"7\" height=\"3.5\" rx=\".5\" ry=\".5\"/><circle cx=\"9.5\" cy=\"13\" r=\"1\"/><circle cx=\"14.5\" cy=\"13\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});