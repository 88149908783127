define("ember-svg-jar/inlined/barbecue-stick-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>barbecue-stick-1</title><path d=\"M15.3 4.2a1 1 0 10-1.414 1.414l.08.08a.249.249 0 01.068.225.252.252 0 01-.15.182 3.071 3.071 0 00-1.7 1.763.25.25 0 01-.411.09l-.114-.114a1 1 0 10-1.414 1.415l4.5 4.5a1 1 0 001.414-1.415l-.113-.112a.25.25 0 01.09-.411 3.067 3.067 0 001.76-1.7.249.249 0 01.408-.081l.079.079A1 1 0 1019.8 8.7zM9.216 10.282A1 1 0 007.8 11.7l.355.356a.25.25 0 01-.085.409 3.713 3.713 0 00-1.259.825 3.686 3.686 0 00-1.087 2.178 2.856 2.856 0 00.058 1 .5.5 0 01-.132.474l-.65.65a1.972 1.972 0 00-.309 2.345 1.417 1.417 0 01-.188 1.688 1.45 1.45 0 01-2.043-.079 1.45 1.45 0 01-.077-2.046 1 1 0 00-1.414-1.419 3.45 3.45 0 00.079 4.871 3.451 3.451 0 004.872.079 3.338 3.338 0 00.656-3.73.5.5 0 01.1-.562l.392-.392a.5.5 0 01.474-.133 2.691 2.691 0 00.659.08 3.618 3.618 0 002.513-1.107 3.891 3.891 0 00.843-1.247.25.25 0 01.408-.08l.339.338a1 1 0 001.414-1.414zM23.706.293a1 1 0 00-1.415 0l-.719.72a.5.5 0 01-.608.078 1.5 1.5 0 00-1.823.231l-1.992 1.994a.5.5 0 000 .707l2.828 2.828a.5.5 0 00.708 0l1.991-1.992a1.5 1.5 0 00.232-1.822.5.5 0 01.077-.606l.722-.724a1 1 0 00-.001-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});