define("ember-svg-jar/inlined/monetization-bill-magnet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monetization-bill-magnet</title><path d=\"M5.5 9.75h13A1.752 1.752 0 0020.25 8V2A1.752 1.752 0 0018.5.25h-13A1.752 1.752 0 003.75 2v6A1.752 1.752 0 005.5 9.75zM5.25 2a.25.25 0 01.25-.25h13a.25.25 0 01.25.25v6a.25.25 0 01-.25.25h-13A.25.25 0 015.25 8z\"/><circle cx=\"12\" cy=\"5\" r=\"2\"/><path d=\"M18.25 13.5a1.752 1.752 0 00-1.75-1.75h-2a1.752 1.752 0 00-1.75 1.75v4.25c0 .232-.036 1.286-.75 1.286s-.75-1.054-.75-1.286V13.5a1.752 1.752 0 00-1.75-1.75h-2a1.752 1.752 0 00-1.75 1.75v4.144c0 3.656 2.512 6.106 6.25 6.106s6.25-2.454 6.25-6.106zM9.75 15a.249.249 0 01-.25.25h-2a.249.249 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h2a.25.25 0 01.25.25zm4.5-1.5a.25.25 0 01.25-.25h2a.25.25 0 01.25.25V15a.249.249 0 01-.25.25h-2a.249.249 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});