define("ember-svg-jar/inlined/deer-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>deer-2</title><path d=\"M23 0a1 1 0 00-1 1h-3.749a5.506 5.506 0 00-5.5 5.5V8a.5.5 0 00.5.5h1a.5.5 0 00.5-.5h2.5a3 3 0 003-3 1 1 0 00-2 0 1 1 0 01-1 1h-2.464a3.5 3.5 0 013.464-3H22a2 2 0 002-2 1 1 0 00-1-1z\"/><path d=\"M15.5 10h-3.129a.5.5 0 01-.474-.342c-.295-.887-1.255-3.218-3.3-3.6a.584.584 0 00-.097-.012.5.5 0 00-.5.5A5.125 5.125 0 0010 11s-4 6-7 6H.5a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h7.675a.5.5 0 00.457-.3c.454-1.019 1.946-4.358 2.368-5.2.5-1 2-1.5 4-1.5h5c1.5 0 1.5-1 1.5-1s-2.393-3.241-3-4c-.7-.872-1.5-2-3-2zm-1 4a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});