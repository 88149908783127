define("ember-svg-jar/inlined/legal-hammer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>legal-hammer</title><path d=\"M3 21h8a1 1 0 001-1v-1.5a1.5 1.5 0 00-1.5-1.5h-7A1.5 1.5 0 002 18.5V20a1 1 0 001 1zM23 18a1.623 1.623 0 000-2.336l-6.814-6.133a.25.25 0 01-.009-.362l1.763-1.764a.25.25 0 01.327-.023 1.5 1.5 0 001.959-2.262L15.545.439A1.5 1.5 0 0013.283 2.4a.249.249 0 01-.023.326L7.725 8.259a.251.251 0 01-.327.024 1.5 1.5 0 00-1.959 2.261l4.681 4.681a1.5 1.5 0 002.262-1.959.249.249 0 01.024-.326l1.763-1.764a.25.25 0 01.363.01L20.667 18A1.624 1.624 0 0023 18zM12.5 22h-11a1 1 0 000 2h11a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});