define("ember-svg-jar/inlined/task-finger-bandage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-finger-bandage</title><path d=\"M19.832 10.208a.5.5 0 00-.694.461V12.5a.5.5 0 01-1 0V9.744A1.485 1.485 0 0016.862 9h-.224a.5.5 0 00-.5.5v2a.5.5 0 01-1 0V8.744A1.485 1.485 0 0013.862 8a.5.5 0 00-.5.5v3a.5.5 0 01-1 0V8a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v7.078a.25.25 0 01-.454.145l-1.671-2.347a1.99 1.99 0 00-3.568.807A1.964 1.964 0 003 15.178l5.951 8.4a1 1 0 00.82.422h8.714a1 1 0 00.961-.724l1.518-5.292a10.779 10.779 0 00.4-2.884v-2.6a2.486 2.486 0 00-1.532-2.292zM8.862 2.5h3a.5.5 0 00.5-.5 2 2 0 00-4 0 .5.5 0 00.5.5zM5.737 6.218a1 1 0 001.249 1.563l2.09-1.672A.5.5 0 019.388 6h2.474a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5H9.388a.5.5 0 01-.312-.109l-2.09-1.673a1 1 0 00-1.249 1.563L7.017 4.8a.25.25 0 010 .391z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});