define("ember-svg-jar/inlined/social-media-iwiw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-iwiw</title><path d=\"M17.62 5.39a2.06 2.06 0 00-.76-2.81L13.51.64C12-.21 11.63-.21 10.17.62L3.24 4.83h-.05C1.71 5.71 1.5 6.07 1.5 7.77v8.45c0 1.72.19 2 1.69 2.92l7 4.24a3.43 3.43 0 001.64.62 3.48 3.48 0 001.67-.64l3.49-2a2.06 2.06 0 00-2.17-3.5l-3 1.73-6.32-3.8V8.21l6.32-3.78 3 1.72a2.07 2.07 0 002.8-.76zM21 5.93h-1a1.47 1.47 0 00-1.5 1.4v9.14a1.51 1.51 0 001.5 1.46h1a1.45 1.45 0 001.45-1.45V7.33A1.41 1.41 0 0021 5.93z\"/><path d=\"M10.91 7.11L7.35 9.18a.5.5 0 00-.25.43v4a1.48 1.48 0 001.46 1.6 1.56 1.56 0 001.54-1.6v-2.43L12.37 10a1.61 1.61 0 00-1.46-2.86z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});