define("ember-svg-jar/inlined/medical-nanobot-lungs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-nanobot-lungs</title><path d=\"M18.447 15.426A7.992 7.992 0 0112.47 11.3a.25.25 0 00-.47.119V13.2a.25.25 0 01-.406.2l-.906-.725a.5.5 0 01-.188-.39V1a1 1 0 00-2 0v11.279a.5.5 0 01-.188.39l-.906.725A.25.25 0 017 13.2V9.7A1.7 1.7 0 005.3 8a3.083 3.083 0 00-2.468 1.244A14.164 14.164 0 000 17.5v4.2A2.3 2.3 0 002.3 24a4.211 4.211 0 001.328-.215l1.892-.631A2.162 2.162 0 007 21.1v-4.58a.5.5 0 01.188-.391l2-1.6a.5.5 0 01.624 0l2 1.6a.5.5 0 01.188.391v4.58a2.162 2.162 0 001.48 2.053l1.892.631A4.206 4.206 0 0016.7 24a2.3 2.3 0 002.3-2.3v-4.2a11.849 11.849 0 00-.123-1.649.5.5 0 00-.43-.425z\"/><path d=\"M23.625 7.225L22.04 5.954A.5.5 0 0122 5.21l.177-.176a1.109 1.109 0 000-1.567L19.033.324a1.108 1.108 0 00-1.566 0l-3.143 3.143a1.109 1.109 0 000 1.567l.177.176a.5.5 0 01-.041.744l-1.586 1.271a1 1 0 00-.242 1.275l2 3.495A1 1 0 1016.368 11l-1.363-2.378a.5.5 0 01.121-.639l.843-.675a.5.5 0 01.667.036l.468.469a.5.5 0 01.146.353V11.5a1 1 0 002 0V8.166a.5.5 0 01.146-.353l.469-.469a.5.5 0 01.666-.036l.843.675a.5.5 0 01.121.639L20.132 11a1 1 0 001.736 1l2-3.495a1 1 0 00-.243-1.28zM18.25 6A1.75 1.75 0 1120 4.25 1.749 1.749 0 0118.25 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});