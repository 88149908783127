define("ember-svg-jar/inlined/target-center-phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>target-center-phone</title><path d=\"M18.757 6.662l2.351-2.35a.488.488 0 00-.345-.832h-2.671a.249.249 0 01-.25-.25V.56a.486.486 0 00-.831-.345l-2.35 2.35a2.416 2.416 0 00-.713 1.722v1.635a.25.25 0 01-.117.212.442.442 0 00-.085.066l-4.869 4.868a.974.974 0 001.377 1.377l4.869-4.868a.53.53 0 00.074-.1.217.217 0 01.186-.105h1.653a2.418 2.418 0 001.721-.71z\"/><path d=\"M8.95 9.909a.974.974 0 00-.616-1.847 3.895 3.895 0 104.928 4.926.974.974 0 00-1.849-.614A1.948 1.948 0 118.95 9.909z\"/><path d=\"M5.184 23.928h8.764a2.437 2.437 0 002.434-2.434v-9.737a.974.974 0 00-1.948 0v6.566a.25.25 0 01-.25.25H4.947a.25.25 0 01-.25-.25V4.218a.249.249 0 01.25-.25H12a.487.487 0 00.487-.488V1.047A.486.486 0 0012 .56H5.184A2.436 2.436 0 002.75 2.994v18.5a2.437 2.437 0 002.434 2.434zm4.382-3.407a.974.974 0 11-.974.974.975.975 0 01.974-.974z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});