define("ember-svg-jar/inlined/programming-team-chat-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-team-chat-1</title><path d=\"M19.237 13a3.73 3.73 0 00-2.337 6.638.249.249 0 01-.044.417 4.956 4.956 0 00-2.6 3.334.5.5 0 00.484.611h8.76a.5.5 0 00.487-.611 4.949 4.949 0 00-2.473-3.264.25.25 0 01-.032-.419A3.724 3.724 0 0019.237 13zm1.738 3.737a1.735 1.735 0 01-3.453.245.25.25 0 01.278-.282 3.911 3.911 0 00.522.037 4.481 4.481 0 002.224-.594.25.25 0 01.365.151 1.655 1.655 0 01.064.443zM3.75 9.747A1.752 1.752 0 005.5 11.5h5.75a.5.5 0 01.5.5v1.75a.75.75 0 00.463.693.738.738 0 00.287.057.746.746 0 00.53-.22l2.634-2.636a.5.5 0 01.354-.147H19.5a1.752 1.752 0 001.75-1.75v-8A1.752 1.752 0 0019.5 0h-14a1.752 1.752 0 00-1.75 1.75zM15.47 4.28a.75.75 0 111.06-1.06l2 2a.749.749 0 010 1.06l-2 2a.75.75 0 11-1.06-1.06l1.293-1.293a.252.252 0 000-.354zm-4.641 3.135l2-4a.75.75 0 111.342.67l-2 4a.75.75 0 01-.671.415.741.741 0 01-.334-.079.75.75 0 01-.337-1.006zM6.47 5.22l2-2a.75.75 0 111.06 1.06L8.237 5.573a.252.252 0 000 .354L9.53 7.22a.75.75 0 11-1.06 1.06l-2-2a.749.749 0 010-1.06zM.013 23.389A.5.5 0 00.5 24h8.76a.5.5 0 00.487-.611 4.955 4.955 0 00-2.612-3.341.251.251 0 01-.045-.418A3.734 3.734 0 101 16.737a3.71 3.71 0 001.5 2.976.25.25 0 01-.03.419 4.95 4.95 0 00-2.457 3.257zM3.061 16.3a.249.249 0 01.145-.165.252.252 0 01.219.014 4.485 4.485 0 002.225.594 3.911 3.911 0 00.522-.037.252.252 0 01.21.071.248.248 0 01.07.21A1.734 1.734 0 013 16.737a1.655 1.655 0 01.061-.437z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});