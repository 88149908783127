define("ember-svg-jar/inlined/file-code-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-star</title><path d=\"M12.607 17.139a1.264 1.264 0 00.3-1.378A1.234 1.234 0 0011.738 15h-2.5a.249.249 0 01-.229-.151l-1.345-3.117-.013-.032a1.293 1.293 0 00-2.317.028l-1.346 3.121a.251.251 0 01-.23.15h-2.5a1.23 1.23 0 00-1.166.76 1.272 1.272 0 00.32 1.4L2.6 19.111a.25.25 0 01.063.286l-1.219 2.814a1.253 1.253 0 00.314 1.451 1.359 1.359 0 001.506.167l3.112-1.753a.249.249 0 01.245 0l3.113 1.753a1.277 1.277 0 001.506-.167 1.254 1.254 0 00.315-1.45L10.331 19.4a.252.252 0 01.063-.287zM17.75 11.5V15a.75.75 0 001.5 0v-3.5a.75.75 0 10-1.5 0zM13.75 8.5V5a.75.75 0 00-1.5 0v3.5a.75.75 0 001.5 0zM20.25 8V5.5A1.251 1.251 0 0019 4.251h-1.5A1.251 1.251 0 0016.25 5.5V8a1.252 1.252 0 001.25 1.25H19A1.252 1.252 0 0020.25 8zm-1.5-.5a.25.25 0 01-.25.25H18a.25.25 0 01-.25-.25V6a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25z\"/><path d=\"M23.414 3L21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v6.793a.248.248 0 00.232.249 8.446 8.446 0 011.452.211.248.248 0 00.316-.24V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.121a.5.5 0 01.146.355V18a.5.5 0 01-.5.5h-7.335a.231.231 0 00-.163.06c-.218.2-.918.829-1.2 1.087a.222.222 0 00-.067.243l.205.473a.263.263 0 00.259.136H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M11.25 12v.763a.247.247 0 00.232.249c.259.016.684.047.987.09a.248.248 0 00.281-.248V12.5a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v1.08a.242.242 0 00.079.178 6.6 6.6 0 01.955 1.414c.056.108.18.126.216.078a1.242 1.242 0 00.25-.749V12A1.25 1.25 0 0014 10.751h-1.5A1.25 1.25 0 0011.25 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});