define("ember-svg-jar/inlined/app-window-bookmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-bookmark</title><path d=\"M24 4.5a3 3 0 00-3-3H3a3 3 0 00-3 3v15a3 3 0 003 3h18a3 3 0 003-3zM3 5a1 1 0 111 1 1 1 0 01-1-1zm3 0a1 1 0 111 1 1 1 0 01-1-1zm3 0a1 1 0 111 1 1 1 0 01-1-1zm13 14.5a1 1 0 01-1 1H3a1 1 0 01-1-1V9a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M13.542 18.472a.887.887 0 00.334.066.871.871 0 00.618-.257l1.829-1.83a.251.251 0 01.354 0l1.829 1.83A.875.875 0 0020 17.662V9.818a.5.5 0 00-.5-.5h-6a.5.5 0 00-.5.5v7.844a.876.876 0 00.542.81z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});