define("ember-svg-jar/inlined/mouse.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mouse</title><path d=\"M22.447 18.858l-2.558-1.28A9.094 9.094 0 0021 13.252a10.356 10.356 0 00-.8-4.052 4.5 4.5 0 10-5.172-4.936 9.238 9.238 0 00-6.054 0A4.5 4.5 0 103.8 9.2a10.356 10.356 0 00-.8 4.052 9.094 9.094 0 001.111 4.326l-2.558 1.28a1 1 0 00-.448 1.342 1.017 1.017 0 001.342.448l2.8-1.4q.325.392.683.745l-1.711 2.134a1 1 0 00.157 1.406 1.011 1.011 0 001.4-.156l1.699-2.117a8.689 8.689 0 003.775 1.448v-3.153c-.647-.307-1.25-.907-1.25-1.3 0-.553 1.172-1 2-1s2 .447 2 1c0 .4-.6 1-1.25 1.3v3.153a8.689 8.689 0 003.775-1.448l1.694 2.117a1.018 1.018 0 001.406.156 1 1 0 00.156-1.406l-1.706-2.133q.357-.352.683-.745l1.677.839c.714.357 1.92 1.191 2.46.111a1 1 0 00-.448-1.341zm-6.7-6.606A1.25 1.25 0 1114.5 13.5a1.25 1.25 0 011.25-1.248zM7 13.5a1.25 1.25 0 111.25 1.25A1.25 1.25 0 017 13.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});