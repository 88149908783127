define("ember-svg-jar/inlined/task-list-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-list-question</title><path d=\"M6 7.749a.75.75 0 000 1.5h6a.75.75 0 000-1.5zM9.4 16.249a.241.241 0 00.238-.2 7.961 7.961 0 01.237-.954c.037-.116 0-.345-.332-.345H6a.75.75 0 100 1.5z\"/><path d=\"M9.686 19.194a.244.244 0 00-.238-.2H3.5a.5.5 0 01-.5-.5V5.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v4.08a.247.247 0 00.3.245 18.173 18.173 0 012.468-.293.249.249 0 00.232-.25V3.5A1.5 1.5 0 0016.5 2h-4a.241.241 0 01-.208-.121 3.827 3.827 0 00-6.588 0A.241.241 0 015.5 2h-4A1.5 1.5 0 000 3.5v17A1.5 1.5 0 001.5 22h8.94a.246.246 0 00.207-.379 7.977 7.977 0 01-.961-2.427z\"/><path d=\"M12.244 11.48c.09-.079-.009-.231-.244-.231H6a.75.75 0 000 1.5h4.958a.241.241 0 00.192-.1 8.059 8.059 0 011.094-1.169zM17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm0 11.5a1 1 0 111-1 1 1 0 01-1 1zm.872-4.148a.172.172 0 00-.122.166v.232a.75.75 0 01-1.5 0v-.55a1.255 1.255 0 01.988-1.221 1.126 1.126 0 10-1.363-1.1.75.75 0 01-1.5 0 2.625 2.625 0 113.5 2.477z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});