define("ember-svg-jar/inlined/navigation-smartphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-smartphone</title><path d=\"M17 .034H7a3 3 0 00-3 3v17.932a3 3 0 003 3h10a3 3 0 003-3V3.034a3 3 0 00-3-3zm-4.75 22.54a1 1 0 111-1 1 1 0 01-1 1zM18 17.966a1 1 0 01-1 1H7a1 1 0 01-1-1V3.534a1 1 0 011-1h10a1 1 0 011 1z\"/><path d=\"M9.625 7.616a1.75 1.75 0 10-1.75 0A.251.251 0 018 7.832v1.55a.252.252 0 01-.125.217 1.738 1.738 0 000 3.017.251.251 0 01.125.216v1.55a.252.252 0 01-.125.217 1.75 1.75 0 101.75 0 .252.252 0 01-.125-.217v-1.55a.251.251 0 01.125-.216 1.738 1.738 0 000-3.017.252.252 0 01-.125-.217v-1.55a.251.251 0 01.125-.216zM16.25 5.357h-4a.75.75 0 000 1.5h4a.75.75 0 000-1.5zM12.25 11.857h3a.75.75 0 000-1.5h-3a.75.75 0 000 1.5zM16.25 15.357h-4a.75.75 0 000 1.5h4a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});