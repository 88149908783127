define("ember-svg-jar/inlined/yoga-stretch-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>yoga-stretch-1</title><path d=\"M13.987 16.5a2.5 2.5 0 01-2.387-1.754l-1.18-3.791a.261.261 0 00-.057-.1L8.394 8.768a.25.25 0 00-.388.03 3.478 3.478 0 00-.463 3.019l1.195 3.825a5.5 5.5 0 005.249 3.858H15.8a.25.25 0 00.25-.25v-2.5a.249.249 0 00-.25-.25zM21.684 8.416l-.013-.008-.011-.008-3.613-2.892a1.483 1.483 0 00-1.373-.149.248.248 0 00-.156.27 2.515 2.515 0 010 .76 2.464 2.464 0 01-.438 1.051.251.251 0 00.015.31 1.45 1.45 0 00.28.248l3.589 2.875a.5.5 0 01.188.567l-1.116 3.35a2.5 2.5 0 01-1.8 1.642.249.249 0 00-.192.243v2.519a.254.254 0 00.084.188.25.25 0 00.2.06 5.483 5.483 0 004.555-3.7l1.207-3.625a3.126 3.126 0 00-1.406-3.701zM13.166 4.786L9.153 7.705a.251.251 0 00-.034.374l1.728 1.833a.25.25 0 00.329.031l3.753-2.731a1.5 1.5 0 00-1.767-2.423z\"/><circle cx=\"3.25\" cy=\"10.498\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});