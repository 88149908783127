define("ember-svg-jar/inlined/single-man-actions-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-star</title><path d=\"M9.244 15a3.236 3.236 0 013.015-2h1.187a.25.25 0 00.23-.151l.458-1.062a.246.246 0 000-.192.25.25 0 00-.134-.138l-2.851-1.223a.25.25 0 01-.149-.229V8.869a.252.252 0 01.074-.177 3.305 3.305 0 00.779-2.23.251.251 0 01.088-.176 1.874 1.874 0 00.538-1.4 1.575 1.575 0 00-.317-1.014.253.253 0 01-.034-.265 2.528 2.528 0 00.156-1.726C11.944.492 10.241 0 8.888 0a3.754 3.754 0 00-3.163 1.324.25.25 0 01-.212.118 1.073 1.073 0 00-.9.375c-.372.493-.105 1.171.176 1.732a.251.251 0 01-.046.288 1.242 1.242 0 00-.161.2A1.763 1.763 0 004.32 5a1.522 1.522 0 00.639 1.331.251.251 0 01.117.193A3.156 3.156 0 005.921 8.7.251.251 0 016 8.882V10a.25.25 0 01-.152.23l-3.935 1.691C.64 12.467.458 13.625.206 15.23c-.056.358-.117.75-.2 1.178a.5.5 0 00.106.411A.5.5 0 00.5 17h8.306a.25.25 0 00.246-.291A3.132 3.132 0 019.244 15zM17.378 22.076a.249.249 0 01.245 0l3.113 1.752a1.275 1.275 0 001.5-.166 1.256 1.256 0 00.316-1.45L21.333 19.4a.249.249 0 01.063-.286l2.212-1.971a1.262 1.262 0 00.3-1.378A1.232 1.232 0 0022.739 15h-2.5a.25.25 0 01-.23-.151l-1.344-3.116-.012-.028a1.294 1.294 0 00-2.318.028l-1.345 3.115a.25.25 0 01-.23.151h-2.5a1.228 1.228 0 00-1.166.76 1.268 1.268 0 00.319 1.4l2.194 1.955a.251.251 0 01.063.286l-1.223 2.815a1.253 1.253 0 00.314 1.451 1.278 1.278 0 001.506.166z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});