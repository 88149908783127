define("ember-svg-jar/inlined/gift-box-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gift-box-1</title><path d=\"M17.469 11.375h-3.594a6.609 6.609 0 002.3-1.105 2.547 2.547 0 10-3.6-3.6 3.568 3.568 0 00-.6.985 3.538 3.538 0 00-.6-.985 2.547 2.547 0 00-3.6 3.6 6.6 6.6 0 002.3 1.105H6.469a1.752 1.752 0 00-1.75 1.75v1a1.764 1.764 0 001.75 1.75h4.5a.25.25 0 00.25-.25v-4.25h1.5v4.25a.25.25 0 00.25.25h4.5a1.763 1.763 0 001.75-1.75v-1a1.752 1.752 0 00-1.75-1.75zm-3.481-3.293a.547.547 0 01.78.768 5.881 5.881 0 01-1.34.566 5.736 5.736 0 01.56-1.334zm-4.812 0a.546.546 0 01.764-.01 5.757 5.757 0 01.569 1.344A5.842 5.842 0 019.17 8.85a.547.547 0 01.006-.768zM12.969 17.375a.25.25 0 00-.25.25v5a.25.25 0 00.25.25h2.569a1.756 1.756 0 001.745-1.626l.258-3.606a.252.252 0 00-.25-.268zM11.219 17.625a.25.25 0 00-.25-.25H6.646a.25.25 0 00-.249.268l.258 3.606A1.756 1.756 0 008.4 22.875h2.569a.25.25 0 00.25-.25zM11.969 4.625a1 1 0 001-1v-1.5a1 1 0 00-2 0v1.5a1 1 0 001 1zM6.122 7.036a1 1 0 000-1.414l-1.061-1.06a1 1 0 00-1.414 1.414l1.061 1.06a1 1 0 001.414 0zM2.692 11.875h-1.5a1 1 0 000 2h1.5a1 1 0 000-2zM20.353 4.562a1 1 0 00-1.414 0l-1.061 1.06a1 1 0 001.414 1.414l1.061-1.06a1 1 0 000-1.414zM22.808 11.875h-1.5a1 1 0 000 2h1.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});