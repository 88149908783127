define("ember-svg-jar/inlined/send-email-fly", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>send-email-fly</title><path d=\"M12 13.685a1 1 0 00-1 1v7.5a1 1 0 102 0v-7.5a1 1 0 00-1-1zM7.5 12.177a1 1 0 00-1 1v5.5a1 1 0 002 0v-5.5a1 1 0 00-1-1zM16.5 12.177a1 1 0 00-1 1v5.5a1 1 0 002 0v-5.5a1 1 0 00-1-1zM2.171.974A1.5 1.5 0 000 2.316v.705a1.493 1.493 0 00.943 1.393l1 .4a1.5 1.5 0 00-.21 2.031l.765 1a1.5 1.5 0 001.2.589 1.435 1.435 0 00.992-.391l.707-.9a.505.505 0 00.103-.306V3.2a.5.5 0 00-.248-.432zM17.391 3.827a.21.21 0 00-.215.006l-4.167 2.616a1.906 1.906 0 01-2.018 0L6.824 3.833a.211.211 0 00-.324.18v5.028a1.273 1.273 0 001.269 1.275h8.462A1.273 1.273 0 0017.5 9.041V4.012a.212.212 0 00-.109-.185z\"/><path d=\"M16.231.816H7.769A1.273 1.273 0 006.5 2.091a.062.062 0 00.03.054l5.134 3.223a.636.636 0 00.672 0l5.135-3.224a.062.062 0 00.029-.053A1.273 1.273 0 0016.231.816zM23.288 1.04A1.5 1.5 0 0021.8.989l-3.052 1.777a.5.5 0 00-.248.434v3.637a.505.505 0 00.105.308l.707.9a1.412 1.412 0 00.992.391 1.5 1.5 0 001.2-.59l.768-1.007a1.5 1.5 0 00-.213-2.028l1-.4A1.493 1.493 0 0024 3.021v-.7a1.493 1.493 0 00-.712-1.281z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});