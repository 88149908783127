define("ember-svg-jar/inlined/armchair-modern-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>armchair-modern-2</title><path d=\"M23 12c0-5.61-5.86-12-11-12S1 6.39 1 12c0 2.991 2.476 6.439 9.286 6.934a.5.5 0 01.464.5V21a.5.5 0 01-.5.5H5.5a1.25 1.25 0 000 2.5h13a1.25 1.25 0 000-2.5h-4.75a.5.5 0 01-.5-.5v-1.568a.5.5 0 01.464-.5C20.524 18.439 23 14.991 23 12zM5.63 14.38a7.148 7.148 0 01-.89-1.03.5.5 0 01-.08-.43.512.512 0 01.28-.33A21.707 21.707 0 0112 11a21.707 21.707 0 017.06 1.59.512.512 0 01.28.33.5.5 0 01-.08.43 7.148 7.148 0 01-.89 1.03.49.49 0 01-.32.12H5.95a.49.49 0 01-.32-.12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});