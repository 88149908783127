define("ember-svg-jar/inlined/footwear-sock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>footwear-sock</title><path d=\"M18.251 5a2.5 2.5 0 000-5h-8a2.5 2.5 0 100 5zM5.327 16.2a.5.5 0 00-.661.02A4.5 4.5 0 007.642 24a.5.5 0 00.506-.427A8.026 8.026 0 005.327 16.2zM9.7 23.2a.25.25 0 00.335.256l3.585-1.3a.5.5 0 00.293-.658 5.629 5.629 0 014.41-7.687.5.5 0 00.428-.495V7a.5.5 0 00-.5-.5h-8a.5.5 0 00-.5.5v5.935a1.5 1.5 0 01-.987 1.409l-1.925.7a.25.25 0 00-.086.417A9.514 9.514 0 019.7 23.2z\"/><path d=\"M15 19.379a4.126 4.126 0 00.446 1.868.249.249 0 00.317.118 4.461 4.461 0 002.988-4.228v-1.585a.249.249 0 00-.29-.247A4.135 4.135 0 0015 19.379z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});