define("ember-svg-jar/inlined/single-man-actions-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-lock</title><path d=\"M14.352 14.228a.25.25 0 00.154-.217 4.425 4.425 0 01.514-1.823.245.245 0 00.014-.2.252.252 0 00-.137-.146l-3.745-1.607a.251.251 0 01-.152-.23V8.87a.252.252 0 01.074-.177 3.305 3.305 0 00.779-2.23.251.251 0 01.088-.177 1.873 1.873 0 00.538-1.4 1.58 1.58 0 00-.317-1.015.252.252 0 01-.034-.265 2.527 2.527 0 00.157-1.726C11.944.492 10.242 0 8.888 0a3.752 3.752 0 00-3.163 1.324.251.251 0 01-.212.118 1.072 1.072 0 00-.9.375c-.372.493-.106 1.171.176 1.732a.251.251 0 01-.046.288 1.3 1.3 0 00-.162.2A1.765 1.765 0 004.32 5a1.524 1.524 0 00.638 1.331.249.249 0 01.117.194A3.167 3.167 0 005.92 8.7a.25.25 0 01.08.183v1.122a.25.25 0 01-.151.23l-3.937 1.687C.64 12.468.457 13.626.205 15.23c-.055.359-.117.751-.2 1.179A.5.5 0 00.5 17h11.759a.25.25 0 00.249-.231 3.008 3.008 0 011.844-2.541zM22.5 24a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.285-1.485.249.249 0 01-.215-.247V14.25a3.5 3.5 0 00-7 0v1.018a.249.249 0 01-.214.247A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7zm-5-9.75a1.5 1.5 0 113 0v1a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25zm2.5 6a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});