define("ember-svg-jar/inlined/study-notebook-maths", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>study-notebook-maths</title><path d=\"M8.294 22h-5.5a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v.25a1 1 0 002 0V5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v.25a1 1 0 102 0V5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v.25a1 1 0 102 0V5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v3.5a1 1 0 102 0v-4a2 2 0 00-2-2h-1.5a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v1a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V1a1 1 0 10-2 0v1a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V1a1 1 0 10-2 0v1a.5.5 0 01-.5.5h-1.5a2 2 0 00-2 2V22a2 2 0 002 2h6a1 1 0 000-2z\"/><path d=\"M22.933 11.766a2.619 2.619 0 00-3.7 0l-6.5 6.5a.5.5 0 000 .707l3 3a.5.5 0 00.707 0l6.5-6.5a2.62 2.62 0 00-.007-3.707zM11.768 20.132a.25.25 0 00-.422.127l-.628 3.141a.5.5 0 00.136.451.5.5 0 00.452.137l3.143-.629a.247.247 0 00.19-.173.249.249 0 00-.062-.249zM7.574 11.282a2.055 2.055 0 00.607-1.465 2.2 2.2 0 00-4.292-.7.75.75 0 001.422.483.7.7 0 011.37.221.571.571 0 01-.167.4L3.9 12.834a.75.75 0 00.53 1.281h3a.75.75 0 000-1.5h-.585a.25.25 0 01-.177-.427zM13.044 12.5a.75.75 0 000 1.5h2a.75.75 0 000-1.5.249.249 0 01-.25-.25v-3.5a1.25 1.25 0 00-2-1l-1.2.9a.75.75 0 00.9 1.2l.8-.6v3a.249.249 0 01-.25.25z\"/><path d=\"M11.044 12.115a.75.75 0 000-1.5.25.25 0 01-.25-.25.75.75 0 10-1.5 0 .25.25 0 01-.25.25.75.75 0 000 1.5.25.25 0 01.25.25.75.75 0 001.5 0 .25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});