define("ember-svg-jar/inlined/image-file-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-svg</title><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.427A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.25a.25.25 0 00-.25-.25zM6.427 14.867l1.84 1.227A1.927 1.927 0 017.2 19.625H5.5a.625.625 0 010-1.25h1.7a.678.678 0 00.376-1.242l-1.84-1.227A1.927 1.927 0 016.8 12.375h1.7a.625.625 0 010 1.25H6.8a.678.678 0 00-.376 1.242zm7.7-.367a8.176 8.176 0 01-1.627 4.875.625.625 0 01-1 0A8.176 8.176 0 019.875 14.5V13a.625.625 0 011.25 0v1.5a6.923 6.923 0 00.674 2.965.251.251 0 00.4.008 6.911 6.911 0 00.678-2.973V13a.625.625 0 011.25 0zm5 .5a.625.625 0 01-1.25 0v-.5a.875.875 0 00-1.75 0v3a.875.875 0 001.67.365c.035-.075.083-.24-.1-.24h-.2a.625.625 0 010-1.25h1a.625.625 0 01.625.625v.5a2.125 2.125 0 01-4.25 0v-3a2.125 2.125 0 014.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});