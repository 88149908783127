define("ember-svg-jar/inlined/volume-control-settings-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>volume-control-settings-1</title><path d=\"M11.056 17.2a.25.25 0 000-.34l-.174-.187a3.283 3.283 0 012.834-5.49.249.249 0 00.284-.246V1a1 1 0 00-1.061-1 9.726 9.726 0 00-7.924 4.85.251.251 0 01-.221.133H3a3 3 0 00-3 3v2a3 3 0 003 3h1.794a.249.249 0 01.221.133 9.445 9.445 0 005.544 4.411.248.248 0 00.263-.074l.061-.067z\"/><circle cx=\"17.748\" cy=\"17.031\" r=\"1.5\"/><path d=\"M23.516 15.654a1.783 1.783 0 00-1.7-2.953l-1.41.327a.451.451 0 01-.534-.311l-.424-1.392a1.774 1.774 0 00-3.4 0l-.424 1.391a.456.456 0 01-.533.312l-1.41-.328a1.783 1.783 0 00-1.7 2.953l.988 1.064a.464.464 0 010 .626l-.988 1.065a1.783 1.783 0 001.7 2.953l1.41-.327a.452.452 0 01.534.311l.424 1.392a1.775 1.775 0 003.4 0l.424-1.392a.452.452 0 01.533-.311l1.411.327a1.783 1.783 0 001.7-2.953l-.988-1.065a.464.464 0 010-.626zm-5.768 4.377a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});