define("ember-svg-jar/inlined/police-woman-4.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-woman-4</title><path d=\"M21.026 18.27l-4.887-1.235a.5.5 0 00-.409.075 7.06 7.06 0 01-3.751 1.39 7.054 7.054 0 01-3.745-1.386.5.5 0 00-.409-.074l-4.9 1.246A4.522 4.522 0 000 22.5v1a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-1a4.531 4.531 0 00-2.974-4.23zm-4.3 5.146l-.61-.406A2.5 2.5 0 0115 20.93V20a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v.93a2.5 2.5 0 01-1.113 2.08l-.61.406a.5.5 0 01-.554 0zM10.521 13.386a3.234 3.234 0 001.507.364 3.146 3.146 0 001.5-.365.75.75 0 10-.734-1.307 1.566 1.566 0 01-.764.172 1.653 1.653 0 01-.772-.174.75.75 0 10-.731 1.31z\"/><path d=\"M8.127 15.411a6.613 6.613 0 003.852 1.839 6.632 6.632 0 003.88-1.864 2.209 2.209 0 002 1.113 5 5 0 003.53-1.706.5.5 0 00-.232-.788 3.057 3.057 0 01-1.674-1.671 7.7 7.7 0 01-.531-1.452 3.148 3.148 0 00.3-1.244 1.885 1.885 0 00-.936-1.809.739.739 0 00-.309-.075 2.84 2.84 0 00.647-1.127 2.362 2.362 0 00.084-.488 1.485 1.485 0 00.466-.665l1.273-3.816a.5.5 0 00-.582-.646c-2.751.6-5.6 1.036-7.542-.869a.5.5 0 00-.7 0c-1.941 1.905-4.791 1.473-7.542.869a.5.5 0 00-.582.646L4.8 5.474a1.48 1.48 0 00.464.663 2.328 2.328 0 00.078.477 2.8 2.8 0 00.652 1.145.733.733 0 00-.347.07 1.886 1.886 0 00-.935 1.81 3.138 3.138 0 00.323 1.3 7.579 7.579 0 01-.535 1.424 3.039 3.039 0 01-1.66 1.642.5.5 0 00-.231.788A5 5 0 006.14 16.5a2.2 2.2 0 001.987-1.089zM10 4.43V3.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v.93a2.5 2.5 0 01-1.113 2.08l-.61.406a.5.5 0 01-.554 0l-.61-.406A2.5 2.5 0 0110 4.43zm1.979 11.32c-1.032 0-3.83-1.49-4.207-3.738a1.749 1.749 0 00-1-1.307 1.272 1.272 0 01-.559-1.1.624.624 0 01.1-.439.741.741 0 00.395-.791A9.084 9.084 0 0012 9.75a9.167 9.167 0 005.242-1.338.742.742 0 00.4.759c.024.012.11.148.105.439a1.276 1.276 0 01-.559 1.1 1.75 1.75 0 00-1 1.306c-.378 2.244-3.176 3.734-4.209 3.734z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});