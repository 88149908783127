define("ember-svg-jar/inlined/asian-interior-stove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>asian-interior-stove</title><path d=\"M3 17.385H2a.5.5 0 00-.5.5v4.5a1 1 0 002 0v-4.5a.5.5 0 00-.5-.5zM22 17.385h-1a.5.5 0 00-.5.5v4.5a1 1 0 102 0v-4.5a.5.5 0 00-.5-.5zM10 20.385H6a1.5 1.5 0 000 3h4a1.5 1.5 0 000-3zM18 20.385h-4a1.5 1.5 0 000 3h4a1.5 1.5 0 000-3zM22.5 12.885h-.854a.5.5 0 01-.493-.585 5.96 5.96 0 00.1-1.033V9.885A1.252 1.252 0 0020 8.635h-3a1.252 1.252 0 00-1.25 1.25v1.382a5.96 5.96 0 00.1 1.033.5.5 0 01-.493.585H13.83a.5.5 0 01-.5-.441l-.385-3.271a.258.258 0 01.256-.288 2.239 2.239 0 002.013-1.244l.558-1.117A.249.249 0 0116 6.385a1 1 0 000-2 2.241 2.241 0 00-2.013 1.244l-.558 1.118a.252.252 0 01-.224.138h-.08a.5.5 0 01-.5-.441L12.5 5.327a.5.5 0 00-.5-.442H6.5a.5.5 0 00-.5.442l-.128 1.117a.5.5 0 01-.5.441H3.75a2.25 2.25 0 000 4.5 1 1 0 000-2 .25.25 0 010-.5h1.272a.5.5 0 01.5.559l-.353 3a.5.5 0 01-.5.441H1.5a1.5 1.5 0 00-1.5 1.5v.5a1.5 1.5 0 001.5 1.5h21a1.5 1.5 0 001.5-1.5v-.5a1.5 1.5 0 00-1.5-1.5zM7 3.885h4.5a.5.5 0 00.5-.5 2.686 2.686 0 00-.868-2.076A2.8 2.8 0 009.253.615 2.673 2.673 0 006.5 3.385a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});