define("ember-svg-jar/inlined/crypto-currency-bitcoin-exhcange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-exhcange</title><path d=\"M18.5 6.5A6.5 6.5 0 1012 13a6.5 6.5 0 006.5-6.5zm-8.75-3a.75.75 0 01.75-.75.25.25 0 00.25-.25.75.75 0 011.5 0 .25.25 0 00.25.25h.5A2.253 2.253 0 0115.25 5a2.219 2.219 0 01-.46 1.349.249.249 0 000 .3A2.219 2.219 0 0115.25 8 2.253 2.253 0 0113 10.25h-.5a.25.25 0 00-.25.25.75.75 0 01-1.5 0 .25.25 0 00-.25-.25.75.75 0 01-.75-.75z\"/><path d=\"M13 7.25h-1.5a.25.25 0 00-.25.25v1a.25.25 0 00.25.25H13a.75.75 0 000-1.5zM11.5 4.25a.25.25 0 00-.25.25v1a.25.25 0 00.25.25H13a.75.75 0 000-1.5zM9.5 13H2a2 2 0 00-2 2v5.5a2 2 0 002 2h1.125a.25.25 0 01.2.4l-.225.3a.5.5 0 00.4.8H8a.5.5 0 00.4-.8l-.225-.3a.25.25 0 01.2-.4H9.5a2 2 0 002-2V15a2 2 0 00-2-2zM2 15.25a.25.25 0 01.25-.25h7a.249.249 0 01.25.25v4a.25.25 0 01-.25.25h-7a.25.25 0 01-.25-.25zM24 15a2 2 0 00-2-2h-7.5a2 2 0 00-2 2v5.5a2 2 0 002 2h1.125a.25.25 0 01.2.4l-.225.3a.5.5 0 00.4.8h4.5a.5.5 0 00.4-.8l-.225-.3a.25.25 0 01.2-.4H22a2 2 0 002-2zm-9.5.25a.25.25 0 01.25-.25h7a.249.249 0 01.25.25v4a.25.25 0 01-.25.25h-7a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});