define("ember-svg-jar/inlined/board-game-dice-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>board-game-dice-2</title><path d=\"M22 15.5h-4.5a1 1 0 010-2h4.25a.25.25 0 00.25-.25v-11a.25.25 0 00-.25-.25h-10.5a.25.25 0 00-.25.25V6.5a1 1 0 01-2 0V2a2 2 0 012-2h11a2 2 0 012 2v11.5a2 2 0 01-2 2z\"/><circle cx=\"19\" cy=\"10.5\" r=\"1\"/><circle cx=\"19\" cy=\"5\" r=\"1\"/><circle cx=\"14.5\" cy=\"5\" r=\"1\"/><path d=\"M13.5 9h-12A1.5 1.5 0 000 10.5v12A1.5 1.5 0 001.5 24h12a1.5 1.5 0 001.5-1.5v-12A1.5 1.5 0 0013.5 9zm-10 11a1 1 0 111-1 1 1 0 01-1 1zm0-5a1 1 0 111-1 1 1 0 01-1 1zm4 5a1 1 0 111-1 1 1 0 01-1 1zm0-5a1 1 0 111-1 1 1 0 01-1 1zm4 5a1 1 0 111-1 1 1 0 01-1 1zm0-5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});