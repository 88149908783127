define("ember-svg-jar/inlined/app-window-expand-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-expand-1</title><path d=\"M7.358 11.17a.513.513 0 00.192.038.5.5 0 00.353-.147l.272-.271a.25.25 0 01.353 0l1.491 1.491a.75.75 0 101.061-1.06L9.588 9.729a.252.252 0 010-.354l.271-.275a.5.5 0 00-.353-.853H7.55a.5.5 0 00-.5.5v1.957a.5.5 0 00.308.466zM13.55 12.5a.744.744 0 00.53-.22l1.491-1.491a.251.251 0 01.354 0l.271.271a.5.5 0 00.354.147.508.508 0 00.191-.038.5.5 0 00.309-.462V8.751a.5.5 0 00-.5-.5h-1.956a.5.5 0 00-.354.853l.271.271a.25.25 0 010 .354l-1.492 1.492a.75.75 0 00.531 1.28zM7.55 18.251h1.956a.5.5 0 00.353-.854l-.271-.271a.25.25 0 010-.353l1.492-1.492a.75.75 0 00-1.061-1.06l-1.491 1.491a.25.25 0 01-.353 0L7.9 15.44a.506.506 0 00-.545-.108.5.5 0 00-.308.462v1.957a.5.5 0 00.503.5zM14.511 16.773a.249.249 0 010 .353l-.271.271a.5.5 0 00.354.854h1.956a.5.5 0 00.5-.5v-1.957a.5.5 0 00-.309-.462.505.505 0 00-.545.108l-.271.272a.251.251 0 01-.354 0l-1.491-1.491a.75.75 0 00-1.061 1.06z\"/><path d=\"M24 4.751a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h18a3 3 0 003-3zm-14.346-1a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.562.091a1 1 0 01.908-.591.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.987.987 0 01.092-.409zM22 19.251a1 1 0 01-1 1H3a1 1 0 01-1-1V7a.25.25 0 01.25-.25h19.5A.25.25 0 0122 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});