define("ember-svg-jar/inlined/dog-poop-clean", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dog-poop-clean</title><path d=\"M2.258 22.188a2.25 2.25 0 002.25-2.25 11.515 11.515 0 00-1.8-4.217.563.563 0 00-.894 0 11.507 11.507 0 00-1.8 4.217 2.25 2.25 0 002.244 2.25zM15.589 16.759L18 18.81a.25.25 0 01-.162.44h-.827a2.25 2.25 0 00-2.25 2.25v1.75a.75.75 0 001.5 0v-.5a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-2.818a.25.25 0 01.388-.208l.377.25a1.578 1.578 0 001.938-2.484l-.948-.839a1.66 1.66 0 00-2.349.155l-.485.623a.248.248 0 01-.171.1.251.251 0 01-.188-.058l-2.756-2.348a.723.723 0 00-.96 0l-.011.011a.755.755 0 00-.007 1.125z\"/><circle cx=\"8.802\" cy=\"2.5\" r=\"2.5\"/><path d=\"M1.622 14.55a4.062 4.062 0 00.71-.29A4.464 4.464 0 004.8 10.25v-.11a.5.5 0 011 0v12.61a1.25 1.25 0 002.5 0V16.5a.5.5 0 011 0v6.25a1.25 1.25 0 002.5 0V10.14a.5.5 0 011 0V14a1.25 1.25 0 002.5 0v-3.61a4.005 4.005 0 00-4-4h-5a4 4 0 00-3.99 3.76.34.34 0 00-.01.1 2 2 0 01-1.41 1.91 1.253 1.253 0 00.37 2.45 1.133 1.133 0 00.362-.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});