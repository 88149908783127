define("ember-svg-jar/inlined/cursor-double-click-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-double-click-1</title><path d=\"M23.561.437a1.5 1.5 0 00-2.122 0L15.646 6.23a.249.249 0 01-.353 0L13.7 4.64a.5.5 0 00-.853.323l-.353 6.01a.5.5 0 00.5.53c.019 0-.4.024 6.04-.355a.5.5 0 00.324-.852l-1.59-1.591a.251.251 0 010-.354l5.793-5.793a1.5 1.5 0 000-2.121z\"/><path d=\"M19.487 14h-2.241a.25.25 0 00-.25.241A7 7 0 119.759 7 .25.25 0 0010 6.752V4.511a.5.5 0 00-.526-.5 10 10 0 1010.512 10.513.5.5 0 00-.5-.526z\"/><path d=\"M5 14a5 5 0 009.993.263.25.25 0 00-.25-.263h-2.527a.249.249 0 00-.248.222 1.99 1.99 0 11-2.19-2.19.249.249 0 00.222-.251V9.254a.25.25 0 00-.263-.249A5.006 5.006 0 005 14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});