define("ember-svg-jar/inlined/natural-disaster-flood", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>natural-disaster-flood</title><path d=\"M23.641 17.981a1 1 0 00-1.41.129c-.021.026-2.268 2.64-4.731 2.64a12.393 12.393 0 01-5.126-1.178A14.24 14.24 0 006.5 18.25c-3.375 0-6.093 2.679-6.207 2.793a1 1 0 001.412 1.416c.022-.022 2.258-2.209 4.8-2.209a12.393 12.393 0 015.126 1.178A14.24 14.24 0 0017.5 22.75c3.429 0 6.154-3.223 6.268-3.36a1 1 0 00-.127-1.409zM1.625 17.031A8.879 8.879 0 016.5 15.25a12.393 12.393 0 015.126 1.178A14.24 14.24 0 0017.5 17.75c3.38 0 6.051-3.558 6.3-3.9a1 1 0 00-.8-1.6c-1.01 0-1.5-.654-1.5-2a9.01 9.01 0 00-9-9 8.015 8.015 0 00-5.979 2.684 1.768 1.768 0 001.708 2.9 3.5 3.5 0 013.908 4.97 1 1 0 101.791.89 5.437 5.437 0 00.572-2.444A5.506 5.506 0 009 4.75a.167.167 0 01-.1-.3 6.01 6.01 0 013.6-1.2 7.009 7.009 0 017 7 3.889 3.889 0 001.446 3.379.249.249 0 01.025.387A5.608 5.608 0 0117.5 15.75a12.393 12.393 0 01-5.126-1.178A14.24 14.24 0 006.5 13.25a10.9 10.9 0 00-6.125 2.219 1 1 0 101.25 1.562z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});