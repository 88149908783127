define("ember-svg-jar/inlined/social-video-imdb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-video-imdb</title><path d=\"M.75 6.75A.75.75 0 000 7.5v9a.75.75 0 001.5 0v-9a.75.75 0 00-.75-.75zM9.07 6.76a.75.75 0 00-.83.51L6 14.13 3.66 7.26a.75.75 0 00-1.46.24v9a.75.75 0 001.5 0V12l1.6 4.71a.75.75 0 00.71.51H6a.75.75 0 00.71-.52l1.49-4.49v4.29a.75.75 0 001.5 0v-9a.75.75 0 00-.63-.74zM14.1 6.75h-3a.75.75 0 00-.75.75v9a.75.75 0 00.75.75h3a2.75 2.75 0 002.75-2.75v-5a2.75 2.75 0 00-2.75-2.75zm1.25 7.75a1.25 1.25 0 01-1.25 1.25h-2.25v-7.5h2.25a1.25 1.25 0 011.25 1.25zM21.25 9.75H19V7.5a.75.75 0 00-1.5 0v9a.75.75 0 00.75.75h3A2.75 2.75 0 0024 14.5v-2a2.75 2.75 0 00-2.75-2.75zm1.25 4.75a1.25 1.25 0 01-1.25 1.25H19v-4.5h2.25a1.25 1.25 0 011.25 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});