define("ember-svg-jar/inlined/toys-dinosaur", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toys-dinosaur</title><path d=\"M23.586 18.323a2.642 2.642 0 00-3.944-.585.587.587 0 01-.962-.341l-.246-1.333a.25.25 0 01.256-.295 8.736 8.736 0 001.708-.153 2.2 2.2 0 00-.624-4.361 4.46 4.46 0 01-1.588.017.249.249 0 01-.2-.234l-.059-1.251a.249.249 0 01.226-.261 5.174 5.174 0 003.2-1.4 4.177 4.177 0 001.171-3.3A5.273 5.273 0 0017.169 0h-3.7a5.608 5.608 0 00-3.315 1.08.5.5 0 01-.425.079 4.941 4.941 0 00-5.974 5.09 5.862 5.862 0 001.609 3.868.5.5 0 01-.016.712 5.083 5.083 0 00-1.593 3.892A5.988 5.988 0 004.49 17.4a.252.252 0 01.009.224.249.249 0 01-.174.141 7.313 7.313 0 01-2.853.006A1.244 1.244 0 00.02 19.222C.658 22.749 5.028 24 8.576 24H21.22A2.783 2.783 0 0024 21.22v-1.477a2.631 2.631 0 00-.414-1.42zm-15.3-6.5A7.277 7.277 0 016.641 16.5a.25.25 0 01-.214.076.247.247 0 01-.187-.128 4.071 4.071 0 01-.488-1.794 3.086 3.086 0 012.224-3.081.251.251 0 01.314.257zm-.302-8.73a.249.249 0 01.293.34 5.605 5.605 0 00-.451 2.209 19.8 19.8 0 00.223 2.188c.057.428.118.884.168 1.319a.251.251 0 01-.086.218.249.249 0 01-.228.051 3.422 3.422 0 01-2.151-3.239 3.084 3.084 0 012.232-3.086zm5.239 2.3a.978.978 0 11.978-.978.978.978 0 01-.978.985z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});