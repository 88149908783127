define("ember-svg-jar/inlined/golf-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>golf-cart</title><path d=\"M19.942 3.666A2.5 2.5 0 0017.585 2H2.415A2.5 2.5 0 00.057 3.668 1 1 0 001 5h18a1 1 0 00.942-1.334zM15.137 13.18a.5.5 0 00.088-.7l-.582-.749a.5.5 0 01.088-.7l.129-.1a.75.75 0 10-.92-1.185l-2.233 1.736a.749.749 0 00.919 1.184l.131-.1a.5.5 0 01.7.088l.583.75a.5.5 0 00.7.088z\"/><circle cx=\"20\" cy=\"20\" r=\"2\"/><path d=\"M24 15.5a3.5 3.5 0 00-3.5-3.5.9.9 0 01-.837-.58l-1.723-4.6a.5.5 0 00-.468-.324H16.4a.5.5 0 00-.468.676l1.785 4.76a1 1 0 01-.336 1.151L14.9 14.949a1 1 0 00-.4.8V17.5a.5.5 0 01-.5.5h-2.5a.5.5 0 01-.5-.5v-1A1.5 1.5 0 009.5 15h-2a.5.5 0 01-.5-.5v-2A1.5 1.5 0 005.5 11H5a.5.5 0 01-.5-.5V7a.5.5 0 00-.5-.5H3a.5.5 0 00-.5.5v3.542a.5.5 0 01-.417.493A2.5 2.5 0 000 13.5V19a1 1 0 001 1 .542.542 0 00.535-.458 3 3 0 015.935.03.5.5 0 00.494.428h8.572a.5.5 0 00.494-.428 3 3 0 015.935-.03.541.541 0 00.535.458.5.5 0 00.5-.5z\"/><circle cx=\"4.5\" cy=\"20\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});