define("ember-svg-jar/inlined/baby-care-clothes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baby-care-clothes</title><path d=\"M22.82 4.08l-1.059-1.06A11.972 11.972 0 0015.07 0a.749.749 0 00-.82.738 2.25 2.25 0 01-4.5 0A.749.749 0 008.93 0a11.972 11.972 0 00-6.691 3.02L1.18 4.08a1.748 1.748 0 000 2.468L2.6 7.977a1.781 1.781 0 002.258.172.249.249 0 01.395.2v12.9a2.75 2.75 0 005.5 0V18.5a1.25 1.25 0 012.5 0v2.75a2.75 2.75 0 005.5 0V8.353a.249.249 0 01.395-.2 1.781 1.781 0 002.252-.176l1.419-1.429a1.748 1.748 0 00.001-2.468zM12 13.767a1 1 0 111-1 1 1 0 01-1 1zm0-3.51a1 1 0 111-1 1 1 0 01-1 1zm0-3.51a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});