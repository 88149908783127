define("ember-svg-jar/inlined/multiple-actions-edit-2.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-edit-2</title><path d=\"M18.318 10.856a.251.251 0 00.041-.3c-.492-.872-1.558-1.3-3.888-2.157l-.486-.173a1.8 1.8 0 01-.055-1.172 4.644 4.644 0 001.216-3.568A3.22 3.22 0 0012 0a3.007 3.007 0 00-1.342.314.251.251 0 00-.1.364 5 5 0 01.842 2.808 6.271 6.271 0 01-.922 3.624.248.248 0 00-.029.215.254.254 0 00.15.157c1.918.716 3.3 1.327 3.9 2.537a8.6 8.6 0 01.749 3.3.25.25 0 00.427.172z\"/><path d=\"M13.5 14a.5.5 0 00.5-.5 7.541 7.541 0 00-.618-2.922c-.454-.908-1.9-1.441-3.911-2.179l-.486-.173a1.8 1.8 0 01-.055-1.172 4.644 4.644 0 001.216-3.568A3.22 3.22 0 007 0a3.22 3.22 0 00-3.146 3.486 4.607 4.607 0 001.193 3.538 1.877 1.877 0 010 1.185l-.515.19c-2.01.738-3.46 1.271-3.914 2.179A7.541 7.541 0 000 13.5a.5.5 0 00.5.5zM12.062 20.131a.251.251 0 00-.422.128l-.628 3.141a.5.5 0 00.49.6.466.466 0 00.1-.01l3.143-.629a.251.251 0 00.128-.422zM23.228 11.765a2.62 2.62 0 00-3.7 0l-6.678 6.679a.251.251 0 000 .354l3.35 3.353a.249.249 0 00.353 0l6.679-6.679a2.621 2.621 0 00-.004-3.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});