define("ember-svg-jar/inlined/image-file-landscape-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-landscape-alternate</title><path d=\"M21.707 5.707L16.293.293A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.465a.5.5 0 01.353.146l4.536 4.536a.5.5 0 01.146.353z\"/><circle cx=\"8.799\" cy=\"8\" r=\"2\"/><path d=\"M14.223 10.678a.5.5 0 00-.848 0l-2.426 3.882a.251.251 0 01-.424 0l-.864-1.382a.5.5 0 00-.848 0l-2.775 4.439A.25.25 0 006.25 18h12.1a.25.25 0 00.212-.383z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});