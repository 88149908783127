define("ember-svg-jar/inlined/multiple-neutral-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-neutral-1</title><path d=\"M18.779 14.694l-.739-.266c-.067-.025-.166-.142-.242-.4a3.358 3.358 0 01.081-1.95c.949-1.045 1.876-2.333 1.876-5.536A4.641 4.641 0 0015 1.5a5.042 5.042 0 00-1.167.141.25.25 0 00-.131.407 6.771 6.771 0 011.553 4.5c0 3.281-.925 4.972-1.991 6.231a.248.248 0 00-.058.137c0 .048-.008.1-.011.15a.25.25 0 00.166.246c2.408.858 6.139 2.2 6.139 7.191V22a1.576 1.576 0 01-.015.218.251.251 0 00.249.282H23.5a.5.5 0 00.5-.5v-1.5c0-3.948-2.773-4.935-5.221-5.806z\"/><path d=\"M18 20.5c0-3.948-2.773-4.935-5.221-5.806l-.739-.266c-.067-.025-.166-.142-.242-.4a3.358 3.358 0 01.081-1.95c.949-1.045 1.876-2.333 1.876-5.536A4.641 4.641 0 009 1.5a4.641 4.641 0 00-4.755 5.045c0 3.2.927 4.491 1.871 5.529a3.336 3.336 0 01.084 1.957c-.076.255-.175.372-.242.4-.252.093-.507.184-.762.274-2.436.862-5.2 1.84-5.2 5.8V22a.5.5 0 00.5.5h17A.5.5 0 0018 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});