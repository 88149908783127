define("ember-svg-jar/inlined/style-two-pin-world", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-two-pin-world</title><path d=\"M12 0a9.511 9.511 0 00-9.5 9.5c0 6.544 6.537 12.487 8.541 14.153a1.5 1.5 0 001.917 0c2-1.666 8.542-7.609 8.542-14.154A9.511 9.511 0 0012 0zm.19 15.318a.248.248 0 01-.19.088.252.252 0 01-.19-.087 6.063 6.063 0 01-1.157-2.248.249.249 0 01.239-.321h2.216a.249.249 0 01.2.1.253.253 0 01.04.221 6.048 6.048 0 01-1.158 2.247zm-1.648-4.068a.25.25 0 01-.249-.229c-.026-.321-.043-.658-.043-1.021s.017-.7.043-1.021a.249.249 0 01.249-.229h2.916a.25.25 0 01.249.229c.026.321.043.658.043 1.021s-.017.7-.043 1.021a.249.249 0 01-.249.229zM6 10a5.988 5.988 0 01.093-1.044.25.25 0 01.246-.206h2.2a.252.252 0 01.25.268c-.023.313-.038.637-.038.982s.015.669.038.982a.252.252 0 01-.25.268h-2.2a.25.25 0 01-.246-.206A5.988 5.988 0 016 10zm5.809-5.319A.252.252 0 0112 4.594a.249.249 0 01.19.087 6.03 6.03 0 011.158 2.248.249.249 0 01-.239.321h-2.217a.249.249 0 01-.2-.1.253.253 0 01-.04-.221 6.06 6.06 0 011.157-2.248zm3.4 4.337a.252.252 0 01.25-.268h2.2a.25.25 0 01.246.206 5.906 5.906 0 010 2.088.25.25 0 01-.246.206h-2.2a.252.252 0 01-.25-.268 13.289 13.289 0 00.003-1.964zm1.9-2.149a.249.249 0 01.006.253.252.252 0 01-.219.128h-1.727a.25.25 0 01-.243-.192 8.813 8.813 0 00-.826-2.195.25.25 0 01.321-.348 6.028 6.028 0 012.69 2.354zM9.575 4.515a.25.25 0 01.321.348 8.813 8.813 0 00-.822 2.195.251.251 0 01-.243.192H7.1a.25.25 0 01-.213-.381 6.028 6.028 0 012.688-2.354zm-2.687 8.616a.25.25 0 01.212-.381h1.731a.25.25 0 01.243.192 8.813 8.813 0 00.822 2.2.25.25 0 01-.321.348 6.036 6.036 0 01-2.687-2.359zm7.537 2.354a.25.25 0 01-.321-.348 8.813 8.813 0 00.822-2.2.251.251 0 01.243-.192H16.9a.251.251 0 01.214.381 6.036 6.036 0 01-2.689 2.359z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});