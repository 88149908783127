define("ember-svg-jar/inlined/baby-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baby-heart</title><path d=\"M8.635 16.112a.251.251 0 00-.235-.019l-2.463 1.059a.25.25 0 00.018.466l2.862.978a.25.25 0 00.314-.328 5.54 5.54 0 01-.382-1.949.251.251 0 00-.114-.207zM11.327 21.157a.991.991 0 00-.4-.255l-9.5-3.247a.395.395 0 00-.523.408 6.514 6.514 0 0010.84 4.27.5.5 0 00.165-.353.5.5 0 00-.139-.364zM2.862 16.561a1 1 0 00.718-.028l5.412-2.327a.5.5 0 00.257-.252 5.5 5.5 0 014.8-3.228.5.5 0 00.478-.457l.2-2.254a7.377 7.377 0 10-14.7 0L.678 15.5a.5.5 0 00.337.43zM7.374 3.25a4.25 4.25 0 11-4.25 4.25 4.254 4.254 0 014.25-4.25zM23.6 14.31a3.775 3.775 0 00-6.047-.981l-.3.3-.3-.3a3.775 3.775 0 00-5.353 5.328l4.752 4.958a1.249 1.249 0 001.8 0l4.743-4.947a3.773 3.773 0 00.705-4.358z\"/><circle cx=\"5.374\" cy=\"6.958\" r=\"1\"/><circle cx=\"9.374\" cy=\"6.958\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});