define("ember-svg-jar/inlined/multiple-neutral-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-neutral-2</title><path d=\"M19.932 16.6l-.564-.2a.589.589 0 01-.137-.308 2.565 2.565 0 01.058-1.3 5.618 5.618 0 001.465-4.312A3.661 3.661 0 0017 6.5a3.965 3.965 0 00-1.615.348.251.251 0 00-.148.221 8.69 8.69 0 01-1.69 5.349.252.252 0 00-.043.217 5.1 5.1 0 00.258.737.249.249 0 00.142.134c2.434.9 5.6 2.392 5.6 6.994V22a1.576 1.576 0 01-.015.218.251.251 0 00.249.282H23.5a.5.5 0 00.5-.5v-.853c0-3.101-2.161-3.871-4.068-4.547z\"/><path d=\"M18 20.5c0-3.948-2.773-4.935-5.221-5.806l-.739-.266c-.067-.025-.166-.142-.242-.4a3.358 3.358 0 01.081-1.95c.949-1.045 1.876-2.333 1.876-5.536A4.641 4.641 0 009 1.5a4.641 4.641 0 00-4.755 5.045c0 3.2.927 4.491 1.871 5.529a3.336 3.336 0 01.084 1.957c-.076.255-.175.372-.242.4-.252.093-.507.184-.762.274-2.436.862-5.2 1.84-5.2 5.8V22a.5.5 0 00.5.5h17A.5.5 0 0018 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});