define("ember-svg-jar/inlined/road-sign-side-road-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-side-road-right</title><path d=\"M23.78 11.47L12.53.22a.749.749 0 00-1.06 0L.22 11.47a.749.749 0 000 1.06l11.25 11.25a.748.748 0 001.06 0l11.25-11.25a.749.749 0 000-1.06zm-6.851 1.314l-.973 1.621a.5.5 0 01-.586.217l-2.291-.763a.25.25 0 00-.329.237v2.153a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2.32a.25.25 0 00.171.237l3.738 1.246a.5.5 0 01.27.732z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});