define("ember-svg-jar/inlined/police-woman-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-woman-4</title><path d=\"M22.533 19.918C21.106 18.828 17.839 17 12 17s-9.106 1.828-10.533 2.917A2.462 2.462 0 00.5 21.894V23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-1.606a2.459 2.459 0 00-.967-1.976zm-3.783 1.263a2.246 2.246 0 01-1 1.872l-.609.407a.256.256 0 01-.278 0l-.609-.407a2.247 2.247 0 01-1-1.872v-.929A.25.25 0 0115.5 20h3a.25.25 0 01.25.25zM18.872 5.58a.254.254 0 00.2-.126.925.925 0 00.071-.153l1-2.991a1 1 0 00-1.137-1.3c-2.3.454-4.67.674-6.417-.776a1 1 0 00-1.291 0c-1.747 1.45-4.115 1.231-6.416.776a1 1 0 00-1.138 1.3l1 2.991a.507.507 0 00.034.075.25.25 0 00.2.131 118.219 118.219 0 0013.894.073zm-7.929-2a1 1 0 111 1 1 1 0 01-1-1.004zM12.414 7.247c-2.767 0-5.484-.082-7.391-.232a.074.074 0 00-.08.074V9.5c0 .089.39 3.707-2.153 4.524a.5.5 0 00-.231.8c1.6 1.912 5.061.808 5.685.687a.246.246 0 01.174.03 6.936 6.936 0 007.07-.013.248.248 0 01.177-.029c.629.128 4.066 1.236 5.661-.675a.5.5 0 00-.231-.8c-2.541-.817-2.152-4.435-2.152-4.524V7.335a.25.25 0 00-.079-.182.247.247 0 00-.186-.068c-1.864.109-4.078.162-6.264.162zM6.943 9.5v-.3a.249.249 0 01.369-.219A9.912 9.912 0 0011.943 10a9.873 9.873 0 004.63-1.026.25.25 0 01.37.219V9.5a5 5 0 01-10 0z\"/><path d=\"M12.943 11.5h-2a.639.639 0 00-.308 1.2 2.416 2.416 0 00.438.183 3.089 3.089 0 00.87.127 3.028 3.028 0 00.867-.125 2.41 2.41 0 00.435-.181.639.639 0 00-.3-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});