define("ember-svg-jar/inlined/server-cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-cash</title><path d=\"M0 3.484a2.5 2.5 0 002.5 2.5h17a2.5 2.5 0 002.5-2.5v-1a2.5 2.5 0 00-2.5-2.5h-17a2.5 2.5 0 00-2.5 2.5zm3.635-.5A1.115 1.115 0 114.75 4.1a1.115 1.115 0 01-1.115-1.116zm3.865 0A1.115 1.115 0 118.615 4.1 1.114 1.114 0 017.5 2.984zM17.5 9.515a7.946 7.946 0 013.777.95.5.5 0 00.723-.443V9.49a2.5 2.5 0 00-2.5-2.506h-17a2.5 2.5 0 00-2.5 2.5v1a2.5 2.5 0 002.5 2.5h8.164a.49.49 0 00.395-.2A7.991 7.991 0 0117.5 9.515zm-7.771.469a1.115 1.115 0 11-1.114-1.115 1.114 1.114 0 011.114 1.115zm-3.864 0A1.115 1.115 0 114.75 8.869a1.115 1.115 0 011.115 1.115zM9.569 13.984H2.5a2.5 2.5 0 00-2.5 2.5v1a2.5 2.5 0 002.5 2.5h6.738a.493.493 0 00.485-.608 7.883 7.883 0 01.315-4.725.5.5 0 00-.469-.667zm-5.934 3A1.115 1.115 0 114.75 18.1a1.115 1.115 0 01-1.115-1.116z\"/><path d=\"M11 17.515a6.5 6.5 0 106.5-6.5 6.508 6.508 0 00-6.5 6.5zm5 3.75a.75.75 0 010-1.5h2.033a.592.592 0 00.22-1.141L16.19 17.8a2.084 2.084 0 01.36-3.981.249.249 0 00.2-.245v-.308a.75.75 0 011.5 0v.25a.25.25 0 00.25.25h.5a.75.75 0 010 1.5h-2.033a.592.592 0 00-.221 1.141l2.064.825a2.085 2.085 0 01-.36 3.982.249.249 0 00-.2.245v.307a.75.75 0 01-1.5 0v-.25a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});