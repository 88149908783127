define("ember-svg-jar/inlined/office-outdoors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-outdoors</title><path d=\"M23.75 22.934a.979.979 0 00-.979-.98h-2.689a.25.25 0 01-.25-.25v-4.955a.25.25 0 01.25-.25h1.153a2.627 2.627 0 002.515-2.663 2.51 2.51 0 00-1.214-2.146.252.252 0 01-.121-.215v-.755A4.638 4.638 0 0017.9 5.98a4.637 4.637 0 00-4.517 4.74 4.807 4.807 0 00.151 1.2.251.251 0 01-.075.248 2.854 2.854 0 00-.966 2.113 2.458 2.458 0 001.182 2.07.736.736 0 00.441.148h.256a.25.25 0 01.24.181 4.714 4.714 0 003.088 3.129.25.25 0 01.177.239V21.7a.25.25 0 01-.25.25h-6.356a.25.25 0 01-.25-.25V6.777a1.47 1.47 0 00-1.469-1.468H8.333a.25.25 0 01-.25-.25V3.35a.98.98 0 00-.983-.979h-.23a.249.249 0 01-.25-.253l.014-1.04a.98.98 0 00-.963-.992h-.013a.979.979 0 00-.979.967l-.013 1.071a.251.251 0 01-.25.247h-.249a.979.979 0 00-.979.979v1.709a.25.25 0 01-.25.25H1.719A1.47 1.47 0 00.25 6.777v16.646a.49.49 0 00.49.49h22.031a.979.979 0 00.979-.979zm-6.9-6.042a.25.25 0 01.2-.393h.573a.25.25 0 01.25.25v.588a.25.25 0 01-.4.2 2.831 2.831 0 01-.627-.645zm-8.522-2.118a.734.734 0 01-.734.734H3.677a.735.735 0 010-1.469h3.917a.735.735 0 01.734.735zm0 4.732a.735.735 0 01-.734.735H3.677a.735.735 0 010-1.469h3.917a.734.734 0 01.734.734zm-.734-8.894H3.677a.735.735 0 010-1.469h3.917a.735.735 0 010 1.469z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});