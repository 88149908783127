define("ember-svg-jar/inlined/kitchenware-pizza-cutter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchenware-pizza-cutter</title><path d=\"M5.925 12.226a5.85 5.85 0 105.849 5.849 5.856 5.856 0 00-5.849-5.849zm0 9.7a3.85 3.85 0 113.849-3.85 3.854 3.854 0 01-3.849 3.849z\"/><path d=\"M10.433 7.817a.5.5 0 01-.221.381l-1.524 1.02a.5.5 0 01-.39.071 8.445 8.445 0 00-4.707.27 1 1 0 10.666 1.886 6.507 6.507 0 018.537 4.829 1 1 0 00.979.8 1.158 1.158 0 00.2-.019 1 1 0 00.781-1.18 8.417 8.417 0 00-1.719-3.63.5.5 0 01-.111-.315V9.212a.5.5 0 01.091-.287c5.144-7.293 10.909-.9 10.909-6.369.001-5.874-13.111-.172-13.491 5.261z\"/><circle cx=\"5.925\" cy=\"18.075\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});