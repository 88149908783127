define("ember-svg-jar/inlined/smiley-head-sick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-head-sick</title><path d=\"M6.2 8.957a.25.25 0 00-.137-.457H.708a.251.251 0 00-.241.181A11.962 11.962 0 000 12q0 .35.02.7a.253.253 0 00.139.209.251.251 0 00.251-.017zM10.516 8.5a.254.254 0 00-.141.043L.579 15.19a.206.206 0 00-.082.23 12 12 0 0023.036-6.739.251.251 0 00-.241-.181zM17 9.5a2 2 0 11-2 2 2 2 0 012-2zm-9.838 8.454a5.773 5.773 0 019.676 0 1 1 0 01-1.676 1.092 3.772 3.772 0 00-6.324 0 1 1 0 11-1.676-1.092zM22.244 6.5a.25.25 0 00.216-.124.254.254 0 000-.249A12.094 12.094 0 0021.436 4.6a.248.248 0 00-.2-.1H2.76a.248.248 0 00-.2.1 12.094 12.094 0 00-1.022 1.527.25.25 0 00.218.373zM18.549 2.5a.25.25 0 00.14-.457 11.972 11.972 0 00-13.378 0 .25.25 0 00.14.457z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});