define("ember-svg-jar/inlined/farming-hay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>farming-hay</title><path d=\"M8.5 15.25h7a7 7 0 000-14h-7a7 7 0 000 14zm0-12a5 5 0 11-5 5 5.006 5.006 0 015-5z\"/><circle cx=\"8.5\" cy=\"8.25\" r=\"3\"/><path d=\"M23 20.75h-.66a.25.25 0 01-.237-.33 5.277 5.277 0 011.1-1.713 1 1 0 10-1.414-1.414 6.854 6.854 0 00-.594.691.25.25 0 01-.4 0 6.868 6.868 0 00-.594-.691 1 1 0 00-1.414 1.407 5.283 5.283 0 011.1 1.715.249.249 0 01-.237.33h-3.308a.249.249 0 01-.237-.33 5.277 5.277 0 011.1-1.713 1 1 0 00-1.414-1.414 7 7 0 00-.594.691.25.25 0 01-.4 0 6.868 6.868 0 00-.594-.691 1 1 0 00-1.415 1.412 5.3 5.3 0 011.1 1.715.25.25 0 01-.237.33h-3.31a.25.25 0 01-.237-.33 5.277 5.277 0 011.1-1.713 1 1 0 00-1.414-1.414 6.854 6.854 0 00-.594.691.252.252 0 01-.2.1.249.249 0 01-.2-.1 7.012 7.012 0 00-.594-.691A1 1 0 006.79 18.7a5.3 5.3 0 011.1 1.715.25.25 0 01-.237.33H4.344a.249.249 0 01-.236-.33 5.26 5.26 0 011.1-1.713 1 1 0 00-1.414-1.414 7 7 0 00-.594.691.25.25 0 01-.4 0 6.868 6.868 0 00-.594-.691A1 1 0 00.791 18.7a5.283 5.283 0 011.1 1.715.249.249 0 01-.236.33H1a1 1 0 000 2h22a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});