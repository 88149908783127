define("ember-svg-jar/inlined/location-target-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>location-target-1</title><path d=\"M24 12.25A1.25 1.25 0 0022.75 11h-.913a.5.5 0 01-.493-.412 9.516 9.516 0 00-7.919-7.73.5.5 0 01-.425-.494V1.25a1.25 1.25 0 00-2.5 0v1.214a.5.5 0 01-.4.489 9.51 9.51 0 00-7.444 7.634.5.5 0 01-.492.413H1.25a1.25 1.25 0 000 2.5h.914a.5.5 0 01.492.413 9.51 9.51 0 007.444 7.634.5.5 0 01.4.489v.714a1.25 1.25 0 002.5 0v-.614a.5.5 0 01.425-.494 9.515 9.515 0 007.919-7.729.5.5 0 01.492-.413h.914A1.25 1.25 0 0024 12.25zM12 19.5a7.25 7.25 0 117.25-7.25A7.258 7.258 0 0112 19.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});