define("ember-svg-jar/inlined/graphic-tablet-draw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>graphic-tablet-draw</title><path d=\"M22.073 7.549a.249.249 0 00-.073.177V19.5a1 1 0 01-1 1H3a1 1 0 01-1-1v-12a1 1 0 011-1h11.776a.251.251 0 00.177-.073l1.5-1.5a.25.25 0 00-.177-.427H3a3 3 0 00-3 3v12a3 3 0 003 3h18a3 3 0 003-3v-12a2.9 2.9 0 00-.242-1.152.251.251 0 00-.406-.078z\"/><path d=\"M16.75 16a.25.25 0 00-.25.25v2.25a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-2.25a.25.25 0 00-.25-.25zM16.666 12.956a.634.634 0 01-.069.059.253.253 0 00-.1.2v1.535a.25.25 0 00.25.25h3.5a.25.25 0 00.25-.25V12.5A.5.5 0 0020 12h-2.275a.247.247 0 00-.176.073zM23.586 1.915a1.412 1.412 0 00-2 0L13.605 9.9l-.684 2.051a.5.5 0 00.475.658.506.506 0 00.158-.025l2.051-.684 7.981-7.981a1.415 1.415 0 000-2.004zM7.5 10.25a4.25 4.25 0 104.25 4.25 4.255 4.255 0 00-4.25-4.25zm0 7a2.75 2.75 0 112.75-2.75 2.752 2.752 0 01-2.75 2.75z\"/><circle cx=\"7.5\" cy=\"14.5\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});