define("ember-svg-jar/inlined/single-woman-actions-lock.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-lock</title><path d=\"M14.353 14.228a.251.251 0 00.154-.217 4.4 4.4 0 01.216-1.109.249.249 0 00-.142-.308c-.894-.369-1.948-.746-3.107-1.171a.779.779 0 01-.513-.73v-.467a.25.25 0 01.25-.25A6.374 6.374 0 0015 9.081a.388.388 0 00.084-.593c-.494-.537-.835-1.185-.981-3.317C13.838 1.346 11.236 0 9 0S4.161 1.346 3.9 5.171c-.145 2.132-.487 2.78-.981 3.317A.388.388 0 003 9.081a6.348 6.348 0 003.782.9.249.249 0 01.253.25v.462a.778.778 0 01-.512.73c-2.9 1.065-5.212 1.825-5.764 2.922A7.822 7.822 0 000 17.076a.386.386 0 00.1.3.4.4 0 00.29.126h11.86a.25.25 0 00.25-.25V17a3.005 3.005 0 011.853-2.772zM22.714 15.515a.249.249 0 01-.214-.247V14.25a3.5 3.5 0 00-7 0v1.018a.249.249 0 01-.214.247A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.286-1.485zM17.5 14.25a1.5 1.5 0 013 0v1a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25zm2.5 6a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});