define("ember-svg-jar/inlined/earth-model-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earth-model-2</title><path d=\"M15.927 18.87a10.916 10.916 0 005.313-2.931 1 1 0 10-1.414-1.414A9 9 0 017.1 1.8 1 1 0 005.684.382a10.98 10.98 0 005.756 18.576.5.5 0 01.374.678L11.03 21.6a.5.5 0 01-.464.315H7.212a1 1 0 000 2h13a1 1 0 100-2h-3.354a.5.5 0 01-.464-.315l-.821-2.053a.5.5 0 01.354-.673z\"/><path d=\"M13.712 15.661a7.75 7.75 0 10-7.75-7.75 7.759 7.759 0 007.75 7.75zm-4.05-9.513l.623-.692a1.4 1.4 0 011.479-.392.931.931 0 00.986-.264l1.063-1.18 1.705-1.9a.5.5 0 01.546-.135 6.7 6.7 0 012.16 1.3c.12.107.233.219.341.333a.5.5 0 01.01.674L16.629 6.1a.929.929 0 00.07 1.314l.346.312a.931.931 0 01.07 1.315l-1.291 1.4a.93.93 0 01-1.315.07l-1.731-1.562-.346-.311-.017-.015a1.2 1.2 0 00-1.224-.233 1.4 1.4 0 01-1.529-2.242z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});