define("ember-svg-jar/inlined/shopping-cart-full", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-cart-full</title><path d=\"M22.974 2.125h-2.605a1.494 1.494 0 00-1.46 1.157l-1.094 4.651a.249.249 0 01-.243.192H1.089A1 1 0 00.14 9.442l2.668 8a1 1 0 00.948.683H15.1a.251.251 0 01.244.308l-.118.5a.249.249 0 01-.243.192H4.922a2.25 2.25 0 102.3 2.249.247.247 0 01.078-.174.25.25 0 01.177-.074h6a.25.25 0 01.25.25 2.25 2.25 0 103.569-1.82.251.251 0 01-.1-.26L20.72 4.318a.251.251 0 01.244-.193h2.01a1 1 0 000-2z\"/><path d=\"M9.8 7.125h5.29a1 1 0 00.8-1.6l-1.957-2.578a1.122 1.122 0 00-.1-.11 1.627 1.627 0 00-2.286.044L9.081 5.43a1 1 0 00.718 1.7zM1.4 6.535a1 1 0 00.912.59h4.313a1 1 0 00.913-1.408L5.566 1.3A1.58 1.58 0 004.554.442a1.777 1.777 0 00-1.24.1l-2.28 1.067a1.657 1.657 0 00-.87 2.159z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});