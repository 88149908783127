define("ember-svg-jar/inlined/office-file-ppt-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-file-ppt-1</title><path d=\"M8.5 12.127h-.623a.252.252 0 00-.252.252v1.246a.252.252 0 00.252.252H8.5a.875.875 0 000-1.75z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zM3.5 15.123h-.623a.252.252 0 00-.252.252V17.5a.625.625 0 01-1.25 0v-6A.625.625 0 012 10.873h1.5a2.125 2.125 0 010 4.25zm5 0h-.623a.252.252 0 00-.252.252V17.5a.625.625 0 01-1.25 0v-6A.625.625 0 017 10.873h1.5a2.125 2.125 0 010 4.25zm7.5-3h-1.126a.249.249 0 00-.249.249V17.5a.625.625 0 01-1.25 0v-5.128a.249.249 0 00-.249-.249H12a.625.625 0 010-1.25h4a.625.625 0 010 1.25z\"/><path d=\"M3.5 12.127h-.623a.252.252 0 00-.252.252v1.246a.252.252 0 00.252.252H3.5a.875.875 0 100-1.75z\"/><path d=\"M23.707 5.705L18.293.291A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.748V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.249A.249.249 0 016 21.749v-.5A.251.251 0 005.749 21H4.25a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.412a1 1 0 00-.293-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});