define("ember-svg-jar/inlined/aircraft-chopper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>aircraft-chopper</title><path d=\"M20.5 22h-.387a.251.251 0 01-.245-.2 5.9 5.9 0 00-1.068-2.344.251.251 0 010-.309 4.178 4.178 0 00.221-5.008.25.25 0 01-.035-.2.409.409 0 00.014-.092 6.662 6.662 0 01.108-.673c.264-1.415.757-4.048-.864-6a5.9 5.9 0 00-3.3-1.862.248.248 0 01-.192-.243v-.157a1.7 1.7 0 00-1.725-1.662.027.027 0 01-.027-.027V1a1 1 0 00-2 0v2.223a.027.027 0 01-.027.027A1.7 1.7 0 009.25 4.912v.157a.249.249 0 01-.192.243 5.9 5.9 0 00-3.3 1.862c-1.621 1.952-1.128 4.585-.864 6a6.662 6.662 0 01.106.673.426.426 0 00.013.094.244.244 0 01-.036.2 4.18 4.18 0 00.223 5.006.251.251 0 010 .309A5.9 5.9 0 004.132 21.8a.251.251 0 01-.245.2H3.5a1 1 0 000 2h3a1 1 0 000-2 .251.251 0 01-.234-.338 3.814 3.814 0 01.603-1.062.249.249 0 01.276-.079 4.168 4.168 0 001.355.229h7a4.142 4.142 0 001.355-.234.25.25 0 01.276.08 3.814 3.814 0 01.608 1.066.25.25 0 01-.239.338 1 1 0 000 2h3a1 1 0 000-2zM7.75 18.25a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM11 12a.25.25 0 01-.25.25H8.5a4.216 4.216 0 00-1.4.241.254.254 0 01-.21-.02.251.251 0 01-.119-.174c-.2-1.17-.358-2.778.528-3.845a4.91 4.91 0 013.427-1.392.25.25 0 01.274.249zm2 0V7.309a.25.25 0 01.274-.249A4.91 4.91 0 0116.7 8.452c.886 1.067.728 2.675.528 3.845a.247.247 0 01-.119.173.25.25 0 01-.21.021 4.216 4.216 0 00-1.4-.241H13.25A.25.25 0 0113 12zm3.25 6.25a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM23 1h-7.5a1 1 0 000 2H23a1 1 0 000-2z\"/><path d=\"M9.5 2a1 1 0 00-1-1H1a1 1 0 000 2h7.5a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});