define("ember-svg-jar/inlined/design-tool-compass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-tool-compass</title><rect y=\"8\" width=\"5\" height=\"3.5\" rx=\".25\" ry=\".25\"/><path d=\"M.25 17.5a.25.25 0 00-.25.25v1.74a.5.5 0 00.053.225l1.776 3.553a.719.719 0 001.342 0l1.776-3.553A.5.5 0 005 19.49v-1.74a.25.25 0 00-.25-.25zM4.75 6.5A.25.25 0 005 6.25v-.729a2.5 2.5 0 00-5 0v.729a.25.25 0 00.25.25z\"/><path d=\"M23.547 23.837a1 1 0 00.29-1.385l-1.282-1.958a.251.251 0 01-.018-.242 1.468 1.468 0 00-.083-1.427l-7.281-11.59A.5.5 0 0014.75 7h-6a.5.5 0 00-.469.326l-1.874 5.063a1.052 1.052 0 01-.878.611H.25a.25.25 0 00-.25.25v2.5a.25.25 0 00.25.25h7.5a2.057 2.057 0 001.819-1.261l2.111-5.627a.25.25 0 01.447-.044l7.179 11.681a1.514 1.514 0 001.347.716.252.252 0 01.221.113l1.289 1.97a1 1 0 001.384.289zM8.75 5.5h6a.5.5 0 00.5-.5V3.5a3.5 3.5 0 00-7 0V5a.5.5 0 00.5.5zm3-4a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});