define("ember-svg-jar/inlined/billboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>billboard</title><path d=\"M17 22a1 1 0 001-1v-3.75a.25.25 0 01.25-.25h4.25a1 1 0 001-1V3a1 1 0 00-1-1H20a.25.25 0 00-.25.25V3.5a.25.25 0 00.25.25h1a.75.75 0 010 1.5h-4a.75.75 0 010-1.5h1a.25.25 0 00.25-.25V2.25A.25.25 0 0018 2h-5a.25.25 0 00-.25.25V3.5a.25.25 0 00.25.25h1a.75.75 0 010 1.5h-4a.75.75 0 010-1.5h1a.25.25 0 00.25-.25V2.25A.25.25 0 0011 2H6a.25.25 0 00-.25.25V3.5a.25.25 0 00.25.25h1a.75.75 0 010 1.5H3a.75.75 0 010-1.5h1a.25.25 0 00.25-.25V2.25A.25.25 0 004 2H1.5a1 1 0 00-1 1v13a1 1 0 001 1h4.25a.25.25 0 01.25.25V21a1 1 0 002 0v-3.75a.25.25 0 01.25-.25h7.5a.25.25 0 01.25.25V21a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});