define("ember-svg-jar/inlined/video-game-mario-tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-mario-tree</title><path d=\"M13 6.75a.75.75 0 00-1.5 0v2a.75.75 0 001.5 0zM14.75 6a.75.75 0 00-.75.75v2a.75.75 0 001.5 0v-2a.75.75 0 00-.75-.75zM14.75 13.25h-3a.5.5 0 00-.5.5 2 2 0 004 0 .5.5 0 00-.5-.5zM9 13.5a1 1 0 00-1 1v5A2.5 2.5 0 015.5 22H3a1 1 0 000 2h2.5a4.505 4.505 0 004.5-4.5v-5a1 1 0 00-1-1z\"/><path d=\"M19.5 8A4.505 4.505 0 0024 3.5V1a1 1 0 00-1-1H1a1 1 0 00-1 1v.5A4.505 4.505 0 004.5 6a1.061 1.061 0 01.788.351 4.974 4.974 0 002.348 1.461.5.5 0 01.364.481V9.5a.5.5 0 01-.5.5h-.75a1 1 0 000 2h4.5a1 1 0 000-2h-.75a.5.5 0 01-.5-.5v-2a3.5 3.5 0 017 0v12a4.505 4.505 0 004.5 4.5H23a1 1 0 000-2h-1.5a2.5 2.5 0 01-2.5-2.5v-11a.5.5 0 01.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});