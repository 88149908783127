define("ember-svg-jar/inlined/pool-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pool-table</title><path d=\"M13.754 10.344a1 1 0 00-1.508 1.312l10 11.5a1 1 0 001.508-1.312z\"/><path d=\"M23.249 13.7a.5.5 0 01-.249-.432V3.732a.5.5 0 01.249-.433A1.5 1.5 0 1021.2 1.251a.5.5 0 01-.433.249h-7.035a.5.5 0 01-.433-.249 1.5 1.5 0 00-2.6 0 .5.5 0 01-.433.249H3.232a.5.5 0 01-.432-.249A1.5 1.5 0 10.751 3.3.5.5 0 011 3.732v9.536a.5.5 0 01-.249.433A1.5 1.5 0 102.8 15.749a.5.5 0 01.433-.249h7.036a.5.5 0 01.433.249A1.5 1.5 0 0012 16.5a1.467 1.467 0 001.346-.86.5.5 0 00-.086-.513l-2.152-2.493a2.5 2.5 0 113.784-3.268l4.92 5.7a1.263 1.263 0 00.956.438.5.5 0 01.433.25 1.5 1.5 0 102.048-2.054zM4 7.5a1 1 0 111-1 1 1 0 01-1 1zm2.5 3a1 1 0 111-1 1 1 0 01-1 1zm1-3a1 1 0 111-1 1 1 0 01-1 1zM18 8a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});