define("ember-svg-jar/inlined/color-bucket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-bucket</title><path d=\"M22.382 17.9a1.083 1.083 0 00-1.764 0A8.616 8.616 0 0019 21.472a2.5 2.5 0 005 0 8.6 8.6 0 00-1.618-3.572zM14.924 7.1a18.912 18.912 0 006.688 4.488.251.251 0 00.307-.329c-.858-2.251-4.353-5.8-4.855-6.3-.525-.526-4.395-4.34-6.641-4.927a.25.25 0 00-.308.3c.296 1.325 2.336 4.298 4.809 6.768z\"/><path d=\"M13.864 8.164a24.375 24.375 0 01-5-6.852.25.25 0 00-.434-.054L.3 12.412c-1.193 1.193 1.416 4.439 3.145 6.169 1.856 1.856 4.1 3.456 5.4 3.456a.958.958 0 00.714-.262l11.486-8.381a.25.25 0 00-.07-.439 20.932 20.932 0 01-7.111-4.791z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});