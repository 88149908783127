define("ember-svg-jar/inlined/equipment-hammer-stone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>equipment-hammer-stone</title><path d=\"M7.5 17v-6a.5.5 0 00-.5-.5H1.5a1.5 1.5 0 00-1.47 1.8 6.516 6.516 0 006.37 5.2H7a.5.5 0 00.5-.5zM12 6a.5.5 0 01.5.5v1A1.5 1.5 0 0014 9h4a1.5 1.5 0 001.5-1.5v-6A1.5 1.5 0 0018 0h-4a1.5 1.5 0 00-1.5 1.5v1a.5.5 0 01-.5.5H1.5a1.5 1.5 0 000 3zM20.5 17.5h2A1.5 1.5 0 0024 16v-4a1.5 1.5 0 00-1.5-1.5h-13a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h1A1.5 1.5 0 0112 19c0 1.034-.737 1.282-2.04 1.631C8.641 20.983 7 21.423 7 23.5a.5.5 0 00.5.5h16a.5.5 0 00.5-.5c0-2.05-1.635-2.5-2.948-2.854S19 20.035 19 19a1.5 1.5 0 011.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});