define("ember-svg-jar/inlined/construction-fence", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>construction-fence</title><path d=\"M23 11.5a1 1 0 000-2h-1v-4h1a1 1 0 000-2h-1V1a1 1 0 00-2 0v2.5h-3.5V1a1 1 0 00-2 0v2.5H11V1a1 1 0 00-2 0v2.5H5.5V3a1 1 0 00-1-1H1a1 1 0 00-1 1v20a1 1 0 001 1h22a1 1 0 001-1v-7a1 1 0 00-1-1h-1v-3.5zm-3.25-6a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25v-3.5a.25.25 0 01.25-.25zm-5.5 0a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25v-3.5a.25.25 0 01.25-.25zM6 15a.5.5 0 01-.5-.5v-2.75a.25.25 0 01.25-.25h3a.25.25 0 01.25.25v3a.25.25 0 01-.25.25zm5.25 0a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25h3a.25.25 0 01.25.25v3a.25.25 0 01-.25.25zm-2.5-9.5a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25v-3.5a.25.25 0 01.25-.25zM20 14.75a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25h3a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});