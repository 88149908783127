define("ember-svg-jar/inlined/js", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>js</title><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zm-11.375 9a2.125 2.125 0 01-4.25 0 .625.625 0 111.25 0 .875.875 0 001.75 0V9a.625.625 0 111.25 0zm2.3-2.633l1.84 1.226a1.928 1.928 0 01-1.07 3.532H12.5a.625.625 0 010-1.25h1.7a.678.678 0 00.376-1.242l-1.84-1.226A1.928 1.928 0 0113.8 8.376h1.7a.625.625 0 010 1.25h-1.7a.678.678 0 00-.376 1.242z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});