define("ember-svg-jar/inlined/quill-write-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>quill-write-1</title><path d=\"M12.138 15.4l-4.206 4.2a2.612 2.612 0 01-3.23.433 2.515 2.515 0 01-.402-3.861l4.3-4.308A15.869 15.869 0 0111 5.321a.245.245 0 00-.2-.382H1.562a1.5 1.5 0 00-1.5 1.5v16a1.5 1.5 0 001.5 1.5h16a1.5 1.5 0 001.5-1.5v-9.233a.245.245 0 00-.382-.2 15.873 15.873 0 01-6.542 2.394z\"/><path d=\"M23.42.061a14.744 14.744 0 00-6.31 1.678.257.257 0 00-.134.261l.324 2.453a.5.5 0 01-.774.482L14.657 3.68a.246.246 0 00-.316.031c-.357.365-1.265 1.3-1.327 1.373a13.355 13.355 0 00-1.666 2.676 14.279 14.279 0 00-.872 2.38q-.164.6-.276 1.22c-.036.2-.069.4-.1.6-.015.11 0 .525-.072.6l-4.673 4.672a1 1 0 000 1.414 1.012 1.012 0 001.414 0l4.676-4.676a14 14 0 008.508-3.912A14.137 14.137 0 0023.938.579a.5.5 0 00-.146-.372.493.493 0 00-.372-.146z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});