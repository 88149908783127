define("ember-svg-jar/inlined/professions-woman-office-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-woman-office-1</title><path d=\"M5.481 21.671A.5.5 0 015.319 21l1.552-2.637a.251.251 0 00-.3-.364L2.92 19.221A4.568 4.568 0 000 23.5a.5.5 0 00.5.5h7.783a.25.25 0 00.134-.461zM21.08 19.221l-.017-.006-3.639-1.221a.251.251 0 00-.295.364L18.681 21a.5.5 0 01-.162.676l-2.936 1.868a.25.25 0 00.134.461H23.5a.5.5 0 00.5-.5 4.568 4.568 0 00-2.92-4.284z\"/><path d=\"M17.452 20.878l-1.733-2.951a.25.25 0 00-.205-.124.253.253 0 00-.215.106l-3.094 4.412a.251.251 0 01-.41 0L8.7 17.908a.253.253 0 00-.215-.106.25.25 0 00-.205.124l-1.732 2.952a.25.25 0 00.081.337l4.314 2.746a.255.255 0 00.134.039h1.846a.255.255 0 00.134-.039l4.314-2.746a.25.25 0 00.081-.337zM6.938 16.66a2.076 2.076 0 001.216-.984A6.577 6.577 0 0011.98 17.5a6.586 6.586 0 003.852-1.849 2.081 2.081 0 001.23 1.009 4.753 4.753 0 004.362-1.56.506.506 0 00.1-.46.5.5 0 00-.328-.337c-1.281-.411-1.932-1.869-2.265-3.156a3.134 3.134 0 00.3-1.255 2.118 2.118 0 00-.461-1.443c.386-2.766-.043-4.9-1.279-6.361C16.317.7 14.464 0 11.981 0S7.646.7 6.473 2.085C5.237 3.543 4.808 5.68 5.194 8.446a2.127 2.127 0 00-.463 1.443 3.154 3.154 0 00.327 1.31c-.337 1.273-.989 2.7-2.251 3.1a.5.5 0 00-.328.337.506.506 0 00.1.46 4.75 4.75 0 004.359 1.564zm-.2-8.033a13.1 13.1 0 01-.171-1.608.249.249 0 01.249-.26 6.2 6.2 0 004.956-2 .253.253 0 01.186-.082.25.25 0 01.186.083 6.272 6.272 0 005 2 .25.25 0 01.249.261 13.149 13.149 0 01-.171 1.6.749.749 0 00.4.793.635.635 0 01.1.441 1.276 1.276 0 01-.557 1.095 1.749 1.749 0 00-1 1.3C15.8 14.51 13.009 16 11.98 16s-3.816-1.49-4.19-3.738a1.749 1.749 0 00-1-1.306 1.282 1.282 0 01-.557-1.1.619.619 0 01.105-.439.752.752 0 00.402-.79z\"/><path d=\"M10.107 12.878a.751.751 0 00.3 1.017 3.431 3.431 0 003.062 0 .75.75 0 00-.715-1.318 1.973 1.973 0 01-1.632 0 .75.75 0 00-1.015.301zM9.4 9.829a.632.632 0 01.627 0 .75.75 0 101-1.115 2.1 2.1 0 00-2.634 0 .75.75 0 101 1.115zM13.962 9.829a.632.632 0 01.627 0 .75.75 0 001-1.115 2.1 2.1 0 00-2.635 0 .75.75 0 101 1.115z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});