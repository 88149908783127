define("ember-svg-jar/inlined/bear-body-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bear-body-1</title><path d=\"M23.741 8.189L20.85 7.08a2.087 2.087 0 00-1.074-.3h-5.161c-2.087 0-3.653-.9-6.783-2.463S.006 3.8.006 9.015A40.9 40.9 0 001.979 20a.5.5 0 00.476.348h1.856a.5.5 0 00.485-.379l.856-3.422a.5.5 0 01.471-.378 10.308 10.308 0 003.627-.7.5.5 0 01.691.462v3.913a.5.5 0 00.5.5H13.6a.5.5 0 00.5-.481C14.286 14.939 17.272 12 18.789 12h2.087c1.7 0 3.06-2.438 3.117-3.33a.521.521 0 00-.252-.481zM19.5 10a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});