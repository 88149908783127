define("ember-svg-jar/inlined/safety-vest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-vest</title><path d=\"M21.5 20.5a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h2a.5.5 0 00.5-.5V17a.5.5 0 00-.5-.5h-2a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h2a.5.5 0 00.5-.5V12a1.5 1.5 0 00-1.207-1.47C20.731 10.12 20 7.885 20 6.1V2.5A2.5 2.5 0 0017.5 0H16a.5.5 0 00-.5.5V6a3.5 3.5 0 01-7 0V.5A.5.5 0 008 0H6.5A2.5 2.5 0 004 2.5v3.6c0 1.786-.732 4.018-2.795 4.433A1.5 1.5 0 000 12v2.5a.5.5 0 00.5.5h2a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-2a.5.5 0 00-.5.5v1.5a.5.5 0 00.5.5h2a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-2a.5.5 0 00-.5.5v1.5A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5V21a.5.5 0 00-.5-.5zm-15 .5a1 1 0 01-2 0v-6.5a1 1 0 012 0zm9.5-1a.5.5 0 01-.5.5h-7A.5.5 0 018 20v-.5a.5.5 0 01.5-.5h7a.5.5 0 01.5.5zm0-4a.5.5 0 01-.5.5h-7A.5.5 0 018 16v-.5a.5.5 0 01.5-.5h7a.5.5 0 01.5.5zm3.5 5a1 1 0 01-2 0v-6.5a1 1 0 012 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});