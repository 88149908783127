define("ember-svg-jar/inlined/smiley-blessed-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-blessed-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M16.561 14.5H7.438a.432.432 0 00-.379.25.557.557 0 000 .5A5.62 5.62 0 0012 18.5a5.622 5.622 0 004.941-3.25.557.557 0 000-.5.431.431 0 00-.38-.25zM10.916 8.9a1 1 0 00-1.832-.8 1.5 1.5 0 01-2.751 0 1 1 0 00-1.833.8 3.5 3.5 0 006.415 0zM18.983 7.584a1 1 0 00-1.316.516 1.5 1.5 0 01-2.751 0 1 1 0 10-1.832.8 3.5 3.5 0 006.415 0 1 1 0 00-.516-1.316z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});