define("ember-svg-jar/inlined/align-top-bottom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>align-top-bottom</title><path d=\"M18.5 18h-13a1 1 0 00-1 1v3a1 1 0 001 1h13a1 1 0 001-1v-3a1 1 0 00-1-1zm-3.75 2.5a.75.75 0 01-.75.75h-4a.75.75 0 010-1.5h4a.75.75 0 01.75.75zM7.5 6h9a1 1 0 001-1V2a1 1 0 00-1-1h-9a1 1 0 00-1 1v3a1 1 0 001 1zm1.75-2.5a.75.75 0 01.75-.75h4a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.75zM13.293 12.793L12 14.086l-1.293-1.293a1.025 1.025 0 00-1.414 0 1 1 0 000 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 000-1.414 1.025 1.025 0 00-1.414 0zM10.707 11.207L12 9.914l1.293 1.293a1 1 0 001.414-1.414l-2-2a1.025 1.025 0 00-1.414 0l-2 2a1 1 0 000 1.414 1.025 1.025 0 001.414 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});