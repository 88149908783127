define("ember-svg-jar/inlined/modern-weapon-tank", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-weapon-tank</title><path d=\"M23.616 2.993a.99.99 0 00-.858-.182l-2 .5a1 1 0 00-.521.331h-6.666a.25.25 0 01-.24-.179l-.131-.438A1.5 1.5 0 0011.779 2H6a1.5 1.5 0 00-1.428 1.042L3.16 7.821A.25.25 0 012.92 8H2a1 1 0 00-1 1v3.549a.5.5 0 00.709.451A5.453 5.453 0 014 12.5h15a5.452 5.452 0 012.939.858.5.5 0 00.716-.2l.2-.4a1.008 1.008 0 00-.255-1.312l-4.088-2.9A2.511 2.511 0 0016.952 8h-2.094a.25.25 0 01-.239-.179l-.55-1.86a.253.253 0 01.04-.221.249.249 0 01.2-.1h5.93a.991.991 0 00.518.328l2 .5A1 1 0 0023 6.5a1.01 1.01 0 00.617-.211A.993.993 0 0024 5.5V3.781a.992.992 0 00-.384-.788zM19 14H4a4 4 0 000 8h15a4 4 0 000-8zM5 20a2 2 0 112-2 2 2 0 01-2 2zm6.5 0a2 2 0 112-2 2 2 0 01-2 2zm6.5 0a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});