define("ember-svg-jar/inlined/road-sign-left-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-left-arrow</title><path d=\"M4.33 12.346a.5.5 0 010-.692L15.074.423A.25.25 0 0014.893 0H4a.75.75 0 00-.75.75v22.5A.75.75 0 004 24h10.893a.25.25 0 00.181-.423z\"/><path d=\"M20 0a.136.136 0 00-.1.041L8.8 11.654a.5.5 0 000 .692l11.1 11.613A.134.134 0 0020 24a.75.75 0 00.75-.75V.75A.75.75 0 0020 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});