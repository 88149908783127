define("ember-svg-jar/inlined/shipment-weight-kg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-weight-kg</title><circle cx=\"12\" cy=\"3\" r=\"3\"/><path d=\"M23.978 20.352L20.4 8.815A2.511 2.511 0 0017.99 7H6.008a2.507 2.507 0 00-2.4 1.8L.022 20.352A.53.53 0 000 20.5v2A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-2a.53.53 0 00-.022-.148zM8.237 16.177l3.293 3.293a.75.75 0 11-1.06 1.06l-2.293-2.293a.25.25 0 00-.427.177V20a.75.75 0 01-1.5 0v-8a.75.75 0 011.5 0v1.586a.251.251 0 00.427.177l2.293-2.293a.75.75 0 011.06 1.06l-3.293 3.293a.252.252 0 000 .354zM16 19.25A1.252 1.252 0 0017.25 18a.25.25 0 00-.25-.25h-.5a.75.75 0 010-1.5H18a.75.75 0 01.75.75v1a2.75 2.75 0 01-5.5 0v-4a2.75 2.75 0 015.5 0 .75.75 0 01-1.5 0 1.25 1.25 0 00-2.5 0v4A1.252 1.252 0 0016 19.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});