define("ember-svg-jar/inlined/renewable-energy-power-socket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>renewable-energy-power-socket</title><path d=\"M23.5 17.25H.5a.5.5 0 00-.5.5v3a1.5 1.5 0 001.5 1.5h4.25a1 1 0 00.8-.4l1.2-1.6h8.5l1.2 1.6a1 1 0 00.8.4h4.25a1.5 1.5 0 001.5-1.5v-3a.5.5 0 00-.5-.5z\"/><circle cx=\"7.5\" cy=\"10.75\" r=\"1.5\"/><path d=\"M13.5 8.5a.75.75 0 00-.75.75v1.25a.75.75 0 001.5 0V9.25a.75.75 0 00-.75-.75zM17 8.5a.75.75 0 00-.75.75v1.25a.75.75 0 001.5 0V9.25A.75.75 0 0017 8.5z\"/><circle cx=\"15.25\" cy=\"12\" r=\".75\"/><path d=\"M22.5 1.75h-2a.5.5 0 00-.476.346l-.607 1.875a1.746 1.746 0 01-1.456.779H6.035a1.746 1.746 0 01-1.456-.779l-.091-.19L3.956 2.1a.5.5 0 00-.477-.349H1.5A1.5 1.5 0 000 3.25v12a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-12a1.5 1.5 0 00-1.5-1.5zm-3 11a1 1 0 01-1 1h-13a1 1 0 01-1-1v-3.5a2 2 0 012-2h11a2 2 0 012 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});