define("ember-svg-jar/inlined/keyboard-asterisk-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-asterisk-2</title><path d=\"M23.5 10.5a1 1 0 00-1-1h-5.737a.25.25 0 01-.216-.375l2.868-4.968a1 1 0 00-.366-1.366l-2.6-1.5a1 1 0 00-1.366.366l-2.866 4.965a.251.251 0 01-.434 0L8.915 1.654a1 1 0 00-1.366-.366l-2.6 1.5a1 1 0 00-.366 1.366l2.87 4.968a.25.25 0 01-.216.375H1.5a1 1 0 00-1 1v3a1 1 0 001 1h5.737a.25.25 0 01.216.375L4.585 19.84a1 1 0 00.366 1.366l2.6 1.5a1 1 0 001.366-.366l2.868-4.968a.251.251 0 01.434 0l2.868 4.968a1 1 0 001.366.366l2.6-1.5a1 1 0 00.366-1.366l-2.868-4.968a.25.25 0 01.216-.375H22.5a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});