define("ember-svg-jar/inlined/electricity-tower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>electricity-tower</title><path d=\"M5.5 0a1 1 0 00-.925.619l-3.5 8.5A1 1 0 002 10.5h5.124a.25.25 0 01.235.335l-4.3 11.82a1.006 1.006 0 001.544 1.138L12.1 18.1a.249.249 0 01.3 0l7.5 5.7a1.007 1.007 0 001.545-1.135l-4.262-11.83a.25.25 0 01.235-.335H22a1 1 0 00.925-1.381l-3.5-8.5A1 1 0 0018.5 0zm8.378 5.555a.249.249 0 01.006-.335l.684-.73a.25.25 0 01.432.171v1.512a.25.25 0 01-.438.164zm-.445 2.53a.25.25 0 01-.188.415h-1.86a.25.25 0 01-.182-.421l.962-1.026a.249.249 0 01.37.007zm-.839-4.414a.251.251 0 01-.371-.006l-1.093-1.25A.25.25 0 0111.318 2h2.265a.25.25 0 01.183.421zM9.5 4.256a.25.25 0 01.438-.165l.943 1.078a.25.25 0 01-.006.335L9.932 6.51a.25.25 0 01-.432-.171zm6.2 8.373a.249.249 0 01-.084.283L12.4 15.358a.249.249 0 01-.3 0l-3.19-2.423a.248.248 0 01-.083-.284l.673-2.027a.123.123 0 01.124-.124h5.151a.249.249 0 01.245.2zM6.106 2.155A.25.25 0 016.337 2h.913a.25.25 0 01.25.25v6a.25.25 0 01-.25.25H3.866a.251.251 0 01-.231-.345zm.672 17.477a.25.25 0 01-.386-.284l1.54-4.236A.25.25 0 018.318 15l2.017 1.533a.251.251 0 010 .4zm9.422-4.651a.25.25 0 01.387.114l1.536 4.269a.25.25 0 01-.386.283l-3.577-2.718a.251.251 0 010-.4zM17.25 8.5a.25.25 0 01-.25-.25v-6a.25.25 0 01.25-.25h.413a.25.25 0 01.231.155l2.471 6a.251.251 0 01-.231.345z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});