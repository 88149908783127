define("ember-svg-jar/inlined/module-phone-puzzle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>module-phone-puzzle</title><path d=\"M14.74 3a3 3 0 00-3-3h-8a3 3 0 00-3 3v18a3 3 0 003 3h8a2.922 2.922 0 00.926-.15.5.5 0 00.2-.828l-3.879-3.875A.5.5 0 008.633 19H3.24a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5h9a.5.5 0 01.5.5V8a1 1 0 002 0z\"/><path d=\"M22.967 16.864a1 1 0 000-1.414l-1.328-1.329a.25.25 0 01.069-.4 1.966 1.966 0 00.552-.391 2 2 0 10-3.219-2.276.251.251 0 01-.183.139.247.247 0 01-.219-.07l-1.328-1.33a1 1 0 00-1.415 0l-1.449 1.449a.5.5 0 00.077.771c.02.013.3.238.312.255a2 2 0 11-3.083 2.517.507.507 0 00-.768-.079l-1.45 1.449a1 1 0 000 1.414l5.657 5.657a1 1 0 001.415 0l1.561-1.562a.5.5 0 00-.171-.819 2.006 2.006 0 01-1.123-1.109 2 2 0 013.268-2.167 2 2 0 01.448.683.5.5 0 00.819.171z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});