define("ember-svg-jar/inlined/escalator-ascend-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>escalator-ascend-person</title><path d=\"M10.5 9a1.5 1.5 0 00-3 0v3.729a.25.25 0 00.425.178l2.5-2.447a.251.251 0 00.075-.179z\"/><circle cx=\"9\" cy=\"3.5\" r=\"2.5\"/><path d=\"M24 10a3 3 0 00-3-3h-3.676a3 3 0 00-2.174.933l-8.824 8.638a1.49 1.49 0 01-1.05.429H3a3 3 0 000 6h4.176a3.017 3.017 0 002.181-.939l8.817-8.632a1.49 1.49 0 011.05-.429H21a3 3 0 003-3zM24 16a.75.75 0 00-.75-.75h-3.5a.75.75 0 00-.53 1.28l.866.866a.25.25 0 010 .354l-3.543 3.543a1 1 0 001.414 1.414l3.543-3.543a.25.25 0 01.354 0l.866.866A.75.75 0 0024 19.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});