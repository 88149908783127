define("ember-svg-jar/inlined/safety-fire-exit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-fire-exit</title><path d=\"M20.538.566a.734.734 0 00-.738.261.756.756 0 00-.09.808 5.82 5.82 0 01.565 2.2v.3a5.809 5.809 0 01-.216 1.573.25.25 0 01-.464.045 5.694 5.694 0 01-.271-.625.762.762 0 00-.541-.482.729.729 0 00-.674.181 6.756 6.756 0 004.64 11.651 6.585 6.585 0 00.81-.053.5.5 0 00.44-.5V2.492a.5.5 0 00-.2-.4A8.525 8.525 0 0020.538.566zM23 15.238a.249.249 0 01-.243.25 2.112 2.112 0 01-2.139-2.208 4.425 4.425 0 011.306-3.08.25.25 0 01.426.2 3.019 3.019 0 00.4 1.811.508.508 0 00.141.14.252.252 0 01.112.209z\"/><circle cx=\"4.5\" cy=\"5.453\" r=\"2.5\"/><path d=\"M14.451 11.124a5.524 5.524 0 00-4.716-2.671H9.34a5.492 5.492 0 00-4.177 1.921l-.6.707a2.5 2.5 0 01-1.9.872H1.5a1.5 1.5 0 000 3h1.16a5.492 5.492 0 004.177-1.921l.179-.21a.253.253 0 01.209-.087.249.249 0 01.193.117l.236.377a2.486 2.486 0 01-.239 2.971l-.555.634a5.5 5.5 0 00-1.36 3.622v1.5a1.5 1.5 0 003 0v-1.5a2.476 2.476 0 01.439-1.4.25.25 0 01.3-.091l2.205.882a1.5 1.5 0 101.114-2.785l-1.612-.644a.252.252 0 01-.147-.3 5.4 5.4 0 00-.384-4.086.25.25 0 01.32-.345 2.443 2.443 0 011.145.988l.335.557a1.5 1.5 0 102.572-1.545z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});