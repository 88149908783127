define("ember-svg-jar/inlined/envelope-letter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>envelope-letter</title><path d=\"M22 4H2a2 2 0 00-2 2v12a2 2 0 002 2h20a2 2 0 002-2V6a2 2 0 00-2-2zm-1 3.5v4a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h4a.5.5 0 01.5.5zM4.5 13.745h8a.755.755 0 010 1.51h-8a.755.755 0 010-1.51zM3.745 11a.755.755 0 01.755-.755h2a.755.755 0 010 1.51h-2A.755.755 0 013.745 11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});