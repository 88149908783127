define("ember-svg-jar/inlined/car-repair-tow-truck", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-repair-tow-truck</title><path d=\"M22.423 15.782H10.392a.742.742 0 01-.742-.742V8.606a1.486 1.486 0 00-1.485-1.484H5.483a1.505 1.505 0 00-1.312.807l-2.422 4.887a.246.246 0 01-.076.09l-1.22.894a1.2 1.2 0 00-.453.952v4.741a1.238 1.238 0 001.237 1.237H1.5a.247.247 0 00.247-.272 2.036 2.036 0 01-.012-.222 2.969 2.969 0 115.938 0 2.036 2.036 0 01-.012.222.247.247 0 00.247.272h7.448a.247.247 0 00.247-.272 2.036 2.036 0 01-.012-.222 2.969 2.969 0 115.938 0 2.036 2.036 0 01-.012.222.247.247 0 00.247.272h1.246a.743.743 0 00.743-.742v-2.969a1.288 1.288 0 00-1.33-1.237zM7.67 11.684a.247.247 0 01-.247.248H4.794a.247.247 0 01-.221-.357l1.156-2.336a.249.249 0 01.222-.139h1.472a.247.247 0 01.247.248z\"/><path d=\"M11.382 14.3h1.468a.245.245 0 00.192-.092L19.6 6.1a.247.247 0 01.44.156V7.5a.247.247 0 01-.147.226 3.142 3.142 0 00-1.832 2.848 3.074 3.074 0 002.969 3.093A2.76 2.76 0 0024 10.942a.99.99 0 10-1.979 0c0 .689-.758.742-.99.742a1.121 1.121 0 010-2.227.99.99 0 00.99-.989V3.024c0-1.417-1.116-1.4-1.621-1.019L11.22 9.9a.251.251 0 00-.086.188v3.962a.248.248 0 00.248.25z\"/><circle cx=\"4.701\" cy=\"20.236\" r=\"1.979\"/><circle cx=\"18.557\" cy=\"20.236\" r=\"1.979\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});