define("ember-svg-jar/inlined/shopping-voucher-mail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-voucher-mail</title><path d=\"M23.742 11.063a.5.5 0 00-.506.013l-2.353 1.471a.25.25 0 01-.383-.212V1.963A1.984 1.984 0 0018.5 0h-13a1.984 1.984 0 00-2 1.963v10.372a.25.25 0 01-.383.212l-2.352-1.47A.5.5 0 000 11.5V22a2 2 0 002 2h20a2 2 0 002-2V11.5a.5.5 0 00-.258-.437zM20.561 20.5a.752.752 0 01-1.059.062l-4.216-3.748a.254.254 0 00-.166-.063H8.88a.254.254 0 00-.166.063L4.5 20.561a.75.75 0 11-1-1.121l4.145-3.685a.248.248 0 00.083-.208.252.252 0 00-.117-.191l-2-1.247A.25.25 0 015.5 13.9V3a1 1 0 011-1l11-.032a1 1 0 011 1V13.9a.25.25 0 01-.117.212l-2 1.247a.252.252 0 00-.117.191.248.248 0 00.083.208L20.5 19.44a.75.75 0 01.061 1.06z\"/><path d=\"M16.256 3.8a.751.751 0 00-.961.449l-.3.841a.249.249 0 01-.236.165H9a1.243 1.243 0 00-1.043.562 1.228 1.228 0 00-.126 1.131v.014l1.088 3.021.006.017a1.245 1.245 0 001.146.752h3.578a1.256 1.256 0 001.176-.822L16.7 4.757a.75.75 0 00-.444-.957z\"/><circle cx=\"10\" cy=\"12.5\" r=\"1\"/><circle cx=\"14\" cy=\"12.5\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});