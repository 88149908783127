define("ember-svg-jar/inlined/multiple-actions-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-email</title><path d=\"M18.382 10.577c-.454-.907-1.9-1.44-3.911-2.179l-.486-.172a1.8 1.8 0 01-.055-1.172 4.647 4.647 0 001.216-3.569A3.22 3.22 0 0012 0a2.994 2.994 0 00-1.342.314.25.25 0 00-.1.363 5 5 0 01.842 2.808 6.266 6.266 0 01-.922 3.624.25.25 0 00.121.372c1.918.716 3.3 1.328 3.9 2.537a7.986 7.986 0 01.711 2.755.25.25 0 00.25.227h3.24a.252.252 0 00.187-.083.25.25 0 00.062-.194 6.531 6.531 0 00-.567-2.146z\"/><path d=\"M13 13h.7a.252.252 0 00.187-.083.25.25 0 00.062-.194 6.531 6.531 0 00-.568-2.146c-.454-.907-1.9-1.44-3.911-2.179l-.486-.172a1.8 1.8 0 01-.054-1.172 4.647 4.647 0 001.216-3.569A3.22 3.22 0 007 0a3.22 3.22 0 00-3.146 3.485 4.605 4.605 0 001.193 3.538 1.877 1.877 0 010 1.185l-.515.19c-2.01.739-3.46 1.272-3.914 2.179A7.542 7.542 0 000 13.5a.5.5 0 00.5.5h10.515A2.489 2.489 0 0113 13zM23.871 18.042a.249.249 0 00-.254.007l-4.425 3.078a2.26 2.26 0 01-2.384 0l-4.425-3.078a.25.25 0 00-.383.212V22.5a1.5 1.5 0 001.5 1.5h9a1.5 1.5 0 001.5-1.5v-4.239a.249.249 0 00-.129-.219z\"/><path d=\"M22.5 14.5h-9A1.5 1.5 0 0012 16a.073.073 0 00.035.063l5.565 3.792a.752.752 0 00.794 0l5.568-3.792A.075.075 0 0024 16a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});