define("ember-svg-jar/inlined/style-three-pin-fish-prepare", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-fish-prepare</title><circle cx=\"15.507\" cy=\"13.241\" r=\".75\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm2.5 5.5A.5.5 0 0115 5h3a.5.5 0 01.5.5v1a1 1 0 01-1 1H15a.5.5 0 01-.5-.5zm-9 0A.5.5 0 016 5h7a.5.5 0 01.5.5v3a.5.5 0 01-.5.5c-.116 0-6.664-1.883-6.775-1.915a1 1 0 01-.725-.96zm13.929 8.255A6.357 6.357 0 0114 16.5a6.505 6.505 0 01-4.929-2.1.25.25 0 00-.345-.025L6.32 16.384A.5.5 0 016 16.5a.507.507 0 01-.212-.047A.5.5 0 015.5 16v-5a.5.5 0 01.82-.384l2.346 1.954a.247.247 0 00.186.057.251.251 0 00.17-.093A6 6 0 0114 10.5c4.289 0 5.418 2.7 5.464 2.814a.5.5 0 01-.035.443z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});