define("ember-svg-jar/inlined/science-atom-positive-negative", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-atom-positive-negative</title><path d=\"M23.517 8.616a1 1 0 10-1.92.562 9.986 9.986 0 11-7.178-6.884A1 1 0 1014.9.354a11.982 11.982 0 108.616 8.262z\"/><path d=\"M12 5.75A6.25 6.25 0 1018.25 12 6.257 6.257 0 0012 5.75zM15 13h-1.5a.5.5 0 00-.5.5V15a1 1 0 01-2 0v-1.5a.5.5 0 00-.5-.5H9a1 1 0 010-2h1.5a.5.5 0 00.5-.5V9a1 1 0 012 0v1.5a.5.5 0 00.5.5H15a1 1 0 010 2z\"/><circle cx=\"19.5\" cy=\"4\" r=\"3.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});