define("ember-svg-jar/inlined/currency-pound-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-pound-circle</title><path d=\"M12 0a12 12 0 1012 12A12 12 0 0012 0zm1.35 10.25a1.25 1.25 0 110 2.5h-1.5a.25.25 0 00-.25.25v.3a9.763 9.763 0 01-.313 2.632.251.251 0 00.041.219.254.254 0 00.2.1h3.622a1.25 1.25 0 010 2.5h-7.2a1.25 1.25 0 110-2.5c.95 0 1.15-1.6 1.15-2.95V13a.25.25 0 00-.25-.25h-1.5a1.25 1.25 0 010-2.5h1.5A.25.25 0 009.1 10V7.9a3.65 3.65 0 117.3 0 1.25 1.25 0 01-2.5 0 1.15 1.15 0 10-2.3 0V10a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});