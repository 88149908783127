define("ember-svg-jar/inlined/car-repair-lock-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-repair-lock-1</title><path d=\"M15.436 12.132a4.51 4.51 0 011.264-1.325.25.25 0 00-.042-.435A1.5 1.5 0 1118.762 9a1.467 1.467 0 01-.156.658.252.252 0 00.018.253.249.249 0 00.23.108c.126-.012.255-.019.385-.019a4.5 4.5 0 011.4.223.25.25 0 00.328-.237V8.25a2.739 2.739 0 00-1-2.115.252.252 0 01-.09-.195.245.245 0 01.093-.193l.895-.716a1 1 0 00-1.249-1.562l-.725.58a.249.249 0 01-.4-.168l-.1-.88A3.017 3.017 0 0015.4.25H6.079a3.011 3.011 0 00-2.987 2.725l-.1.889a.25.25 0 01-.405.168l-.7-.563a1 1 0 00-1.25 1.562l.884.707a.25.25 0 010 .389A2.737 2.737 0 00.512 8.25v2.25a1.752 1.752 0 001.75 1.75h12.962a.248.248 0 00.212-.118zM4.885 4.973l.2-1.806a1.007 1.007 0 011-.917H15.4a1.013 1.013 0 011 .943l.2 1.78a.249.249 0 01-.248.277H5.133a.249.249 0 01-.248-.277zM4.262 10.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM6.762 13.25h-4a.249.249 0 00-.25.25v.25a2.25 2.25 0 004.5 0v-.25a.251.251 0 00-.25-.25z\"/><path d=\"M22.939 16.27a.25.25 0 01-.2-.245V14.5a3.5 3.5 0 00-7 0v1.525a.25.25 0 01-.2.245 1 1 0 00-.8.98v5.5a1 1 0 001 1h7a1 1 0 001-1v-5.5a1 1 0 00-.8-.98zm-3.7 5.23a1 1 0 111-1 1 1 0 01-1 1zm1.5-5.5a.25.25 0 01-.25.25h-2.5a.249.249 0 01-.25-.25v-1.5a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});