define("ember-svg-jar/inlined/single-man-actions-flag.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-flag</title><path d=\"M7.54 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.743a.5.5 0 01.741-.3A6.956 6.956 0 009 5a6.35 6.35 0 00.929-.07.5.5 0 01.569.564A3 3 0 014.54 5a2.8 2.8 0 01.1-.743zM12.452 12.621a.893.893 0 01.007-.1.5.5 0 00-.193-.461 7.454 7.454 0 00-4.52-1.56 7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h10.912a.5.5 0 00.5-.5zM23.23 12.2a.5.5 0 00-.479-.033l-.884.41a3.053 3.053 0 01-2.716-.37c-2.095-1.418-4.231-.275-4.868-.06a.5.5 0 00-.339.474L13.952 23a1 1 0 002 0v-4.662a.249.249 0 01.191-.243 3.042 3.042 0 012.446.44 4.027 4.027 0 002.272.7 4.741 4.741 0 002.309-.662.5.5 0 00.29-.454v-5.5a.5.5 0 00-.23-.419z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});