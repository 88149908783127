define("ember-svg-jar/inlined/animal-products-fried-egg-pan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>animal-products-fried-egg-pan</title><path d=\"M14.014 5.529A1.986 1.986 0 1016 7.515a1.986 1.986 0 00-1.986-1.986z\"/><path d=\"M14 0a10 10 0 00-8.286 15.592.5.5 0 01-.061.633L.439 21.439a1.5 1.5 0 002.122 2.122l5.213-5.215a.5.5 0 01.634-.06A10 10 0 1014 0zm2.28 16.069a1.286 1.286 0 01-1.739-1.2 4.329 4.329 0 00-4.329-4.329 4.235 4.235 0 00-1.513.279.878.878 0 01-1.193-.82 6.494 6.494 0 118.774 6.074z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});