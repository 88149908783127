define("ember-svg-jar/inlined/messages-bubble-graph-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-graph-alternate</title><path d=\"M12 .836C5.383.836 0 5.31 0 10.811a9.011 9.011 0 003.056 6.658L.93 21.713a1 1 0 001.316 1.355l5.982-2.784a14.267 14.267 0 003.772.5c6.617 0 12-4.475 12-9.974S18.617.836 12 .836zm0 17.949a12.169 12.169 0 01-3.562-.523 1 1 0 00-.714.05l-3.071 1.429a.25.25 0 01-.329-.338l.869-1.736a1 1 0 00-.269-1.228A7.215 7.215 0 012 10.811c0-4.4 4.486-7.975 10-7.975s10 3.578 10 7.975-4.486 7.974-10 7.974z\"/><path d=\"M18 13.374h-1.5v-6a1 1 0 10-2 0v6h-1v-4a1 1 0 00-2 0v4h-1v-2.5a1 1 0 00-2 0v2.5h-1v-6a1 1 0 00-2 0v7a1 1 0 001 1H18a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});