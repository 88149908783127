define("ember-svg-jar/inlined/video-game-nintendo-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-nintendo-1</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v9.25a.5.5 0 00.5.5h23a.5.5 0 00.5-.5V1.5A1.5 1.5 0 0022.5 0zm-4 8.5a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5v-6A.5.5 0 016 2h12a.5.5 0 01.5.5zM23.5 12.75H.5a.5.5 0 00-.5.5v9.25A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-9.25a.5.5 0 00-.5-.5zM20.25 16a.75.75 0 11-.75.75.75.75 0 01.75-.75zM5.5 19.25H5a.25.25 0 00-.25.25v.5a.75.75 0 01-1.5 0v-.5a.25.25 0 00-.25-.25h-.5a.75.75 0 010-1.5H3a.25.25 0 00.25-.25V17a.75.75 0 011.5 0v.5a.25.25 0 00.25.25h.5a.75.75 0 010 1.5zm11 2.25a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5v-6A.5.5 0 018 15h8a.5.5 0 01.5.5zm2.25-2.5a.75.75 0 11.75-.75.75.75 0 01-.75.75zm1.5 1.5a.75.75 0 11.75-.75.75.75 0 01-.75.75zm1.5-1.5a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});