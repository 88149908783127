define("ember-svg-jar/inlined/people-man-graduate.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-graduate</title><path d=\"M.5 13.985h3a.5.5 0 00.5-.5A10.623 10.623 0 002.778 8.86a.256.256 0 01-.028-.114V5.205a.75.75 0 00-1.5 0v3.541a.252.252 0 01-.027.114A10.608 10.608 0 000 13.485a.5.5 0 00.5.5zM21.187 20.271l-.017-.006-4.97-1.647a.5.5 0 00-.5.114l-3.357 3.231a.5.5 0 01-.691 0l-3.4-3.218a.5.5 0 00-.5-.112l-4.938 1.638a4.517 4.517 0 00-2.8 3.087.5.5 0 00.483.627h23a.5.5 0 00.483-.626 4.51 4.51 0 00-2.793-3.088zM23.586 1.993L12.429.052a2.5 2.5 0 00-.858 0L.414 1.993a.5.5 0 000 .985l11.157 1.94a2.441 2.441 0 00.858 0l11.157-1.94a.5.5 0 000-.985z\"/><path d=\"M18.458 5.393l-6.115 1.063a2.037 2.037 0 01-.685 0L5.543 5.393a.25.25 0 00-.293.246V10a.249.249 0 01-.063.166 2.1 2.1 0 00-.476 1.455 2.726 2.726 0 001.433 2.429l.151.192c.51 3.047 4.02 4.99 5.686 4.99s5.175-1.943 5.685-4.989l.151-.192a2.733 2.733 0 001.433-2.43 2.118 2.118 0 00-.442-1.407.251.251 0 01-.058-.159V5.639a.249.249 0 00-.292-.246zm-1.266 7.3a1.753 1.753 0 00-1 1.306c-.377 2.249-3.174 3.739-4.207 3.739S8.15 16.246 7.773 14a1.757 1.757 0 00-1-1.306A1.276 1.276 0 016.21 11.6a.638.638 0 01.1-.433.748.748 0 00.438-.678V8.548a.249.249 0 01.1-.2.253.253 0 01.216-.044A19.469 19.469 0 0012 8.952a19.471 19.471 0 004.937-.646.25.25 0 01.313.242v1.821a.743.743 0 00.395.787.622.622 0 01.105.44 1.276 1.276 0 01-.558 1.095z\"/><path d=\"M12.939 14.306a1.385 1.385 0 01-.237.1 1.992 1.992 0 01-.575.081 1.993 1.993 0 01-.58-.082 1.365 1.365 0 01-.238-.1.75.75 0 00-.711 1.321 2.728 2.728 0 00.51.21 3.5 3.5 0 001.017.149h.007a3.449 3.449 0 001.014-.147 2.841 2.841 0 00.511-.212.75.75 0 10-.714-1.319zM11.189 12a.75.75 0 00-.055-1.059 2.1 2.1 0 00-2.636 0 .75.75 0 001 1.115.636.636 0 01.628 0A.75.75 0 0011.189 12zM13.064 10.945a.75.75 0 001 1.115.636.636 0 01.628 0 .75.75 0 101-1.115 2.1 2.1 0 00-2.628 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});