define("ember-svg-jar/inlined/smiley-look-one-eye", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-look-one-eye</title><path d=\"M20.893 3.951a.26.26 0 00-.043-.038 5.894 5.894 0 00-8.05 1.309 1 1 0 01-1.6-1.194 7.75 7.75 0 014.51-2.952.25.25 0 00.022-.476 12.024 12.024 0 105.161 3.356zM8 9a3.159 3.159 0 01-1.8-.668A1.429 1.429 0 005.5 8c-.354 0-.609.455-.612.459a1.006 1.006 0 01-1.345.421.993.993 0 01-.438-1.327A2.808 2.808 0 015.5 6a3.159 3.159 0 011.8.668A1.429 1.429 0 008 7c.354 0 .609-.455.612-.459a1.006 1.006 0 011.345-.421.993.993 0 01.443 1.327A2.808 2.808 0 018 9zm1 3a1 1 0 01-1 1H6a1 1 0 010-2h2a1 1 0 011 1zm8-6.5a4.5 4.5 0 11-4.5 4.5A4.505 4.505 0 0117 5.5zM14.5 17a1 1 0 010 2h-5a1 1 0 010-2z\"/><circle cx=\"17\" cy=\"10\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});