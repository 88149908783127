define("ember-svg-jar/inlined/army-symbol-airborne-infantry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-symbol-airborne-infantry</title><path d=\"M15.53 17.22l-1.146-1.147a1.252 1.252 0 00-1.768 0l-.439.44a.252.252 0 01-.354 0l-.439-.44a1.252 1.252 0 00-1.768 0L8.47 17.22a.75.75 0 001.06 1.06l.793-.793a.252.252 0 01.354 0l.793.793a.749.749 0 001.06 0l.793-.793a.252.252 0 01.354 0l.793.793a.75.75 0 101.06-1.06z\"/><path d=\"M24 4.75a2 2 0 00-2-2H2a2 2 0 00-2 2v14.5a2 2 0 002 2h20a2 2 0 002-2zM2 6.22a.25.25 0 01.4-.2l7.673 5.78a.252.252 0 010 .4L2.4 17.979a.25.25 0 01-.4-.2zM4.634 5.2a.251.251 0 01.151-.45h14.432a.249.249 0 01.15.449l-7.217 5.436a.249.249 0 01-.3 0zm7.216 8.165a.249.249 0 01.3 0l7.215 5.435a.25.25 0 01-.15.45H4.785a.25.25 0 01-.15-.45zm2.077-1.165a.252.252 0 010-.4L21.6 6.021a.25.25 0 01.4.2V17.78a.25.25 0 01-.4.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});