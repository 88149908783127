define("ember-svg-jar/inlined/christmas-sugar-cane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-sugar-cane</title><path d=\"M19.032 10.636a1.794 1.794 0 00-1.278.53l-.849.849a.5.5 0 00-.089.587 3 3 0 01.147 2.471.5.5 0 00.113.533l.678.678a1.807 1.807 0 003.084-1.278v-2.563a1.809 1.809 0 00-1.806-1.807zM11.36 15.073a3.006 3.006 0 01.147-2.471.5.5 0 00-.088-.587l-.85-.85a1.807 1.807 0 00-3.084 1.278v2.563a1.807 1.807 0 003.084 1.278l.678-.678a.5.5 0 00.113-.533z\"/><circle cx=\"14.162\" cy=\"14\" r=\"2\"/><path d=\"M9.162 7a1 1 0 112 0v3.141a.5.5 0 00.148.355l.8.8a.5.5 0 00.6.084c.055-.031.112-.06.169-.087a.5.5 0 00.286-.452v-1.69a.5.5 0 01.342-.474l1-.334a.5.5 0 01.658.475v2.021a.5.5 0 00.285.452c.057.027.114.056.169.086a.5.5 0 00.6-.083l.835-.836a.386.386 0 00.115-.276V7a7 7 0 10-14 0 3 3 0 006 0zm2.619-4.077a.5.5 0 01.724-.338 5.011 5.011 0 012.578 3.533.5.5 0 01-.333.562l-.962.32a.5.5 0 01-.654-.408 3 3 0 00-1.351-2.115.5.5 0 01-.218-.523zM7.359 5.932A2.983 2.983 0 007.162 7a1 1 0 01-2 0 4.967 4.967 0 01.5-2.167.5.5 0 01.783-.155l.785.7a.5.5 0 01.129.554zm.082-2.372a.5.5 0 01.067-.8 4.96 4.96 0 012.277-.75.5.5 0 01.527.6l-.216 1.031a.5.5 0 01-.41.392 2.982 2.982 0 00-.9.295.5.5 0 01-.563-.071zM16.549 16.493a.5.5 0 00-.644-.053 2.96 2.96 0 01-.458.27.5.5 0 00-.285.452v.687a.5.5 0 01-.342.474l-1 .334a.5.5 0 01-.658-.475v-1.02a.5.5 0 00-.285-.452 3.042 3.042 0 01-.458-.27.5.5 0 00-.645.053l-.5.5a.391.391 0 00-.114.276V21a3 3 0 006 0v-3.733a.388.388 0 00-.115-.276zM15.162 21a1 1 0 01-2 0v-.183a.5.5 0 01.342-.474l1-.333a.5.5 0 01.658.474z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});