define("ember-svg-jar/inlined/scroll-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>scroll-vertical</title><circle cx=\"12\" cy=\"11.987\" r=\"3\"/><path d=\"M7.384 7.208l4.439-4.44a.249.249 0 01.354 0l4.439 4.44a1.25 1.25 0 001.768-1.768L13.591.647a2.3 2.3 0 00-3.182 0L5.616 5.44a1.25 1.25 0 001.768 1.768zM16.616 16.767l-4.439 4.44a.251.251 0 01-.354 0l-4.439-4.44a1.25 1.25 0 00-1.768 1.768l4.792 4.792a2.25 2.25 0 003.183 0l4.793-4.793a1.25 1.25 0 00-1.768-1.768z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});