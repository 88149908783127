define("ember-svg-jar/inlined/shipment-online-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-online-monitor</title><path d=\"M6.25 5.375a.25.25 0 00-.25.25v5.75a1 1 0 001 1h10a1 1 0 001-1v-5.75a.25.25 0 00-.25-.25zM17.2 4.375A.25.25 0 0017.417 4l-1.788-2.969a.231.231 0 00-.083-.084.479.479 0 00-.252-.072H12.75a.25.25 0 00-.25.25v3a.25.25 0 00.25.25zM11.5 1.125a.25.25 0 00-.25-.25H8.706a.479.479 0 00-.252.072.241.241 0 00-.084.084L6.582 4a.25.25 0 00.214.379h4.454a.25.25 0 00.25-.25z\"/><path d=\"M23.5 3.875a3 3 0 00-3-3 1 1 0 10.013 2 .991.991 0 01.987 1v9.542a1 1 0 01-1 1h-17a1 1 0 01-1-1V3.875a.99.99 0 01.986-1 1 1 0 10.014-2 3 3 0 00-3 3v12a3 3 0 003 3h6.82a.251.251 0 01.237.329l-.75 2.25a.25.25 0 01-.237.171H8.5a.75.75 0 000 1.5h8a.75.75 0 000-1.5h-1.07a.25.25 0 01-.237-.171l-.75-2.25a.248.248 0 01.034-.225.252.252 0 01.2-.1h5.806a3.007 3.007 0 003-2.94c.017-.639.017.704.017-12.064z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});