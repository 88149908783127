define("ember-svg-jar/inlined/credit-card-payment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-payment</title><path d=\"M3.926 5.6a.978.978 0 00.974-.98V3.051a.25.25 0 01.362-.224l.179.09a.979.979 0 00.981-.061l.786-.525a.251.251 0 01.278 0l.786.525a.979.979 0 00.981.061l.179-.09a.25.25 0 01.362.224V4.62a.979.979 0 101.957 0V1.855A1.467 1.467 0 009.63.541l-.61.306a.248.248 0 01-.251-.015l-.605-.4a1.464 1.464 0 00-1.627 0l-.6.4a.248.248 0 01-.251.015L5.072.541a1.469 1.469 0 00-2.125 1.314V4.62a.979.979 0 00.979.98zM22.5 2.286h-3.644a.25.25 0 00-.25.25V12.8a.25.25 0 00.25.25H22.5a1 1 0 001-1V3.286a1 1 0 00-1-1zm-.468 3.426a.49.49 0 01-.979 0V4.244a.49.49 0 11.979 0zM17.628 2.536a.25.25 0 00-.25-.25h-.708a1 1 0 00-1 1v8.766a1 1 0 001 1h.708a.25.25 0 00.25-.25zM21.543 19.414h-.729a.25.25 0 01-.25-.25v-3.175a.979.979 0 10-1.958 0v3.175a.25.25 0 01-.25.25h-.728a.489.489 0 00-.346.835l1.957 1.958a.49.49 0 00.692 0l1.958-1.958a.489.489 0 00-.346-.835zM11.266 6.333h-7.83A2.939 2.939 0 00.5 9.269v11.613a2.866 2.866 0 002.785 2.936h8.132a2.866 2.866 0 002.783-2.936V9.269a2.939 2.939 0 00-2.934-2.936zm.489 13.947h-.978a.734.734 0 010-1.468h.978a.734.734 0 010 1.468zM7.84 16.854h-.978a.734.734 0 110-1.468h.978a.734.734 0 110 1.468zm.734 2.692a.734.734 0 01-.734.734h-.978a.734.734 0 010-1.468h.978a.733.733 0 01.734.734zm-5.627-4.16h.979a.734.734 0 110 1.468h-.979a.734.734 0 110-1.468zm0 3.426h.979a.734.734 0 010 1.468h-.979a.734.734 0 110-1.468zm8.808-1.958h-.978a.734.734 0 110-1.468h.978a.734.734 0 110 1.468zm-9.3-7.585a.98.98 0 01.979-.979h7.83a.979.979 0 01.979.979v1.936a1 1 0 01-1 1H3.457a1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});