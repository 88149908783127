define("ember-svg-jar/inlined/pin-x-mark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin-x-mark</title><path d=\"M17.948 19.309a1 1 0 00-1.264-.632l-4.526 1.508a.489.489 0 01-.316 0l-4.526-1.508a1 1 0 00-.632 1.9l1.442.481a.25.25 0 010 .474l-1.442.481A1 1 0 007 23.959a.973.973 0 00.316-.052l4.526-1.507a.5.5 0 01.316 0l4.526 1.508a.973.973 0 00.316.052 1 1 0 00.316-1.949l-1.442-.481a.25.25 0 010-.474l1.442-.481a1 1 0 00.632-1.266z\"/><path d=\"M11.5 18.651a.964.964 0 001.005 0c1.709-1.046 7.19-4.755 7.19-10.436C19.693 3.406 16.808.041 12 .041S4.307 3.406 4.307 8.215c0 5.685 5.482 9.39 7.193 10.436zM12 3.887a3.847 3.847 0 11-3.846 3.847A3.846 3.846 0 0112 3.887z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});