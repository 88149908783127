define("ember-svg-jar/inlined/agriculture-machine-harvester-storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>agriculture-machine-harvester-storage</title><path d=\"M23.5 3.5h-1a.5.5 0 00-.5.5v1a1.51 1.51 0 01-.063.432l-.524 1.744a.249.249 0 00.239.322H23.5A.5.5 0 0024 7V4a.5.5 0 00-.5-.5z\"/><rect x=\"12\" y=\"1.5\" width=\"9\" height=\"3\" rx=\".5\" ry=\".5\"/><path d=\"M20.536 5.5h-8.073a.25.25 0 00-.239.322l.182.606a.25.25 0 01-.239.322H8.556a1.988 1.988 0 00-1.7.945l-.791 1.276a.5.5 0 01-.7.153L3.223 7.7A.5.5 0 013 7.281V6.5a1 1 0 00-2 0v10a1 1 0 001 1 .5.5 0 01.5.5v1.883a.5.5 0 01-.235.424l-2.03 1.269A.5.5 0 00.5 22.5h4a1 1 0 001-1V18a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v1.883a.5.5 0 01-.235.424l-2.03 1.269a.5.5 0 00.265.924h4a1 1 0 001-1V18a.5.5 0 01.5-.5 1 1 0 001-1v-1a1 1 0 00-1-1h-.854a.251.251 0 01-.207-.11.254.254 0 01-.025-.233l1.337-3.343a.5.5 0 01.465-.314H15a.5.5 0 01.5.5v1a.5.5 0 00.053.224l.5 1a.5.5 0 00.894 0l.5-1A.5.5 0 0017.5 12v-1a.5.5 0 01.5-.5h.628a1.007 1.007 0 00.958-.713l1.19-3.965a.25.25 0 00-.24-.322zM4.482 11.522l-1.02 1.642A.25.25 0 013 13.032v-2.613a.25.25 0 01.389-.208l.946.631a.5.5 0 01.147.68zM7.25 14a.5.5 0 01-.5.5H5.437a.25.25 0 01-.213-.382l.923-1.487a.469.469 0 01.658-.143 1 1 0 01.445.832zm5.535-4.907l-2.037 5.093a.5.5 0 01-.464.314H9.75a.5.5 0 01-.5-.5v-.68a2.993 2.993 0 00-1.336-2.5l-.054-.036a.5.5 0 01-.147-.679l.7-1.123a.5.5 0 01.425-.236h3.719a.25.25 0 01.232.343z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});