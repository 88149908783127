define("ember-svg-jar/inlined/audio-file-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file-star</title><path d=\"M12.608 17.139a1.262 1.262 0 00.3-1.378A1.232 1.232 0 0011.739 15h-2.5a.25.25 0 01-.229-.151l-1.345-3.117-.013-.032a1.293 1.293 0 00-2.317.028l-1.346 3.12a.25.25 0 01-.23.151h-2.5a1.228 1.228 0 00-1.166.76 1.271 1.271 0 00.319 1.4l2.194 1.955a.249.249 0 01.063.286l-1.224 2.811a1.253 1.253 0 00.315 1.451 1.354 1.354 0 001.506.166l3.111-1.752a.249.249 0 01.245 0l3.113 1.752a1.275 1.275 0 001.506-.166 1.256 1.256 0 00.316-1.45L10.333 19.4a.249.249 0 01.063-.286zM16.232 11.5a.734.734 0 00.017-.084v-5.17c0-.141.178-.179.271-.121l1.08.675a.75.75 0 10.8-1.269l-1.818-1.137a1.25 1.25 0 00-1.833 1.1V8.9a.246.246 0 01-.294.244 2.025 2.025 0 00-1.47.222 2.312 2.312 0 00-1.229 1.8 2.245 2.245 0 104.476.344z\"/><path d=\"M23.413 3L21 .585A2 2 0 0019.585 0H8a2 2 0 00-2 2v6.791a.252.252 0 00.232.249 8.535 8.535 0 011.451.211.249.249 0 00.317-.24V2.5a.5.5 0 01.5-.5h10.878a.5.5 0 01.353.147l2.121 2.121A.5.5 0 0122 4.62V18a.5.5 0 01-.5.5h-7.337a.234.234 0 00-.162.06c-.225.2-.96.867-1.23 1.109a.165.165 0 00-.059.183l.246.566a.132.132 0 00.12.081H22a2 2 0 002-2V4.413A2 2 0 0023.413 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});