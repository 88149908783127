define("ember-svg-jar/inlined/arrow-thick-circle-bottom-left-corner-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-circle-bottom-left-corner-1</title><path d=\"M20.49 3.516a12 12 0 100 16.969 12.012 12.012 0 000-16.969zM17.5 8.619l-5.228 5.228a.25.25 0 00.177.427H17a.5.5 0 01.5.5V16.6a.5.5 0 01-.5.5H7.408a.5.5 0 01-.5-.5V7.01a.5.5 0 01.5-.5h1.823a.5.5 0 01.5.5v4.543a.249.249 0 00.426.176L15.385 6.5a.5.5 0 01.707 0l1.41 1.41a.5.5 0 01-.002.709z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});