define("ember-svg-jar/inlined/style-three-pin-arrows-inwards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-arrows-inwards</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm-1.5 15.75a.75.75 0 01-1.5 0v-1.086a.25.25 0 00-.427-.177L6.78 16.28a.75.75 0 01-1.06-1.06l1.793-1.793A.25.25 0 007.336 13H6.25a.75.75 0 010-1.5h3.5a.754.754 0 01.75.75zm0-8a.753.753 0 01-.75.75h-3.5a.75.75 0 010-1.5h1.086a.25.25 0 00.177-.427L5.72 4.78a.75.75 0 011.06-1.06l1.793 1.793A.25.25 0 009 5.336V4.25a.75.75 0 011.5 0zm1.5 3.5A1.25 1.25 0 1113.25 10 1.252 1.252 0 0112 11.25zm6.28 3.97a.75.75 0 11-1.06 1.06l-1.793-1.793a.25.25 0 00-.427.177v1.086a.75.75 0 01-1.5 0v-3.5a.754.754 0 01.75-.75h3.5a.75.75 0 010 1.5h-1.086a.25.25 0 00-.177.427zM17.75 7a.75.75 0 010 1.5h-3.5a.753.753 0 01-.75-.75v-3.5a.75.75 0 011.5 0v1.086a.25.25 0 00.427.177L17.22 3.72a.75.75 0 111.06 1.06l-1.793 1.793a.25.25 0 00.177.427z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});