define("ember-svg-jar/inlined/medical-condition-cough", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-condition-cough</title><path d=\"M23.6 16.367a1.066 1.066 0 00-1.515 0 1.084 1.084 0 000 1.525 1.066 1.066 0 001.515 0 1.084 1.084 0 000-1.525zM20.033 18.884a1.067 1.067 0 00-.758.315 1.085 1.085 0 000 1.526 1.066 1.066 0 001.515 0 1.085 1.085 0 000-1.526 1.065 1.065 0 00-.757-.315zM17.22 21.716a1.065 1.065 0 00-.758.316 1.084 1.084 0 000 1.525 1.066 1.066 0 001.515 0 1.084 1.084 0 000-1.525 1.062 1.062 0 00-.757-.316zM17 19.959a1.084 1.084 0 000-1.525 1.065 1.065 0 00-1.514 0 1.082 1.082 0 000 1.525 1.065 1.065 0 001.514 0zM20.038 16.9a1.083 1.083 0 000-1.526 1.065 1.065 0 00-1.514 0 1.085 1.085 0 000 1.526 1.065 1.065 0 001.514 0zM15.987 16.967a1.062 1.062 0 00.758-.316 1.084 1.084 0 000-1.525 1.066 1.066 0 00-1.515 0 1.084 1.084 0 000 1.525 1.062 1.062 0 00.757.316zM15.914 10.835a1.16 1.16 0 00.165-.707 5.748 5.748 0 00-1.048-2.793 6.2 6.2 0 01-.5-.982c0-3.9-3.592-6.226-7.064-6.226A7.324 7.324 0 00.084 7.552a6.52 6.52 0 002.683 5.7.5.5 0 01.213.409v3.627a.5.5 0 00.5.5h7.242a.5.5 0 00.5-.5v-1.9a.5.5 0 01.5-.5h.81a1.951 1.951 0 001.949-1.948v-1.167a.5.5 0 01.489-.5h.142a1.041 1.041 0 00.802-.438z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});