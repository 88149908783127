define("ember-svg-jar/inlined/real-estate-favorite-house-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-favorite-house-rating</title><path d=\"M12.664 10.237a1 1 0 00-1.328 0l-7.168 6.374a.5.5 0 00.332.874h2a.5.5 0 01.5.5v5a1 1 0 001 1h2a.5.5 0 00.5-.5v-2.5a1.5 1.5 0 013 0v2.5a.5.5 0 00.5.5h2a1 1 0 001-1v-5a.5.5 0 01.5-.5h2a.5.5 0 00.332-.874zM23.683 7.054a.813.813 0 00-.5-1.457h-.956a.5.5 0 01-.447-.276l-.692-1.385a.843.843 0 00-1.455 0l-.692 1.385a.5.5 0 01-.441.279h-.877a.813.813 0 00-.46 1.482l.783.54a.5.5 0 01.188.577l-.4 1.136a.812.812 0 001.253.921l1.077-.808a.5.5 0 01.6 0l1.169.877a.813.813 0 001.272-.863l-.354-1.3a.5.5 0 01.179-.529zM5.978 10.259a.817.817 0 00.288-.925L5.867 8.2a.5.5 0 01.188-.577l.785-.541A.812.812 0 006.379 5.6H5.5a.5.5 0 01-.447-.276l-.69-1.388a.844.844 0 00-1.454 0l-.693 1.385a.5.5 0 01-.447.276H.812a.813.813 0 00-.493 1.458l.752.574a.5.5 0 01.178.529l-.355 1.3a.812.812 0 001.272.86l1.17-.878a.5.5 0 01.6 0l1.077.808a.812.812 0 00.965.011zM8.942 5.492a.5.5 0 01.173.61L8.479 7.56a1 1 0 00.252 1.158 1.02 1.02 0 001.2.132l1.834-1.032a.5.5 0 01.49 0l1.833 1.032a1.02 1.02 0 001.2-.132 1 1 0 00.252-1.158L14.9 6.1a.5.5 0 01.171-.609L16.2 4.7a1.006 1.006 0 00.238-1.1.985.985 0 00-.934-.611H14.2a.5.5 0 01-.468-.323L12.948.6c-.007-.017-.013-.033-.021-.049a1.055 1.055 0 00-1.858.048l-.784 2.068a.5.5 0 01-.467.323h-1.31a.986.986 0 00-.934.61 1.033 1.033 0 00.3 1.156z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});