define("ember-svg-jar/inlined/video-game-logo-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-logo-heart</title><path d=\"M10.368 14.454a.5.5 0 000-.85l-3.1-1.926a.5.5 0 00-.768.422v3.854a.5.5 0 00.764.425zM4.854 10.46a.5.5 0 00-.236-.425L.764 7.642A.5.5 0 000 8.067v4.785a.5.5 0 00.764.425l3.854-2.392a.5.5 0 00.236-.425zM11.243 8.251a.5.5 0 00-.507.012l-2.854 1.772a.5.5 0 000 .85l2.854 1.771a.5.5 0 00.764-.425V8.688a.5.5 0 00-.257-.437zM11.243 15.39a.5.5 0 00-.507.012l-3.387 2.1a.5.5 0 00.03.866l3.387 1.794a.5.5 0 00.734-.441v-3.9a.5.5 0 00-.257-.431zM11.247 5.932l-4.015-2.1a.5.5 0 00-.732.443v4.542a.5.5 0 00.764.425l4.013-2.491a.472.472 0 00-.03-.819zM4.752 3.89l-3.512 2a.5.5 0 00-.016.859l3.512 2.18a.5.5 0 00.764-.422V4.324a.5.5 0 00-.748-.434zM5.243 11.976a.5.5 0 00-.507.012l-3.592 2.23a.5.5 0 00.029.867l3.593 1.9a.5.5 0 00.734-.442v-4.13a.5.5 0 00-.257-.437zM17.243 11.665a.5.5 0 00-.507.013l-3.1 1.926a.5.5 0 000 .85l3.1 1.927a.5.5 0 00.764-.425V12.1a.5.5 0 00-.257-.435zM23.743 7.63a.5.5 0 00-.507.012l-3.854 2.393a.5.5 0 000 .85l3.854 2.392a.5.5 0 00.764-.425V8.067a.5.5 0 00-.257-.437zM12.757 12.668a.5.5 0 00.507-.012l2.854-1.771a.5.5 0 000-.85l-2.854-1.772a.5.5 0 00-.764.425v3.543a.5.5 0 00.257.437zM13.264 15.4a.5.5 0 00-.764.425v3.9a.5.5 0 00.734.441l3.387-1.794a.5.5 0 00.03-.866zM16.768 3.833l-4.015 2.1a.472.472 0 00-.03.819l4.013 2.491a.5.5 0 00.764-.425V4.276a.5.5 0 00-.732-.443zM18.757 8.944a.5.5 0 00.507-.013l3.512-2.18a.5.5 0 00-.016-.859l-3.512-2a.5.5 0 00-.748.434v4.181a.5.5 0 00.257.437zM19.264 11.988a.5.5 0 00-.764.425v4.133a.5.5 0 00.734.442l3.593-1.9a.5.5 0 00.029-.867z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});