define("ember-svg-jar/inlined/typing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>typing</title><path d=\"M24 8a3 3 0 00-3-3H3a3 3 0 00-3 3v8a3 3 0 003 3h18a3 3 0 003-3zm-2 8a1 1 0 01-1 1H3a1 1 0 01-1-1V8a1 1 0 011-1h18a1 1 0 011 1z\"/><path d=\"M8 10a1 1 0 000-2H5a1 1 0 000 2h.25a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25H5a1 1 0 000 2h3a1 1 0 000-2h-.25a.25.25 0 01-.25-.25v-3.5a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});