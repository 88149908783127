define("ember-svg-jar/inlined/currency-yuan-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-yuan-circle</title><path d=\"M12 0a12 12 0 1012 12A12 12 0 0012 0zm5.479 5.717l-3.248 4.64a.25.25 0 00.205.393h.746a1.25 1.25 0 010 2.5H13.5a.25.25 0 00-.25.25v.77a.25.25 0 00.25.25h1.682a1.25 1.25 0 010 2.5H13.5a.249.249 0 00-.25.25V19a1.25 1.25 0 01-2.5 0v-1.73a.249.249 0 00-.25-.25H8.818a1.25 1.25 0 010-2.5H10.5a.25.25 0 00.25-.25v-.77a.25.25 0 00-.25-.25H8.818a1.25 1.25 0 110-2.5h.746a.25.25 0 00.205-.393l-3.248-4.64A1.25 1.25 0 018.57 4.283l3.23 4.608a.25.25 0 00.41 0l3.22-4.608a1.25 1.25 0 012.049 1.434z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});