define("ember-svg-jar/inlined/book-close-bookmark-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-close-bookmark-1</title><path d=\"M20.15 4.04a.5.5 0 01-.4-.489V2a2 2 0 00-2-2H5.25a3 3 0 00-3 3v18a3 3 0 003 3h14.5a2 2 0 002-2V6a2 2 0 00-1.6-1.96zm-2.9 9.253a.25.25 0 01-.426.177l-1.9-1.9a.252.252 0 00-.354 0l-1.9 1.9a.25.25 0 01-.426-.177V5.75a.25.25 0 01.25-.25H17a.25.25 0 01.25.25zm.5-9.543a.25.25 0 01-.25.25H5.25a1 1 0 010-2H17.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});