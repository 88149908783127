define("ember-svg-jar/inlined/couple-polaroid-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>couple-polaroid-image</title><path d=\"M14.538 3.536a2.683 2.683 0 00-2.295.7.352.352 0 01-.486 0 2.684 2.684 0 00-2.295-.7 2.6 2.6 0 00-1.9 1.353 2.448 2.448 0 00.489 2.928l3.1 3.083a1.2 1.2 0 001.7 0l3.1-3.075a2.452 2.452 0 00.5-2.936 2.6 2.6 0 00-1.913-1.353z\"/><path d=\"M21 0H3a3 3 0 00-3 3v18a3 3 0 003 3h18a3 3 0 003-3V3a3 3 0 00-3-3zm1 17.85a.651.651 0 01-.651.65.591.591 0 01-.544-.363 4.155 4.155 0 00-2.446-2.369.25.25 0 01-.061-.44 2.251 2.251 0 10-2.595 0 .25.25 0 01-.062.44 4.164 4.164 0 00-2.467 2.419.5.5 0 01-.463.313h-1.422a.5.5 0 01-.463-.313 4.164 4.164 0 00-2.467-2.419.25.25 0 01-.062-.44 2.25 2.25 0 10-2.6 0 .25.25 0 01-.061.44A4.155 4.155 0 003.2 18.137a.591.591 0 01-.544.363.651.651 0 01-.656-.65V3a1 1 0 011-1h18a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});