define("ember-svg-jar/inlined/bicycle-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bicycle-person</title><path d=\"M10.5 10a3 3 0 00-3-3h-3a3 3 0 00-3 3v.75a.25.25 0 00.25.25h8.5a.25.25 0 00.25-.25zM5.75 19.75a5.006 5.006 0 015-5 .249.249 0 00.25-.25V13a.5.5 0 00-.5-.5H.5a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h6.366a.25.25 0 00.185-.418A4.936 4.936 0 015.75 19.75z\"/><path d=\"M23.25 22a2.241 2.241 0 01-1.65-3.766l.988 1.867a.75.75 0 001.326-.7l-.988-1.866a2.182 2.182 0 01.325-.033.75.75 0 000-1.5 3.711 3.711 0 00-1.048.167L20 12h.755a.75.75 0 000-1.5H19.58a1.25 1.25 0 00-1.1 1.835l.687 1.3a.25.25 0 01-.221.367H15.52a.249.249 0 01-.238-.175l-.26-.827h.728a.75.75 0 000-1.5h-3a.75.75 0 000 1.5h.7l.451 1.434a.25.25 0 01-.043.231l-1.409 1.761A3.7 3.7 0 0010.75 16a3.75 3.75 0 103.674 4.5h2.319a.883.883 0 00.621-.319l2.763-3.948a.25.25 0 01.426.027l.324.612A3.733 3.733 0 0023.25 23.5a.75.75 0 000-1.5zm-11.459-1.5h1.071a2.25 2.25 0 110-1.5h-1.071a.75.75 0 000 1.5zm4.644-1.608a.25.25 0 01-.2.107h-1.811a3.72 3.72 0 00-.8-1.635l1.416-1.771a.253.253 0 01.2-.094h3.1a.25.25 0 01.2.393z\"/><circle cx=\"6\" cy=\"3\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});