define("ember-svg-jar/inlined/book-music", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-music</title><path d=\"M20.15 4.04a.5.5 0 01-.4-.489V2a2 2 0 00-2-2H5.25a3 3 0 00-3 3v18a3 3 0 003 3h14.5a2 2 0 002-2V6a2 2 0 00-1.6-1.96zM17.5 15.5A2.5 2.5 0 1115 13a2.464 2.464 0 01.415.035.5.5 0 00.585-.493V10a.248.248 0 00-.093-.194.241.241 0 00-.209-.05l-5 1.071a.251.251 0 00-.2.244V17.5A2.5 2.5 0 118 15a2.464 2.464 0 01.415.035.5.5 0 00.585-.493v-3.471a1.76 1.76 0 011.383-1.711l5-1.071A1.75 1.75 0 0117.5 10zm.25-11.75a.25.25 0 01-.25.25H5.25a1 1 0 010-2H17.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});