define("ember-svg-jar/inlined/rooster-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rooster-1</title><path d=\"M1.49 8.316a.251.251 0 00.4.01 10 10 0 0115.727-.011.251.251 0 00.4-.01c.07-.1.135-.2.2-.306a4 4 0 00-4.867-5.747 4 4 0 00-7.2 0A4 4 0 001.287 8c.063.108.131.214.203.316z\"/><circle cx=\"11.251\" cy=\"11.25\" r=\"1.25\"/><path d=\"M17.877 12a8.5 8.5 0 00-16.626 2.5V23a1 1 0 001 1h7.161a.251.251 0 00.2-.4 4.543 4.543 0 01-.9-2.716v-3.09A1.725 1.725 0 007.5 16.206a.75.75 0 11.5-1.412 3.205 3.205 0 012.212 3v3.091a3.07 3.07 0 003.066 3.066A2.927 2.927 0 0016.251 21v-2a.25.25 0 01.112-.209c.6-.4 1.967-1.543 5.571-.343a1 1 0 001.317-.948 5.808 5.808 0 00-5.374-5.5zm-6.626 2A2.75 2.75 0 1114 11.25 2.75 2.75 0 0111.251 14zm5.427 2.349a.25.25 0 01-.332-.2A7.662 7.662 0 0116.251 15c0-.988 1.439-1 1.5-1a3.846 3.846 0 012.839 1.632.25.25 0 01-.234.4 7.722 7.722 0 00-3.678.317z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});