define("ember-svg-jar/inlined/gesture-tap-swipe-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-tap-swipe-horizontal</title><path d=\"M2.164 6.405l-2 2.5a.75.75 0 000 .937l2 2.5a.751.751 0 001.336-.469v-5a.751.751 0 00-1.336-.468zM23.836 8.9l-2-2.5a.751.751 0 00-1.336.468v5a.751.751 0 001.336.469l2-2.5a.75.75 0 000-.937zM12 3.623a7.25 7.25 0 00-6.148 11.093.75.75 0 101.271-.795 5.75 5.75 0 119.745.013.75.75 0 001.27.8A7.251 7.251 0 0012 3.623z\"/><path d=\"M12 7.374a3.5 3.5 0 00-3.5 3.5v9a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-9a3.5 3.5 0 00-3.5-3.5zM13.5 12a.374.374 0 01-.375.375h-2.25A.375.375 0 0110.5 12v-1.126a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});