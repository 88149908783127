define("ember-svg-jar/inlined/single-man-monitor.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-monitor</title><path d=\"M24 2.5A1.5 1.5 0 0022.5 1h-21A1.5 1.5 0 000 2.5V17a1.5 1.5 0 001.5 1.5h9a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5H4a1 1 0 000 2h16a1 1 0 000-2h-6.5a.5.5 0 01-.5-.5V19a.5.5 0 01.5-.5h9A1.5 1.5 0 0024 17zM7 15.268a.5.5 0 01-.5-.5 4.928 4.928 0 01.487-1.988c.369-.739 1.5-1.156 3.073-1.733l.189-.07a.249.249 0 00.164-.234v-.452a.254.254 0 00-.067-.171 2.127 2.127 0 01-.561-1.139.239.239 0 00-.045-.109 1.226 1.226 0 01-.188-.434 1.323 1.323 0 01.1-.937.252.252 0 00.021-.19 2.356 2.356 0 01.053-1.973 1.061 1.061 0 01.58-.392.253.253 0 00.121-.076 2.646 2.646 0 011.984-.6c.975.023 2.079.362 2.256 1.245a3.509 3.509 0 01-.306 1.815.248.248 0 00.01.2 1.336 1.336 0 01.08.905 1.192 1.192 0 01-.192.438.222.222 0 00-.04.1 2.127 2.127 0 01-.562 1.146.254.254 0 00-.067.171v.452a.249.249 0 00.164.234l.189.07c1.57.577 2.7.994 3.073 1.733a4.928 4.928 0 01.487 1.988.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});