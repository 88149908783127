define("ember-svg-jar/inlined/gesture-swipe-vertical-up-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-vertical-up-1</title><path d=\"M15.028 1.8a1.753 1.753 0 01.553.09l1.192.4a.251.251 0 00.3-.116.746.746 0 00-.421-1.074L13.817.152a.747.747 0 00-.949.474l-.945 2.834a.751.751 0 00.712.987.583.583 0 00.155-.024.22.22 0 00.148-.141l.43-1.29a1.75 1.75 0 011.66-1.192zM16.583 16.776a1 1 0 00-1.2.754 8.509 8.509 0 01-2.438 4.624 1 1 0 001.367 1.46 10.517 10.517 0 003.022-5.642 1 1 0 00-.751-1.196z\"/><path d=\"M18.2 5.161a.751.751 0 00-.1-1.382l-2.834-.945a.748.748 0 00-.949.474l-.944 2.834a.751.751 0 001.047.909l.465-.233a.251.251 0 01.353.156c.052.183.1.368.147.565a1 1 0 00.051.121.987.987 0 001.146.633 1 1 0 00.754-1.2c-.027-.119-.063-.223-.091-.34a22.46 22.46 0 00-.208-.787.25.25 0 01.129-.292zM19 12.613a3.5 3.5 0 00-3.5-3.5h-10a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h10a3.5 3.5 0 003.5-3.5zm-3.5 1.5h-1.125a.375.375 0 01-.375-.375v-2.25a.375.375 0 01.375-.375H15.5a1.5 1.5 0 010 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});