define("ember-svg-jar/inlined/fruit-strawberry-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fruit-strawberry-1</title><path d=\"M19.8 8.273A.5.5 0 0019.144 8 7.152 7.152 0 0115 8.211a.5.5 0 00-.589.313 6.561 6.561 0 01-1.185 1.967 2 2 0 01-3.041 0A6.538 6.538 0 019 8.519a.5.5 0 00-.589-.313 6.963 6.963 0 01-3.581-.064.5.5 0 00-.595.281A10.182 10.182 0 003.5 12.5C3.5 18.832 10.333 24 12 24a11.088 11.088 0 004.6-2.941 11.933 11.933 0 003.9-8.559 11.124 11.124 0 00-.7-4.227zM7 14.5a1 1 0 111-1 1 1 0 01-1 1zM9.5 18a1 1 0 111-1 1 1 0 01-1 1zm2.5 3.5a1 1 0 111-1 1 1 0 01-1 1zm0-7a1 1 0 111-1 1 1 0 01-1 1zm2.5 3.5a1 1 0 111-1 1 1 0 01-1 1zm2.5-3.5a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M18.713 6.546a.39.39 0 00.194-.56 5.659 5.659 0 00-5.241-2.468.25.25 0 01-.231-.379 2.968 2.968 0 011.437-1.21 1 1 0 00-.744-1.857 4.95 4.95 0 00-2.928 3.21.5.5 0 01-.573.351 5.693 5.693 0 00-6.076 2.34.391.391 0 00.194.559A5.5 5.5 0 009.923 5.9a.25.25 0 01.386.267 2.95 2.95 0 00-.1.75 4.242 4.242 0 001.12 2.6.5.5 0 00.76 0 4.248 4.248 0 001.12-2.6 2.98 2.98 0 00-.109-.777.25.25 0 01.388-.267 5.49 5.49 0 005.225.673z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});