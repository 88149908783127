define("ember-svg-jar/inlined/deforestation-saw-forbidden", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>deforestation-saw-forbidden</title><path d=\"M17.339 13.038a89.693 89.693 0 00-1.261-1.046.5.5 0 00-.671.033l-2.552 2.552a.5.5 0 00-.12.515 17.907 17.907 0 00.689 1.916l1.258 1.7a1.707 1.707 0 002.6.216l1.767-1.767a1.709 1.709 0 000-2.416zm.647 3.062l-1.769 1.768a.229.229 0 01-.162.06.218.218 0 01-.159-.1l-1-1.348a.25.25 0 01.025-.325l1.529-1.529a.25.25 0 01.354 0l1.177 1.177a.2.2 0 01.06.147.205.205 0 01-.055.15z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM4.27 18.068a.5.5 0 01-.366-.2A10 10 0 0117.863 3.9a.5.5 0 01.06.759L13.388 9.2a.5.5 0 01-.671.032c-1.432-1.176-3.074-2.524-4.742-3.9a1 1 0 00-1.165-.073l-.829.517a.994.994 0 00-.471.849V8.5a1 1 0 001 1H7a.5.5 0 01.5.5v1a1 1 0 001 1h1.086a.414.414 0 01.293.707l-5.216 5.216a.5.5 0 01-.393.145zM12 22a9.949 9.949 0 01-5.864-1.9.5.5 0 01-.059-.759l13.26-13.26a.5.5 0 01.759.06A9.995 9.995 0 0112 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});