define("ember-svg-jar/inlined/information-desk-ticket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>information-desk-ticket</title><path d=\"M13.5 22.29v-7.616a.25.25 0 00-.434-.169 5.388 5.388 0 01-1.557 1.173 3.812 3.812 0 01-1.549.471A2.257 2.257 0 017.8 14.55a2.176 2.176 0 01-.017-1.194.251.251 0 00-.242-.314H.75a.75.75 0 000 1.5h.75v8.5a.5.5 0 00.5.5h11.461a.25.25 0 00.229-.35 2.233 2.233 0 01-.19-.902z\"/><circle cx=\"17.5\" cy=\"3.04\" r=\"2.5\"/><path d=\"M24 10.93a4 4 0 00-4-4h-5a4 4 0 00-3.99 3.759A2.06 2.06 0 019.59 12.7a1.253 1.253 0 00.37 2.45 3.058 3.058 0 001.07-.349 4.49 4.49 0 002.47-4.12.5.5 0 011 0V22.29a1.25 1.25 0 002.5 0v-6.25a.5.5 0 011 0v6.25a1.25 1.25 0 002.5 0V10.68a.5.5 0 011 0v3.86a1.25 1.25 0 002.5 0z\"/><circle cx=\"4.5\" cy=\"3.04\" r=\"2.5\"/><path d=\"M7.75 5.707a.737.737 0 00.187.481l-1.094 1.1a.5.5 0 01-.354.146H3.5A2.5 2.5 0 001 9.93v1.61a.25.25 0 00.25.25h6a.25.25 0 00.25-.25V9.661a.5.5 0 01.146-.354l2.854-2.85H12a.75.75 0 00.75-.75v-4.5a.75.75 0 00-.75-.75H8.5a.75.75 0 00-.75.75zm1.5-3.5a.249.249 0 01.25-.25H11a.249.249 0 01.25.25v2.5a.25.25 0 01-.25.25H9.5a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});