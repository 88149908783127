define("ember-svg-jar/inlined/indent-right-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>indent-right-alternate</title><path d=\"M10.5 20H4a1 1 0 000 2h6.5a1 1 0 100-2zM11.5 16.5a1 1 0 00-1-1H1a1 1 0 000 2h9.5a1 1 0 001-1zM10.5 11H4a1 1 0 100 2h6.5a1 1 0 000-2zM10.5 6.5H1a1 1 0 000 2h9.5a1 1 0 000-2zM10.5 2H4a1 1 0 000 2h6.5a1 1 0 000-2zM14 0a1 1 0 00-1 1v22a1 1 0 002 0V1a1 1 0 00-1-1zM23 11h-1.251a.249.249 0 01-.25-.25v-1.5a.751.751 0 00-1.2-.6l-3.667 2.75a.75.75 0 000 1.2l3.666 2.75a.75.75 0 001.2-.6v-1.5a.249.249 0 01.25-.25H23a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});