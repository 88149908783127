define("ember-svg-jar/inlined/cloud-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-add</title><path d=\"M24 11.113a5.765 5.765 0 00-5.216-5.952.249.249 0 01-.191-.12 7.684 7.684 0 00-14.1 2.294.251.251 0 01-.227.2A4.643 4.643 0 00.624 9.774 4.47 4.47 0 000 12.223a4.293 4.293 0 001.235 3.1 4.624 4.624 0 001.78 1.062.963.963 0 001.243-.86v-.026a.985.985 0 00-.658-1.033 2.58 2.58 0 01-.96-.572A2.374 2.374 0 012 12.109a2.588 2.588 0 012.586-2.588 2.7 2.7 0 01.535.054.989.989 0 00.811-.184 1 1 0 00.392-.779c.085-5.736 8.293-7.372 10.818-2.044a.973.973 0 00.936.57h.01a3.736 3.736 0 013 1.309 3.888 3.888 0 01.9 2.812 3.428 3.428 0 01-1.473 2.667.973.973 0 00-.4.964l.01.059a.968.968 0 001.525.608A5.4 5.4 0 0024 11.113z\"/><path d=\"M19 16.2a6.5 6.5 0 10-6.5 6.5 6.508 6.508 0 006.5-6.5zm-4-.75a.75.75 0 110 1.5h-1.5a.249.249 0 00-.25.25v1.5a.75.75 0 01-1.5 0v-1.5a.249.249 0 00-.25-.25H10a.75.75 0 010-1.5h1.5a.25.25 0 00.25-.25v-1.5a.75.75 0 111.5 0v1.5a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});