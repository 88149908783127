define("ember-svg-jar/inlined/landmark-colosseum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-colosseum</title><path d=\"M20.609 5.091a.242.242 0 01-.112-.2V3.716a.249.249 0 01.138-.223l2.086-1.043a.5.5 0 000-.894l-3-1.5A.5.5 0 0019 .5v3.343a.244.244 0 01-.342.226A17.23 17.23 0 0012 2.805a16.951 16.951 0 00-7.151 1.481.242.242 0 01-.349-.221V3.8a.362.362 0 01.217-.35l2.007-1a.5.5 0 000-.894l-3-1.5A.5.5 0 003 .5v4.749a.247.247 0 01-.1.2A6.381 6.381 0 000 10.5v5.769c0 2.959 2.461 5.475 6.161 6.777a.256.256 0 00.339-.24v-1.7a2.075 2.075 0 011.664-2.08A2 2 0 0110.5 21v2.7a.252.252 0 00.233.252c.416.029.838.047 1.267.047s.851-.018 1.267-.047a.252.252 0 00.233-.252v-2.593a2.075 2.075 0 011.664-2.08A2 2 0 0117.5 21v1.806a.256.256 0 00.339.24c3.7-1.3 6.161-3.818 6.161-6.777V10.5c0-2.129-1.284-4.029-3.391-5.409zM17.294 15.3a15.605 15.605 0 01-5.294.9 15.605 15.605 0 01-5.294-.9.251.251 0 01-.156-.314C7.012 13.726 9.109 12.5 12 12.5s4.988 1.226 5.45 2.487a.251.251 0 01-.156.313zm2.579-1.433a.866.866 0 01-.329.32.232.232 0 01-.255-.05C18.411 12.017 15.526 10.5 12 10.5c-3.51 0-6.385 1.5-7.277 3.609a.266.266 0 01-.3.06.783.783 0 01-.308-.322A2.471 2.471 0 013.964 13c0-2.44 3.68-4.5 8.036-4.5s8.036 2.06 8.036 4.5a2.48 2.48 0 01-.163.868zm2.1-2.953c-.031.229-.284.351-.356.192C20.391 8.414 16.606 6.5 12 6.5s-8.4 1.919-9.619 4.618a.175.175 0 01-.33-.066A3 3 0 012 10.5c0-3.087 4.579-5.695 10-5.695s10 2.608 10 5.7a3.025 3.025 0 01-.029.41z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});