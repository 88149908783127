define("ember-svg-jar/inlined/diagram-round", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-round</title><path d=\"M13.1 1.1a1.44 1.44 0 100 2.88 8.024 8.024 0 11-6.858 12.183.25.25 0 01.162-.375l.889-.187a1.44 1.44 0 00.489-2.616l-3.363-2.192a1.515 1.515 0 00-1.078-.2 1.5 1.5 0 00-.908.616l-2.2 3.371A1.44 1.44 0 001.44 16.8a1.506 1.506 0 00.3-.031l1.231-.26a.251.251 0 01.278.137A10.9 10.9 0 1013.1 1.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});