define("ember-svg-jar/inlined/book-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-download</title><path d=\"M8 17.25A8.259 8.259 0 0116.25 9a8.16 8.16 0 013.534.8.5.5 0 00.716-.451V6a2 2 0 00-1.6-1.96.5.5 0 01-.4-.489V2a2 2 0 00-2-2H4a3 3 0 00-3 3v18a3 3 0 003 3h6.787a.25.25 0 00.161-.441A8.226 8.226 0 018 17.25zM3 3a1 1 0 011-1h12.25a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H4a1 1 0 01-1-1z\"/><path d=\"M16.25 10.5A6.75 6.75 0 1023 17.25a6.758 6.758 0 00-6.75-6.75zm0 2.75a1 1 0 011 1v2.25a.5.5 0 00.5.5h.794a.457.457 0 01.366.729l-2.264 3.086a.456.456 0 01-.731 0l-2.267-3.09a.456.456 0 01.367-.725h.735a.5.5 0 00.5-.5v-2.25a1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});