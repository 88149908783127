define("ember-svg-jar/inlined/fossil-energy-barrel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fossil-energy-barrel</title><path d=\"M21.5 22h-1a.25.25 0 01-.25-.25V18a.25.25 0 00-.25-.25H4a.25.25 0 00-.25.25v3.75a.25.25 0 01-.25.25h-1a1 1 0 000 2h19a1 1 0 000-2zM21.5 14.25h-1a.25.25 0 01-.25-.25v-4a.25.25 0 00-.25-.25H4a.25.25 0 00-.25.25v4a.25.25 0 01-.25.25h-1a1 1 0 000 2h19a1 1 0 000-2zM21.5 6.25h-1a.25.25 0 01-.25-.25V2.25A.25.25 0 0120.5 2h1a1 1 0 000-2h-19a1 1 0 000 2h1a.25.25 0 01.25.25V6a.25.25 0 01-.25.25h-1a1 1 0 000 2h19a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});