define("ember-svg-jar/inlined/video-game-gamasutra-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-gamasutra-1</title><path d=\"M23 9.25h-1.5a.5.5 0 01-.5-.5v-3.5a1 1 0 00-1-1h-3.5a.5.5 0 01-.5-.5v-1.5a1 1 0 00-1-1H9a1 1 0 00-1 1v1.5a.5.5 0 01-.5.5H4a1 1 0 00-1 1v3.5a.5.5 0 01-.5.5H1a1 1 0 00-1 1v6a1 1 0 001 1h4a1 1 0 001-1v-1.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v1.5a1 1 0 001 1h4a1 1 0 001-1v-1.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v1.5a1 1 0 001 1h4a1 1 0 001-1v-6a1 1 0 00-1-1zm-12.5 1a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h3a.5.5 0 01.5.5zm7 0a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h3a.5.5 0 01.5.5z\"/><rect x=\"10.5\" y=\"19.75\" width=\"3\" height=\"3\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});