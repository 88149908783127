define("ember-svg-jar/inlined/stairs-person-decend-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>stairs-person-decend-1</title><circle cx=\"8.125\" cy=\"2.5\" r=\"2.5\"/><path d=\"M2.625 13a1.246 1.246 0 00.884-.366l2.191-2.19a.25.25 0 01.427.177v9.629a1.25 1.25 0 002.5 0v-3.173a.25.25 0 01.446-.156 4.159 4.159 0 001.2 1.027l1.988 1.137a1.25 1.25 0 001.24-2.17l-1.989-1.138a1.75 1.75 0 01-.881-1.518V9.9a.243.243 0 01.364-.211 2.755 2.755 0 011.386 2.387v1.174a1.25 1.25 0 002.5 0v-1.179a5.258 5.258 0 00-2.646-4.557l-.561-.322A5.251 5.251 0 009.062 6.5h-.78a5.217 5.217 0 00-3.713 1.538l-2.828 2.828A1.25 1.25 0 002.625 13z\"/><path d=\"M14.625 19.383v.867a.25.25 0 01-.25.25h-4.25a1.513 1.513 0 00-1.5 1.5v1a1 1 0 002 0v-.25a.25.25 0 01.25-.25h4.75a1 1 0 001-1v-1.75a.25.25 0 01.25-.25h4.75a1 1 0 000-2h-5.5a1.514 1.514 0 00-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});