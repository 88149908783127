define("ember-svg-jar/inlined/color-painting-palette-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-painting-palette-1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM8 12.5a3 3 0 11-3 3 3 3 0 013-3zm1-5a3 3 0 113 3 3 3 0 01-3-3zm7 11a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});