define("ember-svg-jar/inlined/mask-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mask-heart</title><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2zm-2 19.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M11.633 19.069a.5.5 0 00.734 0l5.959-6.46a4.27 4.27 0 00.751-4.8 4 4 0 00-2.99-2.262 3.9 3.9 0 00-3.473 1.179l-.434.451a.251.251 0 01-.36 0l-.434-.451A3.9 3.9 0 007.913 5.55a4 4 0 00-2.99 2.262 4.265 4.265 0 00.744 4.788z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});