define("ember-svg-jar/inlined/multiple-users-wifi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-users-wifi</title><path d=\"M20 23.25a.5.5 0 00.5.5h2.821a.5.5 0 00.463-.688 4.45 4.45 0 00-3.6-2.765.5.5 0 00-.537.646A7.713 7.713 0 0120 23.25zM4.867 20.9a.5.5 0 00-.475-.653H4.38a4.49 4.49 0 00-4.164 2.816.5.5 0 00.464.687H4a.5.5 0 00.5-.5 7.685 7.685 0 01.367-2.35zM7.05 6.043a1 1 0 101.414 1.414 5 5 0 017.072 0 1 1 0 001.414-1.414 7 7 0 00-9.9 0z\"/><path d=\"M6.343 4.593a8 8 0 0111.314 0 1 1 0 101.414-1.414 10 10 0 00-14.142 0 1 1 0 001.414 1.414zM9.83 17.058a.25.25 0 01-.03.442A6.257 6.257 0 006 23.25a.5.5 0 00.5.5H18a.5.5 0 00.5-.5 6.256 6.256 0 00-3.816-5.75.251.251 0 01-.03-.445 4.733 4.733 0 10-4.824.006zm-.33-4.071a2.541 2.541 0 01.063-.562.5.5 0 01.745-.319 6.149 6.149 0 003.175.881 5.983 5.983 0 00.874-.065.5.5 0 01.567.578 2.736 2.736 0 01-5.424-.513z\"/><path d=\"M19.62 19.25a2.767 2.767 0 10-2.682-2.157 2.783 2.783 0 002.682 2.157z\"/><circle cx=\"4.38\" cy=\"16.5\" r=\"2.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});