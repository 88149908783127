define("ember-svg-jar/inlined/breakup-couple-woman-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>breakup-couple-woman-woman</title><path d=\"M12.044 5.75H10.5a.75.75 0 01-.7-1.006l1.353-3.721a.5.5 0 00-.2-.594A2.75 2.75 0 009.146.02 3.1 3.1 0 006.5 3.233c0 2.116 2.6 4.58 4.246 5.924a.25.25 0 00.391-.1l1.141-2.967a.251.251 0 00-.234-.34z\"/><path d=\"M17.5 3.233A3.1 3.1 0 0014.854.02a2.748 2.748 0 00-1.773.394.5.5 0 00-.208.254l-1.18 3.247a.249.249 0 00.235.335H13.5a.75.75 0 01.7 1.02l-1.408 3.661a.25.25 0 00.391.284C14.841 7.859 17.5 5.379 17.5 3.233zM22.526 19.855l-1.192-3.922A2.947 2.947 0 0020 14.236a.25.25 0 010-.433 3.042 3.042 0 10-3-.003.25.25 0 010 .433 2.953 2.953 0 00-1.334 1.7l-1.192 3.922a.5.5 0 00.079.446.594.594 0 00.407.2l.867-.012a.5.5 0 01.5.45l.261 2.612a.5.5 0 00.5.45h2.822a.5.5 0 00.5-.45l.261-2.612a.5.5 0 01.5-.45l.868.012a.6.6 0 00.406-.2.5.5 0 00.081-.446zM18.5 12.7a1.543 1.543 0 01-1.447-1.011.248.248 0 01.152-.322 3.054 3.054 0 001.12-.7.25.25 0 01.349 0 3.047 3.047 0 001.121.7.251.251 0 01.152.322A1.543 1.543 0 0118.5 12.7zM8.335 15.933A2.953 2.953 0 007 14.236a.25.25 0 010-.436 3.042 3.042 0 10-3 0 .25.25 0 010 .433 2.947 2.947 0 00-1.333 1.7l-1.193 3.922a.5.5 0 00.079.446.6.6 0 00.406.2l.868-.012a.5.5 0 01.5.45l.261 2.612a.5.5 0 00.5.45h2.823a.5.5 0 00.5-.45l.261-2.612a.5.5 0 01.5-.45l.867.012a.594.594 0 00.407-.2.5.5 0 00.079-.446zM5.5 12.7a1.543 1.543 0 01-1.447-1.01.251.251 0 01.152-.322 3.047 3.047 0 001.121-.7.25.25 0 01.349 0 3.054 3.054 0 001.12.7.248.248 0 01.152.322A1.543 1.543 0 015.5 12.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});