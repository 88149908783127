define("ember-svg-jar/inlined/renewable-energy-charging", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>renewable-energy-charging</title><path d=\"M2.3 6.607a1.252 1.252 0 001.2.893H4a.5.5 0 00.5-.5V.5A.5.5 0 004 0h-.5a1.252 1.252 0 00-1.2.893.5.5 0 01-.479.357H.75a.75.75 0 000 1.5h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.75.75 0 000 1.5h1.073a.5.5 0 01.477.357z\"/><path d=\"M23.443 18.211l-4.878-3.249a.684.684 0 00-.1-.054 4.23 4.23 0 00-1.819-.408h-2.5a4.511 4.511 0 00-4.036 3.4.5.5 0 01-.478.355H6.5a3.5 3.5 0 010-7h13.25a4.25 4.25 0 000-8.5h-9.265a.5.5 0 01-.468-.324A4.043 4.043 0 006 0a.5.5 0 00-.5.5V7a.5.5 0 00.5.5 4.041 4.041 0 004.017-2.426.5.5 0 01.468-.324h9.265a2.25 2.25 0 010 4.5H6.5a5.5 5.5 0 000 11h3.132a.5.5 0 01.478.355A4.511 4.511 0 0014.151 24h2.5a4.225 4.225 0 001.854-.424.72.72 0 00.09-.051l4.848-3.234a1.249 1.249 0 000-2.08zm-4.292 1.539h-1.5a.25.25 0 00-.2.4l.079.1a.5.5 0 01-.375.832.493.493 0 01-.374-.17 2.483 2.483 0 01-.485-.824.5.5 0 00-.471-.334h-1.361a.25.25 0 00-.2.4 1.527 1.527 0 00.21.231.5.5 0 01-.665.747 2.509 2.509 0 01-.7-1.042.5.5 0 00-.472-.334h-.487a.5.5 0 010-1h.491a.5.5 0 00.471-.333 2.508 2.508 0 01.709-1.043.5.5 0 11.658.753 1.552 1.552 0 00-.207.222.251.251 0 00.2.4h1.349a.5.5 0 00.471-.333 2.5 2.5 0 01.484-.822.5.5 0 11.748.663 1.67 1.67 0 00-.075.092.25.25 0 00.2.4h1.5a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});