define("ember-svg-jar/inlined/accessories-tie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accessories-tie</title><path d=\"M9.526 3.658A.5.5 0 0010 4h4a.5.5 0 00.475-.342l.562-1.684A1.5 1.5 0 0013.613 0h-3.225a1.5 1.5 0 00-1.423 1.975zM16.438 18.982L14.5 5.926A.5.5 0 0014 5.5h-4a.5.5 0 00-.495.426L7.561 18.994a1.5 1.5 0 00.367 1.182L10.88 23.5a1.5 1.5 0 002.242 0l2.951-3.32a1.5 1.5 0 00.365-1.198z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});