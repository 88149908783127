define("ember-svg-jar/inlined/plant-pot-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plant-pot-3</title><path d=\"M18 17H6a.5.5 0 00-.493.582l.861 5.164A1.493 1.493 0 007.847 24h8.306a1.493 1.493 0 001.479-1.254l.861-5.164A.5.5 0 0018 17zM19.5 12h-5.615a.5.5 0 01-.492-.593.707.707 0 01.42-.52 7.466 7.466 0 001.719-1.03.5.5 0 01.365-.106 5.022 5.022 0 00.518.027 6.072 6.072 0 003.482-1.2 1 1 0 10-1.137-1.644 5.8 5.8 0 01-.657.4.25.25 0 01-.34-.33q.079-.168.156-.345a1 1 0 00-1.838-.787 6.074 6.074 0 01-2.053 2.614.25.25 0 01-.4-.2 15.256 15.256 0 00-.142-1.686.5.5 0 01.253-.5A8.179 8.179 0 0016.3 3.863a1 1 0 00-1.6-1.2 6.1 6.1 0 01-1.013 1.059.25.25 0 01-.409-.2 10.232 10.232 0 01.258-2.283A1 1 0 0011.6.758a11.75 11.75 0 00-.077.336.25.25 0 01-.463.07 1.8 1.8 0 01-.11-.23 1 1 0 00-1.894.645 5.439 5.439 0 002.028 2.494.5.5 0 01.21.39c.055 1.574.253 2.412.324 3.448a.25.25 0 01-.319.257 7.22 7.22 0 01-3.032-2.047A1 1 0 006.732 7.4a8.985 8.985 0 004.415 2.787.436.436 0 01.339.5c-.057.364-.123.678-.186.934a.5.5 0 01-.486.381H4.5a1 1 0 00-1 1v1.5a1 1 0 001 1h15a1 1 0 001-1V13a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});