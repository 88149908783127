define("ember-svg-jar/inlined/video-file-camera", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-camera</title><path d=\"M21.414 4.914L17.086.586A2 2 0 0015.672 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.328a2 2 0 00-.586-1.414zm-4.206 12.431a.5.5 0 01-.631.483l-1.99-.542a.249.249 0 00-.314.21A1.746 1.746 0 0112.542 19h-4.5a1.749 1.749 0 01-1.75-1.75v-4.5A1.749 1.749 0 018.042 11h4.5a1.741 1.741 0 011.728 1.5.25.25 0 00.107.182.255.255 0 00.208.035l1.992-.543a.5.5 0 01.631.483z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});