define("ember-svg-jar/inlined/organic-tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>organic-tree</title><path d=\"M23.805 2.084a1.037 1.037 0 00-.837-.428h-1.045a6.877 6.877 0 00-6.532 4.708l-.673 2.019a.5.5 0 00.474.658h1.784a6.878 6.878 0 006.532-4.707l.439-1.319a1.035 1.035 0 00-.142-.931zM10.646 15.151a7.454 7.454 0 012.708 0 .5.5 0 00.59-.529L13.07 2.65a1.073 1.073 0 00-2.14 0l-.874 11.972a.5.5 0 00.59.529zM7.024 9.041h1.784a.5.5 0 00.474-.658l-.673-2.02a6.878 6.878 0 00-6.532-4.707H1.032a1.032 1.032 0 00-.979 1.359l.44 1.319a6.875 6.875 0 006.531 4.707z\"/><path d=\"M19.449 17.619a4.237 4.237 0 00-.9.095.5.5 0 00-.316.757 7.475 7.475 0 01.8 1.7.5.5 0 11-.951.308 6.385 6.385 0 00-12.176 0 .5.5 0 11-.951-.308 7.436 7.436 0 01.8-1.7.5.5 0 00-.317-.757 4.229 4.229 0 00-5.12 4.13.5.5 0 00.5.5h22.356a.5.5 0 00.5-.5 4.23 4.23 0 00-4.225-4.225z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});