define("ember-svg-jar/inlined/envelope-ups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>envelope-ups</title><path d=\"M13 17a1 1 0 00-1-1h-1v2h1a1 1 0 001-1z\"/><path d=\"M20.5 0h-17A1.5 1.5 0 002 1.5v21A1.5 1.5 0 003.5 24h17a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0020.5 0zm-4.047 17.291l1.41.469a1.663 1.663 0 01-.525 3.24H15.5a.5.5 0 010-1h1.838a.662.662 0 00.209-1.291l-1.41-.469a1.663 1.663 0 01.525-3.24H18.5a.5.5 0 010 1h-1.838a.662.662 0 00-.209 1.291zM12 15a2 2 0 010 4h-1v1.5a.5.5 0 01-1 0v-5a.5.5 0 01.5-.5zm-1.5-5a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5zM8 4.25a.75.75 0 010-1.5h8.5a.75.75 0 010 1.5zM7 20a1 1 0 001-1v-3.5a.5.5 0 011 0V19a2 2 0 01-4 0v-3.5a.5.5 0 011 0V19a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});