define("ember-svg-jar/inlined/style-three-pin-unisex", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-unisex</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM9.5 17.5a.5.5 0 01-.5.5H8a.5.5 0 01-.491-.408l-.6-3.184A.5.5 0 006.42 14H6a.5.5 0 01-.5-.5v-1A3.5 3.5 0 019 9a.5.5 0 01.5.5zm0-10A.5.5 0 019 8a2.5 2.5 0 010-5 .5.5 0 01.5.5zm3.25 11.25a.75.75 0 01-1.5 0V2.25a.75.75 0 011.5 0zM14.5 3.5A.5.5 0 0115 3a2.5 2.5 0 010 5 .5.5 0 01-.5-.5zm4.406 12.292A.5.5 0 0118.5 16h-1.219a.5.5 0 00-.485.379l-.311 1.242A.5.5 0 0116 18h-1a.5.5 0 01-.5-.5v-8A.5.5 0 0115 9a2.576 2.576 0 012.448 1.765l1.526 4.577a.5.5 0 01-.068.45z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});