define("ember-svg-jar/inlined/video-game-logo-youtube-gaming-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-logo-youtube-gaming-2</title><path d=\"M23.362 6.525l-5.011-2.871a1.243 1.243 0 00-1.2 0L12 6.463 6.851 3.654a1.236 1.236 0 00-1.213.007L.644 6.521A1.247 1.247 0 000 7.613v5.637a1.244 1.244 0 00.645 1.094l10.754 6a1.242 1.242 0 00.6.155 1.264 1.264 0 00.606-.157l10.741-6A1.246 1.246 0 0024 13.25V7.613a1.241 1.241 0 00-.638-1.088zm-4.873 4.525a.5.5 0 01-.739-.439v-4.5a.5.5 0 01.748-.434l3.332 1.908a.5.5 0 01.058.829z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});