define("ember-svg-jar/inlined/road-sign-speed-limit-30-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-speed-limit-30-1-alternate</title><path d=\"M11.765 18a2 2 0 00-1.991-2h-.758a1.752 1.752 0 00-1.75 1.75.75.75 0 101.5 0 .249.249 0 01.247-.25h.756a.5.5 0 010 1 .75.75 0 000 1.5.5.5 0 010 1h-.754a.25.25 0 01-.25-.25.75.75 0 00-1.5 0 1.753 1.753 0 001.753 1.75h.756a2 2 0 001.99-2 1.983 1.983 0 00-.337-1.109.251.251 0 010-.278A1.983 1.983 0 0011.765 18zM14.515 16a2.253 2.253 0 00-2.25 2.25v2a2.25 2.25 0 004.5 0v-2a2.253 2.253 0 00-2.25-2.25zm.75 4.25a.75.75 0 01-1.5 0v-2a.75.75 0 011.5 0zM8.5 9a.75.75 0 00-.75.75v5a.75.75 0 001.5 0v-5A.75.75 0 008.5 9zM16.5 9a.75.75 0 00-.75.75v5a.75.75 0 001.5 0v-5A.75.75 0 0016.5 9zM20.5 9h-2a.75.75 0 000 1.5.25.25 0 01.25.25v4a.75.75 0 001.5 0v-4a.25.25 0 01.25-.25.75.75 0 000-1.5zM7 8a.75.75 0 00.75-.75V6.188a.249.249 0 01.2-.245A2.244 2.244 0 007.5 1.5H7a.75.75 0 00-.75.75v5A.75.75 0 007 8zm1-4.5a.25.25 0 11-.25.25A.25.25 0 018 3.5zM6.5 14h-1a.25.25 0 01-.25-.25v-4a.75.75 0 00-1.5 0v4A1.752 1.752 0 005.5 15.5h1a.75.75 0 000-1.5zM5.75 6.25a2.553 2.553 0 00-1.3-2.1c-.508-.381-.7-.557-.7-.9A.25.25 0 014 3h1a.75.75 0 000-1.5H4a1.752 1.752 0 00-1.75 1.75 2.553 2.553 0 001.3 2.1c.508.381.7.557.7.9A.25.25 0 014 6.5H3A.75.75 0 003 8h1a1.752 1.752 0 001.75-1.75zM13.143 12.636a.058.058 0 01.107.029v2.085a.75.75 0 001.5 0v-5a.75.75 0 00-1.393-.386l-.643 1.071a.25.25 0 01-.428 0l-.643-1.071a.75.75 0 00-1.393.386v5a.75.75 0 001.5 0v-2.085a.058.058 0 01.107-.029.78.78 0 001.286 0zM19 1.5a.75.75 0 00-.75.75v5A.75.75 0 0019 8a2.752 2.752 0 002.75-2.75v-1A2.752 2.752 0 0019 1.5zm1.25 3.75a.25.25 0 01-.5 0v-1a.25.25 0 01.5 0zM12 8h1a.75.75 0 000-1.5h-1a.25.25 0 010-.5h1a.75.75 0 000-1.5h-1a.25.25 0 01-.25-.25v-1A.25.25 0 0112 3h1a.75.75 0 000-1.5h-1a1.752 1.752 0 00-1.75 1.75v3A1.752 1.752 0 0012 8zM17 6.5h-1a.25.25 0 010-.5h1a.75.75 0 000-1.5h-1a.25.25 0 01-.25-.25v-1A.25.25 0 0116 3h1a.75.75 0 000-1.5h-1a1.752 1.752 0 00-1.75 1.75v3A1.752 1.752 0 0016 8h1a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});