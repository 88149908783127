define("ember-svg-jar/inlined/radiology-scan-mri", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>radiology-scan-mri</title><path d=\"M24 4.51a2.509 2.509 0 00-2.5-2.5h-.01c-2.4.009-3.4.01-3.8 0a.248.248 0 01-.16-.062A8.363 8.363 0 0012 0a8.389 8.389 0 00-5.52 1.938.25.25 0 01-.165.062H2.5A2.5 2.5 0 000 4.5v19a.5.5 0 00.5.5h23a.5.5 0 00.5-.5zm-9.537-.118a.5.5 0 01.282.718L14 6.453a.5.5 0 01-.569.24 5.387 5.387 0 00-2.858 0 .5.5 0 01-.573-.24L9.255 5.11a.5.5 0 01.282-.718 7.934 7.934 0 014.926 0zM17.3 19.73a1.029 1.029 0 01.2.6V21a1 1 0 01-1 1h-9a1 1 0 01-1-1v-.67a1.029 1.029 0 01.2-.6l2.5-4.33a1.014 1.014 0 01.8-.4h4a1.014 1.014 0 01.8.4zM7.172 5.632a.5.5 0 01.74.155l.736 1.322a.5.5 0 01-.122.631 5.492 5.492 0 00-1.14 7.251.5.5 0 01.015.525l-.814 1.412a.5.5 0 01-.822.064 7.963 7.963 0 011.407-11.36zm11.063 11.36a.5.5 0 01-.822-.064l-.813-1.412a.5.5 0 01.015-.525 5.492 5.492 0 00-1.14-7.251.5.5 0 01-.122-.631l.736-1.322a.5.5 0 01.74-.155 7.963 7.963 0 011.407 11.36z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});