define("ember-svg-jar/inlined/bread-loaf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bread-loaf</title><path d=\"M24 8.5a5.005 5.005 0 00-5-5H5.25A5.256 5.256 0 000 8.75c0 2.456.893 4.192 2.481 4.9a.5.5 0 01.29.535l-.6 3.747A2.246 2.246 0 004.4 20.5h14.447a2 2 0 001.98-2.283L20.2 13.8a.5.5 0 01.341-.546A5.01 5.01 0 0024 8.5zm-11.75 3a1.248 1.248 0 00-.945.429 1.257 1.257 0 00-.288 1.006l.7 4.487a.5.5 0 01-.494.576H5.284a.5.5 0 01-.5-.576l.7-4.5A1.245 1.245 0 004.25 11.5c-.479 0-1.75 0-1.75-2.75A2.753 2.753 0 015.25 6h7a2.75 2.75 0 010 5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});