define("ember-svg-jar/inlined/tools-flattener-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-flattener-square</title><path d=\"M20.3 17.406l-5.187-5.186a.5.5 0 00-.707 0l-.9.9a.5.5 0 01-.707 0l-.066-.067a.5.5 0 010-.708L15.4 9.693a2.029 2.029 0 000-2.869L13.876 5.3a.5.5 0 01-.135-.461 1.885 1.885 0 00.046-.406 2.055 2.055 0 00-.6-1.443L10.937.74a2.043 2.043 0 10-2.888 2.89L10.3 5.881a2.032 2.032 0 001.651.587.5.5 0 01.414.148l1.273 1.307a.5.5 0 01-.005.7l-.7.7a.5.5 0 01-.706 0L9.711 6.818a1.535 1.535 0 00-2.121 0L3.7 10.707a1.5 1.5 0 000 2.121l10.589 10.588a1.5 1.5 0 002.121 0l3.89-3.889a1.5 1.5 0 000-2.121z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});