define("ember-svg-jar/inlined/people-woman-14", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-14</title><path d=\"M20.709 18.78l-3.618-.956a.749.749 0 00-.918.541C15.785 19.892 14.03 21 12 21s-3.787-1.11-4.172-2.639a.749.749 0 00-.92-.541l-3.7.987A4.77 4.77 0 00.25 23.25a.75.75 0 001.5 0 3.25 3.25 0 011.925-3.02l.825-.219V23.5a.5.5 0 00.5.5h14a.5.5 0 00.5-.5v-3.492l.744.2a3.26 3.26 0 012.006 3.042.75.75 0 001.5 0 4.785 4.785 0 00-3.041-4.47zM2.6 7.7a1.318 1.318 0 01.912-.579 2.918 2.918 0 011.845.668 1.988 1.988 0 00-.647 1.6 3.109 3.109 0 00.435 1.5c-.322 1.318-.973 2.864-2.3 3.29a.5.5 0 00-.327.337.5.5 0 00.1.46 5.117 5.117 0 003.569 1.672 2.763 2.763 0 00.793-.109 2.1 2.1 0 001.338-1.209A6.484 6.484 0 0011.979 17a6.541 6.541 0 003.746-1.738 2.117 2.117 0 001.378 1.28 2.758 2.758 0 00.793.109 5.116 5.116 0 003.568-1.672.506.506 0 00.1-.46.5.5 0 00-.328-.337c-1.376-.442-2.027-2.082-2.336-3.433a3.144 3.144 0 00.35-1.361 2 2 0 00-.64-1.595.733.733 0 00.246-.454 6.683 6.683 0 00-1.342-5.379C16.344.659 14.489 0 12 0S7.655.659 6.486 1.96a5.313 5.313 0 00-1.2 2.333 3.588 3.588 0 00-1.413-1.272 1.98 1.98 0 00-1.708.058.75.75 0 10.67 1.342.486.486 0 01.447-.021A3.031 3.031 0 014.4 5.7a3.18 3.18 0 00-1.055-.07A2.813 2.813 0 001.4 6.8a.75.75 0 001.2.9zm9.379 7.8c-1.032 0-3.83-1.49-4.207-3.738a1.747 1.747 0 00-1-1.306 1.275 1.275 0 01-.559-1.1.626.626 0 01.1-.439.748.748 0 00.337-1.002.621.621 0 00-.074-.095 16.373 16.373 0 0110.815-.01.69.69 0 00-.082.105.748.748 0 00.336 1.006.616.616 0 01.105.439 1.279 1.279 0 01-.559 1.1 1.746 1.746 0 00-1 1.3c-.381 2.25-3.179 3.74-4.212 3.74z\"/><path d=\"M12.745 12.322a1.7 1.7 0 01-.811.178 1.8 1.8 0 01-.819-.18.75.75 0 00-.711 1.321 3.435 3.435 0 003.06 0 .75.75 0 10-.715-1.318zM11.071 9.519a.752.752 0 00-.053-1.06 2.094 2.094 0 00-2.637 0 .751.751 0 001.006 1.114.628.628 0 01.625 0 .751.751 0 001.059-.054zM12.946 8.459a.751.751 0 001.006 1.114.628.628 0 01.625 0 .751.751 0 001.006-1.114 2.094 2.094 0 00-2.637 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});