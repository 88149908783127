define("ember-svg-jar/inlined/harddrive-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>harddrive-upload</title><path d=\"M1.5 16.5h21a.5.5 0 00.3-.9l-4-3a.507.507 0 00-.3-.1h-13a.507.507 0 00-.3.1l-4 3a.5.5 0 00.3.9zM22.5 18h-21A1.5 1.5 0 000 19.5v3A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-3a1.5 1.5 0 00-1.5-1.5zM20 22.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM10.643 5.619a.25.25 0 00-.25-.25H8.69A.977.977 0 018 3.7L11.417.286a.975.975 0 011.38 0L16.214 3.7a.976.976 0 01-.69 1.667h-1.7a.25.25 0 00-.25.25V9.8a1.464 1.464 0 01-2.928 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});