define("ember-svg-jar/inlined/notes-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-heart</title><path d=\"M9.774 17.666a.24.24 0 00-.227-.166H2.25a.25.25 0 01-.25-.25V4.75a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.249.249 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.249.249 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.249.249 0 01.25-.25h1a.25.25 0 01.25.25v6.169a.244.244 0 00.19.237 5.1 5.1 0 011.426.567.248.248 0 00.384-.206V4.5a2 2 0 00-2-2h-1.25a.249.249 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-1.5a.249.249 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-1.5A.249.249 0 016 2.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25H2a2 2 0 00-2 2v13a2 2 0 002 2h8.355a.249.249 0 00.187-.412 4.931 4.931 0 01-.768-1.422z\"/><path d=\"M23.628 14.449a3.509 3.509 0 00-3.045-1.949 3.657 3.657 0 00-2.694 1.126l-.213.212a.249.249 0 01-.353 0l-.212-.212a3.661 3.661 0 00-2.695-1.126 3.528 3.528 0 00-2.391 6.012l5.113 5.334a.5.5 0 00.723 0l5.106-5.327a3.524 3.524 0 00.661-4.07z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});