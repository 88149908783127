define("ember-svg-jar/inlined/view-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>view-list</title><g stroke=\"#000\" stroke-width=\"2\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M25.5 1.5h45v15h-45zM25.5 25.5h45v15h-45zM25.5 49.5h45v15h-45zM1.5 1.5h15v15h-15zM1.5 25.5h15v15h-15zM1.5 49.5h15v15h-15z\"/></g>",
    "attrs": {
      "width": "72",
      "height": "66",
      "viewBox": "0 0 72 66",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});