define("ember-svg-jar/inlined/weather-cloud-hurricane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-cloud-hurricane</title><path d=\"M10.655 14.386a.25.25 0 00-.21-.386H5.5a3.5 3.5 0 11.448-6.971A1 1 0 007.064 6.2 5 5 0 0117 7c0 .146-.007.289-.02.433a1 1 0 00.226.726.249.249 0 00.193.091h2.281a.25.25 0 00.194-.408 4.972 4.972 0 00-.78-.768.252.252 0 01-.1-.193A7 7 0 005.338 4.84a.249.249 0 01-.22.173A5.5 5.5 0 005.5 16H10a.25.25 0 00.25-.25 2.49 2.49 0 01.405-1.364z\"/><path d=\"M12.5 10a.75.75 0 000 1.5h9.75a.75.75 0 000-1.5zM24 13.25a.75.75 0 00-.75-.75h-9a.75.75 0 000 1.5h9a.75.75 0 00.75-.75zM22 15.75a.75.75 0 00-.75-.75h-8.5a.75.75 0 000 1.5h8.5a.75.75 0 00.75-.75zM22.5 18.25a.75.75 0 00-.75-.75h-5.5a.75.75 0 000 1.5h5.5a.75.75 0 00.75-.75zM19.25 21.5h4a.75.75 0 000-1.5h-4a.75.75 0 000 1.5zM21.75 22.5h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});