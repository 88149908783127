define("ember-svg-jar/inlined/car-repair-fluid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-repair-fluid</title><path d=\"M5.375 12.779h-4a.25.25 0 00-.25.25v.5a1.251 1.251 0 001.25 1.25h2a1.251 1.251 0 001.25-1.25v-.5a.25.25 0 00-.25-.25zM16.479 11.779a.249.249 0 00.2-.106c.32-.452.611-.846.847-1.158a2 2 0 011.595-.794 1.962 1.962 0 011.109.346.25.25 0 00.391-.206V8.236a1.258 1.258 0 00-.366-.884l-1.081-1.085a.5.5 0 01-.111-.168L17.013.973A1.494 1.494 0 0015.62.029H5.3a1.5 1.5 0 00-1.4.943L1.963 5.823a.5.5 0 01-.111.168L.345 7.5a.747.747 0 00-.22.53v2.5a1.251 1.251 0 001.25 1.25zM4.572 4.686l1-2.5a.25.25 0 01.228-.157h9.308a.25.25 0 01.232.157l1 2.5a.25.25 0 01-.232.343H4.8a.25.25 0 01-.207-.11.254.254 0 01-.021-.233zM19.723 11.269a.776.776 0 00-1.2 0c-.425.562-4.152 5.56-4.152 7.952a4.75 4.75 0 109.5 0c.004-2.392-3.723-7.39-4.148-7.952z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});