define("ember-svg-jar/inlined/marine-mammal-turtle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>marine-mammal-turtle</title><path d=\"M23.6 13.416a8.111 8.111 0 00-2.958-1.447C22 11.557 22.9 10.806 22.9 9.771c0-1.679-3.128-6.1-10.171-6.1a7.118 7.118 0 00-6.75 4.716 4.577 4.577 0 00-2.931-1.152C1.253 7.235 0 9.116 0 11.811a.5.5 0 00.147.353 3.825 3.825 0 002.588.932 5.114 5.114 0 002.508-.7c.518-.291.951-.689 1.387-.579a5.121 5.121 0 002.233 1.662 7.09 7.09 0 00.924 3.147 10.824 10.824 0 005.232 3.669 1.032 1.032 0 00.885-.18 1 1 0 00.388-.851 7.082 7.082 0 00-2.492-4.918 10.7 10.7 0 003.1-.495 5.673 5.673 0 002.294 2c.923.311 2.333.076 4.2-.7a1.01 1.01 0 00.6-.811 1.029 1.029 0 00-.394-.924zm-5.677-3.21a.252.252 0 01-.168-.026l-.719-.392a.251.251 0 01-.084-.365l1.46-2.044a.25.25 0 01.345-.061 6.534 6.534 0 011.825 1.819.25.25 0 01-.122.369 15.951 15.951 0 01-2.533.7zm-6.17-1.337a.25.25 0 01.325-.077l2.486 1.356a.25.25 0 01-.108.469 25.889 25.889 0 01-3.413-.064.253.253 0 01-.206-.143.248.248 0 01.021-.248zm3.838-.12a.249.249 0 01-.323.074l-2.281-1.245a.249.249 0 01-.123-.162.245.245 0 01.038-.2l.945-1.366a.254.254 0 01.23-.107 11.129 11.129 0 012.83.627.249.249 0 01.152.161.252.252 0 01-.036.219zm-6.552 1.413a.251.251 0 01-.251.1 19.567 19.567 0 01-.942-.194.25.25 0 01-.19-.286A5.07 5.07 0 0111.534 5.8a.248.248 0 01.26.106.25.25 0 010 .281zm-5.739.884a1.019 1.019 0 111.019-1.019A1.019 1.019 0 013.3 11.046z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});