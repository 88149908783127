define("ember-svg-jar/inlined/ticket-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ticket-clock</title><circle cx=\"15.5\" cy=\"3.875\" r=\"1\"/><circle cx=\"15.5\" cy=\"6.625\" r=\"1\"/><path d=\"M15.5 8.375a1.056 1.056 0 00-.931.625.25.25 0 00.316.321A8.093 8.093 0 0116 9.025a.25.25 0 00.107-.442.98.98 0 00-.607-.208z\"/><path d=\"M22 .625H2a2 2 0 00-2 2v2.781A1.5 1.5 0 001.313 6.9a2.5 2.5 0 010 4.96A1.5 1.5 0 000 13.344v2.781a2 2 0 002 2h7.319a.25.25 0 00.248-.282 7.553 7.553 0 01-.067-.968c0-.163.006-.324.017-.484a.25.25 0 00-.25-.266H2.25a.25.25 0 01-.25-.25v-1.917a.249.249 0 01.184-.241 4.5 4.5 0 000-8.684A.249.249 0 012 4.792V2.875a.25.25 0 01.25-.25h19.5a.25.25 0 01.25.25v1.917a.249.249 0 01-.184.241A4.5 4.5 0 0018.568 8.7a.25.25 0 00.207.285 7.9 7.9 0 011.41.359.254.254 0 00.218-.025.251.251 0 00.114-.188 2.489 2.489 0 012.17-2.231A1.5 1.5 0 0024 5.406V2.625a2 2 0 00-2-2z\"/><path d=\"M17.5 10.375a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm0 11a4.5 4.5 0 114.5-4.5 4.505 4.505 0 01-4.5 4.5z\"/><path d=\"M19.5 15.875h-.75a.25.25 0 01-.25-.25v-1.139a1 1 0 00-2 0v2.389a1 1 0 001 1h2a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});