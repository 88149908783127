define("ember-svg-jar/inlined/warehouse-storage-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>warehouse-storage-3</title><path d=\"M2 23.988a1 1 0 01-1-1v-12.5a1 1 0 012 0v12.5a1 1 0 01-1 1zM22 23.988a1 1 0 01-1-1v-12.5a1 1 0 012 0v12.5a1 1 0 01-1 1z\"/><rect x=\"8.5\" y=\"10.654\" width=\"7\" height=\"6\" rx=\".5\" ry=\".5\"/><rect x=\"12.5\" y=\"17.654\" width=\"7\" height=\"6\" rx=\".5\" ry=\".5\"/><rect x=\"4.5\" y=\"17.654\" width=\"7\" height=\"6\" rx=\".5\" ry=\".5\"/><path d=\"M23.455 8.488A.566.566 0 0024 7.9V4.49a.583.583 0 00-.373-.554L12.71.042a.507.507 0 00-.331 0L.387 3.943A.581.581 0 000 4.5v3.4a.566.566 0 00.545.584z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});