define("ember-svg-jar/inlined/medical-app-smartphone-listen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-app-smartphone-listen</title><path d=\"M21.5 18a2 2 0 00-2-2 1.974 1.974 0 00-1.59.809.5.5 0 01-.4.2A3.5 3.5 0 0014 20.5v.5a1 1 0 01-2 0v-2.631a.5.5 0 01.417-.493A8.51 8.51 0 0019.5 9.5V6a2 2 0 00-2-2 1 1 0 00-.333 1.943.5.5 0 01.333.472V9.5a6.5 6.5 0 01-13 0V6.415a.5.5 0 01.333-.472A1 1 0 004.5 4a2 2 0 00-2 2v3.5a8.51 8.51 0 007.083 8.376.5.5 0 01.417.493V21a3 3 0 006 0v-.5a1.492 1.492 0 011.554-1.487.5.5 0 01.379.2A1.964 1.964 0 0019.5 20a2 2 0 002-2z\"/><path d=\"M6.5 11.5A1.5 1.5 0 008 13h6a1.5 1.5 0 001.5-1.5v-10A1.5 1.5 0 0014 0H8a1.5 1.5 0 00-1.5 1.5zm2-9A.5.5 0 019 2h4a.5.5 0 01.5.5v7a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});