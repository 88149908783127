define("ember-svg-jar/inlined/monitor-heart-beat-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-heart-beat-search</title><path d=\"M23.408 19.09l-3.661-3.66a.251.251 0 01-.037-.306A8.917 8.917 0 0021 10.5 9 9 0 003.4 7.851a.5.5 0 00.47.649h1.055a.5.5 0 00.475-.334 7.013 7.013 0 11.19 5.134.5.5 0 00-.459-.3H4.05a.5.5 0 00-.468.677A9.009 9.009 0 0012 19.5a8.855 8.855 0 004.614-1.287.252.252 0 01.307.037l3.67 3.67a2 2 0 102.82-2.83z\"/><path d=\"M8.162 10.909l.623-1.167a.25.25 0 01.461.05l.994 3.548a1.565 1.565 0 001.36 1.16 1.227 1.227 0 00.136.006 1.57 1.57 0 001.416-.928l1.206-2.649a.25.25 0 01.441-.026l.591.974a1 1 0 101.71-1.038L15.82 8.73a1.538 1.538 0 00-2.736.171l-.962 2.114a.251.251 0 01-.247.145.248.248 0 01-.221-.182l-.949-3.386a1.575 1.575 0 00-1.311-1.154 1.533 1.533 0 00-1.559.831L6.512 9.751H1a1 1 0 000 2h5.79a1.554 1.554 0 001.372-.842z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});