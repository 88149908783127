define("ember-svg-jar/inlined/stove-gas-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>stove-gas-1</title><path d=\"M18.25 12.805a.5.5 0 01-.5-.5v-1.5a.75.75 0 011.5 0v1.5a.5.5 0 01-.5.5zM21.75 12.805a.5.5 0 01-.5-.5v-.5a.25.25 0 00-.25-.25.75.75 0 010-1.5 1.752 1.752 0 011.75 1.75v.5a.5.5 0 01-.5.5zM14.75 12.805a.5.5 0 01-.5-.5v-.5a1.752 1.752 0 011.75-1.75.75.75 0 010 1.5.25.25 0 00-.25.25v.5a.5.5 0 01-.5.5zM5.25 12.805a.5.5 0 01-.5-.5v-1.5a.75.75 0 011.5 0v1.5a.5.5 0 01-.5.5zM8.75 12.805a.5.5 0 01-.5-.5v-.5a.25.25 0 00-.25-.25.75.75 0 010-1.5 1.752 1.752 0 011.75 1.75v.5a.5.5 0 01-.5.5zM1.75 12.805a.5.5 0 01-.5-.5v-.5A1.752 1.752 0 013 10.055a.75.75 0 010 1.5.25.25 0 00-.25.25v.5a.5.5 0 01-.5.5zM18.377 1.719a.25.25 0 00-.318.359 3.049 3.049 0 01.239 2.6.251.251 0 01-.406.087 1.575 1.575 0 01-.41-.594.252.252 0 00-.348-.14 2.584 2.584 0 00-1.513 2.392 2.879 2.879 0 005.758 0 5.3 5.3 0 00-3.002-4.704zM5.377 1.719a.25.25 0 00-.318.359 3.049 3.049 0 01.239 2.6.251.251 0 01-.406.087 1.575 1.575 0 01-.41-.594.252.252 0 00-.348-.14 2.584 2.584 0 00-1.513 2.395 2.879 2.879 0 005.758 0 5.3 5.3 0 00-3.002-4.707zM22.5 14.305h-21a1.5 1.5 0 00-1.5 1.5v5a1.5 1.5 0 001.5 1.5h21a1.5 1.5 0 001.5-1.5v-5a1.5 1.5 0 00-1.5-1.5zm-17 5.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm13 0a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});