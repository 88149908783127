define("ember-svg-jar/inlined/tag-yuan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tag-yuan</title><path d=\"M22.5 0H14a2.9 2.9 0 00-2.061.854L.5 12.293a1.707 1.707 0 000 2.414L9.293 23.5a1.707 1.707 0 002.414 0l11.439-11.439A2.892 2.892 0 0024 10V1.5A1.5 1.5 0 0022.5 0zm-9.086 14.448A1 1 0 1112 15.862l-.95-.949a.249.249 0 00-.353 0l-1.561 1.561a1 1 0 01-1.414-1.414l1.561-1.56a.251.251 0 000-.354l-.95-.949a1 1 0 011.414-1.414l.95.949a.249.249 0 00.353 0l.184-.184a.248.248 0 00.041-.3A4.459 4.459 0 0112 5.893l1.833-1.833a1 1 0 011.414 1.414l-1.833 1.833a2.456 2.456 0 000 3.475 2.519 2.519 0 003.475 0l1.833-1.834a1 1 0 111.414 1.414L18.3 12.2a4.463 4.463 0 01-5.354.725.25.25 0 00-.3.041l-.183.183a.249.249 0 000 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});