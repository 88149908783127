define("ember-svg-jar/inlined/landmark-pagoda", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-pagoda</title><path d=\"M4 6.25h2.5a.5.5 0 01.5.5V8a.5.5 0 00.5.5h3.25a.5.5 0 00.5-.5v-.5a.75.75 0 011.5 0V8a.5.5 0 00.5.5h3.25A.5.5 0 0017 8V6.75a.5.5 0 01.5-.5H20a1 1 0 000-2h-1.334a.5.5 0 01-.3-.1L15.3 1.85a.5.5 0 00-.3-.1h-1.5a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v.25a.5.5 0 01-.5.5H9a.5.5 0 00-.3.1l-3.066 2.3a.5.5 0 01-.3.1H4a1 1 0 000 2zM4 14h2.5a.5.5 0 01.5.5V16a.5.5 0 00.5.5h3.25a.5.5 0 00.5-.5v-.5a.75.75 0 011.5 0v.5a.5.5 0 00.5.5h3.25a.5.5 0 00.5-.5v-1.5a.5.5 0 01.5-.5H20a1 1 0 000-2h-.919a.5.5 0 01-.391-.188l-1.3-1.624A.5.5 0 0017 10H7a.5.5 0 00-.391.188l-1.3 1.624a.5.5 0 01-.39.188H4a1 1 0 000 2zM21 19.5h-1.447a.5.5 0 01-.278-.084l-2-1.332A.5.5 0 0017 18H7a.5.5 0 00-.277.084l-2 1.332a.5.5 0 01-.278.084H3a1 1 0 000 2 .5.5 0 01.5.5v1.5a.5.5 0 00.5.5h2.25a.5.5 0 00.5-.5v-1a.75.75 0 011.5 0v1a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1a.75.75 0 011.5 0v1a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1a.75.75 0 011.5 0v1a.5.5 0 00.5.5H20a.5.5 0 00.5-.5V22a.5.5 0 01.5-.5 1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});