define("ember-svg-jar/inlined/real-estate-sign-board-rent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-sign-board-rent</title><path d=\"M5.625 14.609a.125.125 0 00.125.125.625.625 0 100-1.25.125.125 0 00-.125.125z\"/><path d=\"M23 11.359a2.5 2.5 0 00-2.5-2.5h-.881a.5.5 0 01-.361-.154l-5.689-5.926a2.247 2.247 0 00-3.134 0L4.742 8.705a.5.5 0 01-.361.154H3.5a2.5 2.5 0 00-2.5 2.5v8a2.5 2.5 0 002.5 2.5h17a2.5 2.5 0 002.5-2.5zM11.878 4.16a.176.176 0 01.246 0l4.1 4.274a.25.25 0 01-.181.423h-8.09a.25.25 0 01-.181-.423zM7.536 17.538a.625.625 0 11-1.072.642l-.375-.624a.25.25 0 00-.464.129v.174a.625.625 0 01-1.25 0v-5A.625.625 0 015 12.234h.75a1.872 1.872 0 01.976 3.472.251.251 0 00-.084.342zM11 15.234a.625.625 0 010 1.25H9.875a.25.25 0 00-.25.25v.125a.375.375 0 00.375.375h1a.625.625 0 010 1.25h-1a1.626 1.626 0 01-1.625-1.625v-3A1.627 1.627 0 0110 12.234h1a.625.625 0 010 1.25h-1a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25zm4.625 2.625a.625.625 0 01-1.2.233l-.313-.782a.25.25 0 00-.482.092v.457a.625.625 0 01-1.25 0v-5a.625.625 0 011.205-.232l.313.782a.25.25 0 00.482-.093v-.457a.625.625 0 011.25 0zM19 13.484h-.125a.25.25 0 00-.25.25v4.125a.625.625 0 01-1.25 0v-4.125a.25.25 0 00-.25-.25H17a.625.625 0 010-1.25h2a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});