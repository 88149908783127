define("ember-svg-jar/inlined/alert-triangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>alert-triangle</title><path d=\"M23.119 20L13.772 2.15a2 2 0 00-3.543 0L.881 20a2 2 0 001.772 2.928h18.694A2 2 0 0023.119 20zM11 8.423a1 1 0 012 0v6a1 1 0 11-2 0zm1.05 11.51h-.028a1.528 1.528 0 01-1.522-1.47 1.476 1.476 0 011.448-1.53h.028A1.527 1.527 0 0113.5 18.4a1.475 1.475 0 01-1.45 1.533z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});