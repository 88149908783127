define("ember-svg-jar/inlined/plane-trip-round", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-trip-round</title><path d=\"M2.475 13.655l.815.163a.5.5 0 01.393.392l.164.818a1.274 1.274 0 002.463.282l.6-1.8a.5.5 0 01.136-.208l.455-.42a.5.5 0 01.767.11l1.453 2.421a1.391 1.391 0 002.565-.762l-.215-5.8a.5.5 0 01.129-.355l2.517-2.774a2.075 2.075 0 00-2.93-2.931L9.013 5.3a.5.5 0 01-.354.13l-5.805-.215a1.384 1.384 0 00-.761 2.566l2.42 1.452a.5.5 0 01.11.768l-.419.455a.5.5 0 01-.21.134l-1.8.6a1.273 1.273 0 00.281 2.465z\"/><path d=\"M23.075.924c-1.266-1.266-3.538-1.224-6.459.091a1 1 0 00-.5 1.359.987.987 0 001.283.463c2.015-.906 3.637-1.106 4.252-.49C22.9 3.6 20.975 9.011 15 14.989a31 31 0 01-8.116 6.039c-2.15 1.02-3.883 1.256-4.524.615-.463-.464-.583-1.712.322-3.871a.992.992 0 10-1.832-.763C-.6 20.448.066 22.2.937 23.065c1.676 1.677 4.628.782 6.766-.232a33.118 33.118 0 008.697-6.447c5.361-5.368 9.526-12.61 6.675-15.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});