define("ember-svg-jar/inlined/whale-body-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>whale-body-1</title><path d=\"M9.5 2A1.5 1.5 0 0111 3.5V6a1 1 0 002 0V3.5A1.5 1.5 0 0114.5 2a1 1 0 000-2A3.485 3.485 0 0012 1.058 3.485 3.485 0 009.5 0a1 1 0 000 2z\"/><path d=\"M23.853 21.146l-3.33-3.33a8.694 8.694 0 00.445-2.685l-.007-6.578a3.594 3.594 0 001.775-1.52 4.858 4.858 0 00.549-2.64.5.5 0 00-.218-.376.5.5 0 00-.43-.064l-2.352.732L18.46 3.12a.5.5 0 00-.679.026 3.107 3.107 0 00-.611 2.214 4.037 4.037 0 00.86 2.428 2.545 2.545 0 01-1.685 1.667A11.132 11.132 0 0012.1 8.5c-4.2 0-8.872 2.724-8.872 6.631a8.675 8.675 0 00.4 2.547L.147 21.146a.5.5 0 00.076.77c1.7 1.151 4.467.969 5.16-1.02A8.611 8.611 0 0012.1 24a8.611 8.611 0 006.587-2.951c.818 1.9 3.49 1.955 5.093.867a.5.5 0 00.076-.77zm-17.47-2.8a.848.848 0 01.56-1.162 20.277 20.277 0 0110.141-.071.847.847 0 01.576 1.153 6.148 6.148 0 01-11.277.08zm.368-6.092a1 1 0 11-1 1 1 1 0 011-1.004zm10.5 2a1 1 0 111-1 1 1 0 01-1.001.996z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});