define("ember-svg-jar/inlined/style-three-pin-kayak-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-kayak-1</title><ellipse cx=\"12\" cy=\"13\" rx=\"2\" ry=\"2.5\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.749 7.3 12.286 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.245 22.5 9.5 22.5 4.262 17.79 0 12 0zm0 3.009c.53 0 1.575 1.213 2.463 2.994a.343.343 0 01-.307.5H10.1a.5.5 0 01-.443-.73c.86-1.656 1.836-2.764 2.343-2.764zm0 16.5c-1 0-4-3.029-4-7.5a11.182 11.182 0 01.214-2.1.5.5 0 01.491-.4H15.3a.5.5 0 01.491.4 11.182 11.182 0 01.214 2.1C16 16.48 13 19.509 12 19.509zM20.5 8.5a1 1 0 01-1 1h-2a1 1 0 01-.943-.666.5.5 0 00-.471-.334H7.914a.5.5 0 00-.471.334A1 1 0 016.5 9.5h-2a1 1 0 01-1-1v-1a1 1 0 011-1h2a1 1 0 01.943.667.5.5 0 00.471.333h8.172a.5.5 0 00.471-.333A1 1 0 0117.5 6.5h2a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});