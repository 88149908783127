define("ember-svg-jar/inlined/fast-food-pizza-slice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fast-food-pizza-slice</title><path d=\"M23.875 8.616c-1.194-3.416-4.362-6.062-8.942-7.448a22.588 22.588 0 00-1.318-.363 2.871 2.871 0 00-2.279.385A2.545 2.545 0 0010.2 3.667a3.391 3.391 0 00.182.656c.025.062.049.2-.007.263C9.378 5.663.139 15.687.114 15.718a.763.763 0 00-.062.7c0 .011.582 1.869.582 1.869a1 1 0 00.492.586 1.259 1.259 0 00.762.067l4.53-1.427a.25.25 0 01.325.238v.748a2.542 2.542 0 002.19 2.57 2.446 2.446 0 002.7-2.43v-2.584a.25.25 0 01.175-.238s.959-.3 1.122-.353.254.026.254.161v5.067a2.543 2.543 0 002.189 2.574 2.445 2.445 0 002.7-2.429v-6.809a.251.251 0 01.175-.239l3.834-1.208a2.826 2.826 0 001.711-1.59 4.321 4.321 0 00.082-2.375zm-6.057 1.193a2.49 2.49 0 00-1.748 2.385v8.643a.444.444 0 01-.888 0v-6.413a2.776 2.776 0 10-5.551 0v4.216a.443.443 0 11-.886 0v-2.564a2.5 2.5 0 00-3.255-2.385s-.331.107-.563.178-.323-.123-.165-.294l2.554-2.754c.088-.095.179-.143.571-.115a4.8 4.8 0 003.654-1.79A2.683 2.683 0 0012 6.525a2.223 2.223 0 00-.187-.42.145.145 0 01.01-.145.121.121 0 01.141-.021c.174.058.539.174.625.2a8.712 8.712 0 015.32 3.461.128.128 0 01-.091.209zm2.662-2.867a2.693 2.693 0 00-.769.343 3.054 3.054 0 00-.517.381.188.188 0 01-.232-.02A12.625 12.625 0 0012.7 4.1a.9.9 0 01-.491-.553.679.679 0 01.155-.628.8.8 0 01.712-.186c.425.1 5 1.36 7.434 4.03.049.051.034.165-.03.179z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});