define("ember-svg-jar/inlined/natural-disaster-fire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>natural-disaster-fire</title><path d=\"M21.619 13.113a1 1 0 00-.592-.888 1.018 1.018 0 00-1.059.14 8.116 8.116 0 01-2.39 1.38.25.25 0 01-.293-.373l3.6-5.429A1.418 1.418 0 0018.9 6l-4.2 2.913a.25.25 0 01-.357-.334 28.576 28.576 0 015.1-5.991A1.485 1.485 0 0018.009.065C12.07 1.86 4.242 8.6 2.653 14.921a6.68 6.68 0 001.6 6.688A7.72 7.72 0 009.816 24a9.619 9.619 0 003.834-.855c4.364-1.895 8.088-6.584 7.969-10.032zm-7.029 1.929a7.316 7.316 0 01-2.736 4.76 3.707 3.707 0 01-5.235-.523 4.048 4.048 0 010-5.716 3.785 3.785 0 012.461-1.184.516.516 0 01.512.573l-.26 2.34a.5.5 0 00.446.559 3.88 3.88 0 003.463-1.411.772.772 0 011.349.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});