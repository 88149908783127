define("ember-svg-jar/inlined/read-maths", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-maths</title><path d=\"M12 8a1 1 0 001 1h5a1 1 0 100-2h-5a1 1 0 00-1 1z\"/><circle cx=\"15.5\" cy=\"5.001\" r=\"1\"/><circle cx=\"15.5\" cy=\"11.001\" r=\"1\"/><path d=\"M7.5 4.5h1A.5.5 0 019 5v1a1 1 0 102 0V5a.5.5 0 01.5-.5h1a1 1 0 000-2h-1A.5.5 0 0111 2V1a1 1 0 10-2 0v1a.5.5 0 01-.5.5h-1a1 1 0 100 2z\"/><path d=\"M23.55 6.437a1.592 1.592 0 00-1.075-.431c-.354.006-.7.018-1.027.035a1 1 0 00-.947 1.05 1.01 1.01 0 001.051.947.427.427 0 01.448.427v9.573a.5.5 0 01-.475.5 18.324 18.324 0 00-7.79 1.895.5.5 0 01-.735-.442V13a1 1 0 00-2 0v6.994a.5.5 0 01-.735.441 18.319 18.319 0 00-7.789-1.895.5.5 0 01-.476-.5v-9.5a.5.5 0 01.528-.5 19.96 19.96 0 014.46.716 1 1 0 10.561-1.92 23.038 23.038 0 00-6.017-.832h-.025a1.518 1.518 0 00-1.057.435A1.492 1.492 0 000 7.509v11.5a1.508 1.508 0 001.482 1.495c4.681.078 7.32 1.284 8.629 2.163a6.015 6.015 0 011.143 1 1 1 0 001.493 0 6.009 6.009 0 011.142-1c1.31-.879 3.948-2.085 8.629-2.163A1.508 1.508 0 0024 19.01V7.508a1.51 1.51 0 00-.45-1.071z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});