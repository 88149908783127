define("ember-svg-jar/inlined/monitor-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-alternate</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v16A1.5 1.5 0 001.5 19h21a1.5 1.5 0 001.5-1.5v-16A1.5 1.5 0 0022.5 0zM2 14.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v12a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5zM16.449 23.213l-2.1-2.532a.5.5 0 00-.385-.181h-3.93a.5.5 0 00-.385.181l-2.1 2.532A.5.5 0 007.96 24h8.079a.5.5 0 00.41-.787z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});