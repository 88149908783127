define("ember-svg-jar/inlined/smiley-indiferent-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-indiferent-1-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M17 15H7a1 1 0 000 2h10a1 1 0 000-2zM9.5 10a1 1 0 000-2h-3a1 1 0 000 2zM17.5 8h-3a1 1 0 000 2h3a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});