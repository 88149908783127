define("ember-svg-jar/inlined/smiley-sad-nerd-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-sad-nerd-alternate</title><path d=\"M22.75 14.55a1 1 0 00-1.267.63 10 10 0 01-18.966 0 1 1 0 00-1.9.637 12.005 12.005 0 0022.76 0 1 1 0 00-.627-1.267zM8.083 2.716a.99.99 0 00.356-.065 10.087 10.087 0 016.55-.2 1 1 0 00.6-1.909 12.1 12.1 0 00-7.86.238 1 1 0 00.356 1.934z\"/><path d=\"M5.75 14.748a5.758 5.758 0 005.624-4.548.25.25 0 01.244-.2h.763a.251.251 0 01.245.2 5.751 5.751 0 100-2.395.25.25 0 01-.245.2h-.762a.25.25 0 01-.245-.2 5.75 5.75 0 10-5.624 6.943zm12.5-9.5A3.75 3.75 0 1114.5 9a3.755 3.755 0 013.75-3.752zm-12.5 0A3.75 3.75 0 112 9a3.755 3.755 0 013.75-3.752z\"/><path d=\"M12 15a6.486 6.486 0 00-5.448 2.954 1 1 0 001.676 1.093 4.5 4.5 0 017.544 0 1 1 0 00.839.453 1 1 0 00.837-1.546A6.486 6.486 0 0012 15z\"/><circle cx=\"7\" cy=\"8.498\" r=\"1.5\"/><circle cx=\"19.5\" cy=\"8.498\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});