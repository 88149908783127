define("ember-svg-jar/inlined/professions-woman-bellboy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-woman-bellboy</title><path d=\"M.25 23.125a.75.75 0 00.75.75h10.25a.25.25 0 00.25-.25v-6.55a.25.25 0 00-.222-.248 6.443 6.443 0 01-1.925-.527.25.25 0 00-.353.227v1.054a.25.25 0 01-.227.249 16.3 16.3 0 00-7.459 2.764A2.713 2.713 0 00.25 22.769zM12.5 23.625a.25.25 0 00.25.25H23a.75.75 0 00.75-.75v-.356a2.715 2.715 0 00-1.065-2.176 16.309 16.309 0 00-7.458-2.763.25.25 0 01-.227-.249v-1.02a.25.25 0 00-.348-.23 6.666 6.666 0 01-1.929.5.25.25 0 00-.223.249z\"/><circle cx=\"14\" cy=\"9.375\" r=\"1\"/><circle cx=\"10\" cy=\"9.375\" r=\"1\"/><path d=\"M12.082 12.994a2.976 2.976 0 00.863-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2h-2a.639.639 0 00-.3 1.2 2.31 2.31 0 00.435.18 2.99 2.99 0 00.867.126z\"/><path d=\"M11.786 16.122h.217A6.926 6.926 0 0015.8 15a.252.252 0 01.275 0 4.4 4.4 0 002.389.622 3.8 3.8 0 002.921-1.12.5.5 0 00-.231-.8C18.536 12.867 19 9.986 19 3.756A2.977 2.977 0 0017.182 1 13.511 13.511 0 0012 .125 13.511 13.511 0 006.818 1 2.977 2.977 0 005 3.756c0 6.311.449 9.056-2.153 9.892a.5.5 0 00-.327.337.5.5 0 00.1.46 3.8 3.8 0 002.921 1.119 4.635 4.635 0 002.387-.619.251.251 0 01.279 0 6.723 6.723 0 003.579 1.177zM12 3.375a13.571 13.571 0 014.839.751.25.25 0 01.161.233v1.089a.25.25 0 01-.352.228A11.368 11.368 0 0012 4.875a11.252 11.252 0 00-4.69.825.219.219 0 01-.31-.2V4.359a.25.25 0 01.161-.233A13.571 13.571 0 0112 3.375zm-5 5.5V8a.25.25 0 01.138-.224A10.844 10.844 0 0112 6.875a10.859 10.859 0 014.862.9A.251.251 0 0117 8v1.125a5 5 0 01-5.155 5A5.171 5.171 0 017 8.874z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});