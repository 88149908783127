define("ember-svg-jar/inlined/car-dashboard-seat-adjust", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-dashboard-seat-adjust</title><circle cx=\"21.125\" cy=\"3.75\" r=\"2.5\"/><path d=\"M4.733 22.75a1.358 1.358 0 001.153-.668l3.375-5.49a1.507 1.507 0 011.277-.741 1.418 1.418 0 01.485.086l3.285 1.188a3 3 0 001.021.18 3.211 3.211 0 002.887-1.935l2.513-5.449a1.542 1.542 0 00-.836-2.108 1.2 1.2 0 00-.38-.062 1.476 1.476 0 00-1.292.9l-2.06 4.468a1.534 1.534 0 01-1.379.925 1.439 1.439 0 01-.488-.086L11.12 12.81a2.982 2.982 0 00-1.02-.18 3.168 3.168 0 00-2.686 1.558l-3.779 6.145a1.667 1.667 0 00.216 2.067 1.318 1.318 0 00.882.35zM3.021 14.666a.5.5 0 00.707 0l2.5-2.5a.5.5 0 00-.353-.853h-1a.25.25 0 01-.25-.25v-2.5a1.25 1.25 0 10-2.5 0v2.5a.25.25 0 01-.25.25h-1a.5.5 0 00-.354.853zM8.375 6.313h3a.25.25 0 01.25.25v1a.5.5 0 00.308.462.513.513 0 00.192.038.5.5 0 00.353-.147l2.5-2.5a.5.5 0 000-.707l-2.5-2.5a.506.506 0 00-.545-.108.5.5 0 00-.308.462v1a.25.25 0 01-.25.25h-3a1.25 1.25 0 000 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});