define("ember-svg-jar/inlined/shell-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shell-2</title><path d=\"M18 19.75H6a.5.5 0 00-.5.5v1.5a1.5 1.5 0 001.5 1.5h10a1.5 1.5 0 001.5-1.5v-1.5a.5.5 0 00-.5-.5zM12 .75A11.513 11.513 0 00.5 12.25c0 .129 0 .341.009.569a2.5 2.5 0 00.928 1.888l4.249 3.432A.5.5 0 006 18.25h12a.5.5 0 00.314-.111l4.248-3.432a2.5 2.5 0 00.929-1.888c.006-.228.009-.44.009-.569A11.513 11.513 0 0012 .75zm-1.353 13.735a.753.753 0 01-.882-.588l-1.5-7.5a.75.75 0 111.47-.297l1.5 7.5a.749.749 0 01-.588.885zm-5.011-4.132l2.5 4a.75.75 0 01-1.272.794l-2.5-4a.75.75 0 011.272-.794zm7.129 3.25l1.5-7.5a.75.75 0 111.47.294l-1.5 7.5a.75.75 0 11-1.47-.294zm3.335 1.783a.75.75 0 01-.239-1.033l2.5-4a.75.75 0 011.272.794l-2.5 4a.751.751 0 01-1.033.239z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});