define("ember-svg-jar/inlined/cleaning-broom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cleaning-broom</title><circle cx=\"22.25\" cy=\"22.853\" r=\"1\"/><circle cx=\"18.25\" cy=\"19.853\" r=\"1\"/><circle cx=\"13.25\" cy=\"22.853\" r=\"1\"/><circle cx=\"8.75\" cy=\"19.853\" r=\"1\"/><circle cx=\"4.75\" cy=\"22.853\" r=\"1\"/><circle cx=\"1.75\" cy=\"19.853\" r=\"1\"/><path d=\"M17.658 9.63a5.251 5.251 0 00-6.326-.84.5.5 0 01-.6-.079L2.457.44a1 1 0 00-1.414 1.414l8.275 8.274a.5.5 0 01.079.6 5.254 5.254 0 00.836 6.324l2.652 2.652a.5.5 0 00.707 0l.354-.354a.5.5 0 000-.707L12 16.7a.75.75 0 111.061-1.06l1.944 1.944a.5.5 0 00.708 0l.353-.353a.5.5 0 000-.707l-1.944-1.944a.75.75 0 011.061-1.06l1.945 1.944a.5.5 0 00.707 0l.353-.354a.5.5 0 000-.707l-1.944-1.945a.75.75 0 011.061-1.06l1.944 1.945a.5.5 0 00.707 0l.353-.354a.5.5 0 000-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});