define("ember-svg-jar/inlined/cleaning-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cleaning-sign</title><path d=\"M10.957 15.925a.25.25 0 00.095.413A2.884 2.884 0 0012 16.5a3 3 0 003-3 2.888 2.888 0 00-.162-.947.255.255 0 00-.181-.162.25.25 0 00-.232.068zM13 11.059a.251.251 0 00-.1-.415 2.9 2.9 0 00-.9-.144 3 3 0 00-3 3 2.9 2.9 0 00.144.9.25.25 0 00.414.1zM9 4h6a1 1 0 001-1V.5a.5.5 0 00-.5-.5h-7a.5.5 0 00-.5.5V3a1 1 0 001 1z\"/><path d=\"M20.067.851V.829A1 1 0 0019.078 0H18a.5.5 0 00-.5.5v4a2 2 0 01-2 2h-7a2 2 0 01-2-2v-4A.5.5 0 006 0H4.92a1 1 0 00-.989.851L1.108 22.828A1 1 0 002.094 24h4.045a1 1 0 00.948-.684l.659-1.974A.5.5 0 018.22 21h7.559a.5.5 0 01.474.342l.657 1.974a1 1 0 00.95.684h4.045a1 1 0 00.765-.356.982.982 0 00.223-.793zM17 13.5a5 5 0 01-8.54 3.527l-.011-.007c-.01-.009-.013-.022-.021-.031a4.992 4.992 0 017.058-7.061c.01.009.023.012.033.021l.007.012A4.984 4.984 0 0117 13.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});