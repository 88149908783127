define("ember-svg-jar/inlined/toys-ball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toys-ball</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm8.18 6.255a.25.25 0 01-.249.39 3.3 3.3 0 01-1.731-.854 3.3 3.3 0 01-.85-1.722.25.25 0 01.39-.249 10.074 10.074 0 012.44 2.435zM5.582 18.983a.5.5 0 01-.566-.566C6.2 10.205 11.176 7.2 15.625 6.2a.5.5 0 01.53.217 4.734 4.734 0 001.43 1.43.5.5 0 01.217.53C16.8 12.824 13.793 17.8 5.582 18.983zM2 12a10 10 0 0112.968-9.55.5.5 0 01.35.517 5.826 5.826 0 00-.006.825.5.5 0 01-.381.517A14.906 14.906 0 003.584 15.457a.5.5 0 01-.951.045A9.948 9.948 0 012 12zm20 0a10 10 0 01-13.5 9.367.5.5 0 01.045-.951A14.9 14.9 0 0019.691 9.069a.5.5 0 01.517-.381 5.688 5.688 0 00.825-.006.5.5 0 01.517.35A9.971 9.971 0 0122 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});