define("ember-svg-jar/inlined/team-meeting-message-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>team-meeting-message-question</title><path d=\"M5 10h6.25a.25.25 0 01.25.25v2.25a.5.5 0 00.309.462A.493.493 0 0012 13a.5.5 0 00.354-.146l2.78-2.783a.25.25 0 01.177-.071H19a1.5 1.5 0 001.5-1.5v-7A1.5 1.5 0 0019 0H5a1.5 1.5 0 00-1.5 1.5v7A1.5 1.5 0 005 10zm6.5-2.5a1 1 0 111 1 1 1 0 01-1-1zm1-1.49a.625.625 0 010-1.25 1.125 1.125 0 10-1.125-1.125.625.625 0 01-1.25 0A2.375 2.375 0 1112.5 6.009z\"/><path d=\"M23.5 22.5a.5.5 0 00-.324-.468l-3.352-1.257a.5.5 0 01-.324-.467v-.931a.479.479 0 01.108-.31A5.8 5.8 0 0021 15.5c0-2.445-1.324-3.978-3.455-4a3.425 3.425 0 00-2.907 1.69c-1.566 2.709.183 5.186.757 5.881a.473.473 0 01.105.306v.931a.5.5 0 01-.324.467l-3.088 1.158a.243.243 0 01-.176 0l-3.088-1.159a.5.5 0 01-.324-.467v-.931a.479.479 0 01.108-.31A5.8 5.8 0 0010 15.5c0-2.445-1.324-3.978-3.455-4a3.476 3.476 0 00-2.907 1.69c-1.566 2.709.183 5.186.757 5.881a.473.473 0 01.105.306v.931a.5.5 0 01-.324.467L.824 22.031A.5.5 0 00.5 22.5v1a.5.5 0 00.5.5h22a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});