define("ember-svg-jar/inlined/chef-gear-tacos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chef-gear-tacos</title><path d=\"M14 9a.5.5 0 00-.5-.5H.5A.5.5 0 000 9a7 7 0 0014 0zM3.75 12A1.25 1.25 0 115 10.75 1.25 1.25 0 013.75 12zm3.75 2.5a1.25 1.25 0 111.25-1.25A1.25 1.25 0 017.5 14.5zm3-3.25a.75.75 0 11.75-.75.75.75 0 01-.75.75zM14.62 2.462c0 3.533 1.748 6 4.25 6h.88c2.5 0 4.25-2.467 4.25-6 0-3.283-9.38-3.283-9.38 0zm4.69 1c-2.093 0-3.19-.743-3.19-1s1.1-1 3.19-1 3.19.743 3.19 1-1.1 1-3.19 1zM19.339 12.054l-9.193 9.192a.5.5 0 000 .707 7 7 0 009.9-9.9.5.5 0 00-.707.001zm-4.455 9.97a1.25 1.25 0 110-1.767 1.249 1.249 0 010 1.767zm3.735-1.972a1.25 1.25 0 110-1.768 1.251 1.251 0 010 1.768zm.879-3.743a.75.75 0 110-1.061.75.75 0 01.002 1.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});