define("ember-svg-jar/inlined/tags-search-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-search-alternate</title><circle cx=\"17.17\" cy=\"5.999\" r=\"1.5\"/><path d=\"M10.578 16.528a5.523 5.523 0 008.5 4.66.25.25 0 01.311.034l2.484 2.484a1 1 0 101.414-1.414L20.8 19.808a.251.251 0 01-.034-.312 5.527 5.527 0 10-10.189-2.968zm9.058 0A3.529 3.529 0 1116.107 13a3.533 3.533 0 013.529 3.528z\"/><path d=\"M21.17 0H14a2.98 2.98 0 00-2.122.879l-11 11a3 3 0 000 4.242l6.172 6.172a3 3 0 004.242 0l.138-.137a.251.251 0 00-.013-.366 6.985 6.985 0 01-.991-1.057.251.251 0 00-.376-.026l-.172.172a1.025 1.025 0 01-1.414 0l-6.173-6.173a1 1 0 010-1.414l11-11A1 1 0 0114 2h6.67a.5.5 0 01.5.5v6.67a1 1 0 01-.293.708l-.67.67a.249.249 0 00.026.376 7.077 7.077 0 011.056.991.255.255 0 00.18.086.252.252 0 00.186-.073l.636-.636a2.978 2.978 0 00.879-2.122V2a2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});