define("ember-svg-jar/inlined/advertising-megaphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>advertising-megaphone</title><path d=\"M7.653 11.992a1.5 1.5 0 013 0v3.5a.389.389 0 00.586.336 23.287 23.287 0 003.746-2.6.5.5 0 00.168-.374v-1.862a.5.5 0 01.5-.5h1.5a1 1 0 00.726-1.686c-.022-.025-6.649-7.058-8-8.5a1.032 1.032 0 00-1.459 0l-8 8.5a1 1 0 00.726 1.688h1.5a.5.5 0 01.5.5v4a1 1 0 001 1h3a.5.5 0 00.5-.5zM23.73 18.206l-3.776-7.087a1 1 0 00-1.679-.134 18.241 18.241 0 01-6.644 5.774.817.817 0 00-.276 1.035l2.425 4.531a3.143 3.143 0 004.257 1.3.75.75 0 00-.706-1.324 1.64 1.64 0 01-1.254.123 1.667 1.667 0 01-.461-.223.5.5 0 01.082-.867 17.015 17.015 0 017.212-1.657 1 1 0 00.82-1.471zM10.36 18.055a.5.5 0 00-.676-.2l-.595.321a2.9 2.9 0 102.757 5.1l.576-.313a.5.5 0 00.2-.676z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});