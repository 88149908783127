define("ember-svg-jar/inlined/paralympics-racing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paralympics-racing</title><circle cx=\"12\" cy=\"3.25\" r=\"2.5\"/><path d=\"M17.451 18.5l.579 2.313a1 1 0 001.94-.484l-1.455-5.821a1 1 0 10-1.941.485l.5 2.007H13.75a.25.25 0 01-.25-.25v-1.5a1.5 1.5 0 00-3 0v1.5a.25.25 0 01-.25.25H6.924l.5-2.007a1 1 0 10-1.941-.485L4.03 20.329a1 1 0 101.94.484l.579-2.313h3.7a.25.25 0 01.25.25v3a1.5 1.5 0 003 0v-3a.25.25 0 01.25-.25z\"/><path d=\"M21.648 11.508a7.015 7.015 0 00-6.456-4.258H8.808a7.015 7.015 0 00-6.456 4.258 1.249 1.249 0 002.3.984A4.521 4.521 0 018.808 9.75H9v3.75a.25.25 0 00.25.25h.027a.25.25 0 00.211-.115 2.971 2.971 0 015.024 0 .25.25 0 00.211.115h.027a.25.25 0 00.25-.25V9.75h.192a4.521 4.521 0 014.16 2.742 1.249 1.249 0 002.3-.984z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});