define("ember-svg-jar/inlined/diagram-arrow-dash-corner-point-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-arrow-dash-corner-point-left</title><path d=\"M.469 8.572l6.631 6.16a1 1 0 00.68.268 1.017 1.017 0 00.4-.083 1 1 0 00.6-.917v-3.25a.25.25 0 01.25-.25h8.72a.25.25 0 01.25.25V14a1 1 0 001 1h4a1 1 0 001-1V6.5a2 2 0 00-2-2H9.03a.25.25 0 01-.25-.25V1A1 1 0 007.1.267L.461 6.434a1.461 1.461 0 00.008 2.138z\"/><rect x=\"18\" y=\"16.5\" width=\"6\" height=\"3\" rx=\"1\" ry=\"1\"/><rect x=\"18\" y=\"21\" width=\"6\" height=\"3\" rx=\"1\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});