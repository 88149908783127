define("ember-svg-jar/inlined/single-man-actions-setting.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-setting</title><path d=\"M7 10a5 5 0 10-5-5 5.006 5.006 0 005 5zM4.1 4.257a.5.5 0 01.741-.3A6.956 6.956 0 008.459 5a6.36 6.36 0 00.929-.07.5.5 0 01.569.564A3 3 0 014 5a2.8 2.8 0 01.1-.743zM10.448 12.789a3.141 3.141 0 01.433-.58.5.5 0 00-.124-.781 7.371 7.371 0 00-3.552-.928 7.542 7.542 0 00-7.184 5.357.5.5 0 00.479.643h9.348a.5.5 0 00.455-.709 3.25 3.25 0 01.145-3.002z\"/><circle cx=\"17.748\" cy=\"17.031\" r=\"1.5\"/><path d=\"M23.516 15.654a1.783 1.783 0 00-1.7-2.953l-1.411.326a.452.452 0 01-.533-.31l-.424-1.393a1.776 1.776 0 00-3.4 0l-.424 1.393a.453.453 0 01-.533.31l-1.41-.327a1.783 1.783 0 00-1.7 2.952l.988 1.066a.463.463 0 010 .625l-.988 1.064a1.758 1.758 0 00-.234 2.113 1.739 1.739 0 001.935.84l1.411-.326a.451.451 0 01.533.311l.424 1.392a1.775 1.775 0 003.4 0l.424-1.392a.45.45 0 01.533-.311l1.411.326a1.782 1.782 0 001.7-2.952l-.987-1.065a.463.463 0 010-.625zm-5.768 4.377a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});