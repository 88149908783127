define("ember-svg-jar/inlined/accessories-necklace-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accessories-necklace-1</title><path d=\"M20.125 15.509a.5.5 0 01-.125-.331V10.2a2.044 2.044 0 01.053-.461 5.267 5.267 0 00.137-1.189A8.385 8.385 0 0012 0a8.385 8.385 0 00-8.19 8.55 5.267 5.267 0 00.137 1.191A2.044 2.044 0 014 10.2v4.976a.5.5 0 01-.125.331 1.5 1.5 0 102.25 0A.5.5 0 016 15.179V14.6a.25.25 0 01.448-.153q.217.282.44.555a.5.5 0 01.112.313v3.863a.5.5 0 01-.125.331 1.5 1.5 0 102.25 0 .5.5 0 01-.125-.33v-1.238a.25.25 0 01.425-.179c.559.548 1.051 1 1.407 1.314a.5.5 0 01.168.374v1.728a.5.5 0 01-.125.331 1.5 1.5 0 102.25 0 .5.5 0 01-.125-.331V19.45a.5.5 0 01.168-.374c.356-.316.848-.766 1.407-1.314a.25.25 0 01.425.179v1.238a.5.5 0 01-.125.33 1.5 1.5 0 102.25 0 .5.5 0 01-.125-.331v-3.863a.5.5 0 01.112-.315q.222-.273.44-.555A.25.25 0 0118 14.6v.582a.5.5 0 01-.125.33 1.5 1.5 0 102.25 0zm-7.785 1.617a.5.5 0 01-.68 0c-2.233-2.071-5.85-5.974-5.85-8.576A6.384 6.384 0 0112 2a6.384 6.384 0 016.19 6.55c0 2.602-3.617 6.505-5.85 8.576z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});