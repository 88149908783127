define("ember-svg-jar/inlined/gauge-dashboard-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gauge-dashboard-alternate</title><path d=\"M12 3.348a12.013 12.013 0 00-12 12v2.391a2.916 2.916 0 002.913 2.913h18.174A2.916 2.916 0 0024 17.739v-2.391a12.013 12.013 0 00-12-12zm9.087 15.3H2.913A.914.914 0 012 17.739V16.4h2.5a.75.75 0 000-1.5H2.01a9.922 9.922 0 01.841-3.589l2.029 1.174a.75.75 0 00.75-1.3l-2.07-1.2a10.052 10.052 0 012.477-2.66l1.13 1.957a.762.762 0 001.025.275.75.75 0 00.274-1.025L7.305 6.521a9.911 9.911 0 013.945-1.145v2.276a.75.75 0 101.5 0V5.376a10.006 10.006 0 017.69 4.615l-2.07 1.2a.75.75 0 10.75 1.3l2.029-1.172a9.922 9.922 0 01.841 3.581H19.5a.75.75 0 100 1.5H22v1.337a.914.914 0 01-.913.915z\"/><path d=\"M16.565 8.125a.749.749 0 00-1.037.221l-3 4.619a2.254 2.254 0 101.257.818l3-4.62a.751.751 0 00-.22-1.038z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});