define("ember-svg-jar/inlined/notes-paper-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-paper-text</title><path d=\"M18.707 9.207l1-1a1 1 0 10-1.414-1.414l-1 1a1 1 0 01-1.415 0 3.072 3.072 0 00-4.242 0 1 1 0 01-1.414 0 3.073 3.073 0 00-4.243 0l-1 1a1 1 0 001.415 1.414l1-1a1.024 1.024 0 011.414 0 3 3 0 004.242 0 1.024 1.024 0 011.414 0 3 3 0 004.243 0zM11.636 13.793a1 1 0 01-1.414 0 3.073 3.073 0 00-4.243 0l-1 1a1 1 0 001.415 1.414l1-1a1.024 1.024 0 011.414 0 3 3 0 004.242 0 1 1 0 00-1.414-1.414z\"/><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h14a1 1 0 00.707-.293l7-7A1 1 0 0024 16zM2 2.5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5V15a.5.5 0 01-.5.5h-4a2 2 0 00-2 2v4a.5.5 0 01-.5.5H2.5a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});