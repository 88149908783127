define("ember-svg-jar/inlined/single-neutral-actions-music", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-music</title><path d=\"M0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 00-11 0z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M13.5 23.75a2.5 2.5 0 002.5-2.5v-7.463a.251.251 0 01.2-.246l5.5-1.031a.25.25 0 01.3.246v3.755a.252.252 0 01-.273.249 2.447 2.447 0 00-.227-.01 2.5 2.5 0 102.5 2.5v-6.795a2 2 0 00-2.368-1.966l-6 1.125A2 2 0 0014 13.58v4.931a.252.252 0 01-.273.249 2.447 2.447 0 00-.227-.01 2.5 2.5 0 000 5zM0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 00-11 0z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M13.5 23.75a2.5 2.5 0 002.5-2.5v-7.463a.251.251 0 01.2-.246l5.5-1.031a.25.25 0 01.3.246v3.755a.252.252 0 01-.273.249 2.447 2.447 0 00-.227-.01 2.5 2.5 0 102.5 2.5v-6.795a2 2 0 00-2.368-1.966l-6 1.125A2 2 0 0014 13.58v4.931a.252.252 0 01-.273.249 2.447 2.447 0 00-.227-.01 2.5 2.5 0 000 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});