define("ember-svg-jar/inlined/folder-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-star</title><path d=\"M12.258 13h1.023a.5.5 0 00.458-.3c.254-.588.8-1.843.81-1.87a3.294 3.294 0 015.888-.031c0 .01.07.158.153.351a.207.207 0 00.41-.026V4.5A1.5 1.5 0 0019.5 3H9.619a.5.5 0 01-.447-.277L8.225.83A1.5 1.5 0 006.882 0H1.5A1.5 1.5 0 000 1.5v15A1.5 1.5 0 001.5 18h7.633a.25.25 0 00.215-.372A3.171 3.171 0 019.243 15a3.236 3.236 0 013.015-2z\"/><path d=\"M23.608 17.139a1.264 1.264 0 00.3-1.378A1.233 1.233 0 0022.739 15h-2.5a.25.25 0 01-.23-.151l-1.344-3.116-.013-.028a1.294 1.294 0 00-2.3 0l-.013.028-1.344 3.116a.249.249 0 01-.229.151h-2.5a1.233 1.233 0 00-1.169.762 1.262 1.262 0 00.3 1.378l2.203 1.97a.249.249 0 01.063.286l-1.224 2.816a1.256 1.256 0 00.316 1.45 1.277 1.277 0 001.506.166l3.113-1.752a.249.249 0 01.245 0l3.112 1.752a1.277 1.277 0 001.506-.166 1.254 1.254 0 00.315-1.45L21.333 19.4a.249.249 0 01.063-.286z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});