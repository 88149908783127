define("ember-svg-jar/inlined/road-sign-speed-limit-10-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-speed-limit-10-alternate</title><path d=\"M11.015 21.25a.25.25 0 01-.25-.25v-3.5a1.25 1.25 0 00-2-1l-1.2.9a.75.75 0 00.9 1.2l.4-.3a.25.25 0 01.4.2V21a.25.25 0 01-.25.25.75.75 0 000 1.5h2a.75.75 0 000-1.5zM14.515 16.25a2.253 2.253 0 00-2.25 2.25v2a2.25 2.25 0 004.5 0v-2a2.253 2.253 0 00-2.25-2.25zm.75 4.25a.75.75 0 01-1.5 0v-2a.75.75 0 011.5 0zM8.5 8.75a.75.75 0 00-.75.75v5a.75.75 0 001.5 0v-5a.75.75 0 00-.75-.75zM16.5 8.75a.75.75 0 00-.75.75v5a.75.75 0 001.5 0v-5a.75.75 0 00-.75-.75zM20.5 8.75h-2a.75.75 0 000 1.5.25.25 0 01.25.25v4a.75.75 0 001.5 0v-4a.25.25 0 01.25-.25.75.75 0 000-1.5zM7 7.75A.75.75 0 007.75 7V5.938a.249.249 0 01.2-.245A2.244 2.244 0 007.5 1.25H7a.75.75 0 00-.75.75v5a.75.75 0 00.75.75zm1-4.5a.25.25 0 11-.25.25.25.25 0 01.25-.25zM6.5 13.75h-1a.25.25 0 01-.25-.25v-4a.75.75 0 00-1.5 0v4a1.752 1.752 0 001.75 1.75h1a.75.75 0 000-1.5zM5.75 6a2.553 2.553 0 00-1.3-2.1c-.508-.381-.7-.557-.7-.9A.25.25 0 014 2.75h1a.75.75 0 000-1.5H4A1.752 1.752 0 002.25 3a2.553 2.553 0 001.3 2.1c.508.381.7.557.7.9a.25.25 0 01-.25.25H3a.75.75 0 000 1.5h1A1.752 1.752 0 005.75 6zM13.143 12.386a.058.058 0 01.107.029V14.5a.75.75 0 001.5 0v-5a.75.75 0 00-1.393-.386l-.643 1.071a.25.25 0 01-.428 0l-.643-1.071a.75.75 0 00-1.393.386v5a.75.75 0 001.5 0v-2.085a.058.058 0 01.107-.029.78.78 0 001.286 0zM19 1.25a.75.75 0 00-.75.75v5a.75.75 0 00.75.75A2.752 2.752 0 0021.75 5V4A2.752 2.752 0 0019 1.25zM20.25 5a.25.25 0 01-.5 0V4a.25.25 0 01.5 0zM12 7.75h1a.75.75 0 000-1.5h-1a.25.25 0 010-.5h1a.75.75 0 000-1.5h-1a.25.25 0 01-.25-.25V3a.25.25 0 01.25-.25h1a.75.75 0 000-1.5h-1A1.752 1.752 0 0010.25 3v3A1.752 1.752 0 0012 7.75zM17 6.25h-1a.25.25 0 010-.5h1a.75.75 0 000-1.5h-1a.25.25 0 01-.25-.25V3a.25.25 0 01.25-.25h1a.75.75 0 000-1.5h-1A1.752 1.752 0 0014.25 3v3A1.752 1.752 0 0016 7.75h1a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});