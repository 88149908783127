define("ember-svg-jar/inlined/delivery-package-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>delivery-package-person</title><path d=\"M11 12.5H8.25a.25.25 0 00-.25.25v3.75a.5.5 0 01-.8.4l-1.05-.788a.252.252 0 00-.3 0L4.8 16.9a.5.5 0 01-.8-.4v-3.75a.25.25 0 00-.25-.25H1a1 1 0 00-1 1V23a1 1 0 001 1h10a1 1 0 001-1v-9.5a1 1 0 00-1-1zM21.3 8.357a.25.25 0 01-.011-.437 4.24 4.24 0 00.282-7.145l-.008-.006-.008-.006c-.059-.04-.122-.074-.183-.112l-.009-.005a3.594 3.594 0 00-1.37-.559A4.251 4.251 0 0019.136 0a3.685 3.685 0 00-.736.074 4.256 4.256 0 00-3.065 2.288.251.251 0 01-.223.138H13.5a1 1 0 000 2h1.176a.249.249 0 01.248.223 4.237 4.237 0 001.948 3.116.25.25 0 01-.028.437 5.007 5.007 0 00-2.893 4.524v3.018a.5.5 0 00.5.5h1.584a.25.25 0 01.249.225l.687 6.864a.5.5 0 00.5.45h3.015a.5.5 0 00.5-.45l.688-6.864a.25.25 0 01.249-.225H23.5a.5.5 0 00.5-.5V12.8a5.018 5.018 0 00-2.7-4.443zM21.222 4.5a.12.12 0 01.095.045.125.125 0 01.028.1 2.241 2.241 0 01-4.383.165.251.251 0 01.238-.31z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});