define("ember-svg-jar/inlined/real-estate-action-house-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-house-heart</title><path d=\"M10.03 13.777a5.029 5.029 0 017.2-1.99.5.5 0 00.54 0 5.032 5.032 0 013.507-.724 5.118 5.118 0 011.277.391.487.487 0 00.206.045h.74a.5.5 0 00.344-.863L13.03.409a1.5 1.5 0 00-2.059 0L.157 10.636A.5.5 0 00.5 11.5h2a.5.5 0 01.5.5v10a1 1 0 001 1h9.078a.5.5 0 00.361-.846l-2.493-2.6-.007-.008a4.993 4.993 0 01-.909-5.769z\"/><path d=\"M23.627 14.448a3.531 3.531 0 00-5.774-.788.5.5 0 01-.707 0 3.527 3.527 0 10-5.124 4.846l.007.007 5.109 5.332a.5.5 0 00.723 0l5.1-5.324.006-.007a3.522 3.522 0 00.66-4.066z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});