define("ember-svg-jar/inlined/design-document-3ds-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-document-3ds-1</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM9.085 17.966a2.707 2.707 0 01-2.7 2.159H3.5a.625.625 0 010-1.25h3a1.375 1.375 0 000-2.75H5a.625.625 0 010-1.25h1.5a1.375 1.375 0 000-2.75h-3a.625.625 0 010-1.25h2.882a2.707 2.707 0 012.7 2.159 2.624 2.624 0 01-.7 2.292.244.244 0 000 .348 2.624 2.624 0 01.703 2.292zm1.415 2.159a.625.625 0 01-.625-.625v-8a.625.625 0 01.625-.625 4.625 4.625 0 010 9.25zm7.305-5.671l1.95.974a2.48 2.48 0 01-1.109 4.7H16.5a.625.625 0 010-1.25h2.146a1.229 1.229 0 00.549-2.329l-1.949-.974a2.479 2.479 0 011.108-4.7H20.5a.625.625 0 010 1.25h-2.146a1.229 1.229 0 00-.549 2.329z\"/><path d=\"M11.125 12.5v6a.252.252 0 00.312.246 3.376 3.376 0 000-6.486.252.252 0 00-.312.24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});