define("ember-svg-jar/inlined/diagram-dash-fast-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-dash-fast-up</title><path d=\"M3.457 21.629a1.482 1.482 0 000-2.963H1.481a1.482 1.482 0 000 2.963zM9.971 21.629h.06c.14 0 .279 0 .417-.011a1.481 1.481 0 001.4-1.556 1.5 1.5 0 00-1.557-1.4c-.1.006-.2.008-.311.007H8.4a1.482 1.482 0 000 2.963zM15.106 16.116a6.5 6.5 0 01-1.118 1.149 1.482 1.482 0 001.852 2.313 9.436 9.436 0 001.63-1.678 1.481 1.481 0 00-2.364-1.786zM18.09 13.938a1.482 1.482 0 001.434-1.11l.494-1.913a1.481 1.481 0 10-2.868-.742l-.495 1.912a1.482 1.482 0 001.435 1.853zM23.779 6.639l-2.2-3.566a1.483 1.483 0 00-2.017-.493l-3.589 2.212a1.481 1.481 0 00.438 2.7l5.767 1.365a1.463 1.463 0 00.341.039 1.481 1.481 0 001.26-2.259z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});