define("ember-svg-jar/inlined/modern-tv-wide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-tv-wide</title><path d=\"M24 4.87a2.253 2.253 0 00-2.25-2.25H2.25A2.253 2.253 0 000 4.87v11a2.253 2.253 0 002.25 2.25h.306a.25.25 0 01.214.379l-.593.987a1.251 1.251 0 002.145 1.287l1.446-2.41a.5.5 0 01.432-.243h11.6a.5.5 0 01.429.243l1.446 2.41a1.251 1.251 0 002.145-1.287l-.593-.987a.25.25 0 01.215-.379h.306A2.253 2.253 0 0024 15.87zm-2.5 10.25a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5v-9.5a.5.5 0 01.5-.5h18a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});