define("ember-svg-jar/inlined/body-care-sun-lotion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>body-care-sun-lotion</title><path d=\"M12.815 9.755a2.5 2.5 0 00-2.5-2.5h-.21a.5.5 0 01-.471-.332L9.125 5.5a1.5 1.5 0 00-1.412-.993.15.15 0 01-.15-.15V2.5a.5.5 0 01.5-.5h2a1 1 0 000-2H3.534a1.993 1.993 0 00-1.543.727L.229 2.863a1 1 0 001.543 1.274l1.612-1.955A.5.5 0 013.77 2h1.293a.5.5 0 01.5.5v1.758a.247.247 0 01-.248.247A1.5 1.5 0 003.9 5.5l-.506 1.424a.5.5 0 01-.471.331h-.108a2.5 2.5 0 00-2.5 2.5V21.5a2.5 2.5 0 002.5 2.5h7.5a2.5 2.5 0 002.5-2.5zM15.25 3.5a5.1 5.1 0 00-2.349.569 1 1 0 00.911 1.78A3.127 3.127 0 0115.25 5.5a3.25 3.25 0 010 6.5 1 1 0 000 2 5.25 5.25 0 000-10.5zM15.25 3a1 1 0 001-1V1a1 1 0 00-2 0v1a1 1 0 001 1zM20.023 4.977a1 1 0 00.707-.293l.707-.707a1 1 0 10-1.414-1.414l-.707.707a1 1 0 00.707 1.707zM23 7.75h-1a1 1 0 000 2h1a1 1 0 000-2zM20.73 12.815a1 1 0 10-1.414 1.414l.707.708a1 1 0 101.414-1.415zM15.25 14.5a1 1 0 00-1 1v1a1 1 0 002 0v-1a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});