define("ember-svg-jar/inlined/shipment-monitor-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-monitor-1</title><path d=\"M19.925 3a3 3 0 00-3-3H3a3 3 0 00-3 3v8.5a3 3 0 003 3h5.461a.249.249 0 01.2.1.251.251 0 01.036.224l-.322 1a.249.249 0 01-.236.176H6.425a1 1 0 000 2H13a1 1 0 000-2h-.639a.249.249 0 01-.238-.173l-.322-1a.251.251 0 01.036-.224.249.249 0 01.2-.1h4.872a3.01 3.01 0 003-2.942zM2 3a1 1 0 011-1h13.925a1 1 0 011 1v6.042a1 1 0 01-1 1H3a1 1 0 01-1-1z\"/><path d=\"M23 15.5h-1.75a.25.25 0 00-.25.25v2.783a.25.25 0 01-.389.208l-.972-.648a.247.247 0 00-.278 0l-.972.648a.25.25 0 01-.389-.208V15.75a.25.25 0 00-.25-.25H16a1 1 0 00-1 1V23a1 1 0 001 1h7a1 1 0 001-1v-6.5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});