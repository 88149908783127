define("ember-svg-jar/inlined/security-officer-passport", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>security-officer-passport</title><path d=\"M5.091 11.23a1.25 1.25 0 00.659-1.1V10H7.5A1.5 1.5 0 009 8.5v-7A1.5 1.5 0 007.5 0S.91 0 .867.009A.75.75 0 000 .75v7.5a.749.749 0 00.337.626l3.476 2.3a1.248 1.248 0 001.278.054zM7 8H5.75V3.8a1.247 1.247 0 00-.567-1.047L4.037 2H7zM15.206 6.5a.25.25 0 00-.25.25v.75a2.5 2.5 0 005 .008l.022-.751a.249.249 0 00-.25-.257zM20 12h-1.161a.5.5 0 00-.465.314l-.642 1.606a.25.25 0 01-.464 0l-.642-1.606a.5.5 0 00-.465-.314H14.5a4.5 4.5 0 00-3.387 1.537l-.492.563a1.508 1.508 0 01-2.189.072l-.983-.983a1.5 1.5 0 00-2.121 2.122l.983.982a4.5 4.5 0 006.568-.219C13.368 15.516 13.7 15 14.5 15v8.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-7.75a.5.5 0 011 0v5.75a1.25 1.25 0 002.5 0V16a4.005 4.005 0 00-4-4zM13.5 5.5h6.369a.5.5 0 00.475-.342l.329-.988a1 1 0 00-.707-1.286l-5.034-1.259a.751.751 0 00-.894.965l.3.91H13.5a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});