define("ember-svg-jar/inlined/drawer-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drawer-upload</title><path d=\"M23.884 15.276l-2.966-5.5A1.914 1.914 0 0019.24 8.75H17a1 1 0 000 2h2.009a.25.25 0 01.22.131l2.169 4a.25.25 0 01-.22.369H17.5a1.5 1.5 0 00-1.5 1.5 1.5 1.5 0 01-1.5 1.5h-5a1.5 1.5 0 01-1.5-1.5 1.5 1.5 0 00-1.5-1.5H2.8a.25.25 0 01-.221-.366l2.084-4a.25.25 0 01.221-.134H6.5a1 1 0 000-2H4.736a1.907 1.907 0 00-1.674 1.025l-2.94 5.5A1.359 1.359 0 000 15.75v6a1.5 1.5 0 001.5 1.5h21a1.5 1.5 0 001.5-1.5v-6a1.208 1.208 0 00-.116-.474z\"/><path d=\"M15.353 4.716L12.6 1.05a.75.75 0 00-1.2 0L8.654 4.716a.75.75 0 00.6 1.2h1a.249.249 0 01.25.25v3.75a1.5 1.5 0 003 0V6.167a.249.249 0 01.25-.25h1a.751.751 0 00.6-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});