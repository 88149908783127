define("ember-svg-jar/inlined/notes-book-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-book-1-alternate</title><path d=\"M21 1.5h-2.5a.25.25 0 00-.25.25v1.321a.25.25 0 00.1.2 1.5 1.5 0 11-1.682-.015.2.2 0 00.08-.182V1.75a.25.25 0 00-.25-.25H13a.25.25 0 00-.25.25v1.309a.258.258 0 00.117.224 1.5 1.5 0 11-1.735 0 .243.243 0 00.118-.215V1.75A.25.25 0 0011 1.5H7.5a.25.25 0 00-.25.25v1.311a.252.252 0 00.112.219 1.5 1.5 0 11-1.72 0 .238.238 0 00.108-.2V1.75a.25.25 0 00-.25-.25H3A1.5 1.5 0 001.5 3v18A1.5 1.5 0 003 22.5h18a1.5 1.5 0 001.5-1.5V3A1.5 1.5 0 0021 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});