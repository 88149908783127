define("ember-svg-jar/inlined/love-heart-hands-hold-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-heart-hands-hold-2</title><path d=\"M15.5 19.5H6.375a.625.625 0 010-1.25H8.9a1 1 0 00.92-1.395A2.235 2.235 0 007.764 15.5H3.5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5H14a2.5 2.5 0 002.5-2.5 1 1 0 00-1-1zM1 14.5a1 1 0 00-1 1V23a1 1 0 002 0v-7.5a1 1 0 00-1-1zM20.5 1H10a2.5 2.5 0 00-2.5 2.5 1 1 0 001 1h9.125a.625.625 0 010 1.25H15.1a1 1 0 00-.92 1.395A2.235 2.235 0 0016.236 8.5H20.5A.5.5 0 0021 8V1.5a.5.5 0 00-.5-.5zM23 0a1 1 0 00-1 1v7.5a1 1 0 002 0V1a1 1 0 00-1-1zM12 15.777a.5.5 0 00.361-.153l3.048-3.181a2.017 2.017 0 10-2.852-2.852.789.789 0 01-1.114 0 2.017 2.017 0 10-2.852 2.852l3.048 3.181a.5.5 0 00.361.153z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});