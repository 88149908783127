define("ember-svg-jar/inlined/pin-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin-monitor</title><path d=\"M17.586 13.915a26.17 26.17 0 01-1.148-1.233.248.248 0 00-.276-.07.251.251 0 00-.162.234V17a.5.5 0 01-.5.5h-13A.5.5 0 012 17V8.5a.5.5 0 01.5-.5h10.383a.249.249 0 00.23-.346 8.574 8.574 0 01-.466-1.454.25.25 0 00-.247-.2H2a2 2 0 00-2 2v10.5a2 2 0 002 2h5.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5H5a1 1 0 000 2h8a1 1 0 000-2h-2.5a.5.5 0 01-.5-.5V21a.5.5 0 01.5-.5H16a2 2 0 002-2v-4.136a.251.251 0 00-.114-.21 1.841 1.841 0 01-.3-.239zM19 0a5.006 5.006 0 00-5 5c0 3.17 4.457 7.663 4.646 7.853a.5.5 0 00.708 0C19.543 12.664 24 8.171 24 5a5.006 5.006 0 00-5-5zm0 6.75A1.75 1.75 0 1120.75 5 1.752 1.752 0 0119 6.751z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});