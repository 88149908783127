define("ember-svg-jar/inlined/famous-character-kermit-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character-kermit-1</title><circle cx=\"6.25\" cy=\"4.237\" r=\"1\"/><circle cx=\"14\" cy=\"4.987\" r=\"1\"/><path d=\"M12.505 19.277c0-.116-.006-.234 0-.352a2.372 2.372 0 011.208-2.058 2.325 2.325 0 012.3.027 1.029 1.029 0 00.742.1 1 1 0 00.689-1.3 2.341 2.341 0 011.956-3.109.5.5 0 00.441-.4A9.044 9.044 0 0020 10.5a5.59 5.59 0 00-2.07-4.536.668.668 0 01-.087-.365A4.211 4.211 0 0018 4.5a4.481 4.481 0 00-8-2.793A4.481 4.481 0 002 4.5a4.211 4.211 0 00.157 1.1.668.668 0 01-.087.365A5.59 5.59 0 000 10.5 9.793 9.793 0 0010 20a10.379 10.379 0 002.107-.215.5.5 0 00.398-.508zM13.5 2A2.5 2.5 0 1111 4.5 2.5 2.5 0 0113.5 2zm-7 0A2.5 2.5 0 114 4.5 2.5 2.5 0 016.5 2zm-2.822 8.857a.344.344 0 01.344-.357h11.956a.344.344 0 01.344.357A6.2 6.2 0 0110 16.5a6.2 6.2 0 01-6.322-5.643z\"/><path d=\"M19.784 14.084a.84.84 0 00-.927 1.108 2.495 2.495 0 01-3.6 3 .815.815 0 00-.816-.009.875.875 0 00-.436.77 5.029 5.029 0 004.411 5.042 5.145 5.145 0 003.919-1.234 5 5 0 00-2.547-8.673zM18 21.775a.75.75 0 11.75-.75.75.75 0 01-.75.75zm3-2.5a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});