define("ember-svg-jar/inlined/clamp-expand-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>clamp-expand-1</title><path d=\"M23.268.732A2.486 2.486 0 0021.5 0h-19A2.5 2.5 0 000 2.5V5a1 1 0 001 1h22a1 1 0 001-1V2.5a2.487 2.487 0 00-.732-1.768zM24 23a1 1 0 00-1-1h-3.249a.253.253 0 01-.15-.05l-3.376-2.531a.25.25 0 010-.4L19.85 16.3a2.25 2.25 0 000-3.6l-3.625-2.719a.25.25 0 010-.4L18.4 7.95a.25.25 0 00-.15-.45h-2.5a.255.255 0 00-.15.05L7.4 13.7a1 1 0 000 1.6l2.875 2.156a.25.25 0 010 .4l-.417.312a.248.248 0 01-.3 0L5.2 14.9a.5.5 0 010-.8l8.2-6.15a.25.25 0 00-.15-.45h-2.5a.253.253 0 00-.15.05l-1.158.869a.252.252 0 01-.3 0L7.984 7.55a.255.255 0 00-.15-.05H6.167a.25.25 0 00-.15.45l1.758 1.319a.25.25 0 010 .4L4 12.5a2.5 2.5 0 000 4l3.775 2.831a.25.25 0 010 .4L4.816 21.95a.255.255 0 01-.15.05H1a1 1 0 000 2h22a1 1 0 001-1zM9.933 14.7a.25.25 0 010-.4l2.125-1.594a.252.252 0 01.3 0l2.125 1.594a.25.25 0 010 .4l-2.125 1.594a.252.252 0 01-.3 0zM8 22a.25.25 0 01-.15-.45l8.6-6.45a.75.75 0 000-1.2l-2.725-2.044a.25.25 0 010-.4l.833-.625a.252.252 0 01.3 0L18.95 13.9a.751.751 0 010 1.2l-9.134 6.85a.253.253 0 01-.15.05zm5 0a.25.25 0 01-.15-.45l1.292-.969a.252.252 0 01.3 0l1.292.969a.25.25 0 01-.15.45z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});