define("ember-svg-jar/inlined/wedding-cake-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wedding-cake-heart</title><path d=\"M22.886 7.263a3.415 3.415 0 00-.542-1.084 6.615 6.615 0 00-5.782-2.171 7.855 7.855 0 00-1.709.316.5.5 0 00-.353.476v4.685a2.5 2.5 0 01-5 0V4.8a.5.5 0 00-.353-.478 7.855 7.855 0 00-1.709-.316 6.615 6.615 0 00-5.782 2.173 3.415 3.415 0 00-.542 1.084A3.227 3.227 0 001 8.2v5.885a4.644 4.644 0 001.848 3.722l7.974 5.807a1.995 1.995 0 002.356 0l7.974-5.807A4.644 4.644 0 0023 14.086V8.2a3.227 3.227 0 00-.114-.937zM21 14.086a2.625 2.625 0 01-1.033 2.11l-7.673 5.588a.5.5 0 01-.588 0L4.033 16.2A2.625 2.625 0 013 14.086v-1.923a.5.5 0 01.789-.409l7.632 5.4a1 1 0 001.158 0l7.632-5.4a.5.5 0 01.789.409z\"/><path d=\"M12 4a1.5 1.5 0 001.5-1.5A6.4 6.4 0 0012.424.235a.5.5 0 00-.848 0A6.4 6.4 0 0010.5 2.5 1.5 1.5 0 0012 4zM11 5.5v3.986a1 1 0 002 0V5.5a1 1 0 00-2 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});