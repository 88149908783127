define("ember-svg-jar/inlined/arrow-left-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-left-2</title><path d=\"M23.833 4.5a1.5 1.5 0 00-1.5-1.5H8.284a1.5 1.5 0 00-1.125.508l-6.617 7.5a1.5 1.5 0 000 1.985l6.618 7.5A1.5 1.5 0 008.284 21h14.049a1.5 1.5 0 001.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});