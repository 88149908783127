define("ember-svg-jar/inlined/heavy-equipment-wrecking-ball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>heavy-equipment-wrecking-ball</title><path d=\"M23.784 16.093a6.506 6.506 0 00-5-4.951 3.126 3.126 0 00-2.64-4.072L14.734.781a1 1 0 10-1.951.44L14.2 7.505a3.127 3.127 0 00-.668 4.8 6.5 6.5 0 1010.256 3.785zm-9.067-5.672a1.125 1.125 0 111.785.647 6.393 6.393 0 00-.937.207 1.123 1.123 0 01-.848-.854zM9.221 19.357a19.152 19.152 0 01-7.527-3.776 1 1 0 00-1.269 1.547A21.2 21.2 0 008.743 21.3a1 1 0 00.478-1.942zM1.885 13.76c.242.222.49.439.743.647A1 1 0 003.9 12.862a15.53 15.53 0 01-.659-.573 1 1 0 10-1.356 1.471zM4.785 15.927a17.537 17.537 0 003.806 1.7 1 1 0 00.593-1.91 15.534 15.534 0 01-3.372-1.5 1 1 0 00-1.027 1.717z\"/><path d=\"M10.278 12.389A12.044 12.044 0 016.973 10.8a1 1 0 00-1.131 1.65A14.045 14.045 0 009.7 14.3a1 1 0 00.582-1.914z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});