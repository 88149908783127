define("ember-svg-jar/inlined/christmas-sled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-sled</title><path d=\"M23 15.5a1 1 0 00-1 1 3.005 3.005 0 01-2.508 2.959.5.5 0 01-.529-.27l-1.912-3.825a.249.249 0 01.223-.364H19a1.5 1.5 0 001.5-1.5V9.032a1.02 1.02 0 00-.323-.744.974.974 0 00-.744-.265 6.864 6.864 0 00-5.042 2.787A.5.5 0 0114 11H9.5a.5.5 0 01-.5-.5v-.78a2.482 2.482 0 00-2.1-2.47l-4.5-.883a.5.5 0 01-.4-.491V3.5a1 1 0 10-2 0v9A2.5 2.5 0 002.5 15h3.226a.249.249 0 01.223.362l-1.929 3.86a.5.5 0 01-.447.277H1a1 1 0 000 2h18a5.006 5.006 0 005-5 1 1 0 00-1-.999zm-16.477 4a.25.25 0 01-.213-.119.248.248 0 01-.011-.243l1.93-3.859A.5.5 0 018.676 15h5.648a.5.5 0 01.447.277l1.93 3.859a.248.248 0 01-.011.243.25.25 0 01-.213.119z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});