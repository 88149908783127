define("ember-svg-jar/inlined/single-neutral-actions-share-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-share-2</title><path d=\"M13.75 14a4.151 4.151 0 011.25.194.252.252 0 00.2-.025l1.063-.638a.251.251 0 00.1-.309 4.29 4.29 0 00-.12-.268c-.539-1.076-2.307-1.725-4.754-2.626l-.628-.23a.6.6 0 01-.138-.311 2.573 2.573 0 01.058-1.3 5.618 5.618 0 001.466-4.314C12.256 1.756 10.677 0 8.5 0S4.744 1.756 4.744 4.174A5.605 5.605 0 006.2 8.471a2.453 2.453 0 01.073 1.316.615.615 0 01-.135.309l-.631.232c-2.447.9-4.215 1.55-4.754 2.626A9.173 9.173 0 000 16.5a.5.5 0 00.5.5h9.029a.252.252 0 00.236-.166A4.237 4.237 0 0113.75 14z\"/><path d=\"M24 21.25a2.741 2.741 0 00-4.637-1.989.253.253 0 01-.266.051l-2.445-.978a.251.251 0 01-.157-.217c0-.045-.008-.1-.016-.162a.247.247 0 01.119-.247L19.437 16a.249.249 0 01.27.009 2.714 2.714 0 001.543.487 2.75 2.75 0 10-2.75-2.75 2.4 2.4 0 00.021.295.247.247 0 01-.119.247L15.563 16a.249.249 0 01-.27-.009 2.714 2.714 0 00-1.543-.491 2.75 2.75 0 101.887 4.739.253.253 0 01.266-.051l2.445.978a.251.251 0 01.157.223A2.749 2.749 0 0024 21.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});