define("ember-svg-jar/inlined/ping-pong-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ping-pong-player</title><circle cx=\"10.75\" cy=\"2.997\" r=\"2.5\"/><path d=\"M13.309 12.116a3.516 3.516 0 002.405-1.3l.708-.885a1.5 1.5 0 00.313-1.154.979.979 0 00.29-.242L17.468 8a2.842 2.842 0 00.829.12 3.7 3.7 0 002.808-1.408A3.838 3.838 0 0022 4.348 2.826 2.826 0 0021 2.1a3.284 3.284 0 00-4.538.81 3.849 3.849 0 00-.892 2.362 3 3 0 00.351 1.459l-.442.542a1 1 0 00-.14.225 1.492 1.492 0 00-1.257.561l-.709.885a.5.5 0 01-.343.185.512.512 0 01-.372-.118l-1.931-1.652A1.611 1.611 0 009.751 7H4.912a3.511 3.511 0 00-3.04 1.764L.448 11.253a1.5 1.5 0 102.605 1.488l1.423-2.491a.5.5 0 01.436-.25h2a.252.252 0 01.214.121.249.249 0 01.007.245l-5.724 10.96a1.5 1.5 0 102.683 1.342l2.839-5.194a.25.25 0 01.291-.119l1.621.486a.5.5 0 01.332.638L7.9 21.5a1.5 1.5 0 102.846.949l1.271-3.017a3.5 3.5 0 00-2.314-4.461l-1.313-.398 1.7-3.405a.252.252 0 01.381-.083 3.42 3.42 0 002.838 1.031zm4.7-7.939c.5-.607 1.306-.87 1.723-.527s.33 1.174-.175 1.794-1.313.866-1.724.527c-.369-.302-.388-1.104.176-1.794z\"/><path d=\"M23.75 14.5a1 1 0 00-1-1h-7.5a1 1 0 100 2H16v7a1 1 0 002 0v-7h4.75a1 1 0 001-1z\"/><circle cx=\"21.5\" cy=\"10.247\" r=\"1.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});