define("ember-svg-jar/inlined/road-sign-keep-right-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-keep-right -alternate</title><path d=\"M23 0a1 1 0 00-1 1v22a1 1 0 002 0V1a1 1 0 00-1-1zM1 0a1 1 0 00-1 1v22a1 1 0 002 0V1a1 1 0 00-1-1zM20.436 19.632l-3.5-2.5a.751.751 0 00-1.186.611v1.249a.25.25 0 01-.25.25h-11a1 1 0 100 2h11a.25.25 0 01.25.25v1.25a.751.751 0 001.186.61l3.5-2.5a.75.75 0 000-1.221zM7.25 9.746v5a.75.75 0 001.5 0v-5a.75.75 0 00-1.5 0zM20 9h-2a.75.75 0 100 1.5.25.25 0 01.25.25v4a.75.75 0 001.5 0v-4a.25.25 0 01.25-.25.75.75 0 100-1.5zM15.25 9.746v2a.25.25 0 11-.5 0v-2a.75.75 0 00-1.5 0v5a.75.75 0 001.5 0v-1a.25.25 0 11.5 0v1a.75.75 0 001.5 0v-5a.75.75 0 00-1.5 0zM4.75 14.745a.174.174 0 01.322-.09l.285.475a.75.75 0 101.286-.771l-.835-1.391a.25.25 0 01.073-.335A1.991 1.991 0 004.75 9H4a.75.75 0 00-.75.749v5a.75.75 0 001.5 0zm.25-4a.25.25 0 11-.25.25.25.25 0 01.25-.249zM5 8a.75.75 0 00.75-.75.125.125 0 01.085-.119.127.127 0 01.141.04l.439.548a.749.749 0 101.17-.937L6.085 4.9a.25.25 0 010-.312l1.5-1.874a.749.749 0 10-1.17-.937l-.439.547a.125.125 0 01-.141.041.127.127 0 01-.085-.12.75.75 0 00-1.5 0v5A.75.75 0 005 8zM17 8a.75.75 0 00.75-.75V6.186a.249.249 0 01.2-.245A2.243 2.243 0 0017.5 1.5H17a.75.75 0 00-.75.75v5A.75.75 0 0017 8zm1-4.5a.25.25 0 11-.25.25.25.25 0 01.25-.25zM10 8h1a.75.75 0 100-1.5h-1a.25.25 0 010-.5h1a.75.75 0 000-1.5h-1a.25.25 0 01-.25-.25v-1A.25.25 0 0110 3h1a.75.75 0 000-1.5h-1a1.752 1.752 0 00-1.75 1.75v3A1.752 1.752 0 0010 8zM14 8h1a.75.75 0 100-1.5h-1a.25.25 0 010-.5h1a.75.75 0 000-1.5h-1a.25.25 0 01-.25-.25v-1A.25.25 0 0114 3h1a.75.75 0 000-1.5h-1a1.752 1.752 0 00-1.75 1.75v3A1.752 1.752 0 0014 8zM9.25 10.746v3a1.75 1.75 0 003.5 0v-1A.749.749 0 0012 12h-.5a.75.75 0 00-.375 1.4.25.25 0 01.125.216v.134c0 .275-.5.275-.5 0v-3a.25.25 0 01.25-.25h1A.75.75 0 1012 9h-1a1.752 1.752 0 00-1.75 1.746z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});