define("ember-svg-jar/inlined/cash-payment-bill-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-payment-bill-2</title><path d=\"M12.734 8.688l1.788.714a.163.163 0 01-.06.314H12.7a1 1 0 000 2h.217A.084.084 0 0113 11.8a1 1 0 002 0v-.019a.189.189 0 01.129-.179 2.156 2.156 0 00.137-4.056l-1.788-.715a.163.163 0 01.06-.314H15.3a1 1 0 000-2h-.217A.084.084 0 0115 4.433a1 1 0 00-2 0v.042a.156.156 0 01-.109.149 2.157 2.157 0 00-.157 4.064z\"/><path d=\"M22 2.8h-3.5a1 1 0 000 2h3.25a.25.25 0 01.25.25v8.5a.25.25 0 01-.25.25H13.5a1 1 0 000 2H22a2 2 0 002-2v-9a2 2 0 00-2-2zM5.009 12.3a1 1 0 001-1L6 5.05a.255.255 0 01.073-.177.251.251 0 01.177-.073H10a1 1 0 000-2H6a2 2 0 00-2 2l.009 6.5a1 1 0 001 1z\"/><path d=\"M10.288 14.155a1 1 0 00-.214-1.472 1.231 1.231 0 00-1.2-.094l-4.608 2.127A.89.89 0 013 13.906v-2.638a.25.25 0 00-.357-.227l-2.5 1.191a.251.251 0 00-.143.226v8.492a.25.25 0 00.333.236 22.236 22.236 0 009.716-6.745zM9.571 17.555a1 1 0 00.394 1.145l.114.076a1 1 0 001.325-.194l.983-1.187a.25.25 0 01.442.16v1.1a1 1 0 00.553.894l.276.138a1 1 0 001.425-.683.181.181 0 01.009-.031l.631-1.8a.25.25 0 00-.233-.333l-5.478-.065a.25.25 0 00-.24.171z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});