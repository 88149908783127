define("ember-svg-jar/inlined/rating-star-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rating-star-alternate</title><path d=\"M22.816 8.935a1.469 1.469 0 00-1.374-.959H15.5L13.372 1.95a1.465 1.465 0 00-2.751.018L8.5 7.976H2.558a1.466 1.466 0 00-.941 2.589l5.031 4.169-2.111 6.334a1.465 1.465 0 002.255 1.648L12 18.9l5.207 3.818a1.465 1.465 0 002.256-1.644l-2.112-6.338 5.042-4.179a1.47 1.47 0 00.423-1.622zm-7.267 4.7a1 1 0 00-.311 1.086l1.549 4.647a.25.25 0 01-.385.281l-3.812-2.798a1 1 0 00-1.183 0L7.6 19.644a.25.25 0 01-.385-.28l1.548-4.647a1 1 0 00-.31-1.086l-3.878-3.212a.25.25 0 01.159-.443h4.475a1 1 0 00.943-.668l1.612-4.568a.25.25 0 01.472 0l1.611 4.568a1 1 0 00.943.668h4.475a.25.25 0 01.159.443z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});