define("ember-svg-jar/inlined/punishment-prisoner-ball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>punishment-prisoner-ball</title><path d=\"M23.892 15.041a4 4 0 00-7.892.943c0 .045 0 .09.007.134a.25.25 0 01-.249.274H15.5a3.079 3.079 0 00-3 3.023.99.99 0 01-1 .977h-.429a1 1 0 000 2h.429a3 3 0 003-3.021.99.99 0 011-.979h1.2a.251.251 0 01.2.094 3.991 3.991 0 006.992-3.445zM6.5 5.839a2.5 2.5 0 10-2.5-2.5 2.5 2.5 0 002.5 2.5zM.771 9.142h11.458a.25.25 0 00.216-.376A3.99 3.99 0 009 6.785H4A3.99 3.99 0 00.555 8.766a.25.25 0 00.216.376z\"/><rect x=\"3.5\" y=\"17.142\" width=\"2.5\" height=\"2.5\" rx=\".25\" ry=\".25\"/><path d=\"M5.75 20.642h-2a.25.25 0 00-.25.25v1.019a1.25 1.25 0 002.5 0v-1.019a.25.25 0 00-.25-.25z\"/><rect x=\"7\" y=\"17.142\" width=\"2.5\" height=\"2.5\" rx=\".25\" ry=\".25\"/><path d=\"M9.25 13.642h-5.5a.25.25 0 00-.25.25v2a.25.25 0 00.25.25h2a.25.25 0 00.25-.25.5.5 0 011 0 .25.25 0 00.25.25h2a.25.25 0 00.25-.25v-2a.25.25 0 00-.25-.25zM2.264 10.142H.253a.22.22 0 00-.217.189 3.233 3.233 0 00-.036.454v1.607a.25.25 0 00.25.25h2a.25.25 0 00.25-.25v-1.857a.473.473 0 01.009-.093.25.25 0 00-.245-.3zM9.264 10.142H3.736a.251.251 0 00-.245.3.473.473 0 01.009.093v1.857a.25.25 0 00.25.25h5.5a.25.25 0 00.25-.25v-1.857a.473.473 0 01.009-.093.25.25 0 00-.245-.3zM2.25 13.642h-2a.25.25 0 00-.25.25v.5a1.25 1.25 0 002.5 0v-.5a.25.25 0 00-.25-.25zM12.75 13.642h-2a.25.25 0 00-.25.25v.5a1.25 1.25 0 002.5 0v-.5a.25.25 0 00-.25-.25zM10.543 10.234a.249.249 0 00-.052.208.473.473 0 01.009.093v1.857a.25.25 0 00.25.25h2a.25.25 0 00.25-.25v-1.607a3.152 3.152 0 00-.032-.426.25.25 0 00-.247-.217h-1.985a.249.249 0 00-.193.092zM9.25 20.642h-2a.25.25 0 00-.25.25v1.019a1.25 1.25 0 002.5 0v-1.019a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});