define("ember-svg-jar/inlined/playlist-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>playlist-sync</title><path d=\"M16.264 9.538a.251.251 0 00.236-.249V2.5A2.5 2.5 0 0014 0H2.5A2.5 2.5 0 000 2.5v12A2.5 2.5 0 002.5 17h6a.25.25 0 00.249-.227 7.929 7.929 0 017.515-7.235zM11.5 8a1 1 0 01-1-1 1.77 1.77 0 00-1.151-1.729A.25.25 0 009 5.5v5.552A2.5 2.5 0 116.5 8.5c.077 0 .152 0 .227.01A.249.249 0 007 8.261V4a1 1 0 011-1c1.557 0 4.5.836 4.5 4a1 1 0 01-1 1z\"/><path d=\"M10.771 17.625A6.375 6.375 0 0022.129 21.6a1 1 0 10-1.563-1.248 4.372 4.372 0 11-1.543-6.684 4.313 4.313 0 011.049.7.25.25 0 01.009.362l-1.392 1.392a.5.5 0 00.354.854H23.5a.5.5 0 00.5-.5v-4.455a.5.5 0 00-.854-.353l-1.3 1.3a.249.249 0 01-.347 0 6.479 6.479 0 00-.999-.768 6.381 6.381 0 00-9.726 5.428z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});