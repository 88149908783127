define("ember-svg-jar/inlined/cloud-data-transfer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-data-transfer</title><path d=\"M3.8 16.729a1.008 1.008 0 001.2-.983.984.984 0 00-.8-.977 2.918 2.918 0 01-1.563-.735A2.371 2.371 0 012 12.249a2.588 2.588 0 012.586-2.587 2.635 2.635 0 01.535.056 1 1 0 001.2-.965C6.405 3 14.621 1.39 17.142 6.708a.974.974 0 00.941.571 3.7 3.7 0 013 1.3 3.876 3.876 0 01.907 2.793 3.473 3.473 0 01-1.94 2.976 1.006 1.006 0 00-.55.888.992.992 0 001.427.9A5.475 5.475 0 0024 11.27a5.767 5.767 0 00-5.219-5.97.25.25 0 01-.191-.12 7.685 7.685 0 00-14.1 2.3.251.251 0 01-.227.2A4.642 4.642 0 00.62 9.922 4.479 4.479 0 000 12.369a4.287 4.287 0 001.235 3.09 4.864 4.864 0 002.565 1.27z\"/><path d=\"M10 11.554a1 1 0 00-2 0V17.3a.25.25 0 01-.25.25H6.5a.5.5 0 00-.424.765l.147.235 2.353 3.765a.5.5 0 00.413.235H9a.5.5 0 00.412-.217l2.6-3.783.149-.217a.5.5 0 00-.412-.783h-1.5A.25.25 0 0110 17.3zM16 14.554a.25.25 0 01.25-.25h1.5a.49.49 0 00.421-.75c-.009-.014-.008-.032-.018-.046L15.4 9.758a.5.5 0 00-.819.019l-2.5 3.75c0 .008 0 .018-.009.027a.493.493 0 00.425.75h1.25a.25.25 0 01.25.25v6a1 1 0 102 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});