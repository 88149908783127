define("ember-svg-jar/inlined/cash-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-toggle</title><path d=\"M3.012 9.783l-1.265.45a1.875 1.875 0 000 3.534l1.265.45a1.874 1.874 0 011.065 2.571L3.5 18A1.875 1.875 0 006 20.5l1.213-.577a1.874 1.874 0 012.571 1.065l.45 1.266a1.876 1.876 0 003.534 0l.45-1.266a1.874 1.874 0 012.571-1.065L18 20.5a1.875 1.875 0 002.5-2.5l-.576-1.213a1.874 1.874 0 011.065-2.571l1.265-.45a1.875 1.875 0 000-3.534l-1.265-.45a1.874 1.874 0 01-1.065-2.571L20.5 6A1.874 1.874 0 0018 3.5l-1.213.576a1.875 1.875 0 01-2.571-1.064l-.45-1.266a1.875 1.875 0 00-3.534 0l-.45 1.266a1.875 1.875 0 01-2.57 1.065L6 3.5A1.874 1.874 0 003.5 6l.576 1.213a1.874 1.874 0 01-1.064 2.57zM12 5.5A6.5 6.5 0 115.5 12 6.5 6.5 0 0112 5.5z\"/><path d=\"M10.528 12.489l2.2.879a.431.431 0 01-.16.832H10.4a1 1 0 000 2h.534a.067.067 0 01.066.067 1 1 0 002 0 .124.124 0 01.1-.122 2.431 2.431 0 00.374-4.63l-2.2-.88a.431.431 0 01.16-.832H13.6a1 1 0 000-2h-.534a.066.066 0 01-.066-.07 1 1 0 00-2 0 .125.125 0 01-.1.122 2.431 2.431 0 00-.374 4.63z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});