define("ember-svg-jar/inlined/monitor-flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-flash</title><path d=\"M12.657 16.335a.25.25 0 00-.236-.335H10.5a.5.5 0 01-.5-.5V15a.5.5 0 01.5-.5h3.315a.253.253 0 00.2-.094l3.934-4.915A.245.245 0 0018 9.336V2a2 2 0 00-2-2H2a2 2 0 00-2 2v10.5a2 2 0 002 2h5.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5H5a1 1 0 000 2h7.341a.251.251 0 00.2-.1.249.249 0 00.046-.214 2.356 2.356 0 01.07-1.351zM2.5 11.5A.5.5 0 012 11V2.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5V11a.5.5 0 01-.5.5zM23.792 16.443A.875.875 0 0023.125 15H21.25a.25.25 0 01-.25-.25V9.5a.5.5 0 00-.89-.313l-5.9 7.37A.875.875 0 0014.875 18h1.875a.25.25 0 01.25.25v5.25a.5.5 0 00.335.471.474.474 0 00.165.029.5.5 0 00.39-.188z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});