define("ember-svg-jar/inlined/safety-warning-radioactive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-warning-radioactive</title><path d=\"M10.654 12.777A4.367 4.367 0 008.707 11a.245.245 0 00-.213 0 .248.248 0 00-.136.165A3.447 3.447 0 008.25 12a3.752 3.752 0 002.643 3.577.25.25 0 00.323-.217 4.367 4.367 0 00-.562-2.583zM12 10.446a4.373 4.373 0 002.51-.8.249.249 0 00.028-.388 3.7 3.7 0 00-5.076 0 .25.25 0 00.028.388 4.373 4.373 0 002.51.8zM13.346 12.777a4.367 4.367 0 00-.562 2.583.25.25 0 00.323.217A3.752 3.752 0 0015.75 12a3.422 3.422 0 00-.107-.828.25.25 0 00-.35-.166 4.376 4.376 0 00-1.947 1.771z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm8.452 11.97a4.459 4.459 0 00-1.032-.821 4.393 4.393 0 00-2.061-.579.25.25 0 00-.251.308A4.822 4.822 0 0117.25 12a5.242 5.242 0 01-3.675 4.99.249.249 0 00-.137.371 4.4 4.4 0 002.547 1.918.75.75 0 01-.205 1.472.769.769 0 01-.206-.028 5.993 5.993 0 01-1.351-.574 5.849 5.849 0 01-2.013-1.938.247.247 0 00-.21-.111.25.25 0 00-.211.116 5.853 5.853 0 01-2.012 1.937 5.955 5.955 0 01-1.351.574.774.774 0 01-.206.028.75.75 0 01-.205-1.472 4.4 4.4 0 002.547-1.918.249.249 0 00-.137-.371A5.242 5.242 0 016.75 12a4.768 4.768 0 01.144-1.128.251.251 0 00-.05-.216.247.247 0 00-.2-.092 4.4 4.4 0 00-2.061.585 4.444 4.444 0 00-1.033.821.75.75 0 11-1.1-1.022A5.936 5.936 0 016.5 9.066a.25.25 0 00.21-.364A5.881 5.881 0 016.054 6a5.961 5.961 0 01.252-1.717.75.75 0 011.436.434A4.431 4.431 0 007.554 6a4.374 4.374 0 00.522 2.064.25.25 0 00.389.066 5.216 5.216 0 017.07 0 .251.251 0 00.211.062.253.253 0 00.179-.129A4.382 4.382 0 0016.446 6a4.431 4.431 0 00-.188-1.283.75.75 0 011.436-.434A5.961 5.961 0 0117.946 6a5.88 5.88 0 01-.659 2.705.25.25 0 00.21.364 5.918 5.918 0 014.052 1.879.75.75 0 11-1.1 1.022z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});