define("ember-svg-jar/inlined/boxing-bag-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>boxing-bag-small</title><path d=\"M14.325 8.705L14 8.407V7a1 1 0 00-1-1V3.288A2.507 2.507 0 0014.288 2H16.5a1 1 0 000-2h-9a1 1 0 000 2h2.212A2.507 2.507 0 0011 3.288V6a1 1 0 00-1 1v1.407l-.325.3C7.813 10.4 5 12.957 5 17a7 7 0 0014 0c0-4.043-2.813-6.6-4.675-8.295zm-5.56 4.129a.75.75 0 011.249.83 5.619 5.619 0 00.026 5.714.75.75 0 01-1.3.744 7.114 7.114 0 01.025-7.288zm5.363 7.566a.749.749 0 01-.279-1.023 5.656 5.656 0 00.027-5.712.75.75 0 111.248-.832 7.115 7.115 0 01.027 7.288.75.75 0 01-1.023.279z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});