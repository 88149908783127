define("ember-svg-jar/inlined/project-book-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>project-book-house</title><path d=\"M21.625 5h-15.5a1.5 1.5 0 010-3h15.5a1 1 0 000-2h-15.5a3.5 3.5 0 00-3.5 3.5v3.25a.5.5 0 01-.5.5.75.75 0 000 1.5h2.75a.5.5 0 010 1h-1.75a.5.5 0 00-.5.5v2a.5.5 0 01-.5.5.75.75 0 000 1.5h2.75a.5.5 0 010 1h-1.75a.5.5 0 00-.5.5v2a.5.5 0 01-.5.5.75.75 0 000 1.5h2.75a.5.5 0 010 1h-2a.25.25 0 00-.25.25 3 3 0 003 3h16a1 1 0 001-1V6a1 1 0 00-1-1zm-2.75 9.25h-.5a.5.5 0 00-.5.5v3.5a1 1 0 01-1 1h-6a1 1 0 01-1-1v-3.5a.5.5 0 00-.5-.5h-.5a.5.5 0 01-.342-.865l4.657-4.364a1 1 0 011.369 0l4.657 4.363a.5.5 0 01-.341.866z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});