define("ember-svg-jar/inlined/office-file-stamp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-file-stamp</title><path d=\"M1.511 20.771h10.637a1.182 1.182 0 001.182-1.182 2.365 2.365 0 00-2.364-2.364H8.852a.25.25 0 01-.25-.25v-3.193a.238.238 0 01.091-.186 2.944 2.944 0 00.975-3.116 2.811 2.811 0 00-1.9-1.975 2.964 2.964 0 00-3.89 2.811A2.932 2.932 0 004.966 13.6a.238.238 0 01.091.186v3.193a.25.25 0 01-.25.25H2.693a2.364 2.364 0 00-2.363 2.36 1.182 1.182 0 001.181 1.182z\"/><path d=\"M23.537 5.705L18.123.291A1 1 0 0017.416 0H5.83a2 2 0 00-2 2v5.521a.247.247 0 00.388.205 4.411 4.411 0 011.428-.7.244.244 0 00.184-.226V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5h-6.593a.255.255 0 00-.252.307 5.347 5.347 0 01.1 1.415.251.251 0 00.246.278h7a2 2 0 002-2V6.412a1 1 0 00-.294-.707zM12.739 22.385H.92a.75.75 0 000 1.5h11.819a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});