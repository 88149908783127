define("ember-svg-jar/inlined/video-game-pinball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-pinball</title><path d=\"M4.207 14.99a2.682 2.682 0 10-2.4 4.741l7.183 2.487a1.645 1.645 0 001.473-2.909zM21.9 14.813l-7.422-1.646a1.646 1.646 0 00-1.13 3.059l6.71 3.573a2.682 2.682 0 101.842-4.986zM11 9.692a4 4 0 10-4-4 4 4 0 004 4zm0-6a2 2 0 11-2 2 2 2 0 012-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});