define("ember-svg-jar/inlined/wolf-body-howl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wolf-body-howl</title><path d=\"M21.456 18.5h-.752a.249.249 0 01-.178-.072.252.252 0 01-.074-.178V14.5a5.237 5.237 0 01.6-2.164 8.72 8.72 0 00.9-3.836V1.532A1.509 1.509 0 0020.823.077a1.5 1.5 0 00-1.684.539 5.212 5.212 0 01-1.808 1.726 3.4 3.4 0 00-1.295 1.191c-1 1.5-1.989 3.2-2 3.214a.5.5 0 00.432.752h1.3l-.281.815c-.006.013-.678 1.343-5.667 2.7a4.372 4.372 0 00-2.283 1.477 4.712 4.712 0 00-4.593 3.992 10.976 10.976 0 01-1.311 3.655.5.5 0 00.338.781c1.147.165 2.948-.057 3.584-2.815l.455-1.063a17.67 17.67 0 00-.059 1.455v5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V21c0-.461 0-1.232 1.275-2.082a2.891 2.891 0 00.62-.577 5.345 5.345 0 00.4-1.852.75.75 0 01.75-.747.748.748 0 01.748.749 6.181 6.181 0 01-.055.7.25.25 0 00.327.269 18.107 18.107 0 003.067-1.347.249.249 0 01.371.218v2.188a.25.25 0 01-.212.247 7.914 7.914 0 00-4.991 2.524 1 1 0 101.414 1.414c1.668-1.668 2.718-2.207 8.793-2.207a1 1 0 100-2zM17.707 4.563a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});