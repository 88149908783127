define("ember-svg-jar/inlined/power-tools-electric-saw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>power-tools-electric-saw</title><path d=\"M23.534 18.21A4.968 4.968 0 0022 13.69l-.966-.89a.25.25 0 01-.015-.352l.17-.184a2.748 2.748 0 00-.16-3.878 2.811 2.811 0 00-3.879.159l-.314.342a.222.222 0 01-.169.071.484.484 0 00-.377.153l-.506.551a.25.25 0 01-.353.014L14.693 9a.249.249 0 01-.015-.352l.507-.551a1 1 0 10-1.468-1.352l-.508.551a.25.25 0 01-.352.015l-1.469-1.353a.25.25 0 01-.015-.352l.507-.551A1 1 0 1010.412 3.7l-.512.554a.25.25 0 01-.353.014L8.093 2.925a.249.249 0 01-.07-.254l.409-1.391A1 1 0 006.517.717l-.293.994a.249.249 0 01-.266.177 3.648 3.648 0 00-2.038.366.251.251 0 01-.282-.039l-.733-.674a1 1 0 10-1.351 1.468l.719.663a.25.25 0 01.06.283 3.412 3.412 0 00-.289 1.506 3.235 3.235 0 00.076.568.25.25 0 01-.154.286l-.935.361a1 1 0 00.4 1.929.968.968 0 00.318-.067L3.1 8.015a.251.251 0 01.259.049L4.82 9.409a.249.249 0 01.015.352l-.507.551A1 1 0 005.8 11.664l.506-.551a.251.251 0 01.353-.015l1.466 1.352a.253.253 0 01.08.173.25.25 0 01-.066.18l-.507.55A1 1 0 009.1 14.706l.507-.551a.251.251 0 01.353-.015l.6.55a.5.5 0 01.1.6 1.5 1.5 0 00.306 1.809l6.713 6.107a3.013 3.013 0 002.883.67 2.884 2.884 0 002.053-2.052l.915-3.6zm-9.981-6.867L12.2 12.811a.5.5 0 01-.7.03L7.09 8.785 4.52 6.419A1.5 1.5 0 014.433 4.3a1.5 1.5 0 012.115-.088l2.57 2.367 4.4 4.056a.493.493 0 01.161.346.5.5 0 01-.126.362zm4.7-1.783a1.277 1.277 0 011.763-.073 1.249 1.249 0 01.072 1.763.5.5 0 01-.7.029l-1.1-1.014a.5.5 0 01-.029-.7zm3.336 8.23v.021l-.9 3.521a.925.925 0 01-.686.632 1.02 1.02 0 01-.983-.243l-.947-.813a.5.5 0 01-.14-.561l1.961-5.008a.474.474 0 01.764-.176 2.96 2.96 0 01.926 2.627z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});