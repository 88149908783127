define("ember-svg-jar/inlined/single-woman-actions-credit-card.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-credit-card</title><path d=\"M9 15.25a3.21 3.21 0 011.072-2.393.5.5 0 00-.15-.837 7.388 7.388 0 00-2.716-.52 7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h8A.5.5 0 009 17zM24 15.25a1.753 1.753 0 00-1.75-1.75h-10a1.753 1.753 0 00-1.75 1.75v7A1.752 1.752 0 0012.25 24h10A1.752 1.752 0 0024 22.25zm-1.75 7.25h-10a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25h10a.25.25 0 01.25.25v3a.251.251 0 01-.25.25zM12 15.25a.251.251 0 01.25-.25h10a.251.251 0 01.25.25v1a.25.25 0 01-.25.25h-10a.25.25 0 01-.25-.25z\"/><path d=\"M20.25 20H19a.75.75 0 000 1.5h1.25a.75.75 0 000-1.5zM1.568 10.442a1 1 0 001.383-.3 5.162 5.162 0 00.454-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .251.251 0 01.228-.071.255.255 0 01.184.153 5.093 5.093 0 00.454.9 1 1 0 001.68-1.085 4.569 4.569 0 01-.642-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248A4.564 4.564 0 011.27 9.06a1 1 0 00.298 1.382zM7.21 8.6a2.7 2.7 0 01-2.741-2.352.5.5 0 01.324-.577 5.7 5.7 0 002.245-1.439.25.25 0 01.361 0 5.712 5.712 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.21 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});