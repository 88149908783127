define("ember-svg-jar/inlined/toilet-use-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toilet-use-right</title><path d=\"M17.819 16.214a3.5 3.5 0 00-3.41-2.711h-3.775a.251.251 0 01-.245-.3l.546-2.727a.247.247 0 01.154-.183.25.25 0 01.238.03l3.291 2.392a1.5 1.5 0 101.763-2.425l-5.5-4a1.5 1.5 0 00-2.352.918l-1.5 7.5A1.5 1.5 0 008.5 16.5h5.91a.5.5 0 01.486.386l1.604 5.635a1.5 1.5 0 002.923-.675zM23.6.209a1 1 0 00-1.4.2L19.468 4.05l-1.3-1.3a1 1 0 00-1.414 1.413l1.5 1.5a1.749 1.749 0 002.636-.188l2.91-3.867a1 1 0 00-.2-1.399z\"/><path d=\"M13.5 17.99H5v-2.737A.25.25 0 004.75 15H.25a.25.25 0 00-.25.25v8.25a.5.5 0 00.5.5H10a.5.5 0 00.5-.5v-.511a1.939 1.939 0 01.309-1.307.827.827 0 01.64-.192h.005a2.611 2.611 0 001.777-.678 2.353 2.353 0 00.769-1.75v-.572a.5.5 0 00-.5-.5zM4.75 14a.25.25 0 00.25-.25v-1.258a1.5 1.5 0 00-1.5-1.5h-2c-.726 0-1.5.525-1.5 2v.762A.25.25 0 00.25 14z\"/><circle cx=\"12\" cy=\"2.499\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});