define("ember-svg-jar/inlined/satellite-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>satellite-1</title><path d=\"M7.079 8.457a1.5 1.5 0 002.121-.01l1.408-1.42a1.5 1.5 0 00-.01-2.122L6.337.683a1.505 1.505 0 00-2.121.009L2.808 2.113a1.5 1.5 0 00.01 2.121zM23.307 17.683l-4.263-4.223a1.506 1.506 0 00-2.121.01l-1.408 1.421a1.5 1.5 0 00.01 2.121l4.263 4.223a1.5 1.5 0 002.121-.01l1.407-1.425a1.5 1.5 0 00-.009-2.117zM20.66 5.317h.007a2.038 2.038 0 10-.007 0zM7.25 18.75a2 2 0 01-2-2 1 1 0 00-2 0 4 4 0 004 4 1 1 0 000-2z\"/><path d=\"M6.75 21.75a4.5 4.5 0 01-4.5-4.5 1 1 0 00-2 0 6.508 6.508 0 006.5 6.5 1 1 0 000-2zM18.014 5.948a3 3 0 00-4.242.019l-4.483 4.527a1 1 0 00.011 1.415l2.841 2.815a1 1 0 001.413-.007l4.483-4.527a3 3 0 00-.023-4.242zM5.716 13a1 1 0 00-.27 1.611l4.019 3.983a1 1 0 001.609-.283A4 4 0 005.716 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});