define("ember-svg-jar/inlined/single-neutral-actions-heart.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-heart</title><path d=\"M10.03 13.779a5 5 0 011.07-1.424.5.5 0 00-.085-.8A7.435 7.435 0 007.206 10.5a7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h8.51a.5.5 0 00.5-.5 4.973 4.973 0 01.52-2.221z\"/><circle cx=\"7\" cy=\"4.75\" r=\"4.75\"/><path d=\"M21.038 12.546a3.487 3.487 0 00-3.057.987l-.305.3a.249.249 0 01-.353 0l-.3-.3a3.526 3.526 0 00-4.993 4.979l5.114 5.334a.5.5 0 00.721 0l5.106-5.327a3.522 3.522 0 00.661-4.07 3.487 3.487 0 00-2.594-1.903z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});