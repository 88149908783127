define("ember-svg-jar/inlined/wifi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi</title><path d=\"M4.046 9.917a1.25 1.25 0 001.768 1.767 8.762 8.762 0 0112.374 0 1.248 1.248 0 00.884.366 1.24 1.24 0 001.245-1.221V10.8a1.242 1.242 0 00-.366-.883 11.264 11.264 0 00-15.905 0z\"/><path d=\"M22.784 6.088a15.269 15.269 0 00-21.568 0 1.25 1.25 0 001.768 1.768 12.767 12.767 0 0118.032 0 1.25 1.25 0 001.768-1.768zM14.383 12.033a7.252 7.252 0 00-7.509 1.711 1.25 1.25 0 00.883 2.134 1.247 1.247 0 00.884-.367 4.748 4.748 0 016.527-.155c.062.055.131.1.19.155a1.247 1.247 0 00.881.364 1.251 1.251 0 00.883-2.135 7.188 7.188 0 00-2.739-1.707z\"/><circle cx=\"12\" cy=\"19.871\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});