define("ember-svg-jar/inlined/public-service-ambulance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>public-service-ambulance</title><path d=\"M23.384 18.884A1.242 1.242 0 0023.75 18V4.75A1.753 1.753 0 0022 3h-2.127a.249.249 0 01-.242-.188A3.756 3.756 0 0016 0H8a3.756 3.756 0 00-3.631 2.812.249.249 0 01-.242.188H2A1.751 1.751 0 00.25 4.75V18a1.234 1.234 0 00.894 1.181.25.25 0 01.172.3A2.107 2.107 0 001.25 20a2.253 2.253 0 002.025 2.239.25.25 0 01.225.249V23a1 1 0 001 1h2a1 1 0 001-1v-.5a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v.5a1 1 0 001 1h2a1 1 0 001-1v-.512a.25.25 0 01.225-.249A2.253 2.253 0 0022.75 20a2.137 2.137 0 00-.065-.515.249.249 0 01.176-.3 1.175 1.175 0 00.523-.301zm-2.732-7.2a.252.252 0 01-.044.217.25.25 0 01-.2.1H3.389a.25.25 0 01-.242-.314l1.642-6.24a.5.5 0 01.48-.35h13.26a.5.5 0 01.48.35zM4.251 17.25a.5.5 0 01-.5-.5V14.8a.5.5 0 01.6-.49 3 3 0 012.34 2.34.5.5 0 01-.1.416.5.5 0 01-.387.184zM8 16.75v-1a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5zm9.31-.1a3 3 0 012.34-2.34.5.5 0 01.6.49v1.95a.5.5 0 01-.5.5H17.8a.5.5 0 01-.491-.6zm.638-14.025a.25.25 0 01-.216.375H16a.25.25 0 01-.25-.25v-1A.25.25 0 0116 1.5a2.252 2.252 0 011.948 1.125zM8 1.5a.25.25 0 01.25.25v1A.25.25 0 018 3H6.268a.25.25 0 01-.216-.375A2.252 2.252 0 018 1.5zM21.25 20a.75.75 0 01-.75.75h-17a.744.744 0 01-.337-1.41h17.674a.743.743 0 01.413.66z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});