define("ember-svg-jar/inlined/layers-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layers-off</title><path d=\"M15.176 10.826a.249.249 0 00.276.053L22.991 7.6a1.5 1.5 0 000-2.751L13.342.655a3.013 3.013 0 00-2.392 0L7.143 2.31a.25.25 0 00-.077.406zM1.115 5.251a.25.25 0 00-.369.017A1.5 1.5 0 001.3 7.6l2.622 1.14a.25.25 0 00.278-.406zM22.987 13.6a1.5 1.5 0 000-2.753l-1.245-.542a1 1 0 00-.8 1.834l.191.084-2.833 1.24a.251.251 0 00-.146.182.254.254 0 00.069.224l1.14 1.14a.252.252 0 00.277.053zM11.748 15.963l-8.594-3.736.191-.084a1 1 0 00-.8-1.834L1.3 10.85a1.5 1.5 0 000 2.752l9.649 4.2a3.008 3.008 0 002.392 0 .163.163 0 00.1-.12.16.16 0 00-.045-.147l-1.545-1.546c-.036-.016-.072-.012-.103-.026zM23.893 18.18a1.5 1.5 0 00-.9-1.375l-1.245-.541a.948.948 0 00-.649-.041.25.25 0 00-.107.417l2.308 2.313a.25.25 0 00.4-.053 1.429 1.429 0 00.193-.72zM12.545 21.917a1 1 0 01-.8 0l-8.591-3.736.191-.083a1 1 0 00-.8-1.834l-1.245.541a1.5 1.5 0 000 2.752l9.649 4.194a3 3 0 002.392 0L17.377 22a.248.248 0 00.146-.182.251.251 0 00-.068-.224l-1.14-1.14a.25.25 0 00-.277-.053zM1.814.293A1 1 0 00.4 1.708l21.5 21.5a1 1 0 101.414-1.415z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});