define("ember-svg-jar/inlined/arduino-plus-minus-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arduino-plus-minus-alternate</title><path d=\"M18.217 6.218c-2.952 0-5.093 2.307-6.217 3.889-1.124-1.582-3.265-3.889-6.217-3.889A5.593 5.593 0 000 12a5.594 5.594 0 005.783 5.783c2.952 0 5.093-2.307 6.217-3.89 1.124 1.583 3.265 3.89 6.217 3.89A5.594 5.594 0 0024 12a5.593 5.593 0 00-5.783-5.782zM5.783 15.784A3.62 3.62 0 012 12a3.62 3.62 0 013.783-3.782c2.527 0 4.408 2.69 5.068 3.781-.663 1.085-2.563 3.785-5.068 3.785zm12.434 0c-2.527 0-4.408-2.69-5.068-3.781.663-1.086 2.563-3.785 5.068-3.785A3.62 3.62 0 0122 12a3.62 3.62 0 01-3.783 3.784z\"/><path d=\"M7.5 10.968h-3a.75.75 0 000 1.5h3a.75.75 0 000-1.5zM19.5 10.968H19a.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25h-.5a.75.75 0 000 1.5h.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.5a.25.25 0 01.25-.25h.5a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});