define("ember-svg-jar/inlined/camping-tent-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camping-tent-person</title><path d=\"M13.125 14.245a4.856 4.856 0 01.235-1.439.251.251 0 00-.023-.2L9 5.281V4.209l2.775-1.233a1.01 1.01 0 00.593-.965 1 1 0 00-.682-.9L8.571.076a.763.763 0 00-.428-.06.741.741 0 00-.643.729v4.536L.266 17.484A1 1 0 001.125 19h12zm-5.759-1.534a1 1 0 01.881-.529 1 1 0 01.881.53l2.088 3.915A.25.25 0 0111 17H5.5a.25.25 0 01-.221-.368zM20.625 11h-2.5a3.254 3.254 0 00-3.25 3.25V19a1.25 1.25 0 001.25 1.25h.75v3.5a.25.25 0 00.25.25h4.5a.25.25 0 00.25-.25v-3.5h.75a1.251 1.251 0 001.25-1.25v-4.75a3.254 3.254 0 00-3.25-3.25zM21.875 7a2.475 2.475 0 00-.212-1h-4.576a2.475 2.475 0 00-.212 1 2.5 2.5 0 005 0zM22.252 5a1.01 1.01 0 001.123-1 1 1 0 00-1-1h-.5V1.645a.655.655 0 00-.3-.553.607.607 0 00-.608-.029l-1.595.831-1.6-.831a.607.607 0 00-.608.029.656.656 0 00-.3.553V3h-.5a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});