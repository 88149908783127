define("ember-svg-jar/inlined/tree-cloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tree-cloud</title><path d=\"M16.5 14.994a2 2 0 00-2 2 .5.5 0 01-.2.4l-.4.3a.25.25 0 01-.4-.2V14.95a.5.5 0 00-.56-.5 7.853 7.853 0 01-.933.056 7.7 7.7 0 01-.947-.058.5.5 0 00-.56.5V22.5a1.5 1.5 0 003 0v-1.756a.5.5 0 01.2-.4L15.5 19a.5.5 0 01.44-.08 2 2 0 10.561-3.921zM18.816 5.055a.5.5 0 01-.568-.357A6.5 6.5 0 006.641 2.826a.5.5 0 01-.479.214 5 5 0 102.1 9.119.5.5 0 01.526-.019 6.47 6.47 0 007.174-.5.437.437 0 01.592.053 4 4 0 102.264-6.64z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});