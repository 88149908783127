define("ember-svg-jar/inlined/antique-mace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>antique-mace</title><path d=\"M8.72 13.948a.5.5 0 00-.746-.043L.439 21.439a1.5 1.5 0 002.122 2.122l7.539-7.534a.5.5 0 00-.043-.747 8.031 8.031 0 01-1.337-1.332zM23 8h-1.171a.5.5 0 01-.488-.393 6.437 6.437 0 00-.873-2.107.5.5 0 01.067-.623l.829-.829a1 1 0 10-1.414-1.412l-.829.829a.5.5 0 01-.623.067 6.473 6.473 0 00-2.105-.874A.5.5 0 0116 2.17V1a1 1 0 00-2 0v1.171a.5.5 0 01-.393.488 6.428 6.428 0 00-2.1.873.5.5 0 01-.623-.067l-.829-.829A1 1 0 008.636 4.05l.829.829a.5.5 0 01.067.623 6.437 6.437 0 00-.873 2.105.5.5 0 01-.488.393H7a1 1 0 000 2h1.171a.5.5 0 01.488.393 6.51 6.51 0 004.949 4.949.5.5 0 01.392.488V17a1 1 0 002 0v-1.171a.5.5 0 01.393-.488 6.423 6.423 0 002.1-.873.5.5 0 01.623.067l.83.829a1 1 0 101.414-1.415l-.829-.828a.5.5 0 01-.067-.624 6.423 6.423 0 00.873-2.1.5.5 0 01.492-.397H23a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});