define("ember-svg-jar/inlined/cash-payment-bag-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-payment-bag-1</title><path d=\"M17.7 11.208a.251.251 0 00-.365 0 2.306 2.306 0 01-1.834.545h-4a4.593 4.593 0 01-.974-.105.251.251 0 00-.243.083A10.985 10.985 0 007.5 18.489c0 3.514 3.6 4.761 6.682 4.761 3.393 0 6.818-1.472 6.818-4.761 0-3.102-2-5.88-3.3-7.281zm-3.618 6.539c-.941-.634-1.831-1.232-1.831-2.122a1.342 1.342 0 011.273-1.49.249.249 0 00.227-.248v-.137a.75.75 0 011.5 0v.125a.25.25 0 00.25.25H16a.75.75 0 010 1.5h-1.555a.25.25 0 00-.144.454c.209.149.44.3.618.423.941.634 1.831 1.233 1.831 2.123a1.376 1.376 0 01-1.328 1.493.187.187 0 00-.172.187v.2a.75.75 0 01-1.5 0v-.125a.25.25 0 00-.25-.25H13a.75.75 0 010-1.5h1.544a.25.25 0 00.15-.45c-.208-.161-.436-.314-.613-.433zM16.566 3.753a.251.251 0 00.234-.123l1.255-2.13a.5.5 0 00-.43-.754h-6.246a.5.5 0 00-.43.755l1.395 2.132a.252.252 0 00.21.113z\"/><path d=\"M11.5 10.75h4c1 0 1.184-.051 1.5-1 .5-1.5 1-4 1-4 0-.5-.5-1-1.5-1h-4.488a.251.251 0 01-.21-.114l-1.03-1.58a.251.251 0 00-.413-.009L9.5 4.249a1.343 1.343 0 01-1 .5h-.75A.25.25 0 007.5 5v4.1a.249.249 0 00.138.223c1.868.934 2.709 1.427 3.862 1.427zM5.5 4.25H3.25A.25.25 0 003 4.5V10a.25.25 0 00.25.25H5.5a.472.472 0 00.5-.5v-5a.472.472 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});