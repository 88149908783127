define("ember-svg-jar/inlined/sailing-archor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sailing-archor</title><path d=\"M1.169 19.868A5.833 5.833 0 004.9 18.261a5.237 5.237 0 003.663 1.572 5.863 5.863 0 003.811-1.5 4.576 4.576 0 003.416 1.5 5.155 5.155 0 003.622-1.5 4.834 4.834 0 003.661 1.482A1 1 0 0024 18.751a1.022 1.022 0 00-1.068-.929c-1.59.134-2.667-1.555-2.675-1.569a1.049 1.049 0 00-1.716.022 3.372 3.372 0 01-2.753 1.558 2.8 2.8 0 01-2.4-1.481 1.044 1.044 0 00-1.717-.157 4.3 4.3 0 01-3.105 1.638 3.554 3.554 0 01-2.766-1.58 1.042 1.042 0 00-1.638-.091c-.014.019-1.473 1.838-3.11 1.7A.981.981 0 000 18.811c-.009.189.043 1.057 1.169 1.057zM12.006 15a6.24 6.24 0 006.243-5.956A1 1 0 0017.25 8h-1.038a.75.75 0 000 1.5h.488a4.732 4.732 0 01-3.636 3.874.254.254 0 01-.211-.049.247.247 0 01-.094-.195V7.5a.249.249 0 01.25-.25H14a.75.75 0 000-1.5h-.994a.249.249 0 01-.25-.25V4.362a2.25 2.25 0 10-1.5 0V5.5a.25.25 0 01-.25.25H10a.75.75 0 000 1.5h1.006a.25.25 0 01.25.25v5.63a.25.25 0 01-.094.195.257.257 0 01-.212.049A4.733 4.733 0 017.313 9.5H7.8a.75.75 0 000-1.5H6.761a1 1 0 00-1 1.047A6.24 6.24 0 0012.006 15zm0-13.5a.75.75 0 11-.75.75.752.752 0 01.75-.75z\"/><path d=\"M22.93 21.954c-1.59.15-2.667-1.556-2.675-1.569a1.049 1.049 0 00-1.716.022 3.372 3.372 0 01-2.753 1.558 2.8 2.8 0 01-2.4-1.482 1.045 1.045 0 00-1.717-.156 4.3 4.3 0 01-3.105 1.638 3.557 3.557 0 01-2.764-1.58 1.041 1.041 0 00-1.638-.091C4.148 20.312 2.771 22 1.17 22c-.952 0-1.151.577-1.169.942C-.009 23.13.043 24 1.169 24A5.838 5.838 0 004.9 22.393a5.242 5.242 0 003.663 1.572 5.863 5.863 0 003.811-1.5 4.576 4.576 0 003.416 1.5 5.155 5.155 0 003.622-1.5 4.788 4.788 0 003.661 1.482A1 1 0 0024 22.883a1.018 1.018 0 00-1.07-.929z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});