define("ember-svg-jar/inlined/monitor-heart-notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-heart-notes</title><path d=\"M21.5 5a2 2 0 00-2-2h-2.75a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25H19a.5.5 0 01.5.5v8.107a.25.25 0 01-.25.25h-1.8a1.574 1.574 0 00-1.456 1.159l-.026.078a.25.25 0 01-.483-.032l-.969-5.127a1.659 1.659 0 00-1.333-1.421 1.555 1.555 0 00-1.65 1.146l-1.162 3.513a.251.251 0 01-.467.022l-.511-1.16a1.536 1.536 0 00-1.329-.977 1.507 1.507 0 00-1.407.9l-.875 1.763a.25.25 0 01-.224.139H4.75a.25.25 0 01-.25-.25V5.5A.5.5 0 015 5h2.25a.25.25 0 00.25-.25v-1.5A.25.25 0 007.25 3H4.5a2 2 0 00-2 2v17a2 2 0 002 2h15a2 2 0 002-2zM19 22H5a.5.5 0 01-.5-.5v-5.394a.25.25 0 01.25-.25h1.3a1.511 1.511 0 001.349-.9l.581-1.17.864 1.959a1.52 1.52 0 001.517.97 1.592 1.592 0 001.325-1.152l.88-2.659a.25.25 0 01.483.032l.969 5.127a1.658 1.658 0 001.333 1.422 1.4 1.4 0 00.193.013A1.58 1.58 0 0017 18.34l.766-2.313a.25.25 0 01.234-.171h1.25a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5z\"/><path d=\"M10 6.5h4a1 1 0 001-1V3A3.009 3.009 0 0011.192.107 3.046 3.046 0 009 3.087V5.5a1 1 0 001 1zM12 2a.75.75 0 11-.75.75A.75.75 0 0112 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});