define("ember-svg-jar/inlined/army-symbol-air-defence", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-symbol-air-defence</title><path d=\"M23.973 5.5a2 2 0 00-2-2H2.027a2 2 0 00-2 2c0 13.738-.14 13.177.218 13.89l.01.019A2 2 0 002.027 20.5h19.946a1.994 1.994 0 001.771-1.09l.012-.021c.356-.713.217-.151.217-13.889zm-2.5 0a.5.5 0 01.5.5v9.323a.249.249 0 01-.4.2C19.414 13.959 16 13 12 13s-7.413.958-9.577 2.524a.25.25 0 01-.4-.2V6a.5.5 0 01.5-.5zm-.061 12.6a.248.248 0 01.027.263.253.253 0 01-.225.139H2.786a.25.25 0 01-.2-.4C3.846 16.461 7.15 15 12 15s8.154 1.461 9.412 3.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});