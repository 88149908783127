define("ember-svg-jar/inlined/smiley-lol-side", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-lol-side</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm4.349 16.349a6.166 6.166 0 01-5.942 1.592.149.149 0 01-.067-.25l7.349-7.35a.155.155 0 01.111-.041c.09.019.131.059.144.111a6.175 6.175 0 01-1.595 5.938zm-12.481-3a1 1 0 110-2h4.243a1 1 0 01.814 1.581l-1.769 2.48a1 1 0 01-1.627-1.163l.356-.5a.249.249 0 00-.2-.4zm10.714-8.16a1 1 0 011.2 1.6l-2.829 2.122a1 1 0 01-1.6-.8V3.868a1 1 0 112 0v1.743a.25.25 0 00.4.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});