define("ember-svg-jar/inlined/playlist-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>playlist-warning</title><path d=\"M14.892 11.574c.4-.762.6-1.3 1.416-1.493a.249.249 0 00.192-.243V2.5A2.5 2.5 0 0014 0H2.5A2.5 2.5 0 000 2.5v12A2.5 2.5 0 002.5 17h9.393a.25.25 0 00.221-.134zM12.5 7a1 1 0 01-2 0 1.77 1.77 0 00-1.151-1.729A.25.25 0 009 5.5v5.552A2.5 2.5 0 116.5 8.5c.077 0 .152 0 .227.01A.249.249 0 007 8.261V4a1 1 0 011-1c1.557 0 4.5.836 4.5 4zM18.781 12.271a1.448 1.448 0 00-2.56 0l-5.056 9.634a1.426 1.426 0 00.048 1.408 1.453 1.453 0 001.232.687h10.112a1.454 1.454 0 001.232-.686 1.428 1.428 0 00.048-1.409zM16.751 16a.75.75 0 011.5 0v3a.75.75 0 01-1.5 0zm.75 6.25a1 1 0 111-1 1 1 0 01-1.001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});