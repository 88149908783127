define("ember-svg-jar/inlined/real-estate-building-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-building-house</title><path d=\"M23.823 17.618l-6.5-5.5a.5.5 0 00-.647 0l-6.5 5.5a.5.5 0 00.323.882h1a.5.5 0 01.5.5v4.5a.5.5 0 00.5.5H15a.5.5 0 00.5-.5v-2.25a1.5 1.5 0 013 0v2.25a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5V19a.5.5 0 01.5-.5h1a.5.5 0 00.323-.882z\"/><path d=\"M10.5 19.5a1.5 1.5 0 01-.969-2.644l2.293-1.941a.5.5 0 00.176-.382V6.3a1 1 0 00-.518-.877l-.973-.535a.5.5 0 01-.259-.438V1a1 1 0 00-2 0v1.8a.5.5 0 01-.741.438L1.92.161A1.3 1.3 0 000 1.3V5a.5.5 0 00.5.5h3.75a.75.75 0 010 1.5H.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h4.75a.75.75 0 010 1.5H.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h6.75a.75.75 0 010 1.5H.5a.5.5 0 00-.5.5v9a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a1 1 0 011-1h2a1 1 0 011 1v3a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V20a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});