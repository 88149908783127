define("ember-svg-jar/inlined/glasses-ski", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>glasses-ski</title><path d=\"M23.477 9.25a.25.25 0 00.222-.365 2.547 2.547 0 00-1.005-1.05C20.3 6.482 15.6 6 12 6s-8.3.482-10.694 1.835A2.543 2.543 0 00.3 8.885a.251.251 0 00.222.365zM23.939 11.021a.25.25 0 00-.249-.271H.31a.25.25 0 00-.249.271q.12 1.426.319 2.524a.25.25 0 00.246.205h9.924a.5.5 0 00.391-.188 1.279 1.279 0 012.118 0 .5.5 0 00.391.188h9.924a.251.251 0 00.246-.205q.198-1.098.319-2.524zM1.158 15.25a.25.25 0 00-.233.342 2.612 2.612 0 001.051 1.43c1.845.967 5.814.978 5.982.978 1.138 0 1.6-1.251 2.04-2.462a.215.215 0 00-.2-.288zM14.2 15.25a.214.214 0 00-.2.288c.444 1.211.9 2.462 2.04 2.462.168 0 4.137-.011 5.982-.978a2.612 2.612 0 001.051-1.43.25.25 0 00-.233-.342z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});