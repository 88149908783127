define("ember-svg-jar/inlined/pin-remove-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin-remove-4</title><path d=\"M12 0a8.51 8.51 0 00-8.5 8.5c0 4.625 6.415 13.391 7.7 15.1a1 1 0 001.6 0c1.286-1.71 7.7-10.475 7.7-15.1A8.51 8.51 0 0012 0zm4.065 11.9a1 1 0 11-1.414 1.414l-2.3-2.3a.5.5 0 00-.708 0l-2.3 2.3A1 1 0 117.935 11.9l2.3-2.3a.5.5 0 000-.708l-2.3-2.3a1 1 0 111.414-1.407l2.3 2.3a.5.5 0 00.708 0l2.3-2.3A1 1 0 1116.065 6.6l-2.3 2.3a.5.5 0 000 .708z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});