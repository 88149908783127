define("ember-svg-jar/inlined/discount-circle-dash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>discount-circle-dash</title><path d=\"M2 11.947a1.017 1.017 0 00-1-.995 1 1 0 00-1 1.005 12.018 12.018 0 002.723 7.55 1 1 0 001.545-1.269A10.012 10.012 0 012 11.947zM15.37 21.314a10.09 10.09 0 01-6.691.017 1 1 0 10-.664 1.886 12.057 12.057 0 008.029-.02 1 1 0 00-.674-1.883zM20.471 5.876a1 1 0 00.768-1.638A12.025 12.025 0 0014.345.124a1 1 0 00-.389 1.962A10.025 10.025 0 0119.7 5.514a1 1 0 00.771.362zM9.183 1.213A1 1 0 007.9.614a12.056 12.056 0 00-6.193 5.108 1 1 0 001.715 1.03 10.052 10.052 0 015.163-4.258 1 1 0 00.598-1.281zM23.872 10.141a1 1 0 10-1.978.291A10.2 10.2 0 0122 11.9a10.023 10.023 0 01-2.236 6.3 1 1 0 101.552 1.261 12.017 12.017 0 002.556-9.319zM8.884 16.779l8-8a1.25 1.25 0 00-1.768-1.767l-8 8a1.25 1.25 0 001.768 1.767z\"/><circle cx=\"8.5\" cy=\"8.395\" r=\"2\"/><circle cx=\"15.5\" cy=\"15.395\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});