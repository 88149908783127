define("ember-svg-jar/inlined/modern-weapon-shotgun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-weapon-shotgun</title><path d=\"M23.924 3.065l-.9-1.747a.5.5 0 00-.684-.218L7.747 8.859a.5.5 0 00-.208.675l.924 1.756a.5.5 0 00.677.209l14.572-7.768a.5.5 0 00.212-.666zM6.646 10a.5.5 0 00-.676-.2l-3.544 1.878A4.495 4.495 0 00.4 17.459a.492.492 0 01.037.276 8.162 8.162 0 00.888 4.648 1 1 0 001.375.457l2.257-1.2a1.007 1.007 0 00.472-1.223c-.146-.408-.265-.8-.366-1.165a.25.25 0 01.125-.286l2.2-1.161a1.754 1.754 0 00.726-2.369l-.469-.884.661-.35a.5.5 0 00.207-.676zm.043 6.478l-1.607.848a.25.25 0 01-.367-.22 1.456 1.456 0 01.37-1.194l1.017-.54a.248.248 0 01.338.1l.352.663a.251.251 0 01-.103.347zM18.7 8.119a.5.5 0 00-.676-.207l-8.409 4.461a.5.5 0 00-.207.676l.706 1.33a1.481 1.481 0 00.875.73 1.54 1.54 0 00.451.067 1.5 1.5 0 00.705-.176l.87-.462a.5.5 0 00.208-.676l-.47-.886a.5.5 0 11.883-.469l.47.886a.5.5 0 00.676.207l1.325-.7a.5.5 0 00.207-.676l-.47-.886a.5.5 0 11.884-.468l.47.885a.5.5 0 00.676.207l.909-.482a1.5 1.5 0 00.622-2.031z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});