define("ember-svg-jar/inlined/shield-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shield-check</title><path d=\"M22 3.775a1.537 1.537 0 00-.89-1.406A22.143 22.143 0 0012 .5a22.143 22.143 0 00-9.11 1.869A1.537 1.537 0 002 3.775v7.637A12.285 12.285 0 009.828 22.88l1.067.414a3.063 3.063 0 002.21 0l1.067-.414A12.285 12.285 0 0022 11.412zm-3.492 4.464l-6.035 8.232a1.251 1.251 0 01-.912.507h-.1a1.248 1.248 0 01-.884-.367L6 12.036a1.25 1.25 0 011.768-1.767l3.341 3.342a.25.25 0 00.379-.029l5-6.821a1.25 1.25 0 112.016 1.478z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});