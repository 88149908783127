define("ember-svg-jar/inlined/treasure-chest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>treasure-chest</title><path d=\"M6.107 3H4.5A4.507 4.507 0 000 7.5V9a.5.5 0 00.5.5h4.75a.5.5 0 00.5-.5V6a6.486 6.486 0 01.582-2.643A.249.249 0 006.107 3zM15.606 3.1a.5.5 0 00-.294-.1H8.7a.5.5 0 00-.29.092C7.278 3.9 7.25 5.9 7.25 6v3a.5.5 0 00.5.5h8.5a.5.5 0 00.5-.5V6c0-.1-.028-2.091-1.144-2.9zM19.5 3h-1.607a.25.25 0 00-.226.357A6.473 6.473 0 0118.25 6v3a.5.5 0 00.5.5h4.75A.5.5 0 0024 9V7.5A4.507 4.507 0 0019.5 3zM5.25 11H.5a.5.5 0 00-.5.5v7A2.5 2.5 0 002.5 21h2.75a.5.5 0 00.5-.5v-9a.5.5 0 00-.5-.5zM16.25 11H15a.5.5 0 00-.5.5v1a2.5 2.5 0 01-5 0v-1A.5.5 0 009 11H7.75a.5.5 0 00-.5.5v9a.5.5 0 00.5.5h8.5a.5.5 0 00.5-.5v-9a.5.5 0 00-.5-.5zM23.5 11h-4.75a.5.5 0 00-.5.5v9a.5.5 0 00.5.5h2.75a2.5 2.5 0 002.5-2.5v-7a.5.5 0 00-.5-.5z\"/><circle cx=\"12\" cy=\"12.499\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});