define("ember-svg-jar/inlined/playlist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>playlist</title><path d=\"M18 4H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2zm-3.545 4.958l-3.409 1.1c-.21.044-.3.148-.3.268v4.924A1.75 1.75 0 119 13.5c.05 0 .1 0 .148.006a.092.092 0 00.072-.024.1.1 0 00.03-.069v-3.084a1.753 1.753 0 011.428-1.72l3.409-1.1A1.746 1.746 0 0116.25 9.2v4.05a1.75 1.75 0 11-1.75-1.75.249.249 0 00.25-.25V9.2a.25.25 0 00-.295-.242zM1 6a1 1 0 00-1 1v10.5a1 1 0 002 0V7a1 1 0 00-1-1zM23 6a1 1 0 00-1 1v10.5a1 1 0 002 0V7a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});