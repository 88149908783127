define("ember-svg-jar/inlined/religion-man-muslim-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-man-muslim-2</title><path d=\"M22.533 19.292a12.437 12.437 0 00-2.733-1.521 8.351 8.351 0 00.44-1.563 16.308 16.308 0 00-.24-6.78 13.655 13.655 0 00-1.26-3.277A6.539 6.539 0 0012 .125a6.539 6.539 0 00-6.738 6.026A13.662 13.662 0 004 9.428a16.308 16.308 0 00-.241 6.78 8.351 8.351 0 00.44 1.563 12.413 12.413 0 00-2.734 1.521A2.467 2.467 0 00.5 21.269v2.106a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-2.106a2.467 2.467 0 00-.967-1.977zM6.372 7.265a.252.252 0 01.225-.14H7a.249.249 0 01.25.247 30.27 30.27 0 003.1 13.8.25.25 0 01-.323.336 7.429 7.429 0 01-4.788-5.549 14.513 14.513 0 011.133-8.694zM12 1.625a5.138 5.138 0 015.128 3.81.157.157 0 01-.153.19H7.143a.251.251 0 01-.243-.312A5.156 5.156 0 0112 1.625zm-1.649 11.751a.749.749 0 01.726-.565h1.846a.75.75 0 01.357 1.409 2.731 2.731 0 01-1.275.3H12a2.678 2.678 0 01-1.279-.3.75.75 0 01-.37-.844zM15.239 7.9a2.113 2.113 0 00-2.373.19.751.751 0 001.006 1.114.628.628 0 01.625 0 .757.757 0 00.257.151.749.749 0 00.425.006c-.03.516-.071 1.008-.119 1.486a1.145 1.145 0 01-1.271.388 5.686 5.686 0 00-3.578 0 1.174 1.174 0 01-1.272-.4 37.03 37.03 0 01-.116-1.442.731.731 0 00.353-.11.8.8 0 00.131-.079.628.628 0 01.625 0 .751.751 0 001.006-1.114 2.108 2.108 0 00-2.181-.282v-.687h6.492c-.004.265-.004.531-.01.779zm-5.023 8.989a4.4 4.4 0 003.568 0q-.263.774-.553 1.5a5.806 5.806 0 01-2.462 0q-.291-.723-.553-1.496zm8.545-.939a7.394 7.394 0 01-4.8 5.569.251.251 0 01-.322-.338 30.259 30.259 0 003.11-13.809.249.249 0 01.251-.247h.406a.249.249 0 01.224.14 14.418 14.418 0 011.131 8.689z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});