define("ember-svg-jar/inlined/barbecue-stick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>barbecue-stick</title><path d=\"M7.207 14.672a.5.5 0 00-.707 0L.586 20.586a2 2 0 002.828 2.828L9.328 17.5a.5.5 0 000-.707zM23.707 4.536a1 1 0 00-1.414 0L14.728 12.1A2 2 0 0111.9 9.272l7.565-7.565A1 1 0 0018.05.293l-7.565 7.565a3.993 3.993 0 00-.769 4.542.5.5 0 01-.1.568l-.825.825a.5.5 0 000 .707l.707.707a.5.5 0 00.707 0l.824-.824a.5.5 0 01.571-.1 3.949 3.949 0 001.711.4 3.992 3.992 0 002.829-1.17l7.567-7.563a1 1 0 000-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});