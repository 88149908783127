define("ember-svg-jar/inlined/award-medal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>award-medal</title><path d=\"M23.423.359A.5.5 0 0023 .125h-5a.5.5 0 00-.452.285l-2.883 6.055a.25.25 0 00.152.346 9.511 9.511 0 014.136 2.608.25.25 0 00.409-.062L23.451.842a.5.5 0 00-.028-.483zM9.184 6.811a.248.248 0 00.154-.138.252.252 0 000-.208L6.452.41A.5.5 0 006 .125H1a.5.5 0 00-.451.717l4.088 8.516a.25.25 0 00.409.062 9.508 9.508 0 014.138-2.609zM12 7.875a8 8 0 108 8 8.009 8.009 0 00-8-8zm3.725 7.252l-1.559 1.534a.25.25 0 00-.054.278l.863 1.983a.507.507 0 01-.722.635l-2.129-1.2a.251.251 0 00-.246 0l-2.129 1.2a.507.507 0 01-.722-.635l.863-1.983a.25.25 0 00-.054-.278l-1.559-1.534a.474.474 0 01.333-.827h1.778a.251.251 0 00.227-.145l.93-2a.513.513 0 01.912 0l.928 2a.251.251 0 00.227.145h1.778a.474.474 0 01.335.827z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});