define("ember-svg-jar/inlined/gesture-expand-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-expand-1</title><path d=\"M9 3.5h1.25a.25.25 0 01.25.25v1.5a1 1 0 002 0v-1.5a.25.25 0 01.25-.25H14a.75.75 0 00.469-1.335l-2.5-2a.749.749 0 00-.938 0l-2.5 2A.75.75 0 009 3.5zM14 20.5h-1.25a.25.25 0 01-.25-.25v-1.5a1 1 0 00-2 0v1.5a.25.25 0 01-.25.25H9a.75.75 0 00-.469 1.335l2.5 2a.752.752 0 00.938 0l2.5-2A.75.75 0 0014 20.5zM5.25 10.5h-1.5a.25.25 0 01-.25-.25V9a.751.751 0 00-1.336-.469l-2 2.5a.752.752 0 000 .938l2 2.5A.751.751 0 003.5 14v-1.25a.25.25 0 01.25-.25h1.5a1 1 0 000-2zM23.836 11.031l-2-2.5A.751.751 0 0020.5 9v1.25a.25.25 0 01-.25.25h-1.5a1 1 0 000 2h1.5a.25.25 0 01.25.25V14a.751.751 0 001.336.469l2-2.5a.752.752 0 000-.938zM12.662 10.542a.251.251 0 01-.162-.234V8.48a1 1 0 10-2 0v3.367a.25.25 0 01-.409.193c-1.076-.886-2.658-.192-1.591 1.44l1.694 2.78a.5.5 0 00.427.24h4.609a.5.5 0 00.484-.375c.218-.839.644-2.483.753-2.955.313-1.36-1.007-1.576-3.805-2.628z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});