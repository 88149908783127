define("ember-svg-jar/inlined/bus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bus</title><path d=\"M20.252 6.5A.251.251 0 0120 6.248V2.5A2.5 2.5 0 0017.5 0h-11A2.5 2.5 0 004 2.5v3.75a.25.25 0 01-.25.25H1.5A1.5 1.5 0 000 8v5a1.5 1.5 0 001.5 1.5 1 1 0 001-1v-5h1.25a.25.25 0 01.25.25v10.729a1.5 1.5 0 001.286 1.484.25.25 0 01.214.248V23a1 1 0 001 1h2a1 1 0 001-1v-1.771a.249.249 0 01.25-.25h4.5a.249.249 0 01.25.25V23a1 1 0 001 1h2a1 1 0 001-1v-1.789a.251.251 0 01.214-.248A1.5 1.5 0 0020 19.479V8.75a.25.25 0 01.25-.25h1.25v4.979a1 1 0 001 1 1.5 1.5 0 001.5-1.5v-5a1.5 1.5 0 00-1.5-1.5zm-2.752 1a.5.5 0 01.5.5v6a.5.5 0 01-.5.5h-11A.5.5 0 016 14V8a.5.5 0 01.5-.5zM9.5 4V3a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5zm.75 14a.75.75 0 01-.75.75h-3a.75.75 0 010-1.5h3a.75.75 0 01.75.75zm4.25.75a.75.75 0 010-1.5h3a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});