define("ember-svg-jar/inlined/religion-woman-muslim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-woman-muslim</title><path d=\"M22.836 17.77a13.654 13.654 0 00-4.279-2.107.25.25 0 01-.121-.395A7.111 7.111 0 0020 10.25c0-2.69-1.785-10-8-10s-8 7.31-8 10a7.111 7.111 0 001.564 5.018.25.25 0 01-.121.395 13.673 13.673 0 00-4.28 2.107A2.961 2.961 0 000 20.144v2.606a1 1 0 002 0v-2.606a.979.979 0 01.376-.785 11.114 11.114 0 012.87-1.521.251.251 0 01.335.2c.175 1.315 2.074 3.21 6.419 3.21s6.244-1.894 6.419-3.21a.25.25 0 01.335-.2 11.059 11.059 0 012.869 1.52.977.977 0 01.377.785v2.607a1 1 0 002 0v-2.606a2.959 2.959 0 00-1.164-2.374zM12 13.75a4.505 4.505 0 01-4.5-4.5v-.939a1.5 1.5 0 01.739-1.294A7.819 7.819 0 0112 5.75a7.823 7.823 0 013.761 1.267A1.5 1.5 0 0116.5 8.31v.94a4.505 4.505 0 01-4.5 4.5z\"/><circle cx=\"14\" cy=\"8.75\" r=\"1\"/><circle cx=\"10\" cy=\"8.75\" r=\"1\"/><path d=\"M13.077 10.881h-2a.639.639 0 00-.3 1.2 2.451 2.451 0 00.435.18 2.753 2.753 0 00.866.126h.014a3 3 0 00.856-.125 2.433 2.433 0 00.436-.181.64.64 0 00-.3-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});