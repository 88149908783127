define("ember-svg-jar/inlined/love-gift-box-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-gift-box-heart</title><path d=\"M23.643 9.88a.5.5 0 00-.524.047c-.358.268-9.511 6.79-9.646 6.886a2.557 2.557 0 01-2.946 0c-.1-.071-9.548-6.807-9.654-6.885a.5.5 0 00-.8.4v1.5A4.838 4.838 0 002 15.714l9.258 6.794a1.223 1.223 0 00.729.243 1.2 1.2 0 00.716-.237l9.3-6.8a4.836 4.836 0 001.919-3.879v-1.5a.5.5 0 00-.279-.455z\"/><path d=\"M1.985 8.877l3.993 2.879a.5.5 0 00.574 0L20 2.407a.5.5 0 00-.163-.9A9 9 0 0017 1.275a6.716 6.716 0 00-5 2.453 6.716 6.716 0 00-5-2.453A7.038 7.038 0 00.785 3.633a3.345 3.345 0 00-.663 2.6 4.13 4.13 0 001.863 2.644zM8.477 13.263a.5.5 0 00.211.41l2.706 1.915a1.052 1.052 0 001.212 0L22 8.883a4.144 4.144 0 001.873-2.655 3.35 3.35 0 00-.664-2.595 4.066 4.066 0 00-.194-.244.5.5 0 00-.665-.089L8.691 12.855a.5.5 0 00-.214.408z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});