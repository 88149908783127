define("ember-svg-jar/inlined/police-woman-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-woman-2</title><path d=\"M22.248 19.936a7.1 7.1 0 00-3.056-1.157c-.265-.055-3.435-.844-4.234-1.043a.5.5 0 00-.345.038 6.156 6.156 0 01-2.633.726 6.15 6.15 0 01-2.619-.72.5.5 0 00-.344-.037c-.795.2-3.942.981-4.207 1.036a7.107 7.107 0 00-3.057 1.157A4.529 4.529 0 000 23.5a.5.5 0 00.5.5h23a.5.5 0 00.5-.5 4.529 4.529 0 00-1.752-3.564zm-4.357 3.158A2 2 0 0117 21.43a.429.429 0 01.43-.43h2.14a.429.429 0 01.43.43 2 2 0 01-.891 1.664l-.609.406zM18.955 6a.312.312 0 00.3-.391 7.5 7.5 0 00-14.443-.252.5.5 0 00.48.643zM14 2.93a2.5 2.5 0 01-1.113 2.08l-.61.406a.5.5 0 01-.554 0l-.61-.406A2.5 2.5 0 0110 2.93V2a.5.5 0 01.5-.5h3a.5.5 0 01.5.5zM10.47 13.667a3.167 3.167 0 001.535.36 3.251 3.251 0 001.527-.36.75.75 0 10-.719-1.317 1.753 1.753 0 01-.808.177 1.776 1.776 0 01-.816-.178.75.75 0 10-.715 1.318z\"/><path d=\"M8.12 15.405a6.618 6.618 0 003.859 1.845 6.634 6.634 0 003.885-1.869A2.2 2.2 0 0017.85 16.5a5.057 5.057 0 003.54-1.705.5.5 0 00-.232-.788 3.062 3.062 0 01-1.678-1.679 7.7 7.7 0 01-.53-1.457 3.1 3.1 0 00.148-.349 2.71 2.71 0 00-.028-1.794A2.063 2.063 0 0019.5 7.5.5.5 0 0019 7H5a.5.5 0 00-.5.5 2.046 2.046 0 00.4 1.188 2.676 2.676 0 00-.055 1.788 2.945 2.945 0 00.186.458 7.509 7.509 0 01-.529 1.422 3.042 3.042 0 01-1.663 1.649.5.5 0 00-.232.788A5.057 5.057 0 006.15 16.5a2.244 2.244 0 001.97-1.095zm3.859.345c-1.032 0-3.83-1.49-4.207-3.738a1.747 1.747 0 00-1-1.306 1.04 1.04 0 01-.485-.663 1.605 1.605 0 01-.049-.232A12.484 12.484 0 0012 11a12.923 12.923 0 005.72-1.166 1.586 1.586 0 01-.053.233 1.024 1.024 0 01-.476.639 1.746 1.746 0 00-1 1.3c-.381 2.254-3.179 3.744-4.212 3.744z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});