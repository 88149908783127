define("ember-svg-jar/inlined/medical-ribbon-cancer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-ribbon-cancer</title><path d=\"M18.12 20.119a27.617 27.617 0 01-3.064-2.455.5.5 0 01-.046-.685C17.439 14.038 19 11.551 19 8.4c0-4.36-3.14-7.9-7-7.9S5 4.039 5 8.4c0 3.3 1.717 5.831 3.99 8.58a.5.5 0 01-.046.685 27.5 27.5 0 01-3.064 2.454.5.5 0 00-.14.69l1.63 2.47a.535.535 0 00.32.21.463.463 0 00.38-.08 30.872 30.872 0 003.591-2.861.5.5 0 01.678 0 30.987 30.987 0 003.591 2.862.463.463 0 00.38.08.535.535 0 00.32-.21l1.63-2.47a.5.5 0 00-.14-.691zM9 8.949c-.31-2.47 1.34-4.5 3-4.5s3.31 2.03 3 4.5A11.22 11.22 0 0112.391 14a.5.5 0 01-.782 0A11.215 11.215 0 019 8.949z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});