define("ember-svg-jar/inlined/amphibian-chameleon-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>amphibian-chameleon-1</title><path d=\"M18.874 9.917c-4.649 0-4.6 4.853-4.521 8.31.009.376.015.736.017 1.09a3.022 3.022 0 01-3.654-.712 1.71 1.71 0 01-.326-.624 2.855 2.855 0 00.691-.012 2.409 2.409 0 001.994-1.736 1 1 0 10-1.9-.633.5.5 0 01-.576.384c.093-.378.183-.721.268-1.039a11.143 11.143 0 00.464-2.458 2.375 2.375 0 00.247-.018 2.409 2.409 0 001.994-1.736 1 1 0 10-1.9-.633.5.5 0 01-.524.388 15.344 15.344 0 00-.277-1.176V7.231l1.831.844a1 1 0 00.838-1.816l-2.663-1.231c0-3.078-1.929-4.861-3.75-4.861s-3.75 1.771-3.75 4.905l-2.23 1.217a1 1 0 00.959 1.756l1.271-.694v1.975c-.1.4-.17.789-.233 1.167-.275.023-.448-.029-.569-.393a1 1 0 00-1.9.633 2.4 2.4 0 002.277 1.756 13.784 13.784 0 00.359 3.478c-.313 0-.564.145-.735-.368a1 1 0 00-1.9.633 2.412 2.412 0 002 1.736A2.985 2.985 0 003.84 17.9a7.419 7.419 0 007.534 5.771 7.909 7.909 0 003.04-.573 5.312 5.312 0 005.111.159c1.438-.736 3.849-2.861 3.849-8.836a4.5 4.5 0 00-4.5-4.504zm-13.5-7.5a1 1 0 11-1 1 1 1 0 011.003-1zm4.5 1a1 1 0 11-1-1 1 1 0 011.003 1zm8.738 18.055a3.315 3.315 0 01-2.178.282 9.617 9.617 0 002.5-5.526c.114-.668-.093-1.007-.535-1.1a.747.747 0 00-.891.556 5.211 5.211 0 01-1.158 2.286c-.094-4.3.3-6.049 2.525-6.049a2.5 2.5 0 012.5 2.5c-.001 3.635-.981 6.141-2.76 7.051z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});