define("ember-svg-jar/inlined/zip-file-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>zip-file-search</title><path d=\"M9.616 15.209a7 7 0 013.93-5.032 6.8 6.8 0 014.161-.569.246.246 0 00.293-.241v-4.16a1.5 1.5 0 00-.439-1.06L13.854.439A1.5 1.5 0 0012.793 0H1.5A1.5 1.5 0 000 1.5v19A1.5 1.5 0 001.5 22h10.014a.249.249 0 00.18-.424 6.981 6.981 0 01-2.078-6.367zM6 18.5a1 1 0 111-1 1 1 0 01-1 1zM6 15a1 1 0 111-1 1 1 0 01-.995 1zm0-3.5a1 1 0 111-1 1 1 0 01-.995 1zm1.5-4.9a1.968 1.968 0 01-1.479.67 1.975 1.975 0 01-2.005-2.221l.49-3.127A.5.5 0 015 1.5h2a.5.5 0 01.494.423l.485 3.111v.015A1.985 1.985 0 017.508 6.6z\"/><path d=\"M23.707 23.707a1 1 0 000-1.414l-2.5-2.506a.242.242 0 01-.031-.3 5.515 5.515 0 10-1.688 1.688.241.241 0 01.3.03l2.506 2.506a1 1 0 001.413-.004zm-7.174-3.674a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});