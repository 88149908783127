define("ember-svg-jar/inlined/cursor-select-circle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-select-circle-1</title><circle cx=\"12\" cy=\"11.998\" r=\"3.999\"/><path d=\"M22.018 7.352a1 1 0 00.364-1.367 12.088 12.088 0 00-4.367-4.374 1 1 0 00-1.007 1.728 10.093 10.093 0 013.644 3.649 1 1 0 001.366.364zM7.007 3.33A10.087 10.087 0 0111.988 2a1 1 0 000-2 12.082 12.082 0 00-5.97 1.6 1 1 0 00.989 1.73zM2 11.989a10.1 10.1 0 011.337-4.981 1 1 0 10-1.733-1A12.1 12.1 0 000 11.98a1 1 0 001 1 1 1 0 001-.991zM6.992 20.657a10.093 10.093 0 01-3.644-3.649 1 1 0 10-1.73 1 12.1 12.1 0 004.367 4.374 1 1 0 001.007-1.728zM16.993 20.666A10.087 10.087 0 0112.012 22a1 1 0 000 2 12.082 12.082 0 005.97-1.6 1 1 0 00-.991-1.736zM23.005 11.012a1 1 0 00-1 .995 10.1 10.1 0 01-1.338 4.981 1 1 0 001.733 1 12.1 12.1 0 001.6-5.973 1 1 0 00-.995-1.003z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});