define("ember-svg-jar/inlined/car-tool-spark-plug.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-tool-spark-plug</title><path d=\"M16 8.736h-1.25a.25.25 0 01-.25-.25v-3.5a2.5 2.5 0 00-.264-1.118l-.71-1.419a.256.256 0 01-.026-.112v-.823a1.5 1.5 0 10-3 0v.823a.256.256 0 01-.026.112l-.71 1.419A2.5 2.5 0 009.5 4.986v3.5a.25.25 0 01-.25.25H8a1.5 1.5 0 000 3h8a1.5 1.5 0 000-3zM14.5 12.736h-5a1 1 0 000 2h5a1 1 0 000-2zM14.5 15.736h-5a1 1 0 000 2h5a1 1 0 000-2zM14.5 18.736h-5a1 1 0 000 2h2a.25.25 0 01.25.25v.75a.25.25 0 01-.25.25H9.25a1 1 0 000 2h2.5a2 2 0 002-2v-1a.25.25 0 01.25-.25h.5a1 1 0 100-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});