define("ember-svg-jar/inlined/lab-tube-bottle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lab-tube-bottle</title><path d=\"M11.294 8.708A.5.5 0 0111 8.252V1a1 1 0 00-1-1H6a1 1 0 00-1 1v7.252a.5.5 0 01-.294.456 8 8 0 106.588 0zm2.681 7.838a.5.5 0 01-.5.454H2.523a.5.5 0 01-.5-.454A6.571 6.571 0 012 16a5.975 5.975 0 013.75-5.563A1.993 1.993 0 007 8.582V2.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v6.082a1.991 1.991 0 001.25 1.854A5.977 5.977 0 0114 16c0 .184-.008.366-.025.546z\"/><path d=\"M22 2h-4a2 2 0 00-2 2v7a1 1 0 002 0V4.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v3a.5.5 0 01-.5.5H21a1 1 0 000 2h.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H21a1 1 0 000 2h.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H21a1 1 0 000 2h.5a.5.5 0 01.5.5v1.387a2.079 2.079 0 01-1.661 2.085 2 2 0 01-2.222-1.3 1.018 1.018 0 00-.956-.672.991.991 0 00-.945 1.3 4.008 4.008 0 004.184 2.681 4.122 4.122 0 003.6-4.159V4a2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});