define("ember-svg-jar/inlined/py-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>py-1</title><path d=\"M9.5 12.126H8.375a.25.25 0 00-.25.25v2.25a.25.25 0 00.25.25H9.5a1.375 1.375 0 000-2.75z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM9.4 16.126H8.375a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0v-8a.625.625 0 01.625-.625h2a2.629 2.629 0 012.574 3.148A2.7 2.7 0 019.4 16.126zm8.725-2.626a2.621 2.621 0 01-1.825 2.492.247.247 0 00-.176.233V19.5a.625.625 0 11-1.25 0v-3.275a.247.247 0 00-.176-.233 2.621 2.621 0 01-1.823-2.492v-2a.625.625 0 111.25 0v2a1.375 1.375 0 002.75 0v-2a.625.625 0 111.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});