define("ember-svg-jar/inlined/social-smug-mug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-smug-mug</title><path d=\"M23.63 7.57a1 1 0 00-.75-.34h-20a1 1 0 00-.88.5C.15 11-.74 16.33 1.27 19.79c.92 1.59 2.87 3.48 6.89 3.48 13.51 0 15.68-14.76 15.7-14.91a1 1 0 00-.23-.79zM8.16 21.27C5.7 21.27 4 20.43 3 18.78c-1.49-2.56-.94-6.73.47-9.56h18.19c-.74 3.22-3.66 12.05-13.5 12.05zM17.33 4.81c1.61 0 2.87-.9 2.87-2s-1.26-2-2.87-2-2.88.9-2.88 2 1.27 2 2.88 2zM6.33 4.81c1.61 0 2.87-.9 2.87-2s-1.26-2-2.87-2-2.88.9-2.88 2 1.27 2 2.88 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});