define("ember-svg-jar/inlined/landmarks-monument", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmarks-monument</title><path d=\"M19 23.41v-.5a1.5 1.5 0 00-1.286-1.485.249.249 0 01-.214-.247v-.268a1.5 1.5 0 00-1.5-1.5H8a1.5 1.5 0 00-1.5 1.5v.268a.249.249 0 01-.214.247A1.5 1.5 0 005 22.91v.5a.5.5 0 00.5.5h13a.5.5 0 00.5-.5zM15 18.41a.5.5 0 00.5-.517L15.022 3.63a1.445 1.445 0 00-.4-.931L12.774.441a1.029 1.029 0 00-1.547 0l-1.812 2.22a1.5 1.5 0 00-.438.982L8.5 17.893a.5.5 0 00.5.517z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});