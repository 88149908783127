define("ember-svg-jar/inlined/transportation-ticket-bus-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transportation-ticket-bus-alternate</title><path d=\"M23.25 9.5a1 1 0 001-1V5a2 2 0 00-2-2H1.75a2 2 0 00-2 2v3.5a1 1 0 001 1 2.5 2.5 0 010 5 1 1 0 00-1 1V19a2 2 0 002 2h20.5a2 2 0 002-2v-3.5a1 1 0 00-1-1 2.5 2.5 0 010-5zm-1.184 6.842a.249.249 0 01.184.241v2.167A.25.25 0 0122 19H2a.25.25 0 01-.25-.25v-2.167a.249.249 0 01.184-.241 4.5 4.5 0 000-8.684.249.249 0 01-.184-.241V5.25A.25.25 0 012 5h20a.25.25 0 01.25.25v2.167a.249.249 0 01-.184.241 4.5 4.5 0 000 8.684z\"/><path d=\"M14.5 7.25h-5A1.752 1.752 0 007.75 9v5.75a1.746 1.746 0 00.875 1.509.25.25 0 01.125.216v.775a.75.75 0 001.5 0v-.5a.25.25 0 01.25-.25h3a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.775a.25.25 0 01.125-.216 1.746 1.746 0 00.875-1.509V9a1.752 1.752 0 00-1.75-1.75zm-4.5 7.5a.75.75 0 11.75-.75.75.75 0 01-.75.75zm4 0a.75.75 0 11.75-.75.75.75 0 01-.75.75zm.75-3.25a.25.25 0 01-.25.25h-5a.25.25 0 01-.25-.25V9a.253.253 0 01.25-.25h5a.253.253 0 01.25.25zM6.25 8.25A.75.75 0 005.5 9v2A.75.75 0 007 11V9a.75.75 0 00-.75-.75zM17.75 8.25A.75.75 0 0017 9v2a.75.75 0 001.5 0V9a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});