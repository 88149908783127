define("ember-svg-jar/inlined/compass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>compass</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm3.293 7.293l2-2a1 1 0 111.414 1.414l-2 2a1 1 0 01-1.414-1.414zm-10-2a1 1 0 011.414 0l2 2a1 1 0 11-1.414 1.414l-2-2a1 1 0 010-1.414zm3.414 11.414l-2 2a1 1 0 01-1.414-1.414l2-2a1 1 0 011.414 1.414zm10 2a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414l2 2a1 1 0 010 1.414zm3.521-6.357L14.6 13.7a.723.723 0 00-.505.5l-1.75 8.026a.363.363 0 01-.7 0l-1.751-8.017a.724.724 0 00-.505-.5l-7.37-1.359a.363.363 0 010-.7l7.528-1.4a.527.527 0 00.419-.41l1.679-8.075a.363.363 0 01.7 0l1.678 8.073a.524.524 0 00.421.41l7.785 1.4a.363.363 0 010 .7z\"/><circle cx=\"12.001\" cy=\"12\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});