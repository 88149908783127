define("ember-svg-jar/inlined/dating-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dating-search</title><path d=\"M23.414 20.586l-3.928-3.928a.5.5 0 01-.07-.618 10.508 10.508 0 10-3.376 3.376.5.5 0 01.618.07l3.928 3.928a2 2 0 002.828-2.828zM10.5 19a8.5 8.5 0 118.5-8.5 8.51 8.51 0 01-8.5 8.5z\"/><path d=\"M13.969 5.73a3.241 3.241 0 00-2.84.916.581.581 0 01-.823 0 3.274 3.274 0 00-4.637 4.623l4.687 4.889a.5.5 0 00.722 0l4.681-4.882a3.273 3.273 0 00.613-3.776 3.237 3.237 0 00-2.403-1.77z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});