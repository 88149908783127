define("ember-svg-jar/inlined/smiley-sad-nerd", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-sad-nerd</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM5.75 5a4.751 4.751 0 014.532 3.325.25.25 0 00.239.175h2.958a.251.251 0 00.239-.175A4.75 4.75 0 1113.6 10.7a.249.249 0 00-.244-.2h-2.7a.25.25 0 00-.245.2A4.75 4.75 0 115.75 5zm10.8 15.338a1 1 0 01-1.384-.291 3.771 3.771 0 00-6.324 0 1 1 0 01-1.676-1.094 5.774 5.774 0 019.676 0 1 1 0 01-.296 1.385z\"/><circle cx=\"7\" cy=\"9.25\" r=\"1.5\"/><circle cx=\"19.5\" cy=\"9.25\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});