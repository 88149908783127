define("ember-svg-jar/inlined/insect-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>insect-3</title><path d=\"M23.606 6.661a11.655 11.655 0 00-4.847-5.193 3.253 3.253 0 00-4.579 1.943l-1.172 3.807a4.619 4.619 0 00-1.995 0L9.989 3.5A3.253 3.253 0 005.44 1.43 11.5 11.5 0 00.394 6.661a1.25 1.25 0 102.212 1.164 9.074 9.074 0 013.921-4.144.753.753 0 011.052.478l1.129 4.1a3.965 3.965 0 012.542 3.848 3.78 3.78 0 01-3.5 4c-.084 0-.167 0-.25-.011 0 .835.012 1.7.071 2.536a8.639 8.639 0 001.063 4.033.5.5 0 00.918-.235 10.245 10.245 0 011.079-4.351c.63.04 2.15.037 2.738 0a10.237 10.237 0 011.079 4.351.5.5 0 00.918.235 8.639 8.639 0 001.063-4.033c.059-.838.069-1.7.071-2.536a2.967 2.967 0 01-.25.011 3.78 3.78 0 01-3.5-4 3.964 3.964 0 012.55-3.852l1.265-4.109a.754.754 0 011.057-.45 9.135 9.135 0 013.768 4.129 1.25 1.25 0 102.212-1.164z\"/><ellipse cx=\"7.75\" cy=\"12.107\" rx=\"2\" ry=\"2.5\"/><ellipse cx=\"16.25\" cy=\"12.107\" rx=\"2\" ry=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});