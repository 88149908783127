define("ember-svg-jar/inlined/design-drawing-board-education", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-drawing-board-education</title><path d=\"M2.106 22.551a1 1 0 001.789.894l2.543-5.085a.255.255 0 00-.011-.244.252.252 0 00-.213-.116H4.537a.249.249 0 00-.224.138zM6.922 17A.079.079 0 007 16.921a2.5 2.5 0 01.572-1.514A.25.25 0 007.38 15H3.75a.25.25 0 01-.25-.25V5.5A.5.5 0 014 5h14a.5.5 0 01.5.5v6.883a.252.252 0 00.166.236l1.5.535a.25.25 0 00.334-.235V5.5A2.5 2.5 0 0018 3h-5.75a.25.25 0 01-.25-.25V1a1 1 0 10-2 0v1.75a.25.25 0 01-.25.25H4a2.5 2.5 0 00-2.5 2.5v9.25a.25.25 0 01-.25.25H1a1 1 0 000 2zM21 20.077a.249.249 0 00-.348-.229l-3.561 1.526a1.509 1.509 0 01-1.182 0l-3.56-1.526a.251.251 0 00-.349.229v1.468a1.251 1.251 0 00.8 1.166l3.236 1.194a1.237 1.237 0 00.917 0l.995-.367a.25.25 0 01.28.075.987.987 0 001.221.271.977.977 0 00.535-.916.251.251 0 01.164-.242l.068-.025A1.246 1.246 0 0021 21.545zM9 21a1 1 0 002 0v-1.56a.249.249 0 00-.151-.23l-1.5-.643A.251.251 0 009 18.8z\"/><path d=\"M23.668 16.527l-7-2.5a.5.5 0 00-.336 0l-7 2.5a.5.5 0 00-.028.93l7 3a.494.494 0 00.393 0l7-3a.5.5 0 00-.029-.93z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});