define("ember-svg-jar/inlined/appliances-stove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>appliances-stove</title><path d=\"M21.5 13.5h-15a1 1 0 00-1 1v7A2.5 2.5 0 008 24h12a2.5 2.5 0 002.5-2.5v-7a1 1 0 00-1-1zm-6 5.75h-3A2.252 2.252 0 0110.25 17a.75.75 0 011.5 0 .75.75 0 00.75.75h3a.75.75 0 00.75-.75.75.75 0 011.5 0 2.252 2.252 0 01-2.25 2.25zM21 5.5A1.5 1.5 0 0019.5 4h-10a.5.5 0 01-.5-.5V3a3 3 0 00-3-3H2.5a1 1 0 000 2H6a1 1 0 011 1v6a3 3 0 003 3h8a3 3 0 003-3zm-6.5.5a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5zM9 9V6.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v3a.5.5 0 01-.5.5H10a1 1 0 01-1-1zm10 0a1 1 0 01-1 1h-.5a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h1a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});