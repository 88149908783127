define("ember-svg-jar/inlined/cat-yarn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cat-yarn</title><path d=\"M20.005 14.827H18.26a9.477 9.477 0 001.373-4.948 9.716 9.716 0 10-2.949 6.948h3.321a1.747 1.747 0 011.745 1.745 2.284 2.284 0 01-3.052 2.15l-1.161-.415a5.166 5.166 0 00-3.583.043L10.7 21.6a3.159 3.159 0 01-2.663-.186 1 1 0 10-.971 1.748 5.147 5.147 0 004.352.3l3.251-1.251a3.169 3.169 0 012.193-.027l1.161.415a4.283 4.283 0 005.725-4.034 3.748 3.748 0 00-3.743-3.738zm-15.138.818a9.434 9.434 0 006.382-7.627A9.242 9.242 0 0010.21 2.2a7.62 7.62 0 012.829.643 10.32 10.32 0 01.935 4.29 10.413 10.413 0 01-2.674 6.991c-.007.007-.016.01-.022.018l-.006.011a10.4 10.4 0 01-4.28 2.827 7.726 7.726 0 01-2.125-1.335zM3.355 5.928a11 11 0 005.7 2.839 7.453 7.453 0 01-.933 2A9.31 9.31 0 012.518 7.9a7.623 7.623 0 01.837-1.972zm4.635-3.48a7.279 7.279 0 011.358 4.339A8.984 8.984 0 014.6 4.358a7.692 7.692 0 013.39-1.91zM2.277 10.4a11.35 11.35 0 004.268 2.1 7.378 7.378 0 01-3.112 1.46 7.629 7.629 0 01-1.156-3.56zm13.093 4.922a7.383 7.383 0 01-1.839-.765 12.28 12.28 0 00.844-1.314 7.093 7.093 0 002.024.8 7.707 7.707 0 01-1.029 1.279zm2.263-5.443a7.686 7.686 0 01-.347 2.288 5.07 5.07 0 01-2.066-.767c.071-.2.138-.393.2-.592a14.719 14.719 0 002.185-1.5c.013.192.028.379.028.571zm-5.441 6.2a9.4 9.4 0 001.2.664 7.626 7.626 0 01-3.063.809 12.335 12.335 0 001.863-1.474zm4.9-9.014A9.825 9.825 0 0115.935 8.1c.024-.318.039-.637.039-.96a12.5 12.5 0 00-.228-2.287 7.721 7.721 0 011.346 2.211z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});