define("ember-svg-jar/inlined/cash-payment-bill-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-payment-bill-1</title><path d=\"M8.636 16.969a.248.248 0 00-.224-.36H6.448a.246.246 0 00-.154.053 4.395 4.395 0 01-2.794.947.5.5 0 010-1 3.9 3.9 0 001.8-.533 2 2 0 00.844-1.322l.341-2.043a.251.251 0 01.1-.163l3-2.14A1 1 0 0010 9.594v-.566a1 1 0 00-1.336-.942L4.556 9.553a9.293 9.293 0 00-2.825 1.613L.09 12.534a.248.248 0 00-.09.192v9.415a.25.25 0 00.389.208l1.855-1.237a3 3 0 011.664-.5h4.5a.249.249 0 00.224-.361 3.675 3.675 0 010-3.279zM9.75 18.609a2.243 2.243 0 001.183 1.969.251.251 0 00.119.031h2.727a1 1 0 00.944-.67l.008-.026.7-3a.25.25 0 00-.244-.306h-4.135a.247.247 0 00-.119.03 2.243 2.243 0 00-1.183 1.972zM14.5 3.838a.75.75 0 00-.75.75v.1a.252.252 0 01-.182.241 1.842 1.842 0 00-.189 3.486l1.685.674a.347.347 0 01-.128.668h-1.662a.75.75 0 000 1.5h.226a.25.25 0 01.25.25v.021a.75.75 0 001.5 0v-.1a.251.251 0 01.182-.241 1.843 1.843 0 00.189-3.487l-1.685-.675a.342.342 0 01-.217-.32.346.346 0 01.345-.346h1.662a.75.75 0 100-1.5H15.5a.25.25 0 01-.25-.25v-.021a.75.75 0 00-.75-.75z\"/><path d=\"M24 3.109a1.5 1.5 0 00-1.5-1.5h-17a1.5 1.5 0 00-1.5 1.5v2.5a1 1 0 002 0v-1.75a.249.249 0 01.25-.25h15.5a.249.249 0 01.25.25v8.5a.25.25 0 01-.25.25H11a1 1 0 000 2h11.5a1.5 1.5 0 001.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});