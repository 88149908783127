define("ember-svg-jar/inlined/pathfinder-minus-back", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pathfinder-minus-back</title><path d=\"M3.5 3H2a2 2 0 00-2 2v1.5a1 1 0 002 0v-1a.5.5 0 01.5-.5h1a1 1 0 000-2zM1 14.5a1 1 0 001-1v-3a1 1 0 00-2 0v3a1 1 0 001 1zM3.5 19h-1a.5.5 0 01-.5-.5v-1a1 1 0 00-2 0V19a2 2 0 002 2h1.5a1 1 0 000-2zM23 16.5a1 1 0 00-1 1v1a.5.5 0 01-.5.5h-1a1 1 0 000 2H22a2 2 0 002-2v-1.5a1 1 0 00-1-1zM10 19H8a1 1 0 000 2h2a1 1 0 000-2zM16 19h-2a1 1 0 000 2h2a1 1 0 000-2zM8 5h1a1 1 0 000-2H8a1 1 0 000 2zM24 5a2 2 0 00-2-2h-9a2 2 0 00-2 2v5a2 2 0 002 2h8.75a.25.25 0 01.25.25v1.25a1 1 0 002 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});