define("ember-svg-jar/inlined/video-game-mario-flower-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-mario-flower-1</title><path d=\"M12 16.5A7.508 7.508 0 0019.5 9V3.149a1 1 0 00-1.8-.6l-1.287 1.717a.5.5 0 01-.8-.006L12.809.412a1 1 0 00-1.617 0l-2.8 3.848a.5.5 0 01-.8.006L6.3 2.55a1 1 0 00-1.8.6V9a7.508 7.508 0 007.5 7.5zm.879-7.25a2.25 2.25 0 014.243 0 .75.75 0 01-1.414.5.751.751 0 00-1.415 0 .75.75 0 11-1.414-.5zm-6 0a2.25 2.25 0 014.243 0 .75.75 0 11-1.414.5.751.751 0 00-1.415 0 .75.75 0 11-1.414-.5zM17.25 20a6.035 6.035 0 00-3.842 1.274.25.25 0 01-.408-.194V18.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v2.581a.251.251 0 01-.409.194A6.087 6.087 0 006.75 20c-2.633 0-4.814 1.477-5.192 3.4a.5.5 0 00.491.6h19.9a.5.5 0 00.491-.6c-.376-1.923-2.556-3.4-5.19-3.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});