define("ember-svg-jar/inlined/park-fonutain-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>park-fonutain-1</title><path d=\"M8 4.5a1 1 0 001-1V3a1 1 0 012 0v3.5a1 1 0 002 0V3a1 1 0 012 0v.5a1 1 0 002 0V3A3 3 0 0012.136.651a.219.219 0 01-.272 0A3 3 0 007 3v.5a1 1 0 001 1zM5.277 9.532a4.807 4.807 0 001.436 1.336A8.859 8.859 0 0012 12.5a8.345 8.345 0 006.916-3.223.5.5 0 00-.416-.777h-13a.5.5 0 00-.416.777c.016.023.081.117.193.255zM17.4 22.706c-.6-.259-1.321-.706-1.4-1.365v-.139a.5.5 0 00-.583-.494A21.021 21.021 0 0112 21a21.093 21.093 0 01-3.415-.291A.5.5 0 008 21.2a.774.774 0 010 .1c-.074.694-.8 1.148-1.394 1.406A.675.675 0 006.872 24h10.256a.675.675 0 00.268-1.294zM22 13.5H2a.5.5 0 00-.5.5c0 4.746 6.868 6 10.5 6s10.5-1.254 10.5-6a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});