define("ember-svg-jar/inlined/meeting-headphones-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-headphones-1</title><path d=\"M21.639 10.094a.249.249 0 01-.141-.219 9.5 9.5 0 00-19 0 .251.251 0 01-.14.221A2.716 2.716 0 00.75 12.5v3a2.934 2.934 0 003.083 2.75h2.334a.75.75 0 00.75-.75v-7a.75.75 0 00-.75-.75H4.776a.247.247 0 01-.183-.079.256.256 0 01-.067-.188 7.491 7.491 0 0114.948 0 .256.256 0 01-.067.188.251.251 0 01-.183.079h-1.391a.75.75 0 00-.75.75v7a.75.75 0 00.75.75h.917a.25.25 0 01.25.25 2 2 0 01-2 2h-1.64a.252.252 0 01-.208-.11 2 2 0 100 2.221.253.253 0 01.208-.111H17a4 4 0 004-4v-.175a.25.25 0 01.176-.239A2.787 2.787 0 0023.25 15.5v-3a2.719 2.719 0 00-1.611-2.406z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});