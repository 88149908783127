define("ember-svg-jar/inlined/navigation-menu-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-menu-4</title><path d=\"M22 0H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zm-4.5 17h-11a1 1 0 010-2h11a1 1 0 010 2zm0-4h-11a1 1 0 010-2h11a1 1 0 010 2zm0-4h-11a1 1 0 010-2h11a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});