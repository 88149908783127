define("ember-svg-jar/inlined/lucide-microscope", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6 18h8M3 22h18M14 22a7 7 0 100-14h-1M9 14h2M8 6h4\"/><path d=\"M13 10V6.5a.5.5 0 00-.5-.5.5.5 0 01-.5-.5V3a1 1 0 00-1-1H9a1 1 0 00-1 1v2.5a.5.5 0 01-.5.5.5.5 0 00-.5.5V10c0 1.1.9 2 2 2h2a2 2 0 002-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});