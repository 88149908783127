define("ember-svg-jar/inlined/pathfinder-intersect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pathfinder-intersect</title><path d=\"M22 8h-6v7a1 1 0 01-1 1H8v6a2 2 0 002 2h12a2 2 0 002-2V10a2 2 0 00-2-2z\"/><path d=\"M8 9a1 1 0 011-1h7V2a2 2 0 00-2-2H2a2 2 0 00-2 2v12a2 2 0 002 2h6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});