define("ember-svg-jar/inlined/shrink-vertical-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shrink-vertical-1</title><path d=\"M12 23.67a1.25 1.25 0 001.25-1.25v-3.513a.25.25 0 01.25-.25h1.816a.5.5 0 00.353-.853l-3.315-3.317a.517.517 0 00-.708 0L8.331 17.8a.5.5 0 00.353.853H10.5a.25.25 0 01.25.25v3.517A1.25 1.25 0 0012 23.67zM12 .328a1.25 1.25 0 00-1.25 1.25v3.513a.25.25 0 01-.25.25H8.684a.5.5 0 00-.353.853l3.315 3.317a.5.5 0 00.708 0l3.315-3.317a.5.5 0 00-.353-.853H13.5a.25.25 0 01-.25-.25V1.578A1.25 1.25 0 0012 .328zM1.75 12A1.25 1.25 0 003 13.249h18a1.25 1.25 0 000-2.5H3A1.25 1.25 0 001.75 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});