define("ember-svg-jar/inlined/wild-bird-flamingo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wild-bird-flamingo</title><path d=\"M15.27 12.005a.75.75 0 01-1.5 0 2.188 2.188 0 012.25-2.25h3.466a4.858 4.858 0 00-4.006-2.25 5.962 5.962 0 00-2.5.6v-5.1a3 3 0 00-5.4-1.8A3.055 3.055 0 006.479 1a3.7 3.7 0 00-3.454 3.43.494.494 0 00.118.4.5.5 0 00.377.17h4.222A2.993 2.993 0 009.98 6v5.5a5.2 5.2 0 003.5 4.6v2.4h-1.5a1 1 0 00-.948.684l-.5 1.5a1 1 0 101.9.633l.169-.507a.453.453 0 01.883.143V22a1 1 0 000 2h2a1 1 0 100-2v-1a.5.5 0 01.5-.5h2.25a1 1 0 00.841-1.541l-1.786-2.777a5.238 5.238 0 003.7-4.677.5.5 0 00-.067-.25H16.02a.688.688 0 00-.75.75zM9.979 4a1 1 0 111-1 1 1 0 01-1 1zm5.5 14.5v-1.427L16.4 18.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});