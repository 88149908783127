define("ember-svg-jar/inlined/conversation-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>conversation-chat</title><path d=\"M5.118 15.286A1 1 0 013.5 14.5v-2.75a.25.25 0 00-.25-.25H1.5A1.5 1.5 0 000 13v5a1.5 1.5 0 001.5 1.5h12.215a.249.249 0 01.166.063l4.287 3.811A.5.5 0 0019 23v-3.25a.25.25 0 01.25-.25h3.25A1.5 1.5 0 0024 18v-5a1.5 1.5 0 00-1.5-1.5H10.023a.255.255 0 00-.155.053z\"/><path d=\"M4.75 9.5a.25.25 0 01.25.25v2.75a.5.5 0 00.807.395l4.3-3.342a.251.251 0 01.153-.053H22.5A1.5 1.5 0 0024 8V2A1.5 1.5 0 0022.5.5h-21A1.5 1.5 0 000 2v6a1.5 1.5 0 001.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});