define("ember-svg-jar/inlined/tags-favorite-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-favorite-star</title><path d=\"M9.387 17.754a2.718 2.718 0 012.278-4.254h1.351a.5.5 0 00.459-.3c.289-.671.949-2.2.907-2.1a2.835 2.835 0 012.525-1.6 2.545 2.545 0 01.673.1.5.5 0 00.49-.132l1.03-1.056A3.789 3.789 0 0020.085 6V2a2 2 0 00-2-2l-4.258.005a3.747 3.747 0 00-2.414 1L.585 11.833a2 2 0 000 2.829L5.421 19.5a1.972 1.972 0 001.406.582h.012a1.973 1.973 0 001.41-.6l1.08-1.1a.5.5 0 00.058-.628zM16.585 5.5a2 2 0 11-2-2 2 2 0 012 2z\"/><path d=\"M22.146 15h-2.5a.249.249 0 01-.23-.151l-1.344-3.119-.013-.027a1.293 1.293 0 00-2.317.027L14.4 14.847a.251.251 0 01-.23.15h-2.5a1.228 1.228 0 00-1.166.76 1.269 1.269 0 00.319 1.4l2.194 1.955a.249.249 0 01.063.286l-1.223 2.814a1.255 1.255 0 00.315 1.452 1.28 1.28 0 001.505.166l3.112-1.753a.251.251 0 01.246 0l3.112 1.753a1.3 1.3 0 00.642.172 1.29 1.29 0 00.864-.338 1.257 1.257 0 00.316-1.451L20.74 19.4a.249.249 0 01.063-.286l2.212-1.972a1.264 1.264 0 00.3-1.378A1.233 1.233 0 0022.146 15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});