define("ember-svg-jar/inlined/family-mother", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-mother</title><path d=\"M19.979 5.329a2.264 2.264 0 00-1.239-3.837 2.235 2.235 0 00-1.965.635.193.193 0 01-.266 0 2.265 2.265 0 00-3.21 3.2l2.54 2.651a1.115 1.115 0 001.607 0zM8.888 9.032a.5.5 0 00-.7.111L6.7 11.215a.249.249 0 01-.406 0L4.817 9.143a.5.5 0 00-.7-.111 4 4 0 00-1.527 2.346l-1.578 7.013A.5.5 0 001.5 19h1.6a.5.5 0 01.5.45l.4 4.1a.5.5 0 00.5.45h4a.5.5 0 00.5-.45l.41-4.1A.5.5 0 019.9 19h1.6a.5.5 0 00.488-.609l-1.578-7.013a4 4 0 00-1.522-2.346z\"/><path d=\"M3.021 8.638a5.854 5.854 0 00.6-.879.25.25 0 01.367-.081 4.249 4.249 0 005.024 0 .252.252 0 01.367.081 5.854 5.854 0 00.6.879 1 1 0 001.542-1.276 3.141 3.141 0 01-.771-1.98V4.25a4.25 4.25 0 00-8.5 0v1.132a3.141 3.141 0 01-.771 1.98 1 1 0 001.542 1.276zM4.473 4.26a3.409 3.409 0 00.909-.28 2.471 2.471 0 00.918-.74.251.251 0 01.4 0 2.471 2.471 0 00.916.74 3.409 3.409 0 00.909.28.25.25 0 01.2.278 2.249 2.249 0 01-4.462 0 .251.251 0 01.21-.278zM18.07 11.473a2.027 2.027 0 102.03-2.027 2.027 2.027 0 00-2.03 2.027zM20.1 14a2.9 2.9 0 00-2.9 2.9v1.759a.5.5 0 00.5.5h.5a.5.5 0 01.5.45l.394 3.938a.5.5 0 00.5.45h1.031a.5.5 0 00.5-.45l.394-3.938a.5.5 0 01.5-.45h.5a.5.5 0 00.5-.5V16.9A2.9 2.9 0 0020.1 14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});