define("ember-svg-jar/inlined/common-file-rotate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-rotate</title><path d=\"M9.25 14.333H2.5a.5.5 0 01-.5-.5V2.5a.5.5 0 01.5-.5h6.034a.5.5 0 01.364.157l2.466 2.61a.5.5 0 01.136.343v1.311a.249.249 0 00.251.25h1.5a.251.251 0 00.249-.25V4.845a1.8 1.8 0 00-.518-1.276L10.118.537A1.819 1.819 0 008.821 0H2a2 2 0 00-2 2v12a2.293 2.293 0 002 2.333h7.25a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25z\"/><path d=\"M23.482 11.236L20.618 8.2a1.819 1.819 0 00-1.3-.537H12.5a2 2 0 00-2 2v12a2.294 2.294 0 002 2.337h9.667A1.835 1.835 0 0024 22.167v-9.656a1.8 1.8 0 00-.518-1.275zM22 21.5a.5.5 0 01-.5.5H13a.5.5 0 01-.5-.5V10.167a.5.5 0 01.5-.5h6.034a.5.5 0 01.364.156l2.466 2.611a.5.5 0 01.136.343zM16.646 4.854A.5.5 0 0017.5 4.5v-.879a.124.124 0 01.123-.12H20a.5.5 0 01.5.5V5.5a1 1 0 002 0V4A2.5 2.5 0 0020 1.5h-2.379a.125.125 0 01-.121-.122V.5a.5.5 0 00-.854-.354l-2 2a.5.5 0 000 .708zM5.854 19.146A.5.5 0 005 19.5v.875a.126.126 0 01-.125.125H2.5A.5.5 0 012 20v-1.5a1 1 0 00-2 0V20a2.5 2.5 0 002.5 2.5H5v1a.5.5 0 00.309.462.5.5 0 00.545-.108l2-2a.5.5 0 000-.708z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});