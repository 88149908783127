define("ember-svg-jar/inlined/ticket-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ticket-check</title><circle cx=\"15.304\" cy=\"3.793\" r=\".987\"/><circle cx=\"15.304\" cy=\"6.508\" r=\".987\"/><path d=\"M15.3 8.236a.987.987 0 00-.988.987.832.832 0 00.05.28.247.247 0 00.13.143.253.253 0 00.193 0 7.869 7.869 0 011.37-.395.245.245 0 00.19-.311.985.985 0 00-.945-.704z\"/><path d=\"M9.49 15.888H2.221a.247.247 0 01-.246-.247v-1.893a.246.246 0 01.181-.238 4.444 4.444 0 000-8.574.245.245 0 01-.181-.236V2.806a.246.246 0 01.246-.247h19.253a.246.246 0 01.247.247V4.7a.245.245 0 01-.182.238A4.449 4.449 0 0018.285 8.9a.245.245 0 00.217.263 7.84 7.84 0 011.418.3.246.246 0 00.32-.236 2.472 2.472 0 012.16-2.453 1.482 1.482 0 001.3-1.469V2.559A1.977 1.977 0 0021.721.584H1.975A1.977 1.977 0 000 2.559v2.746a1.483 1.483 0 001.3 1.469 2.468 2.468 0 010 4.9A1.484 1.484 0 000 13.142v2.746a1.977 1.977 0 001.975 1.974h7.487a.247.247 0 00.181-.079.244.244 0 00.065-.187q-.024-.3-.024-.6a7.185 7.185 0 01.052-.835.246.246 0 00-.246-.275z\"/><path d=\"M17.582 10.581A6.418 6.418 0 1024 17a6.425 6.425 0 00-6.418-6.419zm3.233 5.139l-2.869 3.825a1.474 1.474 0 01-1.082.588c-.037 0-.074.005-.11.005a1.468 1.468 0 01-1.042-.438l-1.48-1.48a.74.74 0 011.047-1.047l1.282 1.281a.244.244 0 00.192.072.248.248 0 00.18-.1l2.7-3.6a.741.741 0 011.185.889z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});