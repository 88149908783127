define("ember-svg-jar/inlined/sauna-heat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sauna-heat</title><path d=\"M23.722 11.8L12.723.3a1.036 1.036 0 00-1.446 0l-11 11.5A1 1 0 001 13.492h1.5a.5.5 0 01.5.5v8.5a1.5 1.5 0 001.5 1.5h15a1.5 1.5 0 001.5-1.5v-8.5a.5.5 0 01.5-.5H23a1 1 0 00.722-1.691zM9.916 20.616a.75.75 0 01-.834-1.247 2.71 2.71 0 001.168-1.877 2.717 2.717 0 00-1.169-1.878 4.187 4.187 0 01-1.831-3.122 4.19 4.19 0 011.834-3.124.75.75 0 01.832 1.248 2.715 2.715 0 00-1.166 1.876 2.714 2.714 0 001.169 1.878 4.189 4.189 0 011.831 3.122 4.188 4.188 0 01-1.834 3.124zm6 0a.75.75 0 01-.834-1.247 2.71 2.71 0 001.168-1.877 2.717 2.717 0 00-1.169-1.878 4.187 4.187 0 01-1.831-3.122 4.19 4.19 0 011.834-3.124.75.75 0 11.832 1.248 2.715 2.715 0 00-1.166 1.876 2.714 2.714 0 001.169 1.878 4.189 4.189 0 011.831 3.122 4.188 4.188 0 01-1.834 3.124z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});