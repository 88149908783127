define("ember-svg-jar/inlined/style-two-pin-gas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-two-pin-gas</title><path d=\"M12 6h-2a.5.5 0 000 1h2a.5.5 0 000-1z\"/><path d=\"M12 0a9.511 9.511 0 00-9.5 9.5c0 6.544 6.537 12.487 8.541 14.153a1.5 1.5 0 001.917 0c2-1.666 8.542-7.609 8.542-14.154A9.511 9.511 0 0012 0zM8 6.5A1.5 1.5 0 019.5 5h3A1.5 1.5 0 0114 6.5v1a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5zm9.361 1.327a.253.253 0 00-.111.208V11a2.752 2.752 0 01-2.75 2.75h-.15a.5.5 0 00-.464.313A1.5 1.5 0 0112.5 15h-3A1.5 1.5 0 018 13.5v-4a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v2.25a.5.5 0 00.5.5A1.252 1.252 0 0015.75 11V8.035a1.746 1.746 0 01.779-1.456l1.055-.7a.75.75 0 01.832 1.248z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});