define("ember-svg-jar/inlined/laptop-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-shield</title><path d=\"M22.41 4.5a2 2 0 00-2-2h-17a2 2 0 00-2 2v12.682a.319.319 0 01-.32.319 1 1 0 00-.99 1.164l.124.747A2.492 2.492 0 002.7 21.5h18.606a2.492 2.492 0 002.466-2.089l.124-.746a1 1 0 00-.986-1.165.5.5 0 01-.5-.5zm-2 12.5a.5.5 0 01-.5.5h-16a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5h16a.5.5 0 01.5.5z\"/><path d=\"M10.977 14.759l.369.141a1.545 1.545 0 001.1 0l.367-.14a5.334 5.334 0 003.6-4.693V7.805a1.193 1.193 0 00-.719-1.1A10.9 10.9 0 0011.9 6a10.814 10.814 0 00-3.764.707 1.194 1.194 0 00-.721 1.1v2.261a5.313 5.313 0 003.562 4.691zm-.567-5.008h.5a.25.25 0 00.25-.25V9a.75.75 0 011.5 0v.5a.25.25 0 00.25.25h.5a.75.75 0 010 1.5h-.5a.25.25 0 00-.25.25v.5a.75.75 0 11-1.5 0v-.5a.25.25 0 00-.25-.25h-.5a.75.75 0 010-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});