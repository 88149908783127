define("ember-svg-jar/inlined/file-code-edit-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-edit-1</title><path d=\"M14.679 12.388l.018-.017a.751.751 0 00.018-1.043l-2.029-2.174a.249.249 0 010-.341l2.028-2.174A.75.75 0 0013.636 5.6l-.018.019-2.666 2.853a.749.749 0 000 1.023l2.666 2.857a.751.751 0 001.061.036zM16.286 11.328a.75.75 0 001.078 1.043l.017-.019L20.048 9.5a.752.752 0 000-1.025l-2.666-2.86a.751.751 0 00-1.061-.036.731.731 0 00-.036 1.06l2.029 2.174a.249.249 0 010 .341z\"/><path d=\"M23.414 3.189L21 .775a2 2 0 00-1.415-.586H8a2 2 0 00-2 2v8.694a.249.249 0 00.073.177l1.5 1.5A.25.25 0 008 12.383V2.689a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.121A.5.5 0 0122 4.81v13.379a.5.5 0 01-.5.5h-7.632a.247.247 0 00-.243.307c.106.422.27 1.155.346 1.5a.243.243 0 00.243.191H22a2 2 0 002-2V4.6a2 2 0 00-.586-1.411zM11.15 18.258l-6.58-6.58A2.71 2.71 0 00.964 11.4a2.623 2.623 0 00-.2 3.882l6.679 6.679a.25.25 0 00.353 0l3.354-3.354a.25.25 0 000-.349zM11.938 19.944l-2.809 2.809a.25.25 0 00.128.422l3.143.625a.473.473 0 00.1.01.5.5 0 00.49-.6l-.628-3.143a.25.25 0 00-.424-.123z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});