define("ember-svg-jar/inlined/bread-free", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bread-free</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM2 12a10 10 0 0115.867-8.1.5.5 0 01.059.759L4.66 17.926a.5.5 0 01-.759-.059A9.954 9.954 0 012 12zm20 0a10 10 0 01-15.866 8.1.5.5 0 01-.06-.759L19.34 6.074a.5.5 0 01.759.059A9.954 9.954 0 0122 12z\"/><path d=\"M8.75 12a1 1 0 01-1.97.245.5.5 0 00-.224-.3A3.739 3.739 0 018.5 5h4.75a1 1 0 010 2H8.5a1.742 1.742 0 00-.474 3.421 1.006 1.006 0 01.724.961zM10 19.25a1 1 0 010-2h4.5a.5.5 0 00.5-.5v-4.118a1.006 1.006 0 01.724-.961A1.755 1.755 0 0017 10a1 1 0 012 0 3.762 3.762 0 01-1.765 3.165.5.5 0 00-.235.424v4.161a1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});