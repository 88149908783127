define("ember-svg-jar/inlined/expand-diagonal-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>expand-diagonal-3</title><path d=\"M15.884 7.615a1.251 1.251 0 00-1.768 0l-6.5 6.5a1.25 1.25 0 001.768 1.768l6.5-6.5a1.251 1.251 0 000-1.768zM24 19a1 1 0 00-1.707-.707l-.939.939a.25.25 0 01-.354 0l-4.616-4.616a1.25 1.25 0 10-1.768 1.768L19.232 21a.249.249 0 010 .353l-.939.94A1 1 0 0019 24h4a1 1 0 001-1zM0 5a1 1 0 00.617.924A.987.987 0 001 6a1 1 0 00.707-.293l.939-.939a.25.25 0 01.354 0l4.116 4.115a1.25 1.25 0 101.768-1.768L4.768 3a.25.25 0 010-.354l.939-.939A1 1 0 005 0H1a1 1 0 00-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});