define("ember-svg-jar/inlined/diagram-arrow-dash-corner-right-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-arrow-dash-corner-right-down</title><path d=\"M22.955 11.655a1.078 1.078 0 00-1.155.245l-2.476 2.476a.269.269 0 01-.38 0L15.171 10.6a1.077 1.077 0 00-1.524 0L10.6 13.647a1.077 1.077 0 000 1.524l3.772 3.772a.269.269 0 010 .381L11.9 21.8a1.077 1.077 0 00.722 1.838l9.744.362h.056A1.571 1.571 0 0024 22.364l-.361-9.746a1.078 1.078 0 00-.684-.963zM12.5 7.934l-.762-.762a1.078 1.078 0 00-1.523 0l-3.043 3.047a1.077 1.077 0 000 1.524l.762.761a1.076 1.076 0 001.523 0L12.5 9.458a1.077 1.077 0 000-1.524zM8.314 3.744a1.108 1.108 0 00-1.523 0L3.744 6.792a1.076 1.076 0 000 1.523l.762.762a1.078 1.078 0 001.523 0l3.047-3.048a1.077 1.077 0 000-1.524zM4.886.316a1.076 1.076 0 00-1.523 0L.316 3.363a1.076 1.076 0 000 1.523l.761.762a1.077 1.077 0 001.524 0L5.648 2.6a1.076 1.076 0 000-1.523z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});