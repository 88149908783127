define("ember-svg-jar/inlined/file-ym-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-ym-1</title><path d=\"M20 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h18a1 1 0 001-1zM9.625 12.5A2.621 2.621 0 017.8 14.992a.247.247 0 00-.176.233V17.5a.625.625 0 01-1.25 0v-2.275a.247.247 0 00-.177-.233A2.622 2.622 0 014.375 12.5v-1a.625.625 0 111.25 0v1a1.375 1.375 0 002.75 0v-1a.625.625 0 111.25 0zm5 5a.625.625 0 01-1.25 0v-2.954c0-.126-.162-.074-.2 0l-.115.23a.65.65 0 01-1.118 0s-.056-.121-.11-.221-.206-.114-.206.011V17.5a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279s.71 1.408.781 1.562a.177.177 0 00.323-.007l.778-1.555a.625.625 0 011.184.279z\"/><path d=\"M23.707 5.708L18.293.294A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.751V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.415a1 1 0 00-.293-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});