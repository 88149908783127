define("ember-svg-jar/inlined/smiley-sleepy-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-sleepy-alternate</title><path d=\"M23.7 9.34a1 1 0 00-1.951.442A10 10 0 117.9 2.878a1 1 0 00-.823-1.823A12 12 0 1024 12a12.1 12.1 0 00-.3-2.66z\"/><path d=\"M9.5 15.5v2a2.5 2.5 0 005 0v-2a2.5 2.5 0 00-5 0zM17.5 2h.982a.25.25 0 01.177.427l-1.866 1.866A1 1 0 0017.5 6h4a1 1 0 000-2h-.982a.25.25 0 01-.177-.427l1.866-1.866A1 1 0 0021.5 0h-4a1 1 0 000 2zM10 2a.243.243 0 01.172.414l-.879.879A1 1 0 0010 5h3a1 1 0 000-2 .243.243 0 01-.172-.414l.879-.879A1 1 0 0013 0h-3a1 1 0 000 2zM7.584 8.1a1.5 1.5 0 01-2.751 0A1 1 0 003 8.9a3.5 3.5 0 006.415 0 1 1 0 00-1.832-.8zM13.333 8.1a1 1 0 10-1.832.8 3.5 3.5 0 006.415 0 1 1 0 10-1.832-.8 1.5 1.5 0 01-2.751 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});