define("ember-svg-jar/inlined/transportation-ticket-plane-transfer-train", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transportation-ticket-plane-transfer-train</title><path d=\"M23.763 15.38a1.875 1.875 0 00-1.349-1.211 1.789 1.789 0 00-1.41.27L19 15.8a.252.252 0 01-.229.027L14.12 14.1a.826.826 0 00-.781.113l-.891.661a1.426 1.426 0 00-.133.125.843.843 0 00.154 1.216l2.669 1.653a.25.25 0 01.011.417l-.72.5-1.236-.738a.594.594 0 00-.092-.043.776.776 0 00-.751.143l-1.182 1.043a1.131 1.131 0 00-.131.128.755.755 0 00-.164.563.776.776 0 00.307.534l2.878 2.021a.983.983 0 00.565.179.973.973 0 00.519-.149L23 17.59a1.864 1.864 0 00.871-1.6 1.812 1.812 0 00-.108-.61zM12.176 7.738a4.956 4.956 0 012.6 1.307.25.25 0 01.077.19c0 .071-.529.219-.584.265a.74.74 0 00-.264.485.75.75 0 00.38.742l2.348.949a.75.75 0 00.365.1.805.805 0 00.206-.028.755.755 0 00.45-.358l1.031-1.854a.75.75 0 00-.861-1.086l-.673.192a.25.25 0 01-.271-.094 6.949 6.949 0 00-4.468-2.776 1 1 0 10-.336 1.971zM9.755 17.415a4.976 4.976 0 01-2.38-1.715.251.251 0 01.11-.386l.192-.072a.751.751 0 00.045-1.386l-1.933-.874a.749.749 0 00-.993.375l-.874 1.934a.749.749 0 00.684 1.058.761.761 0 00.265-.048l.218-.082a.22.22 0 01.264.09 7.018 7.018 0 003.772 3 1.022 1.022 0 00.315.051 1 1 0 00.315-1.949zM9.659 10.606a.249.249 0 01.013-.316 1.729 1.729 0 00.445-1.156v-6a1.752 1.752 0 00-1.75-1.75h-6a1.752 1.752 0 00-1.75 1.75v6a1.729 1.729 0 00.445 1.156.249.249 0 01.013.316l-.793 1.057a.75.75 0 001.2.9l1.184-1.579a.249.249 0 01.2-.1h5a.252.252 0 01.2.1l1.186 1.582a.75.75 0 00.6.3.751.751 0 00.6-1.2zM3.367 9.134a.75.75 0 11.75-.75.75.75 0 01-.75.75zm4 0a.75.75 0 11.75-.75.75.75 0 01-.75.75zm1.25-3a.25.25 0 01-.25.25h-6a.25.25 0 01-.25-.25v-3a.253.253 0 01.25-.25h6a.253.253 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});