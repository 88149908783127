define("ember-svg-jar/inlined/diagram-arrow-up-and-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-arrow-up-and-right</title><path d=\"M17.01 8.811a1 1 0 00-1.725.689v2.25a.25.25 0 01-.25.25H11.25a.25.25 0 01-.25-.25V7.465a.25.25 0 01.25-.25h2.25a1 1 0 00.688-1.726L8.688.274a1 1 0 00-1.376 0l-5.5 5.215A1 1 0 002.5 7.215h2.25a.25.25 0 01.25.25V23a1 1 0 001 1h4a1 1 0 001-1v-4.75a.25.25 0 01.25-.25h3.785a.25.25 0 01.25.25v2.25a1 1 0 001.725.688l5.216-5.5a1 1 0 000-1.377z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});