define("ember-svg-jar/inlined/smiley-bad-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-bad-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M12 14.745A5.7 5.7 0 007.059 17.6a.439.439 0 00.38.658h9.123a.439.439 0 00.379-.658A5.7 5.7 0 0012 14.745zM10.164 11.486a1 1 0 00.543-1.693l-3-3a1 1 0 10-1.414 1.414L7.575 9.49a.249.249 0 01-.135.423l-.6.1A1 1 0 007 12a.927.927 0 00.165-.014zM17.642 10.01l-1.026-.146a.25.25 0 01-.141-.425l1.232-1.232a1 1 0 10-1.414-1.414l-3 3a1 1 0 00.565 1.7l3.5.5a1 1 0 10.284-1.98z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});