define("ember-svg-jar/inlined/construction-paper-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>construction-paper-home</title><path d=\"M21.707 5.707L16.293.293A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.465a.5.5 0 01.353.146l4.536 4.536a.5.5 0 01.146.353z\"/><path d=\"M11.723 7.2l-5.571 4.842a.416.416 0 00-.126.466.429.429 0 00.4.276H7.9V17.2a.631.631 0 00.631.632H9.79a.632.632 0 00.631-.632v-1.574a1.579 1.579 0 013.158 0V17.2a.632.632 0 00.631.632h1.264a.633.633 0 00.631-.632v-4.416h1.466a.429.429 0 00.4-.276.416.416 0 00-.126-.466L12.277 7.2a.434.434 0 00-.554 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});