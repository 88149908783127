define("ember-svg-jar/inlined/lantern", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lantern</title><path d=\"M6.687 5.61A.5.5 0 007 6.5h10a.5.5 0 00.313-.89l-2.282-1.826a.248.248 0 01-.089-.244A2.8 2.8 0 0015 3a3 3 0 00-6 0 2.8 2.8 0 00.058.54.248.248 0 01-.089.244zM12 2a1 1 0 011 1 1.2 1.2 0 01-.071.328.248.248 0 01-.237.171h-1.384a.25.25 0 01-.237-.171A1.2 1.2 0 0111 3a1 1 0 011-1zM22 9a1 1 0 00-1-1H3a1 1 0 000 2h.213a.5.5 0 01.494.424l1.577 10.245a.293.293 0 01-.066.231A.289.289 0 015 21a1 1 0 00-1 1v1a1 1 0 001 1h14a1 1 0 001-1v-1a1 1 0 00-1-1 .289.289 0 01-.218-.1.293.293 0 01-.066-.231l1.577-10.245a.5.5 0 01.494-.424H21a1 1 0 001-1zm-5.293 11.576a.5.5 0 01-.494.424h-1.682a.25.25 0 01-.168-.435A3.459 3.459 0 0015.5 18a6.436 6.436 0 00-3.827-5.8.25.25 0 00-.3.374 3.673 3.673 0 01.35 3.388.251.251 0 01-.4.082 1.949 1.949 0 01-.575-.828.25.25 0 00-.347-.15A3.15 3.15 0 008.5 18a3.459 3.459 0 001.137 2.565.25.25 0 01-.168.435H7.787a.5.5 0 01-.494-.424l-1.539-10A.5.5 0 016.248 10h11.5a.5.5 0 01.494.576z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});