define("ember-svg-jar/inlined/single-neutral-actions-home.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-home</title><path d=\"M8.884 16.093l5.168-4.373a.251.251 0 00-.06-.42c-.71-.312-1.547-.624-2.494-.972l-.628-.23a.6.6 0 01-.138-.311 2.573 2.573 0 01.058-1.3 5.618 5.618 0 001.466-4.314C12.256 1.756 10.677 0 8.5 0S4.744 1.756 4.744 4.174A5.605 5.605 0 006.2 8.471a2.453 2.453 0 01.073 1.316.615.615 0 01-.135.309l-.631.232c-2.447.9-4.215 1.55-4.754 2.626A9.173 9.173 0 000 16.5a.5.5 0 00.5.5h7.552a.25.25 0 00.223-.137 2.5 2.5 0 01.609-.77zM21.5 24a.5.5 0 00.5-.5v-4.75a.25.25 0 01.25-.25h1.25a.5.5 0 00.323-.881l-6.5-5.5a.5.5 0 00-.647 0l-6.5 5.5a.5.5 0 00.324.881h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});