define("ember-svg-jar/inlined/day-noon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>day-noon</title><path d=\"M23 20.252h-.984a10.017 10.017 0 00-5.186-8.878 1 1 0 00-.969 1.75 8.031 8.031 0 014.159 7.128H3.98a8.031 8.031 0 014.159-7.128 1 1 0 00-.969-1.75 10.017 10.017 0 00-5.186 8.878H1a1 1 0 000 2h22a1 1 0 000-2z\"/><path d=\"M13 19.252v-2a1 1 0 00-2 0v2a1 1 0 002 0zM12 11.252a1 1 0 00-1 1v2a1 1 0 102 0v-2a1 1 0 00-1-1zM8.366 6.881h1.158a2.566 2.566 0 00.2.471L8.9 8.17a.75.75 0 101.061 1.061l.818-.818a2.566 2.566 0 00.471.2v1.152a.75.75 0 001.5 0V8.608a2.566 2.566 0 00.471-.2l.818.818A.75.75 0 0015.1 8.17l-.819-.818a2.566 2.566 0 00.2-.471h1.158a.75.75 0 000-1.5h-1.163a2.586 2.586 0 00-.2-.47l.819-.819a.75.75 0 00-1.061-1.06l-.818.818a2.566 2.566 0 00-.471-.2V2.5a.75.75 0 00-1.5 0v1.155a2.566 2.566 0 00-.471.2l-.818-.818A.75.75 0 008.9 4.092l.819.819a2.586 2.586 0 00-.2.47H8.366a.75.75 0 000 1.5zm4.734-.75a1.1 1.1 0 11-1.1-1.1 1.1 1.1 0 011.1 1.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});