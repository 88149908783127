define("ember-svg-jar/inlined/amphibian-chameleon-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>amphibian-chameleon-2</title><path d=\"M14.594 4.626a8.722 8.722 0 01-1.155 5.332 2.747 2.747 0 01-2.375 1.369c-1.017 0-1.4-.375-3.12-1.556a.75.75 0 01.85-1.237l1.557 1.072a1.805 1.805 0 00.984.192C12.4 9.559 12.926 7.305 13 6.952a6.7 6.7 0 00-.212-3.881 7.222 7.222 0 00-.939-1.8 1.1 1.1 0 00-.8-.45 1.066 1.066 0 00-.851.31L7.371 4.415H5.582C2.66 4.411 0 6.794 0 9.411c0 2.356 2.09 4 5.082 4h1.3a.25.25 0 01.215.379 5.222 5.222 0 01-.93 1.121H4.038a1 1 0 000 2h.146a.995.995 0 001.686 1.055l.916-1.374a6.664 6.664 0 002.069-3 .251.251 0 01.24-.18h6.849a2.376 2.376 0 012.167 1.415.251.251 0 01-.228.352h-3.038a4 4 0 000 8s1.618 0 1.657-.007c5.256-.628 7.5-3.179 7.5-10.493-.002-2.856-2.51-7.121-9.408-8.053zM5.039 8.911a1 1 0 11-1-1 1 1 0 011 1zm11.284 12.267h-1.478a2 2 0 010-4h3.032a2.432 2.432 0 01-.247.3 2.375 2.375 0 01-1.7.7 1 1 0 000 2 8.139 8.139 0 004.854-1.635 5.332 5.332 0 01-4.461 2.635z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});