define("ember-svg-jar/inlined/sea-transport-boat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sea-transport-boat</title><path d=\"M5.829 8.694a.248.248 0 00.206.053l6.08-1.159a1.966 1.966 0 01.749 0l6.077 1.159a.249.249 0 00.3-.246v-1.48a2.753 2.753 0 00-2.75-2.75h-1.75a.25.25 0 01-.25-.25v-3.5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v3.5a.25.25 0 01-.25.25H8.488a2.754 2.754 0 00-2.75 2.75V8.5a.247.247 0 00.091.194zM13.34 9.3a.252.252 0 00-.091.193v8.869a.251.251 0 00.16.233 2.485 2.485 0 011.347 1.245 1.288 1.288 0 001.057.631 1.87 1.87 0 001.482-.85 2.416 2.416 0 012.139-1.206 2.508 2.508 0 01.951.188.252.252 0 00.208-.009.249.249 0 00.13-.162l1.741-6.945a.5.5 0 00-.391-.613l-8.527-1.63a.251.251 0 00-.206.056zM4.218 18.275a.249.249 0 00.292.184 2.26 2.26 0 01.444-.047A2.5 2.5 0 017.08 19.59a2.122 2.122 0 001.494.878 2.815 2.815 0 001.9-1.021 2.464 2.464 0 011.119-.85.249.249 0 00.16-.233V9.486a.251.251 0 00-.09-.193.255.255 0 00-.206-.053l-8.551 1.631a.5.5 0 00-.391.613z\"/><path d=\"M23.991 22.779a1 1 0 00-1.124-.859c-1.619.218-2.738-1.5-2.746-1.513a1.043 1.043 0 00-1.719.029 3.267 3.267 0 01-2.674 1.509 2.726 2.726 0 01-2.337-1.44 1.043 1.043 0 00-1.717-.156 4.2 4.2 0 01-3.024 1.6 3.464 3.464 0 01-2.686-1.539 1.042 1.042 0 00-1.638-.09c-.015.019-1.532 1.86-3.2 1.645a1 1 0 00-.26 1.983 4.177 4.177 0 00.542.035 5.6 5.6 0 003.481-1.423.252.252 0 01.328.006 5.09 5.09 0 003.433 1.379 5.69 5.69 0 003.557-1.321.25.25 0 01.331.016 4.47 4.47 0 003.19 1.3 5.029 5.029 0 003.38-1.3.25.25 0 01.333 0 4.556 4.556 0 003.692 1.26 1 1 0 00.858-1.121z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});