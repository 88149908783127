define("ember-svg-jar/inlined/book-sound", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-sound</title><path d=\"M20.15 4.04a.5.5 0 01-.4-.489V2a2 2 0 00-2-2H5.25a3 3 0 00-3 3v18a3 3 0 003 3h14.5a2 2 0 002-2V6a2 2 0 00-1.6-1.96zM13 17.75a.75.75 0 01-.75.75 4.2 4.2 0 01-3.368-1.672 1 1 0 00-.593-.375 2.251 2.251 0 010-4.406 1 1 0 00.593-.375A4.2 4.2 0 0112.25 10a.75.75 0 01.75.75zm3.417-1.081a.751.751 0 01-1.334-.689 3.756 3.756 0 000-3.44.75.75 0 111.334-.687 5.264 5.264 0 010 4.816zM17.75 3.75a.25.25 0 01-.25.25H5.25a1 1 0 010-2H17.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});