define("ember-svg-jar/inlined/multiple-actions-music.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-music</title><path d=\"M15.353 10.39l2.338-.439a.5.5 0 00.19-.9 5.995 5.995 0 00-5.858-.513.5.5 0 00-.182.771 7.479 7.479 0 011.053 1.737.5.5 0 00.793.174 3.434 3.434 0 011.666-.83z\"/><ellipse cx=\"14.014\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M12 13.6a6.025 6.025 0 00-12-.267.6.6 0 00.6.667h11.024a.376.376 0 00.274-.119A.368.368 0 0012 13.6z\"/><ellipse cx=\"5.514\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M24 19.5v-6.795a2 2 0 00-2.368-1.966l-6 1.125A2 2 0 0014 13.83v4.931a.252.252 0 01-.082.185.248.248 0 01-.191.064c-.075-.01-.151-.01-.227-.01a2.5 2.5 0 102.5 2.5v-7.256a.5.5 0 01.408-.491l5-.937a.5.5 0 01.592.491v3.454a.252.252 0 01-.082.185.248.248 0 01-.191.064c-.075-.01-.151-.01-.227-.01a2.5 2.5 0 102.5 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});