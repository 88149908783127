define("ember-svg-jar/inlined/video-game-kirby-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-kirby-1</title><path d=\"M23.53 5.668a.99.99 0 00-1.044-.01l-2.817 1.69a.455.455 0 01-.688-.357A7 7 0 005 7.509v1.8a1 1 0 01-.611.921 10.7 10.7 0 00-4.171 3.155A1 1 0 001 15.009h3.932a1 1 0 01.928.627 9.039 9.039 0 00.5 1.043 1 1 0 00.619.478A3.951 3.951 0 019.9 19.762a1 1 0 00.967.747h2.257a1 1 0 00.967-.747 3.964 3.964 0 012.972-2.616 1 1 0 00.588-.415 7.463 7.463 0 00.716-1.3.987.987 0 01.431-.48A10.571 10.571 0 0024 6.6a1 1 0 00-.47-.932zm-14.78.341a.75.75 0 011.5 0v1.5a.75.75 0 01-1.5 0zM12 14.62c-.511 0-3.067-1.533-3.067-2.044a3.067 3.067 0 116.133 0c0 .511-2.555 2.044-3.066 2.044zm3.25-7.111a.75.75 0 01-1.5 0v-1.5a.75.75 0 011.5 0z\"/><path d=\"M5.879 18.509a2.185 2.185 0 00-1.307 3.935l.89.667A1.9 1.9 0 008.5 21.592v-1.336a2.779 2.779 0 00-2.621-1.747zM18.121 18.509a2.779 2.779 0 00-2.621 1.747v1.336a1.9 1.9 0 003.038 1.519l.89-.667a2.185 2.185 0 00-1.307-3.935z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});