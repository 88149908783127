define("ember-svg-jar/inlined/messages-bubble-share-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-share-1</title><path d=\"M13.75 14a4.182 4.182 0 011.25.193.253.253 0 00.2-.024l1.753-1.051a.252.252 0 00.117-.167A4.257 4.257 0 0121.25 9.5a4.114 4.114 0 011.71.38.252.252 0 00.348-.174A7.859 7.859 0 0023.5 8a8.009 8.009 0 00-8-8H8a7.992 7.992 0 00-3 15.4v4.1a.5.5 0 00.854.354L9.707 16h.316a.25.25 0 00.208-.111A4.237 4.237 0 0113.75 14z\"/><path d=\"M21.25 18.5a2.736 2.736 0 00-2 .873l-2.753-1.1v-.021a2.765 2.765 0 00-.046-.457l3.125-1.874a2.724 2.724 0 001.671.581 2.8 2.8 0 10-2.7-2.294l-3.125 1.875a2.724 2.724 0 00-1.672-.583 2.75 2.75 0 102 4.627l2.753 1.1v.021a2.75 2.75 0 102.75-2.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});