define("ember-svg-jar/inlined/aircraft-plane-water-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>aircraft-plane-water-1</title><path d=\"M23 6.75h-1.278a1.994 1.994 0 00-1.721-1h-1.12A4.5 4.5 0 0014.5 2.246a4.29 4.29 0 00-1.554.292c-.016.005-.033.006-.05.012l-.014.007h-.012c-.009 0-.016.011-.025.015a4.506 4.506 0 00-1.5.959L8.09 6.755H7.5L6.3 5.137a3.5 3.5 0 00-2.8-1.391H1a1 1 0 00-1 1 3.427 3.427 0 00.692 2.087l3.837 5.282a1.485 1.485 0 001.222.64h.86L6.2 14H3.55a1.25 1.25 0 00-1.225 1.5C2.647 17.091 3.987 18 6 18h13c2.013 0 3.353-.913 3.676-2.506A1.251 1.251 0 0021.45 14H18.8l-.453-1.36a1.8 1.8 0 00.518-.339l2.689-3.291a1.927 1.927 0 00.178-.26H23a1 1 0 000-2zm-15 3A.75.75 0 018.75 9h6.5a.75.75 0 010 1.5h-6.5A.75.75 0 018 9.754zM8.3 14l.415-1.245h7.562L16.7 14zm5.947-8.245v-1.5a2.531 2.531 0 012.543 1.5z\"/><path d=\"M22.5 9.25a1 1 0 00-1 1v1a1 1 0 002 0v-1a1 1 0 00-1-1zM22.5 6.25a1 1 0 001-1v-1a1 1 0 00-2 0v1a1 1 0 001 1zM2 19.754H1a1 1 0 000 2h1a1 1 0 000-2zM15 19.754H5a1 1 0 000 2h10a1 1 0 100-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});