define("ember-svg-jar/inlined/email-action-sync-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-sync-1</title><path d=\"M17.5 9.34a8.176 8.176 0 013.154.634.25.25 0 00.346-.231V1.84a.159.159 0 00-.271-.113L12.2 10.254a2.407 2.407 0 01-3.4 0L.271 1.727A.157.157 0 00.1 1.693a.159.159 0 00-.1.147v10.5a1.749 1.749 0 001.75 1.75h8.13a.252.252 0 00.225-.14A8.242 8.242 0 0117.5 9.34z\"/><path d=\"M9.726 9.326a1.1 1.1 0 001.547 0L19.748.851A.438.438 0 0019.5.108a1.752 1.752 0 00-.251-.018h-17.5A1.752 1.752 0 001.5.108a.439.439 0 00-.25.743zM11.854 22.943l.969-.969a.251.251 0 01.359 0 6.086 6.086 0 0010.21-2.2 1 1 0 00-1.893-.648 4.091 4.091 0 01-6.918 1.42.25.25 0 01.008-.345l1.722-1.722a.5.5 0 00-.354-.853H11.5a.5.5 0 00-.5.5v4.464a.619.619 0 00.309.462h.022a.5.5 0 00.523-.109zM22.121 12.817a.251.251 0 01-.358 0 6.1 6.1 0 00-10.2 2.227 1 1 0 101.893.647 4.092 4.092 0 016.9-1.445.249.249 0 01-.007.346l-1.66 1.66a.5.5 0 00.354.854H23.5a.5.5 0 00.5-.5v-4.461a.5.5 0 00-.309-.462.638.638 0 00-.545.108z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});