define("ember-svg-jar/inlined/single-man-actions-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-cart</title><path d=\"M11.146 15.127a2 2 0 011.9-2.636h2.171a.248.248 0 00.242-.189.251.251 0 00-.124-.282c-.063-.034-.131-.066-.2-.1l-3.936-1.687a.248.248 0 01-.151-.229V8.87a.251.251 0 01.073-.177 3.306 3.306 0 00.779-2.231.248.248 0 01.089-.176 1.876 1.876 0 00.537-1.4 1.585 1.585 0 00-.316-1.015.247.247 0 01-.034-.264 2.537 2.537 0 00.156-1.727C11.986.493 10.284 0 8.93 0a3.754 3.754 0 00-3.163 1.324.25.25 0 01-.212.118 1.072 1.072 0 00-.9.375c-.372.493-.1 1.17.176 1.732a.251.251 0 01-.046.288 1.242 1.242 0 00-.161.2A1.763 1.763 0 004.362 5 1.52 1.52 0 005 6.331a.252.252 0 01.117.194A3.158 3.158 0 005.963 8.7a.25.25 0 01.08.183v1.123a.249.249 0 01-.152.229l-3.936 1.687C.682 12.468.5 13.626.248 15.23c-.056.359-.118.751-.2 1.179A.5.5 0 00.543 17h10.885a.252.252 0 00.2-.1.255.255 0 00.034-.226zM15.811 23.989a1.423 1.423 0 10-1.311-.869 1.425 1.425 0 001.311.869zM20.937 22.577A1.424 1.424 0 1019.514 24a1.425 1.425 0 001.423-1.423z\"/><path d=\"M23.919 11.737a.75.75 0 00-1.424-.474l-.852 2.555a.25.25 0 01-.237.171h-8.364a.5.5 0 00-.474.659l1.847 5.5a.5.5 0 00.473.341h6.231a.5.5 0 00.481-.365z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});