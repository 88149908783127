define("ember-svg-jar/inlined/style-three-pin-shop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-shop</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm5 15.5a.5.5 0 01-.5.5h-.75a.25.25 0 01-.25-.25V14a1.5 1.5 0 00-3 0v1.75a.25.25 0 01-.25.25H7.5a.5.5 0 01-.5-.5V11a.5.5 0 01.5-.5h9a.5.5 0 01.5.5zM18 9a.5.5 0 01-.5.5h-1.25a.25.25 0 01-.25-.25V7.5a.5.5 0 00-1 0v1.75a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25V7.5a.5.5 0 00-1 0v1.75a.25.25 0 01-.25.25h-2A.25.25 0 019 9.25V7.5a.5.5 0 00-1 0v1.75a.25.25 0 01-.25.25H6.5A.5.5 0 016 9V7a.493.493 0 01.088-.283l1.375-2a.5.5 0 01.412-.217h8.25a.5.5 0 01.412.217l1.375 2A.493.493 0 0118 7z\"/><path d=\"M10.25 11.5h-1.5a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});