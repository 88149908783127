define("ember-svg-jar/inlined/vide-document-avi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vide-document-avi</title><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM9.125 15a.625.625 0 01-1.25 0v-2.125a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0v-4.375a2.211 2.211 0 011.9-2.238 2.127 2.127 0 012.35 2.113zm5-4.5a8.176 8.176 0 01-1.625 4.875.625.625 0 01-1 0A8.176 8.176 0 019.875 10.5V9a.625.625 0 011.25 0v1.5a6.93 6.93 0 00.674 2.966.253.253 0 00.4 0 6.929 6.929 0 00.676-2.97V9a.625.625 0 011.25 0zm3.25 3.875h.125a.625.625 0 010 1.25h-2a.625.625 0 010-1.25h.125a.25.25 0 00.25-.25v-4.25a.25.25 0 00-.25-.25H15.5a.625.625 0 010-1.25h2a.625.625 0 010 1.25h-.125a.25.25 0 00-.25.25v4.25a.25.25 0 00.25.25z\"/><path d=\"M7 9.625a.877.877 0 00-.875.875v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V10.5A.877.877 0 007 9.625z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});