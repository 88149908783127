define("ember-svg-jar/inlined/tags-upload-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-upload-alternate</title><circle cx=\"17.17\" cy=\"5.999\" r=\"1.5\"/><path d=\"M9.9 21.191a.249.249 0 00-.32-.114 1.029 1.029 0 01-1.118-.2l-6.171-6.171a1 1 0 010-1.414l11-11A1 1 0 0114 2h6.67a.5.5 0 01.5.5v6.67a1 1 0 01-.293.708l-.166.166a.249.249 0 00.059.4 8.028 8.028 0 011.23.813.25.25 0 00.342-.027 2.927 2.927 0 00.825-2.06V2a2 2 0 00-2-2H14a2.98 2.98 0 00-2.122.879l-11 11a3 3 0 000 4.242l6.172 6.172a3.005 3.005 0 003.57.5.25.25 0 00.076-.373 8.073 8.073 0 01-.796-1.229z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-1 9.5v-2.75a.25.25 0 00-.25-.25h-.75a.5.5 0 01-.391-.812l2-2.5a.518.518 0 01.781 0l2 2.5a.5.5 0 01-.39.812h-.75a.25.25 0 00-.25.25v2.75a1 1 0 01-2 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});