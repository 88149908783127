define("ember-svg-jar/inlined/real-estate-favorite-hold-house-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-favorite-hold-house-1</title><path d=\"M15 9.913a1 1 0 001-1v-2.5a.5.5 0 01.5-.5h1a.5.5 0 00.336-.871L12.672.348a1 1 0 00-1.344 0L6.164 5.042a.5.5 0 00.336.871h1a.5.5 0 01.5.5v2.5a1 1 0 001 1h1a1 1 0 001-1v-2a1 1 0 012 0v2a1 1 0 001 1z\"/><rect x=\"3\" y=\"20.913\" width=\"6\" height=\"3\" rx=\".5\" ry=\".5\"/><path d=\"M4 19.413a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3.934a.5.5 0 00-.021-.145 22.378 22.378 0 00-1.51-3.89c-.717-1.115-2.032-.5-1.688.937l.383 1.67a.392.392 0 01-.66.365l-1.858-1.858a.5.5 0 01-.147-.35C2.467 7.768 1.4 6.635.666 6.379A.5.5 0 000 6.851v7.105a1 1 0 00.329.742c1.035.936 3 2.977 3.532 3.538a.5.5 0 01.139.346z\"/><rect x=\"15\" y=\"20.913\" width=\"6\" height=\"3\" rx=\".5\" ry=\".5\"/><path d=\"M21.5 12.208a.5.5 0 01-.147.35L19.5 14.416a.392.392 0 01-.66-.365l.383-1.67c.343-1.437-.971-2.052-1.688-.937a22.378 22.378 0 00-1.51 3.89.5.5 0 00-.021.145v3.934a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-.831a.5.5 0 01.139-.346c.536-.561 2.5-2.6 3.532-3.538a1 1 0 00.325-.742v-7.1a.5.5 0 00-.666-.472c-.729.251-1.801 1.384-1.834 5.824z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});