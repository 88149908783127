define("ember-svg-jar/inlined/messages-people-woman-bubble-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-woman-bubble-1</title><circle cx=\"12.868\" cy=\"6.249\" r=\"1\"/><circle cx=\"16.118\" cy=\"6.249\" r=\"1\"/><circle cx=\"19.368\" cy=\"6.249\" r=\"1\"/><path d=\"M17.892 0a5.314 5.314 0 00-3.612 1.327 5.489 5.489 0 00-5.791 1.448 1 1 0 001.45 1.377 3.5 3.5 0 014.105-.758 1 1 0 001.165-.2A3.469 3.469 0 0117.892 2 4.026 4.026 0 0122 6.2a3.853 3.853 0 01-.961 2.632A4.005 4.005 0 0117.992 10a1 1 0 00-.826.436 2.267 2.267 0 01-1.674 1.064 1.98 1.98 0 01-.475-.058 1 1 0 00-.486 1.94 3.784 3.784 0 003.967-1.4 5.8 5.8 0 004.009-1.793A5.844 5.844 0 0024 6.2 6.03 6.03 0 0017.892 0z\"/><circle cx=\"16.252\" cy=\"16.247\" r=\"1.5\"/><path d=\"M1.566 16.943a1 1 0 001.383-.3 5.649 5.649 0 00.594-1.283 4.733 4.733 0 007.33 0 5.649 5.649 0 00.594 1.283 1 1 0 101.68-1.084 4.563 4.563 0 01-.642-2.51V11.8a5.3 5.3 0 10-10.594 0v1.25a4.567 4.567 0 01-.642 2.511 1 1 0 00.297 1.382zM7.208 15.1c-2.629 0-2.794-2.7-2.814-2.8a5.711 5.711 0 002.823-1.758 5.709 5.709 0 002.806 1.757C10 12.394 9.836 15.1 7.208 15.1zM7.208 18a7.543 7.543 0 00-7.185 5.358A.5.5 0 00.5 24h13.415a.5.5 0 00.479-.642A7.545 7.545 0 007.208 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});