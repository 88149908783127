define("ember-svg-jar/inlined/force-touch-tap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>force-touch-tap</title><path d=\"M9.823 14.033a1.812 1.812 0 001.427-.712l3.874-5.845a.671.671 0 011.2.558c-.416 1.438-.4 1.376-.412 1.449a1.894 1.894 0 003.612 1.031c1.187-3.421.965-3.06 1.993-4.542a15.328 15.328 0 002.322-4.5.75.75 0 00-.714-.972h-7.771a.744.744 0 00-.437.141 11.465 11.465 0 00-3.382 4.253L8.379 11.34a1.728 1.728 0 001.444 2.693zM22.5 19.5h-3a.25.25 0 00-.25.25v3.5a.25.25 0 00.25.25h2A2.5 2.5 0 0024 21a1.5 1.5 0 00-1.5-1.5zM17.75 19.75a.25.25 0 00-.25-.25h-16A1.5 1.5 0 000 21a2.5 2.5 0 002.5 2.5h15a.25.25 0 00.25-.25zM5.793 15.457a1 1 0 101.414-1.414l-1-1a1 1 0 00-1.414 1.414z\"/><path d=\"M14.207 15.457l1-1a1 1 0 00-1.414-1.414l-1 1a1 1 0 101.414 1.414zM4.5 18.5H6a1 1 0 000-2H4.5a1 1 0 000 2zM14 18.5h1.5a1 1 0 000-2H14a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});