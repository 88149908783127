define("ember-svg-jar/inlined/car-dashboard-long-lights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-dashboard-long-lights</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm3 16.751a1.5 1.5 0 01-1.5-1.5v-7a1.5 1.5 0 011.5-1.5 5 5 0 010 10zm-3.822-4.515l-5.031 1A.813.813 0 016 13.25a.75.75 0 01-.145-1.486l5.031-1a.75.75 0 01.292 1.472zm.59 2.618a.751.751 0 01-.59.881l-5.031 1a.732.732 0 01-.147.014.75.75 0 01-.145-1.485l5.031-1a.752.752 0 01.883.59zm-.59-6.118l-5.031 1A.732.732 0 016 9.75a.75.75 0 01-.145-1.485l5.031-1a.75.75 0 11.292 1.471z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});