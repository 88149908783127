define("ember-svg-jar/inlined/people-man-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-1</title><path d=\"M21.063 19.156l-2.528-.849a.251.251 0 00-.312.145 6.678 6.678 0 01-12.43-.005.248.248 0 00-.311-.145l-2.562.86A4.519 4.519 0 000 23.375a.5.5 0 00.5.5h23a.5.5 0 00.5-.5 4.524 4.524 0 00-2.937-4.219z\"/><path d=\"M12 21.185A5.175 5.175 0 0016.766 18a.251.251 0 00-.151-.334l-.957-.322a.5.5 0 00-.6.231 3.5 3.5 0 01-6.124 0 .5.5 0 00-.6-.231l-.967.325a.249.249 0 00-.15.334A5.21 5.21 0 0012 21.185zM12.792 12.452a1.14 1.14 0 01-.222.094 1.815 1.815 0 01-.544.079 1.846 1.846 0 01-.548-.08 1.161 1.161 0 01-.224-.094.75.75 0 10-.731 1.31 2.767 2.767 0 00.5.214 3.35 3.35 0 001 .15 3.374 3.374 0 001-.148 2.7 2.7 0 00.5-.216.75.75 0 00-.734-1.309zM11.128 10.161A.749.749 0 0011.09 9.1a2.02 2.02 0 00-2.6 0 .75.75 0 001.022 1.1.559.559 0 01.557 0 .751.751 0 001.059-.039zM12.91 9.1a.75.75 0 001.022 1.1.559.559 0 01.557 0 .75.75 0 001.022-1.1 2.02 2.02 0 00-2.601 0z\"/><path d=\"M5.174 8.57a2.113 2.113 0 00-.464 1.444 2.731 2.731 0 001.439 2.433.25.25 0 01.144.187c.511 3.048 4.021 4.991 5.686 4.991s5.176-1.943 5.687-4.989a.252.252 0 01.145-.189 2.732 2.732 0 001.439-2.434 2.116 2.116 0 00-.465-1.443c.359-2.559.014-4.831-1.275-6.354C16.334.829 14.474.125 11.979.125S7.626.828 6.45 2.216C5.173 3.722 4.811 5.983 5.174 8.57zm12.017 2.511a1.746 1.746 0 00-1 1.3c-.378 2.249-3.176 3.739-4.209 3.739s-3.83-1.49-4.207-3.738a1.747 1.747 0 00-1-1.306 1.275 1.275 0 01-.559-1.1.76.76 0 01.062-.36h.346a1.754 1.754 0 001.71-1.397l.223-1.11a4.98 4.98 0 003.422-1.216A4.99 4.99 0 0015.4 7.109l.222 1.11a1.754 1.754 0 001.716 1.406h.351a.86.86 0 01.058.359 1.281 1.281 0 01-.556 1.097z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});