define("ember-svg-jar/inlined/people-man-11.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-11</title><path d=\"M6.162 13.53a.211.211 0 01.132.15c.521 2.956 3.977 4.765 5.685 4.765s5.165-1.809 5.686-4.764a.216.216 0 01.133-.151 2.617 2.617 0 001.452-2.355 2 2 0 00-.461-1.388c.372-2.555-.029-4.552-1.2-5.94a5.287 5.287 0 00-2.069-1.482.249.249 0 00-.342.19l-.171 1.023a.249.249 0 00.141.267 3.729 3.729 0 011.3.967c.934 1.111 1.2 2.846.8 5.156a.751.751 0 00.417.807c.022.011.1.125.092.373a1.162 1.162 0 01-.546 1 1.707 1.707 0 00-1.016 1.267c-.375 2.121-3.174 3.526-4.209 3.526s-3.84-1.401-4.215-3.521a1.7 1.7 0 00-1.016-1.268 1.163 1.163 0 01-.545-1c0-.248.069-.363.091-.373a.751.751 0 00.417-.807c-.405-2.31-.137-4.046.8-5.157a3.744 3.744 0 011.332-.982.252.252 0 00.14-.273l-.17-1.02a.252.252 0 00-.12-.177.248.248 0 00-.216-.013 5.31 5.31 0 00-2.113 1.5C5.2 5.234 4.8 7.231 5.17 9.787a2 2 0 00-.46 1.389 2.614 2.614 0 001.452 2.354z\"/><path d=\"M10.438 6.164a1 1 0 00.986.836h1.152a1 1 0 00.986-.836l.738-4.417A1.5 1.5 0 0012.819 0h-1.638A1.5 1.5 0 009.7 1.747zM12.792 13.272a1.138 1.138 0 01-.22.094 2.182 2.182 0 01-.549.079 1.833 1.833 0 01-.546-.08 1.168 1.168 0 01-.225-.094.75.75 0 10-.731 1.31 2.757 2.757 0 00.5.214 3.151 3.151 0 001.005.15 3.315 3.315 0 00.993-.149 2.6 2.6 0 00.5-.216.75.75 0 00-.734-1.308zM11.129 10.981a.751.751 0 00-.039-1.06 2.019 2.019 0 00-2.6 0 .75.75 0 001.022 1.1.557.557 0 01.557 0 .752.752 0 001.06-.04zM12.91 9.921a.75.75 0 001.022 1.1.557.557 0 01.557 0 .75.75 0 101.022-1.1 2.019 2.019 0 00-2.601 0zM21.08 19.287l-.017-.006-4.476-1.5a.5.5 0 00-.562.178 4.985 4.985 0 01-8.05 0 .5.5 0 00-.562-.178L2.92 19.287A4.519 4.519 0 000 23.5a.5.5 0 00.5.5h23a.5.5 0 00.5-.5 4.519 4.519 0 00-2.92-4.213z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});