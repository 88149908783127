define("ember-svg-jar/inlined/weather-clouds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-clouds</title><path d=\"M22.094 7.074a.252.252 0 01-.1-.193A7 7 0 008.338 4.84a.249.249 0 01-.22.173 5.5 5.5 0 00-5 6.61.248.248 0 00.318.188q.312-.1.634-.164a.249.249 0 00.177-.143 8.4 8.4 0 01.74-1.322.253.253 0 00.041-.11 3.5 3.5 0 013.92-3.043h.064c.23-.085.465-.161.7-.226a1 1 0 00.348-.612A5 5 0 0120 7c0 .146-.007.289-.02.433a1 1 0 00.5.959 3 3 0 01-.088 5.263c.029.177.051.356.069.537a.244.244 0 00.074.155 6.467 6.467 0 01.734.853.251.251 0 00.334.067 5 5 0 00.49-8.193z\"/><path d=\"M19.094 15.074a.252.252 0 01-.1-.193A7 7 0 005.338 12.84a.249.249 0 01-.22.173A5.5 5.5 0 005.5 24H16a5 5 0 003.094-8.926zM16 22H5.5a3.5 3.5 0 11.448-6.971 1 1 0 001.116-.829A5 5 0 0117 15c0 .146-.007.289-.02.433a1 1 0 00.5.959A3 3 0 0116 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});