define("ember-svg-jar/inlined/nuclear-energy-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>nuclear-energy-message</title><circle cx=\"12.25\" cy=\"11\" r=\"1\"/><path d=\"M15.5 5.37a.5.5 0 00-.684.183l-1.75 3.031a.5.5 0 00.183.684 2.008 2.008 0 011 1.732.5.5 0 00.5.5h3.5a.5.5 0 00.5-.5A6.525 6.525 0 0015.5 5.37zM10.25 11a2.008 2.008 0 011-1.732.5.5 0 00.183-.684l-1.75-3.031A.5.5 0 009 5.371 6.523 6.523 0 005.749 11a.5.5 0 00.5.5h3.5a.5.5 0 00.501-.5zM13.933 12.915a.5.5 0 00-.683-.184 2.006 2.006 0 01-2 0 .5.5 0 00-.683.184l-1.75 3.031a.5.5 0 00.183.682 6.525 6.525 0 006.5 0 .5.5 0 00.184-.683z\"/><path d=\"M12 .75C5.383.75 0 5.348 0 11a9.308 9.308 0 002.783 6.57.5.5 0 01.076.6L1.2 21.012a1.488 1.488 0 001.873 2.117l5.267-2.257a.507.507 0 01.317-.025 13.962 13.962 0 003.343.4c6.617 0 12-4.6 12-10.25S18.617.75 12 .75zm0 18.5a11.87 11.87 0 01-3.3-.462 1 1 0 00-.672.042l-3.621 1.552a.249.249 0 01-.286-.064.252.252 0 01-.029-.292l1.1-1.891a1 1 0 00-.231-1.278A7.554 7.554 0 012 11c0-4.55 4.486-8.25 10-8.25S22 6.45 22 11s-4.486 8.25-10 8.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});