define("ember-svg-jar/inlined/accounting-bill-stack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accounting-bill-stack</title><path d=\"M23 11.25a1 1 0 000-2h-5.25A.25.25 0 0117.5 9V8a.25.25 0 01.25-.25H23a1 1 0 000-2h-5.25a.25.25 0 01-.25-.25v-.25a1 1 0 00-1-1h-9a1 1 0 00-1 1v.25a.25.25 0 01-.25.25H1a1 1 0 000 2h5.25A.25.25 0 016.5 8v1a.25.25 0 01-.25.25H1a1 1 0 000 2h5.25a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H1a1 1 0 000 2h5.25a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H1a1 1 0 000 2h5.25a.25.25 0 01.25.25v.25a1 1 0 001 1h9a1 1 0 001-1v-.25a.25.25 0 01.25-.25H23a1 1 0 000-2h-5.25a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H23a1 1 0 000-2h-5.25a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25zm-9.689.217a2.084 2.084 0 01-.361 3.98.249.249 0 00-.2.245V16a.75.75 0 01-1.5 0v-.25a.25.25 0 00-.25-.25h-.5a.75.75 0 010-1.5h2.033a.592.592 0 00.221-1.141l-2.065-.826a2.084 2.084 0 01.361-3.98.249.249 0 00.2-.245V7.5a.75.75 0 011.5 0v.25A.25.25 0 0013 8h.5a.75.75 0 010 1.5h-2.033a.592.592 0 00-.221 1.141z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});