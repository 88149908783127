define("ember-svg-jar/inlined/sync-location-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sync-location-refresh</title><path d=\"M7.5 10.5c0 3.171 3.977 7.184 4.146 7.354a.5.5 0 00.708 0c.169-.17 4.146-4.183 4.146-7.354a4.5 4.5 0 00-9 0zm6 0A1.5 1.5 0 1112 9a1.5 1.5 0 011.5 1.5z\"/><path d=\"M5.407 11.887a.75.75 0 00-.656-.387h-.426a.5.5 0 01-.5-.565 8.243 8.243 0 0114.646-4.053 1 1 0 101.568-1.242 10.242 10.242 0 00-18.232 5.407.5.5 0 01-.5.453H.75a.75.75 0 00-.636 1.147l2 3.2a.75.75 0 001.273 0l2-3.2a.75.75 0 00.02-.76zM23.886 11.353l-2-3.2a.78.78 0 00-1.273 0l-2 3.2a.75.75 0 00.636 1.147h.426a.5.5 0 01.5.565 8.243 8.243 0 01-14.646 4.053 1 1 0 10-1.568 1.242 10.242 10.242 0 0018.232-5.407.5.5 0 01.5-.453h.56a.75.75 0 00.636-1.147z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});