define("ember-svg-jar/inlined/smiley-concerned-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-concerned-alternate</title><path d=\"M18.055 5.168a1 1 0 00-1.387.277 3.041 3.041 0 01-3.353 1.106 1 1 0 10-.63 1.9 4.781 4.781 0 001.5.239 5.029 5.029 0 004.145-2.133 1 1 0 00-.275-1.389zM6.8 8.1a1.545 1.545 0 012.4 0 1 1 0 101.6-1.2 3.5 3.5 0 00-5.6 0 1 1 0 101.6 1.2zM17.717 14.635a7.754 7.754 0 00-8.065 3.446 1 1 0 001.7 1.06 5.768 5.768 0 015.935-2.554 1 1 0 10.434-1.952z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><circle cx=\"8\" cy=\"11\" r=\"1.5\"/><circle cx=\"15.5\" cy=\"11\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});