define("ember-svg-jar/inlined/calendar-favorite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>calendar-favorite</title><path d=\"M21.5 3h-2.75a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v4.75a.75.75 0 11-1.5 0V3.5a.5.5 0 00-.5-.5H8.25A.25.25 0 018 2.751V1a1 1 0 10-2 0v4.75a.75.75 0 11-1.5 0V3.5A.5.5 0 004 3H2.5a2 2 0 00-2 2v17a2 2 0 002 2h19a2 2 0 002-2V5a2 2 0 00-2-2zm0 18.5a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5v-12A.5.5 0 013 9h18a.5.5 0 01.5.5z\"/><path d=\"M16.028 13.578h-2.05L12.9 11.065l-.01-.021a.995.995 0 00-1.782.021l-1.085 2.513H7.967a.945.945 0 00-.9.585.977.977 0 00.245 1.074l1.791 1.6-1 2.293a.966.966 0 00.242 1.116.991.991 0 00.665.259 1 1 0 00.493-.131L12 18.968l2.488 1.4a.982.982 0 001.158-.128.965.965 0 00.242-1.116l-1-2.293 1.812-1.607a.972.972 0 00.231-1.06.949.949 0 00-.903-.586z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});