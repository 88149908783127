define("ember-svg-jar/inlined/wild-bird-sing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wild-bird-sing</title><path d=\"M21.586 6.75l-5.992.227L13.577.7A1 1 0 0011.8.438S8.018 6.017 8 6.056a7.4 7.4 0 00-5.56 11.192c1.906 3.335.4 5.193-.013 6.028a.5.5 0 00.447.724h12.5a.5.5 0 00.448-.723c-1.474-2.947-.923-4.372-.287-6.024a10.99 10.99 0 00.779-2.867c.095-.047 6.017-5.929 6.017-5.929a1 1 0 00-.745-1.707zM8.624 10.5a1 1 0 11-1 1 1 1 0 011-1zm7.615 1.22a.5.5 0 01-.766-.071 20.3 20.3 0 00-4.951-4.885.5.5 0 01-.14-.7L12.3 3.253l1.624 5.058a.973.973 0 00.99.693l4.2-.159z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});