define("ember-svg-jar/inlined/messages-bubble-square-disable-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-disable-alternate</title><path d=\"M8.5 14a1 1 0 00-.664.252L5 16.773V15a1 1 0 00-1-1H2.25a.251.251 0 01-.25-.25V2.25A.25.25 0 012.25 2h18a.25.25 0 01.25.25v7.941a.25.25 0 00.148.228 7.679 7.679 0 011.453.853.25.25 0 00.4-.2V2a2 2 0 00-2-2H2a2 2 0 00-2 2v12.053A1.953 1.953 0 002 16h1v3a1 1 0 001.664.748L8.88 16h.82a.25.25 0 00.244-.2 7.585 7.585 0 01.486-1.453.25.25 0 00-.23-.347z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm0 11a4.438 4.438 0 01-2.1-.532.253.253 0 01-.129-.184.249.249 0 01.071-.213l5.727-5.727a.249.249 0 01.4.059A4.419 4.419 0 0122 17.5a4.505 4.505 0 01-4.5 4.5zm0-9a4.435 4.435 0 012.1.532.25.25 0 01.129.184.247.247 0 01-.07.213l-5.728 5.728a.251.251 0 01-.4-.059A4.426 4.426 0 0113 17.5a4.505 4.505 0 014.5-4.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});