define("ember-svg-jar/inlined/glasses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>glasses</title><path d=\"M20.609 1.435a1 1 0 000 2A1.393 1.393 0 0122 4.826v7.907a.25.25 0 01-.3.245 2.857 2.857 0 00-.61-.065H16.3a2.9 2.9 0 00-2.164.966.25.25 0 01-.32.044 3.379 3.379 0 00-3.64 0 .25.25 0 01-.32-.044 2.9 2.9 0 00-2.156-.966H2.913a2.857 2.857 0 00-.61.065.25.25 0 01-.3-.245V4.826a1.393 1.393 0 011.388-1.391 1 1 0 000-2A3.4 3.4 0 000 4.826v14.826a2.916 2.916 0 002.913 2.913H7.7a2.916 2.916 0 002.913-2.913v-2.87a1.391 1.391 0 012.782 0v2.87a2.916 2.916 0 002.905 2.913h4.783A2.916 2.916 0 0024 19.652V4.826a3.4 3.4 0 00-3.391-3.391zm-12 18.217a.914.914 0 01-.913.913H2.913A.914.914 0 012 19.652v-3.826a.915.915 0 01.913-.913H7.7a.915.915 0 01.913.913zm13.391 0a.914.914 0 01-.913.913H16.3a.914.914 0 01-.913-.913v-3.826a.915.915 0 01.913-.913h4.783a.915.915 0 01.913.913z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});