define("ember-svg-jar/inlined/multiple-actions-chat.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-chat</title><path d=\"M14.452 8.5a.053.053 0 00.019-.1l-.486-.172a1.8 1.8 0 01-.055-1.172 4.647 4.647 0 001.216-3.569A3.22 3.22 0 0012 0a2.994 2.994 0 00-1.342.314.25.25 0 00-.1.363 5 5 0 01.842 2.808 6.269 6.269 0 01-.922 3.624.25.25 0 00.121.372 16.965 16.965 0 012.277.987A.241.241 0 0013 8.5h1.454z\"/><path d=\"M9.5 12a3.477 3.477 0 011.293-2.7.25.25 0 00-.066-.426 68.545 68.545 0 00-1.256-.473l-.486-.172a1.8 1.8 0 01-.055-1.175 4.647 4.647 0 001.216-3.569A3.22 3.22 0 007 0a3.22 3.22 0 00-3.146 3.485 4.605 4.605 0 001.193 3.538 1.877 1.877 0 010 1.185l-.515.19c-2.01.739-3.46 1.272-3.914 2.179A7.542 7.542 0 000 13.5a.5.5 0 00.5.5h8.75a.25.25 0 00.25-.25zM22 10h-9a2 2 0 00-2 2v7a2 2 0 002 2h.75a.25.25 0 01.25.25V23a1 1 0 001.625.781l3.407-2.726a.247.247 0 01.156-.055H22a2 2 0 002-2v-7a2 2 0 00-2-2zm-3.875 9.219l-1.719 1.375A.249.249 0 0116 20.4V20a1 1 0 00-1-1h-1.75a.25.25 0 01-.25-.25v-6.5a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v6.5a.249.249 0 01-.25.25h-3a1 1 0 00-.625.219z\"/><path d=\"M15 15h5a.75.75 0 000-1.5h-5a.75.75 0 000 1.5zM18.75 16.75A.75.75 0 0018 16h-3a.75.75 0 000 1.5h3a.75.75 0 00.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});