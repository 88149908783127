define("ember-svg-jar/inlined/casino-winner-diamond", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>casino-winner-diamond</title><path d=\"M6.524 16.725a.249.249 0 00-.249-.225H2.75a1 1 0 000 2h4.058a.251.251 0 00.209-.387 3.083 3.083 0 01-.493-1.388zM12.339 9.5h5.788a.251.251 0 00.248-.217l.483-3.673a.252.252 0 01.161-.2 1.5 1.5 0 10-1.477-.253.249.249 0 01.062.306A3.435 3.435 0 0114.5 7.5c-2.514 0-3.48-2.845-3.826-4.458a.249.249 0 01.113-.265 1.5 1.5 0 10-1.575 0 .249.249 0 01.113.265C8.98 4.655 8.014 7.5 5.5 7.5a3.417 3.417 0 01-3.11-2.036.252.252 0 01.063-.307 1.5 1.5 0 10-1.473.25.254.254 0 01.162.2l1.112 8.455a.5.5 0 00.5.435h4.327a.249.249 0 00.2-.1l2.625-3.655A3.032 3.032 0 0112.339 9.5z\"/><path d=\"M23.712 15.569l-2.85-3.969a1.507 1.507 0 00-1.2-.6h-7.323a1.514 1.514 0 00-1.212.617L8.3 15.551a1.513 1.513 0 00.165 1.988l6.491 6.04a1.5 1.5 0 002.077.008l6.523-6.071a1.5 1.5 0 00.156-1.947zm-3.538 2.364l-4 3.726a.25.25 0 01-.34 0l-4.005-3.726A.25.25 0 0112 17.5h8a.25.25 0 01.17.433zm.761-2.569a.247.247 0 01-.222.136h-9.426a.25.25 0 01-.2-.4l1.437-2a.25.25 0 01.2-.1h6.552a.252.252 0 01.2.1l1.437 2a.249.249 0 01.022.264z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});