define("ember-svg-jar/inlined/single-neutral-actions-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-video</title><path d=\"M.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.56a.25.25 0 00.25-.25v-2.25a2.987 2.987 0 011.266-2.438.251.251 0 00.1-.259A5.5 5.5 0 000 13.75v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M11 17.25v5a1.5 1.5 0 001.5 1.5h6.25a1.5 1.5 0 001.5-1.5v-.524a.25.25 0 01.314-.241l2.807.748A.5.5 0 0024 21.75v-6a.5.5 0 00-.629-.483l-2.807.748a.25.25 0 01-.314-.241v-.524a1.5 1.5 0 00-1.5-1.5H12.5a1.5 1.5 0 00-1.5 1.5zM.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.56a.25.25 0 00.25-.25v-2.25a2.987 2.987 0 011.266-2.438.251.251 0 00.1-.259A5.5 5.5 0 000 13.75v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M11 17.25v5a1.5 1.5 0 001.5 1.5h6.25a1.5 1.5 0 001.5-1.5v-.524a.25.25 0 01.314-.241l2.807.748A.5.5 0 0024 21.75v-6a.5.5 0 00-.629-.483l-2.807.748a.25.25 0 01-.314-.241v-.524a1.5 1.5 0 00-1.5-1.5H12.5a1.5 1.5 0 00-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});