define("ember-svg-jar/inlined/single-woman-actions-location.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-location</title><path d=\"M13.222 12.218a.253.253 0 00.036-.22.25.25 0 00-.153-.161l-1.131-.414a.779.779 0 01-.513-.73v-.467a.25.25 0 01.25-.25 6.374 6.374 0 003.786-.9.388.388 0 00.084-.593c-.494-.537-.835-1.185-.981-3.317C14.338 1.346 11.736 0 9.5 0S4.661 1.346 4.4 5.171c-.146 2.132-.487 2.78-.981 3.317a.388.388 0 00.084.593 6.348 6.348 0 003.782.9.251.251 0 01.254.25v.462a.779.779 0 01-.513.73c-2.9 1.065-5.212 1.825-5.764 2.922A7.822 7.822 0 00.5 17.076a.386.386 0 00.1.3.4.4 0 00.29.126h11a.25.25 0 00.244-.3A5.58 5.58 0 0112 16a6.454 6.454 0 011.222-3.782zM18.5 11a5.006 5.006 0 00-5 5c0 3.171 4.457 7.664 4.646 7.854a.5.5 0 00.708 0c.189-.19 4.646-4.683 4.646-7.854a5.006 5.006 0 00-5-5zm-1.75 5a1.75 1.75 0 111.75 1.75A1.752 1.752 0 0116.75 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});