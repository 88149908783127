define("ember-svg-jar/inlined/e-commerce-app-store", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-app-store</title><path d=\"M12.2 7.75a.78.78 0 00-.36-.47l-1.38-.79a.79.79 0 00-.59-.08.77.77 0 00-.47.37L3.61 16.91a.77.77 0 00-.08.59.78.78 0 00.37.5l1.39.8a.77.77 0 00.39.1.79.79 0 00.68-.39l5.76-10.17a.78.78 0 00.08-.59zM4.43 20L3 19.19a.61.61 0 00-.58 0 .61.61 0 00-.31.49l-.24 2.09a.5.5 0 00.21.53.42.42 0 00.22.06.61.61 0 00.35-.12L4.47 21a.59.59 0 00.29-.5.59.59 0 00-.33-.5zM20.95 17.81a.93.93 0 00-.07-.77l-.41-.78C12.21.6 11.69.85 11.31 1.05a.42.42 0 00-.2.42c0 1.63 6.68 16.76 7.61 17.23a1.46 1.46 0 00.65.18 1.4 1.4 0 00.59-.16h.11a1.66 1.66 0 00.88-.91zM22.26 19.49a1.46 1.46 0 00-1.57.13l-.07.05a1.2 1.2 0 00-.12 1.78 3 3 0 001.38.94c.32.14.64.28.69.43a.25.25 0 00.24.17.25.25 0 00.22-.22c.05-.27.25-2.61-.77-3.28zM14.5 13.71h-4a.75.75 0 000 1.5h4a.75.75 0 000-1.5zM3.75 14.46a.75.75 0 00-.75-.75H1.5v-2.5h4a.75.75 0 000-1.5H.75a.75.75 0 00-.75.75v4a.75.75 0 00.75.75H3a.75.75 0 00.75-.75zM23.25 9.71H19.5a.75.75 0 000 1.5h3v2.5h-1.3a.75.75 0 100 1.5h2.05a.75.75 0 00.75-.75v-4a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});