define("ember-svg-jar/inlined/phone-actions-merge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-merge</title><path d=\"M17.793 16.058a2.5 2.5 0 00-3.536 0l-.385.385a46.968 46.968 0 01-5.789-5.789l.386-.385a2.5 2.5 0 000-3.535L6.347 4.613a2.558 2.558 0 00-3.536 0L1.647 5.776a3.507 3.507 0 00-.441 4.4 46.906 46.906 0 0013.141 13.145 3.54 3.54 0 004.4-.442l1.164-1.164a2.505 2.505 0 000-3.535z\"/><path d=\"M14.123 13.139a1.241 1.241 0 00.886-.355l4-4.021a1.252 1.252 0 00.364-.882V4.9h1.543a1 1 0 00.707-1.7L18.83.4a1 1 0 00-1.414 0l-2.793 2.8a1 1 0 00.707 1.7h1.543v2.466l-3.636 3.654a1.238 1.238 0 00.886 2.119zM23.015 11.026l-1.486-1.514a1.25 1.25 0 10-1.784 1.752l1.486 1.514a1.263 1.263 0 001.769.015 1.249 1.249 0 00.015-1.767z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});