define("ember-svg-jar/inlined/underwear-bra-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>underwear-bra-1</title><path d=\"M10.748 17.434a.5.5 0 00.238-.55c-.965-4.044-2.692-6.99-7.594-8.074A.5.5 0 013 8.322V1a1 1 0 00-2 0v7.72a.979.979 0 01-.072.37c-.057.144-.14.359-.237.647a.5.5 0 00.356.645c4.117 1.008 6.527 3.218 7.975 7.324a.5.5 0 00.7.281q.505-.255 1.026-.553z\"/><path d=\"M.726 11.85a.5.5 0 00-.617.422A13.692 13.692 0 000 14a19.838 19.838 0 00.513 4.611.5.5 0 00.329.363 9.946 9.946 0 003.172.525 11.035 11.035 0 003.311-.533.5.5 0 00.324-.641C6.385 14.672 4.331 12.749.726 11.85zM19.972 21.006a15 15 0 01-7.464-2.27 1.026 1.026 0 00-1.016 0 15 15 0 01-7.464 2.27 11.082 11.082 0 01-2.264-.238.5.5 0 00-.6.554l.236 1.811a1 1 0 00.379.665.96.96 0 00.751.188A56.144 56.144 0 0112 23a56.067 56.067 0 019.472.987.944.944 0 00.158.013.975.975 0 00.593-.2 1 1 0 00.379-.666l.233-1.811a.5.5 0 00-.6-.553 11.1 11.1 0 01-2.263.236zM22.953 10.382a.5.5 0 00.356-.645c-.1-.289-.181-.5-.238-.648A1 1 0 0123 8.718V1a1 1 0 00-2 0v7.322a.5.5 0 01-.392.488c-4.9 1.084-6.629 4.03-7.594 8.074a.5.5 0 00.238.55q.522.3 1.03.553a.5.5 0 00.7-.281c1.444-4.106 3.854-6.316 7.971-7.324z\"/><path d=\"M16.351 18.325a.506.506 0 00.026.389.5.5 0 00.3.252 11.035 11.035 0 003.311.533 9.946 9.946 0 003.172-.525.5.5 0 00.329-.363A19.838 19.838 0 0024 14a13.692 13.692 0 00-.109-1.728.5.5 0 00-.617-.422c-3.605.899-5.659 2.822-6.923 6.475z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});