define("ember-svg-jar/inlined/water-pitcher-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-pitcher-3</title><path d=\"M20.234 9.091l-1.7-2.826a1.987 1.987 0 01-.206-1.565l.937-2.763A1.5 1.5 0 0017.828 0H6.172a1.51 1.51 0 00-1.2.6 1.527 1.527 0 00-.222 1.361l.915 2.7a2 2 0 01-.2 1.6l-1.7 2.826a3.97 3.97 0 00-.465 2.961l2.549 10.4A1.99 1.99 0 007.8 24h8.4a1.984 1.984 0 001.945-1.537L20.7 12.048a3.97 3.97 0 00-.466-2.957zM5.481 10.119L7.176 7.3a4.03 4.03 0 00.39-3.242L6.982 2.33A.25.25 0 017.219 2h9.562a.25.25 0 01.237.33l-.6 1.757a4.014 4.014 0 00.4 3.208l1.7 2.825a2 2 0 01.233 1.477l-.427 1.853a.25.25 0 01-.244.194H5.918a.25.25 0 01-.244-.194l-.427-1.85a2.007 2.007 0 01.234-1.481z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});