define("ember-svg-jar/inlined/religion-christianity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-christianity</title><path d=\"M23.25 5.167a1 1 0 00-2 0 10.322 10.322 0 01-1.214 4.871.25.25 0 01-.4.059 12.549 12.549 0 00-8.776-3.653c-4.913 0-9.621 4.651-9.819 4.849a1.009 1.009 0 000 1.416c.2.2 4.9 4.847 9.818 4.847a12.492 12.492 0 008.779-3.656.25.25 0 01.4.059 10.329 10.329 0 011.214 4.872 1 1 0 002 0 12.319 12.319 0 00-1.97-6.7.253.253 0 010-.272 12.316 12.316 0 001.968-6.692zM3.24 12c1.424-1.213 4.572-3.556 7.622-3.556a10.349 10.349 0 017.66 3.389.25.25 0 010 .335 10.375 10.375 0 01-7.661 3.388c-3.04 0-6.195-2.345-7.621-3.556z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});