define("ember-svg-jar/inlined/computer-chip-fire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>computer-chip-fire</title><path d=\"M11.2 21.126a.75.75 0 00-.75.75v1.25a.75.75 0 001.5 0v-1.25a.75.75 0 00-.75-.75zM14.7 21.126a.75.75 0 00-.75.75v1.25a.75.75 0 001.5 0v-1.25a.75.75 0 00-.75-.75zM11.2 7.126a.75.75 0 00.75-.75v-1.25a.75.75 0 00-1.5 0v1.25a.75.75 0 00.75.75zM7.7 21.126a.75.75 0 00-.75.75v1.25a.75.75 0 101.5 0v-1.25a.75.75 0 00-.75-.75zM7.7 7.126a.75.75 0 00.75-.75v-1.25a.75.75 0 00-1.5 0v1.25a.75.75 0 00.75.75zM20.551 17.376H19.3a.75.75 0 000 1.5h1.25a.75.75 0 000-1.5zM3.8 13.376H2.55a.75.75 0 100 1.5H3.8a.75.75 0 000-1.5zM20.551 13.376H19.3a.75.75 0 000 1.5h1.25a.75.75 0 000-1.5zM3.8 9.376H2.55a.75.75 0 100 1.5H3.8a.75.75 0 000-1.5zM3.8 17.376H2.55a.75.75 0 000 1.5H3.8a.75.75 0 000-1.5zM12.956 8.3a.25.25 0 00-.238-.175H7.051a1.5 1.5 0 00-1.5 1.5v9a1.5 1.5 0 001.5 1.5h9a1.5 1.5 0 001.5-1.5V12.3a.248.248 0 00-.211-.246A5.5 5.5 0 0112.956 8.3zm-4.9 8.325a.75.75 0 01.75-.75h1.75a.75.75 0 110 1.5H8.8a.75.75 0 01-.749-.749zM17 .139a.494.494 0 00-.566.281.5.5 0 00.161.61 2.859 2.859 0 011.074 2.556.142.142 0 01-.283.006.5.5 0 00-.239-.372.507.507 0 00-.44-.037 3.653 3.653 0 00-2.5 3.489 4 4 0 008 0A6.826 6.826 0 0017 .139z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});