define("ember-svg-jar/inlined/tags-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-settings</title><circle cx=\"16.973\" cy=\"17.03\" r=\"1.5\"/><path d=\"M8.493 17.046A8.486 8.486 0 0118.52 8.687a.5.5 0 00.448-.141l.131-.134A3.79 3.79 0 0020.084 6V2a2 2 0 00-2-2l-4.257.005a3.747 3.747 0 00-2.414 1L.585 11.833a2 2 0 000 2.829L5.421 19.5a1.977 1.977 0 001.406.581h.011a1.976 1.976 0 001.411-.6l.288-.294a.5.5 0 00.133-.451 8.351 8.351 0 01-.177-1.69zM14.585 7.5a2 2 0 112-2 2 2 0 01-2 2z\"/><path d=\"M22.977 13.541a1.75 1.75 0 00-1.936-.842l-1.412.327a.449.449 0 01-.532-.31l-.424-1.392a1.776 1.776 0 00-3.4 0l-.423 1.392a.456.456 0 01-.534.31l-1.411-.326a1.783 1.783 0 00-1.7 2.953l.987 1.066a.461.461 0 010 .625l-.987 1.065a1.783 1.783 0 001.7 2.953l1.412-.327a.454.454 0 01.533.311l.423 1.391a1.776 1.776 0 003.4 0l.423-1.391a.448.448 0 01.534-.311l1.411.327a1.783 1.783 0 001.7-2.953l-.988-1.065a.463.463 0 010-.625l.988-1.066a1.757 1.757 0 00.236-2.112zm-6 6.489a3 3 0 113-3 3 3 0 01-3.004 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});