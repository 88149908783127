define("ember-svg-jar/inlined/layers-grid-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layers-grid-warning</title><path d=\"M8 19a.25.25 0 01-.25-.25V15a.25.25 0 01.25-.25h4a.25.25 0 01.25.25v.593a.25.25 0 00.472.116l1.214-2.309a.1.1 0 000-.1.1.1 0 00-.085-.048.1.1 0 01-.1-.1V8A.25.25 0 0114 7.75h3.75A.25.25 0 0118 8v1.865a.25.25 0 00.192.243 2.9 2.9 0 011.382.77A.25.25 0 0020 10.7V3a3 3 0 00-3-3H3a3 3 0 00-3 3v15a3 3 0 003 3h6.793a.253.253 0 00.222-.134l.787-1.5A.251.251 0 0010.58 19zm4.25-6a.25.25 0 01-.25.25H8a.25.25 0 01-.25-.25V8A.25.25 0 018 7.75h4a.25.25 0 01.25.25zM2 8a.25.25 0 01.25-.25H6a.25.25 0 01.25.25v5a.25.25 0 01-.25.25H2.249A.25.25 0 012 13zm6-1.75A.25.25 0 017.749 6V2.25A.25.25 0 018 2h4a.25.25 0 01.25.25V6a.25.25 0 01-.25.25zM18 3v3a.25.25 0 01-.25.25H14a.25.25 0 01-.25-.25V2.25A.25.25 0 0114 2h3a1 1 0 011 1zM2 3a1 1 0 011-1h3a.25.25 0 01.25.25V6a.25.25 0 01-.25.25H2.249A.25.25 0 012 6zm0 15v-3a.25.25 0 01.25-.25H6a.25.25 0 01.25.25v3.75A.25.25 0 016 19H3a1 1 0 01-1-1z\"/><path d=\"M18.78 12.271a1.45 1.45 0 00-2.561 0L11.163 21.9a1.435 1.435 0 001.281 2.1h10.111a1.434 1.434 0 001.28-2.095zM17.5 14.75a.75.75 0 01.75.75v3a.75.75 0 01-1.5 0v-3a.75.75 0 01.75-.75zm-1 6.5a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});