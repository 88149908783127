define("ember-svg-jar/inlined/mood-happy-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mood-happy-laptop</title><path d=\"M24 20.5a.5.5 0 00-.5-.5h-1.75a.25.25 0 01-.25-.25V15.5a1 1 0 00-2 0v4.25a.25.25 0 01-.25.25H4.75a.25.25 0 01-.25-.25V9.167a.667.667 0 01.667-.667H7.5a1 1 0 000-2H5.167A2.669 2.669 0 002.5 9.167V19.75a.25.25 0 01-.25.25H.5a.5.5 0 00-.5.5A3.5 3.5 0 003.5 24h17a3.5 3.5 0 003.5-3.5z\"/><path d=\"M10.5 14a.51.51 0 00.215-.048l3.385-1.613a.25.25 0 01.226.006A6.564 6.564 0 1017.434 0 6.432 6.432 0 0011 6.567a6.67 6.67 0 00.659 3.107.251.251 0 010 .222l-1.614 3.389A.5.5 0 0010.5 14zm9.25-9.5a.75.75 0 11-.75.75.75.75 0 01.75-.75zm-.379 3a.126.126 0 01.125.133 2 2 0 01-3.976.153.252.252 0 01.248-.286zM15.25 6a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});