define("ember-svg-jar/inlined/bendable-phone-touch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bendable-phone-touch</title><path d=\"M6 9.75h-.422A7.513 7.513 0 000 17a1.5 1.5 0 001.5 1.5h7A1.5 1.5 0 0010 17a6.481 6.481 0 011.335-3.947.251.251 0 00.015-.281A6.252 6.252 0 006 9.75zM.266 4.97a3.749 3.749 0 004.927 1.963L7.835 5.8a.249.249 0 01.3.076 3.767 3.767 0 002.965 1.45 3.73 3.73 0 001.477-.3l3.9-1.68a.251.251 0 01.329.13.749.749 0 101.376-.592L16.388.7a.749.749 0 10-1.377.592l1.009 2.342a.251.251 0 01-.13.329l-3.905 1.68a2.249 2.249 0 01-2.232-.264.25.25 0 01.051-.43l1.59-.684a.75.75 0 00-.594-1.378L4.6 5.555a2.256 2.256 0 01-2.236-.262.249.249 0 01.05-.43l7.956-3.424A.75.75 0 109.777.061L.658 3.985a.751.751 0 00-.392.985z\"/><path d=\"M24 16a7.508 7.508 0 00-7.5-7.5H9.381a.25.25 0 00-.106.476 7.807 7.807 0 013.414 3.114A6.467 6.467 0 0114 16v6.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5zm-6.5 2.5A1.5 1.5 0 0116 17v-1.225a8.243 8.243 0 00-1.358-4.5.5.5 0 01-.016-.527.528.528 0 01.465-.248l1.986.128A5.209 5.209 0 0122 15.726V17a1.5 1.5 0 01-1.5 1.5zM20 21a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});