define("ember-svg-jar/inlined/hourglass-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hourglass-1</title><path d=\"M21 22h-.929l-4.215-8.693a2.977 2.977 0 010-2.614L20.071 2H21a1 1 0 000-2H3a1 1 0 000 2h.929l4.215 8.693a2.977 2.977 0 010 2.614L3.929 22H3a1 1 0 000 2h18a1 1 0 000-2zM8.063 4.663a.5.5 0 01.437-.256h7a.5.5 0 01.426.762l-3.5 5.688a.5.5 0 01-.852 0l-3.5-5.688a.5.5 0 01-.011-.506zm3.511 9.482a.52.52 0 01.852 0l3.5 5.687a.5.5 0 01-.426.763h-7a.5.5 0 01-.426-.763z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});