define("ember-svg-jar/inlined/messages-bubble-heart-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-heart-1</title><path d=\"M10.03 13.779a5.025 5.025 0 017.329-1.9.249.249 0 00.281 0 5.056 5.056 0 013.638-.811c.054.009.108.02.161.032a.25.25 0 00.3-.179A7.3 7.3 0 0022 9c0-4.962-4.934-9-11-9S0 4.038 0 9a8.077 8.077 0 002.657 5.854L1.03 19.329a.5.5 0 00.124.533.506.506 0 00.538.1l5.832-2.429a13.107 13.107 0 001.966.375.248.248 0 00.222-.09.251.251 0 00.044-.236 5.012 5.012 0 01.274-3.803z\"/><path d=\"M21.038 12.546a3.481 3.481 0 00-3.057.987l-.482.482-.481-.482a3.525 3.525 0 00-5.646.916 3.521 3.521 0 00.653 4.063l5.113 5.334a.5.5 0 00.723 0l5.106-5.327a3.524 3.524 0 00.66-4.07 3.487 3.487 0 00-2.589-1.903z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});