define("ember-svg-jar/inlined/password-cursor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>password-cursor</title><path d=\"M22.571 16.987a.5.5 0 00.1-.932l-7.447-3.725a.5.5 0 00-.671.671l3.725 7.449a.5.5 0 00.932-.1l.6-2.4a.5.5 0 01.364-.364z\"/><path d=\"M13.586 11.363A1.986 1.986 0 0115 10.777a2.009 2.009 0 01.894.211l6.1 3.049a.5.5 0 00.462-.008 1.991 1.991 0 001.044-1.752v-7a2 2 0 00-2-2h-19a2 2 0 00-2 2v7a2 2 0 002 2h10.609a.25.25 0 00.213-.118.248.248 0 00.011-.243l-.122-.245a2 2 0 01.375-2.308zM19 7.277a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm-14 3a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm5.5-1.5a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});