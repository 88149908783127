define("ember-svg-jar/inlined/ecology-plant-hand-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ecology-plant-hand-1</title><path d=\"M16.646 18.25h-8.5a.5.5 0 110-1h4a.5.5 0 00.5-.5 3 3 0 00-3-3h-6a.25.25 0 01-.25-.25 1 1 0 00-2 0V23a1 1 0 002 0 .25.25 0 01.25-.25h16.5a.5.5 0 00.5-.5 4.005 4.005 0 00-4-4zM22.168 5.216a.5.5 0 00-.332-.276.5.5 0 00-.423.086 6.491 6.491 0 01-2.542 1.091 6.976 6.976 0 00-2.258.892 2.737 2.737 0 00-1.37 2.191 2.113 2.113 0 00.376 1.189.5.5 0 01.017.527c-.12.207-.232.412-.336.613a.25.25 0 01-.456-.029 20.2 20.2 0 00-1.1-2.443.5.5 0 01.128-.624 2.883 2.883 0 001.068-1.59 3.792 3.792 0 00-1.148-3.266 6.674 6.674 0 00-3.485-1.867A8.865 8.865 0 016.917.118.5.5 0 006.5.009a.5.5 0 00-.347.258c-1.378 2.606-.73 7.352.778 9.13a4.406 4.406 0 003.119 1.853 2.377 2.377 0 00.317-.022 2.327 2.327 0 001-.395.5.5 0 00.162-.636A24.153 24.153 0 008.9 6.063a.5.5 0 11.786-.621c1.942 2.461 4.019 6.313 4.468 9.1a.75.75 0 00.687.634.515.515 0 00.055 0 .75.75 0 00.717-.53c.642-2.073 2.183-4.988 4.169-5.98a.5.5 0 01.447.895 6.761 6.761 0 00-2.466 2.689.5.5 0 00.233.71 2.105 2.105 0 00.435.135 2.18 2.18 0 00.4.037 3.44 3.44 0 002.391-1.194 7.3 7.3 0 00.946-6.722z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});