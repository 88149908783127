define("ember-svg-jar/inlined/laptop-help-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-help-message</title><path d=\"M24 20.5a.5.5 0 00-.5-.5h-1.75a.25.25 0 01-.25-.25V15.5a1 1 0 10-2 0v4.25a.25.25 0 01-.25.25H4.75a.25.25 0 01-.25-.25V9.167a.667.667 0 01.667-.667H7.5a1 1 0 100-2H5.167A2.669 2.669 0 002.5 9.167v10.584a.25.25 0 01-.25.25H.5a.5.5 0 00-.5.5A3.5 3.5 0 003.5 24h17a3.5 3.5 0 003.5-3.5z\"/><path d=\"M10.5 14a.51.51 0 00.215-.048L14.1 12.34a.25.25 0 01.226.006A6.564 6.564 0 1017.434 0 6.432 6.432 0 0011 6.568a6.665 6.665 0 00.659 3.106.253.253 0 010 .223l-1.614 3.389A.5.5 0 0010.5 14zm9.25-8.75A2.253 2.253 0 0117.5 7.5a.75.75 0 010-1.5.75.75 0 10-.75-.75.75.75 0 11-1.5 0 2.25 2.25 0 014.5 0zM17.5 10a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});