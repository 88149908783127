define("ember-svg-jar/inlined/fitness-weighlifting-bench", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fitness-weighlifting-bench</title><path d=\"M14 12.75h-4a1.5 1.5 0 000 3h.5a.5.5 0 01.5.5v2.5a.5.5 0 01-.5.5 1 1 0 000 2h3a1 1 0 000-2 .5.5 0 01-.5-.5v-2.5a.5.5 0 01.5-.5h.5a1.5 1.5 0 000-3z\"/><path d=\"M22.5 2.75h-1a1.5 1.5 0 00-1.5 1.5v1.5a.5.5 0 01-.5.5H19a.5.5 0 01-.5-.5v-2a1 1 0 00-2 0v2a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5v-1.5a1 1 0 00-2 0v1.5a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-1.5a1.5 1.5 0 00-1.5-1.5h-1A1.5 1.5 0 000 4.25v6a1.5 1.5 0 001.5 1.5h1a1.5 1.5 0 001.5-1.5v-1.5a.5.5 0 01.5-.5H5a.5.5 0 01.5.5v11.5a1 1 0 002 0V8.75a.5.5 0 01.5-.5h8a.5.5 0 01.5.5v11a1 1 0 002 0v-11a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v1.5a1.5 1.5 0 001.5 1.5h1a1.5 1.5 0 001.5-1.5v-6a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});