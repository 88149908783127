define("ember-svg-jar/inlined/single-woman-actions-image.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-image</title><path d=\"M9 11a2 2 0 012-2h3.953a.228.228 0 00.226-.2.389.389 0 00-.1-.316c-.494-.537-.835-1.185-.981-3.317C13.838 1.346 11.236 0 9 0S4.161 1.346 3.9 5.171c-.145 2.132-.487 2.78-.981 3.317A.388.388 0 003 9.081a6.348 6.348 0 003.782.9.249.249 0 01.253.25v.462a.778.778 0 01-.512.73c-2.9 1.065-5.212 1.825-5.764 2.922A7.822 7.822 0 000 17.076a.386.386 0 00.1.3.4.4 0 00.29.126h8.36A.25.25 0 009 17.25z\"/><path d=\"M22 10.5h-9.5a2 2 0 00-2 2V22a2 2 0 002 2H22a2 2 0 002-2v-9.5a2 2 0 00-2-2zm-9.5 2.25a.25.25 0 01.25-.25h9a.25.25 0 01.25.25v4.4a.25.25 0 01-.458.138l-.267-.4a1.25 1.25 0 00-2.064-.023l-1.537 2.2a.248.248 0 01-.169.1.251.251 0 01-.192-.052l-.612-.489a1.249 1.249 0 00-1.821.282l-2 3.005a.206.206 0 01-.377-.114z\"/><circle cx=\"15.5\" cy=\"15\" r=\"1.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});