define("ember-svg-jar/inlined/board-game-geometry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>board-game-geometry</title><path d=\"M5.939 11.435l5.537-4.079a.251.251 0 00-.042-.428L6.479 4.6a.25.25 0 00-.355.2l-.582 6.407a.25.25 0 00.397.228zM14.6 6.623L22.948 9.4c.219.071.076-.235.076-.235l-5.89-7.461a.25.25 0 00-.42.043l-2.262 4.527a.249.249 0 00.148.349zM13.339 18.191L6.29 14.96a.25.25 0 00-.343.3l1.762 5.874a.25.25 0 00.351.152l5.287-2.644a.25.25 0 00-.008-.451zM12.447 8.5l-6.042 4.455a.251.251 0 00.044.429l7.632 3.5a.249.249 0 00.349-.276l-1.589-7.95a.251.251 0 00-.394-.158zM16.323 16.38l4.963-5.58a.25.25 0 00-.108-.4l-6.513-2.17a.25.25 0 00-.324.287l1.551 7.753a.249.249 0 00.431.11zM19.242 21.743l-3.625-2.417a.248.248 0 00-.251-.016l-7.208 3.6s-.261.227.151.184c2.194-.228 9.338-1.005 10.848-1.169.182-.017.192-.111.085-.182zM23.281 10.81l-6.407 7.209a.25.25 0 00.048.374l3.152 2.1a.828.828 0 01.224.255c.1.162.2.155.273-.079.524-1.8 2.809-9.653 2.839-9.737s-.068-.189-.129-.122zM12.7 5.872a.25.25 0 00.33-.114l2.247-4.5A.248.248 0 0015.018.9c-1.728.315-7.537 1.375-8.9 1.662-.2.041-.119.151-.119.151zM3.68 12.608a.244.244 0 00.249-.22c.091-1.029.482-5.406.684-7.708a.25.25 0 00-.476-.126l-3.531 7.7a.25.25 0 00.227.354zM4.087 14.281a.244.244 0 00-.238-.173H1.014a.25.25 0 00-.2.394l5.438 7.718s.253.244.155-.088c-.487-1.669-2.021-6.84-2.32-7.851z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});