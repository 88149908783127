define("ember-svg-jar/inlined/upload-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>upload-box</title><path d=\"M1.208 5.25h9.777a.249.249 0 00.25-.25L11.164.246a.25.25 0 00-.25-.246H4.653a2.588 2.588 0 00-2.286 1.526L1.192 4.2a5.731 5.731 0 00-.228.741.253.253 0 00.047.214.25.25 0 00.197.095zM12.985 5.25h9.807a.25.25 0 00.2-.095.253.253 0 00.047-.214 5.731 5.731 0 00-.228-.741 1.305 1.305 0 00-.039-.085l-1.319-2.559A2.6 2.6 0 0019.153 0h-6.238a.249.249 0 00-.25.254L12.735 5a.251.251 0 00.25.25zM.75 7v15a2 2 0 002 2h18.5a2 2 0 002-2V7a.25.25 0 00-.25-.25H1A.25.25 0 00.75 7zm5.88 7.914l5-5.5a.747.747 0 01.376-.164.5.5 0 01.372.173l4.75 5.5a.5.5 0 01-.378.827H15a.25.25 0 00-.25.25v4.75a.5.5 0 01-.5.5H9.5a.5.5 0 01-.5-.5V16a.25.25 0 00-.25-.25H7a.5.5 0 01-.37-.836z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});