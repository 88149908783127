define("ember-svg-jar/inlined/transportation-ticket-bus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transportation-ticket-bus</title><rect x=\"9.25\" y=\"8.75\" width=\"5.5\" height=\"3.5\" rx=\".25\" ry=\".25\"/><path d=\"M23.25 9.25A.75.75 0 0024 8.5V5a1.752 1.752 0 00-1.75-1.75H1.75A1.752 1.752 0 000 5v3.5a.75.75 0 00.75.75 2.75 2.75 0 010 5.5.75.75 0 00-.75.75V19a1.752 1.752 0 001.75 1.75h20.5A1.752 1.752 0 0024 19v-3.5a.75.75 0 00-.75-.75 2.75 2.75 0 010-5.5zM7 11a.75.75 0 01-1.5 0V9A.75.75 0 017 9zm9.25 3.75a1.746 1.746 0 01-.875 1.509.25.25 0 00-.125.216v.775a.75.75 0 01-1.5 0v-.5a.25.25 0 00-.25-.25h-3a.25.25 0 00-.25.25v.5a.75.75 0 01-1.5 0v-.775a.25.25 0 00-.125-.216 1.746 1.746 0 01-.875-1.509V9A1.752 1.752 0 019.5 7.25h5A1.752 1.752 0 0116.25 9zM18.5 11a.75.75 0 01-1.5 0V9a.75.75 0 011.5 0z\"/><path d=\"M10 13.5a.765.765 0 00-.75.75.75.75 0 00.75.75.75.75 0 00.75-.75.765.765 0 00-.75-.75zM14 13.5a.765.765 0 00-.75.75.75.75 0 00.75.75.75.75 0 00.75-.75.765.765 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});