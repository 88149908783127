define("ember-router-scroll/services/router-scroll", ["exports", "@ember/service", "@ember/object", "@ember/utils", "@ember/debug", "@ember/application"], function (_exports, _service, _object, _utils, _debug, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let RouterScroll = (_class = class RouterScroll extends _service.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "key", void 0);
      _defineProperty(this, "targetElement", void 0);
      _defineProperty(this, "scrollElement", 'window');
      _defineProperty(this, "isFirstLoad", true);
      _defineProperty(this, "preserveScrollPosition", false);
      // ember-app-scheduler properties
      _defineProperty(this, "scrollWhenIdle", false);
      _defineProperty(this, "scrollWhenAfterRender", false);
    }
    get isFastBoot() {
      const fastboot = (0, _application.getOwner)(this).lookup('service:fastboot');
      return fastboot ? fastboot.get('isFastBoot') : false;
    }
    init(...args) {
      super.init(...args);
      this._loadConfig();
      (0, _object.set)(this, 'scrollMap', {
        default: {
          x: 0,
          y: 0
        }
      });
    }
    unsetFirstLoad() {
      (0, _object.set)(this, 'isFirstLoad', false);
    }
    update() {
      if ((0, _object.get)(this, 'isFastBoot') || (0, _object.get)(this, 'isFirstLoad')) {
        return;
      }
      const scrollElement = (0, _object.get)(this, 'scrollElement');
      const targetElement = (0, _object.get)(this, 'targetElement');
      const scrollMap = (0, _object.get)(this, 'scrollMap');
      const key = (0, _object.get)(this, 'key');
      let x;
      let y;
      if (targetElement) {
        let element = document.querySelector(targetElement);
        if (element) {
          x = element.offsetLeft;
          y = element.offsetTop;

          // if we are looking to where to transition to next, we need to set the default to the position
          // of the targetElement on screen
          (0, _object.set)(scrollMap, 'default', {
            x,
            y
          });
        }
      } else if ('window' === scrollElement) {
        x = window.scrollX;
        y = window.scrollY;
      } else if ('#' === scrollElement.charAt(0)) {
        let element = document.getElementById(scrollElement.substring(1));
        if (element) {
          x = element.scrollLeft;
          y = element.scrollTop;
        }
      }

      // only a `key` present after first load
      if (key && 'number' === (0, _utils.typeOf)(x) && 'number' === (0, _utils.typeOf)(y)) {
        (0, _object.set)(scrollMap, key, {
          x,
          y
        });
      }
    }
    _loadConfig() {
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      if (config && config.routerScroll) {
        const scrollElement = config.routerScroll.scrollElement;
        const targetElement = config.routerScroll.targetElement;
        (true && !(!(scrollElement && targetElement)) && (0, _debug.assert)('You defined both scrollElement and targetElement in your config. We currently only support definining one of them', !(scrollElement && targetElement)));
        if ('string' === (0, _utils.typeOf)(scrollElement)) {
          (0, _object.set)(this, 'scrollElement', scrollElement);
        }
        if ('string' === (0, _utils.typeOf)(targetElement)) {
          (0, _object.set)(this, 'targetElement', targetElement);
        }
        const {
          scrollWhenIdle = false,
          scrollWhenAfterRender = false
        } = config.routerScroll;
        (0, _object.set)(this, 'scrollWhenIdle', scrollWhenIdle);
        (0, _object.set)(this, 'scrollWhenAfterRender', scrollWhenAfterRender);
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "isFastBoot", [_object.computed], Object.getOwnPropertyDescriptor(_class.prototype, "isFastBoot"), _class.prototype), _class);
  Object.defineProperty(RouterScroll.prototype, 'position', {
    configurable: true,
    get() {
      const scrollMap = (0, _object.get)(this, 'scrollMap');
      const stateUuid = (0, _object.get)(window, 'history.state.uuid');
      (0, _object.set)(this, 'key', stateUuid);
      const key = (0, _object.get)(this, 'key') || '-1';
      return (0, _object.get)(scrollMap, key) || scrollMap.default;
    }
  });
  var _default = _exports.default = RouterScroll;
});