define("ember-svg-jar/inlined/pencil", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pencil</title><path d=\"M16.187 3.511a.514.514 0 00-.707 0L4.067 14.924a.5.5 0 000 .707l4.28 4.28a.5.5 0 00.707 0L20.467 8.5a.5.5 0 000-.707zM3 16.71a.5.5 0 00-.828.2l-2.14 6.42a.5.5 0 00.12.512.508.508 0 00.512.121l6.42-2.139a.5.5 0 00.2-.829zM22.968 1.017a3.613 3.613 0 00-4.988 0l-.712.713a.5.5 0 000 .707l4.279 4.28a.5.5 0 00.707 0L22.968 6a3.53 3.53 0 000-4.983z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});