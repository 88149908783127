define("ember-svg-jar/inlined/medical-instrument-syringe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-instrument-syringe</title><path d=\"M18.392 7.022a.5.5 0 01.553-.105A3.385 3.385 0 0020.3 7.2a3.5 3.5 0 10-3.5-3.5 3.385 3.385 0 00.287 1.351.5.5 0 01-.1.553L15.636 6.95a.5.5 0 01-.707 0l-.217-.217a.5.5 0 01-.106-.552 3.388 3.388 0 00.286-1.351 3.477 3.477 0 00-1.025-2.475 3.586 3.586 0 00-4.949 0A3.476 3.476 0 007.892 4.83a1 1 0 102 0 1.485 1.485 0 01.44-1.06 1.535 1.535 0 012.121 0 1.5 1.5 0 010 2.118L9.124 9.22l-4.95 4.949a3.992 3.992 0 00-.77 4.542.5.5 0 01-.1.569L.5 22.089A1 1 0 001.911 23.5l2.808-2.8a.5.5 0 01.571-.1 3.985 3.985 0 004.541-.772l4.949-4.95 3.331-3.33a1.5 1.5 0 111.059 2.563 1 1 0 000 2A3.494 3.494 0 1017.818 9.4a.5.5 0 01-.548-.107l-.22-.22a.5.5 0 010-.707zM20.3 2.2a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm-4.66 8.281a.5.5 0 010 .707L14.427 12.4a.5.5 0 01-.707 0l-1.061-1.06-1.059-1.06a.5.5 0 010-.707l1.21-1.21a.5.5 0 01.707 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});