define("ember-svg-jar/inlined/phone-action-sync-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-sync-1</title><path d=\"M12 13.809V18.5a1 1 0 01-1 1H4a1 1 0 01-1-1v-11a1 1 0 011-1h4.271a.245.245 0 00.244-.234 7.941 7.941 0 01.361-1.942A.245.245 0 008.644 4H3.5A2.5 2.5 0 001 6.5v15A2.5 2.5 0 003.5 24h8a2.5 2.5 0 002.5-2.5v-6.978a.241.241 0 00-.163-.228 7.97 7.97 0 01-1.46-.691.244.244 0 00-.377.206z\"/><path d=\"M21.77 7.6a1 1 0 00-1.271.622 4.107 4.107 0 01-7.083 1.248l1.894-1.9a.5.5 0 00-.353-.853H10.5a.5.5 0 00-.5.5v4.463a.5.5 0 00.854.353L12 10.889a6.105 6.105 0 0010.392-2.018A1 1 0 0021.77 7.6zM16.347 0a6.108 6.108 0 00-5.78 4.129 1 1 0 001.893.648 4.1 4.1 0 017.061-1.27L17.69 5.338a.5.5 0 00.353.853H22.5a.5.5 0 00.5-.5V1.234a.5.5 0 00-.854-.353L20.938 2.09A6.129 6.129 0 0016.347 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});