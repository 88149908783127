define("ember-svg-jar/inlined/love-it-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it-text</title><path d=\"M22.5 9.376a2.713 2.713 0 01.552.06.248.248 0 00.274-.134 6.173 6.173 0 00-.006-5.6 6.587 6.587 0 00-10.453-1.71l-.858.783-.829-.755A6.53 6.53 0 005.489.214 6.472 6.472 0 00.7 3.7a6.4 6.4 0 001.2 7.454l7.169 7.776a.249.249 0 00.433-.169v-6.385a3 3 0 013-3z\"/><path d=\"M22 10.876h-9a2 2 0 00-2 2v9a2 2 0 002 2h9a2 2 0 002-2v-9a2 2 0 00-2-2zm-8.5 11a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5h8a.5.5 0 01.5.5v8a.5.5 0 01-.5.5z\"/><path d=\"M20 14.876h-4.75a.75.75 0 000 1.5H20a.75.75 0 000-1.5zM17.5 17.876h-2.25a.75.75 0 000 1.5h2.25a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});