define("ember-svg-jar/inlined/synchronize-arrows-square-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>synchronize-arrows-square-1</title><path d=\"M7 21.748h10a1.25 1.25 0 000-2.5H7A1.753 1.753 0 015.25 17.5v-4.25A.25.25 0 015.5 13h1a.5.5 0 00.384-.82l-2.5-3a.5.5 0 00-.769 0l-2.5 3A.5.5 0 001.5 13h1a.249.249 0 01.25.25v4.25A4.255 4.255 0 007 21.748zM20 15a.5.5 0 00.384-.18l2.5-3A.5.5 0 0022.5 11h-1a.25.25 0 01-.25-.25V6.5A4.255 4.255 0 0017 2.248H7a1.25 1.25 0 000 2.5h10A1.752 1.752 0 0118.75 6.5v4.25a.25.25 0 01-.25.25h-1a.5.5 0 00-.384.82l2.5 3A.5.5 0 0020 15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});