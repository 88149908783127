define("ember-svg-jar/inlined/vide-document-m4v", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vide-document-m4v</title><path d=\"M12.645 10.327a4.915 4.915 0 00-1.268 1.827.159.159 0 00.146.221h1.083a.25.25 0 00.25-.25v-1.7c0-.178-.142-.159-.211-.098z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM9.119 15a.625.625 0 01-1.25 0v-3.018c0-.111-.133-.071-.168 0l-.149.3a.649.649 0 01-1.117 0s-.093-.181-.149-.3-.167-.078-.167 0V15a.625.625 0 01-1.25 0V9a.624.624 0 011.183-.279s.711 1.443.786 1.571a.179.179 0 00.308.005l.789-1.576A.625.625 0 019.119 9zm4.987 0a.625.625 0 01-1.25 0v-1.123a.252.252 0 00-.252-.252h-2.11a.626.626 0 01-.617-.731A6.148 6.148 0 0113.2 8.449a.625.625 0 01.9.561zm5.013-4.5a8.182 8.182 0 01-1.625 4.875.625.625 0 01-1 0 8.176 8.176 0 01-1.625-4.875V9a.625.625 0 011.25 0v1.5a6.916 6.916 0 00.674 2.966c.063.131.328.152.386.031a6.924 6.924 0 00.69-3V9a.625.625 0 011.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});