define("@gavant/ember-shopify-draggable/components/droppable-item", ["exports", "@gavant/ember-shopify-draggable/components/draggable-item", "@gavant/ember-shopify-draggable/templates/components/droppable-item", "@ember/object", "@ember/runloop"], function (_exports, _draggableItem, _droppableItem, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _draggableItem.default.extend({
    layout: _droppableItem.default,
    didInsertElement() {
      this._super(...arguments);
      if ((0, _object.get)(this, 'dropzone')) {
        (0, _runloop.schedule)('afterRender', (0, _object.get)(this, 'dropzone'), 'registerItem', this);
      }
    },
    willDestroyElement() {
      if ((0, _object.get)(this, 'dropzone')) {
        (0, _runloop.schedule)('afterRender', (0, _object.get)(this, 'dropzone'), 'deregisterItem', this);
      }
      this._super(...arguments);
    }
  });
});