define("ember-svg-jar/inlined/spider-web-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>spider-web-1</title><path d=\"M23.085 18.348l-1-.681V6.333l1-.681A1 1 0 1021.958 4l-.964.658L13 1.292V1a1 1 0 00-2 0v.292L3.006 4.658 2.042 4A1 1 0 10.915 5.652l1 .681v11.334l-1 .681A1 1 0 102.042 20l.964-.658L11 22.708V23a1 1 0 002 0v-.292l7.994-3.366.964.658a1 1 0 101.127-1.652zm-3-2.045l-1.348-.918v-6.77l1.35-.915zM13 7.282l2.572 1.072L13 10.108zm-2 2.826L8.428 8.354 11 7.282zM10.225 12l-2.964 2.021V9.979zM11 13.892v2.826l-2.572-1.072zm2 0l2.572 1.754L13 16.718zM13.775 12l2.964-2.021v4.042zm5.251-6l-1.482 1.01L13 5.116V3.462zM11 3.462v1.654L6.456 7.01 4.974 6zM3.913 7.7l1.348.918v6.77l-1.348.912zM4.974 18l1.482-1.01L11 18.884v1.654zM13 20.538v-1.654l4.544-1.894L19.026 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});