define("ember-svg-jar/inlined/power-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>power-button</title><path d=\"M10.5 10.915a1.5 1.5 0 003 0V1.5a1.5 1.5 0 10-3 0z\"/><path d=\"M1.2 11.533a10.917 10.917 0 0018.52 9.272 10.825 10.825 0 003.08-9.278A11.021 11.021 0 0017.067 3.4a1.5 1.5 0 10-1.437 2.63 8.048 8.048 0 014.2 5.925A7.914 7.914 0 016.4 18.684a7.854 7.854 0 01-2.237-6.726A8.042 8.042 0 018.36 6.036a1.5 1.5 0 10-1.441-2.631A11.015 11.015 0 001.2 11.533z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});