define("ember-svg-jar/inlined/material-fabric", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>material-fabric</title><path d=\"M2 11h6.277a.249.249 0 00.249-.242c.035-1.078.121-2.2.255-3.482a.253.253 0 00-.063-.193A.25.25 0 008.532 7H2a2 2 0 000 4zM2 16.5h6.484a.251.251 0 00.185-.082.257.257 0 00.064-.193c-.125-1.283-.2-2.4-.22-3.479a.251.251 0 00-.25-.246H2a2 2 0 000 4zM8.98 18.448a9.71 9.71 0 01-.039-.31.155.155 0 00-.155-.138H2a2 2 0 000 4h6.68a.251.251 0 00.232-.345 3.044 3.044 0 01-.192-1.645zM10.826 17.5h12.318a.5.5 0 00.5-.44 35.9 35.9 0 000-10.121.5.5 0 00-.5-.439H10.822a.5.5 0 00-.5.444 39.2 39.2 0 000 10.111.5.5 0 00.504.445zM10.34 4.582a.5.5 0 00.493.418h12.3a.5.5 0 00.492-.41l.15-.821A1.5 1.5 0 0022.3 2H11.68a1.5 1.5 0 00-1.48 1.746zM23.627 19.41a.5.5 0 00-.492-.41h-12.3a.5.5 0 00-.493.418l-.139.835A1.5 1.5 0 0011.68 22H22.3a1.5 1.5 0 001.477-1.77z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});