define("ember-svg-jar/inlined/octopus-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>octopus-1</title><path d=\"M19 14.26c-.454-.179-.894-.352-1.293-.529a1.257 1.257 0 01-.325-.928c.069-.405.575-.588.985-.67 3.321-.661 5.383-2.774 5.383-5.515 0-2.927-2.325-5.052-5.529-5.052-3.121 0-5.021 2.082-6.176 3.634L9.528 8.478a4.082 4.082 0 01-.731.6h-.028c-1.45.106-3.209-1.995-6.006-1.533a3.386 3.386 0 00-2.748 3.834 3.507 3.507 0 003.39 3.408c2.5 0 3.219-1.8 2.882-3.153a.994.994 0 00-1.187-.722 1.009 1.009 0 00-.753 1.19c.087.421-.077.685-.938.685-1 0-1.348-.988-1.4-1.573-.013-.152-.09-1.5 1.082-1.7 1.558-.248 2.73.82 4.2 1.325a2.813 2.813 0 011.292 2.6c-.159.074-.322.147-.486.221-1.227.551-2.618 1.176-3.225 2.593A3.8 3.8 0 005.7 20.9c2.478 2.061 5.847.133 5.1-2.335a1.944 1.944 0 00-1.655-1.479 1 1 0 00-.308 1.952c.179.287-.057.479-.247.606a1.55 1.55 0 01-1.611-.282c-.6-.5-.695-1.3-.261-2.316.3-.7 1.183-1.1 2.207-1.556a15.135 15.135 0 001.646-.824 4.951 4.951 0 012.287.592 4.182 4.182 0 001.985 2.729c.922.554 1.193 1.316 1.051 1.825a.93.93 0 01-1.014.62 1 1 0 000 2 2.92 2.92 0 002.942-2.085 3.6 3.6 0 00-1.486-3.761c.316-.713.638-.839.948-.865.315.131.647.263.986.4 1.572.621 3.73 1.473 3.73 2.446 0 1.5-1.288.677-1.346.478a1 1 0 00-2 0c0 2.747 5.346 3.752 5.346-.478 0-2.334-2.772-3.428-5-4.307zm-8.665-4.775a.956.956 0 11.956.956.956.956 0 01-.956-.956zm2.935 2.142a.956.956 0 11.955.956.956.956 0 01-.955-.956z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});