define("ember-svg-jar/inlined/credit-card-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-shield</title><path d=\"M.25 2.75h17.5A.25.25 0 0018 2.5 2.5 2.5 0 0015.5 0h-13A2.5 2.5 0 000 2.5a.25.25 0 00.25.25zM17.479 9.5h.021A.5.5 0 0018 9V4.75a.5.5 0 00-.5-.5H.5a.5.5 0 00-.5.5v5.75A2.5 2.5 0 002.5 13h6.632a.5.5 0 00.481-.365 3.046 3.046 0 011.715-1.97A17.439 17.439 0 0117.479 9.5zM6.5 10.25h-3a.75.75 0 010-1.5h3a.75.75 0 010 1.5zM23.072 12.04A16.418 16.418 0 0017.479 11a16.019 16.019 0 00-5.551 1.04A1.545 1.545 0 0011 13.456v3.393a7.715 7.715 0 005.19 6.8l.553.211a2.057 2.057 0 001.471 0l.553-.211c3.081-1.17 5.233-3.967 5.233-6.8v-3.393a1.545 1.545 0 00-.928-1.416zM20.5 17.25h-1.75a.5.5 0 00-.5.5v1.75a.75.75 0 01-1.5 0v-1.75a.5.5 0 00-.5-.5H14.5a.75.75 0 010-1.5h1.75a.5.5 0 00.5-.5V13.5a.75.75 0 011.5 0v1.75a.5.5 0 00.5.5h1.75a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});