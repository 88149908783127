define("ember-svg-jar/inlined/app-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-1</title><path d=\"M11.5 12.126h-1.125a.25.25 0 00-.25.25v2.25a.25.25 0 00.25.25H11.5a1.375 1.375 0 000-2.75zM5.5 12.126A1.377 1.377 0 004.125 13.5v1.125a.25.25 0 00.25.25h2.25a.25.25 0 00.25-.25V13.5A1.377 1.377 0 005.5 12.126z\"/><path d=\"M22.478 0H1.522A1.524 1.524 0 000 1.523v20.955A1.524 1.524 0 001.522 24h20.956A1.524 1.524 0 0024 22.478V1.523A1.524 1.524 0 0022.478 0zM8.125 19.5a.625.625 0 01-1.25 0v-3.124a.25.25 0 00-.25-.25h-2.25a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0v-5.9a2.7 2.7 0 012.1-2.678 2.629 2.629 0 013.15 2.578zm3.275-3.374h-1.025a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0v-8a.625.625 0 01.625-.625h2a2.629 2.629 0 012.574 3.148 2.7 2.7 0 01-2.674 2.103zm6 0h-1.025a.25.25 0 00-.25.25V19.5a.625.625 0 11-1.25 0v-8a.625.625 0 01.625-.625h2a2.629 2.629 0 012.574 3.148 2.7 2.7 0 01-2.674 2.103z\"/><path d=\"M17.5 12.126h-1.125a.25.25 0 00-.25.25v2.25a.25.25 0 00.25.25H17.5a1.375 1.375 0 000-2.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});