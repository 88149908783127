define("ember-svg-jar/inlined/design-file-max", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-max</title><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.427A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6zM12 13.125a.877.877 0 00-.875.875v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V14a.877.877 0 00-.875-.875z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.25a.25.25 0 00-.25-.25zM9.125 18.5a.625.625 0 01-1.25 0v-3.175c0-.075-.08-.062-.109 0l-.207.457a.65.65 0 01-1.118 0s-.167-.327-.2-.4-.118-.062-.118.009V18.5a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279s.807 1.606.837 1.673a.124.124 0 00.208 0l.837-1.674a.625.625 0 011.184.279zm5 0a.625.625 0 01-1.25 0v-2.125a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V18.5a.625.625 0 01-1.25 0v-4.375a2.211 2.211 0 011.9-2.238A2.127 2.127 0 0114.125 14zm4.934-.279a.625.625 0 11-1.118.558l-.876-1.753a.069.069 0 00-.129 0l-.877 1.754a.625.625 0 11-1.118-.558l1.3-2.609a.252.252 0 000-.224l-1.3-2.609a.625.625 0 111.118-.558s.851 1.7.876 1.753a.07.07 0 00.129 0l.877-1.754a.625.625 0 011.118.558l-1.3 2.609a.252.252 0 000 .224z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});