define("ember-svg-jar/inlined/architecture-fence", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>architecture-fence</title><path d=\"M4.259 2.976a1.031 1.031 0 00-1.518 0L.242 5.89A1 1 0 000 6.543v13.814a1 1 0 001 1h5a1 1 0 001-1V6.543a1 1 0 00-.241-.651zM12.759 2.976a1.031 1.031 0 00-1.518 0L8.742 5.89a1 1 0 00-.242.653v13.814a1 1 0 001 1h5a1 1 0 001-1V6.543a1 1 0 00-.241-.651zM23.759 5.892l-2.5-2.916a1.031 1.031 0 00-1.518 0l-2.5 2.915a1 1 0 00-.241.652v13.814a1 1 0 001 1h5a1 1 0 001-1V6.543a1 1 0 00-.241-.651z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});