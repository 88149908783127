define("ember-svg-jar/inlined/smart-house-phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-house-phone</title><path d=\"M6.825 16.584a.154.154 0 00-.1-.273H2.479a.414.414 0 01-.417-.411V3.16a.414.414 0 01.417-.411h9.167a.413.413 0 01.416.411V8a.251.251 0 00.367.221 3.446 3.446 0 01.934-.335.25.25 0 00.2-.245V2.749A2.44 2.44 0 0011.125.311H3A2.441 2.441 0 00.562 2.749v14.625A2.44 2.44 0 003 19.811h2.325a.248.248 0 00.183-.08.244.244 0 00.066-.189 3.5 3.5 0 011.251-2.958z\"/><path d=\"M23.438 21.811a4.881 4.881 0 00-4.876-4.875h-2.124v-5.625a2.375 2.375 0 10-4.75 0v6.715a.251.251 0 01-.443.16l-.356-.428a2.375 2.375 0 10-3.653 3.036l2.143 2.577a.875.875 0 001.346-1.119l-2.143-2.577a.625.625 0 11.961-.8L11.89 21.7a.875.875 0 001.548-.559v-9.83a.625.625 0 111.25 0v6.5a.874.874 0 00.874.875h3a3.13 3.13 0 013.126 3.125v1a.875.875 0 101.75 0zM10.98 9.33L7.463 6.708a.233.233 0 00-.3 0L3.645 9.33a.224.224 0 00-.068.253.231.231 0 00.218.15h1.044a.249.249 0 01.25.25v2.144a.342.342 0 00.342.342h.881a.249.249 0 00.25-.25v-.908a.75.75 0 111.5 0v.908a.25.25 0 00.25.25h.882a.343.343 0 00.342-.342V9.983a.249.249 0 01.25-.25h1.044a.235.235 0 00.218-.15.228.228 0 00-.068-.253z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});