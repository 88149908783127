define("ember-svg-jar/inlined/server-warning-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-warning-1</title><path d=\"M10.137 20.633a.434.434 0 00-.227-.6.251.251 0 01-.158-.233v-2.55A.25.25 0 0110 17h1.739a.5.5 0 00.442-.268l2.1-4A.5.5 0 0013.84 12H2.649a2.6 2.6 0 00-2.634 2.239A2.5 2.5 0 002.5 17H8a.25.25 0 01.25.25v2.5A.25.25 0 018 20H5a.75.75 0 100 1.5h4.4a.5.5 0 00.455-.292c.08-.178.176-.369.282-.575zM3 14.5a1.25 1.25 0 111.25 1.25A1.249 1.249 0 013 14.5zm5 1.25a1.25 1.25 0 111.25-1.25A1.25 1.25 0 018 15.75zM1 20a.75.75 0 100 1.5h1A.75.75 0 002 20zM2.5 5h13a2.5 2.5 0 000-5h-13a2.5 2.5 0 000 5zM3 2.5a1.25 1.25 0 111.25 1.25A1.249 1.249 0 013 2.5zm3.75 0A1.25 1.25 0 118 3.75 1.249 1.249 0 016.752 2.5zM17.628 9.8A2.447 2.447 0 0018 8.546 2.651 2.651 0 0015.322 6H2.649A2.6 2.6 0 00.015 8.239 2.5 2.5 0 002.5 11h12.6a.493.493 0 00.347-.15 2.923 2.923 0 011.8-.837.5.5 0 00.381-.213zM3 8.5a1.25 1.25 0 111.25 1.25A1.249 1.249 0 013 8.5zm3.75 0A1.25 1.25 0 118 9.75 1.249 1.249 0 016.752 8.5zM18.78 12.271a1.45 1.45 0 00-2.562 0l-5.055 9.634a1.429 1.429 0 00.048 1.408 1.456 1.456 0 001.233.687h10.111a1.456 1.456 0 001.233-.687 1.429 1.429 0 00.048-1.408zM16.5 21.25a1 1 0 111 1 1 1 0 01-1-1zm1.75-2.75a.75.75 0 01-1.5 0v-3a.75.75 0 111.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});