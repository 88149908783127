define("ember-svg-jar/inlined/color-picker-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-picker-2</title><path d=\"M22.781 1.219a4.776 4.776 0 00-6.718.353l-1.986 1.987a.247.247 0 01-.176.074.253.253 0 01-.177-.073l-.53-.531a1 1 0 00-1.414 1.414l.53.531a.25.25 0 010 .353L3.268 14.37a5.035 5.035 0 00-1.791 3.911L.439 19.32a1.5 1.5 0 000 2.121l2.122 2.122a1.506 1.506 0 002.121 0l1.038-1.039a5.039 5.039 0 003.911-1.79l9.043-9.043a.25.25 0 01.353 0l.531.531a1 1 0 101.414-1.414l-.531-.531a.25.25 0 010-.353l1.987-1.987a4.776 4.776 0 00.353-6.718zm-14.564 18.1c-.95.952-1.875 1.376-2.526 1.168a1 1 0 00-1.009.247l-.884.884a.249.249 0 01-.353 0l-1.062-1.06a.25.25 0 010-.354l.884-.885a1 1 0 00.247-1.007c-.208-.658.218-1.579 1.168-2.528l1.556-1.555a.249.249 0 01.353 0l1.341 1.34a.75.75 0 001.06-1.06l-1.34-1.341a.25.25 0 010-.354l.586-.585a.249.249 0 01.353 0l1.341 1.34a.75.75 0 001.06-1.06l-1.34-1.341a.25.25 0 010-.354l.586-.585a.249.249 0 01.353 0l1.341 1.34a.75.75 0 001.06-1.06l-1.34-1.341a.25.25 0 010-.354l2.072-2.072a.25.25 0 01.353 0l3.182 3.182a.249.249 0 010 .353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});