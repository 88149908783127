define("ember-svg-jar/inlined/messages-people-person-bubble-square-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-person-bubble-square-1</title><path d=\"M7.5 16A7.508 7.508 0 000 23.5a.5.5 0 00.5.5h14a.5.5 0 00.5-.5A7.508 7.508 0 007.5 16zM22 0H9a2 2 0 00-2 2v2a1 1 0 002 0V2.25A.25.25 0 019.25 2h12.5a.25.25 0 01.25.25v6.5a.25.25 0 01-.25.25H19.5a1.006 1.006 0 00-.665.252L16.416 11.4a.25.25 0 01-.416-.184V10a1 1 0 00-1-1h-1a1 1 0 000 2v3a1 1 0 001.665.748L19.88 11H22a2 2 0 002-2V2a2 2 0 00-2-2z\"/><circle cx=\"12.502\" cy=\"5.75\" r=\"1\"/><circle cx=\"15.752\" cy=\"5.75\" r=\"1\"/><circle cx=\"19.003\" cy=\"5.75\" r=\"1\"/><circle cx=\"7.5\" cy=\"10.75\" r=\"4.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});