define("ember-svg-jar/inlined/charging-battery-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>charging-battery-empty</title><path d=\"M24 10.5a2 2 0 00-2-2h-1v-1a2 2 0 00-2-2h-4.25a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25h3.75a.5.5 0 01.5.5v1.5a1 1 0 001 1h2v3h-2a1 1 0 00-1 1V16a.5.5 0 01-.5.5h-4.316a.25.25 0 00-.195.094l-1.2 1.5a.25.25 0 00.2.406H19a2 2 0 002-2v-1h1a2 2 0 002-2zM2.5 16.5A.5.5 0 012 16V8a.5.5 0 01.5-.5h5.316a.247.247 0 00.195-.094l1.2-1.5a.25.25 0 00-.2-.406H2a2 2 0 00-2 2v9a2 2 0 002 2h5.25a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25z\"/><path d=\"M15.792 11.941a.875.875 0 00-.667-1.442H13.25a.25.25 0 01-.25-.25V5a.5.5 0 00-.891-.312l-5.9 7.37a.875.875 0 00.666 1.442H8.75a.25.25 0 01.25.25V19a.5.5 0 00.334.472.507.507 0 00.557-.159z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});