define("ember-svg-jar/inlined/nautic-sports-surfing-water", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>nautic-sports-surfing-water</title><circle cx=\"19.875\" cy=\"2.625\" r=\"2.5\"/><path d=\"M13.179 13.971a.251.251 0 01.393-.126l1 .777a.5.5 0 01.19.45l-.177 1.587a1.5 1.5 0 00.957 1.567 1.5 1.5 0 002.025-1.236l.176-1.587a3.5 3.5 0 00-1.329-3.149l-.863-.671a.25.25 0 01.025-.412l1.461-.877a.252.252 0 01.291.024l4.079 3.452a1.5 1.5 0 001.938-2.29L17.6 6.617a5.5 5.5 0 00-2.358-1.17l-1.447-.322a3.485 3.485 0 01-2.232-1.608l-.4-.664A1.5 1.5 0 008.589 4.4l.4.664a6.5 6.5 0 003.29 2.729.25.25 0 01.077.422L10.887 9.5a1.5 1.5 0 00-.512 1.129v1.851a1 1 0 01-.606.92 1.431 1.431 0 00.054 2.652l.134.051a1.515 1.515 0 001.229-.073 3.388 3.388 0 001.993-2.059zM23.06 23.231a1 1 0 00-.578-1.291l-21-8a1 1 0 00-.713 1.87l.654.249a.25.25 0 01.131.353l-.347.635L.8 17.8a1.4 1.4 0 001.23 2.075 1.386 1.386 0 00.156-.009 15.212 15.212 0 003.92-.955c.335-.13.649-.263.942-.4a.984.984 0 01.764-.025l13.957 5.324a1 1 0 001.291-.579z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});