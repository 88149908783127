define("ember-svg-jar/inlined/social-music-beats-studio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-music-beats-studio</title><path d=\"M12 15a3 3 0 10-3-3 3 3 0 003 3zM12 .5a11.89 11.89 0 00-3.13.42.5.5 0 00-.37.48v5.09a.5.5 0 00.73.44A5.93 5.93 0 0112 6.25a5.75 5.75 0 010 11.5A5.54 5.54 0 016.5 12V2.94a.5.5 0 00-.78-.41A11.63 11.63 0 00.5 12 11.5 11.5 0 1012 .5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});