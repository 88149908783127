define("ember-svg-jar/inlined/famous-character-vegeta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character-vegeta</title><path d=\"M9.165 9.171l1 .5a.741.741 0 00.334.079.75.75 0 00.336-1.421l-1-.5a.75.75 0 00-.67 1.342zM13.5 9.75a.741.741 0 00.334-.079l1-.5a.75.75 0 00-.67-1.342l-1 .5A.75.75 0 0013.5 9.75zM22.5 16.5s-3.226.013-3.63.031a.254.254 0 01-.087-.012A21.164 21.164 0 0012 15.5a21.164 21.164 0 00-6.783 1.019.254.254 0 01-.087.012c-.4-.018-3.63-.031-3.63-.031a1 1 0 00-1 1v2.251a1 1 0 00.4.8l1 .749a.249.249 0 01.1.2v2a.5.5 0 00.5.5h3.769a.249.249 0 00.244-.231 2 2 0 013.974 0 .249.249 0 00.244.231h2.538a.249.249 0 00.244-.231 2 2 0 013.974 0 .249.249 0 00.244.231H21.5a.5.5 0 00.5-.5v-2a.249.249 0 01.1-.2l1-.75a1 1 0 00.4-.8V17.5a1 1 0 00-1-1zM16.609 19h-2.826a1 1 0 00-.857.486l-.712 1.185a.249.249 0 01-.428 0l-.711-1.185a1 1 0 00-.858-.486H7.391A1.89 1.89 0 015.6 17.7a.251.251 0 01.168-.311 18.434 18.434 0 013.3-.7.253.253 0 01.255.142 3 3 0 005.368 0 .253.253 0 01.255-.142 18.434 18.434 0 013.3.7.251.251 0 01.168.311 1.89 1.89 0 01-1.805 1.3z\"/><path d=\"M8.252 13.432a.179.179 0 01.066.026 6.919 6.919 0 007.364 0 .179.179 0 01.066-.026c3.274-.579 5.114-4.865 5.858-7.094a.5.5 0 00-.752-.574l-1.465.977A.25.25 0 0119 6.533V1.707a.5.5 0 00-.854-.353l-.892.892a.242.242 0 01-.358-.017 6.492 6.492 0 00-9.792 0 .242.242 0 01-.358.017l-.892-.892A.5.5 0 005 1.707v4.826a.25.25 0 01-.389.208l-1.465-.977a.5.5 0 00-.752.574c.744 2.229 2.584 6.515 5.858 7.094zm1.373-9.391a3.23 3.23 0 014.75 0 .253.253 0 01-.024.357l-2.184 1.953a.251.251 0 01-.334 0L9.649 4.4a.253.253 0 01-.024-.359zM8 6.82a.25.25 0 01.311-.242l3.479.869a.443.443 0 00.42 0l3.479-.869A.25.25 0 0116 6.82v2.6a.252.252 0 01-.042.139s-.813 1.21-.888 1.333a.479.479 0 00-.07.25v.213a.36.36 0 01-.118.3A5.01 5.01 0 0112 12.5a5.025 5.025 0 01-2.9-.852.333.333 0 01-.1-.258v-.267a.4.4 0 00-.075-.236l-.883-1.324A.252.252 0 018 9.424z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});