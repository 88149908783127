define("ember-svg-jar/inlined/zip-file-rar-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>zip-file-rar-1</title><path d=\"M23.707 5.707L18.293.293A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.75V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zM4.118 15.233l1.824 1.826a.624.624 0 11-.884.882S3.242 16.133 3.213 16.1s-.088-.037-.088.02v1.38a.625.625 0 01-1.25 0v-6a.625.625 0 01.625-.625H4a2.124 2.124 0 01.206 4.239c-.097 0-.13.078-.088.119zm7.007 2.267a.625.625 0 01-1.25 0v-2.126a.249.249 0 00-.249-.249h-1.25a.251.251 0 00-.251.251V17.5a.625.625 0 01-1.25 0V13a2.125 2.125 0 014.25 0zm2.993-2.267l1.824 1.826a.624.624 0 11-.884.882s-1.816-1.808-1.845-1.843-.088-.037-.088.02V17.5a.625.625 0 01-1.25 0v-6a.625.625 0 01.625-.625H14a2.124 2.124 0 01.206 4.239c-.097 0-.13.078-.088.119z\"/><path d=\"M14.875 13a.877.877 0 00-.875-.875h-.626a.249.249 0 00-.249.249v1.252a.249.249 0 00.249.249H14a.877.877 0 00.875-.875zM9 12.125a.877.877 0 00-.875.875v.623a.252.252 0 00.252.252h1.249a.249.249 0 00.249-.249V13A.877.877 0 009 12.125zM4.875 13A.877.877 0 004 12.125h-.626a.249.249 0 00-.249.249v1.252a.249.249 0 00.249.249H4A.877.877 0 004.875 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});