define("ember-svg-jar/inlined/dresser-wardrobe-mirror", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dresser-wardrobe-mirror</title><rect x=\"14\" y=\"6\" width=\"8\" height=\"4\" rx=\".5\" ry=\".5\"/><path d=\"M20.5 0h-6a.5.5 0 00-.5.5V4a.5.5 0 00.5.5h7A.5.5 0 0022 4V1.5A1.5 1.5 0 0020.5 0z\"/><rect x=\"14\" y=\"11.5\" width=\"8\" height=\"4\" rx=\".5\" ry=\".5\"/><path d=\"M21.5 17h-7a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h6a1.5 1.5 0 001.5-1.5v-5a.5.5 0 00-.5-.5zM19 20.25h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zM12 0H3.5A1.5 1.5 0 002 1.5v21A1.5 1.5 0 003.5 24H12a.5.5 0 00.5-.5V.5A.5.5 0 0012 0zm-1.5 21a.5.5 0 01-.5.5H4.5A.5.5 0 014 21V3a.5.5 0 01.5-.5H10a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});