define("ember-svg-jar/inlined/move-to-top", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>move-to-top</title><path d=\"M21.975 4.987a.75.75 0 00-.326-1.3l-6.539-1.45a.751.751 0 00-.911.782L14.64 9.7a.751.751 0 001.237.52l1.232-1.057a.249.249 0 01.4.106 11.326 11.326 0 01-1.988 11.149 1.5 1.5 0 102.288 1.941 14.352 14.352 0 002.033-15.3.25.25 0 01.064-.293zM7.328 17H3.2a1.436 1.436 0 00-1.435 1.435v4.13A1.437 1.437 0 003.2 24h4.13a1.437 1.437 0 001.435-1.435v-4.13A1.436 1.436 0 007.328 17zm-.565 4.75a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25zM8.763 9.934A1.436 1.436 0 007.328 8.5H3.2a1.436 1.436 0 00-1.437 1.434v4.13A1.437 1.437 0 003.2 15.5h4.13a1.437 1.437 0 001.435-1.435zm-2 3.315a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25z\"/><rect x=\"1.763\" y=\"-.001\" width=\"7\" height=\"7\" rx=\"1.435\" ry=\"1.435\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});