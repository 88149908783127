define("ember-svg-jar/inlined/image-document-bmp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-document-bmp</title><path d=\"M7 9.625h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H7a.875.875 0 000-1.75z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM8.649 12.161a2.13 2.13 0 01.412 1.867 2.174 2.174 0 01-2.137 1.6H5.5A.625.625 0 014.875 15V9a.625.625 0 01.625-.625h1.424a2.174 2.174 0 012.137 1.6 2.13 2.13 0 01-.412 1.867.248.248 0 000 .319zM14.125 15a.625.625 0 01-1.25 0v-3.064c0-.107-.108-.074-.144 0l-.172.343a.65.65 0 01-1.118 0s-.138-.26-.172-.343-.144-.094-.144 0V15a.625.625 0 01-1.25 0V9a.625.625 0 011.184-.279s.73 1.474.8 1.606.206.139.276 0l.8-1.606a.625.625 0 011.19.279zm2.75-2.375h-.5a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0V9a.625.625 0 01.625-.625H17a2.128 2.128 0 012.114 2.347 2.213 2.213 0 01-2.239 1.903z\"/><path d=\"M7 12.625h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H7a.875.875 0 000-1.75zM17 9.625h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H17a.875.875 0 000-1.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});