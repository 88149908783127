define("ember-svg-jar/inlined/e-commerce-buy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-buy</title><path d=\"M6 8.5h-.5a.25.25 0 00-.25.25v1a.25.25 0 00.25.25H6a.75.75 0 000-1.5zM6 5.5h-.5a.25.25 0 00-.25.25v1A.25.25 0 005.5 7H6a.75.75 0 000-1.5z\"/><path d=\"M14 12.756a1.99 1.99 0 011.416-.587h.084a1.988 1.988 0 01.762.151l3.51 1.445a.246.246 0 00.19 0A6.5 6.5 0 0017.5 1.25h-11a6.5 6.5 0 000 13h6.674a.251.251 0 00.25-.231A1.994 1.994 0 0114 12.756zm1.75-8.006a.75.75 0 011.5 0v1.5a1.25 1.25 0 002.5 0v-1.5a.75.75 0 011.5 0v1.5a2.753 2.753 0 01-1.833 2.592.25.25 0 00-.167.236v1.672a.75.75 0 01-1.5 0V9.078a.25.25 0 00-.167-.236A2.753 2.753 0 0115.75 6.25zm-7.5 4.5A2.253 2.253 0 016 11.5H4.5a.75.75 0 01-.75-.75v-6A.75.75 0 014.5 4H6a2.249 2.249 0 011.8 3.6.249.249 0 000 .3 2.241 2.241 0 01.45 1.35zm1.6-.047V4.7a.75.75 0 111.5 0v4.5a.75.75 0 001.5 0V4.7a.75.75 0 111.5 0v4.5a2.25 2.25 0 01-4.5 0z\"/><path d=\"M22.293 22.457a1 1 0 001.414-1.414l-2.956-2.956a.249.249 0 01-.069-.224.252.252 0 01.147-.183l1.077-.461a.5.5 0 00-.006-.919l-6.29-2.59a.5.5 0 00-.652.652l2.59 6.29a.5.5 0 00.458.31.5.5 0 00.46-.3l.461-1.077a.252.252 0 01.183-.147.249.249 0 01.224.069z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});