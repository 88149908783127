define("ember-svg-jar/inlined/laptop-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-question</title><path d=\"M9.594 15.238a2.428 2.428 0 01-1.543-1.387.492.492 0 00-.47-.36H.5a.478.478 0 00-.41.22.467.467 0 00-.05.47 5.43 5.43 0 005 3.31h4.215a.249.249 0 00.249-.242 7.839 7.839 0 01.245-1.714.25.25 0 00-.155-.297zM3.5 12a1 1 0 001-1V3a1 1 0 011-1h12a1 1 0 011 1v6.344a.249.249 0 00.211.247 7.828 7.828 0 011.454.371.251.251 0 00.335-.236V3a3.009 3.009 0 00-3-3h-12a3.009 3.009 0 00-3 3v8a1 1 0 001 1zM17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm0 11.5a1 1 0 111-1 1 1 0 01-1 1zm.912-4.163a.25.25 0 00-.162.234v.18a.75.75 0 01-1.5 0V18.2a1.257 1.257 0 01.986-1.222 1.125 1.125 0 10-1.361-1.1.75.75 0 11-1.5 0 2.625 2.625 0 113.537 2.461z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});