define("ember-svg-jar/inlined/task-list-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-list-lock</title><path d=\"M12 7.749H6a.75.75 0 000 1.5h6a.75.75 0 000-1.5zM12 11.249H6a.75.75 0 000 1.5h6a.75.75 0 000-1.5zM10 16.249a.75.75 0 000-1.5H6a.75.75 0 100 1.5z\"/><path d=\"M12.25 19H3.5a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v5.873a.247.247 0 00.448.145 4.472 4.472 0 012.369-1.6.243.243 0 00.183-.235V3.5A1.5 1.5 0 0016.5 2h-4a.241.241 0 01-.208-.121 3.827 3.827 0 00-6.588 0A.241.241 0 015.5 2h-4A1.5 1.5 0 000 3.5v17A1.5 1.5 0 001.5 22h10.75a.25.25 0 00.25-.25v-2.5a.25.25 0 00-.25-.25z\"/><path d=\"M22.611 15.511a.123.123 0 01-.111-.119v-1.143a3.5 3.5 0 00-7 0v1.134a.114.114 0 01-.113.128A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.389-1.489zM19 21.249a1 1 0 111-1 1 1 0 01-1 1zm1.5-5.866a.119.119 0 01-.106.116h-2.782a.12.12 0 01-.112-.116v-1.134a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});