define("ember-svg-jar/inlined/multiple-neutral-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-neutral-2</title><path d=\"M14 14v3.5c0 .174.044.548.049.61l.453 5.432A.5.5 0 0015 24h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 0120.19 18H22a.5.5 0 00.5-.5V14a5.491 5.491 0 00-9.431-3.834.248.248 0 00-.036.3A6.943 6.943 0 0114 14z\"/><circle cx=\"17\" cy=\"3.5\" r=\"3.5\"/><path d=\"M1.5 17.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229l.441 5.313A.5.5 0 005 24h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 0110.19 18H12a.5.5 0 00.5-.5V14a5.5 5.5 0 00-11 0z\"/><circle cx=\"7\" cy=\"3.5\" r=\"3.5\"/><path d=\"M14 14v3.5c0 .174.044.548.049.61l.453 5.432A.5.5 0 0015 24h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 0120.19 18H22a.5.5 0 00.5-.5V14a5.491 5.491 0 00-9.431-3.834.248.248 0 00-.036.3A6.943 6.943 0 0114 14z\"/><circle cx=\"17\" cy=\"3.5\" r=\"3.5\"/><path d=\"M1.5 17.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229l.441 5.313A.5.5 0 005 24h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 0110.19 18H12a.5.5 0 00.5-.5V14a5.5 5.5 0 00-11 0z\"/><circle cx=\"7\" cy=\"3.5\" r=\"3.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});