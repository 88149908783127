define("ember-svg-jar/inlined/car-dashboard-speed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-dashboard-speed</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM5.145 8.91l-.866-.5a.75.75 0 11.75-1.3l.866.5a.75.75 0 01-.376 1.4.738.738 0 01-.374-.1zm.105 3.081a.75.75 0 01-.75.75h-1a.75.75 0 010-1.5h1a.75.75 0 01.75.75zm8.99.009a2.25 2.25 0 11-2.25-2.25.633.633 0 01.076.005.25.25 0 00.233-.11l3.4-5.074a1 1 0 111.661 1.114l-3.367 5.025a.25.25 0 00-.013.256A2.214 2.214 0 0114.24 12zm-1.482-7.442a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zm-5.365-.533a.748.748 0 011.024.275l.5.866a.748.748 0 01-.274 1.024.738.738 0 01-.374.1.748.748 0 01-.65-.375l-.5-.866a.749.749 0 01.274-1.024zM7.5 17a.5.5 0 01.5-.5h8a.5.5 0 01.5.5v2a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5zm10.309-8.3a.75.75 0 01.274-1.024l.866-.5a.75.75 0 01.75 1.3l-.867.5a.75.75 0 01-1.023-.276zm.881 3.365a.75.75 0 01.75-.75h1a.75.75 0 010 1.5h-1a.75.75 0 01-.75-.749z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});