define("ember-svg-jar/inlined/vintage-man-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-man-2</title><path d=\"M12 17.5a.5.5 0 00-.488.389l-1.25 5.5a.5.5 0 00.488.611h2.5a.5.5 0 00.488-.611l-1.25-5.5A.5.5 0 0012 17.5zM7.615 16.892c-.552.1-1.054.229-1.531.363a.248.248 0 00-.164.145l-1.255 3.048a.251.251 0 00.065.282l3.608 3.207A.249.249 0 008.5 24h.487a.5.5 0 00.388-.185.493.493 0 00.1-.418L8.2 17.281a.5.5 0 00-.585-.389z\"/><path d=\"M.5 21.4v2.1a.5.5 0 00.5.5h5.246a.25.25 0 00.166-.437l-2.744-2.439a.5.5 0 01-.13-.564l.962-2.325a.25.25 0 00-.327-.326 12.2 12.2 0 00-2.7 1.508A2.463 2.463 0 00.5 21.4zM17.917 17.255c-.478-.134-.98-.259-1.532-.363a.5.5 0 00-.582.389L14.52 23.4a.493.493 0 00.1.418.5.5 0 00.388.185h.492a.249.249 0 00.166-.063l3.604-3.21a.251.251 0 00.065-.282L18.08 17.4a.252.252 0 00-.163-.145z\"/><path d=\"M23.5 21.4a2.46 2.46 0 00-.968-1.977 12.191 12.191 0 00-2.7-1.509.25.25 0 00-.327.327l.957 2.324a.5.5 0 01-.13.564l-2.744 2.439a.25.25 0 00.166.437H23a.5.5 0 00.5-.5z\"/><circle cx=\"14\" cy=\"9.731\" r=\"1\"/><circle cx=\"10\" cy=\"9.731\" r=\"1\"/><path d=\"M13.077 11.862h-2a.639.639 0 00-.3 1.2 2.451 2.451 0 00.435.18 3.024 3.024 0 00.867.126h.006a2.976 2.976 0 00.863-.125 2.433 2.433 0 00.436-.181.64.64 0 00-.3-1.2zM12 5.251a21.925 21.925 0 006-.75.25.25 0 00.171-.3l-.636-2.426v-.011a2.5 2.5 0 00-3.72-1.382l-1.285.8a1.007 1.007 0 01-1.06 0l-1.285-.8A2.5 2.5 0 006.461 1.8v.01L5.832 4.2A.25.25 0 006 4.5a21.885 21.885 0 006 .751z\"/><path d=\"M1.5 4c0 1.043.6 2.468 3.335 3.456A.25.25 0 015 7.692V10a7 7 0 0014 0V7.694a.25.25 0 01.165-.235C21.131 6.747 22.5 5.634 22.5 4a1.038 1.038 0 00-1-1 1 1 0 00-1 1c0 1.207-3.416 2.5-8.5 2.5S3.5 5.208 3.5 4a1 1 0 00-1-1 .984.984 0 00-.629.24A.983.983 0 001.5 4zM17 10a5 5 0 01-10 0V8.347a.25.25 0 01.3-.247 26.567 26.567 0 004.7.4 26.693 26.693 0 004.7-.4.25.25 0 01.3.246z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});