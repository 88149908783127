define("ember-svg-jar/inlined/road-sign-4m-wide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-4m-wide</title><path d=\"M9.608 11.818a.189.189 0 00-.211.093 1.334 1.334 0 00-.051.108.166.166 0 00.154.231.25.25 0 00.25-.25.188.188 0 00-.142-.182z\"/><path d=\"M12 .25A11.75 11.75 0 1023.75 12 11.763 11.763 0 0012 .25zM5.53 12.53l-1 1a.746.746 0 01-.53.22.75.75 0 01-.75-.75v-2a.75.75 0 011.28-.53l1 1a.749.749 0 010 1.06zm5.72 1.97a.75.75 0 01-1.5 0V14a.25.25 0 00-.25-.25h-1a.75.75 0 01-.75-.75v-.5a2.752 2.752 0 012.75-2.75.75.75 0 01.75.75zm5.5 0a.75.75 0 01-1.5 0v-1.085a.058.058 0 00-.107-.029.78.78 0 01-1.286 0 .058.058 0 00-.107.029V14.5a.75.75 0 01-1.5 0v-4a.75.75 0 011.393-.386l.643 1.071a.25.25 0 00.428 0l.643-1.071a.75.75 0 011.393.386zm3.537-.807a.757.757 0 01-.287.057.746.746 0 01-.53-.22l-1-1a.749.749 0 010-1.06l1-1a.75.75 0 011.28.53v2a.75.75 0 01-.463.693z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});