define("ember-svg-jar/inlined/shield-bug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shield-bug</title><path d=\"M24 1.953A1.96 1.96 0 0022.044.006H1.959A1.958 1.958 0 00.012 1.965L0 9.306a15.147 15.147 0 0011.861 14.669 1 1 0 00.4 0A15.145 15.145 0 0023.988 9.2zm-6.551 7.992a.5.5 0 010 .707l-.805.805a.474.474 0 000 .669 2 2 0 11-2.828 2.829.474.474 0 00-.67 0l-1 1a.5.5 0 01-.707 0l-.915-.914a.5.5 0 01-.063-.63c.515-.776 1.007-1.962.1-2.87s-2.094-.415-2.871.1a.5.5 0 01-.629-.063l-.914-.915a.5.5 0 010-.707l1-1a.474.474 0 000-.67 2 2 0 012.828-2.829.473.473 0 00.669 0l.8-.8a.5.5 0 01.707 0l.915.915a.5.5 0 01.063.629A1.965 1.965 0 0015.9 8.973a.5.5 0 01.629.063z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});