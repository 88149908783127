define("ember-svg-jar/inlined/ee-icon-question-match-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>picture-landscape</title><g fill=\"none\" fill-rule=\"evenodd\"><rect fill=\"#5E7AF9\" width=\"54\" height=\"54\" rx=\"5\"/><path fill=\"#FFF\" d=\"M7 10h12v4H7zM35 10h12v4H35zM7 20h12v4H7zM35 20h12v4H35zM7 31h12v4H7zM35 31h12v4H35zM7 42h12v4H7z\"/><path d=\"M22.5 12h9M22.5 22h9M22.5 33h9M22.5 44h9\" stroke=\"#273C9B\" stroke-width=\"2\" stroke-linecap=\"square\"/><path fill=\"#FFF\" d=\"M35 42h12v4H35z\"/></g>",
    "attrs": {
      "width": "54",
      "height": "54",
      "viewBox": "0 0 54 54",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});