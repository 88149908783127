define("ember-svg-jar/inlined/surveillance-360-camera", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>surveillance-360-camera</title><path d=\"M12 15.125a7.5 7.5 0 10-7.5-7.5 7.5 7.5 0 007.5 7.5zm0-11.5a4 4 0 11-4 4 4 4 0 014-4z\"/><path d=\"M12 6.375a1.252 1.252 0 011.25 1.25.75.75 0 001.5 0A2.752 2.752 0 0012 4.875a.75.75 0 000 1.5zM11.746 19.875q-.071 0-.138.006a.25.25 0 01-.248-.354 1.112 1.112 0 011.011-.652.75.75 0 100-1.5A2.628 2.628 0 009.746 20v1.875a2 2 0 102-2zm0 2.5a.5.5 0 11.5-.5.5.5 0 01-.5.5zM6.746 17.375a2 2 0 00-1.933 1.483.75.75 0 001.45.387.5.5 0 11.483.63.75.75 0 000 1.5.5.5 0 11-.482.635.75.75 0 10-1.445.4 2 2 0 103.487-1.787 2 2 0 00-1.56-3.25zM16.746 17.375a2 2 0 00-2 2v2.5a2 2 0 004 0v-2.5a2 2 0 00-2-2zm.5 4.5a.5.5 0 01-1 0v-2.5a.5.5 0 011 0zM3.908 16.977c-1.553-.9-2.408-2-2.408-3.1 0-1.029.763-2.075 2.148-2.945a.75.75 0 10-.8-1.27C1.012 10.814 0 12.312 0 13.875c0 1.654 1.121 3.217 3.155 4.4a.734.734 0 00.376.1.75.75 0 00.377-1.4zM23.25 16.875h-.592a4.344 4.344 0 001.342-3c0-1.566-1.015-3.065-2.858-4.22a.75.75 0 00-.8 1.272c1.389.87 2.154 1.917 2.154 2.948A3.323 3.323 0 0121 16.358v-1.233a.75.75 0 00-1.5 0v2.5a.75.75 0 00.75.75h3a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});