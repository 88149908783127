define("ember-svg-jar/inlined/video-edit-brightness-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-edit-brightness-1</title><circle cx=\"17\" cy=\"12\" r=\"7\"/><path d=\"M7.5 12a1 1 0 00-1-1H1a1 1 0 000 2h5.5a1 1 0 001-1zM8 6H3a1 1 0 000 2h5a1 1 0 000-2zM8 16H3a1 1 0 000 2h5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});