define("ember-svg-jar/inlined/crime-man-ninja", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-man-ninja</title><path d=\"M18.375 8.5v-2c0-4.8-4.329-6.5-6.5-6.5-2.2 0-6.5 1.793-6.5 6.5v2a6.5 6.5 0 0013 0zM16.5 7a.249.249 0 01.247.29c-.354 2.171-.416 3.349-2.049 3.844a9.821 9.821 0 01-2.825.366 9.821 9.821 0 01-2.825-.366C7.45 10.649 7.358 9.447 7 7.291A.251.251 0 017.248 7z\"/><circle cx=\"13.875\" cy=\"8.5\" r=\"1\"/><circle cx=\"9.875\" cy=\"8.5\" r=\"1\"/><path d=\"M21.507 16.752a.251.251 0 00.368-.221V15a.25.25 0 01.25-.25h.75a.75.75 0 000-1.5h-.75a.25.25 0 01-.25-.25v-3a1.5 1.5 0 00-3 0v3a.25.25 0 01-.25.25h-.75a.75.75 0 000 1.5h.75a.25.25 0 01.25.25v.2a.25.25 0 00.132.22zM22.408 18.417a11.994 11.994 0 00-2.45-1.4.25.25 0 00-.32.109L18.78 18.7l-2.69 4.93a.249.249 0 00.22.37h6.565a.5.5 0 00.5-.5v-3.1a2.463 2.463 0 00-.967-1.983zM7.46 15.9a14.53 14.53 0 00-6.118 2.52 2.461 2.461 0 00-.967 1.98v3.1a.5.5 0 00.5.5H8.2a.252.252 0 00.19-.087l3.066-3.56a.251.251 0 000-.33l-3.529-3.966a.5.5 0 00-.467-.157zM18.185 16.462a.252.252 0 00-.161-.145c-.244-.07-.5-.136-.76-.2a.252.252 0 00-.249.08l-.609.708-5.755 6.683a.25.25 0 00.189.412h3.191a.251.251 0 00.22-.13l3.206-5.878.717-1.314a.252.252 0 00.011-.216z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});