define("ember-svg-jar/inlined/file-pdf-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-pdf</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M56 67.232C56 68.76 54.865 70 53.464 70H2.539C1.136 70 0 68.76 0 67.232V2.766C0 1.237 1.136 0 2.539 0H42.79L56 14.64v52.592z\" fill=\"#EA4B36\"/><path d=\"M42 0v12.375c0 .032 0 .064.002.095.053 1.67 1.463 2.981 3.15 2.928H56L42 0z\" fill=\"#C33824\"/><path d=\"M13.105 56c-.549 0-1.076-.18-1.525-.52-1.642-1.247-1.862-2.634-1.758-3.578.286-2.597 3.461-5.317 9.438-8.087 2.371-5.258 4.628-11.738 5.974-17.152-1.574-3.466-3.103-7.962-1.988-10.6.39-.923 1.077-1.573 1.986-1.88.36-.121.711-.183 1.045-.183.795 0 1.506.351 2 .99.466.6 1.868 2.406-.241 11.387 2.125 4.442 5.137 8.967 8.023 12.065 2.068-.379 3.847-.57 5.296-.57 2.47 0 3.967.582 4.576 1.78.505.993.298 2.153-.615 3.446-.878 1.244-2.09 1.901-3.502 1.901-1.918 0-4.15-1.226-6.641-3.645-4.475.945-9.7 2.634-13.923 4.502-1.318 2.83-2.581 5.11-3.758 6.782C15.88 54.932 14.485 56 13.105 56zm4.197-8.178c-3.37 1.917-4.744 3.49-4.842 4.378-.016.146-.059.532.68 1.105.234-.076 1.607-.709 4.162-5.483zm21.25-7.055c1.286 1 2.422 1.547 3.262 1.547.37 0 .856-.089 1.342-.777.235-.333.327-.547.362-.661-.193-.105-.76-.319-2.163-.319-.796 0-1.742.072-2.802.21zM27.029 30.232a114.956 114.956 0 01-4.217 12.07 78.112 78.112 0 0110.244-3.224c-2.129-2.502-4.256-5.626-6.027-8.846zm-.804-13.54a.97.97 0 00-.153.04c-.154.051-.278.137-.384.388-.406.96-.086 2.783.535 4.743.515-3.377.242-4.811.002-5.171z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "56",
      "height": "70",
      "viewBox": "0 0 56 70",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});