define("ember-svg-jar/inlined/single-woman-podium", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-podium</title><path d=\"M24 16.875a1 1 0 00-1-1H1a1 1 0 000 2h1.024a.25.25 0 01.243.189l1.263 5.053a1 1 0 00.97.758h15a1 1 0 00.97-.758l1.263-5.053a.25.25 0 01.243-.189H23a1 1 0 001-1zM6.789 14.022a.5.5 0 00.35.857h10a.5.5 0 00.351-.856 7.552 7.552 0 00-5.348-2.144 7.57 7.57 0 00-5.353 2.143zM6.458 10.567a1 1 0 001.382-.3 5.118 5.118 0 00.454-.9.251.251 0 01.412-.083 4.727 4.727 0 006.786 0 .251.251 0 01.412.083 5.153 5.153 0 00.454.9 1 1 0 001.681-1.085 4.561 4.561 0 01-.639-2.508v-1.25a5.3 5.3 0 10-10.594 0v1.249a4.57 4.57 0 01-.646 2.512 1 1 0 00.298 1.382zM12.1 8.724a2.776 2.776 0 01-2.785-2.586.248.248 0 01.173-.277 5.713 5.713 0 002.439-1.5.254.254 0 01.181-.077.25.25 0 01.18.077 5.721 5.721 0 002.424 1.5.251.251 0 01.173.277A2.781 2.781 0 0112.1 8.724z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});