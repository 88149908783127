define("ember-svg-jar/inlined/messages-people-person-bubble-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-person-bubble-square</title><path d=\"M11.5 17.329l-.63-.232c-.218-.133-.252-1.32-.08-1.608a5.621 5.621 0 001.466-4.315C12.256 8.755 10.676 7 8.5 7s-3.756 1.755-3.756 4.174a5.6 5.6 0 001.452 4.3 2.209 2.209 0 01-.063 1.626l-.633.233c-2.446.9-4.213 1.549-4.751 2.626A9.135 9.135 0 000 23.5a.5.5 0 00.5.5h16a.5.5 0 00.5-.5 9.135 9.135 0 00-.749-3.545c-.538-1.077-2.305-1.726-4.751-2.626z\"/><path d=\"M22 0H9a2 2 0 00-2 2v3a1 1 0 002 0V2.25A.25.25 0 019.25 2h12.5a.25.25 0 01.25.25v6.5a.25.25 0 01-.25.25H19.5a1.006 1.006 0 00-.665.252L16.416 11.4a.25.25 0 01-.416-.184V10a1 1 0 00-1-1h-1a1 1 0 000 2v3a1 1 0 001.665.748L19.88 11H22a2 2 0 002-2V2a2 2 0 00-2-2z\"/><circle cx=\"12.502\" cy=\"5.75\" r=\"1\"/><circle cx=\"15.752\" cy=\"5.75\" r=\"1\"/><circle cx=\"19.003\" cy=\"5.75\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});