define("ember-svg-jar/inlined/password-lock-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>password-lock-1</title><path d=\"M1.956 15.417h8.609a1 1 0 000-2H2.5a.5.5 0 01-.5-.5l-.038-6a.5.5 0 01.5-.5H21.5a.5.5 0 01.5.5v3.5a1 1 0 102 0v-4a1.981 1.981 0 00-1.956-2H1.956a1.981 1.981 0 00-1.956 2v7a1.981 1.981 0 001.956 2z\"/><path d=\"M7.972 8.409a.75.75 0 00-1.038-.216l-.125.082a.25.25 0 01-.387-.209v-.149a.75.75 0 00-1.5 0v.131a.25.25 0 01-.389.208l-.109-.073a.75.75 0 10-.832 1.248L4 9.7a.25.25 0 010 .417l-.408.268a.75.75 0 10.822 1.254l.124-.081a.248.248 0 01.256-.011.251.251 0 01.131.22v.148a.75.75 0 001.5 0v-.131a.25.25 0 01.389-.208l.109.073a.75.75 0 00.829-1.249l-.406-.271a.249.249 0 010-.417l.409-.268a.749.749 0 00.217-1.035zM12.327 12.667a.75.75 0 00.75-.75v-.131a.25.25 0 01.389-.208l.109.073a.75.75 0 00.832-1.248L14 10.132a.25.25 0 010-.417l.408-.268a.75.75 0 10-.822-1.254l-.125.082a.25.25 0 01-.387-.209v-.149a.75.75 0 00-1.5 0v.131a.25.25 0 01-.389.208l-.109-.073a.75.75 0 10-.832 1.248l.407.271a.25.25 0 010 .417l-.408.268a.75.75 0 10.822 1.254l.124-.081a.25.25 0 01.387.209v.148a.75.75 0 00.751.75zM22.664 15.515a.249.249 0 01-.214-.247V14.25a3.5 3.5 0 00-7 0v1.018a.249.249 0 01-.214.247A1.5 1.5 0 0013.95 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.286-1.485zM19.95 20.25a1 1 0 11-1-1 1 1 0 011 1zm-2.5-6a1.5 1.5 0 013 0v1a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});