define("ember-svg-jar/inlined/currency-dollar-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-dollar-circle</title><path d=\"M12 0a12 12 0 1012 12A12 12 0 0012 0zm1.464 18.587a.251.251 0 00-.214.248v.765a1.25 1.25 0 01-2.5 0v-.705a.249.249 0 00-.25-.25H9.353a1.25 1.25 0 010-2.5h3.588a1.118 1.118 0 00.415-2.156l-3.64-1.457a3.605 3.605 0 01.82-6.92.25.25 0 00.214-.247V4.6a1.25 1.25 0 012.5 0v.706a.25.25 0 00.25.25h1.147a1.25 1.25 0 010 2.5h-3.588a1.117 1.117 0 00-.415 2.155l3.64 1.457a3.605 3.605 0 01-.82 6.92z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});