define("ember-svg-jar/inlined/diagram-arrow-up-left-corner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-arrow-up-left-corner</title><path d=\"M23.544 21.344a1.558 1.558 0 000-2.2L9.273 4.87a.259.259 0 010-.366l2.385-2.385a1.037 1.037 0 00-.7-1.77L1.578 0A1.514 1.514 0 000 1.575l.348 9.387a1.037 1.037 0 001.77.7L4.5 9.272a.26.26 0 01.367 0l14.276 14.272a1.558 1.558 0 002.2 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});