define("ember-svg-jar/inlined/hardware-hammer-nail-hit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hardware-hammer-nail-hit</title><path d=\"M17.809 1.261a1.951 1.951 0 00-3.645.009L12.3 6.182a.5.5 0 01-.467.323H.5a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h10.99a.5.5 0 01.5.5v2a1 1 0 001 1h6a1 1 0 001-1v-7a.5.5 0 00-.033-.18zM17.984 17h-4a1 1 0 000 2h.243a.5.5 0 01.493.417l.68 4.083a.589.589 0 001.163 0l.681-4.085a.5.5 0 01.493-.417h.243a1 1 0 000-2zM23.572 20.814l-2.257-1.07a.749.749 0 00-.642 1.354l2.257 1.071a.75.75 0 00.642-1.355zM20.81 17.781a.736.736 0 00.161-.018l2.44-.535a.749.749 0 00-.321-1.464l-2.44.535a.75.75 0 00.16 1.482zM10.428 19.744l-2.257 1.07a.75.75 0 00.642 1.355L11.07 21.1a.749.749 0 00-.642-1.354zM11.093 16.3l-2.44-.535a.749.749 0 00-.321 1.464l2.44.535a.736.736 0 00.161.018.75.75 0 00.16-1.482z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});