define("ember-svg-jar/inlined/content-pencil-write", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-pencil-write</title><path d=\"M12.5 9.5c-1.25-1.25-2-4-2.5-5.5s0-3 2-2.5 4.75 1.25 5.5 2l2.577 2.577a.25.25 0 00.427-.177V2.5A2.5 2.5 0 0018 0H6a2.5 2.5 0 00-2.5 2.5V18A2.5 2.5 0 006 20.5h7.689a.251.251 0 01.177.073l2.78 2.781A.5.5 0 0017 23.5a.489.489 0 00.191-.038A.5.5 0 0017.5 23v-2.25a.25.25 0 01.25-.25h.268a2.5 2.5 0 002.5-2.5v-.376a.252.252 0 00-.073-.176zm.521 5a.75.75 0 010 1.5H7a.75.75 0 110-1.5zm-3-4.25a.75.75 0 010 1.5H7a.75.75 0 110-1.5z\"/><path d=\"M11 22H3a1 1 0 01-1-1V5a1 1 0 00-2 0v16a3 3 0 003 3h8a1 1 0 000-2zM15.089 3.76a.5.5 0 00-.347-.355l-3.1-.886a.5.5 0 00-.618.618l.886 3.1a.5.5 0 00.355.346.483.483 0 00.125.017.5.5 0 00.354-.147l2.215-2.215a.5.5 0 00.13-.478zM21.775 10.271a.5.5 0 000-.707l-5.011-5.01a.5.5 0 00-.707 0l-3 3a.5.5 0 000 .707l5.011 5.01a.5.5 0 00.707 0zM23.187 10.977a.5.5 0 00-.606-.075.48.48 0 00-.1.077l-3 3a.56.56 0 00.047.753 2.6 2.6 0 001.854.768c.059 0 .119 0 .178-.007a2.6 2.6 0 001.675-.761 2.639 2.639 0 00-.048-3.755z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});