define("ember-svg-jar/inlined/animal-products-drumsticks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>animal-products-drumsticks</title><path d=\"M11.628 15.834l-3.44-3.441c-1.84-1.84 6.779-17.72 13.958-10.541 7.23 7.23-8.912 15.587-10.518 13.982zM7.182 20a2.5 2.5 0 00-.615-.446L9.126 17a.5.5 0 000-.707l-1.414-1.419a.5.5 0 00-.707 0l-2.559 2.559A2.5 2.5 0 004 16.818a2.327 2.327 0 00-3.359-.177A2.326 2.326 0 00.818 20a2.682 2.682 0 001.918.753.5.5 0 01.511.511A2.682 2.682 0 004 23.182a2.326 2.326 0 003.359.177A2.327 2.327 0 007.182 20z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});