define("ember-svg-jar/inlined/check-badge-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>check-badge-alternate</title><path d=\"M16.416 8a1.251 1.251 0 00-1.75.25l-3.735 4.98-2.048-2.048a1.249 1.249 0 00-1.767 1.767l2.441 2.442a2.043 2.043 0 003.04-.216l4.069-5.426A1.25 1.25 0 0016.416 8z\"/><path d=\"M23.031 9.978L21.193 8.5a.6.6 0 01-.218-.528l.255-2.345a2.593 2.593 0 00-2.859-2.859l-2.346.255a.584.584 0 01-.526-.218L14.021.967a2.594 2.594 0 00-4.042 0L8.5 2.805a.592.592 0 01-.527.219l-2.345-.255a2.592 2.592 0 00-2.859 2.858l.255 2.347a.589.589 0 01-.217.526L.968 9.978a2.594 2.594 0 000 4.042l1.838 1.48a.591.591 0 01.218.529l-.255 2.341a2.594 2.594 0 002.859 2.86l2.346-.256a.585.585 0 01.526.218l1.479 1.838a2.592 2.592 0 004.042 0l1.479-1.837a.6.6 0 01.527-.219l2.346.256a2.594 2.594 0 002.859-2.859l-.255-2.347a.589.589 0 01.217-.526l1.839-1.478a2.592 2.592 0 000-4.042zm-1.253 2.483l-1.839 1.478a2.582 2.582 0 00-.953 2.3l.256 2.347a.593.593 0 01-.17.483.585.585 0 01-.484.17l-2.347-.255a2.583 2.583 0 00-2.3.953l-1.478 1.839a.619.619 0 01-.925 0l-1.478-1.837a2.579 2.579 0 00-2.017-.969 2.505 2.505 0 00-.286.016l-2.346.255a.583.583 0 01-.483-.17.591.591 0 01-.171-.484l.256-2.344a2.583 2.583 0 00-.953-2.3l-1.839-1.482a.594.594 0 010-.925l1.839-1.477a2.582 2.582 0 00.953-2.3L4.757 5.41a.592.592 0 01.654-.653l2.347.255a2.584 2.584 0 002.3-.953l1.478-1.838a.619.619 0 01.925 0l1.477 1.839a2.58 2.58 0 002.3.953l2.346-.255a.594.594 0 01.654.654l-.256 2.347a2.588 2.588 0 00.953 2.3l1.838 1.477a.592.592 0 010 .925z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});