define("ember-svg-jar/inlined/barcode-scan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>barcode-scan</title><path d=\"M1 8a1 1 0 001-1l-.038-1.745A.249.249 0 012.212 5h1.657a1 1 0 000-2H1.956A1.981 1.981 0 000 5v2a1 1 0 001 1zM3.869 19H2.25a.25.25 0 01-.25-.25V17a1 1 0 00-2 0v2a1.981 1.981 0 001.956 2h1.913a1 1 0 000-2zM23 16a1 1 0 00-1 1l.038 1.745a.249.249 0 01-.25.255h-1.657a1 1 0 000 2h1.913A1.981 1.981 0 0024 19v-2a1 1 0 00-1-1zM22.044 3h-1.913a1 1 0 000 2h1.619a.25.25 0 01.25.25V7a1 1 0 002 0V5a1.981 1.981 0 00-1.956-2zM5 7a1 1 0 00-1 1v7.5a1 1 0 002 0V8a1 1 0 00-1-1zM8.5 7a1 1 0 00-1 1v4a1 1 0 002 0V8a1 1 0 00-1-1zM12 7a1 1 0 00-1 1v4a1 1 0 002 0V8a1 1 0 00-1-1zM15.5 7a1 1 0 00-1 1v4a1 1 0 002 0V8a1 1 0 00-1-1zM8.5 14a1 1 0 00-1 1v.5a1 1 0 002 0V15a1 1 0 00-1-1zM12 14a1 1 0 00-1 1v.5a1 1 0 002 0V15a1 1 0 00-1-1zM15.5 14a1 1 0 00-1 1v.5a1 1 0 002 0V15a1 1 0 00-1-1zM20 15.5V8a1 1 0 00-2 0v7.5a1 1 0 002 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});