define("ember-svg-jar/inlined/wild-bird-vulture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wild-bird-vulture</title><path d=\"M19.991 2.087A6.8 6.8 0 0014.92 0c-1.842 0-3.747 1.2-3.747 3.2a3.376 3.376 0 00.914 2.308 8.653 8.653 0 01-.361 1.771c-.033.1-.069.194-.1.285a3.046 3.046 0 00-3.215.1 2.114 2.114 0 00-.991 1.649 2.339 2.339 0 00.151.822c-2.485 1.29-5.687 4.63-4.14 13.2a.5.5 0 00.587.4 20.836 20.836 0 006.58-2.373l.338 1.82a.992.992 0 00.688.766.848.848 0 00.3.046h5a1 1 0 100-1.994H16.2c-1.159-4.763-1.068-4.455-1.166-4.629a9.458 9.458 0 001.168-2.217 1 1 0 00.507-.348c.747-.974 1.581-3.272-.231-4.8a11.252 11.252 0 00.663-3.278A2.372 2.372 0 0119.48 6.3a1 1 0 001.425-.731 4.151 4.151 0 00-.914-3.482zm-9.467 7.168l4.728 2.345a1.244 1.244 0 01.258 1.211c-.82-.445-6.4-3.234-6.4-3.234a1.544 1.544 0 011.414-.322zm2.227 12.751l-.346-1.864a14.049 14.049 0 001.062-.928l.68 2.792zm2.732-16.5a1 1 0 00-.308.676 10.387 10.387 0 01-.486 2.905l-1.27-.631a9.938 9.938 0 00.706-3.271 1 1 0 00-.384-.866 1.418 1.418 0 01-.568-1.119c0-.743.907-1.2 1.747-1.2a4.836 4.836 0 013.543 1.377 2.132 2.132 0 01.42.752 5.207 5.207 0 00-3.4 1.38z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});