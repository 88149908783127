define("ember-svg-jar/inlined/smiley-tongue-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-tongue-1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM9.935 9.144a1 1 0 01-1.87.712A1.207 1.207 0 007 9a1.207 1.207 0 00-1.065.856 1 1 0 01-1.87-.712A3.187 3.187 0 017 7a3.187 3.187 0 012.935 2.144zM6.258 12.53a1 1 0 011.209.715A1.9 1.9 0 009.5 14.5h5a1.921 1.921 0 002.03-1.242 1 1 0 011.94.484 3.583 3.583 0 01-.917 1.561.155.155 0 000 .215 2.494 2.494 0 01-.015 3.517 2.5 2.5 0 01-3.535 0l-2.462-2.462a.249.249 0 00-.177-.073H9.5a3.924 3.924 0 01-3.97-2.758 1 1 0 01.728-1.212zm12.1-2.095a1 1 0 01-1.291-.579A1.207 1.207 0 0016 9a1.207 1.207 0 00-1.065.856 1 1 0 01-1.87-.712 3.081 3.081 0 015.87 0 1 1 0 01-.579 1.291z\"/><path d=\"M16.375 18.312a.75.75 0 00.721-.956 4.932 4.932 0 00-.659-1.06.25.25 0 00-.286-.081 4.491 4.491 0 01-1.336.276.118.118 0 00-.1.066.116.116 0 00.009.118.7.7 0 00.278.239 6.081 6.081 0 01.65.855.749.749 0 00.723.543z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});