define("ember-svg-jar/inlined/controls-camera-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>controls-camera-off</title><path d=\"M3.165 6.432a.251.251 0 00-.279-.051A1.5 1.5 0 002 7.75v9a1.5 1.5 0 001.5 1.5h10.879a.25.25 0 00.177-.427zM21.737 7.451l-3 1.711a1.447 1.447 0 00-.737 1.25v3.676a1.449 1.449 0 00.737 1.25l3 1.711A1.5 1.5 0 0024 15.8V8.7a1.5 1.5 0 00-2.263-1.249z\"/><path d=\"M17.073 15.659a.249.249 0 01-.073-.177V7.75a1.5 1.5 0 00-1.5-1.5H7.768a.249.249 0 01-.177-.073L1.707.293A1 1 0 00.44.172a.984.984 0 00-.147.121 1 1 0 000 1.414l22 22a1 1 0 001.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});