define("ember-svg-jar/inlined/allowances-no-bicycles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>allowances-no-bicycles</title><path d=\"M3.25 13.812a3.744 3.744 0 007.35 1.026.248.248 0 00-.064-.245l-.927-.928a.208.208 0 00-.355.147A2.25 2.25 0 117 11.562a.207.207 0 00.147-.354l-.927-.928a.252.252 0 00-.246-.063 3.746 3.746 0 00-2.724 3.595z\"/><path d=\"M24 12a12 12 0 10-12 12 12.013 12.013 0 0012-12zm-2 0a9.95 9.95 0 01-2.5 6.589l-1.226-1.226a3.724 3.724 0 00-1.653-7.25L15.674 7.4l.7-.173a.75.75 0 00-.363-1.456l-1.1.275a1.125 1.125 0 00-.783 1.469l.1.293-4.1 1.409-.964-.967H10.5a.75.75 0 000-1.5H7.664L5.411 4.5A9.984 9.984 0 0122 12zm-8.831.255l1.778-2.391.26.743a3.746 3.746 0 00-1.632 2.054zM11.312 10.4l1.84-.633-1.052 1.417zm3.563 3.414a2.236 2.236 0 01.845-1.742l.7 1.991a.75.75 0 00.708.5.767.767 0 00.248-.041.751.751 0 00.46-.957l-.7-2a2.25 2.25 0 01-.007 4.5c-.057 0-.11-.012-.165-.016l-2.068-2.068c-.008-.059-.021-.112-.021-.169zM12 22A10.011 10.011 0 012 12a9.845 9.845 0 012.048-6.038l13.99 13.99A9.823 9.823 0 0112 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});