define("ember-svg-jar/inlined/road-sign-speed-limit-30-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-speed-limit-30-alternate</title><path d=\"M16.024 7.978a3.48 3.48 0 00-3.476 3.476v2.476a3.476 3.476 0 106.952 0v-2.476a3.48 3.48 0 00-3.476-3.476zM17.5 13.93a1.476 1.476 0 01-2.952 0v-2.476a1.476 1.476 0 112.952 0zM11.452 10.835A2.861 2.861 0 008.6 7.978H5.5a1 1 0 000 2h3.1a.857.857 0 110 1.714H6.738a1 1 0 100 2H8.6a.857.857 0 010 1.714H5.5a1 1 0 000 2h3.1a2.856 2.856 0 002.29-4.564.248.248 0 010-.3 2.848 2.848 0 00.562-1.707z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});