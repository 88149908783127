define("ember-svg-jar/inlined/timer-0", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>timer-0</title><path d=\"M13.25 1.25A10.769 10.769 0 002.727 9.8a.249.249 0 01-.244.2H.5a.5.5 0 00-.353.854L3.3 14.005a.5.5 0 00.707 0l3.152-3.151A.5.5 0 006.8 10H5.053a.249.249 0 01-.241-.316A8.75 8.75 0 1113.25 20.75a1 1 0 000 2 10.75 10.75 0 000-21.5z\"/><path d=\"M16.75 13.5v-3a3.5 3.5 0 00-7 0v3a3.5 3.5 0 007 0zm-5 0v-3a1.5 1.5 0 013 0v3a1.5 1.5 0 01-3 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});