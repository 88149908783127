define("ember-svg-jar/inlined/famous-character-star-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character-star-badge</title><path d=\"M23.372 11.616a.5.5 0 00.5-.436 14.275 14.275 0 00.12-1.866L24 2.063A1.958 1.958 0 0022.044.116H1.959A1.958 1.958 0 00.013 2.075L0 9.416a13.994 13.994 0 00.126 1.766.5.5 0 00.5.434zM8.149 5.972a.49.49 0 01.343-.856h1.828a.25.25 0 00.234-.161l.968-2.555a.531.531 0 01.943 0l.966 2.552a.25.25 0 00.234.161h1.827a.49.49 0 01.346.856L14.27 7.065a.248.248 0 00-.086.3l.88 2.022a.523.523 0 01-.746.654l-2.2-1.238a.249.249 0 00-.245 0l-2.2 1.238a.523.523 0 01-.746-.654L9.8 7.369a.25.25 0 00-.086-.3zM10.934 23.852a.253.253 0 00.218-.043.25.25 0 00.1-.2v-9.993a.5.5 0 00-.5-.5H8a.5.5 0 00-.5.5v8.5a.5.5 0 00.256.436 14.024 14.024 0 003.178 1.3zM16.5 13.616a.5.5 0 00-.5-.5h-2.75a.5.5 0 00-.5.5v10.018a.251.251 0 00.316.241 14.022 14.022 0 003.179-1.306.5.5 0 00.255-.436zM18.5 13.116a.5.5 0 00-.5.5V20.9a.249.249 0 00.144.226.246.246 0 00.265-.033 15.481 15.481 0 004.909-7.332.5.5 0 00-.477-.648zM6 13.616a.5.5 0 00-.5-.5H1.16a.5.5 0 00-.477.649 15.483 15.483 0 004.908 7.306.25.25 0 00.409-.193z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});