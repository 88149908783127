define("ember-svg-jar/inlined/beacon-remote-vehicle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beacon-remote-vehicle</title><path d=\"M24 12.836a4 4 0 00-4-4h-1.75a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25H20a1.993 1.993 0 011.8 1.142.25.25 0 01-.226.358h-1.683a1 1 0 00-.971 1.242l.75 3a1 1 0 00.971.758h1.109a.25.25 0 01.25.25v3a.25.25 0 01-.25.25h-1.218a.251.251 0 01-.229-.15 2.236 2.236 0 00-4.106 0 .251.251 0 01-.229.15H8.032a.251.251 0 01-.229-.15 2.236 2.236 0 00-4.106 0 .251.251 0 01-.229.15H2.25a.25.25 0 01-.25-.25v-7.75a2 2 0 011.8-1.991.218.218 0 00.2-.217V9.094a.25.25 0 00-.267-.25A4.006 4.006 0 000 12.836v8a2 2 0 002 2h1.751a.251.251 0 01.2.1 2.248 2.248 0 003.6 0 .251.251 0 01.2-.1h8.5a.251.251 0 01.2.1 2.248 2.248 0 003.6 0 .251.251 0 01.2-.1H22a2 2 0 002-2zM7.465 3.629a5 5 0 017.07 0 1 1 0 101.414-1.414 7 7 0 00-9.9 0 1 1 0 001.416 1.414z\"/><path d=\"M16.5 7.336a1.5 1.5 0 00-1.5-1.5H7a1.5 1.5 0 00-1.5 1.5v8a1.5 1.5 0 001.5 1.5h8a1.5 1.5 0 001.5-1.5v-8zm-4.659 5.772a.248.248 0 00-.091.193v1.285a.75.75 0 01-1.5 0v-1.277a.249.249 0 00-.091-.193 1.277 1.277 0 01-.492-.989 1.333 1.333 0 112.666 0 1.267 1.267 0 01-.492.981zm2.409-.772a.75.75 0 01-.75-.75 2.5 2.5 0 10-5 0 .75.75 0 01-1.5 0 4 4 0 118 0 .75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});