define("ember-svg-jar/inlined/baggage-check-in-plane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baggage-check-in-plane</title><path d=\"M23.875 2.855a1.351 1.351 0 00-.08-.461 1.432 1.432 0 00-1.032-.926 1.357 1.357 0 00-1.072.206L19.905 2.89a.252.252 0 01-.229.028L15.6 1.4a.536.536 0 00-.5.071l-.769.572a.893.893 0 00-.084.079.543.543 0 00.091.776l2.486 1.54a.249.249 0 01.012.417l-.766.535a.249.249 0 01-.27.01l-1.072-.635a.485.485 0 00-.468.087l-1.032.91a1.118 1.118 0 00-.077.077.473.473 0 00-.1.351.487.487 0 00.19.333l2.477 1.739a.663.663 0 00.735.021l6.763-4.2a1.43 1.43 0 00.659-1.228zM16.875 16.634h-1.75a1 1 0 000 2h1.75a4.742 4.742 0 004.707-4.276.25.25 0 01.249-.224h.794a.5.5 0 00.353-.854l-2-2a.5.5 0 00-.707 0l-2 2a.5.5 0 00.354.854h.685a.25.25 0 01.245.3 2.741 2.741 0 01-2.68 2.2zM1.875 22.634a.25.25 0 00.25-.25v-8.5a.25.25 0 00-.25-.25h-.25a1.5 1.5 0 00-1.5 1.5v6a1.5 1.5 0 001.5 1.5zM3.125 22.384a.25.25 0 00.25.25h6.5a.25.25 0 00.25-.25v-9.25a3 3 0 00-3-3h-1a3 3 0 00-3 3zm2-9.25a1 1 0 011-1h1a1 1 0 011 1v.25a.25.25 0 01-.25.25h-2.75zM11.625 22.634a1.5 1.5 0 001.5-1.5v-6a1.5 1.5 0 00-1.5-1.5h-.25a.25.25 0 00-.25.25v8.5a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});