define("ember-svg-jar/inlined/library-hidden", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>library-hidden</title><path d=\"M14.667 11.105a10.31 10.31 0 01-4.732-3.618A3.482 3.482 0 019.421 4.4a.5.5 0 00-.478-.649H1.5a1.5 1.5 0 00-1.5 1.5v16a1.5 1.5 0 001.5 1.5h12a1.5 1.5 0 001.5-1.5v-9.674a.5.5 0 00-.333-.472zM5 20.751a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5zm4.828.276L8.4 21.5a.5.5 0 01-.631-.319l-1.247-3.8a.5.5 0 01.319-.631l1.426-.468a.5.5 0 01.631.32l1.246 3.8a.5.5 0 01-.316.625zM13 14H2a.75.75 0 010-1.5h.5A.5.5 0 003 12V7.251a.5.5 0 01.5-.5H5a.5.5 0 01.5.5V12a.5.5 0 00.5.5h.5A.5.5 0 007 12V9.251a.5.5 0 01.5-.5H9a.5.5 0 01.5.5V12a.5.5 0 00.5.5h3a.75.75 0 010 1.5zM23.712 4.551a7.687 7.687 0 00-6.079-3.3 7.686 7.686 0 00-6.079 3.3 1.488 1.488 0 000 1.762 7.684 7.684 0 006.079 3.3 7.685 7.685 0 006.078-3.3 1.488 1.488 0 00.001-1.762zM14.551 6.54a6.587 6.587 0 01-.853-.768.5.5 0 010-.682 5.345 5.345 0 014.073-1.837.251.251 0 01.172.425l-2.734 2.811a.5.5 0 01-.658.051zm7.016-.767a5.344 5.344 0 01-4.073 1.836.25.25 0 01-.172-.424l2.733-2.812a.5.5 0 01.659-.052 6.663 6.663 0 01.853.769.5.5 0 010 .683z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});