define("ember-svg-jar/inlined/dating-rose-vase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dating-rose-vase</title><path d=\"M14.88 14.65a2.318 2.318 0 01-1.412-1.739.5.5 0 00-.492-.411H9.887a.5.5 0 00-.492.411 2.322 2.322 0 01-1.412 1.739c-2.121.859-3.552 2.484-3.552 4.35 0 2.761 3.134 5 7 5s7-2.239 7-5c0-1.866-1.431-3.491-3.551-4.35zM17.2.612A5.368 5.368 0 0014.885 0h-.006a1.767 1.767 0 00-1.7.829c-.512 1.02.447 2.463 2.23 3.359a5.3 5.3 0 002.312.613 1.791 1.791 0 001.714-.83c.508-1.02-.451-2.463-2.235-3.359zM10.29 10.408a.443.443 0 01.343.508.5.5 0 00.493.584h1.011a.5.5 0 00.491-.409 5.779 5.779 0 01.951-2.428.5.5 0 01.524-.187 3.451 3.451 0 00.851.106 3.505 3.505 0 003.131-1.931l.273-.542a.25.25 0 00-.249-.36 5.926 5.926 0 01-3.151-.668 5.852 5.852 0 01-2.416-2.134.25.25 0 00-.438.017l-.274.547a3.5 3.5 0 00.156 3.418.5.5 0 01-.021.559q-.156.211-.3.456a.249.249 0 01-.208.122.252.252 0 01-.214-.11 4.15 4.15 0 00-3.757-1.822.5.5 0 00-.461.536 4.149 4.149 0 003.265 3.738z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});