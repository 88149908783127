define("ember-svg-jar/inlined/car-repair-bottom-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-repair-bottom-1</title><path d=\"M23 22h-.25a.25.25 0 01-.25-.25V5a1 1 0 00-2 0v5.75a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25V9.729a.249.249 0 01.208-.246A1.253 1.253 0 0019.25 8.25V6.707a1.284 1.284 0 00-.406-.921l-1.226-1.061a.5.5 0 01-.156-.248l-.9-3.352A1.5 1.5 0 0015.109 0H8.891a1.494 1.494 0 00-1.452 1.12l-.9 3.358a.5.5 0 01-.156.248l-1.266 1.1a1.258 1.258 0 00-.366.884v1.54a1.253 1.253 0 001.041 1.233.249.249 0 01.208.246v1.021a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25V5a1 1 0 00-2 0v16.75a.25.25 0 01-.25.25H1a1 1 0 000 2h3a1 1 0 000-2h-.25a.25.25 0 01-.25-.25v-8.5a.25.25 0 01.25-.25h16.5a.25.25 0 01.25.25v8.5a.25.25 0 01-.25.25H20a1 1 0 000 2h3a1 1 0 000-2zm-8-11.25a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25zm.068-7.1a.248.248 0 01-.2.1H9.13a.25.25 0 01-.242-.315l.336-1.25A.251.251 0 019.466 2h5.068a.251.251 0 01.242.185l.335 1.25a.252.252 0 01-.043.217z\"/><path d=\"M15.758 17.3a.893.893 0 00-.85-1.479l-.981.228a.664.664 0 01-.783-.454l-.294-.969a.887.887 0 00-1.7 0l-.295.969a.664.664 0 01-.783.454l-.981-.228a.893.893 0 00-.85 1.479l.687.741a.669.669 0 010 .908l-.687.741a.892.892 0 00.85 1.478l.981-.227a.662.662 0 01.783.454l.295.968a.887.887 0 001.7 0l.294-.968a.662.662 0 01.783-.454l.981.227a.892.892 0 00.85-1.478l-.687-.741a.669.669 0 010-.908zM12 19.742a1.25 1.25 0 111.25-1.25 1.25 1.25 0 01-1.25 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});