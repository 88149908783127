define("ember-svg-jar/inlined/smiley-lol-sideways", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-lol-sideways</title><path d=\"M24 4.5a4 4 0 00-4-4 6.07 6.07 0 00-2.459.744.252.252 0 01-.221 0A12.005 12.005 0 001.5 17.818a.249.249 0 01.01.221A5.223 5.223 0 001 20a4 4 0 006.387 3.2.253.253 0 01.241-.032 12.034 12.034 0 0012.857-2.69A12.025 12.025 0 0023.048 7.3a.25.25 0 01.037-.257A3.978 3.978 0 0024 4.5zm-4-2a2 2 0 010 4c-.883 0-2.912-1.279-3.689-1.791a.251.251 0 010-.418C17.089 3.776 19.117 2.5 20 2.5zm-2.947 14.55a6.109 6.109 0 01-4.349 1.8 6.2 6.2 0 01-1.593-.209.153.153 0 01-.106-.107.147.147 0 01.039-.143l7.35-7.35a.142.142 0 01.148-.034.147.147 0 01.1.1 6.176 6.176 0 01-1.589 5.943zm-1.625-9.874L12.6 9.3a1 1 0 01-.6.2 1 1 0 01-1-1V4.255a1 1 0 112 0V6a.25.25 0 00.4.2l.829-.622a1 1 0 011.2 1.6zm-9.509 6.74l.356-.5a.251.251 0 00-.2-.4H4.258a1 1 0 010-2H8.5a1 1 0 01.813 1.584l-1.767 2.478a1 1 0 01-1.627-1.162zM5 22a2 2 0 01-2-2c0-.883 1.279-2.911 1.791-3.689a.251.251 0 01.418 0C5.721 17.085 7 19.114 7 20a2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});