define("ember-svg-jar/inlined/phone-action-cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-cash</title><path d=\"M13.4 10.783l-2.307-.923a.5.5 0 01.186-.963h2.272a1 1 0 000-2h-.676V6.5a1 1 0 00-2 0v.438a2.494 2.494 0 00-.524 4.779l2.306.923a.5.5 0 01-.186.963H10.2a1 1 0 000 2h.676V16a1 1 0 002 0v-.438a2.494 2.494 0 00.524-4.779z\"/><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});