define("ember-svg-jar/inlined/database-disable-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-disable-alternate</title><path d=\"M11.276 0C6.242 0 .893 1.736.882 4.949v12.62c0 2.611 3.524 4.246 7.545 4.77a.98.98 0 001.039-1.359l-.017-.035a1.016 1.016 0 00-.788-.585c-3.594-.46-5.781-1.807-5.781-2.791v-.759a.249.249 0 01.372-.218A13.881 13.881 0 007.556 18a.984.984 0 001.152-.907v-.028a.974.974 0 00-.8-1.023C4.764 15.5 2.88 14.278 2.88 13.365v-.758a.249.249 0 01.372-.218 15.269 15.269 0 005.607 1.594.973.973 0 00.943-.46l.012-.02a.982.982 0 00-.7-1.5C5.25 11.6 2.88 10.187 2.88 9.162V8.4A.251.251 0 013 8.187a.254.254 0 01.249 0 17.414 17.414 0 008.024 1.73A17.422 17.422 0 0019.3 8.184a.25.25 0 01.372.215 5.76 5.76 0 01-.021.872 1.012 1.012 0 00.522 1.1l.094.046a.969.969 0 001.4-.87V4.959C21.672 1.74 16.316 0 11.276 0zm8.4 4.957v.006c-.011 1.2-3.278 2.95-8.395 2.95s-8.4-1.749-8.4-2.954S6.151 2 11.276 2s8.39 1.749 8.4 2.954z\"/><path d=\"M16.62 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm0 11a4.423 4.423 0 01-2.1-.533.25.25 0 01-.13-.183.253.253 0 01.071-.214l5.727-5.726a.25.25 0 01.214-.071.255.255 0 01.184.129 4.434 4.434 0 01.531 2.1A4.5 4.5 0 0116.62 22zm0-9a4.427 4.427 0 012.1.532.25.25 0 01.058.4l-5.728 5.722a.25.25 0 01-.4-.059 4.434 4.434 0 01-.532-2.1A4.5 4.5 0 0116.62 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});