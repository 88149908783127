define("ember-svg-jar/inlined/plane-trip-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-trip-time</title><path d=\"M18.25 17.5h-.374a.251.251 0 01-.193-.091A3.859 3.859 0 0014.7 16H4a.46.46 0 01-.447-.349L3.2 14.229A1.622 1.622 0 001.623 13H1a1 1 0 00-1 1v3.5a2.264 2.264 0 001.9 2.24l3.3.55a.25.25 0 01.164.39L3.59 23.213A.5.5 0 004 24h1.9a3.345 3.345 0 002.255-.872l2.27-2.063A.249.249 0 0110.59 21h7.66a1.75 1.75 0 000-3.5zM17.5 0A6.5 6.5 0 1024 6.5 6.508 6.508 0 0017.5 0zm0 11A4.5 4.5 0 1122 6.5a4.505 4.505 0 01-4.5 4.5z\"/><path d=\"M19.5 5.5h-.75a.25.25 0 01-.25-.25V4.5a1 1 0 00-2 0v2a1 1 0 001 1h2a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});