define("ember-svg-jar/inlined/single-woman-actions-player.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-player</title><path d=\"M10.9 10.984a.251.251 0 00.065-.206.6.6 0 01-.007-.085v-.467a.25.25 0 01.25-.25A6.374 6.374 0 0015 9.081a.388.388 0 00.084-.593c-.494-.537-.835-1.185-.981-3.317C13.838 1.346 11.236 0 9 0S4.161 1.346 3.9 5.171c-.146 2.132-.487 2.78-.981 3.317A.388.388 0 003 9.081a6.348 6.348 0 003.782.9.249.249 0 01.253.25v.462a.778.778 0 01-.512.73c-2.9 1.065-5.212 1.825-5.764 2.922A7.822 7.822 0 000 17.076a.386.386 0 00.1.3.4.4 0 00.29.126h9.86a.25.25 0 00.25-.25V12a1.483 1.483 0 01.4-1.016z\"/><path d=\"M22.125 12h-8.25A1.877 1.877 0 0012 13.875v8.25A1.877 1.877 0 0013.875 24h8.25A1.877 1.877 0 0024 22.125v-8.25A1.877 1.877 0 0022.125 12zM15.5 16.18a.931.931 0 011.408-.8l3.033 1.82a.928.928 0 010 1.595l-3.033 1.819a.917.917 0 01-.477.133.931.931 0 01-.931-.93z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});