define("ember-svg-jar/inlined/upload-thick-bottom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>upload-thick-bottom</title><path d=\"M8.616 17.273a1.455 1.455 0 001.454 1.454h3.392a1.455 1.455 0 001.453-1.454V9.045a.25.25 0 01.25-.25h2.9a1.138 1.138 0 00.827-2L12.794.7a1.489 1.489 0 00-2.056 0L4.64 6.8a1.137 1.137 0 00.827 2h2.9a.249.249 0 01.25.25z\"/><path d=\"M0 19.677a4.039 4.039 0 004.035 4.035h15.93A4.039 4.039 0 0024 19.677V17.8a1.225 1.225 0 00-2.449 0v1.874a1.588 1.588 0 01-1.586 1.586H4.035a1.588 1.588 0 01-1.586-1.586V17.8A1.225 1.225 0 000 17.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});