define("ember-svg-jar/inlined/real-estate-favorite-heart-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-favorite-heart-house</title><path d=\"M22.152 11.759a6.161 6.161 0 001.181-7.187 6.339 6.339 0 00-10.069-1.631l-.927.844a.5.5 0 01-.673 0l-.912-.831a6.276 6.276 0 00-5.476-1.736A6.226 6.226 0 00.669 4.572a6.145 6.145 0 001.156 7.16l9.424 10.8a1 1 0 001.5 0zm-4.651.373h-1a.5.5 0 00-.5.5v3a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1.491a1 1 0 00-2 0v1.491a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-3a.5.5 0 00-.5-.5h-1a.5.5 0 01-.322-.882l5.164-4.361a.989.989 0 011.322 0l5.158 4.356a.5.5 0 01-.323.887z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});