define("ember-svg-jar/inlined/navigation-left-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-left-2</title><path d=\"M11.751 23.739a1.44 1.44 0 00.36-2l-6.583-9.467a.48.48 0 010-.549l6.583-9.463A1.44 1.44 0 009.746.616l-6.582 9.463a3.372 3.372 0 000 3.837l6.582 9.463a1.44 1.44 0 002.005.36z\"/><path d=\"M16.473.89l-6.584 9.463a2.892 2.892 0 000 3.289l6.584 9.464a.961.961 0 00.788.411h3.213a.96.96 0 00.788-1.508l-6.583-9.463a.965.965 0 010-1.1l6.583-9.464a.96.96 0 00-.788-1.504h-3.213a.96.96 0 00-.788.412z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});