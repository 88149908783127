define("ember-svg-jar/inlined/single-woman-actions-key.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-key</title><path d=\"M9.7 17.5a.249.249 0 00.232-.159 5.574 5.574 0 01.506-1 5.984 5.984 0 015.2-2.738.249.249 0 00.122-.471 41.741 41.741 0 00-4.284-1.7.779.779 0 01-.513-.729v-.468a.251.251 0 01.249-.25 6.374 6.374 0 003.786-.9.387.387 0 00.181-.276.383.383 0 00-.1-.316c-.493-.537-.835-1.185-.98-3.316C13.833 1.349 11.232 0 9 0S4.16 1.349 3.9 5.173c-.147 2.127-.488 2.779-.982 3.316A.387.387 0 003 9.081a6.351 6.351 0 003.78.9.249.249 0 01.254.249v.463a.778.778 0 01-.512.729c-2.9 1.064-5.21 1.824-5.763 2.921A7.829 7.829 0 000 17.074a.383.383 0 00.1.3.392.392 0 00.29.127z\"/><path d=\"M24 11.63a.5.5 0 00-.5-.5h-1.978a.5.5 0 00-.343.137l-4.187 3.955a.249.249 0 01-.244.058 4.454 4.454 0 10-1.792 8.687 4.385 4.385 0 00.51.029 4.451 4.451 0 004.258-5.729.252.252 0 01.063-.249l4.067-4.056a.505.505 0 00.146-.354zm-9.617 6.532a1.736 1.736 0 012.456 2.454 1.736 1.736 0 01-2.456-2.455z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});