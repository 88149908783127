define("ember-svg-jar/inlined/family-outdoors-swing-tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-outdoors-swing-tree</title><path d=\"M21.17 2.462a1 1 0 01-.455-.615A3.568 3.568 0 0019.9.332a1 1 0 10-1.488 1.336 1.481 1.481 0 01.4.982 1 1 0 00.258.67l.388.431a1 1 0 00.328.24 2.545 2.545 0 011.63 2.224 2.619 2.619 0 01-1.9 2.336 1.035 1.035 0 00-.661.73 3.391 3.391 0 01-3.567 2.789A3.435 3.435 0 0114 11.843a.776.776 0 00-.084-.034 4.8 4.8 0 01-.847-.486.942.942 0 00-1.079.026 3.674 3.674 0 01-.724.382.5.5 0 01-.685-.465V5.07a1 1 0 00-2 0v1.723a.251.251 0 01-.429.175 1.993 1.993 0 01-.571-1.4 1 1 0 00-2 0 4.006 4.006 0 002.666 3.774.5.5 0 01.334.471V11.5a.5.5 0 01-.591.492A3.576 3.576 0 016.052 11a2.627 2.627 0 01-.8-1.435 1 1 0 00-.675-1 2.647 2.647 0 01-2-2.346A2.645 2.645 0 014.548 3.88a.973.973 0 00.642-.924 1.9 1.9 0 01.462-1.289A1 1 0 004.162.333a3.727 3.727 0 00-.825 1.527 1 1 0 01-.458.6 4.4 4.4 0 00-2.3 3.756A4.416 4.416 0 003 10.042a1 1 0 01.457.537 5.014 5.014 0 001.084 1.734 5.651 5.651 0 003.593 1.715.5.5 0 01.446.5V23a1 1 0 002 0v-8.606a.5.5 0 01.391-.488 6.58 6.58 0 001.4-.477.129.129 0 01.126.007.176.176 0 01.083.149v4.622a.532.532 0 01-.451.493 1 1 0 00-.051 1.948 18.1 18.1 0 009.045 0 1 1 0 00-.112-1.956.466.466 0 01-.431-.446v-7.538a.69.69 0 01.363-.609 4.453 4.453 0 002.475-3.884 4.411 4.411 0 00-2.248-3.753zm-6.139 16.684a.5.5 0 01-.45-.5v-4.077a.5.5 0 01.517-.5 6.166 6.166 0 002.773-.535.5.5 0 01.71.455v4.663a.5.5 0 01-.453.5 16.184 16.184 0 01-3.097-.006z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});