define("ember-svg-jar/inlined/material-isolation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>material-isolation</title><path d=\"M22.5 3H10a.5.5 0 00-.5.5V20a5.463 5.463 0 01-1.34 3.586.25.25 0 00.189.414H22.5a1.5 1.5 0 001.5-1.5v-18A1.5 1.5 0 0022.5 3zm-10 18a1 1 0 111-1 1 1 0 01-1 1zm0-4.5a1 1 0 111-1 1 1 0 01-1 1zm0-4.5a1 1 0 111-1 1 1 0 01-1 1zm0-4.5a1 1 0 111-1 1 1 0 01-1 1zm4 13.5a1 1 0 111-1 1 1 0 01-1 1zm0-4.5a1 1 0 111-1 1 1 0 01-1 1zm0-4.5a1 1 0 111-1 1 1 0 01-1 1zm0-4.5a1 1 0 111-1 1 1 0 01-1 1zm4 13.5a1 1 0 111-1 1 1 0 01-1 1zm0-4.5a1 1 0 111-1 1 1 0 01-1 1zm0-4.5a1 1 0 111-1 1 1 0 01-1 1zm0-4.5a1 1 0 111-1 1 1 0 01-1 1zM4 0a4 4 0 00-4 4v16a4 4 0 008 0V4a4 4 0 00-4-4zm0 22.5A2.5 2.5 0 116.5 20 2.5 2.5 0 014 22.5z\"/><circle cx=\"4\" cy=\"20\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});