define("ember-svg-jar/inlined/cash-user-woman-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-user-woman-message</title><path d=\"M22.5 0h-10A1.5 1.5 0 0011 1.5v8a1.5 1.5 0 001.5 1.5h.25a.25.25 0 01.25.25v2.25a.5.5 0 00.291.454.493.493 0 00.209.046.5.5 0 00.325-.12l3.289-2.819a.246.246 0 01.163-.061H22.5A1.5 1.5 0 0024 9.5v-8A1.5 1.5 0 0022.5 0zm-6.167 4.875h2.334a1.792 1.792 0 010 3.584h-.292a.249.249 0 00-.25.25V9a.625.625 0 01-1.25 0v-.291a.249.249 0 00-.25-.25h-1.458a.625.625 0 110-1.25h3.5a.542.542 0 000-1.084h-2.334a1.792 1.792 0 010-3.583h.292a.25.25 0 00.25-.25V2a.625.625 0 011.25 0v.292a.25.25 0 00.25.25h1.458a.625.625 0 010 1.25h-3.5a.542.542 0 000 1.083zM.5 20.5h1.849a.251.251 0 01.248.215l.408 2.856A.5.5 0 003.5 24h4a.5.5 0 00.5-.429l.4-2.856a.25.25 0 01.25-.215h1.85a.5.5 0 00.477-.65L9.3 14.561a3.968 3.968 0 00-1.937-2.569.251.251 0 00-.338.1l-1.347 2.369a.25.25 0 01-.434 0l-1.351-2.378a.252.252 0 00-.338-.1 3.932 3.932 0 00-1.934 2.544l-1.6 5.325A.5.5 0 00.1 20.3a.5.5 0 00.4.2z\"/><path d=\"M1.188 7.5a4.25 4.25 0 104.25-4.25 4.252 4.252 0 00-4.25 4.25zm6.237.346a.249.249 0 01.165.311 2.249 2.249 0 01-4.3 0 .249.249 0 01.165-.311A4.348 4.348 0 005.26 6.74a.25.25 0 01.357 0 4.348 4.348 0 001.808 1.106z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});