define("ember-svg-jar/inlined/medical-nanobot-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-nanobot-heart</title><path d=\"M10.83 11.9a3.744 3.744 0 00-3.277 1.058L7 13.511l-.551-.553A3.724 3.724 0 003.174 11.9 3.778 3.778 0 001.1 18.294l5.165 5.39A1.009 1.009 0 007 24a1.007 1.007 0 00.735-.316L12.9 18.3a3.779 3.779 0 00-2.065-6.4zM23.624 7.219l-1.582-1.267a.494.494 0 01-.187-.362.5.5 0 01.145-.382l.175-.175a1.106 1.106 0 000-1.566L19.034.324a1.111 1.111 0 00-1.568 0l-3.142 3.143a1.108 1.108 0 000 1.566l.175.175a.5.5 0 01-.041.744l-1.583 1.267a1 1 0 00-.243 1.281l2 3.5a1 1 0 00.869.5.988.988 0 00.5-.132A1 1 0 0016.368 11L15 8.617a.5.5 0 01.122-.638l.842-.673a.5.5 0 01.666.037l.469.469a.5.5 0 01.147.354V11.5a1 1 0 002 0V8.166a.5.5 0 01.146-.354l.47-.469a.5.5 0 01.666-.037l.841.673a.5.5 0 01.122.638L20.132 11a1 1 0 00.372 1.364.988.988 0 00.496.136 1 1 0 00.869-.5l2-3.5a1 1 0 00-.245-1.281zM18.25 6A1.75 1.75 0 1120 4.25 1.75 1.75 0 0118.25 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});