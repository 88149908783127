define("ember-svg-jar/inlined/decoration-hanging-flowers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>decoration-hanging-flowers</title><path d=\"M8.77 18.353A5.5 5.5 0 117.5 7.5a5.472 5.472 0 014.336 2.115 1 1 0 001.576-1.231 7.441 7.441 0 00-4.5-2.746.5.5 0 01-.412-.491V1a1 1 0 00-2 0v4.15a.5.5 0 01-.406.49A7.5 7.5 0 109.23 20.3a1 1 0 10-.46-1.946z\"/><path d=\"M18 17.5a3.473 3.473 0 00-3.118 1.581.5.5 0 01-.711.168A1.5 1.5 0 0113.5 18a3.982 3.982 0 00-.465-1.871.249.249 0 01.152-.358A5.962 5.962 0 0017.5 10.05V7.884a.5.5 0 01.6-.49 4.24 4.24 0 001.976-.027 5.394 5.394 0 002.844-2.346.5.5 0 00-.2-.717 5.4 5.4 0 00-3.647-.55 4.215 4.215 0 00-1.712 1 .25.25 0 01-.417-.152 4.34 4.34 0 00-.727-1.981A5.411 5.411 0 0013.038.754a.5.5 0 00-.607.434 5.412 5.412 0 00.738 3.612 4.591 4.591 0 002.021 1.5.5.5 0 01.31.463v3.287A3.954 3.954 0 0111.55 14H8.5a1 1 0 000 2h1a2 2 0 012 2 3.49 3.49 0 002.866 3.424.5.5 0 01.377.315A3.486 3.486 0 0018 24h.877A5.281 5.281 0 0024 20c-3.479.208-4.042-2.5-6-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});