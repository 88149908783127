define("ember-svg-jar/inlined/asian-food-pork-bun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>asian-food-pork-bun</title><path d=\"M16.907 7.961A22.441 22.441 0 0113.13 4.97a1.548 1.548 0 00-2.259 0 22.492 22.492 0 01-3.778 2.991C3.768 10.271 0 12.888 0 16.5 0 21.477 4.038 24 12 24s12-2.523 12-7.5c0-3.612-3.769-6.229-7.093-8.539zm-7.3 2.425A13.849 13.849 0 016.9 12.628a.75.75 0 01-1.033-.228.751.751 0 01.233-1.04 12.311 12.311 0 002.411-2 .75.75 0 011.1 1.024zM12.75 13a.75.75 0 01-1.5 0v-2.5a.75.75 0 011.5 0zm5.383-.6a.75.75 0 01-1.034.232 13.849 13.849 0 01-2.706-2.242.75.75 0 011.1-1.024 12.311 12.311 0 002.411 2 .751.751 0 01.229 1.034zM4.5 7a1 1 0 01-.707-1.707c.342-.342.366-.418-.15-1.279-.469-.78-1.34-2.232.15-3.721a1 1 0 011.414 1.414c-.342.342-.366.418.15 1.279.469.78 1.34 2.232-.15 3.721A1 1 0 014.5 7zM19.5 7a1 1 0 01-.707-.293c-1.49-1.489-.619-2.941-.15-3.721.516-.861.492-.937.15-1.279A1 1 0 0120.207.293c1.49 1.489.619 2.941.15 3.721-.516.861-.492.937-.15 1.279A1 1 0 0119.5 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});