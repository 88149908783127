define("ember-svg-jar/inlined/multiple-actions-location.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-location</title><path d=\"M14.971 8.4l-.485-.172a1.8 1.8 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 0012.5 0a2.986 2.986 0 00-1.341.314.25.25 0 00-.1.363 5 5 0 01.841 2.808 6.259 6.259 0 01-.922 3.624.254.254 0 00-.029.216.249.249 0 00.151.156c1.917.716 3.3 1.328 3.9 2.537.035.069.062.13.1.21a.189.189 0 00.112.1.186.186 0 00.151-.014A6.46 6.46 0 0117 9.68a.25.25 0 00.044-.472c-.589-.261-1.286-.52-2.073-.808z\"/><path d=\"M14.015 11.309a.25.25 0 00.063-.264 4.09 4.09 0 00-.2-.468c-.454-.907-1.9-1.44-3.911-2.179l-.485-.172a1.8 1.8 0 01-.052-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 007.5 0a3.219 3.219 0 00-3.145 3.485 4.609 4.609 0 001.192 3.538 1.877 1.877 0 010 1.185L5.03 8.4c-2.007.739-3.457 1.272-3.911 2.179A7.531 7.531 0 00.5 13.5a.5.5 0 00.5.5h11.138a.251.251 0 00.236-.166 6.517 6.517 0 011.641-2.525zM18.5 11a5.006 5.006 0 00-5 5c0 3.171 4.457 7.664 4.646 7.854a.5.5 0 00.707 0c.189-.19 4.647-4.683 4.647-7.854a5.006 5.006 0 00-5-5zm0 3.25A1.75 1.75 0 1116.75 16a1.753 1.753 0 011.75-1.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});