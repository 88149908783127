define("ember-svg-jar/inlined/iris-scan-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>iris-scan-1</title><path d=\"M6.5 22H2.25a.25.25 0 01-.25-.25V17.5a1 1 0 00-2 0V22a2 2 0 002 2h4.5a1 1 0 000-2zM1 7.5a1 1 0 001-1V2.25A.25.25 0 012.25 2H6.5a1 1 0 000-2H2a2 2 0 00-2 2v4.5a1 1 0 001 1zM23 16.5a1 1 0 00-1 1v4.25a.25.25 0 01-.25.25H17.5a1 1 0 000 2H22a2 2 0 002-2v-4.5a1 1 0 00-1-1zM22 0h-4.5a1 1 0 000 2h4.25a.25.25 0 01.25.25V6.5a1 1 0 002 0V2a2 2 0 00-2-2z\"/><circle cx=\"12\" cy=\"12\" r=\"1.5\"/><path d=\"M4.842 11.257a1.113 1.113 0 000 1.484c1.7 1.882 4.463 3.8 7.189 3.758 2.724.046 5.484-1.876 7.188-3.758a1.112 1.112 0 000-1.484c-1.74-1.922-4.509-3.8-7.186-3.756-2.681-.045-5.451 1.834-7.191 3.756zM14.5 12A2.5 2.5 0 1112 9.5a2.5 2.5 0 012.5 2.5zM12 4.5a1 1 0 001-1V2a1 1 0 00-2 0v1.5a1 1 0 001 1zM12 19.5a1 1 0 00-1 1V22a1 1 0 002 0v-1.5a1 1 0 00-1-1zM23 11h-1.5a1 1 0 000 2H23a1 1 0 000-2zM1 13h1.5a1 1 0 000-2H1a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});