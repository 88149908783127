define("ember-svg-jar/inlined/align-center", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>align-center</title><path d=\"M19 12.5h-5.75a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H16a1.5 1.5 0 001.5-1.5v-3A1.5 1.5 0 0016 5h-2.75a.25.25 0 01-.25-.25V2a1 1 0 00-2 0v2.75a.25.25 0 01-.25.25H8a1.5 1.5 0 00-1.5 1.5v3A1.5 1.5 0 008 11h2.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H5A1.5 1.5 0 003.5 14v3A1.5 1.5 0 005 18.5h5.75a.25.25 0 01.25.25V22a1 1 0 002 0v-3.25a.25.25 0 01.25-.25H19a1.5 1.5 0 001.5-1.5v-3a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});