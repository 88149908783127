define("ember-svg-jar/inlined/office-file-rtf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-file-rtf</title><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.425A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.248A.25.25 0 0014.75 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.248A.25.25 0 0021.75 7zM6.888 16.179l1.966 1.966a.5.5 0 01-.708.707s-1.973-1.976-2.018-2.019a.073.073 0 00-.128.056V18.5a.5.5 0 01-1 0v-6a.5.5 0 01.5-.5H7a2 2 0 010 4 .123.123 0 00-.112.179zM13.5 13h-.75a.25.25 0 00-.25.25v5.25a.5.5 0 01-1 0v-5.251a.251.251 0 00-.251-.249H10.5a.5.5 0 010-1h3a.5.5 0 010 1zm5 0h-2a.5.5 0 00-.5.5v1.251a.249.249 0 00.249.249H17.5a.5.5 0 010 1h-1.25a.25.25 0 00-.25.25v2.25a.5.5 0 11-1 0v-5a1.5 1.5 0 011.5-1.5h2a.5.5 0 010 1z\"/><path d=\"M8 14a1 1 0 00-1-1h-.749a.251.251 0 00-.251.249v1.5a.249.249 0 00.249.251H7a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});