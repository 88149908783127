define("ember-svg-jar/inlined/modern-tv-channel-van", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-tv-channel-van</title><path d=\"M15.76 22a.25.25 0 00.249-.231 2.989 2.989 0 01.828-1.846.247.247 0 00.05-.271.25.25 0 00-.23-.152H10a1 1 0 01-1-1v-8.239a.25.25 0 00-.25-.25H4.5a.5.5 0 00-.457.3L2.1 14.67a.259.259 0 01-.052.075L.854 15.939A3.248 3.248 0 000 18v2.511a1.5 1.5 0 001.5 1.5l.261-.006a.25.25 0 00.244-.231A3 3 0 015 19a3.049 3.049 0 012.993 2.772.25.25 0 00.249.235z\"/><circle cx=\"5\" cy=\"22\" r=\"2\"/><circle cx=\"19\" cy=\"22\" r=\"2\"/><path d=\"M23 7.5H11a1 1 0 00-1 1V18a.5.5 0 00.5.5H23a1 1 0 001-1v-9a1 1 0 00-1-1zm-10.5 3.739a.75.75 0 010-1.5h3a.75.75 0 010 1.5H15a.25.25 0 00-.25.25v5a.75.75 0 01-1.5 0v-5a.25.25 0 00-.25-.25zm9.25.75a8.3 8.3 0 01-1.65 4.95.75.75 0 01-1.2 0 8.3 8.3 0 01-1.65-4.95v-1.5a.75.75 0 011.5 0v1.5a6.785 6.785 0 00.519 2.591.25.25 0 00.462 0 6.785 6.785 0 00.519-2.591v-1.5a.75.75 0 011.5 0zM20.244 5.745a3.935 3.935 0 000-5.578.5.5 0 00-.707 0l-4.87 4.871a.5.5 0 000 .707 3.948 3.948 0 005.577 0zM15.293 2.207A1 1 0 0016.707.793l-.5-.5a1 1 0 00-1.414 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});