define("ember-svg-jar/inlined/locker-room-suitcase-umbrella", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>locker-room-suitcase-umbrella</title><path d=\"M17.5 12a3 3 0 013 3v4.621a.25.25 0 00.494.054l2.994-13.568a.5.5 0 00-.2-.513.506.506 0 00-.549-.023l-1.162.7a1.412 1.412 0 01-1.577-.078c.013-.065 0-3.193 0-3.193a2.5 2.5 0 00-5 0v.5a1 1 0 002 0V3a.5.5 0 011 0s-.013 3.128 0 3.193a1.417 1.417 0 01-1.582.075l-1.161-.7a.5.5 0 00-.746.533l1.2 5.7a.25.25 0 00.245.2zM10.6 5.3a5.3 5.3 0 10-5.3 5.3 5.307 5.307 0 005.3-5.3zM5.3 1.75a2.25 2.25 0 01.75 4.372V6.3a.75.75 0 01-1.5 0v-.443a1.154 1.154 0 01.911-1.124A.75.75 0 104.55 4a.75.75 0 01-1.5 0A2.253 2.253 0 015.3 1.75zm.75 6.75a.75.75 0 11-.75-.75.75.75 0 01.75.75zM7 13.75a.25.25 0 00-.25-.25H5.5A1.5 1.5 0 004 15v7.5A1.5 1.5 0 005.5 24h1.25a.25.25 0 00.25-.25z\"/><path d=\"M19 22.5V15a1.5 1.5 0 00-1.5-1.5h-1.25a.25.25 0 00-.25.25v10a.25.25 0 00.25.25h1.25a1.5 1.5 0 001.5-1.5zM8.5 13.5h-.25a.25.25 0 00-.25.25v10a.25.25 0 00.25.25h6.5a.25.25 0 00.25-.25v-10a.25.25 0 00-.25-.25h-.25v-1a3 3 0 00-6 0zm3-2a1.04 1.04 0 011 1v1h-2v-1a1.045 1.045 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});