define("ember-svg-jar/inlined/people-woman-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-1</title><circle cx=\"14\" cy=\"8.5\" r=\"1\"/><circle cx=\"10\" cy=\"8.5\" r=\"1\"/><path d=\"M10.38 11.112a.641.641 0 00.314.72 2.423 2.423 0 00.438.181 3 3 0 00.867.126h.006a3.02 3.02 0 00.865-.125 2.441 2.441 0 00.435-.181.639.639 0 00-.3-1.2H11a.641.641 0 00-.62.479z\"/><path d=\"M23.5 20.944a2.463 2.463 0 00-.967-1.976 11.752 11.752 0 00-2.221-1.308.249.249 0 01-.019-.444 13.749 13.749 0 001.581-1.016.5.5 0 00.031-.759c-2.927-2.724-2.4-6.591-2.405-7.955a7.5 7.5 0 10-14.993.081c0 .77.7 4.975-2.413 7.874a.5.5 0 00.032.759 13.673 13.673 0 001.581 1.018.25.25 0 01-.019.445 11.72 11.72 0 00-2.221 1.308A2.463 2.463 0 00.5 20.944V23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5zM11.8 5.429a.251.251 0 01.392 0A5.451 5.451 0 0016.261 7.5a.251.251 0 01.239.25V9.5a4.5 4.5 0 01-9 0V7.745a.25.25 0 01.239-.25A5.453 5.453 0 0011.8 5.429zm4.084 11.382a.251.251 0 01.2.327c-1.213 3.621-2.947 3.853-3.966 4.4a.249.249 0 01-.233 0C8.459 19.778 8.1 18.1 7.878 17.119a.249.249 0 01.037-.2.246.246 0 01.167-.107 25.1 25.1 0 017.806-.001z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});