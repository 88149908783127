define("ember-svg-jar/inlined/arrow-thick-down-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-down-3</title><path d=\"M21.418 8.263a.987.987 0 00-1.068.149l-4.95 4.234a.25.25 0 01-.413-.19V1.085a1 1 0 00-1-1h-4a1 1 0 00-1 1v11.367a.25.25 0 01-.413.19l-4.923-4.23A1 1 0 002 9.17v5.692a1 1 0 00.332.745l8.99 8.052a1 1 0 001.334 0l9.009-8.053a1 1 0 00.335-.744V9.171a.99.99 0 00-.582-.908z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});