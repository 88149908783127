define("ember-svg-jar/inlined/police-woman-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-woman-5</title><path d=\"M17.146 16.338a5.351 5.351 0 001.317.161 3.8 3.8 0 002.921-1.12.5.5 0 00-.231-.8c-2.607-.838-2.188-4.409-2.155-6.4a.251.251 0 01.211-.243 40.572 40.572 0 002.508-.466 1 1 0 10-.434-1.952c-.9.2-1.825.358-2.76.5a.25.25 0 01-.28-.191l-.719-3.115A3.485 3.485 0 0014.113 0H9.887a3.485 3.485 0 00-3.411 2.713l-.719 3.115a.25.25 0 01-.28.191 35.864 35.864 0 01-2.76-.5 1 1 0 00-.434 1.952c.82.182 1.659.333 2.508.466A.251.251 0 015 8.185c.03 2.027.426 5.509-2.155 6.338a.5.5 0 00-.327.337.5.5 0 00.1.46 3.8 3.8 0 002.921 1.119 5.351 5.351 0 001.317-.161 3.056 3.056 0 001.35-.692.249.249 0 01.3-.034 6.931 6.931 0 006.96.022.248.248 0 01.3.039 3.016 3.016 0 001.38.725zM13.244 2.25a.25.25 0 01.25.261A2.9 2.9 0 0112.112 5.2a.252.252 0 01-.224 0 2.9 2.9 0 01-1.382-2.69.252.252 0 01.069-.184.25.25 0 01.18-.077zM7 9.5v-.982a.249.249 0 01.275-.249A47.247 47.247 0 0012 8.505a47.247 47.247 0 004.725-.236.25.25 0 01.275.249V9.5a5 5 0 01-10 0z\"/><path d=\"M.5 23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-2.106a2.462 2.462 0 00-.967-1.977C21.106 18.328 17.839 16.5 12 16.5s-9.106 1.828-10.533 2.917A2.462 2.462 0 00.5 21.394zm16.361-.541l-.609-.406a2.247 2.247 0 01-1-1.872v-.93a.25.25 0 01.25-.25h3a.25.25 0 01.25.25v.93a2.246 2.246 0 01-1 1.872l-.609.406a.251.251 0 01-.278 0z\"/><circle cx=\"14\" cy=\"9.73\" r=\"1\"/><circle cx=\"10\" cy=\"9.73\" r=\"1\"/><path d=\"M13.077 11.862h-2a.639.639 0 00-.3 1.2 2.31 2.31 0 00.435.18 2.99 2.99 0 00.867.126h.006a2.976 2.976 0 00.863-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});