define("ember-svg-jar/inlined/laboratory-test-stool-cup-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laboratory-test-stool-cup-1</title><path d=\"M22 2.5H12a2 2 0 00-2 2v1a1 1 0 001 1h12a1 1 0 001-1v-1a2 2 0 00-2-2zM22.5 8h-11a.5.5 0 00-.5.5v1.415a.5.5 0 00.143.349 8.136 8.136 0 011.071 1.322 5.143 5.143 0 01.779 3.984.5.5 0 00.156.489A3.914 3.914 0 0114.5 19a3.752 3.752 0 01-.445 1.766.5.5 0 00.442.734h6A2.5 2.5 0 0023 19v-1.5a.5.5 0 00-.5-.5h-6a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h6a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5zM11.47 16.742a.5.5 0 01-.244-.68 3.57 3.57 0 00-.279-3.673 9.7 9.7 0 00-2.608-2.541.75.75 0 00-1.185.615A2.174 2.174 0 016.52 12a2.147 2.147 0 01-1.531.642h-.847a2.476 2.476 0 00-2.458 1.949 2.436 2.436 0 00.424 1.929.029.029 0 010 .027.029.029 0 01-.021.018A2.548 2.548 0 000 19a2.6 2.6 0 002.682 2.5h7.636A2.6 2.6 0 0013 19a2.5 2.5 0 00-1.53-2.258z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});