define("ember-svg-jar/inlined/ceiling-lamp-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ceiling-lamp-2</title><path d=\"M12 20.5a1 1 0 00-1 1V23a1 1 0 002 0v-1.5a1 1 0 00-1-1zM7.716 20.523a1 1 0 00-1.192.76L6.2 22.748a1 1 0 00.76 1.192.951.951 0 00.217.025 1 1 0 00.975-.784l.325-1.464a1 1 0 00-.761-1.194zM17.476 21.283a1 1 0 10-1.952.434l.325 1.464a1 1 0 00.975.784.951.951 0 00.217-.025 1 1 0 00.76-1.192zM23 0H1a1 1 0 000 2h9.75a.25.25 0 01.25.25v1.732a.252.252 0 01-.166.236A3.49 3.49 0 008.5 7.5.5.5 0 009 8h6a.5.5 0 00.5-.5 3.49 3.49 0 00-2.334-3.282.252.252 0 01-.166-.236V2.25a.25.25 0 01.25-.25H23a1 1 0 000-2zM14.822 9.524a.517.517 0 00-.155-.024H9.333a.509.509 0 00-.159.026A8.645 8.645 0 003.5 17.683a1.807 1.807 0 00.5 1.28 1.768 1.768 0 001.27.537h13.459A1.773 1.773 0 0020 18.964a1.807 1.807 0 00.5-1.28 8.582 8.582 0 00-5.678-8.16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});