define("ember-svg-jar/inlined/athletics-long-jumping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>athletics-long-jumping</title><circle cx=\"12.25\" cy=\"2.5\" r=\"2.5\"/><path d=\"M5.033 15.377A1.5 1.5 0 006.25 16h4.481a.5.5 0 01.244.063l4.046 2.249a1.5 1.5 0 001.458-2.624l-4.047-2.247a3.5 3.5 0 00-1.7-.441H8.678a.252.252 0 01-.2-.1.248.248 0 01-.034-.225L9.458 9.62a.246.246 0 01.154-.157.25.25 0 01.218.025c.513.332 1.579 1.019 1.616 1.037a4.523 4.523 0 002.012.475h2.792a1.5 1.5 0 000-3h-2.792a1.5 1.5 0 01-.608-.129l-3.284-2.13a1.5 1.5 0 00-2.239.784l-2.5 7.5a1.5 1.5 0 00.206 1.352zM5.556 20.418A1 1 0 004.743 20H.5a.5.5 0 00-.5.5v3a.5.5 0 00.5.5H7a.5.5 0 00.417-.776z\"/><circle cx=\"11.75\" cy=\"22.75\" r=\"1.25\"/><circle cx=\"9\" cy=\"20.75\" r=\"1.25\"/><circle cx=\"17.25\" cy=\"22.75\" r=\"1.25\"/><circle cx=\"14.5\" cy=\"20.75\" r=\"1.25\"/><circle cx=\"22.75\" cy=\"22.75\" r=\"1.25\"/><circle cx=\"20\" cy=\"20.75\" r=\"1.25\"/><path d=\"M3 7.5h1.5a1 1 0 000-2H3a1 1 0 000 2zM2 11.5h1.5a1 1 0 000-2H2a1 1 0 000 2zM1 15.5h1.5a1 1 0 000-2H1a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});