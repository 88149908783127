define("ember-svg-jar/inlined/pets-paw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pets-paw</title><ellipse cx=\"3.145\" cy=\"10.013\" rx=\"3\" ry=\"4.461\"/><path d=\"M8.772 8.443c1.824 0 2.766-2.242 2.687-4.206C11.368 1.94 10.1.227 8.527.25 6.753.321 5.843 2.522 5.92 4.456c.088 2.203 1.31 3.987 2.852 3.987z\"/><ellipse cx=\"20.855\" cy=\"10.013\" rx=\"3\" ry=\"4.461\"/><path d=\"M15.228 8.443c1.542 0 2.764-1.784 2.852-3.987.075-1.888-.811-4.134-2.607-4.206-1.573-.025-2.841 1.69-2.932 3.987-.077 1.932.849 4.206 2.687 4.206zM15.9 13.431c-.4-2-1.639-4.145-3.9-4.145s-3.5 2.141-3.9 4.146a4.656 4.656 0 01-1.337 1.91c-.978 1.032-2.085 2.2-2.085 3.975 0 3.988 5.947 4.433 7.324 4.433 3.527 0 7.324-1.387 7.324-4.433 0-1.773-1.107-2.943-2.085-3.975a4.652 4.652 0 01-1.341-1.911z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});