define("ember-svg-jar/inlined/cleaning-vacuum-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cleaning-vacuum-1</title><path d=\"M5.492 21.5a4 4 0 014-4 3.479 3.479 0 01.435.029.5.5 0 00.565-.5V14.5a.5.5 0 00-.5-.5H.738a.5.5 0 00-.489.4 15.082 15.082 0 00.608 7.559A1.5 1.5 0 002.285 23h3.149a.25.25 0 00.2-.1.253.253 0 00.038-.223 3.9 3.9 0 01-.18-1.177z\"/><path d=\"M22.992 24a1 1 0 00.948-1.316l-.666-2a1 1 0 00-.949-.684h-1.833a.5.5 0 01-.5-.5v-13a6.5 6.5 0 00-13 0 .5.5 0 01-.5.5 1 1 0 00-1 1v.556a.5.5 0 01-.434.5 5.128 5.128 0 00-3.483 2.16 5.5 5.5 0 00-.348.542.5.5 0 00.434.747h8.331a.5.5 0 00.5-.5V8a1 1 0 00-1-1 .5.5 0 01-.5-.5 4.5 4.5 0 019 0v13a.5.5 0 01-.5.5h-1.833a1 1 0 00-.949.683l-.667 2A1 1 0 0014.992 24z\"/><circle cx=\"9.492\" cy=\"21.5\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});