define("ember-svg-jar/inlined/business-deal-handshake-circle-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-deal-handshake-circle-alternate</title><path d=\"M0 12A12 12 0 1012 0 12.013 12.013 0 000 12zM12 2A10 10 0 112 12 10.011 10.011 0 0112 2z\"/><path d=\"M4.5 15.5h1.667a1.32 1.32 0 001.006-.468.254.254 0 01.191-.088h.673a.252.252 0 01.154.056l1.742 1.361a1.354 1.354 0 001.81.088l1.924-1.567a.269.269 0 01.109-.052l2.731-.542a1.344 1.344 0 001.326 1.212H19.5a.5.5 0 00.5-.5V9a.5.5 0 00-.5-.5h-1.667a1.28 1.28 0 00-.412.067.249.249 0 01-.168 0l-2.945-1.123a2.252 2.252 0 00-1.736.095l-1.261.607a.246.246 0 01-.224 0 2.058 2.058 0 00-1.722-.081l-2.019.853a.249.249 0 01-.27-.049 1.318 1.318 0 00-.909-.369H4.5A.5.5 0 004 9v6a.5.5 0 00.5.5zm7.008-5.764a.7.7 0 00.1-.067l1.626-.782a.747.747 0 01.554-.036l2.554.969a.25.25 0 01.161.234v2.5a.25.25 0 01-.2.245l-.7.139a.251.251 0 01-.3-.213 1.441 1.441 0 00-.3-.709l-1.7-1.856a.755.755 0 00-.837-.187l-.616.251a.319.319 0 01-.241 0 .307.307 0 01-.163-.167.3.3 0 01.062-.321zm-4.008.909a.25.25 0 01.153-.231l2.282-.965a.037.037 0 01.036.007.036.036 0 01.01.035 1.81 1.81 0 001.752 2.253 1.831 1.831 0 00.679-.132.218.218 0 01.239.054l.925 1.01a.251.251 0 01-.026.363c-.006 0-2.429 1.98-2.5 2.039a.251.251 0 01-.312 0L8.843 13.6a.747.747 0 00-.462-.16H7.75a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});