define("ember-svg-jar/inlined/skull", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skull</title><path d=\"M12 .5a10.011 10.011 0 00-10 10V13a5.507 5.507 0 003.2 4.993 2 2 0 011.044 2.48c-.645 1.821-.911 2.167-.429 2.7a1 1 0 00.742.331H8.5A.5.5 0 009 23v-2a1 1 0 012 0v2a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-2a1 1 0 012 0v2a.5.5 0 00.5.5h1.947a1 1 0 00.741-.33c.482-.53.219-.87-.429-2.7a2 2 0 011.041-2.477A5.506 5.506 0 0022 13v-2.5a10.008 10.008 0 00-10-10zM7.5 14.506a2.5 2.5 0 112.5-2.5 2.5 2.5 0 01-2.5 2.5zm5.676 2.763a.5.5 0 01-.426.237h-1.5a.5.5 0 01-.447-.723l.75-1.5a.52.52 0 01.894 0l.75 1.5a.5.5 0 01-.02.486zm3.324-2.763a2.5 2.5 0 112.5-2.5 2.5 2.5 0 01-2.5 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});