define("ember-svg-jar/inlined/video-game-boo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-boo</title><path d=\"M23.986 17.339a1 1 0 00-1.5-1.026 3 3 0 01-2.369.4 1.927 1.927 0 01-.862-.762.5.5 0 01.249-.722c3.014-1.172 3.835-4.679 3.889-6.049a1.036 1.036 0 00-.429-.882 1.047 1.047 0 00-.986-.132l-2.644.99A.25.25 0 0119 8.918V8A8 8 0 003 8v.733a.251.251 0 01-.123.215.248.248 0 01-.247 0l-1.114-.61a1.038 1.038 0 00-1.091.095 1.052 1.052 0 00-.409 1.036c.449 2.547 1.846 4.073 3.324 5.69a2.924 2.924 0 01.687 1.3A9.86 9.86 0 0013.571 24c5.647 0 9.735-2.614 10.415-6.661zM11.25 7a.75.75 0 011.5 0v1.5a.75.75 0 01-1.5 0zm-4.5 0a.75.75 0 011.5 0v1.5a.75.75 0 01-1.5 0zm8 5.25A.75.75 0 0114 13h-1.385a.5.5 0 00-.5.5v2.385a2.115 2.115 0 01-4.23 0V13.5a.5.5 0 00-.5-.5H6a.75.75 0 010-1.5h8a.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});