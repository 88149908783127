define("ember-svg-jar/inlined/educative-toys-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>educative-toys-house</title><path d=\"M22.687 17.512a.5.5 0 01-.437-.5V11.75A1.752 1.752 0 0020.5 10h-3.25a.5.5 0 01-.5-.5V6.915a.415.415 0 01.415-.415 1.264 1.264 0 00.771-2.265L12.771.262a1.265 1.265 0 00-1.542 0L6.064 4.235A1.264 1.264 0 006.835 6.5a.415.415 0 01.415.415V9.5a.5.5 0 01-.5.5H3.5a1.752 1.752 0 00-1.75 1.75v5.266a.5.5 0 01-.437.5A1.5 1.5 0 000 19v3.5A1.5 1.5 0 001.5 24h6.75a1.5 1.5 0 001.5-1.5v-4a2.25 2.25 0 014.5 0v4a1.5 1.5 0 001.5 1.5h6.75a1.5 1.5 0 001.5-1.5V19a1.5 1.5 0 00-1.313-1.488zM3.5 21.5a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5V20a.5.5 0 01.5-.5H3a.5.5 0 01.5.5zm4.25 0a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5V20a.5.5 0 01.5-.5h1.25a.5.5 0 01.5.5zm7-12a.5.5 0 01-.5.5h-4.5a.5.5 0 01-.5-.5V7a.5.5 0 01.5-.5h4.5a.5.5 0 01.5.5zm3.75 12a.5.5 0 01-.5.5h-1.25a.5.5 0 01-.5-.5V20a.5.5 0 01.5-.5H18a.5.5 0 01.5.5zm3.5 0a.5.5 0 01-.5.5H21a.5.5 0 01-.5-.5V20a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});