define("ember-svg-jar/inlined/chair-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chair-bar</title><path d=\"M4.5 2a2 2 0 002 2h11a2 2 0 000-4h-11a2 2 0 00-2 2zM19.044 23.973a1 1 0 00.741-1.2L16.317 8.212a.25.25 0 01.162-.295A1.5 1.5 0 0017.5 6.5V6a.5.5 0 00-.5-.5H7a.5.5 0 00-.5.5v.5a1.5 1.5 0 001.021 1.417.25.25 0 01.162.295L4.215 22.769a1 1 0 00.741 1.2 1.027 1.027 0 00.232.031 1 1 0 00.972-.769l.56-2.347a.5.5 0 01.486-.384H10.5a.5.5 0 01.5.5v2a1 1 0 002 0v-2a.5.5 0 01.5-.5h3.294a.5.5 0 01.486.384l.56 2.347a1 1 0 00.972.769 1.034 1.034 0 00.232-.027zM10.5 8a.5.5 0 01.5.5v6a.5.5 0 01-.5.5H8.438a.25.25 0 01-.243-.308L9.7 8.384A.5.5 0 0110.184 8zm2.5.5a.5.5 0 01.5-.5h.316a.5.5 0 01.486.384l1.5 6.308a.25.25 0 01-.243.308H13.5a.5.5 0 01-.5-.5zm-5.4 10a.25.25 0 01-.243-.308l.192-.808A.5.5 0 018.04 17h2.46a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5zm5.9 0a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h2.46a.5.5 0 01.487.384l.192.808a.25.25 0 01-.243.308z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});