define("ember-svg-jar/inlined/medical-nanobot-blood", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-nanobot-blood</title><path d=\"M13.681 13.944A7.485 7.485 0 019.788 3.661a.248.248 0 000-.216 19.514 19.514 0 00-1.03-2.029.931.931 0 00-1.517 0C5.95 3.443 1.5 14.424 1.5 17.468a6.5 6.5 0 1013 0 14.226 14.226 0 00-.673-3.362.253.253 0 00-.146-.162z\"/><path d=\"M22.124 7.219l-1.582-1.267a.5.5 0 01-.187-.362.5.5 0 01.145-.382l.175-.175a1.106 1.106 0 000-1.566L17.533.324a1.109 1.109 0 00-1.567 0l-3.142 3.143a1.108 1.108 0 000 1.566l.176.175a.5.5 0 01-.041.744l-1.584 1.267a1 1 0 00-.243 1.281l2 3.5a1 1 0 00.869.5.988.988 0 00.5-.132A1 1 0 0014.868 11l-1.363-2.383a.5.5 0 01.121-.638l.842-.673a.5.5 0 01.666.037l.469.47a.5.5 0 01.147.353V11.5a1 1 0 002 0V8.166a.5.5 0 01.146-.353l.47-.47a.5.5 0 01.666-.037l.841.673a.5.5 0 01.127.638L18.632 11A1 1 0 0019 12.368a.988.988 0 00.495.132 1 1 0 00.869-.5l2-3.5a1 1 0 00-.24-1.281zM16.75 6a1.75 1.75 0 111.75-1.75A1.749 1.749 0 0116.75 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});