define("ember-svg-jar/inlined/instrument-pan-flute", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>instrument-pan-flute</title><path d=\"M6.25 14.5a.25.25 0 00.25-.25V10a1 1 0 00-1-1h-2a1 1 0 00-1 1v4.25a.25.25 0 00.25.25zM2.75 20.5a.25.25 0 00-.25.25V23a1 1 0 001 1h2a1 1 0 001-1v-2.25a.25.25 0 00-.25-.25zM7.75 20.5a.25.25 0 00-.25.25V23a1 1 0 001 1h2a1 1 0 001-1v-2.25a.25.25 0 00-.25-.25zM11.25 14.5a.25.25 0 00.25-.25V7a1 1 0 00-1-1h-2a1 1 0 00-1 1v7.25a.25.25 0 00.25.25zM12.75 20.5a.25.25 0 00-.25.25V23a1 1 0 001 1h2a1 1 0 001-1v-2.25a.25.25 0 00-.25-.25zM16.25 14.5a.25.25 0 00.25-.25V4a1 1 0 00-1-1h-2a1 1 0 00-1 1v10.25a.25.25 0 00.25.25zM17.75 20.5a.25.25 0 00-.25.25V23a1 1 0 001 1h2a1 1 0 001-1v-2.25a.25.25 0 00-.25-.25zM21.25 14.5a.25.25 0 00.25-.25V1a1 1 0 00-1-1h-2a1 1 0 00-1 1v13.25a.25.25 0 00.25.25z\"/><rect x=\"1.5\" y=\"16\" width=\"21\" height=\"3\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});