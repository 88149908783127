define("ember-svg-jar/inlined/database-download-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-download-alternate</title><path d=\"M2.75 17.753v-.789a.25.25 0 01.372-.218A14.121 14.121 0 007.6 18.2a1 1 0 001.153-.957v-.02a.969.969 0 00-.81-.983c-3.247-.538-5.2-1.8-5.2-2.735v-.789a.248.248 0 01.372-.217 15.426 15.426 0 005.693 1.621.958.958 0 00.94-.477l.014-.026a.99.99 0 00-.758-1.483c-3.875-.416-6.254-1.845-6.254-2.881v-.789a.249.249 0 01.372-.218A17.574 17.574 0 0011.25 10a17.582 17.582 0 008.127-1.756.25.25 0 01.373.218V9.54a.979.979 0 00.612.9l.036.014a.966.966 0 001.352-.88V5.002c0-3.248-5.409-5-10.5-5S.763 1.751.752 4.993L.75 5v12.75c0 2.694 3.722 4.355 7.9 4.843a.983.983 0 001.011-1.405l-.012-.023a1.019 1.019 0 00-.784-.548c-3.799-.431-6.115-1.841-6.115-2.864zM11.25 2c5.186 0 8.5 1.773 8.5 3v.009C19.739 6.232 16.431 8 11.25 8s-8.5-1.777-8.5-3 3.312-3 8.5-3z\"/><path d=\"M16.75 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-.39 9.812l-2-2.5a.5.5 0 01.39-.812h.75a.249.249 0 00.25-.25V14.5a1 1 0 012 0v2.75a.25.25 0 00.25.25h.75a.5.5 0 01.391.812l-2 2.5a.5.5 0 01-.781 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});