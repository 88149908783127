define("ember-svg-jar/inlined/earth-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earth-upload</title><path d=\"M10.814 21.875a.5.5 0 00-.338-.219 9.848 9.848 0 01-2.932-.945v-1.292a2.468 2.468 0 01.884-1.9 4.455 4.455 0 00-2.864-7.865h-3.2a9.884 9.884 0 0115.221-5.941h-3.357a2.723 2.723 0 100 5.446 2.538 2.538 0 011.507.509.5.5 0 00.378.09 7.863 7.863 0 017.095 2.39.39.39 0 00.673-.267 11.881 11.881 0 10-16.494 10.95l.157.077v-.02a11.824 11.824 0 004.456.875.194.194 0 00.13-.337 7.98 7.98 0 01-1.316-1.551z\"/><path d=\"M17.445 11.129a6.436 6.436 0 106.435 6.435 6.442 6.442 0 00-6.435-6.435zm2.426 6.154a.494.494 0 01-.446.281h-.5a.5.5 0 00-.5.5v2.476a.99.99 0 11-1.98 0v-2.481a.5.5 0 00-.5-.5h-.495a.5.5 0 01-.386-.8l1.98-2.475a.512.512 0 01.773 0l1.981 2.475a.492.492 0 01.073.524z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});