define("ember-svg-jar/inlined/picture-polaroid-landscape", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><style>.a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round}</style></defs><title>picture-polaroid-landscape</title><path class=\"a\" d=\"M15.543 15.543l-2.628-6.571c-.2-.511-.558-.519-.785-.018l-2.087 4.589-1.859-2.231a.667.667 0 00-1.155.089l-2.486 4.142\"/><rect class=\"a\" x=\"1.543\" y=\"1.543\" width=\"17\" height=\"17\" rx=\"1\" ry=\"1\"/><path class=\"a\" d=\"M20.551 7.424l1 .091a1 1 0 01.901 1.085l-1.181 12.948a1 1 0 01-1.087.9L6.243 21.18M1.543 15.543h17\"/><circle class=\"a\" cx=\"6.043\" cy=\"6.043\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});