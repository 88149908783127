define("ember-svg-jar/inlined/design-tool-shape", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-tool-shape</title><path d=\"M13.8 8.965a.25.25 0 00.435.1A3.374 3.374 0 0015 6.917 3.421 3.421 0 0011.583 3.5a3.377 3.377 0 00-2.143.769.25.25 0 00.1.435 6.013 6.013 0 014.26 4.261zM17.75 8.5h2a.75.75 0 000-1.5h-2a.75.75 0 000 1.5zM17.75 11.5h1a.75.75 0 000-1.5h-1a.75.75 0 000 1.5zM7.75 19.25h-3.5a.75.75 0 000 1.5h3.5a.75.75 0 000-1.5zM17.75 5.5h2a.75.75 0 000-1.5h-2a.75.75 0 000 1.5z\"/><path d=\"M24 1.98A1.982 1.982 0 0022.02 0H1.98A1.982 1.982 0 000 1.98v20.04A1.982 1.982 0 001.98 24h20.04A1.982 1.982 0 0024 22.02zM22 21.5a.5.5 0 01-.5.5l-19 .02a.5.5 0 01-.5-.5L1.98 2.5a.5.5 0 01.5-.5l19.02-.02a.5.5 0 01.5.5z\"/><circle cx=\"8\" cy=\"10.5\" r=\"4.5\"/><path d=\"M14.177 15.363a.251.251 0 01.073.177v3.71a1.252 1.252 0 001.25 1.25h3.75a1.252 1.252 0 001.25-1.25v-3.5a1.252 1.252 0 00-1.25-1.25h-3.712a.251.251 0 01-.177-.073l-1.456-1.457a.782.782 0 00-1.06 0 .748.748 0 00-.006 1.053l.006.007zM18.75 16a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25H16a.25.25 0 01-.25-.25v-1.211a.25.25 0 01.427-.177l.668.668a.75.75 0 001.06-1.06l-.543-.543a.25.25 0 01.177-.427z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});