define("ember-svg-jar/inlined/read-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-search</title><path d=\"M6.354 8.984c-.714-.131-1.5-.234-2.445-.325a.75.75 0 11.145-1.493 24.772 24.772 0 013.881.634.5.5 0 00.456-.109 8.505 8.505 0 012.776-1.633.5.5 0 00.333-.472V2.517a.5.5 0 00-.232-.422C9.235.8 5.417.11 1.789 0A1.684 1.684 0 00.534.492 1.764 1.764 0 000 1.753v12.833a1.748 1.748 0 001.713 1.748 32.012 32.012 0 013.454.279.5.5 0 00.556-.613 8.445 8.445 0 01-.223-1.93 8.586 8.586 0 01.064-1.045.5.5 0 00-.432-.557 39.883 39.883 0 00-1.223-.137.75.75 0 01.145-1.493c.58.056 1.109.118 1.6.188a.5.5 0 00.529-.3 8.553 8.553 0 01.508-1 .5.5 0 00-.339-.748zm-2.3-5.491a21.85 21.85 0 015.185 1.02.75.75 0 11-.478 1.422 20.239 20.239 0 00-4.852-.949.75.75 0 11.145-1.493zM23.466.492A1.721 1.721 0 0022.211 0c-3.628.11-7.446.8-9.479 2.1a.5.5 0 00-.232.422v2.603a.5.5 0 00.555.5A8.689 8.689 0 0114 5.57c.165 0 .329 0 .492.014a.247.247 0 00.195-.078.244.244 0 00.067-.2.751.751 0 01.508-.794 23.359 23.359 0 015.184-1.19.75.75 0 11.145 1.493 19.889 19.889 0 00-3.743.766.25.25 0 00-.012.475A8.482 8.482 0 0118.83 7.08a.5.5 0 00.367.082Q19.8 7.06 20.446 7a.757.757 0 01.819.674.69.69 0 01-.483.754.25.25 0 00-.107.389 8.492 8.492 0 011.605 7.18.25.25 0 00.293.3A1.722 1.722 0 0024 14.586V1.753a1.764 1.764 0 00-.534-1.261zM16 10.751h-4A1.252 1.252 0 0010.75 12v.445a.75.75 0 001.5.041.25.25 0 01.249-.236H13a.25.25 0 01.25.25V16a.249.249 0 01-.25.25.75.75 0 000 1.5h2a.75.75 0 100-1.5.249.249 0 01-.25-.25v-3.5a.25.25 0 01.25-.25h.5a.25.25 0 01.249.234.75.75 0 001.5-.039V12A1.252 1.252 0 0016 10.751z\"/><path d=\"M19.9 18.489a.5.5 0 01-.064-.629 7 7 0 10-1.98 1.98.5.5 0 01.629.063l3.806 3.806a1 1 0 101.414-1.415zM14 19a5 5 0 115-5 5.006 5.006 0 01-5 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});