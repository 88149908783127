define("ember-svg-jar/inlined/ranking-winner-medal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ranking-winner-medal</title><path d=\"M9.708 4.843A.25.25 0 009.939 5h4.105a.25.25 0 00.232-.157l1.815-4.5A.249.249 0 0015.859 0H8.124a.25.25 0 00-.232.343zM10.086 9.224a.25.25 0 00.175-.337L6.738.156A.25.25 0 006.506 0H6a1.071 1.071 0 00-.752.341l-3.5 4a1 1 0 00-.1 1.178l3.779 6.222a.25.25 0 00.4.036 8.256 8.256 0 014.259-2.553zM22.354 5.519a1 1 0 00-.1-1.178l-3.5-4A1.057 1.057 0 0017.985 0h-.508a.251.251 0 00-.232.157l-3.507 8.734a.248.248 0 00.009.208.254.254 0 00.165.128 8.261 8.261 0 014.255 2.562.251.251 0 00.4-.036zM11.992 10.5a6.75 6.75 0 106.75 6.75 6.758 6.758 0 00-6.75-6.75zm-1 8.75h.687a.25.25 0 00.25-.25v-3.187a.25.25 0 00-.4-.2l-1.15.862a.75.75 0 01-.9-1.2l1.65-1.237a1.437 1.437 0 012.3 1.15V19a.25.25 0 00.25.25h.375a.75.75 0 110 1.5h-3.062a.75.75 0 010-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});