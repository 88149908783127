define("ember-svg-jar/inlined/video-game-assasin-creed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-assasin-creed</title><path d=\"M13.165 13.329a2.45 2.45 0 01-2.33 0 .75.75 0 10-.67 1.342 3.913 3.913 0 003.67 0 .75.75 0 00-.67-1.342z\"/><path d=\"M2.084 14.619l1.1-.091a8.684 8.684 0 003.868-1.5 1.693 1.693 0 00.552-.918l.119-.548A19.482 19.482 0 018.5 8.688a1.5 1.5 0 012.459-.524l.69.69a.5.5 0 00.707 0l.689-.69a1.5 1.5 0 012.455.526 19.69 19.69 0 01.772 2.879c.04.189.079.371.118.544a1.65 1.65 0 00.539.9 8.823 8.823 0 003.919 1.52l1.065.087a.247.247 0 00.211-.088.252.252 0 00.051-.224 4.573 4.573 0 00-.3-.825L19.7 8.9A19.653 19.653 0 0118.325 5a6.5 6.5 0 00-12.65 0A19.974 19.974 0 014.3 8.9l-2.18 4.578a4.621 4.621 0 00-.3.825.251.251 0 00.263.312z\"/><path d=\"M22.166 16.23a.248.248 0 00-.181-.1l-1.294-.106a10.841 10.841 0 01-4.324-1.546.5.5 0 00-.694.119 4.492 4.492 0 01-7.345 0 .5.5 0 00-.695-.119 10.793 10.793 0 01-4.285 1.543l-1.333.109a.248.248 0 00-.181.1.251.251 0 00-.042.2 4.489 4.489 0 002.1 2.858l7.851 4.639a.5.5 0 00.508 0l7.851-4.639a4.487 4.487 0 002.1-2.858.251.251 0 00-.036-.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});