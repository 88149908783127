define("ember-svg-jar/inlined/common-file-text-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-text-sync</title><path d=\"M20 7.5a1 1 0 00-1-1h-7a1 1 0 000 2h7a1 1 0 001-1z\"/><path d=\"M24 4.415A2 2 0 0023.414 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.277a.247.247 0 00.241.249c.364.009 1.037.041 1.485.093A.247.247 0 008 9.371V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.245.245 0 00-.241.21 7.956 7.956 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2z\"/><path d=\"M14.765 13H16.5a1 1 0 000-2h-2.151a.115.115 0 00-.1.173 1.946 1.946 0 01.322 1.061v.578a.188.188 0 00.194.188zM11.77 18.6a1 1 0 00-1.256.582 4.047 4.047 0 01-1.278 1.929 4.162 4.162 0 01-5.656-.47.255.255 0 01.014-.349l1.717-1.717a.5.5 0 00-.354-.853H.5a.5.5 0 00-.5.5v4.458a.5.5 0 00.854.353l.974-.974a.244.244 0 01.353.009 6.086 6.086 0 0010.211-2.2 1 1 0 00-.622-1.268zM12.691 11.772a.5.5 0 00-.545.109l-1.03 1.031a.244.244 0 01-.352-.008 6.1 6.1 0 00-10.2 2.225 1 1 0 001.879.686A4.054 4.054 0 013.719 13.9a4.153 4.153 0 015.637.435.255.255 0 01-.012.35l-1.655 1.653a.5.5 0 00.354.853H12.5a.5.5 0 00.5-.5v-4.457a.5.5 0 00-.309-.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});