define("ember-svg-jar/inlined/road-sign-speed-limit-50", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-speed-limit-50</title><path d=\"M16.23 9.535a1.728 1.728 0 00-1.73 1.727v2.475a1.726 1.726 0 103.452 0v-2.475a1.728 1.728 0 00-1.722-1.727z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM6.944 11.749H8.8a2.608 2.608 0 010 5.215H5.706a.75.75 0 010-1.5H8.8a1.108 1.108 0 000-2.215H6.944a1.99 1.99 0 01-1.988-1.987V8.785a.75.75 0 01.75-.75h4.952a.75.75 0 010 1.5H6.706a.25.25 0 00-.25.25v1.477a.488.488 0 00.488.487zm12.512 1.988a3.226 3.226 0 11-6.452 0v-2.475a3.226 3.226 0 116.452 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});