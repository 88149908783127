define("ember-svg-jar/inlined/design-tool-stamp-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-tool-stamp-1</title><path d=\"M22 21H2a.5.5 0 00-.5.5A2.5 2.5 0 004 24h16a2.5 2.5 0 002.5-2.5.5.5 0 00-.5-.5zM19.5 13.5h-3c-.858 0-2-.26-2-2.5a4.606 4.606 0 011.16-2.719A5.442 5.442 0 0017 5 5 5 0 007 5a5.442 5.442 0 001.34 3.281A4.606 4.606 0 019.5 11c0 2.24-1.142 2.5-2 2.5h-3A4.505 4.505 0 000 18a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18a4.505 4.505 0 00-4.5-4.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});