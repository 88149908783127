define("ember-svg-jar/inlined/public-service-firefighter-truck-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>public-service-firefighter-truck-1</title><path d=\"M23.473 10.861L2.452.124A1 1 0 001.543 1.9l16.889 8.626a.25.25 0 01-.114.472h-5.833a1 1 0 00-1 1v8.49a.249.249 0 01-.249.25h-1a.25.25 0 01-.25-.25V12a1.5 1.5 0 00-1.5-1.5H5.284a1.527 1.527 0 00-1.34.848l-2.052 4.558a.25.25 0 01-.124.124l-1.328.611a.922.922 0 00-.44.813v3.785a.989.989 0 001 1h.513a.246.246 0 00.185-.083.252.252 0 00.064-.193 2.058 2.058 0 01-.013-.224 3.246 3.246 0 016.492 0 2.058 2.058 0 01-.013.224.252.252 0 00.249.276H15.5a.252.252 0 00.249-.276 2.058 2.058 0 01-.013-.224 3.246 3.246 0 016.492 0 2.06 2.06 0 01-.012.224.243.243 0 00.063.193.249.249 0 00.186.083h.513a.988.988 0 001-1V11.988a.985.985 0 00-.505-1.127zM7.99 15.746a.248.248 0 01-.249.254H4.427a.25.25 0 01-.21-.114.254.254 0 01-.018-.238l1.348-3a.248.248 0 01.227-.147h1.967a.248.248 0 01.249.249z\"/><path d=\"M8.74 9.5a.749.749 0 00.749-.749v-.5a1.75 1.75 0 00-1.748-1.744h-1a1.75 1.75 0 00-1.747 1.748v.5a.749.749 0 00.749.749z\"/><circle cx=\"4.994\" cy=\"21.738\" r=\"2.247\"/><circle cx=\"18.977\" cy=\"21.738\" r=\"2.247\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});