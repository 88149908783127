define("ember-svg-jar/inlined/read-glasses-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-glasses-1</title><path d=\"M23 4h-.023a.749.749 0 01-.714-.521 4.979 4.979 0 00-9-1.092.5.5 0 01-.57.217 2.368 2.368 0 00-1.388 0 .5.5 0 01-.57-.217 4.979 4.979 0 00-8.992 1.074A.78.78 0 011 4a1 1 0 000 2 .781.781 0 01.743.541A5 5 0 0011.5 5a.5.5 0 011 0 5 5 0 009.757 1.541A.781.781 0 0123 6a1 1 0 000-2zM6.5 8a3 3 0 113-3 3 3 0 01-3 3zm11 0a3 3 0 113-3 3 3 0 01-3 3zM20.028 11.51a1.751 1.751 0 00-1.568-.249l-6.151 2a1 1 0 01-.618 0l-6.15-2a1.751 1.751 0 00-2.291 1.665v7a1.745 1.745 0 001.21 1.664l7.309 2.376a.759.759 0 00.462 0l7.309-2.376a1.745 1.745 0 001.21-1.664v-7a1.754 1.754 0 00-.722-1.416zM18 15.522a.5.5 0 01-.363.481l-2.5.714a.5.5 0 01-.637-.481v-.71a.5.5 0 01.363-.48l2.5-.715a.5.5 0 01.637.481z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});