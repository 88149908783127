define("ember-svg-jar/inlined/list-stars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>list-stars</title><path d=\"M9.875 5h13a1 1 0 100-2h-13a1 1 0 000 2zM22.875 11h-13a1 1 0 000 2h13a1 1 0 000-2zM22.875 19h-13a1 1 0 000 2h13a1 1 0 000-2zM5.875 3.249h-.733a.25.25 0 01-.215-.379l.592-.986a.751.751 0 00-1.288-.771l-.642 1.07a.25.25 0 01-.428 0l-.642-1.07a.751.751 0 00-1.288.771l.592.986a.25.25 0 01-.215.379H.875a.75.75 0 100 1.5h.733a.249.249 0 01.218.126.252.252 0 010 .252l-.592.986a.751.751 0 001.288.771l.642-1.07a.25.25 0 01.428 0l.642 1.07a.751.751 0 001.288-.771l-.592-.986a.252.252 0 010-.252.249.249 0 01.218-.126h.733a.75.75 0 000-1.5zM5.875 11.249h-.733a.25.25 0 01-.215-.379l.592-.986a.751.751 0 00-1.288-.771l-.642 1.07a.25.25 0 01-.428 0l-.642-1.07a.751.751 0 00-1.288.771l.592.986a.25.25 0 01-.215.379H.875a.75.75 0 000 1.5h.733a.249.249 0 01.218.126.252.252 0 010 .252l-.592.986a.751.751 0 001.288.771l.642-1.07a.25.25 0 01.428 0l.642 1.07a.751.751 0 001.288-.771l-.592-.986a.252.252 0 010-.252.249.249 0 01.218-.126h.733a.75.75 0 100-1.5zM5.875 19.249h-.733a.25.25 0 01-.215-.379l.592-.986a.751.751 0 00-1.288-.771l-.642 1.07a.25.25 0 01-.428 0l-.642-1.07a.751.751 0 00-1.288.771l.592.986a.25.25 0 01-.215.379H.875a.75.75 0 000 1.5h.733a.249.249 0 01.218.126.252.252 0 010 .252l-.592.986a.751.751 0 001.288.771l.642-1.07a.25.25 0 01.428 0l.642 1.07a.751.751 0 001.288-.771l-.592-.986a.252.252 0 010-.252.249.249 0 01.218-.126h.733a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});