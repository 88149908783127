define("ember-svg-jar/inlined/car-dashboard-manual", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-dashboard-manual</title><path d=\"M7.249 13.735a.25.25 0 01.25.25v1.361a1 1 0 102 0v-1.358a.249.249 0 01.25-.25h3.5a.25.25 0 01.25.25v1.354a1 1 0 002 0V14a.249.249 0 01.25-.25h3.5a.25.25 0 01.25.25v1.347a1 1 0 002 0V8.75a1 1 0 00-2 0v2.75a.252.252 0 01-.074.177.249.249 0 01-.177.073h-3.5a.249.249 0 01-.249-.25V8.75a1 1 0 00-2 0v2.742a.252.252 0 01-.074.177.249.249 0 01-.177.073h-3.5a.249.249 0 01-.249-.25V8.75a1 1 0 00-2 0v2.735a.251.251 0 01-.251.25H6.5a3 3 0 01-3-3 1 1 0 00-2 0v.028a5.006 5.006 0 005 4.972h.752zM2.25 5.75v-.625l.607 1.011a.75.75 0 001.286-.771L3.18 3.76A1.993 1.993 0 002.25 0H1.5a.75.75 0 00-.75.75v5a.75.75 0 001.5 0zM2.75 2a.25.25 0 11-.25-.25.25.25 0 01.25.25zM10.75 19.7a2.2 2.2 0 00-4.292-.7.75.75 0 101.422.475.7.7 0 011.37.222.578.578 0 01-.167.4L6.47 22.72A.75.75 0 007 24h3a.75.75 0 000-1.5h-.586a.25.25 0 01-.177-.427l.907-.906a2.085 2.085 0 00.606-1.467zM14.753 5H14a.25.25 0 01-.25-.25.75.75 0 00-1.5 0A1.753 1.753 0 0014 6.5h.756a2 2 0 001.555-3.25A2 2 0 0014.759 0H14a1.753 1.753 0 00-1.75 1.75.75.75 0 001.5 0A.25.25 0 0114 1.5h.756a.5.5 0 010 1 .75.75 0 100 1.5.5.5 0 010 1zM6.95 2.35l.4-.3a.25.25 0 01.4.2v2.5A.25.25 0 017.5 5a.75.75 0 000 1.5h2a.75.75 0 000-1.5.25.25 0 01-.25-.25v-3.5a1.25 1.25 0 00-2-1l-1.2.9a.75.75 0 10.9 1.2zM21.069 2.5c-.819-.075-.819-.108-.819-.5v-.25a.25.25 0 01.25-.25h2a.75.75 0 000-1.5h-3a.75.75 0 00-.75.75V2c0 1.8 1.473 1.931 2.181 2 .819.076.819.108.819.5a.5.5 0 01-.5.5H19.5a.75.75 0 000 1.5h1.75a2 2 0 002-2c0-1.795-1.473-1.931-2.181-2zM20 19.5a.182.182 0 01-.092-.339A1.161 1.161 0 0120.5 19a.75.75 0 000-1.5 2.752 2.752 0 00-2.75 2.75v1.5A2.25 2.25 0 1020 19.5zm0 3a.75.75 0 11.75-.75.75.75 0 01-.75.75zM15.168 17.588a6.265 6.265 0 00-3.395 4.535.751.751 0 00.74.877H14.5a.25.25 0 01.25.25.75.75 0 001.5 0v-4.99a.749.749 0 00-1.082-.672zm-.859 2.535a.25.25 0 01.441.161v.966a.25.25 0 01-.25.25h-.627a.25.25 0 01-.212-.117.247.247 0 01-.013-.241 4.786 4.786 0 01.661-1.019z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});