define("ember-svg-jar/inlined/squeeze-sides", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>squeeze-sides</title><path d=\"M23.368 21.26l-5.447-.872a.5.5 0 01-.421-.494V9a.8.8 0 01.8-.75c.462 0 .95.386.95.75v2a3.552 3.552 0 003.5 3.75h.5a.75.75 0 000-1.5h-.5a2.043 2.043 0 01-2-2.25V9a2.425 2.425 0 00-2.45-2.25 2.271 2.271 0 00-.8.146V5.5A2.5 2.5 0 0015 3H9a2.5 2.5 0 00-2.5 2.5v1.75h-1a2.249 2.249 0 00-2.018 3.244A2.247 2.247 0 002.825 14a2.247 2.247 0 00.657 3.506A2.249 2.249 0 005.5 20.75h1.013A2.5 2.5 0 009 23h6a2.5 2.5 0 002.145-1.217c6.548 1.047 6.038.967 6.106.967a.75.75 0 00.117-1.49zM3.75 12.5a.751.751 0 01.75-.75h3a.75.75 0 010 1.5h-3a.751.751 0 01-.75-.75zm12.25 5a1 1 0 01-1 1H9.75a2.24 2.24 0 00-.575-1.5 2.244 2.244 0 000-3 2.244 2.244 0 000-3A2.247 2.247 0 008 7.307V6a1 1 0 011-1h6a1 1 0 011 1zM7.5 8.75a.75.75 0 010 1.5h-2a.75.75 0 010-1.5zm-3 6h3a.75.75 0 010 1.5h-3a.75.75 0 010-1.5zm.25 3.75a.751.751 0 01.75-.75h2a.75.75 0 010 1.5h-2a.751.751 0 01-.75-.75zM3 5.5a.506.506 0 00.5.5.5.5 0 00.4-.2l1.5-2a.5.5 0 000-.6l-1.5-2a.5.5 0 00-.9.3v1H1a1 1 0 000 2h2zM20.1 5.8a.5.5 0 00.4.2.506.506 0 00.5-.5v-1h2a1 1 0 000-2h-2v-1a.5.5 0 00-.9-.3l-1.5 2a.5.5 0 000 .6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});