define("ember-svg-jar/inlined/road-sign-left-curve-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-left-curve-alternate</title><path d=\"M15 7.5h-3.536a.5.5 0 00-.353.854l.427.426a.252.252 0 010 .354l-1.806 1.8A2.486 2.486 0 009 12.707V16.5a1 1 0 001 1h2a1 1 0 001-1v-2.965a.5.5 0 01.146-.353l1.22-1.22a.25.25 0 01.354 0l.426.427a.5.5 0 00.854-.354V8.5a1 1 0 00-1-1z\"/><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});