define("ember-svg-jar/inlined/folder-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-search</title><path d=\"M23.69 22.276l-2.505-2.5a.243.243 0 01-.031-.3 5.514 5.514 0 10-1.688 1.688.243.243 0 01.3.031l2.5 2.505a1 1 0 001.414-1.414zm-7.173-2.259a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5z\"/><path d=\"M15.753 9.558a6.965 6.965 0 014.864 1.292.248.248 0 00.4-.194V4.517a1.5 1.5 0 00-1.5-1.5H9.635a.5.5 0 01-.447-.277L8.24.845A1.5 1.5 0 006.9.017H1.517a1.5 1.5 0 00-1.5 1.5v15a1.5 1.5 0 001.5 1.5h7.866a.245.245 0 00.241-.293 6.729 6.729 0 01.371-3.693 7.085 7.085 0 015.758-4.473z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});