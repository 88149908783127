define("ember-svg-jar/inlined/ice-cream-stick-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ice-cream-stick-1</title><path d=\"M12 0a7.271 7.271 0 00-7 7.5V16a1.965 1.965 0 001.923 2h10.154A1.965 1.965 0 0019 16V7.5A7.271 7.271 0 0012 0zm4.476 7.5H7.524a.5.5 0 01-.5-.523A5.219 5.219 0 0112 2a5.219 5.219 0 014.978 4.977.5.5 0 01-.502.523zM12.5 19h-1a.751.751 0 00-.75.75v3a1.25 1.25 0 002.5 0v-3a.751.751 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});