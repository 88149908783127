define("ember-svg-jar/inlined/smiley-lol-alternate-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-lol-alternate-1</title><path d=\"M24 12A12 12 0 10.169 14.007a3 3 0 001.165 3.487 12 12 0 0021.332 0 3 3 0 001.165-3.487A12.071 12.071 0 0024 12zm-12 8.5a6 6 0 01-5.862-4.716.5.5 0 01.758-.528A9.3 9.3 0 0012 16.5a9.3 9.3 0 005.1-1.244.5.5 0 01.758.528A6 6 0 0112 20.5zm7.158-9.737a.749.749 0 01-1.019-.292A1.488 1.488 0 0016.8 9.75a1.49 1.49 0 00-1.345.722.758.758 0 01-1.02.291.75.75 0 01-.292-1.02A3.01 3.01 0 0116.8 8.25a3 3 0 012.65 1.494.749.749 0 01-.292 1.019zM4.845 9.743A3.01 3.01 0 017.5 8.25a3 3 0 012.653 1.494.75.75 0 01-1.312.727A1.488 1.488 0 007.5 9.75a1.492 1.492 0 00-1.345.722.758.758 0 01-1.02.291.749.749 0 01-.29-1.02zm-3.071 4.394a6.709 6.709 0 012.233-1.3.5.5 0 01.693.489 6.693 6.693 0 01-.473 2.541 1.517 1.517 0 01-2.09.364 1.5 1.5 0 01-.363-2.094zm18 1.726a6.693 6.693 0 01-.473-2.541.5.5 0 01.693-.489 6.709 6.709 0 012.233 1.3 1.5 1.5 0 11-2.453 1.726z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});