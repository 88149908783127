define("ember-svg-jar/inlined/flip-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flip-left</title><path d=\"M1 4a1 1 0 001-1v-.5a.5.5 0 01.5-.5H3a1 1 0 000-2H2a2 2 0 00-2 2v1a1 1 0 001 1zM6.5 2H8a1 1 0 000-2H6.5a1 1 0 000 2zM3 22h-.5a.5.5 0 01-.5-.5V21a1 1 0 00-2 0v1a2 2 0 002 2h1a1 1 0 000-2zM8 22H6.5a1 1 0 000 2H8a1 1 0 000-2zM1 13.5a1 1 0 001-1v-1a1 1 0 00-2 0v1a1 1 0 001 1zM1 8.5a1 1 0 001-1v-1a1 1 0 00-2 0v1a1 1 0 001 1zM1 18.5a1 1 0 001-1v-1a1 1 0 00-2 0v1a1 1 0 001 1zM12 13a5.822 5.822 0 00-.784.055.25.25 0 00-.216.245V23a1 1 0 002 0v-9.727a.249.249 0 00-.234-.249Q12.4 13 12 13zM12.734 8.028A.25.25 0 0013 7.779V1a1 1 0 00-2 0v6.781a.25.25 0 00.269.25 9.177 9.177 0 011.465-.003z\"/><path d=\"M8.371 12.811a.251.251 0 01.059-.4A7.472 7.472 0 0112 11.5a9.2 9.2 0 016.228 2 1 1 0 101.308-1.512A11.151 11.151 0 0012 9.5a9.541 9.541 0 00-5.145 1.518.252.252 0 01-.311-.034L5.53 9.97a.75.75 0 00-1.28.53v3.251c0 .828.3 1 1.007 1H8.5a.751.751 0 00.53-1.28z\"/><path d=\"M24 2a2 2 0 00-2-2h-6.5a1 1 0 000 2h6a.5.5 0 01.5.5v19a.5.5 0 01-.5.5H16a1 1 0 000 2h6a2 2 0 002-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});