define("ember-svg-jar/inlined/baggage-cart-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baggage-cart-2</title><circle cx=\"15.84\" cy=\"21.36\" r=\"1.92\"/><path d=\"M5.28 19.44a1.92 1.92 0 101.92 1.92 1.92 1.92 0 00-1.92-1.92zM23.04.72h-.586a1.919 1.919 0 00-1.907 1.7L18.93 16.157a1 1 0 01-.993.883H.96a.96.96 0 000 1.92h17.866a1.919 1.919 0 001.907-1.7l1.7-14.4a.251.251 0 01.248-.221h.364a.96.96 0 100-1.92z\"/><path d=\"M3.84 15.6h11.52a2.4 2.4 0 002.4-2.4V7.44a2.4 2.4 0 00-2.4-2.4h-2.15a.25.25 0 01-.25-.25v-.71a2.4 2.4 0 00-2.4-2.4H8.64a2.4 2.4 0 00-2.4 2.4v.71a.25.25 0 01-.25.25H3.84a2.4 2.4 0 00-2.4 2.4v5.76a2.4 2.4 0 002.4 2.4zM14.4 7.92a.48.48 0 010 .96H4.8a.48.48 0 010-.96zm-9.6 3.84h9.6a.48.48 0 010 .96H4.8a.48.48 0 010-.96zm3.36-7.68a.486.486 0 01.48-.48h1.92a.486.486 0 01.48.48v.71a.25.25 0 01-.25.25H8.41a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});