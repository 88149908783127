define("ember-svg-jar/inlined/brain-network", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>brain-network</title><path d=\"M21.428 9.816a7.263 7.263 0 01-.721-1.451c0-4.772-4-7.8-8.252-8.32a.5.5 0 00-.562.5v3.164a.5.5 0 00.5.5h.75a2.753 2.753 0 012.75 2.75v.446a.5.5 0 00.188.391 1.495 1.495 0 01-.938 2.663 1.494 1.494 0 01-.937-2.659.5.5 0 00.187-.391v-.45a1.251 1.251 0 00-1.25-1.25h-.75a.5.5 0 00-.5.5v5.2a.5.5 0 00.188.391 1.495 1.495 0 01-.938 2.663 1.494 1.494 0 01-.937-2.663.5.5 0 00.187-.391v-5.2a.5.5 0 00-.5-.5h-.75a1.252 1.252 0 00-1.25 1.25v.446a.5.5 0 00.188.391 1.495 1.495 0 01-.938 2.663A1.494 1.494 0 016.206 7.8a.5.5 0 00.187-.391v-.45a2.754 2.754 0 012.75-2.75h.75a.5.5 0 00.5-.5V.548a.5.5 0 00-.563-.5 9.91 9.91 0 00-8.687 9.97c0 3.549 1.307 6.2 3.887 7.885a.249.249 0 01.113.209v5.343a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-3.25a.25.25 0 01.25-.25h1.75a2.5 2.5 0 002.5-2.5v-2.231a.252.252 0 01.074-.177.255.255 0 01.178-.073c.26 0 .547 0 .8-.009a1.247 1.247 0 00.963-.519 1.226 1.226 0 00.19-.744v-.137c.083-1.117-.715-2.515-1.42-3.749z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});