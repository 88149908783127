define("ember-svg-jar/inlined/medical-condition-head-dizziness", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-condition-head-dizziness</title><path d=\"M18.523 14.484C18.523 11.052 15.364 9 12.31 9a6.44 6.44 0 00-6.49 6.528A5.744 5.744 0 008.133 20.5a.5.5 0 01.209.407v2.34a.5.5 0 00.5.5h6.3a.5.5 0 00.5-.5v-.771a.5.5 0 01.5-.5h.576a1.764 1.764 0 001.761-1.761v-.889a.5.5 0 01.479-.5h.009a.972.972 0 00.749-.406 1.075 1.075 0 00.155-.648 5.049 5.049 0 00-.921-2.468 6.333 6.333 0 01-.427-.82zM9.011 2.9a12.676 12.676 0 013.239-.4 11.333 11.333 0 014.123.691 1 1 0 00.752-1.853A13.369 13.369 0 0012.25.5a14.582 14.582 0 00-3.762.468A1 1 0 109.011 2.9zM6.359 5.553A1 1 0 006.8 6.9 12.514 12.514 0 0012.25 8a15.014 15.014 0 003.215-.333 1 1 0 00-.429-1.953A13.221 13.221 0 0112.25 6a10.522 10.522 0 01-4.55-.884 1 1 0 00-1.341.437zM22.75 5.25a1 1 0 00-1-1h-.5a.5.5 0 01-.5-.5v-.5a1 1 0 00-2 0v.5a.5.5 0 01-.5.5h-.5a1 1 0 000 2h.5a.5.5 0 01.5.5v.5a1 1 0 002 0v-.5a.5.5 0 01.5-.5h.5a1 1 0 001-1zM6.25 4.25a1 1 0 000-2h-.5a.5.5 0 01-.5-.5v-.5a1 1 0 00-2 0v.5a.5.5 0 01-.5.5h-.5a1 1 0 000 2h.5a.5.5 0 01.5.5v.5a1 1 0 002 0v-.5a.5.5 0 01.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});