define("ember-svg-jar/inlined/agriculture-machine-tractor-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>agriculture-machine-tractor-1</title><circle cx=\"2\" cy=\"19\" r=\"2\"/><path d=\"M17.882 13H14.1a.5.5 0 01-.49-.6l1.236-6.039a.5.5 0 01.389-.389A1 1 0 0016 5V4a1 1 0 00-1-1H6a1 1 0 00-1 1v1a.994.994 0 00.334.744.5.5 0 01.166.373V10.5a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5V10a1 1 0 00-2 0v.9a.5.5 0 01-.249.433A2.5 2.5 0 000 13.5v2.763a.25.25 0 00.385.21 3 3 0 014.325 1.242.5.5 0 00.452.285H5.5a.5.5 0 00.5-.5 4.5 4.5 0 118.463 2.131.25.25 0 00.22.369h.582a2.485 2.485 0 002.235-1.382l1.724-3.448A1.5 1.5 0 0017.882 13zm-5.127-6.4l-.717 3.5a.5.5 0 01-.49.4h-1.429a.5.5 0 01-.448-.276l-.223-.448A.5.5 0 009 9.5H8a.5.5 0 01-.5-.5V6.5A.5.5 0 018 6h4.265a.5.5 0 01.49.6zM23.779 16.719a.749.749 0 00-1.059 0 7.144 7.144 0 00-.764.928.25.25 0 01-.411 0 7.2 7.2 0 00-.764-.929.75.75 0 00-1.062 1.059A4.4 4.4 0 0121 20.25a.75.75 0 001.5 0 4.4 4.4 0 011.282-2.471.751.751 0 00-.003-1.06z\"/><path d=\"M10.5 14a3.5 3.5 0 103.5 3.5 3.5 3.5 0 00-3.5-3.5zm0 4.5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});