define("ember-svg-jar/inlined/team-meeting-message-question-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>team-meeting-message-question_1</title><path d=\"M19.5 11.5a1.753 1.753 0 001.75-1.75v-8A1.753 1.753 0 0019.5 0h-14a1.753 1.753 0 00-1.75 1.75v8A1.753 1.753 0 005.5 11.5h6a.249.249 0 01.25.25v2a.749.749 0 00.463.693.738.738 0 00.287.057.75.75 0 00.53-.219l2.707-2.71a.251.251 0 01.177-.073zm-4.53-1.281l-1.293 1.291a.25.25 0 01-.427-.176v-.587A.75.75 0 0012.5 10h-7a.251.251 0 01-.25-.25v-8a.251.251 0 01.25-.25h14a.251.251 0 01.25.25v8a.251.251 0 01-.25.25h-4a.75.75 0 00-.53.216z\"/><circle cx=\"4.88\" cy=\"16.249\" r=\"3.25\"/><path d=\"M4.88 19.5a4.969 4.969 0 00-4.867 3.89A.5.5 0 00.5 24h8.76a.5.5 0 00.487-.61A4.969 4.969 0 004.88 19.5z\"/><circle cx=\"19.12\" cy=\"16.249\" r=\"3.25\"/><path d=\"M23.987 23.389a4.99 4.99 0 00-9.734 0 .5.5 0 00.487.61h8.76a.5.5 0 00.487-.61zM12.5 2.5a2.253 2.253 0 00-2.25 2.25.75.75 0 001.5 0 .75.75 0 11.75.75.75.75 0 000 1.5 2.25 2.25 0 100-4.5z\"/><circle cx=\"12.5\" cy=\"8.499\" r=\".75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});