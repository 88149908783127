define("ember-svg-jar/inlined/fast-food-pretzel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fast-food-pretzel</title><path d=\"M24 10.641a8.086 8.086 0 00-11.568-7.3 1 1 0 01-.864 0A8.086 8.086 0 000 10.641 10.01 10.01 0 003.858 18.8 13.167 13.167 0 0012 21.446c5.963 0 12-3.711 12-10.805zM11.792 7.889a.252.252 0 01.416 0 4.928 4.928 0 01.835 2.752 4.669 4.669 0 01-.841 2.427.25.25 0 01-.422 0 4.708 4.708 0 01-.823-2.426 4.928 4.928 0 01.835-2.753zM3.13 10.641a4.962 4.962 0 014.957-4.956 5.075 5.075 0 01.526.027.5.5 0 01.375.756 8.043 8.043 0 00-1.162 4.173 5.76 5.76 0 00.985 3.909.5.5 0 01-.12.693 15.143 15.143 0 01-1.671 1.028 1 1 0 01-.966-.014C4.058 15.118 3.13 13.65 3.13 10.641zM9.5 18.46a.25.25 0 01-.071-.46c.7-.412 1.54-.946 2.074-1.29a1 1 0 011.077 0l2.067 1.31a.25.25 0 01-.074.454A10.583 10.583 0 019.5 18.46zm8.4-2.2a1 1 0 01-.964.012 15.494 15.494 0 01-1.683-1.038.5.5 0 01-.118-.7 5.708 5.708 0 001.038-3.9 8.043 8.043 0 00-1.162-4.173.5.5 0 01.375-.756 5.075 5.075 0 01.526-.027 4.962 4.962 0 014.957 4.956c.001 3.027-.957 4.488-2.969 5.626z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});