define("ember-svg-jar/inlined/multiple-actions-home.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-home</title><path d=\"M16.34 10.089a.5.5 0 00.067-.942 39.359 39.359 0 00-1.936-.749l-.486-.173a1.8 1.8 0 01-.055-1.172 4.643 4.643 0 001.216-3.568A3.22 3.22 0 0012 0a2.989 2.989 0 00-1.035.182.5.5 0 00-.258.725 5.029 5.029 0 01.693 2.578 6.489 6.489 0 01-.773 3.383.5.5 0 00.255.719c1.771.672 3.047 1.282 3.622 2.431.065.13.123.262.177.4a.45.45 0 00.708.178 2.489 2.489 0 01.951-.507z\"/><path d=\"M13.589 12.111a.5.5 0 00.162-.5 5.089 5.089 0 00-.369-1.03c-.454-.907-1.9-1.441-3.911-2.179l-.486-.173a1.8 1.8 0 01-.055-1.176 4.643 4.643 0 001.216-3.568A3.22 3.22 0 007 0a3.22 3.22 0 00-3.146 3.485 4.605 4.605 0 001.193 3.538 1.877 1.877 0 010 1.185l-.515.19c-2.01.738-3.46 1.272-3.914 2.179A7.542 7.542 0 000 13.5a.5.5 0 00.5.5h10.674a.5.5 0 00.323-.119zM17.323 12.118a.5.5 0 00-.647 0l-6.5 5.5a.5.5 0 00.324.882h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-4.75a.25.25 0 01.25-.25h1.25a.5.5 0 00.323-.882z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});