define("ember-svg-jar/inlined/folder-settings-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-settings-alternate</title><circle cx=\"17.748\" cy=\"17.031\" r=\"1.5\"/><path d=\"M22.528 17.344a.463.463 0 010-.625l.988-1.065a1.782 1.782 0 00-1.7-2.953l-1.411.326a.452.452 0 01-.533-.31l-.424-1.393a1.775 1.775 0 00-3.4 0l-.424 1.393a.452.452 0 01-.533.31l-1.41-.327a1.782 1.782 0 00-1.7 2.952l.988 1.066a.463.463 0 010 .625l-.988 1.064a1.782 1.782 0 001.7 2.953l1.411-.326a.454.454 0 01.533.311l.424 1.391a1.774 1.774 0 003.4 0l.424-1.392a.452.452 0 01.533-.311l1.411.326a1.782 1.782 0 001.7-2.952zm-4.78 2.687a3 3 0 113-3 3 3 0 01-3 3z\"/><path d=\"M10.745 16.5H2.5A.5.5 0 012 16V2.5a.5.5 0 01.5-.5h4.573a.5.5 0 01.447.276L8.33 3.9A2 2 0 0010.118 5H19a.5.5 0 01.5.5v3.45a.243.243 0 00.107.2 3.215 3.215 0 011.277 1.741.318.318 0 00.616.005V5a2 2 0 00-2-2h-9.073a.5.5 0 01-.447-.276L9.17 1.1A2 2 0 007.382 0H2a2 2 0 00-2 2v14.5a2 2 0 002 2h8.047a.243.243 0 00.224-.152 2.9 2.9 0 01.612-.959c.423-.472.148-.889-.138-.889z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});