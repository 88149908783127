define("ember-svg-jar/inlined/user-logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>user-logout</title><path d=\"M4.951 8.277A5.667 5.667 0 000 14v3.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3.5a5.506 5.506 0 00-6.049-5.473z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M23.561 7.19l-3-3a1.5 1.5 0 00-2.122 2.121l.44.439H13.5a1.5 1.5 0 000 3h5.379l-.44.44a1.5 1.5 0 000 2.121 1.519 1.519 0 002.122 0l3-3a1.5 1.5 0 000-2.121z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});