define("ember-svg-jar/inlined/room-service-cart-food", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>room-service-cart-food</title><path d=\"M7 9.5h14A.75.75 0 0021 8h-.879a.5.5 0 01-.49-.4 5.743 5.743 0 00-11.257 0 .5.5 0 01-.49.4H7a.75.75 0 000 1.5z\"/><circle cx=\"14.002\" cy=\"1\" r=\"1\"/><path d=\"M21.5 11h-17a1.252 1.252 0 01-1.25-1.249v-.5A2.547 2.547 0 00.75 6.5a.75.75 0 000 1.5c.691 0 1 .628 1 1.25v.5a2.75 2.75 0 001.9 2.611.5.5 0 01.35.476V19a1.5 1.5 0 001.5 1.5h2.25a.5.5 0 00.5-.5v-4a.75.75 0 011.5 0v4a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-4a.75.75 0 011.5 0v4a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-4a.75.75 0 011.5 0v4a.5.5 0 00.5.5h2.25A1.5 1.5 0 0024 19v-5.5a2.5 2.5 0 00-2.5-2.5zM9.914 22H7.085a.5.5 0 00-.472.667 2 2 0 003.773 0A.5.5 0 009.914 22zM20.914 22h-2.829a.5.5 0 00-.472.667 2 2 0 003.773 0 .5.5 0 00-.472-.667z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});