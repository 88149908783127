define("ember-svg-jar/inlined/hedgehog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hedgehog</title><path d=\"M22.637 11.629h-4.974a4.519 4.519 0 00-.681-.8l4.634-1.711a1 1 0 00-.693-1.877l-6.509 2.4a4.665 4.665 0 00-.519-.032l3.655-2.962a1 1 0 00-1.26-1.553l-5.783 4.694a.993.993 0 00-.123.119l-2.037.172 5.059-5A1 1 0 0012 3.658l-7.257 7.169a7.081 7.081 0 00-2.673 1.752 21.141 21.141 0 00-1.6 2 1.018 1.018 0 000 .917c.563 1.15 3.256 1.8 4.385 2.027a5.607 5.607 0 001.658.108l.9.3v1.7a1 1 0 002 0v-1.068a11.98 11.98 0 002.675.307 10.24 10.24 0 003.042-.541v1.3a1 1 0 102 0v-2.494a4.609 4.609 0 00.586-.718l3.062 1.149a1 1 0 10.7-1.873L18.4 14.534a4.123 4.123 0 00-.006-.905h4.245a1 1 0 000-2zM4.386 12.87a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});