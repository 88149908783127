define("ember-svg-jar/inlined/photography-equipment-film-print", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>photography-equipment-film-print</title><path d=\"M23 0H1a1 1 0 00-1 1v22a1 1 0 001 1h22a1 1 0 001-1V1a1 1 0 00-1-1zM4.75 13.5A.729.729 0 015 13h14a.25.25 0 01.25.25v8a.25.25 0 01-.25.25H5a.25.25 0 01-.25-.25zm14.5-3a.25.25 0 01-.25.25H5a.25.25 0 01-.25-.25V2.75A.25.25 0 015 2.5h14a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});