define("ember-svg-jar/inlined/party-dance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>party-dance</title><circle cx=\"9.75\" cy=\"4\" r=\"2.5\"/><path d=\"M18.866 6.084c-.141-.192-.479-.237-.789-.291V5.8a1.481 1.481 0 00-1.248-.8l-.026-.034-1.553-2.149a.5.5 0 00-.7-.108l-.806.592a.5.5 0 00-.107.7l1.52 2.1-.462.591a1.493 1.493 0 01-.893.592L7.987 8.577A4.512 4.512 0 004.5 12.412l-.237 1.9a1.5 1.5 0 002.976.372l.238-1.9a1.507 1.507 0 011.163-1.28l.5-.112a.5.5 0 01.6.4l.857 4.987a1.517 1.517 0 01-.136.933l-2.053 4.112a1.5 1.5 0 002.684 1.341l2.056-4.107a4.523 4.523 0 00.43-2.558h.672a1.5 1.5 0 011.385.923l1.73 4.154a1.5 1.5 0 002.77-1.154L18.4 16.269a4.489 4.489 0 00-4.15-2.769h-.75a.5.5 0 01-.493-.416l-.338-1.984a.5.5 0 01.384-.573l1.4-.31a8.061 8.061 0 001.838-.88l1.683 2.294a1 1 0 001.4.214l1.613-1.183a1 1 0 00.214-1.4zM4 3h.75a.75.75 0 000-1.5H4V.75a.75.75 0 00-1.5 0v.75h-.75a.75.75 0 000 1.5h.75v.75a.75.75 0 001.5 0zM22.25 1.5h-.75V.75a.75.75 0 00-1.5 0v.75h-.75a.75.75 0 000 1.5H20v.75a.75.75 0 001.5 0V3h.75a.75.75 0 000-1.5z\"/><circle cx=\"4.75\" cy=\"6.25\" r=\".75\"/><circle cx=\"13.25\" cy=\".75\" r=\".75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});