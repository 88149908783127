define("ember-svg-jar/inlined/love-heart-keyhole", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-heart-keyhole</title><path d=\"M23.288 5.077a6.567 6.567 0 00-10.43-1.71l-.521.474a.5.5 0 01-.674 0l-.49-.447A6.484 6.484 0 005.5 1.588 6.481 6.481 0 00.711 5.077a6.4 6.4 0 001.214 7.468l9.358 9.655a1 1 0 001.435 0l9.342-9.64a6.413 6.413 0 001.228-7.483zM13.25 12.914a.5.5 0 00-.249.433v2.405a1 1 0 11-2 0v-2.405a.5.5 0 00-.25-.433 2.5 2.5 0 112.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});