define("ember-svg-jar/inlined/cog-square-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cog-square-alternate</title><path d=\"M19.174 0H4.826A4.832 4.832 0 000 4.826v14.348A4.832 4.832 0 004.826 24h14.348A4.832 4.832 0 0024 19.174V4.826A4.832 4.832 0 0019.174 0zM22 19.174A2.83 2.83 0 0119.174 22H4.826A2.83 2.83 0 012 19.174V4.826A2.83 2.83 0 014.826 2h14.348A2.83 2.83 0 0122 4.826z\"/><circle cx=\"12\" cy=\"12\" r=\"1.75\"/><path d=\"M19.347 8.747l-.856-1.483a1.141 1.141 0 00-1.559-.418l-.781.451A6.293 6.293 0 0014 6.05v-.9a1.141 1.141 0 00-1.142-1.141h-1.714A1.141 1.141 0 0010 5.152v.906A6.231 6.231 0 007.85 7.3l-.782-.451a1.141 1.141 0 00-1.559.418l-.856 1.48a1.141 1.141 0 00.418 1.559l.78.451a6.23 6.23 0 000 2.486l-.782.451a1.141 1.141 0 00-.418 1.559l.856 1.483a1.141 1.141 0 001.559.418l.781-.45A6.335 6.335 0 0010 17.95v.9a1.141 1.141 0 001.142 1.141h1.711A1.141 1.141 0 0014 18.848v-.906a6.235 6.235 0 002.15-1.242l.782.451a1.141 1.141 0 001.559-.418l.856-1.483a1.142 1.142 0 00-.418-1.559l-.78-.45a6.217 6.217 0 000-2.487l.781-.451a1.142 1.142 0 00.417-1.556zM12 15.25A3.25 3.25 0 1115.25 12 3.254 3.254 0 0112 15.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});