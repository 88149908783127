define("ember-svg-jar/inlined/single-man-actions-key.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-key</title><path d=\"M7.062 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.743a.5.5 0 01.741-.3A6.956 6.956 0 008.521 5a6.346 6.346 0 00.928-.07.5.5 0 01.568.565A3 3 0 014.062 5a2.8 2.8 0 01.1-.743zM12.947 14.134a.5.5 0 00.188-.767A7.517 7.517 0 007.268 10.5a7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h9.444a.5.5 0 00.417-.224 5.877 5.877 0 012.524-2.142zM23.938 11.631a.5.5 0 00-.5-.5H21.46a.5.5 0 00-.343.136l-4.189 3.957a.253.253 0 01-.245.057A4.456 4.456 0 1015.4 24a4.454 4.454 0 004.261-5.731.248.248 0 01.063-.249l4.066-4.057a.5.5 0 00.147-.355zm-7.164 8.989a1.736 1.736 0 110-2.456 1.737 1.737 0 010 2.456z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});