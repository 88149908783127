define("ember-svg-jar/inlined/content-pencil-quill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-pencil-quill</title><path d=\"M11.475 6.1a.254.254 0 00-.032-.385A53.548 53.548 0 00.676.033a.5.5 0 00-.644.643 53.55 53.55 0 005.683 10.767.253.253 0 00.384.032zM19.621 18.206c.505-1.079.519-2.843-1.346-5.62a.259.259 0 00-.392-.042l-5.339 5.339a.249.249 0 00.032.383c1.569 1.06 3.776 2.217 5.629 1.353l4.088 4.088a1.009 1.009 0 001.414 0 1 1 0 000-1.414zM20.869 7.437a.505.505 0 000-.717l-3.588-3.588a.508.508 0 00-.717 0L2.3 17.4a.508.508 0 000 .718L5.887 21.7a.508.508 0 00.717 0zM18 2.413L21.587 6a.506.506 0 00.652.055c.023-.016.869-.858.869-.858A3.045 3.045 0 0018.8.892s-.842.847-.858.87a.506.506 0 00.058.651zM4.917 22.12l-3.038-3.038a.506.506 0 00-.851.237L.016 23.368a.507.507 0 00.615.616l4.05-1.013a.489.489 0 00.232-.128.509.509 0 00.004-.723z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});