define("ember-svg-jar/inlined/recycling-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>recycling-sign</title><path d=\"M10 16H6.522a.25.25 0 01-.2-.4c.223-.292.472-.6.751-.925a.5.5 0 01.379-.174H9a.5.5 0 00.416-.777l-3-4.5A.5.5 0 006 9H.5a.5.5 0 00-.353.854l.809.809a.5.5 0 01.058.636A2.951 2.951 0 00.5 13c0 1.883 3.241 7.579 3.565 7.926A2.874 2.874 0 006 22h4a2 2 0 002-2v-2a2 2 0 00-2-2zm.5 4a.5.5 0 01-.5.5H5.169c-.246 0-.346-.241-.485-.461a2.9 2.9 0 01.285-2.239.5.5 0 01.458-.3H10a.5.5 0 01.5.5zM23.7 11.977l-2.3-3.328a2 2 0 00-2.783-.507l-1.8 1.247a2 2 0 00-.507 2.783l1.787 2.582a.25.25 0 01-.223.392c-.136-.01-.275-.022-.418-.036a.5.5 0 01-.452-.5V14a.5.5 0 00-.916-.277l-3 4.5a.5.5 0 00-.019.522l2.814 5a.5.5 0 00.934-.206l.086-1.085a.5.5 0 01.536-.454 2.352 2.352 0 001.578-.434c1.205-.833 4.749-7.371 4.812-7.554a2.319 2.319 0 00-.129-2.035zm-.974 1.977a3.451 3.451 0 01-2.248 1.1.5.5 0 01-.5-.208l-2.442-3.531a.5.5 0 01.127-.7l1.8-1.247a.5.5 0 01.7.126l2.569 3.715a.641.641 0 01-.009.745zM5.4 7.564l2.055.97a2 2 0 002.662-.955l1.236-2.62a.251.251 0 01.221-.143.248.248 0 01.226.134q.192.368.386.782a.5.5 0 01-.118.582l-.9.816A.5.5 0 0011.5 8H17a.5.5 0 00.44-.262l3-5.545a.5.5 0 00-.613-.707l-1.072.4a.5.5 0 01-.608-.224A2.768 2.768 0 0016.879.5C16.491.321 15.564.07 12 0H7.989a2.288 2.288 0 00-1.8 1.192L4.442 4.9A2 2 0 005.4 7.564zm.4-2.021l1.945-4.12a.568.568 0 01.67-.3 5.221 5.221 0 012 1.657.5.5 0 01.063.527L8.757 6.939a.494.494 0 01-.283.257.5.5 0 01-.382-.018l-2.055-.969a.5.5 0 01-.237-.666z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});