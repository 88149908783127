define("ember-svg-jar/inlined/fitness-dumbbell-lift", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fitness-dumbbell-lift</title><path d=\"M22.5 0h-2A1.5 1.5 0 0019 1.5v3a.5.5 0 01-.5.5.5.5 0 00-.5.5v3a.5.5 0 00.5.5.5.5 0 01.5.5v3a1.5 1.5 0 001.5 1.5h2a1.5 1.5 0 001.5-1.5v-11A1.5 1.5 0 0022.5 0zM7 5.5a.5.5 0 00-.5-.5h-1a.5.5 0 01-.5-.5v-3A1.5 1.5 0 003.5 0h-2A1.5 1.5 0 000 1.5v11A1.5 1.5 0 001.5 14h2A1.5 1.5 0 005 12.5v-3a.5.5 0 01.5-.5H6a1 1 0 001-1z\"/><path d=\"M14.5 2H8.442a2 2 0 00-1.357.53 2.1 2.1 0 00-.461.64.25.25 0 00.188.354A2 2 0 018.5 5.5v3a2 2 0 01-1.574 1.954.253.253 0 00-.182.161.25.25 0 00.04.24l1.246 1.557a2 2 0 01.436 1.34l-.442 9.725a.5.5 0 00.5.523h6.926a.5.5 0 00.5-.547l-.894-9.382a2 2 0 01.2-1.084l1.032-2.065a2 2 0 00.211-.894V4A2 2 0 0014.5 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});