define("ember-svg-jar/inlined/beacon-remote-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beacon-remote-house</title><path d=\"M23.563 7.674l-11-7.5a1 1 0 00-1.126 0l-11 7.5a1 1 0 101.126 1.652L2 9.028V17.5a1 1 0 002 0V7.8a.252.252 0 01.109-.207l7.75-5.284a.25.25 0 01.282 0l7.75 5.284A.252.252 0 0120 7.8v9.7a1 1 0 002 0V9.028l.437.3a1 1 0 001.126-1.652z\"/><path d=\"M16.949 8.464a1 1 0 000-1.414 7 7 0 00-9.9 0 1 1 0 001.416 1.414 5 5 0 017.07 0 1 1 0 001.414 0z\"/><path d=\"M12 8.5a4.966 4.966 0 00-3.535 1.464 1 1 0 000 1.414 1.067 1.067 0 001.414 0c2.027-2.026 4.328.061 4.425.122a.989.989 0 001.231-1.536A4.966 4.966 0 0012 8.5zM17.5 14.5A1.5 1.5 0 0016 13H8a1.5 1.5 0 00-1.5 1.5v8A1.5 1.5 0 008 24h8a1.5 1.5 0 001.5-1.5v-8zm-4.659 5.772a.248.248 0 00-.091.193v1.285a.75.75 0 01-1.5 0v-1.277a.251.251 0 00-.091-.193 1.277 1.277 0 01-.492-.989 1.333 1.333 0 112.666 0 1.267 1.267 0 01-.492.981zm2.409-.772a.75.75 0 01-.75-.75 2.5 2.5 0 00-5 0 .75.75 0 01-1.5 0 4 4 0 018 0 .75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});