define("ember-svg-jar/inlined/landmark-rialto-bridge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-rialto-bridge</title><path d=\"M23.683 8.034L15.159 4.7A.25.25 0 0115 4.466V3.5a.5.5 0 00-.243-.429l-2.5-1.5a.5.5 0 00-.514 0l-2.5 1.5A.5.5 0 009 3.5v.966a.25.25 0 01-.159.233L.317 8.034A.5.5 0 000 8.5V14a.5.5 0 00.683.466l1.158-.454A.249.249 0 002 13.78V11a1 1 0 012 0v1.8a.25.25 0 00.341.233l1.5-.587A.25.25 0 006 12.214V9.5a1 1 0 012 0v1.736a.251.251 0 00.341.233l1.5-.587a.251.251 0 00.159-.233V7.5a2 2 0 014 0v3.149a.251.251 0 00.159.233l1.5.587a.25.25 0 00.341-.233V9.5a1 1 0 012 0v2.714a.25.25 0 00.159.233l1.5.587A.25.25 0 0020 12.8V11a1 1 0 012 0v2.78a.249.249 0 00.159.232l1.158.454A.5.5 0 0024 14V8.5a.5.5 0 00-.317-.466z\"/><path d=\"M23.683 15.534l-11.5-4.5a.5.5 0 00-.366 0l-11.5 4.5A.5.5 0 000 16v3.356a.25.25 0 00.336.235 1.941 1.941 0 01.932-.1c.844.12 1.889-.835 2.269-1.312a2.027 2.027 0 011.575-.768 2 2 0 011.713.96 3.111 3.111 0 00.79.756.25.25 0 00.385-.21V18a4 4 0 018 0v1.119a.25.25 0 00.33.237 2.485 2.485 0 001.209-.956 2.063 2.063 0 011.727-.99 2 2 0 011.709.955l.008.013c.068.1.852 1.192 1.744 1.071a1.939 1.939 0 01.937.1.25.25 0 00.336-.232V16a.5.5 0 00-.317-.466z\"/><path d=\"M22.864 20.44c-1.618.231-2.728-1.536-2.737-1.55a1.048 1.048 0 00-1.726.022 3.275 3.275 0 01-2.672 1.553 2.731 2.731 0 01-2.329-1.477 1.045 1.045 0 00-1.723-.16 4.173 4.173 0 01-3.021 1.637 3.52 3.52 0 01-2.682-1.575 1.045 1.045 0 00-1.649-.09c-.014.02-1.5 1.908-3.19 1.685a1 1 0 10-.27 1.981 4.013 4.013 0 00.552.038 5.539 5.539 0 003.478-1.448.25.25 0 01.331.006 5.038 5.038 0 003.426 1.407 5.623 5.623 0 003.554-1.343.249.249 0 01.334.015 4.432 4.432 0 003.189 1.328 4.978 4.978 0 003.378-1.327.25.25 0 01.336 0 4.488 4.488 0 003.693 1.281 1 1 0 10-.272-1.981z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});