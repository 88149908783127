define("ember-svg-jar/inlined/livestock-sheep", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>livestock-sheep</title><path d=\"M23.874 14.174l-2.105-3.159a.253.253 0 00-.262-.105.248.248 0 00-.193.206 29.858 29.858 0 01-1.4 5.352.254.254 0 00.022.215.25.25 0 00.183.118 4.291 4.291 0 002.052-.211 3.059 3.059 0 001.793-1.763.751.751 0 00-.09-.653zM12 .59c-5.124 0-8 4.5-8 8.5 0 3.679 2.867 12.858 6.916 14.31a.25.25 0 00.334-.236V20.59a.107.107 0 00-.03-.074l-1.074-1.073a.5.5 0 01.354-.853h3a.5.5 0 01.354.853l-1.074 1.073a.107.107 0 00-.03.074v2.57a.25.25 0 00.334.236C17.133 21.948 20 12.769 20 9.09c0-4-2.876-8.5-8-8.5zM8.806 11.84a1.25 1.25 0 111.25-1.25 1.249 1.249 0 01-1.25 1.25zm6.5 0a1.25 1.25 0 111.25-1.25 1.249 1.249 0 01-1.25 1.25zM.126 14.174a.751.751 0 00-.088.653 3.059 3.059 0 001.793 1.763 4.291 4.291 0 002.052.211.25.25 0 00.205-.333 29.858 29.858 0 01-1.4-5.352.248.248 0 00-.193-.206.253.253 0 00-.262.105z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});