define("ember-svg-jar/inlined/currency-euro-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-euro-bubble</title><path d=\"M21.526 8.25a.249.249 0 01-.134-.324 4.079 4.079 0 00-5.3-5.3.249.249 0 01-.324-.134 4.1 4.1 0 00-7.53 0 .249.249 0 01-.324.134 4.079 4.079 0 00-5.3 5.3.25.25 0 01-.134.325 4.077 4.077 0 000 7.5.25.25 0 01.134.325 4.079 4.079 0 005.3 5.3.249.249 0 01.324.134 4.1 4.1 0 007.53 0 .249.249 0 01.324-.134 4.079 4.079 0 005.3-5.3.25.25 0 01.134-.325 4.078 4.078 0 000-7.501zm-8 .756a1 1 0 110 2H9.812a.251.251 0 00-.249.225c-.022.226-.037.46-.037.714 0 .118.008.226.018.332a.249.249 0 00.249.228h2.691a1 1 0 110 2h-1.947a.25.25 0 00-.2.4c.819 1.066 2.307 1.535 4.68 1.535a1 1 0 110 2c-1.544 0-5.7-.015-7.051-3.769a.248.248 0 00-.235-.165h-.74a1 1 0 110-2h.3a.252.252 0 00.249-.268 3.888 3.888 0 01-.012-.292c0-.234.011-.455.028-.669a.248.248 0 00-.065-.189.253.253 0 00-.183-.088h-.279a1 1 0 110-2h.841a.249.249 0 00.229-.15c1.477-3.4 5.426-3.406 6.92-3.406a1 1 0 110 2c-2.047 0-3.439.346-4.311 1.123a.25.25 0 00.167.436z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});