define("ember-svg-jar/inlined/shop-barista", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shop-barista</title><path d=\"M22.25 18H1.706A1.65 1.65 0 000 19.583v3.667a.75.75 0 00.75.75h22.456a.75.75 0 00.75-.75v-3.667A1.65 1.65 0 0022.25 18zM2 16.5h8a.5.5 0 00.5-.5v-4.5A2.5 2.5 0 008 9H5.25A.25.25 0 015 8.75v-.5A.25.25 0 015.25 8h.25a1 1 0 001-1V6a1 1 0 00-1-1h-3a1 1 0 00-1 1v1a1 1 0 001 1h.25a.25.25 0 01.25.25v.821a.219.219 0 01-.121.2A2.5 2.5 0 001.5 11.5V16a.5.5 0 00.5.5zM5 11h2a.5.5 0 010 1H5a.5.5 0 010-1zM17.505 8.5a6.486 6.486 0 00-5.452 2.954 1 1 0 101.676 1.092.146.146 0 01.165-.061.148.148 0 01.106.142V15.5a1 1 0 001 1h5a1 1 0 001-1V13a.25.25 0 01.47-.118A4.471 4.471 0 0122 15v.5a1 1 0 002 0V15a6.505 6.505 0 00-6.495-6.5zM18 13h-1a1 1 0 01-1-1v-1.063a.25.25 0 01.177-.238 4.511 4.511 0 012.647 0 .25.25 0 01.176.239V12a1 1 0 01-1 1z\"/><path d=\"M12 3.75h.951a.25.25 0 01.25.264c0 .078-.007.157-.007.236a4.254 4.254 0 10.571-2.125.252.252 0 01-.217.125H12a.75.75 0 000 1.5zm5.444 2.75a2.253 2.253 0 01-2.25-2.25 2.371 2.371 0 01.018-.282.249.249 0 01.248-.218h3.968a.249.249 0 01.248.218 2.178 2.178 0 01.018.282 2.252 2.252 0 01-2.25 2.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});