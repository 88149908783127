define("ember-svg-jar/inlined/outdoors-camp-fire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-camp-fire</title><path d=\"M12 17a6.7 6.7 0 006.7-6.7 6.924 6.924 0 00-3.538-6.077.239.239 0 00-.346.161 5.32 5.32 0 01-1.2 2.39.25.25 0 01-.361 0A4.66 4.66 0 0112 3.6 4.576 4.576 0 0113.552.414a.239.239 0 00-.228-.4A10.955 10.955 0 005.3 10.3 6.7 6.7 0 0012 17zM22.958 16.713a1 1 0 00-1.245-.671l-20 6A1 1 0 002 24a1.023 1.023 0 00.287-.042l20-6a1 1 0 00.671-1.245zM1.684 17.948l4.5 1.5a.973.973 0 00.316.052 1 1 0 00.316-1.949l-4.5-1.5a1 1 0 10-.632 1.9zM22.316 22.051l-4.5-1.5a1 1 0 10-.632 1.9l4.5 1.5A.973.973 0 0022 24a1 1 0 00.316-1.949z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});