define("ember-svg-jar/inlined/audio-book-music", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-book-music</title><path d=\"M12.75 9.541a1.752 1.752 0 001.75-1.75V2.87c0-.12.086-.224.295-.269L18.2 1.5a.253.253 0 01.207.054.245.245 0 01.09.192v2.045a.25.25 0 01-.25.25A1.75 1.75 0 1020 5.791v-4.04A1.75 1.75 0 0017.711.086l-3.5 1.133A1.75 1.75 0 0013 2.884v2.907a.25.25 0 01-.25.25 1.75 1.75 0 000 3.5zM5.93 12.951A1.752 1.752 0 007.68 11.2V7.288a.25.25 0 01.381-.213l.792.486a.75.75 0 10.785-1.278L7.322 4.862A.75.75 0 006.18 5.5v3.7a.25.25 0 01-.25.25 1.75 1.75 0 000 3.5z\"/><path d=\"M23.549 6.439a1.478 1.478 0 00-1.083-.433 1 1 0 00-.724 1.67 1 1 0 01.258.671v9.691a.5.5 0 01-.475.5 18.324 18.324 0 00-7.79 1.895.5.5 0 01-.735-.442V13a1 1 0 10-2 0v6.99a.5.5 0 01-.735.441 18.319 18.319 0 00-7.789-1.895.5.5 0 01-.476-.5v-9.5a.5.5 0 01.528-.5c.353.019.7.045 1.038.078a1 1 0 10.194-1.99 27.831 27.831 0 00-2.226-.125A1.509 1.509 0 000 7.51v11.5a1.508 1.508 0 001.482 1.495c4.681.078 7.32 1.284 8.629 2.163a6.015 6.015 0 011.143 1 1 1 0 001.493 0 6.009 6.009 0 011.142-1c1.31-.879 3.948-2.085 8.629-2.163A1.508 1.508 0 0024 19.01V7.51a1.493 1.493 0 00-.451-1.071z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});