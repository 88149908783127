define("ember-svg-jar/inlined/coffee-pot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>coffee-pot</title><path d=\"M7.842 12h11.129a.248.248 0 00.193-.4L14.2 5.482a.257.257 0 01.071-.392 1.987 1.987 0 10-1.723 0 .257.257 0 01.072.392L7.649 11.6a.249.249 0 00.193.4z\"/><path d=\"M22.076 13.444c-.067.021-13.168.042-15.3.045a.247.247 0 00-.242.289s.986 5.911 1.021 6.131-.131.284-.294.284h-.902a2.61 2.61 0 01-2.281-2.572l-.813-7.516a2.036 2.036 0 00-.725-1.364L1.615 8A.993.993 0 00.37 9.546s.7.593.781.656a.444.444 0 01.16.3l.789 7.332a4.569 4.569 0 004.256 4.345s1.088-.017 1.575-.032a.627.627 0 01.336.105 1.446 1.446 0 001.012.424h8.249A1.491 1.491 0 0019 21.431l1-6a.249.249 0 01.245-.207h.777a.993.993 0 01.993.993v4.473a.993.993 0 101.986 0v-4.469a2.977 2.977 0 00-1.925-2.777z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});