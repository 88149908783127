define("ember-svg-jar/inlined/car-wagon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-wagon</title><path d=\"M23.493 9.38l-.91-1.456a4.331 4.331 0 00-3.7-2.049H11.3a2.994 2.994 0 00-2.5 1.336L7.1 9.764a.253.253 0 01-.208.111H2.575a2.588 2.588 0 00-2.295 1.4.251.251 0 00.222.366H2.73a.5.5 0 110 1H.27a.249.249 0 00-.246.293 2.575 2.575 0 00.52 1.166l.969 1.211A2.781 2.781 0 003.5 16.342a.217.217 0 00.234-.217 3 3 0 016 0 .255.255 0 00.066.175.251.251 0 00.177.073h5.163a.25.25 0 00.25-.25 3 3 0 016 0 .231.231 0 00.264.229 1.974 1.974 0 001.662-1.562l.607-3.039a3.71 3.71 0 00-.43-2.371zm-11.263.245a.25.25 0 01-.25.25H9.893a.25.25 0 01-.208-.389l.777-1.166a1 1 0 01.833-.445h.685a.25.25 0 01.25.25zm4.92.142a.25.25 0 01-.206.108h-2.551a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h1.989a.251.251 0 01.234.162l.562 1.5a.251.251 0 01-.028.23z\"/><circle cx=\"6.73\" cy=\"16.125\" r=\"2\"/><circle cx=\"18.393\" cy=\"16.125\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});