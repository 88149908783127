define("ember-svg-jar/inlined/single-woman-focus.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-focus</title><path d=\"M7.7 22l-5.2.039a.5.5 0 01-.5-.5v-5.234a1 1 0 00-2 0v5.738A1.959 1.959 0 001.956 24H7.7a1 1 0 000-2zM1 8.7a1 1 0 001-1l-.04-5.2a.5.5 0 01.5-.5H7.7a1 1 0 000-2H1.956A1.959 1.959 0 000 1.957V7.7a1 1 0 001 1zM23 15.305a1 1 0 00-1 1l.04 5.191a.5.5 0 01-.5.5h-5.235a1 1 0 000 2h5.739A1.959 1.959 0 0024 22.043v-5.738a1 1 0 00-1-1zM22.044 0h-5.739a1 1 0 000 2l5.195-.039a.5.5 0 01.5.5V7.7a1 1 0 002 0V1.957A1.959 1.959 0 0022.044 0zM18.426 18.9a.314.314 0 00.073-.229 6.4 6.4 0 00-.549-2.107c-.4-.846-2.068-1.432-4.163-2.254a.6.6 0 01-.37-.563v-.314a.25.25 0 01.244-.25 4.3 4.3 0 002.671-.68.3.3 0 00.131-.213.317.317 0 00-.071-.244c-.357-.414-.6-.914-.709-2.559C15.5 6.538 13.615 5.5 12 5.5S8.506 6.538 8.317 9.489c-.105 1.645-.352 2.145-.709 2.559a.316.316 0 00-.071.243.3.3 0 00.132.214 4.311 4.311 0 002.662.7.249.249 0 01.252.25v.3a.6.6 0 01-.37.563c-2.095.822-3.764 1.408-4.163 2.254a6.4 6.4 0 00-.55 2.101.309.309 0 00.073.229.276.276 0 00.21.1h12.433a.276.276 0 00.21-.102z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});