define("ember-svg-jar/inlined/christmas-tree-ornaments-gift", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-tree-ornaments-gift</title><path d=\"M16.2 4.4A.5.5 0 0116 4v-.629a.249.249 0 01.3-.244 23.294 23.294 0 003.234.482.5.5 0 01.463.5V5a.5.5 0 01-.2.4 2 2 0 102.4 0A.5.5 0 0122 5v-.923a.5.5 0 01.448-.5c.239-.025.474-.055.706-.092a1 1 0 10-.308-1.976c-2.7.423-5.976-.054-10.018-1.457a1 1 0 00-.656 1.89q.752.261 1.474.482a.5.5 0 01.354.481V4a.5.5 0 01-.2.4 2 2 0 102.4 0zM19.5 21h-2a.5.5 0 00-.5.5V23a1 1 0 001 1h1.5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5zM23 17a.242.242 0 01-.171-.412l.379-.379a1 1 0 00-1.414-1.414l-.94.94a.5.5 0 01-.707 0l-.939-.94a1 1 0 00-1.414 1.414l.377.377A.242.242 0 0118 17a1 1 0 00-1 1v1.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V18a1 1 0 00-1-1zM23.5 21h-2a.5.5 0 00-.5.5v2a.5.5 0 00.5.5H23a1 1 0 001-1v-1.5a.5.5 0 00-.5-.5zM4.171 6.972a.249.249 0 01.158.384l-.821 1.232a.25.25 0 00.279.378l6.489-1.918A1.13 1.13 0 0110.6 7a1 1 0 00.849-1.527l-3.1-5a1.044 1.044 0 00-1.7 0l-3.1 5a1 1 0 00-.024 1.013.92.92 0 00.646.486zM7.5 4.25a.75.75 0 11-.75.75.75.75 0 01.75-.75zM2.436 13H3a.25.25 0 01.2.4l-1.457 1.944a.25.25 0 00.269.391l9.353-2.7a1.007 1.007 0 01.277-.035h.925a1 1 0 00.832-1.555L11.22 8.177a.5.5 0 00-.558-.2l-8.055 2.382a1 1 0 00-.548.4l-.455.683A1 1 0 002.436 13zM10 10.25a.75.75 0 11-.75.75.75.75 0 01.75-.75zm-4 1a.75.75 0 11-.75.75.75.75 0 01.75-.75zM12.358 14.142a.5.5 0 00-.538-.18L.455 17.225A.491.491 0 00.2 17.4 1 1 0 001 19h5a.5.5 0 01.5.5V23a1 1 0 002 0v-3.5A.5.5 0 019 19h5a1 1 0 00.8-1.6zM7 17.75a.75.75 0 11.75-.75.75.75 0 01-.75.75zm5-.5a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});