define("ember-svg-jar/inlined/file-bin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-bin</title><path d=\"M7.5 16.626h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H7.5a.875.875 0 000-1.75zM7.5 13.626h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H7.5a.875.875 0 000-1.75z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.251A.25.25 0 0014.75 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.251A.25.25 0 0021.75 7zm-12.6 9.161a2.13 2.13 0 01.412 1.867 2.174 2.174 0 01-2.137 1.6H6A.625.625 0 015.375 19v-6A.625.625 0 016 12.376h1.424a2.174 2.174 0 012.137 1.6 2.13 2.13 0 01-.412 1.867.25.25 0 000 .319zm3.726 2.214H13a.625.625 0 110 1.25h-2a.625.625 0 010-1.25h.125a.25.25 0 00.25-.25v-4.25a.25.25 0 00-.25-.25H11a.625.625 0 010-1.25h2a.625.625 0 010 1.25h-.125a.25.25 0 00-.25.25v4.25a.25.25 0 00.25.251zm5.75.625a.625.625 0 01-.481.608.644.644 0 01-.144.017.627.627 0 01-.559-.346s-1.631-3.245-1.667-3.335-.149-.066-.149 0V19a.625.625 0 11-1.25 0v-6a.625.625 0 011.184-.279s1.583 3.187 1.645 3.29.171.095.171-.007V13a.625.625 0 111.25 0z\"/><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.428A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});