define("ember-svg-jar/inlined/style-three-pin-first-aid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-first-aid</title><path d=\"M12.5 0C6.71 0 2 4.262 2 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.7 21.784 23 15.246 23 9.5 23 4.262 18.29 0 12.5 0zm6 12.5A1.5 1.5 0 0117 14H8a1.5 1.5 0 01-1.5-1.5v-5A1.5 1.5 0 018 6h1a.5.5 0 00.5-.5v-.75A1.752 1.752 0 0111.25 3h2.5a1.752 1.752 0 011.75 1.75v.75a.5.5 0 00.5.5h1a1.5 1.5 0 011.5 1.5z\"/><path d=\"M14 4.75a.251.251 0 00-.25-.25h-2.5a.251.251 0 00-.25.25v.75a.5.5 0 00.5.5h2a.5.5 0 00.5-.5zM14 9.25h-.25a.5.5 0 01-.5-.5V8.5a.75.75 0 00-1.5 0v.25a.5.5 0 01-.5.5H11a.75.75 0 000 1.5h.25a.5.5 0 01.5.5v.25a.75.75 0 001.5 0v-.25a.5.5 0 01.5-.5H14a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});