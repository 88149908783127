define("ember-svg-jar/inlined/video-file-qt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-qt</title><path d=\"M9 17.375a.877.877 0 00.875-.875V14a.875.875 0 00-1.75 0v2.5a.877.877 0 00.875.875zM16 4.5A1.5 1.5 0 0017.5 6h3.9a.25.25 0 00.177-.427L16.427.427A.25.25 0 0016 .6z\"/><path d=\"M17.5 7A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.25a.25.25 0 00-.25-.25zm-6.375 9.5a2.112 2.112 0 01-.925 1.742.127.127 0 00-.025.2l.714.565a.626.626 0 01-.778.98L8.182 18.46a2.125 2.125 0 01-1.307-1.96V14a2.125 2.125 0 014.25 0zm5.375-3.375h-1.125a.25.25 0 00-.25.25V18.5a.625.625 0 01-1.25 0v-5.125a.25.25 0 00-.25-.25H12.5a.625.625 0 010-1.25h4a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});