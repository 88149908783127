define("ember-svg-jar/inlined/single-woman-actions-warning.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-warning</title><path d=\"M11.631 17.5a.25.25 0 00.221-.134l2.417-4.605a.248.248 0 00.013-.2.251.251 0 00-.143-.146c-.793-.312-1.695-.633-2.665-.989a.779.779 0 01-.512-.73v-.467a.249.249 0 01.249-.25A6.377 6.377 0 0015 9.081a.387.387 0 00.083-.593c-.494-.537-.835-1.185-.981-3.317C13.839 1.346 11.237 0 9 0S4.162 1.346 3.9 5.171c-.145 2.129-.486 2.78-.98 3.317a.387.387 0 00.08.593 6.35 6.35 0 003.782.9.251.251 0 01.254.25v.462a.778.778 0 01-.512.73c-2.9 1.065-5.212 1.825-5.765 2.922A7.845 7.845 0 000 17.076a.389.389 0 00.1.3.4.4 0 00.29.126zM18.781 12.271a1.449 1.449 0 00-2.561 0l-5.055 9.634a1.426 1.426 0 00.047 1.408 1.454 1.454 0 001.233.687h10.111a1.456 1.456 0 001.233-.686 1.428 1.428 0 00.047-1.409zM17.5 15.25a.75.75 0 01.75.75v2.5a.75.75 0 01-1.5 0V16a.75.75 0 01.75-.75zm0 7a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});