define("ember-svg-jar/inlined/medical-specialty-skin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-specialty-skin</title><path d=\"M24 6.681A2.878 2.878 0 0021.123 3.8L2.887 3.78A2.88 2.88 0 000 6.658V18.3a1.922 1.922 0 001.92 1.92h20.156A1.922 1.922 0 0024 18.3zM3 14.776a.993.993 0 110-1.986.993.993 0 110 1.986zm2.772 3.014a.994.994 0 110-1.987.994.994 0 110 1.987zm1.971-3.014a.993.993 0 110-1.986.993.993 0 110 1.986zm3.271 3.014a.994.994 0 110-1.987.994.994 0 110 1.987zm1.971-3.014a.993.993 0 110-1.986.993.993 0 110 1.986zm3.272 3.014a.994.994 0 110-1.987.994.994 0 110 1.987zm1.971-3.014a.993.993 0 110-1.986.993.993 0 110 1.986zm-.755-3.153c-2.739 0-2.737-2.84-5.476-2.843s-2.741 2.835-5.478 2.832c-2.3 0-2.668-2.012-4.357-2.649A.25.25 0 012 8.729v-2a.952.952 0 01.953-.952l18.092.023a.951.951 0 01.955.95v2a.25.25 0 01-.162.234c-1.694.635-2.06 2.641-4.365 2.639zM21 17.79a.994.994 0 110-1.99.994.994 0 110 1.987z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});