define("ember-svg-jar/inlined/raccoon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>raccoon</title><path d=\"M6.688 5.386a.5.5 0 00-.48-.638H.5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h4.982a.5.5 0 00.462-.308 19.618 19.618 0 00.744-2.054zM4.688 10.367c.057-.14.116-.28.175-.42a.5.5 0 00-.463-.695H.5a.5.5 0 00-.5.5V13a3.5 3.5 0 001.568 2.917 8.5 8.5 0 013.12-5.55zM3.5 0A3.507 3.507 0 00.112 2.622a.5.5 0 00.488.626h5.8a.5.5 0 00.481-.638A3.612 3.612 0 003.5 0zM16.21 19.1c-.5-.686-.862-1.135-1.223-1.143a2 2 0 01-1.949-2.2 7.561 7.561 0 01.612-2.324 4.1 4.1 0 01-1.142-3.078A9.727 9.727 0 0010 10a7.094 7.094 0 00-7 7v6a1 1 0 002 0v-2h.5a3.5 3.5 0 002.576-1.13A13.313 13.313 0 0013.171 21h.079v2a1 1 0 102 0v-2.233a7.953 7.953 0 001.793-.658c-.254-.252-.78-.941-.833-1.009zM23.345 8.868a1.433 1.433 0 00-.826-.228 2.964 2.964 0 00-2.244 1.139 1.785 1.785 0 00-.173.309 5.358 5.358 0 013.44 2l.017-.024c.631-.908.607-2.625-.214-3.196zM14.458 12.087a5.358 5.358 0 013.44-2 1.785 1.785 0 00-.173-.309 2.964 2.964 0 00-2.244-1.138 1.433 1.433 0 00-.826.228c-.821.571-.845 2.288-.214 3.2z\"/><path d=\"M19 11.5c-3.156 0-4.28 2.495-4.47 4.4a.5.5 0 00.488.55c2.061.05 2.592 3.05 3.982 3.05s1.921-3.006 3.982-3.048a.5.5 0 00.488-.55C23.28 14 22.156 11.5 19 11.5zm1.5 4h-3a.75.75 0 010-1.5h3a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});