define("ember-svg-jar/inlined/modern-weapon-gun-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-weapon-gun-1</title><path d=\"M22.75 5h-1.5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h1.5a1 1 0 001-1V6a1 1 0 00-1-1zM18.75 1h-1.192a1 1 0 00-.894.553l-.654 1.309a.252.252 0 01-.224.138H7a.25.25 0 01-.25-.25V2.5A1.5 1.5 0 005.25 1h-3.5a1.5 1.5 0 00-1.5 1.5v7.279A1.5 1.5 0 001.277 11.2l1.736.577a.251.251 0 01.169.267l-.928 7.893a.494.494 0 00.122.391.5.5 0 00.374.168h5a.5.5 0 00.5-.441l.51-4.338a.251.251 0 01.249-.221h3.024a2 2 0 001.9-1.367l.654-1.962a.25.25 0 01.23-.167h2.433a2.5 2.5 0 002.5-2.5V2a1 1 0 00-1-1zm-6.313 11.279l-.351 1.05a.25.25 0 01-.237.171H9.3a.249.249 0 01-.248-.279l.117-1A.251.251 0 019.417 12h2.791a.218.218 0 01.229.279zM15.75 6.75h-3a.75.75 0 010-1.5h3a.75.75 0 010 1.5zM7 21.5H3.25a.25.25 0 00-.25.25v.75a.5.5 0 00.5.5h3.25a.5.5 0 00.5-.5v-.75A.25.25 0 007 21.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});