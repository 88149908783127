define("ember-svg-jar/inlined/business-deal-cash-exchange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-deal-cash-exchange</title><circle cx=\"3.5\" cy=\"6.22\" r=\"2.143\"/><path d=\"M.5 14.97a.25.25 0 00.25.25h.809a.25.25 0 01.249.225l.384 3.835a.251.251 0 00.249.226h2.118a.251.251 0 00.249-.226l.384-3.835a.25.25 0 01.249-.225h.809a.25.25 0 00.25-.25v-2.322a3 3 0 00-6 0z\"/><circle cx=\"20.5\" cy=\"6.22\" r=\"2.143\"/><path d=\"M23.5 14.97v-2.322a3 3 0 10-6 0v2.322a.25.25 0 00.25.25h.809a.25.25 0 01.249.225l.384 3.835a.251.251 0 00.249.226h2.118a.251.251 0 00.249-.226l.384-3.835a.25.25 0 01.249-.225h.809a.25.25 0 00.25-.25zM12.452 10.884c-1.265-.3-1.382-.4-1.382-1.125a1.091 1.091 0 012.1-.416 1.25 1.25 0 102.309-.958 3.579 3.579 0 00-1.913-1.931.25.25 0 01-.153-.23v-.71a1.25 1.25 0 00-2.5 0v.711a.25.25 0 01-.152.23 3.594 3.594 0 00-2.189 3.3c0 2.762 2.146 3.278 3.3 3.555 1.3.314 1.382.422 1.382 1.124a1.091 1.091 0 01-2.1.417 1.25 1.25 0 00-2.308.959 3.579 3.579 0 001.914 1.93.25.25 0 01.153.231v.7a1.25 1.25 0 002.5 0v-.7a.25.25 0 01.152-.23 3.6 3.6 0 002.187-3.3c-.002-2.765-2.152-3.281-3.3-3.557z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});