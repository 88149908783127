define("ember-svg-jar/inlined/chair-retro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chair-retro</title><path d=\"M19.75 18.5a4.548 4.548 0 00-.469-1.994.25.25 0 01.03-.265A1.981 1.981 0 0019.75 15a2 2 0 00-2-2H6.25a2 2 0 00-2 2 1.981 1.981 0 00.439 1.241.25.25 0 01.03.265A4.548 4.548 0 004.25 18.5a7.325 7.325 0 00.5 2.149 9.965 9.965 0 01.384 1.358A1 1 0 005.25 24a1.8 1.8 0 001.557-.707c.635-.88.229-2.1-.164-3.275A6.536 6.536 0 016.25 18.5a2.643 2.643 0 01.325-1.235.5.5 0 01.442-.265h9.967a.5.5 0 01.441.265 2.646 2.646 0 01.325 1.235 6.536 6.536 0 01-.393 1.518c-.393 1.177-.8 2.395-.164 3.275A1.8 1.8 0 0018.75 24a1 1 0 00.12-1.993 9.965 9.965 0 01.384-1.358 7.325 7.325 0 00.496-2.149zM6.75 10a7.852 7.852 0 01-.055.939.5.5 0 00.5.561h9.616a.5.5 0 00.5-.561A7.852 7.852 0 0117.25 10a5.034 5.034 0 011.009-2.81 5 5 0 00.991-2.69 4.457 4.457 0 00-4.5-4.5h-5.5a4.457 4.457 0 00-4.5 4.5 5 5 0 00.991 2.69A5.034 5.034 0 016.75 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});