define("ember-svg-jar/inlined/warehouse-packages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>warehouse-packages</title><path d=\"M23 22h-1.75a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H23a1 1 0 000-2H1a1 1 0 000 2h1.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H1a1 1 0 000 2h22a1 1 0 000-2zm-4-.25a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25zm-14-1a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25zM8.5 8h8a.5.5 0 00.5-.5V1a1 1 0 00-1-1H9a1 1 0 00-1 1v6.5a.5.5 0 00.5.5zM12.5 10.5V17a.5.5 0 00.5.5h8a.5.5 0 00.5-.5v-6.5a1 1 0 00-1-1h-7a1 1 0 00-1 1zM2.5 17.5h8a.5.5 0 00.5-.5v-6.5a1 1 0 00-1-1H3a1 1 0 00-1 1V17a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});