define("ember-svg-jar/inlined/messaging-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messaging-messages</title><path d=\"M24 4.5A4.5 4.5 0 0019.5 0h-15A4.5 4.5 0 000 4.5v15A4.5 4.5 0 004.5 24h15a4.5 4.5 0 004.5-4.5zm-12 13a9 9 0 01-2.45-.33.5.5 0 00-.35 0l-3.49 1.64a.25.25 0 01-.33-.34l1.28-2.55a.25.25 0 00-.06-.3 5.57 5.57 0 01-2.1-4.25C4.5 8 7.86 5.26 12 5.26s7.5 2.74 7.5 6.12-3.36 6.11-7.5 6.11z\"/><circle cx=\"8\" cy=\"11.5\" r=\"1\"/><circle cx=\"12\" cy=\"11.5\" r=\"1\"/><circle cx=\"16\" cy=\"11.5\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});