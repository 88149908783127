define("ember-svg-jar/inlined/direction-button-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>direction-button-1</title><path d=\"M21 7h-3.5a.5.5 0 01-.5-.5V3a3 3 0 00-3-3h-4a3 3 0 00-3 3v3.5a.5.5 0 01-.5.5H3a3 3 0 00-3 3v4a3 3 0 003 3h3.5a.5.5 0 01.5.5V21a3 3 0 003 3h4a3 3 0 003-3v-3.5a.5.5 0 01.5-.5H21a3 3 0 003-3v-4a3 3 0 00-3-3zm-2.5 3.5a.5.5 0 01.8-.4l1.512 1.11a1 1 0 01.016 1.574L19.3 13.9a.5.5 0 01-.522.042.5.5 0 01-.278-.442zm-4.554-5.276a.5.5 0 01-.446.276h-3a.5.5 0 01-.4-.8l1.109-1.511a1.023 1.023 0 011.575-.018L13.9 4.7a.5.5 0 01.046.524zm-.046 14.07l-1.109 1.512a1 1 0 01-1.575.017L10.1 19.294a.5.5 0 01.4-.8h3a.5.5 0 01.4.8zM5.5 13.5a.5.5 0 01-.274.446.5.5 0 01-.526-.046l-1.508-1.109a1 1 0 01-.016-1.574L4.7 10.1a.5.5 0 01.8.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});