define("ember-svg-jar/inlined/smiley-trouble-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-trouble-alternate</title><path d=\"M6 15a6 6 0 10-6-6 6.007 6.007 0 006 6zM6 5a4 4 0 11-4 4 4 4 0 014-4z\"/><path d=\"M18 15a6 6 0 10-6-6 6.007 6.007 0 006 6zm0-10a4 4 0 11-4 4 4 4 0 014-4z\"/><path d=\"M22.75 14.55a1 1 0 00-1.267.63 10 10 0 01-18.966 0 1 1 0 00-1.9.637 12.005 12.005 0 0022.76 0 1 1 0 00-.627-1.267zM8.083 2.716a.99.99 0 00.356-.065 10.087 10.087 0 016.55-.2 1 1 0 00.6-1.909 12.1 12.1 0 00-7.86.238 1 1 0 00.356 1.934z\"/><circle cx=\"6\" cy=\"7.498\" r=\"1.5\"/><circle cx=\"18\" cy=\"7.498\" r=\"1.5\"/><path d=\"M9.5 17a1 1 0 000 2h5a1 1 0 100-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});