define("ember-svg-jar/inlined/monitor-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-share</title><path d=\"M9.9 15.735a.245.245 0 00-.014-.273.463.463 0 01-.094-.278v-.49a.49.49 0 01.49-.49h1.206a.248.248 0 00.111-.026 3.988 3.988 0 013.732 0 .236.236 0 00.111.027h.227a1.93 1.93 0 00.871-.21.246.246 0 00.132-.252 2.037 2.037 0 01-.023-.273 4.114 4.114 0 01.926-2.584.246.246 0 00.054-.153V1.959A1.962 1.962 0 0015.673 0H1.959A1.961 1.961 0 000 1.959v10.286A1.961 1.961 0 001.959 14.2h5.388a.49.49 0 01.49.49v.49a.49.49 0 01-.49.489H4.9a.98.98 0 100 1.96h4.206a.244.244 0 00.243-.219 4.118 4.118 0 01.551-1.675zm-7.455-4.47a.489.489 0 01-.49-.489V2.449a.49.49 0 01.49-.49h12.739a.489.489 0 01.489.49v8.327a.489.489 0 01-.489.489z\"/><path d=\"M24 20.816a3.169 3.169 0 00-5.507-2.154.245.245 0 01-.271.062l-1.422-.568a.245.245 0 01-.153-.24 2.534 2.534 0 00-.05-.588.246.246 0 01.115-.256l1.872-1.123a.246.246 0 01.278.018 3.142 3.142 0 001.951.686 3.184 3.184 0 10-3.183-3.184 3.008 3.008 0 00.053.55.244.244 0 01-.115.255L15.7 15.4a.246.246 0 01-.278-.018 3.138 3.138 0 00-1.951-.686 3.184 3.184 0 000 6.367 3.154 3.154 0 002.323-1.029.246.246 0 01.271-.062l1.417.567a.246.246 0 01.154.244v.035a3.184 3.184 0 106.367 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});