define("ember-svg-jar/inlined/show-theater-masks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>show-theater-masks</title><path d=\"M10.073 5a18.1 18.1 0 006.69.716.249.249 0 00.237-.245V1a.994.994 0 00-1.373-.925C12.191 1.467 4.809 1.468 1.373.076A.994.994 0 000 1v6.746c0 3.58 2.757 6.738 5.232 8.506a.25.25 0 00.39-.252 8.764 8.764 0 01-.206-1.868V8.14A3.383 3.383 0 0110.073 5z\"/><path d=\"M23.17 6.576a1.882 1.882 0 00-1.763-.182c-2.836 1.149-9.062 1.149-11.9 0A1.882 1.882 0 006.916 8.14v5.987c0 5.389 6.2 9.873 8.542 9.873S24 19.516 24 14.127V8.14a1.881 1.881 0 00-.83-1.564zM22 14.127C22 18.368 16.7 22 15.458 22s-6.542-3.632-6.542-7.873V8.665a.25.25 0 01.33-.237 23.7 23.7 0 0012.424 0 .25.25 0 01.33.237z\"/><path d=\"M18.454 16.105a.753.753 0 00-1.025.273 2.277 2.277 0 01-3.942 0 .75.75 0 10-1.3.752 3.777 3.777 0 006.538 0 .751.751 0 00-.271-1.025zM18.293 10.748a2.253 2.253 0 00-2.123 1.5.75.75 0 101.415.5.75.75 0 011.415 0 .751.751 0 00.708.5.77.77 0 00.249-.042.751.751 0 00.459-.957 2.255 2.255 0 00-2.123-1.501zM13.331 12.749a.75.75 0 00.708.5.775.775 0 00.249-.042.75.75 0 00.458-.957 2.251 2.251 0 00-4.246 0 .751.751 0 101.416.5.75.75 0 011.415 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});