define("ember-svg-jar/inlined/gaming-10", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gaming-10</title><path d=\"M15 9.75A1.252 1.252 0 0013.75 11v2a1.25 1.25 0 002.5 0v-2A1.252 1.252 0 0015 9.75z\"/><path d=\"M21.919 8.2a.5.5 0 01-.22-.532 4.505 4.505 0 00-5.37-5.368.5.5 0 01-.532-.22 4.5 4.5 0 00-7.594 0 .5.5 0 01-.531.221A4.5 4.5 0 002.3 7.67a.5.5 0 01-.22.532 4.506 4.506 0 000 7.6.5.5 0 01.22.532A4.505 4.505 0 007.671 21.7a.5.5 0 01.532.22 4.505 4.505 0 007.594 0 .5.5 0 01.532-.22 4.506 4.506 0 005.37-5.37.5.5 0 01.22-.532 4.506 4.506 0 000-7.6zM11 15.75H7a.75.75 0 010-1.5h.75a.5.5 0 00.5-.5v-2.836a.25.25 0 00-.427-.177l-.293.293a.75.75 0 01-1.06-1.06l1.323-1.324a1.347 1.347 0 01.957-.4 1 1 0 011 1v4.5a.5.5 0 00.5.5H11a.75.75 0 010 1.5zM17.75 13a2.75 2.75 0 01-5.5 0v-2a2.75 2.75 0 015.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});