define("ember-svg-jar/inlined/server-search-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-search-1</title><path d=\"M10.565 20.236A.5.5 0 0010.14 20H10a.25.25 0 01-.25-.25v-1.391a6.941 6.941 0 01.847-5.591.5.5 0 00-.422-.768H2.5a2.5 2.5 0 000 5H8a.25.25 0 01.25.25v2.5A.25.25 0 018 20H5a.75.75 0 000 1.5h5.478a.5.5 0 00.4-.8 6.472 6.472 0 01-.313-.464zM3 14.5a1.25 1.25 0 111.25 1.25A1.249 1.249 0 013 14.5zm5 1.25a1.25 1.25 0 111.25-1.25A1.25 1.25 0 018 15.75zM2 20H1a.75.75 0 000 1.5h1A.75.75 0 002 20zM2.5 5h13a2.5 2.5 0 000-5h-13a2.5 2.5 0 000 5zM3 2.5a1.25 1.25 0 111.25 1.25A1.249 1.249 0 013 2.5zm3.75 0A1.25 1.25 0 118 3.75 1.249 1.249 0 016.75 2.5zM16.529 9.5a6.576 6.576 0 01.823.053.5.5 0 00.54-.347A2.39 2.39 0 0018 8.5 2.5 2.5 0 0015.5 6h-13a2.5 2.5 0 000 5h9.535a.5.5 0 00.3-.1 6.984 6.984 0 014.194-1.4zm-7.279-1A1.25 1.25 0 118 7.25 1.25 1.25 0 019.25 8.5zm-3.75 0a1.25 1.25 0 11-1.25-1.25A1.25 1.25 0 015.5 8.5zM11 16.529a5.524 5.524 0 008.5 4.66.25.25 0 01.311.034l2.484 2.484a1 1 0 001.414-1.414l-2.484-2.484a.251.251 0 01-.034-.312A5.527 5.527 0 1011 16.529zm5.529 3.529a3.529 3.529 0 113.529-3.529 3.533 3.533 0 01-3.529 3.529z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});