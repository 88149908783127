define("ember-svg-jar/inlined/phone-actions-missed-call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-missed-call</title><path d=\"M17.307 15.162a2.5 2.5 0 00-3.536 0l-.385.385A46.837 46.837 0 017.6 9.757l.385-.385a2.5 2.5 0 000-3.535L5.861 3.715a2.5 2.5 0 00-3.537 0L1.161 4.879a3.509 3.509 0 00-.441 4.4 46.893 46.893 0 0013.141 13.145 3.537 3.537 0 004.4-.441l1.164-1.164a2.5 2.5 0 000-3.536zM23.492 3.121a1.249 1.249 0 00-1.767 0l-2.987 2.987a.25.25 0 01-.345.008L15.681 3.64l.928-.928A1 1 0 0015.9 1h-3.791a1 1 0 00-1 1v3.8a1 1 0 001.707.707l1.095-1.1 3.855 3.519a1.249 1.249 0 001.726-.039l4-4a1.249 1.249 0 000-1.766z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});