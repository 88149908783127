define("ember-svg-jar/inlined/style-three-pin-scissors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-scissors</title><circle cx=\"8.75\" cy=\"14\" r=\"1.25\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm3.25 16.75a2.748 2.748 0 01-2.65-2.05c0-.011-.012-.019-.016-.03l-.349-1.052a.25.25 0 00-.474 0l-.349 1.052c0 .011-.012.019-.016.03a2.741 2.741 0 11-.97-2.879.248.248 0 00.226.042.252.252 0 00.164-.16l.265-.8a.25.25 0 00-.019-.2.253.253 0 00-.16-.122 3.593 3.593 0 01-2.5-2.622 4.384 4.384 0 01.872-4.67.76.76 0 011.216.319l1.273 3.822a.25.25 0 00.474 0l1.268-3.819a.76.76 0 011.216-.319 4.383 4.383 0 01.871 4.67 3.592 3.592 0 01-2.5 2.622.253.253 0 00-.16.122.25.25 0 00-.019.2l.265.8a.252.252 0 00.164.16.248.248 0 00.226-.042 2.749 2.749 0 111.682 4.926z\"/><circle cx=\"15.25\" cy=\"14\" r=\"1.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});