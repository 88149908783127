define("ember-svg-jar/inlined/video-game-logo-deep-silver", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-logo-deep-silver</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm8.227 16.75a.534.534 0 01-.227-.667 3.5 3.5 0 10-6.08.709 1 1 0 01-.06 1.241 6.5 6.5 0 01-10.243-.593.481.481 0 01.156-.69.534.534 0 01.691.137 3.5 3.5 0 102.426-5.62 1 1 0 01-1.045-.667 6.5 6.5 0 015.635-8.579.48.48 0 01.52.479.536.536 0 01-.464.531 3.5 3.5 0 103.654 4.91 1 1 0 011.1-.568 6.5 6.5 0 014.61 9.167.48.48 0 01-.673.21z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});