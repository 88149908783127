define("ember-svg-jar/inlined/tool-helmet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tool-helmet</title><path d=\"M12 .917a11.5 11.5 0 00-9.559 17.9 1.5 1.5 0 00.817.584L15.527 23a1.42 1.42 0 001.083-.043 11.8 11.8 0 006.6-7.994A11.506 11.506 0 0012 .917zm8.365 15.988a.251.251 0 01-.269.127c-4.708-.943-9.849-3.217-10.9-6.794a.25.25 0 01.039-.22.253.253 0 01.2-.1h11.528a.249.249 0 01.242.188 9.411 9.411 0 01-.841 6.799z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});