define("ember-svg-jar/inlined/safety-warning-cliff", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-warning-cliff</title><path d=\"M.172 21.305A1.5 1.5 0 001.5 23.5h21a1.5 1.5 0 001.328-2.2l-10.5-20a1.5 1.5 0 00-2.658 0zM13.765 7.968A1.5 1.5 0 1112.6 9.743a1.5 1.5 0 011.165-1.775zM7.65 16.247l1.1-.8a4.763 4.763 0 012.427-.9l1.04-.082a.251.251 0 00.229-.222l.127-1.227a.249.249 0 00-.161-.26 9.353 9.353 0 01-3.055-2.039.75.75 0 011.053-1.064 7.489 7.489 0 002.794 1.8l1.279.337a11.014 11.014 0 011.308.434 3.728 3.728 0 011.976 1.843.75.75 0 01-1.349.655 2.242 2.242 0 00-1.187-1.107 9.226 9.226 0 00-.888-.306.249.249 0 00-.318.214 4.681 4.681 0 01-.753 2.58l-2.245 3.435a.752.752 0 01-.631.343.742.742 0 01-.406-.121.75.75 0 01-.222-1.037l1.632-2.5a.119.119 0 00-.109-.184 3.249 3.249 0 00-1.661.615l-1.1.8a.75.75 0 11-.885-1.211zm-3.307 3.479a1 1 0 011-1h2a1 1 0 011 1v1.046a1 1 0 01-1 1h-2a1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});