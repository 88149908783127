define("ember-svg-jar/inlined/hamster-toy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hamster-toy</title><path d=\"M23.5 20.154V3a3 3 0 00-3-3h-17a3 3 0 00-3 3v17.154A.988.988 0 000 21v.5A2.5 2.5 0 002.5 24h19a2.5 2.5 0 002.5-2.5V21a.988.988 0 00-.5-.846zm-7.761-4.724a.251.251 0 01.156-.055h5.355a.25.25 0 01.25.25v4.125a.249.249 0 01-.25.25h-1.125a.249.249 0 01-.25-.25V17.5a.875.875 0 00-1.75 0v2.25a.249.249 0 01-.25.25h-1.75a.249.249 0 01-.25-.25v-1.19a.875.875 0 00-1.75 0v1.19a.249.249 0 01-.25.25h-3.136a.25.25 0 01-.157-.445zM7.3 20h-.175a.249.249 0 01-.25-.25v-7a.25.25 0 01.25-.25h2.609a.251.251 0 01.117.03l3.7 1.963a.248.248 0 01.131.2.251.251 0 01-.092.218S7.346 19.936 7.3 20zm.2-9.5a.5.5 0 01-.5.5H5a.5.5 0 01-.5-.5v-1a1.5 1.5 0 013 0zm-5 2.25a.25.25 0 01.25-.25h2.125a.25.25 0 01.25.25v7a.249.249 0 01-.25.25H2.75a.249.249 0 01-.25-.25zM3.5 2h6.125v3a.875.875 0 001.75 0V2h2.75v9a.875.875 0 001.75 0V2h2.25v9.5a.875.875 0 001.75 0V2h.625a1 1 0 011 1v10.375a.249.249 0 01-.25.25h-5.544L11 11.273V10.5a5 5 0 00-6.029-4.9A4.765 4.765 0 002.5 6.976V3a1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});