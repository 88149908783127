define("ember-svg-jar/inlined/arrow-right-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-right-2-alternate</title><path d=\"M0 19.153V4.847a2.386 2.386 0 012.384-2.384h12.922a2.4 2.4 0 011.789.806l6.311 7.153a2.393 2.393 0 010 3.156l-6.314 7.156a2.391 2.391 0 01-1.786.8H2.384A2.386 2.386 0 010 19.153zm20.836-6.988a.248.248 0 000-.33l-5.669-6.427a.254.254 0 00-.188-.084H3.111a.25.25 0 00-.25.25v12.852a.25.25 0 00.25.25h11.868a.254.254 0 00.188-.084z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});