define("ember-svg-jar/inlined/style-three-pin-book-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-book_1</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.749 7.3 12.286 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm-.5 15.284a.5.5 0 01-.237.425.5.5 0 01-.263.075.508.508 0 01-.224-.053l-4-2a.5.5 0 01-.276-.447v-6a.5.5 0 01.724-.448l4 2a.5.5 0 01.276.448zm.724-7.553a.5.5 0 01-.448 0L8.209 5.947a.5.5 0 11.447-.894l3.12 1.56a.5.5 0 00.448 0l3.12-1.56a.5.5 0 11.447.894zm5.276 5.553a.5.5 0 01-.276.447l-4 2a.508.508 0 01-.224.053.5.5 0 01-.263-.075.5.5 0 01-.237-.425v-6a.5.5 0 01.276-.448l4-2a.5.5 0 01.724.448z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});