define("ember-svg-jar/inlined/fossil-energy-gas-can", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fossil-energy-gas-can</title><path d=\"M6.326 7.259a.5.5 0 00.7.092l1.591-1.219a.5.5 0 00.092-.7l-.649-.853C5.9 1.741 4.6.027 1.008 0A.975.975 0 00.3.291 1 1 0 000 1v1a1 1 0 00.987 1c2.366.032 3.038 1 4.258 2.76.318.459.667.961 1.081 1.499z\"/><path d=\"M21.5 1h-8A2.5 2.5 0 0011 3.5v2.766l-5.1 4.25a2.493 2.493 0 00-.9 1.921V22.5A1.5 1.5 0 006.5 24h16a1.5 1.5 0 001.5-1.5v-19A2.5 2.5 0 0021.5 1zm-5.236 14.854l3.116 3.116a.75.75 0 11-1.06 1.06l-3.12-3.116a.5.5 0 00-.707 0L11.38 20.03a.75.75 0 01-1.06-1.06l3.116-3.116a.5.5 0 000-.708L10.32 12.03a.75.75 0 011.06-1.06l3.12 3.116a.5.5 0 00.707 0l3.113-3.116a.75.75 0 111.06 1.06l-3.116 3.116a.5.5 0 000 .708zM21.5 5.5a1 1 0 01-1 1h-6a1 1 0 01-1-1v-1a1 1 0 011-1h6a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});