define("ember-svg-jar/inlined/tank-top", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tank-top</title><path d=\"M21 9.282a1 1 0 00-.446-.833C19.954 8.048 18 6.438 18 2.5V1a1 1 0 00-1-1h-1a1 1 0 00-1 1v4c0 .45-1.384 1-3 1s-3-.55-3-1V1a1 1 0 00-1-1H7a1 1 0 00-1 1v1.5c0 3.94-1.956 5.55-2.554 5.949A1 1 0 003 9.282V23a1 1 0 001 1h16a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});