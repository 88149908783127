define("ember-svg-jar/inlined/fast-food-burger-drink", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fast-food-burger-drink</title><path d=\"M19 12h-6a4 4 0 00-3.96 3.428.5.5 0 00.5.572h12.924a.5.5 0 00.5-.571A4 4 0 0019 12zm-5.75 2.5a.75.75 0 11.75-.75.75.75 0 01-.75.75zM16 15a.75.75 0 11.75-.75.75.75 0 01-.75.75zm2.75-.5a.75.75 0 11.75-.75.75.75 0 01-.75.75zM24 19a1.5 1.5 0 01-1.5 1.5h-13A1.5 1.5 0 018 19a1.5 1.5 0 011.5-1.5h13A1.5 1.5 0 0124 19zM22.415 22a.5.5 0 01.471.666A2 2 0 0121 24H11a2 2 0 01-1.886-1.333.5.5 0 01.471-.667z\"/><path d=\"M13.988 7.685A1.5 1.5 0 0012.5 6H8.506a.5.5 0 01-.5-.553l.311-2.908a.5.5 0 01.594-.439l3.9.747a1 1 0 00.377-1.963L8.753.035a2 2 0 00-2.364 1.751l-.4 3.767a.5.5 0 01-.5.447H1.5A1.5 1.5 0 00.012 7.686l1.875 15A1.5 1.5 0 003.375 24H6.75a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5H4.258a.5.5 0 01-.5-.438l-1.625-13a.5.5 0 01.5-.562h2.535a.5.5 0 01.5.553l-1.162 10.84a1 1 0 00.887 1.1h.108a1 1 0 00.993-.893L7.688 8.447A.5.5 0 018.185 8h3.182a.5.5 0 01.5.562l-.141 1.126a.5.5 0 00.5.562h1.007a.5.5 0 00.5-.438z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});