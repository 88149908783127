define("ember-svg-jar/inlined/charging-battery-eco", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>charging-battery-eco</title><path d=\"M24 11.75a2 2 0 00-2-2h-1v-1a2 2 0 00-2-2h-.8a.249.249 0 00-.246.212 11.957 11.957 0 01-.327 1.468.251.251 0 00.24.32h.633a.5.5 0 01.5.5v1.5a1 1 0 001 1h1.75A.25.25 0 0122 12v2.5a.25.25 0 01-.25.25H20a1 1 0 00-1 1v1.5a.5.5 0 01-.5.5h-16a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5h2.268a.249.249 0 00.247-.29 3.377 3.377 0 01.061-1.4.249.249 0 00-.242-.311H2a2 2 0 00-2 2v9a2 2 0 002 2h17a2 2 0 002-2v-1h1a2 2 0 002-2z\"/><path d=\"M7.762 10.126a35.2 35.2 0 00-1 1.119 34.5 34.5 0 00-2.158 2.816.751.751 0 00.209 1.039.783.783 0 001.041-.2c.5-.754 3.132-4.6 6.2-6.3a.5.5 0 11.486.874 14.911 14.911 0 00-3.64 3.064 2.08 2.08 0 001.93 1.28 4.135 4.135 0 002.1-.72c1.986-1.217 4.08-5.526 3.582-8.432a.5.5 0 00-.682-.379 8.856 8.856 0 01-3.716.457 6.67 6.67 0 00-3.892.684A3.063 3.063 0 006.469 7.9a2.97 2.97 0 001.293 2.226z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});