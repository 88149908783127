define("ember-svg-jar/inlined/award-ribbon-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>award-ribbon-star</title><path d=\"M15.494 16.933a4.678 4.678 0 01-6.989 0 4.437 4.437 0 01-.729-.016.254.254 0 00-.194.063.251.251 0 00-.082.186V23a1 1 0 001.53.848L12 21.992l2.97 1.857A1 1 0 0016.5 23v-5.831a.251.251 0 00-.275-.249 4.348 4.348 0 01-.731.013z\"/><path d=\"M18.792 5.687a3.17 3.17 0 00-3.979-3.978 3.17 3.17 0 00-5.626 0 3.169 3.169 0 00-3.978 3.978 3.169 3.169 0 000 5.625 3.17 3.17 0 003.979 3.979 3.169 3.169 0 005.626 0 3.169 3.169 0 003.978-3.978 3.17 3.17 0 000-5.626zm-8.818 6a.459.459 0 01-.653-.573l.779-1.775a.25.25 0 00-.054-.278l-1.4-1.374a.428.428 0 01.3-.748h1.591a.25.25 0 00.227-.144L11.6 5a.463.463 0 01.824 0l.829 1.8a.249.249 0 00.226.144h1.591a.428.428 0 01.3.748l-1.4 1.374a.25.25 0 00-.054.278l.774 1.776a.459.459 0 01-.653.573l-1.911-1.075a.251.251 0 00-.246 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});