define("ember-svg-jar/inlined/bat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bat</title><path d=\"M16.284 2.322A.5.5 0 0116.751 2H19a1 1 0 000-2H5a1 1 0 000 2h2.2a.5.5 0 01.465.317L8.832 5.3a1.753 1.753 0 01.829.312L17.4 11.1a12.94 12.94 0 00-2.386-5.442zm-6.33.361A.5.5 0 0110.419 2h3.123a.5.5 0 01.467.678l-.447 1.177a16.434 16.434 0 00-1.234-1.232.5.5 0 00-.656 0 16.24 16.24 0 00-1.25 1.25zM16.969 12.319L8.938 6.627a.516.516 0 00-.393-.081.5.5 0 00-.326.235A11.08 11.08 0 006.5 12.354 8.465 8.465 0 007.8 17.4a5.549 5.549 0 01.52-.729l1.507-1.786a2.838 2.838 0 014.347 0l1.506 1.786a5.382 5.382 0 01.455.622l1.165-1.175a2.5 2.5 0 00-.331-3.8z\"/><path d=\"M14.534 17.639l-1.507-1.785a1.374 1.374 0 00-2.053 0l-1.507 1.785A4.127 4.127 0 008.5 20.3v2.95a.75.75 0 001.28.53l.832-.832a3.884 3.884 0 002.776 0l.832.832a.749.749 0 001.28-.53V20.3a4.125 4.125 0 00-.966-2.661zM13.5 21.6a1 1 0 111-1 1 1 0 01-1 1zm-2-1a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});