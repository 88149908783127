define("ember-svg-jar/inlined/common-file-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-sync</title><path d=\"M23.414 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.277a.247.247 0 00.241.249c.364.009 1.037.041 1.485.093A.247.247 0 008 9.371V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.245.245 0 00-.241.21 7.956 7.956 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.415A2 2 0 0023.414 3zM11.77 18.6a1 1 0 00-1.255.578 4.074 4.074 0 01-1.277 1.932 4.163 4.163 0 01-5.658-.469.256.256 0 01.014-.349l1.717-1.717a.5.5 0 00-.354-.853H.5a.5.5 0 00-.5.5v4.458a.5.5 0 00.854.353l.974-.974a.244.244 0 01.353.009 6.086 6.086 0 0010.211-2.2 1 1 0 00-.622-1.268z\"/><path d=\"M12.691 11.772a.5.5 0 00-.545.109l-1.03 1.031a.244.244 0 01-.352-.008 6.1 6.1 0 00-10.2 2.225 1 1 0 001.879.686A4.054 4.054 0 013.719 13.9a4.153 4.153 0 015.637.435.255.255 0 01-.012.35l-1.655 1.653a.5.5 0 00.354.853H12.5a.5.5 0 00.5-.5v-4.457a.5.5 0 00-.309-.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});