define("ember-svg-jar/inlined/road-sign-deadend-alternate-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-deadend-alternate_1</title><path d=\"M23 0H1a1 1 0 00-1 1v22a1 1 0 001 1h22a1 1 0 001-1V1a1 1 0 00-1-1zm-1 21.75a.25.25 0 01-.25.25H2.25a.25.25 0 01-.25-.25V2.25A.25.25 0 012.25 2h19.5a.25.25 0 01.25.25z\"/><path d=\"M13.25 13.25a.75.75 0 00-.75.75.213.213 0 01-.411.079l-.143-.357A.75.75 0 0010.5 14v5a.75.75 0 001.5 0 .213.213 0 01.411-.079l.143.357a.749.749 0 00.7.472.691.691 0 00.142-.014A.749.749 0 0014 19v-5a.75.75 0 00-.75-.75zM16 10.5v-4a1.75 1.75 0 00-3.5 0v4a.75.75 0 001.5 0v-1a.25.25 0 01.5 0v1a.75.75 0 001.5 0zm-1.5-3a.25.25 0 01-.5 0v-1c0-.275.5-.275.5 0zM7.5 8.5v-1a2.752 2.752 0 00-2.75-2.75.75.75 0 00-.75.75v5a.75.75 0 00.75.75A2.752 2.752 0 007.5 8.5zM6 8.5a.25.25 0 01-.5 0v-1a.25.25 0 01.5 0zM15.5 13.25a.75.75 0 00-.75.75v5a.75.75 0 00.75.75A2.752 2.752 0 0018.25 17v-1a2.752 2.752 0 00-2.75-2.75zM16.75 17a.25.25 0 01-.5 0v-1a.25.25 0 01.5 0zM17.5 4.75a.75.75 0 00-.75.75v5a.75.75 0 00.75.75 2.752 2.752 0 002.75-2.75v-1a2.752 2.752 0 00-2.75-2.75zm1.25 3.75a.25.25 0 01-.5 0v-1a.25.25 0 01.5 0zM11.75 10.5a.75.75 0 00-.75-.75h-1a.25.25 0 010-.5h1a.75.75 0 000-1.5h-1a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h1a.75.75 0 000-1.5h-1A1.752 1.752 0 008.25 6.5v3A1.752 1.752 0 0010 11.25h1a.75.75 0 00.75-.75zM8 14.75h1a.75.75 0 000-1.5H8A1.752 1.752 0 006.25 15v3A1.752 1.752 0 008 19.75h1a.75.75 0 000-1.5H8a.25.25 0 010-.5h1a.75.75 0 000-1.5H8a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});