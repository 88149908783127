define("ember-svg-jar/inlined/single-neutral-actions-block.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-block</title><path d=\"M11.273 12.385a.5.5 0 00-.119-.745 7.458 7.458 0 00-3.948-1.14 7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h8.559a.5.5 0 00.493-.412 7.944 7.944 0 011.721-3.703z\"/><circle cx=\"7\" cy=\"4.75\" r=\"4.75\"/><path d=\"M19 11.17A6.514 6.514 0 0011.169 19 6.422 6.422 0 0016 23.833a6.771 6.771 0 001.491.167 6.5 6.5 0 006.343-8A6.423 6.423 0 0019 11.17zm-3.27 10.48a.5.5 0 01-.15-.81l5.261-5.262a.5.5 0 01.81.15 4.517 4.517 0 01-5.921 5.922zm-1.41-7.331a4.661 4.661 0 014.953-.965.5.5 0 01.152.811l-5.244 5.243a.5.5 0 01-.817-.166 4.367 4.367 0 01.956-4.923z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});