define("ember-svg-jar/inlined/phone-actions-missed-call-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-missed-call-1</title><path d=\"M23.509 1.251a1.251 1.251 0 00-1.768 0L17.693 5.3a.25.25 0 01-.334.018L15.3 3.664l.822-.822a1 1 0 00-.707-1.707h-3.79a1 1 0 00-1 1v3.793a1 1 0 00.618.924c.9.376 1.729-.857 2.281-1.409l3.318 2.666a1.267 1.267 0 001.667-.091l5-5a1.249 1.249 0 000-1.767zM15.62 14.9a3.116 3.116 0 00-4.4 0l-.827.828a4.25 4.25 0 00-1.346 4.5A11.348 11.348 0 013.013 14.2a4.247 4.247 0 004.5-1.346l.83-.829a3.116 3.116 0 000-4.4L4.76 4.038a2.234 2.234 0 00-3.649.739 13.32 13.32 0 009.653 18.07 13.408 13.408 0 007.7-.715 2.234 2.234 0 00.737-3.649z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});