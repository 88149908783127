define("ember-svg-jar/inlined/police-man-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-man-2</title><path d=\"M23.5 21.394a2.462 2.462 0 00-.967-1.977C21.106 18.328 17.839 16.5 12 16.5s-9.106 1.828-10.533 2.917A2.462 2.462 0 00.5 21.394V23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5zm-6.639 1.565l-.609-.406a2.247 2.247 0 01-1-1.872v-.93a.25.25 0 01.25-.25h3a.25.25 0 01.25.25v.93a2.246 2.246 0 01-1 1.872l-.609.406a.251.251 0 01-.278 0z\"/><path d=\"M19 9.5V8.189a.25.25 0 01.211-.247 40.647 40.647 0 002.506-.466 1 1 0 00.759-1.193 1.041 1.041 0 00-1.193-.759 44.346 44.346 0 01-18.566 0 .967.967 0 00-.646.09.982.982 0 00-.547.669 1 1 0 00.759 1.193c.82.182 1.658.333 2.506.466A.25.25 0 015 8.189V9.5a7 7 0 0014 0zm-3.5 1.75a1.752 1.752 0 01-1.75-1.75c0-.452 0-.75 1.75-.75a4.9 4.9 0 011.323.121.25.25 0 01.177.239v.39a4.946 4.946 0 01-.107 1 .24.24 0 01-.052.109 1.739 1.739 0 01-1.341.641zm-8.341-.638a.245.245 0 01-.052-.109A4.946 4.946 0 017 9.5v-.39a.25.25 0 01.177-.239A4.9 4.9 0 018.5 8.75c1.75 0 1.75.3 1.75.75a1.743 1.743 0 01-3.091 1.112zM12 14.5a4.934 4.934 0 01-3.368-1.336.25.25 0 01.155-.433A3.259 3.259 0 0011.7 9.984a.253.253 0 01.143-.183.369.369 0 01.324 0 .25.25 0 01.143.183 3.26 3.26 0 002.908 2.747.25.25 0 01.155.433A4.934 4.934 0 0112 14.5zM17.741 4.878a.256.256 0 00.174-.105.251.251 0 00.038-.2l-.429-1.861A3.485 3.485 0 0014.113 0H9.887a3.485 3.485 0 00-3.411 2.713l-.429 1.86a.251.251 0 00.211.3 45.494 45.494 0 0011.483.005zm-5.629-.427a.252.252 0 01-.224 0 2.9 2.9 0 01-1.382-2.69.252.252 0 01.069-.184.25.25 0 01.18-.077h2.49a.25.25 0 01.18.077.252.252 0 01.069.184 2.9 2.9 0 01-1.382 2.69z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});