define("ember-svg-jar/inlined/famous-people-man-charlie-chaplin.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-people-man-charlie-chaplin</title><path d=\"M1.486 7.935c.483.09.967.166 1.452.24a.249.249 0 01.211.225q.033.39.09.8a2.117 2.117 0 00-.464 1.443 2.728 2.728 0 001.44 2.433.249.249 0 01.143.187c.511 3.048 4.022 4.991 5.687 4.991s5.176-1.943 5.687-4.992a.249.249 0 01.143-.186 2.725 2.725 0 001.439-2.433 2.112 2.112 0 00-.463-1.443q.054-.393.087-.769a.249.249 0 01.212-.231 45.228 45.228 0 001.614-.268.75.75 0 00-.278-1.475c-.381.072-.763.131-1.145.191a.249.249 0 01-.19-.049.247.247 0 01-.1-.17C16.785 4.075 15.537 0 10.145 0 6.513 0 4.751 1.841 3.9 3.765a6.842 6.842 0 00-.751 2.653.252.252 0 01-.288.225c-.366-.057-.733-.114-1.1-.183a.75.75 0 00-.278 1.475zm14.224 2.236a.626.626 0 01.1.439 1.272 1.272 0 01-.558 1.1 1.747 1.747 0 00-1 1.3c-.378 2.249-3.176 3.739-4.208 3.739s-3.83-1.49-4.207-3.738a1.75 1.75 0 00-1-1.306 1.275 1.275 0 01-.559-1.1.624.624 0 01.1-.439.751.751 0 00.405-.795 15.457 15.457 0 01-.094-.656.25.25 0 01.277-.278 46.334 46.334 0 005.155.3 46.186 46.186 0 005-.282.251.251 0 01.276.279q-.037.312-.092.638a.751.751 0 00.405.799z\"/><path d=\"M11.493 12.724a.624.624 0 00-.606-.474H9.363a.624.624 0 00-.606.474l-.313 1.25a.625.625 0 00.606.776h2.15a.625.625 0 00.606-.776zM21.375 21.107a.248.248 0 00-.044-.141 4.456 4.456 0 00-2.116-1.675l-4.4-1.755a.5.5 0 00-.684.419l-.5 5.5a.5.5 0 00.5.545h7a.25.25 0 00.25-.25zM10.589 19.564a.521.521 0 00-.928 0l-1.5 3.75a.5.5 0 00.464.686h3a.5.5 0 00.464-.686z\"/><path d=\"M20.875 10.25a3 3 0 00-3 3V14a.75.75 0 001.5 0v-.75a1.5 1.5 0 013 0V23a.75.75 0 001.5 0v-9.75a3 3 0 00-3-3zM5.887 17.574a.5.5 0 00-.448-.038l-4.393 1.751a4.574 4.574 0 00-.67.318.5.5 0 00-.251.434V23.5a.5.5 0 00.5.5h5.5a.5.5 0 00.5-.545l-.5-5.5a.5.5 0 00-.238-.381zM9.194 10.786a.75.75 0 00-.039-1.06 2.017 2.017 0 00-2.6 0 .75.75 0 001.021 1.1.559.559 0 01.558 0 .751.751 0 001.06-.039zM10.976 9.726A.75.75 0 0012 10.825a.559.559 0 01.558 0 .75.75 0 101.021-1.1 2.017 2.017 0 00-2.603.001z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});