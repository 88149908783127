define("ember-svg-jar/inlined/modern-tv-remote-hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-tv-remote-hand</title><path d=\"M5.638 2.908a5.578 5.578 0 016.1 0 1 1 0 001.112-1.663 7.612 7.612 0 00-8.322 0 1 1 0 101.11 1.663zM5.636 4.666a1 1 0 101.111 1.662 3.553 3.553 0 013.881 0 1 1 0 101.111-1.663 5.58 5.58 0 00-6.103.001zM20.523 23.869a.75.75 0 00.238-1.033l-2.31-3.689a.5.5 0 01-.076-.266v-4.2c0-1.606-1.319-2.557-2.717-3.564-.449-.324-.911-.657-1.365-1.031a.5.5 0 01-.18-.338 2.5 2.5 0 00-2.488-2.258h-6a2.5 2.5 0 00-2.5 2.5v10a2.5 2.5 0 002.5 2.5h6a2.505 2.505 0 00.538-.058.5.5 0 01.507.186c.334.441.65.829.88 1.1a.75.75 0 101.15-.962 13.36 13.36 0 01-2.325-3.509v-1a.75.75 0 00-.178-.485l-1.753-2.071a.731.731 0 011.075-.99L14.1 17.28a.75.75 0 001.06-1.061l-.884-.884a.5.5 0 01-.146-.353v-2.633a.25.25 0 01.4-.2l.259.189c1.3.936 2.094 1.549 2.094 2.347v4.555a.755.755 0 00.114.4l2.5 3.994a.75.75 0 001.026.235zm-8.9-12.879a1 1 0 11-1-1 1 1 0 011.002 1zm-5 5a1 1 0 111-1 1 1 0 01-.998 1zm1 3a1 1 0 11-1-1 1 1 0 011.002 1zm-1-7a1 1 0 111-1 1 1 0 01-.998 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});