define("ember-svg-jar/inlined/paragraph-spacing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paragraph-spacing</title><path d=\"M6.984 4.876a1 1 0 00.707-1.707L4.823.3a1 1 0 00-1.414 0L.543 3.169a1 1 0 00.707 1.707h1.616a.25.25 0 01.25.25v13.745a.25.25 0 01-.25.25H1.25a1 1 0 00-.707 1.707l2.866 2.866a1 1 0 001.414 0l2.868-2.866a1 1 0 00-.707-1.707H5.366a.25.25 0 01-.25-.25V5.126a.25.25 0 01.25-.25zM15.106 3.442h7.644a1 1 0 000-2h-7.644a1 1 0 100 2zM22.75 6.22H10.806a1 1 0 100 2H22.75a1 1 0 100-2zM22.75 11H10.806a1 1 0 000 2H22.75a1 1 0 000-2zM22.75 15.776h-7.644a1 1 0 100 2h7.644a1 1 0 000-2zM22.75 20.553H10.806a1 1 0 000 2H22.75a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});