define("ember-svg-jar/inlined/modern-architecture-cloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-architecture-cloud</title><path d=\"M17.857 5.994H21a3 3 0 10-2.616-4.453.5.5 0 01-.455.258h-.075a2.1 2.1 0 100 4.2zM23 22h-5.25a.5.5 0 01-.5-.5v-1.91a.34.34 0 01.34-.34 1 1 0 00.961-1.275v-.01l-.336-1.11a.5.5 0 00-.478-.355H13.75a.75.75 0 010-1.5h3.225a.5.5 0 00.479-.645l-.152-.5a.5.5 0 00-.478-.355H13.75a.75.75 0 010-1.5h2.317a.5.5 0 00.478-.645l-2.923-9.647V1.7a1 1 0 00-.894-.7.992.992 0 00-.973.592V1.6l-1.214 2.8a.5.5 0 00-.041.2v14.9a.142.142 0 00.056.113.488.488 0 01.194.387v1.5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V20a.49.49 0 01.193-.387A.145.145 0 009 19.5V4.151a.5.5 0 00-.032-.175L7.727.647A1.03 1.03 0 006.79 0h-.054a1 1 0 00-.9.7L4.387 5.351A.5.5 0 004.864 6h.886a.75.75 0 010 1.5H4.085a.5.5 0 00-.477.351l-.156.5A.5.5 0 003.93 9h1.82a.75.75 0 010 1.5h-2.6a.5.5 0 00-.478.351l-.156.5A.5.5 0 003 12h2.75a.75.75 0 010 1.5H2.216a.5.5 0 00-.477.351L.457 17.966v.013a1 1 0 00.963 1.271h.33a.5.5 0 01.5.5v1.75a.5.5 0 01-.5.5H1a1 1 0 000 2h22a1 1 0 000-2zm-16.25-.5a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5v-1.75a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5zm8.5 0a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5v-1.75a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});