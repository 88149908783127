define("ember-svg-jar/inlined/warehouse-cart-package-ribbon-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>warehouse-cart-package-ribbon-1</title><path d=\"M10.731 20.211l-6.964-1.466a3 3 0 00-2.739.813l-.735.734a1 1 0 001.414 1.416l.734-.735a1 1 0 01.912-.272l6.969 1.468a.989.989 0 001.187-.9v-.02a.98.98 0 00-.778-1.038zM24 1a1 1 0 00-1-1h-1.9a3 3 0 00-2.928 2.349L14.943 16.9a.993.993 0 00.773 1.182l.027.006a.975.975 0 001.153-.754l3.233-14.551A.992.992 0 0121.1 2H23a1 1 0 001-1z\"/><circle cx=\"15\" cy=\"21.5\" r=\"2.5\"/><path d=\"M2.758 16.787l8.731 2.183a1 1 0 001.213-.728L16.1 4.66a1 1 0 00-.727-1.212L13.187 2.9a.248.248 0 00-.3.182l-.717 2.855a.5.5 0 01-.81.258l-.989-.847a.251.251 0 00-.217-.054l-1.271.282a.5.5 0 01-.593-.609L9 2.114a.252.252 0 00-.028-.19.258.258 0 00-.154-.114l-2.18-.545a1 1 0 00-1.212.727L2.03 15.574a1 1 0 00.728 1.213z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});