define("ember-svg-jar/inlined/official-building-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>official-building-2</title><path d=\"M3.241 6h17.518a.5.5 0 00.475-.655C20.2 2.2 16.408 0 12 0S3.8 2.2 2.766 5.345A.5.5 0 003.241 6zM23.25 24a.75.75 0 000-1.5h-.484a.5.5 0 01-.429-.243l-.908-1.514A.5.5 0 0021 20.5H3a.5.5 0 00-.429.243l-.908 1.514a.5.5 0 01-.429.243H.75a.75.75 0 000 1.5z\"/><rect x=\"3\" y=\"12\" width=\"3\" height=\"7.5\" rx=\".5\" ry=\".5\"/><rect x=\"8\" y=\"12\" width=\"3\" height=\"7.5\" rx=\".5\" ry=\".5\"/><rect x=\"13\" y=\"12\" width=\"3\" height=\"7.5\" rx=\".5\" ry=\".5\"/><rect x=\"18\" y=\"12\" width=\"3\" height=\"7.5\" rx=\".5\" ry=\".5\"/><path d=\"M1.912 10.317A1 1 0 002.86 11h18.28a1 1 0 00.949-.684l.666-2A1 1 0 0021.807 7H2.193a1 1 0 00-.948 1.316z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});