define("ember-svg-jar/inlined/real-estate-action-building-megaphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-building-megaphone</title><path d=\"M6.724 6.052l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 000 6.5V14a.5.5 0 00.5.5H2a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5A.5.5 0 007 14V6.5a.5.5 0 00-.276-.448zM5 10.5H2a.5.5 0 110-1h3a.5.5 0 110 1zM5 8H2a.5.5 0 010-1h3a.5.5 0 010 1zM8.5 14.5h4.853a.5.5 0 00.287-.09c.447-.314.835-.611 1.181-.9a.5.5 0 00.179-.385V3a.5.5 0 00-.308-.462l-6-2.5A.5.5 0 008 .5V14a.5.5 0 00.5.5zm1.5-10h3a.5.5 0 010 1h-3a.5.5 0 010-1zM10 7h3a.5.5 0 010 1h-3a.5.5 0 010-1zm0 2.5h3a.5.5 0 110 1h-3a.5.5 0 110-1zM23.882 18.212l-3.776-7.086a1 1 0 00-1.679-.134 18.247 18.247 0 01-6.644 5.774.817.817 0 00-.275 1.035l2.424 4.531a3.146 3.146 0 004.256 1.3.75.75 0 10-.7-1.324 1.636 1.636 0 01-1.254.123 1.668 1.668 0 01-.461-.223.5.5 0 01.082-.867 16.99 16.99 0 017.211-1.657 1 1 0 00.816-1.472zM10.512 18.061a.5.5 0 00-.682-.2l-.589.319a2.89 2.89 0 00-1.182 3.914.692.692 0 00.052.077 2.933 2.933 0 002.525 1.453A2.86 2.86 0 0012 23.277l.571-.308a.507.507 0 00.245-.3.5.5 0 00-.038-.383z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});