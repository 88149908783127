define("ember-svg-jar/inlined/marine-mammal-dolphin-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>marine-mammal-dolphin-1</title><path d=\"M16.413 3.126C14.056.621 10.964-.267 10.048.093a.639.639 0 00-.436.594c0 .409.312.721.672 1.082a3.881 3.881 0 01.692.829A11.5 11.5 0 002.429 19.33a10.992 10.992 0 00-1.317 4.154.5.5 0 00.821.384 17.888 17.888 0 013.387-2.221 17.447 17.447 0 013.91-1.006.5.5 0 00.269-.865 10.8 10.8 0 00-3.87-1.8 6.973 6.973 0 01.813-3.8 8.38 8.38 0 015.29-.1c-.161.194-.324.383-.489.564a.5.5 0 00.326.835 4.93 4.93 0 002.83-.623 12.865 12.865 0 003.613.686 5.053 5.053 0 004.388-2.302c1.055-2.108.952-7.679-5.987-10.11zm-7.726 8.565a7.031 7.031 0 014.61-1.18c.225.06.252.15.264.194a2.737 2.737 0 01-.581 1.641 14.08 14.08 0 00-4.293-.655zm7.649 1.621a12.31 12.31 0 001.777-2.552 10.137 10.137 0 012.552 1.4c-1.15 1.469-2.456 1.585-4.329 1.152z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});