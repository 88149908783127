define("ember-svg-jar/inlined/wild-bird-nest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wild-bird-nest</title><path d=\"M4.458 10.2a8.057 8.057 0 00-1.251 2.1.5.5 0 00.46.7h6.666a.5.5 0 00.46-.7 8.057 8.057 0 00-1.251-2.1 4.415 4.415 0 001.667-5.245l2.022-.48a1 1 0 10-.463-1.946l-.731.174.366-.772a1 1 0 00-1.803-.862l-.887 1.862A4.458 4.458 0 007 2a4.5 4.5 0 00-4.5 4.5 4.444 4.444 0 001.958 3.7zM8 6.5a1 1 0 111-1 1 1 0 01-1 1zM15.828 10.732c-.038.06-.655 1.038-.9 1.556a.5.5 0 00.455.71h5.226a.5.5 0 00.454-.71c-.24-.518-.859-1.5-.9-1.558A3.449 3.449 0 0021.5 8a3.506 3.506 0 00-.068-.676l1.715-.257a1 1 0 10-.295-1.978l-.741.111.322-.842a1 1 0 10-1.867-.716L20 5.129a3.478 3.478 0 00-2-.629 3.472 3.472 0 00-2.172 6.234zM18.5 8.5a1 1 0 111-1 1 1 0 01-1 1zM22 14.5H2a2 2 0 00-2 2c0 3.925 5.271 7 12 7s12-3.075 12-7a2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});