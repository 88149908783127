define("ember-svg-jar/inlined/video-game-wii-music", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-wii-music</title><path d=\"M5 13V4.585a1.032 1.032 0 01.853-.993l5.435-1.572A1 1 0 0112.5 3v4a.5.5 0 01-.5.5 2.5 2.5 0 102.5 2.5V3A2.986 2.986 0 0013.382.66 3.019 3.019 0 0010.8.082L5.362 1.654A3.019 3.019 0 003 4.585V10a.5.5 0 01-.5.5A2.5 2.5 0 105 13zM19.5 15h-11a4.5 4.5 0 103.877 6.749.5.5 0 01.433-.249h2.38a.5.5 0 01.433.249A4.492 4.492 0 1019.5 15zM10 20.247h-.25a.5.5 0 00-.5.5V21a.75.75 0 01-1.5 0v-.25a.5.5 0 00-.5-.5H7a.75.75 0 010-1.5h.25a.5.5 0 00.5-.5V18a.75.75 0 011.5 0v.25a.5.5 0 00.5.5H10a.75.75 0 010 1.5zm7.75-.05a.75.75 0 11.75-.75.75.75 0 01-.75.753zm2 2a.75.75 0 11.75-.75.75.75 0 01-.75.753zm0-4a.75.75 0 11.75-.75.75.75 0 01-.75.753zm2 2a.75.75 0 11.75-.75.75.75 0 01-.75.753z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});