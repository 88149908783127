define("ember-svg-jar/inlined/messages-bubble-square-search-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-search-alternate</title><path d=\"M8.5 14a1.006 1.006 0 00-.665.252L5 16.773V15a1 1 0 00-1-1H2.25a.251.251 0 01-.25-.25V2.25A.25.25 0 012.25 2h18a.25.25 0 01.25.25v8.385a.249.249 0 00.1.2 7.017 7.017 0 011.448 1.416.251.251 0 00.452-.151V2a2 2 0 00-2-2H2a2 2 0 00-2 2v12.053A1.953 1.953 0 002 16h1v3a1 1 0 001.665.748L8.88 16h.42a.249.249 0 00.248-.224 6.943 6.943 0 01.308-1.448.25.25 0 00-.241-.328z\"/><path d=\"M23.707 22.293l-2.534-2.534a.25.25 0 01-.034-.311 5.509 5.509 0 10-1.691 1.691.25.25 0 01.311.034l2.534 2.534a1 1 0 001.414-1.414zM16.5 20a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});