define("ember-svg-jar/inlined/computer-bug-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>computer-bug-2</title><path d=\"M6.733 5.436a.25.25 0 01.073.272A5.466 5.466 0 006.5 7.5a.25.25 0 00.25.25h10.5a.25.25 0 00.25-.25 5.455 5.455 0 00-.306-1.792.249.249 0 01.073-.272 8.509 8.509 0 002.677-4.171 1 1 0 10-1.927-.53 6.471 6.471 0 01-1.756 2.914.25.25 0 01-.352 0 5.47 5.47 0 00-7.819 0 .249.249 0 01-.352 0A6.492 6.492 0 015.983.734a1 1 0 00-1.927.531 8.507 8.507 0 002.677 4.171zM5.489 13.5H1.25a1 1 0 000 2h4.239a.249.249 0 01.232.343A4.487 4.487 0 011.5 18.5a1 1 0 000 2 6.724 6.724 0 004.884-2.047.068.068 0 01.074-.016.069.069 0 01.042.063 5.5 5.5 0 0011 0 .069.069 0 01.042-.063.068.068 0 01.074.016A6.724 6.724 0 0022.5 20.5a1 1 0 000-2 4.487 4.487 0 01-4.221-2.657.25.25 0 01.232-.343h4.239a1 1 0 000-2h-4.239a.249.249 0 01-.232-.344A4.488 4.488 0 0122.5 10.5a1 1 0 000-2 6.759 6.759 0 00-4.581 1.748.25.25 0 01-.419-.184V9.5a.25.25 0 00-.25-.25H6.75a.25.25 0 00-.25.25v.564a.25.25 0 01-.419.184A6.759 6.759 0 001.5 8.5a1 1 0 000 2 4.488 4.488 0 014.221 2.656.251.251 0 01-.232.344z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});