define("ember-svg-jar/inlined/page-words", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>page-words</title><g fill=\"none\" fill-rule=\"evenodd\"><rect fill=\"#F4F6F9\" width=\"83\" height=\"95.487\" rx=\"3\"/><path fill=\"#D8DDE4\" d=\"M9.549 13.221h41.867v3.673H9.549zM9.549 20.566h19.832v3.673H9.549zM31.584 20.566h12.487v3.673H31.584zM46.274 20.566h24.973v3.673H46.274zM9.549 27.912h27.177v3.673H9.549zM38.929 27.912h27.177v3.673H38.929zM9.549 35.257h5.142v3.673H9.549zM16.894 35.257h12.487v3.673H16.894zM31.584 35.257h19.832v3.673H31.584zM53.619 35.257h19.832v3.673H53.619zM9.549 42.602h34.522v3.673H9.549zM46.274 42.602h12.487v3.673H46.274zM60.965 42.602h12.487v3.673H60.965zM9.549 49.947h12.487v3.673H9.549zM24.239 49.947h27.177v3.673H24.239zM53.619 49.947h19.832v3.673H53.619zM9.549 57.292h19.832v3.673H9.549zM31.584 57.292h27.177v3.673H31.584zM60.965 57.292h12.487v3.673H60.965zM9.549 64.637h12.487v3.673H9.549zM24.239 64.637h19.832v3.673H24.239zM46.274 64.637h19.832v3.673H46.274zM68.31 64.637h5.142v3.673H68.31zM9.549 71.982h27.177v3.673H9.549zM38.929 71.982h27.177v3.673H38.929zM9.549 79.327h19.832V83H9.549zM31.584 79.327h27.177V83H31.584z\"/></g>",
    "attrs": {
      "width": "83",
      "height": "96",
      "viewBox": "0 0 83 96",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});