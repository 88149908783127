define("ember-svg-jar/inlined/keyboard-power-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-power-off</title><path d=\"M19.5.5h-15a4.012 4.012 0 00-4 4v15a4.011 4.011 0 004 4h15a4.011 4.011 0 004-4v-15a4.012 4.012 0 00-4-4zM4.5 5a.5.5 0 01.5-.5h4a.5.5 0 01.353.854L8.238 6.467a.252.252 0 000 .354l4.469 4.469a1 1 0 11-1.414 1.41L6.824 8.235a.25.25 0 00-.353 0L5.354 9.351A.5.5 0 015 9.5a.489.489 0 01-.191-.038A.5.5 0 014.5 9zM12 20.5a8.538 8.538 0 01-8.312-6.719 1 1 0 111.955-.418 6.5 6.5 0 107.633-7.738 1 1 0 11.391-1.96A8.5 8.5 0 0112 20.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});