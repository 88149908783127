define("ember-svg-jar/inlined/android-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>android-sync</title><path d=\"M12.75 19.067a6.029 6.029 0 014.56-5.824.248.248 0 00.19-.243v-2.5a.75.75 0 00-.75-.75h-12a.75.75 0 00-.75.75v10.024a1.222 1.222 0 001.212 1.228H12.5a.25.25 0 00.25-.25zM3.97 7.173A1 1 0 004.914 8.5h11.669a1 1 0 00.943-1.331A6.863 6.863 0 0014.142 3.3a.251.251 0 01-.12-.313l.634-1.626a1 1 0 10-1.863-.722l-.7 1.787a.249.249 0 01-.269.157 7.539 7.539 0 00-1.068-.083 7.653 7.653 0 00-1.079.081.249.249 0 01-.269-.157L8.721.642a1 1 0 00-1.867.719l.626 1.627a.25.25 0 01-.12.313 6.806 6.806 0 00-3.39 3.872zM13 5a.75.75 0 11-.75.75A.75.75 0 0113 5zM8.5 5a.75.75 0 11-.75.75A.75.75 0 018.5 5zM20.75 11a1 1 0 00-2 0v2a.251.251 0 00.239.25 5.9 5.9 0 011.44.24.249.249 0 00.321-.239zM2.75 18v-7a1 1 0 00-2 0v7a1 1 0 002 0zM22.6 20.03a.748.748 0 00-.953.467c-.03.088-.072.17-.11.254a2.957 2.957 0 01-2.694 1.75 2.927 2.927 0 01-1.09-.235 3.049 3.049 0 01-.9-.545.25.25 0 01-.011-.364l.758-.757a.5.5 0 00-.354-.853h-2.5a.5.5 0 00-.5.5v2.5a.5.5 0 00.309.462.508.508 0 00.191.038.5.5 0 00.354-.147l.326-.327a.252.252 0 01.349-.005 4.457 4.457 0 007.292-1.789.728.728 0 00.035-.268.747.747 0 00-.502-.681z\"/><path d=\"M23.25 15.751a.5.5 0 00-.309-.462.505.505 0 00-.545.108l-.326.327a.25.25 0 01-.349 0 4.459 4.459 0 00-7.292 1.789.734.734 0 00-.035.269.748.748 0 00.5.685.768.768 0 00.243.04.753.753 0 00.71-.507 2.74 2.74 0 01.11-.254A2.957 2.957 0 0118.653 16a3.074 3.074 0 011.982.788.246.246 0 01.081.179.252.252 0 01-.073.183l-.743.75a.5.5 0 00.354.854h2.5a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});