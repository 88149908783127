define("ember-svg-jar/inlined/flower-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flower-1</title><path d=\"M21.233 19.474a1 1 0 00-.851-.474H18a2.5 2.5 0 00-2 1l-2.022 3.235A.5.5 0 0014.4 24H18a2.5 2.5 0 002-1l1.276-2.553a1 1 0 00-.043-.973zM20.5 8.265V1.893A1.5 1.5 0 0018.132.669a6.362 6.362 0 00-1.141 1.038 1 1 0 00-.241.651v5.407a.75.75 0 01-1.5 0V1.992a1 1 0 00-.277-.691 4.047 4.047 0 00-5.946 0 1 1 0 00-.277.691v5.773a.75.75 0 11-1.5 0v-5.4a1 1 0 00-.241-.651A6.371 6.371 0 005.871.676 1.5 1.5 0 003.5 1.9v6.365a8.511 8.511 0 007.083 8.38.5.5 0 01.417.493V23a1 1 0 002 0v-5.862a.5.5 0 01.417-.493 8.511 8.511 0 007.083-8.38zM8 20a2.5 2.5 0 00-2-1H3.618a1 1 0 00-.894 1.447L4 23a2.5 2.5 0 002 1h3.6a.5.5 0 00.424-.765z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});