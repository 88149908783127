define("ember-svg-jar/inlined/sauna-heat-person-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sauna-heat-person-1</title><path d=\"M2.15 7.278a2.7 2.7 0 001.1-1.826 3.045 3.045 0 00-1.056-2.185c-.35-.4-.711-.806-.689-1.111a1.516 1.516 0 01.6-.837.749.749 0 10-.971-1.138A2.76 2.76 0 00.009 2.052a3.12 3.12 0 001.059 2.207c.345.39.7.8.681 1.088a1.435 1.435 0 01-.574.791.75.75 0 00.975 1.14zM5.749 5.347a1.435 1.435 0 01-.574.791.75.75 0 00.975 1.14 2.7 2.7 0 001.095-1.826 3.045 3.045 0 00-1.051-2.185c-.35-.4-.711-.806-.689-1.111a1.516 1.516 0 01.6-.837.749.749 0 10-.971-1.138 2.76 2.76 0 00-1.125 1.871 3.12 3.12 0 001.059 2.207c.345.39.702.795.681 1.088zM18.5 5.347a1.43 1.43 0 01-.574.791.75.75 0 00.975 1.14 2.7 2.7 0 001.095-1.826 3.045 3.045 0 00-1.056-2.185c-.349-.4-.711-.806-.689-1.111a1.516 1.516 0 01.6-.837.749.749 0 10-.971-1.138 2.76 2.76 0 00-1.125 1.871 3.12 3.12 0 001.059 2.207c.345.39.702.795.686 1.088zM22.94 3.267c-.349-.4-.711-.806-.689-1.111a1.516 1.516 0 01.6-.837.749.749 0 10-.971-1.138 2.76 2.76 0 00-1.125 1.871 3.12 3.12 0 001.059 2.207c.345.39.7.8.681 1.088a1.43 1.43 0 01-.574.791.75.75 0 00.975 1.14 2.7 2.7 0 001.095-1.826 3.045 3.045 0 00-1.051-2.185z\"/><circle cx=\"12\" cy=\"4\" r=\"2.75\"/><path d=\"M8.5 15.25h.663a.5.5 0 01.5.458L10 19.792a.5.5 0 00.5.458h3a.5.5 0 00.5-.458l.34-4.084a.5.5 0 01.5-.458h.66a.5.5 0 00.5-.5v-3a4 4 0 00-8 0v3a.5.5 0 00.5.5z\"/><path d=\"M19.209 13.073a1 1 0 00-.919 1.778c1.563.807 2.46 1.865 2.46 2.9C20.75 19.76 17.156 22 12 22s-8.75-2.24-8.75-4.25c0-1.035.9-2.092 2.459-2.9a1 1 0 00-.919-1.778c-2.283 1.181-3.54 2.842-3.54 4.677C1.25 21.255 5.972 24 12 24s10.75-2.745 10.75-6.25c0-1.835-1.258-3.495-3.541-4.677z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});