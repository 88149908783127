define("ember-svg-jar/inlined/business-deal-men-cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-deal-men-cash</title><path d=\"M11.559 13.962a.493.493 0 00.191.038.5.5 0 00.354-.146l2.78-2.784a.249.249 0 01.177-.07h3.689a1.5 1.5 0 001.5-1.5v-8a1.5 1.5 0 00-1.5-1.5h-14a1.5 1.5 0 00-1.5 1.5v8a1.5 1.5 0 001.5 1.5H11a.25.25 0 01.25.25v2.25a.5.5 0 00.309.462zM11.125 9v-.291a.249.249 0 00-.25-.25H9.417a.625.625 0 110-1.25h3.5a.542.542 0 000-1.084h-2.334a1.792 1.792 0 010-3.583h.292a.25.25 0 00.25-.25V2a.625.625 0 011.25 0v.292a.25.25 0 00.25.25h1.458a.625.625 0 010 1.25h-3.5a.542.542 0 000 1.083h2.334a1.792 1.792 0 010 3.584h-.292a.249.249 0 00-.25.25V9a.625.625 0 01-1.25 0zM.338 23.784A.5.5 0 00.75 24h10a.5.5 0 00.467-.679 5.951 5.951 0 00-2.876-3.21.249.249 0 01-.036-.424 4.25 4.25 0 10-5.042-.034.25.25 0 01-.041.425 5.922 5.922 0 00-2.939 3.244.5.5 0 00.055.462zm3.335-8.246a.251.251 0 01.366-.135 5.666 5.666 0 002.953.847 5.356 5.356 0 00.96-.087.09.09 0 01.105.087 2.25 2.25 0 01-4.5 0 2.209 2.209 0 01.116-.712z\"/><path d=\"M23.717 23.321a5.951 5.951 0 00-2.876-3.21.249.249 0 01-.036-.424 4.25 4.25 0 10-5.042-.034.25.25 0 01-.041.425 5.922 5.922 0 00-2.939 3.244.5.5 0 00.467.678h10a.5.5 0 00.467-.679zm-5.41-4.821a2.252 2.252 0 01-2.25-2.25 2.209 2.209 0 01.116-.712.251.251 0 01.366-.135 5.666 5.666 0 002.953.847 5.356 5.356 0 00.96-.087.09.09 0 01.1.087 2.253 2.253 0 01-2.245 2.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});