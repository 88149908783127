define("ember-svg-jar/inlined/body-scanner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>body-scanner</title><path d=\"M18 22h-3.995a.249.249 0 01-.249-.275l.445-4.45a.253.253 0 00-.063-.193.251.251 0 00-.185-.082h-3.906a.251.251 0 00-.185.082.253.253 0 00-.063.193l.445 4.45a.249.249 0 01-.249.275H6a1 1 0 000 2h12a1 1 0 000-2zM12 9.31a3.227 3.227 0 10-3.228-3.227A3.231 3.231 0 0012 9.31z\"/><path d=\"M15.765 13a.249.249 0 00.243-.309 4.119 4.119 0 00-8.016 0 .25.25 0 00.243.309zM5 15a2.5 2.5 0 00-1.364-2.224.249.249 0 01-.136-.222V1a1 1 0 00-2 0v11.554a.249.249 0 01-.136.222 2.494 2.494 0 000 4.447.252.252 0 01.136.223V23a1 1 0 002 0v-5.554a.252.252 0 01.136-.223A2.5 2.5 0 005 15zM24 15a2.5 2.5 0 00-1.364-2.224.249.249 0 01-.136-.222V1a1 1 0 00-2 0v11.554a.249.249 0 01-.136.222 2.494 2.494 0 000 4.447.252.252 0 01.136.223V23a1 1 0 002 0v-5.554a.252.252 0 01.136-.223A2.5 2.5 0 0024 15z\"/><circle cx=\"7.5\" cy=\"15\" r=\"1\"/><circle cx=\"10.5\" cy=\"15\" r=\"1\"/><circle cx=\"13.5\" cy=\"15\" r=\"1\"/><circle cx=\"16.5\" cy=\"15\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});