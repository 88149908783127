define("ember-svg-jar/inlined/smiley-smirk-glasses-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-smirk-glasses-1</title><path d=\"M22.6 6.379a2.775 2.775 0 00-.53-.92 1.255 1.255 0 00-.049-.054 11.991 11.991 0 00-20.041 0c-.016.018-.033.034-.048.053a2.785 2.785 0 00-.53.92A12 12 0 1024 12a11.918 11.918 0 00-1.4-5.621zM4.032 6.5h15.936a.781.781 0 01.774.893l-.493 3.447a2.894 2.894 0 01-2.471 1.428 4.328 4.328 0 01-4.2-1.686 1.843 1.843 0 00-3.16 0 4.322 4.322 0 01-4.195 1.687 2.9 2.9 0 01-2.472-1.429l-.493-3.447a.782.782 0 01.774-.893zM8.5 18.141a1 1 0 011.31-.541 5.511 5.511 0 006.41-1.644 1 1 0 111.56 1.251 7.511 7.511 0 01-8.74 2.239 1 1 0 01-.54-1.305z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});