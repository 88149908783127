define("ember-svg-jar/inlined/landmark-colosseum-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-colosseum-1</title><path d=\"M22.5 7.25H11.861a.5.5 0 00-.473.664l1.969 5.68a2.454 2.454 0 01.143.833v6.323a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V19a1.25 1.25 0 012.5 0v1.75a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V19a1.25 1.25 0 012.5 0v1.616a.5.5 0 00.751.432A1.5 1.5 0 0024 19.75v-11a1.5 1.5 0 00-1.5-1.5zm-4.5 7a.5.5 0 01-.5.5H16a.5.5 0 01-.5-.5V12.5a1.25 1.25 0 012.5 0zm4.5 0a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5V12.5a1.25 1.25 0 012.5 0zM.5 14.25h1a.5.5 0 00.5-.5V12.5a1.25 1.25 0 012.5 0v1.25a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V12.5a1.25 1.25 0 012.5 0v1.25a.5.5 0 00.5.5h2.324a.123.123 0 00.116-.165l-1.733-5a.5.5 0 00-.472-.336H.5a.5.5 0 00-.5.5v4.5a.5.5 0 00.5.501zM.5 7.75h8.542a.5.5 0 00.472-.664L8.239 3.408A1 1 0 007.3 2.75H1a1 1 0 00-1 1v3.5a.5.5 0 00.5.5zM4.75 5a.75.75 0 011.5 0v.5a.75.75 0 01-1.5 0zm-3 0a.75.75 0 011.5 0v.5a.75.75 0 01-1.5 0zM11.5 15.25H.5a.5.5 0 00-.5.5v4.5a1 1 0 001 1h.5a.5.5 0 00.5-.5V19a1.25 1.25 0 012.5 0v1.75a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V19A1.25 1.25 0 019 19v1.75a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-5a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});