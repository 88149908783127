define("ember-svg-jar/inlined/focus-camera-auto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>focus-camera-auto</title><path d=\"M21.5 5.5H18a.5.5 0 01-.4-.205l-.443-.607C16.134 3.277 15.57 2.5 14.5 2.5h-5c-1.07 0-1.634.777-2.657 2.188L6.4 5.3a.5.5 0 01-.4.2H2.5C.434 5.5 0 6.709 0 7.723v11.388A2.254 2.254 0 002.556 21.5h18.888A2.254 2.254 0 0024 19.111V7.723c0-1.014-.434-2.223-2.5-2.223zM18 13a6 6 0 11-6-6 6 6 0 016 6z\"/><path d=\"M12 9.5a2.253 2.253 0 00-2.25 2.25v4.5a.75.75 0 001.5 0v-1.5a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v1.5a.75.75 0 001.5 0v-4.5A2.253 2.253 0 0012 9.5zm-.5 3.5a.25.25 0 01-.25-.25v-1a.75.75 0 011.5 0v1a.25.25 0 01-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});