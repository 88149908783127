define("ember-svg-jar/inlined/chicken-hatch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chicken-hatch</title><circle cx=\"12.5\" cy=\"6\" r=\"1.25\"/><path d=\"M18.862 13.013a.5.5 0 00-.5.125l-1.509 1.509a.5.5 0 01-.707 0l-1.793-1.793a.5.5 0 00-.707 0l-1.293 1.293a.5.5 0 01-.707 0l-1.293-1.293a.5.5 0 00-.707 0l-1.792 1.793a.5.5 0 01-.707 0l-1.51-1.51a.5.5 0 00-.844.257A16.143 16.143 0 004.5 16.5a7.5 7.5 0 0015 0 16.207 16.207 0 00-.293-3.105.5.5 0 00-.345-.382z\"/><path d=\"M7.147 12.53a.5.5 0 00.705 0l.648-.649V5.5a3.5 3.5 0 017 0v6.378l.649.649a.5.5 0 00.7 0c.272-.267.607-.595.647-.626V8.5H19a.5.5 0 00.5-.5 3 3 0 00-2.01-2.832A5.5 5.5 0 006.5 5.5v6.4c.04.035.376.363.647.63z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});