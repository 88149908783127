define("ember-svg-jar/inlined/lift-three-people", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lift-three-people</title><path d=\"M2.5 4h1.25a.5.5 0 01.5.5v2a1.25 1.25 0 002.5 0v-2a.5.5 0 01.5-.5H8.5a.5.5 0 00.354-.854l-3-3a.5.5 0 00-.708 0l-3 3A.5.5 0 002.5 4zM18.146 7.854a.5.5 0 00.708 0l3-3A.5.5 0 0021.5 4h-1.25a.5.5 0 01-.5-.5v-2a1.25 1.25 0 00-2.5 0v2a.5.5 0 01-.5.5H15.5a.5.5 0 00-.354.854zM22.5 9h-21A1.5 1.5 0 000 10.5v12A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-12A1.5 1.5 0 0022.5 9zm-3.25 2.5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm-7.25 0a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm-7.25 0a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm2.25 7a.5.5 0 01-.5.5.385.385 0 00-.38.322l-.377 2.26A.5.5 0 015.25 22h-1a.5.5 0 01-.493-.418l-.377-2.26A.385.385 0 003 19a.5.5 0 01-.5-.5v-.75a2.25 2.25 0 014.5 0zm7.25 0a.5.5 0 01-.5.5.385.385 0 00-.38.322l-.377 2.26A.5.5 0 0112.5 22h-1a.5.5 0 01-.493-.418l-.377-2.26a.385.385 0 00-.38-.322.5.5 0 01-.5-.5v-.75a2.25 2.25 0 014.5 0zm7.25 0a.5.5 0 01-.5.5.385.385 0 00-.38.322l-.377 2.26a.5.5 0 01-.493.418h-1a.5.5 0 01-.493-.418l-.377-2.26A.385.385 0 0017.5 19a.5.5 0 01-.5-.5v-.75a2.25 2.25 0 014.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});