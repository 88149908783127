define("ember-svg-jar/inlined/car-dashboard-e", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-dashboard-e</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM3.743 10.154a1 1 0 011.514-1.308l6.132 7.1a.25.25 0 00.227.084 2.562 2.562 0 01.374-.03 2.51 2.51 0 11-2.51 2.51 2.454 2.454 0 01.286-1.144.251.251 0 00-.033-.279zM14.5 8.25a.75.75 0 010 1.5H13a.25.25 0 00-.25.25v1.5a.751.751 0 00.75.75H16a.75.75 0 010 1.5h-2.5a2.253 2.253 0 01-2.25-2.25v-5a2.253 2.253 0 012.25-2.25H16a.75.75 0 010 1.5h-2.5a.751.751 0 00-.75.75V8a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});