define("ember-svg-jar/inlined/task-list-to-do", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-list-to-do</title><path d=\"M20.5 2h-5a.241.241 0 01-.208-.121 3.828 3.828 0 00-6.589 0A.241.241 0 018.5 2h-5A1.5 1.5 0 002 3.5v19A1.5 1.5 0 003.5 24h17a1.5 1.5 0 001.5-1.5v-19A1.5 1.5 0 0020.5 2zM19 16.26a2.5 2.5 0 01-.6 1.626l-1.919 2.24a2.5 2.5 0 01-1.9.873H5.5a.5.5 0 01-.5-.5V5.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5z\"/><path d=\"M16 8.249h-4.5a.75.75 0 000 1.5H16a.75.75 0 100-1.5zM16 11.749h-4.5a.75.75 0 000 1.5H16a.75.75 0 100-1.5zM16 15.249h-4.5a.75.75 0 100 1.5H16a.75.75 0 100-1.5z\"/><circle cx=\"7.999\" cy=\"8.999\" r=\"1\"/><circle cx=\"7.999\" cy=\"12.499\" r=\"1\"/><circle cx=\"7.999\" cy=\"15.999\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});