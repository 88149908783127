define("ember-svg-jar/inlined/tools-hammer-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-hammer-1</title><path d=\"M23.707 8.6L17.8 2.694a.484.484 0 00-.15-.1L12.2.167a1.952 1.952 0 00-2.575 2.586l2.487 5.521a.492.492 0 00.1.148l5.835 5.836a1 1 0 001.414 0l4.243-4.243a1 1 0 000-1.414zM11.775 10.1a.5.5 0 00-.707 0L.586 20.585a2 2 0 102.828 2.828L13.9 12.932a.5.5 0 000-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});