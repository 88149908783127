define("ember-svg-jar/inlined/style-three-pin-gas-station", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-gas-station</title><path d=\"M11.5 6.5h-2a.5.5 0 000 1h2a.5.5 0 000-1z\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM7.5 7A1.5 1.5 0 019 5.5h3A1.5 1.5 0 0113.5 7v1a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5zm10.277.416l-1.054.7a.5.5 0 00-.223.416V11.5A2.5 2.5 0 0114 14h-.064a.5.5 0 00-.483.375A1.5 1.5 0 0112 15.5H9A1.5 1.5 0 017.5 14v-4a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v2.5a.5.5 0 00.5.5 1.5 1.5 0 001.5-1.5V8.535a1.5 1.5 0 01.668-1.248l1.055-.7a.5.5 0 01.554.832z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});