define("ember-svg-jar/inlined/real-estate-dimensions-plan-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-dimensions-plan-1</title><path d=\"M24 4.5A1.5 1.5 0 0022.5 3h-2a.5.5 0 01-.5-.5v-1A1.5 1.5 0 0018.5 0h-17A1.5 1.5 0 000 1.5v17.25a1.5 1.5 0 001.5 1.5h10.218a.5.5 0 01.479.357A4.76 4.76 0 0016.75 24h.5a1 1 0 001-1v-2.25a.5.5 0 01.5-.5H19a1 1 0 001-1V15a.5.5 0 01.5-.5h2A1.5 1.5 0 0024 13zm-22 10a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-2A.5.5 0 012 15zm2.5-3.25a.5.5 0 01.5.5V12a.5.5 0 01-.5.5h-2A.5.5 0 012 12v-.25a.5.5 0 01.5-.5zM2 17.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v.25a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5zm.5-8.25a.5.5 0 01-.5-.5V2.5a.5.5 0 01.5-.5h15a.5.5 0 01.5.5v3a.5.5 0 01-.5.5H16a1 1 0 00-1 1v.5a4 4 0 002.667 3.771.5.5 0 01.333.472v6.007a.5.5 0 01-.5.5h-.25a1 1 0 00-1 1v2.015a.5.5 0 01-.723.448A2.755 2.755 0 0114 19.25a1 1 0 00-1-1H7a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5h6a1 1 0 000-2h-3a.5.5 0 01-.5-.5V4a1 1 0 00-2 0v4.75a.5.5 0 01-.5.5zM22 12a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1.5a1 1 0 00-1-1 2 2 0 01-1.489-.666.5.5 0 01.372-.834H19a1 1 0 001-1V5.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});