define("ember-svg-jar/inlined/natural-disaster-volcano", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>natural-disaster-volcano</title><path d=\"M6.256 2.607C8.205 3.854 9 5.105 9 6.921a1 1 0 002 0c0-3.237-1.959-4.906-3.665-6a1 1 0 00-1.079 1.686zM3.057 10.346a2.8 2.8 0 013.912 0 1 1 0 001.431-1.4 4.739 4.739 0 00-6.769 0 1 1 0 101.428 1.4zM14 7.921a1 1 0 001-1c0-1.816.8-3.067 2.744-4.314A1 1 0 0016.666.922C14.959 2.015 13 3.684 13 6.921a1 1 0 001 1zM15.617 10.36a1 1 0 001.414-.014 2.8 2.8 0 013.912 0 1 1 0 101.429-1.4 4.74 4.74 0 00-6.77 0 1 1 0 00.015 1.414zM23.55 21.312c-2.377-1.672-6.462-4.97-7.127-8.277a1.059 1.059 0 00-1.037-.85H13.7a.5.5 0 00-.429.243l-.914 1.524a.346.346 0 00.3.525 1.5 1.5 0 011.22 2.372l-2.226 3.117a1 1 0 01-.815.419.986.986 0 01-.58-.186 1 1 0 01-.233-1.4l1.358-1.9a.27.27 0 00-.22-.426 1.5 1.5 0 01-1.286-2.272l.985-1.642a.25.25 0 00-.214-.378H8.572a1.057 1.057 0 00-1.051.939C7.138 16.5 2.463 19.966.47 21.3a1.059 1.059 0 00.589 1.939H22.94a1.058 1.058 0 00.61-1.924z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});