define("ember-svg-jar/inlined/navigation-menu-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-menu-3</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM7.5 15h9a1 1 0 010 2h-9a1 1 0 010-2zm-1-3a1 1 0 011-1h9a1 1 0 010 2h-9a1 1 0 01-1-1zm10-3h-9a1 1 0 010-2h9a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});