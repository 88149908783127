define("ember-svg-jar/inlined/ping-pong-paddle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ping-pong-paddle</title><path d=\"M4.569 11.628a.249.249 0 00-.426.191A9.4 9.4 0 005.2 15.642a.5.5 0 01-.089.585l-3.8 3.8a1.5 1.5 0 000 2.121l1.414 1.414a1.5 1.5 0 002.122 0l3.8-3.8a.5.5 0 01.585-.089 9.559 9.559 0 003.83 1.065.25.25 0 00.191-.426zM13.625 1.75q-.308 0-.609.02a.5.5 0 00-.455.611A5 5 0 017.687 8.5a4.966 4.966 0 01-2.347-.586.5.5 0 00-.71.279q-.133.393-.234.8a.5.5 0 00.133.473l10.88 10.881a.5.5 0 00.473.132A9.5 9.5 0 0013.625 1.75z\"/><path d=\"M7.687 7a3.5 3.5 0 10-3.5-3.5 3.5 3.5 0 003.5 3.5zm0-5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});