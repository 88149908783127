define("ember-svg-jar/inlined/remove-bold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>remove-bold</title><path d=\"M22.664 5.578a1.5 1.5 0 000-2.121l-2.121-2.121a1.5 1.5 0 00-2.122 0l-6.244 6.245a.251.251 0 01-.354 0L5.579 1.336a1.5 1.5 0 00-2.122 0L1.336 3.457a1.5 1.5 0 000 2.121l6.245 6.245a.252.252 0 010 .354l-6.245 6.245a1.5 1.5 0 000 2.121l2.121 2.121a1.5 1.5 0 002.122 0l6.244-6.245a.251.251 0 01.354 0l6.244 6.245a1.5 1.5 0 002.122 0l2.121-2.121a1.5 1.5 0 000-2.121l-6.245-6.245a.252.252 0 010-.354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});