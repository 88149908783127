define("ember-svg-jar/inlined/button-play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-play</title><path d=\"M12 24A12 12 0 100 12a12.013 12.013 0 0012 12zm4.812-11.5a.939.939 0 01-.587.824l-6.085 3.042a1.185 1.185 0 01-.531.133.919.919 0 01-.488-.136 1.032 1.032 0 01-.459-.911V9.546a.974.974 0 011.478-.914l6.085 3.043a.939.939 0 01.587.825z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});