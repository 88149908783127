define("ember-svg-jar/inlined/laboratory-test-stool-cup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laboratory-test-stool-cup</title><path d=\"M22.5.25h-21A1.5 1.5 0 000 1.75v2a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-2a1.5 1.5 0 00-1.5-1.5zM8.182 16.25a2.1 2.1 0 00-2.182 2 2.1 2.1 0 002.182 2h7.636a2.1 2.1 0 002.182-2 2.1 2.1 0 00-2.182-2 .2.2 0 01-.118-.35 1.368 1.368 0 00.266-.28c1.958-2.758-.984-5.1-2.412-6.111a.25.25 0 00-.395.205 2.679 2.679 0 01-2.665 2.679h-.852a1.982 1.982 0 00-1.967 1.546 1.945 1.945 0 00.663 1.884.244.244 0 01-.156.431z\"/><path d=\"M22 5.75h-1a.5.5 0 00-.5.5v13.5a2 2 0 01-2 2h-13a2 2 0 01-2-2V6.25a.5.5 0 00-.5-.5H2a.5.5 0 00-.5.5v13.5a4 4 0 004 4h13a4 4 0 004-4V6.25a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});