define("ember-svg-jar/inlined/business-contract-approve", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-contract-approve</title><path d=\"M23.651 22.279a.248.248 0 00.026-.27l-.9-1.74a.26.26 0 01-.028-.115v-4.505c.036-1.52-1.512-2.9-3.161-4.292a.251.251 0 01-.089-.191v-.517a.75.75 0 00-1.5 0v2.534a.248.248 0 00.073.176l2.457 2.457a.75.75 0 11-1.06 1.061L17.4 14.8a1.245 1.245 0 00-1.462 1.976l2.744 2.8a.251.251 0 01.072.175v1.4a6 6 0 00.94 2.4.252.252 0 00.175.116 4.07 4.07 0 003.782-1.388zM.323 2.175l.9 1.739a.257.257 0 01.028.115v4.505c-.037 1.6 1.672 3.038 3.411 4.5a.25.25 0 01.089.192v9.306a1.5 1.5 0 001.5 1.5h11a1 1 0 000-2h-10a.5.5 0 01-.5-.5V11.49a.744.744 0 01-.28-.176L3.72 8.564A.75.75 0 114.78 7.5l1.97 1.97v-.03A1.245 1.245 0 008.066 7.4L5.25 4.534v-1.5A5.984 5.984 0 004.31.63a.245.245 0 00-.175-.116A4.072 4.072 0 00.349 1.9a.25.25 0 00-.026.275z\"/><path d=\"M15 13.967a.75.75 0 00-.75-.75h-5.5a.75.75 0 000 1.5h5.5a.75.75 0 00.75-.75zM8.75 17.284a.75.75 0 000 1.5h3.5a.75.75 0 000-1.5zM19 4.967a5 5 0 10-5 5 5.006 5.006 0 005-5zm-2.15-1.246l-2.362 3.148a1.346 1.346 0 01-.992.54h-.1a1.343 1.343 0 01-.958-.4L11.22 5.794a.75.75 0 111.06-1.061l.9.9a.247.247 0 00.194.073.255.255 0 00.183-.1L15.65 2.82a.75.75 0 111.2.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});