define("ember-svg-jar/inlined/american-football-ball-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>american-football-ball-1</title><path d=\"M13.823 1.451a.5.5 0 00-.511-.121 19.106 19.106 0 00-7.53 4.422 19.235 19.235 0 00-4.428 7.593.5.5 0 00.121.51l8.67 8.671a.5.5 0 00.511.121 19.207 19.207 0 007.592-4.43 19.084 19.084 0 004.423-7.528.5.5 0 00-.121-.511zm2.361 7.18L14.845 9.97a.5.5 0 00-.146.354.5.5 0 00.147.353l.375.376a.75.75 0 01-1.06 1.06l-.376-.375a.5.5 0 00-.707 0l-1.345 1.345a.5.5 0 000 .707l.375.376a.75.75 0 01-1.06 1.06l-.376-.375a.5.5 0 00-.707 0l-1.339 1.338a.75.75 0 01-1.06-1.06L8.9 13.79a.5.5 0 000-.707l-.375-.376a.75.75 0 011.06-1.06l.376.375a.5.5 0 00.707 0l1.345-1.345a.5.5 0 000-.707l-.371-.37a.75.75 0 011.06-1.06l.375.375a.5.5 0 00.708 0l1.338-1.339a.75.75 0 111.061 1.06zM1.11 15.608a.249.249 0 00-.42.12 22.878 22.878 0 00-.641 6.708 1.69 1.69 0 001.514 1.514 22.8 22.8 0 006.709-.639.25.25 0 00.121-.42zM23.945 1.569A1.69 1.69 0 0022.431.054 22.755 22.755 0 0015.7.666a.25.25 0 00-.121.42l7.339 7.339a.251.251 0 00.421-.121 22.746 22.746 0 00.606-6.735z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});