define("ember-svg-jar/inlined/space-science", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>space-science</title><path d=\"M22.771 12.94a.7.7 0 01-.412-.637v-2.8a1 1 0 00-1-1 1 1 0 00-1 1v1.593a.5.5 0 01-.7.456l-.359-.16a1 1 0 00-1.41.912v3.2a.5.5 0 01-.5.5h-2.11a.5.5 0 01-.486-.385l-.934-3.946a4 4 0 00-.16-4.15.5.5 0 01-.025-.511 14.388 14.388 0 001.616-5.359A1.506 1.506 0 0012.733.442L7.967 5.208a.5.5 0 01-.707 0L4.07 2.019a1 1 0 00-1.414 1.414l3.189 3.19a.5.5 0 010 .707L1.078 12.1a1.507 1.507 0 001.059 2.568A14.136 14.136 0 007.65 13.04a.5.5 0 01.509.025 3.984 3.984 0 00.389.227.5.5 0 01.2.711l-1.1 1.762A.5.5 0 017.22 16H3.863a.5.5 0 00-.5.5v7a.5.5 0 00.5.5h4a.5.5 0 00.5-.5V22a2 2 0 014 0v1.5a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-9.647a1 1 0 00-.592-.913zm-10.063 2.965a.248.248 0 01-.2.1h-2.203a.248.248 0 01-.218-.129.251.251 0 01.006-.254l1.158-1.851a.5.5 0 01.277-.213 3.656 3.656 0 00.388-.141.252.252 0 01.341.173l.5 2.107a.251.251 0 01-.049.208zm.127-12.071A12.541 12.541 0 014.471 12.2a.25.25 0 01-.249-.416l8.2-8.2a.251.251 0 01.417.249z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});