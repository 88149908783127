define("ember-svg-jar/inlined/smiley-dizzy-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-dizzy-alternate</title><path d=\"M3.985 11.856a1 1 0 001.03-1.715A3.617 3.617 0 013.5 7a2.5 2.5 0 115 0 1 1 0 01-1 1C7.087 8 7 8 7 7.5a1 1 0 00-1-1 1 1 0 00-1 1A2.3 2.3 0 007.5 10a3 3 0 003-3 4.5 4.5 0 00-9 0 5.673 5.673 0 002.485 4.856z\"/><path d=\"M23 11a1 1 0 00-1 1 10 10 0 01-20 0 1 1 0 00-2 0 12 12 0 0024 0 1 1 0 00-1-1zM9.272 2.375a10.13 10.13 0 015.456 0 .983.983 0 00.272.038 1 1 0 00.272-1.962 12.121 12.121 0 00-6.544 0 1 1 0 00.544 1.924z\"/><path d=\"M13.5 7a5.673 5.673 0 002.485 4.857 1 1 0 001.03-1.715A3.617 3.617 0 0115.5 7a2.5 2.5 0 115 0 1 1 0 01-1 1c-.413 0-.5 0-.5-.5a1 1 0 00-1-1 1 1 0 00-1 1 2.3 2.3 0 002.5 2.5 3 3 0 003-3 4.5 4.5 0 00-9 0zM13.5 20.749a3 3 0 003-3v-1.526a.249.249 0 01.2-.244A1 1 0 0016.5 14h-9a1 1 0 000 2h2.75a.25.25 0 01.25.25v1.5a3 3 0 003 2.999zm1-3a1 1 0 01-2 0v-1.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});