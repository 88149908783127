define("ember-svg-jar/inlined/shrink-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shrink-4</title><rect x=\"9.015\" y=\"9.015\" width=\"6\" height=\"6\" rx=\"1.5\" ry=\"1.5\"/><path d=\"M21.977 16.323a.5.5 0 00-.462-.308h-5a.5.5 0 00-.5.5v5a.5.5 0 00.854.353l1.439-1.44a.252.252 0 01.354 0l2.2 2.2a1.25 1.25 0 001.768-1.768l-2.205-2.2a.252.252 0 010-.354l1.44-1.439a.5.5 0 00.112-.544zM8.015 2.515a.5.5 0 00-.853-.354L5.722 3.6a.249.249 0 01-.353 0L3.134 1.365a1.25 1.25 0 00-1.768 1.768L3.6 5.368a.249.249 0 010 .353l-1.438 1.44a.5.5 0 00.353.854h5a.5.5 0 00.5-.5zM22.634 1.365a1.251 1.251 0 00-1.768 0l-2.22 2.22a.249.249 0 01-.353 0l-1.424-1.424a.5.5 0 00-.854.354v5a.5.5 0 00.5.5h5a.5.5 0 00.354-.854l-1.455-1.455a.249.249 0 010-.353l2.22-2.22a1.251 1.251 0 000-1.768zM1.366 22.633a1.251 1.251 0 001.768 0l2.219-2.22a.252.252 0 01.354 0l1.455 1.455a.5.5 0 00.853-.353v-5a.5.5 0 00-.5-.5h-5a.5.5 0 00-.353.853l1.424 1.424a.252.252 0 010 .354l-2.22 2.219a1.251 1.251 0 000 1.768z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});