define("ember-svg-jar/inlined/sim-card-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sim-card-1</title><path d=\"M20.558 5.351l-5-4.912A1.494 1.494 0 0014.5 0h-10A1.5 1.5 0 003 1.5v21A1.5 1.5 0 004.5 24h15a1.5 1.5 0 001.5-1.5V6.414a1.513 1.513 0 00-.442-1.063zM6 15.25h3.628a2.261 2.261 0 000 1.5H6zm6 2.25a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm2.372-2.25H18v1.5h-3.628a2.261 2.261 0 000-1.5zM18 13.75h-4.93a2.513 2.513 0 00-.32-.122V10.5h3.75A1.5 1.5 0 0118 12zm-6.75-.122a2.513 2.513 0 00-.32.122H6V12a1.5 1.5 0 011.5-1.5h3.75zM6 18.25h4.93a2.513 2.513 0 00.32.122V21.5H7.5A1.5 1.5 0 016 20zm6.75.122a2.513 2.513 0 00.32-.122H18V20a1.5 1.5 0 01-1.5 1.5h-3.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});