define("ember-svg-jar/inlined/charging-wireless-charging-port", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>charging-wireless-charging-port</title><path d=\"M21 13V7.421a4 4 0 00-8 0v11.684a3 3 0 01-6 0V13a.5.5 0 00.5-.5v-1.708a5.5 5.5 0 10-3 0V12.5a.5.5 0 00.5.5v6.105a5 5 0 0010 0V7.421a2 2 0 014 0V13a2.5 2.5 0 00-2.5 2.5v4a.5.5 0 00.5.5h.25v3.25A.75.75 0 0018 24h4a.75.75 0 00.75-.75V20H23a.5.5 0 00.5-.5v-4A2.5 2.5 0 0021 13zM6 1.75A3.75 3.75 0 112.25 5.5 3.755 3.755 0 016 1.75zm15.25 20.5a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25V20.5a.25.25 0 01.25-.25h2a.25.25 0 01.25.25z\"/><circle cx=\"6\" cy=\"5.5\" r=\"2.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});