define("ember-svg-jar/inlined/movie-cinema-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>movie-cinema-clock</title><path d=\"M12 17a6 6 0 10-6-6 6.006 6.006 0 006 6zm0-10a4 4 0 11-4 4 4 4 0 014-4z\"/><path d=\"M12 11.75h1.768a.75.75 0 000-1.5H13a.25.25 0 01-.25-.25v-.768a.75.75 0 00-1.5 0V11a.75.75 0 00.75.75zM5 19a3 3 0 00-3 3v1.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V22a3 3 0 00-3-3zM12 19a3 3 0 00-3 3v1.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V22a3 3 0 00-3-3zM19 19a3 3 0 00-3 3v1.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V22a3 3 0 00-3-3zM5.489 16.984C5.29 12.5 2.5 12.5 2.5 12.5h-2a.5.5 0 00-.5.5v3.5a1 1 0 001 1h3.987a.5.5 0 00.502-.516zM.5 11.5H3a1 1 0 000-2H.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5zM23.5 12.5h-1.985s-2.79 0-2.989 4.484a.5.5 0 00.5.516H23a1 1 0 001-1V13a.5.5 0 00-.5-.5zM23.5 9.5h-2.485a1 1 0 000 2H23.5a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5z\"/><path d=\"M23 0H1a1 1 0 00-1 1v6.98a.5.5 0 00.522.5 8.505 8.505 0 007.494-5.65.5.5 0 01.471-.33h7.026a.5.5 0 01.471.334 8.505 8.505 0 007.494 5.65A.5.5 0 0024 7.98V1a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});