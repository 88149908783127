define("ember-svg-jar/inlined/search-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>search-alternate</title><path d=\"M23.384 21.619l-6.529-6.529a9.284 9.284 0 10-1.768 1.768l6.529 6.529a1.266 1.266 0 001.768 0 1.251 1.251 0 000-1.768zM2.75 9.5a6.75 6.75 0 116.75 6.75A6.758 6.758 0 012.75 9.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});