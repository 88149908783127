define("ember-svg-jar/inlined/seahorse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seahorse</title><path d=\"M18.023 8c-.911 0-2.237 1.245-2.466 1.466a7.581 7.581 0 011.494-3.095A4.163 4.163 0 0013.523 0a4.288 4.288 0 00-3.546 1.757A3.156 3.156 0 018.4 2.916a13.857 13.857 0 01-2.88.485.5.5 0 00-.5.5V5.5a.5.5 0 00.5.5h3.285a4.163 4.163 0 002.522 1.442 4.925 4.925 0 00-.8 3.058c0 5.12 4.7 4.338 4.7 8.75 0 1.906-.984 3-2.7 3a2.506 2.506 0 01-1.849-.768c-.891-.939-.9-2.6.141-2.481a.792.792 0 01.757.79c-.062 1.107 2.151 1.256 2.151-.541a2.5 2.5 0 00-4.635-1.289A3.941 3.941 0 008.523 20a4 4 0 004 4c4.588 0 5.77-6.375 3.863-11.029.773.39 1.831.836 2.137.529.791-.791.5-5.5-.5-5.5zm-6.5-3.5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});