define("ember-svg-jar/inlined/social-media-irc-galleria", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-irc-galleria</title><path d=\"M22.84 7.86a5.35 5.35 0 00-4.12-1.49 9.28 9.28 0 00-6.32 2.94 7.19 7.19 0 00-6.31-3C2.5 6.8.29 9 0 12.25a5.15 5.15 0 001.14 3.88 5.34 5.34 0 004.1 1.48 9.13 9.13 0 006.34-2.93 7.32 7.32 0 005.73 3h.58c3.57-.5 5.78-2.68 6.06-6a5.13 5.13 0 00-1.11-3.82zm-12.33 4.25c-.72 1.28-2.55 1.59-4 1.61-.17 0-.16.12-.11.26s.51.58.67.72.07.23-.1.28a2.71 2.71 0 01-2.74-.65 3.63 3.63 0 01-.79-3.62 3 3 0 013.09-1.87 3.15 3.15 0 011.87.85c.81.75 1.43 1.56 2 2a.25.25 0 01.11.42zm9.12 2.1a2.7 2.7 0 01-2.45.74c-.18 0-.44-.1-.44-.3a15.83 15.83 0 000-1.76.2.2 0 00-.3-.14c-1.25.43-2.4.37-3-.75-.1-.17-.06-.27.09-.38.59-.48 1.22-1.29 2-2a3.15 3.15 0 011.87-.85 3 3 0 013.08 1.87 3.62 3.62 0 01-.85 3.57z\"/><circle cx=\"16.89\" cy=\"10.87\" r=\"1\"/><circle cx=\"6.98\" cy=\"10.99\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});