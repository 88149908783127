define("ember-svg-jar/inlined/webcam.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>webcam</title><path d=\"M12 18.751a9 9 0 10-9-9 9.01 9.01 0 009 9zm0-16.5a1 1 0 11-1 1 1 1 0 011-1zm0 3.5a4 4 0 11-4 4 4 4 0 014-4z\"/><path d=\"M18.59 18.717a.5.5 0 00-.749-.247 10.635 10.635 0 01-11.682 0 .491.491 0 00-.435-.059.5.5 0 00-.314.306l-1.381 3.866a.5.5 0 00.471.668h15a.5.5 0 00.471-.668z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});