define("ember-svg-jar/inlined/social-pinterest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-pinterest</title><path d=\"M7.13 22.36a.4.4 0 00.29.33h.11a.45.45 0 00.36-.2 12.8 12.8 0 002.19-4c.09-.33.39-1.52.61-2.37a.25.25 0 01.4-.13 4.12 4.12 0 002.53.88c3.9 0 6.73-3.57 6.73-8.48 0-3.51-2.94-7.13-7.86-7.13C7 1.29 3.65 5.23 3.65 9c0 2.35 1 4.15 2.62 4.82a.66.66 0 00.94-.47l.24-1a.82.82 0 00-.22-.9 3.25 3.25 0 01-.73-2.2 5.47 5.47 0 015.67-5.5c2.93 0 4.75 1.67 4.75 4.36 0 3.5-1.53 6.14-3.56 6.14A1.73 1.73 0 0112 13.7a1.58 1.58 0 01-.28-1.38c.13-.54.3-1.1.47-1.65A10.19 10.19 0 0012.77 8a1.84 1.84 0 00-1.88-2C9.45 6 8.33 7.43 8.33 9.31a5.13 5.13 0 00.32 1.79.5.5 0 010 .29L7.17 17.7a13.38 13.38 0 00-.04 4.66z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});