define("ember-svg-jar/inlined/cog-approved-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cog-approved-1</title><path d=\"M13.261 16.234a2.989 2.989 0 01-2.287-.844l-3.637-3.636a3 3 0 014.243-4.243l1.263 1.263 3.288-4.121a1.873 1.873 0 01-2.382-1.142L13.3 2.245a1.875 1.875 0 00-3.533 0l-.45 1.266a1.875 1.875 0 01-2.573 1.065L5.531 4a1.875 1.875 0 00-2.5 2.5l.578 1.21a1.875 1.875 0 01-1.065 2.572l-1.265.45a1.876 1.876 0 000 3.533l1.265.45a1.874 1.874 0 011.065 2.571L3.033 18.5a1.875 1.875 0 002.5 2.5l1.213-.577a1.875 1.875 0 012.572 1.065l.45 1.266a1.875 1.875 0 003.533 0l.45-1.266a1.874 1.874 0 012.571-1.065l1.211.577a1.875 1.875 0 002.5-2.5l-.576-1.213a1.874 1.874 0 011.065-2.571l1.265-.45a1.875 1.875 0 000-3.533l-1.265-.45a1.862 1.862 0 01-.781-.531l-4.3 5.388a2.986 2.986 0 01-2.18 1.094z\"/><path d=\"M23.4.33a1.5 1.5 0 00-2.108.239l-8.324 10.452-2.449-2.449A1.5 1.5 0 108.4 10.694l3.637 3.635a1.515 1.515 0 002.233-.126l9.369-11.765A1.5 1.5 0 0023.4.33z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});