define("ember-svg-jar/inlined/dating-target", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dating-target</title><path d=\"M12.626 9.241l-.272.273a.5.5 0 01-.708 0l-.272-.273a2.269 2.269 0 00-3.209 3.209l3.429 3.578a.562.562 0 00.812 0l3.43-3.578a2.269 2.269 0 00-3.21-3.209z\"/><path d=\"M23 11h-1.623a.5.5 0 01-.493-.421 9.017 9.017 0 00-7.463-7.463.5.5 0 01-.421-.493V1a1 1 0 00-2 0v1.623a.5.5 0 01-.421.493 9.017 9.017 0 00-7.463 7.463.5.5 0 01-.493.421H1a1 1 0 000 2h1.622a.5.5 0 01.494.421 9.016 9.016 0 007.463 7.463.5.5 0 01.421.493V23a1 1 0 002 0v-1.623a.5.5 0 01.421-.493 9.017 9.017 0 007.463-7.463.5.5 0 01.493-.421H23a1 1 0 000-2zm-4.489 2a.344.344 0 01.337.414 7.009 7.009 0 01-5.435 5.434.343.343 0 01-.413-.336 1 1 0 00-2 0 .343.343 0 01-.413.336 7.009 7.009 0 01-5.435-5.434.344.344 0 01.337-.414 1 1 0 100-2 .344.344 0 01-.337-.414 7.009 7.009 0 015.433-5.433.344.344 0 01.415.337 1 1 0 102 0 .344.344 0 01.415-.337 7.009 7.009 0 015.433 5.433.344.344 0 01-.337.414 1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});