define("ember-svg-jar/inlined/smart-watch-square-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-settings</title><path d=\"M16.43 2.75a.251.251 0 00.238-.329L15.975.342A.5.5 0 0015.5 0h-7a.5.5 0 00-.475.342l-.693 2.079a.251.251 0 00.238.329zM7.57 21.25a.248.248 0 00-.2.1.251.251 0 00-.035.225l.693 2.079A.5.5 0 008.5 24h7a.5.5 0 00.475-.342l.693-2.079a.251.251 0 00-.035-.225.248.248 0 00-.2-.1zM17.5 4h-11A2.5 2.5 0 004 6.5v11A2.508 2.508 0 006.5 20h11a2.5 2.5 0 002.5-2.5v-11A2.5 2.5 0 0017.5 4zm-8.938 7.886l-.687-.741a1.393 1.393 0 011.33-2.306l.982.227a.159.159 0 00.19-.112l.295-.968a1.388 1.388 0 012.656 0l.295.968a.16.16 0 00.19.112l.982-.227a1.392 1.392 0 011.329 2.306l-.686.741a.17.17 0 000 .228l.688.741a1.393 1.393 0 01-1.33 2.306l-.982-.228a.163.163 0 00-.19.112l-.295.969a1.387 1.387 0 01-2.656 0l-.295-.969a.162.162 0 00-.19-.112l-.982.228a1.393 1.393 0 01-1.329-2.307l.686-.74a.169.169 0 00-.001-.228z\"/><circle cx=\"12\" cy=\"12\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});