define("ember-svg-jar/inlined/real-estate-action-house-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-house-disable</title><path d=\"M9.526 17.319a7.9 7.9 0 0113-6.054.494.494 0 00.318.116h.536a.5.5 0 00.34-.855L13.019.4a1.49 1.49 0 00-2.039 0L.278 10.526a.5.5 0 00.34.855H2.6a.494.494 0 01.494.495v9.9a.991.991 0 00.99.99h6.57a.5.5 0 00.4-.787 7.857 7.857 0 01-1.528-4.66z\"/><path d=\"M17.444 10.639a6.568 6.568 0 00-6.434 6.68A6.568 6.568 0 0017.444 24a6.568 6.568 0 006.433-6.681 6.568 6.568 0 00-6.433-6.68zm0 1.979a4.19 4.19 0 011.827.422.5.5 0 01.142.788l-5.244 5.483a.5.5 0 01-.815-.154 4.82 4.82 0 01-.364-1.838 4.588 4.588 0 014.454-4.701zm0 9.4a4.191 4.191 0 01-1.828-.422.5.5 0 01-.142-.788l5.244-5.483a.5.5 0 01.815.153 4.827 4.827 0 01.364 1.838 4.587 4.587 0 01-4.453 4.705z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});