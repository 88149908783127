define("ember-svg-jar/inlined/smart-watch-square-settings-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-settings-alternate</title><path d=\"M21 6a3 3 0 00-3-3h-1.078a.249.249 0 01-.24-.182L15.98.365A.5.5 0 0015.5 0h-7a.5.5 0 00-.481.363l-.7 2.455A.248.248 0 017.077 3H6a3 3 0 00-3 3v12a3 3 0 003 3h1.077a.249.249 0 01.24.181l.7 2.456A.5.5 0 008.5 24h7a.5.5 0 00.48-.363l.7-2.456a.25.25 0 01.24-.181H18a3 3 0 003-3zm-2 12a1 1 0 01-1 1H6a1 1 0 01-1-1V6a1 1 0 011-1h12a1 1 0 011 1z\"/><path d=\"M15.437 12.116a.171.171 0 010-.229l.686-.74A1.393 1.393 0 0014.8 8.841l-.982.227a.16.16 0 01-.19-.112l-.295-.968a1.388 1.388 0 00-2.657 0l-.294.968a.161.161 0 01-.191.112l-.981-.227a1.393 1.393 0 00-1.33 2.306l.687.74a.169.169 0 010 .229l-.685.74a1.393 1.393 0 001.329 2.307l.981-.228a.164.164 0 01.191.112l.294.969a1.388 1.388 0 002.657 0l.295-.969a.163.163 0 01.19-.112l.982.228a1.393 1.393 0 001.33-2.306zM12 13.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});