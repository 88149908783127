define("ember-svg-jar/inlined/nuclear-energy-bin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>nuclear-energy-bin</title><path d=\"M23 3.5h-5.75a.5.5 0 01-.5-.5V2a2 2 0 00-2-2h-5.5a2 2 0 00-2 2v1a.5.5 0 01-.5.5H1a1 1 0 000 2h2a.5.5 0 01.5.5v16.5A1.5 1.5 0 005 24h14a1.5 1.5 0 001.5-1.5V6a.5.5 0 01.5-.5h2a1 1 0 000-2zm-13.75-1a.5.5 0 01.5-.5h4.5a.5.5 0 01.5.5V3a.5.5 0 01-.5.5h-4.5a.5.5 0 01-.5-.5zm4 11A1.25 1.25 0 1112 12.25a1.252 1.252 0 011.25 1.25zm-7.1-.163a.5.5 0 01-.129-.383 6.032 6.032 0 012.862-4.582.5.5 0 01.71.2l.9 1.8a.5.5 0 01-.167.638A3 3 0 009 13.5H6.523a.5.5 0 01-.369-.163zm9.25 4.907a.494.494 0 01-.226.332 5.919 5.919 0 01-6.355 0 .5.5 0 01-.134-.723l1.2-1.6a.5.5 0 01.644-.135 3 3 0 002.932 0 .5.5 0 01.644.135l1.2 1.6a.5.5 0 01.095.391zm2.444-4.908a.5.5 0 01-.37.163h-2.008a.5.5 0 01-.495-.429 3 3 0 00-1.3-2.061.5.5 0 01-.167-.639l.9-1.8a.5.5 0 01.311-.257.5.5 0 01.4.056 6.033 6.033 0 012.858 4.582.5.5 0 01-.126.385z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});