define("ember-svg-jar/inlined/school-test-physical-education", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>school-test-physical-education</title><path d=\"M15.956 12.2a.5.5 0 00-.064-.427A6.809 6.809 0 006.1 10.205.25.25 0 015.7 10a8.418 8.418 0 00-.336-2.77.5.5 0 01.161-.53A3.334 3.334 0 006.74 4.12 3.373 3.373 0 003.024.767 3.491 3.491 0 000 4.284v9.021a3.949 3.949 0 003.944 3.945H9a.5.5 0 00.5-.5v-.415a2 2 0 011.26-1.868l4.9-1.947a.5.5 0 00.296-.32zM23.685 15.86l-6-2.382a.5.5 0 00-.37 0l-6 2.382a.5.5 0 00-.315.475v3.13a.5.5 0 001 0v-2.08a.25.25 0 01.333-.236l5 1.765a.49.49 0 00.332 0l6-2.117a.5.5 0 00.019-.937z\"/><path d=\"M20.763 19.079l-2.93 1.034a1 1 0 01-.666 0l-2.93-1.034a.5.5 0 00-.666.472V21.7a.5.5 0 00.334.471l2.573.908a3.069 3.069 0 002.043 0l2.574-.908a.5.5 0 00.334-.471v-2.149a.5.5 0 00-.666-.472z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});