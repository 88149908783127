define("ember-svg-jar/inlined/pin-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin-location</title><path d=\"M12 18.5a.5.5 0 00.313-.11c.294-.237 7.187-5.831 7.187-9.89a7.5 7.5 0 00-15 0c0 4.059 6.893 9.653 7.187 9.89a.5.5 0 00.313.11zm0-13A2.5 2.5 0 119.5 8 2.5 2.5 0 0112 5.5zM2.5 21.5A1.5 1.5 0 004 23h16a1.5 1.5 0 000-3H4a1.5 1.5 0 00-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});