define("ember-svg-jar/inlined/style-one-pin-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-check</title><path d=\"M12 0a9.5 9.5 0 00-4 18.119 2.5 2.5 0 011.218 1.222l1.873 4.076a1 1 0 001.817 0l1.876-4.08A2.5 2.5 0 0116 18.118 9.5 9.5 0 0012 0zm0 15.5a6 6 0 116-6 6.006 6.006 0 01-6 6z\"/><path d=\"M13.874 7.155l-2.559 3.412a.249.249 0 01-.377.027L9.844 9.5a1 1 0 00-1.414 1.414l1.5 1.5a1.743 1.743 0 001.235.517h.127a1.742 1.742 0 001.278-.7l2.9-3.875a1 1 0 00-1.6-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});