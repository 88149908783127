define("ember-svg-jar/inlined/vectors-pen-new-anchor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vectors-pen-new-anchor</title><path d=\"M23.5 19a1 1 0 00-1-1h-3.7a.25.25 0 01-.194-.093 8.548 8.548 0 00-3.453-2.545.25.25 0 01-.153-.232V13.5a1 1 0 00-1-1h-4a1 1 0 00-1 1v1.63a.25.25 0 01-.157.232 8.548 8.548 0 00-3.453 2.545.25.25 0 01-.19.093H1.5a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1v-3.141a.248.248 0 01.04-.135 6.5 6.5 0 012.449-2.23H9v.006a1 1 0 001 1h4a1 1 0 001-1v-.006h.007a6.506 6.506 0 012.45 2.23.248.248 0 01.04.135V23a1 1 0 001 1h4a1 1 0 001-1zm-21 1.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25zm8.75-3.75a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25zm10.25 5.25a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25zM11.265 10.9a.749.749 0 001.47 0c.373-1.864 2.867-4.686 3.8-5.616a.749.749 0 00.172-.794l-1.5-4A.749.749 0 0014.5 0h-5a.749.749 0 00-.7.486l-1.5 4a.749.749 0 00.172.794c.928.93 3.42 3.752 3.793 5.62zM12 2.25a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});