define("ember-svg-jar/inlined/remove-circle-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>remove-circle-alternate</title><path d=\"M.249 12A11.751 11.751 0 1012 .249 11.765 11.765 0 00.249 12zm21 0A9.251 9.251 0 1112 2.749 9.262 9.262 0 0121.251 12z\"/><path d=\"M8.833 7.066a1.25 1.25 0 10-1.768 1.768l2.99 2.989a.25.25 0 010 .354l-2.99 2.99a1.25 1.25 0 101.768 1.767l2.989-2.989a.249.249 0 01.354 0l2.99 2.989a1.249 1.249 0 101.767-1.767l-2.989-2.99a.25.25 0 010-.354l2.989-2.989a1.25 1.25 0 10-1.767-1.768l-2.99 2.99a.251.251 0 01-.354 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});