define("ember-svg-jar/inlined/exotic-food-kebab-shred", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>exotic-food-kebab-shred</title><path d=\"M5.112 1.851L5.3 1.6A1 1 0 103.7.4l-.411.548a2.057 2.057 0 00.081 2.578.5.5 0 01.018.623L3.2 4.4a1 1 0 101.6 1.2l.411-.548a2.058 2.058 0 00-.081-2.579.5.5 0 01-.018-.622zM20.612 4.149a.5.5 0 01.018-.623 2.057 2.057 0 00.081-2.578L20.3.4a1 1 0 00-1.6 1.2l.188.25a.5.5 0 01-.018.623 2.057 2.057 0 00-.081 2.578l.411.549a1 1 0 101.6-1.2zM16.5 22h-3a.5.5 0 01-.5-.5v-.3a.252.252 0 00-.285-.248 5.272 5.272 0 01-.746.052 5.189 5.189 0 01-.686-.045.246.246 0 00-.2.06.25.25 0 00-.083.181v.3a.5.5 0 01-.5.5h-3a1 1 0 000 2h9a1 1 0 000-2zM7.685 16.3a4.356 4.356 0 004.284 3.7 4.293 4.293 0 001.869-.424.5.5 0 00.277-.367L16 8.037a.5.5 0 00-.129-.426 4.286 4.286 0 00-2.454-1.306.5.5 0 01-.417-.493V5a1 1 0 00-1-1 1 1 0 111-1 1 1 0 002 0 3 3 0 10-4.19 2.754.317.317 0 01.19.29.227.227 0 01-.205.226 4.332 4.332 0 00-3.864 4.946zm5.065-2.05a.5.5 0 010 1h-2.742a.5.5 0 010-1zm-4-4.5h2.614a.5.5 0 010 1H8.75a.5.5 0 010-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});