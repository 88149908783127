define("ember-svg-jar/inlined/surveillance-camera-smartphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>surveillance-camera-smartphone</title><path d=\"M13.124 0h-12a1 1 0 00-.947 1.316l.22 1A1 1 0 001.346 3H12.9a1 1 0 00.949-.684l.22-1A1 1 0 0013.124 0zM13.1 5.045a.5.5 0 00-.5-.545H1.648a.5.5 0 00-.5.545 6 6 0 0011.952 0zM7.125 9a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM22.125 11h-6a1.5 1.5 0 00-1.5 1.5v10a1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5v-10a1.5 1.5 0 00-1.5-1.5zm0 9.5a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5V13a.5.5 0 01.5-.5h5a.5.5 0 01.5.5zM23.6 5.006a7.073 7.073 0 00-8.942 0 .75.75 0 10.942 1.168 5.577 5.577 0 017.058 0 .75.75 0 00.942-1.168z\"/><path d=\"M17.263 8.584a2.944 2.944 0 013.724 0 .751.751 0 00.943-1.168 4.437 4.437 0 00-5.61 0 .751.751 0 00.943 1.168zM3.125 15a1 1 0 001-1v-2a1 1 0 00-2 0v2a1 1 0 001 1zM12.125 19.5h-2.5a1 1 0 000 2h2.5a1 1 0 000-2zM5.625 19.5h-1.5V18a1 1 0 00-2 0v1.5a2 2 0 002 2h1.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});