define("ember-svg-jar/inlined/music-concert-ticket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-concert-ticket</title><path d=\"M20 12a2.5 2.5 0 012.5-2.5h.5a1 1 0 001-1V4a3 3 0 00-3-3H3a3 3 0 00-3 3v4.5a1 1 0 001 1h.5a2.5 2.5 0 010 5H1a1 1 0 00-1 1V20a3 3 0 003 3h18a3 3 0 003-3v-4.5a1 1 0 00-1-1h-.5A2.5 2.5 0 0120 12zm1.789 4.444a.249.249 0 01.211.246V20a1 1 0 01-1 1H3a1 1 0 01-1-1v-3.31a.248.248 0 01.211-.246 4.5 4.5 0 000-8.888A.249.249 0 012 7.31V4a1 1 0 011-1h18a1 1 0 011 1v3.31a.248.248 0 01-.211.246 4.5 4.5 0 000 8.888z\"/><path d=\"M5 4a1 1 0 101 1 1 1 0 00-1-1zM9.5 6a1 1 0 10-1-1 1 1 0 001 1zM14.5 6a1 1 0 10-1-1 1 1 0 001 1zM20 5a1 1 0 10-1 1 1 1 0 001-1zM5 18a1 1 0 101 1 1 1 0 00-1-1zM9.5 18a1 1 0 101 1 1 1 0 00-1-1zM14.5 18a1 1 0 101 1 1 1 0 00-1-1zM19 20a1 1 0 10-1-1 1 1 0 001 1zM16.25 9.24a2 2 0 00-2.369-1.966c-.041.008-3.57 1.14-3.57 1.14a2 2 0 00-1.561 1.951v2.984a.25.25 0 01-.188.242 1.75 1.75 0 102.187 1.746v-4.79a.25.25 0 01.174-.238l3-.957a.25.25 0 01.326.238v1.759a.25.25 0 01-.188.242 1.75 1.75 0 102.187 1.746v-.052z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});