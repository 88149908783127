define("ember-svg-jar/inlined/dial-pad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dial-pad</title><circle cx=\"4.159\" cy=\"2.591\" r=\"2.591\"/><circle cx=\"11.968\" cy=\"2.591\" r=\"2.591\"/><path d=\"M19.841 5.182a2.591 2.591 0 10-2.591-2.591 2.594 2.594 0 002.591 2.591zM4.159 6.273a2.591 2.591 0 102.591 2.59 2.593 2.593 0 00-2.591-2.59zM11.968 6.273a2.591 2.591 0 102.59 2.59 2.593 2.593 0 00-2.59-2.59zM19.841 6.273a2.591 2.591 0 102.591 2.59 2.593 2.593 0 00-2.591-2.59zM4.159 12.546a2.591 2.591 0 102.591 2.591 2.594 2.594 0 00-2.591-2.591zM11.968 12.546a2.591 2.591 0 102.59 2.591 2.594 2.594 0 00-2.59-2.591z\"/><circle cx=\"11.968\" cy=\"21.409\" r=\"2.591\"/><path d=\"M19.841 12.546a2.591 2.591 0 102.591 2.591 2.594 2.594 0 00-2.591-2.591z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});