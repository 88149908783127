define("ember-svg-jar/inlined/headphones-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>headphones-person</title><path d=\"M21.114 8.313a.25.25 0 01-.159-.209 9 9 0 00-17.91 0 .25.25 0 01-.159.209A4.5 4.5 0 004.121 17 .814.814 0 005 16.188V9a7 7 0 0114 0v7.188a.815.815 0 00.881.809 4.5 4.5 0 001.233-8.684z\"/><circle cx=\"12\" cy=\"12\" r=\"5\"/><path d=\"M12 18.5a9.055 9.055 0 00-7.933 4.749A.5.5 0 004.5 24h15a.5.5 0 00.433-.751A9.055 9.055 0 0012 18.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});