define("ember-svg-jar/inlined/road-sign-no-right-turn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-no-right-turn</title><path d=\"M21.139 4.251a.252.252 0 00-.181-.088.249.249 0 00-.187.073L17.72 7.287a.75.75 0 01-1.061-1.06l3.048-3.048a.25.25 0 00.073-.188.253.253 0 00-.089-.181 11.981 11.981 0 00-16.88 16.881.25.25 0 00.368.016l2.587-2.587a.75.75 0 011.061 1.061l-2.591 2.59a.249.249 0 00-.073.187.252.252 0 00.088.181A11.98 11.98 0 0021.139 4.251zm-9.693 7A1.252 1.252 0 0010.2 12.5v4a.75.75 0 01-1.5 0v-4a2.753 2.753 0 012.75-2.75h1a.25.25 0 00.25-.25v-1a.75.75 0 011.281-.53l2 2a.754.754 0 010 1.06l-2 2a.75.75 0 01-.531.22.761.761 0 01-.287-.057.75.75 0 01-.463-.693v-1a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});