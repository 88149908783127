define("ember-svg-jar/inlined/cap-sunglasses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cap-sunglasses</title><path d=\"M20.945 16.336h-14a2.753 2.753 0 00-2.75 2.75v1a3.755 3.755 0 003.75 3.75h1.5a3.755 3.755 0 003.75-3.75v-1.75a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v1.75a3.755 3.755 0 003.75 3.75h1.5a3.755 3.755 0 003.75-3.75v-1a2.752 2.752 0 00-2.75-2.75zM18.2 13.367a1.413 1.413 0 001.483-1.622l-.228-1.509a9.637 9.637 0 00-9.17-8.172 2.023 2.023 0 10-3.958.7A9.639 9.639 0 00.414 13.125l.229 1.507a1.413 1.413 0 001.9 1.11 39.578 39.578 0 017.741-1.763 39.382 39.382 0 017.916-.612zm-10.741-.9a.454.454 0 01-.15-.361 2.739 2.739 0 015.306-.8.457.457 0 01-.361.608l-4.421.67a.454.454 0 01-.378-.116z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});