define("ember-svg-jar/inlined/single-woman-vintage-tv.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-vintage-tv</title><path d=\"M24 3.249a1.5 1.5 0 00-1.5-1.5h-21a1.5 1.5 0 00-1.5 1.5v14.5a1.5 1.5 0 001.5 1.5h.8a.25.25 0 01.2.406l-.775.969a1 1 0 101.562 1.25l1.95-2.437a.5.5 0 01.391-.188h12.75a.5.5 0 01.391.188l1.95 2.437a1 1 0 001.562-1.25l-.775-.969a.25.25 0 01.2-.406h.8a1.5 1.5 0 001.5-1.5zM5.335 10.725a.247.247 0 01-.051-.372c.3-.338.51-.746.6-2.086a3.12 3.12 0 116.234 0c.089 1.34.3 1.748.6 2.086a.247.247 0 01-.051.372 3.7 3.7 0 01-2.216.556.25.25 0 00-.25.25v.207a.488.488 0 00.313.458c1.773.67 3.184 1.148 3.522 1.837a5.047 5.047 0 01.464 1.717.251.251 0 01-.062.187.24.24 0 01-.177.08H3.74a.24.24 0 01-.177-.08.245.245 0 01-.063-.187 5.047 5.047 0 01.465-1.717c.337-.689 1.749-1.167 3.522-1.837a.488.488 0 00.313-.458v-.2a.249.249 0 00-.251-.25 3.737 3.737 0 01-2.214-.563zM17.5 17.249a.5.5 0 01-.5-.5V4a.5.5 0 111 0v12.75a.5.5 0 01-.5.499zm2.25-2.5A.75.75 0 0120.5 14h1a.75.75 0 010 1.5h-1a.75.75 0 01-.75-.751zM21.5 12.5h-1a.75.75 0 010-1.5h1a.75.75 0 010 1.5zM21 8.749a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});