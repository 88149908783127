define("ember-svg-jar/inlined/movie-monster", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>movie-monster</title><path d=\"M22.725 3.647c-1.166-1.166-2.94-1.191-4.631-.063-1.465.977-6.413 3.7-6.522 3.766a.75.75 0 11-.9-1.2l1-.749a.5.5 0 00.054-.753l-.131-.131a.251.251 0 01.015-.368.7.7 0 00.258-.569A3.754 3.754 0 008.121 0a3.647 3.647 0 00-1.25.215 37.408 37.408 0 00-3.685 1.321A4.3 4.3 0 00.564 4.055a.978.978 0 00.172.969.991.991 0 00.938.349l.393-.065a.25.25 0 01.246.389q-.168.24-.358.526a2.312 2.312 0 00-.477 1.861 1.383 1.383 0 00.67.863.5.5 0 00.447 0 .245.245 0 01.329.329 1.573 1.573 0 00.724 2.171.5.5 0 00.481-.018l1.493-.9a.5.5 0 01.721.242L7.9 14.657a2.364 2.364 0 01.121 1.49c-.505.5-4.357 4.836-4.52 5.021a.5.5 0 00-.027.632c.276.367 1.71 2.2 2.9 2.2h1.5a1 1 0 00.8-1.6l-.379-.506a.5.5 0 01.046-.654l2.387-2.386a8.992 8.992 0 012.805-1.879l8.658-2.887a1 1 0 00.683-.948v-2.492A1 1 0 0021.21 9.9a8.412 8.412 0 01-1.562 1.155 21.376 21.376 0 01-2.809.969c-.561.167-1.121.335-1.626.5l-.379.129c-.7.24-1.295.445-1.593.3a1 1 0 01-.4-.608 13.59 13.59 0 01-.378-1.3.5.5 0 01.185-.51A28.675 28.675 0 0116.6 7.947a1.532 1.532 0 011.977.3A1 1 0 0020.181 8l.419-.838a.5.5 0 01.349-.267l1.724-.345a.99.99 0 00.792-.8 2.347 2.347 0 00-.74-2.103zm-14.72.28l.22.22a.5.5 0 01-.707.707l-.22-.22a.249.249 0 00-.353 0l-.22.22a.5.5 0 11-.707-.707l.219-.22a.25.25 0 000-.354l-.219-.219a.5.5 0 11.707-.707l.22.219a.249.249 0 00.353 0l.22-.219a.5.5 0 01.707.707l-.22.219a.25.25 0 000 .354z\"/><path d=\"M18.505 16.75a.75.75 0 00-.75.75v2a.75.75 0 001.5 0v-2a.75.75 0 00-.75-.75zM3.755 16v-2a.75.75 0 00-1.5 0v2a.75.75 0 001.5 0zM22.505 19.25a.75.75 0 00-.75.75v2a.75.75 0 001.5 0v-2a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});