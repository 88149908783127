define("ember-svg-jar/inlined/professions-man-post.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-post</title><circle cx=\"14\" cy=\"8.5\" r=\"1\"/><circle cx=\"10\" cy=\"8.5\" r=\"1\"/><path d=\"M13.891 15.846a9.9 9.9 0 01-1.679 4.674.249.249 0 01-.424 0 9.911 9.911 0 01-1.679-4.674.8.8 0 00-.268-.514.736.736 0 00-.553-.17 21.808 21.808 0 00-3.925.739.751.751 0 00-.508.5l-.046.146a.246.246 0 01-.151.16 12.544 12.544 0 00-3.192 1.713A2.464 2.464 0 00.5 20.394V23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-3.106a2.465 2.465 0 00-.967-1.977 12.569 12.569 0 00-3.191-1.717.246.246 0 01-.151-.16l-.046-.146a.751.751 0 00-.508-.5 21.808 21.808 0 00-3.925-.739.735.735 0 00-.553.17.8.8 0 00-.268.521zm4.557 2.942a.25.25 0 01-.3.318l-1.508-.357a.625.625 0 00-.769.608v2.06a.25.25 0 01-.211.247l-1.893.3a.25.25 0 01-.258-.366 14.66 14.66 0 001.644-4.756.249.249 0 01.281-.209 20.031 20.031 0 012.36.464.253.253 0 01.176.167zm-9.887-2.156a.249.249 0 01.281.21 15.2 15.2 0 001.686 4.9.25.25 0 01-.251.367l-1.933-.244a.25.25 0 01-.219-.248V19.5a.625.625 0 00-.741-.614l-1.57.3a.25.25 0 01-.285-.32l.5-1.6A.251.251 0 016.2 17.1a20.014 20.014 0 012.361-.468z\"/><path d=\"M12.018 15.5A7.008 7.008 0 0019 8.611a.245.245 0 01.1-.192.249.249 0 01.213-.041c.056.015.111.03.167.048A.75.75 0 0019.926 7c-.448-.141-.9-.258-1.347-.372a.251.251 0 01-.179-.198l-.91-4.53a.5.5 0 00-.346-.381l-5-1.5a.5.5 0 00-.288 0l-5 1.5a.5.5 0 00-.347.381l-.9 4.521a.25.25 0 01-.183.194c-.5.128-1 .262-1.493.421A.751.751 0 004.167 8.5a.765.765 0 00.233-.033c.136-.044.273-.079.411-.116a.155.155 0 01.133.027A.153.153 0 015 8.5a7.007 7.007 0 006.981 7h.037zm.087-10.542a.256.256 0 01-.21 0 2.489 2.489 0 01-1.386-2.189.252.252 0 01.067-.188.248.248 0 01.183-.08h2.482a.252.252 0 01.25.268 2.489 2.489 0 01-1.386 2.188zM7 8.5v-.7c0-.71 10-.712 10 0v.7a5 5 0 01-10 0z\"/><path d=\"M12.87 12.111a2.369 2.369 0 00.435-.181.639.639 0 00-.3-1.2H11a.639.639 0 00-.3 1.2 2.336 2.336 0 00.435.179 4.011 4.011 0 00.863.126h.021a2.989 2.989 0 00.851-.124z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});