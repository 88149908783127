define("ember-svg-jar/inlined/sea-transport-submarine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sea-transport-submarine</title><path d=\"M23 13.749a1 1 0 00-1 1v1.5a.25.25 0 01-.25.25h-.3a.25.25 0 01-.248-.281 5.919 5.919 0 00.048-.719 6.758 6.758 0 00-6.75-6.75H14a.25.25 0 01-.25-.25v-1.75A1.252 1.252 0 0012.5 5.5h-1.25a.25.25 0 01-.25-.25v-.5a3 3 0 00-3-3H6.5a1 1 0 000 2H8a1 1 0 011 1v.5a.25.25 0 01-.25.25H8.5a1.252 1.252 0 00-1.25 1.25V8.5a.25.25 0 01-.25.25h-.25a6.75 6.75 0 000 13.5h7.75a6.724 6.724 0 005.959-3.616.251.251 0 01.222-.134h1.07a.25.25 0 01.25.25V20a1 1 0 002 0v-5.25A1 1 0 0023 13.749zM5.5 17A1.5 1.5 0 117 15.5 1.5 1.5 0 015.5 17zm5.25 0a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.501 1.5zM16 17a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0116 17z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});