define("ember-svg-jar/inlined/music-clef", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-clef</title><path d=\"M12.84 11.514a.5.5 0 01-.554-.407l-.033-.184a.5.5 0 01.234-.515C18.939 6.52 16.977 0 13.747 0A3.5 3.5 0 0010.9 1.318c-1.3 1.643-1.4 4.506-.932 7.963a.5.5 0 01-.2.469 5.874 5.874 0 00-2.523 5.12 4.43 4.43 0 004.376 4.347.5.5 0 01.478.4 6.194 6.194 0 01.146.991A1.392 1.392 0 0110.747 22a1.45 1.45 0 01-1.5-1.391 1 1 0 00-2 0 3.451 3.451 0 003.5 3.391 3.371 3.371 0 003.5-3.391 8.546 8.546 0 00-.228-1.6.5.5 0 01.218-.523 4.31 4.31 0 002.01-3.619 3.461 3.461 0 00-.915-2.513 2.969 2.969 0 00-2.492-.84zM9.247 14.87a4.1 4.1 0 01.791-2.647.251.251 0 01.443.106l.093.469a.492.492 0 01-.041.318 2.879 2.879 0 00-.286 1.275 1 1 0 00.645.935.5.5 0 01.309.355c.087.376.2.853.273 1.174a.251.251 0 01-.3.3 2.389 2.389 0 01-1.927-2.285zm2.978-6.613a.25.25 0 01-.382-.186c-.249-2.392-.175-4.5.626-5.511A1.5 1.5 0 0113.747 2c.778 0 2.338 3.823-1.522 6.257zm1.575 7.954a.249.249 0 01-.251.1.253.253 0 01-.193-.187c-.143-.613-.3-1.288-.458-2a.5.5 0 01.6-.6c.374.086.75.408.75 1.346a2.239 2.239 0 01-.448 1.341z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});