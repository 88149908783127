define("ember-svg-jar/inlined/radiology-scanner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>radiology-scanner</title><path d=\"M23 8.5a1 1 0 00-1 1v8a1 1 0 102 0v-8a1 1 0 00-1-1zM17.446 6.583l1.5 1a1 1 0 101.109-1.664l-1.5-1a1 1 0 00-1.109 1.664zM14.481 3.447A1 1 0 0013.7 2.27l-2.5-.5a1 1 0 00-.4 1.961l2.5.5a.964.964 0 00.2.02 1 1 0 00.981-.804zM16.511 8.859a1 1 0 00.848 1.132l3 .429a1 1 0 00.283-1.981l-3-.428a1 1 0 00-1.131.848zM20.359 16.582l-3 .429a1 1 0 00.283 1.98l3-.429a1 1 0 00-.283-1.98zM20.5 12.5h-3a1 1 0 000 2h3a1 1 0 000-2zM15 8a1 1 0 00-1 1v9.25a1 1 0 002 0V9a1 1 0 00-1-1z\"/><circle cx=\"16.001\" cy=\"4.001\" r=\"1.25\"/><path d=\"M6.607 8.251h.065a1.244 1.244 0 10-.065 0zM4.851 11.491A1.25 1.25 0 105.51 9.85a1.247 1.247 0 00-.659 1.641zM.769 6.724l2.878.8a.968.968 0 00.232.028 1 1 0 00.231-1.97l-2.878-.8a1 1 0 10-.463 1.942z\"/><path d=\"M12.514 7.547L9.161 6.5a1 1 0 10-.6 1.908l3.353 1.048a1 1 0 10.6-1.908zM1 12h2.5a1 1 0 100-2H1a1 1 0 000 2zM11.815 10.506L8.6 10.184a1 1 0 00-.2 1.991l3.215.321h.1a1 1 0 00.1-1.995zM6.672 18.753a1.25 1.25 0 10-.129 2.5h.066a1.25 1.25 0 00.063-2.5zM7.151 16.491a1.25 1.25 0 10-1.64.66 1.25 1.25 0 001.64-.66zM3.647 19.475l-2.878.8A1 1 0 001 22.251a1.036 1.036 0 00.233-.027l2.878-.8a1 1 0 10-.463-1.945z\"/><path d=\"M11.917 17.547L8.564 18.6a1 1 0 10.6 1.908l3.353-1.048a1 1 0 10-.6-1.908zM1 17h2.5a1 1 0 000-2H1a1 1 0 000 2zM11.616 14.506l-3.216.321a1 1 0 00.1 1.995h.1l3.215-.321a1 1 0 10-.2-1.99z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});