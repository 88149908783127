define("ember-svg-jar/inlined/dressing-hanger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dressing-hanger</title><path d=\"M19.39 16.008l-3.5-.889a.183.183 0 01-.137-.177.251.251 0 01.143-.226 2.083 2.083 0 10-2.976-1.883.75.75 0 001.5 0 .583.583 0 11.833.528 1.759 1.759 0 00-1 1.581.183.183 0 01-.137.177l-3.555.9a1.913 1.913 0 00.6 3.727h7.676a1.912 1.912 0 00.552-3.742zm-.552 2.242h-7.676a.413.413 0 01-.412-.412.408.408 0 01.229-.376l3.959-1.006a.26.26 0 01.124 0l3.906.99a.413.413 0 01-.13.8z\"/><rect y=\"17.5\" width=\"7\" height=\"6.5\" rx=\".5\" ry=\".5\"/><path d=\"M11.5 12.75a.75.75 0 00-.75-.75H7.5a.5.5 0 01-.5-.5v-1a.5.5 0 00-.5-.5h-6a.5.5 0 00-.5.5v5a.5.5 0 00.5.5h6a.5.5 0 00.5-.5V14a.5.5 0 01.5-.5h3.25a.75.75 0 00.75-.75z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5V8a.5.5 0 00.5.5h21a.5.5 0 01.5.5v2.5a.5.5 0 01-.5.5h-2.25a.75.75 0 000 1.5h2.25a.5.5 0 01.5.5v9a1 1 0 002 0V1.5A1.5 1.5 0 0022.5 0zM8 7H3.25a.75.75 0 010-1.5H8A.75.75 0 018 7zm0-3H3.25a.75.75 0 010-1.5H8A.75.75 0 018 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});