define("ember-svg-jar/inlined/wild-bird-fly", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wild-bird-fly</title><path d=\"M20.8 7.784A3.585 3.585 0 0013.81 8.9v2.615a6.135 6.135 0 01-2.01 4.555 3.925 3.925 0 01-1.217-2.176c-.277-1.5.306-3.151 1.732-4.915a.5.5 0 000-.63L6.125.729a.506.506 0 00-.49-.174.5.5 0 00-.38.356 9.445 9.445 0 00.237 5.114L2.739 3.531a.5.5 0 00-.835.4 17.12 17.12 0 001.669 6.187L.777 8.248A.5.5 0 000 8.725c.028.236.646 5.088 3.148 7.061l-2.28.48a.5.5 0 00-.352.3.513.513 0 00.038.466c1.763 2.862 3.958 6.423 9.941 6.423A10.513 10.513 0 0021 12.953V12.5h2a1 1 0 001-1c0-2.094-1.687-3.214-3.2-3.716zM17.5 8a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});