define("ember-svg-jar/inlined/monitor.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v13.75a.25.25 0 00.25.25h23.5a.25.25 0 00.25-.25V1.5A1.5 1.5 0 0022.5 0zM23.75 17H.25a.25.25 0 00-.25.25v1.25A1.5 1.5 0 001.5 20h7.82a.25.25 0 01.205.393l-1.974 2.82A.5.5 0 007.96 24h8.08a.5.5 0 00.409-.787l-1.974-2.82A.25.25 0 0114.68 20h7.82a1.5 1.5 0 001.5-1.5v-1.25a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});