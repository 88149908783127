define("ember-svg-jar/inlined/heavy-equipment-lift-hook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>heavy-equipment-lift-hook</title><path d=\"M13.75 9.389a2.5 2.5 0 012.5 2.5v.75a.25.25 0 00.25.25h3.623a.25.25 0 00.158-.444L8.268 2.577a1.5 1.5 0 00-1.238-.314C6.985 2.207 4.765.42 4.765.42A1.246 1.246 0 003.432.225a1.245 1.245 0 00-.682 1.164V7.1a2.5 2.5 0 103.5 2.291 1 1 0 00-2 0 .5.5 0 11-.7-.459A2 2 0 004.75 7.1V2.97s.927.739.954.757c0 .053-.006.108 0 .163a1.489 1.489 0 00.49 1.074l4.8 4.36a.254.254 0 00.168.065zM7.75 21.889a2 2 0 002 2h10.5a2 2 0 000-4H9.75a2 2 0 00-2 2zm12.5-.75a.75.75 0 11-.75.75.75.75 0 01.75-.75zm-9.75.75a.75.75 0 11-.75-.75.75.75 0 01.75.75z\"/><path d=\"M15 14.389a.25.25 0 01-.25-.25v-2.25a1 1 0 00-1-1h-3.382a1 1 0 00-.894.552L8.25 14.389v3a1 1 0 001 1h12.5a1 1 0 001-1v-2.75a.249.249 0 00-.25-.25zm-2.25-2a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-2a.5.5 0 01-.447-.724l.309-1a.5.5 0 01.447-.276z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});