define("ember-inputmask/components/one-way-number-mask", ["exports", "ember-inputmask/components/one-way-input-mask", "@ember/object", "@ember/utils", "@ember/polyfills"], function (_exports, _oneWayInputMask, _object, _utils, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_OPTIONS = {
    groupSeparator: ',',
    radixPoint: '.',
    groupSize: '3',
    autoGroup: true
  };

  /**
   * @class OneWayNumberMask
   */
  var _default = _exports.default = _oneWayInputMask.default.extend({
    NON_ATTRIBUTE_BOUND_PROPS: _oneWayInputMask.DEFAULT_NON_BOUND_PROPS.concat('decimal'),
    /**
     * @field mask
     * @override
     */
    mask: 'integer',
    /**
     * Set this to true to include decimals
     *
     * @argument decimal
     * @type Boolean
     */
    decimal: false,
    init() {
      this._super(...arguments);
      (0, _object.set)(this, '_options', (0, _polyfills.assign)({}, this._options, DEFAULT_OPTIONS));
      if (this.decimal) {
        (0, _object.set)(this, 'mask', 'decimal');

        // Give default digits if we don't have them already
        if (!this.options || (0, _utils.isBlank)(this.options.digits)) {
          (0, _object.set)(this, '_options.digits', 2);
        }
      }
    },
    /**
     * sendUpdate - Clean Integer Values
     *
     * @method sendUpdate
     * @override
     * @param  {string} unmaskedValue
     * @param  {string} value
     */
    sendUpdate(unmaskedValue, value) {
      if (!this.decimal) {
        unmaskedValue = this._cleanInteger(unmaskedValue);
        value = this._cleanInteger(value);
      }
      this.update(unmaskedValue, value);
    },
    _cleanInteger(number) {
      if (number === '0.') return '';
      return parseInt(number);
    }
  });
});