define("ember-svg-jar/inlined/meeting-team-monitor-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-team-monitor-1</title><path d=\"M24 2a2 2 0 00-2-2H6a2 2 0 00-2 2v4.5a1 1 0 002 0v-4a.5.5 0 01.5-.5h15a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-9a1 1 0 000 2h.25a.25.25 0 01.25.25v.5a.249.249 0 01-.25.25h-.25a1 1 0 000 2h5a1 1 0 000-2h-2.25a.249.249 0 01-.25-.25v-.5a.25.25 0 01.25-.25H22a2 2 0 002-2zM5.99 17.5a6 6 0 00-5.982 5.33A1.049 1.049 0 001.051 24h10.1a.845.845 0 00.849-.9 6.024 6.024 0 00-6.01-5.6z\"/><circle cx=\"5.991\" cy=\"12.999\" r=\"3.5\"/><circle cx=\"14.33\" cy=\"4.999\" r=\"2\"/><path d=\"M11.036 10.316a.5.5 0 00.47.683h5.648a.5.5 0 00.47-.683 3.5 3.5 0 00-6.588 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});