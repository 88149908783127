define("ember-svg-jar/inlined/meeting-team-laptop-woman-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-team-laptop-woman-1</title><path d=\"M23.5 11.5h-.75a.249.249 0 01-.25-.25V2a2 2 0 00-2-2h-13a2 2 0 00-2 2v5.18a.251.251 0 00.082.185.254.254 0 00.193.064 5.527 5.527 0 011.436.04.251.251 0 00.289-.248V2.5A.5.5 0 018 2h12a.5.5 0 01.5.5V11a.5.5 0 01-.5.5h-7.964a.252.252 0 00-.194.093.249.249 0 00-.05.21 5.6 5.6 0 01.124 1.173 6.631 6.631 0 00.115 1.327.249.249 0 00.244.2H21.5A2.5 2.5 0 0024 12a.5.5 0 00-.5-.5z\"/><circle cx=\"14.297\" cy=\"4.624\" r=\"1.625\"/><path d=\"M11.621 8.944A.408.408 0 0012 9.5h4.588a.409.409 0 00.383-.555 2.844 2.844 0 00-5.353 0zM6.463 21a.247.247 0 01-.216.125A.25.25 0 016.03 21l-1.836-3.18a.251.251 0 00-.312-.107 9.274 9.274 0 00-.271.119A6.261 6.261 0 000 23.5a.5.5 0 00.5.5H12a.5.5 0 00.5-.5 6.257 6.257 0 00-3.513-5.617q-.183-.09-.372-.168a.252.252 0 00-.312.106z\"/><path d=\"M3.491 16.549a4.564 4.564 0 005.425.205 4.859 4.859 0 00.4-.31.249.249 0 01.377.06 3.9 3.9 0 00.6.757 1 1 0 101.4-1.424c-.336-.331-.783-.989-.783-2.862a4.575 4.575 0 10-9.149 0 4.951 4.951 0 01-.448 2.418 1.9 1.9 0 01-.336.444 1 1 0 001.4 1.424c.061-.061.121-.124.178-.188a3.9 3.9 0 00.42-.57.251.251 0 01.377-.06c.053.036.095.072.139.106zm2.851-1A2.577 2.577 0 013.8 13.4a.25.25 0 01.2-.287 3.929 3.929 0 001.067-.324 2.747 2.747 0 001.09-.912.251.251 0 01.4-.017 4.5 4.5 0 001.045.929 3.14 3.14 0 001.05.472.251.251 0 01.188.3 2.578 2.578 0 01-2.498 1.988z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});