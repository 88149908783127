define("ember-svg-jar/inlined/phone-actions-receive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-receive</title><path d=\"M17.168 16.161a2.56 2.56 0 00-3.536 0l-.384.385a46.829 46.829 0 01-5.789-5.789l.384-.386a2.5 2.5 0 000-3.535L5.721 4.715a2.56 2.56 0 00-3.536 0L1.022 5.878a3.51 3.51 0 00-.441 4.4 46.919 46.919 0 0013.141 13.146 3.537 3.537 0 004.4-.443l1.164-1.164a2.5 2.5 0 000-3.535zM23.558.445a1.5 1.5 0 00-2.121 0l-5.9 5.9-1.332-1.339a1 1 0 00-1.707.707v4.793a1 1 0 001 1h4.793A1 1 0 0019 9.8l-1.339-1.337 5.9-5.9a1.5 1.5 0 00-.003-2.118z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});