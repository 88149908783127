define("ember-svg-jar/inlined/armchair-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>armchair-3</title><path d=\"M17.691 11.35a5.91 5.91 0 00-4.41-1.26l-6 .7a.5.5 0 01-.554-.436 2.224 2.224 0 00-.082-.38L3.912 1.391a2.078 2.078 0 00-.99-1.17A1.987 1.987 0 001.392.1a2 2 0 00-1.3 2.51l2.74 8.57a1.949 1.949 0 00.99 1.17h.008a.5.5 0 01.249.276l1.291 3.486a.5.5 0 01-.06.463l-4.128 5.854a.994.994 0 00.24 1.389 1.014 1.014 0 001.4-.239l3.381-4.8a.5.5 0 01.69-.125A1.964 1.964 0 008 19h8.419a.25.25 0 00.25-.269l-.23-3.072a2.251 2.251 0 00-.133-.5.25.25 0 00-.233-.16H8a1.9 1.9 0 00-.43.049.5.5 0 01-.58-.313l-.561-1.529a.25.25 0 01.206-.334l6.874-.8a4.011 4.011 0 014.43 3.47l.56 7.539a1 1 0 001 .92h.081a1 1 0 00.919-1.07l-.57-7.579a6 6 0 00-2.238-4.002zM22 15h-.33a.25.25 0 00-.249.273c.079.853.264 3.4.272 3.5a.25.25 0 00.249.231H22a2 2 0 000-4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});