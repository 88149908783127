define("ember-svg-jar/inlined/add-circle-bold-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>add-circle-bold-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 21a9 9 0 119-9 9.01 9.01 0 01-9 9z\"/><path d=\"M17.25 10.568h-3.568a.25.25 0 01-.25-.25V6.75a.958.958 0 00-.955-.955h-.954a.958.958 0 00-.955.955v3.568a.25.25 0 01-.25.25H6.75a.957.957 0 00-.955.954v.955a.958.958 0 00.955.955h3.568a.25.25 0 01.25.25v3.568a.957.957 0 00.955.954h.954a.957.957 0 00.955-.954v-3.568a.25.25 0 01.25-.25h3.568a.958.958 0 00.955-.955v-.955a.957.957 0 00-.955-.954z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});