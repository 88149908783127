define("ember-svg-jar/inlined/knife", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>knife</title><path d=\"M22.456.208A.494.494 0 0022.089 0a.512.512 0 00-.4.15L9.1 12.736a.5.5 0 000 .707l5.229 5.228a.5.5 0 00.357.147.5.5 0 00.355-.152c6.117-6.301 9.916-14.945 7.415-18.458zM8.028 14.479a.5.5 0 00-.707 0l-5.942 5.943a2.1 2.1 0 102.964 2.964l5.942-5.943a.5.5 0 000-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});