define("ember-svg-jar/inlined/single-neutral-actions-setting.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-setting</title><path d=\"M10.881 16.673a3.279 3.279 0 011.72-5.441.25.25 0 00.038-.479c-.36-.137-.741-.277-1.142-.425l-.628-.23a.593.593 0 01-.137-.311 2.571 2.571 0 01.057-1.3 5.622 5.622 0 001.466-4.315C12.255 1.757 10.676 0 8.5 0S4.744 1.757 4.744 4.174A5.605 5.605 0 006.2 8.471a2.456 2.456 0 01.072 1.316.605.605 0 01-.135.309l-.631.232c-2.447.9-4.214 1.55-4.753 2.626A9.17 9.17 0 000 16.5a.5.5 0 00.5.5h10.239a.2.2 0 00.178-.117.2.2 0 00-.036-.21z\"/><circle cx=\"17.749\" cy=\"17.031\" r=\"1.5\"/><path d=\"M23.516 15.654a1.76 1.76 0 00.236-2.113 1.74 1.74 0 00-1.936-.84l-1.411.327a.456.456 0 01-.534-.311l-.423-1.392a1.776 1.776 0 00-3.4 0l-.423 1.393a.458.458 0 01-.534.311l-1.41-.329a1.74 1.74 0 00-1.936.84 1.76 1.76 0 00.236 2.113l.988 1.064a.463.463 0 010 .625l-.988 1.065a1.782 1.782 0 001.7 2.953l1.411-.328a.457.457 0 01.534.312l.423 1.391a1.775 1.775 0 003.4 0l.423-1.392a.455.455 0 01.534-.312l1.411.328a1.742 1.742 0 001.936-.84 1.76 1.76 0 00-.236-2.113l-.988-1.065a.463.463 0 010-.625zm-5.767 4.377a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});