define("ember-svg-jar/inlined/single-neutral-actions-credit-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-credit-card</title><path d=\"M.625 17.75h1.81a.25.25 0 01.249.229l.443 5.313a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.06a.25.25 0 00.25-.25v-2.75a2.99 2.99 0 011.541-2.61.249.249 0 00.112-.3A5.5 5.5 0 00.125 13.75v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.625\" cy=\"3.75\" r=\"3.5\"/><path d=\"M10.375 14.75v7a1.752 1.752 0 001.75 1.75h10a1.752 1.752 0 001.75-1.75v-7a1.752 1.752 0 00-1.75-1.75h-10a1.752 1.752 0 00-1.75 1.75zm1.75-.25h10a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-10a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25zm10 7.5h-10a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25h10a.25.25 0 01.25.25v3a.25.25 0 01-.25.25z\"/><path d=\"M20.875 20.25a.75.75 0 00-.75-.75h-1.25a.75.75 0 000 1.5h1.25a.75.75 0 00.75-.75zM.625 17.75h1.81a.25.25 0 01.249.229l.443 5.313a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.06a.25.25 0 00.25-.25v-2.75a2.99 2.99 0 011.541-2.61.249.249 0 00.112-.3A5.5 5.5 0 00.125 13.75v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.625\" cy=\"3.75\" r=\"3.5\"/><path d=\"M10.375 14.75v7a1.752 1.752 0 001.75 1.75h10a1.752 1.752 0 001.75-1.75v-7a1.752 1.752 0 00-1.75-1.75h-10a1.752 1.752 0 00-1.75 1.75zm1.75-.25h10a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-10a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25zm10 7.5h-10a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25h10a.25.25 0 01.25.25v3a.25.25 0 01-.25.25z\"/><path d=\"M20.875 20.25a.75.75 0 00-.75-.75h-1.25a.75.75 0 000 1.5h1.25a.75.75 0 00.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});