define("ember-svg-jar/inlined/single-neutral-actions-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-location</title><path d=\"M.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M19 10.75a5.006 5.006 0 00-5 5c0 3.17 4.457 7.664 4.646 7.854a.5.5 0 00.708 0C19.543 23.414 24 18.92 24 15.75a5.006 5.006 0 00-5-5zM19 14a1.75 1.75 0 11-1.75 1.75A1.752 1.752 0 0119 14zM.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M19 10.75a5.006 5.006 0 00-5 5c0 3.17 4.457 7.664 4.646 7.854a.5.5 0 00.708 0C19.543 23.414 24 18.92 24 15.75a5.006 5.006 0 00-5-5zM19 14a1.75 1.75 0 11-1.75 1.75A1.752 1.752 0 0119 14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});