define("ember-svg-jar/inlined/sign-badge-badge-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sign-badge-badge-1-alternate</title><path d=\"M19.788 7.716c0-.619.763-1.611 1.942-2.526a1.653 1.653 0 00.156-2.474L19.7.528a1.654 1.654 0 00-1.99-.266l-2.158 1.232a.5.5 0 01-.5 0L12.82.216a1.664 1.664 0 00-1.64 0L8.944 1.494a.5.5 0 01-.5 0L6.29.262A1.656 1.656 0 004.3.528L2.114 2.716a1.652 1.652 0 00.156 2.473c1.179.916 1.942 1.911 1.942 2.527 0 .652-.361 1.16-1 2a7.149 7.149 0 00-1.832 4.608c0 6.977 9.912 9.535 10.334 9.641a1.186 1.186 0 00.572 0c.422-.106 10.334-2.664 10.334-9.641a7.149 7.149 0 00-1.833-4.607c-.638-.841-.999-1.349-.999-2.001zm-7.643 13.741a.511.511 0 01-.29 0c-1.722-.523-8.033-2.741-8.033-7.156a4.85 4.85 0 011.341-3.149 5.5 5.5 0 001.463-3.393 5.177 5.177 0 00-1.843-3.5.5.5 0 01-.005-.713l.605-.6a.5.5 0 01.6-.08L8.149 4.1a1.169 1.169 0 001.159 0l2.444-1.4a.5.5 0 01.5 0l2.444 1.4a1.169 1.169 0 001.159 0l2.165-1.237a.5.5 0 01.6.08l.605.605a.5.5 0 01-.005.713 5.177 5.177 0 00-1.843 3.5 5.5 5.5 0 001.463 3.393 4.85 4.85 0 011.338 3.146c0 4.415-6.309 6.633-8.033 7.157z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});