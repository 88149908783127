define("ember-svg-jar/inlined/video-game-mario-mushroom-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-mario-mushroom-1</title><path d=\"M12 0C3.723 0 0 7.522 0 12.864a6.871 6.871 0 004.01 6.654 1 1 0 01.593.716A4.758 4.758 0 009.252 24h5.5a4.758 4.758 0 004.648-3.766 1 1 0 01.593-.716A6.871 6.871 0 0024 12.864C24 7.522 20.277 0 12 0zm2.75 2.25A2.25 2.25 0 1112.5 4.5a2.25 2.25 0 012.25-2.25zM4 10.236a3.5 3.5 0 113.5 3.5 3.5 3.5 0 01-3.5-3.5zm13.5 9.014A2.754 2.754 0 0114.748 22h-5.5A2.754 2.754 0 016.5 19.25v-2.271a.5.5 0 01.275-.447A13.22 13.22 0 0112 15.75a13.22 13.22 0 015.225.782.5.5 0 01.275.447zm.5-6.014a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/><path d=\"M9.5 16.986a.75.75 0 00-.75.75v2a.75.75 0 001.5 0v-2a.75.75 0 00-.75-.75zM14.5 16.986a.75.75 0 00-.75.75v2a.75.75 0 101.5 0v-2a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});