define("ember-svg-jar/inlined/navigation-right-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-right-3</title><path d=\"M1.237 18.183a1.237 1.237 0 001.237-1.237v-9.9A1.237 1.237 0 000 7.049v9.9a1.237 1.237 0 001.237 1.234zM5.691 18.183a1.237 1.237 0 001.237-1.237v-3.461a.25.25 0 01.25-.25h10.139a.25.25 0 01.25.25v3.956a.99.99 0 001.756.627l4.453-5.444a.987.987 0 000-1.253l-4.453-5.444a.99.99 0 00-1.756.627v4.206H7.178a.249.249 0 01-.25-.25V7.049a1.237 1.237 0 00-2.474 0v9.9a1.237 1.237 0 001.237 1.234z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});