define("ember-svg-jar/inlined/layers-front", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layers-front</title><path d=\"M15 17a2 2 0 002-2V2a2 2 0 00-2-2H2a2 2 0 00-2 2v13a2 2 0 002 2zM2 2.5a.5.5 0 01.5-.5h12a.5.5 0 01.5.5v12a.5.5 0 01-.5.5h-12a.5.5 0 01-.5-.5z\"/><path d=\"M24 9.5A2.5 2.5 0 0021.5 7h-1.825a1.023 1.023 0 00-1.175.985V16.5a2 2 0 01-2 2H8a1 1 0 00-1 1v2A2.5 2.5 0 009.5 24h12a2.5 2.5 0 002.5-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});