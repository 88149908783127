define("ember-svg-jar/inlined/discount", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>discount</title><circle cx=\"4.5\" cy=\"4.5\" r=\"4\"/><circle cx=\"19.5\" cy=\"19.5\" r=\"4\"/><path d=\"M22.414 1.586a2 2 0 00-2.828 0l-18 18a2 2 0 102.828 2.828l18-18a2 2 0 000-2.828z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});