define("ember-svg-jar/inlined/spa-hand-flower-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>spa-hand-flower-1</title><path d=\"M13.607 18.75h-7.5a.5.5 0 110-1h3a.5.5 0 00.5-.5 2 2 0 00-2-2h-5.25a.25.25 0 01-.25-.25.75.75 0 00-1.5 0v7.5a.75.75 0 001.5 0 .25.25 0 01.25-.25h13.75a.5.5 0 00.5-.5 3 3 0 00-3-3zM23.288 5.241a2.3 2.3 0 00-1.432-1.432 3.143 3.143 0 00-1.7-.138.5.5 0 01-.586-.425A2.676 2.676 0 0017.107.75a2.679 2.679 0 00-2.462 2.5.5.5 0 01-.586.425 3.155 3.155 0 00-1.7.138 2.3 2.3 0 00-1.433 1.432 2.3 2.3 0 00.317 2 3.2 3.2 0 001.294 1.113.5.5 0 01.223.687 3.21 3.21 0 00-.393 1.661 2.3 2.3 0 00.92 1.8 2.184 2.184 0 001.3.414 2.641 2.641 0 00.7-.1 3.2 3.2 0 001.459-.887.5.5 0 01.723 0 3.192 3.192 0 001.459.888 2.647 2.647 0 00.7.1 2.186 2.186 0 001.3-.414 2.3 2.3 0 00.92-1.8 3.21 3.21 0 00-.393-1.661.5.5 0 01.223-.687 3.2 3.2 0 001.294-1.113 2.3 2.3 0 00.316-2.005zm-3.141 3a.5.5 0 01-.475.345.469.469 0 01-.152-.024h-.006l-.384-.124a.5.5 0 00-.528.144 2.005 2.005 0 01-.7.506.5.5 0 00-.3.458v.406a.5.5 0 01-1 0v-.411a.5.5 0 00-.3-.458 2.005 2.005 0 01-.7-.506.5.5 0 00-.528-.144l-.384.124a.477.477 0 01-.152.024.5.5 0 01-.155-.976l.386-.125a.5.5 0 00.343-.428 1.993 1.993 0 01.265-.817.5.5 0 00-.026-.548l-.238-.327a.5.5 0 11.808-.588l.239.328a.5.5 0 00.512.194 2.016 2.016 0 01.861 0 .5.5 0 00.512-.194l.239-.328a.5.5 0 11.808.588l-.238.327a.5.5 0 00-.026.548 1.993 1.993 0 01.265.817.5.5 0 00.344.428l.385.125a.5.5 0 01.325.633z\"/><circle cx=\"17.107\" cy=\"7.25\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});