define("ember-svg-jar/inlined/usb-cable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>usb-cable</title><path d=\"M17.227 7.511A.25.25 0 0117 7.262V2a2 2 0 00-2-2H9a2 2 0 00-2 2v5.262a.25.25 0 01-.227.249A2.5 2.5 0 004.5 10v3.586a1.511 1.511 0 00.439 1.06l2.488 2.487a.251.251 0 01.073.177V19A1.5 1.5 0 009 20.5h1.25a.25.25 0 01.25.25v1.75a1.5 1.5 0 103 0v-1.75a.25.25 0 01.25-.25H15a1.5 1.5 0 001.5-1.5v-1.69a.251.251 0 01.073-.177l2.488-2.487a1.511 1.511 0 00.439-1.06V10a2.5 2.5 0 00-2.273-2.489zM9 2.251A.25.25 0 019.25 2h5.5a.249.249 0 01.25.25v4.5a.25.25 0 01-.25.25h-5.5A.25.25 0 019 6.751zM9.5 13.5a.5.5 0 01-.384-.821l2.5-3a.517.517 0 01.768 0l2.5 3a.5.5 0 01-.384.821z\"/><path d=\"M12.75 3.5h-1.5a.712.712 0 00-.75.666v.667a.712.712 0 00.75.667h1.5a.712.712 0 00.75-.667v-.666a.712.712 0 00-.75-.667z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});