define("ember-svg-jar/inlined/single-neutral-actions-star.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-star</title><path d=\"M9.243 15a3.237 3.237 0 013.016-2h1.187a.248.248 0 00.229-.151l.514-1.19a.25.25 0 00-.127-.328c-.725-.323-1.586-.643-2.564-1l-.628-.231a.6.6 0 01-.138-.31 2.57 2.57 0 01.058-1.3 5.618 5.618 0 001.466-4.314C12.256 1.756 10.677 0 8.5 0S4.745 1.756 4.745 4.174A5.6 5.6 0 006.2 8.47a2.459 2.459 0 01.072 1.317.6.6 0 01-.135.308l-.631.233c-2.447.9-4.214 1.549-4.753 2.625A9.153 9.153 0 000 16.5a.5.5 0 00.5.5h8.3a.25.25 0 00.247-.291A3.131 3.131 0 019.243 15zM20.736 23.828a1.277 1.277 0 001.506-.166 1.254 1.254 0 00.315-1.45L21.333 19.4a.251.251 0 01.063-.286l2.213-1.971a1.264 1.264 0 00.3-1.378A1.234 1.234 0 0022.74 15h-2.5a.249.249 0 01-.229-.151l-1.344-3.116-.013-.028a1.293 1.293 0 00-2.317.028l-1.347 3.115a.25.25 0 01-.23.151h-2.5a1.229 1.229 0 00-1.166.76 1.271 1.271 0 00.319 1.4l2.194 1.955a.249.249 0 01.063.286l-1.223 2.815a1.255 1.255 0 00.315 1.451 1.278 1.278 0 001.506.166l3.111-1.752a.249.249 0 01.245 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});