define("ember-svg-jar/inlined/cat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cat</title><path d=\"M7.2 17.738a.248.248 0 00-.131.334l1.152 2.538a1.074 1.074 0 002.047-.543l-.241-2.567a.251.251 0 00-.262-.227 7.755 7.755 0 00-2.565.465zM18.516 20.348a.016.016 0 000 .015l.14.21a1.408 1.408 0 001.179.674 1.28 1.28 0 00.606-.151 1.05 1.05 0 00.463-1.384c-.1-.226-.716-1.766-1.09-2.7a.25.25 0 00-.474.033z\"/><path d=\"M20.7 7.407c-.271-.163-.577-.346-.922-.562l-.749-1.829a.706.706 0 00-.651-.5.706.706 0 00-.653.5l-1.289 3.071a3.492 3.492 0 01-3.231 2.154h-7.19A2.614 2.614 0 015 7.282c.253-.977.837-1.591 1.071-1.6.029 0 .112.088.184.3a1.122 1.122 0 001.389.724A1.383 1.383 0 008.7 5.142 2.523 2.523 0 005.953 2.76c-1.963.163-4.082 2.305-4.082 5.481a4.623 4.623 0 002.118 4.323c-.907 2.49-2.225 5.773-2.77 7.119a1.088 1.088 0 00.536 1.428 1.39 1.39 0 001.8-.6c.145-.242.281-.479.412-.707 1.278-2.221 2.049-3.562 6.9-3.562 1.971 0 2.967.193 3.435.664s.543 1.367.531 3.075a1.253 1.253 0 001.253 1.261 1.5 1.5 0 001.456-1.136l1.3-5.189 1.337-2.675h.879a1.814 1.814 0 001.812-1.812c.001-1.714-.636-2.097-2.17-3.023zm-1.363 2.334a.75.75 0 11.75-.75.749.749 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});