define("ember-svg-jar/inlined/multiple-actions-up-down.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-up-down</title><path d=\"M14.471 8.523l-.485-.172a1.8 1.8 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 0012 .125a2.987 2.987 0 00-1.342.314.25.25 0 00-.1.363A5 5 0 0111.4 3.61a6.259 6.259 0 01-.922 3.624.254.254 0 00-.029.216.249.249 0 00.151.156c1.917.716 3.3 1.328 3.9 2.537.069.139.13.281.186.424a.244.244 0 00.134.139.249.249 0 00.193 0 7.913 7.913 0 012.178-.539.25.25 0 00.1-.466 20.415 20.415 0 00-2.82-1.178z\"/><path d=\"M13.5 11.518a.251.251 0 00.1-.287 4.286 4.286 0 00-.217-.529c-.454-.907-1.9-1.44-3.911-2.179l-.485-.172a1.8 1.8 0 01-.057-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 007 .125 3.219 3.219 0 003.855 3.61a4.609 4.609 0 001.192 3.538 1.877 1.877 0 010 1.185l-.514.19C2.523 9.262 1.073 9.8.619 10.7A7.522 7.522 0 000 13.625a.5.5 0 00.5.5h10.44a.252.252 0 00.214-.125 8.039 8.039 0 012.346-2.482zM17.944 19.4a.5.5 0 00-.444-.271h-1a.249.249 0 01-.25-.25v-5.25a1.25 1.25 0 00-2.5 0v5.25a.25.25 0 01-.25.25h-1a.5.5 0 00-.408.791l2.5 3.5a.5.5 0 00.815 0c1.351-1.892.782-1.1 2.5-3.5a.5.5 0 00.037-.52zM22.25 17.375a.249.249 0 01.25-.25h1a.5.5 0 00.407-.791l-2.5-3.5a.52.52 0 00-.815 0c-1.351 1.892-.782 1.1-2.5 3.5a.5.5 0 00.408.791h1a.25.25 0 01.25.25v5.25a1.25 1.25 0 002.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});