define("ember-svg-jar/inlined/pets-paw-medal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pets-paw-medal</title><path d=\"M5.328 6.9a.248.248 0 00.057-.317L1.866.5a1 1 0 00-1.732 1l3.7 6.4a.25.25 0 00.4.043A10.741 10.741 0 015.328 6.9zM11.423 4.516a.25.25 0 00.2-.374L9.518.5a1 1 0 00-1.731 1l1.794 3.1a.251.251 0 00.268.119 10.585 10.585 0 011.574-.203zM8.11 5.247a.25.25 0 00.123-.357L5.692.5a1 1 0 00-1.732 1l2.486 4.3a.25.25 0 00.341.091 10.552 10.552 0 011.323-.644zM23.5.135A1 1 0 0022.134.5l-3.519 6.081a.251.251 0 00.057.318 10.623 10.623 0 011.092 1.04.25.25 0 00.4-.043l3.7-6.395A1 1 0 0023.5.135zM16.213 1.5a1 1 0 00-1.731-1l-2.108 3.642a.25.25 0 00.2.374 10.585 10.585 0 011.574.206.251.251 0 00.268-.119zM20.04 1.5a1 1 0 00-1.732-1l-2.541 4.39a.252.252 0 00.124.358 10.462 10.462 0 011.322.64.25.25 0 00.341-.091zM12 6a9 9 0 109 9 9.01 9.01 0 00-9-9zm0 3.5a1.5 1.5 0 11-1.5 1.5A1.5 1.5 0 0112 9.5zM9 14a1.5 1.5 0 11-1.5-1.5A1.5 1.5 0 019 14zm-.068 3.847l2.232-2.9a1 1 0 011.671 0l2.232 2.9a1 1 0 01-.836 1.549H9.768a1 1 0 01-.836-1.549zM15 14a1.5 1.5 0 111.5 1.5A1.5 1.5 0 0115 14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});