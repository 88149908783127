define("ember-svg-jar/inlined/gender-lesbian", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gender-lesbian</title><path d=\"M11.836 8.457a1.237 1.237 0 00-.6 1.645 4.157 4.157 0 01.392 1.774 4.207 4.207 0 11-6.688-3.4 1.237 1.237 0 10-1.462-2A6.674 6.674 0 005.512 18.27a.25.25 0 01.179.239v.791a.25.25 0 01-.25.25h-.493a1.238 1.238 0 000 2.475h.493a.25.25 0 01.25.25v.492a1.237 1.237 0 102.474 0v-.492a.25.25 0 01.25-.25h.492a1.238 1.238 0 000-2.475h-.492a.25.25 0 01-.25-.25v-.563a.249.249 0 01.214-.247 6.683 6.683 0 005.1-9.431 1.237 1.237 0 00-1.643-.602z\"/><path d=\"M22.3 3.061a.25.25 0 010-.354l.595-.6A1.237 1.237 0 0021.146.362l-.6.6a.251.251 0 01-.354 0l-.343-.353a1.237 1.237 0 10-1.749 1.75l.347.348a.249.249 0 010 .354L17.27 4.239a.251.251 0 01-.312.033 6.678 6.678 0 00-9.572 8.614A1.237 1.237 0 109.6 11.777 4.149 4.149 0 019.155 9.9a4.206 4.206 0 116.719 3.374 1.237 1.237 0 101.479 1.982A6.714 6.714 0 0020.041 9.9a6.636 6.636 0 00-1.055-3.6.248.248 0 01.033-.311L20.2 4.811a.25.25 0 01.354 0l.348.347a1.237 1.237 0 001.749-1.749z\"/><path d=\"M11.836 8.457a1.237 1.237 0 00-.6 1.645 4.157 4.157 0 01.392 1.774 4.207 4.207 0 11-6.688-3.4 1.237 1.237 0 10-1.462-2A6.674 6.674 0 005.512 18.27a.25.25 0 01.179.239v.791a.25.25 0 01-.25.25h-.493a1.238 1.238 0 000 2.475h.493a.25.25 0 01.25.25v.492a1.237 1.237 0 102.474 0v-.492a.25.25 0 01.25-.25h.492a1.238 1.238 0 000-2.475h-.492a.25.25 0 01-.25-.25v-.563a.249.249 0 01.214-.247 6.683 6.683 0 005.1-9.431 1.237 1.237 0 00-1.643-.602z\"/><path d=\"M22.3 3.061a.25.25 0 010-.354l.595-.6A1.237 1.237 0 0021.146.362l-.6.6a.251.251 0 01-.354 0l-.343-.353a1.237 1.237 0 10-1.749 1.75l.347.348a.249.249 0 010 .354L17.27 4.239a.251.251 0 01-.312.033 6.678 6.678 0 00-9.572 8.614A1.237 1.237 0 109.6 11.777 4.149 4.149 0 019.155 9.9a4.206 4.206 0 116.719 3.374 1.237 1.237 0 101.479 1.982A6.714 6.714 0 0020.041 9.9a6.636 6.636 0 00-1.055-3.6.248.248 0 01.033-.311L20.2 4.811a.25.25 0 01.354 0l.348.347a1.237 1.237 0 001.749-1.749z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});