define("ember-svg-jar/inlined/road-sign-2-way", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-2-way</title><path d=\"M15.75 10.5v1a.25.25 0 00.5 0v-1c0-.275-.5-.275-.5 0z\"/><path d=\"M23.25 5.75H.75A.75.75 0 000 6.5v11a.75.75 0 00.75.75h22.5a.75.75 0 00.75-.75v-11a.75.75 0 00-.75-.75zM5.183 13.6a.251.251 0 00.231.154H6a.75.75 0 010 1.5H3a.75.75 0 01-.53-1.28l2.613-2.614a.572.572 0 00.167-.4.7.7 0 00-1.37-.223.749.749 0 11-1.422-.474 2.2 2.2 0 014.292.7 2.084 2.084 0 01-.607 1.465l-.9.905a.251.251 0 00-.06.267zm8.567-2.751a6.727 6.727 0 01-.483 2.507l-.567 1.422a.749.749 0 01-.7.472h-.038a.75.75 0 01-.683-.543l-.039-.134a.249.249 0 00-.48 0l-.039.134a.75.75 0 01-.683.543.73.73 0 01-.734-.471l-.571-1.426a6.727 6.727 0 01-.483-2.507V9.5a.75.75 0 011.5 0v1.36a.25.25 0 00.49.068l.039-.134a.75.75 0 011.442 0l.039.134a.25.25 0 00.49-.068V9.5a.75.75 0 011.5 0zm4 3.655a.75.75 0 01-1.5 0v-1a.25.25 0 00-.5 0v1a.75.75 0 01-1.5 0v-4a1.75 1.75 0 013.5 0zm4-3.5a1.746 1.746 0 01-.875 1.509.25.25 0 00-.125.216V14.5a.75.75 0 01-1.5 0v-1.775a.25.25 0 00-.125-.216A1.746 1.746 0 0118.25 11V9.5a.75.75 0 011.5 0V11c0 .275.5.275.5 0V9.5a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});