define("ember-svg-jar/inlined/style-three-pin-key-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-key-lock</title><path d=\"M15.5 10.5a1 1 0 101 1 1 1 0 00-1-1zM15.5 7a1 1 0 00-1 1v.25a.25.25 0 00.25.25h1.5a.25.25 0 00.25-.25V8a1 1 0 00-1-1z\"/><circle cx=\"8\" cy=\"12.5\" r=\"1\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM8 15a2.5 2.5 0 01-.227-4.99A.249.249 0 008 9.761V9.25A.25.25 0 007.75 9H7.5a.75.75 0 010-1.5h.25A.25.25 0 008 7.25v-.5a.25.25 0 00-.25-.25h-1a.75.75 0 010-1.5h2a.75.75 0 01.75.75v4.642a.249.249 0 00.09.192A2.491 2.491 0 018 15zm11-1.5a1 1 0 01-1 1h-5a1 1 0 01-1-1v-4a1 1 0 01.8-.98.25.25 0 00.2-.245V8a2.5 2.5 0 015 0v.275a.25.25 0 00.2.245 1 1 0 01.8.98z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});