define("ember-svg-jar/inlined/chicken-mother", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chicken-mother</title><path d=\"M15.57 8.918a.5.5 0 00-.239-.349 9.123 9.123 0 00-1.1-.546.249.249 0 00-.343.192 5.642 5.642 0 01-2.25 4.117 4.551 4.551 0 01-3.821.37.75.75 0 01.52-1.402 3.116 3.116 0 002.5-.234c.854-.554 1.389-1.685 1.605-3.353a.251.251 0 00-.208-.279 6.331 6.331 0 00-4 .576.25.25 0 01-.356-.289A18.28 18.28 0 008.577 3 2.995 2.995 0 003.13 1.276c-.208.062-3.8 1.942-3.018 4.448.385 1.233 1.774 1.63 4.561.183a13.5 13.5 0 00-2.089 5.011 4.45 4.45 0 00.791 3.759 4.37 4.37 0 001.7 1.184v2.389a1 1 0 102 0v-1.875a13.305 13.305 0 002.25.114v1.761a1 1 0 002 0V16.2a5.791 5.791 0 002.863-1.815 6.79 6.79 0 001.382-5.467zM6.327 2.75a1 1 0 11-1-1 1 1 0 011 1zM1.543 5.276c-.2-.65.411-1.365 1.066-1.9a2.971 2.971 0 00.68 1.55c-.765.354-1.567.505-1.746.35zM21.5 17.317a2.99 2.99 0 10-5.6-2.084h-.69a1 1 0 000 2h.69a3 3 0 001.3 1.576 2.586 2.586 0 00.8 3.256V23a1 1 0 002 0v-.419c.1-.007.2-.014.3-.028l.254.763a1 1 0 101.9-.631l-.3-.9a3.736 3.736 0 001.346-2.792 1 1 0 00-.293-.707 4.141 4.141 0 00-1.707-.969zm-2.553 2.5c.471-1.4 2.156-.553 2.479-.378a1.551 1.551 0 01-1.168 1.088 1.218 1.218 0 01-1.182-.153.586.586 0 01-.129-.561zm-.237-4.58a1 1 0 11-1 1 1 1 0 011-1.004zM15.71 5.237h.69a2.994 2.994 0 001.3 1.576 2.588 2.588 0 00.8 3.257V11a1 1 0 002 0v-.42c.1-.007.2-.014.3-.028l.255.764a1 1 0 001.9-.632l-.3-.9A3.737 3.737 0 0024 7a1 1 0 00-.293-.707A4.152 4.152 0 0022 5.321a2.99 2.99 0 10-5.6-2.084h-.69a1 1 0 000 2zm5.048 3.29c-1.05.263-1.458-.259-1.311-.71.471-1.4 2.156-.552 2.479-.378a1.549 1.549 0 01-1.168 1.088zm-1.548-5.29a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});