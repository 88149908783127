define("ember-svg-jar/inlined/stove-induction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>stove-induction</title><path d=\"M20.714 4.457a1.49 1.49 0 00-1.4-.957H4.684a1.5 1.5 0 00-1.4.974L.549 12.336a.5.5 0 00.472.664h21.952a.5.5 0 00.472-.664zM12 11c-3.038 0-5.5-1.231-5.5-2.75S8.962 5.5 12 5.5s5.5 1.231 5.5 2.75S15.038 11 12 11zM.5 14.5a.5.5 0 00-.5.5v4a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 19v-4a.5.5 0 00-.5-.5zm2.75 4a.75.75 0 11.75-.75.75.75 0 01-.75.75zm3 0a.75.75 0 11.75-.75.75.75 0 01-.75.75zm8.25-.5a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h4a.5.5 0 01.5.5zm3.25.5a.75.75 0 11.75-.75.75.75 0 01-.75.75zm3 0a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});