define("ember-svg-jar/inlined/single-woman-home.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-home</title><path d=\"M23.5 8.943a1.456 1.456 0 00-.625-1.2L12.831.76a1.46 1.46 0 00-1.662 0L1.124 7.747A1.458 1.458 0 00.5 8.943v13.1A1.457 1.457 0 001.956 23.5h20.088a1.457 1.457 0 001.456-1.457zM8.335 14.225a.247.247 0 01-.051-.372c.3-.338.51-.745.6-2.086a3.12 3.12 0 116.234 0c.089 1.341.3 1.748.6 2.086a.247.247 0 01-.051.372 3.7 3.7 0 01-2.216.556.25.25 0 00-.25.25v.208a.489.489 0 00.313.458c1.773.669 3.184 1.147 3.522 1.836a5.047 5.047 0 01.464 1.717.249.249 0 01-.062.187.237.237 0 01-.177.08H6.74a.24.24 0 01-.177-.079.248.248 0 01-.063-.188 5.047 5.047 0 01.465-1.717c.337-.689 1.749-1.167 3.522-1.836a.489.489 0 00.313-.458v-.2a.249.249 0 00-.251-.25 3.729 3.729 0 01-2.214-.564z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});