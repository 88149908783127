define("ember-svg-jar/inlined/common-file-module-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-module-alternate</title><path d=\"M8.874.689A1.5 1.5 0 007.816.25H3a1.5 1.5 0 00-1.5 1.495v8.01A1.5 1.5 0 003 11.25h6.51A1.5 1.5 0 0011 9.755V3.533a1.468 1.468 0 00-.418-1.033zM9 9a.25.25 0 01-.25.25h-5A.25.25 0 013.5 9V2.5a.25.25 0 01.25-.25h3.74a.251.251 0 01.182.078l1.26 1.333A.251.251 0 019 3.833zM8.874 13.189a1.5 1.5 0 00-1.058-.439H3a1.5 1.5 0 00-1.5 1.495v8.01A1.5 1.5 0 003 23.75h6.51A1.5 1.5 0 0011 22.255v-6.222A1.468 1.468 0 0010.582 15zM9 21.5a.25.25 0 01-.25.25h-5a.25.25 0 01-.25-.25V15a.25.25 0 01.25-.25h3.74a.251.251 0 01.182.078l1.26 1.333a.251.251 0 01.068.172zM22.082 2.5L20.374.689A1.5 1.5 0 0019.316.25H14.5A1.5 1.5 0 0013 1.745v8.01a1.5 1.5 0 001.5 1.495h6.51a1.5 1.5 0 001.5-1.495V3.533a1.468 1.468 0 00-.428-1.033zM20.5 9a.25.25 0 01-.25.25h-5A.25.25 0 0115 9V2.5a.25.25 0 01.25-.25h3.74a.251.251 0 01.182.078l1.26 1.333a.251.251 0 01.068.172zM20.374 13.189a1.5 1.5 0 00-1.058-.439H14.5a1.5 1.5 0 00-1.5 1.495v8.01a1.5 1.5 0 001.495 1.5h6.51a1.5 1.5 0 001.5-1.5v-6.222A1.468 1.468 0 0022.082 15zM20.5 21.5a.25.25 0 01-.25.25h-5a.25.25 0 01-.25-.25V15a.25.25 0 01.25-.25h3.74a.251.251 0 01.182.078l1.26 1.333a.251.251 0 01.068.172z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});