define("ember-svg-jar/inlined/love-it-hand-give", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it-hand-give</title><path d=\"M23.127 2.45a3.524 3.524 0 00-5.645-.916l-.3.3a.251.251 0 01-.354 0l-.305-.305a3.525 3.525 0 00-4.993 4.978l4.768 4.974a.975.975 0 001.413 0l4.76-4.966a3.522 3.522 0 00.656-4.065zM1.5 14.25a1 1 0 00-1 1v7.25a1 1 0 002 0v-7.25a1 1 0 00-1-1zM16 19H6.875a.625.625 0 010-1.25H9.4a1 1 0 00.919-1.393A2.235 2.235 0 008.264 15H4a.5.5 0 00-.5.5V22a.5.5 0 00.5.5h10.764A2.239 2.239 0 0017 20.264V20a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});