define("ember-svg-jar/inlined/professions-man-magician.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-magician</title><path d=\"M.615 21.915v1.59a.494.494 0 00.5.5H22.89a.494.494 0 00.5-.5v-1.59a2.436 2.436 0 00-.957-1.957 15.367 15.367 0 00-7.806-2.75.248.248 0 01-.08-.47 5.938 5.938 0 003.4-5.361V9.488a.247.247 0 01.217-.242 49.055 49.055 0 003.507-.558.99.99 0 00-.389-1.942 50.071 50.071 0 01-9.246.871 50.522 50.522 0 01-9.3-.871.99.99 0 00-.389 1.942c1.166.233 2.336.411 3.507.558a.247.247 0 01.217.243v1.888a5.938 5.938 0 003.4 5.361.248.248 0 01-.08.47 15.367 15.367 0 00-7.806 2.75 2.436 2.436 0 00-.97 1.957zm15.07.511a.546.546 0 01-.748.276l-2.449-1.224a1.089 1.089 0 00-.976 0L9.063 22.7a.551.551 0 01-.434.024.543.543 0 01-.314-.3 3.685 3.685 0 010-2.795.546.546 0 01.748-.276l2.449 1.224a1.089 1.089 0 00.976 0l2.449-1.224a.551.551 0 01.434-.024.543.543 0 01.314.3 3.685 3.685 0 010 2.797zM8.04 11.378V9.744a.248.248 0 01.08-.182.25.25 0 01.185-.062c1.231.095 2.463.154 3.7.154s2.463-.059 3.693-.154a.25.25 0 01.187.065.248.248 0 01.08.182v1.634a3.96 3.96 0 01-7.92 0z\"/><path d=\"M17.951 6.242a.246.246 0 00.216-.228L18.433 2a.989.989 0 00-.62-.984 17.217 17.217 0 00-11.626 0 .989.989 0 00-.62.984l.267 4.012a.246.246 0 00.216.228 47.352 47.352 0 0011.901.002z\"/><circle cx=\"13.98\" cy=\"11.13\" r=\".99\"/><circle cx=\"10.02\" cy=\"11.13\" r=\".99\"/><path d=\"M12.081 14.485a2.961 2.961 0 00.855-.124 2.346 2.346 0 00.432-.179.634.634 0 00-.3-1.19h-1.98a.633.633 0 00-.3 1.19 2.363 2.363 0 00.431.178 2.967 2.967 0 00.858.125z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});