define("ember-svg-jar/inlined/shopping-cart-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-cart-disable</title><path d=\"M18 12a6 6 0 106 6 6.007 6.007 0 00-6-6zm0 2a3.973 3.973 0 011.725.392.25.25 0 01.068.4l-5 5a.254.254 0 01-.219.07.249.249 0 01-.183-.139A4 4 0 0118 14zm0 8a3.981 3.981 0 01-1.723-.391.249.249 0 01-.069-.4l5-5a.249.249 0 01.4.069A4 4 0 0118 22z\"/><path d=\"M12.657 14a1 1 0 00-1-1H8.239A.25.25 0 018 12.808l-1.15-5a.25.25 0 01.239-.308H20.28a.5.5 0 01.474.658L20 10.406a1 1 0 101.9.633l1-3A1.929 1.929 0 0021.072 5.5H6.514a.249.249 0 01-.244-.194l-.878-3.819A1.921 1.921 0 003.514 0H1a1 1 0 000 2l2.24-.055a.252.252 0 01.25.194l3.357 14.6a.25.25 0 01-.107.265 2.25 2.25 0 102.286-.1.253.253 0 01-.126-.165l-.33-1.437A.25.25 0 018.813 15h2.844a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});