define("ember-svg-jar/inlined/wench-double", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wench-double</title><path d=\"M17.512 13.985l-.568-.567a.5.5 0 00-.707 0l-2.823 2.824a.5.5 0 000 .707l.57.57a.5.5 0 01.128.457A4.994 4.994 0 0018.985 24a5.039 5.039 0 001.59-.258.5.5 0 00.2-.828l-2.449-2.449a.5.5 0 01-.147-.354V18.7a.5.5 0 01.5-.5h1.411a.5.5 0 01.353.147L22.9 20.8a.5.5 0 00.827-.193 4.992 4.992 0 00-5.759-6.5.494.494 0 01-.456-.122zM10.586 7.059l-.57-.569a.5.5 0 01-.128-.458 4.992 4.992 0 00-6.5-5.76.5.5 0 00-.188.828l2.456 2.458a.5.5 0 01.147.353v1.414a.5.5 0 01-.5.5H3.889a.5.5 0 01-.353-.147L1.087 3.23a.5.5 0 00-.828.2 4.993 4.993 0 005.767 6.46.5.5 0 01.458.129l.571.571a.5.5 0 00.707 0l2.824-2.824a.5.5 0 000-.707z\"/><path d=\"M17.516 10.019a.5.5 0 01.457-.129 4.993 4.993 0 005.768-6.464.5.5 0 00-.828-.2l-2.449 2.452a.5.5 0 01-.354.147H18.7a.5.5 0 01-.5-.5V3.911a.5.5 0 01.146-.353L20.8 1.1a.5.5 0 00-.193-.827 4.992 4.992 0 00-6.5 5.76.5.5 0 01-.128.458l-7.5 7.495a.494.494 0 01-.458.128 4.992 4.992 0 00-5.759 6.5.5.5 0 00.838.186l2.459-2.459a.5.5 0 01.351-.141h1.414a.5.5 0 01.5.5v1.414a.5.5 0 01-.147.354l-2.449 2.447a.5.5 0 00.2.828A5.039 5.039 0 005.015 24a4.994 4.994 0 004.873-6.024.494.494 0 01.128-.458z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});