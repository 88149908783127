define("ember-svg-jar/inlined/discount-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>discount-circle</title><path d=\"M12 .5A11.5 11.5 0 1023.5 12 11.513 11.513 0 0012 .5zm1.833 15.167a1.834 1.834 0 111.834 1.833 1.835 1.835 0 01-1.834-1.833zM17.6 7.818L7.818 17.6A1 1 0 016.4 16.182L16.182 6.4A1 1 0 1117.6 7.818zm-7.429.515A1.834 1.834 0 118.333 6.5a1.834 1.834 0 011.834 1.833z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});