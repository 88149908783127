define("ember-svg-jar/inlined/artboard-subtract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>artboard-subtract</title><path d=\"M6 3.5a1 1 0 001-1V1a1 1 0 00-2 0v1.5a1 1 0 001 1zM2.5 5H1a1 1 0 000 2h1.5a1 1 0 000-2zM2.5 17H1a1 1 0 000 2h1.5a1 1 0 000-2zM18 0a1 1 0 00-1 1v1.5a1 1 0 002 0V1a1 1 0 00-1-1zM6 20.5a1 1 0 00-1 1V23a1 1 0 002 0v-1.5a1 1 0 00-1-1zM18 20.5a1 1 0 00-1 1V23a1 1 0 002 0v-1.5a1 1 0 00-1-1zM21.5 7H23a1 1 0 000-2h-1.5a1 1 0 000 2zM23 17h-1.5a1 1 0 000 2H23a1 1 0 000-2zM18 5H6a1 1 0 00-1 1v12a1 1 0 001 1h12a1 1 0 001-1V6a1 1 0 00-1-1zM8 12a1 1 0 011-1h6a1 1 0 010 2H9a1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});