define("ember-svg-jar/inlined/video-game-control-gear-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-control-gear-1</title><path d=\"M7.447 22.276A.5.5 0 007 22H3.617a.5.5 0 00-.447.276l-.5 1a.5.5 0 00.447.724H7.5a.5.5 0 00.448-.724zM20.447 22.276A.5.5 0 0020 22h-3a.5.5 0 00-.448.276l-.5 1A.5.5 0 0016.5 24h4a.5.5 0 00.448-.724zM15 14h4a.5.5 0 00.5-.5v-1a1 1 0 00-1-1h-3a1 1 0 00-1 1v1a.5.5 0 00.5.5z\"/><path d=\"M21.5 15h-10a.5.5 0 01-.5-.5V9.082a.5.5 0 01.3-.458 4.5 4.5 0 10-3.6 0 .5.5 0 01.3.458V14.5a.5.5 0 01-.5.5h-5a1 1 0 00-1 1v4.5a.5.5 0 00.5.5h20a.5.5 0 00.5-.5V16a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});