define("ember-svg-jar/inlined/school-bag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>school-bag</title><path d=\"M16.522 3.765a1 1 0 01-.634-.706 4 4 0 00-7.776 0 1 1 0 01-.633.707A4.507 4.507 0 004.5 8v14.462A1.54 1.54 0 006.038 24h11.924a1.54 1.54 0 001.538-1.538V8a4.509 4.509 0 00-2.978-4.235zm-6.329-.622a2 2 0 013.637.05.215.215 0 01-.017.207.218.218 0 01-.183.1h-3.211a.25.25 0 01-.226-.357zM16.5 18.578A1.421 1.421 0 0115.078 20H8.922A1.421 1.421 0 017.5 18.578V17a.5.5 0 01.5-.5h4.75a.5.5 0 01.5.5v.5a.75.75 0 001.5 0V17a.5.5 0 01.5-.5H16a.5.5 0 01.5.5zm0-4.078a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5v-.411A1.59 1.59 0 019.089 12.5h5.822a1.59 1.59 0 011.589 1.589zM2.5 14A2.5 2.5 0 000 16.5v5A2.5 2.5 0 002.5 24a.5.5 0 00.5-.5v-9a.5.5 0 00-.5-.5zM21.5 14a.5.5 0 00-.5.5v9a.5.5 0 00.5.5 2.5 2.5 0 002.5-2.5v-5a2.5 2.5 0 00-2.5-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});