define("ember-svg-jar/inlined/real-estate-settings-building-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-settings-building-2</title><path d=\"M23 9.25h-5a.5.5 0 00-.5.5v3a1.5 1.5 0 01-1.5 1.5H8a1.5 1.5 0 01-1.5-1.5v-3a.5.5 0 00-.5-.5H1a1 1 0 00-1 1v2a1 1 0 001 1h1.968a.5.5 0 01.483.37A13.418 13.418 0 004 15.278a.5.5 0 01-.108.547l-1.439 1.436a.991.991 0 00-.293.707 1.009 1.009 0 00.293.707L4.575 20.8a.852.852 0 00.688.294h.039a1 1 0 00.687-.293l1.434-1.441a.5.5 0 01.549-.107 13.039 13.039 0 001.658.547.5.5 0 01.37.483v1.967a1 1 0 001 1h2a1 1 0 001-1v-1.968a.5.5 0 01.37-.483 13.183 13.183 0 001.657-.545.5.5 0 01.547.107l1.437 1.439a1 1 0 001.414 0l2.121-2.121a1 1 0 000-1.414l-1.435-1.436a.5.5 0 01-.111-.551 13.418 13.418 0 00.545-1.658.5.5 0 01.483-.37H23a1 1 0 001-1v-2a1 1 0 00-1-1z\"/><path d=\"M8 13.25h1.5a.5.5 0 00.5-.5v-2a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v2a.5.5 0 00.5.5H16a.5.5 0 00.5-.5v-9a.5.5 0 00-.235-.424l-4-2.5a.5.5 0 00-.53 0l-4 2.5a.5.5 0 00-.235.424v9a.5.5 0 00.5.5zm2.5-9h3a.75.75 0 010 1.5h-3a.75.75 0 010-1.5zm0 3h3a.75.75 0 010 1.5h-3a.75.75 0 010-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});