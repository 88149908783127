define("ember-svg-jar/inlined/cake-cherry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cake-cherry</title><path d=\"M.5 15h23a.5.5 0 00.338-.868l-6.676-6.144a.25.25 0 00-.406.1A5.01 5.01 0 117.4 4.538a.25.25 0 00-.354-.315C3.912 6 0 9.267 0 14.5a.5.5 0 00.5.5z\"/><path d=\"M12.01 9.479a2.989 2.989 0 001.666-5.472.5.5 0 01-.129-.7 3.241 3.241 0 012.118-1.317.992.992 0 00.811-1.154 1.007 1.007 0 00-1.161-.821 5.217 5.217 0 00-3.981 3.2 1 1 0 01-.524.541 2.99 2.99 0 001.2 5.728zM23.5 21H.5a.5.5 0 00-.5.5v.5a2 2 0 002 2h20a2 2 0 002-2v-.5a.5.5 0 00-.5-.5z\"/><rect y=\"16.498\" width=\"24\" height=\"3\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});