define("ember-svg-jar/inlined/tools-hammer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-hammer</title><path d=\"M21.629 6.066a.5.5 0 01-.037-.581 2.241 2.241 0 00-1.9-3.433 2.2 2.2 0 00-1.2.353.5.5 0 01-.584-.032A13.86 13.86 0 0015.005.5c-1.384-.622-2.467-.661-3.061-.113l-.031.029-2.828 2.825C8.063 4.263 8.8 7.2 12.819 11.218c2.943 2.942 5.3 4.106 6.748 4.106a1.619 1.619 0 001.184-.416l2.827-2.827v-.006l.014-.013c.831-.869.456-3.022-1.963-5.996zM11.759 12.278q-.457-.457-.872-.909a.5.5 0 00-.722-.015L.517 21.013a1.75 1.75 0 002.475 2.474l9.646-9.657a.5.5 0 00-.015-.721c-.286-.264-.574-.54-.864-.831z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});