define("ember-svg-jar/inlined/book-library-shelf-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-library-shelf-1</title><path d=\"M23 0a1 1 0 00-1 1v6a1 1 0 01-1 1h-1a1 1 0 01-1-1V1a1 1 0 00-2 0v12.5a2.253 2.253 0 002.25 2.25h2.5A2.253 2.253 0 0024 13.5V1a1 1 0 00-1-1zM23.874 16.732a.253.253 0 00-.251 0 3.661 3.661 0 01-1.873.517h-2.5a3.661 3.661 0 01-1.873-.517.253.253 0 00-.251 0 .249.249 0 00-.126.217V21a3 3 0 003 3h1a3 3 0 003-3v-4.051a.25.25 0 00-.126-.217zM21 21.25h-1a1.25 1.25 0 010-2.5h1a1.25 1.25 0 010 2.5zM14.5 0a1 1 0 00-1 1v6a1 1 0 01-1 1h-1a1 1 0 01-1-1V1a1 1 0 00-2 0v12.5a2.253 2.253 0 002.25 2.25h2.5a2.253 2.253 0 002.25-2.25V1a1 1 0 00-1-1zM15.374 16.732a.253.253 0 00-.251 0 3.661 3.661 0 01-1.873.517h-2.5a3.661 3.661 0 01-1.873-.517.253.253 0 00-.251 0 .249.249 0 00-.126.217V21a3 3 0 003 3h1a3 3 0 003-3v-4.051a.25.25 0 00-.126-.217zM12.5 21.25h-1a1.25 1.25 0 010-2.5h1a1.25 1.25 0 010 2.5zM6 0a1 1 0 00-1 1v6a1 1 0 01-1 1H3a1 1 0 01-1-1V1a1 1 0 00-2 0v12.5a2.253 2.253 0 002.25 2.25h2.5A2.253 2.253 0 007 13.5V1a1 1 0 00-1-1zM6.874 16.732a.253.253 0 00-.251 0 3.661 3.661 0 01-1.873.517h-2.5a3.661 3.661 0 01-1.873-.517.253.253 0 00-.251 0 .249.249 0 00-.126.217V21a3 3 0 003 3h1a3 3 0 003-3v-4.051a.25.25 0 00-.126-.217zM4 21.25H3a1.25 1.25 0 010-2.5h1a1.25 1.25 0 010 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});