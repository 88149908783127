define("ember-svg-jar/inlined/cash-target", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-target</title><path d=\"M1 13h.826a.25.25 0 01.248.22 10.022 10.022 0 008.707 8.706.25.25 0 01.219.248V23a1 1 0 002 0v-.826a.25.25 0 01.219-.248 10.022 10.022 0 008.707-8.706.249.249 0 01.248-.22H23a1 1 0 000-2h-.826a.249.249 0 01-.248-.22 10.022 10.022 0 00-8.707-8.706.248.248 0 01-.219-.248V1a1 1 0 00-2 0v.826a.249.249 0 01-.219.248 10.022 10.022 0 00-8.707 8.707.249.249 0 01-.248.219H1a1 1 0 000 2zm3.5-2h-.149a.249.249 0 01-.247-.29 8.022 8.022 0 016.605-6.61.253.253 0 01.2.057.249.249 0 01.091.195V4.5a1 1 0 002 0v-.148a.249.249 0 01.088-.191.253.253 0 01.2-.057A8.022 8.022 0 0119.9 10.71a.249.249 0 01-.247.29H19.5a1 1 0 000 2h.149a.251.251 0 01.247.291 8.021 8.021 0 01-6.6 6.605.25.25 0 01-.296-.247V19.5a1 1 0 00-2 0v.149a.25.25 0 01-.291.247 8.021 8.021 0 01-6.6-6.6.252.252 0 01.242-.296H4.5a1 1 0 000-2z\"/><path d=\"M12.5 14H10a1 1 0 000 2h.75a.25.25 0 01.25.25v.25a1 1 0 002 0v-.331a.25.25 0 01.2-.244 2.189 2.189 0 001.8-2.189 2.239 2.239 0 00-1.287-2.162l-2.647-.992a.7.7 0 01-.066-.318c0-.079 0-.264.5-.264H14a1 1 0 000-2h-.75a.249.249 0 01-.25-.25V7.5a1 1 0 00-2 0v.332a.248.248 0 01-.2.243A2.189 2.189 0 009 10.264a2.237 2.237 0 001.287 2.162l2.647.992a.7.7 0 01.066.318c0 .079 0 .264-.5.264z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});