define("ember-svg-jar/inlined/protection-sand-bag-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>protection-sand-bag-1</title><path d=\"M5.25 20.5h-3.5a1.75 1.75 0 000 3.5h3.5a1.75 1.75 0 000-3.5zM5.25 15.5h-3.5a1.75 1.75 0 000 3.5h3.5a1.75 1.75 0 000-3.5zM18.25 19h3.5a1.75 1.75 0 000-3.5h-3.5a1.75 1.75 0 000 3.5zM13.75 20.5h-3.5a1.75 1.75 0 000 3.5h3.5a1.75 1.75 0 000-3.5zM22.25 20.5h-3.5a1.75 1.75 0 000 3.5h3.5a1.75 1.75 0 000-3.5z\"/><path d=\"M21.5 14a.5.5 0 00.5-.5V12a4.505 4.505 0 00-4.5-4.5h-4.25a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25H17a.5.5 0 00.5-.5V2a.5.5 0 00-.5-.5h-3.75a.25.25 0 01-.25-.25 1.25 1.25 0 00-2.5 0v6a.25.25 0 01-.25.25H6.5A4.505 4.505 0 002 12v1.5a.5.5 0 00.5.5h2.75a3.254 3.254 0 013.25 3.25 3.178 3.178 0 01-.189 1.081.5.5 0 00.471.669h5.936a.5.5 0 00.471-.669A3.178 3.178 0 0115 17.25 3.254 3.254 0 0118.25 14zM16 12.5H8a1 1 0 010-2h8a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});