define("ember-svg-jar/inlined/travel-tripit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>travel-tripit</title><ellipse cx=\"11.5\" cy=\"14.5\" rx=\"2.7\" ry=\"2.16\"/><path d=\"M11 9v2a.5.5 0 001 0V9a2.5 2.5 0 10-1 0zM22.25 12.75A1.75 1.75 0 0020.58 14h-5.33a.5.5 0 000 1h5.33a1.75 1.75 0 101.67-2.25zM17.41 17.13a1.5 1.5 0 00-1.72-.06l-1.09-.85a.5.5 0 10-.6.78l1.1.86a1.5 1.5 0 00.52 1.65 1.5 1.5 0 101.81-2.39zM14.07 12.95a.5.5 0 00.69.17l4.79-2.9a1.47 1.47 0 00.58.3 1.52 1.52 0 00.36 0 1.49 1.49 0 00.77-.22 1.5 1.5 0 10-2.24-1l-4.78 2.9a.5.5 0 00-.17.75zM7.75 15a.5.5 0 000-1H3.42a1.73 1.73 0 00-1.67-1.24 1.75 1.75 0 000 3.5A1.75 1.75 0 003.42 15zM8.21 15.9L6.3 17.26a1.48 1.48 0 00-.57-.26 1.52 1.52 0 00-1.13.18A1.5 1.5 0 005 19.9a1.52 1.52 0 00.35 0 1.49 1.49 0 001.49-1.8l2-1.39a.5.5 0 00-.58-.82zM2.8 10.4a1.48 1.48 0 001.72 0l3.81 2.58a.49.49 0 00.28.09.5.5 0 00.28-.91l-3.8-2.62a1.5 1.5 0 10-2.29.87z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});