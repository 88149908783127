define("ember-svg-jar/inlined/folder-music", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-music</title><path d=\"M21.5 5.25a.25.25 0 00.25-.25v-.75A.75.75 0 0021 3.5H8l-.18-.076-1.342-1.389a1.751 1.751 0 00-1.26-.535H2.286A2.037 2.037 0 00.25 3.536V16.25A1.749 1.749 0 002 18h.25a.25.25 0 00.25-.25v-9.5a3 3 0 013-3z\"/><path d=\"M22 7H6a1.752 1.752 0 00-1.75 1.75v12A1.752 1.752 0 006 22.5h16a1.752 1.752 0 001.75-1.75v-12A1.752 1.752 0 0022 7zm-4.25 8.75A1.75 1.75 0 1116 14c.086 0 .25.056.25-.091V11.7a.248.248 0 00-.09-.192.245.245 0 00-.205-.053l-3.409 1.1c-.21.044-.3.148-.3.268v4.927A1.75 1.75 0 1110.5 16c.086 0 .25.068.25-.109v-3.062a1.753 1.753 0 011.428-1.72l3.409-1.1a1.776 1.776 0 011.531.349 1.745 1.745 0 01.632 1.342z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});