define("ember-svg-jar/inlined/insect-ant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>insect-ant</title><path d=\"M8.64 3.628s.973 3.334 1.027 3.416A3.951 3.951 0 009.6 7.7c0 1.325 1.075 1.8 2.4 1.8s2.4-.475 2.4-1.8a3.941 3.941 0 00-.066-.656c.053-.082 1.027-3.416 1.027-3.416l2.694-1.8a1 1 0 00-1.109-1.66l-3 2a1 1 0 00-.407.558l-.417 1.457A1.542 1.542 0 0012 3.5a1.543 1.543 0 00-1.122.683l-.416-1.457a1 1 0 00-.407-.558l-3-2a1 1 0 10-1.109 1.664zM21.442 19.164c-1.646-4.617-6.192-5.7-8.692-5.641v-.216A1.776 1.776 0 0013.8 11.7v-.65a.251.251 0 00-.1-.2.254.254 0 00-.22-.041 5.837 5.837 0 01-2.962 0 .251.251 0 00-.319.241v.65a1.776 1.776 0 001.05 1.607v.216c-2.5-.051-7.045 1.023-8.692 5.641a1 1 0 001.884.672 6.622 6.622 0 016-4.279 2.487 2.487 0 00-.888 1.424A6.508 6.508 0 005.5 23a1 1 0 002 0 4.484 4.484 0 012.321-3.912c.494 1.56 1.464 3.412 2.179 3.412s1.685-1.852 2.18-3.412A4.483 4.483 0 0116.5 23a1 1 0 002 0 6.508 6.508 0 00-4.055-6.019 2.494 2.494 0 00-.889-1.424 6.617 6.617 0 016 4.279 1 1 0 001.884-.672z\"/><path d=\"M8.369 12.523a.25.25 0 00.385-.254A3.049 3.049 0 018.7 11.7v-1.433a.25.25 0 00-.059-.162A5.337 5.337 0 017.5 6.5a1 1 0 00-2 0 6.822 6.822 0 002.869 6.023zM17.5 5.5a1 1 0 00-1 1 5.332 5.332 0 01-1.14 3.609.25.25 0 00-.06.163V11.7a3.049 3.049 0 01-.054.569.25.25 0 00.385.254A6.821 6.821 0 0018.5 6.5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});