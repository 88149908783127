define("ember-svg-jar/inlined/indent-increase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>indent-increase</title><path d=\"M18.626 21h-17.5a1 1 0 000 2h17.5a1 1 0 000-2zM1.126 3h17.5a1 1 0 000-2h-17.5a1 1 0 000 2zM1.126 8h11.5a1 1 0 000-2h-11.5a1 1 0 000 2zM1.126 13h11.5a1 1 0 000-2h-11.5a1 1 0 100 2zM1.126 18h11.5a1 1 0 100-2h-11.5a1 1 0 000 2zM18.291 6.75a1 1 0 00-1.665.748v9.329a1 1 0 001.665.747l5.247-4.665a1 1 0 000-1.494z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});