define("ember-svg-jar/inlined/tools-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-box</title><path d=\"M20.5 5.875h-2.75a.5.5 0 01-.5-.5v-1A3.254 3.254 0 0014 1.125h-4a3.254 3.254 0 00-3.25 3.25v1a.5.5 0 01-.5.5H3.5a3.5 3.5 0 00-3.5 3.5v10a3.5 3.5 0 003.5 3.5h17a3.5 3.5 0 003.5-3.5v-10a3.5 3.5 0 00-3.5-3.5zm-11.25-1.5a.76.76 0 01.75-.75h4a.76.76 0 01.75.75v1a.5.5 0 01-.5.5h-4.5a.5.5 0 01-.5-.5zm6.188 10.114l.687.74a1.393 1.393 0 01-1.33 2.307l-.981-.228a.161.161 0 00-.191.112l-.295.969a1.387 1.387 0 01-2.656 0l-.295-.968a.161.161 0 00-.191-.113l-.981.228a1.393 1.393 0 01-1.33-2.307l.688-.74a.169.169 0 000-.228l-.688-.74a1.393 1.393 0 011.33-2.307l.981.228a.163.163 0 00.191-.112l.295-.969a1.387 1.387 0 012.656 0l.295.968a.162.162 0 00.191.113l.981-.228a1.393 1.393 0 011.33 2.307l-.687.74a.167.167 0 000 .228z\"/><circle cx=\"12\" cy=\"14.375\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});