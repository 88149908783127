define("ember-svg-jar/inlined/style-one-pin-church", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-church</title><path d=\"M12 0a9.5 9.5 0 00-4 18.119 2.509 2.509 0 011.218 1.222l1.872 4.076a1 1 0 001.817 0l1.876-4.079A2.5 2.5 0 0116 18.118 9.5 9.5 0 0012 0zm5.781 10.087A.5.5 0 0117.5 11h-1a.5.5 0 00-.5.5V15a.5.5 0 01-.5.5H14a.5.5 0 01-.5-.5v-2a1.5 1.5 0 00-3 0v2a.5.5 0 01-.5.5H8.5A.5.5 0 018 15v-3.5a.5.5 0 00-.5-.5h-1a.5.5 0 01-.281-.913l4.813-3.282a.5.5 0 00.218-.413V6a.5.5 0 00-.5-.5h-.5a.75.75 0 010-1.5h.5a.5.5 0 00.5-.5V3a.75.75 0 011.5 0v.5a.5.5 0 00.5.5h.5a.75.75 0 010 1.5h-.5a.5.5 0 00-.5.5v.392a.5.5 0 00.218.413z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});