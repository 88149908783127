define("ember-svg-jar/inlined/smiley-sad-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-sad-1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM7.25 7a2 2 0 11-2 2 2 2 0 012-2zm-.088 9.788a5.773 5.773 0 019.676 0 1 1 0 01-1.676 1.093 3.771 3.771 0 00-6.324 0 1 1 0 01-1.676-1.093zM16.75 11a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});