define("ember-svg-jar/inlined/style-three-pin-camera", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-camera</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM6 6.75A2.25 2.25 0 118.25 9 2.25 2.25 0 016 6.75zM14.5 14a1 1 0 01-1 1H8a1 1 0 01-1-1v-3a1 1 0 011-1h5.5a1 1 0 011 1zm-1-5A1.5 1.5 0 1115 7.5 1.5 1.5 0 0113.5 9zm4.5 5a.5.5 0 01-.724.447l-1.074-.8a.5.5 0 01-.2-.4v-1.5a.5.5 0 01.2-.4l1.074-.8A.5.5 0 0118 11z\"/><path d=\"M11 11.5H9a.5.5 0 000 1h2a.5.5 0 000-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});