define("ember-svg-jar/inlined/laundry-machine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laundry-machine</title><path d=\"M21.5 5.5A.5.5 0 0022 5V2.5A2.5 2.5 0 0019.5 0h-15A2.5 2.5 0 002 2.5V5a.5.5 0 00.5.5zM19 2a1 1 0 11-1 1 1 1 0 011-1zm-3 0a1 1 0 11-1 1 1 1 0 011-1zm-3 0a1 1 0 11-1 1 1 1 0 011-1zM11.062 15.245a.5.5 0 00.188-.391v-3.4a.5.5 0 00-.647-.477A4.74 4.74 0 007.25 15.5a4.552 4.552 0 00.33 1.691.5.5 0 00.776.206zM12.75 14.844a.5.5 0 00.189.391l2.705 2.16a.5.5 0 00.777-.207 4.556 4.556 0 00.329-1.688 4.74 4.74 0 00-3.35-4.523.5.5 0 00-.648.477zM12.318 16.658a.5.5 0 00-.623 0L9.27 18.586a.5.5 0 00.024.8 4.672 4.672 0 005.415 0 .5.5 0 00.024-.8z\"/><path d=\"M2.5 7a.5.5 0 00-.5.5v14A2.5 2.5 0 004.5 24h15a2.5 2.5 0 002.5-2.5v-14a.5.5 0 00-.5-.5zm14.411 12.365c-.006.008-.016.01-.022.018a6.231 6.231 0 01-9.78 0c-.006-.006-.015-.009-.021-.016s-.01-.024-.017-.034a6.25 6.25 0 119.86 0 .36.36 0 01-.02.032z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});