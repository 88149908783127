define("ember-svg-jar/inlined/touch-id-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>touch-id-4</title><path d=\"M12 0a12 12 0 1012 12A12.015 12.015 0 0012 0zm0 16.32a1.961 1.961 0 001.958-1.959V9.64a1.958 1.958 0 10-3.916 0v1.416a.875.875 0 01-1.75 0V9.64a3.708 3.708 0 117.416 0v4.721a3.708 3.708 0 11-7.416 0 .875.875 0 011.75 0A1.961 1.961 0 0012 16.32zm-.875-2.43v-2.834a.875.875 0 111.75 0v2.834a.875.875 0 01-1.75 0zm-2.7-7.445a4.78 4.78 0 00-1.221 3.2v4.721a4.792 4.792 0 008.364 3.2.875.875 0 111.3 1.167 6.542 6.542 0 01-11.41-4.372V9.64a6.528 6.528 0 011.667-4.363.875.875 0 011.3 1.168zm10.113 7.916a.875.875 0 11-1.75 0V9.64A4.8 4.8 0 0010.5 5.085a.875.875 0 01-.546-1.662 6.547 6.547 0 018.588 6.217z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});