define("ember-svg-jar/inlined/zip-file-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>zip-file-edit</title><path d=\"M18 11.068v-5.86a1.5 1.5 0 00-.439-1.061L13.854.44a1.5 1.5 0 00-1.06-.44H1.5A1.5 1.5 0 000 1.5v19A1.5 1.5 0 001.5 22h8.057a.249.249 0 00.245-.2l.488-2.442a1.982 1.982 0 01.555-1.03l7.083-7.083a.251.251 0 00.072-.177zM6 18.5a1 1 0 111-1 1 1 0 01-1 1zM6 15a1 1 0 111-1 1 1 0 01-.994 1zm0-3.5a1 1 0 111-1 1 1 0 01-.994 1zm1.5-4.9a1.968 1.968 0 01-1.479.67 1.975 1.975 0 01-2.004-2.22l.49-3.127A.5.5 0 015 1.5h2a.5.5 0 01.494.423l.485 3.112v.015a1.982 1.982 0 01-.47 1.55zM12.061 20.131a.25.25 0 00-.422.128l-.628 3.141a.5.5 0 00.49.6.466.466 0 00.1-.01l3.143-.629a.25.25 0 00.128-.422zM23.035 11.591a2.711 2.711 0 00-3.606.273l-6.58 6.58a.252.252 0 000 .354l3.351 3.353a.249.249 0 00.353 0l6.679-6.679a2.622 2.622 0 00-.197-3.881z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});