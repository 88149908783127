define("ember-svg-jar/inlined/baby-hold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baby-hold</title><path d=\"M8.033 12.485l5.044 1.932a1 1 0 001.067-.229l1.556-1.565a1 1 0 00.29-.658l.425-9.088a.5.5 0 00-.242-.453l-2.51-1.5a6.456 6.456 0 10-5.63 11.561zm-1.249-7.76a4 4 0 111.846 5.347 4 4 0 01-1.846-5.347zM17.2 10.886a.252.252 0 00.278-.053l4.279-4.307a.5.5 0 00-.057-.755q-.225-.165-.469-.312l-3.113-1.867a.5.5 0 00-.757.4l-.311 6.648a.25.25 0 00.15.246zM23.181 7.39a.5.5 0 00-.781-.09l-7.152 7.2a.5.5 0 00.176.82l.852.326a5.741 5.741 0 002.054.383 5.7 5.7 0 004.851-8.639z\"/><path d=\"M8.708 8.4a1 1 0 10-.461-1.337A1 1 0 008.708 8.4zM10.021 5.7a1 1 0 10-.462-1.336 1 1 0 00.462 1.336zM15.5 19.5H6.375a.625.625 0 010-1.25H8.9a1 1 0 00.92-1.394A2.234 2.234 0 007.764 15.5H3.5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5H14a2.5 2.5 0 002.5-2.5 1 1 0 00-1-1zM1 14.5a1 1 0 00-1 1V23a1 1 0 002 0v-7.5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});