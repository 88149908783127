define("ember-svg-jar/inlined/cell-border-bottom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cell-border-bottom</title><path d=\"M22 0h-2.5a1 1 0 000 2h2.25a.25.25 0 01.25.25V4.5a1 1 0 002 0V2a2 2 0 00-2-2zM1 5.5a1 1 0 001-1V2.247A.25.25 0 012.25 2H4.5a1 1 0 000-2H2a2 2 0 00-2 2v2.5a1 1 0 001 1zM23 8a1 1 0 00-1 1v1.75a.249.249 0 01-.25.25H19.5a1 1 0 000 2h2.25a.25.25 0 01.25.25V15a1 1 0 002 0V9a1 1 0 00-1-1zM1 16a1 1 0 001-1v-1.75a.25.25 0 01.25-.25H4.5a1 1 0 000-2H2.25a.249.249 0 01-.25-.25V9a1 1 0 00-2 0v6a1 1 0 001 1zM9 13h1.75a.25.25 0 01.25.25V15a1 1 0 002 0v-1.75a.25.25 0 01.25-.25H15a1 1 0 000-2h-1.75a.249.249 0 01-.25-.25V9a1 1 0 00-2 0v1.75a.249.249 0 01-.25.25H9a1 1 0 000 2zM9 2h1.75a.25.25 0 01.25.25V4.5a1 1 0 002 0V2.247A.25.25 0 0113.25 2H15a1 1 0 000-2H9a1 1 0 000 2zM23 22H1a1 1 0 000 2h22a1 1 0 000-2zM1 21a1 1 0 001-1v-2a1 1 0 00-2 0v2a1 1 0 001 1zM12 17a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zM23 17a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});