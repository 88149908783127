define("ember-svg-jar/inlined/gymnastics-acrobatic-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gymnastics-acrobatic-1</title><circle cx=\"12\" cy=\"6.979\" r=\"2.5\"/><path d=\"M20.476 15.3a1.135 1.135 0 00-1.089-.075l-4.177 1.929a.5.5 0 01-.71-.454v-3.783l1.443-1.339a8.171 8.171 0 002.873-5.384l.178-1.6a1 1 0 00-.883-1.1 1.015 1.015 0 00-1.105.884l-.179 1.605a6.2 6.2 0 01-2.206 4.1 3.385 3.385 0 01-2.3.9h-.635a3.388 3.388 0 01-2.3-.9 6.206 6.206 0 01-2.206-4.1l-.186-1.614a1.013 1.013 0 00-1.1-.884 1 1 0 00-.888 1.105l.179 1.6a8.163 8.163 0 002.873 5.384L9.5 12.917V16.7a.5.5 0 01-.709.454l-4.178-1.928a1.137 1.137 0 00-.957 2.062l2.852 1.331a12.991 12.991 0 0010.984 0l2.852-1.331a1.136 1.136 0 00.132-1.987z\"/><path d=\"M8.75 3.731h7a1 1 0 000-2h-7a1 1 0 000 2zM23 0a1 1 0 00-1 1v.731h-1.75a1 1 0 000 2H22V23a1 1 0 002 0V1a1 1 0 00-1-1zM3.75 3.731a1 1 0 100-2H2V1a1 1 0 00-2 0v22a1 1 0 002 0V3.731z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});