define("ember-svg-jar/inlined/messages-bubble-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-disable</title><path d=\"M11.846 11.838A8.032 8.032 0 0119.332 9.7a7.674 7.674 0 012.163.86.25.25 0 00.371-.169A7.341 7.341 0 0022 9c0-4.961-4.934-9-11-9S0 4.037 0 9a8.08 8.08 0 002.657 5.853l-1.625 4.472a.5.5 0 00.124.532.5.5 0 00.538.1l5.832-2.429a13.2 13.2 0 001.71.342.25.25 0 00.283-.251 7.971 7.971 0 012.327-5.781z\"/><path d=\"M12.906 12.9A6.5 6.5 0 1023.832 16a6.531 6.531 0 00-10.926-3.1zm2.483 8.575a.249.249 0 01-.057-.4l5.753-5.752a.251.251 0 01.4.057 4.507 4.507 0 01-6.092 6.091zm-1.069-7.162a4.525 4.525 0 015.29-.8.251.251 0 01.06.4l-5.746 5.744a.25.25 0 01-.217.07.253.253 0 01-.184-.136 4.441 4.441 0 01.797-5.278z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});