define("ember-svg-jar/inlined/crime-woman-riot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-woman-riot</title><path d=\"M22.725 18.081a13.9 13.9 0 00-4.844-2.26.248.248 0 00-.312.242 4.632 4.632 0 01-.389 1.483.125.125 0 00.007.1.119.119 0 00.078.062 12.137 12.137 0 014.258 1.946.964.964 0 01.374.777v2.579a.99.99 0 101.979 0v-2.579a2.931 2.931 0 00-1.151-2.35zM6.431 16.063a.245.245 0 00-.095-.2.248.248 0 00-.216-.044 13.893 13.893 0 00-4.846 2.261 2.93 2.93 0 00-1.15 2.349v2.581a.99.99 0 101.979 0v-2.579a.963.963 0 01.373-.776 12.029 12.029 0 014.13-1.912.246.246 0 00.168-.322 4.276 4.276 0 01-.343-1.358z\"/><circle cx=\"13.979\" cy=\"8.907\" r=\".99\"/><circle cx=\"10.021\" cy=\"8.907\" r=\".99\"/><path d=\"M11.763 22.455a.5.5 0 00.474 0c.17-.093 3.646-2.066 4.561-7.33a.246.246 0 01.318-.193 4.965 4.965 0 001.28.161 3.76 3.76 0 002.891-1.109.493.493 0 00-.229-.787c-2.681-.862-2.378-5.178-2.378-6.517a6.68 6.68 0 00-13.36 0c0 .666.426 5.557-2.378 6.458a.493.493 0 00-.229.787A3.76 3.76 0 005.6 15.034a4.241 4.241 0 001.523-.234.021.021 0 01.019 0 .018.018 0 01.01.015c.829 5.497 4.438 7.545 4.611 7.64zM11.938 5.8a.092.092 0 01.124 0A7.176 7.176 0 0017.1 7.481a.1.1 0 01.1.1V8.66a5.144 5.144 0 01-.785 2.731.246.246 0 01-.355.069 6.908 6.908 0 00-8.112 0 .246.246 0 01-.355-.069A5.144 5.144 0 016.8 8.66v-.934a.247.247 0 01.245-.247A7.091 7.091 0 0011.938 5.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});