define("ember-svg-jar/inlined/move-to-bottom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>move-to-bottom</title><path d=\"M15.94 14.139a.75.75 0 00-1.237.519l-.441 6.682a.749.749 0 00.911.782l6.539-1.447a.751.751 0 00.326-1.3l-2.082-1.787a.252.252 0 01-.065-.293A14.479 14.479 0 0017.869 2a1.5 1.5 0 00-2.288 1.94 11.427 11.427 0 011.975 11.136.254.254 0 01-.171.159.251.251 0 01-.228-.052zM3.135 7h4.131A1.436 1.436 0 008.7 5.564v-4.13A1.435 1.435 0 007.266 0H3.135A1.436 1.436 0 001.7 1.434v4.13A1.437 1.437 0 003.135 7zM3.7 2.249A.25.25 0 013.95 2h2.5a.249.249 0 01.25.25v2.5a.249.249 0 01-.25.25h-2.5a.25.25 0 01-.25-.25zM1.7 14.064A1.437 1.437 0 003.135 15.5h4.131A1.436 1.436 0 008.7 14.064v-4.13A1.435 1.435 0 007.266 8.5H3.135A1.436 1.436 0 001.7 9.934zm2-3.315a.25.25 0 01.25-.25h2.5a.249.249 0 01.25.25v2.5a.249.249 0 01-.25.25h-2.5a.25.25 0 01-.25-.25z\"/><rect x=\"1.7\" y=\"16.999\" width=\"7\" height=\"7\" rx=\"1.435\" ry=\"1.435\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});