define("ember-svg-jar/inlined/phone-actions-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-info</title><path d=\"M11.046 15.489A2.492 2.492 0 018.7 12.133a44.512 44.512 0 01-1.246-1.406l.386-.385a2.508 2.508 0 000-3.536L5.72 4.685a2.56 2.56 0 00-3.535 0L1.022 5.849a3.51 3.51 0 00-.442 4.4 46.919 46.919 0 0013.141 13.145 3.54 3.54 0 004.4-.443l1.164-1.163a2.5 2.5 0 000-3.535l-2.121-2.122a2.5 2.5 0 00-3.536 0l-.385.385c-.451-.377-.9-.782-1.355-1.191a2.5 2.5 0 01-.842.164z\"/><path d=\"M11.045 13.985a.926.926 0 00.429-.092l2.972-1.415a6.587 6.587 0 10-2.89-2.89l-1.415 2.972a1 1 0 00.2 1.136 1.044 1.044 0 00.704.289zM22 6.614a4.572 4.572 0 01-6.989 3.886.993.993 0 00-.527-.15 1.013 1.013 0 00-.43.1l-.895.427.427-.895a1 1 0 00-.054-.957A4.576 4.576 0 1122 6.614z\"/><circle cx=\"17.498\" cy=\"8.476\" r=\"1\"/><path d=\"M16.748 3.7v2.526a.75.75 0 001.5 0V3.7a.75.75 0 10-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});