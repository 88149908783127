define("ember-svg-jar/inlined/social-media-foursquare", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-foursquare</title><path d=\"M23.52.54a.75.75 0 00-.82.19l-9 9.8-3.05-3.07a.52.52 0 00-.71 0L8.69 8.71a4.91 4.91 0 10-5.76 7.64l7 7a.5.5 0 00.71 0l7.59-7.6a.5.5 0 000-.71l-1-1 6.52-5.62a.75.75 0 00.25-.57V1.24a.75.75 0 00-.48-.7zm-1 7L10.26 18l-3.35-2.83a.75.75 0 00-.49-.17.74.74 0 00-.27 0 3.43 3.43 0 112.2-3.2 3.4 3.4 0 01-.26 1.31.75.75 0 00.1.75l1.51 1.99a.75.75 0 00.56.29.76.76 0 00.58-.24L22.5 3.17z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});