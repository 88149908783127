define("ember-svg-jar/inlined/single-woman-phone-book", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-phone-book</title><path d=\"M23.875 4a3 3 0 00-3-3H20.1a.5.5 0 01-.433-.25A1.491 1.491 0 0018.375 0h-14.5a.25.25 0 00-.25.25v23.5a.249.249 0 00.25.25h14.5a1.491 1.491 0 001.293-.75.5.5 0 01.432-.25h.774a3 3 0 003-3zm-7.917 14.672a.5.5 0 01-.451.285h-8a.5.5 0 01-.388-.815 5.689 5.689 0 014.39-2.185 5.678 5.678 0 014.391 2.186.5.5 0 01.058.529zm-.134-3.695a.633.633 0 01-.875-.189 4.112 4.112 0 01-.516-1.206.252.252 0 00-.175-.178.249.249 0 00-.242.062 3.595 3.595 0 01-5.017 0 .25.25 0 00-.417.116 4.1 4.1 0 01-.517 1.208A.633.633 0 117 14.1a3.521 3.521 0 00.5-1.934v-1.7a4.012 4.012 0 118.024 0v1.7a3.52 3.52 0 00.5 1.934.632.632 0 01-.2.877zm6.051-4.227a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25zm-2 2.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25zm1-10.25a1 1 0 011 1v1.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25zm1 17a1 1 0 01-1 1h-.75a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25zM2.375 0h-.75a1.5 1.5 0 00-1.5 1.5v21a1.5 1.5 0 001.5 1.5h.75a.249.249 0 00.25-.25V.25a.25.25 0 00-.25-.25z\"/><path d=\"M14.705 10.858a.5.5 0 00-.4-.585A4.339 4.339 0 0111.9 8.892a.425.425 0 00-.394-.193c-.311.013-.391.189-.393.193a4.345 4.345 0 01-2.409 1.381.5.5 0 00.094.991.528.528 0 00.1-.009A5.331 5.331 0 0011.507 9.9a5.334 5.334 0 002.613 1.358.517.517 0 00.1.009.5.5 0 00.485-.409z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});