define("ember-svg-jar/inlined/seo-search-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seo-search-laptop</title><path d=\"M18.368 18.66a2.05 2.05 0 00-1.374-.66H4.744a.25.25 0 01-.25-.25v-8a.25.25 0 01.25-.25h6.262a1 1 0 000-2H4.494a2 2 0 00-2 2v9a.427.427 0 00.009.081.251.251 0 01-.09.244L.38 20.451a1 1 0 00-.368.891A1.775 1.775 0 001.994 23h17a1.774 1.774 0 001.982-1.656.977.977 0 00-.369-.894 23.949 23.949 0 01-2.239-1.79zm-5.874 2.59h-4a.75.75 0 010-1.5h4a.75.75 0 010 1.5z\"/><path d=\"M21.593 13.686a.252.252 0 01-.027-.322 4.924 4.924 0 00.928-2.864 5 5 0 10-5 5 4.9 4.9 0 002.269-.57.249.249 0 01.292.045l2.232 2.233a1 1 0 101.413-1.415zm-4.1-.186a3 3 0 113-3 3 3 0 01-2.999 3zM16.494 2v2a1 1 0 002 0V2a1 1 0 00-2 0zM12.287 2.793a1 1 0 000 1.415l1 1A1 1 0 0014.7 3.793l-1-1a1 1 0 00-1.413 0zM21.287 2.793l-1 1A1 1 0 0021.7 5.208l1-1a1 1 0 00-1.414-1.415z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});