define("ember-svg-jar/inlined/hierarchy-files", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hierarchy-files</title><path d=\"M17.25 3a.5.5 0 00.5.5h2.4a.25.25 0 00.213-.119.248.248 0 00.011-.243 2.7 2.7 0 00-.482-.7L18.311.852a2.727 2.727 0 00-.7-.481.249.249 0 00-.361.223z\"/><path d=\"M17.75 4.5a1.5 1.5 0 01-1.5-1.5V.249A.25.25 0 0016 0h-2.75a1.5 1.5 0 00-1.5 1.5v8a1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5V4.749a.25.25 0 00-.25-.25zM17.75 17.5a1.5 1.5 0 01-1.5-1.5v-2.75A.25.25 0 0016 13h-2.75a1.5 1.5 0 00-1.5 1.5v8a1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5v-4.75a.25.25 0 00-.25-.25z\"/><path d=\"M17.25 16a.5.5 0 00.5.5h2.4a.25.25 0 00.213-.119.248.248 0 00.011-.243 2.7 2.7 0 00-.482-.7l-1.585-1.586a2.727 2.727 0 00-.7-.481.249.249 0 00-.361.223zM4.25 3a1 1 0 001-1V1a1 1 0 00-2 0v1a1 1 0 001 1zM4.25 10a1 1 0 001-1V8a1 1 0 10-2 0v1a1 1 0 001 1zM5.25 4h-1a1 1 0 100 2h1a1 1 0 100-2zM3.25 13a1 1 0 102 0v-1a1 1 0 00-2 0zM3.25 17a1 1 0 002 0v-1a1 1 0 00-2 0zM5.25 19h-1a1 1 0 000 2h1a1 1 0 000-2zM9.25 4h-1a1 1 0 000 2h1a1 1 0 100-2zM9.25 19h-1a1 1 0 000 2h1a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});