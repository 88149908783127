define("ember-svg-jar/inlined/style-three-pin-bench-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-bench-1</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm6 7.516a1.5 1.5 0 01-1.5 1.5.5.5 0 00-.5.5v1a.5.5 0 00.5.5h.75a.75.75 0 010 1.5h-.263a.5.5 0 00-.474.658l.448 1.338a.749.749 0 01-.473.949.733.733 0 01-.238.039.75.75 0 01-.711-.512l-.714-2.131a.5.5 0 00-.474-.341h-4.7a.5.5 0 00-.474.341l-.714 2.131a.75.75 0 01-.711.512.733.733 0 01-.238-.039.749.749 0 01-.473-.949l.448-1.338a.5.5 0 00-.474-.658H6.75a.75.75 0 110-1.5h.75a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5 1.5 1.5 0 01-1.5-1.5v-.5a1.5 1.5 0 011.5-1.5h9a1.5 1.5 0 011.5 1.5z\"/><rect x=\"9.5\" y=\"9.016\" width=\"5\" height=\"2\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});