define("ember-svg-jar/inlined/desktop-monitor-keyboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>desktop-monitor-keyboard</title><path d=\"M23.6 21.589l-3.075-10.637a.251.251 0 01.016-.179l1.8-3.7A7.22 7.22 0 0022.09.249.545.545 0 0021.639 0a.5.5 0 00-.43.28L12 19.154a.5.5 0 00.516.715 7.222 7.222 0 005.537-4l.854-1.751a.25.25 0 01.465.04l2.081 7.2a.5.5 0 01-.48.639h-4.619a1 1 0 000 2h5.3a2.013 2.013 0 001.522-.69 1.96 1.96 0 00.424-1.718zM8.1 20a1.774 1.774 0 00-.544.044l-6.5 2A1 1 0 00.4 23.3a1.014 1.014 0 001.249.662l4.317-1.328a.25.25 0 01.3.142A2 2 0 108.1 20z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});