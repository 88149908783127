define("ember-svg-jar/inlined/mobile-phone-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mobile-phone-2</title><path d=\"M17 .034H7a3 3 0 00-3 3v17.932a3 3 0 003 3h10a3 3 0 003-3V3.034a3 3 0 00-3-3zm-5 22.432a1 1 0 111-1 1 1 0 01-1 1zm6-4.5a1 1 0 01-1 1H7a1 1 0 01-1-1V3.534a1 1 0 011-1h10a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});