define("ember-svg-jar/inlined/diagram-arrow-down-right-corner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-arrow-down-right-corner</title><path d=\"M22.482 24A1.513 1.513 0 0024 22.425l-.348-9.387a1.037 1.037 0 00-1.77-.7l-2.382 2.39a.26.26 0 01-.367 0L4.857.456a1.558 1.558 0 00-2.2 0l-2.2 2.2a1.557 1.557 0 000 2.2l14.27 14.274a.26.26 0 010 .367l-2.385 2.384a1.037 1.037 0 00.7 1.77l9.38.349z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});