define("ember-svg-jar/inlined/smart-watch-circle-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-home</title><path d=\"M12 3.5a8.5 8.5 0 108.5 8.5A8.5 8.5 0 0012 3.5zm5.229 8.281a.34.34 0 01-.319.219H16a.25.25 0 00-.25.25v3.25a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5v-1.25a1.25 1.25 0 00-2.5 0v1.25a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5v-3.25A.25.25 0 008 12h-.911a.339.339 0 01-.318-.219.329.329 0 01.1-.369l4.911-3.833a.341.341 0 01.438 0l4.911 3.833a.33.33 0 01.098.369zM7.379 3.051a.125.125 0 00.135.014 9.98 9.98 0 018.972 0 .125.125 0 00.177-.144l-.68-2.55A.5.5 0 0015.5 0h-7a.5.5 0 00-.483.371l-.68 2.551a.125.125 0 00.042.129z\"/><path d=\"M16.621 20.949a.125.125 0 00-.135-.015 9.972 9.972 0 01-8.972 0 .125.125 0 00-.177.144l.68 2.551A.5.5 0 008.5 24h7a.5.5 0 00.483-.371l.68-2.551a.125.125 0 00-.042-.129z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});