define("ember-svg-jar/inlined/toys-propeller-hat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toys-propeller-hat</title><path d=\"M0 3a2.752 2.752 0 002.75 2.75h6.04a.5.5 0 01.351.144A1.171 1.171 0 0010 6.25h.5a.5.5 0 01.5.5v2.594a.5.5 0 01-.439.5 12.153 12.153 0 00-9.932 8.17.5.5 0 00.409.657A85.94 85.94 0 0012 19.25a85.94 85.94 0 0010.962-.584.5.5 0 00.408-.657 12.153 12.153 0 00-9.931-8.17.5.5 0 01-.439-.5V6.75a.5.5 0 01.5-.5h.5a1.241 1.241 0 00.513-.11.5.5 0 01.508.058c1.651 1.249 4.357 3.052 6.229 3.052a2.75 2.75 0 000-5.5h-6.2a.5.5 0 01-.4-.194 3.346 3.346 0 00-5.073-.27.5.5 0 01-.668.048C7.308 2.081 4.639.25 2.75.25A2.752 2.752 0 000 3zM23.944 20.56a.5.5 0 00-.563-.438A86.083 86.083 0 0112 20.75a86.083 86.083 0 01-11.381-.628.5.5 0 00-.564.438c-.02.178-.037.358-.05.539a1.753 1.753 0 001.364 1.839A52.66 52.66 0 0012 23.75a52.66 52.66 0 0010.631-.812A1.753 1.753 0 0024 21.1c-.018-.182-.035-.361-.056-.54z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});