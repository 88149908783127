define("ember-svg-jar/inlined/rabbit-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rabbit-1</title><path d=\"M21.5 0c-3.246 0-5.743 2.8-7.435 8.329a6.645 6.645 0 00-4.13 0C8.243 2.8 5.746 0 2.5 0a2.5 2.5 0 000 5c1.15 0 2.69 1.038 3.7 5.807A5.088 5.088 0 005 14v.271A4.329 4.329 0 003.5 17.5a4.5 4.5 0 005.2 4.445A4.331 4.331 0 0012 24a4.3 4.3 0 003.271-2.06A4.5 4.5 0 0020.5 17.5a4.329 4.329 0 00-1.5-3.229V14a5.088 5.088 0 00-1.2-3.193C18.807 6.04 20.348 5 21.5 5a2.5 2.5 0 000-5zM8.75 13.5a1 1 0 111 1 1 1 0 01-1-1zm5.1 3.6l-1.5 1.5a.5.5 0 01-.708 0l-1.5-1.5a.5.5 0 01.354-.853h3a.5.5 0 01.354.853zm.4-2.6a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});