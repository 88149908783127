define("ember-svg-jar/inlined/multiple-actions-check-2.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-check-2</title><path d=\"M14.471 8.4l-.486-.172a1.8 1.8 0 01-.055-1.172 4.647 4.647 0 001.216-3.569A3.22 3.22 0 0012 0a2.994 2.994 0 00-1.342.314.25.25 0 00-.1.363 5 5 0 01.842 2.808 6.266 6.266 0 01-.922 3.624.25.25 0 00.121.372c1.918.716 3.3 1.328 3.9 2.537a.077.077 0 00.1.037 8.052 8.052 0 011.69-.455.25.25 0 00.06-.477 42.534 42.534 0 00-1.878-.723z\"/><path d=\"M13.206 10.753a.249.249 0 00.073-.351c-.546-.8-1.934-1.314-3.808-2l-.486-.172a1.8 1.8 0 01-.055-1.176 4.647 4.647 0 001.216-3.569A3.22 3.22 0 007 0a3.22 3.22 0 00-3.146 3.485 4.605 4.605 0 001.193 3.538 1.877 1.877 0 010 1.185l-.515.19c-2.01.739-3.46 1.272-3.914 2.179A7.542 7.542 0 000 13.5a.5.5 0 00.5.5h9.667a.25.25 0 00.223-.137 7.8 7.8 0 012.816-3.11zM23.049 18.19a1 1 0 00-1.253.656A4.5 4.5 0 1117.5 13a1 1 0 000-2 6.5 6.5 0 106.2 8.443 1 1 0 00-.651-1.253z\"/><path d=\"M22.293 12.667l-4.616 4.617a.252.252 0 01-.354 0l-1.178-1.179a1 1 0 10-1.415 1.414l1.577 1.581a1.69 1.69 0 002.386 0l5.014-5.014a1 1 0 00-1.414-1.415z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});