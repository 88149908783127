define("ember-svg-jar/inlined/conversation-chat-text-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>conversation-chat-text-alternate</title><path d=\"M7.293 17.792L6 19.085V17.5a1 1 0 00-1-1H2.5A.5.5 0 012 16V2.5a.5.5 0 01.5-.5H19a.5.5 0 01.5.5V8a1 1 0 002 0V2a2 2 0 00-2-2H2a2 2 0 00-2 2v14.5a2 2 0 002 2h2v3a1 1 0 001.707.707l3-3a1 1 0 00-1.414-1.414z\"/><path d=\"M22 10.5h-9.5a2 2 0 00-2 2V19a2 2 0 002 2h3.167l3.733 2.8A1 1 0 0021 23v-2h1a2 2 0 002-2v-6.5a2 2 0 00-2-2zm0 8a.5.5 0 01-.5.5H20a1 1 0 00-1 1v1l-2.4-1.8a1 1 0 00-.6-.2h-3a.5.5 0 01-.5-.5V13a.5.5 0 01.5-.5h8.5a.5.5 0 01.5.5zM16.25 6.5a.75.75 0 00-.75-.75H5a.75.75 0 000 1.5h10.5a.75.75 0 00.75-.75zM5 10.75a.75.75 0 000 1.5h3a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});