define("ember-svg-jar/inlined/e-commerce-magento", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-magento</title><path d=\"M21.77 6.18L12.26.08a.5.5 0 00-.54 0l-9.49 6.1A.5.5 0 002 6.6v10.8a.5.5 0 00.19.4l2 1.55A.5.5 0 005 19V8.36l7-4.72 7 4.72V19a.5.5 0 00.81.4l2-1.55a.5.5 0 00.19-.4V6.6a.5.5 0 00-.23-.42z\"/><path d=\"M13.76 7.15a.51.51 0 00-.5 0 .5.5 0 00-.25.44v12.25l-1.16.64-.84-.6V7.58a.5.5 0 00-.25-.44.51.51 0 00-.5 0L7.24 9a.5.5 0 00-.24.39v11.27a.5.5 0 00.22.42l4.3 2.84a.49.49 0 00.28.08.5.5 0 00.26-.07l4.7-2.84a.5.5 0 00.24-.43V9.39a.5.5 0 00-.24-.39z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});