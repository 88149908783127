define("ember-svg-jar/inlined/hyperlink-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hyperlink-1</title><path d=\"M6 12a1 1 0 001 1h9.5a1 1 0 000-2H7a1 1 0 00-1 1z\"/><path d=\"M8.2 14.722A3.458 3.458 0 016 15.5a3.5 3.5 0 010-7 3.46 3.46 0 012.2.779 1 1 0 001.258-1.557 5.5 5.5 0 100 8.556A1 1 0 008.2 14.722zM18 6.5a5.519 5.519 0 00-3.458 1.223A1 1 0 0015.8 9.278 3.46 3.46 0 0118 8.5a3.5 3.5 0 010 7 3.458 3.458 0 01-2.2-.777 1 1 0 00-1.258 1.556A5.5 5.5 0 1018 6.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});