define("ember-svg-jar/inlined/single-man-actions-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-player</title><path d=\"M10.5 12a1.489 1.489 0 01.746-1.29.25.25 0 00-.027-.446l-.066-.029a.249.249 0 01-.153-.229V8.87a.252.252 0 01.074-.177 3.311 3.311 0 00.779-2.231.251.251 0 01.088-.176 1.873 1.873 0 00.538-1.4 1.58 1.58 0 00-.317-1.015.251.251 0 01-.034-.264 2.531 2.531 0 00.157-1.727C11.944.493 10.242 0 8.888 0a3.752 3.752 0 00-3.163 1.324.251.251 0 01-.212.118 1.072 1.072 0 00-.9.375c-.372.493-.106 1.17.176 1.732a.251.251 0 01-.046.288 1.3 1.3 0 00-.162.2A1.763 1.763 0 004.32 5a1.521 1.521 0 00.638 1.331.249.249 0 01.117.194A3.167 3.167 0 005.92 8.7a.25.25 0 01.08.183v1.123a.248.248 0 01-.151.229l-3.937 1.687C.64 12.468.457 13.626.205 15.23c-.055.359-.117.751-.2 1.179A.5.5 0 00.5 17h9.749a.25.25 0 00.25-.25zM24 22.125v-8.25A1.876 1.876 0 0022.124 12h-8.25A1.877 1.877 0 0012 13.875v8.25A1.877 1.877 0 0013.874 24h8.25A1.876 1.876 0 0024 22.125zm-8.5-5.945a.931.931 0 011.408-.8l3.034 1.82a.929.929 0 010 1.594l-3.035 1.82a.923.923 0 01-.478.133.94.94 0 01-.459-.121.931.931 0 01-.471-.809z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});