define("ember-svg-jar/inlined/cake-sugar-cane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cake-sugar-cane</title><path d=\"M16.929 2.536A.5.5 0 0016.9 1.8 7.472 7.472 0 0012.8.023a.5.5 0 00-.554.5V3.55a.5.5 0 00.406.491 3.491 3.491 0 011.483.668.5.5 0 00.659-.042zM19.083 18.145a.25.25 0 00.417-.186v-3.976a1 1 0 00-.331-.744l-3.25-2.925a.25.25 0 00-.417.186v3.977a1 1 0 00.331.743zM15.508 6.078a.5.5 0 00-.13.485 3.762 3.762 0 01.122 1.128.5.5 0 00.166.378l3.418 3.076a.25.25 0 00.417-.186V7.48a7.46 7.46 0 00-1.08-3.88.251.251 0 00-.391-.048zM5.818 4.615A.5.5 0 005 4.789a7.486 7.486 0 00-.5 2.691 2 2 0 002 2 1.969 1.969 0 001.983-1.928.5.5 0 00-.142-.414zM8.583 5.26a.5.5 0 00.737-.031 3.5 3.5 0 011.149-.9.5.5 0 00.281-.45V.7a.5.5 0 00-.625-.484 7.494 7.494 0 00-3.684 2.23.5.5 0 00.017.689zM15.917 17.314a.25.25 0 00-.417.186v4.48a2 2 0 104 0v-1a1 1 0 00-.331-.744z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});