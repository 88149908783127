define("ember-svg-jar/inlined/multiple-man-woman-1.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-man-woman-1</title><path d=\"M24 21a7.508 7.508 0 00-7.5-7.5 7.4 7.4 0 00-2.377.393.5.5 0 00-.2.823A8.957 8.957 0 0116.5 21v.024a.469.469 0 00.469.476H23.5a.5.5 0 00.5-.5zM13.675 5.53a6.8 6.8 0 01.279 1.261.5.5 0 00.707.394 5.885 5.885 0 001.777-1.271.251.251 0 01.362 0 5.882 5.882 0 002.468 1.542.5.5 0 01.342.552 3.05 3.05 0 01-3.01 2.585 3.015 3.015 0 01-2.2-.95.5.5 0 00-.83.165 6.479 6.479 0 01-.417.883.5.5 0 00.1.62 4.987 4.987 0 006.5.149.25.25 0 01.391.1 5.293 5.293 0 00.482.964 1 1 0 101.68-1.084 4.744 4.744 0 01-.668-2.608V7.546a5.035 5.035 0 00-8.365-3.787.5.5 0 00-.1.625 5.8 5.8 0 01.502 1.146zM12.5 7.5a5 5 0 10-5 5 5.006 5.006 0 005-5zm-7.9-.743a.5.5 0 01.741-.3A6.959 6.959 0 008.96 7.5a6.36 6.36 0 00.929-.07.5.5 0 01.569.564A3 3 0 014.5 7.5a2.841 2.841 0 01.1-.743zM15 21a7.5 7.5 0 00-15 0 .5.5 0 00.5.5h14a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});