define("ember-svg-jar/inlined/tags-double-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-double-1</title><path d=\"M7 1.527a5.231 5.231 0 013.474 1.439l11 11a3.174 3.174 0 01.6.861.25.25 0 00.317.122l.519-.207a1.606 1.606 0 00.557-2.714l-11-11a3.749 3.749 0 00-2.413-1h-5a1.99 1.99 0 00-1.8 1.143.247.247 0 00.014.24.25.25 0 00.212.117zm15.763 11.207z\"/><path d=\"M20.414 15.027l-11-11a3.934 3.934 0 00-2.414-1H2a2 2 0 00-2 2v5a3.936 3.936 0 001 2.414l11 11a1.712 1.712 0 001.207.529 1.545 1.545 0 00.31-.031 1.658 1.658 0 001.2-1.057l1.39-3.474a.5.5 0 01.278-.278l3.475-1.39a1.606 1.606 0 00.557-2.714zm-14.914-5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});