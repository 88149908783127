define("ember-svg-jar/inlined/hospital-sign-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hospital-sign-circle</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm5.5 17a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-3a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v3a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5V7a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v3a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V7a.5.5 0 01.5-.5h3a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});