define("ember-svg-jar/inlined/paginate-filter-7-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paginate-filter-7-alternate</title><path d=\"M24 2a2 2 0 00-2-2H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2zm-2 16a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5V2.5A.5.5 0 016 2h15.5a.5.5 0 01.5.5z\"/><path d=\"M20.5 23a1 1 0 00-1-1h-17a.5.5 0 01-.5-.5v-17a1 1 0 00-2 0V22a2 2 0 002 2h17.5a1 1 0 001-1z\"/><path d=\"M15.961 5.5H12A1.5 1.5 0 0010.5 7v1a1 1 0 102 0v-.25a.25.25 0 01.25-.25h2.129a.249.249 0 01.232.344l-2.538 6.281a1 1 0 101.854.75l2.91-7.2a1.459 1.459 0 00-.063-1.458 1.558 1.558 0 00-1.313-.717z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});