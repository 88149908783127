define("ember-svg-jar/inlined/light-mode-sunny-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-mode-sunny-man</title><path d=\"M17.25 2.5a.75.75 0 00.75-.75v-1a.75.75 0 00-1.5 0v1a.75.75 0 00.75.75zM20.785 3.964a.746.746 0 00.53-.219l.707-.707a.75.75 0 00-1.06-1.061l-.707.707a.75.75 0 00.53 1.28zM23.25 6h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM21.315 9.755a.75.75 0 00-1.06 1.061l.707.707a.75.75 0 001.06-1.061zM17.25 11a.75.75 0 00-.75.75v1a.75.75 0 001.5 0v-1a.75.75 0 00-.75-.75zM13.185 9.755l-.707.707a.75.75 0 101.06 1.061l.707-.707a.75.75 0 00-1.06-1.061zM13 6.75a.75.75 0 00-.75-.75h-1a.75.75 0 000 1.5h1a.75.75 0 00.75-.75zM13.185 3.745a.75.75 0 001.06-1.061l-.707-.707a.75.75 0 10-1.06 1.061z\"/><circle cx=\"17.25\" cy=\"6.75\" r=\"3.5\"/><path d=\"M6.005 17.5a4.5 4.5 0 10-4.5-4.5 4.505 4.505 0 004.5 4.5zm0-2a2.5 2.5 0 01-2.419-3.132.247.247 0 01.154-.168.252.252 0 01.229.028 4.857 4.857 0 002.744.857 4.758 4.758 0 001.546-.258.186.186 0 01.246.173 2.5 2.5 0 01-2.5 2.5zM6 18.5c-3.309 0-6 2.243-6 5a.5.5 0 00.5.5h11a.5.5 0 00.5-.5c0-2.757-2.691-5-6-5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});