define("ember-svg-jar/inlined/card-game-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>card-game-heart</title><path d=\"M14.038 8.509a2.153 2.153 0 00-1.848.785.252.252 0 01-.19.087.249.249 0 01-.189-.087 2.157 2.157 0 00-1.849-.785C8.985 8.616 8 9.5 8 11.218c0 2.787 3.557 5.381 3.708 5.49a.5.5 0 00.584 0c.151-.109 3.708-2.7 3.708-5.49 0-1.718-.985-2.602-1.962-2.709z\"/><path d=\"M21.5 21a3 3 0 01-3 3h-13a3 3 0 01-3-3V3a3 3 0 013-3h13a3 3 0 013 3zM5.5 2a1 1 0 00-1 1v18a1 1 0 001 1h13a1 1 0 001-1V3a1 1 0 00-1-1z\"/><circle cx=\"7.25\" cy=\"4.75\" r=\"1.25\"/><circle cx=\"16.75\" cy=\"19.25\" r=\"1.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});