define("ember-svg-jar/inlined/scooter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>scooter</title><path d=\"M1.686 9.3l1.853.854a.491.491 0 00.21.046h6.5c.915 0 1.5-1.1 1.5-1.855V8.2a1 1 0 00-1-1h-8.5a1 1 0 00-1 1v.292a.993.993 0 00.293.707.486.486 0 00.144.101zM22.348 17.452h-6.2a.249.249 0 00-.247.216 2.949 2.949 0 00-.029.409 3.376 3.376 0 006.751 0 3.061 3.061 0 00-.028-.408.251.251 0 00-.247-.217zM8.691 18.452H2.806a.251.251 0 00-.247.292 3.232 3.232 0 006.379 0 .251.251 0 00-.247-.292z\"/><path d=\"M19.25 11.452h-.793a.246.246 0 01-.186-.084.249.249 0 01-.063-.2 5.391 5.391 0 00.042-.625V8.12a.249.249 0 01.4-.2 4.34 4.34 0 002.1.747 1 1 0 001-1v-2.93a1 1 0 00-.341-.753.994.994 0 00-.789-.239 4.142 4.142 0 00-2.059.812.253.253 0 01-.237.044.25.25 0 01-.164-.176 2.491 2.491 0 00-2.41-1.877h-1.5a1 1 0 000 2h1.5a.5.5 0 01.5.5v5.5a4.949 4.949 0 01-2.693 4.4.249.249 0 01-.354-.151 5.084 5.084 0 00-4.953-3.845h-2.5A5.757 5.757 0 000 16.7a.75.75 0 00.75.75h10.5a6.488 6.488 0 003.617-.964.257.257 0 01.13-.036h8.254A.75.75 0 0024 15.7a4.526 4.526 0 00-4.75-4.248z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});