define("ember-svg-jar/inlined/read-email-at-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-email-at-alternate</title><path d=\"M12 .5A11.634 11.634 0 00.262 12 11.634 11.634 0 0012 23.5a11.836 11.836 0 006.624-2 1.25 1.25 0 10-1.393-2.076A9.34 9.34 0 0112 21a9.132 9.132 0 01-9.238-9A9.132 9.132 0 0112 3a9.132 9.132 0 019.238 9v.891a1.943 1.943 0 01-3.884 0V12A5.355 5.355 0 1012 17.261a5.376 5.376 0 003.861-1.634 4.438 4.438 0 007.877-2.736V12A11.634 11.634 0 0012 .5zm0 14.261A2.763 2.763 0 1114.854 12 2.812 2.812 0 0112 14.761z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});