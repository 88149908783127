define("ember-svg-jar/inlined/adventure-car-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>adventure-car-1</title><path d=\"M22.218 10.1H21.5a.248.248 0 01-.243-.2l-.751-3.369a3.732 3.732 0 00-3.928-2.871c-4.142.368-8.406 2.186-12.674 5.4l-.2.206-3.17 4.316a2.471 2.471 0 00.958 3.791.251.251 0 00.348-.182 3.478 3.478 0 016.869.191.25.25 0 00.248.217h8.1a.251.251 0 00.248-.217 3.485 3.485 0 013.45-3.033 3.428 3.428 0 011.564.384.251.251 0 00.328-.094l1.1-1.839a1.784 1.784 0 00-1.528-2.7zm-6.151-2.263a1.752 1.752 0 00-2.017 1.137l-1.55 4.457a.25.25 0 01-.236.168h-1.551A2.123 2.123 0 019.3 13.07a.051.051 0 01-.016-.039.054.054 0 01.019-.037l.416-.328a.252.252 0 01.351.042l.075.1a1 1 0 101.571-1.236l-.848-1.078-.954-1.217a1 1 0 10-1.57 1.236l.182.231a.252.252 0 01-.042.352l-.513.4a.248.248 0 01-.327-.015L6.293 10.19a.251.251 0 01.031-.39C11.209 6.445 15.3 5.712 16.7 5.653a.25.25 0 01.247.332l-.587 1.689a.251.251 0 01-.293.163zM18.19 12L17 13.186a.224.224 0 01-.369-.232l.516-1.483a.249.249 0 01.334-.148l.627.268a.252.252 0 01.147.183.249.249 0 01-.065.226zm.827-2.341a.249.249 0 01-.258.037l-.648-.277a.251.251 0 01-.138-.312l.358-1.028a.25.25 0 01.48.028l.29 1.3a.249.249 0 01-.084.25z\"/><circle cx=\"5.259\" cy=\"17.849\" r=\"2.499\"/><circle cx=\"20.751\" cy=\"17.849\" r=\"2.499\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});