define("ember-svg-jar/inlined/design-tool-ink", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-tool-ink</title><path d=\"M5.5 7h13a2 2 0 002-2V2a2 2 0 00-2-2h-13a2 2 0 00-2 2v3a2 2 0 002 2zm5.25-2a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25zm7.75-.25a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25zm-13-2.5A.25.25 0 015.75 2h2.5a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25zM18.774 10.3a.507.507 0 01-.274-.447V9a.5.5 0 00-.5-.5H6a.5.5 0 00-.5.5v.857a.5.5 0 01-.274.447C4.105 10.872 1.5 12.517 1.5 15.5v5A3.5 3.5 0 005 24h14a3.5 3.5 0 003.5-3.5v-5c0-2.983-2.6-4.628-3.726-5.2zM17.5 15.5a1 1 0 011 1v3a1 1 0 01-1 1h-11a1 1 0 01-1-1v-3a1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});