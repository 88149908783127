define("ember-svg-jar/inlined/shape-triangle-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shape-triangle-circle</title><path d=\"M24 14.48A7.485 7.485 0 0012.75 8a.251.251 0 01-.342-.091L9.484 2.837a1.551 1.551 0 00-2.732 0L.251 14.105a1.583 1.583 0 00-.058 1.612 1.582 1.582 0 001.425.754h7.471a.25.25 0 01.239.177A7.5 7.5 0 0024 14.48zm-10.329-.38a.25.25 0 01-.217.375h-2.2a.25.25 0 01-.249-.263 5.43 5.43 0 01.806-2.593.248.248 0 01.216-.119.25.25 0 01.213.125zM7.9 4.847a.25.25 0 01.433 0l2.551 4.421a.251.251 0 01-.029.29 7.461 7.461 0 00-1.852 4.671.25.25 0 01-.25.242H2.782a.25.25 0 01-.217-.375zm8.6 15.133a5.5 5.5 0 01-4.967-3.152.25.25 0 01.226-.357h2.859a1.518 1.518 0 001.367-2.366l-2.33-4.038a.25.25 0 01.091-.341A5.5 5.5 0 1116.5 19.98z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});