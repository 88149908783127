define("ember-svg-jar/inlined/police-man-4.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-man-4</title><path d=\"M21.026 18.27l-4.886-1.235a.5.5 0 00-.409.075 8.146 8.146 0 01-2.6 1.228.5.5 0 00-.377.485V23.5a.5.5 0 00.5.5H23.5a.5.5 0 00.5-.5v-1a4.531 4.531 0 00-2.974-4.23zM18.5 20v.43a2 2 0 01-.891 1.664L17 22.5l-.609-.406a2 2 0 01-.891-1.664V20a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zM11.25 18.832a.5.5 0 00-.38-.485 8.111 8.111 0 01-2.635-1.233.5.5 0 00-.41-.074l-4.9 1.247A4.519 4.519 0 000 22.5v1a.5.5 0 00.5.5h10.25a.5.5 0 00.5-.5zM17.672 12.258a.251.251 0 01.142-.187 2.728 2.728 0 001.435-2.431 1.969 1.969 0 00-.691-1.635c-.416.058-.832.123-1.25.17a.747.747 0 00.337.995.625.625 0 01.105.44 1.281 1.281 0 01-.557 1.1 1.752 1.752 0 00-1 1.306C15.816 14.26 13.029 15.75 12 15.75s-3.816-1.49-4.191-3.738a1.752 1.752 0 00-1-1.306 1.281 1.281 0 01-.557-1.1.625.625 0 01.105-.44.747.747 0 00.337-.995c-.418-.047-.833-.112-1.25-.17a1.969 1.969 0 00-.693 1.639 2.728 2.728 0 001.435 2.431.251.251 0 01.142.188c.509 3.047 4.011 4.991 5.672 4.991s5.163-1.944 5.672-4.992z\"/><path d=\"M12.741 12.328a1.8 1.8 0 01-.81.178 1.724 1.724 0 01-.82-.18.75.75 0 00-.711 1.321 3.316 3.316 0 001.529.359h.006a3.166 3.166 0 001.525-.36.75.75 0 10-.715-1.318zM10.991 10.027a.75.75 0 00-.054-1.059 2.1 2.1 0 00-2.636 0 .751.751 0 001.006 1.114.63.63 0 01.626 0 .749.749 0 001.058-.055zM12.866 8.968a.751.751 0 001.006 1.114.63.63 0 01.626 0 .75.75 0 101-1.114 2.1 2.1 0 00-2.632 0zM12 7.59a46.648 46.648 0 0010.108-1.1.5.5 0 00.1-.044.492.492 0 00.28-.552.5.5 0 00-.6-.38 45.245 45.245 0 01-3.592.629l-.441-3.129A3.516 3.516 0 0014.394 0H9.606a3.516 3.516 0 00-3.465 3.012L5.7 6.141a45.245 45.245 0 01-3.592-.629.494.494 0 00-.316.932.5.5 0 00.1.044A46.648 46.648 0 0012 7.59zm-2-4.66V2.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v.43a2.5 2.5 0 01-1.113 2.08l-.61.406a.5.5 0 01-.554 0l-.61-.406A2.5 2.5 0 0110 2.93z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});