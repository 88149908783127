define("ember-svg-jar/inlined/single-neutral-id-card-double.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-id-card-double</title><path d=\"M24 7.5a2 2 0 00-2-2h-1.75a.25.25 0 01-.25-.25V3.5a1 1 0 00-1-1H2a2 2 0 00-2 2v12a1 1 0 001 1h1.75a.25.25 0 01.25.25v1.75a2 2 0 002 2h3a1 1 0 001-1 .75.75 0 011.5 0 1 1 0 001 1h4a1 1 0 001-1 .75.75 0 011.5 0 1 1 0 001 1h3a2 2 0 002-2zM22 19a.5.5 0 01-.5.5h-1.367a.5.5 0 01-.443-.269 2.75 2.75 0 00-4.88 0 .5.5 0 01-.443.269h-1.734a.5.5 0 01-.443-.269 2.75 2.75 0 00-4.88 0 .5.5 0 01-.443.269H5.5A.5.5 0 015 19V8a.5.5 0 01.5-.5h16a.5.5 0 01.5.5z\"/><path d=\"M14.75 10.75a.75.75 0 00.75.75H20a.75.75 0 000-1.5h-4.5a.75.75 0 00-.75.75zM18 13h-2.5a.75.75 0 000 1.5H18a.75.75 0 000-1.5z\"/><circle cx=\"9.67\" cy=\"10.5\" r=\"2\"/><path d=\"M9.67 13.5a3.6 3.6 0 00-3.295 2.316.5.5 0 00.471.684h5.647a.5.5 0 00.471-.684A3.6 3.6 0 009.67 13.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});