define("ember-svg-jar/inlined/arrow-rectangle-up-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-rectangle-up-2</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-4 13.161v3.2a.5.5 0 01-.839.368l-5.495-5.07a.251.251 0 00-.339 0l-5.484 5.066a.5.5 0 01-.839-.367v-3.151a.5.5 0 01.146-.353l6-6a.5.5 0 01.706 0l6 5.955a.5.5 0 01.144.352z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});