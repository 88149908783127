define("ember-svg-jar/inlined/single-neutral-hierachy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-hierachy</title><path d=\"M19.054 13.25a.252.252 0 01.223.136 2.5 2.5 0 100-2.272.252.252 0 01-.223.136h-2.3A.25.25 0 0116.5 11V5.25a1 1 0 011-1h1.554a.252.252 0 01.223.136 2.5 2.5 0 100-2.272.252.252 0 01-.223.136H17.5a3 3 0 00-3 3V11a.25.25 0 01-.25.25H13a1 1 0 000 2h1.25a.25.25 0 01.25.25v4.75a3 3 0 003 3h1.554a.252.252 0 01.223.136 2.5 2.5 0 100-2.272.252.252 0 01-.223.136H17.5a1 1 0 01-1-1V13.5a.25.25 0 01.25-.25zM5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M19.054 13.25a.252.252 0 01.223.136 2.5 2.5 0 100-2.272.252.252 0 01-.223.136h-2.3A.25.25 0 0116.5 11V5.25a1 1 0 011-1h1.554a.252.252 0 01.223.136 2.5 2.5 0 100-2.272.252.252 0 01-.223.136H17.5a3 3 0 00-3 3V11a.25.25 0 01-.25.25H13a1 1 0 000 2h1.25a.25.25 0 01.25.25v4.75a3 3 0 003 3h1.554a.252.252 0 01.223.136 2.5 2.5 0 100-2.272.252.252 0 01-.223.136H17.5a1 1 0 01-1-1V13.5a.25.25 0 01.25-.25zM5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});