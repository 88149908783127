define("ember-svg-jar/inlined/toys-beach-ball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toys-beach-ball</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM3.145 14.9a.5.5 0 01-.79-.261 10.005 10.005 0 01.381-6.408.5.5 0 01.487-.31 58.026 58.026 0 015.962.532.5.5 0 01.415.447 4.284 4.284 0 00.246 1.064.5.5 0 01-.236.616 46.235 46.235 0 00-6.465 4.32zm8.435-6.43a2.31 2.31 0 112.31 2.31 2.312 2.312 0 01-2.31-2.31zm2.369 13.339a.5.5 0 01-.57-.651 31.675 31.675 0 001.391-8.1.5.5 0 01.356-.455 4.361 4.361 0 00.487-.179.5.5 0 01.571.123 23.325 23.325 0 013.7 5.168.5.5 0 01-.064.52 10.015 10.015 0 01-5.871 3.574zm4.34-14.96a.5.5 0 01-.528-.274 4.324 4.324 0 00-2.932-2.311.5.5 0 01-.384-.407 10.2 10.2 0 00-.283-1.236.247.247 0 01.059-.244.25.25 0 01.242-.07 10.028 10.028 0 015.72 3.951.25.25 0 01-.183.393c-.631.041-1.219.118-1.711.198z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});