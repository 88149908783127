define("ember-svg-jar/inlined/meerkat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meerkat</title><path d=\"M11.562 10.528c-.608-3.325-.976-4.232-.526-5.049a.986.986 0 01.673-.494C14.128 4.38 13.6 1.059 13.581.918a.5.5 0 00-.412-.411A38.483 38.483 0 008.587 0a2.349 2.349 0 00-2.109 1.456A1.477 1.477 0 005.086 3 1.383 1.383 0 006.1 4.521c.065 1.4.793 1-.983 6.321-1.024 3.072-1.106 4.929-.773 6.231-.832 4.683-3.128 4.878-3.276 4.885a1 1 0 000 2h.008c.231 0 3.133-.107 4.651-4.333.174.32 1.275.687.649 3.12A1 1 0 007.337 24h1a1 1 0 00.169-1.985 5.969 5.969 0 00-.1-1.707 6.519 6.519 0 001.273-.737 5.007 5.007 0 01.2 3.148A1 1 0 0010.837 24h1a1 1 0 00.179-1.984 5.98 5.98 0 00-.926-3.957 6.339 6.339 0 00.938-3.428 16.571 16.571 0 00-.134-1.919c.662.363.943 1.169.943 2.288a1 1 0 002 0 4.552 4.552 0 00-1.391-3.637 3.691 3.691 0 00-1.884-.835zM9.586 3.5a1 1 0 111-1 1 1 0 01-1 1zM22.926 17a1 1 0 001-1 4.144 4.144 0 00-2.649-4.2c-.065-.948-.419-1.733-.08-2.35a.662.662 0 01.457-.335c1.59-.4 1.72-2.265 1.546-3.306a.5.5 0 00-.411-.412 30.447 30.447 0 00-3.6-.4 1.965 1.965 0 00-1.593.9c-1.179-.2-2 .3-2 1.3a1.237 1.237 0 001.541 1.322c-.1.791.758.458-.765 5.031-.48 1.442-1.334 4.671.021 6.15.352.364.367 1.932.145 3.1a1 1 0 00.973 1.2h1.174a1 1 0 100-2h-.036a8.417 8.417 0 00-.046-1.836 2.484 2.484 0 001.333-.789 3.223 3.223 0 01.241 1.165V23a1 1 0 001 1h1.174a1 1 0 000-2h-.174v-1.46a5.984 5.984 0 00-1.159-3.34 13.252 13.252 0 00.339-3 2.6 2.6 0 01.569 1.8 1 1 0 001 1zm-3.25-9.01a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});