define("ember-svg-jar/inlined/kitchenware-ladle-spaghetti", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchenware-ladle-spaghetti</title><path d=\"M21.5 17.25a1.35 1.35 0 00-2.7 0 1.35 1.35 0 01-2.7 0 1.35 1.35 0 00-2.7 0 1.349 1.349 0 01-1.35 1.35 1.609 1.609 0 01-1.55-1.776V6.75A6.758 6.758 0 003.75 0a1.25 1.25 0 000 2.5A4.255 4.255 0 018 6.75v10.5a6.75 6.75 0 0013.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});