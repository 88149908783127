define("ember-svg-jar/inlined/dating-rose-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dating-rose-1</title><path d=\"M14.953 13.413a.251.251 0 00.3.049 6.167 6.167 0 003.422-5.428V3.48C18.671 1.494 15.8 0 12 0S5.329 1.494 5.329 3.48v4.554a6.347 6.347 0 005.271 6.077.5.5 0 01.4.49V23a1 1 0 002 0v-1a.5.5 0 01.5-.5h1a4 4 0 004-4V16a.5.5 0 00-.5-.5h-1.5a3.992 3.992 0 00-3.059 1.423.25.25 0 01-.441-.162v-3.186a.994.994 0 01.219-.623A5.069 5.069 0 0014.25 9.5a4.747 4.747 0 00-1.974-3.852.987.987 0 00-.562-.188c-2.992-.068-4.885-1.2-4.885-1.98 0-.808 2.014-1.98 5.171-1.98.747 0 1.527-.027 1.527.732a.73.73 0 01-.729.729H11.74a.8.8 0 01-.573-.237.75.75 0 00-1.06 1.062 2.3 2.3 0 001.633.675h1.06a2.234 2.234 0 002.23-2.2.25.25 0 01.334-.232c1.152.413 1.809 1 1.809 1.456 0 .566-.988 1.312-2.663 1.708a.25.25 0 00-.138.4A6.215 6.215 0 0115.75 9.5a6.6 6.6 0 01-.834 3.615.249.249 0 00.037.298z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});