define("ember-svg-jar/inlined/image-file-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-upload</title><path d=\"M6.5 11a6.5 6.5 0 106.5 6.5A6.508 6.508 0 006.5 11zm2.45 6.217a.5.5 0 01-.45.283h-.876a.125.125 0 00-.124.121V20.5a1 1 0 01-2 0v-2.875a.127.127 0 00-.124-.125H4.5a.5.5 0 01-.391-.812l2-2.5a.517.517 0 01.782 0l2 2.5a.5.5 0 01.059.529zM13.187 7.03A1.493 1.493 0 1011.7 5.538a1.493 1.493 0 001.487 1.492z\"/><path d=\"M23.415 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.275a.248.248 0 00.242.25c.366.007 1.038.034 1.485.082A.245.245 0 008 9.359V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.244.244 0 00-.241.21 7.935 7.935 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.414A2 2 0 0023.415 3z\"/><path d=\"M20.477 12.9a.186.186 0 00-.006-.189l-3.236-5.175a.373.373 0 00-.633 0l-1.81 2.9a.187.187 0 01-.317 0L13.831 9.4a.375.375 0 00-.633 0l-1.246 1.993a.245.245 0 00.042.309 8.021 8.021 0 011.044 1.198.24.24 0 00.2.1h7.079a.185.185 0 00.16-.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});