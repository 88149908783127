define("ember-svg-jar/inlined/discount-50", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>discount-50</title><path d=\"M12.01 10.254a.749.749 0 00-.748.748V13a.748.748 0 001.5 0v-2a.749.749 0 00-.752-.746z\"/><path d=\"M24 12a4.357 4.357 0 00-2.272-3.86.251.251 0 01-.12-.29 4.415 4.415 0 00-5.458-5.459.25.25 0 01-.29-.12 4.415 4.415 0 00-7.72 0 .249.249 0 01-.289.119 4.416 4.416 0 00-5.459 5.46.251.251 0 01-.12.29 4.415 4.415 0 000 7.72.251.251 0 01.12.29 4.415 4.415 0 005.459 5.458.249.249 0 01.289.12 4.415 4.415 0 007.72 0 .251.251 0 01.29-.12 4.414 4.414 0 005.458-5.458.25.25 0 01.12-.29A4.357 4.357 0 0024 12zm-8.041 3.272a.748.748 0 01-.589-1.207l3.491-4.489a.748.748 0 111.18.919l-3.491 4.488a.745.745 0 01-.591.289zm0-5.611a.873.873 0 11-.873.873.873.873 0 01.874-.873zm3.491 3.491a.873.873 0 11-.873.873.873.873 0 01.874-.873zm-5.236-.124a2.245 2.245 0 01-4.489 0v-2a2.245 2.245 0 014.489 0zm-7.162-1.743c.706.066 2.175.2 2.175 1.992a2 2 0 01-1.995 2H5.487a.748.748 0 110-1.5h1.745a.5.5 0 00.5-.5c0-.394 0-.426-.817-.5-.706-.065-2.175-.2-2.175-1.992V9.537a.748.748 0 01.748-.748h2.991a.748.748 0 010 1.5H6.485a.25.25 0 00-.25.25v.248c0 .39 0 .423.817.498z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});