define("ember-svg-jar/inlined/police-rotating-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-rotating-light</title><path d=\"M23 11.25h-2.5a1 1 0 000 2H23a1 1 0 000-2zM19 7.25a1 1 0 00.707-.293l2-2a1 1 0 10-1.414-1.414l-2 2A1 1 0 0019 7.25zM4.5 11.75a1 1 0 00-1-1H1a1 1 0 000 2h2.5a1 1 0 001-1zM4.293 6.957a1 1 0 001.414-1.414l-2-2a1 1 0 00-1.414 1.414zM12 5.25a1 1 0 001-1v-3a1 1 0 00-2 0v3a1 1 0 001 1zM5.125 18.709a.5.5 0 00.5.541h5.125a.5.5 0 00.5-.5v-1.487a.25.25 0 00-.139-.224 2 2 0 111.778 0 .25.25 0 00-.139.224v1.487a.5.5 0 00.5.5h5.127a.5.5 0 00.5-.541l-.711-8.686a2.49 2.49 0 00-2.49-2.273h-7.35a2.49 2.49 0 00-2.49 2.278zM19 20.75H5a2 2 0 00-2 2v.5a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-.5a2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});