define("ember-svg-jar/inlined/smiley-in-love-alternate-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-in-love-alternate-1</title><path d=\"M23.55 4.359a4.279 4.279 0 00-3.135-2.3 4.233 4.233 0 00-1.527.03.248.248 0 01-.189-.037 11.972 11.972 0 00-13.4 0 .255.255 0 01-.19.038 4.228 4.228 0 00-1.526-.031A4.275 4.275 0 00.506 8.3a.247.247 0 01.019.192 12 12 0 1022.949-.007.252.252 0 01.019-.192 4.265 4.265 0 00.057-3.934zm-21.309.9a2.268 2.268 0 013.633-.59l.45.449a.25.25 0 00.354 0l.449-.449A2.269 2.269 0 0111 6.19v.162a2.268 2.268 0 01-.663 1.522l-3.656 3.813a.25.25 0 01-.18.077.254.254 0 01-.181-.077L2.665 7.874a2.269 2.269 0 01-.424-2.619zm4.818 10.45a.441.441 0 01.38-.22h9.123a.439.439 0 01.38.658 5.706 5.706 0 01-9.882 0 .439.439 0 01-.001-.438zm14.277-7.835l-3.655 3.813a.25.25 0 01-.18.077.254.254 0 01-.181-.077l-3.655-3.813a2.269 2.269 0 013.209-3.209l.45.449a.25.25 0 00.354 0l.449-.449a2.269 2.269 0 013.209 3.209z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});