define("ember-svg-jar/inlined/single-woman-actions-text.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-text</title><path d=\"M9.25 17.5a.25.25 0 00.25-.25V12.5a3 3 0 013-3h1.685A6.179 6.179 0 0015 9.081a.388.388 0 00.084-.593c-.494-.537-.835-1.185-.981-3.317C13.838 1.346 11.236 0 9 0S4.161 1.346 3.9 5.171c-.145 2.132-.487 2.78-.981 3.317A.388.388 0 003 9.081a6.348 6.348 0 003.782.9.249.249 0 01.253.25v.462a.778.778 0 01-.512.73c-2.9 1.065-5.212 1.825-5.764 2.922A7.822 7.822 0 000 17.076a.386.386 0 00.1.3.4.4 0 00.29.126z\"/><path d=\"M24 13a2 2 0 00-2-2h-9a2 2 0 00-2 2v9a2 2 0 002 2h9a2 2 0 002-2zm-11 .25a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v8.5a.249.249 0 01-.25.25h-8.5a.25.25 0 01-.25-.25z\"/><path d=\"M20 15h-4.75a.75.75 0 000 1.5H20a.75.75 0 000-1.5zM15.25 19.5h2.25a.75.75 0 000-1.5h-2.25a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});