define("ember-svg-jar/inlined/office-stapler-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-stapler-1</title><path d=\"M8.26 16.2a.249.249 0 00.414.1l1.513-1.513a.251.251 0 01.354 0l1.6 1.6a2 2 0 002.828 0l7.475-7.475a2 2 0 000-2.828l-1.86-1.86a.253.253 0 01-.071-.209 1.5 1.5 0 00-.724-1.483 5.5 5.5 0 00-6.685.849l-8.98 8.982a.25.25 0 00.138.424A5.016 5.016 0 018.26 16.2zm11.169-9.951a.25.25 0 01.353 0l1.073 1.073a.252.252 0 010 .354L13.733 14.8a.25.25 0 01-.353 0l-1.072-1.072a.251.251 0 010-.354z\"/><path d=\"M21 18.228H7.25a.249.249 0 01-.25-.25v-.25a3.5 3.5 0 00-7 0v3a1.5 1.5 0 001.5 1.5H23a1 1 0 001-1 3 3 0 00-3-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});