define("ember-svg-jar/inlined/style-one-pin-book", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-book</title><path d=\"M12 0a9.5 9.5 0 00-4 18.119 2.5 2.5 0 011.218 1.222l1.873 4.076a1 1 0 001.817 0l1.876-4.08A2.5 2.5 0 0116 18.118 9.5 9.5 0 0012 0zm-.5 14.283a.5.5 0 01-.5.5.492.492 0 01-.224-.053l-4-2a.5.5 0 01-.276-.447v-6a.5.5 0 01.724-.447l4 2a.5.5 0 01.276.447zm.5-7.5a.492.492 0 01-.224-.053L8.209 4.947a.5.5 0 01.448-.894L12 5.725l3.343-1.672a.5.5 0 11.448.894L12.224 6.73a.492.492 0 01-.224.053zm5.5 5.5a.5.5 0 01-.276.447l-4 2a.492.492 0 01-.224.053.5.5 0 01-.5-.5v-6a.5.5 0 01.276-.447l4-2a.5.5 0 01.724.447z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});