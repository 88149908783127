define("ember-svg-jar/inlined/multiple-actions-cart.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-cart</title><path d=\"M18.425 10.577c-.455-.907-1.905-1.44-3.912-2.177l-.485-.172a1.8 1.8 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 0012.043 0 2.991 2.991 0 0010.7.314a.25.25 0 00-.131.16.248.248 0 00.035.2 5 5 0 01.833 2.808 6.259 6.259 0 01-.922 3.624.253.253 0 00-.028.216.247.247 0 00.15.156c1.918.716 3.3 1.328 3.9 2.537a7.438 7.438 0 01.652 2.257.25.25 0 00.248.215h3.231a.251.251 0 00.247-.292 5.941 5.941 0 00-.49-1.618z\"/><path d=\"M11.417 13.324a2.007 2.007 0 011.626-.834h.631a.251.251 0 00.247-.292 5.941 5.941 0 00-.5-1.621C12.97 9.67 11.52 9.137 9.513 8.4l-.485-.172a1.8 1.8 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 007.043 0 3.219 3.219 0 003.9 3.485a4.605 4.605 0 001.19 3.538 1.877 1.877 0 010 1.185l-.515.19c-2.01.739-3.46 1.272-3.914 2.179A7.542 7.542 0 00.043 13.5a.5.5 0 00.5.5H10.93a.25.25 0 00.238-.172 1.809 1.809 0 01.249-.504zM15.811 23.989a1.423 1.423 0 10-1.394-1.136 1.425 1.425 0 001.394 1.136zM20.937 22.577A1.423 1.423 0 1019.514 24a1.424 1.424 0 001.423-1.423zM23.919 11.737a.75.75 0 00-1.424-.474l-.852 2.555a.25.25 0 01-.237.171h-8.364a.5.5 0 00-.474.659l1.847 5.5a.5.5 0 00.473.341h6.231a.5.5 0 00.481-.365z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});