define("ember-svg-jar/inlined/clamp-press", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>clamp-press</title><circle cx=\"21.491\" cy=\"3.25\" r=\"2.5\"/><path d=\"M22.991 16.75h-.25a.25.25 0 01-.25-.25V7.213a.5.5 0 00-.572-.5 2.872 2.872 0 01-.855 0 .5.5 0 00-.573.5V16.5a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25V16a.25.25 0 01.25-.25h1.233a.5.5 0 00.5-.536 7.494 7.494 0 00-6.254-6.855.25.25 0 01-.209-.247V7.25a.5.5 0 00-.5-.5h-1.52a.5.5 0 00-.5.5v9a.5.5 0 00.5.5h1.518a.5.5 0 00.5-.5V16a.25.25 0 01.25-.25h.482a.25.25 0 01.25.25 4.25 4.25 0 01-8.5 0 .25.25 0 01.25-.25h.518a.25.25 0 01.25.25v.25a.5.5 0 00.5.5h1.482a.5.5 0 00.5-.5v-9a.5.5 0 00-.5-.5H7.009a.5.5 0 00-.5.5v.855a.25.25 0 01-.21.247A7.5 7.5 0 00.01 15.214a.5.5 0 00.5.536h1.231a.25.25 0 01.25.25v5.75a1.5 1.5 0 001.5 1.5h12.5a1.5 1.5 0 001.5-1.5V19a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25v1.25a1 1 0 002 0V19a.25.25 0 01.25-.25h.25a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});