define("ember-svg-jar/inlined/love-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-shield</title><path d=\"M24 1.946A1.958 1.958 0 0022.045 0H1.96A1.96 1.96 0 00.012 1.958L0 9.313A15.143 15.143 0 0011.861 23.98a.976.976 0 00.4 0A15.142 15.142 0 0023.987 9.21zM18.4 11.6l-5.44 5.676a1.22 1.22 0 01-1.762 0L5.752 11.6a4.03 4.03 0 012.211-6.82 3.979 3.979 0 013.5 1.128.876.876 0 001.242 0 4.029 4.029 0 016.453 1.048A4.023 4.023 0 0118.4 11.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});