define("ember-svg-jar/inlined/browser-page-mail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>browser-page-mail</title><path d=\"M24 4.5a3 3 0 00-3-3H3a3 3 0 00-3 3v15a3 3 0 003 3h18a3 3 0 003-3zM3 5a1 1 0 111 1 1 1 0 01-1-1zm3 0a1 1 0 111 1 1 1 0 01-1-1zm3 0a1 1 0 111 1 1 1 0 01-1-1zm13 14.5a1 1 0 01-1 1H3a1 1 0 01-1-1V9a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><rect x=\"3.5\" y=\"10.5\" width=\"6\" height=\"8\" rx=\".5\" ry=\".5\"/><path d=\"M12.5 15.25h7a.75.75 0 000-1.5h-7a.75.75 0 000 1.5zM12.5 12.25h7a.75.75 0 000-1.5h-7a.75.75 0 000 1.5zM12.5 18.25h7a.75.75 0 000-1.5h-7a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});