define("ember-svg-jar/inlined/office-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-table</title><path d=\"M22.5 3.749h-7a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h8a.5.5 0 00.5-.5v-5.5a1.5 1.5 0 00-1.5-1.5zm-3 4.14a1 1 0 111-1 1 1 0 01-1 1zM23.5 12.749h-8a.5.5 0 00-.5.5v5.5a1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5v-5.5a.5.5 0 00-.5-.5zm-4 3.5a1 1 0 111-1 1 1 0 01-1 1zM13 3.75H1.5A1.5 1.5 0 000 5.25v13.5a1.5 1.5 0 003 0v-9.5a.5.5 0 01.5-.5H13a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});