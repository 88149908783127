define("ember-svg-jar/inlined/army-symbol-support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-symbol-support</title><path d=\"M24 5.5a2 2 0 00-2-2h-5a1 1 0 000 2h4.5a.5.5 0 01.5.5v12a.5.5 0 01-.5.5H17a1 1 0 000 2h5a2 2 0 002-2zM7.5 18.5h-5A.5.5 0 012 18V6a.5.5 0 01.5-.5H7a1 1 0 000-2H2a2 2 0 00-2 2v13a2 2 0 002 2h5.5a1 1 0 000-2z\"/><path d=\"M18.25 12A6.25 6.25 0 1012 18.25 6.259 6.259 0 0018.25 12zM7.385 13.056a.25.25 0 01.244-.306h1.957a.25.25 0 01.177.427l-1.385 1.385a.253.253 0 01-.205.072.249.249 0 01-.184-.116 4.688 4.688 0 01-.604-1.462zm.6-3.574a.249.249 0 01.184-.116.253.253 0 01.205.072l1.385 1.385a.25.25 0 01-.177.427H7.629a.25.25 0 01-.244-.306 4.688 4.688 0 01.604-1.462zm8.626 1.462a.25.25 0 01-.244.306h-1.953a.25.25 0 01-.177-.427l1.385-1.385a.253.253 0 01.205-.072.249.249 0 01.184.116 4.688 4.688 0 01.604 1.462zm-3.434-1.181a.25.25 0 01-.427-.177V7.629a.25.25 0 01.094-.195.247.247 0 01.211-.049 4.684 4.684 0 011.463.6.249.249 0 01.116.184.253.253 0 01-.072.205zm-1.927-.177a.25.25 0 01-.427.177L9.438 8.378a.253.253 0 01-.072-.205.249.249 0 01.116-.184 4.688 4.688 0 011.462-.6.25.25 0 01.306.244zm-.427 4.651a.25.25 0 01.427.177v1.957a.25.25 0 01-.094.2.247.247 0 01-.211.049 4.684 4.684 0 01-1.463-.6.249.249 0 01-.116-.184.253.253 0 01.072-.205zm1.927.177a.25.25 0 01.427-.177l1.385 1.386a.249.249 0 01-.044.388 4.688 4.688 0 01-1.462.6.25.25 0 01-.306-.244zm1.487-1.237a.25.25 0 01.177-.427h1.957a.25.25 0 01.244.306 4.688 4.688 0 01-.6 1.462.249.249 0 01-.388.044z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});