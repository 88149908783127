define("ember-svg-jar/inlined/multiple-actions-edit-1.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-edit-1</title><path d=\"M17.784 11.694a.252.252 0 00.16-.16 1.936 1.936 0 01.388-.685.251.251 0 00.031-.288c-.486-.871-1.54-1.3-3.892-2.163l-.485-.172a1.8 1.8 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 0012 0a2.991 2.991 0 00-1.342.314.25.25 0 00-.131.16.248.248 0 00.035.2 5 5 0 01.838 2.811 6.259 6.259 0 01-.922 3.624.253.253 0 00-.028.216.247.247 0 00.15.156c1.918.716 3.3 1.328 3.9 2.537a8.534 8.534 0 01.75 3.3.25.25 0 00.426.172l1.328-1.326a1.954 1.954 0 01.78-.47z\"/><path d=\"M14 13.5a7.542 7.542 0 00-.618-2.923c-.455-.907-1.9-1.44-3.912-2.179l-.485-.172a1.8 1.8 0 01-.055-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 007 0a3.219 3.219 0 00-3.145 3.485 4.605 4.605 0 001.193 3.538 1.877 1.877 0 010 1.185L4.53 8.4c-2.007.737-3.457 1.27-3.911 2.177A7.542 7.542 0 000 13.5a.5.5 0 00.5.5h13a.5.5 0 00.5-.5zM15.086 22.74a.5.5 0 00-.131-.479l-2.215-2.215a.5.5 0 00-.834.216l-.886 3.1a.5.5 0 00.48.638.459.459 0 00.137-.02l3.1-.885a.5.5 0 00.349-.355zM21.772 16.936a.5.5 0 000-.707l-3-3a.5.5 0 00-.707 0l-5.011 5.01a.5.5 0 000 .707l3 3a.5.5 0 00.707 0zM19.48 11.813a.5.5 0 000 .708l3 3a.536.536 0 00.377.124.584.584 0 00.377-.17 2.624 2.624 0 000-3.707 2.655 2.655 0 00-3.754.045z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});