define("ember-svg-jar/inlined/single-man-actions-share-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-share-2</title><path d=\"M7.044 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.743a.5.5 0 01.741-.3A6.958 6.958 0 008.5 5a6.35 6.35 0 00.929-.07.5.5 0 01.571.564A3 3 0 014.044 5a2.8 2.8 0 01.1-.743zM12.785 14.112a.5.5 0 00.28-.8A7.507 7.507 0 007.25 10.5a7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h8.99a.5.5 0 00.44-.262 4.251 4.251 0 012.811-2.126zM11 18.25a2.741 2.741 0 004.637 1.989.253.253 0 01.266-.051l2.448.98a.251.251 0 01.158.224 2.42 2.42 0 00.183.867 2.761 2.761 0 10.671-3 .253.253 0 01-.266.051l-2.445-.978a.253.253 0 01-.157-.219c0-.041-.007-.1-.019-.178a.22.22 0 01.1-.219L19.437 16a.251.251 0 01.271.009 2.708 2.708 0 001.542.487 2.75 2.75 0 10-2.75-2.75 2.4 2.4 0 00.021.295.247.247 0 01-.119.247L15.563 16a.249.249 0 01-.27-.009 2.714 2.714 0 00-1.543-.491A2.752 2.752 0 0011 18.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});