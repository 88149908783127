define("ember-svg-jar/inlined/navigation-down-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-down-3</title><path d=\"M24 3a3 3 0 00-3-3h-7.25a.25.25 0 00-.25.25v9a.25.25 0 00.25.25h2.75a1 1 0 01.665 1.747l-4.5 4a1 1 0 01-1.33 0l-4.5-4A1 1 0 017.5 9.5h2.75a.25.25 0 00.25-.25v-9a.25.25 0 00-.25-.25H3a3 3 0 00-3 3v9a12 12 0 0024 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});