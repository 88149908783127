define("ember-svg-jar/inlined/armadillo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>armadillo</title><path d=\"M5.513 11.866a7.189 7.189 0 01.294-2.038A3.282 3.282 0 003 7.839a.8.8 0 00-.7.376c-.308.5-.055 1.462.286 2.32C1.1 11.208 0 12.424 0 13.435c0 .378.2.927 1.134 1.4a8.69 8.69 0 003.712.732 10.808 10.808 0 001.654-.121 5.5 5.5 0 01-.987-3.58zm-2.013.968a.75.75 0 11-.75-.75.75.75 0 01.75.75zM23.225 14.26a2.317 2.317 0 01-1.007-.448 5.626 5.626 0 00.269-1.946A6.094 6.094 0 0020.572 7.5a5.2 5.2 0 00-1.837 3.383.75.75 0 11-1.47-.295 6.86 6.86 0 012.081-4.056A8.483 8.483 0 0015.5 5.266v3.105a.75.75 0 11-1.5 0V5.266a8.483 8.483 0 00-3.846 1.265 6.86 6.86 0 012.081 4.056.75.75 0 11-1.47.295A5.229 5.229 0 008.93 7.5a6.093 6.093 0 00-1.917 4.368c0 2.5.772 3.388 3.193 3.7a.5.5 0 01.436.495v1.671a1 1 0 002 0v-1.508a.5.5 0 01.506-.5c.5.006 1.029.008 1.6.008.928 0 1.753 0 2.485-.026a.5.5 0 01.515.5v1.526a1 1 0 002 0v-1.828a.5.5 0 01.385-.487 4.763 4.763 0 00.663-.2A4.07 4.07 0 0023 16.234a1 1 0 00.224-1.974z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});