define("ember-svg-jar/inlined/fruit-pear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fruit-pear</title><path d=\"M18.122 13.007a4.985 4.985 0 01-1.579-2.194c-.524-2.1-1.107-4.444-3.216-5.12a.5.5 0 01-.345-.423A3.105 3.105 0 009.9 2.5H8a1 1 0 000 2h1.9a1.1 1.1 0 011.077.86.249.249 0 01-.176.295c-2.212.624-2.807 3.015-3.341 5.16a4.98 4.98 0 01-1.578 2.192 6.715 6.715 0 00-2.527 5.146C3.351 21.869 6.5 24 12 24s8.649-2.131 8.649-5.847a6.715 6.715 0 00-2.527-5.146zM19.507 1.223A1.016 1.016 0 0018.433 0a5.012 5.012 0 00-4.516 3.976A1.016 1.016 0 0014.99 5.2a5.015 5.015 0 004.517-3.977z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});