define("ember-svg-jar/inlined/send-email-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>send-email-1</title><path d=\"M23.612.225a1.045 1.045 0 00-1.138-.1L.827 11.646a1.547 1.547 0 00.125 2.8l3.254 1.38a.719.719 0 00.043.245l2.5 6.746A1.817 1.817 0 008.463 24a1.846 1.846 0 001.219-.474 1188 1188 0 003.4-3.754.249.249 0 01.283-.062l3.157 1.339a1.591 1.591 0 001.3-.027 1.563 1.563 0 00.835-.983L23.961 1.3a1.014 1.014 0 00-.349-1.075zM5.838 16.046l9.692-8.063a.226.226 0 01.337.017.219.219 0 01.019.332l-7.238 8.855a.728.728 0 00-.157.351l-.633 3.95z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});