define("ember-svg-jar/inlined/computer-ram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>computer-ram</title><path d=\"M23.667 7.4a.5.5 0 000-.707L17.3.334a.5.5 0 00-.707 0l-1.04 1.04a.25.25 0 000 .353l1.238 1.238a.5.5 0 11-.707.707l-1.236-1.237a.249.249 0 00-.353 0l-.707.707a.249.249 0 000 .353l1.237 1.237a.5.5 0 01-.707.707L13.081 4.2a.252.252 0 00-.354 0l-.883.884L13.257 6.5a.5.5 0 11-.707.707L11.313 5.97a.25.25 0 00-.353 0L9.525 7.405a.5.5 0 000 .708l.353.352A1 1 0 018.465 9.88l-.354-.354a.5.5 0 00-.707 0L6.01 10.92a.251.251 0 000 .354l1.237 1.236a.5.5 0 01-.707.707L5.3 11.981a.25.25 0 00-.354 0l-.707.707a.25.25 0 000 .354l1.237 1.237a.5.5 0 11-.707.707l-1.234-1.237a.251.251 0 00-.354 0l-.707.707a.25.25 0 000 .353l1.238 1.238a.5.5 0 11-.707.707l-1.238-1.238a.25.25 0 00-.353 0L.333 16.6a.5.5 0 000 .707L6.7 23.668a.5.5 0 00.707 0zm-6.01-1.768a1 1 0 011.414 0l.707.707a1 1 0 010 1.415L17.656 9.88a1 1 0 01-1.414 0l-.707-.707a1 1 0 010-1.415zm-4.95 4.951a1 1 0 011.414 0l.707.707a1 1 0 010 1.414l-2.121 2.121a1 1 0 01-1.414 0l-.707-.707a1 1 0 010-1.414zm-7.071 7.075l2.121-2.122a1 1 0 011.415 0l.707.708a1 1 0 010 1.414l-2.121 2.121a1 1 0 01-1.414 0l-.708-.707a1 1 0 010-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});