define("ember-svg-jar/inlined/cursor-move-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-move-up</title><path d=\"M20.723 8.6a1 1 0 00-1.744.982 8 8 0 11-14.132.344 1 1 0 00-1.789-.9A10 10 0 1020.723 8.6z\"/><path d=\"M8.7 10.879l3.176-1.93a.253.253 0 01.259 0l3.236 1.942a1 1 0 001.423-1.272l-3.878-8.53a1 1 0 00-1.822 0L7.268 9.613A1 1 0 008.7 10.879z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});