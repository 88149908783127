define("ember-svg-jar/inlined/messages-bubble-disable-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-disable-alternate</title><path d=\"M9.52 17.747a.249.249 0 00-.2-.236 11.978 11.978 0 01-.724-.183 1 1 0 00-.662.038l-3.648 1.52a.25.25 0 01-.331-.316l.851-2.339a1 1 0 00-.275-1.089A7.038 7.038 0 012 9.9C2 5.546 6.486 2 12 2s10 3.546 10 7.9a6.171 6.171 0 01-.047.735.25.25 0 00.106.236 7.925 7.925 0 011.27 1.091.25.25 0 00.424-.111A8.06 8.06 0 0024 9.9C24 4.443 18.617 0 12 0S0 4.443 0 9.9a8.876 8.876 0 002.707 6.26l-1.6 4.4a1 1 0 001.325 1.265l5.959-2.483c.343.09.689.163 1.037.225a.239.239 0 00.215-.071.236.236 0 00.061-.218 8.114 8.114 0 01-.184-1.531z\"/><path d=\"M12.9 12.905A6.5 6.5 0 1023.831 16 6.535 6.535 0 0012.9 12.905zm2.483 8.578a.252.252 0 01-.057-.4l5.754-5.753a.25.25 0 01.4.058 4.508 4.508 0 01-6.093 6.092zm4.222-7.96a.25.25 0 01.059.4l-5.745 5.745a.25.25 0 01-.4-.066 4.442 4.442 0 01.8-5.281 4.524 4.524 0 015.29-.798z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});