define("ember-svg-jar/inlined/gardening-spirinkler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gardening-spirinkler</title><path d=\"M7.455 1.9a2.894 2.894 0 00-.14-1.4 1 1 0 10-1.726 1.005 2.911 2.911 0 001.156.812.5.5 0 00.71-.415zM2.284 10.089A2.9 2.9 0 001 9.5a1 1 0 000 2 2.9 2.9 0 001.284-.589.5.5 0 000-.822zM5.784 10.089A2.9 2.9 0 004.5 9.5a1 1 0 000 2 2.9 2.9 0 001.284-.589.5.5 0 000-.822zM3.774 5.748a.5.5 0 00.019-.464 2.886 2.886 0 00-.818-1.152 1 1 0 10-1 1.731A2.965 2.965 0 003.381 6a.5.5 0 00.393-.252zM6.006 5.883a1 1 0 00-1 1.731 2.98 2.98 0 001.407.133.5.5 0 00.412-.712 2.906 2.906 0 00-.819-1.152zM7.354 4.528a2.908 2.908 0 001.156.812.5.5 0 00.71-.415 2.91 2.91 0 00-.139-1.406 1 1 0 10-1.727 1.009zM18.411 1.505A1 1 0 1016.685.5a2.894 2.894 0 00-.14 1.406.5.5 0 00.71.415 2.911 2.911 0 001.156-.816zM23 9.5a2.9 2.9 0 00-1.284.589.5.5 0 000 .822A2.9 2.9 0 0023 11.5a1 1 0 000-2zM19.5 9.5a2.9 2.9 0 00-1.284.589.5.5 0 000 .822 2.9 2.9 0 001.284.589 1 1 0 000-2zM22.392 4.5a1 1 0 00-1.367-.366 2.886 2.886 0 00-.818 1.152.5.5 0 00.412.714 2.965 2.965 0 001.406-.133 1 1 0 00.367-1.367zM19 7.614a1 1 0 10-1-1.731 2.906 2.906 0 00-.818 1.152.5.5 0 00.412.712A2.98 2.98 0 0019 7.614zM15.026 5.319a.5.5 0 00.464.021 2.908 2.908 0 001.156-.812 1 1 0 00-1.727-1.009 2.91 2.91 0 00-.139 1.406.5.5 0 00.246.394zM13.367 6.112a.5.5 0 01-.4-.37.938.938 0 00-.139-.3 1 1 0 00-1.658 0 .938.938 0 00-.139.3.5.5 0 01-.4.37C8.36 6.506 7 7.921 7 10c0 2.033 2.455 3.992 3.782 4.9a.5.5 0 01.218.413v.51a.5.5 0 01-.192.394.937.937 0 00-.357.6l-1.365 6.041a1 1 0 00.99 1.142h3.848a1 1 0 00.99-1.142l-1.365-6.039a.937.937 0 00-.357-.6.5.5 0 01-.192-.395v-.51a.5.5 0 01.218-.413C14.545 13.992 17 12.033 17 10c0-2.079-1.36-3.494-3.633-3.888zM11 12.024a.249.249 0 01-.415.187C9.711 11.44 9 10.6 9 10a1.887 1.887 0 011.691-1.86.25.25 0 01.214.047.247.247 0 01.1.2zm2.415.187a.249.249 0 01-.415-.187V8.383a.247.247 0 01.1-.2.25.25 0 01.214-.047A1.887 1.887 0 0115 10c0 .6-.711 1.44-1.585 2.211z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});