define("ember-svg-jar/inlined/multiple-actions-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-warning</title><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.5 14v1.054a.251.251 0 00.472.117l1.92-3.659A2.936 2.936 0 0117.5 9.938a2.516 2.516 0 01.584.07.25.25 0 00.27-.374 4.549 4.549 0 00-7.45-.373.248.248 0 00.005.31A6.959 6.959 0 0112.5 14zM.5 18h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M11.164 21.905a1.429 1.429 0 00.048 1.408 1.454 1.454 0 001.233.687h10.112a1.455 1.455 0 001.232-.686 1.433 1.433 0 00.049-1.409l-5.056-9.634a1.449 1.449 0 00-2.561 0zm5.336-.655a1 1 0 111 1 1 1 0 01-1-1zm1.75-2.75a.75.75 0 01-1.5 0V16a.75.75 0 011.5 0z\"/><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.5 14v1.054a.251.251 0 00.472.117l1.92-3.659A2.936 2.936 0 0117.5 9.938a2.516 2.516 0 01.584.07.25.25 0 00.27-.374 4.549 4.549 0 00-7.45-.373.248.248 0 00.005.31A6.959 6.959 0 0112.5 14zM.5 18h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M11.164 21.905a1.429 1.429 0 00.048 1.408 1.454 1.454 0 001.233.687h10.112a1.455 1.455 0 001.232-.686 1.433 1.433 0 00.049-1.409l-5.056-9.634a1.449 1.449 0 00-2.561 0zm5.336-.655a1 1 0 111 1 1 1 0 01-1-1zm1.75-2.75a.75.75 0 01-1.5 0V16a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});