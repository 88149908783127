define("ember-svg-jar/inlined/science-elecricity-power", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-elecricity-power</title><path d=\"M8 5h9a2 2 0 000-4H8a2 2 0 000 4zM14.5 11h-4a1 1 0 000 2h4a1 1 0 000-2zM14.5 7.25h-4a1 1 0 000 2h4a1 1 0 000-2zM14.5 14.75h-4a1 1 0 000 2h4a1 1 0 000-2zM7.343 7.932a1.243 1.243 0 00-1.114-.682h-.767a.5.5 0 01-.5-.5V5a.75.75 0 00-1.336-.469L.275 8.719a1.251 1.251 0 00.977 2.031h.71a.5.5 0 01.5.5v2.25a.75.75 0 001.356.441L7.24 9.235a1.246 1.246 0 00.103-1.303zM23.863 8.682A1.244 1.244 0 0022.749 8h-.768a.5.5 0 01-.5-.5V5.75a.75.75 0 00-1.335-.469L16.8 9.469a1.251 1.251 0 00.977 2.031h.709a.5.5 0 01.5.5v2.25a.749.749 0 00.519.713.739.739 0 00.231.037.75.75 0 00.607-.309l3.417-4.706a1.246 1.246 0 00.103-1.303z\"/><path d=\"M18.227 19.017a.251.251 0 01-.187-.36l.815-1.63a1 1 0 00-1.789-.895l-1.3 2.592a.5.5 0 01-.447.276H9.678a.5.5 0 01-.448-.276l-1.308-2.616A1 1 0 006.133 17l.828 1.657a.246.246 0 010 .227.251.251 0 01-.185.132A1.5 1.5 0 005.5 20.5V22a1 1 0 001 1h12a1 1 0 001-1v-1.5a1.5 1.5 0 00-1.273-1.483z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});