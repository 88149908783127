define("ember-svg-jar/inlined/professions-woman-office-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-woman-office-2</title><path d=\"M.5 23.562A.472.472 0 001 24h7.88a.247.247 0 00.238-.154.2.2 0 00-.1-.245l-4.374-2.656a.509.509 0 01-.215-.672l1.322-2.606a.2.2 0 00-.043-.235.272.272 0 00-.262-.063 13.852 13.852 0 00-3.98 1.74A2.075 2.075 0 00.5 20.84zM23.5 23.562A.472.472 0 0123 24h-7.88a.247.247 0 01-.238-.154.2.2 0 01.1-.245l4.378-2.656a.509.509 0 00.215-.672l-1.322-2.606a.2.2 0 01.043-.235.272.272 0 01.262-.063 13.852 13.852 0 013.98 1.74 2.075 2.075 0 01.962 1.731z\"/><path d=\"M16.606 17.232a.252.252 0 00-.2-.125.266.266 0 00-.226.09L12.2 22a.272.272 0 01-.4 0l-3.977-4.8a.266.266 0 00-.226-.09.254.254 0 00-.2.125c-.381.713-1.247 2.37-1.525 2.9a.2.2 0 00.087.272l5.9 3.51a.28.28 0 00.28 0l5.9-3.51a.2.2 0 00.087-.272c-.273-.535-1.139-2.19-1.52-2.903zM2.847 13.524a.5.5 0 00-.231.8 3.8 3.8 0 002.921 1.12 4.617 4.617 0 002.347-.6.248.248 0 01.273 0 6.972 6.972 0 007.641.032.25.25 0 01.275 0 4.626 4.626 0 002.39.623 3.8 3.8 0 002.921-1.12.5.5 0 00-.231-.8C18.538 12.744 19 9.053 19 7A7 7 0 005 7c0 1.953.468 5.683-2.153 6.524zM15.75 9a1 1 0 11-1-1 1 1 0 011 1zm-3.316-.909a.251.251 0 01-.233.159h-.4a.251.251 0 01-.233-.159 2.507 2.507 0 00-.605-.9.251.251 0 01.044-.4 5.867 5.867 0 00.832-.6.25.25 0 01.327 0 6.056 6.056 0 00.826.6.248.248 0 01.118.186.252.252 0 01-.076.208 2.506 2.506 0 00-.6.906zM9.25 8a1 1 0 11-1 1 1 1 0 011-1zm-1.532 3.536a.25.25 0 01.337-.345 2.459 2.459 0 001.2.309 2.492 2.492 0 002.316-1.591.251.251 0 01.229-.159h.4a.251.251 0 01.233.159A2.492 2.492 0 0014.75 11.5a2.459 2.459 0 001.2-.309.25.25 0 01.337.345 4.953 4.953 0 01-8.564 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});