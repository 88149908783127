define("ember-svg-jar/inlined/school-teacher-physical-education", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>school-teacher-physical-education</title><path d=\"M8.807 8.453a.25.25 0 01-.037-.418 4.5 4.5 0 10-5.54 0 .251.251 0 01-.036.418A6 6 0 000 13.75v3.5a1 1 0 001 1h1.121a.5.5 0 01.5.458L3 23.312a.749.749 0 00.75.688h4.5a.749.749 0 00.75-.688l.384-4.6a.5.5 0 01.5-.458H11a1 1 0 001-1v-3.5a6 6 0 00-3.193-5.301zM3.6 3.818a.25.25 0 01.367-.147A5.964 5.964 0 007 4.5a5.854 5.854 0 001.186-.124.25.25 0 01.214.054.247.247 0 01.087.2A2.495 2.495 0 113.6 3.818zM10 15.75a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-2a3.991 3.991 0 012.667-3.755.245.245 0 01.227.031.251.251 0 01.106.2v3.52a1 1 0 002 0V10.23a.251.251 0 01.106-.2.247.247 0 01.228-.031A3.991 3.991 0 0110 13.75zM21 0h-8.5a1 1 0 000 2H21a1 1 0 011 1v13a1 1 0 01-1 1h-6a1 1 0 000 2h6a3 3 0 003-3V3a3 3 0 00-3-3z\"/><path d=\"M19.93 8.03a.251.251 0 00-.412-.091l-3.14 3.14a.251.251 0 00.091.412l1.793.655a1.781 1.781 0 00.621.111 1.811 1.811 0 001.7-2.433zM15.4 10.639l3.674-3.674a.751.751 0 00.174-.788L18.784 4.9a1.811 1.811 0 00-2.984-.659l-3.12 3.122a1.811 1.811 0 00.658 2.983l1.278.467a.749.749 0 00.784-.174z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});