define("ember-svg-jar/inlined/expand-vertical-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>expand-vertical-4</title><path d=\"M6.273 23.749h11.454a1.25 1.25 0 000-2.5H6.273a1.25 1.25 0 000 2.5zM18.977 1.5a1.25 1.25 0 00-1.25-1.25H6.273a1.25 1.25 0 000 2.5h11.454a1.25 1.25 0 001.25-1.25zM14.864 8.226a1 1 0 00.707-1.707l-2.864-2.864a1 1 0 00-1.414 0L8.429 6.519a1 1 0 00.707 1.707H10.5a.25.25 0 01.25.25v7.045a.25.25 0 01-.25.25H9.136a1 1 0 00-.707 1.707l2.864 2.865a1 1 0 001.414 0l2.864-2.865a1 1 0 00-.707-1.707H13.5a.25.25 0 01-.25-.25V8.476a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});