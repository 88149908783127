define("ember-svg-jar/inlined/alarm-clock-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>alarm-clock-1</title><path d=\"M21.5 12.254a9.5 9.5 0 10-15.375 7.46l-2.332 2.333a1 1 0 000 1.414 1.014 1.014 0 001.414 0L7.863 20.8a9.483 9.483 0 008.274 0l2.656 2.657a1.014 1.014 0 001.414 0 1 1 0 000-1.414l-2.332-2.333a9.488 9.488 0 003.625-7.456zm-10.25-6.5a.75.75 0 111.5 0V11.5h2.75a.75.75 0 010 1.5H12a.75.75 0 01-.75-.75zM7.4 2.27a.5.5 0 00.129-.823 4.486 4.486 0 00-6.339 6.34.5.5 0 00.368.161.505.505 0 00.454-.29A11.019 11.019 0 017.4 2.27z\"/><path d=\"M19.5.254a4.484 4.484 0 00-3.033 1.193.5.5 0 00.129.823 11.019 11.019 0 015.388 5.388.519.519 0 00.454.29.5.5 0 00.368-.161A4.486 4.486 0 0019.5.254z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});