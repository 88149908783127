define("ember-svg-jar/inlined/audio-document-mp3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-document-mp3</title><path d=\"M12 9.625h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H12a.875.875 0 000-1.75z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM9.125 15a.625.625 0 01-1.25 0v-3.012c0-.141-.116-.108-.172 0l-.144.289a.65.65 0 01-1.118 0l-.153-.3c-.047-.091-.163-.1-.163 0V15a.625.625 0 01-1.25 0V9a.625.625 0 011.184-.279l.8 1.59a.152.152 0 00.289.005l.8-1.6A.625.625 0 019.125 9zm2.75-2.375h-.5a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0V9a.625.625 0 01.625-.625H12a2.128 2.128 0 012.114 2.347 2.213 2.213 0 01-2.239 1.903zm6.821-.479a2.3 2.3 0 01.429 1.352 2.015 2.015 0 01-1.865 2.123h-.757a1.736 1.736 0 01-1.628-1.821.625.625 0 011.25 0c0 .339.2.575.375.575h.754c.337 0 .621-.4.621-.873s-.285-.873-.623-.873a.625.625 0 010-1.25c.338 0 .623-.4.623-.873s-.284-.871-.621-.873h-.757c-.175 0-.372.236-.372.575a.625.625 0 01-1.25 0A1.735 1.735 0 0116.5 8.375h.76a2.015 2.015 0 011.865 2.125 2.3 2.3 0 01-.429 1.352.24.24 0 00.004.294z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});