define("ember-svg-jar/inlined/rating-half-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rating-half-star</title><path d=\"M18.324 1.249a1 1 0 00-1-1 1.512 1.512 0 00-1.411.993l-2.178 6.174a.5.5 0 01-.472.333H7.174a1.5 1.5 0 00-.963 2.651l5.189 4.3a.5.5 0 01.155.543l-2.182 6.53a1.5 1.5 0 002.309 1.685l6.233-4.57a1 1 0 00.408-.807z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});