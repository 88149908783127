define("ember-svg-jar/inlined/3d-print-monitor-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>3d-print-monitor-1</title><path d=\"M22.75 3.625H20a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25h1.575a.424.424 0 01.425.423V15.7a.424.424 0 01-.425.423H2.425A.424.424 0 012 15.7V6.048a.424.424 0 01.425-.423H4a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25H1.25A1.25 1.25 0 000 4.875v13.333a1.25 1.25 0 001.25 1.25h21.5a1.25 1.25 0 001.25-1.25V4.875a1.25 1.25 0 00-1.25-1.25zM13.958 20.859a.417.417 0 00-.32-.151h-3.276a.417.417 0 00-.32.151l-1.75 2.11a.417.417 0 00.341.656h6.733a.417.417 0 00.341-.656z\"/><path d=\"M5.75 9.625a.751.751 0 00.439.683l5.5 2.5a.755.755 0 00.622 0l5.5-2.5a.751.751 0 00.439-.683v-6a.751.751 0 00-.439-.683l-5.5-2.5a.75.75 0 00-.622 0l-5.5 2.5a.751.751 0 00-.439.683zm6.35-4.371a.247.247 0 01-.206 0l-3.084-1.4a.251.251 0 010-.456L11.9 2a.247.247 0 01.2 0l3.084 1.4a.251.251 0 010 .456z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});