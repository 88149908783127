define("ember-svg-jar/inlined/playlist-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>playlist-edit</title><path d=\"M16.427 12.5a.249.249 0 00.073-.177V2.5A2.5 2.5 0 0014 0H2.5A2.5 2.5 0 000 2.5v12A2.5 2.5 0 002.5 17h9.57a.251.251 0 00.177-.073zM12.5 7a1 1 0 01-2 0 1.769 1.769 0 00-1.151-1.728A.249.249 0 009 5.5v5.552A2.5 2.5 0 116.5 8.5c.077 0 .152 0 .227.01A.249.249 0 007 8.261V4a1 1 0 011-1c1.557 0 4.5.836 4.5 4zM12.062 20.132a.248.248 0 00-.249-.063.251.251 0 00-.173.19l-.628 3.141a.5.5 0 00.588.588l3.143-.628a.251.251 0 00.128-.422zM19.529 11.766l-6.679 6.679a.25.25 0 000 .353l3.35 3.354a.249.249 0 00.353 0l6.679-6.679a2.621 2.621 0 00-3.707-3.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});