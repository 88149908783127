define("ember-svg-jar/inlined/folder-empty-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-empty-1-alternate</title><path d=\"M22 22H2a2 2 0 01-2-2V4a2 2 0 012-2h6.5a2 2 0 011.787 1.1l.81 1.62a.5.5 0 00.448.28H22a2 2 0 012 2v13a2 2 0 01-2 2zM8.191 4H2.5a.5.5 0 00-.5.5v15a.5.5 0 00.5.5h19a.5.5 0 00.5-.5v-12a.5.5 0 00-.5-.5H11.236a2 2 0 01-1.788-1.1l-.81-1.619A.5.5 0 008.191 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});