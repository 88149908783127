define("ember-svg-jar/inlined/modern-music-dj-tape", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-music-dj-tape</title><path d=\"M9.921 17.953a.07.07 0 00.042-.119 3.016 3.016 0 01-.389-3.786.252.252 0 00.038-.183 3.009 3.009 0 013.515-3.514.256.256 0 00.183-.037 3.024 3.024 0 012.979-.188.248.248 0 00.219 0 3.021 3.021 0 011.231-.3.249.249 0 00.241-.233Q18 9.3 18 9a9 9 0 10-8.079 8.953zM3.5 9a.5.5 0 01-1 0A6.508 6.508 0 019 2.5a.5.5 0 010 1A5.506 5.506 0 003.5 9zM7 9a2 2 0 112 2 2 2 0 01-2-2z\"/><path d=\"M22.865 21.268a3.886 3.886 0 000-5.489L18.9 11.811a1.519 1.519 0 00-2.143 0 1.576 1.576 0 00-.148.171.25.25 0 01-.378.028l-.2-.2a1.518 1.518 0 00-2.141 0 1.46 1.46 0 00-.169.2.25.25 0 01-.339.068 1.515 1.515 0 00-2.091 2.09.249.249 0 01-.067.338 1.542 1.542 0 00-.2.17 1.516 1.516 0 000 2.142l2.537 2.537a.25.25 0 01-.2.426l-.814-.061a1.324 1.324 0 00-1.211 1.31 1.281 1.281 0 001.008 1.27c.13.046 3.213 1.13 5.336 1.6a3.929 3.929 0 00.852.094 3.863 3.863 0 002.739-1.139z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});