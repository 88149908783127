define("ember-svg-jar/inlined/animal-print-bird-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>animal-print-bird-1</title><path d=\"M16.619 10.324a1.25 1.25 0 00-1.25 1.25v3.5a1.25 1.25 0 002.5 0v-3.5a1.25 1.25 0 00-1.25-1.25zM12.327 13.286a1.25 1.25 0 00-1.94 1.576l2.263 2.785a1.249 1.249 0 101.939-1.576zM22.669 13.1a1.254 1.254 0 00-1.759.183l-2.261 2.783a1.25 1.25 0 101.941 1.576l2.261-2.783a1.251 1.251 0 00-.182-1.759zM16.619 17.824a1.25 1.25 0 00-1.25 1.25v3.5a1.25 1.25 0 002.5 0v-3.5a1.25 1.25 0 00-1.25-1.25zM9.127 1.862A1.249 1.249 0 006.784.993L5.566 4.274a1.25 1.25 0 002.344.87zM3.673 6.67a1.25 1.25 0 00.783-1.585L3.3 1.686a1.25 1.25 0 10-2.367.8l1.152 3.4a1.258 1.258 0 001.588.784zM13.2 4.819a1.249 1.249 0 00-1.712-.44L8.4 6.2a1.25 1.25 0 001.268 2.153l3.087-1.822a1.25 1.25 0 00.445-1.712zM5.716 7.462a1.253 1.253 0 00-1.607.738l-1.153 3.105a1.25 1.25 0 102.343.871l1.154-3.107a1.251 1.251 0 00-.737-1.607z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});