define("ember-svg-jar/inlined/shipment-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-monitor</title><path d=\"M19.962 3a3 3 0 00-3-3H3.038a3 3 0 00-3 3v8.5a3 3 0 003 3H8.75a.249.249 0 01.25.25v1.5a.249.249 0 01-.25.25H6.462a1 1 0 000 2h5.5a1 1 0 000-2h-.712a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h5.7a3.011 3.011 0 003-2.942zM2.038 3a1 1 0 011-1h13.924a1 1 0 011 1v6.042a1 1 0 01-1 1H3.038a1 1 0 01-1-1z\"/><path d=\"M22.962 15.5h-1.75a.25.25 0 00-.25.25v2.783a.25.25 0 01-.388.208l-.973-.648a.246.246 0 00-.277 0l-.973.648a.25.25 0 01-.389-.208V15.75a.25.25 0 00-.25-.25h-1.75a1 1 0 00-1 1V23a1 1 0 001 1h7a1 1 0 001-1v-6.5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});