define("ember-svg-jar/inlined/museum-art", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>museum-art</title><path d=\"M7.289 12.293A2.078 2.078 0 009.175 13.5h5.65a2.078 2.078 0 001.886-1.207l2.124-4.6a2.078 2.078 0 00-.058-1.855l-2.554-4.746A2.076 2.076 0 0014.394 0H9.606a2.076 2.076 0 00-1.829 1.092L5.223 5.837a2.078 2.078 0 00-.058 1.855zm1.125-5.8a.744.744 0 01.864.616c.343 2.037 2.529 2.147 2.835 2.152a.724.724 0 01.115-.009A2.379 2.379 0 0014.712 7a2 2 0 00-2.02-2.25.978.978 0 00-1.035.615.821.821 0 00.308.923.75.75 0 11-.854 1.233 2.312 2.312 0 01-.886-2.6 2.448 2.448 0 012.467-1.671A3.483 3.483 0 0116.212 7a3.874 3.874 0 01-3.931 3.75 1.329 1.329 0 01-.16.009 4.211 4.211 0 01-4.32-3.4.751.751 0 01.613-.868zM18.5 15h-13a.5.5 0 00-.447.724l1 2A.5.5 0 006.5 18a.234.234 0 01.234.234V23.5a.5.5 0 00.5.5h9.5a.5.5 0 00.5-.5v-5.234A.266.266 0 0117.5 18a.5.5 0 00.447-.276l1-2A.5.5 0 0018.5 15zm-4.766 6h-3.5a1 1 0 010-2h3.5a1 1 0 110 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});