define("ember-svg-jar/inlined/smiley-crazy-tongue-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-crazy-tongue-alternate</title><path d=\"M6.014 11.164A1 1 0 007 12a.927.927 0 00.165-.014l3-.5a1 1 0 00.543-1.693l-3-3a1 1 0 10-1.415 1.414L7.575 9.49a.249.249 0 01-.135.423l-.6.1a1 1 0 00-.826 1.151zM17.707 6.793a1 1 0 00-1.414 0l-3 3a1 1 0 00.565 1.7l3.5.5a1 1 0 10.284-1.98l-1.026-.146a.25.25 0 01-.141-.425l1.232-1.232a1 1 0 000-1.417zM17.2 14.5H6.8a.5.5 0 00-.433.75 6.514 6.514 0 001.538 1.8.253.253 0 01.092.2V20a4 4 0 008 0v-2.756a.25.25 0 01.093-.2 6.528 6.528 0 001.537-1.8.5.5 0 00-.433-.75zM12 22a2 2 0 01-2-2v-2.45a.252.252 0 01.138-.224A4.2 4.2 0 0112 17a4.428 4.428 0 011.855.311.251.251 0 01.145.227V20a2 2 0 01-2 2z\"/><path d=\"M12 0a12 12 0 00-7.594 21.292 1 1 0 001.266-1.548 10 10 0 1112.627.024 1 1 0 101.26 1.552A12 12 0 0012 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});