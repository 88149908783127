define("ember-svg-jar/inlined/folder-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-remove</title><path d=\"M17.5 9.5a7.95 7.95 0 013.154.653A.244.244 0 0021 9.93V4.5A1.5 1.5 0 0019.5 3H9.618a.5.5 0 01-.447-.276L8.223.829A1.5 1.5 0 006.882 0H1.5A1.5 1.5 0 000 1.5v15A1.5 1.5 0 001.5 18h7.555a.449.449 0 00.445-.5 8 8 0 018-8z\"/><path d=\"M22.1 12.9a6.5 6.5 0 101.9 4.6 6.457 6.457 0 00-1.9-4.6zm-3.358 4.773l1.414 1.414a.75.75 0 11-1.061 1.06l-1.414-1.414a.252.252 0 00-.354 0l-1.414 1.414a.75.75 0 11-1.061-1.06l1.414-1.414a.25.25 0 000-.354l-1.414-1.414a.75.75 0 011.061-1.06l1.414 1.414a.252.252 0 00.354 0l1.414-1.414a.75.75 0 011.061 1.06l-1.414 1.414a.25.25 0 00-.004.358z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});