define("ember-svg-jar/inlined/insurance-hands", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>insurance-hands</title><path d=\"M18.875 11A1.127 1.127 0 0020 9.875v-3.75A1.127 1.127 0 0018.875 5H15.5a.5.5 0 01-.5-.5V1.125A1.127 1.127 0 0013.875 0h-3.75A1.127 1.127 0 009 1.125V4.5a.5.5 0 01-.5.5H5.125A1.127 1.127 0 004 6.125v3.75A1.127 1.127 0 005.125 11H8.5a.5.5 0 01.5.5v3.375A1.127 1.127 0 0010.125 16h3.75A1.127 1.127 0 0015 14.875V11.5a.5.5 0 01.5-.5zM6.867 16.534a1.376 1.376 0 10-2.251 1.583L5.4 19.2a.5.5 0 01-.8.6l-1.1-1.467a2.52 2.52 0 01-.5-1.5V12.5a1.5 1.5 0 00-3 0v5.672A5.014 5.014 0 001.1 21.3l2.013 2.518A.5.5 0 003.5 24H8a.5.5 0 00.5-.5v-3.137a5.061 5.061 0 00-.93-2.774zM22.5 11a1.5 1.5 0 00-1.5 1.5v4.333a2.52 2.52 0 01-.5 1.5L19.4 19.8a.5.5 0 11-.8-.6l.784-1.083a1.376 1.376 0 10-2.251-1.583l-.7 1.055a5.061 5.061 0 00-.93 2.774V23.5a.5.5 0 00.5.5h4.5a.5.5 0 00.391-.187L22.9 21.3a5.014 5.014 0 001.1-3.128V12.5a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});