define("ember-svg-jar/inlined/security-officer-plant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>security-officer-plant</title><path d=\"M18.452 5.7a.25.25 0 00-.25.25v.75a2.5 2.5 0 005 .008v-.759a.25.25 0 00-.25-.25zM16.746 4.7h6.369a.5.5 0 00.474-.342l.33-.988a1.006 1.006 0 00-.074-.8.994.994 0 00-.633-.488L18.179.825a.741.741 0 00-.734.218.751.751 0 00-.161.746l.3.91h-.841a1 1 0 100 2zM23.5 11.2h-1.41a.5.5 0 00-.47.31l-.64 1.61a.262.262 0 01-.23.16.251.251 0 01-.23-.16l-.65-1.61a.489.489 0 00-.46-.31h-1.743a2 2 0 00-1.415.587l-4.594 4.6a1.245 1.245 0 001.76 1.76l3.9-3.892a.25.25 0 01.427.177V20.7a.5.5 0 01-.5.5H6.47v-1.5a4.54 4.54 0 013.477-2.085 1 1 0 00-.007-2 6.4 6.4 0 00-3.47 1.235V15.3a3.522 3.522 0 012.358-1.011 1 1 0 000-2 5.985 5.985 0 00-2.353.565v-1.5a2.035 2.035 0 011.241-.419 1 1 0 00-.006-2 4.572 4.572 0 00-1.235.191V7.7a1 1 0 00-2 0v1.426a4.587 4.587 0 00-1.237-.191 1 1 0 000 2 2.062 2.062 0 011.237.42v1.5a5.988 5.988 0 00-2.354-.565 1 1 0 100 2A3.511 3.511 0 014.47 15.3v1.55A6.407 6.407 0 001 15.619a1 1 0 00-.008 2A4.542 4.542 0 014.47 19.7v1.5H1.009a1 1 0 000 2H23.5a.5.5 0 00.5-.5v-11a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});