define("ember-svg-jar/inlined/email-action-upload-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-upload_1</title><path d=\"M17.5 9.5a7.908 7.908 0 013.151.657.251.251 0 00.349-.23V1.75a.158.158 0 00-.1-.147.16.16 0 00-.173.034L12.2 10.164a2.407 2.407 0 01-3.4 0L.271 1.637A.159.159 0 000 1.75v10.5A1.749 1.749 0 001.75 14h8.413a.252.252 0 00.223-.136A8 8 0 0117.5 9.5z\"/><path d=\"M9.726 9.236a1.094 1.094 0 001.547 0L19.748.761a.437.437 0 00.106-.447C19.758.027 19.49 0 19.249 0h-17.5c-.24 0-.51.027-.605.314a.441.441 0 00.106.447zM17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.45 6.217a.5.5 0 01-.45.283h-1a.25.25 0 00-.25.25v2.75a.75.75 0 01-1.5 0v-2.75a.25.25 0 00-.25-.25h-1a.5.5 0 01-.391-.812l2-2.5a.517.517 0 01.782 0l2 2.5a.5.5 0 01.059.529z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});