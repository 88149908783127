define("ember-svg-jar/inlined/natural-disaster-bolt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>natural-disaster-bolt</title><path d=\"M19.55 13.765A2 2 0 0118 14.5a1.979 1.979 0 01-.671-.116A2 2 0 0116 12.5l-.036-3a2.32 2.32 0 01-2.045-3.416.252.252 0 00-.044-.295l-1.7-1.7a.251.251 0 00-.354 0l-8.75 8.75a.249.249 0 00-.071.179V21.5A2.5 2.5 0 005.5 24h13a2.5 2.5 0 002.5-2.5v-8.482a.249.249 0 00-.073-.177l-.148-.148a.248.248 0 00-.189-.072.244.244 0 00-.181.091zM21.4 11.5a.25.25 0 00.017.335l.876.875a1 1 0 001.37.042c.015-.014.03-.027.044-.042a1 1 0 000-1.414l-.983-.983a.249.249 0 00-.19-.073.253.253 0 00-.181.092z\"/><path d=\"M1.707 12.707L11.823 2.591a.251.251 0 01.354 0l2.3 2.3a.25.25 0 00.37-.018l.953-1.167a.25.25 0 00-.016-.335L12.707.293a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414c.014.015.029.028.044.042a1 1 0 001.37-.042zM23.036 5H21.75a.25.25 0 01-.25-.25V.5a.5.5 0 00-.888-.316l-5.273 6.462A.822.822 0 0015.964 8h1.286a.25.25 0 01.25.25v4.25a.5.5 0 00.332.471A.512.512 0 0018 13a.5.5 0 00.388-.184l5.273-6.462A.822.822 0 0023.036 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});