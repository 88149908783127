define("ember-svg-jar/inlined/file-copyright-tm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-copyright-tm</title><path d=\"M23.414 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.276a.248.248 0 00.242.249 19.68 19.68 0 011.485.083A.247.247 0 008 9.359V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.245.245 0 00-.241.21 7.935 7.935 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.414A2 2 0 0023.414 3z\"/><path d=\"M6.5 11a6.5 6.5 0 106.5 6.5A6.508 6.508 0 006.5 11zm-2 5h-.25a.25.25 0 00-.25.25v3.25a.5.5 0 01-1 0v-3.25a.25.25 0 00-.25-.25H2.5a.5.5 0 010-1h2a.5.5 0 010 1zm5.5 3.5a.5.5 0 01-1 0v-1.606c0-.217-.227-.21-.313-.068l-.258.431a.5.5 0 01-.858 0s-.145-.248-.252-.42S7 17.729 7 17.882V19.5a.5.5 0 01-1 0v-4a.5.5 0 01.929-.257l.857 1.428a.249.249 0 00.428 0l.857-1.428A.5.5 0 0110 15.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});