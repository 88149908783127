define("ember-svg-jar/inlined/aircraft-hot-air-balloon-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>aircraft-hot-air-balloon-2</title><path d=\"M23 9.5C23 3.462 18.991 0 12 0S1 3.462 1 9.5a8.229 8.229 0 004.953 7.547.251.251 0 01.1.1L7.5 19.586a.5.5 0 01-.123.649A.959.959 0 007 21a1 1 0 001 1 .25.25 0 01.25.25v.5A1.252 1.252 0 009.5 24h5a1.252 1.252 0 001.25-1.25v-.5A.25.25 0 0116 22a1 1 0 001-1 .959.959 0 00-.378-.765.5.5 0 01-.123-.649l1.446-2.443a.249.249 0 01.1-.1A8.23 8.23 0 0023 9.5zm-2 0c0 3.573-2.605 5.413-5.128 6.291a.25.25 0 01-.284-.384 10.684 10.684 0 001.829-6.241 10.083 10.083 0 00-2-6.355.25.25 0 01.255-.4C19.209 3.305 21 5.677 21 9.5zm-18 0c0-3.823 1.791-6.195 5.328-7.09a.251.251 0 01.255.4 10.083 10.083 0 00-2 6.355 10.684 10.684 0 001.829 6.241.25.25 0 01-.284.384C5.605 14.913 3 13.073 3 9.5zm11.075 10.255a.5.5 0 01-.43.245h-3.289a.5.5 0 01-.431-.245l-.672-1.137a.25.25 0 01.263-.372A13.237 13.237 0 0012 18.5a13.169 13.169 0 002.485-.255.251.251 0 01.263.373z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});