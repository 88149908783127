define("ember-svg-jar/inlined/video-game-mario-plant-maneater", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-mario-plant-maneater</title><rect x=\"9.5\" y=\"21\" width=\"14.5\" height=\"3\" rx=\"1\" ry=\"1\"/><circle cx=\"5.5\" cy=\"11\" r=\"1.5\"/><circle cx=\"1.5\" cy=\"14\" r=\"1.5\"/><path d=\"M23.5 14.5H23a3.956 3.956 0 00-1.608.34.249.249 0 01-.326-.337l1.759-3.643A4.31 4.31 0 0023.25 9a3.5 3.5 0 00-2.982-3.449.5.5 0 01-.415-.391A6.481 6.481 0 007.707 3.59a.5.5 0 01-.412.275 2.195 2.195 0 00.114 4.384l2.594.122a.25.25 0 01.184.407l-1.607 2a2.2 2.2 0 001.642 3.656c.068 0 .137 0 .205-.01a2.2 2.2 0 001.536-.849l.334-.434a.5.5 0 01.457-.192 6.3 6.3 0 00.736.048 6.48 6.48 0 006.314-4.949.5.5 0 01.756-.3A1.487 1.487 0 0121.25 9a2.3 2.3 0 01-.227.991s-2.234 4.551-2.911 6.065a.25.25 0 01-.428.048A3.968 3.968 0 0014.5 14.5H14a.5.5 0 00-.5.5v.5a4 4 0 004 4H20a4 4 0 004-4V15a.5.5 0 00-.5-.5zm-8.99-6.695l-3.734 4.857a.7.7 0 01-.487.269.676.676 0 01-.528-.172.708.708 0 01-.034-1.012L11.935 9a1.25 1.25 0 00-.914-2.08l-3.575-.17a.7.7 0 01-.474-1.206.717.717 0 01.528-.186l6.115.438a1.25 1.25 0 01.9 2.009z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});