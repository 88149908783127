define("ember-svg-jar/inlined/social-video-youtube-clip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-video-youtube-clip</title><path d=\"M20.06 3.5H3.94A3.94 3.94 0 000 7.44v9.12a3.94 3.94 0 003.94 3.94h16.12A3.94 3.94 0 0024 16.56V7.44a3.94 3.94 0 00-3.94-3.94zM16.54 12l-6.77 4.36a.5.5 0 01-.77-.42V7.28a.5.5 0 01.77-.42l6.77 4.33a.5.5 0 010 .84z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});