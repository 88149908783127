define("ember-svg-jar/inlined/tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tree</title><path d=\"M19 13a6.419 6.419 0 00-.779-3.057L13.178.7a1.342 1.342 0 00-2.356 0L5.779 9.943A6.419 6.419 0 005 13a7.012 7.012 0 005.367 6.807.5.5 0 01.383.486v2.457a1.25 1.25 0 002.5 0v-2.457a.5.5 0 01.383-.486A7.012 7.012 0 0019 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});