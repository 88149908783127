define("ember-svg-jar/inlined/smiley-smile-upside-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-smile-upside-down</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM8.228 9.546a1 1 0 01-1.676-1.092 6.5 6.5 0 0110.9 0 1 1 0 01-1.676 1.092 4.5 4.5 0 00-7.544 0zM10 15a2 2 0 11-2-2 2 2 0 012 2zm6 2a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});