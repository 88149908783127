define("ember-svg-jar/inlined/video-file-play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-play</title><path d=\"M21.414 4.914L17.086.586A2 2 0 0015.672 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.328a2 2 0 00-.586-1.414zm-4.423 9.405l-7.4 4.045a1.138 1.138 0 01-1.057.012.944.944 0 01-.534-.831v-8.09a.944.944 0 01.532-.831 1.138 1.138 0 011.057.012l7.4 4.045a.913.913 0 010 1.638z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});