define("ember-svg-jar/inlined/picture-stack-human", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>picture-stack-human</title><path d=\"M15.025 17.508a.453.453 0 00.423-.616 4.659 4.659 0 00-.18-.408c-.389-.778-2.013-1.317-4.051-2.072-.552-.205-.461-1.643-.217-1.912a4.066 4.066 0 001.059-3.154 2.482 2.482 0 00-2.581-2.708A2.481 2.481 0 006.9 9.346 4.07 4.07 0 007.956 12.5c.245.269.335 1.707-.216 1.912-2.039.755-3.663 1.294-4.051 2.072a4.378 4.378 0 00-.18.408.453.453 0 00.422.616z\"/><path d=\"M19 19.5a2 2 0 01-2 2H2a2 2 0 01-2-2v-15a2 2 0 012-2h15a2 2 0 012 2zM2.5 4.5A.5.5 0 002 5v14a.5.5 0 00.5.5h14a.5.5 0 00.5-.5V5a.5.5 0 00-.5-.5zM20.75 19.25a.75.75 0 01-.75-.75v-13a.75.75 0 011.5 0v13a.75.75 0 01-.75.75zM23.25 16.75a.75.75 0 01-.75-.75V8A.75.75 0 0124 8v8a.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});