define("ember-svg-jar/inlined/social-bookmark-ffffound", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-bookmark-ffffound</title><path d=\"M21.92 2.83A4.87 4.87 0 0017.4 0c-2.16 0-4.09 1.39-5.4 3.78C10.68 1.44 8.75.07 6.59 0a4.86 4.86 0 00-4.5 2.8c-1.88 3.41 1 7.25 4.06 11.33 2.28 3 4.64 6.19 5.36 9.44a.5.5 0 001 0c.72-3.25 3.08-6.4 5.36-9.44 3.03-4.08 5.91-7.92 4.05-11.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});