define("ember-svg-jar/inlined/smiley-mad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-mad</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM5.632 7.5A1 1 0 017 7.132l3.5 2A1 1 0 0110 11a.988.988 0 01-.5-.132l-3.5-2A1 1 0 015.632 7.5zm1.53 9.949a5.774 5.774 0 019.676 0 1 1 0 01-1.676 1.094 3.771 3.771 0 00-6.324 0 1 1 0 01-1.676-1.094zM18 8.868l-3.5 2a1 1 0 01-1.364-.372 1 1 0 01.364-1.364l3.5-2a1 1 0 111 1.736z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});