define("ember-svg-jar/inlined/browser-page-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>browser-page-account</title><path d=\"M24 4.75a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h18a3 3 0 003-3zm-14.346-1a.966.966 0 011.692 0 .969.969 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0 .969.969 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0A.969.969 0 016 4.25a.969.969 0 01.154-.5zm-3.562.092A1 1 0 013.5 3.25a.985.985 0 01.846.5.969.969 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.979.979 0 01.092-.408zM22 19.25a1 1 0 01-1 1H3a1 1 0 01-1-1V7a.25.25 0 01.25-.25h19.5A.25.25 0 0122 7z\"/><path d=\"M5.321 18.25h6.858A1.288 1.288 0 0013.5 17v-7a1.289 1.289 0 00-1.321-1.25H5.321A1.289 1.289 0 004 10v7a1.288 1.288 0 001.321 1.25zM5.5 10.5a.25.25 0 01.25-.25h6a.25.25 0 01.25.25v6a.25.25 0 01-.25.25h-.391a.249.249 0 01-.241-.184A2.587 2.587 0 008.75 14.5a2.587 2.587 0 00-2.368 2.066.249.249 0 01-.241.184H5.75a.25.25 0 01-.25-.25z\"/><circle cx=\"8.75\" cy=\"13\" r=\"1.5\"/><path d=\"M15 10a.75.75 0 00.75.75h4a.75.75 0 000-1.5h-4A.75.75 0 0015 10zM15.75 14.25h4a.75.75 0 000-1.5h-4a.75.75 0 000 1.5zM15.75 17.75h4a.75.75 0 000-1.5h-4a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});