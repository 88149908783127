define("ember-svg-jar/inlined/arduino-circuit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arduino-circuit</title><path d=\"M24 10a1.509 1.509 0 00-.6-1.2l-1.3-.974a.252.252 0 01-.1-.2V3.5A1.5 1.5 0 0020.5 2h-19A1.5 1.5 0 000 3.5v17A1.5 1.5 0 001.5 22h19a1.5 1.5 0 001.5-1.5v-2.124a.249.249 0 01.1-.2l1.3-.975A1.508 1.508 0 0024 16zm-10.5 8.5a.25.25 0 00-.25.25V19a.75.75 0 01-1.5 0v-.25a.25.25 0 00-.25-.25H10a.25.25 0 00-.25.25V19a.75.75 0 01-1.5 0v-.25A.25.25 0 008 18.5H7a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h1a.25.25 0 00.25-.25V14a.75.75 0 111.5 0v.25a.25.25 0 00.25.25h1.5a.25.25 0 00.25-.25V14a.75.75 0 111.5 0v.25a.25.25 0 00.25.25H15a.25.25 0 00.25-.25V14a.75.75 0 111.5 0v.25a.25.25 0 00.25.25h1a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-1a.25.25 0 00-.25.25V19a.75.75 0 11-1.5 0v-.25a.25.25 0 00-.25-.25zM6 8.751V6a1.251 1.251 0 011.25-1.25H10A1.251 1.251 0 0111.25 6v2.75A1.252 1.252 0 0110 10H7.25A1.252 1.252 0 016 8.751zM14.25 8a1 1 0 11-1 1 1 1 0 011-1zm-1-2.25a1 1 0 111 1 1 1 0 01-1-.999zm4.5 4.25a1 1 0 111-1 1 1 0 01-1 1zm0-3.25a1 1 0 111-1 1 1 0 01-1 1.001z\"/><rect x=\"7.5\" y=\"6.251\" width=\"2.25\" height=\"2.25\" rx=\".25\" ry=\".25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});