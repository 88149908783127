define("ember-svg-jar/inlined/single-man-actions-music.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-music</title><path d=\"M.124 17.567a.494.494 0 00.5.5H2.41a.247.247 0 01.247.227l.443 5.252a.494.494 0 00.488.454h3.959a.5.5 0 00.493-.454l.438-5.257a.246.246 0 01.246-.227h1.792a.5.5 0 00.495-.5V14.1a5.444 5.444 0 00-10.887 0z\"/><path d=\"M5.567 0a4.206 4.206 0 104.206 4.206A4.211 4.211 0 005.567 0zm0 6.433A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.248.248 0 01.36-.119 5.311 5.311 0 002.8.793 5.353 5.353 0 00.9-.077.205.205 0 01.165.045.2.2 0 01.072.155 2.229 2.229 0 01-2.227 2.23zM13.484 24a2.478 2.478 0 002.475-2.475V14.14a.247.247 0 01.2-.243l5.441-1.021a.248.248 0 01.293.243v3.716a.248.248 0 01-.27.247 2.389 2.389 0 00-.225-.01 2.474 2.474 0 102.474 2.474v-6.725a1.98 1.98 0 00-2.344-1.946l-5.938 1.114a1.981 1.981 0 00-1.615 1.945v4.881a.249.249 0 01-.27.246 2.78 2.78 0 00-.225-.01 2.475 2.475 0 000 4.949z\"/><path d=\"M.124 17.567a.494.494 0 00.5.5H2.41a.247.247 0 01.247.227l.443 5.252a.494.494 0 00.488.454h3.959a.5.5 0 00.493-.454l.438-5.257a.246.246 0 01.246-.227h1.792a.5.5 0 00.495-.5V14.1a5.444 5.444 0 00-10.887 0z\"/><path d=\"M5.567 0a4.206 4.206 0 104.206 4.206A4.211 4.211 0 005.567 0zm0 6.433A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.248.248 0 01.36-.119 5.311 5.311 0 002.8.793 5.353 5.353 0 00.9-.077.205.205 0 01.165.045.2.2 0 01.072.155 2.229 2.229 0 01-2.227 2.23zM13.484 24a2.478 2.478 0 002.475-2.475V14.14a.247.247 0 01.2-.243l5.441-1.021a.248.248 0 01.293.243v3.716a.248.248 0 01-.27.247 2.389 2.389 0 00-.225-.01 2.474 2.474 0 102.474 2.474v-6.725a1.98 1.98 0 00-2.344-1.946l-5.938 1.114a1.981 1.981 0 00-1.615 1.945v4.881a.249.249 0 01-.27.246 2.78 2.78 0 00-.225-.01 2.475 2.475 0 000 4.949z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});