define("ember-svg-jar/inlined/arrow-double-down-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-double-down-1</title><path d=\"M17.387 13.05a1 1 0 00-1.088.209l-4.124 4.058a.249.249 0 01-.35 0L7.7 13.259a1 1 0 00-1.7.713v4.009a1 1 0 00.3.713l5 4.919a1 1 0 001.4 0l5-4.92a1 1 0 00.3-.712v-4.009a1 1 0 00-.613-.922z\"/><path d=\"M17.387 6.062a.994.994 0 00-1.088.21l-4.124 4.058a.251.251 0 01-.35 0L7.7 6.272a1 1 0 00-1.7.713V8.51a.994.994 0 00.3.713l5 4.92a1 1 0 001.4 0l5-4.921a.993.993 0 00.3-.712V6.985a1 1 0 00-.613-.923z\"/><path d=\"M10.948 6.383a1.508 1.508 0 002.1 0L17.7 1.815A1 1 0 1016.3.389L12.176 4.44a.249.249 0 01-.35 0L7.7.389a1 1 0 00-1.4 1.426z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});