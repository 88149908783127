define("ember-svg-jar/inlined/baggage-weight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baggage-weight</title><path d=\"M11.525 5.75a5.75 5.75 0 10-5.75 5.75 5.756 5.756 0 005.75-5.75zM5.775 9.5a3.75 3.75 0 113.75-3.75 3.755 3.755 0 01-3.75 3.75z\"/><path d=\"M6.716 3.572l-1.833 2.05a.75.75 0 101.117 1l1.833-2.05a.75.75 0 00-1.118-1zM22.975 13H20.5a.5.5 0 01-.5-.5v-1.49a2 2 0 00-2-2h-4a2 2 0 00-2 2v1.49a.5.5 0 01-.5.5H9.025a1 1 0 00-1 1v9a1 1 0 001 1h13.95a1 1 0 001-1v-9a1 1 0 00-1-1zM14 11.51a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v.99a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5zM19.5 21h-7a.5.5 0 010-1h7a.5.5 0 010 1zm0-4h-7a.5.5 0 010-1h7a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});