define("ember-svg-jar/inlined/flying-insect-bee-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flying-insect-bee-1</title><path d=\"M22.5 12.5A2.5 2.5 0 0021 8h-2.019a.5.5 0 00-.354.147.5.5 0 00-.146.354l.018 8a.5.5 0 00.5.5h2a2.5 2.5 0 001.5-4.5zM15.5 0A2.5 2.5 0 0013 2.5v1.074a6.794 6.794 0 00-2 0V2.5a2.5 2.5 0 00-5 0 1 1 0 002 0 .5.5 0 011 0v1.857A5.52 5.52 0 007 8.5a.5.5 0 00.5.5h9a.5.5 0 00.5-.5 5.52 5.52 0 00-2-4.143V2.5a.5.5 0 011 0 1 1 0 002 0A2.5 2.5 0 0015.5 0z\"/><rect x=\"7\" y=\"10.5\" width=\"10\" height=\"4\" rx=\".5\" ry=\".5\"/><path d=\"M11 20.427V23a1 1 0 002 0v-2.573a4.578 4.578 0 003.862-3.82.5.5 0 00-.492-.607H7.63a.5.5 0 00-.489.607A4.578 4.578 0 0011 20.427zM5.52 8.5a.5.5 0 00-.5-.5H3a2.5 2.5 0 00-1.5 4.5A2.5 2.5 0 003 17h2.02a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});