define("ember-svg-jar/inlined/glasses-sun-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>glasses-sun-circle</title><path d=\"M24 15.25a5.25 5.25 0 00-6.816-5.011.5.5 0 01-.422-.058l-2.6-1.687a.25.25 0 010-.42l3.864-2.507a.489.489 0 01.378-.049.5.5 0 01.3.232 1 1 0 101.731-1 2.5 2.5 0 00-3.458-.887l-4.706 3.053a.5.5 0 01-.544 0l-4.75-3.08a2.5 2.5 0 00-3.414.914 1 1 0 101.731 1 .5.5 0 01.3-.232.481.481 0 01.334.022l3.911 2.535a.25.25 0 010 .419l-2.6 1.687a.5.5 0 01-.422.058 5.25 5.25 0 103.68 5.224.5.5 0 01.242-.409A2.487 2.487 0 0112 14.75a2.56 2.56 0 011.251.292.5.5 0 01.252.415A5.25 5.25 0 0024 15.25zM5.25 18.5a3.25 3.25 0 113.25-3.25 3.254 3.254 0 01-3.25 3.25zM12 12.75a4.885 4.885 0 00-1.539.238.5.5 0 01-.6-.236 5.261 5.261 0 00-.577-.851.5.5 0 01.113-.74l2.325-1.508a.5.5 0 01.545 0l2.333 1.508a.5.5 0 01.113.74 5.252 5.252 0 00-.577.85.5.5 0 01-.6.236A4.9 4.9 0 0012 12.75zm6.75 5.75A3.25 3.25 0 1122 15.25a3.254 3.254 0 01-3.25 3.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});