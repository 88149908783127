define("ember-svg-jar/inlined/space-truck-satelite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>space-truck-satelite</title><path d=\"M13.5 10.546a.25.25 0 00-.4.083l-2.032 4.72a.25.25 0 01-.23.151h-.588a.25.25 0 01-.25-.25V9.5a1 1 0 00-1-1H5.5a1.424 1.424 0 00-1.18.7l-2.22 4-1.6 1.6A1.872 1.872 0 000 16v4.5a1 1 0 001 1h.76a.249.249 0 00.249-.231 3 3 0 015.982 0 .25.25 0 00.249.231h7.52a.249.249 0 00.249-.231 3 3 0 015.982 0 .25.25 0 00.249.231h1.26a.5.5 0 00.5-.5v-4.5a1 1 0 00-1-1h-6.226a.25.25 0 01-.249-.271l.226-2.624a.25.25 0 00-.176-.261 7.8 7.8 0 01-3.075-1.798zm-8.242 2.565a.5.5 0 01-.459-.7l.738-1.722a.5.5 0 01.459-.3H7.5a.5.5 0 01.5.5v1.722a.5.5 0 01-.5.5zM15.474.793A1.066 1.066 0 0014.722.5 1 1 0 0014 .863a6.295 6.295 0 008.864 8.865 1 1 0 00.07-1.478zM21.353 4.639a.5.5 0 00.836-.225l.777-2.914a.789.789 0 00-.18-.785A.792.792 0 0022 .533l-2.914.777a.5.5 0 00-.224.837z\"/><circle cx=\"5\" cy=\"21.5\" r=\"2\"/><circle cx=\"19\" cy=\"21.5\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});