define("ember-svg-jar/inlined/decoration-bottle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>decoration-bottle-1</title><path d=\"M16.65 15.176c-.656-.751-1.275-1.46-1.275-2.026a2.244 2.244 0 01.421-1.108 4.331 4.331 0 00.729-2.342 4.288 4.288 0 00-1.26-2.612 3.549 3.549 0 01-1.04-1.988 1.8 1.8 0 011.565-1.7 1.725 1.725 0 00-.419-3.4H8.629a1.725 1.725 0 00-.419 3.4 1.8 1.8 0 011.565 1.7 3.549 3.549 0 01-1.04 1.988A4.288 4.288 0 007.475 9.7a4.331 4.331 0 00.725 2.342 2.235 2.235 0 01.421 1.108c0 .566-.619 1.275-1.275 2.026-.969 1.11-2.175 2.491-2.175 4.3A4.4 4.4 0 009.7 24h4.6a4.4 4.4 0 004.525-4.525c0-1.808-1.206-3.189-2.175-4.299z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});