define("ember-svg-jar/inlined/weather-night-snow-thunder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-night-snow-thunder</title><path d=\"M23.523 10.338a4.5 4.5 0 010-7.676 1 1 0 000-1.7 6.5 6.5 0 00-9.174 2.558.25.25 0 00-.008.212.246.246 0 00.157.142 8.5 8.5 0 011.356.552.251.251 0 00.333-.1 4.492 4.492 0 014.444-2.295.249.249 0 01.2.153.252.252 0 01-.037.252A6.462 6.462 0 0019.375 6.5a6.615 6.615 0 00.211 1.658.33.33 0 00.019.049 8.443 8.443 0 01.54 1.363.226.226 0 00.021.048 6.3 6.3 0 00.632.948.25.25 0 01-.168.4.193.193 0 00-.17.21v.011a.25.25 0 00.075.155 6.51 6.51 0 011.034 1.308.25.25 0 00.281.112 6.459 6.459 0 001.672-.725 1 1 0 000-1.7z\"/><path d=\"M19.094 12.074a.252.252 0 01-.1-.193A7 7 0 005.338 9.84a.249.249 0 01-.22.173A5.5 5.5 0 001.952 19.7a.25.25 0 00.384-.076 3.012 3.012 0 01.9-1.049.25.25 0 00.014-.394 3.5 3.5 0 012.7-6.151 1 1 0 001.114-.83A5 5 0 0117 12c0 .146-.007.289-.02.433a1 1 0 00.5.959 3 3 0 01.8 4.55.25.25 0 00.076.385 3.026 3.026 0 011.055.9.25.25 0 00.392.014 4.993 4.993 0 00-.713-7.163z\"/><circle cx=\"7\" cy=\"23\" r=\"1\"/><circle cx=\"5\" cy=\"21\" r=\"1\"/><circle cx=\"15\" cy=\"23\" r=\"1\"/><circle cx=\"17\" cy=\"21\" r=\"1\"/><circle cx=\"7\" cy=\"19\" r=\"1\"/><circle cx=\"15\" cy=\"19\" r=\"1\"/><path d=\"M12.461 22.092a.75.75 0 00-.011-1.192l-.953-.714a.25.25 0 010-.4l1.217-.946a.75.75 0 10-.922-1.184l-2.25 1.75A.75.75 0 009.55 20.6l.953.714a.25.25 0 010 .4l-1.217.946a.75.75 0 00.922 1.184z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});