define("ember-svg-jar/inlined/pencil-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g stroke-linecap=\"round\" stroke-width=\"1.5\" stroke=\"#000\" fill=\"none\" stroke-linejoin=\"round\"><path d=\"M22.19 1.81h0a3.639 3.639 0 00-5.17.035l-14.5 14.5L.75 23.25l6.905-1.771 14.5-14.5h0a3.637 3.637 0 00.035-5.169zM16.61 2.26l5.13 5.13M2.52 16.34l5.14 5.13\"/></g>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});