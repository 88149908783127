define("ember-svg-jar/inlined/toys-castle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toys-castle</title><path d=\"M22 20H2a2 2 0 00-2 2v1.5a.5.5 0 00.5.5h23a.5.5 0 00.5-.5V22a2 2 0 00-2-2zM1.5 6H7a1.5 1.5 0 001.5-1.5v-3A1.5 1.5 0 007 0h-.75A1.251 1.251 0 005 1.25a.251.251 0 01-.25.25h-1a.251.251 0 01-.25-.25A1.251 1.251 0 002.25 0H1.5A1.5 1.5 0 000 1.5v3A1.5 1.5 0 001.5 6zM14 19h1.5a.5.5 0 00.5-.5V10a.5.5 0 00-.5-.5h-7a.5.5 0 00-.5.5v8.5a.5.5 0 00.5.5H10a.5.5 0 00.5-.5V15a1.5 1.5 0 013 0v3.5a.5.5 0 00.5.5zM2 7a.5.5 0 00-.5.5v11a.5.5 0 00.5.5h4.5a.5.5 0 00.5-.5v-11a.5.5 0 00-.5-.5zm2.3 5.5a1 1 0 111-1 1 1 0 01-1 1zM22.5 0h-.75a1.251 1.251 0 00-1.25 1.25.251.251 0 01-.25.25h-1a.251.251 0 01-.25-.25A1.251 1.251 0 0017.75 0H17a1.5 1.5 0 00-1.5 1.5v3A1.5 1.5 0 0017 6h5.5A1.5 1.5 0 0024 4.5v-3A1.5 1.5 0 0022.5 0zM22 19a.5.5 0 00.5-.5v-11A.5.5 0 0022 7h-4.5a.5.5 0 00-.5.5v11a.5.5 0 00.5.5zm-2.3-8.5a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});