define("ember-svg-jar/inlined/road-sign-traffic-priority-left-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-traffic-priority-left-alternate</title><path d=\"M12.962 9.384a.5.5 0 00-.108-.545l-2.147-2.147a1 1 0 00-1.414 0L7.146 8.839a.5.5 0 00.354.853h.75a.25.25 0 01.25.25V15.5a1 1 0 001 1h1a1 1 0 001-1V9.942a.25.25 0 01.25-.25h.75a.5.5 0 00.462-.308z\"/><rect x=\"14\" y=\"13.192\" width=\"3\" height=\"3.308\" rx=\"1\" ry=\"1\"/><path d=\"M17.5 10.692h-4a1 1 0 000 2h4a1 1 0 000-2z\"/><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});