define("ember-svg-jar/inlined/performance-phone-increase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>performance-phone-increase</title><path d=\"M15.441 11.33a.251.251 0 01-.362.053l-.487-.389a2 2 0 00-3.038.666l-1.226 2.454a.165.165 0 01-.119.087.162.162 0 01-.14-.046 2 2 0 00-3.077.306l-.477.716a1 1 0 001.664 1.11l.352-.53a.183.183 0 01.135-.081.176.176 0 01.147.053 2 2 0 003.2-.521L13.2 12.83a.25.25 0 01.379-.083l.457.365a2 2 0 002.894-.424l2.3-3.327a.25.25 0 01.382-.035l.527.528A.5.5 0 0020.5 10a.489.489 0 00.191-.038A.5.5 0 0021 9.5v-3a.5.5 0 00-.5-.5h-3a.5.5 0 00-.354.854l.676.675a.253.253 0 01.029.32z\"/><path d=\"M17 16a1 1 0 00-2 0v2.25a.249.249 0 01-.25.25h-9.5a.249.249 0 01-.25-.25V5.75a.25.25 0 01.25-.25H16a1 1 0 001-1V3a3 3 0 00-3-3H6a3 3 0 00-3 3v18a3 3 0 003 3h8a3 3 0 003-3zm-7 4a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});