define("ember-svg-jar/inlined/monitor-heart-rate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-heart-rate</title><path d=\"M6.447 7.977l1.745-2.784a1.5 1.5 0 012.762.24l1.58 4.18a.249.249 0 00.475-.026l1.523-5.878a1.482 1.482 0 011.4-1.146 1.506 1.506 0 011.5 1.084l1.413 4.33H23.5a.5.5 0 00.5-.5V2.5A2.5 2.5 0 0021.5 0h-19A2.5 2.5 0 000 2.5v4.977a.5.5 0 00.5.5z\"/><path d=\"M18.481 9.977a1.507 1.507 0 01-1.443-1.09l-.728-2.232a.25.25 0 00-.48.015l-1.472 5.683a1.483 1.483 0 01-1.385 1.147h-.077a1.5 1.5 0 01-1.426-1.049l-1.878-4.97a.251.251 0 00-.446-.045l-1.3 2.072A1 1 0 017 9.977H.5a.5.5 0 00-.5.5V21.5A2.5 2.5 0 002.5 24h19a2.5 2.5 0 002.5-2.5V10.477a.5.5 0 00-.5-.5zM6.5 20.9a.75.75 0 01-1.5 0v-1.65a.75.75 0 011.5 0zm4 0a.75.75 0 01-1.5 0v-5.15a.75.75 0 011.5 0zm4 0a.75.75 0 01-1.5 0v-1.65a.75.75 0 011.5 0zm4.5 0a.75.75 0 01-1.5 0v-5.15a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});