define("ember-svg-jar/inlined/couple-hold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>couple-hold</title><path d=\"M4.5 9.5h.473a.5.5 0 01.5.45l.41 4.1a.5.5 0 00.5.45h1.239a.5.5 0 00.5-.45l.2-1.972a.5.5 0 00-.136-.4l-.672-.7a3.514 3.514 0 01.985-5.632.251.251 0 00.141-.215.248.248 0 00-.124-.226A3 3 0 004 7.5V9a.5.5 0 00.5.5z\"/><path d=\"M15.787 7.615a2.017 2.017 0 00-3.23-.524l-.2.2a.5.5 0 01-.708 0l-.2-.2a2.017 2.017 0 10-2.858 2.852l3.048 3.181a.5.5 0 00.722 0l3.048-3.181a2.017 2.017 0 00.378-2.328zM4.124 15.836a1.4 1.4 0 00-.458 1.823L5 19.876a.5.5 0 01-.857.516l-1.352-2.25a2.472 2.472 0 01.125-2.485.5.5 0 00.084-.276V12.5a1.5 1.5 0 00-3 0v5.171a4.5 4.5 0 00.986 2.812l2.664 3.329a.5.5 0 00.39.188H8a.5.5 0 00.5-.5v-3.01a4.492 4.492 0 00-.757-2.5c-1.887-1.965-2.477-2.916-3.619-2.154zM22.5 11a1.5 1.5 0 00-1.5 1.5v2.881a.5.5 0 00.084.276 2.472 2.472 0 01.125 2.485l-1.352 2.25a.5.5 0 01-.857-.516l1.334-2.217a1.4 1.4 0 00-.458-1.823c-1.142-.762-1.732.189-3.619 2.157a4.492 4.492 0 00-.757 2.5V23.5a.5.5 0 00.5.5h3.96a.5.5 0 00.39-.188l2.664-3.329A4.5 4.5 0 0024 17.671V12.5a1.5 1.5 0 00-1.5-1.5z\"/><circle cx=\"7\" cy=\"2\" r=\"2\"/><path d=\"M15.49 4.908a.248.248 0 00-.124.226.251.251 0 00.141.215 3.514 3.514 0 01.985 5.632l-.672.7a.5.5 0 00-.136.4l.2 1.972a.5.5 0 00.5.45h1.244a.5.5 0 00.5-.45l.41-4.1a.5.5 0 01.5-.45h.462A.5.5 0 0020 9V7.5a3 3 0 00-4.51-2.592z\"/><circle cx=\"17\" cy=\"2\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});