define("ember-svg-jar/inlined/shipment-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-heart</title><path d=\"M9.28 13.279a4.987 4.987 0 016.137-2.488.248.248 0 00.227-.032.251.251 0 00.106-.2V6a.25.25 0 00-.25-.25H1A.25.25 0 00.749 6v9a1.5 1.5 0 001.5 1.5h6.307a.25.25 0 00.248-.284 5.025 5.025 0 01.476-2.937zM9 4a.25.25 0 00.25.25h5.35a.25.25 0 00.176-.427L11.9.939A1.513 1.513 0 0010.835.5H9.249A.25.25 0 009 .75zM7.249 4.25A.25.25 0 007.5 4V.75A.25.25 0 007.249.5H5.663A1.511 1.511 0 004.6.939L1.719 3.823a.25.25 0 00.181.427z\"/><path d=\"M20.289 12.046a3.483 3.483 0 00-3.057.987l-.3.3a.25.25 0 01-.354 0l-.3-.3a3.527 3.527 0 00-5.647.916 3.521 3.521 0 00.652 4.061l5.112 5.332a.5.5 0 00.722 0l5.105-5.325a3.525 3.525 0 00.658-4.068 3.488 3.488 0 00-2.591-1.903z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});