define("ember-svg-jar/inlined/arrow-rectangle-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-rectangle-up</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-3.616 15.384a1.251 1.251 0 01-1.768 0l-4.939-4.94a.252.252 0 00-.354 0l-4.939 4.94a1.25 1.25 0 01-1.768-1.768l5.647-5.646a1.749 1.749 0 012.475 0l5.646 5.646a1.251 1.251 0 010 1.768z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});