define("ember-svg-jar/inlined/messages-bubble-disable-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-disable-1</title><path d=\"M11.846 11.843a8.026 8.026 0 017.486-2.136 7.778 7.778 0 013.137 1.519.25.25 0 00.386-.1A7.922 7.922 0 0023.5 8a8.009 8.009 0 00-8-8H8a7.993 7.993 0 00-3 15.4v4.1a.5.5 0 00.854.354l3.734-3.734a.262.262 0 00.069-.13 7.944 7.944 0 012.189-4.147z\"/><path d=\"M19 11.17A6.512 6.512 0 0011.169 19 6.418 6.418 0 0016 23.832a6.76 6.76 0 001.49.167 6.5 6.5 0 006.341-8A6.422 6.422 0 0019 11.17zm-3.611 10.311a.247.247 0 01-.128-.183.252.252 0 01.071-.213l5.753-5.753a.249.249 0 01.4.057 4.508 4.508 0 01-6.092 6.092zm4.224-7.959a.25.25 0 01.058.4l-5.747 5.747a.252.252 0 01-.217.069.25.25 0 01-.184-.135 4.443 4.443 0 01.8-5.281 4.609 4.609 0 015.29-.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});