define("ember-svg-jar/inlined/database-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-2-alternate</title><path d=\"M12 .628c-5.084 0-10.487 1.748-10.5 4.99v12.76c0 3.248 5.409 5 10.5 5s10.5-1.752 10.5-5V5.628c0-3.248-5.409-5-10.5-5zm8.5 17.75c0 1.223-3.312 3-8.5 3s-8.5-1.777-8.5-3v-.789a.249.249 0 01.372-.217A17.587 17.587 0 0012 19.128a17.585 17.585 0 008.127-1.756.249.249 0 01.249 0 .252.252 0 01.124.215zm0-4.25c0 1.223-3.312 3-8.5 3s-8.5-1.777-8.5-3v-.789a.249.249 0 01.372-.217A17.587 17.587 0 0012 14.878a17.585 17.585 0 008.127-1.756.249.249 0 01.249 0 .252.252 0 01.124.215zm0-4.25c0 1.223-3.312 3-8.5 3s-8.5-1.777-8.5-3v-.789a.249.249 0 01.372-.218A17.574 17.574 0 0012 10.628a17.582 17.582 0 008.127-1.756.25.25 0 01.373.218zm0-4.25c-.011 1.224-3.319 3-8.5 3s-8.5-1.777-8.5-3 3.312-3 8.5-3 8.5 1.773 8.5 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});