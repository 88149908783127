define("ember-svg-jar/inlined/baggage-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baggage-question</title><path d=\"M2.579 8.516a2.437 2.437 0 00-2.456 2.412v10.044a2.437 2.437 0 002.456 2.413h18.587a2.438 2.438 0 002.457-2.413V10.928a2.438 2.438 0 00-2.457-2.412zm9.294 12.216a.957.957 0 11.978-.956.967.967 0 01-.978.956zm.913-3.956a.249.249 0 00-.163.234v.136a.75.75 0 01-1.5 0v-.53a1.235 1.235 0 01.984-1.2 1.049 1.049 0 10-1.318-1.016.75.75 0 01-1.5 0 2.584 2.584 0 113.5 2.38zM23.044 1.549A1.923 1.923 0 0020.456.86l-1.772 1a1.225 1.225 0 00-.625.986L18 3.777a.249.249 0 01-.127.2l-1.582.891a.257.257 0 01-.192.022.251.251 0 01-.15-.121 2.966 2.966 0 00-2.609-1.537h-2.935a2.938 2.938 0 00-2.956 2.914v1.185a.25.25 0 00.25.25H9.2a.25.25 0 00.25-.25V6.146a.948.948 0 01.956-.914h2.934a.938.938 0 01.957.914v1.185a.25.25 0 00.25.25h1.5a.25.25 0 00.25-.25V7.3a.251.251 0 01.127-.218l2.448-1.377A.25.25 0 0119.1 5.7l.882.429a1.251 1.251 0 001.161-.029l1.772-1a1.862 1.862 0 00.9-1.148 1.84 1.84 0 00-.192-1.427z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});