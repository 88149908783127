define("ember-svg-jar/inlined/police-hat-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-hat-2</title><path d=\"M19.706 18.045A.491.491 0 0019.5 18h-15a.5.5 0 00-.207.045 1.947 1.947 0 00-1.16 1.537 1.875 1.875 0 00.788 1.744 13.542 13.542 0 0016.158 0 1.875 1.875 0 00.789-1.743 1.946 1.946 0 00-1.162-1.538zM20 16.5a.5.5 0 00.5-.5v-1.054a.5.5 0 00-.387-.487l-.858-.2A28.6 28.6 0 0012 13.25a28.6 28.6 0 00-7.254 1.008l-.858.2a.5.5 0 00-.388.487V16a.5.5 0 00.5.5zM23.176 8.079a2.491 2.491 0 00-1.1-1.53L13.449.463a2.5 2.5 0 00-2.9 0L1.952 6.53a2.5 2.5 0 00-.8 3.485l2.115 2.748a.5.5 0 00.51.182L4.4 12.8a29.778 29.778 0 017.6-1.05 29.788 29.788 0 017.6 1.05l.623.146a.5.5 0 00.51-.182l2.145-2.791a2.515 2.515 0 00.298-1.894zm-8.959-1.5l.5.535a.644.644 0 01-.617 1.067l-.709-.164a.478.478 0 00-.565.328l-.213.7a.641.641 0 01-1.227 0l-.213-.7a.477.477 0 00-.565-.328l-.708.164a.644.644 0 01-.613-1.068l.5-.535a.484.484 0 000-.656l-.5-.535A.644.644 0 019.9 4.319l.709.165a.479.479 0 00.565-.328l.213-.7a.641.641 0 011.227 0l.213.7a.479.479 0 00.565.328l.709-.165a.644.644 0 01.613 1.068l-.5.535a.484.484 0 00.003.656z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});