define("ember-svg-jar/inlined/natural-disaster-flood-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>natural-disaster-flood-house</title><path d=\"M12.177 4.091a.25.25 0 00-.354 0l-8.762 8.762A.208.208 0 003 13a.5.5 0 00.5.5h17a.5.5 0 00.5-.5.208.208 0 00-.061-.147zM12 8a2 2 0 11-2 2 2 2 0 012-2zM24 18a1 1 0 00-1-1 2.772 2.772 0 01-2.43-1.428 1 1 0 00-.807-.565 1.019 1.019 0 00-.9.4A4.179 4.179 0 0115.833 17a4.176 4.176 0 01-3.022-1.586A1 1 0 0012 15a1.006 1.006 0 00-.8.407A4.179 4.179 0 018.167 17a4.176 4.176 0 01-3.023-1.586 1 1 0 00-1.714.158A2.748 2.748 0 011 17a1 1 0 000 2 4.631 4.631 0 003.279-1.3.249.249 0 01.328-.015A5.7 5.7 0 008.167 19a5.74 5.74 0 003.673-1.41.249.249 0 01.321 0A5.734 5.734 0 0015.833 19a5.692 5.692 0 003.559-1.317.25.25 0 01.329.015A4.629 4.629 0 0023 19a1 1 0 001-1zM24 23a1 1 0 00-1-1 2.772 2.772 0 01-2.43-1.428 1 1 0 00-.807-.565 1.017 1.017 0 00-.9.4A4.179 4.179 0 0115.833 22a4.176 4.176 0 01-3.022-1.586A1 1 0 0012 20a.955.955 0 00-.811.407A4.179 4.179 0 018.167 22a4.176 4.176 0 01-3.023-1.586 1.011 1.011 0 00-.9-.41 1 1 0 00-.811.568A2.748 2.748 0 011 22a1 1 0 000 2 4.629 4.629 0 003.279-1.3.25.25 0 01.329-.015A5.692 5.692 0 008.167 24a5.74 5.74 0 003.673-1.41.249.249 0 01.321 0A5.734 5.734 0 0015.833 24a5.692 5.692 0 003.559-1.317.25.25 0 01.329.015A4.629 4.629 0 0023 24a1 1 0 001-1z\"/><path d=\"M1.707 12.707L11.823 2.591a.25.25 0 01.354 0l10.116 10.116a1 1 0 001.37.042c.015-.014.03-.027.044-.042a1 1 0 000-1.414l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414c.014.015.029.028.044.042a1 1 0 001.37-.042z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});