define("ember-svg-jar/inlined/single-man-podium", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-podium</title><path d=\"M24 17a1 1 0 00-1-1H1a1 1 0 000 2h1.024a.25.25 0 01.243.189l1.263 5.053A1 1 0 004.5 24h15a1 1 0 00.97-.758l1.263-5.053a.25.25 0 01.243-.189H23a1 1 0 001-1zM6 14.5a.5.5 0 00.5.5h12a.5.5 0 00.5-.5 6.264 6.264 0 00-4.037-5.783.25.25 0 01-.043-.443 4.5 4.5 0 10-4.84 0 .25.25 0 01-.043.443A6.264 6.264 0 006 14.5zm5.75-2.123v-1.338a.75.75 0 011.5 0v1.338a.75.75 0 11-1.5 0zm-1.7-8.333a.252.252 0 01.144-.178.248.248 0 01.228.013 6.156 6.156 0 003.139.858 6.129 6.129 0 001.108-.1.249.249 0 01.292.287A2.5 2.5 0 0110 4.5a2.275 2.275 0 01.048-.456z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});