define("ember-svg-jar/inlined/single-woman-actions-wifi.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-wifi</title><path d=\"M10.447 14.449a11.51 11.51 0 011.027-.726.5.5 0 00.022-.832 7.418 7.418 0 00-4.29-1.391 7.542 7.542 0 00-7.185 5.36.5.5 0 00.479.64h8.561a.5.5 0 00.491-.6 2.494 2.494 0 01.895-2.451z\"/><circle cx=\"17.5\" cy=\"22.248\" r=\"1.75\"/><path d=\"M11.379 15.625a1 1 0 101.242 1.567 7.826 7.826 0 019.758 0 1 1 0 001.242-1.567 9.814 9.814 0 00-12.242 0z\"/><path d=\"M13.853 18.449a1 1 0 001.293 1.526 3.586 3.586 0 014.707 0 1 1 0 101.293-1.526 5.624 5.624 0 00-7.293 0zM1.566 10.442a1 1 0 001.383-.3 5.233 5.233 0 00.454-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .249.249 0 01.411.082 5.233 5.233 0 00.454.9 1 1 0 001.681-1.082 4.569 4.569 0 01-.647-2.511V5.3a5.3 5.3 0 10-10.59 0v1.248a4.573 4.573 0 01-.642 2.512 1 1 0 00.298 1.382zM7.208 8.6a2.706 2.706 0 01-2.742-2.352.5.5 0 01.324-.577 5.708 5.708 0 002.246-1.439.249.249 0 01.36 0 5.719 5.719 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.208 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});