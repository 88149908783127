define("ember-svg-jar/inlined/body-care-cream", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>body-care-cream</title><path d=\"M6.028 9.75h12.078a.5.5 0 00.391-.187c1.833-2.292 1.982-5.073.443-8.263a.963.963 0 00-.812-.545.926.926 0 00-.87.447c-.783 1.235-1.793 2.441-4.726 2.441-6.924 0-7 5.555-7 5.61a.5.5 0 00.496.497zM23 14.25H1a1 1 0 00-1 1v5.5a2.5 2.5 0 002.5 2.5h19a2.5 2.5 0 002.5-2.5v-5.5a1 1 0 00-1-1zm-3 5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h15a.5.5 0 01.5.5zM3.262 13.25h17.476a.5.5 0 00.4-.8 2.972 2.972 0 00-2.388-1.2H5.25a2.972 2.972 0 00-2.388 1.2.5.5 0 00.4.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});