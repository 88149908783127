define("ember-svg-jar/inlined/car-dashboard-f-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-dashboard-f-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M20.153 8.743a1 1 0 00-1.41.1l-6.538 7.567a.25.25 0 01-.192.087 2.01 2.01 0 102.01 2.01 1.969 1.969 0 00-.152-.75.25.25 0 01.042-.259l6.347-7.347a1 1 0 00-.107-1.408zM10.751 13.25V10.5a.25.25 0 01.25-.25h1.25a1 1 0 000-2H11a.25.25 0 01-.25-.25V6.75a.5.5 0 01.5-.5h2.5a1 1 0 000-2h-2.5a2.5 2.5 0 00-2.5 2.5v6.5a1 1 0 002 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});