define("ember-svg-jar/inlined/video-game-mario-mushroom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-mario-mushroom</title><path d=\"M13.5 18h-3a.5.5 0 00-.5.5 2 2 0 004 0 .5.5 0 00-.5-.5zM11.25 15.5v-1a.75.75 0 00-1.5 0v1a.75.75 0 001.5 0zM13.5 13.75a.75.75 0 00-.75.75v1a.75.75 0 001.5 0v-1a.75.75 0 00-.75-.75z\"/><path d=\"M22.252 5.078L14.5.545A5.962 5.962 0 0012 0a6.042 6.042 0 00-2.546.568L1.73 5.089A3.751 3.751 0 003.75 12h2.055a.5.5 0 01.5.562l-.791 6.3A3.77 3.77 0 014.238 21.4 1.5 1.5 0 005.256 24h13.488a1.5 1.5 0 001.017-2.6 3.754 3.754 0 01-1.269-2.522l-.792-6.316a.5.5 0 01.5-.562h2.05a3.751 3.751 0 002-6.922zM3 10.5a1 1 0 111-1 1 1 0 01-1 1zm2.5-5.25A1.75 1.75 0 117.25 7 1.749 1.749 0 015.5 5.25zm11.637 16.511a.5.5 0 01-.427.239H7.289a.5.5 0 01-.445-.729 6.666 6.666 0 00.648-2.146l.84-6.687a.5.5 0 01.5-.438h6.344a.5.5 0 01.5.438l.842 6.7a6.612 6.612 0 00.645 2.13.5.5 0 01-.026.493zM15.75 8.5a2.75 2.75 0 112.75-2.75 2.75 2.75 0 01-2.75 2.75zm4.75 2a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});