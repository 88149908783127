define("ember-svg-jar/inlined/movie-cinema-watch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>movie-cinema-watch</title><path d=\"M21 0H3a3 3 0 00-3 3v9a3 3 0 003 3h18a3 3 0 003-3V3a3 3 0 00-3-3zm1 12a1 1 0 01-1 1H3a1 1 0 01-1-1V3a1 1 0 011-1h18a1 1 0 011 1z\"/><path d=\"M9.542 3.786a.377.377 0 00-.409.058.574.574 0 00-.192.442v6.428a.578.578 0 00.192.443.378.378 0 00.409.057l5.574-2.949a.883.883 0 000-1.53zM4.5 18a3 3 0 00-3 3v2.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V21a3 3 0 00-3-3zM12 18a3 3 0 00-3 3v2.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V21a3 3 0 00-3-3zM19.5 18a3 3 0 00-3 3v2.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V21a3 3 0 00-3-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});