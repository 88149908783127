define("ember-svg-jar/inlined/keyboard-page-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-page-down</title><path d=\"M16.75 10.185a1.5 1.5 0 000-3h-3a.25.25 0 01-.25-.25V1.56a1.5 1.5 0 00-3 0v5.375a.25.25 0 01-.25.25h-3a1.5 1.5 0 000 3h3a.25.25 0 01.25.25v1.25a.25.25 0 01-.25.25h-3a1.5 1.5 0 000 3h3a.25.25 0 01.25.25v1.75a.25.25 0 01-.25.25h-3a1 1 0 00-.707 1.707l4.75 4.75a1 1 0 001.414 0l4.75-4.75a1 1 0 00-.707-1.707h-3a.25.25 0 01-.25-.25v-1.75a.25.25 0 01.25-.25h3a1.5 1.5 0 000-3h-3a.25.25 0 01-.25-.25v-1.25a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});