define("ember-svg-jar/inlined/toilet-unclog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toilet-unclog</title><path d=\"M10.75 9.771v4.759a.5.5 0 00.541.5 8.807 8.807 0 011.418 0 .5.5 0 00.38-.132.5.5 0 00.161-.367v-4.76a.5.5 0 01.148-.355A1.983 1.983 0 0014 8V2A2 2 0 0012.3.03a1.515 1.515 0 00-.6 0A2 2 0 0010 2v6a1.983 1.983 0 00.6 1.416.5.5 0 01.15.355zM20.5 22h-.754a.5.5 0 01-.484-.375 7.5 7.5 0 00-14.524 0 .5.5 0 01-.485.375H3.5a1 1 0 000 2h17a1 1 0 000-2zM9.915 18.954a5.031 5.031 0 00-2.37 2.274.5.5 0 01-.891-.456A6.042 6.042 0 019.5 18.045a.5.5 0 11.417.909z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});