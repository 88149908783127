define("ember-svg-jar/inlined/railroad-locomotive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>railroad-locomotive</title><path d=\"M22.041 15.665H1.959A1.961 1.961 0 000 17.624a.98.98 0 001.94.2.244.244 0 01.239-.2h.27a.245.245 0 01.245.245 2.449 2.449 0 004.9 0 .246.246 0 01.245-.245h.49a.245.245 0 01.244.245 2.449 2.449 0 004.9 0 .246.246 0 01.245-.245h.8a.245.245 0 01.2.385 1.47 1.47 0 102.411 0 .243.243 0 01-.017-.253.246.246 0 01.218-.132h1.1a.244.244 0 01.2.385 1.47 1.47 0 102.411 0 .245.245 0 01.2-.385h.578a.244.244 0 01.239.2.98.98 0 001.94-.2 1.961 1.961 0 00-1.957-1.959zM2.776 5.641h.163a.245.245 0 01.245.245v1.869a.244.244 0 01-.14.221A1.465 1.465 0 002.2 9.3v4.9a.49.49 0 00.49.49h3.922a.49.49 0 00.49-.49V9.3a1.465 1.465 0 00-.84-1.322.244.244 0 01-.14-.221V5.886a.245.245 0 01.245-.245h.327a.98.98 0 100-1.959H2.776a.98.98 0 100 1.959zM8.571 14.685h13.715a.49.49 0 00.49-.49v-3.918a1.472 1.472 0 00-1.47-1.469h-.245a.245.245 0 01-.245-.245V6.376a.245.245 0 01.245-.245h.327a.98.98 0 100-1.959h-9.8a.98.98 0 100 1.959h.163a.245.245 0 01.249.245v2.187a.245.245 0 01-.245.245H8.571a.489.489 0 00-.489.49v4.9a.489.489 0 00.489.487zm5.388-6.857a1.471 1.471 0 011.47-1.469h1.959a1.471 1.471 0 011.469 1.469v2.939a.49.49 0 01-.49.49h-3.918a.49.49 0 01-.49-.49z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});