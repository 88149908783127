define("ember-svg-jar/inlined/landmark-london-bridge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-london-bridge</title><path d=\"M1.34 20.481c.54.07 1.41-.62 1.81-1.13a2.564 2.564 0 011.97-.95 2.474 2.474 0 011.148.282A.5.5 0 007 18.24v-.29a.5.5 0 01.315-.464l3.3-1.315a1 1 0 00-.74-1.86l-4.106 1.644a.491.491 0 01-.185.036H1a1 1 0 00-1 1V20a.5.5 0 00.586.493zM23.415 20.456a.5.5 0 00.585-.493v-2.972a1 1 0 00-1-1h-4.584a.493.493 0 01-.186-.036l-4.11-1.644a.994.994 0 00-1.3.56 1.011 1.011 0 00.56 1.3l3.3 1.315a.5.5 0 01.32.464v.524a.5.5 0 00.828.378 2 2 0 011.442-.452 2.483 2.483 0 012.14 1.2c.11.16.71.91 1.25.84zM2 4.489h3.5a.5.5 0 00.447-.724L4.2.265a.52.52 0 00-.894 0l-1.75 3.5A.5.5 0 002 4.489z\"/><rect x=\"1.5\" y=\"5.489\" width=\"4.5\" height=\"9\" rx=\".5\" ry=\".5\"/><rect x=\"18\" y=\"5.489\" width=\"4.5\" height=\"9\" rx=\".5\" ry=\".5\"/><path d=\"M18.5 4.489H22a.5.5 0 00.447-.724L20.7.265a.521.521 0 00-.895 0l-1.75 3.5a.5.5 0 00.445.724z\"/><rect x=\"7\" y=\"8.489\" width=\"10\" height=\"3\" rx=\".5\" ry=\".5\"/><path d=\"M22.864 21.929c-1.622.217-2.729-1.536-2.736-1.55a1.048 1.048 0 00-1.728.021 3.278 3.278 0 01-2.673 1.553 2.731 2.731 0 01-2.327-1.477 1.044 1.044 0 00-1.722-.159 4.173 4.173 0 01-3.021 1.637 3.518 3.518 0 01-2.679-1.575 1.043 1.043 0 00-1.649-.093c-.016.019-1.506 1.9-3.19 1.684a1 1 0 10-.27 1.981 4.013 4.013 0 00.552.038 5.537 5.537 0 003.478-1.448.25.25 0 01.331.006 5.041 5.041 0 003.427 1.407 5.622 5.622 0 003.553-1.343.25.25 0 01.334.015 4.43 4.43 0 003.189 1.328 4.976 4.976 0 003.378-1.327.251.251 0 01.337 0 4.5 4.5 0 003.691 1.281 1 1 0 10-.271-1.981z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});