define("ember-svg-jar/inlined/messages-bubble-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-edit</title><path d=\"M18.009 10.387a4.232 4.232 0 013.735-1.165.219.219 0 00.256-.216c0-4.963-4.935-9-11-9s-11 4.037-11 9a8.08 8.08 0 002.658 5.854l-1.627 4.475a.5.5 0 00.662.632l5.832-2.43a13.151 13.151 0 002.773.443.249.249 0 00.19-.073zM23.227 11.76a2.62 2.62 0 00-3.7 0l-6.679 6.68a.25.25 0 000 .353l3.352 3.354a.25.25 0 00.353 0l6.679-6.68a2.621 2.621 0 00-.005-3.707zM11.6 23.985l3.143-.629a.25.25 0 00.128-.422l-2.809-2.808a.249.249 0 00-.249-.062.253.253 0 00-.173.19l-.629 3.146a.5.5 0 00.588.588z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});