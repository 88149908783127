define("ember-svg-jar/inlined/trip-road-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trip-road-1</title><path d=\"M18.421 16.535a.5.5 0 00-.4-.3 1.707 1.707 0 01-1.008-.5c-.555-.554-5.153-5.264-5.7-9.238a.07.07 0 00-.139.01V9.48a.75.75 0 01-1.5 0v-2.6a.5.5 0 00-.5-.5h-1.54a1.4 1.4 0 00-1.334.952L.067 22.143A1.422 1.422 0 001.423 24h7.75a.5.5 0 00.5-.5v-2.48a.75.75 0 011.5 0v2.48a.5.5 0 00.5.5h7.75a1.42 1.42 0 001.339-1.9zm-7.248.33a.75.75 0 01-1.5 0v-3.23a.75.75 0 011.5 0z\"/><path d=\"M18.25 15a.5.5 0 00.354-.146c.22-.221 5.4-5.439 5.4-9.1a5.75 5.75 0 00-11.5 0c0 3.665 5.176 8.883 5.4 9.1a.5.5 0 00.346.146zm-2-9.25a2 2 0 112 2 2 2 0 01-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});