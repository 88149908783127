define("ember-svg-jar/inlined/style-three-pin-cargo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-cargo</title><circle cx=\"8.5\" cy=\"14\" r=\"1\"/><circle cx=\"8.5\" cy=\"14\" r=\"1\"/><path d=\"M14.25 10a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5z\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm-1.5 8a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1zm6.85 7.85a.483.483 0 01-.7 0l-.86-.85h-5.412a.247.247 0 00-.207.111 2 2 0 11-1.893-3.1.249.249 0 00.222-.248V5.5A.5.5 0 008 5H7a.5.5 0 01-.5-.5A.5.5 0 017 4h1a1.5 1.5 0 011.5 1.5v6.632a.25.25 0 00.111.207 2 2 0 01.877 1.439.249.249 0 00.248.222H16a.469.469 0 01.35.15l1 1a.483.483 0 010 .7z\"/><path d=\"M15 9.25a.749.749 0 01-.75.75h-1.5a.75.75 0 010-1.5h1.5a.755.755 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});