define("ember-svg-jar/inlined/brain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>brain</title><path d=\"M23.064 9.715a.5.5 0 00-.673-.065l-1.452 1.093a.487.487 0 00-.037.03l-.039.027-1.656 1.247a.221.221 0 00-.088.189 1.31 1.31 0 01-.009.272 1.5 1.5 0 11-1.274-1.7 1.354 1.354 0 01.265.067.214.214 0 00.2-.031l.7-.524a.5.5 0 00.1-.7l-.86-1.145a.5.5 0 00-.392-.2 1.4 1.4 0 01-.156-.012 1.5 1.5 0 111.7-1.274 1.537 1.537 0 01-.032.153.5.5 0 00.082.432L20.3 8.72a.5.5 0 00.7.1l1.257-.946a.5.5 0 00.191-.312A3.173 3.173 0 0022.5 7 3.5 3.5 0 0019 3.5a.008.008 0 01-.009-.008A3.755 3.755 0 0015.25 0a3.714 3.714 0 00-2.31.807.5.5 0 00-.19.393V10a.75.75 0 01-1.5 0V1.2a.5.5 0 00-.19-.393A3.714 3.714 0 008.75 0a3.755 3.755 0 00-3.741 3.492A.008.008 0 015 3.5 3.5 3.5 0 001.5 7a3.173 3.173 0 00.053.561.5.5 0 00.191.312L3 8.82a.5.5 0 00.7-.1l.86-1.142a.5.5 0 00.082-.433 1.479 1.479 0 01-.032-.153 1.5 1.5 0 111.7 1.274 1.4 1.4 0 01-.156.012.5.5 0 00-.392.2L4.9 9.623a.5.5 0 00.1.7l.7.524a.217.217 0 00.2.032 1.288 1.288 0 01.265-.068 1.5 1.5 0 11-1.274 1.7 1.374 1.374 0 01-.01-.271.218.218 0 00-.088-.19l-1.685-1.271-.01-.006a.429.429 0 00-.035-.028L1.609 9.65a.5.5 0 00-.673.065A3.484 3.484 0 000 12a3.668 3.668 0 001.651 2.916.5.5 0 01.171.623 3.475 3.475 0 001.942 4.718.5.5 0 00.531-.114l1.675-1.674a.218.218 0 00.059-.2A1.329 1.329 0 016 18a1.5 1.5 0 111.5 1.5 1.279 1.279 0 01-.271-.03.219.219 0 00-.2.06l-1.618 1.619a.5.5 0 00-.111.551 3.721 3.721 0 005.755 1.5.5.5 0 00.191-.393V20.5a.75.75 0 011.5 0v2.3a.5.5 0 00.191.393A3.721 3.721 0 0018.7 21.7a.5.5 0 00-.107-.547L16.97 19.53a.222.222 0 00-.2-.06 1.279 1.279 0 01-.271.03A1.5 1.5 0 1118 18a1.329 1.329 0 01-.029.27.218.218 0 00.059.2l1.67 1.674a.5.5 0 00.531.114 3.475 3.475 0 001.942-4.718.5.5 0 01.171-.623A3.668 3.668 0 0024 12a3.484 3.484 0 00-.936-2.285z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});