define("ember-svg-jar/inlined/paginate-filter-10", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paginate-filter-10</title><path d=\"M15.75 8.5v3a1.75 1.75 0 003.5 0v-3a1.75 1.75 0 00-3.5 0z\"/><path d=\"M22 0H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2V2a2 2 0 00-2-2zm-1.25 8.5v3a3.25 3.25 0 01-6.5 0v-3a3.25 3.25 0 016.5 0zM8.968 8.045a.75.75 0 11-.936-1.172l1.688-1.35a1.25 1.25 0 012.03.977V13a.25.25 0 00.25.25h.5a.75.75 0 010 1.5h-4a.75.75 0 010-1.5H10a.25.25 0 00.25-.25V7.02z\"/><path d=\"M19.5 22h-17a.5.5 0 01-.5-.5v-17a1 1 0 00-2 0V22a2 2 0 002 2h17.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});