define("ember-svg-jar/inlined/cellular-network-wifi-4g", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cellular-network-wifi-4g</title><path d=\"M15.205 11.853a1 1 0 101.58-1.225 6.058 6.058 0 00-9.58 0 1 1 0 101.58 1.225 4.06 4.06 0 016.42 0z\"/><path d=\"M12 4.142a9.309 9.309 0 00-7.286 3.485A1 1 0 106.27 8.882a7.352 7.352 0 0111.445-.007 1 1 0 101.554-1.257A9.311 9.311 0 0012 4.142z\"/><path d=\"M21.8 4.747a12.505 12.505 0 00-19.6-.013 1 1 0 101.565 1.243 10.5 10.5 0 0116.469.011A1 1 0 0021.8 4.747zM10.016 14a6.006 6.006 0 00-6 6v1a1 1 0 001 1h4v1a1 1 0 002 0v-8a1 1 0 00-1-1zm-1 5.75a.25.25 0 01-.25.25H6.274a.253.253 0 01-.183-.079.256.256 0 01-.067-.188 4.012 4.012 0 012.658-3.5.251.251 0 01.334.236zM16.016 16a1 1 0 011 1 1 1 0 002 0 3 3 0 00-6 0v4a3 3 0 006 0v-1a1 1 0 00-1-1h-1.5a1 1 0 100 2h.5a1 1 0 01-2 0v-4a1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});