define("ember-svg-jar/inlined/swimming-waterpolo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>swimming-waterpolo</title><circle cx=\"13.5\" cy=\"9.5\" r=\"2.5\"/><path d=\"M14.735 19.838a1.281 1.281 0 001.051.627 1.842 1.842 0 001.489-.867.251.251 0 00.016-.251L16.131 17H17.5a1.507 1.507 0 011.443 1.088l.066.17a.249.249 0 00.247.159 2.538 2.538 0 012.2 1.049.25.25 0 00.39.013 1.5 1.5 0 00.306-1.391l-.32-.824A4.52 4.52 0 0017.5 14h-5.078a1.5 1.5 0 01-1.354-.858l-2.7-5.269a1.5 1.5 0 00-2.731 1.254l4.991 9.681A.251.251 0 0011 18.9a2.5 2.5 0 013.737.942z\"/><circle cx=\"7\" cy=\"3\" r=\"3\"/><path d=\"M22.93 21.954a3.378 3.378 0 01-2.675-1.569 1.049 1.049 0 00-1.716.022 3.372 3.372 0 01-2.753 1.558 2.8 2.8 0 01-2.4-1.482 1.044 1.044 0 00-1.717-.156 4.3 4.3 0 01-3.105 1.638 3.557 3.557 0 01-2.764-1.58 1.042 1.042 0 00-1.638-.091c-.022.028-1.51 1.822-3.11 1.7A.984.984 0 000 22.942C-.009 23.13.043 24 1.169 24A5.838 5.838 0 004.9 22.393a5.242 5.242 0 003.663 1.572 5.863 5.863 0 003.811-1.5 4.576 4.576 0 003.416 1.5 5.155 5.155 0 003.622-1.5 4.787 4.787 0 003.661 1.482A1 1 0 0024 22.883a1.015 1.015 0 00-1.07-.929z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});