define("ember-svg-jar/inlined/diagram-arrow-dash-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-arrow-dash-up</title><path d=\"M5 19a1 1 0 00-1 1v3a1 1 0 001 1h14a1 1 0 001-1v-3a1 1 0 00-1-1h-3.75a.25.25 0 01-.25-.25V9.035a.25.25 0 01.25-.25h3.25a1 1 0 00.733-1.685L13.074.473a1.457 1.457 0 00-2.147 0L4.767 7.1A1 1 0 005.5 8.785h3.25a.25.25 0 01.25.25v9.715a.25.25 0 01-.25.25z\"/><rect x=\"21.5\" y=\"19\" width=\"2.5\" height=\"5\" rx=\".5\" ry=\".5\"/><rect y=\"19\" width=\"2.5\" height=\"5\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});