define("ember-svg-jar/inlined/history-spiral", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-spiral</title><path d=\"M23.859 19.137a6.586 6.586 0 00-.954-4.991 5.783 5.783 0 00-3.826-2.72c-2.423-.462-5.4.649-6.713 1.225a.25.25 0 01-.249-.028c-1-.743-3.052-2.435-3.675-4.22a4.286 4.286 0 01.22-3.342 4.582 4.582 0 012.675-2.311 3.4 3.4 0 014.287 2.184 2.523 2.523 0 01-1.617 3.177 1.821 1.821 0 01-2.291-1.167 1.255 1.255 0 01.8-1.58.8.8 0 01.554.019 1 1 0 10.749-1.85 2.822 2.822 0 00-1.92-.068 3.253 3.253 0 00-2.086 4.1 3.819 3.819 0 004.808 2.449 4.524 4.524 0 002.9-5.694 5.4 5.4 0 00-6.8-3.466 6.6 6.6 0 00-3.849 3.32 6.275 6.275 0 00-.316 4.888c.813 2.329 3.26 4.346 4.417 5.2a.249.249 0 01.1.23c-.143 1.238-.582 3.862-1.816 5.294a4.284 4.284 0 01-3 1.48 4.088 4.088 0 01-3.132-1.161 3.407 3.407 0 01-.253-4.8 2.526 2.526 0 013.561-.187 1.817 1.817 0 01.136 2.567 1.258 1.258 0 01-1.771.095.8.8 0 01-.26-.49 1 1 0 00-1.977.275 2.818 2.818 0 00.9 1.7 3.258 3.258 0 004.592-.243 3.814 3.814 0 00-.284-5.387 4.518 4.518 0 00-6.381.335 5.4 5.4 0 00.4 7.625 6.088 6.088 0 004.182 1.677c.137 0 .273 0 .41-.013a6.267 6.267 0 004.39-2.169c1.61-1.869 2.134-5 2.3-6.425a.249.249 0 01.149-.2c1.145-.494 3.641-1.425 5.493-1.076a3.815 3.815 0 012.536 1.863 4.582 4.582 0 01.662 3.471 3.4 3.4 0 01-4.034 2.621 2.52 2.52 0 01-1.943-2.99 1.816 1.816 0 012.155-1.4 1.251 1.251 0 01.967 1.488.787.787 0 01-.291.466 1 1 0 101.224 1.577A2.8 2.8 0 0021 18.855 3.251 3.251 0 0018.5 15a3.817 3.817 0 00-4.524 2.939 4.522 4.522 0 003.48 5.361 5.459 5.459 0 001.133.119 5.4 5.4 0 005.27-4.279z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});