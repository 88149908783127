define("ember-svg-jar/inlined/single-woman-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-heart</title><path d=\"M23.627 1.951a3.526 3.526 0 00-5.646-.916l-.305.305a.252.252 0 01-.354 0l-.3-.305a3.527 3.527 0 00-5.647.916 3.519 3.519 0 00.654 4.063l5.113 5.334a.5.5 0 00.722 0l5.106-5.327a3.522 3.522 0 00.657-4.07zM11.982 17.868l-1.8-6.285a4.255 4.255 0 00-1.238-2.125.5.5 0 00-.764.1l-1.755 2.76a.5.5 0 01-.846 0L3.839 9.54a.5.5 0 00-.358-.23.5.5 0 00-.4.128 4.219 4.219 0 00-1.254 2.129l-1.8 6.3A.5.5 0 00.5 18.5h2.323a.251.251 0 01.246.2L4 23.6a.5.5 0 00.489.4h3a.5.5 0 00.49-.4l.931-4.9a.249.249 0 01.245-.2H11.5a.5.5 0 00.482-.632zM5.979 8.531a4.25 4.25 0 10-4.25-4.25 4.255 4.255 0 004.25 4.25zm0-2a2.249 2.249 0 01-2.16-1.64.249.249 0 01.16-.3A4.67 4.67 0 005.8 3.447a.25.25 0 01.357 0 4.67 4.67 0 001.821 1.14.249.249 0 01.159.3 2.247 2.247 0 01-2.158 1.644z\"/><path d=\"M23.627 1.951a3.526 3.526 0 00-5.646-.916l-.305.305a.252.252 0 01-.354 0l-.3-.305a3.527 3.527 0 00-5.647.916 3.519 3.519 0 00.654 4.063l5.113 5.334a.5.5 0 00.722 0l5.106-5.327a3.522 3.522 0 00.657-4.07zM11.982 17.868l-1.8-6.285a4.255 4.255 0 00-1.238-2.125.5.5 0 00-.764.1l-1.755 2.76a.5.5 0 01-.846 0L3.839 9.54a.5.5 0 00-.358-.23.5.5 0 00-.4.128 4.219 4.219 0 00-1.254 2.129l-1.8 6.3A.5.5 0 00.5 18.5h2.323a.251.251 0 01.246.2L4 23.6a.5.5 0 00.489.4h3a.5.5 0 00.49-.4l.931-4.9a.249.249 0 01.245-.2H11.5a.5.5 0 00.482-.632zM5.979 8.531a4.25 4.25 0 10-4.25-4.25 4.255 4.255 0 004.25 4.25zm0-2a2.249 2.249 0 01-2.16-1.64.249.249 0 01.16-.3A4.67 4.67 0 005.8 3.447a.25.25 0 01.357 0 4.67 4.67 0 001.821 1.14.249.249 0 01.159.3 2.247 2.247 0 01-2.158 1.644z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});