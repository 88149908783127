define("ember-svg-jar/inlined/disability-microphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-microphone</title><path d=\"M15.484 13.8V8.821a3.484 3.484 0 00-6.968 0V13.8a3.484 3.484 0 006.968 0z\"/><path d=\"M11.007 21.763a.248.248 0 01-.249.246H7.023a1 1 0 000 1.991h9.954a1 1 0 000-1.991h-3.735a.248.248 0 01-.249-.246c0-.247-.007-.624-.01-.86a.249.249 0 01.207-.248 6.973 6.973 0 005.777-6.855v-1a1 1 0 10-1.99 0v1a4.977 4.977 0 01-9.954 0v-1a1 1 0 10-1.99 0v1a6.973 6.973 0 005.777 6.857.249.249 0 01.207.248l-.01.858z\"/><path d=\"M22.949 23.751a.994.994 0 00.995-1V7.421a1.992 1.992 0 00-1.038-1.747L12.955.246a1.978 1.978 0 00-1.908 0L1.093 5.675A1.991 1.991 0 00.056 7.421v15.335a1 1 0 001.991 0V8.012a1 1 0 01.518-.873L12 1.993l9.435 5.146a1 1 0 01.518.873v14.744a1 1 0 00.996.995z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});