define("ember-svg-jar/inlined/cookie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cookie</title><path d=\"M22.724 6.527a1 1 0 00-1.308-.46 3.491 3.491 0 01-4.526-1.616 1 1 0 00-.582-.5.989.989 0 00-.765.059A3.376 3.376 0 0114 4.38a3.5 3.5 0 01-3.5-3.5.762.762 0 00-.913-.745A12 12 0 1024 11.88a11.8 11.8 0 00-1.276-5.353zM3.917 14.713a1 1 0 111-1 1 1 0 01-1 1zm4.75-8.166a2 2 0 11-2 2 2 2 0 012-2zM9 18.88a2 2 0 112-2 2 2 0 01-2 2zm4.667-7.75a1 1 0 111 1 1 1 0 01-1-1zM15 19.88a1 1 0 111-1 1 1 0 01-1 1zm3.5-4a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});