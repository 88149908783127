define("ember-svg-jar/inlined/sofa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sofa</title><path d=\"M22.5 19.75a1 1 0 00-1-1h-1.525a.25.25 0 01-.131-.463C22.385 16.723 24 14.376 24 11.75c0-4.69-5.16-8.5-11.5-8.5h-1C5.16 3.25 0 7.06 0 11.75c0 2.626 1.615 4.973 4.156 6.537a.25.25 0 01-.131.463H2.5a1 1 0 000 2h19a1 1 0 001-1zM4.06 13.08a.761.761 0 01.35-1.01.752.752 0 011 .35C6.03 13.7 8.49 15 12 15s5.97-1.3 6.59-2.58a.752.752 0 011-.35.764.764 0 01.35 1.01c-.99 2.04-4.18 3.42-7.94 3.42s-6.95-1.38-7.94-3.42z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});