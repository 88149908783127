define("ember-svg-jar/inlined/disability-ad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-ad</title><path d=\"M7.227 6.851a.991.991 0 00-1.108.373L.181 15.635A.99.99 0 001.8 16.777l.678-.961a.248.248 0 01.2-.105H5.69a.247.247 0 01.247.247v.248a.99.99 0 001.98 0V7.8a.992.992 0 00-.69-.949zm-1.29 6.633a.247.247 0 01-.247.248H4.425a.248.248 0 01-.2-.39l1.266-1.793a.247.247 0 01.449.142zM11.439 17.2a5.352 5.352 0 005.4-4.675 5.2 5.2 0 00-5.17-5.715h-.716A1.553 1.553 0 009.4 8.358v7.848a.989.989 0 00.989.989zm-.059-8.157a.25.25 0 01.079-.182.246.246 0 01.188-.065 3.2 3.2 0 110 6.409.247.247 0 01-.267-.247zM17.6 18.044a.99.99 0 001.358-.339 11.073 11.073 0 000-11.409.989.989 0 10-1.7 1.018 9.1 9.1 0 010 9.372.989.989 0 00.342 1.358z\"/><path d=\"M22.417 6.3a.989.989 0 10-1.7 1.018 9.093 9.093 0 010 9.372.99.99 0 001.7 1.019 11.068 11.068 0 000-11.409z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});