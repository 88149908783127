define("ember-svg-jar/inlined/sofa-double-modern", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sofa-double-modern</title><path d=\"M4.56 9.778c.01.031.96 3 3.266 3h8.347c2.306 0 3.256-2.974 3.266-3a3.121 3.121 0 011.967-2.037.5.5 0 00.314-.619c-.058-.189-.107-.36-.142-.485a8.791 8.791 0 00-.417-1.232A3.642 3.642 0 0017.9 3.392H6.1a3.642 3.642 0 00-3.262 2.013 8.791 8.791 0 00-.417 1.232c-.04.123-.091.292-.149.478a.5.5 0 00.316.623 3.111 3.111 0 011.972 2.04z\"/><path d=\"M22.884 9.2a1.564 1.564 0 00-1.947 1.043c-.052.168-1.312 4.108-4.763 4.108H7.826c-3.452 0-4.711-3.94-4.763-4.108a1.565 1.565 0 00-3 .9c.066.223 1.474 4.736 5.545 6a.251.251 0 01.141.368l-.909 1.514A1.044 1.044 0 006.634 20.1l1.428-2.38a.5.5 0 01.429-.242h7.017a.5.5 0 01.429.242l1.429 2.38a1.043 1.043 0 101.789-1.074l-.908-1.514a.25.25 0 01-.02-.218.253.253 0 01.16-.15c4.072-1.265 5.48-5.778 5.547-6a1.565 1.565 0 00-1.05-1.944z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});