define("ember-svg-jar/inlined/scroll-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>scroll-horizontal</title><circle cx=\"12.011\" cy=\"11.998\" r=\"3\"/><path d=\"M16.79 7.382l4.44 4.439a.249.249 0 010 .354l-4.44 4.439a1.251 1.251 0 001.769 1.768l4.793-4.793a2.305 2.305 0 000-3.182l-4.793-4.793a1.251 1.251 0 00-1.769 1.768zM7.232 16.614l-4.441-4.439a.251.251 0 010-.354l4.441-4.439a1.251 1.251 0 10-1.769-1.768L.671 10.406a2.25 2.25 0 000 3.183l4.793 4.793a1.251 1.251 0 101.769-1.768z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});