define("ember-svg-jar/inlined/multiple-actions-text.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-text</title><path d=\"M14.471 8.4l-.486-.172a1.8 1.8 0 01-.055-1.172 4.647 4.647 0 001.216-3.569A3.22 3.22 0 0012 0a2.994 2.994 0 00-1.342.314.25.25 0 00-.1.363 5 5 0 01.842 2.808 6.272 6.272 0 01-.922 3.625.249.249 0 00.121.371 8.525 8.525 0 013.491 1.933.251.251 0 00.189.086H16a.25.25 0 00.1-.481 47.396 47.396 0 00-1.629-.619z\"/><path d=\"M9.5 12.5a2.986 2.986 0 012.028-2.823.25.25 0 00.022-.465c-.589-.263-1.288-.523-2.079-.814l-.486-.172a1.8 1.8 0 01-.055-1.172 4.647 4.647 0 001.216-3.569A3.22 3.22 0 007 0a3.22 3.22 0 00-3.146 3.485 4.605 4.605 0 001.193 3.538 1.877 1.877 0 010 1.185l-.515.19c-2.01.739-3.46 1.272-3.914 2.179A7.542 7.542 0 000 13.5a.5.5 0 00.5.5h8.75a.25.25 0 00.25-.25zM22 11h-9a2 2 0 00-2 2v9a2 2 0 002 2h9a2 2 0 002-2v-9a2 2 0 00-2-2zm-9 2.25a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v8.5a.249.249 0 01-.25.25h-8.5a.25.25 0 01-.25-.25z\"/><path d=\"M20 15h-4.75a.75.75 0 000 1.5H20a.75.75 0 000-1.5zM17.5 19.5a.75.75 0 000-1.5h-2.25a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});