define("ember-svg-jar/inlined/send-email-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>send-email-monitor</title><path d=\"M15.355 10.272a1.865 1.865 0 01-1.364-.59l-2.273-2.45a.25.25 0 00-.426.113l-.941 3.988a.553.553 0 00.542.667H18.9a.555.555 0 00.543-.441l.905-3.84a.25.25 0 00-.386-.262l-3.545 2.481a1.837 1.837 0 01-1.062.334z\"/><path d=\"M15.557 8.71l3.223-2.255A.25.25 0 0018.636 6h-5.442a.25.25 0 00-.184.42l2.08 2.241a.359.359 0 00.467.049zM9.089 7a.75.75 0 00-.75-.75h-3.5a.75.75 0 000 1.5h3.5a.75.75 0 00.75-.75zM4.839 9.25a.75.75 0 000 1.5h2a.75.75 0 000-1.5z\"/><path d=\"M24 2.589A2.548 2.548 0 0021.5 0h-19A2.548 2.548 0 000 2.589V16.5a3 3 0 003 3h7.25a.25.25 0 01.25.25v2a.25.25 0 01-.25.25H5a1 1 0 000 2h14a1 1 0 000-2h-6.25a.25.25 0 01-.25-.25v-2a.25.25 0 01.25-.25H21a3 3 0 003-3zM2.5 15a.5.5 0 01-.5-.5V2.589A.551.551 0 012.5 2h19a.551.551 0 01.5.589V14.5a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});