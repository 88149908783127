define("ember-svg-jar/inlined/business-rabbit-hat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-rabbit-hat</title><path d=\"M8.735 2.127a1.863 1.863 0 00-1.815.585 3.38 3.38 0 00-.62 3.361c.445 1.464 1.282 3.885 1.915 5.688a.251.251 0 00.236.167h6.828a.25.25 0 00.233-.16c.206-.533.445-1.153.694-1.806a.22.22 0 00-.086-.262A4.9 4.9 0 0115 8.731a8.962 8.962 0 01-1.943-3.252.068.068 0 00-.131 0c-.182.765-.647 2.718-.881 3.754a.249.249 0 01-.488 0c-.366-1.758-.794-3.744-1.1-4.928C10.035 2.69 9.2 2.248 8.735 2.127zM18.651 9.41A1.412 1.412 0 0019.7 7.442 7.879 7.879 0 0016.748 3.9l-.038-.019a4.431 4.431 0 00-1.8-.454c-.453 0-.833.126-.957.479-.362 1.036 1.056 3.275 1.812 4.185a3.537 3.537 0 002.886 1.319zM4.206 14.428a1 1 0 001 1h1.855a.25.25 0 01.248.285l-.666 4.661a1.3 1.3 0 00.386 1.112 1.568 1.568 0 001.1.442h6.911a1.568 1.568 0 001.1-.442 1.3 1.3 0 00.385-1.112l-.666-4.66a.25.25 0 01.248-.285h2.1a1 1 0 000-2h-13a1 1 0 00-1.001.999z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});