define("ember-svg-jar/inlined/database-settings-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-settings-alternate</title><path d=\"M2.759 17.784V17a.25.25 0 01.373-.217 13.885 13.885 0 004.268 1.41.952.952 0 001.11-.978v-.061a.937.937 0 00-.761-.916c-3.124-.556-4.989-1.786-4.989-2.7v-.788a.25.25 0 01.373-.218 14.868 14.868 0 005.159 1.554.963.963 0 00.991-.587l.013-.029a.952.952 0 00-.729-1.357c-3.612-.472-5.807-1.83-5.807-2.825V8.5a.25.25 0 01.373-.218c2.471 1.4 6.157 1.849 9 1.739a.252.252 0 00.13-.043 7.606 7.606 0 014.479-1.451 5 5 0 013.7 1.249 1.007 1.007 0 001.317-.976V5.03c0-3.248-5.41-5-10.5-5S.773 1.782.761 5.024v12.76c0 2.7 3.746 4.366 7.936 4.848a.945.945 0 00.954-1.383l-.06-.105a.967.967 0 00-.733-.495c-3.787-.436-6.099-1.843-6.099-2.865zm8.5-15.75c5.185 0 8.495 1.774 8.5 3v.006c-.01 1.223-3.318 2.994-8.5 2.994s-8.5-1.777-8.5-3 3.312-3 8.501-3z\"/><circle cx=\"16.989\" cy=\"17.003\" r=\"1.5\"/><path d=\"M22.757 15.625a1.783 1.783 0 00-1.7-2.953L19.645 13a.456.456 0 01-.533-.311l-.424-1.389a1.775 1.775 0 00-3.4 0l-.424 1.391a.455.455 0 01-.533.311l-1.411-.327a1.783 1.783 0 00-1.7 2.953l.988 1.065a.463.463 0 010 .625l-.988 1.066a1.783 1.783 0 001.7 2.953l1.411-.328a.45.45 0 01.533.311l.424 1.392a1.775 1.775 0 003.4 0l.424-1.392a.448.448 0 01.533-.311l1.412.328a1.783 1.783 0 001.7-2.953l-.987-1.066a.461.461 0 010-.625zM19.989 17a3 3 0 11-3-3 3 3 0 013 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});