define("ember-svg-jar/inlined/single-neutral-actions-lock.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-lock</title><path d=\"M13.512 14.765a.5.5 0 00.095-.634 7.534 7.534 0 00-6.4-3.631 7.543 7.543 0 00-7.186 5.357.5.5 0 00.479.643h11.648a.5.5 0 00.479-.356 2.992 2.992 0 01.885-1.379z\"/><circle cx=\"7\" cy=\"4.75\" r=\"4.75\"/><path d=\"M22.5 14.25a3.5 3.5 0 00-7 0v1.018a.249.249 0 01-.214.247A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.286-1.485.249.249 0 01-.214-.247zm-2.5 6a1 1 0 11-1-1 1 1 0 011 1zm-2.25-4.75a.25.25 0 01-.25-.25v-1a1.5 1.5 0 013 0v1a.25.25 0 01-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});