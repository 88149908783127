define("ember-svg-jar/inlined/aircraft-military-plane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>aircraft-military-plane</title><path d=\"M22.88 16.405l-7.687-7.662a.251.251 0 01-.073-.15l-.355-3.339a.251.251 0 00-.133-.195.247.247 0 00-.236 0 4.736 4.736 0 01-4.531.012.25.25 0 00-.236 0 .253.253 0 00-.132.195L9.15 8.59a.252.252 0 01-.076.154L1.13 16.4a1.791 1.791 0 00-.51 1.24 1.976 1.976 0 001.75 2h1.486a.249.249 0 00.25-.25V17.9a2.5 2.5 0 012.5-2.5H7.62a2.5 2.5 0 012.5 2.5v1.8a.25.25 0 00.088.191.972.972 0 01.331.553l.422 1.646a1 1 0 00.981.807h.36a1 1 0 00.98-.8l.436-1.656a.974.974 0 01.317-.539.251.251 0 00.085-.188V17.9a2.5 2.5 0 012.5-2.5h1.014a2.5 2.5 0 012.5 2.5v1.49a.249.249 0 00.25.25h1.246a1.976 1.976 0 001.75-2 1.756 1.756 0 00-.5-1.235zM12.12 4.145a3.2 3.2 0 002.116-.809.25.25 0 00.064-.29A6.986 6.986 0 0012.4.47a.506.506 0 00-.53 0 7.052 7.052 0 00-1.912 2.592.249.249 0 00.065.291 3.2 3.2 0 002.097.792z\"/><path d=\"M7.62 16.9H6.606a1 1 0 00-1 1v2.5a.5.5 0 00.363.481.249.249 0 01.175.186l.485 2.154a.5.5 0 00.487.39.5.5 0 00.488-.392l.478-2.152a.25.25 0 01.175-.186.5.5 0 00.362-.481v-2.5a1 1 0 00-.999-1zM17.634 16.9H16.62a1 1 0 00-1 1v2.5a.5.5 0 00.362.481.25.25 0 01.175.186l.478 2.152a.5.5 0 00.488.392.5.5 0 00.487-.39l.485-2.154a.249.249 0 01.175-.186.5.5 0 00.363-.481v-2.5a1 1 0 00-.999-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});