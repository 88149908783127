define("ember-svg-jar/inlined/phone-action-shield-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-shield-1</title><path d=\"M12 13.04v5.46a1 1 0 01-1 1H4a1 1 0 01-1-1v-11a1 1 0 011-1h4.271a.247.247 0 00.25-.251c-.011-.491-.017-1.529-.02-2A.247.247 0 008.251 4H3.5A2.5 2.5 0 001 6.5v15A2.5 2.5 0 003.5 24h8a2.5 2.5 0 002.5-2.5v-7.569a.24.24 0 00-.139-.218 10.251 10.251 0 01-1.469-.87.245.245 0 00-.392.197z\"/><path d=\"M22.072 1.04A16.386 16.386 0 0016.479 0a15.988 15.988 0 00-5.551 1.04A1.545 1.545 0 0010 2.456v3.393a7.716 7.716 0 005.19 6.805 3.643 3.643 0 001.289.346 3.686 3.686 0 001.288-.346C20.848 11.48 23 8.682 23 5.849V2.456a1.545 1.545 0 00-.928-1.416zM17.25 4.5a.25.25 0 00.25.25h2a.75.75 0 010 1.5h-2a.25.25 0 00-.25.25v2a.75.75 0 01-1.5 0v-2a.25.25 0 00-.25-.25h-2a.75.75 0 010-1.5h2a.25.25 0 00.25-.25v-2a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});