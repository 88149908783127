define("ember-svg-jar/inlined/real-estate-market-building-rise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-market-building-rise</title><path d=\"M2.5 14v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5A.5.5 0 007 14V6.5a.5.5 0 00-.276-.447l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 000 6.5V14a.5.5 0 00.5.5H2a.5.5 0 00.5-.5zM2 7h3a.5.5 0 010 1H2a.5.5 0 010-1zm-.5 3a.5.5 0 01.5-.5h3a.5.5 0 010 1H2a.5.5 0 01-.5-.5zM8.317 11.729a.5.5 0 00.549-.125l1.777-1.91a3.714 3.714 0 013.718-1.057.5.5 0 00.639-.481V3a.5.5 0 00-.308-.462l-6-2.5A.5.5 0 008 .5v10.763a.5.5 0 00.317.466zM10 4.5h3a.5.5 0 010 1h-3a.5.5 0 010-1zM10 7h3a.5.5 0 010 1h-3a.5.5 0 010-1zM23.25 9h-4a.75.75 0 00-.53 1.28l.907.908a.5.5 0 01.032.673l-1.64 1.974a.5.5 0 01-.794-.033l-2-2.849a2.246 2.246 0 00-3.484-.235L1.335 21.9a1.25 1.25 0 001.83 1.7l9.775-10.5a.5.5 0 01.775.053l2.03 2.894a2.251 2.251 0 003.55.164l.009-.01 2.071-2.492a.5.5 0 01.738-.035l.607.607A.75.75 0 0024 13.75v-4a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});