define("ember-svg-jar/inlined/saving-bear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>saving-bear</title><path d=\"M23.3 8.412a.5.5 0 00-.465-.139 12.939 12.939 0 01-2.725.3h-1.06a.249.249 0 00-.213.119 2.432 2.432 0 00-.325 1.655 2.306 2.306 0 001.2 1.51.248.248 0 00.156.027 7.676 7.676 0 002.744-.811 6.515 6.515 0 00.817-2.19.5.5 0 00-.129-.471z\"/><path d=\"M21.064 14.118a.5.5 0 00-.164-.818 7.6 7.6 0 01-3.139-2.026c-.77-1.167.1-2.774.309-3.132a.253.253 0 01.12-.1 13.141 13.141 0 003.422-1.972c1-1 1-3 .5-3h-4.134a.249.249 0 01-.185-.082 1.41 1.41 0 00-1.681-.418c-.584.292-.486.584-.3.777a.248.248 0 01.063.222.252.252 0 01-.146.18c-1.364.583-2.81.732-4.114 1.321-2.167.979-2.325 2.3-4.059 2.585a6.533 6.533 0 00-4.34 2.794h-.007v-.008a1.747 1.747 0 01-1.342.627H.832a.249.249 0 00-.248.225c-.066.666-.13 2.589 1.312 2.762a.251.251 0 01.22.24 6.485 6.485 0 002.457 4.866.245.245 0 01.09.147l.369 1.857a.5.5 0 00.49.4H8.062v-.006l-.009-3.491a.5.5 0 01.166-.373 2.7 2.7 0 00.89-2.128 2.977 2.977 0 00-.9-2.146.5.5 0 01.7-.716 3.971 3.971 0 011.205 2.862 4.723 4.723 0 01-.035.578.249.249 0 00.309.273 9.813 9.813 0 001.646-.584 4.1 4.1 0 001.625-1.259.249.249 0 00-.021-.324 3.981 3.981 0 01-1-1.548.5.5 0 01.962-.276c.034.115.8 2.421 5.281 3.109h.021c.2.014.375.021.5.025a.5.5 0 00.369-.147z\"/><path d=\"M12.444 16.748a9.582 9.582 0 01-2.5.782.249.249 0 00-.207.3l.785 3.349a.5.5 0 00.487.386h2.435a.5.5 0 00.479-.642l-.628-2.121a.252.252 0 01.054-.238 2.993 2.993 0 00.764-2c0-.156 0-.3-.014-.422a.25.25 0 00-.409-.172 5.423 5.423 0 01-1.246.778z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});