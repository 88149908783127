define("ember-svg-jar/inlined/reptile-cobra", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>reptile-cobra</title><path d=\"M7.494 19.332v-1.5H2.5a2.5 2.5 0 000 5h6.432a4.982 4.982 0 01-1.438-3.5zM7.629 1.163a5.235 5.235 0 00-1.4.185 1 1 0 00-.5.329A5.1 5.1 0 003 6.163c0 3.872 2.058 5.684 3.71 7.139 1.274 1.122 2.281 2.009 2.281 3.531v2.5a3.5 3.5 0 003.5 3.5h9a2.5 2.5 0 002.474-2.912 2.585 2.585 0 00-2.574-2.088h-6.9a.5.5 0 01-.5-.491v-.509c0-1.522 1.007-2.408 2.284-3.53 1.655-1.456 3.716-3.267 3.716-7.14a5.1 5.1 0 00-2.723-4.486.994.994 0 00-.505-.329 5.235 5.235 0 00-1.4-.185zM15.2 3.789a10.581 10.581 0 01-2.953 4.6v2.335l1.28 1.28a.75.75 0 01-1.061 1.061L11.5 12.1l-.97.97a.75.75 0 01-1.061-1.061l1.281-1.28V8.394a10.483 10.483 0 01-2.958-4.606.5.5 0 01.487-.625h6.437a.5.5 0 01.484.626z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});