define("ember-svg-jar/inlined/vip-royal-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vip-royal-alternate</title><path d=\"M12 24A12 12 0 100 12a12.013 12.013 0 0012 12zm0-22A10 10 0 112 12 10.011 10.011 0 0112 2z\"/><path d=\"M6.5 15.732H18a1 1 0 001-1v-5.4a.5.5 0 00-.829-.377l-2.981 2.61a.253.253 0 01-.182.062.248.248 0 01-.172-.087L12.38 8.675a.5.5 0 00-.76 0l-2.444 2.852a.253.253 0 01-.181.087.249.249 0 01-.186-.073L6.354 9.085a.5.5 0 00-.854.354v5.293a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});