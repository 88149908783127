define("ember-svg-jar/inlined/vintage-woman-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-woman-2</title><path d=\"M19.249 9.639a2 2 0 00-.6-1.556.249.249 0 00-.2-.062c-.327.046-.655.1-.984.136a.25.25 0 00-.214.2.745.745 0 00.4.814.634.634 0 01.1.44 1.281 1.281 0 01-.557 1.1 1.752 1.752 0 00-1 1.306C15.816 14.26 13.029 15.75 12 15.75s-3.816-1.49-4.191-3.738a1.752 1.752 0 00-1-1.306 1.281 1.281 0 01-.557-1.1.634.634 0 01.1-.44.745.745 0 00.4-.814.248.248 0 00-.214-.2c-.329-.04-.657-.09-.984-.136a.249.249 0 00-.2.062 2 2 0 00-.6 1.556 3.121 3.121 0 00.3 1.243 7.632 7.632 0 01-.554 1.486 3.039 3.039 0 01-1.66 1.642.5.5 0 00-.232.788A5.057 5.057 0 006.15 16.5a2.195 2.195 0 001.982-1.111A6.6 6.6 0 0012 17.25a6.6 6.6 0 003.868-1.863A2.19 2.19 0 0017.85 16.5a5.057 5.057 0 003.54-1.705.5.5 0 00-.232-.788 3.057 3.057 0 01-1.674-1.671 7.678 7.678 0 01-.531-1.452 3.121 3.121 0 00.296-1.245z\"/><path d=\"M12.741 12.328a1.966 1.966 0 01-1.634 0 .75.75 0 00-.711 1.321 3.436 3.436 0 003.06 0 .75.75 0 10-.715-1.318zM10.992 10.026a.751.751 0 00-.055-1.059 2.1 2.1 0 00-2.635 0 .75.75 0 101 1.115.632.632 0 01.627 0 .75.75 0 001.063-.056zM12.867 8.967a.75.75 0 001 1.115.632.632 0 01.627 0 .75.75 0 101-1.115 2.1 2.1 0 00-2.627 0zM1.773 6.453a.463.463 0 00.064.029A46.906 46.906 0 0012 7.59a46.906 46.906 0 0010.163-1.108.537.537 0 00.065-.029.742.742 0 00.5-.866.75.75 0 00-.895-.569c-1.19.264-2.4.463-3.606.63l-.372-2.636A3.516 3.516 0 0014.394 0H9.606a3.516 3.516 0 00-3.465 3.012l-.372 2.636a45.14 45.14 0 01-3.606-.63.75.75 0 00-.895.569.741.741 0 00.505.866zM7.762 17.574a.5.5 0 00-.448-.038L2.92 19.287A4.519 4.519 0 000 23.5a.5.5 0 00.5.5H8a.5.5 0 00.5-.545l-.5-5.5a.5.5 0 00-.238-.381zM21.09 19.291l-4.4-1.755a.5.5 0 00-.684.419l-.5 5.5A.5.5 0 0016 24h7.5a.5.5 0 00.5-.5 4.515 4.515 0 00-2.91-4.209zM12.464 19.564a.521.521 0 00-.928 0l-1.5 3.75A.5.5 0 0010.5 24h3a.5.5 0 00.464-.686z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});