define("ember-svg-jar/inlined/taxi-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>taxi-sign</title><path d=\"M10 10.5v1a.25.25 0 00.5 0v-1c0-.275-.5-.275-.5 0z\"/><path d=\"M23.437 16.774a.25.25 0 01-.187-.242V8.5a2.752 2.752 0 00-2.75-2.75h-17A2.752 2.752 0 00.75 8.5v8.032a.25.25 0 01-.188.242.75.75 0 00.188 1.476h22.5a.75.75 0 00.187-1.476zM7 10.25a.25.25 0 00-.25.25v4a.75.75 0 01-1.5 0v-4a.25.25 0 00-.25-.25.75.75 0 010-1.5h2a.75.75 0 010 1.5zm5 4.25a.75.75 0 01-1.5 0v-1a.25.25 0 00-.5 0v1a.75.75 0 01-1.5 0v-4a1.75 1.75 0 013.5 0zm3.345-2.407l.851 2.129a.749.749 0 11-1.392.556l-.072-.179a.25.25 0 00-.464 0l-.072.179a.749.749 0 11-1.392-.556l.851-2.129a.249.249 0 000-.186L12.8 9.778a.749.749 0 011.4-.556l.072.179a.25.25 0 00.464 0l.072-.179a.749.749 0 011.392.556l-.851 2.129a.249.249 0 00-.004.186zM18.5 14.5a.75.75 0 01-1.5 0v-5a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});