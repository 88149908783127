define("ember-svg-jar/inlined/smiley-glasses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-glasses</title><path d=\"M19.75 7.75a.5.5 0 00-.5-.5H15a.5.5 0 00-.5.5V11a.5.5 0 00.5.5h4.251a.5.5 0 00.5-.5zM4.25 11a.5.5 0 00.5.5H8.5A.5.5 0 009 11V7.75a.5.5 0 00-.5-.5H4.75a.5.5 0 00-.5.5z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm4.941 17.147a5.706 5.706 0 01-9.882 0 .44.44 0 01.379-.659h9.123a.439.439 0 01.38.658zM4.25 5.25H9a2 2 0 012 2v.875a.25.25 0 00.25.25h1a.25.25 0 00.25-.25V7.25a2 2 0 012-2h5.25a2 2 0 012 2v4.25a2 2 0 01-2 2H14.5a2 2 0 01-2-2v-.875a.25.25 0 00-.25-.25h-1a.25.25 0 00-.25.25v.875a2 2 0 01-2 2H4.25a2 2 0 01-2-2V7.25a2 2 0 012-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});