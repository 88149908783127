define("ember-svg-jar/inlined/file-dat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-dat</title><path d=\"M5.956 13.82a.249.249 0 00-.331.24v3.882a.249.249 0 00.331.24A2.238 2.238 0 007.375 16a2.237 2.237 0 00-1.419-2.18zM17.5 6h3.9a.25.25 0 00.177-.427L16.427.428A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6zM11.5 13.626a.876.876 0 00-.875.875v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V14.5a.876.876 0 00-.875-.874z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.251A.25.25 0 0014.75 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.251A.25.25 0 0021.75 7zM5 19.626A.625.625 0 014.375 19v-6A.625.625 0 015 12.376a3.625 3.625 0 010 7.25zM13.625 19a.625.625 0 11-1.25 0v-2.124a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V19a.625.625 0 01-1.25 0v-4.374a2.212 2.212 0 011.9-2.239 2.128 2.128 0 012.35 2.113zM19 13.626h-1.125a.25.25 0 00-.25.25V19a.625.625 0 11-1.25 0v-5.124a.25.25 0 00-.25-.25H15a.625.625 0 010-1.25h4a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});