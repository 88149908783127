define("ember-svg-jar/inlined/wifi-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-check</title><path d=\"M17.559 11.022a6.367 6.367 0 106.366 6.366 6.374 6.374 0 00-6.366-6.366zm3.214 5.111l-2.84 3.788a1.483 1.483 0 01-1.083.588h-.107a1.474 1.474 0 01-1.047-.436l-1.467-1.463a.75.75 0 111.061-1.06l1.264 1.264a.25.25 0 00.377-.028l2.643-3.554a.75.75 0 011.2.9zM15.216 8.659a1 1 0 001.414-1.414 9.76 9.76 0 00-13.787 0 1 1 0 001.414 1.414 7.761 7.761 0 0110.959 0z\"/><path d=\"M17.69 5.531a1 1 0 001.41-1.414 13.266 13.266 0 00-18.736 0 1 1 0 001.418 1.414 11.263 11.263 0 0115.908 0zM13.5 11.017a1.035 1.035 0 00-.271-1.387 6.26 6.26 0 00-7.908.767 1 1 0 101.414 1.414 4.257 4.257 0 015.377-.523 1.02 1.02 0 001.388-.271z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});