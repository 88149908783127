define("ember-svg-jar/inlined/hat-retro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hat-retro</title><path d=\"M18.4 9.885a31.7 31.7 0 01-6.4.615 31.633 31.633 0 01-6.4-.616.5.5 0 00-.6.49v2.507a.5.5 0 00.305.46 18.418 18.418 0 006.7.909c3.694 0 5.785-.521 6.7-.935a.5.5 0 00.295-.456v-2.485a.5.5 0 00-.6-.489zM18.669 14.943A24.1 24.1 0 0112 15.75a24.115 24.115 0 01-6.671-.807.251.251 0 00-.329.237v6.32c0 1.727 3.515 2.5 7 2.5s7-.773 7-2.5v-6.32a.25.25 0 00-.33-.237zM12 0C6.183 0 0 1.577 0 4.5S6.183 9 12 9s12-1.577 12-4.5S17.817 0 12 0zm0 6.5c-4.142 0-7.5-.9-7.5-2s3.358-2 7.5-2 7.5.9 7.5 2-3.357 2-7.5 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});