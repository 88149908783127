define("ember-svg-jar/inlined/monitor-download.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-download</title><path d=\"M12.177 11.657a.25.25 0 00-.232-.156H2.5A.5.5 0 012 11V2.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v7.1a.25.25 0 00.088.19.254.254 0 00.2.057 7.658 7.658 0 011.21-.1c.079 0 .157 0 .235.007A.248.248 0 0018 9.509V2a2 2 0 00-2-2H2a2 2 0 00-2 2v10.5a2 2 0 002 2h5.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5H5a1 1 0 000 2h4.508a.251.251 0 00.25-.265c0-.078-.008-.156-.008-.235a7.68 7.68 0 01.245-1.9.229.229 0 00.005-.092V15a.488.488 0 01.268-.436.253.253 0 00.114-.123 7.775 7.775 0 011.737-2.512.246.246 0 00.058-.272z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.391 7.312l-2 2.5a.5.5 0 01-.782 0l-2-2.5a.5.5 0 01.391-.812h.75a.25.25 0 00.25-.25V14.5a1 1 0 112 0v2.75a.25.25 0 00.25.25h.75a.5.5 0 01.391.812z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});