define("ember-svg-jar/inlined/web-service-treehouse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>web-service-treehouse</title><path d=\"M15.62 8.57a3 3 0 00.19 3v.06a30.54 30.54 0 012 2.64 1.53 1.53 0 01-1 1.87A1.46 1.46 0 0115 15.09c-.25-.91.32-1-.57-2-.46-.53-1.41.47-1.71 1.51a8.41 8.41 0 00-.45 2.08 1.75 1.75 0 01-.59 2.48 1.63 1.63 0 01-2.24-.71 1.74 1.74 0 01.79-2.37 21.56 21.56 0 001.1-3.49.25.25 0 00-.43-.23c-.82.92-1.46.53-2.23 2.07a1.51 1.51 0 01-.37.47 1.42 1.42 0 01-2-.22 1.55 1.55 0 01.21-2.12 1.43 1.43 0 01.61-.3 9.84 9.84 0 002.57-1.59.25.25 0 00-.22-.44c-.9.26-1.2.05-1.2.05a1.36 1.36 0 01-.26.26 1.27 1.27 0 01-1.82-.31 1.38 1.38 0 01.29-1.88 1.27 1.27 0 01.8-.25c1 0 4.25.87 5.75-2l1.08-2c.59-1.08.55-2.26-.1-2.64l-1.2-.7a2.36 2.36 0 00-2.15 0L2.58 5.48A2.44 2.44 0 001.5 7.39v9.22a2.44 2.44 0 001.08 1.91l8.08 4.72a2.35 2.35 0 002.15 0l8.52-4.73a2.55 2.55 0 001.17-1.91V7.38a2.55 2.55 0 00-1.17-1.91L20 4.68c-1.62-.95-3.66 2.6-4.38 3.89z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});