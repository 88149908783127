define("ember-svg-jar/inlined/board-game-jenga", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>board-game-jenga</title><rect y=\"20\" width=\"4\" height=\"4\" rx=\"1\" ry=\"1\"/><rect x=\"9\" y=\"20\" width=\"5\" height=\"4\" rx=\"1\" ry=\"1\"/><rect x=\"4\" y=\"10\" width=\"5\" height=\"4\" rx=\"1\" ry=\"1\"/><rect x=\"3\" width=\"5\" height=\"4\" rx=\"1\" ry=\"1\"/><rect x=\"9\" width=\"5\" height=\"4\" rx=\"1\" ry=\"1\"/><rect y=\"15\" width=\"14\" height=\"4\" rx=\"1\" ry=\"1\"/><rect y=\"5\" width=\"14\" height=\"4\" rx=\"1\" ry=\"1\"/><path d=\"M23.849 21.417l-6.068-9.769a1 1 0 00-1.377-.322l-1.7 1.056a1 1 0 00-.322 1.377l6.069 9.769a1 1 0 001.377.321l1.7-1.055a1 1 0 00.321-1.377z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});