define("ember-svg-jar/inlined/messages-people-person-bubble-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-person-bubble-1</title><path d=\"M7.5 16A7.508 7.508 0 000 23.5a.5.5 0 00.5.5h14a.5.5 0 00.5-.5A7.508 7.508 0 007.5 16z\"/><circle cx=\"7.5\" cy=\"10.75\" r=\"4.75\"/><circle cx=\"12.87\" cy=\"5.75\" r=\"1\"/><circle cx=\"16.12\" cy=\"5.75\" r=\"1\"/><circle cx=\"19.37\" cy=\"5.75\" r=\"1\"/><path d=\"M17.894 0a5.434 5.434 0 00-3.606 1.275 5.664 5.664 0 00-5.694 1.29 1 1 0 001.388 1.441 3.651 3.651 0 014.081-.669 1 1 0 001.137-.191A3.562 3.562 0 0117.894 2 3.91 3.91 0 0122 5.976a3.527 3.527 0 01-.946 2.473 4.125 4.125 0 01-3.06 1.116 1 1 0 00-.813.418A2.288 2.288 0 0115.494 11a1.986 1.986 0 01-1.067-.3 1 1 0 10-1.087 1.678 3.946 3.946 0 002.154.622 4.069 4.069 0 002.992-1.449 5.9 5.9 0 004.008-1.715A5.513 5.513 0 0024 5.976 5.913 5.913 0 0017.894 0z\"/><circle cx=\"15.754\" cy=\"15.75\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});