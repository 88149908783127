define("ember-svg-jar/inlined/car-tool-pump", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-tool-pump</title><path d=\"M9.031 2.227a.248.248 0 01.248-.248H12.5A.99.99 0 0012.5 0H3.341a.99.99 0 100 1.979h3.464a.248.248 0 01.247.248V5.2a.248.248 0 00.247.247h1.485a.248.248 0 00.247-.247zM21.649 4.95a1.239 1.239 0 00-1.237-1.237h-1.485a1.735 1.735 0 00-1.718 1.514.246.246 0 01-.245.216h-.58a.99.99 0 100 1.979h.579a.248.248 0 01.246.216 1.73 1.73 0 00.636 1.132.247.247 0 01.093.193V11.9a7.413 7.413 0 01-6.91 7.374.245.245 0 01-.186-.066.248.248 0 01-.079-.181V7.672a1.235 1.235 0 00-1.225-1.234H6.545A1.236 1.236 0 005.32 7.672v14.844H4.083a.742.742 0 100 1.484H12a.742.742 0 100-1.484h-1.237V21.5a.246.246 0 01.237-.245 9.394 9.394 0 008.917-9.355V9.4a.246.246 0 01.247-.247h.248a1.239 1.239 0 001.237-1.237z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});