define("ember-svg-jar/inlined/baby-care-bib", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baby-care-bib</title><path d=\"M15.832.213a2.213 2.213 0 00-2.226.5 2.067 2.067 0 00-.59 2.034 2.266 2.266 0 001.866 1.559 2.429 2.429 0 012.1 2.655C16.806 8.549 15.2 10.15 12 10.15s-4.806-1.6-4.984-3.189a2.429 2.429 0 012.1-2.655 2.266 2.266 0 001.867-1.559 2.067 2.067 0 00-.59-2.034 2.2 2.2 0 00-2.211-.5C3.212 1.547 0 6.115 0 11.847a12 12 0 1024 0c0-5.726-3.2-10.29-8.168-11.634zm.747 15.487a3.07 3.07 0 01.576 1.751c0 2.137-2.309 3.866-5.155 3.866s-5.155-1.729-5.155-3.866a3.07 3.07 0 01.576-1.751.249.249 0 00-.1-.369 1.929 1.929 0 112.742-1.785.249.249 0 00.31.24 6.689 6.689 0 013.255 0 .251.251 0 00.214-.044.247.247 0 00.1-.2 1.931 1.931 0 112.741 1.785.254.254 0 00-.136.161.251.251 0 00.032.212z\"/><circle cx=\"10.554\" cy=\"16.736\" r=\"1\"/><circle cx=\"13.554\" cy=\"16.736\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});