define("ember-svg-jar/inlined/human-resources-rating-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-rating-man</title><path d=\"M14.538 20.18a.25.25 0 01-.146-.208.247.247 0 01.111-.228 4.517 4.517 0 10-4.982 0 .249.249 0 01.111.228.253.253 0 01-.147.208 6.157 6.157 0 00-2.907 2.72.75.75 0 00.661 1.1h9.546a.749.749 0 00.661-1.1 6.159 6.159 0 00-2.908-2.72zm-4.907-5.017a.251.251 0 01.369-.129 5.833 5.833 0 004.373.821.124.124 0 01.151.123 2.517 2.517 0 11-5.033 0 2.493 2.493 0 01.14-.815zM23.927 5.553a.985.985 0 00-.934-.612h-1.482a.25.25 0 01-.234-.162l-.844-2.229a.54.54 0 00-.023-.05 1.054 1.054 0 00-1.856.049l-.544 1.437a.231.231 0 00-.016.078 2.5 2.5 0 01-1.244 2.054.25.25 0 00-.086.3l.229.524a2.511 2.511 0 01-.62 2.868 2.213 2.213 0 01-.234.188.25.25 0 00-.086.3 1.033 1.033 0 001.461.506l1.957-1.1a.249.249 0 01.245 0l1.957 1.1a1.04 1.04 0 00.51.136 1.021 1.021 0 00.688-.269 1 1 0 00.251-1.157l-.717-1.647a.249.249 0 01.085-.3l1.3-.912a1.006 1.006 0 00.237-1.102zM7.718 9.815A2.512 2.512 0 017.1 6.946l.226-.518a.25.25 0 00-.086-.3A2.613 2.613 0 016 4.043a.251.251 0 00-.015-.077l-.54-1.416a.54.54 0 00-.023-.05 1.054 1.054 0 00-1.856.049L2.721 4.78a.25.25 0 01-.234.161H1a.985.985 0 00-.933.611 1.03 1.03 0 00.3 1.155l1.233.859a.249.249 0 01.086.305L.977 9.516a1 1 0 00.251 1.157 1.019 1.019 0 001.2.133l1.957-1.1a.249.249 0 01.245 0l1.956 1.1a1.041 1.041 0 00.511.136 1.04 1.04 0 00.948-.635.25.25 0 00-.086-.3 2.574 2.574 0 01-.241-.192zM14.812 5.9a.249.249 0 01.086-.3l1.3-.912a1.006 1.006 0 00.239-1.1.988.988 0 00-.937-.618h-1.482a.248.248 0 01-.233-.161L12.94.58a.316.316 0 00-.022-.05 1.008 1.008 0 00-.911-.53 1.036 1.036 0 00-.945.58l-.846 2.229a.248.248 0 01-.233.161H8.5a.987.987 0 00-.934.611 1.031 1.031 0 00.3 1.155L9.1 5.6a.252.252 0 01.086.305l-.713 1.64A1 1 0 008.724 8.7a1.019 1.019 0 00.687.269 1.041 1.041 0 00.511-.136l1.956-1.1a.251.251 0 01.246 0l1.956 1.1a1.041 1.041 0 00.511.136 1.019 1.019 0 00.687-.269 1 1 0 00.251-1.157z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});