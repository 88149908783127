define("ember-svg-jar/inlined/renewable-energy-paper-turbine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>renewable-energy-paper-turbine</title><circle cx=\"12\" cy=\"12\" r=\"2\"/><path d=\"M9.962 9.691a.5.5 0 00-.108-.545L1.707 1A1 1 0 000 1.707V9.5a.5.5 0 00.5.5h9a.5.5 0 00.462-.309zM2 11a.5.5 0 00-.354.854L5.4 15.6a.5.5 0 00.708 0l2.75-2.75A.5.5 0 009 12.5v-1a.5.5 0 00-.5-.5zM11.146 8.854A.5.5 0 0011.5 9h1a.5.5 0 00.5-.5V2a.5.5 0 00-.854-.354L8.4 5.4a.5.5 0 000 .708zM17.9 8.4l-2.75 2.75a.5.5 0 00-.15.35v1a.5.5 0 00.5.5H22a.5.5 0 00.354-.854L18.6 8.4a.5.5 0 00-.7 0zM12.854 15.146A.5.5 0 0012.5 15h-1a.5.5 0 00-.5.5V22a.5.5 0 00.309.462.512.512 0 00.191.038.5.5 0 00.354-.146L15.6 18.6a.5.5 0 000-.708zM14.309 9.962A.512.512 0 0014.5 10a.5.5 0 00.354-.146L23 1.707A1 1 0 0022.293 0H14.5a.5.5 0 00-.5.5v9a.5.5 0 00.309.462zM23.5 14h-9a.5.5 0 00-.354.854L22.293 23A1 1 0 0024 22.293V14.5a.5.5 0 00-.5-.5zM9.691 14.038a.5.5 0 00-.545.108L1 22.293A1 1 0 001.707 24H9.5a.5.5 0 00.5-.5v-9a.5.5 0 00-.309-.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});