define("ember-svg-jar/inlined/single-woman-actions.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions</title><path d=\"M12 14a7.508 7.508 0 00-7.5 7.5.5.5 0 00.5.5h14a.5.5 0 00.5-.5A7.508 7.508 0 0012 14zM5.763 11.943a1 1 0 001.68 1.084 6.084 6.084 0 00.6-1.226.249.249 0 01.4-.1 5.511 5.511 0 007.225 0 .25.25 0 01.4.1 6.092 6.092 0 00.6 1.227 1 1 0 001.68-1.084 5.417 5.417 0 01-.765-2.98V7.523a5.524 5.524 0 00-11.047 0v1.44a5.417 5.417 0 01-.773 2.98zm6.288-.9A3.523 3.523 0 018.57 8.029a.5.5 0 01.356-.552 6.569 6.569 0 002.965-1.8.25.25 0 01.363 0 6.574 6.574 0 002.928 1.79.5.5 0 01.354.549 3.525 3.525 0 01-3.485 3.03z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});