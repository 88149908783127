define("ember-svg-jar/inlined/medical-specialty-knee", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-specialty-knee</title><path d=\"M12.228.223A.5.5 0 0011.812 0h-3.75a.25.25 0 00-.212.383C8.615 1.6 10.973 5.335 11.709 6.5a.5.5 0 00.534.221 1 1 0 011.068.44 2.5 2.5 0 01-4.225 2.676 1 1 0 01.058-1.152.5.5 0 00.028-.578L4.16.232A.5.5 0 003.738 0H1.013A.25.25 0 00.8.375L6.08 9.5c1 1.5 1 5.5 1 5.5v8.5a.5.5 0 00.5.5h3.52a.5.5 0 00.5-.5l-.034-7.272a.5.5 0 00-.327-.467 1 1 0 01-.655-.958 2.5 2.5 0 115 .1 1 1 0 01-.677.927.5.5 0 00-.339.476l.035 7.2a.5.5 0 00.5.5h2.977a.5.5 0 00.5-.5V13c-.266-2.479.014-3.519-1.5-5.5zM19.079 7.236a1 1 0 00.83-.441l1.777-2.64a1 1 0 10-1.66-1.117L18.25 5.677a1 1 0 00.829 1.559zM23.558 14.543l-2.64-1.777a1 1 0 00-1.118 1.661l2.64 1.776a1 1 0 001.116-1.66zM20.044 10.869a.986.986 0 00.193-.019l2.454-.48a1 1 0 10-.385-1.963l-2.453.48a1 1 0 00.191 1.982z\"/><path d=\"M3.639 10.4A1 1 0 004.2 8.573L1.558 6.8A1 1 0 00.442 8.458l2.64 1.775a1 1 0 00.557.167zM4.25 16.206l-1.776 2.639a1 1 0 001.659 1.118l1.776-2.64a1 1 0 10-1.659-1.117zM5.1 12.939a1 1 0 00-1.174-.789l-2.453.481a1 1 0 00.191 1.981 1.069 1.069 0 00.194-.018l2.453-.481a1 1 0 00.789-1.174z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});