define("ember-svg-jar/inlined/broken-tab-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>broken-tab-remove</title><path d=\"M13.931 8.368A1 1 0 0013 7H1.5A1.5 1.5 0 000 8.5v7A1.5 1.5 0 001.5 17H13a1 1 0 00.928-1.374l-1.564-3.4a.5.5 0 010-.417z\"/><path d=\"M24 8.5A1.5 1.5 0 0022.5 7h-5.661a.988.988 0 00-.921.609l-1.808 4.019a1.008 1.008 0 00.008.763l1.792 3.982a1 1 0 00.928.627H22.5a1.5 1.5 0 001.5-1.5zm-2.47 4.47a.75.75 0 11-1.06 1.06l-.793-.793a.252.252 0 00-.354 0l-.793.793a.75.75 0 01-1.06-1.06l.793-.793a.252.252 0 000-.354l-.793-.793a.75.75 0 011.06-1.06l.793.793a.252.252 0 00.354 0l.793-.793a.75.75 0 011.06 1.06l-.793.793a.252.252 0 000 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});