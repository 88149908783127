define("ember-svg-jar/inlined/yoga-back-stretch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>yoga-back-stretch</title><circle cx=\"5.81\" cy=\"21.25\" r=\"2.5\"/><path d=\"M14.373 13.725a.25.25 0 00.284-.185l.107-.421a1.535 1.535 0 00.046-.369l-1.5-11a1.5 1.5 0 00-3 0l1.446 10.425a1.983 1.983 0 01-.042.767L11.7 13a.25.25 0 00.2.308zM20.428 19.632l-2.276-4.553a1.5 1.5 0 10-2.684 1.342l1.915 3.829H15.56a.25.25 0 00-.25.25V23a.25.25 0 00.25.25h2.632a2.5 2.5 0 002.236-3.618zM12.953 20.25l1.336-5.259a.251.251 0 00-.2-.309l-2.473-.417a.25.25 0 00-.284.185l-1.463 5.759a2.5 2.5 0 002.441 3.041h1.75a.25.25 0 00.25-.25v-2.5a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});