define("ember-svg-jar/inlined/time-stopwatch-quarter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-stopwatch-quarter</title><path d=\"M12 8.5a.5.5 0 00-.5.5v5.5a.5.5 0 00.5.5h5.5a.5.5 0 00.5-.5 6.006 6.006 0 00-6-6z\"/><path d=\"M19.506 8.689a.25.25 0 01.021-.33l1.651-1.651a1 1 0 10-1.414-1.414L18.108 6.95a.25.25 0 01-.329.021 9.447 9.447 0 00-4.561-1.891.25.25 0 01-.218-.248V2.251A.25.25 0 0113.25 2H15a1 1 0 100-2H9a1 1 0 000 2h1.75a.25.25 0 01.25.25v2.582a.249.249 0 01-.218.248 9.513 9.513 0 108.724 3.609zM12 22a7.5 7.5 0 117.5-7.5A7.508 7.508 0 0112 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});