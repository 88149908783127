define("ember-svg-jar/inlined/subtract-circle-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>subtract-circle-alternate</title><path d=\"M12 .25A11.75 11.75 0 1023.75 12 11.763 11.763 0 0012 .25zm0 21A9.25 9.25 0 1121.25 12 9.26 9.26 0 0112 21.25z\"/><path d=\"M6.273 10.75a1.25 1.25 0 000 2.5h11.454a1.25 1.25 0 000-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});