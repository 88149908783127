define("ember-svg-jar/inlined/server-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-edit</title><path d=\"M2.5 6h17A2.5 2.5 0 0022 3.5v-1A2.5 2.5 0 0019.5 0h-17A2.5 2.5 0 000 2.5v1A2.5 2.5 0 002.5 6zm1.135-3A1.115 1.115 0 114.75 4.115 1.114 1.114 0 013.635 3zM7.5 3a1.115 1.115 0 111.114 1.115A1.114 1.114 0 017.5 3zM18.468 10.7a4.091 4.091 0 012.91-1.2h.052a.5.5 0 00.5-.61A2.559 2.559 0 0019.5 7h-17A2.5 2.5 0 000 9.5v1A2.5 2.5 0 002.5 13h13.466a.5.5 0 00.353-.147zM9.729 10a1.115 1.115 0 11-1.115-1.115A1.115 1.115 0 019.729 10zm-3.865 0A1.115 1.115 0 114.75 8.885 1.114 1.114 0 015.864 10zM10.845 18.328l3.474-3.475a.5.5 0 00-.353-.853H2.5A2.5 2.5 0 000 16.5v1A2.5 2.5 0 002.5 20h7.249a.5.5 0 00.484-.408 2.3 2.3 0 01.612-1.264zM3.635 17a1.115 1.115 0 111.115 1.114A1.114 1.114 0 013.635 17zM7.5 17a1.115 1.115 0 111.114 1.114A1.114 1.114 0 017.5 17zM12.74 20.046a.5.5 0 00-.835.215l-.885 3.1a.5.5 0 00.48.639.466.466 0 00.138-.02l3.1-.886a.5.5 0 00.216-.834zM21.772 16.935a.5.5 0 000-.707l-3-3a.5.5 0 00-.707 0l-5.011 5.011a.5.5 0 000 .707l3 3a.5.5 0 00.707 0zM22.479 15.521a.456.456 0 00.327.125.612.612 0 00.426-.172 2.621 2.621 0 000-3.707 2.641 2.641 0 00-3.755.049.5.5 0 000 .705z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});