define("ember-svg-jar/inlined/shirt-plain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shirt-plain</title><path d=\"M23.707 7.793l-.007-.007-3.9-3.751A5.476 5.476 0 0015.987 2.5c-.1.028-.432-.071-.5.3A3.692 3.692 0 0112 6a3.692 3.692 0 01-3.488-3.2c-.067-.37-.4-.272-.5-.3A5.476 5.476 0 004.2 4.035L.293 7.793a1 1 0 000 1.414l2.324 2.324a.989.989 0 001.332.073l1.245-.963a.5.5 0 01.806.4V20.5a1 1 0 001 1h10a1 1 0 001-1v-9.463a.5.5 0 01.806-.4l1.231.951a1 1 0 001.346-.061l2.324-2.324a1 1 0 000-1.41z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});