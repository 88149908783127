define("ember-svg-jar/inlined/people-woman-12.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-12</title><path d=\"M22.534 18.491a12.533 12.533 0 00-3.195-1.71.251.251 0 00-.23.029.248.248 0 00-.108.205v1.11a.248.248 0 00.068.171A2.476 2.476 0 0119.75 20c0 1.256-1.8 3.021-2.156 3.363a.5.5 0 01-.688 0c-.36-.342-2.156-2.107-2.156-3.363a2.474 2.474 0 01.682-1.7.249.249 0 00.068-.172v-.219a.25.25 0 00-.429-.174 4.183 4.183 0 01-6.142 0 .25.25 0 00-.429.174v.219a.249.249 0 00.068.172A2.474 2.474 0 019.25 20c0 1.256-1.8 3.021-2.156 3.363a.5.5 0 01-.688 0C6.046 23.021 4.25 21.256 4.25 20a2.474 2.474 0 01.682-1.7.249.249 0 00.068-.176v-1.109a.248.248 0 00-.108-.205.251.251 0 00-.23-.029 12.525 12.525 0 00-3.2 1.71A2.467 2.467 0 00.5 20.468V23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-3.032a2.467 2.467 0 00-.966-1.977z\"/><path d=\"M5.966 12.529a.256.256 0 01.034.126v5.8a.5.5 0 01-.186.39A1.488 1.488 0 005.25 20a4.716 4.716 0 001.315 2.1.25.25 0 00.37 0A4.726 4.726 0 008.25 20a1.493 1.493 0 00-.564-1.161.5.5 0 01-.186-.389v-3.585a.25.25 0 01.4-.2c.194.14.4.27.6.391a6.941 6.941 0 007 0c.208-.121.41-.251.6-.391a.25.25 0 01.4.2v3.585a.5.5 0 01-.186.389A1.493 1.493 0 0015.75 20a4.716 4.716 0 001.315 2.1.25.25 0 00.37 0A4.726 4.726 0 0018.75 20a1.493 1.493 0 00-.564-1.161.5.5 0 01-.186-.389v-5.795a.253.253 0 01.034-.126A6.94 6.94 0 0019 9V7c0-.229-.013-.455-.034-.679a7 7 0 00-13.932 0C5.013 6.545 5 6.771 5 7v2a6.94 6.94 0 00.966 3.529zm2.313-.222A4.961 4.961 0 017 9v-.951a.251.251 0 01.243-.25 7.045 7.045 0 004.6-1.606.243.243 0 01.32 0 7.048 7.048 0 004.6 1.609.249.249 0 01.237.249V9a4.98 4.98 0 01-8.721 3.307z\"/><circle cx=\"14\" cy=\"9.25\" r=\"1\"/><circle cx=\"10\" cy=\"9.25\" r=\"1\"/><path d=\"M13.077 11.361h-2a.64.64 0 00-.307 1.2 2.355 2.355 0 00.438.182 3.017 3.017 0 00.869.127 2.977 2.977 0 00.866-.125 2.375 2.375 0 00.441-.183.64.64 0 00-.307-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});