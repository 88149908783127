define("ember-svg-jar/inlined/diagram-arrow-corner-point-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-arrow-corner-point-right</title><path d=\"M23.485 9.354a1.591 1.591 0 000-2.342L16.25.292a1.09 1.09 0 00-1.833.8v3.545a.272.272 0 01-.273.272H1.636A1.638 1.638 0 000 6.546v15.818A1.638 1.638 0 001.636 24h2.728A1.638 1.638 0 006 22.364V11.727a.272.272 0 01.273-.272h7.871a.272.272 0 01.273.272v3.546a1.09 1.09 0 001.833.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});