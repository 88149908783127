define("ember-svg-jar/inlined/natural-disaster-hurricane-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>natural-disaster-hurricane-house</title><path d=\"M13.823 15.368l-6.5-5.5a.5.5 0 00-.646 0l-6.5 5.5a.5.5 0 00.323.882h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5H5a.5.5 0 00.5-.5V19a1.5 1.5 0 013 0v2.25a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5V16.5a.25.25 0 01.25-.25h1.25a.5.5 0 00.323-.882zM11.5 4.25H23a1 1 0 000-2H11.5a1 1 0 000 2zM9 7.75h11a1 1 0 000-2H9a1 1 0 000 2zM13 9.25a1 1 0 000 2h9a1 1 0 000-2zM24 13.75a1 1 0 00-1-1h-6.5a1 1 0 000 2H23a1 1 0 001-1zM21 17.25a1 1 0 00-1-1h-3a1 1 0 000 2h3a1 1 0 001-1zM22 20.75a1 1 0 00-1-1h-2a1 1 0 000 2h2a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});