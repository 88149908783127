define("ember-svg-jar/inlined/presentation-projector-screen-pie-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>presentation-projector-screen-pie-chart</title><path d=\"M24 1.75a1.5 1.5 0 00-1.5-1.5h-21a1.5 1.5 0 00-.214 2.984.25.25 0 01.214.248v14.543a.251.251 0 01-.2.245 1 1 0 00.2 1.98H11a.249.249 0 01.25.25v.314a.249.249 0 01-.107.2 1.5 1.5 0 101.714 0 .249.249 0 01-.107-.2V20.5a.249.249 0 01.25-.25h9.5a1 1 0 00.2-1.98.249.249 0 01-.2-.245V3.482a.251.251 0 01.214-.248A1.5 1.5 0 0024 1.75zM20.5 18a.25.25 0 01-.25.25H3.75A.25.25 0 013.5 18V3.5a.249.249 0 01.25-.25h16.5a.249.249 0 01.25.25z\"/><path d=\"M16.872 12.627a.251.251 0 00.35-.145 5.513 5.513 0 00-.817-5.026.251.251 0 00-.183-.1.247.247 0 00-.194.073l-2.939 2.938a.25.25 0 00.065.4zM7.079 12.5a.249.249 0 00-.162.13.246.246 0 00-.008.207 5.5 5.5 0 009.608 1.055.25.25 0 00-.093-.366l-4.4-2.2a.244.244 0 00-.172-.019zM15.32 6.722a.25.25 0 00-.026-.377 5.5 5.5 0 00-8.766 4.963.25.25 0 00.31.217l4.839-1.21a.25.25 0 00.116-.066z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});