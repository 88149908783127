define("ember-svg-jar/inlined/layers-select-front", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layers-select-front</title><path d=\"M22 18H8a2 2 0 01-2-2V2a2 2 0 012-2h14a2 2 0 012 2v14a2 2 0 01-2 2zM8 15.5a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-13a.5.5 0 00-.5-.5h-13a.5.5 0 00-.5.5zM3 24H2a2 2 0 01-2-2v-1a1 1 0 012 0v.5a.5.5 0 00.5.5H3a1 1 0 010 2zM1 17a1 1 0 01-1-1v-2a1 1 0 012 0v2a1 1 0 01-1 1zM1 10a1 1 0 01-1-1V7a1 1 0 012 0v2a1 1 0 01-1 1zM10 24H8a1 1 0 010-2h2a1 1 0 010 2zM17 24h-2a1 1 0 010-2h2a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});