define("ember-svg-jar/inlined/history-chinese-lantern", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-chinese-lantern</title><path d=\"M18.819 12.218H16.84a2.969 2.969 0 100 5.937h1.979a2.969 2.969 0 100-5.937z\"/><rect x=\"15.356\" y=\"19.145\" width=\"4.948\" height=\"1.484\" rx=\".495\" ry=\".495\"/><rect x=\"2.986\" y=\"21.895\" width=\"6.927\" height=\"1.979\" rx=\".495\" ry=\".495\"/><path d=\"M8.429 12a.115.115 0 00-.072.205 5.425 5.425 0 010 8.5.115.115 0 00.072.2 4.453 4.453 0 000-8.906z\"/><path d=\"M6.263 20.561a.494.494 0 00.374 0 4.425 4.425 0 000-8.216.494.494 0 00-.374 0 4.424 4.424 0 000 8.216z\"/><path d=\"M4.579 20.829a.115.115 0 00-.037-.128 5.426 5.426 0 010-8.5.115.115 0 00-.071-.201 4.453 4.453 0 000 8.906.114.114 0 00.108-.077zM23.012 3.426a.377.377 0 00-.048 0h-.017A54.141 54.141 0 011.33.185a.99.99 0 10-.68 1.859l.157.056a.5.5 0 01.335.42c.078.783.176 1.7.251 2.393a1 1 0 00.656.828 1.018 1.018 0 00.33.057.991.991 0 00.7-.291l1.828-1.833a.5.5 0 01.484-.126.093.093 0 01.069.091v4.9a.494.494 0 01-.5.494H3.481a.5.5 0 00-.495.495v.99a.5.5 0 00.495.494h5.937a.494.494 0 00.5-.494v-.99a.5.5 0 00-.5-.495H7.934a.5.5 0 01-.5-.494V4.675a.5.5 0 01.6-.484q.919.2 1.844.377a.5.5 0 01.374.312c.274.731.58 1.535.8 2.1a.99.99 0 00.777.618 1.006 1.006 0 00.144.01.986.986 0 00.777-.376l1.45-1.825a.5.5 0 01.436-.186q.867.082 1.737.139a.5.5 0 01.463.494v3.4a.5.5 0 01-.5.495h-.486a.494.494 0 00-.494.5v.495a.494.494 0 00.494.494h3.958a.494.494 0 00.5-.494v-.495a.494.494 0 00-.5-.5h-.494a.5.5 0 01-.5-.495V5.968a.5.5 0 01.5-.5.1.1 0 01.087.057c.261.577.59 1.283 1 2.137a.986.986 0 00.885.561h.014a.986.986 0 00.886-.548l1.1-2.2a.494.494 0 01.2-.212.968.968 0 00.514-.901.988.988 0 00-.988-.936z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});