define("ember-svg-jar/inlined/study-maths-brain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>study-maths-brain</title><path d=\"M18.523 12.435a.994.994 0 00-1.1.891 6.139 6.139 0 01-2.749 4.418 1 1 0 00-.409.806V23a1 1 0 002 0v-3.721a.5.5 0 01.179-.383 7.937 7.937 0 002.968-5.362 1 1 0 00-.889-1.099zM8.325 3.22C3.006 4.356 1.694 9.428.072 14.609A1.5 1.5 0 001.5 16.567h.61a.5.5 0 01.5.5v.978a3.484 3.484 0 002.97 3.441.5.5 0 01.428.494V23a1 1 0 002 0v-2.477a1 1 0 00-1-1h-.92a1.48 1.48 0 01-1.479-1.478v-2.478a1 1 0 00-1-1h-.743a.5.5 0 01-.477-.65c1.227-3.906 2.427-7.9 6.354-8.741a1 1 0 00-.418-1.956zM23 7.5h-5a1 1 0 100 2h5a1 1 0 100-2z\"/><circle cx=\"20.5\" cy=\"6.001\" r=\"1\"/><circle cx=\"20.5\" cy=\"11.001\" r=\"1\"/><path d=\"M15.5 6V5a.5.5 0 01.5-.5h1a1 1 0 000-2h-1a.5.5 0 01-.5-.5V1a1 1 0 10-2 0v1a.5.5 0 01-.5.5h-1a1 1 0 100 2h1a.5.5 0 01.5.5v1a1 1 0 102 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});