define("ember-svg-jar/inlined/task-list-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-list-share</title><path d=\"M12 7.749H6a.75.75 0 000 1.5h6a.75.75 0 000-1.5zM12.75 12a.75.75 0 00-.75-.75H6a.75.75 0 000 1.5h6a.75.75 0 00.75-.75z\"/><path d=\"M9.615 19.193A.235.235 0 009.386 19H3.5a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v8.293a.248.248 0 00.366.221c.488-.266 1.321-.743 1.6-.9a.23.23 0 00.111-.162 4.239 4.239 0 01.872-1.867.235.235 0 00.051-.15V3.5A1.5 1.5 0 0016.5 2h-4a.241.241 0 01-.208-.121 3.827 3.827 0 00-6.588 0A.241.241 0 015.5 2h-4A1.5 1.5 0 000 3.5v17A1.5 1.5 0 001.5 22h9.462a.246.246 0 00.148-.444 4.221 4.221 0 01-1.495-2.363z\"/><path d=\"M10 14.749H6a.75.75 0 100 1.5h3.884a.244.244 0 00.212-.125 3.763 3.763 0 01.511-.719.255.255 0 00.033-.271.733.733 0 00-.64-.385zM22.129 18.638a2.742 2.742 0 00-2.767.623.24.24 0 01-.258.054l-2.551-1.021a.11.11 0 01-.06-.084l-.03-.352a.121.121 0 01.056-.1L19.442 16a.247.247 0 01.266.012 2.739 2.739 0 004.168-1.425A2.759 2.759 0 0021.25 11a2.808 2.808 0 00-2.713 3.059.243.243 0 01-.115.222L15.558 16a.247.247 0 01-.266-.011 2.74 2.74 0 00-4.168 1.426 2.747 2.747 0 004.513 2.826.246.246 0 01.26-.054s1.923.781 2.463 1.005a.237.237 0 01.144.214 2.753 2.753 0 105.361-1.026 2.683 2.683 0 00-1.736-1.742z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});