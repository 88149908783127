define("ember-svg-jar/inlined/mailbox-post-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mailbox-post-1</title><path d=\"M19.5 5h-15A1.426 1.426 0 003 6.333v.334A1.426 1.426 0 004.5 8h.25a.251.251 0 01.177.073A.255.255 0 015 8.25V20.9l-.415 1.039A1.5 1.5 0 005.974 24H18.02a1.5 1.5 0 001.392-2.058L19 20.9V8.25a.25.25 0 01.25-.25h.25A1.426 1.426 0 0021 6.667v-.334A1.426 1.426 0 0019.5 5zm-3.021 8.688l-4.234 2.275a.474.474 0 01-.49 0l-4.233-2.275a.043.043 0 01-.022-.038.913.913 0 01.923-.9h7.154a.913.913 0 01.923.9.045.045 0 01-.021.038zm.021 1.888V18.6a.913.913 0 01-.923.9H8.423a.913.913 0 01-.923-.9v-3.024a.15.15 0 01.079-.131.158.158 0 01.156 0l3.531 1.855a1.422 1.422 0 001.468 0l3.531-1.847a.158.158 0 01.156 0 .15.15 0 01.079.123zM16 10.25H8a.75.75 0 010-1.5h8a.75.75 0 110 1.5zM7.2 3.5h9.593a.5.5 0 00.284-.912A8.868 8.868 0 0013 1.1V1a1 1 0 00-2 0v.1a8.868 8.868 0 00-4.08 1.485.5.5 0 00.28.915z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});