define("ember-svg-jar/inlined/navigation-previous-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-previous-alternate</title><path d=\"M.289 10.209a1 1 0 010-1.414l3.5-3.5A1 1 0 015.5 6v1.75a.25.25 0 00.25.25h5.75a4 4 0 014 4v2a1.5 1.5 0 01-3 0v-2a1 1 0 00-1-1H5.746a.25.25 0 00-.25.25V13a1 1 0 01-1.707.707z\"/><path d=\"M8 12.5a1 1 0 011 1V18a.5.5 0 00.5.5h12a.5.5 0 00.5-.5V6a.5.5 0 00-.5-.5H9a1 1 0 11-2 0 2 2 0 012-2h13a2 2 0 012 2v13a2 2 0 01-2 2H9a2 2 0 01-2-2v-5a1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});