define("ember-svg-jar/inlined/sea-transport-yacht", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sea-transport-yacht</title><path d=\"M15.381 7.037l5.994.823c.066.009.112-.057.12-.112l.143-1.05a.253.253 0 01.076-.147l2.131-2.025a.5.5 0 00-.277-.857l-3.059-.419a1.5 1.5 0 00-1.11.291l-4.175 3.17c-.095.072-.114.278.157.326zM9.064 9.2L21.3 10.871a.25.25 0 00.282-.213l.169-1.235a.5.5 0 00-.427-.563l-9.583-1.312a2.5 2.5 0 00-1.851.485l-.943.716a.249.249 0 00.117.451zM23.784 12.221L2.225 9.268a.5.5 0 00-.492.76l2.713 4.341A4.4 4.4 0 007 16.236s.941.165 1.033.183a.773.773 0 00.355-.077 2.448 2.448 0 011.2-.326 2.51 2.51 0 011.76.719.247.247 0 00.314.036l4.28-2.924a3.1 3.1 0 013.56-.039 3.005 3.005 0 01.9 1.064.284.284 0 00.363.124l.563-.238a2.952 2.952 0 012.312-.032.251.251 0 00.36-.226v-2.031a.25.25 0 00-.216-.248z\"/><path d=\"M21.945 16.128l-2.627 1.109a.2.2 0 01-.251-.253c.05-.163.088-.26.088-.26a1.524 1.524 0 00-.47-1.656 1.459 1.459 0 00-1.754-.077L11.56 18.66a.4.4 0 01-.262.034 1.56 1.56 0 01-.843-.694 1 1 0 00-.858-.489 1 1 0 00-.86.488A1.478 1.478 0 016.15 18a1 1 0 00-.858-.489 1 1 0 00-.86.489 1.509 1.509 0 01-1.294.762c-.887 0-1.139-.218-1.279-.452a1 1 0 10-1.718 1.023 3.286 3.286 0 003 1.431 3.446 3.446 0 002.014-.646.234.234 0 01.276 0 3.462 3.462 0 004.029 0 .236.236 0 01.279 0 3.471 3.471 0 001.392.592 1.933 1.933 0 001.477-.341l3.859-2.636c.235-.16.207.094.207.094a1.521 1.521 0 00.388 1.578 1.435 1.435 0 001.6.282l3.076-1.3c.137-.058.266-.027.266.129v.306a1 1 0 102 0v-1.081a1.557 1.557 0 00-.419-1.3 1.45 1.45 0 00-1.64-.313z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});