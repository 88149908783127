define("ember-svg-jar/inlined/common-file-stack-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-stack-alternate</title><path d=\"M21.482 3.569L18.618.537A1.819 1.819 0 0017.321 0H11a2 2 0 00-2 2v1.5H6.5a1 1 0 00-1 1V7H3a1 1 0 00-1 1v14.167A1.835 1.835 0 003.833 24H14.5a1 1 0 001-1v-2.5H18a1 1 0 001-1V17h1.167A1.835 1.835 0 0022 15.167V4.845a1.8 1.8 0 00-.518-1.276zM13 22H4.5a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h.75a.25.25 0 01.25.25v9.417A1.833 1.833 0 007.333 20.5h5.917a.25.25 0 01.25.25v.75a.5.5 0 01-.5.5zm3.5-3.5H8a.5.5 0 01-.5-.5V6a.5.5 0 01.5-.5h.75a.25.25 0 01.25.25V15a2 2 0 002 2h5.75a.25.25 0 01.25.25V18a.5.5 0 01-.5.5zm3-3.5h-8a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h5.535a.5.5 0 01.363.157l2.465 2.61A.5.5 0 0120 5.11v9.39a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});