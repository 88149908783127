define("ember-svg-jar/inlined/human-resources-offer-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-offer-man</title><path d=\"M1.25 14.749a1 1 0 00-1 1V23a1 1 0 002 0v-7.25a1 1 0 00-1-1.001zM15.75 19.5H6.625a.625.625 0 010-1.25H9.15a1 1 0 00.92-1.393A2.234 2.234 0 008.014 15.5H3.75a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.764a2.238 2.238 0 002.236-2.236V20.5a1 1 0 00-1-1zM23.132 10.983c-.454-.908-1.9-1.441-3.909-2.178l-.4-.147a.249.249 0 01-.163-.234V7.59a.248.248 0 01.073-.176 2.629 2.629 0 00.756-1.514.251.251 0 01.052-.123 1.386 1.386 0 00.246-.543A1.54 1.54 0 0019.6 4a.017.017 0 010-.017 4.861 4.861 0 00.466-2.483C19.854.439 18.486.03 17.276 0a3.328 3.328 0 00-2.495.773.252.252 0 01-.137.086 1.242 1.242 0 00-.734.47 3.059 3.059 0 00-.018 2.523.251.251 0 01-.029.2 1.583 1.583 0 00-.15 1.178 1.386 1.386 0 00.246.543.245.245 0 01.052.123 2.629 2.629 0 00.753 1.512.248.248 0 01.073.176v.833a.249.249 0 01-.163.234l-.4.148c-2.006.738-3.455 1.27-3.907 2.178A6.213 6.213 0 009.75 13.5a.5.5 0 00.5.5h13a.5.5 0 00.5-.5 6.213 6.213 0 00-.618-2.517z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});