define("ember-svg-jar/inlined/single-woman-actions-cart.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-cart</title><path d=\"M15.812 21.144a1.423 1.423 0 101.423 1.422 1.425 1.425 0 00-1.423-1.422zM19.514 21.154a1.423 1.423 0 101.423 1.423 1.424 1.424 0 00-1.423-1.423zM11.147 15.126a2 2 0 011.9-2.636.246.246 0 00.085-.477c-.509-.188-1.048-.383-1.611-.59a.779.779 0 01-.513-.73v-.467a.25.25 0 01.249-.25 6.377 6.377 0 003.787-.9.388.388 0 00.084-.593c-.494-.537-.836-1.185-.981-3.317C13.881 1.346 11.279 0 9.043 0S4.2 1.346 3.943 5.171C3.8 7.3 3.456 7.951 2.962 8.488a.387.387 0 00-.1.316.393.393 0 00.182.277 6.359 6.359 0 003.782.9.249.249 0 01.254.25v.462a.778.778 0 01-.512.73c-2.9 1.065-5.211 1.825-5.768 2.922a7.867 7.867 0 00-.76 2.731.389.389 0 00.1.3.4.4 0 00.29.126H11.6a.25.25 0 00.237-.33z\"/><path d=\"M23.444 10.789a.746.746 0 00-.949.474l-.852 2.555a.25.25 0 01-.237.171h-8.363a.5.5 0 00-.474.659l1.846 5.5a.5.5 0 00.473.341h6.232a.5.5 0 00.481-.365l2.318-8.387a.749.749 0 00-.475-.948z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});