define("ember-svg-jar/inlined/police-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-badge</title><path d=\"M22 15.5a2.023 2.023 0 00-.463.052.252.252 0 01-.268-.108l-2.112-3.279a.251.251 0 010-.27l2.123-3.337a.249.249 0 01.269-.109A1.964 1.964 0 0022 8.5a2 2 0 10-1.888-2.665.25.25 0 01-.235.165h-3.544a.254.254 0 01-.188-.084l-2.363-2.668a.249.249 0 01-.031-.287A1.977 1.977 0 0014 2a2 2 0 00-4 0 1.967 1.967 0 00.25.961.251.251 0 01-.032.287L7.855 5.916A.253.253 0 017.668 6H4.123a.25.25 0 01-.235-.165A2 2 0 102 8.5a1.964 1.964 0 00.452-.051.249.249 0 01.269.109L4.844 11.9a.251.251 0 010 .27l-2.113 3.274a.252.252 0 01-.268.108 2 2 0 101.425 2.613.25.25 0 01.235-.165h3.545a.253.253 0 01.187.084l2.363 2.668a.251.251 0 01.032.287A1.967 1.967 0 0010 22a2 2 0 004 0 1.977 1.977 0 00-.249-.961.249.249 0 01.031-.287l2.363-2.668a.254.254 0 01.188-.084h3.544a.25.25 0 01.235.165A2 2 0 1022 15.5zM12 16a4 4 0 114-4 4 4 0 01-4 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});