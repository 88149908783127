define("ember-svg-jar/inlined/desktop-monitor-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>desktop-monitor-warning</title><path d=\"M16 16.751a.25.25 0 01-.25.25H2.251a.249.249 0 01-.25-.25v-8.5A.249.249 0 012.251 8h8.33a.253.253 0 00.222-.134l.787-1.5A.25.25 0 0011.369 6H2a2 2 0 00-2 2v10.5a2 2 0 002 2h5.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H5a1 1 0 000 2h8a1 1 0 100-2h-2.75a.249.249 0 01-.25-.25v-1a.249.249 0 01.25-.25H16a2 2 0 002-2v-4.25a.25.25 0 00-.25-.25h-1.5a.249.249 0 00-.25.25z\"/><path d=\"M18.781.772a1.449 1.449 0 00-2.561 0l-5.055 9.633a1.428 1.428 0 00.047 1.409 1.454 1.454 0 001.233.687h10.111a1.456 1.456 0 001.233-.686 1.428 1.428 0 00.047-1.409zM17.5 3.751a.75.75 0 01.75.75v3a.75.75 0 11-1.5 0v-3a.75.75 0 01.75-.75zm-1 6a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});