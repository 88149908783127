define("ember-svg-jar/inlined/audio-file-mp3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file-mp3</title><path d=\"M12.875 15a.877.877 0 00-.875-.875h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H12a.877.877 0 00.875-.875zM17.5 6h3.9a.25.25 0 00.177-.427L16.427.427A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6z\"/><path d=\"M22 7.25a.25.25 0 00-.25-.25H17.5A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2zM9.125 19.5a.625.625 0 01-1.25 0v-3.012c0-.141-.116-.108-.172 0l-.144.289a.65.65 0 01-1.118 0l-.153-.3c-.047-.092-.163-.1-.163 0V19.5a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279l.8 1.59a.152.152 0 00.289.005l.8-1.6a.625.625 0 011.184.279zm4.989-4.278a2.212 2.212 0 01-2.239 1.9h-.5a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0v-6a.625.625 0 01.625-.625H12a2.128 2.128 0 012.114 2.347zm4.586 1.132a.24.24 0 000 .292A2.3 2.3 0 0119.125 18a2.015 2.015 0 01-1.865 2.123h-.757a1.736 1.736 0 01-1.628-1.823.625.625 0 111.25 0c0 .339.2.575.375.575h.754c.337 0 .621-.4.621-.873s-.285-.873-.623-.873a.625.625 0 110-1.25c.338 0 .623-.4.623-.873s-.284-.871-.621-.873h-.757c-.175 0-.372.236-.372.575a.625.625 0 01-1.25 0 1.735 1.735 0 011.625-1.833h.76A2.015 2.015 0 0119.125 15a2.3 2.3 0 01-.425 1.354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});