define("ember-svg-jar/inlined/earth-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earth-share</title><path d=\"M24 21.25a2.752 2.752 0 00-2.75-2.75 2.724 2.724 0 00-1.785.668.5.5 0 01-.513.086l-2.43-.972a.035.035 0 01-.022-.032c0-.053 0-.1-.006-.156a.5.5 0 01.241-.469l2.567-1.54a.5.5 0 01.521 0 2.687 2.687 0 001.427.412 2.75 2.75 0 10-2.75-2.75c0 .052 0 .1.006.155a.5.5 0 01-.241.469L15.7 15.915a.5.5 0 01-.522 0 2.689 2.689 0 00-1.428-.415 2.75 2.75 0 000 5.5 2.724 2.724 0 001.785-.668.5.5 0 01.513-.086l2.431.973a.033.033 0 01.021.031 2.75 2.75 0 005.5 0z\"/><path d=\"M16.229 21.934a.5.5 0 00-.432.029 4.129 4.129 0 01-3.938.073.485.485 0 00-.207-.053 9.946 9.946 0 01-4.011-1 .25.25 0 01-.141-.225v-1.15a2.492 2.492 0 01.893-1.908A4.5 4.5 0 005.5 9.75H2.584a.25.25 0 01-.241-.314 9.982 9.982 0 0114.546-6.154.25.25 0 01-.122.468H14.25a2.75 2.75 0 000 5.5 2.6 2.6 0 012.222 1.258l.466.777a.5.5 0 00.838.029A4.231 4.231 0 0121.25 9.5a4.117 4.117 0 011.738.384.5.5 0 00.7-.566A11.995 11.995 0 107.341 23.059l.159.078v-.02a11.874 11.874 0 009.393-.175.5.5 0 00.279-.582.112.112 0 00-.067-.075z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});