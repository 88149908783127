define("ember-svg-jar/inlined/board-game-dice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>board-game-dice</title><path d=\"M22 0H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zM6.5 2.5A.5.5 0 017 2h3.75a.5.5 0 01.5.5v2a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5zM5 21.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zM5 17a.5.5 0 01-.5.5h-2A.5.5 0 012 17v-3.75a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm0-6.25a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5V7a.5.5 0 01.5-.5h2A.5.5 0 015 7zM5 4.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm6.25 17a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5v-2A.5.5 0 017 19h3.75a.5.5 0 01.5.5zm-.068-6.525l-.707.707a.5.5 0 01-.707 0l-1.414-1.414a.5.5 0 010-.707l.707-.707a.5.5 0 01.707 0l1.414 1.414a.5.5 0 010 .707zM17.5 21.5a.5.5 0 01-.5.5h-3.75a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5H17a.5.5 0 01.5.5zM12.818 9.025l.707-.707a.5.5 0 01.707 0l1.414 1.414a.5.5 0 010 .707l-.707.707a.5.5 0 01-.707 0l-1.414-1.414a.5.5 0 010-.707zM17.5 4.5a.5.5 0 01-.5.5h-3.75a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5H17a.5.5 0 01.5.5zm4.5 17a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm0-4.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-3.75a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm0-6.25a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5V7a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm0-6.25a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});