define("ember-svg-jar/inlined/volume-control-check-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>volume-control-check-1</title><path d=\"M13.864 10.376a.252.252 0 00.136-.223V1a1 1 0 00-1.061-1 9.731 9.731 0 00-7.924 4.85.249.249 0 01-.221.133H3a3 3 0 00-3 3v2a3 3 0 003 3h1.794a.249.249 0 01.221.133 9.161 9.161 0 004.16 3.867.25.25 0 00.357-.2 8.015 8.015 0 014.332-6.407z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-3.394 6.677a.75.75 0 011.061 0l1.094 1.094a.5.5 0 00.754-.053l2.559-3.413a.75.75 0 111.2.9l-2.905 3.874a1.5 1.5 0 01-2.262.159l-1.5-1.5a.75.75 0 01-.001-1.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});