define("ember-svg-jar/inlined/locker-room-hanger-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>locker-room-hanger-woman</title><circle cx=\"18.641\" cy=\"3.5\" r=\"2.5\"/><path d=\"M14.368 12a1 1 0 00-1-1H3.973a.25.25 0 01-.1-.481l6.99-2.934a.519.519 0 01.426.018l2.263 1.146A.986.986 0 0014.9 8.26l.008-.018a.975.975 0 00-.451-1.281l-2.2-1.111a.252.252 0 01-.138-.224V4.8a1.037 1.037 0 00-1.048-1 .9.9 0 11.693-1.534A1 1 0 0013.171.845 2.931 2.931 0 0010.3.111 2.87 2.87 0 008.315 2.1a2.9 2.9 0 001.326 3.3.252.252 0 01.123.231.249.249 0 01-.151.214L2.019 9.136A1.975 1.975 0 002.6 13h10.768a1 1 0 001-1zM14.357 18.5h2.011a.25.25 0 01.25.25V23a1 1 0 001 1h2.023a.977.977 0 00.977-.978V18.75a.25.25 0 01.25-.25h2.012a.5.5 0 00.465-.683c-.4-1.006-1.147-2.868-1.513-3.782a2.988 2.988 0 01-.214-1.112V9.5a2 2 0 00-2-2h-2a2 2 0 00-2 2v3.422a3.02 3.02 0 01-.214 1.115l-1.512 3.777a.5.5 0 00.465.686z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});