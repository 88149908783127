define("ember-svg-jar/inlined/people-man-10", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-10</title><path d=\"M7.375 17.436a14.264 14.264 0 00-5.908 2.481A2.464 2.464 0 00.5 21.894V23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-1.606a2.464 2.464 0 00-.967-1.977 14.264 14.264 0 00-5.908-2.481.5.5 0 00-.48.175c-.083.1-1.263 2.889-4.145 2.889-2.9 0-4.111-2.85-4.145-2.89a.5.5 0 00-.48-.174z\"/><path d=\"M15.587 16.5a7 7 0 003.413-6 61.18 61.18 0 01.365-7.312A3 3 0 0016.371 0H7.629a3 3 0 00-2.994 3.192A62.3 62.3 0 015 10.5a6.985 6.985 0 0010.587 6zM7 10.5V8.972a.251.251 0 01.162-.234A4.576 4.576 0 008.986 7.29a.217.217 0 01.271-.047 5.323 5.323 0 005.465.011.251.251 0 01.309.055 4.553 4.553 0 001.807 1.431.25.25 0 01.162.234V10.5a5 5 0 01-10 0z\"/><circle cx=\"14\" cy=\"10.75\" r=\"1\"/><circle cx=\"10\" cy=\"10.75\" r=\"1\"/><path d=\"M10.457 13.344a.638.638 0 00.317.719 2.31 2.31 0 00.435.18 2.99 2.99 0 00.867.126h.006a2.976 2.976 0 00.863-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2h-2a.639.639 0 00-.624.481z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});