define("ember-svg-jar/inlined/feed-slash-dot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>feed-slash-dot</title><path d=\"M17.41.21A.5.5 0 0017 0h-5a.5.5 0 00-.47.34l-8 23A.5.5 0 004 24h5a.5.5 0 00.47-.34l8-23a.5.5 0 00-.06-.45z\"/><circle cx=\"17.5\" cy=\"21\" r=\"3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});