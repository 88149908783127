define("ember-svg-jar/inlined/vintage-man-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-man-1</title><path d=\"M7.762 17.574a.506.506 0 00-.448-.039L2.92 19.287A4.519 4.519 0 000 23.5a.5.5 0 00.5.5H8a.5.5 0 00.5-.545l-.5-5.5a.5.5 0 00-.238-.381zM6.688 9.164a.747.747 0 00-1-.334 1.885 1.885 0 00-.932 1.809 2.726 2.726 0 001.435 2.432.251.251 0 01.142.188c.504 3.047 4.006 4.991 5.667 4.991s5.163-1.944 5.672-4.992a.251.251 0 01.142-.187 2.726 2.726 0 001.435-2.432 1.885 1.885 0 00-.932-1.809.75.75 0 00-.671 1.341.627.627 0 01.1.439 1.281 1.281 0 01-.557 1.1 1.752 1.752 0 00-1 1.306c-.373 2.244-3.16 3.734-4.189 3.734s-3.816-1.49-4.191-3.738a1.752 1.752 0 00-1-1.306 1.281 1.281 0 01-.557-1.1.627.627 0 01.1-.439.748.748 0 00.336-1.003zM1.837 7.233A49.38 49.38 0 0012 8.34a49.408 49.408 0 0010.163-1.107.75.75 0 00-.326-1.465 44.018 44.018 0 01-3.449.608.249.249 0 01-.273-.171l-1.542-4.878A2 2 0 0013.7.264L12.5.95a1 1 0 01-.992 0L10.3.264a2 2 0 00-2.879 1.081L5.885 6.2a.248.248 0 01-.273.172 44.1 44.1 0 01-3.449-.608.75.75 0 00-.326 1.465zM21.09 19.29l-4.4-1.755a.5.5 0 00-.684.42l-.5 5.5A.5.5 0 0016 24h7.5a.5.5 0 00.5-.5 4.518 4.518 0 00-2.91-4.21zM12.464 19.565a.52.52 0 00-.928 0l-1.5 3.749A.5.5 0 0010.5 24h3a.5.5 0 00.464-.686z\"/><path d=\"M11.929 13.656a2.16 2.16 0 01-.625-.089 1.5 1.5 0 01-.266-.109.6.6 0 00-.568 1.057 2.665 2.665 0 00.482.2 3.43 3.43 0 00.975.142 3.357 3.357 0 00.971-.141 2.685 2.685 0 00.485-.2.6.6 0 10-.573-1.055 1.368 1.368 0 01-.263.108 2.136 2.136 0 01-.618.087zM8.4 10.079a.6.6 0 10.8.891.772.772 0 01.827 0 .6.6 0 10.8-.891 1.937 1.937 0 00-2.427 0zM13.369 11.125a.6.6 0 00.4-.155.774.774 0 01.828 0 .6.6 0 10.8-.891 1.935 1.935 0 00-2.434 0 .6.6 0 00.4 1.046z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});