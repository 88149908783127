define("ember-svg-jar/inlined/nautic-sports-sailing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>nautic-sports-sailing</title><path d=\"M11.251 11.168l11.474 3.291a1 1 0 10.55-1.922s-.33-.059-.347-.34l-.107-1.814A10.445 10.445 0 0017.889 2.1a.408.408 0 01-.213-.49l.083-.289a1 1 0 00-1.922-.55S13.416 9.224 13.361 9.4a.291.291 0 01-.361.191l-1.2-.345a1 1 0 10-.551 1.922zM2.885 18.973a.249.249 0 00.384.039 2.5 2.5 0 013.793.536 2.109 2.109 0 001.488.874 2.8 2.8 0 001.912-1.046 2.579 2.579 0 012-1.006q.1 0 .207.009a.215.215 0 00.25-.136l.535-1.891a.248.248 0 01.3-.173c1.384.4 8.411 2.4 8.494 2.4a1 1 0 001-1.058l-.076-1.288A.251.251 0 0023 16l-10.4-2.984a.25.25 0 00-.309.171l-1.256 4.381a.252.252 0 01-.31.171l-7.919-2.265a1 1 0 00-1.151 1.445s.853 1.457 1.23 2.054z\"/><path d=\"M14 18.72c-.122-.039-.173.049-.105.1a2.481 2.481 0 01.83.976 1.279 1.279 0 001.051.627 2.081 2.081 0 001.148-.473.222.222 0 00.032-.114.487.487 0 00-.348-.4s-2.433-.67-2.608-.716z\"/><path d=\"M22.931 21.911c-1.583.128-2.667-1.555-2.676-1.569a1.048 1.048 0 00-1.716.022 3.372 3.372 0 01-2.752 1.558 2.807 2.807 0 01-2.405-1.481 1.045 1.045 0 00-1.713-.162 4.3 4.3 0 01-3.108 1.643 3.561 3.561 0 01-2.761-1.58 1.04 1.04 0 00-1.638-.091c-.013.019-1.456 1.837-3.109 1.7A.977.977 0 000 22.9a1 1 0 00.947 1.052c.075 0 .148.006.221.006a5.714 5.714 0 003.564-1.466.24.24 0 01.322.007 5.178 5.178 0 003.506 1.424 5.8 5.8 0 003.64-1.361.241.241 0 01.327.017 4.549 4.549 0 003.259 1.344 5.116 5.116 0 003.457-1.344.241.241 0 01.329 0 4.742 4.742 0 003.5 1.328A1 1 0 0024 22.84a1.014 1.014 0 00-1.069-.929z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});