define("ember-svg-jar/inlined/real-estate-deal-key-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-deal-key-1</title><path d=\"M16.75 15.55a.5.5 0 01-1 0v-1.709a.25.25 0 00-.41-.192l-4.161 3.467A.5.5 0 0011.5 18h1a.5.5 0 01.5.5v5a.5.5 0 00.5.5h8a.5.5 0 00.5-.5v-5a.5.5 0 01.5-.5h1a.5.5 0 00.32-.884l-4.89-4.075a.5.5 0 01-.18-.384V6.75A6.737 6.737 0 007.09 2.138a.5.5 0 01-.426.155A5.392 5.392 0 006 2.25a6 6 0 00-2.308 11.534.5.5 0 01.308.461v.091a.5.5 0 01-.147.353l-.53.531a.751.751 0 000 1.06l.53.531a.5.5 0 01.147.353v.172a.5.5 0 01-.147.353l-.53.531a.751.751 0 000 1.06l.53.531a.5.5 0 01.147.353v1.086a.5.5 0 00.146.354l1.324 1.323a.749.749 0 001.06 0L7.853 21.6A.5.5 0 008 21.25v-7.005a.5.5 0 01.307-.461A5.987 5.987 0 009.6 3.46a.5.5 0 01.052-.833A4.7 4.7 0 0112 2a4.756 4.756 0 014.75 4.75zM6 7.75A1.75 1.75 0 117.75 6 1.749 1.749 0 016 7.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});