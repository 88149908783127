define("ember-svg-jar/inlined/single-man.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man</title><path d=\"M22.947 19.219c-.734-1.47-3.241-2.391-6.71-3.666l-1.074-.4a.251.251 0 01-.163-.231V12.8a.252.252 0 01.083-.187 4.252 4.252 0 001.4-2.767.248.248 0 01.071-.153 1.9 1.9 0 00.445-.9 2.29 2.29 0 00-.319-1.931.253.253 0 01-.038-.252 8.277 8.277 0 00.849-4.263C17.159.689 14.822.048 12.922 0c-1.581-.034-3.534.311-4.209 1.364a.251.251 0 01-.172.113 1.846 1.846 0 00-1.257.723c-1.038 1.4.14 4.5.109 4.4a.252.252 0 01-.049.236A2.271 2.271 0 007 8.791a1.9 1.9 0 00.445.9.246.246 0 01.071.153 4.252 4.252 0 001.4 2.767A.252.252 0 019 12.8v2.122a.251.251 0 01-.163.235l-1.076.4c-3.469 1.275-5.975 2.2-6.708 3.666A10.573 10.573 0 000 23.5a.5.5 0 00.5.5h23a.5.5 0 00.5-.5 10.573 10.573 0 00-1.053-4.281z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});