define("ember-svg-jar/inlined/trip-pins", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trip-pins</title><path d=\"M4.5 12.333a.5.5 0 00.354-.146C5.022 12.017 9 8.008 9 5.167a4.5 4.5 0 00-9 0c0 2.841 3.978 6.85 4.146 7.02a.5.5 0 00.354.146zM2.694 5.167A1.584 1.584 0 114.277 6.75a1.585 1.585 0 01-1.583-1.583zM19 10.333a5.006 5.006 0 00-5 5c0 3.167 4.457 7.667 4.646 7.854a.5.5 0 00.708 0C19.543 23 24 18.5 24 15.333a5.006 5.006 0 00-5-5zm0 6.75a1.75 1.75 0 111.75-1.75 1.751 1.751 0 01-1.75 1.75zM4.5 13.833a1 1 0 00-1 1v1.5a1 1 0 002 0v-1.5a1 1 0 00-1-1zM4.5 18.833a1 1 0 00-1 1v1.5a1 1 0 002 0v-1.5a1 1 0 00-1-1zM9.5 20.333H8a1 1 0 000 2h1.5a1 1 0 000-2zM14.5 20.333H13a1 1 0 000 2h1.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});