define("ember-svg-jar/inlined/family-father", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-father</title><path d=\"M8.53 8.355a.249.249 0 01-.019-.436 4.276 4.276 0 10-4.469 0 .248.248 0 01.119.224.251.251 0 01-.138.212 5.059 5.059 0 00-2.8 4.522v3.037a.5.5 0 00.5.5h1.37a.5.5 0 01.5.45l.669 6.687a.5.5 0 00.5.45h3.032a.5.5 0 00.5-.45l.668-6.687a.5.5 0 01.5-.45h1.371a.5.5 0 00.5-.5v-3.037A5.059 5.059 0 008.53 8.355zm-1.5 3.661a.75.75 0 11-1.5 0v-1.8a.75.75 0 011.5 0zm-.75-5.465A2.278 2.278 0 014 4.275a2.252 2.252 0 01.109-.7.253.253 0 01.152-.159.249.249 0 01.219.024 5.412 5.412 0 002.887.83 5.486 5.486 0 00.886-.07.25.25 0 01.29.268 2.278 2.278 0 01-2.267 2.083zM19.756 5.329a2.265 2.265 0 00-1.239-3.837 2.237 2.237 0 00-1.966.635.191.191 0 01-.265 0 2.265 2.265 0 00-3.21 3.2l2.54 2.651a1.115 1.115 0 001.607 0zM17.846 11.473a2.027 2.027 0 102.027-2.027 2.028 2.028 0 00-2.027 2.027zM19.873 14a2.9 2.9 0 00-2.9 2.9v1.759a.5.5 0 00.5.5h.5a.5.5 0 01.5.45l.393 3.938a.5.5 0 00.5.45h1.03a.5.5 0 00.5-.45l.394-3.938a.5.5 0 01.5-.45h.5a.5.5 0 00.5-.5V16.9a2.9 2.9 0 00-2.917-2.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});