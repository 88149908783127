define("ember-svg-jar/inlined/phone-action-heart-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-heart-1</title><path d=\"M12 9.819V18.5a1 1 0 01-1 1H4a1 1 0 01-1-1v-11a1 1 0 011-1h5.006a.249.249 0 00.206-.386 4.968 4.968 0 01-.668-1.9A.244.244 0 008.3 4H3.5A2.5 2.5 0 001 6.5v15A2.5 2.5 0 003.5 24h8a2.5 2.5 0 002.5-2.5V11.383a.25.25 0 00-.07-.173l-1.5-1.564a.25.25 0 00-.43.173zM20.039.047a3.488 3.488 0 00-3.058.987l-.481.482-.482-.482a3.526 3.526 0 00-5.646.916 3.519 3.519 0 00.653 4.062l5.113 5.334a.5.5 0 00.723 0l5.106-5.327A3.524 3.524 0 0020.039.047z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});