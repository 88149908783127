define("ember-svg-jar/inlined/night-moon-half", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>night-moon-half</title><path d=\"M17.5 0a12 12 0 000 24 1 1 0 001-1V1a1 1 0 00-1-1zm-1 21.916a.034.034 0 01-.01.023.032.032 0 01-.024.008 10 10 0 01-.248-19.865.25.25 0 01.282.248z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});