define("ember-svg-jar/inlined/ui-phone-slider-horizontal-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ui-phone-slider-horizontal-alternate</title><path d=\"M23 6a1 1 0 00-1 1v10a1 1 0 002 0V7a1 1 0 00-1-1zM20.03 10.47a.75.75 0 00-1.28.53v2a.75.75 0 001.28.53l1-1a.749.749 0 000-1.06zM1 6a1 1 0 00-1 1v10a1 1 0 002 0V7a1 1 0 00-1-1zM4.787 10.307a.749.749 0 00-.817.163l-1 1a.749.749 0 000 1.06l1 1a.746.746 0 00.53.22.75.75 0 00.75-.75v-2a.75.75 0 00-.463-.693z\"/><rect x=\"6.25\" y=\"5.25\" width=\"11.5\" height=\"13.5\" rx=\"1.75\" ry=\"1.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});