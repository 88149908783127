define("ember-svg-jar/inlined/photo-frame-hang", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>photo-frame-hang</title><path d=\"M24 7.5A2.5 2.5 0 0021.5 5h-3.738a.25.25 0 01-.172-.069L12.688.275a1 1 0 00-1.376 0L6.41 4.931A.25.25 0 016.238 5H2.5A2.5 2.5 0 000 7.5v14A2.5 2.5 0 002.5 24h19a2.5 2.5 0 002.5-2.5zM11.828 2.543a.249.249 0 01.344 0l2.133 2.026a.25.25 0 01-.172.431H9.867a.25.25 0 01-.167-.431zM9.009 21.5a.25.25 0 01-.206-.393c.26-.374.539-.79.824-1.241a.252.252 0 01.212-.116h4.322a.252.252 0 01.212.116c.285.451.564.867.824 1.241a.25.25 0 01-.206.393zm2-3.25a.25.25 0 01-.221-.368c.35-.654.685-1.346.984-2.059a.25.25 0 01.462 0c.3.713.635 1.405.984 2.059a.251.251 0 01-.221.368zM21.5 20.5a1 1 0 01-1 1h-2.4a.255.255 0 01-.2-.1c-1.187-1.521-4.9-6.625-4.9-10.9a1 1 0 00-2 0c0 4.275-3.713 9.379-4.9 10.9a.252.252 0 01-.2.1H3.5a1 1 0 01-1-1v-12a1 1 0 011-1h17a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});