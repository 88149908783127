define("ember-svg-jar/inlined/water-pitcher-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-pitcher-2</title><path d=\"M20.81 5.089h-2.083a.249.249 0 01-.25-.25v-.806a1 1 0 00-.883-.994L3.87 1.418a1 1 0 00-.917 1.593l.729.971a5 5 0 01.985 2.607l1 11.54a.241.241 0 00.242.228c1.529-.006 10.47-.02 12.253-.022.2 0 .31-.107.31-.278v-.173A11.133 11.133 0 0024 8.211a3.176 3.176 0 00-3.19-3.122zm-1.916 10.042a.255.255 0 01-.417-.2v-7.6a.249.249 0 01.25-.25h2.083A1.192 1.192 0 0122 8.276a9.125 9.125 0 01-3.106 6.855zM18.227 19.844H6.094a.25.25 0 00-.249.272l.136 1.56a1 1 0 001 .913h10.5a1 1 0 001-1v-1.495a.25.25 0 00-.254-.25z\"/><path d=\"M1.564 5.344C1.3 5.81 0 8.176 0 9.089a2 2 0 004 0c0-.913-1.3-3.279-1.564-3.745a.521.521 0 00-.872 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});