define("ember-svg-jar/inlined/primitive-symbols-mother", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>primitive-symbols-mother</title><path d=\"M6.992 8.1a3.952 3.952 0 001.486-.784.272.272 0 01.256.006 4.2 4.2 0 006.548-.022.213.213 0 01.209-.014 3.977 3.977 0 001.52.811.992.992 0 001.2-.719 1.006 1.006 0 00-.71-1.219A1.764 1.764 0 0116.247 4.6a4.249 4.249 0 00-8.5.028A1.645 1.645 0 016.5 6.157a1 1 0 00-.719 1.21 1.08 1.08 0 001.211.733z\"/><path d=\"M19.323 22.26L16.1 12.576a4.317 4.317 0 00-8.19 0L4.677 22.26a1.038 1.038 0 00.984 1.365h12.678a1.038 1.038 0 00.984-1.365zm-4.97-.281a.553.553 0 01-.707 0l-1.469-1.47a.25.25 0 00-.354 0l-1.47 1.47a.524.524 0 01-.707 0 .5.5 0 010-.708l1.781-1.78a.249.249 0 00.073-.177v-1.679s-.07-.167-.181-.114c-.385.186-1.261.6-1.319.6a.5.5 0 01-.224-.947s1.094-.543 1.217-.609.1-.161.047-.192a2 2 0 111.931-.006c-.058.033-.058.151.069.215l1.184.592a.5.5 0 01-.224.947c-.061 0-1-.452-1.363-.625-.085-.041-.137.107-.137.107v1.707a.249.249 0 00.073.177l1.78 1.78a.5.5 0 010 .712z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});