define("ember-svg-jar/inlined/loading-circle-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>loading-circle-2</title><path d=\"M12 24A12 12 0 100 12a12.013 12.013 0 0012 12zm6.451-12.813a1 1 0 011.986-.24A8.957 8.957 0 0120.5 12a8.577 8.577 0 01-.084 1.2 1 1 0 01-.989.859 1.116 1.116 0 01-.142-.009 1 1 0 01-.85-1.131A6.558 6.558 0 0018.5 12a6.793 6.793 0 00-.049-.815zm.029 4.72a1 1 0 01.156 1.4A8.531 8.531 0 0117 18.87a1 1 0 01-1.178-1.616 6.548 6.548 0 001.25-1.194 1 1 0 011.408-.155zm-5.667 4.553q-.394.037-.8.038H12a8.786 8.786 0 01-1.117-.072 1 1 0 11.255-1.983c.3.037.575.044.876.055q.312 0 .613-.029a1 1 0 11.188 1.991zM9.849 4.538a1 1 0 01.311 1.976A5.445 5.445 0 005.5 12a6.488 6.488 0 002.339 4.994 1 1 0 01-1.281 1.536A8.483 8.483 0 013.5 12a7.412 7.412 0 016.349-7.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});