define("ember-svg-jar/inlined/single-woman-news.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-news</title><path d=\"M24 2.5A1.5 1.5 0 0022.5 1h-21A1.5 1.5 0 000 2.5V17a1.5 1.5 0 001.5 1.5h9a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5H4a1 1 0 000 2h16a1 1 0 000-2h-6.5a.5.5 0 01-.5-.5V19a.5.5 0 01.5-.5h9A1.5 1.5 0 0024 17zM10.74 15a.24.24 0 01-.177-.079.248.248 0 01-.062-.188 5.043 5.043 0 01.465-1.716c.337-.69 1.749-1.167 3.522-1.837a.489.489 0 00.313-.458v-.2a.249.249 0 00-.251-.25 3.722 3.722 0 01-2.215-.567.247.247 0 01-.051-.372c.3-.338.51-.745.6-2.086a3.12 3.12 0 116.234 0c.089 1.341.3 1.748.6 2.086a.247.247 0 01-.051.372 3.7 3.7 0 01-2.216.556.251.251 0 00-.25.25v.208a.489.489 0 00.313.458c1.773.67 3.184 1.147 3.522 1.837a5.043 5.043 0 01.465 1.716.249.249 0 01-.062.187.237.237 0 01-.177.08H10.74zM9 4.5v5a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});