define("ember-svg-jar/inlined/kitchenware-scooper-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchenware-scooper-1</title><path d=\"M18.442.409L18.432.4a6.242 6.242 0 00-1.51-.38 1.729 1.729 0 00-1.95 1.411 5.28 5.28 0 01-4.18 4.23 1.718 1.718 0 00-1.42 1.929 6.426 6.426 0 00.38 1.54l.01.011a6.748 6.748 0 108.68-8.73zm-6 8.183a1.149 1.149 0 01-1-.987.233.233 0 01.2-.243 6.366 6.366 0 005.034-5.082.23.23 0 01.253-.187 1.129 1.129 0 01.969.98 4.917 4.917 0 01-5.457 5.519zM2.359 19.741a.316.316 0 00-.439-.01 2.5 2.5 0 003.535 3.535.28.28 0 00-.007-.386zM12.712 15.311a.5.5 0 00-.014-.693l-2.179-2.178a.5.5 0 00-.692-.014l-6.2 5.729a.5.5 0 00-.017.718l2.668 2.711a.5.5 0 00.366.149A.5.5 0 007 21.57z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});