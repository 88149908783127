define("ember-svg-jar/inlined/walking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>walking</title><circle cx=\"11.369\" cy=\"3.284\" r=\"2.5\"/><path d=\"M13.56 11.18a8.518 8.518 0 002.418 1.266l1.176.392a1.518 1.518 0 00.475.077 1.5 1.5 0 001.483-1.275 1.551 1.551 0 00-1.083-1.673L16.924 9.6a5.514 5.514 0 01-1.564-.82l-1.016-.762a5.7 5.7 0 00-5.963-.54 6.319 6.319 0 00-3.511 5.571 1.5 1.5 0 001.5 1.613 1.5 1.5 0 001.5-1.426 3.413 3.413 0 011.622-2.944.25.25 0 01.38.214v1.357a19.425 19.425 0 01-.463 4.217 12.745 12.745 0 01-2.213 4.7 1.5 1.5 0 002.344 1.873 17.332 17.332 0 002.56-5.309.25.25 0 01.433-.081 16.621 16.621 0 012.416 4.927 1.5 1.5 0 001.423 1.026 1.48 1.48 0 00.474-.078 1.5 1.5 0 00.949-1.9 17.2 17.2 0 00-4.568-7.518.993.993 0 01-.355-.754v-1.804a.25.25 0 01.4-.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});