define("ember-svg-jar/inlined/tea-cup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tea-cup</title><path d=\"M20.75 22H1.25a1 1 0 000 2h19.5a1 1 0 000-2zM5.025 6.707a1 1 0 001.414-1.414.769.769 0 010-1.086 2.773 2.773 0 000-3.914 1 1 0 00-1.414 1.414.77.77 0 010 1.086 2.771 2.771 0 000 3.914zM9.525 6.707a1 1 0 001.414-1.414.769.769 0 010-1.086 2.773 2.773 0 000-3.914 1 1 0 00-1.414 1.414.77.77 0 010 1.086 2.771 2.771 0 000 3.914zM14.025 6.707a1 1 0 001.414-1.414.769.769 0 010-1.086 2.773 2.773 0 000-3.914 1 1 0 00-1.415 1.414.77.77 0 010 1.086 2.771 2.771 0 00.001 3.914zM18.75 8h-9.5a.25.25 0 00-.25.25v1.738a.251.251 0 00.139.224A2 2 0 0110.25 12v2.5a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V12a2 2 0 011.111-1.788.252.252 0 00.139-.224V8.25A.25.25 0 007.25 8h-4a1 1 0 00-1 1v3.5a8 8 0 008.532 7.983 7.773 7.773 0 005.108-2.406.25.25 0 01.18-.077h2.68a5 5 0 000-10zm0 8h-1a.25.25 0 01-.228-.351 8.463 8.463 0 00.725-3.423V10.5a.5.5 0 01.5-.5 3 3 0 010 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});