define("ember-svg-jar/inlined/real-estate-location-building-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-location-building-pin</title><path d=\"M8 0a8.009 8.009 0 00-8 8c0 3.343 4.509 10.912 6.576 14.2a.5.5 0 00.924-.26V16a2 2 0 011.105-1.789l3-1.5a2.006 2.006 0 011.79 0l.545.273a.5.5 0 00.682-.246A13.53 13.53 0 0016 8a8.009 8.009 0 00-8-8zm0 11a3 3 0 113-3 3 3 0 01-3 3z\"/><path d=\"M15.724 15.553l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 009 16v7.5a.5.5 0 00.5.5H11a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5V16a.5.5 0 00-.276-.447zM14 20h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zM23.692 12.038l-6-2.5A.5.5 0 0017 10v13.5a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-11a.5.5 0 00-.308-.462zM22 20h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});