define("ember-svg-jar/inlined/diagram-curve-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-curve-down</title><path d=\"M0 6.72A5.286 5.286 0 005.28 12h10.56a2.4 2.4 0 012.4 2.4v3.59a.25.25 0 01-.25.25H16.8a1.44 1.44 0 00-1.018 2.46l2.878 2.878a1.448 1.448 0 001.02.422 1.47 1.47 0 001.02-.424l2.878-2.876a1.44 1.44 0 00-1.018-2.46h-1.19a.25.25 0 01-.25-.25V14.4a5.286 5.286 0 00-5.28-5.28H5.28a2.4 2.4 0 01-2.4-2.4V1.44a1.44 1.44 0 00-2.88 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});