define("ember-svg-jar/inlined/phone-action-share-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-share-1</title><path d=\"M12 11.722V18.5a1 1 0 01-1 1H4a1 1 0 01-1-1v-11a1 1 0 011-1h4.369a.244.244 0 00.237-.194 4.234 4.234 0 01.987-1.9A.246.246 0 009.4 4H3.5A2.5 2.5 0 001 6.5v15A2.5 2.5 0 003.5 24h8a2.5 2.5 0 002.5-2.5v-9.871a.246.246 0 00-.3-.244 4.8 4.8 0 01-1.419.091.252.252 0 00-.281.246z\"/><path d=\"M20.25 7.5a2.744 2.744 0 00-2 .871L15.5 7.273V7.25a2.781 2.781 0 00-.039-.46l3.108-1.866A2.748 2.748 0 1017.5 2.75a2.79 2.79 0 00.039.46l-3.108 1.865a2.75 2.75 0 10.324 4.054l2.746 1.1v.022A2.75 2.75 0 1020.25 7.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});