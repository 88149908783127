define("ember-svg-jar/inlined/tablet-touch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tablet-touch</title><path d=\"M5.92 16.823a.253.253 0 00-.041-.219.248.248 0 00-.2-.1H5.5a1 1 0 01-1-1v-12a1 1 0 011-1h14a1 1 0 011 1v10.678a.249.249 0 00.194.244 6.186 6.186 0 012.884 1.555A.25.25 0 0024 15.8V2.505a2.5 2.5 0 00-2.5-2.5h-19a2.5 2.5 0 00-2.5 2.5v14a2.5 2.5 0 002.5 2.5h3.152a.251.251 0 00.242-.312 3.6 3.6 0 01.026-1.87z\"/><path d=\"M23 24a.75.75 0 00.75-.75v-3A4.756 4.756 0 0019 15.5h-2a.25.25 0 01-.25-.25v-5.5a2.25 2.25 0 00-4.5 0v7.06a.25.25 0 01-.442.16l-.578-.7a2.25 2.25 0 00-3.46 2.877l3.806 4.579a.751.751 0 00.577.271z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});