define("ember-svg-jar/inlined/login-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>login-lock</title><path d=\"M18.008 18.5a1 1 0 00-1 1v.5a.5.5 0 01-.5.5h-5.5a.5.5 0 01-.5-.5V4a.5.5 0 01.5-.5h5.5a.5.5 0 01.5.5v1a1 1 0 002 0V2.5a1 1 0 00-1-1h-7.5v-1A.5.5 0 009.9.012l-9 2a.5.5 0 00-.392.488v19a.5.5 0 00.392.488l9 2a.511.511 0 00.421-.1.5.5 0 00.187-.391v-1h7.5a1 1 0 001-1v-2a1 1 0 00-1-.997zm-10-6a1.5 1.5 0 11-1.5-1.5 1.5 1.5 0 011.5 1.5z\"/><path d=\"M22.492 11h-.25v-.941a2.751 2.751 0 10-5.5 0V11h-.25a1 1 0 00-1 1v4.5a1 1 0 001 1h6a1 1 0 001-1V12a1 1 0 00-1-1zm-4.25-.941a1.251 1.251 0 112.5 0V11h-2.5zm1.25 4.691a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});