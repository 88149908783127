define("ember-svg-jar/inlined/password-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>password-type</title><path d=\"M21.5 2.5h-19A2.5 2.5 0 000 5v14a2.5 2.5 0 002.5 2.5h19A2.5 2.5 0 0024 19V5a2.5 2.5 0 00-2.5-2.5zM21 14.75h-4a.75.75 0 010-1.5h4a.75.75 0 010 1.5zm-6.469-1.28a.749.749 0 010 1.06.768.768 0 01-1.061 0l-1.293-1.293a.25.25 0 00-.353 0l-1.293 1.293a.757.757 0 01-1.061 0 .749.749 0 010-1.06l1.293-1.293a.25.25 0 000-.354L9.47 10.53a.75.75 0 011.061-1.06l1.293 1.293a.25.25 0 00.353 0L13.47 9.47a.75.75 0 011.061 1.06l-1.293 1.293a.25.25 0 000 .354zm-7 0a.749.749 0 010 1.06.759.759 0 01-1.06 0l-1.294-1.293a.252.252 0 00-.354 0L3.53 14.53a.768.768 0 01-1.06 0 .749.749 0 010-1.06l1.293-1.293a.252.252 0 000-.354L2.47 10.53a.75.75 0 011.06-1.06l1.293 1.293a.252.252 0 00.354 0L6.47 9.47a.75.75 0 011.06 1.06l-1.293 1.293a.252.252 0 000 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});