define("ember-svg-jar/inlined/crime-man-thief-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-man-thief-1</title><path d=\"M12.98 12.852a.626.626 0 00.3-1.177 2.293 2.293 0 00-.427-.177 2.892 2.892 0 00-.845-.123H12a2.945 2.945 0 00-.849.124 2.309 2.309 0 00-.426.176.626.626 0 00.3 1.177z\"/><path d=\"M22.533 18.792a15.02 15.02 0 00-6.821-2.634.25.25 0 01-.212-.247v-1.137a.248.248 0 01.116-.21 6.746 6.746 0 003.134-5.689v-2a6.75 6.75 0 00-13.5 0v2a6.746 6.746 0 003.134 5.689.248.248 0 01.116.21v1.137a.25.25 0 01-.212.247 15.015 15.015 0 00-6.821 2.634A2.467 2.467 0 00.5 20.769v2.606a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-2.606a2.462 2.462 0 00-.967-1.977zM16 7.875a.5.5 0 01-.5.5h-1a.5.5 0 010-1h1a.5.5 0 01.5.5zm-6 0a.5.5 0 01-.5.5h-1a.5.5 0 010-1h1a.5.5 0 01.5.5zM8.849 2.7a.251.251 0 01.4.2v.978a.75.75 0 001.5 0V1.994a.25.25 0 01.5 0v1.381a.75.75 0 001.5 0V1.994a.25.25 0 11.5 0v1.881a.75.75 0 001.5 0V2.9a.25.25 0 01.4-.2 5.245 5.245 0 011.839 2.59.251.251 0 01-.239.326h-9.5a.251.251 0 01-.239-.326A5.245 5.245 0 018.849 2.7zM7.32 11.246a.25.25 0 01.214-.363 6.485 6.485 0 004.313-1.592.25.25 0 01.337 0 6.394 6.394 0 004.282 1.592.25.25 0 01.214.363 5.269 5.269 0 01-9.36 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});