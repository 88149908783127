define("ember-svg-jar/inlined/exotic-food-buns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>exotic-food-buns</title><path d=\"M13.658 16.4a16.4 16.4 0 00-2.939-.377A2 2 0 009.1 16.7l-.746.853a.5.5 0 01-.752 0l-.743-.853a2.006 2.006 0 00-1.617-.679A16.423 16.423 0 002.3 16.4a2.818 2.818 0 00-2.069 2.724v1.449a1.409 1.409 0 001.409 1.41h12.682a1.409 1.409 0 001.409-1.41v-1.448a2.818 2.818 0 00-2.073-2.725zM1.64 14.983h11.82a.5.5 0 00.448-.722l-1.875-3.787a2.9 2.9 0 01-.284-.972.5.5 0 00-.459-.444c-.2-.016-.395-.028-.572-.037A2 2 0 009.1 9.7l-.745.852a.5.5 0 01-.752 0L6.859 9.7a2.005 2.005 0 00-1.617-.68A16.516 16.516 0 002.3 9.4a2.818 2.818 0 00-2.069 2.724v1.449a1.409 1.409 0 001.409 1.41zM1.64 7.983h10.047a.5.5 0 00.434-.252 2.9 2.9 0 011.228-1.155l1.3-.642a4.284 4.284 0 01.682-.268.565.565 0 00.4-.541A2.818 2.818 0 0013.658 2.4a16.493 16.493 0 00-2.939-.378A2.008 2.008 0 009.1 2.7l-.746.853a.5.5 0 01-.752 0L6.859 2.7a2.005 2.005 0 00-1.617-.68A16.516 16.516 0 002.3 2.4 2.818 2.818 0 00.231 5.124v1.449a1.409 1.409 0 001.409 1.41zM23.715 18.126a16.426 16.426 0 00-.966-2.8 2 2 0 00-1.327-1.147l-1.094-.291a.5.5 0 01-.334-.674l.433-1.046a2 2 0 00-.108-1.751 16.464 16.464 0 00-1.642-2.467 2.818 2.818 0 00-3.363-.65l-1.3.643a1.41 1.41 0 00-.638 1.888L19 21.2a1.409 1.409 0 001.888.638l1.3-.643a2.819 2.819 0 001.527-3.069z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});