define("ember-svg-jar/inlined/biking-mountain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>biking-mountain</title><path d=\"M2.119 15.781A4 4 0 10.471 10.37a4 4 0 001.648 5.411zm.117-4.47a2 2 0 11.824 2.706 2 2 0 01-.824-2.707zM11.548 12.97a4.741 4.741 0 011.042-1.983.25.25 0 00-.01-.341l-1.215-1.223a1 1 0 00-1.63.175l-.153.278a3.458 3.458 0 00-.317 2.585l.433 2a.493.493 0 00.135.248l.711.711a.251.251 0 00.42-.116zM6.353 17.646A.5.5 0 006 17.5H.5a.5.5 0 00-.5.5v5.5a.5.5 0 00.5.5h11a.5.5 0 00.353-.854z\"/><path d=\"M19.841 10.94A3.258 3.258 0 0019.5 8l-1.46-2.193A1.25 1.25 0 0017 5.25h-4a1.25 1.25 0 000 2.5h3.2a.25.25 0 01.208.111l1.016 1.524A.75.75 0 0117 10.524l-1.694.461A3.254 3.254 0 0013 13.334l-.713 2.866a1.25 1.25 0 002.426.606l.716-2.863a.751.751 0 01.529-.543l1.695-.461a3.267 3.267 0 002.188-1.999zM10.7 5.873a.984.984 0 00-.3-.228 1 1 0 00-1.113.133l-.737.645a7.008 7.008 0 00-3.617-1.069H4.92a1 1 0 00-.014 2 4.933 4.933 0 012.279.588 5.149 5.149 0 01.8.531 1 1 0 001.286-.026L10.6 7.284a1 1 0 00.1-1.411zM23.6 17.183a1 1 0 00.2-1.4 6.837 6.837 0 00-3.74-2.542c-.1-.028-1.323-.515-1.577.651a1.018 1.018 0 00.764 1.189 5.009 5.009 0 012.949 1.9 1 1 0 001.404.202zM14.25 20a4 4 0 104-4 4.047 4.047 0 00-4 4zm4-2a2 2 0 11-2 2 2 2 0 012-2z\"/><circle cx=\"15\" cy=\"2\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});