define("ember-svg-jar/inlined/video-file-flv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-flv</title><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.427A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6z\"/><path d=\"M17.5 7A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.25a.25.25 0 00-.25-.25zM9 13.125H7a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25H8a.625.625 0 010 1.25H6.875a.25.25 0 00-.25.25V18.5a.625.625 0 01-1.25 0v-5A1.626 1.626 0 017 11.875h2a.625.625 0 010 1.25zm5 6h-2a1.627 1.627 0 01-1.625-1.625v-5a.625.625 0 011.25 0v5a.375.375 0 00.375.375h2a.625.625 0 010 1.25zM19.625 14A8.176 8.176 0 0118 18.875a.625.625 0 01-1 0A8.176 8.176 0 0115.375 14v-1.5a.625.625 0 011.25 0V14a6.91 6.91 0 00.738 3.095c.044.088.237.075.284-.019A6.92 6.92 0 0018.375 14v-1.5a.625.625 0 011.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});