define("ember-svg-jar/inlined/image-file-light-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-light-alternate</title><path d=\"M21.707 5.707L16.293.293A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.465a.5.5 0 01.353.146l4.536 4.536a.5.5 0 01.146.353z\"/><circle cx=\"14.5\" cy=\"16\" r=\"3.5\"/><path d=\"M12.279 12.107a.245.245 0 00.081-.362L9.787 8.312a.252.252 0 00-.421.033l-3.655 6.787a.25.25 0 00.22.369h3.908a.244.244 0 00.24-.21 4.48 4.48 0 012.2-3.184z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});