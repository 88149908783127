define("ember-svg-jar/inlined/cursor-double-click-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-double-click-3</title><path d=\"M10.779 6.148a5 5 0 015.211 3.774 1 1 0 001.94-.484 6.993 6.993 0 00-7.289-5.285A7.111 7.111 0 004.158 10.6 7.018 7.018 0 009.3 17.89a1 1 0 00.525-1.929 5.016 5.016 0 01-3.669-5.213 5.076 5.076 0 014.623-4.6z\"/><path d=\"M10.241 20.092a9.029 9.029 0 01-8.1-9.212 9.106 9.106 0 018.741-8.741 9.028 9.028 0 019.212 8.1 1 1 0 001.99-.2 11.013 11.013 0 00-11.258-9.9A11.133 11.133 0 00.142 10.824a11.036 11.036 0 009.9 11.258h.1a1 1 0 00.1-2z\"/><path d=\"M23.594 21.134l-3.744-3.746a.249.249 0 01.034-.382l2.352-1.628a.913.913 0 00-.257-1.625l-10.492-3.148a.706.706 0 00-.88.879l3.147 10.492a.913.913 0 001.625.258l1.629-2.352a.25.25 0 01.382-.035l3.745 3.746a.913.913 0 001.291 0l1.168-1.168a.911.911 0 000-1.291z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});