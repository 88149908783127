define("ember-svg-jar/inlined/database-share-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-share_1</title><path d=\"M9.187 21.561a.25.25 0 00.2-.427 4.161 4.161 0 01-1.244-2.961.25.25 0 00-.217-.246 12.774 12.774 0 01-6.137-2.212.25.25 0 00-.408.194v1.716c-.005 1.967 3.366 3.601 7.806 3.936zM1.376 13.125c0 1.8 2.831 3.321 6.718 3.822a.25.25 0 00.269-.168 4.24 4.24 0 014.011-2.9 6.639 6.639 0 012.209.662.25.25 0 00.321-.1l.512-.889a.25.25 0 00-.255-.372 35.074 35.074 0 01-4.285.451c-3.5 0-7.155-.833-9.092-2.41a.25.25 0 00-.408.194z\"/><path d=\"M10.877 9.125c-3.505 0-7.156-.833-9.093-2.411a.25.25 0 00-.408.194v1.717c0 2.21 4.254 4 9.5 4a20.038 20.038 0 004.954-.591.248.248 0 00.166-.14 4.253 4.253 0 013.878-2.519.953.953 0 01.132.01.251.251 0 00.265-.16 7.542 7.542 0 00.106-2.317.25.25 0 00-.408-.193c-1.937 1.578-5.588 2.41-9.092 2.41z\"/><ellipse cx=\"10.877\" cy=\"4.125\" rx=\"9.5\" ry=\"4\"/><path d=\"M19.874 10.875a2.753 2.753 0 00-2.75 2.75 2.453 2.453 0 00.063.539.25.25 0 01-.116.268l-2.665 1.6a.25.25 0 01-.288-.022 2.752 2.752 0 10.311 3.925.252.252 0 01.281-.067l2.272.909a.25.25 0 01.156.246 2.758 2.758 0 10.681-1.706.248.248 0 01-.281.067l-2.264-.906a.249.249 0 01-.156-.253 2.1 2.1 0 00-.057-.638.25.25 0 01.116-.268l2.665-1.6a.252.252 0 01.288.022 2.72 2.72 0 001.744.634 2.75 2.75 0 000-5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});