define("ember-svg-jar/inlined/family-outdoors-teeter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-outdoors-teeter</title><path d=\"M23.964 9.034A1.5 1.5 0 0022.172 7.9l-2.059.462a.5.5 0 01-.6-.378l-.229-1.012a1.249 1.249 0 10-2.437.552l.227 1.005a.5.5 0 01-.378.6l-2.568.571a.5.5 0 01-.423-.1 2.712 2.712 0 00-4.142.93.5.5 0 01-.34.268l-2.771.622a.5.5 0 01-.6-.378l-.223-.987a1.25 1.25 0 00-2.438.552l.222.98a.5.5 0 01-.378.6l-1.866.419a1.5 1.5 0 10.656 2.927L8.69 14a.25.25 0 01.3.271l-.31 2.902a.751.751 0 00.189.582.744.744 0 00.557.245h5.149a.752.752 0 00.746-.831l-.445-4.109a.5.5 0 01.388-.542l7.564-1.7a1.5 1.5 0 001.136-1.784zM12 12.754a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});