define("ember-svg-jar/inlined/tag-euro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tag-euro</title><path d=\"M22.5 0H14a2.9 2.9 0 00-2.061.854L.5 12.293a1.707 1.707 0 000 2.414L9.293 23.5a1.707 1.707 0 002.414 0l11.439-11.439A2.892 2.892 0 0024 10V1.5A1.5 1.5 0 0022.5 0zm-5 11.089a1 1 0 11-1.413 1.411l-3.221-3.22a.248.248 0 00-.337-.015 8.31 8.31 0 00-1.014 1.012.251.251 0 00.015.338l1.846 1.846a1 1 0 11-1.414 1.414l-1.218-1.217a.25.25 0 00-.425.151A4.692 4.692 0 0012 16.589 1 1 0 1110.587 18c-2.241-2.24-2.846-4.722-1.816-7.093a.25.25 0 00-.053-.276l-.881-.881a1 1 0 011.414-1.411l.507.506a.248.248 0 00.184.074.252.252 0 00.18-.085q.219-.249.465-.495c.163-.164.329-.318.5-.464a.25.25 0 00.085-.18.247.247 0 00-.073-.185L10.587 7A1 1 0 0112 5.589l.893.893a.253.253 0 00.275.054c2.37-1.006 4.875-.4 7.082 1.8a1 1 0 01-1.414 1.414 4.693 4.693 0 00-3.77-1.675.25.25 0 00-.151.426z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});