define("ember-mobiledoc-dom-renderer/index", ["exports", "ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/index"], function (_exports, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "RENDER_TYPE", {
    enumerable: true,
    get: function () {
      return _index.RENDER_TYPE;
    }
  });
  _exports.default = void 0;
  var _default = _exports.default = _index.default;
});