define("ember-svg-jar/inlined/beacon-remote-train", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beacon-remote-train</title><path d=\"M22.656 6.324c-.494-.52-1.883-1.759-4.13-3.682A7.81 7.81 0 0013.459.75H1a1 1 0 000 2h9.023a.249.249 0 01.214.121L11.7 5.308a4.019 4.019 0 003.43 1.942h1.89a.25.25 0 01.245.3 8.306 8.306 0 00-.038 2.909.255.255 0 01-.057.2.25.25 0 01-.19.088H14a1 1 0 100 2h7.431a2.4 2.4 0 002.289-1.525 4.886 4.886 0 00-1.064-4.898zm-9.241-2.045l-.69-1.15a.252.252 0 010-.252.248.248 0 01.217-.127h.52a5.808 5.808 0 013.766 1.411l.756.65a.25.25 0 01-.163.439H15.13a2.011 2.011 0 01-1.715-.971zm8.469 6.157a.426.426 0 01-.452.314h-1.909a.249.249 0 01-.242-.191 5.819 5.819 0 01.084-3.152.25.25 0 01.235-.157h1.046a.25.25 0 01.171.068c.17.16.3.29.393.384a2.925 2.925 0 01.674 2.734zM22 14.25h-8a1 1 0 100 2h8a1 1 0 100-2zM11 13.75a1.5 1.5 0 00-1.5-1.5h-8a1.5 1.5 0 00-1.5 1.5v8a1.5 1.5 0 001.5 1.5h8a1.5 1.5 0 001.5-1.5zm-4.658 5.79a.251.251 0 00-.091.193V21a.75.75 0 01-1.5 0v-1.266a.248.248 0 00-.091-.193 1.291 1.291 0 01-.492-1 1.333 1.333 0 012.666 0 1.286 1.286 0 01-.492.999zm2.409-.79A.75.75 0 018 18a2.5 2.5 0 00-5 0 .75.75 0 01-1.5 0 4 4 0 018 0 .75.75 0 01-.749.75z\"/><path d=\"M3.38 10.628a2.857 2.857 0 011.226-.706 3.134 3.134 0 011.79 0 2.857 2.857 0 011.226.706 1.031 1.031 0 001.414 0 1 1 0 000-1.414 5 5 0 00-7.07 0 1 1 0 000 1.414 1.066 1.066 0 001.414 0z\"/><path d=\"M1.966 7.457a5 5 0 017.07 0 1 1 0 101.414-1.414 7 7 0 00-9.9 0 1 1 0 001.416 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});