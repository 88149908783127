define("ember-svg-jar/inlined/road-sign-speed-limit-25-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-speed-limit-25-alternate</title><path d=\"M14.009 18h2a.75.75 0 000-1.5h-3a.75.75 0 00-.75.75v1.25c0 1.8 1.473 1.931 2.181 2 .819.076.819.108.819.5a.5.5 0 01-.5.5h-1.75a.75.75 0 000 1.5h1.75a2 2 0 002-2c0-1.8-1.472-1.931-2.18-2-.82-.076-.82-.108-.82-.5v-.25a.25.25 0 01.25-.25zM11.152 20.168a2.084 2.084 0 00.607-1.465 2.2 2.2 0 00-4.292-.7.75.75 0 101.423.474.7.7 0 011.369.223.568.568 0 01-.167.4l-2.613 2.62a.75.75 0 00.53 1.28h3a.75.75 0 000-1.5h-.585a.25.25 0 01-.177-.427zM8.5 8.5a.75.75 0 00-.75.75v5a.75.75 0 001.5 0v-5a.75.75 0 00-.75-.75zM16.5 8.5a.75.75 0 00-.75.75v5a.75.75 0 001.5 0v-5a.75.75 0 00-.75-.75zM20.5 8.5h-2a.75.75 0 000 1.5.25.25 0 01.25.25v4a.75.75 0 001.5 0v-4a.25.25 0 01.25-.25.75.75 0 000-1.5zM7 7.5a.75.75 0 00.75-.75V5.688a.249.249 0 01.2-.245A2.244 2.244 0 007.5 1H7a.75.75 0 00-.75.75v5A.75.75 0 007 7.5zM8 3a.25.25 0 11-.25.25A.25.25 0 018 3zM6.5 13.5h-1a.25.25 0 01-.25-.25v-4a.75.75 0 00-1.5 0v4A1.752 1.752 0 005.5 15h1a.75.75 0 000-1.5zM5.75 5.75a2.553 2.553 0 00-1.3-2.1c-.508-.381-.7-.557-.7-.9A.25.25 0 014 2.5h1A.75.75 0 005 1H4a1.752 1.752 0 00-1.75 1.75 2.553 2.553 0 001.3 2.1c.508.381.7.557.7.9A.25.25 0 014 6H3a.75.75 0 000 1.5h1a1.752 1.752 0 001.75-1.75zM13.143 12.136a.058.058 0 01.107.029v2.085a.75.75 0 001.5 0v-5a.75.75 0 00-1.393-.386l-.643 1.071a.25.25 0 01-.428 0l-.643-1.071a.75.75 0 00-1.393.386v5a.75.75 0 001.5 0v-2.085a.058.058 0 01.107-.029.78.78 0 001.286 0zM19 1a.75.75 0 00-.75.75v5a.75.75 0 00.75.75 2.752 2.752 0 002.75-2.75v-1A2.752 2.752 0 0019 1zm1.25 3.75a.25.25 0 01-.5 0v-1a.25.25 0 01.5 0zM12 7.5h1A.75.75 0 0013 6h-1a.25.25 0 010-.5h1A.75.75 0 0013 4h-1a.25.25 0 01-.25-.25v-1A.25.25 0 0112 2.5h1A.75.75 0 0013 1h-1a1.752 1.752 0 00-1.75 1.75v3A1.752 1.752 0 0012 7.5zM17 6h-1a.25.25 0 010-.5h1A.75.75 0 0017 4h-1a.25.25 0 01-.25-.25v-1A.25.25 0 0116 2.5h1A.75.75 0 0017 1h-1a1.752 1.752 0 00-1.75 1.75v3A1.752 1.752 0 0016 7.5h1A.75.75 0 0017 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});