define("ember-svg-jar/inlined/hardware-nut", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hardware-nut</title><path d=\"M23.736 11.23L18.3 2.69A1.506 1.506 0 0017.026 2H6.966a1.49 1.49 0 00-1.261.69L.266 11.23a1.506 1.506 0 000 1.61l5.439 8.47a1.5 1.5 0 001.261.69h10.07a1.5 1.5 0 001.26-.69l5.43-8.47a1.49 1.49 0 00.01-1.61zM11.956 17a5 5 0 115-5 5 5 0 01-5 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});