define("ember-svg-jar/inlined/protection-shield-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>protection-shield-star</title><path d=\"M24 1.953A1.959 1.959 0 0022.044.006H1.959A1.958 1.958 0 00.013 1.965L0 9.306a15.147 15.147 0 0011.862 14.669.974.974 0 00.194.019 1 1 0 00.2-.021A15.145 15.145 0 0023.988 9.2zM17.287 9.52l-2.24 1.819a.25.25 0 00-.071.294l1.247 2.867a.721.721 0 01-1.026.9l-3.074-1.729a.249.249 0 00-.245 0L8.8 15.4a.72.72 0 01-1.025-.9l1.246-2.863a.25.25 0 00-.071-.294L6.714 9.52a.673.673 0 01.473-1.176h2.582A.249.249 0 0010 8.189l1.352-3.3a.731.731 0 011.3 0L14 8.189a.251.251 0 00.232.155h2.582a.674.674 0 01.473 1.176z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});