define("ember-svg-jar/inlined/app-window-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-clock</title><path d=\"M17.25 24A6.75 6.75 0 1124 17.251 6.758 6.758 0 0117.25 24zm0-11.5A4.75 4.75 0 1022 17.251a4.756 4.756 0 00-4.75-4.751z\"/><path d=\"M19.25 18.251h-2a1 1 0 01-1-1v-2.5a1 1 0 112 0V16a.25.25 0 00.25.25h.75a1 1 0 010 2z\"/><path d=\"M9.246 19.222A.968.968 0 008.3 18.5H3a1 1 0 01-1-1V5.251A.25.25 0 012.25 5h19.5a.25.25 0 01.25.25v4.781a1 1 0 00.388.777l.022.018a.983.983 0 001.59-.777V3a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h5.3a1 1 0 00.95-1.256zM9.654 2a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0A.969.969 0 018 2.5a.972.972 0 01-.154.5.966.966 0 01-1.692 0A.972.972 0 016 2.5a.969.969 0 01.154-.5zm-3.562.091A1 1 0 013.5 1.5a.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.987.987 0 01.092-.408z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});