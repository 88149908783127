define("ember-svg-jar/inlined/style-three-pin-vet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-vet</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm2.25 3.75a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm-4.5 0a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm-4.25 5a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5zm6.5 7c-2.344 0-4.25-1.233-4.25-2.75a2.307 2.307 0 011.066-1.83 3.724 3.724 0 001.21-1.494A2.229 2.229 0 0112 8.25a2.229 2.229 0 011.974 1.426 3.724 3.724 0 001.21 1.494A2.307 2.307 0 0116.25 13c0 1.517-1.906 2.75-4.25 2.75zm5-5.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/><path d=\"M14.25 12.25a.75.75 0 00-.75-.75h-.25a.5.5 0 01-.5-.5v-.25a.75.75 0 00-1.5 0V11a.5.5 0 01-.5.5h-.25a.75.75 0 000 1.5h.25a.5.5 0 01.5.5v.25a.75.75 0 001.5 0v-.25a.5.5 0 01.5-.5h.25a.75.75 0 00.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});