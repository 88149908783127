define("ember-svg-jar/inlined/pufferfish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pufferfish</title><circle cx=\"7\" cy=\"6.25\" r=\"1\"/><circle cx=\"17\" cy=\"6.25\" r=\"1\"/><path d=\"M20.7 14.028a2.208 2.208 0 00.327.026 3.12 3.12 0 001.961-.757 2.789 2.789 0 00.989-2.172 3.016 3.016 0 00-1.213-2.469 3.553 3.553 0 00-2.228-.583A3.951 3.951 0 0021 6.25a3.986 3.986 0 00-6.7-2.93 8.58 8.58 0 00-1.3-.258V1.75a1 1 0 00-2 0v1.312a8.58 8.58 0 00-1.3.258 3.977 3.977 0 00-6.24 4.753 3.553 3.553 0 00-2.228.583 3.016 3.016 0 00-1.213 2.469 2.789 2.789 0 00.989 2.175 3.114 3.114 0 001.961.757 2.208 2.208 0 00.327-.026 8.97 8.97 0 00.554 1.545l-1.365.82a1 1 0 001.03 1.714l1.385-.831a8.92 8.92 0 001.8 1.752l-1.051 1.693a1 1 0 101.7 1.052l1.088-1.76a8.974 8.974 0 002.563.673v1.561a1 1 0 002 0v-1.561a8.958 8.958 0 002.8-.775l1.152 1.862a1 1 0 101.7-1.052l-1.146-1.853a8.9 8.9 0 001.69-1.718l1.591.954a1 1 0 101.029-1.714l-1.605-.963a8.939 8.939 0 00.489-1.402zm-17.439-2.02a.957.957 0 01-1.242-.862 1.041 1.041 0 01.378-.864 1.676 1.676 0 011.02-.224 7.8 7.8 0 00-.156 1.95zM12 9c1.654 0 3 1.01 3 2.25s-1.346 2.25-3 2.25-3-1.01-3-2.25S10.346 9 12 9zM7 4.25a2 2 0 11-2 2 2 2 0 012-2zm5 14.5a7.016 7.016 0 01-6.7-4.985.219.219 0 01.125-.264 13.666 13.666 0 012.281-.71.249.249 0 01.268.117A4.652 4.652 0 0012 15a4.652 4.652 0 004.03-2.092.249.249 0 01.268-.117 13.733 13.733 0 012.255.7.251.251 0 01.143.3A7 7 0 0112 18.75zm5-10.5a2 2 0 112-2 2 2 0 01-2 2zm4.6 2.032a1.041 1.041 0 01.378.864.954.954 0 01-1.242.862 7.805 7.805 0 00-.156-1.95 1.665 1.665 0 011.02.224z\"/><ellipse cx=\"12\" cy=\"11.25\" rx=\"1.5\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});