define("ember-svg-jar/inlined/school-locker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>school-locker</title><path d=\"M23.78 1.333a.493.493 0 00-.466-.051l-3.126.8a.249.249 0 00-.188.246v9.843a.249.249 0 00.185.241l3.074.827a.541.541 0 00.521-.032.492.492 0 00.22-.414V1.747a.492.492 0 00-.22-.414zM18.5 1.754h-8a.25.25 0 00-.25.25v9.242a.25.25 0 00.25.25h8.25a.25.25 0 00.25-.25V2.254a.5.5 0 00-.5-.5zm-5.25 8a.75.75 0 01-1.5 0v-4a.75.75 0 111.5 0zm4.122.62a.8.8 0 01-1.023-.279l-2-3.5a.75.75 0 111.3-.744l2 3.5a.751.751 0 01-.277 1.023zM8.5 13H.25a.25.25 0 00-.25.25v8.008a1.5 1.5 0 001.5 1.5h7a.25.25 0 00.25-.25v-9.262A.25.25 0 008.5 13zm-2 8.25a1 1 0 111-1 1 1 0 01-1 .996zM8.5 1.754h-7a1.5 1.5 0 00-1.5 1.5v7.992a.25.25 0 00.25.25H8.5a.25.25 0 00.25-.25V2a.25.25 0 00-.25-.246zm-2 8.492a1 1 0 111-1 1 1 0 01-1 1zM18.75 13H10.5a.25.25 0 00-.25.25v9.25a.25.25 0 00.25.25h7a1.5 1.5 0 001.5-1.5v-8.004a.25.25 0 00-.25-.246zm-2.25 8.25a1 1 0 111-1 1 1 0 01-1 .996z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});