define("ember-svg-jar/inlined/lucide-siren", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7 12a5 5 0 015-5v0a5 5 0 015 5v6H7v-6zM5 20a2 2 0 012-2h10a2 2 0 012 2v2H5v-2zM21 12h1M18.5 4.5L18 5M2 12h1M12 2v1M4.929 4.929l.707.707M12 12v6\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});