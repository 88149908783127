define("ember-svg-jar/inlined/cloud-folder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-folder</title><path d=\"M24 11.657a5.767 5.767 0 00-5.215-6 .249.249 0 01-.191-.12 7.684 7.684 0 00-14.1 2.294.251.251 0 01-.227.2 4.643 4.643 0 00-3.643 2.243A4.47 4.47 0 000 12.723a4.293 4.293 0 001.235 3.1 4.906 4.906 0 002.571 1.27A1 1 0 005 16.11v-.01a.979.979 0 00-.793-.971 2.979 2.979 0 01-1.568-.733A2.374 2.374 0 012 12.609a2.588 2.588 0 012.586-2.588 2.7 2.7 0 01.535.054.989.989 0 00.811-.184 1 1 0 00.392-.779c.085-5.736 8.293-7.372 10.818-2.044a.974.974 0 00.941.57 3.739 3.739 0 013.017 1.32 3.888 3.888 0 01.89 2.812 3.425 3.425 0 01-1.564 2.718.966.966 0 00-.426.812 1 1 0 001.56.826A5.408 5.408 0 0024 11.657z\"/><path d=\"M18.5 14.7a1 1 0 00-1-1h-4.465a.5.5 0 01-.416-.223l-.7-1.055a.5.5 0 00-.419-.222h-4a1 1 0 00-1 1v8a1 1 0 001 1h10a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});