define("ember-svg-jar/inlined/plane-boarding-pass-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-boarding-pass-1</title><path d=\"M23.2 10.53a1 1 0 00.8-.98V5.5A1.5 1.5 0 0022.5 4h-21A1.5 1.5 0 000 5.5v4.05a1 1 0 00.8.98 1.5 1.5 0 010 2.94 1 1 0 00-.8.98v4.05A1.5 1.5 0 001.5 20h21a1.5 1.5 0 001.5-1.5v-4.05a1 1 0 00-.8-.98 1.5 1.5 0 010-2.94zM5.25 18.25A.75.75 0 116 17.5a.75.75 0 01-.75.75zm0-3.667a.75.75 0 11.75-.75.75.75 0 01-.75.75zm0-3.666a.75.75 0 11.75-.75.75.75 0 01-.75.75zm0-3.667A.75.75 0 116 6.5a.75.75 0 01-.75.75zm13.7 3.989l-6.761 4.2a.416.416 0 01-.459-.014l-2.478-1.74a.229.229 0 01-.036-.322.24.24 0 01.04-.038l1.04-.918a.229.229 0 01.221-.038l1.026.612a.5.5 0 00.542-.02l1.077-.751a.25.25 0 00-.011-.418l-2.81-1.739a.285.285 0 01-.032-.4.305.305 0 01.045-.043l.766-.568A.287.287 0 0111.39 9l4.075 1.517a.5.5 0 00.456-.055L17.7 9.245a1.106 1.106 0 01.876-.167 1.183 1.183 0 01.853.765 1.13 1.13 0 01.066.378 1.175 1.175 0 01-.544 1.018z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});