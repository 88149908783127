define("ember-svg-jar/inlined/beach-swim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beach-swim</title><circle cx=\"16.999\" cy=\"2.5\" r=\"2.5\"/><path d=\"M17.5 18.719a.25.25 0 00.4.2 2.427 2.427 0 011.5-.5 2.359 2.359 0 01.315.022.25.25 0 00.285-.254v-2.94a.252.252 0 00-.349-.23l-2 .858a.248.248 0 00-.151.229zM20 9.75a.5.5 0 011 0v3.855a1.25 1.25 0 102.5 0V10a4 4 0 00-4-4h-5a4 4 0 00-4 4v3.605a1.25 1.25 0 002.5 0V9.75a.5.5 0 011 0v3.109a.25.25 0 00.25.25h5.5a.25.25 0 00.25-.25zM14.735 19.838a1.281 1.281 0 001.051.627 1.521 1.521 0 00.553-.1.25.25 0 00.161-.234v-4.026a.25.25 0 00-.152-.23l-2-.857a.245.245 0 00-.236.021.248.248 0 00-.112.208v3.593a.25.25 0 00.089.191 2.418 2.418 0 01.646.807zM2.984 19.365a2.5 2.5 0 014.089.227 2.115 2.115 0 001.488.874 1.77 1.77 0 00.5-.078.251.251 0 00.173-.191l.754-3.769a.5.5 0 00-.49-.6H9.25v-.5a3.25 3.25 0 00-6.5 0v.5H2.5a.5.5 0 00-.49.6l.568 2.841a.229.229 0 00.406.1zm1.266-4.036a1.75 1.75 0 013.5 0v.25a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25z\"/><path d=\"M22.93 21.954c-1.582.143-2.667-1.556-2.676-1.569a1.048 1.048 0 00-1.715.022 3.376 3.376 0 01-2.753 1.558 2.806 2.806 0 01-2.406-1.482 1.046 1.046 0 00-1.714-.16 4.3 4.3 0 01-3.106 1.642 3.563 3.563 0 01-2.76-1.58 1.039 1.039 0 00-1.637-.091c-.015.019-1.452 1.829-3.11 1.7A1.021 1.021 0 000 22.942C-.009 23.132.043 24 1.169 24a5.835 5.835 0 003.726-1.607 5.245 5.245 0 003.665 1.572 5.863 5.863 0 003.81-1.5 4.576 4.576 0 003.416 1.5 5.155 5.155 0 003.622-1.5 4.8 4.8 0 003.661 1.482A1 1 0 0024 22.883a1.019 1.019 0 00-1.07-.929z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});