define("ember-svg-jar/inlined/office-shelf-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-shelf-1</title><path d=\"M23 22h-4.75a.25.25 0 01-.25-.25v-6a1.252 1.252 0 00-1.25-1.25h-1.5A1.252 1.252 0 0014 15.748v6a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25v-5a1.252 1.252 0 00-1.25-1.25h-1.5a1.252 1.252 0 00-1.25 1.25v5a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25v-7A1.252 1.252 0 005.75 13.5h-1.5A1.252 1.252 0 003 14.748v7a.25.25 0 01-.25.25H1a1 1 0 000 2h22a1 1 0 000-2zM1 11h22a1 1 0 000-2h-1.169a.25.25 0 01-.191-.088.254.254 0 01-.056-.2 1.257 1.257 0 00-.076-.681l-2.276-5.556a1.25 1.25 0 00-1.632-.683l-1.852.759a1.252 1.252 0 00-.682 1.631L16.9 8.653a.25.25 0 01-.024.234.247.247 0 01-.205.113H14.25a.25.25 0 01-.25-.25v-7.5A1.252 1.252 0 0012.75 0h-1.5A1.252 1.252 0 0010 1.248v7.5A.25.25 0 019.75 9h-1a.25.25 0 01-.25-.25v-5.5A1.252 1.252 0 007.25 2h-1.5A1.252 1.252 0 004.5 3.248v5.5A.25.25 0 014.25 9H1a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});