define("ember-svg-jar/inlined/compass-east", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>compass-east</title><path d=\"M23.776 11.608l-8.09-4.78a.455.455 0 00-.644.582l.359.777a9.1 9.1 0 010 7.626l-.359.777a.455.455 0 00.644.582l8.09-4.78a.455.455 0 000-.784zM7.25 4.75A7.25 7.25 0 1014.5 12a7.258 7.258 0 00-7.25-7.25zm-.75 6.5h1.333a.75.75 0 010 1.5H6.5a.25.25 0 00-.25.25v1.333a.418.418 0 00.417.417H9a.75.75 0 010 1.5H6.667a1.919 1.919 0 01-1.917-1.917V9.667A1.919 1.919 0 016.667 7.75H9a.75.75 0 010 1.5H6.667a.418.418 0 00-.417.417V11a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});