define("ember-svg-jar/inlined/book-close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-close</title><path d=\"M23 4a1 1 0 00-1.447-.894L12.224 7.77a.5.5 0 01-.448 0L2.447 3.106A1 1 0 001 4v13.382a1.99 1.99 0 001.105 1.789l9.448 4.729A1.082 1.082 0 0012 24a1.182 1.182 0 00.447-.105l9.453-4.724a1.99 1.99 0 001.1-1.789zM3 6.023a.25.25 0 01.362-.223l7.5 3.75a.252.252 0 01.138.223v11.2a.25.25 0 01-.362.224l-7.5-3.75a.251.251 0 01-.138-.22zm18 11.2a.251.251 0 01-.138.224l-7.5 3.75a.25.25 0 01-.362-.22V9.773a.252.252 0 01.138-.224l7.5-3.75a.25.25 0 01.362.224z\"/><path d=\"M3.547 1.893l8 4.048a1.008 1.008 0 00.9 0l8-4.048a1 1 0 00-.9-1.785l-7.322 3.706a.506.506 0 01-.452 0L4.451.108a1 1 0 00-.9 1.785z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});