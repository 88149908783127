define("ember-svg-jar/inlined/single-neutral-actions-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-key</title><path d=\"M0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.853a.25.25 0 00.239-.178 5.875 5.875 0 011.156-2.122.248.248 0 00.06-.162V13.75a5.5 5.5 0 00-11 0z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M15.457 23.748a4.449 4.449 0 004.266-5.729.252.252 0 01.063-.249l4.067-4.058a.5.5 0 00.147-.354V11.38a.5.5 0 00-.5-.5h-1.979a.5.5 0 00-.343.137l-4.189 3.957a.251.251 0 01-.244.057 4.454 4.454 0 10-1.288 8.717zm-1.586-4.606a1.736 1.736 0 11.509 1.228 1.721 1.721 0 01-.509-1.228zM0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.853a.25.25 0 00.239-.178 5.875 5.875 0 011.156-2.122.248.248 0 00.06-.162V13.75a5.5 5.5 0 00-11 0z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M15.457 23.748a4.449 4.449 0 004.266-5.729.252.252 0 01.063-.249l4.067-4.058a.5.5 0 00.147-.354V11.38a.5.5 0 00-.5-.5h-1.979a.5.5 0 00-.343.137l-4.189 3.957a.251.251 0 01-.244.057 4.454 4.454 0 10-1.288 8.717zm-1.586-4.606a1.736 1.736 0 11.509 1.228 1.721 1.721 0 01-.509-1.228z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});