define("ember-svg-jar/inlined/ranking-first", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ranking-first</title><path d=\"M23.67 10.229l-1.052-2.1a1.872 1.872 0 00-1.619-1H17.5v-.5a.722.722 0 00-.112-.371l-1.269-3.13a1.872 1.872 0 00-1.619-1h-5a1.89 1.89 0 00-1.647 1.059L6.614 6.255a.733.733 0 00-.114.383l.008.488H3a1.872 1.872 0 00-1.619 1L.33 10.229a3.485 3.485 0 00-.33 1.4v8.75a1.5 1.5 0 001.5 1.5h21a1.5 1.5 0 001.5-1.5v-8.75a3.485 3.485 0 00-.33-1.4zm-2.42 7.147a2 2 0 01-3.927.537.75.75 0 011.444-.4.5.5 0 10.483-.633.75.75 0 010-1.5.5.5 0 10-.483-.631.75.75 0 01-1.449-.387 2 2 0 113.492 1.768 1.989 1.989 0 01.44 1.246zm-15.5.5a.75.75 0 010 1.5h-2.5a.75.75 0 01-.585-1.219l2.111-2.637A1.037 1.037 0 005 14.876a.5.5 0 00-1 0 .75.75 0 01-1.5 0 2 2 0 014 0 2.537 2.537 0 01-.555 1.581l-1.133 1.419zm5.729-6.709a.75.75 0 11-.96-1.152l1.086-.907a1 1 0 011.641.768v3.715h.254a.75.75 0 010 1.5h-2.25a.75.75 0 010-1.5h.5v-2.647zm6.268-.541a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h2.978a.249.249 0 01.224.138l.5 1a.248.248 0 01-.011.243.25.25 0 01-.213.119zm-11.5-1.5a.249.249 0 01.25.25v1a.249.249 0 01-.25.25H2.77a.249.249 0 01-.223-.362l.5-1a.249.249 0 01.224-.138zM9.562 4.28a.251.251 0 01.231-.154h4.412a.251.251 0 01.231.154l.522 1.25a.25.25 0 01-.231.346H9.271a.249.249 0 01-.23-.346z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});