define("ember-svg-jar/inlined/style-three-pin-climb-rope", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-climb-rope</title><path d=\"M16.707 1.013a.5.5 0 00-.707.455V5a.75.75 0 11-1.5 0V.691A.5.5 0 0014.093.2 11.33 11.33 0 0012 0C6.21 0 1.5 4.262 1.5 9.5c0 3.308 2.417 6.872 4.9 9.661a.5.5 0 00.874-.332v-.391a6.583 6.583 0 01.972-3.327.75.75 0 011.238.847 5.111 5.111 0 00-.71 2.481V21.4a.5.5 0 00.156.363 45.48 45.48 0 002.113 1.886 1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5a9.463 9.463 0 00-5.793-8.487zM7.5 6.6a2 2 0 112 2 2 2 0 01-2-2zm9.447 10.8a1 1 0 01-1.342-.448l-.724-1.452H11.5a1 1 0 01-.962-.725l-1-3.5a1 1 0 01.568-1.194l3.254-1.395L14.7 6.9a1 1 0 111.6 1.2l-1.5 2.008a1 1 0 01-.39.312l-1.929.867a.5.5 0 00-.266.288.5.5 0 00.024.391l.628 1.258a.5.5 0 00.448.276H15.5a1 1 0 01.894.553l1 2a1 1 0 01-.447 1.347z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});