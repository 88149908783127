define("ember-svg-jar/inlined/adventure-car-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>adventure-car-3</title><path d=\"M5.5 14.375a3.5 3.5 0 103.5 3.5 3.5 3.5 0 00-3.5-3.5zm0 4.5a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M23.875 10.392l-3.714-6.734a2 2 0 00-1.751-1.033h-8.329a2 2 0 00-1.737 1.008L5.706 8.249a.249.249 0 01-.217.126H2.5a2.5 2.5 0 00-2.357 1.666.25.25 0 00.032.228.247.247 0 00.2.106H2.25a.75.75 0 010 1.5h-2a.25.25 0 00-.25.25v2.043a1.207 1.207 0 002.061.853l1.085-1.085a1.9 1.9 0 011.354-.561h2a1.9 1.9 0 011.354.561l2 2a1.49 1.49 0 001.06.439h2.172a1.49 1.49 0 001.06-.439l2-2a1.9 1.9 0 011.354-.561h2a1.9 1.9 0 011.354.561l1.085 1.085A1.207 1.207 0 0024 14.168v-3.293a1.279 1.279 0 00-.125-.483zm-8.625-.267a.25.25 0 01-.25.25h-1.5a1.9 1.9 0 01-1.354-.561l-.586-.585a.251.251 0 010-.354l.512-.513a.252.252 0 01.354 0l.293.293A.75.75 0 1013.78 7.6l-2-2a.75.75 0 00-1.06 1.06l.293.293a.252.252 0 010 .354L9.97 8.345a.106.106 0 01-.073.03H8.368A.25.25 0 018.151 8l1.858-3.25a.249.249 0 01.217-.126H15a.25.25 0 01.25.25zm6.124.127a.251.251 0 01-.215.123H17.5a.25.25 0 01-.25-.25v-5.25a.25.25 0 01.25-.25h.762a.249.249 0 01.219.129l2.9 5.25a.249.249 0 01-.007.248z\"/><path d=\"M18.5 14.375a3.5 3.5 0 103.5 3.5 3.5 3.5 0 00-3.5-3.5zm0 4.5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});