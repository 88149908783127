define("ember-svg-jar/inlined/appliances-rice-cooker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>appliances-rice-cooker</title><path d=\"M2.847 21a.5.5 0 00-.425.763A5.228 5.228 0 007 24h10a5.228 5.228 0 004.578-2.237.5.5 0 00-.425-.763zM21.662 8a.282.282 0 00.28-.313C21.458 3.381 17.19 0 12 0 6.915 0 2.716 3.245 2.092 7.426a.5.5 0 00.5.574zM7.72 6a.5.5 0 01-.42-.232.494.494 0 01-.029-.481C7.924 3.9 9.782 3 12 3s4.076.9 4.733 2.286A.5.5 0 0116.28 6zM22 10a.5.5 0 00-.5-.5h-19a.5.5 0 00-.5.5v9a.5.5 0 00.5.5h5A.5.5 0 008 19v-5.5a1 1 0 011-1h6a1 1 0 011 1V19a.5.5 0 00.5.5h5a.5.5 0 00.5-.5z\"/><path d=\"M13.5 15.25h-3a.75.75 0 010-1.5h3a.75.75 0 010 1.5zM13.5 18.25h-3a.75.75 0 010-1.5h3a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});