define("ember-svg-jar/inlined/tuk-tuk-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tuk-tuk-1</title><path d=\"M12 16a2 2 0 00-2 2v4a2 2 0 004 0v-4a2 2 0 00-2-2z\"/><path d=\"M18.986 19H23a.25.25 0 00.25-.25v-2.5a2.752 2.752 0 00-2.75-2.75 2.683 2.683 0 00-.686.091.25.25 0 01-.314-.242V10.92a2.368 2.368 0 00-.106-.66l-1.81-5.177a.253.253 0 01.033-.228.25.25 0 01.2-.105H18a1 1 0 000-2h-2.5a.25.25 0 01-.25-.25v-.75A1.752 1.752 0 0013.5 0h-3a1.753 1.753 0 00-1.75 1.75v.75a.249.249 0 01-.25.25H6a1 1 0 000 2h.226a.251.251 0 01.236.334c-.6 1.689-1.586 4.459-1.831 5.136a2.43 2.43 0 00-.131.7v2.429a.25.25 0 01-.314.242A2.683 2.683 0 003.5 13.5a2.752 2.752 0 00-2.75 2.75v2.5A.25.25 0 001 19h4a.25.25 0 01.25.25v.5A.25.25 0 015 20H1a.25.25 0 00-.25.25v1.5a2.25 2.25 0 004.5 0v-.95a.25.25 0 01.406-.2 2.948 2.948 0 001.844.65h1.25A.25.25 0 009 21v-3a3 3 0 016 0v3a.25.25 0 00.25.25h1.25a2.948 2.948 0 001.844-.649.251.251 0 01.406.2v.953a2.25 2.25 0 004.5 0v-1.5A.25.25 0 0023 20h-4.014a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25zM8 15.25a1 1 0 111-1 1 1 0 01-1 1zm-.722-5.5a.251.251 0 01-.236-.334c.53-1.483 1.266-3.554 1.6-4.5a.251.251 0 01.238-.166h6.293a.25.25 0 01.236.168l1.571 4.5a.25.25 0 01-.236.333zM16 15.25a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});