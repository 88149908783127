define("ember-svg-jar/inlined/currency-pound-decrease", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-pound-decrease</title><path d=\"M16.973 0a7 7 0 107 7 7.009 7.009 0 00-7-7zm1.452 6.216a.75.75 0 010 1.5h-1.178a.25.25 0 00-.25.25v1.178a3.35 3.35 0 01-.206 1.094.25.25 0 00.235.334h2.125a.75.75 0 010 1.5H14.8a.75.75 0 01-.043-1.5.238.238 0 00.142-.054 2.21 2.21 0 00.6-1.375V7.966a.25.25 0 00-.25-.25h-1.18a.75.75 0 010-1.5h1.178a.25.25 0 00.25-.25v-1.9a2.2 2.2 0 114.4 0 .75.75 0 11-1.5 0 .7.7 0 10-1.4 0v1.9a.25.25 0 00.25.25zM.447 12.685a1.25 1.25 0 011.66-1.87l7.155 6.36a.25.25 0 00.322.009l2.9-2.324a2.163 2.163 0 012.8.1l5.047 4.653a.249.249 0 00.346-.007l1.462-1.462A.5.5 0 0122.5 18a.512.512 0 01.191.038.5.5 0 01.309.462v5a.5.5 0 01-.5.5h-5a.5.5 0 01-.354-.854l1.415-1.414a.252.252 0 00.073-.182.249.249 0 00-.08-.179l-4.583-4.225a.25.25 0 00-.326-.012l-2.9 2.317a2.153 2.153 0 01-2.772-.071z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});