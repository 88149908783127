define("ember-svg-jar/inlined/social-media-google", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-google</title><path d=\"M6.1 7.4a.5.5 0 00.68-.06 7 7 0 0110.32-.13.5.5 0 00.71 0l2.92-2.74a.5.5 0 000-.71 12 12 0 00-17.83.4.5.5 0 00.1.74zM5.24 14.79a.5.5 0 00.14-.53 6.89 6.89 0 010-4.46.5.5 0 00-.16-.55L1.95 6.64a.5.5 0 00-.76.17 11.93 11.93 0 00.15 10.65.5.5 0 00.78.14zM24 10.5a.5.5 0 00-.5-.5h-10a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h4.84a6.79 6.79 0 01-1.14 1.67.5.5 0 000 .67l2.67 3a.5.5 0 00.73 0A12 12 0 0024 12.6zM15.52 18.46a.5.5 0 00-.58-.12 6.93 6.93 0 01-8.1-1.63.5.5 0 00-.7 0l-3 2.68a.5.5 0 000 .71 12 12 0 0015 2.25.5.5 0 00.12-.76z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});