define("ember-svg-jar/inlined/road-sign-right-reverse-turn-ahead", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-right-reverse-turn-ahead</title><path d=\"M23.53 11.47l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0l11-11a.749.749 0 000-1.06zm-7.837-1.183a.751.751 0 01-.693.463h-1a.25.25 0 00-.25.25v1.5a1.252 1.252 0 01-1.25 1.25H11a.25.25 0 00-.25.25v3a.75.75 0 01-1.5 0v-3.5a1.252 1.252 0 011.25-1.25H12a.25.25 0 00.25-.25v-1a.25.25 0 00-.25-.25h-1a.75.75 0 01-.53-1.28l2-2a.749.749 0 011.06 0l2 2a.749.749 0 01.163.817z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});