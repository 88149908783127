define("ember-svg-jar/inlined/water-protection-drop-hold-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-protection-drop-hold-1</title><path d=\"M17 8.643C17 6.516 14.375 2.566 12.811.4a1.041 1.041 0 00-1.622 0C9.625 2.566 7 6.516 7 8.643a4.89 4.89 0 005 5 4.89 4.89 0 005-5zm-4.75 3A3.254 3.254 0 019 8.393a.5.5 0 011 0 2.252 2.252 0 002.25 2.25.5.5 0 010 1zM4.124 15.823a1.4 1.4 0 00-.458 1.823L5 19.863a.5.5 0 11-.857.516l-1.352-2.25a2.472 2.472 0 01.125-2.485.493.493 0 00.084-.276v-2.881a1.5 1.5 0 00-3 0v5.172a4.5 4.5 0 00.986 2.811L3.65 23.8a.5.5 0 00.39.187H8a.5.5 0 00.5-.5v-3.01a4.492 4.492 0 00-.757-2.5c-1.887-1.965-2.477-2.916-3.619-2.154zM22.5 10.987a1.5 1.5 0 00-1.5 1.5v2.881a.493.493 0 00.084.276 2.472 2.472 0 01.125 2.485l-1.352 2.25a.5.5 0 11-.857-.516l1.334-2.217a1.4 1.4 0 00-.458-1.823c-1.142-.762-1.732.189-3.619 2.157a4.492 4.492 0 00-.757 2.5v3.01a.5.5 0 00.5.5h3.96a.5.5 0 00.39-.187l2.664-3.33A4.5 4.5 0 0024 17.659v-5.172a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});