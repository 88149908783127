define("ember-svg-jar/inlined/road-sign-speed-limit-20-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-speed-limit-20-alternate</title><path d=\"M16.024 7.978a3.48 3.48 0 00-3.476 3.476v2.476a3.476 3.476 0 106.952 0v-2.476a3.48 3.48 0 00-3.476-3.476zM17.5 13.93a1.476 1.476 0 01-2.952 0v-2.476a1.476 1.476 0 112.952 0zM9.1 14.956l.963-.723A3.476 3.476 0 104.5 11.454a1 1 0 002 0 1.476 1.476 0 112.362 1.181L4.9 15.606a1 1 0 00.6 1.8h4.952a1 1 0 100-2H9.25a.25.25 0 01-.15-.45z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});