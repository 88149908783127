define("ember-svg-jar/inlined/computer-bug-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>computer-bug-1</title><path d=\"M19.019 8.8a.251.251 0 00.19-.413 9.564 9.564 0 00-2.134-1.851.251.251 0 01-.059-.369 6.572 6.572 0 001.343-2.849 1.751 1.751 0 10-2-.2 4.518 4.518 0 01-1.364 2.292.251.251 0 01-.239.053 9.454 9.454 0 00-5.508 0 .252.252 0 01-.24-.053 4.539 4.539 0 01-1.366-2.296 1.75 1.75 0 10-2 .2 6.578 6.578 0 001.344 2.85.25.25 0 01-.06.369 9.539 9.539 0 00-2.135 1.85.251.251 0 00.19.413zM11.25 10.8a.5.5 0 00-.5-.5H5.014a.51.51 0 01-.2-.043L1.9 8.957a1 1 0 00-.812 1.828l1.476.657a.5.5 0 01.279.59 9.133 9.133 0 00-.234 1.09.5.5 0 01-.5.424H1a1 1 0 000 2h1.115a.5.5 0 01.493.424 9.474 9.474 0 00.334 1.409.5.5 0 01-.258.6l-1.458.711a1 1 0 00.439 1.9.985.985 0 00.438-.1l1.461-.713a.5.5 0 01.63.165 9.5 9.5 0 006.486 4.009.5.5 0 00.57-.5zM12.75 23.452a.5.5 0 00.57.5 9.5 9.5 0 006.486-4.009.5.5 0 01.63-.165l1.461.713a.985.985 0 00.438.1 1 1 0 00.439-1.9l-1.458-.711a.5.5 0 01-.258-.6 9.458 9.458 0 00.334-1.41.5.5 0 01.494-.423H23a1 1 0 000-2h-1.114a.5.5 0 01-.5-.424 9.133 9.133 0 00-.234-1.09.5.5 0 01.279-.59l1.476-.657a1 1 0 00-.807-1.829l-2.911 1.3a.51.51 0 01-.2.043H13.25a.5.5 0 00-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});