define("ember-svg-jar/inlined/common-file-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-edit</title><path d=\"M23.413 3L21 .586A2 2 0 0019.584 0H8a2 2 0 00-2 2v9.582a.25.25 0 00.422.182c.436-.423 1.1-1.061 1.412-1.349A.5.5 0 008 10.043V2.5a.5.5 0 01.5-.5h10.878a.5.5 0 01.353.147l2.122 2.121a.5.5 0 01.147.354V18a.5.5 0 01-.5.5h-9.966a.5.5 0 00-.353.147l-1.426 1.427a.249.249 0 00.177.426H22a2 2 0 002-2V4.415A2 2 0 0023.413 3z\"/><path d=\"M12.035 11.591a2.71 2.71 0 00-3.606.274l-6.58 6.58a.25.25 0 000 .353L5.2 22.151a.249.249 0 00.353 0l6.679-6.679a2.622 2.622 0 00-.197-3.881zM1.061 20.132a.25.25 0 00-.422.127L.011 23.4A.5.5 0 00.5 24a.466.466 0 00.1-.01l3.143-.628a.25.25 0 00.128-.422z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});