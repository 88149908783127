define("ember-svg-jar/inlined/folder-remove-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-remove-alternate</title><path d=\"M2.5 2h4.573a.5.5 0 01.447.276L8.33 3.9A2 2 0 0010.118 5H19a.5.5 0 01.5.5v4.075a.242.242 0 00.179.233 7.854 7.854 0 011.459.573.245.245 0 00.362-.215V5a2 2 0 00-2-2h-9.073a.5.5 0 01-.447-.276l-.809-1.618A2 2 0 007.38 0H2a2 2 0 00-2 2v14.5a2 2 0 002 2h7.3a.245.245 0 00.249-.255c-.017-.4-.017-1.086 0-1.49A.245.245 0 009.3 16.5H2.5A.5.5 0 012 16V2.5a.5.5 0 01.5-.5z\"/><path d=\"M22.1 12.9a6.5 6.5 0 101.9 4.6 6.457 6.457 0 00-1.9-4.6zm-3.358 4.773l1.414 1.414a.75.75 0 11-1.061 1.06l-1.414-1.414a.252.252 0 00-.354 0l-1.414 1.414a.75.75 0 11-1.061-1.06l1.414-1.414a.25.25 0 000-.354l-1.414-1.414a.75.75 0 011.061-1.06l1.414 1.414a.252.252 0 00.354 0l1.414-1.414a.75.75 0 011.061 1.06l-1.414 1.414a.25.25 0 00-.004.358z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});