define("ember-svg-jar/inlined/professions-woman-construction-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-woman-construction-1</title><path d=\"M18.463 16.749a3.8 3.8 0 002.921-1.12.5.5 0 00-.231-.8c-2.4-.77-2.429-4.545-2.408-5.588A.249.249 0 0118.994 9h.506a1 1 0 000-2h-.618a.251.251 0 01-.246-.206 6.687 6.687 0 00-4.05-5.008.25.25 0 01-.149-.18A1.754 1.754 0 0012.692 0h-1.383a1.76 1.76 0 00-1.747 1.606.25.25 0 01-.149.18 6.682 6.682 0 00-4.049 5.008.25.25 0 01-.247.206H4.5a1 1 0 000 2h.506a.25.25 0 01.25.245c.022 1.039 0 4.753-2.409 5.528a.5.5 0 00-.231.8 3.8 3.8 0 002.921 1.12 3.944 3.944 0 002.691-.873.248.248 0 01.3-.034 6.7 6.7 0 006.9.022.249.249 0 01.306.04 4.152 4.152 0 002.729.901zM12.692 1.5a.247.247 0 01.244.207l.838 5a.25.25 0 01-.247.293h-3.054a.25.25 0 01-.247-.291l.837-5a.25.25 0 01.243-.209h1.386zM6.75 10v-.75A.25.25 0 017 9h10a.25.25 0 01.25.25V10a5.25 5.25 0 01-10.5 0z\"/><path d=\"M22.685 19.718C21.232 18.609 17.912 16.75 12 16.75s-9.232 1.859-10.685 2.968A2.708 2.708 0 00.25 21.9v1.35a.75.75 0 001.5 0V21.9a1.221 1.221 0 01.475-.984 9.788 9.788 0 011.153-.748.25.25 0 01.372.219V23.5a.5.5 0 00.5.5h15.5a.5.5 0 00.5-.5v-3.118a.25.25 0 01.372-.219 9.7 9.7 0 011.153.748 1.221 1.221 0 01.475.984v1.355a.75.75 0 001.5 0V21.9a2.708 2.708 0 00-1.065-2.182zM16.5 21a.5.5 0 01-.5.5h-3a.25.25 0 01-.25-.25v-2.727a.25.25 0 01.077-.18.245.245 0 01.183-.07 21.611 21.611 0 013.288.369.25.25 0 01.2.245zm-5.25.25a.25.25 0 01-.25.25H8a.5.5 0 01-.5-.5v-2.113a.25.25 0 01.2-.245 21.611 21.611 0 013.288-.369.251.251 0 01.26.25z\"/><circle cx=\"14\" cy=\"10.48\" r=\"1\"/><circle cx=\"10\" cy=\"10.48\" r=\"1\"/><path d=\"M13.077 12.611h-2a.64.64 0 00-.307 1.2 2.355 2.355 0 00.438.182 3.017 3.017 0 00.869.127 2.977 2.977 0 00.866-.12 2.375 2.375 0 00.441-.183.64.64 0 00-.307-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});