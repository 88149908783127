define("ember-svg-jar/inlined/history-middle-east", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-middle-east</title><circle cx=\"4.75\" cy=\"2\" r=\"2\"/><path d=\"M14.75 11.75c3.344 0 5.7-2.446 6.559-3.5a2 2 0 000-2.5c-.858-1.053-3.216-3.5-6.559-3.5s-5.7 2.447-6.561 3.5a2 2 0 000 2.5c.86 1.05 3.217 3.5 6.561 3.5zm-4.715-5.078A6.822 6.822 0 0114.75 4.25a6.813 6.813 0 014.706 2.413.5.5 0 010 .675A6.808 6.808 0 0114.75 9.75a6.873 6.873 0 01-4.711-2.4.5.5 0 01-.004-.678z\"/><path d=\"M14.75 9.3a2.3 2.3 0 10-2.3-2.3 2.3 2.3 0 002.3 2.3zM7.972 17.882a2.988 2.988 0 01-.809-5.428.5.5 0 00.217-.554 3.373 3.373 0 01-.13-.9V9a2.5 2.5 0 00-5 0v2a3.76 3.76 0 01-.395 1.671L.55 15.283a2.847 2.847 0 00-.3 1.27C.25 18.616 2.78 20 4.75 20a6.1 6.1 0 003.409-1.244.5.5 0 00-.187-.874z\"/><path d=\"M20.407 17.208a13.332 13.332 0 003.305-5.434 1 1 0 00-1.924-.548c-.018.062-1.853 6.274-7.038 6.274a5.131 5.131 0 01-3.976-1.451.5.5 0 01-.111-.486 1.934 1.934 0 00.087-.573 2 2 0 10-2 2 .439.439 0 01.339.162 5.72 5.72 0 00.965.932.5.5 0 01.2.4v1.548a.5.5 0 01-.061.238 3.055 3.055 0 00-.443 1.23 1.5 1.5 0 003 0 3.059 3.059 0 00-.44-1.233.506.506 0 01-.06-.238v-.222a.5.5 0 01.6-.491 9.3 9.3 0 00.948.132.5.5 0 01.456.5v1.083a.5.5 0 01-.061.238 3.055 3.055 0 00-.443 1.231 1.5 1.5 0 003 0 3.059 3.059 0 00-.44-1.233.506.506 0 01-.06-.238V19.76a.5.5 0 01.387-.487 8.022 8.022 0 00.94-.28.5.5 0 01.673.469v.566a.506.506 0 01-.06.238 3.053 3.053 0 00-.44 1.234 1.5 1.5 0 003 0 3.068 3.068 0 00-.44-1.234.492.492 0 01-.06-.238v-2.456a.5.5 0 01.157-.364z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});