define("ember-svg-jar/inlined/file-code-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-question</title><path d=\"M6.5 11a6.5 6.5 0 106.5 6.5A6.508 6.508 0 006.5 11zm0 11.5a1 1 0 111-1 1 1 0 01-1 1zm.939-4.173a.27.27 0 00-.189.273v.15a.75.75 0 01-1.5 0v-.55a1.257 1.257 0 01.988-1.222 1.125 1.125 0 10-1.363-1.1.75.75 0 11-1.5 0 2.625 2.625 0 113.564 2.452zM17.75 11.5V15a.75.75 0 001.5 0v-3.5a.75.75 0 10-1.5 0zM13.75 8.5V5a.75.75 0 00-1.5 0v3.5a.75.75 0 001.5 0zM20.25 8V5.5A1.251 1.251 0 0019 4.251h-1.5A1.251 1.251 0 0016.25 5.5V8a1.252 1.252 0 001.25 1.25H19A1.252 1.252 0 0020.25 8zm-1.5-.5a.25.25 0 01-.25.25H18a.25.25 0 01-.25-.25V6a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25z\"/><path d=\"M23.414 3L21 .587A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.276a.248.248 0 00.242.25 19.68 19.68 0 011.485.083A.247.247 0 008 9.36V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.121a.5.5 0 01.146.355V18a.5.5 0 01-.5.5h-6.858a.245.245 0 00-.241.21 7.935 7.935 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M14.406 15.658a1.227 1.227 0 00.844-1.158V12A1.25 1.25 0 0014 10.751h-1.5a1.208 1.208 0 00-.914.429.193.193 0 00.029.179 8.126 8.126 0 011.008 1.007c.043.051.127.125.127.019a.111.111 0 01.115-.134h.635a.25.25 0 01.25.25v1.589a.235.235 0 00.021.1 7.983 7.983 0 01.484 1.376c.011.04.112.105.151.092z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});