define("ember-svg-jar/inlined/disability-blind-read-book-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-blind-read-book-2</title><path d=\"M13.351 7.243a.249.249 0 00-.131-.419 5.161 5.161 0 00-.991-.1A6.441 6.441 0 007.382 9.15a.5.5 0 000 .649 7.752 7.752 0 001.562 1.389.5.5 0 00.636-.068z\"/><path d=\"M21.75 1a1 1 0 00-1-1H4.25a2 2 0 00-2 2v19a3 3 0 003 3h13.5a2 2 0 002-2v-1.585a.5.5 0 01.333-.472A1 1 0 0021.75 19zm-3 20.5a.5.5 0 01-.5.5h-13a1 1 0 010-2h13a.5.5 0 01.5.5zm-.113-12.788a1.292 1.292 0 010 1.525 8.113 8.113 0 01-6.408 3.488 8.121 8.121 0 01-6.41-3.488 1.294 1.294 0 010-1.526 8.124 8.124 0 016.41-3.486 8.11 8.11 0 016.408 3.487z\"/><path d=\"M15.511 7.76a.5.5 0 00-.635.068l-3.771 3.878a.25.25 0 00.132.42 5.245 5.245 0 00.992.1A6.432 6.432 0 0017.074 9.8a.5.5 0 000-.648 7.724 7.724 0 00-1.563-1.392z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});