define("ember-svg-jar/inlined/font-expand-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>font-expand-horizontal</title><path d=\"M19 19.5h-.981a.251.251 0 01-.237-.17L13.108 5.649a1.14 1.14 0 00-2.216 0l-4.674 13.68a.251.251 0 01-.237.17H5a1 1 0 000 2h4a1 1 0 000-2h-.377a.251.251 0 01-.237-.331l1.2-3.5a.251.251 0 01.237-.169h4.362a.251.251 0 01.237.169l1.2 3.5a.251.251 0 01-.237.331H15a1 1 0 100 2h4a1 1 0 000-2zm-8.327-6a.25.25 0 01-.236-.331l1.326-3.884a.251.251 0 01.474 0l1.326 3.884a.25.25 0 01-.236.331zM4.052 9.35a.749.749 0 001.2-.6v-1.5A.25.25 0 015.5 7h2.25a1 1 0 000-2H5.5a.25.25 0 01-.25-.25v-1.5a.749.749 0 00-1.2-.6L.384 5.4a.751.751 0 000 1.2L4.05 9.349zM23.616 5.4l-3.668-2.753a.751.751 0 00-1.2.6v1.5A.25.25 0 0118.5 5h-2.25a1 1 0 000 2h2.25a.25.25 0 01.25.25v1.5a.751.751 0 001.2.6l3.666-2.75a.75.75 0 000-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});