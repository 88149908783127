define("ember-svg-jar/inlined/headphones-customer-support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>headphones-customer-support</title><path d=\"M24 14.251a4.493 4.493 0 00-3-4.239v-.761a9 9 0 00-18 0v.761A4.5 4.5 0 003 18.5a1.5 1.5 0 002-1.42V9.251a7 7 0 1114 0v7.829a1.494 1.494 0 00.5 1.1v.568c0 1.474-1.291 2-2.5 2h-1.778a2 2 0 100 2H17c2.649 0 4.5-1.645 4.5-4v-.478a4.5 4.5 0 002.5-4.019z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});