define("ember-svg-jar/inlined/notes-tasks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-tasks</title><path d=\"M6.47 10.97a.75.75 0 101.06 1.06l1-1a.681.681 0 01.94 0 2.215 2.215 0 003.06 0 .681.681 0 01.94 0 2.215 2.215 0 003.06 0l1-1a.75.75 0 00-1.06-1.06l-1 1a.681.681 0 01-.94 0 2.215 2.215 0 00-3.06 0 .681.681 0 01-.94 0 2.215 2.215 0 00-3.06 0zM11.47 14.97a.681.681 0 01-.94 0 2.215 2.215 0 00-3.06 0l-1 1a.75.75 0 101.06 1.06l1-1a.681.681 0 01.94 0 2.216 2.216 0 003.061 0 .654.654 0 01.469-.194.75.75 0 000-1.5 2.141 2.141 0 00-1.53.634zM10 6.5h4a1 1 0 001-1V3A3.008 3.008 0 0011.192.107 3.047 3.047 0 009 3.088V5.5a1 1 0 001 1zm1.25-3.75a.75.75 0 11.75.75.75.75 0 01-.75-.75z\"/><path d=\"M19.5 3h-2.75a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25H19a.5.5 0 01.5.5v12.837a.5.5 0 01-.146.353l-3.163 3.163a.5.5 0 01-.354.147H5a.5.5 0 01-.5-.5v-16A.5.5 0 015 5h2.25a.25.25 0 00.25-.25v-1.5A.25.25 0 007.25 3H4.5a2 2 0 00-2 2v17a2 2 0 002 2h15a2 2 0 002-2V5a2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});