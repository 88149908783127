define("ember-svg-jar/inlined/multiple-actions-edit-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-edit-1</title><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.5 14v2.006a.25.25 0 00.427.177l5.492-5.492a1.576 1.576 0 01.16-.136.251.251 0 00.078-.294 4.528 4.528 0 00-7.752-1 .25.25 0 00.005.311A6.959 6.959 0 0112.5 14zM.5 18h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M15.086 22.74a.5.5 0 00-.131-.479l-2.215-2.215a.5.5 0 00-.834.216l-.886 3.1a.5.5 0 00.48.638.492.492 0 00.137-.019l3.1-.886a.5.5 0 00.349-.355zM13.055 18.946l3 3a.5.5 0 00.707 0l5.011-5.01a.5.5 0 000-.707l-3-3a.5.5 0 00-.707 0l-5.011 5.01a.5.5 0 000 .707zM19.48 12.521l3 3a.454.454 0 00.327.125.618.618 0 00.427-.171 2.622 2.622 0 10-3.754-3.661.5.5 0 000 .707z\"/><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.5 14v2.006a.25.25 0 00.427.177l5.492-5.492a1.576 1.576 0 01.16-.136.251.251 0 00.078-.294 4.528 4.528 0 00-7.752-1 .25.25 0 00.005.311A6.959 6.959 0 0112.5 14zM.5 18h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M15.086 22.74a.5.5 0 00-.131-.479l-2.215-2.215a.5.5 0 00-.834.216l-.886 3.1a.5.5 0 00.48.638.492.492 0 00.137-.019l3.1-.886a.5.5 0 00.349-.355zM13.055 18.946l3 3a.5.5 0 00.707 0l5.011-5.01a.5.5 0 000-.707l-3-3a.5.5 0 00-.707 0l-5.011 5.01a.5.5 0 000 .707zM19.48 12.521l3 3a.454.454 0 00.327.125.618.618 0 00.427-.171 2.622 2.622 0 10-3.754-3.661.5.5 0 000 .707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});