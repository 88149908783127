define("ember-svg-jar/inlined/arrow-down-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-down-2</title><path d=\"M3 1.667v14.049a1.5 1.5 0 00.508 1.125l7.5 6.617a1.5 1.5 0 001.985 0l7.5-6.618A1.5 1.5 0 0021 15.716V1.667a1.5 1.5 0 00-1.5-1.5h-15a1.5 1.5 0 00-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});