define("ember-svg-jar/inlined/office-shredder-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-shredder-1</title><path d=\"M8.94 14.5a.749.749 0 00-.718.78c.136 3.358-.189 6.684-.7 7.12a.75.75 0 00.9 1.2c1.433-1.075 1.383-6.219 1.294-8.38a.768.768 0 00-.776-.72zM6.741 15.249a.75.75 0 00-1.5 0v.65c.013 2.18.025 4.433-1.164 5.226a.75.75 0 00.832 1.248c1.861-1.241 1.847-3.905 1.832-6.482zM16.477 22.4c-.51-.433-.84-3.76-.707-7.121a.751.751 0 00-.72-.779.781.781 0 00-.779.72c-.085 2.161-.13 7.3 1.3 8.379a.731.731 0 001.019-.145.771.771 0 00-.113-1.054zM19.921 21.125c-1.2-.8-1.184-3.063-1.173-5.255v-.621a.75.75 0 00-1.5 0v.614c-.013 2.588-.026 5.265 1.841 6.51a.75.75 0 00.832-1.248zM12 14.5a.75.75 0 00-.75.75l.005 8a.75.75 0 001.5 0l-.005-8a.749.749 0 00-.75-.75z\"/><path d=\"M24 8.749A.75.75 0 0023.25 8H19.5a.25.25 0 01-.25-.25V3.5a1 1 0 00-.293-.707l-2.5-2.5A1 1 0 0015.75 0h-9a2 2 0 00-2 2v5.749A.25.25 0 014.5 8H.75a.75.75 0 00-.75.75v6.5a.75.75 0 00.75.75h2.5a.75.75 0 00.75-.75v-2a.25.25 0 01.25-.25h15.5a.25.25 0 01.25.25v2a.75.75 0 00.75.75h2.5a.75.75 0 00.75-.75zM6.75 2.5a.5.5 0 01.5-.5h7.982a.249.249 0 01.177.073l1.768 1.768a.251.251 0 01.073.177V7.5a.25.25 0 01-.25.25H7a.25.25 0 01-.25-.25zM22.5 10a.75.75 0 01-.75.75h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});