define("ember-svg-jar/inlined/briefcase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>briefcase</title><path d=\"M3 22.5h18a3 3 0 003-3v-11a3 3 0 00-3-3h-3.9a.25.25 0 01-.248-.219l-.144-1.153A3 3 0 0013.734 1.5h-3.468a3 3 0 00-2.977 2.628l-.144 1.153A.25.25 0 016.9 5.5H3a3 3 0 00-3 3v11a3 3 0 003 3zM9.273 4.376a1 1 0 01.993-.876h3.468a1 1 0 01.993.876l.105.844a.25.25 0 01-.248.28H9.416a.25.25 0 01-.248-.28zM5.25 11a.75.75 0 01.75-.75h12a.75.75 0 01.75.75v.5a.75.75 0 01-.75.75H6a.75.75 0 01-.75-.75zm0 5.5a.75.75 0 01.75-.75h12a.75.75 0 01.75.75v.5a.75.75 0 01-.75.75H6a.75.75 0 01-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});