define("ember-svg-jar/inlined/arrow-thick-circle-bottom-left-corner-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-circle-bottom-left-corner-2</title><path d=\"M20.489 3.511a12.006 12.006 0 100 16.979 12.017 12.017 0 000-16.979zM12.633 14.53l3.51.151a1.058 1.058 0 011 1.044v.419a.983.983 0 01-1 .982l-8.09-.152a1.06 1.06 0 01-1.027-1.019l-.217-8.02a.972.972 0 01.974-1H8.2a1.072 1.072 0 011.056 1l.194 3.448c.03.55.374.681.763.292l4.6-4.6a1 1 0 011.415 0l.7.7a1 1 0 010 1.415l-4.589 4.586c-.387.392-.255.73.294.754z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});