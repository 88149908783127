define("ember-svg-jar/inlined/file-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code</title><path d=\"M21.414 4.915L17.086.587A2 2 0 0015.672 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.329a2 2 0 00-.586-1.414zM10.864 18.96l-.019.018a.75.75 0 01-1.06-.036l-3.333-3.571a.752.752 0 010-1.025l3.333-3.571.018-.019a.75.75 0 011.078 1.044l-2.695 2.887a.249.249 0 000 .341l2.7 2.89a.751.751 0 01-.022 1.042zm6.684-3.59l-3.333 3.571-.018.019a.75.75 0 01-1.078-1.043l2.7-2.888a.249.249 0 000-.341l-2.7-2.889a.751.751 0 011.1-1.025l3.333 3.571a.752.752 0 01-.004 1.025z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});