define("ember-svg-jar/inlined/video-game-tetris", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-tetris</title><path d=\"M17.5 15h-3a1 1 0 00-1 1v2.5a.5.5 0 01-.5.5H9a1 1 0 00-1 1v3a1 1 0 001 1h8.5a1 1 0 001-1v-7a1 1 0 00-1-1zM23 4h-2.5a.5.5 0 01-.5-.5V1a1 1 0 00-1-1h-2a1 1 0 00-1 1v7a1 1 0 001 1h2.5a.5.5 0 01.5.5V12a1 1 0 001 1h2a1 1 0 001-1V5a1 1 0 00-1-1z\"/><path d=\"M8 17a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V9a.5.5 0 00-.5-.5H.5A.5.5 0 000 9v4a.5.5 0 00.5.5h7a.5.5 0 01.5.5z\"/><path d=\"M5.5 15H1a1 1 0 00-1 1v7a.958.958 0 00.906 1H5.5a1 1 0 001-1v-7a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});