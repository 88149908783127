define("ember-svg-jar/inlined/project-building-shopping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>project-building-shopping</title><path d=\"M10.5 15.25h6a.5.5 0 00.5-.5v-11a.5.5 0 00-.308-.462l-6-2.5A.5.5 0 0010 1.25v13.5a.5.5 0 00.5.5zM2.5 15.25H4a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-7.5a.5.5 0 00-.276-.45l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 002 7.25v7.5a.5.5 0 00.5.5zM4 7.75h3a.5.5 0 010 1H4a.5.5 0 010-1zm0 2.5h3a.5.5 0 010 1H4a.5.5 0 010-1z\"/><circle cx=\"16\" cy=\"21.25\" r=\"2\"/><circle cx=\"5\" cy=\"21.25\" r=\"2\"/><path d=\"M23 1.25h-1.5a1 1 0 00-.991.868l-1.827 13.7a.5.5 0 01-.495.434H1a1 1 0 000 2h18.5a1 1 0 00.991-.868l1.827-13.7a.5.5 0 01.495-.434H23a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});