define("ember-svg-jar/inlined/composition-window-woman-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>composition-window-woman-1-alternate</title><path d=\"M15.015 11.9a3.137 3.137 0 00-6.1 0L7.7 15.854a.5.5 0 00.478.646h1.6a.25.25 0 01.248.219l.418 3.343a.5.5 0 00.5.438h2.118a.5.5 0 00.5-.438l.418-3.343a.25.25 0 01.248-.219h1.592a.5.5 0 00.476-.653z\"/><circle cx=\"12\" cy=\"6\" r=\"2.5\"/><path d=\"M21.5 3a3 3 0 00-3-3h-13a3 3 0 00-3 3v18a3 3 0 003 3h13a3 3 0 003-3zm-2 18a1 1 0 01-1 1h-13a1 1 0 01-1-1V3a1 1 0 011-1h13a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});