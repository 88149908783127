define("ember-svg-jar/inlined/shopping-cart-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-cart-heart</title><path d=\"M11.657 13H8.239A.25.25 0 018 12.808l-1.15-5a.25.25 0 01.239-.308H20.28a.5.5 0 01.474.658l-1.1 3.334a1 1 0 101.9.633L22.9 8.039A1.928 1.928 0 0021.072 5.5H6.514a.25.25 0 01-.243-.194l-.878-3.819A1.922 1.922 0 003.515 0H1a1 1 0 000 2l2.24-.055a.25.25 0 01.25.194l3.357 14.6a.248.248 0 01-.107.265 2.251 2.251 0 102.286-.1.256.256 0 01-.126-.165l-.33-1.437A.25.25 0 018.814 15h2.843a1 1 0 000-2z\"/><path d=\"M23.711 16.125a2.736 2.736 0 00-4.384-.711l-.579.578a.249.249 0 01-.353 0l-.579-.578a2.737 2.737 0 10-3.871 3.871l4.446 4.638a.25.25 0 00.361 0l4.448-4.638a2.738 2.738 0 00.511-3.16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});