define("ember-svg-jar/inlined/messages-bubble-double-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-double-alternate</title><path d=\"M7.189 13.121q-.322-.069-.638-.164a1 1 0 00-.662.028l-2.12.849.49-.859a1 1 0 00-.243-1.275A5.217 5.217 0 012 7.633C2 4.527 5.2 2 9.13 2s7.131 2.526 7.131 5.632a1 1 0 002 0C18.261 3.424 14.165 0 9.13 0S0 3.424 0 7.633a7.144 7.144 0 002.123 5.05L.61 15.331a1 1 0 001.24 1.424l4.466-1.787c.151.04.3.076.457.109a1 1 0 10.416-1.956z\"/><path d=\"M22.687 19.9A5.642 5.642 0 0024 16.251c0-3.653-3.524-6.74-7.7-6.74s-7.7 3.087-7.7 6.74a6.738 6.738 0 003.186 5.6 7.651 7.651 0 006.919.734l3.373 1.336a.961.961 0 001.019-.163.879.879 0 00.311-1.259zm-1.817-.971a1 1 0 00-.257.97L21 21.307l-1.952-.732a.993.993 0 00-.757.023 5.669 5.669 0 01-5.406-.424 4.749 4.749 0 01-2.276-3.923c0-2.525 2.662-4.74 5.7-4.74s5.7 2.215 5.7 4.74a3.679 3.679 0 01-1.139 2.675z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});