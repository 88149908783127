define("ember-svg-jar/inlined/vectors-pen-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vectors-pen-add</title><path d=\"M10.743 17.257a7.159 7.159 0 01-1.809-3.043.251.251 0 00-.39-.129L4.4 17.193a1 1 0 00-.107 1.507L9.3 23.707a1 1 0 001.507-.107l3.138-4.184a.219.219 0 00-.115-.341 7.151 7.151 0 01-3.087-1.818zM22 4.055c-3.139.845-8.638 2.5-10.193 4.051a5.721 5.721 0 008.09 8.091c1.553-1.556 3.203-7.054 4.048-10.192A1.589 1.589 0 0022 4.055zm-6.378 10.094a1.771 1.771 0 111.77-1.771 1.771 1.771 0 01-1.77 1.771zM6 8.25v-2A.25.25 0 016.25 6h2a1.25 1.25 0 000-2.5h-2A.25.25 0 016 3.25v-2a1.25 1.25 0 00-2.5 0v2a.25.25 0 01-.25.25h-2a1.25 1.25 0 000 2.5h2a.25.25 0 01.25.25v2a1.25 1.25 0 002.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});