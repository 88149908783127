define("ember-svg-jar/inlined/folder-search-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-search-1-alternate</title><path d=\"M15.79 16.03a.243.243 0 01-.031-.305 5.324 5.324 0 00.826-2.856 5.378 5.378 0 10-2.514 4.544.245.245 0 01.3.031l1.763 1.763a1 1 0 001.414-1.414zm-1.2-3.161a3.37 3.37 0 01-3.37 3.37 3.452 3.452 0 01-3.37-3.37 3.37 3.37 0 116.74 0z\"/><path d=\"M24 6.3a1.95 1.95 0 00-1.95-1.95H12a.5.5 0 01-.447-.277l-.747-1.494A1.949 1.949 0 009.061 1.5H1.95A1.95 1.95 0 000 3.45v17.1a1.95 1.95 0 001.95 1.95h20.1A1.95 1.95 0 0024 20.55zM22.048 20a.5.5 0 01-.5.5L2.5 20.549a.5.5 0 01-.5-.5L1.952 4a.5.5 0 01.5-.5l6.256-.025a.5.5 0 01.449.277l.761 1.523A1.941 1.941 0 0011.66 6.35l9.84-.05a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});