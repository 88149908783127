define("ember-svg-jar/inlined/delivery-parachute-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>delivery-parachute-1</title><path d=\"M22.955 8.826a11.28 11.28 0 00-21.91 0 2 2 0 00.522 1.474L6.6 14.5a.25.25 0 01.065.3 1.439 1.439 0 00-.147.635v4.035a.25.25 0 00.427.177l5.255-5.259a.25.25 0 00-.177-.427H9.038a.219.219 0 01-.18-.094.859.859 0 00-.151-.169l-3.861-3.217a.25.25 0 01.16-.442H19a.25.25 0 01.162.441c-1.1.925-3.573 3.015-4.012 3.416a.25.25 0 01-.169.065H14.8a.249.249 0 00-.177.073L6.59 22.077a.252.252 0 00-.074.177v.046a1.473 1.473 0 001.471 1.471h2.084a.251.251 0 00.177-.073l6.982-6.982a.255.255 0 00.073-.177v-1.1a1.415 1.415 0 00-.108-.538.249.249 0 01.071-.287l5.167-4.3a2 2 0 00.522-1.488zM12.683 23.34a.25.25 0 00.177.427h2.974a1.473 1.473 0 001.471-1.467v-2.978a.25.25 0 00-.427-.177z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});