define("ember-svg-jar/inlined/room-service-bring-plate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>room-service-bring-plate</title><path d=\"M23.25 10.5h-.858a.5.5 0 01-.5-.428 10.014 10.014 0 00-8.702-8.491.5.5 0 01-.44-.5V.75a.75.75 0 00-1.5 0v.334a.5.5 0 01-.44.5 10.014 10.014 0 00-8.71 8.488.5.5 0 01-.5.428H.75a.75.75 0 000 1.5h22.5a.75.75 0 000-1.5zM11.593 5.062a7.269 7.269 0 00-4.016 2.454.75.75 0 01-1.154-.959A8.782 8.782 0 0111.271 3.6a.75.75 0 01.322 1.465zM10.414 22.7L6.05 20.074a.5.5 0 00-.63.095l-2.684 3a.5.5 0 00.373.831h6.752a.5.5 0 00.391-.188l.295-.369a.5.5 0 00-.133-.74z\"/><path d=\"M16.905 12.146a10.1 10.1 0 00-3.262 2.191 17.14 17.14 0 01-2.13 1.639 1.625 1.625 0 01-1.174.258.738.738 0 01-.46-.384c-1.277-2.3-2.712-3.7-3.935-3.838a1.376 1.376 0 00-1.225.434.828.828 0 000 1.107C6 14.986 6.125 17.285 6.07 18.614a.5.5 0 00.241.449l4.939 2.975a.5.5 0 00.614-.077l7.8-7.92a.954.954 0 00.082-1.173c-.337-.588-1.282-1.13-2.841-.722z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});