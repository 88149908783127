define("ember-svg-jar/inlined/single-neutral-increase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-increase</title><path d=\"M5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M24 .5a.5.5 0 00-.5-.5H19a.5.5 0 00-.372.834l1.224 1.36a.249.249 0 010 .333L18.011 4.6a.25.25 0 01-.343.03l-1.387-1.11a1.25 1.25 0 00-1.722.153l-3.5 4a1.25 1.25 0 001.882 1.646L15.5 6.4a.25.25 0 01.344-.03l1.379 1.1a1.249 1.249 0 001.716-.146L21.5 4.44a.246.246 0 01.186-.084.251.251 0 01.187.082l1.256 1.4A.5.5 0 0024 5.5zM5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M24 .5a.5.5 0 00-.5-.5H19a.5.5 0 00-.372.834l1.224 1.36a.249.249 0 010 .333L18.011 4.6a.25.25 0 01-.343.03l-1.387-1.11a1.25 1.25 0 00-1.722.153l-3.5 4a1.25 1.25 0 001.882 1.646L15.5 6.4a.25.25 0 01.344-.03l1.379 1.1a1.249 1.249 0 001.716-.146L21.5 4.44a.246.246 0 01.186-.084.251.251 0 01.187.082l1.256 1.4A.5.5 0 0024 5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});