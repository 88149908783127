define("ember-svg-jar/inlined/vide-document-qt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vide-document-qt</title><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM11.125 13a2.109 2.109 0 01-.925 1.741.127.127 0 00-.025.2l.714.566a.627.627 0 01.1.879.65.65 0 01-.879.1L8.182 14.96A2.125 2.125 0 016.875 13v-2.5a2.125 2.125 0 014.25 0zM16.5 9.625h-1.125a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0V9.875a.25.25 0 00-.25-.25H12.5a.625.625 0 010-1.25h4a.625.625 0 010 1.25z\"/><path d=\"M9 9.625a.877.877 0 00-.875.875V13a.875.875 0 001.75 0v-2.5A.877.877 0 009 9.625z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});