define("ember-svg-jar/inlined/park-bench", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>park-bench</title><path d=\"M9.311 12.316a.24.24 0 01.188-.39 1 1 0 00.818-1.573L8.194 7.319a.25.25 0 01.2-.393h.1a1 1 0 00.818-1.573L6.778 1.724a1.039 1.039 0 00-1.638 0L2.6 5.352a1 1 0 00.819 1.574h.1a.25.25 0 01.205.393L1.6 10.352a1 1 0 00.819 1.574.24.24 0 01.187.39L.216 15.3A1 1 0 001 16.926h3.46a.5.5 0 01.5.5v4.25a1 1 0 002 0v-4.25a.5.5 0 01.5-.5h3.46a.331.331 0 00.331-.331v-1.356a1.666 1.666 0 00-.313-.891zM23 17.676h-1a.5.5 0 01-.5-.5v-.75a.5.5 0 01.5-.5h.75a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-10a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h.75a.5.5 0 01.5.5v.75a.5.5 0 01-.5.5h-1a1 1 0 000 2h.319a.205.205 0 01.209.247l-.265 1.588a1 1 0 00.823 1.151.919.919 0 00.165.014 1 1 0 00.985-.835l.279-1.672a.612.612 0 01.582-.493H19.9a.612.612 0 01.582.493l.278 1.672a1 1 0 00.986.835.935.935 0 00.165-.014 1 1 0 00.822-1.151l-.265-1.588a.206.206 0 01.209-.247H23a1 1 0 000-2zm-3.5-.5a.5.5 0 01-.5.5h-2.5a.5.5 0 01-.5-.5v-.75a.5.5 0 01.5-.5H19a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});