define("ember-svg-jar/inlined/office-outdoors-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-outdoors-1</title><path d=\"M23.04 21.84h-2.91a.238.238 0 01-.183-.085.24.24 0 01-.054-.194l.26-1.561a.479.479 0 00-.473-.559h-.72a.239.239 0 01-.24-.24v-1.669a.24.24 0 01.24-.24h1.2a.72.72 0 00.482-.185A5.242 5.242 0 0022.32 13.2a4.824 4.824 0 00-4.56-5.04 4.824 4.824 0 00-4.56 5.04 5.241 5.241 0 001.677 3.906.716.716 0 00.482.186h1.2a.239.239 0 01.24.24V19.2a.239.239 0 01-.24.24h-.72a.479.479 0 00-.473.559l.26 1.562a.24.24 0 01-.054.194.238.238 0 01-.183.085H10.32a.239.239 0 01-.24-.24v-7.773a.239.239 0 01.185-.233A2.878 2.878 0 0012.48 10.8V3.12A2.883 2.883 0 009.6.24H2.88A2.883 2.883 0 000 3.12v7.68a2.878 2.878 0 002.215 2.794.24.24 0 01.185.233V21.6a.239.239 0 01-.24.24H.96a.96.96 0 000 1.92h22.08a.96.96 0 000-1.92zM4.32 15.6a.48.48 0 01.48-.48h2.88a.48.48 0 01.48.48v1.92a.48.48 0 01-.48.48H4.8a.48.48 0 01-.48-.48zm-2.4-9.36A.239.239 0 012.16 6h8.16a.239.239 0 01.24.24v1.44a.239.239 0 01-.24.24H2.16a.239.239 0 01-.24-.24zm.96-4.08H9.6a.961.961 0 01.96.96v.72a.239.239 0 01-.24.24H2.16a.239.239 0 01-.24-.24v-.72a.961.961 0 01.96-.96zm-.96 8.64v-.72a.239.239 0 01.24-.24h8.16a.239.239 0 01.24.24v.72a.961.961 0 01-.96.96H2.88a.961.961 0 01-.96-.96z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});