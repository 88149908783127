define("ember-svg-jar/inlined/server-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-refresh</title><path d=\"M2.511 6.125h17a2.5 2.5 0 002.5-2.5v-1a2.5 2.5 0 00-2.5-2.5h-17a2.5 2.5 0 00-2.5 2.5v1a2.5 2.5 0 002.5 2.5zm1.135-3a1.115 1.115 0 111.115 1.114 1.115 1.115 0 01-1.115-1.114zm3.865 0a1.115 1.115 0 111.114 1.114 1.114 1.114 0 01-1.114-1.114zM17.011 9.625a7.7 7.7 0 014.238 1.267.493.493 0 00.763-.411v-.85a2.509 2.509 0 00-2.5-2.506h-17a2.5 2.5 0 00-2.5 2.5v1a2.5 2.5 0 002.5 2.5h7.77a.492.492 0 00.4-.212 7.741 7.741 0 016.329-3.288zm-7.271.5A1.115 1.115 0 118.625 9.01a1.115 1.115 0 011.115 1.114zm-3.865 0A1.115 1.115 0 114.761 9.01a1.115 1.115 0 011.114 1.114zM2.511 20.125h6.57a.5.5 0 00.474-.658 7.614 7.614 0 01.162-4.692.5.5 0 00-.476-.65h-6.73a2.5 2.5 0 00-2.5 2.5v1a2.5 2.5 0 002.5 2.5zm1.135-3a1.115 1.115 0 111.115 1.115 1.115 1.115 0 01-1.115-1.116z\"/><path d=\"M21.838 12.84a.25.25 0 01-.348.006A6.373 6.373 0 0010.761 17.5a6.374 6.374 0 0011.357 3.976 1 1 0 00-1.562-1.248 4.374 4.374 0 11-.5-5.982.251.251 0 01.009.363L18.679 16a.5.5 0 00.353.853h4.457a.5.5 0 00.5-.5V11.9a.5.5 0 00-.853-.353l-.508.508z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});