define("ember-svg-jar/inlined/crypto-currency-bitcoin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin</title><path d=\"M11.5 8.25a.25.25 0 00-.25.25v1a.25.25 0 00.25.25H13a.75.75 0 000-1.5zM13 11.25h-1.5a.25.25 0 00-.25.25v1a.25.25 0 00.25.25H13a.75.75 0 000-1.5z\"/><path d=\"M20.5 0A2.5 2.5 0 0018 2.5a2.459 2.459 0 00.125.766.25.25 0 01-.06.255L16.982 4.6a.25.25 0 01-.332.019 7.473 7.473 0 00-9.3 0 .25.25 0 01-.332-.019L5.935 3.521a.25.25 0 01-.06-.255A2.459 2.459 0 006 2.5 2.5 2.5 0 103.5 5a2.454 2.454 0 00.766-.125.25.25 0 01.255.06L5.686 6.1a.25.25 0 01.032.313 7.484 7.484 0 005.072 11.481.25.25 0 01.21.247v.913a.249.249 0 01-.136.222 2.5 2.5 0 102.272 0 .25.25 0 01-.136-.222v-.913a.25.25 0 01.21-.247 7.484 7.484 0 005.072-11.481.25.25 0 01.032-.313l1.165-1.165a.25.25 0 01.255-.06A2.454 2.454 0 0020.5 5a2.5 2.5 0 000-5zM9.75 7.5a.75.75 0 01.75-.75.25.25 0 00.25-.25.75.75 0 011.5 0 .25.25 0 00.25.25h.5A2.253 2.253 0 0115.25 9a2.219 2.219 0 01-.46 1.349.249.249 0 000 .3A2.219 2.219 0 0115.25 12 2.253 2.253 0 0113 14.25h-.5a.25.25 0 00-.25.25.75.75 0 01-1.5 0 .25.25 0 00-.25-.25.75.75 0 01-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});