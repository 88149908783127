define("ember-svg-jar/inlined/paragraph-indent-first-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paragraph-indent-first-line</title><path d=\"M7.366 2.9L3.7.15a.75.75 0 00-1.2.6v1.5a.25.25 0 01-.25.25H1a1 1 0 000 2h1.25a.25.25 0 01.25.25v1.5a.749.749 0 001.2.6L7.368 4.1a.751.751 0 00-.002-1.2zM23 22H7a1 1 0 000 2h16a1 1 0 000-2zM23 17H7a1 1 0 000 2h16a1 1 0 000-2zM24 13a1 1 0 00-1-1H7a1 1 0 000 2h16a1 1 0 001-1zM23 7H7a1 1 0 000 2h16a1 1 0 000-2zM12 4h11a1 1 0 000-2H12a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});