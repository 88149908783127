define("ember-svg-jar/inlined/content-pen-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-pen-5</title><path d=\"M23.518.615a1 1 0 00-1.414 0l-.889.889-.635-.317a2.444 2.444 0 00-2.472.175.848.848 0 00-.058-.062l-.682-.684a2.016 2.016 0 00-2.783 0l-4.135 4.15h-.967a1 1 0 000 2h.967a1.955 1.955 0 001.39-.576l4.113-4.16s.737.734.766.757a.5.5 0 00.018.688L20.66 7.4a.5.5 0 00.691.015l1.066-.976a2.445 2.445 0 00.531-2.881l-.318-.637.888-.888a1 1 0 000-1.418zM3 18.4a.5.5 0 00-.835.221l-.858 3.146-.9.9a.75.75 0 001.06 1.061l.9-.9 3.146-.857a.5.5 0 00.352-.355.5.5 0 00-.13-.481z\"/><path d=\"M16.078 4.134a.49.49 0 00-.366-.146.5.5 0 00-.358.163L3.645 17.049a.5.5 0 00.017.689L6.4 20.473a.5.5 0 00.354.146.494.494 0 00.335-.13L19.982 8.78A.5.5 0 0020 8.057z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});