define("ember-svg-jar/inlined/crypto-currency-bitcoin-bill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-bill</title><path d=\"M11.5 9.75a.25.25 0 00-.25.25v1a.25.25 0 00.25.25H13a.75.75 0 000-1.5zM13 12.75h-1.5a.25.25 0 00-.25.25v1a.25.25 0 00.25.25H13a.75.75 0 000-1.5z\"/><path d=\"M21.143 7.487A.25.25 0 0121 7.261V6a1 1 0 00-1-1H4a1 1 0 00-1 1v1.261a.25.25 0 01-.143.226 4.994 4.994 0 000 9.027.25.25 0 01.143.226V18a1 1 0 001 1h16a1 1 0 001-1v-1.26a.25.25 0 01.143-.226 4.994 4.994 0 000-9.027zM9.75 9a.75.75 0 01.75-.75.25.25 0 00.25-.25.75.75 0 011.5 0 .25.25 0 00.25.25h.5a2.253 2.253 0 012.25 2.25 2.219 2.219 0 01-.46 1.349.249.249 0 000 .3 2.219 2.219 0 01.46 1.349A2.253 2.253 0 0113 15.75h-.5a.25.25 0 00-.25.25.75.75 0 01-1.5 0 .25.25 0 00-.25-.25.75.75 0 01-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});