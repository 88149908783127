define("ember-svg-jar/inlined/flip-vertical-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flip-vertical-down</title><circle cx=\"2\" cy=\"14.749\" r=\"1.25\"/><circle cx=\"2\" cy=\"18.749\" r=\"1.25\"/><circle cx=\"2\" cy=\"22.749\" r=\"1.25\"/><circle cx=\"7\" cy=\"22.749\" r=\"1.25\"/><circle cx=\"12\" cy=\"22.749\" r=\"1.25\"/><circle cx=\"17\" cy=\"22.749\" r=\"1.25\"/><circle cx=\"22\" cy=\"14.749\" r=\"1.25\"/><circle cx=\"22\" cy=\"18.749\" r=\"1.25\"/><circle cx=\"22\" cy=\"22.749\" r=\"1.25\"/><path d=\"M0 11.5a.75.75 0 00.75.75h7.739a.251.251 0 00.25-.265 7.83 7.83 0 01-.018-.485 12 12 0 012.951-7.881 2 2 0 013.016 2.627 8 8 0 00-1.967 5.254c0 .173.006.345.018.517a.249.249 0 00.249.233H23.25a.75.75 0 000-1.5.25.25 0 01-.25-.25V1.749A1.752 1.752 0 0021.25 0H2.75A1.752 1.752 0 001 1.749V10.5a.25.25 0 01-.25.25.75.75 0 00-.75.75z\"/><path d=\"M11.471 11.5a9.249 9.249 0 012.275-6.075.75.75 0 00-1.131-.985A10.736 10.736 0 0010.63 15.2a.25.25 0 01-.1.3l-.9.549-.767.468a.751.751 0 00.215 1.37l1.782.429 2.2.532a.8.8 0 00.152.017h.024a.744.744 0 00.664-.423v-.008a.751.751 0 00.061-.139l.462-1.873.524-2.121a.75.75 0 00-1.118-.821l-.726.443-.828.505a.25.25 0 01-.369-.138 9.228 9.228 0 01-.435-2.79z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});