define("ember-svg-jar/inlined/electricity-pillar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>electricity-pillar</title><path d=\"M24 22.25a1 1 0 00-1-1h-4.5a.5.5 0 01-.5-.5V16.3a.5.5 0 01.119-.324l3.443-4.051a.5.5 0 01.381-.176H23a1 1 0 00.334-1.943.5.5 0 01-.334-.471V8.75a1 1 0 00-2 0v.5a.5.5 0 01-1 0v-.5a1 1 0 00-2 0v.5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-.5a1 1 0 00-2 0v.5a.5.5 0 01-1 0v-.5a1 1 0 00-2 0v.585a.5.5 0 01-.334.472A1 1 0 0011 11.75h1.057a.5.5 0 01.381.176l3.443 4.051A.5.5 0 0116 16.3v4.45a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5V10.061a.5.5 0 01.123-.328l4.184-4.811a.5.5 0 01.377-.172H14a1 1 0 001-1 .97.97 0 00-.5-.846.5.5 0 01-.252-.435V1.75a1 1 0 00-2 0v.5a.5.5 0 01-1 0v-.5a1 1 0 00-2 0v.5a.5.5 0 01-.5.5H6.25a.5.5 0 01-.5-.5v-.5a1 1 0 00-2 0v.5a.5.5 0 01-1 0v-.5a1 1 0 00-2 0v.719A.5.5 0 01.5 2.9a.97.97 0 00-.5.85 1 1 0 001 1h.816a.5.5 0 01.377.172l4.184 4.811a.5.5 0 01.123.328V20.75a.5.5 0 01-.5.5H1a1 1 0 000 2h22a1 1 0 001-1zm-5.993-10.5a.5.5 0 01.381.824l-1.007 1.184a.5.5 0 01-.762 0l-1.007-1.184a.5.5 0 01.381-.824zm-8.8-7a.5.5 0 01.377.828L7.877 7.542a.5.5 0 01-.754 0L5.415 5.578a.5.5 0 01.377-.828z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});