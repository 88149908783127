define("ember-svg-jar/inlined/vegetables-plate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vegetables-plate</title><path d=\"M1.943 12.109a.251.251 0 01-.025.3.216.216 0 00.162.359H6.3a.5.5 0 00.492-.59 5.306 5.306 0 00-.8-1.994.75.75 0 011.248-.832 6.809 6.809 0 011.1 2.976.5.5 0 00.5.44h.6a1 1 0 00.813-.418l1.597-2.25a.252.252 0 00.034-.224.247.247 0 00-.159-.159 3.562 3.562 0 00-.238-.071.251.251 0 01-.187-.246 2.81 2.81 0 00-2.484-2.828.251.251 0 01-.222-.172 3.273 3.273 0 00-3.921-2.386 3.274 3.274 0 00-2.045 4.107.251.251 0 01-.094.266 2.747 2.747 0 00-.918 2.992 2.961 2.961 0 00.327.73zM19.614 14.265H1.25a1 1 0 00-.95 1.308 7.476 7.476 0 007.134 5.192h6a7.476 7.476 0 007.134-5.192 1 1 0 00-.952-1.308zM22.947 6.732l-1.091.078a.248.248 0 01-.245-.145.251.251 0 01.051-.281l.857-.857a.75.75 0 00-1.06-1.061l-.858.858a.25.25 0 01-.426-.2l.078-1.09a.75.75 0 00-1.5-.108l-.151 2.108a.25.25 0 01-.374.2 1.5 1.5 0 00-2.052.382l-.255.356a.251.251 0 00.027.323l1.333 1.334a.5.5 0 01-.707.707L15.42 8.181a.249.249 0 00-.38.031l-.783 1.1a.249.249 0 00.026.322l1 1a.5.5 0 01-.707.707l-.826-.827a.251.251 0 00-.38.031l-1.307 1.826a.25.25 0 00.2.4H17.5a1 1 0 00.608-.206l2.316-1.773a1.511 1.511 0 00.328-2.032.25.25 0 01.2-.375l2.1-.151a.75.75 0 00-.107-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});