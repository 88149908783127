define("ember-svg-jar/inlined/medical-specialty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-specialty</title><path d=\"M6.667 5.55v.7a3.921 3.921 0 003.916 3.917.416.416 0 01.417.416v2.284a.5.5 0 01-.5.5H9.4a1 1 0 100 2h5.2a1 1 0 100-2h-1.1a.5.5 0 01-.5-.5v-2.285a.416.416 0 01.416-.416 3.921 3.921 0 003.917-3.917v-.7a4.947 4.947 0 011.441-3.477A1 1 0 0017.36.659a6.963 6.963 0 00-2.027 4.891v.7a1.92 1.92 0 01-1.917 1.917A.416.416 0 0113 7.75V2.866a.5.5 0 01.5-.5h.233a1 1 0 000-2h-3.466a1 1 0 000 2h.233a.5.5 0 01.5.5v4.883a.417.417 0 01-.417.417 1.919 1.919 0 01-1.916-1.917v-.7A6.963 6.963 0 006.64.659a1 1 0 00-1.414 1.414A4.947 4.947 0 016.667 5.55zM20.5 16.634h-2.635a.25.25 0 00-.195.406 4.971 4.971 0 010 6.189.25.25 0 00.2.405h2.63a3.5 3.5 0 000-7z\"/><path d=\"M15.8 16.7a.494.494 0 00-.256-.071H1a1 1 0 000 2h5a1.5 1.5 0 010 3H1a1 1 0 000 2h14.543a.5.5 0 00.255-.07 3.987 3.987 0 000-6.859z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});