define("ember-svg-jar/inlined/maze-strategy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>maze-strategy</title><path d=\"M8.5 2H2a2 2 0 00-2 2v18a2 2 0 002 2h7a1 1 0 000-2H5.75a.249.249 0 01-.25-.25V19.5a1 1 0 00-2 0v2.25a.249.249 0 01-.25.25h-1a.249.249 0 01-.25-.25v-9.5a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25V16a1 1 0 002 0v-5a1 1 0 00-1-1H2.25A.249.249 0 012 9.75v-5.5A.25.25 0 012.25 4h3a.25.25 0 01.25.25v1.5a.249.249 0 01-.25.25H4.5a1 1 0 000 2h2a1 1 0 001-1V4.25A.25.25 0 017.75 4h.75a1 1 0 000-2zM24 4a2 2 0 00-2-2h-3a1 1 0 000 2h2.75a.25.25 0 01.25.25v1.5a.249.249 0 01-.25.25H18a1 1 0 000 2h3.75a.25.25 0 01.25.25v2a.249.249 0 01-.25.25H13a1 1 0 000 2h1.75a.25.25 0 01.25.25v2a.249.249 0 01-.25.25H13.5a1 1 0 000 2H16a1 1 0 001-1v-3.25a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25v9a.249.249 0 01-.25.25h-1a.249.249 0 01-.25-.25V15a1 1 0 00-2 0v6.75a.249.249 0 01-.25.25H16a1 1 0 000 2h6a2 2 0 002-2z\"/><path d=\"M12.25 7H9.5a1 1 0 00-1 1v11a1 1 0 001 1h1.75a.25.25 0 01.25.25V23a1 1 0 002 0v-4a1 1 0 00-1-1h-1.75a.249.249 0 01-.25-.25v-8.5a.25.25 0 01.25-.25h2.75a1 1 0 001-1V4.235a.251.251 0 01.25-.25h.75a.75.75 0 00.586-1.218l-2-2.5a.777.777 0 00-1.172 0l-2 2.5a.75.75 0 00.586 1.218h.75a.251.251 0 01.25.25V6.75a.249.249 0 01-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});