define("ember-svg-jar/inlined/diagram-zig-zag-fall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-zig-zag-fall</title><path d=\"M17.86 8.729A1.5 1.5 0 0017 6h-3.75a.25.25 0 01-.25-.25V1.5a1.5 1.5 0 00-3 0v6A1.5 1.5 0 0011.5 9h.424a.1.1 0 01.057.182L6.14 13.271A1.5 1.5 0 007 16h2.75a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H8.5a1.5 1.5 0 00-1.061 2.561l3 3a1.5 1.5 0 002.124 0l3-3A1.5 1.5 0 0014.5 18h-1.25a.25.25 0 01-.25-.25V14.5a1.5 1.5 0 00-.827-1.341.25.25 0 01-.031-.428z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});