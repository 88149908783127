define("ember-svg-jar/inlined/video-file-play-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-play-circle</title><path d=\"M21.414 4.914L17.086.586A2 2 0 0015.671 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.328a2 2 0 00-.586-1.414zM12 20.5a6 6 0 116-6 6 6 0 01-6 6z\"/><path d=\"M10.558 11.842a.489.489 0 00-.74.419v4.478a.489.489 0 00.74.419l4.478-2.239a.49.49 0 000-.838z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});