define("ember-svg-jar/inlined/fitness-jumping-rope", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fitness-jumping-rope</title><path d=\"M5.679 16.2a.5.5 0 00-.5-.448h-2.36a.5.5 0 00-.5.448l-.559 5.316a2.25 2.25 0 104.476 0zM22.238 21.514l-.559-5.314a.5.5 0 00-.5-.448h-2.36a.5.5 0 00-.5.448l-.559 5.316a2.25 2.25 0 104.476 0zM15.837 2.349A5.009 5.009 0 0119 7v4.5a.5.5 0 01-.5.5.5.5 0 00-.49.6c.142.7.21 1.051.25 1.25a.5.5 0 00.49.4h2.4a.5.5 0 00.481-.364c.051-.179.148-.507.357-1.288a.5.5 0 00-.49-.6.5.5 0 01-.5-.5V7A7 7 0 0012.251.221a1.008 1.008 0 01-.5 0A7 7 0 003 7v4.5a.5.5 0 01-.5.5.5.5 0 00-.49.6c.209.781.306 1.109.357 1.288a.5.5 0 00.481.364h2.4a.5.5 0 00.49-.4c.04-.2.108-.547.25-1.25A.5.5 0 005.5 12a.5.5 0 01-.5-.5V7a5.009 5.009 0 013.163-4.651.25.25 0 01.29.386A6.961 6.961 0 007 7a5 5 0 0010 0 6.966 6.966 0 00-1.453-4.266.25.25 0 01.29-.385zM12 10a3 3 0 01-3-3 5 5 0 012.5-4.328 1 1 0 011.005 0A5 5 0 0115 7a3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});