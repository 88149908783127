define("ember-svg-jar/inlined/road-sign-66-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-66-alternate</title><path d=\"M21.527 1.96A23.126 23.126 0 0012 0a23.126 23.126 0 00-9.527 1.96A2.547 2.547 0 001 4.274v6.637a13.366 13.366 0 008.467 12.4l1.067.413a4.056 4.056 0 002.931 0l1.068-.413A13.366 13.366 0 0023 10.911V4.274a2.547 2.547 0 00-1.473-2.314zM21 10.911a11.356 11.356 0 01-7.188 10.537l-1.069.413a2.061 2.061 0 01-1.487 0l-1.068-.413A11.356 11.356 0 013 10.911V4.274a.536.536 0 01.307-.5A21.089 21.089 0 0112 2a21.089 21.089 0 018.693 1.778.536.536 0 01.307.5z\"/><path d=\"M8.5 8.625a3.191 3.191 0 00-1.039.175.25.25 0 01-.3-.362A2.119 2.119 0 019 7.375h.5a.875.875 0 000-1.75H9A3.879 3.879 0 005.125 9.5V12A3.375 3.375 0 108.5 8.625zm0 5A1.625 1.625 0 1110.125 12 1.627 1.627 0 018.5 13.625z\"/><path d=\"M15.5 8.625a3.191 3.191 0 00-1.039.175.25.25 0 01-.3-.362A2.119 2.119 0 0116 7.375h.5a.875.875 0 000-1.75H16A3.879 3.879 0 0012.125 9.5V12A3.375 3.375 0 1015.5 8.625zm0 5A1.625 1.625 0 1117.125 12a1.627 1.627 0 01-1.625 1.625z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});