define("ember-svg-jar/inlined/professions-woman-magician.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-woman-magician</title><path d=\"M21.026 18.77l-4.379-1.106a.5.5 0 00-.436.095A7.357 7.357 0 0112 19.5a7.357 7.357 0 01-4.215-1.744.5.5 0 00-.437-.095L2.92 18.787A4.519 4.519 0 000 23v.5a.5.5 0 00.5.5h23a.5.5 0 00.5-.5V23a4.531 4.531 0 00-2.974-4.23zM9 20.5a.5.5 0 01.686-.464l2.314.925 2.314-.925A.5.5 0 0115 20.5v2a.5.5 0 01-.686.464L12 22.039l-2.314.925A.5.5 0 019 22.5zM12 8.34a49.349 49.349 0 0010.163-1.108.75.75 0 00-.326-1.464c-1.1.245-2.217.432-3.337.593V1.5A1.5 1.5 0 0017 0H7a1.5 1.5 0 00-1.5 1.5v4.861a44.616 44.616 0 01-3.337-.593.75.75 0 00-.326 1.464A49.406 49.406 0 0012 8.34zM10.092 13.631a.751.751 0 00.3 1.016 3.436 3.436 0 003.06 0 .75.75 0 10-.715-1.318 1.966 1.966 0 01-1.634 0 .751.751 0 00-1.011.302zM9.306 11.082a.632.632 0 01.627 0 .75.75 0 001-1.115 2.1 2.1 0 00-2.635 0 .75.75 0 101 1.115zM13.871 11.082a.632.632 0 01.627 0 .75.75 0 101-1.115 2.1 2.1 0 00-2.635 0 .75.75 0 001 1.115z\"/><path d=\"M7.993 16.256A6.684 6.684 0 0012 18.25a6.705 6.705 0 004.043-2.027 2.192 2.192 0 001.814.777 5.29 5.29 0 003.517-1.55.5.5 0 00-.229-.81 2.856 2.856 0 01-1.6-1.373 6.285 6.285 0 01-.588-1.4 3.14 3.14 0 00.288-1.226 1.884 1.884 0 00-.932-1.809.749.749 0 00-.671 1.34.634.634 0 01.1.44 1.281 1.281 0 01-.557 1.1 1.752 1.752 0 00-1 1.306C15.816 15.26 13.029 16.75 12 16.75s-3.816-1.49-4.191-3.738a1.752 1.752 0 00-1-1.306 1.281 1.281 0 01-.557-1.1.634.634 0 01.1-.44.749.749 0 00-.671-1.34 1.884 1.884 0 00-.932 1.809 3.138 3.138 0 00.312 1.279 6.035 6.035 0 01-.594 1.37 2.873 2.873 0 01-1.612 1.351.5.5 0 00-.228.814A5.385 5.385 0 006.189 17a2.229 2.229 0 001.804-.744z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});