define("ember-svg-jar/inlined/railroad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>railroad</title><path d=\"M4 0a1 1 0 00-1 1v1.75a.25.25 0 01-.25.25H1a1 1 0 00-1 1v3a1 1 0 001 1h1.75a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H1a1 1 0 00-1 1v3a1 1 0 001 1h1.75a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H1a1 1 0 00-1 1v3a1 1 0 001 1h1.75a.25.25 0 01.25.25V23a1 1 0 002 0V1a1 1 0 00-1-1zM16 0a1 1 0 00-1 1v1.75a.25.25 0 01-.25.25h-5.5A.25.25 0 019 2.75V1a1 1 0 00-2 0v22a1 1 0 002 0v-.75a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25V23a1 1 0 002 0V1a1 1 0 00-1-1zm-1 16.75a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25zm0-7a.25.25 0 01-.25.25h-5.5A.25.25 0 019 9.75v-1.5A.25.25 0 019.25 8h5.5a.25.25 0 01.25.25zM21.25 8H23a1 1 0 001-1V4a1 1 0 00-1-1h-1.75a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v22a1 1 0 002 0v-.75a.25.25 0 01.25-.25H23a1 1 0 001-1v-3a1 1 0 00-1-1h-1.75a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25H23a1 1 0 001-1v-3a1 1 0 00-1-1h-1.75a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});