define("ember-svg-jar/inlined/video-edit-magic-wand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-edit-magic-wand</title><path d=\"M9.67 22.368A.25.25 0 009.45 22H3.5a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h8a.5.5 0 01.5.5.125.125 0 00.235.059l2.736-5.129a.251.251 0 00.029-.118V1a1 1 0 00-1-1H1a1 1 0 00-1 1v22a1 1 0 001 1h7.65a.25.25 0 00.22-.132zM12 14.5a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5V10a.5.5 0 01.5-.5h8a.5.5 0 01.5.5zM12 7a.5.5 0 01-.5.5h-8A.5.5 0 013 7V2.5a.5.5 0 01.5-.5h8a.5.5 0 01.5.5zM15.161 15.792a.252.252 0 00-.339.1l-4.122 7.74a.25.25 0 00.221.368h3.966a.252.252 0 00.221-.132l3.248-6.09a.251.251 0 00-.1-.338zM20.68 13.412a1 1 0 00-.412-1.353l-1.768-.942a1 1 0 00-1.353.412l-1.388 2.6a.25.25 0 00.1.338l3.089 1.647a.251.251 0 00.338-.1zM16.43 3l.264.038a1.508 1.508 0 011.273 1.273l.038.265a.5.5 0 00.99 0l.038-.265a1.508 1.508 0 011.273-1.273L20.57 3a.5.5 0 000-.99l-.264-.038A1.508 1.508 0 0119.033.694L19 .429a.5.5 0 00-.99 0l-.038.265a1.508 1.508 0 01-1.273 1.273l-.264.038a.5.5 0 000 .99zM23.57 7.005l-.264-.038a1.508 1.508 0 01-1.273-1.273L22 5.429a.5.5 0 00-.99 0l-.038.265a1.508 1.508 0 01-1.273 1.273l-.264.038a.5.5 0 000 .99l.264.038a1.508 1.508 0 011.273 1.273l.038.265a.5.5 0 00.99 0l.038-.265a1.508 1.508 0 011.273-1.273L23.57 8a.5.5 0 000-.99z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});