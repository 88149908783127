define("ember-svg-jar/inlined/single-woman-actions-home.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-home</title><path d=\"M9.206 16.474l2.847-2.408a.5.5 0 000-.763A7.471 7.471 0 00.021 16.857.5.5 0 00.5 17.5h7.711a.5.5 0 00.459-.3 1.985 1.985 0 01.536-.726zM21.5 24a.5.5 0 00.5-.5v-4.75a.25.25 0 01.25-.25h1.25a.5.5 0 00.324-.882l-6.5-5.5a.5.5 0 00-.647 0l-6.5 5.5a.5.5 0 00.323.882h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5z\"/><path d=\"M1.564 10.442a1 1 0 001.383-.3 5.093 5.093 0 00.454-.9.25.25 0 01.412-.082 4.727 4.727 0 006.786 0 .251.251 0 01.228-.071.255.255 0 01.184.153 5.093 5.093 0 00.454.9 1 1 0 001.681-1.082 4.561 4.561 0 01-.646-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248a4.573 4.573 0 01-.639 2.512 1 1 0 00.297 1.382zM7.206 8.6a2.7 2.7 0 01-2.741-2.352.5.5 0 01.324-.577 5.7 5.7 0 002.245-1.439.25.25 0 01.361 0 5.719 5.719 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.206 8.6z\"/><path d=\"M1.564 10.442a1 1 0 001.383-.3 5.093 5.093 0 00.454-.9.25.25 0 01.412-.082 4.727 4.727 0 006.786 0 .251.251 0 01.228-.071.255.255 0 01.184.153 5.093 5.093 0 00.454.9 1 1 0 001.681-1.082 4.561 4.561 0 01-.646-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248a4.573 4.573 0 01-.639 2.512 1 1 0 00.297 1.382zM7.206 8.6a2.7 2.7 0 01-2.741-2.352.5.5 0 01.324-.577 5.7 5.7 0 002.245-1.439.25.25 0 01.361 0 5.719 5.719 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.206 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});