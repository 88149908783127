define("ember-svg-jar/inlined/love-heart-hands-hold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-heart-hands-hold</title><path d=\"M23.014 4.017L20.35.688A.5.5 0 0019.96.5H16a.5.5 0 00-.5.5v3.01a4.492 4.492 0 00.757 2.5c1.887 1.968 2.477 2.919 3.619 2.157a1.4 1.4 0 00.458-1.823L19 4.624a.5.5 0 01.857-.516l1.352 2.25a2.472 2.472 0 01-.125 2.485.5.5 0 00-.084.276V12a1.5 1.5 0 003 0V6.829a4.5 4.5 0 00-.986-2.812zM2.791 6.358l1.352-2.25A.5.5 0 015 4.624L3.666 6.841a1.4 1.4 0 00.458 1.823c1.142.762 1.732-.189 3.619-2.157A4.492 4.492 0 008.5 4.01V1A.5.5 0 008 .5H4.04a.5.5 0 00-.39.188L.986 4.017A4.5 4.5 0 000 6.829V12a1.5 1.5 0 003 0V9.119a.5.5 0 00-.084-.276 2.472 2.472 0 01-.125-2.485z\"/><path d=\"M20 12v-1.593a.5.5 0 00-.575-.494c-1.118.167-2.024-.727-3.015-1.784a.5.5 0 00-.428-.154 4.646 4.646 0 00-2.693 1.312l-.952.868a.5.5 0 01-.674 0l-.952-.868a4.646 4.646 0 00-2.693-1.312.5.5 0 00-.428.154C6.6 9.186 5.693 10.08 4.575 9.913a.5.5 0 00-.575.494V12a2.476 2.476 0 01-.773 1.8.5.5 0 00-.113.559 4.572 4.572 0 00.991 1.452l7.178 7.389a1 1 0 001.434 0l7.183-7.394a4.572 4.572 0 00.991-1.452.5.5 0 00-.113-.559A2.476 2.476 0 0120 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});