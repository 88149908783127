define("ember-svg-jar/inlined/casino-player-diamond", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>casino-player-diamond</title><path d=\"M14.112 4.368a1.222 1.222 0 01.973.486l2.082 2.253a.249.249 0 01-.183.419h-2.291a.249.249 0 01-.217-.126l-1.519-2.658a.25.25 0 01.217-.374zm-3.249.053a.105.105 0 01.091-.053.1.1 0 01.091.053l1.561 2.731a.25.25 0 01-.217.374H9.52a.249.249 0 01-.22-.374zM6.872 4.8a1.184 1.184 0 01.928-.432h.939a.25.25 0 01.217.374L7.433 7.4a.25.25 0 01-.217.126H4.928a.25.25 0 01-.184-.419zM4.025 9.59a1.188 1.188 0 01-.144-.174.25.25 0 01.207-.39h2.928a.25.25 0 01.242.19l1.008 4.031a.25.25 0 01-.421.236zm7.4 6.325a.254.254 0 01-.182.183 1.214 1.214 0 01-.294.036 1.165 1.165 0 01-.293-.037.251.251 0 01-.182-.182L8.835 9.337a.25.25 0 01.242-.311h3.754a.249.249 0 01.2.1.245.245 0 01.046.214zm6.6-6.5a1.218 1.218 0 01-.15.182l-3.812 3.884a.25.25 0 01-.421-.235l1.008-4.03a.25.25 0 01.243-.19h2.926a.25.25 0 01.208.389zm3.451.443a7.246 7.246 0 01-.72-1.452C20.758 3.145 15.9 0 11.2 0A9.972 9.972 0 001.1 10.063c0 3.633 1.241 6.217 3.79 7.885a.25.25 0 01.11.209V23.5a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-2.731a.25.25 0 01.244-.25c1.628-.035 2.692-.245 3.425-.978.781-.781.96-3.026 1-4.282a.25.25 0 01.252-.243c.259 0 .547 0 .8-.01 1.078-.048 1.139-.9 1.174-1.4.081-1.117-.715-2.514-1.417-3.748z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});