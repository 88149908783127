define("ember-svg-jar/inlined/data-file-bars-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-bars-star</title><path d=\"M12.608 17.139a1.262 1.262 0 00.3-1.378A1.232 1.232 0 0011.739 15h-2.5a.252.252 0 01-.23-.151l-1.344-3.118-.013-.031a1.293 1.293 0 00-2.317.027l-1.346 3.121a.252.252 0 01-.23.151h-2.5a1.228 1.228 0 00-1.166.76 1.269 1.269 0 00.319 1.4l2.194 1.954a.251.251 0 01.063.287l-1.224 2.811a1.253 1.253 0 00.315 1.451 1.36 1.36 0 001.506.166l3.111-1.753a.251.251 0 01.246 0l3.112 1.753a1.277 1.277 0 001.506-.166 1.256 1.256 0 00.316-1.45L10.333 19.4a.251.251 0 01.063-.287z\"/><rect x=\"10.999\" y=\"10\" width=\"2\" height=\"3.5\" rx=\".5\" ry=\".5\"/><rect x=\"14.499\" y=\"5.5\" width=\"2\" height=\"8\" rx=\".5\" ry=\".5\"/><rect x=\"17.999\" y=\"7.5\" width=\"2\" height=\"6\" rx=\".5\" ry=\".5\"/><path d=\"M12.94 20.379a.2.2 0 00.188.121H22a2 2 0 002-2V4.414A2 2 0 0023.413 3L21 .586A2 2 0 0019.584 0H8a2 2 0 00-2 2v6.792a.252.252 0 00.232.249 8.422 8.422 0 011.451.211A.249.249 0 008 9.013V2.5a.5.5 0 01.5-.5h10.878a.5.5 0 01.353.146l2.121 2.122a.5.5 0 01.148.353V18a.5.5 0 01-.5.5h-7.337a.234.234 0 00-.162.06l-1.222 1.1a.174.174 0 00-.059.208z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});