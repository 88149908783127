define("ember-svg-jar/inlined/app-window-star-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-star-1</title><path d=\"M18.666 11.733l-.013-.028a1.293 1.293 0 00-2.317.028L15.055 14.7a.5.5 0 01-.459.3h-2.337a1.229 1.229 0 00-1.166.76 1.271 1.271 0 00.319 1.4l2.059 1.835a.5.5 0 01.126.572l-1.151 2.649a1.255 1.255 0 00.315 1.451 1.287 1.287 0 00.864.338 1.3 1.3 0 00.642-.172l2.989-1.683a.5.5 0 01.49 0l2.991 1.683a1.275 1.275 0 001.505-.166 1.256 1.256 0 00.316-1.45l-1.152-2.65a.5.5 0 01.126-.572l2.078-1.855a1.262 1.262 0 00.3-1.378A1.232 1.232 0 0022.74 15H20.4a.5.5 0 01-.459-.3z\"/><path d=\"M8.5 18.5H3a1 1 0 01-1-1V5.25A.25.25 0 012.25 5h19.5a.25.25 0 01.25.25v6.25a1 1 0 001 1 1 1 0 001-1V3a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h5.5a1 1 0 001-1 1 1 0 00-1-1zM9.654 2a.966.966 0 011.692 0 .969.969 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0A.969.969 0 018 2.5a.969.969 0 01-.154.5.966.966 0 01-1.692 0A.969.969 0 016 2.5a.969.969 0 01.154-.5zm-3.562.092A1 1 0 013.5 1.5a.985.985 0 01.846.5.969.969 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.979.979 0 01.092-.408z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});