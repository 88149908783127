define("ember-svg-jar/inlined/meeting-smartphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-smartphone</title><path d=\"M11.349 11.508a.253.253 0 00-.237.021.25.25 0 00-.112.209v8.1a.414.414 0 01-.417.411H2.417A.414.414 0 012 19.838V7.161a.415.415 0 01.417-.412H9.25A.249.249 0 009.5 6.5v-2a.25.25 0 00-.25-.25H2.437A2.44 2.44 0 000 6.687v14.625a2.439 2.439 0 002.437 2.437h8.125A2.44 2.44 0 0013 21.312V12a.25.25 0 00-.25-.25h-.25a2.889 2.889 0 01-1.151-.242z\"/><path d=\"M23.371 1.767l-2.807.748a.251.251 0 01-.314-.242v-.524a1.5 1.5 0 00-1.5-1.5H12.5a1.5 1.5 0 00-1.5 1.5v7a1.5 1.5 0 001.5 1.5h6.25a1.5 1.5 0 001.5-1.5v-.523a.25.25 0 01.314-.242l2.807.748A.5.5 0 0024 8.249v-6a.5.5 0 00-.2-.4.49.49 0 00-.429-.082z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});