define("ember-svg-jar/inlined/single-neutral-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-circle</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm7.49 18.239a9.745 9.745 0 01-14.979 0 .25.25 0 01-.054-.207.257.257 0 01.126-.173 33.03 33.03 0 014.04-1.669l.674-.249a.966.966 0 00.477-.723 2.194 2.194 0 00-.249-1.752c-.839-.924-1.659-2.064-1.659-4.915A4.043 4.043 0 0112 4.151a4.043 4.043 0 014.135 4.4c0 2.851-.82 3.991-1.659 4.915a2.194 2.194 0 00-.249 1.752.966.966 0 00.477.723l.675.249a33.134 33.134 0 014.038 1.668.259.259 0 01.126.173.25.25 0 01-.053.208z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});