define("ember-svg-jar/inlined/notes-book-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-book-text</title><path d=\"M18.5 10.5a1 1 0 00-1-1h-11a1 1 0 000 2h11a1 1 0 001-1zM18.5 14.5a1 1 0 00-1-1h-11a1 1 0 000 2h11a1 1 0 001-1zM14.5 17.5h-8a1 1 0 000 2h8a1 1 0 000-2z\"/><path d=\"M23 4.5a2 2 0 00-2-2h-1.75a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-3.5a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-3.5A.25.25 0 017 2.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25H3a2 2 0 00-2 2V22a2 2 0 002 2h18a2 2 0 002-2zm-2 17.25a.25.25 0 01-.25.25H3.25a.25.25 0 01-.25-.25v-17a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v.75a1 1 0 002 0v-.751a.249.249 0 01.249-.249h3.5a.25.25 0 01.25.25v.75a1 1 0 002 0v-.752a.248.248 0 01.248-.248h3.5a.25.25 0 01.25.25v.75a1 1 0 002 0v-.751a.249.249 0 01.249-.249h1.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});