define("ember-svg-jar/inlined/dressing-hanging-rack-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dressing-hanging-rack-1</title><path d=\"M14.933 5.475a.5.5 0 01.567.5v15.503a.5.5 0 01-.5.5H9.5a.5.5 0 01-.5-.5V16.1a.5.5 0 01.146-.354l3.061-3.061a1 1 0 00-1.414-1.414l-1.366 1.367A.25.25 0 019 12.461V3.478a1 1 0 10-2 0v3.983a.25.25 0 01-.427.177L5.207 6.271a1 1 0 00-1.414 1.414l3.061 3.061A.5.5 0 017 11.1v10.378a.5.5 0 01-.5.5H1a1 1 0 000 2h22a1 1 0 000-2h-5a.5.5 0 01-.5-.5V17.1a.5.5 0 01.146-.354l3.061-3.061a1 1 0 00-1.414-1.414l-1.366 1.367a.25.25 0 01-.427-.177V11.6a.5.5 0 01.146-.354l3.061-3.061a1 1 0 10-1.414-1.414l-1.366 1.367a.25.25 0 01-.427-.177V5.567a.5.5 0 01.433-.5l2.6-.349a.75.75 0 00-.2-1.487l-.248.034a.5.5 0 01-.562-.429l-.233-1.731A1.251 1.251 0 0017.884.033L14.416.5a1.252 1.252 0 00-1.073 1.405l.234 1.734a.5.5 0 01-.429.563l-.248.033a.75.75 0 00.2 1.487z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});