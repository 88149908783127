define("ember-svg-jar/inlined/hospital-first-aid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hospital-first-aid</title><path d=\"M21.5 5.867h-4.25a.5.5 0 01-.5-.5V3.883A2.752 2.752 0 0014 1.133h-4a2.752 2.752 0 00-2.75 2.75v1.484a.5.5 0 01-.5.5H2.5a2.5 2.5 0 00-2.5 2.5v12a2.5 2.5 0 002.5 2.5h19a2.5 2.5 0 002.5-2.5v-12a2.5 2.5 0 00-2.5-2.5zM9.75 3.883a.253.253 0 01.25-.25h4a.253.253 0 01.25.25v1.484a.5.5 0 01-.5.5h-3.5a.5.5 0 01-.5-.5zm6.75 11.984a.5.5 0 01-.5.5h-2a.5.5 0 00-.5.5v2a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a.5.5 0 00-.5-.5H8a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 00.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2a.5.5 0 00.5.5h2a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});