define("ember-svg-jar/inlined/smiley-devastated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-devastated</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM5.293 7.207a1 1 0 011.414-1.414l3 3a1 1 0 01-.543 1.693l-3 .5A.927.927 0 016 11a1 1 0 01-.163-1.986l.6-.1a.249.249 0 00.135-.423zm1.259 9.746a6.5 6.5 0 0110.9 0 1 1 0 01-1.676 1.094 4.5 4.5 0 00-7.544 0 1 1 0 01-1.676-1.094zm11.806-5.963l-3.5-.5a1 1 0 01-.565-1.7l3-3a1 1 0 111.414 1.414l-1.232 1.235a.25.25 0 00.141.425l1.026.146a1 1 0 01-.284 1.98z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});