define("ember-svg-jar/inlined/web-service-ning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>web-service-ning</title><circle cx=\"14\" cy=\"6.31\" r=\"1.5\"/><circle cx=\"9.5\" cy=\"6.31\" r=\"1.5\"/><circle cx=\"16.5\" cy=\"1.5\" r=\"1.5\"/><circle cx=\"7.5\" cy=\"1.5\" r=\"1.5\"/><circle cx=\"16.5\" cy=\"22.5\" r=\"1.5\"/><circle cx=\"7.5\" cy=\"22.5\" r=\"1.5\"/><circle cx=\"22.5\" cy=\"16.5\" r=\"1.5\"/><circle cx=\"22.5\" cy=\"7.5\" r=\"1.5\"/><circle cx=\"1.5\" cy=\"16.5\" r=\"1.5\"/><circle cx=\"1.5\" cy=\"7.5\" r=\"1.5\"/><circle cx=\"14\" cy=\"17.31\" r=\"1.5\"/><circle cx=\"9.5\" cy=\"17.31\" r=\"1.5\"/><circle cx=\"17.25\" cy=\"14.06\" r=\"1.5\"/><circle cx=\"17.25\" cy=\"9.56\" r=\"1.5\"/><circle cx=\"6.25\" cy=\"14.06\" r=\"1.5\"/><circle cx=\"6.25\" cy=\"9.56\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});