define("ember-svg-jar/inlined/ecology-globe-leaf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ecology-globe-leaf</title><path d=\"M23.243 4.017a.5.5 0 00-.765-.149c-2.11 1.787-5.186 1.462-6.878 3.454a3.8 3.8 0 00-1.148 3.266 3.133 3.133 0 001.489 1.891.5.5 0 01.157.68 24.146 24.146 0 00-1.677 3.306.748.748 0 00.409.978.731.731 0 00.284.057.751.751 0 00.694-.465 25.636 25.636 0 014.473-7.1.5.5 0 11.746.665 24.31 24.31 0 00-2.63 3.558.5.5 0 00.279.738c1.42.438 2.828-.625 3.786-1.754 1.51-1.773 2.159-6.519.781-9.125zM12 0a12 12 0 000 24 1 1 0 001-1V1a1 1 0 00-1-1zM5.046 4.82a10.078 10.078 0 012.191-1.611.25.25 0 01.335.346 12.568 12.568 0 00-.7 1.392.5.5 0 01-.46.3H5.22a.251.251 0 01-.174-.43zm-2.921 5.6a9.919 9.919 0 01.933-2.895.5.5 0 01.447-.276h1.957a.5.5 0 01.488.608 19.344 19.344 0 00-.393 2.681.5.5 0 01-.5.461H2.619a.5.5 0 01-.494-.579zm1.38 6.329a.5.5 0 01-.447-.276 9.919 9.919 0 01-.933-2.895.5.5 0 01.494-.578h2.44a.5.5 0 01.5.462 19.33 19.33 0 00.393 2.68.5.5 0 01-.489.608zm4.031 4a.25.25 0 01-.3.046 10.078 10.078 0 01-2.19-1.615.251.251 0 01.174-.43h1.192a.5.5 0 01.46.3 12.568 12.568 0 00.7 1.392.249.249 0 01-.036.303zM11 20.87a.25.25 0 01-.418.184 7.408 7.408 0 01-1.471-1.934.252.252 0 010-.248.248.248 0 01.215-.122H10.5a.5.5 0 01.5.5zm0-4.62a.5.5 0 01-.5.5H8.529a.5.5 0 01-.484-.372 16.624 16.624 0 01-.481-2.836.5.5 0 01.5-.542H10.5a.5.5 0 01.5.5zm0-5.75a.5.5 0 01-.5.5H8.063a.5.5 0 01-.5-.542 16.624 16.624 0 01.481-2.836.5.5 0 01.484-.372H10.5a.5.5 0 01.5.5zm0-5.75a.5.5 0 01-.5.5H9.331a.249.249 0 01-.22-.37 7.43 7.43 0 011.471-1.934.25.25 0 01.418.184z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});