define("ember-svg-jar/inlined/programming-language-hash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-language-hash</title><path d=\"M6.531 15a.75.75 0 00.75.75h.708a.25.25 0 01.24.32l-.2.691a.751.751 0 00.513.929.79.79 0 00.208.029.75.75 0 00.721-.542l.36-1.246a.251.251 0 01.24-.181h1.4a.251.251 0 01.241.32l-.2.691a.75.75 0 00.512.929.8.8 0 00.208.029.749.749 0 00.721-.542l.361-1.246a.249.249 0 01.24-.181h1.696a.75.75 0 000-1.5h-1.116a.252.252 0 01-.2-.1.248.248 0 01-.04-.219l.434-1.5a.251.251 0 01.24-.181h1.682a.75.75 0 000-1.5h-1.1a.251.251 0 01-.241-.319l.065-.223a.75.75 0 10-1.442-.416l-.225.778a.249.249 0 01-.24.18h-1.4a.252.252 0 01-.2-.1.248.248 0 01-.04-.219l.064-.223a.75.75 0 10-1.441-.416l-.225.778a.25.25 0 01-.24.18h-1.3a.75.75 0 000 1.5H9a.249.249 0 01.2.1.254.254 0 01.041.22l-.434 1.5a.25.25 0 01-.24.18H7.281a.75.75 0 00-.75.75zm4.312-2.569a.251.251 0 01.241-.181h1.4a.25.25 0 01.24.32l-.434 1.5a.25.25 0 01-.24.18h-1.4a.25.25 0 01-.24-.319z\"/><path d=\"M24 4.75a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h18a3 3 0 003-3zm-14.346-1a.966.966 0 011.692 0 .969.969 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0 .969.969 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0A.969.969 0 016 4.25a.969.969 0 01.154-.5zm-3.562.092A1 1 0 013.5 3.25a.985.985 0 01.846.5.969.969 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.979.979 0 01.092-.408zM22 19.25a1 1 0 01-1 1H3a1 1 0 01-1-1V7a.25.25 0 01.25-.25h19.5A.25.25 0 0122 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});