define("ember-svg-jar/inlined/single-neutral-actions-warning.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-warning</title><path d=\"M14.708 11.926a.254.254 0 00.017-.193.257.257 0 00-.126-.147 30.929 30.929 0 00-3.1-1.258l-.628-.23a.59.59 0 01-.138-.311 2.561 2.561 0 01.058-1.3 5.618 5.618 0 001.465-4.314C12.256 1.756 10.677 0 8.5 0S4.745 1.756 4.745 4.174A5.605 5.605 0 006.2 8.471a2.456 2.456 0 01.072 1.316.605.605 0 01-.135.309l-.63.232c-2.448.9-4.215 1.55-4.754 2.626A9.153 9.153 0 000 16.5a.5.5 0 00.5.5h11.393a.251.251 0 00.222-.134zM18.781 12.271a1.449 1.449 0 00-2.561 0L11.165 21.9a1.428 1.428 0 00.047 1.409 1.454 1.454 0 001.233.691h10.111a1.456 1.456 0 001.233-.686 1.428 1.428 0 00.047-1.409zM17.5 15.25a.75.75 0 01.75.75v2.5a.75.75 0 01-1.5 0V16a.75.75 0 01.75-.75zm0 7a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});