define("ember-svg-jar/inlined/professions-woman-welder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-woman-welder</title><path d=\"M22.725 18.823a14.1 14.1 0 00-5.1-2.33.222.222 0 01-.04-.415 1.467 1.467 0 00.847-1.341v-8.3a6.433 6.433 0 00-12.866 0v8.3a1.467 1.467 0 00.847 1.341.219.219 0 01.126.221.222.222 0 01-.166.194 14.1 14.1 0 00-5.1 2.331 2.93 2.93 0 00-1.15 2.349v1.837a.99.99 0 101.979 0v-1.837a.965.965 0 01.373-.776 10.875 10.875 0 012.756-1.48.248.248 0 01.335.231v4.357a.248.248 0 00.247.248h12.373a.248.248 0 00.247-.248v-4.357a.248.248 0 01.335-.231 10.9 10.9 0 012.755 1.483.966.966 0 01.374.777v1.833a.99.99 0 101.979 0v-1.837a2.931 2.931 0 00-1.151-2.35zM11.01 3.216h1.98a.743.743 0 010 1.485h-1.98a.743.743 0 010-1.485zm3.959 2.722a1.486 1.486 0 011.485 1.485V9.4a1.486 1.486 0 01-1.485 1.485H9.031A1.486 1.486 0 017.546 9.4V7.423a1.486 1.486 0 011.485-1.485zm.99 13.856a.99.99 0 01-.99.99H9.031a.99.99 0 01-.99-.99v-1.433a.248.248 0 01.206-.244 22.266 22.266 0 013.753-.3 22.266 22.266 0 013.753.3.249.249 0 01.206.244z\"/><path d=\"M3.683 10.655a3.8 3.8 0 01-2.172 1.582.494.494 0 00-.283.8 5 5 0 002.758 1.738.452.452 0 00.1.009.493.493 0 00.5-.494v-3.343a.494.494 0 00-.894-.292zM22.772 13.039a.494.494 0 00-.283-.8 3.8 3.8 0 01-2.172-1.582.494.494 0 00-.894.292v3.345a.494.494 0 00.5.494.446.446 0 00.1-.009 5 5 0 002.749-1.74z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});