define("ember-svg-jar/inlined/face-id-approved", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>face-id-approved</title><path d=\"M6.5 0A6.5 6.5 0 000 6.5a5.865 5.865 0 002.514 5.205.342.342 0 01.153.281v3.681A.333.333 0 003 16h6.667a.333.333 0 00.333-.333v-2.209a.333.333 0 01.333-.333h1A1.667 1.667 0 0013 11.458v-1.322a.333.333 0 01.333-.333c.124 0 .251 0 .367-.007a.833.833 0 00.643-.347.817.817 0 00.126-.495v-.091a5.227 5.227 0 00-.949-2.5c-.11-.194-.243-.427-.341-.62a1.32 1.32 0 01-.143-.554C13.039 2.323 10.806 0 6.5 0zM21.015 11.21a1.053 1.053 0 01.21 1.474l-3.058 4.079a1.834 1.834 0 01-1.346.732h-.131a1.839 1.839 0 01-1.3-.543l-1.578-1.579A1.053 1.053 0 0115.3 13.89l1.081 1.08a.351.351 0 00.529-.037l2.634-3.513a1.055 1.055 0 011.471-.21zM23 13.767a.248.248 0 00-.3.039l-1.267 1.267a.25.25 0 00.177.427h.146a.25.25 0 01.25.25v4.5a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-2a.25.25 0 00-.427-.177l-1.5 1.75A.251.251 0 0017 20v2a2 2 0 002 2h3a2 2 0 002-2v-6.5a2 2 0 00-1-1.733z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});