define("ember-svg-jar/inlined/multiple-actions-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-text</title><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M11 9.689a.251.251 0 00.3.075 2.911 2.911 0 011.2-.264h5.261a.25.25 0 00.19-.412 4.548 4.548 0 00-7.047.173.251.251 0 000 .31c.036.039.067.078.096.118zM9.5 12.5a2.976 2.976 0 01.414-1.515.252.252 0 00-.015-.277A5.5 5.5 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.56a.25.25 0 00.25-.25z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M22 11h-9a2 2 0 00-2 2v9a2 2 0 002 2h9a2 2 0 002-2v-9a2 2 0 00-2-2zm-9 2.25a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v8.5a.249.249 0 01-.25.25h-8.5a.25.25 0 01-.25-.25z\"/><path d=\"M20 15h-4.75a.75.75 0 000 1.5H20a.75.75 0 000-1.5zM17.5 19.5a.75.75 0 000-1.5h-2.25a.75.75 0 000 1.5z\"/><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M11 9.689a.251.251 0 00.3.075 2.911 2.911 0 011.2-.264h5.261a.25.25 0 00.19-.412 4.548 4.548 0 00-7.047.173.251.251 0 000 .31c.036.039.067.078.096.118zM9.5 12.5a2.976 2.976 0 01.414-1.515.252.252 0 00-.015-.277A5.5 5.5 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.56a.25.25 0 00.25-.25z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M22 11h-9a2 2 0 00-2 2v9a2 2 0 002 2h9a2 2 0 002-2v-9a2 2 0 00-2-2zm-9 2.25a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v8.5a.249.249 0 01-.25.25h-8.5a.25.25 0 01-.25-.25z\"/><path d=\"M20 15h-4.75a.75.75 0 000 1.5H20a.75.75 0 000-1.5zM17.5 19.5a.75.75 0 000-1.5h-2.25a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});