define("ember-svg-jar/inlined/single-man-actions-check-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-check-2</title><path d=\"M23.786 12.656a.724.724 0 00-.375-.188.991.991 0 00-1.112.2l-4.617 4.616a.249.249 0 01-.353 0l-1.179-1.179a1 1 0 10-1.414 1.415l1.576 1.58a1.691 1.691 0 002.387 0l5.014-5.014a.984.984 0 00.274-.8.736.736 0 00-.201-.63z\"/><path d=\"M23.055 18.19a1 1 0 00-1.254.656A4.5 4.5 0 1117.506 13a1 1 0 000-2 6.5 6.5 0 106.2 8.443 1 1 0 00-.651-1.253zM7.006 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.742a.5.5 0 01.741-.3A6.958 6.958 0 008.465 5a6.35 6.35 0 00.929-.07.5.5 0 01.569.564A3 3 0 014.006 5a2.786 2.786 0 01.1-.742zM11.449 12.021a.5.5 0 00-.226-.343 7.474 7.474 0 00-11.2 4.179.5.5 0 00.479.643h8.64a.5.5 0 00.492-.411 7.95 7.95 0 011.7-3.67.5.5 0 00.115-.398z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});