define("ember-svg-jar/inlined/camping-sleeping-bag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camping-sleeping-bag</title><path d=\"M5.4 18a.25.25 0 00-.244.3A6.726 6.726 0 0012 24a6.726 6.726 0 006.847-5.7.25.25 0 00-.247-.3zM18.75 11.5a.25.25 0 00.25-.25V7A7 7 0 005 7v4.25a.25.25 0 00.25.25zm-3.811-6.836a2.452 2.452 0 01.061.544v1.084A2.866 2.866 0 0112 9a3 3 0 01-2.821-1.788.248.248 0 01.021-.223.252.252 0 01.189-.119 9.385 9.385 0 005.145-2.339.249.249 0 01.408.133zm-5.662.711a.247.247 0 01-.2-.066A.25.25 0 019 5.117 2.877 2.877 0 0112 2.5a3.22 3.22 0 011.6.415.25.25 0 01.044.4 7.873 7.873 0 01-4.367 2.06z\"/><rect x=\"5\" y=\"13\" width=\"14\" height=\"3.5\" rx=\".25\" ry=\".25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});