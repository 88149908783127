define("ember-svg-jar/inlined/image-file-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-refresh</title><path d=\"M14.68 5.538a1.493 1.493 0 10-1.493 1.492 1.493 1.493 0 001.493-1.492z\"/><path d=\"M23.414 3L21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.8a.246.246 0 00.23.25c.35.02 1.016.069 1.487.14A.247.247 0 008 9.939V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-7.374a.243.243 0 00-.239.206 7.192 7.192 0 01-.408 1.453.244.244 0 00.225.34H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M12.92 11.56a.5.5 0 00-.545.108l-1.3 1.3a.246.246 0 01-.343 0 6.31 6.31 0 00-4.075-1.716A6.464 6.464 0 000 17.543 6.374 6.374 0 0011.357 21.6 1 1 0 109.8 20.354a4.388 4.388 0 01-4.611 1.488 4.378 4.378 0 114.111-7.47.256.256 0 010 .367l-1.382 1.386a.5.5 0 00.354.854h4.457a.5.5 0 00.5-.5v-4.458a.5.5 0 00-.309-.461zM14.5 12.045v.705a.25.25 0 00.25.25h5.563a.186.186 0 00.158-.286l-3.236-5.178a.373.373 0 00-.633 0l-1.81 2.9a.187.187 0 01-.317 0L13.831 9.4a.375.375 0 00-.633 0l-.293.47c-.1.172.037.231.119.249a1.669 1.669 0 01.243.077 2 2 0 011.233 1.849z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});