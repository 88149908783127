define("ember-svg-jar/inlined/monitor-bug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-bug</title><path d=\"M11.85 16.012a.055.055 0 00-.035-.012H10.5a.5.5 0 01-.5-.5V15a.5.5 0 01.5-.5h.25a.249.249 0 00.25-.25 2.25 2.25 0 013.65-1.76l.881.7a.252.252 0 00.267.029 4.406 4.406 0 011.952-.469h.02a.219.219 0 00.227-.2.463.463 0 000-.054V2a2 2 0 00-2-2H2a2 2 0 00-2 2v10.5a2 2 0 002 2h5.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5H5a1 1 0 000 2h5.1a.25.25 0 00.216-.124 2.239 2.239 0 011.937-1.125.185.185 0 00.115-.329zM2.5 11.5A.5.5 0 012 11V2.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5V11a.5.5 0 01-.5.5z\"/><path d=\"M24 19a.75.75 0 00-.75-.75H21a.25.25 0 01-.25-.25v-.75a2.706 2.706 0 00-.075-.608.25.25 0 01.089-.251l1.953-1.554a.75.75 0 10-.934-1.174l-1.713 1.364a.25.25 0 01-.323-.01 2.987 2.987 0 00-3.995 0 .248.248 0 01-.322.01l-1.713-1.363a.75.75 0 00-.934 1.174l1.953 1.554a.249.249 0 01.089.251 2.706 2.706 0 00-.075.608V18a.25.25 0 01-.25.25h-2.25a.75.75 0 000 1.5h2.25a.25.25 0 01.25.25v.25a2.713 2.713 0 00.075.608.251.251 0 01-.088.251l-1.954 1.554a.75.75 0 00.934 1.174l1.714-1.363a.249.249 0 01.322.01 2.986 2.986 0 003.994 0 .249.249 0 01.322-.01l1.714 1.363a.75.75 0 10.934-1.174l-1.954-1.553a.25.25 0 01-.088-.252 2.7 2.7 0 00.075-.607V20a.25.25 0 01.25-.25h2.25A.75.75 0 0024 19z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});