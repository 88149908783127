define("ember-svg-jar/inlined/plane-boarding-pass-smartphone-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-boarding-pass-smartphone-check</title><path d=\"M9.185 19.482a.241.241 0 00-.233-.183H2.474a.5.5 0 01-.5-.5V3.464a.5.5 0 01.5-.5h10.887a.5.5 0 01.495.5V9.3a.242.242 0 00.337.223 8.437 8.437 0 011.446-.431.239.239 0 00.2-.236V2.969A2.969 2.969 0 0012.866 0h-9.9A2.969 2.969 0 000 2.969v17.815a2.969 2.969 0 002.969 2.969h8.286a.248.248 0 00.166-.43 8.263 8.263 0 01-2.236-3.841z\"/><path d=\"M5.6 10.637a.248.248 0 01-.268.009l-1.041-.62-.227.021-.959.846a.238.238 0 00-.036.035.212.212 0 00.032.3l2.11 2.064a.381.381 0 00.423.012l6.406-4.33a1.084 1.084 0 00.506-.937 1.038 1.038 0 00-.061-.349 1.1 1.1 0 00-.786-.706 1.028 1.028 0 00-.808.155L9.158 8.318a.246.246 0 01-.226.028L5.073 6.909a.26.26 0 00-.247.035l-.706.524a.2.2 0 00-.042.039.263.263 0 00.029.37l2.568 1.59a.247.247 0 01.011.413zM23.071 17.863a.989.989 0 00-1.215.7 4.7 4.7 0 11-4.536-5.939.99.99 0 000-1.98 6.681 6.681 0 106.446 8.439.989.989 0 00-.695-1.22z\"/><path d=\"M17.145 17.167l-1.26-1.258a.989.989 0 00-1.4 1.4l1.63 1.63a1.7 1.7 0 002.406 0l5.188-5.187a.989.989 0 00-1.4-1.4L17.5 17.167a.247.247 0 01-.355 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});