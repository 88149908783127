define("ember-svg-jar/inlined/laptop.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop</title><path d=\"M23.3 20.691l-2.1-6.83a1.486 1.486 0 00-.339-.56 2.639 2.639 0 00.643-1.717V2.918A2.669 2.669 0 0018.831.251H5.164A2.67 2.67 0 002.5 2.918v8.666a2.64 2.64 0 00.641 1.716 1.486 1.486 0 00-.339.56L.728 20.615a2.167 2.167 0 001.938 3.136h18.662a2.153 2.153 0 001.844-1.028 2.1 2.1 0 00.128-2.032zm-7.793.457a.5.5 0 01-.4.208H8.9a.5.5 0 01-.474-.658l.6-1.8a.5.5 0 01.474-.342h5a.5.5 0 01.475.342l.6 1.8a.5.5 0 01-.075.45zM4.5 2.918a.667.667 0 01.667-.667h13.664a.667.667 0 01.666.667v8.666a.667.667 0 01-.666.667H5.164a.667.667 0 01-.667-.667z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});