define("ember-svg-jar/inlined/iso-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>iso-1</title><path d=\"M17.5 12.126a1.377 1.377 0 00-1.375 1.374v4a1.375 1.375 0 102.75 0v-4a1.377 1.377 0 00-1.375-1.374z\"/><path d=\"M22.478 0H1.522A1.524 1.524 0 000 1.523v20.955A1.524 1.524 0 001.522 24h20.956A1.524 1.524 0 0024 22.478V1.523A1.524 1.524 0 0022.478 0zM7.5 18.876a.625.625 0 010 1.25h-4a.625.625 0 010-1.25h1.125a.25.25 0 00.25-.25v-6.25a.25.25 0 00-.25-.25H3.5a.625.625 0 010-1.25h4a.625.625 0 010 1.25H6.375a.25.25 0 00-.25.25v6.25a.25.25 0 00.25.25zm3.305-4.422l1.949.975a2.479 2.479 0 01-1.108 4.7H9.5a.625.625 0 010-1.25h2.146a1.229 1.229 0 00.548-2.329l-1.949-.974a2.48 2.48 0 011.109-4.7H13.5a.625.625 0 010 1.25h-2.146a1.229 1.229 0 00-.549 2.328zm9.32 3.047a2.625 2.625 0 11-5.25 0v-4a2.625 2.625 0 115.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});