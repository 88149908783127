define("ember-svg-jar/inlined/phone-actions-wait", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-wait</title><circle cx=\"13.51\" cy=\"5.657\" r=\"1.5\"/><circle cx=\"17.51\" cy=\"5.657\" r=\"1.5\"/><circle cx=\"21.51\" cy=\"5.657\" r=\"1.5\"/><path d=\"M18.156 14.173a2.562 2.562 0 00-3.536 0l-.385.385a47.115 47.115 0 01-5.789-5.79l.385-.385a2.5 2.5 0 000-3.535L6.71 2.727a2.5 2.5 0 00-3.536 0L2.011 3.89a3.506 3.506 0 00-.442 4.4A46.893 46.893 0 0014.71 21.435a3.541 3.541 0 004.4-.442l1.164-1.163a2.5 2.5 0 000-3.536z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});