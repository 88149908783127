define("ember-svg-jar/inlined/face-id-8", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>face-id-8</title><path d=\"M15.772 12.952a1 1 0 011.676 1.094 6.5 6.5 0 01-10.9 0 1 1 0 011.676-1.094 4.5 4.5 0 007.544 0zM9.5 11a1 1 0 01-1-1V8a1 1 0 112 0v2a1 1 0 01-1 1zM14.5 11a1 1 0 01-1-1V8a1 1 0 112 0v2a1 1 0 01-1 1zM18.364 18.364a1 1 0 011.414 0l.707.707a1 1 0 01-1.414 1.414l-.707-.707a1 1 0 010-1.414zM12 21.5a1 1 0 011 1v.5a1 1 0 01-2 0v-.5a1 1 0 011-1zM17.3 6.7a1 1 0 010-1.415l1.768-1.767a1 1 0 011.414 1.414L18.718 6.7a1 1 0 01-1.418 0zM12 3.5a1 1 0 01-1-1V1a1 1 0 012 0v1.5a1 1 0 01-1 1zM20.5 12a1 1 0 011-1H23a1 1 0 010 2h-1.5a1 1 0 01-1-1zM5.636 5.636a1 1 0 01-1.414 0l-.707-.707a1 1 0 011.414-1.414l.707.707a1 1 0 010 1.414zM5.282 18.717a1 1 0 010 1.415l-.353.353a1 1 0 01-1.414-1.414l.353-.354a1 1 0 011.414 0zM2.5 12a1 1 0 01-1 1H1a1 1 0 010-2h.5a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});