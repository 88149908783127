define("ember-svg-jar/inlined/video-game-mario-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-mario-2</title><path d=\"M18.634 9.9a.249.249 0 00.044-.216A2.253 2.253 0 0016.5 8h-9a2.253 2.253 0 00-2.178 1.687.249.249 0 00.044.216.253.253 0 00.2.1h12.87a.253.253 0 00.198-.103z\"/><path d=\"M3.505 9.376a.25.25 0 00.384-.138A3.757 3.757 0 017.5 6.5h9a3.756 3.756 0 013.611 2.738.25.25 0 00.384.138A3.5 3.5 0 0018.5 3a.542.542 0 01-.535-.458A3 3 0 0015 0H9a3 3 0 00-2.965 2.542A.541.541 0 015.5 3a3.5 3.5 0 00-1.995 6.376zm5.738-7.8a.5.5 0 01.686.172l.89 1.484a.25.25 0 00.422.01l.343-.514a.519.519 0 01.832 0l.343.514a.25.25 0 00.422-.01l.89-1.484a.5.5 0 01.858.514l-1.5 2.5a.5.5 0 01-.417.238H13a.5.5 0 01-.416-.223l-.376-.564a.25.25 0 00-.416 0l-.376.564a.559.559 0 01-.428.223.5.5 0 01-.417-.243l-1.5-2.5a.5.5 0 01.172-.686zM11.151 13.672a.75.75 0 00-.279-1.024l-3-1.713a.75.75 0 10-.744 1.3l3 1.714a.751.751 0 001.023-.277zM7.75 15v.5a.75.75 0 001.5 0V15a.75.75 0 00-1.5 0zM17.151 11.214a.75.75 0 00-1.023-.279l-3 1.713a.75.75 0 00.744 1.3l3-1.714a.749.749 0 00.279-1.02zM14.75 15v.5a.75.75 0 001.5 0V15a.75.75 0 00-1.5 0z\"/><path d=\"M23.6 11.2a1.014 1.014 0 00-.877-.163l-2.275.65A1 1 0 0018.5 12v3.5a6.473 6.473 0 01-.513 2.531.5.5 0 01-.69.248A10.62 10.62 0 0013.919 17a2.146 2.146 0 00-1.723.88.25.25 0 01-.392 0 2.144 2.144 0 00-1.723-.88A10.62 10.62 0 006.7 18.279a.5.5 0 01-.69-.248A6.473 6.473 0 015.5 15.5V12a1 1 0 00-1.95-.312l-2.275-.65A1.013 1.013 0 00.4 11.2a1 1 0 00-.4.8c0 2.66.961 7.081 4.487 7.472a8.5 8.5 0 0015.026 0C23.039 19.081 24 14.66 24 12a1 1 0 00-.4-.8zM3.36 16.451a.251.251 0 01-.291-.1 7.065 7.065 0 01-.9-2.606.251.251 0 01.315-.282l.654.188a.5.5 0 01.363.48V15.5c0 .234.01.466.028.7a.249.249 0 01-.169.251zm12.86 3.989a6.488 6.488 0 01-8.44 0 .25.25 0 01.163-.44h8.114a.25.25 0 01.163.44zm4.711-4.089a.25.25 0 01-.459-.156 8.9 8.9 0 00.028-.7v-1.364a.5.5 0 01.363-.48l.654-.188a.25.25 0 01.315.282 7.065 7.065 0 01-.901 2.606z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});