define("ember-svg-jar/inlined/business-crossroad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-crossroad</title><path d=\"M.293 15.207l2.5 2.5A.993.993 0 003.5 18H10a.5.5 0 01.5.5v5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-5a.5.5 0 01.5-.5h6a1 1 0 001-1v-5a1 1 0 00-1-1h-6a.5.5 0 01-.5-.5v-1A.5.5 0 0114 9h6.5a.993.993 0 00.707-.293l2.5-2.5a1 1 0 000-1.414l-2.5-2.5A.993.993 0 0020.5 2H14a.5.5 0 01-.5-.5 1.5 1.5 0 00-3 0 .5.5 0 01-.5.5H4a1 1 0 00-1 1v5a1 1 0 001 1h6a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H3.5a.993.993 0 00-.707.293l-2.5 2.5a1 1 0 000 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});