define("ember-svg-jar/inlined/car-garage-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-garage-1</title><path d=\"M23.672 9.953L13.348.527a1.987 1.987 0 00-2.7 0L.328 9.953a1 1 0 001.348 1.477l9.987-9.119a.5.5 0 01.674 0l9.987 9.119a1 1 0 001.348-1.477z\"/><path d=\"M20.856 13.987a1 1 0 00-1.372-.343l-.509.306a.5.5 0 01-.754-.37l-.121-1.063a2 2 0 00-1.986-1.765H7.89a2 2 0 00-1.99 1.765l-.121 1.063a.5.5 0 01-.754.37l-.509-.306a1 1 0 00-1.029 1.715l.823.494a.5.5 0 01.15.718A2.455 2.455 0 004 18v1a1.5 1.5 0 001.5 1.5h13A1.5 1.5 0 0020 19v-1a2.455 2.455 0 00-.459-1.43.5.5 0 01.15-.718l.823-.494a1 1 0 00.342-1.371zm-5.19-1.235a.5.5 0 01.5.441l.206 1.75a.5.5 0 01-.5.558H8.129a.5.5 0 01-.5-.558l.206-1.75a.5.5 0 01.5-.441zM7.5 21.5h-2a.5.5 0 00-.5.5v.5a1.5 1.5 0 103 0V22a.5.5 0 00-.5-.5zM18.5 21.5h-2a.5.5 0 00-.5.5v.5a1.5 1.5 0 003 0V22a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});