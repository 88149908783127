define("ember-svg-jar/inlined/delivery-drone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>delivery-drone</title><path d=\"M16.283 14.391h-2.6a.25.25 0 00-.25.25v2.973a.25.25 0 01-.427.177l-.831-.832a.252.252 0 00-.354 0l-.831.832a.25.25 0 01-.427-.177v-2.973a.25.25 0 00-.25-.25h-2.6a.5.5 0 00-.5.5V22.5a.5.5 0 00.5.5h8.566a.5.5 0 00.5-.5v-7.609a.5.5 0 00-.496-.5z\"/><path d=\"M23.934 2.228a.958.958 0 00-1.141-.728l-2.9.645a.195.195 0 01-.237-.189.957.957 0 10-1.913 0 .194.194 0 01-.236.189L14.6 1.5a.956.956 0 10-.414 1.867l3.359.747a.249.249 0 01.2.244v1.425a.962.962 0 01-.689.918l-2.093.61a.188.188 0 01-.207-.073A3.326 3.326 0 009.274 7.2.25.25 0 019 7.3c-.171-.053-.65-.19-2.046-.6a.962.962 0 01-.689-.918V4.359a.249.249 0 01.2-.244l3.359-.747A.956.956 0 109.4 1.5l-2.9.645a.195.195 0 01-.237-.189.957.957 0 10-1.913 0 .195.195 0 01-.237.189L1.207 1.5a.956.956 0 10-.414 1.868l3.359.747a.25.25 0 01.2.244v1.424a2.884 2.884 0 002.062 2.754c1.441.421 1.9.557 2.067.6a.251.251 0 01.184.222 3.244 3.244 0 00.058.4.25.25 0 01-.191.294l-2.58.574a2.872 2.872 0 00-2.138 3.59l.4 1.4a.957.957 0 101.84-.526l-.4-1.4a.957.957 0 01.711-1.2l2.994-.665a.83.83 0 00.264-.111.25.25 0 01.29.017 3.295 3.295 0 004.171 0 .251.251 0 01.29-.017.83.83 0 00.264.111l3 .665a.958.958 0 01.711 1.2l-.4 1.4a.957.957 0 101.84.526l.4-1.4a2.873 2.873 0 00-2.138-3.589l-2.58-.574a.25.25 0 01-.191-.294 3.244 3.244 0 00.058-.4.25.25 0 01.184-.222c.171-.046.627-.182 2.067-.6a2.884 2.884 0 002.066-2.754V4.359a.25.25 0 01.2-.244l3.359-.747a.956.956 0 00.72-1.14zM12 10.565a1.435 1.435 0 111.435-1.435A1.436 1.436 0 0112 10.565z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});