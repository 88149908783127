define("ember-svg-jar/inlined/architecture-window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>architecture-window</title><path d=\"M24 7.5A1.5 1.5 0 0022.5 6h-1.01a.5.5 0 01-.453-.287 10 10 0 00-18.075 0A.5.5 0 012.51 6H1.5A1.5 1.5 0 000 7.5v2A1.5 1.5 0 001.5 11a.5.5 0 01.5.5v11A1.5 1.5 0 003.5 24h17a1.5 1.5 0 001.5-1.5v-11a.5.5 0 01.5-.5A1.5 1.5 0 0024 9.5zM5.5 11a.25.25 0 01.25-.25h5A.25.25 0 0111 11v3.25a.25.25 0 01-.25.25h-5a.25.25 0 01-.25-.25zm1.189-4.733a.25.25 0 01.382-.032l2.088 2.088a.25.25 0 01-.177.427H5.935a.25.25 0 01-.2-.1.253.253 0 01-.046-.214 6.484 6.484 0 011-2.169zM18.308 8.44a.25.25 0 01-.243.31h-3.047a.25.25 0 01-.177-.427l2.089-2.089a.25.25 0 01.381.033 6.484 6.484 0 01.997 2.173zm-4.881-1.531A.25.25 0 0113 6.732V3.884a.25.25 0 01.3-.245 6.452 6.452 0 012.122.84.25.25 0 01.045.39zM11 6.732a.25.25 0 01-.427.177l-2.04-2.04a.25.25 0 01.045-.39 6.452 6.452 0 012.122-.84.25.25 0 01.3.245zM13 11a.25.25 0 01.25-.25h5a.25.25 0 01.25.25v3.25a.25.25 0 01-.25.25h-5a.25.25 0 01-.25-.25zm-7.5 9.5v-3.75a.25.25 0 01.25-.25h5a.25.25 0 01.25.25v4a.25.25 0 01-.25.25H6a.5.5 0 01-.5-.5zm13 0a.5.5 0 01-.5.5h-4.75a.25.25 0 01-.25-.25v-4a.25.25 0 01.25-.25h5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});