define("ember-svg-jar/inlined/old-people-woman-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>old-people-woman-2</title><path d=\"M.5 23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-.106a2.464 2.464 0 00-.967-1.977 13.678 13.678 0 00-4.932-2.26.247.247 0 00-.209.04l-5.1 3.707a.5.5 0 01-.588 0L6.608 19.2a.247.247 0 00-.209-.04 13.678 13.678 0 00-4.932 2.26A2.462 2.462 0 00.5 23.394z\"/><path d=\"M12 19.5a7.026 7.026 0 007-7v-2a6.962 6.962 0 00-1.72-4.586.25.25 0 01.189-.414H18a1 1 0 000-2h-2.76a.252.252 0 01-.184-.08.257.257 0 01-.066-.19C15 3.154 15 3.077 15 3a3 3 0 00-6 0c0 .077 0 .154.01.23a.252.252 0 01-.25.271H6a1 1 0 000 2h.531a.25.25 0 01.189.414A6.962 6.962 0 005 10.5v2a7.026 7.026 0 007 7zm-5-7v-.95a.25.25 0 01.243-.25 7 7 0 004.694-1.693.1.1 0 01.126 0 7 7 0 004.694 1.693.251.251 0 01.243.25v.95a5 5 0 01-10 0z\"/><circle cx=\"14\" cy=\"13\" r=\"1\"/><circle cx=\"10\" cy=\"13\" r=\"1\"/><path d=\"M10.38 15.344a.64.64 0 00.317.719 2.354 2.354 0 00.434.179 2.964 2.964 0 00.869.127 3.024 3.024 0 00.87-.125 2.444 2.444 0 00.435-.182.639.639 0 00-.3-1.2H11a.64.64 0 00-.62.482z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});