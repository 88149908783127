define("ember-svg-jar/inlined/single-neutral-actions-monitor.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-monitor</title><path d=\"M9.5 13a3.441 3.441 0 01.31-1.428.5.5 0 00-.3-.68 7.2 7.2 0 00-2.304-.392 7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643H9a.5.5 0 00.5-.5z\"/><circle cx=\"7\" cy=\"4.75\" r=\"4.75\"/><path d=\"M20 22h-1.25a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25H22a2 2 0 002-2v-6a2 2 0 00-2-2h-9a2 2 0 00-2 2v6a2 2 0 002 2h3.25a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H15a.988.988 0 00-.847.5.974.974 0 00-.153.5 1 1 0 001 1h5a1 1 0 001-1 .974.974 0 00-.153-.5A.988.988 0 0020 22zm-7-8.75a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v5.5a.249.249 0 01-.25.25h-8.5a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});