define("ember-svg-jar/inlined/messages-bubble-square-heart-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-heart-1</title><path d=\"M10.03 13.779a5.025 5.025 0 017.329-1.9.249.249 0 00.281 0 5 5 0 013.571-.814.249.249 0 00.288-.247V1.5A1.5 1.5 0 0020 0H1.5A1.5 1.5 0 000 1.5v12.053A1.459 1.459 0 001.5 15H3v3.5a.5.5 0 00.832.374L8.19 15h1.229a.249.249 0 00.243-.191 4.859 4.859 0 01.368-1.03z\"/><path d=\"M21.039 12.546a3.485 3.485 0 00-3.058.987l-.481.482-.482-.482a3.526 3.526 0 00-5.646.916 3.521 3.521 0 00.653 4.063l5.113 5.334a.5.5 0 00.723 0l5.106-5.327a3.524 3.524 0 00.66-4.07 3.487 3.487 0 00-2.588-1.903z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});