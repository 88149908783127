define("ember-svg-jar/inlined/warehouse-cart-package", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>warehouse-cart-package</title><path d=\"M11.25 17.49a1 1 0 001 1h7.5a1 1 0 001-1v-7a1 1 0 00-1-1H17.5a.25.25 0 00-.25.25v2.25a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5V9.74a.25.25 0 00-.25-.25h-2.25a1 1 0 00-1 1z\"/><path d=\"M22.2 23.885a1 1 0 00.447-1.342l-.726-1.443a1.988 1.988 0 00-1.789-1.106h-8.4a.25.25 0 01-.23-.154 2.994 2.994 0 00-1.594-1.6.251.251 0 01-.151-.2L8.09 3.875A4.369 4.369 0 003.75.01h-1.5a1 1 0 100 2h1.5A2.369 2.369 0 016.1 4.107l1.641 13.869a.249.249 0 01-.151.26 2.992 2.992 0 103.917 3.908.25.25 0 01.23-.154h8.24a.25.25 0 01.224.138l.654 1.309a1 1 0 001.342.448z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});