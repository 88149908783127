define("ember-svg-jar/inlined/famous-character-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character-1</title><path d=\"M7.5 11.276v1.61a1.5 1.5 0 00.918 1.383A21.333 21.333 0 0012 14.5a17.159 17.159 0 003.582-.233 1.5 1.5 0 00.918-1.383v-1.61c.608-.4 2.038-1.483 2.038-2.776v-2a6.538 6.538 0 00-13.076 0v2c0 1.295 1.43 2.377 2.038 2.778zm0-4.776a4.481 4.481 0 012.862-4.182.5.5 0 01.423.027.493.493 0 01.251.342A1 1 0 0012 3.5a.982.982 0 00.967-.792.5.5 0 01.246-.357.506.506 0 01.433-.031A4.484 4.484 0 0116.5 6.5V9a.5.5 0 01-.5.5c-.4 0-.9.339-.9.5v2.5a.5.5 0 01-.265.44.489.489 0 01-.235.06.5.5 0 01-.278-.084l-.989-.66A1.523 1.523 0 0012.5 12h-1a1.5 1.5 0 00-.833.256l-1 .661a.5.5 0 01-.777-.417V10c0-.161-.5-.5-.89-.5a.5.5 0 01-.5-.5z\"/><path d=\"M9.127 7.171l1 .5a.741.741 0 00.334.079.75.75 0 00.339-1.421l-1-.5a.75.75 0 00-.67 1.342zM13.463 7.75a.744.744 0 00.334-.079l1-.5a.75.75 0 00-.67-1.342l-1 .5a.75.75 0 00.336 1.421zM12 15.5a24.714 24.714 0 00-3.2.265.247.247 0 00-.182.36l.273.546a1.5 1.5 0 001.341.829h3.327a1.49 1.49 0 001.342-.829s.174-.317.279-.529a.265.265 0 00-.134-.4A24.249 24.249 0 0012 15.5z\"/><path d=\"M19.927 18.846A.232.232 0 0020 18.67V15.7a1.012 1.012 0 00-.373-.8l-.851-.64a.977.977 0 00-.81-.228 1 1 0 00-.7.475l-1.466 2.62a2.48 2.48 0 01-2.237 1.373h-3.327A2.485 2.485 0 018 17.118L6.733 14.5a1 1 0 00-.695-.474.977.977 0 00-.79.207l-.9.681a.987.987 0 00-.348.772v2.984a.232.232 0 00.073.176 14.708 14.708 0 013.606 4.992.24.24 0 00.227.162h8.188a.24.24 0 00.227-.162 14.708 14.708 0 013.606-4.992zM12 23a1.75 1.75 0 111.75-1.75A1.752 1.752 0 0112 23zM3.171 19.376A.5.5 0 013 19v-1.243c0-.257-.235-.2-.346-.133a12.936 12.936 0 00-1.149.793 2.465 2.465 0 00-.967 1.977V23.5a.5.5 0 00.5.5H6.3a.252.252 0 00.233-.351 15.69 15.69 0 00-3.362-4.273zM22.5 18.417a13.265 13.265 0 00-1.134-.784c-.116-.07-.361-.141-.361.172V19a.5.5 0 01-.171.376 15.713 15.713 0 00-3.356 4.273.252.252 0 00.233.351h5.256a.5.5 0 00.5-.5v-3.106a2.465 2.465 0 00-.967-1.977z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});