define("ember-svg-jar/inlined/gardening-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gardening-house</title><path d=\"M8 21H2a.5.5 0 00-.5.5v1A1.5 1.5 0 003 24h5a.5.5 0 00.5-.5v-2A.5.5 0 008 21z\"/><rect x=\"1.5\" y=\"16\" width=\"7\" height=\"3.5\" rx=\".5\" ry=\".5\"/><rect x=\"1.5\" y=\"11.5\" width=\"7\" height=\"3\" rx=\".5\" ry=\".5\"/><path d=\"M22 21h-6a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h5a1.5 1.5 0 001.5-1.5v-1a.5.5 0 00-.5-.5z\"/><rect x=\"15.5\" y=\"16\" width=\"7\" height=\"3.5\" rx=\".5\" ry=\".5\"/><rect x=\"15.5\" y=\"11.5\" width=\"7\" height=\"3\" rx=\".5\" ry=\".5\"/><path d=\"M14.327.415A.5.5 0 0014 .188a10.7 10.7 0 00-4 0 .5.5 0 00-.388.624l1.9 6.923a.5.5 0 00.964 0l1.9-6.923a.5.5 0 00-.049-.397zM8.2 1.321A.5.5 0 007.5 1a10.458 10.458 0 00-3.2 2.331.5.5 0 00.048.727l5.331 4.39a.25.25 0 00.4-.26zM19.824 3.705a.5.5 0 00-.132-.374A10.471 10.471 0 0016.5 1a.5.5 0 00-.695.32l-1.892 6.868a.25.25 0 00.4.26l5.33-4.39a.5.5 0 00.181-.353zM22.321 9.836a.5.5 0 00.127-.385 10.5 10.5 0 00-1.22-3.984.5.5 0 00-.758-.147l-5.145 4.237a.25.25 0 00.159.443h6.466a.5.5 0 00.371-.164zM8.675 9.557L3.529 5.32a.5.5 0 00-.757.147 10.5 10.5 0 00-1.22 3.984.5.5 0 00.5.549h6.464a.25.25 0 00.159-.443z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});