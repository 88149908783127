define("ember-svg-jar/inlined/arrow-left-x", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-left-x</title><path d=\"M22.333 3H8.283a1.506 1.506 0 00-1.125.508l-6.617 7.5a1.5 1.5 0 000 1.985l6.618 7.5A1.506 1.506 0 008.283 21h14.05a1.5 1.5 0 001.5-1.5v-15a1.5 1.5 0 00-1.5-1.5zM19.54 15.293a1 1 0 01-1.414 1.414l-3.117-3.116a.249.249 0 00-.353 0l-3.117 3.116a1 1 0 01-1.414-1.414l3.117-3.116a.251.251 0 000-.354l-3.117-3.116a1 1 0 011.414-1.414l3.117 3.116a.249.249 0 00.353 0l3.117-3.116a1 1 0 011.414 1.414l-3.116 3.116a.249.249 0 000 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});