define("ember-svg-jar/inlined/smiley-crazy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-crazy</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm-2 8.5a2 2 0 11-2-2 2 2 0 012 2zm3.084.1a3.5 3.5 0 016.415 0 1 1 0 01-.516 1.316.973.973 0 01-.4.084 1 1 0 01-.917-.6 1.5 1.5 0 00-2.751 0 1 1 0 11-1.832-.8zM8 19v-2.75a.25.25 0 00-.25-.25H6.5a1 1 0 010-2h11a1 1 0 010 2h-1.25a.25.25 0 00-.25.25V19a4 4 0 01-8 0z\"/><path d=\"M11 16.25v2.25a1 1 0 002 0v-2.25a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});