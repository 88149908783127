define("ember-svg-jar/inlined/multiple-actions-flight.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-flight</title><ellipse cx=\"14.013\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M12.024 8.534a.5.5 0 00-.182.771A7.417 7.417 0 0113.5 13.5v.025A.5.5 0 0014 14h6.127a.376.376 0 00.376-.4A6.014 6.014 0 0014.489 8a5.95 5.95 0 00-2.465.534zM11.9 13.881a.376.376 0 00.1-.281 6.025 6.025 0 00-12-.267.6.6 0 00.6.667h11.023a.377.377 0 00.277-.119z\"/><ellipse cx=\"5.513\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M23.9 16.764a1.871 1.871 0 00-1.35-1.21 1.8 1.8 0 00-1.41.27l-2 1.365a.25.25 0 01-.228.027l-4.651-1.731a.826.826 0 00-.784.111s-1 .758-1.025.787a.823.823 0 00-.2.6.852.852 0 00.35.61l2.7 1.672a.25.25 0 01.017.413l-.957.709a.25.25 0 01-.276.015l-1.172-.69a.773.773 0 00-.745.132s-.974.826-1 .856a.776.776 0 00.145 1.1l2.876 2.02a.98.98 0 001.084.032l7.855-4.878A1.868 1.868 0 0024 17.37a1.8 1.8 0 00-.1-.606z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});