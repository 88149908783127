define("ember-svg-jar/inlined/common-file-text-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-text-edit</title><path d=\"M20 7.5a1 1 0 00-1-1h-7a1 1 0 000 2h7a1 1 0 001-1z\"/><path d=\"M23.413 3L21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v9.582a.249.249 0 00.422.181c.436-.422 1.1-1.06 1.412-1.348A.5.5 0 008 10.043V2.5a.5.5 0 01.5-.5h10.878a.5.5 0 01.353.147l2.121 2.121a.5.5 0 01.148.354V18a.5.5 0 01-.5.5h-9.966a.5.5 0 00-.353.147l-1.426 1.427a.249.249 0 00.177.426H22a2 2 0 002-2V4.415A2 2 0 0023.413 3z\"/><path d=\"M14.024 11a.248.248 0 00-.214.377 4.087 4.087 0 01.59 1.423.243.243 0 00.239.2H16.5a1 1 0 000-2zM1.061 20.132a.25.25 0 00-.422.127L.011 23.4A.5.5 0 00.5 24a.466.466 0 00.1-.01l3.143-.628a.25.25 0 00.127-.422zM12.035 11.591a2.71 2.71 0 00-3.606.274l-6.58 6.58a.25.25 0 000 .353L5.2 22.151a.249.249 0 00.353 0l6.68-6.679a2.623 2.623 0 00-.198-3.881z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});