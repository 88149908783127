define("ember-svg-jar/inlined/single-neutral-actions-flight.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-flight</title><path d=\"M10.765 14.907a2.63 2.63 0 01.373-.35l.9-.666a2.213 2.213 0 01.43-.249.5.5 0 00.125-.839A7.464 7.464 0 00.567 16.357a.5.5 0 00.479.643h8.8a.366.366 0 00.365-.395 2.315 2.315 0 01.554-1.698z\"/><circle cx=\"7.546\" cy=\"5.25\" r=\"4.75\"/><path d=\"M20.592 15.323l-2 1.365a.25.25 0 01-.228.028l-4.651-1.732a.832.832 0 00-.784.112l-.891.661a1.217 1.217 0 00-.134.125.823.823 0 00-.2.6.861.861 0 00.351.612l2.7 1.671a.249.249 0 01.018.413l-.957.71a.252.252 0 01-.276.014l-1.172-.69a.776.776 0 00-.746.132l-.87.73a1.385 1.385 0 00-.128.126.774.774 0 00.144 1.1l2.877 2.019a.98.98 0 001.084.032l7.855-4.877a1.865 1.865 0 10-2-3.152z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});