define("ember-svg-jar/inlined/laundry-machine-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laundry-machine-2</title><path d=\"M22.435 6.261H1.565a.517.517 0 00-.522.522v15.652A1.564 1.564 0 002.609 24h18.782a1.564 1.564 0 001.566-1.565V6.783a.517.517 0 00-.522-.522zM12 21.913a6.783 6.783 0 116.783-6.783A6.8 6.8 0 0112 21.913zM21.391 0H2.609a1.568 1.568 0 00-1.566 1.565v2.609a.522.522 0 00.522.522h20.87a.522.522 0 00.522-.522V1.565A1.568 1.568 0 0021.391 0zM8.87 3.13H3.652a.522.522 0 110-1.043H8.87a.522.522 0 010 1.043zm4.521.363a1.044 1.044 0 111.044-1.043 1.043 1.043 0 01-1.044 1.043zm3.479 0a1.044 1.044 0 111.043-1.043 1.043 1.043 0 01-1.043 1.043zm3.478-.068a1.044 1.044 0 111.043-1.044 1.043 1.043 0 01-1.043 1.044z\"/><circle cx=\"12\" cy=\"15.13\" r=\"3.652\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});