define("ember-svg-jar/inlined/martial-arts-sword-fencing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>martial-arts-sword-fencing</title><path d=\"M4.129 18.458a.5.5 0 00-.707 0L.439 21.44A1.5 1.5 0 001.5 24a1.487 1.487 0 001.058-.441l2.983-2.981a.5.5 0 000-.707zM23.817.183a.626.626 0 00-.853-.029L8.251 12.986a1 1 0 01-.927.21 5.491 5.491 0 00-4.6.9.5.5 0 00-.07.766l6.486 6.486a.5.5 0 00.766-.07 5.821 5.821 0 001.074-3.083 4.922 4.922 0 00-.178-1.512 1 1 0 01.21-.927L23.846 1.035a.627.627 0 00-.029-.852z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});