define("ember-svg-jar/inlined/phone-actions-forward-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-forward-1</title><path d=\"M23.613 2.7L20.428.222a1 1 0 00-1.614.79V2.4a7.262 7.262 0 00-5.75 7.093v2a1.25 1.25 0 102.5 0v-2a4.74 4.74 0 013.25-4.484v.961a.992.992 0 00.562.9 1 1 0 001.052-.11l3.185-2.477a1 1 0 000-1.579zM15.5 15.773a3.116 3.116 0 00-4.4 0l-.827.828a4.249 4.249 0 00-1.346 4.5 11.355 11.355 0 01-6.032-6.032 4.248 4.248 0 004.5-1.345l.83-.829a3.117 3.117 0 000-4.4l-3.59-3.583a2.233 2.233 0 00-3.649.74 13.313 13.313 0 0017.357 17.354 2.233 2.233 0 00.737-3.648z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});