define("ember-svg-jar/inlined/construction-pipe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>construction-pipe</title><path d=\"M21.726 19.5a.5.5 0 00.5-.537 8.072 8.072 0 00-8.039-7.463H8.25a2 2 0 010-4h1.5a.5.5 0 00.5-.5V2a.5.5 0 00-.5-.5h-1.5a8 8 0 000 16h5.937a2.061 2.061 0 012.013 1.61.5.5 0 00.487.39z\"/><path d=\"M11.75 1.5v6a1.5 1.5 0 003 0v-6a1.5 1.5 0 00-3 0zM22.25 21h-6a1.5 1.5 0 000 3h6a1.5 1.5 0 000-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});