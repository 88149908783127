define("ember-svg-jar/inlined/vectors-line-path", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vectors-line-path</title><path d=\"M24 2a2 2 0 00-2-2h-3a2 2 0 00-2 2v3a1.942 1.942 0 00.039.391.25.25 0 01-.069.225L5.616 16.97a.252.252 0 01-.226.069A1.925 1.925 0 005 17H2a2 2 0 00-2 2v3a2 2 0 002 2h3a2 2 0 002-2v-3a1.932 1.932 0 00-.039-.39.252.252 0 01.069-.226L18.384 7.03a.249.249 0 01.226-.068A2.007 2.007 0 0019 7h3a2 2 0 002-2zM5 21.75a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25zm17-17a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});