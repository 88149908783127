define("ember-svg-jar/inlined/video-game-controller-wifi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-controller-wifi</title><path d=\"M18.5 13a5.489 5.489 0 00-3.637 1.375.5.5 0 01-.331.125H9.468a.5.5 0 01-.33-.125 5.5 5.5 0 100 8.25.5.5 0 01.331-.125h5.064a.5.5 0 01.33.125A5.5 5.5 0 1018.5 13zm-11 6.25h-.75a.5.5 0 00-.5.5v.75a.75.75 0 01-1.5 0v-.75a.5.5 0 00-.5-.5H3.5a.75.75 0 010-1.5h.75a.5.5 0 00.5-.5v-.75a.75.75 0 011.5 0v.75a.5.5 0 00.5.5h.75a.75.75 0 010 1.5zm8.5.25a1 1 0 111-1 1 1 0 01-1 1zm2.75 2.5a1 1 0 111-1 1 1 0 01-1 1zm0-5a1 1 0 111-1 1 1 0 01-1 1zm2.75 2.5a1 1 0 111-1 1 1 0 01-1 1zM4.575 5.072a10.513 10.513 0 0114.85 0 1 1 0 001.414-1.414 12.516 12.516 0 00-17.678 0 1 1 0 001.414 1.414z\"/><path d=\"M18.01 7.9a1 1 0 000-1.414 8.5 8.5 0 00-12.02 0A1 1 0 007.4 7.9a6.5 6.5 0 019.192 0 1 1 0 001.418 0z\"/><path d=\"M8.818 9.315a1 1 0 001.414 1.414 2.5 2.5 0 013.536 0 1 1 0 001.414-1.414 4.506 4.506 0 00-6.364 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});