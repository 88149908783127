define("ember-svg-jar/inlined/professional-tool-google-drive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professional-tool-google-drive</title><path d=\"M14.92 1.37A.25.25 0 0014.7 1h-6a.5.5 0 00-.5.46 1.54 1.54 0 00-.22.3l-7.44 13a1.5 1.5 0 000 1.42l3 6 .07.12a.25.25 0 00.43 0zM5.47 22.63a.25.25 0 00.23.37h13.42a1.5 1.5 0 001.3-.76l3.21-5.62a.25.25 0 00-.22-.37H9.12a.5.5 0 00-.44.27zM23.42 14.75a.25.25 0 00.22-.37l-7-12.23a.25.25 0 00-.44 0l-3 5.8a.5.5 0 000 .46l3.18 6.07a.5.5 0 00.44.27z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});