define("ember-svg-jar/inlined/fireworks-stick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fireworks-stick</title><circle cx=\"4.583\" cy=\"7.082\" r=\"1\"/><circle cx=\"23\" cy=\"7.248\" r=\"1\"/><circle cx=\"11.083\" cy=\"19.498\" r=\"1\"/><path d=\"M14.53 9.468a1.793 1.793 0 00-2.475 0l-6.364 6.364a1.75 1.75 0 102.475 2.475l6.364-6.364a1.753 1.753 0 000-2.475zM6 4.5a5.444 5.444 0 014.168 2.555 1 1 0 001.664-1.109A7.441 7.441 0 006 2.5a1 1 0 000 2zM14.945 8.33a1 1 0 001.386-.277A5.455 5.455 0 0120.506 5.5a1 1 0 00-.006-2 7.445 7.445 0 00-5.833 3.446 1 1 0 00.278 1.384zM12.519 4.8a1 1 0 00.781 1.179 1.026 1.026 0 00.2.021 1 1 0 00.98-.8 9.316 9.316 0 00-.032-4.518.993.993 0 00-1.255-.631 1 1 0 00-.645 1.255 7.763 7.763 0 01-.029 3.494zM17.8 8.6a1 1 0 00.388 1.962 3.9 3.9 0 013.1.725 1 1 0 101.414-1.414C20.849 8.013 17.926 8.572 17.8 8.6zM17.371 11.57a1 1 0 00-.749 1.854 5.694 5.694 0 012.929 3.391 1 1 0 001.9-.633 7.711 7.711 0 00-4.08-4.612zM15 14.5a1 1 0 00-1 1V18a1 1 0 002 0v-2.5a1 1 0 00-1-1zM5.552 9.1a1 1 0 00.9 1.789A5.112 5.112 0 018.5 10.5a1 1 0 000-2 6.977 6.977 0 00-2.948.6zM4.883 18.761a.748.748 0 00-1.06 0l-3.53 3.53a1 1 0 001.414 1.414l3.53-3.53a.751.751 0 000-1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});