define("ember-svg-jar/inlined/notes-give-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-give-1</title><path d=\"M21.714 2.5h-.964a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-.964A2.286 2.286 0 008 4.786v8.978a.247.247 0 00.232.249 17.89 17.89 0 011.48.141.247.247 0 00.288-.246V4.786a.286.286 0 01.286-.286h.964a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h.964a.286.286 0 01.286.286v12.928a.286.286 0 01-.286.286H18.07a.247.247 0 00-.143.449 2.5 2.5 0 01.977 1.365.246.246 0 00.236.186h2.574A2.285 2.285 0 0024 17.714V4.786A2.285 2.285 0 0021.714 2.5zM16.5 19.5H8.139a.625.625 0 110-1.25H9.9a1 1 0 00.92-1.393A2.234 2.234 0 008.764 15.5H5.5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h9.764a2.238 2.238 0 002.236-2.236V20.5a1 1 0 00-1-1zM3 15.5H.5a.5.5 0 00-.5.5v7.5a.5.5 0 00.5.5H3a.5.5 0 00.5-.5V16a.5.5 0 00-.5-.5zm-1.5 7.25a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});