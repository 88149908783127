define("ember-svg-jar/inlined/video-game-breakout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-breakout</title><path d=\"M17.75 9.207a3.25 3.25 0 103.25 3.25 3.254 3.254 0 00-3.25-3.25zm0 5a1.75 1.75 0 111.75-1.75 1.752 1.752 0 01-1.75 1.75zM1 2h2.5a1 1 0 000-2H1a1 1 0 000 2zM7.5 2H10a1 1 0 000-2H7.5a1 1 0 000 2zM14 2h2.5a1 1 0 000-2H14a1 1 0 000 2zM20.5 2H23a1 1 0 000-2h-2.5a1 1 0 000 2zM4.5 4a1 1 0 00-1-1H1a1 1 0 000 2h2.5a1 1 0 001-1zM14 5h2.5a1 1 0 000-2H14a1 1 0 000 2zM23 3h-2.5a1 1 0 000 2H23a1 1 0 000-2zM23 6h-2.5a1 1 0 000 2H23a1 1 0 000-2zM10 21a1.494 1.494 0 001.061-.438l3.816-3.817a1 1 0 10-1.414-1.414L10 18.793 5.2 14a1 1 0 00-1.41 1.411l5.149 5.15A1.494 1.494 0 0010 21zM14.5 22H6a1 1 0 000 2h8.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});