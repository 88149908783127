define("ember-svg-jar/inlined/bridge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bridge</title><path d=\"M1.207 10.08a4.9 4.9 0 01.669.141.5.5 0 00.434-.078.955.955 0 00.382-.6l.4-2.186a4.8 4.8 0 01.383-1.191.5.5 0 01.921.039l2.031 5.442a.454.454 0 01-.427.608.972.972 0 00-.813.44.5.5 0 00-.047.465 4.889 4.889 0 01.219.679.5.5 0 00.291.343.887.887 0 00.35.073h12a.887.887 0 00.35-.073.5.5 0 00.291-.343 4.892 4.892 0 01.22-.679.5.5 0 00-.048-.465.972.972 0 00-.813-.44.453.453 0 01-.424-.612L19.607 6.2a.5.5 0 01.92-.039 4.8 4.8 0 01.384 1.191l.4 2.186a.988.988 0 00.355.594.5.5 0 00.452.091 4.886 4.886 0 01.678-.144.5.5 0 00.361-.24.947.947 0 00.122-.658L22.879 7A7.044 7.044 0 0016 1.255H8A7.042 7.042 0 001.122 7l-.4 2.187a.948.948 0 00.123.658.5.5 0 00.362.235zm12.541-6.611a.5.5 0 01.411-.214H16a4.923 4.923 0 011.914.391.5.5 0 01.274.636l-1.717 4.6a.5.5 0 01-.937 0L13.69 3.929a.5.5 0 01.058-.46zM12 6.056a.5.5 0 01.469.326l1.934 5.2a.5.5 0 01-.468.675h-3.869a.5.5 0 01-.466-.677l1.935-5.2A.5.5 0 0112 6.056zm-5.914-2.41A4.926 4.926 0 018 3.255h1.842a.5.5 0 01.468.674L8.467 8.883a.5.5 0 01-.937 0l-1.717-4.6a.5.5 0 01.273-.637zM22.941 20.7a3.414 3.414 0 01-2.655-1.575 1.043 1.043 0 00-1.719.028 3.364 3.364 0 01-2.755 1.556 2.8 2.8 0 01-2.41-1.487 1.044 1.044 0 00-1.718-.155 4.312 4.312 0 01-3.111 1.642 3.567 3.567 0 01-2.767-1.585 1.04 1.04 0 00-1.637-.09c-.014.017-1.463 1.784-3.117 1.706a1 1 0 00-.105 2c.075 0 .148.006.222.006a5.6 5.6 0 003.408-1.338.5.5 0 01.636.011 5.138 5.138 0 003.36 1.293 5.742 5.742 0 003.482-1.236.5.5 0 01.639.028 4.539 4.539 0 003.118 1.208 5.094 5.094 0 003.307-1.212.5.5 0 01.643 0 4.663 4.663 0 003.292 1.2 1 1 0 10-.113-2z\"/><path d=\"M23.5 11.005a3.989 3.989 0 00-3.262 6.3 2.487 2.487 0 011.322 1.029 3.123 3.123 0 00.22.266 3.95 3.95 0 001.72.4.5.5 0 00.5-.5v-7a.5.5 0 00-.5-.495zM.5 19.005a3.962 3.962 0 002.481-.887l.01-.011a2.494 2.494 0 01.488-.459A3.966 3.966 0 004.5 15.005a4 4 0 00-4-4 .5.5 0 00-.5.5v7a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});