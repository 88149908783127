define("ember-svg-jar/inlined/concert-microphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>concert-microphone</title><path d=\"M22.5 7.937a.5.5 0 01.375-.484A1.5 1.5 0 0024 6V2a2 2 0 00-2-2H2a2 2 0 00-2 2v4a1.5 1.5 0 001.125 1.453.5.5 0 01.375.484V21.5a.5.5 0 01-.5.5 1 1 0 000 2h22a1 1 0 000-2 .5.5 0 01-.5-.5zM2.5 5.5A.5.5 0 012 5V3.462a.25.25 0 01.44-.162l1.532 1.787a.25.25 0 01-.189.413zm1.814-3.087A.25.25 0 014.5 2h4.642a.25.25 0 01.172.431l-2.36 2.253a.5.5 0 01-.37.137.5.5 0 01-.355-.174zm7.341.549a.5.5 0 01.69 0l2.207 2.107a.25.25 0 01-.172.431H9.62a.25.25 0 01-.172-.431zm3.031-.531A.25.25 0 0114.858 2H19.5a.25.25 0 01.189.413l-1.918 2.234a.5.5 0 01-.355.174.5.5 0 01-.37-.137zm6.874.869a.25.25 0 01.44.163V5a.5.5 0 01-.5.5h-1.283a.25.25 0 01-.189-.413zm-9.38 17.02a.5.5 0 01.64 0l1.487 1.239a.25.25 0 01-.16.442h-3.294a.25.25 0 01-.16-.442zM16.646 22a.25.25 0 01-.229-.35 1 1 0 00-.277-1.169l-2.46-2.05a.5.5 0 01-.18-.384v-2.571a.25.25 0 01.3-.245.96.96 0 00.2.019 1 1 0 00.448-1.894l-2.421-1.211a.5.5 0 01-.277-.439 2.249 2.249 0 10-1.313 2.09.5.5 0 01.432.007l.356.177a.5.5 0 01.276.447v3.62a.5.5 0 01-.18.384l-2.46 2.05a1 1 0 00-.277 1.169.25.25 0 01-.229.35H4a.5.5 0 01-.5-.5V8a.5.5 0 01.5-.5h16a.5.5 0 01.5.5v13.5a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});