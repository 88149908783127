define("ember-svg-jar/inlined/vest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vest</title><path d=\"M20.8 8.009h-.009C20.184 7.849 20 7.338 20 5.82V2.569a.5.5 0 00-.3-.458L15.019.065a1.006 1.006 0 00-.634-.027A7.008 7.008 0 019.581.03a1.016 1.016 0 00-.641.052L4.3 2.111a.5.5 0 00-.3.458V5.82c0 1.518-.184 2.029-.8 2.189a.981.981 0 00-.7.961V21.8a1 1 0 00.586.911l1.289.586a8 8 0 006.244.142 1 1 0 00.631-.929v-11.7a1 1 0 00-.133-.5C9.9 8.189 9.761 5.3 9.514 2.353a.249.249 0 01.313-.262 10.379 10.379 0 002.173.38 10.4 10.4 0 002.174-.38.249.249 0 01.312.262c-.247 2.948-.383 5.836-1.6 7.959a1 1 0 00-.133.5V22.5a1 1 0 00.63.929 8 8 0 006.249-.142l1.285-.585a1 1 0 00.583-.902V8.97a.981.981 0 00-.7-.961zM7.5 19.112h-2a1 1 0 010-2h2a1 1 0 010 2zm12-1a1 1 0 01-1 1h-2a1 1 0 010-2h2a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});