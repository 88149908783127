define("ember-svg-jar/inlined/multiple-actions-monitor.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-monitor</title><path d=\"M14.471 8.4l-.486-.172a1.8 1.8 0 01-.055-1.172 4.647 4.647 0 001.216-3.569A3.22 3.22 0 0012 0a2.994 2.994 0 00-1.342.314.25.25 0 00-.1.363 5 5 0 01.842 2.808 6.272 6.272 0 01-.922 3.625.249.249 0 00.121.371 8.525 8.525 0 013.491 1.933.251.251 0 00.189.086H16a.25.25 0 00.1-.481 47.396 47.396 0 00-1.629-.619z\"/><path d=\"M9.5 13a3.5 3.5 0 012.208-3.251.25.25 0 00.013-.459c-.621-.29-1.382-.572-2.25-.89l-.486-.172a1.8 1.8 0 01-.055-1.174 4.647 4.647 0 001.216-3.569A3.22 3.22 0 007 0a3.22 3.22 0 00-3.146 3.485 4.605 4.605 0 001.193 3.538 1.877 1.877 0 010 1.185l-.515.19c-2.01.739-3.46 1.272-3.914 2.179A7.542 7.542 0 000 13.5a.5.5 0 00.5.5h8.75a.25.25 0 00.25-.25zM20 22h-1.25a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25H22a2 2 0 002-2v-6a2 2 0 00-2-2h-9a2 2 0 00-2 2v6a2 2 0 002 2h3.25a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H15a.987.987 0 00-.847.5.977.977 0 00-.153.5 1 1 0 001 1h5a1 1 0 001-1 .977.977 0 00-.153-.5A.987.987 0 0020 22zm-7-8.75a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v5.5a.249.249 0 01-.25.25h-8.5a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});