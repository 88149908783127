define("ember-svg-jar/inlined/religion-hands-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-hands-1</title><path d=\"M24 6.938a.5.5 0 00-.33-.47 1.644 1.644 0 00-1.457.262c-1.1.774-1.677 2.777-1.711 5.96a.25.25 0 01-.073.174l-.908.907a.25.25 0 01-.42-.233l.1-.452a1.647 1.647 0 00-.788-2.025 1.461 1.461 0 00-1.806.7 23.33 23.33 0 00-1.59 4.089.488.488 0 00-.021.142V19.5a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-.218a.252.252 0 01.074-.178c.653-.647 2.578-2.554 3.484-3.456A1.508 1.508 0 0024 14.585zM3.583 21a.548.548 0 00-.583.5v2a.548.548 0 00.583.5h5.834a.548.548 0 00.583-.5v-2a.548.548 0 00-.583-.5zM14.583 21a.548.548 0 00-.583.5v2a.548.548 0 00.583.5h5.834a.548.548 0 00.583-.5v-2a.548.548 0 00-.583-.5zM8.5 20a.5.5 0 00.5-.5v-3.508a.488.488 0 00-.021-.142 23.33 23.33 0 00-1.59-4.089 1.46 1.46 0 00-1.806-.7 1.646 1.646 0 00-.789 2.02l.105.458a.25.25 0 01-.42.232l-.908-.907a.25.25 0 01-.071-.174c-.036-3.183-.612-5.19-1.713-5.96A1.644 1.644 0 00.33 6.468a.5.5 0 00-.33.47v7.605a1.5 1.5 0 00.494 1.113c.976.883 2.819 2.8 3.437 3.44a.254.254 0 01.069.173v.231a.5.5 0 00.5.5zM16.5 5.5v-2A.5.5 0 0016 3h-2a.5.5 0 01-.5-.5v-2A.5.5 0 0013 0h-2a.5.5 0 00-.5.5v2a.5.5 0 01-.5.5H8a.5.5 0 00-.5.5v2A.5.5 0 008 6h2a.5.5 0 01.5.5v6a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-6A.5.5 0 0114 6h2a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});