define("ember-svg-jar/inlined/reception-bell-call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>reception-bell-call</title><path d=\"M8.379 17.142A.469.469 0 018 16.683v-.433a.5.5 0 01.5-.5h1a1 1 0 000-2h-5a1 1 0 000 2h1a.5.5 0 01.5.5v.433a.467.467 0 01-.378.459 7.068 7.068 0 00-5.608 5.693A1 1 0 001 24h12a1 1 0 00.988-1.165 7.071 7.071 0 00-5.609-5.693zM23.075.093A.993.993 0 0022 1.015l-.517 6.839a1.276 1.276 0 01-.586.979.262.262 0 01-.306-.06c-.482-.376-.992-.972-.959-3.291a1 1 0 00-1.8-.62l-5.011 6.6a1 1 0 01-.681.384 1.014 1.014 0 01-.749-.209 1.039 1.039 0 01-.164-1.468l2.676-3.638a1 1 0 00.03-1.141L11.514 1.7a.88.88 0 01-.142-.6 1 1 0 00-.9-1.09.984.984 0 00-1.09.9 2.856 2.856 0 00.47 1.907L11.7 5.62a.5.5 0 01-.015.57L9.64 8.964a3.035 3.035 0 00.524 4.251 2.988 2.988 0 001.859.643 2.887 2.887 0 00.36-.022 3.013 3.013 0 002.026-1.146l2.936-3.869a.5.5 0 01.849.086 3.961 3.961 0 001.167 1.441 2.239 2.239 0 002.341.318 3.269 3.269 0 001.778-2.652L24 1.165a1 1 0 00-.925-1.072z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});