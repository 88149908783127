define("ember-svg-jar/inlined/file-code-heart-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-heart-1</title><path d=\"M14.715 6.451a.75.75 0 00-1.078-1.043l-.018.019-2.667 2.857a.749.749 0 000 1.023l2.667 2.857a.749.749 0 001.06.036l.019-.017a.753.753 0 00.018-1.043l-2.03-2.174a.249.249 0 010-.341zM16.286 11.14a.75.75 0 001.078 1.043l.018-.019 2.667-2.857a.749.749 0 000-1.023l-2.667-2.857a.749.749 0 00-1.06-.036.731.731 0 00-.037 1.06l2.03 2.174a.249.249 0 010 .341z\"/><path d=\"M23.415 3L21 .587A2 2 0 0019.587 0H8a2 2 0 00-2 2v9.535a.224.224 0 00.122.213c.073.04.146.079.217.122a.633.633 0 00.377-.043 5.019 5.019 0 011.117-.527.243.243 0 00.167-.229V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.354.146l2.121 2.121a.5.5 0 01.146.355V18a.5.5 0 01-.5.5h-7.53a.251.251 0 00-.207.111c-.238.334-.845 1.027-1.244 1.474a.249.249 0 00.189.414H22a2 2 0 002-2V4.415A2 2 0 0023.415 3z\"/><path d=\"M12.629 14.45a3.508 3.508 0 00-3.045-1.95 3.665 3.665 0 00-2.695 1.126l-.212.212a.25.25 0 01-.353 0l-.212-.212A3.665 3.665 0 003.417 12.5a3.528 3.528 0 00-2.391 6.012l5.113 5.334a.5.5 0 00.723 0l5.106-5.327a3.524 3.524 0 00.661-4.069z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});