define("ember-svg-jar/inlined/shield-wall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shield-wall</title><path d=\"M24 1.953A1.959 1.959 0 0022.043.006H1.959A1.958 1.958 0 00.012 1.965L0 9.306a15.146 15.146 0 0011.861 14.669 1 1 0 00.4 0A15.145 15.145 0 0023.988 9.2zm-5.5 4.053v.5a.5.5 0 01-.5.5h-4.75a.5.5 0 01-.5-.5v-1.5a.5.5 0 01.5-.5H17a1.5 1.5 0 011.5 1.5zm-8.5 5a.5.5 0 01-.5-.5v-1.5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5zm-4 0a.5.5 0 01-.5-.5v-1.5a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5zm10-2a.5.5 0 01.5-.5H18a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5zm-9-4.5h3.75a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5v-.5a1.5 1.5 0 011.5-1.5zm-1.5 9v-.5a.5.5 0 01.5-.5h4.75a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5H7a1.5 1.5 0 01-1.5-1.5zm11.5 1.5h-3.75a.5.5 0 01-.5-.5v-1.5a.5.5 0 01.5-.5H18a.5.5 0 01.5.5v.5a1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});