define("ember-svg-jar/inlined/road-sign-turn-left-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-turn-left-3</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm3.25 16.5a.75.75 0 01-1.5 0v-4a1.252 1.252 0 00-1.25-1.25h-1a.25.25 0 00-.25.25v1a.75.75 0 01-1.28.53l-2-2a.751.751 0 010-1.06l2-2a.75.75 0 011.28.53v1a.25.25 0 00.25.25h1a2.752 2.752 0 012.75 2.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});