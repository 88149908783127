define("ember-svg-jar/inlined/landmark-bridge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-bridge</title><path d=\"M23 12.375a1 1 0 00-1 1v.25a.25.25 0 01-.25.25h-.25a.25.25 0 01-.25-.25v-.25a1 1 0 00-2 0v2.25a.25.25 0 01-.25.25h-1.25a.25.25 0 00-.25.25v7.25a2.032 2.032 0 01-.012.221.251.251 0 00.248.279H23.5a.5.5 0 00.5-.5v-10a1 1 0 00-1-1zM6.5 16.125a.25.25 0 00-.25-.25H5a.25.25 0 01-.25-.25v-2.25a1 1 0 00-2 0v.25a.25.25 0 01-.25.25h-.25a.25.25 0 01-.25-.25v-.25a1 1 0 00-2 0v10a.5.5 0 00.5.5h5.764a.251.251 0 00.248-.279 2.032 2.032 0 01-.012-.221zM9.254 9.873c1.128-.016 1.921-.108 2.6.527a.219.219 0 00.3 0c.675-.635 1.468-.543 2.6-.527a.25.25 0 00.246-.25V4.875a.5.5 0 00-1 0v.2a.25.25 0 01-.427.177l-1.221-1.225a.484.484 0 00-.7 0l-1.225 1.221A.25.25 0 0110 5.071v-.2a.5.5 0 00-1 0v4.752a.25.25 0 00.254.25zM12 6.375a1 1 0 11-1 1 1 1 0 011-1zM10.47 2.345a.75.75 0 101.06 1.06l.293-.293a.252.252 0 01.354 0l.293.293a.75.75 0 001.06-1.06l-.293-.293a.252.252 0 010-.354l.293-.293a.75.75 0 00-1.06-1.06l-.293.293a.252.252 0 01-.354 0L11.53.345a.75.75 0 00-1.06 1.06l.293.293a.252.252 0 010 .354zM15.5 11.375h-2a.5.5 0 00-.5.5v.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-.75a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v11.5a.5.5 0 00.5.5h1.75a.25.25 0 00.25-.25v-2.75a1.5 1.5 0 013 0v2.75a.25.25 0 00.25.25h1.75a.5.5 0 00.5-.5v-11.5a.5.5 0 00-.5-.5zm-1.646 5.646a.5.5 0 01-.708 0l-.969-.969a.25.25 0 00-.354 0l-.969.969a.5.5 0 01-.708-.707l1.147-1.146a1 1 0 011.414 0l1.147 1.146a.5.5 0 010 .707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});