define("ember-svg-jar/inlined/wolf-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wolf-2</title><path d=\"M23.989 6.452A.512.512 0 0023.463 6h-5.357a.5.5 0 01-.447-.276l-.671-1.342A2.487 2.487 0 0014.752 3h-1.264a.5.5 0 01-.5-.5v-2a.5.5 0 00-.5-.5C8.834 0 7.511 1.934 7.057 3.655-.083 7.243-.008 18.187.013 22.89v.609a.5.5 0 00.5.5h14.973a.5.5 0 00.3-.9c-1.879-1.409-3.8-3.29-3.8-6.595 0-3.406.972-4.5 4-4.5h4.475a3.13 3.13 0 002.36-.993c1.19-1.244 1.171-4.424 1.168-4.559zm-9 .3a1.25 1.25 0 11-1.25-1.25 1.249 1.249 0 011.249 1.249z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});