define("ember-svg-jar/inlined/cd-playing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cd-playing</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM2.253 12a.248.248 0 01-.179-.076.251.251 0 01-.074-.18 9.969 9.969 0 012.75-6.628.251.251 0 01.358 0l4.071 4.062a.249.249 0 00.342.011 3.748 3.748 0 016.222 2.576.25.25 0 00.249.235h5.755a.248.248 0 01.179.076.251.251 0 01.071.18 9.969 9.969 0 01-2.75 6.628.251.251 0 01-.358 0l-4.067-4.066a.249.249 0 00-.342-.011 3.749 3.749 0 01-6.223-2.577.249.249 0 00-.249-.23z\"/><circle cx=\"12\" cy=\"12\" r=\"2.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});