define("ember-svg-jar/inlined/messages-bubble-square-quotation-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-quotation-alternate</title><path d=\"M9.25 10.249a2.275 2.275 0 00.25-.025v.293a1 1 0 01-1 1 1 1 0 000 2 3 3 0 003-3V8a2.25 2.25 0 10-2.25 2.25zM15.25 10.249a2.275 2.275 0 00.25-.025v.293a1 1 0 01-1 1 1 1 0 000 2 3 3 0 003-3V8a2.25 2.25 0 10-2.25 2.25z\"/><path d=\"M24 3a2 2 0 00-2-2H2a2 2 0 00-2 2v13.552A1.953 1.953 0 002 18.5h2.5V22a1 1 0 001.569.822l6.243-4.322H22a2 2 0 002-2zm-2 13.25a.25.25 0 01-.25.25H12a1 1 0 00-.569.178L6.5 20.09V17.5a1 1 0 00-1-1H2.25a.25.25 0 01-.25-.25v-13A.25.25 0 012.25 3h19.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});