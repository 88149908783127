define("ember-svg-jar/inlined/human-resources-man-previous-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-man-previous-1</title><path d=\"M23.992 11.609A10.707 10.707 0 0012.89 1.294a10.843 10.843 0 00-9.732 7.161.25.25 0 01-.254.163L.538 8.436a.519.519 0 00-.469.246.5.5 0 00.015.53l3.069 4.6a.5.5 0 00.383.221h.033a.5.5 0 00.376-.171l3.579-4.087a.5.5 0 00-.338-.828l-1.665-.128a.25.25 0 01-.208-.353 8.81 8.81 0 017.651-5.173 8.712 8.712 0 019.03 8.39 8.922 8.922 0 01-1.482 5.251.25.25 0 01-.332.079 12.721 12.721 0 00-4.4-1.48.031.031 0 01-.027-.031v-.016a.03.03 0 01.015-.026 4.252 4.252 0 001.985-3.6V8.615a1 1 0 00-.293-.707.244.244 0 01-.063-.107l-.008-.027a3.121 3.121 0 00-2.67-2.04c-2.25-.449-3.267.417-3.679 1a.25.25 0 01-.173.1 1.446 1.446 0 00-1.167.853.858.858 0 00-.037.082.251.251 0 01-.067.091 1 1 0 00-.344.755v3.25a4.253 4.253 0 001.971 3.586.061.061 0 01.029.052v.02l-.006.007A12.654 12.654 0 006.7 17.082a.254.254 0 01-.142.035 1 1 0 00-.911 1.522 9.5 9.5 0 007.993 4.073 10.592 10.592 0 0010.352-11.103zM13.863 9.883a.25.25 0 01.28.029 3.2 3.2 0 001.408.638.249.249 0 01.2.244v1.071a2.25 2.25 0 11-4.5 0v-1.42a.25.25 0 01.272-.249 4.437 4.437 0 002.34-.313z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});