define("ember-svg-jar/inlined/wild-bird-hunt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wild-bird-hunt</title><path d=\"M8.642 8.876a.247.247 0 00.241-.161C9.721 6.5 9.73 5.551 10.2 4.578a5.3 5.3 0 00-.677-1.5C7.514.035 2.989 0 2.8 0a.5.5 0 00-.5.418 7.2 7.2 0 002.143 5.936 5.07 5.07 0 011.122 2.518.25.25 0 00.286.2 14.167 14.167 0 012.791-.196z\"/><path d=\"M11.763 21.615a7.212 7.212 0 003.712-2.908l.017.011a3.437 3.437 0 00.3-.968.5.5 0 011 0 4.394 4.394 0 01-.448 1.5c2.946 1.818 3.356 1.769 3.524 1.75 3.148-.45 3.617-3.827 3.426-5.55a.5.5 0 00-.547-.45l-4.666.466a8.712 8.712 0 00-2.957-3.007.25.25 0 01.014-.428c5.516-3.1 7.552-10.567 7.642-10.906a.5.5 0 00-.1-.453.485.485 0 00-.435-.172c-.4.042-9.861 1.086-10.933 5.376A26.709 26.709 0 019.854 10.3a.251.251 0 01-.256.146 11.062 11.062 0 00-6.579 1.138A3.4 3.4 0 001.3 14.732c-.973.805-.545 2.668-.488 2.889a.5.5 0 00.708.326 17.433 17.433 0 018.005-1.572 1.489 1.489 0 011.232 1.93l-.606 1.969a3.489 3.489 0 00-3.431.426 1 1 0 101.2 1.6 1.631 1.631 0 01.9-.3 1.5 1.5 0 011.469 1.2.989.989 0 001.961-.172 2.9 2.9 0 00-.487-1.413zM4.3 15a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});