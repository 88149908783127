define("ember-svg-jar/inlined/single-neutral-actions-share-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-share-2</title><path d=\"M0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229H9.3a.25.25 0 00.248-.222 4.216 4.216 0 011.373-2.668.25.25 0 00.079-.186v-.924a5.5 5.5 0 00-11 0z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M11 18a2.741 2.741 0 004.637 1.989.253.253 0 01.266-.051l2.448.98a.251.251 0 01.158.224 2.42 2.42 0 00.183.867 2.761 2.761 0 10.671-3 .253.253 0 01-.266.051l-2.445-.978a.253.253 0 01-.157-.219c0-.041-.007-.1-.019-.178a.22.22 0 01.1-.219l2.857-1.714a.251.251 0 01.271.009 2.708 2.708 0 001.542.487A2.75 2.75 0 1018.5 13.5a2.4 2.4 0 00.021.295.247.247 0 01-.119.247l-2.839 1.7a.249.249 0 01-.27-.009 2.714 2.714 0 00-1.543-.487A2.752 2.752 0 0011 18zM0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229H9.3a.25.25 0 00.248-.222 4.216 4.216 0 011.373-2.668.25.25 0 00.079-.186v-.924a5.5 5.5 0 00-11 0z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M11 18a2.741 2.741 0 004.637 1.989.253.253 0 01.266-.051l2.448.98a.251.251 0 01.158.224 2.42 2.42 0 00.183.867 2.761 2.761 0 10.671-3 .253.253 0 01-.266.051l-2.445-.978a.253.253 0 01-.157-.219c0-.041-.007-.1-.019-.178a.22.22 0 01.1-.219l2.857-1.714a.251.251 0 01.271.009 2.708 2.708 0 001.542.487A2.75 2.75 0 1018.5 13.5a2.4 2.4 0 00.021.295.247.247 0 01-.119.247l-2.839 1.7a.249.249 0 01-.27-.009 2.714 2.714 0 00-1.543-.487A2.752 2.752 0 0011 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});