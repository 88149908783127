define("ember-svg-jar/inlined/couple-woman-woman-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>couple-woman-woman-1</title><path d=\"M17.5 3.231A3.1 3.1 0 0014.854.018a2.821 2.821 0 00-2.495 1.021.47.47 0 01-.717 0A2.817 2.817 0 009.146.018 3.1 3.1 0 006.5 3.231c0 2.427 3.423 5.312 4.9 6.44a.981.981 0 001.208 0c1.468-1.127 4.892-4.013 4.892-6.44zM22.526 19.854l-1.192-3.921A2.944 2.944 0 0020 14.236a.25.25 0 010-.433 3.042 3.042 0 10-3-.003.25.25 0 010 .433 2.95 2.95 0 00-1.334 1.7l-1.192 3.921a.5.5 0 00.079.447.594.594 0 00.407.2l.867-.012a.5.5 0 01.5.45l.261 2.612a.5.5 0 00.5.45h2.822a.5.5 0 00.5-.45l.261-2.612a.5.5 0 01.5-.45l.868.012a.605.605 0 00.406-.2.5.5 0 00.081-.447zM18.5 12.7a1.543 1.543 0 01-1.447-1.011.248.248 0 01.152-.322 3.056 3.056 0 001.12-.7.251.251 0 01.349 0 3.06 3.06 0 001.121.7.251.251 0 01.152.322A1.543 1.543 0 0118.5 12.7zM8.335 15.933A2.95 2.95 0 007 14.236a.25.25 0 010-.436 3.042 3.042 0 10-3 0 .25.25 0 010 .433 2.944 2.944 0 00-1.333 1.7l-1.193 3.921a.5.5 0 00.079.447.605.605 0 00.406.2l.868-.012a.5.5 0 01.5.45l.261 2.612a.5.5 0 00.5.45h2.823a.5.5 0 00.5-.45l.261-2.612a.5.5 0 01.5-.45l.867.012a.594.594 0 00.407-.2.5.5 0 00.079-.447zM5.5 12.7a1.543 1.543 0 01-1.447-1.01.251.251 0 01.152-.322 3.06 3.06 0 001.121-.7.251.251 0 01.349 0 3.056 3.056 0 001.12.7.248.248 0 01.152.322A1.543 1.543 0 015.5 12.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});