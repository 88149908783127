define("ember-svg-jar/inlined/notes-flip-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-flip-1</title><path d=\"M22 4.75a2 2 0 00-2-2h-.75A.25.25 0 0119 2.5V1.25a1 1 0 00-2 0v4.158a1 1 0 102 0V5a.25.25 0 01.25-.25h.25a.5.5 0 01.5.5v8.5a.5.5 0 01-.5.5H17a1 1 0 00-1 1v2.5a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5V5.25a.5.5 0 01.5-.5h.25A.25.25 0 005 4.5V3a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v13.5a2 2 0 002 2h12.5a1 1 0 00.707-.293l4.5-4.5A1 1 0 0022 14.75z\"/><path d=\"M12.5 6.408a1 1 0 001-1V5a.25.25 0 01.25-.25h2A.25.25 0 0016 4.5V3a.25.25 0 00-.25-.25h-2a.25.25 0 01-.25-.25V1.25a1 1 0 00-2 0v4.158a1 1 0 001 1zM7 6.408a1 1 0 001-1V5a.25.25 0 01.25-.25h2a.25.25 0 00.25-.25V3a.25.25 0 00-.25-.25h-2A.25.25 0 018 2.5V1.25a1 1 0 00-2 0v4.158a1 1 0 001 1zM19 22.75a1 1 0 00-1-1H4a1 1 0 000 2h14a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});