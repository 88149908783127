define("ember-svg-jar/inlined/vegetables-carrot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vegetables-carrot</title><path d=\"M23.928 6.009a1 1 0 00-1.3-.556l-2.952 1.182a.5.5 0 00-.207.773q.31.4.6.808a.5.5 0 00.6.179l2.71-1.086a1 1 0 00.549-1.3zM22.136 1.864a1 1 0 00-1.414 0l-2.964 2.964a.5.5 0 00.01.718 18.343 18.343 0 01.687.686.5.5 0 00.717.01l2.964-2.964a1 1 0 000-1.414zM16.592 4.531a.5.5 0 00.773-.207l1.183-2.952a1 1 0 00-1.857-.744l-1.085 2.71a.5.5 0 00.178.6q.416.282.808.593zM19.171 9.593a15.5 15.5 0 00-4.764-4.764 1.537 1.537 0 00-2.067.355l-.28.386a.5.5 0 00.051.647l2.336 2.336a.75.75 0 11-1.06 1.06l-2.059-2.054a.5.5 0 00-.758.06L.255 21.814a1.363 1.363 0 00.16 1.771 1.364 1.364 0 001.771.16l6.087-4.423a.5.5 0 00.06-.758l-.918-.918a.75.75 0 011.06-1.06l1.2 1.2a.5.5 0 00.648.051l1.877-1.371a.5.5 0 00.205-.365.5.5 0 00-.145-.393l-2.093-2.093a.75.75 0 011.06-1.06l2.373 2.373a.5.5 0 00.648.051l4.565-3.318a1.537 1.537 0 00.358-2.068z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});