define("ember-svg-jar/inlined/smiley-indiferent-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-indiferent-1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM6.5 8h3a1 1 0 010 2h-3a1 1 0 010-2zm.5 7h10a1 1 0 010 2H7a1 1 0 010-2zm10.5-5h-3a1 1 0 010-2h3a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});