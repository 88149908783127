define("ember-svg-jar/inlined/bendable-phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bendable-phone</title><path d=\"M16.5 2h-4.193a.5.5 0 00-.382.823 8.554 8.554 0 012.014 5.546L14 9.5v11a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5v-11A7.508 7.508 0 0016.5 2zM20 18.5a1 1 0 11-1-1 1 1 0 011 1zm1-10.249V14.5a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V8.285a9.1 9.1 0 00-.552-3.126.5.5 0 01.588-.657c1.849.449 3.956 1.128 3.964 3.749zM6.73 2.055a.506.506 0 00-.129 0A7.511 7.511 0 000 9.5 1.5 1.5 0 001.5 11h7A1.5 1.5 0 0010 9.5a6.515 6.515 0 01.78-3.091 6.712 6.712 0 01.375-.609.5.5 0 00.031-.517A6.022 6.022 0 006.73 2.055z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});