define("ember-svg-jar/inlined/visibility-cloud-low", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>visibility-cloud-low</title><path d=\"M17 11.5A6.5 6.5 0 004.543 8.9a.249.249 0 01-.2.147A5 5 0 005 19h8.5a4.505 4.505 0 004.5-4.5 4.461 4.461 0 00-.948-2.763.254.254 0 01-.052-.157zm-1 3a2.5 2.5 0 01-2.5 2.5H5a3 3 0 010-6h.154a.989.989 0 001.013-.727A4.5 4.5 0 0115 11.5c0 .145-.007.289-.021.43a1 1 0 00.289.8A2.482 2.482 0 0116 14.5zM17.5 7h3a1 1 0 000-2h-3a1 1 0 000 2zM23 10h-3.5a1 1 0 000 2H23a1 1 0 000-2zM22 16h-2a1 1 0 000 2h2a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});