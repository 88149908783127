define("ember-svg-jar/inlined/educative-toys-alphabet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>educative-toys-alphabet</title><path d=\"M9 11.5h6a2.752 2.752 0 002.75-2.75v-6A2.752 2.752 0 0015 0H9a2.752 2.752 0 00-2.75 2.75v6A2.752 2.752 0 009 11.5zm.75-7.25a2.25 2.25 0 014.5 0v4.5a.75.75 0 01-1.5 0V7a.5.5 0 00-.5-.5h-.5a.5.5 0 00-.5.5v1.75a.75.75 0 01-1.5 0z\"/><path d=\"M11.75 5h.5a.5.5 0 00.5-.5v-.25a.75.75 0 00-1.5 0v.25a.5.5 0 00.5.5zM8.75 12.5h-6A2.752 2.752 0 000 15.25v6A2.752 2.752 0 002.75 24h6a2.752 2.752 0 002.75-2.75v-6a2.752 2.752 0 00-2.75-2.75zm-1.115 6.024A2.248 2.248 0 015.75 22h-1.5a.75.75 0 01-.75-.75v-6a.75.75 0 01.75-.75h1.5a2.248 2.248 0 011.885 3.476.5.5 0 000 .548z\"/><path d=\"M5.75 19H5.5a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h.25a.75.75 0 000-1.5zM5.75 16H5.5a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h.25a.75.75 0 000-1.5zM21.25 12.5h-6a2.752 2.752 0 00-2.75 2.75v6A2.752 2.752 0 0015.25 24h6A2.752 2.752 0 0024 21.25v-6a2.752 2.752 0 00-2.75-2.75zm-2 8a.75.75 0 010 1.5 3.75 3.75 0 010-7.5.75.75 0 010 1.5 2.25 2.25 0 000 4.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});