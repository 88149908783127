define("ember-svg-jar/inlined/baby-care-cot-mobile-crib", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baby-care-cot-mobile-crib</title><path d=\"M23 7a1 1 0 00-1 1v3a.5.5 0 01-.5.5h-19A.5.5 0 012 11V8a1 1 0 00-2 0v15a1 1 0 002 0v-1a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v1a1 1 0 002 0V8a1 1 0 00-1-1zM3.5 19a.5.5 0 01-.5.5h-.5A.5.5 0 012 19v-5a.5.5 0 01.5-.5H3a.5.5 0 01.5.5zm5 0a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm5 0a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm5 0a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm3.5 0a.5.5 0 01-.5.5H21a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5z\"/><path d=\"M7.8 6.4a2 2 0 102.4 0A.5.5 0 0110 6V5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v1a.5.5 0 01-.2.4 2 2 0 102.4 0A.5.5 0 0115 6V5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v1a.5.5 0 01-.2.4 2 2 0 102.4 0A.5.5 0 0120 6V4.915a.5.5 0 01.333-.472A1 1 0 0020 2.5h-4.5A.5.5 0 0115 2V1a1 1 0 00-2 0v1a.5.5 0 01-.5.5H8a1 1 0 00-.334 1.943.5.5 0 01.334.472V6a.5.5 0 01-.2.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});