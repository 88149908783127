define("ember-svg-jar/inlined/pet-tracking-dog-chip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pet-tracking-dog-chip</title><path d=\"M15.032 9.626a.5.5 0 00.351-.848L13.23 6.624a.5.5 0 00-.492-.127 3.334 3.334 0 01-.916.129h-6.79a3.239 3.239 0 00-.744.093.5.5 0 01-.422-.091c-2.2-1.7-1.844-5.353-1.844-5.392a1 1 0 10-1.98-.22C.013 1.2-.387 5.18 2.027 7.663a.5.5 0 01.067.612 3.438 3.438 0 00-.532 1.841l-.03 1.89-1.45 2.9a.545.545 0 00-.05.22v2.75a1.75 1.75 0 003.5 0v-1.64a1.559 1.559 0 01.25-.84l1.07-1.6a1.5 1.5 0 011.25-.67h4.438a.5.5 0 00.305-.1 1.936 1.936 0 011.187-.4 1.5 1.5 0 001.5-1.5 1.5 1.5 0 011.5-1.5zM24 5.666a2.051 2.051 0 00-2.05-2.04 1.039 1.039 0 01-.93-.58A2.557 2.557 0 0019 1.641a.5.5 0 01-.364-.221L17.92.346a.5.5 0 00-.87.06l-2 4-.015.032a3.591 3.591 0 01-.47.815.5.5 0 00.045.657l3.564 3.57a.5.5 0 00.353.146h.306a.5.5 0 00.374-.168 2.463 2.463 0 011.843-.832A2.964 2.964 0 0024 5.666zm-4.75-.54a.75.75 0 11.75-.75.755.755 0 01-.75.75z\"/><rect x=\"13.756\" y=\"13.626\" width=\"7\" height=\"7\" rx=\"1\" ry=\"1\"/><path d=\"M14.5 11.126v1a.75.75 0 001.5 0v-1a.75.75 0 10-1.5 0zM20 12.126v-1a.75.75 0 10-1.5 0v1a.75.75 0 001.5 0zM23.25 14.376h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM23.25 18.376h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM19.253 21.376a.75.75 0 00-.75.75v1a.75.75 0 101.5 0v-1a.75.75 0 00-.75-.75zM15.253 21.376a.75.75 0 00-.75.75v1a.75.75 0 101.5 0v-1a.75.75 0 00-.75-.75zM12.255 18.376h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM12.255 14.376h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});