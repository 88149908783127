define("ember-svg-jar/inlined/legal-certificate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>legal-certificate</title><path d=\"M2.03 7.473a.5.5 0 010 .554L.918 9.7a1 1 0 00.832 1.55h2.012a.5.5 0 01.424.235l1.782 2.852a1 1 0 001.7 0l1.782-2.851a.5.5 0 01.424-.235h2.011a1 1 0 00.828-1.551L11.6 8.027a.5.5 0 010-.554l1.112-1.668a1 1 0 00-.832-1.555H9.87a.5.5 0 01-.424-.235L7.664 1.164a1.034 1.034 0 00-1.7 0L4.186 4.015a.5.5 0 01-.424.235H1.75a1 1 0 00-.832 1.555z\"/><path d=\"M23.25 3.5a3.5 3.5 0 00-3.5-3.5h-8.5a1 1 0 000 2h4.967a.251.251 0 01.237.332 3.667 3.667 0 00-.2 1.168v17a1.5 1.5 0 11-1.5-1.5 1 1 0 000-2H5.5a.25.25 0 01-.25-.25v-.5a1 1 0 00-2 0v.732a.252.252 0 01-.166.236A3.489 3.489 0 004.25 24h10.5a3.5 3.5 0 003.5-3.5V7.025a.25.25 0 01.336-.235A3.379 3.379 0 0019.75 7a3.5 3.5 0 003.5-3.5zm-11.8 18.167a.249.249 0 01-.236.333H4.25a1.5 1.5 0 010-3h6.964a.251.251 0 01.236.333 3.5 3.5 0 000 2.334zM19.75 5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});