define("ember-svg-jar/inlined/shipment-smartphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-smartphone</title><path d=\"M15.121 7.213a.251.251 0 000-.465l-2.953-1.181a.449.449 0 00-.336 0L8.877 6.758a.251.251 0 00-.156.233.247.247 0 00.157.231l2.936 1.166a.5.5 0 00.37 0zM12.5 13.931a.25.25 0 00.343.232l3.374-1.35a.45.45 0 00.283-.413V8.108a.249.249 0 00-.11-.207.251.251 0 00-.233-.026l-3.5 1.4a.25.25 0 00-.157.232zM7.842 7.887a.251.251 0 00-.342.232v4.264a.45.45 0 00.282.417l3.375 1.36a.249.249 0 00.343-.232V9.509a.251.251 0 00-.158-.232z\"/><path d=\"M19.5 3a3 3 0 00-3-3h-9a3 3 0 00-3 3v18a3 3 0 003 3h9a3 3 0 003-3zM11 21a1 1 0 111 1 1 1 0 01-1-1zM7.5 2h9a1 1 0 011 1v14.5a1 1 0 01-1 1h-9a1 1 0 01-1-1V3a1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});