define("ember-svg-jar/inlined/shipment-delivered", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-delivered</title><path d=\"M11.091 14.317a2.8 2.8 0 012.248-1.135h2.587a.25.25 0 00.242-.314l-.421-1.6a2.615 2.615 0 01.5-2.307 2.565 2.565 0 011.094-.78.249.249 0 00.162-.234V1.5a1 1 0 00-1-1h-4.75a.25.25 0 00-.25.25v5.543a.5.5 0 01-.853.353L9.176 5.177a.251.251 0 00-.354 0L7.353 6.646a.5.5 0 01-.853-.353V.75A.25.25 0 006.249.5H1.5a1 1 0 00-1 1v14a1 1 0 001 1h8.826a.252.252 0 00.185-.082.248.248 0 00.064-.191 2.775 2.775 0 01.516-1.91z\"/><path d=\"M16.362 23.463c1.141 0 1.945.037 2.614.037a8.562 8.562 0 004.382-1.19.25.25 0 00.143-.226v-6.853a.25.25 0 00-.213-.247 1.426 1.426 0 01-.941-.526l-3.17-4.488a1.109 1.109 0 00-1.98.92l.572 2.166a1.3 1.3 0 01-1.253 1.626h-3.177a1.3 1.3 0 00-1.239 1.673v.006l1.189 4.345V20.719c.511 1.633 1.368 2.744 3.073 2.744z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});