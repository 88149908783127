define("ember-svg-jar/inlined/antique-axe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>antique-axe</title><path d=\"M22.932 9.336a6.494 6.494 0 01-2.35.361 6.344 6.344 0 01-2.4-.566.254.254 0 01-.07-.409l.5-.5a2.068 2.068 0 00.29-2.644 2 2 0 00-3.046-.256s-.457.461-.6.6a.214.214 0 01-.345-.017 6.379 6.379 0 01-.6-2.485 6.522 6.522 0 01.361-2.35.494.494 0 00-.453-.65 9.5 9.5 0 00-9.624 9.625.493.493 0 00.649.453 6.521 6.521 0 012.35-.36 6.349 6.349 0 012.4.566.254.254 0 01.07.409L4.892 16.28a.25.25 0 000 .354l2.475 2.474a.249.249 0 00.353 0l5.169-5.168a.254.254 0 01.409.07 6.341 6.341 0 01.567 2.4 6.513 6.513 0 01-.361 2.349.494.494 0 00.453.65 9.495 9.495 0 009.625-9.625.494.494 0 00-.65-.448zM3.832 17.694a.252.252 0 00-.354 0L1 20.169A2 2 0 003.831 23l2.475-2.476a.249.249 0 000-.353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});