define("ember-svg-jar/inlined/phone-actions-ring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-ring</title><path d=\"M17.167 16.162a2.5 2.5 0 00-3.535 0l-.385.385a46.837 46.837 0 01-5.789-5.79l.386-.384a2.506 2.506 0 000-3.536L5.721 4.715a2.5 2.5 0 00-3.535 0L1.022 5.88a3.509 3.509 0 00-.442 4.4 46.906 46.906 0 0013.142 13.144 3.542 3.542 0 004.4-.442l1.165-1.164a2.5 2.5 0 000-3.535zM11.5.007a1 1 0 000 2 10.512 10.512 0 0110.5 10.5 1 1 0 102 0A12.515 12.515 0 0011.5.007z\"/><path d=\"M11.5 6.007a6.508 6.508 0 016.5 6.5 1 1 0 102 0 8.51 8.51 0 00-8.5-8.5 1 1 0 000 2z\"/><path d=\"M11.5 10.007a2.5 2.5 0 012.5 2.5 1 1 0 002 0 4.505 4.505 0 00-4.5-4.5 1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});