define("ember-svg-jar/inlined/smiley-dizzy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-dizzy</title><path d=\"M13.5 18.75a1 1 0 002 0v-1.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25z\"/><path d=\"M19.5 11.5c-.113 0-.225 0-.334-.011a.249.249 0 00-.257.182 2.5 2.5 0 01-3.695 1.473A7 7 0 0112 7a4.505 4.505 0 01-4.5 4.5c-.113 0-.225 0-.334-.011a.249.249 0 00-.257.182 2.5 2.5 0 01-3.695 1.473 6.45 6.45 0 01-2.6-3.034.25.25 0 00-.477.06 12 12 0 1023.6-.656.25.25 0 00-.454-.084A4.5 4.5 0 0119.5 11.5zM8.5 15h9a1 1 0 01.2 1.98.25.25 0 00-.2.245v1.525a3 3 0 01-6 0v-1.5a.25.25 0 00-.25-.25H8.5a1 1 0 010-2zM12 7a6.01 6.01 0 014.256-5.741.25.25 0 00.017-.473 12.008 12.008 0 00-8.546 0 .25.25 0 00.017.473A6.01 6.01 0 0112 7z\"/><path d=\"M3.985 11.857a1 1 0 101.03-1.714A3.619 3.619 0 013.5 7a2.5 2.5 0 015 0 1 1 0 01-1 1C7.087 8 7 8 7 7.5a1 1 0 00-1-1 1 1 0 00-1 1A2.3 2.3 0 007.5 10a3 3 0 003-3 4.5 4.5 0 00-9 0 5.672 5.672 0 002.485 4.857zM13.5 7a5.672 5.672 0 002.485 4.857 1 1 0 101.03-1.714A3.619 3.619 0 0115.5 7a2.5 2.5 0 015 0 1 1 0 01-1 1c-.413 0-.5 0-.5-.5a1 1 0 00-1-1 1 1 0 00-1 1 2.3 2.3 0 002.5 2.5 3 3 0 003-3 4.5 4.5 0 00-9 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});