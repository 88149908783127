define("ember-svg-jar/inlined/zip-file-compress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>zip-file-compress</title><path d=\"M17.518 11.5h-3.286a1 1 0 000 2h3.286a1 1 0 000-2zM17.518 8h-3.286a1 1 0 000 2h3.286a1 1 0 000-2zM3 8.5h3.5a.991.991 0 001-.988 1 1 0 011-1.012H19a1 1 0 001-1v-1a1 1 0 00-1-1H7.25A.25.25 0 017 3.25v-1A.25.25 0 017.25 2H8a1 1 0 000-2H4a1 1 0 000 2h.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H3a1 1 0 00-1 1v3a1 1 0 001 1zM9 20a1 1 0 00-1-1h-.75a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H19a1 1 0 001-1v-1a1 1 0 00-1-1H8.5a.991.991 0 01-1-.988 1 1 0 00-1-1.012H3a1 1 0 00-1 1v3a1 1 0 001 1h1.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H4a1 1 0 000 2h4a1 1 0 001-1z\"/><path d=\"M21.621 21.405l-3.432-.858a.249.249 0 01-.189-.242v-.8a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v.8a.249.249 0 01-.189.242l-3.432.858a.5.5 0 00-.379.485v1.61a.5.5 0 00.5.5h11a.5.5 0 00.5-.5v-1.61a.5.5 0 00-.379-.485z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});