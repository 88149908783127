define("ember-svg-jar/inlined/footwear-heels-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>footwear-heels-1</title><path d=\"M5.139 12.5a1 1 0 00-.864-.5H1.667a.5.5 0 00-.483.632 61.245 61.245 0 011.461 8.54A1.5 1.5 0 004.134 22.5H5A1.5 1.5 0 006.5 21v-5.892a1 1 0 00-.135-.5z\"/><path d=\"M23.769 20.86L19.9 16.22a2 2 0 00-1.537-.72H15.1a1.491 1.491 0 01-1.269-.7L7.423 4.625a3.485 3.485 0 00-1.51-1.319L2.255 1.644A1.594 1.594 0 000 3.1V10a.5.5 0 00.5.5h3.775a2.51 2.51 0 012.161 1.244l5.243 9.014a3.513 3.513 0 003.027 1.742H23a1 1 0 00.769-1.64z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});