define("ember-svg-jar/inlined/mobile-shopping-basket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mobile-shopping-basket</title><path d=\"M8.275 13.592A1.1 1.1 0 009.4 14.5h5.2a1.1 1.1 0 001.125-.911l.763-3.482A.5.5 0 0016 9.5h-.767a.249.249 0 01-.223-.138L13.9 7.163a.75.75 0 10-1.34.674l.655 1.3A.25.25 0 0113 9.5h-2a.25.25 0 01-.224-.362l.664-1.319a.75.75 0 10-1.34-.673L8.981 9.362a.249.249 0 01-.223.138H8a.5.5 0 00-.488.607z\"/><path d=\"M20 3a3 3 0 00-3-3H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3zm-2 2.5v12a1 1 0 01-1 1H7a1 1 0 01-1-1v-12a1 1 0 011-1h10a1 1 0 011 1zM11 21a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});