define("ember-svg-jar/inlined/road-sign-road-narrowing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-road-narrowing</title><path d=\"M23.53 12.53a.749.749 0 000-1.06l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0zM16.75 16a.75.75 0 01-1.5 0v-3.1a.249.249 0 00-.065-.168l-2.344-2.582a1.217 1.217 0 01-.341-.857V6.5a.75.75 0 011.5 0v2.6a.249.249 0 00.065.168l2.344 2.582a1.217 1.217 0 01.341.857zM11.5 6.5v2.793a1.244 1.244 0 01-.366.884l-2.319 2.556a.249.249 0 00-.065.168V16a.75.75 0 01-1.5 0v-3.293a1.244 1.244 0 01.366-.884l2.319-2.556A.249.249 0 0010 9.1V6.5a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});