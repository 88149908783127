define("ember-svg-jar/inlined/road-sign-left-reverse-turn-ahead-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-left-reverse-turn-ahead-1</title><path d=\"M23.78 12.53a.749.749 0 000-1.06L12.53.22a.749.749 0 00-1.06 0L.22 11.47a.749.749 0 000 1.06l11.25 11.25a.748.748 0 001.06 0zm-11.74.97h-2a.5.5 0 01-.5-.5V9.25A.25.25 0 009.29 9h-.647a.25.25 0 01-.177-.427l1.72-1.719a.5.5 0 01.707 0l1.72 1.719a.25.25 0 01-.177.427h-.646a.249.249 0 00-.25.25V11a.5.5 0 00.5.5h2a.5.5 0 01.5.5v5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-3a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});