define("ember-svg-jar/inlined/professions-man-chef-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-chef-1</title><path d=\"M21.987 2.726a.282.282 0 00-.066-.181.257.257 0 00-.154-.086A3.245 3.245 0 0018.5 4a.1.1 0 01-.092.051.1.1 0 01-.088-.057 6.986 6.986 0 00-12.27-.663.248.248 0 01-.044.053A3.841 3.841 0 004.75 6.236V7a.25.25 0 00.125.217.25.25 0 01.125.216V9a7 7 0 0014 0v-.652a.25.25 0 01.4-.2 3.232 3.232 0 002.371.568.284.284 0 00.166-.1.249.249 0 00.054-.166 3.246 3.246 0 00-1.578-2.65.25.25 0 010-.428 3.247 3.247 0 001.574-2.646zM15.1 3.1a.25.25 0 01-.182.444l-5.1-.51a.25.25 0 01-.091-.471A4.919 4.919 0 0115.1 3.1zM17 9A5 5 0 017 9V7.5a.25.25 0 01.25-.25h9.5a.25.25 0 01.25.25zM16.435 24H23a.5.5 0 00.5-.5v-3.1a2.463 2.463 0 00-.967-1.978 11.994 11.994 0 00-2.45-1.4.25.25 0 00-.32.109l-3.548 6.5a.249.249 0 00.22.37z\"/><path d=\"M.5 20.4v3.1a.5.5 0 00.5.5h7.326a.252.252 0 00.19-.087l3.066-3.56a.251.251 0 000-.33l-3.53-3.966a.5.5 0 00-.467-.16 14.53 14.53 0 00-6.118 2.52A2.461 2.461 0 00.5 20.4zM18.31 16.462a.252.252 0 00-.161-.145c-.244-.07-.5-.136-.76-.2a.252.252 0 00-.249.08l-6.364 7.391a.25.25 0 00.189.413h3.191a.251.251 0 00.22-.13l3.924-7.193a.252.252 0 00.01-.216z\"/><circle cx=\"14\" cy=\"9.25\" r=\"1\"/><circle cx=\"10\" cy=\"9.25\" r=\"1\"/><path d=\"M13.6 11.379a.5.5 0 00-.49-.379h-2.22a.5.5 0 00-.485.379l-.25 1a.5.5 0 00.485.621h2.72a.5.5 0 00.485-.621z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});