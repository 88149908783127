define("ember-svg-jar/inlined/credit-card-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-check</title><path d=\"M17.75 4a.25.25 0 00.25-.25 2.5 2.5 0 00-2.5-2.5h-13A2.5 2.5 0 000 3.75.25.25 0 00.25 4zM17.5 7.75h.242a.252.252 0 00.182-.071A.248.248 0 0018 7.5V5.75a.25.25 0 00-.25-.25H.25a.25.25 0 00-.25.25v6a2.5 2.5 0 002.5 2.5h6.544a.249.249 0 00.241-.186A8.515 8.515 0 0117.5 7.75zM3.5 10h3a.75.75 0 010 1.5h-3a.75.75 0 010-1.5z\"/><path d=\"M17.5 9.75a6.5 6.5 0 106.5 6.5 6.5 6.5 0 00-6.5-6.5zm3.368 4.863l-2.9 3.874a1.494 1.494 0 01-1.1.6h-.109a1.491 1.491 0 01-1.057-.441l-1.5-1.5a.75.75 0 011.061-1.061l1.3 1.3a.249.249 0 00.377-.027l2.732-3.643a.75.75 0 111.2.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});