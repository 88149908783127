define("ember-svg-jar/inlined/database-lock-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-lock-alternate</title><path d=\"M.859 4.942v12.631c0 3.107 4.986 4.834 9.865 4.953a1 1 0 001.03-.985v-.03a.98.98 0 00-.951-.987c-5.048-.1-7.944-1.856-7.944-2.951v-.761a.251.251 0 01.372-.218 17.053 17.053 0 007.536 1.726.98.98 0 00.987-.985V17.3a.981.981 0 00-.951-.987c-5.048-.095-7.944-1.856-7.944-2.951v-.761a.251.251 0 01.372-.218 17.432 17.432 0 008.032 1.731q.579 0 1.161-.031a.971.971 0 00.9-.808v-.022a.982.982 0 00-1.024-1.16c-.337.016-.684.025-1.04.025-5.13 0-8.4-1.753-8.4-2.959V8.4a.25.25 0 01.124-.216.247.247 0 01.248 0 17.437 17.437 0 008.033 1.731 17.441 17.441 0 008.031-1.73.249.249 0 01.249 0 .252.252 0 01.124.215v.34a.975.975 0 00.577.889l.022.01a.985.985 0 001.4-.893V4.952c0-3.221-5.36-4.96-10.4-4.96S.87 1.727.859 4.942zm10.4-2.95c5.126 0 8.4 1.751 8.4 2.958v.008c-.01 1.206-3.281 2.953-8.4 2.953s-8.4-1.753-8.4-2.959 3.273-2.96 8.403-2.96z\"/><path d=\"M21.876 15.606a.251.251 0 01-.213-.248v-1a3.475 3.475 0 00-6.95 0v1a.25.25 0 01-.212.247 1.492 1.492 0 00-1.267 1.472v5.445a1.492 1.492 0 001.49 1.49h6.929a1.492 1.492 0 001.49-1.49v-5.444a1.492 1.492 0 00-1.267-1.472zm-3.688 5.679a.99.99 0 11.99-.99.99.99 0 01-.99.99zm0-8.4a1.476 1.476 0 011.475 1.475v.982a.25.25 0 01-.25.25h-2.45a.25.25 0 01-.25-.25v-.982a1.476 1.476 0 011.475-1.479z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});