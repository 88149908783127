define("ember-svg-jar/inlined/army-badge-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-badge-2</title><path d=\"M5.8 8H.25a.225.225 0 00-.21.12.23.23 0 00-.01.24l.76 1.53A2 2 0 002.58 11h2.285a.25.25 0 00.247-.209 6.714 6.714 0 01.9-2.411.247.247 0 000-.252A.25.25 0 005.8 8zM23.96 8.12a.225.225 0 00-.21-.12H18.2a.25.25 0 00-.213.38 6.707 6.707 0 01.9 2.411.25.25 0 00.247.209h2.286a2 2 0 001.79-1.11l.76-1.53a.23.23 0 00-.01-.24zM5.047 12.727A.251.251 0 004.8 12.5H1.67a.225.225 0 00-.21.12.255.255 0 00-.02.24l.77 1.53A2 2 0 004 15.5h1.519a.25.25 0 00.223-.363 6.632 6.632 0 01-.695-2.41zM22.33 12.5H19.2a.249.249 0 00-.249.227 6.653 6.653 0 01-.7 2.41.25.25 0 00.223.363H20a2 2 0 001.79-1.11l.77-1.53a.255.255 0 00-.02-.24.225.225 0 00-.21-.12zM12 6.5a5.5 5.5 0 105.5 5.5A5.5 5.5 0 0012 6.5zm2.9 4.782l-1.137 1.136a.5.5 0 00-.127.491l.446 1.561a.35.35 0 01-.521.393l-1.294-.809a.5.5 0 00-.53 0l-1.294.808a.349.349 0 01-.521-.392l.446-1.561a.5.5 0 00-.127-.491L9.1 11.282a.349.349 0 01.246-.6h1.168a.5.5 0 00.454-.289l.712-1.528a.35.35 0 01.634 0l.716 1.535a.5.5 0 00.453.289h1.168a.349.349 0 01.247.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});