define("ember-svg-jar/inlined/diamond-hold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diamond-hold</title><path d=\"M12.706 9.705a1 1 0 00.1-1.3l-2.494-3.488A1 1 0 009.5 4.5H3a1 1 0 00-.868.5L.14 8.486A1 1 0 00.3 9.708l5.5 5.5a1 1 0 001.414 0zm-9.2-3.081a.25.25 0 01.218-.124h5.132a.25.25 0 01.2.1l.714 1a.25.25 0 01-.2.4H3.153a.25.25 0 01-.217-.374zm.333 3.8A.25.25 0 014.017 10h4.964a.25.25 0 01.177.427l-2.482 2.48a.25.25 0 01-.354 0zM23.263 7.481a.25.25 0 00.447-.152A9.967 9.967 0 0021.452.454a1.493 1.493 0 00-2.1-.039 1.509 1.509 0 00-.066 2.113 5.529 5.529 0 011.027 1.949.242.242 0 00.079.117 18.24 18.24 0 012.871 2.887z\"/><path d=\"M23.749 24a.25.25 0 00.25-.25V13c-.265-5.174-7.269-9.566-10.173-10.558a1.5 1.5 0 00-1.9.934 1.55 1.55 0 00.935 1.9c2.256.993 5.871 3.148 6.059 6.806.153 3-1.651 5.8-3.917 5.911h-.05a16.985 16.985 0 01-4.613-1.583 1.5 1.5 0 10-1.291 2.708A55.081 55.081 0 0114.5 22a2.635 2.635 0 01.976 1.787.25.25 0 00.248.213z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});