define("ember-svg-jar/inlined/keyboard-gaming", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-gaming</title><path d=\"M21.5 13h-3.689a.25.25 0 01-.177-.074l-.78-.78A.5.5 0 0016.5 12h-9a.5.5 0 00-.354.146l-.78.78a.25.25 0 01-.177.074H3.25a.25.25 0 01-.25-.25v-.25a2 2 0 012-2h13.5a4.5 4.5 0 100-9H12a.5.5 0 01-.5-.5 1 1 0 10-2 0A2.5 2.5 0 0012 3.5h6.5a2.5 2.5 0 110 5H5a4 4 0 00-4 4v1a.013.013 0 01-.006.014A2.493 2.493 0 000 15.5v5A2.5 2.5 0 002.5 23h3.689a.249.249 0 01.177.073l.78.78A.5.5 0 007.5 24h9a.5.5 0 00.354-.147l.78-.78a.249.249 0 01.177-.073H21.5a2.5 2.5 0 002.5-2.5v-5a2.5 2.5 0 00-2.5-2.5zm-3.25 3h-.5a.5.5 0 110-1h.5a.5.5 0 010 1zm.5 1.5a.5.5 0 01-.5.5h-.5a.5.5 0 010-1h.5a.5.5 0 01.5.5zm-3.5-1.5h-.5a.5.5 0 010-1h.5a.5.5 0 010 1zm.5 1.5a.5.5 0 01-.5.5h-.5a.5.5 0 010-1h.5a.5.5 0 01.5.5zm-3.5-1.5h-.5a.5.5 0 010-1h.5a.5.5 0 010 1zm.5 1.5a.5.5 0 01-.5.5h-.5a.5.5 0 010-1h.5a.5.5 0 01.5.5zm-3-2a.5.5 0 01-.5.5h-.5a.5.5 0 010-1h.5a.5.5 0 01.5.5zm-1 1.5h.5a.5.5 0 010 1h-.5a.5.5 0 010-1zm7.75 3a.5.5 0 010 1h-9a.5.5 0 010-1zM6.25 18h-.5a.5.5 0 010-1h.5a.5.5 0 010 1zm0-2h-.5a.5.5 0 010-1h.5a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});