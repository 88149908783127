define("ember-svg-jar/inlined/smart-watch-square-graph-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-graph-line</title><path d=\"M16.43 2.75a.251.251 0 00.238-.329L15.975.342A.5.5 0 0015.5 0h-7a.5.5 0 00-.475.342l-.693 2.079a.251.251 0 00.238.329zM7.57 21.25a.248.248 0 00-.2.1.251.251 0 00-.035.225l.693 2.079A.5.5 0 008.5 24h7a.5.5 0 00.475-.342l.693-2.079a.251.251 0 00-.035-.225.248.248 0 00-.2-.1zM6.068 12.25l2.272-3.9a1.269 1.269 0 011.178-.59 1.25 1.25 0 011.035.753l1.787 5.161a.25.25 0 00.468.013l1.092-2.658a1.267 1.267 0 011.091-.74 1.243 1.243 0 011.122.607l.813 1.354h2.824A.25.25 0 0020 12V6.5A2.5 2.5 0 0017.5 4h-11A2.5 2.5 0 004 6.5V12a.25.25 0 00.25.25z\"/><path d=\"M16.5 13.75a.751.751 0 01-.644-.364l-.519-.866a.249.249 0 00-.445.033l-1.216 2.956a1.274 1.274 0 01-1.142.741H12.5a1.254 1.254 0 01-1.113-.756l-1.864-5.38a.249.249 0 00-.452-.043l-1.923 3.306a.749.749 0 01-.648.373H4.25A.25.25 0 004 14v3.5A2.508 2.508 0 006.5 20h11a2.5 2.5 0 002.5-2.5V14a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});