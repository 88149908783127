define("ember-svg-jar/inlined/breakfast-cereal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>breakfast-cereal</title><path d=\"M11.28 10.781a.75.75 0 000-1.061l-.672-.672a.75.75 0 10-1.06 1.06l.672.672a.75.75 0 001.06.001zM6.142 10.593l-.672.672a.75.75 0 101.06 1.06l.672-.672a.75.75 0 00-1.06-1.06zM8.72 12.22a.749.749 0 000 1.06l.672.672a.75.75 0 101.06-1.06l-.672-.672a.749.749 0 00-1.06 0z\"/><path d=\"M21.943 8.322a1 1 0 00-1.469.432.989.989 0 00.358 1.221C21.594 10.484 22 11.023 22 11.5c0 1.461-3.8 3.5-10 3.5S2 12.961 2 11.5 5.8 8 12 8c.773 0 1.509.032 2.205.09a1 1 0 00.179-1.983A26.743 26.743 0 0012 6C6.183 6 0 7.928 0 11.5v1A11.771 11.771 0 0012 24a11.771 11.771 0 0012-11.5v-1a3.9 3.9 0 00-2.057-3.178z\"/><path d=\"M13.3 11.7a2.147 2.147 0 00-.293.7.5.5 0 00.534.6l4.572-.007a.5.5 0 00.5-.411 3 3 0 00-.263-1.838 1.059 1.059 0 01.01-.9l3.6-8.208A1.166 1.166 0 1019.821.7l-3.629 8.277a.943.943 0 01-.584.54A3.8 3.8 0 0013.3 11.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});