define("ember-svg-jar/inlined/team-meeting-message-women-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>team-meeting-message-women-question</title><path d=\"M18.75 11.5a1.752 1.752 0 001.75-1.75v-8A1.752 1.752 0 0018.75 0H5.25A1.752 1.752 0 003.5 1.748v8A1.752 1.752 0 005.25 11.5h5.5a.25.25 0 01.25.25v2a.75.75 0 001.28.53l2.707-2.71a.251.251 0 01.177-.073zm-4.53-1.28l-1.293 1.294a.25.25 0 01-.427-.177v-.587a.75.75 0 00-.75-.75h-6.5A.25.25 0 015 9.748v-8a.25.25 0 01.25-.25h13.5a.25.25 0 01.25.25v8a.25.25 0 01-.25.25h-4a.747.747 0 00-.53.22z\"/><path d=\"M12.25 2.5A2.253 2.253 0 0010 4.751a.75.75 0 101.5 0 .75.75 0 11.75.75.75.75 0 000 1.5 2.25 2.25 0 000-4.5z\"/><circle cx=\"12.25\" cy=\"8.251\" r=\".75\"/><path d=\"M9 16.751a4 4 0 00-8 0 2.874 2.874 0 01-.7 2.288 1 1 0 101.4 1.423 3.839 3.839 0 00.449-.53.251.251 0 01.361-.051 3.993 3.993 0 004.976 0 .251.251 0 01.361.051 3.839 3.839 0 00.449.53 1 1 0 101.4-1.423A2.874 2.874 0 019 16.751zm-4 2a2 2 0 01-1.965-1.631.249.249 0 01.189-.289 2.735 2.735 0 001.576-.906.25.25 0 01.4 0 2.735 2.735 0 001.577.906.249.249 0 01.189.29A2 2 0 015 18.751z\"/><path d=\"M5 21a4.135 4.135 0 00-3.692 2.266.5.5 0 00.442.734h6.5a.5.5 0 00.442-.734A4.135 4.135 0 005 21zM23.7 19.039a2.874 2.874 0 01-.7-2.288 4 4 0 10-8 0 2.874 2.874 0 01-.7 2.288 1 1 0 101.4 1.423 3.839 3.839 0 00.449-.53.251.251 0 01.361-.051 3.993 3.993 0 004.976 0 .251.251 0 01.361.051 3.839 3.839 0 00.449.53 1 1 0 101.4-1.423zm-4.7-.288a2 2 0 01-1.965-1.631.249.249 0 01.189-.289 2.735 2.735 0 001.577-.906.25.25 0 01.4 0 2.735 2.735 0 001.577.906.249.249 0 01.189.29A2 2 0 0119 18.751z\"/><path d=\"M19 21a4.135 4.135 0 00-3.692 2.266.5.5 0 00.442.734h6.5a.5.5 0 00.442-.734A4.135 4.135 0 0019 21z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});