define("ember-svg-jar/inlined/love-it-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it-circle</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm5.113 12.666l-4.932 5.145a.25.25 0 01-.361 0l-4.933-5.146a3.026 3.026 0 114.279-4.279l.658.659a.254.254 0 00.176.074.249.249 0 00.176-.074l.658-.658a3.025 3.025 0 114.278 4.279z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});