define("ember-svg-jar/inlined/checklist-pen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>checklist-pen</title><path d=\"M7.968 7.413a.752.752 0 00-1.055.118L5.437 9.376l-.907-.907A.75.75 0 003.469 9.53l1.5 1.5a.765.765 0 001.116-.062l2-2.5a.751.751 0 00-.117-1.055zM6.913 13.031l-1.476 1.845-.907-.907a.75.75 0 00-1.061 1.061l1.5 1.5a.765.765 0 001.116-.062l2-2.5a.75.75 0 10-1.172-.937zM20.5 8H17a.5.5 0 00-.5.5V18a1.5 1.5 0 001.5 1.5h1.5A1.5 1.5 0 0021 18V8.5a.5.5 0 00-.5-.5z\"/><path d=\"M24 3a3 3 0 00-3-3h-4a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h3.5a.5.5 0 00.5-.5V2a1 1 0 011 1v8a1 1 0 102 0zM15 3a1 1 0 00-1-1H1a1 1 0 00-1 1v18a1 1 0 001 1h13a1 1 0 000-2H2V4h12a1 1 0 001-1z\"/><path d=\"M12.5 9.249H10a.75.75 0 000 1.5h2.5a.75.75 0 000-1.5zM12.5 14.749H10a.75.75 0 100 1.5h2.5a.75.75 0 000-1.5zM18.749 20.5a1 1 0 00-1 1V23a1 1 0 102 0v-1.5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});