define("ember-svg-jar/inlined/drugs-syrup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drugs-syrup</title><path d=\"M16.5 20h-9a.5.5 0 00-.5.5v2A1.5 1.5 0 008.5 24h7a1.5 1.5 0 001.5-1.5v-2a.5.5 0 00-.5-.5zM19.106.844A2.5 2.5 0 0017.233 0H6.764a2.5 2.5 0 00-2.476 2.84l2.718 15.248a.5.5 0 00.494.412h9a.5.5 0 00.492-.412l2.722-15.282a2.5 2.5 0 00-.608-1.962zm-3.092 7.489a.267.267 0 01-.267.267H14.1a.5.5 0 00-.5.5v1.633a.267.267 0 01-.267.267h-2.669a.266.266 0 01-.266-.267V9.1a.5.5 0 00-.5-.5H8.28a.266.266 0 01-.266-.267V5.667A.266.266 0 018.28 5.4H9.9a.5.5 0 00.5-.5V3.267A.266.266 0 0110.664 3h2.667a.267.267 0 01.267.267V4.9a.5.5 0 00.5.5h1.649a.267.267 0 01.267.267z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});