define("ember-svg-jar/inlined/truck-moving", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>truck-moving</title><path d=\"M19.125 9.336h-2.5a.75.75 0 00-.75.75v5.75a.251.251 0 01-.25.25H.875a.75.75 0 00-.75.75v1.6a1.648 1.648 0 001.646 1.647h.008a.25.25 0 00.239-.179 3.24 3.24 0 016.214 0 .25.25 0 00.239.179h6.058a.25.25 0 00.239-.179 3.24 3.24 0 016.214 0 .25.25 0 00.239.179h1.008a1.648 1.648 0 001.646-1.647v-4.35a4.755 4.755 0 00-4.75-4.75zm2.75 5.25h-2.5a.5.5 0 01-.5-.5v-2.5a.5.5 0 01.5-.5 3 3 0 013 3 .5.5 0 01-.5.5z\"/><circle cx=\"5.125\" cy=\"20.836\" r=\"2.25\"/><circle cx=\"17.875\" cy=\"20.836\" r=\"2.25\"/><path d=\"M1.375 7.586h1.25a.25.25 0 01.25.25v6.25a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-3a1 1 0 011-1h1.5a1 1 0 011 1v3a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-6.25a.25.25 0 01.25-.25h1.25a.5.5 0 00.331-.875L8.7 1.163a1 1 0 00-1.316 0L1.046 6.709a.5.5 0 00.329.877z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});