define("ember-svg-jar/inlined/tshirt-outline-o", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tshirt-outline-o</title><path d=\"M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm0-1c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C7.716 1 1 7.716 1 16c0 8.284 6.716 15 15 15zM12.946 9.205h-.327a3.782 3.782 0 00-2.676 1.11L6.837 13.42l1.883 1.883 2.218-1.85a.516.516 0 01.847.397v9.807h8.43V13.85c0-.437.51-.676.847-.396l2.218 1.849 1.883-1.883-3.107-3.106a3.786 3.786 0 00-2.677-1.109h-.325a3.098 3.098 0 01-6.108 0zm1.054 0a2.065 2.065 0 004 0h-4zm7.247 14.968a.516.516 0 01-.516.516H11.27a.516.516 0 01-.516-.516v-9.22l-1.734 1.444a.516.516 0 01-.696-.031l-2.58-2.58a.516.516 0 010-.73l3.47-3.472a4.815 4.815 0 013.406-1.411h6.76c1.277 0 2.503.508 3.407 1.411l3.471 3.471a.516.516 0 010 .73l-2.58 2.58a.516.516 0 01-.696.032l-1.734-1.445v9.22z\" fill=\"#29344C\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});