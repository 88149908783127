define("ember-svg-jar/inlined/eco-house-sun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>eco-house-sun</title><path d=\"M14.5 3a1 1 0 001-1V1a1 1 0 00-2 0v1a1 1 0 001 1zM21.217 2.782a1 1 0 00-1.414 0l-.703.708a1 1 0 001.41 1.41l.707-.707a1 1 0 000-1.411zM8.49 4.9A1 1 0 009.9 3.49l-.7-.708A1 1 0 007.783 4.2zM23 8.5h-1a1 1 0 000 2h1a1 1 0 000-2zM14.5 4a5.464 5.464 0 00-3.889 1.611 1 1 0 101.414 1.414 3.5 3.5 0 014.95 4.95 1 1 0 001.414 1.414A5.5 5.5 0 0014.5 4zM14.8 11.6l-7-5.15a.5.5 0 00-.592 0L.2 11.6a.5.5 0 00-.2.4v11.5a.5.5 0 00.5.5h14a.5.5 0 00.5-.5V12a.5.5 0 00-.2-.4zm-5.723 7.3a3.234 3.234 0 01-2.907.813 2.087 2.087 0 01-.681-.37.5.5 0 01-.088-.686 6.9 6.9 0 012.844-2.467.5.5 0 10-.337-.941c-2.105.744-4.03 3.547-4.951 5.569a.5.5 0 01-.915-.405A17.247 17.247 0 013.8 17.452a.5.5 0 00.025-.56 2.172 2.172 0 01-.343-1.427A2.739 2.739 0 015.1 13.459a6.957 6.957 0 012.348-.613 6.5 6.5 0 002.655-.779.5.5 0 01.43-.034.5.5 0 01.3.314A7.306 7.306 0 019.077 18.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});