define("ember-svg-jar/inlined/religion-ankh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-ankh</title><path d=\"M22 10a1 1 0 00-1-1h-4.108a.25.25 0 01-.22-.369A6.563 6.563 0 0017.5 5.5 5.346 5.346 0 0012 0a5.346 5.346 0 00-5.5 5.5 6.563 6.563 0 00.828 3.131.25.25 0 01-.22.369H3a1 1 0 00-1 1v2a1 1 0 001 1h5.9a.5.5 0 01.5.545l-.856 9.412A.951.951 0 009.5 24h5a.951.951 0 00.953-1.043l-.853-9.412a.5.5 0 01.5-.545H21a1 1 0 001-1zm-9.7-1.1a.5.5 0 01-.6 0c-.225-.169-2.2-1.7-2.2-3.4A2.276 2.276 0 0112 3a2.276 2.276 0 012.5 2.5c0 1.7-1.975 3.231-2.2 3.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});