define("ember-svg-jar/inlined/time-clock-file-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-clock-file-lock</title><path d=\"M23.363 3.053L20.948.638a1.749 1.749 0 00-1.237-.512H8.125a1.749 1.749 0 00-1.75 1.75v7.773a.242.242 0 00.169.228 4.466 4.466 0 011.078.53c.086.058.253.059.253-.111v-8.42a.251.251 0 01.25-.25h11.586l.177.073L22.3 4.113a.249.249 0 01.073.177v14.086a.251.251 0 01-.25.25H11.875a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h10.25a1.75 1.75 0 001.75-1.75V4.29a1.749 1.749 0 00-.512-1.237z\"/><path d=\"M8.736 15.388a.123.123 0 01-.111-.12v-1.142a3.5 3.5 0 10-7 0v1.134a.114.114 0 01-.113.128 1.5 1.5 0 00-1.387 1.488v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5v-5.5a1.5 1.5 0 00-1.389-1.488zm-3.611 5.738a1 1 0 111-1 1 1 0 01-1 1zm1.5-5.866a.121.121 0 01-.106.116H3.737a.122.122 0 01-.112-.116v-1.134a1.5 1.5 0 113 0zM15.625 13.126a4.75 4.75 0 10-4.75-4.75 4.756 4.756 0 004.75 4.75zm0-8a3.25 3.25 0 11-3.25 3.25 3.254 3.254 0 013.25-3.25z\"/><path d=\"M15.625 9.126h1.768a.75.75 0 100-1.5h-.767a.252.252 0 01-.251-.252v-.766a.75.75 0 00-1.5 0v1.768a.75.75 0 00.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});