define("ember-svg-jar/inlined/currency-dollar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-dollar</title><path d=\"M18.133 16.1a4.79 4.79 0 00-3.028-4.47l-5.1-2.039a1.815 1.815 0 01.675-3.5h5.023a1.5 1.5 0 000-3H13.75a.25.25 0 01-.25-.25V1.5a1.5 1.5 0 00-3 0v1.371a.25.25 0 01-.228.25A4.8 4.8 0 008.9 12.373l5.1 2.039a1.815 1.815 0 01-.675 3.5H8.294a1.5 1.5 0 000 3h1.956a.25.25 0 01.25.25V22.5a1.5 1.5 0 003 0v-1.371a.25.25 0 01.228-.25 4.81 4.81 0 004.405-4.779z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});