define("ember-svg-jar/inlined/cable-split-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cable-split-1</title><circle cx=\"11.75\" cy=\"11.061\" r=\"3.5\"/><path d=\"M21.957 2.392l-.357-.354a1 1 0 00-1.414 0L15.9 6.331a1 1 0 000 1.414l.354.354a1 1 0 001.414 0l4.292-4.293a1 1 0 000-1.414z\"/><rect x=\"9.25\" y=\"20.246\" width=\"5\" height=\"2.5\" rx=\"1\" ry=\"1\"/><rect x=\"9.25\" y=\"16.246\" width=\"5\" height=\"2.5\" rx=\"1\" ry=\"1\"/><path d=\"M6.224 2.6a1 1 0 00-.292-.707l-.353-.354a1.025 1.025 0 00-1.414 0L2.043 3.66a1 1 0 000 1.414l.353.354a1 1 0 001.414 0l2.121-2.122a.993.993 0 00.293-.706zM8.76 4.721l-.354-.354a1.025 1.025 0 00-1.414 0L4.871 6.488a1 1 0 000 1.414l.354.354a1 1 0 001.413 0L8.76 6.135a1 1 0 000-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});