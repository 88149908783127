define("ember-svg-jar/inlined/design-tool-quill-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-tool-quill-1</title><path d=\"M13.3 12.115a.5.5 0 01.707.707l-6.749 6.751a.25.25 0 00.177.427h3.236a2.484 2.484 0 001.768-.732l6.835-6.835A16.578 16.578 0 0023.98 2.81a2.481 2.481 0 00-.713-2.078A2.509 2.509 0 0021.19.019a16.539 16.539 0 00-5.968 1.956.254.254 0 00-.11.123l-2.881 7a.25.25 0 01-.468-.017l-1.01-3.027a.248.248 0 00-.179-.165.252.252 0 00-.235.067L4.732 11.56A2.487 2.487 0 004 13.328v5.154a.249.249 0 01-.073.177L.293 22.293a1 1 0 001.414 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});