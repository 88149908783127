define("ember-svg-jar/inlined/single-man-actions-mobilephone.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-mobilephone</title><path d=\"M.623 18.055h1.79a.248.248 0 01.247.227l.44 5.256a.494.494 0 00.493.453h3.955a.5.5 0 00.493-.453l.438-5.256a.248.248 0 01.246-.227h1.791a.5.5 0 00.495-.494V14.1a5.442 5.442 0 00-10.883 0v3.463a.494.494 0 00.495.492z\"/><path d=\"M5.569 0a4.2 4.2 0 104.2 4.205A4.209 4.209 0 005.569 0zm0 6.431a2.229 2.229 0 01-2.226-2.226 2.132 2.132 0 01.157-.8.245.245 0 01.152-.143.247.247 0 01.207.024 5.313 5.313 0 002.8.792 5.332 5.332 0 00.9-.076.2.2 0 01.237.2 2.228 2.228 0 01-2.227 2.229zM22.414 11.139h-5.988a1.477 1.477 0 00-1.458 1.492v9.876A1.478 1.478 0 0016.426 24h5.988a1.478 1.478 0 001.458-1.493v-9.876a1.477 1.477 0 00-1.458-1.492zm-5.467 10.388v-7.915a.5.5 0 01.494-.495H21.4a.5.5 0 01.494.495v7.915a.5.5 0 01-.494.494h-3.959a.494.494 0 01-.494-.494z\"/><path d=\"M.623 18.055h1.79a.248.248 0 01.247.227l.44 5.256a.494.494 0 00.493.453h3.955a.5.5 0 00.493-.453l.438-5.256a.248.248 0 01.246-.227h1.791a.5.5 0 00.495-.494V14.1a5.442 5.442 0 00-10.883 0v3.463a.494.494 0 00.495.492z\"/><path d=\"M5.569 0a4.2 4.2 0 104.2 4.205A4.209 4.209 0 005.569 0zm0 6.431a2.229 2.229 0 01-2.226-2.226 2.132 2.132 0 01.157-.8.245.245 0 01.152-.143.247.247 0 01.207.024 5.313 5.313 0 002.8.792 5.332 5.332 0 00.9-.076.2.2 0 01.237.2 2.228 2.228 0 01-2.227 2.229zM22.414 11.139h-5.988a1.477 1.477 0 00-1.458 1.492v9.876A1.478 1.478 0 0016.426 24h5.988a1.478 1.478 0 001.458-1.493v-9.876a1.477 1.477 0 00-1.458-1.492zm-5.467 10.388v-7.915a.5.5 0 01.494-.495H21.4a.5.5 0 01.494.495v7.915a.5.5 0 01-.494.494h-3.959a.494.494 0 01-.494-.494z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});