define("ember-svg-jar/inlined/tags-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-edit</title><path d=\"M20.016 6.02V2.034A2 2 0 0018.025.042h-4.241a3.74 3.74 0 00-2.407 1L.583 11.836a2 2 0 000 2.818l4.817 4.82a1.967 1.967 0 001.4.58h.011a1.968 1.968 0 001.406-.595L19.033 8.426a3.775 3.775 0 00.983-2.406zm-3.488-.5a1.993 1.993 0 11-1.993-1.994 1.993 1.993 0 011.993 1.996zM12.779 20.018a.5.5 0 00-.831.215l-.883 3.09a.5.5 0 00.479.635.458.458 0 00.137-.019l3.09-.883a.5.5 0 00.215-.831zM18.791 13.224a.5.5 0 00-.705 0l-4.993 4.993a.5.5 0 000 .705l2.989 2.99a.5.5 0 00.7 0l4.994-4.994a.5.5 0 000-.7zM19.5 11.811a.5.5 0 000 .708l2.99 2.99a.538.538 0 00.376.123.576.576 0 00.376-.169 2.617 2.617 0 000-3.694 2.649 2.649 0 00-3.742.042z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});