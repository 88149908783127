define("ember-svg-jar/inlined/phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone</title><path d=\"M22.673 17.009l-2.452-2.453a2.811 2.811 0 00-3.979 0l-.5.5A54.366 54.366 0 018.94 8.245l.5-.5a2.817 2.817 0 000-3.978l-2.457-2.45a2.883 2.883 0 00-3.983 0L1.66 2.663a3.981 3.981 0 00-.5 4.994 54.174 54.174 0 0015.173 15.176 4.015 4.015 0 005-.5l1.345-1.346a2.809 2.809 0 000-3.977z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});