define("ember-svg-jar/inlined/shield-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shield-key</title><path d=\"M24 1.953A1.959 1.959 0 0022.043.006H1.959A1.958 1.958 0 00.012 1.965L0 9.306a15.146 15.146 0 0011.861 14.669 1 1 0 00.4 0A15.145 15.145 0 0023.988 9.2zm-4.5 3.833a.5.5 0 01-.147.354l-4.577 4.568a.5.5 0 00-.12.513 4 4 0 11-2.507-2.512.5.5 0 00.505-.111l4.7-4.467a.5.5 0 01.345-.137H19a.5.5 0 01.5.5z\"/><path d=\"M9.784 11.1a1.75 1.75 0 102.475 0 1.752 1.752 0 00-2.475 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});