define("ember-svg-jar/inlined/button-rewind-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-rewind-alternate</title><path d=\"M12 .246A11.751 11.751 0 1023.75 12 11.764 11.764 0 0012 .246zm0 21A9.251 9.251 0 1121.25 12 9.262 9.262 0 0112 21.248z\"/><path d=\"M15.167 9.215l-2.832 1.526a.253.253 0 01-.247-.006.25.25 0 01-.122-.215v-.637a.733.733 0 00-1.117-.668L6 11.829a.706.706 0 000 1.29l4.852 2.612a.85.85 0 00.4.107.71.71 0 00.361-.1.759.759 0 00.356-.675v-.638a.25.25 0 01.369-.22l2.831 1.524a.731.731 0 001.118-.666v-5.18a.733.733 0 00-1.117-.668z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});