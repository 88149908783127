define("ember-svg-jar/inlined/style-two-pin-book", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-two-pin-book</title><path d=\"M12 0a9.511 9.511 0 00-9.5 9.5c0 6.547 6.538 12.488 8.541 14.153a1.5 1.5 0 001.917 0c2-1.665 8.542-7.608 8.542-14.154A9.511 9.511 0 0012 0zm-.5 15.284a.5.5 0 01-.5.5.5.5 0 01-.224-.053l-4-2a.5.5 0 01-.276-.447v-6a.5.5 0 01.724-.448l4 2a.5.5 0 01.276.448zm.5-7.5a.5.5 0 01-.224-.053L8.209 5.947a.5.5 0 01.448-.894L12 6.724l3.343-1.671a.5.5 0 11.448.894l-3.567 1.784a.5.5 0 01-.224.053zm5.5 5.5a.5.5 0 01-.276.447l-4 2a.5.5 0 01-.224.053.5.5 0 01-.5-.5v-6a.5.5 0 01.276-.448l4-2a.5.5 0 01.724.448z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});