define("ember-svg-jar/inlined/arrow-right-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-right-1</title><path d=\"M19.5 12a2.3 2.3 0 01-.78 1.729L7.568 23.54a1.847 1.847 0 01-2.439-2.773l9.752-8.579a.25.25 0 000-.376L5.129 3.233A1.847 1.847 0 017.568.46l11.148 9.808A2.31 2.31 0 0119.5 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});