define("ember-svg-jar/inlined/conversation-sync-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>conversation-sync-alternate</title><path d=\"M18.87 1.5H5.13A5.136 5.136 0 000 6.631v7.542a5.139 5.139 0 004.626 5.107l.035 1.354a1.923 1.923 0 001.176 1.717 1.918 1.918 0 002.051-.368l2.866-2.683h2.511l3.023 2.7a1.918 1.918 0 003.192-1.483l-.032-1.244a5.139 5.139 0 004.552-5.1V6.631A5.136 5.136 0 0018.87 1.5zM22 14.173a3.134 3.134 0 01-3.13 3.127h-.212a1.23 1.23 0 00-1.229 1.261l.048 1.813-3.1-2.761a1.229 1.229 0 00-.819-.313h-3.107a1.229 1.229 0 00-.84.331l-2.955 2.795-.05-1.926a1.223 1.223 0 00-1.229-1.2H5.13A3.134 3.134 0 012 14.173V6.631A3.134 3.134 0 015.13 3.5h13.74A3.134 3.134 0 0122 6.631z\"/><path d=\"M15.079 11.117a.686.686 0 00-.874.429 2.722 2.722 0 01-2.575 1.839A2.693 2.693 0 0110 12.83a.228.228 0 01-.024-.345l.678-.678a.688.688 0 00-.487-1.175H7.869a.688.688 0 00-.688.688v2.3a.689.689 0 001.175.487l.316-.317a.231.231 0 01.311-.012 4.077 4.077 0 006.524-1.782.688.688 0 00-.428-.879zM16.394 6.078a.687.687 0 00-.75.149l-.316.316a.229.229 0 01-.311.012 4.077 4.077 0 00-6.524 1.782.688.688 0 001.3.446A2.707 2.707 0 0114 7.5a.228.228 0 01.024.345l-.678.678a.688.688 0 00.49 1.177h2.3a.688.688 0 00.688-.688V6.714a.688.688 0 00-.43-.636z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});