define("ember-svg-jar/inlined/video-game-goomba", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-frog</title><path d=\"M22.571 20.312l-3.552-2.338a1 1 0 01-.427-.62 7.344 7.344 0 00-.13-.5.5.5 0 00-.479-.356H6.017a.5.5 0 00-.479.356c-.049.164-.093.33-.13.5a1 1 0 01-.427.62l-3.552 2.338A2 2 0 002.5 24h19a2 2 0 001.071-3.688zM6.424 21.865A.249.249 0 016.2 22H3.335a.25.25 0 01-.138-.459l1.957-1.288a.251.251 0 01.342.065l.91 1.288a.249.249 0 01.018.259zM20.665 22H17.8a.25.25 0 01-.2-.394l.91-1.288a.251.251 0 01.342-.065l1.948 1.288a.25.25 0 01-.138.459zM5.023 6.507a.5.5 0 01-.372.449l-.763.2A4 4 0 005 15h14a4 4 0 001.112-7.844l-.763-.2a.5.5 0 01-.372-.449 6.916 6.916 0 00-.885-2.925.313.313 0 01.067-.39.565.565 0 01.4-.192.5.5 0 01.353.146l1.35 1.47a1 1 0 101.472-1.353l-1.379-1.5a2.511 2.511 0 00-3.564-.031l-1.96 1.715a.75.75 0 01-.989-1.129l1.331-1.164a.254.254 0 00.083-.22.251.251 0 00-.136-.192 6.931 6.931 0 00-6.24 0 .251.251 0 00-.136.192.254.254 0 00.083.22l1.331 1.164a.75.75 0 01-.989 1.129L7.207 1.732a2.511 2.511 0 00-3.564.031l-1.379 1.5a1 1 0 101.472 1.353l1.35-1.47A.5.5 0 015.439 3a.565.565 0 01.4.192.313.313 0 01.067.39 6.916 6.916 0 00-.883 2.925zM14.75 7a.75.75 0 011.5 0v1a.75.75 0 01-1.5 0zm-7 0a.75.75 0 011.5 0v1a.75.75 0 01-1.5 0zM6.5 10h11a.5.5 0 010 1h-.307a.5.5 0 00-.433.25l-.86 1.49a.438.438 0 01-.8 0l-.86-1.49a.5.5 0 00-.433-.25h-3.614a.5.5 0 00-.433.25l-.86 1.49a.438.438 0 01-.8 0l-.86-1.49a.5.5 0 00-.433-.25H6.5a.5.5 0 010-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});