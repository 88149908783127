define("ember-svg-jar/inlined/weather-cloud-rainbow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-cloud-rainbow</title><path d=\"M23 11.25a1 1 0 000-2 5.23 5.23 0 00-3.5 1.344.251.251 0 00-.052.307 8.4 8.4 0 01.629 1.44.25.25 0 00.43.081A3.242 3.242 0 0123 11.25zM24 6a1 1 0 00-1-1 9.46 9.46 0 00-6.4 2.484.249.249 0 00.032.394 8.494 8.494 0 011.164.913.249.249 0 00.333.008A7.468 7.468 0 0123 7a1 1 0 001-1zM13.181 6.582a.25.25 0 00.226-.087A12.477 12.477 0 0123 2a1 1 0 000-2 14.491 14.491 0 00-11.832 6.127.25.25 0 00.221.394q.3-.021.611-.021a8.518 8.518 0 011.181.082zM12 8a6.965 6.965 0 00-6.662 4.84.249.249 0 01-.22.173A5.5 5.5 0 005.5 24H16a5 5 0 003.094-8.926.252.252 0 01-.1-.193A7.013 7.013 0 0012 8zm5.481 8.392A3 3 0 0116 22H5.5a3.5 3.5 0 11.448-6.971 1 1 0 001.116-.829A5 5 0 0117 15c0 .146-.007.289-.02.433a1 1 0 00.501.959z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});