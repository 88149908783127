define("ember-svg-jar/inlined/single-neutral-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-monitor</title><path d=\"M24 2.5A1.5 1.5 0 0022.5 1h-21A1.5 1.5 0 000 2.5V17a1.5 1.5 0 001.5 1.5h9a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5H4a1 1 0 000 2h16a1 1 0 000-2h-6.5a.5.5 0 01-.5-.5V19a.5.5 0 01.5-.5h9A1.5 1.5 0 0024 17zm-6.115 13.088a.507.507 0 01-.385.18h-11a.507.507 0 01-.385-.18.5.5 0 01-.106-.411A5.293 5.293 0 016.5 13.7c.453-.876 1.591-1.415 3.667-2.159a1 1 0 00-.007-.71A4.978 4.978 0 019.031 7.32 2.881 2.881 0 0112 4.268a2.881 2.881 0 012.969 3.052 5 5 0 01-1.149 3.534.979.979 0 00.027.7c2.061.735 3.2 1.274 3.652 2.151a5.275 5.275 0 01.492 1.476.5.5 0 01-.106.407z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});