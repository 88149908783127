define("ember-svg-jar/inlined/ruler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ruler</title><path d=\"M23.562 6.638a1.5 1.5 0 000-2.121L19.476.43a1.541 1.541 0 00-2.121 0l-2.781 2.78a.251.251 0 000 .352l2.284 2.33a.5.5 0 11-.714.7l-2.275-2.319a.257.257 0 00-.177-.075.248.248 0 00-.178.074L11.32 6.465a.251.251 0 000 .353l2.88 2.923a.5.5 0 01-.713.7l-2.873-2.914a.248.248 0 00-.177-.074.251.251 0 00-.178.073l-2.566 2.567a.25.25 0 000 .353l2.276 2.294a.5.5 0 01-.71.7l-2.273-2.29a.252.252 0 00-.177-.074.249.249 0 00-.177.073l-2.385 2.389a.249.249 0 000 .352l2.71 2.752a.5.5 0 01-.713.7L3.541 14.6a.248.248 0 00-.177-.075.251.251 0 00-.178.073L.438 17.347a1.5 1.5 0 000 2.121l4.086 4.087a1.5 1.5 0 002.121 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});