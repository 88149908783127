define("ember-svg-jar/inlined/shopping-basket-handle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-basket-handle</title><path d=\"M22.5 13.625h-21a.5.5 0 00-.488.607l1.841 8.325a1.459 1.459 0 001.466 1.068h15.362a1.459 1.459 0 001.466-1.068v-.007l1.839-8.318a.5.5 0 00-.488-.607zm-5 3v4a.75.75 0 01-1.5 0v-4a.75.75 0 011.5 0zm-5 0v4a.75.75 0 01-1.5 0v-4a.75.75 0 011.5 0zm-4.5 0v4a.75.75 0 01-1.5 0v-4a.75.75 0 011.5 0zM22.5 8.625h-.254A.249.249 0 0122 8.433l-.65-2.757c-.81-3.418-2.953-5.3-6.035-5.3h-6.63C5.6.375 3.46 2.258 2.65 5.676L2 8.433a.249.249 0 01-.243.192H1.5a1.5 1.5 0 00-1.5 1.5v1a1.5 1.5 0 001.5 1.5h21a1.5 1.5 0 001.5-1.5v-1a1.5 1.5 0 00-1.5-1.5zM5.084 6.252c.716-3.027 2.4-3.377 3.6-3.377h6.63c1.2 0 2.885.35 3.6 3.377l.43 1.815a.25.25 0 01-.243.308H4.9a.25.25 0 01-.243-.308z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});