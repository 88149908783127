define("ember-svg-jar/inlined/tags-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-alternate</title><path d=\"M22.291 16.12a3 3 0 000-4.242l-11-11A2.978 2.978 0 009.169 0H2a2 2 0 00-2 2v7.17a2.978 2.978 0 00.879 2.122l11 11a3 3 0 004.242 0zm-1.414-1.414l-6.172 6.172a1.025 1.025 0 01-1.414 0l-11-11A1 1 0 012 9.17V2.5a.5.5 0 01.5-.5h6.669a1 1 0 01.708.293l11 11a1 1 0 010 1.414z\"/><circle cx=\"5.998\" cy=\"5.999\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});