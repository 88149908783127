define("ember-svg-jar/inlined/audio-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file</title><path d=\"M21.414 4.914L17.086.586A2 2 0 0015.672 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.328a2 2 0 00-.586-1.414zM16.75 15.25a2.2 2.2 0 11-2.2-2.2c.068 0 .2.027.2-.122V10.4a.2.2 0 00-.2-.2l-4.115 1.324a1.207 1.207 0 01-.122.03.2.2 0 00-.163.2v5.9a2.2 2.2 0 11-2.2-2.2c.069 0 .2.028.2-.113v-3.595A2.2 2.2 0 019.874 9.6l4.149-1.334a.99.99 0 01.122-.031A2.2 2.2 0 0116.75 10.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});