define("ember-svg-jar/inlined/plant-bonsai-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plant-bonsai-2</title><path d=\"M21.554 5.338a.5.5 0 01-.3-.307A4.524 4.524 0 0017 2a3.965 3.965 0 00-.456.027.5.5 0 01-.432-.166 5.473 5.473 0 00-8.224 0 .5.5 0 01-.432.166A3.965 3.965 0 007 2a4.522 4.522 0 00-4.252 3.031.5.5 0 01-.3.307A2.7 2.7 0 00.5 8c0 1.767 1.645 3 4 3h1.033a.5.5 0 01.476.346 4.909 4.909 0 004.53 3.378.5.5 0 01.461.5v.276a.5.5 0 01-.5.5H6.25a1 1 0 000 2h11.5a1 1 0 000-2H13.5a.5.5 0 01-.5-.5v-.278a.5.5 0 01.461-.5 4.911 4.911 0 004.53-3.377.5.5 0 01.476-.345H19.5c2.355 0 4-1.233 4-3a2.7 2.7 0 00-1.946-2.662zM11 12.229a.5.5 0 01-.552.5 2.871 2.871 0 01-2.218-1.347.25.25 0 01.211-.382H10.5a.5.5 0 01.5.5zm4.77-.847a2.875 2.875 0 01-2.218 1.344.5.5 0 01-.386-.126.5.5 0 01-.166-.371V11.5a.5.5 0 01.5-.5h2.058a.25.25 0 01.212.382zM16.1 19H7.9a.5.5 0 00-.494.578l.494 3.108A1.5 1.5 0 009.383 24h5.234a1.5 1.5 0 001.483-1.3l.494-3.117A.5.5 0 0016.1 19z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});