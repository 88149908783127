define("ember-svg-jar/inlined/tags-favorite-star-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-favorite-star-alternate</title><circle cx=\"17.17\" cy=\"5.999\" r=\"1.5\"/><path d=\"M22.146 15h-2.5a.249.249 0 01-.229-.151l-1.345-3.119-.013-.027a1.294 1.294 0 00-2.318.027L14.4 14.847a.252.252 0 01-.23.151h-2.5a1.231 1.231 0 00-1.166.759 1.272 1.272 0 00.32 1.4l2.194 1.955a.25.25 0 01.063.286l-1.223 2.814a1.255 1.255 0 00.314 1.452 1.278 1.278 0 001.506.166l3.112-1.753a.254.254 0 01.245 0l3.113 1.753a1.3 1.3 0 00.641.172 1.286 1.286 0 00.865-.338 1.255 1.255 0 00.315-1.451l-1.23-2.813a.25.25 0 01.063-.286l2.213-1.972a1.264 1.264 0 00.3-1.378A1.234 1.234 0 0022.146 15z\"/><path d=\"M10.477 21.61l.836-1.924a.09.09 0 00-.146-.1l-1.29 1.291a1.027 1.027 0 01-1.415 0l-6.171-6.171a1 1 0 010-1.414l11-11A.993.993 0 0114 2h6.67a.5.5 0 01.5.5v6.67a1 1 0 01-.293.708l-1.257 1.256a.252.252 0 00-.053.276l.639 1.482a.252.252 0 00.183.147.254.254 0 00.224-.069l1.678-1.678a2.982 2.982 0 00.879-2.122V2a2 2 0 00-2-2H14a2.978 2.978 0 00-2.121.879l-11 11a3 3 0 000 4.242l6.171 6.172a3 3 0 003.024.738.249.249 0 00.175-.244 2.755 2.755 0 01.228-1.177z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});