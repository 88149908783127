define("ember-svg-jar/inlined/cash-pin-radius", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-pin-radius</title><path d=\"M12 24c1.229 0 12-.07 12-2.5 0-1.77-5.716-2.287-9.295-2.438a.254.254 0 00-.222.116c-.288.455-.565.884-.824 1.28a1.982 1.982 0 01-3.318 0c-.258-.4-.536-.825-.825-1.281a.253.253 0 00-.222-.116C5.715 19.213 0 19.73 0 21.5 0 23.93 10.771 24 12 24z\"/><path d=\"M12 0a6.674 6.674 0 00-6.742 6.6c0 3.1 4.886 10.811 6.339 13.035a.482.482 0 00.806 0c1.454-2.224 6.339-9.931 6.339-13.034A6.675 6.675 0 0012 0zm-.588 6.357l2.147 1.431a2.395 2.395 0 01-1.046 4.37.25.25 0 00-.221.248v.061a.875.875 0 01-1.75 0v-.041a.249.249 0 00-.25-.25h-.042a.875.875 0 010-1.75h1.98a.645.645 0 00.358-1.182l-2.147-1.431a2.395 2.395 0 011.124-4.378.157.157 0 00.143-.156v-.145a.875.875 0 011.75 0v.042a.251.251 0 00.25.25h.042a.875.875 0 010 1.75h-1.98a.645.645 0 00-.358 1.181z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});