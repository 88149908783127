define("ember-svg-jar/inlined/blood-drop-elements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>blood-drop-elements</title><path d=\"M14.142 20.055a8.6 8.6 0 01-2.885-14.681.5.5 0 00.117-.61A39.935 39.935 0 008.828.381a1.1 1.1 0 00-1.639 0C5.713 2.317.008 13.286.008 16.488c0 3.925 3.813 7.5 8 7.5a8.335 8.335 0 006.365-3.146.5.5 0 00-.231-.787zm-6.4.988a.749.749 0 01-.731.584.8.8 0 01-.167-.018 5.233 5.233 0 01-3.537-2.787.75.75 0 111.342-.668 3.738 3.738 0 002.528 1.992.749.749 0 01.563.897z\"/><path d=\"M16.875 4.776a7.117 7.117 0 107.117 7.118 7.126 7.126 0 00-7.117-7.118zm0 12.734a5.617 5.617 0 115.617-5.616 5.623 5.623 0 01-5.617 5.616z\"/><path d=\"M18.215 8.781A1 1 0 1016.8 10.2l1 1a1 1 0 001.414-1.414zM13.8 10.781l-1 1a1 1 0 101.415 1.419l1-1a1 1 0 00-1.415-1.419zM17.508 12.488a1 1 0 00-1 1v1.5a1 1 0 002 0v-1.5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});