define("ember-svg-jar/inlined/color-picker-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-picker-2-alternate</title><path d=\"M19.793 12.207a1 1 0 001.414-1.414l-.641-.642a.249.249 0 010-.353l1.862-1.862a4.775 4.775 0 00.353-6.717 4.777 4.777 0 00-6.718.353L14.2 3.434a.251.251 0 01-.354 0l-.641-.641a1 1 0 00-1.414 1.414zM11.714 6.275a.249.249 0 00-.353 0L9.6 8.038a.25.25 0 000 .353l1.755 1.755a.5.5 0 11-.707.707L8.891 9.1a.252.252 0 00-.354 0l-.939.94a.25.25 0 000 .353l1.755 1.755a.5.5 0 11-.707.707L6.891 11.1a.252.252 0 00-.354 0l-.939.94a.25.25 0 000 .353l1.755 1.755a.5.5 0 11-.707.707L4.891 13.1a.252.252 0 00-.354 0l-1.58 1.581a4.918 4.918 0 00-1.493 4.236L.437 19.94a1.5 1.5 0 000 2.121l1.5 1.5a1.5 1.5 0 002.121 0l1.026-1.025a4.919 4.919 0 004.236-1.493l8.405-8.4a.25.25 0 000-.353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});