define("ember-svg-jar/inlined/file-zip-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-zip-1</title><path d=\"M18 12.123h-1.126a.249.249 0 00-.249.249v2.252a.249.249 0 00.249.249H18a1.375 1.375 0 000-2.75z\"/><path d=\"M22 0H2A1.5 1.5 0 00.5 1.5v21A1.5 1.5 0 002 24h20a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022 0zM4.98 18.873H7.9a.625.625 0 010 1.25H4.3a1.125 1.125 0 01-.955-1.72l3.686-5.9a.25.25 0 00-.212-.382H4a.625.625 0 010-1.25h3.5a1.125 1.125 0 01.953 1.729l-3.685 5.9a.25.25 0 00.212.373zm7.894 0H14a.625.625 0 110 1.25h-4a.625.625 0 010-1.25h1.125a.25.25 0 00.25-.25v-6.251a.249.249 0 00-.249-.249H10a.625.625 0 010-1.25h4a.625.625 0 010 1.25h-1.126a.249.249 0 00-.249.249v6.252a.249.249 0 00.249.249zM18 16.123h-1.126a.249.249 0 00-.249.249V19.5a.625.625 0 11-1.25 0v-8a.625.625 0 01.625-.627h2a2.625 2.625 0 010 5.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});