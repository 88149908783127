define("ember-svg-jar/inlined/style-three-pin-walk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-walk</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm1.317 16.449a1 1 0 01-1.265-.631l-.546-1.636a.25.25 0 00-.472-.005l-.593 1.659a1.011 1.011 0 01-1.275.606 1 1 0 01-.607-1.278l2.5-7a1 1 0 111.882.672l-.515 1.442a.5.5 0 000 .327l1.526 4.579a1 1 0 01-.635 1.265zM13.5 6.5A1.5 1.5 0 1115 5a1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});