define("ember-svg-jar/inlined/book-open-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-open-2</title><path d=\"M22.821 4.1a.5.5 0 01-.337-.473V3.5a2 2 0 00-2-2h-5.468a4.224 4.224 0 00-2.689.811.5.5 0 01-.623 0A4.223 4.223 0 009.016 1.5h-5.5a2 2 0 00-2 2v.131a.5.5 0 01-.337.473A1.743 1.743 0 000 5.75v13a1.752 1.752 0 001.75 1.75H8c1.624 0 3 .433 3 1a1 1 0 002 0c0-.565 1.386-1 3.031-1h6.219A1.752 1.752 0 0024 18.75v-13a1.743 1.743 0 00-1.179-1.65zM11 5.676v11.518a.5.5 0 01-.635.482 4.963 4.963 0 00-1.349-.176H4a.5.5 0 01-.5-.5V4a.5.5 0 01.5-.5h5.016C11.009 3.5 11 4.679 11 5.676zM15.016 3.5h4.968a.5.5 0 01.5.5v13a.5.5 0 01-.5.5h-4.968a5.051 5.051 0 00-1.38.181A.5.5 0 0113 17.2V5.676c0-1.043.109-2.176 2.016-2.176z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});