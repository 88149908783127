define("ember-svg-jar/inlined/house-apartment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>house-apartment</title><path d=\"M16.5 11a.75.75 0 01-.75-.75 3.5 3.5 0 00-3.5-3.5h-.5a3.5 3.5 0 00-3.5 3.5.75.75 0 01-.75.75H1.512a.5.5 0 00-.5.5V23a1 1 0 001 1H10a.5.5 0 00.5-.5v-3a1.5 1.5 0 113 0v3a.5.5 0 00.5.5h8.011a1 1 0 001-1V11.5a.5.5 0 00-.5-.5zm-13 3a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v2a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5zm4.012 7.5a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h3a.5.5 0 01.5.5zM13.5 14a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-3a1.5 1.5 0 013 0zm6.988 7.5a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h3a.5.5 0 01.5.5zM20.5 16a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h3a.5.5 0 01.5.5z\"/><path d=\"M23 9.5a1 1 0 00.912-1.409l-.012-.023L20.077.591A1 1 0 0019.166 0H4.834a.989.989 0 00-.9.567L.088 8.091A1 1 0 001 9.5h5.394a.5.5 0 00.486-.386 5.009 5.009 0 014.87-3.864h.5a5.009 5.009 0 014.87 3.864.5.5 0 00.486.386z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});