define("ember-svg-jar/inlined/archery-bow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>archery-bow</title><path d=\"M23.5 19.438a.5.5 0 00-.849-.292l-.69.69a.5.5 0 01-.707 0l-.543-.543a1 1 0 00-1.414 1.414l.543.543a.5.5 0 010 .707l-.689.689a.5.5 0 00.291.85l4 .5a.5.5 0 00.558-.558zM2.625 5.832a1.491 1.491 0 001.061.439h.757a1 1 0 01.707.293l9.643 9.643a1 1 0 001.414-1.414L6.564 5.15a1 1 0 01-.292-.707v-.757a1.492 1.492 0 00-.44-1.061L3.354.146A.5.5 0 002.5.5V2a.5.5 0 01-.5.5H.5a.5.5 0 00-.353.854z\"/><path d=\"M23.383.076a1 1 0 00-1.09.217l-8.5 8.5a1 1 0 001.414 1.414l6.28-6.279a.248.248 0 01.279-.051.25.25 0 01.147.242c-.36 6.057-1.856 10.035-4.807 12.986s-6.93 4.448-12.987 4.808a.25.25 0 01-.191-.427l6.279-6.279a1 1 0 00-1.414-1.414l-8.5 8.5A1 1 0 001 24c8.439 0 13.678-1.639 17.52-5.48S24 9.438 24 1a1 1 0 00-.617-.924z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});