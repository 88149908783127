define("ember-svg-jar/inlined/office-hole-puncher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-hole-puncher</title><path d=\"M23.975 18.34L23 15.414a.249.249 0 00-.334-.151 3 3 0 01-1.166.237h-4a3 3 0 01-3-3v-.25a.25.25 0 00-.25-.25h-4.5a.25.25 0 00-.25.25v.25a3 3 0 01-3 3h-4a3 3 0 01-1.165-.235.245.245 0 00-.2 0 .25.25 0 00-.135.149L.025 18.34a.122.122 0 00.017.108.119.119 0 00.1.05H23.86a.119.119 0 00.1-.05.122.122 0 00.015-.108zM0 21.5A1.5 1.5 0 001.5 23h21a1.5 1.5 0 001.5-1.5v-1.25a.25.25 0 00-.25-.25H.25a.25.25 0 00-.25.25z\"/><path d=\"M6.5 14A1.5 1.5 0 008 12.5v-2a4.593 4.593 0 01.4-1.673L9 7.616A1.285 1.285 0 0110 7h4a1.285 1.285 0 011 .618l.605 1.211A4.593 4.593 0 0116 10.5v2a1.5 1.5 0 001.5 1.5h4a1.5 1.5 0 001.5-1.5v-2a6.172 6.172 0 00-.443-2.116L20.244 3.1A3.5 3.5 0 0017.038 1H6.962a3.5 3.5 0 00-3.206 2.1L1.443 8.381A6.183 6.183 0 001 10.5v2A1.5 1.5 0 002.5 14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});