define("ember-svg-jar/inlined/astronomy-telescope-stars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astronomy-telescope-stars</title><path d=\"M6.331 11.573a.494.494 0 00.017-.381l-.666-1.84a.5.5 0 00-.64-.3L.913 10.545a1 1 0 00-.6 1.281l.325.9a1 1 0 001.281.6l4.129-1.494a.5.5 0 00.283-.259zM10.044 23.984a1 1 0 001-1V19a.25.25 0 01.468-.122l2.549 4.587a1 1 0 101.748-.971l-4.891-8.805a.975.975 0 00-.164-.217.184.184 0 00-.022-.017.969.969 0 00-.259-.175 1.248 1.248 0 00-.084-.036.9.9 0 00-.69 0 1.248 1.248 0 00-.084.036.969.969 0 00-.259.175.184.184 0 00-.022.017.975.975 0 00-.164.217L4.278 22.5a1 1 0 101.748.971l2.549-4.588a.25.25 0 01.469.117v3.981a1 1 0 001 1.003zM10.5 5.517l-2.737.989A1.481 1.481 0 006.877 8.4l1 2.759a1.479 1.479 0 001.391.976 1.444 1.444 0 00.5-.089l2.737-.99a.5.5 0 00.283-.258.5.5 0 00.017-.382l-1.664-4.6a.5.5 0 00-.641-.299zM15.605 10.959a1.429 1.429 0 00.395-.054l4.25-1.178a1.478 1.478 0 001-1.928L19.3 2.427a1.481 1.481 0 00-2-.846L13.28 3.4a1.484 1.484 0 00-.782 1.85l1.713 4.736a1.487 1.487 0 001.394.973zM18.98 21.123a.623.623 0 001.166 0l.832-2.221a.249.249 0 01.146-.147l2.221-.832a.622.622 0 000-1.165l-2.222-.834a.25.25 0 01-.146-.146l-.833-2.221a.646.646 0 00-1.166 0l-.833 2.22a.25.25 0 01-.146.146l-2.22.834a.622.622 0 000 1.165l2.221.833a.249.249 0 01.146.147zM.574 3.886l1.816.681a.248.248 0 01.146.146l.681 1.815a.5.5 0 00.935 0l.682-1.815a.248.248 0 01.146-.146l1.82-.681a.5.5 0 000-.936l-1.82-.681a.252.252 0 01-.146-.147L4.152.308a.52.52 0 00-.935 0l-.681 1.814a.252.252 0 01-.146.147L.574 2.95a.5.5 0 000 .936zm4.35.586z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});