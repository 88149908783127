define("ember-svg-jar/inlined/movie-audience", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>movie-audience</title><path d=\"M1.5 11.25a1 1 0 00-1 1v4a4 4 0 004 4H6a1 1 0 000-2H4.5a2 2 0 01-2-2v-4a1 1 0 00-1-1z\"/><circle cx=\"4.75\" cy=\"8\" r=\"2\"/><path d=\"M0 1.63a.992.992 0 00.709.956l22 6.7A1 1 0 0024 8.325V1a1 1 0 00-1-1H1a1 1 0 00-1 1zM8.25 15H6.334a.249.249 0 01-.247-.215l-.347-2.427a1 1 0 00-.991-.858.975.975 0 00-.141.01 1 1 0 00-.848 1.132l.378 2.641A2.008 2.008 0 006.117 17H8a.25.25 0 01.25.25v3.25a1 1 0 002 0V17a2 2 0 00-2-2zM16 24h1.5a1 1 0 000-2H16a2 2 0 01-2-2v-4a1 1 0 00-2 0v4a4 4 0 004 4z\"/><circle cx=\"16.25\" cy=\"11.75\" r=\"2\"/><path d=\"M19.75 23a1 1 0 002 0v-2.25a2 2 0 00-2-2h-1.916a.249.249 0 01-.247-.215l-.347-2.427a1 1 0 00-.991-.858.975.975 0 00-.141.01 1 1 0 00-.848 1.132l.378 2.641a2.008 2.008 0 001.979 1.717H19.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});