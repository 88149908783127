define("ember-svg-jar/inlined/cat-carrier-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cat-carrier-2</title><path d=\"M1.75 15.5a.5.5 0 00-.485.621l1.561 6.242A1.5 1.5 0 004.281 23.5h15.438a1.5 1.5 0 001.455-1.137l1.561-6.242a.5.5 0 00-.485-.621zm13.5 3a.75.75 0 011.5 0v2a.75.75 0 01-1.5 0zm-4 0a.75.75 0 011.5 0v2a.75.75 0 01-1.5 0zm-4 0a.75.75 0 011.5 0v2a.75.75 0 01-1.5 0zM24 13a1.022 1.022 0 00-1.043-1h-.192l-1.591-6.364A1.5 1.5 0 0019.719 4.5h-3.247a4.5 4.5 0 00-8.944 0H4.281a1.5 1.5 0 00-1.455 1.136L1.235 12h-.192a1 1 0 100 2h21.914A1.022 1.022 0 0024 13zm-7.25-5v2a.75.75 0 01-1.5 0V8a.75.75 0 011.5 0zm-4 0v2a.75.75 0 01-1.5 0V8a.75.75 0 011.5 0zM8 7.25a.75.75 0 01.75.75v2a.75.75 0 01-1.5 0V8A.75.75 0 018 7.25zm4-4.75a2.5 2.5 0 012.45 2h-4.9a2.5 2.5 0 012.45-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});