define("ember-svg-jar/inlined/koala-head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>koala-head</title><path d=\"M23.785 7.689a5.091 5.091 0 00-2.551-3.529 4.863 4.863 0 00-3.934-.411 5.139 5.139 0 00-2.6 2.358H9.3a5.119 5.119 0 00-2.587-2.356 4.882 4.882 0 00-3.947.409A5.091 5.091 0 00.215 7.689a7.385 7.385 0 00.875 5.718 3.832 3.832 0 003.985 1.346C5.52 18.316 8.126 20.5 12.01 20.5s6.488-2.184 6.934-5.745a3.823 3.823 0 003.966-1.349 7.385 7.385 0 00.875-5.717zM8.005 12a1 1 0 111-1 1 1 0 01-1 1zm6 5.25a2.636 2.636 0 01-2.105-1.238 2.632 2.632 0 01-2.1 1.239.75.75 0 010-1.5c.483 0 .926-.6 1.192-1.124a.892.892 0 01-.478-.732V11c0-.553.672-1 1.5-1s1.5.447 1.5 1v2.9a.969.969 0 01-.647.823c.269.5.689 1.033 1.144 1.033a.75.75 0 110 1.5zm2-5.25a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});