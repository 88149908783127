define("ember-svg-jar/inlined/phone-actions-receive-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-receive-1</title><path d=\"M23.561.439a1.5 1.5 0 00-2.122 0l-6.146 6.147-1.327-1.326a1 1 0 00-1.7.7v4.818a.958.958 0 00.957.957h4.818a1 1 0 00.706-1.7l-1.333-1.328 6.147-6.146a1.5 1.5 0 000-2.122zM15.5 15.783a3.116 3.116 0 00-4.4 0l-.827.828a4.249 4.249 0 00-1.346 4.5 11.357 11.357 0 01-6.032-6.031 4.249 4.249 0 004.5-1.346l.83-.829a3.116 3.116 0 000-4.4l-3.59-3.582a2.232 2.232 0 00-3.649.739 13.313 13.313 0 0017.357 17.354 2.233 2.233 0 00.737-3.648z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});