define("ember-svg-jar/inlined/couple-tv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>couple-tv</title><path d=\"M22 4.25h-5.856a.25.25 0 01-.171-.432l1.961-1.838A1 1 0 0016.566.521l-3.474 3.256a.5.5 0 01-.684 0L8.934.521A1 1 0 007.566 1.98l1.961 1.838a.25.25 0 01-.171.432H2a2 2 0 00-2 2v15.5a2 2 0 002 2h20a2 2 0 002-2V6.25a2 2 0 00-2-2zm-3.5 17a.5.5 0 01-.5.5H2.5a.5.5 0 01-.5-.5V6.75a.5.5 0 01.5-.5H18a.5.5 0 01.5.5zM21.25 19a1 1 0 111-1 1 1 0 01-1 1zm0-4.5a1 1 0 111-1 1 1 0 01-1 1zm0-4.5a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M10.5 10.75a4 4 0 10-4 4 4 4 0 004-4zm-4 2a2 2 0 01-1.92-2.56.248.248 0 01.149-.162.245.245 0 01.219.018A4.978 4.978 0 008.2 10.7a.249.249 0 01.209.069.247.247 0 01.074.207A2 2 0 016.5 12.75zM16.75 16.75a4 4 0 00-8 0c0 1.656-.376 2.2-.492 2.33a1 1 0 101.484 1.34 2.8 2.8 0 00.285-.377.251.251 0 01.36-.068 3.99 3.99 0 004.726 0 .251.251 0 01.36.068 2.8 2.8 0 00.285.377 1 1 0 101.484-1.34c-.116-.128-.492-.674-.492-2.33zm-4 2a2 2 0 01-1.95-1.576.25.25 0 01.192-.3 2.967 2.967 0 00.709-.235 2.337 2.337 0 00.852-.683.251.251 0 01.4 0 2.337 2.337 0 00.852.683 2.967 2.967 0 00.709.235.25.25 0 01.192.3 2 2 0 01-1.956 1.576z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});