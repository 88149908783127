define("ember-svg-jar/inlined/road-sign-board-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-board-left</title><path d=\"M23.25 2.625H.75a.75.75 0 00-.75.75v10a.75.75 0 00.75.75h10.5a.25.25 0 01.25.25v6a1 1 0 002 0v-6a.25.25 0 01.25-.25h9.5a.75.75 0 00.75-.75v-10a.75.75 0 00-.75-.75zM19 9.125H8.75a.25.25 0 00-.25.25v1.5a.75.75 0 01-1.186.61l-3.5-2.5a.757.757 0 010-1.22l3.5-2.5a.75.75 0 011.186.61v1.5a.25.25 0 00.25.25H19a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});