define("ember-svg-jar/inlined/clean-car-leaf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>clean-car-leaf</title><path d=\"M5.443 6.192c2.33-1.5 5.949-3.129 8.644-2.539a.6.6 0 01-.258 1.181 8.47 8.47 0 00-4.593.749.5.5 0 00-.293.606c.492 1.652 2.286 2.189 4 1.961 2.09-.28 5.334-2.972 6-5.6a.606.606 0 00-.567-.753A7.858 7.858 0 0115.156.9a8.4 8.4 0 00-2.8-.875 3.305 3.305 0 00-2.95 1.013 2.647 2.647 0 00-.562 1.743.5.5 0 01-.319.471 20.575 20.575 0 00-3.76 1.94.6.6 0 00.678 1zM6.5 22h-3a.5.5 0 00-.5.5v.5a1 1 0 001 1h2a1 1 0 001-1v-.5a.5.5 0 00-.5-.5zM20.5 22h-3a.5.5 0 00-.5.5v.5a1 1 0 001 1h2a1 1 0 001-1v-.5a.5.5 0 00-.5-.5zM21.634 17.073l-.719-.718a3.987 3.987 0 01-.883-1.342l-1.73-4.323a1.49 1.49 0 00-1.392-.944H7.088a1.49 1.49 0 00-1.392.943l-1.729 4.326a4.011 4.011 0 01-.885 1.343l-.716.715a1.244 1.244 0 00-.366.884v1.793A1.252 1.252 0 003.25 21h17.5A1.252 1.252 0 0022 19.75v-1.793a1.244 1.244 0 00-.366-.884zm-4.186-2.543a.5.5 0 01-.414.22H6.966a.5.5 0 01-.464-.686l.8-2a.5.5 0 01.464-.314h8.468a.5.5 0 01.464.314l.8 2a.5.5 0 01-.05.466z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});