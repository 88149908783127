define("ember-svg-jar/inlined/resize-expand-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>resize-expand-1-alternate</title><rect x=\"6\" y=\"6\" width=\"12\" height=\"12\" rx=\"2\" ry=\"2\"/><path d=\"M1 6a1 1 0 001-1V2.5a.5.5 0 01.5-.5H5a1 1 0 000-2H2a2 2 0 00-2 2v3a1 1 0 001 1zM22 0h-3a1 1 0 000 2h2.5a.5.5 0 01.5.5V5a1 1 0 002 0V2a2 2 0 00-2-2zM5 22H2.5a.5.5 0 01-.5-.5V19a1 1 0 00-2 0v3a2 2 0 002 2h3a1 1 0 000-2zM23 18a1 1 0 00-1 1v2.5a.5.5 0 01-.5.5H19a1 1 0 000 2h3a2 2 0 002-2v-3a1 1 0 00-1-1zM10 2h4a1 1 0 000-2h-4a1 1 0 000 2zM14 22h-4a1 1 0 000 2h4a1 1 0 000-2zM23 9a1 1 0 00-1 1v4a1 1 0 002 0v-4a1 1 0 00-1-1zM1 15a1 1 0 001-1v-4a1 1 0 00-2 0v4a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});