define("ember-svg-jar/inlined/christmas-postcard-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-postcard-1</title><path d=\"M2.27 0a.5.5 0 00-.52.5v20.118a.5.5 0 00.4.489l13.4 2.87a1 1 0 001.2-.977v-1a.5.5 0 01.5-.5h3.5a1.5 1.5 0 001.5-1.5V1.5a1.5 1.5 0 00-1.5-1.5zm11.7 16.663a1.257 1.257 0 01-1.134.489l-2.281-.252a.5.5 0 00-.555.5v2.1a.75.75 0 01-1.5 0v-2.382a.5.5 0 00-.445-.5l-2.706-.3a1.25 1.25 0 01-1-1.757l3.739-8.31a1.25 1.25 0 012.3.039l3.741 9.143a1.26 1.26 0 01-.163 1.23zM20.25 19a.5.5 0 01-.5.5h-2.5a.5.5 0 01-.5-.5V3.905a1 1 0 00-.79-.978L13.942 2.5A.25.25 0 0114 2h5.75a.5.5 0 01.5.5z\"/><path d=\"M6 14.554a.251.251 0 00.2.352l1.747.193a.5.5 0 00.555-.5V14A.75.75 0 0110 14v.882a.5.5 0 00.445.5l1.715.19a.25.25 0 00.259-.343l-2.981-7.29a.251.251 0 00-.46-.008z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});