define("ember-svg-jar/inlined/halloween-calendar-skull", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>halloween-calendar-skull</title><path d=\"M12 11a5 5 0 00-5 5c0 1.657.843 3 2.5 3v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-.75a.5.5 0 011 0V20a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1c1.657 0 2.5-1.343 2.5-3a5 5 0 00-5-5zm-2 5.5a1 1 0 111-1 1 1 0 01-1 1zm4 0a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M22 3h-3a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v4.75a.75.75 0 01-1.5 0V3.5a.5.5 0 00-.5-.5h-6a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v4.75a.75.75 0 01-1.5 0V3.5A.5.5 0 004 3H2a2 2 0 00-2 2v17a2 2 0 002 2h20a2 2 0 002-2V5a2 2 0 00-2-2zm0 18.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});