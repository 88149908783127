define("ember-svg-jar/inlined/cursor-double-click", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-double-click</title><path d=\"M19.757 13.728h-2.241a.25.25 0 00-.25.241 7 7 0 11-7.237-7.237.25.25 0 00.241-.25V4.241a.5.5 0 00-.526-.5 10 10 0 1010.512 10.513.5.5 0 00-.499-.526z\"/><path d=\"M5.27 13.728a5 5 0 009.993.263.25.25 0 00-.249-.263h-2.528a.25.25 0 00-.248.222 1.99 1.99 0 11-2.19-2.19.249.249 0 00.222-.249V8.984a.25.25 0 00-.263-.249 5.006 5.006 0 00-4.737 4.993zM23.583 3.243L20.755.414a.5.5 0 00-.707 0l-1.46 1.46a.5.5 0 000 .707l2.829 2.829a.5.5 0 00.707 0l1.459-1.46a.5.5 0 000-.707z\"/><path d=\"M17.8 3.374a.5.5 0 00-.707 0l-2.879 2.873a.249.249 0 01-.353 0L12.624 5.01a.5.5 0 00-.854.354v6.364a.5.5 0 00.5.5h6.364a.5.5 0 00.353-.854l-1.237-1.237a.249.249 0 010-.353l2.874-2.874a.5.5 0 000-.708z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});