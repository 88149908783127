define("ember-svg-jar/inlined/programming-book", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-book</title><path d=\"M3.015 21.059A2.944 2.944 0 005.956 24h13.059a1.972 1.972 0 001.97-1.971V5.913a1.972 1.972 0 00-1.97-1.971H5.956A.942.942 0 015.015 3a1 1 0 011-1h13.47a1 1 0 000-2H6.015a3 3 0 00-3 3zm9.5-6.4h4a.75.75 0 010 1.5h-4a.75.75 0 010-1.5zm-5.031-3.72a.75.75 0 011.061-1.06l2.5 2.5a.749.749 0 010 1.06l-2.5 2.5a.75.75 0 11-1.061-1.06l1.793-1.789a.25.25 0 000-.354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});