define("ember-svg-jar/inlined/bag-school", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bag-school</title><path d=\"M22.5 11.5a1 1 0 01-.8-1.6A1.488 1.488 0 0022 9V5a3 3 0 00-3-3H5a3 3 0 00-3 3v4a1.488 1.488 0 00.3.9 1 1 0 11-1.6 1.2A3.468 3.468 0 010 9V5a5.006 5.006 0 015-5h14a5.006 5.006 0 015 5v4a3.468 3.468 0 01-.7 2.1 1 1 0 01-.8.4zM19.5 17.5h-2a.5.5 0 00-.5.5v.586a1 1 0 01-.293.707l-.353.353a.5.5 0 01-.708 0l-.353-.353a1 1 0 01-.293-.707V18a.5.5 0 00-.5-.5h-1.25a.5.5 0 00-.5.5v5.5a.5.5 0 00.5.5H19a1 1 0 001-1v-5a.5.5 0 00-.5-.5zM11.25 18a.5.5 0 00-.5-.5H9.5a.5.5 0 00-.5.5v.586a1 1 0 01-.293.707l-.353.353a.5.5 0 01-.708 0l-.353-.353A1 1 0 017 18.586V18a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v5a1 1 0 001 1h5.75a.5.5 0 00.5-.5z\"/><path d=\"M20.99 14.859l-.214-5a1 1 0 00-.99-.859H4.214a1 1 0 00-.99.859l-.214 5A1 1 0 004 16h2.5a.5.5 0 00.5-.5V15a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V15a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v.5a.5.5 0 00.5.5H20a1 1 0 00.99-1.141z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});