define("ember-svg-jar/inlined/single-man-actions-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-view</title><path d=\"M12.121 11.05a.25.25 0 00-.058-.425l-.911-.39a.251.251 0 01-.152-.23V8.87a.252.252 0 01.074-.177 3.305 3.305 0 00.779-2.23.251.251 0 01.088-.177 1.873 1.873 0 00.538-1.4 1.58 1.58 0 00-.317-1.015.252.252 0 01-.034-.265 2.527 2.527 0 00.157-1.726C11.944.492 10.242 0 8.888 0a3.752 3.752 0 00-3.163 1.324.251.251 0 01-.212.118 1.072 1.072 0 00-.9.375c-.372.493-.106 1.171.176 1.732a.251.251 0 01-.046.288 1.3 1.3 0 00-.162.2A1.765 1.765 0 004.32 5a1.524 1.524 0 00.638 1.331.249.249 0 01.117.194A3.167 3.167 0 005.92 8.7a.25.25 0 01.08.183v1.122a.25.25 0 01-.151.23l-3.937 1.687C.64 12.468.457 13.626.205 15.23c-.055.359-.117.751-.2 1.179A.5.5 0 00.5 17h8.757a.25.25 0 00.25-.264c0-.078-.008-.157-.008-.236a6.983 6.983 0 012.622-5.45z\"/><path d=\"M21.172 19.759a.248.248 0 01-.033-.311 5.513 5.513 0 10-1.692 1.691.25.25 0 01.311.034l2.534 2.534a1 1 0 101.414-1.414zM13 16.5a3.5 3.5 0 113.5 3.5 3.5 3.5 0 01-3.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});