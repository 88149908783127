define("ember-svg-jar/inlined/beer-opener", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beer-opener</title><path d=\"M23.414 9.4a2 2 0 000-2.829L17.433.586a2 2 0 00-2.829 0L9.6 5.592a2 2 0 000 2.829l5.98 5.979a2 2 0 002.828 0zm-3.481-1.039l-2.287 2.287a1.287 1.287 0 01-1.281.311.252.252 0 01-.156-.142.249.249 0 01.007-.21c.27-.532.674-1.338.744-1.531a.89.89 0 00-.216-.914l-.9-.906a.89.89 0 00-.917-.216c-.188.062-1 .473-1.528.744a.25.25 0 01-.354-.147 1.284 1.284 0 01.311-1.283l2.288-2.288a.684.684 0 01.969 0l3.325 3.326a.684.684 0 01-.005.969zM12.737 13.738l-2.474-2.476a.5.5 0 00-.708 0l-8.9 8.9a2.25 2.25 0 003.182 3.182l8.9-8.9a.5.5 0 000-.706zm-9.53 8.469a1 1 0 110-1.414 1 1 0 010 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});