define("ember-svg-jar/inlined/team-approve-disapprove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>team-approve-disapprove</title><path d=\"M11 1.837a1.5 1.5 0 00-1.5-1.5h-8a1.5 1.5 0 00-1.5 1.5v6a1.5 1.5 0 001.5 1.5h3.237a.255.255 0 01.156.054l2.3 1.836a.5.5 0 00.312.11.51.51 0 00.217-.05.5.5 0 00.278-.45v-1.25a.25.25 0 01.25-.25H9.5a1.5 1.5 0 001.5-1.5zM5.945 6.206a1.248 1.248 0 01-.911.5h-.089a1.242 1.242 0 01-.883-.367l-1-1a.75.75 0 011.06-1.061l.594.594a.251.251 0 00.377-.027l1.59-2.121a.749.749 0 111.2.9zM22.5.337h-8a1.5 1.5 0 00-1.5 1.5v6a1.5 1.5 0 001.5 1.5h1.25a.25.25 0 01.25.25v1.25a.5.5 0 00.283.45.51.51 0 00.217.05.5.5 0 00.312-.11l2.295-1.836a.255.255 0 01.156-.054H22.5a1.5 1.5 0 001.5-1.5v-6a1.5 1.5 0 00-1.5-1.5zm-1.97 5.469a.75.75 0 11-1.06 1.061l-.793-.793a.25.25 0 00-.354 0l-.793.793a.75.75 0 01-1.06-1.061l.793-.793a.25.25 0 000-.353l-.793-.793a.75.75 0 011.06-1.061l.793.793a.25.25 0 00.354 0l.793-.793a.75.75 0 011.06 1.061l-.793.793a.25.25 0 000 .353zM7.86 21.018a.25.25 0 01-.022-.436 3.925 3.925 0 10-4.167.009.25.25 0 01-.02.435 4.891 4.891 0 00-2.213 2.279.25.25 0 00.226.357h8.2a.249.249 0 00.225-.357 4.894 4.894 0 00-2.229-2.287zM3.9 16.709a.25.25 0 01.364-.144 5.086 5.086 0 002.548.7A4.826 4.826 0 007.6 17.2a.068.068 0 01.053.015.065.065 0 01.023.049 1.926 1.926 0 11-3.77-.552zM20.253 20.98a.25.25 0 01-.03-.435 3.925 3.925 0 10-4.162.084.25.25 0 01-.012.436 4.906 4.906 0 00-2.143 2.24.252.252 0 00.015.241.249.249 0 00.211.116h8.2a.25.25 0 00.226-.357 4.881 4.881 0 00-2.305-2.325zm-4.1-3.719a.065.065 0 01.023-.049.068.068 0 01.053-.015 4.826 4.826 0 00.782.064 5.085 5.085 0 002.547-.7.249.249 0 01.364.145 1.893 1.893 0 01.081.551 1.925 1.925 0 11-3.85 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});