define("ember-svg-jar/inlined/person-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"none\" stroke=\"#000\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\" d=\"M12 23.5h11.5s0-2.057-1-4.057c-.746-1.492-4-2.5-8-4v-3s1.5-1 1.5-3c.5 0 1-2 0-2.5 0-.298 1.339-2.802 1-4.5-.5-2.5-7.5-2.5-8-.5-3 0-1 4.594-1 5-1 .5-.5 2.5 0 2.5 0 2 1.5 3 1.5 3v3c-4 1.5-7.255 2.508-8 4-1 2-1 4.057-1 4.057H12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});