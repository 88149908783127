define("ember-svg-jar/inlined/web-service-dzone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>web-service-dzone</title><path d=\"M24 7.5a2 2 0 00-3.81-.84l-7.74-2.54a1.5 1.5 0 00-2.95.38 1.48 1.48 0 00.1.5l-8.09 9.5A1.5 1.5 0 102.58 17l6.94 1.78v.19a2 2 0 004 0 2 2 0 000-.22l5.67-2.55a1.49 1.49 0 002-2.2l1-4.55A2 2 0 0024 7.5zm-11.6 9.72l-.29-.12-.27-5.1 3.68-1.51 1.4 1.74zm-2.58-5.43a1.5 1.5 0 00-.3-.39l1.08-4.11.21 4.1zm1.7-5.89l.21-.1 3.14 3.91L11.79 11zm5 4.23l3.78-1.56-2.7 3zm-.65-.81L12.53 5.2l7.41 2.43zM8.58 11H8.5A1.5 1.5 0 007 12.5a1.48 1.48 0 00.07.42l-4.46 1.84 7.21-8.47zM10 12.81l.9-.37.23 4.34-1.56-3.24a1.5 1.5 0 00.43-.73zm7.59.25l1.07 1.34a1.42 1.42 0 00-.07 1l-5.32 2.39zm2.41.44a1.48 1.48 0 00-.68.17l-1.09-1.36L21 9.24l.11.06-.92 4.22zM3 16.1V16a1.5 1.5 0 000-.31l4.71-1.94a1.49 1.49 0 00.79.25h.18l1.6 3.44a2 2 0 00-.42.44z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});