define("ember-svg-jar/inlined/medical-instrument-tweezers-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-instrument-tweezers-3</title><path d=\"M24 1.354A1.355 1.355 0 0022.647 0a1.336 1.336 0 00-.847.3L6.943 12.346a.251.251 0 01-.392-.106 3.386 3.386 0 10-3.16 4.586 3.574 3.574 0 002.136-.758l5.127-4.157a.5.5 0 01.665.031l.738.724a.5.5 0 01.038.672l-4.164 5.135a3.585 3.585 0 00-.757 2.135 3.392 3.392 0 104.586-3.159.25.25 0 01-.106-.391L23.7 2.206a1.351 1.351 0 00.3-.852zM2 13.435a1.392 1.392 0 111.391 1.391A1.393 1.393 0 012 13.435zm9.957 7.173a1.392 1.392 0 11-1.392-1.39 1.393 1.393 0 011.392 1.39zM13.993 11a.5.5 0 01-.738.042l-.29-.285a.5.5 0 01.035-.748l3.382-2.742a.249.249 0 01.351.351z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});