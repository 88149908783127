define("ember-svg-jar/inlined/performance-presentation-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>performance-presentation-cart</title><path d=\"M23.5 17.5v-14A1.5 1.5 0 0022 2h-8.75a.249.249 0 01-.25-.25V1a1 1 0 00-2 0v.75a.249.249 0 01-.25.25H2A1.5 1.5 0 00.5 3.5v14A1.5 1.5 0 002 19h8.75a.25.25 0 01.25.25v.607a.25.25 0 01-.105.2l-2.976 2.13a1 1 0 001.162 1.627l2.774-1.981a.249.249 0 01.29 0l2.774 1.981a1 1 0 001.162-1.627l-2.976-2.126a.25.25 0 01-.105-.2v-.611a.25.25 0 01.25-.25H22a1.5 1.5 0 001.5-1.5zm-13.5-1a1 1 0 111-1 1 1 0 01-1 1zm4.5 0a1 1 0 111-1 1 1 0 01-1 1zm2.719-8.784l-1.5 5a.752.752 0 01-.719.534h-5a.75.75 0 01-.712-.513l-2.5-7.5a.75.75 0 011.424-.474l.605 1.816a.249.249 0 00.237.171H16.5a.751.751 0 01.719.966z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});