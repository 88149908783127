define("ember-svg-jar/inlined/love-arrow-bow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-arrow-bow</title><path d=\"M23.707 12.707a1 1 0 000-1.414L21.414 9a1 1 0 00-1.707.707v1.043a.5.5 0 01-.5.5H3.3a.25.25 0 01-.2-.4l5.083-6.878a.5.5 0 01.5-.192 7.506 7.506 0 016.229 5.591.5.5 0 00.482.371h1.514a.5.5 0 00.486-.621 9.691 9.691 0 00-8.863-7.594C8.072 1.5 7.75 1.186 7.75.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v1.252a1.8 1.8 0 00.355 1.075.5.5 0 010 .594L.2 11.405a1 1 0 000 1.19l5.9 7.985a.5.5 0 010 .593 1.8 1.8 0 00-.355 1.075V23.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5c0-.686.322-1 .781-1.027 5.156-.363 8.664-4.288 9.152-9.272a.5.5 0 01.5-.451h1.026a.5.5 0 01.5.5v1.043a1 1 0 001.71.707zM8.684 20.218a.5.5 0 01-.5-.193l-5.09-6.876a.25.25 0 01.2-.4h11.38a.5.5 0 01.5.561 7.592 7.592 0 01-6.49 6.908z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});