define("ember-svg-jar/inlined/receipt-slip-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>receipt-slip-1</title><path d=\"M18.5.063h-13a1.5 1.5 0 00-1.5 1.5v20.913a1 1 0 001.65.759l2.218-1.9a.251.251 0 01.313-.01l3.219 2.413a1.006 1.006 0 001.2 0l3.219-2.414a.251.251 0 01.313.01l2.218 1.9a1 1 0 001.65-.758V1.563a1.5 1.5 0 00-1.5-1.5zm-2 12.5a.75.75 0 11.75-.75.75.75 0 01-.75.75zm.75 3.25a.75.75 0 11-.75-.75.75.75 0 01.75.75zm-.75-7.5a.75.75 0 11.75-.75.75.75 0 01-.75.75zM7 12.563a.75.75 0 010-1.5h6a.75.75 0 010 1.5zm5.25 3.25a.75.75 0 01-.75.75H7a.75.75 0 110-1.5h4.5a.75.75 0 01.75.75zm-6-8.25a.75.75 0 01.75-.75h5.5a.75.75 0 010 1.5H7a.75.75 0 01-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});