define("ember-svg-jar/inlined/download-button-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>download-button-alternate</title><path d=\"M18.5 6.5H16a1 1 0 00-1 1 1 1 0 001 1h2.25a.25.25 0 01.25.25v12.5a.25.25 0 01-.25.25H5.75a.25.25 0 01-.25-.25V8.749a.25.25 0 01.25-.25H8a1 1 0 001-1 1 1 0 00-1-1H5.5a2 2 0 00-2 2v13a2 2 0 002 2h13a2 2 0 002-2V8.5a2 2 0 00-2-2z\"/><path d=\"M11.293 16.706a1 1 0 001.414 0l3.5-3.5A1 1 0 0015.5 11.5h-1.75a.25.25 0 01-.25-.25V2a1.5 1.5 0 00-3 0v9.25a.25.25 0 01-.25.25H8.5a1 1 0 00-.707 1.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});