define("ember-svg-jar/inlined/safety-electricity-danger-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-electricity-danger_1</title><circle cx=\"5.625\" cy=\"3\" r=\"2.5\"/><path d=\"M3.375 15.092a.25.25 0 01.25.25V22.5a1 1 0 001 1h2a1 1 0 001-1v-7.158a.25.25 0 01.25-.25h.75a1 1 0 001-1v-5a2 2 0 00-2-2h-4a2 2 0 00-2 2v5a1 1 0 001 1zM20.375 3.25a2.766 2.766 0 00-.064-.592.75.75 0 10-1.465.321 1.307 1.307 0 01.029.271.75.75 0 001.5 0zM18.024 2.062a.75.75 0 00.946-.482.751.751 0 00-.483-.945A2.681 2.681 0 0017.9.514a.736.736 0 00-.818.674.749.749 0 00.674.818 1.224 1.224 0 01.268.056zM16.493 2.743a1.349 1.349 0 01.138-.241.75.75 0 10-1.215-.879 2.812 2.812 0 00-.294.513.75.75 0 001.371.607zM15.807 5.289a.75.75 0 001-1.121 1.287 1.287 0 01-.184-.2.75.75 0 10-1.215.879 2.8 2.8 0 00.399.442zM18.239 5.89a.756.756 0 00.233-.037 2.782 2.782 0 00.544-.245.75.75 0 00-.76-1.293 1.31 1.31 0 01-.249.112.75.75 0 00.232 1.463zM21.125 15.342a.75.75 0 00-.75-.75h-.75a.75.75 0 000 1.5h.75a.75.75 0 00.75-.75zM15.625 14.592h-.75a.75.75 0 100 1.5h.75a.75.75 0 000-1.5zM13.082 8.8a2.719 2.719 0 00-.207 1.047.75.75 0 001.5 0 1.227 1.227 0 01.094-.475.75.75 0 00-1.387-.572zM20.776 9.354a1.247 1.247 0 01.1.488.75.75 0 101.5 0 2.728 2.728 0 00-.217-1.072.75.75 0 10-1.381.584zM20.375 17.592a.75.75 0 00-1.5 0v.658a.75.75 0 001.5 0zM16.375 17.592a.75.75 0 00-1.5 0v.658a.75.75 0 001.5 0zM19.625 22a.75.75 0 00.75-.75v-.658a.75.75 0 00-1.5 0v.658a.75.75 0 00.75.75zM17.343 23.5H18a.75.75 0 100-1.5h-.658a.75.75 0 000 1.5zM15.625 19.842a.75.75 0 00-.75.75v.658a.75.75 0 001.5 0v-.658a.75.75 0 00-.75-.75zM13.625 14a.75.75 0 00.75-.75v-.75a.75.75 0 00-1.5 0v.754a.75.75 0 00.75.746zM21.626 14.026a.75.75 0 00.749-.751v-.684a.75.75 0 00-.75-.749.75.75 0 00-.749.751v.684a.75.75 0 00.75.749zM17.11 7.75a.75.75 0 00-.75-.75h-.652a.75.75 0 100 1.5h.652a.75.75 0 00.75-.75zM19.646 8.5a.75.75 0 000-1.5h-.733a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});