define("ember-svg-jar/inlined/fishing-bait-fish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fishing-bait-fish</title><path d=\"M8.816 21.993c4.054 0 9.248-1.295 10.941-3.14a.544.544 0 01.674-.1l2.751 1.586a.545.545 0 00.783-.664l-.831-2.216A2.185 2.185 0 0123.107 16l.809-2.426a.546.546 0 00-.858-.6l-2.481 1.982a.543.543 0 01-.747-.065C18.147 13 12.936 11.663 8.816 11.663a13.7 13.7 0 00-8.138 2.279.548.548 0 00-.069.8l2.469 2.716a.545.545 0 01-.4.912H.545a.545.545 0 00-.377.939c1.656 1.581 4.906 2.684 8.648 2.684zm-3.423-7.416a1 1 0 11-1 1 1 1 0 011-1zM6 8.007h9a1 1 0 001-1v-.25a.25.25 0 01.25-.25h5.5a2.25 2.25 0 100-4.5H1.908a1.5 1.5 0 00-1.3 2.244C1.505 5.825 3.88 8.007 6 8.007zm7.75-4a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H6a4.854 4.854 0 01-2.74-1.574.25.25 0 01.177-.426z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});