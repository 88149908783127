define("ember-svg-jar/inlined/fox-body-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fox-body-alternate</title><path d=\"M5.629 9.9a10.551 10.551 0 001.936-3.407v-.011a9.046 9.046 0 00-.1-5.8.993.993 0 00-1.206-.644A8.5 8.5 0 00.737 11.7a8.393 8.393 0 00.842 1.477A6.025 6.025 0 015.629 9.9zm.222-7.6a7.054 7.054 0 01-.01 2.938 3.037 3.037 0 01-2.269-1.233A6.5 6.5 0 015.851 2.3zM23.793 8.992a1.522 1.522 0 00-1.3-.749h-.538A4.126 4.126 0 0019 5.342v-2.6a.5.5 0 00-.5-.5c-3.308 0-5.5 4.625-5.5 6.491 0 .025-.161 2.509-6 2.509a4.5 4.5 0 00-4.5 4.5v4.463l-.247 2.7a1 1 0 001.992.182l.238-2.587a4.549 4.549 0 002.264-1.747H13.5V23a1 1 0 002 0v-4.477c2.026-.638 3.5-2.046 3.5-4.28a2 2 0 012-2 3.111 3.111 0 002.817-1.808 1.445 1.445 0 00-.024-1.443zM19 9.239a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});