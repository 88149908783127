define("ember-svg-jar/inlined/bread-baguette", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bread-baguette</title><path d=\"M10.783 16.621a3.451 3.451 0 01-.057-.621 5.6 5.6 0 015.691-5.5h.372a1 1 0 00.708-.293l4.834-4.841A3.142 3.142 0 1017.887.921l-2.2 2.2a.5.5 0 00-.006.7c.992 1.023 2.144 1.619 1.244 2.44-.583.532-1.786.127-2.888-.834a.5.5 0 00-.682.024l-2.31 2.31a.5.5 0 000 .7c.984 1.006 2.108 1.6 1.216 2.412-.579.528-1.767.133-2.862-.811a.5.5 0 00-.679.026l-2.033 2.033a.5.5 0 00-.006.7c.991 1.024 2.145 1.619 1.244 2.441-.583.532-1.786.127-2.888-.834a.5.5 0 00-.681.023l-2.193 2.195a.5.5 0 00.354.853h7.488a.5.5 0 00.354-.146l.286-.287a.5.5 0 00.138-.445z\"/><path d=\"M20.719 12.154a.25.25 0 00-.309.3 5.21 5.21 0 01-.068 2.84.75.75 0 11-1.425-.467 3.58 3.58 0 00-.133-2.5.5.5 0 00-.47-.329H17.4a.5.5 0 00-.489.606 5.188 5.188 0 01-.1 2.689.75.75 0 01-1.426-.467 3.694 3.694 0 00-.084-2.425.251.251 0 00-.321-.159A4.016 4.016 0 0012.226 16a2.05 2.05 0 002.1 2h7.333a2.05 2.05 0 002.095-2 4.034 4.034 0 00-3.035-3.846zM.431 20.573A1 1 0 011.25 19h21a1 1 0 01.819 1.574l-1.2 1.72A4 4 0 0118.588 24H4.912a4 4 0 01-3.277-1.706z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});