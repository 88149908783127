define("ember-svg-jar/inlined/arrow-button-circle-left-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-circle-left-1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm3.223 8.255a.5.5 0 01-.193.395l-4.053 3.15a.249.249 0 000 .394l4.053 3.156a.5.5 0 01.193.395v2.687a.5.5 0 01-.82.385l-7.719-6.433a.5.5 0 010-.768L14.4 5.183a.5.5 0 01.82.385z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});