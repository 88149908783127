define("ember-svg-jar/inlined/history-babushka-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-babushka-1</title><path d=\"M15.132 7.412a.25.25 0 00.289-.19A3.5 3.5 0 0012.5 3.038a3.145 3.145 0 00-1 0 3.5 3.5 0 00-2.921 4.184.25.25 0 00.289.19 4.3 4.3 0 001.882-.854 4.468 4.468 0 001.039-1.137.25.25 0 01.422 0 4.468 4.468 0 001.039 1.137 4.3 4.3 0 001.882.854zM19.422 15.073a.251.251 0 00-.179-.073h-.394a8.554 8.554 0 01-6.649-3.341.252.252 0 00-.394 0A8.552 8.552 0 015.151 15h-.394a.249.249 0 00-.25.254 20.782 20.782 0 00.622 4.193c.1.46.2.908.287 1.351a.25.25 0 00.246.2h12.676a.251.251 0 00.246-.2c.086-.443.185-.891.287-1.351a20.782 20.782 0 00.622-4.193.249.249 0 00-.071-.181zM12 19.5a1 1 0 111-1 1 1 0 01-1 1zm0-3.5a1 1 0 111-1 1 1 0 01-1 1zM18.11 22H5.89a.251.251 0 00-.249.277c.012.108.022.215.03.323A1.558 1.558 0 007.259 24h9.482a1.558 1.558 0 001.588-1.4c.008-.108.018-.216.03-.323A.249.249 0 0018.11 22z\"/><path d=\"M4.841 14h.31a7.514 7.514 0 005.711-2.781.25.25 0 00-.122-.4 4.5 4.5 0 112.52 0 .249.249 0 00-.122.4A7.514 7.514 0 0018.849 14h.31a.251.251 0 00.248-.281 9.252 9.252 0 00-.24-1.255 17.546 17.546 0 01-.667-4.557V6A6.271 6.271 0 0012 0a6.271 6.271 0 00-6.5 6v1.907a17.546 17.546 0 01-.664 4.555 9.252 9.252 0 00-.24 1.255.251.251 0 00.245.283z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});