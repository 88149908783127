define("ember-svg-jar/inlined/graphic-tablet-draw-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>graphic-tablet-draw-1</title><path d=\"M21 8a1 1 0 011 1v12a1 1 0 01-1 1H3a1 1 0 01-1-1V9a1 1 0 011-1h8.776a.251.251 0 00.177-.073l1.5-1.5A.25.25 0 0013.276 6H3a3 3 0 00-3 3v12a3 3 0 003 3h18a3 3 0 003-3V9a3 3 0 00-1.88-2.782.25.25 0 00-.271.055l-1.3 1.3a.25.25 0 00.177.427z\"/><path d=\"M18.25 14A1.252 1.252 0 0017 12.75h-.5a.75.75 0 000 1.5.25.25 0 01.25.25v4a.25.25 0 01-.25.25h-8a.25.25 0 01-.25-.25v-1.25H12A1.252 1.252 0 0013.25 16v-1.55a.25.25 0 00-.326-.238l-1 .321a.25.25 0 00-.174.238v.729a.25.25 0 01-.25.25h-6a.25.25 0 01-.25-.25v-4a.25.25 0 01.25-.25h4.048a.251.251 0 00.236-.169l.344-1a.25.25 0 00-.236-.331H5A1.252 1.252 0 003.75 11v5A1.252 1.252 0 005 17.25h1.75V19A1.252 1.252 0 008 20.25h9A1.252 1.252 0 0018.25 19z\"/><path d=\"M21.586.415l-9.917 9.917a.494.494 0 00-.121.195l-.648 1.939a.5.5 0 00.632.633l1.94-.647a.506.506 0 00.2-.12l9.917-9.917a1.414 1.414 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});