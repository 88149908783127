define("ember-svg-jar/inlined/single-man-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-search</title><path d=\"M23.414 23.414a2 2 0 000-2.828l-4.069-4.069a.25.25 0 01-.033-.313 10.508 10.508 0 10-3.112 3.108.249.249 0 01.313.033l4.069 4.069a2 2 0 002.832 0zM10.5 2A8.5 8.5 0 112 10.5 8.51 8.51 0 0110.5 2z\"/><path d=\"M5.285 15.736a.491.491 0 00.427.264H15a.491.491 0 00.427-.262.562.562 0 00.006-.527A5.8 5.8 0 0010.363 12a5.818 5.818 0 00-5.081 3.21.566.566 0 00.003.526zM10.354 11A3.75 3.75 0 0014.1 7.25a3.75 3.75 0 00-7.5 0A3.749 3.749 0 0010.354 11zM12.1 7.25a1.75 1.75 0 01-3.5 0 1.683 1.683 0 01.037-.35.5.5 0 01.663-.367A7.834 7.834 0 0011.851 7a.254.254 0 01.253.252z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});