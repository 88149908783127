define("ember-svg-jar/inlined/vintage-woman-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-woman-1</title><path d=\"M1.837 7.622A46.906 46.906 0 0012 8.73a46.906 46.906 0 0010.163-1.108.75.75 0 00-.326-1.464 44.975 44.975 0 01-3.449.609l-.529-3.755A3.516 3.516 0 0014.394 0H9.606a3.516 3.516 0 00-3.465 3.012l-.529 3.755a46.3 46.3 0 01-3.449-.609.75.75 0 00-.326 1.464zM11.934 13.75a1.8 1.8 0 01-.819-.18.75.75 0 00-.711 1.321 3.317 3.317 0 001.528.359h.006a3.255 3.255 0 001.526-.36.75.75 0 10-.715-1.318 1.7 1.7 0 01-.815.178zM8.381 9.709a.751.751 0 101.006 1.114.628.628 0 01.625 0 .751.751 0 001.006-1.114 2.094 2.094 0 00-2.637 0zM13.952 10.823a.628.628 0 01.625 0 .751.751 0 001.006-1.114 2.094 2.094 0 00-2.637 0 .751.751 0 001.006 1.114z\"/><path d=\"M2.847 15.432a.5.5 0 00-.327.337.5.5 0 00.1.46 4.747 4.747 0 004.362 1.563 2.1 2.1 0 001.338-1.209 6.484 6.484 0 003.663 1.667 6.541 6.541 0 003.746-1.738 2.117 2.117 0 001.378 1.28 4.752 4.752 0 004.361-1.563.506.506 0 00.1-.46.5.5 0 00-.328-.337c-1.38-.442-2.031-2.086-2.34-3.432a3.144 3.144 0 00.35-1.361 1.885 1.885 0 00-.936-1.809.75.75 0 00-.669 1.342.616.616 0 01.105.439 1.279 1.279 0 01-.559 1.1 1.75 1.75 0 00-1 1.3c-.377 2.249-3.175 3.739-4.208 3.739s-3.83-1.49-4.207-3.738a1.747 1.747 0 00-1-1.306 1.275 1.275 0 01-.559-1.1.626.626 0 01.1-.439.75.75 0 00-.669-1.342 1.886 1.886 0 00-.935 1.81 3.109 3.109 0 00.435 1.5c-.325 1.325-.976 2.871-2.301 3.297zM23.847 23.375a4.491 4.491 0 00-2.757-3.084l-4.4-1.755a.5.5 0 00-.684.419l-.409 4.5a.5.5 0 00.5.545h7.271a.5.5 0 00.485-.625zM12.464 20.564a.521.521 0 00-.928 0l-1.1 2.75A.5.5 0 0010.9 24h2.2a.5.5 0 00.463-.686zM7.762 18.574a.5.5 0 00-.448-.038L2.92 20.287a4.5 4.5 0 00-2.767 3.088.5.5 0 00.485.625h7.271a.5.5 0 00.5-.545L8 18.955a.5.5 0 00-.238-.381z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});