define("ember-svg-jar/inlined/money-atm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>money-atm</title><path d=\"M22.5 2.25h-21A1.5 1.5 0 000 3.75v5.5a1.5 1.5 0 001.5 1.5h2.223a.249.249 0 00.241-.184c.282-1.032.6-2.028.957-2.978a.25.25 0 00-.234-.338H3.5a1 1 0 010-2h17a1 1 0 010 2h-1.193a.25.25 0 00-.234.338c.356.949.675 1.946.957 2.978a.25.25 0 00.241.184H22.5a1.5 1.5 0 001.5-1.5v-5.5a1.5 1.5 0 00-1.5-1.5z\"/><path d=\"M17.06 7.909a.251.251 0 00-.233-.159h-9.66a.251.251 0 00-.233.159A39.057 39.057 0 004.5 21.244a.5.5 0 00.5.506h13.994a.5.5 0 00.5-.506A39.037 39.037 0 0017.06 7.909zM13.075 18.2a.249.249 0 00-.2.245v.308a.75.75 0 01-1.5 0V18.5a.25.25 0 00-.25-.25h-.5a.75.75 0 010-1.5h2.033a.592.592 0 00.221-1.141l-2.065-.826a2.084 2.084 0 01.361-3.98.249.249 0 00.2-.245v-.308a.75.75 0 011.5 0v.25a.25.25 0 00.25.25h.5a.75.75 0 010 1.5h-2.033a.592.592 0 00-.221 1.141l2.065.826a2.084 2.084 0 01-.361 3.98z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});