define("ember-svg-jar/inlined/famous-character-mr-potato-head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character-mr-potato-head</title><path d=\"M20.5 7.5a3.441 3.441 0 00-.638.059.5.5 0 01-.566-.333L19 6.345a.264.264 0 01.036-.236A.261.261 0 0119.25 6a.75.75 0 000-1.5h-.445a.5.5 0 01-.485-.38C17.9 2.446 16.524 0 12 0S6.1 2.446 5.68 4.121a.5.5 0 01-.48.379h-.45a.75.75 0 000 1.5.261.261 0 01.212.108.264.264 0 01.038.237l-.3.881a.5.5 0 01-.566.333A3.441 3.441 0 003.5 7.5a3.5 3.5 0 00-1.111 6.813.5.5 0 01.34.47 9.271 9.271 0 0018.542 0 .5.5 0 01.34-.47A3.5 3.5 0 0020.5 7.5zM3.09 12.119a.25.25 0 01-.374.152 1.495 1.495 0 01.9-2.765.249.249 0 01.213.328c-.71 2.145-.668 2.002-.739 2.285zm14.823 6.854a7.289 7.289 0 01-12.828-6.567l2.021-6.063A.5.5 0 017.581 6h8.838a.5.5 0 01.475.342l2.021 6.063a7.307 7.307 0 01-1.002 6.568zm3.371-6.7a.249.249 0 01-.225.019.253.253 0 01-.149-.171c-.083-.33-.78-2.39-.744-2.285a.249.249 0 01.213-.328 1.495 1.495 0 01.9 2.765z\"/><path d=\"M9.251 11.5A1.251 1.251 0 108 10.251 1.251 1.251 0 009.251 11.5zM14.751 11.5a1.251 1.251 0 10-1.251-1.249 1.251 1.251 0 001.251 1.249zM15.406 16.065a.984.984 0 01-.367-.472l-.875-2.349a2.313 2.313 0 00-4.323-.008L8.957 15.6a1 1 0 01-.366.471A5.987 5.987 0 007.207 17.4a.987.987 0 00-.092 1.043 1 1 0 00.9.56h7.97a1 1 0 00.9-.56.987.987 0 00-.092-1.043 6.034 6.034 0 00-1.387-1.335zm-2.33-1.093a.248.248 0 01-.23.091 5.712 5.712 0 00-1.692 0 .25.25 0 01-.231-.091.247.247 0 01-.039-.244l.364-.972a.812.812 0 011.508.008l.36.964a.252.252 0 01-.04.244z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});