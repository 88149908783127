define("ember-svg-jar/inlined/web-service-amazon-web-services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>web-service-amazon-web-services</title><path d=\"M.5 6.27a.5.5 0 01.66-.48l9 3a.5.5 0 01.34.47v13.51a.5.5 0 01-.66.47L.9 20.13a.5.5 0 01-.34-.47zm13 16.55a.5.5 0 00.67.47l9-3.15a.5.5 0 00.33-.47V6.35a.5.5 0 00-.65-.48l-9 2.86a.5.5 0 00-.35.48zM11.85 6.52a.5.5 0 00.27 0l8.68-2.38a.5.5 0 000-1L12.12.7a.5.5 0 00-.27 0L3.29 3a.5.5 0 000 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});