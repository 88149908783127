define("ember-svg-jar/inlined/car-dashboard-window-rear-wipe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-dashboard-window-rear-wipe</title><path d=\"M23.293 11.579A2 2 0 0022.82 8.4a24.592 24.592 0 00-8.189-2.414 1 1 0 10-.238 1.986 22.94 22.94 0 017.179 2.035.251.251 0 01.065.4l-3.269 3.267a.25.25 0 01-.265.056 20.943 20.943 0 00-3.413-.83 1 1 0 00-.354 1.969 19.063 19.063 0 013.156.774 2 2 0 002.175-.436zM12 16.875a1.181 1.181 0 00-.146.011.251.251 0 01-.234-.1l-1.415-1.956a.25.25 0 01.013-.309.973.973 0 00.227-.811 1 1 0 00-1.162-.81l-.258.048a.247.247 0 01-.249-.1l-1.811-2.5a1 1 0 10-1.62 1.172l1.2 1.652a.251.251 0 01-.13.386 7.924 7.924 0 00-.514.172.25.25 0 01-.265-.057l-3.273-3.268a.25.25 0 01.065-.4A22.956 22.956 0 019.56 7.977a1 1 0 10-.241-1.986A24.581 24.581 0 001.181 8.4a2 2 0 00-.474 3.18l3.625 3.62a2 2 0 002.175.436 9.735 9.735 0 011.336-.4.252.252 0 01.261.1l1.705 2.358a.251.251 0 010 .3 2.7 2.7 0 00-.556 1.636A2.75 2.75 0 1012 16.875zM11.991 4.125a1 1 0 001-1v-.5a1 1 0 00-2 0v.5a1 1 0 001 1zM11.991 7.125a1 1 0 001-1v-.5a1 1 0 00-2 0v.5a1 1 0 001 1z\"/><path d=\"M11.991 10.125a1 1 0 001-1v-.5a1 1 0 00-2 0v.5a1 1 0 001 1zM11.991 13.125a1 1 0 001-1v-.5a1 1 0 00-2 0v.5a1 1 0 001 1zM11.991 16.125a1 1 0 001-1v-.5a1 1 0 00-2 0v.5a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});