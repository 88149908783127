define("ember-svg-jar/inlined/direction-button-arrows", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>direction-button-arrows</title><path d=\"M23.6 11.187l-4-3a1 1 0 00-1.6.8v1.25a.25.25 0 01-.25.25h-4a.25.25 0 01-.25-.25v-4a.25.25 0 01.25-.25H15a1 1 0 00.8-1.6l-3-4a1.036 1.036 0 00-1.6 0l-3 4a1 1 0 00.8 1.6h1.25a.25.25 0 01.25.25v4a.25.25 0 01-.25.25h-4a.25.25 0 01-.25-.25v-1.25a1 1 0 00-1.6-.8l-4 3a1 1 0 000 1.6l4 3a1 1 0 001.6-.8v-1.25a.25.25 0 01.25-.25h4a.25.25 0 01.25.25v4a.25.25 0 01-.25.25H9a1 1 0 00-.8 1.6l3 4a1 1 0 001.6 0l3-4a1 1 0 00-.8-1.6h-1.25a.25.25 0 01-.25-.25v-4a.25.25 0 01.25-.25h4a.25.25 0 01.25.25v1.25a1 1 0 001.6.8l4-3a1 1 0 000-1.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});