define("ember-svg-jar/inlined/antique-sword-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>antique-sword-1</title><path d=\"M23.051.308l-4.6.418-.283.134L6.961 12.088a.479.479 0 00-.085.584c.353.578.987 2.049.487 2.549l-.51.511s-.2.191-.336.021a7.81 7.81 0 01-.9-1.563 1 1 0 10-1.841.781 9.914 9.914 0 001.307 2.218.243.243 0 01-.018.325l-1.7 1.7a.242.242 0 01-.235.06 2.242 2.242 0 00-2.161 3.755 2.195 2.195 0 003.182 0 2.24 2.24 0 00.574-2.165.241.241 0 01.059-.236l1.7-1.7a.242.242 0 01.325-.017 9.856 9.856 0 001.814 1.149 1 1 0 10.873-1.8 7.566 7.566 0 01-1.231-.768.212.212 0 01-.018-.328l.53-.53c.5-.5 1.961.123 2.539.477a.481.481 0 00.584-.085L23.155 5.75l.079-.167.435-4.657a.5.5 0 00-.618-.618z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});