define("ember-svg-jar/inlined/single-neutral-actions-block.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-block</title><path d=\"M11.846 11.843a7.249 7.249 0 01.792-.679.25.25 0 00-.061-.434c-.342-.129-.7-.262-1.078-.4l-.628-.23a.6.6 0 01-.137-.311 2.576 2.576 0 01.057-1.3 5.618 5.618 0 001.466-4.314C12.257 1.757 10.678 0 8.5 0S4.745 1.757 4.745 4.175A5.6 5.6 0 006.2 8.472a2.456 2.456 0 01.072 1.316.605.605 0 01-.135.309l-.631.232c-2.447.9-4.215 1.55-4.754 2.626A9.173 9.173 0 000 16.5a.5.5 0 00.5.5h8.8a.251.251 0 00.249-.227 7.949 7.949 0 012.297-4.93zM23.832 16A6.422 6.422 0 0019 11.169 6.513 6.513 0 0011.169 19 6.418 6.418 0 0016 23.832a6.76 6.76 0 001.49.167 6.5 6.5 0 006.341-8zm-7.391 5.879a4.111 4.111 0 01-1.051-.4.251.251 0 01-.058-.4l5.752-5.751a.252.252 0 01.213-.071.246.246 0 01.184.129 4.119 4.119 0 01.4 1.05 4.513 4.513 0 01-5.44 5.44zM17.516 13a4.763 4.763 0 011.043.116 4.134 4.134 0 011.054.4.251.251 0 01.128.184.248.248 0 01-.07.213l-5.753 5.757a.252.252 0 01-.213.071.248.248 0 01-.184-.129 4.111 4.111 0 01-.4-1.054A4.5 4.5 0 0117.516 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});