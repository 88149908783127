define("ember-svg-jar/inlined/road-sign-speed-limit-80-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-speed-limit-80-alternate</title><path d=\"M16.043 7.2a3.6 3.6 0 00-3.6 3.6v2.6a3.6 3.6 0 107.2 0v-2.6a3.605 3.605 0 00-3.6-3.6zm1.6 6.2a1.6 1.6 0 11-3.2 0v-2.6a1.6 1.6 0 113.2 0zM10.472 10.584a.25.25 0 01-.078-.285 2.233 2.233 0 00.149-.8 2.3 2.3 0 10-4.6 0 2.233 2.233 0 00.15.8.25.25 0 01-.078.285 3.6 3.6 0 104.457 0zM8.244 9.2a.3.3 0 11-.3.3.3.3 0 01.3-.3zm0 5.8a1.6 1.6 0 111.6-1.6 1.6 1.6 0 01-1.6 1.6z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});