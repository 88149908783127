define("ember-svg-jar/inlined/upload-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>upload-square</title><path d=\"M22.286 24A1.716 1.716 0 0024 22.285V1.714A1.716 1.716 0 0022.286 0H1.714A1.716 1.716 0 000 1.714v20.571A1.716 1.716 0 001.714 24zM9.551 8.816h4.9a.49.49 0 01.49.49v4.158a.25.25 0 00.25.25H16.9a.844.844 0 01.633 1.529l-4.492 4.492a1.473 1.473 0 01-2.078 0l-4.494-4.492a.844.844 0 01.631-1.529h1.711a.25.25 0 00.25-.25V9.306a.49.49 0 01.49-.49zm-.49-2.449V3.918a.49.49 0 01.49-.49h4.9a.49.49 0 01.49.49v2.449a.49.49 0 01-.49.49h-4.9a.49.49 0 01-.49-.49z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});