define("ember-svg-jar/inlined/profile-woman-cash-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>profile-woman-cash-message</title><path d=\"M1.7 21.063a.251.251 0 01.051.45l-1.028.638a1 1 0 001.056 1.7L4.751 22v-1.863a.249.249 0 00-.1-.2 2.8 2.8 0 01-.92-2.36c-.01-.812 0-1.2 0-1.395a.249.249 0 01.171-.227 6.037 6.037 0 002.649-1.711.251.251 0 01.39 0 5.7 5.7 0 002.646 1.71.249.249 0 01.175.227c.009.2.017.593.008 1.394a2.8 2.8 0 01-.92 2.36.249.249 0 00-.1.2V22l2.973 1.849a1 1 0 101.056-1.7l-1.1-.685a.25.25 0 01.051-.448 6.093 6.093 0 001.3-.6.5.5 0 00.216-.361.506.506 0 00-.132-.4c-.017-.018-.859-.965-.859-3.652a5.207 5.207 0 00-5.5-5.5A5.207 5.207 0 001.25 16c0 2.7-.85 3.644-.853 3.646a.5.5 0 000 .708 3.471 3.471 0 001.303.709zM22.25 0h-10a1.5 1.5 0 00-1.5 1.5v8a1.5 1.5 0 001.5 1.5h.25a.25.25 0 01.25.25v2.25a.5.5 0 00.825.38l3.289-2.819a.246.246 0 01.163-.061h5.223a1.5 1.5 0 001.5-1.5v-8a1.5 1.5 0 00-1.5-1.5zm-6.167 4.875h2.334a1.792 1.792 0 010 3.584h-.292a.249.249 0 00-.25.25V9a.625.625 0 01-1.25 0v-.291a.249.249 0 00-.25-.25h-1.458a.625.625 0 110-1.25h3.5a.542.542 0 000-1.084h-2.334a1.792 1.792 0 010-3.583h.292a.25.25 0 00.25-.25V2a.625.625 0 011.25 0v.292a.25.25 0 00.25.25h1.458a.625.625 0 010 1.25h-3.5a.542.542 0 000 1.083z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});