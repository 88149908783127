define("ember-svg-jar/inlined/unicycle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>unicycle</title><path d=\"M13.208 9.105A.249.249 0 0113 8.859V5.75a.25.25 0 01.25-.25h4a1.786 1.786 0 002-1.75 1.786 1.786 0 00-2-1.75H13.5A7.11 7.11 0 0110.208.727L9.914.555A4.088 4.088 0 007.5 0h-2a.75.75 0 00-.75.75A4.756 4.756 0 009.5 5.5h1.25a.25.25 0 01.25.25v3.109a.251.251 0 01-.209.247 7.5 7.5 0 102.419 0zM12 21.25a4.748 4.748 0 01-1.322-9.308.25.25 0 01.32.24v2.461a.249.249 0 01-.111.208 2 2 0 102.222 0 .253.253 0 01-.109-.208V12.18a.251.251 0 01.32-.24A4.748 4.748 0 0112 21.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});