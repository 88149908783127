define("ember-svg-jar/inlined/auto-pilot-car-radius", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>auto-pilot-car-radius</title><path d=\"M19.7 11.909a.954.954 0 00-.581-.037.149.149 0 00-.116.145 3.069 3.069 0 01-.311 1.332.25.25 0 00.076.309.87.87 0 00.2.114c2.289.894 3.03 1.841 3.03 2.16 0 1.015-4.223 3.4-10 3.4s-10-2.386-10-3.4c0-.319.741-1.266 3.03-2.16a.87.87 0 00.205-.114.25.25 0 00.076-.309A3.069 3.069 0 015 12.017a.151.151 0 00-.116-.146.954.954 0 00-.581.038C1.528 12.992 0 14.42 0 15.932c0 3.091 6.335 5.4 12 5.4s12-2.31 12-5.4c0-1.512-1.528-2.94-4.3-4.023zM4.8 11.667h.046a.217.217 0 00.215-.181 3.131 3.131 0 016.174-.024.248.248 0 00.245.205h1.04a.248.248 0 00.245-.205 3.131 3.131 0 016.174.024.218.218 0 00.215.181h.046a1.3 1.3 0 001.3-1.3V9.074a2.276 2.276 0 00-1.266-2.048l-1.987-.994a.243.243 0 01-.112-.112l-.973-1.946a2.349 2.349 0 00-2.114-1.307h-4.1a2.349 2.349 0 00-2.11 1.307L6.865 5.92a.248.248 0 01-.112.112L4.7 7.058A2.16 2.16 0 003.5 9v1.368a1.3 1.3 0 001.3 1.299z\"/><circle cx=\"8.15\" cy=\"12.017\" r=\"1.75\"/><circle cx=\"15.85\" cy=\"12.017\" r=\"1.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});