define("ember-svg-jar/inlined/shopping-bag-woman-carry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-bag-woman-carry</title><path d=\"M12.2 3.156a.251.251 0 00-.3 0 4.61 4.61 0 01-2.8.8 3.086 3.086 0 005.919 0 4.662 4.662 0 01-2.819-.8zM11.634 2.05a.431.431 0 01.418-.218.5.5 0 01.414.221 3.055 3.055 0 002.548.9.12.12 0 00.088-.039.118.118 0 00.031-.091 3.086 3.086 0 00-6.151 0 .123.123 0 00.031.09.117.117 0 00.088.039 3 3 0 002.533-.902z\"/><path d=\"M16.059 12.129a.251.251 0 00.045-.224l-.979-3.412a3.119 3.119 0 00-3.067-2.3 3.12 3.12 0 00-3.067 2.3c-.366 1.281-.73 2.562-1 3.529a.252.252 0 00.039.216 3.727 3.727 0 01.707 1.841.251.251 0 00.249.226H9.6a.25.25 0 01.245.2l.619 3.092a.5.5 0 00.49.4h2.218a.5.5 0 00.49-.4l.619-3.092a.25.25 0 01.245-.2h.5a.251.251 0 00.249-.226 3.739 3.739 0 01.784-1.95zM7.885 16a.134.134 0 01-.135-.135v-1.412a2.75 2.75 0 00-5.5 0v1.412a.134.134 0 01-.135.135 1.157 1.157 0 00-1.146 1.013L.259 22.7A1.156 1.156 0 001.405 24H8.6a1.156 1.156 0 001.146-1.3l-.71-5.687A1.157 1.157 0 007.885 16zM3.75 14.453a1.25 1.25 0 012.5 0v1.3A.25.25 0 016 16H4a.25.25 0 01-.25-.25zM23.741 22.7l-.71-5.687A1.157 1.157 0 0021.885 16a.134.134 0 01-.135-.135v-1.412a2.75 2.75 0 00-5.5 0v1.412a.134.134 0 01-.135.135 1.157 1.157 0 00-1.146 1.013l-.71 5.687a1.156 1.156 0 001.146 1.3H22.6a1.156 1.156 0 001.146-1.3zm-5.991-8.247a1.25 1.25 0 012.5 0v1.3A.25.25 0 0120 16h-2a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});