define("ember-svg-jar/inlined/ticket-exchange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ticket-exchange</title><path d=\"M15 12.508H1a1 1 0 00-1 1v2.068a.489.489 0 00.37.477 1.5 1.5 0 011.13 1.455 1.5 1.5 0 01-1.13 1.454.49.49 0 00-.37.477v2.069a1 1 0 001 1h14a1 1 0 001-1v-2.069a.49.49 0 00-.37-.477 1.5 1.5 0 010-2.909.489.489 0 00.37-.477v-2.068a1 1 0 00-1-1zm-10.75 8a.75.75 0 11.75-.75.75.75 0 01-.75.75zm0-2.5a.75.75 0 11.75-.75.75.75 0 01-.75.75zm0-2.5a.75.75 0 11.75-.75.75.75 0 01-.75.75zM1 8.508a1 1 0 001-1 2.98 2.98 0 011.25-2.421c.08-.058.25-.073.25.042v.379a.5.5 0 00.309.461.927.927 0 00.545-.108l2-2a.5.5 0 000-.707l-2-2a.5.5 0 00-.854.354v.964a.375.375 0 01-.25.377A4.986 4.986 0 000 7.508a1 1 0 001 1zM23 15.492a1 1 0 00-1 1 2.98 2.98 0 01-1.25 2.421c-.08.058-.25.073-.25-.041v-.38a.5.5 0 00-.309-.461.927.927 0 00-.545.108l-2 2a.5.5 0 000 .707l2 2a.5.5 0 00.854-.354v-.964a.376.376 0 01.25-.377A4.986 4.986 0 0024 16.492a1 1 0 00-1-1zM18.146 13.008H22.5a1 1 0 001-1V9.939a.49.49 0 00-.37-.477 1.5 1.5 0 010-2.909.49.49 0 00.37-.477V4.008a1 1 0 00-1-1h-14a1 1 0 00-1 1v2.068a.49.49 0 00.37.477 1.5 1.5 0 010 2.909.49.49 0 00-.37.477v.319a.25.25 0 00.25.25H15a3 3 0 012.91 2.306.245.245 0 00.236.194zm-6.4-4.5a.75.75 0 11.75-.75.75.75 0 01-.746.75zm0-2.5a.75.75 0 11.75-.75.75.75 0 01-.746.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});