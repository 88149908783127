define("ember-svg-jar/inlined/professions-woman-chef-3.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-woman-chef-3</title><path d=\"M7.12 20.194l-4.5 1.59a4.691 4.691 0 00-2 1.393A.5.5 0 001 24h7.25a.5.5 0 00.5-.5v-2.111a.5.5 0 00-.234-.424 6.179 6.179 0 01-.892-.669.5.5 0 00-.504-.102zM23.382 23.177a4.691 4.691 0 00-2-1.393l-4.5-1.59a.5.5 0 00-.5.1A6.482 6.482 0 0112 22a5.735 5.735 0 01-1.15-.123.5.5 0 00-.6.49V23.5a.5.5 0 00.5.5H23a.5.5 0 00.382-.823zM5.371 9.2a.5.5 0 00.692.462 14.988 14.988 0 0111.616.011.5.5 0 00.469-.045.5.5 0 00.223-.416V6.871A2.75 2.75 0 0016.621 2a2.671 2.671 0 00-1.159.262 3.983 3.983 0 00-7.184 0A2.679 2.679 0 007.121 2a2.75 2.75 0 00-1.75 4.871zM10.4 16.589a3.431 3.431 0 003.062 0 .75.75 0 10-.715-1.318 1.969 1.969 0 01-1.632 0 .75.75 0 00-.715 1.318zM11.072 12.468a.751.751 0 00-.055-1.059 2.1 2.1 0 00-2.635 0 .75.75 0 101 1.114.637.637 0 01.627 0 .749.749 0 001.059-.055zM14.578 12.523a.75.75 0 101-1.114 2.1 2.1 0 00-2.635 0 .75.75 0 001 1.114.637.637 0 01.635 0z\"/><path d=\"M6.978 19.492a2.108 2.108 0 001.338-1.208 6.486 6.486 0 003.663 1.666 6.543 6.543 0 003.746-1.739 2.118 2.118 0 001.375 1.281 4.751 4.751 0 004.361-1.564.5.5 0 00-.231-.8c-1.374-.442-2.024-2.087-2.333-3.434a3.141 3.141 0 00.35-1.361 1.884 1.884 0 00-.936-1.808.75.75 0 00-.669 1.342.616.616 0 01.105.439 1.279 1.279 0 01-.559 1.1 1.75 1.75 0 00-1 1.3c-.377 2.249-3.175 3.739-4.208 3.739s-3.83-1.49-4.207-3.738a1.745 1.745 0 00-1-1.306 1.275 1.275 0 01-.559-1.1.626.626 0 01.1-.439.75.75 0 00-.669-1.342 1.886 1.886 0 00-.935 1.81 3.1 3.1 0 00.435 1.5c-.322 1.318-.973 2.864-2.3 3.29a.5.5 0 00-.231.8 4.748 4.748 0 004.364 1.572z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});