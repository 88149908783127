define("ember-svg-jar/inlined/biking-helmet-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>biking-helmet-person</title><path d=\"M4 16a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 112-2 2 2 0 01-2 2zM20 16a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 112-2 2 2 0 01-2 2zM16.989 14.21a1.49 1.49 0 00-.644-1.219 2.225 2.225 0 01-1.43.528H13.15a.993.993 0 00-.48 1.432 4.931 4.931 0 01.689 2.549 1 1 0 001.728.686c1.821-1.93 1.952-2.521 1.902-3.976zM11.4 6.491a2.493 2.493 0 002.471-2.251h1.288a1 1 0 000-2H14.4a3.484 3.484 0 00-6.74 1.249A2.482 2.482 0 009.014 5.87a1.09 1.09 0 00.824.06 2.432 2.432 0 00.307.213 2.49 2.49 0 001.255.348z\"/><path d=\"M9 17.9v2.365a1.25 1.25 0 002.5 0V17.21a4.228 4.228 0 00-1.6-3.317l-1.81-1.385 1.2-1.8a4.222 4.222 0 003.48 1.808h2.146a1.25 1.25 0 000-2.5h-2.147a1.737 1.737 0 01-1.569-.964l-.667-1.342a1.247 1.247 0 00-1.075-.689 1.287 1.287 0 00-1.083.555l-2.492 3.737a2.24 2.24 0 00-.358 1.569 10.278 10.278 0 00-1.27-.132 6.978 6.978 0 00-3.8 1.119 1 1 0 101.086 1.68 4.929 4.929 0 014.007-.62A5.735 5.735 0 019 17.9zM18.129 14.423a1.013 1.013 0 001.124.627 4.981 4.981 0 013.243.461 1 1 0 00.916-1.778 6.964 6.964 0 00-3.694-.757l-1.442-3.794a1 1 0 00-1.29-.579 1.017 1.017 0 00-.579 1.29z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});