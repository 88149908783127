define("ember-svg-jar/inlined/lab-whisk-cup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lab-whisk-cup</title><path d=\"M20.458 5.294A1 1 0 0019.75 5h-3.5a1 1 0 000 2h2a.5.5 0 01.5.5l-.016 6a.5.5 0 01-.5.5h-2.34a.5.5 0 00-.5.562l.172 1.38a.5.5 0 00.165.312 3 3 0 11-4.279.315.5.5 0 00.114-.384l-.219-1.747a.5.5 0 00-.5-.438H7.21a.5.5 0 01-.5-.5V8.851a2.212 2.212 0 00-.595-1.445A.25.25 0 016.31 7h2.44a1 1 0 000-2h-4a1.5 1.5 0 00-1.172 2.437l.912 1.14a1 1 0 01.22.625V21a3 3 0 003 3h10a3 3 0 003-3l.04-15a1 1 0 00-.292-.706z\"/><path d=\"M12.45 16.98a2 2 0 102.4-.15.5.5 0 01-.221-.355L12.679.876a1 1 0 00-1.984.248l1.927 15.414a.5.5 0 01-.172.442z\"/><circle cx=\"8.75\" cy=\"11\" r=\"1\"/><circle cx=\"16.25\" cy=\"9.5\" r=\"1\"/><circle cx=\"16.25\" cy=\"2\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});