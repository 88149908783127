define("ember-svg-jar/inlined/road-sign-right-arrow-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-right-arrow-alternate</title><path d=\"M3 1v22a1 1 0 001 1h16a1 1 0 001-1V1a1 1 0 00-1-1H4a1 1 0 00-1 1zm16 20.75a.25.25 0 01-.25.25H10.4a.25.25 0 01-.18-.423l8.168-8.54a1.5 1.5 0 000-2.074l-8.168-8.54A.25.25 0 0110.4 2h8.35a.25.25 0 01.25.25zM5 2.25A.25.25 0 015.25 2h1.694a.252.252 0 01.181.077l9.326 9.75a.251.251 0 010 .346l-9.326 9.75a.252.252 0 01-.181.077H5.25a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});