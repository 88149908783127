define("ember-svg-jar/inlined/single-neutral-actions-wifi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-wifi</title><path d=\"M0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229s.188 0 .9-.024a.25.25 0 00.212-.37 2.5 2.5 0 01.643-3.155c.139-.11.289-.217.444-.325a.248.248 0 00.109-.208 5.5 5.5 0 00-11 .082z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><circle cx=\"17.5\" cy=\"22\" r=\"1.75\"/><path d=\"M12.621 16.944a7.826 7.826 0 019.758 0 1 1 0 101.242-1.567 9.817 9.817 0 00-12.242 0 1 1 0 101.242 1.567z\"/><path d=\"M13.853 18.2a1 1 0 101.293 1.525 3.586 3.586 0 014.707 0 1 1 0 101.293-1.525 5.621 5.621 0 00-7.293 0zM0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229s.188 0 .9-.024a.25.25 0 00.212-.37 2.5 2.5 0 01.643-3.155c.139-.11.289-.217.444-.325a.248.248 0 00.109-.208 5.5 5.5 0 00-11 .082z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><circle cx=\"17.5\" cy=\"22\" r=\"1.75\"/><path d=\"M12.621 16.944a7.826 7.826 0 019.758 0 1 1 0 101.242-1.567 9.817 9.817 0 00-12.242 0 1 1 0 101.242 1.567z\"/><path d=\"M13.853 18.2a1 1 0 101.293 1.525 3.586 3.586 0 014.707 0 1 1 0 101.293-1.525 5.621 5.621 0 00-7.293 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});