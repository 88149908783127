define("ember-svg-jar/inlined/exotic-food-rolls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>exotic-food-rolls</title><path d=\"M7.262 14.731a.5.5 0 00.3.621l5.113 1.948a2.981 2.981 0 002.3-.067 2.914 2.914 0 001.485-1.468.25.25 0 01.315-.13l.668.254a2.639 2.639 0 001.876-4.933l-.688-.262a.251.251 0 01-.152-.3 3 3 0 00-1.817-3.63l-.686-.264a.99.99 0 00-.4-.064c-2.43.1-5.816 1.443-6.461 3.334a.5.5 0 01-.473.338.508.508 0 01-.162-.026.5.5 0 01-.311-.635c.512-1.5 2.528-2.932 4.8-3.608a.25.25 0 00.018-.473L7.31 3.2a3 3 0 00-3.8 4.033 3.082 3.082 0 00.237.43.249.249 0 01.026.226.253.253 0 01-.168.154 3 3 0 00-.274 5.7l2.36.9a.5.5 0 00.649-.3 3.8 3.8 0 01.837-1.3 8.659 8.659 0 013.159-1.993 1 1 0 01.712 0l.48.182a.25.25 0 01-.021.475 8.14 8.14 0 00-3.6 2.022 2.826 2.826 0 00-.645 1.002zm8.349.473a2 2 0 11-1.159-2.58 2 2 0 011.159 2.576zm-.731-3.773a2 2 0 112.58-1.158 2 2 0 01-2.58 1.158zm5.1 2.6a1.711 1.711 0 11-.991-2.207 1.71 1.71 0 01.986 2.203z\"/><path d=\"M12 20.994c-5.817 0-12-1.928-12-5.5a2.907 2.907 0 01.1-.762 1 1 0 011.931.523.9.9 0 00-.031.239c0 1.461 3.8 3.5 10 3.5s10-2.039 10-3.5a.9.9 0 00-.033-.239 1 1 0 111.931-.523 2.907 2.907 0 01.1.762c.002 3.572-6.181 5.5-11.998 5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});