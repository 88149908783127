define("ember-svg-jar/inlined/style-three-pin-slot-machine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-slot-machine</title><rect x=\"7\" y=\"8.494\" width=\"2\" height=\"4\" rx=\".5\" ry=\".5\"/><rect x=\"13\" y=\"8.494\" width=\"2\" height=\"4\" rx=\".5\" ry=\".5\"/><rect x=\"10\" y=\"8.494\" width=\"2\" height=\"4\" rx=\".5\" ry=\".5\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM7.5 4h7A1.5 1.5 0 0116 5.5a.25.25 0 01-.25.25h-9.5A.25.25 0 016 5.5 1.5 1.5 0 017.5 4zM19 10.75a2.752 2.752 0 01-2.75 2.75.25.25 0 00-.25.25V15a1 1 0 01-1 1H7a1 1 0 01-1-1V7.75a.5.5 0 01.5-.5h9a.5.5 0 01.5.5v4a.25.25 0 00.25.25 1.251 1.251 0 001.25-1.25v-4a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});