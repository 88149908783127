define("ember-svg-jar/inlined/family-outdoors-slide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-outdoors-slide</title><path d=\"M9.2 12.88a.5.5 0 00-.537.083l-3.031 2.714a7.285 7.285 0 01-4.074 1.828A1.749 1.749 0 000 19.243v3a1.752 1.752 0 001.929 1.742 12.241 12.241 0 007.4-3.662.5.5 0 00.171-.376v-6.611a.5.5 0 00-.3-.456zM18.5 0h-2A5.506 5.506 0 0011 5.5v18a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-8a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v8a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-18A5.506 5.506 0 0018.5 0zM20 10a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5V6.5a2.5 2.5 0 015 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});