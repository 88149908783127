define("ember-svg-jar/inlined/products-give-gift-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>products-give-gift-1</title><path d=\"M16.25 3.384a.5.5 0 00-.124-.329L13.752.342a1 1 0 10-1.5 1.317L13.92 3.57a.25.25 0 01-.188.415H11a1 1 0 00-1 1V6a1 1 0 001 1h5a.25.25 0 00.25-.25zM24 4.985a1 1 0 00-1-1h-2.732a.25.25 0 01-.188-.415l1.672-1.911a1 1 0 10-1.5-1.317l-2.378 2.713a.5.5 0 00-.124.329V6.75A.25.25 0 0018 7h5a1 1 0 001-1zM15.5 19.5H6.375a.625.625 0 010-1.25H8.9a1 1 0 00.92-1.394A2.236 2.236 0 007.764 15.5H3.5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.764a2.238 2.238 0 002.236-2.236V20.5a1 1 0 00-1-1zM1 14.5a1 1 0 00-1 1V23a1 1 0 002 0v-7.5a1 1 0 00-1-1zM16.25 8.75A.25.25 0 0016 8.5h-4.5a.5.5 0 00-.5.5v5.485a1 1 0 001 1h4a.25.25 0 00.25-.25zM22.5 8.5H18a.25.25 0 00-.25.25v6.485a.25.25 0 00.25.25h4a1 1 0 001-1V9a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});