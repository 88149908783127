define("ember-svg-jar/inlined/professions-man-welder.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-welder</title><path d=\"M24 21.394a2.959 2.959 0 00-1.164-2.374 14.211 14.211 0 00-5.152-2.355.224.224 0 01-.04-.419 1.482 1.482 0 00.856-1.356V8.75a.25.25 0 01.25-.25h.75a1 1 0 000-2h-.755a.249.249 0 01-.249-.241 6.5 6.5 0 00-12.992 0 .249.249 0 01-.249.241H4.5a1 1 0 000 2h.75a.25.25 0 01.25.25v6.14a1.482 1.482 0 00.855 1.356.224.224 0 01-.039.419 14.243 14.243 0 00-5.153 2.355A2.961 2.961 0 000 21.394V23a1 1 0 002 0v-1.606a.98.98 0 01.376-.785 11.026 11.026 0 012.786-1.5.25.25 0 01.338.234v4.4a.25.25 0 00.25.25h12.5a.25.25 0 00.25-.25v-4.4a.25.25 0 01.338-.234 11 11 0 012.785 1.5.978.978 0 01.377.785V23a1 1 0 002 0zM11 3.25h2a.75.75 0 010 1.5h-2a.75.75 0 010-1.5zM15 6a1.5 1.5 0 011.5 1.5v2A1.5 1.5 0 0115 11H9a1.5 1.5 0 01-1.5-1.5v-2A1.5 1.5 0 019 6zm1 14a1 1 0 01-1 1H9a1 1 0 01-1-1v-1.448a.25.25 0 01.208-.246A22.487 22.487 0 0112 18a22.487 22.487 0 013.792.306.251.251 0 01.208.246z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});