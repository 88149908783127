define("ember-svg-jar/inlined/single-man-actions-time.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-time</title><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm0 11a4.5 4.5 0 114.5-4.5 4.505 4.505 0 01-4.5 4.5z\"/><path d=\"M20.5 17.5a1 1 0 00-1-1h-.75a.25.25 0 01-.25-.25v-1a1 1 0 00-2 0v2.25a1 1 0 001 1h2a1 1 0 001-1zM7 10a5 5 0 10-5-5 5.006 5.006 0 005 5zM4.1 4.258a.5.5 0 01.741-.3A6.959 6.959 0 008.46 5a6.36 6.36 0 00.929-.07.5.5 0 01.425.139.5.5 0 01.144.425A3 3 0 014 5a2.828 2.828 0 01.1-.742zM11.443 12.021a.5.5 0 00-.225-.343 7.475 7.475 0 00-11.2 4.179.5.5 0 00.482.643h8.64a.5.5 0 00.492-.411 7.96 7.96 0 011.7-3.67.5.5 0 00.111-.398z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});