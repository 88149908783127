define("ember-svg-jar/inlined/bed-window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bed-window</title><path d=\"M22.75 18.4V17a2 2 0 00-2-2h-10a.5.5 0 01-.5-.5V14a2.1 2.1 0 00-1.9-2.1l-2.512-.251A2.358 2.358 0 003.25 14v.5a.5.5 0 01-1 0v-4a1 1 0 00-2 0v12a1 1 0 002 0V22a.5.5 0 01.5-.5h18a.5.5 0 01.5.5v.5a1 1 0 002 0v-3a.962.962 0 00-.333-.724.5.5 0 01-.167-.376zm-2-.4a.5.5 0 01-.5.5H2.75a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h17.5a.5.5 0 01.5.5z\"/><rect x=\"19.75\" y=\"5\" width=\"4\" height=\"3\" rx=\".5\" ry=\".5\"/><path d=\"M22.75.5h-2.5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V1.5a1 1 0 00-1-1z\"/><rect x=\"14.25\" y=\"5\" width=\"4\" height=\"3\" rx=\".5\" ry=\".5\"/><path d=\"M23.25 9.5h-3a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h2.5a1 1 0 001-1V10a.5.5 0 00-.5-.5zM14.75 3.5h3a.5.5 0 00.5-.5V1a.5.5 0 00-.5-.5h-2.5a1 1 0 00-1 1V3a.5.5 0 00.5.5zM15.25 12.5h2.5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v1.5a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});