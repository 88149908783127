define("ember-svg-jar/inlined/yoga-mat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>yoga-mat</title><path d=\"M21.5 6.5H8a.5.5 0 00-.5.5v12A.75.75 0 016 19a3 3 0 10-3 3h18.5a2.5 2.5 0 002.5-2.5V9a2.5 2.5 0 00-2.5-2.5zM3 20a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M.136 15.37a.249.249 0 00.26-.02 4.414 4.414 0 015.208 0 .25.25 0 00.4-.2V5A3 3 0 000 5v10.147a.252.252 0 00.136.223z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});