define("ember-svg-jar/inlined/road-sign-hairpin-turn-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-hairpin-turn-right</title><path d=\"M23.53 12.53a.749.749 0 000-1.06l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0zm-5.436-1.78a.75.75 0 01.58 1.225l-2.093 2.562a.739.739 0 01-1.158 0L13.3 11.979a.75.75 0 01.577-1.229H15a.25.25 0 00.25-.25 2.75 2.75 0 00-5.5 0v6a.75.75 0 01-1.5 0v-6a4.25 4.25 0 018.5 0 .25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});