define("ember-svg-jar/inlined/temperature-thermometer-snow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>temperature-thermometer-snow</title><path d=\"M19.1 12.348a1 1 0 00.469-.848v-4a1 1 0 00-.469-.848l-4-2.5a1 1 0 00-1.061 0L12.687 5a.251.251 0 00-.118.212v1.77a.251.251 0 00.383.212l1.485-.929a.253.253 0 01.265 0l2.75 1.719a.25.25 0 01.117.212v2.614a.25.25 0 01-.117.212L14.7 12.738a.253.253 0 01-.265 0l-1.485-.929a.251.251 0 00-.383.212v1.231a.247.247 0 00.046.144 7.923 7.923 0 01.524.85.234.234 0 00.088.094l.812.508a1 1 0 001.061 0z\"/><path d=\"M13.862 3.707a1 1 0 001.414 0l2-2A1 1 0 0015.862.293l-.939.939a.5.5 0 01-.707 0l-.94-.939A1 1 0 0011.57.96a.248.248 0 00.037.14 6.652 6.652 0 01.378.707.243.243 0 00.05.073zM13.777 15.379a.249.249 0 00-.063.25 7.981 7.981 0 01.337 1.834.2.2 0 00.342.128.25.25 0 01.353 0l1.116 1.116a1 1 0 001.414-1.414l-2-2a1 1 0 00-1.414 0zM22.672 11.052l-2.733.732a1 1 0 00-.707 1.225l.733 2.732a1 1 0 00.965.741.962.962 0 00.259-.035 1 1 0 00.707-1.224l-.344-1.283a.5.5 0 01.354-.612l1.283-.344a1 1 0 00-.517-1.932zM19.232 5.991a1 1 0 00.707 1.225l2.733.732a1.015 1.015 0 00.26.034 1 1 0 00.257-1.966l-1.283-.344a.5.5 0 01-.354-.612l.348-1.283a1 1 0 00-1.931-.518zM6.069 24a6.006 6.006 0 006-6 5.946 5.946 0 00-1.439-3.9.249.249 0 01-.061-.162V4.5a4.5 4.5 0 00-9 0v9.438a.248.248 0 01-.06.162A5.946 5.946 0 00.069 18a6.007 6.007 0 006 6zm0-2a4 4 0 01-4-4 3.963 3.963 0 011.2-2.856 1 1 0 00.3-.714V4.5a2.5 2.5 0 015 0v9.93a1 1 0 00.3.714 3.962 3.962 0 011.2 2.856 4 4 0 01-4 4z\"/><path d=\"M6.958 16.208a.25.25 0 01-.139-.224V6.5a.75.75 0 00-1.5 0v9.484a.25.25 0 01-.139.224 2 2 0 101.778 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});