define("ember-svg-jar/inlined/transfer-pictures-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transfer-pictures-laptop</title><path d=\"M21.5 16v-3.5a1 1 0 00-1-1 1 1 0 00-1 1V16a1 1 0 002 0zM3.5 17a1 1 0 001-1V8a1 1 0 011-1h3a1 1 0 001-1 1 1 0 00-1-1h-3a3 3 0 00-3 3v8a1 1 0 001 1zM23.5 18.5H16a.5.5 0 00-.5.5c0 .708-1.5 1.5-3.5 1.5s-3.5-.792-3.5-1.5a.5.5 0 00-.5-.5H.5a.5.5 0 00-.474.658A7.075 7.075 0 006.74 24h10.52a7.075 7.075 0 006.714-4.842.5.5 0 00-.474-.658zM22.5 2h-.536a.251.251 0 01-.224-.138L20.947.276A.5.5 0 0020.5 0H16a.5.5 0 00-.447.276l-.793 1.586a.251.251 0 01-.224.138H12.5A1.5 1.5 0 0011 3.5v5a1.5 1.5 0 001.5 1.5h10A1.5 1.5 0 0024 8.5v-5A1.5 1.5 0 0022.5 2zM18 7.5a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});