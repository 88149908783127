define("ember-svg-jar/inlined/house.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>house</title><path d=\"M20.069 6.536a.25.25 0 00.431-.173v-4.87a.5.5 0 00-.5-.5h-3.5a.5.5 0 00-.5.5v.588a.5.5 0 00.139.346zM23.722 11.8L12.723.3a1.035 1.035 0 00-1.446 0l-11 11.5A1 1 0 001 13.493h1.5a.5.5 0 01.5.5v8.5a1.5 1.5 0 001.5 1.5H9a1 1 0 001-1v-4a2 2 0 014 0v4a1 1 0 001 1h4.5a1.5 1.5 0 001.5-1.5v-8.5a.5.5 0 01.5-.5H23a1 1 0 00.722-1.692z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});