define("ember-svg-jar/inlined/car-repair-fire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-repair-fire</title><path d=\"M9.464 9.36a.25.25 0 00.25.243h3.506a5.023 5.023 0 014.638 3.151l.68 1.694a.249.249 0 00.225.157 5.6 5.6 0 012.629.745.251.251 0 00.294-.033 6.208 6.208 0 001.452-1.958 8.344 8.344 0 00-.06-6.3.506.506 0 00-.481-.3.5.5 0 00-.451.342 1.87 1.87 0 01-.418.654.25.25 0 01-.431-.136C20.708 3.424 16.551.485 16.5.458a.5.5 0 00-.676.692 1.969 1.969 0 01-.524 2.609A10.412 10.412 0 0013.635 5.3a.252.252 0 01-.388-.013 3.628 3.628 0 00-2.742-1.392.5.5 0 00-.354.854c.366.365.047 1.26-.115 1.592a7.964 7.964 0 00-.572 3.019z\"/><path d=\"M18.6 15.6a1 1 0 01-.931-.62l-.739-1.857a4.016 4.016 0 00-3.71-2.523H8.89a2.994 2.994 0 00-2.94 2.413L5.59 14.8a1 1 0 01-.98.8H3.25a3 3 0 00-3 3v2a2 2 0 002 2 .133.133 0 00.1-.053.131.131 0 00.02-.115 2.872 2.872 0 01-.127-.828 3 3 0 016 0 2.766 2.766 0 01-.089.685.248.248 0 00.046.215.251.251 0 00.2.1h6.691a.251.251 0 00.2-.1.248.248 0 00.046-.215 2.766 2.766 0 01-.088-.685 3 3 0 016 0 2.838 2.838 0 01-.127.823.136.136 0 00.021.119.134.134 0 00.108.054 2 2 0 002-2v-.35A4.655 4.655 0 0018.6 15.6zm-3-.11a.25.25 0 01-.207.11H13.25a.989.989 0 01-1-1v-1.75a.25.25 0 01.25-.25h.72a2 2 0 011.851 1.26l.556 1.4a.249.249 0 01-.027.233z\"/><circle cx=\"5.248\" cy=\"21.607\" r=\"2\"/><circle cx=\"18.248\" cy=\"21.607\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});