define("ember-svg-jar/inlined/speech-translate-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>speech-translate-1</title><path d=\"M14.919 4.25a1 1 0 000 2 2.961 2.961 0 012.912 3 1 1 0 002 0 4.962 4.962 0 00-4.912-5z\"/><path d=\"M14.685 0a1 1 0 000 2A7.417 7.417 0 0122 9.5a1 1 0 002 0A9.42 9.42 0 0014.685 0zM6.169 9.25a2.961 2.961 0 012.913-3 1 1 0 000-2 4.963 4.963 0 00-4.913 5 1 1 0 002 0z\"/><path d=\"M9.315 2a1 1 0 000-2A9.42 9.42 0 000 9.5a1 1 0 002 0A7.417 7.417 0 019.315 2zM18.608 12H13.5a.25.25 0 01-.25-.25V9.5a1 1 0 00-2 0v2.25A.25.25 0 0111 12H5.892a1 1 0 100 2h1.275a.25.25 0 01.228.147 20.793 20.793 0 003.054 4.79.25.25 0 01-.022.347 15.541 15.541 0 01-4.321 2.8 1 1 0 10.788 1.837 17.606 17.606 0 004.938-3.2.249.249 0 01.336 0 17.591 17.591 0 004.938 3.2.981.981 0 00.394.079 1 1 0 00.394-1.919 15.5 15.5 0 01-4.318-2.8.248.248 0 01-.022-.346 20.823 20.823 0 003.051-4.787.252.252 0 01.228-.148h1.775a1 1 0 000-2zM12.2 17.423a.251.251 0 01-.193.091.254.254 0 01-.193-.091 19.578 19.578 0 01-2.064-3.054.249.249 0 01.219-.369h4.067a.25.25 0 01.22.369 19.384 19.384 0 01-2.056 3.054z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});