define("ember-svg-jar/inlined/business-boat-success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-boat-success</title><path d=\"M22.542 3.6a1.888 1.888 0 00-2.162-1.522.251.251 0 01-.3-.167 2 2 0 00-3.658-.39.251.251 0 01-.29.088 1.584 1.584 0 00-2.215 1.514s0 1.917 2.4 1.917h4.313c1.432 0 1.912-.479 1.912-1.44zM8.024 11.639h8.253a.25.25 0 00.193-.409l-3.919-4.791a.519.519 0 00-.8 0L7.83 11.23a.251.251 0 00.194.409zM2.492 13.194a.552.552 0 00-.018.541L4.7 18.022a.25.25 0 00.208.135 2.488 2.488 0 011.747.936.242.242 0 00.193.08.235.235 0 00.181-.1l3.99-5.751a.25.25 0 00-.206-.392H2.964a.552.552 0 00-.472.264zM8.646 20.146a1.5 1.5 0 001.264-.693l.008-.014a2.342 2.342 0 011.874-1.226 2.4 2.4 0 012.074.909l.01.013a3.074 3.074 0 001.957 1.011 1.531 1.531 0 00.183-.01.251.251 0 00.2-.144.247.247 0 00-.021-.245L12.2 13.891a.249.249 0 00-.412 0l-3.973 5.726a.254.254 0 00-.034.214.25.25 0 00.146.16 1.907 1.907 0 00.719.155zM17.117 19.215a.25.25 0 00.206-.1 2.336 2.336 0 011.663-.909.6.6 0 01.095 0 .25.25 0 00.237-.136l2.216-4.339a.553.553 0 00-.492-.8h-7.864a.25.25 0 00-.206.391l3.945 5.787a.25.25 0 00.2.106z\"/><path d=\"M.021 22.542a.957.957 0 00.958.958 5.51 5.51 0 003.773-1.322.25.25 0 01.319-.01A5.938 5.938 0 008.646 23.5a4.731 4.731 0 003.3-1.3.251.251 0 01.324-.014 5.859 5.859 0 003.563 1.314 4.7 4.7 0 003.293-1.273.25.25 0 01.325-.014 5.784 5.784 0 003.57 1.287.958.958 0 100-1.916 4.246 4.246 0 01-3.067-1.534.943.943 0 00-.846-.375.966.966 0 00-.774.514 2.839 2.839 0 01-2.5 1.395 4.5 4.5 0 01-3.085-1.558.946.946 0 00-.83-.351.964.964 0 00-.76.493 2.952 2.952 0 01-2.512 1.416 4.666 4.666 0 01-3.1-1.569.966.966 0 00-1.585.173 3.254 3.254 0 01-2.983 1.4.957.957 0 00-.958.954z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});