define("ember-svg-jar/inlined/pets-paw-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pets-paw-search</title><path d=\"M23.414 20.586L19.2 16.372a10.51 10.51 0 10-2.828 2.828l4.214 4.214a2 2 0 002.828-2.828zM10.5 2A8.5 8.5 0 112 10.5 8.51 8.51 0 0110.5 2z\"/><path d=\"M11.461 10.541a1.192 1.192 0 00-1.924 0l-1.822 2.486a1.245 1.245 0 00-.1 1.274 1.221 1.221 0 001.063.683h3.648a1.229 1.229 0 00.961-1.943z\"/><circle cx=\"6.746\" cy=\"8.738\" r=\"1.246\"/><circle cx=\"10.484\" cy=\"6.246\" r=\"1.246\"/><circle cx=\"14.254\" cy=\"8.738\" r=\"1.246\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});