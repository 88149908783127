define("ember-svg-jar/inlined/checkmark-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.646 7.858l-3.5-3.417a.473.473 0 010-.684l.707-.684a.514.514 0 01.707 0L4 5.467 9.439.142a.514.514 0 01.707 0l.707.684a.472.472 0 010 .683l-6.5 6.351a.514.514 0 01-.707-.002z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "11",
      "height": "8",
      "viewBox": "0 0 11 8"
    }
  };
});