define("ember-svg-jar/inlined/people-man-cap-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-cap-1</title><path d=\"M6.189 12.447a.253.253 0 01.145.187c.511 3.048 4.021 4.991 5.687 4.991a7.039 7.039 0 005.061-3.312.1.1 0 01.025-.028c3.5-2.453 2.239-6.8 1.716-7.646a.231.231 0 01-.036-.108c-.31-3.151-2.24-6.406-6.808-6.406C7.432.125 5.5 3.352 5.178 6.49a.264.264 0 01-.023.082.706.706 0 00-.076.317A13.439 13.439 0 005.2 8.464a.252.252 0 01-.049.185 2.158 2.158 0 00-.4 1.364 2.731 2.731 0 001.438 2.434zm5.832 3.678c-1.033 0-3.831-1.49-4.209-3.74a1.747 1.747 0 00-1-1.3 1.279 1.279 0 01-.559-1.1.616.616 0 01.105-.439.752.752 0 00.406-.795 12.469 12.469 0 01-.113-.851.249.249 0 01.249-.275h4.8a.25.25 0 01.244.2 2.909 2.909 0 002.882 2.554c.787 0 1.181 1.075 1.362 2.084a.236.236 0 010 .1 5.372 5.372 0 01-4.167 3.562zm-.042-14.5a4.888 4.888 0 015.19 4.2.25.25 0 01-.245.3H7.036a.25.25 0 01-.245-.3 4.888 4.888 0 015.188-4.2z\"/><path d=\"M21.08 18.662l-.017-.006-4.476-1.5a.5.5 0 00-.562.178 4.985 4.985 0 01-8.05 0 .5.5 0 00-.562-.178L2.92 18.662A4.519 4.519 0 000 22.875v.5a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-.5a4.519 4.519 0 00-2.92-4.213zM12.792 12.953a1.2 1.2 0 01-.22.093 1.834 1.834 0 01-.544.079 1.837 1.837 0 01-.55-.081 1.2 1.2 0 01-.222-.093.75.75 0 10-.731 1.31 2.717 2.717 0 00.5.214 3.382 3.382 0 001 .15h.006a3.346 3.346 0 00.993-.148 2.669 2.669 0 00.5-.217.75.75 0 10-.734-1.307zM11.129 10.661A.751.751 0 0011.09 9.6a2.019 2.019 0 00-2.6 0 .75.75 0 001.022 1.1.557.557 0 01.557 0 .753.753 0 001.061-.039zM12.5 3.625H11a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});