define("ember-svg-jar/inlined/video-file-mov", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-mov</title><path d=\"M12 12.625a.877.877 0 00-.875.875v3a.875.875 0 001.75 0v-3a.877.877 0 00-.875-.875zM17.5 6h3.9a.25.25 0 00.177-.427L16.427.427A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6z\"/><path d=\"M17.5 7A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.25a.25.25 0 00-.25-.25zM9.125 18a.625.625 0 01-1.25 0v-2.978c0-.147-.15-.075-.19 0l-.126.252a.65.65 0 01-1.118 0l-.128-.257c-.061-.121-.188-.128-.188 0V18a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279s.669 1.341.738 1.475a.243.243 0 00.4.005l.74-1.48A.625.625 0 019.125 12zm5-1.5a2.125 2.125 0 01-4.25 0v-3a2.125 2.125 0 014.25 0zm5-3a8.176 8.176 0 01-1.625 4.875.625.625 0 01-1 0 8.176 8.176 0 01-1.625-4.875V12a.625.625 0 011.25 0v1.5a6.923 6.923 0 00.772 3.162.133.133 0 00.2.009 6.926 6.926 0 00.776-3.171V12a.625.625 0 011.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});