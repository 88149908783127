define("ember-svg-jar/inlined/death-coffin-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>death-coffin-1</title><path d=\"M20.929 7.846a1 1 0 00-.156-.825L16.056.419A1 1 0 0015.243 0H8.757a1 1 0 00-.814.419l-4.716 6.6a1 1 0 00-.156.824l3.849 15.4a1 1 0 00.97.757h8.219a1 1 0 00.971-.758zM17 8.5a1 1 0 01-1 1h-2.5a.5.5 0 00-.5.5v8.5a1 1 0 01-2 0V10a.5.5 0 00-.5-.5H8a1 1 0 010-2h2.5A.5.5 0 0011 7V4.5a1 1 0 012 0V7a.5.5 0 00.5.5H16a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});