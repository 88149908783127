define("ember-svg-jar/inlined/messages-bubble-quotation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-quotation</title><path d=\"M12 1.375C5.682 1.375.541 5.611.541 10.817A8.566 8.566 0 003.7 17.331L1.416 21.9a.5.5 0 00.658.677L8.2 19.726a13.67 13.67 0 003.8.531c6.319 0 11.459-4.235 11.459-9.44S18.319 1.375 12 1.375zm5.25 10.5a2.753 2.753 0 01-2.75 2.75.75.75 0 010-1.5 1.25 1.25 0 001.25-1.25v-.406a2.218 2.218 0 01-.75.139 2.25 2.25 0 112.25-2.25zm-9.5 2a.75.75 0 01.75-.75 1.25 1.25 0 001.25-1.25v-.406a2.218 2.218 0 01-.75.139 2.25 2.25 0 112.25-2.25v2.517a2.753 2.753 0 01-2.75 2.75.75.75 0 01-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});