define("ember-svg-jar/inlined/wolf-howl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wolf-howl</title><path d=\"M23.961 7.332c-.115-.673-1.055-2.463-2.183-1.709l-.023.016A6.207 6.207 0 0022 4.005 4.763 4.763 0 0020.054.173a1 1 0 00-1.3.169C17.087 2.221 15.1 3.909 14.5 3.955c-2.225 0-4.061-.379-7.753 3.842C3.569 11.429.854 11.965.836 11.969a1 1 0 00-.644 1.575 7.22 7.22 0 004.361 2.628 4.755 4.755 0 01-3.567 1.783 1 1 0 00-.986 1V23a1 1 0 001 1h13a1 1 0 00.65-.241 10.174 10.174 0 003.372-7.923c.944-.994 3.823-4.06 5.111-5.776a3.523 3.523 0 00.828-2.728zm-2.428 1.523c-1.446 1.928-5.218 5.869-5.255 5.909a.991.991 0 00-.271.8A8.533 8.533 0 0115.513 19a8.692 8.692 0 00-2.7-3.609 6.16 6.16 0 01-.978-.992c-.454-.681.759-1.638.767-1.645a1 1 0 00-.6-1.8 3.927 3.927 0 01-2.3-.707c-.327-.327-.266-1.51.13-2.762 2.029-1.827 2.979-1.531 4.663-1.531 1.458 0 3.662-2.059 5.024-3.5A2.723 2.723 0 0120 4.005a8.779 8.779 0 01-1.494 3.889 1 1 0 001.381 1.393l2.106-1.4a2.049 2.049 0 01-.46.968z\"/><circle cx=\"13\" cy=\"8.455\" r=\"1.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});