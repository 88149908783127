define("ember-svg-jar/inlined/fast-food-double-burger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fast-food-double-burger</title><path d=\"M21.5 15.5a1.207 1.207 0 00-.854.354l-1.627 1.626a2 2 0 01-2.308.375l-4.078-2.038a2.986 2.986 0 00-1.341-.317H2.5a2 2 0 000 4h19a2 2 0 000-4zM3 21a.5.5 0 00-.5.5v.5a2 2 0 002 2h15a2 2 0 002-2v-.5a.5.5 0 00-.5-.5zM2.5 10.5s-2 .4-2 1.5.9 1.5 2 1.5a2.625 2.625 0 001.135-.25.5.5 0 01.621.146A2.719 2.719 0 006.5 14.5a2.443 2.443 0 002.3-1.112.249.249 0 01.414-.016A3.438 3.438 0 0012 14.5a3.437 3.437 0 002.786-1.128.251.251 0 01.415.016A2.441 2.441 0 0017.5 14.5a2.717 2.717 0 002.243-1.1.5.5 0 01.622-.146 2.622 2.622 0 001.135.25c1.105 0 2-.4 2-1.5s-2-1.5-2-1.5zM20.986 9a.5.5 0 00.5-.527C21.211 3.5 17.069 0 12 0S2.789 3.5 2.515 8.472a.5.5 0 00.5.528zM7 6a1 1 0 111-1 1 1 0 01-1 1zm2.5-2a1 1 0 111-1 1 1 0 01-1 1zM12 6a1 1 0 111-1 1 1 0 01-1 1zm2.5-2a1 1 0 111-1 1 1 0 01-1 1zM17 6a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});