define("ember-svg-jar/inlined/cat-head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cat-head</title><path d=\"M7.654 4.332L3.272 1.7a1.5 1.5 0 00-2.264 1.42L2.3 11.153a14.2 14.2 0 015.354-6.821zM21.705 11.152L23 3.068A1.5 1.5 0 0020.729 1.7l-4.383 2.63a14.2 14.2 0 015.359 6.822zM20.119 18.251a6.634 6.634 0 00.632-1.483H23a1 1 0 000-2h-2c-.152-4.143-4.121-10.256-9-10.256s-8.843 6.113-9 10.256H1a1 1 0 000 2h2.249a6.634 6.634 0 00.632 1.483l-2.529 1.1a1 1 0 00-.519 1.315 1.016 1.016 0 001.315.519l3-1.306A9.8 9.8 0 0012 22.512a9.8 9.8 0 006.847-2.633l3 1.306a1 1 0 00.8-1.834zM15.25 9.262a1.25 1.25 0 11-1.25 1.25 1.25 1.25 0 011.25-1.25zm-5 1.5A1.25 1.25 0 119 9.512a1.25 1.25 0 011.25 1.25zM12 17.567a3.279 3.279 0 01-3.687.966.75.75 0 01.553-1.394c1.633.65 2.441-1.245 2.394-1.263A1.883 1.883 0 0110 14.518c0-.553.895-1 2-1s2 .447 2 1a1.883 1.883 0 01-1.263 1.359c-.047.018.778 1.906 2.4 1.262a.75.75 0 01.553 1.394 3.229 3.229 0 01-3.69-.966z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});