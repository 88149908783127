define("ember-svg-jar/inlined/safety-helmet-mine-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-helmet-mine-1</title><path d=\"M22.444 14.848a10.493 10.493 0 00-7.655-9.658 2.754 2.754 0 00-2.694-2.2h-.19a2.755 2.755 0 00-2.691 2.188 10.5 10.5 0 00-7.658 9.67A1.55 1.55 0 00.5 16.315v1.048a1.549 1.549 0 001.548 1.547h2.166l.279.974a1.554 1.554 0 001.488 1.122H18.02a1.556 1.556 0 001.488-1.122l.278-.974h2.166a1.549 1.549 0 001.548-1.547v-1.048a1.55 1.55 0 00-1.056-1.467zM11.905 4.494h.19a1.251 1.251 0 011.25 1.25v7a1.25 1.25 0 01-1.25 1.25h-.19a1.25 1.25 0 01-1.25-1.25v-7a1.251 1.251 0 011.25-1.25zm3.809 5.183a1 1 0 012 0v3.5a1 1 0 01-2 0zm-7.428 0v3.5a1 1 0 01-2 0v-3.5a1 1 0 012 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});