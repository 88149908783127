define("ember-svg-jar/inlined/harddrive-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>harddrive-download</title><path d=\"M22.281 18.12H1.719A1.47 1.47 0 00.25 19.588v2.938A1.471 1.471 0 001.719 24h20.562a1.471 1.471 0 001.469-1.469v-2.943a1.47 1.47 0 00-1.469-1.468zm-.981 2.937a1.469 1.469 0 11-1.469-1.469 1.469 1.469 0 011.469 1.469zM22.575 15.769l-3.917-2.937a.483.483 0 00-.293-.1H5.635a.485.485 0 00-.293.1l-3.917 2.937a.49.49 0 00.294.882h20.562a.49.49 0 00.294-.882zM10.567 5.646a.25.25 0 01-.25.25h-1.7a.977.977 0 00-.691 1.667l3.417 3.416a.975.975 0 001.38 0l3.417-3.416a.976.976 0 00-.69-1.667h-1.7a.249.249 0 01-.25-.25V1.469a1.465 1.465 0 00-2.929 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});