define("ember-svg-jar/inlined/farming-sickle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>farming-sickle</title><path d=\"M21.124 3.243A11.179 11.179 0 0011.167.2a1 1 0 00-.809.954.98.98 0 00.794 1.017c5.508.906 7.4 3.37 8.01 5.278a5.273 5.273 0 01-.886 5.052 4.492 4.492 0 01-2.413 1.263 4.642 4.642 0 01-2.884-.437 4.764 4.764 0 01-.868-.6 2.524 2.524 0 00-3.433.119l-.811.81a.5.5 0 000 .712L10 16.5a.519.519 0 00.443.139.5.5 0 01.333.061 8.553 8.553 0 0010.344-1.354 8.747 8.747 0 001.322-1.7 8.557 8.557 0 00-1.318-10.403zM6.444 15.076a.5.5 0 00-.712 0L1.1 19.7a2.517 2.517 0 103.56 3.559l4.627-4.627a.5.5 0 000-.712z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});