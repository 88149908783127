define("ember-svg-jar/inlined/send-email-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>send-email-3</title><path d=\"M23.854.2A.5.5 0 0023.3.091L.3 9.99a.5.5 0 00-.036.9l6.67 3.531a.249.249 0 00.276-.021L17.5 6.1a.25.25 0 01.352.352l-8.3 10.29a.249.249 0 00-.026.274l3.531 6.67a.5.5 0 00.442.266h.019a.5.5 0 00.439-.3l10-22.9A.5.5 0 0023.854.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});