define("ember-svg-jar/inlined/road-sign-no-parking-allowed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-no-parking-allowed</title><path d=\"M12.437 9.25h-1a.249.249 0 00-.25.25v2a.249.249 0 00.25.25h1a1.25 1.25 0 000-2.5z\"/><path d=\"M21.134 4.245a.252.252 0 00-.367-.015l-3.025 3.025a.75.75 0 01-1.061-1.061l3.019-3.02a.249.249 0 00-.017-.368A11.98 11.98 0 002.806 19.685a.251.251 0 00.368.017l3.432-3.432a.75.75 0 111.061 1.06L4.23 20.767a.254.254 0 00-.073.187.249.249 0 00.089.18A11.979 11.979 0 0021.134 4.245zm-8.7 9h-1a.249.249 0 00-.25.25v3a.75.75 0 01-1.5 0v-8a.75.75 0 01.75-.75h2a2.75 2.75 0 010 5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});