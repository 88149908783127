define("ember-svg-jar/inlined/diagram-curvy-both-direction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-curvy-both-direction</title><path d=\"M24 4.32a1.472 1.472 0 00-.423-1.02L20.7.422a1.44 1.44 0 00-2.46 1.018v1.19a.25.25 0 01-.25.25H9.6a5.28 5.28 0 000 10.56h4.8a2.4 2.4 0 010 4.8H6.01a.25.25 0 01-.25-.25V16.8a1.44 1.44 0 00-2.46-1.018L.423 18.661a1.439 1.439 0 000 2.038L3.3 23.578a1.44 1.44 0 002.46-1.018v-1.19a.25.25 0 01.25-.25h8.39a5.28 5.28 0 000-10.56H9.6a2.4 2.4 0 010-4.8h8.39a.25.25 0 01.25.25V7.2a1.44 1.44 0 002.46 1.018l2.879-2.879A1.45 1.45 0 0024 4.32z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});