define("ember-svg-jar/inlined/email-action-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-star</title><path d=\"M12.259 13h1.186a.252.252 0 00.23-.151c.84-1.944.832-1.933.875-2.017a3.293 3.293 0 015.887-.031c.018.034.014.024.084.183a.25.25 0 00.479-.1V1.75a.159.159 0 00-.271-.113L12.2 10.164a2.407 2.407 0 01-3.4 0L.271 1.637A.157.157 0 00.1 1.6a.159.159 0 00-.1.15v10.5A1.749 1.749 0 001.75 14H9.9a3.26 3.26 0 012.359-1z\"/><path d=\"M9.726 9.236a1.1 1.1 0 001.547 0L19.748.761a.437.437 0 00.106-.447C19.758.027 19.489 0 19.249 0h-17.5c-.24 0-.51.027-.605.314a.441.441 0 00.106.447zM20.737 23.827a1.273 1.273 0 001.505-.166 1.254 1.254 0 00.316-1.45l-1.3-2.981 2.348-2.092a1.262 1.262 0 00.3-1.378A1.232 1.232 0 0022.74 15h-2.665l-1.409-3.267-.012-.028a1.294 1.294 0 00-2.318.028L14.925 15h-2.666a1.229 1.229 0 00-1.166.76 1.273 1.273 0 00.319 1.4l2.329 2.075-1.295 2.98a1.255 1.255 0 00.315 1.451 1.286 1.286 0 001.506.166l3.233-1.826z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});