define("ember-svg-jar/inlined/breakfast-croissant-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>breakfast-croissant-1</title><path d=\"M3.326 12.261a.5.5 0 00-.081-.561C2.12 10.485 1.846 8.75.862 8.75c-1.039 0-1.294 3.427.11 6.515a.5.5 0 00.906.008c.451-.946 1.136-2.379 1.448-3.012zM7.3 13.624A.5.5 0 006.958 13l-1.5-.442a.709.709 0 00-.839.4l-1.963 4.173a.832.832 0 00.257 1.028A9.983 9.983 0 005 19.275a.5.5 0 00.655-.321zM20.674 12.261a.5.5 0 01.081-.561c1.125-1.215 1.4-2.95 2.383-2.95 1.039 0 1.294 3.427-.11 6.515a.5.5 0 01-.906.008 357.931 357.931 0 00-1.448-3.012zM16.7 13.624a.5.5 0 01.342-.624l1.5-.442a.709.709 0 01.839.4l1.967 4.173a.832.832 0 01-.257 1.028A9.983 9.983 0 0119 19.275a.5.5 0 01-.655-.321zM16.966 19.39l-1.821-5.739a.736.736 0 00-.694-.553h-4.9a.736.736 0 00-.694.553L7.034 19.39a.809.809 0 00.44.979 12.066 12.066 0 009.052 0 .809.809 0 00.44-.979zM8.114 11.775a1 1 0 00.647-.027 2.156 2.156 0 01.788-.15h4.9a2.14 2.14 0 01.785.149 1 1 0 00.648.028l.4-.118A1 1 0 0017 10.7V9.25h1a3.25 3.25 0 000-6.5H7a1 1 0 00-1 1v6.65a1 1 0 00.716.959zM17 4.75a.5.5 0 01.5-.5h.5a1.75 1.75 0 010 3.5h-.5a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});