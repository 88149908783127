define("ember-svg-jar/inlined/snail-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>snail-1</title><path d=\"M5.64 11.823a4.357 4.357 0 00-1.04-.977l2.3-3.908a1.5 1.5 0 10-1.795-.891L3.73 8.383l-.83-2.33v-.012A1.476 1.476 0 003 5.5a1.5 1.5 0 10-1.906 1.437l1.187 3.34a2.684 2.684 0 00-.3.054C.745 10.675.005 11.859.005 13.5a6.508 6.508 0 006.5 6.5h3.012a.25.25 0 00.139-.458 8.752 8.752 0 01-4.016-7.719zM22.145 16.611a8.944 8.944 0 01-2.787 2.93.25.25 0 00.142.459h4a.5.5 0 00.493-.582 4.339 4.339 0 00-1.848-2.807z\"/><path d=\"M21.875 12.125A7.375 7.375 0 108.94 16.966a.25.25 0 00.425-.247 7.824 7.824 0 01-.434-2.558 6.465 6.465 0 011.463-4.234A5.744 5.744 0 0114.917 8a4.005 4.005 0 014 4.086 3.188 3.188 0 01-3.332 3.331 2.211 2.211 0 01-2.208-2.209.75.75 0 111.5 0 .709.709 0 00.708.709 1.7 1.7 0 001.832-1.831 2.493 2.493 0 00-2.5-2.586 4.3 4.3 0 00-3.386 1.4 4.979 4.979 0 00-1.1 3.258 5.52 5.52 0 003.44 5.307c.208.018.417.032.629.032a7.375 7.375 0 007.375-7.372z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});