define("ember-svg-jar/inlined/raccoon-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>raccoon-1</title><circle cx=\"8.998\" cy=\"4.631\" r=\"1\"/><path d=\"M10.284 15.5a.5.5 0 00-.712-.02l-5.124 4.928a.5.5 0 00.031.748 12.081 12.081 0 006.871 2.7.5.5 0 00.532-.5v-6.25a.5.5 0 00-.231-.421 7.152 7.152 0 01-1.367-1.185zM7.287 12.383c-1.516-1.143-4.1-1.241-5.5-1.191a1.689 1.689 0 00-1.219.582 1.725 1.725 0 00-.429 1.283A11.641 11.641 0 002.644 19.3a.5.5 0 00.738.049l5.232-5.027a.5.5 0 00.02-.7 10.4 10.4 0 00-1.347-1.239z\"/><path d=\"M16.291.92a2.663 2.663 0 01-.261 2.713 2.263 2.263 0 01-1.809.785 3.662 3.662 0 01-.892-.116.75.75 0 01.371-1.455c.32.082.9.168 1.176-.167a1.236 1.236 0 000-1.255A2.308 2.308 0 0013.149.19 11.771 11.771 0 0012.1.142c-4.746 0-6 1.626-7 2.933A2.694 2.694 0 012.519 4.5a.752.752 0 00-.7 1.028C1.869 5.643 3 8.414 5.39 8.414h6.437v.7c0 4.322 1.476 6.935 3.958 7.034a7.4 7.4 0 001.795-3.3.75.75 0 011.467.311c-.926 4.534-3.565 4.594-5.086 4.349a.5.5 0 00-.579.494v5.24a.5.5 0 00.58.494A11.9 11.9 0 0016.291.92zm-5.5 4.813a2.27 2.27 0 01-3.068 0l-1.129-.926a.255.255 0 01-.04-.348 4.686 4.686 0 012.673-1.952.256.256 0 01.249.066l.863.876c.837.845 1.003 1.678.454 2.284z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});