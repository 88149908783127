define("ember-svg-jar/inlined/gender-female", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gender-female</title><path d=\"M19.75 7.75a7.75 7.75 0 10-9.2 7.6.249.249 0 01.2.245v2.655a.25.25 0 01-.25.25H9A1.25 1.25 0 009 21h1.5a.25.25 0 01.25.25v1.5a1.25 1.25 0 002.5 0v-1.5a.25.25 0 01.25-.25H15a1.25 1.25 0 000-2.5h-1.5a.25.25 0 01-.25-.25V15.6a.249.249 0 01.2-.245 7.756 7.756 0 006.3-7.605zm-13 0A5.25 5.25 0 1112 13a5.256 5.256 0 01-5.25-5.25z\"/><path d=\"M19.75 7.75a7.75 7.75 0 10-9.2 7.6.249.249 0 01.2.245v2.655a.25.25 0 01-.25.25H9A1.25 1.25 0 009 21h1.5a.25.25 0 01.25.25v1.5a1.25 1.25 0 002.5 0v-1.5a.25.25 0 01.25-.25H15a1.25 1.25 0 000-2.5h-1.5a.25.25 0 01-.25-.25V15.6a.249.249 0 01.2-.245 7.756 7.756 0 006.3-7.605zm-13 0A5.25 5.25 0 1112 13a5.256 5.256 0 01-5.25-5.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});