define("ember-svg-jar/inlined/single-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman</title><path d=\"M5.137 11.442a1 1 0 101.68 1.085 6.813 6.813 0 00.721-1.512.25.25 0 01.406-.1 5.971 5.971 0 008.112 0 .25.25 0 01.406.1 6.813 6.813 0 00.721 1.512 1 1 0 101.68-1.085A6.09 6.09 0 0118 8.092V6.5a6 6 0 00-12 0v1.592a6.09 6.09 0 01-.863 3.35zM12 10.5a4 4 0 01-3.981-3.7.251.251 0 01.191-.262 7.268 7.268 0 003.631-2.1.251.251 0 01.364 0 7.27 7.27 0 003.588 2.088.249.249 0 01.19.261A4 4 0 0112 10.5zM12 13.5A9.511 9.511 0 002.5 23a.5.5 0 00.5.5h18a.5.5 0 00.5-.5 9.511 9.511 0 00-9.5-9.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});