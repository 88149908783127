define("ember-svg-jar/inlined/style-three-pin-hindu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-hindu</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm3.25 3.25a1 1 0 11-1 1 1 1 0 011-1zm.487 11.961a.731.731 0 01-.237.039.75.75 0 01-.237-1.461c1.3-.435 1.987-1.229 1.987-1.789 0-.84-1.542-1.115-3.11-1.2a.25.25 0 00-.236.366 2.813 2.813 0 01.346 1.334A3.943 3.943 0 0110 16.75a4.748 4.748 0 01-3.849-1.994 3.685 3.685 0 01-.351-3.534.749.749 0 011.392.556 2.194 2.194 0 00.2 2.137A3.251 3.251 0 0010 15.25a2.459 2.459 0 002.75-2.75 2.826 2.826 0 00-1.05-1.731.06.06 0 00-.044-.019H11a.75.75 0 010-1.5h.438a.249.249 0 00.221-.133A5.042 5.042 0 0012.25 7.5c0-1.624-1.723-1.75-2.25-1.75a1.762 1.762 0 00-1.441.75.75.75 0 11-1.118-1A3.242 3.242 0 0110 4.25a3.465 3.465 0 013.616 2.322.25.25 0 00.19.177c.859.177 2.251.335 2.472-.452a.75.75 0 011.444.406c-.313 1.112-1.271 1.69-2.721 1.69a6.831 6.831 0 01-1.153-.119.25.25 0 00-.282.172c-.049.16-.106.322-.169.482a.25.25 0 00.225.341c2.447.079 5.128.5 5.128 2.731 0 1.341-1.183 2.6-3.013 3.211z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});