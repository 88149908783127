define("ember-svg-jar/inlined/envelope-sealed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>envelope-sealed</title><path d=\"M7.61 3.746h8.8c1-.031 1.077-.351 3.627-3.329a.25.25 0 00-.19-.417H4.153a.251.251 0 00-.19.413C6.5 3.377 6.593 3.719 7.61 3.746z\"/><path d=\"M18.531 4.487a3.046 3.046 0 01-2.1.758H13a.25.25 0 00-.25.25v14a.25.25 0 00.25.25h5.5a.748.748 0 01.576.27l2.373 2.848a1.5 1.5 0 00.051-.363v-21a1.478 1.478 0 00-.052-.372zM4.924 20.016a.748.748 0 01.576-.27H11a.25.25 0 00.25-.25V5.5a.25.25 0 00-.25-.25H7.591a3.07 3.07 0 01-2.139-.778l-2.9-3.344A1.478 1.478 0 002.5 1.5v21a1.5 1.5 0 00.051.368z\"/><path d=\"M18.224 21.338a.252.252 0 00-.194-.092H5.97a.252.252 0 00-.194.092l-1.835 2.25a.25.25 0 00.194.412h15.73a.25.25 0 00.194-.408z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});