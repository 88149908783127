define("ember-svg-jar/inlined/messages-bubble-settings-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-settings-1</title><path d=\"M10.447 12.759a3.258 3.258 0 013.572-1.551l.229.053a.25.25 0 00.295-.171l.071-.234a3.275 3.275 0 016.268 0l.071.234a.251.251 0 00.3.171l.228-.053a3.236 3.236 0 011.178-.048.251.251 0 00.267-.154A8 8 0 0015.5.031H8a7.993 7.993 0 00-3 15.4v4.1a.5.5 0 00.853.354l3.853-3.854h.333a.249.249 0 00.23-.348 3.256 3.256 0 01.178-2.924z\"/><circle cx=\"17.748\" cy=\"17\" r=\"1.5\"/><path d=\"M23.516 15.622a1.783 1.783 0 00-1.7-2.953L20.4 13a.452.452 0 01-.532-.31l-.424-1.392a1.775 1.775 0 00-3.4 0l-.424 1.392a.453.453 0 01-.533.31l-1.411-.327a1.783 1.783 0 00-1.7 2.953l.988 1.066a.461.461 0 010 .624l-.988 1.066a1.783 1.783 0 001.7 2.953L15.092 21a.454.454 0 01.533.31l.424 1.392a1.775 1.775 0 003.4 0l.424-1.392a.45.45 0 01.533-.31l1.411.327a1.783 1.783 0 001.7-2.953l-.987-1.066a.461.461 0 010-.624zM20.748 17a3 3 0 11-3-3 3 3 0 013 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});