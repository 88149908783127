define("ember-svg-jar/inlined/real-estate-favorite-house-pick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-favorite-house-pick</title><path d=\"M22.468 16.626L15.3 10.252a1 1 0 00-1.328 0L6.8 16.626a.5.5 0 00.332.874h2a.5.5 0 01.5.5v5a1 1 0 001 1h2a.5.5 0 00.5-.5V21a1.5 1.5 0 013 0v2.5a.5.5 0 00.5.5h2a1 1 0 001-1v-5a.5.5 0 01.5-.5h2a.5.5 0 00.336-.874zM7.974 12A1.724 1.724 0 009.7 10.277V5.865a.5.5 0 01.853-.354l.127.127a1.764 1.764 0 002.435 0 1.726 1.726 0 000-2.436L10.056.146A.5.5 0 009.7 0H1.863a.5.5 0 00-.5.5v1.223A4.171 4.171 0 005.53 5.889h.222a.5.5 0 01.5.5v3.888A1.724 1.724 0 007.974 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});