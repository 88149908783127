define("ember-svg-jar/inlined/messages-bubble-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-graph</title><path d=\"M12 1.337C5.659 1.337.5 5.587.5 10.811a8.6 8.6 0 003.174 6.54l-2.3 4.587a.5.5 0 00.659.677l6.15-2.862a13.733 13.733 0 003.817.532c6.341 0 11.5-4.25 11.5-9.474S18.341 1.337 12 1.337zm6.5 13.793h-12a.755.755 0 01-.755-.755v-8a.755.755 0 111.509 0v7.245h1.49v-2.745a.755.755 0 111.509 0v2.745h1.491V9.375a.755.755 0 111.509 0v4.245h1.491V7.375a.755.755 0 111.509 0v6.245H18.5a.755.755 0 010 1.51z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});