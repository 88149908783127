define("ember-svg-jar/inlined/tools-wench-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-wench-1</title><path d=\"M23.215 3.889a.507.507 0 00-.81-.128l-4.077 4.077a.506.506 0 000 .717l3.214 3.214a.507.507 0 00.358.149h.019a.505.505 0 00.366-.176 7.065 7.065 0 00.93-7.853z\"/><path d=\"M16.16 8.54a.5.5 0 01-.143-.409l.266-2.4a.5.5 0 01.143-.3l3.828-3.821a.507.507 0 00-.128-.81 7.1 7.1 0 00-9.847 8.9.5.5 0 01-.112.536L.746 19.655a2.533 2.533 0 103.578 3.585l9.407-9.409a.5.5 0 01.539-.112 7.111 7.111 0 005.856-.275.506.506 0 00.128-.81z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});