define("ember-svg-jar/inlined/whale-tail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>whale-tail</title><path d=\"M6.85 8.4c3.35 1.914 3.212 2.741 2.755 5.483-.3 1.814-.607 3.454-.8 4.459a2.967 2.967 0 001.662-1.018 2.584 2.584 0 012.005-1.006 2.505 2.505 0 012.261 1.424 1.262 1.262 0 001.638.511 207.944 207.944 0 01-.782-4.37c-.456-2.742-.594-3.57 2.755-5.483a5.321 5.321 0 002.546-5.372 1 1 0 00-1.767-.558 5.23 5.23 0 01-2.66 1.51A6.315 6.315 0 0012.6 6.708 6.321 6.321 0 008.736 3.98a5.3 5.3 0 01-2.663-1.515 1 1 0 00-1.773.558A5.322 5.322 0 006.85 8.4z\"/><path d=\"M22.93 19.854a3.4 3.4 0 01-2.676-1.57 1.044 1.044 0 00-1.72.029 3.354 3.354 0 01-2.748 1.552 2.8 2.8 0 01-2.4-1.483 1.045 1.045 0 00-1.714-.159 4.3 4.3 0 01-3.108 1.642A3.56 3.56 0 015.8 18.284a1.041 1.041 0 00-1.638-.091c-.015.018-1.477 1.769-3.109 1.7a.982.982 0 00-1.053.95c-.01.189.043 1.056 1.168 1.056a5.837 5.837 0 003.726-1.606 5.244 5.244 0 003.664 1.572 5.858 5.858 0 003.811-1.5 4.573 4.573 0 003.416 1.5 5.151 5.151 0 003.622-1.5 4.8 4.8 0 003.661 1.482 1 1 0 10-.139-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});