define("ember-svg-jar/inlined/dog-head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dog-head</title><path d=\"M12 19.579a4.473 4.473 0 01-4.236 1.615 6.536 6.536 0 008.466 0A4.459 4.459 0 0112 19.579z\"/><path d=\"M21.626 1.875a9.012 9.012 0 00-6.078-.069 9.974 9.974 0 00-3.539-.556 10.055 10.055 0 00-3.56.556 8.98 8.98 0 00-6.069.069C.742 2.42-.14 4.344.022 7.021c.158 2.622 1.326 5.538 3.01 5.879a2.141 2.141 0 001.153-.075 16.4 16.4 0 001.639 5.9c.228.093 2.051 1.707 4.046.707a2.39 2.39 0 001.372-1.793A1.571 1.571 0 0110 16.246c0-.828.895-1.5 2-1.5s2 .672 2 1.5a1.571 1.571 0 01-1.244 1.389 2.384 2.384 0 001.337 1.774c1.966 1.02 3.722-.537 4.037-.614a16.326 16.326 0 001.683-5.97 2.155 2.155 0 001.153.075c1.681-.341 2.851-3.258 3.01-5.881.164-2.676-.714-4.599-2.35-5.144zM9.5 12.24a1.25 1.25 0 111.25-1.25 1.25 1.25 0 01-1.25 1.25zm5 0a1.25 1.25 0 111.25-1.25 1.25 1.25 0 01-1.25 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});