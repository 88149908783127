define("ember-svg-jar/inlined/composition-16-to-9-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>composition-16-to-9-alternate</title><path d=\"M24 7.75a4 4 0 00-4-4H4a4 4 0 00-4 4v8.5a4 4 0 004 4h16a4 4 0 004-4zm-2 8.5a2 2 0 01-2 2H4a2 2 0 01-2-2v-8.5a2 2 0 012-2h16a2 2 0 012 2z\"/><circle cx=\"14.5\" cy=\"10\" r=\"1\"/><circle cx=\"14.5\" cy=\"14\" r=\"1\"/><path d=\"M7.5 14.5a.75.75 0 00-.75-.75.25.25 0 01-.25-.25V10a1.25 1.25 0 00-2-1l-1.2.9a.75.75 0 10.9 1.2l.4-.3a.25.25 0 01.4.2v2.5a.25.25 0 01-.25.25.75.75 0 000 1.5h2a.75.75 0 00.75-.75zM10.25 10.75a.188.188 0 01-.089-.353 1.245 1.245 0 01.589-.147.75.75 0 000-1.5A2.752 2.752 0 008 11.5V13a2.25 2.25 0 102.25-2.25zm0 3A.75.75 0 1111 13a.751.751 0 01-.75.75zM18.75 8.75a2.25 2.25 0 000 4.5.188.188 0 01.089.353 1.245 1.245 0 01-.589.147.75.75 0 000 1.5A2.752 2.752 0 0021 12.5V11a2.253 2.253 0 00-2.25-2.25zm0 3a.75.75 0 11.75-.75.751.751 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});