define("ember-svg-jar/inlined/camera-settings-rotate-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camera-settings-rotate-1</title><path d=\"M3.184 11.336a.251.251 0 00-.357-.226C1.1 11.927 0 13.041 0 14.439c0 2 2.378 3.634 6.412 4.457a.249.249 0 01.2.245V20.6a.49.49 0 00.836.346L9.694 18.7a.489.489 0 000-.693l-2.246-2.242a.49.49 0 00-.836.346v.512a.25.25 0 01-.306.244c-2.987-.692-4.347-1.786-4.347-2.428 0-.394.429-.855 1.217-1.295a.25.25 0 00.12-.282 3.439 3.439 0 01-.112-.872zM21.173 11.11a.251.251 0 00-.357.226v.654a3.439 3.439 0 01-.112.872.25.25 0 00.12.282c.788.44 1.217.9 1.217 1.3 0 .964-2.669 2.521-7.68 2.933a.979.979 0 10.161 1.952C18.894 18.965 24 17.47 24 14.439c0-1.398-1.1-2.512-2.827-3.329z\"/><path d=\"M19.5 6.906a2 2 0 00-2-2H16a.913.913 0 01-.5-.207l-1.293-1.293a1.872 1.872 0 00-1.207-.5h-2a1.872 1.872 0 00-1.207.5L8.5 4.7a.913.913 0 01-.5.207H6.5a2 2 0 00-2 2v5a2 2 0 002 2h11a2 2 0 002-2zm-10 2.5a2.5 2.5 0 112.5 2.5 2.5 2.5 0 01-2.5-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});