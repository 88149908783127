define("ember-svg-jar/inlined/decoration-flower-vase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>decoration-flower-vase</title><circle cx=\"19.25\" cy=\"6.5\" r=\"1\"/><circle cx=\"9.75\" cy=\"1\" r=\"1\"/><circle cx=\"3.75\" cy=\"1.5\" r=\"1\"/><circle cx=\"1.75\" cy=\"6\" r=\"1\"/><circle cx=\"5.75\" cy=\"8\" r=\"1\"/><circle cx=\"22.25\" cy=\"4\" r=\"1\"/><circle cx=\"21.25\" cy=\"1\" r=\"1\"/><path d=\"M13.551 11.084a.991.991 0 00-.4-.084H11.03a.251.251 0 01-.235-.164v-.012a.217.217 0 01.076-.252 17.4 17.4 0 005.446-6.538.5.5 0 01.451-.284h2.41a.75.75 0 000-1.5H17.45a.251.251 0 01-.207-.11.254.254 0 01-.025-.233l.252-.629a.75.75 0 00-1.393-.556A17.4 17.4 0 0110.464 9a.249.249 0 01-.388-.109A64.242 64.242 0 017.392.784a.75.75 0 10-1.436.432l.885 2.95a.25.25 0 01-.338.3l-1.534-.655a.75.75 0 10-.591 1.378L7.4 6.483a.5.5 0 01.27.279c.274.71.832 2.174 1.592 4.238H8.35a.991.991 0 00-.4.084 7 7 0 00-4.2 6.416c0 3.7 3.009 6.5 7 6.5s7-2.8 7-6.5a7 7 0 00-4.199-6.416zM12.813 13a.5.5 0 01.229.055A5 5 0 0115.75 17.5a4.326 4.326 0 01-.345 1.7.5.5 0 01-.461.3h-.716a.5.5 0 01-.473-.337 452.23 452.23 0 00-2.052-5.828.25.25 0 01.235-.335zm-4.355.055A.505.505 0 018.687 13h1.3c.641 1.771 1.38 3.85 2.182 6.168a.251.251 0 01-.237.332H6.556a.5.5 0 01-.461-.3 4.326 4.326 0 01-.345-1.7 5 5 0 012.708-4.445z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});