define("ember-svg-jar/inlined/vintage-car-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-car-3</title><path d=\"M23.2 15.52a.25.25 0 01-.2-.245V12.5a7.008 7.008 0 00-7-7h-3.88a1.993 1.993 0 00-1.795 1.1L8.951 9.362a.253.253 0 01-.224.138H2.85a1.993 1.993 0 00-1.976 1.676l-.641 3.85-.219 1.308A1 1 0 001 17.5h.847a.25.25 0 00.242-.312A2.784 2.784 0 012 16.5a3 3 0 016 0 2.784 2.784 0 01-.089.688.25.25 0 00.243.312h4.693a.25.25 0 00.242-.312A2.784 2.784 0 0113 16.5a3 3 0 016 0 2.784 2.784 0 01-.089.688.25.25 0 00.242.312H23a1 1 0 00.2-1.98zm-6.71-7.538l-1.049 2a.5.5 0 01-.441.268h-3.317a.5.5 0 01-.448-.723l1-2a.5.5 0 01.447-.277h3.367a.5.5 0 01.443.732z\"/><circle cx=\"5\" cy=\"16.5\" r=\"2\"/><circle cx=\"16\" cy=\"16.5\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});