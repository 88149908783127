define("ember-svg-jar/inlined/outdoors-machete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-machete</title><path d=\"M8.667 16.962l-1.883-2.477a.5.5 0 00-.706-.091L.986 18.377a2.5 2.5 0 00-.478 3.5l.605.8a1.487 1.487 0 001.182.589 1.439 1.439 0 00.338-.04.5.5 0 00.374-.385c.167-.806.772-1.529 2.233-2.668 1.111-.866 3.325-2.5 3.326-2.506a.5.5 0 00.1-.7zM23.88 1.605a1 1 0 00-1.595-.667L14.3 6.946a.25.25 0 00.009.407l1.524 1.04a.75.75 0 01-.424 1.369.739.739 0 01-.422-.131l-2.081-1.422a.249.249 0 00-.291.007l-1.137.856a.247.247 0 00-.1.205.253.253 0 00.109.2l1.625 1.11a.75.75 0 11-.846 1.238l-2.184-1.492a.251.251 0 00-.291.007L8.4 11.385a.25.25 0 01-.349-.048l-.132-.174a.75.75 0 00-1.195.908l4.236 5.573a.75.75 0 001.2-.908l-.149-.2a.246.246 0 01-.049-.185.251.251 0 01.1-.166L22.244 8.5c1.962-1.988 1.867-5.173 1.636-6.895z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});