define("ember-svg-jar/inlined/crypto-currency-bitcoin-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-circle</title><path d=\"M10.593 7.846a.25.25 0 00-.25.25v2.169a.25.25 0 00.25.25h1.73a1.335 1.335 0 000-2.669zM12.323 13.015h-1.73a.25.25 0 00-.25.25v2.17a.25.25 0 00.25.25h1.73a1.335 1.335 0 000-2.67z\"/><path d=\"M12 0a12 12 0 1012 12A12 12 0 0012 0zm3.275 11.606a.249.249 0 000 .318 3.806 3.806 0 01-2.757 6.237.25.25 0 00-.238.249V19a1.25 1.25 0 01-2.5 0v-.565a.249.249 0 00-.25-.25h-.437a1.25 1.25 0 01-1.25-1.25V6.6a1.249 1.249 0 011.25-1.25h.438a.25.25 0 00.25-.25V5a1.25 1.25 0 112.5 0v.151a.218.218 0 00.209.218 3.8 3.8 0 012.785 6.237z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});