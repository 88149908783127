define("ember-svg-jar/inlined/ipod", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ipod</title><path d=\"M21 8.5h-4a1 1 0 010-2h4a3 3 0 000-6H10.5A2.5 2.5 0 008 3v2.25a.25.25 0 01-.25.25H2.5A2.5 2.5 0 000 8v13a2.5 2.5 0 002.5 2.5h8A2.5 2.5 0 0013 21V8a2.5 2.5 0 00-2.5-2.5h-.25a.25.25 0 01-.25-.25V3a.5.5 0 01.5-.5H21a1 1 0 010 2h-4a3 3 0 000 6h4a1 1 0 010 2h-3a3 3 0 00-3 3v.75a.25.25 0 00.25.25h1.5a.25.25 0 00.25-.25v-.75a1 1 0 011-1h3a3 3 0 000-6zM10.5 12a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5V9a.5.5 0 01.5-.5h7a.5.5 0 01.5.5zm-1 6a3 3 0 11-3-3 3 3 0 013 3z\"/><path d=\"M21.633 16.727a.994.994 0 00-.832-.207 3.492 3.492 0 00-2.132 1.38.252.252 0 01-.2.1H15.5a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3.042a.253.253 0 01.2.1 3.485 3.485 0 002.058 1.28 1 1 0 001.2-.98v-4.9a.993.993 0 00-.367-.773z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});