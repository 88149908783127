define("ember-svg-jar/inlined/folder-lock-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-lock-1</title><path d=\"M12 9.322a1.7 1.7 0 00-1.7 1.7v.221a.25.25 0 00.25.25h2.89a.25.25 0 00.25-.25v-.221a1.7 1.7 0 00-1.69-1.7zM12 14.1a1.222 1.222 0 101.222 1.222A1.223 1.223 0 0012 14.1z\"/><path d=\"M22.5 4.5H11.736a.5.5 0 01-.447-.276l-.947-1.9A1.494 1.494 0 009 1.5H1.5A1.5 1.5 0 000 3v18a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 21V6a1.5 1.5 0 00-1.5-1.5zm-6.333 12.85a1.5 1.5 0 01-1.5 1.5H9.333a1.5 1.5 0 01-1.5-1.5v-4.112a1.486 1.486 0 01.834-1.327.249.249 0 00.138-.223v-.671a3.2 3.2 0 016.39 0v.66a.249.249 0 00.138.223 1.5 1.5 0 01.834 1.338z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});