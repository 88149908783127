define("ember-svg-jar/inlined/social-media-twitter-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-twitter-1</title><path d=\"M21 20.5a3.5 3.5 0 00-3.5-3.5h-5a2.5 2.5 0 01-2.5-2.5v-2a.5.5 0 01.5-.5h7a3.5 3.5 0 000-7h-7a.5.5 0 01-.5-.5v-1a3.5 3.5 0 00-7 0v12a8.51 8.51 0 008.5 8.5h6a3.5 3.5 0 003.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});