define("ember-svg-jar/inlined/task-list-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-list-settings</title><path d=\"M12.749 8.531a.75.75 0 00-.75-.75H6a.75.75 0 000 1.5h6a.75.75 0 00.749-.75z\"/><path d=\"M10.035 19.277a.251.251 0 00-.253-.247H3.5a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v3.819a.248.248 0 00.429.166 3.325 3.325 0 012.3-.986.249.249 0 00.271-.245V3.531a1.5 1.5 0 00-1.5-1.5h-4a.243.243 0 01-.208-.121 3.827 3.827 0 00-6.588 0 .243.243 0 01-.208.121h-4A1.5 1.5 0 000 3.531v17a1.5 1.5 0 001.5 1.5h9.03a.247.247 0 00.2-.395 4.4 4.4 0 01-.695-2.359z\"/><path d=\"M11.95 11.427c.081-.045.115-.146.049-.146H6a.75.75 0 000 1.5h4.324a.249.249 0 00.186-.083 6.978 6.978 0 011.44-1.271zM9.812 14.781H6a.75.75 0 100 1.5h4a.676.676 0 00.208-.033.263.263 0 00.168-.354 7.614 7.614 0 01-.324-.938.244.244 0 00-.24-.175z\"/><circle cx=\"17.749\" cy=\"16.999\" r=\"1.5\"/><path d=\"M22.529 17.312a.464.464 0 010-.626l.988-1.064a1.783 1.783 0 00-1.7-2.953L20.406 13a.451.451 0 01-.534-.311l-.424-1.392a1.775 1.775 0 00-3.4 0l-.424 1.391a.455.455 0 01-.533.312l-1.411-.327a1.783 1.783 0 00-1.7 2.953l.988 1.064a.464.464 0 010 .626l-.988 1.065a1.783 1.783 0 001.7 2.953L15.092 21a.452.452 0 01.534.311l.424 1.392a1.775 1.775 0 003.4 0l.424-1.392a.451.451 0 01.531-.311l1.411.327a1.783 1.783 0 001.7-2.953zM17.749 20a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});