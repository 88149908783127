define("ember-svg-jar/inlined/safety-call-firefighters-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-call-firefighters-1</title><path d=\"M12.812 18.049a1.6 1.6 0 00-2.252.086l-.44.427a.249.249 0 01-.334.011 32.282 32.282 0 01-4.515-4.643.25.25 0 01.022-.334l.439-.427a1.6 1.6 0 00.151-2.249L4.306 9.3a1.6 1.6 0 00-2.252.087l-.988.96a2.459 2.459 0 00-.5 3.012 31.6 31.6 0 009.66 9.936 2.459 2.459 0 003.025-.415l.988-.96a1.6 1.6 0 00.149-2.25zM23.657 8.43a.989.989 0 00-.587-.752 1 1 0 00-.95.082 6 6 0 01-.383.232.251.251 0 01-.37-.194A8.689 8.689 0 0015.214.4a.984.984 0 00-1.02.283A1 1 0 0014 1.725a5.9 5.9 0 01.181 3.78.25.25 0 01-.463.044 5.937 5.937 0 01-.17-.361.991.991 0 00-.748-.588 1 1 0 00-.905.3A6.84 6.84 0 1023.75 9.552a6.742 6.742 0 00-.093-1.122zm-6.747 6.611a2.682 2.682 0 01-2.67-2.741 5.053 5.053 0 012.825-4.471.25.25 0 01.323.354 2.909 2.909 0 00-.216 2.434.25.25 0 00.408.088 1.5 1.5 0 00.372-.539.25.25 0 01.352-.134 2.481 2.481 0 011.275 2.344 2.617 2.617 0 01-2.669 2.665z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});