define("ember-svg-jar/inlined/warehouse-truck-delivery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>warehouse-truck-delivery</title><path d=\"M24 15.868a1.491 1.491 0 00-.829-1.342l-.036-.017a.253.253 0 01-.135-.233c.009-.26 0-1.354 0-6.506a3.1 3.1 0 00-3.1-3.1h-2.265A10.636 10.636 0 007 15.308v3.5a2.02 2.02 0 002.02 2.02h.29a1 1 0 000-2h-.233A.077.077 0 019 18.75V17.4a.252.252 0 01.074-.177c1.7-1.688-.629.639 3.784-3.775a.252.252 0 01.3-.042.724.724 0 00.345.09.75.75 0 00.53-1.28l-1-1a.749.749 0 00-1.06 0 .739.739 0 00-.127.876.25.25 0 01-.044.295l-2.328 2.328a.25.25 0 01-.425-.2 8.7 8.7 0 018.643-7.841H19.9A1.1 1.1 0 0121 7.77v5.73a.25.25 0 01-.25.25h-2.144a.248.248 0 01-.2-.1.252.252 0 01-.046-.214l.866-3.51a.751.751 0 00-1.458-.36l-.983 3.99a.25.25 0 01-.243.19h-.194a1.5 1.5 0 00-.937.329l-2.227 1.78a.225.225 0 00.159.4c.051-.005.1-.007.154-.007a3.5 3.5 0 013.5 3.5 3.185 3.185 0 01-.082.7.25.25 0 00.048.211.253.253 0 00.2.094h1.1a.249.249 0 00.249-.227 2.491 2.491 0 014.716-.884.25.25 0 00.44.014A2.437 2.437 0 0024 18.421z\"/><circle cx=\"21\" cy=\"20.75\" r=\"1.5\"/><circle cx=\"13.5\" cy=\"19.75\" r=\"2.5\"/><path d=\"M4.5 1.75a1 1 0 00-1 1V13.5a.25.25 0 01-.25.25H2a1 1 0 000 2h1.25a.25.25 0 01.25.25v2.75a1 1 0 01-1 1H1a1 1 0 000 2h1.5a3 3 0 003-3v-16a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});