define("ember-svg-jar/inlined/people-man-glasses-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-glasses-1</title><path d=\"M.735 23.51a.489.489 0 00.489.49h21.552a.489.489 0 00.489-.49v-3.043a2.413 2.413 0 00-.947-1.936 11.938 11.938 0 00-2.577-1.449.244.244 0 01-.149-.23.724.724 0 00-.025-.2.733.733 0 00-.479-.51 19.348 19.348 0 00-2.823-.7.742.742 0 00-.582.15l-3.53 2.819a.246.246 0 01-.306 0l-3.53-2.819a.743.743 0 00-.582-.15 19.348 19.348 0 00-2.823.7.733.733 0 00-.479.51.724.724 0 00-.025.2.244.244 0 01-.149.23 11.938 11.938 0 00-2.577 1.449 2.413 2.413 0 00-.947 1.936zm6.008-5.994a.244.244 0 01-.052-.237.241.241 0 01.177-.165c.225-.054.452-.1.681-.147a.244.244 0 01.2.049l2.834 2.262a.245.245 0 010 .383l-.928.74a.244.244 0 01-.334-.027zm7.936 2.858a.244.244 0 01-.334.027l-.928-.74a.245.245 0 010-.383l2.845-2.271a.216.216 0 01.175-.043c.234.046.465.1.7.15a.241.241 0 01.177.165.244.244 0 01-.052.237z\"/><path d=\"M12 0a6.865 6.865 0 00-6.857 6.857v1.959a6.857 6.857 0 0013.714 0V6.857A6.865 6.865 0 0012 0zm4.448 7.114h.45v.656a2.45 2.45 0 00-.45-.656zm-.775 1.7a.98.98 0 11-.979-.979.981.981 0 01.979.981zm-3.248-.891a.244.244 0 01-.228.157H11.8a.244.244 0 01-.228-.157 2.439 2.439 0 00-.212-.423.246.246 0 01.208-.376h.864a.246.246 0 01.208.376 2.439 2.439 0 00-.215.425zm-4.873-.809a2.45 2.45 0 00-.45.654v-.656zm1.754.723a.98.98 0 11-.979.979.981.981 0 01.979-.979zM12 13.714A4.861 4.861 0 017.806 11.3a.245.245 0 01.329-.339 2.4 2.4 0 001.171.3 2.44 2.44 0 002.269-1.559.246.246 0 01.228-.155h.397a.246.246 0 01.228.155 2.44 2.44 0 002.269 1.559 2.4 2.4 0 001.171-.3.245.245 0 01.329.339A4.861 4.861 0 0112 13.714z\"/><path d=\"M13.055 11.13H11.1a.626.626 0 00-.3 1.178 2.309 2.309 0 00.426.176 2.945 2.945 0 00.849.124h.006a2.933 2.933 0 00.846-.123 2.293 2.293 0 00.427-.177.626.626 0 00-.3-1.177z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});