define("ember-svg-jar/inlined/day-sunrise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>day-sunrise</title><path d=\"M7.488 14.008l.064-.057a.249.249 0 00.054-.307 5 5 0 118.781-.008.25.25 0 00.053.306l.073.065a2.008 2.008 0 01.655 1.284.249.249 0 00.449.122 7 7 0 10-11.232.019.25.25 0 00.449-.125 2.005 2.005 0 01.654-1.299zM1.744 12.25h1.5a1 1 0 000-2h-1.5a1 1 0 000 2z\"/><path d=\"M5.1 5.77a1 1 0 101.414-1.415L5.453 3.3a1 1 0 00-1.414 1.409zM11.994 3.5a1 1 0 001-1V1a1 1 0 00-2 0v1.5a1 1 0 001 1zM4.039 19.2a1 1 0 001.414 0l1.061-1.06A1 1 0 105.1 16.73l-1.061 1.061a1 1 0 000 1.409zM19.744 11.25a1 1 0 001 1h1.5a1 1 0 000-2h-1.5a1 1 0 00-1 1zM18.182 6.063a1 1 0 00.707-.293l1.06-1.061A1 1 0 1018.535 3.3l-1.06 1.06a1 1 0 00.707 1.708zM18.889 16.73a1 1 0 00-1.414 1.415l1.06 1.06a1 1 0 001.414-1.414zM13.506 20.5a.5.5 0 00.5-.5v-3.75a.25.25 0 01.25-.25h.926a.5.5 0 00.332-.873L12.338 12.3a.5.5 0 00-.665 0l-3.184 2.83a.5.5 0 00.332.87h.935a.25.25 0 01.25.25V20a.5.5 0 00.5.5zM22.994 22H1.006a1 1 0 000 2h21.988a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});