define("ember-svg-jar/inlined/house-3.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>house-3</title><path d=\"M23 21.629H1a1 1 0 000 2h22a1 1 0 000-2zM20.834 5.109a.251.251 0 00.4-.2v-3.28a.5.5 0 00-.5-.5h-2.5a.5.5 0 00-.5.5v.935a.5.5 0 00.2.4zM2.268 9.629h19.964a1 1 0 00.594-1.8L13.142.667a1.494 1.494 0 00-1.784 0L1.675 7.824a1 1 0 00.593 1.8zM11.5 20.646h9.25a.5.5 0 00.5-.5v-9.017a.5.5 0 00-.5-.5h-17a.5.5 0 00-.5.5v9.017a.5.5 0 00.5.5H6a.5.5 0 00.5-.5v-5.017a.5.5 0 01.5-.5h3.5a.5.5 0 01.5.5v5.017a.5.5 0 00.5.5zm2.25-5.517a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});