define("ember-svg-jar/inlined/crypto-currency-bitcoin-stack-coins", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-stack-coins</title><path d=\"M9.713 18.416a.252.252 0 00-.236-.166H2.75a1 1 0 00-1 1v2a1 1 0 001 1h9a.837.837 0 00.167-.017.249.249 0 00.114-.434 8 8 0 01-2.318-3.383zM9.712 13.084a.253.253 0 00-.031-.228.25.25 0 00-.2-.106H1.25a1 1 0 00-1 1v2a1 1 0 001 1h7.789a.249.249 0 00.249-.275 7.55 7.55 0 01-.038-.725 7.932 7.932 0 01.462-2.666zM13.129 8.9a.25.25 0 00.121-.214V8.25a1 1 0 00-1-1h-9a1 1 0 00-1 1v2a1 1 0 001 1h7.26a.251.251 0 00.2-.106A8.061 8.061 0 0113.129 8.9z\"/><rect x=\".75\" y=\"1.75\" width=\"11\" height=\"4\" rx=\"1\" ry=\"1\"/><path d=\"M17.75 16.5h-1.5a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h1.5a.75.75 0 000-1.5z\"/><path d=\"M17.25 9.25a6.5 6.5 0 106.5 6.5 6.5 6.5 0 00-6.5-6.5zm-2.75 3.5a.75.75 0 01.75-.75.25.25 0 00.25-.25.75.75 0 011.5 0 .25.25 0 00.25.25h.5A2.253 2.253 0 0120 14.25a2.219 2.219 0 01-.46 1.349.249.249 0 000 .3A2.219 2.219 0 0120 17.25a2.253 2.253 0 01-2.25 2.25h-.5a.25.25 0 00-.25.25.75.75 0 01-1.5 0 .25.25 0 00-.25-.25.75.75 0 01-.75-.75z\"/><path d=\"M16.25 13.5a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h1.5a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});