define("ember-svg-jar/inlined/java-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>java-1</title><path d=\"M19.021 14.126a.875.875 0 00-.875.875v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V15a.876.876 0 00-.875-.874zM9.021 14.126a.875.875 0 00-.875.874v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V15a.876.876 0 00-.875-.874z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM6.146 18A2.125 2.125 0 011.9 18a.625.625 0 011.25 0 .875.875 0 001.75 0v-4.5a.625.625 0 011.25 0zm5 1.5a.625.625 0 01-1.25 0v-2.124a.25.25 0 00-.25-.25H8.4a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0v-4.374a2.212 2.212 0 011.9-2.239A2.128 2.128 0 0111.146 15zm5-4.5a8.178 8.178 0 01-1.625 4.875.625.625 0 01-1 0A8.178 8.178 0 0111.9 15v-1.5a.625.625 0 111.25 0V15a6.918 6.918 0 00.771 3.161.137.137 0 00.212-.01A6.915 6.915 0 0014.9 15v-1.5a.625.625 0 111.25 0zm5 4.5a.625.625 0 11-1.25 0v-2.124a.25.25 0 00-.25-.25H18.4a.25.25 0 00-.25.25V19.5a.625.625 0 11-1.25 0v-4.374a2.212 2.212 0 011.9-2.239A2.128 2.128 0 0121.146 15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});