define("ember-svg-jar/inlined/police-hat-6", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-hat-6</title><path d=\"M21.5 17.5h-19a.5.5 0 00-.437.744C3.739 21.24 8.01 23.5 12 23.5s8.261-2.26 9.937-5.256a.5.5 0 00-.437-.744zM21.642 6.031A33.968 33.968 0 0012 4.75a33.963 33.963 0 00-9.642 1.281A.5.5 0 002 6.51v8.99a.5.5 0 00.5.5h19a.5.5 0 00.5-.5V6.51a.5.5 0 00-.358-.479zM15 9.674c0 1.717-1.994 3.177-2.73 3.658a.489.489 0 01-.54 0C10.994 12.853 9 11.4 9 9.674V8a1 1 0 011-1h4a1 1 0 011 1zM21.219 1.422A42.343 42.343 0 0012 .5a42.343 42.343 0 00-9.219.922A.994.994 0 002 2.4v1.525a.5.5 0 00.63.483A36.029 36.029 0 0112 3.25a35.982 35.982 0 019.37 1.159.5.5 0 00.63-.483V2.4a.994.994 0 00-.781-.978z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});