define("ember-svg-jar/inlined/real-estate-message-chat-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-message-chat-building</title><path d=\"M20 15V1.5A1.5 1.5 0 0018.5 0h-17A1.5 1.5 0 000 1.5V15a1.5 1.5 0 001.5 1.5h1a.5.5 0 01.5.5v1.811a1.69 1.69 0 002.958 1.112L8.8 16.671a.5.5 0 01.38-.171h9.32A1.5 1.5 0 0020 15zm-2-1a.5.5 0 01-.5.5h-9a1 1 0 00-.752.342l-2.31 2.638A.25.25 0 015 17.315V15.5a1 1 0 00-1-1H2.5A.5.5 0 012 14V2.5a.5.5 0 01.5-.5h15a.5.5 0 01.5.5z\"/><path d=\"M22.5 10.5H22a1 1 0 00-.333 1.943.5.5 0 01.333.472V18a.5.5 0 01-.5.5H21a1 1 0 00-1 1v1.62a.25.25 0 01-.415.188l-2.927-2.561A1 1 0 0016 18.5h-3.5a.5.5 0 01-.5-.5 1 1 0 00-2 0v1a1.5 1.5 0 001.5 1.5h3.937a.5.5 0 01.329.124l3.369 2.946A1.728 1.728 0 0022 22.272V21a.5.5 0 01.5-.5A1.5 1.5 0 0024 19v-7a1.5 1.5 0 00-1.5-1.5zM13.474 4.8l-3-1.5a.5.5 0 00-.448 0l-3 1.5a.5.5 0 00-.276.447v7.5a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5V5.25a.5.5 0 00-.276-.45zM11.75 9.25h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});