define("ember-svg-jar/inlined/dresser-double-door-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dresser-double-door-1</title><path d=\"M1.146 2.854A.5.5 0 001.5 3h21a.5.5 0 00.354-.146l.793-.793A1.208 1.208 0 0022.793 0H1.207a1.207 1.207 0 00-.853 2.061zM22.854 21.146A.5.5 0 0022.5 21h-21a.5.5 0 00-.354.146l-.793.793A1.208 1.208 0 001.207 24h21.586a1.207 1.207 0 00.853-2.061zM11.5 4.5A.5.5 0 0011 4H1.5a.5.5 0 00-.5.5V16a.5.5 0 00.5.5H11a.5.5 0 00.5-.5zM9 14a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5V6.5A.5.5 0 014 6h4.5a.5.5 0 01.5.5zM23 4.5a.5.5 0 00-.5-.5H13a.5.5 0 00-.5.5V16a.5.5 0 00.5.5h9.5a.5.5 0 00.5-.5zM20.5 14a.5.5 0 01-.5.5h-4.5a.5.5 0 01-.5-.5V6.5a.5.5 0 01.5-.5H20a.5.5 0 01.5.5z\"/><rect x=\"12.5\" y=\"17.5\" width=\"10.5\" height=\"2.5\" rx=\".5\" ry=\".5\"/><rect x=\"1\" y=\"17.5\" width=\"10.5\" height=\"2.5\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});