define("ember-svg-jar/inlined/professional-network-nomad-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professional-network-nomad-list</title><path d=\"M12 0a12 12 0 1012 12A12 12 0 0012 0zM9.5 15.44a.5.5 0 01-.28.45l-2.5 1.25a.5.5 0 01-.72-.45V8.81a.5.5 0 01.28-.45l2.86-1.43a.25.25 0 01.36.22zm4 1.4a.25.25 0 01-.36.22l-2.36-1.18a.5.5 0 01-.28-.45V7.15a.25.25 0 01.36-.22l2.36 1.18a.5.5 0 01.28.45zm4.5-1.65a.5.5 0 01-.28.45l-2.86 1.43a.25.25 0 01-.36-.22V8.56a.5.5 0 01.28-.45l2.5-1.25a.5.5 0 01.72.45z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});