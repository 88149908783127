define("ember-svg-jar/inlined/laptop-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-user</title><path d=\"M22.41 4.5a2 2 0 00-2-2h-17a2 2 0 00-2 2v12.682a.319.319 0 01-.32.319 1 1 0 00-.99 1.164l.124.747A2.492 2.492 0 002.7 21.5h18.606a2.492 2.492 0 002.466-2.089l.124-.746a1 1 0 00-.986-1.165.5.5 0 01-.5-.5zm-2 12.5a.5.5 0 01-.5.5h-16a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5h16a.5.5 0 01.5.5z\"/><path d=\"M7.423 14.89a.5.5 0 00.487.611h7.525a.5.5 0 00.488-.611 4.334 4.334 0 00-2.839-3.158.249.249 0 01-.169-.215.252.252 0 01.129-.241 2.863 2.863 0 10-2.743 0 .252.252 0 01.129.241.249.249 0 01-.169.215 4.331 4.331 0 00-2.838 3.158z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});