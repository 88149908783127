define("ember-svg-jar/inlined/single-neutral-actions-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-warning</title><path d=\"M0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.442-5.313a.251.251 0 01.25-.229H10.5a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 10-11 0z\"/><circle cx=\"5.501\" cy=\"3.75\" r=\"3.5\"/><path d=\"M18.782 12.021a1.45 1.45 0 00-2.562 0l-5.055 9.634a1.433 1.433 0 00.048 1.409 1.457 1.457 0 001.233.686h10.11a1.459 1.459 0 001.234-.687 1.434 1.434 0 00.047-1.408zM17.5 15a.75.75 0 01.75.75v2a.75.75 0 01-1.5 0v-2a.75.75 0 01.75-.75zm0 7a1 1 0 111-1 1 1 0 01-1 1zM0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.442-5.313a.251.251 0 01.25-.229H10.5a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 10-11 0z\"/><circle cx=\"5.501\" cy=\"3.75\" r=\"3.5\"/><path d=\"M18.782 12.021a1.45 1.45 0 00-2.562 0l-5.055 9.634a1.433 1.433 0 00.048 1.409 1.457 1.457 0 001.233.686h10.11a1.459 1.459 0 001.234-.687 1.434 1.434 0 00.047-1.408zM17.5 15a.75.75 0 01.75.75v2a.75.75 0 01-1.5 0v-2a.75.75 0 01.75-.75zm0 7a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});