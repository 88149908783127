define("ember-svg-jar/inlined/shipment-monitor-track", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-monitor-track</title><path d=\"M17.175 15.667H3.25a1 1 0 01-1-1V8.625a1 1 0 011-1h8a1 1 0 001-1 1 1 0 00-1-1h-8a3 3 0 00-3 3v8.5a3 3 0 003 3h5.192a.249.249 0 01.23.348l-.536 1.25a.249.249 0 01-.229.152H6.675a.75.75 0 000 1.5h7.075a.75.75 0 000-1.5h-1.157a.249.249 0 01-.229-.152l-.536-1.25a.249.249 0 01.23-.348h5.1a3.01 3.01 0 003-2.942c.019-.679.014-.036.014-4.61a1 1 0 00-1-1 1 1 0 00-1 1v2.1a1 1 0 01-.997.994z\"/><path d=\"M22.75.625H20.5a.25.25 0 00-.25.25v3.283a.25.25 0 01-.389.208l-.972-.649a.251.251 0 00-.278 0l-.972.649a.25.25 0 01-.389-.208V.875a.25.25 0 00-.25-.25h-2.25a1 1 0 00-1 1v7.444a1 1 0 001 1h8a1 1 0 001-1V1.625a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});