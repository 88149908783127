define("ember-svg-jar/inlined/astrology-virgo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astrology-virgo</title><path d=\"M22.5 18a1 1 0 00-1 1v.5a1.5 1.5 0 01-3 0v-2.015a.253.253 0 01.051-.152l4.21-5.525A3.617 3.617 0 0019.883 6h-1.139a.251.251 0 01-.25-.238 4.983 4.983 0 00-8.8-2.973.249.249 0 01-.382 0A4.984 4.984 0 00.5 6v12.5a1 1 0 002 0V6a3 3 0 016 0v12.5a1 1 0 002 0V6a3 3 0 016 0v10.64a.253.253 0 01-.051.152l-2.744 3.6a1 1 0 101.59 1.212l.922-1.209a.25.25 0 01.439.082A3.491 3.491 0 0023.5 19.5V19a1 1 0 00-1-1zm-4-9.75a.25.25 0 01.25-.25h1.133a1.616 1.616 0 011.286 2.6l-2.22 2.914a.25.25 0 01-.449-.151z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});