define("ember-svg-jar/inlined/browser-page-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>browser-page-layout</title><path d=\"M24 4.75a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h18a3 3 0 003-3zm-2.25 2A.25.25 0 0122 7v5.5a.25.25 0 01-.25.25h-12a.25.25 0 01-.25-.25V7a.25.25 0 01.25-.25zm-12.1-3a.966.966 0 011.692 0 .969.969 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.969.969 0 01.158-.5zm-3.5 0a.966.966 0 011.692 0 .969.969 0 01.158.5.969.969 0 01-.154.5.966.966 0 01-1.692 0A.969.969 0 016 4.25a.969.969 0 01.154-.5zm-3.562.092A1 1 0 013.5 3.25a.985.985 0 01.846.5.969.969 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.979.979 0 01.092-.408zM8 20a.25.25 0 01-.25.25H3a1 1 0 01-1-1V7a.25.25 0 01.25-.25h5.5A.25.25 0 018 7zm14-.75a1 1 0 01-1 1H9.75A.25.25 0 019.5 20v-5.5a.25.25 0 01.25-.25h12a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});