define("ember-svg-jar/inlined/style-two-pin-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-two-pin-question</title><path d=\"M12 0a9.511 9.511 0 00-9.5 9.5c0 6.544 6.537 12.487 8.541 14.153a1.5 1.5 0 001.917 0c2-1.666 8.542-7.609 8.542-14.154A9.511 9.511 0 0012 0zm0 17.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm1.557-6.614a1.008 1.008 0 00-.557.9v.468a1 1 0 01-2 0v-.468A3.022 3.022 0 0112.666 9.1 1.5 1.5 0 1010.5 7.75a1 1 0 01-2 0 3.5 3.5 0 115.057 3.136z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});