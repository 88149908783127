define("ember-svg-jar/inlined/office-file-pdf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-file-pdf</title><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.425A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6zM11.951 13.822c-.105-.046-.326-.048-.326.164v3.977a.237.237 0 00.327.21 2.374 2.374 0 000-4.351zM7.5 13.623h-.623a.252.252 0 00-.252.252v1.246a.252.252 0 00.252.252H7.5a.875.875 0 000-1.75z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.248A.25.25 0 0014.75 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.248A.25.25 0 0021.75 7zM7.5 16.623h-.623a.252.252 0 00-.252.252V19a.625.625 0 01-1.25 0v-6A.625.625 0 016 12.373h1.5a2.125 2.125 0 010 4.25zm3.5 3a.625.625 0 01-.625-.623v-6a.625.625 0 01.625-.627 3.625 3.625 0 010 7.25zm8-6h-2a.375.375 0 00-.375.375v1.123a.252.252 0 00.252.252H18a.625.625 0 010 1.25h-1.123a.252.252 0 00-.252.252V19a.625.625 0 11-1.25 0v-5A1.627 1.627 0 0117 12.373h2a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});