define("ember-svg-jar/inlined/office-lamp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-lamp</title><path d=\"M19.824 12.75a.249.249 0 00-.209-.047.247.247 0 00-.169.132l-1.87 3.739a.25.25 0 00.147.35 8.553 8.553 0 011.349.562.259.259 0 00.193.016.253.253 0 00.146-.126l1.823-3.645a.25.25 0 00-.159-.353 3.488 3.488 0 01-1.251-.628zM13.625 4.593a.251.251 0 00-.2-.012.247.247 0 00-.144.139 4.492 4.492 0 01-.747 1.214.251.251 0 00.075.386l5.648 2.892a.251.251 0 00.355-.155 3.486 3.486 0 01.64-1.245.25.25 0 00-.082-.379z\"/><circle cx=\"21.982\" cy=\"9.997\" r=\"2\"/><path d=\"M11.281.875a3 3 0 00-4.243 0l-.8.8a.248.248 0 01-.212.07l-3.918-.56A1.836 1.836 0 00.556 4.293L7.863 11.6a1.835 1.835 0 003.111-1.556l-.559-3.918a.252.252 0 01.07-.212l.8-.795a3.006 3.006 0 00-.004-4.244zM8.715 22.034A1.5 1.5 0 0010.142 24h9.681a1.5 1.5 0 001.427-1.963A6.44 6.44 0 0014.982 18a6.439 6.439 0 00-6.267 4.034z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});