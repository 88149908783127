define("ember-svg-jar/inlined/people-man-10.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-10</title><path d=\"M5.39 7.9a.251.251 0 01-.074.215 2.025 2.025 0 00-.565 1.527 2.728 2.728 0 001.435 2.432.251.251 0 01.142.188c.51 3.047 4.011 4.991 5.672 4.991s5.162-1.944 5.672-4.992a.251.251 0 01.142-.187 2.728 2.728 0 001.435-2.432 2.025 2.025 0 00-.565-1.527.25.25 0 01-.074-.215l.758-5.419A1.505 1.505 0 0018.2.8 32.64 32.64 0 0012 0a32.64 32.64 0 00-6.2.8 1.505 1.505 0 00-1.168 1.678zm11.8 2.809a1.752 1.752 0 00-1 1.306c-.374 2.245-3.161 3.735-4.19 3.735s-3.816-1.49-4.191-3.738a1.752 1.752 0 00-1-1.306 1.281 1.281 0 01-.557-1.1.634.634 0 01.1-.44.7.7 0 00.189-.139A.108.108 0 016.619 9a1.5 1.5 0 001.453-1.127l.517-.973a.249.249 0 01.327-.109A7.4 7.4 0 0012 7.5a7.391 7.391 0 003.073-.7.251.251 0 01.328.114l.486.957A1.5 1.5 0 0017.342 9a.207.207 0 01.139.055.676.676 0 00.165.115.634.634 0 01.1.44 1.281 1.281 0 01-.553 1.096z\"/><path d=\"M12.741 12.328a1.311 1.311 0 01-.236.1 2.135 2.135 0 01-1.157 0 1.321 1.321 0 01-.241-.1.75.75 0 00-.711 1.321 2.882 2.882 0 00.511.211 3.512 3.512 0 001.019.148 3.445 3.445 0 001.017-.147 2.7 2.7 0 00.513-.213.75.75 0 10-.715-1.318zM10.992 10.026a.751.751 0 00-.055-1.059 2.1 2.1 0 00-2.635 0 .75.75 0 101 1.115.632.632 0 01.627 0 .751.751 0 001.059-.056zM12.867 8.967a.75.75 0 001 1.115.632.632 0 01.627 0 .75.75 0 101-1.115 2.1 2.1 0 00-2.627 0zM21.08 18.287l-.017-.006-4.476-1.5a.5.5 0 00-.562.178 4.985 4.985 0 01-8.05 0 .5.5 0 00-.562-.178L2.92 18.287A4.519 4.519 0 000 22.5v1a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-1a4.519 4.519 0 00-2.92-4.213z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});