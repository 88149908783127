define("ember-svg-jar/inlined/single-man-actions-credit-card.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-credit-card</title><path d=\"M7.044 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.743a.5.5 0 01.741-.3A6.958 6.958 0 008.5 5a6.363 6.363 0 00.93-.07.5.5 0 01.57.564A3 3 0 014.044 5a2.8 2.8 0 01.1-.743zM8.956 15.25a3.234 3.234 0 011.863-2.924.5.5 0 00.029-.889 7.422 7.422 0 00-3.6-.937 7.543 7.543 0 00-7.183 5.357.5.5 0 00.479.643h7.912a.5.5 0 00.5-.5zM22.206 13.5h-10a1.752 1.752 0 00-1.75 1.75v7a1.752 1.752 0 001.75 1.75h10a1.752 1.752 0 001.75-1.75v-7a1.752 1.752 0 00-1.75-1.75zm0 9h-10a.251.251 0 01-.25-.25v-3a.25.25 0 01.25-.25h10a.25.25 0 01.25.25v3a.25.25 0 01-.25.25zm-10-7.5h10a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-10a.25.25 0 01-.25-.25v-1a.251.251 0 01.25-.25z\"/><path d=\"M20.206 20h-1.25a.75.75 0 000 1.5h1.25a.75.75 0 100-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});