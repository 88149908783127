define("ember-svg-jar/inlined/small-caps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>small-caps</title><path d=\"M10.25 19.748H9a.25.25 0 01-.25-.25V4.5A.25.25 0 019 4.248h3.792a.458.458 0 01.458.458v1.542a1 1 0 102 0V4.706a2.461 2.461 0 00-2.458-2.458H2.708A2.461 2.461 0 00.25 4.706v1.542a1 1 0 002 0V4.706a.458.458 0 01.458-.458H6.5a.25.25 0 01.25.25v15a.25.25 0 01-.25.25H5.25a1 1 0 000 2h5a1 1 0 000-2z\"/><path d=\"M23.75 10.748a2 2 0 00-2-2h-7a2 2 0 00-2 2v1a1 1 0 002 0V11a.25.25 0 01.25-.25h2a.25.25 0 01.25.25v8.5a.25.25 0 01-.25.25h-.75a1 1 0 000 2h4a1 1 0 000-2h-.75a.25.25 0 01-.25-.25V11a.25.25 0 01.25-.25h2a.25.25 0 01.25.25v.75a1 1 0 002 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});