define("ember-svg-jar/inlined/keyboard-arrow-next", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-arrow-next</title><path d=\"M13.661 5.563a1 1 0 00-1.707.707v3.978a.25.25 0 01-.25.25H1.5a1.5 1.5 0 000 3h10.2a.25.25 0 01.25.25v3.977a1 1 0 001.707.707l5.728-5.727a1 1 0 000-1.414zM22.5 4.77a1.5 1.5 0 00-1.5 1.5v11.455a1.5 1.5 0 003 0V6.27a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});