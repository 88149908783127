define("ember-svg-jar/inlined/android-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>android-1</title><path d=\"M6.462 22.876h11.076a1.222 1.222 0 001.212-1.228V11.626a.75.75 0 00-.75-.75H6a.75.75 0 00-.75.75v10.022a1.222 1.222 0 001.212 1.228zM5.22 8.3a1 1 0 00.944 1.329h11.669a1 1 0 00.943-1.329 6.863 6.863 0 00-3.384-3.867.248.248 0 01-.119-.313l.633-1.626a1 1 0 10-1.863-.726l-.7 1.788a.25.25 0 01-.269.156 7.406 7.406 0 00-1.071-.081 7.653 7.653 0 00-1.079.081.249.249 0 01-.269-.157l-.684-1.788a1 1 0 10-1.871.718l.63 1.627a.25.25 0 01-.12.313A6.806 6.806 0 005.22 8.3zm9.03-2.171a.75.75 0 11-.75.75.75.75 0 01.75-.753zm-4.5 0a.75.75 0 11-.75.75.75.75 0 01.75-.753zM22 19.126v-7a1 1 0 10-2 0v7a1 1 0 102 0zM3 11.126a1 1 0 00-1 1v7a1 1 0 002 0v-7a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});