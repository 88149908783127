define("ember-svg-jar/inlined/road-sign-lane-crossing-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-lane-crossing-right</title><path d=\"M11.47 23.53a.748.748 0 001.06 0l11-11a.749.749 0 000-1.06l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06zm-.647-8.293a.251.251 0 00-.073.177v1.836a.75.75 0 01-1.5 0v-2.043a1.258 1.258 0 01.366-.884l3.561-3.56a.251.251 0 00.073-.177V9.5a.25.25 0 00-.25-.25h-.5a.75.75 0 01-.53-1.28l1.5-1.5a.749.749 0 011.06 0l1.5 1.5a.75.75 0 01-.53 1.28H15a.25.25 0 00-.25.25v1.293a1.244 1.244 0 01-.366.884zM14 14.25a.75.75 0 01.75.75v2.5a.75.75 0 01-1.5 0V15a.75.75 0 01.75-.75zM9.25 7a.75.75 0 011.5 0v3.5a.75.75 0 01-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});