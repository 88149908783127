define("ember-svg-jar/inlined/navigation-arrows-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-arrows-up</title><path d=\"M2.291 10.91h1.4a.5.5 0 00.337-.13l6.964-6.333a1.494 1.494 0 012.018 0l6.965 6.333a.5.5 0 00.336.13h1.4a.986.986 0 00.921-.626.975.975 0 00-.254-1.114L12.685.357a.987.987 0 00-.679-.272 1.015 1.015 0 00-.681.265L1.633 9.163a.971.971 0 00-.271 1.1.994.994 0 00.929.647z\"/><path d=\"M12.685 6.857a.987.987 0 00-.679-.272 1.015 1.015 0 00-.681.265l-9.692 8.813a.971.971 0 00-.271 1.1.994.994 0 00.929.648h1.4a.5.5 0 00.337-.13l6.964-6.333a1.494 1.494 0 012.018 0l6.965 6.333a.5.5 0 00.336.13h1.4a.986.986 0 00.921-.626.975.975 0 00-.254-1.114z\"/><path d=\"M12.675 13.346a1 1 0 00-1.345 0L1.623 22.17a1 1 0 00.677 1.74h19.408a1 1 0 00.673-1.739z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});