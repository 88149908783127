define("ember-svg-jar/inlined/common-file-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-refresh</title><path d=\"M24 4.415A2 2 0 0023.414 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.546a.245.245 0 00.23.249c.349.02 1.015.069 1.488.137A.247.247 0 008 9.685V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.844a.243.243 0 00-.241.216 7.666 7.666 0 01-.335 1.456.244.244 0 00.23.327H22a2 2 0 002-2z\"/><path d=\"M13.229 12.021a.5.5 0 00-.854-.353l-1.3 1.3a.246.246 0 01-.343 0 6.31 6.31 0 00-4.075-1.716A6.464 6.464 0 000 17.543 6.374 6.374 0 0011.357 21.6 1 1 0 109.8 20.354a4.376 4.376 0 11-3.42-7.1 4.34 4.34 0 012.92 1.118.256.256 0 010 .367l-1.382 1.386a.5.5 0 00.354.854h4.457a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});