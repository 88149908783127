define("ember-svg-jar/inlined/credit-card-phone-call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-phone-call</title><path d=\"M22.29 10.315A1.7 1.7 0 0021.1 9.8a1.378 1.378 0 00-.986.4l-1.554 1.549a1.549 1.549 0 00.115 2.182l.414.414a.25.25 0 01.018.334 31.3 31.3 0 01-4.428 4.428.25.25 0 01-.335-.017l-.415-.414a1.7 1.7 0 00-1.2-.511 1.382 1.382 0 00-.986.4L10.2 20.11a1.548 1.548 0 00.115 2.181l.944.945a2.553 2.553 0 001.788.764 2.116 2.116 0 001.136-.32 30.649 30.649 0 009.5-9.5 2.381 2.381 0 00-.445-2.925z\"/><path d=\"M22.29 10.315A1.7 1.7 0 0021.1 9.8a1.378 1.378 0 00-.986.4l-1.554 1.549a1.549 1.549 0 00.115 2.182l.414.414a.25.25 0 01.018.334 31.3 31.3 0 01-4.428 4.428.25.25 0 01-.335-.017l-.415-.414a1.7 1.7 0 00-1.2-.511 1.382 1.382 0 00-.986.4L10.2 20.11a1.548 1.548 0 00.115 2.181l.944.945a2.553 2.553 0 001.788.764 2.116 2.116 0 001.136-.32 30.649 30.649 0 009.5-9.5 2.381 2.381 0 00-.445-2.925zM20 2a2 2 0 00-2-2H2a2 2 0 00-2 2v1a.25.25 0 00.25.25h19.5A.25.25 0 0020 3z\"/><path d=\"M17.119 14.7a.25.25 0 00.026-.3 3 3 0 01.353-3.711l1.551-1.55a2.839 2.839 0 01.823-.572.219.219 0 00.128-.2V5a.25.25 0 00-.25-.25H.25A.25.25 0 000 5v8a2 2 0 002 2h14.736a.252.252 0 00.185-.082zM6 12.25H3a.75.75 0 010-1.5h3a.75.75 0 010 1.5zm3.5-3.5H3a.75.75 0 010-1.5h6.5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});