define("ember-svg-jar/inlined/messages-bubble-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-text</title><path d=\"M2 11.947a1 1 0 10-2 .009 12.018 12.018 0 002.723 7.551 1 1 0 001.545-1.269A10.012 10.012 0 012 11.947zM9.183 1.213A1 1 0 007.9.614a12.056 12.056 0 00-6.193 5.108 1 1 0 001.715 1.03 10.047 10.047 0 015.163-4.258 1 1 0 00.598-1.281zM23.872 10.14a1 1 0 00-1.978.291A10.226 10.226 0 0122 11.9a10.023 10.023 0 01-2.236 6.3 1 1 0 001.552 1.261 12.019 12.019 0 002.556-9.32zM20.471 5.876a1 1 0 00.768-1.638A12.025 12.025 0 0014.345.124a1 1 0 00-.389 1.962A10.025 10.025 0 0119.7 5.514a1 1 0 00.771.362zM15.37 21.314a10.1 10.1 0 01-6.691.016 1 1 0 10-.664 1.887 12.057 12.057 0 008.029-.02 1 1 0 00-.674-1.883zM12.5 5.4C8.916 5.4 6 7.8 6 10.753a4.789 4.789 0 001.542 3.468.25.25 0 01.05.292L6.5 16.67a.5.5 0 00.656.679l3.2-1.476a.256.256 0 01.168-.015 7.8 7.8 0 001.976.251c3.584 0 6.5-2.4 6.5-5.356S16.084 5.4 12.5 5.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});