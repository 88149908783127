define("ember-svg-jar/inlined/shopping-basket-subtract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-basket-subtract</title><path d=\"M17.352 10.936a.249.249 0 00.208-.184l.308-1.17a.5.5 0 00-.483-.627H2.379a.5.5 0 00-.489.6l1.276 5.994A1.956 1.956 0 005.132 17h5.654a.25.25 0 00.245-.2 7.5 7.5 0 016.321-5.864zm-6.852.679V14a.5.5 0 01-1 0v-2.385a.5.5 0 011 0zm-3.872 3.03a.488.488 0 01-.085.007.5.5 0 01-.492-.416l-.408-2.386a.5.5 0 01.986-.168l.408 2.386a.5.5 0 01-.409.577zM19.763 6.167a1.441 1.441 0 00-1.439-1.439h-1.142a.25.25 0 01-.222-.135L14.848.538a1 1 0 00-1.774.924l1.511 2.9a.253.253 0 01-.008.246.251.251 0 01-.214.12H5.4a.25.25 0 01-.221-.366l1.51-2.9A1 1 0 004.916.538L2.8 4.593a.249.249 0 01-.221.135h-1.14a1.439 1.439 0 000 2.878h16.885a1.441 1.441 0 001.439-1.439z\"/><path d=\"M18.366 12.734A5.633 5.633 0 1024 18.368a5.64 5.64 0 00-5.634-5.634zm-2.888 5.634a.748.748 0 01.75-.75h4.278a.75.75 0 010 1.5h-4.278a.75.75 0 01-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});