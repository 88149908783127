define("ember-svg-jar/inlined/aircraft-plane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>aircraft-plane</title><path d=\"M23.878.8A.5.5 0 0023.5.625h-4.25a.75.75 0 000 1.5h.375a.25.25 0 01.2.4l-.526.7a3.517 3.517 0 01-2.8 1.4H5.5A3.463 3.463 0 002.993 5.7a.25.25 0 00.18.423H3.5a.75.75 0 010 1.5H1.75a1.75 1.75 0 000 3.5h6.062a.25.25 0 00.245-.2 2.243 2.243 0 012.193-1.8h5.681a.247.247 0 00.191-.09l.726-.87A1.5 1.5 0 0118 7.625h1A1.5 1.5 0 0120.423 9.6l-.107.319a.086.086 0 00.024.09.082.082 0 00.092.013 5.032 5.032 0 002.853-3.854L24 1.2a.5.5 0 00-.122-.4zM7.25 7.625h-.5a.75.75 0 010-1.5h.5a.75.75 0 010 1.5zm3 0h-.5a.75.75 0 010-1.5h.5a.75.75 0 010 1.5zm3 0h-.5a.75.75 0 010-1.5h.5a.75.75 0 010 1.5z\"/><path d=\"M14.872 10.535a.25.25 0 00-.191-.41H10.25a1.25 1.25 0 000 2.5h3.53a.249.249 0 00.247-.288 1.474 1.474 0 01.321-1.172zM19 8.625h-1a.5.5 0 00-.384.18l-2.5 3a.5.5 0 00.384.82h2a1.239 1.239 0 001.132-.816l.842-2.526A.5.5 0 0019 8.625zM11.488 17.624a.251.251 0 01-.212-.112 4.209 4.209 0 00-3.526-1.887A4.264 4.264 0 003.711 18.6a.247.247 0 01-.165.164 3.254 3.254 0 00-2.3 3.109 1.5 1.5 0 001.5 1.5h11.5a1.5 1.5 0 001.5-1.5 4.25 4.25 0 00-4.258-4.249zm2.073 3.641a.249.249 0 01-.207.11H3.777a.226.226 0 01-.159-.386 1.344 1.344 0 01.887-.364c.019 0 .1 0 .14-.009a1 1 0 00.877-.915 2.236 2.236 0 014.32-.634A1.011 1.011 0 0011 19.68a2.312 2.312 0 01.5-.055 2.252 2.252 0 012.085 1.406.247.247 0 01-.024.234z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});