define("ember-svg-jar/inlined/webcam-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>webcam-2-alternate</title><path d=\"M4 8.722a8 8 0 1013.17-6.094.249.249 0 00-.339.014A6.806 6.806 0 0112 4.662a6.806 6.806 0 01-4.831-2.02.249.249 0 00-.339-.014A7.982 7.982 0 004 8.722zm8-3a3.5 3.5 0 11-3.5 3.5A3.5 3.5 0 0112 5.723z\"/><path d=\"M12 3.162a5.3 5.3 0 003.431-1.269.25.25 0 00-.057-.418q-.226-.1-.461-.2a10.755 10.755 0 00-5.826 0q-.234.093-.461.2a.25.25 0 00-.057.418A5.3 5.3 0 0012 3.162zM16.535 17.3a.506.506 0 00-.535-.073 9.4 9.4 0 01-8 0 .5.5 0 00-.536.072 7.021 7.021 0 00-2.35 4.052 1.5 1.5 0 001.477 1.772h10.817a1.5 1.5 0 001.477-1.772 7.023 7.023 0 00-2.35-4.051z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});