define("ember-svg-jar/inlined/road-sign-left-curve-ahead", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-left-curve-ahead</title><path d=\"M11.47 23.53a.748.748 0 001.06 0l11-11a.749.749 0 000-1.06l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06zM8.25 8A.777.777 0 019 7.25h2a.75.75 0 01.53 1.28l-.177.178a.247.247 0 00-.073.185.25.25 0 00.085.18l1.446 1.265a2.75 2.75 0 01.939 2.07V16.5a.75.75 0 01-1.5 0v-4.092a1.252 1.252 0 00-.427-.941l-1.546-1.353a.251.251 0 00-.342.011l-.405.4a.746.746 0 01-.53.22.75.75 0 01-.75-.745z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});