define("ember-svg-jar/inlined/single-woman-vintage-tv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-vintage-tv</title><path d=\"M24 3.249a1.5 1.5 0 00-1.5-1.5h-21a1.5 1.5 0 00-1.5 1.5v14.5a1.5 1.5 0 001.5 1.5h.8a.25.25 0 01.2.406l-.775.969a1 1 0 101.562 1.25l1.943-2.437a.5.5 0 01.391-.188h12.8a.5.5 0 01.394.192l1.894 2.423a1 1 0 101.576-1.23l-.766-.981a.25.25 0 01.2-.4h.781a1.5 1.5 0 001.5-1.5zM12.78 15.935a.5.5 0 01-.388.814h-8A.5.5 0 014 15.934a5.69 5.69 0 014.4-2.185 5.676 5.676 0 014.38 2.186zm.119-4.041a.633.633 0 01-1.065.686 4.112 4.112 0 01-.516-1.206.252.252 0 00-.175-.178.249.249 0 00-.242.062 3.595 3.595 0 01-5.017 0 .25.25 0 00-.417.116 4.1 4.1 0 01-.517 1.206.633.633 0 11-1.064-.686 3.521 3.521 0 00.5-1.934v-1.7a4.012 4.012 0 118.024 0v1.7a3.521 3.521 0 00.49 1.934zM18 16.749a.5.5 0 01-1 0V4a.5.5 0 111 0zm4.25-2a.75.75 0 01-.75.75h-1a.75.75 0 010-1.5h1a.75.75 0 01.75.75zM21.5 12.5h-1a.75.75 0 010-1.5h1a.75.75 0 010 1.5zM21 8.749a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/><path d=\"M11.59 8.65a.5.5 0 00-.4-.585 4.345 4.345 0 01-2.404-1.381.425.425 0 00-.394-.193C8.081 6.5 8 6.68 8 6.684a4.348 4.348 0 01-2.41 1.381.5.5 0 00-.4.585.505.505 0 00.586.4 5.3 5.3 0 002.453-1.214.25.25 0 01.327 0 5.3 5.3 0 002.449 1.211.5.5 0 00.585-.397z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});