define("ember-svg-jar/inlined/server-mail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-mail</title><path d=\"M9.548 13.127a.654.654 0 00-.088-.433.5.5 0 00-.4-.194H2.5a2.5 2.5 0 000 5H8a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25H5A.75.75 0 005 22h4a.5.5 0 00.5-.5s.031-7.455.048-8.373zM3 15a1.25 1.25 0 111.25 1.25A1.249 1.249 0 013 15zm5 1.25A1.25 1.25 0 119.25 15 1.25 1.25 0 018 16.25zM2 20.5H1A.75.75 0 001 22h1a.75.75 0 000-1.5zM2.5 5.5h13a2.5 2.5 0 000-5h-13a2.5 2.5 0 000 5zM3 3a1.25 1.25 0 111.25 1.25A1.249 1.249 0 013 3zm3.75 0A1.25 1.25 0 118 4.25 1.249 1.249 0 016.75 3zM0 9a2.5 2.5 0 002.5 2.5h13a2.5 2.5 0 000-5h-13A2.5 2.5 0 000 9zm3 0a1.25 1.25 0 111.25 1.25A1.249 1.249 0 013 9zm3.75 0A1.25 1.25 0 118 10.25 1.249 1.249 0 016.75 9zM22.5 23.5A1.5 1.5 0 0024 22v-4.74a.252.252 0 00-.25-.25.246.246 0 00-.131.038l-4.93 3.081a2.254 2.254 0 01-2.381 0l-4.927-3.079a.245.245 0 00-.131-.037.249.249 0 00-.25.25V22a1.5 1.5 0 001.5 1.5z\"/><path d=\"M15.5 13.5h-3a1.367 1.367 0 00-1.53 1.46.223.223 0 00.108.13l6.022 3.765a.754.754 0 00.794 0l5.976-3.735a.255.255 0 00.13-.229 1.5 1.5 0 00-1.5-1.391z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});