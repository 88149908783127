define("ember-svg-jar/inlined/baby-care-trolley-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baby-care-trolley-1</title><circle cx=\"17.688\" cy=\"20.5\" r=\"2.25\"/><path d=\"M19.31 6.227a.5.5 0 00-.393.145L5.735 19.543a.344.344 0 00.243.586 6.368 6.368 0 004.568-1.944l1.483-1.541a.5.5 0 01.714-.007l1.557 1.555a.783.783 0 001.1 0 .72.72 0 000-1.019l-1.63-1.631a.5.5 0 01-.007-.7s5.915-6.151 5.937-6.189a1.926 1.926 0 00-.028-2.221.5.5 0 00-.362-.205z\"/><path d=\"M23 1.28h-2.831a1 1 0 00-.707.293l-1.315 1.315a.5.5 0 01-.648.05 8.805 8.805 0 00-9.973-.272.489.489 0 00-.118.7L11.5 8.939a.5.5 0 01-.05.649l-1.572 1.569a1 1 0 01-.707.293H4.18a1.932 1.932 0 00-1.93 1.93v3.4a.5.5 0 00.434.5 3.242 3.242 0 012.032 1.108.5.5 0 00.733.027L20.29 3.573A1 1 0 0121 3.28h2a1 1 0 000-2z\"/><circle cx=\"2.25\" cy=\"20.5\" r=\"2.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});