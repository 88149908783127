define("ember-svg-jar/inlined/video-player-adjust-finger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-player-adjust-finger</title><path d=\"M24 21.082a3.333 3.333 0 00-3.333-3.333H19.5a.5.5 0 01-.5-.5v-4a1.5 1.5 0 00-3 0v6.465a.25.25 0 01-.41.191l-.879-.735a1.25 1.25 0 00-1.922 1.6l2.479 2.983H24z\"/><path d=\"M21.565.249H2.435A2.5 2.5 0 000 2.794V17.2a2.494 2.494 0 002.435 2.55h6.7a1 1 0 000-2h-6.7A.506.506 0 012 17.2V2.794a.506.506 0 01.435-.545h19.13a.506.506 0 01.435.545v12.552a1 1 0 102 0V2.794A2.5 2.5 0 0021.565.249z\"/><path d=\"M5.5 16.5a.75.75 0 00.75-.75v-.25a.5.5 0 01.5-.5h6.75a.75.75 0 000-1.5H6.75a.5.5 0 01-.5-.5v-.251a.75.75 0 00-1.5 0V13a.5.5 0 01-.5.5H3.5a.75.75 0 000 1.5h.75a.5.5 0 01.5.5v.249a.75.75 0 00.75.751zM9.977 10.657l5.91-2.73a.645.645 0 000-1.106l-5.91-2.73A.645.645 0 009 4.644v5.461a.644.644 0 00.977.552z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});