define("ember-svg-jar/inlined/video-player-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-player-slider</title><path d=\"M18.5 3h-13A1.5 1.5 0 004 4.5v15A1.5 1.5 0 005.5 21h13a1.5 1.5 0 001.5-1.5v-15A1.5 1.5 0 0018.5 3zm-3.828 9.79l-4.441 2.588a.905.905 0 01-1.356-.79V9.412a.905.905 0 011.356-.79l4.442 2.588a.917.917 0 01-.001 1.58zM1 5a1 1 0 00-1 1v12.5a1 1 0 002 0V6a1 1 0 00-1-1zM23 5a1 1 0 00-1 1v12.5a1 1 0 002 0V6a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});