define("ember-svg-jar/inlined/music-genre-choose", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-genre-choose</title><path d=\"M5.875 10.953a2.2 2.2 0 00-4.293-.7.75.75 0 001.424.474.7.7 0 011.369.222.579.579 0 01-.167.4L1.6 13.97a.75.75 0 00.53 1.281h3a.75.75 0 000-1.5h-.591a.25.25 0 01-.176-.427l.907-.907a2.06 2.06 0 00.605-1.464zM3.885 17.5h-.759a1.752 1.752 0 00-1.75 1.75.75.75 0 001.5 0 .249.249 0 01.246-.25h.757a.5.5 0 010 1 .75.75 0 100 1.5.5.5 0 010 1h-.754a.251.251 0 01-.25-.25.75.75 0 00-1.5 0A1.754 1.754 0 003.127 24h.757a2 2 0 001.655-3.11.249.249 0 010-.279A2 2 0 003.885 17.5zM5.125 5a.25.25 0 01-.25-.25v-3.5a1.249 1.249 0 00-2-1l-1.2.9a.75.75 0 00.9 1.2l.4-.3a.25.25 0 01.4.2v2.5a.25.25 0 01-.25.25.75.75 0 000 1.5h2a.75.75 0 000-1.5zM13.125 13.5h8a1.5 1.5 0 000-3h-8a1.5 1.5 0 000 3zM12.832 7h8.586a1 1 0 00.707-1.707L17.832 1a1 1 0 00-1.414 0l-4.294 4.294A1 1 0 0012.832 7zM21.418 17h-8.586a1 1 0 00-.707 1.707L16.418 23a1 1 0 001.414 0l4.294-4.294A1 1 0 0021.418 17z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});