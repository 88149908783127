define("ember-svg-jar/inlined/cat-carrier-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cat-carrier-1</title><path d=\"M20.173 16.5a.251.251 0 00-.24.178A1.5 1.5 0 0118.5 17.75h-.75a.25.25 0 01-.25-.25V11a.25.25 0 01.25-.25h.75a1.5 1.5 0 011.5 1.5v2.5a.25.25 0 00.25.25h3.25a.5.5 0 00.5-.5V9.75a2.5 2.5 0 00-2.5-2.5h-4v-1.5a3 3 0 00-3-3h-5a3 3 0 00-3 3v1.5h-4A2.5 2.5 0 000 9.75v4.75a.5.5 0 00.5.5h3.25a.25.25 0 00.25-.25v-2.5a1.5 1.5 0 011.5-1.5h.75a.25.25 0 01.25.25v6.5a.25.25 0 01-.25.25H5.5a1.5 1.5 0 01-1.433-1.072.25.25 0 00-.24-.178H.5a.5.5 0 00-.5.5v2.75a1.5 1.5 0 001.5 1.5h21a1.5 1.5 0 001.5-1.5V17a.5.5 0 00-.5-.5zm-4.673 1a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25V11a.25.25 0 01.25-.25h2a.25.25 0 01.25.25zm-7-11.75a1 1 0 011-1h5a1 1 0 011 1v1a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5zm0 5.25a.25.25 0 01.25-.25h2A.25.25 0 0111 11v6.5a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});