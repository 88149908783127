define("ember-svg-jar/inlined/toilet-use-wrong", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toilet-use-wrong</title><path d=\"M13.821 10.74a1.512 1.512 0 00-1.426-.113l-1.753.779.824-3.571a1.5 1.5 0 10-2.924-.674l-1.5 6.5a1.5 1.5 0 002.071 1.708l2.387-1.062V16.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V12a1.5 1.5 0 00-.679-1.26zM13.5 17.988H5V15.25a.25.25 0 00-.25-.25H.254a.25.25 0 00-.25.25v8.25a.5.5 0 00.5.5H10a.5.5 0 00.5-.5v-.512a1.939 1.939 0 01.309-1.307.827.827 0 01.64-.192h.005a2.611 2.611 0 001.777-.678 2.356 2.356 0 00.769-1.75v-.573a.5.5 0 00-.5-.5zM4.754 14A.25.25 0 005 13.75v-1.262a1.5 1.5 0 00-1.5-1.5h-2c-.726 0-1.5.526-1.5 2v.762a.25.25 0 00.25.25zM22.466 3.306a.25.25 0 010-.354L23.7 1.715A1 1 0 1022.289.3l-1.237 1.238a.25.25 0 01-.354 0L19.461.3a1 1 0 10-1.414 1.414l1.237 1.237a.25.25 0 010 .354l-1.237 1.238a1 1 0 101.414 1.414L20.7 4.72a.25.25 0 01.354 0l1.237 1.237A1 1 0 1023.7 4.543z\"/><circle cx=\"13.254\" cy=\"2.5\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});