define("ember-svg-jar/inlined/science-magnet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-magnet</title><path d=\"M13.5 0a1 1 0 00-1 1v.705a.25.25 0 01-.474.112L11.4.553A1 1 0 009.5 1v6a1 1 0 002 0v-.7a.25.25 0 01.474-.111l.631 1.264A1 1 0 0014.5 7V1a1 1 0 00-1-1zM11.8 18h1.7a1 1 0 000-2h-1.7a2.3 2.3 0 00-1.278 4.219l1.84 1.226A.3.3 0 0112.2 22h-1.7a1 1 0 000 2h1.7a2.3 2.3 0 001.278-4.219l-1.84-1.226A.3.3 0 0111.8 18z\"/><circle cx=\"1.5\" cy=\"12\" r=\"1.5\"/><circle cx=\"2.907\" cy=\"17.249\" r=\"1.5\"/><circle cx=\"6.75\" cy=\"21.093\" r=\"1.5\"/><circle cx=\"2.907\" cy=\"6.751\" r=\"1.5\"/><circle cx=\"6.75\" cy=\"2.907\" r=\"1.5\"/><circle cx=\"22.5\" cy=\"12\" r=\"1.5\"/><circle cx=\"21.093\" cy=\"17.249\" r=\"1.5\"/><circle cx=\"17.25\" cy=\"21.093\" r=\"1.5\"/><circle cx=\"21.093\" cy=\"6.751\" r=\"1.5\"/><circle cx=\"17.25\" cy=\"2.907\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});