define("ember-svg-jar/inlined/steampunk-decoration-lamp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>steampunk-decoration-lamp</title><path d=\"M11.6 7.747a.248.248 0 01-.223-.068A2.231 2.231 0 009.8 7.024a2.24 2.24 0 00-1.568 3.849.251.251 0 01.071.229l-.568 2.781a.25.25 0 01-.2.2 2.24 2.24 0 00-1.745 1.704.248.248 0 01-.151.177l-1.61.651a.251.251 0 01-.257-.043A2.254 2.254 0 104.5 18.7a.254.254 0 01.152-.184l1.556-.629a.249.249 0 01.262.047 2.244 2.244 0 003.216-3.12.249.249 0 01-.055-.213l.608-2.977a.251.251 0 01.177-.191 2.255 2.255 0 001.558-1.581.249.249 0 01.2-.181l1.6-.3a.254.254 0 00.192-.172 5.7 5.7 0 01.4-.971.251.251 0 00.011-.209l-.219-.552a.248.248 0 00-.279-.153zM2.3 19.024a.75.75 0 11.75-.75.751.751 0 01-.75.75zm5.676-2a.75.75 0 11.75-.75.75.75 0 01-.747.75zm1.824-7a.75.75 0 11.75-.75.751.751 0 01-.75.75zM23.235 12.735a1.125 1.125 0 00.632-1.461L22.85 8.708a3.858 3.858 0 00-2.664-2.176.5.5 0 01-.335-.3l-.511-1.286A2.23 2.23 0 0017.577 3.5a2.578 2.578 0 00-2.36.935 2.231 2.231 0 00-.293 2.26l.511 1.288a.5.5 0 01-.039.447 3.85 3.85 0 00-.448 3.41l1.017 2.565a1.121 1.121 0 00.6.619 1.139 1.139 0 00.446.093 1.111 1.111 0 00.415-.081z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});