define("ember-svg-jar/inlined/phone-actions-next", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-next</title><path d=\"M17.169 16.152a2.5 2.5 0 00-3.536 0l-.386.385a46.976 46.976 0 01-5.789-5.79l.386-.385a2.505 2.505 0 000-3.535L5.722 4.706a2.56 2.56 0 00-3.536 0L1.022 5.869a3.508 3.508 0 00-.441 4.4 46.906 46.906 0 0013.141 13.145 3.541 3.541 0 004.4-.442l1.163-1.163a2.5 2.5 0 000-3.536z\"/><path d=\"M23.612 2.706L20.427.228a1 1 0 00-1.615.79V2.4a7.261 7.261 0 00-5.75 7.092v2a1.25 1.25 0 002.5 0v-2a4.74 4.74 0 013.25-4.484v.962a.994.994 0 00.561.9 1 1 0 001.054-.109l3.185-2.478a1 1 0 000-1.578z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});