define("ember-svg-jar/inlined/sea-transport-ship", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sea-transport-ship</title><path d=\"M1.9 20.335a.246.246 0 00.295.055 4 4 0 001.132-.913 2.5 2.5 0 011.913-.917 2.46 2.46 0 012.09 1.156 1.979 1.979 0 001.386.816 2.63 2.63 0 001.8-.985 2.537 2.537 0 011.967-.987 2.46 2.46 0 012.218 1.4 1.155 1.155 0 00.964.574 1.744 1.744 0 001.375-.789 2.368 2.368 0 012.1-1.183 2.46 2.46 0 012.087 1.158 3.06 3.06 0 00.594.587.244.244 0 00.327-.04 6.59 6.59 0 001.607-3.717 1.229 1.229 0 00-1.223-1.337h-1.2a.245.245 0 01-.235-.175l-1.178-3.928a1.464 1.464 0 00-1.41-1.049h-1.358a.245.245 0 01-.245-.245V6.869a.49.49 0 00-.491-.49h-1.962a.49.49 0 00-.491.49v2.944a.245.245 0 01-.245.245h-3.435a.245.245 0 01-.245-.245V6.869a.49.49 0 00-.491-.49H7.583a.49.49 0 00-.49.49v2.944a.246.246 0 01-.246.245H5.5a1.48 1.48 0 00-1.41 1.05l-1.183 3.927a.245.245 0 01-.235.175h-1.2A1.228 1.228 0 00.25 16.547a6.6 6.6 0 001.65 3.788zM5 14.894l.81-2.7a.245.245 0 01.235-.175h11.91a.245.245 0 01.235.175l.81 2.7a.245.245 0 01-.235.316H5.233A.245.245 0 015 14.894zM9.055 5.4a.981.981 0 00.981-.981.983.983 0 01.982-.981A2.457 2.457 0 0013.471.981a.982.982 0 00-1.963 0 .491.491 0 01-.49.491 2.946 2.946 0 00-2.944 2.944.981.981 0 00.981.984zM14.943 5.4a.981.981 0 00.981-.981.983.983 0 01.982-.981A2.457 2.457 0 0019.359.981a.982.982 0 00-1.963 0 .491.491 0 01-.49.491 2.946 2.946 0 00-2.944 2.944.981.981 0 00.981.984z\"/><path d=\"M23.767 22.822a.987.987 0 00-1.1-.843c-1.6.211-2.686-1.472-2.694-1.484a1.027 1.027 0 00-1.683.022A3.223 3.223 0 0115.66 22a2.673 2.673 0 01-2.293-1.413 1.026 1.026 0 00-1.681-.157A4.117 4.117 0 018.716 22a3.4 3.4 0 01-2.635-1.5 1.023 1.023 0 00-1.606-.1c-.014.018-1.493 1.835-3.139 1.615a.981.981 0 10-.26 1.945 3.978 3.978 0 00.539.04 5.486 5.486 0 003.415-1.4.246.246 0 01.322.007 4.99 4.99 0 003.364 1.356 5.577 5.577 0 003.489-1.3.247.247 0 01.325.015 4.384 4.384 0 003.13 1.281 4.93 4.93 0 003.316-1.28.246.246 0 01.327 0 4.468 4.468 0 003.621 1.237.982.982 0 00.843-1.094z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});