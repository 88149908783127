define("ember-svg-jar/inlined/laptop-announcement-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-announcement-1</title><path d=\"M1.6 23.963h19.8a1.6 1.6 0 001-2.849l-2.486-1.99a.249.249 0 01-.083-.27 2.057 2.057 0 00.1-.616v-6.6a2.1 2.1 0 00-2.1-2.1H5.172a2.1 2.1 0 00-2.1 2.1v6.6a2.045 2.045 0 00.1.615.25.25 0 01-.083.27L.6 21.114a1.6 1.6 0 001 2.849zm11.824-1.675H9.572a.75.75 0 010-1.5h3.85a.75.75 0 010 1.5zm-8.35-10.25a.5.5 0 01.5-.5h11.85a.5.5 0 01.5.5v5.8a.5.5 0 01-.5.5H5.572a.5.5 0 01-.5-.5zM0 6.538a1 1 0 001 1h8a1 1 0 001-1V2.246a.25.25 0 00-.359-.225L5.034 4.239a.5.5 0 01-.433 0L.361 2.2a.249.249 0 00-.241.014.247.247 0 00-.12.21z\"/><path d=\"M4.709 3.181a.25.25 0 00.217 0L9.731.869a.251.251 0 00.106-.356A.972.972 0 009 .038H1a.982.982 0 00-.924.668.251.251 0 00.124.308zM23 .038h-8a.982.982 0 00-.927.668.251.251 0 00.128.308l4.5 2.167a.25.25 0 00.217 0L23.731.869a.251.251 0 00.106-.356A.972.972 0 0023 .038z\"/><path d=\"M23.644 2.021l-4.61 2.218a.5.5 0 01-.433 0L14.361 2.2a.25.25 0 00-.361.224v4.114a1 1 0 001 1h8a1 1 0 001-1V2.246a.25.25 0 00-.359-.225z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});