define("ember-svg-jar/inlined/office-file-glue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-file-glue</title><path d=\"M23.707 5.705L18.293.291A1 1 0 0017.586 0H6a2 2 0 00-2 2v12.857a.25.25 0 00.416.187l1.5-1.328A.25.25 0 006 13.529V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H10.769a.24.24 0 00-.169.066c-.241.227-1.059 1-1.642 1.494a.25.25 0 00.162.44H22a2 2 0 002-2V6.412a1 1 0 00-.293-.707zM3.134 20.44a.25.25 0 00-.349-.005L.2 22.9A.638.638 0 00.64 24h3.594a.247.247 0 00.166-.063l.98-.877a.25.25 0 00.01-.363z\"/><path d=\"M14.758 14.179a1.276 1.276 0 00-.132-1.65l-1.165-1.164a1.277 1.277 0 00-1.658-.126l-5.737 4.216-2.381 2.38a1.275 1.275 0 000 1.8l2.707 2.708a1.277 1.277 0 001.8 0l2.381-2.38z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});