define("ember-svg-jar/inlined/office-employee", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-employee</title><path d=\"M22.867 21.953H19.01a.251.251 0 01-.242-.315l1.322-4.956a.879.879 0 00-.18-.795 1.014 1.014 0 00-.778-.356H4.868a1.014 1.014 0 00-.778.356.879.879 0 00-.18.795l1.322 4.956a.251.251 0 01-.242.315H1.133a.988.988 0 000 1.975h21.734a.988.988 0 000-1.975zM12 18a1.235 1.235 0 11-1.235 1.235A1.235 1.235 0 0112 18zM6.279 13.565a.5.5 0 00.5.485h10.437a.5.5 0 00.5-.485 5.724 5.724 0 00-3.2-5.141.25.25 0 01-.034-.424 4.386 4.386 0 10-5.052.037.25.25 0 01-.029.428 5.725 5.725 0 00-3.122 5.1zM12 9.668a.741.741 0 01.741.741v1.216a.741.741 0 11-1.482 0v-1.216A.741.741 0 0112 9.668zM9.652 3.691a.247.247 0 01.155-.154.25.25 0 01.216.026 5.606 5.606 0 003.038.893 5.613 5.613 0 001.131-.118.121.121 0 01.1.024.123.123 0 01.045.094 2.41 2.41 0 11-4.819 0 2.306 2.306 0 01.134-.765z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});