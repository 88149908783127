define("ember-svg-jar/inlined/water-fountain-fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-fountain-fill</title><path d=\"M22.75 4V.5a.5.5 0 00-.5-.5h-11a3.5 3.5 0 00-3.5 3.5V4a.5.5 0 00.5.5h14a.5.5 0 00.5-.5zM7.75 6a1.5 1.5 0 001.5 1.5h2a1.5 1.5 0 001.5-1.5v-.25a.25.25 0 00-.25-.25H8a.25.25 0 00-.25.25z\"/><circle cx=\"5.75\" cy=\"15.5\" r=\"1\"/><circle cx=\"8.75\" cy=\"15.5\" r=\"1\"/><circle cx=\"11.75\" cy=\"15.5\" r=\"1\"/><circle cx=\"14.75\" cy=\"15.5\" r=\"1\"/><circle cx=\"7.25\" cy=\"12.5\" r=\"1\"/><circle cx=\"10.25\" cy=\"12.5\" r=\"1\"/><circle cx=\"13.25\" cy=\"12.5\" r=\"1\"/><circle cx=\"8.75\" cy=\"9.5\" r=\"1\"/><circle cx=\"11.75\" cy=\"9.5\" r=\"1\"/><path d=\"M2.086 10.014a1 1 0 00-.822 1.15l1.721 10.329A2.991 2.991 0 005.944 24h8.612a2.991 2.991 0 002.959-2.507l1.721-10.329a1 1 0 00-1.972-.328l-1.722 10.329a1 1 0 01-.986.835H5.944a1 1 0 01-.986-.835L3.236 10.836a.993.993 0 00-1.15-.822z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});