define("ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/index", ["exports", "ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/renderer-factory", "ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/render-type"], function (_exports, _rendererFactory, _renderType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "RENDER_TYPE", {
    enumerable: true,
    get: function () {
      return _renderType.default;
    }
  });
  _exports.default = void 0;
  _exports.registerGlobal = registerGlobal;
  function registerGlobal(window) {
    window.MobiledocDOMRenderer = _rendererFactory.default;
  }
  var _default = _exports.default = _rendererFactory.default;
});