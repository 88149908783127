define("ember-svg-jar/inlined/light-bulb-eco-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-bulb-eco-alternate</title><path d=\"M18.5 15.429A1.467 1.467 0 0017 14H7a1.467 1.467 0 00-1.5 1.429 6.187 6.187 0 005.092 5.92.5.5 0 01.408.484V23a1 1 0 002 0v-1.168a.5.5 0 01.407-.483 6.187 6.187 0 005.093-5.92zM17.5 3.454A3.388 3.388 0 0014.515.011a3.2 3.2 0 00-2.339.767.249.249 0 01-.323 0A3.226 3.226 0 009.486.011 3.389 3.389 0 006.5 3.454v8.512a.534.534 0 00.534.534h.933a.534.534 0 00.533-.534V3.25a1.25 1.25 0 012.5 0v8.716a.534.534 0 00.534.534h.933a.534.534 0 00.533-.534V3.25a1.25 1.25 0 012.5 0v8.716a.534.534 0 00.534.534h.933a.534.534 0 00.533-.534z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});