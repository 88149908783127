define("ember-svg-jar/inlined/trends-hashtag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trends-hashtag</title><path d=\"M17.869 4.5a2.752 2.752 0 00-2.75-2.75h-6A2.752 2.752 0 006.371 4.5v15a2.752 2.752 0 002.75 2.75h6a2.752 2.752 0 002.75-2.75zm-1.5 13.25a.5.5 0 01-.5.5H8.37a.5.5 0 01-.5-.5V6.25a.5.5 0 01.5-.5h7.5a.5.5 0 01.5.5z\"/><path d=\"M8.994 12.5a.625.625 0 00.625.625h.233a.251.251 0 01.245.3l-.091.455a.625.625 0 00.491.735.641.641 0 00.123.012.625.625 0 00.612-.5l.16-.8a.25.25 0 01.245-.2h.715a.251.251 0 01.245.3l-.091.455a.625.625 0 00.491.735.641.641 0 00.123.012.625.625 0 00.612-.5l.16-.8a.25.25 0 01.245-.2h.482a.625.625 0 000-1.25h-.132a.25.25 0 01-.245-.3l.05-.25a.25.25 0 01.245-.2h.582a.625.625 0 000-1.25h-.232a.254.254 0 01-.194-.091.252.252 0 01-.051-.208l.09-.454a.625.625 0 00-1.226-.244l-.159.8a.25.25 0 01-.245.2h-.715a.254.254 0 01-.194-.091.252.252 0 01-.051-.208l.09-.454a.625.625 0 00-1.226-.244l-.159.8a.25.25 0 01-.245.2h-.483a.625.625 0 000 1.25h.133a.251.251 0 01.245.3l-.05.25a.25.25 0 01-.245.2h-.583a.625.625 0 00-.625.615zm2.8-1.174a.25.25 0 01.245-.2h.715a.251.251 0 01.245.3l-.05.25a.25.25 0 01-.245.2h-.715a.25.25 0 01-.245-.3zM19.09 12.021a.748.748 0 00.134 1.051.735.735 0 00.458.157.744.744 0 00.593-.291l.2-.252a3.177 3.177 0 00-.257-4.169.75.75 0 00-1.067 1.054 1.677 1.677 0 01.136 2.2z\"/><path d=\"M21.837 6.7l-.131-.1a.75.75 0 00-.9 1.2l.13.1a3.54 3.54 0 010 5.68l-.152.114a.75.75 0 00.451 1.35.741.741 0 00.449-.151l.152-.113a5.04 5.04 0 00.001-8.08zM4.846 8.511a.75.75 0 00-1.061.006 3.175 3.175 0 00-.255 4.17l.194.251a.747.747 0 00.594.291.739.739 0 00.457-.156.749.749 0 00.136-1.052l-.193-.25a1.675 1.675 0 01.134-2.2.749.749 0 00-.006-1.06z\"/><path d=\"M2.294 6.605l-.131.1a5.041 5.041 0 00-.007 8.084l.154.114a.75.75 0 00.9-1.2l-.152-.113a3.54 3.54 0 01.002-5.684l.13-.1a.75.75 0 10-.9-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});