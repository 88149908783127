define("ember-svg-jar/inlined/hardware-nuts-round", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hardware-nuts-round</title><path d=\"M18.1 8.284a.5.5 0 00-.63.445 9.4 9.4 0 01-.832 3.23.5.5 0 00.319.691 3.49 3.49 0 11-4.3 4.3.5.5 0 00-.69-.32 9.416 9.416 0 01-3.231.832.5.5 0 00-.445.631A8 8 0 1018.1 8.284z\"/><path d=\"M16 8a8 8 0 10-8 8 8.009 8.009 0 008-8zm-8 3.5A3.5 3.5 0 1111.5 8 3.5 3.5 0 018 11.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});