define("ember-svg-jar/inlined/expand-vertical-left-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>expand-vertical-left-right</title><path d=\"M1.5 16.112h8.523a.25.25 0 01.25.25v2.024a.25.25 0 01-.25.25H8.659a1 1 0 00-.707 1.707l2.864 2.863a1 1 0 001.414 0l2.863-2.863a1 1 0 00-.707-1.707h-1.363a.25.25 0 01-.25-.25v-2.024a.25.25 0 01.25-.25H22.5a1.25 1.25 0 000-2.5h-21a1.25 1.25 0 100 2.5zM.25 10.09a1.25 1.25 0 001.25 1.25h21a1.25 1.25 0 000-2.5h-9.477a.25.25 0 01-.25-.25V5.612a.25.25 0 01.25-.25h1.363a1 1 0 00.707-1.707L12.23.792a1 1 0 00-1.414 0L7.952 3.655a1 1 0 00.707 1.707h1.364a.25.25 0 01.25.25V8.59a.25.25 0 01-.25.25H1.5a1.25 1.25 0 00-1.25 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});