define("ember-svg-jar/inlined/messages-bubble-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-settings</title><path d=\"M11.056 17.169a.251.251 0 000-.339l-.174-.187a3.283 3.283 0 013.139-5.435l.227.053a.25.25 0 00.3-.17l.071-.235a3.275 3.275 0 016.268 0l.073.239a.251.251 0 00.291.172l.275-.058a.248.248 0 00.19-.177 7.336 7.336 0 00.287-2c0-4.962-4.934-9-11-9s-11 4.038-11 9a8.082 8.082 0 002.657 5.855L1.03 19.36a.5.5 0 00.662.633l5.831-2.43a13.246 13.246 0 002.74.441.25.25 0 00.23-.123 3.69 3.69 0 01.563-.712z\"/><circle cx=\"17.748\" cy=\"17\" r=\"1.5\"/><path d=\"M23.516 15.623a1.783 1.783 0 00-1.7-2.954L20.405 13a.452.452 0 01-.533-.311l-.424-1.393a1.775 1.775 0 00-3.4 0l-.424 1.393a.455.455 0 01-.533.311l-1.411-.328a1.784 1.784 0 00-1.7 2.954l.988 1.064a.464.464 0 010 .626l-.988 1.064a1.784 1.784 0 001.7 2.954L15.092 21a.453.453 0 01.533.311l.424 1.393a1.775 1.775 0 003.4 0l.424-1.393a.45.45 0 01.532-.311l1.411.328a1.783 1.783 0 001.7-2.954l-.987-1.064a.464.464 0 010-.626zM17.748 20a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});