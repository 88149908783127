define("ember-svg-jar/inlined/agriculture-machine-plow-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>agriculture-machine-plow-4</title><path d=\"M6.25 21H.75a.75.75 0 00-.75.75v1.5a.75.75 0 001.5 0V23a.5.5 0 01.5-.5h.25a.5.5 0 01.5.5v.25a.75.75 0 001.5 0V23a.5.5 0 01.5-.5H5a.5.5 0 01.5.5v.25a.75.75 0 001.5 0v-1.5a.75.75 0 00-.75-.75zM14.75 21h-5.5a.75.75 0 00-.75.75v1.5a.75.75 0 001.5 0V23a.5.5 0 01.5-.5h.25a.5.5 0 01.5.5v.25a.75.75 0 001.5 0V23a.5.5 0 01.5-.5h.25a.5.5 0 01.5.5v.25a.75.75 0 001.5 0v-1.5a.75.75 0 00-.75-.75zM23.25 21h-5.5a.75.75 0 00-.75.75v1.5a.75.75 0 001.5 0V23a.5.5 0 01.5-.5h.25a.5.5 0 01.5.5v.25a.75.75 0 001.5 0V23a.5.5 0 01.5-.5H22a.5.5 0 01.5.5v.25a.75.75 0 001.5 0v-1.5a.75.75 0 00-.75-.75zM5.4 2.333a.424.424 0 00.359.235c2.176.107 4.093.67 5.152 2.028a.253.253 0 01.028.264.25.25 0 01-.225.14H9a.5.5 0 00-.32.116l-3 2.5A.5.5 0 006 8.5a.25.25 0 01.25.25v2.161a.25.25 0 00.387.209 2.249 2.249 0 01.727-.312.5.5 0 00.386-.487V9a.5.5 0 01.5-.5h7.5a.5.5 0 01.5.5v1.319a.5.5 0 00.388.487 2.214 2.214 0 01.725.309.253.253 0 00.256.011.25.25 0 00.131-.22V8.75A.25.25 0 0118 8.5a.5.5 0 00.32-.884l-3-2.5A.5.5 0 0015 5h-.272a.5.5 0 01-.427-.24C12.207 1.33 10.013.073 4.93 0a.423.423 0 00-.386.611zM1.707 16.207l1.061-1.06A.5.5 0 013.121 15H6.75a.5.5 0 01.5.5 1 1 0 002 0 .5.5 0 01.5-.5h4.5a.5.5 0 01.5.5 1 1 0 002 0 .5.5 0 01.5-.5h3.629a.5.5 0 01.353.147l1.061 1.06a1 1 0 001.414-1.414L22.5 13.586A1.986 1.986 0 0021.086 13h-3.921a.5.5 0 01-.472-.333 1 1 0 00-1.886 0 .5.5 0 01-.472.333h-4.67a.5.5 0 01-.472-.333 1 1 0 00-1.886 0 .5.5 0 01-.472.333H2.914a1.986 1.986 0 00-1.414.586L.293 14.793a1 1 0 001.414 1.414zM21.882 17h-2.764a1.493 1.493 0 00-1.342.829l-.2.395a.5.5 0 01-.447.276h-1.761a.5.5 0 01-.447-.276l-.2-.395A1.493 1.493 0 0013.382 17h-2.764a1.493 1.493 0 00-1.342.829l-.2.395a.5.5 0 01-.447.276H6.868a.5.5 0 01-.447-.276l-.2-.395A1.493 1.493 0 004.882 17H2.118a1.493 1.493 0 00-1.342.829l-.723 1.447A.5.5 0 00.5 20h23a.5.5 0 00.447-.724l-.723-1.447A1.493 1.493 0 0021.882 17z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});