define("ember-svg-jar/inlined/smiley-disapointed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-disapointed</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM9.053 4.605A1 1 0 119.947 6.4l-4 2a1 1 0 01-.894-1.79zM5.6 9.938a1 1 0 011.316.516.91.91 0 001.668 0 1 1 0 111.832.8 2.909 2.909 0 01-5.332 0A1 1 0 015.6 9.938zm1.386 7.425a6.5 6.5 0 0110.028 0 1 1 0 01-1.543 1.274 4.5 4.5 0 00-6.942 0 1 1 0 01-1.543-1.274zm11.43-6.108a2.909 2.909 0 01-5.332 0 1 1 0 111.832-.8.91.91 0 001.668 0 1 1 0 111.832.8zM18.5 8.5a1 1 0 01-.446-.105l-4-2a1 1 0 11.894-1.79l4 2A1 1 0 0118.5 8.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});