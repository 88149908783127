define("ember-svg-jar/inlined/people-man-cap-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-cap-3</title><path d=\"M23.5 20.394a2.464 2.464 0 00-.967-1.977 14.264 14.264 0 00-5.908-2.481.5.5 0 00-.552.279A4.557 4.557 0 0112 19a4.557 4.557 0 01-4.073-2.785.5.5 0 00-.552-.279 14.264 14.264 0 00-5.908 2.481A2.464 2.464 0 00.5 20.394V23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5z\"/><path d=\"M5 9a7 7 0 0014 0V7.75a.25.25 0 01.25-.25H22a1 1 0 000-2h-2.968a.248.248 0 01-.242-.19A7 7 0 005.034 6.326v.011C5.022 6.415 5 6.969 5 7zm12 0A5 5 0 017 9V7.75a.25.25 0 01.25-.25h9.5a.25.25 0 01.25.25z\"/><circle cx=\"14\" cy=\"9.25\" r=\"1\"/><circle cx=\"10\" cy=\"9.25\" r=\"1\"/><path d=\"M10.457 11.844a.638.638 0 00.317.719 2.31 2.31 0 00.435.18 2.99 2.99 0 00.867.126h.006a2.976 2.976 0 00.863-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2h-2a.639.639 0 00-.624.481z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});