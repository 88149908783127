define("ember-svg-jar/inlined/monitor-heart-rate-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-heart-rate-up</title><path d=\"M24 9.934a1.5 1.5 0 00-1.5-1.5h-6.75a1.25 1.25 0 000 2.5h3.542a.25.25 0 01.183.42l-4.554 4.867a.249.249 0 01-.36.006l-2.72-2.72a2.253 2.253 0 00-3.182 0L.732 21.683A1.25 1.25 0 002.5 23.451l7.573-7.817a.249.249 0 01.357 0l2.729 2.729a2.261 2.261 0 003.211-.029l4.7-5.02a.25.25 0 01.433.171v3.2a1.25 1.25 0 002.5 0zM5.779 11.152a1 1 0 001.444 0L11.968 6.2a3.525 3.525 0 10-4.985-4.983L6.5 1.7l-.48-.483a3.525 3.525 0 00-4.994 4.977z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});