define("ember-svg-jar/inlined/paginate-filter-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paginate-filter-2</title><path d=\"M22 0H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2V2a2 2 0 00-2-2zm-5.5 14.75H11a.75.75 0 01-.5-1.313l3.594-3.17c.975-.86 1.3-1.2 1.117-2.118a1.75 1.75 0 00-3.461.351.75.75 0 01-1.5 0 3.25 3.25 0 016.435-.649c.368 1.811-.63 2.691-1.6 3.542l-2.106 1.857H16.5a.75.75 0 010 1.5z\"/><path d=\"M19.5 22h-17a.5.5 0 01-.5-.5v-17a1 1 0 00-2 0V22a2 2 0 002 2h17.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});