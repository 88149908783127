define("ember-svg-jar/inlined/remove-circle-alternate.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>remove-circle-alternate</title><path d=\"M17.666 6.333a1.249 1.249 0 00-1.768 0l-3.722 3.722a.25.25 0 01-.353 0L8.1 6.333A1.25 1.25 0 106.333 8.1l3.722 3.722a.25.25 0 010 .354L6.333 15.9a1.251 1.251 0 000 1.768 1.269 1.269 0 001.768 0l3.722-3.722a.249.249 0 01.353 0l3.724 3.72a1.272 1.272 0 001.768 0 1.251 1.251 0 000-1.768l-3.722-3.722a.25.25 0 010-.354l3.72-3.722a1.249 1.249 0 000-1.767z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});