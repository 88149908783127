define("ember-svg-jar/inlined/social-media-memori", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-memori</title><path d=\"M12 0a12 12 0 1012 12A12 12 0 0012 0zm4.8 17.74a.5.5 0 01-.72.52l-3.86-1.93a.5.5 0 00-.45 0l-3.84 1.93a.5.5 0 01-.72-.52l.65-4.26a.5.5 0 00-.14-.48l-3.03-3A.5.5 0 015 9.13l4.26-.7a.5.5 0 00.36-.26l2-3.83a.5.5 0 01.89 0l2 3.83a.5.5 0 00.36.26l4.25.7a.5.5 0 01.28.84L16.3 13a.5.5 0 00-.14.43z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});