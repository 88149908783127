define("ember-svg-jar/inlined/flip-vertical-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flip-vertical-up</title><circle cx=\"2\" cy=\"9.249\" r=\"1.25\"/><circle cx=\"2\" cy=\"5.249\" r=\"1.25\"/><circle cx=\"2\" cy=\"1.249\" r=\"1.25\"/><circle cx=\"7\" cy=\"1.249\" r=\"1.25\"/><circle cx=\"12\" cy=\"1.249\" r=\"1.25\"/><circle cx=\"17\" cy=\"1.249\" r=\"1.25\"/><circle cx=\"22\" cy=\"9.249\" r=\"1.25\"/><circle cx=\"22\" cy=\"5.249\" r=\"1.25\"/><circle cx=\"22\" cy=\"1.249\" r=\"1.25\"/><path d=\"M0 12.5a.75.75 0 00.75.75.25.25 0 01.25.25v8.75A1.752 1.752 0 002.75 24h18.5A1.752 1.752 0 0023 22.249V13.5a.25.25 0 01.25-.25.75.75 0 000-1.5H12.988a.249.249 0 00-.249.233 7.419 7.419 0 00-.018.517 8 8 0 001.967 5.255 2 2 0 01-3.016 2.627A12 12 0 018.721 12.5c0-.162.008-.324.018-.485a.254.254 0 00-.068-.187.25.25 0 00-.182-.078H.75a.75.75 0 00-.75.75z\"/><path d=\"M9.971 12.5a10.742 10.742 0 002.644 7.06.75.75 0 101.131-.984 9.212 9.212 0 01-1.837-8.868.25.25 0 01.15-.159.253.253 0 01.218.021l.828.5.726.443a.75.75 0 001.118-.813l-.524-2.121-.462-1.879a.771.771 0 00-.055-.116l-.024-.05a.706.706 0 00-.14-.185c-.009-.009-.013-.022-.023-.031S13.7 5.31 13.69 5.3a.723.723 0 00-.164-.095.756.756 0 00-.12-.045.858.858 0 00-.124-.016.691.691 0 00-.176 0h-.047l-2.2.532-1.782.43a.751.751 0 00-.215 1.37l.767.468.9.549a.248.248 0 01.1.3 10.76 10.76 0 00-.658 3.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});