define("ember-svg-jar/inlined/astrology-aquarius", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astrology-aquarius</title><path d=\"M5.078 10.281a2.018 2.018 0 002.834 0l3.863-4.166a.25.25 0 01.365 0l3.938 4.169a1.948 1.948 0 002.836 0l4.812-5.1a1 1 0 00-1.452-1.374l-4.557 4.9a.255.255 0 01-.182.08.25.25 0 01-.182-.079l-3.939-4.17a2.016 2.016 0 00-2.835 0l-3.866 4.17a.25.25 0 01-.365 0l-4.621-4.9A1 1 0 10.272 5.183zM22.28 13.515l-4.572 4.806a.25.25 0 01-.361 0l-3.947-4.09a2.026 2.026 0 00-2.819 0L6.7 18.32a.249.249 0 01-.361 0l-4.618-4.804A1 1 0 10.279 14.9l4.8 4.995a2.03 2.03 0 002.821 0l3.877-4.087a.247.247 0 01.181-.078.253.253 0 01.181.076l3.946 4.094a1.961 1.961 0 002.823 0l4.812-5a1 1 0 10-1.44-1.387z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});