define("ember-svg-jar/inlined/app-window-mail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-mail</title><path d=\"M12 14.3a3.153 3.153 0 01-1.57-.418l-4.055-2.331a.25.25 0 00-.375.216v4.784a1.2 1.2 0 001.2 1.2h9.6a1.2 1.2 0 001.2-1.2v-4.784a.25.25 0 00-.375-.216l-4.055 2.331A3.153 3.153 0 0112 14.3z\"/><path d=\"M7.2 9.251a1.126 1.126 0 00-.6.173.25.25 0 00.008.429l4.571 2.628a1.647 1.647 0 001.644 0l4.571-2.628a.25.25 0 00.008-.429 1.126 1.126 0 00-.6-.173z\"/><path d=\"M24 4.751a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h18a3 3 0 003-3zm-14.346-1a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.562.091a1 1 0 01.908-.591.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.987.987 0 01.092-.409zM22 19.251a1 1 0 01-1 1H3a1 1 0 01-1-1V7a.25.25 0 01.25-.25h19.5A.25.25 0 0122 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});