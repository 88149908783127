define("ember-svg-jar/inlined/csv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>csv</title><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zm-14 9.875a.625.625 0 010 1.25 3.625 3.625 0 010-7.25.625.625 0 010 1.25 2.375 2.375 0 000 4.75zm2.927-3.508l1.84 1.226a1.928 1.928 0 01-1.07 3.532H10.5a.625.625 0 010-1.25h1.7a.678.678 0 00.376-1.242l-1.84-1.226A1.928 1.928 0 0111.8 8.376h1.7a.625.625 0 010 1.25h-1.7a.678.678 0 00-.376 1.242zm7.7-.367a8.178 8.178 0 01-1.627 4.876.625.625 0 01-1 0 8.178 8.178 0 01-1.625-4.876V9a.625.625 0 111.25 0v1.5a6.91 6.91 0 00.7 3.014.227.227 0 00.357-.006 6.916 6.916 0 00.7-3.008V9a.625.625 0 111.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});