define("ember-svg-jar/inlined/social-music-lastfm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-music-lastfm</title><path d=\"M19.83 19.22c-4.48 0-5.61-3.47-6.6-6.54s-1.92-5.91-6-5.91a5.22 5.22 0 100 10.44 5.18 5.18 0 004.21-2.13 1 1 0 111.61 1.19A7.22 7.22 0 117.26 4.78c5.5 0 6.81 4 7.87 7.29s1.82 5.16 4.7 5.16A1.87 1.87 0 0022 15.1c0-1.45-.77-1.77-2.39-2.31s-3.86-1.27-3.86-4.39a3.32 3.32 0 011.46-2.81 5 5 0 012.8-.8c2.31 0 4 1.21 4 2.88a1 1 0 01-2 0c0-.4-.87-.87-2-.87a3 3 0 00-1.67.44 1.31 1.31 0 00-.6 1.17c0 1.6.81 1.94 2.49 2.49S24 12.13 24 15.1a3.83 3.83 0 01-4.17 4.12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});