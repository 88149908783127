define("ember-svg-jar/inlined/elephant-water", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>elephant-water</title><path d=\"M16.937 14.137a6.475 6.475 0 01-1.265 5.463c-.162.2-.333.4-.51.591a1.131 1.131 0 00.622.186 1.887 1.887 0 001.449-.8 2.5 2.5 0 014.292-.071c.113.17.747.9 1.3.865a2.569 2.569 0 011.175.208v-2.387a4.836 4.836 0 00-5-5h-2.347a6.414 6.414 0 01.284.945zM9.08 4.208a18.69 18.69 0 013.042-.248c1.438.093 3.008-.69 3.1-2.1a1.533 1.533 0 00-.651-1.4 3.274 3.274 0 00-3.126.042 5.993 5.993 0 00-2.9 3.021.5.5 0 00.542.682zM13.115 5.028a.5.5 0 00-.1.821 10.191 10.191 0 011.347 1.394 2.089 2.089 0 001.608.918 1.541 1.541 0 001.5-1.792 2.234 2.234 0 00-1.41-1.549 3.778 3.778 0 00-2.945.208zM10.447 19.358a2.5 2.5 0 013.756-.344 4.75 4.75 0 00-.7-7.353 2.4 2.4 0 00-1.087-.277 3.4 3.4 0 00-2.108 1.038.5.5 0 01-.611.017 3.769 3.769 0 00-2.197-.747 7.069 7.069 0 00-3.037 1.239.5.5 0 01-.711-.274A3.747 3.747 0 014 9.692a8.853 8.853 0 013.2-3.046.5.5 0 00-.038-.873L6.15 5.266a.5.5 0 00-.223-.053A7.189 7.189 0 000 12.692c0 4.821 2.8 5.709 3.435 6.143a2.493 2.493 0 013.633.666 2.112 2.112 0 001.488.875 2.8 2.8 0 001.891-1.018zm-3.195-3.916a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M22.928 21.863c-1.584.123-2.668-1.557-2.677-1.569a1.042 1.042 0 00-1.718.029 3.359 3.359 0 01-2.749 1.552 2.8 2.8 0 01-2.4-1.483 1.045 1.045 0 00-1.717-.156 4.3 4.3 0 01-3.1 1.639A3.56 3.56 0 015.8 20.294a1.039 1.039 0 00-1.637-.094c-.014.017-1.464 1.812-3.11 1.7A.985.985 0 000 22.852c-.01.188.042 1.058 1.168 1.058a5.843 5.843 0 003.727-1.61 5.24 5.24 0 003.664 1.572 5.858 5.858 0 003.81-1.5 4.571 4.571 0 003.415 1.5 5.153 5.153 0 003.622-1.5 4.772 4.772 0 003.66 1.482A1 1 0 0024 22.792a1.022 1.022 0 00-1.072-.929z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});