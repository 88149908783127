define("ember-svg-jar/inlined/tools-wench-hold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-wench-hold</title><path d=\"M5.252 17.063a1.124 1.124 0 01-1.59 0l-.94-.93a1.124 1.124 0 010-1.591 1.1 1.1 0 01.8-.33 1.073 1.073 0 01.79.33l.94.931a1.123 1.123 0 010 1.59zM4.142 13.3a1.125 1.125 0 111.59-1.591l1.64 1.641a1.112 1.112 0 01.33.79 1.135 1.135 0 01-.33.8 1.161 1.161 0 01-1.59 0zM9.492 12.822a1.121 1.121 0 01-1.59 0l-1.64-1.639a1.123 1.123 0 010-1.59 1.105 1.105 0 01.79-.33 1.128 1.128 0 01.8.33l1.64 1.639a1.123 1.123 0 010 1.59zM11.582 10.322v.119a.5.5 0 01-.3.483 1.165 1.165 0 01-1.259-.221l-.93-.94a1.074 1.074 0 01-.33-.79 1.1 1.1 0 01.33-.8 1.161 1.161 0 011.59 0l.891.9a.5.5 0 01.121.5 2.442 2.442 0 00-.113.749zM13.367 13.876a.5.5 0 00.147-.354.505.505 0 00-.146-.354l-1.056-1.056a2.348 2.348 0 01-.238-.28.5.5 0 00-.585-.174 1.837 1.837 0 01-.484.114.5.5 0 00-.306.146l-.008.008a.493.493 0 00-.141.3 1.859 1.859 0 01-.528 1.128 1.924 1.924 0 01-1.118.533.5.5 0 00-.3.145l-.029.029a.5.5 0 00-.141.3 1.883 1.883 0 01-.534 1.112A1.914 1.914 0 016.8 16a.5.5 0 00-.294.144l-.061.062a.5.5 0 00-.141.29A1.841 1.841 0 014.7 18.12a.5.5 0 00-.29.143l-3.918 3.96a.513.513 0 00-.11.54.5.5 0 00.46.309l3.06.021a.5.5 0 00.35-.14z\"/><path d=\"M16.911 21.651l1.29 1.441H9.358A.5.5 0 019 22.945l-1.29-1.29a.5.5 0 010-.707l6.72-6.72a1 1 0 000-1.414L13.021 11.4a1.5 1.5 0 012.12-2.12l2.12 2.12a4 4 0 010 5.662l-.348.348a3 3 0 00-.002 4.241zM23.487 4.256a.5.5 0 00-.837-.226l-1.482 1.482a.5.5 0 01-.3.144l-1.592.177a.5.5 0 01-.552-.553l.176-1.59a.507.507 0 01.143-.3l1.483-1.482a.5.5 0 00-.225-.837 4.424 4.424 0 00-1.144-.164h-.006a4.5 4.5 0 00-3.181 7.682 4.47 4.47 0 003.181 1.318 4.5 4.5 0 004.5-4.5 4.4 4.4 0 00-.164-1.151z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});