define("ember-svg-jar/inlined/astrology-pisces", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astrology-pisces</title><path d=\"M6 13a5.006 5.006 0 005-5 4.9 4.9 0 00-.762-2.617A.251.251 0 0110.45 5H22a1 1 0 000-2H6a5 5 0 000 10zm0-8a3 3 0 11-3 3 3 3 0 013-3zM18 11a5.006 5.006 0 00-5 5 4.9 4.9 0 00.762 2.617.251.251 0 01-.212.383H2a1 1 0 000 2h16a5 5 0 000-10zm0 8a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});