define("ember-svg-jar/inlined/arrow-left-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-left-1</title><path d=\"M4.5 12a2.3 2.3 0 01.78-1.729L16.432.46a1.847 1.847 0 012.439 2.773l-9.752 8.579a.25.25 0 000 .376l9.752 8.579a1.847 1.847 0 11-2.439 2.773L5.284 13.732A2.31 2.31 0 014.5 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});