define("ember-svg-jar/inlined/database-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-1-alternate</title><path d=\"M5.648 21.906a.978.978 0 000 1.955h12.7a.978.978 0 000-1.955h-5.121a.25.25 0 01-.25-.25v-2.689a.248.248 0 01.236-.249c4.593-.255 9.066-1.945 9.066-4.875V5.048C22.28 1.86 16.982.139 12 .139S1.725 1.857 1.713 5.038v8.81c0 2.932 4.479 4.622 9.075 4.875a.251.251 0 01.237.25v2.688a.25.25 0 01-.25.25zM3.711 8.037A16.571 16.571 0 0012 9.958a16.563 16.563 0 008.283-1.92v1.408c0 1.186-3.227 2.909-8.284 2.909s-8.288-1.723-8.288-2.909zM12 2.139c5.054 0 8.279 1.72 8.284 2.907v.008c-.01 1.187-3.234 2.9-8.283 2.9S3.712 6.235 3.712 5.048 6.939 2.139 12 2.139zm-8.285 11.7v-1.404A16.567 16.567 0 0012 14.355a16.567 16.567 0 008.284-1.92v1.408c0 1.187-3.227 2.91-8.284 2.91s-8.289-1.723-8.289-2.91zM20.8 22.883a.977.977 0 00.977.978h.977a.978.978 0 000-1.955h-.977a.976.976 0 00-.977.977zM.273 22.883a.977.977 0 00.977.978h.977a.978.978 0 000-1.955H1.25a.976.976 0 00-.977.977z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});