define("ember-svg-jar/inlined/image-document-jpg-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-document-jpg-1</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM8.125 17.5a2.625 2.625 0 01-5.25 0 .625.625 0 011.25 0 1.375 1.375 0 002.75 0v-6a.625.625 0 011.25 0zm3.275-1.375h-1.025a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0v-8a.625.625 0 01.625-.625h2a2.629 2.629 0 012.574 3.149 2.7 2.7 0 01-2.674 2.101zm6.1 2.75a1.377 1.377 0 001.375-1.375s.031-.375-.2-.375H18a.625.625 0 010-1.25h1.5a.625.625 0 01.625.625v1a2.625 2.625 0 01-5.25 0v-4a2.625 2.625 0 015.25 0 .625.625 0 01-1.25 0 1.375 1.375 0 00-2.75 0v4a1.377 1.377 0 001.375 1.375z\"/><path d=\"M11.5 12.125h-1.125a.25.25 0 00-.25.25v2.25a.25.25 0 00.25.25H11.5a1.375 1.375 0 000-2.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});