define("ember-svg-jar/inlined/expand-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>expand-5</title><rect x=\"7\" y=\"6.999\" width=\"10\" height=\"10\" rx=\"1.5\" ry=\"1.5\"/><path d=\"M0 23.5a.5.5 0 00.5.5h5a.5.5 0 00.354-.854l-1.44-1.439a.25.25 0 010-.354l1.97-1.969a1.25 1.25 0 00-1.768-1.768l-1.969 1.97a.252.252 0 01-.354 0L.854 18.145A.5.5 0 000 18.5zM24 .5a.5.5 0 00-.5-.5h-5a.5.5 0 00-.354.853l1.44 1.44a.249.249 0 010 .353l-1.97 1.97a1.25 1.25 0 001.768 1.768l1.969-1.97a.25.25 0 01.354 0l1.439 1.439A.5.5 0 0023.5 6a.512.512 0 00.191-.038A.5.5 0 0024 5.5zM.5 0a.5.5 0 00-.5.5v5a.5.5 0 00.309.462A.512.512 0 00.5 6a.5.5 0 00.354-.147l1.439-1.44a.25.25 0 01.354 0l1.969 1.97a1.25 1.25 0 101.768-1.768l-1.97-1.97a.249.249 0 010-.353l1.44-1.44A.5.5 0 005.5 0zM24 23.5v-5a.5.5 0 00-.854-.354l-1.439 1.44a.252.252 0 01-.354 0l-1.969-1.97a1.25 1.25 0 00-1.768 1.768l1.97 1.969a.25.25 0 010 .354l-1.44 1.439A.5.5 0 0018.5 24h5a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});