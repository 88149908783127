define("ember-svg-jar/inlined/photography-equipment-light-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>photography-equipment-light-3</title><path d=\"M9.168 11.936a.25.25 0 00-.293.247v3.446a.251.251 0 01-.073.177l-6.061 6.06a1.25 1.25 0 001.768 1.768L8.448 19.7a.25.25 0 01.427.177v2.873a1.25 1.25 0 002.5 0v-2.878a.25.25 0 01.425-.172l3.939 3.939a1.25 1.25 0 001.768-1.768l-6.061-6.06a.251.251 0 01-.073-.177v-3.149a.232.232 0 00-.089-.182zM19.9.211c-.188-.12-4.365-.606-6.046.49A28.948 28.948 0 0011.99 2.06a.517.517 0 01-.2.094l-7.3 1.683a1.816 1.816 0 00-1.365 1.712v2.408a1.727 1.727 0 001.4 1.663l7.263 1.255a.508.508 0 01.224.1 28.69 28.69 0 001.84 1.341 7.234 7.234 0 003.6.7A11.8 11.8 0 0019.9 12.8c.177-.115 1.727-1.272 1.727-6.3S20.075.326 19.9.211z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});