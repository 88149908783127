define("ember-svg-jar/inlined/concert-guitarist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>concert-guitarist</title><path d=\"M7.079 8.659a.25.25 0 01-.011-.431 4.075 4.075 0 10-4.414-.082.247.247 0 01.11.222.25.25 0 01-.137.208A4.778 4.778 0 000 12.839v2.851a.5.5 0 00.5.5h1.231a.5.5 0 01.5.45l.622 6.223a.5.5 0 00.5.45H6.2a.5.5 0 00.5-.45l.623-6.223a.5.5 0 01.5-.45h1.225a.5.5 0 00.354-.147.5.5 0 00.146-.353v-2.851a4.778 4.778 0 00-2.469-4.18zM5.524 12.03a.75.75 0 11-1.5 0v-1.692a.75.75 0 011.5 0zm-.6-5.192a2.073 2.073 0 01-1.986-2.671.248.248 0 01.149-.162.251.251 0 01.22.02 5.076 5.076 0 002.643.738 5.183 5.183 0 00.75-.055.25.25 0 01.286.273 2.078 2.078 0 01-2.061 1.857zM23.627 16.422a3.86 3.86 0 00-2.757-2.014.573.573 0 01-.447-.648 2.649 2.649 0 00-.232-1.611 3.321 3.321 0 00-3.7-1.5.5.5 0 01-.538-.268l-1.161-2.326a.5.5 0 01-.03-.371 1.559 1.559 0 00-.1-1.162L13.5 4.182a1.572 1.572 0 10-2.813 1.4l1.165 2.337a1.565 1.565 0 00.871.776.5.5 0 01.277.25l1.162 2.331a.5.5 0 01-.11.591 3.321 3.321 0 00-1.034 3.855 2.651 2.651 0 001.147 1.156.572.572 0 01.249.747 3.859 3.859 0 00-.052 3.414 4.166 4.166 0 003.858 2.14 6.017 6.017 0 002.67-.644c2.555-1.274 3.782-4.016 2.737-6.113zm-7.95-.826a1.572 1.572 0 112.107.7 1.574 1.574 0 01-2.107-.696zm4.85 3.807l-1.918.956a.738.738 0 01-.334.079.75.75 0 01-.335-1.421l1.918-.956a.75.75 0 11.669 1.342z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});