define("ember-svg-jar/inlined/single-man-actions-lock.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-lock</title><path d=\"M.619 18.062H2.41a.246.246 0 01.246.227l.444 5.257a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h1.791a.494.494 0 00.495-.5V14.1a5.443 5.443 0 10-10.886 0v3.464a.494.494 0 00.495.498z\"/><path d=\"M1.361 4.206A4.206 4.206 0 105.567 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM22.392 14.351a3.464 3.464 0 10-6.928 0v1.007a.248.248 0 01-.212.245 1.486 1.486 0 00-1.273 1.469v5.443A1.486 1.486 0 0015.464 24h6.928a1.486 1.486 0 001.484-1.485v-5.443a1.487 1.487 0 00-1.3-1.472.216.216 0 01-.189-.215zm-2.474 5.938a.99.99 0 11-.99-.99.99.99 0 01.99.99zm-2.227-4.7a.248.248 0 01-.248-.248v-.989a1.485 1.485 0 112.969 0v.989a.247.247 0 01-.247.248z\"/><path d=\"M.619 18.062H2.41a.246.246 0 01.246.227l.444 5.257a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h1.791a.494.494 0 00.495-.5V14.1a5.443 5.443 0 10-10.886 0v3.464a.494.494 0 00.495.498z\"/><path d=\"M1.361 4.206A4.206 4.206 0 105.567 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM22.392 14.351a3.464 3.464 0 10-6.928 0v1.007a.248.248 0 01-.212.245 1.486 1.486 0 00-1.273 1.469v5.443A1.486 1.486 0 0015.464 24h6.928a1.486 1.486 0 001.484-1.485v-5.443a1.487 1.487 0 00-1.3-1.472.216.216 0 01-.189-.215zm-2.474 5.938a.99.99 0 11-.99-.99.99.99 0 01.99.99zm-2.227-4.7a.248.248 0 01-.248-.248v-.989a1.485 1.485 0 112.969 0v.989a.247.247 0 01-.247.248z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});