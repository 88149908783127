define("ember-svg-jar/inlined/technology-chip-hold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>technology-chip-hold</title><path d=\"M20.3 4.412a.5.5 0 00.158.23 18.186 18.186 0 012.806 2.83.25.25 0 00.447-.151A9.9 9.9 0 0021.45.459a1.5 1.5 0 10-2.15 2.08 5.531 5.531 0 011 1.873zM13.83 2.439a1.529 1.529 0 00-1.91.94 1.553 1.553 0 00.94 1.9c2.25 1 5.87 3.15 6.06 6.81.172 3.419-2.047 5.911-3.97 5.911a17.236 17.236 0 01-4.61-1.581 1.5 1.5 0 00-1.29 2.71A54.438 54.438 0 0114.5 22a2.5 2.5 0 01.943 1.6.5.5 0 00.49.4H23.5a.5.5 0 00.5-.5V13c-.278-4.835-6.6-9.341-10.17-10.561zM6 15.5a1 1 0 00-1 1V18a1 1 0 002 0v-1.5a1 1 0 00-1-1z\"/><path d=\"M13.5 10H15a1 1 0 000-2h-1.5a1 1 0 000 2zM13.5 14H15a1 1 0 000-2h-1.5a1 1 0 000 2zM6 6.5a1 1 0 001-1V4a1 1 0 00-2 0v1.5a1 1 0 001 1zM10 6.5a1 1 0 001-1V4a1 1 0 00-2 0v1.5a1 1 0 001 1zM2.5 8H1a1 1 0 000 2h1.5a1 1 0 000-2zM2.5 12H1a1 1 0 000 2h1.5a1 1 0 000-2zM10.5 15a1.5 1.5 0 001.5-1.5v-5A1.5 1.5 0 0010.5 7h-5A1.5 1.5 0 004 8.5v5A1.5 1.5 0 005.5 15zm-2.75-3H9.5a.75.75 0 010 1.5H7.75a.75.75 0 010-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});