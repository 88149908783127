define("ember-svg-jar/inlined/hierarchy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hierarchy</title><path d=\"M3.25 24h11a1.5 1.5 0 001.485-1.286.249.249 0 01.247-.214h4.268a2 2 0 002-2V1a1 1 0 00-2 0v5a.5.5 0 01-.5.5h-3.768a.249.249 0 01-.247-.214A1.5 1.5 0 0014.25 5h-11a1.5 1.5 0 00-1.5 1.5v2a1.5 1.5 0 001.5 1.5h11a1.5 1.5 0 001.485-1.286.249.249 0 01.247-.214h3.768a.5.5 0 01.5.5v4a.5.5 0 01-.5.5h-3.768a.249.249 0 01-.247-.214A1.5 1.5 0 0014.25 12h-11a1.5 1.5 0 00-1.5 1.5v2a1.5 1.5 0 001.5 1.5h11a1.5 1.5 0 001.485-1.286.249.249 0 01.247-.214h3.768a.5.5 0 01.5.5v4a.5.5 0 01-.5.5h-3.768a.249.249 0 01-.247-.214A1.5 1.5 0 0014.25 19h-11a1.5 1.5 0 00-1.5 1.5v2a1.5 1.5 0 001.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});