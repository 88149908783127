define("ember-svg-jar/inlined/layers-hide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layers-hide</title><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.5 7.25h-5a.75.75 0 010-1.5h5a.75.75 0 010 1.5zM7.5 7.5A4.5 4.5 0 1012 3a4.505 4.505 0 00-4.5 4.5zM12 6a1.5 1.5 0 00-1.5 1.5.5.5 0 01-1 0A2.5 2.5 0 0112 5a.5.5 0 010 1z\"/><path d=\"M9.269 12.618A15.293 15.293 0 012.29 7.833a.5.5 0 010-.661C5.341 3.74 8.6 2 12 2s6.659 1.738 9.708 5.168a.5.5 0 010 .665c-.342.382-.7.752-1.065 1.1a1 1 0 001.384 1.444c.519-.5 1.018-1.028 1.491-1.579a1.989 1.989 0 000-2.6C19.119 1.075 14.828 0 12 0S4.881 1.076.486 6.2a1.99 1.99 0 000 2.6c.958 1.116 3.87 4.512 8.244 5.738a1 1 0 10.54-1.925z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});