define("ember-svg-jar/inlined/gesture-swipe-horizontal-right-two-fingers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-horizontal-right-two-fingers</title><path d=\"M23.389 3.9a.75.75 0 00-1.411-.215l-.487.973a.249.249 0 01-.322.117 23.933 23.933 0 00-9.282-1.755A20.178 20.178 0 00.565 6.065a1 1 0 101.119 1.659 18.2 18.2 0 0110.2-2.7A22 22 0 0120.24 6.56a.25.25 0 01.127.343l-.349.7a.751.751 0 00.951 1.031l2.433-.979a.749.749 0 00.461-.816z\"/><path d=\"M8.375 7.938a3 3 0 00-3 3v9.613a.428.428 0 00.428.428h5.142a.429.429 0 00.429-.428v-9.613a3 3 0 00-2.999-3zm1.5 4a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1a1.5 1.5 0 013 0zM15.375 7.979a3 3 0 00-3 3v9.573a.428.428 0 00.428.428h5.142a.429.429 0 00.429-.428v-9.574a3 3 0 00-2.999-2.999zm1.5 4a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});