define("ember-svg-jar/inlined/playlist-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>playlist-heart</title><path d=\"M21.038 12.546a3.488 3.488 0 00-3.057.987l-.305.3a.249.249 0 01-.353 0l-.3-.3a3.525 3.525 0 00-4.994 4.977l5.111 5.333a.5.5 0 00.723 0l5.1-5.326a3.526 3.526 0 00-1.931-5.971z\"/><path d=\"M16.5 2.5A2.5 2.5 0 0014 0H2.5A2.5 2.5 0 000 2.5v12A2.5 2.5 0 002.5 17h6.8a.251.251 0 00.248-.284 5.033 5.033 0 014.177-5.651 4.958 4.958 0 012.143.129.5.5 0 00.636-.482zm-5 5.5a1 1 0 01-1-1 1.77 1.77 0 00-1.151-1.729A.25.25 0 009 5.5v5.552A2.5 2.5 0 116.5 8.5c.077 0 .152 0 .227.01a.251.251 0 00.192-.064A.255.255 0 007 8.261V4a1 1 0 011-1c1.557 0 4.5.836 4.5 4a1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});