define("ember-svg-jar/inlined/vide-document-mov-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vide-document-mov-1</title><path d=\"M12.5 12.125a1.377 1.377 0 00-1.375 1.375v4a1.375 1.375 0 002.75 0v-4a1.377 1.377 0 00-1.375-1.375z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM9.125 19.5a.625.625 0 01-1.25 0v-4.939c0-.125-.168-.077-.209.005l-.607 1.213a.625.625 0 01-1.118 0s-.568-1.109-.608-1.215-.208-.136-.208-.011V19.5a.625.625 0 01-1.25 0v-8a.625.625 0 011.184-.279l1.217 2.434a.25.25 0 00.448 0l1.217-2.434a.625.625 0 011.184.279zm6-2a2.625 2.625 0 01-5.25 0v-4a2.625 2.625 0 015.25 0zm6-3.7a9.788 9.788 0 01-2.137 6.092.626.626 0 01-.976 0 9.788 9.788 0 01-2.137-6.092v-2.3a.625.625 0 011.25 0v2.3a8.535 8.535 0 001.154 4.275.26.26 0 00.442 0 8.535 8.535 0 001.154-4.275v-2.3a.625.625 0 011.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});