define("ember-svg-jar/inlined/credit-card-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-1-alternate</title><path d=\"M21 3.5H3a3 3 0 00-3 3v11a3 3 0 003 3h18a3 3 0 003-3v-11a3 3 0 00-3-3zm-18 2h18a1 1 0 011 1v1a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-1a1 1 0 011-1zm18 13H3a1 1 0 01-1-1v-6a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v6a1 1 0 01-1 1z\"/><path d=\"M8 14.5H5a1 1 0 000 2h3a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});