define("ember-svg-jar/inlined/clothes-design-pin-cushion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>clothes-design-pin-cushion</title><path d=\"M20.4 13.664a.257.257 0 00-.42.077l-1.78 4.177a.747.747 0 01-.983.4.667.667 0 01-.3-.923l2.61-6.14a.243.243 0 01.206-.145 2.5 2.5 0 002.175-3.159 2.468 2.468 0 00-1.737-1.735 2.5 2.5 0 00-2.546 4.068.234.234 0 01.046.224c-.038.089-.255.606-.285.672a.179.179 0 01-.2.077 12.911 12.911 0 00-2.378-.908.25.25 0 00-.315.242v2.037a.75.75 0 01-1.5 0v-7.69a.243.243 0 01.136-.215 2.5 2.5 0 001.258-2.96 2.464 2.464 0 00-1.7-1.67 2.5 2.5 0 00-1.828 4.63.242.242 0 01.141.216v4.873a.246.246 0 01-.237.247 7.943 7.943 0 00-3.158.805.245.245 0 00-.106.287l1.47 4.961a.749.749 0 01-.506.932.805.805 0 01-.932-.506L5.21 8.685a.24.24 0 01.07-.246 2.5 2.5 0 00.68-2.523A2.509 2.509 0 002.2 4.525a2.467 2.467 0 00-1.143 2.086A2.5 2.5 0 003.1 9.075a.245.245 0 01.191.168L4.3 12.67s.108.208-.146.419A11.536 11.536 0 00.5 21.5v1A1.5 1.5 0 002 24h20a1.5 1.5 0 001.5-1.5v-1a11.532 11.532 0 00-3.1-7.836z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});