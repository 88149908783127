define("ember-svg-jar/inlined/arrow-thick-circle-bottom-right-corner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-circle-bottom-right-corner</title><path d=\"M20.485 3.515a12 12 0 100 16.97 12.015 12.015 0 000-16.97zM7.554 16.446l2.678-2.678a.25.25 0 000-.354L5.815 8.992a.5.5 0 010-.707l2.47-2.47a.5.5 0 01.707 0l4.419 4.42a.25.25 0 00.354 0l2.679-2.679a.5.5 0 01.854.353V16.8a.5.5 0 01-.5.5h-8.89a.5.5 0 01-.354-.854z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});