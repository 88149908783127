define("ember-svg-jar/inlined/presentation-board-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>presentation-board-graph</title><path d=\"M24 1.5A1.5 1.5 0 0022.5 0h-21A1.5 1.5 0 000 1.5v6a.25.25 0 00.25.25h5.591a.251.251 0 00.23-.152l.756-1.764a1.246 1.246 0 011.149-.757H8a1.243 1.243 0 011.144.8l1.281 3.331a.25.25 0 00.453.03l1.556-2.851a1.25 1.25 0 012.156-.065l1.06 1.7a.246.246 0 00.228.116.249.249 0 00.211-.146l1.728-3.8a.75.75 0 011.366.621l-2.114 4.648a1.249 1.249 0 01-2.2.146l-1.1-1.759a.25.25 0 00-.432.012l-1.664 3.05a1.251 1.251 0 01-1.1.651h-.081a1.256 1.256 0 01-1.084-.8l-1.23-3.2a.25.25 0 00-.463-.009l-.4.936a1.249 1.249 0 01-1.148.758H.25A.249.249 0 000 9.5v5A1.5 1.5 0 001.5 16H10a.249.249 0 01.25.25v.916a.251.251 0 01-.1.2 2.1 2.1 0 00-.239.205l-4.295 4.295a1.25 1.25 0 001.768 1.767l3.939-3.939a.25.25 0 01.354 0l3.939 3.939a1.25 1.25 0 001.768-1.767l-4.293-4.293a2.1 2.1 0 00-.239-.205.251.251 0 01-.1-.2v-.918A.249.249 0 0113 16h9.5a1.5 1.5 0 001.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});