define("ember-svg-jar/inlined/single-neutral-actions-flash.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-flash</title><path d=\"M14.8 14.1a.5.5 0 00.009-.619 7.481 7.481 0 00-13.146 2.38.5.5 0 00.479.643H12.6a.5.5 0 00.388-.184z\"/><circle cx=\"8.643\" cy=\"4.75\" r=\"4.75\"/><path d=\"M21.536 16H20.5a.5.5 0 01-.5-.5v-4a.5.5 0 00-.887-.316l-5.275 6.462A.822.822 0 0014.464 19H15.5a.5.5 0 01.5.5v4a.5.5 0 00.332.471.516.516 0 00.168.029.5.5 0 00.387-.184l5.275-6.462A.822.822 0 0021.536 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});