define("ember-svg-jar/inlined/common-file-give-hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-give-hand</title><path d=\"M23.977 4.605a1.8 1.8 0 00-.276-.768 1.709 1.709 0 00-.219-.268L20.618.537A1.823 1.823 0 0019.321 0H12.5a2 2 0 00-2 2v12a2.193 2.193 0 00.035.38 2.386 2.386 0 001.523 1.893 1.638 1.638 0 00.442.06h9.667A1.832 1.832 0 0024 14.5V4.845a1.894 1.894 0 00-.023-.24zM22 13.833a.5.5 0 01-.5.5h-8.866a.774.774 0 01-.106-.222A.486.486 0 0112.5 14V2.5A.5.5 0 0113 2h6.034a.5.5 0 01.364.157l2.466 2.61A.5.5 0 0122 5.11zM1 14.75a1 1 0 00-1 1V23a1 1 0 002 0v-7.25a1 1 0 00-1-1zM15.5 19.5H6.375a.625.625 0 010-1.25H8.9a1 1 0 00.92-1.394A2.233 2.233 0 007.764 15.5H3.5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.764a2.238 2.238 0 002.236-2.236V20.5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});