define("ember-svg-jar/inlined/keyboard-shift", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-shift</title><path d=\"M19.5.5h-15a4.012 4.012 0 00-4 4v15a4.011 4.011 0 004 4h15a4.011 4.011 0 004-4v-15a4.012 4.012 0 00-4-4zm-1.147 11.7a.5.5 0 01-.455.293h-3.149a.25.25 0 00-.25.25V19a.5.5 0 01-.5.5H10a.5.5 0 01-.5-.5v-6.25a.25.25 0 00-.25-.25H6.1a.5.5 0 01-.376-.829l5.9-6.74a.5.5 0 01.752 0l5.9 6.74a.5.5 0 01.077.529z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});