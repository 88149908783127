define("ember-svg-jar/inlined/gardening-sprinkle-bottle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gardening-sprinkle-bottle</title><path d=\"M23.775 11.51l-1.466-5.864a2.5 2.5 0 00-2.425-1.894h-1.62a.5.5 0 00-.485.621l.5 2a.5.5 0 00.485.379h.4a.5.5 0 01.486.378L20.865 12a1 1 0 00.971.757h.964a1 1 0 00.971-1.242zM4.2 5.252a.75.75 0 00-.75-.75H.945a.75.75 0 000 1.5h2.5a.75.75 0 00.755-.75zM3.861 2.128l-1.5-1a.75.75 0 00-.832 1.248l1.5 1a.75.75 0 10.832-1.248zM3.029 7.128l-1.5 1a.75.75 0 00.832 1.248l1.5-1a.75.75 0 00-.832-1.248zM17.673 8.023a4.86 4.86 0 01-.086-.294l-.914-3.659a.751.751 0 00-.728-.568h-1a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v2a.5.5 0 01-.5.5h-1a.749.749 0 00-.727.568.241.241 0 01-.233.182H8.562a.5.5 0 01-.372-.166 1 1 0 00-.745-.334h-2a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h2a1 1 0 00.745-.333.5.5 0 01.372-.167h.79a.248.248 0 01.2.1.251.251 0 01.045.215L9.3 7.729c-.025.1-.053.2-.086.294L7.9 11.974a3.993 3.993 0 00-.2 1.264V21.5a2.5 2.5 0 002.5 2.5h6.5a2.5 2.5 0 002.5-2.5v-8.262a4 4 0 00-.205-1.265zM17.2 14.25a.5.5 0 01-.5.5h-6.5a.5.5 0 01-.5-.5v-1.174a1 1 0 01.051-.316l1.139-3.416A.5.5 0 0111.359 9h4.172a.5.5 0 01.474.342l1.139 3.416a1 1 0 01.051.316z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});