define("ember-svg-jar/inlined/legal-judge-balance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>legal-judge-balance</title><path d=\"M21.5 11h-19A1.5 1.5 0 001 12.5v11a.5.5 0 00.5.5h21a.5.5 0 00.5-.5v-11a1.5 1.5 0 00-1.5-1.5zM4.06 17.6a.5.5 0 01.491-.6h4.9a.5.5 0 01.49.6 3 3 0 01-5.88 0zm10.44 5.15h-5a.75.75 0 010-1.5H11a.25.25 0 00.25-.25v-6.5a.25.25 0 00-.25-.25H8a.25.25 0 00-.25.25v.5a.75.75 0 01-1.5 0v-1.5a.75.75 0 01.75-.75h10a.75.75 0 01.75.75V15a.75.75 0 01-1.5 0v-.5a.25.25 0 00-.25-.25h-3a.25.25 0 00-.25.25V21a.25.25 0 00.25.25h1.5a.75.75 0 010 1.5zM17 20a3 3 0 01-2.94-2.4.5.5 0 01.491-.6h4.9a.5.5 0 01.49.6A3 3 0 0117 20zM12.278 6a3 3 0 10-3-3 3 3 0 003 3zM8.783 9.995h6.937a.5.5 0 00.434-.75A4.474 4.474 0 0012.275 7a4.564 4.564 0 00-3.924 2.242.5.5 0 00.432.753z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});