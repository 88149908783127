define("ember-svg-jar/inlined/flag-triangle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flag-triangle-1</title><path d=\"M3 1.5v21a1.5 1.5 0 003 0v-21a1.5 1.5 0 00-3 0zM7.981 14.855a1 1 0 00.981.032l11.5-6a1 1 0 000-1.774l-11.5-6A1 1 0 007.5 2v12a1 1 0 00.481.855z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});