define("ember-svg-jar/inlined/jellyfish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>jellyfish</title><path d=\"M19.556 4.7A7.941 7.941 0 0012 0a7.941 7.941 0 00-7.556 4.7A2.994 2.994 0 005.5 10.5h13a2.994 2.994 0 001.056-5.8zm-8.834-1.05A4.322 4.322 0 008.385 4.9a.75.75 0 01-1.106-1.009 5.813 5.813 0 013.126-1.706.75.75 0 01.317 1.467zM12.687 13.685a1.25 1.25 0 10-2.091-1.37c-1.74 2.656-.78 4.561-.009 6.091.637 1.263 1.139 2.26.482 3.872a1.25 1.25 0 002.315.944 6.507 6.507 0 00-.565-5.941c-.697-1.381-1.081-2.147-.132-3.596zM18.314 13.685a1.25 1.25 0 10-2.091-1.37c-1.74 2.656-.78 4.561-.008 6.091.636 1.263 1.138 2.26.481 3.873a1.251 1.251 0 002.317.942 6.511 6.511 0 00-.566-5.94c-.698-1.381-1.082-2.147-.133-3.596zM7.059 13.685a1.25 1.25 0 00-2.092-1.37c-1.739 2.656-.78 4.561-.009 6.091.637 1.263 1.139 2.26.482 3.872a1.25 1.25 0 002.315.944 6.507 6.507 0 00-.565-5.941c-.697-1.381-1.081-2.147-.131-3.596z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});