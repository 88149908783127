define("ember-svg-jar/inlined/ticket-movie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ticket-movie</title><path d=\"M23 9a1 1 0 001-1V5.5A2.5 2.5 0 0021.5 3h-19A2.5 2.5 0 000 5.5V8a1 1 0 001 1 3 3 0 010 6 1 1 0 00-1 1v2.5A2.5 2.5 0 002.5 21h19a2.5 2.5 0 002.5-2.5V16a1 1 0 00-1-1 3 3 0 010-6zM12 5a1 1 0 11-1 1 1 1 0 011-1zM6 19a1 1 0 111-1 1 1 0 01-1 1zM6 7a1 1 0 111-1 1 1 0 01-1 1zm6 12a1 1 0 111-1 1 1 0 01-1 1zm3.21-7.435l-1.349 1.09a.249.249 0 00-.077.282l.656 1.749a.5.5 0 01-.758.583l-1.542-1.1a.249.249 0 00-.29 0l-1.541 1.1a.5.5 0 01-.759-.582l.656-1.749a.249.249 0 00-.077-.282L8.78 11.565a.5.5 0 01.314-.889h1.441a.25.25 0 00.23-.153l.773-1.832a.5.5 0 01.922 0l.765 1.831a.25.25 0 00.231.153H14.9a.5.5 0 01.314.889zM18 19a1 1 0 111-1 1 1 0 01-1 1zm0-12a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});