define("ember-svg-jar/inlined/design-file-pyramid-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-pyramid-alternate</title><path d=\"M12 16.286c-2.6 0-4.544.919-4.544 1.741S9.4 19.77 12 19.77s4.544-.92 4.544-1.743S14.6 16.286 12 16.286zM8.383 15.931A9.438 9.438 0 0112 15.286a9.447 9.447 0 013.625.648.245.245 0 00.317-.328l-3.661-8.541a.248.248 0 00-.456-.006C11.1 8.71 8.85 13.822 8.067 15.6a.245.245 0 00.316.331z\"/><path d=\"M21.707 5.707L16.293.293A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.465a.5.5 0 01.353.146l4.536 4.536a.5.5 0 01.146.353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});