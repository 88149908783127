define("ember-svg-jar/inlined/seo-search-reward", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seo-search-reward</title><path d=\"M18.546 13.278a7.72 7.72 0 00-.216-1.821 1.25 1.25 0 10-2.43.586 5.25 5.25 0 11-10.212.015 1.25 1.25 0 00-2.434-.575 7.76 7.76 0 007.546 9.545 7.716 7.716 0 004.353-1.341.249.249 0 01.317.029l3.8 3.8a1.25 1.25 0 101.767-1.768l-3.8-3.8a.25.25 0 01-.03-.318 7.7 7.7 0 001.339-4.352zM18.8 1.5a1.5 1.5 0 10-2.633.982.252.252 0 01-.026.354L14 4.668a.249.249 0 01-.339-.013l-1.775-1.772a.249.249 0 010-.349 1.5 1.5 0 10-2.172 0 .249.249 0 010 .349L7.932 4.656a.25.25 0 01-.339.012L5.455 2.836a.249.249 0 01-.026-.354 1.5 1.5 0 10-1.667.42.25.25 0 01.154.177l1.213 5.26A1.5 1.5 0 006.591 9.5H15a1.493 1.493 0 001.462-1.162l1.214-5.259a.248.248 0 01.154-.179 1.5 1.5 0 00.97-1.4zM.429 22l.265.038a1.506 1.506 0 011.273 1.273l.038.265a.5.5 0 00.99 0l.038-.266a1.506 1.506 0 011.274-1.272L4.571 22a.5.5 0 000-.991l-.265-.038a1.506 1.506 0 01-1.273-1.273L3 19.429a.5.5 0 00-.99 0l-.038.266a1.506 1.506 0 01-1.279 1.272l-.264.038a.5.5 0 000 .991zM23.57 7.505l-.318-.045a1.909 1.909 0 01-1.611-1.613l-.041-.318a.5.5 0 00-.5-.429.5.5 0 00-.5.43l-.044.317a1.914 1.914 0 01-1.608 1.613l-.318.045a.5.5 0 000 .991l.318.045a1.914 1.914 0 011.612 1.611l.044.318a.5.5 0 00.5.43.5.5 0 00.495-.428l.046-.319a1.909 1.909 0 011.611-1.612l.314-.041a.5.5 0 000-.991z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});