define("ember-svg-jar/inlined/folder-text-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-text-alternate</title><path d=\"M5.365 3.4l1.192 1.238A2 2 0 008 5.25h13a1 1 0 000-2H8.208a.5.5 0 01-.359-.15L6.657 1.862a2 2 0 00-1.441-.612h-2.93A2.285 2.285 0 000 3.536V15.25a1 1 0 002 0V3.536a.286.286 0 01.286-.286h2.719a.5.5 0 01.36.15z\"/><path d=\"M22 6.75H6a2 2 0 00-2 2v12a2 2 0 002 2h16a2 2 0 002-2v-12a2 2 0 00-2-2zm0 13.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5h15a.5.5 0 01.5.5z\"/><path d=\"M12 12.5H9A.75.75 0 009 14h3a.75.75 0 000-1.5zM12 16H9a.75.75 0 000 1.5h3a.75.75 0 000-1.5zM19.5 12h-5a.75.75 0 00-.75.75v1a.75.75 0 001.5 0v-.125a.125.125 0 01.125-.125h.752a.123.123 0 01.123.123v2.752a.125.125 0 01-.125.125H16a.75.75 0 000 1.5h2a.75.75 0 000-1.5h-.125a.125.125 0 01-.125-.125v-2.752a.123.123 0 01.123-.123h.75a.127.127 0 01.127.127v.123a.75.75 0 001.5 0v-1a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});