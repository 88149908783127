define("ember-svg-jar/inlined/crafts-origami", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crafts-origami</title><path d=\"M21.919 2.26L18.9 4.028a.25.25 0 01-.376-.216v-2.3a1.354 1.354 0 00-2.414-1l-5.929 5.927a.251.251 0 01-.181.073H4.545a1.01 1.01 0 00-.737.3L.521 10.1A1.41 1.41 0 00.1 11.688a1.411 1.411 0 001.423.825h4.334a.25.25 0 01.221.134l3.548 6.789a.992.992 0 00.168.241l.033.033c0 .043.26.186.36.221l10.427 3.915a1.3 1.3 0 001.641-.293 1.32 1.32 0 00-.106-1.677l-3.13-4.326a.25.25 0 01-.033-.232l4.886-13.407c.5-1.326-.594-2.437-1.953-1.651zM16.1 3.356a.25.25 0 01.427.177v9.793a.25.25 0 01-.453.146L11.957 7.79a.249.249 0 01.026-.323zM3.537 10.512a.25.25 0 01-.177-.426l.666-.666a.249.249 0 01.4.061l.348.666a.251.251 0 01-.222.366zm2.827-1.638a.249.249 0 01.007-.245.252.252 0 01.214-.12l2.67-.009a.25.25 0 01.25.25l.014 5.141a.25.25 0 01-.472.116zm5.32 9.486a.25.25 0 01-.162-.234v-6.753a.25.25 0 01.452-.147l6.707 9.274a.249.249 0 010 .3.252.252 0 01-.287.083zm7.323-6.928a.25.25 0 01-.485-.086V6.708a.248.248 0 01.124-.215l2.143-1.254a.251.251 0 01.362.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});