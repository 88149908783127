define("ember-svg-jar/inlined/electronics-transister", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>electronics-transister</title><path d=\"M19 10.5h-.75a.25.25 0 01-.25-.25V1.5A1.5 1.5 0 0016.5 0h-9A1.5 1.5 0 006 1.5v8.75a.25.25 0 01-.25.25H5a1 1 0 100 2h1.75a.25.25 0 01.25.25V23a1 1 0 002 0V12.751a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V23a1 1 0 102 0V12.751a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V23a1 1 0 102 0V12.751a.25.25 0 01.25-.25H19a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});