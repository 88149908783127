define("ember-svg-jar/inlined/people-man-7.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-7</title><path d=\"M21.08 18.287l-.017-.006-4.476-1.5a.5.5 0 00-.562.178 4.985 4.985 0 01-8.05 0 .5.5 0 00-.562-.178L2.92 18.287A4.519 4.519 0 000 22.5v1a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-1a4.519 4.519 0 00-2.92-4.213z\"/><path d=\"M6.186 12.071a.251.251 0 01.142.188c.51 3.047 4.011 4.991 5.672 4.991s5.162-1.944 5.672-4.992a.251.251 0 01.142-.187 2.225 2.225 0 00.921-.8.254.254 0 01.153-.1c.239-.053.483-.11.741-.179a.5.5 0 00.183-.874c-.675-.54-.767-1.769-.874-3.192C18.72 4 18.42 0 12 0 9.579 0 7.778.676 6.649 2.009 5.5 3.366 5.085 5.349 5.4 7.9a.249.249 0 01-.075.211 2.017 2.017 0 00-.57 1.531 2.728 2.728 0 001.431 2.429zm10.045-.231a1.6 1.6 0 00-.04.172C15.816 14.26 13.029 15.75 12 15.75s-3.816-1.49-4.191-3.738a1.752 1.752 0 00-1-1.306 1.281 1.281 0 01-.557-1.1.634.634 0 01.1-.44.7.7 0 00.19-.14A.1.1 0 016.618 9a1.5 1.5 0 001.471-1.206l.211-1.06a.249.249 0 01.273-.2c1.637.19 2.236 1.134 2.92 2.21.906 1.424 1.826 2.75 4.5 2.774a.249.249 0 01.237.321z\"/><path d=\"M12.741 12.328a1.311 1.311 0 01-.236.1 2.135 2.135 0 01-1.157 0 1.321 1.321 0 01-.241-.1.75.75 0 00-.711 1.321 2.882 2.882 0 00.511.211 3.512 3.512 0 001.019.148 3.445 3.445 0 001.017-.147 2.7 2.7 0 00.513-.213.75.75 0 10-.715-1.318zM10.992 10.026a.751.751 0 00-.055-1.059 2.1 2.1 0 00-2.635 0 .75.75 0 101 1.115.632.632 0 01.627 0 .751.751 0 001.059-.056z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});