define("ember-svg-jar/inlined/road-sign-right-turn-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-right-turn-1 -alternate</title><path d=\"M14 7.97a.75.75 0 00-1.28.53v.75a.25.25 0 01-.25.25h-1a3 3 0 00-3 3v4a1 1 0 002 0v-4a1 1 0 011-1h1a.25.25 0 01.25.25v.75a.75.75 0 001.28.53l2-2a.749.749 0 000-1.06z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22.5A10.5 10.5 0 1122.5 12 10.512 10.512 0 0112 22.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});