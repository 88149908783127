define("ember-svg-jar/inlined/yoga-leg-grab-stretch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>yoga-leg-grab-stretch</title><circle cx=\"7.928\" cy=\"3.25\" r=\"2.5\"/><path d=\"M13.489 7.957l1.338-1.095a.5.5 0 01.315-.112h3.786a1.5 1.5 0 000-3h-3.787a3.5 3.5 0 00-2.216.791L8.531 8.136a.509.509 0 01-.32.114H2.428a1.5 1.5 0 000 3h5.787a3.5 3.5 0 002.216-.791l.736-.6 1.3 1.675a.5.5 0 00.729.065l1.489-1.343a.5.5 0 00.06-.678zM22.862 9.054L22.3 7.5a1.5 1.5 0 00-2.819 1.026l.566 1.557a.5.5 0 01-.47.672h-3.726a.5.5 0 00-.332.126L13.583 12.6a.5.5 0 00-.167.374l.012 8.777a1.5 1.5 0 003 0v-7.5a.5.5 0 01.5-.5h2.518a3.683 3.683 0 001.987-.532 3.507 3.507 0 001.429-4.165z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});