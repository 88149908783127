define("ember-svg-jar/inlined/landmark-buddha-statue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-buddha-statue</title><path d=\"M14.636 11.068a.248.248 0 00-.189.05A4.043 4.043 0 0112 12a4.034 4.034 0 01-2.417-.865.25.25 0 00-.213-.044 6.591 6.591 0 00-4.026 2.944c-.48.96-.739 2.522.754 4.511a.5.5 0 00.4.2H18a.5.5 0 00.5-.5V15.5a4.489 4.489 0 00-3.864-4.432zM4.618 11.2l1.891-.651a1 1 0 00-.651-1.891l-1.891.652a1 1 0 10.651 1.89zM4.313 6.468l1.937.5a1 1 0 00.5-1.936l-1.937-.5a1 1 0 00-.5 1.936zM7.085 3.168A1 1 0 108.6 1.863L7.3.348a1 1 0 00-1.516 1.3zM20.653 10.576a1 1 0 00-.62-1.271l-1.891-.652a1 1 0 10-.651 1.891l1.891.651a1 1 0 001.271-.619zM16.532 6.25a1 1 0 001.218.718l1.937-.5a1 1 0 00-.5-1.936l-1.937.5a1 1 0 00-.718 1.218zM16.915 3.168l1.3-1.516A1 1 0 1016.7.348l-1.3 1.515a1 1 0 101.516 1.305zM14.874 20.18a.251.251 0 00-.241-.18H4.5a2 2 0 000 4h2.627a.5.5 0 00.116-.014 27.2 27.2 0 007.525-3.525.25.25 0 00.106-.281zM19.5 20h-1.254a.479.479 0 00-.291.1 41.723 41.723 0 01-5.485 3.43.25.25 0 00.114.472H19.5a2 2 0 000-4zM12 11a3.051 3.051 0 003-3 2.66 2.66 0 00-1.661-2.768.251.251 0 01-.116-.379A1.476 1.476 0 0013.5 4a1.5 1.5 0 00-3 0 1.473 1.473 0 00.276.858.251.251 0 01-.116.379A2.66 2.66 0 009 8a3.051 3.051 0 003 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});