define("ember-svg-jar/inlined/human-resources-hierarchy-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-hierarchy-woman</title><path d=\"M8.386 13.211a1 1 0 001.406-1.423c-.159-.156-.641-.632-.641-2.392a4.1 4.1 0 00-8.209 0c0 1.76-.481 2.236-.64 2.392a1 1 0 101.406 1.423 3.535 3.535 0 00.442-.533.251.251 0 01.362-.057 4.1 4.1 0 00.536.358.25.25 0 01-.021.447A5.006 5.006 0 00.047 18v.25a.25.25 0 00.25.25H9.8a.25.25 0 00.25-.25V18a5.007 5.007 0 00-2.981-4.574.251.251 0 01-.021-.447 3.969 3.969 0 00.536-.358.25.25 0 01.194-.05.246.246 0 01.168.107 3.541 3.541 0 00.44.533zM5.047 11.5a2.109 2.109 0 01-2.071-1.728.251.251 0 01.2-.29 3.2 3.2 0 00.773-.252 2.421 2.421 0 00.924-.759.25.25 0 01.4-.016 3.958 3.958 0 00.882.775 2.893 2.893 0 00.769.373.249.249 0 01.171.3A2.109 2.109 0 015.047 11.5zM14.505 5.25a.249.249 0 01.25-.25h.75a1 1 0 100-2h-1.5a1.5 1.5 0 00-1.5 1.5v6.25a.25.25 0 01-.25.25h-.25a1 1 0 000 2h.25a.249.249 0 01.25.25v6.25a1.5 1.5 0 001.5 1.5h1.5a1 1 0 000-2h-.75a.25.25 0 01-.25-.25z\"/><circle cx=\"20.505\" cy=\"17.944\" r=\"1.944\"/><path d=\"M20.505 20.5a3.5 3.5 0 00-3.49 3.231.253.253 0 00.066.189.25.25 0 00.183.08h6.481a.252.252 0 00.25-.27 3.5 3.5 0 00-3.49-3.23z\"/><circle cx=\"20.505\" cy=\"1.944\" r=\"1.944\"/><path d=\"M20.505 4.5a3.5 3.5 0 00-3.49 3.231.253.253 0 00.066.189.25.25 0 00.183.08h6.481A.252.252 0 0024 7.73a3.5 3.5 0 00-3.495-3.23z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});