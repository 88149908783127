define("ember-svg-jar/inlined/messaging-gabbr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messaging-gabbr</title><path d=\"M17.48 17.85l-2-1.47a2.84 2.84 0 00-2.16-.64 2.52 2.52 0 00-1.6 1.2c-.21.31-.51.59-1.24.26a7.69 7.69 0 01-4.07-4c-.35-.79-.25-1.25.35-1.66A2.1 2.1 0 007.9 9.71C7.84 8.57 5.43 5.29 4.37 4.9a2.13 2.13 0 00-1.44 0 4 4 0 00-2.48 2A3.81 3.81 0 00.49 10 23.75 23.75 0 0013.6 23.2a4.91 4.91 0 001.14.3 4.67 4.67 0 003.88-2.94c.5-1.45-.45-2.18-1.14-2.71zM23.83 11.35A15.72 15.72 0 0013 .54a1 1 0 10-.53 1.93 13.69 13.69 0 019.41 9.42 1 1 0 101.93-.53z\"/><path d=\"M11.89 3.76a1 1 0 10-.41 2A9.46 9.46 0 0118.67 13a1 1 0 002-.38 11.51 11.51 0 00-8.78-8.86z\"/><path d=\"M10.53 9.06a5.19 5.19 0 014.77 4.77 1 1 0 001.41.85 1 1 0 00.59-1 7.18 7.18 0 00-3.07-5.38 6.9 6.9 0 00-3.58-1.28 1 1 0 00-1 .56 1 1 0 00-.1.38 1 1 0 00.98 1.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});