define("ember-svg-jar/inlined/single-woman-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-question</title><path d=\"M17.5 0A6.5 6.5 0 1024 6.5 6.507 6.507 0 0017.5 0zm0 11.5a1 1 0 111-1 1 1 0 01-1 1zm.75-4.108v.359a.75.75 0 01-1.5 0V7.2a1.254 1.254 0 01.988-1.221 1.125 1.125 0 10-1.363-1.1.75.75 0 01-1.5 0 2.625 2.625 0 113.375 2.516zM11.983 17.868l-1.8-6.285a4.248 4.248 0 00-1.239-2.125.5.5 0 00-.764.1l-1.755 2.76a.5.5 0 01-.846 0L3.839 9.54a.5.5 0 00-.357-.23.5.5 0 00-.406.128 4.219 4.219 0 00-1.254 2.129l-1.8 6.3A.5.5 0 00.5 18.5h2.323a.25.25 0 01.246.2L4 23.6a.5.5 0 00.489.4h3a.5.5 0 00.49-.4l.931-4.9a.249.249 0 01.245-.2H11.5a.5.5 0 00.483-.632zM5.979 8.531a4.25 4.25 0 10-4.25-4.25 4.255 4.255 0 004.25 4.25zm0-2a2.247 2.247 0 01-2.159-1.64.25.25 0 01.159-.3A4.67 4.67 0 005.8 3.447a.25.25 0 01.357 0 4.67 4.67 0 001.821 1.14.25.25 0 01.16.3 2.249 2.249 0 01-2.159 1.644z\"/><path d=\"M17.5 0A6.5 6.5 0 1024 6.5 6.507 6.507 0 0017.5 0zm0 11.5a1 1 0 111-1 1 1 0 01-1 1zm.75-4.108v.359a.75.75 0 01-1.5 0V7.2a1.254 1.254 0 01.988-1.221 1.125 1.125 0 10-1.363-1.1.75.75 0 01-1.5 0 2.625 2.625 0 113.375 2.516zM11.983 17.868l-1.8-6.285a4.248 4.248 0 00-1.239-2.125.5.5 0 00-.764.1l-1.755 2.76a.5.5 0 01-.846 0L3.839 9.54a.5.5 0 00-.357-.23.5.5 0 00-.406.128 4.219 4.219 0 00-1.254 2.129l-1.8 6.3A.5.5 0 00.5 18.5h2.323a.25.25 0 01.246.2L4 23.6a.5.5 0 00.489.4h3a.5.5 0 00.49-.4l.931-4.9a.249.249 0 01.245-.2H11.5a.5.5 0 00.483-.632zM5.979 8.531a4.25 4.25 0 10-4.25-4.25 4.255 4.255 0 004.25 4.25zm0-2a2.247 2.247 0 01-2.159-1.64.25.25 0 01.159-.3A4.67 4.67 0 005.8 3.447a.25.25 0 01.357 0 4.67 4.67 0 001.821 1.14.25.25 0 01.16.3 2.249 2.249 0 01-2.159 1.644z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});