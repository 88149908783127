define("ember-svg-jar/inlined/smart-watch-square-missed-call-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-missed-call-alternate</title><path d=\"M21 6a3 3 0 00-3-3h-1.078a.249.249 0 01-.24-.182L15.98.364A.5.5 0 0015.5 0h-7a.5.5 0 00-.481.362l-.7 2.456A.248.248 0 017.077 3H6a3 3 0 00-3 3v12a3 3 0 003 3h1.077a.249.249 0 01.24.181l.7 2.456A.5.5 0 008.5 24h7a.5.5 0 00.48-.363l.7-2.456a.25.25 0 01.24-.181H18a3 3 0 003-3zm-2 12a1 1 0 01-1 1H6a1 1 0 01-1-1V6a1 1 0 011-1h12a1 1 0 011 1z\"/><path d=\"M14.444 13.234a1.57 1.57 0 00-2.167 0l-.8.8a.249.249 0 01-.345.007q-.6-.546-1.147-1.147a.249.249 0 01.008-.345l.8-.8a1.533 1.533 0 000-2.167l-.832-.833a1.538 1.538 0 00-2.17 0l-.455.456a1.932 1.932 0 00-.244 2.421 18.936 18.936 0 005.305 5.3 1.933 1.933 0 002.422-.244l.455-.455a1.533 1.533 0 000-2.168zM17.005 7.022a.749.749 0 00-1.06 0l-1.47 1.47a.251.251 0 01-.354 0l-.292-.292a.5.5 0 00-.854.353v2a.5.5 0 00.5.5h2a.5.5 0 00.354-.853l-.293-.293a.249.249 0 010-.354l1.469-1.469a.75.75 0 000-1.062z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});