define("ember-svg-jar/inlined/bulb-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bulb-alternate</title><path d=\"M9.4 17.888a.971.971 0 00.345.063h4.52a.971.971 0 00.345-.063 6.741 6.741 0 004.249-6.335A6.948 6.948 0 0012 4.7a6.948 6.948 0 00-6.854 6.858 6.741 6.741 0 004.254 6.33zM12 6.654a5.026 5.026 0 014.9 4.9 4.8 4.8 0 01-2.776 4.419.246.246 0 01-.1.021h-.791a.25.25 0 01-.25-.25v-1.953a.253.253 0 01.073-.177l1.6-1.6a.979.979 0 10-1.385-1.385l-1.094 1.091a.251.251 0 01-.354 0l-1.089-1.089a.979.979 0 10-1.385 1.385l1.6 1.6a.249.249 0 01.073.177v1.952a.25.25 0 01-.25.25H9.98a.246.246 0 01-.1-.021 4.8 4.8 0 01-2.78-4.421A5.026 5.026 0 0112 6.654zM12 3.979A.979.979 0 0012.979 3V1.131a.979.979 0 00-1.958 0V3a.979.979 0 00.979.979zM3.472 11.813a.98.98 0 100-1.959H1.229a.98.98 0 000 1.959zM4.943 6.141a.979.979 0 101.385-1.385l-1.87-1.869a.979.979 0 00-1.384 1.384zM19.548 10.833a.979.979 0 00.98.98h2.243a.98.98 0 000-1.959h-2.243a.979.979 0 00-.98.979zM18.365 6.427a.976.976 0 00.692-.286l1.869-1.87a.979.979 0 10-1.384-1.384l-1.87 1.869a.979.979 0 00.693 1.671zM14.938 20.462a.979.979 0 00-.98-.979h-3.916a.979.979 0 100 1.958h.81a.25.25 0 01.25.25v1.178a.979.979 0 001.958 0v-1.178a.25.25 0 01.25-.25h.648a.979.979 0 00.98-.979z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});