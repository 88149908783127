define("ember-svg-jar/inlined/focus-landscape", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>focus-landscape</title><path d=\"M15.335 10.493a.873.873 0 00-1.7-.039l-1.592 3.5a.25.25 0 01-.42.056l-1.1-1.314a1.163 1.163 0 00-1.967.152l-2.485 4.145a.5.5 0 00.429.757h11a.5.5 0 00.464-.686z\"/><circle cx=\"8\" cy=\"7.75\" r=\"2\"/><path d=\"M4 2.75H2a2 2 0 00-2 2v2a1 1 0 002 0v-1.5a.5.5 0 01.5-.5H4a1 1 0 000-2zM5 20.25a1 1 0 00-1-1H2.5a.5.5 0 01-.5-.5v-1.5a1 1 0 00-2 0v2a2 2 0 002 2h2a1 1 0 001-1zM22 2.75h-2a1 1 0 000 2h1.5a.5.5 0 01.5.5v1.5a1 1 0 002 0v-2a2 2 0 00-2-2zM23 16.25a1 1 0 00-1 1v1.5a.5.5 0 01-.5.5H20a1 1 0 000 2h2a2 2 0 002-2v-2a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});