define("ember-svg-jar/inlined/real-estate-action-house-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-house-warning</title><path d=\"M17.5 10a2.935 2.935 0 012.608 1.574l.23.439A.351.351 0 0021 11.85a.351.351 0 01.351-.351H23.5a.5.5 0 00.344-.863L13.029.408a1.5 1.5 0 00-2.059 0L.157 10.636A.5.5 0 00.5 11.5h2a.5.5 0 01.5.5v10a1 1 0 001 1h5.022a.5.5 0 00.5-.5 2.8 2.8 0 01.315-1.3l5.054-9.63A2.936 2.936 0 0117.5 10z\"/><path d=\"M23.836 21.905L18.78 12.27a1.448 1.448 0 00-2.56 0l-5.056 9.63a1.428 1.428 0 00.048 1.409 1.452 1.452 0 001.232.691h10.112a1.453 1.453 0 001.232-.685 1.428 1.428 0 00.048-1.41zM16.75 15.5a.75.75 0 011.5 0v3a.75.75 0 01-1.5 0zm.75 6.75a1 1 0 111-1 1 1 0 01-1 .999z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});