define("ember-svg-jar/inlined/office-razor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-razor</title><path d=\"M1.755 17.381a1.874 1.874 0 00-.5 1.207v4.657a.676.676 0 001.205.5l4.92-4.865a.251.251 0 000-.355l-3.188-3.186a.249.249 0 00-.351 0zM14.213 2.522a.249.249 0 00-.354 0l-9.12 9.121a1.5 1.5 0 000 2.121l4.242 4.241a1.5 1.5 0 002.121 0l9.122-9.12a.25.25 0 000-.353zm1.4 4.614a1.318 1.318 0 010 1.861l-6.261 6.256a.5.5 0 01-.707 0L7.493 14.1a.5.5 0 010-.707l6.255-6.257a1.318 1.318 0 011.861 0zM21.527 1.26a4.544 4.544 0 00-6.177-.126.251.251 0 00-.027.377l5.916 5.915a.249.249 0 00.376-.026 4.582 4.582 0 00-.088-6.14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});