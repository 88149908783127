define("ember-svg-jar/inlined/style-three-pin-barn-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-barn-1</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.541 14.153a1.5 1.5 0 001.92 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm6.97 9.672a.5.5 0 01-.47.328h-1a.5.5 0 00-.5.5V15a.5.5 0 01-.5.5H15a.5.5 0 01-.5-.5v-3a1 1 0 00-1-1H13a.5.5 0 00-.5.5V15a.5.5 0 01-1 0v-3.5a.5.5 0 00-.5-.5h-.5a1 1 0 00-1 1v3a.5.5 0 01-.5.5H7.5A.5.5 0 017 15v-4.5a.5.5 0 00-.5-.5h-1a.5.5 0 01-.323-.882l6.5-5.5a.5.5 0 01.647 0l6.5 5.5a.5.5 0 01.146.554z\"/><rect x=\"11\" y=\"7\" width=\"2\" height=\"2\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});