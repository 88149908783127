define("ember-svg-jar/inlined/park-fountain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>park-fountain</title><path d=\"M19.729 14.439a.75.75 0 00-.3-1.439H4.578a.75.75 0 00-.3 1.438 4.1 4.1 0 011.808 1.339c.8 1.208 1.373 2.56 3.8 3.041a.5.5 0 01.362.293 2.917 2.917 0 01.252 1.139 2.8 2.8 0 01-.159.912.5.5 0 01-.473.338h-.863a2 2 0 00-2 2 .5.5 0 00.5.5h9a.5.5 0 00.5-.5 2 2 0 00-2-2h-.873a.5.5 0 01-.473-.338 2.8 2.8 0 01-.159-.912 2.912 2.912 0 01.245-1.137.5.5 0 01.363-.293c2.438-.48 3.009-1.833 3.813-3.043a4.093 4.093 0 011.808-1.338zM5.005 5.5a1 1 0 001-1 2.5 2.5 0 015 0v1.04a.5.5 0 01-.578.494A2.657 2.657 0 0010.005 6a3 3 0 00-3 3 1 1 0 102 0 1 1 0 012-.015v2.265a1 1 0 001 1 1 1 0 001-1V9a1 1 0 012 0 1 1 0 102 0 3 3 0 00-3-3 2.66 2.66 0 00-.422.034.5.5 0 01-.579-.5V4.5a2.5 2.5 0 115 0 1 1 0 002 0 4.5 4.5 0 00-7.816-3.04.249.249 0 01-.368 0A4.5 4.5 0 004.005 4.5a1 1 0 001 1zM4.5 9.75a1.168 1.168 0 001.167-1.167 3.9 3.9 0 00-.756-1.617.517.517 0 00-.822 0 3.9 3.9 0 00-.756 1.617A1.168 1.168 0 004.5 9.75zM19.911 6.966a.517.517 0 00-.822 0 3.9 3.9 0 00-.756 1.617 1.167 1.167 0 102.334 0 3.9 3.9 0 00-.756-1.617z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});