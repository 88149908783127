define("ember-svg-jar/inlined/seat-vip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seat-vip</title><circle cx=\"5.501\" cy=\"4.535\" r=\"2.5\"/><path d=\"M15.853 18.4a4.521 4.521 0 00-4.453-3.867H9.843A1.5 1.5 0 018.387 13.4l-.931-3.729a1.5 1.5 0 10-2.91.726l.931 3.728a4.493 4.493 0 004.366 3.408H11.4a1.507 1.507 0 011.484 1.288l.631 3.888a1.5 1.5 0 002.971-.424z\"/><path d=\"M10.639 20.884a1 1 0 00-.948-.86 6.962 6.962 0 01-6.651-5.292c-.459-1.824-.647-2.6-.864-3.476a1 1 0 00-.97-.757H.5A.5.5 0 000 11v12a1 1 0 001 1h9a1 1 0 00.991-1.143c-.022-.146-.225-1.057-.352-1.973zM17.749 1.5a.75.75 0 000-1.5h-2a.75.75 0 000 1.5H16V6h-.25a.75.75 0 000 1.5h2a.75.75 0 000-1.5h-.25V1.5zM21.749 0h-1.5a.75.75 0 00-.75.75v6a.75.75 0 001.5 0V4.5h.75a2.25 2.25 0 100-4.5zm0 3H21V1.5h.75a.75.75 0 010 1.5zM11.75 7.5a.75.75 0 00.6-.3A8.3 8.3 0 0014 2.25V.75a.75.75 0 00-1.5 0v1.5a6.8 6.8 0 01-.75 3.089A6.8 6.8 0 0111 2.25V.75a.75.75 0 00-1.5 0v1.5a8.3 8.3 0 001.65 4.95.75.75 0 00.6.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});