define("ember-svg-jar/inlined/style-three-pin-car-repair-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-car-repair_1</title><path d=\"M13 9.5h-2c-.5 0-.879.757-1.1 1.692a.25.25 0 00.243.308h3.72a.25.25 0 00.243-.308C13.879 10.257 13.5 9.5 13 9.5z\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm1.829 5.665l1-2a.75.75 0 011.342.67l-1 2a.752.752 0 01-.672.415.741.741 0 01-.334-.079.75.75 0 01-.336-1.006zm-3 0l1-2a.75.75 0 111.342.67l-1 2a.752.752 0 01-.672.415.741.741 0 01-.334-.079.75.75 0 01-.336-1.006zm-3 0l1-2a.75.75 0 111.342.67l-1 2a.752.752 0 01-.671.415.741.741 0 01-.334-.079.75.75 0 01-.337-1.006zM6.25 5.5a.5.5 0 00-.5.5v7.75a.5.5 0 01-1 0V6a1.5 1.5 0 011.5-1.5.5.5 0 010 1zm9.393 8.849a.25.25 0 00-.143.226v.175a.75.75 0 01-1.5 0 .25.25 0 00-.25-.25h-3.5a.25.25 0 00-.25.25.75.75 0 01-1.5 0v-.175a.248.248 0 00-.143-.226 1.49 1.49 0 01.3-2.8.25.25 0 00.188-.2C9.064 10.193 9.622 8.5 11 8.5h2c1.378 0 1.936 1.693 2.157 2.851a.25.25 0 00.188.2 1.49 1.49 0 01.3 2.8zm3.607-.6a.5.5 0 01-1 0V6a.5.5 0 00-.5-.5.5.5 0 010-1 1.5 1.5 0 011.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});