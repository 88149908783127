define("ember-svg-jar/inlined/road-sign-speed-limit-40-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-speed-limit-40-alternate</title><path d=\"M16.024 7.978a3.48 3.48 0 00-3.476 3.476v2.476a3.476 3.476 0 106.952 0v-2.476a3.48 3.48 0 00-3.476-3.476zM17.5 13.93a1.476 1.476 0 01-2.952 0v-2.476a1.476 1.476 0 112.952 0zM10.452 7.978A5.958 5.958 0 004.5 13.93a1 1 0 001 1h3.7a.25.25 0 01.25.25v1.226a1 1 0 002 0V8.978a1 1 0 00-.998-1zm-1 4.7a.25.25 0 01-.25.25H6.967a.25.25 0 01-.235-.335 3.974 3.974 0 012.385-2.386.252.252 0 01.229.031.249.249 0 01.106.2z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});