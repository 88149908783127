define("ember-svg-jar/inlined/outdoors-shelter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-shelter</title><path d=\"M3.022 21.66a2.216 2.216 0 002.216 2.216h17.387a1.25 1.25 0 000-2.5H6.522a1 1 0 01-1-1V8.729a1 1 0 01.7-.955L23 2.569a1.25 1.25 0 00-.74-2.387L1 6.779a1.25 1.25 0 00.37 2.443 1.222 1.222 0 00.37-.056l.953-.3a.249.249 0 01.324.238z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});