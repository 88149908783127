define("ember-svg-jar/inlined/button-loop-forward", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-loop-forward</title><path d=\"M16.679 18.275a.976.976 0 010 1.38l-3.235 3.235a1 1 0 001.7.687l3.9-3.905a1 1 0 000-1.414l-3.9-3.9a1 1 0 00-1.7.686z\"/><path d=\"M22.25 9.462a1.25 1.25 0 001.25-1.25V5.284A5.162 5.162 0 0018.345.128H5.655A5.161 5.161 0 00.5 5.284v9.776a5.16 5.16 0 005.155 5.155h3.631a.25.25 0 01.25.25v2.4a1 1 0 00.617.924.987.987 0 00.383.076 1 1 0 00.707-.292l1.733-1.734 2.171-2.171a1 1 0 000-1.414l-2.171-2.171-1.733-1.734a1 1 0 00-1.707.707v2.4a.25.25 0 01-.25.25H5.655A2.658 2.658 0 013 15.06V5.284a2.659 2.659 0 012.655-2.656h12.69A2.659 2.659 0 0121 5.284v2.928a1.25 1.25 0 001.25 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});