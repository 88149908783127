define("ember-svg-jar/inlined/arrow-corner-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-corner-left</title><path d=\"M20 18.5H8.207a.25.25 0 01-.177-.427L21.06 5.06a1.5 1.5 0 00-2.12-2.123l-13.013 13a.25.25 0 01-.427-.177V4a1.5 1.5 0 00-3 0v15A2.5 2.5 0 005 21.5h15a1.5 1.5 0 000-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});