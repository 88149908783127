define("ember-svg-jar/inlined/smiley-cowboy-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-cowboy-alternate</title><path d=\"M23.121 4.441a1 1 0 10-1.414 1.415 1 1 0 01-.567 1.692.25.25 0 01-.267-.145L18.692 2.55a3.556 3.556 0 00-2.2-2.263c-1.554-.447-3.033.6-3.745 1.1l-.188.131a1 1 0 01-1.11 0l-.188-.135C10.562.873 9.12-.188 7.553.25a3.564 3.564 0 00-2.235 2.275L3.127 7.4a.251.251 0 01-.267.145 1 1 0 01-.567-1.692A1 1 0 10.879 4.441 3 3 0 003 9.563h18a3 3 0 002.121-5.122zM7.153 3.319c.19-.454.506-1.022.938-1.142.633-.178 1.544.5 1.985.819.1.075.191.139.26.185a2.993 2.993 0 003.328 0l.236-.161c.458-.322 1.415-.989 2.038-.811.422.122.729.671.923 1.135l1.733 3.866a.25.25 0 01-.228.353H5.634a.25.25 0 01-.228-.353zM21.164 10.643a1 1 0 00-.8 1.164A8.5 8.5 0 113.5 13.353a8.581 8.581 0 01.14-1.546 1 1 0 00-1.967-.361A10.5 10.5 0 1022.5 13.353a10.577 10.577 0 00-.173-1.907 1 1 0 00-1.163-.803z\"/><path d=\"M7.553 16.847a.393.393 0 000 .395 5.136 5.136 0 008.894 0 .395.395 0 00-.342-.592H7.9a.392.392 0 00-.347.197z\"/><circle cx=\"8.136\" cy=\"12.785\" r=\"1.5\"/><circle cx=\"15.864\" cy=\"12.785\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});