define("ember-svg-jar/inlined/programming-hold-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-hold-laptop</title><path d=\"M18.848 18.9a.253.253 0 01-.123-.275l.494-2.153c.343-1.438-.971-2.052-1.688-.938a21.692 21.692 0 00-1.487 3.817.973.973 0 00-.044.293v4.106a.25.25 0 00.25.25h3.5a.25.25 0 00.25-.25v-1.072a.5.5 0 01.148-.356c.567-.561 2.615-2.589 3.558-3.528a1 1 0 00.294-.709v-7.146a.5.5 0 00-.665-.472c-.73.256-1.8 1.389-1.834 5.829a.5.5 0 01-.147.35l-2.208 2.209a.253.253 0 01-.298.045zM3.861 22.324A.5.5 0 014 22.67v1.08a.25.25 0 00.25.25h3.5a.25.25 0 00.25-.25v-4.109a.973.973 0 00-.044-.293 21.692 21.692 0 00-1.487-3.817c-.717-1.114-2.031-.5-1.688.938l.494 2.153a.25.25 0 01-.421.233l-2.208-2.209A.5.5 0 012.5 16.3c-.032-4.44-1.1-5.573-1.834-5.829a.5.5 0 00-.666.468v7.105a1 1 0 00.329.741c1.035.937 2.996 2.978 3.532 3.539zM6.115 12.5h11.77A3.062 3.062 0 0021 9.5a.5.5 0 00-.5-.5h-.75a.25.25 0 01-.25-.25v-7.1A1.649 1.649 0 0017.853 0H6.147A1.649 1.649 0 004.5 1.648v7.1A.25.25 0 014.25 9H3.5a.5.5 0 00-.5.5 3.062 3.062 0 003.115 3zm.385-10A.5.5 0 017 2h10a.5.5 0 01.5.5v6.25a.25.25 0 01-.25.25H6.75a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});