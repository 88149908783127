define("ember-svg-jar/inlined/cog-hand-give", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cog-hand-give</title><path d=\"M15.5 19.5H6.374a.625.625 0 110-1.25H8.9a1 1 0 00.919-1.394A2.234 2.234 0 007.763 15.5H3.5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.762a2.239 2.239 0 002.238-2.242V20.5a1 1 0 00-1-1zM1 14.745a1 1 0 00-1 1V23a1 1 0 002 0v-7.25a1 1 0 00-1-1.005zM22.214 8.968a.69.69 0 010-.933l1.22-1.316a2.056 2.056 0 00.275-2.471 2.04 2.04 0 00-2.263-.983l-1.744.4a.678.678 0 01-.8-.465l-.524-1.72A2.045 2.045 0 0016.393.007a2.044 2.044 0 00-1.988 1.477L13.881 3.2a.675.675 0 01-.8.466l-1.743-.4a2.032 2.032 0 00-2.263.982 2.056 2.056 0 00.275 2.471l1.22 1.316a.688.688 0 010 .933l-1.22 1.316a2.085 2.085 0 001.988 3.454l1.745-.4a.678.678 0 01.8.464l.524 1.721a2.076 2.076 0 003.975 0L18.9 13.8a.679.679 0 01.8-.465l1.744.4a2.036 2.036 0 002.263-.982 2.057 2.057 0 00-.275-2.472zm-5.822-2.461A1.994 1.994 0 1114.4 8.5a1.994 1.994 0 011.992-1.993z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});