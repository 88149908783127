define("ember-svg-jar/inlined/signal-no", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>signal-no</title><path d=\"M23 13h-2a1 1 0 010-2h2a1 1 0 010 2zM18 13h-2a1 1 0 010-2h2a1 1 0 010 2zM13 13h-2a1 1 0 010-2h2a1 1 0 010 2zM8 13H6a1 1 0 010-2h2a1 1 0 010 2zM3 13H1a1 1 0 010-2h2a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});