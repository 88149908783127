define("ember-svg-jar/inlined/charging-flash-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>charging-flash-sync</title><path d=\"M23.709 11.5L21.79 8.941a.752.752 0 00-1.268.108L19.064 11.9a.75.75 0 00.668 1.092c.041 0-.006 0 .734-.06a8.524 8.524 0 01-14.788 4.8 1 1 0 10-1.478 1.34 10.525 10.525 0 0018.3-6.317l.678-.058a.749.749 0 00.531-1.197zM4.206 11.009l-.683.059A8.531 8.531 0 0118.6 6.58a1 1 0 001.543-1.272 10.519 10.519 0 00-18.631 5.931l-.685.061a.75.75 0 00-.537 1.2l1.921 2.558a.75.75 0 001.268-.108l1.458-2.85a.751.751 0 00-.731-1.091z\"/><path d=\"M10.936 16.713a.5.5 0 00.553-.144l3.941-4.588a.75.75 0 00-.57-1.237h-1a.25.25 0 01-.25-.25v-3.25a.5.5 0 00-.879-.326l-3.942 4.589a.75.75 0 00.571 1.237h1a.25.25 0 01.25.25v3.25a.5.5 0 00.326.469z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});