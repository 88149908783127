define("ember-svg-jar/inlined/animal-print-bird", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>animal-print-bird</title><path d=\"M16.789 11.751a.5.5 0 00-.464-.019c-.163.078-4.011 1.936-4.524 4.984a7.335 7.335 0 00.064 3.525 2.393 2.393 0 001.946 1.681 1.7 1.7 0 00.29.025c.808 0 1.446-.612 1.9-1.823a20.723 20.723 0 00.8-3.536 19.657 19.657 0 00.237-4.446.5.5 0 00-.249-.391zM20 12.394a.492.492 0 00-.445-.135.5.5 0 00-.363.289 19.659 19.659 0 00-1.232 4.28 20.6 20.6 0 00-.4 3.6c.033 1.445.531 2.257 1.478 2.416a2.67 2.67 0 00.457.039 2.4 2.4 0 001.933-.991 7.329 7.329 0 001.213-3.309c.513-3.047-2.514-6.062-2.641-6.189zM5.22 1.688a.5.5 0 00-.793-.216c-.138.117-3.388 2.891-3.11 5.971.273 3.013 1.283 4.537 2.989 4.537.091 0 .183 0 .278-.013.957-.087 1.515-.859 1.659-2.3a20.683 20.683 0 00-.122-3.622 19.582 19.582 0 00-.901-4.357zM12.277 6.5C12 3.416 8.3 1.27 8.147 1.18a.5.5 0 00-.464-.017.5.5 0 00-.278.371 19.676 19.676 0 00-.1 4.452c.224 2.482.5 5.555 2.4 5.555.055 0 .111 0 .169-.008A2.4 2.4 0 0011.944 10a7.324 7.324 0 00.333-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});