define("ember-svg-jar/inlined/loading-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>loading-1</title><path d=\"M13.083.8a11.176 11.176 0 00-2.164 0 1.251 1.251 0 00-1.1 1.49l1.109 5.575a1.1 1.1 0 002.154 0l1.105-5.575A1.253 1.253 0 0013.083.8zM5.824 3.046a1.257 1.257 0 00-.978.27c-.28.23-.55.475-.8.727s-.5.526-.727.8a1.25 1.25 0 00.272 1.834l4.724 3.159a1.1 1.1 0 001.523-1.523L6.68 3.587a1.253 1.253 0 00-.856-.541zM1.3 10.034a1.251 1.251 0 00-.5.883 11.486 11.486 0 00.001 2.164 1.252 1.252 0 001.49 1.1l5.577-1.11a1.1 1.1 0 000-2.154L2.291 9.812a1.26 1.26 0 00-.991.222zM4.044 19.954c.254.254.524.5.8.729a1.251 1.251 0 001.834-.273l3.157-4.727A1.1 1.1 0 009.7 14.3a1.106 1.106 0 00-1.39-.134l-4.721 3.152a1.251 1.251 0 00-.271 1.834c.23.28.474.548.726.802zM10.914 23.2a10.931 10.931 0 002.17 0 1.244 1.244 0 00.876-.5 1.26 1.26 0 00.224-1l-1.109-5.57a1.1 1.1 0 00-2.154 0L9.814 21.7a1.264 1.264 0 00.224 1 1.243 1.243 0 00.876.5zM18.176 20.952a1.259 1.259 0 00.977-.271c.28-.23.55-.474.8-.726s.5-.521.73-.8a1.251 1.251 0 00-.273-1.834l-4.725-3.156a1.1 1.1 0 00-1.385.135 1.106 1.106 0 00-.135 1.39l3.156 4.723a1.254 1.254 0 00.855.539zM15.25 12a1.1 1.1 0 00.885 1.078l5.574 1.109A1.253 1.253 0 0023.2 13.08a11.503 11.503 0 000-2.163 1.248 1.248 0 00-1.489-1.1l-5.578 1.109A1.1 1.1 0 0015.25 12zM19.957 4.043c-.254-.254-.524-.5-.8-.73a1.253 1.253 0 00-1.834.274l-3.161 4.726a1.1 1.1 0 001.525 1.524l4.724-3.158a1.253 1.253 0 00.272-1.835 12.486 12.486 0 00-.726-.801z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});