define("ember-svg-jar/inlined/warehouse-truck-delivery-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>warehouse-truck-delivery-1</title><path d=\"M22 16.75a2.464 2.464 0 011.59.581.25.25 0 00.41-.192V13.25a1.5 1.5 0 00-1.286-1.485.249.249 0 01-.214-.247V8.25a2 2 0 00-2-2h-2.938a4 4 0 00-3.277 1.706L12.223 10.9a3.984 3.984 0 00-.723 2.3v3.851a.25.25 0 00.463.132 2.986 2.986 0 011.386-1.185.25.25 0 00.153-.231V13.2a1.99 1.99 0 01.361-1.147L15.924 9.1a2 2 0 011.638-.853h2.688a.25.25 0 01.25.25v3a.25.25 0 01-.25.25h-.836a1.511 1.511 0 00-1.06.439L15.646 14.9a.5.5 0 00-.146.354v.513a.25.25 0 00.153.231 3 3 0 011.838 2.525.25.25 0 00.249.231h1.614a.251.251 0 00.241-.182A2.5 2.5 0 0122 16.75z\"/><circle cx=\"14.5\" cy=\"18.75\" r=\"2\"/><circle cx=\"22\" cy=\"19.25\" r=\"1.5\"/><path d=\"M9 3.25a1 1 0 00-1 1V17.5a.25.25 0 01-.25.25H3a1 1 0 000 2h5a2 2 0 002-2V4.25a1 1 0 00-1-1z\"/><path d=\"M6.5 15.25v-4.5a1 1 0 00-1-1H1a1 1 0 00-1 1v4.5a1 1 0 001 1h4.5a1 1 0 001-1zm-2-1H3a.5.5 0 010-1h1.5a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});