define("ember-svg-jar/inlined/touch-id-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>touch-id-desktop</title><path d=\"M14.35 20.681a.5.5 0 00-.385-.181h-3.93a.5.5 0 00-.385.181l-2.1 2.532a.5.5 0 00.41.787h8.079a.5.5 0 00.41-.787zM22.5 0h-21A1.5 1.5 0 000 1.5v16A1.5 1.5 0 001.5 19h21a1.5 1.5 0 001.5-1.5v-16A1.5 1.5 0 0022.5 0zM22 14.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M12 3.25a4.243 4.243 0 00-2.191.608.75.75 0 00.774 1.285A2.75 2.75 0 0114.75 7.5v2a2.727 2.727 0 01-.264 1.178.75.75 0 001.354.645 4.218 4.218 0 00.41-1.823v-2A4.255 4.255 0 0012 3.25zM12.787 12.137a2.789 2.789 0 01-.787.113A2.752 2.752 0 019.25 9.5v-2a2.742 2.742 0 01.114-.786.75.75 0 00-1.438-.428A4.249 4.249 0 007.75 7.5v2a4.255 4.255 0 005.463 4.075.75.75 0 00-.426-1.438z\"/><path d=\"M13 9.625A.625.625 0 0013.625 9V7.5A1.627 1.627 0 0012 5.875a.625.625 0 000 1.25.375.375 0 01.375.375V9a.625.625 0 00.625.625zM10.375 8v1.5A1.626 1.626 0 0012 11.125a.625.625 0 000-1.25.375.375 0 01-.375-.375V8a.625.625 0 00-1.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});