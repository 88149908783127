define("ember-svg-jar/inlined/fossil-energy-plant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fossil-energy-plant</title><path d=\"M21.5 22h-.966a.25.25 0 01-.229-.149L15.784 11.6a.249.249 0 01.229-.351h.487a1 1 0 000-2h-1.59a.25.25 0 01-.229-.149l-1.766-4A1 1 0 0012 4.5a1 1 0 00-.915.6l-1.762 4a.251.251 0 01-.229.149H7.5a1 1 0 000 2h.493a.249.249 0 01.229.351L3.71 21.851a.25.25 0 01-.228.149H2.5a1 1 0 000 2h19a1 1 0 000-2zm-6.764-7.018a.25.25 0 01.218-.055.247.247 0 01.174.143l1.211 2.745a.25.25 0 01-.392.29l-2.195-1.894a.252.252 0 01-.087-.189.255.255 0 01.086-.19zM11.6 8.9l.176-.4a.25.25 0 01.458 0l.176.4a.249.249 0 01-.228.351h-.353A.25.25 0 0111.6 8.9zm-1.668 3.781L10.5 11.4a.25.25 0 01.229-.149h2.551a.25.25 0 01.229.149l.564 1.279a.251.251 0 01-.065.29l-1.843 1.591a.25.25 0 01-.327 0L10 12.971a.249.249 0 01-.068-.29zm-2.259 5.132l1.205-2.738a.249.249 0 01.392-.089l.983.848a.251.251 0 010 .378L8.065 18.1a.25.25 0 01-.392-.29zm9.287 4.024a.249.249 0 01-.234.163h-9.44a.25.25 0 01-.164-.439l4.717-4.076a.25.25 0 01.327 0l4.723 4.076a.247.247 0 01.071.276zM14.247 4.2a1 1 0 001.395-.231 2.514 2.514 0 012.848-.906 1 1 0 00.653-1.891A4.523 4.523 0 0014.016 2.8a1 1 0 00.231 1.4zM5.51 3.061a2.514 2.514 0 012.848.906A1 1 0 109.984 2.8a4.523 4.523 0 00-5.127-1.63 1 1 0 00.653 1.891zM12 3.25a1 1 0 001-1V1a1 1 0 00-2 0v1.25a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});