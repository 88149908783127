define("ember-svg-jar/inlined/landmark-stonehenge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-stonehenge</title><path d=\"M7.5 8.752H5.34a2 2 0 00-1.993 1.834l-.666 10a2 2 0 001.993 2.166H7.5a2 2 0 002-2v-10a2 2 0 00-2-2zM18.66 8.752H16.5a2 2 0 00-2 2v10a2 2 0 002 2h2.826a2 2 0 001.993-2.166l-.666-10a2 2 0 00-1.993-1.834z\"/><rect y=\"1.248\" width=\"24\" height=\"6\" rx=\"1.5\" ry=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});