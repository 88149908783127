define("ember-svg-jar/inlined/instrument-triangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>instrument-triangle</title><path d=\"M11.544 5.253a.253.253 0 01-.044-.141V2.5a.5.5 0 011 0A3.5 3.5 0 0016 6h.5a1 1 0 000-2H16a1.5 1.5 0 01-1.5-1.5 2.5 2.5 0 00-5 0v2.607a.245.245 0 01-.045.143L1.679 16.429a1 1 0 001.642 1.142l6.968-10.017a.25.25 0 01.411 0l4.277 6.221a.251.251 0 00.383.035l1.079-1.079a.25.25 0 00.029-.318zM19.06 16.186a.251.251 0 00-.383-.035L17.6 17.229a.25.25 0 00-.029.318l1.761 2.561a.251.251 0 01-.206.392h-2.793a.249.249 0 00-.225.142 2.479 2.479 0 01-.487.686l-.744.745a.25.25 0 00.176.427H19.6a2 2 0 001.647-3.133zM6.7 20.833a.254.254 0 00-.032-.228.25.25 0 00-.2-.105H3a1 1 0 000 2h3.258a.25.25 0 00.25-.267c0-.077-.008-.155-.008-.233a3.489 3.489 0 01.2-1.167zM13.306 17.28a.25.25 0 01-.315.032 1 1 0 00-1.258.127l-3.147 3.147a2 2 0 002.828 2.828l3.147-3.146a1 1 0 00.127-1.259.25.25 0 01.032-.315l7.487-7.487a1 1 0 00-1.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});