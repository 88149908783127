define("ember-svg-jar/inlined/mailbox-full", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mailbox-full</title><path d=\"M19.251 6.625a.25.25 0 00-.25-.25H17.5a.25.25 0 00-.25.25v2.75a.75.75 0 01-1.5 0v-8.5a.75.75 0 00-.75-.75H9.5a.75.75 0 00-.75.75v3a.75.75 0 00.75.75H14a.249.249 0 01.25.25v1.25a.25.25 0 01-.25.25H4.8a4.3 4.3 0 00-4.3 4.3v5.7a.5.5 0 00.5.5h8.749a.25.25 0 01.25.25v5.75a1 1 0 002 0v-5.75a.25.25 0 01.25-.25H19a.25.25 0 00.25-.25zM23.377 9.047A.5.5 0 0023 8.875h-2a.25.25 0 00-.25.25v7.5a.25.25 0 00.25.25h1a.5.5 0 00.5-.429l1-7a.507.507 0 00-.123-.399z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});