define("ember-svg-jar/inlined/award-ribbon-star-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>award-ribbon-star-1</title><path d=\"M3.456 15.115A.25.25 0 003.24 15a.254.254 0 00-.209.124l-2.4 4.14a1 1 0 001.032 1.487l2.391-.405.833 2.288a1 1 0 001.8.168l1.974-3.34a.25.25 0 00-.131-.363 10.283 10.283 0 01-5.074-3.984zM23.366 19.266L20.954 15.1a.25.25 0 00-.425-.012 10.3 10.3 0 01-5.069 4 .248.248 0 00-.15.15.251.251 0 00.02.212l1.981 3.353a1 1 0 001.8-.171l.832-2.287 2.392.405a1 1 0 001.031-1.488zM20.734 9.455a8.75 8.75 0 10-8.75 8.75 8.761 8.761 0 008.75-8.75zm-5.258 3.424a.6.6 0 01-.849.745l-2.52-1.42a.251.251 0 00-.246 0l-2.52 1.42a.6.6 0 01-.849-.745l1.026-2.359a.252.252 0 00-.054-.278L7.612 8.416A.558.558 0 018 7.442h2.118a.25.25 0 00.225-.141l1.1-2.268a.6.6 0 011.071 0L13.62 7.3a.25.25 0 00.225.141h2.118a.558.558 0 01.393.974L14.5 10.242a.25.25 0 00-.054.278z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});