define("ember-svg-jar/inlined/navigation-left-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-left-3</title><path d=\"M5.776 5.622a.989.989 0 00-1.1.306L.224 11.371a.989.989 0 000 1.254l4.453 5.443a.99.99 0 001.756-.627v-3.956a.25.25 0 01.25-.25h10.139a.25.25 0 01.25.25v3.461a1.237 1.237 0 102.474 0v-9.9a1.237 1.237 0 10-2.474 0v3.462a.25.25 0 01-.25.25H6.683a.25.25 0 01-.25-.25V6.554a.99.99 0 00-.657-.932zM24 7.049a1.237 1.237 0 10-2.474 0v9.9a1.237 1.237 0 102.474 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});