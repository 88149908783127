define("ember-svg-jar/inlined/image-file-svg-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-svg-1</title><path d=\"M23.707 5.707L18.293.293A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.75V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zM3.427 13.367l1.84 1.227A1.927 1.927 0 014.2 18.125H2.5a.625.625 0 010-1.25h1.7a.678.678 0 00.376-1.242l-1.84-1.227A1.927 1.927 0 013.8 10.875h1.7a.625.625 0 010 1.25H3.8a.678.678 0 00-.376 1.242zm7.7-.367A8.176 8.176 0 019.5 17.875a.625.625 0 01-1 0A8.176 8.176 0 016.875 13v-1.5a.625.625 0 011.25 0V13a6.93 6.93 0 00.675 2.966.252.252 0 00.4.008A6.922 6.922 0 009.875 13v-1.5a.625.625 0 011.25 0zm5 .5a.625.625 0 01-1.25 0V13a.875.875 0 00-1.75 0v3a.875.875 0 001.67.365c.035-.075.083-.24-.1-.24h-.2a.625.625 0 010-1.25h1a.625.625 0 01.625.625v.5a2.125 2.125 0 01-4.25 0v-3a2.125 2.125 0 014.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});