define("ember-svg-jar/inlined/presentation-desk-notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>presentation-desk-notes</title><path d=\"M.719 19.374a.25.25 0 00-.2.394 62.348 62.348 0 001.008 1.357 1.994 1.994 0 001.562.75h6.484a.249.249 0 01.25.25v1a.75.75 0 00.75.75h3a.75.75 0 00.75-.75v-1a.249.249 0 01.25-.25h6.343a1.993 1.993 0 001.561-.75s.637-.834 1.008-1.357a.25.25 0 00-.2-.394zM23.505 18.374a.25.25 0 00.238-.327c-.588-1.838-3.525-10.8-3.525-10.8a2 2 0 00-1.9-1.373H8.163a.251.251 0 01-.236-.334l.617-1.748a1 1 0 01.943-.667h.726a.249.249 0 01.207.11 1.989 1.989 0 001.653.89h1.5a2 2 0 000-4h-1.5a1.988 1.988 0 00-1.653.889.25.25 0 01-.207.111h-.726a3.005 3.005 0 00-2.829 2l-.912 2.589a.251.251 0 01-.217.166 1.992 1.992 0 00-1.746 1.367S.845 16.209.257 18.047a.25.25 0 00.238.327zm-5.121-2.642a.5.5 0 01-.479.642H6.241a.5.5 0 01-.48-.642l2.206-7.5a.5.5 0 01.48-.358H15.7a.5.5 0 01.479.358z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});