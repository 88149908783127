define("ember-svg-jar/inlined/vip-crown-queen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vip-crown-queen</title><path d=\"M5 10.578a6.007 6.007 0 016 6V19a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-2.422a6.007 6.007 0 016-6 .134.134 0 00.117-.069.135.135 0 00-.006-.136A8.5 8.5 0 0013 6.572V4h1a1 1 0 000-2h-1V1a1 1 0 00-2 0v1h-1a1 1 0 000 2h1v2.572a8.5 8.5 0 00-6.111 3.8.132.132 0 00.111.206z\"/><path d=\"M9.5 19.5a.5.5 0 00.5-.5v-2.422a5 5 0 00-10 0 4.947 4.947 0 00.81 2.694.5.5 0 00.42.228zM19 11.578a5.005 5.005 0 00-5 5V19a.5.5 0 00.5.5h8.277a.5.5 0 00.42-.229 4.954 4.954 0 00.8-2.693 5.005 5.005 0 00-4.997-5zM9 20.5H1.87a.5.5 0 00-.382.177A2.052 2.052 0 001.01 22a1.937 1.937 0 002 2h18A1.932 1.932 0 0023 22a2.057 2.057 0 00-.474-1.322.5.5 0 00-.383-.178z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});