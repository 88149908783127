define("ember-svg-jar/inlined/iris-scan-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>iris-scan-2</title><circle cx=\"11.875\" cy=\"16.825\" r=\"1.485\"/><path d=\"M21.474 13.849a.25.25 0 00-.28-.025 7.836 7.836 0 01-5.53.844.25.25 0 00-.249.092.246.246 0 00-.022.264 3.9 3.9 0 01.441 1.8 3.959 3.959 0 11-3.959-3.959.085.085 0 00.078-.053.083.083 0 00-.021-.092 7.925 7.925 0 01-1.823-2.52.246.246 0 00-.268-.141 18.243 18.243 0 00-9.27 5.361 2.015 2.015 0 000 2.807c2.589 2.685 6.866 5.527 11.149 5.527h.3c4.3 0 8.573-2.842 11.161-5.526a2.012 2.012 0 000-2.808 20.51 20.51 0 00-1.707-1.571z\"/><path d=\"M24 6.927a.743.743 0 00-.742-.742h-.341a.247.247 0 01-.243-.2 5.451 5.451 0 00-4.409-4.409.246.246 0 01-.2-.243V.989a.742.742 0 10-1.484 0v.34a.246.246 0 01-.205.243 5.449 5.449 0 00-4.408 4.409.247.247 0 01-.244.2h-.34a.743.743 0 000 1.485h.341a.247.247 0 01.243.2 5.449 5.449 0 004.408 4.408.248.248 0 01.205.244v.34a.742.742 0 001.484 0v-.34a.248.248 0 01.2-.244 5.449 5.449 0 004.408-4.408.247.247 0 01.244-.2h.34A.743.743 0 0024 6.927zm-5.615 3.292a.247.247 0 01-.324-.235V9.9a.742.742 0 10-1.484 0v.087a.247.247 0 01-.324.235 3.475 3.475 0 01-2.226-2.225.247.247 0 01.236-.324h.087a.743.743 0 000-1.485h-.087a.247.247 0 01-.236-.324 3.472 3.472 0 012.226-2.225.247.247 0 01.324.235v.087a.742.742 0 101.484 0v-.09a.247.247 0 01.324-.235 3.472 3.472 0 012.226 2.225.248.248 0 01-.236.324h-.087a.743.743 0 000 1.485h.087a.248.248 0 01.236.324 3.475 3.475 0 01-2.226 2.225z\"/><circle cx=\"17.319\" cy=\"6.927\" r=\".99\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});