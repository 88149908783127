define("ember-svg-jar/inlined/tools-hammer-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-hammer-2</title><rect x=\"10.408\" y=\"5.342\" width=\"8.5\" height=\"8\" rx=\"2\" ry=\"2\" transform=\"rotate(-45.004 14.658 9.342)\"/><path d=\"M23.674 13.054L22.26 11.64a2 2 0 00-2.829 0L16.6 14.469a2 2 0 000 2.828l1.414 1.414a.5.5 0 00.708 0l4.95-4.95a.5.5 0 00.002-.707zM6.7 7.4a2 2 0 002.829 0l2.831-2.831a2 2 0 000-2.828L10.946.326a.5.5 0 00-.707 0l-4.95 4.951a.5.5 0 000 .707zM9.708 12.524a.5.5 0 00-.707 0L.693 20.833a1.75 1.75 0 002.474 2.475L11.476 15a.5.5 0 000-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});