define("ember-svg-jar/inlined/smiley-hypnotized", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-hypnotized</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm8.4 5.053a1 1 0 01-1.79.894A2.656 2.656 0 0016.5 5a1 1 0 010-2c1.153 0 3.142.547 3.9 2.053zM11.25 12A4.25 4.25 0 117 7.751 4.254 4.254 0 0111.25 12zM9.5 18.5h5a1 1 0 110 2h-5a1 1 0 010-2zm3.25-6.5A4.25 4.25 0 1117 16.251 4.254 4.254 0 0112.75 12zM8.5 4a1 1 0 01-1 1 2.656 2.656 0 00-2.1.947 1 1 0 11-1.79-.894C4.358 3.547 6.347 3 7.5 3a1 1 0 011 1z\"/><circle cx=\"7\" cy=\"12.001\" r=\"1.5\"/><circle cx=\"17\" cy=\"12.001\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});