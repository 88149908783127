define("ember-svg-jar/inlined/project-blueprint-buildings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>project-blueprint-buildings</title><path d=\"M4 16a4 4 0 104 4 4 4 0 00-4-4zm0 6.5A2.5 2.5 0 116.5 20 2.5 2.5 0 014 22.5z\"/><circle cx=\"4\" cy=\"20\" r=\"1.5\"/><path d=\"M22 2.5H10a1 1 0 000 2h11.5a.5.5 0 01.5.5v16.5a.5.5 0 01-.5.5H9a1 1 0 000 2h13a2 2 0 002-2V4.5a2 2 0 00-2-2zM.821 16.161a4.933 4.933 0 016.358 0A.5.5 0 008 15.778V4a4 4 0 00-8 0v11.778a.5.5 0 00.821.383z\"/><path d=\"M17 18.5h1.5a.5.5 0 00.5-.5v-7.5a.5.5 0 00-.276-.447l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 0012 10.5V18a.5.5 0 00.5.5H14a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5zM14 11h3a.5.5 0 010 1h-3a.5.5 0 010-1zm0 3.5a.5.5 0 010-1h3a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});