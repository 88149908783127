define("ember-svg-jar/inlined/smiley-shocked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-shocked</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM5.793 7.707a1 1 0 011.414-1.414l3 3a1 1 0 01-.543 1.693l-3 .5a.927.927 0 01-.164.014 1 1 0 01-.163-1.986l1.083-.181zM9.5 16a2.5 2.5 0 015 0v1.5a2.5 2.5 0 01-5 0zm9.49-5.358a1 1 0 01-1.132.848l-3.5-.5a1 1 0 01-.565-1.7l3-3a1 1 0 111.414 1.414l-1.586 1.589 1.521.217a1 1 0 01.848 1.132z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});