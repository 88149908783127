define("ember-svg-jar/inlined/landmark-angkor-wat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-angkor-wat</title><path d=\"M11.355 7.2a.084.084 0 00.106.035 1.485 1.485 0 011.078 0 .084.084 0 00.106-.035 82.625 82.625 0 012.533-4.048.5.5 0 00-.032-.6A10.03 10.03 0 0012.22.176a.487.487 0 00-.44 0 10.513 10.513 0 00-2.914 2.4.5.5 0 00-.033.6A81.008 81.008 0 0111.355 7.2zM4.414 7.408A8.037 8.037 0 017.8 8.983a.5.5 0 00.646 0 10.206 10.206 0 011.572-1.077.5.5 0 00.184-.692C8.816 4.889 7.429 2.874 7.41 2.846a.5.5 0 00-.661-.158 5.793 5.793 0 00-2.717 4.151.5.5 0 00.382.569zM13.981 7.907a10.206 10.206 0 011.572 1.077.5.5 0 00.646 0 8.032 8.032 0 013.387-1.575.5.5 0 00.382-.568c-.357-2.178-1.936-3.944-2.822-4.2a.5.5 0 00-.556.2c-.019.028-1.406 2.043-2.793 4.369a.5.5 0 00.184.697zM3.185 13.015a.542.542 0 00.315.111H6a.5.5 0 00.5-.5 4.117 4.117 0 01.707-2.277.5.5 0 00-.1-.658A8.578 8.578 0 002 8.126a.5.5 0 00-.493.418 4.688 4.688 0 001.678 4.471zM16.793 10.349a4.117 4.117 0 01.707 2.277.5.5 0 00.5.5h2.5a.542.542 0 00.315-.111 4.688 4.688 0 001.678-4.471.5.5 0 00-.493-.418 8.576 8.576 0 00-5.106 1.565.5.5 0 00-.101.658zM16.5 12.626c0-2.814-4.148-4.4-4.324-4.468a.507.507 0 00-.352 0c-.176.066-4.324 1.654-4.324 4.468a.5.5 0 00.5.5h8a.5.5 0 00.5-.5zM14.32 17.126H21a.5.5 0 00.5-.5v-.5a1.5 1.5 0 00-1.5-1.5h-5.872a.5.5 0 00-.5.564l.192 1.5a.5.5 0 00.5.436zM23 21.876h-.25a.5.5 0 01-.5-.5v-1.25a1.5 1.5 0 00-1.5-1.5h-6.122a.5.5 0 00-.5.564l.3 2.377a.5.5 0 01-.5.559h-3.859a.5.5 0 01-.5-.564l.3-2.374a.5.5 0 00-.5-.562H3.25a1.5 1.5 0 00-1.5 1.5v1.25a.5.5 0 01-.5.5H1a1 1 0 000 2h22a1 1 0 000-2zM4 14.626a1.5 1.5 0 00-1.5 1.5v.5a.5.5 0 00.5.5h6.688a.5.5 0 00.5-.438l.186-1.5a.5.5 0 00-.5-.562z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});