define("ember-svg-jar/inlined/shipment-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-disable</title><path d=\"M8.626 3.626a.25.25 0 00.25.25h5.354a.25.25 0 00.177-.427L11.523.566a1.508 1.508 0 00-1.061-.44H8.876a.25.25 0 00-.25.25zM10.867 11.114a8.448 8.448 0 014.309-2.305.249.249 0 00.2-.244V5.626a.249.249 0 00-.25-.25H.626a.25.25 0 00-.25.25v9a1.5 1.5 0 001.5 1.5h6.355a.249.249 0 00.247-.214 8.45 8.45 0 012.389-4.798zM6.876 3.876a.25.25 0 00.25-.25V.376a.25.25 0 00-.25-.25H5.29a1.507 1.507 0 00-1.06.44L1.346 3.449a.25.25 0 00.177.427z\"/><path d=\"M18.431 10.551a6.765 6.765 0 00-8.131 8.128 6.671 6.671 0 005.017 5.021 7.039 7.039 0 001.546.173 6.755 6.755 0 006.588-8.3 6.67 6.67 0 00-5.02-5.022zm-3.854 10.737a.248.248 0 01-.126-.184.255.255 0 01.071-.211l6.121-6.12a.25.25 0 01.395.055 4.756 4.756 0 01-6.461 6.46zm4.6-8.324a.25.25 0 01.055.395l-6.124 6.121a.251.251 0 01-.4-.055 4.756 4.756 0 016.461-6.461z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});