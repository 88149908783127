define("ember-svg-jar/inlined/smart-watch-square-sound", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-sound</title><path d=\"M16.43 2.749a.248.248 0 00.2-.1.251.251 0 00.035-.225l-.69-2.083A.5.5 0 0015.5 0h-7a.5.5 0 00-.475.342L7.332 2.42a.251.251 0 00.035.225.248.248 0 00.2.1zM7.57 21.249a.251.251 0 00-.238.329l.693 2.079A.5.5 0 008.5 24h7a.5.5 0 00.475-.342l.693-2.079a.251.251 0 00-.238-.329zM17.5 4h-11A2.5 2.5 0 004 6.5v11A2.508 2.508 0 006.5 20h11a2.5 2.5 0 002.5-2.5v-11A2.5 2.5 0 0017.5 4zm-3.714 5.98a.5.5 0 11.777-.628 4.184 4.184 0 010 5.3.5.5 0 11-.777-.628 3.2 3.2 0 000-4.044zM12 8.5a.5.5 0 01.5.5v6a.5.5 0 01-.5.5h-.5a3.5 3.5 0 01-3.083-1.866.25.25 0 00-.217-.134H7a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h1.2a.249.249 0 00.221-.133A3.5 3.5 0 0111.5 8.5zm3.974 7.814a.5.5 0 11-.778-.628 5.83 5.83 0 000-7.372.5.5 0 01.778-.628 6.824 6.824 0 010 8.627z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});