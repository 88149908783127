define("ember-svg-jar/inlined/water-fountain-drop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-fountain-drop</title><path d=\"M18.407 16.868A8.755 8.755 0 0016.75 20.5a2.5 2.5 0 005 0 8.754 8.754 0 00-1.657-3.63 1.036 1.036 0 00-1.686-.002z\"/><path d=\"M21.75 9H9.992a.251.251 0 01-.25-.234 3.9 3.9 0 00-.217-1.057.5.5 0 00-.67-.293L3.714 9.641a2 2 0 01-.8.166.165.165 0 00-.165.165V23a1 1 0 001 1h5a1 1 0 001-1v-6.1a.5.5 0 01.138-.345 13.178 13.178 0 016.042-3.884.25.25 0 01.32.24V13a1 1 0 001 1h4.5a1 1 0 001-1v-3a1 1 0 00-.999-1zM3.316 8.723l12.146-5.256A1.811 1.811 0 0014 .152L1.867 5.4a1.024 1.024 0 00-.53 1.354L1.972 8.2a1.029 1.029 0 00.94.611 1.009 1.009 0 00.404-.088z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});