define("ember-svg-jar/inlined/image-file-png", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-png</title><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.427A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6zM7 13.625h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H7a.875.875 0 000-1.75z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.25a.25.25 0 00-.25-.25zM6.875 16.625h-.5a.25.25 0 00-.25.25V19a.625.625 0 01-1.25 0v-6a.625.625 0 01.625-.625H7a2.128 2.128 0 012.114 2.347 2.213 2.213 0 01-2.239 1.903zM14.125 19a.624.624 0 01-.481.608.644.644 0 01-.144.017.627.627 0 01-.559-.346l-1.552-3.1c-.067-.135-.264-.188-.264 0V19a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279s1.489 2.988 1.554 3.108.262.16.262-.021V13a.625.625 0 011.25 0zm5-4a.625.625 0 01-1.25 0v-.5a.875.875 0 00-1.75 0v3a.876.876 0 001.68.345c.029-.07.06-.22-.1-.22H17.5a.625.625 0 010-1.25h1a.625.625 0 01.625.625v.5a2.125 2.125 0 01-4.25 0v-3a2.125 2.125 0 014.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});