define("ember-svg-jar/inlined/single-man-profile-picture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-profile-picture</title><path d=\"M22.5 1.5A1.5 1.5 0 0021 0H3a1.5 1.5 0 00-1.5 1.5v21A1.5 1.5 0 003 24h18a1.5 1.5 0 001.5-1.5zM19 20a.5.5 0 01-.5.5h-13A.5.5 0 015 20V4a.5.5 0 01.5-.5h13a.5.5 0 01.5.5z\"/><path d=\"M11.863 14.5a5.816 5.816 0 00-5.081 3.21.566.566 0 000 .526.491.491 0 00.427.264H16.5a.491.491 0 00.427-.262.562.562 0 00.006-.527 5.8 5.8 0 00-5.07-3.211zM11.854 13.5A3.75 3.75 0 0015.6 9.75c0-.65-.592-3.75-3.764-3.75A3.811 3.811 0 008.1 9.75a3.749 3.749 0 003.754 3.75zm1.5-4a.251.251 0 01.251.249 1.75 1.75 0 01-3.5 0 1.7 1.7 0 01.138-.668.25.25 0 01.319-.135 7.91 7.91 0 002.791.554z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});