define("ember-svg-jar/inlined/lucide-clapperboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4 11v8a2 2 0 002 2h12a2 2 0 002-2v-8H4z\"/><path d=\"M4 11l-.88-2.87a2 2 0 011.33-2.5l11.48-3.5a2 2 0 012.5 1.32l.87 2.87L4 11.01zM6.6 4.99l3.38 4.2M11.86 3.38l3.38 4.2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});