define("ember-svg-jar/inlined/social-bookmark-instapaper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-bookmark-instapaper</title><path d=\"M15 2.17c.32-.28.78-.47 2.07-.53a.5.5 0 00.48-.5V.5A.5.5 0 0017 0H7a.5.5 0 00-.5.5v.64a.5.5 0 00.48.5c1.29.06 1.75.25 2.07.53s.45.62.45 2.1v15.46c0 1.64-.33 2-.43 2.09a3.67 3.67 0 01-2.07.54.5.5 0 00-.46.5v.65A.5.5 0 007 24h10a.5.5 0 00.5-.5v-.65a.5.5 0 00-.46-.5 3.67 3.67 0 01-2.11-.53c-.1-.08-.43-.46-.43-2.09V4.27c0-1.47.28-1.95.5-2.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});