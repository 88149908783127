define("ember-svg-jar/inlined/smiley-decode-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-decode-alternate</title><path d=\"M10.447 8.9a1 1 0 00.453-1.347C10.142 6.047 8.153 5.5 7 5.5a1 1 0 000 2 2.656 2.656 0 012.105.947 1 1 0 001.342.448zM18 6.5a1 1 0 00-1-1c-1.153 0-3.142.547-3.895 2.053a1 1 0 001.79.894A2.656 2.656 0 0117 7.5a1 1 0 001-1z\"/><circle cx=\"8\" cy=\"11.5\" r=\"1.5\"/><circle cx=\"16\" cy=\"11.5\" r=\"1.5\"/><path d=\"M3.25 19.5a.25.25 0 00.5 0v-1a.25.25 0 00-.5 0zM16.75 18.5a.25.25 0 00-.5 0c0 .276.5.276.5 0z\"/><path d=\"M24 16.5a1.5 1.5 0 00-1.5-1.5h-21A1.5 1.5 0 000 16.5v5A1.5 1.5 0 001.5 23h21a1.5 1.5 0 001.5-1.5zm-1.876 3.584a.75.75 0 11-1.248.832l-.168-.252a.25.25 0 00-.416 0l-.168.252a.75.75 0 01-1.248-.832l.63-.945a.251.251 0 000-.278l-.63-.945a.75.75 0 011.248-.832l.168.252a.25.25 0 00.416 0l.168-.252a.75.75 0 111.248.832l-.63.945a.251.251 0 000 .278zM14.75 18.5a1.75 1.75 0 112.625 1.515.25.25 0 00-.125.216v.269a.75.75 0 01-1.5 0v-.269a.251.251 0 00-.125-.216 1.749 1.749 0 01-.875-1.515zm-.915 2.671a.741.741 0 01-.334.079.752.752 0 01-.672-.415l-.605-1.211a.251.251 0 00-.448 0l-.605 1.211a.75.75 0 01-1.342-.67l1.5-3a.781.781 0 011.342 0l1.5 3a.75.75 0 01-.336 1.006zM8.5 19.75a.75.75 0 010 1.5h-2a.75.75 0 010-1.5.25.25 0 00.25-.25v-2a.75.75 0 011.5 0v2a.25.25 0 00.25.25zM5.25 17.5v3a.75.75 0 01-.75.75h-2a.75.75 0 01-.75-.75v-3a.75.75 0 01.75-.75h2a.75.75 0 01.75.75zM1 14a1 1 0 001-1 10 10 0 0120 0 1 1 0 002 0 12 12 0 00-24 0 1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});