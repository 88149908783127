define("ember-svg-jar/inlined/folder-exchange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-exchange</title><path d=\"M13 10.5a2.983 2.983 0 011.573.457c.094.059.339.1.377-.084A1.435 1.435 0 0015 10.5v-7A1.5 1.5 0 0013.5 2h-7a.5.5 0 01-.4-.2L5.2.6A1.5 1.5 0 004 0H1.5A1.5 1.5 0 000 1.5v9A1.5 1.5 0 001.5 12h6.284a.242.242 0 00.2-.114A2.985 2.985 0 0110.5 10.5zM22.5 14h-7a.5.5 0 01-.4-.2l-.9-1.2A1.508 1.508 0 0013 12h-2.5A1.5 1.5 0 009 13.5v9a1.5 1.5 0 001.5 1.5h12a1.5 1.5 0 001.5-1.5v-7a1.5 1.5 0 00-1.5-1.5zM5.354 17.146a.5.5 0 00-.854.354v1a.25.25 0 01-.25.25H2.5a.751.751 0 01-.75-.75v-1.5a.75.75 0 00-1.5 0V18a2.253 2.253 0 002.25 2.25h1.75a.25.25 0 01.25.25v1a.5.5 0 00.309.462.5.5 0 00.545-.108l2-2a.5.5 0 000-.708zM18.646 6.854A.5.5 0 0019.5 6.5v-1a.25.25 0 01.25-.25h1.75a.751.751 0 01.75.75v1.5a.75.75 0 001.5 0V6a2.253 2.253 0 00-2.25-2.25h-1.75a.25.25 0 01-.25-.25v-1a.5.5 0 00-.854-.354l-2 2a.5.5 0 000 .708z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});