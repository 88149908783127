define("ember-svg-jar/inlined/disability-q", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-q</title><path d=\"M11.75 3.25a8.137 8.137 0 00-8 8.25 8.137 8.137 0 008 8.25 7.751 7.751 0 003.784-.989.25.25 0 01.28.025l1.986 1.625a1.5 1.5 0 001.9-2.322l-1.542-1.262a.249.249 0 01-.043-.341A8.367 8.367 0 0019.75 11.5a8.137 8.137 0 00-8-8.25zm4.05 11.294a.251.251 0 01-.365.052L14.2 13.589a1.5 1.5 0 10-1.9 2.322l.384.314a.251.251 0 01-.109.439 4.236 4.236 0 01-.825.086 5.135 5.135 0 01-5-5.25 5.135 5.135 0 015-5.25 5.135 5.135 0 015 5.25 5.35 5.35 0 01-.95 3.044z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});