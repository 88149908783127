define("ember-svg-jar/inlined/stamps-image-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>stamps-image-alternate</title><path d=\"M18 5H6a1 1 0 00-1 1v12a1 1 0 001 1h12a1 1 0 001-1V6a1 1 0 00-1-1zM7 9a2 2 0 112 2 2 2 0 01-2-2zm9.824 7.5H8.881a.5.5 0 01-.429-.756l1.183-1.98a.5.5 0 01.752-.126l1.167.988a.5.5 0 00.75-.122l2.474-4.052a.5.5 0 01.9.114l1.622 5.288a.5.5 0 01-.476.646z\"/><path d=\"M20.8 0a3.18 3.18 0 00-2.234.909A3.177 3.177 0 0014.2.941a3.177 3.177 0 00-4.4 0A3.177 3.177 0 005.434.909 3.2 3.2 0 000 3.2v.028a3.16 3.16 0 00.86 2.2A3.187 3.187 0 00.876 9.8a3.2 3.2 0 000 4.4 3.187 3.187 0 00-.016 4.372A3.161 3.161 0 000 20.744v.056a3.2 3.2 0 005.434 2.291 3.177 3.177 0 004.366-.032 3.177 3.177 0 004.4 0 3.177 3.177 0 004.366.032A3.2 3.2 0 0024 20.8v-.056a3.161 3.161 0 00-.86-2.172 3.187 3.187 0 00-.016-4.372 3.2 3.2 0 000-4.4 3.187 3.187 0 00.016-4.372A3.161 3.161 0 0024 3.256V3.2A3.2 3.2 0 0020.8 0zm.276 10.837a1.194 1.194 0 010 2.326 1.015 1.015 0 00-.768 1.1 1 1 0 00.768.972 1.185 1.185 0 010 2.312 1.065 1.065 0 000 2.046 1.216 1.216 0 11-1.438 1.482 1.019 1.019 0 00-1.12-.769 1 1 0 00-.967.743 1.188 1.188 0 01-2.3 0 1.025 1.025 0 00-1.131-.743 1 1 0 00-.966.742 1.189 1.189 0 01-2.3 0 1.023 1.023 0 00-1.13-.742 1 1 0 00-.967.743 1.188 1.188 0 01-2.3 0 1.02 1.02 0 00-1.114-.743 1 1 0 00-.973.769A1.2 1.2 0 112.924 19.6a1.065 1.065 0 000-2.046 1.185 1.185 0 010-2.312 1.013 1.013 0 00.768-1.1 1 1 0 00-.768-.973 1.194 1.194 0 010-2.326 1.015 1.015 0 00.768-1.1 1 1 0 00-.768-.972 1.185 1.185 0 010-2.312 1.009 1.009 0 00.768-1.074 1 1 0 00-.768-.972A1.189 1.189 0 012 3.229a1.2 1.2 0 012.359-.306 1.019 1.019 0 001.12.769 1 1 0 00.967-.743 1.188 1.188 0 012.3 0 1.025 1.025 0 001.131.743 1 1 0 00.966-.742 1.189 1.189 0 012.3 0 1.023 1.023 0 001.13.742 1 1 0 00.967-.743 1.188 1.188 0 012.3 0 1.02 1.02 0 001.114.743 1 1 0 00.973-.769A1.2 1.2 0 0122 3.229a1.189 1.189 0 01-.921 1.176 1.009 1.009 0 00-.768 1.074 1 1 0 00.768.972 1.185 1.185 0 010 2.312 1.013 1.013 0 00-.768 1.1 1 1 0 00.765.974z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});