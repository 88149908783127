define("ember-svg-jar/inlined/smart-watch-circle-mail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-mail</title><path d=\"M12 3.5a8.5 8.5 0 108.5 8.5A8.5 8.5 0 0012 3.5zm4.083 10.571a.927.927 0 01-.923.929H9.006a.927.927 0 01-.923-.929v-2.935a.156.156 0 01.08-.136.153.153 0 01.156 0l3.031 1.907a1.386 1.386 0 001.467 0L15.848 11A.151.151 0 0116 11a.154.154 0 01.079.136zm-.021-4.159l-3.734 2.215a.486.486 0 01-.489 0L8.105 9.913a.042.042 0 01-.022-.037A.9.9 0 019.006 9h6.154a.9.9 0 01.923.876.042.042 0 01-.021.036zM7.379 3.051a.125.125 0 00.135.014 9.98 9.98 0 018.972 0 .125.125 0 00.177-.144l-.68-2.55A.5.5 0 0015.5 0h-7a.5.5 0 00-.483.371l-.68 2.551a.125.125 0 00.042.129z\"/><path d=\"M16.621 20.949a.125.125 0 00-.135-.015 9.972 9.972 0 01-8.972 0 .125.125 0 00-.177.144l.68 2.551A.5.5 0 008.5 24h7a.5.5 0 00.483-.371l.68-2.551a.125.125 0 00-.042-.129z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});