define("ember-svg-jar/inlined/shield-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shield-monitor</title><path d=\"M16.5 11.256a.5.5 0 00.5-.5v-5.25a.251.251 0 00-.25-.25h-9.5a.251.251 0 00-.25.25v5.25a.5.5 0 00.5.5z\"/><path d=\"M24 1.954A1.96 1.96 0 0022.043.006H1.96A1.961 1.961 0 00.012 1.964L0 9.306a15.144 15.144 0 0011.861 14.668.976.976 0 00.4 0A15.144 15.144 0 0023.988 9.2zm-5.5 10.052a1.752 1.752 0 01-1.75 1.75h-3.5a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h1.375a.75.75 0 010 1.5h-5.25a.75.75 0 010-1.5h1.375a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5h-3.5a1.752 1.752 0 01-1.75-1.75v-6.5a1.753 1.753 0 011.75-1.75h9.5a1.753 1.753 0 011.75 1.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});