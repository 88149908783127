define("ember-svg-jar/inlined/primitive-symbols-bull", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>primitive-symbols-bull</title><path d=\"M23.842 16.948l-1.493-3.063a.251.251 0 01.066-.306A3.7 3.7 0 0020.081 7h-.517a4.511 4.511 0 00-1.68.324A7.353 7.353 0 0114.842 8a7.04 7.04 0 01-1.928-.269l-1.949-.557A4.48 4.48 0 009.731 7a4.179 4.179 0 00-1.341.21.25.25 0 01-.328-.233A3.757 3.757 0 005.241 3.4l-3.46-.865A1 1 0 101.3 4.47l3.46.865a1.73 1.73 0 011.3 1.4.249.249 0 01-.06.193.252.252 0 01-.172.1 3.1 3.1 0 00-1.526.606.25.25 0 01-.2.045 2.922 2.922 0 01-2.176-1.8 1 1 0 00-1.857.742A4.849 4.849 0 002.876 9.4a.249.249 0 01.148.313l-.418 1.256a1.705 1.705 0 002.681 1.873h.006l.945-.712a.25.25 0 01.229-.037.247.247 0 01.161.168l.382 1.335c.031.111.074.217.115.324a.035.035 0 010 .033l-2.664 4a1 1 0 001.664 1.109l2.056-3.084a.25.25 0 01.369-.053c.055.046.11.09.166.133a.251.251 0 01.1.23l-.512 4.1a1 1 0 00.868 1.116 1.063 1.063 0 00.125.008 1 1 0 00.991-.876l.431-3.446a.251.251 0 01.281-.217 3.978 3.978 0 00.536.039h3.71a5.974 5.974 0 002.776-.689.249.249 0 01.358.16l.069.27a.244.244 0 01-.028.19l-1.232 2.055a1 1 0 001.711 1.017l1.386-2.315a1.512 1.512 0 00.171-1.143l-.333-1.311a.25.25 0 01.111-.275l.087-.053a.251.251 0 01.2-.028.255.255 0 01.155.129l1.3 2.67A.256.256 0 0122 17.8v2.7a1 1 0 002 0v-2.882a1.522 1.522 0 00-.158-.67z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});