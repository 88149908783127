define("ember-svg-jar/inlined/loading-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>loading-bar</title><path d=\"M21.061 2.2H2.939A2.942 2.942 0 000 5.141v13.714a2.942 2.942 0 002.939 2.939h18.122A2.942 2.942 0 0024 18.855V5.141A2.942 2.942 0 0021.061 2.2zM11.02 5.141a.735.735 0 11-.734-.735.734.734 0 01.734.735zm-2.938 0a.735.735 0 11-.735-.735.735.735 0 01.735.735zm-2.939 0a.735.735 0 11-.735-.735.735.735 0 01.735.735zm15.918 14.694H2.939a.98.98 0 01-.98-.98V8.835a1 1 0 011-1h18.082a1 1 0 011 1v10.02a.98.98 0 01-.98.98z\"/><path d=\"M17.143 11.018H6.857a2.694 2.694 0 000 5.388h10.286a2.694 2.694 0 100-5.388zm0 3.919H11.27a.25.25 0 01-.25-.25v-1.949a.25.25 0 01.25-.25h5.873a1.225 1.225 0 010 2.449z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});