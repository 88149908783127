define("ember-svg-jar/inlined/real-estate-sign-board-sold-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-sign-board-sold-1</title><path d=\"M17.125 11.786v-1.072a1.514 1.514 0 00-.47-1.1.251.251 0 00-.423.181v2.9a.25.25 0 00.423.181 1.514 1.514 0 00.47-1.09zM8.107 9.2a.446.446 0 00-.446.446v3.214a.447.447 0 00.893 0V9.643a.447.447 0 00-.447-.443z\"/><path d=\"M24 2a2 2 0 00-2-2H1.5a1 1 0 000 2H3a.5.5 0 01.5.5v1.75a.5.5 0 01-.5.5H1.5A1.5 1.5 0 000 6.25v10a1.5 1.5 0 001.5 1.5h17.75a1.5 1.5 0 001.5-1.5v-10a1.5 1.5 0 00-1.5-1.5H17.5a.5.5 0 01-.5-.5V2.5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5V23a1 1 0 002 0zm-18.5.5A.5.5 0 016 2h8.5a.5.5 0 01.5.5v1.75a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5zm-1.3 8.25a2.586 2.586 0 011.321 2.107 1.7 1.7 0 01-1.7 1.7H2.75a.625.625 0 010-1.25h1.072a.446.446 0 00.446-.446c0-.47-.281-.7-.821-1.108a2.584 2.584 0 01-1.322-2.11 1.7 1.7 0 011.7-1.7h1.068a.625.625 0 010 1.25H3.822a.447.447 0 00-.447.446c0 .473.282.706.825 1.111zm5.6 2.107a1.7 1.7 0 01-3.393 0V9.643a1.7 1.7 0 013.393 0zm3.66 1.7h-1.067a1.7 1.7 0 01-1.7-1.7V8.572a.625.625 0 011.25 0v4.285a.447.447 0 00.447.446h1.071a.625.625 0 110 1.25zm4.911-3.839v1.072a2.771 2.771 0 01-2.768 2.767.625.625 0 01-.625-.625v-5.36a.625.625 0 01.625-.625 2.771 2.771 0 012.772 2.767z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});