define("ember-svg-jar/inlined/marine-mammal-seal-ball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>marine-mammal-seal-ball</title><path d=\"M21.063 19.888a3.577 3.577 0 00-2.236.233 7.91 7.91 0 00-2.536-3.621c-2.14-1.833-6.375-1.754-7.858-1.658A10.553 10.553 0 007.381 10.6 4.73 4.73 0 006.2 9.4a4.751 4.751 0 10-3.268-.16 8.824 8.824 0 00-1.967 6.138 10.831 10.831 0 00.626 4.411.252.252 0 00.326.131 6.066 6.066 0 011.335-.381.751.751 0 01.248 1.481 4.376 4.376 0 00-3.3 2.489.249.249 0 00.224.36h4.191a2.551 2.551 0 002.374-1.916.75.75 0 011.454.368 4.4 4.4 0 01-.5 1.179.251.251 0 00.214.38h15.312a.5.5 0 00.5-.5 3.567 3.567 0 00-2.906-3.492zm-15.848-8.76a1 1 0 11-1 1 1 1 0 011-1zm2.32-6.259a2.756 2.756 0 01-2.028 2.654 5.921 5.921 0 00.528-2.654 5.919 5.919 0 00-.528-2.653 2.755 2.755 0 012.028 2.653zm-5.5 0a2.755 2.755 0 012.028-2.653 5.905 5.905 0 00-.528 2.653 5.907 5.907 0 00.528 2.654 2.756 2.756 0 01-2.028-2.654z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});