define("ember-svg-jar/inlined/single-man-flag-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-flag-2</title><path d=\"M21.784 6.91a.251.251 0 010-.32l2.1-2.52a.5.5 0 00-.384-.82h-8a.25.25 0 01-.25-.25V1.75a1.25 1.25 0 00-2.5 0v21a1.25 1.25 0 002.5 0V10.5a.25.25 0 01.25-.25h8a.5.5 0 00.384-.82zM5.5 8.987a5.506 5.506 0 00-5.5 5.5v3a.5.5 0 00.5.5h1.81a.25.25 0 01.249.23L3 23.529a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.312a.25.25 0 01.249-.23H10.5a.5.5 0 00.5-.5v-3a5.506 5.506 0 00-5.5-5.5zm.75 5.949a.75.75 0 01-1.5 0v-3.949a.75.75 0 011.5 0zM5.5 8.137a4.069 4.069 0 10-4.065-4.068A4.073 4.073 0 005.5 8.137zM3.571 3.35a.25.25 0 01.362-.124 5.052 5.052 0 002.609.725 4.962 4.962 0 00.736-.051.249.249 0 01.287.247A2.028 2.028 0 015.5 6.137a2.07 2.07 0 01-2.065-2.068 2 2 0 01.136-.719z\"/><path d=\"M21.784 6.91a.251.251 0 010-.32l2.1-2.52a.5.5 0 00-.384-.82h-8a.25.25 0 01-.25-.25V1.75a1.25 1.25 0 00-2.5 0v21a1.25 1.25 0 002.5 0V10.5a.25.25 0 01.25-.25h8a.5.5 0 00.384-.82zM5.5 8.987a5.506 5.506 0 00-5.5 5.5v3a.5.5 0 00.5.5h1.81a.25.25 0 01.249.23L3 23.529a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.312a.25.25 0 01.249-.23H10.5a.5.5 0 00.5-.5v-3a5.506 5.506 0 00-5.5-5.5zm.75 5.949a.75.75 0 01-1.5 0v-3.949a.75.75 0 011.5 0zM5.5 8.137a4.069 4.069 0 10-4.065-4.068A4.073 4.073 0 005.5 8.137zM3.571 3.35a.25.25 0 01.362-.124 5.052 5.052 0 002.609.725 4.962 4.962 0 00.736-.051.249.249 0 01.287.247A2.028 2.028 0 015.5 6.137a2.07 2.07 0 01-2.065-2.068 2 2 0 01.136-.719z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});