define("ember-svg-jar/inlined/monitor-lock.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-lock</title><path d=\"M14.5 11.86a.25.25 0 00-.225-.359H2.5A.5.5 0 012 11V2.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v7.048a.249.249 0 00.383.211 4.893 4.893 0 011.427-.614A.25.25 0 0018 8.9V2a2 2 0 00-2-2H2a2 2 0 00-2 2v10.5a2 2 0 002 2h5.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5H5a1 1 0 000 2h7.25a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25H10.5a.5.5 0 01-.5-.5V15a.5.5 0 01.5-.5h2.6a.247.247 0 00.156-.055 2 2 0 01.678-.357A.092.092 0 0014 14a4.87 4.87 0 01.5-2.14z\"/><path d=\"M22.714 15.516a.25.25 0 01-.214-.248v-1.017a3.5 3.5 0 10-7 0v1.017a.25.25 0 01-.214.248A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.286-1.484zM19 21.251a1 1 0 111-1 1 1 0 01-1 1zm1.5-6a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-1a1.5 1.5 0 113 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});