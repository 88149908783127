define("ember-svg-jar/inlined/mood-happy-smartphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mood-happy-smartphone</title><path d=\"M8.929 4.079a.251.251 0 00-.237-.329H3.5a3 3 0 00-3 2.95v16.05a1 1 0 002 0V8.25a.5.5 0 01.5-.5h5.282a.248.248 0 00.184-.081.251.251 0 00.065-.19A8.718 8.718 0 018.5 6.75a8.487 8.487 0 01.429-2.671zM15 22.75a1 1 0 002 0V15.5a.249.249 0 00-.243-.249 8.518 8.518 0 01-1.457-.168.248.248 0 00-.208.052.251.251 0 00-.092.194zM16.934 13.383a6.567 6.567 0 000-13.133A6.432 6.432 0 0010.5 6.817a6.67 6.67 0 00.659 3.107.251.251 0 010 .222l-.87 1.826-.744 1.563a.5.5 0 00.455.715.51.51 0 00.215-.048l1.563-.745 1.822-.868a.25.25 0 01.226.006 6.534 6.534 0 003.108.788zM19.25 4.75a.75.75 0 11-.75.75.75.75 0 01.75-.75zm-.413 3a.156.156 0 01.156.169 2 2 0 01-3.973.117.252.252 0 01.248-.286zm-4.087-3a.75.75 0 11-.75.75.75.75 0 01.75-.75z\"/><circle cx=\"8.67\" cy=\"17.25\" r=\"2\"/><path d=\"M8.67 20.25a3.594 3.594 0 00-3.294 2.317.5.5 0 00.47.683h5.647a.5.5 0 00.471-.683A3.594 3.594 0 008.67 20.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});