define("ember-svg-jar/inlined/task-checklist-write", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-checklist-write</title><path d=\"M5 10.249h6.5a.75.75 0 000-1.5H5a.75.75 0 000 1.5zM5 13.749h3a.75.75 0 000-1.5H5a.75.75 0 000 1.5zM5 17.25h2.75a.75.75 0 000-1.5H5a.75.75 0 000 1.5zM5.25 6.249h8.5A.25.25 0 0014 6V3a.251.251 0 00-.25-.25H12V2.5A2.509 2.509 0 008.821.09 2.558 2.558 0 007 2.587v.162H5.25A.251.251 0 005 3v3a.25.25 0 00.25.249zm5-4A.75.75 0 119.5 1.5a.75.75 0 01.75.749z\"/><path d=\"M9.052 23l.14-.7a.249.249 0 00-.051-.207.252.252 0 00-.194-.093H2.5a.5.5 0 01-.5-.5V5.249a.5.5 0 01.5-.5h.75A.25.25 0 003.5 4.5V3a.251.251 0 00-.25-.25H2a2 2 0 00-2 2V22a2 2 0 002 2h6.776a.249.249 0 00.249-.277A2.333 2.333 0 019.052 23zM18.884 9.747A.252.252 0 0019 9.535V4.749a2 2 0 00-2-2h-1.25A.251.251 0 0015.5 3v1.5a.25.25 0 00.25.25h.75a.5.5 0 01.5.5v5.612a.25.25 0 00.427.177l.688-.688a4.383 4.383 0 01.769-.604zM12.739 20.044a.5.5 0 00-.834.217l-.886 3.1a.5.5 0 00.618.618l3.1-.886a.5.5 0 00.217-.835zM18.771 13.228a.5.5 0 00-.707 0l-5.01 5.009a.5.5 0 000 .708l3 3a.5.5 0 00.707 0l5.01-5.01a.5.5 0 000-.707zM19.478 11.814a.5.5 0 000 .707l3 3a.454.454 0 00.327.125.617.617 0 00.426-.172 2.621 2.621 0 000-3.707 2.654 2.654 0 00-3.753.047z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});