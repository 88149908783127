define("ember-svg-jar/inlined/lock-unlock-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lock-unlock-2</title><path d=\"M18.75 11.725V6.75a6.75 6.75 0 00-13.5 0 1.25 1.25 0 002.5 0 4.25 4.25 0 018.5 0v1.631a.5.5 0 01-.722.448A7.918 7.918 0 0012 8a8.027 8.027 0 106.75 3.725zm-5.75 5V19a1 1 0 01-2 0v-2.277a2 2 0 112 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});