define("ember-svg-jar/inlined/famous-people-man-steve-jobs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-people-man-steve-jobs</title><path d=\"M23.447 23.278a2.46 2.46 0 00-.914-1.486 14.937 14.937 0 00-6.847-2.649.218.218 0 01-.186-.216V17.56a.251.251 0 00-.355-.227l-.841.388a5.483 5.483 0 01-4.61 0l-.841-.388a.249.249 0 00-.354.227v1.367a.218.218 0 01-.185.216 14.948 14.948 0 00-6.848 2.649 2.466 2.466 0 00-.914 1.486.5.5 0 00.491.6h21.913a.5.5 0 00.491-.6zM8.033 15.741l2.118.977a4.408 4.408 0 003.7 0l2.118-.978c2.5-1.153 2.783-3.623 2.783-7.366a.25.25 0 01.25-.25.75.75 0 000-1.5h-.026a.249.249 0 01-.249-.232 6.742 6.742 0 00-13.45 0 .249.249 0 01-.249.232H5a.75.75 0 000 1.5.25.25 0 01.25.25c0 3.626.273 6.208 2.783 7.367zm3.426-2.459a.977.977 0 001.082 0 .75.75 0 11.918 1.186 2.417 2.417 0 01-2.918 0 .75.75 0 11.918-1.186zM9.907 2.074a.251.251 0 01.307.088A2.315 2.315 0 0012 3.375a2.317 2.317 0 001.787-1.213.25.25 0 01.306-.088 5.24 5.24 0 013.126 4.448.1.1 0 01-.1.1 2.483 2.483 0 00-4.689-.159.25.25 0 01-.233.159h-.4a.251.251 0 01-.233-.159 2.481 2.481 0 00-4.667.1.094.094 0 01-.089.063.037.037 0 01-.024-.01.038.038 0 01-.009-.025 5.241 5.241 0 013.132-4.517zm5.843 5.3a1 1 0 11-1-1 1 1 0 011 1.001zm-5.5 0a1 1 0 11-1-1 1 1 0 011 1.001zm-3.372.75a2.482 2.482 0 004.688.159.251.251 0 01.233-.159h.4a.25.25 0 01.233.159 2.483 2.483 0 004.689-.159.127.127 0 01.127.128v.27a.25.25 0 01-.055.157l-2.253 2.8-.005.006a1.077 1.077 0 01-.961.425.246.246 0 01-.148-.067 2.385 2.385 0 00-3.656 0 .253.253 0 01-.148.067 1.1 1.1 0 01-.976-.446l-.005-.006-2.235-2.779a.246.246 0 01-.056-.156v-.27a.128.128 0 01.128-.128z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});