define("ember-svg-jar/inlined/layers-grid-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layers-grid-disable</title><path d=\"M8 19a.25.25 0 01-.25-.25V15a.25.25 0 01.25-.25h1.475a.252.252 0 00.236-.166 7.815 7.815 0 01.419-.97.25.25 0 00-.222-.364H8a.25.25 0 01-.25-.25V8A.25.25 0 018 7.75h4a.25.25 0 01.25.25v2.51a.25.25 0 00.393.205 8.124 8.124 0 01.971-.582.252.252 0 00.136-.223V8a.25.25 0 01.25-.25h3.75A.25.25 0 0118 8v1.064a.25.25 0 00.22.248 7.878 7.878 0 011.454.321A.25.25 0 0020 9.4V3a3 3 0 00-3-3H3a3 3 0 00-3 3v15a3 3 0 003 3h6.781a.251.251 0 00.211-.116.247.247 0 00.015-.24 7.936 7.936 0 01-.514-1.454.25.25 0 00-.243-.19zm-1.75-6a.25.25 0 01-.25.25H2.25A.25.25 0 012 13V8a.25.25 0 01.25-.25H6a.25.25 0 01.25.25zM8 6.25A.25.25 0 017.75 6V2.25A.25.25 0 018 2h4a.25.25 0 01.25.25V6a.25.25 0 01-.25.25zM18 3v3a.25.25 0 01-.25.25H14a.25.25 0 01-.25-.25V2.25A.25.25 0 0114 2h3a1 1 0 011 1zM3 2h3a.25.25 0 01.25.25V6a.25.25 0 01-.25.25H2.25A.25.25 0 012 6V3a1 1 0 011-1zM2 18v-3a.25.25 0 01.25-.25H6a.25.25 0 01.25.25v3.75A.25.25 0 016 19H3a1 1 0 01-1-1z\"/><path d=\"M17.25 10.5A6.75 6.75 0 1024 17.25a6.758 6.758 0 00-6.75-6.75zm2.288 2.6a.251.251 0 01.055.4l-6.1 6.1a.249.249 0 01-.212.071.253.253 0 01-.184-.126 4.7 4.7 0 01-.6-2.288 4.756 4.756 0 014.75-4.75 4.7 4.7 0 012.291.593zM17.25 22a4.7 4.7 0 01-2.288-.6.252.252 0 01-.126-.184.249.249 0 01.071-.212l6.1-6.1a.251.251 0 01.4.055A4.7 4.7 0 0122 17.25 4.756 4.756 0 0117.25 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});