define("ember-svg-jar/inlined/monitor-heart-beat-touch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-heart-beat-touch</title><path d=\"M18.157 18.75l-5.079-1.821a.5.5 0 01-.331-.471V15.5a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v4.73a.25.25 0 01-.452.147L8.027 18.63a1.048 1.048 0 00-.8-.37l-1 .02a.982.982 0 00-.84.48.993.993 0 00-.02.96l2.217 4.021a.5.5 0 00.438.259h9.356a.5.5 0 00.487-.389l.882-3.891a.943.943 0 00-.59-.97zM5.752 13a.99.99 0 001 1h9a.989.989 0 001-1V5.5a5.5 5.5 0 10-11 0zm9.5-1.5a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-1.38a.5.5 0 01.5-.5h1.191a1.241 1.241 0 001.118-.691l.235-.468a.248.248 0 01.245-.137.251.251 0 01.218.177l.49 1.637a1.245 1.245 0 00.978.871 1.3 1.3 0 00.223.019 1.241 1.241 0 001-.5l.53-.707a.5.5 0 01.4-.2h.375a.5.5 0 01.5.5zm-4-10a1 1 0 11-1 1 1 1 0 011-1zm-4 4a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v2.121a.5.5 0 01-.5.5H14a1.263 1.263 0 00-1 .5l-.161.215a.25.25 0 01-.439-.078l-.569-1.895a1.25 1.25 0 00-2.315-.2l-.589 1.181a.5.5 0 01-.447.276h-.728a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});