define("ember-svg-jar/inlined/outdoors-bird-house-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-bird-house-1</title><path d=\"M22.472 15.557a3.5 3.5 0 00-6.944 0 2 2 0 00-1.521 2.114 2.087 2.087 0 002.118 1.829h2.125a.25.25 0 01.25.25V20a1 1 0 01-1 1h-3.75a.25.25 0 01-.25-.25v-10.5a.25.25 0 01.25-.25h1.75A1.5 1.5 0 0017 8.5V7c0-.266.293 0 .293 0a1 1 0 001.414-1.414L13.561.439a1.5 1.5 0 00-2.122 0L6.293 5.586A1 1 0 007.707 7S8 6.7 8 6.987V8.5A1.5 1.5 0 009.5 10h1.75a.25.25 0 01.25.25v8.5a.25.25 0 01-.25.25H7.5a1 1 0 01-1-1v-.25a.25.25 0 01.25-.25h2.125a2.087 2.087 0 002.118-1.829 2 2 0 00-1.521-2.114 3.5 3.5 0 00-6.937-.057h-.422a2.08 2.08 0 00-2.085 1.662A2 2 0 002 17.5h2.25a.25.25 0 01.25.25V18a3 3 0 003 3h3.75a.25.25 0 01.25.25V23a1 1 0 001.977.215.35.35 0 01.252-.215H17.5a3 3 0 003-3v-.25a.25.25 0 01.25-.25h1.125a2.087 2.087 0 002.118-1.829 2 2 0 00-1.521-2.114zM11.5 8.5A.5.5 0 0111 8V6.5a1.5 1.5 0 013 0V8a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});