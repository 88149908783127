define("ember-svg-jar/inlined/smart-watch-circle-microphone-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-microphone-alternate</title><path d=\"M21.5 12A9.5 9.5 0 0017 3.928a.245.245 0 01-.109-.148L15.983.374A.5.5 0 0015.5 0h-7a.5.5 0 00-.484.371L7.108 3.78A.245.245 0 017 3.928a9.5 9.5 0 000 16.149.25.25 0 01.109.148l.908 3.407A.5.5 0 008.5 24h7a.5.5 0 00.482-.368l.935-3.428a.247.247 0 01.109-.146A9.5 9.5 0 0021.5 12zM12 19.5a7.5 7.5 0 117.5-7.5 7.509 7.509 0 01-7.5 7.5z\"/><path d=\"M12 7a2 2 0 00-2 2v2a2 2 0 004 0V9a2 2 0 00-2-2z\"/><path d=\"M15.5 10.753a.75.75 0 00-.75.75 2.75 2.75 0 01-5.5 0 .75.75 0 00-1.5 0 4.258 4.258 0 003.305 4.144.25.25 0 01.2.243V17a.75.75 0 001.5 0v-1.11a.249.249 0 01.194-.243A4.258 4.258 0 0016.25 11.5a.75.75 0 00-.75-.747z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});