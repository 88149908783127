define("ember-svg-jar/inlined/dog-allowed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dog-allowed</title><path d=\"M17.558 11.428c-.2-1.833-1.522-5.857-3.772-7.233a.977.977 0 00-1.417.476l-1.328 3.39a.486.486 0 01-.35.3l-3.355.732A3.786 3.786 0 004.2 12.827c0 2.931 2.065 3.335 4.249 3.763.353.069.719.14 1.148.234l1.036.116.336 1.128a.488.488 0 00.687.3l6.9-3.47a.488.488 0 00.229-.629z\"/><path d=\"M12 0a12 12 0 1012 12A12 12 0 0012 0zm8.293 17.091a.487.487 0 01-.423-.294l-.124-.287a.487.487 0 00-.667-.243l-7.159 3.6a.488.488 0 00-.249.575l.271.91a.488.488 0 01-.5.626 10.005 10.005 0 119.3-5.137.485.485 0 01-.449.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});