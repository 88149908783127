define("ember-svg-jar/inlined/people-woman-glasses-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-glasses-1</title><circle cx=\"13.979\" cy=\"8.66\" r=\".99\"/><circle cx=\"10.021\" cy=\"8.66\" r=\".99\"/><path d=\"M10.4 11.247a.632.632 0 00.313.711 2.263 2.263 0 00.43.178 2.966 2.966 0 00.86.126 3 3 0 00.858-.124 2.3 2.3 0 00.436-.181.633.633 0 00-.3-1.188H11.01a.632.632 0 00-.61.478z\"/><path d=\"M.619 23.505a.494.494 0 00.494.5h21.774a.494.494 0 00.494-.5v-2.529a2.439 2.439 0 00-.956-1.956 11.662 11.662 0 00-2.2-1.294.247.247 0 01-.018-.44 13.73 13.73 0 001.564-1.007.495.495 0 00.031-.752c-4.149-3.861-.97-7.562-3.446-11.671a.249.249 0 01-.01-.237 3.149 3.149 0 00.333-1.392C18.68 0 16.508 0 15.464 0a5.081 5.081 0 00-2.094.336.249.249 0 01-.145.02 6.938 6.938 0 00-2.45 0 .249.249 0 01-.145-.02A5.081 5.081 0 008.536 0C7.492 0 5.32 0 5.32 2.227a3.14 3.14 0 00.34 1.406.249.249 0 01-.009.237C3.2 7.969 6.315 11.7 2.2 15.527a.492.492 0 00-.157.382.5.5 0 00.189.37 13.592 13.592 0 001.564 1.006.248.248 0 01-.018.441 11.6 11.6 0 00-2.2 1.294 2.439 2.439 0 00-.956 1.956zM6.8 2.227c0-.447 0-.742 1.732-.742s1.732.295 1.732.742a1.732 1.732 0 01-3.464 0zm10.392 0a1.732 1.732 0 01-3.464 0c0-.447 0-.742 1.732-.742s1.74.295 1.74.742zm-5.385 3.385A.25.25 0 0112 5.519a.247.247 0 01.193.093 5.487 5.487 0 004.024 2.053.248.248 0 01.237.247v1.737a4.454 4.454 0 01-8.908 0V7.912a.248.248 0 01.237-.247 5.491 5.491 0 004.024-2.053zm4.048 11.275a.249.249 0 01.171.114.245.245 0 01.026.2c-1.018 3.346-2.628 3.616-3.934 4.36a.249.249 0 01-.227.01A6 6 0 017.937 17.2a.244.244 0 01.027-.2.248.248 0 01.17-.113 24.9 24.9 0 017.721 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});