define("ember-svg-jar/inlined/history-indian-eagle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-indian-eagle</title><path d=\"M17.272 21.25a.5.5 0 00-.17-.376l-2-1.75a.5.5 0 00-.33-.124h-5.5a.5.5 0 00-.329.124l-2 1.75a.5.5 0 00-.171.376v2a.75.75 0 101.5 0V23a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v.25a.75.75 0 001.5 0V23a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v.25a.75.75 0 001.5 0V23a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v.25a.75.75 0 001.5 0z\"/><rect x=\"10.034\" y=\"7.5\" width=\"3.977\" height=\"10\" rx=\".5\" ry=\".5\"/><path d=\"M.043 10.311a.5.5 0 00.678.414L3.1 9.8a.75.75 0 01.543 1.4L1 12.227a.47.47 0 00-.255.637 7.72 7.72 0 001.965 2.571.5.5 0 00.742-.1l1.437-2.145a.75.75 0 111.245.836l-1.377 2.051a.5.5 0 00.225.742 9.318 9.318 0 003.029.681.5.5 0 00.5-.5V8a.5.5 0 00-.5-.5H1.5A1.5 1.5 0 000 9zM5.123 4.2a.5.5 0 00.4.8h3a.5.5 0 01.5.5.5.5 0 00.5.5h5a.5.5 0 00.5-.5V3a.5.5 0 00-.146-.354l-2.5-2.5A.5.5 0 0012.022 0h-2.5a.5.5 0 00-.5.5v1a.5.5 0 01-.5.5h-.5a2.517 2.517 0 00-2 1zm5.65-2.7a.75.75 0 11-.75.75.75.75 0 01.75-.75zM23.957 10.311a.5.5 0 01-.678.414L20.905 9.8a.75.75 0 00-.543 1.4L23 12.227a.47.47 0 01.255.637 7.72 7.72 0 01-1.965 2.571.5.5 0 01-.742-.1l-1.437-2.145a.75.75 0 10-1.245.836l1.377 2.051a.5.5 0 01-.225.742 9.318 9.318 0 01-3.029.681.5.5 0 01-.5-.5V8a.5.5 0 01.5-.5H22.5A1.5 1.5 0 0124 9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});