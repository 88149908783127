define("ember-svg-jar/inlined/monetization-approve", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monetization-approve</title><path d=\"M7.6 22.235a1 1 0 00.615 1.274 11.432 11.432 0 002.128.518 1.016 1.016 0 00.144.01 1 1 0 00.141-1.989 9.4 9.4 0 01-1.758-.428 1 1 0 00-1.27.615zM4.76 21.587a1 1 0 001.258-1.555 9.586 9.586 0 01-1.526-1.554A1 1 0 102.913 19.7a11.578 11.578 0 001.847 1.887zM2.007 16.9a.985.985 0 00.31-.05 1 1 0 00.641-1.26 9.51 9.51 0 01-.393-1.765 1 1 0 00-1.985.248 11.542 11.542 0 00.476 2.136 1 1 0 00.951.691zM13.315 3.236a9.614 9.614 0 011.758.419.989.989 0 00.324.055 1 1 0 00.324-1.946 11.323 11.323 0 00-2.131-.508 1 1 0 10-.275 1.98zM19.527 6.859a1 1 0 101.586-1.217 11.492 11.492 0 00-1.873-1.926 1 1 0 10-1.262 1.553 9.472 9.472 0 011.549 1.59zM21.063 9.812a9.4 9.4 0 01.37 1.769 1 1 0 00.993.889 1.065 1.065 0 00.112-.006 1 1 0 00.883-1.105 11.39 11.39 0 00-.45-2.143 1 1 0 10-1.908.6zM4.7 7.426l2.717 1.086a.516.516 0 01-.191 1H4.549a1.25 1.25 0 000 2.5h.475a.249.249 0 01.25.25v.138a1.25 1.25 0 002.5 0v-.245a.249.249 0 01.189-.242 3.016 3.016 0 00.383-5.723L5.63 5.1a.516.516 0 01.192-1H8.5a1.25 1.25 0 000-2.5h-.476a.249.249 0 01-.25-.25v-.137a1.25 1.25 0 00-2.5 0v.245a.25.25 0 01-.189.243A3.016 3.016 0 004.7 7.426zM17.927 23.963a5.5 5.5 0 10-5.5-5.5 5.506 5.506 0 005.5 5.5zm-3.03-5.7a.749.749 0 011.06 0l1.093 1.092a.25.25 0 00.377-.026l2.4-3.2a.749.749 0 011.2.9l-2.623 3.5a1.422 1.422 0 01-1.042.568h-.1a1.423 1.423 0 01-1.009-.421L14.9 19.323a.749.749 0 010-1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});