define("ember-svg-jar/inlined/religion-peace-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-peace-1</title><path d=\"M24 12a12 12 0 00-24 0c0 9.155 8.513 12 12 12 9.232 0 12-8.49 12-12zm-2 0a9.907 9.907 0 01-1.583 5.374.5.5 0 01-.362.227.5.5 0 01-.405-.136l-6.5-6.222a.5.5 0 01-.15-.361V2.619a.5.5 0 01.579-.493A10.014 10.014 0 0122 12zm-11 9.38a.5.5 0 01-.58.494 9.936 9.936 0 01-4.564-2 .5.5 0 01-.046-.748l4.336-4.336a.5.5 0 01.854.354zm2-6.345a.5.5 0 01.846-.361l4.5 4.31a.5.5 0 01-.029.749 9.951 9.951 0 01-4.738 2.141.5.5 0 01-.579-.494zM10.421 2.126a.5.5 0 01.579.493V10.9a.5.5 0 01-.146.354l-6.38 6.38a.5.5 0 01-.769-.074 9.978 9.978 0 016.716-15.434z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});