define("ember-svg-jar/inlined/turntable-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>turntable-1</title><path d=\"M22 1.5A1.5 1.5 0 0020.5 0h-17A1.5 1.5 0 002 1.5v21A1.5 1.5 0 003.5 24h17a1.5 1.5 0 001.5-1.5zm-3.5 10A6.5 6.5 0 0112 18a6.4 6.4 0 01-2.239-.415.25.25 0 00-.295.1l-2.415 3.62a1.013 1.013 0 01-1.539.153l-1.691-1.691a.987.987 0 01-.068-1.331.249.249 0 01.372-.017C4.56 18.861 5.688 20 5.688 20a.76.76 0 001.161-.123l3.573-5.611a.5.5 0 00-.691-.69l-2.692 1.718a.25.25 0 01-.338-.067A6.419 6.419 0 015.5 11.5a6.5 6.5 0 0113 0zm-1.25 9v1a.75.75 0 11-1.5 0v-1a.75.75 0 011.5 0zM4.5 3.5a1 1 0 111 1 1 1 0 01-1-1zm13 0a1 1 0 111 1 1 1 0 01-1-1zm1.25 17a.75.75 0 011.5 0v1a.75.75 0 11-1.5 0z\"/><path d=\"M12 9.5a2 2 0 102 2 2 2 0 00-2-2zm0 3a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});