define("ember-svg-jar/inlined/bulb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bulb</title><path d=\"M12 4.827a6.507 6.507 0 00-6.391 6.394A6.232 6.232 0 009.5 17.1a.258.258 0 00.091.017h1.424a.25.25 0 00.25-.25v-3.274a.252.252 0 00-.074-.177l-1.679-1.677a.738.738 0 011.043-1.039l1.268 1.268a.249.249 0 00.354 0l1.268-1.268a.738.738 0 011.043 1.043l-1.677 1.677a.252.252 0 00-.074.177v3.274a.25.25 0 00.25.25h1.425a.248.248 0 00.09-.017 6.232 6.232 0 003.889-5.879A6.507 6.507 0 0012 4.827zM11.017.983V2.86a.983.983 0 001.966 0V.983a.983.983 0 00-1.966 0zM3.437 9.743H1.184a.983.983 0 100 1.966h2.253a.983.983 0 000-1.966zM6.3 4.623L4.427 2.746a.983.983 0 00-1.39 1.39l1.877 1.877A.983.983 0 006.3 4.623zM22.816 9.743h-2.253a.983.983 0 000 1.966h2.253a.983.983 0 000-1.966zM20.963 2.746a.983.983 0 00-1.39 0L17.7 4.623a.983.983 0 101.39 1.39l1.877-1.877a.983.983 0 00-.004-1.39zM14.458 18.337H9.542a.983.983 0 000 1.966h4.916a.983.983 0 000-1.966zM14.458 21.05H9.542a.983.983 0 100 1.966h1.25a.249.249 0 01.245.2.983.983 0 001.926 0 .25.25 0 01.245-.2h1.25a.983.983 0 100-1.966z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});