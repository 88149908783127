define("ember-svg-jar/inlined/zip-file-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>zip-file-1</title><path d=\"M16 4.5A1.5 1.5 0 0017.5 6h3.9a.25.25 0 00.177-.427L16.427.427A.25.25 0 0016 .6z\"/><path d=\"M22 7.25a.25.25 0 00-.25-.25H17.5A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2zM9.5 19.125H7a1.127 1.127 0 01-.936-1.75l2.464-3.866a.25.25 0 00-.211-.384H6.5a.625.625 0 010-1.25H9a1.126 1.126 0 01.936 1.75l-2.464 3.866a.25.25 0 00.211.384H9.5a.625.625 0 010 1.25zm3.877-1.25h.123a.625.625 0 010 1.25h-2a.625.625 0 010-1.25h.126a.249.249 0 00.249-.249v-4.249a.252.252 0 00-.252-.252H11.5a.625.625 0 010-1.25h2a.625.625 0 010 1.25h-.126a.249.249 0 00-.249.249v4.249a.252.252 0 00.252.252zM17 16.123h-.623a.252.252 0 00-.252.252V18.5a.625.625 0 11-1.25 0v-6a.625.625 0 01.625-.625H17a2.125 2.125 0 010 4.25z\"/><path d=\"M17 13.127h-.623a.252.252 0 00-.252.252v1.246a.252.252 0 00.252.252H17a.875.875 0 000-1.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});