define("ember-svg-jar/inlined/style-three-pin-bread-slice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-bread-slice</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm4.7 8.1a.5.5 0 00-.2.4v6a1 1 0 01-1 1h-7a1 1 0 01-1-1v-6a.5.5 0 00-.2-.4 2 2 0 011.2-3.6h7a2 2 0 011.2 3.6z\"/><path d=\"M9.5 10a.5.5 0 00.354-.146l2-2a.5.5 0 00-.708-.708l-2 2A.5.5 0 009.5 10zM14.146 10.146l-2 2a.5.5 0 00.708.708l2-2a.5.5 0 00-.708-.708zM14.146 7.146l-5 5a.5.5 0 00.708.708l5-5a.5.5 0 00-.708-.708z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});