define("ember-svg-jar/inlined/alarm-bell-ring-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>alarm-bell-ring-1</title><path d=\"M4.5 7.5a1.015 1.015 0 00.873-.505A5.372 5.372 0 017.457 4.9a1 1 0 10-.975-1.746 7.335 7.335 0 00-2.854 2.859 1 1 0 00.384 1.361.968.968 0 00.488.126zM6.432.711A1 1 0 005.186.044 7.492 7.492 0 00.043 5.187a1 1 0 00.668 1.247A1.038 1.038 0 001 6.477a1 1 0 00.957-.712 5.5 5.5 0 013.807-3.807A1 1 0 006.432.711zM19.146 4.386a7.391 7.391 0 00-1.628-1.228 1 1 0 10-.975 1.742 5.372 5.372 0 012.085 2.087 1.015 1.015 0 001.36.383 1 1 0 00.384-1.361 7.438 7.438 0 00-1.226-1.623zM23.957 5.187A7.492 7.492 0 0018.814.044a1 1 0 10-.578 1.914 5.5 5.5 0 013.807 3.807 1.018 1.018 0 001.246.669 1 1 0 00.668-1.247zM13.5 21.5h-3a.5.5 0 00-.5.5 2 2 0 104 0 .5.5 0 00-.5-.5zM18.5 13.647V13c0-3.493-2.079-6.479-5-7.27V4.5a1.5 1.5 0 00-3 0v1.23c-2.921.791-5 3.777-5 7.27v.646a13.771 13.771 0 01-1.447 6.13.5.5 0 00.447.724h15a.5.5 0 00.447-.724 13.771 13.771 0 01-1.447-6.129z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});