define("ember-svg-jar/inlined/hat-cowboy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hat-cowboy</title><path d=\"M23 11.985a1 1 0 00-1 1 3.317 3.317 0 01-1.994 3.381 17.708 17.708 0 01-1.783-6.677c-.208-1.8-.372-3.222-1.423-3.879a2.568 2.568 0 00-2.3-.074l-.433.155c-1.357.787-1.309 1.461-2.553 2.621a4.776 4.776 0 01-1.026.74.5.5 0 01-.468-.883 5.347 5.347 0 001.63-1.514.25.25 0 00.038-.236.247.247 0 00-.176-.16c-.333-.082-.839-.271-2-.691a2.668 2.668 0 00-2.339.075c-1.2.716-1.458 2.341-1.808 4.59a25.865 25.865 0 01-1.451 5.907A3.352 3.352 0 012 12.985a1 1 0 00-2 0c0 2.954 1.742 5.156 4.223 5.464.213.062 15.342.062 15.555 0 2.48-.308 4.222-2.511 4.222-5.464a1 1 0 00-1-1zm-16.3 4.5a.5.5 0 01-.476-.655c.1-.314.2-.638.292-.975A.5.5 0 017 14.489h9.707a.5.5 0 01.476.346q.159.492.349.965a.5.5 0 01-.464.686z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});