define("ember-svg-jar/inlined/road-sign-divided-highway-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-divided-highway-2</title><path d=\"M23.53 12.53a.749.749 0 000-1.06l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0zm-16.414.647a1.244 1.244 0 01-.366-.884V10.5a.25.25 0 00-.25-.25.75.75 0 01-.53-1.28l1-1a.751.751 0 011.06 0l1 1a.75.75 0 01-.53 1.28.25.25 0 00-.25.25v1.586a.251.251 0 00.073.177l1.561 1.561a1.244 1.244 0 01.366.884V17.5a.75.75 0 01-1.5 0v-2.585a.251.251 0 00-.073-.177zM10.5 6.75a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25V9.5a1.5 1.5 0 01-3 0zm7.693 3.037a.751.751 0 01-.693.463.25.25 0 00-.25.25v1.793a1.244 1.244 0 01-.366.884l-1.561 1.561a.251.251 0 00-.073.177V17.5a.75.75 0 01-1.5 0v-2.792a1.244 1.244 0 01.366-.884l1.561-1.561a.251.251 0 00.073-.177V10.5a.25.25 0 00-.25-.25.75.75 0 01-.53-1.28l1-1a.751.751 0 011.06 0l1 1a.749.749 0 01.163.817z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});