define("ember-svg-jar/inlined/family-grandfather", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-grandfather</title><path d=\"M13.753 8.328a.25.25 0 01-.019-.436 4.275 4.275 0 10-4.468 0 .249.249 0 01-.019.436 5.06 5.06 0 00-2.8 4.522v3.037a.5.5 0 00.5.5h1.371a.5.5 0 01.5.45l.669 6.687a.5.5 0 00.5.45h3.036a.5.5 0 00.5-.45l.669-6.687a.5.5 0 01.5-.45h1.371a.5.5 0 00.5-.5V12.85a5.058 5.058 0 00-2.81-4.522zm-1.5 3.661a.75.75 0 01-1.5 0v-1.8a.75.75 0 111.5 0zM11.5 6.524a2.278 2.278 0 01-2.275-2.276 2.252 2.252 0 01.109-.7.249.249 0 01.371-.135 5.412 5.412 0 002.887.83 5.5 5.5 0 00.885-.072.248.248 0 01.21.063.251.251 0 01.08.205A2.278 2.278 0 0111.5 6.524zM2.25 14.473A2.253 2.253 0 000 16.723v.25a1 1 0 002 0v-.25c0-.275.5-.275.5 0v6.25a1 1 0 002 0v-6.25a2.253 2.253 0 00-2.25-2.25z\"/><circle cx=\"21.097\" cy=\"11.5\" r=\"2.027\"/><path d=\"M21.1 13.973a2.9 2.9 0 00-2.9 2.9v1.759a.5.5 0 00.5.5h.5a.5.5 0 01.5.45l.394 3.938a.5.5 0 00.5.45h1.03a.5.5 0 00.5-.45l.393-3.938a.5.5 0 01.5-.45h.5a.5.5 0 00.5-.5v-1.756a2.9 2.9 0 00-2.917-2.903z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});