define("ember-svg-jar/inlined/astrology-leo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astrology-leo</title><path d=\"M22.5 20a1 1 0 00-1 1 1 1 0 01-2 0V7a7 7 0 00-14 0v9.975a.25.25 0 01-.336.235A3.379 3.379 0 004 17a3.5 3.5 0 103.5 3.5V7a5 5 0 0110 0v14a3 3 0 006 0 1 1 0 00-1-1zM4 22a1.5 1.5 0 111.5-1.5A1.5 1.5 0 014 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});