define("ember-svg-jar/inlined/plant-bonsai", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plant-bonsai</title><path d=\"M15.806 18H8.194a1 1 0 00-.812.416 1.02 1.02 0 00-.123.938L8.8 22.975A1.5 1.5 0 0010.221 24h3.558a1.5 1.5 0 001.421-1l1.56-3.679A1 1 0 0015.806 18zM16.952 5.014a.5.5 0 01-.468-.462C16.314 2.221 14.819 0 12 0S7.686 2.221 7.516 4.552a.5.5 0 01-.468.462C4.5 5.17 2.5 6.665 2.5 9.5c0 3 2.239 4.5 5 4.5h2.75a.5.5 0 01.5.5v2a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-2a.5.5 0 01.5-.5h2.75c2.761 0 5-1.5 5-4.5 0-2.835-2-4.33-4.548-4.486z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});