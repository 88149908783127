define("ember-svg-jar/inlined/allowances-no-photos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>allowances-no-photos</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm8.133 17.806a.5.5 0 01-.761.062l-.181-.18a1 1 0 00-1.414 1.412l.2.2a.5.5 0 01-.058.757A9.994 9.994 0 013.944 6.083a.5.5 0 01.756-.058l.758.758a1 1 0 101.415-1.414l-.741-.741a.5.5 0 01.062-.761 9.991 9.991 0 0113.939 13.939z\"/><path d=\"M18.545 9.455a1.5 1.5 0 00-1.5-1.5h-.883a.5.5 0 01-.447-.276l-.085-.17a1.008 1.008 0 00-.9-.554h-2.876a1 1 0 00-.895.554l-.085.169a.5.5 0 01-.447.277H7.045a1.5 1.5 0 00-1.5 1.5v6a1.5 1.5 0 001.5 1.5h10a1.5 1.5 0 001.5-1.5zm-5.5 5.5a2.5 2.5 0 112.5-2.5 2.5 2.5 0 01-2.5 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});