define("ember-svg-jar/inlined/music-genre-brain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-genre-brain</title><path d=\"M12.316 17.158a.249.249 0 00.184-.241V13.83a3.5 3.5 0 012.855-3.44l3.445-.645A.249.249 0 0019 9.5a2.85 2.85 0 00-1.663-2.578.249.249 0 01-.129-.317 3.341 3.341 0 00.227-1.214 3.441 3.441 0 00-2.96-3.4.219.219 0 01-.187-.188 2.059 2.059 0 00-3.938-.547.251.251 0 01-.274.148 3.251 3.251 0 00-1.143 0 .249.249 0 01-.273-.147 2.066 2.066 0 00-3.948.543.218.218 0 01-.187.188 3.441 3.441 0 00-2.96 3.4 3.341 3.341 0 00.227 1.214.249.249 0 01-.129.317 2.83 2.83 0 000 5.156.249.249 0 01.129.317 3.341 3.341 0 00-.227 1.214 3.441 3.441 0 002.96 3.4.219.219 0 01.187.188 2.059 2.059 0 003.938.547.251.251 0 01.274-.148 3.275 3.275 0 001.143 0 .25.25 0 01.273.147l.045.1a.25.25 0 00.377.087 4.5 4.5 0 011.554-.769zM6.5 14.293A1.8 1.8 0 004.706 12.5a.289.289 0 01-.093.011.75.75 0 11-.052-1.5.778.778 0 01.145-.011A3.3 3.3 0 018 14.293a.75.75 0 01-1.5 0zm0-9.586a.75.75 0 011.5 0A3.3 3.3 0 014.706 8c-.035 0-.133-.008-.167-.013a.742.742 0 01-.713-.776.769.769 0 01.787-.722c.027 0 .109.009.134.012A1.789 1.789 0 006.5 4.707zm7.96 3.28c-.041 0-.138.013-.166.013A3.3 3.3 0 0111 4.707a.75.75 0 011.5 0A1.8 1.8 0 0014.294 6.5a.289.289 0 01.093-.011.734.734 0 01.776.722.751.751 0 01-.703.776z\"/><path d=\"M24 12.705a2 2 0 00-2.368-1.966l-6 1.125A2 2 0 0014 13.83v4.931a.255.255 0 01-.081.185.251.251 0 01-.192.064c-.075-.01-.15-.01-.227-.01a2.5 2.5 0 102.5 2.552V14.245a.5.5 0 01.408-.491l5-.938a.5.5 0 01.592.492v3.453a.255.255 0 01-.081.185.251.251 0 01-.192.064c-.075-.01-.15-.01-.227-.01a2.5 2.5 0 102.5 2.552V19.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});