define("ember-svg-jar/inlined/car-actions-disable-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-actions-disable-1</title><path d=\"M11.971 11.966a8.035 8.035 0 017.488-2.135 7.06 7.06 0 011.029.321.25.25 0 00.341-.232V8.127a2.741 2.741 0 00-1-2.116.249.249 0 010-.387l.895-.716a1 1 0 00-1.249-1.562l-.725.58a.25.25 0 01-.405-.168l-.1-.88A3.016 3.016 0 0015.262.127h-9.32a3.011 3.011 0 00-2.987 2.725l-.1.889a.25.25 0 01-.405.168l-.7-.563A1 1 0 10.5 4.908l.884.707a.249.249 0 010 .389A2.74 2.74 0 00.375 8.127v2.25a1.752 1.752 0 001.75 1.75h9.705c.049-.052.09-.11.141-.161zM4.748 4.85l.2-1.806a1.006 1.006 0 011-.917h9.32a1.013 1.013 0 011 .943l.2 1.78a.249.249 0 01-.248.277H5a.251.251 0 01-.249-.277zm-.623 5.527a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM6.625 13.127h-4a.25.25 0 00-.25.25v.25a2.25 2.25 0 104.5 0v-.25a.25.25 0 00-.25-.25z\"/><path d=\"M23.715 16.185a6.174 6.174 0 00-4.649-4.648 6.263 6.263 0 00-7.528 7.525 6.258 6.258 0 0010.509 2.983 6.282 6.282 0 001.668-5.86zm-9.7 3.5a.251.251 0 01-.184-.133 4.26 4.26 0 015.721-5.72.248.248 0 01.132.184.245.245 0 01-.07.215l-5.386 5.379a.251.251 0 01-.215.07zm6.62.95a4.253 4.253 0 01-4.928.793.252.252 0 01-.133-.184.249.249 0 01.071-.215l5.383-5.383a.249.249 0 01.4.062 4.256 4.256 0 01-.795 4.922z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});