define("ember-svg-jar/inlined/landmark-notre-dame", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-notre-dame</title><path d=\"M8.5 8h1a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5h-8a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.75.75 0 011.5 0v1A.5.5 0 005 8h1a.5.5 0 00.5-.5v-1a.75.75 0 011.5 0v1a.5.5 0 00.5.5zM21.5 8h1a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5h-8a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.75.75 0 011.5 0v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.75.75 0 011.5 0v1a.5.5 0 00.5.5zM.75 1.5h9.5a.75.75 0 000-1.5H.75a.75.75 0 000 1.5zM13.75 1.5h9.5a.75.75 0 000-1.5h-9.5a.75.75 0 000 1.5zM23.25 22.5a.25.25 0 01-.25-.25V18a.5.5 0 00-.5-.5h-21a.5.5 0 00-.5.5v4.25a.25.25 0 01-.25.25.75.75 0 000 1.5H4a.5.5 0 00.5-.5v-2a1.5 1.5 0 013 0v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2a1.5 1.5 0 013 0v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2a1.5 1.5 0 013 0v2a.5.5 0 00.5.5h3.25a.75.75 0 000-1.5zM23.25 9.5H.75a.75.75 0 000 1.5.25.25 0 01.25.25v4.25a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.75.75 0 011.5 0v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.75.75 0 011.5 0v1a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-1a.75.75 0 011.5 0v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.75.75 0 011.5 0v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-4.25a.25.25 0 01.25-.25.75.75 0 000-1.5zM12 14a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0112 14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});