define("ember-svg-jar/inlined/touch-finger-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>touch-finger-lock</title><path d=\"M19.879 17H16.75a.25.25 0 01-.25-.25v-6.375a1.875 1.875 0 00-3.75 0v8.808a.5.5 0 01-.908.29l-1.373-1.933a1.575 1.575 0 00-2.787 1.467l1.972 4.687a.5.5 0 00.461.306H23.5a.5.5 0 00.5-.5v-2.153A4.332 4.332 0 0019.879 17zM11 11V8a1.491 1.491 0 00-.643-1.223.249.249 0 01-.107-.205V6a2.75 2.75 0 00-5.5 0v.572a.25.25 0 01-.107.205A1.491 1.491 0 004 8v3a1.5 1.5 0 001.5 1.5h4A1.5 1.5 0 0011 11zm-3.5-.48a1 1 0 111-1 1 1 0 01-1 1zm1.25-4.27a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25V6a1.25 1.25 0 012.5 0z\"/><path d=\"M3 13h-.5a.5.5 0 01-.5-.5v-10a.5.5 0 01.5-.5h10a.5.5 0 01.5.5V6a1 1 0 002 0V2a2 2 0 00-2-2H2a2 2 0 00-2 2v11a2 2 0 002 2h1a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});