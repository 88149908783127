define("ember-svg-jar/inlined/speakers-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>speakers-1</title><path d=\"M18.5 0h-13A2.5 2.5 0 003 2.5v19A2.5 2.5 0 005.5 24h13a2.5 2.5 0 002.5-2.5v-19A2.5 2.5 0 0018.5 0zm-6.51 9a6 6 0 11-6 6 6.006 6.006 0 016-6zM6 4.5a1 1 0 011-1h2.5a1 1 0 010 2H7a1 1 0 01-1-1zm10.5 2a2 2 0 112-2 2 2 0 01-2 2z\"/><circle cx=\"11.99\" cy=\"14.998\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});