define("ember-svg-jar/inlined/religion-man-christian-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-man-christian-2</title><path d=\"M11.286 19.738a.245.245 0 00-.2-.245 5.325 5.325 0 01-1.347-.414l-2.274-1.05a5.064 5.064 0 01-.94-.575.244.244 0 00-.212-.041 16.042 16.042 0 00-2.059.7.245.245 0 01-.336-.274l.641-3.2.958-4.783a.05.05 0 01.1.01l-.3 2.933v.008a4.444 4.444 0 002.559 4.327l2.273 1.05a4.426 4.426 0 003.708 0l2.273-1.05a4.451 4.451 0 002.555-4.334v-.007l-.3-2.919a.05.05 0 01.1-.01l.955 4.771.644 3.215a.246.246 0 01-.336.275 15.988 15.988 0 00-2.059-.7.246.246 0 00-.213.041 5.089 5.089 0 01-.939.575l-2.273 1.05a5.345 5.345 0 01-1.348.414.246.246 0 00-.2.245c.008.745.019 3.226.022 4.017a.246.246 0 00.246.245h9.83a.491.491 0 00.492-.491V21.52a2.423 2.423 0 00-.95-1.943.223.223 0 01-.079-.245.9.9 0 00.027-.44L19.267 3.737a.2.2 0 010-.049A2.964 2.964 0 0017.313.9 15.723 15.723 0 0012 0a15.735 15.735 0 00-5.314.9 2.964 2.964 0 00-1.948 2.79.263.263 0 01-.005.049L1.7 18.892a.9.9 0 00.027.44.227.227 0 01-.079.246A2.42 2.42 0 00.7 21.52v1.989a.491.491 0 00.488.491h9.83a.246.246 0 00.246-.245c.003-.791.014-3.272.022-4.017zm1.7-5.067a.628.628 0 01.3 1.18 2.353 2.353 0 01-.427.178 2.931 2.931 0 01-.85.123 2.878 2.878 0 01-.858-.124 2.328 2.328 0 01-.428-.176.629.629 0 01.3-1.182zm4.266-5.784a.246.246 0 01.157.23v.53a.243.243 0 01-.049.147l-1.951 2.6a1.475 1.475 0 01-1.646.515 5.6 5.6 0 00-3.52 0 1.474 1.474 0 01-1.649-.509L6.643 9.8a.243.243 0 01-.049-.147v-.539a.245.245 0 01.156-.229A14.825 14.825 0 0112 8.092a14.906 14.906 0 015.249.795zm-3.286 13.229a.983.983 0 11.983.983.983.983 0 01-.983-.983zM7.356 2.752A13.746 13.746 0 0112 1.966a13.746 13.746 0 014.644.786.982.982 0 01.656 1.006l-.12 1.566-.08 1.138a.25.25 0 01-.1.182.246.246 0 01-.2.037 19.116 19.116 0 00-4.8-.554 19.006 19.006 0 00-4.788.556.244.244 0 01-.307-.219l-.088-1.14L6.7 3.758a.982.982 0 01.656-1.006z\"/><circle cx=\"13.966\" cy=\"9.892\" r=\".983\"/><circle cx=\"10.034\" cy=\"9.892\" r=\".983\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});