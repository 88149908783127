define("ember-svg-jar/inlined/sigma", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sigma</title><path d=\"M20.412 23.631H4.532a2.181 2.181 0 01-2.044-1.369 2.132 2.132 0 01.612-2.411l9.029-7.662a.25.25 0 000-.381L3.1 4.147a2.133 2.133 0 01-.609-2.41A2.181 2.181 0 014.532.365h15.88a1.25 1.25 0 010 2.5H6.131a.25.25 0 00-.162.441l8.323 7.063a2.127 2.127 0 010 3.257L5.969 20.69a.25.25 0 00.162.441h14.281a1.25 1.25 0 010 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});