define("ember-svg-jar/inlined/giraffe-body-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>giraffe-body-1</title><path d=\"M23.508 1.59a1 1 0 00-1.387.277l-.873 1.309-.71-.444.822-1.151A1 1 0 1019.733.419S18.4 2.352 18.3 2.439c0-1.194-1.43-1.612-2.39-1.758a.517.517 0 00-.5.225.65.65 0 00-.073.592c.327.887.954 2.13 1.921 2.244-.562.905-2 4.026-2.185 4.4C13.837 10.62 13.111 11.5 12.3 11.5H3.547a3.69 3.69 0 00-3.5 3.5 1 1 0 002 0 1.692 1.692 0 011.5-1.5h.77a2.952 2.952 0 00-.27 1.25c0 .945-1.5 2.859-1.523 2.9a.991.991 0 00-.428.538L.6 22.679a1 1 0 001.9.631l.9-2.7c2.033-.428 2.965-1.67 4.619-2.605H16.2a.5.5 0 01.491.41l.867 4.764a1.01 1.01 0 001.162.8 1 1 0 00.8-1.162l-.975-5.359a.456.456 0 01-.008-.09V11.75c0-2.191.589-4.75 2.25-4.75h1.969a.751.751 0 00.745-.844 3.69 3.69 0 00-.664-1.756l.948-1.422a1 1 0 00-.277-1.388zM12.3 15.25a1 1 0 111-1 1 1 0 01-1 1zm3 1.5a1 1 0 111-1 1 1 0 01-1 1zm.5-3.5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});