define("ember-svg-jar/inlined/famous-character-power-ranger-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character-power-ranger-2</title><path d=\"M3.75 7.622a2.987 2.987 0 001.741 2.024.228.228 0 01.138.166 6.492 6.492 0 0012.727 0 .231.231 0 01.144-.172 2.99 2.99 0 001.75-2.019L21.605 2.2A1.5 1.5 0 0019.2.67l-1.973 1.6a.244.244 0 01-.34-.033 6.462 6.462 0 00-9.784 0 .244.244 0 01-.34.033L4.8.671A1.5 1.5 0 002.4 2.2zm9.843.944l.261-.913a.247.247 0 01.083-.125l4.783-3.886a.25.25 0 01.4.254l-.809 3.24a1 1 0 01-.7.72s-3.149.894-3.505 1-.606.037-.513-.29zM12 11.5a1.018 1.018 0 00.274-.038l2.907-.83a.25.25 0 01.319.24v1.737a.263.263 0 01-.1.206 5.62 5.62 0 01-6.8 0 .263.263 0 01-.1-.206v-1.737a.25.25 0 01.319-.24l2.907.83A1.018 1.018 0 0012 11.5zM5.28 3.642l4.783 3.885a.247.247 0 01.083.125s.183.669.271.95-.2.348-.526.255l-3.5-1a1 1 0 01-.7-.72L4.88 3.9a.25.25 0 01.4-.258z\"/><path d=\"M22.533 18.417a12.1 12.1 0 00-2.682-1.517.254.254 0 00-.334.139 27.335 27.335 0 01-2.411 4.545.2.2 0 01-.365-.148 17.142 17.142 0 00.378-5.23.252.252 0 00-.2-.211A23.081 23.081 0 0012 15.5a23.081 23.081 0 00-4.922.5.252.252 0 00-.2.211 17.142 17.142 0 00.378 5.23.2.2 0 01-.365.148 27.335 27.335 0 01-2.411-4.545.254.254 0 00-.334-.139 12.1 12.1 0 00-2.682 1.513A2.467 2.467 0 00.5 20.394V23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-3.106a2.467 2.467 0 00-.967-1.977z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});