define("ember-svg-jar/inlined/laptop-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-heart</title><path d=\"M22.41 4.5a2 2 0 00-2-2h-17a2 2 0 00-2 2v12.682a.319.319 0 01-.32.319 1 1 0 00-.99 1.164l.124.747A2.492 2.492 0 002.7 21.5h18.606a2.492 2.492 0 002.466-2.089l.124-.746a1 1 0 00-.986-1.165.5.5 0 01-.5-.5zm-2 12.5a.5.5 0 01-.5.5h-16a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5h16a.5.5 0 01.5.5z\"/><path d=\"M11.279 14.513a.5.5 0 00.722 0l3.4-3.549a2.517 2.517 0 10-3.56-3.559l-.026.026a.25.25 0 01-.354 0l-.026-.026a2.518 2.518 0 00-4.032.654 2.511 2.511 0 00.464 2.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});