define("ember-svg-jar/inlined/heavy-equipment-lift-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>heavy-equipment-lift-2</title><path d=\"M.75 16a.5.5 0 00-.5.5v.683c0 2.211 1.262 3.531 3.375 3.531a4.357 4.357 0 003.8-2.378.5.5 0 00-.66-.67 1.846 1.846 0 01-1.567-.034 2.026 2.026 0 01-.762-1.257.5.5 0 00-.49-.375zM1.126 6.472A.5.5 0 00.25 6.8V14a.5.5 0 00.5.5h3.092a.5.5 0 00.5-.433l.429-3.175a.5.5 0 00-.119-.4zM9.246 7.273a3.1 3.1 0 01.51-.838.5.5 0 00-.06-.706L3.77.749A2.143 2.143 0 00.759 3.775l6.23 7.125a.5.5 0 00.839-.141zM20.75 11.752h-2v-2a3 3 0 00-3-3h-3.5a1.807 1.807 0 00-1.615 1.086l-1.811 4.449a1 1 0 00-.074.377v4.088a2 2 0 002 2h10a2 2 0 002-2v-3a2 2 0 00-2-2zm-8.119-3h3.551a.57.57 0 01.568.572v2.857a.57.57 0 01-.568.571h-4.363a.571.571 0 01-.547-.728l.812-2.857a.569.569 0 01.547-.415zM10.75 19.752a2 2 0 100 4h11a2 2 0 000-4zm1 2.75a.75.75 0 11.75-.75.75.75 0 01-.75.748zm4.5 0a.75.75 0 11.75-.75.75.75 0 01-.75.748zm4.5 0a.75.75 0 11.75-.75.75.75 0 01-.75.748z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});