define("ember-svg-jar/inlined/smart-watch-square-missed-call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-missed-call</title><path d=\"M16.43 2.75a.251.251 0 00.238-.329L15.975.342A.5.5 0 0015.5 0h-7a.5.5 0 00-.475.342l-.693 2.079a.251.251 0 00.238.329zM7.57 21.25a.248.248 0 00-.2.1.251.251 0 00-.035.225l.693 2.079A.5.5 0 008.5 24h7a.5.5 0 00.475-.342l.693-2.079a.251.251 0 00-.035-.225.248.248 0 00-.2-.1zM17.5 4h-11A2.5 2.5 0 004 6.5v11A2.508 2.508 0 006.5 20h11a2.5 2.5 0 002.5-2.5v-11A2.5 2.5 0 0017.5 4zm-1.646 5.9a.5.5 0 01-.354.854h-2a.5.5 0 01-.5-.5v-2a.5.5 0 01.854-.354l.469.47a.25.25 0 00.354 0l1.469-1.47a.5.5 0 11.708.707l-1.47 1.47a.25.25 0 000 .354zm-4.688 4.19a.248.248 0 00.342-.01l.971-.971a1.272 1.272 0 01.905-.376 1.274 1.274 0 01.908.376l.83.831a1.285 1.285 0 010 1.814l-.456.455a1.681 1.681 0 01-2.107.213 18.7 18.7 0 01-5.234-5.233 1.682 1.682 0 01.21-2.106L8 8.626a1.289 1.289 0 011.817 0l.83.833a1.282 1.282 0 010 1.813l-.973.971a.249.249 0 00-.01.342 17.543 17.543 0 001.502 1.505z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});