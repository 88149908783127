define("ember-svg-jar/inlined/smart-house-ventilator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-house-ventilator</title><path d=\"M23.772 6.882L15.416.15a.652.652 0 00-.832 0L6.228 6.882a.628.628 0 00-.189.7.643.643 0 00.6.416H8.5a.5.5 0 01.5.5v5.866a.639.639 0 00.643.636h10.714a.638.638 0 00.643-.634V8.5a.5.5 0 01.5-.5h1.857a.643.643 0 00.6-.416.628.628 0 00-.185-.702zm-5.133 1.16a2.091 2.091 0 01-1.8.949.251.251 0 00-.2.113.248.248 0 00-.025.224l1.252 3.337a.251.251 0 01-.232.338 2.621 2.621 0 01-1.685-.364 2.1 2.1 0 01-.949-1.8.249.249 0 00-.337-.22l-3.337 1.252a.251.251 0 01-.326-.228 2.63 2.63 0 01.364-1.685 2.091 2.091 0 011.8-.949.251.251 0 00.2-.113.248.248 0 00.025-.224l-1.263-3.337a.249.249 0 01.027-.229.251.251 0 01.2-.109 2.62 2.62 0 011.685.364 2.1 2.1 0 01.949 1.8.249.249 0 00.337.22l3.337-1.252a.251.251 0 01.339.227 2.615 2.615 0 01-.361 1.686zM2.414 14.057a1.281 1.281 0 011.672 0 .75.75 0 101-1.114 2.819 2.819 0 00-3.68 0 .75.75 0 101 1.114z\"/><path d=\"M6.219 10.793a4.871 4.871 0 00-5.938 0 .75.75 0 10.938 1.17 3.3 3.3 0 014.062 0 .75.75 0 10.938-1.17zM4.75 15.25h-3A1.752 1.752 0 000 17v5.25A1.752 1.752 0 001.75 24h3a1.752 1.752 0 001.75-1.75V17a1.752 1.752 0 00-1.75-1.75zm.25 7a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25V17a.25.25 0 01.25-.25h3A.25.25 0 015 17z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});