define("ember-svg-jar/inlined/instrument-guitar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>instrument-guitar</title><path d=\"M23.707 4.793l-.47-.47a.25.25 0 010-.353l.117-.116a1.5 1.5 0 000-2.122L22.268.646a1.5 1.5 0 00-2.122 0l-.116.117a.25.25 0 01-.353 0l-.47-.47a1 1 0 00-1.414 1.414l.47.47a.25.25 0 010 .353l-.233.233a.25.25 0 01-.353 0l-.47-.47a1 1 0 00-1.414 1.414l.469.47a.242.242 0 01.073.176.249.249 0 01-.073.177l-.116.116a.859.859 0 00-.175.949.25.25 0 01-.053.279l-2.582 2.55a.5.5 0 01-.584.089 4.8 4.8 0 00-4.028-.263 4.135 4.135 0 00-2.389 2.6.484.484 0 01-.524.343 4.942 4.942 0 00-5.28 2.745 7.037 7.037 0 001.751 7.746c1.084 1.084 4.3 3.375 7.735 1.753a5.009 5.009 0 002.753-5.282.5.5 0 01.343-.523 4.143 4.143 0 002.607-2.392 4.791 4.791 0 00-.268-4.024.5.5 0 01.09-.584l2.584-2.552a.248.248 0 01.275-.052.88.88 0 00.953-.174l.116-.117a.25.25 0 01.353 0l.47.47a1 1 0 001.414-1.414l-.47-.47a.25.25 0 010-.353l.233-.233a.25.25 0 01.353 0l.47.47a1 1 0 001.414-1.414zM10.414 15.774a2.222 2.222 0 112.222-2.22 2.221 2.221 0 01-2.222 2.22zm-3.248 5.087a.75.75 0 01-1.061 0l-3-3A.75.75 0 014.166 16.8l3 3a.749.749 0 010 1.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});