define("ember-svg-jar/inlined/taxi-wave-businessman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>taxi-wave-businessman</title><path d=\"M11.375 18.5a2.5 2.5 0 012.5-2.5h.25a.25.25 0 00.25-.25v-5.11a.5.5 0 011 0v3.86a1.24 1.24 0 00.408.912 2.275 2.275 0 00-.413.588 2.228 2.228 0 00-.245 1v.5h-1.25a1 1 0 00-1 1v4a1 1 0 001 1h7a1 1 0 001-1v-4a1 1 0 00-1-1h-1.25V17a2.284 2.284 0 00-1.81-2.2 1.171 1.171 0 00.06-.295V10.89a4.005 4.005 0 00-4-4H9.886l-5.2-5.2a1.5 1.5 0 00-2.122 2.12l5.811 5.811V22.5a1 1 0 001 1h1.856a.25.25 0 00.24-.319 2.473 2.473 0 01-.1-.681zm6.75-1.5v.5h-1.5V17a.75.75 0 011.5 0z\"/><circle cx=\"11.375\" cy=\"3\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});