define("ember-svg-jar/inlined/receipt-register-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>receipt-register-1</title><path d=\"M19.914 17.75a.25.25 0 00.246-.3A12.727 12.727 0 0010.2 7.26a.25.25 0 01-.2-.245V5.5a.25.25 0 00-.25-.25h-7.5A.25.25 0 002 5.5v12a.25.25 0 00.25.25zM7.75 10a2.75 2.75 0 012.75 2.75 2.222 2.222 0 01-.018.273.249.249 0 00.152.262l3.654 1.523a.75.75 0 11-.576 1.384L9.963 14.63a.251.251 0 00-.274.055A2.746 2.746 0 117.75 10zM9.97 3.75a.031.031 0 00.03-.03.031.031 0 01.009-.022l1.137-1.137a1.477 1.477 0 00.326-1.635A1.478 1.478 0 0010.086 0H3.5A1.5 1.5 0 002 1.5v2a.25.25 0 00.25.25zM20.522 19.25H2.25a.25.25 0 00-.25.25v3.062A1.472 1.472 0 003.5 24h17a1.472 1.472 0 001.5-1.438v-1.876a1.469 1.469 0 00-1.478-1.436zM13.263 7.211l1.5.5A.731.731 0 0015 7.75a.75.75 0 00.237-1.461l-1.5-.5a.749.749 0 10-.474 1.422zM17.97 10.03a.75.75 0 001.06-1.06l-1-1a.75.75 0 00-1.06 1.06zM20.288 13.737a.753.753 0 00.712.513.731.731 0 00.237-.039.749.749 0 00.475-.948l-.5-1.5a.75.75 0 00-1.424.474z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});