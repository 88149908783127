define("ember-svg-jar/inlined/insect-catch-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>insect-catch-1</title><path d=\"M22 4.5h-4a.5.5 0 01-.5-.5V2a2 2 0 00-2-2h-7a2 2 0 00-2 2v2a.5.5 0 01-.5.5H2a2 2 0 00-2 2v15A2.5 2.5 0 002.5 24h19a2.5 2.5 0 002.5-2.5v-15a2 2 0 00-2-2zm-13.5-2A.5.5 0 019 2h6a.5.5 0 01.5.5V4a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5zM22 19a.5.5 0 01-.5.5h-19A.5.5 0 012 19V7a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M18.4 12.437a3.488 3.488 0 00-3.14-1.937h-2.8a2 2 0 01-.746-.144c-.636-.255-1.9-.762-1.974-.788-2.865-.954-4.462.483-4.908 1.377a.75.75 0 101.342.67 2 2 0 012.055-.856.247.247 0 01.186.112.25.25 0 01.029.216l-.226.676a.751.751 0 00.474.949.789.789 0 00.238.038.748.748 0 00.711-.513l.238-.714a.251.251 0 01.33-.153l.529.212a.248.248 0 01.155.263l-.214 1.717a.5.5 0 01-.5.438H7.5a.75.75 0 000 1.5h3.507a.25.25 0 01.224.362l-.4.8a.75.75 0 00.336 1.006.75.75 0 001.006-.336l.779-1.559a.5.5 0 01.448-.273h.835a.5.5 0 01.354.146l1.881 1.884a.75.75 0 001.06-1.06l-.543-.543a.25.25 0 01.177-.427h.336a1.492 1.492 0 001.275-.706v-.006a1.5 1.5 0 00.059-1.46z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});