define("ember-svg-jar/inlined/remove-shield-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>remove-shield-alternate</title><path d=\"M20.052 0H3.947A1.954 1.954 0 002 1.956v5.992a17.982 17.982 0 009.53 15.934 1 1 0 00.939 0A17.984 17.984 0 0022 7.948V1.956A1.955 1.955 0 0020.052 0zM20 7.948a16 16 0 01-8 13.909A15.99 15.99 0 014 7.948V2.5a.5.5 0 01.5-.5l15-.041a.5.5 0 01.5.5z\"/><path d=\"M7.376 14.8a1.267 1.267 0 001.768 0l2.589-2.589a.5.5 0 01.707 0l2.589 2.589a1.271 1.271 0 001.767 0 1.248 1.248 0 000-1.767l-2.588-2.589a.5.5 0 010-.707L16.8 7.143a1.25 1.25 0 00-1.767-1.768L12.44 7.964a.5.5 0 01-.707 0L9.144 5.375a1.25 1.25 0 00-1.768 1.768l2.589 2.589a.5.5 0 010 .707l-2.589 2.589a1.249 1.249 0 000 1.772z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});