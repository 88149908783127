define("ember-svg-jar/inlined/lucide-citrus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5.51 18.49a12 12 0 0016.12.78c.49-.41.49-1.15.03-1.6L6.34 2.33a1.08 1.08 0 00-1.6.03A12 12 0 005.5 18.5z\"/><path d=\"M8.34 15.66a8 8 0 0010.4.78c.54-.4.54-1.16.06-1.64L9.2 5.2c-.48-.48-1.25-.48-1.64.06a8 8 0 00.78 10.4zM14 10l-5.5 5.5M14 10v8M14 10H6\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});