define("ember-svg-jar/inlined/professions-man-telecommunicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-telecommunicator</title><path d=\"M8.489 9.1a.75.75 0 001.022 1.1.559.559 0 01.557 0 .75.75 0 001.022-1.1 2.02 2.02 0 00-2.601 0zM13.932 10.2a.559.559 0 01.557 0 .75.75 0 001.022-1.1 2.02 2.02 0 00-2.6 0 .75.75 0 001.022 1.1z\"/><path d=\"M5.159 8.464a.252.252 0 01-.049.185 2.156 2.156 0 00-.4 1.365 2.731 2.731 0 001.439 2.433.25.25 0 01.144.187c.511 3.048 4.021 4.991 5.686 4.991a4.764 4.764 0 001.873-.493.751.751 0 00-.633-1.361 3.328 3.328 0 01-1.24.354c-1.032 0-3.83-1.49-4.207-3.738a1.747 1.747 0 00-1-1.306 1.275 1.275 0 01-.559-1.1 1.226 1.226 0 01.008-.17.218.218 0 01.217-.19h.183a1.754 1.754 0 001.713-1.402l.185-.919a.25.25 0 01.227-.2 5.1 5.1 0 003.07-1.051.251.251 0 01.328 0 5.107 5.107 0 003.07 1.05.25.25 0 01.227.2l.184.922a1.754 1.754 0 001.716 1.406.159.159 0 01.159.159v1.435a.252.252 0 01-.077.181.184.184 0 00-.023.025 4.272 4.272 0 01-3.4 1.7h-1.5a.75.75 0 000 1.5H14a5.777 5.777 0 004.484-2.156.25.25 0 01.195-.094h.821a2 2 0 000-4h-.416a.249.249 0 01-.249-.277c.275-2.529-.163-4.51-1.325-5.882C16.334.829 14.474.125 11.979.125S7.626.828 6.45 2.216C5.191 3.7 4.821 5.921 5.159 8.464zM16.951 16.979l-.259-.074a.5.5 0 00-.537.18 5.556 5.556 0 01-2.129 1.553.247.247 0 00.017.461l3.19 1.111a.25.25 0 00.329-.274l-.433-2.755a.25.25 0 00-.178-.202zM7.845 17.085a.5.5 0 00-.537-.18l-.259.074a.25.25 0 00-.178.2l-.432 2.747a.251.251 0 00.331.275l3.156-1.12a.247.247 0 00.02-.459 5.515 5.515 0 01-2.101-1.537z\"/><path d=\"M5.917 21.566a.506.506 0 01-.167.029.5.5 0 01-.494-.577l.518-3.3a.247.247 0 00-.081-.226.252.252 0 00-.234-.053l-2.538.727A4.52 4.52 0 000 22.375v1a.5.5 0 00.5.5H11a.25.25 0 00.25-.25V20.03a.249.249 0 00-.106-.205.253.253 0 00-.228-.031zM21.042 18.149l-2.5-.715a.25.25 0 00-.316.279l.519 3.305a.5.5 0 01-.494.577.474.474 0 01-.165-.029l-5-1.743a.249.249 0 00-.332.236v3.566a.25.25 0 00.25.25H23.5a.5.5 0 00.5-.5v-1a4.531 4.531 0 00-2.958-4.226z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});