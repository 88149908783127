define("ember-svg-jar/inlined/modern-music-bass-guitar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-music-bass-guitar</title><path d=\"M8.722 7.988a.249.249 0 00-.222.248v.894a.25.25 0 00.111.207c.05.034.1.07.146.108a.25.25 0 00.157.055h14.822a.249.249 0 00.249-.278 2.506 2.506 0 00-2.258-2.212.249.249 0 01-.227-.248V6a1 1 0 00-2 0v.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V6a1 1 0 00-2 0v.75a.25.25 0 01-.25.25h-4.879a.25.25 0 00-.208.111 2 2 0 01-1.441.877zM10.078 11a.251.251 0 00-.228.353l1.563 3.48a.25.25 0 00.431.043 4.5 4.5 0 112.947 7.068.251.251 0 00-.289.261 3.118 3.118 0 01-.272 1.443.25.25 0 00.228.352H21.5a2.5 2.5 0 002.5-2.5V11.25a.25.25 0 00-.25-.25z\"/><circle cx=\"15.5\" cy=\"17.5\" r=\"1.5\"/><path d=\"M8.979 13.072a.25.25 0 00-.478.1V17a2 2 0 11-4 0v-3.826a.25.25 0 00-.478-.1l-3.862 8.6a1.623 1.623 0 002.073 2.206h.007L6.4 22.087a.25.25 0 01.2 0l4.174 1.8h.008a1.623 1.623 0 002.065-2.2z\"/><path d=\"M5.5 17a1 1 0 002 0V6.75a.25.25 0 01.25-.25h.75A.5.5 0 009 6V.5a.5.5 0 00-.722-.447L5.383 1.5A2.487 2.487 0 004 3.736V6a.5.5 0 00.5.5h.75a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});