define("ember-svg-jar/inlined/season-spring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>season-spring</title><path d=\"M10.75 17.5a1 1 0 000-2h-2a6.75 6.75 0 116.671-7.719 1 1 0 001.485.727 3.742 3.742 0 015.2 1.569 1 1 0 101.789-.893A5.717 5.717 0 0018.75 6a5.473 5.473 0 00-1.191.13.5.5 0 01-.577-.32A8.746 8.746 0 108.75 17.5zM1.818 21.534a6.1 6.1 0 00-1.525.759 1 1 0 001.414 1.414 6.171 6.171 0 00.758-1.525.5.5 0 00-.647-.648zM5.965 19.182a.5.5 0 00-.648-.648 6.115 6.115 0 00-1.525.759 1 1 0 001.414 1.414 6.133 6.133 0 00.759-1.525zM6.817 21.534a6.115 6.115 0 00-1.525.759 1 1 0 001.414 1.414 6.133 6.133 0 00.759-1.525.5.5 0 00-.648-.648zM10.852 18.646a.5.5 0 00-.535-.112 6.115 6.115 0 00-1.525.759 1 1 0 101.414 1.414 6.133 6.133 0 00.759-1.525.5.5 0 00-.113-.536z\"/><path d=\"M23.7 13.4a.5.5 0 00-.43-.035 8.847 8.847 0 01-3.724.388 6.666 6.666 0 00-3.9.613 3.792 3.792 0 00-2.158 2.706 2.781 2.781 0 00.369 1.653.5.5 0 01-.112.618 20.534 20.534 0 00-3.274 3.484.75.75 0 001.233.854 24.3 24.3 0 016.89-5.98.75.75 0 01.756 1.295 23.331 23.331 0 00-3.553 2.556.5.5 0 00-.031.724 2.273 2.273 0 00.6.446 2.533 2.533 0 001.147.266 5.418 5.418 0 002.7-.865c2.009-1.183 4.183-5.452 3.739-8.367a.5.5 0 00-.252-.356z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});