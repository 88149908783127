define("ember-svg-jar/inlined/folder-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-add</title><path d=\"M9.5 17.5a8.009 8.009 0 018-8 7.914 7.914 0 013.153.657.244.244 0 00.347-.222V4.5A1.5 1.5 0 0019.5 3H9.618a.5.5 0 01-.447-.276L8.223.829A1.5 1.5 0 006.882 0H1.5A1.5 1.5 0 000 1.5v15A1.5 1.5 0 001.5 18h7.641c.25 0 .359-.331.359-.5z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm3.25 6.5a.75.75 0 01-.75.75h-1.5a.25.25 0 00-.25.25V20a.75.75 0 01-1.5 0v-1.5a.25.25 0 00-.25-.25H15a.75.75 0 010-1.5h1.5a.25.25 0 00.25-.25V15a.75.75 0 011.5 0v1.5a.25.25 0 00.25.25H20a.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});