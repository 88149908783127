define("ember-svg-jar/inlined/yoga-back-stretch-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>yoga-back-stretch-2</title><circle cx=\"11.5\" cy=\"15.63\" r=\"2.5\"/><path d=\"M24 16.13a1.5 1.5 0 00-1.5-1.5h-4.75a.5.5 0 010-1h.765a.5.5 0 00.493-.417l.423-2.539a.5.5 0 00-.588-.573l-2.095.4a.5.5 0 00-.4.409l-.634 3.8a2.5 2.5 0 002.466 2.913h4.32A1.5 1.5 0 0024 16.13zM19.035 6.589a2.507 2.507 0 00-2.2-.68l-15.6 3a1.5 1.5 0 10.538 2.951l17.55-3.379a.5.5 0 00.4-.58 2.447 2.447 0 00-.688-1.312z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});