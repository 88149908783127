define("ember-svg-jar/inlined/style-three-pin-hold-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-hold-home</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM8 7.5a.5.5 0 01-.354-.854l4-4a.5.5 0 01.708 0l4 4A.5.5 0 0116 7.5a.5.5 0 00-.5.5v1.5a1 1 0 01-1 1H14a1 1 0 01-1-1v-1a1 1 0 00-2 0v1a1 1 0 01-1 1h-.5a1 1 0 01-1-1V8a.5.5 0 00-.5-.5zm9.938 6.847c-1.3.79-4.479 2.658-5.438 2.654s-1.961-.593-4.145-1.608A.25.25 0 008 15.62v.131a.75.75 0 11-1.5 0v-4a.75.75 0 111.5 0 .249.249 0 00.249.249H9.5c1.33 0 2.217 1 2.5 1h1.5a.483.483 0 01.246.074.5.5 0 01.215.231.506.506 0 01.039.2.5.5 0 01-.5.5h-2a.5.5 0 000 1h2a1.5 1.5 0 001.426-1.046.252.252 0 01.155-.16l1.724-.613a1.252 1.252 0 011.189.351.5.5 0 01-.056.815z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});