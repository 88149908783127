define("ember-svg-jar/inlined/cash-network", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-network</title><path d=\"M11.817 7.75H14.5a1.25 1.25 0 000-2.5h-1a.249.249 0 01-.25-.25v-.375a1.25 1.25 0 00-2.5 0v.609a.249.249 0 01-.164.235 3.568 3.568 0 00-.364 6.54l2.438 1.22a1.067 1.067 0 01-.477 2.021H9.5a1.25 1.25 0 000 2.5h1a.25.25 0 01.25.25v.375a1.25 1.25 0 002.5 0v-.609a.249.249 0 01.164-.235 3.567 3.567 0 00.364-6.539l-2.438-1.22a1.067 1.067 0 01.477-2.022zM17.759 7.252a1 1 0 00.7-.29L20.5 4.948a.251.251 0 01.251-.061A2.5 2.5 0 1018.99 2.5a2.476 2.476 0 00.131.8.252.252 0 01-.061.258l-2 1.986a1 1 0 00.7 1.711zM19 21.5a2.5 2.5 0 101.7-2.369.248.248 0 01-.258-.061l-1.784-1.8a1 1 0 10-1.418 1.41l1.809 1.821a.251.251 0 01.062.251A2.474 2.474 0 0019 21.5zM6.977 5.571L4.941 3.555A.254.254 0 014.88 3.3a2.476 2.476 0 00.131-.8 2.5 2.5 0 10-1.756 2.387.251.251 0 01.251.061l2.062 2.044a1 1 0 001.409-1.421zM2.5 24A2.5 2.5 0 005 21.5a2.462 2.462 0 00-.114-.743.25.25 0 01.062-.251l1.81-1.821a1 1 0 00-1.418-1.411l-1.786 1.8a.246.246 0 01-.257.061A2.5 2.5 0 102.5 24zM21.49 9a2.5 2.5 0 00-2.226 1.364.251.251 0 01-.222.136h-1.021a1 1 0 000 2h1.021a.25.25 0 01.222.137A2.5 2.5 0 1021.49 9zM6.041 10.5H4.959a.251.251 0 01-.222-.136 2.5 2.5 0 100 2.273.25.25 0 01.222-.137h1.082a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});