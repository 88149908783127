define("ember-svg-jar/inlined/tools-saw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-saw</title><path d=\"M23.237 1.274L21.442.154a1.019 1.019 0 00-1.186.078l-14.3 11.76a.5.5 0 00-.037.74L9.781 16.6a.5.5 0 00.851-.308l.013-.144a.5.5 0 01.5-.455h3.243a1.021 1.021 0 001.019-1.02v-2.639a.5.5 0 01.5-.5h2.637a1.02 1.02 0 001.019-1.019V7.876a.5.5 0 01.5-.5H22.7a1.021 1.021 0 001.02-1.02V2.14a1.016 1.016 0 00-.483-.866zM4.684 13.62a.5.5 0 00-.707 0l-2.938 2.938a2.576 2.576 0 000 3.646l3.041 3.041A2.57 2.57 0 005.9 24q.107 0 .213-.009A2.569 2.569 0 008 22.92l2.248-3.146a.5.5 0 00-.054-.644zM6.7 20.749a.75.75 0 01-1.061 0l-2.574-2.572a.75.75 0 011.061-1.061L6.7 19.688a.75.75 0 010 1.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});