define("ember-svg-jar/inlined/medical-specialty-lungs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-specialty-lungs</title><path d=\"M14.32 14.468a.5.5 0 01.18.384v4.2a3.17 3.17 0 001.55 2.734c1.583.936 4 2.216 5.34 2.216a2.112 2.112 0 002.11-2.153C23.246 9 18.517 5.038 16.019 3.837a1.061 1.061 0 00-1.519.956v6.687a.25.25 0 01-.41.192l-.91-.758a.5.5 0 01-.18-.384V1a1 1 0 00-2 0v9.53a.5.5 0 01-.18.384l-.91.758a.25.25 0 01-.41-.192V4.793a1.06 1.06 0 00-1.519-.956C5.483 5.038.755 9 .5 21.847A2.112 2.112 0 002.61 24c1.345 0 3.757-1.28 5.34-2.216A3.17 3.17 0 009.5 19.05v-4.2a.5.5 0 01.18-.384l2-1.667a.5.5 0 01.64 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});