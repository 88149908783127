define("ember-svg-jar/inlined/road-sign-look-both-ways-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-look-both-ways-alternate</title><path d=\"M23.02 5.143H.98a.979.979 0 00-.98.979v11.756a.979.979 0 00.98.979h22.04a.979.979 0 00.98-.979V6.122a.979.979 0 00-.98-.979zm-.979 11.51a.245.245 0 01-.245.245H2.2a.245.245 0 01-.245-.245V7.347A.245.245 0 012.2 7.1h19.6a.245.245 0 01.245.245z\"/><path d=\"M20.754 11.327l-3.429-2.449a.735.735 0 00-1.162.6V10.7a.245.245 0 01-.245.245H8.082a.245.245 0 01-.245-.245V9.475a.735.735 0 00-1.162-.6l-3.429 2.452a.735.735 0 000 1.2l3.429 2.449a.735.735 0 001.162-.6v-1.227a.245.245 0 01.245-.245h7.836a.245.245 0 01.245.245v1.224a.735.735 0 001.162.6l3.429-2.449a.735.735 0 000-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});