define("ember-svg-jar/inlined/keyboard-eject-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-eject-alternate</title><path d=\"M19.174 0H4.826A4.832 4.832 0 000 4.822V19.17A4.832 4.832 0 004.826 24h14.348A4.832 4.832 0 0024 19.17V4.822A4.832 4.832 0 0019.174 0zM22 19.17A2.83 2.83 0 0119.174 22H4.826A2.829 2.829 0 012 19.17V4.822A2.828 2.828 0 014.826 2h14.348A2.829 2.829 0 0122 4.822z\"/><rect x=\"4.5\" y=\"13.996\" width=\"15\" height=\"4\" rx=\".5\" ry=\".5\"/><path d=\"M5.654 12h12.692a.5.5 0 00.364-.842l-6.345-6.773a.5.5 0 00-.73 0l-6.346 6.769a.5.5 0 00.365.846z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});