define("ember-svg-jar/inlined/responsive-design-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>responsive-design-1</title><path d=\"M2.625 13.375a1.752 1.752 0 001.75 1.75h6.5a1.752 1.752 0 001.75-1.75v-10a1.752 1.752 0 00-1.75-1.751h-1.5a.75.75 0 00-.75.749v1.5a.751.751 0 00.75.751h1.5a.25.25 0 01.25.25v7a.25.25 0 01-.25.25h-7.5a.75.75 0 00-.75.75z\"/><path d=\"M7.124 1.875a1.752 1.752 0 00-1.75-1.75h-3.5a1.752 1.752 0 00-1.75 1.75v7a1.752 1.752 0 001.75 1.75h3.5a1.752 1.752 0 001.75-1.75zm-1.5 5.5a.25.25 0 01-.25.25h-3.5a.25.25 0 01-.25-.25v-5.5a.25.25 0 01.25-.25h3.5a.25.25 0 01.25.25zM13.875 7.375a1 1 0 001 1h6.75a.25.25 0 01.25.25v7.5a.25.25 0 01-.25.25H7.374a1 1 0 00-1 1v1a2 2 0 002 2h5.25a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-2.25a1 1 0 000 2h7a1 1 0 000-2h-2.25a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h5.75a2 2 0 002-2v-10a2 2 0 00-2-2h-7a1 1 0 00-.999 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});