define("ember-svg-jar/inlined/lucide-podcast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"12\" cy=\"11\" r=\"1\"/><path d=\"M11 17a1 1 0 012 0c0 .5-.34 3-.5 4.5a.5.5 0 01-1 0c-.16-1.5-.5-4-.5-4.5zM8 14a5 5 0 118 0M17 18.5a9 9 0 10-10 0\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});