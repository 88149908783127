define("ember-svg-jar/inlined/landmark-chinese-pagoda-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-chinese-pagoda-2</title><path d=\"M23 24a1 1 0 000-2h-4.281a.208.208 0 01-.147-.354l.793-.792a.5.5 0 00.147-.354v-1a.5.5 0 00-.5-.5h-14a.5.5 0 00-.5.5v1a.5.5 0 00.146.354l.793.792A.208.208 0 015.3 22H1a1 1 0 000 2zM1.383 14.989A6.628 6.628 0 005.516 16a.5.5 0 01.5.5v.5a.5.5 0 00.5.5h11a.5.5 0 00.5-.5v-.5a.5.5 0 01.5-.5 6.628 6.628 0 004.133-1.009.751.751 0 00-.3-1.386h-.019a15.951 15.951 0 01-5.542-1.256.5.5 0 01-.276-.447v-.4a.5.5 0 00-.5-.5h-8a.5.5 0 00-.5.5v.4a.5.5 0 01-.277.447A16.032 16.032 0 011.688 13.6a.75.75 0 00-.305 1.389zM6.855 7.45a4.736 4.736 0 001.253.472.5.5 0 01.4.491V9a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-.587a.5.5 0 01.4-.491 4.737 4.737 0 001.255-.474.75.75 0 00-.132-1.39 10.807 10.807 0 01-4.22-1.958.5.5 0 00-.3-.1h-1a.5.5 0 00-.3.1 10.807 10.807 0 01-4.226 1.958.751.751 0 00-.13 1.392zM10.442 1.978a.5.5 0 01.5.165l.489.588a.752.752 0 001.056.1l.014-.012.343-.307a.5.5 0 01.766.121.752.752 0 001.178.152 1.459 1.459 0 000-2.061 1.52 1.52 0 00-2.052-.083l-.25.215a.5.5 0 01-.71-.06L11.338.27a.75.75 0 00-1.107-.05l-.5.5a.75.75 0 00.711 1.258z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});