define("ember-svg-jar/inlined/weather-app-sun-cloud-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-app-sun-cloud-location</title><path d=\"M8.25 3a1 1 0 001-1V1a1 1 0 00-2 0v1a1 1 0 001 1zM3.124 4.538a1 1 0 101.414-1.415l-.708-.707a1 1 0 10-1.414 1.415zM3 8.25a1 1 0 00-1-1H1a1 1 0 000 2h1a1 1 0 001-1zM14.085 2.417a1 1 0 00-1.415 0l-.708.707a1 1 0 00-.292.67.249.249 0 00.337.243 8.545 8.545 0 012.125-.492.252.252 0 00.219-.192 1 1 0 00-.266-.936z\"/><path d=\"M5.229 9.313a.249.249 0 00.124-.285 3 3 0 014-3.569.248.248 0 00.247-.039 8.443 8.443 0 011.375-.92.25.25 0 00.025-.427 5 5 0 00-7.407 5.984.25.25 0 00.4.1 7.069 7.069 0 011.236-.844zM13.327 19.143A.252.252 0 0013.1 19H8.5a3.5 3.5 0 11.448-6.971 1 1 0 001.116-.833 5 5 0 019.2-1.8.25.25 0 00.2.119 6.443 6.443 0 011.784.385.25.25 0 00.321-.321 7 7 0 00-13.23.26.249.249 0 01-.22.173A5.5 5.5 0 008.5 21h5.426a.25.25 0 00.212-.382 14.92 14.92 0 01-.811-1.475z\"/><path d=\"M24 16a5 5 0 00-10 0c0 3.171 4.457 7.664 4.646 7.854a.5.5 0 00.708 0C19.543 23.664 24 19.171 24 16zm-6.75 0A1.75 1.75 0 1119 17.75 1.752 1.752 0 0117.25 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});