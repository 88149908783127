define("ember-svg-jar/inlined/bear-head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bear-head</title><path d=\"M24 4.75a4 4 0 00-7.677-1.571 10.523 10.523 0 00-8.646 0 4 4 0 10-5.249 5.248 10.5 10.5 0 1019.143 0A3.989 3.989 0 0024 4.75zM16.25 9A1.25 1.25 0 1115 10.25 1.249 1.249 0 0116.25 9zm-6.136 3.5h3.772a.5.5 0 01.5.5A2.111 2.111 0 0112 15.005 2.111 2.111 0 019.614 13a.5.5 0 01.5-.5zM7.75 9a1.25 1.25 0 11-1.25 1.25A1.249 1.249 0 017.75 9zm7 10.967A3.5 3.5 0 0112 18.629a3.5 3.5 0 01-6.25-2.162.75.75 0 111.5 0 2 2 0 004 0 .75.75 0 111.5 0 2 2 0 004 0 .75.75 0 011.5 0 3.5 3.5 0 01-3.5 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});