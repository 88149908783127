define("ember-svg-jar/inlined/single-woman-actions-edit-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-edit-2</title><path d=\"M11.741 17.505a.248.248 0 00.061-.238l-1.6-5.834a4.2 4.2 0 00-1.224-2.1.5.5 0 00-.757.1L6.483 12.16a.5.5 0 01-.837 0L3.924 9.411a.5.5 0 00-.755-.1 4.179 4.179 0 00-1.241 2.107L.142 17.931a.5.5 0 00.478.626h2.3a.247.247 0 01.243.2l.922 4.85a.5.5 0 00.483.393h2.969a.5.5 0 00.484-.392l.922-4.85a.247.247 0 01.243-.2h1.435a.249.249 0 00.179-.077c.194-.205.721-.749.941-.976zM6.042 8.412a4.206 4.206 0 10-4.207-4.206 4.211 4.211 0 004.207 4.206zm0-1.979A2.223 2.223 0 013.905 4.81a.248.248 0 01.158-.3 4.63 4.63 0 001.8-1.128.247.247 0 01.353 0 4.615 4.615 0 001.8 1.128.248.248 0 01.157.3 2.222 2.222 0 01-2.131 1.623zM15.985 22a.494.494 0 00.7 0l6.435-6.44a2.6 2.6 0 00-.2-3.842 2.681 2.681 0 00-3.568.271l-6.338 6.337a.5.5 0 000 .7zM11.6 23.989l2.658-.531a.5.5 0 00.252-.835L12.388 20.5a.5.5 0 00-.835.253l-.532 2.658a.494.494 0 00.485.591.457.457 0 00.094-.013z\"/><path d=\"M11.741 17.505a.248.248 0 00.061-.238l-1.6-5.834a4.2 4.2 0 00-1.224-2.1.5.5 0 00-.757.1L6.483 12.16a.5.5 0 01-.837 0L3.924 9.411a.5.5 0 00-.755-.1 4.179 4.179 0 00-1.241 2.107L.142 17.931a.5.5 0 00.478.626h2.3a.247.247 0 01.243.2l.922 4.85a.5.5 0 00.483.393h2.969a.5.5 0 00.484-.392l.922-4.85a.247.247 0 01.243-.2h1.435a.249.249 0 00.179-.077c.194-.205.721-.749.941-.976zM6.042 8.412a4.206 4.206 0 10-4.207-4.206 4.211 4.211 0 004.207 4.206zm0-1.979A2.223 2.223 0 013.905 4.81a.248.248 0 01.158-.3 4.63 4.63 0 001.8-1.128.247.247 0 01.353 0 4.615 4.615 0 001.8 1.128.248.248 0 01.157.3 2.222 2.222 0 01-2.131 1.623zM15.985 22a.494.494 0 00.7 0l6.435-6.44a2.6 2.6 0 00-.2-3.842 2.681 2.681 0 00-3.568.271l-6.338 6.337a.5.5 0 000 .7zM11.6 23.989l2.658-.531a.5.5 0 00.252-.835L12.388 20.5a.5.5 0 00-.835.253l-.532 2.658a.494.494 0 00.485.591.457.457 0 00.094-.013z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});