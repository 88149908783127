define("ember-svg-jar/inlined/rb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rb</title><path d=\"M14 12.626h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H14a.875.875 0 000-1.75zM9.875 10.5A.876.876 0 009 9.626h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H9a.877.877 0 00.875-.876zM14 9.626h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H14a.875.875 0 000-1.75z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM10.942 15.443a.669.669 0 01-.884 0l-1.506-1.506a.25.25 0 00-.427.177V15a.625.625 0 01-1.25 0V9a.625.625 0 01.625-.624h1.375a2.21 2.21 0 012.238 1.9 2.127 2.127 0 01-1.628 2.293.246.246 0 00-.119.414l1.576 1.576a.625.625 0 010 .884zm4.707-3.281a2.13 2.13 0 01.412 1.867 2.174 2.174 0 01-2.137 1.6H12.5a.625.625 0 01-.625-.629V9a.625.625 0 01.625-.625h1.424a2.174 2.174 0 012.137 1.6 2.128 2.128 0 01-.412 1.866.25.25 0 000 .321z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});