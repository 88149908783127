define("ember-svg-jar/inlined/people-woman-12", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-12</title><path d=\"M22.247 19.311a7.1 7.1 0 00-3.056-1.157c-.3-.063-2.92-.711-2.92-.711a.5.5 0 00-.579.28 15.7 15.7 0 01-3.355 4.177.5.5 0 01-.682 0A15.683 15.683 0 018.3 17.725a.5.5 0 00-.578-.28s-2.61.646-2.913.709a7.107 7.107 0 00-3.057 1.157A4.529 4.529 0 000 22.875v.5a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-.5a4.527 4.527 0 00-1.753-3.564zM12.665 12.947a1.706 1.706 0 01-.811.178 1.8 1.8 0 01-.819-.18.75.75 0 00-.711 1.321 3.319 3.319 0 001.534.359 3.255 3.255 0 001.526-.36.75.75 0 10-.715-1.318zM10.991 10.144a.752.752 0 00-.053-1.06 2.094 2.094 0 00-2.637 0A.751.751 0 009.307 10.2a.628.628 0 01.625 0 .751.751 0 001.059-.056zM12.866 9.084a.751.751 0 001.006 1.116.628.628 0 01.625 0A.751.751 0 0015.5 9.084a2.094 2.094 0 00-2.634 0z\"/><path d=\"M6.9 17.167a2.1 2.1 0 001.339-1.209 6.482 6.482 0 003.661 1.667 6.541 6.541 0 003.746-1.738 2.117 2.117 0 001.377 1.28 4.753 4.753 0 004.361-1.567.5.5 0 00.1-.46.5.5 0 00-.327-.337c-1.373-.442-2.024-2.086-2.333-3.433a3.144 3.144 0 00.35-1.361A2.116 2.116 0 0018.7 8.57c.524-3.691-.252-8.445-6.8-8.445S4.577 4.875 5.1 8.57a2.112 2.112 0 00-.464 1.444 3.1 3.1 0 00.435 1.5c-.322 1.318-.973 2.864-2.3 3.29a.5.5 0 00-.328.337.506.506 0 00.1.46A4.747 4.747 0 006.9 17.167zm5-1.042c-1.032 0-3.83-1.49-4.207-3.738a1.746 1.746 0 00-1-1.306 1.277 1.277 0 01-.558-1.1.626.626 0 01.1-.439.752.752 0 00.406-.794 13.403 13.403 0 01-.137-1.119.255.255 0 01.066-.19.248.248 0 01.183-.08 6.671 6.671 0 004.886-1.757.248.248 0 01.345 0 6.759 6.759 0 004.979 1.769h.082a.247.247 0 01.185.08.25.25 0 01.066.19c-.029.354-.071.72-.136 1.106a.751.751 0 00.405.8c.025.013.111.148.106.439a1.279 1.279 0 01-.559 1.1 1.75 1.75 0 00-1 1.3c-.383 2.249-3.18 3.739-4.212 3.739z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});