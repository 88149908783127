define("ember-svg-jar/inlined/car-repair-24h-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-repair-24h-service</title><path d=\"M23.868 11.614A10.522 10.522 0 003.355 8.747a.252.252 0 01-.255.173L.933 8.753a.728.728 0 00-.715.387.749.749 0 00.057.811l3 4a.749.749 0 00.547.3h.053a.753.753 0 00.531-.22l3.5-3.5a.75.75 0 00-.473-1.278l-1.744-.134a.25.25 0 01-.213-.344 8.532 8.532 0 111.23 8.5 1 1 0 10-1.568 1.241 10.545 10.545 0 008.623 3.98 10.5 10.5 0 0010.107-10.882z\"/><path d=\"M8.875 16.75a.75.75 0 000 1.5 2.744 2.744 0 002.58-1.834.248.248 0 01.235-.166h3.37a.248.248 0 01.235.166 2.743 2.743 0 002.58 1.834.75.75 0 000-1.5 1.25 1.25 0 010-2.5.75.75 0 000-1.5 2.742 2.742 0 00-2.58 1.833.25.25 0 01-.235.167h-3.37a.249.249 0 01-.235-.167 2.743 2.743 0 00-2.58-1.833.75.75 0 000 1.5 1.25 1.25 0 010 2.5zM8.347 10.973a.75.75 0 00.53 1.281h3a.75.75 0 000-1.5h-.586a.25.25 0 01-.176-.427l.9-.906a2.058 2.058 0 00.607-1.465 2.2 2.2 0 00-4.293-.7.75.75 0 001.423.475.7.7 0 011.37.222.566.566 0 01-.167.4zM13.889 11.244h1.988a.25.25 0 01.25.25.75.75 0 001.5 0V6.5a.749.749 0 00-1.082-.673 6.272 6.272 0 00-3.4 4.535.751.751 0 00.739.878zm1.135-1.859a4.82 4.82 0 01.663-1.019.249.249 0 01.44.162v.966a.25.25 0 01-.25.25h-.627a.251.251 0 01-.226-.359z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});