define("ember-svg-jar/inlined/people-woman-graduate.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-graduate</title><path d=\"M12.494 22.338a.5.5 0 01-.738 0L7.783 18a.246.246 0 00-.233-.076 14.359 14.359 0 00-5.959 2.49 2.464 2.464 0 00-.966 1.976v1.11a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-1.106a2.465 2.465 0 00-.967-1.977 14.363 14.363 0 00-5.958-2.489.248.248 0 00-.233.076zM1.125 8.5a.381.381 0 00.016.1.25.25 0 01-.015.168A8.893 8.893 0 00.375 12.5a.75.75 0 00.75.75h2a.75.75 0 00.75-.75 8.893 8.893 0 00-.751-3.73.256.256 0 01-.015-.17.381.381 0 00.016-.1V5.68a.25.25 0 00-.195-.244L1.43 5.1a.25.25 0 00-.211.048.253.253 0 00-.094.2z\"/><path d=\"M12.347 7.475a1 1 0 01-.444 0L5.43 6a.25.25 0 00-.211.048.253.253 0 00-.094.2c0 5.461.45 8.439-2.153 9.275a.5.5 0 00-.327.337.5.5 0 00.1.46 3.8 3.8 0 002.921 1.119 5.351 5.351 0 001.317-.161c.039-.01.068-.026.1-.037a3.474 3.474 0 00.924-.4.249.249 0 01.272 0 6.974 6.974 0 007.645.032.248.248 0 01.273 0 3.474 3.474 0 00.927.41c.051.015.092.038.146.052a5.351 5.351 0 001.317.161 3.8 3.8 0 002.921-1.119.5.5 0 00.1-.46.5.5 0 00-.327-.337c-2.611-.839-2.153-3.806-2.153-9.335a.253.253 0 00-.094-.2A.25.25 0 0018.82 6zM12.125 9.5a19.2 19.2 0 004.688-.487.249.249 0 01.312.242V11a5 5 0 01-10 0V9.255a.249.249 0 01.312-.242 19.2 19.2 0 004.688.487z\"/><circle cx=\"14.125\" cy=\"11.25\" r=\"1\"/><circle cx=\"10.125\" cy=\"11.25\" r=\"1\"/><path d=\"M10.582 13.844a.639.639 0 00.317.72 2.376 2.376 0 00.435.179 2.99 2.99 0 00.867.126h.006a2.976 2.976 0 00.863-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2h-2a.639.639 0 00-.624.481zM.647 3.131a.5.5 0 00.367.357l11 2.5a.519.519 0 00.222 0l11-2.5a.5.5 0 00.367-.357.513.513 0 00.022-.131.5.5 0 00-.389-.488l-11-2.5a.519.519 0 00-.222 0l-11 2.5A.5.5 0 00.625 3a.513.513 0 00.022.131z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});