define("ember-svg-jar/inlined/ui-browser-slider-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ui-browser-slider-1</title><path d=\"M21.5 0h-19A2.5 2.5 0 000 2.5v19A2.5 2.5 0 002.5 24h19a2.5 2.5 0 002.5-2.5v-19A2.5 2.5 0 0021.5 0zM5.515 12.5a.5.5 0 01-.309.462.5.5 0 01-.545-.108l-2-2a.5.5 0 010-.707l2-2a.5.5 0 01.854.353zm2 10a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm4.5 0a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm4.5 0a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm4.853-11.646l-2 2a.5.5 0 01-.353.146.513.513 0 01-.192-.038.5.5 0 01-.308-.462v-4a.5.5 0 01.853-.353l2 2a.5.5 0 010 .707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});