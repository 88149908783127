define("ember-svg-jar/inlined/social-media-food-spotting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-food-spotting</title><path d=\"M19.5 3h-1.69l-.86-1.72A.5.5 0 0016.5 1h-3a.5.5 0 00-.45.28L12.19 3H10.5A2.5 2.5 0 008 5.5v6a2.5 2.5 0 002.5 2.5h9a2.5 2.5 0 002.5-2.5v-6A2.5 2.5 0 0019.5 3zM15 12a3.5 3.5 0 113.5-3.5A3.5 3.5 0 0115 12z\"/><circle cx=\"15\" cy=\"8.5\" r=\"1.5\"/><rect x=\"2\" y=\"3\" width=\"4\" height=\"11\" rx=\".5\" ry=\".5\"/><path d=\"M5.61 15.75H3a.75.75 0 00-.75.75v6a.75.75 0 001.5 0v-2.25H4.9a.75.75 0 000-1.5H3.75v-1.5h1.86a.75.75 0 000-1.5zM20 15.75a.75.75 0 00-.75.75v1.75a2.15 2.15 0 00-.43-.05c-.58 0-1.92.25-1.92 2.53s1.34 2.53 1.92 2.53a2 2 0 00.75-.15.73.73 0 00.43.15.75.75 0 00.75-.75v-6a.75.75 0 00-.75-.76zm-1.18 6c-.12 0-.42 0-.42-1s.31-1 .42-1 .43 0 .43 1-.31 1-.43 1zM8.64 18.2c-.58 0-1.92.25-1.92 2.53s1.35 2.53 1.92 2.53 1.92-.25 1.92-2.53-1.34-2.53-1.92-2.53zm0 3.55c-.12 0-.42 0-.42-1s.31-1 .42-1 .42 0 .42 1-.3 1-.42 1zM13.64 18.2c-.58 0-1.93.25-1.93 2.53s1.35 2.53 1.93 2.53 1.92-.25 1.92-2.53-1.34-2.53-1.92-2.53zm0 3.55c-.12 0-.43 0-.43-1s.31-1 .43-1 .42 0 .42 1-.31 1-.42 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});