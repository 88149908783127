define("ember-svg-jar/inlined/famous-character-yoda", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character-yoda</title><path d=\"M1 6c.184 0 .331-.008.454-.015.323-.017.326-.02 1.123.965.271.334.616.763 1.074 1.312a3.344 3.344 0 001.723 1.083c.094.025.25-.1.236-.175A6.357 6.357 0 015.5 8V6a6.462 6.462 0 01.425-2.309.3.3 0 00-.178-.281l-.3-.054A16.317 16.317 0 002.5 3C.918 3 .5 4.636.5 5.5A.5.5 0 001 6zM18.5 6v2a6.371 6.371 0 01-.1 1.138c-.016.09.125.234.214.211a3.351 3.351 0 001.739-1.087c.458-.549.8-.978 1.074-1.312.8-.984.8-.98 1.123-.965.123.007.27.015.454.015a.5.5 0 00.5-.5c0-.864-.418-2.5-2-2.5a16.317 16.317 0 00-2.945.356l-.3.054a.319.319 0 00-.164.316A6.474 6.474 0 0118.5 6zM6.659 15.719a.26.26 0 00-.247-.065c-.522.133-1 .281-1.45.438a.247.247 0 00-.078.421A8.372 8.372 0 017.861 23c0 .083 0 .165-.01.247a.248.248 0 00.263.253H11a.25.25 0 00.25-.25v-2.836a.251.251 0 00-.073-.177zM19.047 16.482a.281.281 0 00-.1-.422 16.96 16.96 0 00-1.356-.407.262.262 0 00-.246.066l-4.518 4.518a.251.251 0 00-.073.177v2.836a.25.25 0 00.25.25h2.765a.261.261 0 00.279-.25c0-.083-.008-.167-.008-.25a8.476 8.476 0 013.007-6.518zM22.533 17.917a11 11 0 00-1.366-.881.6.6 0 00-.321 0 4.691 4.691 0 00-.412.262 7 7 0 00-2.894 5.952.262.262 0 00.257.25H23a.5.5 0 00.5-.5v-3.106a2.467 2.467 0 00-.967-1.977zM3.555 17.366a6.055 6.055 0 00-.427-.282.688.688 0 00-.379 0 10.715 10.715 0 00-1.282.835A2.467 2.467 0 00.5 19.894V23a.5.5 0 00.5.5h5.1a.26.26 0 00.256-.249 6.946 6.946 0 00-2.801-5.885zM15.175 15.2a25.428 25.428 0 00-6.35 0 .251.251 0 00-.138.427l3.136 3.137a.252.252 0 00.354 0l3.136-3.137a.251.251 0 00-.138-.427zM12 13.5A5.507 5.507 0 0017.5 8V6a5.5 5.5 0 00-11 0v2a5.507 5.507 0 005.5 5.5zM14.5 6a.75.75 0 11-.75.75.75.75 0 01.75-.75zm-3.823 4.032l1.165.441a.467.467 0 00.317 0l1.164-.441a.5.5 0 01.354.936l-1.165.44a1.468 1.468 0 01-1.023 0l-1.166-.44a.5.5 0 01.354-.936zM9.5 6a.75.75 0 11-.75.75A.75.75 0 019.5 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});