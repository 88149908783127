define("ember-svg-jar/inlined/wedding-celebration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wedding-celebration</title><path d=\"M18.75 3H19a.5.5 0 01.5.5v.25a.75.75 0 001.5 0V3.5a.5.5 0 01.5-.5h.25a.75.75 0 000-1.5h-.25A.5.5 0 0121 1V.75a.75.75 0 00-1.5 0V1a.5.5 0 01-.5.5h-.25a.75.75 0 000 1.5zM6.75 18.5H5.5A.5.5 0 015 18v-1.25a.75.75 0 00-1.5 0V18a.5.5 0 01-.5.5H1.75a.75.75 0 000 1.5H3a.5.5 0 01.5.5v1.25a.75.75 0 001.5 0V20.5a.5.5 0 01.5-.5h1.25a.75.75 0 000-1.5zM15.166 2.985a4.074 4.074 0 00-6.561.042l-.653.83a.5.5 0 01-.664.11L6.4 3.4A4.073 4.073 0 00.182 5.484 3.953 3.953 0 001.687 9.9l7.184 5.3a.867.867 0 001.226-.2l5.08-7.336a3.955 3.955 0 00-.011-4.679zM20.258 16.624l-.533.184a.5.5 0 01-.618-.266l-.233-.513a2.748 2.748 0 00-4.29-1.086 2.667 2.667 0 00-.76 3.057l2.144 5.623a.585.585 0 00.77.331l5.562-2.306A2.667 2.667 0 0024 19a2.748 2.748 0 00-3.742-2.376z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});