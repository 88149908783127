define("ember-svg-jar/inlined/body-care-mask-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>body-care-mask-alternate</title><ellipse cx=\"11.997\" cy=\"16.75\" rx=\"3.5\" ry=\"2\"/><ellipse cx=\"7.997\" cy=\"8.999\" rx=\"2.5\" ry=\"1.5\"/><ellipse cx=\"15.997\" cy=\"8.999\" rx=\"2.5\" ry=\"1.5\"/><path d=\"M23.25 12C23.25 5.245 18.2 0 12 0S.75 5.245.75 12c0 6.658 4.976 12 11.25 12s11.25-5.344 11.25-12zm-4.478 6.849a.5.5 0 01-.732.027l-.583-.583a1 1 0 00-1.414 1.415l.369.368a.5.5 0 01-.109.79 8.731 8.731 0 01-8.606 0 .5.5 0 01-.109-.79l.369-.368a1 1 0 00-1.414-1.415l-.583.583a.5.5 0 01-.732-.027 10.183 10.183 0 01-2.321-5.017.5.5 0 01.493-.582h.85a1 1 0 000-2h-.918a.5.5 0 01-.5-.559 10.324 10.324 0 011.93-4.952.5.5 0 01.757-.057l.524.525a1 1 0 001.414-1.415l-.487-.486a.5.5 0 01.073-.768 8.757 8.757 0 019.914 0 .5.5 0 01.073.768l-.487.486a1 1 0 101.414 1.415l.524-.525a.5.5 0 01.757.057 10.324 10.324 0 011.927 4.952.5.5 0 01-.5.559h-.915a1 1 0 000 2h.85a.5.5 0 01.493.582 10.183 10.183 0 01-2.321 5.017z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});