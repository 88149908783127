define("ember-svg-jar/inlined/usb-type-c", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>usb-type-c</title><path d=\"M16.357 5.535a.251.251 0 01-.107-.205V1.742A1.747 1.747 0 0014.5 0h-4a1.747 1.747 0 00-1.75 1.742V5.33a.251.251 0 01-.107.205A1.481 1.481 0 008 6.751v6a1.5 1.5 0 001.5 1.5h1.75a.25.25 0 01.25.25v1.75a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V14.5a.25.25 0 01.25-.25h1.75a1.5 1.5 0 001.5-1.5v-6a1.481 1.481 0 00-.643-1.215zM14.75 5a.25.25 0 01-.25.25h-4a.25.25 0 01-.25-.25V1.742a.246.246 0 01.25-.242h4a.246.246 0 01.25.241z\"/><path d=\"M13.75 3.251A.75.75 0 0013 2.5h-1A.75.75 0 1012 4h1a.75.75 0 00.75-.749zM2.5 24a.75.75 0 00.75-.75v-4A.25.25 0 013.5 19a.75.75 0 000-1.5h-2a.75.75 0 000 1.5.25.25 0 01.25.25v4a.75.75 0 00.75.75zM22.5 19a.75.75 0 000-1.5 2.752 2.752 0 00-2.75 2.75v1A2.753 2.753 0 0022.5 24a.75.75 0 000-1.5 1.252 1.252 0 01-1.25-1.25v-1A1.251 1.251 0 0122.5 19zM10 17.5h-.5a.75.75 0 00-.75.75v5a.75.75 0 101.5 0v-1.062a.249.249 0 01.2-.244A2.244 2.244 0 0010 17.5zm.75 2.25a.25.25 0 11-.25-.25.25.25 0 01.25.251zM12.75 22.251A1.752 1.752 0 0014.5 24h1a.75.75 0 100-1.5h-1a.25.25 0 010-.5h1a.75.75 0 100-1.5h-1a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h1a.75.75 0 100-1.5h-1a1.752 1.752 0 00-1.75 1.75zM5.5 17.5a.75.75 0 00-.75.75v1.5a1.745 1.745 0 00.875 1.508.252.252 0 01.125.217v1.775a.75.75 0 001.5 0v-1.774a.251.251 0 01.125-.217 1.745 1.745 0 00.875-1.508v-1.5a.75.75 0 00-1.5 0v1.5c0 .275-.5.275-.5 0v-1.5a.75.75 0 00-.75-.751zM16.75 21.251a.75.75 0 00.75.75h1a.75.75 0 000-1.5h-1a.75.75 0 00-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});