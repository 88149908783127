define("ember-svg-jar/inlined/server-hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-hand</title><path d=\"M10 2.5A2.5 2.5 0 0012.5 5h9a2.5 2.5 0 000-5h-9A2.5 2.5 0 0010 2.5zm2.75 0A1.25 1.25 0 1114 3.75a1.249 1.249 0 01-1.25-1.25zM10 8.5a2.5 2.5 0 002.5 2.5h9a2.5 2.5 0 000-5h-9A2.5 2.5 0 0010 8.5zm2.75 0A1.25 1.25 0 1114 9.75a1.249 1.249 0 01-1.25-1.25zM11.2 16.266a2.463 2.463 0 01.114.329.252.252 0 00.134.16A2.45 2.45 0 0012.5 17h9a2.5 2.5 0 000-5h-9a2.5 2.5 0 00-2.5 2.5 1.662 1.662 0 00.016.189.253.253 0 00.071.145 6.22 6.22 0 011.113 1.432zm1.55-1.766A1.25 1.25 0 1114 15.75a1.249 1.249 0 01-1.25-1.25zM3.5 23h10.764a2.239 2.239 0 002.236-2.237V20.5a1 1 0 00-1-1H6.375a.625.625 0 010-1.25H8.9a1 1 0 00.919-1.394A2.234 2.234 0 007.764 15.5H3.5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5zM1 24a.981.981 0 00.808-.443A.972.972 0 002 23v-7.25a.99.99 0 00-.267-.669A.993.993 0 000 15.75V23a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});