define("ember-svg-jar/inlined/spider-hang", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>spider-hang</title><path d=\"M9.5 17a3.527 3.527 0 01.24-1.3 4.381 4.381 0 01-1.419-1.932 4.006 4.006 0 01-.649-1.777l-.178-1.6a1 1 0 00-1.988.22l.178 1.605a6.014 6.014 0 002.677 4.356l-.5.142A3.992 3.992 0 005.533 18.5l-.89 1.484a1 1 0 00.342 1.372 1.011 1.011 0 001.372-.342l.891-1.485a1.991 1.991 0 011.165-.893l.161-.046a4.026 4.026 0 00-.706 1.758l-.358 2.51a1 1 0 001.98.284l.359-2.51a2.009 2.009 0 01.5-1.068A3.051 3.051 0 019.5 17zM19.357 19.985l-.89-1.484a3.994 3.994 0 00-2.332-1.788l-.5-.142a6.007 6.007 0 002.677-4.356l.179-1.605a1 1 0 00-1.988-.22l-.179 1.6a3.982 3.982 0 01-.649 1.777A4.365 4.365 0 0114.26 15.7a3.557 3.557 0 01.24 1.3 3.056 3.056 0 01-.853 2.564 2 2 0 01.5 1.068l.36 2.51a1.037 1.037 0 001.132.848 1 1 0 00.848-1.132l-.359-2.509a4.036 4.036 0 00-.7-1.758l.16.046a1.99 1.99 0 011.166.893l.891 1.485a1.011 1.011 0 001.372.342 1 1 0 00.34-1.372zM13 7.22V1a1 1 0 00-2 0v6.22a2.383 2.383 0 012 0zM13.5 17a2.241 2.241 0 00-.66-1.657A3.454 3.454 0 0015 12c0-2.5-2-4-3-4s-3 1.5-3 4a3.456 3.456 0 002.159 3.344A2.236 2.236 0 0010.5 17c0 2 .671 2 1.5 2s1.5 0 1.5-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});