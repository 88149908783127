define("ember-svg-jar/inlined/outdoors-flashlight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-flashlight</title><path d=\"M23.622 8.279a1.29 1.29 0 000-1.823L17.544.378a1.29 1.29 0 00-1.823 0L12.6 3.5a2.083 2.083 0 000 2.941l4.961 4.959a2.081 2.081 0 002.94 0zM11.609 7.6a.5.5 0 00-.707 0L9.546 8.951a.5.5 0 01-.707 0l-.492-.492a1 1 0 00-1.414 1.414l.492.493a.5.5 0 010 .707L.609 17.888a2.079 2.079 0 000 2.94l2.563 2.563a2.081 2.081 0 002.94 0L16.406 13.1a.5.5 0 000-.708z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});