define("ember-svg-jar/inlined/science-fiction-ufo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-fiction-ufo</title><path d=\"M20.388 14.743a1 1 0 00-1.934.512l2.114 8a1 1 0 101.933-.512zM4.908 14.032a1 1 0 00-1.223.711l-2.117 8a1 1 0 101.933.512l2.118-8a1 1 0 00-.711-1.223zM14 20a1 1 0 000-2h-3a1 1 0 000 2h.5v.138l-1.3 1.768a1 1 0 001.61 1.187l.71-.962.691.9a1 1 0 001.588-1.217L13.5 20.128V20zM14 16a1.5 1.5 0 10-1.5 1.5A1.5 1.5 0 0014 16zM23.476 9.5a.535.535 0 00.4-.173.487.487 0 00.123-.4A4.13 4.13 0 0019.851 5.5H4.152A4.133 4.133 0 00.005 8.928a.488.488 0 00.123.4.535.535 0 00.4.173h1.11a.25.25 0 01.234.161 5.169 5.169 0 009.664 0 .25.25 0 01.234-.162h.46a.25.25 0 01.234.161 5.169 5.169 0 009.664 0 .25.25 0 01.234-.161zM6.7 11a3.2 3.2 0 01-2.385-1.088.249.249 0 01.19-.412H8.9a.249.249 0 01.19.412A3.2 3.2 0 016.7 11zm10.6 0a3.2 3.2 0 01-2.389-1.088.249.249 0 01.189-.412h4.4a.25.25 0 01.19.412A3.2 3.2 0 0117.3 11zM12.034 0A8.218 8.218 0 005.1 3.623.251.251 0 005.318 4h13.434a.25.25 0 00.215-.377A8.218 8.218 0 0012.034 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});