define("ember-svg-jar/inlined/skirt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skirt</title><path d=\"M8.179 5.534A.5.5 0 007.68 5H4.5a.5.5 0 00-.486.382L.025 21.862a1 1 0 00.4 1.036 5.593 5.593 0 003.1 1.1 5.407 5.407 0 003.315-1.085.5.5 0 00.2-.364zM14.314 5.466a.5.5 0 00-.5-.466h-3.628a.5.5 0 00-.5.466L8.541 22.521a.5.5 0 00.2.435A5.423 5.423 0 0012 24a5.422 5.422 0 003.259-1.044.5.5 0 00.2-.435zM23.974 21.854L19.986 5.382A.5.5 0 0019.5 5h-3.18a.5.5 0 00-.5.534l1.144 17.015a.5.5 0 00.2.364A5.407 5.407 0 0020.476 24a5.593 5.593 0 003.1-1.1 1 1 0 00.398-1.046zM20 1.105A1.107 1.107 0 0018.9 0H5.106A1.107 1.107 0 004 1.105V3a.5.5 0 00.5.5h15A.5.5 0 0020 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});