define("ember-svg-jar/inlined/conversation-smile-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>conversation-smile-type</title><path d=\"M9.5 12.5a3 3 0 013-3h7.75a.25.25 0 00.25-.25V1.5A1.5 1.5 0 0019 0H1.5A1.5 1.5 0 000 1.5V16a1.5 1.5 0 001.5 1.5h3V21a.5.5 0 00.309.462A.508.508 0 005 21.5a.5.5 0 00.354-.147L9.207 17.5h.043a.25.25 0 00.25-.25zM2.97 9.969a.751.751 0 011.06 0 5.2 5.2 0 003.039 1.5.75.75 0 11-.191 1.488A6.691 6.691 0 012.97 11.03a.75.75 0 010-1.061zM7.75 6.5a.75.75 0 01-1.5 0v-2a.75.75 0 011.5 0zm6 0a.75.75 0 01-1.5 0v-2a.75.75 0 011.5 0z\"/><path d=\"M22.5 11h-10a1.5 1.5 0 00-1.5 1.5v7a1.5 1.5 0 001.5 1.5h3.833l3.867 2.9a.5.5 0 00.8-.4V21h1.5a1.5 1.5 0 001.5-1.5v-7a1.5 1.5 0 00-1.5-1.5zm-8 5a1 1 0 111 1 1 1 0 01-1-1zm5 1a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});