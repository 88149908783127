define("ember-svg-jar/inlined/cell-border-center", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cell-border-center</title><path d=\"M1 9a1 1 0 001-1V6a1 1 0 00-2 0v2a1 1 0 001 1zM1 19a1 1 0 001-1v-2a1 1 0 00-2 0v2a1 1 0 001 1zM23 5a1 1 0 00-1 1v2a1 1 0 002 0V6a1 1 0 00-1-1zM23 15a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zM8 22H6a1 1 0 000 2h2a1 1 0 000-2zM18 22h-2a1 1 0 000 2h2a1 1 0 000-2zM3 22h-.75a.249.249 0 01-.25-.25V21a1 1 0 00-2 0v1a2 2 0 002 2h1a1 1 0 000-2zM23 20a1 1 0 00-1 1v.75a.249.249 0 01-.25.25H21a1 1 0 000 2h1a2 2 0 002-2v-1a1 1 0 00-1-1zM18 2a1 1 0 000-2h-2a1 1 0 000 2zM8 2a1 1 0 000-2H6a1 1 0 000 2zM22 0h-1a1 1 0 000 2h.75a.25.25 0 01.25.25V3a1 1 0 002 0V2a2 2 0 00-2-2zM1 4a1 1 0 001-1v-.75A.25.25 0 012.25 2H3a1 1 0 000-2H2a2 2 0 00-2 2v1a1 1 0 001 1zM23 11h-9.75a.249.249 0 01-.25-.25V1a1 1 0 00-2 0v9.75a.249.249 0 01-.25.25H1a1 1 0 000 2h9.75a.25.25 0 01.25.25V23a1 1 0 002 0v-9.75a.25.25 0 01.25-.25H23a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});