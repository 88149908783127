define("ember-svg-jar/inlined/people-man-8", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-8</title><path d=\"M21.3 18.181h-.011l-2.957-.96a.114.114 0 01-.079-.109.751.751 0 00-.542-.721l-1.315-.38a.749.749 0 00-.59.075l-3.684 2.18a.249.249 0 01-.253 0L8.148 16.1a.744.744 0 00-.585-.072l-1.35.39a.751.751 0 00-.542.721.107.107 0 01-.073.1l-2.884.936h-.011A4.174 4.174 0 000 22.087v1.663a.25.25 0 00.25.25h23.5a.25.25 0 00.25-.25v-1.663a4.174 4.174 0 00-2.7-3.906zm-4.728-.561a.25.25 0 01.181.24v2.207a.253.253 0 01-.1.2.249.249 0 01-.224.035l-2.582-.835a.25.25 0 01-.051-.453l2.404-1.421a.251.251 0 01.2-.025zm-6.324 1.435a.251.251 0 01-.044.453l-2.7.941a.25.25 0 01-.333-.236v-2.322a.251.251 0 01.181-.24l.217-.063a.251.251 0 01.2.024z\"/><circle cx=\"14\" cy=\"9\" r=\"1\"/><circle cx=\"10\" cy=\"9\" r=\"1\"/><path d=\"M12 16a7.008 7.008 0 007-7V7A7 7 0 005 7v2a7.008 7.008 0 007 7zM7 7.265a.25.25 0 01.247-.25 5.936 5.936 0 004.569-1.8.25.25 0 01.369 0 5.935 5.935 0 004.568 1.8.25.25 0 01.247.25V9A5 5 0 017 9z\"/><path d=\"M10.384 11.485a.626.626 0 00.311.7 2.281 2.281 0 00.426.176 2.935 2.935 0 00.849.124h.006a2.9 2.9 0 00.846-.123 2.321 2.321 0 00.427-.177.627.627 0 00-.3-1.177h-1.958a.625.625 0 00-.607.477z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});