define("ember-svg-jar/inlined/server-upload-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-upload-1</title><path d=\"M9.375 17.375a7.928 7.928 0 011.377-4.47.5.5 0 00-.408-.78H2.772a2.6 2.6 0 00-2.634 2.239 2.5 2.5 0 002.487 2.761h5.5a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25h-3a.75.75 0 000 1.5h4.637a.5.5 0 00.446-.714 7.912 7.912 0 01-.833-3.536zm-6.25-2.75a1.25 1.25 0 111.25 1.25 1.249 1.249 0 01-1.25-1.25zm5 1.25a1.25 1.25 0 111.25-1.25 1.25 1.25 0 01-1.25 1.25zM1.125 20.125a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM.125 2.625a2.5 2.5 0 002.5 2.5h13a2.5 2.5 0 000-5h-13a2.5 2.5 0 00-2.5 2.5zm3 0a1.25 1.25 0 111.25 1.25 1.249 1.249 0 01-1.25-1.25zm3.75 0a1.25 1.25 0 111.25 1.25 1.249 1.249 0 01-1.25-1.25zM17.587 9.378a.5.5 0 00.511-.445 2.49 2.49 0 00-2.473-2.808H2.772A2.6 2.6 0 00.138 8.364a2.5 2.5 0 002.487 2.761h9.605a.494.494 0 00.3-.1 7.956 7.956 0 015.057-1.647zm-8.212-.753a1.25 1.25 0 11-1.25-1.25 1.25 1.25 0 011.25 1.25zm-3.75 0a1.25 1.25 0 11-1.25-1.25 1.25 1.25 0 011.25 1.25zM10.875 17.375a6.5 6.5 0 106.5-6.5 6.508 6.508 0 00-6.5 6.5zm8.951-.283a.5.5 0 01-.451.283h-.75a.25.25 0 00-.25.25v2.75a1 1 0 01-2 0v-2.75a.25.25 0 00-.25-.25h-.75a.5.5 0 01-.391-.813l2-2.5a.52.52 0 01.782 0l2 2.5a.5.5 0 01.06.53z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});