define("ember-svg-jar/inlined/pollution-o2-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pollution-o2-message</title><path d=\"M17.523 18.359A2.18 2.18 0 0018 17a1.75 1.75 0 00-3.5 0 .75.75 0 001.5 0c0-.275.5-.275.5 0a.68.68 0 01-.148.422l-1.687 2.11a.749.749 0 00.585 1.218h2a.75.75 0 000-1.5.211.211 0 01-.165-.343zM14.75 8.75a3 3 0 00-6 0v4a3 3 0 006 0zm-2 4a1 1 0 01-2 0v-4a1 1 0 012 0z\"/><path d=\"M12 .75C5.383.75 0 5.348 0 11a9.305 9.305 0 002.776 6.563.5.5 0 01.083.609L1.2 21.012a1.488 1.488 0 001.873 2.117l5.267-2.256a.5.5 0 01.32-.026 13.934 13.934 0 003.059.4.975.975 0 00.994-1.078 1.038 1.038 0 00-1.008-.924 11.755 11.755 0 01-2.991-.453 1.021 1.021 0 00-.685.038l-3.622 1.552a.249.249 0 01-.286-.064.252.252 0 01-.029-.292l1.1-1.891a.993.993 0 00-.22-1.269A7.559 7.559 0 012 11c0-4.549 4.486-8.25 10-8.25S22 6.451 22 11a7.167 7.167 0 01-1.9 4.842.981.981 0 00.008 1.3 1 1 0 001.5-.01A9.268 9.268 0 0024 11C24 5.348 18.617.75 12 .75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});