define("ember-svg-jar/inlined/video-game-angry-birds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-angry-birds</title><circle cx=\"15\" cy=\"11.502\" r=\"1\"/><circle cx=\"9\" cy=\"11.502\" r=\"1\"/><path d=\"M12 3a6.561 6.561 0 00-.784.048.5.5 0 01-.531-.327L9.94.662a1 1 0 10-1.88.68l.725 2.008a.25.25 0 01-.368.3L6.03 2.155a1 1 0 10-1.06 1.7l1.437.9a.5.5 0 01.085.781A16.016 16.016 0 002 16c0 5.01 3.738 8 10 8s10-2.988 10-8c0-5.232-4.2-13-10-13zm4.29 15.849A18.278 18.278 0 0112 20.5a18.278 18.278 0 01-4.29-1.651.5.5 0 01-.09-.844C8.714 17.154 10.968 15.5 12 15.5s3.286 1.652 4.38 2.505a.5.5 0 01-.09.844zm1.11-6.644a2.45 2.45 0 01-1.653 1.688 2.509 2.509 0 01-3.148-1.68.625.625 0 00-1.206 0 2.509 2.509 0 01-3.148 1.68A2.45 2.45 0 016.6 12.205 2.509 2.509 0 017.855 9.28a.251.251 0 00.036-.423L7.55 8.6a.75.75 0 11.9-1.2l2.95 2.215a1 1 0 001.2 0L15.55 7.4a.75.75 0 11.9 1.2l-.341.255a.254.254 0 00-.1.221.251.251 0 00.135.2 2.509 2.509 0 011.256 2.929z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});