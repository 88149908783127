define("ember-svg-jar/inlined/sea-transport-kayak", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sea-transport-kayak</title><path d=\"M18.425 8.7a1.249 1.249 0 00-.425.936v1.469a.25.25 0 01-.25.25h-2.627a.252.252 0 00-.19.087.255.255 0 00-.057.2 3.481 3.481 0 01-.97 3.009l-.855.855a.249.249 0 00.088.41 2.458 2.458 0 011.361 1.239 1.173 1.173 0 00.982.585 1.778 1.778 0 001.4-.8 2.412 2.412 0 012.137-1.2 2.507 2.507 0 012.127 1.18 2.869 2.869 0 00.754.686.248.248 0 00.286-.03 5.244 5.244 0 00-2.78-9.17 1.242 1.242 0 00-.981.294zM1.7 17.435a.249.249 0 00.3.028 4.252 4.252 0 00.916-.8 2.547 2.547 0 011.949-.935 2.507 2.507 0 012.126 1.178 2.016 2.016 0 001.409.834 1.946 1.946 0 00.909-.244.25.25 0 00.011-.435 1.955 1.955 0 01-.368-.287l-2.117-2.121a2 2 0 010-2.828l.043-.043a.25.25 0 00-.176-.427h-.453a.251.251 0 01-.25-.25V9.636a1.249 1.249 0 00-1.4-1.24 5.223 5.223 0 00-2.9 9.039z\"/><path d=\"M9.84 15.89a.748.748 0 001.06 0l2.121-2.121a2.254 2.254 0 00.545-2.3.249.249 0 01.06-.255l4.9-4.9a.25.25 0 01.353 0l.116.117a1 1 0 001.414-1.414l-2-2A1 1 0 1017 4.433l.116.116a.25.25 0 010 .353l-5.074 5.069a.127.127 0 01-.118.037 2.279 2.279 0 00-2.082.577l-2.123 2.123a.75.75 0 000 1.061zM23.991 20.074a1 1 0 00-1.123-.859c-1.625.213-2.738-1.5-2.746-1.512a1.047 1.047 0 00-1.715.022 3.282 3.282 0 01-2.677 1.515 2.723 2.723 0 01-2.336-1.44 1.042 1.042 0 00-1.716-.156 4.2 4.2 0 01-3.024 1.596 3.462 3.462 0 01-2.685-1.54 1.042 1.042 0 00-1.635-.093c-.016.019-1.522 1.865-3.2 1.646a1 1 0 00-.264 1.985 4.113 4.113 0 00.549.036A5.6 5.6 0 004.9 19.852a.249.249 0 01.327.006 5.089 5.089 0 003.427 1.382 5.679 5.679 0 003.556-1.321.251.251 0 01.331.016 4.469 4.469 0 003.189 1.3 5.024 5.024 0 003.379-1.3.248.248 0 01.333 0 4.556 4.556 0 003.69 1.26 1 1 0 00.859-1.121z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});