define("ember-svg-jar/inlined/monetization-dollar-bill-magnet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monetization-dollar-bill-magnet</title><path d=\"M13.7 7.677H9.091a.97.97 0 100 1.939h1.851a.251.251 0 01.25.25v.316a.97.97 0 001.94 0v-.316a.251.251 0 01.25-.25h.318a2.5 2.5 0 100-5.01h-3.074a.565.565 0 110-1.13h4.606a.97.97 0 000-1.94h-1.85a.25.25 0 01-.25-.25V.97a.97.97 0 00-1.94 0v.316a.25.25 0 01-.25.25h-.316a2.505 2.505 0 100 5.01H13.7a.566.566 0 010 1.131zM18.061 14.061a1.7 1.7 0 00-1.7-1.7h-1.94a1.7 1.7 0 00-1.7 1.7v4.017c0 .226-.035 1.351-.727 1.351s-.727-1.125-.727-1.351v-4.017a1.7 1.7 0 00-1.7-1.7H7.636a1.7 1.7 0 00-1.7 1.7v4.017A5.674 5.674 0 0012 24a5.674 5.674 0 006.061-5.922zm-3.879 0a.244.244 0 01.242-.243h1.94a.244.244 0 01.242.243v1.447a.25.25 0 01-.25.25h-1.924a.25.25 0 01-.25-.25zm-6.788 1.447v-1.447a.244.244 0 01.242-.243h1.94a.244.244 0 01.242.243v1.447a.25.25 0 01-.25.25H7.644a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});