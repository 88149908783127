define("ember-svg-jar/inlined/rating-hotel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rating-hotel</title><path d=\"M17.048 5.269a.5.5 0 01.173.609l-.8 1.834a.524.524 0 00.746.655L19.248 7.2a.5.5 0 01.49 0l2.08 1.17a.524.524 0 00.746-.655l-.8-1.834a.5.5 0 01.173-.609l1.4-.976a.49.49 0 00-.346-.855h-1.654a.5.5 0 01-.467-.323L19.965.724a.531.531 0 00-.943 0l-.907 2.391a.5.5 0 01-.468.323h-1.655a.489.489 0 00-.343.855zM1.742 2.188h6.5a1 1 0 000-2h-6.5a1 1 0 000 2zM1.742 5.688h6.5a1 1 0 100-2h-6.5a1 1 0 000 2zM.742 8.188a1 1 0 001 1h6.5a1 1 0 000-2h-6.5a1 1 0 00-1 1z\"/><circle cx=\"11.242\" cy=\"1.188\" r=\"1.125\"/><circle cx=\"11.242\" cy=\"4.688\" r=\"1.125\"/><circle cx=\"11.242\" cy=\"8.188\" r=\"1.125\"/><circle cx=\"5.5\" cy=\"16.438\" r=\"2\"/><path d=\"M8.5 14.279v3.659a.5.5 0 00.5.5h10.667a.834.834 0 00.623-1.387c-2.057-2.317-6.187-3.742-10.981-3.6a.833.833 0 00-.809.828z\"/><path d=\"M0 22.938a1 1 0 002 0v-.5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v.5a1 1 0 102 0v-9a1 1 0 00-2 0v5.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-7a1 1 0 00-2 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});