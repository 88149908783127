define("ember-svg-jar/inlined/phone-actions-24-support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-24-support</title><path d=\"M17.169 16.163a2.5 2.5 0 00-3.536 0l-.386.385a46.9 46.9 0 01-5.789-5.789l.386-.386a2.5 2.5 0 000-3.535L5.721 4.717a2.556 2.556 0 00-3.535 0L1.022 5.881a3.507 3.507 0 00-.441 4.4 46.945 46.945 0 0013.141 13.145 3.549 3.549 0 004.4-.443l1.164-1.164a2.5 2.5 0 000-3.535zM23 .007a5.694 5.694 0 00-5.69 5.687v.938a1 1 0 001 1H22v.875a1 1 0 102 0v-7.5a1 1 0 00-1-1zm-1 5.625h-2.689A3.7 3.7 0 0122 2.144z\"/><path d=\"M11.279 4.35a1 1 0 001-1 1.344 1.344 0 012.661-.269c.136.674-.032.892-.952 1.7l-3.371 2.976a1 1 0 00.662 1.75h5.156a1 1 0 000-2h-2.511l1.385-1.223c.924-.812 1.97-1.734 1.591-3.6a3.344 3.344 0 00-6.621.668 1 1 0 001 .998z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});