define("ember-svg-jar/inlined/currency-yuan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-yuan</title><path d=\"M19.263.209a1.5 1.5 0 00-2.054.528l-4.994 8.45a.249.249 0 01-.43 0L6.791.737a1.5 1.5 0 00-2.582 1.526l5.826 9.86a.25.25 0 01-.215.377H7.5a1.5 1.5 0 000 3h2.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H7.5a1.5 1.5 0 000 3h2.75a.25.25 0 01.25.25v2.25a1.5 1.5 0 003 0v-2.25a.25.25 0 01.25-.25h2.75a1.5 1.5 0 000-3h-2.75a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h2.75a1.5 1.5 0 000-3h-2.32a.25.25 0 01-.215-.377l5.826-9.86a1.5 1.5 0 00-.528-2.054z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});