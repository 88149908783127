define("ember-svg-jar/inlined/flyer-taken", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flyer-taken</title><path d=\"M23.164 12.119a.25.25 0 00.336-.235V2a1 1 0 00-1-1h-21a1 1 0 00-1 1v9.689a.25.25 0 00.368.221 3.452 3.452 0 014.2.733.249.249 0 00.184.082.252.252 0 00.185-.082 3.451 3.451 0 015.131 0 .249.249 0 00.184.082.252.252 0 00.185-.082 3.464 3.464 0 015.353.257.251.251 0 00.179.1.463.463 0 00.419-.18 2 2 0 013.228 0 .489.489 0 00.772 0A2 2 0 0122.5 12a1.921 1.921 0 01.664.119zM8.5 7.5a1 1 0 01-1 1h-3a1 1 0 01-1-1v-3a1 1 0 011-1h3a1 1 0 011 1zm10-1.25H12a.75.75 0 010-1.5h6.5a.75.75 0 010 1.5zM.5 22.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V15a2 2 0 00-2-2 2.04 2.04 0 00-.336.028A2.074 2.074 0 00.5 15.107zM9.5 23a.5.5 0 00.5-.5V15a2 2 0 00-2.336-1.972A1.962 1.962 0 006 15v7.5a.5.5 0 00.5.5z\"/><path d=\"M15 23a.5.5 0 00.5-.5V15a2 2 0 00-2.336-1.972A1.962 1.962 0 0011.5 15v7.5a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});