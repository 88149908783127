define("ember-svg-jar/inlined/day-sunrise-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>day-sunrise-2</title><path d=\"M1.744 14.75h1.5a1 1 0 000-2h-1.5a1 1 0 000 2zM5.1 8.27a1 1 0 101.414-1.414L5.453 5.8a1 1 0 00-1.414 1.409zM11.994 6a1 1 0 001-1V3.5a1 1 0 00-2 0V5a1 1 0 001 1zM19.744 13.75a1 1 0 001 1h1.5a1 1 0 000-2h-1.5a1 1 0 00-1 1zM18.182 8.563a1 1 0 00.707-.293l1.06-1.061A1 1 0 1018.535 5.8l-1.06 1.061a1 1 0 00.707 1.707z\"/><path d=\"M6.31 17.834a1 1 0 101.623-1.168 5 5 0 118.123 0 1 1 0 101.623 1.168 7 7 0 10-11.369 0zM22.994 19.5H1.006a1 1 0 000 2h21.988a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});