define("ember-svg-jar/inlined/common-file-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-horizontal</title><path d=\"M22 19.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-15a.5.5 0 01.5-.5h14.465a.5.5 0 01.353.146l4.536 4.536a.5.5 0 01.146.353zm2-11.086a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0017.586 2H2a2 2 0 00-2 2v16a2 2 0 002 2h20a2 2 0 002-2V8.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});