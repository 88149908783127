define("ember-svg-jar/inlined/temperature-thermometer-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>temperature-thermometer-up</title><path d=\"M11 4.5a4.5 4.5 0 00-9 0v9.438a.249.249 0 01-.061.162A5.946 5.946 0 00.5 18a6 6 0 1010.56-3.9.248.248 0 01-.06-.162zM6.5 22a4 4 0 01-4-4 3.964 3.964 0 011.2-2.856 1 1 0 00.3-.714V4.5a2.5 2.5 0 015 0v9.93a1 1 0 00.3.714A4 4 0 016.5 22z\"/><path d=\"M7.611 16.337a.248.248 0 01-.111-.208V8.5a1 1 0 00-2 0v7.629a.25.25 0 01-.111.208 2 2 0 102.222 0zM23.334 7.361l-3.667-4.887a.866.866 0 00-1.334 0l-3.667 4.887a.832.832 0 00.667 1.332h1.917a.25.25 0 01.25.25V19.5a1.5 1.5 0 003 0V8.944a.25.25 0 01.25-.25h1.917a.833.833 0 00.667-1.333z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});