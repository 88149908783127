define("ember-svg-jar/inlined/messages-bubble-disable-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-disable-1-alternate</title><path d=\"M9.774 15.478a.25.25 0 00-.393-.262.915.915 0 00-.088.077L7 17.586V15.56a1 1 0 00-.664-.942A6.493 6.493 0 018.5 2h7A6.508 6.508 0 0122 8.5a6.426 6.426 0 01-.308 1.956.251.251 0 00.105.287 8 8 0 011.212.937.247.247 0 00.226.062.251.251 0 00.178-.152A8.424 8.424 0 0024 8.5 8.51 8.51 0 0015.5 0h-7A8.492 8.492 0 005 16.232V20a1 1 0 001.707.707l2.742-2.742a.251.251 0 00.073-.185 8.092 8.092 0 01.252-2.302z\"/><path d=\"M19 11.171A6.512 6.512 0 0011.168 19 6.417 6.417 0 0016 23.833a6.757 6.757 0 001.489.167 6.5 6.5 0 006.342-8A6.422 6.422 0 0019 11.171zm-3.61 10.312a.252.252 0 01-.057-.4l5.754-5.753a.251.251 0 01.4.057 4.509 4.509 0 01-6.093 6.093zm4.225-7.96a.249.249 0 01.057.4l-5.746 5.747a.25.25 0 01-.4-.066 4.442 4.442 0 01.8-5.281 4.613 4.613 0 015.286-.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});