define("ember-svg-jar/inlined/bluetooth-transfer.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bluetooth-transfer</title><path d=\"M10.5 2h-8A2.5 2.5 0 000 4.5v15A2.5 2.5 0 002.5 22h8a2.5 2.5 0 002.5-2.5v-15A2.5 2.5 0 0010.5 2zm.5 14.5a1 1 0 01-1 1H3a1 1 0 01-1-1v-11a1 1 0 011-1h7a1 1 0 011 1zM23.633 13.345l-2.566-2.1 2.565-2.091a1 1 0 00-.016-1.562l-4-3.131A1 1 0 0018 5.25v3.488l-2.341-1.915a1 1 0 10-1.265 1.549l3.511 2.872-3.537 2.883a1 1 0 101.264 1.55L18 13.746v3.5a1.009 1.009 0 001 1 .993.993 0 00.616-.213l4-3.13a1 1 0 00.017-1.562zM20 7.3l1.4 1.1L20 9.536zm0 7.9v-2.24l1.4 1.14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});