define("ember-svg-jar/inlined/hospital-board", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hospital-board</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 21.25A9.25 9.25 0 1121.25 12 9.26 9.26 0 0112 21.25z\"/><path d=\"M12 4.25A7.75 7.75 0 1019.75 12 7.759 7.759 0 0012 4.25zM16.5 13a.5.5 0 01-.5.5h-2a.5.5 0 00-.5.5v2a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a.5.5 0 00-.5-.5H8a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 00.5-.5V8a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2a.5.5 0 00.5.5h2a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});