define("ember-svg-jar/inlined/search-engine-yahoo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>search-engine-yahoo</title><path d=\"M19.22 9h2a.5.5 0 00.46-.3l.89-2a.5.5 0 00-.46-.7h-8.93a.5.5 0 00-.49.58l.35 2a.5.5 0 00.49.42h1.13l-2.73 2.4-.93-1.22c-.73-1-1.61-2.1-2.38-3.18h1.88a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5H.5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h2.86c1.06 1 4.3 5.09 5.64 6.83v3.26c-1 .2-2.35.45-2.65.47a.5.5 0 00-.46.44l-.17 1.44a.5.5 0 00.5.56h9.28a.5.5 0 00.41-.21l1-1.44a.5.5 0 00-.31-.79l-2.6-.48v-3.23z\"/><path d=\"M23.9 10.5a.5.5 0 00-.38-.2c-.24 0-2.61-.24-2.9-.29a.51.51 0 00-.41.11.5.5 0 00-.18.39v6.39a.5.5 0 00.46.5l1.38.11a.5.5 0 00.48-.37l1.6-6.2a.5.5 0 00-.05-.44zM21.66 18.12l-1-.09a.51.51 0 00-.38.13.5.5 0 00-.16.37v.9a.5.5 0 00.46.5l.86.07a.5.5 0 00.49-.43l.13-.88a.5.5 0 00-.45-.57z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});