define("ember-svg-jar/inlined/keyboard-page-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-page-up</title><path d=\"M16.75 12.06a1.5 1.5 0 000-3h-3a.25.25 0 01-.25-.25V7.06a.25.25 0 01.25-.25h3a1 1 0 00.707-1.71L12.707.353a1 1 0 00-1.414 0L6.543 5.1a1 1 0 00.707 1.71h3a.25.25 0 01.25.25v1.75a.25.25 0 01-.25.25h-3a1.5 1.5 0 000 3h3a.25.25 0 01.25.25v1.25a.25.25 0 01-.25.25h-3a1.5 1.5 0 000 3h3a.25.25 0 01.25.25v5.375a1.5 1.5 0 103 0V17.06a.25.25 0 01.25-.25h3a1.5 1.5 0 100-3h-3a.25.25 0 01-.25-.25v-1.25a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});