define("ember-svg-jar/inlined/online-reading-blinklist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>online-reading-blinklist</title><path d=\"M13 1a11 11 0 00-9 4.77.5.5 0 00.13.7l.78.53a.5.5 0 00.69-.13 9 9 0 110 10.21.5.5 0 00-.69-.08l-.83.55a.5.5 0 00-.13.7A11 11 0 1013 1z\"/><path d=\"M.78 6.52a.5.5 0 00-.78.41v10.14a.5.5 0 00.78.42l7.6-5.07a.5.5 0 000-.83z\"/><path d=\"M7.41 15.31a.5.5 0 00-.13.7 7 7 0 100-8 .5.5 0 00.13.7l4.34 2.9a.5.5 0 010 .83z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});