define("ember-svg-jar/inlined/sweater", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sweater</title><path d=\"M20.579 5.311a3.984 3.984 0 00-2.091-2.846.249.249 0 00-.352.187 6.242 6.242 0 01-12.273 0 .248.248 0 00-.352-.186A3.985 3.985 0 003.42 5.311L.6 21.414a.5.5 0 00.5.586h2.014a.5.5 0 00.483-.374l1.919-9.752A.5.5 0 016.5 12v1.571a.156.156 0 00.253.136l2.395-2.061a.5.5 0 01.707 0l1.793 1.793a.505.505 0 00.714 0l1.793-1.793a.5.5 0 01.707 0s2.291 1.979 2.4 2.061.243-.018.243-.16V12a.5.5 0 01.984-.126l1.911 9.752a.5.5 0 00.484.374h2.019a.5.5 0 00.492-.586z\"/><path d=\"M12 6.25a4.737 4.737 0 004.663-3.982c.014-.087-.046-.263-.162-.264h-1.25a.5.5 0 00-.48.357 2.988 2.988 0 01-5.732 0A.5.5 0 008.56 2H7.473c-.1 0-.154.159-.141.237A4.738 4.738 0 0012 6.25zM14.862 14.646a.5.5 0 00-.707 0l-1.793 1.793a.507.507 0 01-.714 0l-1.793-1.793a.5.5 0 00-.707 0l-2.565 2.3a.25.25 0 00-.083.186V21.5a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-4.375a.252.252 0 00-.083-.186z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});