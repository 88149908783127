define("ember-svg-jar/inlined/dating-rose", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dating-rose</title><path d=\"M12 7c4.276 0 7.5-1.5 7.5-3.5S16.276 0 12 0c-1.568 0-2.75.645-2.75 1.5C9.25 2.928 11.541 3 12 3h2a.75.75 0 010 1.5h-2c-2.582 0-4.25-1.178-4.25-3 0-.061 0-.121.007-.181a.5.5 0 00-.683-.5C5.479 1.45 4.5 2.4 4.5 3.5 4.5 5.5 7.724 7 12 7z\"/><path d=\"M19.243 6.836a.5.5 0 00-.507.014A13.035 13.035 0 0112 8.5a13.035 13.035 0 01-6.736-1.65.5.5 0 00-.764.423V8a7.226 7.226 0 006.087 7.126.5.5 0 01.413.492v2.617a.25.25 0 01-.409.193c-.127-.105-.258-.2-.395-.3a5.467 5.467 0 00-4.118-.871.494.494 0 00-.321.208.5.5 0 00-.08.375 5.511 5.511 0 004.872 4.467.5.5 0 01.451.5V23a1 1 0 002 0v-.194a.5.5 0 01.451-.5 5.511 5.511 0 004.872-4.467.5.5 0 00-.08-.375.494.494 0 00-.321-.208 5.462 5.462 0 00-4.118.871 5.003 5.003 0 00-.395.3.25.25 0 01-.409-.192v-2.617a.5.5 0 01.413-.492A7.226 7.226 0 0019.5 8v-.727a.5.5 0 00-.257-.437z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});