define("ember-svg-jar/inlined/launch-go-flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>launch-go-flag</title><path d=\"M17.52 5.28a1.2 1.2 0 00-1.2 1.2v3.84a1.2 1.2 0 102.4 0V6.48a1.2 1.2 0 00-1.2-1.2z\"/><path d=\"M6 1.44a.96.96 0 00-.96.96v12.48a.96.96 0 00.96.96h16.32a.96.96 0 00.96-.96V2.4a.96.96 0 00-.96-.96zm4.8 10.321a1.2 1.2 0 001.2-1.2.24.24 0 00-.24-.241h-.48a.72.72 0 110-1.44h1.44a.721.721 0 01.72.721v.96a2.64 2.64 0 01-5.28 0v-3.84a2.64 2.64 0 115.28 0 .72.72 0 01-1.44 0 1.2 1.2 0 10-2.4 0v3.84a1.2 1.2 0 001.2 1.2zm9.36-1.441a2.64 2.64 0 01-5.28 0V6.48a2.64 2.64 0 015.28 0zM2.16 0A1.441 1.441 0 00.72 1.44v21.121a1.44 1.44 0 002.88 0V1.44A1.44 1.44 0 002.16 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});