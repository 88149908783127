define("ember-svg-jar/inlined/sauna-heat-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sauna-heat-person</title><path d=\"M5.412 8.266A2.463 2.463 0 102.949 5.8a2.466 2.466 0 002.463 2.466zM.338 15.186a1.471 1.471 0 102.3 1.838l2.138-2.672a.25.25 0 01.424.057l1.11 2.565a1 1 0 00.918.6h.621a.981.981 0 01.981.981V21.5a1.472 1.472 0 102.944 0v-2.24a.25.25 0 01.469-.12l1.673 3.066a1.471 1.471 0 001.293.768 1.456 1.456 0 00.7-.181 1.472 1.472 0 00.587-2l-2.525-4.63a2.943 2.943 0 00-2.584-1.534H8.668a.25.25 0 01-.23-.151l-1.676-3.864a1.473 1.473 0 00-1.155-.874 1.559 1.559 0 00-.192-.012 1.472 1.472 0 00-1.153.552z\"/><path d=\"M6.868 23.91a.5.5 0 00.5-.5l.015-3.873a.5.5 0 00-.5-.5H.506a.5.5 0 00-.5.5v3.873a.5.5 0 00.5.5zM21.781 2.843a1.1 1.1 0 01-.1 1.426 3.086 3.086 0 00-.227 4.117l.266.333a1.089 1.089 0 01-.081 1.454 1 1 0 001.416 1.414 3.09 3.09 0 00.227-4.117l-.268-.334a1.089 1.089 0 01.086-1.452 3.11 3.11 0 00.287-4.041L22.515.489a1 1 0 10-1.6 1.2zM17.178 2.843a1.1 1.1 0 01-.1 1.426 3.087 3.087 0 00-.228 4.117l.267.333a1.09 1.09 0 01-.081 1.454 1 1 0 001.416 1.414 3.09 3.09 0 00.226-4.117l-.267-.334a1.087 1.087 0 01.082-1.452 3.11 3.11 0 00.286-4.041L17.913.489a1 1 0 10-1.6 1.2zM12.576 2.844a1.1 1.1 0 01-.1 1.426 3.085 3.085 0 00-.227 4.115l.267.335a1.089 1.089 0 01-.081 1.453 1 1 0 101.414 1.414 3.086 3.086 0 00.229-4.116l-.268-.335a1.089 1.089 0 01.082-1.452 3.109 3.109 0 00.285-4.041L13.309.489a1 1 0 10-1.6 1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});