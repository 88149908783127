define("ember-svg-jar/inlined/cellular-network-3g", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cellular-network-3g</title><path d=\"M8.5 14h-3a1 1 0 000 2h3a1 1 0 010 2H7a1 1 0 000 2h1.5a1 1 0 010 2h-3a1 1 0 000 2h3a3 3 0 002.234-5A3 3 0 008.5 14zM18.5 19H17a1 1 0 000 2h.5a1 1 0 01-2 0v-4a1 1 0 012 0 1 1 0 002 0 3 3 0 00-6 0v4a3 3 0 006 0v-1a1 1 0 00-1-1zM16.407 11.041l2.5-3.5a.5.5 0 00-.407-.791h-1.25v-5.5a1.25 1.25 0 00-2.5 0v5.5H13.5a.5.5 0 00-.407.791l2.5 3.5a.5.5 0 00.814 0zM6 4.5h1.25V10a1.25 1.25 0 002.5 0V4.5H11a.5.5 0 00.407-.791l-2.5-3.5a.519.519 0 00-.814 0l-2.5 3.5A.5.5 0 006 4.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});