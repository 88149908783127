define("ember-svg-jar/inlined/messages-bubble-quotation-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-quotation-alternate</title><path d=\"M12 .874C5.406.874.041 5.334.041 10.816a8.981 8.981 0 003.045 6.633L.969 21.675a1 1 0 001.316 1.354l5.957-2.772a14.2 14.2 0 003.758.5c6.594 0 11.959-4.459 11.959-9.94S18.594.874 12 .874zm0 17.882a12.091 12.091 0 01-3.547-.521 1.014 1.014 0 00-.715.05L4.692 19.7a.25.25 0 01-.329-.339l.86-1.717a1 1 0 00-.269-1.228 7.186 7.186 0 01-2.913-5.6c0-4.379 4.468-7.942 9.959-7.942s9.959 3.563 9.959 7.942-4.468 7.94-9.959 7.94z\"/><path d=\"M9 7.107a2.25 2.25 0 000 4.5 2.055 2.055 0 00.25-.026v.293a1 1 0 01-1 1 1 1 0 000 2 3 3 0 003-3V9.357A2.252 2.252 0 009 7.107zM15 7.107a2.25 2.25 0 000 4.5 2.055 2.055 0 00.25-.026v.293a1 1 0 01-1 1 1 1 0 000 2 3 3 0 003-3V9.357A2.252 2.252 0 0015 7.107z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});