define("ember-svg-jar/inlined/arrow-thick-right-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-right-1</title><path d=\"M23.606 11.292L13.388 1.076a.994.994 0 00-.706-.294.991.991 0 00-.707.293L9.219 3.83a1 1 0 00-.025 1.389l3.141 3.373a.25.25 0 01-.183.421L1.1 9a1 1 0 00-1 1v4a1 1 0 001 1h11.015a.25.25 0 01.229.149.253.253 0 01-.046.27l-3.107 3.363a1 1 0 00.026 1.386l2.756 2.757a1 1 0 001.415 0l10.218-10.219a1 1 0 000-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});