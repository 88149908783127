define("ember-svg-jar/inlined/soft-drinks-glass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>soft-drinks-glass</title><circle cx=\"16.168\" cy=\"1\" r=\"1\"/><circle cx=\"14.168\" cy=\"3.25\" r=\"1\"/><path d=\"M20.28 5.5a1.475 1.475 0 00-1.112-.5h-7.492a.5.5 0 01-.5-.45l-.1-1.032A3.874 3.874 0 007.206 0H4.331a1 1 0 100 2h2.875a1.883 1.883 0 011.88 1.71l.073.741a.5.5 0 01-.5.549H7.168a1.473 1.473 0 00-1.112.5 1.521 1.521 0 00-.381 1.179c.583 4.9.073.58 1.89 16A1.5 1.5 0 009.048 24h8.24a1.5 1.5 0 001.483-1.323c1.8-15.3 1.3-11.032 1.89-16v-.049A1.5 1.5 0 0020.28 5.5zM9.43 8.836A.5.5 0 019.059 9h-.65a.5.5 0 01-.5-.44l-.12-1A.5.5 0 018.29 7h.67a.5.5 0 01.5.451l.1 1a.5.5 0 01-.13.385zm2.238 6.414a1 1 0 111-1 1 1 0 01-1 1zm2 5a1 1 0 111-1 1 1 0 01-1 1zm2.5-7a1 1 0 111-1 1 1 0 01-1 1zm2.254-4.691a.5.5 0 01-.5.441h-5.848a.5.5 0 01-.5-.45l-.1-1a.5.5 0 01.5-.55h6.07a.5.5 0 01.5.559z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});