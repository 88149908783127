define("ember-svg-jar/inlined/biking-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>biking-person</title><circle cx=\"14.5\" cy=\"3.688\" r=\"2.375\"/><path d=\"M4.5 13.688a4.5 4.5 0 104.5 4.5 4.5 4.5 0 00-4.5-4.5zm0 7a2.5 2.5 0 112.5-2.5 2.5 2.5 0 01-2.5 2.5zM19.5 13.688a4.5 4.5 0 104.5 4.5 4.5 4.5 0 00-4.5-4.5zm0 7a2.5 2.5 0 112.5-2.5 2.5 2.5 0 01-2.5 2.5z\"/><path d=\"M10.722 11.427l1.451-1.659a8.265 8.265 0 004.655 1.42h.672a1.5 1.5 0 000-3h-.672a5.36 5.36 0 01-3.767-1.561 1.413 1.413 0 00-1.111-.438 1.5 1.5 0 00-1.079.512l-3.5 4a1.5 1.5 0 00.3 2.235l.664.442a5.263 5.263 0 012.2 5.485 1.5 1.5 0 102.928.65 8.288 8.288 0 00-2.741-8.086z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});