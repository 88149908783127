define("ember-svg-jar/inlined/seafood-sardine-can", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seafood-sardine-can</title><path d=\"M24 6.75a5.256 5.256 0 00-5.25-5.25h-.65a.5.5 0 01-.433-.25A2.5 2.5 0 0015.5 0h-2a2.5 2.5 0 00-2.164 1.251.5.5 0 01-.436.249H5a5.006 5.006 0 00-5 5v7a5.006 5.006 0 005 5h5.9a.5.5 0 01.433.249 2.507 2.507 0 001.747 1.216.5.5 0 01.417.493V21a1 1 0 01-1 1h-.75a.25.25 0 01-.25-.25 1 1 0 00-2 0A2.252 2.252 0 0011.75 24h.75a3 3 0 003-3v-.542a.5.5 0 01.417-.493 2.508 2.508 0 001.747-1.215.5.5 0 01.433-.25h.653A5.256 5.256 0 0024 13.25zM11 14.5a.5.5 0 01-.5.5H8.354a14.872 14.872 0 01-5.327-1.537.5.5 0 010-.926A14.872 14.872 0 018.354 11H10.5a.5.5 0 01.5.5zm0-6a.5.5 0 01-.5.5H8.354a14.872 14.872 0 01-5.327-1.537.5.5 0 010-.926A14.872 14.872 0 018.354 5H10.5a.5.5 0 01.5.5zm5 9a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-15a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm5.5-4.25A2.752 2.752 0 0118.75 16h-.25a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5h.25a2.752 2.752 0 012.75 2.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});