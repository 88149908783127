define("ember-svg-jar/inlined/code-write", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>code-write</title><path d=\"M1.794 23.25h9.06a.488.488 0 00.479-.392l.5-2.5a2 2 0 01.547-1.022l5.268-5.268a.5.5 0 00.146-.353V4.664a1 1 0 00-.293-.707L14.087.543A1 1 0 0013.38.25H1.794a1 1 0 00-1 1v21a1 1 0 001 1zm2-14.5a.477.477 0 01.45-.5h3.6a.477.477 0 01.45.5v2a.477.477 0 01-.45.5h-3.6a.477.477 0 01-.45-.5zm0 5a.477.477 0 01.45-.5h3.6a.477.477 0 01.45.5.25.25 0 00.25.25h2.25a.25.25 0 00.25-.25v-8a.25.25 0 00-.25-.25h-2.25a.25.25 0 00-.25.25.477.477 0 01-.45.5h-3.6a.477.477 0 01-.45-.5v-2a.477.477 0 01.45-.5h3.6a.477.477 0 01.45.5.25.25 0 00.25.25h2.25a1.752 1.752 0 011.75 1.75v3a.25.25 0 00.25.25h1.5a.75.75 0 010 1.5h-1.5a.25.25 0 00-.25.25v3a1.752 1.752 0 01-1.75 1.75h-2.25a.25.25 0 00-.25.25.477.477 0 01-.45.5h-3.6a.477.477 0 01-.45-.5zM22.223 16.675a.249.249 0 00.353 0l.072-.071a1.914 1.914 0 00-2.707-2.704l-.072.071a.25.25 0 000 .353z\"/><rect x=\"14.073\" y=\"16.887\" width=\"7.342\" height=\"3.828\" rx=\".25\" ry=\".25\" transform=\"rotate(-44.999 17.744 18.8)\"/><path d=\"M13.392 23.74l1.78-.356a.25.25 0 00.128-.421l-1.718-1.718a.25.25 0 00-.422.127l-.356 1.78a.5.5 0 00.49.6.466.466 0 00.098-.012z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});