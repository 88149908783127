define("ember-svg-jar/inlined/love-gift-chocolate-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-gift-chocolate-box</title><path d=\"M23.343 10.154l-9.849 7a2.572 2.572 0 01-2.977 0L.7 10.18c-.1-.068-.2-.134-.285-.203a.25.25 0 00-.4.2v1.513a4.884 4.884 0 002.053 3.975l9.328 6.625a1.048 1.048 0 001.212 0l9.328-6.625a4.884 4.884 0 002.053-3.975v-1.512a.251.251 0 00-.4-.2c-.082.061-.163.122-.246.176z\"/><path d=\"M23.311 3.791A6.745 6.745 0 0017.9 1.518a6.639 6.639 0 00-5.689 2.672.25.25 0 01-.421 0A6.642 6.642 0 006.1 1.518 6.742 6.742 0 00.689 3.791c-1.812 2.469.634 4.382 1.131 4.735l9.85 7a.571.571 0 00.66 0l9.854-7c1.833-1.25 2.286-3.156 1.127-4.735z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});