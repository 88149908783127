define("ember-svg-jar/inlined/file-code-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-clock</title><path d=\"M17.75 11.5V15a.75.75 0 001.5 0v-3.5a.75.75 0 10-1.5 0zM13.75 8.5V5a.75.75 0 00-1.5 0v3.5a.75.75 0 001.5 0zM20.25 8V5.5A1.251 1.251 0 0019 4.251h-1.5A1.251 1.251 0 0016.25 5.5V8a1.252 1.252 0 001.25 1.25H19A1.252 1.252 0 0020.25 8zm-1.5-.5a.25.25 0 01-.25.25H18a.25.25 0 01-.25-.25V6a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25z\"/><path d=\"M23.414 3L21 .587A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.276a.247.247 0 00.242.25c.366.007 1.038.034 1.485.082A.246.246 0 008 9.36V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.121a.5.5 0 01.146.355V18a.5.5 0 01-.5.5h-6.858a.244.244 0 00-.241.21 7.935 7.935 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M14.406 15.658a1.227 1.227 0 00.844-1.158V12A1.25 1.25 0 0014 10.751h-1.5a1.208 1.208 0 00-.914.429.193.193 0 00.029.179 8.2 8.2 0 011.009 1.007c.042.051.126.125.126.019a.111.111 0 01.115-.134h.635a.25.25 0 01.25.25v1.589a.235.235 0 00.021.1 7.983 7.983 0 01.484 1.376c.011.04.112.105.151.092zM6.75 10.5a6.75 6.75 0 106.75 6.75 6.758 6.758 0 00-6.75-6.75zm0 11.5a4.75 4.75 0 114.75-4.75A4.756 4.756 0 016.75 22z\"/><path d=\"M8.75 16.251H8A.25.25 0 017.75 16v-1.25a1 1 0 10-2 0v2.5a1 1 0 001 1h2a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});