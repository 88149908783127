define("ember-svg-jar/inlined/database-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-download</title><path d=\"M8.847 21.529a.25.25 0 00.243-.368 7.928 7.928 0 01-.918-3.006.248.248 0 00-.216-.223 12.826 12.826 0 01-6.174-2.217.25.25 0 00-.408.194v1.716c0 1.917 3.202 3.513 7.473 3.904zM1.782 11.215a.25.25 0 00-.408.194v1.716c0 1.769 2.728 3.268 6.507 3.8a.251.251 0 00.284-.227 7.939 7.939 0 01.768-2.816.25.25 0 00-.2-.358 13.6 13.6 0 01-6.951-2.309zM16.126 9.375a7.927 7.927 0 013.333.738.25.25 0 00.29-.059 2.129 2.129 0 00.625-1.429V6.91a.25.25 0 00-.408-.194c-1.937 1.577-5.587 2.409-9.091 2.409s-7.156-.833-9.093-2.411a.25.25 0 00-.408.194v1.717c0 2.027 3.581 3.7 8.221 3.961a.25.25 0 00.212-.1 7.978 7.978 0 016.319-3.111z\"/><ellipse cx=\"10.875\" cy=\"4.125\" rx=\"9.5\" ry=\"4\"/><path d=\"M16.126 10.875a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm0 10a.5.5 0 01-.391-.187l-2-2.5a.5.5 0 01.391-.813h.75a.25.25 0 00.25-.25v-2.75a1 1 0 012 0v2.75a.25.25 0 00.25.25h.75a.5.5 0 01.391.813l-2 2.5a.5.5 0 01-.391.187z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});