define("ember-svg-jar/inlined/indent-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>indent-right</title><path d=\"M24 11.5a1 1 0 00-1-1h-2.25a.25.25 0 01-.25-.25V9.5a1 1 0 00-1.707-.707l-2 2a1 1 0 000 1.414l2 2A1 1 0 0020.5 13.5v-.75a.25.25 0 01.25-.25H23a1 1 0 001-1zM15 0a1 1 0 00-1 1v22a1 1 0 102 0V1a1 1 0 00-1-1zM10.5 20.5h-9a1 1 0 000 2h9a1 1 0 000-2zM10.5.5H1a1 1 0 000 2h9.5a1 1 0 000-2zM10.5 5.5h-7a1 1 0 000 2h7a1 1 0 000-2zM10.5 10.5H1a1 1 0 000 2h9.5a1 1 0 000-2zM10.5 15.5h-7a1 1 0 000 2h7a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});