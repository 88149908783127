define("ember-svg-jar/inlined/os-system-osx", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>os-system-osx</title><path d=\"M15.85 12.17l6.07-9.4A.5.5 0 0021.5 2h-4.05a.5.5 0 00-.42.23L12 9.88 7 2.23A.5.5 0 006.55 2H2.5a.5.5 0 00-.42.77l6.07 9.51-6.06 8.94a.5.5 0 00.41.78h4.05a.5.5 0 00.45-.21l5-7.18 5 7.18a.5.5 0 00.41.21h4.09a.5.5 0 00.42-.78z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});