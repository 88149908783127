define("ember-svg-jar/inlined/allowances-silence", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>allowances-silence</title><path d=\"M23.742 23.234V.873a.5.5 0 00-.412-.492 8.9 8.9 0 00-1.588-.147c-7.249 0-8.933 5.128-10.716 10.559l-.043.129a1 1 0 00.951 1.312h1.558a.249.249 0 01.25.25v2.659a3.1 3.1 0 003.109 3.091h.641a.249.249 0 01.25.25v4.75a.5.5 0 00.5.5h5a.5.5 0 00.5-.5zM3.406 21.085a.5.5 0 01-.634-.314l-.08-.237a1.25 1.25 0 10-2.368.8l.706 2.092a.5.5 0 00.474.34l8.969-.007a.5.5 0 00.473-.66l-3.38-10.014a1.25 1.25 0 10-2.369.8l1.839 5.448a.5.5 0 01-.947.32l-.4-1.185a1.25 1.25 0 00-2.369.8l.4 1.184a.5.5 0 01-.314.633z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});