define("ember-svg-jar/inlined/parking-p", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>parking-p</title><path d=\"M5.5 21.75h-4a.25.25 0 00-.25.25v.5a1.252 1.252 0 001.25 1.25h2a1.252 1.252 0 001.25-1.25V22a.25.25 0 00-.25-.25zM19.5 21.75h-4a.25.25 0 00-.25.25v.5a1.252 1.252 0 001.25 1.25h2a1.252 1.252 0 001.25-1.25V22a.25.25 0 00-.25-.25zM19.3 15.238l-.111-.168-.465-1.163a.25.25 0 00-.232-.157h-1.823a.251.251 0 00-.219.128.245.245 0 01-.213.122H4.929a.25.25 0 01-.207-.11.254.254 0 01-.025-.233l1-2.5A.25.25 0 015.929 11h4.321a1 1 0 000-2H5.421a1.5 1.5 0 00-1.393.943l-1.94 4.851-.111.168L.47 16.47a.747.747 0 00-.22.53v2.5a1.25 1.25 0 001.25 1.25h18a1.25 1.25 0 001.25-1.25v-2.293a1.254 1.254 0 00-.366-.884z\"/><path d=\"M17.75.25a6 6 0 106 6 6 6 0 00-6-6zm.5 7.25h-1a.25.25 0 00-.25.25v2a.75.75 0 01-1.5 0v-7a.75.75 0 01.75-.75h2a2.75 2.75 0 010 5.5z\"/><path d=\"M18.25 3.5h-1a.25.25 0 00-.25.25v2a.25.25 0 00.25.25h1a1.25 1.25 0 000-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});