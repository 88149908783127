define("ember-svg-jar/inlined/safety-heart-electricity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-heart-electricity</title><path d=\"M6.5 7.5a2 2 0 012 2v3.749a.25.25 0 00.25.25h.375a2.375 2.375 0 011.837 3.88l-1.017 1.27a.25.25 0 00.016.331l1.321 1.36a1 1 0 001.434 0l9.436-9.714a6.16 6.16 0 001.18-7.187 6.338 6.338 0 00-10.07-1.628l-1.1 1a.25.25 0 01-.337 0l-1.08-.984A6.265 6.265 0 005.274.087a6.206 6.206 0 00-3.613 10.351l.008.008.632.65a.249.249 0 00.191.076.252.252 0 00.183-.094l2.263-2.826A2 2 0 016.5 7.5z\"/><path d=\"M2.75 18a.25.25 0 01.25.25v5.25a.5.5 0 00.89.313l5.9-7.37A.875.875 0 009.124 15H7.249a.25.25 0 01-.25-.25V9.5a.5.5 0 00-.89-.313l-5.9 7.37A.875.875 0 00.875 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});