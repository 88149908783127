define("ember-svg-jar/inlined/composition-hand-frame", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>composition-hand-frame</title><path d=\"M.739 10.52a1.955 1.955 0 003.794-.949l-.315-1.26a.251.251 0 01.046-.211.248.248 0 01.2-.1H11.5a2 2 0 000-4H7.752a.25.25 0 01-.25-.25V2.5A2.5 2.5 0 005 0H.5a.5.5 0 00-.5.5v7a.489.489 0 00.015.121zM6 20a1 1 0 00-1-1H3.2a.2.2 0 01-.2-.2V17a1 1 0 00-2 0v1.8A2.2 2.2 0 003.2 21H5a1 1 0 001-1zM19 5h1.8a.2.2 0 01.2.2V7a1 1 0 002 0V5.2A2.2 2.2 0 0020.8 3H19a1 1 0 000 2zM23.985 16.379l-.724-2.9a1.955 1.955 0 00-3.794.949l.315 1.26a.251.251 0 01-.046.215.248.248 0 01-.2.1H12.5a2 2 0 000 4h3.748a.25.25 0 01.25.25V21.5A2.5 2.5 0 0019 24h4.5a.5.5 0 00.5-.5v-7a.489.489 0 00-.015-.121z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});