define("ember-svg-jar/inlined/remove-tab-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>remove-tab-alternate</title><path d=\"M18.121 5.5a3.749 3.749 0 00-2.414-1h-13.5a2 2 0 00-2 2v11a2 2 0 002 2h13.5a3.749 3.749 0 002.414-1l5.086-5.086a2 2 0 000-2.828zm-1.414 11.586a1.635 1.635 0 01-1 .414H2.457a.25.25 0 01-.25-.25V6.75a.25.25 0 01.25-.25h13.25a1.63 1.63 0 011 .414L21.793 12z\"/><path d=\"M11.591 9.116a1.251 1.251 0 00-1.768 0l-.939.939a.25.25 0 01-.354 0l-.939-.939a1.25 1.25 0 00-1.768 1.768l.94.939a.252.252 0 010 .354l-.94.939a1.25 1.25 0 001.768 1.768l.939-.94a.25.25 0 01.354 0l.939.94a1.25 1.25 0 101.768-1.768l-.94-.939a.252.252 0 010-.354l.94-.939a1.251 1.251 0 000-1.768z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});