define("ember-svg-jar/inlined/christmas-lights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-lights</title><path d=\"M21.721 9.036a.578.578 0 01-.151-.324L21.416 7.5a1.492 1.492 0 00-1.425-1.307l-.211-1.672a35.231 35.231 0 003.562-1.082 1 1 0 00-.684-1.879A31.007 31.007 0 0112 3.491 31.007 31.007 0 011.342 1.56a1 1 0 00-.684 1.879A35.231 35.231 0 004.22 4.521l-.211 1.672A1.492 1.492 0 002.584 7.5L2.43 8.712a.578.578 0 01-.151.324A8.089 8.089 0 00.064 13.47a7.974 7.974 0 001.164 5.247l.41.663a1.878 1.878 0 002.868.363l.563-.54a7.976 7.976 0 002.431-4.79 8.092 8.092 0 00-1.04-4.847.573.573 0 01-.06-.347L6.552 8A1.5 1.5 0 005.5 6.382l.193-1.53a31.99 31.99 0 005.56.631v2.959A1.5 1.5 0 0010 9.917v1.22a.589.589 0 01-.109.342 8.08 8.08 0 00-1.641 4.677 7.979 7.979 0 001.814 5.059l.491.606a1.877 1.877 0 002.89 0l.491-.606a7.979 7.979 0 001.814-5.059 8.08 8.08 0 00-1.641-4.677.589.589 0 01-.109-.342v-1.22a1.5 1.5 0 00-1.25-1.475V5.483a31.99 31.99 0 005.56-.631l.193 1.53A1.5 1.5 0 0017.448 8l.152 1.219a.573.573 0 01-.066.347 8.092 8.092 0 00-1.04 4.847 7.976 7.976 0 002.435 4.79l.563.54a1.879 1.879 0 002.868-.363l.41-.663a7.974 7.974 0 001.164-5.247 8.089 8.089 0 00-2.213-4.434zm-15.7 5.189a6.493 6.493 0 01-1.986 3.9l-.563.54a.381.381 0 01-.553-.069l-.419-.668a6.5 6.5 0 01-.951-4.269 6.306 6.306 0 011.392-3.1.5.5 0 01.455-.185l1.647.208a.5.5 0 01.394.293 6.306 6.306 0 01.579 3.35zm6.814-1.558a.5.5 0 01.427.241 6.293 6.293 0 01.993 3.248 6.5 6.5 0 01-1.48 4.116l-.491.606a.4.4 0 01-.558 0l-.491-.606a6.5 6.5 0 01-1.48-4.116 6.293 6.293 0 01.993-3.248.5.5 0 01.427-.241zm8.665 5.261l-.411.664a.381.381 0 01-.553.069l-.563-.54a6.493 6.493 0 01-1.986-3.9 6.306 6.306 0 01.576-3.349.5.5 0 01.394-.293l1.647-.208a.5.5 0 01.455.185 6.306 6.306 0 011.392 3.1 6.5 6.5 0 01-.951 4.272z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});