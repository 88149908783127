define("ember-svg-jar/inlined/baggage-cart-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baggage-cart-1</title><circle cx=\"8.25\" cy=\"22\" r=\"2\"/><circle cx=\"19.25\" cy=\"22\" r=\"2\"/><path d=\"M23 17.25H6a1 1 0 01-1-1V4.508a4.226 4.226 0 00-2.348-3.8l-1.2-.6A1 1 0 00.553 1.9l1.2.6A2.235 2.235 0 013 4.508V16.25a3 3 0 003 3h17a1 1 0 000-2z\"/><path d=\"M11 15.75h7a.5.5 0 00.5-.5V5.051a4.1 4.1 0 00-4-3.55c-3.391 0-4 3.5-4 3.5V15.25a.5.5 0 00.5.5zm2.017-11.582a1.982 1.982 0 012.966 0A.5.5 0 0115.61 5h-2.22a.5.5 0 01-.373-.833zM20.5 15.75h.5a1.5 1.5 0 001.5-1.5V6.5A1.5 1.5 0 0021 5h-.5a.5.5 0 00-.5.5v9.75a.5.5 0 00.5.5zM8 15.75h.5a.5.5 0 00.5-.5V5.5a.5.5 0 00-.5-.5H8a1.5 1.5 0 00-1.5 1.5v7.75a1.5 1.5 0 001.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});