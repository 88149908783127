define("ember-svg-jar/inlined/design-shape-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-shape-monitor</title><path d=\"M24 1.5A1.5 1.5 0 0022.5 0h-21A1.5 1.5 0 000 1.5v16A1.5 1.5 0 001.5 19h21a1.5 1.5 0 001.5-1.5zm-2 13a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5zM14.35 20.681a.5.5 0 00-.385-.181h-3.93a.5.5 0 00-.385.181l-2.1 2.532a.5.5 0 00.41.787h8.079a.5.5 0 00.41-.787z\"/><path d=\"M9.705 6.294a3.618 3.618 0 10-.585 7.212c.1.008.2.012.295.012a3.625 3.625 0 003.6-3.326 3.621 3.621 0 00-3.31-3.898zm-.122 1.5a2.117 2.117 0 11-.172-.007.99.99 0 01.172.002z\"/><path d=\"M17.033 3.25h-6.066A1.218 1.218 0 009.75 4.467v.467a.75.75 0 101.5 0 .184.184 0 01.184-.184h4.816a.5.5 0 01.5.5v4.5a.5.5 0 01-.5.5H14.7a.75.75 0 000 1.5h2.333a1.218 1.218 0 001.217-1.217V4.467a1.218 1.218 0 00-1.217-1.217z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});