define("ember-svg-jar/inlined/flying-insect-bee-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flying-insect-bee-3</title><path d=\"M23.24 1.108a.994.994 0 00-1.035.023l-8.326 5.3a.25.25 0 00.042.443c.048.019.1.04.145.061a4.461 4.461 0 012.172 2.027.248.248 0 00.193.133 7.74 7.74 0 011.295.261c4.254-.615 5.849-2.727 6.015-7.343a.992.992 0 00-.501-.905zM8.627 5.64a1.864 1.864 0 00-.749-.154 3.024 3.024 0 00-2.17 1.132L4.056 4.57a1.5 1.5 0 00-2.544.354L.339 7.661a1 1 0 00.525 1.312.979.979 0 00.394.081 1 1 0 00.919-.606l.846-1.973 1.664 2.064c-.412 1.543.029 3.03 1.129 3.512a1.845 1.845 0 00.749.155A3.573 3.573 0 009.51 9.849c.777-1.769.381-3.649-.883-4.209zM14.258 14.725a.5.5 0 00.612.487l5.24-1.2a.5.5 0 00.343-.282.5.5 0 00-.016-.444 5.509 5.509 0 00-4.907-2.738l-.322.013a2.868 2.868 0 00-1.745-2.249 3 3 0 00-1.872-.178.249.249 0 00-.193.229 6.247 6.247 0 01-1.708 3.881.248.248 0 00-.038.3 2.992 2.992 0 004.606.742zM14.646 16.8a.5.5 0 00-.388.487v.263a5.116 5.116 0 00.435 2.143l6.565-1.511v-1.13a10.985 10.985 0 00-.062-1.185.5.5 0 00-.61-.432zM20.717 23.024a.5.5 0 00.541-.5v-2.173a.5.5 0 00-.613-.488l-4.029.928a.5.5 0 00-.164.9 9.482 9.482 0 004.265 1.333z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});