define("ember-svg-jar/inlined/road-sign-bird-life-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-bird-life-alternate</title><path d=\"M23.707 12.707a1 1 0 000-1.414l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0zm-2.3-.884a.25.25 0 010 .354l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0z\"/><path d=\"M20 12a1 1 0 00-1-1h-3.693a1.511 1.511 0 00-1.279.715c-.145.236-.632.785-2.028.785s-1.883-.549-2.029-.786A1.509 1.509 0 008.693 11H5a1 1 0 000 2h3.311a.248.248 0 01.193.091A4.291 4.291 0 0012 14.5a4.291 4.291 0 003.5-1.409.248.248 0 01.189-.091H19a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});