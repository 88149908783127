define("ember-svg-jar/inlined/crypto-currency-bitcoin-give", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-give</title><path d=\"M14.75 11a.25.25 0 00.25.25h1.5a1.25 1.25 0 000-2.5H15a.25.25 0 00-.25.25zM17.75 6a1.252 1.252 0 00-1.25-1.25H15a.25.25 0 00-.25.25v2a.25.25 0 00.25.25h1.5A1.252 1.252 0 0017.75 6z\"/><path d=\"M16 0a8 8 0 108 8 8 8 0 00-8-8zm-2.75 4a.75.75 0 01.75-.75h.5a.25.25 0 00.25-.25v-.25a.75.75 0 011.5 0V3a.25.25 0 00.25.25 2.742 2.742 0 012.04 4.583.25.25 0 000 .335A2.723 2.723 0 0119.25 10a2.752 2.752 0 01-2.75 2.75.25.25 0 00-.25.25v.25a.75.75 0 01-1.5 0V13a.25.25 0 00-.25-.25H14a.75.75 0 01-.75-.75zM1 14.75a1 1 0 00-1 1V23a1 1 0 002 0v-7.25a1 1 0 00-1-1zM15.5 19.5H6.375a.625.625 0 010-1.25H8.9a1 1 0 00.92-1.393A2.234 2.234 0 007.764 15.5H3.5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.764a2.238 2.238 0 002.236-2.236V20.5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});