define("ember-svg-jar/inlined/photography-equipment-retro-film", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>photography-equipment-retro-film</title><rect x=\"1.5\" y=\"6\" width=\"10\" height=\"12\" rx=\".5\" ry=\".5\"/><path d=\"M22.5 6H14a.5.5 0 00-.5.5v11a.5.5 0 00.5.5h4.5a1.5 1.5 0 001.5-1.5v-1c0-1.107.393-1.5 1.5-1.5h1a1.5 1.5 0 001.5-1.5v-5A1.5 1.5 0 0022.5 6z\"/><rect y=\"2\" width=\"13\" height=\"3\" rx=\"1\" ry=\"1\"/><rect y=\"19\" width=\"13\" height=\"3\" rx=\"1\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});