define("ember-svg-jar/inlined/steampunk-decoration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>steampunk-decoration</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M16 5.071A1 1 0 1015 6.8a6.025 6.025 0 013 5.2 1 1 0 002 0 8.029 8.029 0 00-4-6.929zM15.091 17.144A6 6 0 018.909 6.856a1 1 0 10-1.033-1.712 8 8 0 008.248 13.713 1 1 0 10-1.033-1.713z\"/><path d=\"M13 4.5a1 1 0 00-2 0v3.975a.252.252 0 01-.166.236 3.477 3.477 0 00-1.1.634.249.249 0 01-.287.026l-.412-.237a.5.5 0 00-.683.182l-.5.867a.5.5 0 00.183.682l.41.238a.248.248 0 01.12.261 3.457 3.457 0 000 1.267.25.25 0 01-.121.262l-.415.24a.5.5 0 00-.182.683l.5.867a.5.5 0 00.683.182l.414-.239a.251.251 0 01.288.027 3.437 3.437 0 001.095.639.252.252 0 01.166.236V16a.5.5 0 00.5.5h1A.5.5 0 0013 16v-.475a.252.252 0 01.166-.236 3.487 3.487 0 001.089-.628.251.251 0 01.288-.026L17.5 16.36a.9.9 0 00.5.14 1 1 0 00.5-1.86l-2.95-1.74a.25.25 0 01-.119-.261 3.452 3.452 0 000-1.266.252.252 0 01.121-.263l.417-.241a.5.5 0 00.182-.682l-.5-.867a.5.5 0 00-.683-.182l-.416.24a.251.251 0 01-.288-.027 3.469 3.469 0 00-1.118-.651.22.22 0 01-.146-.205zm-1 9a1.5 1.5 0 111.5-1.5 1.516 1.516 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});