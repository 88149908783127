define("ember-svg-jar/inlined/water-fountain-jet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-fountain-jet</title><path d=\"M11 .25a9.01 9.01 0 00-9 9v5.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-5.5a5 5 0 015.493-4.977A5.169 5.169 0 0116 9.486a.765.765 0 00.764.764H19a1 1 0 001-1 9.011 9.011 0 00-9-9zM10 19.25a1 1 0 00-1-1H7.25A.25.25 0 017 18v-.25a1 1 0 00-1-1H2a1 1 0 00-1 1v5a1 1 0 001 1h4a1 1 0 001-1V20.5a.25.25 0 01.25-.25H9a1 1 0 001-1z\"/><circle cx=\"18\" cy=\"16.25\" r=\"1\"/><circle cx=\"18\" cy=\"12.75\" r=\"1\"/><circle cx=\"18\" cy=\"19.75\" r=\"1\"/><circle cx=\"21\" cy=\"16.25\" r=\"1\"/><circle cx=\"22\" cy=\"19.75\" r=\"1\"/><circle cx=\"15\" cy=\"16.25\" r=\"1\"/><circle cx=\"14\" cy=\"19.75\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});