define("ember-svg-jar/inlined/vegetables-beet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vegetables-beet</title><path d=\"M14 5.572a.411.411 0 00.44.443 4.9 4.9 0 004.7-4.579.411.411 0 00-.433-.436A4.9 4.9 0 0014 5.572zM10 5.572a.411.411 0 01-.44.443 4.9 4.9 0 01-4.7-4.579A.411.411 0 015.293 1 4.9 4.9 0 0110 5.572z\"/><path d=\"M14.293 17.808a1.05 1.05 0 01.46-.868C16.713 15.614 19 13.526 19 11c0-3.437-3.328-3.947-5.513-4A.5.5 0 0113 6.5V1a1 1 0 00-2 0v5.5a.5.5 0 01-.487.5C8.329 7.053 5 7.563 5 11c0 2.526 2.287 4.614 4.247 5.94a1.048 1.048 0 01-.032 1.76c-.811.545-2.5 1.68-1.242 3.841a1 1 0 101.728-1c-.291-.5-.314-.542.63-1.177.084-.056.177-.119.275-.188a.25.25 0 01.394.2V23a1 1 0 002 0v-2.629a.25.25 0 01.394-.2c.1.069.191.132.275.188.945.635.921.676.63 1.177a1 1 0 101.729 1c1.256-2.161-.432-3.3-1.243-3.841a1.051 1.051 0 01-.492-.887zm-4.6-7.891C8.4 10.24 8.214 10.95 8.2 11.533a.751.751 0 01-.749.729h-.025a.748.748 0 01-.726-.772 3.109 3.109 0 012.627-3.028.75.75 0 11.364 1.455z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});