define("ember-svg-jar/inlined/touch-password-lock-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>touch-password-lock-1</title><path d=\"M6.25 12.3a1 1 0 00-1-1l-2.51-.038a.5.5 0 01-.493-.5l-.038-7.509a.5.5 0 01.5-.5H13.25a1 1 0 100-2H2.206A2 2 0 00.25 2.8v8.455A2 2 0 002.206 13.3H5.25a1 1 0 001-1z\"/><path d=\"M16.139 16.251H13.25v-6.5a2.5 2.5 0 00-5 0v8.835l-1.089-1.528a2.075 2.075 0 10-3.672 1.932l2.1 3.993a.5.5 0 00.442.267H20.25a.5.5 0 00.5-.5V21.1a4.8 4.8 0 00-4.611-4.849zM22.75 5.651h-.25v-1.4a2.75 2.75 0 00-5.5 0v1.4h-.25a1 1 0 00-1 1v4.5a1 1 0 001 1h6a1 1 0 001-1v-4.5a1 1 0 00-1-1zm-3 3.75a1 1 0 111-1 1 1 0 01-1 .999zm-1.25-3.75v-1.4a1.25 1.25 0 012.5 0v1.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});