define("ember-svg-jar/inlined/crypto-currency-bitcoin-wallet-open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-wallet-open</title><path d=\"M18.928 6.68h-1.482a.25.25 0 00-.25.25v.985a.25.25 0 00.25.25h1.482a.743.743 0 000-1.485zM19.624 4.2a.742.742 0 00-.7-.487h-1.478a.25.25 0 00-.25.25v.985a.25.25 0 00.25.25h1.482a.745.745 0 00.742-.743.721.721 0 00-.046-.255M4.549 4.449a.25.25 0 01.067-.491h4.415a.99.99 0 100-1.979H3.093A2.973 2.973 0 00.124 4.948v11.986A4.624 4.624 0 003.473 21.3h.005l9.75 2.6h.005a3.36 3.36 0 00.8.1 2.838 2.838 0 001.776-.6 3.136 3.136 0 001.136-2.5v-1.847a.25.25 0 01.25-.25h1.235a3.962 3.962 0 003.958-3.958v-.476a.99.99 0 00-1.979 0v.476a1.982 1.982 0 01-1.979 1.979H17.2a.25.25 0 01-.25-.25v-5.193a4.7 4.7 0 00-3.331-4.4zM12 18.309a1.98 1.98 0 111.979-1.98A1.98 1.98 0 0112 18.309z\"/><path d=\"M23.876 5.938a5.935 5.935 0 00-11.831-.688.251.251 0 00.181.27l1.6.441a5.779 5.779 0 011.5.661.249.249 0 00.381-.212V2.969a.742.742 0 01.742-.742.248.248 0 00.248-.248.742.742 0 011.484 0 .248.248 0 00.248.248h.5a2.229 2.229 0 012.226 2.226 2.194 2.194 0 01-.455 1.334.249.249 0 000 .3 2.191 2.191 0 01.454 1.333 2.229 2.229 0 01-2.226 2.227h-.054a.25.25 0 00-.25.25v1.636a.249.249 0 00.291.246 5.925 5.925 0 004.961-5.841z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});