define("ember-svg-jar/inlined/road-sign-keep-left-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-keep-left-alternate</title><path d=\"M1 0a1 1 0 00-1 1v22a1 1 0 002 0V1a1 1 0 00-1-1zM23 0a1 1 0 00-1 1v22a1 1 0 002 0V1a1 1 0 00-1-1zM20.5 20a1 1 0 00-1-1h-11a.25.25 0 01-.25-.25V17.5a.75.75 0 00-1.186-.61l-3.5 2.5a.75.75 0 000 1.22l3.5 2.5a.75.75 0 001.186-.61v-1.25A.25.25 0 018.5 21h11a1 1 0 001-1zM13 16.25a.75.75 0 00.75-.75v-1a.25.25 0 01.25-.25h.5a.75.75 0 000-1.5H14a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h1a.75.75 0 000-1.5h-1a1.752 1.752 0 00-1.75 1.75v4a.75.75 0 00.75.75zM17 11.25a.25.25 0 01.25.25v4a.75.75 0 001.5 0v-4a.25.25 0 01.25-.25.75.75 0 000-1.5h-2a.75.75 0 000 1.5zM5 7.75A.75.75 0 005.75 7a.127.127 0 01.226-.079l.439.548a.75.75 0 101.17-.938l-1.5-1.875a.25.25 0 010-.312l1.5-1.875a.75.75 0 10-1.17-.938l-.439.548A.127.127 0 015.75 2a.75.75 0 00-1.5 0v5a.75.75 0 00.75.75zM17 7.75a.75.75 0 00.75-.75V5.938a.249.249 0 01.2-.245 2.244 2.244 0 00-.45-4.443H17a.75.75 0 00-.75.75v5a.75.75 0 00.75.75zm1.25-4.25a.25.25 0 11-.25-.25.25.25 0 01.25.25zM6 16.25h1a.75.75 0 000-1.5H6a.25.25 0 01-.25-.25v-4a.75.75 0 00-1.5 0v4A1.752 1.752 0 006 16.25zM10 7.75h1a.75.75 0 000-1.5h-1a.25.25 0 010-.5h1a.75.75 0 000-1.5h-1A.25.25 0 019.75 4V3a.25.25 0 01.25-.25h1a.75.75 0 000-1.5h-1A1.752 1.752 0 008.25 3v3A1.752 1.752 0 0010 7.75zM10 16.25h1a.75.75 0 000-1.5h-1a.25.25 0 010-.5h1a.75.75 0 000-1.5h-1a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h1a.75.75 0 000-1.5h-1a1.752 1.752 0 00-1.75 1.75v3A1.752 1.752 0 0010 16.25zM14 7.75h1a.75.75 0 000-1.5h-1a.25.25 0 010-.5h1a.75.75 0 000-1.5h-1a.25.25 0 01-.25-.25V3a.25.25 0 01.25-.25h1a.75.75 0 000-1.5h-1A1.752 1.752 0 0012.25 3v3A1.752 1.752 0 0014 7.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});