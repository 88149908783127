define("ember-svg-jar/inlined/messages-bubble-lock-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-lock-alternate</title><path d=\"M12 17.833a12.3 12.3 0 01-3.4-.481 1 1 0 00-.662.038l-3.647 1.52a.25.25 0 01-.331-.316l.851-2.339a1 1 0 00-.275-1.089A7.04 7.04 0 012 9.928c0-4.358 4.486-7.9 10-7.9s10 3.546 10 7.9c0 .045 0 .089-.007.133a.249.249 0 00.1.223 4.978 4.978 0 011.277 1.353.25.25 0 00.456-.082A7.909 7.909 0 0024 9.928c0-5.461-5.383-9.9-12-9.9S0 4.467 0 9.928a8.876 8.876 0 002.707 6.26l-1.6 4.4a1 1 0 001.325 1.265l5.959-2.483a14.251 14.251 0 003.609.463c.089 0 .177 0 .264-.009a.249.249 0 00.236-.25v-1.5a.251.251 0 00-.262-.25 3.135 3.135 0 01-.238.009z\"/><path d=\"M22.5 15.476v-1.25a3.5 3.5 0 10-7 0v1.25a1.5 1.5 0 00-1.5 1.5v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5v-5.5a1.5 1.5 0 00-1.5-1.5zm-3.5-2.75a1.5 1.5 0 011.5 1.5v1.25h-3v-1.25a1.5 1.5 0 011.5-1.5zm1 7.5a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});