define("ember-svg-jar/inlined/single-man-news.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-news</title><path d=\"M24 2.5A1.5 1.5 0 0022.5 1h-21A1.5 1.5 0 000 2.5V17a1.5 1.5 0 001.5 1.5h9a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5H4a1 1 0 000 2h16a1 1 0 000-2h-6.5a.5.5 0 01-.5-.5V19a.5.5 0 01.5-.5h9A1.5 1.5 0 0024 17zM11.016 14.768a.5.5 0 01-.5-.5A4.905 4.905 0 0111 12.28c.369-.739 1.5-1.156 3.072-1.733l.19-.071a.248.248 0 00.164-.234v-.451a.25.25 0 00-.068-.171 2.133 2.133 0 01-.56-1.139.251.251 0 00-.045-.109 1.227 1.227 0 01-.189-.434 1.317 1.317 0 01.1-.937.249.249 0 00.02-.191 2.357 2.357 0 01.053-1.972 1.063 1.063 0 01.58-.392.246.246 0 00.121-.076 2.682 2.682 0 011.984-.6c.975.023 2.08.363 2.257 1.245a3.525 3.525 0 01-.307 1.816.252.252 0 00.01.2 1.336 1.336 0 01.081.9 1.222 1.222 0 01-.189.433.259.259 0 00-.044.109 2.13 2.13 0 01-.561 1.14.254.254 0 00-.067.171v.451a.25.25 0 00.162.235l.189.07c1.569.577 2.7.994 3.074 1.732a4.922 4.922 0 01.488 1.989.5.5 0 01-.5.5zM9 4.5v5a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});