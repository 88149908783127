define("ember-svg-jar/inlined/analytics-mountain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>analytics-mountain</title><path d=\"M23 21H2.5a.5.5 0 01-.5-.5V2a1 1 0 00-2 0v19.5A1.5 1.5 0 001.5 23H23a1 1 0 000-2z\"/><path d=\"M17.75 18.5a1 1 0 000-2 2.02 2.02 0 01-1.083-.264 1 1 0 00-1.063 1.7 3.974 3.974 0 002.146.564zM8.431 15.22l1.2-6.573A1.387 1.387 0 0111 7.5a1 1 0 001-1 1.032 1.032 0 00-1-1 3.4 3.4 0 00-3.341 2.79l-1.2 6.571A1.993 1.993 0 014.5 16.5a1 1 0 000 2 3.993 3.993 0 003.931-3.28z\"/><path d=\"M23 16.5a1.994 1.994 0 01-1.964-1.639L19.842 8.29A3.31 3.31 0 0016.5 5.51a3.308 3.308 0 00-3.34 2.78l-1.2 6.571A1.993 1.993 0 0110 16.5a1 1 0 000 2 3.993 3.993 0 003.931-3.28l1.2-6.573a1.4 1.4 0 012.747 0l1.194 6.571A3.994 3.994 0 0023 18.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});