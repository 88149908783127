define("ember-svg-jar/inlined/gmo-food-pear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gmo-food-pear</title><ellipse cx=\"17.25\" cy=\"2.75\" rx=\"3.708\" ry=\"1.173\" transform=\"rotate(-45 17.25 2.75)\"/><path d=\"M18.121 13a5.015 5.015 0 01-1.577-2.2c-.522-2.092-1.1-4.42-3.2-5.1A.5.5 0 0113 5.223V4.75a3 3 0 00-3-3H8a1 1 0 000 2h2a1 1 0 011 1v.472a.5.5 0 01-.346.475c-2.1.682-2.677 3.01-3.2 5.1A5.015 5.015 0 015.879 13a6.742 6.742 0 00-2.528 5.156C3.351 21.869 6.5 24 12 24s8.649-2.131 8.649-5.847A6.742 6.742 0 0018.121 13zm-1.742 6.971l.716.716a.75.75 0 01-1.06 1.061l-.716-.717a.5.5 0 00-.707 0l-.716.717a.75.75 0 01-1.061-1.061l.716-.716a.5.5 0 000-.707l-.632-.633a.5.5 0 00-.707 0l-.716.717a.75.75 0 01-1.061-1.061l.716-.716a.5.5 0 000-.707l-.632-.632a.5.5 0 00-.707 0l-.717.716a.75.75 0 01-1.06-1.061l.716-.716a.5.5 0 000-.707l-.636-.636a.75.75 0 011.061-1.06l.636.635a.5.5 0 00.707 0l.716-.716a.75.75 0 111.06 1.061l-.716.716a.5.5 0 000 .707l.633.632a.5.5 0 00.707 0l.716-.716a.75.75 0 011.06 1.061l-.716.716a.5.5 0 000 .707l.633.632a.5.5 0 00.707 0l.716-.716a.75.75 0 011.06 1.061l-.716.716a.5.5 0 000 .704z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});