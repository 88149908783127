define("ember-svg-jar/inlined/tennis-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tennis-player</title><path d=\"M7.873 8.854a.876.876 0 00.076-1.287L6.374 5.992a3.571 3.571 0 00-.919-3.92 3.349 3.349 0 00-4.664-.426c-1.168 1.168-.977 3.26.425 4.663A4 4 0 003.528 7.48a3 3 0 00.4.026 2.869 2.869 0 001.215-.272L6.71 8.8a.844.844 0 001.15.063zM4.641 5.5a1.078 1.078 0 01-.9.248 2.288 2.288 0 01-1.288-.673 2.291 2.291 0 01-.673-1.289 1.08 1.08 0 01.246-.9.979.979 0 01.709-.263 2.2 2.2 0 011.479.687c.71.708.905 1.71.427 2.19z\"/><circle cx=\"15.984\" cy=\"2.75\" r=\"2.5\"/><path d=\"M17.984 7a4.423 4.423 0 00-.8-.073 4.51 4.51 0 00-2.584.813L12.087 9.5a1.5 1.5 0 01-1.532.113l-.4-.2a1.5 1.5 0 00-2.066.793 1.575 1.575 0 00.823 1.94l.3.151a4.506 4.506 0 002.013.476 4.318 4.318 0 002.378-.692.249.249 0 01.378.215v1.424a1 1 0 01-.292.707L12.3 15.811a4.5 4.5 0 00-1.318 3.182v3.257a1.5 1.5 0 003 0v-3.257a1.5 1.5 0 01.44-1.061l1.135-1.132a.25.25 0 01.426.177v.665a4.5 4.5 0 001.084 2.929l2.278 2.657a1.5 1.5 0 102.277-1.952l-2.277-2.657a1.5 1.5 0 01-.362-.977v-7.113a.249.249 0 01.09-.192.246.246 0 01.2-.054l.432.078c1.029.187 1.172 1.028 1.3 1.682a1.514 1.514 0 001.8 1.171 1.542 1.542 0 001.129-1.822C23.2 7.881 20.677 7.487 17.984 7z\"/><circle cx=\"3.484\" cy=\"12.75\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});