define("ember-svg-jar/inlined/bus-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bus-2</title><path d=\"M8 21.5H5a.25.25 0 00-.25.25v.5a1.75 1.75 0 003.5 0v-.5A.25.25 0 008 21.5zM19 21.5h-3a.25.25 0 00-.25.25v.5a1.75 1.75 0 003.5 0v-.5a.25.25 0 00-.25-.25zM22.25 4.253h-.5A.25.25 0 0121.5 4V3a3 3 0 00-3-3h-13a3 3 0 00-3 3v1a.25.25 0 01-.25.25h-.5A1.752 1.752 0 000 6v3a1.752 1.752 0 001.75 1.75h.5a.25.25 0 01.25.25v6.5a3 3 0 003 3h13a3 3 0 003-3V11a.25.25 0 01.25-.25h.5A1.752 1.752 0 0024 9V6a1.752 1.752 0 00-1.75-1.747zM7.5 16.751h-2a.75.75 0 110-1.5h2a.75.75 0 110 1.5zm11 0h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm1-4a.25.25 0 01-.25.25H4.75a.25.25 0 01-.25-.25V3a1 1 0 011-1h13a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});