define("ember-svg-jar/inlined/arrow-button-up-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-up-2-alternate</title><path d=\"M21.172 22.405H2.829a2.829 2.829 0 01-2.419-4.3L9.58 2.959A2.806 2.806 0 0112 1.6a2.806 2.806 0 012.42 1.363l9.172 15.154a2.829 2.829 0 01-2.42 4.293zm-16.44-4.151a.25.25 0 00.214.379h14.109a.25.25 0 00.214-.379L12.214 6.6a.25.25 0 00-.428 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});