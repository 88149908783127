define("ember-svg-jar/inlined/gardening-house-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gardening-house-1</title><path d=\"M23.121 9.707L14.414 1a3.414 3.414 0 00-4.828 0L.879 9.707A2.978 2.978 0 000 11.828V22a2 2 0 002 2h20a2 2 0 002-2V11.828a2.978 2.978 0 00-.879-2.121zm-2.871.561a.25.25 0 01.427-.177l.982.982a.25.25 0 01-.177.427h-.732a.5.5 0 01-.5-.5zM9.5 4.121a.5.5 0 01.146-.353l.927-.927a.25.25 0 01.427.177V11a.5.5 0 01-.5.5H10a.5.5 0 01-.5-.5zM3.75 21.5a.5.5 0 01-.5.5H2.5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h.75a.5.5 0 01.5.5zm0-4a.5.5 0 01-.5.5H2.5a.5.5 0 01-.5-.5V14a.5.5 0 01.5-.5h.75a.5.5 0 01.5.5zm0-6.5a.5.5 0 01-.5.5h-.732a.25.25 0 01-.177-.427l.982-.982a.25.25 0 01.427.177zM7.5 21.5a.5.5 0 01-.5.5h-.75a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5H7a.5.5 0 01.5.5zm0-4a.5.5 0 01-.5.5h-.75a.5.5 0 01-.5-.5V14a.5.5 0 01.5-.5H7a.5.5 0 01.5.5zm0-6.5a.5.5 0 01-.5.5h-.75a.5.5 0 01-.5-.5V7.871a.5.5 0 01.15-.353l1.173-1.177a.25.25 0 01.427.177zm7 10.5a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5V14a.5.5 0 01.5-.5h4a.5.5 0 01.5.5zm0-10.5a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5V3.018a.25.25 0 01.427-.177l.927.927a.5.5 0 01.146.353zm3.75 10.5a.5.5 0 01-.5.5H17a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h.75a.5.5 0 01.5.5zm0-4a.5.5 0 01-.5.5H17a.5.5 0 01-.5-.5V14a.5.5 0 01.5-.5h.75a.5.5 0 01.5.5zm0-6.5a.5.5 0 01-.5.5H17a.5.5 0 01-.5-.5V6.518a.25.25 0 01.427-.177L18.1 7.518a.5.5 0 01.146.353zM22 21.5a.5.5 0 01-.5.5h-.75a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h.75a.5.5 0 01.5.5zm0-4a.5.5 0 01-.5.5h-.75a.5.5 0 01-.5-.5V14a.5.5 0 01.5-.5h.75a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});