define("ember-svg-jar/inlined/layers-grid-hide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layers-grid-hide</title><path d=\"M9.5 14.75a.75.75 0 000-1.5H8a.25.25 0 01-.25-.25V8A.25.25 0 018 7.75h4a.25.25 0 01.25.25v3a.75.75 0 001.5 0V8a.25.25 0 01.25-.25h3.75A.25.25 0 0118 8v2.5a1 1 0 002 0V3a3 3 0 00-3-3H3a3 3 0 00-3 3v15a3 3 0 003 3h4.5a1 1 0 001-1 .978.978 0 00-.615-.9.218.218 0 01-.135-.2V15a.25.25 0 01.25-.25zM6.25 13a.25.25 0 01-.25.25H2.25A.25.25 0 012 13V8a.25.25 0 01.25-.25H6a.25.25 0 01.25.25zM8 6.25A.25.25 0 017.75 6V2.25A.25.25 0 018 2h4a.25.25 0 01.25.25V6a.25.25 0 01-.25.25zM18 3v3a.25.25 0 01-.25.25H14a.25.25 0 01-.25-.25V2.25A.25.25 0 0114 2h3a1 1 0 011 1zM3 2h3a.25.25 0 01.25.25V6a.25.25 0 01-.25.25H2.25A.25.25 0 012 6V3a1 1 0 011-1zm3.25 16.75A.25.25 0 016 19H3a1 1 0 01-1-1v-3a.25.25 0 01.25-.25H6a.25.25 0 01.25.25zM23.8 13.4a1 1 0 00-1.4-.2l-12 9A1 1 0 0011 24a.989.989 0 00.6-.2l12-9a1 1 0 00.2-1.4z\"/><path d=\"M12.023 20.2a1 1 0 00.088-1.412.438.438 0 010-.582A6.564 6.564 0 0117 16a1 1 0 000-2 8.57 8.57 0 00-6.389 2.886 2.439 2.439 0 000 3.229 1 1 0 001.412.085zM23.389 16.885a1 1 0 10-1.5 1.324.439.439 0 010 .582A6.564 6.564 0 0117 21a1 1 0 000 2 8.573 8.573 0 006.388-2.884 2.44 2.44 0 000-3.231z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});