define("ember-svg-jar/inlined/common-file-bookmark-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-bookmark-alternate</title><path d=\"M21.707 5.707L16.293.293A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707zM19.5 22h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h1.75a.25.25 0 01.25.25V13a.5.5 0 00.854.354l1.969-1.97a.25.25 0 01.354 0l1.969 1.97A.5.5 0 0012.5 13V2.25a.25.25 0 01.25-.25h2.215a.5.5 0 01.353.146l4.536 4.536a.5.5 0 01.146.353V21.5a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});