define("ember-svg-jar/inlined/couple-woman-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>couple-woman-woman</title><path d=\"M17.5 3.232A3.1 3.1 0 0014.854.019a2.835 2.835 0 00-2.654 1.22.25.25 0 01-.406 0A2.831 2.831 0 009.146.019 3.1 3.1 0 006.5 3.232c0 2.427 3.423 5.312 4.9 6.44a.981.981 0 001.208 0c1.468-1.127 4.892-4.013 4.892-6.44zM7.3 18.668a.5.5 0 00-.629.21l-1.208 2.1a.25.25 0 01-.434 0l-1.208-2.1a.5.5 0 00-.629-.21A5.26 5.26 0 000 23.5a.5.5 0 00.5.5H10a.5.5 0 00.5-.5 5.26 5.26 0 00-3.2-4.832z\"/><path d=\"M9.5 14.233a4.25 4.25 0 00-8.5 0 3.07 3.07 0 01-.7 2.352 1 1 0 101.4 1.422 3.794 3.794 0 00.418-.488.249.249 0 01.173-.1.252.252 0 01.193.059 4.24 4.24 0 005.526 0 .252.252 0 01.193-.059.249.249 0 01.173.1 3.959 3.959 0 00.418.488 1 1 0 001.406-1.422 3.07 3.07 0 01-.7-2.352zM5.25 16.5a2.253 2.253 0 01-2.231-1.962.251.251 0 01.2-.278 3.409 3.409 0 00.909-.28 2.471 2.471 0 00.916-.74.25.25 0 01.4 0 2.471 2.471 0 00.916.74 3.409 3.409 0 00.909.28.25.25 0 01.2.278A2.253 2.253 0 015.25 16.5zM20.8 18.668a.5.5 0 00-.629.21l-1.208 2.1a.25.25 0 01-.434 0l-1.208-2.1a.5.5 0 00-.629-.21A5.26 5.26 0 0013.5 23.5a.5.5 0 00.5.5h9.5a.5.5 0 00.5-.5 5.26 5.26 0 00-3.2-4.832z\"/><path d=\"M14.5 14.233a3.07 3.07 0 01-.7 2.352 1 1 0 101.4 1.422 3.794 3.794 0 00.418-.488.249.249 0 01.173-.1.252.252 0 01.193.059 4.24 4.24 0 005.526 0 .252.252 0 01.193-.059.249.249 0 01.173.1 3.959 3.959 0 00.418.488 1 1 0 101.406-1.422 3.07 3.07 0 01-.7-2.352 4.25 4.25 0 00-8.5 0zm2.223.027a3.409 3.409 0 00.909-.28 2.471 2.471 0 00.916-.74.25.25 0 01.4 0 2.471 2.471 0 00.916.74 3.409 3.409 0 00.909.28.25.25 0 01.2.278 2.249 2.249 0 01-4.462 0 .251.251 0 01.212-.278z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});