define("ember-svg-jar/inlined/tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags</title><path d=\"M1.382 11.384L13.16 22.953l.017.016a2 2 0 002.808-.021l7.065-7.063a2 2 0 00-.012-2.841L11.3 1.5c-.018-.019-.035-.037-.054-.054A3.751 3.751 0 008.855.465H2.363a2 2 0 00-2 2L.37 8.96a3.784 3.784 0 001.012 2.424zm3.481-4.919a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});