define("ember-svg-jar/inlined/taking-pictures-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>taking-pictures-man</title><path d=\"M14.5 13.5h-.267a.25.25 0 00-.242.23A3 3 0 0111 16.5H7.688a.251.251 0 00-.185.081A9.465 9.465 0 005 23a.5.5 0 00.5.5h18a.5.5 0 00.5-.5 9.511 9.511 0 00-9.5-9.5z\"/><path d=\"M11 15a1.5 1.5 0 001.5-1.5V8A1.5 1.5 0 0011 6.5H9.96a.249.249 0 01-.219-.129A1.45 1.45 0 008.382 5.5H6.118a1.45 1.45 0 00-1.359.871.249.249 0 01-.219.129H1.5A1.5 1.5 0 000 8v5.5A1.5 1.5 0 001.5 15zM7.25 8.25A2.25 2.25 0 115 10.5a2.25 2.25 0 012.25-2.25z\"/><path d=\"M18.188 6.036a.251.251 0 01.3.229c.005.078.007.156.007.235a4 4 0 01-4 4c-.079 0-.157 0-.235-.007a.254.254 0 00-.187.068.25.25 0 00-.078.182v1.5a.25.25 0 00.238.25c.087 0 .174.006.262.006A6 6 0 109.857 2.7 1 1 0 0010 4.114a9.636 9.636 0 008.188 1.922z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});