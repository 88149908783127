define("ember-svg-jar/inlined/grid-dot-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>grid-dot-alternate</title><path d=\"M21.5 0h-19A2.5 2.5 0 000 2.5v19A2.5 2.5 0 002.5 24h19a2.5 2.5 0 002.5-2.5v-19A2.5 2.5 0 0021.5 0zm-17 18.25a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm-1.5-6a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5zM5.5 16A1.5 1.5 0 117 17.5 1.5 1.5 0 015.5 16zM8 12.25a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5zm1.5 6a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm1-2.25a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5zm2.5-3.75a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5zm1.5 6a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm1-2.25a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5zm4 2.25a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm0-4.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});