define("ember-svg-jar/inlined/car-repair-engine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-repair-engine</title><path d=\"M22.907 13.92l-2.313-1.872-1.871-3.369a2.537 2.537 0 00-2.216-1.3h-5.5a2.518 2.518 0 00-2.055 1.053l-2.14 2.687a.251.251 0 01-.34.049L4.691 9.917a4.2 4.2 0 00-4.1-.4 1 1 0 00.812 1.829 2.191 2.191 0 012.136.208l.9.63a.25.25 0 01-.055.439l-1.8.681a2.588 2.588 0 00.149 5 .25.25 0 00.287-.142 2.982 2.982 0 015.488.061.251.251 0 00.231.154h7.779a.249.249 0 00.23-.154 2.987 2.987 0 015.446-.168.25.25 0 00.321.12A2.42 2.42 0 0024 15.945a2.377 2.377 0 00-1.093-2.025zM15 12.375a1 1 0 01-1 1H9.247a.5.5 0 01-.416-.778l1.679-2.519a1.575 1.575 0 011.314-.7H14a1 1 0 011 1z\"/><circle cx=\"5.747\" cy=\"19.375\" r=\"2\"/><circle cx=\"19.5\" cy=\"19.375\" r=\"2\"/><path d=\"M.582 6.375a2.151 2.151 0 00.635 1.531.75.75 0 001.06-1.062.655.655 0 01-.2-.469.665.665 0 01.2-.47 2.164 2.164 0 000-3.06.75.75 0 00-1.062 1.06.664.664 0 010 .94 2.149 2.149 0 00-.633 1.53zM4.082 6.375a2.151 2.151 0 00.635 1.531.75.75 0 001.06-1.062.655.655 0 01-.2-.469.665.665 0 01.2-.47 2.164 2.164 0 000-3.06.75.75 0 00-1.062 1.06.664.664 0 010 .94 2.149 2.149 0 00-.633 1.53z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});