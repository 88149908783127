define("ember-svg-jar/inlined/road-sign-mudslide-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-mudslide-alternate</title><path d=\"M23.707 12.707a1 1 0 000-1.414l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0zm-11.53 8.7a.25.25 0 01-.354 0l-9.232-9.23a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/><path d=\"M17 15a.75.75 0 00-.75-.75h-3a.251.251 0 01-.24-.181l-.722-2.527a1.761 1.761 0 00-.445-.756l-1.02-1.023a.257.257 0 01-.073-.177V7.707a1.25 1.25 0 00-2.134-.884L6.47 8.97a.747.747 0 00-.22.53V15a.75.75 0 00.75.75h9.25A.75.75 0 0017 15z\"/><circle cx=\"15.5\" cy=\"10.5\" r=\"1.5\"/><circle cx=\"13\" cy=\"7.5\" r=\".75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});