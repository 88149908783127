define("ember-svg-jar/inlined/safety-call-firefighters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-call-firefighters</title><path d=\"M12.812 18.164a1.6 1.6 0 00-2.252.086l-.44.427a.25.25 0 01-.334.012 32.281 32.281 0 01-4.514-4.644.249.249 0 01.021-.334l.439-.426a1.6 1.6 0 00.151-2.25L4.306 9.414a1.6 1.6 0 00-2.252.086l-.988.96a2.459 2.459 0 00-.5 3.012 31.612 31.612 0 009.66 9.937A2.459 2.459 0 0013.249 23l.989-.961a1.6 1.6 0 00.149-2.249zM8.949 5.446a.87.87 0 00-.279.991 5.908 5.908 0 005.517 3.835h5.182a4.941 4.941 0 012.778.866.883.883 0 00.489.149.877.877 0 00.819-1.181 5.909 5.909 0 00-5.429-3.834h-5.27a4.94 4.94 0 01-2.778-.865.873.873 0 00-1.029.039zM23.25 12.771h-6.118a4.945 4.945 0 01-2.779-.865.875.875 0 00-1.307 1.031 5.908 5.908 0 005.517 3.835h4.687a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.501zM23.25 1.257h-6.124a4.94 4.94 0 01-2.781-.868.876.876 0 00-1.305 1.034 5.909 5.909 0 005.518 3.834h4.692a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});