define("ember-svg-jar/inlined/app-window-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-badge</title><path d=\"M14 19.7a1 1 0 00-1-1H3a1 1 0 01-1-1V5.452a.25.25 0 01.25-.25h19.5a.25.25 0 01.25.25V7.7a1 1 0 002 0V3.2a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h10a1 1 0 001-1zM9.654 2.2a.966.966 0 011.692 0 .972.972 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.972.972 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0A.972.972 0 018 2.7a.969.969 0 01-.154.5.966.966 0 01-1.692 0A.969.969 0 016 2.7a.972.972 0 01.154-.5zm-3.562.092A1 1 0 013.5 1.7a.985.985 0 01.846.5.972.972 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.979.979 0 01.092-.406zM19.251 19.369a2.977 2.977 0 01-2.382-1.176.247.247 0 00-.162-.1 2.893 2.893 0 01-.152-.029.25.25 0 00-.306.244V23.3a.5.5 0 00.821.384L19.089 22a.251.251 0 01.32 0l2.02 1.683a.5.5 0 00.82-.384v-4.988a.249.249 0 00-.093-.2.257.257 0 00-.212-.049 1.89 1.89 0 01-.15.029.249.249 0 00-.162.1 2.975 2.975 0 01-2.381 1.178z\"/><path d=\"M23.5 12.2a2 2 0 00-2-2 1.793 1.793 0 00-.322.031.251.251 0 01-.256-.11 1.979 1.979 0 00-3.345 0 .251.251 0 01-.256.11A1.793 1.793 0 0017 10.2a2 2 0 00-2 2 1.97 1.97 0 00.519 1.332.251.251 0 010 .336A1.972 1.972 0 0015 15.2a2 2 0 002 2 1.794 1.794 0 00.322-.03.25.25 0 01.256.11 1.98 1.98 0 003.345 0 .25.25 0 01.256-.11 1.794 1.794 0 00.322.03 2 2 0 002-2 1.972 1.972 0 00-.519-1.332.251.251 0 010-.336A1.97 1.97 0 0023.5 12.2zm-4.251 3a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});