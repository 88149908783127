define("ember-svg-jar/inlined/color-picker-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-picker-4</title><path d=\"M17 9a1 1 0 000-2h-.75a.25.25 0 01-.25-.25V4.5A4.277 4.277 0 0012 0a4.277 4.277 0 00-4 4.5v2.25a.25.25 0 01-.25.25H7a1 1 0 000 2zM8.25 10.5a.25.25 0 00-.25.25v6.75a5.19 5.19 0 001.9 4.042.25.25 0 01.1.2V23a1 1 0 001 1h2a1 1 0 001-1v-1.257a.25.25 0 01.1-.2A5.19 5.19 0 0016 17.5v-6.75a.25.25 0 00-.25-.25zm2.25 3.75a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25V18a1.5 1.5 0 01-3 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});