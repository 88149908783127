define("ember-svg-jar/inlined/real-estate-insurance-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-insurance-house</title><rect y=\"14.058\" width=\"4\" height=\"9\" rx=\"1\" ry=\"1\"/><path d=\"M23.724 19.067a.5.5 0 00.129-.8 3.236 3.236 0 00-3.482-1.13c-.058.017-1.967.628-2.024.646a.5.5 0 00-.348.485 2.25 2.25 0 01-2.249 2.29H10.5a.5.5 0 010-1h5.25a1.25 1.25 0 000-2.5l-3.557.007a.494.494 0 01-.272-.08c-.122-.078-.286-.2-.428-.3A6.836 6.836 0 007.5 15.058h-2a.5.5 0 00-.5.5v5.9a.5.5 0 00.339.474c.706.239 1.341.46 1.917.66C9.88 23.5 11.344 24 12.674 24c1.624 0 3.049-.742 6.139-2.386 1.281-.681 2.876-1.529 4.911-2.547zM18.168 2.919A.5.5 0 0019 2.545V1a1 1 0 00-1-1h-1.8a.5.5 0 00-.332.874zM5.5 7.5h2A.5.5 0 018 8v5a1 1 0 001 1h1.5a1 1 0 001-1v-2.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5V13a1 1 0 001 1H17a1 1 0 001-1V8a.5.5 0 01.5-.5h2a.5.5 0 00.332-.874L13.664.252a1 1 0 00-1.328 0L5.168 6.626A.5.5 0 005.5 7.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});