define("ember-svg-jar/inlined/single-man-actions-edit-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-edit-2</title><path d=\"M16.076 13.1a.251.251 0 00.035-.31 2.167 2.167 0 00-1.023-.866l-3.936-1.687a.249.249 0 01-.152-.231V8.87a.252.252 0 01.074-.177 3.305 3.305 0 00.779-2.23.251.251 0 01.088-.176 1.874 1.874 0 00.538-1.4 1.575 1.575 0 00-.317-1.014.253.253 0 01-.034-.265 2.531 2.531 0 00.156-1.727C11.944.493 10.241 0 8.888 0a3.753 3.753 0 00-3.163 1.325.252.252 0 01-.213.118 1.071 1.071 0 00-.9.375c-.371.493-.1 1.17.176 1.732a.249.249 0 01-.045.287 1.312 1.312 0 00-.162.2A1.76 1.76 0 004.319 5a1.525 1.525 0 00.639 1.332.249.249 0 01.117.193A3.165 3.165 0 005.92 8.7a.251.251 0 01.08.183v1.123a.248.248 0 01-.151.229l-3.937 1.687C.64 12.468.457 13.626.205 15.231c-.055.358-.117.751-.2 1.179A.5.5 0 00.5 17h11.57a.247.247 0 00.176-.073zM19.529 11.765l-6.679 6.679a.249.249 0 000 .353l3.35 3.355a.251.251 0 00.354 0l6.679-6.68a2.621 2.621 0 00-3.707-3.707zM12.062 20.131a.25.25 0 00-.422.127l-.629 3.142a.5.5 0 00.491.6.457.457 0 00.1-.01l3.143-.628a.251.251 0 00.128-.422z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});