define("ember-svg-jar/inlined/move-bottom-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>move-bottom-right</title><path d=\"M21.5 6a1 1 0 00-1 1v13.25a.25.25 0 01-.25.25H7a1 1 0 00-1 1V23a1 1 0 001 1h15a2 2 0 002-2V7a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});