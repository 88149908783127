define("ember-svg-jar/inlined/ticket-buy.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ticket-buy</title><path d=\"M24 2.25a1 1 0 00-1-1H1a1 1 0 00-1 1v5a1 1 0 001 1h3.25a.5.5 0 01.5.5v8.5a1 1 0 001 1H9.5a1 1 0 001-1 1.5 1.5 0 013 0 1 1 0 001 1h3.75a1 1 0 001-1v-8.5a.5.5 0 01.5-.5H23a1 1 0 001-1zm-8.464 14a.251.251 0 01-.236-.167 3.5 3.5 0 00-6.6 0 .25.25 0 01-.236.167H7a.25.25 0 01-.25-.25V4.25A.25.25 0 017 4h10a.25.25 0 01.25.25V16a.25.25 0 01-.25.25zM12.54 19.158a.774.774 0 00-1.149 0l-1.968 2.363A.749.749 0 0010 22.75h3.933a.75.75 0 00.576-1.23z\"/><path d=\"M10 8h4a.75.75 0 000-1.5h-4A.75.75 0 0010 8z\"/><circle cx=\"9.5\" cy=\"11.75\" r=\".75\"/><circle cx=\"12\" cy=\"11.75\" r=\".75\"/><circle cx=\"14.5\" cy=\"11.75\" r=\".75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});