define("ember-svg-jar/inlined/business-contract-handshake-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-contract-handshake-sign</title><path d=\"M3.51 1.094v11.5a1 1 0 002 0v-10a.5.5 0 01.5-.5h9.379a.5.5 0 01.353.146l2.621 2.621a.5.5 0 01.147.354v7.379a1 1 0 002 0v-8a1 1 0 00-.293-.707l-3.5-3.5a1 1 0 00-.707-.293H4.51a1 1 0 00-1 1z\"/><path d=\"M16.674 21.252a.253.253 0 01.106.162 1.751 1.751 0 001.72 1.43H21a.75.75 0 00.75-.75v-7a.75.75 0 00-.75-.75h-2.5a1.737 1.737 0 00-.851.222.25.25 0 01-.212.015l-2.856-1.093a2.448 2.448 0 00-1.892.107l-1.487.72a.248.248 0 01-.232-.006 2.2 2.2 0 00-1.919-.117l-1.939.827a.251.251 0 01-.288-.067 1.745 1.745 0 00-1.324-.608H3a.75.75 0 00-.75.75v7a.75.75 0 00.75.75h2.5a1.751 1.751 0 001.515-.875.251.251 0 01.216-.125h.321a.255.255 0 01.155.053l2.015 1.585a1.43 1.43 0 001.925.1l2.207-1.81a.26.26 0 01.11-.052l2.521-.5a.252.252 0 01.189.032zm-2.734-1.486l-3.152 2.662s-.1-.09-2.426-1.923a.747.747 0 00-.464-.161H7.5a.25.25 0 01-.25-.25v-3.339a.249.249 0 01.152-.23l2.221-.946a.142.142 0 01.145.025.14.14 0 01.043.141 1.955 1.955 0 002.621 2.3l.056-.024a.252.252 0 01.28.064l1.2 1.317a.256.256 0 01.064.185.252.252 0 01-.092.179zm1.818-.025a.253.253 0 01-.1-.186 1.531 1.531 0 00-.333-.876l-1.922-2.117a.757.757 0 00-.841-.191l-.7.287a.45.45 0 01-.586-.252c-.113-.289.031-.409.214-.566l1.856-.9a.947.947 0 01.706-.045l2.534.969a.246.246 0 01.158.23v3.333a.25.25 0 01-.2.245l-.588.117a.247.247 0 01-.198-.048zM11.5 6.344a.75.75 0 000-1.5h-3a.75.75 0 000 1.5zM8.51 10.344h7.5a.75.75 0 000-1.5h-7.5a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});