define("ember-svg-jar/inlined/messages-bubble-square-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-lock</title><path d=\"M13.9 13.889a.252.252 0 00.134-.195A5.007 5.007 0 0119 9.25a4.914 4.914 0 012.141.5.25.25 0 00.359-.225V1.5A1.5 1.5 0 0020 0H1.5A1.5 1.5 0 000 1.5v12.053A1.459 1.459 0 001.5 15H3v3.5a.5.5 0 00.832.374L8.19 15h4.319a.249.249 0 00.2-.1 3.522 3.522 0 011.191-1.011z\"/><path d=\"M22.5 15.5v-1.25a3.5 3.5 0 00-7 0v1.25A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.5-1.5zM19 12.75a1.5 1.5 0 011.5 1.5v1.25h-3v-1.25a1.5 1.5 0 011.5-1.5zm1 7.5a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});