define("ember-svg-jar/inlined/business-rabbit-hat-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-rabbit-hat-1</title><path d=\"M8.216 11.532h7.04a.25.25 0 00.238-.172c.19-.575.407-1.214.636-1.852a.25.25 0 01.333-.146c1 .423 2.256.614 2.846.024 1.088-1.088.537-2.192.094-3.077A5.845 5.845 0 0017 4.042a4.044 4.044 0 00-2.771-.385 1.143 1.143 0 00-.684.691c-.036.1-.833 2.976-1.487 5.34a.25.25 0 01-.486-.016c-.38-1.837-.845-4.005-1.173-5.266C9.976 2.8 9.138 2.353 8.675 2.231a1.861 1.861 0 00-1.813.585 3.379 3.379 0 00-.62 3.362c.4 1.331 1.133 3.456 1.739 5.186a.248.248 0 00.235.168zM4.058 14.323a1 1 0 001 1h1.856a.249.249 0 01.247.285L6.5 20.27a1.3 1.3 0 00.386 1.112 1.574 1.574 0 001.1.441h6.91a1.571 1.571 0 001.1-.44 1.3 1.3 0 00.387-1.113l-.665-4.661a.249.249 0 01.247-.285h2.1a1 1 0 000-2h-13a1 1 0 00-1.007.999z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});