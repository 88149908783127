define("ember-svg-jar/inlined/bathroom-mirror-cabinet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bathroom-mirror-cabinet</title><path d=\"M20.66 15.51a10.045 10.045 0 00-6.32 0 .508.508 0 00-.34.48V22a1 1 0 001 1h5a1 1 0 001-1v-6.01a.508.508 0 00-.34-.48zM12.5 16.75H12a.75.75 0 000 1.5h.5a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5zM23 16.75h-.5a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h.5a.75.75 0 000-1.5zM23.25 11h-.75a.5.5 0 01-.5-.5V1.25A1.25 1.25 0 0020.75 0H3.25A1.25 1.25 0 002 1.25v9.25a.5.5 0 01-.5.5H.75a.75.75 0 000 1.5h22.5a.75.75 0 000-1.5zm-15.2-1h-1a3 3 0 01-2.94-2.4.526.526 0 01.1-.42A.532.532 0 014.6 7h5.9a.532.532 0 01.39.18.526.526 0 01.1.42A3 3 0 018.05 10zm8.9 0h-1a3 3 0 01-2.94-2.4.526.526 0 01.1-.42.532.532 0 01.39-.18h5.9a.532.532 0 01.39.18.526.526 0 01.1.42 3 3 0 01-2.94 2.4zM8 17.75H7a.25.25 0 01-.25-.25 2.25 2.25 0 00-4.5 0 .25.25 0 01-.25.25H1a.75.75 0 000 1.5h.12a.5.5 0 01.5.45L2 23.55a.5.5 0 00.5.45h4a.5.5 0 00.5-.45l.385-3.85a.5.5 0 01.5-.45H8a.75.75 0 000-1.5zm-3 0H4a.25.25 0 01-.25-.25.75.75 0 011.5 0 .25.25 0 01-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});