define("ember-svg-jar/inlined/monitor-cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-cash</title><path d=\"M11.1 11.915a.249.249 0 00-.188-.414H2.5A.5.5 0 012 11V2.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v6.347a.25.25 0 00.287.247A8.216 8.216 0 0117.5 9c.079 0 .157 0 .235.008a.254.254 0 00.187-.068.25.25 0 00.078-.181V2a2 2 0 00-2-2H2a2 2 0 00-2 2v10.5a2 2 0 002 2h5.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5H5a1 1 0 000 2h3.758a.251.251 0 00.25-.265C9 17.658 9 17.58 9 17.5a8.464 8.464 0 012.1-5.585z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm.95 10.2a.249.249 0 00-.2.245v.308a.75.75 0 11-1.5 0V21.5a.25.25 0 00-.25-.25H16a.75.75 0 010-1.5h2.033a.592.592 0 00.221-1.141l-2.065-.826a2.085 2.085 0 01.361-3.983.249.249 0 00.2-.245v-.307a.75.75 0 111.5 0v.25a.25.25 0 00.25.25h.5a.75.75 0 010 1.5h-2.033a.591.591 0 00-.221 1.14l2.065.826a2.085 2.085 0 01-.361 3.986z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});