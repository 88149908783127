define("ember-svg-jar/inlined/arrow-thick-left-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-left-2</title><path d=\"M21.864 9.5H10.257a.25.25 0 01-.174-.43l3.864-3.721a2.609 2.609 0 00-.075-3.682 2.612 2.612 0 00-3.68-.077L.4 11.289A1 1 0 00.392 12.7l9.625 9.724a2.66 2.66 0 003.755-3.757l-3.729-3.733a.25.25 0 01.177-.427h11.673c1.556 0 2-1.675 2-2.51A2.28 2.28 0 0021.864 9.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});