define("ember-svg-jar/inlined/smart-house-phone-connect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-house-phone-connect</title><path d=\"M4.3 13.148a8.021 8.021 0 01-.294-2.156 1 1 0 00-2 0 10.04 10.04 0 00.366 2.692 1 1 0 001.928-.536zM7.093 17.3A8.136 8.136 0 015.6 15.781a1 1 0 10-1.6 1.2 10.1 10.1 0 001.864 1.9A1 1 0 107.093 17.3zM12 18.986a8 8 0 01-2.253-.322 1 1 0 00-.561 1.919 10.048 10.048 0 002.814.4 1 1 0 000-2zM19.266 7.652a8.024 8.024 0 01.553 1.666 1 1 0 101.954-.418 9.909 9.909 0 00-.692-2.085 1 1 0 10-1.815.835zM16.483 4.371a8.137 8.137 0 011.122.921 1 1 0 101.4-1.424 10.081 10.081 0 00-1.4-1.148 1 1 0 10-1.125 1.651zM14.88 2.41a1 1 0 00-.767-1.187 10.242 10.242 0 00-3.271-.159 1 1 0 10.225 1.987 8.111 8.111 0 012.626.126 1 1 0 001.187-.767zM9.481 4.278a.331.331 0 00-.1-.369L4.975.079a.345.345 0 00-.439 0L.129 3.909a.329.329 0 00-.1.369.339.339 0 00.319.222h.91a.25.25 0 01.25.25v3.244a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V6.745a1.25 1.25 0 012.5 0v1.249a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V4.747a.25.25 0 01.25-.25h.91a.339.339 0 00.313-.219zM22.518 12.009H17.47A1.491 1.491 0 0016 13.516v8.977A1.493 1.493 0 0017.47 24h5.048a1.492 1.492 0 001.473-1.507v-8.977a1.492 1.492 0 00-1.473-1.507zm-.525 8.491a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-5.975a.5.5 0 01.5-.5h3a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});