define("ember-svg-jar/inlined/video-file-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-warning</title><path d=\"M13.254 13.891l5.846-3.236a.735.735 0 000-1.31l-5.846-3.236a.888.888 0 00-.835-.01.758.758 0 00-.42.665v6.472a.758.758 0 00.42.665.891.891 0 00.835-.01z\"/><path d=\"M23.414 3L21 .585A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.819a.247.247 0 00.234.249 7.423 7.423 0 011.443.215.248.248 0 00.323-.237V2.5a.5.5 0 01.5-.5h10.878a.5.5 0 01.354.146l2.121 2.122a.5.5 0 01.147.353V18a.5.5 0 01-.5.5h-8.343a.25.25 0 00-.222.366l.787 1.5a.25.25 0 00.221.134H22a2 2 0 002-2V4.414A2 2 0 0023.414 3z\"/><path d=\"M7.78 12.271a1.449 1.449 0 00-2.561 0L.164 21.9a1.428 1.428 0 00.047 1.409A1.457 1.457 0 001.444 24h10.111a1.458 1.458 0 001.233-.686 1.428 1.428 0 00.047-1.409zM5.749 16a.75.75 0 011.5 0v2.5a.75.75 0 01-1.5 0zm.75 6.25a1 1 0 111-1 1 1 0 01-.999 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});