define("ember-svg-jar/inlined/tank-top-female-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tank-top-female-1</title><path d=\"M20.8 10.267a4.131 4.131 0 00-.574-5.288 2.991 2.991 0 01-.87-1.784l-.292-2.32A1 1 0 0018.072 0H16.5a1 1 0 00-1 1v1a2.483 2.483 0 01-1.055 2.359 10.863 10.863 0 00-2.024 2.373.5.5 0 01-.842 0 10.863 10.863 0 00-2.024-2.373A2.483 2.483 0 018.5 2V1a1 1 0 00-1-1H5.928a1 1 0 00-.992.875L4.644 3.2a2.984 2.984 0 01-.869 1.784 4.131 4.131 0 00-.575 5.283l.534.8a3.163 3.163 0 01.48 2.333L3.5 22.925A1 1 0 004.5 24h15a1 1 0 001-1.075l-.72-9.575a3.116 3.116 0 01.485-2.285z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});