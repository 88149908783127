define("ember-svg-jar/inlined/shipment-crane-hook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-crane-hook</title><path d=\"M16.555.427A.25.25 0 0016.378 0h-3.12a.251.251 0 00-.177.073L5.827 7.327a.252.252 0 00-.063.25 4.447 4.447 0 00.2.541l.766 1.657a.251.251 0 00.4.072zM18.427 4.491a.247.247 0 00.073-.176V1.5a1.528 1.528 0 00-.034-.319.25.25 0 00-.421-.123L7.674 11.428a.251.251 0 00-.051.282l.5 1.091a2.11 2.11 0 00.836.889.25.25 0 00.305-.038zM10.606.427A.25.25 0 0010.43 0H7a1.5 1.5 0 00-1.5 1.5v3.43a.25.25 0 00.427.176zM11.073 13.967a.255.255 0 00-.073.177v.772a.249.249 0 01-.184.241A4.5 4.5 0 1016.5 19.5a1 1 0 00-2 0 2.5 2.5 0 11-3.056-2.438A1.989 1.989 0 0013 15.112v-.862a.25.25 0 01.25-.25H14a2.221 2.221 0 001.875-1.2l2.157-4.64a4.654 4.654 0 00.268-.76.25.25 0 00-.418-.241z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});