define("ember-svg-jar/inlined/read-art", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-art</title><path d=\"M20.75 0H4.25a2 2 0 00-2 2v19a3 3 0 003 3h13.5a2 2 0 002-2v-1.584a.5.5 0 01.333-.471A1 1 0 0021.75 19V1a1 1 0 00-1-1zm-2 21.5a.5.5 0 01-.5.5h-13a1 1 0 010-2h13a.5.5 0 01.5.5zm.686-10.763a3.959 3.959 0 01-1.386 1.14 1 1 0 00-.43.4c-.416.719-1.531 2.67-2.389 4.385a2 2 0 01-2.683.894 1.812 1.812 0 01-1.015-2.238.25.25 0 00-.353-.285 3.109 3.109 0 01-1.351.316 3.63 3.63 0 01-1.2-.214A6.315 6.315 0 014.144 9.3C4.144 5.825 7.352 3 11.3 3a7.83 7.83 0 014.267 1.238.5.5 0 00.563-.011 2 2 0 012.352.028A4.837 4.837 0 0120.2 7.473a4.312 4.312 0 01-.764 3.265z\"/><path d=\"M13.355 11.5a.75.75 0 00-.633-.851 2.256 2.256 0 00-2.356 1.387c-.3.687-.556.6-.824.5A3.611 3.611 0 016.894 9.3c0-1.954 1.974-3.544 4.4-3.544a5.1 5.1 0 012.641.712.75.75 0 10.777-1.283 6.59 6.59 0 00-3.412-.934c-3.255 0-5.9 2.263-5.9 5.044a5.082 5.082 0 003.65 4.661 2.037 2.037 0 002.693-1.31.775.775 0 01.768-.51.75.75 0 00.844-.636z\"/><circle cx=\"8.798\" cy=\"10.211\" r=\".75\"/><circle cx=\"9.548\" cy=\"7.711\" r=\".75\"/><circle cx=\"12.048\" cy=\"7.211\" r=\".75\"/><path d=\"M17.581 5.459a.5.5 0 00-.5-.055.5.5 0 00-.293.414 1.739 1.739 0 01-.849 1.475c-.727.566-1.237 1.129-1 2.039a1.822 1.822 0 00.285.608.5.5 0 01.041.515l-2.489 4.978a.75.75 0 001.342.67l2.593-5.185a.5.5 0 01.327-.262 2.285 2.285 0 001.22-.84 2.84 2.84 0 00.462-2.143 3.375 3.375 0 00-1.139-2.214z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});