define("ember-svg-jar/inlined/single-woman-actions-key.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-key</title><path d=\"M10.435 16.336a5.987 5.987 0 011.923-1.862.5.5 0 00.092-.783A7.479 7.479 0 007.2 11.5a7.541 7.541 0 00-7.179 5.359.5.5 0 00.479.641h9.027a.5.5 0 00.462-.307 5.35 5.35 0 01.446-.857zM24 11.628a.5.5 0 00-.5-.5h-1.977a.5.5 0 00-.343.136l-4.188 3.957a.251.251 0 01-.245.058A4.455 4.455 0 1015.466 24a4.453 4.453 0 004.259-5.731.248.248 0 01.063-.249l4.065-4.058a.5.5 0 00.147-.356zm-7.162 8.99a1.736 1.736 0 110-2.456 1.738 1.738 0 010 2.456zM1.568 10.442a1 1 0 001.383-.3 5.233 5.233 0 00.454-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .249.249 0 01.411.082 5.233 5.233 0 00.454.9 1 1 0 001.681-1.082 4.561 4.561 0 01-.643-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248A4.573 4.573 0 011.27 9.06a1 1 0 00.298 1.382zM7.21 8.6a2.706 2.706 0 01-2.742-2.352.5.5 0 01.324-.577 5.708 5.708 0 002.246-1.439.249.249 0 01.36 0 5.719 5.719 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.21 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});