define("ember-svg-jar/inlined/crime-woman-thief", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-woman-thief</title><path d=\"M11.021 12.977h1.959a.626.626 0 00.3-1.177 2.293 2.293 0 00-.427-.177 2.892 2.892 0 00-.845-.123H12a2.911 2.911 0 00-.849.124 2.378 2.378 0 00-.426.175.626.626 0 00.3 1.178z\"/><path d=\"M22.533 18.917a15.02 15.02 0 00-6.821-2.634.25.25 0 01-.212-.247v-.858a.219.219 0 01.106-.188l.052-.032a.254.254 0 01.181-.032c.726.148 2.9.88 4.673-.023a.981.981 0 00.544-.827 1.033 1.033 0 00-.466-.917C18.562 11.818 19 9.081 19 9V7.216A7.136 7.136 0 0013.568.171 7.013 7.013 0 005 7v2c0 .081.437 2.818-1.591 4.158a1.035 1.035 0 00-.467.918.981.981 0 00.544.827c1.39.706 2.908.453 4.669.028a.249.249 0 01.19.03l.034.02a.252.252 0 01.121.219v.841a.25.25 0 01-.212.247 15.015 15.015 0 00-6.821 2.634A2.467 2.467 0 00.5 20.894V23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-2.606a2.462 2.462 0 00-.967-1.977zM16 8a.5.5 0 01-.5.5h-1a.5.5 0 010-1h1a.5.5 0 01.5.5zm-6 0a.5.5 0 01-.5.5h-1a.5.5 0 010-1h1a.5.5 0 01.5.5zm2.155-.668a.249.249 0 01-.337 0 5.663 5.663 0 00-1.526-1.006.251.251 0 01-.009-.452 5.234 5.234 0 001.533-1.161.249.249 0 01.368 0 5.228 5.228 0 001.53 1.158.25.25 0 01-.01.453 5.773 5.773 0 00-1.549 1.008zm-.31 2.084a.25.25 0 01.337 0 6.252 6.252 0 004.011 1.578.249.249 0 01.2.368 4.985 4.985 0 01-8.8 0 .25.25 0 010-.24.253.253 0 01.2-.128 6.341 6.341 0 004.052-1.578z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});