define("ember-svg-jar/inlined/material-brick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>material-brick</title><rect x=\"1.5\" y=\"6\" width=\"4\" height=\"5\" rx=\".5\" ry=\".5\"/><rect x=\"12.5\" y=\"6\" width=\"5\" height=\"5\" rx=\".5\" ry=\".5\"/><rect x=\"9\" width=\"6\" height=\"5\" rx=\".5\" ry=\".5\"/><rect x=\"6.5\" y=\"6\" width=\"5\" height=\"5\" rx=\".5\" ry=\".5\"/><rect x=\"9\" y=\"12\" width=\"6\" height=\"4.5\" rx=\".5\" ry=\".5\"/><rect x=\"18.5\" y=\"6\" width=\"4\" height=\"5\" rx=\".5\" ry=\".5\"/><path d=\"M3 16.5h4.5A.5.5 0 008 16v-3.5a.5.5 0 00-.5-.5H2a.5.5 0 00-.5.5V15A1.5 1.5 0 003 16.5zM16.5 16.5H21a1.5 1.5 0 001.5-1.5v-2.5a.5.5 0 00-.5-.5h-5.5a.5.5 0 00-.5.5V16a.5.5 0 00.5.5zM2 5h5.5a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5H3a1.5 1.5 0 00-1.5 1.5v3A.5.5 0 002 5zM16.5 5H22a.5.5 0 00.5-.5v-3A1.5 1.5 0 0021 0h-4.5a.5.5 0 00-.5.5v4a.5.5 0 00.5.5zM23 22h-2a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h2a1 1 0 000-2H1a1 1 0 000 2h2a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H1a1 1 0 000 2h22a1 1 0 000-2zm-12-.5a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5v-1A.5.5 0 016 20h4.5a.5.5 0 01.5.5zm7.5 0a.5.5 0 01-.5.5h-4.5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5H18a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});