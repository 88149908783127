define("ember-svg-jar/inlined/lift", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lift</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-7.7 2.776a.5.5 0 01.45-.276h3a.5.5 0 01.4.8l-1.5 2a.5.5 0 01-.8 0l-1.5-2a.5.5 0 01-.05-.524zM5.35 4.7l1.5-2a.518.518 0 01.8 0l1.5 2a.5.5 0 01-.4.8h-3a.5.5 0 01-.4-.8zM11 21.5a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5V9a.5.5 0 01.5-.5h6.5a.5.5 0 01.5.5zm9.5 0a.5.5 0 01-.5.5h-6.5a.5.5 0 01-.5-.5V9a.5.5 0 01.5-.5H20a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});