define("ember-svg-jar/inlined/phone-actions-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-lock</title><path d=\"M17.169 16.162a2.5 2.5 0 00-3.536 0l-.386.385a47.045 47.045 0 01-5.789-5.79l.386-.385a2.505 2.505 0 000-3.535L5.722 4.716a2.558 2.558 0 00-3.536 0L1.022 5.879a3.507 3.507 0 00-.441 4.4 46.906 46.906 0 0013.141 13.145 3.541 3.541 0 004.4-.443l1.163-1.163a2.5 2.5 0 000-3.535zM22.5 4.757v-1.25a3.5 3.5 0 10-7 0v1.25a1.5 1.5 0 00-1.5 1.5v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5v-5.5a1.5 1.5 0 00-1.5-1.5zM20 9.507a1 1 0 11-1-1 1 1 0 011 1zm-2.5-6a1.5 1.5 0 013 0v1.25h-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});