define("ember-svg-jar/inlined/single-woman-actions-sync.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-sync</title><path d=\"M9.254 17.5a.25.25 0 00.25-.242A7.352 7.352 0 0112 12.007a.25.25 0 00-.08-.422l-.444-.162a.779.779 0 01-.513-.73v-.467a.25.25 0 01.25-.25A6.37 6.37 0 0015 9.081a.388.388 0 00.084-.593c-.494-.537-.835-1.185-.981-3.317C13.838 1.346 11.236 0 9 0S4.161 1.346 3.9 5.171c-.146 2.132-.487 2.78-.981 3.317A.388.388 0 003 9.081a6.348 6.348 0 003.782.9.251.251 0 01.254.25v.462a.779.779 0 01-.513.73c-2.9 1.065-5.212 1.825-5.764 2.922A7.822 7.822 0 000 17.076a.386.386 0 00.1.3.4.4 0 00.29.126z\"/><path d=\"M21.972 20.2a1 1 0 00-1.406.158 4.376 4.376 0 11-.495-5.982.25.25 0 01.009.362l-1.39 1.391a.5.5 0 00.353.854H23.5a.5.5 0 00.5-.5v-4.462a.5.5 0 00-.854-.353l-1.3 1.3a.25.25 0 01-.348.006 6.374 6.374 0 10.628 8.631 1 1 0 00-.154-1.405z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});