define("ember-svg-jar/inlined/single-neutral-actions-chat.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-chat</title><path d=\"M9.5 12a3.471 3.471 0 011.1-2.528.251.251 0 00.079-.184 2.264 2.264 0 01.11-.8 5.618 5.618 0 001.466-4.314C12.256 1.756 10.677 0 8.5 0S4.744 1.756 4.744 4.174A5.605 5.605 0 006.2 8.471a2.505 2.505 0 01.073 1.317.614.614 0 01-.135.308l-.632.233c-2.446.9-4.214 1.549-4.753 2.626A9.167 9.167 0 000 16.5a.5.5 0 00.5.5h8.75a.25.25 0 00.25-.25zM22 10h-9a2 2 0 00-2 2v7a2 2 0 002 2h.75a.25.25 0 01.25.25V23a1 1 0 001.625.781l3.407-2.726a.247.247 0 01.156-.055H22a2 2 0 002-2v-7a2 2 0 00-2-2zm-3.875 9.219L16.406 20.6a.25.25 0 01-.406-.2V20a1 1 0 00-1-1h-1.75a.25.25 0 01-.25-.25v-6.5a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v6.5a.249.249 0 01-.25.25h-3a1 1 0 00-.625.219z\"/><path d=\"M15 13.5a.75.75 0 000 1.5h5a.75.75 0 000-1.5zM18.75 16.75A.75.75 0 0018 16h-3a.75.75 0 000 1.5h3a.75.75 0 00.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});