define("ember-svg-jar/inlined/arrow-button-circle-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-circle-up</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm5.57 15.747a.5.5 0 01-.432.249H6.861a.5.5 0 01-.434-.748l5.138-8.992a.5.5 0 01.869 0l5.138 8.992a.5.5 0 01-.002.499z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});