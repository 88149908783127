define("ember-svg-jar/inlined/bin-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bin-1</title><path d=\"M6.875 13.5A1.377 1.377 0 005.5 12.126H4.375a.25.25 0 00-.25.25v2.25a.25.25 0 00.25.25H5.5A1.377 1.377 0 006.875 13.5z\"/><path d=\"M22.478 0H1.522A1.524 1.524 0 000 1.523v20.955A1.524 1.524 0 001.522 24h20.956A1.524 1.524 0 0024 22.478V1.523A1.524 1.524 0 0022.478 0zM8.09 17.929a2.72 2.72 0 01-2.725 2.2H3.5a.625.625 0 01-.625-.625v-8a.625.625 0 01.625-.625h1.865a2.72 2.72 0 012.725 2.2 2.588 2.588 0 01-.72 2.252.246.246 0 000 .352 2.587 2.587 0 01.72 2.246zm5.41.947a.625.625 0 110 1.25h-4a.625.625 0 010-1.25h1.125a.25.25 0 00.25-.25v-6.25a.25.25 0 00-.25-.25H9.5a.625.625 0 010-1.25h4a.625.625 0 010 1.25h-1.125a.25.25 0 00-.25.25v6.25a.25.25 0 00.25.25zm6.625.625a.625.625 0 01-.481.608.644.644 0 01-.144.017.627.627 0 01-.559-.346L16.6 15.1a.25.25 0 00-.474.112V19.5a.625.625 0 11-1.25 0v-8a.625.625 0 011.184-.279l2.34 4.685a.25.25 0 00.474-.111V11.5a.625.625 0 111.25 0z\"/><path d=\"M4.125 18.626a.25.25 0 00.25.25H5.5a1.375 1.375 0 000-2.75H4.375a.25.25 0 00-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});