define("ember-svg-jar/inlined/style-one-pin-gas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-gas</title><path d=\"M11.5 5.5h-2a.5.5 0 000 1h2a.5.5 0 000-1z\"/><path d=\"M12 0a9.5 9.5 0 00-4 18.119 2.509 2.509 0 011.218 1.222l1.872 4.076a1 1 0 001.817 0l1.876-4.079A2.5 2.5 0 0116 18.118 9.5 9.5 0 0012 0zM7.5 6A1.5 1.5 0 019 4.5h3A1.5 1.5 0 0113.5 6v1.25a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25zm10.416.624l-1.055.7a.251.251 0 00-.111.208V10.5A2.752 2.752 0 0114 13.25h-.327a.251.251 0 00-.24.178A1.5 1.5 0 0112 14.5H9A1.5 1.5 0 017.5 13V8.75a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25v2.75a.25.25 0 00.25.25H14a1.252 1.252 0 001.25-1.25V7.535a1.745 1.745 0 01.779-1.456l1.055-.7a.75.75 0 01.832 1.248z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});