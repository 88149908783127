define("ember-svg-jar/inlined/plant-smile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plant-smile</title><path d=\"M11.781 7.512a.25.25 0 00.438 0l2.143-3.895a.5.5 0 000-.474A54.802 54.802 0 0012.853.469a1.043 1.043 0 00-1.715 0A55.117 55.117 0 009.63 3.144a.5.5 0 000 .474zM20.75 10.982H3.25a.75.75 0 000 1.5h.372a.5.5 0 01.5.458l.729 8.749a2.514 2.514 0 002.489 2.293h9.32a2.514 2.514 0 002.491-2.293l.729-8.749a.5.5 0 01.5-.458h.372a.75.75 0 000-1.5zM7.839 14.746a1.957 1.957 0 011.738-1.014 1.955 1.955 0 011.737 1.014.75.75 0 11-1.322.708.5.5 0 00-.832 0 .75.75 0 01-1.322-.708zm7.96 4.57a4.2 4.2 0 01-7.6 0 .75.75 0 011.343-.668 2.7 2.7 0 004.914 0 .75.75 0 111.342.668zm.055-3.555a.75.75 0 01-1.015-.307.5.5 0 00-.832 0 .75.75 0 01-1.322-.708 1.955 1.955 0 011.737-1.014 1.957 1.957 0 011.738 1.014.75.75 0 01-.306 1.015zM7.324 9.723A1 1 0 008 9.982h2.568A.5.5 0 0011 9.723l.294-.535a.5.5 0 000-.482L7.4 1.628a.968.968 0 00-.9-.508.979.979 0 00-.857.578 6.94 6.94 0 00.5 6.641 7.033 7.033 0 001.181 1.384zM12.21 9.859a.249.249 0 00.215.123H16a1 1 0 00.672-.259 7.033 7.033 0 001.178-1.384 6.938 6.938 0 00.5-6.641.982.982 0 00-1.75-.071l-4.394 7.985a.248.248 0 00.004.247z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});