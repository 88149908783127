define("ember-svg-jar/inlined/accessories-necklace-pendant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accessories-necklace-pendant</title><path d=\"M20 8a8 8 0 10-15.276 3.327 1 1 0 01.007.819A2.754 2.754 0 007.241 16h.017a2.743 2.743 0 001.361-.361.249.249 0 01.341.093 3.526 3.526 0 001.485 1.4 1 1 0 01.555.9v.86a.25.25 0 01-.35.229 1.441 1.441 0 00-1.068-.044 1.5 1.5 0 00-.913.865l-.019.046a1.5 1.5 0 00.417 1.7l2.527 2.166a.626.626 0 00.813 0l2.527-2.166a1.5 1.5 0 00.416-1.7l-.019-.046a1.5 1.5 0 00-.913-.865 1.441 1.441 0 00-1.068.044.25.25 0 01-.35-.229v-.86a1 1 0 01.555-.9 3.529 3.529 0 001.486-1.4.249.249 0 01.34-.093 2.734 2.734 0 001.362.361h.016a2.755 2.755 0 002.51-3.857 1 1 0 01.007-.818A7.958 7.958 0 0020 8zM7.258 14a.757.757 0 010-1.513.757.757 0 010 1.513zm6.242-.009a1.5 1.5 0 01-1.49 1.509H12a1.5 1.5 0 01-.009-3H12a1.5 1.5 0 011.5 1.493zm4-.753a.758.758 0 01-.752.762.757.757 0 01-.005-1.513h.005a.758.758 0 01.752.753zm.007-2.854a.249.249 0 01-.273.147 2.753 2.753 0 00-.492-.044h-.016a2.747 2.747 0 00-2.022.9.251.251 0 01-.353.017A3.485 3.485 0 0012 10.5h-.022a3.489 3.489 0 00-2.327.909.25.25 0 01-.353-.017 2.752 2.752 0 00-2.023-.9h-.017a2.751 2.751 0 00-.491.044.249.249 0 01-.273-.147 6 6 0 1111.012 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});