define("ember-svg-jar/inlined/standing-lamp-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>standing-lamp-1</title><path d=\"M14 22h-.75a.25.25 0 01-.25-.25v-10.8a.252.252 0 01.136-.223A2.5 2.5 0 0014.5 8.5.5.5 0 0014 8h-4a.5.5 0 00-.5.5 2.5 2.5 0 001.364 2.223.252.252 0 01.136.223v10.8a.25.25 0 01-.25.25H10a1 1 0 000 2h4a1 1 0 000-2zM15.5 0h-7a1 1 0 00-1 1v1.5a4.387 4.387 0 002.261 3.939A.5.5 0 0010 6.5h4a.5.5 0 00.239-.061A4.387 4.387 0 0016.5 2.5V1a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});