define("ember-svg-jar/inlined/livestock-ram-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>livestock-ram-2</title><path d=\"M23.1 4.858a5.636 5.636 0 00-5.863-4.8A4.9 4.9 0 0013.482 1.6 6.6 6.6 0 0012 4.588 6.6 6.6 0 0010.518 1.6 4.9 4.9 0 006.767.056 5.636 5.636 0 00.9 4.858 1.5 1.5 0 003.034 6.5c1.229-.6 2.281-.8 2.749-.5.421.263.484 1.09.484 1.559a1 1 0 00.028.233A5.943 5.943 0 005.5 9.425l-3.4 3.5a1.5 1.5 0 00.109 2.422 7.357 7.357 0 003.11 1.124c.79 3.879 2.9 6.851 5.607 7.469a.251.251 0 00.212-.048.247.247 0 00.094-.2v-2.221a.249.249 0 00-.073-.177l-1.206-1.207a.751.751 0 01.53-1.281h3a.751.751 0 01.531 1.281l-1.207 1.207a.25.25 0 00-.074.177v2.223a.251.251 0 00.306.243c2.708-.619 4.818-3.59 5.607-7.469a7.349 7.349 0 003.109-1.124 1.507 1.507 0 00.671-1.184 1.467 1.467 0 00-.515-1.2l-3.445-3.536a6.092 6.092 0 00-.769-1.6 1 1 0 00.036-.268c0-.469.063-1.3.484-1.559.465-.292 1.519-.1 2.747.5A1.5 1.5 0 0023.1 4.858zM6.841 4.3a4.255 4.255 0 00-3.687-.024 3.532 3.532 0 013.613-2.22 2.931 2.931 0 012.279.9c.064.068 1.173 1.121 1.232 3.921a3.647 3.647 0 01-2.046-.008A3.2 3.2 0 006.841 4.3zm2.392 10.756a1.25 1.25 0 111.25-1.25 1.249 1.249 0 01-1.25 1.25zm5.5 0a1.25 1.25 0 111.25-1.25 1.249 1.249 0 01-1.25 1.25zM17.159 4.3a3.2 3.2 0 00-1.391 2.565 3.652 3.652 0 01-2.044.008c.067-2.806 1.162-3.848 1.226-3.917a2.934 2.934 0 012.283-.9 3.533 3.533 0 013.613 2.22 4.265 4.265 0 00-3.687.024z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});