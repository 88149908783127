define("ember-svg-jar/inlined/cat-climber", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cat-climber</title><path d=\"M14.5 16.75a.5.5 0 00-.5.5V22a1 1 0 001 1h1a1 1 0 001-1v-4.75a.5.5 0 00-.5-.5zM16.5 12.25a.5.5 0 00.5-.5V2a1 1 0 00-1-1h-1a1 1 0 00-1 1v4a.5.5 0 01-.5.5h-10A.5.5 0 013 6V2a1 1 0 00-1-1H1a1 1 0 00-1 1v20a1 1 0 001 1h1a1 1 0 001-1V9a.5.5 0 01.5-.5h10a.5.5 0 01.5.5v2.75a.5.5 0 00.5.5z\"/><path d=\"M23 11h-1a1 1 0 00-1 1v1a.5.5 0 01-.5.5h-10a.5.5 0 01-.5-.5v-1a1 1 0 00-1-1H8a1 1 0 00-1 1v10a1 1 0 001 1h1a1 1 0 001-1v-6a.5.5 0 01.5-.5h10a.5.5 0 01.5.5v6a1 1 0 001 1h1a1 1 0 001-1V12a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});