define("ember-svg-jar/inlined/surveillance-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>surveillance-location</title><path d=\"M24 12a1 1 0 00-1-1h-1.331a.249.249 0 01-.248-.218 9.515 9.515 0 00-8.2-8.2.25.25 0 01-.221-.25V1a1 1 0 00-2 0v1.332a.249.249 0 01-.218.248 9.524 9.524 0 00-8.2 8.2.249.249 0 01-.251.22H1a1 1 0 000 2h1.331a.249.249 0 01.248.218 9.515 9.515 0 008.2 8.2.25.25 0 01.218.247V23a1 1 0 002 0v-1.331a.249.249 0 01.218-.248 9.515 9.515 0 008.2-8.2.25.25 0 01.253-.221H23a1 1 0 001-1zm-12 7.5A7.491 7.491 0 015.078 9.123a.258.258 0 01.148-.14.252.252 0 01.2.017l3.106 1.69a.248.248 0 01.12.292A3.5 3.5 0 1012 8.5a3.24 3.24 0 00-.722.077.249.249 0 01-.284-.144L9.579 5.2a.249.249 0 01.151-.34A7.315 7.315 0 0112 4.5a7.5 7.5 0 010 15zm0-9a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});