define("ember-svg-jar/inlined/bicycle-retro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bicycle-retro</title><path d=\"M18.18 8.748a.251.251 0 01-.207-.246V3.661a.251.251 0 01.289-.247 1.769 1.769 0 011.711 1.827 1 1 0 002 0c0-2.318-1.754-3.876-4.4-3.876a1.562 1.562 0 00-1.006.289 1.508 1.508 0 00-.592 1.193v1.541a.25.25 0 01-.244.25 12.514 12.514 0 00-3.922.727.251.251 0 01-.334-.236v-.244a.25.25 0 01.25-.25h.751a1 1 0 000-2h-5.5a1 1 0 000 2h2.25a.25.25 0 01.25.25v1.427a.252.252 0 01-.119.213 12.757 12.757 0 00-2.829 2.353 12.039 12.039 0 00-2.62 4.77.251.251 0 01-.2.179 4.451 4.451 0 102.394.246.25.25 0 01-.145-.311 10.079 10.079 0 012.06-3.551 10.7 10.7 0 017.7-3.573.249.249 0 01.256.25V8.5a.25.25 0 01-.207.246 7 7 0 102.414 0zm-11.26 9.44a2.447 2.447 0 11-3.82-2.024.25.25 0 01.391.215 9.971 9.971 0 00.049 1.466 1 1 0 00.992.889 1.055 1.055 0 00.113-.006 1 1 0 00.883-1.105 8.306 8.306 0 01-.041-1.231.251.251 0 01.395-.2 2.433 2.433 0 011.038 1.996zm10.053 2.447a5 5 0 01-1.316-9.824.253.253 0 01.218.043.25.25 0 01.1.2v3.582a1 1 0 002 0v-3.583a.251.251 0 01.316-.242 5 5 0 01-1.316 9.824z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});