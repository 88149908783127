define("ember-svg-jar/inlined/cd-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cd-player</title><path d=\"M11 9.5a2 2 0 012 2 2.036 2.036 0 01-.012.222.249.249 0 00.249.278H19a.5.5 0 00.5-.5 8.5 8.5 0 00-17 0 .5.5 0 00.5.5h5.763a.249.249 0 00.249-.278A2.036 2.036 0 019 11.5a2 2 0 012-2zM24 15.5a2.5 2.5 0 00-2.5-2.5h-19A2.5 2.5 0 000 15.5v2a2.5 2.5 0 001.818 2.405.251.251 0 01.182.241v.1a.75.75 0 001.5 0A.25.25 0 013.75 20h16.5a.25.25 0 01.25.25.75.75 0 001.5 0v-.1a.251.251 0 01.182-.241A2.5 2.5 0 0024 17.5zm-3 1a1.5 1.5 0 11-1.5-1.5 1.5 1.5 0 011.5 1.5zm-19 0a1 1 0 011-1h12a1 1 0 010 2H3a1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});