define("ember-svg-jar/inlined/laptop-smiley", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-smiley</title><path d=\"M9.5 9.4a.75.75 0 00.75-.75V7.5a.75.75 0 00-1.5 0v1.145a.75.75 0 00.75.755zM15.25 8.645V7.5a.75.75 0 00-1.5 0v1.145a.75.75 0 001.5 0zM7.691 11.359a.75.75 0 000 1.061 6.249 6.249 0 008.839 0 .75.75 0 00-1.06-1.061 4.749 4.749 0 01-6.718 0 .75.75 0 00-1.061 0zM23 17.751a.5.5 0 00-.5-.5h-8a.5.5 0 00-.5.5V18a.25.25 0 01-.25.25h-3.5A.25.25 0 0110 18v-.25a.5.5 0 00-.5-.5h-8a.5.5 0 00-.5.5v2a1.5 1.5 0 001.5 1.5h19a1.5 1.5 0 001.5-1.5z\"/><path d=\"M3.5 15.751a1 1 0 001-1v-9a1 1 0 011-1h13a1 1 0 011 1v9a1 1 0 002 0v-9a3 3 0 00-3-3h-13a3 3 0 00-3 3v9a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});