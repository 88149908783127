define("ember-svg-jar/inlined/bin-paper-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bin-paper-1</title><path d=\"M23.5 3.566C23.5.1 13.177 0 12 0S.5.1.5 3.566a2.463 2.463 0 00.041.353C1.4 9.919 2.916 19.886 3.674 21S6.537 24 12 24s7.562-1.882 8.326-3S22.6 9.919 23.459 3.919a2.463 2.463 0 00.041-.353zM12 2c5.223 0 8.627.941 9.4 1.566-.772.625-4.175 1.568-9.4 1.568s-8.627-.943-9.4-1.568C3.373 2.941 6.777 2 12 2zM7.583 19.245a.789.789 0 01-.084 0 .75.75 0 01-.745-.667l-1-9a.751.751 0 011.492-.166l1 9a.75.75 0 01-.663.833zM12.75 19a.75.75 0 01-1.5 0v-9a.75.75 0 011.5 0zm4.5-.417a.75.75 0 01-.745.667.789.789 0 01-.084 0 .75.75 0 01-.663-.828l1-9a.751.751 0 011.492.166z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});