define("ember-svg-jar/inlined/folder-dash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-dash</title><path d=\"M3.5 1.5H1.95A1.953 1.953 0 000 3.45V5a1 1 0 002 0l-.05-1.5H3.5a1 1 0 000-2zM9.075 1.5H7a1 1 0 000 2h2.075a1 1 0 000-2zM18 4.5h-1.5a1 1 0 000 2H18a1 1 0 000-2zM13.25 4.5h-.795l-.241-.482a1 1 0 10-1.789.894l.255.511a1.934 1.934 0 001.151.978 13.184 13.184 0 001.419.1 1 1 0 000-2zM3 20.5l-1 .05V19.5a1 1 0 00-2 0v1.05a1.953 1.953 0 001.95 1.95H3a1 1 0 000-2zM20.85 6.5l1-.05V7.5a1 1 0 002 0V6.45A1.952 1.952 0 0021.9 4.5h-1.05a1 1 0 000 2zM23 18.5a1 1 0 00-1 1l.05 1H21a1 1 0 000 2h1.05A1.953 1.953 0 0024 20.55V19.5a1 1 0 00-1-1zM1 18a1 1 0 001-1v-1.5a1 1 0 00-2 0V17a1 1 0 001 1zM1 14a1 1 0 001-1v-1.5a1 1 0 00-2 0V13a1 1 0 001 1zM23 14a1 1 0 00-1 1v1.5a1 1 0 002 0V15a1 1 0 00-1-1zM23 9.5a1 1 0 00-1 1V12a1 1 0 002 0v-1.5a1 1 0 00-1-1zM1 10a1 1 0 001-1V7.5a1 1 0 00-2 0V9a1 1 0 001 1zM7.5 20.5H6a1 1 0 000 2h1.5a1 1 0 000-2zM12.5 20.5H11a1 1 0 000 2h1.5a1 1 0 000-2zM17.5 20.5H16a1 1 0 000 2h1.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});