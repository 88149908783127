define("ember-svg-jar/inlined/send-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>send-email</title><path d=\"M23.82 1.116a.5.5 0 00-.512-.078l-23 9.5a.5.5 0 00.01.927l6.332 2.459a.5.5 0 00.468-.056l8.4-5.974a.5.5 0 01.637.768l-7 6.745a.5.5 0 00-.153.36V22.5a.5.5 0 00.931.252l3.167-5.425a.251.251 0 01.337-.093l5.826 3.2a.5.5 0 00.73-.332l4-18.5a.508.508 0 00-.173-.486z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});