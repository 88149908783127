define("ember-svg-jar/inlined/style-three-pin-croissant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-croissant</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM7.4 11.266c-.313.55-.56.984-.986.984-.2 0-.56-.131-.646-1.008a5.7 5.7 0 011.765-4.548.417.417 0 01.67.211l.677 2.309a.5.5 0 01-.237.577A3.649 3.649 0 007.4 11.266zm7.44-4.655l-.881 3a.86.86 0 01-.814.648h-2.291a.854.854 0 01-.816-.653l-.875-2.982a.933.933 0 01.487-1.108 5.611 5.611 0 014.7 0 .933.933 0 01.49 1.095zm3.391 4.631c-.086.877-.442 1.008-.646 1.008-.426 0-.673-.434-.986-.984a3.651 3.651 0 00-1.243-1.475.5.5 0 01-.237-.578l.681-2.338v-.014a.386.386 0 01.616-.2 5.679 5.679 0 011.815 4.581z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});