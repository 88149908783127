define("ember-svg-jar/inlined/road-sign-width-2-5m-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-width-2.5m-alternate</title><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/><path d=\"M5.53 10.47a.75.75 0 00-1.28.53v2a.75.75 0 001.28.53l1-1a.749.749 0 000-1.06zM19.287 10.307a.751.751 0 00-.817.163l-1 1a.749.749 0 000 1.06l1 1a.746.746 0 00.53.22.75.75 0 00.75-.75v-2a.75.75 0 00-.463-.693zM10.75 12a.75.75 0 00-.75-.75.211.211 0 01-.19-.12.208.208 0 01.025-.223l.438-.549A2.175 2.175 0 0010.75 9a1.75 1.75 0 00-3.5 0 .75.75 0 001.5 0c0-.275.5-.275.5 0a.682.682 0 01-.148.422l-1.687 2.109A.75.75 0 008 12.75h2a.75.75 0 00.75-.75zM15 8.75h1a.75.75 0 000-1.5h-2a.75.75 0 00-.75.75v1.5a1.252 1.252 0 001.25 1.25h.5a.25.25 0 010 .5h-1a.75.75 0 000 1.5h1a1.75 1.75 0 000-3.5.25.25 0 010-.5z\"/><circle cx=\"12\" cy=\"12\" r=\".75\"/><path d=\"M13.7 13.777a.748.748 0 00-.843.337l-.643 1.071a.25.25 0 01-.428 0l-.643-1.071a.75.75 0 00-1.393.386v4a.75.75 0 001.5 0v-1.085a.058.058 0 01.107-.029.78.78 0 001.286 0 .058.058 0 01.107.029V18.5a.75.75 0 001.5 0v-4a.75.75 0 00-.55-.723z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});