define("ember-svg-jar/inlined/ecology-globe-nature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ecology-globe-nature</title><path d=\"M23.723 11.8a1 1 0 00-.723-.308h-2.253a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1.5a.5.5 0 00.458-.7l-1.149-2.626A.482.482 0 0122 5.492a.5.5 0 00.427-.761l-2.75-4.5a.521.521 0 00-.854 0l-2.75 4.5a.5.5 0 00.427.761.481.481 0 01.441.674l-1.152 2.626a.5.5 0 00.458.7h1.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H16.7a1 1 0 01-.74-.327L9.736 4.319a1.032 1.032 0 00-1.479 0l-6.223 6.846a1 1 0 01-.74.327H1a1 1 0 00-1 1.045 12.012 12.012 0 0024 0 1 1 0 00-.277-.737zM3.151 15.992a.5.5 0 01-.465-.317 9.806 9.806 0 01-.477-1.575.5.5 0 01.49-.6h2.353a.5.5 0 01.5.465q.051.75.154 1.464a.5.5 0 01-.5.571zm3.941 4.519a.25.25 0 01-.3.03A10.016 10.016 0 014.305 18.4a.25.25 0 01.192-.41H5.9a.5.5 0 01.476.345 13.361 13.361 0 00.77 1.878.249.249 0 01-.054.298zm3.908.747a.251.251 0 01-.4.2 7.177 7.177 0 01-2.1-3.125.251.251 0 01.233-.341H10.5a.5.5 0 01.5.5zm0-5.766a.5.5 0 01-.5.5H8.248a.5.5 0 01-.493-.415 17.217 17.217 0 01-.193-1.544.5.5 0 01.5-.541H10.5a.5.5 0 01.5.5zm4.5 2.841a7.168 7.168 0 01-2.1 3.125.252.252 0 01-.263.024.249.249 0 01-.137-.224v-2.766a.5.5 0 01.5-.5h1.766a.251.251 0 01.233.341zm.939-4.3a17.217 17.217 0 01-.193 1.544.5.5 0 01-.493.415H13.5a.5.5 0 01-.5-.5v-1.5a.5.5 0 01.5-.5h2.44a.5.5 0 01.5.541zM19.7 18.4a10.016 10.016 0 01-2.487 2.139.25.25 0 01-.353-.326 13.361 13.361 0 00.77-1.878.5.5 0 01.476-.345h1.4a.25.25 0 01.192.41zm2.1-4.306a9.908 9.908 0 01-.477 1.579.5.5 0 01-.465.317h-2.067a.5.5 0 01-.5-.571q.1-.714.154-1.464a.5.5 0 01.5-.465H21.3a.5.5 0 01.489.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});