define("ember-svg-jar/inlined/scale-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>scale-horizontal</title><path d=\"M5.5 5a1 1 0 001-1V2h4.25a.25.25 0 01.25.25v12.5a.25.25 0 01-.25.25H9a1 1 0 000 2h6a1 1 0 000-2h-1.75a.25.25 0 01-.25-.25V2.248A.25.25 0 0113.25 2h4.25v2a1 1 0 102 0V2a2 2 0 00-2-2h-11a2 2 0 00-2 2v2a1 1 0 001 1z\"/><path d=\"M23.3 20.1l-4-3a.5.5 0 00-.8.4v1.75a.25.25 0 01-.25.25H5.75a.25.25 0 01-.25-.25V17.5a.5.5 0 00-.8-.4l-4 3a.5.5 0 000 .8l4 3a.506.506 0 00.3.1.5.5 0 00.5-.5v-1.75a.25.25 0 01.25-.25h12.5a.25.25 0 01.25.25v1.75a.5.5 0 00.5.5.506.506 0 00.3-.1l4-3a.5.5 0 000-.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});