define("ember-svg-jar/inlined/asian-food-noodles-bowl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>asian-food-noodles-bowl</title><path d=\"M21.948 4.489L3.116 7.009A1 1 0 003.248 9a1.028 1.028 0 00.134-.009l2.829-.378a.5.5 0 01.551.617 7.239 7.239 0 01-.426 1.114 6.39 6.39 0 00-.653 2.189.5.5 0 01-.5.467H1.3a1.045 1.045 0 00-.815.391 1.066 1.066 0 00-.219.873A10.68 10.68 0 006.6 22.179a.5.5 0 01.278.628 2.082 2.082 0 00-.125.693.5.5 0 00.5.5h8a.5.5 0 00.5-.5 2.082 2.082 0 00-.125-.693.5.5 0 01.277-.628 10.675 10.675 0 006.329-7.914 1.064 1.064 0 00-.219-.875A1.042 1.042 0 0021.2 13h-6a.5.5 0 01-.492-.593 6.033 6.033 0 01.452-1.251 4.96 4.96 0 00.566-3.263.5.5 0 01.421-.61l6.063-.811a1 1 0 00-.266-1.983zM8.164 11.156A5.9 5.9 0 008.83 8.7a.5.5 0 01.433-.5l.493-.066a.5.5 0 01.381.108.5.5 0 01.183.35 3.754 3.754 0 01-.484 1.748 6.383 6.383 0 00-.653 2.189.5.5 0 01-.5.467H8.2a.5.5 0 01-.492-.594 6.036 6.036 0 01.456-1.246zm5.172-.812a6.39 6.39 0 00-.653 2.189.5.5 0 01-.5.467H11.7a.5.5 0 01-.492-.593 6.042 6.042 0 01.453-1.251 5.365 5.365 0 00.639-2.862.5.5 0 01.43-.553l.4-.055a.5.5 0 01.521.29c.374.817.095 1.447-.315 2.368zM23.729.794a1 1 0 00-1.185-.772l-19 4A1 1 0 003.749 6a1.046 1.046 0 00.207-.021l19-4a1 1 0 00.773-1.185z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});