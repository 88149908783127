define("ember-svg-jar/inlined/eco-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>eco-house</title><path d=\"M23.722 11.8L12.723.3a1.034 1.034 0 00-1.446 0l-11 11.5A1 1 0 001 13.493h1.5a.5.5 0 01.5.5v8.5a1.5 1.5 0 001.5 1.5h15a1.5 1.5 0 001.5-1.5v-8.5a.5.5 0 01.5-.5H23a1 1 0 00.722-1.691zm-8.86 6.507a5.411 5.411 0 01-2.693.886 2.515 2.515 0 01-1.149-.257 2.258 2.258 0 01-.6-.441.5.5 0 01.026-.725 23.315 23.315 0 013.533-2.583.75.75 0 10-.767-1.289 24.3 24.3 0 00-6.842 6.035.75.75 0 01-1.24-.844 20.436 20.436 0 013.246-3.509.5.5 0 00.108-.619A2.784 2.784 0 018.1 13.31a3.794 3.794 0 012.137-2.722 6.657 6.657 0 013.9-.643 8.857 8.857 0 003.721-.417.5.5 0 01.677.386c.465 2.911-1.673 7.197-3.673 8.395z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});