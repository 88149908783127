define("ember-svg-jar/inlined/people-woman-cap-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-cap-3</title><path d=\"M17.85 16.373a5.057 5.057 0 003.54-1.705.5.5 0 00-.232-.788 3.911 3.911 0 01-2.116-2.817 3.165 3.165 0 00.208-1.05 2.116 2.116 0 00-.465-1.443c.046-.325.075-.638.1-.945H22a.75.75 0 000-1.5h-3.126c-.284-4.725-3.595-6-6.895-6-5.791 0-7.455 3.808-6.8 8.445a2.112 2.112 0 00-.464 1.444 3.166 3.166 0 00.232 1.1 3.87 3.87 0 01-2.1 2.763.5.5 0 00-.232.788 5.057 5.057 0 003.54 1.705 2.142 2.142 0 001.765-.786 6.736 6.736 0 004.064 2.038 6.747 6.747 0 004.09-2.061 2.137 2.137 0 001.776.812zM11 3.625h2a.75.75 0 010 1.5h-2a.75.75 0 010-1.5zm.979 12.5c-1.032 0-3.83-1.49-4.207-3.738a1.747 1.747 0 00-1-1.306 1.275 1.275 0 01-.559-1.1.626.626 0 01.1-.439.752.752 0 00.406-.794 13.536 13.536 0 01-.113-.852.251.251 0 01.249-.275H17.1a.251.251 0 01.249.275 13.41 13.41 0 01-.113.851.752.752 0 00.406.8.616.616 0 01.105.439 1.279 1.279 0 01-.559 1.1 1.746 1.746 0 00-1 1.3c-.378 2.249-3.176 3.739-4.209 3.739z\"/><path d=\"M12.751 12.453a1.626 1.626 0 01-.764.172 1.651 1.651 0 01-.771-.174.75.75 0 10-.731 1.31 3.277 3.277 0 003 0 .75.75 0 10-.734-1.307zM11.089 10.161A.751.751 0 0011.05 9.1a2.019 2.019 0 00-2.6 0 .75.75 0 001.022 1.1.557.557 0 01.557 0 .751.751 0 001.06-.039zM12.869 9.1a.75.75 0 001.022 1.1.558.558 0 01.559 0 .75.75 0 001.02-1.1 2.022 2.022 0 00-2.601 0zM7.975 17.331a.5.5 0 00-.562-.178L2.92 18.662A4.519 4.519 0 000 22.875v.5a.5.5 0 00.5.5h10.25a.5.5 0 00.5-.5v-3.649a.5.5 0 00-.388-.487 4.947 4.947 0 01-2.887-1.908zM21.063 18.656l-4.476-1.5a.5.5 0 00-.562.178 4.947 4.947 0 01-2.887 1.908.5.5 0 00-.388.487v3.649a.5.5 0 00.5.5H23.5a.5.5 0 00.5-.5v-.5a4.524 4.524 0 00-2.937-4.222zM15 22.875a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});