define("ember-svg-jar/inlined/single-woman-actions-credit-card.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-credit-card</title><path d=\"M11.83 12.14a.25.25 0 00.063-.484l-.294-.108a.779.779 0 01-.513-.73v-.467a.25.25 0 01.25-.25 6.374 6.374 0 003.786-.9.388.388 0 00.084-.593c-.494-.537-.835-1.185-.981-3.317-.262-3.82-2.864-5.166-5.1-5.166S4.286 1.471 4.025 5.3c-.146 2.132-.487 2.78-.981 3.317a.388.388 0 00.084.593 6.355 6.355 0 003.782.9.249.249 0 01.253.25v.462a.778.778 0 01-.512.73C3.75 12.613 1.439 13.373.887 14.47a7.822 7.822 0 00-.76 2.73.386.386 0 00.1.3.4.4 0 00.29.126h8.358a.25.25 0 00.25-.25v-2.25a3 3 0 012.705-2.986z\"/><path d=\"M22.125 13.375h-10a1.752 1.752 0 00-1.75 1.75v7a1.752 1.752 0 001.75 1.75h10a1.752 1.752 0 001.75-1.75v-7a1.752 1.752 0 00-1.75-1.75zm.25 8.75a.25.25 0 01-.25.25h-10a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25h10a.25.25 0 01.25.25zm0-6a.25.25 0 01-.25.25h-10a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h10a.25.25 0 01.25.25z\"/><path d=\"M20.125 19.875h-1.25a.75.75 0 000 1.5h1.25a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});