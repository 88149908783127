define("ember-svg-jar/inlined/vegetables-corn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vegetables-corn</title><path d=\"M11.147 15.018a12.408 12.408 0 00-7.954-4.4.73.73 0 00-.8.459.76.76 0 00.279.906c1.1.777 1.445 2.535 1.81 4.4.492 2.508 1.073 5.468 3.766 6.846a.5.5 0 00.728-.447 13.915 13.915 0 012.2-7.167.5.5 0 00-.029-.597zM7.4 10.837a13.381 13.381 0 014.227 3.2.5.5 0 00.749 0 13.417 13.417 0 014.224-3.2 1 1 0 00.565-.963l-.333-5.338a4.841 4.841 0 00-9.663 0l-.334 5.337a1 1 0 00.565.964zm7.35-1.087a.75.75 0 01-.75.75h-1.75a.75.75 0 010-1.5H14a.75.75 0 01.75.75zm-3-6.75h1.75a.75.75 0 010 1.5h-1.75a.75.75 0 010-1.5zM9 6h1.75a.75.75 0 010 1.5H9A.75.75 0 019 6z\"/><path d=\"M21.605 11.074a.724.724 0 00-.791-.46 12.436 12.436 0 00-10.8 12.793.5.5 0 00.423.477A10.246 10.246 0 0012 24c6.022 0 6.85-4.227 7.516-7.624.365-1.861.71-3.619 1.81-4.4a.761.761 0 00.279-.902z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});