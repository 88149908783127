define("ember-svg-jar/inlined/messages-bubble-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square</title><path d=\"M21.5 2h-19A1.5 1.5 0 001 3.5v13A1.5 1.5 0 002.5 18h4.252a.25.25 0 01.25.25v3.25a.5.5 0 00.853.354l3.781-3.781a.253.253 0 01.177-.073H21.5a1.5 1.5 0 001.5-1.5v-13A1.5 1.5 0 0021.5 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});