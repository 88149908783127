define("ember-svg-jar/inlined/death-noose", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>death-noose</title><path d=\"M9.016 6.387a1 1 0 001.161.807l3.937-.71a1 1 0 10-.355-1.968l-.465.084A.25.25 0 0113 4.354V1a1 1 0 00-2 0v3.8a.25.25 0 01-.206.246l-.971.176a1 1 0 00-.807 1.165zM12.972 10.405a.125.125 0 01.054-.223l1.088-.2a1 1 0 10-.355-1.968l-3.936.71a1 1 0 00.354 1.968l.136-.025a.125.125 0 01.1.22C8.7 12.3 5.5 15.33 5.5 18.43a5.144 5.144 0 002.116 4.234A7.356 7.356 0 0011.979 24c3.241 0 6.521-1.913 6.521-5.57 0-3.522-4.14-6.968-5.528-8.025zM11.979 22C9.822 22 7.5 20.883 7.5 18.43c0-2.15 2.682-4.776 4.343-6.121a.25.25 0 01.314 0c1.661 1.345 4.343 3.971 4.343 6.121 0 2.453-2.344 3.57-4.521 3.57z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});