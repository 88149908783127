define("ember-svg-jar/inlined/smart-watch-square-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-heart</title><path d=\"M16.43 2.75a.251.251 0 00.238-.329L15.975.342A.5.5 0 0015.5 0h-7a.5.5 0 00-.475.342l-.693 2.079a.251.251 0 00.238.329zM7.57 21.25a.248.248 0 00-.2.1.251.251 0 00-.035.225l.693 2.079A.5.5 0 008.5 24h7a.5.5 0 00.475-.342l.693-2.079a.251.251 0 00-.035-.225.248.248 0 00-.2-.1zM17.5 4h-11A2.5 2.5 0 004 6.5v11a2.5 2.5 0 002 2.449c.162.033 11.5.051 11.5.051a2.5 2.5 0 002.5-2.5v-11A2.5 2.5 0 0017.5 4zM16 12.681l-3.959 4.13a.25.25 0 01-.361 0l-3.959-4.13a2.449 2.449 0 013.464-3.463l.5.5a.252.252 0 00.354 0l.5-.5A2.449 2.449 0 1116 12.681z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});