define("ember-svg-jar/inlined/real-estate-building-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-building-person</title><path d=\"M20.5 13.5A3.5 3.5 0 0017 17v2a.5.5 0 00.5.5h.605a.5.5 0 01.5.445l.4 3.611a.5.5 0 00.5.444h2a.5.5 0 00.5-.444l.4-3.611a.5.5 0 01.5-.445h.595a.5.5 0 00.5-.5v-2a3.5 3.5 0 00-3.5-3.5z\"/><circle cx=\"20.5\" cy=\"10\" r=\"2.5\"/><path d=\"M6.724 6.053l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 000 6.5V14a.5.5 0 00.5.5H2a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5A.5.5 0 007 14V6.5a.5.5 0 00-.276-.447zM5 10.5H2a.5.5 0 010-1h3a.5.5 0 010 1zM5 8H2a.5.5 0 010-1h3a.5.5 0 010 1zM14.692 2.538l-6-2.5A.5.5 0 008 .5V14a.5.5 0 00.5.5H10a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5V3a.5.5 0 00-.308-.462zM13 10.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zM13 8h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});