define("ember-svg-jar/inlined/school-exam-f-fail-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>school-exam-f-fail-alternate</title><path d=\"M19 0H5a2 2 0 00-2 2v20a2 2 0 002 2h8.638a2 2 0 001.281-.464l5.362-4.469A1.993 1.993 0 0021 17.532V2a2 2 0 00-2-2zm0 17.063a1 1 0 01-.36.769l-4.724 3.936a1 1 0 01-.64.232H5.5a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h13a.5.5 0 01.5.5z\"/><path d=\"M15.5 13.5H8A.75.75 0 008 15h7.5a.75.75 0 000-1.5zM11 17.5H8A.75.75 0 008 19h3a.75.75 0 000-1.5zM16.25 4h-2a1.752 1.752 0 00-1.75 1.75V10a.75.75 0 001.5 0V9a.5.5 0 01.5-.5h.75a.75.75 0 000-1.5h-.75a.5.5 0 01-.5-.5V6a.5.5 0 01.5-.5h1.75a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});