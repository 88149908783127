define("ember-svg-jar/inlined/paginate-filter-8", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paginate-filter-8</title><circle cx=\"13.5\" cy=\"7.497\" r=\".75\"/><circle cx=\"13.5\" cy=\"11.497\" r=\"1.75\"/><path d=\"M22 0H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2V2a2 2 0 00-2-2zm-8.5 14.75a3.249 3.249 0 01-1.821-5.94 2.23 2.23 0 01-.429-1.31 2.25 2.25 0 014.5 0 2.23 2.23 0 01-.429 1.31 3.249 3.249 0 01-1.821 5.94z\"/><path d=\"M19.5 22h-17a.5.5 0 01-.5-.5v-17a1 1 0 00-2 0V22a2 2 0 002 2h17.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});