define("ember-svg-jar/inlined/real-estate-dimensions-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-dimensions-house</title><path d=\"M4.251 8h.5a.75.75 0 00.53-1.28l-2-2a.75.75 0 00-1.061 0l-2 2A.751.751 0 00.751 8h.5a.5.5 0 01.5.5V20a.5.5 0 01-.5.5h-.5a.751.751 0 00-.531 1.28l2 2a.75.75 0 001.061 0l2-2a.75.75 0 00-.53-1.28h-.5a.5.5 0 01-.5-.5V8.5a.5.5 0 01.5-.5zM6.72 5.281A.75.75 0 008 4.751v-.5a.5.5 0 01.5-.5H20a.5.5 0 01.5.5v.5a.75.75 0 001.28.53l2-2a.75.75 0 000-1.061l-2-2a.75.75 0 00-1.28.531v.5a.5.5 0 01-.5.5H8.5a.5.5 0 01-.5-.5v-.5A.75.75 0 006.72.22l-2 2a.75.75 0 000 1.061zM15.729 8.3a1.035 1.035 0 00-1.459 0l-7.995 8.5A1 1 0 007 18.486h1.5a.5.5 0 01.5.5v4a1 1 0 001 1h10a1 1 0 001-1v-4a.5.5 0 01.5-.5H23a1 1 0 00.727-1.687S17.079 9.744 15.729 8.3zm.77 7.685a.5.5 0 01-.5.5H14a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});