define("ember-svg-jar/inlined/cursor-hand-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-hand-2-alternate</title><path d=\"M19.363 5.238a2.5 2.5 0 00-.5.051v-.551A2.484 2.484 0 0015.819 2.3a2.493 2.493 0 00-4.912 0 2.484 2.484 0 00-3.044 2.438v8.437l-1.213-1.7a2.485 2.485 0 00-4.05 2.877l5.467 7.716a4 4 0 003.264 1.689h.008l5.536-.011a5.008 5.008 0 004.99-5V7.738a2.5 2.5 0 00-2.502-2.5zm.5 13.508a3 3 0 01-2.994 3l-5.536.011a2.03 2.03 0 01-1.633-.844L4.23 13.2a.485.485 0 01.791-.562l2.121 2.972a1.5 1.5 0 002.721-.872v-10a.5.5 0 111 0v6a1 1 0 002 0v-8a.5.5 0 111 0v8a1 1 0 002 0v-6a.5.5 0 111 0v7.5a1 1 0 002 0v-4.5a.5.5 0 111 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});