define("ember-svg-jar/inlined/christmas-tree-ornament", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-tree-ornament</title><path d=\"M11.047 5.045Q11.518 5 12 5t.953.045a.5.5 0 00.547-.5V1.5a1.5 1.5 0 00-3 0v3.047a.5.5 0 00.163.37.506.506 0 00.384.128z\"/><rect x=\"16.939\" y=\"13.939\" width=\"2.121\" height=\"2.121\" rx=\".5\" ry=\".5\" transform=\"rotate(-45 18 15)\"/><path d=\"M4.021 11.952L5.648 10.8a.5.5 0 01.679.026l2.319 2.319a.5.5 0 00.707 0l2.293-2.292a.5.5 0 01.707 0l2.293 2.292a.5.5 0 00.707 0l2.319-2.319a.5.5 0 01.679-.026l1.628 1.152a.25.25 0 00.376-.3 9 9 0 00-16.71 0 .25.25 0 00.376.3zM19.979 18.047L18.351 19.2a.5.5 0 01-.679-.026l-2.319-2.319a.5.5 0 00-.707 0l-2.293 2.292a.5.5 0 01-.707 0l-2.293-2.293a.5.5 0 00-.707 0l-2.319 2.319a.5.5 0 01-.679.026l-1.627-1.151a.25.25 0 00-.376.3 9 9 0 0016.71 0 .25.25 0 00-.376-.3z\"/><rect x=\"10.939\" y=\"13.939\" width=\"2.121\" height=\"2.121\" rx=\".5\" ry=\".5\" transform=\"rotate(-45 12 15)\"/><rect x=\"4.939\" y=\"13.939\" width=\"2.121\" height=\"2.121\" rx=\".5\" ry=\".5\" transform=\"rotate(-45 6 15)\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});