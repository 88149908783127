define("ember-svg-jar/inlined/coffee-to-go", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>coffee-to-go</title><rect x=\"2.75\" y=\"5\" width=\"18.5\" height=\"3.5\" rx=\"1.25\" ry=\"1.25\"/><path d=\"M4.5 3.5h15a.751.751 0 00.712-.987L19.325.854A1.248 1.248 0 0018.14 0H5.86a1.25 1.25 0 00-1.186.855l-.886 1.658A.751.751 0 004.5 3.5zM19.313 10H4.688a.5.5 0 00-.365.157.508.508 0 00-.135.374l.784 12.532a1 1 0 001 .937H18.03a1 1 0 001-.937l.784-12.532a.508.508 0 00-.135-.374.5.5 0 00-.366-.157zm-3.233 7.136a.39.39 0 01-.389.364H8.309a.39.39 0 01-.389-.364l-.172-2.723a.393.393 0 01.1-.29.387.387 0 01.288-.123h7.728a.39.39 0 01.388.413z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});