define("ember-svg-jar/inlined/study-exam-math", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>study-exam-math</title><path d=\"M20.914 4.5L17 .586A2.011 2.011 0 0015.586 0H4.5a2 2 0 00-2 2v20a2 2 0 002 2h15a2 2 0 002-2V5.914a1.986 1.986 0 00-.586-1.414zm-1.414 17a.5.5 0 01-.5.5H5a.5.5 0 01-.5-.5v-19A.5.5 0 015 2h10.172a1 1 0 01.707.293l3.328 3.328a1 1 0 01.293.707z\"/><path d=\"M8.5 7.5v.75a.75.75 0 001.5 0V7.5a.5.5 0 01.5-.5h.75a.75.75 0 000-1.5h-.75A.5.5 0 0110 5v-.75a.75.75 0 00-1.5 0V5a.5.5 0 01-.5.5h-.75a.75.75 0 000 1.5H8a.5.5 0 01.5.5zM18.031 10.452a.75.75 0 00-1.061-1.061l-.531.531a.5.5 0 01-.707 0l-.53-.531a.75.75 0 00-1.061 1.061l.531.53a.5.5 0 010 .707l-.531.531a.75.75 0 001.059 1.06l.53-.53a.5.5 0 01.707 0l.531.53a.75.75 0 101.061-1.06l-.531-.531a.5.5 0 010-.707zM12.25 16.5h-4a.75.75 0 000 1.5h4a.75.75 0 000-1.5z\"/><circle cx=\"10.25\" cy=\"15\" r=\"1\"/><circle cx=\"10.25\" cy=\"19.5\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});