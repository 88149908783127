define("ember-svg-jar/inlined/medical-personnel-doctor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-personnel-doctor</title><path d=\"M11.128 11.327a.25.25 0 00-.128.218v1.082a.25.25 0 00.336.235 1.921 1.921 0 01.664-.119h.472a.25.25 0 00.173-.43 7.477 7.477 0 00-1.264-.981.252.252 0 00-.253-.005z\"/><path d=\"M9.9 10.65a5.622 5.622 0 10-5.038-.021.25.25 0 01.138.223v2.817a.252.252 0 00.143.226 1.5 1.5 0 11-1.286 0A.252.252 0 004 13.669v-2.125a.249.249 0 00-.38-.213A7.5 7.5 0 000 17.743a.5.5 0 00.5.5h9.25a.25.25 0 00.25-.25v-7.2a.146.146 0 00-.1-.143zM7.4 9.243a3.626 3.626 0 01-3.619-3.622 3.516 3.516 0 01.283-1.366.252.252 0 01.378-.1 7.621 7.621 0 004.5 1.472 7.571 7.571 0 001.8-.222.223.223 0 01.194.043.225.225 0 01.087.178A3.626 3.626 0 017.4 9.243zM22.5 14h-1.75a.5.5 0 01-.5-.5V13a1.752 1.752 0 00-1.75-1.75h-2A1.752 1.752 0 0014.75 13v.5a.5.5 0 01-.5.5H12.5a1.5 1.5 0 00-1.5 1.5v7a1.5 1.5 0 001.5 1.5h10a1.5 1.5 0 001.5-1.5v-7a1.5 1.5 0 00-1.5-1.5zm-6.25-1a.25.25 0 01.25-.25h2a.25.25 0 01.25.25v.5a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5zM20 19.75a.25.25 0 01-.25.25H19a.5.5 0 00-.5.5v.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-.75a.5.5 0 00-.5-.5h-.75a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25H16a.5.5 0 00.5-.5v-.75a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v.75a.5.5 0 00.5.5h.75a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});