define("ember-svg-jar/inlined/email-action-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-disable</title><path d=\"M17.5 9.5a7.908 7.908 0 013.151.657.251.251 0 00.349-.23V1.75a.158.158 0 00-.1-.147.16.16 0 00-.173.034L12.2 10.164a2.407 2.407 0 01-3.4 0L.271 1.637A.159.159 0 000 1.75v10.5A1.749 1.749 0 001.75 14h8.413a.252.252 0 00.223-.136A8 8 0 0117.5 9.5z\"/><path d=\"M9.726 9.236a1.094 1.094 0 001.547 0L19.748.761a.437.437 0 00.106-.447C19.758.027 19.49 0 19.249 0h-17.5c-.24 0-.51.027-.605.314a.441.441 0 00.106.447zM17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm0 11a4.559 4.559 0 01-2.136-.534.246.246 0 01-.129-.186.255.255 0 01.072-.217l5.819-5.781a.238.238 0 01.2-.067.243.243 0 01.175.122 4.389 4.389 0 01.573 2.163A4.543 4.543 0 0117.5 22zm-3.626-2.282a.234.234 0 01-.2.067.244.244 0 01-.175-.121 4.393 4.393 0 01-.573-2.164 4.567 4.567 0 016.71-3.966.248.248 0 01.129.186.256.256 0 01-.071.218z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});