define("ember-svg-jar/inlined/common-file-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-home</title><path d=\"M23.414 3L21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.868a.25.25 0 00.33.232 2.479 2.479 0 011.34 0A.249.249 0 008 9.867V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-5.16a.491.491 0 00-.459.318 4.021 4.021 0 01-1.228 1.231.249.249 0 00.149.45H22a2 2 0 002-2V4.414A2 2 0 0023.414 3z\"/><path d=\"M7.324 12.118a.5.5 0 00-.647 0l-6.5 5.5A.5.5 0 00.5 18.5h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5H5a.5.5 0 00.5-.5v-2.25a1.5 1.5 0 013 0v2.25a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-4.75a.25.25 0 01.25-.25h1.25a.5.5 0 00.324-.882z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});