define("ember-svg-jar/inlined/real-estate-message-house-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-message-house-1</title><path d=\"M6.164 9.961a.5.5 0 00.336.871h1a.5.5 0 01.5.5v2.5a1 1 0 001 1h1a1 1 0 001-1v-2a1 1 0 012 0v2a1 1 0 001 1h1a1 1 0 001-1v-2.5a.5.5 0 01.5-.5h1a.5.5 0 00.336-.871l-5.164-4.694a1 1 0 00-1.344 0z\"/><path d=\"M24 3.082a2 2 0 00-2-2H2a2 2 0 00-2 2v13.5a2 2 0 002 2h4v2.335a2 2 0 003.414 1.415l3.75-3.75H22a2 2 0 002-2zm-2 13a.5.5 0 01-.5.5h-8.75a1 1 0 00-.707.293l-3.616 3.616A.251.251 0 018 20.314v-2.732a1 1 0 00-1-1H2.5a.5.5 0 01-.5-.5v-12.5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});