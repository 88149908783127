define("ember-svg-jar/inlined/laptop-shopping-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-shopping-cart</title><path d=\"M13.609 13a1.243 1.243 0 001.165-.8l1.881-4.671a.75.75 0 10-1.391-.561l-.289.717a.5.5 0 01-.463.315H8.96a1.251 1.251 0 00-1.15 1.743l1.072 2.5a1.248 1.248 0 001.149.757z\"/><circle cx=\"10.46\" cy=\"14.5\" r=\"1\"/><path d=\"M13.21 13.5a1 1 0 101 1 1 1 0 00-1.001-1z\"/><path d=\"M22.41 4.5a2 2 0 00-2-2h-17a2 2 0 00-2 2v12.682a.319.319 0 01-.32.319 1 1 0 00-.99 1.164l.124.747A2.492 2.492 0 002.7 21.5h18.606a2.492 2.492 0 002.466-2.089l.124-.746a1 1 0 00-.986-1.165.5.5 0 01-.5-.5zm-2 12.5a.5.5 0 01-.5.5h-16a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5h16a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});