define("ember-svg-jar/inlined/real-estate-search-building.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-search-building</title><path d=\"M10.75 12.5h-5a1.5 1.5 0 00-1.5 1.5.5.5 0 00.5.5h4a.5.5 0 010 1h-4a.5.5 0 00-.5.5v.492a.509.509 0 00.5.508h2a.5.5 0 010 1h-.268a.5.5 0 00-.324.881A8.975 8.975 0 0012 21.03a.25.25 0 00.25-.25V14a1.5 1.5 0 00-1.5-1.5z\"/><path d=\"M16.75 5.49L14 7.07a.5.5 0 01-.749-.434V5.75a.75.75 0 00-1.5 0v2.309a.5.5 0 01-.252.435l-.748.426a1 1 0 00-.5.87V11a.5.5 0 00.5.5 2.5 2.5 0 012.5 2.5v6.348a.5.5 0 00.6.49 8.9 8.9 0 004.238-2.176.5.5 0 00.161-.367V15.5a.5.5 0 00-.5-.5h-3a.5.5 0 010-1h3a.5.5 0 00.5-.5V13a.5.5 0 00-.5-.5h-3a.5.5 0 010-1h3a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5h-2a.5.5 0 010-1h2a.5.5 0 00.5-.5V6.36a1.009 1.009 0 00-1.5-.87z\"/><path d=\"M12 0a12 12 0 1012 12A12.01 12.01 0 0012 0zm0 22.5A10.5 10.5 0 1122.5 12 10.506 10.506 0 0112 22.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});