define("ember-svg-jar/inlined/people-woman-13", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-13</title><path d=\"M15.1 16.985C17.88 16.291 20.538 10.336 20 6c-.275-2.24-1.617-6-7.5-6h-.014c-1.63 0-7.3.259-6.6 7.519a.769.769 0 00-.173.677 2.115 2.115 0 00-.462 1.443 2.727 2.727 0 001.435 2.432.251.251 0 01.142.188c.51 3.047 4.011 4.991 5.672 4.991a4.828 4.828 0 002.021-.572.485.485 0 00.579.307zm-6.791-4.973a1.752 1.752 0 00-1-1.306 1.281 1.281 0 01-.557-1.1.634.634 0 01.1-.44.752.752 0 00.405-.793c-.032-.187-.036-.224-.043-.268a.25.25 0 01.192-.28 6.508 6.508 0 002.856-1.458.249.249 0 01.345.014c.153.159.308.316.465.473 1.456 1.45 3.1 3.1 3.363 8.145a4.24 4.24 0 01-1.937.747c-1.027.004-3.814-1.486-4.189-3.734zM16.951 17.594l-.259-.074a.5.5 0 00-.537.18 5.534 5.534 0 01-2.129 1.552.248.248 0 00.017.462l3.19 1.111a.25.25 0 00.329-.275l-.433-2.75a.249.249 0 00-.178-.206zM6.77 20.817L9.926 19.7a.247.247 0 00.02-.459 5.515 5.515 0 01-2.1-1.539.5.5 0 00-.537-.18l-.259.074a.25.25 0 00-.178.2l-.432 2.747a.25.25 0 00.33.274z\"/><path d=\"M5.431 22.094a.5.5 0 01-.175-.462l.518-3.3a.249.249 0 00-.315-.279l-2.538.727A4.52 4.52 0 000 22.99v.51a.5.5 0 00.5.5h10.25a.5.5 0 00.5-.5V21a.5.5 0 00-.667-.471l-4.666 1.652a.5.5 0 01-.486-.087zM21.042 18.764l-2.5-.715a.247.247 0 00-.234.053.251.251 0 00-.082.226l.519 3.3a.5.5 0 01-.659.549l-4.67-1.628a.5.5 0 00-.665.473V23.5a.5.5 0 00.5.5H23.5a.5.5 0 00.5-.5v-.51a4.531 4.531 0 00-2.958-4.226zM14.013 13.161a.751.751 0 00-.941-.49 1.834 1.834 0 01-.544.079 1.653 1.653 0 01-.772-.174.75.75 0 10-.731 1.31 3.181 3.181 0 001.5.364h.006a3.346 3.346 0 00.993-.148.751.751 0 00.489-.941zM11.629 10.286a.751.751 0 00-.039-1.06 2.019 2.019 0 00-2.6 0 .75.75 0 001.022 1.1.557.557 0 01.557 0 .751.751 0 001.06-.04z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});