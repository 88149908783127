define("ember-svg-jar/inlined/design-drawing-board", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-drawing-board</title><path d=\"M11 23a1 1 0 102 0v-4.75a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25zM3.105 22.551a1 1 0 001.79.894l2.542-5.085a.25.25 0 00-.223-.36H5.536a.249.249 0 00-.223.138zM19.105 23.445a1 1 0 001.79-.894l-2.208-4.415a.249.249 0 00-.223-.136h-1.678a.25.25 0 00-.223.362zM23.5 16a1 1 0 00-1-1h-.75a.25.25 0 01-.25-.25V5.5A2.5 2.5 0 0019 3h-5.75a.25.25 0 01-.25-.25V1a1 1 0 10-2 0v1.75a.25.25 0 01-.25.25H5a2.5 2.5 0 00-2.5 2.5v9.25a.25.25 0 01-.25.25H1.5a1 1 0 100 2h21a1 1 0 001-1zM4.5 5.5A.5.5 0 015 5h14a.5.5 0 01.5.5v9.25a.25.25 0 01-.25.25H4.75a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});