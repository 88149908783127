define("ember-svg-jar/inlined/shopping-bag-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-bag-search</title><path d=\"M19.452 22.935a.251.251 0 00-.274-.053 7.03 7.03 0 113.215-10.235.25.25 0 00.459-.164l-.681-5.451v-.007a.6.6 0 00-.011-.061c0-.02-.01-.04-.016-.059V6.877l-1.05-2.937a.928.928 0 00-.04-.091 1.731 1.731 0 00-1.525-.974h-1a.75.75 0 000 1.5h1a.238.238 0 01.171.125l.546 1.54a.253.253 0 01-.031.228.249.249 0 01-.205.106h-2.731a.25.25 0 01-.25-.25v-1a5 5 0 00-10 0v1a.25.25 0 01-.25.25H3.936a.252.252 0 01-.209-.112.249.249 0 01-.021-.235l.631-1.492a.258.258 0 01.192-.161h1a.75.75 0 000-1.5h-1A1.755 1.755 0 002.98 3.9L1.737 6.83v.008l-.019.051-.008.011a.673.673 0 00-.027.126L.1 19.66a3.75 3.75 0 003.721 4.215h16.2a.151.151 0 00.107-.257zM9.029 5.125a3 3 0 016 0v1a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25zM22.933 19.126a.251.251 0 00.054.274l.425.425a.2.2 0 00.337-.165l-.128-1.027a.25.25 0 00-.486-.047c-.06.183-.128.363-.202.54z\"/><path d=\"M21.149 19.683a.25.25 0 01-.034-.311 5.532 5.532 0 10-1.692 1.692.25.25 0 01.312.033l2.483 2.483a1 1 0 001.414-1.414zM19.982 16.4a3.529 3.529 0 11-3.528-3.529 3.533 3.533 0 013.528 3.529z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});