define("ember-svg-jar/inlined/explosive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>explosive</title><path d=\"M20.621 4.379a1 1 0 00.708-.293l.914-.915a1 1 0 00-1.415-1.414l-.914.915a1 1 0 00.707 1.707zM23 5h-1.25a1 1 0 000 2H23a1 1 0 000-2zM21.329 7.914a1 1 0 10-1.415 1.414l.914.915a1 1 0 001.415-1.414zM18 0a1 1 0 00-1 1v1.25a1 1 0 002 0V1a1 1 0 00-1-1zM14.671 4.086a1 1 0 001.415-1.414l-.914-.915a1 1 0 00-1.415 1.414zM17.328 5.258l-1.657 1.656a.5.5 0 000 .708l.707.707a.5.5 0 00.707 0l1.657-1.657a1 1 0 10-1.414-1.414zM13.551 6.915a1 1 0 00-1.414 0L.293 18.758a1 1 0 000 1.414l3.536 3.536a1 1 0 001.414 0l11.843-11.844a1 1 0 000-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});