define("ember-svg-jar/inlined/server-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-clock</title><path d=\"M0 3.5A2.5 2.5 0 002.5 6h17A2.5 2.5 0 0022 3.5v-1A2.5 2.5 0 0019.5 0h-17A2.5 2.5 0 000 2.5zM3.635 3A1.115 1.115 0 114.75 4.114 1.115 1.115 0 013.635 3zM7.5 3a1.115 1.115 0 111.115 1.114A1.114 1.114 0 017.5 3zM17.25 9a8.169 8.169 0 014.006 1.046.5.5 0 00.744-.435V9.5A2.5 2.5 0 0019.5 7h-17A2.5 2.5 0 000 9.5v1A2.5 2.5 0 002.5 13h7.424a.488.488 0 00.411-.228A8.24 8.24 0 0117.25 9zm-7.521 1a1.115 1.115 0 11-1.114-1.115A1.115 1.115 0 019.729 10zm-3.864 0A1.115 1.115 0 114.75 8.885 1.115 1.115 0 015.865 10zM0 17.5A2.5 2.5 0 002.5 20h6.3a.5.5 0 00.477-.648 8.152 8.152 0 01.146-4.711.5.5 0 00-.477-.641H2.5A2.5 2.5 0 000 16.5zm3.635-.5a1.115 1.115 0 111.115 1.114A1.115 1.115 0 013.635 17z\"/><path d=\"M10.5 17.25a6.75 6.75 0 106.75-6.75 6.758 6.758 0 00-6.75 6.75zM17.25 22A4.75 4.75 0 1122 17.25 4.756 4.756 0 0117.25 22z\"/><path d=\"M16.25 17.25a1 1 0 001 1h2a1 1 0 000-2h-.75a.25.25 0 01-.25-.25v-1.25a1 1 0 00-2 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});