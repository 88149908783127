define("ember-svg-jar/inlined/email-action-read-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-read-document</title><path d=\"M18.409 15.131a.754.754 0 01-.4.119H6a.754.754 0 01-.4-.119l-5.215-3.35a.25.25 0 00-.385.21V22a2 2 0 002 2h20a2 2 0 002-2V11.993a.25.25 0 00-.385-.211z\"/><path d=\"M5.115 13.041a.25.25 0 00.385-.21V2.25A.25.25 0 015.75 2h12.5a.25.25 0 01.25.25v10.575a.25.25 0 00.385.211l4.063-2.608a.25.25 0 00.008-.416L20.5 8.3V2.206a2.114 2.114 0 00-2-2.208h-13a2.115 2.115 0 00-2 2.209V8.3l-2.458 1.71a.25.25 0 00.007.416z\"/><path d=\"M11.243 5a.75.75 0 00-.75-.75h-2a.75.75 0 000 1.5h2a.75.75 0 00.75-.75zM7.75 8.5a.75.75 0 00.75.75h7a.75.75 0 100-1.5h-7a.75.75 0 00-.75.75zM8.5 12.75h7a.75.75 0 100-1.5h-7a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});