define("ember-svg-jar/inlined/antique-armor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>antique-armor</title><path d=\"M9 17.359V14.5a.5.5 0 00-.5-.5h-4a.5.5 0 00-.5.5v2.859a3.492 3.492 0 001.559 2.912l.664.443a.493.493 0 00.553 0l.665-.443A3.492 3.492 0 009 17.359z\"/><path d=\"M19 0h-9a.5.5 0 00-.5.5.5.5 0 01-.518.5H4.5a.5.5 0 00-.3.9l1.2.9a.249.249 0 010 .4l-1.2.9a.5.5 0 00.3.9H10a.5.5 0 00.5-.5.478.478 0 01.494-.5h6.756a.25.25 0 01.25.25v9a.25.25 0 01-.25.25h-3.466c-.258 0-.174-.279-.145-.454l.74-4.436a1 1 0 00-.539-1.058l-2.893-1.447a1 1 0 00-.894 0L7.661 7.552a1 1 0 00-.54 1.059l.7 4.211A.268.268 0 008.1 13h.4a1.5 1.5 0 011.5 1.5v2.859A4.5 4.5 0 018 21.1l-.662.425c-.057.036-.12.237-.12.237L7.1 22.905A1 1 0 008.1 24h5.831a1 1 0 001-1.066l-.411-6.167a.25.25 0 01.249-.267h2.981a.25.25 0 01.25.25V23a1 1 0 002 0V1a1 1 0 00-1-1zm-6.25 10.882H12a.25.25 0 00-.25.25v.75a.75.75 0 01-1.5 0v-.75a.25.25 0 00-.25-.25h-.75a.75.75 0 010-1.5H10a.25.25 0 00.25-.25v-.75a.75.75 0 111.5 0v.75a.25.25 0 00.25.25h.75a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});