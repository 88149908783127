define("ember-svg-jar/inlined/rb-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rb-1</title><path d=\"M14.125 18.626a.25.25 0 00.25.25H15.5a1.375 1.375 0 100-2.75h-1.125a.25.25 0 00-.25.25zM10.875 13.5A1.377 1.377 0 009.5 12.126H8.375a.25.25 0 00-.25.25v2.25a.25.25 0 00.25.25H9.5a1.377 1.377 0 001.375-1.376z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM11.942 19.059a.625.625 0 010 .884.643.643 0 01-.884 0l-2.506-2.506a.25.25 0 00-.427.177V19.5a.625.625 0 01-1.25 0v-8a.625.625 0 01.625-.625h1.9a2.7 2.7 0 012.678 2.1A2.629 2.629 0 019.5 16.126h-.2c-.118 0-.149.13-.06.229.06.066 2.702 2.704 2.702 2.704zm6.148-1.13a2.72 2.72 0 01-2.725 2.2H13.5a.625.625 0 01-.625-.625v-8a.625.625 0 01.625-.625h1.865a2.72 2.72 0 012.725 2.2 2.588 2.588 0 01-.72 2.252.246.246 0 000 .352 2.587 2.587 0 01.72 2.246z\"/><path d=\"M16.875 13.5a1.377 1.377 0 00-1.375-1.374h-1.125a.25.25 0 00-.25.25v2.25a.25.25 0 00.25.25H15.5a1.377 1.377 0 001.375-1.376z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});