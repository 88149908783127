define("ember-svg-jar/inlined/gesture-swipe-horizontal-left-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-horizontal-left-3</title><path d=\"M17.561 12.581l-3.742-1.117a.25.25 0 01-.178-.239V6.657a1 1 0 00-2 0v7.064a.25.25 0 01-.455.142c-1.49-2.138-3.856-1.449-2.762.224l2.912 4.367a.5.5 0 00.416.223h5.435a.5.5 0 00.492-.411c.2-1.1.664-3.684.775-4.165a1.281 1.281 0 00-.893-1.52zM2.994 10.566a1.749 1.749 0 01-1.107-2.213l.4-1.193a.252.252 0 00-.115-.3.732.732 0 00-.414-.09.748.748 0 00-.658.511l-.947 2.836a.749.749 0 00.474.948l2.834.945a.75.75 0 00.8-.22.741.741 0 00.169-.6.25.25 0 00-.168-.2zM23.569 11.035a1 1 0 00.047-1.414c-1.63-1.742-4.106-2.866-7.361-3.341a1 1 0 10-.289 1.979 10.283 10.283 0 016.189 2.728 1 1 0 001.414.048z\"/><path d=\"M5.971 6.9a.249.249 0 01-.292-.129l-.516-1.034a.75.75 0 00-1.383.1l-.944 2.832a.749.749 0 00.474.949l2.834.944a.75.75 0 00.908-1.046l-.234-.47a.248.248 0 010-.216.251.251 0 01.165-.138c.227-.058.46-.113.7-.165a22.336 22.336 0 011.57-.294 1 1 0 00-.291-1.979c-.764.113-1.5.263-2.2.434-.274.066-.535.136-.791.212z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});