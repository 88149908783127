define("ember-svg-jar/inlined/space-rocket-launch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>space-rocket-launch</title><path d=\"M17.75 19.5a1.5 1.5 0 00-1.5 1.5 10.185 10.185 0 001.055 2.729.5.5 0 00.89 0A10.2 10.2 0 0019.25 21a1.5 1.5 0 00-1.5-1.5zM13.25 11.6a1 1 0 000-2 1.5 1.5 0 010-3 1 1 0 000-2 3.507 3.507 0 00-3.265 2.241.25.25 0 01-.233.159H8a.25.25 0 01-.25-.25V3.6a1 1 0 00-.539-.887l-5-2.6A1 1 0 00.75 1v22a1 1 0 001 1h5a1 1 0 001-1V9.25A.25.25 0 018 9h1.68a.249.249 0 01.238.174A3.507 3.507 0 0013.25 11.6zm-10.5-.08a.25.25 0 01.4-.2l1.684 1.33a.25.25 0 010 .392l-1.684 1.33a.25.25 0 01-.4-.2zm2.6 3.356a.251.251 0 01.405.2v3.086a.249.249 0 01-.406.2L3.4 16.8a.249.249 0 010-.391zM3.4 9.292a.251.251 0 010-.392l1.944-1.553a.25.25 0 01.406.195v3.087a.25.25 0 01-.405.2zm2.215-5.153a.25.25 0 01.135.222v.3a.25.25 0 01-.094.2l-2.5 2a.25.25 0 01-.406-.2V3.06a.25.25 0 01.365-.222zM3 22a.25.25 0 01-.25-.25v-2.707a.25.25 0 01.406-.2l2.5 2a.25.25 0 01.094.2v.707a.25.25 0 01-.25.25z\"/><path d=\"M22.75 16.112a38.711 38.711 0 00-2.9-3.638.247.247 0 01-.061-.185c.237-2.865.462-5.84.462-6.789 0-1.235-1.694-3.826-2.292-4.7a.251.251 0 00-.413 0c-.6.877-2.294 3.469-2.294 4.7 0 .5.243 3.677.49 6.768a.248.248 0 01-.063.187 37.819 37.819 0 00-2.969 3.657 5.681 5.681 0 00-.458 2.274v1.154a.455.455 0 00.308.419.536.536 0 00.544-.1l2.679-2.5a.25.25 0 01.42.168v.033a1 1 0 001 .94h1.1a1 1 0 001-.936.244.244 0 01.397-.164l2.7 2.478a.537.537 0 00.543.094.455.455 0 00.306-.419v-1.659a3.647 3.647 0 00-.499-1.782z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});