define("ember-svg-jar/inlined/online-reading-scribd-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>online-reading-scribd-1</title><path d=\"M11.25 7.5a3.21 3.21 0 00-3.1-3.21 5 5 0 012.85-1 7.7 7.7 0 013.69 1.16c.44.22.93.47 1.47.71l-.88 1.77a.25.25 0 00.08.32l1.5 1a.25.25 0 00.2 0 .25.25 0 00.16-.12l1.9-3.56a1.25 1.25 0 00-.56-1.68 5.19 5.19 0 01-.56-.35 11.47 11.47 0 00-7-2.29C6 .25 2.75 2.7 2.75 6.5a5.15 5.15 0 004.75 4.75 3.75 3.75 0 003.75-3.75zM16.5 12.75a3.75 3.75 0 00-3.75 3.75 3.21 3.21 0 003.1 3.21 5 5 0 01-2.85 1 7.7 7.7 0 01-3.69-1.16c-.44-.22-.93-.47-1.47-.71l.88-1.77a.25.25 0 00-.08-.32l-1.5-1a.25.25 0 00-.36.09l-1.9 3.56a1.25 1.25 0 00.56 1.68 5.19 5.19 0 01.52.34 11.47 11.47 0 007 2.29c5 0 8.25-2.45 8.25-6.25a5.15 5.15 0 00-4.71-4.71z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});