define("ember-svg-jar/inlined/smart-house-phone-remote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-house-phone-remote</title><path d=\"M23.7 9.6L12.554.2a.859.859 0 00-1.108 0L.3 9.6a.854.854 0 00.558 1.5h2.168a.5.5 0 01.5.5v8.041a.857.857 0 00.858.855H7.25a.25.25 0 00.25-.25v-4a2.994 2.994 0 011.231-2.42.25.25 0 00.083-.3 2.057 2.057 0 01-.129-.458.249.249 0 00-.129-.182 2 2 0 01-.307-3.316 6.159 6.159 0 017.5 0 2 2 0 01-.307 3.323.251.251 0 00-.129.182 2.057 2.057 0 01-.129.458.249.249 0 00.083.3 3 3 0 011.233 2.419v4a.25.25 0 00.25.25h2.964a.856.856 0 00.857-.855V11.6a.5.5 0 01.5-.5h2.071a.854.854 0 00.558-1.5z\"/><path d=\"M11.164 13.574a1.281 1.281 0 011.672 0 .751.751 0 001-1.116 2.822 2.822 0 00-3.68 0 .751.751 0 001 1.116z\"/><path d=\"M9.969 11.661a3.3 3.3 0 014.062 0 .75.75 0 00.938-1.17 4.871 4.871 0 00-5.938 0 .75.75 0 00.938 1.17zM15.25 16.252A1.753 1.753 0 0013.5 14.5h-3a1.753 1.753 0 00-1.75 1.75v6A1.752 1.752 0 0010.5 24h3a1.752 1.752 0 001.75-1.75zM13.5 22.5h-3a.25.25 0 01-.25-.25v-6a.251.251 0 01.25-.25h3a.251.251 0 01.25.25v6a.25.25 0 01-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});