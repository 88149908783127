define("ember-svg-jar/inlined/livestock-sheep-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>livestock-sheep-body</title><path d=\"M23.5 13.125a7.571 7.571 0 00-3.166-6.362 6.98 6.98 0 00-5.072-1.045 4.872 4.872 0 01-.34.4 7.336 7.336 0 011.471 5.649.751.751 0 01-1.483-.229 5.865 5.865 0 00-1.18-4.509 4.493 4.493 0 01-.86.347c-.541 3.041-2.706 5.253-5.37 5.253a5.286 5.286 0 01-4.622-3.046 16.307 16.307 0 00-.378 3.542A8.1 8.1 0 004 17.877v4.5a1 1 0 002 0V20.56a.5.5 0 01.7-.452 5.881 5.881 0 002.41.517 12.279 12.279 0 001.327-.073.5.5 0 01.563.5v1.328a1 1 0 002 0v-1.627a.509.509 0 01.633-.5 10.7 10.7 0 002.867.371 7.019 7.019 0 001.887-.254.494.494 0 01.613.486v1.518a1 1 0 002 0v-3.415a7.646 7.646 0 002.5-5.834z\"/><path d=\"M7.5 11.125c2.162 0 3.929-2.263 4-5.078a3.147 3.147 0 002.965-1.9.5.5 0 00-.342-.673l-3.51-.874A3.694 3.694 0 007.5.625 3.694 3.694 0 004.387 2.6l-3.508.873a.5.5 0 00-.342.673A3.11 3.11 0 003.5 6.047c.071 2.815 1.838 5.078 4 5.078zm-1-6.25a1 1 0 11-1-1 1 1 0 011 1zm2 0a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});