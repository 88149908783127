define("ember-svg-jar/inlined/real-estate-action-house-ribbon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-house-ribbon</title><path d=\"M13.2 13.683a.5.5 0 000-.368 3.862 3.862 0 012.825-4.894.5.5 0 00.32-.185 3.631 3.631 0 012.617-1.215.5.5 0 00.319-.863L13.2.409a1.505 1.505 0 00-2.061 0L.325 10.636a.5.5 0 00.344.863h2a.5.5 0 01.5.5V22a1 1 0 001 1h9.5a.5.5 0 00.5-.5v-4.742a.5.5 0 00-.131-.338 3.771 3.771 0 01-.838-3.737z\"/><path d=\"M23.825 12.428A2.484 2.484 0 0022.423 10a1.168 1.168 0 00-1.17.1.114.114 0 01-.185-.1 1.163 1.163 0 00-.5-1.062 2.52 2.52 0 00-2.8 0 1.167 1.167 0 00-.5 1.062.114.114 0 01-.185.107 1.162 1.162 0 00-1.169-.1 2.481 2.481 0 00-1.4 2.426 1.165 1.165 0 00.692.973.109.109 0 010 .2 1.168 1.168 0 00-.693.973A2.482 2.482 0 0015.915 17a1.164 1.164 0 001.167-.1.115.115 0 01.186.107 1.162 1.162 0 00.5 1.061 2.48 2.48 0 002.805 0 1.168 1.168 0 00.5-1.061.115.115 0 01.185-.108 1.169 1.169 0 001.171.1 2.478 2.478 0 001.4-2.425 1.166 1.166 0 00-.691-.973.109.109 0 010-.2 1.167 1.167 0 00.687-.973zM19.169 15a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/><path d=\"M21.7 18.322a2.161 2.161 0 01-.566.57 3.539 3.539 0 01-3.934 0 2.139 2.139 0 01-.565-.569.53.53 0 00-.966.275V23a1 1 0 001.64.769l1.54-1.283a.5.5 0 01.64 0l1.542 1.285A1 1 0 0022.669 23v-4.4a.534.534 0 00-.969-.278z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});