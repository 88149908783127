define("ember-svg-jar/inlined/instrument-accordian-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>instrument-accordian-1</title><path d=\"M4.25 6.25a.75.75 0 010-1.5h3.5A.25.25 0 008 4.5v-2a.5.5 0 00-.5-.5h-5A2.5 2.5 0 000 4.5v15A2.5 2.5 0 002.5 22h5a.5.5 0 00.5-.5v-2a.25.25 0 00-.25-.25h-3.5a.75.75 0 010-1.5h3.5A.25.25 0 008 17.5v-2a.25.25 0 00-.25-.25h-3.5a.75.75 0 010-1.5h3.5A.25.25 0 008 13.5V12a.25.25 0 00-.25-.25h-3.5a.75.75 0 010-1.5h3.5A.25.25 0 008 10V6.5a.25.25 0 00-.25-.25zM22 7.5h-.75a.25.25 0 01-.25-.25V4.5A2.5 2.5 0 0018.5 2h-1a.5.5 0 00-.5.5v19a.5.5 0 00.5.5h1a2.5 2.5 0 002.5-2.5v-2.75a.25.25 0 01.25-.25H22a2 2 0 002-2v-5a2 2 0 00-2-2zm0 6.5a.5.5 0 01-.5.5h-.25a.25.25 0 01-.25-.25v-4.5a.25.25 0 01.25-.25h.25a.5.5 0 01.5.5zM14.5 3a1 1 0 00-1 1v16a1 1 0 002 0V4a1 1 0 00-1-1zM11 3a1 1 0 00-1 1v16a1 1 0 002 0V4a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});