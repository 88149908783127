define("ember-svg-jar/inlined/medical-specialty-broken-bone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-specialty-broken-bone</title><path d=\"M11.453 15.587a.518.518 0 00-.205-.858l-1.126-.368a.5.5 0 01-.325-.335l-.284-.976a.518.518 0 00-.863-.221l-2.215 2.215a.246.246 0 01-.191.073.25.25 0 01-.181-.093 6.217 6.217 0 00-.252-.3 3.138 3.138 0 00-4.4-.055 3.039 3.039 0 00-.027 4.316 3 3 0 002.2.886.5.5 0 01.509.508 3.04 3.04 0 00.891 2.207 3.1 3.1 0 004.366.021 3.043 3.043 0 00.021-4.321 1.264 1.264 0 00-.12-.1.251.251 0 01-.1-.182.249.249 0 01.073-.193zM23.087 7.707a3.088 3.088 0 00-3.5-4.513.5.5 0 01-.619-.361 3.074 3.074 0 00-5.639-.807A3.205 3.205 0 0014.565 6.3c.075.043.221.11.351.168a.249.249 0 01.115.354l-1.693 2.909a.522.522 0 00-.007.508.514.514 0 00.43.269l1.025.05a.5.5 0 01.39.218l.67.988a.518.518 0 00.876-.03l1.694-2.912a.25.25 0 01.163-.119.253.253 0 01.2.04c.047.034.1.066.141.092a3.041 3.041 0 004.167-1.128zM4 10h2a1 1 0 000-2H4a1 1 0 000 2zM6.3 6.687a1 1 0 001.4-1.436l-1.036-1a1 1 0 10-1.395 1.432zM10 6a1 1 0 001-1V3a1 1 0 00-2 0v2a1 1 0 001 1zM20 14h-2a1 1 0 000 2h2a1 1 0 000-2zM17.717 17.273a1 1 0 10-1.434 1.392l1.035 1.067a1 1 0 001.435-1.393zM14 18a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});