define("ember-svg-jar/inlined/seo-search-one", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seo-search-one</title><path d=\"M8.761 12.19h-1a1.25 1.25 0 000 2.5h5a1.25 1.25 0 000-2.5h-1a.25.25 0 01-.25-.25V5.815A1.627 1.627 0 009.886 4.19a2.145 2.145 0 00-1.525.631L6.377 6.806a1.25 1.25 0 001.768 1.768l.439-.44a.25.25 0 01.427.177v3.629a.25.25 0 01-.25.25z\"/><path d=\"M.808 16.364a.5.5 0 00.353.854h2.828a.5.5 0 00.5-.5V13.89a.5.5 0 00-.853-.354l-.3.3a.251.251 0 01-.4-.059A7.921 7.921 0 012 10a8.009 8.009 0 018-8 7.928 7.928 0 013.517.812 1 1 0 00.879-1.8A9.907 9.907 0 0010 0 10.011 10.011 0 000 10a9.9 9.9 0 001.548 5.347.25.25 0 01-.034.311zM16.782 19.611l3.8 3.805a2 2 0 002.828-2.829l-3.8-3.8a1 1 0 00-1.259-.127.25.25 0 01-.314-.032l-.141-.141a.25.25 0 01-.02-.33 9.988 9.988 0 00.574-11.5.251.251 0 01.035-.311l.706-.706a.5.5 0 00-.353-.854h-2.827a.5.5 0 00-.5.5v2.825a.5.5 0 00.308.462.5.5 0 00.545-.108l.295-.295a.249.249 0 01.214-.07.246.246 0 01.183.129 8 8 0 01-10.632 10.93 1 1 0 10-.9 1.788 10 10 0 0010.626-1.069.249.249 0 01.33.02l.141.14a.25.25 0 01.031.315 1 1 0 00.13 1.258z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});