define("ember-svg-jar/inlined/design-tool-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-tool-layout</title><path d=\"M24 1.98A1.982 1.982 0 0022.02 0H1.98A1.982 1.982 0 000 1.98v20.04A1.982 1.982 0 001.98 24h20.04A1.982 1.982 0 0024 22.02zM22 21.5a.5.5 0 01-.5.5l-19 .02a.5.5 0 01-.5-.5L1.98 2.5a.5.5 0 01.5-.5l19.02-.02a.5.5 0 01.5.5z\"/><path d=\"M4.5 16.5h11a1 1 0 001-1v-11a1 1 0 00-1-1h-11a1 1 0 00-1 1v11a1 1 0 001 1zm.833-3.343l1.584-3.083a.749.749 0 011.164-.219L9.3 10.936a.252.252 0 00.386-.069l1.735-3.222a.75.75 0 011.375.127l1.75 5.5a.75.75 0 01-.715.978H6a.75.75 0 01-.667-1.093z\"/><rect x=\"13\" y=\"18\" width=\"3\" height=\"2.5\" rx=\".833\" ry=\".833\"/><rect x=\"17.5\" y=\"18\" width=\"3\" height=\"2.5\" rx=\".833\" ry=\".833\"/><path d=\"M4.25 20h4.5a.75.75 0 000-1.5h-4.5a.75.75 0 000 1.5zM18.75 5h1a.75.75 0 000-1.5h-1a.75.75 0 000 1.5zM18.75 8.5h1a.75.75 0 000-1.5h-1a.75.75 0 000 1.5zM18.75 12h1a.75.75 0 000-1.5h-1a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});