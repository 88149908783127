define("ember-svg-jar/inlined/ambulance-car", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ambulance-car</title><path d=\"M9.5 4.75h4a.5.5 0 00.5-.5v-.5a2.5 2.5 0 00-5 0v.5a.5.5 0 00.5.5z\"/><circle cx=\"5\" cy=\"20.75\" r=\"2\"/><path d=\"M23 6.25H9.5a.5.5 0 00-.5.5v.5a.5.5 0 01-.5.5H5.589A2.033 2.033 0 003.8 8.859l-2.215 5a.929.929 0 00-.032.146l-.82.82A2.487 2.487 0 000 16.594v3.656a1 1 0 001 1 .5.5 0 00.5-.5 3.5 3.5 0 017 0v.011a.5.5 0 00.5.5l6-.015a.5.5 0 00.5-.5 3.5 3.5 0 017 0 .5.5 0 00.5.5 1 1 0 001-1v-13a1 1 0 00-1-.996zM9 12.766a.5.5 0 01-.5.5H4.8a.5.5 0 01-.458-.7l1.112-2.516a.5.5 0 01.458-.3H8.5a.5.5 0 01.5.5zm10.5.484a.5.5 0 01-.5.5h-1a.5.5 0 00-.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 00-.5-.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1a.5.5 0 01.5.5z\"/><circle cx=\"19\" cy=\"20.75\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});