define("ember-svg-jar/inlined/design-file-psd-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-psd-1</title><path d=\"M23.707 5.708L18.293.293A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.75V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.706z\"/><path d=\"M13.317 12.137a.248.248 0 00-.317.242v4.242a.248.248 0 00.317.242 2.5 2.5 0 000-4.726zM4 12h-.75a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25H4a1 1 0 000-2z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zM5.972 13.338A2.079 2.079 0 013.888 15H3.25a.25.25 0 00-.25.25v2.25a.5.5 0 01-1 0v-6a.5.5 0 01.5-.5H4a2 2 0 011.972 2.338zm2.385.133L10.2 14.7a1.8 1.8 0 01-1 3.3H7.5a.5.5 0 010-1h1.7a.8.8 0 00.446-1.471L7.8 14.3a1.8 1.8 0 011-3.3h1.7a.5.5 0 010 1H8.8a.8.8 0 00-.446 1.471zM12.5 18a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5 3.5 3.5 0 010 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});