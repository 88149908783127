define("ember-svg-jar/inlined/button-zigzag-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-zigzag-2</title><path d=\"M7.384 10.137a1.809 1.809 0 00-1.871-.158 1.806 1.806 0 00-.9 1.645v11a1.25 1.25 0 002.5 0v-9.2a.25.25 0 01.389-.208l5.837 3.892a1.722 1.722 0 002.774-1.486V5.936a.25.25 0 01.25-.25h2.032a1 1 0 00.71-1.7L15.57.419a1 1 0 00-1.412-.008l-3.625 3.562a1 1 0 00.7 1.713h2.125a.25.25 0 01.25.25v7.884a.249.249 0 01-.388.208z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});