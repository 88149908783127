define("ember-svg-jar/inlined/underwear-shorts-male-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>underwear-shorts-male-1</title><path d=\"M22.89 17.621L22.024.948a1 1 0 00-1-.948H2.975a1 1 0 00-1 .948L1.11 17.621a2.51 2.51 0 00.735 1.9 1 1 0 01.3.711v2.518A1.252 1.252 0 003.392 24h5a1.251 1.251 0 001.25-1.25v-2.106a.5.5 0 01.339-.473A1.5 1.5 0 0011 18.75V12a1 1 0 012 0v6.75a1.5 1.5 0 00.857 1.355.5.5 0 01.285.452v2.193a1.252 1.252 0 001.25 1.25h5a1.251 1.251 0 001.25-1.25v-2.318a1 1 0 01.358-.768 2.5 2.5 0 00.89-2.043zM5.142 22.25a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25V20.5a.25.25 0 01.25-.25h1a.25.25 0 01.25.25zm-.92-15.818a.5.5 0 01-.589-.518l.154-2.94a.5.5 0 01.5-.474h3.007a.5.5 0 01.484.625 4.513 4.513 0 01-3.556 3.307zm3.92 15.818a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25V20.5a.25.25 0 01.25-.25h1a.25.25 0 01.25.25zm9 0a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25V20.5a.25.25 0 01.25-.25h1a.25.25 0 01.25.25zm3 0a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25V20.5a.25.25 0 01.25-.25h1a.25.25 0 01.25.25zm.05-15.935a.5.5 0 01-.42.117 4.5 4.5 0 01-3.547-3.308.5.5 0 01.485-.624h3a.5.5 0 01.5.474l.154 2.94a.5.5 0 01-.172.401z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});