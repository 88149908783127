define("ember-svg-jar/inlined/trees-hive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trees-hive</title><path d=\"M22.909 7.636h-.272a.273.273 0 01-.164-.491 1.091 1.091 0 10-1.31-1.745L18.4 7.473a.25.25 0 01-.4-.2V5.727a1.638 1.638 0 00-1.637-1.636h-4.354a2.743 2.743 0 00-2.623 1.977l-.419 1.468a.25.25 0 01-.48 0l-.422-1.477a2.727 2.727 0 00-2.611-1.968 1.638 1.638 0 00-1.636 1.636V15a2.73 2.73 0 01-2.727 2.727A1.093 1.093 0 000 18.82a1.093 1.093 0 001.091 1.089h19.636a1.091 1.091 0 000-2.182A2.73 2.73 0 0118 15v-4.255a.5.5 0 01.2-.4c.176-.129.423-.313.574-.425a.492.492 0 01.3-.1h3.834a1.091 1.091 0 100-2.182zm-9.273 2.455a.546.546 0 01-1.091 0V7.364a.546.546 0 111.091 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});