define("ember-svg-jar/inlined/love-plant-pot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-plant-pot</title><path d=\"M15.9 18H8.1a.5.5 0 00-.491.59l.672 3.658A2 2 0 0010.266 24h3.468a2 2 0 001.985-1.752l.672-3.658A.5.5 0 0015.9 18zM9.684 14.636a.5.5 0 00.281-.649l-.365-.931a3.5 3.5 0 00-4.541-1.967l-.468.183a1 1 0 00-.561 1.3l.185.465A3.5 3.5 0 008.752 15zM19.411 11.272l-.465-.183a3.5 3.5 0 00-4.546 1.967l-.369.931a.5.5 0 00.281.649l.931.368a3.5 3.5 0 004.542-1.967l.185-.467a1 1 0 00-.559-1.298z\"/><path d=\"M10.93 10.191a.247.247 0 01.07.173v5.886a.751.751 0 00.75.75h.5a.751.751 0 00.75-.75v-5.885a.25.25 0 01.07-.173l4.2-4.376A3.4 3.4 0 0015.4.047 3.354 3.354 0 0012.449 1l-.271.272a.252.252 0 01-.354 0L11.553 1a3.407 3.407 0 00-5.453.885 3.4 3.4 0 00.631 3.923z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});