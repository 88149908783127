define("ember-svg-jar/inlined/music-note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-note</title><path d=\"M16.786 4.59l-2.943 1.177a.25.25 0 01-.343-.232v-1.1a.5.5 0 01.314-.464l3.9-1.561a1.249 1.249 0 10-.928-2.32l-4.371 1.748A2.241 2.241 0 0011 3.927V15.75a.25.25 0 01-.25.25H9.5a4 4 0 000 8 4.1 4.1 0 004-4V8.765a.25.25 0 01.157-.232l4.057-1.623a1.249 1.249 0 10-.928-2.32z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});