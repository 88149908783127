define("ember-svg-jar/inlined/music-sound", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-sound</title><path d=\"M12 0a1 1 0 00-1 1v22a1 1 0 002 0V1a1 1 0 00-1-1zM8 2a1 1 0 00-1 1v18a1 1 0 002 0V3a1 1 0 00-1-1zM4 6a1 1 0 00-1 1v10a1 1 0 002 0V7a1 1 0 00-1-1zM1 10a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zM16 2a1 1 0 00-1 1v18a1 1 0 002 0V3a1 1 0 00-1-1zM20 6a1 1 0 00-1 1v10a1 1 0 002 0V7a1 1 0 00-1-1zM23 10a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});