define("ember-svg-jar/inlined/bluetooth.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bluetooth</title><path d=\"M3.667 11.1H1a1 1 0 000 2h2.667a1 1 0 000-2zM23 11.1h-2.667a1 1 0 000 2H23a1 1 0 000-2zM18.018 8.211a1 1 0 00-.4-.788L12.124 3.3a1 1 0 00-1.6.8v5.265L6.787 6.409a1 1 0 10-1.24 1.568l4.977 3.938v.154L5.512 16.02a1 1 0 001.238 1.572l3.774-2.977V19.9a1 1 0 001.6.8l5.493-4.12a1 1 0 00.021-1.584l-3.789-3 3.788-2.986a1 1 0 00.381-.799zm-2.894 7.342a.25.25 0 01-.005.4l-2.2 1.647a.247.247 0 01-.261.023.25.25 0 01-.139-.223v-3.387a.25.25 0 01.141-.225.254.254 0 01.265.029zm-2.2-5.38a.251.251 0 01-.405-.2V6.6a.25.25 0 01.139-.223.247.247 0 01.261.023l2.193 1.644a.251.251 0 010 .4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});