define("ember-svg-jar/inlined/app-window-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-refresh</title><path d=\"M23.729 12.022a.5.5 0 00-.854-.353l-1.3 1.3a.249.249 0 01-.348.005 6.375 6.375 0 10.627 8.631 1 1 0 00-1.554-1.251 4.374 4.374 0 11-.5-5.982.251.251 0 01.009.363l-1.391 1.391a.5.5 0 00.354.853h4.457a.5.5 0 00.5-.5z\"/><path d=\"M21 0H3a3 3 0 00-3 3v14.5a3 3 0 003 3h4.5a1 1 0 001-1 1 1 0 00-1-1H3a1 1 0 01-1-1V5.251A.25.25 0 012.25 5h19.5a.25.25 0 01.25.25V8.5a1 1 0 001 1 1 1 0 001-1V3a3 3 0 00-3-3zM4.346 3a.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.987.987 0 01.092-.409A1 1 0 013.5 1.5a.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5zm3.5 0a.966.966 0 01-1.692 0A.972.972 0 016 2.5a.969.969 0 01.154-.5.966.966 0 011.692 0A.969.969 0 018 2.5a.972.972 0 01-.154.5zm3.5 0a.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});