define("ember-svg-jar/inlined/picture-double", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>picture-double</title><path d=\"M3.25 13.25a1 1 0 01-1-1v-6a1 1 0 011-1h11a1 1 0 01.98.8.25.25 0 00.245.2H17a.25.25 0 00.25-.25v-.75a2 2 0 00-2-2h-13a2 2 0 00-2 2v8a2 2 0 002 2H4a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25z\"/><path d=\"M21.25 7.75h-13a2.5 2.5 0 00-2.5 2.5v8a2.5 2.5 0 002.5 2.5h13a2.5 2.5 0 002.5-2.5v-8a2.5 2.5 0 00-2.5-2.5zm-13 9.5v-6a1 1 0 011-1h11a1 1 0 011 1v6a1 1 0 01-1 1h-11a1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});