define("ember-svg-jar/inlined/multiple-actions-credit-card.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-credit-card</title><path d=\"M18.313 10.707c-.452-.9-1.9-1.435-3.9-2.171l-.483-.172a1.789 1.789 0 01-.053-1.164 4.626 4.626 0 001.212-3.555A3.207 3.207 0 0011.955.17a2.981 2.981 0 00-1.337.312.249.249 0 00-.1.362 4.98 4.98 0 01.831 2.8 6.237 6.237 0 01-.919 3.61.25.25 0 00.122.371c1.91.713 3.287 1.322 3.89 2.527a6.9 6.9 0 01.569 1.772.25.25 0 00.245.2h3.2a.248.248 0 00.2-.1.251.251 0 00.047-.213 5.277 5.277 0 00-.39-1.104z\"/><path d=\"M11.954 12.125h1.526a.25.25 0 00.2-.1.254.254 0 00.047-.213 5.277 5.277 0 00-.391-1.11c-.453-.9-1.9-1.435-3.9-2.171l-.483-.172A1.789 1.789 0 018.9 7.2a4.627 4.627 0 001.211-3.555A3.206 3.206 0 006.974.17 3.207 3.207 0 003.84 3.642a4.593 4.593 0 001.188 3.525 1.876 1.876 0 010 1.18l-.513.189c-2 .736-3.444 1.267-3.9 2.171A7.512 7.512 0 000 13.619a.5.5 0 00.5.5h8.48a.249.249 0 00.23-.153 2.981 2.981 0 012.744-1.841zM22.257 13.37h-9.963a1.745 1.745 0 00-1.743 1.743v6.974a1.745 1.745 0 001.743 1.743h9.963A1.745 1.745 0 0024 22.087v-6.974a1.745 1.745 0 00-1.743-1.743zm0 8.966h-9.963a.25.25 0 01-.249-.249V19.1a.25.25 0 01.249-.249h9.963a.25.25 0 01.249.249v2.989a.25.25 0 01-.249.247zm.249-7.223v1a.25.25 0 01-.249.249h-9.963a.25.25 0 01-.249-.249v-1a.25.25 0 01.249-.249h9.963a.25.25 0 01.249.249z\"/><path d=\"M21.011 20.593a.747.747 0 00-.747-.748h-1.245a.748.748 0 000 1.5h1.245a.747.747 0 00.747-.752z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});