define("ember-svg-jar/inlined/social-media-reddit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-reddit</title><path d=\"M24 11.5a3 3 0 00-5.42-1.76A12.44 12.44 0 0012.76 8c.06-1.06.37-2.9 1.38-3.48.63-.36 1.55-.19 2.71.46a2.49 2.49 0 10.34-1.53 3.91 3.91 0 00-3.8-.23c-1.77 1-2.09 3.65-2.14 4.78a12.45 12.45 0 00-5.83 1.74A3 3 0 000 11.5a3 3 0 002 2.84A5 5 0 002 15c0 3.86 4.49 7 10 7s10-3.14 10-7a5 5 0 000-.66 3 3 0 002-2.84zm-17.5 2A1.5 1.5 0 118 15a1.5 1.5 0 01-1.5-1.5zm9.41 5a8 8 0 01-3.91 1 7.94 7.94 0 01-3.93-1 .75.75 0 01.75-1.3 7 7 0 006.34 0 .75.75 0 01.75 1.3zM16 15a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0116 15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});