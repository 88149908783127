define("ember-svg-jar/inlined/module-puzzle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>module-puzzle</title><path d=\"M4.123 8.3a.25.25 0 01.157.426L1.008 12a.971.971 0 000 1.374l1.783 1.784a.5.5 0 00.777-.088 3.79 3.79 0 01.531-.665 3.886 3.886 0 015.5 5.5 3.837 3.837 0 01-.665.531.5.5 0 00-.088.777l1.784 1.784a.972.972 0 001.374 0l3.273-3.273a.25.25 0 01.426.157 3.871 3.871 0 005.891 3 3.892 3.892 0 00.715-6.066 3.822 3.822 0 00-2.428-1.11.25.25 0 01-.157-.426L22.992 12a.971.971 0 000-1.374l-1.783-1.784a.5.5 0 00-.777.088 3.79 3.79 0 01-.532.67 3.886 3.886 0 01-5.5-5.5 3.837 3.837 0 01.665-.531.5.5 0 00.088-.777l-1.779-1.785a.972.972 0 00-1.374 0L8.727 4.28a.25.25 0 01-.427-.157 3.871 3.871 0 00-5.891-3A3.892 3.892 0 001.7 7.191 3.822 3.822 0 004.123 8.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});