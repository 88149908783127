define("ember-svg-jar/inlined/microphone-stage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>microphone-stage</title><path d=\"M23.245 20.405L21.387 1.349A1.5 1.5 0 0018.627.7l-3.515 5.537a.248.248 0 00.023.3 4.524 4.524 0 01.82 1.36.25.25 0 00.445.045l2.748-4.333a.25.25 0 01.46.11L21.235 20.4a.252.252 0 01-.068.2c-.467.484-2.621 1.4-6.417 1.4-3.391 0-5.467-.732-6.2-1.222a.249.249 0 01-.073-.342l3.85-6.07a.249.249 0 00-.229-.383 4.89 4.89 0 01-.343.013 4.485 4.485 0 01-1.239-.174.251.251 0 00-.28.107l-3.831 6.033a1.021 1.021 0 00-.155.54c0 2.405 4.406 3.5 8.5 3.5s8.5-1.095 8.5-3.5a1.05 1.05 0 00-.005-.097z\"/><path d=\"M14.25 18.248a.25.25 0 01-.25.25h-.5a1 1 0 000 2h3a1 1 0 000-2 .25.25 0 01-.25-.25v-2.1a.25.25 0 01.359-.225 1.422 1.422 0 001.58-2.3L15.872 11.3l-1.093-1.093a.251.251 0 01-.07-.218 2.964 2.964 0 00.041-.489 3 3 0 10-2.034 2.84.249.249 0 01.258.06l1.205 1.207a.251.251 0 01.073.177l-.002 4.464zM4 11a.5.5 0 00.492-.43l.057-.4a2.513 2.513 0 012.122-2.118L7.067 8a.5.5 0 000-.99l-.4-.057a2.513 2.513 0 01-2.118-2.126l-.057-.4a.5.5 0 00-.984 0l-.057.4A2.513 2.513 0 011.33 6.948l-.4.057a.5.5 0 000 .99l.4.057a2.513 2.513 0 012.122 2.121l.057.4A.5.5 0 004 11zM9.179 2.993l.265.038A1.507 1.507 0 0110.717 4.3l.038.265a.5.5 0 00.99 0l.038-.266a1.507 1.507 0 011.274-1.272l.264-.038a.5.5 0 000-.99l-.265-.038A1.508 1.508 0 0111.783.692l-.038-.265a.5.5 0 00-.99 0l-.038.265a1.508 1.508 0 01-1.274 1.273L9.179 2a.5.5 0 000 .99z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});