define("ember-svg-jar/inlined/fast-food-hot-dog-grilled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fast-food-hot-dog-grilled</title><path d=\"M13.978 4.813a.5.5 0 010-.707l1.982-1.981a1 1 0 111.414 1.415l-1.981 1.981a.5.5 0 01-.707 0zM18.478 9.313a.5.5 0 010-.707l1.982-1.981a1 1 0 111.414 1.415l-1.981 1.981a.5.5 0 01-.707 0zM16.228 7.063a.5.5 0 010-.707l1.982-1.981a1 1 0 111.414 1.415l-1.981 1.981a.5.5 0 01-.707 0zM.367 23.633a1.247 1.247 0 010-1.767l5.646-5.646a.748.748 0 011.06 0l.707.707a.75.75 0 010 1.061l-5.646 5.645a1.248 1.248 0 01-1.767 0zM22.654 16.154L7.846 1.346a4.6 4.6 0 00-6.5 6.5l14.808 14.808a4.6 4.6 0 006.5-6.5zM2.75 5.5a.75.75 0 01.75-.75H8a.75.75 0 010 1.5H3.5a.75.75 0 01-.75-.75zm4 4a.75.75 0 01.75-.751H12a.751.751 0 010 1.5H7.5a.75.75 0 01-.75-.749zm4.5 5a.75.75 0 01.75-.75h4.5a.75.75 0 010 1.5H12a.75.75 0 01-.75-.75zm9.25 4.75H16a.75.75 0 010-1.5h4.5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});