define("ember-svg-jar/inlined/gmo-food-corn-dna", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gmo-food-corn-dna</title><path d=\"M3.844 9.984a13.722 13.722 0 011.378.646.367.367 0 00.432-.061.37.37 0 01.258-.105H7.09a1 1 0 100-2h-1.4l.158-2.531a.5.5 0 01.5-.469H7.59a1 1 0 000-2h-.641a.25.25 0 01-.182-.422 3.189 3.189 0 015.507 1.986l.056.905a.5.5 0 01-.5.531h-1.24a1 1 0 000 2h1.429a.5.5 0 01.5.469.405.405 0 00.609.324 2.147 2.147 0 01.911-.283.5.5 0 00.453-.529L14.27 4.9a5.191 5.191 0 00-10.361 0l-.294 4.719a.369.369 0 00.229.365zM7.885 14.6a12.479 12.479 0 00-6.675-3.861 1 1 0 00-.952 1.648 7.093 7.093 0 011.332 4.077 7.5 7.5 0 003.624 6.418.25.25 0 00.379-.209 14 14 0 012.336-7.458.5.5 0 00-.044-.615zM13.833 17.52a.5.5 0 000-.612 8.787 8.787 0 01-1.618-3.59.251.251 0 00-.4-.145A12.509 12.509 0 007.1 23.317a.5.5 0 00.393.475 7.549 7.549 0 001.6.172 7.45 7.45 0 002.574-.456.5.5 0 00.328-.477 9.341 9.341 0 011.838-5.511z\"/><path d=\"M21.655 17.594a.5.5 0 010-.76A7.406 7.406 0 0024 11.214a.75.75 0 10-1.5 0 .25.25 0 01-.25.25h-7a.25.25 0 01-.25-.25.75.75 0 10-1.5 0 7.411 7.411 0 002.345 5.62.5.5 0 010 .76 7.411 7.411 0 00-2.345 5.62.75.75 0 001.5 0 .25.25 0 01.25-.25h7a.25.25 0 01.25.25.75.75 0 001.5 0 7.406 7.406 0 00-2.345-5.62zm-5.761-3.63a.5.5 0 01-.469-.325 6.574 6.574 0 01-.119-.351.247.247 0 01.038-.223.251.251 0 01.2-.1h6.411a.25.25 0 01.239.324q-.055.178-.12.351a.5.5 0 01-.468.325zm4.518 1.955a2.807 2.807 0 01-3.324 0 .25.25 0 01.143-.455h3.039a.249.249 0 01.142.455zm.1 2.869a.251.251 0 01-.239.176h-3.042a.25.25 0 01-.143-.455 2.807 2.807 0 013.324 0 .25.25 0 01.097.279zm1.566 2c.043.114.083.232.12.351a.251.251 0 01-.239.324h-6.414a.249.249 0 01-.2-.1.245.245 0 01-.038-.222c.037-.119.076-.237.119-.351a.5.5 0 01.469-.325h5.713a.5.5 0 01.467.324z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});