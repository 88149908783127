define("ember-svg-jar/inlined/protection-vest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>protection-vest</title><path d=\"M12 7.1c1.085 0 2.424-1.827 3.184-4.993a.25.25 0 00-.378-.269 5.363 5.363 0 01-2.8.718 5.372 5.372 0 01-2.81-.721.25.25 0 00-.379.269C9.575 5.276 10.914 7.1 12 7.1z\"/><path d=\"M23.97 12.412a1.008 1.008 0 00-.594-1.172c-3.882-1.59-3.371-4.891-2.442-8.423a1.5 1.5 0 00-.984-1.806L17.36.163a.246.246 0 00-.21.026.252.252 0 00-.116.177C16.345 5.31 14.347 8.6 12 8.6 9.651 8.6 7.654 5.309 6.965.363a.249.249 0 00-.326-.2l-2.592.853a1.5 1.5 0 00-.98 1.805c.927 3.528 1.438 6.83-2.443 8.419a1.008 1.008 0 00-.594 1.172L2.725 23.1a1 1 0 00.97.756h16.61a1 1 0 00.97-.756zM10 18.353a1 1 0 01-1 1H6a1 1 0 01-1-1v-1a1 1 0 011-1h3a1 1 0 011 1zm0-5a1 1 0 01-1 1H7a1 1 0 01-1-1v-1a1 1 0 011-1h2a1 1 0 011 1zm4-1a1 1 0 011-1h2a1 1 0 011 1v1a1 1 0 01-1 1h-2a1 1 0 01-1-1zm5 6a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a1 1 0 011-1h3a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});