define("ember-svg-jar/inlined/shopping-cart-upload-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-cart-upload-1</title><path d=\"M18.5 13a5.5 5.5 0 105.5 5.5 5.506 5.506 0 00-5.5-5.5zm2.1 5.3a.5.5 0 01-.451.283H19.5a.25.25 0 00-.25.25v2.142a.75.75 0 01-1.5 0v-2.138a.25.25 0 00-.25-.25h-.652a.5.5 0 01-.391-.813l1.652-2.065a.52.52 0 01.782 0l1.652 2.065a.5.5 0 01.057.526z\"/><path d=\"M12.657 14a1 1 0 00-1-1H8.239A.25.25 0 018 12.808l-1.15-5a.25.25 0 01.239-.308H20.28a.5.5 0 01.474.658l-.749 2.248a1 1 0 101.9.633l1-3A1.928 1.928 0 0021.072 5.5H6.514a.249.249 0 01-.243-.194l-.878-3.819A1.922 1.922 0 003.515 0H1a1 1 0 000 2l2.24-.055a.252.252 0 01.25.194l3.357 14.6a.248.248 0 01-.107.265 2.251 2.251 0 102.286-.1.256.256 0 01-.126-.165l-.33-1.437A.25.25 0 018.814 15h2.843a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});