define("ember-svg-jar/inlined/pregnancy-sperm-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pregnancy-sperm-1</title><path d=\"M18.994 1.257c-2.88 0-4.785 1.148-5.578 2.325a.251.251 0 01-.314.087 1.04 1.04 0 01-.4-.33 3.048 3.048 0 00-2.66-1.259A3.016 3.016 0 007.57 3.67l-.545 1.014a1 1 0 01-1.656.166 2.993 2.993 0 00-2.307-1.08H1a1 1 0 000 2h2.062a1 1 0 01.771.36 3 3 0 004.953-.5l.546-1.014a1.031 1.031 0 011.747-.112 3.02 3.02 0 002.1 1.222.249.249 0 01.182.117c.751 1.21 2.679 2.421 5.637 2.421 2.488 0 5.006-1.2 5.006-3.5-.004-2.279-2.583-3.507-5.01-3.507zM15.539 10.27a5.045 5.045 0 00-3.3 1.056.25.25 0 01-.326 0 2.936 2.936 0 00-2.389-.671c-2.112.345-1.919 2.058-2.911 2.222a.967.967 0 01-.833-.268 2.939 2.939 0 00-2.091-.866H2.5a1 1 0 000 2h1.189a.956.956 0 01.678.28 2.963 2.963 0 002.094.867c2.448 0 2.395-2.1 3.383-2.263a.934.934 0 01.8.259 2.905 2.905 0 00.967.635.248.248 0 01.112.091 4.536 4.536 0 003.812 1.606c1.72 0 3.461-.85 3.461-2.474.004-1.607-1.779-2.474-3.457-2.474zM13.539 17.8a5.041 5.041 0 00-3.31 1.062.251.251 0 01-.322 0 2.887 2.887 0 00-2.387-.681c-2.12.346-1.907 2.057-2.909 2.219a.977.977 0 01-.832-.269 2.934 2.934 0 00-2.09-.864H1a1 1 0 000 2h.689a.951.951 0 01.677.279 2.966 2.966 0 002.092.867c2.458 0 2.4-2.1 3.385-2.263a.912.912 0 01.8.259 2.875 2.875 0 00.967.635.25.25 0 01.112.091 4.533 4.533 0 003.812 1.606c1.72 0 3.461-.849 3.461-2.474.005-1.605-1.778-2.467-3.456-2.467z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});