define("ember-svg-jar/inlined/hard-drive-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hard-drive-1</title><path d=\"M23 11.751H1a1 1 0 00-1 1v3a2.493 2.493 0 001.591 2.316.25.25 0 01.159.233v1.2a.75.75 0 001.5 0v-1a.25.25 0 01.25-.25h17a.25.25 0 01.25.25v1a.75.75 0 101.5 0v-1.2a.25.25 0 01.159-.233A2.493 2.493 0 0024 15.751v-3a1 1 0 00-1-1zm-3 4a1 1 0 111-1 1 1 0 01-1 1zm-2-1a1 1 0 11-1-1 1 1 0 011 1zm-3 0a1 1 0 11-1-1 1 1 0 011 1zM1.084 10.751h21.832a.994.994 0 00.928-.629.983.983 0 00-.219-1.075L19.061 4.19A1.494 1.494 0 0018 3.751H6a1.5 1.5 0 00-1.072.45L.394 9.027a1 1 0 00.69 1.724z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});