define("ember-svg-jar/inlined/single-woman-actions-check-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-check-1</title><path d=\"M11.79 11.915a.252.252 0 00.065-.231.249.249 0 00-.157-.179l-.224-.082a.779.779 0 01-.513-.73v-.467a.25.25 0 01.25-.25A6.374 6.374 0 0015 9.081a.388.388 0 00.084-.593c-.494-.537-.835-1.185-.981-3.317C13.838 1.346 11.236 0 9 0S4.161 1.346 3.9 5.171c-.145 2.132-.487 2.78-.981 3.317A.388.388 0 003 9.081a6.348 6.348 0 003.782.9.249.249 0 01.253.25v.462a.778.778 0 01-.512.73c-2.9 1.065-5.212 1.825-5.764 2.922A7.822 7.822 0 000 17.076a.386.386 0 00.1.3.4.4 0 00.29.126h8.864a.25.25 0 00.25-.242 7.958 7.958 0 012.286-5.345z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm.558 9.378a1.808 1.808 0 01-1.324.721h-.13a1.805 1.805 0 01-1.28-.534l-1.624-1.62a1 1 0 011.414-1.415l1.27 1.27a.249.249 0 00.377-.027l2.844-3.794a1 1 0 111.6 1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});