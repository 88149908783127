define("ember-svg-jar/inlined/music-note-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-note-1</title><path d=\"M14.825 3.21c-.958-.639-1.444-1.3-1.444-1.96a1.25 1.25 0 00-2.5 0v14.5a.25.25 0 01-.25.25h-1.25a4 4 0 000 8 4.1 4.1 0 004-4V5.728a.25.25 0 01.4-.2c3.419 2.475 2.434 5.033 1.3 7.981l-.113.3a1.25 1.25 0 102.332.891l.113-.293c1.153-3.007 2.899-7.539-2.588-11.197z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});