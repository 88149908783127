define("ember-svg-jar/inlined/camera-holder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camera-holder</title><path d=\"M6.75 13.25a.25.25 0 00-.25.25v6.25a2 2 0 004 0v-2.5a2 2 0 012-2 2 2 0 001.98-1.714.25.25 0 00-.248-.286zM6 11.75a.5.5 0 00.5.5H17a1.5 1.5 0 001.5-1.5v-7a1.5 1.5 0 00-1.5-1.5H7.5A1.5 1.5 0 006 3.75V5.5a.25.25 0 01-.25.25H3.325a.251.251 0 01-.215-.121l-.39-.65a1.508 1.508 0 00-1.286-.729H1a1 1 0 00-1 1v4a1 1 0 001 1h.434a1.508 1.508 0 001.286-.729l.39-.65a.251.251 0 01.215-.121H5.75A.25.25 0 016 9zm2.5-5.5a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5zM24 4.614a1.5 1.5 0 00-2.335-1.246l-1.443.966A.5.5 0 0020 4.75v3a.5.5 0 00.224.416l1.447.961A1.5 1.5 0 0024 7.877z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});