define("ember-svg-jar/inlined/wedding-money-piggy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wedding-money-piggy</title><path d=\"M20.475 12.25a1 1 0 00-1 1 2.216 2.216 0 01-.63 1.548.3.3 0 01-.52-.21 5.978 5.978 0 00-.124-1.2.5.5 0 00-.539-.4c-.054 0-.108.008-.162.008a2 2 0 01-1.444-.616l-4.332-4.52a1 1 0 00-.606-.3 9.821 9.821 0 00-2.634.047.5.5 0 01-.458-.17A3.387 3.387 0 004.6 6.36a.912.912 0 00-.4 1.545 2.4 2.4 0 01.5.682.5.5 0 01-.154.624 6.972 6.972 0 00-2.644 3.617.61.61 0 01-.583.435A1.326 1.326 0 000 14.588v1.649a1.326 1.326 0 001.325 1.325h.8a.5.5 0 01.43.245 7.486 7.486 0 002.347 2.4.5.5 0 01.2.569L4.5 22.7a1 1 0 001.9.6l.513-1.616a.5.5 0 01.606-.331 9.442 9.442 0 004.934.006.5.5 0 01.605.331l.511 1.61a1 1 0 001.906-.606l-.61-1.922a.5.5 0 01.2-.568 7.572 7.572 0 002.3-2.346 1 1 0 01.663-.459 4.238 4.238 0 003.445-4.152 1 1 0 00-.998-.997zM5.038 14.263a.913.913 0 11.912-.912.914.914 0 01-.912.912z\"/><path d=\"M23.627 1.95a3.526 3.526 0 00-5.646-.916.681.681 0 01-.963 0 3.525 3.525 0 00-4.993 4.977l5.113 5.335a.5.5 0 00.722 0l5.107-5.327a3.522 3.522 0 00.66-4.069z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});