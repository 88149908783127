define("ember-svg-jar/inlined/fossil-energy-gas-latern", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fossil-energy-gas-latern</title><path d=\"M18.05 21H16.5a3.5 3.5 0 003.5-3.5V8.75a3.489 3.489 0 00-2.844-3.421.5.5 0 01-.406-.491V2.5a.5.5 0 01.5-.5h1.25a1 1 0 000-2h-13a1 1 0 000 2h1.25a.5.5 0 01.5.5v2.338a.5.5 0 01-.407.491A3.489 3.489 0 004 8.75v8.75A3.5 3.5 0 007.5 21H5.95a3 3 0 00-2.94 2.4.5.5 0 00.49.6h17a.5.5 0 00.491-.6A3 3 0 0018.05 21zM9.25 2.5a.5.5 0 01.5-.5h4.5a.5.5 0 01.5.5v2.25a.5.5 0 01-.5.5h-4.5a.5.5 0 01-.5-.5zm2.75 15A3.5 3.5 0 018.5 14a3.142 3.142 0 011.88-2.926.266.266 0 01.371.156 1.955 1.955 0 00.569.811.25.25 0 00.4-.082 3.673 3.673 0 00-.32-3.344.268.268 0 01.33-.394A6.439 6.439 0 0115.5 14a3.5 3.5 0 01-3.5 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});