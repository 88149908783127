define("ember-svg-jar/inlined/love-potion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-potion</title><path d=\"M8.5 2h7a1 1 0 000-2h-7a1 1 0 000 2zM14.843 6.467a.5.5 0 01-.343-.474V4a.5.5 0 00-.5-.5h-4a.5.5 0 00-.5.5v1.993a.5.5 0 01-.343.474 9 9 0 105.686 0zM8.75 10.5a.75.75 0 11-.75.75.75.75 0 01.75-.75zm3.6 7.049l-2.612 2.815a.421.421 0 01-.619 0l-2.613-2.815a1.831 1.831 0 01-.323-2.062 1.7 1.7 0 012.768-.464.663.663 0 00.955 0 1.7 1.7 0 012.769.464 1.832 1.832 0 01-.324 2.062zm1.9 2.951a.75.75 0 11.75-.75.75.75 0 01-.75.75zm2.418-8.468l-1.715 1.877a.272.272 0 01-.406 0l-1.715-1.877a1.233 1.233 0 01-.212-1.374 1.107 1.107 0 011.817-.309.433.433 0 00.626 0 1.107 1.107 0 011.817.309 1.233 1.233 0 01-.212 1.374zM17.75 17a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});