define("ember-svg-jar/inlined/move-right-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>move-right-1</title><path d=\"M17.6 11.168L13.785 7.35a.5.5 0 00-.853.353v2.318a.25.25 0 01-.25.25H1.5a1.25 1.25 0 000 2.5h11.182a.25.25 0 01.25.25v2.319a.5.5 0 00.308.462.513.513 0 00.192.038.5.5 0 00.353-.147l3.815-3.818a.5.5 0 000-.707zM22.5.249A1.25 1.25 0 0021.25 1.5v21a1.25 1.25 0 002.5 0v-21A1.25 1.25 0 0022.5.249z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});