define("ember-svg-jar/inlined/livestock-lamb-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>livestock-lamb-1</title><path d=\"M23.332 11.332a3.767 3.767 0 00-2.668-3.589 3.747 3.747 0 00-5.075-5.075 3.748 3.748 0 00-7.178 0 3.747 3.747 0 00-5.075 5.075 3.748 3.748 0 000 7.178 3.741 3.741 0 001.914 5.253V22.5a1.5 1.5 0 003 0v-2.423c.054-.026.108-.053.161-.081a3.748 3.748 0 007.178 0c.053.029.107.055.161.081V22.5a1.5 1.5 0 003 0v-2.326a3.741 3.741 0 001.914-5.253 3.767 3.767 0 002.668-3.589zm-3.4.19a.5.5 0 01-.175.35 5.085 5.085 0 01-2.848 1.09 5.482 5.482 0 01-4.159 3.983v-2.387l1.28-1.281A.75.75 0 0013.5 12h-3a.75.75 0 00-.53 1.28l1.28 1.281v2.387a5.482 5.482 0 01-4.159-3.983 5.089 5.089 0 01-2.848-1.09.5.5 0 01-.045-.719l2.916-3.188C7.859 5.767 9.572 4 12 4s4.141 1.77 4.886 3.971l2.914 3.182a.5.5 0 01.132.369z\"/><circle cx=\"14\" cy=\"9.5\" r=\"1\"/><circle cx=\"10\" cy=\"9.5\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});