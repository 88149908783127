define("ember-svg-jar/inlined/arrange-spacing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrange-spacing</title><path d=\"M12.707 8.786a1 1 0 001.308-.538l.693-1.664a.25.25 0 01.23-.154h2.394a.248.248 0 01.23.154l.694 1.664a1 1 0 001.846-.77L17.344.858a1.309 1.309 0 00-2.416 0l-2.759 6.62a1 1 0 00.538 1.308zm3.958-4.356h-1.06l.53-1.271zM7.865 13.728a1 1 0 00.769-1.64L6.2 9.169a1.034 1.034 0 00-1.537 0l-2.432 2.919A1 1 0 003 13.728h1.183a.25.25 0 01.25.25v4.8a.25.25 0 01-.25.25H3a1 1 0 00-.769 1.641l2.433 2.919a1 1 0 001.537 0l2.433-2.919a1 1 0 00-.769-1.641H6.683a.25.25 0 01-.25-.25v-4.8a.25.25 0 01.25-.25zM11.271 11.3H21a1 1 0 000-2h-9.729a1 1 0 000 2zM16.135 12.214a1.3 1.3 0 00-1.207.8l-2.759 6.626a1 1 0 001.846.77l.693-1.664a.25.25 0 01.23-.154h2.394a.248.248 0 01.23.154l.694 1.664a1 1 0 00.923.615.979.979 0 00.384-.077 1 1 0 00.537-1.308l-2.758-6.62a1.306 1.306 0 00-1.207-.806zm-.53 4.378l.53-1.271.53 1.271zM21 21.458h-9.729a1 1 0 000 2H21a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});