define("ember-svg-jar/inlined/light-mode-sunny-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-mode-sunny-woman</title><path d=\"M17.25 2.5a.75.75 0 00.75-.75v-1a.75.75 0 00-1.5 0v1a.75.75 0 00.75.75zM21.316 3.745l.707-.707a.75.75 0 10-1.06-1.061l-.707.707a.75.75 0 001.06 1.061zM23.25 6h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM21.316 9.755a.75.75 0 00-1.06 1.061l.707.707a.75.75 0 101.06-1.061zM17.25 11a.75.75 0 00-.75.75v1a.75.75 0 001.5 0v-1a.75.75 0 00-.75-.75zM13.185 9.755l-.707.707a.75.75 0 101.06 1.061l.707-.707a.75.75 0 00-1.06-1.061zM13 6.75a.75.75 0 00-.75-.75h-1a.75.75 0 000 1.5h1a.75.75 0 00.75-.75zM13.185 3.745a.75.75 0 001.06-1.061l-.707-.707a.75.75 0 10-1.06 1.061z\"/><circle cx=\"17.25\" cy=\"6.75\" r=\"3.5\"/><path d=\"M.863 16.76a1 1 0 101.68 1.085 5.559 5.559 0 00.522-1.033.249.249 0 01.376-.116 4.49 4.49 0 005.128 0 .251.251 0 01.376.116 5.5 5.5 0 00.522 1.033 1 1 0 101.679-1.085 4.57 4.57 0 01-.641-2.511V13a4.5 4.5 0 00-9 0v1.249a4.562 4.562 0 01-.642 2.511zm5.142-1.26a2.5 2.5 0 01-2.465-2.085.251.251 0 01.159-.276 5.718 5.718 0 002.134-1.407.252.252 0 01.181-.077.247.247 0 01.18.078 5.73 5.73 0 002.118 1.4.249.249 0 01.158.275A2.5 2.5 0 016.005 15.5zM6 18.5c-3.309 0-6 2.243-6 5a.5.5 0 00.5.5h11a.5.5 0 00.5-.5c0-2.757-2.691-5-6-5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});