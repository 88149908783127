define("ember-svg-jar/inlined/road-sign-traffic-priority-right-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-traffic-priority-right-alternate</title><path d=\"M11.038 9.384a.5.5 0 01.108-.545l2.147-2.147a1 1 0 011.414 0l2.147 2.147a.5.5 0 01-.354.853h-.75a.25.25 0 00-.25.25V15.5a1 1 0 01-1 1h-1a1 1 0 01-1-1V9.942a.25.25 0 00-.25-.25h-.75a.5.5 0 01-.462-.308z\"/><rect x=\"7\" y=\"13.192\" width=\"3\" height=\"3.308\" rx=\"1\" ry=\"1\"/><path d=\"M6.5 10.692h4a1 1 0 010 2h-4a1 1 0 010-2z\"/><path d=\"M.293 12.707l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414zm2.3-.884l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354l-9.232 9.232a.25.25 0 01-.354 0l-9.234-9.232a.25.25 0 010-.354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});