define("ember-svg-jar/inlined/pets-paw-ribbon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pets-paw-ribbon</title><path d=\"M3.339 15.669a.251.251 0 00-.415.027L.4 20.063a.73.73 0 00-.009.816.726.726 0 00.769.276l2.945-.5 1.023 2.808a.706.706 0 001.326.124l2.261-3.8a.251.251 0 00-.136-.365 11.041 11.041 0 01-5.24-3.753zM23.6 20.063l-2.459-4.244a.253.253 0 00-.2-.124.249.249 0 00-.214.095 11.043 11.043 0 01-5.284 3.684.249.249 0 00-.139.366l2.242 3.749A.743.743 0 0018.2 24a.758.758 0 00.677-.535l1.023-2.808 2.946.5a.72.72 0 00.769-.276.729.729 0 00-.015-.818zM21.081 9a9 9 0 10-9 9 9 9 0 009-9zm-7.769-4.117a1.247 1.247 0 11-1.247-1.246 1.249 1.249 0 011.247 1.246zM8.327 6.129a1.246 1.246 0 11-1.246 1.246 1.247 1.247 0 011.246-1.246zm.867 6.171a1.247 1.247 0 01.1-1.275l1.822-2.486a1.192 1.192 0 011.924 0l1.824 2.5a1.228 1.228 0 01-.961 1.943h-3.646a1.22 1.22 0 01-1.063-.682zm6.641-3.679a1.246 1.246 0 111.246-1.246 1.248 1.248 0 01-1.246 1.246z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});