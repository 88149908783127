define("ember-svg-jar/inlined/gesture-swipe-vertical-up-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-vertical-up-2</title><path d=\"M11.808 3.7a.751.751 0 001.176.729L13.593 4a.252.252 0 01.374.1 15.478 15.478 0 01.942 2.865 1 1 0 001.95-.443 16.915 16.915 0 00-1.273-3.684.25.25 0 01.08-.313l.74-.525a.75.75 0 00-.3-1.35L13.147.132a.752.752 0 00-.87.622zM16.106 16.568a1 1 0 00-1.2.754 8.994 8.994 0 01-2.536 4.823 1 1 0 001.367 1.46 10.88 10.88 0 003.119-5.841 1 1 0 00-.75-1.196zM18.51 11.615a1 1 0 00-1-1h-7.064a.25.25 0 01-.143-.455c2.138-1.49 1.45-3.856-.222-2.762L5.712 10.31a.5.5 0 00-.222.416v5.437a.5.5 0 00.411.491c4.412.8 3.586.643 4.165.776a1.284 1.284 0 001.52-.895l1.114-3.742a.25.25 0 01.24-.178h4.57a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});