define("ember-svg-jar/inlined/brain-chip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>brain-chip</title><path d=\"M24 12a3.678 3.678 0 00-1.652-2.919.5.5 0 01-.17-.622A3.456 3.456 0 0022.5 7a3.5 3.5 0 00-3.117-3.479.5.5 0 01-.438-.412A3.755 3.755 0 0015.25 0a3.711 3.711 0 00-2.309.807.5.5 0 00-.191.393v1.268a.75.75 0 01-1.5 0V1.2a.5.5 0 00-.191-.393A3.711 3.711 0 008.75 0a3.755 3.755 0 00-3.695 3.11.5.5 0 01-.438.411A3.5 3.5 0 001.5 7a3.452 3.452 0 00.322 1.459.5.5 0 01-.171.622A3.676 3.676 0 000 12a3.67 3.67 0 001.651 2.917.5.5 0 01.171.623 3.5 3.5 0 002.79 4.944.5.5 0 01.444.412A3.756 3.756 0 008.75 24a3.717 3.717 0 002.309-.807.5.5 0 00.191-.393v-1.332a.75.75 0 011.5 0V22.8a.5.5 0 00.191.393A3.717 3.717 0 0015.25 24a3.756 3.756 0 003.7-3.11.5.5 0 01.438-.412A3.5 3.5 0 0022.5 17a3.457 3.457 0 00-.322-1.46.5.5 0 01.171-.623A3.67 3.67 0 0024 12zM7.25 14.25h-1a.75.75 0 010-1.5h1a.75.75 0 010 1.5zM8 10.5a.75.75 0 01-.75.75h-1a.75.75 0 010-1.5h1a.75.75 0 01.75.75zm4.75-4.25a.75.75 0 011.5 0v1a.75.75 0 01-1.5 0zm-3 0a.75.75 0 011.5 0v1a.75.75 0 01-1.5 0zm1.5 11.5a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zm3 0a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zM15 14a1 1 0 01-1 1h-4a1 1 0 01-1-1v-4a1 1 0 011-1h4a1 1 0 011 1zm2.75.25h-1a.75.75 0 010-1.5h1a.75.75 0 010 1.5zm0-3h-1a.75.75 0 010-1.5h1a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});