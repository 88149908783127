define("ember-svg-jar/inlined/folder-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-heart</title><path d=\"M10.03 13.779a5.025 5.025 0 017.337-1.9.232.232 0 00.266 0 5.024 5.024 0 013.1-.853.245.245 0 00.267-.241V4.5A1.5 1.5 0 0019.5 3H9.618a.5.5 0 01-.447-.276L8.223.83A1.5 1.5 0 006.88 0H1.5A1.5 1.5 0 000 1.5v15A1.5 1.5 0 001.5 18h8.055a.247.247 0 00.229-.334 5.012 5.012 0 01.246-3.887z\"/><path d=\"M22.967 18.519a3.527 3.527 0 00-2.386-6.019 3.667 3.667 0 00-2.694 1.127l-.211.211a.249.249 0 01-.353 0l-.211-.211a3.665 3.665 0 00-2.693-1.127 3.528 3.528 0 00-2.394 6.012l5.113 5.334a.5.5 0 00.723 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});