define("ember-svg-jar/inlined/cap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cap</title><path d=\"M8.747 11.312v3.317a.5.5 0 00.547.5 28.829 28.829 0 015.409 0 .5.5 0 00.547-.5v-3.315c0-3.7-.7-6.392-1.994-7.834A1 1 0 0113 2.811V1.75a1 1 0 00-2 0v1.058a1 1 0 01-.257.669c-1.299 1.442-1.996 4.134-1.996 7.835zM16.75 14.977a.5.5 0 00.41.491 28.7 28.7 0 014.177 1.1.5.5 0 00.663-.475V12.75a10.016 10.016 0 00-6.439-9.344.25.25 0 00-.308.353 16.037 16.037 0 011.5 7.552zM21.9 23.124a1.5 1.5 0 002.03-1.824c-.034-.105.03.029-1.515-2.505a1 1 0 00-.488-.41 27.149 27.149 0 00-19.854 0 1 1 0 00-.488.41C.04 21.324.1 21.19.07 21.3a1.5 1.5 0 002.03 1.824A24.531 24.531 0 0112 21.25a24.531 24.531 0 019.9 1.874zM7.247 11.314a16.046 16.046 0 011.5-7.554.25.25 0 00-.309-.353A10.016 10.016 0 002 12.75v3.342a.5.5 0 00.663.473 28.5 28.5 0 014.174-1.1.5.5 0 00.41-.491z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});