define("ember-svg-jar/inlined/landmark-pyramid.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-pyramid</title><path d=\"M8.926 9H6a.5.5 0 00-.463.311L.133 22.5A1 1 0 001 24h6.073a.5.5 0 00.495-.434l1.853-14A.5.5 0 008.926 9zM23.886 22.538L18.462 9.311A.5.5 0 0018 9h-2.925a.5.5 0 00-.5.566l1.854 14a.5.5 0 00.5.434H23a1 1 0 00.886-1.462zM6 4a.5.5 0 01.5.5V7a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V5.5A.5.5 0 0111 5h2a.5.5 0 01.5.5V7a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V4.5A.5.5 0 0118 4a1 1 0 001-1v-.5a.5.5 0 00-.5-.5h-1a.5.5 0 01-.5-.5V1a1 1 0 00-1-1H8a1 1 0 00-1 1v.5a.5.5 0 01-.5.5h-1a.5.5 0 00-.5.5V3a1 1 0 001 1zM12.75 11a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5zM14 22.5h-4a.75.75 0 000 1.5h4a.75.75 0 000-1.5zM9.563 20a.75.75 0 00.75.75h3.375a.75.75 0 100-1.5h-3.375a.75.75 0 00-.75.75zM9.875 16.75a.75.75 0 00.75.75h2.75a.75.75 0 000-1.5h-2.75a.75.75 0 00-.75.75zM10.188 13.5a.75.75 0 00.75.75h2.125a.75.75 0 100-1.5h-2.125a.75.75 0 00-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});