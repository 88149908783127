define("ember-svg-jar/inlined/famous-character-batman-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character-batman-1</title><path d=\"M17.13 4.426a.749.749 0 00-.967.89l.382 1.538a2.861 2.861 0 01-.67 2.531.5.5 0 01-.875-.332V5.614a.749.749 0 00-1.2-.6l-1.468 1.1h-.666l-1.466-1.1a.751.751 0 00-1.2.6v3.438a.5.5 0 01-.875.331 2.861 2.861 0 01-.669-2.529l.381-1.534a.75.75 0 00-.964-.9C2.7 5.815 0 8.833 0 12.114a7.446 7.446 0 003.483 5.994.75.75 0 001.088-.982c-.056-.1-.109-.2-.158-.3-.568-1.227-.4-2.349.1-2.585s1.436.349 2.012 1.533a.5.5 0 00.578.265.5.5 0 00.371-.516c-.045-.691.167-1.267.51-1.371.565-.171.906.348 2.272 2.849.367.671.8 1.457 1.313 2.363a.5.5 0 00.87 0c.509-.892.934-1.67 1.3-2.338 1.356-2.484 1.722-3.05 2.293-2.872.338.1.55.678.5 1.369a.5.5 0 00.371.516.494.494 0 00.578-.265c.575-1.181 1.523-1.754 2-1.537.5.24.671 1.362.1 2.587-.049.106-.1.206-.157.3a.75.75 0 001.087.982A7.444 7.444 0 0024 12.114c0-3.281-2.7-6.3-6.87-7.688z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});