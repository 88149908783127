define("ember-svg-jar/inlined/style-three-pin-paragliding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-paragliding</title><path d=\"M12 0C6.21 0 1.5 4.26 1.5 9.5c0 5.75 7.3 12.29 9.54 14.15a1.492 1.492 0 001.92 0c2.24-1.87 9.54-8.4 9.54-14.15C22.5 4.26 17.79 0 12 0zm.546 17.808A.249.249 0 0112.3 18h-.6a.249.249 0 01-.243-.192c-.05-.208-.135-.569-.234-1a.25.25 0 01.244-.306h1.072a.25.25 0 01.244.306c-.102.432-.183.792-.237 1zm7.354-7a1 1 0 01-.73 1.69H17a.5.5 0 00-.5.5v1.25a1.25 1.25 0 01-1.25 1.25h-6.5A1.25 1.25 0 017.5 14.25V13a.5.5 0 00-.5-.5H4.83a1 1 0 01-.73-1.69l7.18-7.55a.991.991 0 011.44 0z\"/><path d=\"M14 10.761a.5.5 0 01-.052-.688A2.471 2.471 0 0014.5 8.5a2.5 2.5 0 00-5 0 2.471 2.471 0 00.556 1.573.5.5 0 01-.052.688 2.974 2.974 0 00-.952 1.726.016.016 0 01-.015.013H9a.5.5 0 00-.5.5v1.25a.248.248 0 00.25.25h1.369a.5.5 0 00.494-.584A6.592 6.592 0 0110.5 13a1.5 1.5 0 013 0 6.592 6.592 0 01-.113.916.5.5 0 00.494.584h1.369a.248.248 0 00.25-.25V13a.5.5 0 00-.5-.5h-.05a2.977 2.977 0 00-.95-1.739z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});