define("ember-svg-jar/inlined/e-commerce-apparel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-apparel</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v16A1.5 1.5 0 001.5 19h21a1.5 1.5 0 001.5-1.5v-16A1.5 1.5 0 0022.5 0zM22 14.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5zM14.35 20.681a.5.5 0 00-.385-.181h-3.93a.5.5 0 00-.385.181l-2.1 2.532a.5.5 0 00.41.787h8.079a.5.5 0 00.41-.787z\"/><path d=\"M17.175 5.136A1.5 1.5 0 0015.719 4H13.5a.5.5 0 00-.5.5 1 1 0 01-2 0 .5.5 0 00-.5-.5H8.281a1.5 1.5 0 00-1.456 1.136l-.81 3.243A.5.5 0 006.5 9h1.25a.25.25 0 01.25.25v3.25a.5.5 0 00.5.5h7a.5.5 0 00.5-.5V9.25a.25.25 0 01.25-.25h1.25a.5.5 0 00.485-.621z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});