define("ember-svg-jar/inlined/railroad-train-back", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>railroad-train-back</title><path d=\"M23 22.387h-2.222a.25.25 0 01-.25-.25v-.809c0-.346.5-.342.5-.342a1.5 1.5 0 001.5-1.5l-.028-16a1.5 1.5 0 00-.875-1.364l-.016.033a22.817 22.817 0 00-19.234-.033A1.5 1.5 0 001.5 3.487l.028 16a1.5 1.5 0 001.5 1.5s.5 0 .5.327v.824a.25.25 0 01-.25.25H1a.8.8 0 100 1.6h22a.8.8 0 100-1.6zM8.528 5.986a1 1 0 011-1h5a1 1 0 011 1v12a1 1 0 01-1 1h-5a1 1 0 01-1-1zm-5 2.988V5.5a.513.513 0 01.512-.513h2.476a.513.513 0 01.512.513v3.474a.512.512 0 01-.512.512H4.04a.512.512 0 01-.512-.512zm2 8.512a1 1 0 111-1 1 1 0 01-1 1zm0-3.5a1 1 0 111-1 1 1 0 01-1 1zm12 8.151a.249.249 0 01-.25.25h-10.5a.25.25 0 01-.25-.25v-.9a.25.25 0 01.25-.25h10.5a.249.249 0 01.25.25zm1-4.651a1 1 0 111-1 1 1 0 01-1 1zm0-3.5a1 1 0 111-1 1 1 0 01-1 1zm2-5.012a.512.512 0 01-.512.512H17.54a.512.512 0 01-.512-.512V5.5a.513.513 0 01.512-.513h2.476a.513.513 0 01.512.513z\"/><rect x=\"10.028\" y=\"6.486\" width=\"4\" height=\"4\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});