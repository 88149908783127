define("ember-svg-jar/inlined/vr-user-box-human", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vr-user-box-human</title><path d=\"M13.447 3.105l-6-3a1 1 0 00-.894 0l-6 3A1.058 1.058 0 000 4v6.75a1 1 0 00.553.894l6 3a1 1 0 00.894 0l6-3A1 1 0 0014 10.75V4a1.058 1.058 0 00-.553-.895zm-3.131.671a.25.25 0 010 .448l-3.2 1.6a.252.252 0 01-.224 0l-3.2-1.6a.25.25 0 010-.448l3.2-1.6a.252.252 0 01.224 0zM2 6.023a.25.25 0 01.119-.213.248.248 0 01.243-.01l3.5 1.75A.251.251 0 016 7.773v3.954a.25.25 0 01-.362.224l-3.5-1.75A.251.251 0 012 9.977zm6.362 5.928A.25.25 0 018 11.727V7.773a.251.251 0 01.138-.224l3.5-1.75a.248.248 0 01.243.011.25.25 0 01.119.213v3.954a.251.251 0 01-.138.224zM23.522 5.011a7.473 7.473 0 00-4.341.973.5.5 0 00.055.88l4.057 1.842A.5.5 0 0024 8.25V5.51a.5.5 0 00-.478-.499zM23.438 14.5l-8 1a.5.5 0 00-.41.329s-.544 1.538-.7 2.009a.5.5 0 00.475.658h1.613a.25.25 0 01.25.25v.564a2.6 2.6 0 002.593 2.593h.026a.25.25 0 01.25.25V23.5a.5.5 0 00.5.5H23.5a.5.5 0 00.5-.5V15a.5.5 0 00-.169-.375.49.49 0 00-.393-.125zM15.252 14l7.338-.443A1.5 1.5 0 0024 12.059V11.2a1.506 1.506 0 00-.978-1.406L17.414 7.7a1.481 1.481 0 00-1.6.423 5.387 5.387 0 00-1.083 2.252 5.061 5.061 0 00.038 3.345.5.5 0 00.483.28zm2.023-4.747l1.627.522a.5.5 0 01.147.877l-2.242 1.668a.5.5 0 01-.8-.429 5.728 5.728 0 01.693-2.422.5.5 0 01.575-.217z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});