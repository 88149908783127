define("ember-svg-jar/inlined/phone-actions-off-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-off-1</title><path d=\"M23.707 1.709A1 1 0 0022.293.3L7.532 15.056a11.352 11.352 0 01-1.649-2.739 4.253 4.253 0 004.5-1.345l.83-.829a3.117 3.117 0 000-4.4L7.631 2.158A2.233 2.233 0 003.98 2.9a13.3 13.3 0 002.134 13.574L.293 22.3a1 1 0 000 1.414 1.009 1.009 0 001.414 0l5.805-5.8c-.033.051 1.679 1.227 1.867 1.346a13.264 13.264 0 0011.957 1 2.234 2.234 0 00.739-3.649l-3.585-3.594a3.116 3.116 0 00-4.4 0l-.827.828a4.248 4.248 0 00-1.346 4.5 11.3 11.3 0 01-2.983-1.864z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});