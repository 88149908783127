define("ember-svg-jar/inlined/phone-action-edit-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-edit-1</title><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/><path d=\"M13.233 7.56l-5.087 5.086a.5.5 0 00-.136.256l-.5 2.5a.5.5 0 00.136.452.529.529 0 00.452.136l2.5-.5a.5.5 0 00.256-.136l5.086-5.087a1.914 1.914 0 00-2.707-2.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});