define("ember-svg-jar/inlined/arrow-dot-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-dot-left</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-3 11.5A1.5 1.5 0 1121 10a1.5 1.5 0 01-1.5 1.5zM21 14a1.5 1.5 0 11-1.5-1.5A1.5 1.5 0 0121 14zm-5.5-2.5A1.5 1.5 0 1117 10a1.5 1.5 0 01-1.5 1.5zM17 14a1.5 1.5 0 11-1.5-1.5A1.5 1.5 0 0117 14zm-5.5 1.5A1.5 1.5 0 1113 14a1.5 1.5 0 01-1.5 1.5zM13 18a1.5 1.5 0 11-1.5-1.5A1.5 1.5 0 0113 18zm-1.5-6.5A1.5 1.5 0 1113 10a1.5 1.5 0 01-1.5 1.5zm0-4A1.5 1.5 0 1113 6a1.5 1.5 0 01-1.5 1.5zm-4 4A1.5 1.5 0 119 10a1.5 1.5 0 01-1.5 1.5zM9 14a1.5 1.5 0 11-1.5-1.5A1.5 1.5 0 019 14zm-4-2a1.5 1.5 0 11-1.5-1.5A1.5 1.5 0 015 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});