define("ember-svg-jar/inlined/smiley-explosion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-explosion</title><path d=\"M21.827 4.471a.25.25 0 01-.162-.171 3.947 3.947 0 00-5.21-2.6.25.25 0 01-.261-.05 6.163 6.163 0 00-8.388 0 .25.25 0 01-.261.05 3.947 3.947 0 00-5.21 2.6.25.25 0 01-.162.171A3.146 3.146 0 003.2 10.608h2.582a1 1 0 000-2H3.2a1.152 1.152 0 110-2.3 1 1 0 001-1 1.9 1.9 0 011.934-1.87 1.943 1.943 0 011.169.4 1 1 0 001.391-.2 4.16 4.16 0 016.612 0 1 1 0 001.391.2 1.943 1.943 0 011.169-.395A1.9 1.9 0 0119.8 5.305a1 1 0 001 1 1.152 1.152 0 110 2.3h-2.582a1 1 0 000 2H20.8a3.146 3.146 0 001.027-6.137z\"/><path d=\"M18.346 11.479a2 2 0 01-2-2V7.7a1 1 0 10-2 0v1.78a3.975 3.975 0 00.832 2.439.25.25 0 01-.176.4c-.77.068-1.753.114-3 .114s-2.234-.046-3-.114a.25.25 0 01-.176-.4 3.975 3.975 0 00.826-2.439V7.7a1 1 0 00-2 0v1.78a2 2 0 01-2 2 1 1 0 00-.905.574 8.174 8.174 0 1014.5 0 1 1 0 00-.901-.575zM9.75 16a1.25 1.25 0 11-1.25-1.25A1.25 1.25 0 019.75 16zM11 19a1 1 0 012 0v1.5a1 1 0 01-2 0zm3.25-3a1.25 1.25 0 111.25 1.25A1.25 1.25 0 0114.25 16z\"/><path d=\"M13 10.565V8.652a1 1 0 00-2 0v1.913a1 1 0 002 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});