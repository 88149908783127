define("ember-svg-jar/inlined/real-estate-market-house-decrease", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-market-house-decrease</title><path d=\"M23.836 15.7l-5.164-4.695a1 1 0 00-1.345 0L12.163 15.7a.5.5 0 00.337.87h1a.5.5 0 01.5.5v2.68a1 1 0 001 1h6a1 1 0 001-1v-2.676a.5.5 0 01.5-.5h1a.5.5 0 00.336-.874zM6.5 20.75h3a.5.5 0 00.5-.5V6.75a1 1 0 00-1-1H7a1 1 0 00-1 1v13.5a.5.5 0 00.5.5zM.5 20.75h3a.5.5 0 00.5-.5V1.75a1 1 0 00-1-1H1a1 1 0 00-1 1v18.5a.5.5 0 00.5.5zM21.5 22H1a1 1 0 000 2h20.5a1 1 0 000-2zM14.357 6.625a.5.5 0 010 .707L14.1 7.6a.75.75 0 00.53 1.28h3a.75.75 0 00.75-.75v-3a.75.75 0 00-1.28-.53l-.263.262a.5.5 0 01-.707 0L11.634.366a1.25 1.25 0 00-1.768 1.768z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});