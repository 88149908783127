define("ember-svg-jar/inlined/design-file-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-text</title><path d=\"M21.414 4.914L17.086.586A2 2 0 0015.672 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.329a2 2 0 00-.586-1.415zM16.75 13a.75.75 0 01-1.5 0v-1a.25.25 0 00-.25-.25h-2a.25.25 0 00-.25.25v6.5a.25.25 0 00.25.25h1a.75.75 0 010 1.5h-4a.75.75 0 010-1.5h1a.25.25 0 00.25-.25V12a.25.25 0 00-.25-.25H9a.25.25 0 00-.25.25v1a.75.75 0 01-1.5 0v-1.5a1.25 1.25 0 011.25-1.25h7a1.25 1.25 0 011.25 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});