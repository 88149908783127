define("ember-svg-jar/inlined/messages-bubble-square-subtract-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-subtract-alternate</title><path d=\"M8.5 14a1 1 0 00-.665.253L5.416 16.4A.25.25 0 015 16.216V15a1 1 0 00-1-1H2.25a.251.251 0 01-.25-.25V2.25A.25.25 0 012.25 2h18a.25.25 0 01.25.25v7.672a.251.251 0 00.154.231 8 8 0 011.452.815.25.25 0 00.394-.205V2a2 2 0 00-2-2H2a2 2 0 00-2 2v12.053A1.953 1.953 0 002 16h1v3a1 1 0 001.665.748L8.88 16h.562a.249.249 0 00.244-.2 7.889 7.889 0 01.47-1.454.25.25 0 00-.229-.346z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zM15 16.75h5a.75.75 0 010 1.5h-5a.75.75 0 010-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});