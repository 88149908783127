define("ember-svg-jar/inlined/download-bottom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>download-bottom</title><path d=\"M22.667 16.938a1.212 1.212 0 00-1.212 1.212v1.855a1.572 1.572 0 01-1.571 1.57H4.116a1.572 1.572 0 01-1.571-1.57V18.15a1.212 1.212 0 00-2.424 0v1.855a4 4 0 003.995 4h15.768a4 4 0 004-4V18.15a1.212 1.212 0 00-1.217-1.212z\"/><path d=\"M12 0a1.939 1.939 0 00-1.939 1.939v9.066a.25.25 0 01-.25.25H7.152a.97.97 0 00-.732 1.606l4.848 5.576a.969.969 0 001.464 0l4.848-5.576a.97.97 0 00-.732-1.606h-2.659a.25.25 0 01-.25-.25V1.939A1.939 1.939 0 0012 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});