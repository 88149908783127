define("ember-svg-jar/inlined/image-file-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-clock</title><path d=\"M6.68 10.639a6.681 6.681 0 106.681 6.681 6.688 6.688 0 00-6.681-6.681zm0 11.382a4.7 4.7 0 114.7-4.7 4.707 4.707 0 01-4.7 4.7z\"/><path d=\"M8.66 16.33h-.81a.174.174 0 01-.18-.2v-1.285a.99.99 0 00-1.979 0v2.475a.989.989 0 00.989.989h1.98a.99.99 0 000-1.979zM13.3 4a1.478 1.478 0 101.477 1.478A1.478 1.478 0 0013.3 4z\"/><path d=\"M23.421 2.97L21.03.579A1.98 1.98 0 0019.631 0H8.165a1.979 1.979 0 00-1.979 1.979v7.2a.245.245 0 00.239.247c.362.006 1.027.033 1.469.082a.244.244 0 00.271-.246V2.474a.5.5 0 01.5-.5h10.762a.5.5 0 01.349.145l2.1 2.1a.5.5 0 01.145.349v13.246a.5.5 0 01-.5.5h-6.783a.241.241 0 00-.238.208 7.875 7.875 0 01-.361 1.443.242.242 0 00.227.328h7.655A1.98 1.98 0 0024 18.309V4.369a1.98 1.98 0 00-.579-1.399z\"/><path d=\"M13.935 9.3a.37.37 0 00-.626 0l-1.232 1.973a.241.241 0 00.041.305 7.965 7.965 0 011.033 1.182.236.236 0 00.194.1h7.006a.185.185 0 00.157-.283l-3.2-5.125a.369.369 0 00-.627 0l-1.791 2.867a.184.184 0 01-.313 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});