define("ember-svg-jar/inlined/style-three-pin-monument", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-monument</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm-1.093 4.68a.5.5 0 00.343-.475V3.25a.75.75 0 011.5 0v.955a.5.5 0 00.343.475A3.5 3.5 0 0115.5 8v.5a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5V8a3.5 3.5 0 012.407-3.32zM17 15.5H7a.5.5 0 01-.5-.5 7.4 7.4 0 011.272-4.283.5.5 0 01.413-.217h7.63a.5.5 0 01.413.217A7.4 7.4 0 0117.5 15a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});