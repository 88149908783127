define("ember-svg-jar/inlined/user-outline-o", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>user-outline-o</title><g fill=\"#29344C\" fill-rule=\"nonzero\"><path d=\"M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm0-1c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C7.716 1 1 7.716 1 16c0 8.284 6.716 15 15 15z\"/><path d=\"M25.323 28.422c-.224-.312-.732-.492-2.64-1.069-1.051-.318-2.81-.85-3.084-1.127-.297-.303-.526-1.792-.573-3.09.924-.87 1.783-2.123 2.512-3.714.419-.917.67-3.077.67-5.778 0-3.637-2.559-6.177-6.222-6.177-3.606 0-6.222 2.644-6.222 6.289 0 2.597.249 4.717.667 5.672.692 1.584 1.552 2.848 2.495 3.724-.13 1.285-.448 2.77-.784 3.104-.278.278-1.887.775-2.848 1.072-1.986.613-2.829.614-2.966 1.094-.424-.34 2.814 1.253 3.767 1.634.83.332.742.952 5.89.952 5.15 0 6.578-.447 7.083-.952.48-.48 2.572-1.193 2.255-1.634zM10.83 13.756c0-3.075 2.12-5.223 5.156-5.223 2.566 0 5.155 1.58 5.155 5.111 0 3.012-.31 4.756-.573 5.334-1.288 2.81-3.002 4.489-4.582 4.489-1.62 0-3.374-1.712-4.578-4.466-.266-.61-.578-2.349-.578-5.245zm12.39 16.142c-.495.248-1.848 1.17-7.434 1.17-5.21 0-5.94-.878-6.759-1.17-.071-.413-1.526-.383-1.438-.855.322-.171 1.293-.471 2.02-.696 1.756-.542 2.854-.902 3.287-1.337.581-.58.877-2.1 1.016-3.12.674.41 1.373.643 2.074.643.68 0 1.357-.225 2.014-.621.08 1.055.288 2.503.839 3.062.433.44 1.624.822 3.534 1.4.748.226 1.75.53 2.07.692.086.408-1.123.441-1.224.832z\"/></g>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});