define("ember-svg-jar/inlined/tag-free", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tag-free</title><path d=\"M8.85 11.024a.226.226 0 00.225.226.875.875 0 000-1.75.226.226 0 00-.225.226z\"/><path d=\"M21.5 4h-19A2.5 2.5 0 000 6.5v11A2.5 2.5 0 002.5 20h19a2.5 2.5 0 002.5-2.5v-11A2.5 2.5 0 0021.5 4zm-.4 7.9a.75.75 0 010 1.5h-1.6a.25.25 0 00-.25.25v.3a.551.551 0 00.55.55h1.3a.75.75 0 010 1.5h-1.3a2.053 2.053 0 01-2.05-2.05v-3.9A2.053 2.053 0 0119.8 8h1.3a.75.75 0 010 1.5h-1.3a.551.551 0 00-.55.55v1.6a.25.25 0 00.25.25zm-5.2 0a.75.75 0 110 1.5h-1.6a.25.25 0 00-.25.25v.3a.551.551 0 00.55.55h1.3a.75.75 0 110 1.5h-1.3a2.053 2.053 0 01-2.05-2.05v-3.9A2.053 2.053 0 0114.6 8h1.3a.75.75 0 110 1.5h-1.3a.551.551 0 00-.55.55v1.6a.25.25 0 00.25.25zm-4.556 2.964a.751.751 0 01-.258 1.029.75.75 0 01-1.029-.257L9.314 14.4a.25.25 0 00-.464.129v.723a.75.75 0 01-1.5 0V8.75A.75.75 0 018.1 8h.975a2.374 2.374 0 011.132 4.462.249.249 0 00-.1.348zM4.85 11.9a.75.75 0 010 1.5H3.9a.25.25 0 00-.25.25v1.6a.75.75 0 01-1.5 0v-5.2A2.053 2.053 0 014.2 8h1.3a.75.75 0 010 1.5H4.2a.551.551 0 00-.55.55v1.6a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});