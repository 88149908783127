define("ember-svg-jar/inlined/medical-nanobots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-nanobots</title><path d=\"M23.621 18.721l-1.576-1.27a.5.5 0 01-.045-.745l.176-.176a1.1 1.1 0 000-1.56l-3.15-3.15a1.1 1.1 0 00-1.56 0l-3.15 3.15a1.1 1.1 0 000 1.56l.176.176a.5.5 0 01-.039.743l-1.576 1.27A1 1 0 0012.628 20v.007l2 3.493.006.01a1 1 0 001.738-1L15 20.122a.5.5 0 01.122-.64l.845-.673a.5.5 0 01.665.037l.468.468a.5.5 0 01.146.353V23a1 1 0 002 0v-3.333a.5.5 0 01.146-.353l.468-.468a.5.5 0 01.665-.037l.845.673a.5.5 0 01.122.64L20.131 22.5a1 1 0 001.739 1l.006-.01 2-3.493v-.007a1 1 0 00-.255-1.269zM18.25 17.5A1.75 1.75 0 1120 15.75a1.746 1.746 0 01-1.75 1.75zM11.121 18.721l-1.576-1.27a.5.5 0 01-.039-.743l.176-.176a1.1 1.1 0 000-1.56L6.53 11.82a1.1 1.1 0 00-1.56 0l-3.15 3.15a1.1 1.1 0 000 1.56l.18.176a.5.5 0 01-.039.743l-1.58 1.27A1 1 0 00.128 20v.007l2 3.493.006.01a1 1 0 001.739-1L2.5 20.122a.5.5 0 01.122-.64l.845-.673a.5.5 0 01.665.037l.468.468a.5.5 0 01.146.353V23a1 1 0 002 0v-3.333a.5.5 0 01.146-.353l.468-.468a.5.5 0 01.665-.037l.845.673a.5.5 0 01.122.64L7.631 22.5a1 1 0 001.74 1v-.008l2-3.493v-.007a1 1 0 00-.25-1.271zM5.75 17.5a1.75 1.75 0 111.75-1.75 1.746 1.746 0 01-1.75 1.75zM17.125 7.219l-1.583-1.266a.5.5 0 01-.041-.745l.175-.174a1.109 1.109 0 000-1.567L12.533.325a1.106 1.106 0 00-1.566 0L7.824 3.467a1.109 1.109 0 000 1.567L8 5.208a.5.5 0 01-.041.745L6.375 7.219A1 1 0 006.132 8.5l2 3.5a1 1 0 00.868.5.988.988 0 00.5-.132A1 1 0 009.868 11L8.505 8.617a.5.5 0 01.122-.638l.841-.673a.5.5 0 01.666.037l.47.47a.5.5 0 01.146.353V11.5a1 1 0 002 0V8.166a.5.5 0 01.146-.353l.47-.47a.5.5 0 01.666-.037l.841.673a.5.5 0 01.127.638L13.632 11A1 1 0 0014 12.368a.988.988 0 00.5.132 1 1 0 00.869-.5l2-3.5a1 1 0 00-.244-1.281zM11.75 6a1.75 1.75 0 111.75-1.75A1.749 1.749 0 0111.75 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});