define("ember-svg-jar/inlined/room-service-give-plate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>room-service-give-plate</title><path d=\"M18 20.5H9a.5.5 0 010-1h2.5a.5.5 0 00.5-.5 1 1 0 00-1-1H5.5a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h14a.5.5 0 00.5-.5 2 2 0 00-2-2zM3.243 11h17.514a.5.5 0 00.488-.612 9.51 9.51 0 00-6.994-7.108.5.5 0 01-.356-.647A1.95 1.95 0 0014 2a2 2 0 00-4 0 1.95 1.95 0 00.105.633.5.5 0 01-.356.647 9.51 9.51 0 00-6.994 7.108.5.5 0 00.488.612zm3.781-4.1a8.278 8.278 0 013.482-1.685.75.75 0 01.317 1.467 6.75 6.75 0 00-2.847 1.38.75.75 0 11-.952-1.162zM23.25 12H.75a.75.75 0 000 1.5h.836a.5.5 0 01.353.146L3 14.707a1 1 0 00.707.293h16.586a1 1 0 00.707-.293l1.061-1.061a.5.5 0 01.354-.146h.835a.75.75 0 000-1.5zM3 17H1a1 1 0 00-1 1v5a1 1 0 001 1h2a1 1 0 001-1v-5a1 1 0 00-1-1zm-1.25 5.5a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});