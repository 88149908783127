define("ember-svg-jar/inlined/house-nature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>house-nature</title><path d=\"M3.5 7H8a2.5 2.5 0 000-5 2.311 2.311 0 00-.924.176A.3.3 0 016.665 2 3.5 3.5 0 103.5 7zM23 22h-1.75a.25.25 0 01-.25-.25v-1.2a.243.243 0 01.165-.228A4.03 4.03 0 0024 16.625c0-1.689-2.666-8.026-3.813-10.677a.78.78 0 00-1.375 0C17.666 8.6 15 14.936 15 16.625a4.091 4.091 0 003.185 3.818.255.255 0 00.315-.243v-1.764a.249.249 0 00-.073-.177l-1.281-1.28a.5.5 0 01.708-.708s.4.411.473.474.173 0 .173-.13v-3.553a.5.5 0 111 0v8.688a.25.25 0 01-.25.25h-6a.25.25 0 01-.25-.25v-4.244c0-.141.146-.027.146-.027a.5.5 0 00.708-.708l-1-1-5.147-5.146a1 1 0 00-1.414 0l-5.146 5.146-1 1a.5.5 0 00.708.708S1 17.305 1 17.5v4.279a.263.263 0 01-.222.248A1 1 0 001 24h22a1 1 0 000-2zm-14.5-.25a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-2.625a1 1 0 011-1h1a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});