define("ember-svg-jar/inlined/taxi-station", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>taxi-station</title><path d=\"M24 20.875a1 1 0 00-1-1h-.25a.25.25 0 01-.25-.25V17.85a.25.25 0 01.125-.216 1.746 1.746 0 00.875-1.509v-.75a1.945 1.945 0 00-.573-1.384l-.815-.814a.18.18 0 01-.051-.125.176.176 0 01.176-.177h.513a1 1 0 000-2h-1a.842.842 0 00-.3.057.25.25 0 01-.2-.009.254.254 0 01-.129-.149L20.552 9a2.514 2.514 0 00-2.342-1.625h-5.92a2.525 2.525 0 00-2.357 1.667l-.558 1.732a.25.25 0 01-.129.149.254.254 0 01-.2.009.841.841 0 00-.3-.057h-1a1 1 0 000 2h.513a.177.177 0 01.125.3l-.816.815A1.944 1.944 0 007 15.375v.75a1.746 1.746 0 00.875 1.509.25.25 0 01.125.216v1.775a.25.25 0 01-.25.25h-2a.251.251 0 01-.231-.154 3.013 3.013 0 00-1.619-1.61.251.251 0 01-.154-.231v-7a.25.25 0 01.25-.25h.25A1.252 1.252 0 005.5 9.375v-6a1.252 1.252 0 00-1.25-1.25h-3A1.252 1.252 0 000 3.375v6a1.252 1.252 0 001.25 1.25h.25a.25.25 0 01.25.25v6.75a.25.25 0 01-.25.25H1a1 1 0 000 2h1.75a1 1 0 011 1 1 1 0 001 1H23a1 1 0 001-1zM11.821 9.7a.5.5 0 01.469-.325h5.92a.5.5 0 01.453.28l.61 1.893a.252.252 0 01-.036.224.249.249 0 01-.2.1h-7.57a.249.249 0 01-.2-.1.251.251 0 01-.036-.224zm-.071 10.175a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h7a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});