define("ember-svg-jar/inlined/instrument-piano", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>instrument-piano</title><path d=\"M22.5 12.313a.5.5 0 00-.5-.5h-6a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h2.032a.25.25 0 00.225-.358l-2.786-5.818a.751.751 0 011.354-.648l3.2 6.682a.25.25 0 00.226.142H22a.5.5 0 00.437-.743l-2.381-4.285a.5.5 0 01.181-.678 1.5 1.5 0 00.505-2.06L19.446.909A1.5 1.5 0 0017.385.4L1.741 9.885a.5.5 0 00.259.928h6a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H2a.5.5 0 00-.5.5v3a1.5 1.5 0 00.857 1.355.25.25 0 01.143.226v5.919a1 1 0 002 0v-5.75a.25.25 0 01.25-.25h14.5a.25.25 0 01.25.25v5.75a1 1 0 102 0v-5.919a.249.249 0 01.142-.226 1.5 1.5 0 00.858-1.355zm-12.75-3.5a.25.25 0 01.25-.25h4a.25.25 0 01.25.25v4.25a.25.25 0 01-.25.25h-4a.25.25 0 01-.25-.25z\"/><path d=\"M14 17.813h-4a1.5 1.5 0 000 3h1a.25.25 0 01.25.25v1a.25.25 0 01-.25.25.75.75 0 000 1.5h2a.75.75 0 100-1.5.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h1a1.5 1.5 0 100-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});