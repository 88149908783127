define("ember-svg-jar/inlined/multiple-actions-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-monitor</title><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M11.153 9.883a.154.154 0 00.2.045A3.439 3.439 0 0113 9.5h4.761a.25.25 0 00.19-.412 4.548 4.548 0 00-7.046.172.25.25 0 00.005.311q.126.153.243.312zM9.5 13a3.475 3.475 0 01.529-1.844.25.25 0 00-.006-.275A5.5 5.5 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.56a.25.25 0 00.25-.25z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M20 22h-1.25a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25H22a2 2 0 002-2v-6a2 2 0 00-2-2h-9a2 2 0 00-2 2v6a2 2 0 002 2h3.25a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H15a1.041 1.041 0 00-1 1 1 1 0 001 1h5a1 1 0 001-1 .977.977 0 00-.153-.5A.988.988 0 0020 22zm1.75-9a.25.25 0 01.25.25v5.5a.249.249 0 01-.25.25h-8.5a.25.25 0 01-.25-.25v-5.5a.25.25 0 01.25-.25z\"/><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M11.153 9.883a.154.154 0 00.2.045A3.439 3.439 0 0113 9.5h4.761a.25.25 0 00.19-.412 4.548 4.548 0 00-7.046.172.25.25 0 00.005.311q.126.153.243.312zM9.5 13a3.475 3.475 0 01.529-1.844.25.25 0 00-.006-.275A5.5 5.5 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.56a.25.25 0 00.25-.25z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M20 22h-1.25a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25H22a2 2 0 002-2v-6a2 2 0 00-2-2h-9a2 2 0 00-2 2v6a2 2 0 002 2h3.25a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H15a1.041 1.041 0 00-1 1 1 1 0 001 1h5a1 1 0 001-1 .977.977 0 00-.153-.5A.988.988 0 0020 22zm1.75-9a.25.25 0 01.25.25v5.5a.249.249 0 01-.25.25h-8.5a.25.25 0 01-.25-.25v-5.5a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});