define("ember-svg-jar/inlined/task-list-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-list-clock</title><path d=\"M12.619 8.412a.743.743 0 00-.743-.742H5.938a.743.743 0 000 1.485h5.938a.743.743 0 00.743-.743z\"/><path d=\"M9.586 19a.242.242 0 00-.236-.2H3.464a.5.5 0 01-.5-.5V5.443a.5.5 0 01.5-.495h10.887a.5.5 0 01.494.495v4.039a.244.244 0 00.295.242 18.068 18.068 0 012.443-.29.245.245 0 00.231-.247V3.464a1.484 1.484 0 00-1.484-1.485h-3.957a.238.238 0 01-.205-.119 3.788 3.788 0 00-6.521 0 .239.239 0 01-.205.119H1.485A1.485 1.485 0 000 3.464v16.825a1.485 1.485 0 001.485 1.484h8.847a.244.244 0 00.206-.375A7.847 7.847 0 019.586 19z\"/><path d=\"M11.035 12.523a8.067 8.067 0 01.951-1.046c.085-.079.028-.343-.11-.343H5.938a.743.743 0 000 1.485h4.907a.238.238 0 00.19-.096zM5.938 14.6a.742.742 0 100 1.484h3.37a.239.239 0 00.235-.2 7.664 7.664 0 01.251-.995c.032-.1.055-.29-.129-.29zM17.32 10.639A6.681 6.681 0 1024 17.32a6.688 6.688 0 00-6.68-6.681zm0 11.382a4.7 4.7 0 114.7-4.7 4.706 4.706 0 01-4.7 4.7z\"/><path d=\"M19.3 16.33h-.742a.248.248 0 01-.248-.248v-1.237a.99.99 0 00-1.979 0v2.475a.989.989 0 00.99.989H19.3a.99.99 0 100-1.979z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});