define("ember-svg-jar/inlined/landmark-tower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-tower</title><path d=\"M5.75 20.411h8a.5.5 0 00.5-.56L12.259 3.477a1 1 0 00-.159-.447L10.582.757a1.037 1.037 0 00-1.664 0L7.4 3.03a.986.986 0 00-.162.444L5.254 19.851a.5.5 0 00.496.56zM18.5 21.661H1a1 1 0 000 2h17.5a1 1 0 000-2zM20.58.831A3.385 3.385 0 0017.547 2.7a.5.5 0 01-.49.273 2.357 2.357 0 00-.207-.008 2.35 2.35 0 000 4.7h3.73a3.415 3.415 0 100-6.83zm0 5.33h-3.73a.85.85 0 110-1.7.8.8 0 01.57.23.752.752 0 001.26-.52 1.911 1.911 0 013.82.07 1.92 1.92 0 01-1.92 1.92z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});