define("ember-svg-jar/inlined/design-document-max-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-document-max-1</title><path d=\"M12.5 12.125a1.377 1.377 0 00-1.375 1.375v1.125a.25.25 0 00.25.25h2.25a.25.25 0 00.25-.25V13.5a1.377 1.377 0 00-1.375-1.375z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM9.125 19.5a.625.625 0 01-1.25 0v-4.982c0-.112-.141-.088-.185 0l-.631 1.261a.625.625 0 01-1.118 0s-.579-1.167-.631-1.261-.185-.094-.185 0V19.5a.625.625 0 01-1.25 0v-8a.625.625 0 011.184-.279l1.217 2.434a.25.25 0 00.448 0l1.217-2.434a.625.625 0 011.184.279zm6 0a.625.625 0 01-1.25 0v-3.125a.25.25 0 00-.25-.25h-2.25a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0v-5.9a2.7 2.7 0 012.1-2.678 2.63 2.63 0 013.15 2.578zm5.934-.279a.625.625 0 11-1.118.558l-1.217-2.435a.251.251 0 00-.448 0l-1.217 2.435a.625.625 0 11-1.118-.558l1.8-3.609a.252.252 0 000-.224l-1.8-3.609a.625.625 0 111.118-.558l1.217 2.435a.25.25 0 00.448 0l1.217-2.435a.625.625 0 011.118.558l-1.8 3.609a.252.252 0 000 .224z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});