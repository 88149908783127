define("ember-svg-jar/inlined/design-file-psd", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-psd</title><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.427A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6zM7 13h-.75a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25H7a1 1 0 000-2zM16.317 13.137a.248.248 0 00-.317.242v4.242a.248.248 0 00.317.242 2.5 2.5 0 000-4.726z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.25a.25.25 0 00-.25-.25zM8.972 14.338A2.079 2.079 0 016.888 16H6.25a.25.25 0 00-.25.25v2.25a.5.5 0 01-1 0v-6a.5.5 0 01.5-.5H7a2 2 0 011.972 2.338zm2.385.133L13.2 15.7a1.8 1.8 0 01-1 3.3h-1.7a.5.5 0 010-1h1.7a.8.8 0 00.446-1.471L10.8 15.3a1.8 1.8 0 011-3.3h1.7a.5.5 0 010 1h-1.7a.8.8 0 00-.446 1.471zM15.5 19a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5 3.5 3.5 0 010 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});