define("ember-svg-jar/inlined/arrow-thick-circle-left-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-circle-left-2</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm6.469 12.5a1 1 0 01-1 1h-6.5c-.55 0-.7.336-.334.746l2.3 2.573a1.072 1.072 0 01-.042 1.452l-.295.3a.972.972 0 01-1.4-.019l-5.51-5.83a1.06 1.06 0 01.006-1.446L11.3 5.451a.982.982 0 011.4-.014l.3.3a1.055 1.055 0 01.031 1.443L10.66 9.765c-.372.4-.226.737.324.737h6.485a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});