define("ember-svg-jar/inlined/show-hat-cards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>show-hat-cards</title><path d=\"M4.445 10.111a.5.5 0 00.487.388h1.026a.5.5 0 00.487-.612L5.054 3.851a.5.5 0 01.375-.6l4.38-1.009a.5.5 0 01.6.375l.623 2.7a1 1 0 01-.032.565L9.582 9.83a.5.5 0 00.47.669h1.063a.5.5 0 00.47-.331l1.335-3.719a.5.5 0 01.64-.3l4.286 1.538a.5.5 0 01.3.639l-.539 1.504a.5.5 0 00.471.669h1.062a.5.5 0 00.471-.331l.809-2.255a1.346 1.346 0 00-.81-1.72l-6.461-2.318a.846.846 0 01-.537-.607l-.512-2.23A1.347 1.347 0 0010.49.033l-6.638 1.53A1.338 1.338 0 002.845 3.17zM18 17.5H6a.5.5 0 00-.5.5v3.333A2.633 2.633 0 008.1 24h7.8a2.635 2.635 0 002.6-2.667V18a.5.5 0 00-.5-.5zM23 12H1a1 1 0 000 2h4a.5.5 0 01.5.5v1a.5.5 0 00.5.5h12a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h4a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});