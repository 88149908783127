define("ember-svg-jar/inlined/file-code-search-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-search-1</title><path d=\"M10.168 19.772a.242.242 0 01-.03-.3 5.515 5.515 0 10-1.688 1.683.242.242 0 01.3.031l2.506 2.5a1 1 0 101.414-1.414zm-4.668.245a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5zM14.714 6.467a.75.75 0 00-1.078-1.042l-.018.018L10.952 8.3a.749.749 0 000 1.023l2.666 2.857a.751.751 0 001.061.036l.021-.016a.749.749 0 00.018-1.042l-2.032-2.175a.25.25 0 010-.342zM16.286 11.157a.75.75 0 001.078 1.043l.017-.018 2.667-2.857a.752.752 0 000-1.025l-2.666-2.857a.752.752 0 00-1.061-.036.731.731 0 00-.036 1.06l2.029 2.175a.249.249 0 010 .341z\"/><path d=\"M23.414 3.018L21 .6a2 2 0 00-1.414-.583H8a2 2 0 00-2 2v7.3a.242.242 0 00.222.241 6.991 6.991 0 011.453.309A.242.242 0 008 9.642V2.517a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.147l2.122 2.121a.5.5 0 01.146.354v13.379a.5.5 0 01-.5.5h-9.118a.242.242 0 00-.23.17 6.945 6.945 0 01-.386.948.321.321 0 00.033.352l.4.4a.431.431 0 00.346.133H22a2 2 0 002-2V4.432a2 2 0 00-.586-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});