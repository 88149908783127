define("ember-svg-jar/inlined/vip-crown-queen-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vip-crown-queen-2</title><path d=\"M3.594 17.25A2.27 2.27 0 004 21.75h16a2.192 2.192 0 001.679-.723 2.3 2.3 0 00.56-1.527 2.169 2.169 0 00-1.828-2.25zM20.2 16.25a.5.5 0 00.491-.4l1.2-6.11a2.257 2.257 0 10-1.61-.548l-2.425 2.767a.5.5 0 01-.744.009l-4.059-4.429a2.75 2.75 0 10-2.106 0L6.9 11.954a.5.5 0 01-.377.162.5.5 0 01-.371-.174L3.758 9.158a2.245 2.245 0 10-1.58.585l1.151 6.1a.5.5 0 00.491.407z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});