define("ember-svg-jar/inlined/fitness-pilates-ball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fitness-pilates-ball</title><path d=\"M16.767 1.337a.5.5 0 00-.313-.49 11.958 11.958 0 00-3.69-.834.5.5 0 00-.531.468 12.47 12.47 0 01-3.868 8.312A12.463 12.463 0 01.482 12.23a.5.5 0 00-.469.531 11.977 11.977 0 00.834 3.693.5.5 0 00.491.313 16.229 16.229 0 0010.309-4.409 16.212 16.212 0 005.12-11.021z\"/><path d=\"M.766 10.2a10.475 10.475 0 006.219-2.855A10.482 10.482 0 0010.2.765a.5.5 0 00-.6-.54A12.014 12.014 0 00.226 9.6a.5.5 0 00.54.6zM23.917 10.68a.25.25 0 00-.479-.07A24.2 24.2 0 0110.61 23.438a.25.25 0 00.071.479A12 12 0 0023.917 10.68z\"/><path d=\"M22.588 6.932a.994.994 0 00-.091-.74 12.051 12.051 0 00-3.061-3.613.5.5 0 00-.806.326 18.2 18.2 0 01-5.612 10.91 18.206 18.206 0 01-10.113 4.814.5.5 0 00-.327.806A12.074 12.074 0 006.192 22.5a1 1 0 00.741.091A22.142 22.142 0 0022.588 6.932z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});