define("ember-svg-jar/inlined/renewable-energy-battery-charge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>renewable-energy-battery-charge</title><path d=\"M6 20.5a2 2 0 01-2-2v-13a2 2 0 012-2h5.25a.5.5 0 01.5.5v.5a.5.5 0 00.309.462.512.512 0 00.191.038.5.5 0 00.354-.146l2-2a.5.5 0 000-.708l-2-2A.5.5 0 0011.75.5V1a.5.5 0 01-.5.5H6a4 4 0 00-4 4v13a4 4 0 004 4 1 1 0 000-2zM18 1.5a1 1 0 000 2 2 2 0 012 2v13a2 2 0 01-2 2h-5.25a.5.5 0 01-.5-.5v-.5a.5.5 0 00-.309-.462.512.512 0 00-.191-.038.5.5 0 00-.354.146l-2 2a.5.5 0 000 .708l2 2a.5.5 0 00.854-.354V23a.5.5 0 01.5-.5H18a4 4 0 004-4v-13a4 4 0 00-4-4z\"/><path d=\"M16 17V9a1 1 0 00-1-1h-.5a.5.5 0 01-.5-.5V7a1 1 0 00-1-1h-2a1 1 0 00-1 1v.5a.5.5 0 01-.5.5H9a1 1 0 00-1 1v8a1 1 0 001 1h6a1 1 0 001-1zm-2.646-6.646l-1.233 1.232a.243.243 0 00.172.414A1 1 0 0113 13.707l-1.646 1.647a.5.5 0 01-.708-.708l1.233-1.232a.243.243 0 00-.172-.414A1 1 0 0111 11.293l1.646-1.647a.5.5 0 01.708.708z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});