define("ember-svg-jar/inlined/single-woman.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman</title><path d=\"M22.5 19.193c-.7-1.41-3.65-2.387-7.347-3.756a1 1 0 01-.653-.937v-.685a.25.25 0 01.241-.25 8.255 8.255 0 004.9-1.139.5.5 0 00.106-.762c-.629-.69-1.064-1.523-1.25-4.265C18.167 2.48 14.85.75 12 .75S5.833 2.48 5.5 7.4c-.186 2.742-.621 3.575-1.25 4.265a.5.5 0 00.106.762 8.1 8.1 0 004.889 1.157.249.249 0 01.255.25v.666a1 1 0 01-.653.938C5.15 16.806 2.2 17.783 1.5 19.193A10.158 10.158 0 00.531 22.7a.5.5 0 00.5.546h7.575a.5.5 0 00.462-.691l-1.533-3.702a.453.453 0 01.113-.509.461.461 0 01.518-.072 8.522 8.522 0 007.678.068.456.456 0 01.616.6l-1.618 3.608a.5.5 0 00.457.7h7.671a.5.5 0 00.5-.546 10.158 10.158 0 00-.97-3.509z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});