define("ember-svg-jar/inlined/house-modern", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>house-modern</title><path d=\"M24 21.775a1 1 0 00-1-1h-.75a.5.5 0 01-.5-.5V12a.25.25 0 01.386-.209l.319.207a1 1 0 001.09-1.677l-2.568-1.666a.5.5 0 01-.227-.419V4.775a1 1 0 00-1-1h-1a1 1 0 00-1 1V6.1a.251.251 0 01-.387.209L9.8 1.387a1 1 0 10-1.09 1.676l1.817 1.182a.5.5 0 01.228.419v15.611a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5V9.025a.75.75 0 00-.376-.651A.732.732 0 008.4 8.3a.969.969 0 00-.636.1L.883 12.242A1 1 0 00.5 13.6a.972.972 0 00.786.5.5.5 0 01.464.5v5.678a.5.5 0 01-.5.5H1a1 1 0 000 2h22a1 1 0 001-1.003zm-9.25-12.5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm-1.5 8.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v2.5a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5zm-9-.5a.5.5 0 01.5-.5h2.5a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-2.5a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});