define("ember-svg-jar/inlined/table-lamp-colorful", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>table-lamp-colorful</title><path d=\"M19.477 15.846c-.082-.332-.218-.841-.423-1.615-.557-2.1-1.595-6-3.469-13.473A.993.993 0 0014.62 0H9.38a.993.993 0 00-.965.758C6.541 8.233 5.5 12.136 4.946 14.231c-.205.774-.341 1.283-.423 1.615a.918.918 0 00.181.779c.185.237.319.227.566.461 1.475 1.478 1.51 2.805.152 4.4A1.5 1.5 0 006.5 24h11a1.5 1.5 0 001.078-2.518c-1.358-1.591-1.323-2.918.152-4.4.247-.234.381-.224.566-.461a.918.918 0 00.181-.775zM14.49 8.5a1 1 0 11-1 1 1 1 0 011-1zm-3-3.5a1 1 0 11-1 1 1 1 0 011-1zm-1 6a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zM7.965 22a.25.25 0 01-.219-.371 4.391 4.391 0 00.178-4.268.25.25 0 01.225-.361h7.7a.25.25 0 01.225.361 4.391 4.391 0 00.178 4.268.25.25 0 01-.219.371z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});