define("ember-svg-jar/inlined/cash-payment-coin-stash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-payment-coin-stash</title><path d=\"M16.7 4.028a17.374 17.374 0 005.333-.667.25.25 0 00.167-.235v-.1a.5.5 0 00-.147-.353c-.117-.117-1.264-1.147-5.353-1.147s-5.237 1.03-5.354 1.147a.5.5 0 00-.146.353v.114a.25.25 0 00.17.237 18.078 18.078 0 005.33.651zM16.7 9.028a17.374 17.374 0 005.333-.667.25.25 0 00.167-.235V4.7a.252.252 0 00-.1-.2.248.248 0 00-.219-.04 22.6 22.6 0 01-5.181.569 22.6 22.6 0 01-5.182-.569.249.249 0 00-.219.04.254.254 0 00-.1.2v3.442a.25.25 0 00.17.237 18.078 18.078 0 005.331.649zM16.7 10.028a22.6 22.6 0 01-5.182-.569.249.249 0 00-.219.04.254.254 0 00-.1.2v2.829c0 1.354 3.846 1.5 5.5 1.5s5.5-.146 5.5-1.5V9.7a.252.252 0 00-.1-.2.248.248 0 00-.219-.04 22.6 22.6 0 01-5.18.568zM20.705 16.527h-.011L17.173 17.7a.255.255 0 00-.152.143 2 2 0 01-1.821 1.185h-4a.5.5 0 010-1h4a1 1 0 000-2h-3.5a7.124 7.124 0 00-4-1.5H5.644a4 4 0 00-1.789.423L.838 16.459a.25.25 0 00-.138.224v5.394a.249.249 0 00.382.212l3.218-2.01a1.018 1.018 0 01.857-.1c9.964 3.358 6.629 3.373 17.861-2.309A.521.521 0 0023.1 17a2.477 2.477 0 00-2.395-.473z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});