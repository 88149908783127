define("ember-svg-jar/inlined/cleaning-vacuum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cleaning-vacuum</title><path d=\"M8.5 20h-8a.5.5 0 00-.5.5v2A1.5 1.5 0 001.5 24h6A1.5 1.5 0 009 22.5v-2a.5.5 0 00-.5-.5z\"/><circle cx=\"4.503\" cy=\"14\" r=\"1\"/><path d=\"M23.862 22.49l-.829-1.16a1.489 1.489 0 00-1.341-.83H20.5a.5.5 0 01-.5-.5v-1.5a.965.965 0 00-.335-.725.5.5 0 01-.165-.375V3a3 3 0 00-6 0v10.5a.5.5 0 01-1 0v-6a4.5 4.5 0 00-9 0v2.032a.5.5 0 01-.437.5A3.5 3.5 0 000 13.5V18a.5.5 0 00.5.5h8A.5.5 0 009 18v-4.5a3.5 3.5 0 00-3.062-3.472.5.5 0 01-.438-.5V7.5a2.5 2.5 0 015 0v6a2.5 2.5 0 005 0V3a1 1 0 012 0v14.4a.5.5 0 01-.166.372.964.964 0 00-.334.728V20a.5.5 0 01-.5.5h-1.19a1.481 1.481 0 00-1.32.79l-.88 1.26A1 1 0 0014 24h9a1 1 0 00.851-.47 1.042 1.042 0 00.011-1.04zM6.5 14a2 2 0 11-2-2 2.006 2.006 0 012 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});