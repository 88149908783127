define("ember-svg-jar/inlined/shipment-upload.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-upload</title><path d=\"M13.25 22.944a.251.251 0 00.353.228l9.311-4.232a1 1 0 00.586-.91V6.886a.25.25 0 00-.353-.228L13.4 11.09a.248.248 0 00-.147.227zm4.9-9.624a.5.5 0 01.708 0l1.5 1.5a.5.5 0 01-.354.854h-.5a.249.249 0 00-.25.25v1.75a.75.75 0 01-1.5 0v-1.75a.249.249 0 00-.25-.25H17a.5.5 0 01-.354-.854zM7.581 9.437a.25.25 0 00-.346.23v2.007a.75.75 0 01-1.5 0v-2.84a.249.249 0 00-.154-.234L.846 6.631a.249.249 0 00-.235.022.253.253 0 00-.111.208v11.146a1 1 0 00.615.923L11.4 23.217a.25.25 0 00.346-.231V11.34a.251.251 0 00-.154-.231zM15.9 2.822a.25.25 0 000-.456L12.4.847a1 1 0 00-.8 0L1.993 5.025a.25.25 0 000 .46L6.2 7.234a.247.247 0 00.2 0zM12.386 9.814a.253.253 0 00.2 0L22.062 5.5a.25.25 0 000-.456l-3.717-1.612a.252.252 0 00-.2 0L8.689 7.82a.25.25 0 00.01.457z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});