define("ember-svg-jar/inlined/crime-man-riot-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-man-riot-2</title><path d=\"M9.55 10.2a.561.561 0 01.559 0 .75.75 0 101.022-1.1 2.021 2.021 0 00-2.6 0 .75.75 0 001.02 1.1zM13.972 10.2a.552.552 0 01.557 0 .75.75 0 101.022-1.1 2.019 2.019 0 00-2.6 0 .75.75 0 001.022 1.1zM22.248 18.814a7.1 7.1 0 00-3.056-1.156 57.81 57.81 0 01-1.257-.3.5.5 0 00-.6.346 7.826 7.826 0 01-5.161 5.4.748.748 0 01-.352 0 7.826 7.826 0 01-5.161-5.4.5.5 0 00-.6-.346l-1.257.3a7.106 7.106 0 00-3.057 1.156A4.532 4.532 0 000 22.379v1a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-1a4.532 4.532 0 00-1.752-3.565z\"/><path d=\"M7.781 15.405C8.09 19.977 11.222 21.331 12 21.6c.794-.265 3.892-1.589 4.216-6.145a5.7 5.7 0 001.325-2.16 4.616 4.616 0 00.165-.655.247.247 0 01.143-.186 2.729 2.729 0 001.439-2.433 2.112 2.112 0 00-.464-1.444C20.088-.454 12.205.129 12.021.129S3.948-.452 5.215 8.574a2.114 2.114 0 00-.465 1.443 2.731 2.731 0 001.439 2.434.253.253 0 01.145.187 4.564 4.564 0 00.145.6 5.632 5.632 0 001.302 2.167zM6.25 9.989a.859.859 0 01.058-.36h.351a1.754 1.754 0 001.716-1.407l.184-.922a.25.25 0 01.227-.2 5.113 5.113 0 003.071-1.05.249.249 0 01.327 0 5.117 5.117 0 003.07 1.05.25.25 0 01.227.2l.185.92a1.754 1.754 0 001.716 1.408h.347a.76.76 0 01.061.36 1.272 1.272 0 01-.559 1.095 1.854 1.854 0 00-1 1.306 3.384 3.384 0 01-.319.929c-.468-.7-1.539-1.19-3.91-1.19-2.332 0-3.409.476-3.889 1.155a3.4 3.4 0 01-.3-.9 1.847 1.847 0 00-1-1.3 1.276 1.276 0 01-.563-1.094z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});