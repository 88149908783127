define("ember-svg-jar/inlined/antique-swords", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>antique-swords</title><path d=\"M23.414 1.224a2 2 0 00-2.828 0C20.3 1.51 17.5 4.75 16.073 6.568a.249.249 0 01-.182.1.254.254 0 01-.192-.072l-1.624-1.629a1 1 0 00-1.414 0 1 1 0 000 1.414l1.06 1.06a.249.249 0 010 .353l-.229.229a.5.5 0 000 .707l2.476 2.477a.5.5 0 00.707 0l.229-.23a.252.252 0 01.354 0l1.06 1.06a1 1 0 001.414-1.414L18.05 8.941a.25.25 0 01.023-.374c1.764-1.376 4.91-4.084 5.341-4.515a2 2 0 000-2.828zM6.326 15.191a20.71 20.71 0 00-5.053 7.535.5.5 0 00.639.642 20.577 20.577 0 007.6-4.995l1.156-1.157a.5.5 0 000-.707l-2.479-2.474a.5.5 0 00-.707 0z\"/><path d=\"M17.735 15.1l-7.4-7.4a.25.25 0 010-.353l1.06-1.06a1 1 0 10-1.41-1.417l-5.656 5.657a1 1 0 101.414 1.414l1.06-1.06a.25.25 0 01.353 0l7.4 7.4a20.735 20.735 0 007.535 5.053.5.5 0 00.641-.64 20.559 20.559 0 00-4.997-7.594zM5.672 7.416l1.152-1.153a.5.5 0 00.051-.646C5.793 4.12 3.9 1.677 3.414 1.188A2 2 0 00.586 4.017c.322.322 2.878 2.3 4.437 3.448a.5.5 0 00.649-.049z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});