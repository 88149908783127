define("ember-svg-jar/inlined/single-neutral-focus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-focus</title><path d=\"M7.7 22l-5.2.039a.5.5 0 01-.5-.5v-5.234a1 1 0 00-2 0v5.738A1.959 1.959 0 001.956 24H7.7a1 1 0 000-2zM1 8.7a1 1 0 001-1l-.04-5.2a.5.5 0 01.5-.5H7.7a1 1 0 000-2H1.956A1.959 1.959 0 000 1.957V7.7a1 1 0 001 1zM23 15.305a1 1 0 00-1 1l.04 5.191a.5.5 0 01-.5.5h-5.235a1 1 0 000 2h5.739A1.959 1.959 0 0024 22.043v-5.738a1 1 0 00-1-1zM22.044 0h-5.739a1 1 0 000 2l5.195-.039a.5.5 0 01.5.5V7.7a1 1 0 002 0V1.957A1.959 1.959 0 0022.044 0z\"/><path d=\"M9.938 11.992a1.4 1.4 0 01.043.877c-.032.1-.068.133-.075.135l-.552.205c-2.155.8-3.711 1.376-4.194 2.347a6.76 6.76 0 00-.66 2.712.5.5 0 00.5.5h14a.5.5 0 00.5-.5 6.76 6.76 0 00-.66-2.712c-.483-.971-2.039-1.548-4.194-2.347L14.094 13c-.007 0-.043-.034-.075-.135a1.441 1.441 0 01.032-.863 6.055 6.055 0 001.3-4.17 3.356 3.356 0 10-6.7 0 6.044 6.044 0 001.287 4.16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});