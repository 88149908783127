define("ember-svg-jar/inlined/smart-house-timer-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-house-timer-alternate</title><path d=\"M12 0a12 12 0 1012 12A12 12 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M18.555 15.7a.75.75 0 00-1.056.1c-.1.116-.2.23-.3.341a.75.75 0 001.088 1.032c.128-.135.252-.274.37-.417a.75.75 0 00-.102-1.056zM19.817 12.147a.752.752 0 00-.876.6 6.5 6.5 0 01-.212.823.751.751 0 001.428.463 8.317 8.317 0 00.257-1.006.749.749 0 00-.597-.88zM18.932 10.127a.75.75 0 001.472-.29 8.245 8.245 0 00-.822-2.3.75.75 0 10-1.322.707 6.757 6.757 0 01.672 1.883zM17.487 6.425a.75.75 0 00-.129-1.053 8.317 8.317 0 00-11.151 12.28.75.75 0 001.093-1.031 6.817 6.817 0 019.135-10.066.75.75 0 001.052-.13z\"/><path d=\"M11 8.749H9.778a1.746 1.746 0 00-1.7 1.325l-.811 3.242a.751.751 0 00.733.933h2.25V15.5a.75.75 0 001.5 0v-6a.75.75 0 00-.75-.751zm-.75 3.75a.25.25 0 01-.25.25h-.719a.248.248 0 01-.2-.1.251.251 0 01-.045-.215l.5-2a.25.25 0 01.241-.189H10a.249.249 0 01.25.25zM16 10.249a.75.75 0 000-1.5h-2.5a.75.75 0 00-.75.75v2.313a1.439 1.439 0 001.437 1.437h.313a.75.75 0 010 1.5h-1a.75.75 0 100 1.5h1a2.25 2.25 0 100-4.5.2.2 0 01-.173-.037.2.2 0 01-.077-.158V10.5a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});