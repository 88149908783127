define("ember-svg-jar/inlined/wifi-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-upload</title><path d=\"M17.559 11.014a6.376 6.376 0 106.375 6.376 6.382 6.382 0 00-6.375-6.376zm-1 9.31V17.65a.25.25 0 00-.25-.25H15.6a.5.5 0 01-.391-.812l1.956-2.447a.519.519 0 01.781 0l1.957 2.447a.5.5 0 01-.39.812h-.707a.25.25 0 00-.25.25v2.674a1 1 0 01-2 0zM9.733 6.384a7.7 7.7 0 015.482 2.271 1 1 0 001.414-1.414 9.687 9.687 0 00-6.9-2.857 9.688 9.688 0 00-6.892 2.857 1 1 0 001.414 1.414 7.7 7.7 0 015.48-2.271z\"/><path d=\"M17.69 5.524A1 1 0 0019.1 4.11a13.272 13.272 0 00-18.745 0 1 1 0 101.418 1.414 11.272 11.272 0 0115.917 0zM13.493 11.015a.98.98 0 00.153-.444.992.992 0 00-.423-.944 6.264 6.264 0 00-7.912.767 1 1 0 101.414 1.414 4.262 4.262 0 015.38-.523 1.006 1.006 0 00.335.139 1 1 0 001.053-.409z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});