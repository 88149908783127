define("ember-svg-jar/inlined/real-estate-settings-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-settings-house</title><path d=\"M22.421 9.763l-1.266-.451a1.375 1.375 0 01-.78-1.885l.576-1.213a2.375 2.375 0 00-3.164-3.164l-1.213.576a1.376 1.376 0 01-1.886-.782l-.45-1.264a2.375 2.375 0 00-4.476 0l-.45 1.266a1.377 1.377 0 01-1.886.781L6.214 3.05a2.375 2.375 0 00-3.165 3.164l.576 1.213a1.375 1.375 0 01-.78 1.885l-1.266.451a2.374 2.374 0 000 4.474l1.266.451a1.375 1.375 0 01.78 1.885l-.576 1.213a2.375 2.375 0 003.164 3.164l1.213-.576a1.376 1.376 0 011.886.782l.45 1.264a2.375 2.375 0 004.476 0l.45-1.266a1.376 1.376 0 011.886-.781l1.212.576a2.375 2.375 0 003.165-3.164l-.576-1.213a1.375 1.375 0 01.78-1.885l1.266-.451a2.374 2.374 0 000-4.474zM17.5 12h-1a.5.5 0 00-.5.5v3a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5V14a1 1 0 00-2 0v1.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-3a.5.5 0 00-.5-.5h-1a.5.5 0 01-.336-.87l5.163-4.694a.994.994 0 011.346 0l5.163 4.694a.5.5 0 01-.336.87z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});