define("ember-svg-jar/inlined/gardening-pot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gardening-pot</title><rect x=\"1\" y=\".5\" width=\"22\" height=\"6\" rx=\"1.5\" ry=\"1.5\"/><path d=\"M20.5 8h-17a.5.5 0 00-.493.585l2.43 14.08a1 1 0 00.986.835h11.154a1 1 0 00.986-.834l2.43-14.081A.5.5 0 0020.5 8zm-2.93 8.582l-.334 2a.5.5 0 01-.493.418H7.257a.5.5 0 01-.493-.418l-.334-2A.5.5 0 016.924 16h10.153a.5.5 0 01.493.582z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});