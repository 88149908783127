define("ember-svg-jar/inlined/smiley-in-love", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-in-love</title><path d=\"M23.548 4.359a4.277 4.277 0 00-3.134-2.3 4.234 4.234 0 00-1.527.03.248.248 0 01-.189-.037 11.969 11.969 0 00-13.394 0 .25.25 0 01-.19.037 4.227 4.227 0 00-1.526-.03A4.276 4.276 0 00.508 8.3a.252.252 0 01.019.192 12 12 0 1022.946-.007.252.252 0 01.018-.192 4.265 4.265 0 00.057-3.934zm-21.306.9a2.268 2.268 0 013.633-.59l.45.449a.249.249 0 00.353 0l.45-.449A2.27 2.27 0 0111 6.19v.162a2.267 2.267 0 01-.662 1.522l-3.656 3.813a.25.25 0 01-.361 0L2.667 7.874a2.269 2.269 0 01-.425-2.619zm5.214 9.738a1 1 0 011.383.291 3.771 3.771 0 006.324 0 1 1 0 011.675 1.093 5.771 5.771 0 01-9.674 0 1 1 0 01.292-1.388zm13.879-7.123l-3.654 3.813a.254.254 0 01-.181.077.25.25 0 01-.18-.077l-3.655-3.813a2.269 2.269 0 013.209-3.209l.449.449a.25.25 0 00.354 0l.449-.449a2.269 2.269 0 013.209 3.209z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});