define("ember-svg-jar/inlined/love-it-break", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it-break</title><path d=\"M23.32 5.2a6.587 6.587 0 00-10.453-1.71c-.212.193-.277.231-.309.32l-2.7 7.348a.251.251 0 00.03.23.249.249 0 00.205.107h4.787a.749.749 0 01.721.955l-2.462 8.655a.25.25 0 00.42.242l8.211-8.458A6.393 6.393 0 0023.32 5.2z\"/><path d=\"M13.8 13.313a.253.253 0 00-.041-.219.248.248 0 00-.2-.1h-4.9a.75.75 0 01-.7-1.009L11.037 3.6a.25.25 0 00-.066-.271 6.514 6.514 0 00-5.482-1.618A6.476 6.476 0 00.7 5.2a6.4 6.4 0 001.2 7.454s7.357 7.938 8.953 9.644a.25.25 0 00.423-.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});