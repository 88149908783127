define("ember-svg-jar/inlined/rating-star-subtract-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rating-star-subtract-1-alternate</title><path d=\"M10.5 17.932a.25.25 0 00-.4-.206L6.724 20.2a.25.25 0 01-.385-.28L8.1 14.648a1 1 0 00-.31-1.086l-4.37-3.62a.25.25 0 01.159-.442h5.07a1 1 0 00.943-.668l1.822-5.161a.25.25 0 01.471 0l1.821 5.162a1 1 0 00.943.667h5.068a.25.25 0 01.16.442l-.369.306a.25.25 0 00.106.437 7.428 7.428 0 011.608.551.248.248 0 00.267-.033l1.282-1.062A1.5 1.5 0 0021.8 7.5h-6.44L13.054.975a1.5 1.5 0 00-2.809 0l-.007.019L7.942 7.5H1.5a1.5 1.5 0 00-.963 2.65l5.447 4.515L3.7 21.523a1.5 1.5 0 002.309 1.686l4.576-3.356a.25.25 0 00.1-.255A7.39 7.39 0 0110.5 18z\"/><path d=\"M18 12a6 6 0 106 6 6.007 6.007 0 00-6-6zm2 6.75h-4a.75.75 0 010-1.5h4a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});