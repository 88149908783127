define("ember-svg-jar/inlined/style-three-pin-couple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-couple</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM8.5 4a2 2 0 11-2 2 2 2 0 012-2zm2.83 6.242l-1.1 4.409a1.781 1.781 0 01-3.456 0l-1.1-4.41a1 1 0 01.182-.858A1.011 1.011 0 016.64 9h3.719a1 1 0 01.971 1.242zM15.5 4a2 2 0 11-2 2 2 2 0 012-2zm2.648 11.616a1.011 1.011 0 01-.788.384h-3.719a1 1 0 01-.971-1.242l1.1-4.409a1.781 1.781 0 013.456 0l1.1 4.41a1 1 0 01-.178.857z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});