define("ember-svg-jar/inlined/transfusion-water", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transfusion-water</title><path d=\"M12.75 9.75A2.75 2.75 0 0010 7H8.5a.5.5 0 01-.5-.5v-.243a.5.5 0 01.321-.466A2.994 2.994 0 0010.25 3v-.5a.5.5 0 01.5-.5h6.5a3 3 0 013 3v18a1 1 0 002 0V5a5 5 0 00-5-5h-7a2 2 0 00-2 2v1a1 1 0 01-2 0V2a1 1 0 00-2 0v1a2.994 2.994 0 001.929 2.791.5.5 0 01.321.466V6.5A.5.5 0 016 7H4.5a2.75 2.75 0 00-2.75 2.75V19a2.5 2.5 0 002.5 2.5H6a.25.25 0 01.25.25V23a1 1 0 002 0v-1.255a.25.25 0 01.25-.25h1.75a2.5 2.5 0 002.5-2.5zm-2 .469v.75a.5.5 0 01-.5.5h-6a.5.5 0 01-.5-.5v-.75A1.25 1.25 0 015 8.969h4.5a1.25 1.25 0 011.25 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});