define("ember-svg-jar/inlined/monitor-exchange-arrow-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-exchange-arrow-1</title><path d=\"M22.25 0h-5.47a.738.738 0 00-.738.717v.022a.738.738 0 00.739.761h5.47a.251.251 0 01.25.25v5.5a.25.25 0 01-.25.25h-6.716l-.267.267a2.5 2.5 0 01-1.149.655.8.8 0 00-.619.778.8.8 0 00.8.8h2.22a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H15a.75.75 0 000 1.5h5.25a.75.75 0 000-1.5h-1.73a.249.249 0 01-.25-.25v-1a.249.249 0 01.25-.25h3.73A1.751 1.751 0 0024 8.25v-6.5A1.75 1.75 0 0022.25 0z\"/><path d=\"M7.793 11.207a1 1 0 001.414 0l2-2A1 1 0 0010.5 7.5h-.31a.249.249 0 01-.214-.121.253.253 0 01-.007-.247 4.015 4.015 0 012.2-1.888.251.251 0 01.334.236V6a1 1 0 001.707.707l2-2a1 1 0 000-1.414l-2-2A1 1 0 0012.5 2v.883a.249.249 0 01-.2.244A6.011 6.011 0 007.747 7.32a.25.25 0 01-.24.18H6.5a1 1 0 00-.707 1.707z\"/><path d=\"M0 19a2 2 0 002 2h5.75a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H5a1 1 0 000 2h8a1 1 0 000-2h-2.75a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25H16a2 2 0 002-2v-3.5a1 1 0 00-2 0v1.75a.25.25 0 01-.25.25H2.25a.25.25 0 01-.25-.25V11a.25.25 0 01.25-.25H3.5a.987.987 0 00.968-.841.953.953 0 00.032-.159.983.983 0 00-.455-.817.976.976 0 00-.545-.183H2a2 2 0 00-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});