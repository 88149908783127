define("ember-svg-jar/inlined/sport-horse-riding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sport-horse-riding</title><path d=\"M12.25 5.31a.25.25 0 00-.25.25v.185a2.5 2.5 0 105 0V5.56a.25.25 0 00-.25-.25zM5.354 19.164l.865-1.913a.5.5 0 00-.174-.411l-2.04-1.75a2.425 2.425 0 01-.643-.883.25.25 0 00-.31-.136A4.488 4.488 0 000 18.31v3.5a.5.5 0 00.5.5h5.129a.25.25 0 00.183-.421 2.487 2.487 0 01-.458-2.725zM23.53 12.17l-3.4-2.137v-1.5a.622.622 0 00-.316-.543.613.613 0 00-.629.01 21.186 21.186 0 00-3.931 3.387.25.25 0 00.041.373l1.124.793a2.5 2.5 0 01-2.528 4.315c-.027-.016-2.169-1.527-2.169-1.527a5.377 5.377 0 01-.8-.575.25.25 0 00-.4.271 7.186 7.186 0 01.489 3.7 2.6 2.6 0 01-.124.337l-.972 2.151a2.43 2.43 0 01-.447.667.25.25 0 00.183.421H17.5a.5.5 0 00.5-.5v-1.5a10.457 10.457 0 011.889-5.944l2.086.386a1.493 1.493 0 001.659-.61c.021-.032.3-.774.3-.774a1 1 0 00-.404-1.201z\"/><path d=\"M9 20.81s1.026-2.285 1.047-2.355a4.511 4.511 0 00-1.4-4.653l-.664-.569L10.03 11.6l.328.766a4.552 4.552 0 001.9 2.133L14.388 16a1.5 1.5 0 101.488-2.606l-2.125-1.5a1.517 1.517 0 01-.636-.712L12.012 8.6A1.5 1.5 0 009.7 8.022l-5 4a1.5 1.5 0 00-.039 2.31L6.7 16.081a1.5 1.5 0 01.489 1.461l-.919 2.034A1.5 1.5 0 009 20.81zM18.5 4.31a.5.5 0 100-1h-1.2a3.017 3.017 0 00-1.862-1.51 2.979 2.979 0 00-3.612 1.831.5.5 0 00.468.676z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});