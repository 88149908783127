define("ember-svg-jar/inlined/video-game-mario-plant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-mario-plant</title><path d=\"M19.837 11.422l-3.862-2.758a.5.5 0 010-.814l3.861-2.758a1 1 0 00-.384-1.792l-1.424-.285a1 1 0 01-.784-.785L16.959.806A1 1 0 0015.165.42l-2.758 3.861a.5.5 0 01-.814 0L8.835.421a1 1 0 00-1.794.385l-.285 1.422a1 1 0 01-.784.785L4.548 3.3a1 1 0 00-.385 1.794L8.024 7.85a.5.5 0 010 .814l-3.861 2.758a1 1 0 00.385 1.794l1.424.285a1 1 0 01.784.784l.285 1.423a.986.986 0 00.77.782 1.016 1.016 0 00.217.024.988.988 0 00.807-.42l1.616-2.262a.25.25 0 01.453.156 33.168 33.168 0 01-.514 4.3.5.5 0 01-.8.307 2.98 2.98 0 00-2.518-.565l-.487.113a.5.5 0 00-.375.6l.112.488a2.99 2.99 0 003.12 2.309.249.249 0 01.262.3c-.066.333-.132.664-.192.984a1 1 0 00.8 1.167A1.05 1.05 0 0010.5 24a1 1 0 00.982-.816c.112-.6.236-1.218.364-1.85l.078-.391a.5.5 0 01.662-.372 3 3 0 003.579-1.257l.261-.427a.5.5 0 00-.166-.687l-.427-.261a2.986 2.986 0 00-2.933-.1.249.249 0 01-.361-.261 30.7 30.7 0 00.379-3.862.25.25 0 01.453-.139l1.8 2.518a1 1 0 001.794-.385l.285-1.423a1 1 0 01.784-.784l1.424-.285a1 1 0 00.385-1.794zm-6.423-2.813l.872.872a.75.75 0 11-1.06 1.061l-.872-.872a.5.5 0 00-.707 0l-.872.872a.75.75 0 01-1.061-1.061l.872-.872a.5.5 0 000-.707l-.872-.872a.75.75 0 011.061-1.06l.872.872a.5.5 0 00.707 0l.872-.872a.75.75 0 011.06 1.06l-.872.872a.5.5 0 000 .707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});