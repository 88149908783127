define("ember-svg-jar/inlined/trip-pin-multiple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trip-pin-multiple</title><path d=\"M5.75 12.25a6.255 6.255 0 014.611-6.031.5.5 0 00.368-.443c.014-.174.021-.35.021-.526a5.25 5.25 0 00-10.5 0c0 2.39 1.4 4.32 2.63 6.02a14.28 14.28 0 011.58 2.513.5.5 0 01.04.2v2.27a1 1 0 002 0v-.624a.5.5 0 00-.043-.2 7.809 7.809 0 01-.707-3.179zM3 5a2.5 2.5 0 112.5 2.5A2.5 2.5 0 013 5zM18.5 0a5.249 5.249 0 00-5.25 5.25c0 .176.007.352.021.526a.5.5 0 00.368.443 6.255 6.255 0 014.611 6.031 7.809 7.809 0 01-.707 3.175.5.5 0 00-.043.2v.624a1 1 0 002 0V13.88a13.5 13.5 0 011.62-2.61c1.23-1.7 2.63-3.63 2.63-6.02A5.249 5.249 0 0018.5 0zm0 7.5A2.5 2.5 0 1121 5a2.5 2.5 0 01-2.5 2.5z\"/><path d=\"M12 7a5.256 5.256 0 00-5.25 5.25c0 2.387 1.4 4.319 2.634 6.024A13.386 13.386 0 0111 20.885V23a1 1 0 002 0v-2.115a13.386 13.386 0 011.616-2.611c1.235-1.705 2.634-3.637 2.634-6.024A5.256 5.256 0 0012 7zm0 7.5a2.5 2.5 0 112.5-2.5 2.5 2.5 0 01-2.5 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});