define("ember-svg-jar/inlined/road-sign-roundabout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-roundabout</title><path d=\"M23.53 11.47l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0l11-11a.749.749 0 000-1.06zm-16.22-.229a4.732 4.732 0 012.654-3.519.22.22 0 00.119-.247l-.013-.055a.75.75 0 011.128-.8l1.2.749a.749.749 0 01.238 1.031l-.749 1.2a.75.75 0 01-.636.352.657.657 0 01-.124-.011.747.747 0 01-.607-.57.177.177 0 00-.268-.109 3.24 3.24 0 00-1.46 2.22.751.751 0 01-.74.631.808.808 0 01-.12-.009.751.751 0 01-.622-.863zm6.389 5.2a4.733 4.733 0 01-1.7.313 4.682 4.682 0 01-2.683-.854.219.219 0 00-.273.02L9 15.962a.751.751 0 01-1.262-.575l.05-1.413a.762.762 0 01.775-.724l1.413.049a.751.751 0 01.486 1.3.162.162 0 00-.051.144.165.165 0 00.088.125 3.286 3.286 0 002.665.169.75.75 0 01.536 1.4zM17.065 14l-1.248.665a.742.742 0 01-.353.088.772.772 0 01-.218-.032.757.757 0 01-.444-.365l-.664-1.256a.75.75 0 01.879-1.071.168.168 0 00.153-.027.171.171 0 00.066-.142 3.181 3.181 0 00-1.111-2.322.75.75 0 01.982-1.133A4.751 4.751 0 0116.75 12a2.67 2.67 0 01-.027.335.251.251 0 00.174.272l.035.01A.751.751 0 0117.065 14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});