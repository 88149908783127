define("ember-svg-jar/inlined/single-man-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-chat</title><path d=\"M24 1.506a1.5 1.5 0 00-1.5-1.5h-10a1.5 1.5 0 00-1.5 1.5v7a1.5 1.5 0 001.5 1.5H14v2.5a.5.5 0 00.813.391l3.612-2.891H22.5a1.5 1.5 0 001.5-1.5zm-10 5a1 1 0 111-1 1 1 0 01-1 1zm3.5 0a1 1 0 111-1 1 1 0 01-1 1zm3.5 0a1 1 0 111-1 1 1 0 01-1 1zM5.5 8.994a5.506 5.506 0 00-5.5 5.5v3a.5.5 0 00.5.5h1.81a.249.249 0 01.249.229L3 23.535a.5.5 0 00.5.459h4a.5.5 0 00.5-.459l.443-5.312a.249.249 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3a5.506 5.506 0 00-5.5-5.5zm.75 5.948a.75.75 0 01-1.5 0v-3.948a.75.75 0 011.5 0zM5.5 8.144a4.069 4.069 0 10-4.065-4.069A4.074 4.074 0 005.5 8.144zM3.571 3.356a.25.25 0 01.362-.124 5.053 5.053 0 002.609.726 4.961 4.961 0 00.736-.058.254.254 0 01.2.058.249.249 0 01.086.189 2.028 2.028 0 01-2.062 2 2.07 2.07 0 01-2.067-2.072 2 2 0 01.136-.719z\"/><path d=\"M24 1.506a1.5 1.5 0 00-1.5-1.5h-10a1.5 1.5 0 00-1.5 1.5v7a1.5 1.5 0 001.5 1.5H14v2.5a.5.5 0 00.813.391l3.612-2.891H22.5a1.5 1.5 0 001.5-1.5zm-10 5a1 1 0 111-1 1 1 0 01-1 1zm3.5 0a1 1 0 111-1 1 1 0 01-1 1zm3.5 0a1 1 0 111-1 1 1 0 01-1 1zM5.5 8.994a5.506 5.506 0 00-5.5 5.5v3a.5.5 0 00.5.5h1.81a.249.249 0 01.249.229L3 23.535a.5.5 0 00.5.459h4a.5.5 0 00.5-.459l.443-5.312a.249.249 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3a5.506 5.506 0 00-5.5-5.5zm.75 5.948a.75.75 0 01-1.5 0v-3.948a.75.75 0 011.5 0zM5.5 8.144a4.069 4.069 0 10-4.065-4.069A4.074 4.074 0 005.5 8.144zM3.571 3.356a.25.25 0 01.362-.124 5.053 5.053 0 002.609.726 4.961 4.961 0 00.736-.058.254.254 0 01.2.058.249.249 0 01.086.189 2.028 2.028 0 01-2.062 2 2.07 2.07 0 01-2.067-2.072 2 2 0 01.136-.719z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});