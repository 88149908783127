define("ember-svg-jar/inlined/sea-transport-buoy-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sea-transport-buoy-1</title><path d=\"M7.072 18.279a2.113 2.113 0 001.489.874 2.792 2.792 0 001.888-1.016 2.5 2.5 0 013.6-.482.25.25 0 00.4-.175 2.993 2.993 0 00-2.007-3.253s-.213-.15-.163-.3l.976-2.9a.251.251 0 01.317-.157l2.6.874a1.418 1.418 0 00.953-.065 1.242 1.242 0 00.63-.721l.319-.948a1.25 1.25 0 00-.785-1.583l-2.62-.877a.242.242 0 01-.143-.31l.167-.494a.29.29 0 01.272-.184l4.656-.26a.75.75 0 00.444-1.32l-4.1-3.492a.752.752 0 00-1.2.332s-3.846 11.46-3.925 11.65-.3.116-.3.116a3 3 0 00-3.8 1.88L6.159 17.2a.247.247 0 00.111.29 2.473 2.473 0 01.802.789z\"/><path d=\"M22.93 20.643c-1.573.129-2.669-1.556-2.676-1.57a1.043 1.043 0 00-1.72.03 3.357 3.357 0 01-2.748 1.55 2.8 2.8 0 01-2.4-1.481 1.043 1.043 0 00-1.717-.156 4.3 4.3 0 01-3.1 1.637 3.558 3.558 0 01-2.769-1.58 1.041 1.041 0 00-1.638-.09C4.147 19 2.7 20.8 1.053 20.685A.978.978 0 000 21.631a1 1 0 00.947 1.052c.075 0 .148.006.221.006a5.709 5.709 0 003.563-1.467.238.238 0 01.321.008 5.187 5.187 0 003.508 1.423 5.794 5.794 0 003.639-1.36.241.241 0 01.327.017 4.551 4.551 0 003.26 1.343 5.117 5.117 0 003.456-1.343.244.244 0 01.331 0 4.719 4.719 0 003.5 1.327A1 1 0 0024 21.571a1.015 1.015 0 00-1.07-.928z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});