define("ember-svg-jar/inlined/style-three-pin-microphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-microphone</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm-.808 3.629a.251.251 0 01.213.048.248.248 0 01.1.2V5.51a.5.5 0 001 0V3.873a.25.25 0 01.308-.244A3.483 3.483 0 0115.389 6.2a.25.25 0 01-.243.308H14a.5.5 0 000 1h1.25a.25.25 0 01.25.25v1.013a.25.25 0 01-.25.25H14a.5.5 0 000 1h1.148a.249.249 0 01.243.307 3.481 3.481 0 01-6.782 0 .25.25 0 01.243-.307h.9a.5.5 0 000-1h-1a.25.25 0 01-.25-.25V7.76a.25.25 0 01.25-.25H10a.5.5 0 000-1H8.854a.25.25 0 01-.243-.31 3.483 3.483 0 012.581-2.571zm1.771 12.31a.249.249 0 00-.213.247v1.585a.75.75 0 01-1.5 0v-1.585a.25.25 0 00-.213-.247 6.5 6.5 0 01-5.531-6.418.75.75 0 011.5 0 4.994 4.994 0 009.988 0 .75.75 0 011.5 0 6.5 6.5 0 01-5.531 6.418z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});