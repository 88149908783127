define("ember-svg-jar/inlined/image-document-svg-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-document-svg-1</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM5.805 14.453l1.949.976a2.479 2.479 0 01-1.108 4.7H4.5a.625.625 0 010-1.25h2.146a1.229 1.229 0 00.554-2.332l-1.949-.976a2.479 2.479 0 011.108-4.7H8.5a.625.625 0 010 1.25H6.354a1.229 1.229 0 00-.549 2.328zm9.32-.654a9.788 9.788 0 01-2.137 6.092.626.626 0 01-.976 0A9.788 9.788 0 019.875 13.8v-2.3a.625.625 0 011.25 0v2.3a8.535 8.535 0 001.154 4.275.26.26 0 00.442 0 8.535 8.535 0 001.154-4.275v-2.3a.625.625 0 011.25 0zm3.375 5.076a1.377 1.377 0 001.375-1.375s.028-.375-.194-.375H19a.625.625 0 010-1.25h1.5a.625.625 0 01.625.625v1a2.625 2.625 0 01-5.25 0v-4a2.625 2.625 0 015.25 0 .625.625 0 01-1.25 0 1.375 1.375 0 00-2.75 0v4a1.377 1.377 0 001.375 1.375z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});