define("ember-svg-jar/inlined/fishing-hook-fish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fishing-hook-fish</title><path d=\"M7 24a4.5 4.5 0 01-4.5-4.5V15a1 1 0 012 0v4.5a2.5 2.5 0 005 0v-.685a.249.249 0 00-.378-.215l-.863.511a1 1 0 01-1.018-1.722l1.973-1.166a1.47 1.47 0 011.493-.045A1.5 1.5 0 0111.5 17.5v2A4.505 4.505 0 017 24zm3.279-6.085l-.049.028a.268.268 0 00.049-.028zM5 13a.988.988 0 01-.5-.132l-3.5-2a1 1 0 111-1.736l3.5 2A1 1 0 015 13zM5 9a.988.988 0 01-.5-.132l-3.5-2a1 1 0 011-1.736l3.5 2A1 1 0 015 9zM3.5 4.5a1 1 0 01-1-1V1a1 1 0 012 0v2.5a1 1 0 01-1 1zM19.911 18.929a.249.249 0 01-.132-.171.252.252 0 01.05-.21c4.665-5.759 5.27-11.321-.437-16.16a2.005 2.005 0 00-2.577 0 10.909 10.909 0 00-3.917 5.9.25.25 0 00.047.208 4.543 4.543 0 006.061.993.75.75 0 11.824 1.254 6.007 6.007 0 01-3.3.989 6.07 6.07 0 01-3.391-1.032.25.25 0 00-.388.232 14.858 14.858 0 003.626 7.618.251.251 0 01-.081.381 3.989 3.989 0 00-2.134 2.884 1.017 1.017 0 00.224.831 1 1 0 00.764.354h5.906a1 1 0 00.764-.355 1.02 1.02 0 00.224-.833 3.985 3.985 0 00-2.133-2.883zM18 6.367a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});