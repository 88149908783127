define("ember-svg-jar/inlined/subtract-tab-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>subtract-tab-alternate</title><path d=\"M18.408 6a3.825 3.825 0 00-2.414-.972H2.211a2 2 0 00-2 2v9.936a2 2 0 002 2h13.783A3.839 3.839 0 0018.408 18l4.775-4.585a1.956 1.956 0 000-2.828zm-1.385 10.55a1.72 1.72 0 01-1.029.414H2.461a.25.25 0 01-.25-.25V7.282a.25.25 0 01.25-.25h13.533a1.93 1.93 0 011.029.414l4.584 4.343a.251.251 0 010 .362z\"/><path d=\"M10.711 10.718h-5a1.25 1.25 0 000 2.5h5a1.25 1.25 0 100-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});