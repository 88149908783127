define("ember-svg-jar/inlined/multiple-actions-flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-flash</title><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.5 14c0 1.763-.011 5.3.169 7.051a.5.5 0 00.5.449h2.667a.428.428 0 00.081-.008.248.248 0 00.2-.248l-.006-.571a.25.25 0 00-.226-.246 2.321 2.321 0 01-1.55-3.81l4.493-5.506a.247.247 0 00.049-.217A4.535 4.535 0 0010.9 9.261a.248.248 0 00.005.31A6.96 6.96 0 0112.5 14zM.5 18h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M15.483 17.645A.822.822 0 0016.108 19h1.285a.25.25 0 01.25.25v4.25a.5.5 0 00.332.471.5.5 0 00.556-.155l5.269-6.461A.822.822 0 0023.179 16h-1.286a.25.25 0 01-.25-.25V11.5a.5.5 0 00-.887-.316z\"/><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.5 14c0 1.763-.011 5.3.169 7.051a.5.5 0 00.5.449h2.667a.428.428 0 00.081-.008.248.248 0 00.2-.248l-.006-.571a.25.25 0 00-.226-.246 2.321 2.321 0 01-1.55-3.81l4.493-5.506a.247.247 0 00.049-.217A4.535 4.535 0 0010.9 9.261a.248.248 0 00.005.31A6.96 6.96 0 0112.5 14zM.5 18h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M15.483 17.645A.822.822 0 0016.108 19h1.285a.25.25 0 01.25.25v4.25a.5.5 0 00.332.471.5.5 0 00.556-.155l5.269-6.461A.822.822 0 0023.179 16h-1.286a.25.25 0 01-.25-.25V11.5a.5.5 0 00-.887-.316z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});