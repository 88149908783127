define("ember-svg-jar/inlined/office-sign-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-sign-document</title><path d=\"M17.988 3.885a.251.251 0 00.262-.25V3.5a1.5 1.5 0 011.5-1.5c.911 0 1.5.785 1.5 2a1 1 0 102 0c0-2.318-1.472-4-3.5-4H4.25a4 4 0 00-4 4v19a1 1 0 002 0V4a2 2 0 012-2h11.964a.251.251 0 01.236.334 3.466 3.466 0 00-.2 1.166v.323a.251.251 0 00.323.24 4.142 4.142 0 011.415-.178zM16.25 23a1 1 0 102 0v-5.443a.25.25 0 00-.427-.177l-1.5 1.5a.249.249 0 00-.073.177z\"/><path d=\"M14.886 11.478a.251.251 0 00-.354 0l-4.239 4.24a1.5 1.5 0 000 2.121l1.414 1.415a1.5 1.5 0 002.121 0l4.24-4.24a.25.25 0 000-.354z\"/><path d=\"M16.814 7.029a1.375 1.375 0 011.941 0 .163.163 0 01.047.114.161.161 0 01-.047.114l-2.808 2.808a.25.25 0 000 .354l3.181 3.181a.251.251 0 00.354 0l3.535-3.536a2.5 2.5 0 00-2.893-4 .219.219 0 01-.253-.041l-.056-.056a2.877 2.877 0 00-4.061 0l-5.5 5.5a.75.75 0 101.06 1.06zM6.969 19.32c-.775.775-1.314 3.54-1.415 4.087a.5.5 0 00.582.582c.547-.1 3.316-.637 4.089-1.413a2.3 2.3 0 10-3.256-3.256z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});