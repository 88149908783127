define("ember-svg-jar/inlined/time-clock-file-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-clock-file-warning</title><path d=\"M23.362 3.053L20.947.638A1.749 1.749 0 0019.71.126H8.124a1.75 1.75 0 00-1.75 1.75v7.8a.245.245 0 00.222.248 7.37 7.37 0 01.963.152.249.249 0 00.315-.24v-7.96a.251.251 0 01.25-.25H19.71l.177.073L22.3 4.113a.249.249 0 01.073.177v14.086a.251.251 0 01-.25.25h-8.9c-.134 0-.151.133-.1.232l.6 1.134a.251.251 0 00.222.134h8.187a1.75 1.75 0 001.75-1.75V4.29a1.749 1.749 0 00-.52-1.237zM7.905 12.147a1.449 1.449 0 00-2.561 0L.289 21.781a1.426 1.426 0 00.047 1.408 1.454 1.454 0 001.233.687H11.68a1.456 1.456 0 001.233-.686 1.428 1.428 0 00.047-1.409zm-2.031 3.729a.75.75 0 011.5 0v3a.75.75 0 01-1.5 0zm.75 6.25a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M20.374 8.376a4.75 4.75 0 10-4.75 4.75 4.755 4.755 0 004.75-4.75zm-8 0a3.25 3.25 0 113.25 3.25 3.254 3.254 0 01-3.25-3.25z\"/><path d=\"M17.392 9.126a.75.75 0 100-1.5h-.766a.252.252 0 01-.252-.252v-.766a.75.75 0 00-1.5 0v1.768a.75.75 0 00.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});