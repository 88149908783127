define("ember-svg-jar/inlined/bear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bear</title><path d=\"M22.672 2.665c-1.039-2.078-3.934-1.511-5.482-.849l-.39.167a8.5 8.5 0 00-9.6 0l-.391-.167c-1.543-.663-4.44-1.23-5.479.849a3.217 3.217 0 00-.043 3.05 4.307 4.307 0 002.321 1.938A8.551 8.551 0 003.5 9L2.115 22.4a1 1 0 00.995 1.1h17.782a1 1 0 001-1.1L20.5 9a8.516 8.516 0 00-.1-1.345 4.4 4.4 0 002.117-1.6 3.231 3.231 0 00.155-3.39zM14.927 6a1.25 1.25 0 11-1.25 1.25A1.249 1.249 0 0114.927 6zm-.921 5.5a.5.5 0 01.5.5A2.5 2.5 0 019.5 12a.5.5 0 01.5-.5zM8.927 6a1.25 1.25 0 11-1.25 1.25A1.249 1.249 0 018.927 6zm5.824 13.625A3.5 3.5 0 0112 18.287a3.5 3.5 0 01-6.25-2.162.75.75 0 011.5 0 2 2 0 004 0 .75.75 0 011.5 0 2 2 0 004 0 .75.75 0 011.5 0 3.5 3.5 0 01-3.499 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});