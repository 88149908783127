define("ember-svg-jar/inlined/messages-bubble-square-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-edit</title><path d=\"M21.428 6.967a.247.247 0 00.073-.176V1.506A1.5 1.5 0 0020 .006H1.5a1.5 1.5 0 00-1.5 1.5v12.052a1.459 1.459 0 001.5 1.448H3v3.5a.5.5 0 00.832.373l4.358-3.873h5.095a.25.25 0 00.177-.074zM11.6 23.985l3.143-.629a.25.25 0 00.128-.422l-2.809-2.808a.249.249 0 00-.249-.062.253.253 0 00-.173.19l-.629 3.146a.5.5 0 00.588.588zM23.227 11.76a2.62 2.62 0 00-3.7 0l-6.679 6.68a.25.25 0 000 .353l3.352 3.354a.25.25 0 00.353 0l6.679-6.68a2.621 2.621 0 00-.005-3.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});