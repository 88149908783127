define("ember-svg-jar/inlined/like-plus-one-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>like-plus-one-alternate</title><path d=\"M24 3.5a3 3 0 00-3-3h-4.5a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h5a.5.5 0 01.5.5v12.5a1 1 0 01-1 1H3a1 1 0 01-1-1V8a.5.5 0 01.5-.5h5A.5.5 0 008 7V1a.5.5 0 00-.5-.5H3a3 3 0 00-3 3v17a3 3 0 003 3h18a3 3 0 003-3z\"/><rect x=\"9\" y=\".5\" width=\"6\" height=\"7\" rx=\".5\" ry=\".5\"/><path d=\"M11 14h-1v-1a1 1 0 00-2 0v1H7a1 1 0 000 2h1v1a1 1 0 002 0v-1h1a1 1 0 000-2zM15.4 9.7l-2 1.5a1 1 0 001.2 1.6l.094-.071-.685 5.138a1 1 0 001.982.266l1-7.5A1 1 0 0015.4 9.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});