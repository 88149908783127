define("ember-svg-jar/inlined/feed-drupal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>feed-drupal</title><path d=\"M15.25 3.16c-1.53-1-2.86-1.92-3.17-2.83a.49.49 0 00-.49-.33.5.5 0 00-.47.37c-.26 1-1.6 2.06-3.14 3.31C5.12 6 1.56 8.89 1.56 13.56a10.44 10.44 0 0020.88 0c0-5.56-4.15-8.36-7.19-10.4zm.8 17.95a4.73 4.73 0 01-2.68.88 4.78 4.78 0 01-2.77-.94.5.5 0 01.6-.8 3.59 3.59 0 004.29 0 .5.5 0 11.56.83zm-3.76-2.67a2.43 2.43 0 012.08-1.19 1.77 1.77 0 011.5 1.05.5.5 0 11-.87.5c-.09-.17-.35-.55-.63-.55a1.45 1.45 0 00-1.24.73.5.5 0 11-.84-.54zm6-.29a.49.49 0 01-.21.09c-.78.13-1.64-.66-2.55-1.5a5.62 5.62 0 00-1.26-1 9.85 9.85 0 00-1.69 1c-1.29.82-2.76 1.74-3.79 1.74a3.09 3.09 0 01-3.34-3.18 3.86 3.86 0 013.87-3.93 6.64 6.64 0 013.41 1.45 4.61 4.61 0 001.55.82 5.78 5.78 0 001.89-.85 5.85 5.85 0 012.45-1c.54 0 1 .42 1.24 1.16a5 5 0 01-1.58 5.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});