define("ember-svg-jar/inlined/single-neutral-actions-up-down.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-up-down</title><path d=\"M12 12.639a.5.5 0 00-.192-.394 7.461 7.461 0 00-4.6-1.62 7.543 7.543 0 00-7.187 5.357.5.5 0 00.479.643h11a.5.5 0 00.5-.5z\"/><circle cx=\"7\" cy=\"4.875\" r=\"4.75\"/><path d=\"M17.25 19.125h-.75a.5.5 0 01-.5-.5v-5a1.25 1.25 0 00-2.5 0v5a.5.5 0 01-.5.5h-.75a.5.5 0 00-.407.791l2.5 3.5a.5.5 0 00.814 0l2.5-3.5a.5.5 0 00-.407-.791zM23.907 16.334l-2.5-3.5a.519.519 0 00-.814 0l-2.5 3.5a.5.5 0 00.407.791h.75a.5.5 0 01.5.5v5a1.25 1.25 0 002.5 0v-5a.5.5 0 01.5-.5h.75a.5.5 0 00.407-.791z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});