define("ember-svg-jar/inlined/nautic-sports-scooter-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>nautic-sports-scooter-1</title><path d=\"M1.653 16.111a.25.25 0 00.271-.051c.132-.127.272-.285.431-.473a3.023 3.023 0 014.6-.024c.553.655.875.937 1.375.937s.822-.281 1.356-.913a3.022 3.022 0 014.6-.024c.554.656.876.937 1.376.937s.822-.282 1.356-.914a3.029 3.029 0 013.768-.7 1 1 0 001.018-.033l1.46-.929a.5.5 0 00.236-.424 4.659 4.659 0 00-2.471-4.118L16.74 7.1a1.462 1.462 0 00-1.88.444.5.5 0 01-.408.211H14a.5.5 0 01-.474-.342l-.847-2.541a2 2 0 00-1.9-1.367H10a1 1 0 000 2h.419a.5.5 0 01.474.342l.527 1.579a.251.251 0 01-.238.329h-.5a3 3 0 00-2.628 1.558l-.636 1.166A1 1 0 016.54 11H3a1.5 1.5 0 00-1.5 1.5v3.381a.25.25 0 00.153.23zm7.96-5.481l.2-.359a1 1 0 01.877-.521h2.121a.25.25 0 01.142.456A4.494 4.494 0 0110.4 11h-.567a.25.25 0 01-.22-.37z\"/><path d=\"M23 18.5a3.745 3.745 0 01-2.9-1.646 1.056 1.056 0 00-1.546.023 3.72 3.72 0 01-2.887 1.623 3.743 3.743 0 01-2.9-1.646 1.055 1.055 0 00-1.547.024A3.72 3.72 0 018.333 18.5a3.745 3.745 0 01-2.9-1.646 1.055 1.055 0 00-1.547.024A3.718 3.718 0 011 18.5a1 1 0 000 2 5.126 5.126 0 003.346-1.243.5.5 0 01.639 0 5.128 5.128 0 006.694 0 .5.5 0 01.639 0 5.129 5.129 0 006.7 0 .5.5 0 01.639 0A5.131 5.131 0 0023 20.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});