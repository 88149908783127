define("ember-svg-jar/inlined/e-commerce-cart-monitor-keyboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-cart-monitor-keyboard</title><path d=\"M19.5 0h-15A2.3 2.3 0 002 2.5v10C2 13.949 3.262 15 5 15h5.75a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H7.036a1 1 0 000 2H17a1 1 0 000-2h-3.75a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25H19c1.738 0 3-1.051 3-2.5v-10A2.3 2.3 0 0019.5 0zm.5 12.5c0 .364-.613.5-1 .5H5c-.387 0-1-.136-1-.5v-10c0-.453.047-.5.5-.5h15c.453 0 .5.047.5.5zM20.224 19.83a1.491 1.491 0 00-1.342-.83H5.5a1.494 1.494 0 00-1.342.829l-1 2A1.5 1.5 0 004.5 24h15.382a1.506 1.506 0 001.275-.711 1.488 1.488 0 00.067-1.46zM19 21.5a.5.5 0 01-.5.5h-2a.5.5 0 010-1h2a.5.5 0 01.5.5zm-5.5 0a.5.5 0 01-.5.5h-2a.5.5 0 010-1h2a.5.5 0 01.5.5zm-5 0a.5.5 0 01-.5.5H6a.5.5 0 010-1h2a.5.5 0 01.5.5z\"/><path d=\"M16.081 2.628a.752.752 0 00-.961.449l-.3.842a.249.249 0 01-.235.164h-5.76a1.242 1.242 0 00-1.042.561 1.226 1.226 0 00-.126 1.133c.007.018 1.085 3.036 1.094 3.054a1.244 1.244 0 001.149.752h3.579a1.256 1.256 0 001.175-.822L16.53 3.59a.75.75 0 00-.449-.962z\"/><circle cx=\"10.325\" cy=\"11.083\" r=\"1\"/><circle cx=\"13.325\" cy=\"11.083\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});