define("ember-svg-jar/inlined/ab-testing-monitors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ab-testing-monitors</title><path d=\"M7.87 3.254a.73.73 0 00-.729.729v.479a.25.25 0 00.25.25h.958a.25.25 0 00.25-.25v-.479a.73.73 0 00-.729-.729z\"/><path d=\"M12.243.1H3.011a1.946 1.946 0 00-1.944 1.94v7.774a1.945 1.945 0 001.944 1.943h3.637a.25.25 0 01.25.25v.958a.25.25 0 01-.25.25H3.983a.972.972 0 100 1.944H7.87a.972.972 0 00.972-.972v-2.43a2.826 2.826 0 012.794-2.915h2.786a.25.25 0 00.25-.25V2.04C14.672.872 13.21.1 12.243.1zm-2.187 8.26a.729.729 0 11-1.457 0V6.419a.25.25 0 00-.25-.25h-.958a.25.25 0 00-.25.25v1.937a.729.729 0 11-1.458 0V3.983a2.187 2.187 0 114.373 0zM16.616 12.972h-.479a.25.25 0 00-.25.25v.958a.251.251 0 00.25.25h.479a.729.729 0 100-1.458zM16.616 15.888h-.479a.25.25 0 00-.25.25v.962a.25.25 0 00.25.25h.479a.729.729 0 100-1.457z\"/><path d=\"M18.074 20.268a.249.249 0 01.25-.25h2.787c.8 0 1.822-.653 1.822-1.458v-6.8c0-.8-1.139-1.457-1.944-1.457h-9.353a1.359 1.359 0 00-1.336 1.454v6.8a1.458 1.458 0 001.458 1.458h4.122a.249.249 0 01.25.25v1.443a.25.25 0 01-.25.25h-3.151a.972.972 0 100 1.944h8.746a.972.972 0 100-1.944h-3.151a.25.25 0 01-.25-.25zM16.616 18.8h-1.458a.729.729 0 01-.729-.729v-5.828a.729.729 0 01.729-.728h1.458A2.188 2.188 0 0118.8 13.7a2.165 2.165 0 01-.533 1.417.062.062 0 000 .081 2.167 2.167 0 01.533 1.417 2.189 2.189 0 01-2.184 2.185z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});