define("ember-svg-jar/inlined/database-flash-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-flash-alternate</title><path d=\"M21.755 5c0-3.248-5.41-5-10.5-5S.768 1.751.756 4.993v12.76c0 3.853 7.36 5.506 13.141 4.837a.976.976 0 00.851-.978V21.6a.977.977 0 00-1.087-.976c-6.012.735-10.9-1.363-10.9-2.869v-.788a.25.25 0 01.373-.218 17.238 17.238 0 007.641 1.751.966.966 0 001-1.043v-.027a1 1 0 00-.952-.929c-5.123-.09-8.069-1.89-8.069-3.001v-.787a.25.25 0 01.374-.218c2.934 1.668 7.219 1.887 9.93 1.677a.965.965 0 00.68-.368l.011-.013A.981.981 0 0012.9 12.2c-5.525.47-10.142-1.473-10.142-2.951v-.785a.25.25 0 01.373-.218A17.574 17.574 0 0011.255 10a20.259 20.259 0 006.225-.93.248.248 0 00.116-.081l.528-.66a2.092 2.092 0 011.776-.822 2.03 2.03 0 011.059.4.494.494 0 00.8-.392c-.008-.476-.006-1.247-.004-2.515zm-10.5-3c5.185 0 8.495 1.773 8.5 3v.009C19.744 6.232 16.436 8 11.255 8s-8.5-1.777-8.5-3 3.311-3 8.5-3z\"/><path d=\"M23.038 16.445A.875.875 0 0022.371 15H20.5a.25.25 0 01-.25-.25V9.5a.5.5 0 00-.89-.313l-5.9 7.37A.875.875 0 0014.121 18H16a.25.25 0 01.25.25v5.25a.5.5 0 00.335.471.474.474 0 00.165.029.5.5 0 00.39-.188z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});