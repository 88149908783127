define("ember-svg-jar/inlined/alert-diamond", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>alert-diamond</title><path d=\"M23 10.586L13.414 1a2 2 0 00-2.828 0L1 10.587a2 2 0 000 2.828L10.586 23a2 2 0 002.828 0L23 13.415a2 2 0 000-2.829zM11.068 6.417a1 1 0 012 0v6a1 1 0 01-2 0zm1.051 11.51h-.028a1.529 1.529 0 01-1.522-1.47 1.475 1.475 0 011.448-1.53h.028a1.527 1.527 0 011.522 1.47 1.475 1.475 0 01-1.448 1.53z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});