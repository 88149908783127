define("ember-svg-jar/inlined/drugs-powder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drugs-powder</title><path d=\"M7.7 18.832a2.964 2.964 0 00-4.1 0L.322 22.106a1.1 1.1 0 00.778 1.877h9.093a1.1 1.1 0 00.777-1.877z\"/><circle cx=\"10.001\" cy=\"12.483\" r=\"1.25\"/><circle cx=\"13.501\" cy=\"14.983\" r=\"1.25\"/><circle cx=\"10.001\" cy=\"15.983\" r=\"1.25\"/><circle cx=\"13.501\" cy=\"11.483\" r=\"1.25\"/><circle cx=\"6.501\" cy=\"13.983\" r=\"1.25\"/><circle cx=\"13.001\" cy=\"18.483\" r=\"1.25\"/><path d=\"M22.976 1.009a3.582 3.582 0 00-4.949 0L13.431 5.6a1 1 0 000 1.415l3.536 3.536a1 1 0 001.414 0l4.6-4.6a3.5 3.5 0 00-.005-4.942z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});