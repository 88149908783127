define("ember-svg-jar/inlined/smiley-sick-contageous-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-sick-contageous-alternate</title><path d=\"M6.553 8.9A1 1 0 007.9 8.448 2.656 2.656 0 0110 7.5a1 1 0 000-2c-1.153 0-3.142.547-3.895 2.053A1 1 0 006.553 8.9zM13 6.5a1 1 0 001 1 2.656 2.656 0 012.105.947 1 1 0 101.79-.894C17.142 6.048 15.153 5.5 14 5.5a1 1 0 00-1 1z\"/><path d=\"M24 12a12 12 0 10-12 12 12.014 12.014 0 0012-12zm-21.96.891a.25.25 0 01.322-.261l3.461 1.057a.25.25 0 01.177.239v1.325a.25.25 0 01-.25.25H2.808a.251.251 0 01-.236-.166 9.924 9.924 0 01-.532-2.444zM8 13a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v4a.5.5 0 01-.5.5h-7A.5.5 0 018 17zm10 .925a.25.25 0 01.177-.239l3.461-1.057a.25.25 0 01.322.261 9.907 9.907 0 01-.532 2.443.25.25 0 01-.236.167H18.25a.25.25 0 01-.25-.25zM12 2a10.017 10.017 0 019.846 8.245.25.25 0 01-.173.283l-3.638 1.111a.249.249 0 01-.291-.116A2 2 0 0016 10.5H8a2 2 0 00-1.744 1.022.248.248 0 01-.291.116l-3.638-1.11a.249.249 0 01-.173-.282A10.017 10.017 0 0112 2zm0 20a10 10 0 01-8.07-4.1.25.25 0 01.2-.4h1.634a.249.249 0 01.248.222A2 2 0 008 19.5h8a2 2 0 001.988-1.778.25.25 0 01.248-.222h1.632a.25.25 0 01.2.4A10 10 0 0112 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});