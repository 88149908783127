define("ember-svg-jar/inlined/crypto-currency-bitcoin-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-bubble</title><path d=\"M21.526 8.25a.248.248 0 01-.134-.324 4.079 4.079 0 00-5.3-5.3.249.249 0 01-.324-.134 4.1 4.1 0 00-7.53 0 .249.249 0 01-.324.134 4.079 4.079 0 00-5.3 5.3.25.25 0 01-.134.325 4.077 4.077 0 000 7.5.25.25 0 01.134.325 4.079 4.079 0 005.3 5.3.249.249 0 01.324.134 4.1 4.1 0 007.53 0 .249.249 0 01.324-.134 4.079 4.079 0 005.3-5.3.25.25 0 01.134-.325 4.078 4.078 0 000-7.501zM9.073 7.5a1 1 0 011-1h.6a.2.2 0 00.2-.2v-.283a1 1 0 012 0V6.3a.2.2 0 00.2.2 3.4 3.4 0 012.559 5.635.25.25 0 000 .329 3.4 3.4 0 01-2.559 5.636.2.2 0 00-.2.2v.718a1 1 0 01-2 0V18.3a.2.2 0 00-.2-.2h-.6a1 1 0 01-1-1z\"/><path d=\"M13.073 13.3h-1.75a.25.25 0 00-.25.25v2.3a.25.25 0 00.25.25h1.75a1.4 1.4 0 000-2.8zM11.323 8.5a.25.25 0 00-.25.25v2.3a.25.25 0 00.25.25h1.75a1.4 1.4 0 100-2.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});