define("ember-svg-jar/inlined/love-it-flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it-flag</title><path d=\"M23.388 2.681a1.465 1.465 0 00-1.343-.2c-4.6 1.591-6.4.954-9.91 0C9.033 1.628 6.88.871 2 2.144V1a1 1 0 00-2 0v22a1 1 0 002 0v-2.775c4.815-1.309 6.827-.572 9.871.257 4.155 1.132 6.119 1.659 11.17-.259a1.506 1.506 0 00.959-1.4V3.9a1.509 1.509 0 00-.612-1.219zM12.948 16.1a.619.619 0 01-.9 0l-3.962-4.142a2.611 2.611 0 113.693-3.692l.544.544a.25.25 0 00.354 0l.544-.544a2.611 2.611 0 113.693 3.692z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});