define("ember-svg-jar/inlined/dmg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dmg</title><path d=\"M6.456 9.82a.249.249 0 00-.331.239v3.883a.249.249 0 00.331.24A2.238 2.238 0 007.875 12a2.237 2.237 0 00-1.419-2.18z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zm-17 11.126A.625.625 0 014.875 15V9a.625.625 0 01.625-.624 3.625 3.625 0 010 7.25zM14.125 15a.625.625 0 01-1.25 0v-2.926c0-.154-.15-.134-.224.021l-.092.185a.65.65 0 01-1.118 0l-.107-.214c-.06-.122-.209-.117-.209.008V15a.625.625 0 01-1.25 0V9a.625.625 0 011.184-.279s.72 1.429.786 1.571a.173.173 0 00.311 0l.785-1.569A.625.625 0 0114.125 9zm5-4a.625.625 0 01-1.25 0v-.5a.875.875 0 10-1.75 0v3a.875.875 0 001.718.237c.01-.036.033-.112-.085-.112H17.5a.625.625 0 010-1.25h1a.625.625 0 01.625.625v.5a2.125 2.125 0 01-4.25 0v-3a2.125 2.125 0 114.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});