define("ember-svg-jar/inlined/smart-watch-circle-cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-cash</title><path d=\"M12 3.5a8.5 8.5 0 108.5 8.5A8.5 8.5 0 0012 3.5zm.909 12.458a.25.25 0 00-.2.245v.3a.75.75 0 01-1.5 0v-.25a.25.25 0 00-.25-.25h-.5a.75.75 0 110-1.5h2.034a.592.592 0 00.219-1.141l-2.062-.826a2.091 2.091 0 01.359-3.991.25.25 0 00.2-.245V8a.75.75 0 111.5 0v.25a.25.25 0 00.25.25h.5a.75.75 0 010 1.5h-2.034a.592.592 0 00-.22 1.141l2.063.825a2.092 2.092 0 01-.359 3.992zM7.379 3.051a.125.125 0 00.135.014 9.98 9.98 0 018.972 0 .125.125 0 00.177-.144l-.68-2.55A.5.5 0 0015.5 0h-7a.5.5 0 00-.483.371l-.68 2.551a.125.125 0 00.042.129z\"/><path d=\"M16.621 20.949a.125.125 0 00-.135-.015 9.972 9.972 0 01-8.972 0 .125.125 0 00-.177.144l.68 2.551A.5.5 0 008.5 24h7a.5.5 0 00.483-.371l.68-2.551a.125.125 0 00-.042-.129z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});