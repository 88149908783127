define("ember-svg-jar/inlined/baseball-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baseball-player</title><path d=\"M7.086 9.8a.249.249 0 00.195.051.254.254 0 00.169-.11l4.34-6.6A1.907 1.907 0 108.422 1.35L5.3 8.908a.25.25 0 00.234.346 2.463 2.463 0 011.552.546zM15.083 21.1a.5.5 0 01.944 0l.8 2.309a.5.5 0 00.471.335h2.143a.5.5 0 00.475-.658l-1.363-4.089v-5.743a3 3 0 00-3-3H12.3a4.5 4.5 0 00-3.183 1.318l-.573.572a.506.506 0 01-.738-.033l-1.1-1.317a1.5 1.5 0 10-2.306 1.92l1.1 1.318a3.528 3.528 0 002.747 1.262 3.443 3.443 0 002.417-1.029c.651-.651.858-.892 1.3-.974a.5.5 0 01.591.493V19l-1.362 4.086a.5.5 0 00.474.658h2.141a.5.5 0 00.473-.336z\"/><circle cx=\"15.055\" cy=\"6.254\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});