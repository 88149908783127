define("ember-svg-jar/inlined/park-bench-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>park-bench-light</title><path d=\"M8.5 9H10a2 2 0 002-2v-.838a.5.5 0 01.285-.452 3 3 0 10-2.57 0 .5.5 0 01.285.452V6.5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5 1 1 0 00-2 0 .5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-.338a.5.5 0 01.285-.452 3 3 0 10-2.57 0A.5.5 0 012 6.162V7a2 2 0 002 2h1.5a.5.5 0 01.5.5v5.235a.5.5 0 01-.265.441 1.5 1.5 0 00-.793 1.23l-.408 6.531a1 1 0 001 1.063h2.934a1 1 0 001-1.063l-.408-6.531a1.5 1.5 0 00-.793-1.23.5.5 0 01-.267-.441V9.5a.5.5 0 01.5-.5zM11 2a1 1 0 11-1 1 1 1 0 011-1zM3 4a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M23.168 16.735A1 1 0 0024 15.75v-1a1 1 0 00-1-1H12a1 1 0 00-1 1v1a1 1 0 00.832.985.5.5 0 01.418.494v.671a.5.5 0 01-.312.464 1.5 1.5 0 00-.319 2.6.5.5 0 01.194.514l-.292 1.3a1 1 0 00.759 1.194 1.02 1.02 0 00.22.028 1 1 0 00.975-.782l.352-1.577a.5.5 0 01.488-.391h6.372a.5.5 0 01.488.391l.352 1.577A1 1 0 0022.5 24a1.02 1.02 0 00.219-.024 1 1 0 00.759-1.194l-.292-1.3a.5.5 0 01.194-.514 1.5 1.5 0 00-.319-2.6.5.5 0 01-.312-.464v-.667a.5.5 0 01.419-.502zM20.75 17.75a.5.5 0 01-.5.5h-5.5a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h5.5a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});