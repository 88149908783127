define("ember-svg-jar/inlined/skateboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skateboard</title><path d=\"M23.452 7.86A5.479 5.479 0 0016.144.547a1 1 0 01-.822.022 3.852 3.852 0 00-4.16.945l-.875.875a3.886 3.886 0 00-1.046 3.837 1 1 0 01-.25 1L7.243 8.972a1 1 0 01-1 .25 3.882 3.882 0 00-3.843 1.05l-.875.875a3.859 3.859 0 00-.94 4.153 1 1 0 01-.026.82 5.48 5.48 0 007.32 7.327 1 1 0 01.824-.024 3.839 3.839 0 004.15-.947l.876-.876a3.862 3.862 0 001.049-3.837 1 1 0 01.249-1l1.746-1.745a1 1 0 011-.25 3.871 3.871 0 003.838-1.05l.875-.875a3.835 3.835 0 00.943-4.157 1 1 0 01.023-.826zM12.313 20.174l-.875.875a1.716 1.716 0 01-2.373.251 1.533 1.533 0 01-.174-1.78.5.5 0 00-.1-.575L5.052 15.2a.5.5 0 00-.573-.1 1.518 1.518 0 01-1.779-.164 1.707 1.707 0 01.252-2.373l.875-.875a1.714 1.714 0 012.373-.252 1.533 1.533 0 01.175 1.78.5.5 0 00.095.575l3.742 3.743a.5.5 0 00.574.1 1.525 1.525 0 011.779.172 1.706 1.706 0 01-.252 2.368zm8.75-8.751l-.875.875a1.716 1.716 0 01-2.373.252 1.526 1.526 0 01-.182-1.762.5.5 0 00-.1-.57L13.782 6.46a.5.5 0 00-.571-.1 1.512 1.512 0 01-1.761-.176 1.707 1.707 0 01.25-2.371l.875-.875a1.714 1.714 0 012.373-.252 1.536 1.536 0 01.166 1.8.5.5 0 00.094.578l3.73 3.734a.5.5 0 00.581.092 1.525 1.525 0 011.793.164 1.706 1.706 0 01-.249 2.369z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});