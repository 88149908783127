define("ember-svg-jar/inlined/gesture-tap-two-fingers-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-tap-two-fingers-1</title><path d=\"M8.214 10.624a3 3 0 00-3 3V21.2a.428.428 0 00.428.428h5.142a.429.429 0 00.429-.428v-7.577a3 3 0 00-2.999-2.999zm1.5 4a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1a1.5 1.5 0 013 0zM15.714 8.124a3 3 0 00-3 3V21.2a.428.428 0 00.428.428h5.142a.429.429 0 00.429-.428V11.123a3 3 0 00-2.999-2.999zm1.5 4a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1a1.5 1.5 0 113 0z\"/><path d=\"M15.714 5.374a5.729 5.729 0 00-3.159.945.75.75 0 00.824 1.253 4.252 4.252 0 016.529 4.242.75.75 0 101.48.243 5.692 5.692 0 00.076-.933 5.757 5.757 0 00-5.75-5.75z\"/><path d=\"M23.934 8.12a8.76 8.76 0 00-11.253-5.206.75.75 0 10.519 1.407 7.258 7.258 0 019.323 4.314.75.75 0 101.409-.515zM8.214 9.374a4.247 4.247 0 011.006.12.75.75 0 10.353-1.458 5.755 5.755 0 00-6.928 7.025.75.75 0 101.455-.373 4.255 4.255 0 014.114-5.314z\"/><path d=\"M8.214 6.374a7.293 7.293 0 011.569.17.75.75 0 10.323-1.464A8.759 8.759 0 00.072 10.412a.75.75 0 001.4.551 7.211 7.211 0 016.742-4.589z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});