define("ember-router-scroll/locations/router-scroll", ["exports", "@ember/routing/history-location", "@ember/object", "@ember/application/deprecations"], function (_exports, _historyLocation, _object, _deprecations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const uuid = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : r & 3 | 8;
    return v.toString(16);
  });
  class _default extends _historyLocation.default {
    init() {
      super.init(...arguments);
      (true && !(false) && (0, _deprecations.deprecate)(`Setting 'locationType' to 'router-scroll' in config/environment.js is deprecated, please change it to 'auto'. If you are overriding ember-router-scroll's implementation of "pushState" or "replaceState", then you can subclass and override a new location object from: import HistoryLocation from '@ember/routing/history-location';`, false, {
        id: 'ember-router-scroll',
        until: '2.0.0'
      }));
    }
    pushState(path) {
      const state = {
        path,
        uuid: uuid()
      };
      (0, _object.get)(this, 'history').pushState(state, null, path);
      (0, _object.set)(this, '_previousURL', this.getURL());
    }
    replaceState(path) {
      const state = {
        path,
        uuid: uuid()
      };
      (0, _object.get)(this, 'history').replaceState(state, null, path);
      (0, _object.set)(this, '_previousURL', this.getURL());
    }
  }
  _exports.default = _default;
});