define("ember-svg-jar/inlined/smart-watch-circle-target", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-target</title><path d=\"M7.379 3.051a.125.125 0 00.135.014 9.98 9.98 0 018.972 0 .125.125 0 00.177-.144l-.68-2.55A.5.5 0 0015.5 0h-7a.5.5 0 00-.483.371l-.68 2.551a.125.125 0 00.042.129zM16.621 20.949a.125.125 0 00-.135-.015 9.972 9.972 0 01-8.972 0 .125.125 0 00-.177.144l.68 2.551A.5.5 0 008.5 24h7a.5.5 0 00.483-.371l.68-2.551a.125.125 0 00-.042-.129z\"/><path d=\"M12 3.5a8.5 8.5 0 108.5 8.5A8.5 8.5 0 0012 3.5zm-.159 6.691a.656.656 0 01-.591.372h-.5a.25.25 0 00-.25.25v3.437a1 1 0 01-2 0v-3.437a.25.25 0 00-.25-.25h-.5A.657.657 0 017.238 9.5l1.395-1.747a.99.99 0 01.191-.24l.164-.2a.679.679 0 011.024 0l.164.2a.99.99 0 01.191.24L11.762 9.5a.656.656 0 01.079.691zm4.921 4.309l-1.395 1.744a1 1 0 01-.191.239l-.164.205a.656.656 0 01-1.024 0l-.164-.205a1 1 0 01-.191-.239L12.238 14.5a.656.656 0 01.512-1.066h.5a.25.25 0 00.25-.25V10.25a1 1 0 012 0v2.938a.25.25 0 00.25.25h.5a.656.656 0 01.512 1.066z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});