define("ember-svg-jar/inlined/tags-share-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-share-alternate</title><circle cx=\"17.171\" cy=\"5.999\" r=\"1.5\"/><path d=\"M23.873 13.749a2.746 2.746 0 10-5.437.539.25.25 0 01-.115.268l-2.666 1.6a.252.252 0 01-.288-.022 2.72 2.72 0 00-1.744-.634 2.75 2.75 0 102.055 4.559.252.252 0 01.281-.067l2.272.909a.25.25 0 01.157.246 2.756 2.756 0 10.68-1.706.25.25 0 01-.28.067l-2.265-.908a.25.25 0 01-.156-.253 2.122 2.122 0 00-.056-.638.25.25 0 01.115-.268l2.665-1.6a.25.25 0 01.288.022 2.722 2.722 0 001.744.634 2.752 2.752 0 002.75-2.748z\"/><path d=\"M10.281 20.87a.249.249 0 00-.182-.1.255.255 0 00-.192.073l-.029.03a1.025 1.025 0 01-1.414 0l-6.172-6.167a1 1 0 010-1.414l11-11A.993.993 0 0114 2h6.672a.5.5 0 01.5.5v6.67a.987.987 0 01-.053.321V9.5a4.228 4.228 0 011.663.339.251.251 0 00.219-.011.247.247 0 00.126-.179 2.909 2.909 0 00.039-.478V2a2 2 0 00-2-2H14a2.978 2.978 0 00-2.121.879l-11 11a3 3 0 000 4.242l6.171 6.171a3 3 0 004.242 0l.079-.08a.247.247 0 00.072-.2.252.252 0 00-.113-.184 4.262 4.262 0 01-1.049-.958z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});