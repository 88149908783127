define("ember-svg-jar/inlined/phone-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-horizontal</title><path d=\"M20.966 4H3.034a3 3 0 00-3 3v10a3 3 0 003 3h17.932a3 3 0 003-3V7a3 3 0 00-3-3zm-1.432 13a1 1 0 01-1 1h-13.5a1 1 0 01-1-1V7a1 1 0 011-1h13.5a1 1 0 011 1z\"/><path d=\"M14.783 10.7V10a2.75 2.75 0 00-5.5 0v.7a1.5 1.5 0 00-.75 1.3v3a1.5 1.5 0 001.5 1.5h4a1.5 1.5 0 001.5-1.5v-3a1.5 1.5 0 00-.75-1.3zm-1.749 2.82a1 1 0 11-1-1 1 1 0 011 1zM10.783 10a1.25 1.25 0 012.5 0v.25h-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});