define("ember-svg-jar/inlined/button-shuffle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-shuffle</title><path d=\"M.375 6.749A1.25 1.25 0 001.625 8H6.4A3.41 3.41 0 019 9.658a1.25 1.25 0 002.04-1.445A5.76 5.76 0 006.4 5.5H1.625a1.25 1.25 0 00-1.25 1.249z\"/><path d=\"M23.457 7.3a1 1 0 000-1.11l-1.909-2.859a1 1 0 00-1.832.555v1.363a.25.25 0 01-.25.25h-1.614c-3.072 0-4.958 2.963-6.782 5.829C9.609 13.624 8.1 16 6.4 16H1.625a1.25 1.25 0 000 2.5H6.4c3.072 0 4.958-2.963 6.782-5.829C14.641 10.374 16.152 8 17.852 8h1.614a.25.25 0 01.25.25v1.362a1 1 0 00.71.957.966.966 0 00.29.043 1 1 0 00.832-.445z\"/><path d=\"M20.426 21.069a.966.966 0 00.29.043 1 1 0 00.832-.445l1.909-2.867a1 1 0 000-1.11l-1.909-2.863a1 1 0 00-1.832.555v1.363a.25.25 0 01-.25.25h-1.614a4.581 4.581 0 01-3.121-1.578 1.25 1.25 0 10-1.8 1.738 6.984 6.984 0 004.917 2.34h1.614a.25.25 0 01.25.25v1.363a1 1 0 00.714.961z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});