define("ember-svg-jar/inlined/wifi-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-laptop</title><path d=\"M20.5 14.5a1 1 0 001-1v-8a3 3 0 00-3-3h-13a3 3 0 00-3 3v8a1 1 0 002 0v-8a1 1 0 011-1h13a1 1 0 011 1v8a1 1 0 001 1zM23.5 16H16a.5.5 0 00-.5.5c0 .708-1.5 1.5-3.5 1.5s-3.5-.792-3.5-1.5A.5.5 0 008 16H.5a.5.5 0 00-.474.658A7.076 7.076 0 006.74 21.5h10.52a7.076 7.076 0 006.714-4.842A.5.5 0 0023.5 16z\"/><path d=\"M10.591 14.091A1.409 1.409 0 1012 12.68a1.409 1.409 0 00-1.409 1.411zM16 9a1 1 0 00.628-1.779 7.318 7.318 0 00-9.256 0 1 1 0 001.256 1.557 5.332 5.332 0 016.744 0A1 1 0 0016 9z\"/><path d=\"M15.112 11.628a1 1 0 00-.151-1.407 4.683 4.683 0 00-5.921 0 1 1 0 101.254 1.557 2.7 2.7 0 013.412 0 1 1 0 001.406-.15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});