define("ember-svg-jar/inlined/remove-square-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>remove-square-1</title><path d=\"M.5 22A1.5 1.5 0 002 23.5h20a1.5 1.5 0 001.5-1.5V2A1.5 1.5 0 0022 .5H2A1.5 1.5 0 00.5 2zM7.05 8.465a1 1 0 111.414-1.414l3.359 3.359a.25.25 0 00.353 0l3.359-3.359a1 1 0 111.414 1.414l-3.359 3.359a.25.25 0 000 .353l3.359 3.359a1 1 0 11-1.414 1.414l-3.359-3.359a.25.25 0 00-.353 0L8.464 16.95a1 1 0 01-1.414-1.414l3.359-3.359a.25.25 0 000-.353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});