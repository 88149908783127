define("ember-svg-jar/inlined/wifi-transfer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-transfer</title><path d=\"M22.706 0h-9.885A1.32 1.32 0 0011.5 1.314v2.451a.25.25 0 00.059.161A3.979 3.979 0 0112.5 6.5v5.25a.25.25 0 00.25.25h9.957A1.291 1.291 0 0024 10.714V1.288A1.292 1.292 0 0022.706 0zm-6.568 6.909a.75.75 0 11-.938-1.167 4.432 4.432 0 015.608 0 .75.75 0 11-.942 1.167 2.944 2.944 0 00-3.728 0zM19 8.7a1 1 0 11-1-1 1 1 0 011 1zm-4.529-4.2a.75.75 0 01-.942-1.168 7.071 7.071 0 018.942 0 .75.75 0 01-.942 1.168 5.577 5.577 0 00-7.058 0zM8.5 4h-6A2.5 2.5 0 000 6.5v15A2.5 2.5 0 002.5 24h6a2.5 2.5 0 002.5-2.5v-15A2.5 2.5 0 008.5 4zm1 14.5a1 1 0 01-1 1h-6a1 1 0 01-1-1V7a1 1 0 011-1h6a1 1 0 011 1zM12.293 23.707a1 1 0 001.414 0l3.142-3.142 1.3 1.3a.5.5 0 00.851-.353V17.5a.5.5 0 00-.5-.5h-4a.5.5 0 00-.354.853l1.291 1.295-3.144 3.145a1 1 0 000 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});