define("ember-svg-jar/inlined/shop-assistant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shop-assistant</title><path d=\"M9.906 11.291a.751.751 0 00.135 1.052 3.249 3.249 0 003.918 0 .75.75 0 10-.918-1.186 1.867 1.867 0 01-2.082 0 .751.751 0 00-1.053.134z\"/><circle cx=\"14\" cy=\"9\" r=\"1\"/><circle cx=\"10\" cy=\"9\" r=\"1\"/><path d=\"M12 16a7.008 7.008 0 007-7V7A7 7 0 005 7v2a7.008 7.008 0 007 7zM7 7.25A.25.25 0 017.25 7H8c1.285 0 3.888.316 5.815-1.788a.25.25 0 01.369 0 5.281 5.281 0 002.651 1.577A.219.219 0 0117 7v2A5 5 0 017 9zM22.837 18.52a13.84 13.84 0 00-4.558-2.2 1 1 0 00-1.279.965V20.5a1 1 0 01-1 1H8a1 1 0 01-1-1v-3.215a1 1 0 00-1.279-.961 13.822 13.822 0 00-4.557 2.2A2.956 2.956 0 000 20.894V23a1 1 0 002 0v-2.106a.975.975 0 01.378-.785 9.479 9.479 0 011.254-.8.25.25 0 01.368.22v4.221a.25.25 0 00.25.25h15.5a.25.25 0 00.25-.25v-4.221a.25.25 0 01.368-.221 9.39 9.39 0 011.255.8.974.974 0 01.377.784V23a1 1 0 002 0v-2.106a2.958 2.958 0 00-1.163-2.374z\"/><path d=\"M14.341 16.27l-2.257.8a.255.255 0 01-.168 0l-2.258-.8A1.286 1.286 0 008 17.5v1.2a1.285 1.285 0 001.659 1.23l2.257-.8a.255.255 0 01.168 0l2.258.8a1.194 1.194 0 00.4.07A1.28 1.28 0 0016 18.7v-1.2a1.285 1.285 0 00-1.659-1.23z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});