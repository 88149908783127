define("ember-svg-jar/inlined/shop-sign-bag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shop-sign-bag</title><path d=\"M21.5 0a2.5 2.5 0 00-2.226 1.364.252.252 0 01-.223.136H3a3 3 0 00-3 3v9a1 1 0 002 0v-9a1 1 0 011-1h4.75a.25.25 0 01.25.25V5.5a1 1 0 002 0V3.75a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25V5.5a1 1 0 002 0V3.75a.25.25 0 01.25-.25h.82a.22.22 0 01.2.12A2.5 2.5 0 1021.5 0zM14 14a.25.25 0 00.25-.25V13a1.25 1.25 0 00-2.5 0v.75A.25.25 0 0012 14z\"/><path d=\"M21.5 10.5A2.5 2.5 0 0019 8H7a2.5 2.5 0 00-2.5 2.5v11A2.5 2.5 0 007 24h12a2.5 2.5 0 002.5-2.5zm-4.269 4.813l.748 4.663A1.753 1.753 0 0116.246 22H9.754A1.756 1.756 0 018 20.246a1.706 1.706 0 01.021-.267l.748-4.668a1.539 1.539 0 011.272-1.29.249.249 0 00.209-.246V13a2.75 2.75 0 015.5 0v.775a.249.249 0 00.209.246 1.539 1.539 0 011.272 1.292z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});