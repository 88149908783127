define("ember-svg-jar/inlined/monetization-touch-browser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monetization-touch-browser</title><path d=\"M14 21.161a.25.25 0 01-.438.165L12 19.543a1.5 1.5 0 00-2.309 1.916l.008.009 2.229 2.456a.246.246 0 00.185.082H23.75a.25.25 0 00.25-.25V22.5a4 4 0 00-4-4h-2.75a.25.25 0 01-.25-.25V14a1.5 1.5 0 00-3 0z\"/><path d=\"M22 3a3 3 0 00-3-3H3a3 3 0 00-3 3v11.5a3.021 3.021 0 003 3h1a1 1 0 000-2H3a1 1 0 01-1-1V5.25A.249.249 0 012.25 5h17.5a.249.249 0 01.25.25v9.25a1 1 0 002 0zM8.75 2a.75.75 0 11-.75.75.749.749 0 01.75-.75zm-2.5 0a.75.75 0 11-.75.75.749.749 0 01.75-.75zm-2.5 0a.75.75 0 11-.75.75.749.749 0 01.75-.75z\"/><path d=\"M10.388 16.676v-.214a.25.25 0 01.189-.242 2.471 2.471 0 00.317-4.693l-2.27-.909a.471.471 0 01-.3-.441A.476.476 0 018.8 9.7h2.236a1 1 0 000-2h-.4a.25.25 0 01-.25-.25v-.124a1 1 0 10-2 0v.214a.248.248 0 01-.189.242 2.471 2.471 0 00-.317 4.694l2.269.908a.475.475 0 01-.176.917H7.737a1 1 0 000 2h.4a.25.25 0 01.25.25v.125a1 1 0 002 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});