define("ember-svg-jar/inlined/file-code-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-shield</title><path d=\"M17.75 11.5V15a.75.75 0 001.5 0v-3.5a.75.75 0 10-1.5 0zM13.75 5a.75.75 0 00-1.5 0v3.5a.75.75 0 001.5 0zM20.25 8V5.5A1.251 1.251 0 0019 4.251h-1.5A1.251 1.251 0 0016.25 5.5V8a1.252 1.252 0 001.25 1.25H19A1.252 1.252 0 0020.25 8zm-1.5-.5a.25.25 0 01-.25.25H18a.25.25 0 01-.25-.25V6a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25z\"/><path d=\"M23.414 3L21 .587A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.267a.248.248 0 00.244.25c.368.005 1.045.021 1.5.048A.247.247 0 008 9.315V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.121a.5.5 0 01.146.355V18a.5.5 0 01-.5.5h-7.01a.242.242 0 00-.235.184 8.179 8.179 0 01-.53 1.459.245.245 0 00.218.356H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M12.072 12.041A16.381 16.381 0 006.479 11a15.983 15.983 0 00-5.551 1.04A1.545 1.545 0 000 13.457v3.392a7.716 7.716 0 005.19 6.806A9 9 0 006.479 24a9.023 9.023 0 001.288-.346C10.848 22.481 13 19.682 13 16.849v-3.392a1.545 1.545 0 00-.928-1.416zM9.5 17.251h-2a.25.25 0 00-.25.25v2a.75.75 0 01-1.5 0v-2a.25.25 0 00-.25-.25h-2a.75.75 0 110-1.5h2a.25.25 0 00.25-.25v-2a.75.75 0 111.5 0v2a.25.25 0 00.25.25h2a.75.75 0 110 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});