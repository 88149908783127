define("ember-svg-jar/inlined/computer-chip-64", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>computer-chip-64</title><path d=\"M2.435 11H1a1 1 0 000 2h1.435a1 1 0 100-2zM2.435 16H1a1 1 0 000 2h1.435a1 1 0 000-2zM2.435 6.5H1a1 1 0 100 2h1.435a1 1 0 100-2zM24 12a1 1 0 00-1-1h-1.435a1 1 0 000 2H23a1 1 0 001-1zM20.565 7.5a1 1 0 001 1H23a1 1 0 000-2h-1.435a1 1 0 00-1 1zM23 16h-1.435a1 1 0 000 2H23a1 1 0 000-2zM12 3.435a1 1 0 001-1V1a1 1 0 00-2 0v1.435a1 1 0 001 1zM7.5 3.435a1 1 0 001-1V1a1 1 0 10-2 0v1.435a1 1 0 001 1zM16.5 3.435a1 1 0 001-1V1a1 1 0 00-2 0v1.435a1 1 0 001 1zM12 20.566a1 1 0 00-1 1V23a1 1 0 102 0v-1.434a1 1 0 00-1-1zM16.5 20.566a1 1 0 00-1 1V23a1 1 0 102 0v-1.434a1 1 0 00-1-1zM7.5 20.566a1 1 0 00-1 1V23a1 1 0 002 0v-1.434a1 1 0 00-1-1zM19.5 5.934A1.436 1.436 0 0018.066 4.5H5.934A1.436 1.436 0 004.5 5.934v12.133A1.437 1.437 0 005.934 19.5h12.132a1.437 1.437 0 001.434-1.433zM6.75 11.513a2.755 2.755 0 012.742-2.762h.332a.75.75 0 010 1.5h-.332a1.181 1.181 0 00-.849.359.25.25 0 00.219.422A2.5 2.5 0 116.75 13.5zM17.25 15a.75.75 0 01-1.5 0v-.329a.25.25 0 00-.25-.25h-2a.75.75 0 01-.75-.75V13a3.945 3.945 0 013.75-4.1.75.75 0 01.75.75z\"/><circle cx=\"9.25\" cy=\"13.501\" r=\"1\"/><path d=\"M14.271 12.642a.251.251 0 00.248.28h.981a.25.25 0 00.25-.25v-1.71a.25.25 0 00-.391-.206 2.655 2.655 0 00-1.088 1.886z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});