define("ember-svg-jar/inlined/style-three-pin-police-badge-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-police-badge-1</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM6.606 7.2a1 1 0 01-.049-1.462l1.35-1.35a.991.991 0 011.1-.212L11.9 5.414a.247.247 0 00.2 0l2.894-1.24a.992.992 0 011.1.212l1.35 1.35a1 1 0 01-.05 1.464 2.817 2.817 0 00-.568.68.25.25 0 01-.215.12H7.389a.252.252 0 01-.215-.122 2.817 2.817 0 00-.568-.678zM12 16.5c-1.751 0-5.5-2.511-5.5-4a3.668 3.668 0 01.473-1.6 5.3 5.3 0 00.45-1.2.248.248 0 01.244-.2h8.666a.248.248 0 01.244.2 5.3 5.3 0 00.45 1.2 3.668 3.668 0 01.473 1.6c0 1.489-3.749 4-5.5 4z\"/><path d=\"M12 10.5a.75.75 0 00-.75.75v2a.75.75 0 001.5 0v-2a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});