define("ember-svg-jar/inlined/coffee-machine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>coffee-machine</title><path d=\"M19 9.5a.971.971 0 00-.846.5.5.5 0 01-.434.252H15.4a.5.5 0 01-.458-.3.751.751 0 00-.688-.45H9.75a.75.75 0 00-.75.75v1.5A1.252 1.252 0 0010.25 13h3.5a1.252 1.252 0 001.2-.893.5.5 0 01.479-.357h2.311a.471.471 0 01.41.241.972.972 0 00.85.509h3.5a1.5 1.5 0 000-3z\"/><path d=\"M22.5 19a.5.5 0 01-.5-.5V15a1 1 0 00-2 0v3.5a.5.5 0 01-.5.5h-4.167a.5.5 0 01-.369-.837A3.935 3.935 0 0016 15.5V15a.5.5 0 00-.5-.5h-7a.5.5 0 00-.5.5v.5a3.935 3.935 0 001.036 2.663.5.5 0 01-.37.837H4.5a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h3.185a2 2 0 011.628.837l1.037 1.454a.5.5 0 00.407.209h2.486a.5.5 0 00.406-.209l1.039-1.454A2 2 0 0116.315 6H19.5a.5.5 0 01.5.5V7a1 1 0 002 0v-.5a.5.5 0 01.5-.5 1 1 0 001-1V1a1 1 0 00-1-1h-21a1 1 0 00-1 1v4a1 1 0 001 1 .5.5 0 01.5.5v12a.5.5 0 01-.5.5A1.5 1.5 0 000 20.5v2A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-2a1.5 1.5 0 00-1.5-1.5zM20.25 2.5a.75.75 0 11-.75.75.75.75 0 01.75-.75zm-2.5 0a.75.75 0 11-.75.75.75.75 0 01.75-.75zM3.5 3a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});