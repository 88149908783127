define("ember-svg-jar/inlined/folder-search-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-search-alternate</title><path d=\"M23.69 22.276l-2.505-2.5a.243.243 0 01-.031-.3 5.514 5.514 0 10-1.688 1.688.243.243 0 01.3.031l2.5 2.505a1 1 0 001.414-1.414zm-7.173-2.259a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5z\"/><path d=\"M2.517 2.017H7.09a.5.5 0 01.447.276l.81 1.619a2 2 0 001.788 1.105h8.882a.5.5 0 01.5.5v4.536a.24.24 0 00.135.216 7.055 7.055 0 011.445.964.25.25 0 00.42-.181V5.017a2 2 0 00-2-2h-9.073A.5.5 0 0110 2.74l-.81-1.618A2 2 0 007.4.017H2.017a2 2 0 00-2 2v14.5a2 2 0 002 2h7.475a.243.243 0 00.235-.308 6.955 6.955 0 01-.206-1.453.244.244 0 00-.242-.239H2.517a.5.5 0 01-.5-.5v-13.5a.5.5 0 01.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});