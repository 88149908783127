define("ember-svg-jar/inlined/bag-handle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bag-handle</title><path d=\"M15.447 4.894a.246.246 0 00.111.167.251.251 0 00.2.033 2.99 2.99 0 011.4-.022.249.249 0 00.223-.059.253.253 0 00.08-.217 5.5 5.5 0 00-10.91 0 .25.25 0 00.3.276 2.99 2.99 0 011.4.022.25.25 0 00.309-.2 3.5 3.5 0 016.894 0zM23.991 20.254l-.571-9A3.512 3.512 0 0019.929 8h-1.492a.5.5 0 01-.484-.375 1.5 1.5 0 00-2.906 0 .5.5 0 01-.484.375H9.437a.5.5 0 01-.484-.375 1.5 1.5 0 00-2.906 0A.5.5 0 015.563 8H4.071A3.514 3.514 0 00.58 11.254l-.571 9A3.5 3.5 0 003.5 24H6a.5.5 0 00.5-.5V10a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v13.5a.5.5 0 00.5.5h6a.5.5 0 00.5-.5V10a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v13.5a.5.5 0 00.5.5h2.5a3.5 3.5 0 003.491-3.746z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});