define("ember-svg-jar/inlined/casino-player-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>casino-player-woman</title><path d=\"M10.407 13.873a.749.749 0 00.134 1.052 2.409 2.409 0 002.918 0 .749.749 0 10-.918-1.185.977.977 0 01-1.082 0 .751.751 0 00-1.052.133z\"/><circle cx=\"14\" cy=\"11.75\" r=\"1\"/><circle cx=\"10\" cy=\"11.75\" r=\"1\"/><path d=\"M7.752 19.036a1 1 0 00-1.229-.916 13.605 13.605 0 00-5.41 2.48A3.014 3.014 0 000 23a.985.985 0 001 .982 1.015 1.015 0 001-1.018 1 1 0 01.361-.8 8.909 8.909 0 011.206-.806.245.245 0 01.216-.014.25.25 0 01.146.16 17.987 17.987 0 01.471 2.081.5.5 0 00.495.415h2.9a1 1 0 00.78-1.625c-.586-.727-.722-1.575-.823-3.339zM22.887 20.6a13.605 13.605 0 00-5.41-2.484 1 1 0 00-1.229.916c-.1 1.759-.237 2.612-.819 3.339a1 1 0 00.78 1.629H19.1a.5.5 0 00.493-.415 17.987 17.987 0 01.474-2.078.25.25 0 01.146-.16.245.245 0 01.216.014 8.909 8.909 0 011.206.806 1 1 0 01.361.8 1.015 1.015 0 001 1.018A.985.985 0 0024 23a3.014 3.014 0 00-1.113-2.4zM14.538 18.777a.375.375 0 00-.354.041l-1.966.951a.5.5 0 01-.436 0l-1.966-.951a.375.375 0 00-.354-.041.46.46 0 00-.259.285 3.912 3.912 0 000 2.489.466.466 0 00.259.285.377.377 0 00.354-.041l1.966-.951a.5.5 0 01.436 0l1.966.951a.377.377 0 00.354.041.466.466 0 00.259-.285 3.912 3.912 0 000-2.489.46.46 0 00-.259-.285zM12 18.5a7.008 7.008 0 007-7v-2a7.008 7.008 0 00-4.2-6.416.5.5 0 01-.3-.48 2.5 2.5 0 10-5 0 .5.5 0 01-.3.48A7.008 7.008 0 005 9.5v2a7.008 7.008 0 007 7zm-5-7.985a.25.25 0 01.247-.25 6.986 6.986 0 004.439-1.4.5.5 0 01.627 0 6.99 6.99 0 004.44 1.4.25.25 0 01.247.25v.985a5 5 0 01-10 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});