define("ember-svg-jar/inlined/data-file-bars-cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-bars-cash</title><rect x=\"14.5\" y=\"5.5\" width=\"2\" height=\"8\" rx=\".5\" ry=\".5\"/><rect x=\"18\" y=\"7.5\" width=\"2\" height=\"6\" rx=\".5\" ry=\".5\"/><path d=\"M14.4 18.711a7.956 7.956 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.414A2 2 0 0023.414 3L21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.275a.248.248 0 00.242.25c.366.007 1.038.034 1.485.082A.245.245 0 008 9.359V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.245.245 0 00-.242.211z\"/><path d=\"M11.5 10a.5.5 0 00-.5.5v.229a.325.325 0 00.156.271 8.012 8.012 0 011.41 1.3.25.25 0 00.434-.172V10.5a.5.5 0 00-.5-.5zM6.5 24A6.5 6.5 0 100 17.5 6.508 6.508 0 006.5 24zm-.909-10.2v-.008a.191.191 0 00.164-.2v-.35a.75.75 0 011.5 0v.335a.171.171 0 00.169.165H8a.75.75 0 010 1.5H5.967a.592.592 0 00-.22 1.141l2.063.826a2.079 2.079 0 01-.423 3.991.184.184 0 00-.137.2v.354a.75.75 0 01-1.5 0v-.334a.17.17 0 00-.164-.166H5a.75.75 0 010-1.5h2.033a.592.592 0 00.22-1.141l-2.063-.83a2.079 2.079 0 01.4-3.98z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});