define("ember-svg-jar/inlined/sd-card-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sd-card-refresh</title><path d=\"M9.011 17.5A8.006 8.006 0 0115.8 9.6a.251.251 0 00.213-.247V4.915a1.508 1.508 0 00-.44-1.061L12.157.44A1.49 1.49 0 0011.1 0H1.511a1.5 1.5 0 00-1.5 1.5V9a.5.5 0 00.5.5h.75a.25.25 0 01.25.25v2a.25.25 0 01-.25.25h-.75a.5.5 0 00-.5.5V20a1.5 1.5 0 001.5 1.5h8.161a.251.251 0 00.222-.365 7.912 7.912 0 01-.883-3.635zm-.75-14v4a.75.75 0 01-1.5 0v-4a.75.75 0 011.5 0zm-3.5 0v4a.75.75 0 01-1.5 0v-4a.75.75 0 011.5 0zm7 4a.75.75 0 11-1.5 0v-4a.75.75 0 111.5 0z\"/><path d=\"M17.136 11.251A6.375 6.375 0 1022.118 21.6a1 1 0 00-1.562-1.248 4.375 4.375 0 11-.306-5.8l-1.571 1.572a.5.5 0 00.353.853h4.457a.5.5 0 00.5-.5v-4.455a.5.5 0 00-.853-.353l-1.472 1.472a6.327 6.327 0 00-4.528-1.89z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});