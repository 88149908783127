define("ember-svg-jar/inlined/stairs-circular", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>stairs-circular</title><path d=\"M23.75.5a.5.5 0 00-.5-.5h-11.5a.5.5 0 00-.5.5v23a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-11a.5.5 0 01.5-.5h4a1.5 1.5 0 001.5-1.5V8a.5.5 0 01.5-.5h.5a1.5 1.5 0 001.5-1.5V3.436a.5.5 0 01.375-.483A1.5 1.5 0 0023.75 1.5zm-5.5 8a.5.5 0 01-.5.5h-3.5a.5.5 0 01-.5-.5V8a.5.5 0 01.5-.5h3.5a.5.5 0 01.5.5zm2.5-4.5a.5.5 0 01-.5.5h-6a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h6a.5.5 0 01.5.5zM3.25 18a1.5 1.5 0 001.5 1.5h5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-4.5a.5.5 0 01-.5-.5v-5.554a.5.5 0 01.168-.373c.3-.268.61-.525.931-.769a.25.25 0 01.4.2v4A1.5 1.5 0 007.75 15h2a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-1.5a.5.5 0 01-.5-.5V8.387a.5.5 0 01.272-.445 15.222 15.222 0 011.894-.812.5.5 0 00.334-.472v-.722a.37.37 0 00-.484-.352 16.914 16.914 0 00-9.422 7.8 1.73 1.73 0 00-.094.363V22.5a1.5 1.5 0 001.5 1.5h8a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-7.5a.5.5 0 01-.5-.5v-6.406a.493.493 0 01.068-.251 15.858 15.858 0 01.982-1.492.25.25 0 01.451.149z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});