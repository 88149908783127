define("ember-svg-jar/inlined/layers-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layers-lock</title><path d=\"M22.5 15.5a.25.25 0 01-.25-.25v-1a3.25 3.25 0 00-6.5 0v1a.25.25 0 01-.25.25A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.5-1.5zM20 20.25a1 1 0 11-1-1 1 1 0 011 1zm-2.75-6a1.75 1.75 0 013.5 0v1a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25zM7.5 7.5A4.5 4.5 0 1012 3a4.505 4.505 0 00-4.5 4.5zM12 6a1.5 1.5 0 00-1.5 1.5.5.5 0 11-1 0A2.5 2.5 0 0112 5a.5.5 0 110 1z\"/><path d=\"M14.49 13.757a1 1 0 00-1.129-.853c-3.852.541-7.615-1.184-11.07-5.069a.5.5 0 010-.664C5.338 3.739 8.6 2 12 2s6.662 1.738 9.711 5.166a.5.5 0 010 .666c-.133.149-.281.308-.441.477a1 1 0 101.445 1.382c.317-.331.583-.633.8-.89a1.988 1.988 0 000-2.6C19.118 1.075 14.827 0 12 0S4.881 1.076.486 6.2a1.99 1.99 0 000 2.6C4.881 13.925 9.17 15 12 15a12.08 12.08 0 001.641-.114 1 1 0 00.849-1.129z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});