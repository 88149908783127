define("ember-svg-jar/inlined/american-football-run-ball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>american-football-run-ball</title><circle cx=\"11.9\" cy=\"2.5\" r=\"2.5\"/><path d=\"M17.529 5.092a.25.25 0 00-.229.408 2.033 2.033 0 01.453 1.193c.02.447-.043 2.039-.044 2.134a.7.7 0 01-.376.637l-1.662.638a1.5 1.5 0 00-.863 1.938 1.524 1.524 0 001.941.861l1.754-.675a3.759 3.759 0 001.967-4.739 3.8 3.8 0 00-2.941-2.395z\"/><path d=\"M21.877 16.139a1.5 1.5 0 00-1.953-2.278L18 15.515a1.5 1.5 0 01-1.693.177l-2.776-1.512a.248.248 0 01-.131-.219v-1a.25.25 0 00-.267-.25c-.05 0-.1.006-.148.006a2.041 2.041 0 01-2.041-1.954 6.723 6.723 0 01.509-3 .25.25 0 00-.329-.331l-3.4 1.482A2.5 2.5 0 015.75 8.89l-2.038-1a1.5 1.5 0 00-1.219 2.743l2.039 1a5.467 5.467 0 002.22.468 5.647 5.647 0 002.17-.436l1.48-.675v3.092l-2.513 1.247A4.473 4.473 0 005.4 19.354V22.5a1.5 1.5 0 103 0v-3.146a1.492 1.492 0 01.829-1.341l2.641-1.321 2.994 1.633a4.519 4.519 0 005.084-.533z\"/><path d=\"M13.925 10.483a2.489 2.489 0 011.39-1.317l.518-.2a.246.246 0 00.146-.15 5.562 5.562 0 00.276-2.057.544.544 0 00-.651-.51 4.7 4.7 0 00-2.761 1.5 4.7 4.7 0 00-.8 3.04.545.545 0 00.543.522.5.5 0 00.108-.011 6.316 6.316 0 00.949-.278.247.247 0 00.154-.171 2.537 2.537 0 01.128-.368z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});