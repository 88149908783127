define("ember-svg-jar/inlined/gesture-two-finger-flip-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-two-finger-flip-left</title><path d=\"M15.039 12.069a.25.25 0 01-.14-.293l1.247-4.657a.93.93 0 00-1.8-.454l-1.43 4.592a.25.25 0 01-.3.168l-.584-.143a.25.25 0 01-.19-.243V6.482a1 1 0 00-2 0v7.062a.25.25 0 01-.456.143c-1.489-2.138-3.855-1.449-2.761.223l2.912 4.367a.5.5 0 00.416.223h5.434a.5.5 0 00.492-.411c.2-1.1.665-3.683.776-4.165.252-1.094-.662-1.424-1.616-1.855zM8.038 19.471a.75.75 0 00-1.348-.094l-.233.408a.249.249 0 01-.415.029 9.533 9.533 0 01-1.432-2.863 1 1 0 10-1.9.617 10.452 10.452 0 002.4 4.211.25.25 0 01.041.3l-.455.8a.751.751 0 00.889 1.083l3-1a.75.75 0 00.459-.989zM21.28 5.908a10 10 0 00-2.219-3.681.25.25 0 01-.041-.3l.458-.8a.751.751 0 00-.89-1.083l-2.985 1a.751.751 0 00-.459.986l.985 2.5a.75.75 0 001.349.1l.236-.414a.251.251 0 01.416-.028A9.18 9.18 0 0119.4 6.6a1 1 0 001.878-.687z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});