define("ember-svg-jar/inlined/database-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-remove</title><path d=\"M8.7 21.391a.247.247 0 00.23-.106.25.25 0 00.019-.253 7.959 7.959 0 01-.8-3 .25.25 0 00-.217-.231 12.8 12.8 0 01-6.146-2.211.25.25 0 00-.408.194V17.5c0 1.894 3.128 3.477 7.322 3.891zM1.786 11.09a.25.25 0 00-.408.194V13c0 1.771 2.734 3.271 6.52 3.8a.245.245 0 00.188-.05.251.251 0 00.095-.169 7.905 7.905 0 01.875-2.807.253.253 0 000-.236.25.25 0 00-.2-.13c-2.822-.258-5.505-1.044-7.07-2.318zM10.879 9c-3.5 0-7.156-.833-9.093-2.411a.25.25 0 00-.408.194V8.5c0 2.053 3.671 3.744 8.4 3.973a.249.249 0 00.2-.089 7.957 7.957 0 019.308-2.222.251.251 0 00.272-.048 2.264 2.264 0 00.82-1.614V6.785a.25.25 0 00-.408-.194C18.033 8.168 14.383 9 10.879 9z\"/><ellipse cx=\"10.879\" cy=\"4\" rx=\"9.5\" ry=\"4\"/><path d=\"M16.122 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm1.591 9.151L16.3 18.737a.252.252 0 00-.354 0l-1.414 1.414a.75.75 0 11-1.06-1.06l1.414-1.414a.252.252 0 000-.354l-1.414-1.414a.75.75 0 011.06-1.06l1.414 1.414a.252.252 0 00.354 0l1.414-1.414a.75.75 0 111.06 1.06l-1.414 1.414a.252.252 0 000 .354l1.414 1.414a.75.75 0 11-1.06 1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});