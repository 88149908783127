define("ember-svg-jar/inlined/day-sunrise-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>day-sunrise-1</title><path d=\"M7.456 15.4l.59-.786a.251.251 0 000-.3 5 5 0 117.913-.014.251.251 0 000 .3l.6.8a2.284 2.284 0 01.254.432.251.251 0 00.19.145.248.248 0 00.225-.081 7 7 0 10-10.446.018.25.25 0 00.415-.066 2.255 2.255 0 01.26-.446zM1.744 12.25h1.5a1 1 0 000-2h-1.5a1 1 0 000 2z\"/><path d=\"M5.1 5.77a1 1 0 101.414-1.415L5.453 3.3a1 1 0 00-1.414 1.409zM11.994 3.5a1 1 0 001-1V1a1 1 0 00-2 0v1.5a1 1 0 001 1zM4.039 19.2a1 1 0 001.414 0l1.061-1.06A1 1 0 105.1 16.73l-1.061 1.061a1 1 0 000 1.409zM19.744 11.25a1 1 0 001 1h1.5a1 1 0 000-2h-1.5a1 1 0 00-1 1zM18.182 6.063a1 1 0 00.707-.293l1.06-1.061A1 1 0 1018.535 3.3l-1.06 1.06a1 1 0 00.707 1.708zM18.889 16.73a1 1 0 00-1.414 1.415l1.06 1.06a1 1 0 001.414-1.414zM23.994 23a1 1 0 00-1-1H1.006a1 1 0 000 2h21.988a1 1 0 001-1zM13.006 17.75a.25.25 0 01.25-.25h1.5a.749.749 0 00.6-1.2L12.6 12.631a.772.772 0 00-1.2 0L8.655 16.3a.75.75 0 00.6 1.2h1.5a.25.25 0 01.25.25V20a1 1 0 002 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});