define("ember-svg-jar/inlined/professions-man-magician", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-magician</title><path d=\"M21.026 18.27l-4.061-1.026a.252.252 0 00-.233.06A7.654 7.654 0 0112 19.5a7.662 7.662 0 01-4.736-2.2.251.251 0 00-.233-.06L2.92 18.287A4.519 4.519 0 000 22.5v1a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-1a4.531 4.531 0 00-2.974-4.23zM9 20.5a.5.5 0 01.686-.464l2.314.925 2.314-.925A.5.5 0 0115 20.5v2a.5.5 0 01-.5.5.5.5 0 01-.186-.036L12 22.039l-2.314.925A.5.5 0 019.5 23a.5.5 0 01-.5-.5z\"/><path d=\"M12 18.25c1.661 0 5.163-1.944 5.672-4.992a.251.251 0 01.142-.187 2.728 2.728 0 001.435-2.431 1.887 1.887 0 00-.931-1.81.75.75 0 00-.673 1.34.625.625 0 01.105.44 1.281 1.281 0 01-.557 1.1 1.752 1.752 0 00-1 1.306C15.816 15.26 13.029 16.75 12 16.75s-3.816-1.49-4.191-3.738a1.752 1.752 0 00-1-1.306 1.281 1.281 0 01-.557-1.1.625.625 0 01.105-.44.75.75 0 00-.673-1.34 1.887 1.887 0 00-.931 1.81 2.728 2.728 0 001.435 2.431.251.251 0 01.142.188c.507 3.051 4.009 4.995 5.67 4.995zM12 8.48a49.211 49.211 0 0010.108-1.1.5.5 0 10-.216-.976 43.406 43.406 0 01-3.105.56.247.247 0 01-.2-.058.25.25 0 01-.087-.189V1.5A1.5 1.5 0 0017 0H7a1.5 1.5 0 00-1.5 1.5v5.215a.25.25 0 01-.287.247 42.773 42.773 0 01-3.1-.56.5.5 0 00-.216.976A49.211 49.211 0 0012 8.48z\"/><path d=\"M10.092 13.631a.751.751 0 00.3 1.016 2.913 2.913 0 00.511.211 3.51 3.51 0 001.018.148h.006a3.5 3.5 0 001.013-.147 2.754 2.754 0 00.512-.213.75.75 0 10-.715-1.318 1.342 1.342 0 01-.236.1 1.986 1.986 0 01-.574.081 2 2 0 01-.581-.082 1.331 1.331 0 01-.239-.1.75.75 0 00-1.015.304zM8.8 11.274a.751.751 0 00.5-.192.63.63 0 01.626 0 .75.75 0 001-1.114 2.1 2.1 0 00-2.636 0 .75.75 0 00.5 1.306zM13.369 11.274a.748.748 0 00.5-.192.63.63 0 01.626 0 .75.75 0 101-1.114 2.1 2.1 0 00-2.636 0 .75.75 0 00.5 1.306z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});