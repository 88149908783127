define("ember-svg-jar/inlined/dating-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dating-calendar</title><path d=\"M12.676 12.441l-.322.322a.5.5 0 01-.708 0l-.322-.322A2.449 2.449 0 107.861 15.9l3.778 3.942a.5.5 0 00.722 0L16.14 15.9a2.449 2.449 0 00-3.464-3.463z\"/><path d=\"M22 3h-3a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v4.75a.75.75 0 01-1.5 0V3.5a.5.5 0 00-.5-.5h-6a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v4.75a.75.75 0 01-1.5 0V3.5A.5.5 0 004 3H2a2 2 0 00-2 2v17a2 2 0 002 2h20a2 2 0 002-2V5a2 2 0 00-2-2zm0 18.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});