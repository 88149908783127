define("ember-svg-jar/inlined/people-woman-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-3</title><circle cx=\"9.75\" cy=\"19.501\" r=\"1\"/><circle cx=\"14.25\" cy=\"19.501\" r=\"1\"/><circle cx=\"12.021\" cy=\"21.001\" r=\"1\"/><path d=\"M5.872 19.892a.235.235 0 01-.045-.219.246.246 0 01.16-.16l1.11-.358a.714.714 0 00.472-.912.757.757 0 00-.949-.454L3.083 18.93A4.6 4.6 0 000 23.206a.705.705 0 00.359.6A.5.5 0 00.75 24h7.5a.5.5 0 00.45-.272.465.465 0 00-.059-.509zM20.893 18.922l-3.513-1.133a.757.757 0 00-.949.454.714.714 0 00.472.912l1.11.358a.246.246 0 01.16.16.233.233 0 01-.045.218l-2.769 3.328a.465.465 0 00-.059.509.5.5 0 00.45.272h7.5a.5.5 0 00.391-.194.705.705 0 00.359-.6 4.6 4.6 0 00-3.107-4.284zM6.978 16.661a2.077 2.077 0 001.216-.984 6.583 6.583 0 003.827 1.823 6.592 6.592 0 003.851-1.849 2.08 2.08 0 001.228 1.01 4.751 4.751 0 004.364-1.561.5.5 0 00-.231-.8c-1.281-.412-1.932-1.869-2.265-3.156a3.156 3.156 0 00.3-1.255 2.127 2.127 0 00-.462-1.444C19.322 4.766 18.558 0 12.021 0S4.72 4.764 5.234 8.446a2.131 2.131 0 00-.463 1.444A3.15 3.15 0 005.1 11.2c-.337 1.274-.989 2.7-2.251 3.1a.5.5 0 00-.231.8 4.748 4.748 0 004.36 1.561zm-.2-8.034a13.109 13.109 0 01-.169-1.607.25.25 0 01.249-.261 6.2 6.2 0 004.956-1.995.252.252 0 01.372 0 6.272 6.272 0 005 2 .25.25 0 01.249.261 13.129 13.129 0 01-.171 1.6.749.749 0 00.4.793.641.641 0 01.105.441 1.277 1.277 0 01-.558 1.095 1.75 1.75 0 00-1 1.306c-.375 2.251-3.162 3.74-4.19 3.74S8.2 14.511 7.83 12.263a1.75 1.75 0 00-1-1.307 1.281 1.281 0 01-.557-1.1.627.627 0 01.105-.439.752.752 0 00.402-.79z\"/><path d=\"M10.147 12.879a.751.751 0 00.3 1.017 3.436 3.436 0 003.062 0 .75.75 0 00-.715-1.318 1.311 1.311 0 01-.236.1 2.138 2.138 0 01-1.158 0 1.262 1.262 0 01-.238-.1.75.75 0 00-1.015.301zM9.437 9.829a.635.635 0 01.626 0 .75.75 0 101-1.114 2.1 2.1 0 00-2.634 0 .75.75 0 101 1.114zM14 9.829a.637.637 0 01.627 0 .75.75 0 101-1.114 2.1 2.1 0 00-2.635 0 .75.75 0 101 1.114z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});