define("ember-svg-jar/inlined/3d-print-tshirt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>3d-print-tshirt</title><path d=\"M9 18.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-3.6a.25.25 0 01.389-.208l.334.223a.5.5 0 00.724-.192l1-2a.5.5 0 00-.127-.608l-3-2.5a.5.5 0 00-.674.03A2.525 2.525 0 0112 10.5a2.525 2.525 0 01-1.646-.854.5.5 0 00-.674-.03l-3 2.5a.5.5 0 00-.127.608l1 2a.5.5 0 00.319.259.492.492 0 00.4-.067l.334-.223A.25.25 0 019 14.9z\"/><path d=\"M24 2.5a1 1 0 00-1-1h-8.25a.25.25 0 01-.25-.25V.5A.5.5 0 0014 0h-4a.5.5 0 00-.5.5v.75a.25.25 0 01-.25.25H1a1 1 0 000 2h8.565a.249.249 0 01.221.133 2.506 2.506 0 001.077 1.058.251.251 0 01.137.223v.054a.093.093 0 01-.083.093 9.495 9.495 0 1010.476 8.011 1 1 0 00-1.977.3A7.5 7.5 0 1112 7a1 1 0 001-1V4.914a.251.251 0 01.137-.223 2.511 2.511 0 001.077-1.058.249.249 0 01.221-.133H23a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});