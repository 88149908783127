define("ember-svg-jar/inlined/instrument-drums", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>instrument-drums</title><path d=\"M12.221 13.526a.25.25 0 00-.442 0l-2.96 5.607a.25.25 0 00.221.367h5.92a.25.25 0 00.221-.367zM10.431 12.867a.25.25 0 00-.221-.367H4.29a.25.25 0 00-.221.367l2.96 5.607a.25.25 0 00.442 0zM19.931 12.867a.25.25 0 00-.221-.367h-5.92a.25.25 0 00-.221.367l2.96 5.607a.25.25 0 00.442 0zM18.319 19.133a.25.25 0 00.221.367h3.21a.25.25 0 00.25-.25v-6.082a.25.25 0 00-.471-.116zM5.46 19.5a.25.25 0 00.221-.367l-3.21-6.081a.25.25 0 00-.471.116v6.082a.25.25 0 00.25.25zM22.5 11a1.5 1.5 0 000-3h-21a1.5 1.5 0 000 3zM22.5 21h-21a1.5 1.5 0 000 3h21a1.5 1.5 0 000-3zM6.355 4.436a.25.25 0 01.149.215 2.5 2.5 0 10.913-2.084.251.251 0 01-.259.035L1.4.084a1 1 0 10-.8 1.832zM23.4 1.916a1 1 0 10-.8-1.832L16.842 2.6a.251.251 0 01-.259-.035 2.5 2.5 0 10.917 2.086.25.25 0 01.149-.215z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});