define("ember-svg-jar/inlined/programming-monitor-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-monitor-type</title><path d=\"M13 16.046a.265.265 0 01-.019-.1v-1.2a.251.251 0 01.249-.25c1.136-.008 5.8-.042 6.164-.042A2 2 0 0020.98 12.5V2a2 2 0 00-2-2h-14a2 2 0 00-2 2v10.5a2 2 0 001.607 1.96c.345 0 5.009.031 6.145.038a.25.25 0 01.248.25v1.206a.251.251 0 01-.016.09c-.06.155-.272.706-.505 1.264a.5.5 0 00.462.692h2.151a.5.5 0 00.459-.7c-.241-.553-.467-1.1-.531-1.254zM18.48 2a.5.5 0 01.5.5v8a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5z\"/><path d=\"M9.176 16.546a.732.732 0 00-.358-.971 43.584 43.584 0 00-4.6.428c-.312-.01-.8-.009-.972.359l-2.04 3.5A2.438 2.438 0 002.4 23.1l1.479.682a2.44 2.44 0 002.345-.168C7.54 22.76 9.4 21.215 9.4 21.215a.585.585 0 00-.454-1.07l-1.2.55a.25.25 0 01-.332-.331zM20.755 16.362c-.169-.368-.66-.369-.972-.359a43.584 43.584 0 00-4.6-.428.732.732 0 00-.358.971l1.758 3.818a.25.25 0 01-.332.331l-1.2-.55a.585.585 0 00-.453 1.07s1.86 1.545 3.176 2.394a2.44 2.44 0 002.345.168L21.6 23.1a2.439 2.439 0 001.2-3.236z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});