define("ember-svg-jar/inlined/data-transfer-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-transfer-circle</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM8.924 12.094A1 1 0 019.5 13v1.25a.25.25 0 00.25.25h3.75a1 1 0 010 2H9.75a.25.25 0 00-.25.25V18a1 1 0 01-1.64.768l-3-2.5a1 1 0 010-1.537l3-2.5a1 1 0 011.064-.137zM15.5 12a1 1 0 01-1-1V9.75a.25.25 0 00-.25-.25H10.5a1 1 0 010-2h3.75a.25.25 0 00.25-.25V6a1 1 0 011.64-.769l3 2.5a1 1 0 010 1.537l-3 2.5a1 1 0 01-.64.232z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});