define("ember-svg-jar/inlined/e-commerce-amazon-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><style>.cls-1{fill-rule:evenodd}</style></defs><title>e-commerce-amazon-1</title><path class=\"cls-1\" d=\"M21.82 16.95c-.47-.14-2.88-.81-3.94-.15a1 1 0 00-.36 1.4 1 1 0 001.34.34 4 4 0 011.47.11c-10.55 4.65-18.19-.74-18.53-1a1 1 0 10-1.19 1.61 20 20 0 0011.16 3.26 23 23 0 009.08-1.95 2.77 2.77 0 01-.88 1 1 1 0 00.93 1.77c1.72-.91 2.68-3.94 2.86-4.54.38-1.27-1.85-1.8-1.94-1.85z\"/><path class=\"cls-1\" d=\"M4.14 12.69a4.15 4.15 0 004.37 4.52 5.75 5.75 0 004.82-2.13A8 8 0 0015 17a.71.71 0 00.71-.05c.61-.51 1.68-1.42 2.23-1.93a.55.55 0 00.05-.76 3.8 3.8 0 01-1.12-2.69V7c0-1.93.15-3.71-1.27-5A6.82 6.82 0 0011.2.51C7.94.51 5.24 1.84 4.65 5a.48.48 0 00.41.61l2.84.35a.64.64 0 00.51-.56 2.33 2.33 0 012.38-1.8 2 2 0 011.63.76 4.59 4.59 0 01.36 2.54c-4.55.35-8.64 1.1-8.64 5.79zm8.07.26c-1 1.93-3.81 1.94-3.81-.76a2.67 2.67 0 012.18-2.74 10 10 0 012.18-.2v.61a5.39 5.39 0 01-.55 3.09z\"/>",
    "attrs": {
      "id": "Bold",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});