define("ember-svg-jar/inlined/style-one-pin-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-information</title><path d=\"M12 0a9.5 9.5 0 00-4 18.119 2.509 2.509 0 011.218 1.222l1.872 4.076a1 1 0 001.817 0l1.876-4.079A2.5 2.5 0 0116 18.118 9.5 9.5 0 0012 0zm0 15.5a6 6 0 116-6 6.006 6.006 0 01-6 6z\"/><circle cx=\"11.5\" cy=\"6\" r=\"1.25\"/><path d=\"M14.5 13a1 1 0 00-1-1 .5.5 0 01-.5-.5v-2A1.5 1.5 0 0011.5 8h-1a1 1 0 000 2 .5.5 0 01.5.5v1a.5.5 0 01-.5.5 1 1 0 000 2h3a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});