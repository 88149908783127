define("ember-svg-jar/inlined/accounting-calculator-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accounting-calculator-1</title><path d=\"M12.751 15.5a.25.25 0 00-.25.25v8a.25.25 0 00.25.25H18a3 3 0 003-3v-5.25a.25.25 0 00-.25-.25zM15 18h3a.5.5 0 010 1h-3a.5.5 0 010-1zm0 2h3a.5.5 0 010 1h-3a.5.5 0 010-1zM11.5 15.75a.25.25 0 00-.25-.25h-8a.25.25 0 00-.25.25V21a3 3 0 003 3h5.251a.25.25 0 00.25-.25zm-5.855 2.6a.5.5 0 01.708-.708l.969.97a.25.25 0 00.354 0l.969-.97a.5.5 0 01.708.708l-.97.969a.25.25 0 000 .354l.97.969a.5.5 0 01-.708.708l-.969-.97a.25.25 0 00-.354 0l-.969.97a.5.5 0 01-.708-.708l.97-.969a.25.25 0 000-.354zM16 5.75a.75.75 0 00.75-.75V3.5a.75.75 0 00-1.5 0V5a.75.75 0 00.75.75z\"/><path d=\"M18 0H6a3 3 0 00-3 3v11.25a.25.25 0 00.25.25h8a.25.25 0 00.25-.25v-7.5a.25.25 0 00-.25-.25h-6A.25.25 0 015 6.25V3a1 1 0 011-1h12a1 1 0 011 1v3.25a.25.25 0 01-.25.25h-6a.25.25 0 00-.25.25v7.5a.25.25 0 00.25.25h8a.25.25 0 00.25-.25V3a3 3 0 00-3-3zM9 11.5h-.75a.25.25 0 00-.25.25v.75a.5.5 0 01-1 0v-.75a.25.25 0 00-.25-.25H6a.5.5 0 010-1h.75a.25.25 0 00.25-.25V9.5a.5.5 0 011 0v.75a.25.25 0 00.25.25H9a.5.5 0 010 1zm9 0h-3a.5.5 0 010-1h3a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});