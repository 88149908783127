define("ember-svg-jar/inlined/outdoors-bird-house.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-bird-house</title><path d=\"M20 24a1 1 0 001-1v-4.25a.25.25 0 01.25-.25h1.25A1.5 1.5 0 0024 17v-4.5a1.5 1.5 0 00-1.5-1.5h-5a1.5 1.5 0 00-1.5 1.5V17a1.5 1.5 0 001.5 1.5h1.25a.25.25 0 01.25.25V23a1 1 0 001 1zM9.467.607a2.088 2.088 0 00-2.9-.032L.3 6.786a1 1 0 101.4 1.42l.371-.364a.25.25 0 01.425.179V22.5c0 .931 1.3 1.5 2 1.5H12a1.5 1.5 0 001.5-1.5V8.017a.247.247 0 01.154-.23.25.25 0 01.272.053l.37.366a1 1 0 101.404-1.42zM10 16.5a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5h3a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});