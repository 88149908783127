define("ember-svg-jar/inlined/adventure-car-atv-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>adventure-car-atv-1</title><path d=\"M5.255 14.125a3.75 3.75 0 103.75 3.75 3.755 3.755 0 00-3.75-3.75zm0 4.75a1 1 0 111-1 1 1 0 01-1 1zM18.255 14.125a3.75 3.75 0 103.75 3.75 3.755 3.755 0 00-3.75-3.75zm0 4.75a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M23.921 15.036a5.965 5.965 0 00-1.821-2.117.25.25 0 01-.1-.2v-1.848a2.094 2.094 0 00-.569-1.38L21.04 9.1a.244.244 0 01-.07-.14 2.721 2.721 0 00-.777-1.536 2.774 2.774 0 00-1.943-.8h-.036a.25.25 0 01-.234-.162l-1.113-2.97a1.777 1.777 0 00-1.612-1.117h-2a1 1 0 000 2h1.633a.25.25 0 01.234.162l.655 1.746a.251.251 0 01-.234.338H14.25a.75.75 0 00-.75.75v3a.25.25 0 01-.25.25H9.005a.25.25 0 01-.25-.25v-4a1 1 0 10-2 0v2a.25.25 0 01-.25.25H2.42A1.422 1.422 0 001 10.041v3.166a.25.25 0 01-.079.182 5.851 5.851 0 00-.786.893.75.75 0 101.23.86 4.343 4.343 0 01.85-.912l.005-.005.009-.006a4.74 4.74 0 017.137 1.269 1.226 1.226 0 001.084.633h2.61a1.225 1.225 0 001.079-.624 4.767 4.767 0 014.111-2.376 4.906 4.906 0 012.6.746 4.577 4.577 0 011.721 1.835.75.75 0 001.342-.67z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});