define("ember-svg-jar/inlined/password-approved-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>password-approved-1</title><path d=\"M1.956 14h8.609a.978.978 0 00.5-.153 7.7 7.7 0 01.472-.712.872.872 0 00.028-.135 1 1 0 00-1-1H2.5a.5.5 0 01-.5-.5l-.038-6a.5.5 0 01.5-.5H21.5a.5.5 0 01.5.5v4a1 1 0 002 0V5a1.981 1.981 0 00-1.956-2H1.956A1.981 1.981 0 000 5v7a1.981 1.981 0 001.956 2z\"/><path d=\"M7.972 6.992a.751.751 0 00-1.038-.216l-.125.082a.25.25 0 01-.387-.209V6.5a.75.75 0 00-1.5 0v.131a.25.25 0 01-.389.208l-.109-.073a.75.75 0 00-.832 1.248L4 8.285a.253.253 0 01.111.209A.249.249 0 014 8.7l-.408.268a.75.75 0 10.822 1.254l.124-.081a.248.248 0 01.256-.011.251.251 0 01.131.22v.15a.75.75 0 001.5 0v-.131a.25.25 0 01.389-.207l.109.072a.75.75 0 00.832-1.248l-.406-.271a.249.249 0 010-.417l.409-.268a.749.749 0 00.214-1.038zM14.627 6.992a.75.75 0 00-1.038-.216l-.125.082a.248.248 0 01-.256.011.252.252 0 01-.131-.22V6.5a.75.75 0 10-1.5 0v.131a.25.25 0 01-.389.208l-.109-.073a.75.75 0 10-.832 1.248l.407.271a.25.25 0 010 .417l-.408.268a.75.75 0 10.822 1.254l.124-.081a.25.25 0 01.387.209v.148a.75.75 0 001.5 0v-.131a.25.25 0 01.389-.207l.109.072a.75.75 0 00.832-1.248L14 8.715a.25.25 0 010-.415l.408-.268a.749.749 0 00.219-1.04zM17.75 11.5A6.25 6.25 0 1024 17.75a6.257 6.257 0 00-6.25-6.25zm3.162 5.028l-2.784 3.712a1.46 1.46 0 01-1.071.584h-.1a1.463 1.463 0 01-1.039-.432l-1.437-1.438a.75.75 0 111.06-1.06l1.029 1.028a.5.5 0 00.756-.056l2.392-3.242a.75.75 0 011.2.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});