define("ember-svg-jar/inlined/video-player-movie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-player-movie</title><path d=\"M23.75 18a.25.25 0 00.25-.25V6.25a.25.25 0 00-.25-.25H.25a.25.25 0 00-.25.25v11.5a.25.25 0 00.25.25zM8.877 8.98a.482.482 0 01.72-.417l5.283 3.019a.482.482 0 010 .836L9.6 15.437a.482.482 0 01-.72-.417zM23.75 5a.25.25 0 00.25-.25V4.5A2.5 2.5 0 0021.5 2h-19A2.5 2.5 0 000 4.5v.25A.25.25 0 00.25 5zM.25 19a.25.25 0 00-.25.25v.25A2.5 2.5 0 002.5 22h19a2.5 2.5 0 002.5-2.5v-.25a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});