define("ember-svg-jar/inlined/cat-sitting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cat-sitting</title><path d=\"M21.794 19.072a3 3 0 00-2.294-1.065 1 1 0 000 2 1.007 1.007 0 01.983 1.171 1.071 1.071 0 01-1.093.829H15.5a.5.5 0 01-.5-.5v-9.934a.494.494 0 00-.653-.472A6.973 6.973 0 0112 11.5a6.9 6.9 0 01-3.477-.926.505.505 0 00-.715.2l-1.636 3.27c-2.764.419-4.672 2.831-4.672 5.963 0 1.326.5 3.991 2.84 3.991h15.05a3.075 3.075 0 003.066-2.5 2.988 2.988 0 00-.662-2.426zM12 10a4.734 4.734 0 004.988-4.43V.552A.563.563 0 0016.112.1L14.08 1.552a5.415 5.415 0 00-4.158 0L7.89.1a.561.561 0 00-.876.451V5.57A4.734 4.734 0 0012 10zm2-5.5a1 1 0 11-1 1 1 1 0 011-1zm-4 0a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});