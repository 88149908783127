define("ember-svg-jar/inlined/voice-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>voice-id</title><path d=\"M4 9v6a1 1 0 002 0V9a1 1 0 00-2 0zM8.5 6.5a1 1 0 00-1 1v9a1 1 0 002 0v-9a1 1 0 00-1-1zM20 15V9a1 1 0 00-2 0v6a1 1 0 002 0zM16.5 7.5a1 1 0 00-2 0v9a1 1 0 002 0zM12 3.5a1 1 0 00-1 1v15a1 1 0 002 0v-15a1 1 0 00-1-1zM6.5 22H2.25a.25.25 0 01-.25-.25V17.5a1 1 0 00-2 0V22a2 2 0 002 2h4.5a1 1 0 000-2zM1 7.5a1 1 0 001-1V2.25A.25.25 0 012.25 2H6.5a1 1 0 000-2H2a2 2 0 00-2 2v4.5a1 1 0 001 1zM23 16.5a1 1 0 00-1 1v4.25a.25.25 0 01-.25.25H17.5a1 1 0 000 2H22a2 2 0 002-2v-4.5a1 1 0 00-1-1zM22 0h-4.5a1 1 0 000 2h4.25a.25.25 0 01.25.25V6.5a1 1 0 002 0V2a2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});