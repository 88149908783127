define("ember-svg-jar/inlined/smiley-devastated-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-devastated-1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM5.945 5.668l3-2a1 1 0 111.11 1.664l-3 2a1 1 0 01-1.11-1.664zm.07 3.42a1 1 0 011.359.388.991.991 0 00.877.455 1 1 0 00.873-.454 1 1 0 011.75.969 2.977 2.977 0 01-2.622 1.485 2.979 2.979 0 01-2.625-1.484 1 1 0 01.388-1.359zm1.044 8.764a5.706 5.706 0 019.882 0 .439.439 0 01-.38.659 21.078 21.078 0 00-9.123 0 .44.44 0 01-.379-.659zm11.815-7.405a2.979 2.979 0 01-2.625 1.484 2.977 2.977 0 01-2.622-1.485 1 1 0 011.75-.969 1 1 0 00.873.454.991.991 0 00.877-.455 1 1 0 011.748.971zm-5.206-6.5a1 1 0 011.387-.277l3 2a1 1 0 01-1.11 1.664l-3-2a1 1 0 01-.277-1.389z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});