define("ember-svg-jar/inlined/headphones-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>headphones-2</title><path d=\"M7.821 14.965a1.5 1.5 0 00-1.949-.836l-.929.371a1.5 1.5 0 00-.835 1.95l2.042 5.107a1.5 1.5 0 001.95.836l.928-.372a1.5 1.5 0 00.835-1.951z\"/><path d=\"M12 .5A11.513 11.513 0 00.5 12c0 5.2 2.994 10.745 3.122 10.978a1 1 0 101.756-.957C5.35 21.969 2.5 16.679 2.5 12a9.5 9.5 0 0119 0c0 4.679-2.85 9.969-2.878 10.021a1 1 0 101.756.957C20.506 22.745 23.5 17.2 23.5 12A11.513 11.513 0 0012 .5z\"/><path d=\"M19.057 14.5l-.929-.371a1.5 1.5 0 00-1.949.836l-2.042 5.105a1.5 1.5 0 00.835 1.951l.928.372a1.5 1.5 0 001.95-.836l2.042-5.106a1.5 1.5 0 00-.835-1.951z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});