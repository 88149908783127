define("ember-svg-jar/inlined/make-up-mascara", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>make-up-mascara</title><path d=\"M19.035 5.371a.5.5 0 01-.285-.452V4a1.252 1.252 0 00-1.25-1.25h-2A1.252 1.252 0 0014.25 4v.919a.5.5 0 01-.285.452A1.251 1.251 0 0013.25 6.5v14.25a3.25 3.25 0 006.5 0V6.5a1.25 1.25 0 00-.715-1.129zM8.75 3.885h.5a1 1 0 000-2h-.5a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v.385a.5.5 0 01-.5.5h-.5a1 1 0 000 2h.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-.5a1 1 0 000 2h.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-.5a1 1 0 000 2h.5a.5.5 0 01.5.5V12.5a.5.5 0 01-.5.5 1.252 1.252 0 00-1.25 1.25V15a.5.5 0 00.5.5h4.5a.5.5 0 00.5-.5v-.751A1.252 1.252 0 008.75 13a.5.5 0 01-.5-.5v-1.115a.5.5 0 01.5-.5h.5a1 1 0 000-2h-.5a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h.5a1 1 0 000-2h-.5a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5zM9.5 16.5H5a.5.5 0 00-.5.5v4.25a2.75 2.75 0 005.5 0V17a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});