define("ember-svg-jar/inlined/database-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-add</title><path d=\"M8.7 21.391a.247.247 0 00.23-.106.25.25 0 00.019-.253 7.954 7.954 0 01-.8-3 .25.25 0 00-.217-.231 12.8 12.8 0 01-6.147-2.211.25.25 0 00-.408.194V17.5c0 1.9 3.129 3.478 7.323 3.891zM1.377 13c0 1.772 2.735 3.272 6.522 3.8a.25.25 0 00.283-.219 7.923 7.923 0 01.874-2.808.25.25 0 00-.2-.366c-2.821-.257-5.506-1.043-7.071-2.317a.25.25 0 00-.408.194zM10.878 9c-3.5 0-7.156-.833-9.093-2.411a.25.25 0 00-.408.194V8.5c0 2.053 3.672 3.744 8.4 3.974a.253.253 0 00.2-.09 7.956 7.956 0 019.307-2.222.251.251 0 00.272-.049 2.263 2.263 0 00.821-1.613V6.785a.25.25 0 00-.408-.194C18.032 8.168 14.382 9 10.878 9z\"/><ellipse cx=\"10.878\" cy=\"4\" rx=\"9.5\" ry=\"4\"/><path d=\"M16.123 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-3.25 6.5a.75.75 0 01.75-.75h1.5a.25.25 0 00.25-.25V15a.75.75 0 111.5 0v1.5a.25.25 0 00.25.25h1.5a.75.75 0 110 1.5h-1.5a.25.25 0 00-.25.25V20a.75.75 0 11-1.5 0v-1.5a.25.25 0 00-.25-.25h-1.5a.75.75 0 01-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});