define("ember-svg-jar/inlined/currency-euro-increase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-euro-increase</title><path d=\"M7.5 0a7 7 0 107 7 7.009 7.009 0 00-7-7zm1.089 6.216a.75.75 0 010 1.5H6.554a.25.25 0 00-.227.354 3.63 3.63 0 003.351 1.957.75.75 0 010 1.5A5 5 0 014.7 7.908a.25.25 0 00-.243-.192h-.225a.75.75 0 010-1.5h.222a.25.25 0 00.246-.193A5 5 0 019.678 2.4a.75.75 0 010 1.5 3.631 3.631 0 00-3.351 1.962.25.25 0 00.227.354zM23.5 11a.5.5 0 00-.5-.5h-5a.5.5 0 00-.354.854l1.415 1.414a.251.251 0 01-.007.361l-4.583 4.225a.249.249 0 01-.325.012l-2.9-2.317a2.154 2.154 0 00-2.771.071l-.219.194-7.314 6.5a1.25 1.25 0 001.66 1.87l7.155-6.36a.251.251 0 01.323-.009l2.9 2.324a2.164 2.164 0 002.805-.1l5.047-4.653a.251.251 0 01.347.007l1.461 1.462A.5.5 0 0023.5 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});