define("ember-svg-jar/inlined/credit-card-mastercard-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-mastercard</title><path d=\"M21.913 3.5H2.087A2.051 2.051 0 000 5.5v13a2.051 2.051 0 002.087 2h19.826a2.05 2.05 0 002.087-2v-13a2.05 2.05 0 00-2.087-2zm-6.842 14.179a5.125 5.125 0 01-3.171-1.112.251.251 0 00-.309 0 5.112 5.112 0 01-3.165 1.112 5.179 5.179 0 010-10.358A5.109 5.109 0 0111.6 8.434a.248.248 0 00.308 0 5.129 5.129 0 013.168-1.112 5.179 5.179 0 010 10.358z\"/><path d=\"M18.75 12.5a3.683 3.683 0 00-3.679-3.679 3.6 3.6 0 00-2.127.714.251.251 0 00-.067.33 5.121 5.121 0 010 5.27.25.25 0 00.067.33 3.6 3.6 0 002.127.714A3.683 3.683 0 0018.75 12.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});