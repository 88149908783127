define("ember-svg-jar/inlined/dog-forbidden", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dog-forbidden</title><path d=\"M17.587 11.494a.476.476 0 01-.037-.134 8.312 8.312 0 00-.059-.395.489.489 0 00-.826-.26l-5.878 5.878a.489.489 0 00-.113.513l.005.015.285.956a.487.487 0 00.686.3l6.9-3.469a.488.488 0 00.229-.629z\"/><path d=\"M12 0a12 12 0 1012 12A12 12 0 0012 0zM4.667 17.953a.487.487 0 01-.739-.057A9.995 9.995 0 0117.9 3.928a.489.489 0 01.057.739l-1.565 1.561a.484.484 0 01-.4.14.491.491 0 01-.359-.222 6.078 6.078 0 00-1.849-1.952.977.977 0 00-1.417.476l-1.325 3.391a.489.489 0 01-.351.3l-3.355.732A3.786 3.786 0 004.2 12.827a3.013 3.013 0 001.666 3.028.488.488 0 01.124.78zm15.627-.862a.488.488 0 01-.424-.293l-.124-.288a.487.487 0 00-.667-.243l-7.16 3.6a.487.487 0 00-.248.575l.271.911a.486.486 0 01-.494.626A9.862 9.862 0 016.1 20.071a.487.487 0 01-.056-.738L19.332 6.047a.492.492 0 01.383-.142.487.487 0 01.356.2 9.971 9.971 0 01.673 10.739.486.486 0 01-.45.247z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});