define("ember-svg-jar/inlined/agriculture-machine-seeder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>agriculture-machine-seeder</title><path d=\"M23.058 14.017l-5.718-.573a.249.249 0 01-.222-.206l-.958-5.591a.25.25 0 01.246-.293h.523a.5.5 0 00.5-.5V1.977a.5.5 0 00-.5-.5H7.071a.5.5 0 00-.5.5v4.877a.5.5 0 00.5.5h.523a.25.25 0 01.246.293l-.958 5.591a.251.251 0 01-.222.206l-5.718.572a1.018 1.018 0 00-.92 1.016v1.093a1.023 1.023 0 001.021 1.022h8.164a.249.249 0 00.25-.25v-3.484a.225.225 0 00-.075-.167.228.228 0 00-.173-.057.224.224 0 01-.244-.262l.919-5.365a.251.251 0 01.247-.208h3.738a.251.251 0 01.247.208l.92 5.365a.229.229 0 01-.058.192.226.226 0 01-.187.07.225.225 0 00-.173.058.221.221 0 00-.075.167V16.9a.249.249 0 00.25.25h8.685a.5.5 0 00.5-.5v-1.618a1.016 1.016 0 00-.92-1.015z\"/><rect x=\"10.957\" y=\"10.908\" width=\"2.087\" height=\"6.261\" rx=\".25\" ry=\".25\"/><path d=\"M3.045 18.263a1 1 0 00-1.265.637l-.77 2.312a1 1 0 00.633 1.265.989.989 0 00.315.051 1 1 0 00.949-.683l.771-2.313a1 1 0 00-.633-1.269zM6.761 18.9a1 1 0 00-1.9.632l.771 2.313a1 1 0 101.9-.633zM10.754 18.263a.985.985 0 00-1.136.406.933.933 0 00-.129.226l-.771 2.312a1 1 0 001.9.633l.771-2.313a1.04 1.04 0 00-.632-1.264zM13.205 18.263a1.042 1.042 0 00-.633 1.264l.771 2.313a1 1 0 00.949.683.989.989 0 00.315-.051 1 1 0 00.633-1.265l-.77-2.307a.971.971 0 00-.13-.226.985.985 0 00-1.135-.411zM22.178 18.9a1 1 0 00-1.9.632l.771 2.313a1 1 0 001.9-.633zM18.462 18.263a1 1 0 00-1.262.637l-.771 2.312a1 1 0 001.9.633l.771-2.313a1 1 0 00-.638-1.269z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});