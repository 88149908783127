define("ember-svg-jar/inlined/yoga-full-body-stretch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>yoga-full-body-stretch</title><circle cx=\"12.014\" cy=\"5.943\" r=\"2.5\"/><path d=\"M17.977 22.116A3.27 3.27 0 0016.413 18L15 17.3v-5.412a.251.251 0 01.133-.221A6.744 6.744 0 0016.772.92a1.25 1.25 0 00-1.767 1.768 4.25 4.25 0 11-6.01 0A1.25 1.25 0 007.228.92a6.744 6.744 0 001.639 10.747.251.251 0 01.133.221v11.055a.5.5 0 00.5.5H11a.5.5 0 00.5-.5v-3.794a.5.5 0 01.724-.447l3.076 1.535a.756.756 0 01.361.951l-.629 1.572a.5.5 0 00.465.686h1.615a.5.5 0 00.464-.314z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});