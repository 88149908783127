define("ember-svg-jar/inlined/instrument-xylophone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>instrument-xylophone</title><path d=\"M22.517 5.543L2.507.062A2 2 0 000 2v20a2 2 0 001.994 2c.159 0 12.931-3.471 14.559-3.913a.25.25 0 01.271.1l2.356 3.387a1.011 1.011 0 001.392.249 1 1 0 00.25-1.392l-1.85-2.66a.25.25 0 01.139-.384l3.392-.929A2 2 0 0024 16.518V7.476a2 2 0 00-1.483-1.933zM1.988 2.649a.5.5 0 01.632-.482l2.7.739a.249.249 0 01.184.241v17.7a.25.25 0 01-.184.241l-2.684.735A.5.5 0 012 21.34zM12 16c-.08 0-.158 0-.237-.006a.25.25 0 00-.263.25V19.2a.249.249 0 01-.184.241l-3.5.959a.249.249 0 01-.316-.241V3.832a.249.249 0 01.316-.241l3.5.959a.249.249 0 01.184.241v1.962a.251.251 0 00.078.182.254.254 0 00.186.065H12a4.5 4.5 0 011.184.159.251.251 0 00.218-.043.248.248 0 00.1-.2V5.475a.25.25 0 01.316-.242l3.5.959a.25.25 0 01.184.241v10.42a.25.25 0 01-.455.142l-2.511-3.609a.251.251 0 01-.005-.277A3 3 0 1012 14.5a2.94 2.94 0 00.634-.068.252.252 0 01.259.1L15.309 18a.247.247 0 01.03.228.249.249 0 01-.169.156l-1.354.371a.254.254 0 01-.218-.043.25.25 0 01-.1-.2v-2.433a.249.249 0 00-.316-.241A4.5 4.5 0 0112 16zm9.988.143a.5.5 0 01-.368.482l-1.8.494a.249.249 0 01-.316-.241V7.119a.249.249 0 01.316-.241l1.816.5a.5.5 0 01.364.48z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});