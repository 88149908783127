define("ember-svg-jar/inlined/mobile-launch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mobile-launch</title><path d=\"M21.582 1.7A1 1 0 0020.168.287l-.946.947A.251.251 0 0119 1.3a30.307 30.307 0 00-1.244-.186 3.091 3.091 0 00-2.613 1.126l-2.487 3.895a.25.25 0 01-.312.094l-.116-.051a2 2 0 00-2.268.454L9 7.653a.5.5 0 00.254.831l1.668.372 2.093 2.094.371 1.664a.5.5 0 00.832.255l1.021-.964a2 2 0 00.454-2.267l-.05-.113a.25.25 0 01.094-.312l3.9-2.487a3.092 3.092 0 001.121-2.618c-.03-.269-.107-.761-.185-1.244a.252.252 0 01.069-.217zm-3.336 3.53a1.137 1.137 0 110-1.608 1.137 1.137 0 010 1.609zM7.482 14.481a4.412 4.412 0 003.346-.91 1.681 1.681 0 000-2.662 1.681 1.681 0 00-2.662 0 4.425 4.425 0 00-.911 3.347.248.248 0 00.227.225z\"/><path d=\"M12.125 3.007v-2.5a.5.5 0 00-.5-.5h-7a2.5 2.5 0 00-2.5 2.5v19a2.5 2.5 0 002.5 2.5h9a2.5 2.5 0 002.5-2.5v-5.5a1 1 0 00-2 0v2a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5v-14a.5.5 0 01.5-.5h7a.5.5 0 00.5-.5zm-3 17.5a1 1 0 11-1 1 1 1 0 011-.999z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});