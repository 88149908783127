define("ember-svg-jar/inlined/single-man-actions-credit-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-credit-card</title><path d=\"M9.124 15.125a3 3 0 013-3h2.053a.25.25 0 00.1-.48l-3-1.285a.25.25 0 01-.152-.23V8.995a.252.252 0 01.074-.177 3.311 3.311 0 00.779-2.231.251.251 0 01.088-.176 1.873 1.873 0 00.538-1.4A1.58 1.58 0 0012.287 4a.251.251 0 01-.034-.264 2.531 2.531 0 00.157-1.727C12.069.618 10.367.125 9.013.125A3.752 3.752 0 005.85 1.449a.251.251 0 01-.212.118 1.072 1.072 0 00-.9.375c-.372.493-.106 1.17.176 1.732a.251.251 0 01-.046.288 1.3 1.3 0 00-.162.2 1.763 1.763 0 00-.265.961 1.521 1.521 0 00.638 1.331.249.249 0 01.121.196 3.167 3.167 0 00.845 2.175.25.25 0 01.08.183v1.123a.248.248 0 01-.151.229l-3.937 1.687C.765 12.593.582 13.751.33 15.355c-.055.359-.117.751-.2 1.179a.5.5 0 00.491.591h8.253a.25.25 0 00.25-.25z\"/><path d=\"M22.124 13.375h-10a1.753 1.753 0 00-1.75 1.75v7a1.753 1.753 0 001.75 1.75h10a1.752 1.752 0 001.75-1.75v-7a1.752 1.752 0 00-1.75-1.75zm0 9h-10a.251.251 0 01-.25-.25v-3a.25.25 0 01.25-.25h10a.25.25 0 01.25.25v3a.25.25 0 01-.25.25zm-10-7.5h10a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-10a.25.25 0 01-.25-.25v-1a.251.251 0 01.25-.25z\"/><path d=\"M20.874 20.625a.75.75 0 00-.75-.75h-1.25a.75.75 0 100 1.5h1.25a.75.75 0 00.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});