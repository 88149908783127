define("ember-svg-jar/inlined/outdoors-tree-road", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-tree-road</title><path d=\"M2.312 13.16A2.384 2.384 0 004.5 15v1a.75.75 0 00.75.75h1A.75.75 0 007 16v-1a2.224 2.224 0 001.84-1.068.5.5 0 00-.01-.563 5.414 5.414 0 01-.63-1.164.5.5 0 00-.156-.211 4.746 4.746 0 01-1.8-3.736 4.794 4.794 0 01.263-1.567.5.5 0 00-.38-.655A2.014 2.014 0 005.75 6a2.146 2.146 0 00-2.1 1.848 1.945 1.945 0 00-1.4 1.577 2.207 2.207 0 00.061 3.735zM9.265 11.019a.5.5 0 01.217.289A3.6 3.6 0 0012.719 14c.081 0 .163 0 .245-.009a.5.5 0 01.536.5V16a.75.75 0 00.75.75h1A.75.75 0 0016 16v-1.51a.5.5 0 01.536-.5c.082.006.164.009.245.009a3.6 3.6 0 003.237-2.692.5.5 0 01.217-.289 3.275 3.275 0 00.091-5.464.5.5 0 01-.213-.32 2.9 2.9 0 00-1.924-2.212.5.5 0 01-.352-.391 3.126 3.126 0 00-6.174 0 .5.5 0 01-.352.391 2.9 2.9 0 00-1.924 2.213.5.5 0 01-.213.32 3.275 3.275 0 00.091 5.464zM1 20h22a1 1 0 000-2H1a1 1 0 000 2zM23 22H1a1 1 0 000 2h22a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});