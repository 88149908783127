define("ember-svg-jar/inlined/airport-road", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>airport-road</title><path d=\"M20.585 13.591a.5.5 0 00-.524-.356c-.1.01-.206.015-.311.015a3.742 3.742 0 01-2.745-1.2.251.251 0 00-.191-.08.255.255 0 00-.185.091 6 6 0 01-3.244 2.015.5.5 0 00-.385.487V16.5a1 1 0 01-2 0v-1.935a.5.5 0 00-.385-.487 6 6 0 01-3.244-2.015.25.25 0 00-.185-.09.247.247 0 00-.186.079 3.742 3.742 0 01-2.745 1.2c-.105 0-.208-.005-.311-.015a.5.5 0 00-.524.356L.521 23.359A.5.5 0 001 24h9.5a.5.5 0 00.5-.5v-3a1 1 0 012 0v3a.5.5 0 00.5.5H23a.5.5 0 00.479-.641z\"/><path d=\"M23.02 6.987h-6.435a.489.489 0 01-.458-.318A4.429 4.429 0 0013.322 4a.492.492 0 01-.342-.467V1.109a.98.98 0 00-1.96 0v2.428a.49.49 0 01-.342.463 4.429 4.429 0 00-2.805 2.669.489.489 0 01-.458.318H.98a.98.98 0 100 1.959h.992a.249.249 0 01.182.081.245.245 0 01.061.19 2.277 2.277 0 00-.011.219 2.2 2.2 0 004.408 0c0-.073 0-.147-.011-.219a.245.245 0 01.061-.19.249.249 0 01.183-.081h.418a.491.491 0 01.475.368 4.4 4.4 0 008.524 0 .49.49 0 01.475-.368h.418a.246.246 0 01.244.271 2.277 2.277 0 00-.011.219 2.2 2.2 0 004.408 0c0-.073 0-.147-.011-.219a.245.245 0 01.061-.19.249.249 0 01.182-.081h.992a.98.98 0 100-1.959zM4.408 10.171a.735.735 0 11.735-.735.74.74 0 01-.735.735zm9.551-2.939h-3.845a.215.215 0 01-.18-.33 2.444 2.444 0 014.174.068.173.173 0 01-.149.262zm5.633 2.939a.735.735 0 11.735-.735.74.74 0 01-.735.735z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});