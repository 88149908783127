define("ember-svg-jar/inlined/bear-paw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bear-paw</title><path d=\"M17.065 8.786A6.631 6.631 0 0015.8 4.873V2.587a2.617 2.617 0 00-2.5-2.5c-1.3 0-2.053 1.347-2.34 2H6.3c-.288-.653-1.038-2-2.34-2a2.616 2.616 0 00-2.5 2.5v2.191A6.671 6.671 0 00.125 8.786c0 4.062 2.933 5.51 4.7 6.383a4.412 4.412 0 007.47.2c3.828-2.154 4.77-4.121 4.77-6.583zm-7.99 3.7a.777.777 0 00-.9 0 1.778 1.778 0 01-2.225-.108.251.251 0 01-.065-.283l.49-1.174a.251.251 0 01.139-.136.254.254 0 01.2 0 5.457 5.457 0 001.917.547 5.465 5.465 0 001.917-.546.247.247 0 01.194-.005.251.251 0 01.139.136l.49 1.173a.248.248 0 01-.065.284 1.8 1.8 0 01-2.231.113zM7.258 8.8a1.453 1.453 0 011.367-.966 1.468 1.468 0 011.383 1.008l.1.231a.25.25 0 01-.127.324 4.575 4.575 0 01-1.352.437A4.593 4.593 0 017.272 9.4a.251.251 0 01-.127-.324zm5.109-1.21a1 1 0 111-1 1 1 0 01-1 1.003zM3.875 6.586a1 1 0 111 1 1 1 0 01-1-1zm2.429 8.1a.25.25 0 01.242-.4 3.231 3.231 0 002.079-.313 3.225 3.225 0 002.079.313.249.249 0 01.242.4 2.909 2.909 0 01-4.642 0zM18.875 18.157c-2.347 0-4.25 1.567-4.25 3.5a2.68 2.68 0 00.866 1.93.987.987 0 001.331.123 3.671 3.671 0 012.3-.553 3.824 3.824 0 012.083.572.985.985 0 001.308-.278 3.011 3.011 0 00.609-1.794c.003-1.933-1.9-3.5-4.247-3.5zM14.961 18.329a.751.751 0 00.335-1.006l-.5-1a.75.75 0 00-1.342.67l.5 1a.761.761 0 001.007.336zM16.375 15.658v1a.75.75 0 001.5 0v-1a.75.75 0 00-1.5 0z\"/><rect x=\"19.375\" y=\"14.908\" width=\"1.5\" height=\"2.5\" rx=\".75\" ry=\".75\"/><path d=\"M23.461 15.987a.751.751 0 00-1.007.336l-.5 1a.75.75 0 00.336 1.006.76.76 0 001.006-.336l.5-1a.751.751 0 00-.335-1.006z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});