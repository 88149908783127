define("ember-svg-jar/inlined/single-man-actions-flight.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-flight</title><path d=\"M7.544 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.743a.5.5 0 01.741-.3A6.958 6.958 0 009 5a6.228 6.228 0 00.93-.071.5.5 0 01.568.565A3 3 0 014.544 5a2.8 2.8 0 01.1-.743z\"/><path d=\"M10.763 15.407a2.637 2.637 0 01.373-.351l.9-.666a2.286 2.286 0 011.071-.43.5.5 0 00.316-.821A7.465 7.465 0 00.565 15.857a.5.5 0 00.479.643h8.821a.5.5 0 00.474-.341 2.275 2.275 0 01.424-.752zM22 15.554a1.8 1.8 0 00-1.41.27l-2 1.365a.25.25 0 01-.228.027l-4.651-1.731a.829.829 0 00-.784.111l-.892.662a1.2 1.2 0 00-.133.125.823.823 0 00-.2.6.852.852 0 00.35.61l2.7 1.672a.249.249 0 01.018.413l-.958.709a.248.248 0 01-.275.015l-1.173-.69a.774.774 0 00-.745.132l-.87.73a1.172 1.172 0 00-.128.126.775.775 0 00.144 1.1l2.877 2.019a.98.98 0 001.084.032l7.855-4.878A1.882 1.882 0 0022 15.554z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});