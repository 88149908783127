define("ember-svg-jar/inlined/screen-curved", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>screen-curved</title><path d=\"M17.5 21.561a1 1 0 00-1-1h-3.25a.25.25 0 01-.25-.25v-1a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25v1a.25.25 0 01-.25.25H7.5a1 1 0 000 2h9a1 1 0 001-1zM23.472 1.793a1.455 1.455 0 00-1.265-.318A49.25 49.25 0 0112 2.31a49.25 49.25 0 01-10.207-.835 1.455 1.455 0 00-1.265.317A1.506 1.506 0 000 2.942V16.93a1.5 1.5 0 00.532 1.153 1.46 1.46 0 001.261.313A49.25 49.25 0 0112 17.561a49.25 49.25 0 0110.207.835 1.451 1.451 0 001.265-.316A1.506 1.506 0 0024 16.93V2.942a1.5 1.5 0 00-.528-1.149zm-1.74 14.038a.743.743 0 01-.619.163A53.16 53.16 0 0012 15.31a53.055 53.055 0 00-9.115.69.741.741 0 01-.617-.164.752.752 0 01-.268-.579V4.615a.751.751 0 01.88-.739 52.987 52.987 0 009.12.685 52.845 52.845 0 009.115-.685.753.753 0 01.885.739v10.642a.752.752 0 01-.268.574z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});