define("ember-svg-jar/inlined/music-book-note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-book-note</title><path d=\"M11 18.983a.248.248 0 01-.073.176l-3.134 3.134a1 1 0 101.414 1.414l1.366-1.366a.25.25 0 01.427.177V23a1 1 0 002 0v-.482a.25.25 0 01.427-.177l1.366 1.366a1 1 0 001.414-1.414l-3.134-3.134a.248.248 0 01-.073-.176V17.75a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25zM2.75 7v7.5a1.5 1.5 0 001.5 1.5h15.5a1.5 1.5 0 001.5-1.5V7a1.5 1.5 0 00-1.072-1.437.25.25 0 01-.178-.24V1.25a1.265 1.265 0 00-.377-.895 1.2 1.2 0 00-.9-.355c-2.536.062-5.2.589-6.552 1.807a.25.25 0 01-.334 0C10.475.589 7.816.062 5.279 0a1.313 1.313 0 00-.905.358A1.262 1.262 0 004 1.25v4.073a.251.251 0 01-.178.24A1.5 1.5 0 002.75 7zm8.5-3.256v7.2a.249.249 0 01-.121.213.246.246 0 01-.245.008 12.633 12.633 0 00-4.913-1.133.5.5 0 01-.471-.5v-7.5a.5.5 0 01.534-.5c3.023.204 5.216 1.11 5.216 2.212zm6.717-2.209a.5.5 0 01.533.5v7.5a.5.5 0 01-.471.5 12.633 12.633 0 00-4.913 1.133.249.249 0 01-.245-.007.252.252 0 01-.121-.214v-7.2c0-1.105 2.194-2.012 5.217-2.212z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});