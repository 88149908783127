define("ember-svg-jar/inlined/dentistry-tooth-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dentistry-tooth-remove</title><path d=\"M13.206 13.633A.5.5 0 0013 13.1a7.992 7.992 0 01-3.474-6.007.5.5 0 00-.584-.456 3.84 3.84 0 00-1.865.877.5.5 0 01-.654 0 3.863 3.863 0 00-2.538-.944C1.707 6.569 0 8.63 0 11.26a7.8 7.8 0 001.584 5.086.5.5 0 01.086.483 11.536 11.536 0 00-.629 3.806 11.973 11.973 0 00.245 2.422A1.187 1.187 0 002.444 24a1.211 1.211 0 001.206-1.081c.257-2.353 1.589-4.127 3.1-4.127s2.843 1.774 3.1 4.128A1.211 1.211 0 0011.056 24a1.187 1.187 0 001.158-.943 11.973 11.973 0 00.245-2.422 11.532 11.532 0 00-.629-3.806.5.5 0 01.084-.482 6.75 6.75 0 001.292-2.714zM17.5 0A6.5 6.5 0 1024 6.5 6.508 6.508 0 0017.5 0zm1.768 6.854l1.439 1.439a1 1 0 11-1.414 1.414l-1.439-1.439a.5.5 0 00-.708 0l-1.439 1.439a1 1 0 01-1.414-1.414l1.439-1.439a.5.5 0 000-.708l-1.439-1.439a1 1 0 011.414-1.414l1.439 1.439a.5.5 0 00.708 0l1.439-1.439a1 1 0 111.414 1.414l-1.439 1.439a.5.5 0 000 .708z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});