define("ember-svg-jar/inlined/pregnancy-vgr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pregnancy-vgr</title><path d=\"M15.417 6.447a.926.926 0 00-1.307 0l-.478.478a.25.25 0 000 .353l.955.954a.249.249 0 00.353 0l.477-.477a.925.925 0 000-1.308z\"/><path d=\"M19.93 1.693L6.19 4.657A2 2 0 004.657 6.19L1.693 19.93a2 2 0 002.377 2.377l13.74-2.964a2 2 0 001.533-1.533l2.964-13.74a2 2 0 00-2.377-2.377zM8.583 13.282a9.178 9.178 0 012.58 5.161.75.75 0 01-.848.848 9.187 9.187 0 01-5.161-2.58L3.97 15.527a.75.75 0 111.06-1.061l1.185 1.184a7.666 7.666 0 002.914 1.818c.1.035.246-.11.21-.213a7.666 7.666 0 00-1.816-2.913l-1.185-1.184A.75.75 0 017.4 12.1zm4.342-3.553a.75.75 0 11-1.061 1.06l-.394-.389a.925.925 0 00-1.308 1.3l2.368 2.37a.924.924 0 001.512-1c-.022-.055-.074-.168-.145-.1l-.182.182a.75.75 0 01-1.061-1.052l.789-.789a.75.75 0 011.061 0l.394.394a2.424 2.424 0 11-3.428 3.429L9.1 12.763a2.424 2.424 0 113.43-3.428zm7.325-.26a.749.749 0 01-.75.75h-2.532c-.166 0-.24.154-.153.242l.847.846a.75.75 0 01-1.062 1.061l-4.735-4.736a.75.75 0 010-1.061l1.089-1.089a2.518 2.518 0 013.307-.288 2.418 2.418 0 01.57 3.157.245.245 0 00.215.369H19.5a.75.75 0 01.75.749z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});