define("ember-svg-jar/inlined/keyboard-alt-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-alt-alternate</title><path d=\"M19.174 0H4.826A4.832 4.832 0 000 4.822V19.17A4.832 4.832 0 004.826 24h14.348A4.832 4.832 0 0024 19.17V4.822A4.832 4.832 0 0019.174 0zM22 19.17A2.83 2.83 0 0119.174 22H4.826A2.829 2.829 0 012 19.17V4.822A2.828 2.828 0 014.826 2h14.348A2.829 2.829 0 0122 4.822z\"/><path d=\"M8.941 8.16a1 1 0 00-1.883 0l-2.5 7a1 1 0 001.883.672l.418-1.17A.25.25 0 017.1 14.5h1.8a.248.248 0 01.235.166l.418 1.17a1 1 0 001.883-.672zM7.633 12.5L8 11.469l.366 1.031zM13.5 7.5a1 1 0 00-1 1v7a1 1 0 102 0v-7a1 1 0 00-1-1zM19 9.5h-.25a.25.25 0 01-.25-.25V8.5a1 1 0 00-2 0v.75a.25.25 0 01-.25.25H16a1 1 0 000 2h.25a.25.25 0 01.25.25v2.75a2 2 0 002 2 1 1 0 00.2-1.98.249.249 0 01-.2-.245v-2.529a.25.25 0 01.25-.25H19a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});