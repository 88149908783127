define("ember-svg-jar/inlined/office-tape-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-tape-2</title><path d=\"M1.44 3.084a1 1 0 00-.81 1.828l8.013 3.549a.25.25 0 00.263-.038 12.016 12.016 0 011.364-1 .25.25 0 00-.032-.44z\"/><path d=\"M24 16.5a9.01 9.01 0 00-9-9c-3.259 0-6.167 2.234-9.15 7.029A1 1 0 014 14v-3.5a3.3 3.3 0 00-1.471-2.75l-.92-.613a1.057 1.057 0 00-1.1-.123A1.06 1.06 0 000 8v8.5A4.5 4.5 0 004.5 21h15a4.5 4.5 0 004.5-4.5zm-9 1a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});