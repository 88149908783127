define("ember-svg-jar/inlined/multiple-actions-add.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-add</title><path d=\"M17.5 9.5a.32.32 0 00.169-.593 6 6 0 00-5.646-.373.5.5 0 00-.182.771 7.473 7.473 0 01.787 1.187.5.5 0 00.7.19A7.93 7.93 0 0117.5 9.5z\"/><ellipse cx=\"14.013\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M5.989 8A6.014 6.014 0 000 13.333.6.6 0 00.6 14h9.411a.5.5 0 00.441-.264 8.01 8.01 0 011.024-1.484.5.5 0 00.086-.519A6.022 6.022 0 005.989 8z\"/><ellipse cx=\"5.513\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm3.5 7.5h-2a.5.5 0 00-.5.5v2a1 1 0 01-2 0v-2a.5.5 0 00-.5-.5h-2a1 1 0 010-2h2a.5.5 0 00.5-.5v-2a1 1 0 012 0v2a.5.5 0 00.5.5h2a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});