define("ember-svg-jar/inlined/road-sign-obstruction-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-obstruction-alternate</title><path d=\"M16.5 0h-9a1 1 0 00-1 1v22a1 1 0 001 1h9a1 1 0 001-1V1a1 1 0 00-1-1zm-8 2.25A.25.25 0 018.75 2h3.663a.25.25 0 01.177.427L8.927 6.09a.25.25 0 01-.427-.177zm7 19.5a.25.25 0 01-.25.25h-2.258a.25.25 0 01-.177-.427l2.258-2.258a.25.25 0 01.427.176zm0-5.087a.251.251 0 01-.073.177l-5.087 5.087a.248.248 0 01-.176.073H8.75a.25.25 0 01-.25-.25v-1.509a.251.251 0 01.073-.177l6.5-6.5a.25.25 0 01.427.177zm0-5.75a.251.251 0 01-.073.177l-6.5 6.5a.25.25 0 01-.427-.177v-2.922a.251.251 0 01.073-.177l6.5-6.5a.25.25 0 01.427.177zm0-5.75a.251.251 0 01-.073.177l-6.5 6.5a.25.25 0 01-.427-.177V8.741a.251.251 0 01.073-.177l6.492-6.491A.249.249 0 0115.242 2h.008a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});