define("ember-svg-jar/inlined/camera", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camera</title><path d=\"M22.5 6h-21A1.5 1.5 0 000 7.5v12A1.5 1.5 0 001.5 21h21a1.5 1.5 0 001.5-1.5v-12A1.5 1.5 0 0022.5 6zm-1 7.5a5 5 0 11-5-5 5 5 0 015 5zM8 12.25H2.5a.75.75 0 010-1.5H8a.75.75 0 010 1.5zm.75 3.25a.75.75 0 01-.75.75H2.5a.75.75 0 010-1.5H8a.75.75 0 01.75.75zM3 5h4a.5.5 0 00.5-.5A1.5 1.5 0 006 3H4a1.5 1.5 0 00-1.5 1.5.5.5 0 00.5.5z\"/><rect x=\"13.777\" y=\"10.777\" width=\"5.445\" height=\"5.445\" rx=\"1.056\" ry=\"1.056\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});