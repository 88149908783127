define("ember-svg-jar/inlined/multiple-actions-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-chat</title><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M13 8.5h3.487a.25.25 0 00.118-.471 4.474 4.474 0 00-4.308.048.25.25 0 00.162.465A3.445 3.445 0 0113 8.5zM9.5 12a3.453 3.453 0 01.248-1.28.25.25 0 00-.041-.253A5.494 5.494 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.56a.25.25 0 00.25-.25z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M22 10h-9a2 2 0 00-2 2v7a2 2 0 002 2h.75a.25.25 0 01.25.25V23a1 1 0 001.625.781l3.407-2.726a.247.247 0 01.156-.055H22a2 2 0 002-2v-7a2 2 0 00-2-2zm-3.875 9.219L16.406 20.6a.25.25 0 01-.406-.2V20a1 1 0 00-1-1h-1.75a.25.25 0 01-.25-.25v-6.5a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v6.5a.249.249 0 01-.25.25h-3a1 1 0 00-.625.219z\"/><path d=\"M15 15h5a.75.75 0 000-1.5h-5a.75.75 0 000 1.5zM18 17.5a.75.75 0 000-1.5h-3a.75.75 0 000 1.5z\"/><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M13 8.5h3.487a.25.25 0 00.118-.471 4.474 4.474 0 00-4.308.048.25.25 0 00.162.465A3.445 3.445 0 0113 8.5zM9.5 12a3.453 3.453 0 01.248-1.28.25.25 0 00-.041-.253A5.494 5.494 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.56a.25.25 0 00.25-.25z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M22 10h-9a2 2 0 00-2 2v7a2 2 0 002 2h.75a.25.25 0 01.25.25V23a1 1 0 001.625.781l3.407-2.726a.247.247 0 01.156-.055H22a2 2 0 002-2v-7a2 2 0 00-2-2zm-3.875 9.219L16.406 20.6a.25.25 0 01-.406-.2V20a1 1 0 00-1-1h-1.75a.25.25 0 01-.25-.25v-6.5a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v6.5a.249.249 0 01-.25.25h-3a1 1 0 00-.625.219z\"/><path d=\"M15 15h5a.75.75 0 000-1.5h-5a.75.75 0 000 1.5zM18 17.5a.75.75 0 000-1.5h-3a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});