define("ember-svg-jar/inlined/road-sign-road-narrows", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-road-narrows</title><path d=\"M23.53 12.53a.749.749 0 000-1.06l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0zm-7.963 3.48a.749.749 0 11-1.134.98l-1.6-1.853a1.2 1.2 0 01-.329-.844V9.707a1.244 1.244 0 01.366-.884l1.563-1.813a.749.749 0 111.134.98l-1.506 1.741A.254.254 0 0014 9.9v4.212a.254.254 0 00.061.164zM8.433 7.99a.749.749 0 111.134-.98l1.6 1.853a1.2 1.2 0 01.329.844v4.586a1.244 1.244 0 01-.366.884L9.567 16.99a.749.749 0 11-1.134-.98l1.506-1.741a.254.254 0 00.061-.164V9.893a.254.254 0 00-.061-.164z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});