define("ember-svg-jar/inlined/style-three-pin-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-block</title><path d=\"M14.92 6.52a.25.25 0 00-.038-.385A5.168 5.168 0 0012 5.25a5.256 5.256 0 00-5.25 5.25 5.168 5.168 0 00.885 2.882.249.249 0 00.183.11.252.252 0 00.2-.072zM9.08 14.48a.252.252 0 00-.072.2.249.249 0 00.11.183A5.168 5.168 0 0012 15.75a5.256 5.256 0 005.25-5.25 5.168 5.168 0 00-.885-2.882.249.249 0 00-.183-.11.252.252 0 00-.2.072z\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm0 17.25a6.75 6.75 0 116.75-6.75A6.758 6.758 0 0112 17.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});