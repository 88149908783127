define("ember-svg-jar/inlined/filter-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>filter-1</title><path d=\"M22.826 3.58A2.251 2.251 0 0021.01 0H2.992a2.25 2.25 0 00-1.828 3.563l7.1 10.171v8.015A2.259 2.259 0 0010.716 24a2.9 2.9 0 001.573-.408c2.733-1.7 2.445-1.524 2.471-1.543a2.261 2.261 0 00.9-1.8v-6.512zm-9.437 9.04a1.25 1.25 0 00-.229.721v6.481a.5.5 0 01-.244.43c-.328.194-.923.547-1.405.827a.5.5 0 01-.751-.432v-7.306a1.242 1.242 0 00-.225-.715L4.018 3.287a.5.5 0 01.41-.786h15.135a.5.5 0 01.409.788z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});