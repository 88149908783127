define("ember-svg-jar/inlined/vinyl-record-player-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vinyl-record-player-1</title><path d=\"M24 2.75a2 2 0 00-2-2H2a2 2 0 00-2 2v18.5a2 2 0 002 2h20a2 2 0 002-2zM4.5 21.5H3A.75.75 0 013 20h1.5a.75.75 0 010 1.5zm4.5 0H7.5a.75.75 0 010-1.5H9a.75.75 0 010 1.5zm0-3.75a6.5 6.5 0 116.5-6.5 6.5 6.5 0 01-6.5 6.5zm13.5-8.5a2 2 0 01-.889 1.663.25.25 0 00-.111.208v2.629a6.251 6.251 0 01-2.256 4.817l-1.464 1.22a2 2 0 01-2.56-3.074l1.464-1.219a2.266 2.266 0 00.816-1.744v-2.629a.25.25 0 00-.111-.208A2 2 0 0116.5 9.25v-3a3 3 0 016 0z\"/><path d=\"M20.25 10a.25.25 0 01.25-.25.5.5 0 00.5-.5v-3a1.5 1.5 0 00-3 0v3a.5.5 0 00.5.5.25.25 0 01.25.25v3.75a3.509 3.509 0 01-1.267 2.7l-1.463 1.22a.75.75 0 00.96 1.152l1.464-1.22a5 5 0 001.806-3.852z\"/><circle cx=\"9\" cy=\"11.25\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});