define("ember-svg-jar/inlined/natural-disaster-draught", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>natural-disaster-draught</title><circle cx=\"5.5\" cy=\"5.5\" r=\"5.5\"/><path d=\"M9.741 21.43a.251.251 0 00.045-.2.247.247 0 00-.115-.166L7.02 19.446a.251.251 0 00-.223-.019l-6.639 2.62a.251.251 0 00-.158.232V23.5a.5.5 0 00.5.5h7.188a.249.249 0 00.2-.1zM17.13 19.721a.249.249 0 00-.314-.125l-5.279 2a.258.258 0 00-.112.084L9.987 23.6a.25.25 0 00.2.4h8.6a.25.25 0 00.225-.358zM4.843 18.586a.25.25 0 00.038-.446l-3.441-2.1a.248.248 0 00-.13-.04H.5a.5.5 0 00-.5.5v3.629a.25.25 0 00.342.232zM18.693 18.884a.249.249 0 00-.137.342l2.227 4.632a.25.25 0 00.225.142H23.5a.5.5 0 00.5-.5v-6.267a.25.25 0 00-.339-.233zM17.412 17.645a.249.249 0 00.236.032l3.181-1.207a.243.243 0 00-.086-.47.243.243 0 01-.243-.243v-5.485a.248.248 0 01.105-.2l2.872-2.051a1.251 1.251 0 00-1.454-2.036l-1.123.804a.25.25 0 01-.395-.2V5A1.25 1.25 0 0018 5v4.194a.25.25 0 01-.415.188L16.073 8.06a1.249 1.249 0 00-1.646 1.88l3.488 3.053a.25.25 0 01.085.188v2.569a.25.25 0 01-.25.25h-1.831a.25.25 0 00-.147.452zM10.973 20.1a.252.252 0 00.219.021l4.221-1.6a.248.248 0 00.159-.2.252.252 0 00-.1-.236l-2.806-2.04a.249.249 0 00-.148-.045H5.144a.25.25 0 00-.13.463z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});