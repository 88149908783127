define("ember-svg-jar/inlined/mask-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mask-bubble</title><path d=\"M22 0H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zm0 21.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M5.644 14.54a.124.124 0 01.066.158A2.784 2.784 0 009.3 18.29a.125.125 0 01.158.066 2.784 2.784 0 005.091-.026.1.1 0 01.121-.05 2.784 2.784 0 003.62-3.58.124.124 0 01.066-.158 2.784 2.784 0 000-5.08.124.124 0 01-.066-.162 2.784 2.784 0 00-3.59-3.59.125.125 0 01-.158-.066 2.784 2.784 0 00-5.08 0 .125.125 0 01-.162.066A2.784 2.784 0 005.71 9.3a.124.124 0 01-.066.158 2.784 2.784 0 000 5.08z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});