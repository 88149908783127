define("ember-svg-jar/inlined/love-gift-diamond", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-gift-diamond</title><path d=\"M16.46 8.959a.25.25 0 00.259.021l3.144-1.571a.25.25 0 00.034-.427L13.651 2.5a.25.25 0 00-.4.2v3.8a.248.248 0 00.1.2zM17.25 10.546v2.76a.249.249 0 00.138.223l3.25 1.625a.25.25 0 00.362-.223v-6.01a.25.25 0 00-.362-.223l-3.25 1.625a.249.249 0 00-.138.223zM15.75 13.575v-3.15a.249.249 0 00-.1-.2l-3-2.187a.249.249 0 00-.294 0l-3 2.187a.249.249 0 00-.1.2v3.15a.249.249 0 00.1.2l3 2.187a.249.249 0 00.294 0l3-2.187a.249.249 0 00.1-.2zM7.75 13.4v-2.858a.249.249 0 00-.138-.224l-3.25-1.625A.25.25 0 004 8.917v6.023a.247.247 0 00.354.228c.793-.385 2.685-1.3 3.242-1.539a.251.251 0 00.154-.229zM5.141 7.406l3.144 1.572a.248.248 0 00.258-.022l3.109-2.267a.249.249 0 00.1-.2V2.7a.25.25 0 00-.4-.2L5.107 6.979a.25.25 0 00.034.427zM19.984 16.5l-3.145-1.572a.248.248 0 00-.259.022l-3.222 2.35a.248.248 0 00-.1.2v3.788a.25.25 0 00.4.2l6.367-4.565a.25.25 0 00-.041-.423zM8.388 14.931a.249.249 0 00-.266-.018c-.514.275-2.329 1.2-3.11 1.6a.248.248 0 00-.028.424l6.375 4.57a.25.25 0 00.4-.2v-3.794a.249.249 0 00-.1-.2zM23 20.25h-1a.25.25 0 01-.25-.25v-1a.75.75 0 00-1.5 0v1a.25.25 0 01-.25.25h-1a.75.75 0 000 1.5h1a.25.25 0 01.25.25v1a.75.75 0 001.5 0v-1a.25.25 0 01.25-.25h1a.75.75 0 000-1.5zM5 3.75a.75.75 0 000-1.5H4A.25.25 0 013.75 2V1a.75.75 0 00-1.5 0v1a.25.25 0 01-.25.25H1a.75.75 0 000 1.5h1a.25.25 0 01.25.25v1a.75.75 0 001.5 0V4A.25.25 0 014 3.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});