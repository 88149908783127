define("ember-svg-jar/inlined/crafts-carving", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crafts-carving</title><path d=\"M23.5 0H22a.5.5 0 00-.4.207L13.877 10.9a.249.249 0 00.054.347l4.45 3.266a.25.25 0 00.35-.054L23.9 7.406a.5.5 0 00.1-.3V.5a.5.5 0 00-.5-.5zM7.372 16.939A6.818 6.818 0 0010.143 7.7a5.662 5.662 0 00-7.66-2.3 4.729 4.729 0 00-1.919 6.4 3.98 3.98 0 005.39 1.618 3.388 3.388 0 001.374-4.579 1 1 0 00-1.761.947 1.384 1.384 0 01-.562 1.874 1.978 1.978 0 01-2.68-.8 2.725 2.725 0 011.107-3.693 3.658 3.658 0 014.95 1.485 4.817 4.817 0 01-1.958 6.526 1 1 0 00.948 1.761zM13.056 12.46a.243.243 0 00-.186-.045.248.248 0 00-.164.1l-.339.469a2.5 2.5 0 00-.468 1.759.252.252 0 01-.076.208L6.9 19.664a2 2 0 00.821 3.365l3.043.89a2 2 0 002.449-1.264l1.715-4.931a.249.249 0 01.238-.167 2.591 2.591 0 00.406-.03 2.483 2.483 0 001.636-.992l.345-.469a.252.252 0 00-.054-.35zM11.4 21.774a.25.25 0 01-.306.158l-2.4-.7a.25.25 0 01-.1-.421l4.365-4.173a.115.115 0 01.188.12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});