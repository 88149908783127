define("ember-svg-jar/inlined/engagement-move-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>engagement-move-in</title><path d=\"M15.3 12.364a1.25 1.25 0 10-2.436.56A5.251 5.251 0 117.75 8.856c.139 0 .276.006.412.015a1.25 1.25 0 10.176-2.494 8.788 8.788 0 00-.588-.021 7.744 7.744 0 107.55 6.008z\"/><path d=\"M18.693 6.75a.249.249 0 01-.117-.392l1.451-1.833a.655.655 0 000-.984l-1.412-1.235a.649.649 0 00-.43-.162h-3.87a.649.649 0 00-.43.162l-1.412 1.235a.655.655 0 000 .984l1.418 1.848a.25.25 0 01-.118.389 7.76 7.76 0 00-5.055 9.177 1.25 1.25 0 102.43-.59 5.251 5.251 0 115.1 4.007c-.139 0-.276-.006-.412-.016a1.25 1.25 0 10-.176 2.494c.194.014.39.022.588.022A7.751 7.751 0 0018.693 6.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});