define("ember-svg-jar/inlined/coffee-jar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>coffee-jar</title><path d=\"M5.5 3.5h13A.5.5 0 0019 3V1.5A1.5 1.5 0 0017.5 0h-11A1.5 1.5 0 005 1.5V3a.5.5 0 00.5.5zM20.964 10.314l-2-5A.5.5 0 0018.5 5h-13a.5.5 0 00-.464.314l-2 5A.5.5 0 003 10.5v11A2.5 2.5 0 005.5 24h13a2.5 2.5 0 002.5-2.5v-11a.5.5 0 00-.036-.186zM9.64 19a.635.635 0 00-.123.084.25.25 0 01-.34-.011c-1.366-1.352-1.593-3.937-.41-6.308a5.82 5.82 0 014.793-3.51.25.25 0 01.156.446c-1.927 1.541-2.021 3.549-2.1 5.207-.079 1.757-.143 3.145-1.976 4.092zm6.591-2.508a5.921 5.921 0 01-4.569 3.5.25.25 0 01-.181-.437c1.48-1.309 1.564-3.025 1.636-4.572.086-1.862.16-3.469 2.383-4.659a.642.642 0 00.093-.06.249.249 0 01.337.028c1.27 1.374 1.454 3.89.301 6.198z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});