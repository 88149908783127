define("ember-svg-jar/inlined/cloud-monitor-exchange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-monitor-exchange</title><path d=\"M4.1 9.3a.5.5 0 00.8 0l1.5-2a.5.5 0 00-.4-.8h-.5V4.749a1 1 0 011-1h2v.5a.5.5 0 00.276.447.508.508 0 00.224.053.5.5 0 00.3-.1l2-1.5a.5.5 0 000-.8l-2-1.5a.5.5 0 00-.8.4v.5h-2a3 3 0 00-3 3V6.5H3a.5.5 0 00-.4.8zM23.011 2.938a3.336 3.336 0 00-1.883-.907.249.249 0 01-.174-.107A4.426 4.426 0 0017.3 0a4.357 4.357 0 00-4.2 3.1.25.25 0 01-.2.178 2.711 2.711 0 00-1.329.586 2.59 2.59 0 00-.961 2.01 2.489 2.489 0 00.71 1.821 3.161 3.161 0 002.08.805h.017l7.6-.006A3.3 3.3 0 0024 5.249a3.194 3.194 0 00-.989-2.311zM20.922 7h-7.507c-.309 0-1.315-.081-1.315-1.126a1.085 1.085 0 01.41-.849 1.218 1.218 0 01.777-.275 1.294 1.294 0 01.254.025.75.75 0 00.9-.689A2.82 2.82 0 0117.3 1.5a2.894 2.894 0 012.6 1.583.767.767 0 00.7.42 1.826 1.826 0 011.371.514 1.7 1.7 0 01.529 1.232A1.625 1.625 0 0120.922 7zM15 12a2 2 0 00-2-2H2a2 2 0 00-2 2v6.917a2 2 0 002 2h4.25a.25.25 0 01.25.25v.583a.25.25 0 01-.25.25H3.5a1 1 0 000 2h8a1 1 0 000-2H8.75a.25.25 0 01-.25-.25v-.583a.25.25 0 01.25-.25H13a2 2 0 002-2zm-2.25 0a.25.25 0 01.25.25v5.5a.25.25 0 01-.25.25H2.25a.25.25 0 01-.25-.25v-5.5a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});