define("ember-svg-jar/inlined/fossil-energy-plant-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fossil-energy-plant-alternate</title><path d=\"M12 2.5a1 1 0 001-1V1a1 1 0 00-2 0v.5a1 1 0 001 1zM18.76 22.71l-2.66-8.771a.248.248 0 01-.006-.121l.394-1.97a.5.5 0 00-.49-.6H8a.5.5 0 00-.49.6l.394 1.97a.248.248 0 01-.006.121L5.24 22.71A1 1 0 006.8 23.8l5.05-3.8a.249.249 0 01.3 0l5.05 3.8a1 1 0 001.56-1.09zm-8.689-3.877L8.653 19.9a.25.25 0 01-.389-.272l.526-1.738a.251.251 0 01.39-.127l.891.671a.249.249 0 010 .399zm1.779-1.564l-2.2-1.654a.25.25 0 01-.089-.272l.126-.415a.25.25 0 01.239-.178h4.146a.25.25 0 01.239.178l.125.415a.25.25 0 01-.088.272l-2.2 1.654a.249.249 0 01-.298 0zm3.5 2.631l-1.417-1.067a.249.249 0 010-.4l.891-.671a.25.25 0 01.389.127l.527 1.738a.25.25 0 01-.394.273zM9.75 6.75h4.5a.75.75 0 000-1.5h-.394a.25.25 0 01-.249-.229l-.026-.316a1.586 1.586 0 00-3.162 0l-.026.316a.251.251 0 01-.25.229H9.75a.75.75 0 000 1.5zM13.605 7.75H10.4a.25.25 0 00-.249.229L10 9.708a.5.5 0 00.5.542h3a.5.5 0 00.5-.542l-.144-1.729a.25.25 0 00-.251-.229z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});