define("ember-svg-jar/inlined/phone-vibrate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-vibrate</title><path d=\"M3.291 5.322l-1.5-1.938A1 1 0 00.209 4.608l1.026 1.327L.209 7.26a1 1 0 000 1.224L1.235 9.81.209 11.136a1 1 0 000 1.224l1.026 1.326-1.026 1.326a1 1 0 000 1.224l1.026 1.326-1.026 1.326a1 1 0 101.582 1.224l1.5-1.938a1 1 0 000-1.224l-1.026-1.326L3.291 14.3a1 1 0 000-1.224l-1.026-1.328 1.026-1.326a1 1 0 000-1.224L2.265 7.872l1.026-1.326a1 1 0 000-1.224zM22.765 17.562l1.026-1.326a1 1 0 000-1.224l-1.026-1.326 1.026-1.326a1 1 0 000-1.224L22.765 9.81l1.026-1.326a1 1 0 000-1.224l-1.026-1.325 1.026-1.327a1 1 0 00-1.582-1.224l-1.5 1.938a1 1 0 000 1.224l1.026 1.326L20.709 9.2a1 1 0 000 1.224l1.026 1.326-1.026 1.326a1 1 0 000 1.224l1.026 1.326-1.026 1.324a1 1 0 000 1.224l1.5 1.938a1 1 0 001.582-1.224zM16 2H8a2.5 2.5 0 00-2.5 2.5v15A2.5 2.5 0 008 22h8a2.5 2.5 0 002.5-2.5v-15A2.5 2.5 0 0016 2zm.5 14.5a1 1 0 01-1 1h-7a1 1 0 01-1-1v-11a1 1 0 011-1h7a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});