define("ember-svg-jar/inlined/police-man-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-man-3</title><path d=\"M.5 23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-2.106a2.464 2.464 0 00-.967-1.977C21.105 18.328 17.838 16.5 12 16.5s-9.1 1.828-10.534 2.917A2.466 2.466 0 00.5 21.394zm14.25-3.07v-.93a.25.25 0 01.25-.25h3a.25.25 0 01.25.25v.93a2.247 2.247 0 01-1 1.872l-.609.406a.251.251 0 01-.278 0l-.609-.406a2.247 2.247 0 01-1.004-1.872zM19.1 6a.249.249 0 00.242-.311 7.582 7.582 0 00-14.684 0A.249.249 0 004.9 6zm-7.021-1.263a.247.247 0 01-.158 0A2.724 2.724 0 0110.25 2a.25.25 0 01.25-.25h3a.25.25 0 01.25.25 2.724 2.724 0 01-1.671 2.737zM12 16.5a7.008 7.008 0 007-7v-.293A.247.247 0 0119.117 9a1.356 1.356 0 00.446-.436 1.489 1.489 0 00.179-1.154l-.055-.217A.248.248 0 0019.445 7H4.555a.248.248 0 00-.242.189l-.055.217a1.489 1.489 0 00.179 1.154 1.356 1.356 0 00.446.44.247.247 0 01.117.207V9.5a7.008 7.008 0 007 7zm0-2a5 5 0 01-4.982-4.634.251.251 0 01.081-.2.248.248 0 01.208-.066A29.82 29.82 0 0012 10a29.82 29.82 0 004.693-.4.245.245 0 01.208.062.251.251 0 01.081.2A5 5 0 0112 14.5z\"/><path d=\"M13 11.861h-2a.639.639 0 00-.3 1.2 2.354 2.354 0 00.434.179 2.964 2.964 0 00.869.127 3.024 3.024 0 00.87-.125 2.444 2.444 0 00.435-.182.639.639 0 00-.3-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});