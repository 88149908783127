define("ember-svg-jar/inlined/road-sign-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-warning</title><path d=\"M23.25 23.235a.75.75 0 00.661-1.105l-11.25-21a.782.782 0 00-1.322 0l-11.25 21a.75.75 0 00.661 1.105zM12 20.485a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm0-12.25a1 1 0 011 1V14.7a1 1 0 01-2 0V9.235a1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});