define("ember-svg-jar/inlined/single-woman-actions-setting.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-setting</title><path d=\"M1.564 10.442a1 1 0 001.383-.3 5.153 5.153 0 00.454-.9.25.25 0 01.411-.083 4.728 4.728 0 006.787 0 .25.25 0 01.411.082 5.2 5.2 0 00.454.9 1 1 0 001.681-1.081 4.561 4.561 0 01-.645-2.511V5.3a5.3 5.3 0 10-10.593 0v1.248a4.57 4.57 0 01-.64 2.512 1 1 0 00.297 1.382zM7.206 8.6a2.7 2.7 0 01-2.741-2.35.5.5 0 01.324-.578 5.693 5.693 0 002.245-1.44.25.25 0 01.361 0 5.708 5.708 0 002.229 1.437.5.5 0 01.323.576A2.707 2.707 0 017.206 8.6zM11 17.265c.221-.247-.01-.47-.123-.592a3.269 3.269 0 01-.432-3.884.449.449 0 00-.205-.633 7.4 7.4 0 00-3.034-.656 7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h10.068a.542.542 0 00.432-.235z\"/><circle cx=\"17.748\" cy=\"17.031\" r=\"1.5\"/><path d=\"M23.516 15.654a1.783 1.783 0 00-1.7-2.953l-1.411.327a.453.453 0 01-.533-.311l-.424-1.392a1.775 1.775 0 00-3.4 0l-.424 1.392a.454.454 0 01-.533.311l-1.41-.328a1.782 1.782 0 00-1.7 2.952l.987 1.066a.461.461 0 010 .625l-.987 1.065a1.782 1.782 0 001.7 2.952l1.411-.326a.449.449 0 01.533.311l.424 1.392a1.775 1.775 0 003.4 0l.424-1.392a.45.45 0 01.533-.311l1.411.326a1.782 1.782 0 001.7-2.952l-.987-1.065a.461.461 0 010-.625zm-5.768 4.377a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});