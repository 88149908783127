define("ember-svg-jar/inlined/messages-people-person-bubble-circle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-person-bubble-circle-1</title><path d=\"M16.5 0a7.678 7.678 0 00-6.162 3.007 1 1 0 001.6 1.195A5.67 5.67 0 0116.5 2 5.274 5.274 0 0122 7a5.005 5.005 0 01-3.359 4.605 1.008 1.008 0 00-.319.2c-.2.184-2.068 1.773-2.324 1.991v-.858a1 1 0 00-.861-1 5.81 5.81 0 01-.789-.166A1 1 0 0013.8 13.7l.2.054v2.25a1 1 0 001.707.707c.267-.267 3.543-3.054 3.828-3.305A6.973 6.973 0 0024 7a7.271 7.271 0 00-7.5-7z\"/><circle cx=\"13.116\" cy=\"6.5\" r=\"1\"/><circle cx=\"16.366\" cy=\"6.5\" r=\"1\"/><circle cx=\"19.616\" cy=\"6.5\" r=\"1\"/><path d=\"M7.5 16A7.508 7.508 0 000 23.5a.5.5 0 00.5.5h14a.5.5 0 00.5-.5A7.508 7.508 0 007.5 16z\"/><circle cx=\"7.5\" cy=\"10.75\" r=\"4.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});