define("ember-svg-jar/inlined/road-sign-no-bicycle-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-no-bicycle-alternate</title><path d=\"M16.5 10.759a1.3 1.3 0 00-.188.018.247.247 0 00-.144.071l-2.829 2.829a.247.247 0 00-.071.143 1.475 1.475 0 00-.018.189 3.25 3.25 0 103.25-3.25zm0 5a1.752 1.752 0 01-1.75-1.75 1.693 1.693 0 01.158-.715.25.25 0 01.455 0l.451 1.015a.751.751 0 001.372-.609l-.453-1.017a.25.25 0 01.306-.339 1.748 1.748 0 01-.539 3.411z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM5.6 16.987l-1.14 1.139a.25.25 0 01-.375-.024A9.993 9.993 0 0118.1 4.085a.25.25 0 01.024.375L14.888 7.7a.251.251 0 01-.406-.075l-.157-.354a.254.254 0 010-.209.247.247 0 01.16-.134l.71-.194a.75.75 0 00.526-.921.758.758 0 00-.922-.526l-1.274.349a1.251 1.251 0 00-.81 1.713l.6 1.349a.251.251 0 010 .214.254.254 0 01-.169.132l-2.387.53a.25.25 0 01-.295-.175l-.121-.427a.161.161 0 01.027-.145.163.163 0 01.132-.066.75.75 0 000-1.5H8a.75.75 0 000 1.5h.531a.251.251 0 01.241.181l.374 1.311a.249.249 0 01-.036.214l-.27.378a.25.25 0 01-.281.092 3.243 3.243 0 00-2.988 5.674.251.251 0 01.1.183.248.248 0 01-.071.193zm5.477-5.477l-1.7 1.7a.157.157 0 01-.238-.2l1.253-1.754a.246.246 0 01.149-.1l.308-.068a.25.25 0 01.231.42zm-4.439.975a1.648 1.648 0 01.669-.2.251.251 0 01.225.395l-.641.9a.75.75 0 00.61 1.186.136.136 0 01.1.231l-.549.549a.25.25 0 01-.282.05 1.745 1.745 0 01-.128-3.1zM12 22a10.028 10.028 0 01-6.1-2.085.251.251 0 01-.023-.375L19.54 5.874a.25.25 0 01.375.024A9.993 9.993 0 0112 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});