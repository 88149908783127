define("ember-svg-jar/inlined/organic-plant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>organic-plant</title><path d=\"M6.5 20.4a10.09 10.09 0 01-1.4-1.116 1 1 0 00-1.38 1.447 12.031 12.031 0 001.68 1.342A1 1 0 006.5 20.4zM3.423 17.194a9.9 9.9 0 01-.78-1.609 1 1 0 00-1.872.7 11.853 11.853 0 00.938 1.934 1 1 0 101.714-1.03zM15.092 21.561a10 10 0 01-1.745.4 1 1 0 10.267 1.982 12.087 12.087 0 002.095-.477 1 1 0 10-.617-1.9zM10.664 21.96a9.987 9.987 0 01-1.746-.395 1 1 0 10-.616 1.9 11.9 11.9 0 002.1.474 1 1 0 00.265-1.982zM22.649 14.991a1 1 0 00-1.288.583 10.085 10.085 0 01-.779 1.611 1 1 0 101.718 1.029 11.94 11.94 0 00.937-1.935 1 1 0 00-.588-1.288zM18.906 19.281a10.112 10.112 0 01-1.4 1.117 1 1 0 101.1 1.668 12.081 12.081 0 001.68-1.339 1 1 0 10-1.382-1.446zM12 .048a12.013 12.013 0 00-12 12c0 .368.017.731.049 1.089a1 1 0 101.992-.179Q2 12.508 2 12.048a10 10 0 0120 0q0 .461-.041.91a1 1 0 101.992.179c.032-.358.049-.721.049-1.089a12.013 12.013 0 00-12-12z\"/><path d=\"M3.959 10.758a8.055 8.055 0 005.711 7.7.25.25 0 00.322-.246 9.728 9.728 0 011-4.139.5.5 0 00-.045-.517 8.036 8.036 0 00-6.487-3.294.5.5 0 00-.501.496zM11.807 12.22a.251.251 0 00.386 0 9.578 9.578 0 012.462-2.113.5.5 0 00.241-.476A8.864 8.864 0 0012.354 4.2a.5.5 0 00-.708 0A8.883 8.883 0 009.1 9.628a.5.5 0 00.241.477 9.62 9.62 0 012.466 2.115zM13 20.048v-.883a.5.5 0 01.413-.491 8.053 8.053 0 006.628-7.916.5.5 0 00-.5-.5 8.058 8.058 0 00-8.041 7.79v2a.75.75 0 101.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});