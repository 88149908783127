define("ember-svg-jar/inlined/footwear-heels-ankle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>footwear-heels-ankle</title><path d=\"M22.1 15.976a21.263 21.263 0 01-.953-.691l-.006-.005a13.859 13.859 0 01-5.337-7.606 1.462 1.462 0 00-1.709-1.1C9.7 7.423 6.727 5.962 3.834 1.548a1.451 1.451 0 00-1.2-.677 1.436 1.436 0 00-1.249.654A8.554 8.554 0 00.3 3.993a10.5 10.5 0 00.04 5.1 62.347 62.347 0 01.96 12.125 1.945 1.945 0 003.89-.028l-.039-5.681a.5.5 0 01.9-.3 38.975 38.975 0 014.335 6.924 1.934 1.934 0 001.7 1h7.928a3.932 3.932 0 003.911-3.1 3.812 3.812 0 00-1.825-4.057zM2.188 4.5a7.63 7.63 0 01.272-.819.25.25 0 01.434-.055c2.962 4.031 6.328 5.6 10.7 5.016a.5.5 0 01.542.341A15.915 15.915 0 0018.4 15.5a.5.5 0 01-.08.787l-.677.421c-2.069.94-3.627-.622-5.935-3.3-1.94-2.25-4.311-5-7.4-3.8a.5.5 0 01-.542-.12A30.382 30.382 0 002.227 8a.5.5 0 01-.162-.3 7.822 7.822 0 01.123-3.2z\"/><circle cx=\"15.125\" cy=\"14.065\" r=\".98\"/><circle cx=\"12.661\" cy=\"11.205\" r=\".98\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});