define("ember-svg-jar/inlined/settings-toggle-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>settings-toggle-vertical</title><path d=\"M0 21a3 3 0 003 3h5a3 3 0 003-3V3a3 3 0 00-3-3H3a3 3 0 00-3 3zm5.5-.5A1.5 1.5 0 117 19a1.5 1.5 0 01-1.5 1.5zM2 3a1 1 0 011-1h5a1 1 0 011 1v1.75a.5.5 0 01-.5.5h-6a.5.5 0 01-.5-.5zM24 3a3 3 0 00-3-3h-5a3 3 0 00-3 3v18a3 3 0 003 3h5a3 3 0 003-3zm-5.5 17.5A1.5 1.5 0 1120 19a1.5 1.5 0 01-1.5 1.5zm3.5-6.75a.5.5 0 01-.5.5h-6a.5.5 0 01-.5-.5V3a1 1 0 011-1h5a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});