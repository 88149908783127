define("ember-svg-jar/inlined/canoe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>canoe</title><path d=\"M5.834 17.105l-1 1.006a.5.5 0 01-.47.133 1.493 1.493 0 00-1.15.2l-.015.01L.7 20.169A1.5 1.5 0 00.439 22.5l1.066 1.066a1.5 1.5 0 002.316-.241l1.739-2.534a1.494 1.494 0 00.2-1.15.5.5 0 01.133-.47l1.006-1a.75.75 0 00-1.061-1.061zM23.561 1.505L22.5.439a1.5 1.5 0 00-2.321.241L18.44 3.209a1.494 1.494 0 00-.2 1.15.5.5 0 01-.133.47l-1.006 1A.75.75 0 0018.166 6.9l1-1.006a.5.5 0 01.47-.133 1.493 1.493 0 001.15-.2l.015-.01 2.5-1.719a1.5 1.5 0 00.256-2.326zM16.243 7.757C11.454 2.969 4.4 1.981 2.334 1.79a.5.5 0 00-.544.544c.191 2.065 1.179 9.12 5.967 13.909S19.6 22.019 21.666 22.21a.5.5 0 00.544-.544c-.191-2.066-1.179-9.12-5.967-13.909zm-.354 8.132a2.5 2.5 0 01-3.535 0l-2.829-2.828a2.5 2.5 0 113.536-3.536l2.828 2.829a2.5 2.5 0 010 3.535z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});