define("ember-svg-jar/inlined/rss-feed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rss-feed</title><path d=\"M1.5 8.267a1.5 1.5 0 000 3 11.1 11.1 0 0111.438 10.992 1.5 1.5 0 003 0A14.069 14.069 0 001.5 8.267z\"/><path d=\"M1.531.237a1.5 1.5 0 000 3C12.448 3.237 21 11.592 21 22.259a1.5 1.5 0 003 0C24 9.91 14.13.237 1.531.237z\"/><circle cx=\"3.5\" cy=\"20.248\" r=\"3.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});