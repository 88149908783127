define("ember-svg-jar/inlined/multiple-actions-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-player</title><circle cx=\"14.476\" cy=\"3.514\" r=\"2.995\"/><path d=\"M11.447 10.355a.248.248 0 00.263.114 1.347 1.347 0 01.271-.029h6.34a.249.249 0 00.226-.355 4.52 4.52 0 00-7.66-.82.249.249 0 000 .31 7.024 7.024 0 01.56.78zM10.483 11.957a.745.745 0 01.009-.106.247.247 0 00-.021-.137A5.488 5.488 0 000 14.016v3.495a.5.5 0 00.5.5h1.806a.249.249 0 01.249.229L3 23.542a.5.5 0 00.5.458h3.988a.5.5 0 00.5-.458l.442-5.3a.249.249 0 01.249-.229h1.557a.25.25 0 00.25-.25z\"/><circle cx=\"5.491\" cy=\"3.494\" r=\"3.494\"/><path d=\"M24 13.824a1.884 1.884 0 00-1.882-1.882h-8.294a1.885 1.885 0 00-1.882 1.882v8.294A1.884 1.884 0 0013.824 24h8.294A1.884 1.884 0 0024 22.118zM15.947 20.6a.929.929 0 01-.471-.807v-3.639a.928.928 0 011.4-.8l3.029 1.817a.928.928 0 010 1.592l-3.028 1.818a.938.938 0 01-.478.132.922.922 0 01-.452-.113z\"/><circle cx=\"14.476\" cy=\"3.514\" r=\"2.995\"/><path d=\"M11.447 10.355a.248.248 0 00.263.114 1.347 1.347 0 01.271-.029h6.34a.249.249 0 00.226-.355 4.52 4.52 0 00-7.66-.82.249.249 0 000 .31 7.024 7.024 0 01.56.78zM10.483 11.957a.745.745 0 01.009-.106.247.247 0 00-.021-.137A5.488 5.488 0 000 14.016v3.495a.5.5 0 00.5.5h1.806a.249.249 0 01.249.229L3 23.542a.5.5 0 00.5.458h3.988a.5.5 0 00.5-.458l.442-5.3a.249.249 0 01.249-.229h1.557a.25.25 0 00.25-.25z\"/><circle cx=\"5.491\" cy=\"3.494\" r=\"3.494\"/><path d=\"M24 13.824a1.884 1.884 0 00-1.882-1.882h-8.294a1.885 1.885 0 00-1.882 1.882v8.294A1.884 1.884 0 0013.824 24h8.294A1.884 1.884 0 0024 22.118zM15.947 20.6a.929.929 0 01-.471-.807v-3.639a.928.928 0 011.4-.8l3.029 1.817a.928.928 0 010 1.592l-3.028 1.818a.938.938 0 01-.478.132.922.922 0 01-.452-.113z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});