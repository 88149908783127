define("ember-svg-jar/inlined/landmark-cn-tower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-cn-tower</title><path d=\"M9.25 16a1 1 0 000 2H10a.5.5 0 01.5.5v3a.5.5 0 01-.5.5H5.75a1 1 0 000 2h12.5a1 1 0 000-2H14a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h.75a1 1 0 000-2zM6.75 9h.175a.219.219 0 01.213.167 4.988 4.988 0 003.044 3.466.5.5 0 01.318.466v1.4a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V13.1a.5.5 0 01.318-.466 4.994 4.994 0 003.022-3.377.342.342 0 01.332-.257h.078a1 1 0 000-2h-.006a.438.438 0 01-.422-.322 5.01 5.01 0 00-3.459-3.489.5.5 0 01-.363-.481V1a1 1 0 00-2 0v1.708a.5.5 0 01-.364.481 5.011 5.011 0 00-3.5 3.666A.188.188 0 016.95 7h-.2a1 1 0 000 2zm7.907.364a2.975 2.975 0 01-5.313 0A.249.249 0 019.566 9h4.868a.25.25 0 01.223.364zM9.344 6.635a2.975 2.975 0 015.313 0 .25.25 0 01-.223.365H9.566a.25.25 0 01-.222-.365z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});