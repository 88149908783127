define("ember-svg-jar/inlined/army-symbol-navy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-symbol-navy</title><path d=\"M15 11.75a.75.75 0 00-.75.75v.5a2.238 2.238 0 01-1.177 1.967.219.219 0 01-.323-.192V10.5a.25.25 0 01.25-.25h1a.75.75 0 000-1.5h-1a.25.25 0 01-.25-.25V7a.75.75 0 00-1.5 0v1.5a.25.25 0 01-.25.25h-1a.75.75 0 000 1.5h1a.25.25 0 01.25.25v4.222a.25.25 0 01-.375.216A2.237 2.237 0 019.75 13v-.5a.75.75 0 00-1.5 0v.5a3.75 3.75 0 007.5 0v-.5a.75.75 0 00-.75-.75z\"/><path d=\"M23.163 3.88A1.987 1.987 0 0022 3.5H2a1.992 1.992 0 00-1.162.378l-.017.014A1.993 1.993 0 000 5.5v13a2 2 0 002 2h20a2 2 0 002-2v-13a1.993 1.993 0 00-.837-1.62zM4.926 18.5a.25.25 0 01-.145-.453l2.3-1.647a1 1 0 00-1.164-1.626L2.4 17.3a.25.25 0 01-.395-.2V6.909a.25.25 0 01.4-.2l3.513 2.517A1 1 0 007.082 7.6L4.78 5.953a.25.25 0 01.146-.453h14.928l-2.936 2.1a1 1 0 101.164 1.626l3.523-2.521a.25.25 0 01.395.2v10.186a.25.25 0 01-.395.2l-3.523-2.521a1 1 0 00-1.164 1.63l2.3 1.647a.25.25 0 01-.145.453z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});