define("ember-svg-jar/inlined/breakup-couple-man-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>breakup-couple-man-man</title><path d=\"M12.044 5.75H10.5a.749.749 0 01-.7-1l1.353-3.722a.5.5 0 00-.2-.594A2.749 2.749 0 009.147.02 3.1 3.1 0 006.5 3.233c0 2.116 2.6 4.58 4.246 5.924a.25.25 0 00.391-.1l1.141-2.967a.251.251 0 00-.234-.34z\"/><path d=\"M11.928 4.25H13.5a.751.751 0 01.7 1.02l-1.408 3.661a.251.251 0 00.392.284C14.841 7.859 17.5 5.379 17.5 3.233A3.1 3.1 0 0014.854.02a2.754 2.754 0 00-1.773.394.5.5 0 00-.208.254l-1.18 3.247a.249.249 0 00.235.335zM8.366 18.381a.251.251 0 01-.033-.425 4.4 4.4 0 10-5.166 0 .251.251 0 01-.033.425A5.754 5.754 0 000 23.5a.5.5 0 00.5.5H11a.5.5 0 00.5-.5 5.754 5.754 0 00-3.134-5.119zM5.75 16.8a2.4 2.4 0 01-2.276-3.155.25.25 0 01.372-.132 5.643 5.643 0 004 .8.253.253 0 01.211.061.25.25 0 01.081.2A2.4 2.4 0 015.75 16.8zM20.866 18.381a.251.251 0 01-.033-.425 4.4 4.4 0 10-5.166 0 .251.251 0 01-.033.425A5.754 5.754 0 0012.5 23.5a.5.5 0 00.5.5h10.5a.5.5 0 00.5-.5 5.754 5.754 0 00-3.134-5.119zM18.25 16.8a2.4 2.4 0 01-2.276-3.155.25.25 0 01.372-.132 5.643 5.643 0 004 .8.253.253 0 01.211.061.25.25 0 01.081.2A2.4 2.4 0 0118.25 16.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});