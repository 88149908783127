define("ember-svg-jar/inlined/table-retro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>table-retro</title><path d=\"M0 2.75a1.5 1.5 0 001.5 1.5h21a1.5 1.5 0 000-3h-21A1.5 1.5 0 000 2.75zM22 20.75a.229.229 0 01-.215-.3c.177-.572.528-1.38.791-1.982A13.1 13.1 0 0024 13.75a10.665 10.665 0 00-1.913-5.7.5.5 0 01-.087-.284V6.25a.5.5 0 00-.5-.5h-19a.5.5 0 00-.5.5v1.516a.494.494 0 01-.087.281A10.665 10.665 0 000 13.75a13.1 13.1 0 001.424 4.723c.265.606.616 1.417.791 1.986A.225.225 0 012 20.75a1 1 0 000 2h.5a1.721 1.721 0 001.544-.742c.664-1.018.023-2.483-.788-4.337-.109-.248-.219-.5-.328-.757a5.105 5.105 0 01-.394-2.37l.219-2.954a5.079 5.079 0 01.982-2.64.5.5 0 01.39-.2 4.55 4.55 0 002.048-.5 4.829 4.829 0 012.327-.5 3.484 3.484 0 011.964.572 2.774 2.774 0 003.072 0A3.484 3.484 0 0115.5 7.75a4.829 4.829 0 012.327.5 4.536 4.536 0 002.044.5.5.5 0 01.39.2 5.048 5.048 0 01.986 2.64l.219 2.946a5.111 5.111 0 01-.39 2.369c-.11.259-.222.515-.332.766-.811 1.854-1.452 3.319-.788 4.337a1.721 1.721 0 001.544.742h.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});