define("ember-svg-jar/inlined/style-two-pin-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-two-pin-star</title><path d=\"M12 0a9.511 9.511 0 00-9.5 9.5c0 6.547 6.538 12.488 8.541 14.153a1.5 1.5 0 001.917 0c2-1.665 8.542-7.608 8.542-14.154A9.511 9.511 0 0012 0zm4.366 7.627A.613.613 0 0116.8 8.7l-1.921 1.894a.5.5 0 00-.108.556l1.064 2.446a.654.654 0 01-.931.819l-2.655-1.5a.5.5 0 00-.491 0L9.1 14.412a.654.654 0 01-.931-.819l1.063-2.446a.5.5 0 00-.107-.556L7.2 8.7a.613.613 0 01.429-1.07h2.179a.5.5 0 00.454-.289l1.15-2.48a.662.662 0 011.177 0l1.147 2.48a.5.5 0 00.454.289z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});