define("ember-svg-jar/inlined/beach-parasol-water", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beach-parasol-water</title><path d=\"M22.931 21.955a3.423 3.423 0 01-2.677-1.57 1.047 1.047 0 00-1.715.022 3.372 3.372 0 01-2.753 1.558 2.8 2.8 0 01-2.4-1.482 1.044 1.044 0 00-1.718-.156 4.3 4.3 0 01-3.1 1.638 3.558 3.558 0 01-2.76-1.58 1.04 1.04 0 00-1.638-.091c-.014.018-1.458 1.781-3.11 1.7a.99.99 0 00-1.06.949C-.009 23.131.042 24 1.169 24A5.835 5.835 0 004.9 22.393a5.24 5.24 0 003.66 1.572 5.855 5.855 0 003.81-1.5 4.574 4.574 0 003.416 1.5 5.151 5.151 0 003.622-1.5 4.8 4.8 0 003.661 1.482 1 1 0 00-.138-2zM4.718 13.783a2 2 0 001.812.257l6.86-2.365a.249.249 0 01.317.155l1.319 3.79a.25.25 0 01-.206.33 9.411 9.411 0 00-4.414 1.742 1 1 0 00-.219 1.4 1.012 1.012 0 001.4.219A7.944 7.944 0 0116 17.875h7a1 1 0 000-2h-5.59a.251.251 0 01-.236-.168L15.6 11.179a.251.251 0 01.011-.192.254.254 0 01.144-.127l6.839-2.365a2.025 2.025 0 001.245-1.321 1.985 1.985 0 00-.352-1.8 11.017 11.017 0 00-11.26-3.821l-.336-.9a1 1 0 10-1.874.7l.323.867a10.927 10.927 0 00-6.466 9.935 2.043 2.043 0 00.844 1.628zm16.967-7.452a.251.251 0 01-.108.4l-2.826.978a.249.249 0 01-.29-.1 19.525 19.525 0 00-3.452-3.944.25.25 0 01.171-.439 9.006 9.006 0 016.505 3.105zM8.763 5.608a.25.25 0 01.414.234 18.96 18.96 0 00-.311 5.082.251.251 0 01-.168.255l-2.483.859A.251.251 0 015.984 12a.249.249 0 01-.1-.212 8.946 8.946 0 012.879-6.18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});