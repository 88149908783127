define("ember-svg-jar/inlined/reception-toilet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>reception-toilet</title><path d=\"M24 11a1 1 0 00-1-1h-9.4a.25.25 0 01-.2-.407 4.873 4.873 0 001.049-2.38A1.5 1.5 0 0012.965 5.5H10a.5.5 0 01-.5-.5V2.5a.5.5 0 011 0 1 1 0 002 0 2.5 2.5 0 00-5 0V5a.5.5 0 01-.5.5H4.035a1.5 1.5 0 00-1.484 1.713A4.873 4.873 0 003.6 9.593a.25.25 0 01-.2.407H1a1 1 0 00-1 1v12a1 1 0 002 0v-1a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v1a1 1 0 002 0V11zM11.949 7.5a.251.251 0 01.223.364A3.02 3.02 0 019.5 9.5h-2a3.02 3.02 0 01-2.672-1.636.25.25 0 01.223-.364zM9 12.5a1 1 0 11-1-1 1 1 0 011 1zm5.5 6.5a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5v-3.5A.5.5 0 013 15h11a.5.5 0 01.5.5zm3-2.5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});