define("ember-svg-jar/inlined/file-code-warning-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-warning-1</title><path d=\"M14.464 6.451a.75.75 0 00-1.079-1.043l-.017.019L10.7 8.284a.749.749 0 000 1.023l2.667 2.857a.749.749 0 001.06.036l.018-.017a.752.752 0 00.019-1.043l-2.03-2.174a.249.249 0 010-.341zM16.035 11.14a.75.75 0 001.078 1.043l.018-.019L19.8 9.307a.749.749 0 000-1.023l-2.669-2.857a.749.749 0 00-1.06-.036.731.731 0 00-.037 1.06l2.03 2.174a.251.251 0 010 .341z\"/><path d=\"M23.164 3L20.749.587A2 2 0 0019.335 0H7.749a2 2 0 00-2 2v7.9a.246.246 0 00.276.247 7.782 7.782 0 011.448 0 .246.246 0 00.276-.247V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.354.146L21.6 4.268a.5.5 0 01.146.354V18a.5.5 0 01-.5.5h-7.84a.25.25 0 00-.221.366l.787 1.5a.25.25 0 00.221.134h7.556a2 2 0 002-2V4.415A2 2 0 0023.164 3z\"/><path d=\"M8.03 12.272a1.449 1.449 0 00-2.561 0L.414 21.906a1.426 1.426 0 00.047 1.408A1.454 1.454 0 001.694 24h10.111a1.456 1.456 0 001.233-.686 1.428 1.428 0 00.047-1.409zM6 16a.75.75 0 011.5 0v2.5a.75.75 0 01-1.5 0zm.75 6.25a1 1 0 111-1 1 1 0 01-1.001 1.001z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});