define("ember-svg-jar/inlined/fast-food-wrap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fast-food-wrap</title><path d=\"M1.9 18.743a.25.25 0 00-.253.415l4.367 4.365a1.622 1.622 0 002.3 0l1.847-1.845a.251.251 0 00-.136-.424 5.415 5.415 0 01-.683-.158zM16.018 7.414a1 1 0 00.767.745l2.371.495a.249.249 0 00.3-.283A4.013 4.013 0 0015.77 5a.251.251 0 00-.259.308z\"/><path d=\"M23.05 4.34a.5.5 0 01-.185-.428 2.58 2.58 0 00-2.778-2.776.5.5 0 01-.427-.185 2.622 2.622 0 00-3.467-.517.5.5 0 01-.428.06 3.011 3.011 0 00-2.43.306 2.739 2.739 0 00-1.353 3.025.5.5 0 01-.131.48L.467 15.684a1.605 1.605 0 00-.465 1 .5.5 0 00.348.516l9.274 2.932a4.828 4.828 0 001.391.2c.1 0 .21 0 .315-.009a5.049 5.049 0 003.213-1.446l.613-.613A5.035 5.035 0 0016.6 15.06a4.84 4.84 0 00-.183-1.676L14.005 3.328a.734.734 0 01.341-.8.9.9 0 011.111.065 1 1 0 001.643-.251.632.632 0 01.111-.17.614.614 0 01.855-.005.594.594 0 01.164.407 1 1 0 001.66.7.59.59 0 01.833.832 1 1 0 00.7 1.66.6.6 0 01.4.159.613.613 0 01.007.853.641.641 0 01-.177.118 1 1 0 00-.247 1.644c.472.43.1 1.04.021 1.158a.708.708 0 01-.733.3l-3.892-.813a.249.249 0 00-.294.3l.878 3.659a4.752 4.752 0 01.139.6.25.25 0 00.423.135l1.729-1.728a.5.5 0 01.5-.126 2.24 2.24 0 00.653.1 2.773 2.773 0 002.26-1.309 2.983 2.983 0 00.421-2.589.5.5 0 01.058-.429 2.624 2.624 0 00-.519-3.458zM8.785 17.169a.841.841 0 11.841-.841.841.841 0 01-.841.841zm0-3.362a.841.841 0 11.841-.841.841.841 0 01-.841.841zm3.363 0a.841.841 0 11.841-.841.841.841 0 01-.841.841zm0-3.362a.84.84 0 11.841-.84.841.841 0 01-.841.84z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});