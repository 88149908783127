define("ember-svg-jar/inlined/tree-two", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tree-two</title><path d=\"M6.62.84a1.166 1.166 0 00-2.239 0L.072 15.681A1.813 1.813 0 001.813 18H4a.5.5 0 01.5.5V23a1 1 0 002 0v-4.5A.5.5 0 017 18h2.187a1.812 1.812 0 001.741-2.318zM17.5 3a6.5 6.5 0 00-1.393 12.85.5.5 0 01.393.488V23a1 1 0 002 0v-6.662a.5.5 0 01.393-.488A6.5 6.5 0 0017.5 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});