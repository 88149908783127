define("ember-svg-jar/inlined/cd-burn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cd-burn</title><path d=\"M12.477 8.251a8.5 8.5 0 017.814 5.182.251.251 0 00.419.067 5.392 5.392 0 001.324-3.65 7.515 7.515 0 00-2.265-5.271.5.5 0 00-.832.324 1.929 1.929 0 01-2.131 1.655A4.834 4.834 0 0115.67 1a.5.5 0 00-.436-.746c-4.313 0-7.389 2.4-8.125 6.3A2.052 2.052 0 015.23 4.883a.5.5 0 00-.82-.313 6.916 6.916 0 00-2.385 6.13 5.76 5.76 0 001.943 3.638.25.25 0 00.4-.114 8.506 8.506 0 018.109-5.973z\"/><circle cx=\"12.477\" cy=\"16.751\" r=\"1.5\"/><path d=\"M12.477 9.751a7 7 0 107 7 7 7 0 00-7-7zm2.5 7a2.5 2.5 0 11-2.5-2.5 2.5 2.5 0 012.5 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});