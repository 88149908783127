define("ember-svg-jar/inlined/religion-jainism", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-jainism</title><path d=\"M19.005 5.75h-.045a.467.467 0 00-.455.468v5.032a.5.5 0 01-1 0V3.644a.5.5 0 00-.163-.369 1.973 1.973 0 00-1.337-.525 1.918 1.918 0 00-.676.124.5.5 0 00-.324.468v6.908a.5.5 0 01-1 0V1.644a.5.5 0 00-.163-.369 1.966 1.966 0 00-2.675 0 .5.5 0 00-.162.369v9.106a.5.5 0 01-1 0v-7.5a.5.5 0 00-.5-.5 2 2 0 00-2 2v8.6l-1.7-2.077a1.71 1.71 0 00-2.309.087 1.666 1.666 0 00-.228 2.11c.088.183 2.17 4.526 3.889 7.231a5.476 5.476 0 004.644 2.553h3.7a5.507 5.507 0 005.5-5.5v-10a2 2 0 00-1.996-2.004zm-5.364 8a5.752 5.752 0 014.845 2.732.5.5 0 010 .535 5.66 5.66 0 01-9.689 0 .5.5 0 010-.535 5.752 5.752 0 014.844-2.732z\"/><circle cx=\"13.641\" cy=\"16.75\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});