define("ember-svg-jar/inlined/gesture-two-fingers-swipe-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-two-fingers-swipe-left</title><path d=\"M14.829 14.294a.251.251 0 01-.14-.293l1.247-4.658a.93.93 0 00-1.8-.454L12.7 13.481a.249.249 0 01-.3.168l-.585-.143a.25.25 0 01-.19-.243V8.705a1 1 0 00-2 0v7.063a.25.25 0 01-.456.143c-1.489-2.138-3.855-1.449-2.761.224L9.326 20.5a.5.5 0 00.416.223h5.435a.5.5 0 00.492-.411c.2-1.1.664-3.684.775-4.166.256-1.091-.661-1.427-1.615-1.852z\"/><path d=\"M23.7 7.708a1 1 0 00-.27-1.388 20.147 20.147 0 00-11.305-3.047 24.021 24.021 0 00-9.282 1.754.251.251 0 01-.322-.118l-.485-.971a.751.751 0 00-1.411.212L.136 7.085A.749.749 0 00.6 7.9l2.445.978a.75.75 0 00.947-1.028l-.352-.7a.252.252 0 01.128-.35 22.1 22.1 0 018.357-1.531 18.171 18.171 0 0110.19 2.705 1 1 0 001.385-.266z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});