define("ember-svg-jar/inlined/phone-action-play-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-play-1</title><path d=\"M12 14.1v4.4a1 1 0 01-1 1H4a1 1 0 01-1-1v-11a1 1 0 011-1h5.75a.25.25 0 00.25-.25v-2A.25.25 0 009.75 4H3.5A2.5 2.5 0 001 6.5v15A2.5 2.5 0 003.5 24h8a2.5 2.5 0 002.5-2.5v-7.334a.25.25 0 00-.267-.249 8.524 8.524 0 01-1.442-.067.249.249 0 00-.291.25z\"/><path d=\"M22.185 4.872L14 .221A1.722 1.722 0 0012.352.2a1.59 1.59 0 00-.852 1.4v9.3a1.59 1.59 0 00.852 1.4A1.756 1.756 0 0014 12.278l8.183-4.652a1.571 1.571 0 000-2.754z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});