define("ember-svg-jar/inlined/wedding-cake", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wedding-cake</title><path d=\"M18.5 20.5h-5a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h5a1 1 0 001-1v-4.25a2 2 0 00-1.2-1.833.5.5 0 01-.3-.458V9.5a2 2 0 00-1.2-1.833.5.5 0 01-.3-.458V6a2 2 0 00-2-2h-1.175a.251.251 0 01-.178-.426L14.4 2.306a1.3 1.3 0 00.263-1.559A1.456 1.456 0 0012.392.4a.567.567 0 01-.783 0 1.457 1.457 0 00-2.272.347A1.3 1.3 0 009.6 2.306l1.25 1.268a.251.251 0 01-.175.426H9.5a2 2 0 00-2 2v1.209a.5.5 0 01-.3.458A2 2 0 006 9.5v1.959a.5.5 0 01-.3.458 2 2 0 00-1.2 1.833V18a1 1 0 001 1h5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-5a2 2 0 00-2 2v.5a1 1 0 001 1h15a1 1 0 001-1v-.5a2 2 0 00-2-2zm-9-14A.5.5 0 0110 6h4a.5.5 0 01.5.5V7a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5zM8 10a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v1.25a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5zm-1 7a.5.5 0 01-.5-.5v-2.25a.5.5 0 01.5-.5h10a.5.5 0 01.5.5v2.25a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});