define("ember-svg-jar/inlined/link-broken-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>link-broken-1</title><path d=\"M19 6.5h-4a1 1 0 100 2h4a3 3 0 013 3v1a3 3 0 01-3 3h-4a1 1 0 000 2h4a5.006 5.006 0 005-5v-1a5.006 5.006 0 00-5-5zM5 8.5h4a1 1 0 000-2H5a5.006 5.006 0 00-5 5v1a5.006 5.006 0 005 5h4a1 1 0 100-2H5a3 3 0 01-3-3v-1a3 3 0 013-3zM12 1a1 1 0 00-1 1v3a1 1 0 002 0V2a1 1 0 00-1-1zM15 5.493a1 1 0 00.707-.287l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5A.994.994 0 0015 5.493zM8.207 2.292a1 1 0 10-1.414 1.414l1.5 1.5a1.012 1.012 0 001.414 0 1 1 0 000-1.414zM12 18a1 1 0 00-1 1v3a1 1 0 002 0v-3a1 1 0 00-1-1zM15.707 18.792a1 1 0 00-1.414 1.414l1.5 1.5a1 1 0 101.414-1.414zM8.293 18.792l-1.5 1.5a1 1 0 001.414 1.414l1.5-1.5a1 1 0 00-1.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});