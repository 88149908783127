define("ember-svg-jar/inlined/single-woman-book.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-book</title><path d=\"M20.15 4.04a.5.5 0 01-.4-.489V2a2 2 0 00-2-2H5.25a3 3 0 00-3 3v18a3 3 0 003 3h14.5a2 2 0 002-2V6a2 2 0 00-1.6-1.96zM8.585 14.514a.247.247 0 01-.051-.372c.3-.338.51-.746.6-2.085a3.12 3.12 0 116.234 0c.089 1.339.3 1.747.6 2.085a.247.247 0 01-.051.372 3.7 3.7 0 01-2.216.556.25.25 0 00-.25.25v.207a.49.49 0 00.313.459c1.773.669 3.184 1.147 3.522 1.836a5.047 5.047 0 01.465 1.717.253.253 0 01-.062.188.24.24 0 01-.177.079H6.99a.24.24 0 01-.177-.079.249.249 0 01-.062-.188 5.047 5.047 0 01.465-1.717c.337-.689 1.749-1.167 3.522-1.836a.489.489 0 00.313-.459v-.2a.249.249 0 00-.251-.25 3.737 3.737 0 01-2.215-.563zM4.25 3a1 1 0 011-1H17.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H5.25a1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});