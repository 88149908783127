define("ember-svg-jar/inlined/messages-bubble-square-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-warning</title><path d=\"M22 1.5H2A1.5 1.5 0 00.5 3v13.552A1.459 1.459 0 002 18h3v4a.5.5 0 00.785.411L12.156 18H22a1.5 1.5 0 001.5-1.5V3A1.5 1.5 0 0022 1.5zm-11 12a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5zm.5-4V5a1 1 0 011-1 1 1 0 011 1v4.5a1 1 0 01-1 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});