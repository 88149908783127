define("ember-svg-jar/inlined/video-game-kirby", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-kirby</title><path d=\"M23 7.183a2.483 2.483 0 00-2.533-.258l-2.044.943a.5.5 0 01-.692-.322 19.134 19.134 0 01-.453-6.479A1 1 0 0016.089.018c-3.565.682-6.981 4.07-8.88 7.108a5.033 5.033 0 01-.883 1.058 7.014 7.014 0 00-.4.39.247.247 0 01-.218.074.251.251 0 01-.186-.135 6.384 6.384 0 01-.153-.336.25.25 0 01.112-.316A.974.974 0 006 7a1 1 0 00-.768-.974.251.251 0 01-.165-.354C6 3.776 6 3.434 6 3.25 6 2.333 4.16 0 3 0S0 2.333 0 3.25c0 .184 0 .526.934 2.423a.25.25 0 01-.165.353A1 1 0 000 7a.974.974 0 00.52.859.25.25 0 01.113.31A8.622 8.622 0 00.176 11.3a8.152 8.152 0 003.752 6.56 2 2 0 01.722.741 8.536 8.536 0 00.928 1.337 1 1 0 01.229.755l-.228 2.057a1.251 1.251 0 002.288.7l.523-.841a.5.5 0 01.608-.2 8.515 8.515 0 006.022.084.5.5 0 01.6.205l.468.753a1.25 1.25 0 002.287-.7l-.2-1.762a1 1 0 01.256-.785 8.506 8.506 0 002.2-4.914 1 1 0 01.387-.7 7.78 7.78 0 002.959-5.044A2.486 2.486 0 0023 7.183zM2.2 3.048a6.957 6.957 0 01.621-.729.249.249 0 01.356 0 6.839 6.839 0 01.621.729.5.5 0 01.055.492c-.143.333-.367.806-.63 1.338a.25.25 0 01-.448 0c-.263-.532-.487-1-.63-1.337a.5.5 0 01.055-.493zm16.72 10.514a.5.5 0 00-.268.479c.009.137.015.276.015.411A6.545 6.545 0 016.079 16.97a1 1 0 00-.561-.548A5.951 5.951 0 012.177 11.3a5.077 5.077 0 01.663-3.063.253.253 0 01.239-.1.25.25 0 01.2.167c.958 2.819 2.33 2.984 2.907 2.929A.5.5 0 006.56 11 6.6 6.6 0 018.6 8.945c3.258-1.41 5.8 1.016 7.946 1.521a1.992 1.992 0 001.293-.131l3.464-1.6a.487.487 0 01.688.514 5.825 5.825 0 01-3.071 4.313z\"/><path d=\"M13.87 12.953a.75.75 0 00.75-.75v-1a.75.75 0 00-1.5 0v1a.75.75 0 00.75.75zM9.87 12.953a.75.75 0 00.75-.75v-1a.75.75 0 00-1.5 0v1a.75.75 0 00.75.75zM14.261 15.5H9.48a.607.607 0 00-.591.747 3.1 3.1 0 005.963 0 .607.607 0 00-.591-.747z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});