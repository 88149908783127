define("ember-svg-jar/inlined/phone-actions-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-off</title><path d=\"M20.158 13.17a2.56 2.56 0 00-3.536 0l-.385.385a45.16 45.16 0 01-.983-.849.5.5 0 00-.686.021l-3.023 3.022a.5.5 0 00.021.728 46.289 46.289 0 005.147 3.955 3.538 3.538 0 004.4-.442l1.164-1.164a2.5 2.5 0 000-3.535zM7.525 12.436a.5.5 0 00.36.168H7.9a.5.5 0 00.354-.147l3.022-3.023a.5.5 0 00.021-.686q-.432-.487-.848-.982l.386-.386a2.5 2.5 0 000-3.535L8.712 1.724a2.557 2.557 0 00-3.535 0L4.013 2.888a3.509 3.509 0 00-.442 4.4 46.455 46.455 0 003.954 5.148zM23.707.3a1 1 0 00-1.414 0l-22 22a1 1 0 000 1.414 1.01 1.01 0 001.414 0l22-22a1 1 0 000-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});