define("ember-svg-jar/inlined/organic-bag-leaf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>organic-bag-leaf</title><path d=\"M20.528 20.438c.111-.462.225-.921.339-1.378A28.884 28.884 0 0022 12a28.877 28.877 0 00-1.135-7.065c-.113-.456-.227-.913-.337-1.373l.7-1.391A1.5 1.5 0 0019.882 0H4.118a1.5 1.5 0 00-1.342 2.171l.7 1.391c-.111.462-.225.921-.339 1.378A28.884 28.884 0 002 12a28.884 28.884 0 001.133 7.06c.114.457.228.916.339 1.378l-.7 1.391A1.5 1.5 0 004.118 24h15.764a1.5 1.5 0 001.342-2.171zm-5.7-4.517a3.808 3.808 0 01-3.505.561 2.486 2.486 0 01-.873-.671.5.5 0 01.019-.664 8.266 8.266 0 013.674-2.52.5.5 0 00-.236-.972c-2.57.622-5.252 3.719-6.513 5.865a.75.75 0 11-1.294-.762 20.842 20.842 0 012.7-3.506.5.5 0 00.1-.542 2.5 2.5 0 01-.159-1.591 3.208 3.208 0 012.164-2.107 8.29 8.29 0 012.871-.4 8.062 8.062 0 003.337-.57.5.5 0 01.69.362c.51 2.552-1.246 6.396-2.973 7.517z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});