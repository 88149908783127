define("ember-svg-jar/inlined/image-file-eps-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-eps-1</title><path d=\"M23.707 5.707L18.293.293A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.75V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zM2.875 13.875H4a.625.625 0 010 1.25H2.875a.25.25 0 00-.25.25V16.5a.375.375 0 00.375.375h2a.625.625 0 010 1.25H3A1.626 1.626 0 011.375 16.5v-4A1.626 1.626 0 013 10.875h2a.625.625 0 010 1.25H3a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25zm6 1.25h-.5a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-6a.625.625 0 01.625-.625H9a2.128 2.128 0 012.114 2.347 2.213 2.213 0 01-2.239 1.903zm4.552-1.758l1.84 1.227a1.928 1.928 0 01-1.07 3.532H12.5a.625.625 0 010-1.25h1.7a.678.678 0 00.376-1.242l-1.84-1.227a1.928 1.928 0 011.07-3.532h1.7a.625.625 0 010 1.25H13.8a.678.678 0 00-.376 1.242z\"/><path d=\"M9 12.125h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H9a.875.875 0 000-1.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});