define("ember-svg-jar/inlined/gun-silent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gun-silent</title><path d=\"M21.513 2.559L19.392.437a.5.5 0 00-.707 0l-6.158 6.147a.5.5 0 000 .707l2.121 2.121a.5.5 0 00.707 0l6.158-6.146a.5.5 0 000-.707zM14.649 12.24a.5.5 0 000-.707L11.114 8a.5.5 0 00-.707 0L5.1 13.3a.5.5 0 01-.707 0l-.348-.348a1 1 0 00-1.414 1.414l.378.378a.5.5 0 01.053.643 2.506 2.506 0 00.27 3.216l1.109 1.109a1 1 0 001.274.116 2.52 2.52 0 011.68-.594.677.677 0 01.44.3l1.811 3.622a1.006 1.006 0 00.734.54 1.087 1.087 0 00.16.012 1 1 0 00.708-.293l2.09-2.089a1 1 0 00.2-1.13L12.9 18.82a.247.247 0 01.05-.282l.922-.922a2.746 2.746 0 00.515-3.175l-.565-1.13a.251.251 0 01.047-.289zm-3.531-1.408l-6.01 6.01a.5.5 0 11-.708-.707l6.011-6.011a.5.5 0 01.707.708zm1.691 5.723l-.408.408a.252.252 0 01-.22.069.248.248 0 01-.183-.14l-.563-1.206a.251.251 0 01.05-.282l.891-.891a.25.25 0 01.4.065l.267.534a1.249 1.249 0 01-.234 1.443z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});