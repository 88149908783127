define("ember-svg-jar/inlined/shopping-bag-tag-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-bag-tag-1</title><path d=\"M17.358 9H2.147a1.416 1.416 0 00-1.394 1.434v12.132A1.416 1.416 0 002.147 24h15.211a1.416 1.416 0 001.395-1.434V10.434A1.416 1.416 0 0017.358 9zM5.253 8a1 1 0 001-1V5.5a3.5 3.5 0 017 0V7a1 1 0 002 0V5.5a5.5 5.5 0 00-11 0V7a1 1 0 001 1zM22.734 1.729l-.707-.708a1.789 1.789 0 00-2.474 0l-2.537 2.535a1.738 1.738 0 00-.516 1.237V6.5a.75.75 0 00.75.75h1.709a1.759 1.759 0 001.241-.513L22.734 4.2a1.748 1.748 0 000-2.474z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});