define("ember-svg-jar/inlined/love-it-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it-search</title><path d=\"M9.558 16.991a.25.25 0 00-.066-.175l-6.161-6.674a4.422 4.422 0 01-.849-5.16 4.5 4.5 0 013.328-2.42A4.578 4.578 0 019.8 3.846l1.531 1.4a1 1 0 001.347 0l1.561-1.423a4.55 4.55 0 013.965-1.257 4.5 4.5 0 013.329 2.42 4.327 4.327 0 01-.857 5.034.25.25 0 00.034.371 7.092 7.092 0 011.066.975.25.25 0 00.183.084.255.255 0 00.186-.08 6.325 6.325 0 001.175-7.292 6.587 6.587 0 00-10.453-1.71l-.858.783-.829-.751A6.524 6.524 0 005.489.589 6.472 6.472 0 00.7 4.078a6.4 6.4 0 001.2 7.454l7.187 7.787a.25.25 0 00.434-.164z\"/><path d=\"M21.223 19.31a.248.248 0 01-.033-.31 5.542 5.542 0 10-1.69 1.69.25.25 0 01.311.034l2.484 2.484a1 1 0 001.414-1.414zM13 16.031a3.529 3.529 0 113.528 3.528A3.532 3.532 0 0113 16.031z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});