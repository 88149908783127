define("ember-svg-jar/inlined/analytics-board-graph-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>analytics-board-graph-line</title><path d=\"M1.5 21h21a1.5 1.5 0 001.5-1.5v-15A1.5 1.5 0 0022.5 3h-21A1.5 1.5 0 000 4.5v15A1.5 1.5 0 001.5 21zm1.143-5.076l2.571-4.285a1.5 1.5 0 012.573 0l1.665 2.774a.248.248 0 00.2.12.245.245 0 00.211-.089l.991-1.187a1.546 1.546 0 012.3 0l1.194 1.43 4.366-7.641a1 1 0 111.736.992l-4.724 8.272a1.5 1.5 0 01-2.456.213l-1.078-1.292a.252.252 0 00-.384 0l-1.09 1.307a1.5 1.5 0 01-2.439-.189l-1.565-2.61a.25.25 0 00-.428 0l-1.929 3.214a1 1 0 11-1.714-1.029z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});