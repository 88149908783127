define("ember-svg-jar/inlined/type-cursor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>type-cursor</title><path d=\"M1.091 7.248a1.048 1.048 0 00-1.091 1v7a1.048 1.048 0 001.091 1H14a1 1 0 001-1v-7a1 1 0 00-1-1zM22.909 7.248H21a1 1 0 00-1 1v7a1 1 0 001 1h1.909a1.048 1.048 0 001.091-1v-7a1.048 1.048 0 00-1.091-1z\"/><path d=\"M18.5 7.248a3.043 3.043 0 013-3 1 1 0 000-2 4.994 4.994 0 00-3.81 1.765.248.248 0 01-.19.088.252.252 0 01-.191-.088A4.991 4.991 0 0013.5 2.248a1 1 0 000 2 3.043 3.043 0 013 3v9.5a2.942 2.942 0 01-.051.5 3 3 0 01-2.949 2.5 1 1 0 000 2 4.994 4.994 0 003.81-1.765.248.248 0 01.19-.088.255.255 0 01.191.088 4.991 4.991 0 003.809 1.765 1 1 0 000-2 3 3 0 01-2.949-2.5 2.942 2.942 0 01-.051-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});