define("ember-svg-jar/inlined/crypto-currency-ripple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-ripple</title><path d=\"M12 1a11 11 0 1011 11A11 11 0 0012 1zm2.133 12.053l.174.354a.25.25 0 00.271.135 2.255 2.255 0 012.136.786 2.377 2.377 0 01.786 1.7 2.5 2.5 0 01-4.848.813 2.4 2.4 0 01.086-1.885.248.248 0 000-.216l-.308-.627A2 2 0 0010.633 13h-.186a.252.252 0 00-.223.136 2.5 2.5 0 01-4.631-1.827 2.454 2.454 0 011.67-1.7 2.506 2.506 0 012.961 1.259.252.252 0 00.223.136h.386a2 2 0 001.7-.943l.4-.65a.251.251 0 00-.005-.272 2.49 2.49 0 011.432-3.806A2.5 2.5 0 1115 10.25a.492.492 0 01-.067 0 .25.25 0 00-.237.117l-.466.75a2 2 0 00-.097 1.936z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});