define("ember-svg-jar/inlined/rabbit-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rabbit-2</title><path d=\"M22.4 1.906A1.5 1.5 0 0020.2.414c-4.53 2.426-6.24 7.669-6.883 9.642-.083.252-.074.221-.084.26a5.74 5.74 0 00-2.466 0c-.007-.029 0 .006-.084-.262C10.039 8.08 8.329 2.839 3.8.414a1.5 1.5 0 00-2.2 1.492 18.683 18.683 0 005.365 10.823l.025.021A4.979 4.979 0 006 15.682a3.614 3.614 0 00-1 2.5 3.5 3.5 0 003.5 3.5v.25a1.835 1.835 0 001.833 1.833h3.334a1.835 1.835 0 001.833-1.833v-.25a3.5 3.5 0 003.5-3.5 3.61 3.61 0 00-1-2.5 4.97 4.97 0 00-.99-2.93l.026-.022A18.687 18.687 0 0022.4 1.906zM8.5 15.679a1.25 1.25 0 111.25 1.25 1.249 1.249 0 01-1.25-1.25zm5.353 3.353l-1.5 1.5a.5.5 0 01-.707 0l-1.5-1.5a.5.5 0 01.353-.853h3a.5.5 0 01.354.853zm.4-2.1a1.25 1.25 0 111.25-1.25 1.249 1.249 0 01-1.253 1.247z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});