define("ember-svg-jar/inlined/smiley-devastated-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-devastated-1-alternate</title><path d=\"M18.485 10.3a1 1 0 00-1.359.389.778.778 0 01-1.251 0 1 1 0 00-1.75.969A2.69 2.69 0 0016.5 13a2.694 2.694 0 002.375-1.345 1 1 0 00-.39-1.355zM7.5 13a2.69 2.69 0 002.372-1.346 1 1 0 00-1.75-.969.778.778 0 01-1.251 0 1 1 0 10-1.748.971A2.694 2.694 0 007.5 13zM6 8.972c.168 0 4.123-.039 4.97-3.424a1 1 0 00-1.94-.486C8.563 6.927 6.1 6.971 6 6.972a1 1 0 000 2zM18 8.972a1 1 0 000-2c-.105 0-2.567-.044-3.034-1.91a1 1 0 00-1.94.486c.851 3.385 4.806 3.424 4.974 3.424z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M12 14.989a5.7 5.7 0 00-4.941 2.852.44.44 0 00.379.659 21.078 21.078 0 019.123 0 .439.439 0 00.38-.658A5.7 5.7 0 0012 14.989z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});