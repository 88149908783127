define("ember-svg-jar/inlined/design-tool-sharpener", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-tool-sharpener</title><path d=\"M21 6.92V1.5A1.5 1.5 0 0019.5 0h-15A1.5 1.5 0 003 1.5v5.42a.5.5 0 00.134.341A6.93 6.93 0 015 12a6.93 6.93 0 01-1.866 4.739.5.5 0 00-.134.341v5.42A1.5 1.5 0 004.5 24h15a1.5 1.5 0 001.5-1.5v-5.42a.5.5 0 00-.134-.341 6.951 6.951 0 010-9.478A.5.5 0 0021 6.92zM7 13.5V3a1 1 0 011-1h1.25a.25.25 0 01.25.25v15.1a.25.25 0 01-.395.2A4.964 4.964 0 017 13.5zm4-11.25a.25.25 0 01.25-.25H16a1 1 0 011 1v10.5a5 5 0 01-5.79 4.937.248.248 0 01-.21-.246z\"/><circle cx=\"14\" cy=\"8\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});