define("ember-svg-jar/inlined/single-man-actions-flag.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-flag</title><path d=\"M.731 17.878H2.5a.245.245 0 01.244.224l.434 5.2a.488.488 0 00.487.449h3.923a.489.489 0 00.488-.449l.433-5.2a.245.245 0 01.244-.224h1.774a.49.49 0 00.489-.49v-3.429a5.388 5.388 0 10-10.775 0v3.429a.49.49 0 00.49.49z\"/><path d=\"M1.465 4.163A4.164 4.164 0 105.629 0a4.169 4.169 0 00-4.164 4.163zm4.164 2.2a2.206 2.206 0 01-2.2-2.2 2.116 2.116 0 01.154-.789.244.244 0 01.356-.117 5.259 5.259 0 002.771.784 5.247 5.247 0 00.89-.076.2.2 0 01.163.045.2.2 0 01.072.153 2.206 2.206 0 01-2.206 2.204zM23.533 13.174a.486.486 0 00-.469-.033l-.853.4-.026.011a2.991 2.991 0 01-2.647-.368 4.022 4.022 0 00-3.526-.479l-1.243.421a.491.491 0 00-.332.464l.008 9.434a.98.98 0 101.959 0v-3.838a.245.245 0 01.187-.238 2.983 2.983 0 012.4.431 3.939 3.939 0 002.225.688 4.163 4.163 0 001.335-.223l.024-.01.9-.415a.49.49 0 00.284-.445v-5.388a.488.488 0 00-.226-.412z\"/><path d=\"M.731 17.878H2.5a.245.245 0 01.244.224l.434 5.2a.488.488 0 00.487.449h3.923a.489.489 0 00.488-.449l.433-5.2a.245.245 0 01.244-.224h1.774a.49.49 0 00.489-.49v-3.429a5.388 5.388 0 10-10.775 0v3.429a.49.49 0 00.49.49z\"/><path d=\"M1.465 4.163A4.164 4.164 0 105.629 0a4.169 4.169 0 00-4.164 4.163zm4.164 2.2a2.206 2.206 0 01-2.2-2.2 2.116 2.116 0 01.154-.789.244.244 0 01.356-.117 5.259 5.259 0 002.771.784 5.247 5.247 0 00.89-.076.2.2 0 01.163.045.2.2 0 01.072.153 2.206 2.206 0 01-2.206 2.204zM23.533 13.174a.486.486 0 00-.469-.033l-.853.4-.026.011a2.991 2.991 0 01-2.647-.368 4.022 4.022 0 00-3.526-.479l-1.243.421a.491.491 0 00-.332.464l.008 9.434a.98.98 0 101.959 0v-3.838a.245.245 0 01.187-.238 2.983 2.983 0 012.4.431 3.939 3.939 0 002.225.688 4.163 4.163 0 001.335-.223l.024-.01.9-.415a.49.49 0 00.284-.445v-5.388a.488.488 0 00-.226-.412z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});