define("ember-svg-jar/inlined/like-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>like-alternate</title><path d=\"M18.5 23.065H12a8.268 8.268 0 01-3.787-.826C6.933 21.688 5.48 21.065 1 21.065a1 1 0 01-1-1v-9a1 1 0 011-1h3a6.937 6.937 0 006.5-7 2.167 2.167 0 012.245-2.13 2.6 2.6 0 011.935.884 3.591 3.591 0 01.82 2.465 24.042 24.042 0 01-.657 4.476.249.249 0 00.244.3H21.5a2.5 2.5 0 011.337 4.612 2.5 2.5 0 01-1 3 2.5 2.5 0 01-1 3 2.5 2.5 0 01-2.337 3.393zM2 18.833a.249.249 0 00.243.249A15.8 15.8 0 019 20.4a6.34 6.34 0 003 .664h6.5a.5.5 0 000-1 1 1 0 010-2h1a.5.5 0 000-1 1 1 0 110-2h1a.5.5 0 000-1 1 1 0 010-2h1a.5.5 0 000-1h-8a1 1 0 01-.967-1.255 30.852 30.852 0 00.967-5.525 1.745 1.745 0 00-.316-1.138c-.28-.315-.684-.221-.684-.081a8.987 8.987 0 01-8.5 9H2.25a.25.25 0 00-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});