define("ember-svg-jar/inlined/job-seach-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>job-seach-woman</title><path d=\"M10.5 20.25a10.7 10.7 0 01-5.209-1.345.25.25 0 00-.369.188l-.168 1.345a.5.5 0 00.5.562h1.86a.249.249 0 01.247.208l.4 2.374A.5.5 0 008.25 24h4.5a.5.5 0 00.493-.418l.4-2.374a.249.249 0 01.243-.208h1.864a.5.5 0 00.5-.562l-.168-1.345a.25.25 0 00-.369-.188A10.7 10.7 0 0110.5 20.25z\"/><path d=\"M22.311 21.06a1.5 1.5 0 000-2.121l-4.132-4.132a.25.25 0 01-.029-.318 9.274 9.274 0 10-2.41 2.41.25.25 0 01.318.029l4.131 4.132a1.5 1.5 0 002.122 0zM10.322 6.739a.249.249 0 01.356 0 4.344 4.344 0 001.808 1.106.249.249 0 01.165.312 2.249 2.249 0 01-4.3 0 .249.249 0 01.165-.312 4.344 4.344 0 001.806-1.106zM3.25 9.26a7.261 7.261 0 1112.314 5.209.25.25 0 01-.395-.061 5.446 5.446 0 00-2.9-2.576.25.25 0 01-.015-.462 4.25 4.25 0 10-3.55-.018.248.248 0 01.144.24.251.251 0 01-.17.223 4.677 4.677 0 00-2.9 2.5.249.249 0 01-.406.078A7.239 7.239 0 013.25 9.26z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});