define("ember-svg-jar/inlined/aircraft-propeller-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>aircraft-propeller-1</title><path d=\"M1 7a1 1 0 001-1V3.25a1 1 0 00-2 0V6a1 1 0 001 1zM1 10a1 1 0 00-1 1v2.75a1 1 0 002 0V11a1 1 0 00-1-1zM21.326 9.6c.5-.212.994-.435 1.481-.675A2.131 2.131 0 0024 7V3.108a2.108 2.108 0 00-3.9-1.117l-1.5 2.4c-.24.383-.4.612-.856.612h-.282a.248.248 0 01-.2-.1.251.251 0 01-.045-.215l.252-1.007a.75.75 0 10-1.456-.364l-.559 2.237a.243.243 0 01-.107.149A1.973 1.973 0 0114.25 6a1.7 1.7 0 01-1.55-.724.5.5 0 00-.45-.276h-1.005a.249.249 0 00-.23.348l1.286 3a.249.249 0 00.229.152H16a2.244 2.244 0 012.23 1.982.25.25 0 00.334.206c.3-.11.544-.227.864-.35a.248.248 0 01.3.1l.858 1.291a.249.249 0 01.024.232 1.45 1.45 0 00-.108.544A1.5 1.5 0 1022 11a.182.182 0 01-.15-.08l-.635-.956a.25.25 0 01.111-.368z\"/><path d=\"M7.889 13.461a.25.25 0 01-.139-.223v-.988A.25.25 0 018 12h.209a.249.249 0 00.229-.351 2.226 2.226 0 01-.188-.9A2.253 2.253 0 0110.5 8.5h.4a.25.25 0 00.209-.112.253.253 0 00.021-.237L9.07 3.348A.249.249 0 019.3 3h2.95a1.5 1.5 0 000-3h-6a1.5 1.5 0 000 3h.374a.252.252 0 01.23.151l.643 1.5a.25.25 0 01-.23.349H4.75a2 2 0 00-2 2v.25a.25.25 0 01-.25.25H1a1 1 0 000 2h1.5a.25.25 0 01.25.25V10a2 2 0 002 2H6a.25.25 0 01.25.25v.988a.25.25 0 01-.139.223 2 2 0 101.778 0z\"/><path d=\"M9.25 10.75A1.252 1.252 0 0010.5 12H16a1.25 1.25 0 000-2.5h-5.5a1.252 1.252 0 00-1.25 1.25zM20.7 19.391a.247.247 0 01-.165-.163A4.266 4.266 0 0016.5 16.25a4.21 4.21 0 00-3.527 1.888.252.252 0 01-.21.112A4.262 4.262 0 008.5 22.5 1.5 1.5 0 0010 24h11.5a1.5 1.5 0 001.5-1.5 3.254 3.254 0 00-2.3-3.109zm-.023 2.469a.225.225 0 01-.208.14H10.9a.249.249 0 01-.231-.344 2.252 2.252 0 012.081-1.406 2.287 2.287 0 01.5.055 1 1 0 001.161-.613 2.236 2.236 0 014.32.634 1 1 0 00.873.914h.015a1.032 1.032 0 00.124.008h.009a1.242 1.242 0 01.883.364.225.225 0 01.046.248z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});