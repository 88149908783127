define("ember-svg-jar/inlined/plant-flower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plant-flower</title><path d=\"M16.626 14.131a1 1 0 10-1.026 1.713c.43.26.624.513.624.649 0 .436-1.577 1.5-4.5 1.5s-4.5-1.064-4.5-1.5c0-.123.168-.355.544-.6a1 1 0 10-1.086-1.68 2.784 2.784 0 00-1.458 2.278 2.947 2.947 0 00.083.7l1.683 4.341c.219 1.463 2.1 2.461 4.729 2.461s4.51-1 4.728-2.461l1.689-4.341a2.949 2.949 0 00.083-.7 2.844 2.844 0 00-1.593-2.36z\"/><path d=\"M21.164 8.14a.512.512 0 00-.443-.14c-.459.084-2.784.536-3.446 1.2a1.978 1.978 0 00-.57 1.211.244.244 0 01-.136.2l-3.76 1.8v-1.5L15.72 8c.136-.139.355-.087.464-.074a2.18 2.18 0 001.6-.574c.663-.662 1.115-2.987 1.2-3.446a.5.5 0 00-.138-.443.522.522 0 00-.444-.139c-.459.084-2.783.536-3.445 1.2a1.987 1.987 0 00-.539 1.8.239.239 0 01-.063.219l-1.546 1.536V5.347a.242.242 0 01.112-.2 1.99 1.99 0 00.888-1.652c0-.936-1.324-2.9-1.589-3.284a.519.519 0 00-.823 0c-.265.384-1.588 2.348-1.588 3.284a1.99 1.99 0 00.891 1.65.244.244 0 01.112.2v2.734L9.248 6.518c-.082-.074-.043-.2-.031-.265a1.9 1.9 0 00-.555-1.734C8 3.855 5.675 3.4 5.216 3.319a.5.5 0 00-.444.139.5.5 0 00-.138.443c.084.459.537 2.783 1.2 3.446A2.29 2.29 0 007.57 7.9a.279.279 0 01.276.043l2.963 2.963V12.4l-3.363-1.623a.247.247 0 01-.135-.231A1.9 1.9 0 006.725 9.2C6.062 8.537 3.738 8.085 3.279 8a.5.5 0 00-.443.139.5.5 0 00-.139.443c.084.459.536 2.784 1.2 3.445a2 2 0 001.415.585 1.957 1.957 0 00.786-.17.244.244 0 01.2 0l4.509 2.177v1.372a1 1 0 002 0v-1.367l4.726-2.257a.238.238 0 01.217.008 1.965 1.965 0 00.937.239 1.987 1.987 0 001.415-.586c.663-.661 1.115-2.986 1.2-3.445a.5.5 0 00-.138-.443z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});