define("ember-svg-jar/inlined/safety-fire-exit-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-fire-exit-1</title><circle cx=\"4.5\" cy=\"6\" r=\"2.5\"/><path d=\"M14.451 11.671A5.524 5.524 0 009.735 9H9.34a5.492 5.492 0 00-4.177 1.921l-.6.707a2.5 2.5 0 01-1.9.872H1.5a1.5 1.5 0 000 3h1.16a5.492 5.492 0 004.177-1.921l.179-.21a.25.25 0 01.4.03l.236.377a2.486 2.486 0 01-.239 2.971l-.555.634A5.5 5.5 0 005.5 21v1.5a1.5 1.5 0 003 0V21a2.485 2.485 0 01.438-1.4.251.251 0 01.3-.091l2.206.883a1.5 1.5 0 101.114-2.786l-1.612-.644a.251.251 0 01-.147-.3 5.4 5.4 0 00-.384-4.087.25.25 0 01.32-.345 2.448 2.448 0 011.145.988l.335.557a1.5 1.5 0 102.572-1.544zM16.694 4.084a.5.5 0 00-.77.5A4.078 4.078 0 0019.959 8h.954a3.091 3.091 0 011.716.52l.594.4A.5.5 0 0023.5 9a.493.493 0 00.271-.08.5.5 0 00.222-.5A4.078 4.078 0 0020.021 5H19.2a3.738 3.738 0 01-2.072-.627zM20.021 14h-.413a5.084 5.084 0 01-2.821-.854l-.093-.062a.488.488 0 00-.721.2.492.492 0 00-.049.3l.083.332A4.073 4.073 0 0019.959 17h.35a5.087 5.087 0 012.821.854l.093.062A.5.5 0 0023.5 18a.493.493 0 00.271-.08.5.5 0 00.222-.5A4.078 4.078 0 0020.021 14zM23.5 10h-1.08a4.6 4.6 0 01-2.551-.772l-.216-.144a.5.5 0 00-.77.5A4.075 4.075 0 0022.917 13h.583a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5zM22.913 4h.587a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-.821a5.458 5.458 0 01-3.03-.916.5.5 0 00-.77.5A4.075 4.075 0 0022.913 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});