define("ember-svg-jar/inlined/shopping-basket-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-basket-search</title><path d=\"M17.411 11a.108.108 0 00.1-.081l.352-1.337a.5.5 0 00-.483-.627H2.379a.5.5 0 00-.489.6l1.276 5.994A1.956 1.956 0 005.132 17h5.651a.25.25 0 00.249-.225A6.421 6.421 0 0117.411 11zm-6.911.614V14a.5.5 0 01-1 0v-2.386a.5.5 0 011 0zm-3.872 3.031a.488.488 0 01-.085.007.5.5 0 01-.492-.417l-.408-2.385a.5.5 0 01.409-.576.5.5 0 01.577.408l.408 2.386a.5.5 0 01-.409.577zM19.763 6.167a1.441 1.441 0 00-1.439-1.439h-1.142a.25.25 0 01-.222-.135L14.848.538a1 1 0 00-1.774.924l1.511 2.9a.251.251 0 01-.222.366H5.4a.249.249 0 01-.213-.121.246.246 0 01-.008-.245l1.51-2.9A1 1 0 104.916.538L2.8 4.593a.249.249 0 01-.221.135h-1.14a1.439 1.439 0 000 2.878h16.885a1.441 1.441 0 001.439-1.439z\"/><path d=\"M21.659 20.245a.248.248 0 01-.037-.306 4.919 4.919 0 10-1.683 1.682.25.25 0 01.306.038l2.048 2.048a1 1 0 001.414-1.414zM14.5 17.411a2.912 2.912 0 112.911 2.912 2.915 2.915 0 01-2.911-2.912z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});