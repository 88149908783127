define("ember-svg-jar/inlined/email-action-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-download</title><path d=\"M19.6 16.25a.3.3 0 00-.3.008l-5.872 3.694a2.682 2.682 0 01-2.844 0l-5.878-3.693a.3.3 0 00-.3-.009.3.3 0 00-.154.263V22.2A1.8 1.8 0 006.038 24h11.924a1.8 1.8 0 001.788-1.8v-5.687a.3.3 0 00-.15-.263z\"/><path d=\"M11.526 18.427a.9.9 0 00.948 0l7.234-4.552a.088.088 0 00.042-.075 1.8 1.8 0 00-1.788-1.8H6.038a1.8 1.8 0 00-1.788 1.8.089.089 0 00.042.076zM11.45 10.1a1 1 0 001.6 0l3-4a1 1 0 00-.8-1.6H14a.25.25 0 01-.25-.25V1.5a1.5 1.5 0 00-3 0v2.75a.25.25 0 01-.25.25H9.25a1 1 0 00-.8 1.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});