define("ember-svg-jar/inlined/old-people-woman-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>old-people-woman-2</title><path d=\"M12.745 15.072a1.7 1.7 0 01-.811.178 1.8 1.8 0 01-.819-.18.75.75 0 00-.711 1.321 3.317 3.317 0 001.528.359h.006a3.255 3.255 0 001.526-.36.75.75 0 10-.715-1.318zM11.071 12.269a.752.752 0 00-.053-1.06 2.094 2.094 0 00-2.637 0 .751.751 0 001.006 1.114.628.628 0 01.625 0 .751.751 0 001.059-.054zM12.946 11.209a.751.751 0 001.006 1.114.628.628 0 01.625 0 .751.751 0 001.006-1.114 2.094 2.094 0 00-2.637 0z\"/><path d=\"M6.149 14.572a.25.25 0 01.144.187c.511 3.048 4.021 4.991 5.686 4.991s5.176-1.943 5.687-4.989a.252.252 0 01.145-.189 2.731 2.731 0 001.439-2.434 2.116 2.116 0 00-.465-1.443c.388-2.759-.041-4.9-1.275-6.354a5.257 5.257 0 00-1.6-1.267l2.449-1.428A.75.75 0 0017.6.35l-2.783 1.624a3 3 0 00-5.64.02L6.357.35a.75.75 0 00-.755 1.3l2.449 1.424a5.249 5.249 0 00-1.6 1.267C5.216 5.8 4.787 7.935 5.174 10.7a2.112 2.112 0 00-.464 1.444 2.729 2.729 0 001.439 2.428zm11.042-1.366a1.746 1.746 0 00-1 1.3c-.378 2.249-3.176 3.739-4.209 3.739s-3.83-1.49-4.207-3.738a1.747 1.747 0 00-1-1.306 1.275 1.275 0 01-.559-1.1.626.626 0 01.1-.439.752.752 0 00.406-.794 13.374 13.374 0 01-.137-1.12.25.25 0 01.248-.27 6.616 6.616 0 004.807-1.756.249.249 0 01.345 0A6.9 6.9 0 0017.121 9.5a.25.25 0 01.254.27c-.029.354-.071.72-.136 1.1a.752.752 0 00.406.8.616.616 0 01.105.439 1.279 1.279 0 01-.559 1.097zM23.374 23.252a4.405 4.405 0 00-1.127-1.316 7.1 7.1 0 00-3.056-1.157c-.3-.063-2.65-.643-2.65-.643a.493.493 0 00-.36.045l-3.945 2.124a.494.494 0 01-.472 0l-3.945-2.124a.5.5 0 00-.36-.045s-2.347.58-2.65.643a7.107 7.107 0 00-3.057 1.157 4.417 4.417 0 00-1.126 1.316.5.5 0 00.435.748h21.878a.5.5 0 00.435-.748z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});