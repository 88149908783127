define("ember-svg-jar/inlined/developer-community-github-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>developer-community-github-1</title><path d=\"M12 .28a12 12 0 00-3.72 23.41h.29a1 1 0 001.06-1.06v-.21-1.09a.5.5 0 00-.2-.33.5.5 0 00-.42-.1c-2.68.58-3.25-1.1-3.29-1.21A4.64 4.64 0 004 17.5a1.16 1.16 0 00-.15-.11.63.63 0 01.38-.07 1.47 1.47 0 011.15.88 3 3 0 004 1.16.5.5 0 00.29-.36 2 2 0 01.56-1.23.5.5 0 00-.27-.87c-2.37-.27-4.79-1.1-4.79-5.19a4 4 0 011.05-2.78.5.5 0 00.09-.53A3.52 3.52 0 016.32 6a5.54 5.54 0 012.52 1.15.49.49 0 00.42.07A10.61 10.61 0 0112 6.85a10.47 10.47 0 012.75.37.49.49 0 00.41-.07A5.59 5.59 0 0117.68 6a3.57 3.57 0 010 2.38.5.5 0 00.09.53 4 4 0 011.05 2.75c0 4.09-2.43 4.91-4.81 5.18a.5.5 0 00-.27.88 2.2 2.2 0 01.61 1.74v3.18a1.06 1.06 0 00.36.83 1.2 1.2 0 001.06.19A12 12 0 0012 .28z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});