define("ember-svg-jar/inlined/professions-man-office-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-office-1</title><path d=\"M13.171 21.209a.249.249 0 01.073-.286 2 2 0 00.735-1.548.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5 1.991 1.991 0 00.763 1.566.25.25 0 01.077.289l-.784 1.959a.5.5 0 00.464.686h3a.5.5 0 00.464-.686zM19 20.625h-2a.75.75 0 000 1.5h2a.75.75 0 000-1.5zM20.643 17.811l-2.905-.976a.75.75 0 00-.476 1.422l2.88.967a3.264 3.264 0 012.108 3.043v.608a.75.75 0 001.5 0v-.608a4.777 4.777 0 00-3.107-4.456zM6.313 16.709l-2.98 1A4.77 4.77 0 00.25 22.157v.61a.75.75 0 001.5 0v-.61a3.258 3.258 0 012.084-3.034l2.956-.992a.75.75 0 10-.477-1.422zM12.792 12.452a1.14 1.14 0 01-.222.094 1.815 1.815 0 01-.544.079 1.846 1.846 0 01-.548-.08 1.161 1.161 0 01-.224-.094.75.75 0 10-.731 1.31 2.767 2.767 0 00.5.214 3.35 3.35 0 001 .15 3.374 3.374 0 001-.148 2.7 2.7 0 00.5-.216.75.75 0 00-.734-1.309zM11.128 10.161A.749.749 0 0011.09 9.1a2.02 2.02 0 00-2.6 0 .75.75 0 001.022 1.1.559.559 0 01.557 0 .751.751 0 001.059-.039zM12.91 9.1a.75.75 0 001.022 1.1.559.559 0 01.557 0 .75.75 0 001.022-1.1 2.02 2.02 0 00-2.601 0z\"/><path d=\"M17.811 12.447a2.732 2.732 0 001.439-2.434 2.16 2.16 0 00-.4-1.364.252.252 0 01-.049-.185c.334-2.516-.019-4.746-1.29-6.248C16.334.829 14.474.125 11.979.125S7.626.828 6.45 2.216C5.191 3.7 4.821 5.921 5.159 8.464a.252.252 0 01-.049.185 2.156 2.156 0 00-.4 1.365 2.731 2.731 0 001.439 2.433.25.25 0 01.144.187c.511 3.048 4.021 4.991 5.686 4.991s5.176-1.943 5.687-4.989a.252.252 0 01.145-.189zm-.62-1.366a1.746 1.746 0 00-1 1.3c-.378 2.249-3.176 3.739-4.209 3.739s-3.83-1.49-4.207-3.738a1.747 1.747 0 00-1-1.306 1.275 1.275 0 01-.559-1.1v-.132a.249.249 0 01.249-.228h.155a1.754 1.754 0 001.714-1.397l.185-.919a.25.25 0 01.227-.2 5.1 5.1 0 003.07-1.051.251.251 0 01.328 0 5.107 5.107 0 003.07 1.05.25.25 0 01.227.2l.184.922a1.754 1.754 0 001.716 1.406h.184a.219.219 0 01.217.191 1.212 1.212 0 01.008.168 1.281 1.281 0 01-.559 1.095z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});