define("ember-svg-jar/inlined/presentation-projector-screen-budget-analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>presentation-projector-screen-budget-analytics</title><path d=\"M24 1.75a1.5 1.5 0 00-1.5-1.5h-21a1.5 1.5 0 00-.214 2.984.25.25 0 01.214.248v14.543a.251.251 0 01-.2.245 1 1 0 00.2 1.98H11a.249.249 0 01.25.25v.314a.249.249 0 01-.107.2 1.5 1.5 0 101.714 0 .249.249 0 01-.107-.2V20.5a.249.249 0 01.25-.25h9.5a1 1 0 00.2-1.98.249.249 0 01-.2-.245V3.482a.251.251 0 01.214-.248A1.5 1.5 0 0024 1.75zM20.5 18a.25.25 0 01-.25.25H3.75A.25.25 0 013.5 18V3.5a.249.249 0 01.25-.25h16.5a.249.249 0 01.25.25z\"/><path d=\"M8.75 16.25a.75.75 0 00.75.75H19a.75.75 0 000-1.5h-.75a.25.25 0 01-.25-.25v-4.5a1 1 0 00-1-1h-1a1 1 0 00-1 1v4.5a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25v-1.5a1 1 0 00-1-1h-1a1 1 0 00-1 1v1.5a.25.25 0 01-.25.25H9.5a.75.75 0 00-.75.75zM10 10.947a2.3 2.3 0 00-1.025-1.916L7.135 7.8a.3.3 0 01.165-.55H9a1 1 0 00.184-1.984.249.249 0 01-.2-.2 1 1 0 00-1.972.034.25.25 0 01-.195.208 2.295 2.295 0 00-.792 4.156l1.84 1.236a.3.3 0 01-.168.555H6a1 1 0 00-.184 1.983.249.249 0 01.2.2 1 1 0 001.97-.041.249.249 0 01.194-.21 2.292 2.292 0 001.82-2.24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});