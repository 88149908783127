define("ember-svg-jar/inlined/breakup-heart-skull", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>breakup-heart-skull</title><path d=\"M10.3 9.216a1.2 1.2 0 101.2 1.2 1.2 1.2 0 00-1.2-1.2zM13.7 9.216a1.2 1.2 0 101.2 1.2 1.2 1.2 0 00-1.2-1.2z\"/><path d=\"M22 12.56a6.414 6.414 0 001.228-7.483 6.346 6.346 0 00-4.709-3.489 6.5 6.5 0 00-5.661 1.779l-.521.474a.5.5 0 01-.674 0l-.49-.447a6.517 6.517 0 00-5.69-1.806 6.325 6.325 0 00-4.7 3.489A6.4 6.4 0 002 12.545l9.283 9.655a1 1 0 001.435 0zm-6.431.683a1.993 1.993 0 00-.417 1.219v1.061a.9.9 0 01-1.8 0 .225.225 0 00-.45 0 .93.93 0 01-.9.893.876.876 0 01-.3-.06.887.887 0 01-.6-.833.225.225 0 00-.45 0 .9.9 0 01-1.8 0v-1.06a2 2 0 00-.416-1.219 4.5 4.5 0 117.132 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});