define("ember-svg-jar/inlined/microphone-podcast-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>microphone-podcast-person</title><path d=\"M23.8 4.6a1 1 0 00-.2-1.4 8.475 8.475 0 00-2.43-1.273.251.251 0 01-.17-.238V1.5A1.5 1.5 0 0019.5 0h-1A1.5 1.5 0 0017 1.5a.155.155 0 01-.126.153 8.516 8.516 0 00-6.857 7.809.253.253 0 01-.071.16C8.531 11.061 7.873 12.74 8.3 13.919A1.584 1.584 0 009.772 15h.018a.249.249 0 01.249.231A4.66 4.66 0 0011.22 18.3a4.3 4.3 0 002.563 1.13.25.25 0 01.217.248V23a1 1 0 002 0v-4.5a1 1 0 00-1-1 3.406 3.406 0 01-2.368-.621C12.107 16.358 12 15.351 12 14a1 1 0 00-1-1h-.493a.25.25 0 01-.236-.333 4.9 4.9 0 011.4-1.923A1 1 0 0012 10a6.512 6.512 0 014.68-6.24.248.248 0 01.22.039A.252.252 0 0117 4v3a.249.249 0 01-.1.2 3.5 3.5 0 104.2 0A.251.251 0 0121 7V4.385a.251.251 0 01.118-.212.248.248 0 01.242-.012 6.484 6.484 0 011.04.639 1 1 0 001.4-.2zM6 17a.25.25 0 00-.25-.25H4a.5.5 0 010-1h1.75A.25.25 0 006 15.5V14a.25.25 0 00-.25-.25H4a.5.5 0 010-1h1.75A.25.25 0 006 12.5a3 3 0 00-6 0 .25.25 0 00.25.25H2a.5.5 0 010 1H.25A.25.25 0 000 14v1.5a.25.25 0 00.25.25H2a.5.5 0 010 1H.25A.25.25 0 000 17a3 3 0 001.846 2.769A.251.251 0 012 20v1.75a.25.25 0 01-.25.25H1a1 1 0 000 2h4a1 1 0 000-2h-.75a.25.25 0 01-.25-.25V20a.251.251 0 01.154-.231A3 3 0 006 17z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});