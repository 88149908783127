define("ember-svg-jar/inlined/image-document-tiff-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-document-tiff-1</title><path d=\"M24 1.5A1.5 1.5 0 0022.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5zM7 14.125H5.875a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0v-5.125a.25.25 0 00-.25-.25H3a.625.625 0 010-1.25h4a.625.625 0 010 1.25zm4 4.75a.625.625 0 010 1.25H9a.625.625 0 010-1.25h.125a.25.25 0 00.25-.25v-4.25a.25.25 0 00-.25-.25H9a.625.625 0 010-1.25h2a.625.625 0 010 1.25h-.125a.25.25 0 00-.25.25v4.25a.25.25 0 00.25.25zm5-4.75h-2a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25H15a.625.625 0 010 1.25h-1.125a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0v-5A1.626 1.626 0 0114 12.875h2a.625.625 0 010 1.25zm5 0h-2a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25H20a.625.625 0 010 1.25h-1.125a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0v-5A1.626 1.626 0 0119 12.875h2a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});