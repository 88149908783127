define("ember-svg-jar/inlined/style-two-pin-train", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-two-pin-train</title><path d=\"M9.5 10.5a1 1 0 101 1 1 1 0 00-1-1zm.5 1zM14.5 10.5a1 1 0 101 1 1 1 0 00-1-1zm.5 1z\"/><path d=\"M16.615 14.357a.749.749 0 011.028.257l.948 1.58a.5.5 0 00.86-.006A13.441 13.441 0 0021.5 9.5a9.5 9.5 0 00-19 0 13.454 13.454 0 002.049 6.688.5.5 0 00.86.006l.948-1.58a.75.75 0 111.286.772L6.09 17.975a.5.5 0 00.031.56 32.679 32.679 0 004.92 5.118 1.5 1.5 0 001.917 0 32.594 32.594 0 004.922-5.119.5.5 0 00.031-.56l-1.554-2.589a.75.75 0 01.258-1.028zM11 2h2a.75.75 0 010 1.5h-2A.75.75 0 0111 2zM7.5 6A1.5 1.5 0 019 4.5h6A1.5 1.5 0 0116.5 6v2a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5zm9 6a1.5 1.5 0 01-1.5 1.5H9A1.5 1.5 0 017.5 12v-2a.5.5 0 01.5-.5h8a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});