define("ember-svg-jar/inlined/common-file-empty-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-empty-alternate</title><path d=\"M15.318 2.146l4.536 4.536a.5.5 0 01.146.353V21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.465a.5.5 0 01.353.146zM15.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707L16.293.293A1 1 0 0015.586 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});