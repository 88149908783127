define("ember-svg-jar/inlined/style-one-pin-drink", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-drink</title><path d=\"M12 0a9.5 9.5 0 00-4 18.119 2.5 2.5 0 011.218 1.222l1.873 4.076a1 1 0 001.817 0l1.876-4.08A2.5 2.5 0 0116 18.118 9.5 9.5 0 0012 0zm3.5 7.5a3.5 3.5 0 01-2.406 3.32.5.5 0 00-.344.475v1.455a.5.5 0 00.5.5H14a.75.75 0 010 1.5h-4a.75.75 0 010-1.5h.75a.5.5 0 00.5-.5V11.3a.5.5 0 00-.344-.475A3.5 3.5 0 018.5 7.5v-1A1.5 1.5 0 0110 5h4a1.5 1.5 0 011.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});