define("ember-svg-jar/inlined/e-learning-exchange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-learning-exchange</title><path d=\"M15.592 13.3l-2.736.911a1.152 1.152 0 01-.711 0L9.408 13.3a.491.491 0 00-.45.068.5.5 0 00-.208.406v1.091a1.154 1.154 0 00.768 1.085l2.382.841a1.813 1.813 0 001.2 0l2.384-.842a1.152 1.152 0 00.767-1.084v-1.091a.5.5 0 00-.208-.406.492.492 0 00-.451-.068z\"/><path d=\"M18.85 10.544a.5.5 0 00-.342-.474l-5.85-1.95a.5.5 0 00-.316 0l-5.85 1.95a.866.866 0 00-.592.821v2.9a.75.75 0 001.5 0v-2.123a.251.251 0 01.329-.238l4.613 1.538a.493.493 0 00.316 0l5.85-1.95a.5.5 0 00.342-.474z\"/><path d=\"M2 12a10 10 0 0115.867-8.1.5.5 0 01.06.758l-1.134 1.136A1 1 0 0017.5 7.5h4a1 1 0 001-1v-4a1 1 0 00-1.707-.707l-.724.725a.5.5 0 01-.663.039A12 12 0 000 12a1 1 0 002 0zM23 11a1 1 0 00-1 1 10 10 0 01-15.867 8.1.5.5 0 01-.06-.758l1.134-1.135A1 1 0 006.5 16.5h-4a1 1 0 00-1 1v4a1 1 0 001.707.707l.725-.724a.5.5 0 01.662-.039A12 12 0 0024 12a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});