define("ember-svg-jar/inlined/multiple-actions-credit-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-credit-card</title><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.134 11.768a.251.251 0 00.237.17h6.374a.25.25 0 00.249-.264A4.462 4.462 0 0014.5 7.5a4.527 4.527 0 00-3.6 1.76.251.251 0 000 .311 6.99 6.99 0 011.234 2.197zM9 14.938a2.989 2.989 0 011.522-2.6.249.249 0 00.109-.307A5.5 5.5 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.06a.249.249 0 00.25-.25z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M24 15.25a1.752 1.752 0 00-1.75-1.75h-10a1.752 1.752 0 00-1.75 1.75v7A1.752 1.752 0 0012.25 24h10A1.752 1.752 0 0024 22.25zm-1.75 7.25h-10a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25h10a.25.25 0 01.25.25v3a.25.25 0 01-.25.25zm0-7.5a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-10a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25z\"/><path d=\"M21 20.75a.75.75 0 00-.75-.75H19a.75.75 0 000 1.5h1.25a.75.75 0 00.75-.75z\"/><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.134 11.768a.251.251 0 00.237.17h6.374a.25.25 0 00.249-.264A4.462 4.462 0 0014.5 7.5a4.527 4.527 0 00-3.6 1.76.251.251 0 000 .311 6.99 6.99 0 011.234 2.197zM9 14.938a2.989 2.989 0 011.522-2.6.249.249 0 00.109-.307A5.5 5.5 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.06a.249.249 0 00.25-.25z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M24 15.25a1.752 1.752 0 00-1.75-1.75h-10a1.752 1.752 0 00-1.75 1.75v7A1.752 1.752 0 0012.25 24h10A1.752 1.752 0 0024 22.25zm-1.75 7.25h-10a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25h10a.25.25 0 01.25.25v3a.25.25 0 01-.25.25zm0-7.5a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-10a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25z\"/><path d=\"M21 20.75a.75.75 0 00-.75-.75H19a.75.75 0 000 1.5h1.25a.75.75 0 00.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});