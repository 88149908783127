define("ember-svg-jar/inlined/halloween-bat-fly", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>halloween-bat-fly</title><path d=\"M18.941 11.882a.5.5 0 00-.7.409 3.522 3.522 0 01-1.714 2.7.25.25 0 01-.395-.176 2.488 2.488 0 00-.657-1.445.5.5 0 01-.123-.25l-.2-1.128a.5.5 0 00-.822-.29l-.8.706a.5.5 0 01-.279.122 2.241 2.241 0 00-.955.307.5.5 0 01-.296.063l-1.053-.1a.5.5 0 00-.493.719l.483.982a.5.5 0 01.047.284 2.476 2.476 0 00.316 1.6.25.25 0 01-.215.376 3.526 3.526 0 01-2.98-1.161.5.5 0 00-.81.086c-1.432 2.534-.947 4.739-.081 7.462a.5.5 0 00.947.018c1.033-2.857 3.314-2.375 4.347-1.2a.5.5 0 00.641.093 2.176 2.176 0 012.309.182.5.5 0 00.763-.25 2.17 2.17 0 011.755-1.508.472.472 0 00.46-.423c.123-1.566 1.684-3.336 4.22-1.626a.5.5 0 00.754-.574c-.91-2.711-1.817-4.777-4.469-5.978z\"/><path d=\"M12.064.505a12 12 0 00-9.135 19.782 1 1 0 001.522-1.3 10 10 0 1117.5-8.018 1 1 0 101.976-.3A12.077 12.077 0 0012.064.505z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});