define("ember-svg-jar/inlined/money-bag-dollar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>money-bag-dollar</title><path d=\"M9 8h6a1 1 0 000-2H9a1 1 0 000 2zM9.471 4.5a.5.5 0 00.5.438h4.064a.5.5 0 00.5-.438 9.165 9.165 0 011.206-3.049.5.5 0 00-.638-.7l-1.862.8a.25.25 0 01-.33-.137l-.441-1.1a.5.5 0 00-.928 0l-.441 1.1a.25.25 0 01-.33.137L8.9.755a.5.5 0 00-.638.7A9.165 9.165 0 019.471 4.5zM15.438 9.121A.591.591 0 0015.081 9H8.919a.585.585 0 00-.357.121c-2.551 1.95-5.4 5.4-5.4 8.4C3.16 21.75 5.518 24 12 24s8.84-2.25 8.84-6.482c0-2.995-2.85-6.447-5.402-8.397zM12.955 20.13a.25.25 0 00-.205.246v.374a.75.75 0 01-1.5 0v-.323a.25.25 0 00-.25-.25h-.588a.75.75 0 110-1.5h2.152a.671.671 0 00.25-1.294l-2.185-.873a2.164 2.164 0 01.416-4.14.25.25 0 00.205-.246v-.374a.75.75 0 011.5 0v.323a.25.25 0 00.25.25h.588a.75.75 0 110 1.5h-2.152a.671.671 0 00-.25 1.294l2.185.873a2.164 2.164 0 01-.416 4.14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});