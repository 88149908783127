define("ember-svg-jar/inlined/restaurant-food-truck", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>restaurant-food-truck</title><circle cx=\"5\" cy=\"19.75\" r=\"2\"/><path d=\"M24 3.25a1 1 0 00-1-1H9a1 1 0 00-1 1v3.5a.5.5 0 01-.5.5H5.84a1.78 1.78 0 00-1.536.9l-2.159 3.9L.37 13.819a1.237 1.237 0 00-.37.891V19a1.252 1.252 0 001.25 1.25.262.262 0 00.188-.081.255.255 0 00.069-.193c0-.075-.007-.15-.007-.226a3.5 3.5 0 017 0 .5.5 0 00.5.5h6a.5.5 0 00.5-.5 3.5 3.5 0 017 0 .5.5 0 00.5.5 1 1 0 001-1zM8 11.5a.25.25 0 01-.25.25H4.955a.25.25 0 01-.211-.384l1.281-2.02a.5.5 0 01.422-.232h1.3a.25.25 0 01.25.25zm12 1.75a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h7a.5.5 0 01.5.5zm.912-5.783a.5.5 0 01-.412.783h-9a.5.5 0 01-.412-.783l1.714-2.5a.5.5 0 01.412-.217h5.572a.5.5 0 01.412.217z\"/><circle cx=\"19\" cy=\"19.75\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});