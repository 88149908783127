define("ember-svg-jar/inlined/smiley-kiss-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-kiss-2</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM7.5 6a3.187 3.187 0 012.935 2.144 1 1 0 01-1.87.712A1.207 1.207 0 007.5 8a1.207 1.207 0 00-1.065.856 1 1 0 01-1.87-.712A3.187 3.187 0 017.5 6zM13 17.5a1 1 0 010-2 .5.5 0 000-1h-2a1 1 0 010-2h2a2.5 2.5 0 012 4 2.5 2.5 0 01-2 4h-2a1 1 0 010-2h2a.5.5 0 000-1zm5.5-8a1 1 0 01-.935-.644A1.207 1.207 0 0016.5 8a1.207 1.207 0 00-1.065.856 1 1 0 01-1.87-.712 3.081 3.081 0 015.87 0A1 1 0 0118.5 9.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});