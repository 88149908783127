define("ember-svg-jar/inlined/graph-stats-descend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>graph-stats-descend</title><path d=\"M7 13.706a3.357 3.357 0 004.694-.075l2.138-2.137a.483.483 0 01.682 0l3.572 3.572a.24.24 0 010 .341l-1.7 1.7a.964.964 0 00.68 1.647h5.786a.965.965 0 00.964-.964v-5.77a.965.965 0 00-1.645-.683l-1.694 1.688a.24.24 0 01-.34 0l-3.581-3.577a3.456 3.456 0 00-4.773 0l-2.137 2.138a.481.481 0 01-.671.01L2.624 5.642a1.446 1.446 0 10-1.979 2.11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});