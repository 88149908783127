define("ember-svg-jar/inlined/religion-hinduism", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-hinduism</title><path d=\"M23.835 7.175l-1.027-1.028a.564.564 0 00-.65-.106 6.727 6.727 0 01-2.993.7 6.715 6.715 0 01-3.134-.771.564.564 0 00-.675.884A6.739 6.739 0 0020.293 9a6.7 6.7 0 003.43-.941.565.565 0 00.112-.884z\"/><circle cx=\"18.5\" cy=\"2.5\" r=\"2.5\"/><path d=\"M18.684 10.91A7.3 7.3 0 0013.2 9.87a.25.25 0 01-.249-.385 5.686 5.686 0 00.732-3.93A6.291 6.291 0 0010.524.968 6.213 6.213 0 004.92.733a1 1 0 00-.587 1.023L4.448 2.8a1 1 0 001.07.887 3.928 3.928 0 014.1 2.3C10.3 8.089 8.7 9.453 7.535 10.45c-.649.553-1.079.919-1.012 1.413a.763.763 0 00.477.588c2.523 1.177 3.86 2.829 3.765 4.65a3.762 3.762 0 01-2.426 3.252 4.514 4.514 0 01-4-.662A7.956 7.956 0 011.3 15.619a.665.665 0 00-1.289.181A7.772 7.772 0 0011.6 22.994a7.719 7.719 0 003.668-4.684 7.885 7.885 0 00-.354-5.18.246.246 0 01.028-.244.251.251 0 01.225-.1 3.683 3.683 0 011.8.582 3.215 3.215 0 011.3 2.736 11.052 11.052 0 01-.989 4.583.72.72 0 001.053.9 6.583 6.583 0 002.93-5.487 6.207 6.207 0 00-2.577-5.19z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});