define("ember-svg-jar/inlined/punishment-prisoner-transport", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>punishment-prisoner-transport</title><path d=\"M23.375 11.75h-16a.5.5 0 00-.5.5v2.25a.5.5 0 01-.5.5h-5a1.25 1.25 0 000 2.5h5.286a.5.5 0 01.454.291 2.489 2.489 0 002.26 1.459H9.9a.5.5 0 00.5-.458 5.5 5.5 0 0110.958-.053.5.5 0 00.616.44 2.505 2.505 0 001.907-2.429v-4.5a.5.5 0 00-.506-.5z\"/><path d=\"M15.875 15.25a4 4 0 104 4 4 4 0 00-4-4zm0 5a1 1 0 111-1 1 1 0 01-1 1z\"/><rect x=\"12.875\" y=\"6.25\" width=\"5\" height=\"4\" rx=\".5\" ry=\".5\"/><rect x=\"12.875\" y=\".75\" width=\"5\" height=\"4\" rx=\".5\" ry=\".5\"/><path d=\"M22.375.75h-2.5a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3.5a.5.5 0 00.5-.5v-2a1.5 1.5 0 00-1.5-1.5z\"/><rect x=\"19.375\" y=\"6.25\" width=\"4.5\" height=\"4\" rx=\".5\" ry=\".5\"/><path d=\"M7.375 4.75h3.5a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-2.5a1.5 1.5 0 00-1.5 1.5v2a.5.5 0 00.5.5z\"/><rect x=\"6.875\" y=\"6.25\" width=\"4.5\" height=\"4\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});