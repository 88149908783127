define("ember-svg-jar/inlined/scroll-vertical-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>scroll-vertical-1</title><circle cx=\"12\" cy=\"12.076\" r=\"2.5\"/><path d=\"M16.5 16.991h-9a1 1 0 00-.743 1.668l4.5 5a1 1 0 001.486 0l4.5-5a1 1 0 00-.743-1.668zM7.5 6.991h9a1 1 0 00.743-1.669l-4.5-5a1.032 1.032 0 00-1.486 0l-4.5 5A1 1 0 007.5 6.991z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});