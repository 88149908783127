define("ember-svg-jar/inlined/ruler-triangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ruler-triangle</title><path d=\"M23.388 22.2l-3.263-3.262a.249.249 0 00-.353 0L18.6 20.112a.5.5 0 01-.707-.707l1.177-1.176a.252.252 0 000-.354l-2.44-2.439a.249.249 0 00-.353 0L15.1 16.612a.5.5 0 01-.707-.707l1.177-1.176a.252.252 0 000-.354l-2.44-2.439a.249.249 0 00-.353 0L11.6 13.112a.5.5 0 01-.707-.707l1.177-1.176a.252.252 0 000-.354L9.633 8.444a.249.249 0 00-.353 0L8.111 9.612A.5.5 0 017.4 8.9l1.172-1.163a.25.25 0 000-.354L6.133 4.944a.249.249 0 00-.353 0L4.611 6.112a.5.5 0 01-.711-.707l1.172-1.168a.25.25 0 000-.354L1.8.612C1.068-.121.241.3.241 1.259v21a1.5 1.5 0 001.5 1.5h21c.951 0 1.381-.826.647-1.559zM3.741 10.759c0-.55.319-.682.707-.293l9.086 9.086c.389.389.257.707-.293.707h-8.5a1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});