define("ember-svg-jar/inlined/outdoors-kite-flying-cloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-kite-flying-cloud</title><path d=\"M13.423 10.509a.549.549 0 00.737.247l3.7-1.845a.55.55 0 00-.095-1.021 878.058 878.058 0 00-5.376-1.513.55.55 0 00-.639.775zM12.192 11.737a.549.549 0 00.246-.737L10.77 7.654a.55.55 0 00-1 .048 894.204 894.204 0 00-1.97 5.163.55.55 0 00.76.686zM14.651 11.74a.549.549 0 00-.247.737l3.1 6.216a.55.55 0 001.037-.172c.314-2.329.818-6.4 1.045-8.291a.55.55 0 00-.791-.558zM13.42 12.968a.549.549 0 00-.737-.246l-4.078 2.034a.55.55 0 00-.033.966c1.624.954 5.136 3 7.173 4.163a.55.55 0 00.764-.724z\"/><path d=\"M3.274 10A3.643 3.643 0 01.86 9.058 3.017 3.017 0 010 6.849a3.153 3.153 0 012.755-3.127A5.2 5.2 0 017.737 0a4.959 4.959 0 014.229 2.3 3.848 3.848 0 012.679 1.63A1 1 0 0113 5.07a1.857 1.857 0 00-1.6-.8.973.973 0 01-.942-.572A2.968 2.968 0 007.737 2a3.114 3.114 0 00-3.145 2.8 1 1 0 01-1.2.92A1.153 1.153 0 002 6.849 1.126 1.126 0 003.289 8H7a1 1 0 010 2H3.274zM23 24a1 1 0 01-.87-.5.362.362 0 00-.25-.18.371.371 0 00-.3.076 2.371 2.371 0 01-3.537-.675l-1.008-1.765a1 1 0 011.736-.992l1.009 1.765a.37.37 0 00.252.178.363.363 0 00.3-.074 2.372 2.372 0 013.538.675 1 1 0 01-.373 1.364A.991.991 0 0123 24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});