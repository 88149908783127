define("ember-svg-jar/inlined/hardware-nuts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hardware-nuts</title><path d=\"M23.773 16.23l-3.21-5.54A1.5 1.5 0 0019.3 10h-2.78a.5.5 0 00-.432.248l-1.845 3.162-.37.64a3.156 3.156 0 01-1.48 1.24 2.859 2.859 0 01-1.09.21H8.932a.5.5 0 00-.433.25l-.266.46a1.513 1.513 0 00-.01 1.6l3.21 5.5a1.489 1.489 0 001.26.69H19.3a1.5 1.5 0 001.27-.71l3.19-5.46a1.513 1.513 0 00.013-1.6zM15.993 14a3 3 0 11-3 3 3 3 0 013-3z\"/><path d=\"M15.777 6.23L12.567.69a1.5 1.5 0 00-1.26-.69H4.7a1.492 1.492 0 00-1.27.71L.237 6.21a1.513 1.513 0 00-.01 1.6l3.21 5.5A1.489 1.489 0 004.7 14h6.61a1.5 1.5 0 001.27-.71l3.19-5.46a1.513 1.513 0 00.007-1.6zM8 10a3 3 0 113-3 3.009 3.009 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});