define("ember-svg-jar/inlined/smart-watch-square-app-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-app-alternate</title><path d=\"M21 6a3 3 0 00-3-3h-1.078a.25.25 0 01-.24-.181L15.98.365A.5.5 0 0015.5 0h-7a.5.5 0 00-.481.363l-.7 2.456A.249.249 0 017.077 3H6a3 3 0 00-3 3v12a3 3 0 003 3h1.077a.248.248 0 01.24.182l.7 2.455A.5.5 0 008.5 24h7a.5.5 0 00.48-.363l.7-2.455a.249.249 0 01.24-.182H18a3 3 0 003-3zm-2 12a1 1 0 01-1 1H6a1 1 0 01-1-1V6a1 1 0 011-1h12a1 1 0 011 1z\"/><circle cx=\"12\" cy=\"12.252\" r=\"2\"/><circle cx=\"14.25\" cy=\"8.002\" r=\"1\"/><circle cx=\"9.75\" cy=\"8.002\" r=\"1\"/><circle cx=\"14.25\" cy=\"16.002\" r=\"1\"/><circle cx=\"16.75\" cy=\"12.002\" r=\"1\"/><circle cx=\"7.25\" cy=\"12.002\" r=\"1\"/><circle cx=\"9.75\" cy=\"16.002\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});