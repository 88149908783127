define("ember-svg-jar/inlined/smart-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-house</title><path d=\"M24 23a1 1 0 00-1-1H12.75a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25h5.75A1.5 1.5 0 0020 17v-6.293a.25.25 0 01.427-.177l1.543 1.543a.75.75 0 101.06-1.061L12.384.366a1.251 1.251 0 00-1.768 0L.22 10.762a.75.75 0 001.06 1.061l1.293-1.293a.25.25 0 01.427.177V17a1.5 1.5 0 001.5 1.5h5.75a.25.25 0 01.25.25v3a.25.25 0 01-.25.25H1a1 1 0 000 2h22a1 1 0 001-1zM9.5 17a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v5a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});