define("ember-svg-jar/inlined/tags-double", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-double</title><path d=\"M7 1.5a5.236 5.236 0 013.475 1.439l11 11a3.472 3.472 0 01.862 1.442.249.249 0 00.415.1l.66-.659a2 2 0 000-2.827l-11-11A3.749 3.749 0 0010 0H5a1.994 1.994 0 00-1.8 1.142.25.25 0 00.226.357z\"/><path d=\"M12 23.413a1.98 1.98 0 001.415.587 1.986 1.986 0 001.414-.585l3-3 2.586-2.586a2 2 0 000-2.827l-11-11A3.749 3.749 0 007 3H2a2 2 0 00-2 2v5a3.743 3.743 0 001 2.414zM5.5 7A1.5 1.5 0 114 8.5 1.5 1.5 0 015.5 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});