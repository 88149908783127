define("ember-svg-jar/inlined/couple-album-book", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>couple-album-book</title><path d=\"M4.5 19a.5.5 0 01-.5-.5v-16a.5.5 0 01.5-.5h11.75a.5.5 0 01.5.5V11a1 1 0 002 0V2a2 2 0 00-2-2h-15A1.752 1.752 0 000 1.75v17.5A1.752 1.752 0 001.75 21H4.5a1 1 0 000-2z\"/><path d=\"M10.25 13a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 01-1.92-2.56.25.25 0 01.37-.14 4.978 4.978 0 003.256.654.249.249 0 01.209.069.247.247 0 01.074.207A2 2 0 0110.25 19z\"/><path d=\"M10.25 21.5a4.492 4.492 0 00-3.513 1.688.5.5 0 00.391.812h6.245a.5.5 0 00.389-.813A4.491 4.491 0 0010.25 21.5zM19.25 21.5a4.492 4.492 0 00-3.513 1.688.5.5 0 00.391.812h6.245a.5.5 0 00.389-.813A4.491 4.491 0 0019.25 21.5zM23.742 19.33c-.116-.128-.492-.674-.492-2.33a4 4 0 00-8 0c0 1.656-.376 2.2-.492 2.33a1 1 0 101.484 1.34 2.8 2.8 0 00.285-.377.251.251 0 01.36-.068 3.99 3.99 0 004.726 0 .251.251 0 01.36.068 2.8 2.8 0 00.285.377 1 1 0 101.484-1.34zM19.25 19a2 2 0 01-1.95-1.576.25.25 0 01.192-.3 2.967 2.967 0 00.709-.235 2.337 2.337 0 00.852-.683.251.251 0 01.4 0 2.337 2.337 0 00.852.683 2.967 2.967 0 00.709.235.25.25 0 01.192.3A2 2 0 0119.25 19z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});