define("ember-svg-jar/inlined/location-target", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>location-target</title><circle cx=\"12\" cy=\"12\" r=\"4\"/><path d=\"M23 11h-1.623a.5.5 0 01-.493-.421 9.017 9.017 0 00-7.463-7.463.5.5 0 01-.421-.493V1a1 1 0 00-2 0v1.623a.5.5 0 01-.421.493 9.017 9.017 0 00-7.463 7.463.5.5 0 01-.493.421H1a1 1 0 000 2h1.623a.5.5 0 01.493.421 9.017 9.017 0 007.463 7.463.5.5 0 01.421.493V23a1 1 0 002 0v-1.623a.5.5 0 01.421-.493 9.017 9.017 0 007.463-7.463.5.5 0 01.493-.421H23a1 1 0 000-2zm-11 8a7 7 0 117-7 7.008 7.008 0 01-7 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});