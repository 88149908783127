define("ember-svg-jar/inlined/messages-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble</title><path d=\"M12 1.337C5.659 1.337.5 5.587.5 10.811a8.6 8.6 0 003.175 6.54l-2.3 4.587a.5.5 0 00.659.677l6.149-2.862a13.717 13.717 0 003.817.533c6.341 0 11.5-4.25 11.5-9.475S18.341 1.337 12 1.337z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});