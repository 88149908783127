define("ember-svg-jar/inlined/paginate-filter-audio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paginate-filter-audio</title><path d=\"M22 0H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2V2a2 2 0 00-2-2zm-3.97 10.53a.75.75 0 01-1.06-1.06 1.4 1.4 0 00.43-1.578 1 1 0 00-.9-.645 3.176 3.176 0 01-1.391-.33.25.25 0 00-.359.225V13.5A2.75 2.75 0 1112 10.747h1a.25.25 0 00.25-.25V5a.75.75 0 011.374-.416A2.715 2.715 0 0016.5 5.747a2.49 2.49 0 012.287 1.572 2.926 2.926 0 01-.757 3.208z\"/><path d=\"M19.5 22h-17a.5.5 0 01-.5-.5v-17a1 1 0 00-2 0V22a2 2 0 002 2h17.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});