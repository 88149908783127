define("ember-svg-jar/inlined/audio-document-mid-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-document-mid-1</title><path d=\"M17.125 12.5v6a.251.251 0 00.312.246 3.375 3.375 0 000-6.484.251.251 0 00-.312.238z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM9.125 19.5a.625.625 0 01-1.25 0v-4.971c0-.145-.147-.088-.19 0l-.626 1.251a.625.625 0 01-1.118 0s-.579-1.15-.625-1.249-.191-.1-.191 0V19.5a.625.625 0 01-1.25 0v-8a.625.625 0 011.184-.279l1.217 2.435a.251.251 0 00.448 0l1.217-2.435a.625.625 0 011.184.279zm5.375-.625a.625.625 0 010 1.25h-4a.625.625 0 010-1.25h1.125a.25.25 0 00.25-.25v-6.25a.25.25 0 00-.25-.25H10.5a.625.625 0 010-1.25h4a.625.625 0 010 1.25h-1.125a.25.25 0 00-.25.25v6.25a.25.25 0 00.25.25zm2 1.25a.625.625 0 01-.625-.625v-8a.625.625 0 01.625-.625 4.625 4.625 0 010 9.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});