define("ember-svg-jar/inlined/e-commerce-cart-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-cart-monitor</title><path d=\"M16.231 3.67a.75.75 0 00-.96.449l-.306.841a.25.25 0 01-.235.165H8.976a1.242 1.242 0 00-1.043.562 1.222 1.222 0 00-.123 1.137L8.9 9.865a1.248 1.248 0 001.15.76h3.578A1.257 1.257 0 0014.8 9.8l1.88-5.171a.751.751 0 00-.449-.959z\"/><circle cx=\"9.976\" cy=\"12.375\" r=\"1\"/><circle cx=\"13.476\" cy=\"12.375\" r=\"1\"/><path d=\"M24 2.125a2 2 0 00-2-2H2a2 2 0 00-2 2v16a2 2 0 002 2h7.535a.25.25 0 01.239.323 8.384 8.384 0 01-.831 1.8.251.251 0 01-.215.123H7a.75.75 0 000 1.5h10a.75.75 0 000-1.5h-1.74a.251.251 0 01-.216-.123 8.442 8.442 0 01-.825-1.8.25.25 0 01.239-.323h7.514a2.009 2.009 0 002-1.972L24 16.139c0-.01 0-.019-.006-.03a.2.2 0 00.006-.026zm-21 0h18a1 1 0 011 1v11a1 1 0 01-1 1H3a1 1 0 01-1-1v-11a1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});