define("ember-svg-jar/inlined/arrow-button-circle-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-circle-down</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm5.573 8.752l-5.139 8.992a.5.5 0 01-.868 0L6.427 8.752A.5.5 0 016.862 8h10.276a.5.5 0 01.435.748z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});