define("ember-svg-jar/inlined/single-neutral-actions-address.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-address</title><path d=\"M10.777 12a.5.5 0 00-.166-.776 7.343 7.343 0 00-3.155-.724 7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h7.631a.5.5 0 00.493-.416A8.462 8.462 0 0110.777 12z\"/><circle cx=\"7.25\" cy=\"4.75\" r=\"4.75\"/><path d=\"M17.174 10.5a6.753 6.753 0 00-1.76 13.315A7.081 7.081 0 0017 24a6.7 6.7 0 001.735-.227 1 1 0 10-.512-1.932 4.889 4.889 0 01-2.363.027 4.728 4.728 0 01-3.484-3.495 4.8 4.8 0 01.947-4.132 4.718 4.718 0 013.8-1.74 4.923 4.923 0 014.626 5.015v.811a.8.8 0 01-1.594 0v-1.078A3.156 3.156 0 1017 20.405a3.106 3.106 0 001.476-.377.249.249 0 01.312.062 2.793 2.793 0 004.962-1.763v-.811a6.932 6.932 0 00-6.576-7.016zM17 18.406a1.157 1.157 0 111.156-1.157A1.157 1.157 0 0117 18.406z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});