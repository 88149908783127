define("ember-svg-jar/inlined/email-action-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-home</title><path d=\"M18.409 15.131a.758.758 0 01-.409.119H6a.757.757 0 01-.405-.119l-5.21-3.35a.25.25 0 00-.385.21V22a2 2 0 002 2h20a2 2 0 002-2V11.993a.25.25 0 00-.385-.21z\"/><path d=\"M5.12 13.041a.25.25 0 00.385-.21L5.5 2.25A.249.249 0 015.75 2h12.5a.25.25 0 01.25.25l.01 10.575a.25.25 0 00.385.21l4.053-2.607a.248.248 0 00.115-.206.251.251 0 00-.107-.21L20.5 8.3V2.207a2.114 2.114 0 00-2-2.208h-13a2.115 2.115 0 00-2 2.209V8.3l-2.458 1.71a.249.249 0 00.007.415z\"/><path d=\"M12.031 3.579L7.12 7.412a.329.329 0 00-.1.369.338.338 0 00.319.219h.911a.25.25 0 01.25.25v3.25a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-1.25a1.25 1.25 0 012.5 0v1.25a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5V8.25a.25.25 0 01.25-.25h.911a.338.338 0 00.318-.219.329.329 0 00-.1-.369l-4.91-3.833a.344.344 0 00-.438 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});