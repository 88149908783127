define("ember-svg-jar/inlined/arrow-double-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-double-down</title><path d=\"M18.4 11.239a1 1 0 00-1.082.19l-5.162 4.856a.25.25 0 01-.343 0l-5.126-4.849A1 1 0 005 12.163v4.757a1.006 1.006 0 00.312.726l5.98 5.66a1 1 0 001.373 0l6.021-5.661a1 1 0 00.314-.726v-4.762a1 1 0 00-.6-.918z\"/><path d=\"M18.4.506A.987.987 0 0017.314.7l-5.162 4.851a.25.25 0 01-.343 0L6.687.7A1 1 0 005 1.429v4.756a1.008 1.008 0 00.312.727l5.98 5.661a1.005 1.005 0 001.373 0l6.021-5.662A1 1 0 0019 6.184V1.423a.986.986 0 00-.6-.917z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});