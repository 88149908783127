define("ember-svg-jar/inlined/login-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>login-key</title><path d=\"M23.5 0h-3a.5.5 0 00-.354.147l-9.53 9.531a7.5 7.5 0 103.707 3.707l2.312-2.313 1.933.644a1 1 0 001.3-1.1L19.507 8.2l.726-.726 1.456.662a1 1 0 001.378-1.18l-.506-1.809 1.293-1.293A.5.5 0 0024 3.5v-3a.5.5 0 00-.5-.5zM7 18.5A1.5 1.5 0 115.5 17 1.5 1.5 0 017 18.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});