define("ember-svg-jar/inlined/real-estate-market-house-fall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-market-house-fall</title><path d=\"M23.832 6.626L16.664.252a1 1 0 00-1.329 0L8.167 6.626A.5.5 0 008.5 7.5h2a.5.5 0 01.5.5v2.929a.5.5 0 00.72.449 3.72 3.72 0 014.419.879l1.4 1.576a.5.5 0 00.374.167H20a1 1 0 001-1V8a.5.5 0 01.5-.5h2a.5.5 0 00.332-.874z\"/><path d=\"M21.72 18.72l-.645.644a.5.5 0 01-.73-.024l-5.323-6.087a2.255 2.255 0 00-3.521.2l-2.036 2.896a.5.5 0 01-.775.054L2.165 9.4a1.251 1.251 0 00-1.83 1.7l7.155 7.682a2.249 2.249 0 003.488-.236L13 15.675a.5.5 0 01.786-.041l4.834 5.529a.5.5 0 01-.023.682l-.874.875A.75.75 0 0018.25 24h4a.75.75 0 00.75-.75v-4a.75.75 0 00-1.28-.53z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});