define("ember-svg-jar/inlined/famous-character-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character-2</title><path d=\"M9.221 8.559l1 .5a.618.618 0 00.279.066.625.625 0 00.279-1.184l-1-.5a.625.625 0 10-.558 1.118zM13.5 9.125a.618.618 0 00.279-.066l1-.5a.625.625 0 00-.558-1.118l-1 .5a.625.625 0 00.279 1.184zM10.875 11.5a.625.625 0 00.625.625h1a.625.625 0 000-1.25h-1a.625.625 0 00-.625.625z\"/><path d=\"M7 14.5h2.692A1.982 1.982 0 0011 15h2a1.982 1.982 0 001.309-.5H17a1.5 1.5 0 001.5-1.5V6.472a6.5 6.5 0 00-13 .011V13A1.5 1.5 0 007 14.5zm1-7.006a2.48 2.48 0 013.269-2.36c.07.023.206.036.206-.131v-.686a.394.394 0 00-.126-.261l-.7-.7a.5.5 0 01.708-.708s.48.479.533.534a.165.165 0 00.22.006l.539-.54a.5.5 0 01.708.708s-.729.724-.787.786a.3.3 0 00-.092.24V5c0 .161.1.188.151.169A2.483 2.483 0 0116 7.494v.757a3.241 3.241 0 01-1.576 2.327A1 1 0 0014 11.4V13a1 1 0 01-1 1h-2a1 1 0 01-1-1v-1.6a1 1 0 00-.424-.817A3.241 3.241 0 018 8.251zM22.533 18.417a10.369 10.369 0 00-.974-.655.25.25 0 00-.314.047 9.328 9.328 0 01-1.959 1.643.257.257 0 01-.334-.052l-.974-1.169a.246.246 0 01.065-.368 7.9 7.9 0 001.248-.93c.063-.059.053-.23-.015-.256A20.828 20.828 0 0012 15.5a20.871 20.871 0 00-7.212 1.154c-.09.033-.079.28.019.367a8.039 8.039 0 001.117.823.246.246 0 01.064.367l-.973 1.168a.257.257 0 01-.335.051 9.337 9.337 0 01-1.925-1.621.25.25 0 00-.314-.047 10.34 10.34 0 00-.973.655A2.459 2.459 0 00.5 20.394V23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-3.106a2.461 2.461 0 00-.967-1.977zM11.225 21.16a.258.258 0 01-.272.255 14.052 14.052 0 01-4.417-1.009.254.254 0 01-.093-.4l1.014-1.217a.242.242 0 01.272-.072 12.182 12.182 0 003.266.7.246.246 0 01.23.244zm6.2-.737a14.084 14.084 0 01-4.43 1 .257.257 0 01-.271-.255v-1.5a.245.245 0 01.23-.244 12.213 12.213 0 003.279-.686.242.242 0 01.271.072l1.015 1.218a.255.255 0 01-.093.395z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});