define("ember-svg-jar/inlined/single-neutral-idea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-idea</title><path d=\"M5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M21.751 10.5a.5.5 0 00.341-.135 6 6 0 10-8.18 0 .5.5 0 00.342.134zM15.5 11.5a.5.5 0 00-.5.5 2 2 0 001.778 1.988.249.249 0 01.222.248V15a1 1 0 002 0v-.764a.25.25 0 01.222-.248A2 2 0 0021 12a.5.5 0 00-.5-.5zM5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M21.751 10.5a.5.5 0 00.341-.135 6 6 0 10-8.18 0 .5.5 0 00.342.134zM15.5 11.5a.5.5 0 00-.5.5 2 2 0 001.778 1.988.249.249 0 01.222.248V15a1 1 0 002 0v-.764a.25.25 0 01.222-.248A2 2 0 0021 12a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});