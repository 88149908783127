define("ember-svg-jar/inlined/locker-room-suitcase-key-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>locker-room-suitcase-key-1</title><path d=\"M16.25 13a.5.5 0 01-.5-.5v-1.25a2 2 0 00-2-2h-3.5a2 2 0 00-2 2v1.25a.5.5 0 01-.5.5.5.5 0 00-.5.5v10a.5.5 0 00.5.5h8.5a.5.5 0 00.5-.5v-10a.5.5 0 00-.5-.5zm-2.5-.5a.5.5 0 01-.5.5h-2.5a.5.5 0 01-.5-.5v-.75a.5.5 0 01.5-.5h2.5a.5.5 0 01.5.5zM19.5 13h-.75a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h.75a1.5 1.5 0 001.5-1.5v-8a1.5 1.5 0 00-1.5-1.5zM5.25 13H4.5A1.5 1.5 0 003 14.5v8A1.5 1.5 0 004.5 24h.75a.5.5 0 00.5-.5v-10a.5.5 0 00-.5-.5zM19.354 4.146l-1.5-1.5A.5.5 0 0017.5 2.5h-5.968a4.5 4.5 0 100 4H17.5a.5.5 0 00.354-.146l1.5-1.5a.5.5 0 000-.708zM7.5 6.5a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});