define("ember-svg-jar/inlined/content-brush-pen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-brush-pen</title><path d=\"M16.134 11.046l-3-3a.5.5 0 00-.707 0L3.99 16.479a1.5 1.5 0 000 2.123l1.587 1.586a1.5 1.5 0 002.121 0l8.436-8.435a.5.5 0 000-.707zM2.342 20.3l-2 2a1 1 0 000 1.414 1.011 1.011 0 001.414 0l2-2A1 1 0 002.342 20.3zM17.194 9.985a.5.5 0 00.708 0l3.421-3.421.381.382a1.218 1.218 0 010 1.585l-3.854 3.854a1 1 0 000 1.414 1.019 1.019 0 001.414 0l3.854-3.853a3.211 3.211 0 000-4.415L19.383 1.8a.5.5 0 00-.707 0l-4.354 4.35c-.195.2-.323.641-.128.835zM4.718 11.742a3.745 3.745 0 003.632-1.628 4.073 4.073 0 00.529-3.766A4.332 4.332 0 005.764 3.7C4.248 3.215 2.937 2.8 3.05.524a.5.5 0 00-.293-.479A.5.5 0 002.2.14 6.839 6.839 0 00.333 7.274c.71 2.308 2.514 4.145 4.385 4.468zM15.646 14.6a.5.5 0 00-.661.041l-1.932 1.931a.5.5 0 00-.147.364.506.506 0 00.161.358l7.145 6.568a.5.5 0 00.339.132h.589a2.347 2.347 0 001.775-.492A3.435 3.435 0 0023.551 21a.5.5 0 00-.194-.394z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});