define("ember-svg-jar/inlined/bluetooth-transfer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bluetooth-transfer</title><path d=\"M13.992 12.179a.25.25 0 01-.08-.184.248.248 0 01.081-.184l4.6-4.221a1.518 1.518 0 00.484-1.08 1.5 1.5 0 00-.444-1.089L13.807.635A1.5 1.5 0 0011.25 1.7v7.388a.251.251 0 01-.419.185l-4.9-4.51a1 1 0 00-1.358 1.473l6.063 5.574a.25.25 0 01.08.184.248.248 0 01-.081.184l-6.087 5.584A1 1 0 105.9 19.237l4.932-4.525a.25.25 0 01.419.185v7.4a1.5 1.5 0 002.556 1.065l4.828-4.786a1.5 1.5 0 00-.04-2.17zM13.25 3.5a.25.25 0 01.426-.178l2.991 2.965a.245.245 0 01.074.182.248.248 0 01-.081.18l-2.991 2.745a.25.25 0 01-.419-.185zm.426 17.177a.25.25 0 01-.426-.177v-5.718a.25.25 0 01.419-.184l2.993 2.752a.249.249 0 01.08.179.244.244 0 01-.074.182z\"/><rect x=\"18.592\" y=\"10.166\" width=\"3.667\" height=\"3.667\" rx=\".648\" ry=\".648\" transform=\"rotate(-45.001 20.426 12)\"/><rect x=\"1.741\" y=\"10.166\" width=\"3.667\" height=\"3.667\" rx=\".648\" ry=\".648\" transform=\"rotate(-45.001 3.574 12)\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});