define("ember-svg-jar/inlined/breakup-couple-man-man-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>breakup-couple-man-man-1</title><path d=\"M12.044 5.75H10.5a.75.75 0 01-.7-1.006l1.353-3.721a.5.5 0 00-.2-.594A2.75 2.75 0 009.146.02 3.1 3.1 0 006.5 3.233c0 2.115 2.6 4.58 4.246 5.924a.25.25 0 00.391-.1l1.141-2.967a.251.251 0 00-.234-.34z\"/><path d=\"M14.854.02a2.748 2.748 0 00-1.773.394.5.5 0 00-.208.254l-1.18 3.247a.249.249 0 00.235.335H13.5a.75.75 0 01.7 1.02l-1.408 3.661a.25.25 0 00.391.284C14.841 7.859 17.5 5.379 17.5 3.233A3.1 3.1 0 0014.854.02zM6.847 13.47a.247.247 0 01-.119-.2.251.251 0 01.094-.211 2.918 2.918 0 10-3.616 0 .251.251 0 01.094.211.246.246 0 01-.118.2 3.515 3.515 0 00-1.682 3v2.011a.5.5 0 00.5.5h.6a.5.5 0 01.5.45l.413 4.123a.5.5 0 00.5.45h2.01a.5.5 0 00.5-.45l.412-4.123a.5.5 0 01.5-.45h.6a.5.5 0 00.5-.5v-2.014a3.514 3.514 0 00-1.688-2.997zm-1.833-1.279A1.419 1.419 0 013.6 10.773a1.481 1.481 0 01.028-.285.25.25 0 01.367-.168 3.576 3.576 0 001.746.452 3.453 3.453 0 00.4-.022.251.251 0 01.273.3 1.421 1.421 0 01-1.4 1.141zM20.818 13.47a.246.246 0 01-.118-.2.251.251 0 01.094-.211 2.919 2.919 0 10-3.616 0 .251.251 0 01.094.211.247.247 0 01-.119.2 3.516 3.516 0 00-1.681 3v2.011a.5.5 0 00.5.5h.6a.5.5 0 01.5.45l.412 4.123a.5.5 0 00.5.45h2.01a.5.5 0 00.5-.45l.412-4.123a.5.5 0 01.5-.45H22a.5.5 0 00.5-.5v-2.014a3.515 3.515 0 00-1.682-2.997zm-1.832-1.279a1.42 1.42 0 01-1.419-1.418 1.406 1.406 0 01.029-.285.25.25 0 01.367-.168 3.576 3.576 0 001.746.452 3.453 3.453 0 00.4-.022.252.252 0 01.273.3 1.422 1.422 0 01-1.396 1.141z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});