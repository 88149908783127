define("ember-svg-jar/inlined/make-up-lipstick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>make-up-lipstick</title><path d=\"M7.963 11.79a.991.991 0 00-.707-.293.989.989 0 00-.7.293l-4.7 4.7a1.5 1.5 0 000 2.121l4.95 4.95A1.485 1.485 0 007.859 24a1.491 1.491 0 001.061-.439l4.7-4.7a1 1 0 000-1.414zM21.66.112a1.5 1.5 0 00-1.63.33l-5.906 5.9a.5.5 0 01-.381.145.966.966 0 00-.743.276l-3.891 3.889a.5.5 0 000 .707l4.95 4.95a.5.5 0 00.707 0l3.889-3.89a.959.959 0 00.277-.75.5.5 0 01.146-.384L22 8.362a2.005 2.005 0 00.59-1.409V1.5a1.516 1.516 0 00-.93-1.388zm-8.1 9.333l-1.414 1.414a.5.5 0 11-.707-.707l1.414-1.414a.5.5 0 11.707.707zm7.031-2.7a.5.5 0 01-.146.353L17.95 9.593a.5.5 0 01-.707 0l-1.418-1.418a.5.5 0 01-.146-.353.5.5 0 01.147-.354l3.911-3.9a.5.5 0 01.853.354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});