define("ember-svg-jar/inlined/agriculture-machine-tractor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>agriculture-machine-tractor</title><path d=\"M17.75 13.75a4 4 0 104 4 4 4 0 00-4-4zm0 5.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/><path d=\"M23.839 13.816a7.249 7.249 0 00-2.984-2.616.5.5 0 01-.275-.347l-1.18-5.52a.5.5 0 01.11-.432.99.99 0 00.242-.651v-.5a1.5 1.5 0 00-1.5-1.5h-9a1.5 1.5 0 00-1.5 1.5v.5a.992.992 0 00.227.633.5.5 0 01.1.44L6.935 9.888a.5.5 0 01-.4.372 16.363 16.363 0 00-5.1 1.681A2.752 2.752 0 000 14.354v4.4a1.751 1.751 0 00.988 1.575.251.251 0 00.353-.282 3.455 3.455 0 01-.091-.793 3.5 3.5 0 017 0 3.458 3.458 0 01-.062.656.5.5 0 00.492.594h2.07a1.752 1.752 0 001.75-1.75v-1a5.25 5.25 0 019.661-2.854 1 1 0 101.678-1.087zM8.25 14.75h-2.5a.5.5 0 010-1h2.5a.5.5 0 010 1zm9.978-4.433a.5.5 0 01-.393.183 7.329 7.329 0 00-1.263.1.25.25 0 01-.288-.282l.209-1.458a.751.751 0 10-1.486-.212l-.328 2.3a.5.5 0 01-.264.373 7.3 7.3 0 00-3.284 3.48.349.349 0 01-.318.206.425.425 0 01-.423-.382l-.319-3.188a.5.5 0 01.224-.468l.941-.616a.5.5 0 01.583.024.769.769 0 00.847 0 .749.749 0 00.208-1.04l-1-1.5a.75.75 0 00-1.323.689.5.5 0 01-.19.6l-.87.569a.247.247 0 01-.281-.005.25.25 0 01-.1-.264l.949-3.8a.5.5 0 01.485-.379h6.386a.5.5 0 01.489.4L18.33 9.9a.5.5 0 01-.102.417z\"/><circle cx=\"4.75\" cy=\"19.25\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});