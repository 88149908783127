define("ember-svg-jar/inlined/natural-disaster-flood-house-indoor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>natural-disaster-flood-house-indoor</title><path d=\"M23.6 8.272L13.175.388a1.974 1.974 0 00-2.36.012L.4 8.272a1 1 0 001.207 1.6l.107-.081v11.254A2.954 2.954 0 004.67 24h14.66a2.957 2.957 0 002.955-2.954V9.787l.107.081a1 1 0 001.207-1.6zm-3.315 12.151a.25.25 0 01-.318.241 3.6 3.6 0 01-1.629-1.074.489.489 0 00-.726 0 3.437 3.437 0 01-2.553 1.183 2.942 2.942 0 01-2.336-1.16.487.487 0 00-.352-.183h-.031a.491.491 0 00-.342.139 5.027 5.027 0 01-.438.384 3.816 3.816 0 01-2.306.821 3.505 3.505 0 01-2.571-1.228.488.488 0 00-.348-.161h-.014a.488.488 0 00-.342.138 4.642 4.642 0 01-1.95 1.177.249.249 0 01-.314-.241V19.4a.25.25 0 01.157-.232 3.562 3.562 0 001.062-.722 1.98 1.98 0 011.387-.562 2.006 2.006 0 011.475.656 2.012 2.012 0 001.458.733 2.335 2.335 0 001.382-.5 3.685 3.685 0 00.314-.276 1.991 1.991 0 012.947.184 1.439 1.439 0 001.162.594 1.947 1.947 0 001.441-.69 1.988 1.988 0 012.952 0 2.4 2.4 0 00.692.543.25.25 0 01.141.225zm0-4.623a.5.5 0 01-.746.435 3.581 3.581 0 01-1.139-1.074.532.532 0 00-.868.016 3.013 3.013 0 01-2.468 1.4 2.537 2.537 0 01-2.207-1.365.5.5 0 00-.4-.283.493.493 0 00-.45.2 3.6 3.6 0 01-2.745 1.449 3.221 3.221 0 01-2.48-1.416.5.5 0 00-.4-.233.48.48 0 00-.419.188 5.034 5.034 0 01-1.529 1.2.5.5 0 01-.715-.452v-7.35a.5.5 0 01.2-.4l7.764-5.9a.5.5 0 01.6 0l7.807 5.906a.5.5 0 01.2.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});