define("ember-svg-jar/inlined/real-estate-favorite-hold-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-favorite-hold-building</title><path d=\"M11.224 6.053l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 004.5 6.5V14a.5.5 0 00.5.5h1.5A.5.5 0 007 14v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5H11a.5.5 0 00.5-.5V6.5a.5.5 0 00-.276-.447zM9.5 10.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zM19.5 14V3a.5.5 0 00-.308-.462l-6-2.5A.5.5 0 0012.5.5V14a.5.5 0 00.5.5h6a.5.5 0 00.5-.5zm-2-3.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1z\"/><path d=\"M4.124 15.836a1.438 1.438 0 00-.458 1.824L5 19.877a.5.5 0 11-.857.516l-1.352-2.25a2.465 2.465 0 01.126-2.476.5.5 0 00.083-.275V12.5a1.5 1.5 0 00-3 0v5.172a4.5 4.5 0 00.986 2.811l2.664 3.329a.5.5 0 00.39.188H8a.5.5 0 00.5-.5v-3.01a4.492 4.492 0 00-.757-2.5c-1.888-1.965-2.477-2.916-3.619-2.154zM22.5 11a1.5 1.5 0 00-1.5 1.5v2.891a.5.5 0 00.083.276 2.437 2.437 0 01.145 2.442l-1.371 2.284a.5.5 0 11-.857-.516l1.352-2.25a1.4 1.4 0 00-.476-1.791c-1.142-.762-1.731.189-3.619 2.157a4.492 4.492 0 00-.757 2.5V23.5a.5.5 0 00.5.5h3.96a.5.5 0 00.39-.188l2.664-3.329A4.5 4.5 0 0024 17.672V12.5a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});