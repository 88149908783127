define("ember-svg-jar/inlined/gmo-food-leaf-dna", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gmo-food-leaf-dna</title><path d=\"M14.25 9h3.26a1 1 0 00.906-.576 13.5 13.5 0 00.921-7.7.871.871 0 00-1.35-.576 9.788 9.788 0 01-5.581 1.415c-4.613 0-6.553 1.9-6.576 1.928a6.1 6.1 0 00-2.462 5.188 1 1 0 01-.321.782c-.316.292-.832.8-2.736 2.6a1 1 0 001.378 1.449C4.8 10.362 7.076 8.441 11.3 6.986a.75.75 0 01.484 1.414 18.34 18.34 0 00-6.457 3.716.5.5 0 00-.161.368.508.508 0 00.162.368 6.051 6.051 0 004.1 1.6 8.521 8.521 0 002.467-.384.5.5 0 00.343-.587 10.261 10.261 0 01-.238-2.23A2.25 2.25 0 0114.25 9z\"/><path d=\"M21.655 17.63a.5.5 0 010-.76A7.409 7.409 0 0024 11.25a.75.75 0 00-1.5 0 .25.25 0 01-.25.25h-7a.25.25 0 01-.25-.25.75.75 0 00-1.5 0 7.408 7.408 0 002.345 5.62.5.5 0 010 .76 7.408 7.408 0 00-2.345 5.62.75.75 0 001.5 0 .25.25 0 01.25-.25h7a.25.25 0 01.25.25.75.75 0 001.5 0 7.409 7.409 0 00-2.345-5.62zM15.894 14a.5.5 0 01-.469-.325 6.289 6.289 0 01-.12-.351.251.251 0 01.239-.324h6.412a.251.251 0 01.239.324 6.289 6.289 0 01-.12.351.5.5 0 01-.468.325zm4.518 1.955a2.807 2.807 0 01-3.324 0 .25.25 0 01.143-.455h3.039a.25.25 0 01.142.455zm.1 2.869a.249.249 0 01-.242.176h-3.039a.25.25 0 01-.143-.455 2.807 2.807 0 013.324 0 .248.248 0 01.096.279zm1.567 2c.043.114.083.232.12.351a.251.251 0 01-.239.324h-6.416a.251.251 0 01-.239-.324c.037-.119.077-.237.12-.351a.5.5 0 01.469-.325h5.713a.5.5 0 01.468.326z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});