define("ember-svg-jar/inlined/hotel-double-bed-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hotel-double-bed-add</title><path d=\"M.9 15.5h8.348a.5.5 0 00.477-.352 8.023 8.023 0 017.65-5.648 7.925 7.925 0 014.883 1.669.5.5 0 00.8-.486l-.7-3.773a.5.5 0 00-.49-.41H2.385a.5.5 0 00-.49.41l-1.49 8a.56.56 0 00.11.41.527.527 0 00.385.18zM9.375 17.5c0-.159 0-.316.013-.472a.5.5 0 00-.5-.528H.625a.5.5 0 00-.5.5v3.25a2.253 2.253 0 002.25 2.25.248.248 0 01.25.25v.5a.75.75 0 001.5 0V23a.5.5 0 01.5-.5h5.547a.5.5 0 00.424-.766A7.911 7.911 0 019.375 17.5zM2.625 5.5H4.2a.5.5 0 00.5-.418l.36-2.165a.5.5 0 01.489-.417h4.576a.5.5 0 01.5.5v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V3a.5.5 0 01.5-.5H18.7a.5.5 0 01.494.417l.36 2.165a.5.5 0 00.494.418h1.576a.5.5 0 00.5-.5V2.25A2.253 2.253 0 0019.875 0h-15.5a2.253 2.253 0 00-2.25 2.25V5a.5.5 0 00.5.5z\"/><path d=\"M17.375 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.5 7.5h-1a.5.5 0 00-.5.5v1a1 1 0 01-2 0v-1a.5.5 0 00-.5-.5h-1a1 1 0 010-2h1a.5.5 0 00.5-.5v-1a1 1 0 012 0v1a.5.5 0 00.5.5h1a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});