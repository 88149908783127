define("ember-svg-jar/inlined/modern-music-cymbal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-music-cymbal</title><path d=\"M12.465 15.5q-.6 0-1.19-.059a.249.249 0 00-.275.249v7.81a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-7.815a.249.249 0 00-.276-.249 12.546 12.546 0 01-1.259.064zM23.5 9.5a1 1 0 00-1-1h-21a1 1 0 000 2h1.331a.245.245 0 01.085.015l2.668.97a.256.256 0 01.075.043A10.574 10.574 0 0012.465 14a10.767 10.767 0 006.876-2.472.281.281 0 01.075-.043l2.668-.97a.245.245 0 01.085-.015h.331a1 1 0 001-1zM23.5 6.5a1 1 0 00-1-1h-.331a.245.245 0 01-.085-.015l-2.668-.97a.256.256 0 01-.075-.043 10.734 10.734 0 00-5.62-2.4.25.25 0 01-.221-.248V1a1 1 0 00-2 0v.818a.25.25 0 01-.222.248 10.58 10.58 0 00-5.619 2.406.281.281 0 01-.075.043l-2.668.97a.245.245 0 01-.085.015H1.5a1 1 0 000 2h21a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});