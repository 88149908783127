define("ember-svg-jar/inlined/wifi-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-off</title><path d=\"M1.393 6.394a1 1 0 101.414 1.414A12.988 12.988 0 0114.135 4.19a1 1 0 00.925-.323l.006-.007a1 1 0 00-.575-1.64A14.99 14.99 0 001.393 6.394zM21.193 7.808a1 1 0 001.414-1.414c-.075-.075-.151-.148-.227-.221A1 1 0 0021 7.622a6.8 6.8 0 01.193.186zM10.747 8.861A1 1 0 009.8 7.225a10.916 10.916 0 00-4.767 2.262q-.42.347-.81.735a1 1 0 101.414 1.414 8.93 8.93 0 014.542-2.443.99.99 0 00.557-.319zM17.835 9.833a1.007 1.007 0 00.119 1.42c.141.123.278.25.411.383a1 1 0 001.414-1.414 8.525 8.525 0 00-.546-.5 1 1 0 00-1.4.115zM14.708 13.454a1 1 0 00.188 1.471 4.306 4.306 0 01.641.539 1 1 0 001.414-1.415 7.1 7.1 0 00-.892-.754 1.01 1.01 0 00-1.35.159z\"/><circle cx=\"12\" cy=\"20\" r=\"2\"/><path d=\"M22.154.243a1 1 0 00-1.41.1l-19 22a1 1 0 101.513 1.307l19-22a1 1 0 00-.103-1.407z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});