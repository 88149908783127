define("ember-svg-jar/inlined/make-up-lipstick-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>make-up-lipstick-1</title><path d=\"M9.541 13.24a.5.5 0 01-.291-.455V1.5A1.5 1.5 0 006.868.287L2.984 3.111A3.012 3.012 0 001.75 5.537v7.248a.5.5 0 01-.291.455A2.488 2.488 0 000 15.5V23a1 1 0 001 1h9a1 1 0 001-1v-7.5a2.488 2.488 0 00-1.459-2.26zM7.25 12.5a.5.5 0 01-.5.5h-2.5a.5.5 0 01-.5-.5V5.537a1.006 1.006 0 01.411-.809l2.3-1.669a.5.5 0 01.794.4zM21.5 12h-6a2.5 2.5 0 00-2.5 2.5v4.75a.5.5 0 00.5.5h10a.5.5 0 00.5-.5V14.5a2.5 2.5 0 00-2.5-2.5zM23.5 21.25h-10a.5.5 0 00-.5.5V23a1 1 0 001 1h9a1 1 0 001-1v-1.25a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});