define("ember-svg-jar/inlined/flag-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flag-1</title><path d=\"M16.55 7.2a.249.249 0 010-.39l5.988-4.789c.521-.418.485-.8.417-1S22.674.5 22.006.5H5.993a.5.5 0 00-.5.5v12a.5.5 0 00.5.5h16.013c.668 0 .882-.325.949-.519s.1-.58-.417-1zM2.506 0a1.5 1.5 0 00-1.5 1.5v21a1.5 1.5 0 003 0v-21a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});