define("ember-svg-jar/inlined/outdoors-backpack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-backpack</title><path d=\"M2.4 14.56A3 3 0 000 17.5V22a.5.5 0 00.5.5h2A.5.5 0 003 22v-6.95a.5.5 0 00-.6-.49zM21.6 14.56a.5.5 0 00-.6.49V22a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-4.5a3 3 0 00-2.4-2.94zM8.5 15.5a5.937 5.937 0 01-3.229-.952.5.5 0 00-.771.421V22.5A1.5 1.5 0 006 24h12a1.5 1.5 0 001.5-1.5v-7.531a.5.5 0 00-.771-.421 5.937 5.937 0 01-3.229.952zm5.25 6h-3.5a1.25 1.25 0 010-2.5h3.5a1.25 1.25 0 010 2.5zM19.5 8h-15a.5.5 0 00-.5.5v1A4.505 4.505 0 008.5 14h7A4.505 4.505 0 0020 9.5v-1a.5.5 0 00-.5-.5z\"/><rect x=\"7.25\" width=\"9.5\" height=\"6.5\" rx=\".5\" ry=\".5\"/><path d=\"M23.5 4.083V2.417A2.419 2.419 0 0021.083 0H18.75a.5.5 0 00-.5.5V6a.5.5 0 00.5.5h2.333A2.419 2.419 0 0023.5 4.083zM.5 4.083A2.419 2.419 0 002.917 6.5H5.25a.5.5 0 00.5-.5V.5a.5.5 0 00-.5-.5H2.917A2.419 2.419 0 00.5 2.417z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});