define("ember-svg-jar/inlined/sports-car-convertible-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sports-car-convertible-2</title><path d=\"M23.75 12.55H22a.5.5 0 010-1h1.6a.249.249 0 00.23-.348 1.633 1.633 0 00-1.238-.984l-.376-.064A18 18 0 0020.3 9.9a.247.247 0 01-.174-.087l-2.268-2.64a2.994 2.994 0 00-2.278-1.048H14.5a1 1 0 00-.961.726l-.813 2.843a.25.25 0 01-.24.181H9.714a.25.25 0 01-.158-.444l1.575-1.28A1 1 0 009.869 6.6l-4.4 3.576a.259.259 0 01-.078.043l-4.1 1.366a1.849 1.849 0 00-1.149 1.13.25.25 0 00.235.336H2.5a.5.5 0 010 1H.25A.25.25 0 000 14.3v1.075a1.5 1.5 0 001.5 1.5h1.846a.251.251 0 00.243-.311 2.8 2.8 0 01-.089-.689 3 3 0 016 0 2.8 2.8 0 01-.089.689.251.251 0 00.243.311h5.692a.251.251 0 00.243-.311 2.8 2.8 0 01-.089-.689 3 3 0 016 0 2.493 2.493 0 01-.02.313.25.25 0 00.36.254A3.9 3.9 0 0024 12.961V12.8a.25.25 0 00-.25-.25zm-6.526-2.821a.25.25 0 01-.224.146h-1.914a.251.251 0 01-.2-.1.254.254 0 01-.041-.22l.358-1.25a.249.249 0 01.24-.181h.137a1 1 0 01.759.349l.848.988a.25.25 0 01.037.268z\"/><circle cx=\"6.5\" cy=\"15.875\" r=\"2\"/><circle cx=\"18.5\" cy=\"15.875\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});