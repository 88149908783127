define("ember-svg-jar/inlined/marine-mammal-penguin-slide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>marine-mammal-penguin-slide</title><path d=\"M19.661 16.037a.75.75 0 10.456.155.746.746 0 00-.456-.155zM1.6 3.257a1 1 0 001.28-1.538L1.69.728A1 1 0 10.409 2.265zM1.4 7.5a1 1 0 101.276-1.532l-1.034-.863A1 1 0 10.36 6.64z\"/><path d=\"M23.079 17.612a4.423 4.423 0 00-.919-3.767c-.939-1.067-1.914-1.2-4.429-1.314-.236-.018-.552-.361-1.091-.967a18.722 18.722 0 00-2.753-2.62C8.106 4.512 4.95 3.862 4.818 3.837a.993.993 0 00-1.152.708 4.186 4.186 0 001.461 4.01 5.513 5.513 0 00-.394 4.887l-3.1-2.483a1 1 0 00-1.25 1.562L13.83 23.282a1 1 0 001.25-1.563l-3.561-2.848a8.088 8.088 0 003.665.173 3.752 3.752 0 012.758.311 4.024 4.024 0 002.07.637 3.083 3.083 0 001.823-.593c.246.447.135 1.442 1.163 1.442a1 1 0 001-.935 3.905 3.905 0 00-.919-2.294zm-2.785.344a1.761 1.761 0 01-1.324-.318 5.65 5.65 0 00-4.053-.576c-.714.1-3.143.628-6.639-2.046-2.122-1.636-2.293-4.149-1.5-5.324l1.2.713a1 1 0 00-.569 1.646 7.706 7.706 0 003.391 2.088 6.4 6.4 0 002.447.29 5.3 5.3 0 003.153 1.032 6.165 6.165 0 002.826-.746 1.432 1.432 0 011.435.451 2.41 2.41 0 01.428 2.2 1.5 1.5 0 01-.795.59z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});