define("ember-svg-jar/inlined/camera-glasses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camera-glasses</title><path d=\"M20.727 4.421A4.086 4.086 0 0017 2h-1a1 1 0 000 2h1a2.078 2.078 0 011.9 1.234l2.407 5.414a.251.251 0 01-.229.352H4.924a.251.251 0 01-.229-.352L7.1 5.234A2.078 2.078 0 019 4h1a1 1 0 000-2H9a4.086 4.086 0 00-3.727 2.421C1.96 11.878 2 11.625 2 12v1.75a.25.25 0 01-.25.25H1.5A1.5 1.5 0 000 15.5v2A1.5 1.5 0 001.5 19h.826a.249.249 0 01.223.138 5.181 5.181 0 009.027.426l.741-1.186a.835.835 0 011.366 0l.741 1.186A5.182 5.182 0 0024 16.818V12c0-.371.034-.137-3.273-7.579zM3.5 16.5a1 1 0 11-1-1 1 1 0 011 1zm2 1A.5.5 0 015 17v-1a.5.5 0 01.5-.5H8a.5.5 0 01.5.5v1a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});