define("ember-svg-jar/inlined/color-palette-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-palette-1</title><path d=\"M23.5 12a2.492 2.492 0 00-4.816-.909.251.251 0 01-.233.159H13a.25.25 0 01-.25-.25V5.549a.251.251 0 01.159-.233 2.5 2.5 0 10-1.818 0 .251.251 0 01.159.233V11a.25.25 0 01-.25.25H5.549a.251.251 0 01-.233-.159 2.5 2.5 0 100 1.818.251.251 0 01.233-.159H11a.25.25 0 01.25.25v5.451a.251.251 0 01-.159.233 2.5 2.5 0 101.818 0 .251.251 0 01-.159-.233V13a.25.25 0 01.25-.25h5.451a.251.251 0 01.233.159A2.492 2.492 0 0023.5 12z\"/><path d=\"M16.6 20.132a2.5 2.5 0 003.532-3.532 2.554 2.554 0 00-2.758-.5.249.249 0 01-.273-.054L15.03 13.97a.75.75 0 00-1.06 1.06l2.06 2.06a.25.25 0 01.051.279 2.438 2.438 0 00-.217 1 2.484 2.484 0 00.736 1.763zM7.4 3.868a2.56 2.56 0 00-3.535 0 2.5 2.5 0 001.771 4.268 2.438 2.438 0 001-.217.25.25 0 01.279.051l2.06 2.06a.75.75 0 001.06-1.06L7.97 6.91a.25.25 0 01-.051-.279 2.438 2.438 0 00.217-1A2.484 2.484 0 007.4 3.868zM13.97 8.97a.75.75 0 101.06 1.06l2.06-2.06a.25.25 0 01.279-.051 2.438 2.438 0 001 .217 2.5 2.5 0 001.768-4.268 2.558 2.558 0 00-3.535 0 2.484 2.484 0 00-.733 1.768 2.438 2.438 0 00.217 1 .25.25 0 01-.051.279zM10.03 13.97a.749.749 0 00-1.06 0L6.9 16.04a.249.249 0 01-.273.054 2.556 2.556 0 00-2.758.5 2.5 2.5 0 104.268 1.767 2.438 2.438 0 00-.217-1 .25.25 0 01.051-.279l2.06-2.06a.749.749 0 00-.001-1.052z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});