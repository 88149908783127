define("ember-svg-jar/inlined/religion-grave", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-grave</title><path d=\"M21.5 19.75h-19a1.5 1.5 0 00-1.5 1.5v2a.5.5 0 00.5.5h21a.5.5 0 00.5-.5v-2a1.5 1.5 0 00-1.5-1.5zM6.046 17.541a.5.5 0 00.454.709h11a.5.5 0 00.454-.709l-1.829-3.96A1.217 1.217 0 0015 12.75h-1.5v-6a.5.5 0 01.5-.5h2a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-2a.5.5 0 01-.5-.5v-2a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v2a.5.5 0 01-.5.5H8a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h2a.5.5 0 01.5.5v6H9a1.22 1.22 0 00-1.113.794z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});