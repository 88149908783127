define("ember-svg-jar/inlined/single-man-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-circle</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm5.876 17.412a.748.748 0 01-.626.338h-10a.751.751 0 01-.689-1.05 6.305 6.305 0 015.689-3.95 6.3 6.3 0 015.689 3.95.749.749 0 01-.063.712zM13.452 7.028A6.169 6.169 0 019.89 5.845a.254.254 0 01-.1-.187.25.25 0 01.078-.2 3.5 3.5 0 015.524.938.25.25 0 01-.146.353 5.936 5.936 0 01-1.794.279zm1.979 1.149a.249.249 0 01.231.052.253.253 0 01.084.221 3.513 3.513 0 01-7-.45 3.21 3.21 0 01.089-.732.25.25 0 01.386-.148 7.367 7.367 0 004.227 1.327 7.417 7.417 0 001.983-.27z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});