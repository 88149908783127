define("ember-svg-jar/inlined/phone-action-music-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-music-1</title><path d=\"M13.686 14.63a4.132 4.132 0 01-1.413.181.248.248 0 00-.273.244v3.428a1 1 0 01-1 1H4a1 1 0 01-1-1v-11a1 1 0 011-1l7.249.032a.25.25 0 00.251-.25v-2a.249.249 0 00-.249-.25L3.5 3.983a2.5 2.5 0 00-2.5 2.5v15a2.5 2.5 0 002.5 2.5h8a2.5 2.5 0 002.5-2.5V14.87a.246.246 0 00-.314-.24z\"/><path d=\"M22.278.478a1.993 1.993 0 00-1.646-.427l-6 1.125A2 2 0 0013 3.142v5.187a2.5 2.5 0 102 2.5v-7.48a.251.251 0 01.2-.246l5.5-1.032a.251.251 0 01.3.246v4.012a2.5 2.5 0 102 2.5V2.017a1.994 1.994 0 00-.722-1.539z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});