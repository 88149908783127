define("ember-svg-jar/inlined/canoe-paddles-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>canoe-paddles-1</title><path d=\"M8.25 0a3 3 0 00-3 3v5.5a2.489 2.489 0 00.938 1.952l.874.7a.5.5 0 01.188.39v9.637a.5.5 0 01-.125.33 1.5 1.5 0 102.25 0 .5.5 0 01-.125-.33v-9.637a.5.5 0 01.188-.39l.874-.7A2.489 2.489 0 0011.25 8.5V3a3 3 0 00-3-3zM17.812 13.548l-.874-.7a.5.5 0 01-.188-.39V2.821a.5.5 0 01.125-.33 1.5 1.5 0 10-2.25 0 .5.5 0 01.125.33v9.637a.5.5 0 01-.188.39l-.874.7a2.489 2.489 0 00-.938 1.952V21a3 3 0 006 0v-5.5a2.489 2.489 0 00-.938-1.952z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});