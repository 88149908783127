define("ember-svg-jar/inlined/flying-insect-moth-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flying-insect-moth-1</title><path d=\"M20 13a4.07 4.07 0 00-1.262.191 50.544 50.544 0 00-.232-.507A8.066 8.066 0 0024 5a1 1 0 00-2 0 6.028 6.028 0 01-4.38 5.842c-.331-.664-.7-1.369-1.09-2.107A4.99 4.99 0 0020 4a1 1 0 00-2 0 3 3 0 01-3 3H9a3 3 0 01-3-3 1 1 0 00-2 0 4.99 4.99 0 003.47 4.735Q6.89 9.821 6.4 10.8A6 6 0 012 5a1 1 0 00-2 0 8.038 8.038 0 005.512 7.646c-.084.183-.17.368-.25.545A4.07 4.07 0 004 13a4 4 0 00-4 4 1 1 0 002 0 2 2 0 012-2 2.108 2.108 0 01.471.065c-2.052 5.167-1.53 6.97-1.232 7.475A1.955 1.955 0 005 23.5a7.106 7.106 0 003.994-1.367A3.736 3.736 0 0012 24a3.68 3.68 0 003-1.87 7.114 7.114 0 004 1.37 1.955 1.955 0 001.761-.96c.3-.5.82-2.308-1.232-7.475A2.108 2.108 0 0120 15a2 2 0 012 2 1 1 0 002 0 4 4 0 00-4-4zM4.963 21.526c-.718-1 1.69-6.835 3.848-11.039a3.494 3.494 0 002.439 2.177v4.194c-.539.629-2.988 4.642-6.287 4.668zM19 21.5c-3.237 0-5.638-3.928-6.25-4.642v-4.194a3.494 3.494 0 002.439-2.177C17.313 14.623 19.758 20.522 19 21.5z\"/><path d=\"M9.256 4.843A.75.75 0 0010 5.5h4a.75.75 0 00.744-.657l.19-1.519a2.957 2.957 0 10-5.868 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});