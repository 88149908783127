define("ember-svg-jar/inlined/skateboard-person-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skateboard-person-1</title><circle cx=\"14.5\" cy=\"3\" r=\"2.5\"/><path d=\"M14.164 12.877l-1.664-.941.87-1.78a.251.251 0 01.225-.14H20.5a1.5 1.5 0 000-3h-16a1.5 1.5 0 000 3h5.2a.251.251 0 01.225.36l-1.396 2.851a1.5 1.5 0 01-1.321.789H3.5a1.5 1.5 0 000 3h3.708a4.514 4.514 0 003.968-2.381l1.521.859a1.492 1.492 0 01.671 1.853l-.163.276a.25.25 0 00.215.377h2.68a.25.25 0 00.244-.193 4.461 4.461 0 00-2.18-4.93zM9 19a1 1 0 000 2h11a1 1 0 000-2z\"/><circle cx=\"18\" cy=\"22.5\" r=\"1\"/><circle cx=\"11\" cy=\"22.5\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});