define("ember-svg-jar/inlined/passport-globe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>passport-globe</title><path d=\"M13.5 7h2.29a.5.5 0 00.487-.614A8.246 8.246 0 108.25 16.5a8.116 8.116 0 001.332-.111A.5.5 0 0010 15.9v-1.02a.5.5 0 00-.6-.491 5.917 5.917 0 01-1.15.111 6.219 6.219 0 01-3.7-1.219.5.5 0 01-.193-.5l.306-1.53h.309a1.5 1.5 0 001.453-1.866l-.5-2A1.5 1.5 0 004.469 6.25H3.078a.5.5 0 01-.449-.719A6.25 6.25 0 018.25 2a6.188 6.188 0 013.358.989.142.142 0 01-.077.261 1.5 1.5 0 00-1.456 1.135l-.5 2a1.494 1.494 0 00.273 1.287 1.464 1.464 0 00.672.48.5.5 0 00.52-.119A3.461 3.461 0 0113.5 7zM15.311 12.307a3.882 3.882 0 00-1.559 2.258.25.25 0 00.243.31h1a.25.25 0 00.249-.228 7.772 7.772 0 01.442-2.047.25.25 0 00-.376-.293zM19.689 12.307a.25.25 0 00-.376.293 7.772 7.772 0 01.442 2.047.249.249 0 00.249.228h1a.25.25 0 00.243-.31 3.882 3.882 0 00-1.558-2.258zM18.253 16.125h-1.506a.251.251 0 00-.249.277 5.422 5.422 0 00.794 2.447.25.25 0 00.417 0A5.431 5.431 0 0018.5 16.4a.251.251 0 00-.249-.277zM17.5 12.04a.248.248 0 00-.208.112A5.41 5.41 0 0016.5 14.6a.251.251 0 00.248.276h1.507a.251.251 0 00.245-.276 5.417 5.417 0 00-.794-2.447.248.248 0 00-.206-.113z\"/><path d=\"M21.5 8h-8a2.5 2.5 0 00-2.5 2.5v11a2.5 2.5 0 002.5 2.5h8a2.5 2.5 0 002.5-2.5v-11A2.5 2.5 0 0021.5 8zm-4 12.625a5.125 5.125 0 115.125-5.125 5.131 5.131 0 01-5.125 5.125z\"/><path d=\"M21.005 16.125H20a.25.25 0 00-.249.228 7.78 7.78 0 01-.442 2.048.25.25 0 00.376.293 3.889 3.889 0 001.559-2.258.251.251 0 00-.243-.311zM15.245 16.353a.249.249 0 00-.245-.228h-1a.251.251 0 00-.243.311 3.885 3.885 0 001.559 2.258.25.25 0 00.376-.293 7.78 7.78 0 01-.447-2.048z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});