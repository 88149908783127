define("ember-svg-jar/inlined/car-actions-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-actions-shield</title><path d=\"M5.25 12.7h-4a.249.249 0 00-.25.25v.5a1.249 1.249 0 001.25 1.245h2a1.249 1.249 0 001.25-1.247v-.5a.249.249 0 00-.25-.248zM17.478 9.556a17 17 0 012.731.238.251.251 0 00.291-.246V8.175a1.251 1.251 0 00-.366-.88l-1.085-1.081a.5.5 0 01-.111-.167L16.888.941A1.492 1.492 0 0015.5 0H5.171a1.494 1.494 0 00-1.393.939l-1.94 4.833a.484.484 0 01-.111.167L.22 7.441a.744.744 0 00-.22.528v2.491a1.249 1.249 0 001.25 1.245h8.724a.253.253 0 00.2-.094 3.023 3.023 0 011.158-.9 17.506 17.506 0 016.146-1.155zM4.679 4.981a.25.25 0 01-.232-.342l1-2.49a.25.25 0 01.232-.157h9.308a.25.25 0 01.232.157l1 2.49a.25.25 0 01-.232.342zM23.072 12.086a16.467 16.467 0 00-5.594-1.036 16.038 16.038 0 00-5.55 1.036A1.539 1.539 0 0011 13.5v3.379c0 2.832 2.134 5.62 5.19 6.779l.553.21a2.071 2.071 0 001.471 0l.553-.21C21.848 22.486 24 19.7 24 16.876V13.5a1.539 1.539 0 00-.928-1.414zM20.5 18.023h-1.75a.25.25 0 00-.25.249v1.743a1 1 0 01-2 0v-1.743a.25.25 0 00-.25-.249H14.5a1 1 0 110-1.992h1.75a.25.25 0 00.25-.249v-1.743a1 1 0 012 0v1.743a.25.25 0 00.25.249h1.75a1 1 0 110 1.992z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});