define("ember-svg-jar/inlined/award-medal-shine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>award-medal-shine</title><path d=\"M10.178 4.862A.25.25 0 0010.4 5h3.217a.25.25 0 00.223-.138l2.26-4.5A.25.25 0 0015.879 0H8.141a.249.249 0 00-.223.362zM10.067 7.53a.249.249 0 00.159-.14.252.252 0 00-.005-.213L6.686.138A.25.25 0 006.462 0H6a1.071 1.071 0 00-.752.341L2.3 3.709a2 2 0 00-.052 2.572l3.43 4.262a.25.25 0 00.406-.023 7.016 7.016 0 013.983-2.99zM13.78 7.177a.248.248 0 00-.006.213.251.251 0 00.16.14 7.024 7.024 0 013.982 2.989.25.25 0 00.406.023l3.43-4.261a2 2 0 00-.052-2.572L18.752.341A1.082 1.082 0 0018 0h-.6zM12 8.75a5.5 5.5 0 105.5 5.5 5.506 5.506 0 00-5.5-5.5zm0 3.5a2 2 0 11-2 2 2 2 0 012-2zM11.4 21.22a.98.98 0 00-.4.78v1a1 1 0 002 0v-1a.98.98 0 00-1.6-.78zM16.4 19.143a1.021 1.021 0 000 1.414l.707.708a1 1 0 001.415-1.414l-.707-.708a1.046 1.046 0 00-1.415 0zM18.911 15.312a.979.979 0 00.785.408h1a1 1 0 000-2h-1a1 1 0 00-1 1 .976.976 0 00.215.592zM7.584 19.143a1.043 1.043 0 00-1.415 0l-.707.708a1 1 0 001.415 1.414l.707-.708a1.018 1.018 0 000-1.414zM5.011 14.038a.991.991 0 00-.724-.318h-1a1 1 0 000 2h1a.994.994 0 00.724-1.682z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});