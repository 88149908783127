define("ember-svg-jar/inlined/arrow-button-right-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-right-2-alternate</title><path d=\"M4.434 24a2.828 2.828 0 01-2.829-2.828V2.829A2.83 2.83 0 015.9.411l15.13 9.171a2.828 2.828 0 010 4.838L5.9 23.591A2.831 2.831 0 014.434 24zM5.757 4.735a.249.249 0 00-.379.213v14.105a.25.25 0 00.379.214l11.635-7.052a.251.251 0 000-.428z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});