define("ember-svg-jar/inlined/single-woman-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-shield</title><path d=\"M23.5 1.957A1.458 1.458 0 0022.043.5H1.957A1.458 1.458 0 00.5 1.957V9.25a14.645 14.645 0 0011.4 14.24.5.5 0 00.2 0A14.645 14.645 0 0023.5 9.25zM16.108 17h-8a.5.5 0 01-.388-.815A5.689 5.689 0 0112.11 14a5.68 5.68 0 014.39 2.185.5.5 0 01-.388.815zm.506-4.855a.633.633 0 01-1.064.686 4.087 4.087 0 01-.517-1.206.25.25 0 00-.416-.117 3.595 3.595 0 01-5.018 0 .249.249 0 00-.416.116 4.126 4.126 0 01-.517 1.208.633.633 0 01-1.066-.687 3.512 3.512 0 00.5-1.934v-1.7a4.013 4.013 0 118.025 0v1.7a3.521 3.521 0 00.489 1.934z\"/><path d=\"M15.306 8.9a.5.5 0 00-.4-.585 4.348 4.348 0 01-2.406-1.38s-.083-.18-.394-.193a.424.424 0 00-.393.193 4.345 4.345 0 01-2.407 1.381.5.5 0 00-.4.585.5.5 0 00.585.4A5.294 5.294 0 0011.94 8.09a.25.25 0 01.327 0A5.3 5.3 0 0014.72 9.3a.505.505 0 00.586-.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});