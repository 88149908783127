define("ember-svg-jar/inlined/products-give-purse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>products-give-purse</title><path d=\"M14.865 9.616a.479.479 0 01-.23 0L6.558 7.7a.247.247 0 00-.213.047.25.25 0 00-.095.2v3.049a2.5 2.5 0 002.5 2.5h12a2.5 2.5 0 002.5-2.5V7.942a.25.25 0 00-.095-.2.247.247 0 00-.213-.047z\"/><path d=\"M16.265.523a2.489 2.489 0 00-3.031 0L8.968 3.937a.255.255 0 01-.157.054h-2.5a.6.6 0 00-.556.628v1.128a.894.894 0 00.646.886l8.3 1.97a.253.253 0 00.116 0l8.3-1.97a.894.894 0 00.646-.886V4.619a.6.6 0 00-.556-.628h-2.5a.255.255 0 01-.157-.054zm-1.781 1.561a.438.438 0 01.532 0l1.827 1.461a.252.252 0 01.079.278.249.249 0 01-.236.167h-3.873a.25.25 0 01-.156-.445l.693-.555zM1.25 14.741a1 1 0 00-1 1v7.25a1 1 0 002 0v-7.25a1 1 0 00-1-1zM15.75 19.491H6.625a.625.625 0 010-1.25H9.15a1 1 0 00.92-1.393 2.234 2.234 0 00-2.056-1.357H3.75a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.764a2.238 2.238 0 002.236-2.236v-.264a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});