define("ember-svg-jar/inlined/passport", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>passport</title><path d=\"M8.3 13.5a.5.5 0 00.5-.468 15.373 15.373 0 01.382-2.618.5.5 0 00-.79-.514 5.507 5.507 0 00-1.961 2.973.5.5 0 00.484.625zM14.7 13.032a.5.5 0 00.5.468h1.389a.5.5 0 00.484-.625A5.507 5.507 0 0015.108 9.9a.5.5 0 00-.792.512 15.358 15.358 0 01.384 2.62zM11.39 8.808a9.724 9.724 0 00-1.1 4.154.5.5 0 00.5.538h1.93a.5.5 0 00.5-.538 9.724 9.724 0 00-1.1-4.154.092.092 0 00-.073-.044c-.1-.007-.19-.014-.287-.014s-.192.007-.287.014a.092.092 0 00-.083.044zM8.807 15.465a.5.5 0 00-.5-.465H6.915a.5.5 0 00-.484.625 5.51 5.51 0 001.987 3 .5.5 0 00.79-.516 15.71 15.71 0 01-.401-2.644zM12.1 19.708a10.368 10.368 0 001.1-4.166.5.5 0 00-.5-.542h-1.906a.5.5 0 00-.5.542 10.368 10.368 0 001.1 4.166.052.052 0 00.043.027c.1.007.2.015.307.015s.205-.008.307-.015a.052.052 0 00.049-.027z\"/><path d=\"M20.8 5.175a.5.5 0 01-.3-.458V2a2 2 0 00-2-2h-13A3.5 3.5 0 002 3.5V21a3 3 0 003 3h15a2 2 0 002-2V7a1.993 1.993 0 00-1.2-1.825zM4 3.5A1.5 1.5 0 015.5 2H18a.5.5 0 01.5.5v2a.5.5 0 01-.5.5H5.5A1.5 1.5 0 014 3.5zm7.75 17.75a7 7 0 117-7 7.008 7.008 0 01-7 7z\"/><path d=\"M15.191 15a.5.5 0 00-.5.465 15.71 15.71 0 01-.4 2.639.5.5 0 00.79.516 5.51 5.51 0 001.987-3 .5.5 0 00-.483-.62z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});