define("ember-svg-jar/inlined/real-estate-insurance-house-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-insurance-house-1</title><path d=\"M14.5 19.5H7a.5.5 0 010-1h3a.5.5 0 00.5-.5 2 2 0 00-2-2H3.25a.25.25 0 01-.25-.25.75.75 0 00-1.5 0v7.5a.75.75 0 001.5 0 .25.25 0 01.25-.25H17a.5.5 0 00.5-.5 3 3 0 00-3-3zM19.668 2.919a.5.5 0 00.332.126.511.511 0 00.2-.045.5.5 0 00.3-.456V1a1 1 0 00-1-1h-1.8a.5.5 0 00-.332.874zM22.332 6.626L15.164.252a1 1 0 00-1.328 0L6.668 6.626A.5.5 0 007 7.5h2a.5.5 0 01.5.5v5a1 1 0 001 1H12a1 1 0 001-1v-2.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5V13a1 1 0 001 1h1.5a1 1 0 001-1V8a.5.5 0 01.5-.5h2a.5.5 0 00.332-.874z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});