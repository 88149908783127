define("ember-svg-jar/inlined/smart-watch-circle-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-location</title><path d=\"M12 3.5a8.5 8.5 0 108.5 8.5A8.5 8.5 0 0012 3.5zm.871 12.616a1 1 0 01-.786.384 1.007 1.007 0 01-.789-.384c-1.237-1.581-2.713-3.744-2.713-5.066a3.5 3.5 0 017 0c0 1.318-1.474 3.482-2.712 5.065zM7.379 3.05a.125.125 0 00.135.015 9.972 9.972 0 018.972 0 .125.125 0 00.177-.144L15.983.37A.5.5 0 0015.5 0h-7a.5.5 0 00-.483.37l-.68 2.551a.125.125 0 00.042.129z\"/><path d=\"M16.663 21.078a.125.125 0 00-.177-.144 9.98 9.98 0 01-8.972 0 .124.124 0 00-.177.143l.68 2.551A.5.5 0 008.5 24h7a.5.5 0 00.483-.371z\"/><circle cx=\"12.083\" cy=\"11.049\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});