define("ember-svg-jar/inlined/real-estate-deal-document-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-deal-document-building</title><path d=\"M11.974 10.209l-3.5-1.75a.5.5 0 00-.448 0l-3.5 1.75a.5.5 0 00-.276.447v8.75a.5.5 0 00.5.5h1.833a.5.5 0 00.5-.5v-1.25a.5.5 0 01.5-.5h1.334a.5.5 0 01.5.5v1.25a.5.5 0 00.5.5h1.833a.5.5 0 00.5-.5v-8.75a.5.5 0 00-.276-.447zM10 15.24H6.5a.5.5 0 010-1H10a.5.5 0 010 1zm0-2.917H6.5a.5.5 0 010-1H10a.5.5 0 010 1zM18.4 4.479l-2.88 2.645a.5.5 0 01-.715-.039l-.618-.707a1.25 1.25 0 00-1.883 1.644L13.7 9.615a1.851 1.851 0 002.647.148L20.1 6.321a1.251 1.251 0 00-1.7-1.842z\"/><path d=\"M21.5 0h-19A2.5 2.5 0 000 2.5v19A2.5 2.5 0 002.5 24H17a1.008 1.008 0 00.708-.294l6-6A1.1 1.1 0 0024 17V2.5A2.5 2.5 0 0021.5 0zm.5 15.5a.5.5 0 01-.5.5H18a2 2 0 00-2 2v3.5a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});