define("ember-svg-jar/inlined/music-genre-dumbbell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-genre-dumbbell</title><path d=\"M24 15.522a2 2 0 00-2.368-1.967c-.041.008-3.572 1.14-3.572 1.14a2 2 0 00-1.56 1.952v2.984a.25.25 0 01-.188.242 1.75 1.75 0 102.188 1.694V16.83a.25.25 0 01.175-.238c.553-.175 2.273-.726 3-.958a.25.25 0 01.326.238v1.759a.25.25 0 01-.188.242A1.75 1.75 0 1024 19.567zM3.414 11.875A2 2 0 00.586 14.7l.621.621a.256.256 0 01.07.22.251.251 0 01-.139.183 2 2 0 00-.552 3.218l3.535 3.536a2 2 0 003.219-.552.25.25 0 01.4-.069l.621.621a2 2 0 102.828-2.828zM18.971 11.875A2 2 0 0021.8 9.046l-.621-.621a.247.247 0 01-.07-.219.253.253 0 01.139-.184A2 2 0 0021.8 4.8l-3.536-3.532a2.048 2.048 0 00-2.828 0 1.993 1.993 0 00-.392.552.251.251 0 01-.183.139.247.247 0 01-.219-.07l-.621-.621A2 2 0 1011.192 4.1zM15.258 10.637a.249.249 0 000-.353l-2.475-2.475a.249.249 0 00-.353 0l-5.3 5.3a.251.251 0 000 .353l2.47 2.479a.25.25 0 00.353 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});