define("ember-svg-jar/inlined/human-resources-man-previous", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-man-previous</title><path d=\"M23.992 11.609A10.707 10.707 0 0012.89 1.294a10.843 10.843 0 00-9.732 7.161.25.25 0 01-.254.163L.538 8.436a.51.51 0 00-.469.246.5.5 0 00.015.53l3.069 4.6a.5.5 0 00.383.222h.033a.5.5 0 00.376-.171l3.579-4.088a.5.5 0 00-.338-.827L5.521 8.82a.251.251 0 01-.208-.354 8.809 8.809 0 017.651-5.173 8.712 8.712 0 019.03 8.389 8.923 8.923 0 01-2.475 6.491.119.119 0 01-.112.031.115.115 0 01-.082-.082 5.773 5.773 0 00-3.454-3.936.251.251 0 01-.156-.213.247.247 0 01.121-.234 4.413 4.413 0 10-4.366.074.25.25 0 01-.02.448A5.759 5.759 0 008 19.531a1 1 0 00.176.563 6.771 6.771 0 005.466 2.615 10.592 10.592 0 0010.35-11.1zM14.5 17.382a.75.75 0 01-1.5 0V16.16a.75.75 0 011.5 0zm-3.325-7.439a2.413 2.413 0 01.124-.764.253.253 0 01.154-.156.25.25 0 01.218.025 5.634 5.634 0 003.054.895 5.744 5.744 0 001.136-.114.118.118 0 01.1.024.115.115 0 01.042.09 2.413 2.413 0 11-4.825 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});