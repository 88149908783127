define("ember-svg-jar/inlined/milk-carton", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>milk-carton</title><path d=\"M6 3.5h12a.5.5 0 00.5-.5v-.5a2.793 2.793 0 00-3-2.5h-7a2.793 2.793 0 00-3 2.5V3a.5.5 0 00.5.5zM12.75 11.5H.5a.5.5 0 00-.5.5v10.5A1.5 1.5 0 001.5 24h11.25a.5.5 0 00.5-.5V12a.5.5 0 00-.5-.5zm-2.25 6a1 1 0 01-1 1h-6a1 1 0 01-1-1v-2a1 1 0 011-1h6a1 1 0 011 1zM17.8 5.3a.5.5 0 00-.458-.3H5.5a.5.5 0 00-.371.165l-3.615 4a.5.5 0 00.371.835h11.794a.5.5 0 00.369-.162l3.667-4A.5.5 0 0017.8 5.3zM23.578 10.372l-3.6-3.978a.5.5 0 00-.74 0l-4.361 4.758a.5.5 0 00-.131.338V23.5a.5.5 0 00.5.5H22.5a1.5 1.5 0 001.5-1.5V11.414a1.494 1.494 0 00-.422-1.042z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});