define("ember-svg-jar/inlined/video-game-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-key</title><path d=\"M1.874 4.25h.5a.249.249 0 01.25.25V5a.75.75 0 101.5 0v-.5a.25.25 0 01.25-.25h.5a.75.75 0 000-1.5h-.5a.25.25 0 01-.25-.25V2a.75.75 0 00-1.5 0v.5a.249.249 0 01-.25.25h-.5a.75.75 0 000 1.5zM21.374 19.75h-.5a.25.25 0 01-.25-.25V19a.75.75 0 00-1.5 0v.5a.249.249 0 01-.25.25h-.5a.75.75 0 100 1.5h.5a.249.249 0 01.25.25v.5a.75.75 0 001.5 0v-.5a.25.25 0 01.25-.25h.5a.75.75 0 000-1.5zM23.374 7a7 7 0 10-13.313 3.012.5.5 0 01-.1.57L1.21 19.336A2 2 0 004 22.2a.5.5 0 01.687.019l1.485 1.485a1 1 0 101.414-1.414L6.1 20.811a.5.5 0 010-.708l.629-.628a.5.5 0 01.707 0l1.482 1.482a1 1 0 001.414-1.414l-1.483-1.482a.5.5 0 010-.707l3.944-3.944a.5.5 0 01.569-.1A6.99 6.99 0 0023.374 7zm-7 3a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});