define("ember-svg-jar/inlined/cat-scratcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cat-scratcher</title><path d=\"M21 13.25h.5a1 1 0 001-1v-.5a1 1 0 00-1-1h-8.283c.334-1.268.847-4.994 6.033-7.292v1.811a2 2 0 102 0V2.711a1.5 1.5 0 00-2.037-1.4A12.572 12.572 0 0012.5 6.8V2.75h.5a1 1 0 001-1v-.5a1 1 0 00-1-1H5a1 1 0 00-1 1v.5a1 1 0 001 1h.5v8h-3a1 1 0 00-1 1v.5a1 1 0 001 1H3v8h-.5a1 1 0 00-1 1v.5a1 1 0 001 1h19a1 1 0 001-1v-.5a1 1 0 00-1-1H21zM5 17a.25.25 0 01.25-.25h2.5A.25.25 0 018 17v.5a.25.25 0 01-.25.25h-2.5A.25.25 0 015 17.5zM7.5 6.5a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25V7a.25.25 0 01-.25.25h-2.5A.25.25 0 017.5 7zm2.75 14.75A.25.25 0 0110 21v-7.5a.25.25 0 01.25-.25h3.5a.25.25 0 01.25.25V21a.25.25 0 01-.25.25zM16 17a.25.25 0 01.25-.25h2.5A.25.25 0 0119 17v.5a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25zm3-2.5a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25zM10.25 2.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-2.5A.25.25 0 017.5 4V3a.25.25 0 01.25-.25zM7.5 9.5a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25zm.25 3.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-2.5A.25.25 0 015 14.5v-1a.25.25 0 01.25-.25zM5 20a.25.25 0 01.25-.25h2.5A.25.25 0 018 20v1a.25.25 0 01-.25.25h-2.5A.25.25 0 015 21zm11.25 1.25A.25.25 0 0116 21v-1a.25.25 0 01.25-.25h2.5A.25.25 0 0119 20v1a.25.25 0 01-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});