define("ember-svg-jar/inlined/currency-yuan-increase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-yuan-increase</title><path d=\"M7.5 0a7 7 0 107 7 7.009 7.009 0 00-7-7zm-.716 10.847V9.123a.25.25 0 00-.25-.25H5.9a.75.75 0 010-1.5h.634a.25.25 0 00.25-.25v-.281a.25.25 0 00-.055-.157L4.771 4.236A.75.75 0 115.942 3.3l1.4 1.747a.249.249 0 00.39 0L9.127 3.3a.751.751 0 011.172.938l-1.96 2.447a.256.256 0 00-.055.157v.281a.25.25 0 00.25.25h.634a.75.75 0 110 1.5h-.634a.25.25 0 00-.25.25v1.724a.75.75 0 01-1.5 0zM23.5 11a.5.5 0 00-.5-.5h-5a.5.5 0 00-.354.854l1.415 1.414a.251.251 0 01-.007.361l-4.583 4.225a.249.249 0 01-.325.012l-2.9-2.317a2.154 2.154 0 00-2.771.071l-.219.194-7.314 6.5a1.25 1.25 0 001.66 1.87l7.155-6.36a.251.251 0 01.323-.009l2.9 2.324a2.164 2.164 0 002.805-.1l5.047-4.653a.251.251 0 01.347.007l1.461 1.462A.5.5 0 0023.5 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});