define("ember-svg-jar/inlined/laptop-flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-flash</title><path d=\"M12.589 15.265a4.007 4.007 0 01-1.338.236 3.1 3.1 0 01-2.95-1.65.492.492 0 00-.47-.36H.751a.478.478 0 00-.41.22.467.467 0 00-.05.47 5.43 5.43 0 005 3.31h6.716a.25.25 0 00.18-.077.253.253 0 00.07-.183 2.394 2.394 0 01.564-1.64.208.208 0 00-.232-.326zM3.751 12a1 1 0 001-1V3a1 1 0 011-1h12a1 1 0 011 1v4.63a.249.249 0 00.389.207 1.974 1.974 0 011.331-.323.25.25 0 00.28-.248V3a3.009 3.009 0 00-3-3h-12a3.009 3.009 0 00-3 3v8a1 1 0 001 1zM23.67 15.508a.879.879 0 00-.794-.508H21a.25.25 0 01-.25-.25V9.5a.5.5 0 00-.89-.313l-5.9 7.37A.876.876 0 0014.626 18H16.5a.25.25 0 01.25.25v5.25a.5.5 0 00.334.471.48.48 0 00.166.029.5.5 0 00.39-.188l5.9-7.37a.877.877 0 00.13-.934z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});