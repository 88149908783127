define("ember-svg-jar/inlined/electronics-single-cell-battery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>electronics-single-cell-battery</title><path d=\"M17 13H7a1 1 0 100 2h3.75a.25.25 0 01.25.25V23a1 1 0 102 0v-7.75a.25.25 0 01.25-.25H17a1 1 0 000-2zM20 9.5h-6.75a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v8.25a.25.25 0 01-.25.25H4a1 1 0 000 2h16a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});