define("ember-svg-jar/inlined/share-location-hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>share-location-hand</title><path d=\"M16.714 16.5a1.058 1.058 0 00.8-.369c.213-.251 5.234-6.177 5.234-10.1a6.036 6.036 0 10-12.071 0c0 3.921 5.02 9.844 5.234 10.094a1.053 1.053 0 00.803.375zM14.5 6.036a2.215 2.215 0 112.214 2.214A2.215 2.215 0 0114.5 6.036zM16.75 19.5H7.625a.625.625 0 010-1.25h2.525a1 1 0 00.92-1.394A2.235 2.235 0 009.014 15.5H4.75a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.764a2.238 2.238 0 002.236-2.236V20.5a1 1 0 00-1-1zM2.25 14.75a1 1 0 00-1 1V23a1 1 0 002 0v-7.25a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});