define("ember-svg-jar/inlined/cell-border-top", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cell-border-top</title><path d=\"M23 18.5a1 1 0 00-1 1v2.252a.249.249 0 01-.25.25H19.5a1 1 0 000 2H22a2 2 0 002-2V19.5a1 1 0 00-1-1zM4.5 22H2.25a.249.249 0 01-.25-.25V19.5a1 1 0 00-2 0V22a2 2 0 002 2h2.5a1 1 0 000-2zM23 8a1 1 0 00-1 1v1.75a.249.249 0 01-.25.25H19.5a1 1 0 000 2h2.25a.25.25 0 01.25.25V15a1 1 0 002 0V9a1 1 0 00-1-1zM15 11h-1.75a.249.249 0 01-.25-.25V9a1 1 0 00-2 0v1.75a.249.249 0 01-.25.25H9a1 1 0 000 2h1.75a.25.25 0 01.25.25V15a1 1 0 002 0v-1.75a.25.25 0 01.25-.25H15a1 1 0 000-2zM15 22h-1.75a.249.249 0 01-.25-.25V19.5a1 1 0 00-2 0v2.25a.249.249 0 01-.25.25H9a1 1 0 000 2h6a1 1 0 000-2zM4.5 11H2.25a.249.249 0 01-.25-.25V9a1 1 0 00-2 0v6a1 1 0 002 0v-1.75a.25.25 0 01.25-.25H4.5a1 1 0 000-2zM23 0H1a1 1 0 000 2h22a1 1 0 000-2zM1 7a1 1 0 001-1V4a1 1 0 00-2 0v2a1 1 0 001 1zM12 7a1 1 0 001-1V4a1 1 0 00-2 0v2a1 1 0 001 1zM23 3a1 1 0 00-1 1v2a1 1 0 002 0V4a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});