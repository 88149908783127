define("ember-svg-jar/inlined/video-file-mpg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-mpg</title><path d=\"M12 13.125h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H12a.875.875 0 000-1.75zM17.5 6h3.9a.25.25 0 00.177-.427L16.427.427A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6z\"/><path d=\"M17.5 7A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.25a.25.25 0 00-.25-.25zM9.125 18.5a.625.625 0 01-1.25 0v-2.976c0-.13-.143-.09-.188 0l-.128.256a.65.65 0 01-1.118 0s-.095-.18-.141-.28-.177-.119-.177 0v3a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279s.722 1.441.784 1.569a.176.176 0 00.315 0l.783-1.567a.625.625 0 011.184.279zm2.75-2.375h-.5a.25.25 0 00-.25.25V18.5a.625.625 0 01-1.25 0v-6a.625.625 0 01.625-.625H12a2.128 2.128 0 012.114 2.347 2.213 2.213 0 01-2.239 1.903zm7.25-1.625a.625.625 0 01-1.25 0V14a.875.875 0 00-1.75 0v3a.875.875 0 001.7.307c.021-.058.047-.182-.054-.182H17.5a.625.625 0 010-1.25h1a.625.625 0 01.625.625v.5a2.125 2.125 0 01-4.25 0v-3a2.125 2.125 0 014.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});