define("ember-svg-jar/inlined/cloud-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-remove</title><path d=\"M24 11.059a5.766 5.766 0 00-5.218-5.9.249.249 0 01-.191-.12 7.684 7.684 0 00-14.1 2.294.251.251 0 01-.227.2 4.643 4.643 0 00-3.64 2.241A4.47 4.47 0 000 12.223a4.289 4.289 0 001.235 3.1 4.745 4.745 0 002.031 1.142.988.988 0 001.258-.877v-.028a.99.99 0 00-.724-1.03 2.72 2.72 0 01-1.158-.636A2.374 2.374 0 012 12.109a2.588 2.588 0 012.586-2.588 2.7 2.7 0 01.535.054.988.988 0 00.811-.184 1 1 0 00.392-.779c.085-5.736 8.293-7.372 10.818-2.044a.978.978 0 00.941.57 3.773 3.773 0 013.332 1.742A3.647 3.647 0 0122 10.814a3.512 3.512 0 01-1.312 2.986.973.973 0 00-.353.917v.028a.975.975 0 001.568.625A5.394 5.394 0 0024 11.059z\"/><path d=\"M19 16.2a6.5 6.5 0 10-6.5 6.5 6.508 6.508 0 006.5-6.5zm-3.849-2.652a.75.75 0 010 1.061l-1.414 1.414a.25.25 0 000 .353l1.414 1.414a.75.75 0 11-1.06 1.061l-1.414-1.414a.25.25 0 00-.354 0l-1.414 1.414a.75.75 0 01-1.06-1.061l1.414-1.414a.25.25 0 000-.353l-1.414-1.418a.75.75 0 011.06-1.061l1.414 1.414a.25.25 0 00.354 0l1.414-1.414a.749.749 0 011.06 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});