define("ember-svg-jar/inlined/single-man-actions-view.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-view</title><path d=\"M7.044 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.743a.5.5 0 01.741-.3A6.958 6.958 0 008.5 5a6.35 6.35 0 00.929-.07.5.5 0 01.571.564A3 3 0 014.044 5a2.8 2.8 0 01.1-.743zM10.96 12.182a.5.5 0 00-.151-.748A7.367 7.367 0 007.25 10.5a7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h8.429a.5.5 0 00.5-.466 6.945 6.945 0 011.487-3.852zM21.129 19.759a.248.248 0 01-.033-.311 5.513 5.513 0 10-1.696 1.691.25.25 0 01.311.034l2.534 2.534a1 1 0 001.414-1.414zM16.456 13a3.5 3.5 0 11-3.5 3.5 3.5 3.5 0 013.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});