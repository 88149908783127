define("ember-svg-jar/inlined/kitchenware-strainer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchenware-strainer</title><path d=\"M3.622 18.684a.5.5 0 00-.707 0L.5 21.1a1.7 1.7 0 102.4 2.4l2.418-2.418a.5.5 0 000-.707zM11.128 14.567l-1.694-1.694a.5.5 0 00-.707 0l-4.044 4.044a.5.5 0 000 .707l1.693 1.694a.5.5 0 00.708 0l4.044-4.044a.5.5 0 000-.707zM16.5 0A7.5 7.5 0 1024 7.5 7.5 7.5 0 0016.5 0zm-4.243 9.207a1 1 0 111-1 1 1 0 01-1 1zm1.475-3.475a1 1 0 111 1 1 1 0 01-1-1zm2.061 7.011a1 1 0 111-1 1 1 0 01-1 1zm.414-9.486a1 1 0 111 1 1 1 0 01-1-1zm2.061 7.011a1 1 0 111-1 1 1 0 01-1 1zm2.475-2.475a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});