define("ember-svg-jar/inlined/multiple-actions-home.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-home</title><path d=\"M15.384 10.591a2.5 2.5 0 013.228 0 .333.333 0 00.469-.47 6.01 6.01 0 00-7.058-1.586.5.5 0 00-.182.771 7.459 7.459 0 011.273 2.311.5.5 0 00.8.222z\"/><ellipse cx=\"14.013\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M5.989 8A6.014 6.014 0 000 13.333.6.6 0 00.6 14h10.573a.5.5 0 00.323-.118l.284-.241a.5.5 0 00.173-.449A6.012 6.012 0 005.989 8zM7.123 6.594A3.448 3.448 0 105.513 7a3.485 3.485 0 001.61-.406zM17.323 12.118a.5.5 0 00-.647 0l-6.5 5.5a.5.5 0 00.324.882h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-4.75a.25.25 0 01.25-.25h1.25a.5.5 0 00.323-.882z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});