define("ember-svg-jar/inlined/people-woman-cap-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-cap-2</title><path d=\"M12.739 12.95a1.181 1.181 0 01-.232.1 2.142 2.142 0 01-1.159 0 1.308 1.308 0 01-.237-.1.75.75 0 00-.715 1.319 3.44 3.44 0 003.064 0 .751.751 0 00-.721-1.317zM10.513 10.393a.75.75 0 00.5-1.308 2.1 2.1 0 00-2.634 0 .75.75 0 101 1.114.636.636 0 01.628 0 .743.743 0 00.506.194zM12.945 9.086a.75.75 0 101 1.114.637.637 0 01.627 0 .75.75 0 101-1.114 2.1 2.1 0 00-2.627 0z\"/><path d=\"M6.779 16.3a2.069 2.069 0 001.152-.738 6.747 6.747 0 004.09 2.061 6.738 6.738 0 004.064-2.038 2.071 2.071 0 001.136.715c1.687.406 3.566-.887 4.169-1.634a.5.5 0 00-.232-.788 3.87 3.87 0 01-2.1-2.763 3.166 3.166 0 00.232-1.1 2.112 2.112 0 00-.464-1.444c.059-.421.093-.823.114-1.215H23a.75.75 0 000-1.5h-4.076a6.3 6.3 0 00-1.374-3.64C16.374.829 14.514.125 12.021.125S7.666.829 6.49 2.216C5.256 3.674 4.827 5.811 5.215 8.57a2.116 2.116 0 00-.465 1.443 3.165 3.165 0 00.208 1.05 3.911 3.911 0 01-2.116 2.817.5.5 0 00-.232.788c.59.725 2.48 2.04 4.169 1.632zm5.242-.177c-1.033 0-3.831-1.49-4.209-3.74a1.747 1.747 0 00-1-1.3 1.279 1.279 0 01-.559-1.1.616.616 0 01.105-.439.752.752 0 00.406-.795C6.7 8.365 6.654 8 6.625 7.645a.252.252 0 01.249-.27h10.292a.252.252 0 01.25.27c-.029.354-.071.721-.136 1.107a.752.752 0 00.406.794.626.626 0 01.1.439 1.275 1.275 0 01-.559 1.1 1.744 1.744 0 00-1 1.3c-.376 2.25-3.174 3.74-4.206 3.74z\"/><path d=\"M21.08 18.662l-.017-.006-4.476-1.5a.5.5 0 00-.562.178 4.985 4.985 0 01-8.05 0 .5.5 0 00-.562-.178L2.92 18.662A4.519 4.519 0 000 22.875v.5a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-.5a4.519 4.519 0 00-2.92-4.213z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});