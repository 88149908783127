define("ember-svg-jar/inlined/police-footstep", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-footstep</title><path d=\"M11.5 5A5.506 5.506 0 006 10.5v9.568a3.932 3.932 0 107.582-1.46l-.9-2.249A1.721 1.721 0 0114.28 14 2.723 2.723 0 0017 11.28v-.78A5.506 5.506 0 0011.5 5z\"/><circle cx=\"17.5\" cy=\"2.5\" r=\"2.5\"/><circle cx=\"12.5\" cy=\"1.5\" r=\"1.5\"/><circle cx=\"8.5\" cy=\"2\" r=\"1.5\"/><circle cx=\"5.5\" cy=\"4.5\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});