define("ember-svg-jar/inlined/single-neutral-protect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-protect</title><path d=\"M23 4.57a2.907 2.907 0 00-1.746-2.664 23.418 23.418 0 00-18.508 0A2.907 2.907 0 001 4.57v6.288a13.455 13.455 0 008.618 12.505A5.934 5.934 0 0012 24a6.016 6.016 0 002.382-.637A13.455 13.455 0 0023 10.858zm-2 6.288a11.443 11.443 0 01-7.33 10.636l-1.009.384a1.848 1.848 0 01-1.322 0l-1.009-.384A11.443 11.443 0 013 10.858V4.57a.907.907 0 01.546-.831 21.419 21.419 0 0116.908 0A.907.907 0 0121 4.57z\"/><path d=\"M15.5 12.5a3.5 3.5 0 00-2.319-3.291.25.25 0 01-.021-.462 2.75 2.75 0 10-2.32 0 .25.25 0 01-.021.462A3.5 3.5 0 008.5 12.5V14a.5.5 0 00.5.5h.821a.25.25 0 01.249.225l.43 4.325a.5.5 0 00.5.45h2a.5.5 0 00.5-.45l.432-4.326a.25.25 0 01.249-.225H15a.5.5 0 00.5-.5z\"/><path d=\"M23 4.57a2.907 2.907 0 00-1.746-2.664 23.418 23.418 0 00-18.508 0A2.907 2.907 0 001 4.57v6.288a13.455 13.455 0 008.618 12.505A5.934 5.934 0 0012 24a6.016 6.016 0 002.382-.637A13.455 13.455 0 0023 10.858zm-2 6.288a11.443 11.443 0 01-7.33 10.636l-1.009.384a1.848 1.848 0 01-1.322 0l-1.009-.384A11.443 11.443 0 013 10.858V4.57a.907.907 0 01.546-.831 21.419 21.419 0 0116.908 0A.907.907 0 0121 4.57z\"/><path d=\"M15.5 12.5a3.5 3.5 0 00-2.319-3.291.25.25 0 01-.021-.462 2.75 2.75 0 10-2.32 0 .25.25 0 01-.021.462A3.5 3.5 0 008.5 12.5V14a.5.5 0 00.5.5h.821a.25.25 0 01.249.225l.43 4.325a.5.5 0 00.5.45h2a.5.5 0 00.5-.45l.432-4.326a.25.25 0 01.249-.225H15a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});