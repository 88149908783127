define("ember-svg-jar/inlined/data-transfer-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-transfer-horizontal</title><path d=\"M6 10.231a1 1 0 001-1v-1.25a.25.25 0 01.25-.25h10.108a1.5 1.5 0 000-3H7.25a.25.25 0 01-.25-.25v-1.25a1 1 0 00-1.515-.857l-5 3a1 1 0 000 1.715l5 3a1 1 0 00.515.142zM23.515 16.911l-5-3a1 1 0 00-1.515.858v1.25a.25.25 0 01-.25.25H6.642a1.5 1.5 0 100 3H16.75a.249.249 0 01.25.25v1.25a1 1 0 001.515.857l5-3a1 1 0 000-1.715z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});