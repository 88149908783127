define("ember-svg-jar/inlined/single-man-actions-alarm.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-alarm</title><path d=\"M7 10a5 5 0 10-5-5 5.006 5.006 0 005 5zM4.1 4.257a.5.5 0 01.741-.3A6.959 6.959 0 008.46 5a6.36 6.36 0 00.929-.07.5.5 0 01.569.564A3 3 0 014 5a2.841 2.841 0 01.1-.743z\"/><path d=\"M12 15.5a5.938 5.938 0 01.487-2.353.5.5 0 00-.114-.559A7.476 7.476 0 00.021 15.857.5.5 0 00.5 16.5h11a.5.5 0 00.5-.5zM23.659 19.884a4.086 4.086 0 01-1.159-2.717V15.5a4.5 4.5 0 00-9 0v1.667a4.086 4.086 0 01-1.159 2.717l-.241.316a.5.5 0 00.4.8h11a.5.5 0 00.4-.8zM19.682 22.194a.5.5 0 00-.4-.194h-2.568a.5.5 0 00-.484.625 1.827 1.827 0 003.54 0 .5.5 0 00-.088-.431z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});