define("ember-svg-jar/inlined/shipment-crack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-crack</title><path d=\"M19.5 8.454v-.032L18.553.877A1 1 0 0017.561 0h-2.438a.5.5 0 00-.353.146l-2.927 2.927a.25.25 0 00.177.427h.78a1.5 1.5 0 011.06 2.562l-4.151 4.145A1 1 0 118.3 8.793l2.866-2.866a.25.25 0 00-.177-.427h-.775a1.5 1.5 0 01-1.06-2.562L11.661.427A.25.25 0 0011.484 0H6.443a1 1 0 00-.992.876L4.5 8.484v.032a7.505 7.505 0 005.859 7.294.5.5 0 01.391.488V21a.5.5 0 01-.5.5H8A1.25 1.25 0 008 24h8a1.25 1.25 0 000-2.5h-2.25a.5.5 0 01-.5-.5v-4.7a.5.5 0 01.391-.488A7.523 7.523 0 0019.5 8.454z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});