define("ember-svg-jar/inlined/wild-bird-flock-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wild-bird-flock-1</title><path d=\"M8.964 13.187a.25.25 0 00-.019-.285.246.246 0 00-.274-.081A2.631 2.631 0 017.4 12.9c1.075-1.052.558-2.981.075-3.951a.252.252 0 00-.428-.034l-2.21 3.094a9.634 9.634 0 00-.507-.185c-1.689-.572-2.758 1.535-2.8 1.625a.25.25 0 00.224.362H3.1a2.862 2.862 0 002.542 1.439 3.938 3.938 0 003.322-2.063zM7.75 7.75H9.1a2.86 2.86 0 002.542 1.443 3.94 3.94 0 003.322-2.064.25.25 0 00-.019-.285.247.247 0 00-.274-.081 2.58 2.58 0 01-1.271.077c1.076-1.051.56-2.981.076-3.951a.25.25 0 00-.2-.138.247.247 0 00-.224.1l-2.215 3.098q-.254-.1-.508-.186C8.638 5.2 7.571 7.3 7.526 7.389a.249.249 0 00.224.361zM22.445 4.344a.248.248 0 00-.274-.081 2.59 2.59 0 01-1.271.077c1.076-1.051.56-2.981.076-3.951a.25.25 0 00-.2-.138.247.247 0 00-.224.1l-2.215 3.098q-.253-.1-.508-.186c-1.692-.564-2.758 1.536-2.8 1.626a.249.249 0 00.224.361H16.6a2.86 2.86 0 002.542 1.443 3.94 3.94 0 003.322-2.064.25.25 0 00-.019-.285zM13.464 17.687a.25.25 0 00-.019-.285.245.245 0 00-.274-.081 2.631 2.631 0 01-1.271.079c1.075-1.052.558-2.981.075-3.951a.249.249 0 00-.2-.137.235.235 0 00-.224.1l-2.21 3.094a9.634 9.634 0 00-.507-.185c-1.69-.564-2.758 1.536-2.8 1.625a.25.25 0 00.224.362H7.6a2.862 2.862 0 002.542 1.442 3.938 3.938 0 003.322-2.063zM19.171 21.321a2.632 2.632 0 01-1.271.079c1.075-1.052.558-2.981.075-3.951a.249.249 0 00-.2-.137.228.228 0 00-.224.1l-2.21 3.094a9.634 9.634 0 00-.507-.185c-1.692-.572-2.758 1.535-2.8 1.625a.25.25 0 00.224.362H13.6a2.862 2.862 0 002.542 1.442 3.938 3.938 0 003.322-2.063.25.25 0 00-.293-.366z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});