define("ember-svg-jar/inlined/remove-square-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>remove-square-1-alternate</title><path d=\"M21.545.25H2.455a2.206 2.206 0 00-2.2 2.2v19.096a2.207 2.207 0 002.205 2.2h19.09a2.207 2.207 0 002.2-2.2V2.454A2.206 2.206 0 0021.545.25zm-.3 21H2.75V2.75h18.5z\"/><path d=\"M7.066 16.934a1.247 1.247 0 001.767 0l2.99-2.989a.25.25 0 01.353 0l2.99 2.989a1.249 1.249 0 101.767-1.767l-2.989-2.99a.25.25 0 010-.354l2.989-2.989a1.25 1.25 0 10-1.767-1.768l-2.99 2.99a.25.25 0 01-.353 0l-2.99-2.99a1.25 1.25 0 00-1.767 1.768l2.989 2.989a.25.25 0 010 .354l-2.989 2.99a1.249 1.249 0 000 1.767z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});