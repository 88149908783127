define("ember-svg-jar/inlined/smiley-lol-sideways-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-lol-sideways-alternate</title><path d=\"M18.276 10.314a.5.5 0 00-.837-.225l-7.35 7.351a.5.5 0 00.225.836 6.5 6.5 0 007.962-7.962zM16.2 5.388a.142.142 0 01-.226 0 1 1 0 00-1.4-.2l-.829.621a.25.25 0 01-.4-.2V3.865a1 1 0 00-2 0v4.243a1 1 0 001.6.8l2.828-2.122a1.094 1.094 0 00.124-.11.245.245 0 01.182-.077.248.248 0 01.181.079.955.955 0 00.163.141C17.376 7.474 19.718 9 21 9a3 3 0 000-6c-1.279 0-3.621 1.522-4.569 2.177a.991.991 0 00-.231.211zM21 5a1 1 0 010 2 6.046 6.046 0 01-1.773-.783.25.25 0 010-.435A6.04 6.04 0 0121 5z\"/><path d=\"M3.263 16.873a10 10 0 0113.61-13.611 1 1 0 10.976-1.745A12 12 0 001.517 17.848a1 1 0 00.874.513.988.988 0 00.487-.128 1 1 0 00.385-1.36zM22.881 13.878a1 1 0 00-1.223.708 10.012 10.012 0 01-7.071 7.071 1 1 0 00.257 1.967.962.962 0 00.259-.035 12.009 12.009 0 008.486-8.489 1 1 0 00-.708-1.222zM5.173 16.428C4.519 17.375 3 19.718 3 21a3 3 0 006 0c0-1.279-1.523-3.622-2.177-4.569a1.039 1.039 0 00-1.65-.003zM6 22a1 1 0 01-1-1 6.049 6.049 0 01.782-1.772.25.25 0 01.435 0A6.033 6.033 0 017 21a1 1 0 01-1 1z\"/><path d=\"M8.108 11.35H3.865a1 1 0 000 2h1.814a.25.25 0 01.2.395l-.356.5a1 1 0 001.627 1.163l1.768-2.476a1 1 0 00-.813-1.581z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});