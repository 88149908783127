define("ember-svg-jar/inlined/button-pause", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-pause</title><path d=\"M12 24A12 12 0 100 12a12.013 12.013 0 0012 12zm-4.736-7.459v-8a1.5 1.5 0 011.5-1.5h.472a1.5 1.5 0 011.5 1.5v8a1.5 1.5 0 01-1.5 1.5h-.472a1.5 1.5 0 01-1.5-1.5zm5.944-8a1.5 1.5 0 011.5-1.5h.528a1.5 1.5 0 011.5 1.5v8a1.5 1.5 0 01-1.5 1.5h-.528a1.5 1.5 0 01-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});