define("ember-svg-jar/inlined/video-file-mov-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-mov-1</title><path d=\"M23.707 5.707L18.293.293A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.75V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707z\"/><path d=\"M9 12.125a.877.877 0 00-.875.875v3a.875.875 0 001.75 0v-3A.877.877 0 009 12.125z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zM6.125 17.5a.625.625 0 01-1.25 0v-2.978c0-.147-.15-.075-.19 0l-.126.252a.65.65 0 01-1.118 0s-.066-.136-.129-.257-.187-.128-.187 0V17.5a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279l.738 1.475a.242.242 0 00.4 0l.74-1.479a.625.625 0 011.184.279zm5-1.5a2.125 2.125 0 01-4.25 0v-3a2.125 2.125 0 014.25 0zm5-3a8.176 8.176 0 01-1.625 4.875.625.625 0 01-1 0A8.176 8.176 0 0111.875 13v-1.5a.625.625 0 011.25 0V13a6.931 6.931 0 00.771 3.161.132.132 0 00.2.01A6.923 6.923 0 0014.875 13v-1.5a.625.625 0 011.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});