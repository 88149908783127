define("ember-svg-jar/inlined/touch-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>touch-up</title><path d=\"M13.515 21.85a7.493 7.493 0 002.41-13.73.927.927 0 00-1.425.78v.178a.957.957 0 00.461.8 5.5 5.5 0 11-5.919 0 .95.95 0 00.458-.8V8.9a.927.927 0 00-1.413-.786 7.5 7.5 0 005.428 13.736z\"/><path d=\"M10.75 13a1.25 1.25 0 102.5 0V5.749a.25.25 0 01.25-.25h1a.5.5 0 00.354-.854l-.377-.376-2.123-2.124a.5.5 0 00-.708 0L9.523 4.269l-.377.376A.5.5 0 009.5 5.5h1a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});