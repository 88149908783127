define("ember-svg-jar/inlined/style-three-pin-house-repair", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-house-repair</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm5 13.51a.75.75 0 01-.75.75h-3.013a.5.5 0 00-.462.308 2.738 2.738 0 01-4.671.661.749.749 0 111.17-.937 1.251 1.251 0 100-1.563.749.749 0 11-1.17-.937 2.737 2.737 0 014.671.661.5.5 0 00.462.307h3.013a.75.75 0 01.75.75zm1.416-4.874A.75.75 0 0118 10.01a.74.74 0 01-.415-.126l-5.308-3.537a.5.5 0 00-.554 0L6.416 9.884a.75.75 0 01-.832-1.248l6-4a.75.75 0 01.832 0l1.7 1.13a.25.25 0 00.389-.208V5.01a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2.748a.5.5 0 00.223.416z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});