define("ember-svg-jar/inlined/tools-flattener-triangle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-flattener-triangle-1</title><path d=\"M20.588.854a1.751 1.751 0 00-2.475-.047L14 4.669a1.747 1.747 0 00-.466 1.75 1 1 0 00.283.874l2.2 2.172a.249.249 0 010 .355L11 14.835a.651.651 0 01-.935-.026.749.749 0 010-1.06l2.07-2.07a.25.25 0 000-.353l-.866-.865A2.442 2.442 0 008.916 9.8a2.03 2.03 0 00-1.457 1.453L3 22.622a.724.724 0 00.985.984l11.328-4.457a2.017 2.017 0 001.492-1.449 2.455 2.455 0 00-.666-2.371l-1.23-1.23a.25.25 0 010-.353L17.96 10.7a1.5 1.5 0 00-.006-2.123l-1.465-1.45 4.052-3.8a1.751 1.751 0 00.047-2.473z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});