define("ember-svg-jar/inlined/ceiling-lamp-chandelier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ceiling-lamp-chandelier</title><path d=\"M10.443 15.992a.99.99 0 00-1.807-.808A14.731 14.731 0 016.725 18.3a.5.5 0 01-.5.161 2.166 2.166 0 00-.532-.068A2.227 2.227 0 107.92 20.62a1.914 1.914 0 00-.035-.358.493.493 0 01.115-.427 16.236 16.236 0 002.443-3.843zm-4.752 5.369a.743.743 0 11.742-.743.743.743 0 01-.742.743zM4.236 17.654a.486.486 0 01.193-.175 10.242 10.242 0 005.592-7.172.989.989 0 10-1.952-.325 7.828 7.828 0 01-3.415 5.066.494.494 0 01-.648-.074 2.228 2.228 0 10.23 2.68zm-1.872-.445a.742.742 0 11.743-.742.742.742 0 01-.743.742z\"/><path d=\"M13.228 19.918A.5.5 0 0113 19.5v-8.861a.99.99 0 10-1.979 0V19.5a.5.5 0 01-.224.415 2.227 2.227 0 102.428 0zm-1.214 2.6a.742.742 0 11.742-.742.742.742 0 01-.742.739zM21.636 14.24a2.211 2.211 0 00-1.636.722.494.494 0 01-.646.071 7.816 7.816 0 01-3.4-5.051.989.989 0 10-1.952.325 10.234 10.234 0 005.554 7.153.511.511 0 01.194.178 2.222 2.222 0 101.881-3.4zm0 2.969a.742.742 0 11.742-.742.743.743 0 01-.742.742z\"/><path d=\"M18.337 18.391a2.181 2.181 0 00-.533.068.5.5 0 01-.5-.161 14.772 14.772 0 01-1.911-3.114.989.989 0 10-1.806.808 16.2 16.2 0 002.439 3.843.5.5 0 01.121.425 1.914 1.914 0 00-.035.358 2.227 2.227 0 102.227-2.227zm0 2.97a.743.743 0 11.742-.743.743.743 0 01-.742.743zM3.672 8.532a.989.989 0 001.287.552 18.307 18.307 0 0114.11 0 .99.99 0 10.731-1.838A20.933 20.933 0 0014.076 5.8a.093.093 0 01-.083-.1v-1A1.976 1.976 0 0013 2.99a.5.5 0 01-.247-.429V.742a.742.742 0 10-1.484 0v1.819a.5.5 0 01-.248.429 1.976 1.976 0 00-.99 1.711v1a.093.093 0 01-.084.1 20.933 20.933 0 00-5.724 1.445.99.99 0 00-.551 1.286z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});