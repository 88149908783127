define("ember-svg-jar/inlined/time-clock-circle-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-clock-circle-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M17.134 15.81L12.5 11.561V6.5a1 1 0 00-2 0V12a1 1 0 00.324.738l4.959 4.545a1.01 1.01 0 001.413-.061 1 1 0 00-.062-1.412z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});