define("ember-svg-jar/inlined/history-man-king", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-man-king</title><path d=\"M.5 23.5a.5.5 0 00.5.5h4a7.2 7.2 0 006.778-4 .25.25 0 01.444 0A7.2 7.2 0 0019 24h4a.5.5 0 00.5-.5v-1.782a2.105 2.105 0 00-1-1.756 16.076 16.076 0 00-6.418-2.179.253.253 0 00-.2.058A5.785 5.785 0 0112 19a5.785 5.785 0 01-3.884-1.159.253.253 0 00-.2-.058A16.083 16.083 0 001.5 19.962a2.105 2.105 0 00-1 1.756z\"/><path d=\"M8.6 16.615a.254.254 0 01.1.1C9.084 17.425 10.108 18 12 18s2.916-.575 3.306-1.287a.254.254 0 01.1-.1A7.034 7.034 0 0019 10.5v-2a6.95 6.95 0 00-.4-2.312.239.239 0 01-.013-.106L19 1.545a.5.5 0 00-.674-.513L15.21 2.2a.247.247 0 01-.2-.009 6.9 6.9 0 00-.953-.378.248.248 0 01-.1-.062l-1.6-1.6a.5.5 0 00-.708 0l-1.608 1.6a.238.238 0 01-.1.062 6.9 6.9 0 00-.953.378.247.247 0 01-.2.009L5.676 1.032A.5.5 0 005 1.545l.412 4.537a.239.239 0 01-.013.106A6.95 6.95 0 005 8.5v2a7.034 7.034 0 003.6 6.115zm1.86-2a.639.639 0 01.62-.482h2a.639.639 0 01.3 1.2 2.3 2.3 0 01-.436.181 2.983 2.983 0 01-.856.125h-.014a3.83 3.83 0 01-.866-.126 2.3 2.3 0 01-.437-.181.64.64 0 01-.314-.719zM7 10.5v-2a4.211 4.211 0 01.045-.577.25.25 0 01.279-.214A37.151 37.151 0 0012 8a37.151 37.151 0 004.676-.291.25.25 0 01.279.214A4.211 4.211 0 0117 8.5v2a4.937 4.937 0 01-.7 2.5 4.992 4.992 0 01-.975 1.2.25.25 0 01-.379-.053 3.468 3.468 0 00-5.9 0 .251.251 0 01-.379.053A4.992 4.992 0 017.7 13a4.937 4.937 0 01-.7-2.5z\"/><path d=\"M15 10a1 1 0 10-1 1 .878.878 0 00.1-.021A.989.989 0 0015 10zM10 9a1 1 0 00-1 1 .989.989 0 00.9.979.878.878 0 00.1.021 1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});