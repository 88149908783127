define("ember-svg-jar/inlined/diagram-arrow-bend-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-arrow-bend-down</title><path d=\"M1 4a1 1 0 000 2h10.75a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25z\"/><path d=\"M13.5 14.97a.25.25 0 01-.25.25H10a1 1 0 00-.732 1.68l6.167 6.638a1.458 1.458 0 002.137-.008l6.16-6.63A1 1 0 0023 15.22h-3.25a.25.25 0 01-.25-.25V4a4.075 4.075 0 00-.668-2.215A4 4 0 0015.5 0H1a1 1 0 000 2h10.5a1.993 1.993 0 011.343.523A1.957 1.957 0 0113.5 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});