define("ember-svg-jar/inlined/drugs-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drugs-box</title><path d=\"M20 0H4a2 2 0 00-2 2v2a1 1 0 001 1h18a1 1 0 001-1V2a2 2 0 00-2-2zM20.5 6.5h-17A.5.5 0 003 7v13.5A3.5 3.5 0 006.5 24h11a3.5 3.5 0 003.5-3.5V7a.5.5 0 00-.5-.5zM17 15.611a.556.556 0 01-.556.556h-2.277a.5.5 0 00-.5.5v2.277a.556.556 0 01-.556.556h-2.222a.556.556 0 01-.556-.556v-2.277a.5.5 0 00-.5-.5H7.556A.556.556 0 017 15.611v-2.222a.556.556 0 01.556-.556h2.277a.5.5 0 00.5-.5v-2.278a.556.556 0 01.556-.555h2.222a.556.556 0 01.556.555v2.278a.5.5 0 00.5.5h2.277a.556.556 0 01.556.556z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});