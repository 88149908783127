define("ember-svg-jar/inlined/instrument-banjo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>instrument-banjo</title><path d=\"M6.086 15.793a1 1 0 10-1.414 1.414l2.121 2.121a1 1 0 101.414-1.414z\"/><path d=\"M23.707 6.707a1 1 0 000-1.414l-.555-.555a.25.25 0 010-.354l.407-.407a1.5 1.5 0 000-2.121L22.145.441a1.5 1.5 0 00-2.122 0l-.407.408a.25.25 0 01-.353 0l-.556-.556a1 1 0 00-1.414 1.414l.555.556a.249.249 0 010 .353l-.654.654a1 1 0 00-.127 1.258.25.25 0 01-.032.315L11.779 10.1a.25.25 0 01-.309.035 7.5 7.5 0 102.395 2.395.249.249 0 01.036-.309l5.257-5.256a.249.249 0 01.314-.032 1 1 0 001.258-.127l.654-.654a.25.25 0 01.354 0l.555.555a1 1 0 001.414 0zM3.964 20.036a5 5 0 117.072 0 5 5 0 01-7.072 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});