define("ember-svg-jar/inlined/gesture-tap-swipe-right-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-tap-swipe-right-1</title><path d=\"M23.836 8.71l-2-2.5a.751.751 0 00-1.336.469v1.25a.25.25 0 01-.25.25H15a1 1 0 000 2h5.25a.25.25 0 01.25.25v1.25a.751.751 0 001.336.468l2-2.5a.75.75 0 000-.937zM12.644 6.3A6.649 6.649 0 006.75 2.819a6.745 6.745 0 00-5.715 10.313.75.75 0 101.271-.795A5.246 5.246 0 016.75 4.319a5.246 5.246 0 014.437 8.029.75.75 0 101.269.8 6.653 6.653 0 00.188-6.848z\"/><path d=\"M11.171 15.083l-3.742-1.117a.249.249 0 01-.178-.239V9.159a1 1 0 00-2 0v7.063a.25.25 0 01-.456.143c-1.489-2.138-3.855-1.449-2.761.224l2.912 4.367a.5.5 0 00.416.223H10.8a.5.5 0 00.492-.411c.2-1.1.664-3.683.775-4.166a1.281 1.281 0 00-.896-1.519z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});