define("ember-svg-jar/inlined/shooting-rifle-aim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shooting-rifle-aim</title><path d=\"M23 10.5H5.75a1.252 1.252 0 00-1.25 1.25.75.75 0 01-.75.75h-2.5A1.252 1.252 0 000 13.75v1A1.252 1.252 0 001.25 16h5.5a1.229 1.229 0 001.024-.536l1.329-1.6a1 1 0 01.77-.362h3.877a1.251 1.251 0 001.13-.715.5.5 0 01.451-.285H23a1 1 0 000-2z\"/><path d=\"M21.715 14.584a1 1 0 00-1.288.584 9.053 9.053 0 01-7.141 5.738.246.246 0 01-.2-.058.25.25 0 01-.086-.189v-1.313a.5.5 0 01.4-.489 7.035 7.035 0 004.663-3.357 1 1 0 00-1.731-1 5.022 5.022 0 01-3.016 2.323.248.248 0 01-.217-.042.252.252 0 01-.1-.2V16a1 1 0 00-2 0v.584a.25.25 0 01-.316.242 5.051 5.051 0 01-.94-.362 1 1 0 10-.9 1.783 6.933 6.933 0 001.76.611.5.5 0 01.4.49v1.313a.248.248 0 01-.086.189.255.255 0 01-.2.059 8.937 8.937 0 01-5.049-2.517 1 1 0 10-1.409 1.42 10.915 10.915 0 006.541 3.122.25.25 0 01.22.209.994.994 0 001.97 0 .25.25 0 01.22-.209 11.064 11.064 0 009.09-7.063 1 1 0 00-.585-1.287zM2.03 10.726a1.021 1.021 0 00.225.025 1 1 0 00.974-.776 8.942 8.942 0 017.486-6.885.254.254 0 01.2.059.251.251 0 01.085.189v1.314a.5.5 0 01-.4.49 7.01 7.01 0 00-3.831 2.207 1 1 0 001.494 1.33 5 5 0 012.421-1.5.249.249 0 01.316.239V8.5a1 1 0 002 0V7.415a.25.25 0 01.315-.241 4.969 4.969 0 012.247 1.317 1 1 0 101.424-1.4A6.938 6.938 0 0013.4 5.14a.5.5 0 01-.4-.491V3.34a.25.25 0 01.286-.248 9.018 9.018 0 016.94 5.252 1 1 0 101.827-.813 11.018 11.018 0 00-8.848-6.465.249.249 0 01-.22-.209.995.995 0 00-1.97 0 .25.25 0 01-.22.209 10.937 10.937 0 00-9.516 8.461 1 1 0 00.751 1.199z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});