define("ember-svg-jar/inlined/kitchenware-ice-tray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchenware-ice-tray</title><path d=\"M20.5 0h-17A2.5 2.5 0 001 2.5v19A2.5 2.5 0 003.5 24h17a2.5 2.5 0 002.5-2.5v-19A2.5 2.5 0 0020.5 0zm-10 20.5a1 1 0 01-1 1h-5a1 1 0 01-1-1v-3a1 1 0 011-1h5a1 1 0 011 1zm0-7a1 1 0 01-1 1h-5a1 1 0 01-1-1v-3a1 1 0 011-1h5a1 1 0 011 1zm0-7a1 1 0 01-1 1h-5a1 1 0 01-1-1v-3a1 1 0 011-1h5a1 1 0 011 1zm10 14a1 1 0 01-1 1h-5a1 1 0 01-1-1v-3a1 1 0 011-1h5a1 1 0 011 1zm0-7a1 1 0 01-1 1h-5a1 1 0 01-1-1v-3a1 1 0 011-1h5a1 1 0 011 1zm0-7a1 1 0 01-1 1h-5a1 1 0 01-1-1v-3a1 1 0 011-1h5a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});