define("ember-svg-jar/inlined/canoe-single", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>canoe-single</title><path d=\"M8.5 0h-3a1 1 0 00-1 1v3.424a1 1 0 00.168.555l1.164 1.746A1 1 0 016 7.28v9.592a.994.994 0 01-.168.554L4.584 19.3a.5.5 0 00-.084.278V23a1 1 0 001 1h3a1 1 0 001-1v-3.424a.5.5 0 00-.084-.278l-1.248-1.872A.994.994 0 018 16.872V7.28a1 1 0 01.168-.555l1.164-1.746a1 1 0 00.168-.555V1a1 1 0 00-1-1zM15.825.121a.5.5 0 00-.65 0C11.542 3.234 11.5 10.205 11.5 10.5v11A2.5 2.5 0 0014 24h3a2.5 2.5 0 002.5-2.5v-11c0-.295-.042-7.266-3.675-10.379zM17.5 14.83a2.284 2.284 0 01-.735 1.658l-.782.741a.717.717 0 01-.966 0l-.782-.741a2.284 2.284 0 01-.735-1.658v-1.612a2.3 2.3 0 01.831-1.744l.71-.609a.721.721 0 01.918 0l.71.609a2.3 2.3 0 01.831 1.744z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});