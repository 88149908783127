define("ember-svg-jar/inlined/outdoors-backpack-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-backpack-1</title><path d=\"M22 16h-.5a.5.5 0 00-.5.5v7a.5.5 0 00.5.5h1a1.5 1.5 0 001.5-1.5V18a2 2 0 00-2-2zM2.5 16H2a2 2 0 00-2 2v4.5A1.5 1.5 0 001.5 24h1a.5.5 0 00.5-.5v-7a.5.5 0 00-.5-.5zM9 10.5V7a.5.5 0 00-.5-.5h-1A.5.5 0 007 7v3.5a1 1 0 002 0z\"/><path d=\"M21 3.5A3.5 3.5 0 0017.5 0h-11A3.5 3.5 0 003 3.5v5A1.5 1.5 0 004.5 10H5a.5.5 0 00.5-.5V7a2 2 0 012-2h1a2 2 0 012 2v2.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V7a2 2 0 012-2h1a2 2 0 012 2v2.5a.5.5 0 00.5.5h.5A1.5 1.5 0 0021 8.5z\"/><path d=\"M17 10.5V7a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v3.5a1 1 0 002 0zM15.25 18h-6.5a.751.751 0 00-.75.75v4.75a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-4.75a.751.751 0 00-.75-.75z\"/><path d=\"M19 11.5h-.406a.5.5 0 00-.433.25 2.493 2.493 0 01-4.322 0 .5.5 0 00-.433-.25h-2.812a.5.5 0 00-.433.25 2.493 2.493 0 01-4.322 0 .5.5 0 00-.433-.25H5a.5.5 0 00-.5.5v11.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-4.75a2.253 2.253 0 012.25-2.25h6.5a2.253 2.253 0 012.25 2.25v4.75a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V12a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});