define("ember-svg-jar/inlined/road-sign-no-bicycle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-no-bicycle</title><path d=\"M17.305 15.686a.766.766 0 01-.3.064.752.752 0 01-.686-.445l-.451-1.016a.25.25 0 00-.455 0 1.685 1.685 0 00-.163.711 1.75 1.75 0 102.289-1.661.25.25 0 00-.306.339l.453 1.017a.752.752 0 01-.381.991z\"/><path d=\"M22.041 5.445a.247.247 0 00-.183-.111.25.25 0 00-.2.071l-5.488 5.486a.248.248 0 00-.052.278l.207.465a.248.248 0 00.262.146 2.974 2.974 0 01.413-.03 3.285 3.285 0 11-1.964.678.212.212 0 00-.154-.121.209.209 0 00-.187.058l-3.57 3.57a.26.26 0 00-.06.1 3.239 3.239 0 01-2.034 2.035.245.245 0 00-.1.059l-3.53 3.53a.25.25 0 00.04.386 11.988 11.988 0 0016.6-16.6zM20.766 3.833A11.887 11.887 0 0012 0 12.013 12.013 0 000 12a11.892 11.892 0 003.833 8.766.249.249 0 00.346-.006L20.76 4.18a.25.25 0 00.006-.347zm-8.677 7.017l-4.438 4.439a.218.218 0 01-.208.057.221.221 0 01-.156-.149.72.72 0 01.1-.633l.641-.9a.25.25 0 00-.225-.395 1.636 1.636 0 00-.669.206A1.755 1.755 0 006.25 15a1.733 1.733 0 00.35 1.04.248.248 0 01-.023.326l-.712.712a.252.252 0 01-.37-.018 3.248 3.248 0 013.567-5.133.252.252 0 00.281-.093l.269-.377a.248.248 0 00.037-.214l-.378-1.312a.249.249 0 00-.24-.181H8.5a.75.75 0 010-1.5H11a.75.75 0 010 1.5.167.167 0 00-.133.066.169.169 0 00-.027.146l.122.426a.25.25 0 00.295.175l.6-.133a.249.249 0 01.231.42zm3.975-3.975l-.983.984a.253.253 0 01-.111.064l-.154.042a.1.1 0 00-.062.052.1.1 0 000 .082.1.1 0 01-.02.108l-.832.832a.25.25 0 01-.4-.076l-.278-.624a1.25 1.25 0 01.814-1.714l1.262-.348a.745.745 0 01.784.272.249.249 0 01-.02.326z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});