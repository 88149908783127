define("ember-svg-jar/inlined/baseball-helmet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baseball-helmet</title><circle cx=\"11\" cy=\"18.506\" r=\"1.248\"/><path d=\"M23 11h-.606a.5.5 0 01-.493-.435A11 11 0 1011 23a4.5 4.5 0 100-8.993.513.513 0 01-.5-.513.5.5 0 01.5-.5h12a1 1 0 000-2zm-12 4.759a2.747 2.747 0 11-2.748 2.747A2.751 2.751 0 0111 15.759z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});