define("ember-svg-jar/inlined/programming-language-monitor-css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-language-monitor-css</title><path d=\"M19 23.584a1 1 0 000-2h-6a.5.5 0 01-.5-.5v-.668a.5.5 0 01.5-.5h8a3 3 0 003-3V3.005A2.548 2.548 0 0021.5.416h-19A2.548 2.548 0 000 3.005v13.911a3 3 0 003 3h7a.5.5 0 01.5.5v.668a.5.5 0 01-.5.5H5a1 1 0 000 2zM2 3.005a.55.55 0 01.5-.589h19a.55.55 0 01.5.589v11.911a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5z\"/><path d=\"M7.5 6.666a.75.75 0 000-1.5 2.752 2.752 0 00-2.75 2.75v1a2.753 2.753 0 002.75 2.75.75.75 0 000-1.5 1.252 1.252 0 01-1.25-1.25v-1a1.251 1.251 0 011.25-1.25zM11.249 6.916a.25.25 0 01.25-.25h1a.75.75 0 000-1.5h-1a1.752 1.752 0 00-1.75 1.75 2.55 2.55 0 001.3 2.1c.508.382.7.558.7.9a.251.251 0 01-.25.25h-1a.75.75 0 100 1.5h1a1.752 1.752 0 001.75-1.75 2.551 2.551 0 00-1.3-2.1c-.508-.382-.7-.558-.7-.9zM16.25 6.916a.25.25 0 01.25-.25h1a.75.75 0 000-1.5h-1a1.752 1.752 0 00-1.75 1.75 2.55 2.55 0 001.3 2.1c.508.382.7.558.7.9a.251.251 0 01-.25.25h-1a.75.75 0 000 1.5h1a1.752 1.752 0 001.75-1.75 2.551 2.551 0 00-1.3-2.1c-.508-.382-.7-.558-.7-.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});