define("ember-svg-jar/inlined/delivery-truck-boxes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>delivery-truck-boxes</title><path d=\"M4.5 20.344v2.25a1.2 1.2 0 00.609 1.06 2.977 2.977 0 002.782 0 1.2 1.2 0 00.609-1.06v-2.25a.25.25 0 00-.25-.25h-3.5a.25.25 0 00-.25.25zM15.5 20.344v2.25a1.2 1.2 0 00.609 1.06 2.977 2.977 0 002.782 0 1.2 1.2 0 00.609-1.06v-2.253a.247.247 0 00-.247-.247h-3.5a.25.25 0 00-.253.25zM13 10v4a.5.5 0 00.5.5H18a.5.5 0 00.5-.5v-4a.48.48 0 00-.458-.5H13.5a.5.5 0 00-.5.5z\"/><rect x=\"5.5\" y=\"9.501\" width=\"6\" height=\"5\" rx=\".5\" ry=\".5\"/><path d=\"M9.5 8h5a.5.5 0 00.5-.5V4.126a.573.573 0 00-.5-.625h-5a.573.573 0 00-.5.625V7.5a.5.5 0 00.5.5z\"/><path d=\"M22.16 16.476a.971.971 0 00-.481-.4.26.26 0 01-.179-.238V2a2.034 2.034 0 00-2.062-2H4.563A2.034 2.034 0 002.5 2v13.839a.26.26 0 01-.179.238.975.975 0 00-.481.4 1 1 0 00-.043.971l.361.724A1.5 1.5 0 003.5 19h17a1.494 1.494 0 001.342-.829l.36-.721a1.006 1.006 0 00-.042-.974zM19.5 3v12a1 1 0 01-1 1H5.507a1 1 0 01-1-1l.051-12a1 1 0 011-1H18.5a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});