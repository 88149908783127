define("ember-svg-jar/inlined/antique-axe-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>antique-axe-1</title><path d=\"M4.432 3.7a3.435 3.435 0 001.621-.875 3.74 3.74 0 00.575-.737.314.314 0 000-.231l-.421-.417a1.5 1.5 0 00-2.122 2.121.437.437 0 00.347.139zM23.746 20.594l-1.973-3.455a3.467 3.467 0 00-.565-.737s-8.389-8.383-8.452-8.453a.228.228 0 00-.276-.013 8.839 8.839 0 00-.846.619A3.429 3.429 0 0010.758 10a.257.257 0 00.066.258l8.047 8.048a2.484 2.484 0 01.6.977l.9 2.69A1.508 1.508 0 0021.8 23c.119 0 1.01-.206 1.01-.206a1.5 1.5 0 00.938-2.2zM9.267 12.434c-.076-.2-.276-.121-.395-.077a5.248 5.248 0 01-6.694-6.869.307.307 0 00-.074-.4 2.21 2.21 0 01-.461-.172 1 1 0 00-1.329.638 6.992 6.992 0 006.743 8.876 6.885 6.885 0 001.7-.212 1.129 1.129 0 00.671-1.312 2.147 2.147 0 01-.161-.472z\"/><path d=\"M11.88 6.13a4.983 4.983 0 00-1.245-2.186 4.978 4.978 0 00-2.152-1.3.5.5 0 00-.569.221A5.226 5.226 0 017.1 3.9a4.959 4.959 0 01-2.988 1.4.5.5 0 00-.2.114 3.573 3.573 0 00-.339.638 3.748 3.748 0 004.779 4.9A3.812 3.812 0 009 10.638a.469.469 0 00.117-.165 4.951 4.951 0 011.475-2.994 5.133 5.133 0 011.056-.785.5.5 0 00.232-.564z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});