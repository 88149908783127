define("ember-svg-jar/inlined/shopping-bag-purse-barcode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-bag-purse-barcode</title><path d=\"M23.823 19.66L22.244 7.032v-.008c0-.019-.006-.04-.01-.06 0-.021-.01-.04-.016-.061l-.005-.012v-.015L21.167 3.94a.744.744 0 00-.041-.091 1.729 1.729 0 00-1.526-.974h-1a.75.75 0 000 1.5h1a.234.234 0 01.172.126l.546 1.54a.251.251 0 01-.236.334h-2.73a.25.25 0 01-.25-.25v-1a5 5 0 00-10 0v1a.25.25 0 01-.25.25H4.009a.249.249 0 01-.23-.348l.633-1.493a.255.255 0 01.19-.159h1a.75.75 0 000-1.5h-1A1.752 1.752 0 003.054 3.9L1.809 6.832a.571.571 0 00-.021.058l-.005.01a.712.712 0 00-.027.128L.177 19.66A3.75 3.75 0 003.9 23.875h16.2a3.75 3.75 0 003.721-4.215zm-3.838-8.035v7.5a.625.625 0 01-1.25 0v-7.5a.625.625 0 011.25 0zm-6.395 5.24a.625.625 0 01-.625-.625v-4.615a.625.625 0 011.25 0v4.615a.625.625 0 01-.625.625zm.625 1.683v.577a.625.625 0 01-1.25 0v-.577a.625.625 0 011.25 0zm-3.509-1.683a.625.625 0 01-.625-.625v-4.615a.625.625 0 011.25 0v4.615a.625.625 0 01-.625.625zm.625 1.683v.577a.625.625 0 01-1.25 0v-.577a.625.625 0 011.25 0zm-3.51-1.683a.625.625 0 01-.621-.625v-4.615a.625.625 0 011.25 0v4.615a.625.625 0 01-.629.625zm.625 1.683v.577a.625.625 0 01-1.25 0v-.577a.625.625 0 011.25 0zm7.4 0a.625.625 0 111.25 0v.577a.625.625 0 11-1.25 0zm.625-1.683a.625.625 0 01-.625-.625v-4.615a.625.625 0 111.25 0v4.615a.625.625 0 01-.621.625zm-10.91-5.24v7.5a.625.625 0 01-1.25 0v-7.5a.625.625 0 011.25 0zm3.539-6.5a3 3 0 016 0v1a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});