define("ember-svg-jar/inlined/ventilator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ventilator</title><path d=\"M18.5 21h-4a.5.5 0 01-.5-.5v-1.606a.5.5 0 00-.571-.5 10.006 10.006 0 01-2.857 0 .5.5 0 00-.572.5V20.5a.5.5 0 01-.5.5h-4a1.5 1.5 0 000 3h13a1.5 1.5 0 100-3zM12 17a8.5 8.5 0 10-8.5-8.5A8.51 8.51 0 0012 17zm1.247-11.137l1.327-3.293a.5.5 0 01.7-.254A7.005 7.005 0 0119 8.5v.25a.5.5 0 01-.57.475l-3.762-.532A.2.2 0 0114.5 8.5a2.484 2.484 0 00-1.071-2.041.5.5 0 01-.182-.596zM12 11a2.489 2.489 0 001.06-.235.5.5 0 01.605.145l2.178 2.782a.5.5 0 01.1.394.5.5 0 01-.226.338 6.936 6.936 0 01-7.429 0 .5.5 0 01-.127-.731l2.169-2.783a.5.5 0 01.606-.145A2.481 2.481 0 0012 11zM5.185 9.112a.5.5 0 01-.18-.363V8.5a7 7 0 013.714-6.179.5.5 0 01.7.254l1.325 3.288a.5.5 0 01-.177.6A2.484 2.484 0 009.495 8.5a.194.194 0 01-.168.193l-3.752.531a.5.5 0 01-.39-.112z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});