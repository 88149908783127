define("ember-svg-jar/inlined/messages-bubble-square-subtract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-subtract</title><path d=\"M17.5 9.5a7.92 7.92 0 013.636.884.25.25 0 00.364-.223V1.5A1.5 1.5 0 0020 0H1.5A1.5 1.5 0 000 1.5v12.053A1.459 1.459 0 001.5 15H3v3.5a.5.5 0 00.832.373L8.19 15h1.537a.25.25 0 00.235-.167A8.01 8.01 0 0117.5 9.5z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zM15 16.749h5a.75.75 0 010 1.5h-5a.75.75 0 110-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});