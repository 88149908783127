define("ember-svg-jar/inlined/video-player-smartphone-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-player-smartphone-horizontal</title><path d=\"M13.523 11.152L8.446 8.134A.962.962 0 007 8.981v6.038a.962.962 0 001.446.847l5.076-3.019a.992.992 0 00.001-1.695z\"/><path d=\"M24 6a3 3 0 00-3-3H3a3 3 0 00-3 3v12a3 3 0 003 3h18a3 3 0 003-3zm-3 7.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM3 19a1 1 0 01-1-1V6a1 1 0 011-1h14.5a.5.5 0 01.5.5v13a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});