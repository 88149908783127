define("ember-svg-jar/inlined/hierarchy-files-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hierarchy-files-1</title><path d=\"M18 3a.5.5 0 00.5.5h2.4a.25.25 0 00.213-.119.248.248 0 00.011-.243 2.7 2.7 0 00-.482-.7L19.061.852a2.727 2.727 0 00-.7-.481.249.249 0 00-.361.223z\"/><path d=\"M18.5 4.5A1.5 1.5 0 0117 3V.249A.25.25 0 0016.75 0H14a1.5 1.5 0 00-1.5 1.5v8A1.5 1.5 0 0014 11h6a1.5 1.5 0 001.5-1.5v-5zM18.5 17.5A1.5 1.5 0 0117 16v-2.75a.25.25 0 00-.25-.25H14a1.5 1.5 0 00-1.5 1.5v8A1.5 1.5 0 0014 24h6a1.5 1.5 0 001.5-1.5v-4.75a.25.25 0 00-.25-.25z\"/><path d=\"M18 16a.5.5 0 00.5.5h2.4a.25.25 0 00.213-.119.248.248 0 00.011-.243 2.7 2.7 0 00-.482-.7l-1.585-1.586a2.727 2.727 0 00-.7-.481.249.249 0 00-.361.223zM3.5 13a1 1 0 001-1V9a1 1 0 00-2 0v3a1 1 0 001 1zM4.7 4.019a.25.25 0 01-.2-.245V2a1 1 0 00-2 0v3a1 1 0 001 1h1a1 1 0 00.2-1.98zM10 4H8a1 1 0 000 2h2a1 1 0 100-2zM4.7 18.019a.25.25 0 01-.2-.245V16a1 1 0 00-2 0v3a1 1 0 001 1h1a1 1 0 00.2-1.98zM10 18H8a1 1 0 000 2h2a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});