define("ember-svg-jar/inlined/gesture-tap-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-tap-2</title><path d=\"M12 2.614a7.25 7.25 0 00-6.148 11.093.75.75 0 101.271-.795 5.751 5.751 0 119.745.011.75.75 0 001.27.8A7.25 7.25 0 0012 2.614z\"/><path d=\"M16.417 15.288l-3.742-1.117a.249.249 0 01-.178-.239V9.364a1 1 0 00-2 0v7.064a.25.25 0 01-.456.142c-1.489-2.138-3.855-1.449-2.761.224l2.912 4.367a.5.5 0 00.416.223h5.436a.5.5 0 00.492-.409c.218-1.185.774-4.167.774-4.167a1.281 1.281 0 00-.893-1.52z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});