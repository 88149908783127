define("ember-svg-jar/inlined/vintage-man-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-man-1</title><path d=\"M12 17.5a.5.5 0 00-.488.389l-1.25 5.5a.5.5 0 00.488.611h2.5a.5.5 0 00.488-.611l-1.25-5.5A.5.5 0 0012 17.5zM19 9.5V8.189a.25.25 0 01.211-.247 40.647 40.647 0 002.506-.466 1 1 0 00.759-1.193.988.988 0 00-1.016-.763.311.311 0 00-.044.006 44.817 44.817 0 01-9.416.98 44.817 44.817 0 01-9.416-.98.222.222 0 00-.044-.006.986.986 0 00-1.016.763 1 1 0 00.759 1.193c.82.182 1.658.333 2.506.466A.25.25 0 015 8.189V9.5a7 7 0 0014 0zm-7 5a5.006 5.006 0 01-5-5v-.982a.249.249 0 01.275-.249A47.247 47.247 0 0012 8.506a47.247 47.247 0 004.725-.237.25.25 0 01.275.249V9.5a5.006 5.006 0 01-5 5zM8.5 24h.487a.5.5 0 00.388-.184.494.494 0 00.1-.418L8.2 17.28a.5.5 0 00-.582-.388c-.552.1-1.054.229-1.531.362a.252.252 0 00-.164.146l-1.258 3.048a.251.251 0 00.065.282l3.608 3.207A.249.249 0 008.5 24z\"/><path d=\"M4.5 18.235a.25.25 0 00-.327-.326 12.2 12.2 0 00-2.7 1.508A2.463 2.463 0 00.5 21.4v2.1a.5.5 0 00.5.5h5.246a.25.25 0 00.166-.437l-2.744-2.439a.5.5 0 01-.13-.564zM15.009 24h.491a.249.249 0 00.166-.063l3.604-3.207a.251.251 0 00.065-.282L18.08 17.4a.252.252 0 00-.163-.146c-.478-.134-.98-.259-1.532-.363a.5.5 0 00-.582.388L14.52 23.4a.494.494 0 00.1.418.5.5 0 00.389.182z\"/><path d=\"M17.754 24H23a.5.5 0 00.5-.5v-2.1a2.46 2.46 0 00-.968-1.977 12.215 12.215 0 00-2.7-1.509.25.25 0 00-.327.327l.957 2.324a.5.5 0 01-.13.564l-2.744 2.439a.25.25 0 00.166.437zM17.8 5.122a.248.248 0 00.173-.1.251.251 0 00.039-.2L16.987.388a.5.5 0 00-.645-.362A20.256 20.256 0 0112 1 20.335 20.335 0 017.658.026a.5.5 0 00-.645.362L5.99 4.818a.251.251 0 00.212.3 45.736 45.736 0 0011.598.004z\"/><circle cx=\"14\" cy=\"9.731\" r=\"1\"/><circle cx=\"10\" cy=\"9.731\" r=\"1\"/><path d=\"M13.077 11.862h-2a.64.64 0 00-.3 1.2 2.376 2.376 0 00.435.179 2.99 2.99 0 00.867.126h.006a2.976 2.976 0 00.863-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});