define("ember-svg-jar/inlined/vectors-pen-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vectors-pen-1</title><path d=\"M15.115 6.651a.251.251 0 00-.295-.058 6.487 6.487 0 01-5.64 0 .251.251 0 00-.295.058l-.511.569a8.525 8.525 0 00-1.189 9.674l3.481 6.552a1.52 1.52 0 00.134.215.25.25 0 00.45-.15V14.88a.5.5 0 00-.25-.433 2 2 0 112 0 .5.5 0 00-.25.433v8.632a.25.25 0 00.45.15 1.44 1.44 0 00.133-.213l3.482-6.555a8.525 8.525 0 00-1.189-9.674zM17 .738a.5.5 0 00-.5-.5h-9a.5.5 0 00-.5.5 5 5 0 0010 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});