define("ember-svg-jar/inlined/laptop-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-2</title><path d=\"M.5 16.751h17a.5.5 0 00.489-.4l2.74-12.79a1.5 1.5 0 00-1.466-1.814H4.309a1.507 1.507 0 00-1.467 1.189L.011 16.146a.5.5 0 00.489.605zm7-8a2.5 2.5 0 112.5 2.5 2.5 2.5 0 01-2.5-2.5zM23.5 18.251H.5a.5.5 0 00-.5.5v1a2.5 2.5 0 002.5 2.5h19a2.5 2.5 0 002.5-2.5v-1a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});