define("ember-svg-jar/inlined/video-game-ea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-ea</title><path d=\"M23.92 17.23l-7.07-11a.5.5 0 00-.42-.23.5.5 0 00-.42.23L10.49 15H5.35l1-2h3.5a.5.5 0 00.41-.21l1.4-2a.5.5 0 00-.41-.79h-8.6a.5.5 0 00-.41.21l-1.4 2a.5.5 0 00.41.79h1.2L.06 17.26A.5.5 0 00.5 18h11.56a.5.5 0 00.42-.23l3.92-6.25.8 1.48h-.54a.5.5 0 00-.41.21l-1.4 2a.5.5 0 00.41.79h3.92l1.25 1.79a.5.5 0 00.41.21h2.66a.5.5 0 00.42-.77z\"/><path d=\"M4 9h8.63a.5.5 0 00.41-.21l1.4-2a.5.5 0 00-.37-.79H5.45a.5.5 0 00-.45.21l-1.4 2A.5.5 0 004 9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});