define("ember-svg-jar/inlined/style-two-pin-wifi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-two-pin-wifi</title><path d=\"M12 0a9.511 9.511 0 00-9.5 9.5c0 6.547 6.538 12.488 8.541 14.153a1.5 1.5 0 001.917 0c2-1.665 8.542-7.608 8.542-14.154A9.511 9.511 0 0012 0zm0 17a1.75 1.75 0 111.75-1.75A1.752 1.752 0 0112 17zm4.278-4.634a1 1 0 01-1.406.15 4.543 4.543 0 00-5.744 0 1 1 0 01-1.256-1.557 6.531 6.531 0 018.256 0 1 1 0 01.15 1.407zM18 9.623a1 1 0 01-.628-.222 8.493 8.493 0 00-10.744 0 1 1 0 01-1.256-1.557 10.481 10.481 0 0113.256 0A1 1 0 0118 9.623z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});