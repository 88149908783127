define("ember-svg-jar/inlined/movies-3d-glasses-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>movies-3d-glasses-1</title><path d=\"M3.5 11h.25a.25.25 0 00.25-.25V8.414a.5.5 0 01.146-.353l.415-.415A1.49 1.49 0 005 6.586V2a1.5 1.5 0 00-3 0v2.586a.5.5 0 01-.146.353L.439 6.354A1.49 1.49 0 000 7.414v4.722a.221.221 0 00.137.205.224.224 0 00.242-.048A4.388 4.388 0 013.5 11zM23.62 12.292a.224.224 0 00.242.048.221.221 0 00.138-.204V7.414a1.49 1.49 0 00-.439-1.06l-1.415-1.415A.5.5 0 0122 4.586V2a1.5 1.5 0 00-3 0v4.586a1.49 1.49 0 00.439 1.06l.415.415a.5.5 0 01.146.353v2.336a.25.25 0 00.25.25h.25a4.387 4.387 0 013.12 1.292zM22.561 13.354A2.9 2.9 0 0020.5 12.5H15a3.815 3.815 0 00-2.069.643A1.9 1.9 0 0112 13.5a1.9 1.9 0 01-.931-.357A3.815 3.815 0 009 12.5H3.5a2.9 2.9 0 00-2.061.854A4.879 4.879 0 000 16.828V19a4.505 4.505 0 004.5 4.5H8a2.448 2.448 0 001.988-1.023l1.627-2.28a.492.492 0 01.769 0l1.628 2.28A2.448 2.448 0 0016 23.5h3.5A4.505 4.505 0 0024 19v-2.172a4.879 4.879 0 00-1.439-3.474zM22 16.5v2a2.5 2.5 0 01-2.5 2.5h-2a2.131 2.131 0 01-1.64-.769l-1.54-1.847A1.379 1.379 0 0114 17.5v-.793A1.707 1.707 0 0115.707 15H20.5a1.5 1.5 0 011.5 1.5zm-20 2v-2A1.5 1.5 0 013.5 15h4.793A1.707 1.707 0 0110 16.707v.793a1.379 1.379 0 01-.32.884l-1.54 1.847A2.131 2.131 0 016.5 21h-2A2.5 2.5 0 012 18.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});