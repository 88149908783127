define("ember-svg-jar/inlined/multiple-actions-view.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-view</title><path d=\"M16.5 9.5a.148.148 0 00.06-.283c-.59-.265-1.294-.526-2.089-.819l-.485-.172a1.8 1.8 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 0012 0a2.986 2.986 0 00-1.341.314.25.25 0 00-.1.363 5 5 0 01.841 2.808 6.265 6.265 0 01-.922 3.625.249.249 0 00.122.371c1.723.645 3.007 1.21 3.689 2.2a.249.249 0 00.275.1A6.98 6.98 0 0116.5 9.5z\"/><path d=\"M12.944 10.474a.246.246 0 00.121-.187.251.251 0 00-.077-.21A11.373 11.373 0 009.471 8.4l-.485-.172a1.8 1.8 0 01-.056-1.174 4.647 4.647 0 001.216-3.569A3.219 3.219 0 007 0a3.219 3.219 0 00-3.145 3.485 4.609 4.609 0 001.192 3.538 1.877 1.877 0 010 1.185L4.53 8.4c-2.007.737-3.457 1.27-3.911 2.177A7.531 7.531 0 000 13.5a.5.5 0 00.5.5h9.3a.251.251 0 00.232-.155 7.04 7.04 0 012.912-3.371zM21.172 19.758a.251.251 0 01-.033-.311 5.511 5.511 0 10-1.692 1.692.251.251 0 01.312.034l2.533 2.534a1 1 0 001.415-1.414zM13 16.5a3.5 3.5 0 113.5 3.5 3.5 3.5 0 01-3.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});