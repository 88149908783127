define("ember-svg-jar/inlined/real-estate-message-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-message-house</title><path d=\"M12.672 5.965a1 1 0 00-1.344 0l-5.164 4.7a.5.5 0 00.336.87h1a.5.5 0 01.5.5v2.5a1 1 0 001 1h1a1 1 0 001-1v-2a1 1 0 012 0v2a1 1 0 001 1h1a1 1 0 001-1v-2.5a.5.5 0 01.5-.5h1a.5.5 0 00.336-.87z\"/><path d=\"M12 .78C5.246.78 0 5.377 0 11.03a9.21 9.21 0 002.756 6.481.5.5 0 01.089.573L1.36 21.049a1.5 1.5 0 001.975 2.031l4.857-2.26a.5.5 0 01.342-.029A12.427 12.427 0 0012 21.28c6.754 0 12-4.6 12-10.25S18.755.78 12 .78zm0 18.5a10.793 10.793 0 01-3.425-.559.992.992 0 00-.719.049l-3.415 1.589a.249.249 0 01-.284-.052.253.253 0 01-.045-.287l1.027-2.047a1 1 0 00-.254-1.216A7.463 7.463 0 012 11.03c0-4.549 4.348-8.25 10-8.25s10 3.7 10 8.25-4.347 8.25-10 8.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});