define("ember-svg-jar/inlined/credit-card-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-lock</title><path d=\"M20 2a2 2 0 00-2-2H2a2 2 0 00-2 2v1a.25.25 0 00.25.25h19.5A.25.25 0 0020 3zM13.4 13.139a.249.249 0 00.134-.194A5.007 5.007 0 0118.5 8.5a4.7 4.7 0 011.187.161.25.25 0 00.313-.242V5a.25.25 0 00-.25-.25H.25A.25.25 0 000 5v8a2 2 0 002 2h9.572a.251.251 0 00.23-.15 3.511 3.511 0 011.598-1.711zM6 12.25H3a.75.75 0 010-1.5h3a.75.75 0 010 1.5zm3.5-3.5H3a.75.75 0 010-1.5h6.5a.75.75 0 010 1.5zM22.714 15.515a.249.249 0 01-.214-.247V14.25a3.5 3.5 0 00-7 0v1.018a.249.249 0 01-.214.247A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.286-1.485zM19 12.75a1.5 1.5 0 011.5 1.5v1a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-1a1.5 1.5 0 011.5-1.5zm1 7.5a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});