define("ember-svg-jar/inlined/dating-cup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dating-cup</title><path d=\"M11.474.017A2.508 2.508 0 009.364.8.5.5 0 019 .96.5.5 0 018.636.8 2.5 2.5 0 006.527.017a2.746 2.746 0 00-2.348 2.845c0 2 2.592 4.32 4.137 5.526a1.113 1.113 0 001.368 0c1.545-1.206 4.137-3.528 4.137-5.526A2.747 2.747 0 0011.474.017zM18 10H2.5a1 1 0 00-1 1v5.186A7.709 7.709 0 008.769 24a7.5 7.5 0 007.731-7.5v-4a.5.5 0 01.5-.5h1a2.5 2.5 0 011.071 4.76 1 1 0 10.858 1.806A4.5 4.5 0 0018 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});