define("ember-svg-jar/inlined/dresser-drawers-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dresser-drawers-1</title><path d=\"M10.75 15h-8.5a.5.5 0 00-.5.5v5a1.5 1.5 0 001.286 1.485.249.249 0 01.214.247V23a1 1 0 002 0v-.75A.25.25 0 015.5 22h5.25a.5.5 0 00.5-.5v-6a.5.5 0 00-.5-.5zm-2 3.75a1 1 0 111-1 1 1 0 01-1 1zM21.75 15h-8.5a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h5.25a.25.25 0 01.25.25V23a1 1 0 002 0v-.768a.249.249 0 01.214-.247A1.5 1.5 0 0022.25 20.5v-5a.5.5 0 00-.5-.5zm-6.5 3.75a1 1 0 111-1 1 1 0 01-1 1z\"/><rect x=\"1.75\" y=\"8\" width=\"20.5\" height=\"5.5\" rx=\".5\" ry=\".5\"/><path d=\"M20.75 0H3.25a1.5 1.5 0 00-1.5 1.5V6a.5.5 0 00.5.5h19.5a.5.5 0 00.5-.5V1.5a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});