define("ember-svg-jar/inlined/earpods-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earpods-left</title><path d=\"M2.4 10.842a7.754 7.754 0 00-2.056 7.227A7.628 7.628 0 007.839 24 7.71 7.71 0 102.4 10.842zm9.486 9.49a5.71 5.71 0 111.52-5.378 5.764 5.764 0 01-1.517 5.378zM17.853 0a6.218 6.218 0 00-4.613 1.986 1.5 1.5 0 00-.387 1.007v5.014a1.5 1.5 0 00.388 1.008A6.217 6.217 0 0017.853 11a6.445 6.445 0 001.392-.15.5.5 0 01.608.488V22.5a1.5 1.5 0 001.5 1.5h1a1.5 1.5 0 001.5-1.5v-17a5.773 5.773 0 00-6-5.5zm-1 5.5a1 1 0 11-1-1 1 1 0 011 1z\"/><path d=\"M9.353 17.544h-1v-3.75a1 1 0 00-2 0v4.75a1 1 0 001 1h2a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});