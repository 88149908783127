define("ember-svg-jar/inlined/cog-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cog-square</title><path d=\"M19.5 0h-15A4.505 4.505 0 000 4.5v15A4.5 4.5 0 004.5 24h15a4.5 4.5 0 004.5-4.5v-15A4.505 4.505 0 0019.5 0zm-1.351 13.255l.78.451a1.141 1.141 0 01.418 1.559l-.856 1.483a1.141 1.141 0 01-1.559.418l-.782-.452a6.237 6.237 0 01-2.15 1.24v.905A1.142 1.142 0 0112.855 20h-1.711A1.142 1.142 0 0110 18.859v-.9a6.321 6.321 0 01-2.153-1.247l-.781.451a1.14 1.14 0 01-1.558-.418l-.857-1.483a1.141 1.141 0 01.418-1.559l.782-.452a6.23 6.23 0 010-2.486l-.78-.451a1.141 1.141 0 01-.418-1.559l.856-1.483a1.142 1.142 0 011.559-.417l.783.451A6.235 6.235 0 0110 6.069v-.905a1.142 1.142 0 011.142-1.142h1.711A1.142 1.142 0 0114 5.164v.9a6.267 6.267 0 012.154 1.247l.781-.451a1.142 1.142 0 011.559.417l.856 1.483a1.141 1.141 0 01-.418 1.559l-.781.452a6.212 6.212 0 01-.002 2.484z\"/><circle cx=\"12\" cy=\"12.012\" r=\"3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});