define("ember-svg-jar/inlined/light-mode-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-mode-home</title><path d=\"M23 18.5h-9.75a.25.25 0 01-.25-.25v-6a.25.25 0 01.25-.25h.881a.86.86 0 00.608-1.468L7.854 3.646a.5.5 0 00-.708 0L.261 10.532A.86.86 0 00.869 12h.881a.25.25 0 01.25.25v6a.25.25 0 01-.25.25H1a1 1 0 000 2h22a1 1 0 000-2zm-17.5-4a1 1 0 011-1h2a1 1 0 011 1v3.75a.25.25 0 01-.25.25h-3.5a.25.25 0 01-.25-.25z\"/><path d=\"M14.97 16.534a.749.749 0 001.06 0l4-4a.75.75 0 00-1.06-1.06l-4 4a.749.749 0 000 1.06zM19.97 16.534a.749.749 0 001.06 0l1.5-1.5a.75.75 0 00-1.06-1.06l-1.5 1.5a.749.749 0 000 1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});