define("ember-svg-jar/inlined/tags-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-download</title><path d=\"M17.941 9.314a.5.5 0 00.417-.146l.741-.756A3.789 3.789 0 0020.085 6V2a2 2 0 00-2-2l-4.258.005a3.751 3.751 0 00-2.415 1L.585 11.834a2 2 0 000 2.828L5.421 19.5a1.972 1.972 0 001.406.582h.012a1.975 1.975 0 001.41-.6l.7-.708a.5.5 0 00.137-.42 7.985 7.985 0 018.855-9.04zM12.585 5.5a2 2 0 112 2 2 2 0 01-2-2z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-.391 9.812l-2-2.5a.5.5 0 01.391-.812h.75a.25.25 0 00.25-.25V14.5a1 1 0 012 0v2.75a.25.25 0 00.25.25h.75a.5.5 0 01.391.812l-2 2.5a.5.5 0 01-.782 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});