define("ember-svg-jar/inlined/farming-farmer-work", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>farming-farmer-work</title><path d=\"M12.931 7.009a2.488 2.488 0 002.39-3.177.253.253 0 00-.142-.161.249.249 0 00-.215.008l-3.872 2.03a.252.252 0 00-.129.172.249.249 0 00.052.208 2.481 2.481 0 001.916.92zM7.785 5.2a1 1 0 001.35.421l.618-.324-.005-.009 6.17-3.235v.008l.3-.156A1 1 0 1015.289.13l-1.364.716a.252.252 0 01-.287-.039l-.359-.336A1.51 1.51 0 0011.5.178l-1.325.693A1.5 1.5 0 009.4 2.476l.076.514a.249.249 0 01-.131.258l-1.14.6a1 1 0 00-.42 1.352zM6.16 5.394l-1.151-.317a1.5 1.5 0 00-1.823.97l-1.03 3.09a1.5 1.5 0 001.177 1.952l1.845.308A1.5 1.5 0 006.92 9.916V8.007a.252.252 0 01.317-.241l4.3 1.188a1.208 1.208 0 00-.254.169 1.4 1.4 0 00-.367.883v5.769a1 1 0 01-.191.588l-3.57 5.643a1.25 1.25 0 102.022 1.471l2.776-4.5a.25.25 0 01.426 0l2.776 4.5a1.25 1.25 0 002.022-1.471l-3.57-5.643a1 1 0 01-.191-.588v-2.751l.866.866a1.252 1.252 0 001.665.093l2.5-2a1.2 1.2 0 00.426-.722.248.248 0 01.312-.195 8.254 8.254 0 001.732.44 1 1 0 00.265-1.964L6.214 5.41zm9.885 4.8a.25.25 0 01.089.436l-.7.558a.251.251 0 01-.333-.019l-1.036-1.036a.25.25 0 01.243-.418z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});