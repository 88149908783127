define("ember-svg-jar/inlined/people-woman-3.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-3</title><path d=\"M22.534 18.917a14.255 14.255 0 00-5.908-2.481.5.5 0 00-.552.279A4.557 4.557 0 0112 19.5a4.662 4.662 0 01-4.073-2.785.5.5 0 00-.552-.279 14.939 14.939 0 00-5.909 2.481A2.466 2.466 0 00.5 20.894V23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-2.606a2.466 2.466 0 00-.966-1.977z\"/><path d=\"M15.587 15.5A7 7 0 0019 9.5v-2a6.884 6.884 0 00-.192-1.6.251.251 0 01.123-.278 2.992 2.992 0 10-3.721-4.544.25.25 0 01-.3.066 6.915 6.915 0 00-5.828 0 .25.25 0 01-.3-.066 2.992 2.992 0 10-3.713 4.548.251.251 0 01.123.278A6.884 6.884 0 005 7.5v2a6.985 6.985 0 0010.587 6zM7 9.5V8.491a.251.251 0 01.243-.25 7 7 0 004.694-1.693.1.1 0 01.126 0 7 7 0 004.694 1.7.249.249 0 01.243.25V9.5a5 5 0 01-10 0z\"/><circle cx=\"14\" cy=\"10\" r=\"1\"/><circle cx=\"10\" cy=\"10\" r=\"1\"/><path d=\"M10.458 12.611a.638.638 0 00.312.719 2.308 2.308 0 00.438.183 3.017 3.017 0 00.869.127 2.977 2.977 0 00.866-.125 2.378 2.378 0 00.441-.184.639.639 0 00-.307-1.2h-2a.639.639 0 00-.619.48z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});