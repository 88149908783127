define("ember-svg-jar/inlined/flask", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flask</title><path d=\"M8.5 5.5H15a1.252 1.252 0 001.25-1.25v-2.5A1.752 1.752 0 0014.5 0h-5a1.752 1.752 0 00-1.75 1.75v3a.75.75 0 00.75.75zM20 7H4a2.5 2.5 0 00-2.5 2.5v12A2.511 2.511 0 004.062 24h.01A55.2 55.2 0 0120 24a2.5 2.5 0 002.5-2.5v-12A2.5 2.5 0 0020 7zM8.25 19.5a.75.75 0 01-1.5 0v-8a.75.75 0 011.5 0zm9 0a.75.75 0 01-1.5 0v-8a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});