define("ember-svg-jar/inlined/business-piggy-bank-broken", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-piggy-bank-broken</title><path d=\"M.5 11.251h2.316a.252.252 0 01.215.122 7.317 7.317 0 002.354 2.4.248.248 0 01.115.211v2.772a1 1 0 002 0v-1.5a.25.25 0 01.107-.205.247.247 0 01.229-.03 12.279 12.279 0 004.2.732 12.243 12.243 0 003.641-.543.25.25 0 01.324.239v1.3a1 1 0 102 0V14.32a.25.25 0 01.119-.213 7.358 7.358 0 003.567-4.789.253.253 0 01.174-.194A3 3 0 0024 6.251a1 1 0 00-2 0 1.017 1.017 0 01-.014.167.25.25 0 01-.485.035 8.5 8.5 0 00-5.738-5.133.252.252 0 00-.27.08l-2.248 2.747a.441.441 0 00-.011.543l2.225 2.967a.439.439 0 01-.014.546l-4.491 5.39a.44.44 0 01-.744-.453l2.262-5.371a.441.441 0 00-.094-.483l-2.249-2.249a.439.439 0 01-.112-.432l.992-3.471a.25.25 0 00-.048-.229.255.255 0 00-.217-.089 12.215 12.215 0 00-2.144.415.249.249 0 01-.24-.057A3.464 3.464 0 004.311.691a.5.5 0 000 .874A2.328 2.328 0 015.278 2.6a.249.249 0 01-.078.3 7.287 7.287 0 00-2.823 4.16.249.249 0 01-.241.187H.5a.5.5 0 00-.5.5v3a.5.5 0 00.5.504zm5.642-5.5A1.169 1.169 0 115 6.92a1.158 1.158 0 011.142-1.169z\"/><ellipse cx=\"3\" cy=\"22.251\" rx=\"3\" ry=\"1.5\"/><ellipse cx=\"10\" cy=\"20.251\" rx=\"3\" ry=\"1.5\"/><ellipse cx=\"20\" cy=\"22.251\" rx=\"3\" ry=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});