define("ember-svg-jar/inlined/focus-frame-target", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>focus-frame-target</title><path d=\"M15.25 12A3.25 3.25 0 1012 15.25 3.254 3.254 0 0015.25 12zM11 12a1 1 0 111 1 1 1 0 01-1-1z\"/><path d=\"M21.25 2H2.75A2.752 2.752 0 000 4.75v14.5A2.752 2.752 0 002.75 22h18.5A2.752 2.752 0 0024 19.25V4.75A2.752 2.752 0 0021.25 2zM7.346 12.95a.249.249 0 00-.245-.2H6a.75.75 0 010-1.5h1.1a.25.25 0 00.245-.2 4.762 4.762 0 013.7-3.7.25.25 0 00.2-.245V6a.75.75 0 011.5 0v1.1a.25.25 0 00.2.245 4.762 4.762 0 013.7 3.7.25.25 0 00.245.2H18a.75.75 0 010 1.5h-1.1a.25.25 0 00-.245.2 4.762 4.762 0 01-3.7 3.7.25.25 0 00-.2.245V18a.75.75 0 01-1.5 0v-1.1a.25.25 0 00-.2-.245 4.762 4.762 0 01-3.709-3.705zM2.5 6.25A1.752 1.752 0 014.25 4.5h2a.75.75 0 010 1.5h-2a.25.25 0 00-.25.25v2a.75.75 0 01-1.5 0zm0 9.5a.75.75 0 011.5 0v2a.25.25 0 00.25.25h2a.75.75 0 010 1.5h-2a1.752 1.752 0 01-1.75-1.75zM19.75 6h-2a.75.75 0 010-1.5h2a1.752 1.752 0 011.75 1.75v2a.75.75 0 01-1.5 0v-2a.25.25 0 00-.25-.25zm-2 12h2a.25.25 0 00.25-.25v-2a.75.75 0 011.5 0v2a1.752 1.752 0 01-1.75 1.75h-2a.75.75 0 010-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});