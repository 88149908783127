define("ember-svg-jar/inlined/single-woman-actions-heart.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-heart</title><path d=\"M9.986 13.779a4.91 4.91 0 01.719-1.058.249.249 0 00-.073-.388A7.4 7.4 0 007.25 11.5a7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643H9.35a.25.25 0 00.243-.308 5.03 5.03 0 01.393-3.413zM23.584 14.449a3.527 3.527 0 00-5.647-.916l-.3.3a.249.249 0 01-.353 0l-.3-.3a3.525 3.525 0 00-4.993 4.978l5.109 5.335a.5.5 0 00.722 0l5.106-5.327a3.524 3.524 0 00.656-4.07zM1.565 10.442a1 1 0 001.383-.3 5.233 5.233 0 00.454-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .249.249 0 01.411.082 5.233 5.233 0 00.454.9 1 1 0 001.681-1.082 4.569 4.569 0 01-.646-2.511V5.3a5.3 5.3 0 10-10.59 0v1.248a4.564 4.564 0 01-.643 2.512 1 1 0 00.298 1.382zM7.207 8.6a2.7 2.7 0 01-2.741-2.352.5.5 0 01.324-.577 5.7 5.7 0 002.245-1.439.25.25 0 01.361 0 5.7 5.7 0 002.23 1.437.5.5 0 01.323.576A2.708 2.708 0 017.207 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});