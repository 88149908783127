define("ember-svg-jar/inlined/road-sign-pedestrian-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-pedestrian-alternate</title><circle cx=\"8.835\" cy=\"5.633\" r=\"2.449\"/><path d=\"M11.257 10.21a.243.243 0 01-.049-.083 2.585 2.585 0 00-2.373-1.555 2.449 2.449 0 00-2.449 2.449l-.441 4.853a.49.49 0 00.488.535.2.2 0 01.2.2v2.5a.735.735 0 101.469 0v-2.455a.245.245 0 01.245-.245h.98a.245.245 0 01.245.245V19.1a.735.735 0 101.469 0v-2.472a.219.219 0 01.22-.219.49.49 0 00.488-.533l-.217-2.5a.245.245 0 01.427-.185l.249.278a.735.735 0 001.1-.981z\"/><circle cx=\"16.672\" cy=\"12\" r=\"1.469\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm7.486 18.681a.245.245 0 01-.413-.081.623.623 0 00-.073-.15l-.815-1.223a.242.242 0 01-.041-.136v-1.662a1.465 1.465 0 00-2.631-.89.244.244 0 01-.3.07l-.655-.327a.735.735 0 00-.657 1.314l1.173.586a.246.246 0 01.135.219v2.7a.735.735 0 001.47 0v-.683a.245.245 0 01.174-.234.242.242 0 01.274.1l.655.982a.733.733 0 00.611.327.06.06 0 01.057.04.058.058 0 01-.018.066 9.956 9.956 0 111.061-1.017z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});