define("ember-svg-jar/inlined/smiley-disapointed-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-disapointed-1-alternate</title><path d=\"M23.193 7.32a1 1 0 00-1.865.722 10 10 0 11-18.547-.271 1 1 0 10-1.843-.777A12 12 0 1024 11.653a11.927 11.927 0 00-.807-4.333z\"/><path d=\"M7.059 16.5a.44.44 0 00.379.66 21.078 21.078 0 019.123 0 .44.44 0 00.38-.659 5.707 5.707 0 00-9.882 0z\"/><circle cx=\"7.583\" cy=\"7.153\" r=\"1.75\"/><path d=\"M5.662 3.916a1 1 0 00.707-.293A4.337 4.337 0 019.45 2.347a1 1 0 000-2 6.35 6.35 0 00-4.495 1.861 1 1 0 00.707 1.708z\"/><circle cx=\"15.917\" cy=\"7.153\" r=\"1.75\"/><path d=\"M14.05 2.347a4.337 4.337 0 013.081 1.276 1 1 0 001.414-1.415A6.35 6.35 0 0014.05.347a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});