define("ember-svg-jar/inlined/body-care-soap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>body-care-soap</title><path d=\"M3.752 3.5a1 1 0 00.833-.445l.555-.832A.5.5 0 015.556 2H6.5a.5.5 0 01.5.5v1.361a.5.5 0 01-.5.5h-.25A1.374 1.374 0 005 5.746v.5a.75.75 0 00.75.75h4.5A.75.75 0 0011 6.25v-.5a1.374 1.374 0 00-1.25-1.385H9.5a.5.5 0 01-.5-.5V2.5a.5.5 0 01.5-.5h.75a1 1 0 000-2H5.288a2 2 0 00-1.664.891l-.7 1.054A1 1 0 003.752 3.5zM14.246 15.305A6.431 6.431 0 0111 9.656V8.5a.5.5 0 00-.5-.5H4a2.5 2.5 0 00-2.5 2.5v11A2.5 2.5 0 004 24h8a2.5 2.5 0 002.5-2.5v-5.76a.5.5 0 00-.254-.435zM18.311 1.414a1.041 1.041 0 00-1.622 0C15.125 3.579 12.5 7.529 12.5 9.656a4.89 4.89 0 005 5 4.89 4.89 0 005-5c0-2.127-2.625-6.077-4.189-8.242zM17.5 12.906a3.254 3.254 0 01-3.25-3.25.5.5 0 111 0 2.253 2.253 0 002.25 2.25.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});