define("ember-svg-jar/inlined/gardening-lawn-mower-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gardening-lawn-mower-1</title><path d=\"M4 13.5h6a.5.5 0 00.5-.5v-.5A1.5 1.5 0 009 11H5a1.5 1.5 0 00-1.5 1.5v.5a.5.5 0 00.5.5z\"/><circle cx=\"2\" cy=\"19.5\" r=\"2\"/><path d=\"M23.738 3.011a1 1 0 00-1.412-.065l-.262.24a.25.25 0 01-.4-.084 1 1 0 00-1.865.714l.264.792a.5.5 0 01-.137.528l-7.7 7.031a3 3 0 00-.977 2.215.289.289 0 01-.389.271A2.488 2.488 0 0010 14.5H4a2.5 2.5 0 00-2.307 1.539A.332.332 0 002 16.5a3 3 0 013 3 .5.5 0 00.5.5h3a.5.5 0 00.5-.5 3 3 0 013-3 2.951 2.951 0 01.642.07.5.5 0 00.608-.489v-1.7a1 1 0 01.326-.738l10.1-9.221a1 1 0 00.062-1.411z\"/><circle cx=\"12\" cy=\"19.5\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});