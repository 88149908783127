define("ember-svg-jar/inlined/road-sign-banned-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-banned-alternate</title><path d=\"M24 12a12 12 0 10-12 12 12.013 12.013 0 0012-12zM12 2a9.993 9.993 0 017.915 16.1.25.25 0 01-.375.024L5.874 4.46a.25.25 0 01.026-.375A9.944 9.944 0 0112 2zm0 20A9.993 9.993 0 014.085 5.9a.25.25 0 01.375-.024L18.126 19.54a.25.25 0 01-.024.375A9.944 9.944 0 0112 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});