define("ember-svg-jar/inlined/volume-control-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>volume-control-settings</title><path d=\"M10.883 17.389l.173-.186a.251.251 0 000-.341l-.174-.187a3.293 3.293 0 01.977-5.2.25.25 0 00.141-.222V1a1 1 0 00-1.707-.706L4.366 6.221a.249.249 0 01-.177.073H2a2 2 0 00-2 2v3.414a2 2 0 002 2h2.189a.253.253 0 01.177.073l5.286 5.287a.252.252 0 00.248.062.248.248 0 00.173-.187 3.288 3.288 0 01.81-1.554z\"/><circle cx=\"17.748\" cy=\"17.031\" r=\"1.5\"/><path d=\"M23.516 15.654a1.783 1.783 0 00-1.7-2.953l-1.41.327a.45.45 0 01-.534-.311l-.424-1.392a1.774 1.774 0 00-3.4 0l-.424 1.391a.457.457 0 01-.533.312l-1.41-.328a1.783 1.783 0 00-1.7 2.953l.988 1.064a.464.464 0 010 .626l-.988 1.065a1.783 1.783 0 001.7 2.953l1.41-.327a.453.453 0 01.534.311l.424 1.392a1.775 1.775 0 003.4 0l.424-1.392a.451.451 0 01.533-.311l1.411.327a1.783 1.783 0 001.7-2.953l-.988-1.065a.464.464 0 010-.626zm-5.768 4.377a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});