define("ember-svg-jar/inlined/medical-nanobot-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-nanobot-search</title><path d=\"M23.408 20.59l-4.064-4.066a.25.25 0 01-.033-.312A10.5 10.5 0 1010.5 21a10.384 10.384 0 005.707-1.692.248.248 0 01.313.033l4.068 4.069a1.994 1.994 0 002.82-2.82zM10.5 19a8.5 8.5 0 118.5-8.5 8.506 8.506 0 01-8.5 8.5z\"/><path d=\"M14.212 10.008a.5.5 0 01-.174-.36.5.5 0 01.146-.373l.242-.242a1.108 1.108 0 000-1.566l-3.143-3.143a1.108 1.108 0 00-1.566 0L6.574 7.467a1.108 1.108 0 000 1.566l.242.242a.5.5 0 01-.028.733l-1.439 1.233a1 1 0 00-.244 1.206l1.75 3.5a1 1 0 101.79-.894L7.418 12.6a.5.5 0 01.122-.6l.724-.621a.5.5 0 01.679.026l.411.411a.5.5 0 01.146.353V15.5a1 1 0 002 0v-3.334a.5.5 0 01.146-.353l.411-.411a.5.5 0 01.679-.026l.724.624a.5.5 0 01.122.6l-1.227 2.453a1 1 0 001.79.894l1.75-3.5a1 1 0 00-.244-1.206zM10.5 10a1.75 1.75 0 111.75-1.75A1.749 1.749 0 0110.5 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});