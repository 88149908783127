define("ember-svg-jar/inlined/power-tools-circular-saw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>power-tools-circular-saw</title><path d=\"M16.787 12.625a4.787 4.787 0 10-9.574 0 1.5 1.5 0 01-1.5 1.5H3.441a.25.25 0 00-.231.153 4.775 4.775 0 00-.293 1.061.573.573 0 00.566.661h1.8a3.4 3.4 0 00.764 1.848 2.632 2.632 0 00.026 3.436.573.573 0 00.876.069l1.273-1.306a7.844 7.844 0 002.139.867 2.812 2.812 0 002.7 2.455.571.571 0 00.66-.564v-1.8a3.414 3.414 0 001.849-.765 2.631 2.631 0 003.43-.025.573.573 0 00.069-.876l-1.306-1.273a7.825 7.825 0 00.867-2.138 2.378 2.378 0 002.077-1.445.25.25 0 00-.226-.358h-2.194a1.5 1.5 0 01-1.5-1.5zM12 16.572a2.286 2.286 0 112.286-2.286A2.285 2.285 0 0112 16.572z\"/><path d=\"M12 .623A11.848 11.848 0 00.012 12.026a.574.574 0 00.571.6h4.885a.251.251 0 00.25-.241A6.207 6.207 0 0112 6.338a6.207 6.207 0 016.282 6.045.25.25 0 00.25.242h4.885a.573.573 0 00.571-.6A11.848 11.848 0 0012 .623z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});