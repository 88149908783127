define("ember-svg-jar/inlined/truck-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>truck-3</title><path d=\"M23.233 12.7a2.249 2.249 0 00-1.483-3.946.25.25 0 01-.25-.25V8a7.906 7.906 0 00-.737-3.43l-.006-.01C19.384 1.662 16.194 0 12 0S4.611 1.667 3.234 4.572v.006A7.9 7.9 0 002.5 8v.506a.248.248 0 01-.248.248 2.25 2.25 0 00-1.471 3.954l1.627 1.361a.247.247 0 01.09.191v3.45a.25.25 0 01-.124.216A1.247 1.247 0 001.75 19v1.25A1.252 1.252 0 003 21.5h1a.25.25 0 01.25.25v.75A1.72 1.72 0 006 24a1.72 1.72 0 001.75-1.5v-.746A.25.25 0 018 21.5h8a.25.25 0 01.25.25v.75a1.771 1.771 0 003.5 0v-.746A.25.25 0 0120 21.5h1a1.252 1.252 0 001.25-1.25V19a1.245 1.245 0 00-.627-1.08.252.252 0 01-.125-.217v-3.439a.249.249 0 01.09-.192zM15 15.5a2 2 0 112 2 2 2 0 01-2-2zM19.5 8v.75a.25.25 0 01-.25.25h-6a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25H19a.249.249 0 01.239.178A6.278 6.278 0 0119.5 8zm-15 0a6.278 6.278 0 01.255-1.822A.25.25 0 014.992 6h5.756a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25h-6a.25.25 0 01-.25-.25zm10.751 3a.25.25 0 01.157.445 10.231 10.231 0 00-3.165 4.436.25.25 0 01-.469 0 10.056 10.056 0 00-3.136-4.435.25.25 0 01.155-.446zM5 15.5a2 2 0 112 2 2 2 0 01-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});