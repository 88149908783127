define("ember-svg-jar/inlined/wildlife-protection-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wildlife-protection-shield</title><path d=\"M21.319 1.915A22.6 22.6 0 0012 0a22.6 22.6 0 00-9.319 1.915A2.045 2.045 0 001.5 3.774v7.638a12.863 12.863 0 008.147 11.935l1.068.413a3.56 3.56 0 002.57 0l1.068-.413A12.863 12.863 0 0022.5 11.412V3.774a2.045 2.045 0 00-1.181-1.859zM17.667 10.4a.372.372 0 00-.356.482 8.024 8.024 0 01.356 2.351c0 3.443-2.224 4.534-5.667 4.534s-5.667-1.091-5.667-4.534a8.035 8.035 0 01.356-2.352.371.371 0 00-.356-.481 2.834 2.834 0 112.755-3.436.251.251 0 00.372.162 5.008 5.008 0 015.08 0 .251.251 0 00.372-.162 2.827 2.827 0 112.755 3.436z\"/><circle cx=\"9.733\" cy=\"12.1\" r=\"1.25\"/><circle cx=\"14.267\" cy=\"12.1\" r=\"1.25\"/><path d=\"M13.25 14.025h-2.5a.5.5 0 00-.354.854l1.25 1.25a.5.5 0 00.708 0l1.25-1.25a.5.5 0 00-.354-.854z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});