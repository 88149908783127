define("ember-svg-jar/inlined/water-glass-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-glass-1</title><path d=\"M20.5 1.61a1.588 1.588 0 00-.42-1.157A1.518 1.518 0 0019 0H5a1.567 1.567 0 00-1.143.524A1.562 1.562 0 003.5 1.629c.84 8.98.4 4.169 1.8 19.627A2.983 2.983 0 008.289 24h7.42a2.984 2.984 0 002.991-2.743c1.461-16.149.9-10.057 1.8-19.647zm-2.545 5.663a.249.249 0 01-.249.227H6.292a.25.25 0 01-.249-.227l-.471-5a.248.248 0 01.063-.191A.252.252 0 015.82 2h12.359a.251.251 0 01.184.081.248.248 0 01.064.192z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});