define("ember-svg-jar/inlined/road-sign-4m-high-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-4m-high-1</title><path d=\"M10.108 11.818a.189.189 0 00-.211.093q-.027.053-.051.108a.166.166 0 00.154.231.25.25 0 00.25-.25.188.188 0 00-.142-.182z\"/><path d=\"M23.53 11.47l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0l11-11a.749.749 0 000-1.06zM10.307 4.713A.751.751 0 0111 4.25h2a.75.75 0 01.53 1.28l-1 1a.748.748 0 01-1.06 0l-1-1a.749.749 0 01-.163-.817zM10 13.75H9a.75.75 0 01-.75-.75v-.5A2.752 2.752 0 0111 9.75a.75.75 0 01.75.75v4a.75.75 0 01-1.5 0V14a.25.25 0 00-.25-.25zm3.693 5.537a.751.751 0 01-.693.463h-2a.75.75 0 01-.53-1.28l1-1a.749.749 0 011.06 0l1 1a.749.749 0 01.163.817zM16.75 14.5a.75.75 0 01-1.5 0v-1.085a.058.058 0 00-.107-.029.78.78 0 01-1.286 0 .058.058 0 00-.107.029V14.5a.75.75 0 01-1.5 0v-4a.75.75 0 011.393-.386l.643 1.071a.25.25 0 00.428 0l.643-1.071a.75.75 0 011.393.386z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});