define("ember-svg-jar/inlined/shrink-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shrink-3</title><path d=\"M23.384.615a1.251 1.251 0 00-1.768 0l-4.843 4.844a.252.252 0 01-.354 0l-2.28-2.28a1 1 0 00-1.707.707v6.681a1 1 0 001 1h6.682a1 1 0 00.707-1.707L18.54 7.58a.251.251 0 010-.354l4.844-4.843a1.251 1.251 0 000-1.768zM.616 23.383a1.251 1.251 0 001.768 0l4.843-4.844a.25.25 0 01.354 0l2.28 2.28a1 1 0 00.707.293.987.987 0 00.383-.076 1 1 0 00.617-.924v-6.681a1 1 0 00-1-1H3.886a1 1 0 00-.707 1.707l2.281 2.28a.251.251 0 010 .354L.616 21.615a1.251 1.251 0 000 1.768z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});