define("ember-svg-jar/inlined/crime-man-riot-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-man-riot-2</title><path d=\"M23.755 23.02v-2.553a2.9 2.9 0 00-1.14-2.325 14.328 14.328 0 00-5.715-2.455.246.246 0 01-.189-.285 14.374 14.374 0 00.218-1.746.24.24 0 01.066-.152 6.826 6.826 0 001.863-4.688V6.857a6.857 6.857 0 00-13.714 0v1.959A6.826 6.826 0 007.006 13.5a.24.24 0 01.066.152A14.374 14.374 0 007.29 15.4a.246.246 0 01-.189.285 14.334 14.334 0 00-5.717 2.455 2.9 2.9 0 00-1.139 2.327v2.553a.98.98 0 101.959 0v-2.553a.956.956 0 01.369-.768 12.645 12.645 0 015.1-2.132.244.244 0 01.277.149 9.6 9.6 0 003.812 4.755.49.49 0 00.47 0 9.6 9.6 0 003.812-4.755.245.245 0 01.277-.149 12.632 12.632 0 015.1 2.132.954.954 0 01.37.768v2.553a.98.98 0 101.959 0zM7.1 7.885a.244.244 0 01.238-.244 6.854 6.854 0 004.6-1.658.092.092 0 01.124 0 6.854 6.854 0 004.6 1.659.245.245 0 01.238.245v.929a4.852 4.852 0 01-.733 2.566.246.246 0 01-.345.074 6.821 6.821 0 00-7.64 0 .246.246 0 01-.345-.074A4.852 4.852 0 017.1 8.816z\"/><circle cx=\"13.959\" cy=\"9.061\" r=\".98\"/><circle cx=\"10.041\" cy=\"9.061\" r=\".98\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});