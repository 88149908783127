define("ember-svg-jar/inlined/human-resources-employee-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-employee-star</title><path d=\"M22.533 19.917C21.106 18.827 17.841 17 12 17s-9.106 1.827-10.533 2.917A2.464 2.464 0 00.5 21.894v1.559a.5.5 0 00.5.5h9a.5.5 0 00.47-.328l1.3-3.534a.25.25 0 01.47 0l1.3 3.534a.5.5 0 00.467.328L23 24a.5.5 0 00.5-.5v-1.606a2.464 2.464 0 00-.967-1.977zM15.75 22a.75.75 0 01.75-.75h3a.75.75 0 010 1.5h-3a.75.75 0 01-.75-.75zM4.638 9.377l1.543 1.09a.249.249 0 01.085.3l-.88 2.023a2.6 2.6 0 00.65 3.011 2.635 2.635 0 001.778.7 2.7 2.7 0 001.321-.35l2.747-1.545a.249.249 0 01.245 0l2.75 1.546a2.7 2.7 0 003.1-.346 2.6 2.6 0 00.652-3.012l-.883-2.028a.25.25 0 01.085-.3l1.533-1.077.126-.1A2.6 2.6 0 0017.66 4.8h-1.811a.247.247 0 01-.233-.162l-1.181-3.113-.055-.119A2.617 2.617 0 0012.014 0h-.008a2.687 2.687 0 00-2.414 1.49l-1.2 3.15a.25.25 0 01-.233.161H6.347a2.609 2.609 0 00-2.435 1.608 2.669 2.669 0 00.726 2.968zm1.126-2.21a.592.592 0 01.583-.367h3.192a.25.25 0 00.234-.162l1.627-4.29A.677.677 0 0112.012 2a.649.649 0 01.576.291l1.647 4.348a.25.25 0 00.234.162h3.191a.594.594 0 01.584.366.619.619 0 01-.115.638L15.34 9.764a.251.251 0 00-.086.3l1.536 3.526a.613.613 0 01-.16.729.653.653 0 01-.775.086l-3.728-2.1a.249.249 0 00-.245 0l-3.726 2.1a.653.653 0 01-.778-.086.613.613 0 01-.158-.729l1.534-3.525a.249.249 0 00-.086-.3L5.974 7.884a.681.681 0 01-.21-.717z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});