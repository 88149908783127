define("ember-svg-jar/inlined/road-sign-horn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-horn</title><path d=\"M15 12.25h-3.783a.266.266 0 00-.1.022l-1.653.752a.249.249 0 00-.018.446 2.174 2.174 0 001.058.28H15a.75.75 0 000-1.5z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm2.5 8h1a.75.75 0 010 1.5h-1a.75.75 0 010-1.5zm-3 0h1a.75.75 0 010 1.5h-1a.75.75 0 010-1.5zm8.75 4.5a.75.75 0 01-1.28.53l-.707-.707a.251.251 0 00-.177-.073h-.642a.25.25 0 00-.2.093.254.254 0 00-.049.211 2.053 2.053 0 01.05.446A2.253 2.253 0 0115 15.25h-4.5a3.759 3.759 0 01-2.781-1.243.25.25 0 00-.289-.06l-.912.414a1.25 1.25 0 01-1.768-1.137V9.776a1.251 1.251 0 011.767-1.137l4.6 2.089a.266.266 0 00.1.022h6.869a.251.251 0 00.177-.073l.707-.707a.75.75 0 011.28.53z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});