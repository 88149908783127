define("ember-svg-jar/inlined/taking-pictures-circle-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>taking-pictures-circle-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M17.5 7.5H16a.913.913 0 01-.5-.207L14.207 6A1.872 1.872 0 0013 5.5h-2a1.872 1.872 0 00-1.207.5L8.5 7.293A.913.913 0 018 7.5H6.5a2 2 0 00-2 2v5a2 2 0 002 2h11a2 2 0 002-2v-5a2 2 0 00-2-2zM14.25 12A2.25 2.25 0 1112 9.75 2.25 2.25 0 0114.25 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});