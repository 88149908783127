define("ember-svg-jar/inlined/technology-hand-chip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>technology-hand-chip</title><path d=\"M16.112 12.273a.5.5 0 00.248-.22l1.854-3.341a1.366 1.366 0 00-2.327-1.431l-1.718 3.493a.455.455 0 01-.851-.31l1.851-7.475a1.366 1.366 0 00-2.669-.575l-1.12 5.722a.456.456 0 01-.9-.087V1.367a1.367 1.367 0 10-2.734 0V8.21a.456.456 0 01-.9.113L5.466 2.9a1.367 1.367 0 00-2.672.575l2.257 8.995a.456.456 0 01-.769.428l-1.993-2.061a1.366 1.366 0 10-1.845 2.016l4.79 6.378A4.1 4.1 0 008.2 20.5l3.473-.007a.249.249 0 00.232-.3 6.907 6.907 0 01-.156-1.441 6.994 6.994 0 014.363-6.479z\"/><path d=\"M23.25 19.25h-.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h.5a.75.75 0 000-1.5h-.516a.25.25 0 01-.248-.219 1.755 1.755 0 00-1.518-1.518.248.248 0 01-.218-.248v-.515a.75.75 0 00-1.5 0v.5A.25.25 0 0119 15h-.5a.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0v.515a.25.25 0 01-.219.248 1.755 1.755 0 00-1.517 1.518.251.251 0 01-.249.219h-.515a.75.75 0 000 1.5h.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-.5a.75.75 0 000 1.5h.515a.251.251 0 01.249.219 1.753 1.753 0 001.517 1.517.25.25 0 01.219.248v.516a.75.75 0 001.5 0v-.5a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.516a.25.25 0 01.218-.248 1.753 1.753 0 001.518-1.517.25.25 0 01.248-.219h.516a.75.75 0 000-1.5zM20 20h-1.5a.5.5 0 010-1H20a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});