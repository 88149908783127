define("ember-svg-jar/inlined/human-resources-offer-employee", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-offer-employee</title><path d=\"M1 14.75a1 1 0 00-1 1V23a1 1 0 002 0v-7.25a1 1 0 00-1-1zM15.5 19.5H6.375a.625.625 0 010-1.25H8.9a1 1 0 00.92-1.394A2.233 2.233 0 007.764 15.5H3.5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.764a2.239 2.239 0 002.236-2.237V20.5a1 1 0 00-1-1zM23.34 11.288c-.483-.971-2.041-1.548-4.2-2.348l-.473-.175a.25.25 0 01-.149-.151v-.013a1.53 1.53 0 01.007-.8.247.247 0 01.052-.09 6.084 6.084 0 001.272-4.141 3.356 3.356 0 10-6.7 0 6.069 6.069 0 001.267 4.13.243.243 0 01.048.082 1.473 1.473 0 01.018.82v.013a.248.248 0 01-.149.151l-.473.175c-2.155.8-3.713 1.377-4.2 2.348A6.76 6.76 0 009 14a.5.5 0 00.5.5h14a.5.5 0 00.5-.5 6.76 6.76 0 00-.66-2.712z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});