define("ember-svg-jar/inlined/common-file-double-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-double-1</title><path d=\"M17.25 19.75h-11a.5.5 0 01-.5-.5v-13a1 1 0 00-2 0v13.5a2 2 0 002 2h11.5a1 1 0 000-2z\"/><path d=\"M19.99 5.308l-2.844-2.8a.883.883 0 00-.626-.26H8.136a.886.886 0 00-.886.886V17.25a.886.886 0 00.886.886h11.228a.886.886 0 00.886-.886V5.935a.886.886 0 00-.26-.627z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});