define("ember-svg-jar/inlined/diamond", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diamond</title><path d=\"M18.019 8.836a.25.25 0 00-.241.183l-3.387 12.1a.25.25 0 00.444.212l8.64-12.094a.251.251 0 00-.2-.4zM16.182 9.154a.25.25 0 00-.24-.318H8.059a.25.25 0 00-.241.318l3.941 14.077a.25.25 0 00.482 0zM14.938 7.336a.25.25 0 00.226-.357L12.174.7A.193.193 0 0012 .586a.191.191 0 00-.173.11L8.836 6.979a.25.25 0 00.226.357zM9.165 21.327a.25.25 0 00.444-.212L6.222 9.019a.249.249 0 00-.24-.183H.729a.251.251 0 00-.2.4zM18.338 7.019a.249.249 0 00.241.317h4.676a.25.25 0 00.2-.4l-3.393-4.58a.25.25 0 00-.441.081zM5.422 7.336a.249.249 0 00.24-.317L4.38 2.437a.25.25 0 00-.442-.081L.544 6.938a.25.25 0 00.2.4zM7.016 6.292a.251.251 0 00.467.04L10.047.944a.25.25 0 00-.225-.358H5.745a.256.256 0 00-.2.1.251.251 0 00-.037.214zM16.517 6.332a.25.25 0 00.467-.04L18.492.9a.25.25 0 00-.236-.318h-4.078a.25.25 0 00-.225.358z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});