define("ember-svg-jar/inlined/button-syncing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-syncing</title><path d=\"M16.773 16.476H16.3a1.25 1.25 0 000 2.5h.478a6.944 6.944 0 00.98-13.823.251.251 0 01-.208-.246V2.93a1.25 1.25 0 00-1.966-1.024l-4.778 3.341a1.25 1.25 0 00.717 2.274h4.764c2.829 0 4.963 1.925 4.963 4.478a4.482 4.482 0 01-4.477 4.477zM6.247 18.845a.249.249 0 01.208.246v1.976a1.249 1.249 0 001.966 1.024l4.773-3.34a1.251 1.251 0 00-.717-2.275H7.713c-2.829 0-4.963-1.925-4.963-4.476a4.482 4.482 0 014.477-4.479h.478a1.25 1.25 0 000-2.5h-.478a6.945 6.945 0 00-.98 13.824z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});