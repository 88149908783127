define("ember-svg-jar/inlined/history-armor-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-armor-shield</title><path d=\"M21.932 14.421a2.483 2.483 0 010-4.842 1 1 0 00.744-1.214 11 11 0 00-21.352 0 1 1 0 00.744 1.215 2.483 2.483 0 010 4.842 1 1 0 00-.744 1.214 11 11 0 0021.352 0 1 1 0 00-.744-1.215zm-2.495 1.531a.25.25 0 01.082.283 8 8 0 01-15.038 0 .25.25 0 01.082-.283 5 5 0 000-7.9.251.251 0 01-.081-.283 8 8 0 0115.038 0 .251.251 0 01-.081.283 5 5 0 000 7.9z\"/><circle cx=\"8.25\" cy=\"12\" r=\"1\"/><circle cx=\"9.5\" cy=\"6.25\" r=\"1\"/><circle cx=\"14.5\" cy=\"6.25\" r=\"1\"/><circle cx=\"9.5\" cy=\"17.75\" r=\"1\"/><circle cx=\"14.5\" cy=\"17.75\" r=\"1\"/><circle cx=\"15.75\" cy=\"12\" r=\"1\"/><path d=\"M16.5 6.942l-3.331 3a.25.25 0 01-.417-.186V4.5a.75.75 0 00-1.5 0v5.254a.25.25 0 01-.417.186l-3.331-3a.751.751 0 10-1 1.116l4.174 3.756a.25.25 0 010 .372L6.5 15.942a.751.751 0 001 1.116l3.331-3a.25.25 0 01.417.186V19.5a.75.75 0 001.5 0v-5.254a.25.25 0 01.417-.186l3.331 3a.751.751 0 101-1.116l-4.174-3.756a.25.25 0 010-.372L17.5 8.058a.751.751 0 00-1-1.116z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});