define("ember-svg-jar/inlined/gesture-two-finger-flip-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-two-finger-flip-right</title><path d=\"M6.69 4.623a.75.75 0 001.348-.094l1-2.5a.753.753 0 00-.459-.989l-3-1a.751.751 0 00-.889 1.083l.46.8a.25.25 0 01-.042.3 10.343 10.343 0 00-2.4 4.2 1 1 0 001.9.615 9.606 9.606 0 011.433-2.853.25.25 0 01.415.028zM21.28 18.092a1 1 0 10-1.878-.686 9.157 9.157 0 01-1.272 2.412.251.251 0 01-.416-.028l-.236-.413a.75.75 0 00-1.349.1l-.985 2.5a.749.749 0 00.459.985l2.985 1a.751.751 0 00.89-1.083l-.458-.8a.25.25 0 01.041-.3 10 10 0 002.219-3.687zM14.039 12.068a.25.25 0 01-.14-.292l1.247-4.657a.929.929 0 00-1.8-.454l-1.43 4.591a.25.25 0 01-.3.168l-.584-.143a.249.249 0 01-.19-.243V6.481a1 1 0 00-2 0v7.062a.25.25 0 01-.456.143c-1.489-2.138-3.855-1.449-2.761.224l2.912 4.366a.5.5 0 00.416.223h5.434a.5.5 0 00.493-.411c.2-1.1.664-3.683.775-4.165.253-1.093-.661-1.429-1.616-1.855z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});