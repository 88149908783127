define("ember-svg-jar/inlined/car-repair-checklist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-repair-checklist</title><path d=\"M5.312 22h-4a.25.25 0 00-.25.25v.5A1.252 1.252 0 002.312 24h2a1.251 1.251 0 001.25-1.25v-.5a.249.249 0 00-.25-.25zM19.312 22h-4a.25.25 0 00-.25.25v.5a1.252 1.252 0 001.25 1.25h2a1.251 1.251 0 001.25-1.25v-.5a.249.249 0 00-.25-.25zM23.437 0h-10a.5.5 0 00-.5.5v11a.5.5 0 00.5.5h10a.5.5 0 00.5-.5V.5a.5.5 0 00-.5-.5zm-3 8.082l-1.781 1.582a1.251 1.251 0 01-1.869-.242l-.432-.647a.75.75 0 111.245-.832l.113.17a.254.254 0 00.176.109.251.251 0 00.2-.061l1.35-1.2a.75.75 0 11.995 1.122zm0-4.529l-1.781 1.582a1.25 1.25 0 01-1.869-.242l-.432-.647a.75.75 0 111.245-.832l.113.169a.249.249 0 00.374.049l1.35-1.2a.75.75 0 11.995 1.122z\"/><path d=\"M19.076 15.448a.253.253 0 01-.056-.084l-.7-1.74a1.04 1.04 0 00-1.3-.557 1.05 1.05 0 00-.62.933.25.25 0 01-.25.249H4.742a.251.251 0 01-.207-.11.248.248 0 01-.025-.239l1-2.5a.25.25 0 01.232-.157h3.7a1 1 0 000-2h-4.2a1.494 1.494 0 00-1.393.942l-1.96 4.9a.245.245 0 01-.055.084L.282 16.715a.755.755 0 00-.22.531v2.5a1.234 1.234 0 001 1.2h18.5a1.233 1.233 0 001-1.2v-2.293a1.256 1.256 0 00-.366-.884z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});