define("ember-svg-jar/inlined/e-commerce-monitor-keyboard-basket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-monitor-keyboard-basket</title><path d=\"M19.5.5h-15a1.8 1.8 0 00-2 2v9c0 1.178 1.028 2 2.5 2h5.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H7.036a1 1 0 000 2H17a1 1 0 000-2h-3.75a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H19c1.472 0 2.5-.822 2.5-2v-9a1.8 1.8 0 00-2-2zm-10.1 10a1.1 1.1 0 01-1.125-.908l-.763-3.485A.5.5 0 018 5.5h.258a.249.249 0 00.223-.138L9.6 3.146a.75.75 0 111.34.673l-.664 1.319a.25.25 0 00.224.362h3a.25.25 0 00.224-.362l-.664-1.319a.75.75 0 111.34-.673l1.116 2.216a.249.249 0 00.223.138H16a.5.5 0 01.488.607l-.763 3.482a1.1 1.1 0 01-1.125.911zM20.224 19.33a1.491 1.491 0 00-1.342-.83H5.5a1.494 1.494 0 00-1.342.829l-1 2A1.5 1.5 0 004.5 23.5h15.382a1.506 1.506 0 001.275-.711 1.488 1.488 0 00.067-1.46zM6 21a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1A.5.5 0 016 21zm3.5 0a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5zm3.5 0a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5zm3.5 0a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});