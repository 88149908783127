define("ember-svg-jar/inlined/single-woman-actions-add.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-add</title><path d=\"M10.836 13.1a.5.5 0 00-.183-.72 7.361 7.361 0 00-3.447-.88 7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h8.515a.5.5 0 00.5-.47 7.917 7.917 0 011.321-3.93zM17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm3.5 7.5h-2a.5.5 0 00-.5.5v2a1 1 0 01-2 0v-2a.5.5 0 00-.5-.5h-2a1 1 0 010-2h2a.5.5 0 00.5-.5v-2a1 1 0 012 0v2a.5.5 0 00.5.5h2a1 1 0 010 2zM1.564 10.442a1 1 0 001.383-.3 5.093 5.093 0 00.454-.9.25.25 0 01.412-.082 4.727 4.727 0 006.786 0 .251.251 0 01.228-.071.255.255 0 01.184.153 5.093 5.093 0 00.454.9 1 1 0 001.681-1.082 4.561 4.561 0 01-.646-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248a4.573 4.573 0 01-.639 2.512 1 1 0 00.297 1.382zM7.206 8.6a2.7 2.7 0 01-2.741-2.352.5.5 0 01.324-.577 5.7 5.7 0 002.245-1.439.25.25 0 01.361 0 5.719 5.719 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.206 8.6z\"/><path d=\"M1.564 10.442a1 1 0 001.383-.3 5.093 5.093 0 00.454-.9.25.25 0 01.412-.082 4.727 4.727 0 006.786 0 .251.251 0 01.228-.071.255.255 0 01.184.153 5.093 5.093 0 00.454.9 1 1 0 001.681-1.082 4.561 4.561 0 01-.646-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248a4.573 4.573 0 01-.639 2.512 1 1 0 00.297 1.382zM7.206 8.6a2.7 2.7 0 01-2.741-2.352.5.5 0 01.324-.577 5.7 5.7 0 002.245-1.439.25.25 0 01.361 0 5.719 5.719 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.206 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});