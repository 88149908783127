define("ember-svg-jar/inlined/office-shelf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-shelf</title><path d=\"M24 2.5A2.5 2.5 0 0021.5 0h-19A2.5 2.5 0 000 2.5v20A1.5 1.5 0 001.5 24H2a2.357 2.357 0 001.913-1.343c.385-.562.644-.907 1.087-.907h13.984c.445 0 .71.347 1.107.912A2.4 2.4 0 0022.016 24h.484a1.5 1.5 0 001.5-1.5zM8.5 8.748a.25.25 0 00.25-.25v-4a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v4a.25.25 0 00.25.25h2.068a.25.25 0 00.232-.342L12.786 4.6a.5.5 0 01.279-.65l1.858-.74a.5.5 0 01.65.28l1.665 4.18a.5.5 0 01-.28.649.221.221 0 00.082.426h4.71a.75.75 0 010 1.5H2.25a.75.75 0 010-1.5h1A.25.25 0 003.5 8.5v-5A.5.5 0 014 3h2.5a.5.5 0 01.5.5v5a.25.25 0 00.25.25zm-7 9.5a.75.75 0 01.75-.75h6.5a.25.25 0 00.25-.25v-3a.5.5 0 01.5-.5H11a.5.5 0 01.5.5v3a.25.25 0 00.25.25h1a.25.25 0 00.25-.25V13.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v3.75a.25.25 0 00.25.25h1a.25.25 0 00.25-.25V12.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v4.75a.25.25 0 00.25.25h1a.75.75 0 010 1.5H2.25a.75.75 0 01-.75-.752z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});