define("ember-svg-jar/inlined/messages-bubble-heart-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-heart-1-alternate</title><path d=\"M9.813 17.759a.251.251 0 00-.189-.166 12.07 12.07 0 01-1.024-.241 1 1 0 00-.661.038l-3.648 1.52a.25.25 0 01-.331-.316l.85-2.339a1 1 0 00-.274-1.089A7.04 7.04 0 012 9.928c0-4.358 4.486-7.9 10-7.9s10 3.546 10 7.9a5.961 5.961 0 01-.1 1.03.25.25 0 00.167.279 5.039 5.039 0 011.321.684.25.25 0 00.389-.146A8.1 8.1 0 0024 9.928c0-5.461-5.382-9.9-12-9.9S0 4.467 0 9.928a8.876 8.876 0 002.707 6.26l-1.6 4.4a1 1 0 001.325 1.265L8.39 19.37a14.215 14.215 0 002.188.391.25.25 0 00.214-.412 4.49 4.49 0 01-.979-1.59z\"/><path d=\"M21.039 12.522a3.487 3.487 0 00-3.058.987l-.481.482-.482-.482a3.526 3.526 0 00-4.993 4.979l5.113 5.334a.5.5 0 00.723 0l5.106-5.322a3.526 3.526 0 00-1.928-5.973z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});