define("ember-svg-jar/inlined/shrink-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shrink-horizontal</title><path d=\"M18.714 9.618a1 1 0 00-1.707-.707l-2.857 2.857a1 1 0 000 1.415l2.857 2.856a1 1 0 00.707.293.987.987 0 00.383-.076 1 1 0 00.617-.924v-1.357a.25.25 0 01.25-.25h2.012a.25.25 0 01.25.25V22a1.25 1.25 0 002.5 0V2a1.25 1.25 0 10-2.5 0v8.976a.249.249 0 01-.25.25h-2.012a.249.249 0 01-.25-.25zM6.855 16.256a.987.987 0 00.383.076 1 1 0 00.707-.293l2.855-2.856a1 1 0 000-1.415L7.945 8.911a1 1 0 00-1.707.707v1.357a.249.249 0 01-.25.25H3.024a.249.249 0 01-.25-.25V2a1.25 1.25 0 00-2.5 0v20a1.25 1.25 0 002.5 0v-8.025a.25.25 0 01.25-.25h2.964a.25.25 0 01.25.25v1.357a1 1 0 00.617.924z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});