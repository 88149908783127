define("ember-svg-jar/inlined/halloween-hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>halloween-hand</title><path d=\"M20.75 4.75A1.752 1.752 0 0019 3a1.49 1.49 0 00-.61.121.25.25 0 00-.15.229v2.52a.5.5 0 11-1 0V2.634A1.746 1.746 0 0015.5 1a1.49 1.49 0 00-.61.121.25.25 0 00-.15.229v4.02a.5.5 0 01-1 0V1.634A1.746 1.746 0 0012 0a1.49 1.49 0 00-.61.121.25.25 0 00-.15.229v4.52a.5.5 0 01-1 0V2.634a1.746 1.746 0 00-3.49.116l-.01 5.479a.5.5 0 01-1 0V5.25A.25.25 0 005.49 5H5a1.752 1.752 0 00-1.75 1.75v3c0 1.888 2.5 5.39 5.732 6.113a.5.5 0 01.391.487v.3a.5.5 0 01-.37.483A3.551 3.551 0 006.289 20.5a3.636 3.636 0 003.75 3.5 3.928 3.928 0 002.25-.7 3.928 3.928 0 002.25.7 3.636 3.636 0 003.75-3.5 3.551 3.551 0 00-2.714-3.364.5.5 0 01-.37-.483V16.5a.5.5 0 01.485-.5 5.1 5.1 0 002.528-.758 5.251 5.251 0 002.532-4.49zM13.205 18a1 1 0 001 1h.334a1.641 1.641 0 011.75 1.5 1.641 1.641 0 01-1.75 1.5 1.836 1.836 0 01-1.458-.68 1.036 1.036 0 00-1.584 0 1.836 1.836 0 01-1.458.68 1.641 1.641 0 01-1.75-1.5 1.641 1.641 0 011.75-1.5h.334a1 1 0 001-1v-1.5a.5.5 0 01.5-.5h.832a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});