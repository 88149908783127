define("ember-svg-jar/inlined/music-metronome", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-metronome</title><path d=\"M7.949 15a.251.251 0 00.2-.1l7.122-9.747a.249.249 0 00.035-.227l-1.3-3.9A1.5 1.5 0 0012.586 0H9.027a1.5 1.5 0 00-1.422 1.025L3.056 14.671a.25.25 0 00.237.329zM14.56 14.6a.25.25 0 00.2.4h3.56a.25.25 0 00.237-.329l-1.115-3.345a.253.253 0 00-.2-.168.25.25 0 00-.24.1zM22.807 2.5a2.5 2.5 0 10-4.732 1.127.249.249 0 01-.021.261L10.224 14.6a.25.25 0 00.2.4h1.858a.251.251 0 00.2-.1l7.183-9.83a.251.251 0 01.242-.1 2.461 2.461 0 00.4.032 2.5 2.5 0 002.5-2.502zM2.626 16.5a.251.251 0 00-.238.171l-1.117 3.355a1.487 1.487 0 001.313 1.962.25.25 0 01.223.249V23a1 1 0 002 0v-.75a.25.25 0 01.25-.25h11.5a.25.25 0 01.25.25V23a1 1 0 002 0v-.763a.25.25 0 01.222-.249 1.49 1.49 0 001.314-1.962l-1.118-3.354a.25.25 0 00-.237-.171z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});