define("ember-svg-jar/inlined/bathroom-robe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bathroom-robe</title><path d=\"M9.536 0a.5.5 0 00-.407.791L11.563 4.2a.5.5 0 00.817 0L14.765.787A.5.5 0 0014.356 0zM12.25 13.751a.5.5 0 00-.5-.5H7.239a.239.239 0 00-.239.238c0 .018-.867 9.371-.868 9.388A1 1 0 007.125 24h4.625a.5.5 0 00.5-.5zM6.874 5.86A.142.142 0 017 6v5.251a.5.5 0 00.5.5h4.25a.5.5 0 00.5-.5V8.462a2.239 2.239 0 00-.419-1.308L6.932.3a.5.5 0 00-.508-.2 3.99 3.99 0 00-3.1 3.067L1.13 13.4a.5.5 0 00.488.6h2.574a1 1 0 00.977-.79l1.552-7.239a.141.141 0 01.153-.111zM17 13.489a.239.239 0 00-.238-.238H14.25a.5.5 0 00-.5.5V23.5a.5.5 0 00.5.5h2.625a1 1 0 00.993-1.12c-.001-.014-.868-9.373-.868-9.391zM20.678 3.163A3.985 3.985 0 0017.445.074a.5.5 0 00-.5.206l-3.9 5.573a.371.371 0 000 .43 3.727 3.727 0 01.7 2.179v2.789a.5.5 0 00.5.5H16.5a.5.5 0 00.5-.5V6a.141.141 0 01.279-.029l1.552 7.239a1 1 0 00.977.79h2.573a.5.5 0 00.489-.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});