define("ember-svg-jar/inlined/farming-scarecrow-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>farming-scarecrow-1</title><path d=\"M7.75 4H8a.5.5 0 01.5.5V5a3.5 3.5 0 007 0v-.5A.5.5 0 0116 4h.25a1 1 0 000-2h-.921a.5.5 0 01-.472-.333A2.5 2.5 0 0012.5 0h-1a2.5 2.5 0 00-2.357 1.667.5.5 0 01-.472.333H7.75a1 1 0 000 2zm2.75.5A.5.5 0 0111 4h2a.5.5 0 01.5.5V5a1.5 1.5 0 01-3 0z\"/><path d=\"M23.6 11.2l-1.2-.9a4.024 4.024 0 00-2.4-.8h-.588a.5.5 0 01-.471-.333A1 1 0 0018 8.5h-3.05a.5.5 0 00-.493.417 2.491 2.491 0 01-4.914 0A.5.5 0 009.05 8.5H6a1 1 0 00-.943.666.5.5 0 01-.472.334H4a4.024 4.024 0 00-2.4.8l-1.2.9a1 1 0 001.2 1.6l1.2-.9a2.012 2.012 0 011.2-.4h.5a.5.5 0 01.5.5v.91a1 1 0 001.164.986l1.382-.23a.25.25 0 01.288.288L7.1 18.336a1 1 0 00.988 1.164h2.089a.5.5 0 01.5.455l.282 3.093a1.047 1.047 0 002.086 0l.281-3.092a.5.5 0 01.5-.455h2.084a1 1 0 00.986-1.164l-.73-4.383a.25.25 0 01.287-.287l1.385.23A1 1 0 0019 12.909V12a.5.5 0 01.5-.5h.5a2.012 2.012 0 011.2.4l1.2.9a1 1 0 101.2-1.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});