define("ember-svg-jar/inlined/soccer-player-ball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>soccer-player-ball</title><circle cx=\"14.625\" cy=\"3.125\" r=\"2.5\"/><circle cx=\"21.5\" cy=\"20.875\" r=\"2.5\"/><path d=\"M19.885 12.563a1.5 1.5 0 00-1.5-1.5h-.572c-1.981 0-2.245-.645-3.411-2.247a7.2 7.2 0 00-5.775-2.941A7.1 7.1 0 002.57 9.231l-.217.349A1.5 1.5 0 104.9 11.17l.217-.349A4.123 4.123 0 018.52 8.876a.25.25 0 01.224.371L6.046 14.1a1.5 1.5 0 01-1.312.771H1.5a1.5 1.5 0 000 3h3.234a4.508 4.508 0 003.393-1.543.249.249 0 01.172-.086.252.252 0 01.182.063L10.5 18.1a1.5 1.5 0 01.5 1.122v2.652a1.5 1.5 0 003 0v-2.651a4.506 4.506 0 00-1.511-3.365L9.971 13.62a.249.249 0 01-.053-.308l1.564-2.812a.251.251 0 01.409-.041c1.335 1.568 2.151 3.607 5.922 3.607h.572a1.5 1.5 0 001.5-1.503z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});