define("ember-svg-jar/inlined/multiple-actions-credit-card.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-credit-card</title><path d=\"M19.407 12a.5.5 0 00.444-.728A6.021 6.021 0 0014.489 8a5.95 5.95 0 00-2.465.534.5.5 0 00-.182.771 7.47 7.47 0 011.291 2.352.5.5 0 00.475.343z\"/><ellipse cx=\"14.013\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M8.935 14a.5.5 0 00.442-.266 3.26 3.26 0 011.8-1.544.5.5 0 00.289-.681A6.026 6.026 0 000 13.333.6.6 0 00.6 14z\"/><ellipse cx=\"5.513\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M22.25 13.5h-10a1.752 1.752 0 00-1.75 1.75v7A1.752 1.752 0 0012.25 24h10A1.752 1.752 0 0024 22.25v-7a1.752 1.752 0 00-1.75-1.75zm0 9h-10a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25h10a.25.25 0 01.25.25v3a.25.25 0 01-.25.25zm.25-7.25v1a.25.25 0 01-.25.25h-10a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h10a.25.25 0 01.25.25z\"/><path d=\"M20.25 20H19a.75.75 0 000 1.5h1.25a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});