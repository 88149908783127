define("ember-svg-jar/inlined/shipment-in-transit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-in-transit</title><path d=\"M22.87 7.1a.249.249 0 00-.025-.452c-.681-.274-2.412-.964-6.825-2.73a1.138 1.138 0 00-.9.022l-2.143 1a.25.25 0 00.005.455l6.943 3.064a.5.5 0 00.438-.017zM16.613 19.854a.25.25 0 00.362.223l6.354-3.177a1.119 1.119 0 00.618-1c0-5.458.005-7.234 0-7.844a.25.25 0 00-.369-.218L16.745 11.5a.248.248 0 00-.132.22zM7.522 8.314a.249.249 0 00-.344.229v7.406a1.12 1.12 0 00.68 1.029l7.421 3.166a.249.249 0 00.348-.23v-8.135a.249.249 0 00-.156-.232zM15.874 10.646a.5.5 0 00.424-.023L18.6 9.39a.25.25 0 00-.018-.449L11.5 5.817a.521.521 0 00-.422.009L8.313 7.117a.25.25 0 00.012.458zM5.02 13.764L1.072 11.94a.717.717 0 00-.957.374.761.761 0 00.385.991l3.9 1.8a.727.727 0 00.31.068.74.74 0 00.31-1.411zM5.02 10.311L2.684 9.232a.74.74 0 00-.621 1.343L4.4 11.654a.727.727 0 00.31.068.74.74 0 00.31-1.411zM5.02 6.859l-1.112-.514a.74.74 0 00-.62 1.343L4.4 8.2a.728.728 0 00.31.069.74.74 0 00.31-1.411z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});