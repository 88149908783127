define("ember-svg-jar/inlined/diagram-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-up</title><path d=\"M1.473 24h.11A21.2 21.2 0 0020.834 5.977a.249.249 0 01.247-.213h1.445A1.441 1.441 0 0023.544 3.3L20.663.423a1.441 1.441 0 00-2.038 0L15.744 3.3a1.441 1.441 0 001.018 2.46h.9a.25.25 0 01.246.293A18.3 18.3 0 011.365 21.122 1.441 1.441 0 001.473 24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});