define("ember-svg-jar/inlined/natural-disaster-water-level-fall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>natural-disaster-water-level-fall</title><path d=\"M23 13a2.772 2.772 0 01-2.43-1.428 1 1 0 00-.807-.565 1.023 1.023 0 00-.9.4A4.179 4.179 0 0115.833 13a4.176 4.176 0 01-3.022-1.586A1 1 0 0012 11a1.06 1.06 0 00-.811.407A4.179 4.179 0 018.167 13a4.176 4.176 0 01-3.023-1.586 1.016 1.016 0 00-.9-.41 1 1 0 00-.811.568A2.748 2.748 0 011 13a1 1 0 00-1 1v9a1 1 0 001 1h22a1 1 0 001-1v-9a1 1 0 00-1-1zm-18.721.7a.25.25 0 01.329-.015A5.692 5.692 0 008.167 15a5.734 5.734 0 003.672-1.41.249.249 0 01.321 0A5.74 5.74 0 0015.833 15a5.692 5.692 0 003.559-1.317.25.25 0 01.329.015 4.615 4.615 0 002.089 1.161.249.249 0 01.19.241v2.325a.251.251 0 01-.125.216.247.247 0 01-.249 0 2.836 2.836 0 01-1.056-1.07 1 1 0 00-.807-.565 1.019 1.019 0 00-.9.4A4.179 4.179 0 0115.833 18a4.176 4.176 0 01-3.022-1.586A1 1 0 0012 16a1.1 1.1 0 00-.811.407A4.179 4.179 0 018.167 18a4.176 4.176 0 01-3.023-1.586 1.013 1.013 0 00-.9-.41 1 1 0 00-.811.568 2.774 2.774 0 01-1.056 1.075A.25.25 0 012 17.43V15.1a.248.248 0 01.19-.242A4.615 4.615 0 004.279 13.7zM2.25 22a.25.25 0 01-.25-.25V20.1a.25.25 0 01.19-.243A4.62 4.62 0 004.279 18.7a.25.25 0 01.329-.015A5.692 5.692 0 008.167 20a5.734 5.734 0 003.672-1.41.249.249 0 01.321 0A5.74 5.74 0 0015.833 20a5.692 5.692 0 003.559-1.317.25.25 0 01.329.015 4.62 4.62 0 002.089 1.16.25.25 0 01.19.242v1.65a.25.25 0 01-.25.25zM3.649 5.45L6.4 9.119a.752.752 0 001.2 0l2.75-3.667a.75.75 0 00-.6-1.2H8.5A.25.25 0 018.25 4V1.25a1.25 1.25 0 00-2.5 0V4a.25.25 0 01-.25.25H4.25a.75.75 0 00-.6 1.2zM16.4 9.119a.752.752 0 001.2 0l2.75-3.666a.75.75 0 00-.6-1.2H18.5A.25.25 0 0118.25 4V1.25a1.25 1.25 0 00-2.5 0V4a.25.25 0 01-.25.25h-1.25a.75.75 0 00-.6 1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});