define("ember-svg-jar/inlined/camping-rv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camping-rv</title><path d=\"M21.5 2.612A2.5 2.5 0 0018.987.125l-16.5.084A2.5 2.5 0 000 2.722l.043 8.5A2.492 2.492 0 002.1 13.657a.25.25 0 01.205.245l.008 1.558a6.007 6.007 0 006.031 5.97l7.232-.037a.248.248 0 01.236.165 3.491 3.491 0 003.294 2.317h.75a.5.5 0 00.5-.5v-.25a.251.251 0 01.249-.251l2-.01a1 1 0 00-.01-2l-2 .01a.249.249 0 01-.251-.248v-.5a.25.25 0 01.249-.252l2-.01a1 1 0 00-.01-2l-2 .01a.25.25 0 01-.252-.248v-.25a.5.5 0 00-.5-.5h-.75a3.492 3.492 0 00-3.27 2.35.251.251 0 01-.234.168l-7.233.037a4 4 0 01-4.02-3.98l-.008-1.5a.251.251 0 01.249-.251h.765a.251.251 0 00.248-.279 1.812 1.812 0 01-.015-.222 4 4 0 018-.041 2.036 2.036 0 01-.012.222.249.249 0 00.063.2.255.255 0 00.187.082c.467 0-.066 0 9.214-.049a1 1 0 00-.01-2l-1.2.008a.251.251 0 01-.252-.249zm-9.985 3.051a1 1 0 01-1 1l-7 .036a1 1 0 01-1-1l-.007-1.5a1 1 0 011-1l7-.036a1 1 0 011.006 1zm7.5-.038a1 1 0 01-1 1l-2.5.013a1 1 0 01-1-1l-.008-1.5a1 1 0 011-1L18 3.13a1 1 0 011.005 1z\"/><circle cx=\"9.553\" cy=\"13.173\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});