define("ember-svg-jar/inlined/transportation-ticket-plane-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transportation-ticket-plane-alternate</title><path d=\"M22.687 9.52A1.5 1.5 0 0024 8.031V5a2 2 0 00-2-2H2a2 2 0 00-2 2v3.031A1.5 1.5 0 001.313 9.52a2.5 2.5 0 010 4.96A1.5 1.5 0 000 15.969V19a2 2 0 002 2h20a2 2 0 002-2v-3.031a1.506 1.506 0 00-1.313-1.489 2.5 2.5 0 010-4.96zm-.871 6.823a.249.249 0 01.184.241v2.166a.25.25 0 01-.25.25H2.25a.25.25 0 01-.25-.25v-2.191a.218.218 0 01.162-.211 4.5 4.5 0 00.022-8.69A.249.249 0 012 7.417V5.25A.25.25 0 012.25 5h19.5a.25.25 0 01.25.25v2.167a.249.249 0 01-.184.241 4.5 4.5 0 000 8.685z\"/><path d=\"M15.8 8.918l-2.213 1.51a.25.25 0 01-.228.028l-4.89-1.822a.332.332 0 00-.312.044l-.89.66a.273.273 0 00-.052.05.329.329 0 00.037.466l3.315 2.053a.25.25 0 01.119.205.254.254 0 01-.107.212L9.1 13.354a.251.251 0 01-.271.01l-1.341-.8a.25.25 0 00-.2-.026.28.28 0 00-.066.03.27.27 0 00-.034.025L6 13.649l-.01.008a.267.267 0 00-.037.38.273.273 0 00.047.043l2.868 2.013a.479.479 0 00.532.015l7.853-4.875c1.532-.967.024-3.333-1.453-2.315z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});