define("ember-svg-jar/inlined/read-home-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-home-1</title><path d=\"M11 13.8V4.839a.5.5 0 01.135-.341c.638-.681 2.434-2.14 6.324-2.458a.5.5 0 01.541.5v7.19a1 1 0 002 0V2a2.007 2.007 0 00-.624-1.444A2.053 2.053 0 0017.882 0C13.522.232 11.135 1.773 10 2.819 8.865 1.773 6.478.232 2.119 0A1.988 1.988 0 00.624.556 2.007 2.007 0 000 2v12.66a1.988 1.988 0 001.9 2 13.115 13.115 0 014.81 1.04 1 1 0 00.826-1.821 14.7 14.7 0 00-5.07-1.192.5.5 0 01-.465-.5l.013-11.651a.5.5 0 01.542-.5c3.885.32 5.678 1.784 6.309 2.458A.5.5 0 019 4.837V13.8a1 1 0 002 0z\"/><path d=\"M23.823 17.62l-6.5-5.5a.5.5 0 00-.647 0l-6.5 5.5a.5.5 0 00.324.882H12v5a.5.5 0 00.5.5H15a.5.5 0 00.5-.5v-2.25a1.5 1.5 0 113 0V23.5a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-5h1.5a.5.5 0 00.323-.882z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});