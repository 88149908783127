define("ember-svg-jar/inlined/e-learning-smartphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-learning-smartphone</title><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm-5 22.5a1 1 0 111-1 1 1 0 01-1 1zm6-4.5a1 1 0 01-1 1H7a1 1 0 01-1-1V3.5a1 1 0 011-1h10a1 1 0 011 1z\"/><path d=\"M16.352 8.524l-4.195-1.666a.5.5 0 00-.369 0L7.593 8.524a.5.5 0 00-.315.459v2.2a.5.5 0 101 0v-1.134a.251.251 0 01.334-.236l3.2 1.128a.5.5 0 00.332 0l4.189-1.481a.5.5 0 00.019-.936z\"/><path d=\"M14.2 11.115l-2.064.728a.5.5 0 01-.333 0l-2.065-.728a.5.5 0 00-.666.471v1.5a.5.5 0 00.334.472l1.8.634a2.3 2.3 0 001.529 0l1.8-.634a.5.5 0 00.334-.472v-1.5a.5.5 0 00-.666-.471z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});