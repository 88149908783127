define("ember-svg-jar/inlined/blazer-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>blazer-1</title><path d=\"M16.556 1.091a.992.992 0 01-.314-.179A3.535 3.535 0 0014 0h-4a3.535 3.535 0 00-2.242.912 1.011 1.011 0 01-.314.179L3.335 2.528a.5.5 0 00-.328.39l-3 18a.493.493 0 00.112.405.5.5 0 00.381.177h2.557a.5.5 0 00.49-.4l1.408-6.88a.275.275 0 01.545.055v8.708A.979.979 0 006.481 24H9a1.679 1.679 0 001.237-.626l1-1.61a.5.5 0 00.075-.264v-4.525a.5.5 0 00-.174-.38c-1.987-1.7-4.369-8.2-4.369-8.2a.571.571 0 01.03-.564l.633-.981a.5.5 0 00-.132-.681L6.47 5.6a.565.565 0 01-.177-.719 5.384 5.384 0 012.7-2.733.5.5 0 01.662.416c.524 4.542 1.835 12.428 1.85 12.517a.5.5 0 00.986 0c.015-.089 1.326-7.975 1.85-12.518a.5.5 0 01.662-.415 5.384 5.384 0 012.7 2.733.564.564 0 01-.177.719l-.825.568a.5.5 0 00-.137.683l.633.981a.571.571 0 01.03.564s-2.382 6.5-4.369 8.2a.5.5 0 00-.175.38V21.5a.5.5 0 00.076.264l1 1.61A1.679 1.679 0 0015 24h2.519a.979.979 0 00.981-1.017v-8.708a.275.275 0 01.545-.055l1.408 6.88a.5.5 0 00.49.4H23.5a.5.5 0 00.493-.582l-3-18a.5.5 0 00-.328-.39z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});