define("ember-svg-jar/inlined/lucide-map-pin-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5.43 5.43A8.06 8.06 0 004 10c0 6 8 12 8 12a29.94 29.94 0 005-5M19.18 13.52A8.66 8.66 0 0020 10a8 8 0 00-8-8 7.88 7.88 0 00-3.52.82\"/><path d=\"M9.13 9.13A2.78 2.78 0 009 10a3 3 0 003 3 2.78 2.78 0 00.87-.13M14.9 9.25a3 3 0 00-2.15-2.16M2 2l20 20\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});