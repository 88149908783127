define("ember-svg-jar/inlined/science-earth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-earth</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm8.159 15.009a.251.251 0 01-.356.251l-3.289-1.549a1 1 0 01-.561-.749 7.228 7.228 0 00-2.585-4.479A1 1 0 0113 7.708V3.32a.25.25 0 01.4-.2 16.991 16.991 0 016.759 11.889zM10.6 3.12a.253.253 0 01.262-.023.249.249 0 01.138.224v4.387a1 1 0 01-.368.775 7.228 7.228 0 00-2.585 4.479 1 1 0 01-.561.749L4.2 15.26a.249.249 0 01-.355-.251A17 17 0 0110.6 3.12zM5 17.532a.247.247 0 01-.13-.226.251.251 0 01.143-.219l3.435-1.618a1 1 0 01.85 0 6.447 6.447 0 005.406 0 1 1 0 01.85 0l3.435 1.618a.25.25 0 01.013.445 14.709 14.709 0 01-14 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});