define("ember-svg-jar/inlined/bulb-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bulb-1-alternate</title><path d=\"M14.449 17.833a.733.733 0 00.259-.047 6.98 6.98 0 004.392-6.561A7.2 7.2 0 0012 4.119a7.2 7.2 0 00-7.1 7.106 6.98 6.98 0 004.394 6.561.733.733 0 00.259.047zm-3.1-1.469a.252.252 0 01-.246-.205l-.508-2.8a.252.252 0 01.074-.227.249.249 0 01.23-.061 4.711 4.711 0 002.2 0 .249.249 0 01.3.288l-.508 2.8a.252.252 0 01-.246.205zM12 5.588a5.782 5.782 0 015.633 5.637 5.562 5.562 0 01-2.826 4.9.25.25 0 01-.365-.265l.73-4.018a.735.735 0 00-1.051-.789l-.7.349a3.2 3.2 0 01-2.848 0l-.7-.349a.735.735 0 00-1.051.789l.73 4.019a.25.25 0 01-.365.264 5.561 5.561 0 01-2.826-4.9A5.782 5.782 0 0112 5.588zM12 3.874a.98.98 0 00.98-.979V1.024a.98.98 0 00-1.96 0V2.9a.98.98 0 00.98.974zM3.469 11.71a.98.98 0 000-1.959H1.225a.98.98 0 100 1.959z\"/><path d=\"M4.94 6.036a.979.979 0 001.385-1.385l-1.87-1.87A.979.979 0 003.07 4.166zM19.552 10.731a.979.979 0 00.979.979h2.245a.98.98 0 000-1.959h-2.245a.979.979 0 00-.979.98zM18.367 6.323a.978.978 0 00.693-.287l1.87-1.87a.979.979 0 00-1.385-1.385l-1.87 1.87a.98.98 0 00.692 1.672zM15.429 19.547a.979.979 0 00-.98-.979h-4.9a.98.98 0 100 1.959h4.9a.98.98 0 00.98-.98zM15.429 22a.98.98 0 00-.98-.98h-4.9a.98.98 0 100 1.959H10.8a.249.249 0 01.245.2.98.98 0 001.918 0 .25.25 0 01.245-.2h1.245a.979.979 0 00.976-.979z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});