define("ember-svg-jar/inlined/vip-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vip-circle</title><path d=\"M17.023 9.646h-.5a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h.5a.75.75 0 000-1.5z\"/><path d=\"M12 .5A11.5 11.5 0 1023.5 12 11.513 11.513 0 0012 .5zm-2.75 10a8.3 8.3 0 01-1.65 4.95.75.75 0 01-1.2 0 8.3 8.3 0 01-1.65-4.95V9a.75.75 0 011.5 0v1.5a6.793 6.793 0 00.519 2.591.25.25 0 00.462 0A6.793 6.793 0 007.75 10.5V9a.75.75 0 011.5 0zM13 14.25a.75.75 0 010 1.5h-2a.75.75 0 010-1.5.25.25 0 00.25-.25v-4a.25.25 0 00-.25-.25.75.75 0 010-1.5h2a.75.75 0 010 1.5.25.25 0 00-.25.25v4a.25.25 0 00.25.25zm4-1.5h-.75V15a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75H17a2.25 2.25 0 010 4.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});