define("ember-svg-jar/inlined/road-sign-no-outlet-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-no-outlet-alternate</title><path d=\"M23 22H1a1 1 0 000 2h22a1 1 0 000-2zM1 2h22a1 1 0 000-2H1a1 1 0 000 2zM10.5 18.5a.75.75 0 00.75-.75v-4a.25.25 0 01.25-.25.75.75 0 000-1.5h-2a.75.75 0 000 1.5.25.25 0 01.25.25v4a.75.75 0 00.75.75zM21.75 18.5a.75.75 0 00.75-.75v-4a.25.25 0 01.25-.25.75.75 0 000-1.5h-2a.75.75 0 000 1.5.25.25 0 01.25.25v4a.75.75 0 00.75.75zM12.25 4.75a.75.75 0 00-1.5 0 .213.213 0 01-.411.079l-.139-.357a.75.75 0 00-1.45.278v5a.75.75 0 001.5 0 .213.213 0 01.411-.079l.143.357a.749.749 0 00.7.472.691.691 0 00.142-.014.749.749 0 00.608-.736zM16.25 5.75a1.75 1.75 0 00-3.5 0v3a1.75 1.75 0 003.5 0zm-1.5 3c0 .275-.5.275-.5 0v-3a.25.25 0 01.5 0zM1 13.75v3a1.75 1.75 0 003.5 0v-3a1.75 1.75 0 00-3.5 0zm2 0v3c0 .275-.5.275-.5 0v-3a.25.25 0 01.5 0z\"/><path d=\"M8 12a.75.75 0 00-.75.75v4c0 .275-.5.275-.5 0v-4a.75.75 0 00-1.5 0v4a1.75 1.75 0 003.5 0v-4A.75.75 0 008 12zM13.25 12a.75.75 0 00-.75.75v4a1.752 1.752 0 001.75 1.75h1a.75.75 0 000-1.5h-1a.25.25 0 01-.25-.25v-4a.75.75 0 00-.75-.75zM17.75 13.5h1a.75.75 0 000-1.5h-1A1.752 1.752 0 0016 13.75v3a1.752 1.752 0 001.75 1.75h1a.75.75 0 000-1.5h-1a.25.25 0 010-.5h1a.75.75 0 000-1.5h-1a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});