define("ember-svg-jar/inlined/transportation-ticket-boat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transportation-ticket-boat</title><path d=\"M10.75 11.26h3.19a.249.249 0 00.168-.434L12.543 9.4a.327.327 0 00-.238-.135H10.75a.25.25 0 00-.25.25v1.495a.249.249 0 00.25.25z\"/><path d=\"M22.906 9.271A1.252 1.252 0 0024 8.031V5a1.752 1.752 0 00-1.75-1.75H1.75A1.752 1.752 0 000 5v3.031a1.252 1.252 0 001.094 1.24 2.751 2.751 0 010 5.458A1.252 1.252 0 000 15.969V19a1.752 1.752 0 001.75 1.75h20.5A1.752 1.752 0 0024 19v-3.031a1.252 1.252 0 00-1.094-1.24 2.751 2.751 0 010-5.458zM17 18a6.52 6.52 0 01-2.88-.5.247.247 0 00-.214 0 4.924 4.924 0 01-4.3.007.252.252 0 00-.218 0A4.873 4.873 0 017.25 18a2 2 0 01-.667-3.878.251.251 0 00.167-.236V12.26a.967.967 0 011-1h1a.249.249 0 00.25-.25V9.515a.25.25 0 00-.25-.25.75.75 0 010-1.5h3.555a1.751 1.751 0 011.3.579l3.151 2.862a.206.206 0 00.14.054h.855a.5.5 0 01.424.765l-.007.013-.9 1.653a.25.25 0 00.166.364A2 2 0 0117 18z\"/><path d=\"M17 15.25a2.968 2.968 0 01-2.47-.78.749.749 0 00-1.06 0 2.294 2.294 0 01-3.44 0 .749.749 0 00-1.06 0 2.4 2.4 0 01-1.72.78.75.75 0 000 1.5 3.722 3.722 0 002.11-.664.251.251 0 01.28 0 3.687 3.687 0 004.223 0 .249.249 0 01.278 0A4.78 4.78 0 0017 16.75a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});