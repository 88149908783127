define("ember-svg-jar/inlined/video-game-logo-xbox-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-logo-xbox-1</title><path d=\"M7.065 3.1a17.232 17.232 0 014.549 1.216 1 1 0 00.771 0A17.242 17.242 0 0116.936 3.1a1 1 0 00.322-1.9 12.081 12.081 0 00-10.514 0 1 1 0 00.321 1.9zM20.939 19.479c-.491-3.932-4.627-8.344-8.335-11.163a1.007 1.007 0 00-1.209 0c-3.707 2.819-7.843 7.23-8.334 11.163a.994.994 0 00.267.814A11.853 11.853 0 0012 24a11.852 11.852 0 008.671-3.706 1 1 0 00.268-.815zM7.922 6.952a1 1 0 00-.192-1.683 14.047 14.047 0 00-4.023-1.331.992.992 0 00-.9.347A12.038 12.038 0 00.724 16.119a1 1 0 001.933-.232 13.465 13.465 0 015.265-8.935zM16.078 6.952a1 1 0 01.192-1.683 14.084 14.084 0 014.023-1.332 1 1 0 01.9.348 12.038 12.038 0 012.079 11.834 1 1 0 01-1.933-.232 13.465 13.465 0 00-5.261-8.935z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});