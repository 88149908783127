define("ember-svg-jar/inlined/love-it-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it-disable</title><path d=\"M17.5 10.25a6.5 6.5 0 106.5 6.5 6.507 6.507 0 00-6.5-6.5zm-4.42 6.5a4.425 4.425 0 014.42-4.42 4.334 4.334 0 012 .493.248.248 0 01.132.183.254.254 0 01-.07.216l-5.592 5.586a.251.251 0 01-.4-.062 4.33 4.33 0 01-.491-1.996zm4.42 4.42a4.331 4.331 0 01-2-.493.248.248 0 01-.132-.183.251.251 0 01.07-.216l5.586-5.586a.251.251 0 01.4.062 4.334 4.334 0 01.493 2A4.425 4.425 0 0117.5 21.17z\"/><path d=\"M5.813 2.812A4.578 4.578 0 019.807 4.1l1.531 1.4a1 1 0 001.348 0l1.561-1.424a4.546 4.546 0 013.965-1.256 4.5 4.5 0 013.329 2.419 4.177 4.177 0 01-.134 4.057.243.243 0 00-.022.2.248.248 0 00.129.151 7.933 7.933 0 011.24.754.25.25 0 00.366-.077 6.192 6.192 0 00.2-5.985 6.589 6.589 0 00-10.45-1.721l-.858.783-.829-.756A6.529 6.529 0 005.491.839 6.478 6.478 0 00.7 4.329a6.4 6.4 0 001.2 7.454L9 19.469a.25.25 0 00.434-.16L9.5 17.18a.248.248 0 00-.065-.18l-6.1-6.608a4.421 4.421 0 01-.851-5.16 4.5 4.5 0 013.329-2.42z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});