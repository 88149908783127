define("ember-svg-jar/inlined/power-tools-electric-saw-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>power-tools-electric-saw-1</title><path d=\"M12.029 7.366a.5.5 0 00.648.05L17.5 3.9a1.972 1.972 0 10-2.774-2.774l-3.551 4.782a.5.5 0 00.048.651zM23.4 12.77A8.7 8.7 0 0013.175 8.9a.5.5 0 00-.2.83l4.837 4.836a3.129 3.129 0 01.618.887.5.5 0 00.69.228l.308-.166a.5.5 0 01.725.333 4.749 4.749 0 01-8 4.376l-.055-.056a.5.5 0 01.124-.788l.193-.1a.5.5 0 00.117-.794L8.4 14.338a.5.5 0 00-.829.2A8.714 8.714 0 008 21.026a.5.5 0 00.3.257.482.482 0 00.154.025.5.5 0 00.237-.06l.83-.445a.5.5 0 01.638.143 6.118 6.118 0 00.588.688 6.743 6.743 0 0011.14-6.982.5.5 0 01.235-.606l1.085-.581a.5.5 0 00.194-.7z\"/><path d=\"M17.105 18.452a2.251 2.251 0 000-3.182L8.8 6.961a.5.5 0 00-.707 0L5.614 9.436a.5.5 0 000 .707l8.309 8.309a2.251 2.251 0 003.182 0zm-2.554-2.634a1.1 1.1 0 110 1.549 1.095 1.095 0 010-1.549zM7.382 5.547L6.321 4.486a.5.5 0 00-.707 0L3.139 6.961a.5.5 0 000 .707L4.2 8.729a.5.5 0 00.707 0l2.475-2.475a.5.5 0 000-.707zM5.054 3.426a.5.5 0 00-.147-.354l-.53-.53A2.25 2.25 0 001.2 5.724l.53.53a.5.5 0 00.707 0l2.47-2.475a.5.5 0 00.147-.353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});