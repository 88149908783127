define("ember-svg-jar/inlined/car-actions-shield-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-actions-shield-1</title><path d=\"M15.406 9.643a.25.25 0 00.2-.341A1.5 1.5 0 1118.5 8.75a1.462 1.462 0 01-.078.47.25.25 0 00.218.33c.611.047 1.207.126 1.771.225a.254.254 0 00.2-.055.25.25 0 00.089-.192V8a2.738 2.738 0 00-1-2.115.253.253 0 01-.091-.195.249.249 0 01.094-.193l.895-.716a1 1 0 00-1.249-1.562l-.725.58a.25.25 0 01-.405-.168l-.1-.88A3.018 3.018 0 0015.137 0h-9.32A3.011 3.011 0 002.83 2.725l-.1.889a.25.25 0 01-.405.168l-.7-.563A1 1 0 10.376 4.781l.884.707a.25.25 0 010 .389A2.738 2.738 0 00.25 8v2.25A1.752 1.752 0 002 12h7.735a.252.252 0 00.214-.12 3.025 3.025 0 011.381-1.215 16.522 16.522 0 014.076-1.022zM4 10.25a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM4.872 5a.249.249 0 01-.186-.083.252.252 0 01-.063-.194l.2-1.806a1.006 1.006 0 011-.917h9.32a1.013 1.013 0 011 .943l.2 1.78a.249.249 0 01-.26.277zM6.5 13h-4a.25.25 0 00-.25.25v.25a2.25 2.25 0 004.5 0v-.25A.25.25 0 006.5 13zM23.072 12.041A16.365 16.365 0 0017.478 11a15.986 15.986 0 00-5.55 1.04A1.544 1.544 0 0011 13.456v3.393a7.716 7.716 0 005.19 6.805l.553.211a2.068 2.068 0 001.47 0l.554-.211C21.848 22.48 24 19.682 24 16.849v-3.393a1.541 1.541 0 00-.928-1.415zM21 18h-2.25a.25.25 0 00-.25.25v2.25a1 1 0 01-2 0v-2.25a.25.25 0 00-.25-.25H14a1 1 0 010-2h2.25a.25.25 0 00.25-.25V13.5a1 1 0 012 0v2.25a.25.25 0 00.25.25H21a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});