define("ember-svg-jar/inlined/escalator-ascend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>escalator-ascend</title><path d=\"M20.5 7.5h-5.262a1.5 1.5 0 00-1.094.475l-6.908 7.367a.5.5 0 01-.364.158H3.5a3.5 3.5 0 000 7h5.762a1.5 1.5 0 001.094-.475l6.908-7.367a.5.5 0 01.364-.158H20.5a3.5 3.5 0 000-7zM3.384 10.884l4.793-4.793a.5.5 0 01.707 0l1.262 1.263A.5.5 0 0011 7V2a.5.5 0 00-.5-.5h-5a.5.5 0 00-.354.854l1.263 1.262a.5.5 0 010 .707L1.617 9.116a1.25 1.25 0 001.767 1.768z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});