define("ember-svg-jar/inlined/army-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-arrow</title><path d=\"M23.844 18.934l-3.686-3.687a.5.5 0 00-.4-.144l-3.241.295a.5.5 0 00-.453.452l-.294 3.241a.5.5 0 00.144.4l3.686 3.685a.5.5 0 00.844-.255l.459-2.293a.5.5 0 01.392-.392l2.293-.459a.5.5 0 00.255-.843zM16.22 14.306a.248.248 0 00-.067-.232l-2.565-2.565a.25.25 0 010-.354L18.2 6.54a.5.5 0 01.424-.142l1.077.154a1.023 1.023 0 001.084-.61l1.8-4.2a.761.761 0 00-1-1l-4.205 1.8a1.026 1.026 0 00-.609 1.085l.16 1.073a.5.5 0 01-.142.425l-4.615 4.616a.25.25 0 01-.354 0L7.208 5.129a.5.5 0 01-.142-.424l.154-1.077a1.022 1.022 0 00-.609-1.083l-4.2-1.8a.76.76 0 00-1 1l1.8 4.205a1.026 1.026 0 001.084.609L5.369 6.4a.5.5 0 01.424.141l4.613 4.612a.25.25 0 010 .354l-2.563 2.566a.25.25 0 00.094.413 1.448 1.448 0 01.908.906.25.25 0 00.413.094l2.562-2.563a.25.25 0 01.354 0l2.566 2.566a.251.251 0 00.233.067.248.248 0 00.18-.161 1.456 1.456 0 01.906-.909.248.248 0 00.161-.18zM7.482 15.4l-3.241-.3a.5.5 0 00-.4.144l-3.685 3.69a.5.5 0 00.255.843l2.293.459a.5.5 0 01.392.392l.459 2.293a.5.5 0 00.844.255l3.686-3.686a.5.5 0 00.144-.4l-.294-3.24a.5.5 0 00-.453-.45z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});