define("ember-svg-jar/inlined/online-reading-scribd", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>online-reading-scribd</title><circle cx=\"19.47\" cy=\"18.5\" r=\"3.5\"/><path d=\"M14.77 18.61a4.23 4.23 0 011.56-3.26.52.52 0 00.15-.56v-.13c-1.16-3.21-4.16-4.54-6.57-5.66-2.2-1-3.55-1.64-3.55-2.92S8 4.05 9.45 4.05a5 5 0 011.39.17 3 3 0 00.38 2.33 3 3 0 005.52-1.61C16.74 3 13.57 0 9.19 0 5.3 0 2.26 2.67 2.26 6.09c0 3.93 3 5.42 5.65 6.74 2.21 1.1 4.12 2 4.12 4.16a2.79 2.79 0 01-1.81 2.7 3.59 3.59 0 01-3.79-1A2.94 2.94 0 007 16.88a3 3 0 00-6 0A8.55 8.55 0 009.61 24a7.18 7.18 0 005.71-2.69.5.5 0 000-.57 4.18 4.18 0 01-.55-2.13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});