define("ember-svg-jar/inlined/layers-grid-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layers-grid-upload</title><path d=\"M8 19a.25.25 0 01-.25-.25V15a.25.25 0 01.25-.25h1.932a.094.094 0 00.088-.061 7.963 7.963 0 01.494-1.068.247.247 0 000-.248.251.251 0 00-.215-.123H8a.25.25 0 01-.25-.25V8A.25.25 0 018 7.75h4a.25.25 0 01.25.25v2.95a.25.25 0 00.4.2 7.976 7.976 0 01.97-.641.249.249 0 00.128-.218V8A.25.25 0 0114 7.75h3.75A.25.25 0 0118 8v1.294a.251.251 0 00.228.249 7.935 7.935 0 011.454.265.25.25 0 00.318-.24V3a3 3 0 00-3-3H3a3 3 0 00-3 3v15a3 3 0 003 3h6.927a.25.25 0 00.229-.349 7.889 7.889 0 01-.47-1.454.249.249 0 00-.244-.2zm-1.75-6a.25.25 0 01-.25.25H2.25A.25.25 0 012 13V8a.25.25 0 01.25-.25H6a.25.25 0 01.25.25zM8 6.25A.25.25 0 017.75 6V2.25A.25.25 0 018 2h4a.25.25 0 01.25.25V6a.25.25 0 01-.25.25zM18 3v3a.25.25 0 01-.25.25H14a.25.25 0 01-.25-.25V2.25A.25.25 0 0114 2h3a1 1 0 011 1zM3 2h3a.25.25 0 01.25.25V6a.25.25 0 01-.25.25H2.25A.25.25 0 012 6V3a1 1 0 011-1zM2 18v-3a.25.25 0 01.25-.25H6a.25.25 0 01.25.25v3.75A.25.25 0 016 19H3a1 1 0 01-1-1z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-.75 9.5v-2.753a.249.249 0 00-.245-.249c-1.827-.037-1.646-.5-1.4-.81l2-2.5a.517.517 0 01.782 0l2 2.5c.25.313.431.773-1.4.81a.249.249 0 00-.245.249V20.5a.75.75 0 01-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});