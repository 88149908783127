define("ember-svg-jar/inlined/lucide-folder-tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13 10h7a1 1 0 001-1V6a1 1 0 00-1-1h-2.5a1 1 0 01-.8-.4l-.9-1.2A1 1 0 0015 3h-2a1 1 0 00-1 1v5a1 1 0 001 1zM13 21h7a1 1 0 001-1v-3a1 1 0 00-1-1h-2.88a1 1 0 01-.9-.55l-.44-.9a1 1 0 00-.9-.55H13a1 1 0 00-1 1v5a1 1 0 001 1zM3 3v2c0 1.1.9 2 2 2h3\"/><path d=\"M3 3v13c0 1.1.9 2 2 2h3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});