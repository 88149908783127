define("ember-svg-jar/inlined/meeting-camera-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-camera-square</title><path d=\"M20.631 0H3.369A3.374 3.374 0 000 3.369V20.63A3.373 3.373 0 003.369 24h17.262A3.373 3.373 0 0024 20.63V3.369A3.374 3.374 0 0020.631 0zM17.5 14.722a.5.5 0 01-.771.419l-1.843-1.194a.25.25 0 00-.386.21V15.5a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5v-7A.5.5 0 017 8h7a.5.5 0 01.5.5v1.342a.251.251 0 00.386.21l1.843-1.194a.5.5 0 01.51-.02.5.5 0 01.261.439z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});