define("ember-svg-jar/inlined/arrow-button-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-left</title><path d=\"M16.793.249L4.322 11.233a1.066 1.066 0 000 1.539l12.471 10.979a.987.987 0 001.414-.107l1.557-1.85a1.028 1.028 0 00-.111-1.438L10.2 12.191a.25.25 0 010-.379l9.45-8.168a1.021 1.021 0 00.349-.7 1 1 0 00-.238-.741L18.207.356A.988.988 0 0017.522 0a.978.978 0 00-.729.249z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});