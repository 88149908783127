define("ember-svg-jar/inlined/app-window-move-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-move-left</title><path d=\"M0 19.251a3 3 0 003 3h18a3 3 0 003-3v-14.5a3 3 0 00-3-3H3a3 3 0 00-3 3zm10.5-16a1.035 1.035 0 011 1 1 1 0 11-2 0 1.035 1.035 0 011-1zm-3.5 0a1.035 1.035 0 011 1 1 1 0 11-2 0 1.035 1.035 0 011-1zm-3.5 0a1.035 1.035 0 011 1 1.035 1.035 0 01-1 1 1.035 1.035 0 01-1-1 1.008 1.008 0 011-1zM2 7a.25.25 0 01.25-.25h13.5A.25.25 0 0116 7v1.5a.75.75 0 001.5 0V7a.25.25 0 01.25-.25h4A.25.25 0 0122 7v12.25a1 1 0 01-1 1h-3.25a.25.25 0 01-.25-.25v-1.5a.75.75 0 00-1.5 0V20a.25.25 0 01-.25.25H3a1 1 0 01-1-1z\"/><path d=\"M16.75 11.751a.75.75 0 01.75.75v2a.75.75 0 01-1.5 0v-2a.75.75 0 01.75-.75zM8.146 10.646a.505.505 0 01.545-.109A.5.5 0 019 11v1.25a.25.25 0 00.25.25H13a1 1 0 010 2H9.25a.25.25 0 00-.25.25V16a.5.5 0 01-.309.462.5.5 0 01-.545-.108l-2.5-2.5a.5.5 0 010-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});