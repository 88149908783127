define("ember-svg-jar/inlined/boxing-bag-hanging", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>boxing-bag-hanging</title><path d=\"M7 21.25a.5.5 0 00-.5.5v.75A1.5 1.5 0 008 24h8a1.5 1.5 0 001.5-1.5v-.75a.5.5 0 00-.5-.5zM16 6.5h-.132L13.618 2H14a1 1 0 000-2h-4a1 1 0 000 2h.382l-2.25 4.5H8A1.5 1.5 0 006.5 8v.75a.5.5 0 00.5.5h10a.5.5 0 00.5-.5V8A1.5 1.5 0 0016 6.5zm-5.632 0L12 3.236 13.632 6.5z\"/><rect x=\"6.5\" y=\"10.75\" width=\"11\" height=\"9\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});