define("ember-svg-jar/inlined/single-woman-podium.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-podium</title><path d=\"M6.074 14.027a.276.276 0 00.21.1h12.432a.276.276 0 00.21-.1A.314.314 0 0019 13.8a6.4 6.4 0 00-.549-2.107c-.4-.846-2.068-1.432-4.163-2.254a.6.6 0 01-.37-.563V8.56a.25.25 0 01.244-.25 4.293 4.293 0 002.67-.68.3.3 0 00.132-.213.317.317 0 00-.071-.244c-.357-.414-.6-.914-.709-2.559C16 1.663 14.115.625 12.5.625S9.006 1.663 8.817 4.614c-.105 1.645-.352 2.145-.709 2.559a.316.316 0 00-.071.243.3.3 0 00.132.214 4.311 4.311 0 002.662.7.249.249 0 01.252.25v.3a.6.6 0 01-.37.563c-2.095.822-3.764 1.408-4.163 2.254A6.4 6.4 0 006 13.8a.309.309 0 00.074.227zM23 15.875H1a.75.75 0 000 1.5h1.219a.25.25 0 01.243.189l1.31 5.243a.75.75 0 00.728.568h15a.75.75 0 00.728-.568l1.31-5.243a.25.25 0 01.243-.189H23a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});