define("ember-svg-jar/inlined/smiley-wrong", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-wrong</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM6.5 6.167a1 1 0 012 0v1.25a.25.25 0 00.25.25H10a1 1 0 010 2H8.75a.25.25 0 00-.25.25v1.25a1 1 0 11-2 0v-1.25a.25.25 0 00-.25-.25H5a1 1 0 010-2h1.25a.25.25 0 00.25-.25zm-.319 9.927c2.29-3.272 4.729-1.772 6.343-.779 1.817 1.119 2.5 1.423 3.657-.221a1 1 0 011.638 1.146 3.857 3.857 0 01-3.158 2 6.389 6.389 0 01-3.185-1.221C9.66 15.9 8.973 15.6 7.819 17.24a1 1 0 01-1.638-1.146zM17.75 9.667a.25.25 0 00-.25.25v1.25a1 1 0 11-2 0v-1.25a.25.25 0 00-.25-.25H14a1 1 0 010-2h1.25a.25.25 0 00.25-.25v-1.25a1 1 0 012 0v1.25a.25.25 0 00.25.25H19a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});