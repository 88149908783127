define("ember-svg-jar/inlined/gesture-two-fingers-horizontal-zoom-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-two-fingers-horizontal-zoom-in</title><path d=\"M14.7 12.073a.251.251 0 01-.14-.293l1.24-4.657a.929.929 0 00-1.8-.454l-1.43 4.592a.251.251 0 01-.3.168l-.584-.143a.25.25 0 01-.19-.243V6.485a1 1 0 00-2 0v7.063a.25.25 0 01-.456.143c-1.489-2.138-3.855-1.449-2.761.224L9.2 18.282a.5.5 0 00.416.223h5.435a.5.5 0 00.492-.412c.2-1.1.664-3.683.775-4.165.248-1.093-.666-1.428-1.618-1.855zM2.414 7.542l-2 2.5a.752.752 0 000 .938l2 2.5a.751.751 0 001.336-.469v-5a.751.751 0 00-1.336-.469zM23.586 10.042l-2-2.5a.751.751 0 00-1.336.469v5a.751.751 0 001.336.469l2-2.5a.752.752 0 000-.938z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});