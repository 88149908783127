define("ember-svg-jar/inlined/password-desktop-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>password-desktop-lock</title><path d=\"M14.35 20.681a.5.5 0 00-.385-.181h-3.93a.5.5 0 00-.385.181l-2.1 2.532a.5.5 0 00.41.787h8.079a.5.5 0 00.41-.787zM22.5 4.4h-.25V3a2.75 2.75 0 00-5.5 0v1.4h-.25a1 1 0 00-1 1v4.5a1 1 0 001 1h6a1 1 0 001-1V5.4a1 1 0 00-1-1zm-3 3.75a1 1 0 111-1 1 1 0 01-1 1zM18.25 4.4V3a1.25 1.25 0 012.5 0v1.4z\"/><path d=\"M23 12.5a1 1 0 00-1 1v1a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5H14a1 1 0 000-2H2a2 2 0 00-2 2v15.045A2.571 2.571 0 002.54 19.5h18.92A2.571 2.571 0 0024 17.045V13.5a1 1 0 00-1-1z\"/><path d=\"M14.062 5.525a.75.75 0 00-.75-.75h-8a1.753 1.753 0 00-1.75 1.75v3.5a1.753 1.753 0 001.75 1.75h8a.75.75 0 000-1.5h-8a.251.251 0 01-.25-.25v-3.5a.251.251 0 01.25-.25h8a.75.75 0 00.75-.75z\"/><circle cx=\"7.563\" cy=\"8.275\" r=\"1\"/><circle cx=\"11.063\" cy=\"8.275\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});