define("ember-svg-jar/inlined/file-exe-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-exe-1</title><path d=\"M23.707 5.708L18.293.294A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.751V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.415a1 1 0 00-.293-.707z\"/><path d=\"M20 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h18a1 1 0 001-1zM4.375 13.876H5.5a.625.625 0 010 1.25H4.375a.25.25 0 00-.25.25V16.5a.375.375 0 00.375.375h2a.625.625 0 010 1.25h-2A1.626 1.626 0 012.875 16.5v-4A1.625 1.625 0 014.5 10.876h2a.625.625 0 010 1.25h-2a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25zm7.684 3.346a.625.625 0 11-1.118.558l-.854-1.709a.094.094 0 00-.175 0l-.853 1.709a.625.625 0 01-.559.346.625.625 0 01-.559-.9l1.305-2.609a.252.252 0 000-.224L7.941 11.78a.625.625 0 111.118-.558s.806 1.616.84 1.681a.122.122 0 00.2.006l.843-1.687a.625.625 0 111.118.558l-1.3 2.609a.252.252 0 000 .224zm2.316-3.346H15.5a.625.625 0 010 1.25h-1.125a.25.25 0 00-.25.25V16.5a.375.375 0 00.375.375h2a.625.625 0 010 1.25h-2a1.626 1.626 0 01-1.625-1.625v-4a1.625 1.625 0 011.625-1.624h2a.625.625 0 010 1.25h-2a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});