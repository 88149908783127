define("ember-svg-jar/inlined/soccer-goal-net", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>soccer-goal-net</title><path d=\"M22 3.808H2a2 2 0 00-2 2v12.381a2.016 2.016 0 00.949 1.7 1.99 1.99 0 001.947.087l2.124-1.062a.994.994 0 01.448-.106h13.06a.993.993 0 01.447.106l2.13 1.065A2 2 0 0024 18.19V5.808a2 2 0 00-2-2zM4 16.57a1 1 0 01-.552.894l-1.086.544A.248.248 0 012.119 18 .25.25 0 012 17.784V7.826a.25.25 0 01.427-.177l1.28 1.28A1 1 0 014 9.636zm4.5-.262a.5.5 0 01-.5.5H6.5a.5.5 0 01-.5-.5v-1.5a.5.5 0 01.5-.5H8a.5.5 0 01.5.5zm0-4.5a.5.5 0 01-.5.5H6.5a.5.5 0 01-.5-.5v-1.5a.5.5 0 01.5-.5H8a.5.5 0 01.5.5zm5 4.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm0-4.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm4.5 4.5a.5.5 0 01-.5.5H16a.5.5 0 01-.5-.5v-1.5a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5zm0-4.5a.5.5 0 01-.5.5H16a.5.5 0 01-.5-.5v-1.5a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5zm.879-4.293a1 1 0 01-.707.293H5.828a1 1 0 01-.707-.293l-1.28-1.28a.25.25 0 01.176-.427h15.967a.25.25 0 01.177.426zM22 17.785a.25.25 0 01-.362.224l-1.085-.543a1 1 0 01-.553-.894V9.636a1 1 0 01.293-.707l1.28-1.28a.25.25 0 01.427.177z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});