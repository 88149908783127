define("ember-svg-jar/inlined/calendar-edit-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>calendar-edit-1</title><path d=\"M2.5 16.5A.5.5 0 012 16V7.5a.5.5 0 01.5-.5H16a.5.5 0 01.5.5v4.776a.25.25 0 00.427.176l1.5-1.5a.249.249 0 00.074-.176V3a1 1 0 00-1-1H15a.25.25 0 01-.25-.25v-1a.75.75 0 10-1.5 0V4.5a.75.75 0 11-1.5 0v-2a.5.5 0 00-.5-.5H7a.25.25 0 01-.25-.25v-1a.75.75 0 00-1.5 0V4.5a.75.75 0 11-1.5 0v-2a.5.5 0 00-.5-.5H1a1 1 0 00-1 1v13.5a2 2 0 002 2h8.775a.251.251 0 00.177-.073l1.5-1.5a.25.25 0 00-.177-.427zM11.639 23.982l3.1-.886a.5.5 0 00.217-.834l-2.215-2.215a.5.5 0 00-.834.216l-.886 3.1a.5.5 0 00.618.618z\"/><rect x=\"13.371\" y=\"14.967\" width=\"8.086\" height=\"5.242\" rx=\".5\" ry=\".5\" transform=\"rotate(-44.999 17.414 17.59)\"/><path d=\"M23.234 15.476a2.622 2.622 0 10-3.754-3.66.5.5 0 000 .707l3 3a.564.564 0 00.754-.047z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});