define("ember-svg-jar/inlined/christmas-tree-ornaments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-tree-ornaments</title><path d=\"M20.738 7.7a.5.5 0 01-.278-.3 2 2 0 00-3.812 0 .5.5 0 01-.278.307 5.524 5.524 0 00-3.222 4.026.25.25 0 00.462.172 2.125 2.125 0 011.831-1.314c1.093 0 1.6.893 1.962 1.546.265.471.454.78.654.78s.39-.309.655-.781c.366-.652.869-1.545 1.962-1.545s1.6.892 1.963 1.545c.266.472.455.781.657.781.067 0 .178 0 .422-.38a.72.72 0 01.123-.145.5.5 0 00.161-.446A5.517 5.517 0 0020.738 7.7z\"/><path d=\"M23.427 14.408c-1.061.075-1.594-.649-2.1-1.54-.265-.472-.455-.781-.656-.781s-.389.309-.655.781c-.366.652-.869 1.545-1.962 1.545s-1.595-.893-1.961-1.545c-.266-.472-.454-.781-.655-.781s-.389.309-.655.781a3.257 3.257 0 01-1.11 1.332.5.5 0 00-.215.623 5.5 5.5 0 0010.22-.069.251.251 0 00-.251-.341z\"/><circle cx=\"6.434\" cy=\"7\" r=\"1\"/><circle cx=\"6.434\" cy=\"4\" r=\"1\"/><circle cx=\"6.434\" cy=\"1\" r=\"1\"/><circle cx=\"18.554\" cy=\"1\" r=\"1\"/><circle cx=\"18.554\" cy=\"4\" r=\"1\"/><path d=\"M8.723 11.417A.447.447 0 018.434 11a2 2 0 10-4 0 .446.446 0 01-.288.417 6.516 6.516 0 00-4.141 5.119.5.5 0 00.06.321c.252.445.514.957.763.957.21 0 .4-.317.675-.8.372-.66.88-1.565 1.984-1.565s1.612.9 1.983 1.566c.273.482.466.8.676.8s.4-.317.677-.8c.371-.66.879-1.565 1.983-1.565s1.613.9 1.985 1.565c.281.5.467.8.679.8s.4-.3.679-.8a6.7 6.7 0 01.477-.761.5.5 0 00.087-.435 6.525 6.525 0 00-3.99-4.402z\"/><path d=\"M12.291 19.114a1.7 1.7 0 01-.821.2c-1.1 0-1.613-.9-1.985-1.564-.281-.5-.467-.8-.679-.8s-.4.317-.677.8c-.371.661-.88 1.565-1.983 1.565s-1.611-.9-1.983-1.564c-.272-.484-.465-.8-.676-.8s-.4.317-.676.8c-.371.66-.879 1.565-1.983 1.565a1.878 1.878 0 01-.265-.016.25.25 0 00-.272.33 6.5 6.5 0 0012.357-.217.25.25 0 00-.357-.294z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});