define("ember-svg-jar/inlined/job-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>job-search</title><path d=\"M23.645 22.292l-4.1-4.1a.25.25 0 01-.019-.332q.313-.391.592-.814a1 1 0 00-1.67-1.1 8.991 8.991 0 01-15.025 0 1 1 0 00-1.67 1.1A10.992 10.992 0 0017.8 19.593a.251.251 0 01.333.019l4.095 4.1a1 1 0 001.415-1.415zM13.8 6.775h-1.434a.875.875 0 00-.875.875v5.715a.875.875 0 00.875.875H13.8a2.3 2.3 0 001.9-3.592.252.252 0 010-.281 2.284 2.284 0 00.4-1.288 2.307 2.307 0 00-2.3-2.304zm-.554 2a.25.25 0 01.25-.25h.3a.554.554 0 110 1.107h-.3a.25.25 0 01-.25-.25zm.554 3.715h-.3a.25.25 0 01-.25-.25v-.608a.25.25 0 01.25-.25h.3a.554.554 0 010 1.108zM5.776 9.079v2.857a2.3 2.3 0 104.608 0V9.079a2.3 2.3 0 00-4.608 0zm2.858 0v2.857a.554.554 0 11-1.108 0V9.079a.554.554 0 111.108 0zM4.67 11.936V7.65a.875.875 0 00-1.75 0v4.286a.554.554 0 11-1.108 0 .875.875 0 10-1.75 0 2.3 2.3 0 104.608 0zM17.2 9.079a2.306 2.306 0 002.3 2.3.554.554 0 010 1.108h-1.42a.875.875 0 000 1.75h1.429a2.3 2.3 0 000-4.608.554.554 0 110-1.107h1.429a.875.875 0 000-1.75h-1.429A2.307 2.307 0 0017.2 9.079zM2.438 5.654a1 1 0 001.4-.175 8.977 8.977 0 0114.193 0 1 1 0 101.578-1.228 10.976 10.976 0 00-17.349 0 1 1 0 00.178 1.403z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});