define("ember-svg-jar/inlined/pregnancy-sperm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pregnancy-sperm</title><path d=\"M23.4.039a.5.5 0 01.5.5v4.75h-.5a4.754 4.754 0 01-4.75-4.75v-.5z\"/><path d=\"M23.4 6.789a.5.5 0 01.5.5v4.25a.5.5 0 01-.5.5A11.514 11.514 0 0111.9.539a.5.5 0 01.5-.5h4.25a.5.5 0 01.5.5 6.252 6.252 0 006.25 6.25zM11.231 13.392a.5.5 0 01.325 0 1.91 1.91 0 00.612.1 2.764 2.764 0 001.913-.848 2.863 2.863 0 00.831-1.593 1.845 1.845 0 00-.477-1.589 2.3 2.3 0 00-3.182.354 2.863 2.863 0 00-.831 1.593.251.251 0 01-.166.2 4.2 4.2 0 00-3.274 4.113 1.752 1.752 0 01-.545 1.339 3.4 3.4 0 01-2.268.563 1 1 0 10-.219 1.988 5.045 5.045 0 00.539.026 5.05 5.05 0 003.253-1.062 3.736 3.736 0 001.239-2.788c.052-1.543.843-1.922 2.139-2.361zM4.692 10.715a.5.5 0 01.327 0 1.921 1.921 0 00.621.1 2.213 2.213 0 00.312-.022 2.86 2.86 0 001.593-.831 2.86 2.86 0 00.831-1.593A1.842 1.842 0 007.9 6.783a2.3 2.3 0 00-3.182.354 2.867 2.867 0 00-.83 1.594.251.251 0 01-.165.2 4.2 4.2 0 00-3.277 4.116 2.1 2.1 0 01-.223.937 1 1 0 101.757.954 4.06 4.06 0 00.465-1.825c.051-1.543.842-1.922 2.14-2.361zM14.217 17.279a2.864 2.864 0 00-.83 1.593v.012a.187.187 0 01-.135.161C12 19.406 9.989 20.129 9.828 22.9a1 1 0 00.94 1.056h.059a1 1 0 001-.942c.084-1.451.891-1.748 2.2-2.116l.189-.054a.5.5 0 01.3.008 1.9 1.9 0 00.623.1 2.373 2.373 0 00.312-.022 3 3 0 002.424-2.425 1.838 1.838 0 00-.477-1.588 2.3 2.3 0 00-3.181.362z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});