define("ember-svg-jar/inlined/asian-interior-lamp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>asian-interior-lamp</title><path d=\"M7.5 4h9a.5.5 0 00.5-.5A1.5 1.5 0 0015.5 2h-2.25a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v.75a.25.25 0 01-.25.25H8.5A1.5 1.5 0 007 3.5a.5.5 0 00.5.5zM16.5 20.5h-9a.5.5 0 00-.5.5v.5a.5.5 0 00.334.472.248.248 0 01.166.235v1.043a.75.75 0 001.5 0v-1a.25.25 0 01.25-.25H11a.25.25 0 01.25.25v1a.75.75 0 001.5 0v-1A.25.25 0 0113 22h1.75a.25.25 0 01.25.25v1a.75.75 0 001.5 0v-1.043a.249.249 0 01.167-.235A.5.5 0 0017 21.5V21a.5.5 0 00-.5-.5zM16.486 5.5H13.25a.5.5 0 00-.5.5v2.25a.5.5 0 00.5.5h5.235a.5.5 0 00.433-.75 7.982 7.982 0 00-2.132-2.4.5.5 0 00-.3-.1zM19.885 10.665a.5.5 0 00-.493-.415H13.25a.5.5 0 00-.5.5v2.5a.5.5 0 00.5.5h6.123a.5.5 0 00.492-.407A7.212 7.212 0 0020 12a7.879 7.879 0 00-.115-1.335zM11.25 13.25v-2.5a.5.5 0 00-.5-.5H4.608a.5.5 0 00-.493.415A7.983 7.983 0 004 12a7.213 7.213 0 00.136 1.343.5.5 0 00.491.407h6.123a.5.5 0 00.5-.5zM9.325 19.5h1.425a.5.5 0 00.5-.5v-3.25a.5.5 0 00-.5-.5H5.536A.5.5 0 005.1 16a8 8 0 004.043 3.466.5.5 0 00.182.034zM18.465 15.25H13.25a.5.5 0 00-.5.5V19a.5.5 0 00.5.5h1.425a.5.5 0 00.179-.033A7.989 7.989 0 0018.9 16a.5.5 0 00-.432-.751zM5.516 8.75h5.234a.5.5 0 00.5-.5V6a.5.5 0 00-.5-.5H7.514a.5.5 0 00-.3.1A7.977 7.977 0 005.083 8a.5.5 0 00.433.75zM23 9.5h-1.5a1 1 0 000 2H23a1 1 0 000-2zM21 7a1 1 0 00.707-.293l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5A1 1 0 0021 7zM21.707 14.293a1 1 0 00-1.414 1.414l1.5 1.5a1 1 0 001.414-1.414zM2.5 11.5a1 1 0 000-2H1a1 1 0 000 2zM2.207 3.793A1 1 0 00.793 5.207l1.5 1.5a1 1 0 001.414-1.414zM2.293 14.293l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5a1 1 0 00-1.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});