define("ember-svg-jar/inlined/messages-people-woman-bubble-circle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-woman-bubble-circle-1</title><path d=\"M17.245 0A6.911 6.911 0 0011.7 2.708 1 1 0 0013.3 3.9 4.9 4.9 0 0117.245 2 4.555 4.555 0 0122 6.311a4.319 4.319 0 01-2.9 3.969 1 1 0 00-.32.2c-.166.154-1.593 1.4-1.78 1.564v-.476a1 1 0 00-.86-1 6.353 6.353 0 01-.768-.145 1 1 0 10-.547 1.924c.054.015.112.031.174.045v1.884a1 1 0 001.707.707c.25-.25 3.056-2.7 3.288-2.91a6.283 6.283 0 004-5.764A6.552 6.552 0 0017.245 0z\"/><circle cx=\"13.5\" cy=\"6.499\" r=\"1\"/><circle cx=\"16.75\" cy=\"6.499\" r=\"1\"/><circle cx=\"20\" cy=\"6.499\" r=\"1\"/><path d=\"M1.564 16.943a1 1 0 001.383-.3 5.649 5.649 0 00.594-1.283 4.733 4.733 0 007.33 0 5.649 5.649 0 00.594 1.283 1 1 0 101.68-1.084 4.563 4.563 0 01-.642-2.51V11.8a5.3 5.3 0 10-10.594 0v1.25a4.567 4.567 0 01-.642 2.511 1 1 0 00.297 1.382zM7.206 15.1c-2.629 0-2.793-2.7-2.814-2.8a5.711 5.711 0 002.823-1.758 5.709 5.709 0 002.806 1.757C10 12.394 9.834 15.1 7.206 15.1zM7.206 18a7.543 7.543 0 00-7.185 5.358A.5.5 0 00.5 24h13.413a.5.5 0 00.479-.642A7.545 7.545 0 007.206 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});