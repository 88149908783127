define("ember-svg-jar/inlined/vintage-car-8", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-car-8</title><path d=\"M21.851 11.241a3.284 3.284 0 00-.62-.369.252.252 0 01-.144-.227v-.27a.25.25 0 01.25-.25h.163a1.5 1.5 0 000-3h-.25a.25.25 0 01-.25-.25v-.75a5.006 5.006 0 00-5-5H8a5.006 5.006 0 00-5 5v.75a.25.25 0 01-.25.25H2.5a1.5 1.5 0 000 3h.337a.25.25 0 01.25.25v.184a.25.25 0 01-.154.231 3.356 3.356 0 00-.784.451 4.914 4.914 0 00-1.28 5.3 3.652 3.652 0 002.449 2.439.25.25 0 01.182.24v2.655a1 1 0 001 1h2a1 1 0 001-1v-2.5a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v2.5a1 1 0 001 1h2a1 1 0 001-1V19.22a.248.248 0 01.182-.24 3.652 3.652 0 002.449-2.438 4.914 4.914 0 00-1.28-5.301zM4.5 15.125a1.25 1.25 0 111.25-1.25 1.25 1.25 0 01-1.25 1.25zm11.5 1.75a.25.25 0 01-.25.25h-7.5a.25.25 0 01-.25-.25v-3.787a.251.251 0 01.123-.215l3.368-1.989a1 1 0 011.018 0l3.367 1.988a.251.251 0 01.123.215zM6.459 8.508a6.589 6.589 0 00-1.132.252.252.252 0 01-.227-.036.249.249 0 01-.1-.2v-2.4a3 3 0 013-3h8a3 3 0 013 3v2.4a.249.249 0 01-.1.2.252.252 0 01-.224.036 6.589 6.589 0 00-1.132-.252 40.681 40.681 0 00-11.085 0zM19.5 15.125a1.25 1.25 0 111.25-1.25 1.25 1.25 0 01-1.25 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});