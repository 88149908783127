define("ember-svg-jar/inlined/engagement-couple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>engagement-couple</title><path d=\"M11.36 5.709a1 1 0 001.24-.674A2.5 2.5 0 1115 8.25a1 1 0 000 2 4.5 4.5 0 001.683-8.673.25.25 0 01-.15-.285A1.065 1.065 0 0015.492 0h-.985a1.065 1.065 0 00-1.039 1.3.249.249 0 01-.15.285 4.522 4.522 0 00-2.631 2.884 1 1 0 00.673 1.24z\"/><path d=\"M13.64 5.791a1.007 1.007 0 00-1.244.674A2.5 2.5 0 1110 3.25a1 1 0 000-2 4.5 4.5 0 104.313 5.785 1 1 0 00-.673-1.244zM8.366 18.381a.251.251 0 01-.033-.425 4.4 4.4 0 10-5.167 0 .25.25 0 01-.032.425A5.754 5.754 0 000 23.5a.5.5 0 00.5.5H11a.5.5 0 00.5-.5 5.754 5.754 0 00-3.134-5.119zM5.75 16.8a2.4 2.4 0 01-2.276-3.155.25.25 0 01.372-.132 5.643 5.643 0 004 .8.253.253 0 01.211.061.25.25 0 01.081.2A2.4 2.4 0 015.75 16.8zM14.5 14.233a3.068 3.068 0 01-.7 2.352 1 1 0 101.4 1.422 4.025 4.025 0 00.418-.487.25.25 0 01.173-.1.256.256 0 01.193.059 4.24 4.24 0 005.526 0 .256.256 0 01.193-.059.25.25 0 01.173.1 3.938 3.938 0 00.418.487 1 1 0 101.406-1.422 3.068 3.068 0 01-.7-2.352 4.25 4.25 0 00-8.5 0zm5.368-.253a3.409 3.409 0 00.909.28.25.25 0 01.2.278 2.249 2.249 0 01-4.462 0 .25.25 0 01.2-.278 3.409 3.409 0 00.909-.28 2.471 2.471 0 00.916-.74.251.251 0 01.4 0 2.471 2.471 0 00.928.74zM21.478 19.017a.5.5 0 00-.478-.025 4.472 4.472 0 00-2.034 1.985.251.251 0 01-.434 0 4.472 4.472 0 00-2.032-1.985.5.5 0 00-.477.025A5.251 5.251 0 0013.5 23.5a.5.5 0 00.5.5h9.5a.5.5 0 00.5-.5 5.251 5.251 0 00-2.522-4.483z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});