define("ember-svg-jar/inlined/ticket-pass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ticket-pass</title><path d=\"M11 15.5H7.5a.5.5 0 00-.5.5v5.207a.5.5 0 00.741.438A6.8 6.8 0 0011.5 16a.5.5 0 00-.5-.5zM16.5 15.5H13a.5.5 0 00-.5.5 6.8 6.8 0 003.759 5.645.5.5 0 00.741-.438V16a.5.5 0 00-.5-.5zM2.75 12A2.75 2.75 0 000 14.75v8.75a.5.5 0 00.5.5H5a.5.5 0 00.5-.5v-8.75A2.75 2.75 0 002.75 12zm.75 3.75H2a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zM19.5 8V1a1 1 0 00-1-1h-13a1 1 0 00-1 1v7a1 1 0 001 1h13a1 1 0 001-1zM12 6.5a1.75 1.75 0 111.75-1.75A1.75 1.75 0 0112 6.5zM21.25 12a2.75 2.75 0 00-2.75 2.75v8.75a.5.5 0 00.5.5h4.5a.5.5 0 00.5-.5v-8.75A2.75 2.75 0 0021.25 12zm.75 3.75h-1.5a.75.75 0 010-1.5H22a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});