define("ember-svg-jar/inlined/real-estate-update-building-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-update-building-refresh</title><path d=\"M13.5 18.99H15a.5.5 0 00.5-.5v-7.5a.5.5 0 00-.276-.447l-3-1.5a.5.5 0 00-.448 0l-3 1.5a.5.5 0 00-.276.447v7.5a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5zm-3-7.5h3a.5.5 0 010 1h-3a.5.5 0 010-1zm0 3.5a.5.5 0 010-1h3a.5.5 0 010 1z\"/><path d=\"M20.7 17.787a1 1 0 00-1.386.282A8.747 8.747 0 117.409 5.82a.5.5 0 01.762.386l.085 1.1a.75.75 0 001.244.508l4-3.5a.749.749 0 00-.078-1.188l-4.5-3a.749.749 0 00-1.166.682l.164 2.138a.5.5 0 01-.294.494 10.748 10.748 0 1013.355 15.733 1 1 0 00-.281-1.386zM21.811 12.973a.987.987 0 00-1.068.926 8.541 8.541 0 01-.209 1.373 1 1 0 00.743 1.205 1.076 1.076 0 00.231.026 1 1 0 00.972-.77 10.671 10.671 0 00.258-1.693 1 1 0 00-.927-1.067zM20.151 10.017a1 1 0 001.86-.739 10.5 10.5 0 00-.761-1.536 1 1 0 00-1.713 1.031 8.431 8.431 0 01.614 1.244zM15.608 5.266a8.413 8.413 0 011.218.666 1 1 0 001.1-1.672 10.649 10.649 0 00-1.5-.823 1 1 0 00-.811 1.829z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});