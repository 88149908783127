define("ember-svg-jar/inlined/floppy-disk-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>floppy-disk-1</title><path d=\"M22 0H4.414A1.986 1.986 0 003 .587L.586 3A2.009 2.009 0 000 4.415V22a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zM4.75 6a.249.249 0 01-.25-.25v-3.5A.25.25 0 014.75 2h14.5a.251.251 0 01.177.073.255.255 0 01.073.177v3.5a.249.249 0 01-.25.25zm10.75 6.5A3.5 3.5 0 1112 9a3.5 3.5 0 013.5 3.5zm-11 7.75a.25.25 0 01.25-.25h14.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H4.75a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});