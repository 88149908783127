define("ember-svg-jar/inlined/single-neutral-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions</title><path d=\"M19.752 16.454c-.539-1.076-2.307-1.725-4.754-2.626l-.628-.23a.6.6 0 01-.138-.311 2.573 2.573 0 01.058-1.3 5.618 5.618 0 001.466-4.314C15.756 5.256 14.177 3.5 12 3.5S8.244 5.256 8.244 7.674a5.605 5.605 0 001.451 4.3 2.453 2.453 0 01.073 1.316.615.615 0 01-.135.309L9 13.828c-2.447.9-4.215 1.55-4.754 2.626A9.173 9.173 0 003.5 20a.5.5 0 00.5.5h16a.5.5 0 00.5-.5 9.173 9.173 0 00-.748-3.546z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});