define("ember-svg-jar/inlined/ice-cream-stick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ice-cream-stick</title><path d=\"M14.308 0H9.692A4.36 4.36 0 005.5 4.5v12A1.465 1.465 0 006.923 18h10.154a1.465 1.465 0 001.423-1.5v-12A4.36 4.36 0 0014.308 0zM10.25 14.5a.75.75 0 01-1.5 0v-11a.75.75 0 011.5 0zm5 0a.75.75 0 01-1.5 0v-11a.75.75 0 011.5 0zM12 24a1.252 1.252 0 01-1.25-1.25v-3a.751.751 0 01.75-.75h1a.751.751 0 01.75.75v3A1.252 1.252 0 0112 24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});