define("ember-svg-jar/inlined/video-file-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-information</title><path d=\"M23.415 3L21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.275a.247.247 0 00.242.25c.366.006 1.037.034 1.485.083A.246.246 0 008 9.36V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.244.244 0 00-.241.21 7.956 7.956 0 01-.364 1.458.244.244 0 00.229.331H22a2 2 0 002-2V4.414A2 2 0 0023.415 3z\"/><path d=\"M19.5 10a.751.751 0 00-.4-.655l-5.846-3.236a.886.886 0 00-.834-.009.755.755 0 00-.42.665V11.6a.241.241 0 00.073.173 8.013 8.013 0 011.359 1.737.245.245 0 00.331.1l5.337-2.955a.751.751 0 00.4-.655zM6.5 11a6.5 6.5 0 106.5 6.5A6.508 6.508 0 006.5 11zM6 13.249a1 1 0 11-1 1 1 1 0 011-1zm2 8.5H5a.75.75 0 010-1.5h.627a.123.123 0 00.123-.123v-2.251a.125.125 0 00-.125-.125H5a.75.75 0 010-1.5h1a1.252 1.252 0 011.25 1.25v2.623a.127.127 0 00.126.127H8a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});