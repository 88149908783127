define("ember-svg-jar/inlined/multiple-man-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-man-2</title><path d=\"M22.516 17.015l-2.868-1.275a.249.249 0 01-.148-.228v-1.143a.247.247 0 01.074-.177 3.305 3.305 0 00.779-2.23.251.251 0 01.088-.176 1.875 1.875 0 00.538-1.4 1.575 1.575 0 00-.317-1.014.25.25 0 01-.034-.265 2.53 2.53 0 00.156-1.727c-.353-1.439-2.2-1.972-3.729-1.861h-.036a5.516 5.516 0 00-1.974.217.254.254 0 00-.171.185 7.558 7.558 0 01-.1.412.247.247 0 00.017.171A3.439 3.439 0 0115.12 8a3.278 3.278 0 01-.658 2.031.24.24 0 00-.05.114 5.362 5.362 0 01-.7 1.942.248.248 0 00-.034.155 4.356 4.356 0 00.273 1.191.247.247 0 00.139.139l2.254.893a4.975 4.975 0 013.156 4.649V22a1.551 1.551 0 01-.015.217.251.251 0 00.249.283H23.5a.5.5 0 00.5-.5v-2.7a2.5 2.5 0 00-1.484-2.285z\"/><path d=\"M18 19.114a3.484 3.484 0 00-2.211-3.255l-4.131-1.636a.251.251 0 01-.158-.232V12.44a.247.247 0 01.108-.205 3.468 3.468 0 001.373-2.745.251.251 0 01.1-.181A1.593 1.593 0 0013.62 8a1.7 1.7 0 00-.412-1.2.25.25 0 01-.059-.25 6.205 6.205 0 00.336-3.174C13.139 1.992 11.256 1.5 9.75 1.5c-1.292 0-2.86.362-3.5 1.341a.251.251 0 01-.206.113 1.431 1.431 0 00-1.069.433c-.713.769-.357 2.253-.1 3.161a.253.253 0 01-.065.245 1.184 1.184 0 00-.207.279A1.942 1.942 0 004.38 8a1.593 1.593 0 00.539 1.309.251.251 0 01.1.181 3.468 3.468 0 001.373 2.745.25.25 0 01.108.205v1.552a.249.249 0 01-.158.232L2.206 15.87A3.482 3.482 0 000 19.122V22a.5.5 0 00.5.5h17a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});