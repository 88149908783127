define("ember-svg-jar/inlined/social-bookmark-zootool", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-bookmark-zootool</title><path d=\"M20.71 18.29a.5.5 0 00-.8-.29c-2.12 1.71-4.3.43-6.62-.92a11.64 11.64 0 00-4.06-1.75 72.45 72.45 0 016-7c3.28-3.57 4.11-4.53 4.17-5l.23-2a.79.79 0 00-.24-.71.88.88 0 00-.69-.23h-.06a24.45 24.45 0 01-6 .52A8.2 8.2 0 019 .14 1 1 0 007.73.3a6.22 6.22 0 00-1.5 3.1 2.2 2.2 0 00.1 1.13C6.92 6 9.74 5.92 11.64 5.67l-8.11 9.57c-.44.52-.44 2.72-.17 3.41a.71.71 0 00.57.51h.77a10.07 10.07 0 017.23 2.53A9.06 9.06 0 0016.81 24h.42a3.09 3.09 0 002.34-1.05 5.57 5.57 0 001.14-4.66z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});