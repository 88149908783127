define("ember-svg-jar/inlined/auto-pilot-car-signal-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>auto-pilot-car-signal-1</title><path d=\"M4.937 8.3A1 1 0 006.32 6.852a3.5 3.5 0 010-5.055A1 1 0 004.937.352a5.5 5.5 0 000 7.945z\"/><path d=\"M7.765 6.5a1 1 0 00.989-1.74.5.5 0 01-.254-.435.5.5 0 01.254-.435 1 1 0 10-.989-1.738 2.5 2.5 0 000 4.347zM20.759 4.325a3.466 3.466 0 01-1.079 2.527A1 1 0 1021.064 8.3a5.5 5.5 0 000-7.945A1 1 0 0019.68 1.8a3.468 3.468 0 011.079 2.525z\"/><path d=\"M17.247 4.76a1 1 0 00.988 1.74 2.5 2.5 0 000-4.347 1 1 0 00-.99 1.738.5.5 0 01.254.435.5.5 0 01-.252.434zM23.982 19.913a11.336 11.336 0 00-7.645-9.776.25.25 0 01-.174-.238v-.732a3.3 3.3 0 10-6.6 0v.74a.251.251 0 01-.181.24C4.666 11.516.779 15.421.03 20.012a2.318 2.318 0 00.516 1.87 2.254 2.254 0 00.766.588.25.25 0 00.355-.253 2.792 2.792 0 01-.017-.3 3.163 3.163 0 016.325 0 2.776 2.776 0 01-.046.494.25.25 0 00.246.294h7.65a.25.25 0 00.246-.294 2.872 2.872 0 01-.046-.494 3.163 3.163 0 016.325 0c0 .079 0 .157-.009.235a.25.25 0 00.366.24 2.373 2.373 0 00.673-.527 2.5 2.5 0 00.602-1.952zM9.27 13.17l-1.437 2.869a1.642 1.642 0 01-1.475.911H4.516a.5.5 0 01-.414-.781A11.19 11.19 0 018.6 12.5a.5.5 0 01.674.669zm2.293-4a1.3 1.3 0 112.6 0v.247a.25.25 0 01-.271.249A9.727 9.727 0 0013 9.625c-.39 0-.777.024-1.162.062a.249.249 0 01-.275-.249zm5.866 7a1.662 1.662 0 01-1.4.782h-4.49a.841.841 0 01-.753-1.218l1.16-2.32a1.64 1.64 0 011.476-.912h2.028a1.64 1.64 0 011.476.912l.575 1.15a1.642 1.642 0 01-.072 1.604z\"/><path d=\"M4.813 19.9a2.013 2.013 0 102.012 2.012A2.012 2.012 0 004.813 19.9zM19.188 19.9a2.013 2.013 0 102.012 2.012 2.012 2.012 0 00-2.012-2.012z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});