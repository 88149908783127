define("ember-svg-jar/inlined/outdoors-nps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-nps</title><path d=\"M12.468 10.015l-.485.118a.251.251 0 00-.184.3l.237.972a.249.249 0 00.3.183l.484-.117a.752.752 0 00.553-.906.755.755 0 00-.905-.55z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM9.173 17.249a.749.749 0 01-.912-.185l-1.6-1.887a.25.25 0 00-.433.221l.416 1.714a.749.749 0 01-.551.906.773.773 0 01-.178.021.749.749 0 01-.728-.572l-1.418-5.83a.75.75 0 011.3-.662l1.6 1.887a.25.25 0 00.433-.221l-.416-1.714a.75.75 0 111.457-.355L9.562 16.4a.752.752 0 01-.389.849zm5.394-5.335a2.231 2.231 0 01-1.39 1.016l-.729.177.532 2.187a.748.748 0 01-.55.905.737.737 0 01-.179.022.75.75 0 01-.728-.573l-.709-2.915-.709-2.915a.751.751 0 01.551-.906l1.457-.354a2.258 2.258 0 012.719 1.654 2.232 2.232 0 01-.265 1.702zm4.871 2.452l-1.65.4a.781.781 0 01-.179.021.75.75 0 01-.176-1.478l1.649-.4a.552.552 0 00.06-1.055l-2.079-.754a2.052 2.052 0 01.218-3.922l1.649-.4a.75.75 0 01.355 1.458l-1.649.4a.553.553 0 00-.061 1.056l2.079.758a2.052 2.052 0 01-.216 3.922z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});