define("ember-svg-jar/inlined/read-email-target", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-email-target</title><path d=\"M7.912 2.874a10.037 10.037 0 018.25.035A1 1 0 0017 1.091a12.048 12.048 0 00-9.905-.042 1 1 0 00.82 1.825zM16.162 21.091a10.037 10.037 0 01-8.25.035 1 1 0 00-.82 1.825A12 12 0 0017 22.909a1 1 0 00-.835-1.818zM21.629 6.01a1 1 0 00-.5 1.322 10 10 0 01-.035 8.251 1 1 0 101.817.834 12 12 0 00.042-9.905 1 1 0 00-1.324-.502zM2.375 6.01a1 1 0 00-1.322.5 11.987 11.987 0 00.047 9.907 1 1 0 101.817-.834 9.992 9.992 0 01-.035-8.251 1 1 0 00-.507-1.322zM19.708 18.293a1 1 0 10-1.415 1.414l4 4a1 1 0 101.407-1.414zM4.3 18.292l-4 4a1 1 0 001.414 1.416l4-4A1 1 0 004.3 18.292zM4.3 5.708a1 1 0 001.41-1.416l-4-4A1 1 0 00.293 1.708zM19.708 5.707l4-4A1 1 0 0022.293.293l-4 4a1 1 0 101.414 1.414zM18.118 10.549l-4.924 3.078a2.26 2.26 0 01-2.384 0l-4.925-3.078a.247.247 0 00-.253-.007.249.249 0 00-.129.219V15.5A1.5 1.5 0 007 17h10a1.5 1.5 0 001.5-1.5v-4.739a.251.251 0 00-.129-.219.249.249 0 00-.253.007z\"/><path d=\"M17 7H7a1.5 1.5 0 00-1.5 1.5.073.073 0 00.035.063l6.065 3.792a.754.754 0 00.8 0l6.067-3.792A.075.075 0 0018.5 8.5 1.5 1.5 0 0017 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});