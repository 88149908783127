define("ember-svg-jar/inlined/real-estate-action-house-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-house-lock</title><path d=\"M23.5 11.5a.5.5 0 00.344-.863L13.029.408a1.5 1.5 0 00-2.059 0L.157 10.636A.5.5 0 00.5 11.5h2a.5.5 0 01.5.5v10a1 1 0 001 1h8a.5.5 0 00.5-.5V17a2.977 2.977 0 011.527-2.6.5.5 0 00.254-.409 4.722 4.722 0 018.434-2.676.5.5 0 00.392.189z\"/><path d=\"M22.875 15.547a.5.5 0 01-.375-.484v-.814a3.5 3.5 0 00-7 0v.814a.5.5 0 01-.375.484A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.125-1.453zM19 21.249a1 1 0 111-1 1 1 0 01-1 1zM20.5 15a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-.75a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});