define("ember-svg-jar/inlined/swimming-goggles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>swimming-goggles</title><path d=\"M13.95 17.349a.751.751 0 00-1.058.077 1.253 1.253 0 01-.928.341 1.282 1.282 0 01-.937-.341.75.75 0 10-1.134.981 2.744 2.744 0 002.071.86 2.7 2.7 0 002.063-.86.75.75 0 00-.077-1.058z\"/><path d=\"M12 .75c-5.238 0-9.5 4.71-9.5 10.5a11.1 11.1 0 002.406 6.976 3.945 3.945 0 01.446.687A7.378 7.378 0 0012 23.25a7.378 7.378 0 006.648-4.337 4.042 4.042 0 01.446-.687A11.1 11.1 0 0021.5 11.25c0-5.79-4.262-10.5-9.5-10.5zM7.053 9.358a6 6 0 019.894 0 .251.251 0 01-.206.392h-1.609a3.376 3.376 0 00-1.966.633l-.876.623a.5.5 0 01-.58 0l-.876-.623a3.376 3.376 0 00-1.966-.633H7.259a.251.251 0 01-.206-.392zm-1.93 3.63a.292.292 0 010-.476l.7-.5a1.386 1.386 0 01.807-.263h2.238a1.386 1.386 0 01.807.263l.7.5a.292.292 0 010 .476l-.7.5a1.386 1.386 0 01-.807.263H6.632a1.386 1.386 0 01-.807-.263zM17.6 16.047c-.835 3.177-2.907 5.2-5.6 5.2s-4.754-2.019-5.593-5.186a.25.25 0 01.244-.314h2.217a3.376 3.376 0 001.966-.633l.876-.623a.5.5 0 01.58 0l.876.623a3.376 3.376 0 001.966.633h2.236a.236.236 0 01.229.3zm1.28-3.059l-.7.5a1.386 1.386 0 01-.807.263h-2.241a1.386 1.386 0 01-.807-.263l-.7-.5a.292.292 0 010-.476l.7-.5a1.386 1.386 0 01.807-.263h2.236a1.386 1.386 0 01.807.263l.7.5a.292.292 0 010 .476z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});