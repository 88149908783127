define("ember-svg-jar/inlined/single-man-actions-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-video</title><path d=\"M9.5 15.5a3 3 0 013-3h2.692a.25.25 0 00.12-.469 2.433 2.433 0 00-.223-.109l-3.936-1.687a.249.249 0 01-.153-.229V8.87a.252.252 0 01.074-.177 3.311 3.311 0 00.779-2.231.251.251 0 01.088-.176 1.873 1.873 0 00.538-1.4 1.575 1.575 0 00-.317-1.014.253.253 0 01-.034-.265 2.531 2.531 0 00.157-1.727C11.944.493 10.242 0 8.888 0a3.752 3.752 0 00-3.163 1.324.251.251 0 01-.212.118 1.072 1.072 0 00-.9.375c-.372.493-.106 1.17.176 1.732a.251.251 0 01-.046.288 1.3 1.3 0 00-.162.2A1.763 1.763 0 004.32 5a1.521 1.521 0 00.638 1.331.249.249 0 01.117.194A3.167 3.167 0 005.92 8.7a.25.25 0 01.08.183v1.123a.248.248 0 01-.151.229l-3.937 1.687C.64 12.468.457 13.626.205 15.23c-.055.359-.117.751-.2 1.179A.5.5 0 00.5 17h8.749a.25.25 0 00.25-.25z\"/><path d=\"M23.37 15.517l-2.806.748a.252.252 0 01-.217-.043.249.249 0 01-.1-.2V15.5a1.5 1.5 0 00-1.5-1.5H12.5a1.5 1.5 0 00-1.5 1.5v7a1.5 1.5 0 001.5 1.5h6.25a1.5 1.5 0 001.5-1.5v-.524a.249.249 0 01.1-.2.252.252 0 01.217-.043l2.806.748A.5.5 0 0024 22v-6a.5.5 0 00-.629-.483z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});