define("ember-svg-jar/inlined/paralympics-javelin-throwing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paralympics-javelin-throwing</title><circle cx=\"13.75\" cy=\"7\" r=\"2.5\"/><path d=\"M21.725 22.919a1.5 1.5 0 00.948-1.9l-.734-2.2a4.548 4.548 0 00-4.765-3.049l-.306.034a.5.5 0 01-.544-.389l-.2-.917a.5.5 0 01.407-.6l.557-.092a1.5 1.5 0 10-.489-2.954l-4.681.778a1.5 1.5 0 01-1.638-.942L9.113 7.545a1.5 1.5 0 00-2.8 1.076l1.165 3.144a4.525 4.525 0 004.214 2.883 4.476 4.476 0 00.709-.056l.176-.03a.5.5 0 01.571.385l.5 2.23-3.06 4.371a1.575 1.575 0 00.2 2.1 1.5 1.5 0 002.193-.287l3.111-4.444 1.417-.158a1.49 1.49 0 011.588 1.017l.733 2.2a1.51 1.51 0 001.895.943zM19.2.675a1 1 0 00-1.27-.62l-16 5.5a1 1 0 00.65 1.89l16-5.5a1 1 0 00.62-1.27z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});