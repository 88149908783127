define("ember-svg-jar/inlined/single-man-actions-graduate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-graduate</title><path d=\"M10.711 15.662l5.4-2.316a.249.249 0 00.125-.341 2.181 2.181 0 00-1.151-1.082l-3.936-1.687a.251.251 0 01-.152-.23V8.87a.252.252 0 01.074-.177 3.305 3.305 0 00.779-2.23.251.251 0 01.088-.176 1.875 1.875 0 00.538-1.4 1.575 1.575 0 00-.317-1.014.252.252 0 01-.034-.265 2.524 2.524 0 00.156-1.727C11.944.493 10.242 0 8.888 0a3.753 3.753 0 00-3.163 1.325.252.252 0 01-.213.118 1.068 1.068 0 00-.895.375c-.372.492-.106 1.17.176 1.732a.249.249 0 01-.046.287 1.352 1.352 0 00-.162.2A1.765 1.765 0 004.32 5a1.524 1.524 0 00.638 1.332.249.249 0 01.117.193A3.165 3.165 0 005.92 8.7a.251.251 0 01.08.183v1.123a.25.25 0 01-.151.23l-3.937 1.686C.64 12.468.457 13.626.205 15.23c-.055.359-.117.752-.2 1.18A.5.5 0 00.5 17h8.888a.249.249 0 00.236-.166 1.986 1.986 0 011.087-1.172zM11 20.965a.75.75 0 001.5 0v-2.154a.25.25 0 01.334-.235l4.5 1.589a.526.526 0 00.166.028.516.516 0 00.166-.028l6-2.119a.5.5 0 00.019-.935l-6-2.383a.5.5 0 00-.369 0l-6 2.383a.5.5 0 00-.316.474z\"/><path d=\"M21.094 22.917a.5.5 0 00.334-.471V20.3a.5.5 0 00-.666-.471l-3.179 1.122a.252.252 0 01-.167 0l-3.178-1.122a.5.5 0 00-.666.471v2.146a.5.5 0 00.334.471l2.571.908a3.075 3.075 0 002.045 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});