define("ember-svg-jar/inlined/cloud-monitor-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-monitor-download</title><path d=\"M22.884 3.009a3.565 3.565 0 00-1.919-.959.248.248 0 01-.164-.1A4.684 4.684 0 0017 0a4.612 4.612 0 00-4.4 3.137.252.252 0 01-.19.168 2.965 2.965 0 00-1.307.616 2.84 2.84 0 00-1.052 2.2 2.733 2.733 0 00.785 2A3.426 3.426 0 0013.1 9h.018c8.183 0 7.545 0 7.628-.008A3.548 3.548 0 0023.95 5.5a3.438 3.438 0 00-1.066-2.491zM20.545 7H13.1c-1.625 0-1.16-1.75-.111-1.75a1.074 1.074 0 01.2.02 1 1 0 001.2-.92A2.569 2.569 0 0117 2a2.642 2.642 0 012.372 1.444.966.966 0 00.934.556 1.678 1.678 0 011.19.445A1.449 1.449 0 0121.95 5.5 1.556 1.556 0 0120.545 7zM7.55 8.5a1 1 0 00-1 1v3.25a.25.25 0 01-.25.25H5.05a.5.5 0 00-.353.853l2.5 2.5a.5.5 0 00.707 0l2.5-2.5A.5.5 0 0010.05 13H8.8a.25.25 0 01-.25-.25V9.5a1 1 0 00-1-1z\"/><path d=\"M3.55 12.5a1 1 0 000-2h-1.5a2 2 0 00-2 2v6.417a2 2 0 002 2H6.3a.25.25 0 01.25.25v.583a.25.25 0 01-.25.25H3.55a1 1 0 000 2h8a1 1 0 000-2H8.8a.25.25 0 01-.25-.25v-.583a.25.25 0 01.25-.25h4.25a2 2 0 002-2V12.5a2 2 0 00-2-2h-1.5a1 1 0 000 2h1.25a.25.25 0 01.25.25v5a.25.25 0 01-.25.25H2.3a.25.25 0 01-.25-.25v-5a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});