define("ember-svg-jar/inlined/cursor-select-1-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-select-1_1</title><path d=\"M4.5 0H2a2 2 0 00-2 2v2.5a1 1 0 002 0V2.248A.25.25 0 012.25 2H4.5a1 1 0 000-2zM1 12.5a1 1 0 001-1v-3a1 1 0 00-2 0v3a1 1 0 001 1zM4.5 18H2.25a.25.25 0 01-.25-.25V15.5a1 1 0 10-2 0V18a2 2 0 002 2h2.5a1 1 0 000-2zM11.5 0h-3a1 1 0 000 2h3a1 1 0 000-2zM15.5 2h2.25a.25.25 0 01.25.25V4.5a1 1 0 102 0V2a2 2 0 00-2-2h-2.5a1 1 0 000 2zM19 7.5a1 1 0 00-1 1v3a1 1 0 002 0v-3a1 1 0 00-1-1zM11.5 18h-3a1 1 0 000 2h3a1 1 0 000-2zM23.634 21.864l-4.015-4.015.984-.421a1 1 0 00-.014-1.844l-6.29-2.59a1 1 0 00-1.3 1.306l2.59 6.289a1 1 0 00.918.618h.006a1 1 0 00.919-.607l.423-.985 4.015 4.016a1.25 1.25 0 001.768-1.768z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});