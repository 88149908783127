define("ember-svg-jar/inlined/task-list-pin-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-list-pin-alternate</title><path d=\"M19 7h-4.715a.25.25 0 00-.25.25V9H18.5a.5.5 0 01.5.5V16a1.171 1.171 0 01-.057.322.25.25 0 01-.24.178h-3.418a1.752 1.752 0 00-1.75 1.75v3.443a.249.249 0 01-.173.238A1.254 1.254 0 0113 22H5.5a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h3.285a.249.249 0 00.25-.25v-1.5a.25.25 0 00-.25-.25H5a2 2 0 00-2 2v13a2 2 0 002 2h8a3.749 3.749 0 002.414-1L20 18.414A3.751 3.751 0 0021 16V9a2 2 0 00-2-2zm-3.538 13.124a.25.25 0 01-.427-.177v-1.7a.253.253 0 01.25-.25h1.7a.25.25 0 01.177.427z\"/><path d=\"M6.535 13.5a.75.75 0 00.75.75h9.5a.75.75 0 000-1.5h-9.5a.75.75 0 00-.75.75zM10.285 17.25h-3a.75.75 0 000 1.5h3a.75.75 0 000-1.5zM10.381 5.769a.251.251 0 01.154.231v3a1 1 0 002 0V6a.251.251 0 01.154-.231 3 3 0 10-2.308 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});