define("ember-svg-jar/inlined/smiley-in-love-alternate-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-in-love-alternate-2</title><path d=\"M17.2 14.5H6.8a.5.5 0 00-.433.75 6.5 6.5 0 0011.26 0 .5.5 0 00-.433-.75z\"/><path d=\"M23 11a1 1 0 00-1 1 10 10 0 01-20 0 1 1 0 00-2 0 12 12 0 0024 0 1 1 0 00-1-1zM9.727 2.259a10.113 10.113 0 014.546 0 1.054 1.054 0 00.228.025 1 1 0 00.226-1.973 12.159 12.159 0 00-5.454 0 1 1 0 00.454 1.948z\"/><path d=\"M5.139 11.345a.5.5 0 00.722 0L9.263 7.8a2.514 2.514 0 00.471-2.9A2.517 2.517 0 005.7 4.236l-.026.027a.252.252 0 01-.354 0l-.02-.027a2.517 2.517 0 00-3.571 3.552zM18.3 4.236a2.517 2.517 0 00-4.031.655 2.51 2.51 0 00.463 2.9l3.41 3.557a.5.5 0 00.722 0l3.4-3.549a2.514 2.514 0 00.471-2.9 2.517 2.517 0 00-4.035-.663l-.026.027a.252.252 0 01-.354 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});