define("ember-svg-jar/inlined/vegetables-bean-soup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vegetables-bean-soup</title><path d=\"M20.787 6.371a.5.5 0 01-.258-.3 5.011 5.011 0 00-4.9-3.142 5.178 5.178 0 00-4.691 2.613.5.5 0 01-.449.279H9.176a3.633 3.633 0 00-2.95 1.514.5.5 0 01-.408.208h-.384C2.457 7.544.125 9.115.125 11.117a3.145 3.145 0 001.691 2.632 6.957 6.957 0 003.617.938c.141 0 .294 0 .457-.009a10.4 10.4 0 014.365.717 14.56 14.56 0 004.958.827 15.716 15.716 0 002.395-.181.5.5 0 01.577.485v.535a.5.5 0 01-.424.491 16.731 16.731 0 01-2.539.191 15.81 15.81 0 01-5.4-.911 9.158 9.158 0 00-3.9-.64c-.176 0-.339.009-.491.009a8.219 8.219 0 01-4.274-1.124.176.176 0 00-.252.225c1.658 3.519 5.826 5.769 11.247 5.769a16.541 16.541 0 006.582-1.3.5.5 0 01.515.069 2.842 2.842 0 004.624-2.216V11.3a5.483 5.483 0 00-3.086-4.929zm-5.162-1.442A3.352 3.352 0 0118.4 6.205a.5.5 0 01-.043.61 3.707 3.707 0 01-2.729 1.006A3.707 3.707 0 0112.9 6.815a.5.5 0 01-.043-.61 3.352 3.352 0 012.768-1.276zm6.25 12.693a.844.844 0 11-1.688 0v-1.054a5.485 5.485 0 00-5.479-5.479H9.176a1.634 1.634 0 010-3.268h1.714a.5.5 0 01.39.187 5.557 5.557 0 004.345 1.813A5.817 5.817 0 0019.609 8.4a.5.5 0 01.6-.063 3.478 3.478 0 011.666 2.963z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});