define("ember-svg-jar/inlined/login-keys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>login-keys</title><path d=\"M13.5 9A5.975 5.975 0 0012 5.036V2.5a2.5 2.5 0 00-5 0v.521a6 6 0 00-1.5 11.631v.641l-.854.853a.5.5 0 00-.146.354.505.505 0 00.146.354l.854.854v.586l-.854.854a.5.5 0 000 .707l.854.853V22a.5.5 0 00.146.354l1.5 1.5a.5.5 0 00.708 0l1.5-1.5A.5.5 0 009.5 22v-7.348A6.019 6.019 0 0013.5 9zm-6-3a1 1 0 11-1 1 1 1 0 011-1zm2-5A1.5 1.5 0 0111 2.5v1.63a5.988 5.988 0 00-2-.94V5H8V2.5A1.5 1.5 0 019.5 1z\"/><path d=\"M22.354 18.026l-5.2-5.2a5.946 5.946 0 00-2.123-7.592.5.5 0 00-.728.628 7.471 7.471 0 01-2.774 9.45.5.5 0 00.269.921h.006a6.023 6.023 0 002.541-.589l.65.577V17.5a.5.5 0 00.5.5h1.286l.214.224V19.5a.5.5 0 00.5.5h1.119l.917.864a.5.5 0 00.343.136H22a.5.5 0 00.5-.5v-2.121a.5.5 0 00-.146-.353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});