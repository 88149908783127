define("ember-svg-jar/inlined/wind-cloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wind-cloud</title><path d=\"M22.513 9.943a.252.252 0 01-.071-.218A4.6 4.6 0 0022.5 9a4.489 4.489 0 00-3.517-4.392.251.251 0 01-.171-.136 7 7 0 00-12.988.915.251.251 0 01-.205.173A6.509 6.509 0 000 12a6.594 6.594 0 00.058.872.253.253 0 00.109.174.249.249 0 00.2.035A2.481 2.481 0 011 13h.809A.248.248 0 002 12.912a.245.245 0 00.057-.2A4.5 4.5 0 016.581 7.5a1 1 0 00.986-.835 5 5 0 019.647-.83 1 1 0 00.9.667 2.5 2.5 0 012.232 3.365 1 1 0 00.366 1.169A3 3 0 0119 16.5h-4.587a.25.25 0 00-.154.447 4.532 4.532 0 011.2 1.422.25.25 0 00.22.131H19a5 5 0 003.513-8.557z\"/><path d=\"M1 16.5h7.5a3 3 0 000-6 1 1 0 000 2 1 1 0 010 2H1a1 1 0 000 2zM11.5 17.5H1a1 1 0 000 2h10.5a1 1 0 010 2 1 1 0 000 2 3 3 0 000-6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});