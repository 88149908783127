define("ember-svg-jar/inlined/video-game-knight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-knight</title><path d=\"M23.5 13H22a.5.5 0 01-.5-.5v-9c0-.829-.672-3.5-1.5-3.5s-1.5 2.671-1.5 3.5v9a.5.5 0 01-.5.5h-1.5a.5.5 0 000 1h7a.5.5 0 000-1zM20.5 15.5h-1a.5.5 0 00-.5.5v3.5a1 1 0 002 0V16a.5.5 0 00-.5-.5z\"/><path d=\"M13.008 13.9a.5.5 0 01-.106-.725 4.974 4.974 0 00.967-1.978 1.231 1.231 0 01.222-.471L16.44 7.63a1.25 1.25 0 00-1.129-1.974h-1.374a.5.5 0 01-.5-.45A5.543 5.543 0 0010.1.251 3.843 3.843 0 007.437.226a18.666 18.666 0 00-4.221 2c-.491.293-.962.575-1.443.82A.5.5 0 002 4h2.214a.5.5 0 01.491.6c-.039.2-.077.411-.109.634a.5.5 0 01-.5.429h-1.41a1.249 1.249 0 00-1.108 2l1.139 1.5a1 1 0 00.8.395H4.52a.5.5 0 00.4-.8l-.52-.701a.25.25 0 01.2-.4h8.811a.251.251 0 01.2.4l-1.437 1.884a.994.994 0 00-.2.481 2.993 2.993 0 01-1.265 2.086.5.5 0 00-.214.411v4.629c0 2.077-1.465 4.5-3.12 6.018a.25.25 0 00.174.434H15a1 1 0 001-1v-2.668a7.572 7.572 0 00-2.992-6.432z\"/><path d=\"M9 12.048a1 1 0 00-1-1H1a1 1 0 00-1 1v5.5c0 2.732 3.229 6 4.5 6s4.5-3.268 4.5-6zM6.5 16.3h-.75a.5.5 0 00-.5.5v2.25a.75.75 0 01-1.5 0V16.8a.5.5 0 00-.5-.5H2.5a.75.75 0 110-1.5h.75a.5.5 0 00.5-.5v-.75a.75.75 0 011.5 0v.75a.5.5 0 00.5.5h.75a.75.75 0 110 1.5z\"/><circle cx=\"7.5\" cy=\"9.298\" r=\".75\"/><circle cx=\"10.5\" cy=\"9.298\" r=\".75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});