define("ember-svg-jar/inlined/real-estate-search-house-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-search-house-1</title><path d=\"M9.5 16.5a6.993 6.993 0 0111.749-5.134.5.5 0 00.34.134H23.5a.5.5 0 00.344-.864L13.03.41a1.5 1.5 0 00-2.06 0L.156 10.636A.5.5 0 00.5 11.5h2a.5.5 0 01.5.5v10a1 1 0 001 1h8.9a.235.235 0 00.117-.438A7 7 0 019.5 16.5z\"/><path d=\"M23.634 21.866l-2.2-2.2a.5.5 0 01-.087-.59A5.448 5.448 0 0022 16.5a5.5 5.5 0 10-5.5 5.5 5.441 5.441 0 002.58-.651.5.5 0 01.59.088l2.2 2.2a1.25 1.25 0 001.768-1.767zM13 16.5a3.5 3.5 0 113.5 3.5 3.5 3.5 0 01-3.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});