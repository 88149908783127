define("ember-svg-jar/inlined/color-tube", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-tube</title><path d=\"M11.369 17.831l5.731-5.469a.5.5 0 00.009-.715l-7-7a.508.508 0 00-.716.007l-5.481 5.735a4.473 4.473 0 00-.959 1.951l-.691 3.05a.5.5 0 00.134.464l3.5 3.5a.5.5 0 00.464.134l3.022-.688a4.473 4.473 0 001.987-.969zM18.731 11.222a1 1 0 001.415 0l1.166-1.166a1.5 1.5 0 000-2.121L13.817.439a1.5 1.5 0 00-2.121 0l-1.168 1.166a1 1 0 000 1.414zM1.805 16.945a.507.507 0 00-.72.013l-.4.426a1.5 1.5 0 000 2.122l1.557 1.555a1.5 1.5 0 001.065.44 1.469 1.469 0 001.043-.428l.438-.408a.5.5 0 00.013-.72zM23.457 21.569a1 1 0 00-1.414 0 1.513 1.513 0 01-2.086 0 3.556 3.556 0 00-4.914 0 1.513 1.513 0 01-2.086 0 3.556 3.556 0 00-4.914 0 1 1 0 001.414 1.414 1.509 1.509 0 012.086 0 3.56 3.56 0 004.914 0 1.509 1.509 0 012.086 0 3.476 3.476 0 004.914 0 1 1 0 000-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});