define("ember-svg-jar/inlined/gesture-swipe-vertical-down-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-vertical-down-2</title><path d=\"M14.91 6.679a1 1 0 101.95-.442A10.88 10.88 0 0013.741.4a1 1 0 00-1.366 1.46 8.992 8.992 0 012.535 4.819zM16.106 16.285a1 1 0 00-1.2.754 15.5 15.5 0 01-.934 2.859.251.251 0 01-.374.1l-.616-.44a.751.751 0 00-1.177.73l.478 2.951a.748.748 0 00.867.619l2.946-.506a.751.751 0 00.309-1.35l-.736-.525a.25.25 0 01-.079-.313 17.036 17.036 0 001.266-3.685 1 1 0 00-.75-1.194zM18.51 11.366a1 1 0 00-1-1h-7.064a.25.25 0 01-.146-.456c2.139-1.489 1.45-3.855-.222-2.761l-4.366 2.912a.5.5 0 00-.222.416v5.437a.5.5 0 00.411.491c4.412.8 3.586.643 4.165.776a1.286 1.286 0 001.521-.895l1.113-3.742a.25.25 0 01.24-.178h4.57a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});