define("ember-svg-jar/inlined/style-three-pin-user-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-user-woman</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm0 3a2.5 2.5 0 11-2.5 2.5A2.5 2.5 0 0112 3zm3.906 12.792A.5.5 0 0115.5 16h-1.219a.5.5 0 00-.485.379l-.311 1.242A.5.5 0 0113 18h-2a.5.5 0 01-.485-.379l-.315-1.242A.5.5 0 009.719 16H8.5a.5.5 0 01-.474-.658l1.525-4.577a2.581 2.581 0 014.9 0l1.526 4.577a.5.5 0 01-.071.45z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});