define("ember-svg-jar/inlined/camera-tripod", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camera-tripod</title><path d=\"M18.384 23.634a1.251 1.251 0 000-1.768l-5.061-5.06a.251.251 0 01-.073-.177V13.25a.25.25 0 01.25-.25H15a1 1 0 000-2H9a1 1 0 000 2h1.5a.25.25 0 01.25.25v3.379a.251.251 0 01-.073.177l-5.061 5.06a1.25 1.25 0 001.768 1.768l2.939-2.94a.25.25 0 01.427.177v1.879a1.25 1.25 0 002.5 0v-1.879a.25.25 0 01.427-.177l2.939 2.94a1.251 1.251 0 001.768 0z\"/><circle cx=\"13.5\" cy=\"5.5\" r=\"2\"/><path d=\"M5.5 8.5A1.5 1.5 0 007 10h10a1.5 1.5 0 001.5-1.5v-6A1.5 1.5 0 0017 1h-1.04a.249.249 0 01-.219-.129A1.449 1.449 0 0014.382 0h-2.264a1.449 1.449 0 00-1.359.871.249.249 0 01-.219.129H7a1.5 1.5 0 00-1.5 1.5zm8-6a3 3 0 11-3 3 3 3 0 013-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});