define("ember-svg-jar/inlined/data-file-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-sync</title><path d=\"M21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.277a.247.247 0 00.241.249 19.53 19.53 0 011.485.093A.246.246 0 008 9.371V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.245.245 0 00-.241.21 7.956 7.956 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.414A2 2 0 0023.414 3z\"/><path d=\"M11.114 10.786a1.913 1.913 0 012.165-.372.223.223 0 00.23-.045c.14-.2.418-.594.471-.664.071-.094.164-.082.272-.017l1.523.914A1 1 0 0017 10.451a.9.9 0 00.105-.123l2.688-3.734a1 1 0 10-1.624-1.168l-2.015 2.8a.252.252 0 01-.332.068L14.3 7.387a1 1 0 00-1.33.28l-2.07 2.925c-.1.149.145.261.214.194zM.309 23.142a.5.5 0 00.545-.109l.974-.974a.244.244 0 01.353.008 6.085 6.085 0 0010.211-2.2 1 1 0 00-1.879-.687 4.022 4.022 0 01-1.277 1.929 4.163 4.163 0 01-5.656-.471.255.255 0 01.014-.349l1.716-1.713a.5.5 0 00-.353-.853H.5a.5.5 0 00-.5.5v4.457a.5.5 0 00.309.462zM6.347 11a6.108 6.108 0 00-5.78 4.129 1 1 0 001.88.683A4.02 4.02 0 013.718 13.9a4.157 4.157 0 015.638.435.256.256 0 01-.012.351L7.69 16.338a.5.5 0 00.353.853H12.5a.5.5 0 00.5-.5v-4.457a.5.5 0 00-.854-.353l-1.03 1.03a.243.243 0 01-.352-.008A6.126 6.126 0 006.347 11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});