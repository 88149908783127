define("ember-svg-jar/inlined/smiley-tongue-sticking-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-tongue-sticking-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M16.5 14h-9a1 1 0 000 2h2.75a.25.25 0 01.25.25v1.5a3 3 0 006 0v-1.525a.25.25 0 01.2-.245 1 1 0 00-.2-1.98zm-2 3.75a1 1 0 01-2 0v-1.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25zM6.336 7.986l3 .5A.927.927 0 009.5 8.5a1 1 0 00.163-1.986l-3-.5a1 1 0 10-.328 1.972zM9.5 11.5a1 1 0 000-2h-3a1 1 0 000 2zM17.336 6.014l-3 .5A1 1 0 0014.5 8.5a.927.927 0 00.165-.014l3-.5a1 1 0 00-.328-1.972zM17.5 9.5h-3a1 1 0 000 2h3a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});