define("ember-svg-jar/inlined/road-sign-look-both-ways-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-look-both-ways-1</title><path d=\"M23.53 11.47l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0l11-11a.749.749 0 000-1.06zM10.96 10.1l-2.723 2.723a.25.25 0 000 .354l.293.293A.75.75 0 018 14.75H6a.75.75 0 01-.75-.75v-2a.75.75 0 011.28-.53l.293.292a.25.25 0 00.354 0L9.9 9.039a.75.75 0 011.06 1.061zm7.79 3.9a.75.75 0 01-.75.75h-2a.75.75 0 01-.53-1.28l.293-.293a.252.252 0 000-.354L13.034 10.1A.75.75 0 0114.1 9.034l2.728 2.729a.252.252 0 00.354 0l.293-.293a.75.75 0 011.28.53z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});