define("ember-svg-jar/inlined/measure-caliber", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>measure-caliber</title><path d=\"M23.707 5.243l-4.95-4.95a1 1 0 00-1.414 0L.293 17.343a1 1 0 000 1.414l4.95 4.95a1 1 0 001.414 0l17.05-17.05a1 1 0 000-1.414zM19.8 4.2a2.37 2.37 0 01.586 2.379.251.251 0 01-.416.1l-2.651-2.65a.25.25 0 01.1-.416A2.37 2.37 0 0119.8 4.2zM4.2 16.428a2.37 2.37 0 012.379-.586.25.25 0 01.1.416l-2.65 2.652a.25.25 0 01-.416-.1 2.37 2.37 0 01.587-2.382zM7.572 19.8a2.37 2.37 0 01-2.379.586.25.25 0 01-.1-.416l2.652-2.652a.25.25 0 01.237-.066.247.247 0 01.178.169 2.367 2.367 0 01-.588 2.379zm2.741-9.488a2.28 2.28 0 01.623-.44.218.218 0 01.314.2v3.86a.218.218 0 01-.314.2 2.28 2.28 0 01-.623-.44 2.387 2.387 0 010-3.379zm3.374 3.374a2.255 2.255 0 01-.573.415.249.249 0 01-.364-.222V10.12a.25.25 0 01.364-.222 2.255 2.255 0 01.573.415 2.387 2.387 0 010 3.374zm2.741-6.115a2.37 2.37 0 01-.586-2.379.25.25 0 01.416-.1l2.652 2.65a.25.25 0 01-.1.416 2.37 2.37 0 01-2.382-.586z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});