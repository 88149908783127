define("ember-svg-jar/inlined/folder-cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-cash</title><path d=\"M17.5 9.5a7.914 7.914 0 013.153.657.244.244 0 00.347-.222V4.5A1.5 1.5 0 0019.5 3H9.618a.5.5 0 01-.447-.276L8.224.83A1.5 1.5 0 006.88 0H1.5A1.5 1.5 0 000 1.5v15A1.5 1.5 0 001.5 18h7.594c.341 0 .406-.331.406-.5a8.009 8.009 0 018-8z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm.887 10.2a.184.184 0 00-.137.2v.354a.75.75 0 01-1.5 0v-.334a.17.17 0 00-.164-.166H16a.75.75 0 010-1.5h2.033a.592.592 0 00.22-1.141l-2.063-.826a2.079 2.079 0 01.4-3.98l-.005-.008a.191.191 0 00.164-.2v-.35a.75.75 0 011.5 0v.335a.171.171 0 00.169.165H19a.75.75 0 010 1.5h-2.033a.592.592 0 00-.22 1.141l2.063.826a2.079 2.079 0 01-.423 3.984z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});