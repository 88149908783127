define("ember-svg-jar/inlined/real-estate-message-house-buy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-message-house-buy</title><path d=\"M15.08 14a.5.5 0 00-.6-.1l-1.537.814a2.707 2.707 0 01-3.66-3.658l.922-1.741a.5.5 0 00-.077-.577c-.161-.17-.3-.315-.4-.425a1.035 1.035 0 00-1.459 0c-.7.746-8 8.5-8 8.5A1 1 0 001 18.5h1.5a.5.5 0 01.5.5v4a1 1 0 001 1h3a.5.5 0 00.5-.5V20a1.5 1.5 0 013 0v3.5a.5.5 0 00.5.5h3a1 1 0 001-1v-4a.5.5 0 01.5-.5H17a1 1 0 00.724-1.688z\"/><path d=\"M17.5 0A6.508 6.508 0 0011 6.5a6.436 6.436 0 00.418 2.3.5.5 0 01-.025.411l-1.225 2.314a1.708 1.708 0 002.308 2.308l2.314-1.225a.5.5 0 01.411-.025A6.436 6.436 0 0017.5 13a6.5 6.5 0 000-13zm0 11.5a4.943 4.943 0 01-2.2-.509.752.752 0 00-.682.01l-2.84 1.5h-.006a.207.207 0 01-.278-.281L13 9.382a.752.752 0 00.01-.682 4.943 4.943 0 01-.51-2.2 5 5 0 115 5z\"/><path d=\"M16.654 5h2.6a.75.75 0 000-1.5H18.5a.25.25 0 01-.25-.25.75.75 0 00-1.5 0v.15a.1.1 0 01-.1.1A1.654 1.654 0 0016 6.674l2.406 1.031a.152.152 0 01.092.141.154.154 0 01-.152.154h-2.6a.75.75 0 000 1.5h.75a.25.25 0 01.25.25.75.75 0 001.5 0V9.6a.1.1 0 01.1-.1A1.654 1.654 0 0019 6.326L16.592 5.3a.152.152 0 01-.092-.141.154.154 0 01.154-.159z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});