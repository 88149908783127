define("ember-svg-jar/inlined/light-mode-bw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-mode-bw</title><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2zm-2 19.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M13.47 17.019a.75.75 0 001.254-.333l.535-1.962a.25.25 0 01.482 0l.535 1.962a.752.752 0 00.532.528.784.784 0 00.192.025.747.747 0 00.53-.22c1.11-1.109 1.72-3.221 1.72-5.946V8.489a.75.75 0 10-1.5 0v2.584a12.73 12.73 0 01-.246 2.619.25.25 0 01-.485.014l-.795-2.914a.75.75 0 00-1.448 0l-.795 2.914a.25.25 0 01-.485-.014 12.73 12.73 0 01-.246-2.619V8.489a.75.75 0 10-1.5 0v2.584c0 2.727.61 4.837 1.72 5.946zM5.5 17.239H7a2.748 2.748 0 002.047-4.584.248.248 0 010-.333A2.748 2.748 0 007 7.739H5.5a.75.75 0 00-.75.75v8a.75.75 0 00.75.75zm2.75-2.75A1.252 1.252 0 017 15.739h-.5a.25.25 0 01-.25-.25v-2a.25.25 0 01.25-.25H7a1.251 1.251 0 011.25 1.25zm-2-5a.25.25 0 01.25-.25H7a1.25 1.25 0 010 2.5h-.5a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});