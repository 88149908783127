define("ember-svg-jar/inlined/app-window-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-home</title><path d=\"M16.676 12.119l-6.5 5.5a.5.5 0 00.324.882h1.25a.25.25 0 01.25.25V23.5a.5.5 0 00.5.5H15a.5.5 0 00.5-.5v-2.25a1.5 1.5 0 013 0v2.25a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-4.75a.25.25 0 01.25-.25h1.25a.5.5 0 00.323-.882l-6.5-5.5a.5.5 0 00-.647.001z\"/><path d=\"M24 3a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h4a1 1 0 001-1 1 1 0 00-1-1H3a1 1 0 01-1-1V5.251A.25.25 0 012.25 5h19.5a.25.25 0 01.25.25v7.25a1 1 0 001 1 1 1 0 001-1zM4.346 3a.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.987.987 0 01.092-.409A1 1 0 013.5 1.5a.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5zm3.5 0a.966.966 0 01-1.692 0A.972.972 0 016 2.5a.969.969 0 01.154-.5.966.966 0 011.692 0A.969.969 0 018 2.5a.972.972 0 01-.154.5zm3.5 0a.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});