define("ember-svg-jar/inlined/footwear-boots-cowboy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>footwear-boots-cowboy</title><path d=\"M18.761.067a.5.5 0 00-.565.5v6.37a1.5 1.5 0 11-3 0V.569a.5.5 0 00-.567-.5c-1.523.205-2.993.656-3.614 1.463a1.49 1.49 0 00-.3 1.1l1.325 10.571a1.488 1.488 0 01-.436 1.258 7.152 7.152 0 01-2.949 1.387 12.421 12.421 0 00-6.837 4.332 2.843 2.843 0 00-.278.413 2.251 2.251 0 00.76 2.848 3.361 3.361 0 001.64.5h9.825a1 1 0 00.964-.74 5.229 5.229 0 012.207-2.949.5.5 0 01.76.427v2.262a1 1 0 001 1h3a1 1 0 001-1V2.437C22.7 1.115 20.93.352 18.761.067z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});