define("ember-svg-jar/inlined/computer-chip-core", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>computer-chip-core</title><path d=\"M12.53 6.031a.749.749 0 01-1.06 0L9.763 4.324a.251.251 0 00-.177-.073H9.5a.25.25 0 00-.25.25v15a.25.25 0 00.25.25h.086a.252.252 0 00.177-.074l1.707-1.707a.749.749 0 011.06 0l1.707 1.707a.252.252 0 00.177.074h.086a.25.25 0 00.25-.25V4.5a.25.25 0 00-.25-.25h-.086a.251.251 0 00-.177.073z\"/><path d=\"M21 9.5a1 1 0 100-2h-1.75a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H21a1 1 0 000-2h-1.75a.25.25 0 01-.25-.25V1.5A1.5 1.5 0 0017.5 0h-11A1.5 1.5 0 005 1.5v2.25a.25.25 0 01-.25.25H3a1 1 0 000 2h1.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H3a1 1 0 100 2h1.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H3a1 1 0 000 2h1.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H3a1 1 0 100 2h1.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H3a1 1 0 000 2h1.75a.25.25 0 01.25.25v2.25A1.5 1.5 0 006.5 24h11a1.5 1.5 0 001.5-1.5v-2.25a.25.25 0 01.25-.25H21a1 1 0 000-2h-1.75a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H21a1 1 0 000-2h-1.75a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H21a1 1 0 000-2h-1.75a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25zm-4.75-6v17a.75.75 0 01-.75.75H14a.747.747 0 01-.53-.22l-1.293-1.293a.25.25 0 00-.354 0l-1.293 1.294a.747.747 0 01-.53.22H8.5a.75.75 0 01-.75-.75V3.5a.75.75 0 01.75-.75H10a.75.75 0 01.53.219l1.293 1.293a.25.25 0 00.354 0L13.47 2.97a.75.75 0 01.53-.219h1.5a.75.75 0 01.75.749z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});