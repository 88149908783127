define("ember-svg-jar/inlined/shape-cylinder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shape-cylinder</title><path d=\"M6.176 17.026a.25.25 0 00-.176.239v1.057a.247.247 0 00.105.2.251.251 0 00.227.033A17.711 17.711 0 0112 17.75a17.711 17.711 0 015.668.808.251.251 0 00.227-.033.247.247 0 00.105-.2v-1.06a.25.25 0 00-.176-.239A20.488 20.488 0 0012 16.25a20.488 20.488 0 00-5.824.776z\"/><path d=\"M21.5 4v-.055C21.433 1.235 16.545 0 12 0S2.567 1.235 2.5 3.945v16.11C2.567 22.765 7.455 24 12 24s9.433-1.235 9.5-3.945V20zM12 2c4.872 0 7.5 1.42 7.5 2S16.872 6 12 6 4.5 4.58 4.5 4 7.128 2 12 2zm0 20c-4.872 0-7.5-1.42-7.5-2V6.981a.25.25 0 01.354-.228A18.4 18.4 0 0012 8a18.4 18.4 0 007.146-1.247.25.25 0 01.354.228V20c0 .58-2.628 2-7.5 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});