define("ember-svg-jar/inlined/video-file-m4v-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-m4v-1</title><path d=\"M23.707 5.707L18.293.293A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.75V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707z\"/><path d=\"M9.652 12.827a4.892 4.892 0 00-1.268 1.827.158.158 0 00.145.221h1.083a.25.25 0 00.25-.25v-1.7c0-.178-.142-.159-.21-.098z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zM6.125 17.5a.625.625 0 01-1.25 0v-3.018c0-.111-.132-.071-.167 0l-.149.3a.65.65 0 01-1.118 0s-.093-.181-.148-.3-.168-.078-.168 0V17.5a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279s.711 1.443.786 1.571a.179.179 0 00.308.005l.788-1.576a.625.625 0 011.184.279zm4.987 0a.625.625 0 01-1.25 0v-1.123a.252.252 0 00-.252-.252H7.5a.624.624 0 01-.616-.731 6.146 6.146 0 013.327-4.445.625.625 0 01.9.561zm5.013-4.5a8.176 8.176 0 01-1.625 4.875.625.625 0 01-1 0A8.176 8.176 0 0111.875 13v-1.5a.625.625 0 011.25 0V13a6.93 6.93 0 00.674 2.966c.063.131.328.152.387.031a6.923 6.923 0 00.689-3V11.5a.625.625 0 011.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});