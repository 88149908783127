define("ember-svg-jar/inlined/multiple-actions-mail.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-mail</title><path d=\"M19.8 13a.5.5 0 00.481-.637 6.015 6.015 0 00-8.261-3.829.5.5 0 00-.182.771 7.429 7.429 0 011.531 3.289.5.5 0 00.491.406z\"/><ellipse cx=\"14.013\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M11.675 13.389a.5.5 0 00.224-.517A6.023 6.023 0 000 13.333.6.6 0 00.6 14h10.177a.5.5 0 00.373-.166 2.483 2.483 0 01.525-.445z\"/><ellipse cx=\"5.513\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M23.871 18.042a.249.249 0 00-.254.007l-4.425 3.078a2.26 2.26 0 01-2.384 0l-4.425-3.078a.25.25 0 00-.383.212V22.5a1.5 1.5 0 001.5 1.5h9a1.5 1.5 0 001.5-1.5v-4.239a.249.249 0 00-.129-.219z\"/><path d=\"M24 16a1.5 1.5 0 00-1.5-1.5h-9A1.5 1.5 0 0012 16a.073.073 0 00.035.063l5.565 3.792a.752.752 0 00.794 0l5.568-3.792A.075.075 0 0024 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});