define("ember-svg-jar/inlined/modern-weapon-bullets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-weapon-bullets</title><path d=\"M1 11a1 1 0 00.98-.8.249.249 0 01.245-.2H15a.5.5 0 00.5-.5v-6A.5.5 0 0015 3H2.225a.25.25 0 01-.245-.2A1 1 0 000 3v7a1 1 0 001 1zM17 9.5a.5.5 0 00.5.5c3.28 0 6.5-1.733 6.5-3.5S20.78 3 17.5 3a.5.5 0 00-.5.5zM23 13a1 1 0 00-.98.8.249.249 0 01-.245.2H9a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h12.775a.25.25 0 01.245.2A1 1 0 0024 21v-7a1 1 0 00-1-1zM6.5 14C3.22 14 0 15.733 0 17.5S3.22 21 6.5 21a.5.5 0 00.5-.5v-6a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});