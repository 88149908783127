define("ember-svg-jar/inlined/coffee-turkish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>coffee-turkish</title><path d=\"M21.249 13.375H13.5a1 1 0 00-1 1v2.5c0 2.2 1.8 3.5 4 3.5a3.965 3.965 0 003.016-1.413.252.252 0 01.19-.087h1.544a2.75 2.75 0 000-5.5zm0 4h-.283a.5.5 0 01-.5-.565 10.615 10.615 0 00.029-1.435.5.5 0 01.5-.5h.25a1.25 1.25 0 010 2.5zM22 21.875H11a1 1 0 000 2h11a1 1 0 000-2zM10.315 10.421a.148.148 0 00.12-.006l9.877-5.153a1 1 0 10-.924-1.774l-8.319 4.34a.5.5 0 00-.265.38 2.437 2.437 0 01-.322.944 1.958 1.958 0 00-.25 1.182.146.146 0 00.083.087zM8.809 10.806a3.293 3.293 0 01.384-2.42 1 1 0 00-.86-1.511H1.366a1 1 0 00-.86 1.51 3.308 3.308 0 01.386 2.421l-.739 2.147a2.8 2.8 0 00.233 2.328 2.869 2.869 0 001.968 1.371 14.075 14.075 0 004.991 0 2.871 2.871 0 001.969-1.371 2.8 2.8 0 00.232-2.328zM2.783 3.876l-.412.549a.75.75 0 101.2.9l.413-.549a1.8 1.8 0 00-.264-2.462.309.309 0 01-.06-.44l.412-.549a.75.75 0 00-1.2-.9l-.413.549a1.8 1.8 0 00.264 2.462.309.309 0 01.06.44zM6.04 3.876l-.411.549a.75.75 0 101.2.9l.412-.549a1.8 1.8 0 00-.264-2.462.308.308 0 01-.06-.44l.412-.549a.75.75 0 00-1.2-.9l-.411.549a1.8 1.8 0 00.262 2.462.307.307 0 01.06.44z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});