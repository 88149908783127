define("ember-svg-jar/inlined/swan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>swan</title><path d=\"M12 0A12.013 12.013 0 000 12a7.427 7.427 0 00.563 2.852 63.355 63.355 0 012.519 7.6A1.99 1.99 0 005.029 24a3.224 3.224 0 003.221-3.221V12A3.8 3.8 0 0112 8a4 4 0 010 8 .5.5 0 00-.5.5v7a.5.5 0 00.5.5 12 12 0 000-24zM3.5 13.5a1 1 0 111 1 1 1 0 01-1-1zm2.75 7.279A1.223 1.223 0 015.03 22a42.411 42.411 0 00-1.389-4.555 3.985 3.985 0 001.98.04 2.865 2.865 0 00.629-.236z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});