define("ember-svg-jar/inlined/team-exchange-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>team-exchange-chat</title><path d=\"M13 7.5v-6A1.5 1.5 0 0011.5 0h-10A1.5 1.5 0 000 1.5v6A1.5 1.5 0 001.5 9h5.189a.25.25 0 01.177.074l2.28 2.28a.5.5 0 00.354.146.489.489 0 00.191-.038A.5.5 0 0010 11V9.249A.25.25 0 0110.25 9h1.25A1.5 1.5 0 0013 7.5zM6.25 5.249A.25.25 0 006 5.5V6a.5.5 0 01-.276.448.507.507 0 01-.224.052.494.494 0 01-.3-.1l-2-1.5a.5.5 0 010-.8l2-1.5A.5.5 0 016 3v.5a.249.249 0 00.25.25H10a.75.75 0 110 1.5zM24 14a1.5 1.5 0 00-1.5-1.5h-10A1.5 1.5 0 0011 14v6a1.5 1.5 0 001.5 1.5h1.25a.25.25 0 01.25.25v1.75a.5.5 0 00.309.462.489.489 0 00.191.038.5.5 0 00.354-.146l2.28-2.28a.25.25 0 01.177-.074H22.5A1.5 1.5 0 0024 20zm-10 3.75a.75.75 0 010-1.5h3.75A.249.249 0 0018 16v-.5a.5.5 0 01.8-.4l2 1.5a.5.5 0 010 .8l-2 1.5a.494.494 0 01-.3.1.507.507 0 01-.224-.052A.5.5 0 0118 18.5V18a.25.25 0 00-.25-.25zM14.2 9.87a.249.249 0 00.225.357h7.785a.25.25 0 00.225-.358 4.675 4.675 0 00-2.035-2.12.25.25 0 01-.01-.437 3.925 3.925 0 10-4.04-.047.249.249 0 01-.021.436A4.655 4.655 0 0014.2 9.87zm2.363-6.5a.249.249 0 01.364-.145 5.085 5.085 0 002.547.7 4.838 4.838 0 00.783-.064.066.066 0 01.075.064 1.925 1.925 0 11-3.769-.551zM7.514 20.222a.249.249 0 01-.129-.206.252.252 0 01.107-.218 3.924 3.924 0 10-4.395.053.249.249 0 01-.012.425A4.689 4.689 0 001.227 22.3a.25.25 0 00.225.358h7.786a.25.25 0 00.225-.358 4.671 4.671 0 00-1.949-2.078zm-4.183-3.647a.064.064 0 01.023-.05.068.068 0 01.053-.015 4.826 4.826 0 00.782.065 5.086 5.086 0 002.547-.7.249.249 0 01.364.145 1.925 1.925 0 11-3.769.552z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});