define("ember-svg-jar/inlined/button-loop-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-loop-arrow</title><path d=\"M15.243 15.333a1.25 1.25 0 10-1.768 1.767 6.042 6.042 0 008.536 0 6.108 6.108 0 000-8.537 6.051 6.051 0 00-6.854-1.187 5.929 5.929 0 00-2.294 2.032l-3.777 5.418a3.49 3.49 0 01-1.314 1.2 3.545 3.545 0 01-4.016-.695A3.524 3.524 0 015.38 9.414a.1.1 0 01.109.153 1 1 0 00.834 1.553 1.023 1.023 0 00.2-.02l3.318-.672a1 1 0 00.782-1.179l-.678-3.318a1 1 0 00-1.813-.353l-.779 1.176a.252.252 0 01-.244.11 6.031 6.031 0 101.734 11.424 5.948 5.948 0 002.294-2.031l3.777-5.419a3.466 3.466 0 011.314-1.2 3.546 3.546 0 014.016.7 3.612 3.612 0 010 5 3.54 3.54 0 01-5.001-.005z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});