define("ember-svg-jar/inlined/gesture-zoom-in-two-fingers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-zoom-in-two-fingers</title><path d=\"M2.165 9.3l-2 2.5a.749.749 0 000 .938l2 2.5a.751.751 0 001.335-.472v-5A.75.75 0 002.165 9.3zM23.835 11.8l-2-2.5a.75.75 0 00-1.335.469v5a.75.75 0 001.335.469l2-2.5a.749.749 0 000-.938zM8.5 7.979a3 3 0 00-3 3v4.613a.428.428 0 00.428.428h5.143a.428.428 0 00.428-.428v-4.614A3 3 0 008.5 7.979zm1.5 4a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1a1.5 1.5 0 013 0zM15.5 8.019a3 3 0 00-3 3v4.572a.428.428 0 00.428.428h5.143a.428.428 0 00.428-.428v-4.572a3 3 0 00-2.999-3zm1.5 4a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1a1.5 1.5 0 113 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});