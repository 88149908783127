define("ember-svg-jar/inlined/saving-bag-increase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>saving-bag-increase</title><path d=\"M23.418 0h-4a.5.5 0 00-.353.854l.935.939a.25.25 0 010 .354l-5.97 5.969a1.25 1.25 0 001.77 1.768l5.97-5.97a.249.249 0 01.353 0l.94.94a.5.5 0 00.355.146.494.494 0 00.192-.038.5.5 0 00.308-.462v-4a.5.5 0 00-.5-.5zM9.259 6.379a.25.25 0 00-.189-.087H3.43a.25.25 0 00-.189.087C2.1 7.7.082 10.387.082 12.832a6.168 6.168 0 0012.336 0c0-2.445-2.018-5.132-3.159-6.453zm-1.966 9.087a.249.249 0 00-.187.242v.159a.75.75 0 01-1.5 0v-.084a.25.25 0 00-.25-.25h-.3a.75.75 0 110-1.5h1.763a.413.413 0 00.152-.8l-1.787-.715A1.907 1.907 0 015.418 8.9a.249.249 0 00.188-.242V8.5a.75.75 0 011.5 0v.083a.249.249 0 00.25.25h.3a.75.75 0 110 1.5H5.894a.413.413 0 00-.154.8l1.789.716a1.907 1.907 0 01-.236 3.621zM10.675 2.425a.866.866 0 00-1.05-1.293 1.819 1.819 0 01-2-.386l-.3-.3a1.513 1.513 0 00-2.138 0l-.3.3a1.819 1.819 0 01-2 .386.866.866 0 00-1.05 1.293l1.582 2.26a.251.251 0 00.205.107h5.264a.251.251 0 00.205-.107z\"/><circle cx=\"17.918\" cy=\"19.75\" r=\"1.25\"/><path d=\"M21.918 15.5h-8a2 2 0 00-2 2V22a2 2 0 002 2h8a2 2 0 002-2v-4.5a2 2 0 00-2-2zm0 6.25a.25.25 0 01-.25.25h-7.5a.25.25 0 01-.25-.25v-4a.25.25 0 01.25-.25h7.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});