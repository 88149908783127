define("ember-svg-jar/inlined/baseball-bat-ball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baseball-bat-ball</title><circle cx=\"18.84\" cy=\"18.329\" r=\"2.695\"/><path d=\"M1.887 19.871A1.078 1.078 0 10.363 21.4l2.287 2.284a1.079 1.079 0 101.526-1.525zM5.523 16.942a.5.5 0 00-.707 0L3.3 18.457a.5.5 0 000 .707l1.58 1.579a.5.5 0 00.707 0L7.1 19.23a.5.5 0 000-.708zM22.946 1.007a3.438 3.438 0 00-5.134.306L6.5 15.163a.5.5 0 00.034.67l1.679 1.677a.5.5 0 00.673.032L22.664 6.123a3.44 3.44 0 00.282-5.116z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});