define("ember-svg-jar/inlined/audio-file-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file-search</title><path d=\"M10.168 19.787a.241.241 0 01-.03-.3 5.515 5.515 0 10-1.688 1.684.241.241 0 01.3.03l2.506 2.506a1 1 0 001.414-1.414zm-4.668.246a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5zM14 13.588a2.246 2.246 0 002.233-2.088.734.734 0 00.017-.084V6.247c0-.141.178-.18.271-.122l1.08.68a.75.75 0 10.8-1.269l-1.818-1.141A1.251 1.251 0 0014.75 5.5v3.4a.246.246 0 01-.294.244 2.029 2.029 0 00-1.47.221 2.314 2.314 0 00-1.229 1.8A2.253 2.253 0 0014 13.588z\"/><path d=\"M23.415 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.082a.5.5 0 00.442.489 6.9 6.9 0 01.915.187A.5.5 0 008 9.293V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-8.942a.493.493 0 00-.463.337 6.883 6.883 0 01-.36.841.267.267 0 00.033.26l.483.483a.354.354 0 00.251.078H22a2 2 0 002-2V4.414A2 2 0 0023.415 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});