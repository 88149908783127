define("ember-svg-jar/inlined/crypto-currency-bitcoin-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-lock</title><path d=\"M10.75 19.5a.25.25 0 00.25.25h1.5a1.25 1.25 0 000-2.5H11a.25.25 0 00-.25.25zM11 13.25a.25.25 0 00-.25.25v2a.25.25 0 00.25.25h1.5a1.25 1.25 0 000-2.5z\"/><path d=\"M18.954 9.055a.251.251 0 01-.2-.246V6.75a6.75 6.75 0 00-13.5 0v2.059a.251.251 0 01-.2.246A2.491 2.491 0 003 11.5v10A2.5 2.5 0 005.5 24h13a2.5 2.5 0 002.5-2.5v-10a2.491 2.491 0 00-2.046-2.445zM9.25 12.5a.75.75 0 01.75-.75h.5a.25.25 0 00.25-.25.75.75 0 011.5 0 .25.25 0 00.25.25 2.752 2.752 0 012.75 2.75 2.723 2.723 0 01-.71 1.832.25.25 0 000 .335 2.742 2.742 0 01-2.04 4.583.25.25 0 00-.25.25.75.75 0 01-1.5 0 .25.25 0 00-.25-.25H10a.75.75 0 01-.75-.75zm-1.5-5.75a4.25 4.25 0 018.5 0v2A.25.25 0 0116 9H8a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});