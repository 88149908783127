define("ember-svg-jar/inlined/grid-dot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>grid-dot</title><path d=\"M21 0H3a3 3 0 00-3 3v18a3 3 0 003 3h18a3 3 0 003-3V3a3 3 0 00-3-3zm1 21a1 1 0 01-1 1H3a1 1 0 01-1-1V3a1 1 0 011-1h18a1 1 0 011 1z\"/><circle cx=\"5.25\" cy=\"18.75\" r=\"1.5\"/><circle cx=\"9.75\" cy=\"18.75\" r=\"1.5\"/><circle cx=\"14.25\" cy=\"18.75\" r=\"1.5\"/><circle cx=\"18.75\" cy=\"18.75\" r=\"1.5\"/><circle cx=\"5.25\" cy=\"12.25\" r=\"1.5\"/><circle cx=\"9.75\" cy=\"12.25\" r=\"1.5\"/><circle cx=\"14.25\" cy=\"12.25\" r=\"1.5\"/><circle cx=\"18.75\" cy=\"12.25\" r=\"1.5\"/><circle cx=\"7.5\" cy=\"15.5\" r=\"1.5\"/><circle cx=\"12\" cy=\"15.5\" r=\"1.5\"/><circle cx=\"16.5\" cy=\"15.5\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});