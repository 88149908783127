define("ember-svg-jar/inlined/hat-sombrero", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hat-sombrero</title><path d=\"M5.478 24c-3.3 0-5.4-2.129-5.477-4.259a1.6 1.6 0 01.584-1.26A2.079 2.079 0 011.938 18h20.124a2.079 2.079 0 011.353.481 1.6 1.6 0 01.585 1.26C23.926 21.871 21.821 24 18.522 24zM8.984 10.183a.25.25 0 00.369.005l2.073-2.2a.75.75 0 011.092 0l2.073 2.2a.25.25 0 00.369-.005l1.951-2.2a1 1 0 00.236-.835C16.231 1.872 15.4 0 11.972 0S7.713 1.872 6.8 7.145a1 1 0 00.236.835z\"/><path d=\"M17.623 10.057a.25.25 0 00-.434-.127L15.357 12a.753.753 0 01-.551.252H14.8a.75.75 0 01-.546-.236l-2.1-2.226a.25.25 0 00-.182-.079.247.247 0 00-.182.079l-2.1 2.226a.854.854 0 01-.557.236.747.747 0 01-.546-.252L6.755 9.929a.247.247 0 00-.255-.074.251.251 0 00-.177.2 505.673 505.673 0 01-.959 5.859.5.5 0 00.492.586H18.09a.5.5 0 00.492-.586 509.48 509.48 0 01-.959-5.857z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});