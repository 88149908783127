define("ember-svg-jar/inlined/shipment-subtract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-subtract</title><path d=\"M8.5 3.75a.25.25 0 00.25.25h5.35a.25.25 0 00.177-.427L11.4.689A1.511 1.511 0 0010.336.25H8.75A.25.25 0 008.5.5zM6.75 4A.249.249 0 007 3.75V.5a.249.249 0 00-.25-.25H5.165A1.511 1.511 0 004.1.689L1.22 3.573A.25.25 0 001.4 4zM15.069 9.562a.248.248 0 00.182-.24V5.75A.25.25 0 0015 5.5H.5a.25.25 0 00-.25.25v9a1.5 1.5 0 001.5 1.5H9.1a.25.25 0 00.247-.212 8.01 8.01 0 015.722-6.476z\"/><path d=\"M17.25 10.75a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-2.5 5.75h5a.75.75 0 010 1.5h-5a.75.75 0 010-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});