define("ember-svg-jar/inlined/christmas-tree-top-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-tree-top-star</title><path d=\"M17.5 8.75h-2.626L13.35 5.6a1.5 1.5 0 00-2.7 0L9.126 8.75H6.5a1.5 1.5 0 00-1.069 2.55l2.226 2.266-1.5 3.462a1.5 1.5 0 002.113 1.906l.992-.559a.25.25 0 01.367.27l-.799 3.707a.753.753 0 00.344.8A5.461 5.461 0 0012 24a5.452 5.452 0 002.825-.844.761.761 0 00.345-.8l-.794-3.7a.25.25 0 01.367-.27l.993.56a1.5 1.5 0 002.112-1.906l-1.5-3.463L18.57 11.3a1.5 1.5 0 00-1.07-2.55zm-1.609 2.425l-1.447 1.472a1 1 0 00-.2 1.1l.883 2.033a.25.25 0 01-.352.317l-2.28-1.285a1 1 0 00-.982 0L9.229 16.1a.25.25 0 01-.352-.317l.883-2.033a1 1 0 00-.2-1.1l-1.451-1.475a.25.25 0 01.179-.425h1.465a1 1 0 00.9-.564l1.122-2.321a.249.249 0 01.45 0l1.122 2.321a1 1 0 00.9.564h1.466a.25.25 0 01.178.425zM12 3.75a1 1 0 001-1V1a1 1 0 00-2 0v1.75a1 1 0 001 1zM5.2 5.718A1 1 0 006.617 4.3l-1.25-1.25a1 1 0 00-1.414 1.418zM18.16 6.011a1 1 0 00.707-.293l1.25-1.25A1 1 0 0018.7 3.054L17.453 4.3a1 1 0 00.707 1.707zM3 9.25H1a1 1 0 000 2h2a1 1 0 000-2zM23 9.25h-2a1 1 0 000 2h2a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});