define("ember-svg-jar/inlined/amusement-park-disneyland", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>amusement-park-disneyland</title><path d=\"M9 13.5h6a.5.5 0 00.379-.825L12.741 9.6a1 1 0 01-.241-.65v-.88a.5.5 0 01.243-.428l1.582-.95a.749.749 0 00-.107-1.339l-2.032-.813a.5.5 0 00-.466.05.5.5 0 00-.22.41v3.946a1 1 0 01-.241.65l-2.638 3.079A.5.5 0 009 13.5zM2.075 16.263a.5.5 0 00.425.237h4a.5.5 0 00.447-.724l-1.842-3.683A1 1 0 015 11.646a.639.639 0 01.311-.549l1.514-.909a.749.749 0 00-.107-1.339l-2.032-.813A.5.5 0 004 8.5v3.146a.993.993 0 01-.106.447l-1.841 3.683a.5.5 0 00.022.487zM21.718 8.849l-2.032-.813A.5.5 0 0019 8.5v3.146a.993.993 0 01-.106.447l-1.841 3.683a.5.5 0 00.447.724h4a.5.5 0 00.447-.724l-1.842-3.683a1 1 0 01-.105-.447.639.639 0 01.311-.549l1.514-.909a.749.749 0 00-.107-1.339zM18.538 3.349A.75.75 0 0019.6 2.288l-1.06-1.06a.75.75 0 00-1.061 1.06zM21.72 4.409a.75.75 0 00-1.061 1.061l1.061 1.06a.75.75 0 001.06-1.06zM21.189 3.568a.751.751 0 00.531-.219l1.06-1.061a.75.75 0 00-1.06-1.06l-1.061 1.06a.75.75 0 00.53 1.28zM18.007 6.75a.748.748 0 00.531-.22L19.6 5.47a.75.75 0 00-1.06-1.061L17.477 5.47a.75.75 0 00.53 1.28zM4.932 2.561a.748.748 0 00.53-.22L6.523 1.28A.75.75 0 005.462.22L4.4 1.28a.751.751 0 00.53 1.281zM3.341 4.462A.75.75 0 102.28 3.4L1.22 4.462a.75.75 0 101.06 1.06zM5.462 5.522a.75.75 0 101.061-1.06L5.462 3.4A.75.75 0 004.4 4.462zM2.28 2.341A.75.75 0 103.341 1.28L2.28.22a.75.75 0 00-1.06 1.06z\"/><path d=\"M21.5 17.5h-4a.5.5 0 00-.5.5v2.5a.5.5 0 01-.5.5H16a.5.5 0 01-.5-.5V15a.5.5 0 00-.5-.5H9a.5.5 0 00-.5.5v5.5a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5V18a.5.5 0 00-.5-.5h-4a.5.5 0 00-.5.5v5.5a.5.5 0 00.5.5h19a.5.5 0 00.5-.5V18a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});