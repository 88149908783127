define("ember-svg-jar/inlined/style-three-pin-calculator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-calculator</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm4.5 13.5A2.5 2.5 0 0114 16h-4a2.5 2.5 0 01-2.5-2.5V6A1.5 1.5 0 019 4.5h6A1.5 1.5 0 0116.5 6z\"/><rect x=\"8.5\" y=\"5.5\" width=\"7\" height=\"2.5\" rx=\".5\" ry=\".5\"/><circle cx=\"10\" cy=\"10.5\" r=\"1\"/><circle cx=\"14\" cy=\"10.5\" r=\"1\"/><circle cx=\"10\" cy=\"13.5\" r=\"1\"/><circle cx=\"14\" cy=\"13.5\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});