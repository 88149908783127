define("ember-svg-jar/inlined/paragraph-indent-last-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paragraph-indent-last-line</title><path d=\"M10 21H1a1 1 0 000 2h9a1 1 0 000-2zM1 3h22a1 1 0 000-2H1a1 1 0 000 2zM23 6H1a1 1 0 000 2h22a1 1 0 000-2zM20 11H1a1 1 0 000 2h19a2 2 0 010 4h-2.25a.25.25 0 01-.25-.25v-1.71a.5.5 0 00-.8-.4l-4 2.942a.5.5 0 00-.005.8l4 3.016a.5.5 0 00.3.1.491.491 0 00.223-.053A.5.5 0 0017.5 21v-1.75a.25.25 0 01.25-.25H20a4 4 0 000-8z\"/><path d=\"M10 16H1a1 1 0 000 2h9a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});