define("ember-svg-jar/inlined/underwear-bra", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>underwear-bra</title><path d=\"M22.688 10.657a1 1 0 01-.188-.582V3.25a1 1 0 00-2 0v6.231a1 1 0 01-.493.862 29.253 29.253 0 00-7.62 6.67.5.5 0 01-.777 0 29.8 29.8 0 00-7.619-6.672 1 1 0 01-.491-.859V3.25a1 1 0 00-2 0v6.824a1 1 0 01-.188.583A7.611 7.611 0 000 15.072a6.686 6.686 0 006.679 6.678 6.851 6.851 0 004.957-2.128.5.5 0 01.727 0 6.85 6.85 0 004.957 2.128A6.687 6.687 0 0024 15.072a7.62 7.62 0 00-1.312-4.415z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});