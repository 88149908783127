define("ember-svg-jar/inlined/tv-flat-screen-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tv-flat-screen-disable</title><path d=\"M22.5 1.5h-21A1.5 1.5 0 000 3v13.5A1.5 1.5 0 001.5 18h8.75a.25.25 0 01.25.25v2a.25.25 0 01-.25.25H7a1 1 0 000 2h10a1 1 0 000-2h-3.25a.25.25 0 01-.25-.25v-2a.25.25 0 01.25-.25h8.75a1.5 1.5 0 001.5-1.5V3a1.5 1.5 0 00-1.5-1.5zM22 15.615a.386.386 0 01-.385.385H2.385A.386.386 0 012 15.615V3.885a.386.386 0 01.385-.385h19.23a.386.386 0 01.385.385z\"/><path d=\"M12 4.5a5.25 5.25 0 105.25 5.25A5.256 5.256 0 0012 4.5zm-3.041 6.364a3.1 3.1 0 01-.017-2.191A3.307 3.307 0 0110.908 6.7a3.1 3.1 0 012.206.011.253.253 0 01.1.416l-3.839 3.834a.254.254 0 01-.416-.097zm6.1-.034a3.3 3.3 0 01-1.972 1.975 3.094 3.094 0 01-2.2-.014.253.253 0 01-.1-.416l3.837-3.836a.253.253 0 01.416.1 3.093 3.093 0 01.017 2.191z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});