define("ember-svg-jar/inlined/christmas-postcard-envelope", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-postcard-envelope</title><path d=\"M23.134 10.138L19.421 7.5A1 1 0 0119 6.685V1.5A1.5 1.5 0 0017.5 0h-11A1.5 1.5 0 005 1.5v5.185a1 1 0 01-.421.815L.891 10.121A2 2 0 000 11.785V21.5A2.5 2.5 0 002.5 24h19a2.5 2.5 0 002.5-2.5v-9.715a1.982 1.982 0 00-.866-1.647zm-4.134 0a.25.25 0 01.395-.2l1.461 1.038a.25.25 0 01-.015.417l-1.461.885a.25.25 0 01-.38-.213zM8.165 6.349a.519.519 0 01-.129.017.5.5 0 01-.129-.983l.641-.172a.249.249 0 00.177-.306l-.172-.641a.5.5 0 11.967-.259l.259.966a.5.5 0 00.232.3l1.114.643A.25.25 0 0011.5 5.7V4.414a.5.5 0 00-.146-.353l-.708-.707a.5.5 0 01.708-.708l.469.47a.25.25 0 00.354 0l.469-.47a.5.5 0 01.708.708l-.708.707a.5.5 0 00-.146.353V5.7a.25.25 0 00.375.216l1.114-.643a.5.5 0 00.232-.3l.259-.966a.5.5 0 11.967.259l-.172.641a.249.249 0 00.177.306l.641.172a.5.5 0 01-.129.983.519.519 0 01-.129-.017l-.966-.259a.5.5 0 00-.38.05l-1.114.643a.251.251 0 000 .434l1.114.643a.5.5 0 00.38.05l.966-.259a.5.5 0 01.258.966l-.641.172a.249.249 0 00-.177.306l.172.641a.5.5 0 01-.353.613.534.534 0 01-.13.017.5.5 0 01-.484-.371l-.259-.966a.5.5 0 00-.232-.3l-1.114-.643a.25.25 0 00-.375.212v1.286a.5.5 0 00.146.353l.708.707a.5.5 0 01-.708.708l-.469-.47a.25.25 0 00-.354 0l-.469.47a.5.5 0 01-.708-.708l.708-.707a.5.5 0 00.146-.353V8.3a.25.25 0 00-.375-.216l-1.114.643a.5.5 0 00-.232.3l-.259.966a.5.5 0 01-.484.371.534.534 0 01-.13-.017.5.5 0 01-.353-.613l.172-.634a.249.249 0 00-.177-.306l-.641-.172a.5.5 0 01.258-.966l.966.259a.5.5 0 00.38-.05l1.114-.643a.251.251 0 000-.434L9.511 6.14a.5.5 0 00-.38-.05zm-5.021 4.624l1.461-1.038a.251.251 0 01.26-.018.248.248 0 01.135.222v1.923a.25.25 0 01-.38.214l-1.461-.885a.251.251 0 01-.015-.418zM22 21.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5l.02-8.018a.251.251 0 01.38-.213l4.661 2.824a.25.25 0 01.01.421L4.6 18.182a1 1 0 001.12 1.658l3.58-2.419a1 1 0 01.56-.171h4.253a1 1 0 01.577.183l3.391 2.395a1 1 0 101.154-1.634l-2.35-1.66a.25.25 0 01.014-.418l4.719-2.859a.25.25 0 01.38.214z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});