define("ember-svg-jar/inlined/golf-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>golf-player</title><path d=\"M10.021.671a2.079 2.079 0 00-2.012-.3C6.94.716 6.3 1.609 6.556 2.41a1.263 1.263 0 00.651.745 1.984 1.984 0 00.912.208 2.628 2.628 0 00.8-.127 2.557 2.557 0 00.457-.2.248.248 0 01.3.042l4.379 4.392a.989.989 0 00.965.246l.055-.016a.972.972 0 00.413-1.625L10.21.8a.972.972 0 00-.189-.129z\"/><circle cx=\"8\" cy=\"7.251\" r=\"2.25\"/><path d=\"M8.578 11.272a1.5 1.5 0 00-.051.747v.025l.828 4.141a.5.5 0 01-.086.392l-3.482 4.791a1.5 1.5 0 102.426 1.765l3.487-4.792a3.268 3.268 0 00.379-.69.249.249 0 01.431-.056l.19.253a.505.505 0 01.08.442l-.831 2.827a1.5 1.5 0 102.877.845l.832-2.826a3.516 3.516 0 00-.558-3.088l-2.372-3.163a.247.247 0 01-.037-.23.251.251 0 01.168-.161l4.555-1.3a1.5 1.5 0 10-.824-2.885l-7 2a1.5 1.5 0 00-1.012.963z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});