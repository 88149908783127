define("ember-svg-jar/inlined/award-trophy-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>award-trophy-1</title><path d=\"M15.155 18.679a.249.249 0 00.243.3h.042a.25.25 0 00.182-.078l3.956-4.184a1.841 1.841 0 00-.244-2.573 3.013 3.013 0 00-2.423-.61.249.249 0 00-.213.193zM7.3 11.747a.249.249 0 00-.215-.194 3.117 3.117 0 00-2.413.585 1.85 1.85 0 00-.22 2.626l3.918 4.141a.25.25 0 00.182.078H8.6a.249.249 0 00.244-.3zM9.246 20.5A6.178 6.178 0 005.3 22.293 1 1 0 006 24h11.99a1 1 0 00.718-1.693A6 6 0 0014.75 20.5c-.471-.026-5.009-.031-5.504 0z\"/><circle cx=\"11.998\" cy=\"2.997\" r=\"2.997\"/><path d=\"M15.769 9.005a.5.5 0 00-.669-.579 8.29 8.29 0 01-6.208-.008.5.5 0 00-.667.582l2.175 9.79a.25.25 0 00.244.2h2.708a.248.248 0 00.243-.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});