define("ember-svg-jar/inlined/hierarchy-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hierarchy-3</title><path d=\"M24 12.5a1.5 1.5 0 00-1.5-1.5h-7.25a.25.25 0 01-.25-.25V9a2.5 2.5 0 00-2.5-2.5h-4A.5.5 0 018 6V4.249A.25.25 0 018.25 4H12a1.5 1.5 0 001.5-1.5v-1A1.5 1.5 0 0012 0H1.5A1.5 1.5 0 000 1.5v1A1.5 1.5 0 001.5 4h4.25a.25.25 0 01.25.25V6a2.5 2.5 0 002.5 2.5h4a.5.5 0 01.5.5v1.75a.25.25 0 01-.25.25H12a1.5 1.5 0 00-1.5 1.5v1A1.5 1.5 0 0012 15h5.25a.25.25 0 01.25.25v5.25a.5.5 0 01-.5.5h-3.445a.22.22 0 01-.2-.127A1.5 1.5 0 0012 20H1.5A1.5 1.5 0 000 21.5v1A1.5 1.5 0 001.5 24H12a1.5 1.5 0 001.356-.873.218.218 0 01.2-.127H17a2.5 2.5 0 002.5-2.5v-5.25a.25.25 0 01.25-.25h2.75a1.5 1.5 0 001.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});