define("ember-svg-jar/inlined/loading-half", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>loading-half</title><circle cx=\"20.25\" cy=\"7.498\" r=\"3.5\"/><circle cx=\"13.25\" cy=\"3.498\" r=\"3\"/><circle cx=\"6.25\" cy=\"5.498\" r=\"3\"/><circle cx=\"2.75\" cy=\"11.498\" r=\"2.5\"/><circle cx=\"4.25\" cy=\"17.498\" r=\"2.5\"/><circle cx=\"9.25\" cy=\"21.498\" r=\"2\"/><circle cx=\"14.625\" cy=\"21.644\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});