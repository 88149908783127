define("ember-svg-jar/inlined/cake-sifter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cake-sifter</title><path d=\"M11 5.75a1 1 0 01-1-1A2.427 2.427 0 007.426 2a2.6 2.6 0 00-1.918.844 1 1 0 01-.926.3 1.456 1.456 0 00-1.131.192A1.881 1.881 0 003 4.75a1 1 0 01-2 0 3.606 3.606 0 011.182-2.962A3.215 3.215 0 014.43 1.11a4.567 4.567 0 013-1.11A4.416 4.416 0 0112 4.75a1 1 0 01-1 1zM2.5 18.5a1 1 0 01-1-1V16a1 1 0 012 0v1.5a1 1 0 01-1 1zM2.5 23a1 1 0 01-1-1v-1.5a1 1 0 012 0V22a1 1 0 01-1 1zM10.5 18.5a1 1 0 01-1-1V16a1 1 0 012 0v1.5a1 1 0 01-1 1zM10.5 23a1 1 0 01-1-1v-1.5a1 1 0 012 0V22a1 1 0 01-1 1zM6.5 19.5a1 1 0 01-1-1V17a1 1 0 012 0v1.5a1 1 0 01-1 1zM6.5 24a1 1 0 01-1-1v-1.5a1 1 0 012 0V23a1 1 0 01-1 1z\"/><path d=\"M23 7H.5a.5.5 0 00-.5.5v1a6.5 6.5 0 0012.869 1.3 1 1 0 01.979-.8H23a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});