define("ember-svg-jar/inlined/content-pen-write-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-pen-write-3</title><path d=\"M8.707 19.67a1 1 0 00-1.414 0L6 20.963 4.707 19.67a1 1 0 00-1.348-.061l-3 2.5a1 1 0 00-.127 1.409 1.015 1.015 0 001.409.128l2.3-1.916 1.354 1.354a1 1 0 001.414 0L8 21.791l.793.793a1 1 0 101.414-1.414zM14.989 14.76a2.183 2.183 0 00-2.972.84c-.495.884-.319 3.72-.25 4.609a.182.182 0 00.092.146.187.187 0 00.172 0c.8-.407 3.3-1.741 3.8-2.625a2.184 2.184 0 00-.842-2.97zm-.578 2.153a.546.546 0 11-.21-.744.546.546 0 01.21.744zM22.882.651a2.178 2.178 0 00-2.308.118.969.969 0 00-.174-.15L20.08.44a2.461 2.461 0 00-3.343.944l-2.653 4.742a1 1 0 00.385 1.361A1.02 1.02 0 0015.83 7.1l2.654-4.742a.456.456 0 01.619-.176l.318.179L14.1 11.87a1.093 1.093 0 00.42 1.486l1.906 1.066A1.092 1.092 0 0017.915 14l5.807-10.376a2.185 2.185 0 00-.84-2.973z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});