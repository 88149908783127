define("ember-svg-jar/inlined/check-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>check-2</title><path d=\"M22.455 0H1.545A1.547 1.547 0 000 1.545v20.909A1.547 1.547 0 001.545 24h20.91A1.547 1.547 0 0024 22.454V1.545A1.547 1.547 0 0022.455 0zm-3.65 7.844l-7 9.5a1.013 1.013 0 01-1.43.187l-5-4a1 1 0 111.25-1.561l4.187 3.35L17.2 6.656a1 1 0 111.61 1.187z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});