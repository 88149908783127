define("ember-svg-jar/inlined/uv-meter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>uv-meter</title><path d=\"M2 10.5a8.5 8.5 0 0116.492-2.894.252.252 0 00.175.158 8.874 8.874 0 011.7.612.25.25 0 00.351-.283A10.5 10.5 0 108.093 20.721a.25.25 0 00.283-.351 8.874 8.874 0 01-.612-1.7.25.25 0 00-.158-.175A8.514 8.514 0 012 10.5z\"/><path d=\"M5 12.9a1 1 0 101.832-.8 3.981 3.981 0 01-.332-1.6 4.09 4.09 0 01.023-.431.251.251 0 00-.072-.2L5.19 8.6a.251.251 0 00-.416.1A6.013 6.013 0 005 12.9zM10.5 4.5a5.967 5.967 0 00-3.307 1 .251.251 0 01-.315-.032l-.671-.671a1 1 0 10-1.414 1.41l4.484 4.484a.25.25 0 00.367-.015 9.143 9.143 0 011.032-1.032.25.25 0 00.015-.367L8.707 7.293a.251.251 0 01.069-.4 3.995 3.995 0 014.721.963.25.25 0 00.264.072 8.915 8.915 0 011.566-.349.251.251 0 00.2-.145.247.247 0 00-.02-.241A6 6 0 0010.5 4.5zM16.5 9a7.5 7.5 0 107.5 7.5A7.5 7.5 0 0016.5 9zm-2.75 11.5a2.253 2.253 0 01-2.25-2.25v-4.5a.75.75 0 011.5 0v4.5a.75.75 0 001.5 0v-4.5a.75.75 0 011.5 0v4.5a2.253 2.253 0 01-2.25 2.25zm7.75-5.25a8.3 8.3 0 01-1.65 4.95.75.75 0 01-1.2 0A8.3 8.3 0 0117 15.25v-1.5a.75.75 0 011.5 0v1.5a6.793 6.793 0 00.519 2.591.25.25 0 00.462 0A6.793 6.793 0 0020 15.25v-1.5a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});