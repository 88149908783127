define("ember-svg-jar/inlined/seasoning-food", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seasoning-food</title><path d=\"M20 13H1a1 1 0 00-.982 1.189 9.667 9.667 0 005.776 7.076.5.5 0 01.288.605A3.889 3.889 0 005.915 23a1 1 0 001 1h7.17a1 1 0 001-1 3.917 3.917 0 00-.166-1.13.5.5 0 01.288-.606 9.666 9.666 0 005.776-7.075A1 1 0 0020 13zM23.866 4.522L22.211.88a1.5 1.5 0 00-1.985-.745l-5.691 2.584a1.25 1.25 0 00-.621 1.656l1.862 4.1a1.252 1.252 0 001.14.733 1.239 1.239 0 00.514-.112l5.691-2.585a1.5 1.5 0 00.745-1.989z\"/><circle cx=\"10.5\" cy=\"6.5\" r=\"1.5\"/><circle cx=\"12.5\" cy=\"10\" r=\"1.5\"/><circle cx=\"8.5\" cy=\"10\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});