define("ember-svg-jar/inlined/multiple-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-home</title><path d=\"M2.21 23.983h1.958a.979.979 0 100-1.958H2.7a.49.49 0 01-.49-.491l.018-12.195a.487.487 0 01.2-.394l9.263-6.792a.489.489 0 01.579 0l9.32 6.823a.49.49 0 01.2.395l.01 12.177a.49.49 0 01-.494.49l-1.464-.013a.967.967 0 00-.988.97.98.98 0 00.971.988L21.79 24a1.961 1.961 0 001.959-1.958V9.122a1.95 1.95 0 00-.821-1.594L13.118.347a1.973 1.973 0 00-2.254.013L1.092 7.5a1.96 1.96 0 00-.841 1.6v12.925a1.959 1.959 0 001.959 1.958z\"/><circle cx=\"8.084\" cy=\"10.276\" r=\"2.448\"/><path d=\"M4.657 18.6a.492.492 0 00.49.49h.8a.244.244 0 01.244.221l.424 4.233a.489.489 0 00.487.441h1.961a.49.49 0 00.487-.441l.423-4.233a.245.245 0 01.244-.221h.8a.49.49 0 00.489-.49v-1.96a3.427 3.427 0 10-6.853 0z\"/><circle cx=\"15.916\" cy=\"10.276\" r=\"2.448\"/><path d=\"M12.49 18.6a.49.49 0 00.489.49h.8a.245.245 0 01.244.221l.423 4.233a.49.49 0 00.487.441H16.9a.489.489 0 00.487-.441l.424-4.233a.244.244 0 01.244-.221h.8a.492.492 0 00.49-.49v-1.96a3.427 3.427 0 10-6.853 0z\"/><path d=\"M2.21 23.983h1.958a.979.979 0 100-1.958H2.7a.49.49 0 01-.49-.491l.018-12.195a.487.487 0 01.2-.394l9.263-6.792a.489.489 0 01.579 0l9.32 6.823a.49.49 0 01.2.395l.01 12.177a.49.49 0 01-.494.49l-1.464-.013a.967.967 0 00-.988.97.98.98 0 00.971.988L21.79 24a1.961 1.961 0 001.959-1.958V9.122a1.95 1.95 0 00-.821-1.594L13.118.347a1.973 1.973 0 00-2.254.013L1.092 7.5a1.96 1.96 0 00-.841 1.6v12.925a1.959 1.959 0 001.959 1.958z\"/><circle cx=\"8.084\" cy=\"10.276\" r=\"2.448\"/><path d=\"M4.657 18.6a.492.492 0 00.49.49h.8a.244.244 0 01.244.221l.424 4.233a.489.489 0 00.487.441h1.961a.49.49 0 00.487-.441l.423-4.233a.245.245 0 01.244-.221h.8a.49.49 0 00.489-.49v-1.96a3.427 3.427 0 10-6.853 0z\"/><circle cx=\"15.916\" cy=\"10.276\" r=\"2.448\"/><path d=\"M12.49 18.6a.49.49 0 00.489.49h.8a.245.245 0 01.244.221l.423 4.233a.49.49 0 00.487.441H16.9a.489.489 0 00.487-.441l.424-4.233a.244.244 0 01.244-.221h.8a.492.492 0 00.49-.49v-1.96a3.427 3.427 0 10-6.853 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});