define("ember-svg-jar/inlined/web-service-virb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>web-service-virb</title><path d=\"M11.23 18.69a.5.5 0 01-.46.31H6.4a.5.5 0 01-.46-.31l-5.28-13A.5.5 0 011.12 5H4.9a.5.5 0 01.47.32l2.79 7.42a.5.5 0 00.94 0l2.76-7.42a.5.5 0 01.46-.32H16a.5.5 0 01.46.69z\"/><circle cx=\"20.88\" cy=\"7.5\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});