define("ember-svg-jar/inlined/ecology-leaf-movie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ecology-leaf-movie</title><path d=\"M22 1H2a2 2 0 00-2 2v18a2 2 0 002 2h20a2 2 0 002-2V3a2 2 0 00-2-2zM2 19V5h20v14z\"/><path d=\"M16.018 7.534a.506.506 0 00-.431.028 6.509 6.509 0 01-2.665.741 6.943 6.943 0 00-2.357.579 2.739 2.739 0 00-1.651 1.983 2.17 2.17 0 00.322 1.435.5.5 0 01-.033.56 17.184 17.184 0 00-1.8 2.935.5.5 0 00.909.418c.95-2.008 2.915-4.784 5.03-5.5a.5.5 0 11.323.946 6.9 6.9 0 00-2.879 2.43.5.5 0 00.078.687 2.107 2.107 0 00.677.38 3.233 3.233 0 002.918-.772 7.305 7.305 0 001.851-6.532.5.5 0 00-.292-.318z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});