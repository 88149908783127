define("ember-svg-jar/inlined/movies-3d-glasses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>movies-3d-glasses</title><path d=\"M23.5 13H.5a.5.5 0 00-.5.5V21a3 3 0 003 3h5a2.9 2.9 0 002.061-.854l.586-.585a1.96 1.96 0 012.707 0l.585.585A2.9 2.9 0 0016 24h5a3 3 0 003-3v-7.5a.5.5 0 00-.5-.5zM22 20a1.5 1.5 0 01-1.5 1.5H18a2.76 2.76 0 01-1.768-.641l-1.464-1.218A2.141 2.141 0 0114 18v-1a1.5 1.5 0 011.5-1.5h5A1.5 1.5 0 0122 17zM6 21.5H3.5A1.5 1.5 0 012 20v-3a1.5 1.5 0 011.5-1.5h5A1.5 1.5 0 0110 17v1a2.137 2.137 0 01-.768 1.641l-1.464 1.218A2.76 2.76 0 016 21.5zM.018 10.869A.5.5 0 00.5 11.5h4A.5.5 0 005 11V5.7a1.5 1.5 0 01.329-.936l.961-1.2A1.493 1.493 0 017.461 3H8.5a1.5 1.5 0 000-3H6.328a2.48 2.48 0 00-1.767.732l-2 2a2.457 2.457 0 00-.667 1.2zM15.5 3h1.039a1.493 1.493 0 011.171.562l.961 1.2A1.5 1.5 0 0119 5.7V11a.5.5 0 00.5.5h4a.5.5 0 00.482-.631l-1.868-6.908a2.485 2.485 0 00-.673-1.227l-2-2A2.48 2.48 0 0017.672 0H15.5a1.5 1.5 0 000 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});