define("ember-svg-jar/inlined/single-woman-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-check</title><path d=\"M23.786 1.657a.735.735 0 00-.375-.19 1 1 0 00-1.112.2l-4.616 4.617a.251.251 0 01-.354 0L16.15 5.105a1 1 0 00-1.414 1.414L16.313 8.1a1.69 1.69 0 002.386 0l5.014-5.014a.984.984 0 00.274-.8.734.734 0 00-.201-.629z\"/><path d=\"M23.055 7.19a1 1 0 00-1.254.656A4.5 4.5 0 1117.506 2a1 1 0 000-2 6.5 6.5 0 106.2 8.443 1 1 0 00-.651-1.253zM11.988 17.868l-1.8-6.285A4.246 4.246 0 008.95 9.458a.5.5 0 00-.764.1l-1.755 2.76a.5.5 0 01-.846 0L3.844 9.54a.5.5 0 00-.763-.1 4.211 4.211 0 00-1.253 2.129l-1.805 6.3a.5.5 0 00.483.632h2.323a.251.251 0 01.246.2l.931 4.9A.5.5 0 004.5 24h3a.5.5 0 00.489-.4l.931-4.9a.251.251 0 01.246-.2h2.345a.5.5 0 00.482-.632zM5.984 8.531a4.25 4.25 0 10-4.25-4.25 4.255 4.255 0 004.25 4.25zm0-2a2.249 2.249 0 01-2.159-1.64.249.249 0 01.16-.3 4.67 4.67 0 001.821-1.14.249.249 0 01.356 0 4.674 4.674 0 001.822 1.14.25.25 0 01.159.3 2.247 2.247 0 01-2.159 1.64z\"/><path d=\"M23.786 1.657a.735.735 0 00-.375-.19 1 1 0 00-1.112.2l-4.616 4.617a.251.251 0 01-.354 0L16.15 5.105a1 1 0 00-1.414 1.414L16.313 8.1a1.69 1.69 0 002.386 0l5.014-5.014a.984.984 0 00.274-.8.734.734 0 00-.201-.629z\"/><path d=\"M23.055 7.19a1 1 0 00-1.254.656A4.5 4.5 0 1117.506 2a1 1 0 000-2 6.5 6.5 0 106.2 8.443 1 1 0 00-.651-1.253zM11.988 17.868l-1.8-6.285A4.246 4.246 0 008.95 9.458a.5.5 0 00-.764.1l-1.755 2.76a.5.5 0 01-.846 0L3.844 9.54a.5.5 0 00-.763-.1 4.211 4.211 0 00-1.253 2.129l-1.805 6.3a.5.5 0 00.483.632h2.323a.251.251 0 01.246.2l.931 4.9A.5.5 0 004.5 24h3a.5.5 0 00.489-.4l.931-4.9a.251.251 0 01.246-.2h2.345a.5.5 0 00.482-.632zM5.984 8.531a4.25 4.25 0 10-4.25-4.25 4.255 4.255 0 004.25 4.25zm0-2a2.249 2.249 0 01-2.159-1.64.249.249 0 01.16-.3 4.67 4.67 0 001.821-1.14.249.249 0 01.356 0 4.674 4.674 0 001.822 1.14.25.25 0 01.159.3 2.247 2.247 0 01-2.159 1.64z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});