define("ember-svg-jar/inlined/stairs-person-ascend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>stairs-person-ascend</title><circle cx=\"6.5\" cy=\"2\" r=\"2\"/><path d=\"M12.032 17.483a.037.037 0 00.005-.035l-.772-2.157a.258.258 0 00-.042-.074l-2.166-2.648A.247.247 0 019 12.411V10.1a.25.25 0 01.427-.177l1.3 1.3a.5.5 0 00.6.081l2.31-1.321a.923.923 0 00-.871-1.626L11.5 9l-1.406-2.109A2 2 0 008.43 6h-4.6a2 2 0 00-1.414.586L.146 8.854A.5.5 0 000 9.207V12a1 1 0 002 0v-1.8a.25.25 0 01.1-.2l1.5-1.179a.25.25 0 01.4.2v3.261a.2.2 0 010 .043l-1.4 8.09a.5.5 0 00.494.585h1.029a.5.5 0 00.481-.363l1.786-6.252a.25.25 0 01.408-.117l2.167 1.95a.254.254 0 01.056.074l.9 1.8a.25.25 0 00.394.071A2.465 2.465 0 0112 17.5a.037.037 0 00.032-.017zM23.5 11H20a1 1 0 00-1 1v2.5a.5.5 0 01-.5.5H16a1 1 0 00-1 1v2.5a.5.5 0 01-.5.5H12a1 1 0 00-1 1v3.5a.5.5 0 00.5.5h12a.5.5 0 00.5-.5v-12a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});