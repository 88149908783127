define("ember-svg-jar/inlined/gymnastics-acrobatic-hanging-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gymnastics-acrobatic-hanging-person</title><circle cx=\"12\" cy=\"5.5\" r=\"2.5\"/><path d=\"M16.015 9.779a.251.251 0 00-.249-.279H8.234a.251.251 0 00-.249.279A1.8 1.8 0 018 10a4.469 4.469 0 01-.539 2.131.25.25 0 00.22.369H8a1 1 0 011 1v10a.5.5 0 00.5.5H11a.5.5 0 00.5-.5v-4a.5.5 0 011 0v4a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-10a1 1 0 011-1h.319a.25.25 0 00.22-.369A4.469 4.469 0 0116 10a1.8 1.8 0 01.015-.221zM0 10a3.5 3.5 0 104.5-3.337V1a1 1 0 00-2 0v5.663A3.494 3.494 0 000 10zm5 0a1.5 1.5 0 11-1.5-1.5A1.5 1.5 0 015 10zM19.5 6.663a3.5 3.5 0 102 0V1a1 1 0 00-2 0zm1 1.837A1.5 1.5 0 1119 10a1.5 1.5 0 011.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});