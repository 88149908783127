define("ember-svg-jar/inlined/phone-action-at", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-at</title><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/><path d=\"M12.122 6.752a4.718 4.718 0 00-3.8 1.739 4.775 4.775 0 00-.947 4.109 4.693 4.693 0 003.51 3.52 4.94 4.94 0 002.337-.031.75.75 0 10-.385-1.449 3.343 3.343 0 01-1.616.018 3.226 3.226 0 01-2.383-2.39 3.254 3.254 0 013.247-4.019 3.37 3.37 0 013.165 3.435v.564a.5.5 0 01-1 0V11.5A2.25 2.25 0 1012 13.75a2.228 2.228 0 001.142-.313 2 2 0 003.608-1.187v-.564a4.877 4.877 0 00-4.628-4.934zM12 12.25a.75.75 0 11.75-.75.751.751 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});