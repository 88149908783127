define("ember-svg-jar/inlined/walking-fast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>walking-fast</title><path d=\"M20.785 12.741a2 2 0 00-2.609-1.092 2.636 2.636 0 01-2.221.179 3.387 3.387 0 01-1.223-2.136A1.981 1.981 0 0013.5 8.145c-3.541-1.467-6.021-.5-7.481.565a8.834 8.834 0 00-2.8 3.678 2 2 0 103.693 1.535 4.9 4.9 0 011.475-1.986 2.542 2.542 0 011.356-.521.25.25 0 01.258.333l-3.447 9.573a2 2 0 103.764 1.355l1.109-3.083a.252.252 0 01.234-.165.249.249 0 01.235.162l1.167 3.109a2 2 0 003.746-1.4l-1.94-5.172a.25.25 0 01.3-.328 5.72 5.72 0 001.521.2 7.911 7.911 0 003-.646 2 2 0 001.095-2.613z\"/><circle cx=\"14.435\" cy=\"3.5\" r=\"3.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});