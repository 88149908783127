define("ember-svg-jar/inlined/eraser-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>eraser-2</title><path d=\"M20.479 21.657h-15a1 1 0 100 2h15a1 1 0 000-2zM22.04 6.435L16.383.777a1.5 1.5 0 00-2.122 0L6.5 8.54a1 1 0 000 1.414l6.364 6.364a1 1 0 001.413 0l7.763-7.762a1.5 1.5 0 000-2.121zM9.185 19.657a1.825 1.825 0 001.216-.528L11.413 18a.5.5 0 00-.02-.689l-5.776-5.73a.505.505 0 00-.708 0l-2.649 2.7a2.593 2.593 0 00-.021 3.606l1.083 1.223a1.754 1.754 0 001.206.543z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});