define("ember-svg-jar/inlined/task-list-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-list-disable</title><path d=\"M6 7.749a.75.75 0 000 1.5h6a.75.75 0 000-1.5zM9.4 16.249a.241.241 0 00.238-.2 7.961 7.961 0 01.237-.954c.037-.116 0-.345-.333-.345H6a.75.75 0 100 1.5z\"/><path d=\"M9.685 19.194a.242.242 0 00-.238-.2H3.5a.5.5 0 01-.5-.5V5.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v4.08a.246.246 0 00.3.245 18.168 18.168 0 012.469-.293.249.249 0 00.231-.25V3.5A1.5 1.5 0 0016.5 2h-4a.239.239 0 01-.208-.121 3.827 3.827 0 00-6.588 0A.242.242 0 015.5 2h-4A1.5 1.5 0 000 3.5v17A1.5 1.5 0 001.5 22h8.94a.246.246 0 00.207-.379 7.953 7.953 0 01-.962-2.427z\"/><path d=\"M12.244 11.48c.09-.079-.01-.231-.244-.231H6a.75.75 0 000 1.5h4.958a.241.241 0 00.192-.1 8 8 0 011.094-1.169zM17.5 11a6.5 6.5 0 106.5 6.5 6.5 6.5 0 00-6.5-6.5zm-3.966 8.6a4.442 4.442 0 01-.368-3.319 4.5 4.5 0 013.113-3.116 4.444 4.444 0 013.325.366.252.252 0 01.051.4l-5.724 5.723a.252.252 0 01-.397-.054zm8.3-.886a4.494 4.494 0 01-3.112 3.116 4.444 4.444 0 01-3.325-.367.251.251 0 01-.052-.4l5.724-5.723a.252.252 0 01.4.051 4.445 4.445 0 01.365 3.325z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});