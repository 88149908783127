define("ember-svg-jar/inlined/medical-specialty-liver", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-specialty-liver</title><path d=\"M10.952 14.968c-.427.041-1.116.1-1.723.178a1.229 1.229 0 00-.511.226.5.5 0 00-.218.414V22.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-7.034a.5.5 0 00-.548-.5z\"/><path d=\"M23.6 2.425A2.862 2.862 0 0021.112 1h-6.819a.506.506 0 00-.293.093l-.5.36a4.674 4.674 0 00-1.727 5.31.752.752 0 01-.474.949.789.789 0 01-.238.038.748.748 0 01-.711-.513 6.153 6.153 0 011.05-5.83.25.25 0 00-.2-.407h-.7A10.512 10.512 0 000 11.5v6.429a1.942 1.942 0 001.151 1.792 2 2 0 002.166-.334 14.584 14.584 0 001.831-2.208c1.152-1.566 2.452-3.34 3.895-3.522.515-.064 1.078-.117 1.667-.173 2.555-.242 5.736-.543 7.6-2.055 3.019-2.452 3.718-3.265 5.341-6.2a2.8 2.8 0 00-.051-2.804z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});