define("ember-svg-jar/inlined/punishment-hanging-noose", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>punishment-hanging-noose</title><path d=\"M12 10.688c-3.323 0-6 3.965-6 7.25a6 6 0 0012 0c0-3.285-2.677-7.25-6-7.25zm0 11.25a4 4 0 01-4-4c0-2.315 1.884-5.25 4-5.25s4 2.935 4 5.25a4 4 0 01-4 4zM10.894 9.981l3.5-1.5a1 1 0 10-.788-1.837l-3.5 1.5a1 1 0 00-.525 1.312 1.076 1.076 0 001.313.525zM10.894 6.731l3.5-1.5a1 1 0 10-.788-1.837l-3.5 1.5a1 1 0 00-.525 1.312 1.037 1.037 0 001.313.525zM12 3.062a1 1 0 001-1v-1a1 1 0 10-2 0v1a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});