define("ember-svg-jar/inlined/exe-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>exe-1</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM7 14.876a.625.625 0 010 1.25H5.375a.25.25 0 00-.25.25V18a.875.875 0 00.875.876h2.5a.625.625 0 010 1.25H6A2.125 2.125 0 013.875 18v-5A2.124 2.124 0 016 10.876h2.5a.625.625 0 010 1.25H6a.875.875 0 00-.875.874v1.625a.25.25 0 00.25.25zm8.059 4.346a.625.625 0 11-1.118.558l-1.217-2.435a.25.25 0 00-.448 0l-1.217 2.435a.625.625 0 01-.559.346.625.625 0 01-.559-.9l1.8-3.609a.252.252 0 000-.224l-1.8-3.609a.625.625 0 111.118-.558l1.217 2.435a.251.251 0 00.448 0l1.217-2.435a.625.625 0 011.118.558l-1.8 3.609a.252.252 0 000 .224zM19 14.876a.625.625 0 010 1.25h-1.625a.25.25 0 00-.25.25V18a.875.875 0 00.875.875h2.5a.625.625 0 010 1.25H18A2.125 2.125 0 0115.875 18v-5A2.124 2.124 0 0118 10.876h2.5a.625.625 0 010 1.25H18a.875.875 0 00-.875.875v1.625a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});