define("ember-svg-jar/inlined/family-children", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-children</title><path d=\"M16.24 4.779A2.8 2.8 0 1012.281.82a.4.4 0 01-.562 0 2.8 2.8 0 00-3.966 3.951l3.31 3.454a1.3 1.3 0 001.874 0zM5.569 20.563a2.007 2.007 0 01-1.376-1.9V16.9a4.389 4.389 0 011.264-3.084.5.5 0 00.077-.6 3.515 3.515 0 01-.427-1.239.5.5 0 00-.408-.421A4 4 0 000 15.5v2.168a.5.5 0 00.5.5h.845a.5.5 0 01.5.451l.494 4.932a.5.5 0 00.5.45h2.328a.5.5 0 00.5-.45l.246-2.462a.5.5 0 00-.344-.526zM4 11a2.435 2.435 0 00.914-.177.5.5 0 00.293-.325 3.532 3.532 0 011.064-1.674.5.5 0 00.169-.4A2.444 2.444 0 104 11zM10.624 11.473A2.027 2.027 0 108.6 13.5a2.028 2.028 0 002.024-2.027z\"/><path d=\"M8.6 14a2.9 2.9 0 00-2.9 2.9v1.759a.5.5 0 00.5.5h.5a.5.5 0 01.5.45l.394 3.938a.5.5 0 00.5.45h1.03a.5.5 0 00.5-.45L10 19.612a.5.5 0 01.5-.45h.5a.5.5 0 00.5-.5V16.9A2.9 2.9 0 008.6 14zM20 11.5a4.021 4.021 0 00-.7.061.5.5 0 00-.408.421 3.515 3.515 0 01-.427 1.239.5.5 0 00.077.6 4.389 4.389 0 011.265 3.079v1.759a2.007 2.007 0 01-1.376 1.9.5.5 0 00-.341.525l.246 2.462a.5.5 0 00.5.45h2.334a.5.5 0 00.5-.45l.494-4.932a.5.5 0 01.5-.451h.836a.5.5 0 00.5-.5V15.5a4 4 0 00-4-4zM18.793 10.5a.5.5 0 00.293.325 2.444 2.444 0 10-1.526-2.4.5.5 0 00.169.4 3.532 3.532 0 011.064 1.675zM15.4 9.446a2.027 2.027 0 102.027 2.027A2.028 2.028 0 0015.4 9.446z\"/><path d=\"M18.306 18.662V16.9a2.9 2.9 0 00-5.806 0v1.759a.5.5 0 00.5.5h.5a.5.5 0 01.5.45l.394 3.938a.5.5 0 00.5.45h1.031a.5.5 0 00.5-.45l.394-3.938a.5.5 0 01.5-.45h.5a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});