define("ember-svg-jar/inlined/time-clock-circle-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-clock-circle-2-alternate</title><path d=\"M19.5 0h-15A4.505 4.505 0 000 4.5v15A4.5 4.5 0 004.5 24h15a4.5 4.5 0 004.5-4.5v-15A4.505 4.505 0 0019.5 0zm1 12A8.5 8.5 0 1112 3.5a8.5 8.5 0 018.5 8.5z\"/><path d=\"M15 11.5h-2.5V7a1 1 0 00-2 0v5.5a1 1 0 001 1H15a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});