define("ember-svg-jar/inlined/database-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-refresh</title><path d=\"M1.375 15.058v2.256c0 1.843 2.988 3.389 7.033 3.831a.25.25 0 00.26-.339 7.658 7.658 0 01-.537-2.932.251.251 0 00-.217-.254c-2.727-.357-5.214-1.22-6.539-2.562zM1.375 10.605v2.256c0 1.779 2.79 3.28 6.625 3.779a.249.249 0 00.277-.2 7.9 7.9 0 011.1-2.761.25.25 0 00-.193-.383c-3.203-.231-6.284-1.149-7.809-2.691z\"/><path d=\"M10.778 8.9c-3.731 0-7.628-.953-9.4-2.752v2.259c0 2.122 3.965 3.848 8.942 3.949a.244.244 0 00.18-.072 7.887 7.887 0 018.159-1.847.248.248 0 00.228-.033 2.59 2.59 0 001.295-2V6.152C18.4 7.949 14.506 8.9 10.778 8.9z\"/><ellipse cx=\"10.778\" cy=\"3.953\" rx=\"9.402\" ry=\"3.959\"/><path d=\"M20.506 13.065a.249.249 0 01-.348.005 6.319 6.319 0 10.624 8.558 1 1 0 10-1.562-1.249 4.32 4.32 0 11-.492-5.908.251.251 0 01.082.179.245.245 0 01-.073.183L17.37 16.2a.495.495 0 00.35.845h4.411a.5.5 0 00.494-.5V12.14a.494.494 0 00-.844-.35l-.327.326z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});