define("ember-svg-jar/inlined/smart-watch-square-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-star</title><path d=\"M16.43 2.75a.251.251 0 00.238-.329L15.975.342A.5.5 0 0015.5 0h-7a.5.5 0 00-.475.342l-.693 2.079a.251.251 0 00.238.329zM7.57 21.25a.248.248 0 00-.2.1.251.251 0 00-.035.225l.693 2.079A.5.5 0 008.5 24h7a.5.5 0 00.475-.342l.693-2.079a.251.251 0 00-.035-.225.248.248 0 00-.2-.1zM17.5 4h-11A2.5 2.5 0 004 6.5v11A2.508 2.508 0 006.5 20h11a2.5 2.5 0 002.5-2.5v-11A2.5 2.5 0 0017.5 4zm-1.964 11.074a1 1 0 01-.251 1.158 1.019 1.019 0 01-1.2.133l-1.957-1.1a.254.254 0 00-.245 0l-1.957 1.1a1.028 1.028 0 01-1.2-.133 1 1 0 01-.25-1.158l.724-1.645a.251.251 0 00-.087-.3l-1.231-.859a1.03 1.03 0 01-.3-1.154.987.987 0 01.934-.611H9.99a.249.249 0 00.233-.162l.845-2.229a1.053 1.053 0 011.856-.049.527.527 0 01.022.049l.844 2.229a.251.251 0 00.234.162h1.483a.985.985 0 01.933.611 1 1 0 01-.238 1.1l-1.3.912a.25.25 0 00-.085.305z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});