define("ember-svg-jar/inlined/coffee-mug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>coffee-mug</title><path d=\"M17.685 3.818a.5.5 0 01-.435-.5V3a1 1 0 00-1-1H1a1 1 0 00-1 1v14.5A4.505 4.505 0 004.5 22h8.25a4.493 4.493 0 004.355-3.424.5.5 0 01.432-.378 7.241 7.241 0 00.148-14.38zM3.75 16a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zm0-4a.75.75 0 01-1.5 0V8a.75.75 0 011.5 0zm14.118 3.592a.5.5 0 01-.618-.486V6.894a.5.5 0 01.618-.486 4.719 4.719 0 010 9.184z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});