define("ember-svg-jar/inlined/outdoors-swiss-knife", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-swiss-knife</title><path d=\"M3.5 13.9c.164-.015.33-.023.5-.023h5.271a.5.5 0 00.435-.253l1.52-2.667a10.833 10.833 0 00.48-9.749.994.994 0 00-.864-.59 1.015 1.015 0 00-.916.5l-6.9 12.035a.5.5 0 00.48.746zm2.9-3.17l2.739-4.815a.75.75 0 111.3.743L7.7 11.473a.75.75 0 01-1.3-.743zM20 15.38H4a4 4 0 000 8h16a4 4 0 100-8zm-15 5.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm14 0a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM18.5 13.88H20a5.453 5.453 0 012.291.5.5.5 0 00.709-.451V6.88a2.5 2.5 0 10-5 0 .5.5 0 00.4.49c.472.1 1.1.4 1.1 1.959s-.627 1.864-1.1 1.96a.5.5 0 00-.4.49v1.6a.5.5 0 00.5.501z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});