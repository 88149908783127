define("ember-svg-jar/inlined/sign-badge-bubble-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sign-badge-bubble-message</title><path d=\"M23 2.5A2.5 2.5 0 0020.5 0h-17A2.5 2.5 0 001 2.5v14A2.5 2.5 0 003.5 19h3.279l4.111 4.509a1.5 1.5 0 002.219 0L17.221 19H20.5a2.5 2.5 0 002.5-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});