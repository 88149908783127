define("ember-svg-jar/inlined/pet-tracking-cat-smartphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pet-tracking-cat-smartphone</title><path d=\"M8.89 13.542a8.043 8.043 0 00-2.047.352.5.5 0 00-.295.681l1.044 2.294a1.053 1.053 0 00.966.631 1.144 1.144 0 00.287-.038 1.058 1.058 0 00.8-1.136l-.219-2.332a.5.5 0 00-.536-.452z\"/><path d=\"M18 10.5a.5.5 0 00.447-.276l.724-1.448a.5.5 0 01.447-.276h1.07A1.814 1.814 0 0022.5 6.688V6.5A.5.5 0 0022 6c-.336 0-.573-.459-.923-1.214a3.651 3.651 0 00-1.847-2.17.5.5 0 01-.333-.405L18.7.743a.512.512 0 00-.033-.122A1 1 0 0017.743 0a1.015 1.015 0 00-.944.677l-.987 3.669A3.491 3.491 0 0112.576 6.5H5.889a2.462 2.462 0 00-1.046.238.5.5 0 01-.425 0A3.313 3.313 0 012.5 3.75 1.562 1.562 0 014 2a.485.485 0 01.5.5c0 .352-.448.605-.447.605a1 1 0 10.894 1.79A2.808 2.808 0 006.5 2.5 2.476 2.476 0 004 0 3.552 3.552 0 00.5 3.75a5.325 5.325 0 002.558 4.527.5.5 0 01.208.6c-.749 2.006-2.231 5.966-2.675 7.062a1.089 1.089 0 00.535 1.429 1.3 1.3 0 00.572.13 1.425 1.425 0 001.232-.731c.144-.243.28-.479.412-.707 1.277-2.221 2.048-3.562 6.9-3.562h.014a13.279 13.279 0 013.317.335.5.5 0 00.6-.319 2.974 2.974 0 012.8-2.016zm.75-6a.75.75 0 11-.75.75.749.749 0 01.75-.75zM22.03 12h-5.06a1.494 1.494 0 00-1.47 1.51v8.98A1.494 1.494 0 0016.97 24h5.06a1.494 1.494 0 001.47-1.51v-8.98A1.494 1.494 0 0022.03 12zM22 20.5a.508.508 0 01-.5.5h-4a.5.5 0 01-.5-.5V14a.5.5 0 01.5-.5h4a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});