define("ember-svg-jar/inlined/align-middle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>align-middle</title><path d=\"M23 12a1 1 0 00-1-1h-2.75a.25.25 0 01-.25-.25V8a1.5 1.5 0 00-1.5-1.5h-3A1.5 1.5 0 0013 8v2.75a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25V5A1.5 1.5 0 0010 3.5H7A1.5 1.5 0 005.5 5v5.75a.25.25 0 01-.25.25H2a1 1 0 000 2h3.25a.25.25 0 01.25.25V19A1.5 1.5 0 007 20.5h3a1.5 1.5 0 001.5-1.5v-5.75a.25.25 0 01.25-.25h1a.25.25 0 01.25.25V16a1.5 1.5 0 001.5 1.5h3A1.5 1.5 0 0019 16v-2.75a.25.25 0 01.25-.25H22a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});