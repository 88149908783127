define("ember-svg-jar/inlined/weather-cloud-snow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-cloud-snow</title><path d=\"M20.594 7.074a.252.252 0 01-.1-.193A7 7 0 006.838 4.84a.249.249 0 01-.22.173A5.5 5.5 0 007 16h10.5a5 5 0 003.094-8.926zM17.5 14H7a3.5 3.5 0 11.448-6.971A1 1 0 008.564 6.2 5 5 0 0118.5 7c0 .146-.007.289-.02.433a1 1 0 00.5.959A3 3 0 0117.5 14z\"/><circle cx=\"7.5\" cy=\"23\" r=\"1\"/><circle cx=\"10\" cy=\"21\" r=\"1\"/><circle cx=\"12.5\" cy=\"23\" r=\"1\"/><circle cx=\"15\" cy=\"21\" r=\"1\"/><circle cx=\"17.5\" cy=\"23\" r=\"1\"/><circle cx=\"7.5\" cy=\"19\" r=\"1\"/><circle cx=\"12.5\" cy=\"19\" r=\"1\"/><circle cx=\"17.5\" cy=\"19\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});