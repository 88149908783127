define("ember-svg-jar/inlined/breakup-couple-man-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>breakup-couple-man-woman</title><path d=\"M12.044 5.75H10.5a.749.749 0 01-.7-1l1.353-3.722a.5.5 0 00-.2-.594A2.75 2.75 0 009.146.02 3.1 3.1 0 006.5 3.233c0 2.116 2.6 4.58 4.246 5.924a.25.25 0 00.391-.1l1.141-2.967a.251.251 0 00-.234-.34z\"/><path d=\"M17.5 3.233A3.1 3.1 0 0014.854.02a2.754 2.754 0 00-1.773.394.5.5 0 00-.208.254l-1.18 3.247a.249.249 0 00.235.335H13.5a.751.751 0 01.7 1.02l-1.408 3.661a.25.25 0 00.391.284C14.841 7.859 17.5 5.379 17.5 3.233zM8.366 18.381a.251.251 0 01-.033-.425 4.4 4.4 0 10-5.167 0 .25.25 0 01-.032.425A5.754 5.754 0 000 23.5a.5.5 0 00.5.5H11a.5.5 0 00.5-.5 5.754 5.754 0 00-3.134-5.119zM5.75 16.8a2.4 2.4 0 01-2.276-3.155.25.25 0 01.372-.132 5.643 5.643 0 004 .8.253.253 0 01.211.061.25.25 0 01.081.2A2.4 2.4 0 015.75 16.8zM14.5 14.233a3.068 3.068 0 01-.7 2.352 1 1 0 101.4 1.422 4.025 4.025 0 00.418-.487.25.25 0 01.173-.1.256.256 0 01.193.059 4.24 4.24 0 005.526 0 .256.256 0 01.193-.059.25.25 0 01.173.1 3.938 3.938 0 00.418.487 1 1 0 101.406-1.422 3.068 3.068 0 01-.7-2.352 4.25 4.25 0 00-8.5 0zm2.223.027a3.409 3.409 0 00.909-.28 2.471 2.471 0 00.916-.74.251.251 0 01.4 0 2.471 2.471 0 00.916.74 3.409 3.409 0 00.909.28.25.25 0 01.2.278 2.249 2.249 0 01-4.462 0 .25.25 0 01.212-.278zM21.478 19.017a.5.5 0 00-.478-.025 4.472 4.472 0 00-2.034 1.985.251.251 0 01-.434 0 4.472 4.472 0 00-2.032-1.985.5.5 0 00-.477.025A5.251 5.251 0 0013.5 23.5a.5.5 0 00.5.5h9.5a.5.5 0 00.5-.5 5.251 5.251 0 00-2.522-4.483z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});