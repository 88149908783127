define("@gavant/ember-shopify-draggable/components/droppable-dropzone", ["exports", "@ember/component", "@gavant/ember-shopify-draggable/templates/components/droppable-dropzone", "@ember/object", "@ember/object/computed", "@ember/array", "@ember/debug"], function (_exports, _component, _droppableDropzone, _object, _computed, _array, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _droppableDropzone.default,
    classNames: ['droppable-dropzone'],
    classNameBindings: ['isOccupied:draggable-dropzone--occupied'],
    isOccupied: (0, _computed.gt)('items.length', 0),
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'items', (0, _array.A)());
    },
    registerItem(item) {
      (true && !((0, _object.get)(this, 'items.length') === 0) && (0, _debug.assert)('dropzones can only be occupied by a single item', (0, _object.get)(this, 'items.length') === 0));
      (0, _object.get)(this, 'items').pushObject(item);
    },
    deregisterItem(item) {
      (0, _object.get)(this, 'items').removeObject(item);
    }
  });
});