define("ember-svg-jar/inlined/body-care-perfume", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>body-care-perfume</title><path d=\"M15.344 20.457a.5.5 0 00-.688-.109 8.939 8.939 0 01-10.311 0 .5.5 0 00-.688.109L2.8 21.6A1.5 1.5 0 004 24h11a1.5 1.5 0 001.2-2.4zM3.75 4.5h.5a.75.75 0 000-1.5h-.5a.75.75 0 000 1.5zM2 3.75A.75.75 0 001.25 3h-.5a.75.75 0 000 1.5h.5A.75.75 0 002 3.75z\"/><path d=\"M19.5 0a4.752 4.752 0 00-4.281 2.446.5.5 0 01-.46.3H13.34a.5.5 0 01-.476-.348A2.742 2.742 0 0010.25.5h-1.5a2.73 2.73 0 00-2.58 1.834.5.5 0 00.472.666H9a.5.5 0 010 1H6.5a.5.5 0 00-.5.5v.994a.5.5 0 01-.264.441 8 8 0 107.528 0A.5.5 0 0113 5.494V4.75a.5.5 0 01.5-.5h1.259a.5.5 0 01.46.3A4.752 4.752 0 0019.5 7C21.628 7 24 5.563 24 3.5S21.628 0 19.5 0zm-3.872 10.835a.5.5 0 01-.065.455.5.5 0 01-.407.21H3.844a.5.5 0 01-.472-.664 6.494 6.494 0 0112.256 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});