define("ember-svg-jar/inlined/soft-drinks-coconut", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>soft-drinks-coconut</title><path d=\"M22.68 8.262A1.989 1.989 0 0020.82 7h-3.307a.5.5 0 01-.49-.6l.417-2.132A3 3 0 0120.351 2h1.877a1 1 0 000-2h-1.877A4.993 4.993 0 0015.5 3.787L14.926 6.6a.5.5 0 01-.49.4H3.18a1.988 1.988 0 00-1.859 1.26A11.247 11.247 0 00.5 12.5a11.5 11.5 0 0023 0 11.327 11.327 0 00-.82-4.238zm-19.356 2.3a.5.5 0 01-.461-.637q.086-.3.191-.594A.5.5 0 013.525 9h16.948a.5.5 0 01.472.333 9.71 9.71 0 01.488 2.07.5.5 0 01-.387.548 1.461 1.461 0 01-1.339-.375 3.469 3.469 0 00-4.91 0 1.492 1.492 0 01-2.09 0 3.547 3.547 0 00-4.91 0c-1.162 1.149-1.911-.045-2.911-.586a3.673 3.673 0 00-1.562-.428z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});