define("ember-svg-jar/inlined/credit-card-lock-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-lock-alternate</title><path d=\"M3 15a1 1 0 01-1-1V8a.5.5 0 01.5-.5h17a.5.5 0 01.5.5v1.151a.981.981 0 00.623.913l.021.008A.985.985 0 0022 9.159V3a3 3 0 00-3-3H3a3 3 0 00-3 3v11a3 3 0 003 3h8.7a.947.947 0 00.9-.742l.015-.058a.922.922 0 00-.868-1.2zM2 3a1 1 0 011-1h16a1 1 0 011 1v1a.5.5 0 01-.5.5h-17A.5.5 0 012 4z\"/><path d=\"M8 11H5a1 1 0 000 2h3a1 1 0 000-2zM22.714 15.515a.249.249 0 01-.214-.247V14.25a3.5 3.5 0 00-7 0v1.018a.249.249 0 01-.214.247A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.286-1.485zM17.5 14.25a1.5 1.5 0 013 0v1a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25zm1.5 5a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});