define("ember-svg-jar/inlined/bin-paper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bin-paper</title><path d=\"M22.473.869A2.387 2.387 0 0020.619 0H3.381a2.387 2.387 0 00-1.854.869 2.284 2.284 0 00-.476 1.946L3.664 22.1a.856.856 0 00.019.1A2.363 2.363 0 006 24h12a2.363 2.363 0 002.317-1.8.856.856 0 00.019-.1l2.613-19.285a2.284 2.284 0 00-.476-1.946zM20.928 2.14a.425.425 0 01.045.362l-.444 3.282a.25.25 0 01-.248.216h-3.239a.25.25 0 01-.249-.269l.261-3.5A.251.251 0 0117.3 2h3.315a.4.4 0 01.313.14zM10.037 16.769l-.224-3a.25.25 0 01.249-.269h3.876a.25.25 0 01.249.269l-.224 3a.251.251 0 01-.249.231h-3.428a.251.251 0 01-.249-.231zm3.739 2.5l-.187 2.5a.249.249 0 01-.249.231h-2.68a.249.249 0 01-.249-.231l-.187-2.5a.25.25 0 01.249-.269h3.054a.25.25 0 01.249.269zm.822-11l-.224 3a.251.251 0 01-.25.231H9.876a.251.251 0 01-.25-.231l-.224-3A.25.25 0 019.651 8h4.7a.25.25 0 01.249.269zm-5.383-2.5l-.261-3.5A.25.25 0 019.2 2h5.6a.25.25 0 01.249.269l-.261 3.5a.251.251 0 01-.25.231H9.465a.251.251 0 01-.25-.231zM7.369 11.5h-2.9a.25.25 0 01-.248-.216l-.406-3A.25.25 0 014.058 8h3.087a.25.25 0 01.249.231l.224 3a.25.25 0 01-.249.269zm-2.569 2h2.756a.251.251 0 01.249.231l.224 3A.25.25 0 017.78 17H5.209a.25.25 0 01-.248-.216l-.406-3A.25.25 0 014.8 13.5zm11.641 0H19.2a.25.25 0 01.248.284l-.406 3a.25.25 0 01-.248.216H16.22a.25.25 0 01-.249-.269l.224-3a.251.251 0 01.249-.231zm3.092-2h-2.902a.25.25 0 01-.249-.269l.224-3A.25.25 0 0116.855 8h3.087a.25.25 0 01.248.284l-.406 3a.25.25 0 01-.248.216zM3.072 2.14A.4.4 0 013.381 2H6.7a.251.251 0 01.25.231l.261 3.5A.25.25 0 016.958 6H3.719a.25.25 0 01-.248-.216L3.027 2.5c-.018-.133-.05-.244.045-.36zm2.564 19.627L5.3 19.284A.25.25 0 015.548 19h2.418a.251.251 0 01.25.231l.187 2.5a.251.251 0 01-.25.269H6a.383.383 0 01-.364-.233zM18 22h-2.153a.251.251 0 01-.25-.269l.187-2.5a.251.251 0 01.25-.231h2.418a.25.25 0 01.248.284l-.336 2.483A.383.383 0 0118 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});