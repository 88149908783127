define("ember-svg-jar/inlined/database-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-disable</title><path d=\"M8.706 21.392a.25.25 0 00.248-.36 7.949 7.949 0 01-.8-3 .25.25 0 00-.217-.231 12.8 12.8 0 01-6.154-2.211.25.25 0 00-.408.194V17.5c0 1.9 3.131 3.479 7.331 3.892zM1.375 13c0 1.772 2.737 3.272 6.526 3.8a.254.254 0 00.188-.05.251.251 0 00.095-.169 7.929 7.929 0 01.874-2.808.25.25 0 00-.2-.366c-2.825-.254-5.511-1.04-7.077-2.315a.25.25 0 00-.408.194zM10.876 9c-3.5 0-7.156-.833-9.093-2.411a.25.25 0 00-.408.194V8.5c0 2.054 3.674 3.745 8.4 3.974a.252.252 0 00.205-.09 7.958 7.958 0 019.3-2.224.251.251 0 00.272-.048 2.259 2.259 0 00.823-1.612V6.785a.25.25 0 00-.408-.194C18.03 8.168 14.38 9 10.876 9z\"/><ellipse cx=\"10.876\" cy=\"4\" rx=\"9.5\" ry=\"4\"/><path d=\"M16.125 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm0 11a4.426 4.426 0 01-2.1-.532.253.253 0 01-.129-.184.249.249 0 01.071-.213l5.733-5.727a.251.251 0 01.4.059 4.422 4.422 0 01.532 2.1A4.5 4.5 0 0116.125 22zm0-9a4.424 4.424 0 012.1.533.25.25 0 01.13.183.253.253 0 01-.071.214l-5.728 5.727a.251.251 0 01-.4-.059 4.423 4.423 0 01-.532-2.1A4.505 4.505 0 0116.125 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});