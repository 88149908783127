define("ember-svg-jar/inlined/database-edit-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-edit-alternate</title><path d=\"M8.01 22.288a1 1 0 001.139-.788.986.986 0 00-.822-1.17c-3.41-.5-5.47-1.8-5.47-2.753v-.761a.249.249 0 01.373-.216 16.176 16.176 0 006.531 1.678 1 1 0 00.767-.292l.008-.008a.99.99 0 00-.647-1.686c-4.407-.272-7.031-1.869-7.031-2.92v-.761a.249.249 0 01.372-.218 17.445 17.445 0 008.032 1.731 21.99 21.99 0 003.064-.218 1 1 0 00.569-.288.99.99 0 00-.852-1.678 20.406 20.406 0 01-2.781.184c-5.13 0-8.4-1.753-8.4-2.959V8.4a.25.25 0 01.368-.22 17.437 17.437 0 008.033 1.732A17.425 17.425 0 0019.3 8.179a.25.25 0 01.372.215v.3a.963.963 0 00.928.964h.022a.994.994 0 001.042-.981V4.953c0-3.222-5.36-4.96-10.4-4.96S.871 1.728.86 4.943v12.631c-.002 2.526 3.295 4.135 7.15 4.714zm3.253-20.3c5.126 0 8.4 1.75 8.4 2.957v.009c-.011 1.206-3.281 2.953-8.4 2.953s-8.4-1.753-8.4-2.959 3.27-2.955 8.4-2.955zM14.187 22.282L12 20.09a.5.5 0 00-.834.215l-.877 3.07a.5.5 0 00.618.618l3.069-.877a.5.5 0 00.216-.834z\"/><path d=\"M17.966 13.341a.5.5 0 00-.707 0L12.3 18.3a.5.5 0 000 .707l2.97 2.97a.5.5 0 00.707 0l4.96-4.96a.5.5 0 000-.707zM18.661 11.939a.5.5 0 000 .709l2.97 2.97a.536.536 0 00.377.124.582.582 0 00.376-.17 2.6 2.6 0 000-3.677 2.636 2.636 0 00-3.723.044z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});