define("ember-svg-jar/inlined/messages-bubble-square-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-sync</title><path d=\"M17.45 9.5a8.179 8.179 0 013.688.877.249.249 0 00.362-.223V1.5A1.5 1.5 0 0020 0H1.5A1.5 1.5 0 000 1.5v12.053A1.459 1.459 0 001.5 15H3v3.5a.5.5 0 00.832.374L8.19 15h1.317a.25.25 0 00.234-.161A8.255 8.255 0 0117.45 9.5zM22.769 18.6a1 1 0 00-1.27.622 4.093 4.093 0 01-7.074 1.238l1.886-1.885a.5.5 0 00-.354-.853H11.5a.5.5 0 00-.5.5v4.458a.5.5 0 00.854.353l1.155-1.155a6.088 6.088 0 0010.383-2.007 1 1 0 00-.623-1.271z\"/><path d=\"M21.936 13.091a6.1 6.1 0 00-10.369 2.038 1 1 0 001.893.648 4.094 4.094 0 017.054-1.264l-1.825 1.825a.5.5 0 00.354.853H23.5a.5.5 0 00.5-.5v-4.457a.5.5 0 00-.309-.462.49.49 0 00-.486.078c-.018.014-.042.015-.059.031z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});