define("ember-svg-jar/inlined/navigation-car-pin-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-car-pin-1</title><circle cx=\"9\" cy=\"22\" r=\"2\"/><path d=\"M19.5 20.5h-.25a.25.25 0 01-.25-.25V18.5a3.5 3.5 0 00-3.5-3.5h-1.247a.252.252 0 01-.19-.087L10.108 10.3A2.994 2.994 0 007.83 9.25h-.095a.25.25 0 01-.208-.111l-.611-.916A.5.5 0 006.5 8H2.25a.5.5 0 00-.416.223l-.611.916a.25.25 0 01-.208.111H1a1 1 0 000 2h1.5a.25.25 0 01.25.25v3.25a.25.25 0 01-.25.25h-2a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h5.259a.251.251 0 00.25-.231 3 3 0 015.982 0 .25.25 0 00.25.231H19.5a.75.75 0 000-1.5zm-8.312-5.646a.248.248 0 01-.227.146H5a.25.25 0 01-.25-.25V11.5a.25.25 0 01.25-.25h2.83a1 1 0 01.759.349l2.562 2.988a.249.249 0 01.037.267zM19 13a.5.5 0 00.354-.146C19.543 12.664 24 8.171 24 5a5 5 0 00-10 0c0 3.171 4.457 7.664 4.646 7.854A.5.5 0 0019 13zm-1.75-8A1.75 1.75 0 1119 6.75 1.752 1.752 0 0117.25 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});