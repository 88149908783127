define("ember-svg-jar/inlined/yoga-bridge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>yoga-bridge</title><circle cx=\"9\" cy=\"13.497\" r=\"2.5\"/><path d=\"M15.013 5.313a.5.5 0 00-.4-.58 13.4 13.4 0 00-2.48-.236 13.612 13.612 0 00-8.316 2.854l-1.238.962a1.5 1.5 0 00-.556.923l-.171.968a.5.5 0 00.351.566l1.929.572a.5.5 0 00.635-.392l.043-.244a1 1 0 01.371-.615l.477-.372A10.6 10.6 0 0112.134 7.5a10.252 10.252 0 011.938.189.5.5 0 00.587-.4c.099-.559.257-1.435.354-1.976zM23.115 9.994a14.56 14.56 0 00-6.5-4.719.5.5 0 00-.416.039.5.5 0 00-.242.343l-.362 1.991a.5.5 0 00.313.557 10.527 10.527 0 014.063 2.783c.279.311.525.519.525.968V18a1.5 1.5 0 003 0v-7a1.5 1.5 0 00-.381-1.006zM2.139 11.784a.5.5 0 00-.635.393l-.981 5.559a1.5 1.5 0 102.955.522l.941-5.334a.5.5 0 00-.35-.566z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});