define("ember-svg-jar/inlined/hardware-wench-screw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hardware-wench-screw</title><path d=\"M15.744 9.091a1 1 0 001-1v-.25a.249.249 0 01.25-.249h.5a.749.749 0 000-1.5h-.5a.25.25 0 01-.25-.25v-.5a.249.249 0 01.25-.249h.5a.749.749 0 000-1.5h-.5a.25.25 0 01-.25-.249v-.5a.25.25 0 01.25-.249h1.247a1 1 0 100-2h-4.993a1 1 0 100 2H14.5a.249.249 0 01.249.249v.5a.249.249 0 01-.249.256H14a.749.749 0 000 1.5h.5a.248.248 0 01.249.249v.5a.249.249 0 01-.249.25H14a.749.749 0 000 1.5h.5a.248.248 0 01.249.249v.25a1 1 0 00.995.993zM16.381 20.673a.249.249 0 01.165.312l-.075.238a1 1 0 001.907.592l.074-.238a.25.25 0 01.313-.164l.476.148a.749.749 0 00.445-1.431l-.477-.147a.251.251 0 01-.147-.122.247.247 0 01-.017-.19l.148-.477a.254.254 0 01.122-.147.247.247 0 01.19-.017l.477.148a.749.749 0 00.444-1.43l-.477-.148a.25.25 0 01-.164-.313l.148-.477a.251.251 0 01.313-.164l1.191.371a1.023 1.023 0 00.3.044 1 1 0 00.3-1.951l-4.767-1.481a1 1 0 00-.593 1.908l1.192.37a.254.254 0 01.147.122.249.249 0 01.017.191l-.148.475a.244.244 0 01-.122.147.251.251 0 01-.19.018l-.476-.149a.749.749 0 00-.445 1.431l.477.148a.25.25 0 01.164.312l-.148.477a.254.254 0 01-.122.147.247.247 0 01-.19.017l-.477-.148a.749.749 0 00-.444 1.43zM9.779 3.862A4.23 4.23 0 007.43.065a.5.5 0 00-.724.446v2.824a.5.5 0 01-.146.353l-.671.671a.5.5 0 01-.706 0l-.671-.671a.5.5 0 01-.146-.353V.5a.5.5 0 00-.72-.448 4.25 4.25 0 00-.507 7.337.5.5 0 01.22.415V16.2a.5.5 0 01-.22.415 4.249 4.249 0 00.507 7.336.5.5 0 00.221.049.493.493 0 00.264-.076.5.5 0 00.235-.423v-2.836a.5.5 0 01.146-.353l.671-.671a.5.5 0 01.706 0l.671.671a.5.5 0 01.146.353v2.824a.5.5 0 00.724.446 4.251 4.251 0 00.482-7.321.5.5 0 01-.22-.414V7.8a.5.5 0 01.22-.414 4.226 4.226 0 001.867-3.524z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});