define("ember-svg-jar/inlined/tv-retro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tv-retro</title><path d=\"M24 7.5A2.5 2.5 0 0021.5 5h-7.628a.25.25 0 01-.177-.427l2.439-2.439A1.25 1.25 0 0014.367.366l-4.013 4.013a.5.5 0 01-.707 0L5.634.366a1.25 1.25 0 00-1.767 1.768l2.439 2.439A.25.25 0 016.129 5H2.5A2.5 2.5 0 000 7.5v14A2.5 2.5 0 002.5 24h19a2.5 2.5 0 002.5-2.5zm-6.5 12a2 2 0 01-2 2h-11a2 2 0 01-2-2v-10a2 2 0 012-2h11a2 2 0 012 2zM21 21a1 1 0 111-1 1 1 0 01-1 1zm1-5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V9a.5.5 0 01.5-.5h1a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});