define("ember-svg-jar/inlined/rating-star-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rating-star-add</title><path d=\"M21.393 11.579A1.465 1.465 0 0020.441 9H14.5l-2.128-6.051a1.466 1.466 0 00-2.751.019L7.5 9H1.558a1.466 1.466 0 00-.941 2.589l5.03 4.144-2.11 6.334a1.465 1.465 0 002.255 1.649L11 19.9l5.207 3.819a1.465 1.465 0 002.256-1.645l-2.112-6.338zm-6.845 3.052a1 1 0 00-.31 1.086l1.548 4.647a.25.25 0 01-.385.28l-3.811-2.793a1 1 0 00-1.183 0L6.6 20.644a.25.25 0 01-.385-.281l1.548-4.646a1 1 0 00-.31-1.086l-3.874-3.188A.25.25 0 013.737 11h4.472a1 1 0 00.943-.668l1.612-4.589a.25.25 0 01.471 0l1.612 4.589a1 1 0 00.943.668h4.471a.25.25 0 01.159.443z\"/><path d=\"M22.906 3h-2V1a1 1 0 00-2 0v2h-2a1 1 0 100 2h2v2a1 1 0 002 0V5h2a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});