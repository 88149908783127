define("ember-svg-jar/inlined/arrow-thick-dot-corner-down-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-dot-corner-down-right</title><circle cx=\"19\" cy=\"19\" r=\"2\"/><circle cx=\"15.5\" cy=\"15.5\" r=\"2\"/><circle cx=\"12\" cy=\"12\" r=\"2\"/><circle cx=\"8.5\" cy=\"8.5\" r=\"2\"/><circle cx=\"5\" cy=\"5\" r=\"2\"/><circle cx=\"19\" cy=\"12.5\" r=\"2\"/><circle cx=\"19\" cy=\"7\" r=\"2\"/><circle cx=\"12.5\" cy=\"19\" r=\"2\"/><circle cx=\"7\" cy=\"19\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});