define("ember-svg-jar/inlined/real-estate-action-building-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-building-key</title><path d=\"M6.724 6.053l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 000 6.5V14a.5.5 0 00.5.5H2a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5A.5.5 0 007 14V6.5a.5.5 0 00-.276-.447zM5 10.5H2a.5.5 0 010-1h3a.5.5 0 010 1zM5 8H2a.5.5 0 010-1h3a.5.5 0 010 1zM12.187 14.423a5.986 5.986 0 012.38-.868.5.5 0 00.433-.495V3a.5.5 0 00-.308-.462l-6-2.5A.5.5 0 008 .5V14a.5.5 0 00.5.5h3.421a.5.5 0 00.266-.077zM10 4.5h3a.5.5 0 010 1h-3a.5.5 0 010-1zM10 7h3a.5.5 0 010 1h-3a.5.5 0 010-1zm-.5 3a.5.5 0 01.5-.5h3a.5.5 0 010 1h-3a.5.5 0 01-.5-.5zM23.5 11h-2a.5.5 0 00-.344.137l-4.133 3.9a.5.5 0 01-.476.118 4.5 4.5 0 103.166 3.181.5.5 0 01.129-.484l4.012-4A.5.5 0 0024 13.5v-2a.5.5 0 00-.5-.5zm-6.741 9.584a1.752 1.752 0 11.512-1.236 1.747 1.747 0 01-.512 1.236z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});