define("ember-svg-jar/inlined/ecology-human-mind", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ecology-human-mind</title><path d=\"M21.478 9.858a7.246 7.246 0 01-.72-1.452C20.758 3.145 15.9 0 11.2 0A9.972 9.972 0 001.1 10.063c0 3.632 1.24 6.216 3.791 7.884a.253.253 0 01.113.21V23.5a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-2.73a.248.248 0 01.244-.25c1.628-.036 2.692-.246 3.425-.979.781-.781.96-3.026 1-4.282a.249.249 0 01.252-.242c.259 0 .547 0 .8-.011 1.078-.048 1.139-.9 1.174-1.4.077-1.117-.719-2.514-1.421-3.748zm-7 2.777A3.809 3.809 0 0111.194 14a2.494 2.494 0 01-.938-.394.5.5 0 01-.154-.658 8.244 8.244 0 013.019-3.368.589.589 0 00-.527-1.054c-2.4 1.2-4.161 4.662-5 7.212a.589.589 0 11-1.128-.338 20.1 20.1 0 011.661-3.762.5.5 0 00-.047-.567 2.568 2.568 0 01-.635-1.66 3.222 3.222 0 011.613-2.579 8.175 8.175 0 012.659-1.05A7.657 7.657 0 0014.711 4.5a.6.6 0 01.5-.1.586.586 0 01.391.325c1.098 2.394.289 6.42-1.125 7.91z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});