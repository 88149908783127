define("ember-svg-jar/inlined/road-sign-4m-wide-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-4m-wide-alternate</title><path d=\"M4.53 10.47a.75.75 0 10-1.06 1.06l.293.293a.252.252 0 010 .354l-.293.293a.75.75 0 101.06 1.06l1-1a.749.749 0 000-1.06zM20.237 12.177a.252.252 0 010-.354l.293-.293a.75.75 0 00-1.06-1.06l-1 1a.749.749 0 000 1.06l1 1a.75.75 0 001.06-1.06zM10.5 9.75a2.752 2.752 0 00-2.75 2.75v.5a.75.75 0 00.75.75h1a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-4a.75.75 0 00-.75-.75zm-1 2.5a.166.166 0 01-.154-.231q.024-.056.051-.108A.188.188 0 019.75 12a.25.25 0 01-.25.25zM16.2 9.777a.749.749 0 00-.843.337l-.643 1.071a.25.25 0 01-.428 0l-.643-1.071a.75.75 0 00-1.393.386v4a.75.75 0 001.5 0v-1.085a.058.058 0 01.107-.029.78.78 0 001.286 0 .058.058 0 01.107.029V14.5a.75.75 0 001.5 0v-4a.75.75 0 00-.55-.723z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});