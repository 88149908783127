define("ember-svg-jar/inlined/programming-hold-code-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-hold-code-2</title><path d=\"M19.5 22.678a.5.5 0 01.148-.355c.567-.562 2.615-2.59 3.558-3.529a1 1 0 00.294-.709v-7.146a.5.5 0 00-.665-.472c-.73.256-1.8 1.389-1.834 5.829a.5.5 0 01-.147.35l-2.208 2.209a.25.25 0 01-.421-.233l.494-2.153c.343-1.438-.971-2.052-1.688-.938a23.457 23.457 0 00-1.531 3.961v4.258a.25.25 0 00.25.25h3.5a.25.25 0 00.25-.25zM8.25 24a.25.25 0 00.25-.25v-4.258a23.457 23.457 0 00-1.531-3.961c-.717-1.114-2.031-.5-1.688.938l.493 2.153a.25.25 0 01-.42.233l-2.208-2.209A.5.5 0 013 16.3c-.032-4.44-1.1-5.573-1.834-5.829a.5.5 0 00-.665.472v7.105a1 1 0 00.329.742c1.035.936 3 2.977 3.532 3.538a.5.5 0 01.139.346v1.08a.25.25 0 00.25.25zM15.616 8.866a1.25 1.25 0 001.768 1.768l4-4a1.251 1.251 0 000-1.768l-4-4a1.25 1.25 0 00-1.768 1.768L18.379 5.4a.5.5 0 010 .707zM8.384.866a1.251 1.251 0 00-1.768 0l-4 4a1.251 1.251 0 000 1.768l4 4a1.25 1.25 0 001.768-1.768L5.621 6.1a.5.5 0 010-.707l2.763-2.759a1.251 1.251 0 000-1.768zM10.729 11.471A1.331 1.331 0 0011 11.5a1.253 1.253 0 001.22-.979l2-9a1.251 1.251 0 00-2.441-.543l-2 9a1.253 1.253 0 00.95 1.493z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});