define("ember-svg-jar/inlined/dog-sit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dog-sit</title><path d=\"M24 14c0-4.943-2.877-5-3-5a.5.5 0 00-.475.656 8.172 8.172 0 01-.015 3.744 5.509 5.509 0 01-1.456 2.891 9.4 9.4 0 00-2.836-2.469C14.382 12.625 12.484 11.384 12 8V5C12 2.818 9.457.255 9.349.147A.489.489 0 008.937 0a.5.5 0 00-.368.235l-1.4 2.29a11.832 11.832 0 00-1.859.5A3.856 3.856 0 003.09 5.1l-2.072.7A1.5 1.5 0 000 7.22v.98a1.492 1.492 0 001.145 1.456 14.888 14.888 0 001.534.229c1.305.152 3.278.382 3.287 1.119L6 14.7a.317.317 0 01-.252.325.333.333 0 01-.39-.165l-.9-1.648A2.309 2.309 0 002.426 12c-1.453 0-2.32.748-2.32 2a.5.5 0 00.5.5h1.226l2.075 3.257a2.713 2.713 0 002.2 1.225v2.571a2.5 2.5 0 00-2 1.947.5.5 0 00.5.5h3a1.5 1.5 0 001.5-1.5v-2.466l1.47.883a.25.25 0 00.326-.061 3.744 3.744 0 012.986-1.106.75.75 0 010 1.5 2.732 2.732 0 00-2.835 2.086.5.5 0 00.473.664h4.44A3.926 3.926 0 0019 22.852a4.9 4.9 0 00.963-3.843 4.1 4.1 0 002.556-.974A5.131 5.131 0 0024 14zM6.25 6.75a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});