define("ember-svg-jar/inlined/cursor-select-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-select-3</title><path d=\"M3 .013H1a1 1 0 00-1 1v2a1 1 0 002 0v-.75a.25.25 0 01.25-.25H3a1 1 0 100-2zM19 2.013h.75a.25.25 0 01.25.25v.75a1 1 0 002 0v-2a1 1 0 00-1-1h-2a1 1 0 000 2zM9 .013H7a1 1 0 000 2h2a1 1 0 000-2zM15 2.013a1 1 0 100-2h-2a1 1 0 000 2zM3 20.013h-.75a.25.25 0 01-.25-.25v-.75a1 1 0 00-2 0v2a1 1 0 001 1h2a1 1 0 000-2zM9 20.013H7a1 1 0 000 2h2a1 1 0 000-2zM1 10.013a1 1 0 001-1v-2a1 1 0 00-2 0v2a1 1 0 001 1zM1 16.013a1 1 0 001-1v-2a1 1 0 00-2 0v2a1 1 0 001 1zM21 6.013a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zM23.488 16.092L13.11 12.2a.716.716 0 00-.808.128.724.724 0 00-.125.809l3.931 10.337a.671.671 0 00.625.508h.035c.136-.009.466-.083.59-.586l1.142-4.688a.252.252 0 01.183-.183l4.732-1.183c.5-.125.575-.457.583-.593a.66.66 0 00-.51-.657z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});