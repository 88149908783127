define("ember-svg-jar/inlined/blood-drop-type-negative", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>blood-drop-type-negative</title><path d=\"M15.28 14.176A8.008 8.008 0 019.5 6.5a7.912 7.912 0 01.75-3.36.5.5 0 00-.018-.457A23.317 23.317 0 008.819.393a1.093 1.093 0 00-1.638 0C5.705 2.328 0 13.3 0 16.5 0 20.425 3.813 24 8 24s8-3.575 8-7.5a7.727 7.727 0 00-.379-1.984.5.5 0 00-.341-.34zm-7.549 6.879a.749.749 0 01-.9.565A5.233 5.233 0 013.3 18.834a.75.75 0 111.343-.668 3.736 3.736 0 002.528 1.991.75.75 0 01.56.898z\"/><path d=\"M17.5 0A6.5 6.5 0 1024 6.5 6.513 6.513 0 0017.5 0zM20 7.5h-5a1 1 0 010-2h5a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});