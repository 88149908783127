define("ember-svg-jar/inlined/gesture-tap-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-tap-1</title><path d=\"M12 7.5a4.255 4.255 0 00-4.25 4.25v11.5a.75.75 0 00.75.75h7a.75.75 0 00.75-.75v-11.5A4.255 4.255 0 0012 7.5zm2 6a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V12a2 2 0 014 0z\"/><path d=\"M20.555 12.048a8.555 8.555 0 10-15.81 4.536.75.75 0 001.271-.8 7.055 7.055 0 1111.958.015.75.75 0 001.269.8 8.524 8.524 0 001.312-4.551z\"/><path d=\"M20.485 3.508a12 12 0 00-16.97 16.971.75.75 0 001.06-1.061 10.5 10.5 0 1114.85 0 .75.75 0 001.06 1.061 12.014 12.014 0 000-16.971z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});