define("ember-svg-jar/inlined/keyboard-arrow-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-arrow-right</title><path d=\"M23.707 11.291l-4.778-4.778a1 1 0 00-1.707.707v3.028a.25.25 0 01-.25.25H1.5a1.5 1.5 0 000 3h15.472a.25.25 0 01.25.25v3.028a1 1 0 001.707.707l4.778-4.778a1 1 0 000-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});