define("ember-svg-jar/inlined/vintage-camera-flash-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-camera-flash-1</title><path d=\"M24 5.938a5.938 5.938 0 10-7.126 5.818.248.248 0 01.2.242v7.8a.248.248 0 01-.247.247h-.743a.248.248 0 01-.247-.247V13.2a.247.247 0 00-.167-.234 7.471 7.471 0 01-4.23-3.676.248.248 0 00-.221-.135H1.485A1.486 1.486 0 000 10.639v11.876A1.486 1.486 0 001.485 24h12.866a1.486 1.486 0 001.484-1.485v-.247a.247.247 0 01.247-.247h.99a1.983 1.983 0 001.98-1.98V12a.247.247 0 01.2-.242A5.946 5.946 0 0024 5.938zM5.2 12.124a.742.742 0 01-.742.742h-.99a.743.743 0 010-1.485h.99a.743.743 0 01.742.743zm2.722 2.969a2.969 2.969 0 11-2.97 2.969 2.969 2.969 0 012.966-2.969zM14.1 5.938a3.931 3.931 0 01.515-1.951.249.249 0 01.391-.053l1.828 1.829a.247.247 0 010 .35l-1.825 1.829a.248.248 0 01-.391-.053 3.941 3.941 0 01-.518-1.951zm7.918 0a3.931 3.931 0 01-.516 1.951.248.248 0 01-.39.053l-1.826-1.829a.249.249 0 010-.35l1.829-1.829a.251.251 0 01.208-.07.246.246 0 01.182.123 3.931 3.931 0 01.516 1.951zm-4.131 1.225a.244.244 0 01.175-.073.248.248 0 01.175.073l1.829 1.829a.248.248 0 01-.053.39 3.953 3.953 0 01-3.9 0 .248.248 0 01-.053-.39zm.35-2.449a.251.251 0 01-.175.072.247.247 0 01-.175-.072l-1.829-1.829a.251.251 0 01-.07-.208.246.246 0 01.123-.182 3.946 3.946 0 013.9 0 .247.247 0 01.052.39z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});