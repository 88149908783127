define("ember-svg-jar/inlined/power-button-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>power-button-1</title><path d=\"M12 24A12 12 0 100 12a12.013 12.013 0 0012 12zM10.5 7a1.5 1.5 0 113 0v9.5a1.5 1.5 0 01-3 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});