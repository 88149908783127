define("ember-svg-jar/inlined/astrology-david", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astrology-david</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M18.125 5.419a.251.251 0 00-.409.106l-2.458 7.565a.5.5 0 01-.475.346.493.493 0 01-.155-.025.5.5 0 01-.321-.63L16.4 6.355a.25.25 0 00-.385-.28L14 7.539a.5.5 0 11-.588-.809l2.978-2.166a.251.251 0 00-.024-.421 8.91 8.91 0 00-8.732 0 .25.25 0 00-.025.421l6.434 4.674a.5.5 0 11-.587.809L7.99 6.075a.252.252 0 00-.294 0 .25.25 0 00-.091.28l.77 2.369a.5.5 0 11-.952.308l-1.14-3.507a.249.249 0 00-.175-.165.252.252 0 00-.233.059A8.969 8.969 0 003 12a8.781 8.781 0 00.175 1.727.249.249 0 00.392.153L10 9.2a.5.5 0 11.588.809l-5.462 3.974a.25.25 0 00.147.453h2.491a.5.5 0 110 1h-3.7a.25.25 0 00-.227.355 9.015 9.015 0 007.073 5.135.247.247 0 00.223-.089.252.252 0 00.045-.236l-2.455-7.568a.5.5 0 11.951-.308l2.088 6.425a.25.25 0 00.476 0l.769-2.369a.5.5 0 01.951.309l-1.14 3.51a.249.249 0 00.267.325 9.012 9.012 0 007.073-5.135.247.247 0 00-.015-.24.25.25 0 00-.211-.115H11.97a.5.5 0 110-1h6.757a.251.251 0 00.147-.453l-2.015-1.462a.5.5 0 01.588-.809l2.985 2.169a.25.25 0 00.238.03.245.245 0 00.154-.183A8.773 8.773 0 0021 12a8.973 8.973 0 00-2.875-6.581z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});