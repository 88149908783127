define("ember-svg-jar/inlined/house-retro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>house-retro</title><path d=\"M15 11.5H9a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-3.5a1.5 1.5 0 013 0V22a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V12a.5.5 0 00-.5-.5zM6.617 11.432a2.034 2.034 0 01-1.131-3.247L7.4 5.634a.5.5 0 00.1-.3V2a.5.5 0 00-.5-.5H5a.5.5 0 00-.5.5v3a.5.5 0 01-.5.5h-.357a1.5 1.5 0 00-1.379.91l-2.141 5A1.5 1.5 0 001.5 13.5a.5.5 0 01.5.5v7a1.5 1.5 0 001.5 1.5h3A.5.5 0 007 22V11.918a.5.5 0 00-.383-.486zM23.878 11.409l-2.142-5a1.5 1.5 0 00-1.379-.91H20a.5.5 0 01-.5-.5V2a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v3.334a.5.5 0 00.1.3l1.915 2.552a2.034 2.034 0 01-1.132 3.246.5.5 0 00-.383.486V22a.5.5 0 00.5.5h3A1.5 1.5 0 0022 21v-7a.5.5 0 01.5-.5 1.5 1.5 0 001.379-2.091z\"/><path d=\"M17.4 9.691a.551.551 0 00-.062-.578L12.862 3.42a1.1 1.1 0 00-1.725 0L6.664 9.113A.548.548 0 007.1 10h9.8a.55.55 0 00.5-.309z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});