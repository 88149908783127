define("ember-svg-jar/inlined/touch-id-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>touch-id-2</title><path d=\"M10.671 5.112a4.8 4.8 0 016.287 4.555v4.722a.875.875 0 001.75 0V9.667a6.546 6.546 0 00-8.583-6.217.875.875 0 10.546 1.662zM17.042 18.751a.875.875 0 10-1.3-1.168 4.791 4.791 0 01-8.363-3.194V9.667A4.777 4.777 0 018.6 6.473a.875.875 0 10-1.3-1.168 6.528 6.528 0 00-1.675 4.362v4.722a6.542 6.542 0 0011.417 4.362z\"/><path d=\"M10.208 14.389a.875.875 0 00-1.75 0 3.709 3.709 0 107.417 0V9.667a3.709 3.709 0 10-7.417 0v1.416a.875.875 0 001.75 0V9.667a1.959 1.959 0 113.917 0v4.722a1.959 1.959 0 11-3.917 0z\"/><path d=\"M13.042 13.917v-2.834a.875.875 0 00-1.75 0v2.834a.875.875 0 001.75 0zM6.5 22H2.25a.25.25 0 01-.25-.25V17.5a1 1 0 00-2 0V22a2 2 0 002 2h4.5a1 1 0 000-2zM1 7.5a1 1 0 001-1V2.25A.25.25 0 012.25 2H6.5a1 1 0 000-2H2a2 2 0 00-2 2v4.5a1 1 0 001 1zM23 16.5a1 1 0 00-1 1v4.25a.25.25 0 01-.25.25H17.5a1 1 0 000 2H22a2 2 0 002-2v-4.5a1 1 0 00-1-1zM22 0h-4.5a1 1 0 000 2h4.25a.25.25 0 01.25.25V6.5a1 1 0 002 0V2a2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});