define("ember-svg-jar/inlined/army-symbol-mp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-symbol-mp</title><path d=\"M24 5.5a2 2 0 00-2-2H2a2 2 0 00-2 2v13a2 2 0 002 2h20a2 2 0 002-2zM22 18a.5.5 0 01-.5.5h-19A.5.5 0 012 18V6a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M6.5 16a1 1 0 001-1v-2.4a.092.092 0 01.065-.088.094.094 0 01.1.037 1.037 1.037 0 001.664 0 .094.094 0 01.1-.037.092.092 0 01.071.088V15a1 1 0 002 0V9a1 1 0 00-1.832-.555l-.96 1.44a.25.25 0 01-.416 0l-.96-1.44A1 1 0 005.5 9v6a1 1 0 001 1zM14 16a1 1 0 001-1v-1.75a.249.249 0 01.25-.25H16a2.5 2.5 0 100-5h-2a1 1 0 00-1 1v6a1 1 0 001 1zm1-5.75a.249.249 0 01.25-.25H16a.5.5 0 010 1h-.75a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});