define("ember-svg-jar/inlined/road-tunnel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-tunnel</title><path d=\"M12 0A12.021 12.021 0 00.25 12.252v10a1.743 1.743 0 00.439 1.158.755.755 0 00.56.252.679.679 0 00.56-.25l7.75-8.66a.748.748 0 00.191-.5v-2.5c0-1.369.7-2.75 2.25-2.75s2.25 1.381 2.25 2.75v2.5a.748.748 0 00.191.5l7.75 8.66a.75.75 0 00.559.25.755.755 0 00.56-.252 1.743 1.743 0 00.439-1.158v-10A12.021 12.021 0 0012 0z\"/><path d=\"M12 20.5a1 1 0 00-1 1V23a1 1 0 002 0v-1.5a1 1 0 00-1-1zM12 15.748a1 1 0 00-1 1v1.5a1 1 0 002 0v-1.5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});