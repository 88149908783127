define("ember-svg-jar/inlined/single-man-actions-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-heart</title><path d=\"M10.031 13.777a5.016 5.016 0 012.654-2.419.25.25 0 00.006-.463l-1.54-.66a.25.25 0 01-.151-.23V8.87a.251.251 0 01.073-.177 3.3 3.3 0 00.779-2.23.248.248 0 01.089-.177 1.876 1.876 0 00.537-1.4 1.585 1.585 0 00-.316-1.015.248.248 0 01-.034-.265 2.528 2.528 0 00.156-1.726C11.943.492 10.241 0 8.887 0a3.754 3.754 0 00-3.163 1.324.25.25 0 01-.212.118 1.072 1.072 0 00-.9.375c-.372.493-.1 1.171.176 1.732a.249.249 0 01-.046.288 1.242 1.242 0 00-.161.2A1.766 1.766 0 004.319 5a1.522 1.522 0 00.639 1.331.252.252 0 01.117.194A3.158 3.158 0 005.92 8.7a.25.25 0 01.08.183v1.122a.251.251 0 01-.152.23l-3.936 1.687C.639 12.468.457 13.626.205 15.23c-.056.359-.117.751-.2 1.179A.5.5 0 00.5 17h8.805a.25.25 0 00.248-.284 5.018 5.018 0 01.478-2.939zM23.627 14.449a3.525 3.525 0 00-5.646-.916l-.305.3a.25.25 0 01-.354 0l-.3-.3a3.526 3.526 0 00-4.993 4.979l5.113 5.334a.5.5 0 00.723 0l5.107-5.327a3.522 3.522 0 00.655-4.07z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});