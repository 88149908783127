define("ember-svg-jar/inlined/road-sign-crossroad-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-crossroad-1</title><path d=\"M23.53 12.53a.749.749 0 000-1.06l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0zM17.25 12a.75.75 0 01-.75.75H13a.25.25 0 00-.25.25v3.5a.75.75 0 01-1.5 0V13a.25.25 0 00-.25-.25H7.5a.75.75 0 010-1.5H11a.25.25 0 00.25-.25V7.5a.75.75 0 011.5 0V11a.25.25 0 00.25.25h3.5a.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});