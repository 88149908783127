define("ember-svg-jar/inlined/wild-bird-head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wild-bird-head</title><path d=\"M19.951 6.2C19.461 2.3 15.417 0 11.5 0 8.042 0 0 1.754 0 18v5.5a.5.5 0 00.5.5h15a.5.5 0 00.5-.5 60.231 60.231 0 01.623-9.5 9.641 9.641 0 015.6 1.245c1.4.8 1.774-.449 1.774-.744C24 8.586 21.086 6.708 19.951 6.2zM6.532 5.5H9.7a4.6 4.6 0 00.124 5.6c-2.507-.267-3.543-2.349-3.292-5.6zm5.256 10.763c-1.167 3.5-4.712 3.506-7.54 3.495a.5.5 0 01-.5-.5V16a20.489 20.489 0 01.706-5.554.5.5 0 01.922-.1 5.015 5.015 0 002.014 2.077 6.233 6.233 0 003.059.712 10.607 10.607 0 001.779-.161.5.5 0 01.542.694c-.315.72-.641 1.572-.982 2.595zm.944-5.435a2.826 2.826 0 01-1.919-2.609 2.722 2.722 0 012.1-2.635c.927.251 1.62 1.128 2.057 2.633.584 2.025-1.643 2.466-2.238 2.611zm4.3 1.138c.6-2.453 1.329-3.564 1.842-4.061 2.778 1.238 3 4.651 3.036 4.944a11.6 11.6 0 00-4.882-.883z\"/><circle cx=\"13\" cy=\"8.494\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});