define("ember-svg-jar/inlined/outdoors-bird", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-bird</title><path d=\"M24 12a8 8 0 00-12-6.93 8 8 0 100 13.86A8 8 0 0024 12zm-2 0a6 6 0 01-8.451 5.478.25.25 0 01.1-.478H17.3a1 1 0 00.707-1.707l-6.733-6.732a2.5 2.5 0 00-4.146 1l-1.94 1.552A.5.5 0 005.5 12h1.007a.5.5 0 01.5.5 4.509 4.509 0 003.6 4.409.25.25 0 01.062.468 6 6 0 11.763-10.3 1 1 0 001.144 0A6 6 0 0122 12zm-11.743-1.5a.75.75 0 11-.75-.75.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});