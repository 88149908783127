define("ember-svg-jar/inlined/color-brush-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-brush-2</title><path d=\"M23.06.872a3.071 3.071 0 00-4.564.345L8.571 14.228a.251.251 0 00.086.375 5.136 5.136 0 012.406 2.452.25.25 0 00.406.073L23.1 5.256a3.072 3.072 0 00-.04-4.384zM7.876 15.877a3.649 3.649 0 00-4.824 1.836c-.192.426-.335.828-.468 1.2-.394 1.109-.574 1.616-1.578 1.874A1.338 1.338 0 00.782 23.3a7.513 7.513 0 003.137.7 6.992 6.992 0 002.469-.447A5.945 5.945 0 009.711 20.7a3.649 3.649 0 00-1.835-4.824z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});