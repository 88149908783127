define("ember-svg-jar/inlined/phone-action-remove-user-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-remove-user-1</title><path d=\"M11.375 13.75v4.75a1 1 0 01-1 1h-7a1 1 0 01-1-1v-11a1 1 0 011-1h5.681a.24.24 0 00.187-.091 6.369 6.369 0 011.066-1.048.24.24 0 00.066-.3 4.478 4.478 0 01-.342-.875A.244.244 0 009.8 4H2.875a2.5 2.5 0 00-2.5 2.5v15a2.5 2.5 0 002.5 2.5h8a2.5 2.5 0 002.5-2.5v-7.75a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25z\"/><path d=\"M15.431 10.523A2.053 2.053 0 0117.455 9h1.232a.254.254 0 00.242-.334A4.888 4.888 0 0015.69 5.7a3.012 3.012 0 001.544-3.63A2.93 2.93 0 0015.329.148 3 3 0 0013 5.667a4.812 4.812 0 00-3.629 4.61V11.5a.5.5 0 00.5.5h5.383a.245.245 0 00.234-.325 2.022 2.022 0 01-.057-1.152z\"/><path d=\"M22.625 10h-5a1 1 0 000 2h5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});