define("ember-svg-jar/inlined/style-three-pin-trailer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-trailer</title><path d=\"M22.389 7.955a8.67 8.67 0 00-4.01-6.033 12.126 12.126 0 00-12.758 0 8.67 8.67 0 00-4.01 6.033 10.785 10.785 0 002.577 8.485 59.243 59.243 0 007.17 7.252 1 1 0 001.284 0 60.3 60.3 0 007.17-7.253 10.785 10.785 0 002.577-8.484zM15 16a2 2 0 01-1.886-1.333.5.5 0 01.471-.667h2.83a.5.5 0 01.471.667A2 2 0 0115 16zm3.5-3.5a.5.5 0 01-.5.5H5a.75.75 0 010-1.5h3.5V7.574a1.5 1.5 0 011.5-1.5h6a2.5 2.5 0 012.5 2.5z\"/><path d=\"M10.5 8.5H12a.5.5 0 000-1h-1.5a.5.5 0 000 1z\"/><rect x=\"14\" y=\"8.5\" width=\"2.5\" height=\"3\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});