define("ember-svg-jar/inlined/arrow-left-9", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M23 24a.995.995 0 01-.691-.277l-11.5-11a.998.998 0 010-1.446l11.5-11a1 1 0 011.383 1.445L12.947 12l10.745 10.277A1 1 0 0123 24z\"/><path d=\"M12.5 24a.997.997 0 01-.691-.277l-11.5-11a.998.998 0 010-1.446l11.5-11a1 1 0 011.383 1.445L2.447 12l10.745 10.277A1 1 0 0112.5 24z\"/><g><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});