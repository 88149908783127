define("ember-svg-jar/inlined/decoration-bottle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>decoration-bottle</title><path d=\"M15.192 5.941a1.319 1.319 0 01-.942-1.2V3.583a.161.161 0 01.162-.162 1.711 1.711 0 000-3.421H9.588a1.711 1.711 0 000 3.421.161.161 0 01.162.162v1.154a1.319 1.319 0 01-.942 1.2A10.8 10.8 0 003.4 22.711 3.23 3.23 0 005.971 24H18.03a3.225 3.225 0 002.57-1.288 10.8 10.8 0 00-5.408-16.771zM13.809 21h-3.618a3.625 3.625 0 01-3.618-3.632 5.427 5.427 0 1110.854 0A3.625 3.625 0 0113.809 21z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});