define("ember-svg-jar/inlined/video-file-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-sync</title><path d=\"M23.415 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.277a.247.247 0 00.241.249c.364.009 1.036.041 1.485.093A.247.247 0 008 9.371V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.244.244 0 00-.241.21 7.956 7.956 0 01-.364 1.458.244.244 0 00.229.331H22a2 2 0 002-2V4.414A2 2 0 0023.415 3z\"/><path d=\"M14.5 12.733a.254.254 0 00.378.259l4.22-2.337a.735.735 0 000-1.31l-5.844-3.236a.886.886 0 00-.834-.009.753.753 0 00-.42.665V10c0 .192.208.264.313.253a1.945 1.945 0 01.954.134 1.994 1.994 0 011.233 1.847zM11.77 18.6a1 1 0 00-1.256.581 4.053 4.053 0 01-1.278 1.931 4.162 4.162 0 01-5.655-.471.255.255 0 01.013-.349l1.717-1.717a.5.5 0 00-.354-.853H.5a.5.5 0 00-.5.5v4.458a.5.5 0 00.854.353l.974-.974a.244.244 0 01.353.009 6.086 6.086 0 0010.211-2.2 1 1 0 00-.622-1.268zM12.691 11.772a.5.5 0 00-.545.109l-1.03 1.03a.244.244 0 01-.352-.008 6.1 6.1 0 00-10.2 2.226 1 1 0 001.878.69A4.08 4.08 0 013.718 13.9a4.154 4.154 0 015.638.435.255.255 0 01-.012.35l-1.655 1.653a.5.5 0 00.354.853H12.5a.5.5 0 00.5-.5v-4.457a.5.5 0 00-.309-.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});