define("ember-svg-jar/inlined/social-music-grooveshark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-music-grooveshark</title><path d=\"M24 12a12 12 0 00-24 0 12 12 0 0023.57 3.15A12 12 0 0024 12zM12 1.51a10.43 10.43 0 0110.2 12.92C19 14 17 11.15 15.19 8.62c-2.09-2.94-4.06-5.71-7.41-4.07a.5.5 0 00-.23.66C9.26 8.82 8.08 11 7.22 12A4.32 4.32 0 013 13.58a2.11 2.11 0 01-1.5-1.85A10.51 10.51 0 0112 1.51z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});