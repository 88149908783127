define("ember-svg-jar/inlined/audio-file-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file-warning</title><path d=\"M16.233 11.5a.734.734 0 00.017-.084v-5.17c0-.141.178-.18.271-.121L17.6 6.8a.75.75 0 10.8-1.269l-1.817-1.137A1.25 1.25 0 0014.75 5.5v3.4a.245.245 0 01-.294.243 2.034 2.034 0 00-1.47.221 2.314 2.314 0 00-1.229 1.8 2.245 2.245 0 104.476.344z\"/><path d=\"M23.414 3L21 .585A2 2 0 0019.588 0H8a2 2 0 00-2 2v7.739a.248.248 0 00.233.249 7.332 7.332 0 011.443.216A.25.25 0 008 9.966V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.147l2.122 2.122a.5.5 0 01.146.352V18a.5.5 0 01-.5.5h-8.3a.25.25 0 00-.221.366l.786 1.5a.251.251 0 00.222.134H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M7.779 12.271a1.448 1.448 0 00-2.56 0L.163 21.9a1.428 1.428 0 00.048 1.409A1.455 1.455 0 001.443 24h10.112a1.456 1.456 0 001.232-.686 1.428 1.428 0 00.048-1.409zM5.749 16a.75.75 0 011.5 0v3a.75.75 0 01-1.5 0zm.75 6.25a1 1 0 111-1 1 1 0 01-.999 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});