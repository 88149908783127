define("ember-svg-jar/inlined/car-actions-wifi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-actions-wifi</title><path d=\"M7.005 22.015h-4a.25.25 0 00-.25.25v.5a1.249 1.249 0 001.249 1.247h2a1.249 1.249 0 001.249-1.247v-.5a.25.25 0 00-.248-.25zM20.991 22.015H17a.25.25 0 00-.25.25v.5a1.249 1.249 0 001.249 1.247h2a1.249 1.249 0 001.249-1.247v-.5a.25.25 0 00-.257-.25zM21.874 16.6l-1.084-1.084a.5.5 0 01-.11-.168l-2.049-5.116a1.49 1.49 0 00-1.391-.942H6.926a1.492 1.492 0 00-1.391.941L3.6 15.073a.484.484 0 01-.111.167l-1.51 1.505a.749.749 0 00-.219.529v2.5a1.249 1.249 0 001.249 1.247h17.982a1.249 1.249 0 001.249-1.251v-2.289a1.253 1.253 0 00-.366-.881zm-3.936-2.43a.249.249 0 01-.207.11H6.435a.249.249 0 01-.207-.11.246.246 0 01-.025-.232l1-2.5a.25.25 0 01.233-.157h9.3a.25.25 0 01.232.157l1 2.5a.246.246 0 01-.03.232zM6.43 4.331a7.91 7.91 0 015.613-2.323 7.873 7.873 0 015.614 2.323 1 1 0 001.512-1.3 9.858 9.858 0 00-7.123-3.02A9.892 9.892 0 005.017 2.92 1 1 0 006.43 4.331z\"/><path d=\"M7.544 5.839A1 1 0 008.749 7.41c.274-.158 2.769-3.095 6.457-.091a1.014 1.014 0 001.337-.068 1 1 0 000-1.412 6.378 6.378 0 00-8.999 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});