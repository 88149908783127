define("ember-svg-jar/inlined/adventure-car-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>adventure-car-2</title><path d=\"M6.5 16.74a3 3 0 103 3 3 3 0 00-3-3zm0 4a1 1 0 111-1 1 1 0 01-1 1zM17.5 16.74a3 3 0 103 3 3 3 0 00-3-3zm0 4a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M23.335 10.258l-.225-.149A.253.253 0 0123 9.9V2.26a1 1 0 10-2 0v5.975a.25.25 0 01-.389.208l-3-2a1.572 1.572 0 00-.765-.206H8.5A1.5 1.5 0 007 7.74v2.417a.25.25 0 01-.215.247l-5.473.783A1.492 1.492 0 000 12.67v4.07a1.5 1.5 0 001.5 1.5h.53a1.507 1.507 0 001.49-1.286A2.01 2.01 0 015.5 15.24h2a2.006 2.006 0 011.977 1.688 1.492 1.492 0 001.483 1.312h2.07a1.507 1.507 0 001.49-1.286 2.01 2.01 0 011.98-1.714h2a2.006 2.006 0 011.977 1.688 1.492 1.492 0 001.483 1.312H23a1 1 0 001-1v-5.73a1.48 1.48 0 00-.665-1.252zM13.9 8.575l-.543 1.5a.25.25 0 01-.235.165H9.25A.25.25 0 019 9.99v-1.5a.25.25 0 01.25-.25h4.417a.25.25 0 01.205.107.247.247 0 01.028.228z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});