define("ember-svg-jar/inlined/messages-bubble-sync-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-sync-1</title><path d=\"M17.5 9.5a7.913 7.913 0 014.958 1.747.25.25 0 00.385-.1A7.908 7.908 0 0023.5 8a8.009 8.009 0 00-8-8H8a7.992 7.992 0 00-3 15.4v4.1a.5.5 0 00.854.354l3.736-3.737a.243.243 0 00.068-.128A8.027 8.027 0 0117.5 9.5zM13.009 21.878a6.088 6.088 0 0010.383-2.007 1 1 0 00-1.893-.648 4.092 4.092 0 01-7.073 1.238l1.885-1.885a.5.5 0 00-.354-.853H11.5a.5.5 0 00-.5.5v4.457a.5.5 0 00.309.462.486.486 0 00.4-.032.5.5 0 00.144-.077z\"/><path d=\"M21.936 13.091a6.1 6.1 0 00-10.369 2.038 1 1 0 001.893.648 4.093 4.093 0 017.054-1.263l-1.824 1.824a.5.5 0 00.353.853H23.5a.5.5 0 00.5-.5v-4.457a.5.5 0 00-.838-.361s-.011 0-.016.008z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});