define("ember-svg-jar/inlined/login-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>login-1-alternate</title><path d=\"M22 .5H11.5a2 2 0 00-2 2v3a1 1 0 102 0V3a.5.5 0 01.5-.5h9.5a.5.5 0 01.5.5v18a.5.5 0 01-.5.5H12a.5.5 0 01-.5-.5v-2.5a1 1 0 00-2 0v3a2 2 0 002 2H22a2 2 0 002-2v-19a2 2 0 00-2-2z\"/><path d=\"M17 14a1.5 1.5 0 000-3H8V8.5a1 1 0 00-1.555-.832l-6 4a1 1 0 000 1.664l6 4a1.012 1.012 0 001.027.05A1 1 0 008 16.5V14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});