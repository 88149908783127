define("ember-svg-jar/inlined/single-man-phone-book.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-phone-book</title><path d=\"M2.25 0H1.5A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h.75a.25.25 0 00.25-.25V.25A.25.25 0 002.25 0zM24 4a3 3 0 00-3-3h-.774a.5.5 0 01-.433-.25A1.493 1.493 0 0018.5 0H3.75a.25.25 0 00-.25.25v23.5a.25.25 0 00.25.25H18.5a1.493 1.493 0 001.293-.75.5.5 0 01.433-.25H21a3 3 0 003-3zm-7.125 14a.5.5 0 01-.5.5h-10a.5.5 0 01-.5-.5 4.924 4.924 0 01.487-1.987c.369-.74 1.5-1.157 3.073-1.734l.189-.069a.25.25 0 00.164-.235v-.451a.25.25 0 00-.067-.171 2.13 2.13 0 01-.562-1.147.218.218 0 00-.04-.1 1.2 1.2 0 01-.192-.439 1.323 1.323 0 01.1-.937.251.251 0 00.021-.19A2.356 2.356 0 019.1 8.57a1.066 1.066 0 01.58-.392.246.246 0 00.12-.078 2.654 2.654 0 011.984-.6c.975.023 2.079.362 2.256 1.245a3.513 3.513 0 01-.306 1.816.248.248 0 00.01.2 1.333 1.333 0 01.08.9 1.21 1.21 0 01-.188.434.248.248 0 00-.045.109 2.13 2.13 0 01-.561 1.14.25.25 0 00-.067.171v.451a.25.25 0 00.164.235l.189.069c1.57.577 2.7.994 3.073 1.734A4.924 4.924 0 0116.875 18zM22 20a1 1 0 01-1 1h-.75a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25zm0-4.25a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25zm0-5a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25zm0-5a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25H21a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});