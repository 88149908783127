define("ember-svg-jar/inlined/shipment-upload-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-upload-information</title><path d=\"M24 1.043A1.043 1.043 0 0022.957 0H1.043A1.043 1.043 0 000 1.043v21.914A1.043 1.043 0 001.043 24h21.914A1.043 1.043 0 0024 22.957zm-10.854 5.6l3-3a.5.5 0 01.708 0l3 3a.5.5 0 01-.354.857h-1.75a.25.25 0 00-.25.25V14a1 1 0 01-2 0V7.75a.25.25 0 00-.25-.25H13.5a.5.5 0 01-.354-.854zm-9 0l3-3a.5.5 0 01.708 0l3 3a.5.5 0 01-.354.857H8.75a.25.25 0 00-.25.25V14a1 1 0 01-2 0V7.75a.25.25 0 00-.25-.25H4.5a.5.5 0 01-.354-.854zM20.5 20.5h-17a1 1 0 010-2h17a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});