define("ember-svg-jar/inlined/history-empire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-empire</title><path d=\"M24 14.5a.5.5 0 00-.5-.5H.5a.5.5 0 00-.5.5v6a.5.5 0 00.5.5H4a.5.5 0 00.5-.5V20a.5.5 0 00-.5-.5H3a1 1 0 01-1-1V17a1 1 0 011-1h4.5a1 1 0 011 1v3.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V20a.5.5 0 00-.5-.5h-1a1 1 0 01-1-1V17a1 1 0 011-1h4.5a1 1 0 011 1v3.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V20a.5.5 0 00-.5-.5h-1a1 1 0 01-1-1V17a1 1 0 011-1h4.5a.5.5 0 00.5-.5z\"/><path d=\"M19 18a.5.5 0 00.5.5h1a1 1 0 011 1V21a1 1 0 01-1 1h-4a1 1 0 01-1-1v-3.5a.5.5 0 00-.5-.5h-3.5a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h1a1 1 0 011 1V21a1 1 0 01-1 1h-4a1 1 0 01-1-1v-3.5A.5.5 0 007 17H3.5a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h1a1 1 0 011 1V21a1 1 0 01-1 1h-4a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-6a.5.5 0 00-.5-.5h-4a.5.5 0 00-.5.5zM3.5 7h.372a.5.5 0 01.5.541L4 11.958a.5.5 0 00.5.542h2a.5.5 0 00.5-.542l-.37-4.417A.5.5 0 017.128 7h3.244a.5.5 0 01.5.541l-.372 4.417a.5.5 0 00.5.542h2a.5.5 0 00.5-.542l-.37-4.417a.5.5 0 01.5-.541h3.244a.5.5 0 01.5.541L17 11.958a.5.5 0 00.5.542h2a.5.5 0 00.5-.542l-.37-4.417a.5.5 0 01.5-.541h.37a1.5 1.5 0 000-3h-1.418a.507.507 0 01-.257-.071L12.515.142a1.006 1.006 0 00-1.03 0l-6.31 3.787A.507.507 0 014.918 4H3.5a1.5 1.5 0 000 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});