define("ember-svg-jar/inlined/seasoning-salt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seasoning-salt</title><path d=\"M17 4.5A4.505 4.505 0 0012.5 0h-1A4.5 4.5 0 007 4.5V6a1.5 1.5 0 001.5 1.5h7A1.5 1.5 0 0017 6zm-6.5-.75a.75.75 0 11.75-.75.75.75 0 01-.75.75zm3 0a.75.75 0 11.75-.75.75.75 0 01-.75.75zM19.482 16.966a.5.5 0 00-.5-.466H5.017a.5.5 0 00-.5.466 41.98 41.98 0 00-.009 1.544.5.5 0 00.5.49h13.983a.5.5 0 00.5-.49c.009-.322.003-1.366-.009-1.544zM5.212 20.5a.5.5 0 00-.492.592C5.094 23.082 6.065 24 8.5 24h7c2.435 0 3.406-.918 3.78-2.908a.5.5 0 00-.492-.592zM18.475 15a.5.5 0 00.476-.653 21.454 21.454 0 00-2.575-5.125.5.5 0 00-.415-.222H8.039a.5.5 0 00-.415.222 21.454 21.454 0 00-2.575 5.125.5.5 0 00.476.653z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});