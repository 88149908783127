define("ember-svg-jar/inlined/weather-night-thunder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-night-thunder</title><path d=\"M23.523 10.337a4.5 4.5 0 010-7.674 1 1 0 000-1.7A6.5 6.5 0 0014.35 3.52a.249.249 0 00.148.354 8.5 8.5 0 011.356.552.251.251 0 00.333-.1A4.5 4.5 0 0120.125 2a4.756 4.756 0 01.507.028.25.25 0 01.166.406A6.46 6.46 0 0019.375 6.5a6.6 6.6 0 00.211 1.657.262.262 0 00.019.049 8.49 8.49 0 01.54 1.364.286.286 0 00.02.048 6.387 6.387 0 00.633.948.25.25 0 01-.167.4.192.192 0 00-.17.211v.01a.248.248 0 00.075.154 6.516 6.516 0 011.034 1.309.25.25 0 00.281.112 6.508 6.508 0 001.672-.725 1 1 0 000-1.7z\"/><path d=\"M19.094 12.073a.25.25 0 01-.1-.193A7 7 0 005.338 9.84a.249.249 0 01-.22.173A5.5 5.5 0 005.5 21h.751a.25.25 0 00.2-.4 2.209 2.209 0 01-.242-.4A2.248 2.248 0 016 19.25a.249.249 0 00-.25-.25H5.5a3.5 3.5 0 11.448-6.971.991.991 0 001.116-.829A5 5 0 0117 12c0 .146-.007.29-.02.433a1 1 0 00.5.959A3 3 0 0116 19h-.009a.007.007 0 00-.007.007 2.254 2.254 0 01-.524 1.2l-.322.375a.25.25 0 00.189.413H16a5 5 0 003.094-8.927z\"/><path d=\"M14.32 19.237A.75.75 0 0013.75 18h-1a.25.25 0 01-.25-.25V14.5a.5.5 0 00-.879-.326L7.68 18.763A.75.75 0 008.25 20h1a.25.25 0 01.25.25v3.25a.5.5 0 00.879.326z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});