define("ember-svg-jar/inlined/single-woman-actions-shield.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-shield</title><path d=\"M9.261 17.5a.25.25 0 00.182-.078.254.254 0 00.068-.186 7.142 7.142 0 01-.011-.387v-3.393a3.044 3.044 0 011.365-2.535.219.219 0 00.1-.2V10.226a.25.25 0 01.25-.25A6.374 6.374 0 0015 9.081a.388.388 0 00.084-.593c-.494-.537-.835-1.185-.981-3.317C13.838 1.346 11.236 0 9 0S4.161 1.346 3.9 5.171c-.146 2.132-.487 2.78-.981 3.317A.388.388 0 003 9.081a6.348 6.348 0 003.782.9.249.249 0 01.253.25v.462a.778.778 0 01-.512.73c-2.9 1.065-5.212 1.825-5.764 2.922A7.822 7.822 0 000 17.076a.386.386 0 00.1.3.4.4 0 00.29.126z\"/><path d=\"M23.072 12.04A16.386 16.386 0 0017.479 11a15.988 15.988 0 00-5.551 1.04A1.545 1.545 0 0011 13.456v3.393a7.716 7.716 0 005.19 6.805l.553.211a2.071 2.071 0 00.736.135 2.04 2.04 0 00.734-.136l.553-.21C21.848 22.48 24 19.682 24 16.849v-3.393a1.545 1.545 0 00-.928-1.416zM18.25 15.5a.25.25 0 00.25.25h2a.75.75 0 010 1.5h-2a.25.25 0 00-.25.25v2a.75.75 0 01-1.5 0v-2a.25.25 0 00-.25-.25h-2a.75.75 0 010-1.5h2a.25.25 0 00.25-.25v-2a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});