define("ember-svg-jar/inlined/toys-fidget-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toys-fidget-spinner</title><path d=\"M23.826 16.736a4.994 4.994 0 00-2.342-3.053l-2.141-1.236a4.643 4.643 0 01-2.316-4.01V5.964a5.028 5.028 0 10-10.055 0v2.473a4.641 4.641 0 01-2.316 4.01l-2.14 1.236a5.027 5.027 0 105.027 8.708l2.141-1.236a4.646 4.646 0 014.632 0l2.14 1.237a5.028 5.028 0 007.37-5.656zM5.369 19.7a1.61 1.61 0 11.59-2.2 1.61 1.61 0 01-.59 2.2zM12 3.817a1.61 1.61 0 11-1.61 1.61A1.61 1.61 0 0112 3.817zM12 16.7a2.683 2.683 0 112.683-2.683A2.683 2.683 0 0112 16.7zm8.829 2.415a1.61 1.61 0 11-.589-2.2 1.61 1.61 0 01.589 2.196z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});