define("ember-svg-jar/inlined/keyboard-alt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-alt</title><path d=\"M7.278 12.747h1.444L8 10.727l-.722 2.02z\"/><path d=\"M19.5.5h-15a4.012 4.012 0 00-4 4v15a4.011 4.011 0 004 4h15a4.011 4.011 0 004-4v-15a4.012 4.012 0 00-4-4zm-8.748 15.7a.738.738 0 01-.252.043.749.749 0 01-.706-.5l-.477-1.336a.252.252 0 00-.236-.166H6.919a.252.252 0 00-.236.166l-.477 1.336a.749.749 0 11-1.412-.5l2.5-7a.749.749 0 011.412 0l2.5 7a.748.748 0 01-.454.957zm3.5-.707a.75.75 0 01-1.5 0v-7a.75.75 0 011.5 0zM19 11.247h-.5a.25.25 0 00-.25.25v3a.25.25 0 00.25.25.75.75 0 010 1.5 1.752 1.752 0 01-1.75-1.75v-3a.25.25 0 00-.25-.25H16a.75.75 0 010-1.5h.5a.249.249 0 00.25-.25v-1a.75.75 0 011.5 0v1a.249.249 0 00.25.25h.5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});