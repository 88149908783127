define("ember-svg-jar/inlined/video-edit-cut", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-edit-cut</title><path d=\"M23 22a2 2 0 01-2-2V4a4 4 0 00-4-4H6a1 1 0 000 2 2 2 0 012 2v6.908a.248.248 0 00.122.215l2.5 1.482a.251.251 0 00.378-.216V9.5a.5.5 0 01.5-.5h6a.5.5 0 01.5.5v5a.5.5 0 01-.5.5h-1.928a.25.25 0 00-.128.465l1.325.785a2.5 2.5 0 01.71.63.5.5 0 00.206.158.5.5 0 01.315.462V21a.5.5 0 01-.5.5H13a2 2 0 01-2-2v-.717a.251.251 0 00-.123-.215l-2.5-1.482A.25.25 0 008 17.3V20a4 4 0 004 4h11a1 1 0 100-2zM17.5 7h-6a.5.5 0 01-.5-.5V3a.5.5 0 01.5-.5H16a2 2 0 012 2v2a.5.5 0 01-.5.5z\"/><path d=\"M2.464 11.842l12.521 7.42A1 1 0 0016 17.541L5.856 11.527a.25.25 0 01-.038-.4 3.558 3.558 0 00.745-.923A3.5 3.5 0 10.005 8.5a3.477 3.477 0 00.458 1.728 3.519 3.519 0 002.001 1.614zM3.505 7a1.5 1.5 0 11-1.5 1.57V8.5a1.5 1.5 0 011.5-1.5zM3.505 14a3.5 3.5 0 103.5 3.5 3.507 3.507 0 00-3.5-3.5zm0 5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});