define("ember-svg-jar/inlined/messages-bubble-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-star</title><path d=\"M9.242 15a3.239 3.239 0 013.016-2h1.185a.25.25 0 00.231-.153C14.424 11.061 14.987 9 17.5 9c2.2 0 3.05 1.853 3.446 2.9a.25.25 0 00.464.009A7.5 7.5 0 0022 9c0-4.963-4.934-9-11-9S0 4.037 0 9a8.08 8.08 0 002.657 5.854l-1.625 4.475a.5.5 0 00.662.632l5.831-2.429a13.024 13.024 0 001.493.312.25.25 0 00.266-.348A3.168 3.168 0 019.242 15z\"/><path d=\"M20.735 23.827a1.275 1.275 0 001.506-.166 1.256 1.256 0 00.316-1.45l-1.3-2.981 2.347-2.092a1.262 1.262 0 00.3-1.378 1.233 1.233 0 00-1.165-.76h-2.665l-1.409-3.267-.013-.028a1.293 1.293 0 00-2.317.028L14.924 15h-2.666a1.228 1.228 0 00-1.166.76 1.269 1.269 0 00.319 1.4l2.329 2.07-1.295 2.98a1.255 1.255 0 00.315 1.451 1.286 1.286 0 001.506.166l3.234-1.821z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});