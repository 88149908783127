define("ember-svg-jar/inlined/single-man-actions-text.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-text</title><path d=\"M.619 18.062H2.41a.246.246 0 01.246.227l.444 5.257a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h.554a.248.248 0 00.248-.248v-5.195a2.94 2.94 0 01.41-1.5.249.249 0 00-.016-.274A5.438 5.438 0 00.124 14.1v3.464a.494.494 0 00.495.498z\"/><path d=\"M1.361 4.206A4.206 4.206 0 105.567 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM11.01 22.021A1.982 1.982 0 0012.99 24h8.91a1.981 1.981 0 001.979-1.979v-8.908a1.981 1.981 0 00-1.979-1.979h-8.91a1.982 1.982 0 00-1.98 1.979zm2.227 0a.247.247 0 01-.247-.248v-8.412a.247.247 0 01.247-.248h8.413a.248.248 0 01.247.248v8.412a.248.248 0 01-.247.248z\"/><path d=\"M19.918 15.093h-4.7a.742.742 0 000 1.484h4.7a.742.742 0 000-1.484zM17.443 19.546a.742.742 0 100-1.484h-2.227a.742.742 0 100 1.484zM.619 18.062H2.41a.246.246 0 01.246.227l.444 5.257a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h.554a.248.248 0 00.248-.248v-5.195a2.94 2.94 0 01.41-1.5.249.249 0 00-.016-.274A5.438 5.438 0 00.124 14.1v3.464a.494.494 0 00.495.498z\"/><path d=\"M1.361 4.206A4.206 4.206 0 105.567 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM11.01 22.021A1.982 1.982 0 0012.99 24h8.91a1.981 1.981 0 001.979-1.979v-8.908a1.981 1.981 0 00-1.979-1.979h-8.91a1.982 1.982 0 00-1.98 1.979zm2.227 0a.247.247 0 01-.247-.248v-8.412a.247.247 0 01.247-.248h8.413a.248.248 0 01.247.248v8.412a.248.248 0 01-.247.248z\"/><path d=\"M19.918 15.093h-4.7a.742.742 0 000 1.484h4.7a.742.742 0 000-1.484zM17.443 19.546a.742.742 0 100-1.484h-2.227a.742.742 0 100 1.484z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});