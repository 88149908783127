define("ember-svg-jar/inlined/laundry-iron-board", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laundry-iron-board</title><path d=\"M24 11a1.5 1.5 0 00-1.5-1.5H1a1 1 0 00-1 1v1a1 1 0 001 1h5.379a.5.5 0 01.353.146l5.177 5.177a.25.25 0 010 .354l-4.116 4.115a1 1 0 101.414 1.414l4.116-4.116a.25.25 0 01.354 0l4.116 4.116a1 1 0 101.414-1.414l-4.116-4.116a.25.25 0 010-.354l5.177-5.177a.5.5 0 01.353-.146H22.5A1.5 1.5 0 0024 11zm-10.323 5.408a.25.25 0 01-.354 0l-3.482-3.482a.25.25 0 01.177-.427h6.964a.25.25 0 01.177.427zM3 8.5h4a.5.5 0 00.5-.5V3.842A5.177 5.177 0 005.68.267a1 1 0 00-1.36 0A5.186 5.186 0 002.5 3.842V8a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});