define("ember-svg-jar/inlined/hair-dress-round-brush", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hair-dress-round-brush</title><path d=\"M7.372 14.506a.5.5 0 00-.707 0L.6 20.567A2 2 0 103.432 23.4l6.061-6.061a.5.5 0 000-.707zM23.688 8.089l-.81-.809a.355.355 0 010-.5 1.5 1.5 0 000-2.122l-3.536-3.537a1.5 1.5 0 00-2.121 0 .356.356 0 01-.5 0L15.91.312a1 1 0 10-1.41 1.414l.706.706a.5.5 0 010 .707l-.54.541a.5.5 0 01-.707 0l-.707-.706a1 1 0 00-1.414 1.414l.707.706a.5.5 0 010 .708L12 6.341a.5.5 0 01-.707 0l-.706-.707a1 1 0 10-1.414 1.414l.706.707a.5.5 0 010 .707L9.338 9a.5.5 0 01-.707 0l-.706-.7a1 1 0 00-1.414 1.41l.809.81a.352.352 0 010 .5 1.5 1.5 0 000 2.122l3.536 3.536a1.5 1.5 0 002.121 0 .355.355 0 01.5 0l.809.81a1 1 0 001.414-1.415l-.7-.706a.5.5 0 010-.707l.54-.54a.5.5 0 01.353-.147.5.5 0 01.354.146l.707.707a1 1 0 101.414-1.414l-.707-.706a.5.5 0 01-.147-.354.5.5 0 01.144-.352l.54-.541a.5.5 0 01.708 0l.706.706a1 1 0 101.414-1.414l-.706-.706a.5.5 0 010-.707l.54-.54a.5.5 0 01.707 0l.707.706a1 1 0 101.414-1.414zM11.9 13.516a1 1 0 110-1.414 1 1 0 010 1.414zm2.6-2.607a1 1 0 110-1.414 1 1 0 010 1.414zM17.11 8.3a1 1 0 110-1.414 1 1 0 010 1.414zm2.607-2.6a1 1 0 110-1.414 1 1 0 010 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});