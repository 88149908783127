define("ember-svg-jar/inlined/single-neutral-actions-flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-flag</title><path d=\"M0 17.123a.5.5 0 00.5.5h1.809a.25.25 0 01.249.229L3 23.163a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.311a.25.25 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 00-11 0z\"/><circle cx=\"5.498\" cy=\"3.628\" r=\"3.499\"/><path d=\"M24 13.244a.5.5 0 00-.709-.454c-2.177 1.01-3.06.405-3.6.04a4.106 4.106 0 00-3.6-.49l-1.268.43a.5.5 0 00-.339.474c0 9.367.008 2.134.008 9.627a1 1 0 102 0v-3.913a.251.251 0 01.191-.243 3.041 3.041 0 012.446.441 4.026 4.026 0 002.27.7 4.253 4.253 0 001.376-.233l.933-.429a.5.5 0 00.29-.454zM0 17.123a.5.5 0 00.5.5h1.809a.25.25 0 01.249.229L3 23.163a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.311a.25.25 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 00-11 0z\"/><circle cx=\"5.498\" cy=\"3.628\" r=\"3.499\"/><path d=\"M24 13.244a.5.5 0 00-.709-.454c-2.177 1.01-3.06.405-3.6.04a4.106 4.106 0 00-3.6-.49l-1.268.43a.5.5 0 00-.339.474c0 9.367.008 2.134.008 9.627a1 1 0 102 0v-3.913a.251.251 0 01.191-.243 3.041 3.041 0 012.446.441 4.026 4.026 0 002.27.7 4.253 4.253 0 001.376-.233l.933-.429a.5.5 0 00.29-.454z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});