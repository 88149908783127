define("ember-svg-jar/inlined/cd-partition", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cd-partition</title><path d=\"M12 .5A11.5 11.5 0 1023.5 12 11.5 11.5 0 0012 .5zM14.25 12A2.25 2.25 0 1112 9.751 2.253 2.253 0 0114.25 12zM9.8 21.733a.458.458 0 01-.115-.014 9.933 9.933 0 01-7.406-7.405.5.5 0 01.37-.6l5.368-1.278a.251.251 0 01.2.034.248.248 0 01.108.167 3.731 3.731 0 003.042 3.041.251.251 0 01.2.3l-1.278 5.367a.5.5 0 01-.489.388zm11.549-11.444l-5.368 1.278a.252.252 0 01-.2-.035.248.248 0 01-.108-.166 3.733 3.733 0 00-3.042-3.042.251.251 0 01-.2-.3l1.278-5.368a.494.494 0 01.6-.37 9.933 9.933 0 017.406 7.4.5.5 0 01-.366.603z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});