define("ember-svg-jar/inlined/modern-weapon-machine-gun-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-weapon-machine-gun-1</title><path d=\"M22.781.788A1 1 0 0021.366.8l-.683.693a.248.248 0 01-.177.075.251.251 0 01-.178-.073l-1.2-1.2a1 1 0 00-1.414 1.414l1.213 1.212a.251.251 0 010 .353L16.19 6.05a.5.5 0 000 .7l.738.745a.451.451 0 00.643 0l5.22-5.295a1 1 0 00-.01-1.412zM18.548 12.435a6.574 6.574 0 01-3.09-1.346.5.5 0 01-.193-.363.5.5 0 01.145-.386l.806-.8a1 1 0 000-1.412l-2.11-2.129a1.006 1.006 0 00-.711-.3 1 1 0 00-.711.3l-6.541 6.69a1.491 1.491 0 00-.428 1.049v1.781a.249.249 0 01-.073.177l-4.428 4.426a1 1 0 000 1.414l2.172 2.171a1 1 0 001.587-.232l2.792-5.185a.5.5 0 01.794-.116l1.8 1.8a.5.5 0 00.707 0l1.293-1.293a.5.5 0 000-.707l-1.94-1.94a.5.5 0 010-.707l2.151-2.147a.5.5 0 01.663-.038 6.708 6.708 0 004.018 1.491.5.5 0 00.37-.146l1.2-1.2a.5.5 0 00-.277-.848z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});