define("ember-svg-jar/inlined/cursor-move-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-move-right</title><path d=\"M14.423 18.977a8 8 0 11-.344-14.133 1 1 0 10.894-1.788A10 10 0 1015.4 20.72a1 1 0 00-.981-1.743z\"/><path d=\"M22.91 11.091l-8.523-3.826A1 1 0 0013.122 8.7l1.93 3.176a.251.251 0 010 .259l-1.942 3.234a1 1 0 001.273 1.423l8.531-3.878a1 1 0 000-1.823z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});