define("ember-svg-jar/inlined/composition-oval", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>composition-oval</title><path d=\"M24 4.89a1.745 1.745 0 00-1.2-1.66C14.844.59 9.191.589 1.2 3.23A1.746 1.746 0 000 4.891v14.217a1.748 1.748 0 001.2 1.662 33.863 33.863 0 0010.812 1.981A33.68 33.68 0 0022.8 20.769a1.746 1.746 0 001.2-1.66zM21.5 18.2a.5.5 0 01-.351.477 27.262 27.262 0 01-18.3 0A.5.5 0 012.5 18.2V5.8a.5.5 0 01.351-.477 27.25 27.25 0 0118.3 0 .5.5 0 01.349.477z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});