define("ember-svg-jar/inlined/cloud-smartphone-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-smartphone-download</title><path d=\"M11.647 9.853a.5.5 0 00.707 0l2.5-2.5A.5.5 0 0014.5 6.5h-1.25a.25.25 0 01-.25-.25V4.5a1 1 0 00-2 0v1.75a.25.25 0 01-.25.25H9.5a.5.5 0 00-.353.853z\"/><path d=\"M4.953 15.4A1.012 1.012 0 006 14.386a1.019 1.019 0 00-.983-1.013c-1.453-.062-1.878-.292-2.378-.783A2.375 2.375 0 012 10.8a2.588 2.588 0 012.586-2.583 2.7 2.7 0 01.535.054 1 1 0 001.2-.963c.089-5.736 8.296-7.373 10.821-2.045a.98.98 0 00.941.571 3.726 3.726 0 013 1.306 3.882 3.882 0 01.9 2.867 3.7 3.7 0 01-2.773 3.253.983.983 0 00-.71.941.986.986 0 001.288.934A5.538 5.538 0 0024 9.876a5.767 5.767 0 00-5.215-6.019.252.252 0 01-.191-.12 7.684 7.684 0 00-14.1 2.294.251.251 0 01-.227.2A4.643 4.643 0 00.624 8.47 4.467 4.467 0 000 10.918a4.289 4.289 0 001.235 3.1A5.473 5.473 0 004.953 15.4z\"/><path d=\"M17 13.25a1.752 1.752 0 00-1.75-1.75h-6a1.752 1.752 0 00-1.75 1.75v9A1.752 1.752 0 009.25 24h6A1.752 1.752 0 0017 22.25zm-2 7a.25.25 0 01-.25.25h-5a.25.25 0 01-.25-.25v-6.5a.251.251 0 01.25-.25h5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});