define("ember-svg-jar/inlined/laptop-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-sync</title><path d=\"M9.719 15.289a2.492 2.492 0 01-1.668-1.429.492.492 0 00-.47-.36H.5a.478.478 0 00-.41.22.467.467 0 00-.05.47 5.43 5.43 0 005 3.31h4.248a.25.25 0 00.249-.228 7.559 7.559 0 01.341-1.672.25.25 0 00-.159-.311zM3.5 12.01a1 1 0 001-1v-8a1 1 0 011-1h12a1 1 0 011 1v6.843a.249.249 0 00.211.247 7.828 7.828 0 011.454.371.247.247 0 00.228-.031.25.25 0 00.107-.2V3.01a3.009 3.009 0 00-3-3h-12a3.009 3.009 0 00-3 3v8a1 1 0 001 1zM22.77 18.6a1 1 0 00-1.27.622 4.091 4.091 0 01-6.918 1.42.25.25 0 01.008-.345l1.721-1.721a.5.5 0 00-.353-.854H11.5a.5.5 0 00-.5.5v4.458a.5.5 0 00.853.353l.97-.969a.251.251 0 01.359 0 6.085 6.085 0 0010.209-2.2 1 1 0 00-.621-1.264z\"/><path d=\"M23.692 11.773a.5.5 0 00-.545.108l-1.025 1.026a.251.251 0 01-.358 0 6.1 6.1 0 00-10.195 2.226 1 1 0 101.892.649 4.09 4.09 0 016.9-1.445.249.249 0 01-.006.346l-1.66 1.659a.5.5 0 00.354.854H23.5a.5.5 0 00.5-.5v-4.461a.5.5 0 00-.308-.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});