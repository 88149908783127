define("ember-svg-jar/inlined/adventure-car-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>adventure-car-4</title><path d=\"M2.5 13h3.689a.249.249 0 01.177.073l1.488 1.488a1.49 1.49 0 001.06.439h6.172a1.49 1.49 0 001.06-.439l1.488-1.488a.249.249 0 01.177-.073H21.5a.5.5 0 00.416-.223.5.5 0 00.045-.469L21 10a1.614 1.614 0 00-.969-.9.249.249 0 01-.158-.164L18.53 4.522a2.485 2.485 0 00-2.391-1.772H15.5a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v1.5a.25.25 0 01-.25.25H7.862A2.487 2.487 0 005.47 4.522L4.128 8.933a.253.253 0 01-.158.167A1.61 1.61 0 003 10l-.961 2.308a.5.5 0 00.045.469A.5.5 0 002.5 13zm3.8-4.323L7.383 5.1a.5.5 0 01.479-.354h8.277a.5.5 0 01.478.354L17.7 8.677a.251.251 0 01-.24.323h-.879a.25.25 0 01-.243-.189l-.1-.4A1.861 1.861 0 0014.435 7H9.564a1.86 1.86 0 00-1.807 1.412l-.1.4A.25.25 0 017.414 9h-.879a.25.25 0 01-.235-.323z\"/><path d=\"M22.466 14h-3.932A1.536 1.536 0 0017 15.534v1.443a.25.25 0 01-.119.213.248.248 0 01-.243.011l-2.191-1.1A1.207 1.207 0 0014 16h-4a1.207 1.207 0 00-.447.105L7.362 17.2a.248.248 0 01-.243-.011.25.25 0 01-.119-.212v-1.443A1.536 1.536 0 005.466 14H1.534A1.536 1.536 0 000 15.534v6.932A1.536 1.536 0 001.534 24h3.932A1.536 1.536 0 007 22.466v-2.693a.249.249 0 01.138-.224l2.768-1.384a.251.251 0 01.293.051 2.461 2.461 0 003.6 0 .251.251 0 01.293-.051l2.768 1.384a.249.249 0 01.138.224v2.693A1.536 1.536 0 0018.534 24h3.932A1.536 1.536 0 0024 22.466v-6.932A1.536 1.536 0 0022.466 14zM5.724 20.947l-2 1a.5.5 0 01-.448 0l-2-1a.5.5 0 11.448-.894l1.664.832a.246.246 0 00.224 0l1.664-.832a.5.5 0 11.448.894zm0-3.5l-2 1a.5.5 0 01-.448 0l-2-1a.5.5 0 11.448-.894l1.664.832a.246.246 0 00.224 0l1.664-.832a.5.5 0 01.448.894zm17 3.5l-2 1a.5.5 0 01-.448 0l-2-1a.5.5 0 11.448-.894l1.664.832a.246.246 0 00.224 0l1.664-.832a.5.5 0 11.448.894zm0-3.5l-2 1a.5.5 0 01-.448 0l-2-1a.5.5 0 11.448-.894l1.664.832a.246.246 0 00.224 0l1.664-.832a.5.5 0 11.448.894z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});