define("ember-svg-jar/inlined/monetization-team", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monetization-team</title><path d=\"M4 21a3 3 0 00-3 3h6a3 3 0 00-3-3zM9 24h6a3 3 0 00-6 0zM17 24h6a3 3 0 00-6 0z\"/><circle cx=\"4\" cy=\"18.5\" r=\"2\"/><circle cx=\"12\" cy=\"18.5\" r=\"2\"/><circle cx=\"20\" cy=\"18.5\" r=\"2\"/><path d=\"M12.331 7.5H10a1 1 0 000 2h.75a.25.25 0 01.25.25V10a1 1 0 002 0v-.26a.25.25 0 01.229-.249A1.834 1.834 0 0015 7.5c0-1.186-1.187-1.984-2.441-2.83-.3-.2-.711-.479-1.038-.718a.251.251 0 01.148-.452H14a1 1 0 000-2h-.75a.249.249 0 01-.25-.25V1a1 1 0 00-2 0v.259a.249.249 0 01-.232.249A1.792 1.792 0 009 3.5c0 1.187 1.186 1.984 2.441 2.829.3.2.712.48 1.038.72a.25.25 0 01-.148.451zM4 15.5a1 1 0 001-1v-.25a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25v.25a1 1 0 002 0v-.25a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25v.25a1 1 0 002 0V13a1 1 0 00-1-1H4a1 1 0 00-1 1v1.5a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});