define("ember-svg-jar/inlined/video-game-bottle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-bottle</title><path d=\"M7.619 2.716a7.662 7.662 0 011.53 1.816.5.5 0 00.541.232 10.262 10.262 0 014.564-.014.5.5 0 00.536-.228 7.068 7.068 0 011.52-1.746 3.346 3.346 0 001.078-1.571.918.918 0 00-.147-.817.965.965 0 00-.77-.388H7.529a.965.965 0 00-.77.388.917.917 0 00-.147.816 3.324 3.324 0 001.007 1.512z\"/><path d=\"M22.448 18.992L20.487 12a9 9 0 00-16.987.032l-1.949 6.96A3.942 3.942 0 005.35 24h13.3a3.945 3.945 0 003.8-5.008zM9.4 10.8c.214-.211.732-.713 2.082-2.08a.736.736 0 011.042 0c.793.8 1.673 1.66 2.081 2.091a.251.251 0 01-.071.4l-2.307 1.153a.5.5 0 01-.448 0L9.46 11.2a.25.25 0 01-.063-.4zm-.344 7.223V12.8a.252.252 0 01.118-.213.255.255 0 01.244-.011l1.684.843a.5.5 0 01.276.447v3.091a.5.5 0 01-.276.447l-1.684.842a.25.25 0 01-.362-.223zm5.684 2.049c-.2.223-.7.677-2.216 2.216a.736.736 0 01-1.042 0c-1.51-1.538-2.014-1.991-2.216-2.217a.25.25 0 01.075-.39l2.438-1.219a.5.5 0 01.448 0l2.438 1.219a.251.251 0 01.075.391zm.21-2.05a.249.249 0 01-.361.224L12.9 17.4a.5.5 0 01-.276-.447v-3.091a.5.5 0 01.276-.447l1.685-.842a.248.248 0 01.361.223z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});