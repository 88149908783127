define("ember-svg-jar/inlined/koala-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>koala-body</title><path d=\"M21.02 10l-3.534.005a.251.251 0 00-.188.086 6.994 6.994 0 01-10.406.175.254.254 0 00-.2-.079.248.248 0 00-.188.106c-1.093 1.568-2.759 3.965-3.4 4.93a5.8 5.8 0 00.562 6.631A6.641 6.641 0 008.52 24h4.67a2.473 2.473 0 001.761-.735l2.422-2.411a2.433 2.433 0 00.764-1.571 1.78 1.78 0 00-.489-1.378 1.475 1.475 0 00-1.171-.431 2.116 2.116 0 00-1.311.672l-1.146 1.147-.72-.72a.251.251 0 00-.177-.073h-2.794a.75.75 0 010-1.5h3.343a.251.251 0 00.223-.138l1.011-2.029A1.515 1.515 0 0116.249 14h2.271a2.922 2.922 0 002.352-.942 3.562 3.562 0 00.641-2.64.5.5 0 00-.493-.418z\"/><path d=\"M5.306 7.435a6.709 6.709 0 001.5-.184 5.5 5.5 0 0010.421 0 6.7 6.7 0 001.492.183A3.221 3.221 0 1019.016 1a5.07 5.07 0 00-2.8.949 5.492 5.492 0 00-8.393 0A5.07 5.07 0 005.016 1a3.223 3.223 0 10.29 6.435zM15.329 4.5a1 1 0 11-1 1 1 1 0 011-1zm-3.313.5c1 0 1.5 2.5 1.5 2.5a1.5 1.5 0 01-3 0s.5-2.5 1.5-2.5zm-3.187-.5a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});