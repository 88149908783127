define("ember-svg-jar/inlined/file-code-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-edit</title><path d=\"M18 11.5V15a.75.75 0 001.5 0v-3.5a.75.75 0 10-1.5 0zM15.5 14.5V12a1.252 1.252 0 00-1.25-1.25h-1.5A1.252 1.252 0 0011.5 12v2.5a1.251 1.251 0 001.25 1.25h1.5a1.251 1.251 0 001.25-1.25zM14 14a.249.249 0 01-.25.25h-.5A.249.249 0 0113 14v-1.5a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25zM14 8.5V5a.75.75 0 00-1.5 0v3.5a.75.75 0 001.5 0zM20.5 8V5.5a1.252 1.252 0 00-1.25-1.25h-1.5A1.252 1.252 0 0016.5 5.5V8a1.251 1.251 0 001.25 1.25h1.5A1.251 1.251 0 0020.5 8zM19 7.5a.249.249 0 01-.25.25h-.5A.249.249 0 0118 7.5V6a.25.25 0 01.25-.25h.5A.25.25 0 0119 6z\"/><path d=\"M23.664 3L21.25.587A2 2 0 0019.835 0H8.25a2 2 0 00-2 2v9.57a.247.247 0 00.073.176l1.5 1.5a.25.25 0 00.427-.176V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.147L22.1 4.269a.5.5 0 01.146.354V18a.5.5 0 01-.5.5h-8.319a.247.247 0 00-.234.334c.162.437.359 1.153.447 1.485a.243.243 0 00.24.18h8.37a2 2 0 002-2V4.416A2 2 0 0023.664 3zM10.9 18.445l-6.58-6.58a2.71 2.71 0 00-3.606-.273 2.622 2.622 0 00-.2 3.881l6.679 6.679a.249.249 0 00.353 0L10.9 18.8a.252.252 0 000-.355zM11.688 20.132L8.879 22.94a.25.25 0 00.128.422l3.143.629a.55.55 0 00.1.009.5.5 0 00.49-.6l-.63-3.14a.25.25 0 00-.422-.128z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});