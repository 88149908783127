define("ember-svg-jar/inlined/artboard-expand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>artboard-expand</title><path d=\"M23 17.25h-1.5a1 1 0 000 2H23a1 1 0 000-2zM2.5 5.25H1a1 1 0 000 2h1.5a1 1 0 000-2zM19 7.25a2 2 0 00-2-2H7.25A.25.25 0 017 5V2.25a1 1 0 00-2 0v15a2 2 0 002 2h9.75a.25.25 0 01.25.25v2.75a1 1 0 002 0zm-11.5 10a.5.5 0 01-.5-.5v-9a.5.5 0 01.5-.5h9a.5.5 0 01.5.5v9a.5.5 0 01-.5.5zM21.207 4.457l2-2a1 1 0 00-1.414-1.414l-2 2a1 1 0 001.414 1.414z\"/><path d=\"M15.707 8.543a1 1 0 00-1.414 0l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5a1 1 0 000-1.414zM10.293 12.543l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5a1 1 0 00-1.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});