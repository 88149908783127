define("ember-svg-jar/inlined/database-subtract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-subtract</title><path d=\"M8.7 21.391a.25.25 0 00.249-.359 7.937 7.937 0 01-.8-3 .249.249 0 00-.216-.231 12.8 12.8 0 01-6.148-2.211.25.25 0 00-.408.194V17.5c0 1.9 3.129 3.477 7.323 3.891zM1.377 13c0 1.771 2.735 3.271 6.522 3.8a.251.251 0 00.187-.05.257.257 0 00.1-.169 7.923 7.923 0 01.874-2.808.25.25 0 00-.2-.366c-2.822-.255-5.506-1.041-7.072-2.315a.25.25 0 00-.408.194zM10.878 9c-3.5 0-7.156-.833-9.093-2.411a.25.25 0 00-.408.194V8.5c0 2.053 3.672 3.744 8.4 3.974a.253.253 0 00.2-.09 7.957 7.957 0 019.308-2.222.251.251 0 00.272-.048 2.263 2.263 0 00.82-1.614V6.785a.249.249 0 00-.407-.194C18.032 8.168 14.382 9 10.878 9z\"/><ellipse cx=\"10.878\" cy=\"4\" rx=\"9.5\" ry=\"4\"/><path d=\"M16.123 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-3.25 6.5a.75.75 0 01.75-.75h5a.75.75 0 110 1.5h-5a.75.75 0 01-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});