define("ember-svg-jar/inlined/cursor-select-frame-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-select-frame-1</title><path d=\"M9 0H8a1 1 0 00-1 1v5.75a.25.25 0 01-.25.25H1a1 1 0 00-1 1v1a1 1 0 001 1h7a2 2 0 002-2V1a1 1 0 00-1-1zM23 7h-5.75a.25.25 0 01-.25-.25V1a1 1 0 00-1-1h-1a1 1 0 00-1 1v7a2 2 0 002 2h7a1 1 0 001-1V8a1 1 0 00-1-1zM8 14H1a1 1 0 00-1 1v1a1 1 0 001 1h5.75a.25.25 0 01.25.25V23a1 1 0 001 1h1a1 1 0 001-1v-7a2 2 0 00-2-2zM23 14h-7a2 2 0 00-2 2v7a1 1 0 001 1h1a1 1 0 001-1v-5.75a.25.25 0 01.25-.25H23a1 1 0 001-1v-1a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});