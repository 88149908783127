define("ember-svg-jar/inlined/style-three-pin-plane-world", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-plane-world</title><path d=\"M10.2 6a.25.25 0 00.229-.35 5.62 5.62 0 00-.732-1.238.247.247 0 00-.2-.094.25.25 0 00-.2.094A5.6 5.6 0 008.57 5.65.25.25 0 008.8 6zM12 8.5a8.344 8.344 0 01-.1 1.249.219.219 0 00.217.251h1.425a.249.249 0 00.238-.174 4.124 4.124 0 000-2.652.249.249 0 00-.236-.174h-1.387a.254.254 0 00-.189.086.25.25 0 00-.058.2A8.464 8.464 0 0112 8.5zM7 8.5a8.464 8.464 0 01.09-1.214A.249.249 0 006.843 7H5.456a.249.249 0 00-.238.174 4.142 4.142 0 000 2.652.249.249 0 00.238.174h1.387a.254.254 0 00.189-.086.25.25 0 00.058-.2A8.464 8.464 0 017 8.5zM11.576 5.83a.25.25 0 00.237.17h.921a.25.25 0 00.19-.413 4.481 4.481 0 00-1.451-1.11.25.25 0 00-.33.341 6.882 6.882 0 01.433 1.012zM11 8.5a7.371 7.371 0 00-.118-1.294.251.251 0 00-.247-.206H8.363a.25.25 0 00-.246.206 7.184 7.184 0 000 2.588.251.251 0 00.248.206h2.271a.25.25 0 00.247-.207A7.526 7.526 0 0011 8.5z\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM9.5 14a5.5 5.5 0 115.047-3.313v.007a.52.52 0 01-.469.306H8.8a.25.25 0 00-.229.351A4.878 4.878 0 009.794 13.1l.01.008A.5.5 0 019.5 14zm10.047.092l-6.6 4.1a.4.4 0 01-.447-.013l-2.4-1.692a.224.224 0 01-.046-.323.273.273 0 01.034-.034l.01-.008 1-.884a.223.223 0 01.222-.044l1.133.676a.251.251 0 00.271-.01l1.169-.816a.25.25 0 00-.011-.417l-2.711-1.677a.256.256 0 01-.083-.084.279.279 0 01.027-.324.285.285 0 01.044-.041l.748-.554a.276.276 0 01.262-.037l4.091 1.523a.25.25 0 00.228-.028l1.842-1.257a1.084 1.084 0 01.855-.164 1.158 1.158 0 01.832.747 1.1 1.1 0 01.064.369 1.147 1.147 0 01-.534.992z\"/><path d=\"M7.187 6a.249.249 0 00.237-.17 6.882 6.882 0 01.433-1.012.25.25 0 00-.33-.341 4.481 4.481 0 00-1.451 1.11.251.251 0 00.19.413zM7.424 11.17a.25.25 0 00-.237-.17h-.921a.25.25 0 00-.189.413 4.478 4.478 0 001.45 1.11.25.25 0 00.33-.342 6.868 6.868 0 01-.433-1.011z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});