define("ember-svg-jar/inlined/right-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.5 6.001h7.984l-2.342 2.42a.522.522 0 000 .715l.684.717a.47.47 0 00.684 0l4.35-4.506a.526.526 0 000-.717L7.538.15a.466.466 0 00-.684 0L6.17.866a.523.523 0 000 .716l2.341 2.419H.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "12",
      "height": "10.001",
      "viewBox": "0 0 12 10.001"
    }
  };
});