define("ember-svg-jar/inlined/landmark-totem-pole", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-totem-pole</title><path d=\"M6.5 11h-3a.5.5 0 00-.5.5 2 2 0 002 2h1.5A.5.5 0 007 13v-1.5a.5.5 0 00-.5-.5zM6.5 6H1a1 1 0 00-.969 1.249A3 3 0 002.938 9.5H6.5A.5.5 0 007 9V6.5a.5.5 0 00-.5-.5zM20.5 11h-3a.5.5 0 00-.5.5V13a.5.5 0 00.5.5H19a2 2 0 002-2 .5.5 0 00-.5-.5zM23 6h-5.5a.5.5 0 00-.5.5V9a.5.5 0 00.5.5h3.562a3 3 0 002.907-2.252A1 1 0 0023 6zM12 0a4 4 0 00-4 4v11a.25.25 0 00.416.186l.836-.743a.751.751 0 011 0l1.586 1.41a.25.25 0 00.332 0l1.586-1.41a.751.751 0 011 0l.836.743A.25.25 0 0016 15V4a4 4 0 00-4-4zm1 4.5a.5.5 0 011 0v1a.5.5 0 01-1 0zm-3 0a.5.5 0 011 0v1a.5.5 0 01-1 0zm4.994 3.927a.5.5 0 01-.182.464l-2.5 2a.5.5 0 01-.625 0l-2.5-2a.5.5 0 01-.182-.464.5.5 0 01.308-.391l2.5-1a.5.5 0 01.372 0l2.5 1a.5.5 0 01.309.391zM15.5 21.25h-7a.5.5 0 00-.5.5V23a1 1 0 001 1h6a1 1 0 001-1v-1.25a.5.5 0 00-.5-.5zM14.582 16.3a.5.5 0 00-.664 0L12.5 17.561a.751.751 0 01-1 0L10.082 16.3a.5.5 0 00-.664 0l-1.25 1.11a.5.5 0 00-.168.373v1.467a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-1.467a.5.5 0 00-.168-.373z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});