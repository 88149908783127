define("ember-svg-jar/inlined/lock-6", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lock-6</title><path d=\"M18.75 10.063V6.748a6.75 6.75 0 00-13.5 0v3.315A4.986 4.986 0 002.5 14.5V19a5.005 5.005 0 005 5h9a5.005 5.005 0 005-5v-4.5a4.986 4.986 0 00-2.75-4.437zM14 16.452a2 2 0 11-2-2 2 2 0 012 2zM12 2.5a4.255 4.255 0 014.25 4.249V9a.5.5 0 01-.5.5h-7.5a.5.5 0 01-.5-.5V6.748A4.255 4.255 0 0112 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});