define("ember-svg-jar/inlined/pet-tracking-dog-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pet-tracking-dog-location</title><path d=\"M18.687 9.841c.06.02.11.04.16.06l.08-.15a2.479 2.479 0 012.15-1.25A2.926 2.926 0 0024 5.551 2.028 2.028 0 0021.987 3.5a1.043 1.043 0 01-.94-.58 2.557 2.557 0 00-2.023-1.406.505.505 0 01-.364-.22L17.947.221A.524.524 0 0017.5 0a.5.5 0 00-.42.28L15.067 4.3a3.171 3.171 0 01-.471.829.5.5 0 00.041.66zm.592-6.341a.75.75 0 11-.75.75.749.749 0 01.75-.75zM16.122 9.76a.5.5 0 00-.125-.5L13.221 6.5a.5.5 0 00-.485-.129 3.5 3.5 0 01-.919.126h-6.79a3.01 3.01 0 00-.74.1.5.5 0 01-.432-.091C1.66 4.791 2.018 1.149 2.027 1.111a1 1 0 00-1.99-.22c-.019.182-.427 4.161 1.992 6.653a.5.5 0 01.064.615 3.365 3.365 0 00-.536 1.832l-.028 1.776a.492.492 0 01-.053.216l-1.4 2.8a.545.545 0 00-.05.22v2.75a1.75 1.75 0 003.5 0v-1.64a1.559 1.559 0 01.25-.84l1.07-1.6A1.5 1.5 0 016.1 13h6.48a.5.5 0 00.42-.233 8.156 8.156 0 012.733-2.667.5.5 0 00.389-.34zM19 11a5 5 0 00-5 5c0 3.17 4.46 7.66 4.65 7.85A.485.485 0 0019 24a.469.469 0 00.35-.15C19.54 23.66 24 19.17 24 16a5 5 0 00-5-5zm0 6.75A1.75 1.75 0 1120.75 16 1.758 1.758 0 0119 17.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});