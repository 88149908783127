define("ember-svg-jar/inlined/protection-shield-knight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>protection-shield-knight</title><path d=\"M24 1.953A1.958 1.958 0 0022.044.006H1.959A1.958 1.958 0 00.012 1.965L0 9.305a15.148 15.148 0 0011.861 14.67 1 1 0 00.4 0A15.145 15.145 0 0023.988 9.2zm-6 10.8a.5.5 0 01-.685.465A19.429 19.429 0 0014 12.212a.249.249 0 00-.293.293 19.412 19.412 0 001 3.316.5.5 0 01-.464.685H9.754a.5.5 0 01-.464-.685 19.412 19.412 0 001-3.316.249.249 0 00-.29-.293 19.429 19.429 0 00-3.316 1.005.5.5 0 01-.684-.465V8.26a.5.5 0 01.685-.46A19.44 19.44 0 0010 8.8a.249.249 0 00.293-.293 19.44 19.44 0 00-1-3.316.5.5 0 01.464-.685h4.492a.5.5 0 01.464.685 19.44 19.44 0 00-1 3.316A.249.249 0 0014 8.8a19.44 19.44 0 003.316-1 .5.5 0 01.684.46z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});