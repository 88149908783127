define("ember-svg-jar/inlined/cloud-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-warning</title><path d=\"M3.924 17.376a1 1 0 001.052-.534 1.018 1.018 0 00-.743-1.476 2.518 2.518 0 01-1.594-.707A2.371 2.371 0 012 12.874a2.588 2.588 0 012.586-2.587 2.635 2.635 0 01.535.056 1.008 1.008 0 00.811-.186 1 1 0 00.392-.779 4.521 4.521 0 01.961-3.012c3.357-3.8 8.227-2.47 9.856.966a1.024 1.024 0 00.978.57 3.666 3.666 0 012.721 1.05A3.757 3.757 0 0122 11.679a3.9 3.9 0 01-2.483 3.61.969.969 0 00-.586 1.373.956.956 0 001.162.47 5.6 5.6 0 003.9-5.2 5.769 5.769 0 00-5.215-6.007.252.252 0 01-.191-.12 7.685 7.685 0 00-14.1 2.3.251.251 0 01-.227.2 4.642 4.642 0 00-3.64 2.242A4.482 4.482 0 000 12.994a4.287 4.287 0 001.235 3.09 5.057 5.057 0 002.689 1.292z\"/><path d=\"M13.281 10.2a1.45 1.45 0 00-2.562 0l-5.056 9.634a1.429 1.429 0 00.049 1.408 1.454 1.454 0 001.233.687h10.111a1.456 1.456 0 001.233-.687 1.431 1.431 0 00.048-1.408zM12 20.179a1 1 0 111-1 1 1 0 01-1 1zm-.75-6.75a.75.75 0 011.5 0v3a.75.75 0 01-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});