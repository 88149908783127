define("ember-svg-jar/inlined/smiley-blush-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-blush-alternate</title><path d=\"M12 16.5a5.306 5.306 0 00-3.707 1.293.993.993 0 00-.006 1.4 1.008 1.008 0 001.408.03A3.46 3.46 0 0112 18.5a3.478 3.478 0 012.293.707 1 1 0 001.414-1.414A5.306 5.306 0 0012 16.5zM16.707 11.793a1 1 0 00-1.414 0l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5a1 1 0 000-1.414zM20.207 11.793a1 1 0 00-1.414 0l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5a1 1 0 000-1.414zM6.707 11.793a1 1 0 00-1.414 0l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5a1 1 0 000-1.414zM10.207 13.207a1 1 0 00-1.414-1.414l-1.5 1.5a1 1 0 101.414 1.414z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><circle cx=\"8\" cy=\"8\" r=\"2\"/><circle cx=\"16\" cy=\"8\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});