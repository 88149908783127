define("ember-svg-jar/inlined/style-three-pin-swimming", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-swimming</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM6 12.439a.5.5 0 01.235-.424l4.092-2.561a1 1 0 00.3-1.4 3.039 3.039 0 01.558-3.977l.006-.006 1.3-1.084h.005A1 1 0 0113.8 4.5h-.005l-1.312 1.09-.009.007a1 1 0 00-.17 1.319l.009.013 2.445 3.839a.3.3 0 01-.253.461 1.726 1.726 0 00-1.632 1.124.5.5 0 01-.3.3 1.749 1.749 0 01-.577.1 1.872 1.872 0 01-.55-.084.5.5 0 01-.317-.3 1.752 1.752 0 00-1.624-1.136 1.726 1.726 0 00-1.632 1.124.5.5 0 01-.3.3 1.749 1.749 0 01-.577.1 1.723 1.723 0 00-.49.071.4.4 0 01-.506-.389zM17 15a3.624 3.624 0 01-2.191-.693.5.5 0 00-.617 0 3.812 3.812 0 01-4.383 0 .5.5 0 00-.617 0A3.627 3.627 0 017 15a.5.5 0 010-1c.827 0 1.811-.353 2.018-1.128a.519.519 0 01.965 0C10.19 13.647 11.174 14 12 14s1.811-.353 2.018-1.128a.519.519 0 01.965 0C15.19 13.647 16.174 14 17 14a.5.5 0 010 1zm-.5-5.5a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});