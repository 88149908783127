define("ember-svg-jar/inlined/style-three-pin-lotus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-lotus</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM6.627 6.8a.5.5 0 01.385-.535 5.819 5.819 0 011.562-.1.5.5 0 01.461.565A3.956 3.956 0 009 7.25a4.561 4.561 0 00.145 1.123.5.5 0 00.968-.246 4.433 4.433 0 011.558-4.253.5.5 0 01.658 0 4.454 4.454 0 011.558 4.253.5.5 0 00.361.607.478.478 0 00.123.016.5.5 0 00.484-.377A4.561 4.561 0 0015 7.25a3.927 3.927 0 00-.036-.515.5.5 0 01.46-.567 5.81 5.81 0 011.564.1.5.5 0 01.385.535 5.06 5.06 0 01-1.77 3.326 4.36 4.36 0 01-2.543.728.31.31 0 00-.31.31v.586a.75.75 0 01-1.5 0v-.4a.5.5 0 00-.486-.5 4.247 4.247 0 01-2.364-.727A5.062 5.062 0 016.627 6.8zM12 15.25c-3.248 0-5.792-1.537-5.792-3.5a2.353 2.353 0 01.3-1.1.75.75 0 011.33.692.931.931 0 00-.129.4c0 .944 1.836 2 4.292 2s4.292-1.056 4.292-2a.931.931 0 00-.129-.4.75.75 0 011.33-.692 2.353 2.353 0 01.3 1.1c-.002 1.963-2.546 3.5-5.794 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});