define("ember-svg-jar/inlined/real-estate-update-house-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-update-house-sync</title><path d=\"M8.893 15.464a.5.5 0 00.5.5h5.714a.5.5 0 00.5-.5v-3a.5.5 0 01.5-.5h.143a.5.5 0 00.325-.88l-4-3.428a.5.5 0 00-.65 0l-4 3.428a.5.5 0 00.325.88h.143a.5.5 0 01.5.5z\"/><path d=\"M5.387 12.647a.75.75 0 00-.635-1.147h-.427a.5.5 0 01-.5-.565A8.242 8.242 0 0118.47 6.882a1 1 0 101.568-1.242 10.24 10.24 0 00-18.23 5.406.5.5 0 01-.5.454H.751a.75.75 0 00-.636 1.147l2 3.2a.751.751 0 001.272 0zM23.249 12.5a.75.75 0 00.636-1.147l-2-3.2a.779.779 0 00-1.272 0l-2 3.2a.75.75 0 00.635 1.147h.427a.5.5 0 01.5.565A8.242 8.242 0 015.53 17.118a1 1 0 10-1.568 1.242 10.24 10.24 0 0018.23-5.406.5.5 0 01.5-.454z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});