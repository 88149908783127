define("ember-svg-jar/inlined/warehouse-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>warehouse-cart</title><path d=\"M21.337 22h-3.569a.252.252 0 00-.248.286 2 2 0 003.973-.117.156.156 0 00-.156-.169zM23 19H9.531a.5.5 0 01-.486-.379l-1.181-4.727a2.487 2.487 0 00-1.54-1.725A.5.5 0 016 11.7V7.329a.5.5 0 01.32-.466 1.987 1.987 0 00.669-.43l2.368-2.308a1.014 1.014 0 00.071-.076A2.469 2.469 0 007.531 0H2.467A2.467 2.467 0 00.572 4.046a.828.828 0 00.07.075L3.01 6.432a1.988 1.988 0 00.671.431.5.5 0 01.319.466v4.576a.5.5 0 01-.249.433A2.5 2.5 0 002.5 14.5v4a.5.5 0 00.5.5 2.988 2.988 0 012.7 1.715.5.5 0 00.449.285H23a1 1 0 000-2zM6 2.5a.5.5 0 01.5-.5h1.031a.469.469 0 01.405.706.306.306 0 01-.042.053L6.424 4.19A.25.25 0 016 4.011zM4 4.01a.251.251 0 01-.425.179l-1.47-1.434a.26.26 0 01-.041-.055A.461.461 0 012 2.467.467.467 0 012.467 2H3.5a.5.5 0 01.5.5z\"/><circle cx=\"3\" cy=\"22\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});