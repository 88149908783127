define("ember-svg-jar/inlined/shipment-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-remove</title><path d=\"M6.75 4A.25.25 0 007 3.75V.5a.25.25 0 00-.25-.25H5.164A1.511 1.511 0 004.1.689L1.219 3.573A.25.25 0 001.4 4zM15.068 9.563a.251.251 0 00.182-.241V5.75A.25.25 0 0015 5.5H.5a.249.249 0 00-.25.25v9a1.5 1.5 0 001.5 1.5H9.1a.249.249 0 00.246-.212 8.012 8.012 0 015.722-6.475zM8.5 3.75a.249.249 0 00.25.25h5.35a.25.25 0 00.177-.427L11.4.689A1.511 1.511 0 0010.335.25H8.75A.249.249 0 008.5.5z\"/><path d=\"M17.25 10.75a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-2.65 8.091l1.414-1.414a.25.25 0 000-.354L14.6 15.659a.75.75 0 011.06-1.059l1.414 1.414a.25.25 0 00.353 0l1.414-1.414a.75.75 0 011.061 1.06l-1.414 1.414a.25.25 0 000 .354l1.412 1.413a.75.75 0 11-1.061 1.06l-1.414-1.414a.25.25 0 00-.353 0L15.66 19.9a.75.75 0 11-1.061-1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});