define("ember-svg-jar/inlined/road-sign-side-road-angle-right-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-side-road-angle-right-1-alternate</title><path d=\"M12.573 10.538a.249.249 0 01-.073-.177V9.25a.25.25 0 01.25-.25h.75a.5.5 0 00.354-.854L11.707 6a1 1 0 00-1.414 0L8.146 8.146A.5.5 0 008.5 9h.75a.25.25 0 01.25.25V16a1 1 0 001 1h1a1 1 0 001-1v-.69a.25.25 0 01.427-.176l1.109 1.108a1 1 0 001.414 0l.707-.707a1 1 0 000-1.414z\"/><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});