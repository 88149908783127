define("ember-svg-jar/inlined/anteater", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>anteater</title><path d=\"M24 16.431c0-4.543 0-9.24-2.688-11.39A17.5 17.5 0 008.945 1.934c-3.923.436-6.5 2-7.892 4.774a5.245 5.245 0 00-.114 4.185A7.46 7.46 0 000 14.428C0 15.6.541 16 .864 16.137a1.4 1.4 0 001.49-.356 5.275 5.275 0 00.886-1.42 10.544 10.544 0 012.114-3.076 2.708 2.708 0 013.785-.119.252.252 0 01.085.178.248.248 0 01-.07.185 8.14 8.14 0 00-2.147 4.32c-.564 3.383 1.257 4.031 1.335 4.057a.5.5 0 00.489-.1.5.5 0 00.159-.472c-.042-.209-.962-5 2.837-6.323a.25.25 0 01.241.042c.342.282.932 1.034.932 2.879v4.75h-.25a.75.75 0 100 1.5h2.5a.739.739 0 00.719-.6c.005-.014 1.009-4.675 1.3-6.02a.251.251 0 01.388-.153 9.473 9.473 0 011.953 1.832 5.839 5.839 0 011.444 3.437h-.8a.75.75 0 000 1.5h3a.74.74 0 00.711-.555.5.5 0 00.039-.2zM2.25 8.681a1 1 0 111 1 1 1 0 01-1-1zm1.779-3.247a.5.5 0 01.3-.638 2.5 2.5 0 011.039-.105 1.933 1.933 0 011.623 1.368.5.5 0 01-.942.334.935.935 0 00-.8-.71c-.49-.057-1.02.317-1.22-.249zM3.441 20.11a.8.8 0 01-.835.325c-.226-.086-.356-.344-.356-.709v-1.795a.75.75 0 00-1.5 0v1.795a2.161 2.161 0 001.321 2.111 2.269 2.269 0 002.488-.727.75.75 0 00-1.118-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});