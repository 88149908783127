define("ember-svg-jar/inlined/diagram-up-then-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-up-then-down</title><path d=\"M2.905 10.626a6.356 6.356 0 016.348-6.348h13.278a1.436 1.436 0 100-2.872H9.253a9.22 9.22 0 100 18.44H18.1a.25.25 0 01.25.25v1.062a1.435 1.435 0 002.45 1.015l2.746-2.745a1.439 1.439 0 00.314-.472l.007-.022a1.4 1.4 0 000-1.048l-.007-.021a1.439 1.439 0 00-.314-.472L20.8 14.648a1.435 1.435 0 00-2.451 1.015v1.061a.25.25 0 01-.25.25H9.253a6.356 6.356 0 01-6.348-6.348z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});