define("ember-svg-jar/inlined/phone-landline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-landline</title><path d=\"M18.5 11.956a4.005 4.005 0 01-3.961-3.442.248.248 0 00-.23-.218 45.021 45.021 0 00-4.618 0 .248.248 0 00-.23.218A4.005 4.005 0 015.5 11.956H4.251a.25.25 0 00-.25.25v7.75a2.5 2.5 0 002.5 2.5h11a2.5 2.5 0 002.5-2.5v-7.75a.25.25 0 00-.25-.25zM12 17.512a1 1 0 11-1 1 1 1 0 011-1zm-1-2.966a1 1 0 111 1 1 1 0 01-1-1zm3 3.966a1 1 0 111 1 1 1 0 01-1-1zm2-3.966a1 1 0 11-1-1 1 1 0 011 1zm-7-1a1 1 0 11-1 1 1 1 0 011-1zm0 3.966a1 1 0 11-1 1 1 1 0 011-1z\"/><path d=\"M21.1 2.466a45.381 45.381 0 00-18.2 0A3.7 3.7 0 000 5.956v2a2.5 2.5 0 002.5 2.5h3a2.5 2.5 0 002.5-2.5v-.812a.254.254 0 01.232-.253 45.151 45.151 0 017.536 0 .254.254 0 01.232.253v.812a2.5 2.5 0 002.5 2.5h3a2.5 2.5 0 002.5-2.5v-2a3.7 3.7 0 00-2.9-3.49z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});