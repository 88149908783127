define("ember-svg-jar/inlined/temperature-celsius-fahrenheit-convert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>temperature-celsius-fahrenheit-convert</title><path d=\"M14.2 3.4l2 1.5a.5.5 0 00.3.1.508.508 0 00.224-.053A.5.5 0 0017 4.5V4a.25.25 0 01.25-.25H19A1.252 1.252 0 0120.25 5v2.5a.75.75 0 001.5 0V5A2.752 2.752 0 0019 2.25h-1.75A.25.25 0 0117 2v-.5a.5.5 0 00-.8-.4l-2 1.5a.5.5 0 000 .8zM9.8 20.6l-2-1.5a.5.5 0 00-.8.4v.5a.25.25 0 01-.25.25H5A1.252 1.252 0 013.75 19v-2.5a.75.75 0 00-1.5 0V19A2.752 2.752 0 005 21.75h1.75A.25.25 0 017 22v.5a.5.5 0 00.276.447A.508.508 0 007.5 23a.5.5 0 00.3-.1l2-1.5a.5.5 0 000-.8zM18.25 12.5A5.75 5.75 0 1024 18.25a5.757 5.757 0 00-5.75-5.75zm2 4.5h-1a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h.5a.75.75 0 010 1.5h-.5a.25.25 0 00-.25.25v1a.75.75 0 01-1.5 0v-4a1.752 1.752 0 011.75-1.75h1a.75.75 0 010 1.5zM17 16a1 1 0 11-1-1 1 1 0 011 1zM5.75 0a5.75 5.75 0 105.75 5.75A5.757 5.757 0 005.75 0zm2 8a.75.75 0 010 1.5A2.752 2.752 0 015 6.75v-1A2.752 2.752 0 017.75 3a.75.75 0 010 1.5A1.252 1.252 0 006.5 5.75v1A1.252 1.252 0 007.75 8zM4.5 3.5a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});