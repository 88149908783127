define("ember-svg-jar/inlined/shape-square-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shape-square-add</title><path d=\"M23 18h-2.75a.25.25 0 01-.25-.25V15a1 1 0 00-2 0v2.75a.25.25 0 01-.25.25H15a1 1 0 000 2h2.75a.25.25 0 01.25.25V23a1 1 0 002 0v-2.75a.25.25 0 01.25-.25H23a1 1 0 000-2zM11 18H2.5a.5.5 0 01-.5-.5v-15a.5.5 0 01.5-.5h15a.5.5 0 01.5.5V11a1 1 0 002 0V2a2 2 0 00-2-2H2a2 2 0 00-2 2v16a2 2 0 002 2h9a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});