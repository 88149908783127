define("ember-svg-jar/inlined/garbage-bin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>garbage-bin</title><path d=\"M17.75.5H4.25A1.5 1.5 0 002.75 2v2a.5.5 0 00.5.5h17.5a.5.5 0 00.5-.5 3.5 3.5 0 00-3.5-3.5zM19.25 6h-15a.5.5 0 00-.5.5v11.448a.246.246 0 00.381.209A3.747 3.747 0 019.5 23.113a.252.252 0 00.209.387h4.852a.5.5 0 00.48-.363l4.687-16.5A.5.5 0 0019.25 6z\"/><circle cx=\"6.25\" cy=\"21.25\" r=\"2.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});