define("ember-svg-jar/inlined/baggage-scan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baggage-scan</title><path d=\"M21.5 1h-19A2.5 2.5 0 000 3.5v17A2.5 2.5 0 002.5 23h19a2.5 2.5 0 002.5-2.5v-17A2.5 2.5 0 0021.5 1zm-11 18.5a1 1 0 01-1 1h-4a1 1 0 01-1-1v-5.56a1.44 1.44 0 011.44-1.44h3.12a1.44 1.44 0 011.44 1.44zm-5-9V10a2 2 0 014 0v.5a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5zm14.25 0a.75.75 0 010 1.5H16a.5.5 0 00-.5.5v.146a.856.856 0 00.854.854h3.4a.75.75 0 010 1.5h-3.4a2.3 2.3 0 01-.289-.019.5.5 0 00-.565.5v4.269a.75.75 0 01-1.5 0v-9.515A2.739 2.739 0 0116.735 7.5h3.015a.75.75 0 010 1.5h-3.015a1.236 1.236 0 00-1.235 1.235.264.264 0 00.265.265zm1.5-5.25H5.75a.5.5 0 00-.5.5v.75a.75.75 0 01-1.5 0v-.75a.5.5 0 00-.5-.5h-.5a.75.75 0 010-1.5h18.5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});