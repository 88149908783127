define("ember-svg-jar/inlined/microphone-karaoke", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>microphone-karaoke</title><path d=\"M12 11.5a6.465 6.465 0 01-2.5-.5.247.247 0 00-.243.029.249.249 0 00-.1.223l.708 8.282a2.14 2.14 0 001.01 1.645.249.249 0 01.125.221V23a1 1 0 002 0v-1.594a.249.249 0 01.121-.214 2.117 2.117 0 001.006-1.631l.711-8.306a.252.252 0 00-.1-.223.249.249 0 00-.251-.032 6.458 6.458 0 01-2.487.5zm-.75 2.5a.75.75 0 011.5 0v1.5a.75.75 0 01-1.5 0zM16.822 4.75a.157.157 0 00.115-.05.16.16 0 00.041-.119 5 5 0 00-9.955-.107.251.251 0 00.249.276zM7.487 6.25a.252.252 0 00-.2.1.248.248 0 00-.034.225 5 5 0 009.491 0 .25.25 0 00-.244-.325z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});