define("ember-svg-jar/inlined/arrow-thick-left-bottom-corner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-left-bottom-corner</title><path d=\"M23.6 5.09L18.911.4A1 1 0 0017.5.4L6.9 11a.251.251 0 01-.354 0L1.811 6.264A1 1 0 00.1 6.971V22.9a1 1 0 001 1h15.929a1 1 0 00.707-1.707L13 17.457a.255.255 0 01-.073-.177.251.251 0 01.073-.18L23.6 6.5a1 1 0 000-1.41z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});