define("ember-svg-jar/inlined/keyboard-arrow-previous", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-arrow-previous</title><path d=\"M22.5 10.5H12.3a.25.25 0 01-.25-.25V6.27a1 1 0 00-1.707-.707l-5.732 5.728a1 1 0 000 1.414l5.728 5.727a1 1 0 00.707.293 1 1 0 001-1v-3.977a.25.25 0 01.25-.25H22.5a1.5 1.5 0 000-3zM1.5 4.77A1.5 1.5 0 000 6.27v11.455a1.5 1.5 0 003 0V6.27a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});