define("ember-svg-jar/inlined/plant-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plant-2</title><path d=\"M9.727 12.124c-3.86-2.572-3.86-4.543-3.86-5.385A4.744 4.744 0 0110.606 2 3.6 3.6 0 0114.2 5.591a2.677 2.677 0 01-2.673 2.674 1.941 1.941 0 01-1.941-1.939 1 1 0 10-2 0 3.943 3.943 0 003.939 3.939A4.679 4.679 0 0016.2 5.591 5.6 5.6 0 0010.606 0a6.747 6.747 0 00-6.739 6.739c0 1.062 0 3.883 4.75 7.049 4.424 2.949 2.125 8.585 2.026 8.821a1 1 0 001.84.782c1.08-2.541 1.893-8.167-2.756-11.267z\"/><path d=\"M15.294 16.584a4.909 4.909 0 001.241-1.46.25.25 0 01.206-.124 2 2 0 10-1.912-1.136.25.25 0 010 .223 2.891 2.891 0 01-.777.937 1 1 0 101.246 1.564zM19.905 18.574a2 2 0 00-3.759 1.156.249.249 0 01-.055.188 2.936 2.936 0 01-1.586.982 1 1 0 00.237 1.972 1.024 1.024 0 00.238-.028 5.025 5.025 0 002.38-1.344.251.251 0 01.245-.066 1.977 1.977 0 001.453-.157 2 2 0 00.847-2.703z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});