define("ember-svg-jar/inlined/mask-triangle-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mask-triangle-alternate</title><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2zm-2 19.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M5.32 20h13.36a1.274 1.274 0 001.161-1.9L13.118 4.922a1.258 1.258 0 00-2.238 0L4.161 18.1A1.274 1.274 0 005.32 20zm6.457-12.432a.251.251 0 01.446 0l5.135 10.068a.25.25 0 01-.222.364H6.864a.25.25 0 01-.222-.364z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});