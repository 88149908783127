define("ember-svg-jar/inlined/real-estate-dimensions-plan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-dimensions-plan</title><path d=\"M23 9a1 1 0 001-1V4.5A1.5 1.5 0 0022.5 3H17a.5.5 0 01-.5-.5v-1A1.5 1.5 0 0015 0H4.5A1.5 1.5 0 003 1.5V9a.5.5 0 01-.5.5h-1A1.5 1.5 0 000 11v11.5A1.5 1.5 0 001.5 24H12a1.5 1.5 0 001.5-1.5V21a.5.5 0 01.5-.5h8.5A1.5 1.5 0 0024 19v-7a1 1 0 00-1-1 2.5 2.5 0 01-2.164-1.249.5.5 0 01.432-.751zM5.75 18.75a.5.5 0 01-.5.5H2.5a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h2.75a.5.5 0 01.5.5zM2 21.25a.5.5 0 01.5-.5h2.75a.5.5 0 01.5.5v.25a.5.5 0 01-.5.5H2.5a.5.5 0 01-.5-.5zm9.5.25a.5.5 0 01-.5.5H7.75a.5.5 0 01-.5-.5V17a.75.75 0 00-.75-.75h-4a.5.5 0 01-.5-.5V12a.5.5 0 01.5-.5h3.75a1 1 0 000-2H5.5A.5.5 0 015 9V2.5a.5.5 0 01.5-.5H14a.5.5 0 01.5.5V9a.5.5 0 01-.5.5H9.75a1 1 0 000 2H11a.5.5 0 01.5.5v1a1 1 0 001 1 2 2 0 011.489.666.5.5 0 01-.372.834H12.5a1 1 0 00-1 1zM22 6.5a.5.5 0 01-.5.5h-2a1 1 0 00-1 1v.5a4.508 4.508 0 003.15 4.293.5.5 0 01.35.477V18a.5.5 0 01-.5.5H14a.5.5 0 010-1h1.5a1 1 0 001-1V16a4 4 0 00-2.887-3.842.157.157 0 01-.113-.15V12a.5.5 0 01.5-.5h1.5a1 1 0 001-1v-5A.5.5 0 0117 5h4.5a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});