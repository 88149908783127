define("ember-svg-jar/inlined/phone-action-at-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-at-1</title><path d=\"M11 12.763V18.5a1 1 0 01-1 1H3a1 1 0 01-1-1v-11a1 1 0 011-1h5.771a.244.244 0 00.243-.234 8.261 8.261 0 01.351-1.944A.245.245 0 009.132 4H2.5A2.5 2.5 0 000 6.5v15A2.5 2.5 0 002.5 24h8a2.5 2.5 0 002.5-2.5v-7.547a.241.241 0 00-.115-.205 8.317 8.317 0 01-1.453-1.155.25.25 0 00-.432.17z\"/><path d=\"M24 7.828v-.811A6.933 6.933 0 0017.424 0a6.759 6.759 0 00-6.747 8.314 6.671 6.671 0 004.987 5 6.914 6.914 0 003.322-.044 1 1 0 10-.513-1.932 4.872 4.872 0 01-2.362.027 4.725 4.725 0 01-3.483-3.495 4.8 4.8 0 01.946-4.134A4.705 4.705 0 0117.373 2 4.921 4.921 0 0122 7.017v.811a.8.8 0 11-1.594 0V6.75v-.052a3.158 3.158 0 10-1.492 2.733A2.8 2.8 0 0024 7.828zm-6.75.078a1.156 1.156 0 111.156-1.156 1.157 1.157 0 01-1.156 1.156z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});