define("ember-svg-jar/inlined/real-estate-deal-shake", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-deal-shake</title><path d=\"M22 15.124a1 1 0 00-1-1h-1a1.48 1.48 0 00-.692.171.5.5 0 01-.377.036l-4.352-1.313a2.445 2.445 0 00-1.889.106c-.156.076-1.14.55-1.639.8a.5.5 0 01-.491-.027c-.828-.532-1.416-.323-2.321-.074-.536.149-1.406.389-2.81.774a.5.5 0 01-.459-.1 1.475 1.475 0 00-.97-.373H3a1 1 0 00-1 1v5.5a1 1 0 001 1h1a1.489 1.489 0 001.163-.562.5.5 0 01.39-.188H6.89a.5.5 0 01.349.142l1.93 1.88a1.431 1.431 0 001.973.158l2.577-2.07a.5.5 0 01.313-.11h4.415a.5.5 0 01.39.187 1.487 1.487 0 001.163.563h1a1 1 0 001-1zm-10.509.248l1.861-.9a.868.868 0 01.756-.029l4.037 1.218a.5.5 0 01.355.479v2.735a.5.5 0 01-.5.5h-2.36a.5.5 0 01-.5-.431 1.532 1.532 0 00-.316-.734l-.867-.955a.1.1 0 01.044-.158.751.751 0 10-.444-1.434l-1.695.527a.45.45 0 01-.376-.817zM10.6 21.58a.5.5 0 01-.663-.031l-2.088-2.033a.5.5 0 00-.349-.142H6a.5.5 0 01-.5-.5v-2.365a.5.5 0 01.368-.482c1.379-.377 2.24-.615 2.772-.762.445-.123.652-.181.755-.207a.385.385 0 01.379.441 1.917 1.917 0 00.113.987 1.944 1.944 0 002.133 1.206.5.5 0 01.456.157l.8.886a.5.5 0 01-.056.725z\"/><path d=\"M23.707 12.917a1 1 0 000-1.414L13.414 1.21a2 2 0 00-2.828 0L.293 11.5a1 1 0 101.414 1.414l9.939-9.939a.5.5 0 01.708 0l9.939 9.939a1 1 0 001.414.003zM16 1.624a1 1 0 001 1h2a.5.5 0 01.5.5v2a1 1 0 002 0v-3a1.5 1.5 0 00-1.5-1.5h-3a1 1 0 00-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});